// src/components/Modal.tsx
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { colors } from '../theme';
import { Title } from './Text';

interface ModalProps {
  className?: string; // Class
  name: string; // Key used to fetch the translated title text
  onClose: () => void; // Function to call when the modal should be closed
  children: ReactNode; // The content of the modal
}

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; // High z-index to ensure it's on top of everything else
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  position: relative;
  background-color: ${colors.light};
  padding: 20px;
  border-radius: 10px;
`;

const ModalScrollView = styled.div`
  flex-direction: column;
  display: flex;
  flex: 0 1 auto;
  max-height: 80vh;
  max-width: 500px;
  width: 100%;
  overflow: auto;
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px; // Spacing between header and body
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0.1em 0.4em;
  font-size: 24px;
  cursor: pointer;
  color: ${colors.mainDark};
  position: absolute;
  border-radius: 10px;
  top: 2px;
  right: 2px;

  &:hover {
    background-color: ${colors.lightGrey};
  }
`;

const Modal: React.FC<ModalProps> = ({ name, onClose, className, children }) => {
  return (
    <ModalOverlay>
      <ModalContainer className={className}>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <ModalScrollView>
          <ModalHeader>
            <Title name={name} />
          </ModalHeader>
          {children}
        </ModalScrollView>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default Modal;
