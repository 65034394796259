import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../components/Modal';
import Input from '../components/Input';
import Button from '../components/Button';
import { listSports, Sport, createSport } from '../api'
import { useLanguage } from '../contexts/languageContext';
import { ErrorMessage } from '../components/Text';
import { cache } from '../contexts/cache';

const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const SportCreation: React.FC<{ onClose: () => void; }> = ({ onClose }) => {
  const [sportsList, setSportsList] = useState<Sport[]>(cache.sports)
  const [sportName, setSportName] = useState('');
  const [feedback, setFeedback] = useState('');
  const language = useLanguage()

  React.useEffect(() => {
    listSports(language).then(setSportsList)
  },[language])

  const handleSubmit = React.useCallback(async () => {
    sportName && createSport(sportName, language)
      .then(onClose)
      .catch(err => setFeedback(err.message))
  }, [language, onClose, sportName]);

  return (
    <Modal onClose={onClose} name='createSport'>
      <Form>
        <Input
          name='sportName'
          value={sportName}
          onChangeText={React.useCallback((e: string) => setSportName(e.replace(/,/g, '')), [])}
        />
        <Button disabled={!sportName || sportName==='publicOnly' || sportsList.map(sport => sport.name).includes(sportName)} name='create' onClick={handleSubmit} />
        {feedback && <ErrorMessage>{feedback}</ErrorMessage>}
      </Form>
    </Modal>
  );
};

export default SportCreation;
