import React, { FormEvent, useState } from 'react';
import Button from '../components/Button';
import Input from '../components/Input';
import Text, { ErrorMessage, Title } from '../components/Text';
import styled from 'styled-components';
import Dropdown from '../components/Dropdown';
import { useProfile } from '../contexts/profileContext';
import { RegisterRequest } from '../api';
import Terms from '../modals/Terms';
import { useTranslation } from '../contexts/languageContext';

const Container = styled.div`
  padding: 1em;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  gap: 0.5em;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  margin-right: 8px;
`;

const Label = styled.label`
  cursor: pointer;
`

const ClickableLink = styled.div`
  cursor: pointer;
  text-decoration: underline;
  color: blue;
`

const availableCountries = ['France']
const toName = (country: string) => country

const Login: React.FC = () => {
  const [isRegistering, setIsRegistering] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);
  const [feedback, setFeedback] = useState<string>();
  const { login, register } = useProfile()
  const [isCGUAccepted, setIsCGUAccepted] = React.useState(false)
  const [modal, setModal] = React.useState<'cgu' | 'legalTerms'>()
  const { t } = useTranslation()

  const [formData, setFormData] = useState<RegisterRequest & { confirmPassword: string }>({
    phoneNumber: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    country: 'France',
    postalCode: '',
  });

  React.useEffect(() => {
    try {
      const data = localStorage.getItem('login')
      if(data) setFormData(current => ({ ...current, ...JSON.parse(data) }))
    } catch(err) {
      console.log(err)
    }
  }, [])

  const handleChange = React.useCallback((name: string) => (value: string) => {
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }, []);

  const handleSubmit = React.useCallback(() => {
    // Save the data for next login
    try {
      if(rememberMe) localStorage.setItem('login', JSON.stringify({ phoneNumber: formData.phoneNumber, password: formData.password }))
    } catch(err) {
      console.error(err)
    }

    if (isRegistering) {
      // CGU needed
      if(!isCGUAccepted) {
        return setFeedback(t("cguRequired"))
      }
      // confirm password should match
      if (formData.password !== formData.confirmPassword) {
        return setFeedback(t("wrongPasswords"));
      }
      // Required field missing
      if(Object.values(formData).some(elt => !elt)) {
        return setFeedback(t('missingFields'))
      }
    } else {
      if(!formData.phoneNumber || !formData.password) {
        return setFeedback(t('missingFields'))
      }
    }
    (isRegistering ? register(formData, rememberMe) : login(formData, rememberMe)).catch(err => {
      if(err.message.includes('404')) setFeedback(t('notFound'))
      else if(err.message.includes('401')) setFeedback(t('wrongPassword'))
      else setFeedback(err.message)
    })
  }, [formData, isCGUAccepted, isRegistering, login, register, rememberMe, t])

  const onFormSubmit = React.useCallback((e: FormEvent) => {
    e.preventDefault()
    handleSubmit()
  }, [handleSubmit])

  const onAcceptingCGU = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => setIsCGUAccepted(e.target.checked), [])
  const toggleAcceptingCGU = React.useCallback(() => setIsCGUAccepted(b => !b), [])
  const closeModal = React.useCallback(() => setModal(undefined), [])
  const openCGU = React.useCallback(() => setModal('cgu'), [])
  const openLegalTerms = React.useCallback(() => setModal('legalTerms'), [])

  return (
    <Container>
      <Title name={isRegistering ? 'registerScreen' : 'loginScreen'} />

      <Form onSubmit={onFormSubmit}>
        <Input
          name="phoneNumber"
          value={formData.phoneNumber}
          required
          onChangeText={handleChange('phoneNumber')}
        />
        <Input
          type="password"
          name="password"
          value={formData.password}
          required
          onChangeText={handleChange('password')}
        />

        {isRegistering && (
          <>
            <Input
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              required
              onChangeText={handleChange('confirmPassword')}
            />
            <Input
              name="firstName"
              value={formData.firstName}
              required
              onChangeText={handleChange('firstName')}
            />
            <Input
              name="lastName"
              value={formData.lastName}
              required
              onChangeText={handleChange('lastName')}
            />
            <Input
              name="postalCode"
              value={formData.postalCode}
              required
              onChangeText={handleChange('postalCode')}
            />
            <Dropdown selectedItem={formData.country} items={availableCountries} itemToString={toName} name='country' onChange={handleChange('country')}/>
          </>
        )}

        <div>
          <Checkbox name='rememberMe' checked={rememberMe} onChange={React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => setRememberMe(e.target.checked), [])} />
          <Label htmlFor='rememberMe' onClick={React.useCallback(() => setRememberMe(b => !b), [])}><Text name='rememberMe' /></Label>
        </div>

        {isRegistering && <div>
          <div>
            <Checkbox name='acceptCGU' checked={isCGUAccepted} onChange={onAcceptingCGU} />
            <Label htmlFor='acceptCGU' onClick={toggleAcceptingCGU}><Text name='acceptCGU' /></Label>
            <ClickableLink onClick={openCGU}><Text color='blue' name='cgu' /></ClickableLink>
          </div>
        </div>}

        <Button name={isRegistering ? 'register' : 'login'} onClick={handleSubmit} />
        {feedback && <ErrorMessage>{feedback}</ErrorMessage>}
      </Form>

      <Button name={isRegistering ? 'goToLogin' : 'goToRegister'} onClick={React.useCallback(() => setIsRegistering(b => !b), [])} />
      <ClickableLink onClick={openLegalTerms}><Text color='blue' name='legalTerms' /></ClickableLink>
      {modal && <Terms type={modal} onClose={closeModal}/>}
    </Container>
  );
};

export default Login;
