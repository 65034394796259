// src/components/Dropdown.tsx
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import List, { ListProps } from './List';
import { colors } from '../theme';
import Text from './Text';

interface DropdownProps<ItemType> {
  items: ItemType[];
  name: string;
  selectedItem: ItemType | null;
  itemToString: (item: ItemType) => string;
  onChange: (item: ItemType, index: number) => void;
  addElement?: () => void
}

const DropdownContainer = styled.div`
  position: relative;
`;

const SelectedItem = styled.div`
  border: 1px solid ${colors.dark};
  padding: 10px;
  cursor: pointer;
`;

const DropdownList = styled(List)`
  background-color: ${colors.light};
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 10;
  overflow-y: auto;
  max-height: 30em;
`;

const ItemContainer = styled.div`
  cursor: pointer;
  padding: 0.5em 0.8em;

  &:hover {
    background-color: ${colors.lightGrey};
  }
`

const Item: ListProps<{ value: string, onClick: () => void }>['Item'] = ({ item: { value, onClick } }) => {
  return <ItemContainer onClick={onClick}><Text name='item' customText={value} /></ItemContainer>
}

const Dropdown = <ItemType,>({
  items,
  name,
  selectedItem,
  itemToString,
  onChange,
  addElement
}: DropdownProps<ItemType>) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const closeDropdown = React.useCallback(() => setIsOpen(false), [])

  const options = React.useMemo(() => items.map((item, i) => ({
    value: itemToString(item),
    onClick: () => {
      closeDropdown()
      onChange(item, i)
    }
  })), [closeDropdown, itemToString, items, onChange])
  
  const selectedIndex = items.indexOf(selectedItem as ItemType)  

  // Handle clicks outside of the dropdown
  useEffect(() => {
    function handleDocumentClick(event: MouseEvent) {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) closeDropdown()
    }

    document.addEventListener('mousedown', handleDocumentClick);
    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, [closeDropdown]);

  return (
    <DropdownContainer ref={containerRef}>
      <SelectedItem onClick={React.useCallback(() => setIsOpen(!isOpen), [isOpen])}>
        <Text name={`placeholderFor${name}`} customText={options[selectedIndex]?.value} />
      </SelectedItem>
      {isOpen && ( <DropdownList type={name} items={options} Item={Item} addElement={addElement} /> )}
    </DropdownContainer>
  );
};

export default Dropdown;
