export const CGU = `<h2>1. Informations légales</h2>
<p>L’application Cantoo Sport Meeting, accessible à l'adresse <a href="https://sport.cantoo.fr">https://sport.cantoo.fr</a>, est éditée par la société Cantoo, société par actions simplifiée, au capital de 22 857,10 € dont le siège social est situé au 165 avenue de Bretagne, 59000 Lille, France.</p>
<h2>2. Acceptation des Conditions Générales d'Utilisation</h2>
<p>L'utilisation de l’application Cantoo Sport Meeting implique l'acceptation pleine et entière des présentes conditions générales d'utilisation. Ces conditions d'utilisation sont susceptibles d'être modifiées ou complétées à tout moment, les utilisateurs de l’application sont donc invités à les consulter de manière régulière.</p>
<h2>3. Description des services fournis</h2>
<p>Cantoo Sport Meeting est une application permettant aux utilisateurs de planifier, organiser et participer à des événements sportifs. Les informations fournies sur Cantoo Sport Meeting sont données à titre indicatif et sont susceptibles d’évoluer.</p>
<h2>4. Responsabilités</h2>
<p>L’utilisateur est seul responsable de l’utilisation qu’il fait des informations et services proposés par Cantoo Sport Meeting, et la société Cantoo ne pourra en aucun cas être tenue responsable de tout dommage direct ou indirect découlant de l'utilisation de son application.</p>
<h2>5. Propriété intellectuelle</h2>
<p>La société Cantoo détient les droits de propriété intellectuelle ou détient les droits d’usage sur tous les éléments accessibles sur l’application, notamment les textes, images, graphismes, logo, icônes. Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments de l’application, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de la société Cantoo.</p>
<h2>6. Données personnelles</h2>
<p>L’application Cantoo Sport Meeting respecte la vie privée de ses utilisateurs et s’engage à ce que toutes les informations qu’elle recueille permettant d’identifier ceux-ci soient considérées comme des informations confidentielles. La société Cantoo s'engage à respecter les obligations légales en matière de protection des données personnelles. Les données recueillies ne servent qu'à la seule fin de fournir le service. Elles seront supprimées après 13 mois si l'utilisateur ne s'est pas connecté durant cette période.</p>
<p>Conformément à l’article 34 de la loi « Informatique et Libertés », Cantoo garantit à l’Utilisateur un droit d’opposition, d’accès, de rectification et de suppression sur les données personnelles le concernant. Si vous souhaitez faire valoir vos droits cités précédemment, adressez-nous un email à l’adresse francois@cantoo.fr afin que nous puissions traiter votre demande rapidement.</p>
<h2>7. Droit applicable et attribution de juridiction</h2>
<p>Tout litige en relation avec l’utilisation de l’application Cantoo Sport Meeting est soumis au droit français. En cas de litige, les tribunaux français seront seuls compétents.</p>
<h2>8. Contact</h2>
<p>Pour toute question relative à l’application, vous pouvez contacter la société Cantoo à l’adresse suivante : 165 avenue de Bretagne, 59000 Lille, France.</p>`;

export const legalTerms = `<h2>Éditeur du Site :</h2>
<p>Cantoo, société par actions simplifiée au capital de 22 857,10 €, immatriculée au Registre du commerce et des sociétés de Lille métropole sous le numéro 879 181 584, représentée par Monsieur François Billioud, Président, domicilié en cette qualité audit siège</p>
<ul>
  <li><b>Adresse :</b> 165 avenue de Bretagne 59000 Lille,</li>
  <li><b>Numéro de téléphone :</b> +33 (0)6.63.91.75.15</li>
  <li><b>Adresse électronique :</b> francois@cantoo.fr</li>
  <li><b>Directeur de la Publication et Responsable de Traitement :</b> Monsieur François Billioud</li>
</ul>

<h2>Hébergeur</h2>

<p>Le Site et les Données et l'application Cantoo Sport Meeting sont hébergées par OVH, société par actions simplifiée, immatriculée au RCS de Lille métropole sous le numéro 424 761 419, dont le siège social est situé</p>
<ul>
  <li><b>Adresse :</b> 2 rue Kellermann 59100 Roubaix</li>
  <li><b>Téléphone :</b> 1007</li>
  <li><b>Adresse électronique :</b> contact@ovh.com</li>
  <li><b>Site internet :</b> https://ovh.com.</li>
</ul>

<h2>Propriété Intellectuelle</h2>

<p>Le site internet accessible à l’adresse  « <a href="https://sport.cantoo.fr/">https://sport.cantoo.fr/</a> » ainsi que les éléments téléchargeables (images, programmes, vidéos...) sont couverts par des droits de propriété intellectuelle.</p>

<p>Aussi, toute représentation, reproduction et/ou exploitation, totale ou partielle, de ce site internet, en ce compris notamment de son contenu, ses pages, ses scripts, ses textes, vidéos et images, est interdit, sauf accord préalable et écrit de l’éditeur du site internet.</p>

<p>De même, l’élaboration de liens hypertextes profonds vers le site internet est interdite sauf accord préalable et écrit de l’éditeur du site internet.</p>

<p>Le non-respect de ces interdictions est susceptible de constituer un acte de contrefaçon et/ou de concurrence déloyale.</p>

<p>La reproduction de portions du logiciel Cantoo Sport Meeting à des fins de formation à l'usage de l'outil, ou d'information, est toutefois autorisée. De même, la distribution des executables d'installation est autorisée tant que le distributeur garanti le respect de la licence d'utilisation par l'utilisateur final.</p>
`