import axios from 'axios';
import { cache, clearCache } from './contexts/cache';

const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://sport.cantoo.fr/api' : 'http://localhost:3000/api'

const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export type Status = 'participant' | 'interested' | 'invited' | 'banned' | 'completed' | undefined

// ======================= Types =======================
// User Types
export type RegisterRequest = {
  phoneNumber: string;
  firstName: string;
  lastName: string;
  postalCode: string;
  country: string;
  password: string;
  favoriteSports?: string
  favoritePlaces?: string
}

export type UpdateRequest = Partial<Omit<RegisterRequest, 'phoneNumber' | 'password'>>

export interface RegisterResponse {
  userId: string;
  token: string;
}

export interface LoginRequest {
  phoneNumber: string;
  password: string;
}

export interface LoginResponse {
  userId: string;
  token: string;
}

export interface MessageResponse {
  message: string;
}

export interface User {
  uuid: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  postalCode: string;
  favoriteSports: string;
  favoritePlaces: string;
  country: string;
  reputation: number;
  voteCount: number;
}

export interface Participant extends User {
  extraPeople: number;
  status: Status
}

export interface Friend {
  uuid: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

export interface Place {
  id: number;
  name: string;
  address: string;
  latitude: number;
  longitude: number;
  phoneNumber?: string;
  websiteUrl?: string;
}

export interface CreatePlaceResponse {
  placeId: number;
}

export interface Sport {
  id: number;
  name: string;
  language: string;
}

export interface CreateSportResponse {
  sportId: number;
}

export interface Message {
  uuid: string;
  userId: string;
  sportSessionId: string;
  content: string;
  postDate: Date;
}

export interface PostMessageResponse {
  message: string;
  postDate: Date;
  messageId: string;
}

export interface SportSession {
  uuid: string;
  sport: Sport;
  creatorId: string;
  place: Place;
  datetime: Date;
  duration: number;
  price: number;
  password?: string;
  level: number;
  participants: number;
  teams: number;
  description: string;
  canceled: boolean;
  subscribed: number;
}

export type SportSessionWithStatus = SportSession & { status: 'interested' | 'participant' | 'invited' | 'completed' }

export type SportSessionCreation = {
  sportId: number;
  placeId: number
  datetime: Date;
  duration: number;
  price: number;
  password?: string;
  level: number;
  participants: number;
  teams: number;
  description: string;
}

export interface CreateSportSessionResponse {
  message: string;
  sessionId: string;
}

function fromUTCTime(utcDateString: string) {
  // Convert UTC date string to local Date object
  const utcDate = new Date(utcDateString);
  return new Date(utcDate.getTime() - (utcDate.getTimezoneOffset() * 60000));
}

// ======================= API Calls =======================
let token = localStorage.getItem('token')

// User API
export const registerUser = async (data: RegisterRequest): Promise<RegisterResponse> => {
  const response = await apiClient.post('/user/register', data);
  token = response.data.token
  return response.data as Record<keyof RegisterResponse, string>;
};

export const logoutUser = () => {
  clearCache()
  token = null
}

export const loginUser = async (data: LoginRequest): Promise<LoginResponse> => {
  const response = await apiClient.post('/user/login', data);
  token = response.data.token
  return response.data as Record<keyof LoginResponse, string>;
};

export const updateUser = async ( data: UpdateRequest ): Promise<MessageResponse> => {
  const response = await apiClient.put('/user/update', data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data as Record<keyof MessageResponse, string>;
};

export const updatePassword = async ( data: { oldPassword: string; newPassword: string } ): Promise<MessageResponse> => {
  const response = await apiClient.put('/user/updatePassword', data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data as Record<keyof MessageResponse, string>;
};

export const updatePhoneNumber = async ( data: { newPhoneNumber: string } ): Promise<MessageResponse> => {
  const response = await apiClient.put('/user/updatePhoneNumber', data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data as Record<keyof MessageResponse, string>;
};

export const confirmPhoneNumberUpdate = async ( data: { newPhoneNumber: string; code: string } ): Promise<MessageResponse> => {
  const response = await apiClient.put('/user/confirmPhoneNumberUpdate', data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data as Record<keyof MessageResponse, string>;
};

export const getUserProfile = async (): Promise<User> => {
  if(!token) throw new Error('Not connected')
  const response = await apiClient.get('/user/profile', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return {
    ...(response.data as Record<keyof User, string>),
    reputation: Number.parseFloat(response.data.reputation),
    voteCount: Number.parseInt(response.data.voteCount)
  };
};

// Friend API
export const addFriend = async (friendId: string): Promise<MessageResponse> => {
  const response = await apiClient.put(`/friend/${friendId}`, {}, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data as Record<keyof MessageResponse, string>;
};

export const deleteFriend = async (friendId: string): Promise<MessageResponse> => {
  const response = await apiClient.delete(`/friend/${friendId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data as Record<keyof MessageResponse, string>;
};

export const listFriends = async (): Promise<Friend[]> => {
  const response = await apiClient.get('/friend/friends', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  cache.friends = response.data as Friend[]
  return response.data as Record<keyof Friend, string>[];
};

// Place API
export const createPlace = async (place: Omit<Place, 'id'>): Promise<CreatePlaceResponse> => {
  const response = await apiClient.post('/place/create', place, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return { ...(response.data as Record<keyof CreatePlaceResponse, string>), placeId: Number.parseInt(response.data.placeId) }
};

export const updatePlace = async (placeId: number, place: Partial<Omit<Place, 'id'>>): Promise<MessageResponse> => {
  const response = await apiClient.put(`/place/${placeId}`, place, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data as Record<keyof MessageResponse, string>;
};

export const listPlaces = async (): Promise<Place[]> => {
  const response = await apiClient.get('/place/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const places: Place[] = response.data.map((place: Record<keyof Place, string>) => ({
    ...place,
    id: Number.parseInt(place.id),
    latitude: Number.parseFloat(place.latitude),
    longitude: Number.parseFloat(place.longitude)
  }));
  places.sort((s1, s2) => s1.name > s2.name ? 1 : -1)
  cache.places = places
  return places
};

// Sport API
export const createSport = async (sportName: string, language: string): Promise<CreateSportResponse> => {
  const response = await apiClient.post('/sport/create', { name: sportName, language }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return {...response.data as Record<keyof CreateSportResponse, string>, sportId: Number.parseInt(response.data.sportId) };
};

export const listSports = async (language: string): Promise<Sport[]> => {
  const response = await apiClient.get(`/sport/${language}`);
  const sports: Sport[] = response.data.map((sport: Record<keyof Sport, string>) => ({
    ...sport,
    id: Number.parseInt(sport.id)
  }));
  sports.sort((s1, s2) => s1.name > s2.name ? 1 : -1)
  cache.sports = sports
  return sports
};

// Message API
export const postMessage = async (sessionId: string, content: string): Promise<PostMessageResponse> => {
  const response = await apiClient.post(`/message/${sessionId}`, { content }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return { ...(response.data as Record<keyof PostMessageResponse, string>), postDate: fromUTCTime(response.data.postDate) };
};

export const getSessionMessages = async (sessionId: string): Promise<Message[]> => {
  const response = await apiClient.get(`/message/${sessionId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const messages: Message[] = (response.data as Record<keyof Message, string>[]).map(message => ({ ...message, postDate: fromUTCTime(message.postDate) }));
  cache.sessionMessages[sessionId] = messages
  return messages
};

export const deleteMessage = async (messageId: string): Promise<MessageResponse> => {
  const response = await apiClient.delete(`/message/${messageId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data as Record<keyof MessageResponse, string>;
};

// SportSession API
export const cancelParticipation = async (sessionId: string): Promise<MessageResponse> => {
  const response = await apiClient.put(`/sportSession/${sessionId}/cancelParticipation`, {}, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data as Record<keyof MessageResponse, string>;
};

export const updateExtraPeople = async (sessionId: string, newExtraCount: number): Promise<MessageResponse> => {
  const response = await apiClient.put(`/sportSession/${sessionId}/updateExtraPeople`, { extraPeople: newExtraCount }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data as Record<keyof MessageResponse, string>;
};

export const createSportSession = async (session: SportSessionCreation): Promise<CreateSportSessionResponse> => {
  const response = await apiClient.post(`/sportSession/create`, session, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data as Record<keyof CreateSportSessionResponse, string>;
};

export const inviteToSession = async (sessionId: string, friendId: string): Promise<MessageResponse> => {
  const response = await apiClient.post(`/sportSession/${sessionId}/invite`, { friendId }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data as Record<keyof MessageResponse, string>;
};

export const participateInSession = async (sessionId: string, password?: string): Promise<MessageResponse> => {
  const response = await apiClient.post(`/sportSession/${sessionId}/participate`, { password }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data as Record<keyof MessageResponse, string>;
};

export const showInterestInSession = async (sessionId: string): Promise<MessageResponse> => {
  const response = await apiClient.post(`/sportSession/${sessionId}/interested`, {}, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data as Record<keyof MessageResponse, string>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function formatSession(session: Record<string, any>) {
  const { canceled, participants, subscribed, price, duration, level, place, sport, password, datetime, ...rest } = session
  const result = {
    ...rest,
    datetime: fromUTCTime(datetime),
    canceled: !!canceled,
    subscribed: Number.parseInt(subscribed),
    participants: Number.parseInt(participants),
    price: Number.parseFloat(price),
    duration: Number.parseInt(duration),
    level: Number.parseInt(level),
    place: {
      ...place,
      id: Number.parseInt(place.id),
      longitude: Number.parseFloat(place.longitude),
      latitude: Number.parseFloat(place.latitude)
    } as Place,
    sport: {
      ...sport,
      id: Number.parseInt(sport.id)
    } as Sport,
  }
  if(password) Object.assign(result, { password })
  return result
}

export const getEventsOnDate = async (date: Date): Promise<SportSession[]> => {
  const response = await apiClient.get(`/sportSession/date/${date.toISOString().split("T")[0]}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const sessions = response.data.map(formatSession);
  cache.sessions[date.toISOString()] = sessions
  return sessions
};

export const getSubscribedSessions = async (): Promise<SportSessionWithStatus[]> => {
  const response = await apiClient.get(`/sportSession/subscribedSessions`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const sessions = response.data.map(formatSession);
  cache.subscribedSessions = sessions
  return sessions
};

export const getSubscribersForSession = async (sessionId: string): Promise<Participant[]> => {
  const response = await apiClient.get(`/sportSession/${sessionId}/subscribers`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const participants = response.data.map((participant: Record<keyof Participant, string>) => ({
    ...participant,
    voteCount: Number.parseInt(participant.voteCount),
    reputation: Number.parseFloat(participant.reputation),
    extraPeople: Number.parseInt(participant.extraPeople)
  } as Participant));
  cache.subscribers[sessionId] = participants
  return participants
};

export const reviewSession = async (sessionId: string, reviews: Record<string, number>): Promise<MessageResponse> => {
  const response = await apiClient.post(`/sportSession/${sessionId}/review`, { reviews }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data as Record<keyof MessageResponse, string>;
};

export const reportAbsentUserInSession = async (sessionId: string, userId: string): Promise<MessageResponse> => {
  const response = await apiClient.put(`/sportSession/${sessionId}/complain`, { userId }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data as Record<keyof MessageResponse, string>;
};

export const kickUserFromSession = async (sessionId: string, userId: string): Promise<MessageResponse> => {
  const response = await apiClient.put(`/sportSession/${sessionId}/kick`, { userId }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data as Record<keyof MessageResponse, string>;
};

export const banUserFromSession = async (sessionId: string, userId: string): Promise<MessageResponse> => {
  const response = await apiClient.put(`/sportSession/${sessionId}/ban`, { userId }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data as Record<keyof MessageResponse, string>;
};
