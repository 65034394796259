import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import SessionCard from './SessionCard';
import { SportSession, getEventsOnDate } from '../api'
import { Title } from './Text';
import { colors } from '../theme';
import { useNavigation } from '../contexts/navigationContext';
import { cache } from '../contexts/cache';

const DayLaneContainer = styled.div<{ disabled: boolean }>`
  display: flex;
  ${props => props.disabled ? '' : 'cursor: pointer;'}
  flex-direction: column;
  align-items: stretch;
  flex: 1 0 0;
  min-width: 10em;
  border: 1px solid ${colors.dark};
  border-right: none;
  background-color: ${props => props.disabled ? colors.grey : colors.lightGrey};
`;

const DateContainer = styled.div`
  margin-bottom: 15px;
  border-bottom: 1px solid ${colors.dark};
  display: flex;
  flex-direction: column;
  align-items: center;
`

const DateDisplay = styled(Title)`
  font-size: 1.5em;
  font-weight: bold;
`;

const DayLane: React.FC<{ date: Date }> = ({ date }) => {
  const [sessions, setSessions] = useState<SportSession[]>(cache.sessions[date.toISOString()] || []);
  const { toCreateSession } = useNavigation()
  const disabled = React.useMemo(() => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    return date < today
  }, [date])

  useEffect(() => {
    // Fetch the sessions for the given date
    getEventsOnDate(date).then(events => setSessions(events));
  }, [date]);

  // Format the date for display
  const displayDay = date.toLocaleDateString(navigator.language || 'en-US', {
    weekday: 'long',
  });
  const displayDate = date.toLocaleDateString(navigator.language || 'en-US', {
    month: 'short',
    day: 'numeric',
  });

  const sessionItems = React.useMemo(() => sessions.map((session) => (
    <SessionCard key={session.uuid} session={session} />
  )), [sessions])

  const createSession = React.useCallback(() => {
    !disabled && toCreateSession(date)
  }, [date, disabled, toCreateSession])

  return (
    <DayLaneContainer disabled={disabled} onClick={createSession}>
      <DateContainer>
        <DateDisplay name='date' customText={displayDay} />
        <DateDisplay name='day' customText={displayDate} />
      </DateContainer>
      {sessionItems}
    </DayLaneContainer>
  );
};

export default DayLane;
