import React, { useEffect, useState } from 'react';
import { SportSession, Participant, Friend, inviteToSession, listFriends } from '../api';
import Modal from '../components/Modal';
import styled from 'styled-components';
import Text, { ErrorMessage } from '../components/Text';
import List from '../components/List';
import { cache } from '../contexts/cache';

const ItemContainer = styled.div`
  cursor: pointer;
  padding: 0.5em 0.8em;

  &:hover {
    background-color: #E5E5E5;
  }
`

interface InviteFriendsProps {
  session: SportSession;
  subscribedUsers: Participant[];
  onClose: () => void;
}

const FriendItem = ({ item: { uuid, firstName, lastName, onPress } }: { item: Friend & { onPress: () => void } }) => {
  return <ItemContainer key={uuid} onClick={onPress}>
    <Text name='friend' customText={`${firstName} ${lastName}`} />
  </ItemContainer>
}

const InviteFriends: React.FC<InviteFriendsProps> = ({ session, subscribedUsers, onClose }) => {
  const [friends, setFriends] = useState<Friend[]>(cache.friends);
  const [feedback, setFeedback] = useState('')
  const [invitedPeople, setInvitedPeople] = useState<string[]>([])

  useEffect(() => {
    listFriends().then(setFriends).catch(err => setFeedback(err.message))
  }, []);

  const friendsToInvite = React.useMemo(() => friends.filter(
    friend => !subscribedUsers.map(user => user.uuid).includes(friend.uuid) && !invitedPeople.includes(friend.uuid)
  ), [friends, invitedPeople, subscribedUsers])

  const handleInvite = React.useCallback((friendId: string) => {
    inviteToSession(session.uuid, friendId).then(() => setInvitedPeople(invited => [...invited, friendId])).catch(err => setFeedback(err.message))
  }, [session.uuid])

  const items = React.useMemo(() => friendsToInvite.map(friend => ({ ...friend, onPress: () => handleInvite(friend.uuid) })), [friendsToInvite, handleInvite])

  return (
    <Modal name='inviteFriends' onClose={onClose}>
      <List Item={FriendItem} items={items} type='Friends' />
      {feedback && <ErrorMessage>{feedback}</ErrorMessage>}
    </Modal>
  );
};

export default InviteFriends;
