import React, { useState } from 'react';
import Modal from '../components/Modal';
import Input from '../components/Input';
import Button from '../components/Button';
import { useProfile } from '../contexts/profileContext';
import { confirmPhoneNumberUpdate, updatePhoneNumber } from '../api';
import { ErrorMessage } from '../components/Text';

interface UpdatePhoneProps {
  onClose: () => void;
}

const UpdatePhone: React.FC<UpdatePhoneProps> = ({ onClose }) => {
  const { profile } = useProfile()
  const [phoneNumber, setPhoneNumber] = useState(profile?.phoneNumber || '');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [feedback, setFeedback] = useState('')

  // For now, we ignore the code validation
  React.useEffect(() => {
    isCodeSent && onClose()
  }, [isCodeSent, onClose])

  const handleSendCode = React.useCallback(() => {
    if(!phoneNumber) return
    updatePhoneNumber({ newPhoneNumber: phoneNumber }).then(() => setIsCodeSent(true)).catch(err => setFeedback(err.message))
  }, [phoneNumber])

  const handleSubmit = React.useCallback(() => {
    if(!phoneNumber || !confirmationCode) return
    confirmPhoneNumberUpdate({ newPhoneNumber: phoneNumber, code: confirmationCode }).then(onClose).catch(err => setFeedback(err.message))
  }, [confirmationCode, onClose, phoneNumber])

  return (
    <Modal onClose={onClose} name="updatePhoneNumber">
      <Input name="phoneNumber" value={phoneNumber} onChangeText={setPhoneNumber} />

      {isCodeSent ? (
        <>
          <Input name="confirmationCode" value={confirmationCode} onChangeText={setConfirmationCode} />
          <Button disabled={!phoneNumber} name='confirm' onClick={handleSubmit} />
        </>
      ) : (
        <Button disabled={!confirmationCode} name='submit' onClick={handleSendCode} />
      )}
      {feedback && <ErrorMessage>{feedback}</ErrorMessage>}
    </Modal>
  );
};

export default UpdatePhone;
