const geocode = `code_commune_INSEE,nom_commune_postal,code_postal,libelle_acheminement,ligne_5,latitude,longitude,code_commune,article,nom_commune,nom_commune_complet,code_departement,nom_departement,code_region,nom_region
1001,L ABERGEMENT CLEMENCIAT,1400,L ABERGEMENT CLEMENCIAT,,46.1534255214,4.92611354223,1,L',Abergement-Clémenciat,L'Abergement-Clémenciat,1,Ain,84,Auvergne-Rhône-Alpes
1002,L ABERGEMENT DE VAREY,1640,L ABERGEMENT DE VAREY,,46.0091878776,5.42801696363,2,L',Abergement-de-Varey,L'Abergement-de-Varey,1,Ain,84,Auvergne-Rhône-Alpes
1004,AMBERIEU EN BUGEY,1500,AMBERIEU EN BUGEY,,45.9608475114,5.3729257777,4,,Ambérieu-en-Bugey,Ambérieu-en-Bugey,1,Ain,84,Auvergne-Rhône-Alpes
1005,AMBERIEUX EN DOMBES,1330,AMBERIEUX EN DOMBES,,45.9961799872,4.91227250796,5,,Ambérieux-en-Dombes,Ambérieux-en-Dombes,1,Ain,84,Auvergne-Rhône-Alpes
1006,AMBLEON,1300,AMBLEON,,45.7494989044,5.59432017366,6,,Ambléon,Ambléon,1,Ain,84,Auvergne-Rhône-Alpes
1007,AMBRONAY,1500,AMBRONAY,,46.0055913782,5.35760660735,7,,Ambronay,Ambronay,1,Ain,84,Auvergne-Rhône-Alpes
1008,AMBUTRIX,1500,AMBUTRIX,,45.9367134524,5.3328092349,8,,Ambutrix,Ambutrix,1,Ain,84,Auvergne-Rhône-Alpes
1009,ANDERT ET CONDON,1300,ANDERT ET CONDON,,45.7873565333,5.65788307924,9,,Andert-et-Condon,Andert-et-Condon,1,Ain,84,Auvergne-Rhône-Alpes
1010,ANGLEFORT,1350,ANGLEFORT,,45.9093715116,5.79516005674,10,,Anglefort,Anglefort,1,Ain,84,Auvergne-Rhône-Alpes
1011,APREMONT,1100,APREMONT,,46.2054981558,5.65781475272,11,,Apremont,Apremont,1,Ain,84,Auvergne-Rhône-Alpes
1012,ARANC,1110,ARANC,,46.0015344029,5.51130637511,12,,Aranc,Aranc,1,Ain,84,Auvergne-Rhône-Alpes
1013,ARANDAS,1230,ARANDAS,,45.8908155275,5.49870439091,13,,Arandas,Arandas,1,Ain,84,Auvergne-Rhône-Alpes
1014,ARBENT,1100,ARBENT,,46.2834936005,5.69061651221,14,,Arbent,Arbent,1,Ain,84,Auvergne-Rhône-Alpes
1015,ARBOYS EN BUGEY,1300,ARBOYS EN BUGEY,ARBIGNIEU,45.7237621545,5.65263086429,15,,Arboys en Bugey,Arboys en Bugey,1,Ain,84,Auvergne-Rhône-Alpes
1015,ARBOYS EN BUGEY,1300,ARBOYS EN BUGEY,ST BOIS,45.7237621545,5.65263086429,15,,Arboys en Bugey,Arboys en Bugey,1,Ain,84,Auvergne-Rhône-Alpes
1016,ARBIGNY,1190,ARBIGNY,,46.4776762988,4.9599416863,16,,Arbigny,Arbigny,1,Ain,84,Auvergne-Rhône-Alpes
1017,ARGIS,1230,ARGIS,,45.9337182132,5.48251100314,17,,Argis,Argis,1,Ain,84,Auvergne-Rhône-Alpes
1019,ARMIX,1510,ARMIX,,45.8541838459,5.58357838695,19,,Armix,Armix,1,Ain,84,Auvergne-Rhône-Alpes
1021,ARS SUR FORMANS,1480,ARS SUR FORMANS,,45.9934611,4.82199649494,21,,Ars-sur-Formans,Ars-sur-Formans,1,Ain,84,Auvergne-Rhône-Alpes
1022,ARTEMARE,1510,ARTEMARE,,45.8695891748,5.69065283943,22,,Artemare,Artemare,1,Ain,84,Auvergne-Rhône-Alpes
1023,ASNIERES SUR SAONE,1570,ASNIERES SUR SAONE,,46.3857281418,4.88387478623,23,,Asnières-sur-Saône,Asnières-sur-Saône,1,Ain,84,Auvergne-Rhône-Alpes
1024,ATTIGNAT,1340,ATTIGNAT,,46.2861802203,5.1795233845,24,,Attignat,Attignat,1,Ain,84,Auvergne-Rhône-Alpes
1025,BAGE LA VILLE,1380,BAGE LA VILLE,,46.3233203302,4.9528678933,25,,Bâgé-Dommartin,Bâgé-Dommartin,1,Ain,84,Auvergne-Rhône-Alpes
1026,BAGE LE CHATEL,1380,BAGE LE CHATEL,,46.3077069231,4.92862084189,26,,Bâgé-le-Châtel,Bâgé-le-Châtel,1,Ain,84,Auvergne-Rhône-Alpes
1027,BALAN,1360,BALAN,,45.8311226453,5.10780019249,27,,Balan,Balan,1,Ain,84,Auvergne-Rhône-Alpes
1028,BANEINS,1990,BANEINS,,46.1141214445,4.90146475101,28,,Baneins,Baneins,1,Ain,84,Auvergne-Rhône-Alpes
1029,BEAUPONT,1270,BEAUPONT,,46.4288360203,5.26003405676,29,,Beaupont,Beaupont,1,Ain,84,Auvergne-Rhône-Alpes
1030,BEAUREGARD,1480,BEAUREGARD,,46.0008580204,4.75600664694,30,,Beauregard,Beauregard,1,Ain,84,Auvergne-Rhône-Alpes
1031,BELLIGNAT,1100,BELLIGNAT,,46.2373497134,5.6360052884,31,,Bellignat,Bellignat,1,Ain,84,Auvergne-Rhône-Alpes
1032,BELIGNEUX,1360,BELIGNEUX,,45.8591464826,5.13989505865,32,,Béligneux,Béligneux,1,Ain,84,Auvergne-Rhône-Alpes
1032,BELIGNEUX,1360,BELIGNEUX,CAMP DE LA VALBONNE,45.8591464826,5.13989505865,32,,Béligneux,Béligneux,1,Ain,84,Auvergne-Rhône-Alpes
1032,BELIGNEUX,1360,BELIGNEUX,CHANES,45.8591464826,5.13989505865,32,,Béligneux,Béligneux,1,Ain,84,Auvergne-Rhône-Alpes
1032,BELIGNEUX,1360,BELIGNEUX,LA VALBONNE,45.8591464826,5.13989505865,32,,Béligneux,Béligneux,1,Ain,84,Auvergne-Rhône-Alpes
1033,BELLEGARDE SUR VALSERINE,1200,BELLEGARDE SUR VALSERINE,,46.1067901755,5.83202736464,33,,Bellegarde-sur-Valserine,Bellegarde-sur-Valserine,1,Ain,84,Auvergne-Rhône-Alpes
1034,BELLEY,1300,BELLEY,,45.7494697806,5.68412365526,34,,Belley,Belley,1,Ain,84,Auvergne-Rhône-Alpes
1035,BELLEYDOUX,1130,BELLEYDOUX,,46.2530316409,5.78471723698,35,,Belleydoux,Belleydoux,1,Ain,84,Auvergne-Rhône-Alpes
1036,BELMONT LUTHEZIEU,1260,BELMONT LUTHEZIEU,,45.8847475883,5.65115788055,36,,Belmont-Luthézieu,Belmont-Luthézieu,1,Ain,84,Auvergne-Rhône-Alpes
1036,BELMONT LUTHEZIEU,1260,BELMONT LUTHEZIEU,LUTHEZIEU,45.8847475883,5.65115788055,36,,Belmont-Luthézieu,Belmont-Luthézieu,1,Ain,84,Auvergne-Rhône-Alpes
1037,BENONCES,1470,BENONCES,,45.8374974718,5.48285330863,37,,Bénonces,Bénonces,1,Ain,84,Auvergne-Rhône-Alpes
1038,BENY,1370,BENY,,46.3188629218,5.28253433405,38,,Bény,Bény,1,Ain,84,Auvergne-Rhône-Alpes
1039,BEON,1350,BEON,,45.8568920052,5.75220315052,39,,Béon,Béon,1,Ain,84,Auvergne-Rhône-Alpes
1040,BEREZIAT,1340,BEREZIAT,,46.3674371012,5.04021655965,40,,Béréziat,Béréziat,1,Ain,84,Auvergne-Rhône-Alpes
1041,BETTANT,1500,BETTANT,,45.9368718303,5.36667617009,41,,Bettant,Bettant,1,Ain,84,Auvergne-Rhône-Alpes
1042,BEY,1290,BEY,,46.2191748844,4.84642690241,42,,Bey,Bey,1,Ain,84,Auvergne-Rhône-Alpes
1043,BEYNOST,1700,BEYNOST,,45.8390325448,4.99738002182,43,,Beynost,Beynost,1,Ain,84,Auvergne-Rhône-Alpes
1044,BILLIAT,1200,BILLIAT,,46.0791346715,5.76591818163,44,,Billiat,Billiat,1,Ain,84,Auvergne-Rhône-Alpes
1045,BIRIEUX,1330,BIRIEUX,,45.9533995502,5.03666479984,45,,Birieux,Birieux,1,Ain,84,Auvergne-Rhône-Alpes
1046,BIZIAT,1290,BIZIAT,,46.2266794269,4.94084673168,46,,Biziat,Biziat,1,Ain,84,Auvergne-Rhône-Alpes
1047,BLYES,1150,BLYES,,45.8469686113,5.25166055884,47,,Blyes,Blyes,1,Ain,84,Auvergne-Rhône-Alpes
1049,LA BOISSE,1120,LA BOISSE,,45.8446213549,5.02783224111,49,La,Boisse,La Boisse,1,Ain,84,Auvergne-Rhône-Alpes
1050,BOISSEY,1190,BOISSEY,,46.3727973545,4.9989764059,50,,Boissey,Boissey,1,Ain,84,Auvergne-Rhône-Alpes
1050,BOISSEY,1380,BOISSEY,,46.3727973545,4.9989764059,50,,Boissey,Boissey,1,Ain,84,Auvergne-Rhône-Alpes
1051,BOLOZON,1450,BOLOZON,,46.1960338503,5.47095075411,51,,Bolozon,Bolozon,1,Ain,84,Auvergne-Rhône-Alpes
1052,BOULIGNEUX,1330,BOULIGNEUX,,46.0232774841,4.99252709276,52,,Bouligneux,Bouligneux,1,Ain,84,Auvergne-Rhône-Alpes
1053,BOURG EN BRESSE,1000,BOURG EN BRESSE,,46.2051520382,5.24602125501,53,,Bourg-en-Bresse,Bourg-en-Bresse,1,Ain,84,Auvergne-Rhône-Alpes
1053,BOURG EN BRESSE,1000,BOURG EN BRESSE,BROU,46.2051520382,5.24602125501,53,,Bourg-en-Bresse,Bourg-en-Bresse,1,Ain,84,Auvergne-Rhône-Alpes
1054,BOURG ST CHRISTOPHE,1800,BOURG ST CHRISTOPHE,,45.8861934456,5.14349196896,54,,Bourg-Saint-Christophe,Bourg-Saint-Christophe,1,Ain,84,Auvergne-Rhône-Alpes
1054,BOURG ST CHRISTOPHE,1800,BOURG ST CHRISTOPHE,MARFOZ,45.8861934456,5.14349196896,54,,Bourg-Saint-Christophe,Bourg-Saint-Christophe,1,Ain,84,Auvergne-Rhône-Alpes
1056,BOYEUX ST JEROME,1640,BOYEUX ST JEROME,,46.0363677631,5.46322744717,56,,Boyeux-Saint-Jérôme,Boyeux-Saint-Jérôme,1,Ain,84,Auvergne-Rhône-Alpes
1057,BOZ,1190,BOZ,,46.4051688134,4.9135797178,57,,Boz,Boz,1,Ain,84,Auvergne-Rhône-Alpes
1058,BREGNIER CORDON,1300,BREGNIER CORDON,,45.6416854652,5.61653371,58,,Brégnier-Cordon,Brégnier-Cordon,1,Ain,84,Auvergne-Rhône-Alpes
1059,BRENAZ,1260,BRENAZ,,45.9500082947,5.7371892334,59,,Brénaz,Brénaz,1,Ain,84,Auvergne-Rhône-Alpes
1060,BRENOD,1110,BRENOD,,46.0759940484,5.61418361314,60,,Brénod,Brénod,1,Ain,84,Auvergne-Rhône-Alpes
1061,BRENS,1300,BRENS,,45.7135069062,5.68998154517,61,,Brens,Brens,1,Ain,84,Auvergne-Rhône-Alpes
1062,BRESSOLLES,1360,BRESSOLLES,,45.869423064,5.1009428902,62,,Bressolles,Bressolles,1,Ain,84,Auvergne-Rhône-Alpes
1062,BRESSOLLES,1360,BRESSOLLES,LA LECHERE,45.869423064,5.1009428902,62,,Bressolles,Bressolles,1,Ain,84,Auvergne-Rhône-Alpes
1063,BRION,1460,BRION,,46.1686327301,5.54656422194,63,,Brion,Brion,1,Ain,84,Auvergne-Rhône-Alpes
1064,BRIORD,1470,BRIORD,,45.7708330939,5.48537461884,64,,Briord,Briord,1,Ain,84,Auvergne-Rhône-Alpes
1065,BUELLAS,1310,BUELLAS,,46.2104135162,5.14487465017,65,,Buellas,Buellas,1,Ain,84,Auvergne-Rhône-Alpes
1065,BUELLAS,1310,BUELLAS,CORGENON,46.2104135162,5.14487465017,65,,Buellas,Buellas,1,Ain,84,Auvergne-Rhône-Alpes
1066,LA BURBANCHE,1510,LA BURBANCHE,,45.8598709332,5.54609714598,66,La,Burbanche,La Burbanche,1,Ain,84,Auvergne-Rhône-Alpes
1067,CEIGNES,1430,CEIGNES,,46.1256533435,5.49457379202,67,,Ceignes,Ceignes,1,Ain,84,Auvergne-Rhône-Alpes
1068,CERDON,1450,CERDON,,46.0720202633,5.47577586118,68,,Cerdon,Cerdon,1,Ain,84,Auvergne-Rhône-Alpes
1069,CERTINES,1240,CERTINES,,46.1337950959,5.26239539372,69,,Certines,Certines,1,Ain,84,Auvergne-Rhône-Alpes
1071,CESSY,1170,CESSY,,46.315689376,6.0779844362,71,,Cessy,Cessy,1,Ain,84,Auvergne-Rhône-Alpes
1072,CEYZERIAT,1250,CEYZERIAT,,46.1832170988,5.32007207542,72,,Ceyzériat,Ceyzériat,1,Ain,84,Auvergne-Rhône-Alpes
1073,CEYZERIEU,1350,CEYZERIEU,,45.8335530148,5.71933090966,73,,Ceyzérieu,Ceyzérieu,1,Ain,84,Auvergne-Rhône-Alpes
1074,CHALAMONT,1320,CHALAMONT,,46.0076491739,5.16936568,74,,Chalamont,Chalamont,1,Ain,84,Auvergne-Rhône-Alpes
1075,CHALEINS,1480,CHALEINS,,46.0340545373,4.79853838574,75,,Chaleins,Chaleins,1,Ain,84,Auvergne-Rhône-Alpes
1076,CHALEY,1230,CHALEY,,45.9533367734,5.53682505273,76,,Chaley,Chaley,1,Ain,84,Auvergne-Rhône-Alpes
1077,CHALLES LA MONTAGNE,1450,CHALLES LA MONTAGNE,,46.1221539654,5.45755377201,77,,Challes-la-Montagne,Challes-la-Montagne,1,Ain,84,Auvergne-Rhône-Alpes
1078,CHALLEX,1630,CHALLEX,,46.1768358327,5.97115092939,78,,Challex,Challex,1,Ain,84,Auvergne-Rhône-Alpes
1079,CHAMPAGNE EN VALROMEY,1260,CHAMPAGNE EN VALROMEY,,45.9316447397,5.69321890072,79,,Champagne-en-Valromey,Champagne-en-Valromey,1,Ain,84,Auvergne-Rhône-Alpes
1079,CHAMPAGNE EN VALROMEY,1260,CHAMPAGNE EN VALROMEY,LILIGNOD,45.9316447397,5.69321890072,79,,Champagne-en-Valromey,Champagne-en-Valromey,1,Ain,84,Auvergne-Rhône-Alpes
1079,CHAMPAGNE EN VALROMEY,1260,CHAMPAGNE EN VALROMEY,PASSIN,45.9316447397,5.69321890072,79,,Champagne-en-Valromey,Champagne-en-Valromey,1,Ain,84,Auvergne-Rhône-Alpes
1080,CHAMPDOR CORCELLES,1110,CHAMPDOR CORCELLES,,46.0198844188,5.60008759722,80,,Champdor-Corcelles,Champdor-Corcelles,1,Ain,84,Auvergne-Rhône-Alpes
1080,CHAMPDOR CORCELLES,1110,CHAMPDOR CORCELLES,CORCELLES,46.0198844188,5.60008759722,80,,Champdor-Corcelles,Champdor-Corcelles,1,Ain,84,Auvergne-Rhône-Alpes
1081,CHAMPFROMIER,1410,CHAMPFROMIER,,46.220365768,5.82233573131,81,,Champfromier,Champfromier,1,Ain,84,Auvergne-Rhône-Alpes
1082,CHANAY,1420,CHANAY,,46.005695299,5.76893393185,82,,Chanay,Chanay,1,Ain,84,Auvergne-Rhône-Alpes
1083,CHANEINS,1990,CHANEINS,,46.0934984191,4.85473364691,83,,Chaneins,Chaneins,1,Ain,84,Auvergne-Rhône-Alpes
1084,CHANOZ CHATENAY,1400,CHANOZ CHATENAY,,46.1861812234,5.02807719246,84,,Chanoz-Châtenay,Chanoz-Châtenay,1,Ain,84,Auvergne-Rhône-Alpes
1085,LA CHAPELLE DU CHATELARD,1240,LA CHAPELLE DU CHATELARD,,46.0744536905,5.02162055898,85,La,Chapelle-du-Châtelard,La Chapelle-du-Châtelard,1,Ain,84,Auvergne-Rhône-Alpes
1087,CHARIX,1130,CHARIX,,46.1856344066,5.6811896084,87,,Charix,Charix,1,Ain,84,Auvergne-Rhône-Alpes
1088,CHARNOZ SUR AIN,1800,CHARNOZ SUR AIN,,45.8736786869,5.2211032563,88,,Charnoz-sur-Ain,Charnoz-sur-Ain,1,Ain,84,Auvergne-Rhône-Alpes
1089,CHATEAU GAILLARD,1500,CHATEAU GAILLARD,,45.9730390239,5.30754273532,89,,Château-Gaillard,Château-Gaillard,1,Ain,84,Auvergne-Rhône-Alpes
1090,CHATENAY,1320,CHATENAY,,46.0259402155,5.2048590395,90,,Châtenay,Châtenay,1,Ain,84,Auvergne-Rhône-Alpes
1091,CHATILLON EN MICHAILLE,1200,CHATILLON EN MICHAILLE,,46.1293337743,5.770176021,91,,Châtillon-en-Michaille,Châtillon-en-Michaille,1,Ain,84,Auvergne-Rhône-Alpes
1091,CHATILLON EN MICHAILLE,1200,CHATILLON EN MICHAILLE,OCHIAZ,46.1293337743,5.770176021,91,,Châtillon-en-Michaille,Châtillon-en-Michaille,1,Ain,84,Auvergne-Rhône-Alpes
1091,CHATILLON EN MICHAILLE,1200,CHATILLON EN MICHAILLE,VOUVRAY,46.1293337743,5.770176021,91,,Châtillon-en-Michaille,Châtillon-en-Michaille,1,Ain,84,Auvergne-Rhône-Alpes
1092,CHATILLON LA PALUD,1320,CHATILLON LA PALUD,,45.9748017433,5.2416027725,92,,Châtillon-la-Palud,Châtillon-la-Palud,1,Ain,84,Auvergne-Rhône-Alpes
1093,CHATILLON SUR CHALARONNE,1400,CHATILLON SUR CHALARONNE,,46.1232390685,4.95810003714,93,,Châtillon-sur-Chalaronne,Châtillon-sur-Chalaronne,1,Ain,84,Auvergne-Rhône-Alpes
1094,CHAVANNES SUR REYSSOUZE,1190,CHAVANNES SUR REYSSOUZE,,46.4379785193,5.00693749662,94,,Chavannes-sur-Reyssouze,Chavannes-sur-Reyssouze,1,Ain,84,Auvergne-Rhône-Alpes
1095,CHAVANNES SUR SURAN,1250,CHAVANNES SUR SURAN,,46.2750157946,5.41649423093,95,,Nivigne et Suran,Nivigne et Suran,1,Ain,84,Auvergne-Rhône-Alpes
1096,CHAVEYRIAT,1660,CHAVEYRIAT,,46.1991513767,5.0564069258,96,,Chaveyriat,Chaveyriat,1,Ain,84,Auvergne-Rhône-Alpes
1097,CHAVORNAY,1510,CHAVORNAY,,45.8824049535,5.72499814516,97,,Chavornay,Chavornay,1,Ain,84,Auvergne-Rhône-Alpes
1098,CHAZEY BONS,1300,CHAZEY BONS,,45.7925770617,5.68477063188,98,,Chazey-Bons,Chazey-Bons,1,Ain,84,Auvergne-Rhône-Alpes
1099,CHAZEY SUR AIN,1150,CHAZEY SUR AIN,,45.8929448968,5.25528498524,99,,Chazey-sur-Ain,Chazey-sur-Ain,1,Ain,84,Auvergne-Rhône-Alpes
1100,CHEIGNIEU LA BALME,1510,CHEIGNIEU LA BALME,,45.8227204334,5.6050023969,100,,Cheignieu-la-Balme,Cheignieu-la-Balme,1,Ain,84,Auvergne-Rhône-Alpes
1101,CHEVILLARD,1430,CHEVILLARD,,46.1103512578,5.58116056005,101,,Chevillard,Chevillard,1,Ain,84,Auvergne-Rhône-Alpes
1102,CHEVROUX,1190,CHEVROUX,,46.38249255,4.96288275972,102,,Chevroux,Chevroux,1,Ain,84,Auvergne-Rhône-Alpes
1103,CHEVRY,1170,CHEVRY,,46.283152935,6.04707941462,103,,Chevry,Chevry,1,Ain,84,Auvergne-Rhône-Alpes
1104,CHEZERY FORENS,1200,CHEZERY FORENS,,46.222573373,5.87248105275,104,,Chézery-Forens,Chézery-Forens,1,Ain,84,Auvergne-Rhône-Alpes
1104,CHEZERY FORENS,1410,CHEZERY FORENS,,46.222573373,5.87248105275,104,,Chézery-Forens,Chézery-Forens,1,Ain,84,Auvergne-Rhône-Alpes
1105,CIVRIEUX,1390,CIVRIEUX,,45.922467454,4.88633786877,105,,Civrieux,Civrieux,1,Ain,84,Auvergne-Rhône-Alpes
1106,CIZE,1250,CIZE,,46.2003848172,5.45082526739,106,,Cize,Cize,1,Ain,84,Auvergne-Rhône-Alpes
1107,CLEYZIEU,1230,CLEYZIEU,,45.902146623,5.43272695486,107,,Cleyzieu,Cleyzieu,1,Ain,84,Auvergne-Rhône-Alpes
1108,COLIGNY,1270,COLIGNY,,46.3866637865,5.3308227014,108,,Coligny,Coligny,1,Ain,84,Auvergne-Rhône-Alpes
1109,COLLONGES,1550,COLLONGES,,46.1419227898,5.90040256654,109,,Collonges,Collonges,1,Ain,84,Auvergne-Rhône-Alpes
1110,COLOMIEU,1300,COLOMIEU,,45.7361469025,5.62670101041,110,,Colomieu,Colomieu,1,Ain,84,Auvergne-Rhône-Alpes
1111,CONAND,1230,CONAND,,45.8834810846,5.47541328981,111,,Conand,Conand,1,Ain,84,Auvergne-Rhône-Alpes
1112,CONDAMINE,1430,CONDAMINE,,46.105831161,5.55568206762,112,,Condamine,Condamine,1,Ain,84,Auvergne-Rhône-Alpes
1113,CONDEISSIAT,1400,CONDEISSIAT,,46.1538469834,5.08437171701,113,,Condeissiat,Condeissiat,1,Ain,84,Auvergne-Rhône-Alpes
1114,CONFORT,1200,CONFORT,,46.1543700156,5.83319395258,114,,Confort,Confort,1,Ain,84,Auvergne-Rhône-Alpes
1115,CONFRANCON,1310,CONFRANCON,,46.270304175,5.05775217893,115,,Confrançon,Confrançon,1,Ain,84,Auvergne-Rhône-Alpes
1116,CONTREVOZ,1300,CONTREVOZ,,45.8009403071,5.62087768159,116,,Contrevoz,Contrevoz,1,Ain,84,Auvergne-Rhône-Alpes
1117,CONZIEU,1300,CONZIEU,,45.7253102173,5.60442351043,117,,Conzieu,Conzieu,1,Ain,84,Auvergne-Rhône-Alpes
1118,CORBONOD,1420,CORBONOD,,45.9659473187,5.78961970355,118,,Corbonod,Corbonod,1,Ain,84,Auvergne-Rhône-Alpes
1121,CORLIER,1110,CORLIER,,46.0336885573,5.49635755029,121,,Corlier,Corlier,1,Ain,84,Auvergne-Rhône-Alpes
1122,CORMARANCHE EN BUGEY,1110,CORMARANCHE EN BUGEY,,45.9442772826,5.60860828487,122,,Cormaranche-en-Bugey,Cormaranche-en-Bugey,1,Ain,84,Auvergne-Rhône-Alpes
1123,CORMORANCHE SUR SAONE,1290,CORMORANCHE SUR SAONE,,46.2403530179,4.8276569593,123,,Cormoranche-sur-Saône,Cormoranche-sur-Saône,1,Ain,84,Auvergne-Rhône-Alpes
1124,CORMOZ,1560,CORMOZ,,46.4336632942,5.22181275755,124,,Cormoz,Cormoz,1,Ain,84,Auvergne-Rhône-Alpes
1125,CORVEISSIAT,1250,CORVEISSIAT,,46.2505107235,5.47152467919,125,,Corveissiat,Corveissiat,1,Ain,84,Auvergne-Rhône-Alpes
1127,COURMANGOUX,1370,COURMANGOUX,,46.3267864521,5.35558241812,127,,Courmangoux,Courmangoux,1,Ain,84,Auvergne-Rhône-Alpes
1128,COURTES,1560,COURTES,,46.4570082032,5.11128546723,128,,Courtes,Courtes,1,Ain,84,Auvergne-Rhône-Alpes
1129,CRANS,1320,CRANS,,45.9663793103,5.19490390871,129,,Crans,Crans,1,Ain,84,Auvergne-Rhône-Alpes
1130,CRAS SUR REYSSOUZE,1340,CRAS SUR REYSSOUZE,,46.3130831891,5.17796707174,130,,Cras-sur-Reyssouze,Cras-sur-Reyssouze,1,Ain,84,Auvergne-Rhône-Alpes
1133,CRESSIN ROCHEFORT,1350,CRESSIN ROCHEFORT,,45.7826434885,5.76546256637,133,,Cressin-Rochefort,Cressin-Rochefort,1,Ain,84,Auvergne-Rhône-Alpes
1134,CROTTET,1290,CROTTET,,46.283072336,4.87747971182,134,,Crottet,Crottet,1,Ain,84,Auvergne-Rhône-Alpes
1134,CROTTET,1750,CROTTET,,46.283072336,4.87747971182,134,,Crottet,Crottet,1,Ain,84,Auvergne-Rhône-Alpes
1135,CROZET,1170,CROZET,,46.2915659686,5.99510417092,135,,Crozet,Crozet,1,Ain,84,Auvergne-Rhône-Alpes
1136,CRUZILLES LES MEPILLAT,1290,CRUZILLES LES MEPILLAT,,46.2220588715,4.8752193513,136,,Cruzilles-lès-Mépillat,Cruzilles-lès-Mépillat,1,Ain,84,Auvergne-Rhône-Alpes
1138,CULOZ,1350,CULOZ,,45.8559966843,5.78398308932,138,,Culoz,Culoz,1,Ain,84,Auvergne-Rhône-Alpes
1139,CURCIAT DONGALON,1560,CURCIAT DONGALON,,46.4885456881,5.16344336837,139,,Curciat-Dongalon,Curciat-Dongalon,1,Ain,84,Auvergne-Rhône-Alpes
1140,CURTAFOND,1310,CURTAFOND,,46.273150382,5.1020896267,140,,Curtafond,Curtafond,1,Ain,84,Auvergne-Rhône-Alpes
1141,CUZIEU,1300,CUZIEU,,45.8200002803,5.6765554896,141,,Cuzieu,Cuzieu,1,Ain,84,Auvergne-Rhône-Alpes
1142,DAGNEUX,1120,DAGNEUX,,45.8531616918,5.07289166803,142,,Dagneux,Dagneux,1,Ain,84,Auvergne-Rhône-Alpes
1143,DIVONNE LES BAINS,1220,DIVONNE LES BAINS,,46.3756333495,6.1158647611,143,,Divonne-les-Bains,Divonne-les-Bains,1,Ain,84,Auvergne-Rhône-Alpes
1144,DOMMARTIN,1380,DOMMARTIN,,46.33627762,4.99863894,144,,Dommartin,Dommartin,1,Ain,84,Auvergne-Rhône-Alpes
1145,DOMPIERRE SUR VEYLE,1240,DOMPIERRE SUR VEYLE,,46.0667883703,5.21665199666,145,,Dompierre-sur-Veyle,Dompierre-sur-Veyle,1,Ain,84,Auvergne-Rhône-Alpes
1146,DOMPIERRE SUR CHALARONNE,1400,DOMPIERRE SUR CHALARONNE,,46.1415406621,4.90141714714,146,,Dompierre-sur-Chalaronne,Dompierre-sur-Chalaronne,1,Ain,84,Auvergne-Rhône-Alpes
1147,DOMSURE,1270,DOMSURE,,46.4244066427,5.29313479651,147,,Domsure,Domsure,1,Ain,84,Auvergne-Rhône-Alpes
1148,DORTAN,1590,DORTAN,,46.3133221173,5.64146584117,148,,Dortan,Dortan,1,Ain,84,Auvergne-Rhône-Alpes
1149,DOUVRES,1500,DOUVRES,,45.984297392,5.37106901673,149,,Douvres,Douvres,1,Ain,84,Auvergne-Rhône-Alpes
1150,DROM,1250,DROM,,46.2196603059,5.36799994918,150,,Drom,Drom,1,Ain,84,Auvergne-Rhône-Alpes
1151,DRUILLAT,1160,DRUILLAT,,46.067579544,5.29361666429,151,,Druillat,Druillat,1,Ain,84,Auvergne-Rhône-Alpes
1152,ECHALLON,1130,ECHALLON,,46.2271364137,5.73330448395,152,,Échallon,Échallon,1,Ain,84,Auvergne-Rhône-Alpes
1153,ECHENEVEX,1170,ECHENEVEX,,46.3160845207,6.01926187996,153,,Échenevex,Échenevex,1,Ain,84,Auvergne-Rhône-Alpes
1154,ETREZ,1340,ETREZ,,46.3380891758,5.19286414645,154,,Étrez,Étrez,1,Ain,84,Auvergne-Rhône-Alpes
1155,EVOSGES,1230,EVOSGES,,45.965147537,5.50283599885,155,,Évosges,Évosges,1,Ain,84,Auvergne-Rhône-Alpes
1156,FARAMANS,1800,FARAMANS,,45.9104942091,5.1249258559,156,,Faramans,Faramans,1,Ain,84,Auvergne-Rhône-Alpes
1157,FAREINS,1480,FAREINS,,46.020997974,4.7620080898,157,,Fareins,Fareins,1,Ain,84,Auvergne-Rhône-Alpes
1158,FARGES,1550,FARGES,,46.166230455,5.90019477201,158,,Farges,Farges,1,Ain,84,Auvergne-Rhône-Alpes
1159,FEILLENS,1570,FEILLENS,,46.3365833672,4.88507050971,159,,Feillens,Feillens,1,Ain,84,Auvergne-Rhône-Alpes
1160,FERNEY VOLTAIRE,1210,FERNEY VOLTAIRE,,46.2519789243,6.10826403805,160,,Ferney-Voltaire,Ferney-Voltaire,1,Ain,84,Auvergne-Rhône-Alpes
1162,FLAXIEU,1350,FLAXIEU,,45.8113298005,5.74459048839,162,,Flaxieu,Flaxieu,1,Ain,84,Auvergne-Rhône-Alpes
1163,FOISSIAT,1340,FOISSIAT,,46.3784759782,5.18483641417,163,,Foissiat,Foissiat,1,Ain,84,Auvergne-Rhône-Alpes
1165,FRANCHELEINS,1090,FRANCHELEINS,,46.0735946256,4.81293575559,165,,Francheleins,Francheleins,1,Ain,84,Auvergne-Rhône-Alpes
1165,FRANCHELEINS,1090,FRANCHELEINS,AMAREINS,46.0735946256,4.81293575559,165,,Francheleins,Francheleins,1,Ain,84,Auvergne-Rhône-Alpes
1165,FRANCHELEINS,1090,FRANCHELEINS,CESSEINS,46.0735946256,4.81293575559,165,,Francheleins,Francheleins,1,Ain,84,Auvergne-Rhône-Alpes
1166,FRANS,1480,FRANS,,45.9939065077,4.78033196905,166,,Frans,Frans,1,Ain,84,Auvergne-Rhône-Alpes
1167,GARNERANS,1140,GARNERANS,,46.2136224825,4.82843188743,167,,Garnerans,Garnerans,1,Ain,84,Auvergne-Rhône-Alpes
1169,GENOUILLEUX,1090,GENOUILLEUX,,46.1201611728,4.79288195016,169,,Genouilleux,Genouilleux,1,Ain,84,Auvergne-Rhône-Alpes
1170,BEARD GEOVREISSIAT,1460,BEARD GEOVREISSIAT,,46.1896733539,5.55139781353,170,,Béard-Géovreissiat,Béard-Géovreissiat,1,Ain,84,Auvergne-Rhône-Alpes
1171,GEOVREISSET,1100,GEOVREISSET,,46.2508621615,5.61271973915,171,,Géovreisset,Géovreisset,1,Ain,84,Auvergne-Rhône-Alpes
1172,GERMAGNAT,1250,GERMAGNAT,,46.3054983344,5.45012798616,172,,Germagnat,Germagnat,1,Ain,84,Auvergne-Rhône-Alpes
1173,GEX,1170,GEX,,46.3471891747,6.04650555568,173,,Gex,Gex,1,Ain,84,Auvergne-Rhône-Alpes
1174,GIRON,1130,GIRON,,46.2227121191,5.78049809256,174,,Giron,Giron,1,Ain,84,Auvergne-Rhône-Alpes
1175,GORREVOD,1190,GORREVOD,,46.414943437,4.95120595427,175,,Gorrevod,Gorrevod,1,Ain,84,Auvergne-Rhône-Alpes
1177,GRAND CORENT,1250,GRAND CORENT,,46.207387937,5.43318666027,177,,Grand-Corent,Grand-Corent,1,Ain,84,Auvergne-Rhône-Alpes
1179,GRIEGES,1290,GRIEGES,,46.2633634407,4.84621318847,179,,Grièges,Grièges,1,Ain,84,Auvergne-Rhône-Alpes
1180,GRILLY,1220,GRILLY,,46.3318810665,6.11366767978,180,,Grilly,Grilly,1,Ain,84,Auvergne-Rhône-Alpes
1181,GROISSIAT,1100,GROISSIAT,,46.2260039429,5.61095413441,181,,Groissiat,Groissiat,1,Ain,84,Auvergne-Rhône-Alpes
1183,GUEREINS,1090,GUEREINS,,46.106624674,4.78159420118,183,,Guéreins,Guéreins,1,Ain,84,Auvergne-Rhône-Alpes
1184,HAUTECOURT ROMANECHE,1250,HAUTECOURT ROMANECHE,,46.1611241927,5.42944457639,184,,Hautecourt-Romanèche,Hautecourt-Romanèche,1,Ain,84,Auvergne-Rhône-Alpes
1184,HAUTECOURT ROMANECHE,1250,HAUTECOURT ROMANECHE,ROMANECHE,46.1611241927,5.42944457639,184,,Hautecourt-Romanèche,Hautecourt-Romanèche,1,Ain,84,Auvergne-Rhône-Alpes
1185,HAUTEVILLE LOMPNES,1110,HAUTEVILLE LOMPNES,,45.9696520061,5.57627261783,185,,Hauteville-Lompnes,Hauteville-Lompnes,1,Ain,84,Auvergne-Rhône-Alpes
1186,HOSTIAZ,1110,HOSTIAZ,,45.9061631889,5.54077986031,186,,Hostiaz,Hostiaz,1,Ain,84,Auvergne-Rhône-Alpes
1187,HAUT VALROMEY,1260,HAUT VALROMEY,HOTONNES,46.0317412192,5.70267027388,187,,Haut Valromey,Haut Valromey,1,Ain,84,Auvergne-Rhône-Alpes
1187,HAUT VALROMEY,1260,HAUT VALROMEY,LE GRAND ABERGEMENT,46.0317412192,5.70267027388,187,,Haut Valromey,Haut Valromey,1,Ain,84,Auvergne-Rhône-Alpes
1187,HAUT VALROMEY,1260,HAUT VALROMEY,LE PETIT ABERGEMENT,46.0317412192,5.70267027388,187,,Haut Valromey,Haut Valromey,1,Ain,84,Auvergne-Rhône-Alpes
1187,HAUT VALROMEY,1260,HAUT VALROMEY,SONGIEU,46.0317412192,5.70267027388,187,,Haut Valromey,Haut Valromey,1,Ain,84,Auvergne-Rhône-Alpes
1188,ILLIAT,1140,ILLIAT,,46.1866986838,4.88840344003,188,,Illiat,Illiat,1,Ain,84,Auvergne-Rhône-Alpes
1189,INJOUX GENISSIAT,1200,INJOUX GENISSIAT,,46.0497734524,5.75991363273,189,,Injoux-Génissiat,Injoux-Génissiat,1,Ain,84,Auvergne-Rhône-Alpes
1189,INJOUX GENISSIAT,1200,INJOUX GENISSIAT,CRAZ,46.0497734524,5.75991363273,189,,Injoux-Génissiat,Injoux-Génissiat,1,Ain,84,Auvergne-Rhône-Alpes
1190,INNIMOND,1680,INNIMOND,,45.7919647191,5.57389398559,190,,Innimond,Innimond,1,Ain,84,Auvergne-Rhône-Alpes
1191,IZENAVE,1430,IZENAVE,,46.0381623415,5.52447901164,191,,Izenave,Izenave,1,Ain,84,Auvergne-Rhône-Alpes
1192,IZERNORE,1580,IZERNORE,,46.2179780137,5.56258251719,192,,Izernore,Izernore,1,Ain,84,Auvergne-Rhône-Alpes
1193,IZIEU,1300,IZIEU,,45.6557496407,5.63909140794,193,,Izieu,Izieu,1,Ain,84,Auvergne-Rhône-Alpes
1194,JASSANS RIOTTIER,1480,JASSANS RIOTTIER,,45.9777239103,4.75984637871,194,,Jassans-Riottier,Jassans-Riottier,1,Ain,84,Auvergne-Rhône-Alpes
1195,JASSERON,1250,JASSERON,,46.2150037064,5.31215372338,195,,Jasseron,Jasseron,1,Ain,84,Auvergne-Rhône-Alpes
1196,JAYAT,1340,JAYAT,,46.3660828991,5.11429701617,196,,Jayat,Jayat,1,Ain,84,Auvergne-Rhône-Alpes
1197,JOURNANS,1250,JOURNANS,,46.1464982407,5.33452833151,197,,Journans,Journans,1,Ain,84,Auvergne-Rhône-Alpes
1198,JOYEUX,1800,JOYEUX,,45.9499899849,5.10261326872,198,,Joyeux,Joyeux,1,Ain,84,Auvergne-Rhône-Alpes
1199,JUJURIEUX,1640,JUJURIEUX,,46.0497041467,5.41094771899,199,,Jujurieux,Jujurieux,1,Ain,84,Auvergne-Rhône-Alpes
1200,LABALME,1450,LABALME,,46.0932491295,5.49438685213,200,,Labalme,Labalme,1,Ain,84,Auvergne-Rhône-Alpes
1202,LAGNIEU,1150,LAGNIEU,,45.8917015798,5.32917518895,202,,Lagnieu,Lagnieu,1,Ain,84,Auvergne-Rhône-Alpes
1203,LAIZ,1290,LAIZ,,46.2469919709,4.89933490972,203,,Laiz,Laiz,1,Ain,84,Auvergne-Rhône-Alpes
1204,LE POIZAT LALLEYRIAT,1130,LE POIZAT LALLEYRIAT,,46.1479592076,5.71829194244,204,Le,Poizat-Lalleyriat,Le Poizat-Lalleyriat,1,Ain,84,Auvergne-Rhône-Alpes
1204,LE POIZAT LALLEYRIAT,1130,LE POIZAT LALLEYRIAT,LE POIZAT,46.1479592076,5.71829194244,204,Le,Poizat-Lalleyriat,Le Poizat-Lalleyriat,1,Ain,84,Auvergne-Rhône-Alpes
1205,LANCRANS,1200,LANCRANS,,46.1323202721,5.84050985141,205,,Lancrans,Lancrans,1,Ain,84,Auvergne-Rhône-Alpes
1206,LANTENAY,1430,LANTENAY,,46.0544405897,5.54127421165,206,,Lantenay,Lantenay,1,Ain,84,Auvergne-Rhône-Alpes
1207,LAPEYROUSE,1330,LAPEYROUSE,,45.9859131165,4.98196682865,207,,Lapeyrouse,Lapeyrouse,1,Ain,84,Auvergne-Rhône-Alpes
1208,LAVOURS,1350,LAVOURS,,45.8108837933,5.77267317838,208,,Lavours,Lavours,1,Ain,84,Auvergne-Rhône-Alpes
1209,LEAZ,1200,LEAZ,,46.1086518044,5.87649017888,209,,Léaz,Léaz,1,Ain,84,Auvergne-Rhône-Alpes
1210,LELEX,1410,LELEX,,46.2855010936,5.92927713771,210,,Lélex,Lélex,1,Ain,84,Auvergne-Rhône-Alpes
1211,LENT,1240,LENT,,46.1160216305,5.20200684747,211,,Lent,Lent,1,Ain,84,Auvergne-Rhône-Alpes
1212,LESCHEROUX,1560,LESCHEROUX,,46.4114892731,5.15107164478,212,,Lescheroux,Lescheroux,1,Ain,84,Auvergne-Rhône-Alpes
1213,LEYMENT,1150,LEYMENT,,45.9269989549,5.2947657235,213,,Leyment,Leyment,1,Ain,84,Auvergne-Rhône-Alpes
1214,LEYSSARD,1450,LEYSSARD,,46.1624187428,5.47633297491,214,,Leyssard,Leyssard,1,Ain,84,Auvergne-Rhône-Alpes
1215,LHOPITAL,1420,LHOPITAL,,46.0238143134,5.76236736492,215,,Lhôpital,Lhôpital,1,Ain,84,Auvergne-Rhône-Alpes
1216,LHUIS,1680,LHUIS,,45.7444141403,5.53602066955,216,,Lhuis,Lhuis,1,Ain,84,Auvergne-Rhône-Alpes
1218,LOCHIEU,1260,LOCHIEU,,45.9302479879,5.7413126836,218,,Lochieu,Lochieu,1,Ain,84,Auvergne-Rhône-Alpes
1219,LOMPNAS,1680,LOMPNAS,,45.8105943351,5.52320457866,219,,Lompnas,Lompnas,1,Ain,84,Auvergne-Rhône-Alpes
1221,LOMPNIEU,1260,LOMPNIEU,,45.9690865613,5.65968816497,221,,Lompnieu,Lompnieu,1,Ain,84,Auvergne-Rhône-Alpes
1224,LOYETTES,1360,LOYETTES,,45.7921564114,5.21770971647,224,,Loyettes,Loyettes,1,Ain,84,Auvergne-Rhône-Alpes
1225,LURCY,1090,LURCY,,46.061730034,4.78075207026,225,,Lurcy,Lurcy,1,Ain,84,Auvergne-Rhône-Alpes
1227,MAGNIEU,1300,MAGNIEU,,45.7754050255,5.71540945601,227,,Magnieu,Magnieu,1,Ain,84,Auvergne-Rhône-Alpes
1228,MAILLAT,1430,MAILLAT,,46.1201642836,5.52854394238,228,,Maillat,Maillat,1,Ain,84,Auvergne-Rhône-Alpes
1229,MALAFRETAZ,1340,MALAFRETAZ,,46.3295545661,5.14386299599,229,,Malafretaz,Malafretaz,1,Ain,84,Auvergne-Rhône-Alpes
1230,MANTENAY MONTLIN,1560,MANTENAY MONTLIN,,46.4302754511,5.10675899732,230,,Mantenay-Montlin,Mantenay-Montlin,1,Ain,84,Auvergne-Rhône-Alpes
1231,MANZIAT,1570,MANZIAT,,46.3643830585,4.90242777155,231,,Manziat,Manziat,1,Ain,84,Auvergne-Rhône-Alpes
1232,MARBOZ,1851,MARBOZ,,46.3434312497,5.24374534963,232,,Marboz,Marboz,1,Ain,84,Auvergne-Rhône-Alpes
1233,MARCHAMP,1680,MARCHAMP,,45.7738495266,5.54985181107,233,,Marchamp,Marchamp,1,Ain,84,Auvergne-Rhône-Alpes
1234,MARIGNIEU,1300,MARIGNIEU,,45.8010384947,5.71570352924,234,,Marignieu,Marignieu,1,Ain,84,Auvergne-Rhône-Alpes
1235,MARLIEUX,1240,MARLIEUX,,46.0549073186,5.0741150816,235,,Marlieux,Marlieux,1,Ain,84,Auvergne-Rhône-Alpes
1236,MARSONNAS,1340,MARSONNAS,,46.3409117711,5.06434294764,236,,Marsonnas,Marsonnas,1,Ain,84,Auvergne-Rhône-Alpes
1237,MARTIGNAT,1100,MARTIGNAT,,46.2030768095,5.61384033805,237,,Martignat,Martignat,1,Ain,84,Auvergne-Rhône-Alpes
1238,MASSIEUX,1600,MASSIEUX,,45.9094016817,4.82779660051,238,,Massieux,Massieux,1,Ain,84,Auvergne-Rhône-Alpes
1239,MASSIGNIEU DE RIVES,1300,MASSIGNIEU DE RIVES,,45.7571503942,5.7620535823,239,,Massignieu-de-Rives,Massignieu-de-Rives,1,Ain,84,Auvergne-Rhône-Alpes
1240,MATAFELON GRANGES,1580,MATAFELON GRANGES,,46.2565319445,5.53958933702,240,,Matafelon-Granges,Matafelon-Granges,1,Ain,84,Auvergne-Rhône-Alpes
1240,MATAFELON GRANGES,1580,MATAFELON GRANGES,GRANGES,46.2565319445,5.53958933702,240,,Matafelon-Granges,Matafelon-Granges,1,Ain,84,Auvergne-Rhône-Alpes
1241,MEILLONNAS,1370,MEILLONNAS,,46.244997627,5.33594738489,241,,Meillonnas,Meillonnas,1,Ain,84,Auvergne-Rhône-Alpes
1242,MERIGNAT,1450,MERIGNAT,,46.0648564341,5.44210660051,242,,Mérignat,Mérignat,1,Ain,84,Auvergne-Rhône-Alpes
1243,MESSIMY SUR SAONE,1480,MESSIMY SUR SAONE,,46.0472670758,4.75935643933,243,,Messimy-sur-Saône,Messimy-sur-Saône,1,Ain,84,Auvergne-Rhône-Alpes
1244,MEXIMIEUX,1800,MEXIMIEUX,,45.9060530318,5.20404833653,244,,Meximieux,Meximieux,1,Ain,84,Auvergne-Rhône-Alpes
1245,BOHAS MEYRIAT RIGNAT,1250,BOHAS MEYRIAT RIGNAT,,46.1427060662,5.3780109646,245,,Bohas-Meyriat-Rignat,Bohas-Meyriat-Rignat,1,Ain,84,Auvergne-Rhône-Alpes
1245,BOHAS MEYRIAT RIGNAT,1250,BOHAS MEYRIAT RIGNAT,BOHAS,46.1427060662,5.3780109646,245,,Bohas-Meyriat-Rignat,Bohas-Meyriat-Rignat,1,Ain,84,Auvergne-Rhône-Alpes
1245,BOHAS MEYRIAT RIGNAT,1250,BOHAS MEYRIAT RIGNAT,RIGNAT,46.1427060662,5.3780109646,245,,Bohas-Meyriat-Rignat,Bohas-Meyriat-Rignat,1,Ain,84,Auvergne-Rhône-Alpes
1246,MEZERIAT,1660,MEZERIAT,,46.2423102101,5.04725416984,246,,Mézériat,Mézériat,1,Ain,84,Auvergne-Rhône-Alpes
1247,MIJOUX,1170,MIJOUX,,46.3622451724,6.00902631941,247,,Mijoux,Mijoux,1,Ain,84,Auvergne-Rhône-Alpes
1247,MIJOUX,1410,MIJOUX,,46.3622451724,6.00902631941,247,,Mijoux,Mijoux,1,Ain,84,Auvergne-Rhône-Alpes
1248,MIONNAY,1390,MIONNAY,,45.8955939299,4.92416967439,248,,Mionnay,Mionnay,1,Ain,84,Auvergne-Rhône-Alpes
1249,MIRIBEL,1700,MIRIBEL,,45.8442099203,4.94106746787,249,,Miribel,Miribel,1,Ain,84,Auvergne-Rhône-Alpes
1249,MIRIBEL,1700,MIRIBEL,LE MAS RILLIER,45.8442099203,4.94106746787,249,,Miribel,Miribel,1,Ain,84,Auvergne-Rhône-Alpes
1249,MIRIBEL,1700,MIRIBEL,LES ECHETS,45.8442099203,4.94106746787,249,,Miribel,Miribel,1,Ain,84,Auvergne-Rhône-Alpes
1250,MISERIEUX,1600,MISERIEUX,,45.9762325343,4.81028471711,250,,Misérieux,Misérieux,1,Ain,84,Auvergne-Rhône-Alpes
1252,MOGNENEINS,1140,MOGNENEINS,,46.1446696076,4.820110702,252,,Mogneneins,Mogneneins,1,Ain,84,Auvergne-Rhône-Alpes
1254,MONTAGNAT,1250,MONTAGNAT,,46.1686969123,5.27962107938,254,,Montagnat,Montagnat,1,Ain,84,Auvergne-Rhône-Alpes
1255,MONTAGNIEU,1470,MONTAGNIEU,,45.799449019,5.46428608859,255,,Montagnieu,Montagnieu,1,Ain,84,Auvergne-Rhône-Alpes
1257,MONTANGES,1200,MONTANGES,,46.17325293,5.79006091307,257,,Montanges,Montanges,1,Ain,84,Auvergne-Rhône-Alpes
1258,MONTCEAUX,1090,MONTCEAUX,,46.0962893261,4.80453564172,258,,Montceaux,Montceaux,1,Ain,84,Auvergne-Rhône-Alpes
1259,MONTCET,1310,MONTCET,,46.219333227,5.11149132074,259,,Montcet,Montcet,1,Ain,84,Auvergne-Rhône-Alpes
1260,LE MONTELLIER,1800,LE MONTELLIER,,45.9287482486,5.07092815964,260,Le,Montellier,Le Montellier,1,Ain,84,Auvergne-Rhône-Alpes
1261,MONTHIEUX,1390,MONTHIEUX,,45.9622791838,4.94555984534,261,,Monthieux,Monthieux,1,Ain,84,Auvergne-Rhône-Alpes
1262,MONTLUEL,1120,MONTLUEL,,45.8909006209,5.01987566185,262,,Montluel,Montluel,1,Ain,84,Auvergne-Rhône-Alpes
1262,MONTLUEL,1120,MONTLUEL,CORDIEUX,45.8909006209,5.01987566185,262,,Montluel,Montluel,1,Ain,84,Auvergne-Rhône-Alpes
1262,MONTLUEL,1120,MONTLUEL,JAILLEUX,45.8909006209,5.01987566185,262,,Montluel,Montluel,1,Ain,84,Auvergne-Rhône-Alpes
1263,MONTMERLE SUR SAONE,1090,MONTMERLE SUR SAONE,,46.0849573236,4.7608579486,263,,Montmerle-sur-Saône,Montmerle-sur-Saône,1,Ain,84,Auvergne-Rhône-Alpes
1264,MONTRACOL,1310,MONTRACOL,,46.1867200301,5.11706011755,264,,Montracol,Montracol,1,Ain,84,Auvergne-Rhône-Alpes
1265,MONTREAL LA CLUSE,1460,MONTREAL LA CLUSE,,46.1795688194,5.59510550127,265,,Montréal-la-Cluse,Montréal-la-Cluse,1,Ain,84,Auvergne-Rhône-Alpes
1266,MONTREVEL EN BRESSE,1340,MONTREVEL EN BRESSE,,46.3252811442,5.11295966315,266,,Montrevel-en-Bresse,Montrevel-en-Bresse,1,Ain,84,Auvergne-Rhône-Alpes
1267,NURIEUX VOLOGNAT,1460,NURIEUX VOLOGNAT,,46.1830720992,5.51435812316,267,,Nurieux-Volognat,Nurieux-Volognat,1,Ain,84,Auvergne-Rhône-Alpes
1267,NURIEUX VOLOGNAT,1460,NURIEUX VOLOGNAT,VOLOGNAT,46.1830720992,5.51435812316,267,,Nurieux-Volognat,Nurieux-Volognat,1,Ain,84,Auvergne-Rhône-Alpes
1268,MURS ET GELIGNIEUX,1300,MURS ET GELIGNIEUX,,45.6420014274,5.66039684889,268,,Murs-et-Gélignieux,Murs-et-Gélignieux,1,Ain,84,Auvergne-Rhône-Alpes
1269,NANTUA,1130,NANTUA,,46.1537845403,5.61352793799,269,,Nantua,Nantua,1,Ain,84,Auvergne-Rhône-Alpes
1269,NANTUA,1460,NANTUA,,46.1537845403,5.61352793799,269,,Nantua,Nantua,1,Ain,84,Auvergne-Rhône-Alpes
1272,NEUVILLE LES DAMES,1400,NEUVILLE LES DAMES,,46.1567753049,5.00942432434,272,,Neuville-les-Dames,Neuville-les-Dames,1,Ain,84,Auvergne-Rhône-Alpes
1273,NEUVILLE SUR AIN,1160,NEUVILLE SUR AIN,,46.0909654813,5.37349784691,273,,Neuville-sur-Ain,Neuville-sur-Ain,1,Ain,84,Auvergne-Rhône-Alpes
1274,LES NEYROLLES,1130,LES NEYROLLES,,46.1342937114,5.63714517869,274,Les,Neyrolles,Les Neyrolles,1,Ain,84,Auvergne-Rhône-Alpes
1275,NEYRON,1700,NEYRON,,45.8184192468,4.9309045685,275,,Neyron,Neyron,1,Ain,84,Auvergne-Rhône-Alpes
1276,NIEVROZ,1120,NIEVROZ,,45.8222941077,5.05949629195,276,,Niévroz,Niévroz,1,Ain,84,Auvergne-Rhône-Alpes
1277,NIVOLLET MONTGRIFFON,1230,NIVOLLET MONTGRIFFON,,45.9999448244,5.45847815374,277,,Nivollet-Montgriffon,Nivollet-Montgriffon,1,Ain,84,Auvergne-Rhône-Alpes
1279,ONCIEU,1230,ONCIEU,,45.9651165469,5.47146617499,279,,Oncieu,Oncieu,1,Ain,84,Auvergne-Rhône-Alpes
1280,ORDONNAZ,1510,ORDONNAZ,,45.8339690378,5.5395794296,280,,Ordonnaz,Ordonnaz,1,Ain,84,Auvergne-Rhône-Alpes
1281,ORNEX,1210,ORNEX,,46.2774557513,6.09454723599,281,,Ornex,Ornex,1,Ain,84,Auvergne-Rhône-Alpes
1282,OUTRIAZ,1430,OUTRIAZ,,46.067796636,5.55697547429,282,,Outriaz,Outriaz,1,Ain,84,Auvergne-Rhône-Alpes
1283,OYONNAX,1100,OYONNAX,,46.2605435859,5.65344320923,283,,Oyonnax,Oyonnax,1,Ain,84,Auvergne-Rhône-Alpes
1283,OYONNAX,1100,OYONNAX,BOUVENT,46.2605435859,5.65344320923,283,,Oyonnax,Oyonnax,1,Ain,84,Auvergne-Rhône-Alpes
1283,OYONNAX,1100,OYONNAX,VEYZIAT,46.2605435859,5.65344320923,283,,Oyonnax,Oyonnax,1,Ain,84,Auvergne-Rhône-Alpes
1284,OZAN,1190,OZAN,,46.3867168877,4.91805840196,284,,Ozan,Ozan,1,Ain,84,Auvergne-Rhône-Alpes
1285,PARCIEUX,1600,PARCIEUX,,45.9184267932,4.82747423402,285,,Parcieux,Parcieux,1,Ain,84,Auvergne-Rhône-Alpes
1286,PARVES ET NATTAGES,1300,PARVES ET NATTAGES,,45.7437340183,5.73800737134,286,,Parves et Nattages,Parves et Nattages,1,Ain,84,Auvergne-Rhône-Alpes
1286,PARVES ET NATTAGES,1300,PARVES ET NATTAGES,NATTAGES,45.7437340183,5.73800737134,286,,Parves et Nattages,Parves et Nattages,1,Ain,84,Auvergne-Rhône-Alpes
1288,PERON,1630,PERON,,46.1913554154,5.91968751164,288,,Péron,Péron,1,Ain,84,Auvergne-Rhône-Alpes
1289,PERONNAS,1960,PERONNAS,,46.1711903413,5.21802169564,289,,Péronnas,Péronnas,1,Ain,84,Auvergne-Rhône-Alpes
1290,PEROUGES,1800,PEROUGES,,45.8803945768,5.17955874977,290,,Pérouges,Pérouges,1,Ain,84,Auvergne-Rhône-Alpes
1291,PERREX,1540,PERREX,,46.2465065604,4.97464940331,291,,Perrex,Perrex,1,Ain,84,Auvergne-Rhône-Alpes
1293,PEYRIAT,1430,PEYRIAT,,46.1498184067,5.50572312284,293,,Peyriat,Peyriat,1,Ain,84,Auvergne-Rhône-Alpes
1294,PEYRIEU,1300,PEYRIEU,,45.6762428663,5.67912809192,294,,Peyrieu,Peyrieu,1,Ain,84,Auvergne-Rhône-Alpes
1295,PEYZIEUX SUR SAONE,1140,PEYZIEUX SUR SAONE,,46.1226331853,4.82243226719,295,,Peyzieux-sur-Saône,Peyzieux-sur-Saône,1,Ain,84,Auvergne-Rhône-Alpes
1296,PIRAJOUX,1270,PIRAJOUX,,46.385801285,5.27417594024,296,,Pirajoux,Pirajoux,1,Ain,84,Auvergne-Rhône-Alpes
1297,PIZAY,1120,PIZAY,,45.8928509581,5.08915465965,297,,Pizay,Pizay,1,Ain,84,Auvergne-Rhône-Alpes
1298,PLAGNE,1130,PLAGNE,,46.1883038423,5.71763811249,298,,Plagne,Plagne,1,Ain,84,Auvergne-Rhône-Alpes
1299,LE PLANTAY,1330,LE PLANTAY,,46.0230264227,5.08912046926,299,Le,Plantay,Le Plantay,1,Ain,84,Auvergne-Rhône-Alpes
1301,POLLIAT,1310,POLLIAT,,46.2463108642,5.13794412439,301,,Polliat,Polliat,1,Ain,84,Auvergne-Rhône-Alpes
1302,POLLIEU,1350,POLLIEU,,45.7965373468,5.74794515549,302,,Pollieu,Pollieu,1,Ain,84,Auvergne-Rhône-Alpes
1303,PONCIN,1450,PONCIN,,46.089530586,5.41681266947,303,,Poncin,Poncin,1,Ain,84,Auvergne-Rhône-Alpes
1304,PONT D AIN,1160,PONT D AIN,,46.0542077091,5.34703652618,304,,Pont-d'Ain,Pont-d'Ain,1,Ain,84,Auvergne-Rhône-Alpes
1305,PONT DE VAUX,1190,PONT DE VAUX,,46.4422864477,4.92862459427,305,,Pont-de-Vaux,Pont-de-Vaux,1,Ain,84,Auvergne-Rhône-Alpes
1306,PONT DE VEYLE,1290,PONT DE VEYLE,,46.2637215091,4.88186068102,306,,Pont-de-Veyle,Pont-de-Veyle,1,Ain,84,Auvergne-Rhône-Alpes
1307,PORT,1460,PORT,,46.155963139,5.57615832883,307,,Port,Port,1,Ain,84,Auvergne-Rhône-Alpes
1308,POUGNY,1550,POUGNY,,46.1450914685,5.95100479291,308,,Pougny,Pougny,1,Ain,84,Auvergne-Rhône-Alpes
1309,POUILLAT,1250,POUILLAT,,46.3219301481,5.42318000482,309,,Pouillat,Pouillat,1,Ain,84,Auvergne-Rhône-Alpes
1310,PREMEYZEL,1300,PREMEYZEL,,45.6806263123,5.64396081449,310,,Prémeyzel,Prémeyzel,1,Ain,84,Auvergne-Rhône-Alpes
1311,PREMILLIEU,1110,PREMILLIEU,,45.876457149,5.56475010385,311,,Prémillieu,Prémillieu,1,Ain,84,Auvergne-Rhône-Alpes
1313,PREVESSIN MOENS,1280,PREVESSIN MOENS,,46.25827228,6.07223251859,313,,Prévessin-Moëns,Prévessin-Moëns,1,Ain,84,Auvergne-Rhône-Alpes
1313,PREVESSIN MOENS,1280,PREVESSIN MOENS,MOENS,46.25827228,6.07223251859,313,,Prévessin-Moëns,Prévessin-Moëns,1,Ain,84,Auvergne-Rhône-Alpes
1314,PRIAY,1160,PRIAY,,46.0197521486,5.28523353803,314,,Priay,Priay,1,Ain,84,Auvergne-Rhône-Alpes
1316,PUGIEU,1510,PUGIEU,,45.8176794806,5.64970405499,316,,Pugieu,Pugieu,1,Ain,84,Auvergne-Rhône-Alpes
1317,RAMASSE,1250,RAMASSE,,46.1905550894,5.35572056985,317,,Ramasse,Ramasse,1,Ain,84,Auvergne-Rhône-Alpes
1318,RANCE,1390,RANCE,,45.9673905154,4.86343025951,318,,Rancé,Rancé,1,Ain,84,Auvergne-Rhône-Alpes
1319,RELEVANT,1990,RELEVANT,,46.0843657897,4.94176845356,319,,Relevant,Relevant,1,Ain,84,Auvergne-Rhône-Alpes
1320,REPLONGES,1750,REPLONGES,,46.3077356023,4.8746938271,320,,Replonges,Replonges,1,Ain,84,Auvergne-Rhône-Alpes
1321,REVONNAS,1250,REVONNAS,,46.1615355815,5.33543916388,321,,Revonnas,Revonnas,1,Ain,84,Auvergne-Rhône-Alpes
1322,REYRIEUX,1600,REYRIEUX,,45.9367652442,4.82840395968,322,,Reyrieux,Reyrieux,1,Ain,84,Auvergne-Rhône-Alpes
1323,REYSSOUZE,1190,REYSSOUZE,,46.4285569061,4.91285056272,323,,Reyssouze,Reyssouze,1,Ain,84,Auvergne-Rhône-Alpes
1325,RIGNIEUX LE FRANC,1800,RIGNIEUX LE FRANC,,45.9518762734,5.16936671105,325,,Rignieux-le-Franc,Rignieux-le-Franc,1,Ain,84,Auvergne-Rhône-Alpes
1328,ROMANS,1400,ROMANS,,46.1237520756,5.0217131176,328,,Romans,Romans,1,Ain,84,Auvergne-Rhône-Alpes
1329,ROSSILLON,1510,ROSSILLON,,45.8363854589,5.59926863007,329,,Rossillon,Rossillon,1,Ain,84,Auvergne-Rhône-Alpes
1330,RUFFIEU,1260,RUFFIEU,,46.0037108867,5.65322391497,330,,Ruffieu,Ruffieu,1,Ain,84,Auvergne-Rhône-Alpes
1331,ST ALBAN,1450,ST ALBAN,,46.0983335519,5.45573839846,331,,Saint-Alban,Saint-Alban,1,Ain,84,Auvergne-Rhône-Alpes
1332,ST ANDRE DE BAGE,1380,ST ANDRE DE BAGE,,46.298541568,4.91411565769,332,,Saint-André-de-Bâgé,Saint-André-de-Bâgé,1,Ain,84,Auvergne-Rhône-Alpes
1333,ST ANDRE DE CORCY,1390,ST ANDRE DE CORCY,,45.927001954,4.95373324492,333,,Saint-André-de-Corcy,Saint-André-de-Corcy,1,Ain,84,Auvergne-Rhône-Alpes
1334,ST ANDRE D HUIRIAT,1290,ST ANDRE D HUIRIAT,,46.2140960915,4.91291358797,334,,Saint-André-d'Huiriat,Saint-André-d'Huiriat,1,Ain,84,Auvergne-Rhône-Alpes
1335,ST ANDRE LE BOUCHOUX,1240,ST ANDRE LE BOUCHOUX,,46.1131399612,5.0802376895,335,,Saint-André-le-Bouchoux,Saint-André-le-Bouchoux,1,Ain,84,Auvergne-Rhône-Alpes
1336,ST ANDRE SUR VIEUX JONC,1960,ST ANDRE SUR VIEUX JONC,,46.1492410532,5.14175200148,336,,Saint-André-sur-Vieux-Jonc,Saint-André-sur-Vieux-Jonc,1,Ain,84,Auvergne-Rhône-Alpes
1337,ST BENIGNE,1190,ST BENIGNE,,46.4519173207,4.95309825903,337,,Saint-Bénigne,Saint-Bénigne,1,Ain,84,Auvergne-Rhône-Alpes
1338,GROSLEE ST BENOIT,1300,GROSLEE ST BENOIT,,45.6891842277,5.5843972561,338,,Groslée-Saint-Benoit,Groslée-Saint-Benoit,1,Ain,84,Auvergne-Rhône-Alpes
1338,GROSLEE ST BENOIT,1680,GROSLEE ST BENOIT,GROSLEE,45.6891842277,5.5843972561,338,,Groslée-Saint-Benoit,Groslée-Saint-Benoit,1,Ain,84,Auvergne-Rhône-Alpes
1339,ST BERNARD,1600,ST BERNARD,,45.9493830581,4.74276628471,339,,Saint-Bernard,Saint-Bernard,1,Ain,84,Auvergne-Rhône-Alpes
1341,ST CHAMP,1300,ST CHAMP,,45.7825741014,5.7326531461,341,,Saint-Champ,Saint-Champ,1,Ain,84,Auvergne-Rhône-Alpes
1342,STE CROIX,1120,STE CROIX,,45.895522477,5.04935536228,342,,Sainte-Croix,Sainte-Croix,1,Ain,84,Auvergne-Rhône-Alpes
1343,ST CYR SUR MENTHON,1380,ST CYR SUR MENTHON,,46.2754005606,4.96757105158,343,,Saint-Cyr-sur-Menthon,Saint-Cyr-sur-Menthon,1,Ain,84,Auvergne-Rhône-Alpes
1344,ST DENIS LES BOURG,1000,ST DENIS LES BOURG,,46.2111998453,5.18462806771,344,,Saint-Denis-lès-Bourg,Saint-Denis-lès-Bourg,1,Ain,84,Auvergne-Rhône-Alpes
1345,ST DENIS EN BUGEY,1500,ST DENIS EN BUGEY,,45.9488551151,5.32367701698,345,,Saint-Denis-en-Bugey,Saint-Denis-en-Bugey,1,Ain,84,Auvergne-Rhône-Alpes
1346,ST DIDIER D AUSSIAT,1340,ST DIDIER D AUSSIAT,,46.3069992095,5.05820697692,346,,Saint-Didier-d'Aussiat,Saint-Didier-d'Aussiat,1,Ain,84,Auvergne-Rhône-Alpes
1347,ST DIDIER DE FORMANS,1600,ST DIDIER DE FORMANS,,45.9564926067,4.77766764875,347,,Saint-Didier-de-Formans,Saint-Didier-de-Formans,1,Ain,84,Auvergne-Rhône-Alpes
1348,ST DIDIER SUR CHALARONNE,1140,ST DIDIER SUR CHALARONNE,,46.1840408682,4.81645666173,348,,Saint-Didier-sur-Chalaronne,Saint-Didier-sur-Chalaronne,1,Ain,84,Auvergne-Rhône-Alpes
1349,ST ELOI,1800,ST ELOI,,45.9322494861,5.15187041178,349,,Saint-Éloi,Saint-Éloi,1,Ain,84,Auvergne-Rhône-Alpes
1350,ST ETIENNE DU BOIS,1370,ST ETIENNE DU BOIS,,46.2749447261,5.28428557632,350,,Saint-Étienne-du-Bois,Saint-Étienne-du-Bois,1,Ain,84,Auvergne-Rhône-Alpes
1351,ST ETIENNE SUR CHALARONNE,1140,ST ETIENNE SUR CHALARONNE,,46.1468059584,4.86696454244,351,,Saint-Étienne-sur-Chalaronne,Saint-Étienne-sur-Chalaronne,1,Ain,84,Auvergne-Rhône-Alpes
1352,ST ETIENNE SUR REYSSOUZE,1190,ST ETIENNE SUR REYSSOUZE,,46.4030398952,5.00605581573,352,,Saint-Étienne-sur-Reyssouze,Saint-Étienne-sur-Reyssouze,1,Ain,84,Auvergne-Rhône-Alpes
1353,STE EUPHEMIE,1600,STE EUPHEMIE,,45.9669798317,4.79478189169,353,,Sainte-Euphémie,Sainte-Euphémie,1,Ain,84,Auvergne-Rhône-Alpes
1354,ST GENIS POUILLY,1630,ST GENIS POUILLY,,46.254539333,6.0362907345,354,,Saint-Genis-Pouilly,Saint-Genis-Pouilly,1,Ain,84,Auvergne-Rhône-Alpes
1355,ST GENIS SUR MENTHON,1380,ST GENIS SUR MENTHON,,46.2815955593,5.01311826405,355,,Saint-Genis-sur-Menthon,Saint-Genis-sur-Menthon,1,Ain,84,Auvergne-Rhône-Alpes
1356,ST GEORGES SUR RENON,1400,ST GEORGES SUR RENON,,46.1037951263,5.03001967494,356,,Saint-Georges-sur-Renon,Saint-Georges-sur-Renon,1,Ain,84,Auvergne-Rhône-Alpes
1357,ST GERMAIN DE JOUX,1130,ST GERMAIN DE JOUX,,46.1821955394,5.74672259773,357,,Saint-Germain-de-Joux,Saint-Germain-de-Joux,1,Ain,84,Auvergne-Rhône-Alpes
1358,ST GERMAIN LES PAROISSES,1300,ST GERMAIN LES PAROISSES,,45.7709749249,5.61753440834,358,,Saint-Germain-les-Paroisses,Saint-Germain-les-Paroisses,1,Ain,84,Auvergne-Rhône-Alpes
1359,ST GERMAIN SUR RENON,1240,ST GERMAIN SUR RENON,,46.0797285417,5.06090903982,359,,Saint-Germain-sur-Renon,Saint-Germain-sur-Renon,1,Ain,84,Auvergne-Rhône-Alpes
1360,ST JEAN DE GONVILLE,1630,ST JEAN DE GONVILLE,,46.2178585727,5.94203093769,360,,Saint-Jean-de-Gonville,Saint-Jean-de-Gonville,1,Ain,84,Auvergne-Rhône-Alpes
1361,ST JEAN DE NIOST,1800,ST JEAN DE NIOST,,45.8405002569,5.21167430462,361,,Saint-Jean-de-Niost,Saint-Jean-de-Niost,1,Ain,84,Auvergne-Rhône-Alpes
1362,ST JEAN DE THURIGNEUX,1390,ST JEAN DE THURIGNEUX,,45.9598424323,4.89936410681,362,,Saint-Jean-de-Thurigneux,Saint-Jean-de-Thurigneux,1,Ain,84,Auvergne-Rhône-Alpes
1363,ST JEAN LE VIEUX,1640,ST JEAN LE VIEUX,,46.0291437714,5.38252835538,363,,Saint-Jean-le-Vieux,Saint-Jean-le-Vieux,1,Ain,84,Auvergne-Rhône-Alpes
1364,ST JEAN SUR REYSSOUZE,1560,ST JEAN SUR REYSSOUZE,,46.3972479854,5.06297763777,364,,Saint-Jean-sur-Reyssouze,Saint-Jean-sur-Reyssouze,1,Ain,84,Auvergne-Rhône-Alpes
1365,ST JEAN SUR VEYLE,1290,ST JEAN SUR VEYLE,,46.2683632132,4.92198366998,365,,Saint-Jean-sur-Veyle,Saint-Jean-sur-Veyle,1,Ain,84,Auvergne-Rhône-Alpes
1366,STE JULIE,1150,STE JULIE,,45.8819723176,5.28452304614,366,,Sainte-Julie,Sainte-Julie,1,Ain,84,Auvergne-Rhône-Alpes
1367,ST JULIEN SUR REYSSOUZE,1560,ST JULIEN SUR REYSSOUZE,,46.3997655501,5.10354296019,367,,Saint-Julien-sur-Reyssouze,Saint-Julien-sur-Reyssouze,1,Ain,84,Auvergne-Rhône-Alpes
1368,ST JULIEN SUR VEYLE,1540,ST JULIEN SUR VEYLE,,46.2014862081,4.959356342,368,,Saint-Julien-sur-Veyle,Saint-Julien-sur-Veyle,1,Ain,84,Auvergne-Rhône-Alpes
1369,ST JUST,1250,ST JUST,,46.1929758576,5.28494181398,369,,Saint-Just,Saint-Just,1,Ain,84,Auvergne-Rhône-Alpes
1370,ST LAURENT SUR SAONE,1750,ST LAURENT SUR SAONE,,46.3051486294,4.83949202669,370,,Saint-Laurent-sur-Saône,Saint-Laurent-sur-Saône,1,Ain,84,Auvergne-Rhône-Alpes
1371,ST MARCEL,1390,ST MARCEL,,45.9485803654,4.9896671017,371,,Saint-Marcel,Saint-Marcel,1,Ain,84,Auvergne-Rhône-Alpes
1372,ST MARTIN DE BAVEL,1510,ST MARTIN DE BAVEL,,45.848212674,5.67889099153,372,,Saint-Martin-de-Bavel,Saint-Martin-de-Bavel,1,Ain,84,Auvergne-Rhône-Alpes
1373,ST MARTIN DU FRENE,1430,ST MARTIN DU FRENE,,46.135983782,5.57106016428,373,,Saint-Martin-du-Frêne,Saint-Martin-du-Frêne,1,Ain,84,Auvergne-Rhône-Alpes
1374,ST MARTIN DU MONT,1160,ST MARTIN DU MONT,,46.1061500835,5.32551470817,374,,Saint-Martin-du-Mont,Saint-Martin-du-Mont,1,Ain,84,Auvergne-Rhône-Alpes
1375,ST MARTIN LE CHATEL,1310,ST MARTIN LE CHATEL,,46.291445874,5.12291715091,375,,Saint-Martin-le-Châtel,Saint-Martin-le-Châtel,1,Ain,84,Auvergne-Rhône-Alpes
1376,ST MAURICE DE BEYNOST,1700,ST MAURICE DE BEYNOST,,45.8343709108,4.97726215174,376,,Saint-Maurice-de-Beynost,Saint-Maurice-de-Beynost,1,Ain,84,Auvergne-Rhône-Alpes
1378,ST MAURICE DE GOURDANS,1800,ST MAURICE DE GOURDANS,,45.8200191939,5.16980415584,378,,Saint-Maurice-de-Gourdans,Saint-Maurice-de-Gourdans,1,Ain,84,Auvergne-Rhône-Alpes
1379,ST MAURICE DE REMENS,1500,ST MAURICE DE REMENS,,45.9527471237,5.2708276475,379,,Saint-Maurice-de-Rémens,Saint-Maurice-de-Rémens,1,Ain,84,Auvergne-Rhône-Alpes
1380,ST NIZIER LE BOUCHOUX,1560,ST NIZIER LE BOUCHOUX,,46.4528537121,5.1759906585,380,,Saint-Nizier-le-Bouchoux,Saint-Nizier-le-Bouchoux,1,Ain,84,Auvergne-Rhône-Alpes
1381,ST NIZIER LE DESERT,1320,ST NIZIER LE DESERT,,46.052861561,5.14246131764,381,,Saint-Nizier-le-Désert,Saint-Nizier-le-Désert,1,Ain,84,Auvergne-Rhône-Alpes
1382,STE OLIVE,1330,STE OLIVE,,46.0142006121,4.93624894982,382,,Sainte-Olive,Sainte-Olive,1,Ain,84,Auvergne-Rhône-Alpes
1383,ST PAUL DE VARAX,1240,ST PAUL DE VARAX,,46.0956691617,5.12762470203,383,,Saint-Paul-de-Varax,Saint-Paul-de-Varax,1,Ain,84,Auvergne-Rhône-Alpes
1384,ST RAMBERT EN BUGEY,1230,ST RAMBERT EN BUGEY,,45.9504631708,5.44017084612,384,,Saint-Rambert-en-Bugey,Saint-Rambert-en-Bugey,1,Ain,84,Auvergne-Rhône-Alpes
1385,ST REMY,1310,ST REMY,,46.1846642149,5.16771354778,385,,Saint-Rémy,Saint-Rémy,1,Ain,84,Auvergne-Rhône-Alpes
1386,ST SORLIN EN BUGEY,1150,ST SORLIN EN BUGEY,,45.886308438,5.37608915006,386,,Saint-Sorlin-en-Bugey,Saint-Sorlin-en-Bugey,1,Ain,84,Auvergne-Rhône-Alpes
1387,ST SULPICE,1340,ST SULPICE,,46.3209806116,5.04148389346,387,,Saint-Sulpice,Saint-Sulpice,1,Ain,84,Auvergne-Rhône-Alpes
1388,ST TRIVIER DE COURTES,1560,ST TRIVIER DE COURTES,,46.4612969335,5.06679195727,388,,Saint-Trivier-de-Courtes,Saint-Trivier-de-Courtes,1,Ain,84,Auvergne-Rhône-Alpes
1389,ST TRIVIER SUR MOIGNANS,1990,ST TRIVIER SUR MOIGNANS,,46.0618477991,4.90158948884,389,,Saint-Trivier-sur-Moignans,Saint-Trivier-sur-Moignans,1,Ain,84,Auvergne-Rhône-Alpes
1390,ST VULBAS,1150,ST VULBAS,,45.8222315102,5.27019602929,390,,Saint-Vulbas,Saint-Vulbas,1,Ain,84,Auvergne-Rhône-Alpes
1391,SALAVRE,1270,SALAVRE,,46.3635396668,5.34742387197,391,,Salavre,Salavre,1,Ain,84,Auvergne-Rhône-Alpes
1392,SAMOGNAT,1580,SAMOGNAT,,46.2648715781,5.58607689306,392,,Samognat,Samognat,1,Ain,84,Auvergne-Rhône-Alpes
1393,SANDRANS,1400,SANDRANS,,46.0656961548,4.98282673172,393,,Sandrans,Sandrans,1,Ain,84,Auvergne-Rhône-Alpes
1396,SAULT BRENAZ,1150,SAULT BRENAZ,,45.8654038798,5.40416104083,396,,Sault-Brénaz,Sault-Brénaz,1,Ain,84,Auvergne-Rhône-Alpes
1397,SAUVERNY,1220,SAUVERNY,,46.3126908233,6.11043836433,397,,Sauverny,Sauverny,1,Ain,84,Auvergne-Rhône-Alpes
1398,SAVIGNEUX,1480,SAVIGNEUX,,45.997346828,4.8576200906,398,,Savigneux,Savigneux,1,Ain,84,Auvergne-Rhône-Alpes
1399,SEGNY,1170,SEGNY,,46.2935928404,6.07287743792,399,,Ségny,Ségny,1,Ain,84,Auvergne-Rhône-Alpes
1400,SEILLONNAZ,1470,SEILLONNAZ,,45.7976976614,5.49810658524,400,,Seillonnaz,Seillonnaz,1,Ain,84,Auvergne-Rhône-Alpes
1401,SERGY,1630,SERGY,,46.2626243003,5.98914553194,401,,Sergy,Sergy,1,Ain,84,Auvergne-Rhône-Alpes
1402,SERMOYER,1190,SERMOYER,,46.4981991944,4.97856020441,402,,Sermoyer,Sermoyer,1,Ain,84,Auvergne-Rhône-Alpes
1403,SERRIERES DE BRIORD,1470,SERRIERES DE BRIORD,,45.8133219416,5.44125880379,403,,Serrières-de-Briord,Serrières-de-Briord,1,Ain,84,Auvergne-Rhône-Alpes
1404,SERRIERES SUR AIN,1450,SERRIERES SUR AIN,,46.1466977757,5.45023362942,404,,Serrières-sur-Ain,Serrières-sur-Ain,1,Ain,84,Auvergne-Rhône-Alpes
1405,SERVAS,1960,SERVAS,,46.1385540577,5.17887070992,405,,Servas,Servas,1,Ain,84,Auvergne-Rhône-Alpes
1406,SERVIGNAT,1560,SERVIGNAT,,46.4346497878,5.05559973107,406,,Servignat,Servignat,1,Ain,84,Auvergne-Rhône-Alpes
1407,SEYSSEL,1420,SEYSSEL,,45.9484118176,5.82616979887,407,,Seyssel,Seyssel,1,Ain,84,Auvergne-Rhône-Alpes
1408,SIMANDRE SUR SURAN,1250,SIMANDRE SUR SURAN,,46.2340471594,5.42240815305,408,,Simandre-sur-Suran,Simandre-sur-Suran,1,Ain,84,Auvergne-Rhône-Alpes
1410,SONTHONNAX LA MONTAGNE,1580,SONTHONNAX LA MONTAGNE,,46.2229768712,5.50566888767,410,,Sonthonnax-la-Montagne,Sonthonnax-la-Montagne,1,Ain,84,Auvergne-Rhône-Alpes
1410,SONTHONNAX LA MONTAGNE,1580,SONTHONNAX LA MONTAGNE,NAPT,46.2229768712,5.50566888767,410,,Sonthonnax-la-Montagne,Sonthonnax-la-Montagne,1,Ain,84,Auvergne-Rhône-Alpes
1411,SOUCLIN,1150,SOUCLIN,,45.8781798572,5.43058627512,411,,Souclin,Souclin,1,Ain,84,Auvergne-Rhône-Alpes
1412,SULIGNAT,1400,SULIGNAT,,46.1735383313,4.95772382526,412,,Sulignat,Sulignat,1,Ain,84,Auvergne-Rhône-Alpes
1413,SURJOUX,1420,SURJOUX,,46.030402108,5.79957920623,413,,Surjoux,Surjoux,1,Ain,84,Auvergne-Rhône-Alpes
1414,SUTRIEU,1260,SUTRIEU,,45.9311367808,5.64988248129,414,,Sutrieu,Sutrieu,1,Ain,84,Auvergne-Rhône-Alpes
1414,SUTRIEU,1260,SUTRIEU,CHARANCIN,45.9311367808,5.64988248129,414,,Sutrieu,Sutrieu,1,Ain,84,Auvergne-Rhône-Alpes
1414,SUTRIEU,1260,SUTRIEU,FITIGNIEU,45.9311367808,5.64988248129,414,,Sutrieu,Sutrieu,1,Ain,84,Auvergne-Rhône-Alpes
1415,TALISSIEU,1510,TALISSIEU,,45.8637167128,5.72090411074,415,,Talissieu,Talissieu,1,Ain,84,Auvergne-Rhône-Alpes
1416,TENAY,1230,TENAY,,45.922698278,5.51453923595,416,,Tenay,Tenay,1,Ain,84,Auvergne-Rhône-Alpes
1417,THEZILLIEU,1110,THEZILLIEU,,45.8931883593,5.59700638944,417,,Thézillieu,Thézillieu,1,Ain,84,Auvergne-Rhône-Alpes
1418,THIL,1120,THIL,,45.8169214201,5.0184098869,418,,Thil,Thil,1,Ain,84,Auvergne-Rhône-Alpes
1419,THOIRY,1710,THOIRY,,46.2435475879,5.96506297987,419,,Thoiry,Thoiry,1,Ain,84,Auvergne-Rhône-Alpes
1420,THOISSEY,1140,THOISSEY,,46.1700943481,4.79387095814,420,,Thoissey,Thoissey,1,Ain,84,Auvergne-Rhône-Alpes
1421,TORCIEU,1230,TORCIEU,,45.92125055,5.40400282244,421,,Torcieu,Torcieu,1,Ain,84,Auvergne-Rhône-Alpes
1422,TOSSIAT,1250,TOSSIAT,,46.1384790158,5.30400055922,422,,Tossiat,Tossiat,1,Ain,84,Auvergne-Rhône-Alpes
1423,TOUSSIEUX,1600,TOUSSIEUX,,45.959898291,4.82898610612,423,,Toussieux,Toussieux,1,Ain,84,Auvergne-Rhône-Alpes
1424,TRAMOYES,1390,TRAMOYES,,45.8745670311,4.9639268325,424,,Tramoyes,Tramoyes,1,Ain,84,Auvergne-Rhône-Alpes
1425,LA TRANCLIERE,1160,LA TRANCLIERE,,46.0970027075,5.26099875456,425,La,Tranclière,La Tranclière,1,Ain,84,Auvergne-Rhône-Alpes
1426,VAL REVERMONT,1370,VAL REVERMONT,CUISIAT,46.2803281838,5.36083165162,426,,Val-Revermont,Val-Revermont,1,Ain,84,Auvergne-Rhône-Alpes
1426,VAL REVERMONT,1370,VAL REVERMONT,PRESSIAT,46.2803281838,5.36083165162,426,,Val-Revermont,Val-Revermont,1,Ain,84,Auvergne-Rhône-Alpes
1426,VAL REVERMONT,1370,VAL REVERMONT,TREFFORT CUISIAT,46.2803281838,5.36083165162,426,,Val-Revermont,Val-Revermont,1,Ain,84,Auvergne-Rhône-Alpes
1427,TREVOUX,1600,TREVOUX,,45.940671655,4.7714415007,427,,Trévoux,Trévoux,1,Ain,84,Auvergne-Rhône-Alpes
1428,VALEINS,1140,VALEINS,,46.1146467438,4.86551696868,428,,Valeins,Valeins,1,Ain,84,Auvergne-Rhône-Alpes
1429,VANDEINS,1660,VANDEINS,,46.217806795,5.08189056957,429,,Vandeins,Vandeins,1,Ain,84,Auvergne-Rhône-Alpes
1430,VARAMBON,1160,VARAMBON,,46.0433928197,5.2947944594,430,,Varambon,Varambon,1,Ain,84,Auvergne-Rhône-Alpes
1431,VAUX EN BUGEY,1150,VAUX EN BUGEY,,45.9201205456,5.36353184193,431,,Vaux-en-Bugey,Vaux-en-Bugey,1,Ain,84,Auvergne-Rhône-Alpes
1432,VERJON,1270,VERJON,,46.3459093717,5.34853019646,432,,Verjon,Verjon,1,Ain,84,Auvergne-Rhône-Alpes
1433,VERNOUX,1560,VERNOUX,,46.4848229774,5.10590413121,433,,Vernoux,Vernoux,1,Ain,84,Auvergne-Rhône-Alpes
1434,VERSAILLEUX,1330,VERSAILLEUX,,45.9854373478,5.11204819151,434,,Versailleux,Versailleux,1,Ain,84,Auvergne-Rhône-Alpes
1435,VERSONNEX,1210,VERSONNEX,,46.2997631425,6.10057952207,435,,Versonnex,Versonnex,1,Ain,84,Auvergne-Rhône-Alpes
1436,VESANCY,1170,VESANCY,,46.3610768127,6.08470665475,436,,Vesancy,Vesancy,1,Ain,84,Auvergne-Rhône-Alpes
1437,VESCOURS,1560,VESCOURS,,46.4743960759,5.02617426573,437,,Vescours,Vescours,1,Ain,84,Auvergne-Rhône-Alpes
1439,VESINES,1570,VESINES,,46.3593397751,4.86265852202,439,,Vésines,Vésines,1,Ain,84,Auvergne-Rhône-Alpes
1441,VIEU D IZENAVE,1430,VIEU D IZENAVE,,46.0853386653,5.54922100161,441,,Vieu-d'Izenave,Vieu-d'Izenave,1,Ain,84,Auvergne-Rhône-Alpes
1442,VIEU,1260,VIEU,,45.8898392612,5.68999399947,442,,Vieu,Vieu,1,Ain,84,Auvergne-Rhône-Alpes
1443,VILLARS LES DOMBES,1330,VILLARS LES DOMBES,,45.9927150222,5.04507856642,443,,Villars-les-Dombes,Villars-les-Dombes,1,Ain,84,Auvergne-Rhône-Alpes
1444,VILLEBOIS,1150,VILLEBOIS,,45.8485624628,5.44711117486,444,,Villebois,Villebois,1,Ain,84,Auvergne-Rhône-Alpes
1445,VILLEMOTIER,1270,VILLEMOTIER,,46.3458538978,5.31036160991,445,,Villemotier,Villemotier,1,Ain,84,Auvergne-Rhône-Alpes
1446,VILLENEUVE,1480,VILLENEUVE,,46.0319048788,4.85182526102,446,,Villeneuve,Villeneuve,1,Ain,84,Auvergne-Rhône-Alpes
1447,VILLEREVERSURE,1250,VILLEREVERSURE,,46.1926750369,5.3947679407,447,,Villereversure,Villereversure,1,Ain,84,Auvergne-Rhône-Alpes
1448,VILLES,1200,VILLES,,46.0954293058,5.7569250415,448,,Villes,Villes,1,Ain,84,Auvergne-Rhône-Alpes
1449,VILLETTE SUR AIN,1320,VILLETTE SUR AIN,,46.0099989032,5.25214547187,449,,Villette-sur-Ain,Villette-sur-Ain,1,Ain,84,Auvergne-Rhône-Alpes
1450,VILLIEU LOYES MOLLON,1800,VILLIEU LOYES MOLLON,,45.932014998,5.22953071636,450,,Villieu-Loyes-Mollon,Villieu-Loyes-Mollon,1,Ain,84,Auvergne-Rhône-Alpes
1450,VILLIEU LOYES MOLLON,1800,VILLIEU LOYES MOLLON,LOYES,45.932014998,5.22953071636,450,,Villieu-Loyes-Mollon,Villieu-Loyes-Mollon,1,Ain,84,Auvergne-Rhône-Alpes
1450,VILLIEU LOYES MOLLON,1800,VILLIEU LOYES MOLLON,MOLLON,45.932014998,5.22953071636,450,,Villieu-Loyes-Mollon,Villieu-Loyes-Mollon,1,Ain,84,Auvergne-Rhône-Alpes
1451,VIRIAT,1440,VIRIAT,,46.2508985632,5.22302249216,451,,Viriat,Viriat,1,Ain,84,Auvergne-Rhône-Alpes
1452,VIRIEU LE GRAND,1510,VIRIEU LE GRAND,,45.8464417872,5.63971940347,452,,Virieu-le-Grand,Virieu-le-Grand,1,Ain,84,Auvergne-Rhône-Alpes
1453,VIRIEU LE PETIT,1260,VIRIEU LE PETIT,,45.9063608209,5.73707051523,453,,Virieu-le-Petit,Virieu-le-Petit,1,Ain,84,Auvergne-Rhône-Alpes
1454,VIRIGNIN,1300,VIRIGNIN,,45.7267387762,5.71282330936,454,,Virignin,Virignin,1,Ain,84,Auvergne-Rhône-Alpes
1456,VONGNES,1350,VONGNES,,45.8129059524,5.72536666767,456,,Vongnes,Vongnes,1,Ain,84,Auvergne-Rhône-Alpes
1457,VONNAS,1540,VONNAS,,46.2168871367,4.99744373712,457,,Vonnas,Vonnas,1,Ain,84,Auvergne-Rhône-Alpes
2001,ABBECOURT,2300,ABBECOURT,,49.5991518653,3.17708232927,1,,Abbécourt,Abbécourt,2,Aisne,32,Hauts-de-France
2002,ACHERY,2800,ACHERY,,49.6936187791,3.39735632148,2,,Achery,Achery,2,Aisne,32,Hauts-de-France
2003,ACY,2200,ACY,,49.3471963534,3.41817609834,3,,Acy,Acy,2,Aisne,32,Hauts-de-France
2004,AGNICOURT ET SECHELLES,2340,AGNICOURT ET SECHELLES,,49.7166573626,3.96043211816,4,,Agnicourt-et-Séchelles,Agnicourt-et-Séchelles,2,Aisne,32,Hauts-de-France
2005,AGUILCOURT,2190,AGUILCOURT,,49.393650796,3.96803679426,5,,Aguilcourt,Aguilcourt,2,Aisne,32,Hauts-de-France
2006,AISONVILLE ET BERNOVILLE,2110,AISONVILLE ET BERNOVILLE,,49.930148162,3.51120205593,6,,Aisonville-et-Bernoville,Aisonville-et-Bernoville,2,Aisne,32,Hauts-de-France
2007,AIZELLES,2820,AIZELLES,,49.4848002918,3.81870255331,7,,Aizelles,Aizelles,2,Aisne,32,Hauts-de-France
2008,AIZY JOUY,2370,AIZY JOUY,,49.4384256824,3.51149185429,8,,Aizy-Jouy,Aizy-Jouy,2,Aisne,32,Hauts-de-France
2008,AIZY JOUY,2370,AIZY JOUY,JOUY,49.4384256824,3.51149185429,8,,Aizy-Jouy,Aizy-Jouy,2,Aisne,32,Hauts-de-France
2009,ALAINCOURT,2240,ALAINCOURT,,49.7667120841,3.36639043616,9,,Alaincourt,Alaincourt,2,Aisne,32,Hauts-de-France
2010,ALLEMANT,2320,ALLEMANT,,49.4624440593,3.45394275872,10,,Allemant,Allemant,2,Aisne,32,Hauts-de-France
2011,AMBLENY,2290,AMBLENY,,49.3762672939,3.18334679178,11,,Ambleny,Ambleny,2,Aisne,32,Hauts-de-France
2012,AMBRIEF,2200,AMBRIEF,,49.3215250088,3.40362196255,12,,Ambrief,Ambrief,2,Aisne,32,Hauts-de-France
2013,AMIFONTAINE,2190,AMIFONTAINE,,49.498787026,3.92278993322,13,,Amifontaine,Amifontaine,2,Aisne,32,Hauts-de-France
2014,AMIGNY ROUY,2700,AMIGNY ROUY,,49.6108275885,3.30289341579,14,,Amigny-Rouy,Amigny-Rouy,2,Aisne,32,Hauts-de-France
2015,ANCIENVILLE,2600,ANCIENVILLE,,49.2186389559,3.20808809779,15,,Ancienville,Ancienville,2,Aisne,32,Hauts-de-France
2016,ANDELAIN,2800,ANDELAIN,,49.6411808161,3.3695440898,16,,Andelain,Andelain,2,Aisne,32,Hauts-de-France
2017,ANGUILCOURT LE SART,2800,ANGUILCOURT LE SART,,49.6940879195,3.42823182437,17,,Anguilcourt-le-Sart,Anguilcourt-le-Sart,2,Aisne,32,Hauts-de-France
2018,ANIZY LE CHATEAU,2320,ANIZY LE CHATEAU,,49.5045058305,3.43129584952,18,,Anizy-le-Château,Anizy-le-Château,2,Aisne,32,Hauts-de-France
2019,ANNOIS,2480,ANNOIS,,49.7210674108,3.17611501401,19,,Annois,Annois,2,Aisne,32,Hauts-de-France
2020,ANY MARTIN RIEUX,2500,ANY MARTIN RIEUX,,49.8823706752,4.22351346907,20,,Any-Martin-Rieux,Any-Martin-Rieux,2,Aisne,32,Hauts-de-France
2021,ARCHON,2360,ARCHON,,49.744126618,4.11908765808,21,,Archon,Archon,2,Aisne,32,Hauts-de-France
2022,ARCY STE RESTITUE,2130,ARCY STE RESTITUE,,49.2548799371,3.47829076337,22,,Arcy-Sainte-Restitue,Arcy-Sainte-Restitue,2,Aisne,32,Hauts-de-France
2022,ARCY STE RESTITUE,2130,ARCY STE RESTITUE,BRANGES,49.2548799371,3.47829076337,22,,Arcy-Sainte-Restitue,Arcy-Sainte-Restitue,2,Aisne,32,Hauts-de-France
2023,ARMENTIERES SUR OURCQ,2210,ARMENTIERES SUR OURCQ,,49.1734232511,3.38789647903,23,,Armentières-sur-Ourcq,Armentières-sur-Ourcq,2,Aisne,32,Hauts-de-France
2024,ARRANCY,2860,ARRANCY,,49.4989016398,3.7647230005,24,,Arrancy,Arrancy,2,Aisne,32,Hauts-de-France
2025,ARTEMPS,2480,ARTEMPS,,49.7617999337,3.19725095546,25,,Artemps,Artemps,2,Aisne,32,Hauts-de-France
2027,ASSIS SUR SERRE,2270,ASSIS SUR SERRE,,49.6803739117,3.55491692691,27,,Assis-sur-Serre,Assis-sur-Serre,2,Aisne,32,Hauts-de-France
2028,ATHIES SOUS LAON,2840,ATHIES SOUS LAON,,49.5757750686,3.69006632109,28,,Athies-sous-Laon,Athies-sous-Laon,2,Aisne,32,Hauts-de-France
2029,ATTILLY,2490,ATTILLY,,49.8554489068,3.15363305662,29,,Attilly,Attilly,2,Aisne,32,Hauts-de-France
2030,AUBENCHEUL AUX BOIS,2420,AUBENCHEUL AUX BOIS,,50.0239903854,3.26103215604,30,,Aubencheul-aux-Bois,Aubencheul-aux-Bois,2,Aisne,32,Hauts-de-France
2031,AUBENTON,2500,AUBENTON,,49.8252306759,4.19066939985,31,,Aubenton,Aubenton,2,Aisne,32,Hauts-de-France
2032,AUBIGNY AUX KAISNES,2590,AUBIGNY AUX KAISNES,,49.7721524634,3.1192565988,32,,Aubigny-aux-Kaisnes,Aubigny-aux-Kaisnes,2,Aisne,32,Hauts-de-France
2033,AUBIGNY EN LAONNOIS,2820,AUBIGNY EN LAONNOIS,,49.4968821185,3.79282850765,33,,Aubigny-en-Laonnois,Aubigny-en-Laonnois,2,Aisne,32,Hauts-de-France
2034,AUDIGNICOURT,2300,AUDIGNICOURT,,49.4777157214,3.12718502825,34,,Audignicourt,Audignicourt,2,Aisne,32,Hauts-de-France
2035,AUDIGNY,2120,AUDIGNY,,49.8660645425,3.6312599901,35,,Audigny,Audigny,2,Aisne,32,Hauts-de-France
2036,AUGY,2220,AUGY,,49.340063724,3.5110198819,36,,Augy,Augy,2,Aisne,32,Hauts-de-France
2037,AULNOIS SOUS LAON,2000,AULNOIS SOUS LAON,,49.6198117499,3.60981962105,37,,Aulnois-sous-Laon,Aulnois-sous-Laon,2,Aisne,32,Hauts-de-France
2038,LES AUTELS,2360,LES AUTELS,,49.7608855336,4.22422505762,38,Les,Autels,Les Autels,2,Aisne,32,Hauts-de-France
2039,AUTREMENCOURT,2250,AUTREMENCOURT,,49.7051244442,3.79419867292,39,,Autremencourt,Autremencourt,2,Aisne,32,Hauts-de-France
2040,AUTREPPES,2580,AUTREPPES,,49.8969849321,3.85601033505,40,,Autreppes,Autreppes,2,Aisne,32,Hauts-de-France
2041,AUTREVILLE,2300,AUTREVILLE,,49.5860875305,3.23602708427,41,,Autreville,Autreville,2,Aisne,32,Hauts-de-France
2042,AZY SUR MARNE,2400,AZY SUR MARNE,,49.0039701095,3.35913214045,42,,Azy-sur-Marne,Azy-sur-Marne,2,Aisne,32,Hauts-de-France
2043,BAGNEUX,2290,BAGNEUX,,49.4601139731,3.27945330014,43,,Bagneux,Bagneux,2,Aisne,32,Hauts-de-France
2044,BANCIGNY,2140,BANCIGNY,,49.7978711595,4.03669866073,44,,Bancigny,Bancigny,2,Aisne,32,Hauts-de-France
2046,BARENTON BUGNY,2000,BARENTON BUGNY,,49.6218589779,3.66103056579,46,,Barenton-Bugny,Barenton-Bugny,2,Aisne,32,Hauts-de-France
2047,BARENTON CEL,2000,BARENTON CEL,,49.6477517259,3.64469997459,47,,Barenton-Cel,Barenton-Cel,2,Aisne,32,Hauts-de-France
2048,BARENTON SUR SERRE,2270,BARENTON SUR SERRE,,49.66833977,3.68940610952,48,,Barenton-sur-Serre,Barenton-sur-Serre,2,Aisne,32,Hauts-de-France
2049,BARISIS AUX BOIS,2700,BARISIS AUX BOIS,,49.577979369,3.32804487461,49,,Barisis-aux-Bois,Barisis-aux-Bois,2,Aisne,32,Hauts-de-France
2050,BARZY EN THIERACHE,2170,BARZY EN THIERACHE,,50.0395375873,3.74901595409,50,,Barzy-en-Thiérache,Barzy-en-Thiérache,2,Aisne,32,Hauts-de-France
2051,BARZY SUR MARNE,2850,BARZY SUR MARNE,,49.0906196202,3.56214117254,51,,Barzy-sur-Marne,Barzy-sur-Marne,2,Aisne,32,Hauts-de-France
2052,BASSOLES AULERS,2380,BASSOLES AULERS,,49.5332813699,3.38492604839,52,,Bassoles-Aulers,Bassoles-Aulers,2,Aisne,32,Hauts-de-France
2053,VALLEES EN CHAMPAGNE,2330,VALLEES EN CHAMPAGNE,BAULNE EN BRIE,48.9896915811,3.60486686026,53,,Vallées en Champagne,Vallées en Champagne,2,Aisne,32,Hauts-de-France
2053,VALLEES EN CHAMPAGNE,2330,VALLEES EN CHAMPAGNE,LA CHAPELLE MONTHODON,48.9896915811,3.60486686026,53,,Vallées en Champagne,Vallées en Champagne,2,Aisne,32,Hauts-de-France
2053,VALLEES EN CHAMPAGNE,2330,VALLEES EN CHAMPAGNE,ST AGNAN,48.9896915811,3.60486686026,53,,Vallées en Champagne,Vallées en Champagne,2,Aisne,32,Hauts-de-France
2054,BAZOCHES SUR VESLES,2220,BAZOCHES SUR VESLES,,49.3070586788,3.61583126589,54,,Bazoches-sur-Vesles,Bazoches-sur-Vesles,2,Aisne,32,Hauts-de-France
2055,BEAUME,2500,BEAUME,,49.8211281836,4.15200066545,55,,Beaumé,Beaumé,2,Aisne,32,Hauts-de-France
2056,BEAUMONT EN BEINE,2300,BEAUMONT EN BEINE,,49.6874633882,3.13133369335,56,,Beaumont-en-Beine,Beaumont-en-Beine,2,Aisne,32,Hauts-de-France
2057,BEAUREVOIR,2110,BEAUREVOIR,,49.9965608612,3.32970842537,57,,Beaurevoir,Beaurevoir,2,Aisne,32,Hauts-de-France
2058,BEAURIEUX,2160,BEAURIEUX,,49.402806797,3.75287161991,58,,Beaurieux,Beaurieux,2,Aisne,32,Hauts-de-France
2059,BEAUTOR,2800,BEAUTOR,,49.648319958,3.33811188104,59,,Beautor,Beautor,2,Aisne,32,Hauts-de-France
2060,BEAUVOIS EN VERMANDOIS,2590,BEAUVOIS EN VERMANDOIS,,49.8339921767,3.10282045546,60,,Beauvois-en-Vermandois,Beauvois-en-Vermandois,2,Aisne,32,Hauts-de-France
2061,BECQUIGNY,2110,BECQUIGNY,,50.0158703814,3.45898644538,61,,Becquigny,Becquigny,2,Aisne,32,Hauts-de-France
2062,BELLEAU,2400,BELLEAU,,49.0829128265,3.30049173293,62,,Belleau,Belleau,2,Aisne,32,Hauts-de-France
2063,BELLENGLISE,2420,BELLENGLISE,,49.9261057081,3.2441994692,63,,Bellenglise,Bellenglise,2,Aisne,32,Hauts-de-France
2064,BELLEU,2200,BELLEU,,49.3567574344,3.34051688283,64,,Belleu,Belleu,2,Aisne,32,Hauts-de-France
2065,BELLICOURT,2420,BELLICOURT,,49.9592991729,3.23020850852,65,,Bellicourt,Bellicourt,2,Aisne,32,Hauts-de-France
2066,BENAY,2440,BENAY,,49.755094123,3.30909898139,66,,Benay,Benay,2,Aisne,32,Hauts-de-France
2067,BERGUES SUR SAMBRE,2450,BERGUES SUR SAMBRE,,50.0294686238,3.711371001,67,,Bergues-sur-Sambre,Bergues-sur-Sambre,2,Aisne,32,Hauts-de-France
2068,BERLANCOURT,2250,BERLANCOURT,,49.7808533631,3.75710714699,68,,Berlancourt,Berlancourt,2,Aisne,32,Hauts-de-France
2069,BERLISE,2340,BERLISE,,49.6622290606,4.11314086897,69,,Berlise,Berlise,2,Aisne,32,Hauts-de-France
2070,BERNOT,2120,BERNOT,,49.8793651304,3.48665456392,70,,Bernot,Bernot,2,Aisne,32,Hauts-de-France
2071,BERNY RIVIERE,2290,BERNY RIVIERE,,49.4113288887,3.14975272702,71,,Berny-Rivière,Berny-Rivière,2,Aisne,32,Hauts-de-France
2072,BERRIEUX,2820,BERRIEUX,,49.4823563962,3.8521031839,72,,Berrieux,Berrieux,2,Aisne,32,Hauts-de-France
2073,BERRY AU BAC,2190,BERRY AU BAC,,49.4084985083,3.8985497785,73,,Berry-au-Bac,Berry-au-Bac,2,Aisne,32,Hauts-de-France
2074,BERTAUCOURT EPOURDON,2800,BERTAUCOURT EPOURDON,,49.6276056573,3.39748988886,74,,Bertaucourt-Epourdon,Bertaucourt-Epourdon,2,Aisne,32,Hauts-de-France
2075,BERTHENICOURT,2240,BERTHENICOURT,,49.7788058177,3.37247241058,75,,Berthenicourt,Berthenicourt,2,Aisne,32,Hauts-de-France
2076,BERTRICOURT,2190,BERTRICOURT,,49.3975285682,4.01146178698,76,,Bertricourt,Bertricourt,2,Aisne,32,Hauts-de-France
2077,BERZY LE SEC,2200,BERZY LE SEC,,49.3172125296,3.29666086699,77,,Berzy-le-Sec,Berzy-le-Sec,2,Aisne,32,Hauts-de-France
2078,BESME,2300,BESME,,49.5341682819,3.16474171361,78,,Besmé,Besmé,2,Aisne,32,Hauts-de-France
2079,BESMONT,2500,BESMONT,,49.823361306,4.11768907822,79,,Besmont,Besmont,2,Aisne,32,Hauts-de-France
2080,BESNY ET LOIZY,2870,BESNY ET LOIZY,,49.5928792816,3.57657238799,80,,Besny-et-Loizy,Besny-et-Loizy,2,Aisne,32,Hauts-de-France
2081,BETHANCOURT EN VAUX,2300,BETHANCOURT EN VAUX,,49.6290547772,3.14064657935,81,,Béthancourt-en-Vaux,Béthancourt-en-Vaux,2,Aisne,32,Hauts-de-France
2082,BEUGNEUX,2210,BEUGNEUX,,49.2275759309,3.41647403702,82,,Beugneux,Beugneux,2,Aisne,32,Hauts-de-France
2083,BEUVARDES,2130,BEUVARDES,,49.1308547007,3.49165019107,83,,Beuvardes,Beuvardes,2,Aisne,32,Hauts-de-France
2084,BEZU LE GUERY,2310,BEZU LE GUERY,,49.0102591835,3.22764610411,84,,Bézu-le-Guéry,Bézu-le-Guéry,2,Aisne,32,Hauts-de-France
2085,BEZU ST GERMAIN,2400,BEZU ST GERMAIN,,49.1046732888,3.40812513224,85,,Bézu-Saint-Germain,Bézu-Saint-Germain,2,Aisne,32,Hauts-de-France
2086,BICHANCOURT,2300,BICHANCOURT,,49.578776546,3.21263017443,86,,Bichancourt,Bichancourt,2,Aisne,32,Hauts-de-France
2087,BIEUXY,2290,BIEUXY,,49.4476050585,3.26985890807,87,,Bieuxy,Bieuxy,2,Aisne,32,Hauts-de-France
2088,BIEVRES,2860,BIEVRES,,49.4921822965,3.7137356904,88,,Bièvres,Bièvres,2,Aisne,32,Hauts-de-France
2089,BILLY SUR AISNE,2200,BILLY SUR AISNE,,49.3556556532,3.37609947556,89,,Billy-sur-Aisne,Billy-sur-Aisne,2,Aisne,32,Hauts-de-France
2090,BILLY SUR OURCQ,2210,BILLY SUR OURCQ,,49.2215032882,3.29903267663,90,,Billy-sur-Ourcq,Billy-sur-Ourcq,2,Aisne,32,Hauts-de-France
2091,BLANZY LES FISMES,2160,BLANZY LES FISMES,,49.3372673738,3.671868408,91,,Blanzy-lès-Fismes,Blanzy-lès-Fismes,2,Aisne,32,Hauts-de-France
2093,BLERANCOURT,2300,BLERANCOURT,,49.5076223945,3.15179502187,93,,Blérancourt,Blérancourt,2,Aisne,32,Hauts-de-France
2094,BLESMES,2400,BLESMES,,49.0264919363,3.46405474705,94,,Blesmes,Blesmes,2,Aisne,32,Hauts-de-France
2095,BOHAIN EN VERMANDOIS,2110,BOHAIN EN VERMANDOIS,,49.989032874,3.46522354976,95,,Bohain-en-Vermandois,Bohain-en-Vermandois,2,Aisne,32,Hauts-de-France
2096,BOIS LES PARGNY,2270,BOIS LES PARGNY,,49.7369350689,3.65414834535,96,,Bois-lès-Pargny,Bois-lès-Pargny,2,Aisne,32,Hauts-de-France
2097,BONCOURT,2350,BONCOURT,,49.6264861998,3.956447719,97,,Boncourt,Boncourt,2,Aisne,32,Hauts-de-France
2098,BONNEIL,2400,BONNEIL,,49.0082419817,3.3459478468,98,,Bonneil,Bonneil,2,Aisne,32,Hauts-de-France
2099,BONNESVALYN,2400,BONNESVALYN,,49.1264049496,3.32541661771,99,,Bonnesvalyn,Bonnesvalyn,2,Aisne,32,Hauts-de-France
2100,BONY,2420,BONY,,49.9908643724,3.22116002448,100,,Bony,Bony,2,Aisne,32,Hauts-de-France
2101,BOSMONT SUR SERRE,2250,BOSMONT SUR SERRE,,49.7390653213,3.86733568391,101,,Bosmont-sur-Serre,Bosmont-sur-Serre,2,Aisne,32,Hauts-de-France
2102,BOUCONVILLE VAUCLAIR,2860,BOUCONVILLE VAUCLAIR,,49.4606212957,3.75658607824,102,,Bouconville-Vauclair,Bouconville-Vauclair,2,Aisne,32,Hauts-de-France
2103,BOUE,2450,BOUE,,50.0114554001,3.71432024852,103,,Boué,Boué,2,Aisne,32,Hauts-de-France
2104,BOUFFIGNEREUX,2160,BOUFFIGNEREUX,,49.3788374998,3.84626014622,104,,Bouffignereux,Bouffignereux,2,Aisne,32,Hauts-de-France
2105,BOURESCHES,2400,BOURESCHES,,49.0671787396,3.31728226934,105,,Bouresches,Bouresches,2,Aisne,32,Hauts-de-France
2106,BOURG ET COMIN,2160,BOURG ET COMIN,,49.4022354002,3.65944342757,106,,Bourg-et-Comin,Bourg-et-Comin,2,Aisne,32,Hauts-de-France
2107,BOURGUIGNON SOUS COUCY,2300,BOURGUIGNON SOUS COUCY,,49.5427641575,3.15422957657,107,,Bourguignon-sous-Coucy,Bourguignon-sous-Coucy,2,Aisne,32,Hauts-de-France
2108,BOURGUIGNON SOUS MONTBAVIN,2000,BOURGUIGNON SOUS MONTBAVIN,,49.5288056883,3.53777236106,108,,Bourguignon-sous-Montbavin,Bourguignon-sous-Montbavin,2,Aisne,32,Hauts-de-France
2109,LA BOUTEILLE,2140,LA BOUTEILLE,,49.8778828965,3.96243530235,109,La,Bouteille,La Bouteille,2,Aisne,32,Hauts-de-France
2110,BRAINE,2220,BRAINE,,49.3484719318,3.53596629744,110,,Braine,Braine,2,Aisne,32,Hauts-de-France
2111,BRANCOURT EN LAONNOIS,2320,BRANCOURT EN LAONNOIS,,49.5176286609,3.41282321335,111,,Brancourt-en-Laonnois,Brancourt-en-Laonnois,2,Aisne,32,Hauts-de-France
2112,BRANCOURT LE GRAND,2110,BRANCOURT LE GRAND,,49.98081057,3.39391192109,112,,Brancourt-le-Grand,Brancourt-le-Grand,2,Aisne,32,Hauts-de-France
2114,BRASLES,2400,BRASLES,,49.0567927241,3.42453409652,114,,Brasles,Brasles,2,Aisne,32,Hauts-de-France
2115,BRAYE EN LAONNOIS,2000,BRAYE EN LAONNOIS,,49.4375773374,3.60977976284,115,,Braye-en-Laonnois,Braye-en-Laonnois,2,Aisne,32,Hauts-de-France
2116,BRAYE EN THIERACHE,2140,BRAYE EN THIERACHE,,49.7675470994,3.96265841899,116,,Braye-en-Thiérache,Braye-en-Thiérache,2,Aisne,32,Hauts-de-France
2117,BRAY ST CHRISTOPHE,2480,BRAY ST CHRISTOPHE,,49.7746700975,3.14070965091,117,,Bray-Saint-Christophe,Bray-Saint-Christophe,2,Aisne,32,Hauts-de-France
2118,BRAYE,2880,BRAYE,,49.4221361409,3.37829006511,118,,Braye,Braye,2,Aisne,32,Hauts-de-France
2119,BRECY,2210,BRECY,,49.1398626041,3.42373097034,119,,Brécy,Brécy,2,Aisne,32,Hauts-de-France
2120,BRENELLE,2220,BRENELLE,,49.3680052078,3.5442035574,120,,Brenelle,Brenelle,2,Aisne,32,Hauts-de-France
2121,BRENY,2210,BRENY,,49.1842783438,3.34972495405,121,,Breny,Breny,2,Aisne,32,Hauts-de-France
2122,BRIE,2870,BRIE,,49.5973962294,3.4733634659,122,,Brie,Brie,2,Aisne,32,Hauts-de-France
2123,BRISSAY CHOIGNY,2240,BRISSAY CHOIGNY,,49.7228299725,3.39639768252,123,,Brissay-Choigny,Brissay-Choigny,2,Aisne,32,Hauts-de-France
2124,BRISSY HAMEGICOURT,2240,BRISSY HAMEGICOURT,,49.7432786375,3.39968837909,124,,Brissy-Hamégicourt,Brissy-Hamégicourt,2,Aisne,32,Hauts-de-France
2125,BRUMETZ,2810,BRUMETZ,,49.1103074474,3.15291445147,125,,Brumetz,Brumetz,2,Aisne,32,Hauts-de-France
2126,BRUNEHAMEL,2360,BRUNEHAMEL,,49.7718100626,4.18649398572,126,,Brunehamel,Brunehamel,2,Aisne,32,Hauts-de-France
2127,BRUYERES SUR FERE,2130,BRUYERES SUR FERE,,49.1996571467,3.44535846468,127,,Bruyères-sur-Fère,Bruyères-sur-Fère,2,Aisne,32,Hauts-de-France
2128,BRUYERES ET MONTBERAULT,2860,BRUYERES ET MONTBERAULT,,49.5239615523,3.66954693798,128,,Bruyères-et-Montbérault,Bruyères-et-Montbérault,2,Aisne,32,Hauts-de-France
2129,BRUYS,2220,BRUYS,,49.2621681637,3.56863439754,129,,Bruys,Bruys,2,Aisne,32,Hauts-de-France
2130,BUCILLY,2500,BUCILLY,,49.8658103548,4.09323369949,130,,Bucilly,Bucilly,2,Aisne,32,Hauts-de-France
2131,BUCY LE LONG,2880,BUCY LE LONG,,49.3884447185,3.39828602712,131,,Bucy-le-Long,Bucy-le-Long,2,Aisne,32,Hauts-de-France
2132,BUCY LES CERNY,2870,BUCY LES CERNY,,49.5790852566,3.496908306,132,,Bucy-lès-Cerny,Bucy-lès-Cerny,2,Aisne,32,Hauts-de-France
2133,BUCY LES PIERREPONT,2350,BUCY LES PIERREPONT,,49.6467998089,3.90358311553,133,,Bucy-lès-Pierrepont,Bucy-lès-Pierrepont,2,Aisne,32,Hauts-de-France
2134,BUIRE,2500,BUIRE,,49.9070296259,4.05674542304,134,,Buire,Buire,2,Aisne,32,Hauts-de-France
2135,BUIRONFOSSE,2620,BUIRONFOSSE,,49.9577111138,3.838016082,135,,Buironfosse,Buironfosse,2,Aisne,32,Hauts-de-France
2136,BURELLES,2140,BURELLES,,49.7687046874,3.91196369709,136,,Burelles,Burelles,2,Aisne,32,Hauts-de-France
2137,BUSSIARES,2810,BUSSIARES,,49.0765910572,3.25026191909,137,,Bussiares,Bussiares,2,Aisne,32,Hauts-de-France
2138,BUZANCY,2200,BUZANCY,,49.3099528163,3.3478744079,138,,Buzancy,Buzancy,2,Aisne,32,Hauts-de-France
2139,CAILLOUEL CREPIGNY,2300,CAILLOUEL CREPIGNY,,49.6176055636,3.12169527347,139,,Caillouël-Crépigny,Caillouël-Crépigny,2,Aisne,32,Hauts-de-France
2140,CAMELIN,2300,CAMELIN,,49.5205248253,3.12854853594,140,,Camelin,Camelin,2,Aisne,32,Hauts-de-France
2140,CAMELIN,2300,CAMELIN,LOMBRAY,49.5205248253,3.12854853594,140,,Camelin,Camelin,2,Aisne,32,Hauts-de-France
2141,LA CAPELLE,2260,LA CAPELLE,,49.9732109539,3.91398483546,141,La,Capelle,La Capelle,2,Aisne,32,Hauts-de-France
2142,CASTRES,2680,CASTRES,,49.8013894137,3.25325245088,142,,Castres,Castres,2,Aisne,32,Hauts-de-France
2143,LE CATELET,2420,LE CATELET,,50.0009779444,3.2423098464,143,Le,Catelet,Le Catelet,2,Aisne,32,Hauts-de-France
2144,CAULAINCOURT,2490,CAULAINCOURT,,49.864658382,3.11126489473,144,,Caulaincourt,Caulaincourt,2,Aisne,32,Hauts-de-France
2145,CAUMONT,2300,CAUMONT,,49.6372659106,3.17347612819,145,,Caumont,Caumont,2,Aisne,32,Hauts-de-France
2146,CELLES LES CONDE,2330,CELLES LES CONDE,,49.0148161041,3.57007423847,146,,Celles-lès-Condé,Celles-lès-Condé,2,Aisne,32,Hauts-de-France
2148,CELLES SUR AISNE,2370,CELLES SUR AISNE,,49.4159264546,3.47446927431,148,,Celles-sur-Aisne,Celles-sur-Aisne,2,Aisne,32,Hauts-de-France
2149,CERIZY,2240,CERIZY,,49.7670333979,3.33255021354,149,,Cerizy,Cerizy,2,Aisne,32,Hauts-de-France
2150,CERNY EN LAONNOIS,2860,CERNY EN LAONNOIS,,49.4500150087,3.67982439455,150,,Cerny-en-Laonnois,Cerny-en-Laonnois,2,Aisne,32,Hauts-de-France
2151,CERNY LES BUCY,2870,CERNY LES BUCY,,49.5778658953,3.54646763896,151,,Cerny-lès-Bucy,Cerny-lès-Bucy,2,Aisne,32,Hauts-de-France
2152,CERSEUIL,2220,CERSEUIL,,49.324784775,3.51746919082,152,,Cerseuil,Cerseuil,2,Aisne,32,Hauts-de-France
2153,CESSIERES,2320,CESSIERES,,49.5595788079,3.50190405701,153,,Cessières,Cessières,2,Aisne,32,Hauts-de-France
2154,CHACRISE,2200,CHACRISE,,49.3002009035,3.39759102537,154,,Chacrise,Chacrise,2,Aisne,32,Hauts-de-France
2155,CHAILLEVOIS,2000,CHAILLEVOIS,,49.5081654549,3.52234605948,155,,Chaillevois,Chaillevois,2,Aisne,32,Hauts-de-France
2156,CHALANDRY,2270,CHALANDRY,,49.6702675165,3.64445509533,156,,Chalandry,Chalandry,2,Aisne,32,Hauts-de-France
2157,CHAMBRY,2000,CHAMBRY,,49.5986366056,3.67200832611,157,,Chambry,Chambry,2,Aisne,32,Hauts-de-France
2158,CHAMOUILLE,2860,CHAMOUILLE,,49.4720646954,3.67062673062,158,,Chamouille,Chamouille,2,Aisne,32,Hauts-de-France
2159,CHAMPS,2670,CHAMPS,,49.5434967943,3.2618311238,159,,Champs,Champs,2,Aisne,32,Hauts-de-France
2160,CHAOURSE,2340,CHAOURSE,,49.7007898116,3.98562292281,160,,Chaourse,Chaourse,2,Aisne,32,Hauts-de-France
2162,LA CHAPELLE SUR CHEZY,2570,LA CHAPELLE SUR CHEZY,,48.9492049177,3.37662807022,162,La,Chapelle-sur-Chézy,La Chapelle-sur-Chézy,2,Aisne,32,Hauts-de-France
2163,CHARLY SUR MARNE,2310,CHARLY SUR MARNE,,48.9898678258,3.29365157876,163,,Charly-sur-Marne,Charly-sur-Marne,2,Aisne,32,Hauts-de-France
2164,LE CHARMEL,2850,LE CHARMEL,,49.1121866037,3.54125960951,164,Le,Charmel,Le Charmel,2,Aisne,32,Hauts-de-France
2165,CHARMES,2800,CHARMES,,49.6508532429,3.38525104222,165,,Charmes,Charmes,2,Aisne,32,Hauts-de-France
2166,CHARTEVES,2400,CHARTEVES,,49.0898127369,3.50871838479,166,,Chartèves,Chartèves,2,Aisne,32,Hauts-de-France
2167,CHASSEMY,2370,CHASSEMY,,49.3783410842,3.50271467967,167,,Chassemy,Chassemy,2,Aisne,32,Hauts-de-France
2168,CHATEAU THIERRY,2400,CHATEAU THIERRY,,49.0564070801,3.38160004843,168,,Château-Thierry,Château-Thierry,2,Aisne,32,Hauts-de-France
2169,CHATILLON LES SONS,2270,CHATILLON LES SONS,,49.7618503091,3.70312508858,169,,Châtillon-lès-Sons,Châtillon-lès-Sons,2,Aisne,32,Hauts-de-France
2170,CHATILLON SUR OISE,2240,CHATILLON SUR OISE,,49.7938161671,3.41630401708,170,,Châtillon-sur-Oise,Châtillon-sur-Oise,2,Aisne,32,Hauts-de-France
2171,CHAUDARDES,2160,CHAUDARDES,,49.3962562981,3.78942153696,171,,Chaudardes,Chaudardes,2,Aisne,32,Hauts-de-France
2172,CHAUDUN,2200,CHAUDUN,,49.3150223009,3.25276338247,172,,Chaudun,Chaudun,2,Aisne,32,Hauts-de-France
2173,CHAUNY,2300,CHAUNY,,49.619880673,3.21875355041,173,,Chauny,Chauny,2,Aisne,32,Hauts-de-France
2174,CHAVIGNON,2000,CHAVIGNON,,49.4794437147,3.51936895626,174,,Chavignon,Chavignon,2,Aisne,32,Hauts-de-France
2175,CHAVIGNY,2880,CHAVIGNY,,49.4232730431,3.30567490669,175,,Chavigny,Chavigny,2,Aisne,32,Hauts-de-France
2176,CHAVONNE,2370,CHAVONNE,,49.4073162185,3.56243209622,176,,Chavonne,Chavonne,2,Aisne,32,Hauts-de-France
2177,CHERET,2860,CHERET,,49.5141661683,3.68718851388,177,,Chérêt,Chérêt,2,Aisne,32,Hauts-de-France
2178,CHERMIZY AILLES,2860,CHERMIZY AILLES,,49.4629888139,3.71995479046,178,,Chermizy-Ailles,Chermizy-Ailles,2,Aisne,32,Hauts-de-France
2179,CHERY CHARTREUVE,2220,CHERY CHARTREUVE,,49.2556867214,3.60484629397,179,,Chéry-Chartreuve,Chéry-Chartreuve,2,Aisne,32,Hauts-de-France
2180,CHERY LES POUILLY,2000,CHERY LES POUILLY,,49.6488389918,3.59416271271,180,,Chéry-lès-Pouilly,Chéry-lès-Pouilly,2,Aisne,32,Hauts-de-France
2181,CHERY LES ROZOY,2360,CHERY LES ROZOY,,49.7222192886,4.1011989282,181,,Chéry-lès-Rozoy,Chéry-lès-Rozoy,2,Aisne,32,Hauts-de-France
2182,CHEVENNES,2250,CHEVENNES,,49.818366417,3.7371313317,182,,Chevennes,Chevennes,2,Aisne,32,Hauts-de-France
2183,CHEVREGNY,2000,CHEVREGNY,,49.4661009452,3.59365333616,183,,Chevregny,Chevregny,2,Aisne,32,Hauts-de-France
2184,CHEVRESIS MONCEAU,2270,CHEVRESIS MONCEAU,,49.7616780422,3.5837583969,184,,Chevresis-Monceau,Chevresis-Monceau,2,Aisne,32,Hauts-de-France
2185,CHEZY EN ORXOIS,2810,CHEZY EN ORXOIS,,49.1268031885,3.16759094422,185,,Chézy-en-Orxois,Chézy-en-Orxois,2,Aisne,32,Hauts-de-France
2186,CHEZY SUR MARNE,2570,CHEZY SUR MARNE,,48.983996655,3.38226485102,186,,Chézy-sur-Marne,Chézy-sur-Marne,2,Aisne,32,Hauts-de-France
2187,CHIERRY,2400,CHIERRY,,49.035155356,3.43302324278,187,,Chierry,Chierry,2,Aisne,32,Hauts-de-France
2188,CHIGNY,2120,CHIGNY,,49.9298846139,3.77489645427,188,,Chigny,Chigny,2,Aisne,32,Hauts-de-France
2189,CHIVRES EN LAONNOIS,2350,CHIVRES EN LAONNOIS,,49.6268606782,3.85603255601,189,,Chivres-en-Laonnois,Chivres-en-Laonnois,2,Aisne,32,Hauts-de-France
2190,CHIVRES VAL,2880,CHIVRES VAL,,49.4029298198,3.43836038727,190,,Chivres-Val,Chivres-Val,2,Aisne,32,Hauts-de-France
2191,CHIVY LES ETOUVELLES,2000,CHIVY LES ETOUVELLES,,49.5326303582,3.58562168803,191,,Chivy-lès-Étouvelles,Chivy-lès-Étouvelles,2,Aisne,32,Hauts-de-France
2192,CHOUY,2210,CHOUY,,49.2167334121,3.24708004454,192,,Chouy,Chouy,2,Aisne,32,Hauts-de-France
2193,CIERGES,2130,CIERGES,,49.1648316775,3.60411788847,193,,Cierges,Cierges,2,Aisne,32,Hauts-de-France
2194,CILLY,2250,CILLY,,49.7490011958,3.84000022806,194,,Cilly,Cilly,2,Aisne,32,Hauts-de-France
2195,CIRY SALSOGNE,2220,CIRY SALSOGNE,,49.3674868367,3.46778299062,195,,Ciry-Salsogne,Ciry-Salsogne,2,Aisne,32,Hauts-de-France
2196,CLACY ET THIERRET,2000,CLACY ET THIERRET,,49.5524137158,3.56557185412,196,,Clacy-et-Thierret,Clacy-et-Thierret,2,Aisne,32,Hauts-de-France
2197,CLAIRFONTAINE,2260,CLAIRFONTAINE,,49.9739945177,3.98660655282,197,,Clairfontaine,Clairfontaine,2,Aisne,32,Hauts-de-France
2198,CLAMECY,2880,CLAMECY,,49.4281077825,3.36378754787,198,,Clamecy,Clamecy,2,Aisne,32,Hauts-de-France
2199,CLASTRES,2440,CLASTRES,,49.7488769741,3.2302033407,199,,Clastres,Clastres,2,Aisne,32,Hauts-de-France
2200,CLERMONT LES FERMES,2340,CLERMONT LES FERMES,,49.6601654327,3.94320327145,200,,Clermont-les-Fermes,Clermont-les-Fermes,2,Aisne,32,Hauts-de-France
2201,COEUVRES ET VALSERY,2600,COEUVRES ET VALSERY,,49.3295432232,3.15290453511,201,,Cœuvres-et-Valsery,Cœuvres-et-Valsery,2,Aisne,32,Hauts-de-France
2203,COINCY,2210,COINCY,,49.1632280955,3.43633795455,203,,Coincy,Coincy,2,Aisne,32,Hauts-de-France
2204,COINGT,2360,COINGT,,49.7923246397,4.09591303887,204,,Coingt,Coingt,2,Aisne,32,Hauts-de-France
2205,COLLIGIS CRANDELAIN,2860,COLLIGIS CRANDELAIN,,49.4666159338,3.63042181928,205,,Colligis-Crandelain,Colligis-Crandelain,2,Aisne,32,Hauts-de-France
2206,COLONFAY,2120,COLONFAY,,49.8584586783,3.71138683561,206,,Colonfay,Colonfay,2,Aisne,32,Hauts-de-France
2207,COMMENCHON,2300,COMMENCHON,,49.6416913377,3.15095437349,207,,Commenchon,Commenchon,2,Aisne,32,Hauts-de-France
2208,CONCEVREUX,2160,CONCEVREUX,,49.3764478648,3.78830446161,208,,Concevreux,Concevreux,2,Aisne,32,Hauts-de-France
2209,CONDE EN BRIE,2330,CONDE EN BRIE,,49.0016101753,3.56116999962,209,,Condé-en-Brie,Condé-en-Brie,2,Aisne,32,Hauts-de-France
2210,CONDE SUR AISNE,2370,CONDE SUR AISNE,,49.4007552828,3.46571591087,210,,Condé-sur-Aisne,Condé-sur-Aisne,2,Aisne,32,Hauts-de-France
2211,CONDE SUR SUIPPE,2190,CONDE SUR SUIPPE,,49.4138370327,3.94498449027,211,,Condé-sur-Suippe,Condé-sur-Suippe,2,Aisne,32,Hauts-de-France
2212,CONDREN,2700,CONDREN,,49.6370236167,3.27726632135,212,,Condren,Condren,2,Aisne,32,Hauts-de-France
2213,CONNIGIS,2330,CONNIGIS,,49.0359363932,3.53287282671,213,,Connigis,Connigis,2,Aisne,32,Hauts-de-France
2214,CONTESCOURT,2680,CONTESCOURT,,49.7916934603,3.23428350737,214,,Contescourt,Contescourt,2,Aisne,32,Hauts-de-France
2215,CORBENY,2820,CORBENY,,49.4620558975,3.82654082441,215,,Corbeny,Corbeny,2,Aisne,32,Hauts-de-France
2216,CORCY,2600,CORCY,,49.2549892095,3.19724367023,216,,Corcy,Corcy,2,Aisne,32,Hauts-de-France
2217,COUCY LE CHATEAU AUFFRIQUE,2380,COUCY LE CHATEAU AUFFRIQUE,,49.5159709578,3.31548892957,217,,Coucy-le-Château-Auffrique,Coucy-le-Château-Auffrique,2,Aisne,32,Hauts-de-France
2218,COUCY LES EPPES,2840,COUCY LES EPPES,,49.5566159059,3.77716711865,218,,Coucy-lès-Eppes,Coucy-lès-Eppes,2,Aisne,32,Hauts-de-France
2219,COUCY LA VILLE,2380,COUCY LA VILLE,,49.5405792355,3.34372156034,219,,Coucy-la-Ville,Coucy-la-Ville,2,Aisne,32,Hauts-de-France
2220,COULONGES COHAN,2130,COULONGES COHAN,,49.1980463524,3.62776592985,220,,Coulonges-Cohan,Coulonges-Cohan,2,Aisne,32,Hauts-de-France
2220,COULONGES COHAN,2130,COULONGES COHAN,COHAN,49.1980463524,3.62776592985,220,,Coulonges-Cohan,Coulonges-Cohan,2,Aisne,32,Hauts-de-France
2221,COUPRU,2310,COUPRU,,49.0313536349,3.2743801467,221,,Coupru,Coupru,2,Aisne,32,Hauts-de-France
2222,COURBES,2800,COURBES,,49.6815888779,3.46004834981,222,,Courbes,Courbes,2,Aisne,32,Hauts-de-France
2223,COURBOIN,2330,COURBOIN,,48.9960917184,3.50690687861,223,,Courboin,Courboin,2,Aisne,32,Hauts-de-France
2224,COURCELLES SUR VESLE,2220,COURCELLES SUR VESLE,,49.348025885,3.57684035869,224,,Courcelles-sur-Vesle,Courcelles-sur-Vesle,2,Aisne,32,Hauts-de-France
2225,COURCHAMPS,2810,COURCHAMPS,,49.1164230809,3.26303714363,225,,Courchamps,Courchamps,2,Aisne,32,Hauts-de-France
2226,COURMELLES,2200,COURMELLES,,49.3434328339,3.30199112892,226,,Courmelles,Courmelles,2,Aisne,32,Hauts-de-France
2227,COURMONT,2130,COURMONT,,49.1377444173,3.57546880498,227,,Courmont,Courmont,2,Aisne,32,Hauts-de-France
2228,COURTEMONT VARENNES,2850,COURTEMONT VARENNES,,49.0715288855,3.54168079771,228,,Courtemont-Varennes,Courtemont-Varennes,2,Aisne,32,Hauts-de-France
2229,COURTRIZY ET FUSSIGNY,2820,COURTRIZY ET FUSSIGNY,,49.5140957547,3.79503800634,229,,Courtrizy-et-Fussigny,Courtrizy-et-Fussigny,2,Aisne,32,Hauts-de-France
2230,COUVRELLES,2220,COUVRELLES,,49.3339841009,3.48486954064,230,,Couvrelles,Couvrelles,2,Aisne,32,Hauts-de-France
2231,COUVRON ET AUMENCOURT,2270,COUVRON ET AUMENCOURT,,49.6458776473,3.52446684584,231,,Couvron-et-Aumencourt,Couvron-et-Aumencourt,2,Aisne,32,Hauts-de-France
2232,COYOLLES,2600,COYOLLES,,49.2133089693,3.01306173751,232,,Coyolles,Coyolles,2,Aisne,32,Hauts-de-France
2233,CRAMAILLE,2130,CRAMAILLE,,49.2285541737,3.45227303073,233,,Cramaille,Cramaille,2,Aisne,32,Hauts-de-France
2234,CRAONNE,2160,CRAONNE,,49.4476368348,3.79203280761,234,,Craonne,Craonne,2,Aisne,32,Hauts-de-France
2235,CRAONNELLE,2160,CRAONNELLE,,49.4254999308,3.77686483236,235,,Craonnelle,Craonnelle,2,Aisne,32,Hauts-de-France
2236,CRECY AU MONT,2380,CRECY AU MONT,,49.4839031807,3.31195074309,236,,Crécy-au-Mont,Crécy-au-Mont,2,Aisne,32,Hauts-de-France
2237,CRECY SUR SERRE,2270,CRECY SUR SERRE,,49.698624191,3.62256564798,237,,Crécy-sur-Serre,Crécy-sur-Serre,2,Aisne,32,Hauts-de-France
2238,CREPY,2870,CREPY,,49.6082534854,3.5125070585,238,,Crépy,Crépy,2,Aisne,32,Hauts-de-France
2239,CREZANCY,2650,CREZANCY,,49.0428713757,3.51138201699,239,,Crézancy,Crézancy,2,Aisne,32,Hauts-de-France
2240,CROIX FONSOMME,2110,CROIX FONSOMME,,49.9306761102,3.39430326005,240,,Croix-Fonsomme,Croix-Fonsomme,2,Aisne,32,Hauts-de-France
2241,LA CROIX SUR OURCQ,2210,LA CROIX SUR OURCQ,,49.1598010602,3.35048905306,241,La,Croix-sur-Ourcq,La Croix-sur-Ourcq,2,Aisne,32,Hauts-de-France
2242,CROUTTES SUR MARNE,2310,CROUTTES SUR MARNE,,48.9791200153,3.24757642131,242,,Crouttes-sur-Marne,Crouttes-sur-Marne,2,Aisne,32,Hauts-de-France
2243,CROUY,2880,CROUY,,49.4078535405,3.36640020468,243,,Crouy,Crouy,2,Aisne,32,Hauts-de-France
2244,CRUPILLY,2120,CRUPILLY,,49.9313828796,3.75249198254,244,,Crupilly,Crupilly,2,Aisne,32,Hauts-de-France
2245,CUFFIES,2880,CUFFIES,,49.4081126802,3.32236958826,245,,Cuffies,Cuffies,2,Aisne,32,Hauts-de-France
2246,CUGNY,2480,CUGNY,,49.7031009123,3.15424498862,246,,Cugny,Cugny,2,Aisne,32,Hauts-de-France
2248,CUIRIEUX,2350,CUIRIEUX,,49.6834404265,3.81994750866,248,,Cuirieux,Cuirieux,2,Aisne,32,Hauts-de-France
2249,CUIRY HOUSSE,2220,CUIRY HOUSSE,,49.2904033438,3.48843248507,249,,Cuiry-Housse,Cuiry-Housse,2,Aisne,32,Hauts-de-France
2250,CUIRY LES CHAUDARDES,2160,CUIRY LES CHAUDARDES,,49.3897631676,3.76227512623,250,,Cuiry-lès-Chaudardes,Cuiry-lès-Chaudardes,2,Aisne,32,Hauts-de-France
2251,CUIRY LES IVIERS,2360,CUIRY LES IVIERS,,49.7661751176,4.10397751712,251,,Cuiry-lès-Iviers,Cuiry-lès-Iviers,2,Aisne,32,Hauts-de-France
2252,CUISSY ET GENY,2160,CUISSY ET GENY,,49.4034575347,3.70612307803,252,,Cuissy-et-Geny,Cuissy-et-Geny,2,Aisne,32,Hauts-de-France
2253,CUISY EN ALMONT,2200,CUISY EN ALMONT,,49.4207417064,3.24887510168,253,,Cuisy-en-Almont,Cuisy-en-Almont,2,Aisne,32,Hauts-de-France
2254,CUTRY,2600,CUTRY,,49.3442753875,3.19127169549,254,,Cutry,Cutry,2,Aisne,32,Hauts-de-France
2255,CYS LA COMMUNE,2220,CYS LA COMMUNE,,49.3837163848,3.57235420125,255,,Cys-la-Commune,Cys-la-Commune,2,Aisne,32,Hauts-de-France
2256,DAGNY LAMBERCY,2140,DAGNY LAMBERCY,,49.7690562311,4.04218248631,256,,Dagny-Lambercy,Dagny-Lambercy,2,Aisne,32,Hauts-de-France
2257,DALLON,2680,DALLON,,49.8240617542,3.23031219742,257,,Dallon,Dallon,2,Aisne,32,Hauts-de-France
2258,DAMMARD,2470,DAMMARD,,49.1470522556,3.17719709632,258,,Dammard,Dammard,2,Aisne,32,Hauts-de-France
2259,DAMPLEUX,2600,DAMPLEUX,,49.2414407733,3.14413128827,259,,Dampleux,Dampleux,2,Aisne,32,Hauts-de-France
2260,DANIZY,2800,DANIZY,,49.6665738585,3.40089557013,260,,Danizy,Danizy,2,Aisne,32,Hauts-de-France
2261,DERCY,2270,DERCY,,49.709705981,3.68499803784,261,,Dercy,Dercy,2,Aisne,32,Hauts-de-France
2262,DEUILLET,2700,DEUILLET,,49.6285991184,3.36051598302,262,,Deuillet,Deuillet,2,Aisne,32,Hauts-de-France
2263,DHUIZEL,2220,DHUIZEL,,49.3602201297,3.61487590771,263,,Dhuizel,Dhuizel,2,Aisne,32,Hauts-de-France
2264,DIZY LE GROS,2340,DIZY LE GROS,,49.6355575735,4.01312880234,264,,Dizy-le-Gros,Dizy-le-Gros,2,Aisne,32,Hauts-de-France
2265,DOHIS,2360,DOHIS,,49.7667554096,4.14178746545,265,,Dohis,Dohis,2,Aisne,32,Hauts-de-France
2266,DOLIGNON,2360,DOLIGNON,,49.7338298517,4.08637882793,266,,Dolignon,Dolignon,2,Aisne,32,Hauts-de-France
2267,DOMMIERS,2600,DOMMIERS,,49.3290731499,3.21634686939,267,,Dommiers,Dommiers,2,Aisne,32,Hauts-de-France
2268,DOMPTIN,2310,DOMPTIN,,49.0156995656,3.27296497779,268,,Domptin,Domptin,2,Aisne,32,Hauts-de-France
2269,DORENGT,2450,DORENGT,,49.9652064178,3.7033753973,269,,Dorengt,Dorengt,2,Aisne,32,Hauts-de-France
2270,DOUCHY,2590,DOUCHY,,49.7940905147,3.13035849025,270,,Douchy,Douchy,2,Aisne,32,Hauts-de-France
2271,DRAVEGNY,2130,DRAVEGNY,,49.2391906053,3.64128453803,271,,Dravegny,Dravegny,2,Aisne,32,Hauts-de-France
2272,DROIZY,2210,DROIZY,,49.2773564352,3.38965007811,272,,Droizy,Droizy,2,Aisne,32,Hauts-de-France
2273,DURY,2480,DURY,,49.751235296,3.13377121735,273,,Dury,Dury,2,Aisne,32,Hauts-de-France
2274,EBOULEAU,2350,EBOULEAU,,49.6781110152,3.88259465732,274,,Ébouleau,Ébouleau,2,Aisne,32,Hauts-de-France
2275,EFFRY,2500,EFFRY,,49.9191180654,3.98790173223,275,,Effry,Effry,2,Aisne,32,Hauts-de-France
2276,ENGLANCOURT,2260,ENGLANCOURT,,49.9258825845,3.80718694445,276,,Englancourt,Englancourt,2,Aisne,32,Hauts-de-France
2277,EPAGNY,2290,EPAGNY,,49.471791053,3.25384432012,277,,Épagny,Épagny,2,Aisne,32,Hauts-de-France
2278,EPARCY,2500,EPARCY,,49.8861370225,4.0702371205,278,,Éparcy,Éparcy,2,Aisne,32,Hauts-de-France
2279,EPAUX BEZU,2400,EPAUX BEZU,,49.1029988371,3.35359350285,279,,Épaux-Bézu,Épaux-Bézu,2,Aisne,32,Hauts-de-France
2280,EPIEDS,2400,EPIEDS,,49.1045180167,3.45072347386,280,,Épieds,Épieds,2,Aisne,32,Hauts-de-France
2281,L EPINE AUX BOIS,2540,L EPINE AUX BOIS,,48.8992804015,3.44384138078,281,L',Épine-aux-Bois,L'Épine-aux-Bois,2,Aisne,32,Hauts-de-France
2282,EPPES,2840,EPPES,,49.5523855068,3.73980858367,282,,Eppes,Eppes,2,Aisne,32,Hauts-de-France
2283,ERLON,2250,ERLON,,49.7348278249,3.70534450333,283,,Erlon,Erlon,2,Aisne,32,Hauts-de-France
2284,ERLOY,2260,ERLOY,,49.9195909244,3.83764920476,284,,Erloy,Erloy,2,Aisne,32,Hauts-de-France
2286,ESQUEHERIES,2170,ESQUEHERIES,,49.9822300914,3.75309920982,286,,Esquéhéries,Esquéhéries,2,Aisne,32,Hauts-de-France
2287,ESSIGNY LE GRAND,2690,ESSIGNY LE GRAND,,49.7740780116,3.27214459324,287,,Essigny-le-Grand,Essigny-le-Grand,2,Aisne,32,Hauts-de-France
2288,ESSIGNY LE PETIT,2100,ESSIGNY LE PETIT,,49.8961825414,3.36742260207,288,,Essigny-le-Petit,Essigny-le-Petit,2,Aisne,32,Hauts-de-France
2289,ESSISES,2570,ESSISES,,48.9666904843,3.41396491705,289,,Essises,Essises,2,Aisne,32,Hauts-de-France
2290,ESSOMES SUR MARNE,2400,ESSOMES SUR MARNE,,49.0341132047,3.34014174183,290,,Essômes-sur-Marne,Essômes-sur-Marne,2,Aisne,32,Hauts-de-France
2291,ESTREES,2420,ESTREES,,49.9705842912,3.2801536166,291,,Estrées,Estrées,2,Aisne,32,Hauts-de-France
2292,ETAMPES SUR MARNE,2400,ETAMPES SUR MARNE,,49.0317558536,3.41777664616,292,,Étampes-sur-Marne,Étampes-sur-Marne,2,Aisne,32,Hauts-de-France
2293,ETAVES ET BOCQUIAUX,2110,ETAVES ET BOCQUIAUX,,49.9310354275,3.45081301205,293,,Étaves-et-Bocquiaux,Étaves-et-Bocquiaux,2,Aisne,32,Hauts-de-France
2294,ETOUVELLES,2000,ETOUVELLES,,49.5186450995,3.58156180986,294,,Étouvelles,Étouvelles,2,Aisne,32,Hauts-de-France
2295,ETREAUPONT,2580,ETREAUPONT,,49.8962633822,3.92348915288,295,,Étréaupont,Étréaupont,2,Aisne,32,Hauts-de-France
2296,ETREILLERS,2590,ETREILLERS,,49.8325776228,3.15800068609,296,,Étreillers,Étreillers,2,Aisne,32,Hauts-de-France
2297,ETREPILLY,2400,ETREPILLY,,49.0822681369,3.34802063568,297,,Étrépilly,Étrépilly,2,Aisne,32,Hauts-de-France
2298,ETREUX,2510,ETREUX,,49.9996010721,3.65890491685,298,,Étreux,Étreux,2,Aisne,32,Hauts-de-France
2299,EVERGNICOURT,2190,EVERGNICOURT,,49.4564306975,4.03631753944,299,,Évergnicourt,Évergnicourt,2,Aisne,32,Hauts-de-France
2301,FAUCOUCOURT,2320,FAUCOUCOURT,,49.5334418244,3.46663902605,301,,Faucoucourt,Faucoucourt,2,Aisne,32,Hauts-de-France
2302,FAVEROLLES,2600,FAVEROLLES,,49.227064775,3.17814481548,302,,Faverolles,Faverolles,2,Aisne,32,Hauts-de-France
2303,FAYET,2100,FAYET,,49.8710844824,3.25278845948,303,,Fayet,Fayet,2,Aisne,32,Hauts-de-France
2304,LA FERE,2800,LA FERE,,49.6662828073,3.36938834693,304,La,Fère,La Fère,2,Aisne,32,Hauts-de-France
2305,FERE EN TARDENOIS,2130,FERE EN TARDENOIS,,49.1875000917,3.51106425515,305,,Fère-en-Tardenois,Fère-en-Tardenois,2,Aisne,32,Hauts-de-France
2306,LA FERTE CHEVRESIS,2270,LA FERTE CHEVRESIS,,49.7409973498,3.55129770695,306,La,Ferté-Chevresis,La Ferté-Chevresis,2,Aisne,32,Hauts-de-France
2307,LA FERTE MILON,2460,LA FERTE MILON,,49.1759874423,3.14237641756,307,La,Ferté-Milon,La Ferté-Milon,2,Aisne,32,Hauts-de-France
2307,LA FERTE MILON,2460,LA FERTE MILON,MOSLOY,49.1759874423,3.14237641756,307,La,Ferté-Milon,La Ferté-Milon,2,Aisne,32,Hauts-de-France
2308,FESMY LE SART,2450,FESMY LE SART,,50.0482154856,3.69825469199,308,,Fesmy-le-Sart,Fesmy-le-Sart,2,Aisne,32,Hauts-de-France
2308,FESMY LE SART,2450,FESMY LE SART,LE SART,50.0482154856,3.69825469199,308,,Fesmy-le-Sart,Fesmy-le-Sart,2,Aisne,32,Hauts-de-France
2309,FESTIEUX,2840,FESTIEUX,,49.5252642949,3.76013631694,309,,Festieux,Festieux,2,Aisne,32,Hauts-de-France
2310,FIEULAINE,2110,FIEULAINE,,49.9018502295,3.44594045878,310,,Fieulaine,Fieulaine,2,Aisne,32,Hauts-de-France
2311,FILAIN,2000,FILAIN,,49.4528807514,3.5574063541,311,,Filain,Filain,2,Aisne,32,Hauts-de-France
2312,LA FLAMENGRIE,2260,LA FLAMENGRIE,,50.0002036206,3.92466690244,312,La,Flamengrie,La Flamengrie,2,Aisne,32,Hauts-de-France
2313,FLAVIGNY LE GRAND ET BEAURAIN,2120,FLAVIGNY LE GRAND ET BEAURAIN,,49.8865505663,3.67406409367,313,,Flavigny-le-Grand-et-Beaurain,Flavigny-le-Grand-et-Beaurain,2,Aisne,32,Hauts-de-France
2315,FLAVY LE MARTEL,2520,FLAVY LE MARTEL,,49.7047240534,3.19451403575,315,,Flavy-le-Martel,Flavy-le-Martel,2,Aisne,32,Hauts-de-France
2316,FLEURY,2600,FLEURY,,49.2652863131,3.15947064674,316,,Fleury,Fleury,2,Aisne,32,Hauts-de-France
2317,FLUQUIERES,2590,FLUQUIERES,,49.800435087,3.15658582012,317,,Fluquières,Fluquières,2,Aisne,32,Hauts-de-France
2318,FOLEMBRAY,2670,FOLEMBRAY,,49.5492820594,3.29319165625,318,,Folembray,Folembray,2,Aisne,32,Hauts-de-France
2319,FONSOMME,2110,FONSOMME,,49.9012467271,3.39650664791,319,,Fonsomme,Fonsomme,2,Aisne,32,Hauts-de-France
2320,FONTAINE LES CLERCS,2680,FONTAINE LES CLERCS,,49.8078439274,3.21253412905,320,,Fontaine-lès-Clercs,Fontaine-lès-Clercs,2,Aisne,32,Hauts-de-France
2321,FONTAINE LES VERVINS,2140,FONTAINE LES VERVINS,,49.8537390603,3.90543025367,321,,Fontaine-lès-Vervins,Fontaine-lès-Vervins,2,Aisne,32,Hauts-de-France
2322,FONTAINE NOTRE DAME,2110,FONTAINE NOTRE DAME,,49.8785724713,3.42380596752,322,,Fontaine-Notre-Dame,Fontaine-Notre-Dame,2,Aisne,32,Hauts-de-France
2323,FONTAINE UTERTE,2110,FONTAINE UTERTE,,49.9252841672,3.36796234214,323,,Fontaine-Uterte,Fontaine-Uterte,2,Aisne,32,Hauts-de-France
2324,FONTENELLE,2170,FONTENELLE,,50.0309452362,3.84625365761,324,,Fontenelle,Fontenelle,2,Aisne,32,Hauts-de-France
2326,FONTENOY,2290,FONTENOY,,49.4099999437,3.19454758662,326,,Fontenoy,Fontenoy,2,Aisne,32,Hauts-de-France
2327,FORESTE,2590,FORESTE,,49.8055794029,3.09615936707,327,,Foreste,Foreste,2,Aisne,32,Hauts-de-France
2328,FOSSOY,2650,FOSSOY,,49.044990122,3.48523126715,328,,Fossoy,Fossoy,2,Aisne,32,Hauts-de-France
2329,FOURDRAIN,2870,FOURDRAIN,,49.6194184451,3.46049121118,329,,Fourdrain,Fourdrain,2,Aisne,32,Hauts-de-France
2330,FRANCILLY SELENCY,2760,FRANCILLY SELENCY,,49.8546596853,3.22822036861,330,,Francilly-Selency,Francilly-Selency,2,Aisne,32,Hauts-de-France
2331,FRANQUEVILLE,2140,FRANQUEVILLE,,49.8164125588,3.79770261738,331,,Franqueville,Franqueville,2,Aisne,32,Hauts-de-France
2332,FRESNES EN TARDENOIS,2130,FRESNES EN TARDENOIS,,49.1427948273,3.54893406985,332,,Fresnes-en-Tardenois,Fresnes-en-Tardenois,2,Aisne,32,Hauts-de-France
2333,FRESNES,2380,FRESNES,,49.5548078585,3.37018022508,333,,Fresnes-sous-Coucy,Fresnes-sous-Coucy,2,Aisne,32,Hauts-de-France
2334,FRESNOY LE GRAND,2230,FRESNOY LE GRAND,,49.9540724704,3.41858032499,334,,Fresnoy-le-Grand,Fresnoy-le-Grand,2,Aisne,32,Hauts-de-France
2335,FRESSANCOURT,2800,FRESSANCOURT,,49.6284783975,3.42727910427,335,,Fressancourt,Fressancourt,2,Aisne,32,Hauts-de-France
2336,FRIERES FAILLOUEL,2700,FRIERES FAILLOUEL,,49.6835552621,3.23680430341,336,,Frières-Faillouël,Frières-Faillouël,2,Aisne,32,Hauts-de-France
2337,FROIDESTREES,2260,FROIDESTREES,,49.9427092508,3.91032175248,337,,Froidestrées,Froidestrées,2,Aisne,32,Hauts-de-France
2338,FROIDMONT COHARTILLE,2270,FROIDMONT COHARTILLE,,49.6818768902,3.72070844849,338,,Froidmont-Cohartille,Froidmont-Cohartille,2,Aisne,32,Hauts-de-France
2339,GANDELU,2810,GANDELU,,49.0832089104,3.18156231552,339,,Gandelu,Gandelu,2,Aisne,32,Hauts-de-France
2340,GAUCHY,2430,GAUCHY,,49.8234688599,3.28649189661,340,,Gauchy,Gauchy,2,Aisne,32,Hauts-de-France
2341,GERCY,2140,GERCY,,49.8158108138,3.87133277309,341,,Gercy,Gercy,2,Aisne,32,Hauts-de-France
2342,GERGNY,2260,GERGNY,,49.9326319225,3.92972384714,342,,Gergny,Gergny,2,Aisne,32,Hauts-de-France
2343,GERMAINE,2590,GERMAINE,,49.8124843843,3.11903042429,343,,Germaine,Germaine,2,Aisne,32,Hauts-de-France
2344,GERNICOURT,2160,GERNICOURT,,49.3952382352,3.86382109909,344,,Gernicourt,Gernicourt,2,Aisne,32,Hauts-de-France
2345,GIBERCOURT,2440,GIBERCOURT,,49.7393520234,3.28269647304,345,,Gibercourt,Gibercourt,2,Aisne,32,Hauts-de-France
2346,GIZY,2350,GIZY,,49.6104960814,3.76786152197,346,,Gizy,Gizy,2,Aisne,32,Hauts-de-France
2347,GLAND,2400,GLAND,,49.0579740538,3.45713931457,347,,Gland,Gland,2,Aisne,32,Hauts-de-France
2349,GOUDELANCOURT LES BERRIEUX,2820,GOUDELANCOURT LES BERRIEUX,,49.4946764579,3.85858670968,349,,Goudelancourt-lès-Berrieux,Goudelancourt-lès-Berrieux,2,Aisne,32,Hauts-de-France
2350,GOUDELANCOURT LES PIERREPONT,2350,GOUDELANCOURT LES PIERREPONT,,49.6783296824,3.85162740345,350,,Goudelancourt-lès-Pierrepont,Goudelancourt-lès-Pierrepont,2,Aisne,32,Hauts-de-France
2351,GOUSSANCOURT,2130,GOUSSANCOURT,,49.1608459229,3.65658681802,351,,Goussancourt,Goussancourt,2,Aisne,32,Hauts-de-France
2352,GOUY,2420,GOUY,,49.9987486908,3.26458305638,352,,Gouy,Gouy,2,Aisne,32,Hauts-de-France
2353,GRANDLUP ET FAY,2350,GRANDLUP ET FAY,,49.6512687794,3.73646950468,353,,Grandlup-et-Fay,Grandlup-et-Fay,2,Aisne,32,Hauts-de-France
2354,GRANDRIEUX,2360,GRANDRIEUX,,49.7291718471,4.1879249496,354,,Grandrieux,Grandrieux,2,Aisne,32,Hauts-de-France
2355,GRICOURT,2100,GRICOURT,,49.8869947757,3.23960817466,355,,Gricourt,Gricourt,2,Aisne,32,Hauts-de-France
2356,GRISOLLES,2210,GRISOLLES,,49.130183511,3.37217369933,356,,Grisolles,Grisolles,2,Aisne,32,Hauts-de-France
2357,GRONARD,2140,GRONARD,,49.7963196915,3.88176918551,357,,Gronard,Gronard,2,Aisne,32,Hauts-de-France
2358,GROUGIS,2110,GROUGIS,,49.9508110437,3.53098081744,358,,Grougis,Grougis,2,Aisne,32,Hauts-de-France
2359,GRUGIES,2680,GRUGIES,,49.8100941328,3.2751623814,359,,Grugies,Grugies,2,Aisne,32,Hauts-de-France
2360,GUIGNICOURT,2190,GUIGNICOURT,,49.4433017606,3.95534582458,360,,Guignicourt,Guignicourt,2,Aisne,32,Hauts-de-France
2361,GUISE,2120,GUISE,,49.8960415076,3.62476062929,361,,Guise,Guise,2,Aisne,32,Hauts-de-France
2362,GUIVRY,2300,GUIVRY,,49.651625761,3.12103476493,362,,Guivry,Guivry,2,Aisne,32,Hauts-de-France
2363,GUNY,2300,GUNY,,49.5120153085,3.26082658052,363,,Guny,Guny,2,Aisne,32,Hauts-de-France
2364,GUYENCOURT,2160,GUYENCOURT,,49.3573380108,3.84132930883,364,,Guyencourt,Guyencourt,2,Aisne,32,Hauts-de-France
2366,HANNAPES,2510,HANNAPES,,49.9771158104,3.61442642232,366,,Hannapes,Hannapes,2,Aisne,32,Hauts-de-France
2367,HAPPENCOURT,2480,HAPPENCOURT,,49.7825905619,3.1831286327,367,,Happencourt,Happencourt,2,Aisne,32,Hauts-de-France
2368,HARAMONT,2600,HARAMONT,,49.2872759039,3.03148977109,368,,Haramont,Haramont,2,Aisne,32,Hauts-de-France
2369,HARCIGNY,2140,HARCIGNY,,49.80256362,3.9828364762,369,,Harcigny,Harcigny,2,Aisne,32,Hauts-de-France
2370,HARGICOURT,2420,HARGICOURT,,49.9664492878,3.19055510378,370,,Hargicourt,Hargicourt,2,Aisne,32,Hauts-de-France
2371,HARLY,2100,HARLY,,49.8407051764,3.33484724239,371,,Harly,Harly,2,Aisne,32,Hauts-de-France
2372,HARTENNES ET TAUX,2210,HARTENNES ET TAUX,,49.2789534457,3.35763287633,372,,Hartennes-et-Taux,Hartennes-et-Taux,2,Aisne,32,Hauts-de-France
2373,HARY,2140,HARY,,49.797388651,3.91927403736,373,,Hary,Hary,2,Aisne,32,Hauts-de-France
2374,LEHAUCOURT,2420,LEHAUCOURT,,49.911760676,3.27675235436,374,,Lehaucourt,Lehaucourt,2,Aisne,32,Hauts-de-France
2375,HAUTEVESNES,2810,HAUTEVESNES,,49.1088469374,3.2356780284,375,,Hautevesnes,Hautevesnes,2,Aisne,32,Hauts-de-France
2376,HAUTEVILLE,2120,HAUTEVILLE,,49.8884981223,3.52369340599,376,,Hauteville,Hauteville,2,Aisne,32,Hauts-de-France
2377,HAUTION,2140,HAUTION,,49.8721128525,3.82719888249,377,,Haution,Haution,2,Aisne,32,Hauts-de-France
2378,LA HERIE,2500,LA HERIE,,49.8838269882,4.04360657052,378,La,Hérie,La Hérie,2,Aisne,32,Hauts-de-France
2379,LE HERIE LA VIEVILLE,2120,LE HERIE LA VIEVILLE,,49.8222697536,3.65376324724,379,Le,Hérie-la-Viéville,Le Hérie-la-Viéville,2,Aisne,32,Hauts-de-France
2380,HINACOURT,2440,HINACOURT,,49.7459956466,3.29422566384,380,,Hinacourt,Hinacourt,2,Aisne,32,Hauts-de-France
2381,HIRSON,2500,HIRSON,,49.9426458618,4.10483732841,381,,Hirson,Hirson,2,Aisne,32,Hauts-de-France
2382,HOLNON,2760,HOLNON,,49.8612556888,3.20085325892,382,,Holnon,Holnon,2,Aisne,32,Hauts-de-France
2383,HOMBLIERES,2720,HOMBLIERES,,49.8577543083,3.37495127984,383,,Homblières,Homblières,2,Aisne,32,Hauts-de-France
2384,HOURY,2140,HOURY,,49.7861780633,3.84806416077,384,,Houry,Houry,2,Aisne,32,Hauts-de-France
2385,HOUSSET,2250,HOUSSET,,49.7961649956,3.69539262425,385,,Housset,Housset,2,Aisne,32,Hauts-de-France
2386,IRON,2510,IRON,,49.9504309458,3.6652978953,386,,Iron,Iron,2,Aisne,32,Hauts-de-France
2387,ITANCOURT,2240,ITANCOURT,,49.803442885,3.34942752527,387,,Itancourt,Itancourt,2,Aisne,32,Hauts-de-France
2388,IVIERS,2360,IVIERS,,49.7858863872,4.13552902559,388,,Iviers,Iviers,2,Aisne,32,Hauts-de-France
2389,JAULGONNE,2850,JAULGONNE,,49.0895236955,3.53359601877,389,,Jaulgonne,Jaulgonne,2,Aisne,32,Hauts-de-France
2390,JEANCOURT,2490,JEANCOURT,,49.9288562683,3.14778805313,390,,Jeancourt,Jeancourt,2,Aisne,32,Hauts-de-France
2391,JEANTES,2140,JEANTES,,49.8127276252,4.06715502895,391,,Jeantes,Jeantes,2,Aisne,32,Hauts-de-France
2392,JONCOURT,2420,JONCOURT,,49.9601487798,3.30407272701,392,,Joncourt,Joncourt,2,Aisne,32,Hauts-de-France
2393,JOUAIGNES,2220,JOUAIGNES,,49.2928418789,3.52818628425,393,,Jouaignes,Jouaignes,2,Aisne,32,Hauts-de-France
2395,JUMENCOURT,2380,JUMENCOURT,,49.5122950231,3.34939792383,395,,Jumencourt,Jumencourt,2,Aisne,32,Hauts-de-France
2396,JUMIGNY,2160,JUMIGNY,,49.4083834603,3.72524669282,396,,Jumigny,Jumigny,2,Aisne,32,Hauts-de-France
2397,JUSSY,2480,JUSSY,,49.7172050931,3.23611732777,397,,Jussy,Jussy,2,Aisne,32,Hauts-de-France
2398,JUVIGNY,2880,JUVIGNY,,49.44857677,3.31533781107,398,,Juvigny,Juvigny,2,Aisne,32,Hauts-de-France
2399,JUVINCOURT ET DAMARY,2190,JUVINCOURT ET DAMARY,,49.4468853425,3.89147198339,399,,Juvincourt-et-Damary,Juvincourt-et-Damary,2,Aisne,32,Hauts-de-France
2400,LAFFAUX,2880,LAFFAUX,,49.4494770874,3.42995693216,400,,Laffaux,Laffaux,2,Aisne,32,Hauts-de-France
2401,LAIGNY,2140,LAIGNY,,49.8631466398,3.86133626518,401,,Laigny,Laigny,2,Aisne,32,Hauts-de-France
2402,LANCHY,2590,LANCHY,,49.8292105187,3.07415428089,402,,Lanchy,Lanchy,2,Aisne,32,Hauts-de-France
2403,LANDIFAY ET BERTAIGNEMONT,2120,LANDIFAY ET BERTAIGNEMONT,,49.8255553996,3.60399989958,403,,Landifay-et-Bertaignemont,Landifay-et-Bertaignemont,2,Aisne,32,Hauts-de-France
2404,LANDOUZY LA COUR,2140,LANDOUZY LA COUR,,49.8441415789,3.99358286375,404,,Landouzy-la-Cour,Landouzy-la-Cour,2,Aisne,32,Hauts-de-France
2405,LANDOUZY LA VILLE,2140,LANDOUZY LA VILLE,,49.8562890185,4.05045506584,405,,Landouzy-la-Ville,Landouzy-la-Ville,2,Aisne,32,Hauts-de-France
2406,LANDRICOURT,2380,LANDRICOURT,,49.505420346,3.36950507041,406,,Landricourt,Landricourt,2,Aisne,32,Hauts-de-France
2407,LANISCOURT,2000,LANISCOURT,,49.5469118723,3.53406747358,407,,Laniscourt,Laniscourt,2,Aisne,32,Hauts-de-France
2408,LAON,2000,LAON,,49.5679724897,3.62089561902,408,,Laon,Laon,2,Aisne,32,Hauts-de-France
2409,LAPPION,2150,LAPPION,,49.5898015281,3.96457967258,409,,Lappion,Lappion,2,Aisne,32,Hauts-de-France
2410,LARGNY SUR AUTOMNE,2600,LARGNY SUR AUTOMNE,,49.2610748419,3.05411123113,410,,Largny-sur-Automne,Largny-sur-Automne,2,Aisne,32,Hauts-de-France
2411,LATILLY,2210,LATILLY,,49.1538863119,3.31218468893,411,,Latilly,Latilly,2,Aisne,32,Hauts-de-France
2412,LAUNOY,2210,LAUNOY,,49.2629984132,3.4054374314,412,,Launoy,Launoy,2,Aisne,32,Hauts-de-France
2413,LAVAL EN LAONNOIS,2860,LAVAL EN LAONNOIS,,49.5029905064,3.58758242212,413,,Laval-en-Laonnois,Laval-en-Laonnois,2,Aisne,32,Hauts-de-France
2414,LAVAQUERESSE,2450,LAVAQUERESSE,,49.9465387553,3.7166420191,414,,Lavaqueresse,Lavaqueresse,2,Aisne,32,Hauts-de-France
2415,LAVERSINE,2600,LAVERSINE,,49.3528718857,3.15459651522,415,,Laversine,Laversine,2,Aisne,32,Hauts-de-France
2416,LEME,2140,LEME,,49.843926232,3.7576717994,416,,Lemé,Lemé,2,Aisne,32,Hauts-de-France
2417,LEMPIRE,2420,LEMPIRE,,49.9953429453,3.17401503527,417,,Lempire,Lempire,2,Aisne,32,Hauts-de-France
2418,LERZY,2260,LERZY,,49.9499258495,3.88135093149,418,,Lerzy,Lerzy,2,Aisne,32,Hauts-de-France
2419,LESCHELLE,2170,LESCHELLE,,49.9605709227,3.77740709683,419,,Leschelle,Leschelle,2,Aisne,32,Hauts-de-France
2420,LESDINS,2100,LESDINS,,49.9043632178,3.31462117869,420,,Lesdins,Lesdins,2,Aisne,32,Hauts-de-France
2421,LESGES,2220,LESGES,,49.309828392,3.50034767893,421,,Lesges,Lesges,2,Aisne,32,Hauts-de-France
2422,LESQUIELLES ST GERMAIN,2120,LESQUIELLES ST GERMAIN,,49.931687068,3.62808644318,422,,Lesquielles-Saint-Germain,Lesquielles-Saint-Germain,2,Aisne,32,Hauts-de-France
2423,LEUILLY SOUS COUCY,2380,LEUILLY SOUS COUCY,,49.4734455044,3.35288205414,423,,Leuilly-sous-Coucy,Leuilly-sous-Coucy,2,Aisne,32,Hauts-de-France
2424,LEURY,2880,LEURY,,49.4269529918,3.34130922178,424,,Leury,Leury,2,Aisne,32,Hauts-de-France
2425,LEUZE,2500,LEUZE,,49.8578215993,4.16963845435,425,,Leuze,Leuze,2,Aisne,32,Hauts-de-France
2426,LEVERGIES,2420,LEVERGIES,,49.9345380686,3.31185579217,426,,Levergies,Levergies,2,Aisne,32,Hauts-de-France
2427,LHUYS,2220,LHUYS,,49.2728528048,3.54230635286,427,,Lhuys,Lhuys,2,Aisne,32,Hauts-de-France
2428,LICY CLIGNON,2810,LICY CLIGNON,,49.102123319,3.26534900839,428,,Licy-Clignon,Licy-Clignon,2,Aisne,32,Hauts-de-France
2429,LIERVAL,2860,LIERVAL,,49.4874867517,3.62227691789,429,,Lierval,Lierval,2,Aisne,32,Hauts-de-France
2430,LIESSE NOTRE DAME,2350,LIESSE NOTRE DAME,,49.6108544067,3.81711786834,430,,Liesse-Notre-Dame,Liesse-Notre-Dame,2,Aisne,32,Hauts-de-France
2431,LIEZ,2700,LIEZ,,49.6923509874,3.30017368966,431,,Liez,Liez,2,Aisne,32,Hauts-de-France
2432,LIME,2220,LIME,,49.3234940469,3.55539520178,432,,Limé,Limé,2,Aisne,32,Hauts-de-France
2433,LISLET,2340,LISLET,,49.6757265054,4.02084295692,433,,Lislet,Lislet,2,Aisne,32,Hauts-de-France
2434,LIZY,2320,LIZY,,49.5119505422,3.47941613566,434,,Lizy,Lizy,2,Aisne,32,Hauts-de-France
2435,LOGNY LES AUBENTON,2500,LOGNY LES AUBENTON,,49.8330144048,4.2201280927,435,,Logny-lès-Aubenton,Logny-lès-Aubenton,2,Aisne,32,Hauts-de-France
2438,LONGPONT,2600,LONGPONT,,49.2843362442,3.22069360555,438,,Longpont,Longpont,2,Aisne,32,Hauts-de-France
2439,LES SEPTVALLONS,2160,LES SEPTVALLONS,BARBONVAL,49.3603355862,3.65567844397,439,Les,Septvallons,Les Septvallons,2,Aisne,32,Hauts-de-France
2439,LES SEPTVALLONS,2160,LES SEPTVALLONS,GLENNES,49.3603355862,3.65567844397,439,Les,Septvallons,Les Septvallons,2,Aisne,32,Hauts-de-France
2439,LES SEPTVALLONS,2160,LES SEPTVALLONS,LONGUEVAL BARBONVAL,49.3603355862,3.65567844397,439,Les,Septvallons,Les Septvallons,2,Aisne,32,Hauts-de-France
2439,LES SEPTVALLONS,2160,LES SEPTVALLONS,MERVAL,49.3603355862,3.65567844397,439,Les,Septvallons,Les Septvallons,2,Aisne,32,Hauts-de-France
2439,LES SEPTVALLONS,2160,LES SEPTVALLONS,PERLES,49.3603355862,3.65567844397,439,Les,Septvallons,Les Septvallons,2,Aisne,32,Hauts-de-France
2439,LES SEPTVALLONS,2160,LES SEPTVALLONS,REVILLON,49.3603355862,3.65567844397,439,Les,Septvallons,Les Septvallons,2,Aisne,32,Hauts-de-France
2439,LES SEPTVALLONS,2160,LES SEPTVALLONS,VAUXCERE,49.3603355862,3.65567844397,439,Les,Septvallons,Les Septvallons,2,Aisne,32,Hauts-de-France
2439,LES SEPTVALLONS,2160,LES SEPTVALLONS,VILLERS EN PRAYERES,49.3603355862,3.65567844397,439,Les,Septvallons,Les Septvallons,2,Aisne,32,Hauts-de-France
2440,LOR,2190,LOR,,49.5296668744,4.05453884406,440,,Lor,Lor,2,Aisne,32,Hauts-de-France
2441,LOUATRE,2600,LOUATRE,,49.2527947078,3.24153629897,441,,Louâtre,Louâtre,2,Aisne,32,Hauts-de-France
2442,LOUPEIGNE,2130,LOUPEIGNE,,49.2411071956,3.5251073823,442,,Loupeigne,Loupeigne,2,Aisne,32,Hauts-de-France
2443,LUCY LE BOCAGE,2400,LUCY LE BOCAGE,,49.0549612755,3.27616704555,443,,Lucy-le-Bocage,Lucy-le-Bocage,2,Aisne,32,Hauts-de-France
2444,LUGNY,2140,LUGNY,,49.782281297,3.8121953982,444,,Lugny,Lugny,2,Aisne,32,Hauts-de-France
2445,LUZOIR,2500,LUZOIR,,49.9271348987,3.96025090337,445,,Luzoir,Luzoir,2,Aisne,32,Hauts-de-France
2446,LY FONTAINE,2440,LY FONTAINE,,49.732033107,3.31099377857,446,,Ly-Fontaine,Ly-Fontaine,2,Aisne,32,Hauts-de-France
2447,MAAST ET VIOLAINE,2220,MAAST ET VIOLAINE,,49.2931740531,3.45694908531,447,,Maast-et-Violaine,Maast-et-Violaine,2,Aisne,32,Hauts-de-France
2448,MACHECOURT,2350,MACHECOURT,,49.6516966172,3.83765995783,448,,Mâchecourt,Mâchecourt,2,Aisne,32,Hauts-de-France
2449,MACOGNY,2470,MACOGNY,,49.1590429097,3.21784708642,449,,Macogny,Macogny,2,Aisne,32,Hauts-de-France
2450,MACQUIGNY,2120,MACQUIGNY,,49.8732387452,3.57070489565,450,,Macquigny,Macquigny,2,Aisne,32,Hauts-de-France
2451,MAGNY LA FOSSE,2420,MAGNY LA FOSSE,,49.9371259007,3.27654031882,451,,Magny-la-Fosse,Magny-la-Fosse,2,Aisne,32,Hauts-de-France
2452,MAISSEMY,2490,MAISSEMY,,49.8929443672,3.1890371838,452,,Maissemy,Maissemy,2,Aisne,32,Hauts-de-France
2453,MAIZY,2160,MAIZY,,49.377259904,3.71616526094,453,,Maizy,Maizy,2,Aisne,32,Hauts-de-France
2454,LA MALMAISON,2190,LA MALMAISON,,49.5165178767,4.00048717912,454,La,Malmaison,La Malmaison,2,Aisne,32,Hauts-de-France
2455,MALZY,2120,MALZY,,49.921297034,3.73000059946,455,,Malzy,Malzy,2,Aisne,32,Hauts-de-France
2456,MANICAMP,2300,MANICAMP,,49.5650654162,3.17962639988,456,,Manicamp,Manicamp,2,Aisne,32,Hauts-de-France
2457,MARCHAIS,2350,MARCHAIS,,49.5850707567,3.82150148626,457,,Marchais,Marchais,2,Aisne,32,Hauts-de-France
2458,DHUYS ET MORIN EN BRIE,2330,DHUYS ET MORIN EN BRIE,ARTONGES,48.8935786183,3.49268121283,458,,Dhuys et Morin-en-Brie,Dhuys et Morin-en-Brie,2,Aisne,32,Hauts-de-France
2458,DHUYS ET MORIN EN BRIE,2540,DHUYS ET MORIN EN BRIE,FONTENELLE EN BRIE,48.8935786183,3.49268121283,458,,Dhuys et Morin-en-Brie,Dhuys et Morin-en-Brie,2,Aisne,32,Hauts-de-France
2458,DHUYS ET MORIN EN BRIE,2540,DHUYS ET MORIN EN BRIE,LA CELLE SOUS MONTMIRAIL,48.8935786183,3.49268121283,458,,Dhuys et Morin-en-Brie,Dhuys et Morin-en-Brie,2,Aisne,32,Hauts-de-France
2458,DHUYS ET MORIN EN BRIE,2540,DHUYS ET MORIN EN BRIE,MARCHAIS EN BRIE,48.8935786183,3.49268121283,458,,Dhuys et Morin-en-Brie,Dhuys et Morin-en-Brie,2,Aisne,32,Hauts-de-France
2459,MARCY,2720,MARCY,,49.8535898318,3.41135532201,459,,Marcy,Marcy,2,Aisne,32,Hauts-de-France
2460,MARCY SOUS MARLE,2250,MARCY SOUS MARLE,,49.7457495163,3.73453881149,460,,Marcy-sous-Marle,Marcy-sous-Marle,2,Aisne,32,Hauts-de-France
2461,MAREST DAMPCOURT,2300,MAREST DAMPCOURT,,49.59443854,3.14627124688,461,,Marest-Dampcourt,Marest-Dampcourt,2,Aisne,32,Hauts-de-France
2462,MAREUIL EN DOLE,2130,MAREUIL EN DOLE,,49.2424552782,3.56114326739,462,,Mareuil-en-Dôle,Mareuil-en-Dôle,2,Aisne,32,Hauts-de-France
2463,MARFONTAINE,2140,MARFONTAINE,,49.8080469097,3.7639268819,463,,Marfontaine,Marfontaine,2,Aisne,32,Hauts-de-France
2464,MARGIVAL,2880,MARGIVAL,,49.432719029,3.40572977303,464,,Margival,Margival,2,Aisne,32,Hauts-de-France
2465,MARIGNY EN ORXOIS,2810,MARIGNY EN ORXOIS,,49.0526386182,3.22281001734,465,,Marigny-en-Orxois,Marigny-en-Orxois,2,Aisne,32,Hauts-de-France
2466,MARIZY STE GENEVIEVE,2470,MARIZY STE GENEVIEVE,,49.1825477943,3.19814247608,466,,Marizy-Sainte-Geneviève,Marizy-Sainte-Geneviève,2,Aisne,32,Hauts-de-France
2467,MARIZY ST MARD,2470,MARIZY ST MARD,,49.1822693098,3.22799114728,467,,Marizy-Saint-Mard,Marizy-Saint-Mard,2,Aisne,32,Hauts-de-France
2468,MARLE,2250,MARLE,,49.7451393478,3.7652054501,468,,Marle,Marle,2,Aisne,32,Hauts-de-France
2469,MARLY GOMONT,2120,MARLY GOMONT,,49.8913737841,3.7849871912,469,,Marly-Gomont,Marly-Gomont,2,Aisne,32,Hauts-de-France
2470,MARTIGNY,2500,MARTIGNY,,49.866582249,4.13443052228,470,,Martigny,Martigny,2,Aisne,32,Hauts-de-France
2471,MARTIGNY COURPIERRE,2860,MARTIGNY COURPIERRE,,49.4900405005,3.6867077549,471,,Martigny-Courpierre,Martigny-Courpierre,2,Aisne,32,Hauts-de-France
2472,MAUREGNY EN HAYE,2820,MAUREGNY EN HAYE,,49.5406230877,3.79762834314,472,,Mauregny-en-Haye,Mauregny-en-Haye,2,Aisne,32,Hauts-de-France
2473,MAYOT,2800,MAYOT,,49.7090117255,3.39804766077,473,,Mayot,Mayot,2,Aisne,32,Hauts-de-France
2474,MENNESSIS,2700,MENNESSIS,,49.6918385873,3.27543313236,474,,Mennessis,Mennessis,2,Aisne,32,Hauts-de-France
2475,MENNEVILLE,2190,MENNEVILLE,,49.4303233528,4.00555747176,475,,Menneville,Menneville,2,Aisne,32,Hauts-de-France
2476,MENNEVRET,2630,MENNEVRET,,49.9919704555,3.55884133428,476,,Mennevret,Mennevret,2,Aisne,32,Hauts-de-France
2477,MERCIN ET VAUX,2200,MERCIN ET VAUX,,49.3705627456,3.27070140635,477,,Mercin-et-Vaux,Mercin-et-Vaux,2,Aisne,32,Hauts-de-France
2478,MERLIEUX ET FOUQUEROLLES,2000,MERLIEUX ET FOUQUEROLLES,,49.5137172169,3.49932983224,478,,Merlieux-et-Fouquerolles,Merlieux-et-Fouquerolles,2,Aisne,32,Hauts-de-France
2480,MESBRECOURT RICHECOURT,2270,MESBRECOURT RICHECOURT,,49.710385665,3.53739733076,480,,Mesbrecourt-Richecourt,Mesbrecourt-Richecourt,2,Aisne,32,Hauts-de-France
2481,MESNIL ST LAURENT,2720,MESNIL ST LAURENT,,49.8290188644,3.36454012617,481,,Mesnil-Saint-Laurent,Mesnil-Saint-Laurent,2,Aisne,32,Hauts-de-France
2482,MEURIVAL,2160,MEURIVAL,,49.3567698663,3.7590616765,482,,Meurival,Meurival,2,Aisne,32,Hauts-de-France
2483,MEZIERES SUR OISE,2240,MEZIERES SUR OISE,,49.7918999939,3.38740833653,483,,Mézières-sur-Oise,Mézières-sur-Oise,2,Aisne,32,Hauts-de-France
2484,MEZY MOULINS,2650,MEZY MOULINS,,49.0630074888,3.5122782761,484,,Mézy-Moulins,Mézy-Moulins,2,Aisne,32,Hauts-de-France
2485,MISSY AUX BOIS,2200,MISSY AUX BOIS,,49.3349407256,3.24662970633,485,,Missy-aux-Bois,Missy-aux-Bois,2,Aisne,32,Hauts-de-France
2486,MISSY LES PIERREPONT,2350,MISSY LES PIERREPONT,,49.6279354887,3.7841954065,486,,Missy-lès-Pierrepont,Missy-lès-Pierrepont,2,Aisne,32,Hauts-de-France
2487,MISSY SUR AISNE,2880,MISSY SUR AISNE,,49.3855898418,3.43829672684,487,,Missy-sur-Aisne,Missy-sur-Aisne,2,Aisne,32,Hauts-de-France
2488,MOLAIN,2110,MOLAIN,,50.03359227,3.52985658168,488,,Molain,Molain,2,Aisne,32,Hauts-de-France
2489,MOLINCHART,2000,MOLINCHART,,49.5624802928,3.53979641476,489,,Molinchart,Molinchart,2,Aisne,32,Hauts-de-France
2490,MONAMPTEUIL,2000,MONAMPTEUIL,,49.4760084468,3.57360176783,490,,Monampteuil,Monampteuil,2,Aisne,32,Hauts-de-France
2491,MONCEAU LE NEUF ET FAUCOUZY,2270,MONCEAU LE NEUF ET FAUCOUZY,,49.7845792788,3.63110860935,491,,Monceau-le-Neuf-et-Faucouzy,Monceau-le-Neuf-et-Faucouzy,2,Aisne,32,Hauts-de-France
2492,MONCEAU LES LEUPS,2270,MONCEAU LES LEUPS,,49.6617789272,3.48549133203,492,,Monceau-lès-Leups,Monceau-lès-Leups,2,Aisne,32,Hauts-de-France
2493,MONCEAU LE WAAST,2840,MONCEAU LE WAAST,,49.6217435488,3.71656668597,493,,Monceau-le-Waast,Monceau-le-Waast,2,Aisne,32,Hauts-de-France
2494,MONCEAU SUR OISE,2120,MONCEAU SUR OISE,,49.9097732744,3.69947432588,494,,Monceau-sur-Oise,Monceau-sur-Oise,2,Aisne,32,Hauts-de-France
2495,MONDREPUIS,2500,MONDREPUIS,,49.9637441269,4.05128113518,495,,Mondrepuis,Mondrepuis,2,Aisne,32,Hauts-de-France
2496,MONNES,2470,MONNES,,49.1458358775,3.22861820737,496,,Monnes,Monnes,2,Aisne,32,Hauts-de-France
2497,MONS EN LAONNOIS,2000,MONS EN LAONNOIS,,49.5379451828,3.55478457954,497,,Mons-en-Laonnois,Mons-en-Laonnois,2,Aisne,32,Hauts-de-France
2498,MONTAIGU,2820,MONTAIGU,,49.5531328831,3.84439779259,498,,Montaigu,Montaigu,2,Aisne,32,Hauts-de-France
2499,MONTBAVIN,2000,MONTBAVIN,,49.5295376592,3.51319789821,499,,Montbavin,Montbavin,2,Aisne,32,Hauts-de-France
2500,MONTBREHAIN,2110,MONTBREHAIN,,49.9642449609,3.35609147368,500,,Montbrehain,Montbrehain,2,Aisne,32,Hauts-de-France
2501,MONTCHALONS,2860,MONTCHALONS,,49.5131917425,3.72718965805,501,,Montchâlons,Montchâlons,2,Aisne,32,Hauts-de-France
2502,MONTCORNET,2340,MONTCORNET,,49.6890129328,4.02915061151,502,,Montcornet,Montcornet,2,Aisne,32,Hauts-de-France
2503,MONT D ORIGNY,2390,MONT D ORIGNY,,49.8514997521,3.52673568406,503,,Mont-d'Origny,Mont-d'Origny,2,Aisne,32,Hauts-de-France
2504,MONTESCOURT LIZEROLLES,2440,MONTESCOURT LIZEROLLES,,49.7360396205,3.26188923395,504,,Montescourt-Lizerolles,Montescourt-Lizerolles,2,Aisne,32,Hauts-de-France
2505,MONTFAUCON,2540,MONTFAUCON,,48.9466694844,3.43192398845,505,,Montfaucon,Montfaucon,2,Aisne,32,Hauts-de-France
2506,MONTGOBERT,2600,MONTGOBERT,,49.289626635,3.17404129063,506,,Montgobert,Montgobert,2,Aisne,32,Hauts-de-France
2507,MONTGRU ST HILAIRE,2210,MONTGRU ST HILAIRE,,49.1768851038,3.32722209943,507,,Montgru-Saint-Hilaire,Montgru-Saint-Hilaire,2,Aisne,32,Hauts-de-France
2508,MONTHENAULT,2860,MONTHENAULT,,49.4892743465,3.65592404446,508,,Monthenault,Monthenault,2,Aisne,32,Hauts-de-France
2509,MONTHIERS,2400,MONTHIERS,,49.1085987235,3.29437776654,509,,Monthiers,Monthiers,2,Aisne,32,Hauts-de-France
2510,MONTHUREL,2330,MONTHUREL,,49.0275310805,3.55187396803,510,,Monthurel,Monthurel,2,Aisne,32,Hauts-de-France
2511,MONTIGNY EN ARROUAISE,2110,MONTIGNY EN ARROUAISE,,49.9120067618,3.48306674241,511,,Montigny-en-Arrouaise,Montigny-en-Arrouaise,2,Aisne,32,Hauts-de-France
2512,MONTIGNY L ALLIER,2810,MONTIGNY L ALLIER,,49.1221418457,3.10744372815,512,,Montigny-l'Allier,Montigny-l'Allier,2,Aisne,32,Hauts-de-France
2513,MONTIGNY LE FRANC,2250,MONTIGNY LE FRANC,,49.6874788196,3.9183982393,513,,Montigny-le-Franc,Montigny-le-Franc,2,Aisne,32,Hauts-de-France
2514,MONTIGNY LENGRAIN,2290,MONTIGNY LENGRAIN,,49.3726900463,3.09924630011,514,,Montigny-Lengrain,Montigny-Lengrain,2,Aisne,32,Hauts-de-France
2515,MONTIGNY LES CONDE,2330,MONTIGNY LES CONDE,,48.9830055534,3.56635546224,515,,Montigny-lès-Condé,Montigny-lès-Condé,2,Aisne,32,Hauts-de-France
2516,MONTIGNY SOUS MARLE,2250,MONTIGNY SOUS MARLE,,49.7411090858,3.80397947574,516,,Montigny-sous-Marle,Montigny-sous-Marle,2,Aisne,32,Hauts-de-France
2517,MONTIGNY SUR CRECY,2270,MONTIGNY SUR CRECY,,49.7149248587,3.58368023998,517,,Montigny-sur-Crécy,Montigny-sur-Crécy,2,Aisne,32,Hauts-de-France
2518,MONTLEVON,2330,MONTLEVON,,48.9627005485,3.51543798042,518,,Montlevon,Montlevon,2,Aisne,32,Hauts-de-France
2519,MONTLOUE,2340,MONTLOUE,,49.6682396007,4.06275825607,519,,Montloué,Montloué,2,Aisne,32,Hauts-de-France
2520,MONT NOTRE DAME,2220,MONT NOTRE DAME,,49.2921467742,3.5821661713,520,,Mont-Notre-Dame,Mont-Notre-Dame,2,Aisne,32,Hauts-de-France
2521,MONTREUIL AUX LIONS,2310,MONTREUIL AUX LIONS,,49.0264607134,3.19454438663,521,,Montreuil-aux-Lions,Montreuil-aux-Lions,2,Aisne,32,Hauts-de-France
2522,MONT ST JEAN,2360,MONT ST JEAN,,49.7932196838,4.21125667983,522,,Mont-Saint-Jean,Mont-Saint-Jean,2,Aisne,32,Hauts-de-France
2523,MONT ST MARTIN,2220,MONT ST MARTIN,,49.2747967442,3.64275042996,523,,Mont-Saint-Martin,Mont-Saint-Martin,2,Aisne,32,Hauts-de-France
2524,MONT ST PERE,2400,MONT ST PERE,,49.0816980947,3.47715449247,524,,Mont-Saint-Père,Mont-Saint-Père,2,Aisne,32,Hauts-de-France
2525,MORCOURT,2100,MORCOURT,,49.8759595448,3.33400206098,525,,Morcourt,Morcourt,2,Aisne,32,Hauts-de-France
2526,MORGNY EN THIERACHE,2360,MORGNY EN THIERACHE,,49.7544947391,4.07631392389,526,,Morgny-en-Thiérache,Morgny-en-Thiérache,2,Aisne,32,Hauts-de-France
2527,MORSAIN,2290,MORSAIN,,49.4616807631,3.19122405616,527,,Morsain,Morsain,2,Aisne,32,Hauts-de-France
2528,MORTEFONTAINE,2600,MORTEFONTAINE,,49.3362768244,3.08053479514,528,,Mortefontaine,Mortefontaine,2,Aisne,32,Hauts-de-France
2529,MORTIERS,2270,MORTIERS,,49.6934508264,3.66411841593,529,,Mortiers,Mortiers,2,Aisne,32,Hauts-de-France
2530,MOULINS,2160,MOULINS,,49.4198471298,3.67884923026,530,,Moulins,Moulins,2,Aisne,32,Hauts-de-France
2531,MOUSSY VERNEUIL,2160,MOUSSY VERNEUIL,,49.4126815323,3.63718132987,531,,Moussy-Verneuil,Moussy-Verneuil,2,Aisne,32,Hauts-de-France
2532,MOY DE L AISNE,2610,MOY DE L AISNE,,49.750274961,3.35100553228,532,,Moÿ-de-l'Aisne,Moÿ-de-l'Aisne,2,Aisne,32,Hauts-de-France
2533,MURET ET CROUTTES,2210,MURET ET CROUTTES,,49.2781417953,3.43464742094,533,,Muret-et-Crouttes,Muret-et-Crouttes,2,Aisne,32,Hauts-de-France
2534,MUSCOURT,2160,MUSCOURT,,49.3595795698,3.74350239233,534,,Muscourt,Muscourt,2,Aisne,32,Hauts-de-France
2535,NAMPCELLES LA COUR,2140,NAMPCELLES LA COUR,,49.7765756098,4.00209910519,535,,Nampcelles-la-Cour,Nampcelles-la-Cour,2,Aisne,32,Hauts-de-France
2536,NAMPTEUIL SOUS MURET,2200,NAMPTEUIL SOUS MURET,,49.3051403969,3.43398003516,536,,Nampteuil-sous-Muret,Nampteuil-sous-Muret,2,Aisne,32,Hauts-de-France
2537,NANTEUIL LA FOSSE,2880,NANTEUIL LA FOSSE,,49.4338010814,3.44800041437,537,,Nanteuil-la-Fosse,Nanteuil-la-Fosse,2,Aisne,32,Hauts-de-France
2538,NANTEUIL NOTRE DAME,2210,NANTEUIL NOTRE DAME,,49.1907421685,3.41207163018,538,,Nanteuil-Notre-Dame,Nanteuil-Notre-Dame,2,Aisne,32,Hauts-de-France
2539,NAUROY,2420,NAUROY,,49.9508154668,3.25861980976,539,,Nauroy,Nauroy,2,Aisne,32,Hauts-de-France
2540,NESLES LA MONTAGNE,2400,NESLES LA MONTAGNE,,48.9997837313,3.44587892818,540,,Nesles-la-Montagne,Nesles-la-Montagne,2,Aisne,32,Hauts-de-France
2541,NEUFCHATEL SUR AISNE,2190,NEUFCHATEL SUR AISNE,,49.4367296826,4.0236471269,541,,Neufchâtel-sur-Aisne,Neufchâtel-sur-Aisne,2,Aisne,32,Hauts-de-France
2542,NEUFLIEUX,2300,NEUFLIEUX,,49.6163788231,3.15996849471,542,,Neuflieux,Neuflieux,2,Aisne,32,Hauts-de-France
2543,NEUILLY ST FRONT,2470,NEUILLY ST FRONT,,49.1626366671,3.26436269893,543,,Neuilly-Saint-Front,Neuilly-Saint-Front,2,Aisne,32,Hauts-de-France
2544,NEUVE MAISON,2500,NEUVE MAISON,,49.9286429779,4.04086730255,544,,Neuve-Maison,Neuve-Maison,2,Aisne,32,Hauts-de-France
2545,LA NEUVILLE BOSMONT,2250,LA NEUVILLE BOSMONT,,49.7145039747,3.83367274994,545,La,Neuville-Bosmont,La Neuville-Bosmont,2,Aisne,32,Hauts-de-France
2546,LA NEUVILLE EN BEINE,2300,LA NEUVILLE EN BEINE,,49.6762298475,3.15600362361,546,La,Neuville-en-Beine,La Neuville-en-Beine,2,Aisne,32,Hauts-de-France
2547,LA NEUVILLE HOUSSET,2250,LA NEUVILLE HOUSSET,,49.7881379377,3.73171627307,547,La,Neuville-Housset,La Neuville-Housset,2,Aisne,32,Hauts-de-France
2548,LA NEUVILLE LES DORENGT,2450,LA NEUVILLE LES DORENGT,,49.983064353,3.68565926181,548,La,Neuville-lès-Dorengt,La Neuville-lès-Dorengt,2,Aisne,32,Hauts-de-France
2549,NEUVILLE ST AMAND,2100,NEUVILLE ST AMAND,,49.8206477091,3.33360991741,549,,Neuville-Saint-Amand,Neuville-Saint-Amand,2,Aisne,32,Hauts-de-France
2550,NEUVILLE SUR AILETTE,2860,NEUVILLE SUR AILETTE,,49.4695141414,3.69819946444,550,,Neuville-sur-Ailette,Neuville-sur-Ailette,2,Aisne,32,Hauts-de-France
2551,NEUVILLE SUR MARGIVAL,2880,NEUVILLE SUR MARGIVAL,,49.4548729773,3.3986658815,551,,Neuville-sur-Margival,Neuville-sur-Margival,2,Aisne,32,Hauts-de-France
2552,NEUVILLETTE,2390,NEUVILLETTE,,49.8554002239,3.46831298648,552,,Neuvillette,Neuvillette,2,Aisne,32,Hauts-de-France
2553,NIZY LE COMTE,2150,NIZY LE COMTE,,49.574361853,4.03064818206,553,,Nizy-le-Comte,Nizy-le-Comte,2,Aisne,32,Hauts-de-France
2554,NOGENTEL,2400,NOGENTEL,,49.0140758623,3.40340404208,554,,Nogentel,Nogentel,2,Aisne,32,Hauts-de-France
2555,NOGENT L ARTAUD,2310,NOGENT L ARTAUD,,48.9426222247,3.332802902,555,,Nogent-l'Artaud,Nogent-l'Artaud,2,Aisne,32,Hauts-de-France
2556,NOIRCOURT,2340,NOIRCOURT,,49.6691648747,4.09433809595,556,,Noircourt,Noircourt,2,Aisne,32,Hauts-de-France
2557,NOROY SUR OURCQ,2600,NOROY SUR OURCQ,,49.2041824443,3.20698565652,557,,Noroy-sur-Ourcq,Noroy-sur-Ourcq,2,Aisne,32,Hauts-de-France
2558,LE NOUVION EN THIERACHE,2170,LE NOUVION EN THIERACHE,,50.0086867152,3.80929288198,558,Le,Nouvion-en-Thiérache,Le Nouvion-en-Thiérache,2,Aisne,32,Hauts-de-France
2559,NOUVION ET CATILLON,2270,NOUVION ET CATILLON,,49.7075667391,3.49359729343,559,,Nouvion-et-Catillon,Nouvion-et-Catillon,2,Aisne,32,Hauts-de-France
2559,NOUVION ET CATILLON,2270,NOUVION ET CATILLON,PONT A BUCY,49.7075667391,3.49359729343,559,,Nouvion-et-Catillon,Nouvion-et-Catillon,2,Aisne,32,Hauts-de-France
2560,NOUVION LE COMTE,2800,NOUVION LE COMTE,,49.7079830783,3.46084868911,560,,Nouvion-le-Comte,Nouvion-le-Comte,2,Aisne,32,Hauts-de-France
2561,NOUVION LE VINEUX,2860,NOUVION LE VINEUX,,49.5092763308,3.605351266,561,,Nouvion-le-Vineux,Nouvion-le-Vineux,2,Aisne,32,Hauts-de-France
2562,NOUVRON VINGRE,2290,NOUVRON VINGRE,,49.4333492,3.18803179842,562,,Nouvron-Vingré,Nouvron-Vingré,2,Aisne,32,Hauts-de-France
2563,NOYALES,2120,NOYALES,,49.9082559491,3.53848565514,563,,Noyales,Noyales,2,Aisne,32,Hauts-de-France
2564,NOYANT ET ACONIN,2200,NOYANT ET ACONIN,,49.3319620657,3.33416303776,564,,Noyant-et-Aconin,Noyant-et-Aconin,2,Aisne,32,Hauts-de-France
2565,OEUILLY,2160,OEUILLY,,49.3928856944,3.68222741301,565,,Œuilly,Œuilly,2,Aisne,32,Hauts-de-France
2566,OGNES,2300,OGNES,,49.6136864694,3.19142372904,566,,Ognes,Ognes,2,Aisne,32,Hauts-de-France
2567,OHIS,2500,OHIS,,49.9184307239,4.01170912127,567,,Ohis,Ohis,2,Aisne,32,Hauts-de-France
2568,OIGNY EN VALOIS,2600,OIGNY EN VALOIS,,49.2137237953,3.1302728623,568,,Oigny-en-Valois,Oigny-en-Valois,2,Aisne,32,Hauts-de-France
2569,OISY,2450,OISY,,50.0229089259,3.65273893683,569,,Oisy,Oisy,2,Aisne,32,Hauts-de-France
2570,OLLEZY,2480,OLLEZY,,49.729996536,3.14607261695,570,,Ollezy,Ollezy,2,Aisne,32,Hauts-de-France
2571,OMISSY,2100,OMISSY,,49.8804267246,3.29788036707,571,,Omissy,Omissy,2,Aisne,32,Hauts-de-France
2572,ORAINVILLE,2190,ORAINVILLE,,49.3759679828,4.01565856496,572,,Orainville,Orainville,2,Aisne,32,Hauts-de-France
2573,ORGEVAL,2860,ORGEVAL,,49.5041140764,3.70337016497,573,,Orgeval,Orgeval,2,Aisne,32,Hauts-de-France
2574,ORIGNY EN THIERACHE,2550,ORIGNY EN THIERACHE,,49.8861904623,4.01101149757,574,,Origny-en-Thiérache,Origny-en-Thiérache,2,Aisne,32,Hauts-de-France
2575,ORIGNY STE BENOITE,2390,ORIGNY STE BENOITE,,49.8268502674,3.52909324665,575,,Origny-Sainte-Benoite,Origny-Sainte-Benoite,2,Aisne,32,Hauts-de-France
2576,OSLY COURTIL,2290,OSLY COURTIL,,49.3995301311,3.23736511582,576,,Osly-Courtil,Osly-Courtil,2,Aisne,32,Hauts-de-France
2577,OSTEL,2370,OSTEL,,49.4299692983,3.56538871192,577,,Ostel,Ostel,2,Aisne,32,Hauts-de-France
2578,OULCHES LA VALLEE FOULON,2160,OULCHES LA VALLEE FOULON,,49.4311311984,3.73966290896,578,,Oulches-la-Vallée-Foulon,Oulches-la-Vallée-Foulon,2,Aisne,32,Hauts-de-France
2579,OULCHY LA VILLE,2210,OULCHY LA VILLE,,49.2098503819,3.34062520679,579,,Oulchy-la-Ville,Oulchy-la-Ville,2,Aisne,32,Hauts-de-France
2580,OULCHY LE CHATEAU,2210,OULCHY LE CHATEAU,,49.2070701806,3.38202413773,580,,Oulchy-le-Château,Oulchy-le-Château,2,Aisne,32,Hauts-de-France
2580,OULCHY LE CHATEAU,2210,OULCHY LE CHATEAU,CUGNY LES CROUTTES,49.2070701806,3.38202413773,580,,Oulchy-le-Château,Oulchy-le-Château,2,Aisne,32,Hauts-de-France
2581,PAARS,2220,PAARS,,49.3302779346,3.60267922049,581,,Paars,Paars,2,Aisne,32,Hauts-de-France
2582,PAISSY,2160,PAISSY,,49.4263466952,3.7014989195,582,,Paissy,Paissy,2,Aisne,32,Hauts-de-France
2583,PANCY COURTECON,2860,PANCY COURTECON,,49.4606885919,3.64987795196,583,,Pancy-Courtecon,Pancy-Courtecon,2,Aisne,32,Hauts-de-France
2584,PAPLEUX,2260,PAPLEUX,,50.0169167744,3.88023781289,584,,Papleux,Papleux,2,Aisne,32,Hauts-de-France
2585,PARCY ET TIGNY,2210,PARCY ET TIGNY,,49.2703704352,3.32906709704,585,,Parcy-et-Tigny,Parcy-et-Tigny,2,Aisne,32,Hauts-de-France
2586,PARFONDEVAL,2360,PARFONDEVAL,,49.7443573688,4.16145285338,586,,Parfondeval,Parfondeval,2,Aisne,32,Hauts-de-France
2587,PARFONDRU,2840,PARFONDRU,,49.5385213412,3.70351915315,587,,Parfondru,Parfondru,2,Aisne,32,Hauts-de-France
2588,PARGNAN,2160,PARGNAN,,49.3942391486,3.70308940891,588,,Pargnan,Pargnan,2,Aisne,32,Hauts-de-France
2589,PARGNY FILAIN,2000,PARGNY FILAIN,,49.4629055692,3.53929108659,589,,Pargny-Filain,Pargny-Filain,2,Aisne,32,Hauts-de-France
2590,PARGNY LA DHUYS,2330,PARGNY LA DHUYS,,48.9545509576,3.56357370593,590,,Pargny-la-Dhuys,Pargny-la-Dhuys,2,Aisne,32,Hauts-de-France
2591,PARGNY LES BOIS,2270,PARGNY LES BOIS,,49.7323072713,3.61788330529,591,,Pargny-les-Bois,Pargny-les-Bois,2,Aisne,32,Hauts-de-France
2592,PARPEVILLE,2240,PARPEVILLE,,49.7943758027,3.56422835389,592,,Parpeville,Parpeville,2,Aisne,32,Hauts-de-France
2593,PASLY,2200,PASLY,,49.4022800131,3.29799527999,593,,Pasly,Pasly,2,Aisne,32,Hauts-de-France
2594,PASSY EN VALOIS,2470,PASSY EN VALOIS,,49.1621618332,3.18418881622,594,,Passy-en-Valois,Passy-en-Valois,2,Aisne,32,Hauts-de-France
2595,PASSY SUR MARNE,2850,PASSY SUR MARNE,,49.0663377762,3.57438808256,595,,Passy-sur-Marne,Passy-sur-Marne,2,Aisne,32,Hauts-de-France
2596,PAVANT,2310,PAVANT,,48.9528924605,3.28390382889,596,,Pavant,Pavant,2,Aisne,32,Hauts-de-France
2598,PERNANT,2200,PERNANT,,49.3756522114,3.23031459047,598,,Pernant,Pernant,2,Aisne,32,Hauts-de-France
2599,PIERREMANDE,2300,PIERREMANDE,,49.566550412,3.25836777343,599,,Pierremande,Pierremande,2,Aisne,32,Hauts-de-France
2600,PIERREPONT,2350,PIERREPONT,,49.6482784601,3.78777546723,600,,Pierrepont,Pierrepont,2,Aisne,32,Hauts-de-France
2601,PIGNICOURT,2190,PIGNICOURT,,49.4091910952,4.0300596148,601,,Pignicourt,Pignicourt,2,Aisne,32,Hauts-de-France
2602,PINON,2320,PINON,,49.488480349,3.46156583024,602,,Pinon,Pinon,2,Aisne,32,Hauts-de-France
2604,PITHON,2480,PITHON,,49.7562365167,3.10422093946,604,,Pithon,Pithon,2,Aisne,32,Hauts-de-France
2605,PLEINE SELVE,2240,PLEINE SELVE,,49.7965883398,3.5260425643,605,,Pleine-Selve,Pleine-Selve,2,Aisne,32,Hauts-de-France
2606,LE PLESSIER HULEU,2210,LE PLESSIER HULEU,,49.2388752756,3.34814266149,606,Le,Plessier-Huleu,Le Plessier-Huleu,2,Aisne,32,Hauts-de-France
2607,PLOISY,2200,PLOISY,,49.330941238,3.27456677749,607,,Ploisy,Ploisy,2,Aisne,32,Hauts-de-France
2608,PLOMION,2140,PLOMION,,49.8188816623,4.01999597737,608,,Plomion,Plomion,2,Aisne,32,Hauts-de-France
2609,PLOYART ET VAURSEINE,2860,PLOYART ET VAURSEINE,,49.493253698,3.74014252866,609,,Ployart-et-Vaurseine,Ployart-et-Vaurseine,2,Aisne,32,Hauts-de-France
2610,POMMIERS,2200,POMMIERS,,49.3921873782,3.27884937169,610,,Pommiers,Pommiers,2,Aisne,32,Hauts-de-France
2612,PONT ARCY,2160,PONT ARCY,,49.391846031,3.62003836186,612,,Pont-Arcy,Pont-Arcy,2,Aisne,32,Hauts-de-France
2613,PONTAVERT,2160,PONTAVERT,,49.4176198194,3.82051815377,613,,Pontavert,Pontavert,2,Aisne,32,Hauts-de-France
2614,PONTRU,2490,PONTRU,,49.921599489,3.20120191987,614,,Pontru,Pontru,2,Aisne,32,Hauts-de-France
2615,PONTRUET,2490,PONTRUET,,49.9148506173,3.2294738031,615,,Pontruet,Pontruet,2,Aisne,32,Hauts-de-France
2616,PONT ST MARD,2380,PONT ST MARD,,49.493979148,3.27934093822,616,,Pont-Saint-Mard,Pont-Saint-Mard,2,Aisne,32,Hauts-de-France
2617,POUILLY SUR SERRE,2270,POUILLY SUR SERRE,,49.6778615755,3.58559308314,617,,Pouilly-sur-Serre,Pouilly-sur-Serre,2,Aisne,32,Hauts-de-France
2618,PREMONT,2110,PREMONT,,50.0143067533,3.39725258008,618,,Prémont,Prémont,2,Aisne,32,Hauts-de-France
2619,PREMONTRE,2320,PREMONTRE,,49.5493580112,3.4177638358,619,,Prémontré,Prémontré,2,Aisne,32,Hauts-de-France
2620,PRESLES ET BOVES,2370,PRESLES ET BOVES,,49.3900726835,3.54013980029,620,,Presles-et-Boves,Presles-et-Boves,2,Aisne,32,Hauts-de-France
2621,PRESLES ET THIERNY,2860,PRESLES ET THIERNY,,49.5144603614,3.62483361601,621,,Presles-et-Thierny,Presles-et-Thierny,2,Aisne,32,Hauts-de-France
2622,PRIEZ,2470,PRIEZ,,49.1306197467,3.26280717645,622,,Priez,Priez,2,Aisne,32,Hauts-de-France
2623,PRISCES,2140,PRISCES,,49.7721688271,3.86274146906,623,,Prisces,Prisces,2,Aisne,32,Hauts-de-France
2624,PROISY,2120,PROISY,,49.8906172484,3.75241120947,624,,Proisy,Proisy,2,Aisne,32,Hauts-de-France
2625,PROIX,2120,PROIX,,49.9022161869,3.57346862626,625,,Proix,Proix,2,Aisne,32,Hauts-de-France
2626,PROUVAIS,2190,PROUVAIS,,49.4760906978,3.97215349857,626,,Prouvais,Prouvais,2,Aisne,32,Hauts-de-France
2627,PROVISEUX ET PLESNOY,2190,PROVISEUX ET PLESNOY,,49.4777224772,4.0158784311,627,,Proviseux-et-Plesnoy,Proviseux-et-Plesnoy,2,Aisne,32,Hauts-de-France
2628,PUISEUX EN RETZ,2600,PUISEUX EN RETZ,,49.2925861417,3.13448879571,628,,Puiseux-en-Retz,Puiseux-en-Retz,2,Aisne,32,Hauts-de-France
2629,PUISIEUX ET CLANLIEU,2120,PUISIEUX ET CLANLIEU,,49.8472444334,3.66367663689,629,,Puisieux-et-Clanlieu,Puisieux-et-Clanlieu,2,Aisne,32,Hauts-de-France
2631,QUIERZY,2300,QUIERZY,,49.5641090409,3.14613795931,631,,Quierzy,Quierzy,2,Aisne,32,Hauts-de-France
2632,QUINCY BASSE,2380,QUINCY BASSE,,49.5091437001,3.38465364189,632,,Quincy-Basse,Quincy-Basse,2,Aisne,32,Hauts-de-France
2633,QUINCY SOUS LE MONT,2220,QUINCY SOUS LE MONT,,49.3082323924,3.55906421912,633,,Quincy-sous-le-Mont,Quincy-sous-le-Mont,2,Aisne,32,Hauts-de-France
2634,RAILLIMONT,2360,RAILLIMONT,,49.701759318,4.15934737957,634,,Raillimont,Raillimont,2,Aisne,32,Hauts-de-France
2635,RAMICOURT,2110,RAMICOURT,,49.9579684711,3.33105686967,635,,Ramicourt,Ramicourt,2,Aisne,32,Hauts-de-France
2636,REGNY,2240,REGNY,,49.8343070304,3.42061400129,636,,Regny,Regny,2,Aisne,32,Hauts-de-France
2637,REMAUCOURT,2100,REMAUCOURT,,49.8954350618,3.34948034131,637,,Remaucourt,Remaucourt,2,Aisne,32,Hauts-de-France
2638,REMIES,2270,REMIES,,49.6793679757,3.52478064261,638,,Remies,Remies,2,Aisne,32,Hauts-de-France
2639,REMIGNY,2440,REMIGNY,,49.7141625709,3.29518707017,639,,Remigny,Remigny,2,Aisne,32,Hauts-de-France
2640,RENANSART,2240,RENANSART,,49.7340931411,3.45338513198,640,,Renansart,Renansart,2,Aisne,32,Hauts-de-France
2641,RENNEVAL,2340,RENNEVAL,,49.7391714019,4.03674170066,641,,Renneval,Renneval,2,Aisne,32,Hauts-de-France
2642,RESIGNY,2360,RESIGNY,,49.7413860683,4.21000271409,642,,Résigny,Résigny,2,Aisne,32,Hauts-de-France
2643,RESSONS LE LONG,2290,RESSONS LE LONG,,49.3881779495,3.1411755288,643,,Ressons-le-Long,Ressons-le-Long,2,Aisne,32,Hauts-de-France
2644,RETHEUIL,2600,RETHEUIL,,49.318909114,3.00378064988,644,,Retheuil,Retheuil,2,Aisne,32,Hauts-de-France
2645,REUILLY SAUVIGNY,2850,REUILLY SAUVIGNY,,49.0506024106,3.56448771259,645,,Reuilly-Sauvigny,Reuilly-Sauvigny,2,Aisne,32,Hauts-de-France
2647,RIBEAUVILLE,2110,RIBEAUVILLE,,50.0366991182,3.58754496166,647,,Ribeauville,Ribeauville,2,Aisne,32,Hauts-de-France
2648,RIBEMONT,2240,RIBEMONT,,49.7885436581,3.47018526049,648,,Ribemont,Ribemont,2,Aisne,32,Hauts-de-France
2649,ROCOURT ST MARTIN,2210,ROCOURT ST MARTIN,,49.1453872803,3.3891401292,649,,Rocourt-Saint-Martin,Rocourt-Saint-Martin,2,Aisne,32,Hauts-de-France
2650,ROCQUIGNY,2260,ROCQUIGNY,,50.0193341952,3.97342250903,650,,Rocquigny,Rocquigny,2,Aisne,32,Hauts-de-France
2651,ROGECOURT,2800,ROGECOURT,,49.6492919859,3.41550074354,651,,Rogécourt,Rogécourt,2,Aisne,32,Hauts-de-France
2652,ROGNY,2140,ROGNY,,49.766712979,3.82104672788,652,,Rogny,Rogny,2,Aisne,32,Hauts-de-France
2653,ROMENY SUR MARNE,2310,ROMENY SUR MARNE,,48.9852721229,3.3344209065,653,,Romeny-sur-Marne,Romeny-sur-Marne,2,Aisne,32,Hauts-de-France
2654,ROMERY,2120,ROMERY,,49.8882844958,3.73266627848,654,,Romery,Romery,2,Aisne,32,Hauts-de-France
2655,RONCHERES,2130,RONCHERES,,49.1398358197,3.60245169493,655,,Ronchères,Ronchères,2,Aisne,32,Hauts-de-France
2656,ROUCY,2160,ROUCY,,49.3745878159,3.82024502091,656,,Roucy,Roucy,2,Aisne,32,Hauts-de-France
2657,ROUGERIES,2140,ROUGERIES,,49.8056045885,3.78694787331,657,,Rougeries,Rougeries,2,Aisne,32,Hauts-de-France
2658,ROUPY,2590,ROUPY,,49.8120572031,3.18195896491,658,,Roupy,Roupy,2,Aisne,32,Hauts-de-France
2659,ROUVROY,2100,ROUVROY,,49.8579447094,3.33298681219,659,,Rouvroy,Rouvroy,2,Aisne,32,Hauts-de-France
2660,ROUVROY SUR SERRE,2360,ROUVROY SUR SERRE,,49.7153670931,4.17597798145,660,,Rouvroy-sur-Serre,Rouvroy-sur-Serre,2,Aisne,32,Hauts-de-France
2661,ROYAUCOURT ET CHAILVET,2000,ROYAUCOURT ET CHAILVET,,49.5119671872,3.53821206051,661,,Royaucourt-et-Chailvet,Royaucourt-et-Chailvet,2,Aisne,32,Hauts-de-France
2662,ROZET ST ALBIN,2210,ROZET ST ALBIN,,49.1982180685,3.29738760461,662,,Rozet-Saint-Albin,Rozet-Saint-Albin,2,Aisne,32,Hauts-de-France
2663,ROZIERES SUR CRISE,2200,ROZIERES SUR CRISE,,49.3227443516,3.37101592804,663,,Rozières-sur-Crise,Rozières-sur-Crise,2,Aisne,32,Hauts-de-France
2664,ROZOY BELLEVALLE,2540,ROZOY BELLEVALLE,,48.9244622578,3.45003603667,664,,Rozoy-Bellevalle,Rozoy-Bellevalle,2,Aisne,32,Hauts-de-France
2665,GRAND ROZOY,2210,GRAND ROZOY,,49.2423109815,3.38147360131,665,,Grand-Rozoy,Grand-Rozoy,2,Aisne,32,Hauts-de-France
2666,ROZOY SUR SERRE,2360,ROZOY SUR SERRE,,49.706447861,4.13134010865,666,,Rozoy-sur-Serre,Rozoy-sur-Serre,2,Aisne,32,Hauts-de-France
2667,SACONIN ET BREUIL,2200,SACONIN ET BREUIL,,49.3506902358,3.24672146323,667,,Saconin-et-Breuil,Saconin-et-Breuil,2,Aisne,32,Hauts-de-France
2668,SAINS RICHAUMONT,2120,SAINS RICHAUMONT,,49.8276068685,3.70490926861,668,,Sains-Richaumont,Sains-Richaumont,2,Aisne,32,Hauts-de-France
2670,ST ALGIS,2260,ST ALGIS,,49.8944330304,3.82546447463,670,,Saint-Algis,Saint-Algis,2,Aisne,32,Hauts-de-France
2671,ST AUBIN,2300,ST AUBIN,,49.5099540337,3.18837580055,671,,Saint-Aubin,Saint-Aubin,2,Aisne,32,Hauts-de-France
2672,ST BANDRY,2290,ST BANDRY,,49.3638994868,3.14949009826,672,,Saint-Bandry,Saint-Bandry,2,Aisne,32,Hauts-de-France
2673,ST CHRISTOPHE A BERRY,2290,ST CHRISTOPHE A BERRY,,49.4290499245,3.12949472733,673,,Saint-Christophe-à-Berry,Saint-Christophe-à-Berry,2,Aisne,32,Hauts-de-France
2674,ST CLEMENT,2360,ST CLEMENT,,49.7752799579,4.07288901165,674,,Saint-Clément,Saint-Clément,2,Aisne,32,Hauts-de-France
2675,STE CROIX,2820,STE CROIX,,49.4800538584,3.78129682642,675,,Sainte-Croix,Sainte-Croix,2,Aisne,32,Hauts-de-France
2676,ST ERME OUTRE ET RAMECOURT,2820,ST ERME OUTRE ET RAMECOURT,,49.5182833727,3.86386406835,676,,Saint-Erme-Outre-et-Ramecourt,Saint-Erme-Outre-et-Ramecourt,2,Aisne,32,Hauts-de-France
2677,ST EUGENE,2330,ST EUGENE,,49.0155450781,3.51721331428,677,,Saint-Eugène,Saint-Eugène,2,Aisne,32,Hauts-de-France
2678,STE GENEVIEVE,2340,STE GENEVIEVE,,49.7247039611,4.07368196391,678,,Sainte-Geneviève,Sainte-Geneviève,2,Aisne,32,Hauts-de-France
2679,ST GENGOULPH,2810,ST GENGOULPH,,49.1189499126,3.21526676033,679,,Saint-Gengoulph,Saint-Gengoulph,2,Aisne,32,Hauts-de-France
2680,ST GOBAIN,2410,ST GOBAIN,,49.5953887213,3.39411143672,680,,Saint-Gobain,Saint-Gobain,2,Aisne,32,Hauts-de-France
2681,ST GOBERT,2140,ST GOBERT,,49.8037605968,3.83219152047,681,,Saint-Gobert,Saint-Gobert,2,Aisne,32,Hauts-de-France
2682,ST MARD,2220,ST MARD,,49.3813338634,3.58902035004,682,,Saint-Mard,Saint-Mard,2,Aisne,32,Hauts-de-France
2683,ST MARTIN RIVIERE,2110,ST MARTIN RIVIERE,,50.044008328,3.55486334419,683,,Saint-Martin-Rivière,Saint-Martin-Rivière,2,Aisne,32,Hauts-de-France
2684,ST MICHEL,2830,ST MICHEL,,49.9259700696,4.15288229364,684,,Saint-Michel,Saint-Michel,2,Aisne,32,Hauts-de-France
2685,ST NICOLAS AUX BOIS,2410,ST NICOLAS AUX BOIS,,49.5970302282,3.43898003392,685,,Saint-Nicolas-aux-Bois,Saint-Nicolas-aux-Bois,2,Aisne,32,Hauts-de-France
2686,ST PAUL AUX BOIS,2300,ST PAUL AUX BOIS,,49.5443121228,3.20260437248,686,,Saint-Paul-aux-Bois,Saint-Paul-aux-Bois,2,Aisne,32,Hauts-de-France
2687,ST PIERRE AIGLE,2600,ST PIERRE AIGLE,,49.3121621719,3.19602649155,687,,Saint-Pierre-Aigle,Saint-Pierre-Aigle,2,Aisne,32,Hauts-de-France
2688,ST PIERRE LES FRANQUEVILLE,2140,ST PIERRE LES FRANQUEVILLE,,49.824528104,3.81406121819,688,,Saint-Pierre-lès-Franqueville,Saint-Pierre-lès-Franqueville,2,Aisne,32,Hauts-de-France
2689,ST PIERREMONT,2250,ST PIERREMONT,,49.7138800989,3.8761235335,689,,Saint-Pierremont,Saint-Pierremont,2,Aisne,32,Hauts-de-France
2690,STE PREUVE,2350,STE PREUVE,,49.6173037494,3.91098324267,690,,Sainte-Preuve,Sainte-Preuve,2,Aisne,32,Hauts-de-France
2691,ST QUENTIN,2100,ST QUENTIN,,49.8472336321,3.27769499462,691,,Saint-Quentin,Saint-Quentin,2,Aisne,32,Hauts-de-France
2693,ST REMY BLANZY,2210,ST REMY BLANZY,,49.2457583592,3.30773725902,693,,Saint-Rémy-Blanzy,Saint-Rémy-Blanzy,2,Aisne,32,Hauts-de-France
2694,ST SIMON,2640,ST SIMON,,49.7458877556,3.18260412516,694,,Saint-Simon,Saint-Simon,2,Aisne,32,Hauts-de-France
2695,ST THIBAUT,2220,ST THIBAUT,,49.2891405131,3.61650327087,695,,Saint-Thibaut,Saint-Thibaut,2,Aisne,32,Hauts-de-France
2696,ST THOMAS,2820,ST THOMAS,,49.5014100438,3.81925378492,696,,Saint-Thomas,Saint-Thomas,2,Aisne,32,Hauts-de-France
2697,SAMOUSSY,2840,SAMOUSSY,,49.5868706589,3.74329094453,697,,Samoussy,Samoussy,2,Aisne,32,Hauts-de-France
2698,SANCY LES CHEMINOTS,2880,SANCY LES CHEMINOTS,,49.4393806827,3.47649552313,698,,Sancy-les-Cheminots,Sancy-les-Cheminots,2,Aisne,32,Hauts-de-France
2699,SAPONAY,2130,SAPONAY,,49.2143831516,3.48437985358,699,,Saponay,Saponay,2,Aisne,32,Hauts-de-France
2701,SAULCHERY,2310,SAULCHERY,,48.9756405479,3.31260850509,701,,Saulchery,Saulchery,2,Aisne,32,Hauts-de-France
2702,SAVY,2590,SAVY,,49.8343646886,3.19764190963,702,,Savy,Savy,2,Aisne,32,Hauts-de-France
2703,SEBONCOURT,2110,SEBONCOURT,,49.9626066437,3.49425998163,703,,Seboncourt,Seboncourt,2,Aisne,32,Hauts-de-France
2704,SELENS,2300,SELENS,,49.4943706034,3.20586043656,704,,Selens,Selens,2,Aisne,32,Hauts-de-France
2705,LA SELVE,2150,LA SELVE,,49.5698786702,3.99524867672,705,La,Selve,La Selve,2,Aisne,32,Hauts-de-France
2706,SEPTMONTS,2200,SEPTMONTS,,49.3384597812,3.36210276584,706,,Septmonts,Septmonts,2,Aisne,32,Hauts-de-France
2707,SEPTVAUX,2410,SEPTVAUX,,49.5697880929,3.38679579518,707,,Septvaux,Septvaux,2,Aisne,32,Hauts-de-France
2708,SEQUEHART,2420,SEQUEHART,,49.9320260511,3.34096519199,708,,Sequehart,Sequehart,2,Aisne,32,Hauts-de-France
2709,SERAIN,2110,SERAIN,,50.0246912807,3.36304349229,709,,Serain,Serain,2,Aisne,32,Hauts-de-France
2710,SERAUCOURT LE GRAND,2790,SERAUCOURT LE GRAND,,49.780519235,3.21829749297,710,,Seraucourt-le-Grand,Seraucourt-le-Grand,2,Aisne,32,Hauts-de-France
2711,SERCHES,2220,SERCHES,,49.3320664098,3.44951495068,711,,Serches,Serches,2,Aisne,32,Hauts-de-France
2712,SERGY,2130,SERGY,,49.1828178666,3.57092215608,712,,Sergy,Sergy,2,Aisne,32,Hauts-de-France
2713,SERINGES ET NESLES,2130,SERINGES ET NESLES,,49.2116537607,3.55885016448,713,,Seringes-et-Nesles,Seringes-et-Nesles,2,Aisne,32,Hauts-de-France
2714,SERMOISE,2220,SERMOISE,,49.3724753933,3.44697180612,714,,Sermoise,Sermoise,2,Aisne,32,Hauts-de-France
2715,SERVAL,2160,SERVAL,,49.352069984,3.68270184221,715,,Serval,Serval,2,Aisne,32,Hauts-de-France
2716,SERVAIS,2700,SERVAIS,,49.6194083182,3.34197325752,716,,Servais,Servais,2,Aisne,32,Hauts-de-France
2717,SERY LES MEZIERES,2240,SERY LES MEZIERES,,49.7647253763,3.42321314919,717,,Séry-lès-Mézières,Séry-lès-Mézières,2,Aisne,32,Hauts-de-France
2718,SILLY LA POTERIE,2460,SILLY LA POTERIE,,49.1959898805,3.15010276155,718,,Silly-la-Poterie,Silly-la-Poterie,2,Aisne,32,Hauts-de-France
2719,SINCENY,2300,SINCENY,,49.5910938147,3.26877002882,719,,Sinceny,Sinceny,2,Aisne,32,Hauts-de-France
2720,SISSONNE,2150,SISSONNE,,49.5639648451,3.92032139579,720,,Sissonne,Sissonne,2,Aisne,32,Hauts-de-France
2721,SISSY,2240,SISSY,,49.8106555228,3.42084647603,721,,Sissy,Sissy,2,Aisne,32,Hauts-de-France
2722,SOISSONS,2200,SOISSONS,,49.3791742979,3.32471758491,722,,Soissons,Soissons,2,Aisne,32,Hauts-de-France
2723,SOIZE,2340,SOIZE,,49.6977848851,4.08583269025,723,,Soize,Soize,2,Aisne,32,Hauts-de-France
2724,SOMMELANS,2470,SOMMELANS,,49.135392,3.29302303663,724,,Sommelans,Sommelans,2,Aisne,32,Hauts-de-France
2725,SOMMERON,2260,SOMMERON,,49.9569688333,3.94573288215,725,,Sommeron,Sommeron,2,Aisne,32,Hauts-de-France
2726,SOMMETTE EAUCOURT,2480,SOMMETTE EAUCOURT,,49.727418066,3.11947808471,726,,Sommette-Eaucourt,Sommette-Eaucourt,2,Aisne,32,Hauts-de-France
2727,SONS ET RONCHERES,2270,SONS ET RONCHERES,,49.7689463972,3.66761782458,727,,Sons-et-Ronchères,Sons-et-Ronchères,2,Aisne,32,Hauts-de-France
2728,SORBAIS,2580,SORBAIS,,49.9137614487,3.88302482521,728,,Sorbais,Sorbais,2,Aisne,32,Hauts-de-France
2729,SOUCY,2600,SOUCY,,49.3209750175,3.12324909086,729,,Soucy,Soucy,2,Aisne,32,Hauts-de-France
2730,SOUPIR,2160,SOUPIR,,49.4106784751,3.59989736789,730,,Soupir,Soupir,2,Aisne,32,Hauts-de-France
2731,LE SOURD,2140,LE SOURD,,49.8656956341,3.75208012539,731,Le,Sourd,Le Sourd,2,Aisne,32,Hauts-de-France
2732,SURFONTAINE,2240,SURFONTAINE,,49.7471421081,3.47979315066,732,,Surfontaine,Surfontaine,2,Aisne,32,Hauts-de-France
2733,SUZY,2320,SUZY,,49.556451044,3.46091896853,733,,Suzy,Suzy,2,Aisne,32,Hauts-de-France
2734,TAILLEFONTAINE,2600,TAILLEFONTAINE,,49.3099328056,3.04962539056,734,,Taillefontaine,Taillefontaine,2,Aisne,32,Hauts-de-France
2735,TANNIERES,2220,TANNIERES,,49.2873370949,3.55040892873,735,,Tannières,Tannières,2,Aisne,32,Hauts-de-France
2736,TARTIERS,2290,TARTIERS,,49.4400811642,3.23156556276,736,,Tartiers,Tartiers,2,Aisne,32,Hauts-de-France
2737,TAVAUX ET PONTSERICOURT,2250,TAVAUX ET PONTSERICOURT,,49.7296446334,3.9190519179,737,,Tavaux-et-Pontséricourt,Tavaux-et-Pontséricourt,2,Aisne,32,Hauts-de-France
2738,TERGNIER,2700,TERGNIER,,49.6572646109,3.29924077478,738,,Tergnier,Tergnier,2,Aisne,32,Hauts-de-France
2738,TERGNIER,2700,TERGNIER,FARGNIERS,49.6572646109,3.29924077478,738,,Tergnier,Tergnier,2,Aisne,32,Hauts-de-France
2738,TERGNIER,2700,TERGNIER,QUESSY,49.6572646109,3.29924077478,738,,Tergnier,Tergnier,2,Aisne,32,Hauts-de-France
2738,TERGNIER,2700,TERGNIER,VOUEL,49.6572646109,3.29924077478,738,,Tergnier,Tergnier,2,Aisne,32,Hauts-de-France
2739,TERNY SORNY,2880,TERNY SORNY,,49.4472096599,3.36942791187,739,,Terny-Sorny,Terny-Sorny,2,Aisne,32,Hauts-de-France
2740,THENAILLES,2140,THENAILLES,,49.8149576709,3.95232479493,740,,Thenailles,Thenailles,2,Aisne,32,Hauts-de-France
2741,THENELLES,2390,THENELLES,,49.8314948078,3.45894075748,741,,Thenelles,Thenelles,2,Aisne,32,Hauts-de-France
2742,THIERNU,2250,THIERNU,,49.7649515663,3.77987535834,742,,Thiernu,Thiernu,2,Aisne,32,Hauts-de-France
2743,LE THUEL,2340,LE THUEL,,49.6413105954,4.08903091475,743,Le,Thuel,Le Thuel,2,Aisne,32,Hauts-de-France
2744,TORCY EN VALOIS,2810,TORCY EN VALOIS,,49.0828638075,3.27358839647,744,,Torcy-en-Valois,Torcy-en-Valois,2,Aisne,32,Hauts-de-France
2745,TOULIS ET ATTENCOURT,2250,TOULIS ET ATTENCOURT,,49.6970260523,3.75052883427,745,,Toulis-et-Attencourt,Toulis-et-Attencourt,2,Aisne,32,Hauts-de-France
2746,TRAVECY,2800,TRAVECY,,49.6858217171,3.34481845935,746,,Travecy,Travecy,2,Aisne,32,Hauts-de-France
2747,TREFCON,2490,TREFCON,,49.859082365,3.08864647254,747,,Trefcon,Trefcon,2,Aisne,32,Hauts-de-France
2748,TRELOU SUR MARNE,2850,TRELOU SUR MARNE,,49.0916773783,3.60157906913,748,,Trélou-sur-Marne,Trélou-sur-Marne,2,Aisne,32,Hauts-de-France
2749,TROESNES,2460,TROESNES,,49.1981484588,3.1768274022,749,,Troësnes,Troësnes,2,Aisne,32,Hauts-de-France
2750,TROSLY LOIRE,2300,TROSLY LOIRE,,49.5175794818,3.23167717474,750,,Trosly-Loire,Trosly-Loire,2,Aisne,32,Hauts-de-France
2751,TRUCY,2860,TRUCY,,49.472149818,3.61092318317,751,,Trucy,Trucy,2,Aisne,32,Hauts-de-France
2752,TUGNY ET PONT,2640,TUGNY ET PONT,,49.7704032986,3.16072597634,752,,Tugny-et-Pont,Tugny-et-Pont,2,Aisne,32,Hauts-de-France
2753,TUPIGNY,2120,TUPIGNY,,49.9705608416,3.59399622464,753,,Tupigny,Tupigny,2,Aisne,32,Hauts-de-France
2754,UGNY LE GAY,2300,UGNY LE GAY,,49.6610045264,3.15663213439,754,,Ugny-le-Gay,Ugny-le-Gay,2,Aisne,32,Hauts-de-France
2755,URCEL,2000,URCEL,,49.4940437763,3.55713233917,755,,Urcel,Urcel,2,Aisne,32,Hauts-de-France
2756,URVILLERS,2690,URVILLERS,,49.7897507931,3.31629966887,756,,Urvillers,Urvillers,2,Aisne,32,Hauts-de-France
2757,VADENCOURT,2120,VADENCOURT,,49.9229771613,3.57386118846,757,,Vadencourt,Vadencourt,2,Aisne,32,Hauts-de-France
2757,VADENCOURT,2120,VADENCOURT,LONGCHAMPS,49.9229771613,3.57386118846,757,,Vadencourt,Vadencourt,2,Aisne,32,Hauts-de-France
2758,VAILLY SUR AISNE,2370,VAILLY SUR AISNE,,49.4108623288,3.52012059802,758,,Vailly-sur-Aisne,Vailly-sur-Aisne,2,Aisne,32,Hauts-de-France
2759,LA VALLEE AU BLE,2140,LA VALLEE AU BLE,,49.8571725081,3.794382858,759,La,Vallée-au-Blé,La Vallée-au-Blé,2,Aisne,32,Hauts-de-France
2760,LA VALLEE MULATRE,2110,LA VALLEE MULATRE,,50.0255708699,3.56063329937,760,La,Vallée-Mulâtre,La Vallée-Mulâtre,2,Aisne,32,Hauts-de-France
2761,VARISCOURT,2190,VARISCOURT,,49.4148891865,3.98066282251,761,,Variscourt,Variscourt,2,Aisne,32,Hauts-de-France
2762,VASSENS,2290,VASSENS,,49.4754669876,3.15640761403,762,,Vassens,Vassens,2,Aisne,32,Hauts-de-France
2763,VASSENY,2220,VASSENY,,49.3556275252,3.48910249175,763,,Vasseny,Vasseny,2,Aisne,32,Hauts-de-France
2764,VASSOGNE,2160,VASSOGNE,,49.4200443432,3.72979191104,764,,Vassogne,Vassogne,2,Aisne,32,Hauts-de-France
2765,VAUCELLES ET BEFFECOURT,2000,VAUCELLES ET BEFFECOURT,,49.5206900038,3.55877460724,765,,Vaucelles-et-Beffecourt,Vaucelles-et-Beffecourt,2,Aisne,32,Hauts-de-France
2766,VAUDESSON,2320,VAUDESSON,,49.4750001736,3.48695975523,766,,Vaudesson,Vaudesson,2,Aisne,32,Hauts-de-France
2767,VAUXREZIS,2200,VAUXREZIS,,49.4205077489,3.27709335238,767,,Vauxrezis,Vauxrezis,2,Aisne,32,Hauts-de-France
2768,VAUXAILLON,2320,VAUXAILLON,,49.4770651148,3.40823751638,768,,Vauxaillon,Vauxaillon,2,Aisne,32,Hauts-de-France
2769,VAUX ANDIGNY,2110,VAUX ANDIGNY,,50.0111472485,3.52295400201,769,,Vaux-Andigny,Vaux-Andigny,2,Aisne,32,Hauts-de-France
2770,VAUXBUIN,2200,VAUXBUIN,,49.357283338,3.29549391938,770,,Vauxbuin,Vauxbuin,2,Aisne,32,Hauts-de-France
2772,VAUX EN VERMANDOIS,2590,VAUX EN VERMANDOIS,,49.8238668444,3.13578987374,772,,Vaux-en-Vermandois,Vaux-en-Vermandois,2,Aisne,32,Hauts-de-France
2773,VAUXTIN,2220,VAUXTIN,,49.3454673056,3.60165884249,773,,Vauxtin,Vauxtin,2,Aisne,32,Hauts-de-France
2774,VENDELLES,2490,VENDELLES,,49.9078313873,3.1381693574,774,,Vendelles,Vendelles,2,Aisne,32,Hauts-de-France
2775,VENDEUIL,2800,VENDEUIL,,49.719870808,3.3449169008,775,,Vendeuil,Vendeuil,2,Aisne,32,Hauts-de-France
2776,VENDHUILE,2420,VENDHUILE,,50.0120254621,3.20888614063,776,,Vendhuile,Vendhuile,2,Aisne,32,Hauts-de-France
2777,VENDIERES,2540,VENDIERES,,48.8731710961,3.44301787703,777,,Vendières,Vendières,2,Aisne,32,Hauts-de-France
2778,VENDRESSE BEAULNE,2160,VENDRESSE BEAULNE,,49.4317132906,3.65690243676,778,,Vendresse-Beaulne,Vendresse-Beaulne,2,Aisne,32,Hauts-de-France
2779,VENEROLLES,2510,VENEROLLES,,49.9903433031,3.63008652624,779,,Vénérolles,Vénérolles,2,Aisne,32,Hauts-de-France
2780,VENIZEL,2200,VENIZEL,,49.3676425771,3.39248543249,780,,Venizel,Venizel,2,Aisne,32,Hauts-de-France
2781,VERDILLY,2400,VERDILLY,,49.0744862502,3.42189905232,781,,Verdilly,Verdilly,2,Aisne,32,Hauts-de-France
2782,LE VERGUIER,2490,LE VERGUIER,,49.9231401902,3.16936385423,782,Le,Verguier,Le Verguier,2,Aisne,32,Hauts-de-France
2783,GRAND VERLY,2120,GRAND VERLY,,49.9434199886,3.58138282505,783,,Grand-Verly,Grand-Verly,2,Aisne,32,Hauts-de-France
2784,PETIT VERLY,2630,PETIT VERLY,,49.9581493631,3.56275189688,784,,Petit-Verly,Petit-Verly,2,Aisne,32,Hauts-de-France
2785,VERMAND,2490,VERMAND,,49.8839098456,3.15146515668,785,,Vermand,Vermand,2,Aisne,32,Hauts-de-France
2786,VERNEUIL SOUS COUCY,2380,VERNEUIL SOUS COUCY,,49.5451146096,3.31890035764,786,,Verneuil-sous-Coucy,Verneuil-sous-Coucy,2,Aisne,32,Hauts-de-France
2787,VERNEUIL SUR SERRE,2000,VERNEUIL SUR SERRE,,49.6436030037,3.67943401416,787,,Verneuil-sur-Serre,Verneuil-sur-Serre,2,Aisne,32,Hauts-de-France
2788,VERSIGNY,2800,VERSIGNY,,49.658136733,3.44270966266,788,,Versigny,Versigny,2,Aisne,32,Hauts-de-France
2789,VERVINS,2140,VERVINS,,49.8351798708,3.92522602825,789,,Vervins,Vervins,2,Aisne,32,Hauts-de-France
2790,VESLES ET CAUMONT,2350,VESLES ET CAUMONT,,49.6750932677,3.78950879053,790,,Vesles-et-Caumont,Vesles-et-Caumont,2,Aisne,32,Hauts-de-France
2791,VESLUD,2840,VESLUD,,49.5387490108,3.73067663493,791,,Veslud,Veslud,2,Aisne,32,Hauts-de-France
2792,VEUILLY LA POTERIE,2810,VEUILLY LA POTERIE,,49.0832976282,3.2188650234,792,,Veuilly-la-Poterie,Veuilly-la-Poterie,2,Aisne,32,Hauts-de-France
2793,VEZAPONIN,2290,VEZAPONIN,,49.4667498193,3.22298094528,793,,Vézaponin,Vézaponin,2,Aisne,32,Hauts-de-France
2794,VEZILLY,2130,VEZILLY,,49.1874443601,3.68274149419,794,,Vézilly,Vézilly,2,Aisne,32,Hauts-de-France
2795,VIC SUR AISNE,2290,VIC SUR AISNE,,49.410747629,3.10912550549,795,,Vic-sur-Aisne,Vic-sur-Aisne,2,Aisne,32,Hauts-de-France
2796,VICHEL NANTEUIL,2210,VICHEL NANTEUIL,,49.1822486294,3.29889935708,796,,Vichel-Nanteuil,Vichel-Nanteuil,2,Aisne,32,Hauts-de-France
2797,VIEL ARCY,2160,VIEL ARCY,,49.3789345254,3.62589443596,797,,Viel-Arcy,Viel-Arcy,2,Aisne,32,Hauts-de-France
2798,VIELS MAISONS,2540,VIELS MAISONS,,48.9070425737,3.39571892839,798,,Viels-Maisons,Viels-Maisons,2,Aisne,32,Hauts-de-France
2799,VIERZY,2210,VIERZY,,49.2868570454,3.27759490696,799,,Vierzy,Vierzy,2,Aisne,32,Hauts-de-France
2800,VIFFORT,2540,VIFFORT,,48.9576165601,3.46972594402,800,,Viffort,Viffort,2,Aisne,32,Hauts-de-France
2801,VIGNEUX HOCQUET,2340,VIGNEUX HOCQUET,,49.7440393657,3.99489165596,801,,Vigneux-Hocquet,Vigneux-Hocquet,2,Aisne,32,Hauts-de-France
2802,LA VILLE AUX BOIS LES DIZY,2340,LA VILLE AUX BOIS LES DIZY,,49.6626572968,3.98152083729,802,La,Ville-aux-Bois-lès-Dizy,La Ville-aux-Bois-lès-Dizy,2,Aisne,32,Hauts-de-France
2803,LA VILLE AUX BOIS LES PONTAVERT,2160,LA VILLE AUX BOIS LES PONTAVERT,,49.4265972983,3.85108262844,803,La,Ville-aux-Bois-lès-Pontavert,La Ville-aux-Bois-lès-Pontavert,2,Aisne,32,Hauts-de-France
2804,VILLEMONTOIRE,2210,VILLEMONTOIRE,,49.3000199564,3.32189672243,804,,Villemontoire,Villemontoire,2,Aisne,32,Hauts-de-France
2805,VILLENEUVE ST GERMAIN,2200,VILLENEUVE ST GERMAIN,,49.3789013616,3.36175815803,805,,Villeneuve-Saint-Germain,Villeneuve-Saint-Germain,2,Aisne,32,Hauts-de-France
2806,VILLENEUVE SUR FERE,2130,VILLENEUVE SUR FERE,,49.1706936984,3.47593842959,806,,Villeneuve-sur-Fère,Villeneuve-sur-Fère,2,Aisne,32,Hauts-de-France
2807,VILLEQUIER AUMONT,2300,VILLEQUIER AUMONT,,49.6606240148,3.19834244186,807,,Villequier-Aumont,Villequier-Aumont,2,Aisne,32,Hauts-de-France
2808,VILLERET,2420,VILLERET,,49.9494418046,3.18749892652,808,,Villeret,Villeret,2,Aisne,32,Hauts-de-France
2809,VILLERS AGRON AIGUIZY,2130,VILLERS AGRON AIGUIZY,,49.1632072457,3.71636244573,809,,Villers-Agron-Aiguizy,Villers-Agron-Aiguizy,2,Aisne,32,Hauts-de-France
2810,VILLERS COTTERETS,2600,VILLERS COTTERETS,,49.2392000574,3.09612191097,810,,Villers-Cotterêts,Villers-Cotterêts,2,Aisne,32,Hauts-de-France
2810,VILLERS COTTERETS,2600,VILLERS COTTERETS,PISSELEUX,49.2392000574,3.09612191097,810,,Villers-Cotterêts,Villers-Cotterêts,2,Aisne,32,Hauts-de-France
2812,VILLERS HELON,2600,VILLERS HELON,,49.2649355463,3.26786134731,812,,Villers-Hélon,Villers-Hélon,2,Aisne,32,Hauts-de-France
2813,VILLERS LE SEC,2240,VILLERS LE SEC,,49.7711456702,3.50490696746,813,,Villers-le-Sec,Villers-le-Sec,2,Aisne,32,Hauts-de-France
2814,VILLERS LES GUISE,2120,VILLERS LES GUISE,,49.9255661982,3.68241664598,814,,Villers-lès-Guise,Villers-lès-Guise,2,Aisne,32,Hauts-de-France
2815,VILLERS ST CHRISTOPHE,2590,VILLERS ST CHRISTOPHE,,49.7796914155,3.09099897496,815,,Villers-Saint-Christophe,Villers-Saint-Christophe,2,Aisne,32,Hauts-de-France
2816,VILLERS SUR FERE,2130,VILLERS SUR FERE,,49.1715150184,3.53152683586,816,,Villers-sur-Fère,Villers-sur-Fère,2,Aisne,32,Hauts-de-France
2817,VILLE SAVOYE,2220,VILLE SAVOYE,,49.2929807511,3.63762361829,817,,Ville-Savoye,Ville-Savoye,2,Aisne,32,Hauts-de-France
2818,VILLIERS ST DENIS,2310,VILLIERS ST DENIS,,48.9977206046,3.26104064101,818,,Villiers-Saint-Denis,Villiers-Saint-Denis,2,Aisne,32,Hauts-de-France
2819,VINCY REUIL ET MAGNY,2340,VINCY REUIL ET MAGNY,,49.7140295341,4.04512127967,819,,Vincy-Reuil-et-Magny,Vincy-Reuil-et-Magny,2,Aisne,32,Hauts-de-France
2820,VIRY NOUREUIL,2300,VIRY NOUREUIL,,49.6385456387,3.24823591959,820,,Viry-Noureuil,Viry-Noureuil,2,Aisne,32,Hauts-de-France
2821,VIVAISE,2870,VIVAISE,,49.6228688465,3.56431943267,821,,Vivaise,Vivaise,2,Aisne,32,Hauts-de-France
2822,VIVIERES,2600,VIVIERES,,49.3051885401,3.09196075945,822,,Vivières,Vivières,2,Aisne,32,Hauts-de-France
2823,VOHARIES,2140,VOHARIES,,49.7871481245,3.79203868186,823,,Voharies,Voharies,2,Aisne,32,Hauts-de-France
2824,VORGES,2860,VORGES,,49.5175796033,3.65105369026,824,,Vorges,Vorges,2,Aisne,32,Hauts-de-France
2826,VOULPAIX,2140,VOULPAIX,,49.8387507375,3.83266238617,826,,Voulpaix,Voulpaix,2,Aisne,32,Hauts-de-France
2827,VOYENNE,2250,VOYENNE,,49.7158793314,3.73841251024,827,,Voyenne,Voyenne,2,Aisne,32,Hauts-de-France
2828,VREGNY,2880,VREGNY,,49.4140619853,3.4197356356,828,,Vregny,Vregny,2,Aisne,32,Hauts-de-France
2829,VUILLERY,2880,VUILLERY,,49.4306831911,3.38794524263,829,,Vuillery,Vuillery,2,Aisne,32,Hauts-de-France
2830,WASSIGNY,2630,WASSIGNY,,50.017642986,3.59944731539,830,,Wassigny,Wassigny,2,Aisne,32,Hauts-de-France
2831,WATIGNY,2830,WATIGNY,,49.9163639357,4.20040317785,831,,Watigny,Watigny,2,Aisne,32,Hauts-de-France
2832,WIEGE FATY,2120,WIEGE FATY,,49.8827986391,3.7134240171,832,,Wiège-Faty,Wiège-Faty,2,Aisne,32,Hauts-de-France
2833,WIMY,2500,WIMY,,49.9480535708,3.99735885106,833,,Wimy,Wimy,2,Aisne,32,Hauts-de-France
2834,WISSIGNICOURT,2320,WISSIGNICOURT,,49.5301896159,3.44102073707,834,,Wissignicourt,Wissignicourt,2,Aisne,32,Hauts-de-France
3001,ABREST,3200,ABREST,,46.0955766716,3.45052482175,1,,Abrest,Abrest,3,Allier,84,Auvergne-Rhône-Alpes
3002,AGONGES,3210,AGONGES,,46.6180197037,3.14884278466,2,,Agonges,Agonges,3,Allier,84,Auvergne-Rhône-Alpes
3003,AINAY LE CHATEAU,3360,AINAY LE CHATEAU,,46.7155678491,2.72085613518,3,,Ainay-le-Château,Ainay-le-Château,3,Allier,84,Auvergne-Rhône-Alpes
3004,ANDELAROCHE,3120,ANDELAROCHE,,46.2501153137,3.74613192809,4,,Andelaroche,Andelaroche,3,Allier,84,Auvergne-Rhône-Alpes
3005,ARCHIGNAT,3380,ARCHIGNAT,,46.3670864602,2.41001210443,5,,Archignat,Archignat,3,Allier,84,Auvergne-Rhône-Alpes
3006,ARFEUILLES,3120,ARFEUILLES,,46.1431515084,3.74329110559,6,,Arfeuilles,Arfeuilles,3,Allier,84,Auvergne-Rhône-Alpes
3007,ARPHEUILLES ST PRIEST,3420,ARPHEUILLES ST PRIEST,,46.2269459869,2.66485932203,7,,Arpheuilles-Saint-Priest,Arpheuilles-Saint-Priest,3,Allier,84,Auvergne-Rhône-Alpes
3008,ARRONNES,3250,ARRONNES,,46.0520854633,3.57527535836,8,,Arronnes,Arronnes,3,Allier,84,Auvergne-Rhône-Alpes
3009,AUBIGNY,3460,AUBIGNY,,46.6733055007,3.16204245022,9,,Aubigny,Aubigny,3,Allier,84,Auvergne-Rhône-Alpes
3010,AUDES,3190,AUDES,,46.4607707399,2.55904786289,10,,Audes,Audes,3,Allier,84,Auvergne-Rhône-Alpes
3011,AUROUER,3460,AUROUER,,46.6830651059,3.30496843823,11,,Aurouër,Aurouër,3,Allier,84,Auvergne-Rhône-Alpes
3012,AUTRY ISSARDS,3210,AUTRY ISSARDS,,46.5445755503,3.12273629246,12,,Autry-Issards,Autry-Issards,3,Allier,84,Auvergne-Rhône-Alpes
3013,AVERMES,3000,AVERMES,,46.5983891991,3.3186329397,13,,Avermes,Avermes,3,Allier,84,Auvergne-Rhône-Alpes
3014,AVRILLY,3130,AVRILLY,,46.332137774,3.96662161873,14,,Avrilly,Avrilly,3,Allier,84,Auvergne-Rhône-Alpes
3015,BAGNEUX,3460,BAGNEUX,,46.6455268495,3.20422374955,15,,Bagneux,Bagneux,3,Allier,84,Auvergne-Rhône-Alpes
3016,BARBERIER,3140,BARBERIER,,46.2199674425,3.24930716448,16,,Barberier,Barberier,3,Allier,84,Auvergne-Rhône-Alpes
3017,BARRAIS BUSSOLLES,3120,BARRAIS BUSSOLLES,,46.2837408507,3.69766013244,17,,Barrais-Bussolles,Barrais-Bussolles,3,Allier,84,Auvergne-Rhône-Alpes
3018,BAYET,3500,BAYET,,46.257529442,3.27682744078,18,,Bayet,Bayet,3,Allier,84,Auvergne-Rhône-Alpes
3019,BEAULON,3230,BEAULON,,46.5874411926,3.66899984776,19,,Beaulon,Beaulon,3,Allier,84,Auvergne-Rhône-Alpes
3020,BEAUNE D ALLIER,3390,BEAUNE D ALLIER,,46.272817743,2.8909137183,20,,Beaune-d'Allier,Beaune-d'Allier,3,Allier,84,Auvergne-Rhône-Alpes
3021,BEGUES,3800,BEGUES,,46.1229614254,3.14273582877,21,,Bègues,Bègues,3,Allier,84,Auvergne-Rhône-Alpes
3022,BELLENAVES,3330,BELLENAVES,,46.2055132294,3.06605990143,22,,Bellenaves,Bellenaves,3,Allier,84,Auvergne-Rhône-Alpes
3023,BELLERIVE SUR ALLIER,3700,BELLERIVE SUR ALLIER,,46.1214944433,3.39719062136,23,,Bellerive-sur-Allier,Bellerive-sur-Allier,3,Allier,84,Auvergne-Rhône-Alpes
3024,BERT,3130,BERT,,46.3276009713,3.70176788462,24,,Bert,Bert,3,Allier,84,Auvergne-Rhône-Alpes
3025,BESSAY SUR ALLIER,3340,BESSAY SUR ALLIER,,46.4507552937,3.36752338544,25,,Bessay-sur-Allier,Bessay-sur-Allier,3,Allier,84,Auvergne-Rhône-Alpes
3026,BESSON,3210,BESSON,,46.4808588768,3.25477616923,26,,Besson,Besson,3,Allier,84,Auvergne-Rhône-Alpes
3027,BEZENET,3170,BEZENET,,46.3323101362,2.84939307523,27,,Bézenet,Bézenet,3,Allier,84,Auvergne-Rhône-Alpes
3028,BILLEZOIS,3120,BILLEZOIS,,46.2094826701,3.57688265252,28,,Billezois,Billezois,3,Allier,84,Auvergne-Rhône-Alpes
3029,BILLY,3260,BILLY,,46.2315363963,3.43994128747,29,,Billy,Billy,3,Allier,84,Auvergne-Rhône-Alpes
3030,BIOZAT,3800,BIOZAT,,46.0812713782,3.28868518632,30,,Biozat,Biozat,3,Allier,84,Auvergne-Rhône-Alpes
3031,BIZENEUILLE,3170,BIZENEUILLE,,46.4015746011,2.73071658216,31,,Bizeneuille,Bizeneuille,3,Allier,84,Auvergne-Rhône-Alpes
3032,BLOMARD,3390,BLOMARD,,46.2883371774,2.9626756681,32,,Blomard,Blomard,3,Allier,84,Auvergne-Rhône-Alpes
3033,BOST,3300,BOST,,46.1834084633,3.50582300177,33,,Bost,Bost,3,Allier,84,Auvergne-Rhône-Alpes
3034,BOUCE,3150,BOUCE,,46.3203697694,3.49250482471,34,,Boucé,Boucé,3,Allier,84,Auvergne-Rhône-Alpes
3035,LE BOUCHAUD,3130,LE BOUCHAUD,,46.3073055814,3.90705346696,35,Le,Bouchaud,Le Bouchaud,3,Allier,84,Auvergne-Rhône-Alpes
3036,BOURBON L ARCHAMBAULT,3160,BOURBON L ARCHAMBAULT,,46.5836819113,3.05141625983,36,,Bourbon-l'Archambault,Bourbon-l'Archambault,3,Allier,84,Auvergne-Rhône-Alpes
3037,BRAIZE,3360,BRAIZE,,46.658636225,2.65427484498,37,,Braize,Braize,3,Allier,84,Auvergne-Rhône-Alpes
3038,BRANSAT,3500,BRANSAT,,46.3291248847,3.21707091921,38,,Bransat,Bransat,3,Allier,84,Auvergne-Rhône-Alpes
3039,BRESNAY,3210,BRESNAY,,46.4335692397,3.24899330539,39,,Bresnay,Bresnay,3,Allier,84,Auvergne-Rhône-Alpes
3040,BRESSOLLES,3000,BRESSOLLES,,46.5280643075,3.295748661,40,,Bressolles,Bressolles,3,Allier,84,Auvergne-Rhône-Alpes
3041,LE BRETHON,3350,LE BRETHON,,46.5841457281,2.71525662654,41,Le,Brethon,Le Brethon,3,Allier,84,Auvergne-Rhône-Alpes
3042,LE BREUIL,3120,LE BREUIL,,46.1767784559,3.66869091002,42,Le,Breuil,Le Breuil,3,Allier,84,Auvergne-Rhône-Alpes
3043,BROUT VERNET,3110,BROUT VERNET,,46.1961727418,3.28851651471,43,,Broût-Vernet,Broût-Vernet,3,Allier,84,Auvergne-Rhône-Alpes
3044,BRUGHEAS,3700,BRUGHEAS,,46.0728347147,3.3660599245,44,,Brugheas,Brugheas,3,Allier,84,Auvergne-Rhône-Alpes
3045,BUSSET,3270,BUSSET,,46.0568912652,3.51533127883,45,,Busset,Busset,3,Allier,84,Auvergne-Rhône-Alpes
3046,BUXIERES LES MINES,3440,BUXIERES LES MINES,,46.4698341323,2.95493090181,46,,Buxières-les-Mines,Buxières-les-Mines,3,Allier,84,Auvergne-Rhône-Alpes
3047,LA CELLE,3600,LA CELLE,,46.2278844579,2.77894118647,47,La,Celle,La Celle,3,Allier,84,Auvergne-Rhône-Alpes
3048,CERILLY,3350,CERILLY,,46.6265101815,2.80901857226,48,,Cérilly,Cérilly,3,Allier,84,Auvergne-Rhône-Alpes
3049,CESSET,3500,CESSET,,46.2993847548,3.19908944342,49,,Cesset,Cesset,3,Allier,84,Auvergne-Rhône-Alpes
3050,LA CHABANNE,3250,LA CHABANNE,,46.0244474804,3.75155529865,50,La,Chabanne,La Chabanne,3,Allier,84,Auvergne-Rhône-Alpes
3051,CHAMBERAT,3370,CHAMBERAT,,46.4139665556,2.42552132856,51,,Chambérat,Chambérat,3,Allier,84,Auvergne-Rhône-Alpes
3052,CHAMBLET,3170,CHAMBLET,,46.3283879186,2.71331446339,52,,Chamblet,Chamblet,3,Allier,84,Auvergne-Rhône-Alpes
3053,CHANTELLE,3140,CHANTELLE,,46.2365355832,3.16046709261,53,,Chantelle,Chantelle,3,Allier,84,Auvergne-Rhône-Alpes
3054,CHAPEAU,3340,CHAPEAU,,46.4922208672,3.51548779612,54,,Chapeau,Chapeau,3,Allier,84,Auvergne-Rhône-Alpes
3055,LA CHAPELAUDE,3380,LA CHAPELAUDE,,46.4276365849,2.50433585569,55,La,Chapelaude,La Chapelaude,3,Allier,84,Auvergne-Rhône-Alpes
3056,LA CHAPELLE,3300,LA CHAPELLE,,46.0889341496,3.5877712092,56,La,Chapelle,La Chapelle,3,Allier,84,Auvergne-Rhône-Alpes
3057,LA CHAPELLE AUX CHASSES,3230,LA CHAPELLE AUX CHASSES,,46.6598285232,3.53021703178,57,La,Chapelle-aux-Chasses,La Chapelle-aux-Chasses,3,Allier,84,Auvergne-Rhône-Alpes
3058,CHAPPES,3390,CHAPPES,,46.3828961665,2.94412358895,58,,Chappes,Chappes,3,Allier,84,Auvergne-Rhône-Alpes
3059,CHAREIL CINTRAT,3140,CHAREIL CINTRAT,,46.2662583678,3.2297935311,59,,Chareil-Cintrat,Chareil-Cintrat,3,Allier,84,Auvergne-Rhône-Alpes
3060,CHARMEIL,3110,CHARMEIL,,46.1598815241,3.39334670689,60,,Charmeil,Charmeil,3,Allier,84,Auvergne-Rhône-Alpes
3061,CHARMES,3800,CHARMES,,46.0829571549,3.24639555805,61,,Charmes,Charmes,3,Allier,84,Auvergne-Rhône-Alpes
3062,CHARROUX,3140,CHARROUX,,46.1823286094,3.16415034202,62,,Charroux,Charroux,3,Allier,84,Auvergne-Rhône-Alpes
3063,CHASSENARD,3510,CHASSENARD,,46.441587471,3.97329862567,63,,Chassenard,Chassenard,3,Allier,84,Auvergne-Rhône-Alpes
3064,CHATEAU SUR ALLIER,3320,CHATEAU SUR ALLIER,,46.7817092789,2.98716115012,64,,Château-sur-Allier,Château-sur-Allier,3,Allier,84,Auvergne-Rhône-Alpes
3065,CHATEL DE NEUVRE,3500,CHATEL DE NEUVRE,,46.4160449941,3.29822520917,65,,Châtel-de-Neuvre,Châtel-de-Neuvre,3,Allier,84,Auvergne-Rhône-Alpes
3066,CHATEL MONTAGNE,3250,CHATEL MONTAGNE,,46.1000720289,3.71657168388,66,,Châtel-Montagne,Châtel-Montagne,3,Allier,84,Auvergne-Rhône-Alpes
3067,CHATELPERRON,3220,CHATELPERRON,,46.3968658739,3.63313760873,67,,Châtelperron,Châtelperron,3,Allier,84,Auvergne-Rhône-Alpes
3068,CHATELUS,3120,CHATELUS,,46.1980382828,3.72511111092,68,,Châtelus,Châtelus,3,Allier,84,Auvergne-Rhône-Alpes
3069,CHATILLON,3210,CHATILLON,,46.4615377644,3.12807465548,69,,Châtillon,Châtillon,3,Allier,84,Auvergne-Rhône-Alpes
3070,CHAVENON,3440,CHAVENON,,46.4219939323,2.95170741295,70,,Chavenon,Chavenon,3,Allier,84,Auvergne-Rhône-Alpes
3071,CHAVROCHES,3220,CHAVROCHES,,46.353495459,3.59684840219,71,,Chavroches,Chavroches,3,Allier,84,Auvergne-Rhône-Alpes
3072,CHAZEMAIS,3370,CHAZEMAIS,,46.4871767183,2.51910449046,72,,Chazemais,Chazemais,3,Allier,84,Auvergne-Rhône-Alpes
3073,CHEMILLY,3210,CHEMILLY,,46.4783424537,3.31267906143,73,,Chemilly,Chemilly,3,Allier,84,Auvergne-Rhône-Alpes
3074,CHEVAGNES,3230,CHEVAGNES,,46.6082038568,3.56403297131,74,,Chevagnes,Chevagnes,3,Allier,84,Auvergne-Rhône-Alpes
3075,CHEZELLE,3140,CHEZELLE,,46.2129757558,3.11154820906,75,,Chezelle,Chezelle,3,Allier,84,Auvergne-Rhône-Alpes
3076,CHEZY,3230,CHEZY,,46.612475752,3.45934804797,76,,Chézy,Chézy,3,Allier,84,Auvergne-Rhône-Alpes
3077,CHIRAT L EGLISE,3330,CHIRAT L EGLISE,,46.2460009301,3.03251610569,77,,Chirat-l'Église,Chirat-l'Église,3,Allier,84,Auvergne-Rhône-Alpes
3078,CHOUVIGNY,3450,CHOUVIGNY,,46.1257380444,3.00492464249,78,,Chouvigny,Chouvigny,3,Allier,84,Auvergne-Rhône-Alpes
3079,CINDRE,3220,CINDRE,,46.3230930256,3.55110289744,79,,Cindré,Cindré,3,Allier,84,Auvergne-Rhône-Alpes
3080,COGNAT LYONNE,3110,COGNAT LYONNE,,46.1074095478,3.29764762028,80,,Cognat-Lyonne,Cognat-Lyonne,3,Allier,84,Auvergne-Rhône-Alpes
3081,COLOMBIER,3600,COLOMBIER,,46.2669829489,2.7937131637,81,,Colombier,Colombier,3,Allier,84,Auvergne-Rhône-Alpes
3082,COMMENTRY,3600,COMMENTRY,,46.2787466852,2.74916734673,82,,Commentry,Commentry,3,Allier,84,Auvergne-Rhône-Alpes
3083,CONTIGNY,3500,CONTIGNY,,46.3463711102,3.31483781041,83,,Contigny,Contigny,3,Allier,84,Auvergne-Rhône-Alpes
3084,COSNE D ALLIER,3430,COSNE D ALLIER,,46.482962805,2.82323006329,84,,Cosne-d'Allier,Cosne-d'Allier,3,Allier,84,Auvergne-Rhône-Alpes
3085,COULANDON,3000,COULANDON,,46.5546535461,3.24541387326,85,,Coulandon,Coulandon,3,Allier,84,Auvergne-Rhône-Alpes
3086,COULANGES,3470,COULANGES,,46.4751096526,3.86330653833,86,,Coulanges,Coulanges,3,Allier,84,Auvergne-Rhône-Alpes
3087,COULEUVRE,3320,COULEUVRE,,46.6740683179,2.89685534446,87,,Couleuvre,Couleuvre,3,Allier,84,Auvergne-Rhône-Alpes
3088,COURCAIS,3370,COURCAIS,,46.463962183,2.44909172775,88,,Courçais,Courçais,3,Allier,84,Auvergne-Rhône-Alpes
3089,COUTANSOUZE,3330,COUTANSOUZE,,46.205276583,3.00755651899,89,,Coutansouze,Coutansouze,3,Allier,84,Auvergne-Rhône-Alpes
3090,COUZON,3160,COUZON,,46.6443750911,3.11347416578,90,,Couzon,Couzon,3,Allier,84,Auvergne-Rhône-Alpes
3091,CRECHY,3150,CRECHY,,46.2696885601,3.42387984399,91,,Créchy,Créchy,3,Allier,84,Auvergne-Rhône-Alpes
3092,CRESSANGES,3240,CRESSANGES,,46.4443092739,3.17072489552,92,,Cressanges,Cressanges,3,Allier,84,Auvergne-Rhône-Alpes
3093,CREUZIER LE NEUF,3300,CREUZIER LE NEUF,,46.1781234697,3.46274147991,93,,Creuzier-le-Neuf,Creuzier-le-Neuf,3,Allier,84,Auvergne-Rhône-Alpes
3094,CREUZIER LE VIEUX,3300,CREUZIER LE VIEUX,,46.1605988745,3.43435089726,94,,Creuzier-le-Vieux,Creuzier-le-Vieux,3,Allier,84,Auvergne-Rhône-Alpes
3095,CUSSET,3300,CUSSET,,46.1377738304,3.48296708451,95,,Cusset,Cusset,3,Allier,84,Auvergne-Rhône-Alpes
3096,DENEUILLE LES CHANTELLE,3140,DENEUILLE LES CHANTELLE,,46.252932184,3.1458559831,96,,Deneuille-lès-Chantelle,Deneuille-lès-Chantelle,3,Allier,84,Auvergne-Rhône-Alpes
3097,DENEUILLE LES MINES,3170,DENEUILLE LES MINES,,46.3782924502,2.77557506066,97,,Deneuille-les-Mines,Deneuille-les-Mines,3,Allier,84,Auvergne-Rhône-Alpes
3098,DESERTINES,3630,DESERTINES,,46.3569236858,2.62735601895,98,,Désertines,Désertines,3,Allier,84,Auvergne-Rhône-Alpes
3099,DEUX CHAISES,3240,DEUX CHAISES,,46.3749069193,3.04059198306,99,,Deux-Chaises,Deux-Chaises,3,Allier,84,Auvergne-Rhône-Alpes
3100,DIOU,3290,DIOU,,46.5226236257,3.7367345302,100,,Diou,Diou,3,Allier,84,Auvergne-Rhône-Alpes
3101,DOMERAT,3410,DOMERAT,,46.3681510176,2.54346219645,101,,Domérat,Domérat,3,Allier,84,Auvergne-Rhône-Alpes
3102,DOMPIERRE SUR BESBRE,3290,DOMPIERRE SUR BESBRE,,46.5305895294,3.67644076991,102,,Dompierre-sur-Besbre,Dompierre-sur-Besbre,3,Allier,84,Auvergne-Rhône-Alpes
3103,LE DONJON,3130,LE DONJON,,46.3449718057,3.7999456795,103,Le,Donjon,Le Donjon,3,Allier,84,Auvergne-Rhône-Alpes
3104,DOYET,3170,DOYET,,46.3433946285,2.79161075003,104,,Doyet,Doyet,3,Allier,84,Auvergne-Rhône-Alpes
3105,DROITURIER,3120,DROITURIER,,46.2267468451,3.70488868966,105,,Droiturier,Droiturier,3,Allier,84,Auvergne-Rhône-Alpes
3106,DURDAT LAREQUILLE,3310,DURDAT LAREQUILLE,,46.2540040648,2.69686439225,106,,Durdat-Larequille,Durdat-Larequille,3,Allier,84,Auvergne-Rhône-Alpes
3107,EBREUIL,3450,EBREUIL,,46.1176543632,3.07363121151,107,,Ébreuil,Ébreuil,3,Allier,84,Auvergne-Rhône-Alpes
3108,ECHASSIERES,3330,ECHASSIERES,,46.1956641038,2.94596916837,108,,Échassières,Échassières,3,Allier,84,Auvergne-Rhône-Alpes
3109,ESCUROLLES,3110,ESCUROLLES,,46.1418285128,3.26795917971,109,,Escurolles,Escurolles,3,Allier,84,Auvergne-Rhône-Alpes
3110,ESPINASSE VOZELLE,3110,ESPINASSE VOZELLE,,46.1292798016,3.33501762138,110,,Espinasse-Vozelle,Espinasse-Vozelle,3,Allier,84,Auvergne-Rhône-Alpes
3111,ESTIVAREILLES,3190,ESTIVAREILLES,,46.4250969781,2.62477435556,111,,Estivareilles,Estivareilles,3,Allier,84,Auvergne-Rhône-Alpes
3112,ETROUSSAT,3140,ETROUSSAT,,46.2202976466,3.22102195142,112,,Étroussat,Étroussat,3,Allier,84,Auvergne-Rhône-Alpes
3113,FERRIERES SUR SICHON,3250,FERRIERES SUR SICHON,,46.0200050808,3.64850064576,113,,Ferrières-sur-Sichon,Ferrières-sur-Sichon,3,Allier,84,Auvergne-Rhône-Alpes
3114,LA FERTE HAUTERIVE,3340,LA FERTE HAUTERIVE,,46.396159353,3.3355449517,114,La,Ferté-Hauterive,La Ferté-Hauterive,3,Allier,84,Auvergne-Rhône-Alpes
3115,FLEURIEL,3140,FLEURIEL,,46.2870292399,3.16169451004,115,,Fleuriel,Fleuriel,3,Allier,84,Auvergne-Rhône-Alpes
3116,FOURILLES,3140,FOURILLES,,46.2438583922,3.19784046802,116,,Fourilles,Fourilles,3,Allier,84,Auvergne-Rhône-Alpes
3117,FRANCHESSE,3160,FRANCHESSE,,46.6436566411,3.03662004358,117,,Franchesse,Franchesse,3,Allier,84,Auvergne-Rhône-Alpes
3118,GANNAT,3800,GANNAT,,46.0984192073,3.17790823629,118,,Gannat,Gannat,3,Allier,84,Auvergne-Rhône-Alpes
3119,GANNAY SUR LOIRE,3230,GANNAY SUR LOIRE,,46.7179070524,3.59634650725,119,,Gannay-sur-Loire,Gannay-sur-Loire,3,Allier,84,Auvergne-Rhône-Alpes
3120,GARNAT SUR ENGIEVRE,3230,GARNAT SUR ENGIEVRE,,46.6348166525,3.66797467464,120,,Garnat-sur-Engièvre,Garnat-sur-Engièvre,3,Allier,84,Auvergne-Rhône-Alpes
3121,GENNETINES,3400,GENNETINES,,46.6296398798,3.40498318638,121,,Gennetines,Gennetines,3,Allier,84,Auvergne-Rhône-Alpes
3122,GIPCY,3210,GIPCY,,46.5110673404,3.05138939193,122,,Gipcy,Gipcy,3,Allier,84,Auvergne-Rhône-Alpes
3124,GOUISE,3340,GOUISE,,46.4107128153,3.43846428318,124,,Gouise,Gouise,3,Allier,84,Auvergne-Rhône-Alpes
3125,LA GUILLERMIE,3250,LA GUILLERMIE,,45.9813228114,3.64529174052,125,La,Guillermie,La Guillermie,3,Allier,84,Auvergne-Rhône-Alpes
3126,HAUTERIVE,3270,HAUTERIVE,,46.0773045238,3.43868453629,126,,Hauterive,Hauterive,3,Allier,84,Auvergne-Rhône-Alpes
3127,HERISSON,3190,HERISSON,,46.5199077449,2.71052889853,127,,Hérisson,Hérisson,3,Allier,84,Auvergne-Rhône-Alpes
3128,HURIEL,3380,HURIEL,,46.3770986154,2.48198364823,128,,Huriel,Huriel,3,Allier,84,Auvergne-Rhône-Alpes
3129,HYDS,3600,HYDS,,46.273205648,2.8280145759,129,,Hyds,Hyds,3,Allier,84,Auvergne-Rhône-Alpes
3130,ISLE ET BARDAIS,3360,ISLE ET BARDAIS,,46.6820455059,2.77701869373,130,,Isle-et-Bardais,Isle-et-Bardais,3,Allier,84,Auvergne-Rhône-Alpes
3131,ISSERPENT,3120,ISSERPENT,,46.1542955213,3.61825876933,131,,Isserpent,Isserpent,3,Allier,84,Auvergne-Rhône-Alpes
3132,JALIGNY SUR BESBRE,3220,JALIGNY SUR BESBRE,,46.3770497759,3.59029838546,132,,Jaligny-sur-Besbre,Jaligny-sur-Besbre,3,Allier,84,Auvergne-Rhône-Alpes
3133,JENZAT,3800,JENZAT,,46.1601157879,3.19738809001,133,,Jenzat,Jenzat,3,Allier,84,Auvergne-Rhône-Alpes
3134,LAFELINE,3500,LAFELINE,,46.3446298238,3.17548675407,134,,Laféline,Laféline,3,Allier,84,Auvergne-Rhône-Alpes
3135,LALIZOLLE,3450,LALIZOLLE,,46.1646303512,3.00825559496,135,,Lalizolle,Lalizolle,3,Allier,84,Auvergne-Rhône-Alpes
3136,LAMAIDS,3380,LAMAIDS,,46.3040091673,2.43816762566,136,,Lamaids,Lamaids,3,Allier,84,Auvergne-Rhône-Alpes
3137,LANGY,3150,LANGY,,46.2728774328,3.46181153336,137,,Langy,Langy,3,Allier,84,Auvergne-Rhône-Alpes
3138,LAPALISSE,3120,LAPALISSE,,46.264846808,3.63838425184,138,,Lapalisse,Lapalisse,3,Allier,84,Auvergne-Rhône-Alpes
3139,LAPRUGNE,3250,LAPRUGNE,,45.9937136705,3.76308018051,139,,Laprugne,Laprugne,3,Allier,84,Auvergne-Rhône-Alpes
3140,LAVAULT STE ANNE,3100,LAVAULT STE ANNE,,46.3086016059,2.60160340266,140,,Lavault-Sainte-Anne,Lavault-Sainte-Anne,3,Allier,84,Auvergne-Rhône-Alpes
3141,LAVOINE,3250,LAVOINE,,45.9741706627,3.68733506061,141,,Lavoine,Lavoine,3,Allier,84,Auvergne-Rhône-Alpes
3142,LENAX,3130,LENAX,,46.3039709752,3.83067027438,142,,Lenax,Lenax,3,Allier,84,Auvergne-Rhône-Alpes
3143,LETELON,3360,LETELON,,46.6563542672,2.59495618457,143,,Lételon,Lételon,3,Allier,84,Auvergne-Rhône-Alpes
3144,LIERNOLLES,3130,LIERNOLLES,,46.3986289465,3.76799783799,144,,Liernolles,Liernolles,3,Allier,84,Auvergne-Rhône-Alpes
3145,LIGNEROLLES,3410,LIGNEROLLES,,46.2822531749,2.56690530803,145,,Lignerolles,Lignerolles,3,Allier,84,Auvergne-Rhône-Alpes
3146,LIMOISE,3320,LIMOISE,,46.6841966516,3.05488887781,146,,Limoise,Limoise,3,Allier,84,Auvergne-Rhône-Alpes
3147,LODDES,3130,LODDES,,46.2946168116,3.76081551954,147,,Loddes,Loddes,3,Allier,84,Auvergne-Rhône-Alpes
3148,LORIGES,3500,LORIGES,,46.2659177615,3.34188086413,148,,Loriges,Loriges,3,Allier,84,Auvergne-Rhône-Alpes
3149,LOUCHY MONTFAND,3500,LOUCHY MONTFAND,,46.3075829687,3.24491928356,149,,Louchy-Montfand,Louchy-Montfand,3,Allier,84,Auvergne-Rhône-Alpes
3150,LOUROUX BOURBONNAIS,3350,LOUROUX BOURBONNAIS,,46.5340787528,2.8385482758,150,,Louroux-Bourbonnais,Louroux-Bourbonnais,3,Allier,84,Auvergne-Rhône-Alpes
3151,LOUROUX DE BEAUNE,3600,LOUROUX DE BEAUNE,,46.2996042062,2.85942062102,151,,Louroux-de-Beaune,Louroux-de-Beaune,3,Allier,84,Auvergne-Rhône-Alpes
3152,LOUROUX DE BOUBLE,3330,LOUROUX DE BOUBLE,,46.2297197928,2.97309854454,152,,Louroux-de-Bouble,Louroux-de-Bouble,3,Allier,84,Auvergne-Rhône-Alpes
3154,LUNEAU,3130,LUNEAU,,46.3662876048,3.95278636616,154,,Luneau,Luneau,3,Allier,84,Auvergne-Rhône-Alpes
3155,LURCY LEVIS,3320,LURCY LEVIS,,46.7350922642,2.91800325447,155,,Lurcy-Lévis,Lurcy-Lévis,3,Allier,84,Auvergne-Rhône-Alpes
3156,LUSIGNY,3230,LUSIGNY,,46.5717045071,3.48949898102,156,,Lusigny,Lusigny,3,Allier,84,Auvergne-Rhône-Alpes
3157,MAGNET,3260,MAGNET,,46.2172906901,3.5106036727,157,,Magnet,Magnet,3,Allier,84,Auvergne-Rhône-Alpes
3158,HAUT BOCAGE,3190,HAUT BOCAGE,GIVARLAIS,46.4928636302,2.65852361334,158,,Haut-Bocage,Haut-Bocage,3,Allier,84,Auvergne-Rhône-Alpes
3158,HAUT BOCAGE,3190,HAUT BOCAGE,LOUROUX HODEMENT,46.4928636302,2.65852361334,158,,Haut-Bocage,Haut-Bocage,3,Allier,84,Auvergne-Rhône-Alpes
3158,HAUT BOCAGE,3190,HAUT BOCAGE,MAILLET,46.4928636302,2.65852361334,158,,Haut-Bocage,Haut-Bocage,3,Allier,84,Auvergne-Rhône-Alpes
3159,MALICORNE,3600,MALICORNE,,46.3072678602,2.77027684739,159,,Malicorne,Malicorne,3,Allier,84,Auvergne-Rhône-Alpes
3160,MARCENAT,3260,MARCENAT,,46.237174267,3.39703242692,160,,Marcenat,Marcenat,3,Allier,84,Auvergne-Rhône-Alpes
3161,MARCILLAT EN COMBRAILLE,3420,MARCILLAT EN COMBRAILLE,,46.1701624404,2.63441118376,161,,Marcillat-en-Combraille,Marcillat-en-Combraille,3,Allier,84,Auvergne-Rhône-Alpes
3162,MARIGNY,3210,MARIGNY,,46.579557675,3.20989648546,162,,Marigny,Marigny,3,Allier,84,Auvergne-Rhône-Alpes
3163,MARIOL,3270,MARIOL,,46.0252722149,3.48652009889,163,,Mariol,Mariol,3,Allier,84,Auvergne-Rhône-Alpes
3164,LE MAYET D ECOLE,3800,LE MAYET D ECOLE,,46.1649554035,3.24084567902,164,Le,Mayet-d'École,Le Mayet-d'École,3,Allier,84,Auvergne-Rhône-Alpes
3165,LE MAYET DE MONTAGNE,3250,LE MAYET DE MONTAGNE,,46.0602181682,3.65546570851,165,Le,Mayet-de-Montagne,Le Mayet-de-Montagne,3,Allier,84,Auvergne-Rhône-Alpes
3166,MAZERIER,3800,MAZERIER,,46.1325144393,3.18170482903,166,,Mazerier,Mazerier,3,Allier,84,Auvergne-Rhône-Alpes
3167,MAZIRAT,3420,MAZIRAT,,46.2185045574,2.55398563,167,,Mazirat,Mazirat,3,Allier,84,Auvergne-Rhône-Alpes
3168,MEAULNE,3360,MEAULNE,,46.5959256579,2.62022117679,168,,Meaulne-Vitray,Meaulne-Vitray,3,Allier,84,Auvergne-Rhône-Alpes
3169,MEILLARD,3500,MEILLARD,,46.3881490513,3.23168900799,169,,Meillard,Meillard,3,Allier,84,Auvergne-Rhône-Alpes
3170,MEILLERS,3210,MEILLERS,,46.5132615599,3.10267194059,170,,Meillers,Meillers,3,Allier,84,Auvergne-Rhône-Alpes
3171,MERCY,3340,MERCY,,46.4511354301,3.52416501976,171,,Mercy,Mercy,3,Allier,84,Auvergne-Rhône-Alpes
3172,MESPLES,3370,MESPLES,,46.4272621161,2.36441867083,172,,Mesples,Mesples,3,Allier,84,Auvergne-Rhône-Alpes
3173,MOLINET,3510,MOLINET,,46.464220041,3.9262713854,173,,Molinet,Molinet,3,Allier,84,Auvergne-Rhône-Alpes
3174,MOLLES,3300,MOLLES,,46.1150413617,3.55648495184,174,,Molles,Molles,3,Allier,84,Auvergne-Rhône-Alpes
3175,MONESTIER,3140,MONESTIER,,46.2628525623,3.10440007037,175,,Monestier,Monestier,3,Allier,84,Auvergne-Rhône-Alpes
3176,MONETAY SUR ALLIER,3500,MONETAY SUR ALLIER,,46.3810175167,3.28932554471,176,,Monétay-sur-Allier,Monétay-sur-Allier,3,Allier,84,Auvergne-Rhône-Alpes
3177,MONETAY SUR LOIRE,3470,MONETAY SUR LOIRE,,46.4350468294,3.82462613047,177,,Monétay-sur-Loire,Monétay-sur-Loire,3,Allier,84,Auvergne-Rhône-Alpes
3178,MONTAIGUET EN FOREZ,3130,MONTAIGUET EN FOREZ,,46.267429173,3.8221356053,178,,Montaiguët-en-Forez,Montaiguët-en-Forez,3,Allier,84,Auvergne-Rhône-Alpes
3179,MONTAIGU LE BLIN,3150,MONTAIGU LE BLIN,,46.2907437255,3.51623839378,179,,Montaigu-le-Blin,Montaigu-le-Blin,3,Allier,84,Auvergne-Rhône-Alpes
3180,MONTBEUGNY,3340,MONTBEUGNY,,46.5282061849,3.47422921423,180,,Montbeugny,Montbeugny,3,Allier,84,Auvergne-Rhône-Alpes
3181,MONTCOMBROUX LES MINES,3130,MONTCOMBROUX LES MINES,,46.3606934499,3.72808957144,181,,Montcombroux-les-Mines,Montcombroux-les-Mines,3,Allier,84,Auvergne-Rhône-Alpes
3182,MONTEIGNET SUR L ANDELOT,3800,MONTEIGNET SUR L ANDELOT,,46.1150007655,3.24811283769,182,,Monteignet-sur-l'Andelot,Monteignet-sur-l'Andelot,3,Allier,84,Auvergne-Rhône-Alpes
3183,LE MONTET,3240,LE MONTET,,46.4062028922,3.0541000253,183,Le,Montet,Le Montet,3,Allier,84,Auvergne-Rhône-Alpes
3184,MONTILLY,3000,MONTILLY,,46.610528559,3.24005745558,184,,Montilly,Montilly,3,Allier,84,Auvergne-Rhône-Alpes
3185,MONTLUCON,3100,MONTLUCON,,46.3385883496,2.60390499777,185,,Montluçon,Montluçon,3,Allier,84,Auvergne-Rhône-Alpes
3186,MONTMARAULT,3390,MONTMARAULT,,46.3201872756,2.95237432039,186,,Montmarault,Montmarault,3,Allier,84,Auvergne-Rhône-Alpes
3187,MONTOLDRE,3150,MONTOLDRE,,46.3375770064,3.45102443628,187,,Montoldre,Montoldre,3,Allier,84,Auvergne-Rhône-Alpes
3188,MONTORD,3500,MONTORD,,46.2897924844,3.23962321645,188,,Montord,Montord,3,Allier,84,Auvergne-Rhône-Alpes
3189,MONTVICQ,3170,MONTVICQ,,46.315678912,2.82223124184,189,,Montvicq,Montvicq,3,Allier,84,Auvergne-Rhône-Alpes
3190,MOULINS,3000,MOULINS,,46.5624641056,3.32662040221,190,,Moulins,Moulins,3,Allier,84,Auvergne-Rhône-Alpes
3191,MURAT,3390,MURAT,,46.410976289,2.90417805262,191,,Murat,Murat,3,Allier,84,Auvergne-Rhône-Alpes
3192,NADES,3450,NADES,,46.1542506646,2.96861834904,192,,Nades,Nades,3,Allier,84,Auvergne-Rhône-Alpes
3193,NASSIGNY,3190,NASSIGNY,,46.4978434905,2.59440652102,193,,Nassigny,Nassigny,3,Allier,84,Auvergne-Rhône-Alpes
3194,NAVES,3330,NAVES,,46.1760532799,3.11644371952,194,,Naves,Naves,3,Allier,84,Auvergne-Rhône-Alpes
3195,NERIS LES BAINS,3310,NERIS LES BAINS,,46.2994420207,2.67574785449,195,,Néris-les-Bains,Néris-les-Bains,3,Allier,84,Auvergne-Rhône-Alpes
3196,NEUILLY EN DONJON,3130,NEUILLY EN DONJON,,46.3427363495,3.88528963495,196,,Neuilly-en-Donjon,Neuilly-en-Donjon,3,Allier,84,Auvergne-Rhône-Alpes
3197,NEUILLY LE REAL,3340,NEUILLY LE REAL,,46.466710676,3.43721508538,197,,Neuilly-le-Réal,Neuilly-le-Réal,3,Allier,84,Auvergne-Rhône-Alpes
3198,NEURE,3320,NEURE,,46.7448146276,2.97978880995,198,,Neure,Neure,3,Allier,84,Auvergne-Rhône-Alpes
3200,NEUVY,3000,NEUVY,,46.5712518334,3.28275338591,200,,Neuvy,Neuvy,3,Allier,84,Auvergne-Rhône-Alpes
3201,NIZEROLLES,3250,NIZEROLLES,,46.1072966632,3.64408039597,201,,Nizerolles,Nizerolles,3,Allier,84,Auvergne-Rhône-Alpes
3202,NOYANT D ALLIER,3210,NOYANT D ALLIER,,46.4796760503,3.11878325467,202,,Noyant-d'Allier,Noyant-d'Allier,3,Allier,84,Auvergne-Rhône-Alpes
3203,PARAY LE FRESIL,3230,PARAY LE FRESIL,,46.6598970323,3.60383070741,203,,Paray-le-Frésil,Paray-le-Frésil,3,Allier,84,Auvergne-Rhône-Alpes
3204,PARAY SOUS BRIAILLES,3500,PARAY SOUS BRIAILLES,,46.2903733952,3.36980984118,204,,Paray-sous-Briailles,Paray-sous-Briailles,3,Allier,84,Auvergne-Rhône-Alpes
3205,PERIGNY,3120,PERIGNY,,46.2466695762,3.56039743926,205,,Périgny,Périgny,3,Allier,84,Auvergne-Rhône-Alpes
3206,LA PETITE MARCHE,3420,LA PETITE MARCHE,,46.1898756857,2.56142616231,206,La,Petite-Marche,La Petite-Marche,3,Allier,84,Auvergne-Rhône-Alpes
3207,PIERREFITTE SUR LOIRE,3470,PIERREFITTE SUR LOIRE,,46.5040402992,3.81007064731,207,,Pierrefitte-sur-Loire,Pierrefitte-sur-Loire,3,Allier,84,Auvergne-Rhône-Alpes
3208,LE PIN,3130,LE PIN,,46.4222179747,3.88958660139,208,Le,Pin,Le Pin,3,Allier,84,Auvergne-Rhône-Alpes
3209,POEZAT,3800,POEZAT,,46.0755325422,3.22701379423,209,,Poëzat,Poëzat,3,Allier,84,Auvergne-Rhône-Alpes
3210,POUZY MESANGY,3320,POUZY MESANGY,,46.7049856575,3.00718039936,210,,Pouzy-Mésangy,Pouzy-Mésangy,3,Allier,84,Auvergne-Rhône-Alpes
3211,PREMILHAT,3410,PREMILHAT,,46.3099309772,2.54719729208,211,,Prémilhat,Prémilhat,3,Allier,84,Auvergne-Rhône-Alpes
3212,QUINSSAINES,3380,QUINSSAINES,,46.3092023874,2.49444625529,212,,Quinssaines,Quinssaines,3,Allier,84,Auvergne-Rhône-Alpes
3213,REUGNY,3190,REUGNY,,46.458404964,2.61328894387,213,,Reugny,Reugny,3,Allier,84,Auvergne-Rhône-Alpes
3214,ROCLES,3240,ROCLES,,46.4445146062,3.03677199137,214,,Rocles,Rocles,3,Allier,84,Auvergne-Rhône-Alpes
3215,RONGERES,3150,RONGERES,,46.2976179231,3.4520098944,215,,Rongères,Rongères,3,Allier,84,Auvergne-Rhône-Alpes
3216,RONNET,3420,RONNET,,46.2015478227,2.70087446856,216,,Ronnet,Ronnet,3,Allier,84,Auvergne-Rhône-Alpes
3217,ST ANGEL,3170,ST ANGEL,,46.3558498078,2.68461176543,217,,Saint-Angel,Saint-Angel,3,Allier,84,Auvergne-Rhône-Alpes
3218,ST AUBIN LE MONIAL,3160,ST AUBIN LE MONIAL,,46.535765709,3.00144398409,218,,Saint-Aubin-le-Monial,Saint-Aubin-le-Monial,3,Allier,84,Auvergne-Rhône-Alpes
3219,ST BONNET DE FOUR,3390,ST BONNET DE FOUR,,46.314979417,2.90455342628,219,,Saint-Bonnet-de-Four,Saint-Bonnet-de-Four,3,Allier,84,Auvergne-Rhône-Alpes
3220,ST BONNET DE ROCHEFORT,3800,ST BONNET DE ROCHEFORT,,46.1505309834,3.14079379444,220,,Saint-Bonnet-de-Rochefort,Saint-Bonnet-de-Rochefort,3,Allier,84,Auvergne-Rhône-Alpes
3221,ST BONNET TRONCAIS,3360,ST BONNET TRONCAIS,,46.6697641721,2.69613341169,221,,Saint-Bonnet-Tronçais,Saint-Bonnet-Tronçais,3,Allier,84,Auvergne-Rhône-Alpes
3222,ST CAPRAIS,3190,ST CAPRAIS,,46.5449333532,2.73980095211,222,,Saint-Caprais,Saint-Caprais,3,Allier,84,Auvergne-Rhône-Alpes
3223,ST CHRISTOPHE,3120,ST CHRISTOPHE,,46.1663162062,3.57800809692,223,,Saint-Christophe,Saint-Christophe,3,Allier,84,Auvergne-Rhône-Alpes
3224,ST CLEMENT,3250,ST CLEMENT,,46.0577534847,3.7219179861,224,,Saint-Clément,Saint-Clément,3,Allier,84,Auvergne-Rhône-Alpes
3225,ST DESIRE,3370,ST DESIRE,,46.5074397829,2.44986211954,225,,Saint-Désiré,Saint-Désiré,3,Allier,84,Auvergne-Rhône-Alpes
3226,ST DIDIER EN DONJON,3130,ST DIDIER EN DONJON,,46.3830807017,3.86098077845,226,,Saint-Didier-en-Donjon,Saint-Didier-en-Donjon,3,Allier,84,Auvergne-Rhône-Alpes
3227,ST DIDIER LA FORET,3110,ST DIDIER LA FORET,,46.2294445001,3.33625437059,227,,Saint-Didier-la-Forêt,Saint-Didier-la-Forêt,3,Allier,84,Auvergne-Rhône-Alpes
3228,ST ELOY D ALLIER,3370,ST ELOY D ALLIER,,46.4967909945,2.36296544008,228,,Saint-Éloy-d'Allier,Saint-Éloy-d'Allier,3,Allier,84,Auvergne-Rhône-Alpes
3229,ST ENNEMOND,3400,ST ENNEMOND,,46.6808991637,3.40446807688,229,,Saint-Ennemond,Saint-Ennemond,3,Allier,84,Auvergne-Rhône-Alpes
3230,ST ETIENNE DE VICQ,3300,ST ETIENNE DE VICQ,,46.1731804107,3.53616252147,230,,Saint-Étienne-de-Vicq,Saint-Étienne-de-Vicq,3,Allier,84,Auvergne-Rhône-Alpes
3231,ST FARGEOL,3420,ST FARGEOL,,46.1343424286,2.63071732014,231,,Saint-Fargeol,Saint-Fargeol,3,Allier,84,Auvergne-Rhône-Alpes
3232,ST FELIX,3260,ST FELIX,,46.229193409,3.47524603973,232,,Saint-Félix,Saint-Félix,3,Allier,84,Auvergne-Rhône-Alpes
3233,ST GENEST,3310,ST GENEST,,46.2634149323,2.60320533651,233,,Saint-Genest,Saint-Genest,3,Allier,84,Auvergne-Rhône-Alpes
3234,ST GERAND DE VAUX,3340,ST GERAND DE VAUX,,46.3838249809,3.40743256191,234,,Saint-Gérand-de-Vaux,Saint-Gérand-de-Vaux,3,Allier,84,Auvergne-Rhône-Alpes
3235,ST GERAND LE PUY,3150,ST GERAND LE PUY,,46.2637640044,3.51175451596,235,,Saint-Gérand-le-Puy,Saint-Gérand-le-Puy,3,Allier,84,Auvergne-Rhône-Alpes
3236,ST GERMAIN DES FOSSES,3260,ST GERMAIN DES FOSSES,,46.1959477042,3.42997700831,236,,Saint-Germain-des-Fossés,Saint-Germain-des-Fossés,3,Allier,84,Auvergne-Rhône-Alpes
3237,ST GERMAIN DE SALLES,3140,ST GERMAIN DE SALLES,,46.1871001813,3.22053975584,237,,Saint-Germain-de-Salles,Saint-Germain-de-Salles,3,Allier,84,Auvergne-Rhône-Alpes
3238,ST HILAIRE,3440,ST HILAIRE,,46.4866887035,3.01543389624,238,,Saint-Hilaire,Saint-Hilaire,3,Allier,84,Auvergne-Rhône-Alpes
3239,ST LEGER SUR VOUZANCE,3130,ST LEGER SUR VOUZANCE,,46.4092021906,3.93725507717,239,,Saint-Léger-sur-Vouzance,Saint-Léger-sur-Vouzance,3,Allier,84,Auvergne-Rhône-Alpes
3240,ST LEON,3220,ST LEON,,46.410638894,3.68775721106,240,,Saint-Léon,Saint-Léon,3,Allier,84,Auvergne-Rhône-Alpes
3241,ST LEOPARDIN D AUGY,3160,ST LEOPARDIN D AUGY,,46.6979502806,3.10551355591,241,,Saint-Léopardin-d'Augy,Saint-Léopardin-d'Augy,3,Allier,84,Auvergne-Rhône-Alpes
3242,ST LOUP,3150,ST LOUP,,46.3549641882,3.37601860081,242,,Saint-Loup,Saint-Loup,3,Allier,84,Auvergne-Rhône-Alpes
3243,ST MARCEL EN MURAT,3390,ST MARCEL EN MURAT,,46.3216708939,3.00484402977,243,,Saint-Marcel-en-Murat,Saint-Marcel-en-Murat,3,Allier,84,Auvergne-Rhône-Alpes
3244,ST MARCEL EN MARCILLAT,3420,ST MARCEL EN MARCILLAT,,46.1532804539,2.58075381141,244,,Saint-Marcel-en-Marcillat,Saint-Marcel-en-Marcillat,3,Allier,84,Auvergne-Rhône-Alpes
3245,ST MARTIN DES LAIS,3230,ST MARTIN DES LAIS,,46.6742609191,3.65661845694,245,,Saint-Martin-des-Lais,Saint-Martin-des-Lais,3,Allier,84,Auvergne-Rhône-Alpes
3246,ST MARTINIEN,3380,ST MARTINIEN,,46.3329189292,2.44287081295,246,,Saint-Martinien,Saint-Martinien,3,Allier,84,Auvergne-Rhône-Alpes
3247,ST MENOUX,3210,ST MENOUX,,46.5823923172,3.14494082801,247,,Saint-Menoux,Saint-Menoux,3,Allier,84,Auvergne-Rhône-Alpes
3248,ST NICOLAS DES BIEFS,3250,ST NICOLAS DES BIEFS,,46.0632245634,3.79460612214,248,,Saint-Nicolas-des-Biefs,Saint-Nicolas-des-Biefs,3,Allier,84,Auvergne-Rhône-Alpes
3249,ST PALAIS,3370,ST PALAIS,,46.4403223251,2.30568571701,249,,Saint-Palais,Saint-Palais,3,Allier,84,Auvergne-Rhône-Alpes
3250,ST PIERRE LAVAL,42620,ST PIERRE LAVAL,,46.1954561376,3.77027031369,250,,Saint-Pierre-Laval,Saint-Pierre-Laval,3,Allier,84,Auvergne-Rhône-Alpes
3251,ST PLAISIR,3160,ST PLAISIR,,46.6243818392,2.95587034653,251,,Saint-Plaisir,Saint-Plaisir,3,Allier,84,Auvergne-Rhône-Alpes
3252,ST PONT,3110,ST PONT,,46.1588138012,3.29855532517,252,,Saint-Pont,Saint-Pont,3,Allier,84,Auvergne-Rhône-Alpes
3253,ST POURCAIN SUR BESBRE,3290,ST POURCAIN SUR BESBRE,,46.4817772858,3.64933142149,253,,Saint-Pourçain-sur-Besbre,Saint-Pourçain-sur-Besbre,3,Allier,84,Auvergne-Rhône-Alpes
3254,ST POURCAIN SUR SIOULE,3500,ST POURCAIN SUR SIOULE,,46.3045094327,3.30537827054,254,,Saint-Pourçain-sur-Sioule,Saint-Pourçain-sur-Sioule,3,Allier,84,Auvergne-Rhône-Alpes
3255,ST PRIEST D ANDELOT,3800,ST PRIEST D ANDELOT,,46.0765718864,3.15433929793,255,,Saint-Priest-d'Andelot,Saint-Priest-d'Andelot,3,Allier,84,Auvergne-Rhône-Alpes
3256,ST PRIEST EN MURAT,3390,ST PRIEST EN MURAT,,46.3556892534,2.90261837567,256,,Saint-Priest-en-Murat,Saint-Priest-en-Murat,3,Allier,84,Auvergne-Rhône-Alpes
3257,ST PRIX,3120,ST PRIX,,46.2252273378,3.64894031518,257,,Saint-Prix,Saint-Prix,3,Allier,84,Auvergne-Rhône-Alpes
3258,ST REMY EN ROLLAT,3110,ST REMY EN ROLLAT,,46.1924635321,3.38335990994,258,,Saint-Rémy-en-Rollat,Saint-Rémy-en-Rollat,3,Allier,84,Auvergne-Rhône-Alpes
3259,ST SAUVIER,3370,ST SAUVIER,,46.396594391,2.33154584912,259,,Saint-Sauvier,Saint-Sauvier,3,Allier,84,Auvergne-Rhône-Alpes
3260,ST SORNIN,3240,ST SORNIN,,46.4116980303,3.00356131793,260,,Saint-Sornin,Saint-Sornin,3,Allier,84,Auvergne-Rhône-Alpes
3261,STE THERENCE,3420,STE THERENCE,,46.2433906142,2.57271161868,261,,Sainte-Thérence,Sainte-Thérence,3,Allier,84,Auvergne-Rhône-Alpes
3262,ST VICTOR,3410,ST VICTOR,,46.3882767935,2.60941319336,262,,Saint-Victor,Saint-Victor,3,Allier,84,Auvergne-Rhône-Alpes
3263,ST VOIR,3220,ST VOIR,,46.410067,3.5060612523,263,,Saint-Voir,Saint-Voir,3,Allier,84,Auvergne-Rhône-Alpes
3264,ST YORRE,3270,ST YORRE,,46.0642575713,3.46749665689,264,,Saint-Yorre,Saint-Yorre,3,Allier,84,Auvergne-Rhône-Alpes
3265,SALIGNY SUR ROUDON,3470,SALIGNY SUR ROUDON,,46.4629712549,3.75125723141,265,,Saligny-sur-Roudon,Saligny-sur-Roudon,3,Allier,84,Auvergne-Rhône-Alpes
3266,SANSSAT,3150,SANSSAT,,46.2504085675,3.46770686659,266,,Sanssat,Sanssat,3,Allier,84,Auvergne-Rhône-Alpes
3267,SAULCET,3500,SAULCET,,46.3294310458,3.26694921793,267,,Saulcet,Saulcet,3,Allier,84,Auvergne-Rhône-Alpes
3268,SAULZET,3800,SAULZET,,46.1377242918,3.22134877309,268,,Saulzet,Saulzet,3,Allier,84,Auvergne-Rhône-Alpes
3269,SAUVAGNY,3430,SAUVAGNY,,46.4345448666,2.79955608965,269,,Sauvagny,Sauvagny,3,Allier,84,Auvergne-Rhône-Alpes
3270,SAZERET,3390,SAZERET,,46.3468702581,2.96976112683,270,,Sazeret,Sazeret,3,Allier,84,Auvergne-Rhône-Alpes
3271,SERBANNES,3700,SERBANNES,,46.098919682,3.35245745606,271,,Serbannes,Serbannes,3,Allier,84,Auvergne-Rhône-Alpes
3272,SERVILLY,3120,SERVILLY,,46.2802589797,3.58979684003,272,,Servilly,Servilly,3,Allier,84,Auvergne-Rhône-Alpes
3273,SEUILLET,3260,SEUILLET,,46.2045684264,3.46787844535,273,,Seuillet,Seuillet,3,Allier,84,Auvergne-Rhône-Alpes
3274,SORBIER,3220,SORBIER,,46.3613185647,3.65747930664,274,,Sorbier,Sorbier,3,Allier,84,Auvergne-Rhône-Alpes
3275,SOUVIGNY,3210,SOUVIGNY,,46.5243440423,3.18992960249,275,,Souvigny,Souvigny,3,Allier,84,Auvergne-Rhône-Alpes
3276,SUSSAT,3450,SUSSAT,,46.1523532024,3.05584411459,276,,Sussat,Sussat,3,Allier,84,Auvergne-Rhône-Alpes
3277,TARGET,3140,TARGET,,46.2848407144,3.04794632227,277,,Target,Target,3,Allier,84,Auvergne-Rhône-Alpes
3278,TAXAT SENAT,3140,TAXAT SENAT,,46.207728501,3.14578508085,278,,Taxat-Senat,Taxat-Senat,3,Allier,84,Auvergne-Rhône-Alpes
3279,TEILLET ARGENTY,3410,TEILLET ARGENTY,,46.262769167,2.51658003922,279,,Teillet-Argenty,Teillet-Argenty,3,Allier,84,Auvergne-Rhône-Alpes
3280,TERJAT,3420,TERJAT,,46.2144621437,2.61879197582,280,,Terjat,Terjat,3,Allier,84,Auvergne-Rhône-Alpes
3281,LE THEIL,3240,LE THEIL,,46.3563893296,3.12547357039,281,Le,Theil,Le Theil,3,Allier,84,Auvergne-Rhône-Alpes
3282,THENEUILLE,3350,THENEUILLE,,46.587490556,2.87994545903,282,,Theneuille,Theneuille,3,Allier,84,Auvergne-Rhône-Alpes
3283,THIEL SUR ACOLIN,3230,THIEL SUR ACOLIN,,46.5311020411,3.57823760672,283,,Thiel-sur-Acolin,Thiel-sur-Acolin,3,Allier,84,Auvergne-Rhône-Alpes
3284,THIONNE,3220,THIONNE,,46.4118104465,3.57326147664,284,,Thionne,Thionne,3,Allier,84,Auvergne-Rhône-Alpes
3285,TORTEZAIS,3430,TORTEZAIS,,46.4456252579,2.87340721419,285,,Tortezais,Tortezais,3,Allier,84,Auvergne-Rhône-Alpes
3286,TOULON SUR ALLIER,3400,TOULON SUR ALLIER,,46.51061605,3.37706265767,286,,Toulon-sur-Allier,Toulon-sur-Allier,3,Allier,84,Auvergne-Rhône-Alpes
3287,TREBAN,3240,TREBAN,,46.3993644498,3.1654172286,287,,Treban,Treban,3,Allier,84,Auvergne-Rhône-Alpes
3288,TREIGNAT,3380,TREIGNAT,,46.3483905337,2.3521991874,288,,Treignat,Treignat,3,Allier,84,Auvergne-Rhône-Alpes
3289,TRETEAU,3220,TRETEAU,,46.3677104422,3.51996970707,289,,Treteau,Treteau,3,Allier,84,Auvergne-Rhône-Alpes
3290,TREVOL,3460,TREVOL,,46.6370512909,3.31589139326,290,,Trévol,Trévol,3,Allier,84,Auvergne-Rhône-Alpes
3291,TREZELLES,3220,TREZELLES,,46.3195324708,3.59671419361,291,,Trézelles,Trézelles,3,Allier,84,Auvergne-Rhône-Alpes
3292,TRONGET,3240,TRONGET,,46.418633391,3.08862365583,292,,Tronget,Tronget,3,Allier,84,Auvergne-Rhône-Alpes
3293,URCAY,3360,URCAY,,46.6340748365,2.61371570998,293,,Urçay,Urçay,3,Allier,84,Auvergne-Rhône-Alpes
3294,USSEL D ALLIER,3140,USSEL D ALLIER,,46.2103626484,3.18945634007,294,,Ussel-d'Allier,Ussel-d'Allier,3,Allier,84,Auvergne-Rhône-Alpes
3295,VALIGNAT,3330,VALIGNAT,,46.1744050814,3.07839354321,295,,Valignat,Valignat,3,Allier,84,Auvergne-Rhône-Alpes
3296,VALIGNY,3360,VALIGNY,,46.7156221865,2.82039123195,296,,Valigny,Valigny,3,Allier,84,Auvergne-Rhône-Alpes
3297,VALLON EN SULLY,3190,VALLON EN SULLY,,46.5401383727,2.61674884517,297,,Vallon-en-Sully,Vallon-en-Sully,3,Allier,84,Auvergne-Rhône-Alpes
3298,VARENNES SUR ALLIER,3150,VARENNES SUR ALLIER,,46.3170256578,3.40012828582,298,,Varennes-sur-Allier,Varennes-sur-Allier,3,Allier,84,Auvergne-Rhône-Alpes
3299,VARENNES SUR TECHE,3220,VARENNES SUR TECHE,,46.3177738358,3.6374417286,299,,Varennes-sur-Tèche,Varennes-sur-Tèche,3,Allier,84,Auvergne-Rhône-Alpes
3300,VAUMAS,3220,VAUMAS,,46.4484082735,3.6241176484,300,,Vaumas,Vaumas,3,Allier,84,Auvergne-Rhône-Alpes
3301,VAUX,3190,VAUX,,46.4214002132,2.57435492547,301,,Vaux,Vaux,3,Allier,84,Auvergne-Rhône-Alpes
3302,VEAUCE,3450,VEAUCE,,46.1700928934,3.05432607962,302,,Veauce,Veauce,3,Allier,84,Auvergne-Rhône-Alpes
3303,VENAS,3190,VENAS,,46.4727802173,2.76726504,303,,Venas,Venas,3,Allier,84,Auvergne-Rhône-Alpes
3304,VENDAT,3110,VENDAT,,46.1618116569,3.34727094186,304,,Vendat,Vendat,3,Allier,84,Auvergne-Rhône-Alpes
3305,VERNEIX,3190,VERNEIX,,46.4080547115,2.6746212521,305,,Verneix,Verneix,3,Allier,84,Auvergne-Rhône-Alpes
3306,LE VERNET,3200,LE VERNET,,46.1067527363,3.47492140254,306,Le,Vernet,Le Vernet,3,Allier,84,Auvergne-Rhône-Alpes
3307,VERNEUIL EN BOURBONNAIS,3500,VERNEUIL EN BOURBONNAIS,,46.3540600283,3.25057881171,307,,Verneuil-en-Bourbonnais,Verneuil-en-Bourbonnais,3,Allier,84,Auvergne-Rhône-Alpes
3308,VERNUSSE,3390,VERNUSSE,,46.2602732838,2.97167741847,308,,Vernusse,Vernusse,3,Allier,84,Auvergne-Rhône-Alpes
3309,LE VEURDRE,3320,LE VEURDRE,,46.7427276777,3.04259770031,309,Le,Veurdre,Le Veurdre,3,Allier,84,Auvergne-Rhône-Alpes
3310,VICHY,3200,VICHY,,46.1300051383,3.42442081174,310,,Vichy,Vichy,3,Allier,84,Auvergne-Rhône-Alpes
3311,VICQ,3450,VICQ,,46.1471274068,3.09596954152,311,,Vicq,Vicq,3,Allier,84,Auvergne-Rhône-Alpes
3312,VIEURE,3430,VIEURE,,46.5001110432,2.88222237454,312,,Vieure,Vieure,3,Allier,84,Auvergne-Rhône-Alpes
3313,LE VILHAIN,3350,LE VILHAIN,,46.5619095522,2.79303077979,313,Le,Vilhain,Le Vilhain,3,Allier,84,Auvergne-Rhône-Alpes
3314,VILLEBRET,3310,VILLEBRET,,46.277798684,2.63276519171,314,,Villebret,Villebret,3,Allier,84,Auvergne-Rhône-Alpes
3315,VILLEFRANCHE D ALLIER,3430,VILLEFRANCHE D ALLIER,,46.3963110357,2.84126895926,315,,Villefranche-d'Allier,Villefranche-d'Allier,3,Allier,84,Auvergne-Rhône-Alpes
3315,VILLEFRANCHE D ALLIER,3430,VILLEFRANCHE D ALLIER,NEUVILLE,46.3963110357,2.84126895926,315,,Villefranche-d'Allier,Villefranche-d'Allier,3,Allier,84,Auvergne-Rhône-Alpes
3316,VILLENEUVE SUR ALLIER,3460,VILLENEUVE SUR ALLIER,,46.6741150218,3.25033675035,316,,Villeneuve-sur-Allier,Villeneuve-sur-Allier,3,Allier,84,Auvergne-Rhône-Alpes
3317,VIPLAIX,3370,VIPLAIX,,46.461810965,2.36975422763,317,,Viplaix,Viplaix,3,Allier,84,Auvergne-Rhône-Alpes
3318,VITRAY,3360,VITRAY,,46.6167757197,2.67692261152,318,,Vitray,Vitray,3,Allier,84,Auvergne-Rhône-Alpes
3319,VOUSSAC,3140,VOUSSAC,,46.3256833709,3.07336455653,319,,Voussac,Voussac,3,Allier,84,Auvergne-Rhône-Alpes
3320,YGRANDE,3160,YGRANDE,,46.5497137895,2.94199693043,320,,Ygrande,Ygrande,3,Allier,84,Auvergne-Rhône-Alpes
3321,YZEURE,3400,YZEURE,,46.5636456232,3.37978160133,321,,Yzeure,Yzeure,3,Allier,84,Auvergne-Rhône-Alpes
4001,AIGLUN,4510,AIGLUN,,44.0638744567,6.13842845338,1,,Aiglun,Aiglun,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4004,ALLEMAGNE EN PROVENCE,4500,ALLEMAGNE EN PROVENCE,,43.7887902464,6.03091832001,4,,Allemagne-en-Provence,Allemagne-en-Provence,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4005,ALLONS,4170,ALLONS,,43.9803884973,6.59032744238,5,,Allons,Allons,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4006,ALLOS,4260,ALLOS,,44.2614961379,6.62671789984,6,,Allos,Allos,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4006,ALLOS,4260,ALLOS,LA FOUX D ALLOS,44.2614961379,6.62671789984,6,,Allos,Allos,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4007,ANGLES,4170,ANGLES,,43.9443293069,6.55232063474,7,,Angles,Angles,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4008,ANNOT,4240,ANNOT,,43.9620009704,6.66395811566,8,,Annot,Annot,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4009,ARCHAIL,4420,ARCHAIL,,44.1110653424,6.33071411194,9,,Archail,Archail,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4012,AUBENAS LES ALPES,4110,AUBENAS LES ALPES,,43.9335584163,5.67694693989,12,,Aubenas-les-Alpes,Aubenas-les-Alpes,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4013,AUBIGNOSC,4200,AUBIGNOSC,,44.1182449561,5.95734484907,13,,Aubignosc,Aubignosc,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4016,AUTHON,4200,AUTHON,,44.2492545518,6.15102271049,16,,Authon,Authon,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4017,AUZET,4140,AUZET,,44.3054001563,6.31115592449,17,,Auzet,Auzet,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4018,BANON,4150,BANON,,44.0269448077,5.64964715248,18,,Banon,Banon,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4019,BARCELONNETTE,4400,BARCELONNETTE,,44.3785614205,6.65215089713,19,,Barcelonnette,Barcelonnette,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4020,BARLES,4140,BARLES,,44.2730280199,6.25325743878,20,,Barles,Barles,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4021,BARRAS,4380,BARRAS,,44.1006575617,6.09850212835,21,,Barras,Barras,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4022,BARREME,4330,BARREME,,43.9462808705,6.37811558709,22,,Barrême,Barrême,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4023,BAYONS,4250,BAYONS,,44.3249277264,6.17282537759,23,,Bayons,Bayons,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4023,BAYONS,4250,BAYONS,ASTOIN,44.3249277264,6.17282537759,23,,Bayons,Bayons,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4023,BAYONS,4250,BAYONS,ESPARRON LA BATIE,44.3249277264,6.17282537759,23,,Bayons,Bayons,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4023,BAYONS,4250,BAYONS,REYNIER,44.3249277264,6.17282537759,23,,Bayons,Bayons,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4024,BEAUJEU,4420,BEAUJEU,,44.2185950386,6.37119806932,24,,Beaujeu,Beaujeu,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4025,BEAUVEZER,4370,BEAUVEZER,,44.1439752737,6.5977277471,25,,Beauvezer,Beauvezer,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4026,BELLAFFAIRE,4250,BELLAFFAIRE,,44.4043915253,6.19420304469,26,,Bellaffaire,Bellaffaire,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4027,BEVONS,4200,BEVONS,,44.1779964769,5.87895928741,27,,Bevons,Bevons,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4028,BEYNES,4270,BEYNES,,43.9834965171,6.24025663148,28,,Beynes,Beynes,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4030,BLIEUX,4330,BLIEUX,,43.8746274845,6.36279670798,30,,Blieux,Blieux,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4031,BRAS D ASSE,4270,BRAS D ASSE,,43.9263257197,6.12649772452,31,,Bras-d'Asse,Bras-d'Asse,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4032,BRAUX,4240,BRAUX,,43.993034454,6.69941127815,32,,Braux,Braux,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4033,LA BREOLE,4340,LA BREOLE,,44.4434662119,6.28924750363,33,,Ubaye-Serre-Ponçon,Ubaye-Serre-Ponçon,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4034,LA BRILLANNE,4700,LA BRILLANNE,,43.9319005392,5.88421838797,34,La,Brillanne,La Brillanne,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4035,BRUNET,4210,BRUNET,,43.8858785364,6.03257536276,35,,Brunet,Brunet,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4036,LE BRUSQUET,4420,LE BRUSQUET,,44.1681621392,6.30347351863,36,Le,Brusquet,Le Brusquet,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4037,LE CAIRE,4250,LE CAIRE,,44.3744163533,6.06875774225,37,Le,Caire,Le Caire,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4039,CASTELLANE,4120,CASTELLANE,,43.8449862174,6.49029842016,39,,Castellane,Castellane,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4039,CASTELLANE,4120,CASTELLANE,CHASTEUIL,43.8449862174,6.49029842016,39,,Castellane,Castellane,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4039,CASTELLANE,4120,CASTELLANE,EOULX,43.8449862174,6.49029842016,39,,Castellane,Castellane,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4039,CASTELLANE,4120,CASTELLANE,ROBION,43.8449862174,6.49029842016,39,,Castellane,Castellane,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4039,CASTELLANE,4120,CASTELLANE,TALOIRE,43.8449862174,6.49029842016,39,,Castellane,Castellane,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4039,CASTELLANE,4120,CASTELLANE,TAULANNE,43.8449862174,6.49029842016,39,,Castellane,Castellane,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4040,LE CASTELLARD MELAN,4380,LE CASTELLARD MELAN,,44.2016431995,6.13159521325,40,Le,Castellard-Mélan,Le Castellard-Mélan,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4040,LE CASTELLARD MELAN,4380,LE CASTELLARD MELAN,MELAN,44.2016431995,6.13159521325,40,Le,Castellard-Mélan,Le Castellard-Mélan,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4041,LE CASTELLET,4700,LE CASTELLET,,43.9198884936,5.97900495307,41,Le,Castellet,Le Castellet,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4042,CASTELLET LES SAUSSES,4320,CASTELLET LES SAUSSES,,44.0403038573,6.73450900533,42,,Castellet-lès-Sausses,Castellet-lès-Sausses,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4043,VAL DE CHALVAGNE,4320,VAL DE CHALVAGNE,,43.9046374337,6.76578520195,43,,Val-de-Chalvagne,Val-de-Chalvagne,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4043,VAL DE CHALVAGNE,4320,VAL DE CHALVAGNE,MONTBLANC,43.9046374337,6.76578520195,43,,Val-de-Chalvagne,Val-de-Chalvagne,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4043,VAL DE CHALVAGNE,4320,VAL DE CHALVAGNE,VILLEVIEILLE,43.9046374337,6.76578520195,43,,Val-de-Chalvagne,Val-de-Chalvagne,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4045,CERESTE,4280,CERESTE,,43.8503161664,5.58976926214,45,,Céreste,Céreste,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4046,LE CHAFFAUT ST JURSON,4510,LE CHAFFAUT ST JURSON,,44.0231804287,6.14073798744,46,Le,Chaffaut-Saint-Jurson,Le Chaffaut-Saint-Jurson,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4046,LE CHAFFAUT ST JURSON,4510,LE CHAFFAUT ST JURSON,ESPINOUSE,44.0231804287,6.14073798744,46,Le,Chaffaut-Saint-Jurson,Le Chaffaut-Saint-Jurson,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4047,CHAMPTERCIER,4660,CHAMPTERCIER,,44.096935134,6.14456451427,47,,Champtercier,Champtercier,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4049,CHATEAU ARNOUX ST AUBAN,4160,CHATEAU ARNOUX ST AUBAN,,44.0854706133,5.99239535024,49,,Château-Arnoux-Saint-Auban,Château-Arnoux-Saint-Auban,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4049,CHATEAU ARNOUX ST AUBAN,4600,CHATEAU ARNOUX ST AUBAN,ST AUBAN,44.0854706133,5.99239535024,49,,Château-Arnoux-Saint-Auban,Château-Arnoux-Saint-Auban,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4050,CHATEAUFORT,4250,CHATEAUFORT,,44.2750461186,6.0296701152,50,,Châteaufort,Châteaufort,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4051,CHATEAUNEUF MIRAVAIL,4200,CHATEAUNEUF MIRAVAIL,,44.153315213,5.71757262461,51,,Châteauneuf-Miravail,Châteauneuf-Miravail,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4053,CHATEAUNEUF VAL ST DONAT,4200,CHATEAUNEUF VAL ST DONAT,,44.0954534225,5.93054683108,53,,Châteauneuf-Val-Saint-Donat,Châteauneuf-Val-Saint-Donat,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4054,CHATEAUREDON,4270,CHATEAUREDON,,44.026508747,6.22429507853,54,,Châteauredon,Châteauredon,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4055,CHAUDON NORANTE,4330,CHAUDON NORANTE,,43.9919149482,6.31736807144,55,,Chaudon-Norante,Chaudon-Norante,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4057,CLAMENSANE,4250,CLAMENSANE,,44.3282663642,6.07850180008,57,,Clamensane,Clamensane,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4058,CLARET,5110,CLARET,,44.3738118271,5.96467673226,58,,Claret,Claret,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4059,CLUMANC,4330,CLUMANC,,44.0220942583,6.37764321053,59,,Clumanc,Clumanc,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4061,COLMARS,4370,COLMARS LES ALPES,,44.1724272821,6.6615406144,61,,Colmars,Colmars,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4062,LA CONDAMINE CHATELARD,4530,LA CONDAMINE CHATELARD,,44.4627686721,6.69629170403,62,La,Condamine-Châtelard,La Condamine-Châtelard,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4062,LA CONDAMINE CHATELARD,4530,LA CONDAMINE CHATELARD,STE ANNE,44.4627686721,6.69629170403,62,La,Condamine-Châtelard,La Condamine-Châtelard,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4063,CORBIERES,4220,CORBIERES,,43.7606909826,5.74590452535,63,,Corbières,Corbières,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4065,CRUIS,4230,CRUIS,,44.0831626991,5.83846949092,65,,Cruis,Cruis,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4066,CURBANS,5110,CURBANS,,44.4083473334,6.03005001215,66,,Curbans,Curbans,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4067,CUREL,4200,CUREL,,44.1766055073,5.67267464668,67,,Curel,Curel,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4068,DAUPHIN,4300,DAUPHIN,,43.8932434369,5.776452248,68,,Dauphin,Dauphin,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4069,DEMANDOLX,4120,DEMANDOLX,,43.8698630954,6.57440592456,69,,Demandolx,Demandolx,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4070,DIGNE LES BAINS,4000,DIGNE LES BAINS,,44.0908723554,6.23590323452,70,,Digne-les-Bains,Digne-les-Bains,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4070,DIGNE LES BAINS,4000,DIGNE LES BAINS,LES DOURBES,44.0908723554,6.23590323452,70,,Digne-les-Bains,Digne-les-Bains,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4072,DRAIX,4420,DRAIX,,44.1299391525,6.37179977607,72,,Draix,Draix,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4073,ENCHASTRAYES,4400,ENCHASTRAYES,,44.3640085405,6.71028980662,73,,Enchastrayes,Enchastrayes,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4073,ENCHASTRAYES,4400,ENCHASTRAYES,LE SAUZE,44.3640085405,6.71028980662,73,,Enchastrayes,Enchastrayes,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4074,ENTRAGES,4000,ENTRAGES,,44.0379332734,6.26880026038,74,,Entrages,Entrages,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4074,ENTRAGES,4270,ENTRAGES,CHABRIERES,44.0379332734,6.26880026038,74,,Entrages,Entrages,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4075,ENTREPIERRES,4200,ENTREPIERRES,,44.1956537173,6.02224124199,75,,Entrepierres,Entrepierres,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4075,ENTREPIERRES,4200,ENTREPIERRES,ST SYMPHORIEN,44.1956537173,6.02224124199,75,,Entrepierres,Entrepierres,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4075,ENTREPIERRES,4200,ENTREPIERRES,VILHOSC,44.1956537173,6.02224124199,75,,Entrepierres,Entrepierres,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4076,ENTREVAUX,4320,ENTREVAUX,,43.9502625021,6.80238543689,76,,Entrevaux,Entrevaux,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4077,ENTREVENNES,4700,ENTREVENNES,,43.9378972717,6.03380903148,77,,Entrevennes,Entrevennes,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4079,L ESCALE,4160,L ESCALE,,44.0810058608,6.03455494518,79,L',Escale,L'Escale,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4081,ESPARRON DE VERDON,4800,ESPARRON DE VERDON,,43.7438250286,5.98891639232,81,,Esparron-de-Verdon,Esparron-de-Verdon,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4081,ESPARRON DE VERDON,4800,ESPARRON DE VERDON,ALBIOSC,43.7438250286,5.98891639232,81,,Esparron-de-Verdon,Esparron-de-Verdon,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4084,ESTOUBLON,4270,ESTOUBLON,,43.943168614,6.19407821084,84,,Estoublon,Estoublon,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4084,ESTOUBLON,4270,ESTOUBLON,TREVANS,43.943168614,6.19407821084,84,,Estoublon,Estoublon,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4085,FAUCON DU CAIRE,4250,FAUCON DU CAIRE,,44.3959463913,6.1036738865,85,,Faucon-du-Caire,Faucon-du-Caire,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4086,FAUCON DE BARCELONNETTE,4400,FAUCON DE BARCELONNETTE,,44.4135826726,6.67868075997,86,,Faucon-de-Barcelonnette,Faucon-de-Barcelonnette,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4087,FONTIENNE,4230,FONTIENNE,,44.0068634181,5.79382501149,87,,Fontienne,Fontienne,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4088,FORCALQUIER,4300,FORCALQUIER,,43.9599082374,5.7882827572,88,,Forcalquier,Forcalquier,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4090,LE FUGERET,4240,LE FUGERET,,44.0145000822,6.66560747142,90,Le,Fugeret,Le Fugeret,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4091,GANAGOBIE,4310,GANAGOBIE,,44.0025862123,5.90850076129,91,,Ganagobie,Ganagobie,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4092,LA GARDE,4120,LA GARDE,,43.8298617013,6.57154524239,92,La,Garde,La Garde,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4093,GIGORS,4250,GIGORS,,44.4239994398,6.14097306258,93,,Gigors,Gigors,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4094,GREOUX LES BAINS,4800,GREOUX LES BAINS,,43.7676102812,5.86495046225,94,,Gréoux-les-Bains,Gréoux-les-Bains,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4095,L HOSPITALET,4150,L HOSPITALET,,44.1089187611,5.70685925605,95,L',Hospitalet,L'Hospitalet,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4096,JAUSIERS,4850,JAUSIERS,,44.3876110175,6.78602312361,96,,Jausiers,Jausiers,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4097,LA JAVIE,4000,LA JAVIE,ESCLANGON,44.1971990157,6.32157607452,97,La,Javie,La Javie,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4097,LA JAVIE,4420,LA JAVIE,,44.1971990157,6.32157607452,97,La,Javie,La Javie,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4099,LAMBRUISSE,4170,LAMBRUISSE,,44.0448344934,6.44538846702,99,,Lambruisse,Lambruisse,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4101,LARDIERS,4230,LARDIERS,,44.0850792894,5.72691612181,101,,Lardiers,Lardiers,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4102,LE LAUZET UBAYE,4340,LE LAUZET UBAYE,,44.432913878,6.41942527455,102,Le,Lauzet-Ubaye,Le Lauzet-Ubaye,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4104,LIMANS,4300,LIMANS,,43.986604993,5.72366425789,104,,Limans,Limans,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4106,LURS,4700,LURS,,43.9698343331,5.8804940629,106,,Lurs,Lurs,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4107,MAJASTRES,4270,MAJASTRES,,43.8980780342,6.2836913455,107,,Majastres,Majastres,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4108,MALIJAI,4350,MALIJAI,,44.0336660524,6.0504243737,108,,Malijai,Malijai,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4108,MALIJAI,4510,MALIJAI,CHENERILLES,44.0336660524,6.0504243737,108,,Malijai,Malijai,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4109,MALLEFOUGASSE AUGES,4230,MALLEFOUGASSE AUGES,,44.0759918613,5.89779648569,109,,Mallefougasse-Augès,Mallefougasse-Augès,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4110,MALLEMOISSON,4510,MALLEMOISSON,,44.0420071966,6.11807147192,110,,Mallemoisson,Mallemoisson,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4111,MANE,4300,MANE,,43.9388973757,5.74234213479,111,,Mane,Mane,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4112,MANOSQUE,4100,MANOSQUE,,43.8354040831,5.79106654173,112,,Manosque,Manosque,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4113,MARCOUX,4420,MARCOUX,,44.128219237,6.28007885939,113,,Marcoux,Marcoux,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4115,MEAILLES,4240,MEAILLES,,44.0401459693,6.64532905257,115,,Méailles,Méailles,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4116,LES MEES,4190,LES MEES,,43.9944767361,5.96601755581,116,Les,Mées,Les Mées,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4118,MELVE,4250,MELVE,,44.3563698074,5.9929110209,118,,Melve,Melve,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4120,VAL D ORONAYE,4530,VAL D ORONAYE,LARCHE,44.4730642294,6.81114702697,120,,Val d'Oronaye,Val d'Oronaye,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4120,VAL D ORONAYE,4530,VAL D ORONAYE,MEYRONNES,44.4730642294,6.81114702697,120,,Val d'Oronaye,Val d'Oronaye,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4121,MEZEL,4270,MEZEL,,43.9980863365,6.17879892264,121,,Mézel,Mézel,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4122,MIRABEAU,4510,MIRABEAU,,44.0562295958,6.08015931568,122,,Mirabeau,Mirabeau,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4123,MISON,4200,MISON,,44.2624329752,5.86562678758,123,,Mison,Mison,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4124,MONTAGNAC MONTPEZAT,4500,MONTAGNAC MONTPEZAT,,43.766525069,6.09454805934,124,,Montagnac-Montpezat,Montagnac-Montpezat,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4124,MONTAGNAC MONTPEZAT,4500,MONTAGNAC MONTPEZAT,MONTPEZAT,43.766525069,6.09454805934,124,,Montagnac-Montpezat,Montagnac-Montpezat,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4126,MONTCLAR,4140,MONTCLAR,,44.401613844,6.34112805174,126,,Montclar,Montclar,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4127,MONTFORT,4600,MONTFORT,,44.0591709898,5.95286093626,127,,Montfort,Montfort,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4128,MONTFURON,4110,MONTFURON,,43.831580084,5.68859318549,128,,Montfuron,Montfuron,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4129,MONTJUSTIN,4110,MONTJUSTIN,,43.8452930923,5.65023560321,129,,Montjustin,Montjustin,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4130,MONTLAUX,4230,MONTLAUX,,44.0407923753,5.85016627036,130,,Montlaux,Montlaux,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4132,MONTSALIER,4150,MONTSALIER,,44.0299525826,5.59574173796,132,,Montsalier,Montsalier,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4133,MORIEZ,4170,MORIEZ,,43.9640929535,6.46253345907,133,,Moriez,Moriez,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4134,LA MOTTE DU CAIRE,4250,LA MOTTE DU CAIRE,,44.3408705676,6.03052669324,134,La,Motte-du-Caire,La Motte-du-Caire,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4135,MOUSTIERS STE MARIE,4360,MOUSTIERS STE MARIE,,43.8383278559,6.22818909628,135,,Moustiers-Sainte-Marie,Moustiers-Sainte-Marie,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4136,LA MURE ARGENS,4170,LA MURE ARGENS,,44.0166107784,6.53196701824,136,La,Mure-Argens,La Mure-Argens,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4136,LA MURE ARGENS,4170,LA MURE ARGENS,ARGENS,44.0166107784,6.53196701824,136,La,Mure-Argens,La Mure-Argens,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4137,NIBLES,4250,NIBLES,,44.2892541829,6.00872621678,137,,Nibles,Nibles,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4138,NIOZELLES,4300,NIOZELLES,,43.9318568104,5.84735007889,138,,Niozelles,Niozelles,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4139,NOYERS SUR JABRON,4200,NOYERS SUR JABRON,,44.1686523521,5.80998243978,139,,Noyers-sur-Jabron,Noyers-sur-Jabron,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4140,LES OMERGUES,4200,LES OMERGUES,,44.1527454808,5.5893705894,140,Les,Omergues,Les Omergues,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4141,ONGLES,4230,ONGLES,,44.0314259831,5.72480881412,141,,Ongles,Ongles,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4142,OPPEDETTE,4110,OPPEDETTE,,43.9306604764,5.59274330363,142,,Oppedette,Oppedette,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4143,ORAISON,4700,ORAISON,,43.911106033,5.92458011626,143,,Oraison,Oraison,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4144,LA PALUD SUR VERDON,4120,LA PALUD SUR VERDON,,43.796648297,6.32708874551,144,La,Palud-sur-Verdon,La Palud-sur-Verdon,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4144,LA PALUD SUR VERDON,4120,LA PALUD SUR VERDON,CHATEAUNEUF LES MOUSTIERS,43.796648297,6.32708874551,144,La,Palud-sur-Verdon,La Palud-sur-Verdon,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4145,PEIPIN,4200,PEIPIN,,44.1413026547,5.9437147381,145,,Peipin,Peipin,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4145,PEIPIN,4200,PEIPIN,LES BONS ENFANTS,44.1413026547,5.9437147381,145,,Peipin,Peipin,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4148,PEYROULES,4120,PEYROULES,,43.8169194662,6.64310114729,148,,Peyroules,Peyroules,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4149,PEYRUIS,4310,PEYRUIS,,44.03515352,5.92833355728,149,,Peyruis,Peyruis,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4150,PIEGUT,5130,PIEGUT,,44.4533786118,6.1317225786,150,,Piégut,Piégut,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4151,PIERRERUE,4300,PIERRERUE,,43.9623331645,5.83601873141,151,,Pierrerue,Pierrerue,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4152,PIERREVERT,4860,PIERREVERT,,43.8065050599,5.72273821724,152,,Pierrevert,Pierrevert,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4154,PONTIS,5160,PONTIS,,44.4956918963,6.36523776593,154,,Pontis,Pontis,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4155,PRADS HAUTE BLEONE,4420,PRADS HAUTE BLEONE,,44.2134336367,6.46376994635,155,,Prads-Haute-Bléone,Prads-Haute-Bléone,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4155,PRADS HAUTE BLEONE,4420,PRADS HAUTE BLEONE,BLEGIERS,44.2134336367,6.46376994635,155,,Prads-Haute-Bléone,Prads-Haute-Bléone,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4155,PRADS HAUTE BLEONE,4420,PRADS HAUTE BLEONE,MARIAUD,44.2134336367,6.46376994635,155,,Prads-Haute-Bléone,Prads-Haute-Bléone,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4156,PUIMICHEL,4700,PUIMICHEL,,43.9791657071,6.02538678185,156,,Puimichel,Puimichel,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4157,PUIMOISSON,4410,PUIMOISSON,,43.8708152829,6.12200213815,157,,Puimoisson,Puimoisson,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4158,QUINSON,4500,QUINSON,,43.7080624854,6.02269492276,158,,Quinson,Quinson,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4159,REDORTIERS,4150,REDORTIERS,,44.0981211073,5.61414591507,159,,Redortiers,Redortiers,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4160,REILLANNE,4110,REILLANNE,,43.8861362182,5.65935415272,160,,Reillanne,Reillanne,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4161,MEOLANS REVEL,4340,MEOLANS REVEL,,44.3714312017,6.49846404885,161,,Méolans-Revel,Méolans-Revel,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4161,MEOLANS REVEL,4340,MEOLANS REVEL,MEOLANS,44.3714312017,6.49846404885,161,,Méolans-Revel,Méolans-Revel,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4161,MEOLANS REVEL,4340,MEOLANS REVEL,REVEL,44.3714312017,6.49846404885,161,,Méolans-Revel,Méolans-Revel,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4162,REVEST DES BROUSSES,4150,REVEST DES BROUSSES,,43.9754850716,5.67501221174,162,,Revest-des-Brousses,Revest-des-Brousses,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4163,REVEST DU BION,4150,REVEST DU BION,,44.0940903498,5.54050938545,163,,Revest-du-Bion,Revest-du-Bion,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4164,REVEST ST MARTIN,4230,REVEST ST MARTIN,,44.0198482775,5.82298621297,164,,Revest-Saint-Martin,Revest-Saint-Martin,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4166,RIEZ,4500,RIEZ,,43.8284587474,6.08237819985,166,,Riez,Riez,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4167,LA ROBINE SUR GALABRE,4000,LA ROBINE SUR GALABRE,,44.1927329945,6.23038435702,167,La,Robine-sur-Galabre,La Robine-sur-Galabre,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4167,LA ROBINE SUR GALABRE,4000,LA ROBINE SUR GALABRE,AINAC,44.1927329945,6.23038435702,167,La,Robine-sur-Galabre,La Robine-sur-Galabre,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4167,LA ROBINE SUR GALABRE,4000,LA ROBINE SUR GALABRE,LAMBERT,44.1927329945,6.23038435702,167,La,Robine-sur-Galabre,La Robine-sur-Galabre,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4167,LA ROBINE SUR GALABRE,4000,LA ROBINE SUR GALABRE,TANARON,44.1927329945,6.23038435702,167,La,Robine-sur-Galabre,La Robine-sur-Galabre,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4169,LA ROCHEGIRON,4150,LA ROCHEGIRON,,44.1094183926,5.66381397427,169,La,Rochegiron,La Rochegiron,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4170,LA ROCHETTE,6260,LA ROCHETTE,,43.9122760348,6.88070058923,170,La,Rochette,La Rochette,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4171,ROUGON,4120,ROUGON,,43.7966594802,6.39318145128,171,,Rougon,Rougon,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4172,ROUMOULES,4500,ROUMOULES,,43.821041609,6.14864703263,172,,Roumoules,Roumoules,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4173,ST ANDRE LES ALPES,4170,ST ANDRE LES ALPES,,43.9713301406,6.50149712225,173,,Saint-André-les-Alpes,Saint-André-les-Alpes,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4174,ST BENOIT,4240,ST BENOIT,,43.9629414064,6.72386704569,174,,Saint-Benoît,Saint-Benoît,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4175,STE CROIX A LAUZE,4110,STE CROIX A LAUZE,,43.9042070968,5.61568170523,175,,Sainte-Croix-à-Lauze,Sainte-Croix-à-Lauze,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4176,STE CROIX DU VERDON,4500,STE CROIX DU VERDON,,43.7642821493,6.15158882357,176,,Sainte-Croix-du-Verdon,Sainte-Croix-du-Verdon,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4177,HAUTES DUYES,4380,HAUTES DUYES,,44.1949051654,6.17423380965,177,,Hautes-Duyes,Hautes-Duyes,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4177,HAUTES DUYES,4380,HAUTES DUYES,AURIBEAU,44.1949051654,6.17423380965,177,,Hautes-Duyes,Hautes-Duyes,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4178,ST ETIENNE LES ORGUES,4230,ST ETIENNE LES ORGUES,,44.0670149849,5.78046052827,178,,Saint-Étienne-les-Orgues,Saint-Étienne-les-Orgues,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4179,ST GENIEZ,4200,ST GENIEZ,,44.2326668931,6.06267402716,179,,Saint-Geniez,Saint-Geniez,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4180,ST JACQUES,4330,ST JACQUES,,43.976917929,6.37128517143,180,,Saint-Jacques,Saint-Jacques,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4181,ST JEANNET,4270,ST JEANNET,,43.9793220173,6.11801552777,181,,Saint-Jeannet,Saint-Jeannet,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4182,ST JULIEN D ASSE,4270,ST JULIEN D ASSE,,43.9266153853,6.07806454992,182,,Saint-Julien-d'Asse,Saint-Julien-d'Asse,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4183,ST JULIEN DU VERDON,4170,ST JULIEN DU VERDON,,43.9097626116,6.54605900451,183,,Saint-Julien-du-Verdon,Saint-Julien-du-Verdon,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4184,ST JURS,4410,ST JURS,,43.8990474204,6.19288088982,184,,Saint-Jurs,Saint-Jurs,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4186,ST LAURENT DU VERDON,4500,ST LAURENT DU VERDON,,43.7216172183,6.06859538838,186,,Saint-Laurent-du-Verdon,Saint-Laurent-du-Verdon,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4187,ST LIONS,4330,ST LIONS,,43.9786318012,6.40627740741,187,,Saint-Lions,Saint-Lions,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4188,ST MAIME,4300,ST MAIME,,43.900642071,5.801542403,188,,Saint-Maime,Saint-Maime,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4189,ST MARTIN DE BROMES,4800,ST MARTIN DE BROMES,,43.7774768739,5.96250175121,189,,Saint-Martin-de-Brômes,Saint-Martin-de-Brômes,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4190,ST MARTIN LES EAUX,4300,ST MARTIN LES EAUX,,43.8719702944,5.74137178032,190,,Saint-Martin-les-Eaux,Saint-Martin-les-Eaux,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4191,ST MARTIN LES SEYNE,4140,ST MARTIN LES SEYNE,,44.3827202732,6.2555532059,191,,Saint-Martin-lès-Seyne,Saint-Martin-lès-Seyne,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4192,ST MICHEL L OBSERVATOIRE,4870,ST MICHEL L OBSERVATOIRE,,43.909879406,5.72004315449,192,,Saint-Michel-l'Observatoire,Saint-Michel-l'Observatoire,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4192,ST MICHEL L OBSERVATOIRE,4870,ST MICHEL L OBSERVATOIRE,LINCEL,43.909879406,5.72004315449,192,,Saint-Michel-l'Observatoire,Saint-Michel-l'Observatoire,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4193,ST PAUL SUR UBAYE,4530,ST PAUL SUR UBAYE,,44.5588988433,6.82078770145,193,,Saint-Paul-sur-Ubaye,Saint-Paul-sur-Ubaye,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4194,ST PIERRE,6260,ST PIERRE,,43.9088889412,6.92184591099,194,,Saint-Pierre,Saint-Pierre,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4195,ST PONS,4400,ST PONS,,44.4172713189,6.61507424866,195,,Saint-Pons,Saint-Pons,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4197,STE TULLE,4220,STE TULLE,,43.7830482525,5.77302331731,197,,Sainte-Tulle,Sainte-Tulle,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4198,ST VINCENT LES FORTS,4340,ST VINCENT LES FORTS,,44.4374162723,6.3688796074,198,,Saint-Vincent-les-Forts,Saint-Vincent-les-Forts,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4199,ST VINCENT SUR JABRON,4200,ST VINCENT SUR JABRON,,44.1697659083,5.74794765286,199,,Saint-Vincent-sur-Jabron,Saint-Vincent-sur-Jabron,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4200,SALIGNAC,4290,SALIGNAC,,44.1571681055,5.98824785964,200,,Salignac,Salignac,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4201,SAUMANE,4150,SAUMANE,,44.0812808516,5.68214602167,201,,Saumane,Saumane,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4202,SAUSSES,4320,SAUSSES,,44.0122711291,6.77936939324,202,,Sausses,Sausses,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4203,SELONNET,4140,SELONNET,,44.3717210833,6.29379329975,203,,Selonnet,Selonnet,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4204,SENEZ,4270,SENEZ,LE POIL,43.9224978199,6.36903371255,204,,Senez,Senez,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4204,SENEZ,4330,SENEZ,,43.9224978199,6.36903371255,204,,Senez,Senez,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4205,SEYNE,4140,SEYNE LES ALPES,,44.3390108176,6.38482796835,205,,Seyne,Seyne,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4206,SIGONCE,4300,SIGONCE,,44.0045227576,5.85658315569,206,,Sigonce,Sigonce,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4207,SIGOYER,4200,SIGOYER,,44.3223711513,5.96102816265,207,,Sigoyer,Sigoyer,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4208,SIMIANE LA ROTONDE,4150,SIMIANE LA ROTONDE,,43.9838128235,5.56248994589,208,,Simiane-la-Rotonde,Simiane-la-Rotonde,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4208,SIMIANE LA ROTONDE,4150,SIMIANE LA ROTONDE,CARNIOL,43.9838128235,5.56248994589,208,,Simiane-la-Rotonde,Simiane-la-Rotonde,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4208,SIMIANE LA ROTONDE,4150,SIMIANE LA ROTONDE,VALSAINTES,43.9838128235,5.56248994589,208,,Simiane-la-Rotonde,Simiane-la-Rotonde,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4209,SISTERON,4200,SISTERON,,44.2001925067,5.93083716039,209,,Sisteron,Sisteron,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4210,SOLEILHAS,4120,SOLEILHAS,,43.8599760555,6.64860753226,210,,Soleilhas,Soleilhas,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4211,SOURRIBES,4290,SOURRIBES,,44.1588664818,6.04430204786,211,,Sourribes,Sourribes,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4214,TARTONNE,4330,TARTONNE,,44.0784663763,6.3839310068,214,,Tartonne,Tartonne,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4216,THEZE,4200,THEZE,,44.3297532432,5.92502310147,216,,Thèze,Thèze,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4217,THOARD,4380,THOARD,,44.1473006003,6.12401298333,217,,Thoard,Thoard,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4217,THOARD,4380,THOARD,LA PERUSSE,44.1473006003,6.12401298333,217,,Thoard,Thoard,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4218,THORAME BASSE,4170,THORAME BASSE,,44.1071929199,6.48931325437,218,,Thorame-Basse,Thorame-Basse,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4219,THORAME HAUTE,4170,THORAME HAUTE,,44.0855014984,6.60128741508,219,,Thorame-Haute,Thorame-Haute,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4219,THORAME HAUTE,4170,THORAME HAUTE,ST MICHEL PEYRESQ,44.0855014984,6.60128741508,219,,Thorame-Haute,Thorame-Haute,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4220,LES THUILES,4400,LES THUILES,,44.390358455,6.56135597439,220,Les,Thuiles,Les Thuiles,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4222,TURRIERS,4250,TURRIERS,,44.3915882101,6.15918221528,222,,Turriers,Turriers,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4224,UBRAYE,4240,UBRAYE,,43.9070446741,6.68718463205,224,,Ubraye,Ubraye,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4226,UVERNET FOURS,4400,UVERNET FOURS,,44.3191132083,6.67028443403,226,,Uvernet-Fours,Uvernet-Fours,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4226,UVERNET FOURS,4400,UVERNET FOURS,FOURS,44.3191132083,6.67028443403,226,,Uvernet-Fours,Uvernet-Fours,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4226,UVERNET FOURS,4400,UVERNET FOURS,PRA LOUP,44.3191132083,6.67028443403,226,,Uvernet-Fours,Uvernet-Fours,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4227,VACHERES,4110,VACHERES,,43.9467491617,5.63529437,227,,Vachères,Vachères,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4228,VALAVOIRE,4250,VALAVOIRE,,44.2795099462,6.07615731535,228,,Valavoire,Valavoire,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4229,VALBELLE,4200,VALBELLE,,44.1371207692,5.87627669795,229,,Valbelle,Valbelle,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4230,VALENSOLE,4210,VALENSOLE,,43.8380844852,5.94191847166,230,,Valensole,Valensole,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4231,VALERNES,4200,VALERNES,,44.2515589736,5.969547642,231,,Valernes,Valernes,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4233,VAUMEILH,4200,VAUMEILH,,44.2903076957,5.95653121817,233,,Vaumeilh,Vaumeilh,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4234,VENTEROL,5130,VENTEROL,,44.4423460092,6.0873850138,234,,Venterol,Venterol,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4235,VERDACHES,4140,VERDACHES,,44.2677990746,6.34334657591,235,,Verdaches,Verdaches,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4236,VERGONS,4170,VERGONS,,43.9152134017,6.6046313619,236,,Vergons,Vergons,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4237,LE VERNET,4140,LE VERNET,,44.2766068334,6.40297525512,237,Le,Vernet,Le Vernet,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4240,VILLARS COLMARS,4370,VILLARS COLMARS,,44.198291615,6.56876674305,240,,Villars-Colmars,Villars-Colmars,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4241,VILLEMUS,4110,VILLEMUS,,43.8613317961,5.70930443127,241,,Villemus,Villemus,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4242,VILLENEUVE,4180,VILLENEUVE,,43.8957056864,5.85732128727,242,,Villeneuve,Villeneuve,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4244,VOLONNE,4290,VOLONNE,,44.1239478859,6.03467827528,244,,Volonne,Volonne,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
4245,VOLX,4130,VOLX,,43.8706676132,5.83153973779,245,,Volx,Volx,4,Alpes-de-Haute-Provence,93,Provence-Alpes-Côte d'Azur
5001,ABRIES,5460,ABRIES,,44.8163462455,6.94164442809,1,,Abriès,Abriès,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5003,AIGUILLES,5470,AIGUILLES,,44.7793442905,6.87518098236,3,,Aiguilles,Aiguilles,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5004,ANCELLE,5260,ANCELLE,,44.6190703929,6.22962169969,4,,Ancelle,Ancelle,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5006,L ARGENTIERE LA BESSEE,5120,L ARGENTIERE LA BESSEE,,44.7821732712,6.47214497323,6,L',Argentière-la-Bessée,L'Argentière-la-Bessée,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5007,ARVIEUX,5350,ARVIEUX,,44.7638980345,6.72230859416,7,,Arvieux,Arvieux,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5008,ASPREMONT,5140,ASPREMONT,,44.4929345499,5.72022682239,8,,Aspremont,Aspremont,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5009,ASPRES LES CORPS,5800,ASPRES LES CORPS,,44.8128831432,6.00076834757,9,,Aspres-lès-Corps,Aspres-lès-Corps,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5010,ASPRES SUR BUECH,5140,ASPRES SUR BUECH,,44.5617512104,5.77203298744,10,,Aspres-sur-Buëch,Aspres-sur-Buëch,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5011,AVANCON,5230,AVANCON,,44.5339475338,6.19116390114,11,,Avançon,Avançon,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5012,BARATIER,5200,BARATIER,,44.5049966967,6.50494218197,12,,Baratier,Baratier,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5013,BARCILLONNETTE,5110,BARCILLONNETTE,,44.4300646552,5.90582685015,13,,Barcillonnette,Barcillonnette,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5014,BARRET SUR MEOUGE,5300,BARRET SUR MEOUGE,,44.2647188875,5.72864435071,14,,Barret-sur-Méouge,Barret-sur-Méouge,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5016,LA BATIE MONTSALEON,5700,LA BATIE MONTSALEON,,44.4559449966,5.74903304625,16,La,Bâtie-Montsaléon,La Bâtie-Montsaléon,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5017,LA BATIE NEUVE,5230,LA BATIE NEUVE,,44.5797566279,6.21267983536,17,La,Bâtie-Neuve,La Bâtie-Neuve,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5018,LA BATIE VIEILLE,5000,LA BATIE VIEILLE,,44.5553227405,6.15570739329,18,La,Bâtie-Vieille,La Bâtie-Vieille,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5019,LA BEAUME,5140,LA BEAUME,,44.5482914658,5.65375214301,19,La,Beaume,La Beaume,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5021,LE BERSAC,5700,LE BERSAC,,44.4056964766,5.75340964069,21,Le,Bersac,Le Bersac,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5022,BREZIERS,5190,BREZIERS,,44.4210530909,6.22281621001,22,,Bréziers,Bréziers,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5023,BRIANCON,5100,BRIANCON,,44.8994986041,6.64947524018,23,,Briançon,Briançon,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5024,BRUIS,5150,BRUIS,,44.4677366709,5.50388863719,24,,Valdoule,Valdoule,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5025,BUISSARD,5500,BUISSARD,,44.6575269333,6.14755068818,25,,Buissard,Buissard,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5026,CEILLAC,5600,CEILLAC,,44.6526516194,6.80366189831,26,,Ceillac,Ceillac,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5027,CERVIERES,5100,CERVIERES,,44.8532848504,6.76486547281,27,,Cervières,Cervières,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5028,CHABESTAN,5400,CHABESTAN,,44.4775755934,5.78305319582,28,,Chabestan,Chabestan,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5029,CHABOTTES,5260,CHABOTTES,,44.6475783169,6.16791978587,29,,Chabottes,Chabottes,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5031,CHAMPCELLA,5310,CHAMPCELLA,,44.7175774929,6.53138042905,31,,Champcella,Champcella,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5032,CHAMPOLEON,5260,CHAMPOLEON,,44.7457461011,6.27312209866,32,,Champoléon,Champoléon,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5033,CHANOUSSE,5700,CHANOUSSE,,44.3646760156,5.66013922855,33,,Chanousse,Chanousse,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5035,CHATEAUNEUF D OZE,5400,CHATEAUNEUF D OZE,,44.5053255197,5.89775602851,35,,Châteauneuf-d'Oze,Châteauneuf-d'Oze,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5036,CHATEAUROUX LES ALPES,5380,CHATEAUROUX LES ALPES,,44.6423670958,6.48290823337,36,,Châteauroux-les-Alpes,Châteauroux-les-Alpes,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5037,CHATEAUVIEUX,5000,CHATEAUVIEUX,,44.4841748312,6.05187114632,37,,Châteauvieux,Châteauvieux,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5038,CHATEAU VILLE VIEILLE,5350,CHATEAU VILLE VIEILLE,,44.7677289666,6.8000656504,38,,Château-Ville-Vieille,Château-Ville-Vieille,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5038,CHATEAU VILLE VIEILLE,5350,CHATEAU VILLE VIEILLE,CHATEAU QUEYRAS,44.7677289666,6.8000656504,38,,Château-Ville-Vieille,Château-Ville-Vieille,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5039,CHAUFFAYER,5800,CHAUFFAYER,,44.7581501379,6.01187494014,39,,Aubessagne,Aubessagne,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5040,CHORGES,5230,CHORGES,,44.5455124295,6.28782392595,40,,Chorges,Chorges,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5043,LES COSTES,5500,LES COSTES,,44.7485743408,6.04728565137,43,Les,Costes,Les Costes,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5044,CREVOUX,5200,CREVOUX,,44.5376065379,6.62470372696,44,,Crévoux,Crévoux,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5045,CROTS,5200,CROTS,,44.496293828,6.46197152572,45,,Crots,Crots,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5046,EMBRUN,5200,EMBRUN,,44.5804294908,6.47559298927,46,,Embrun,Embrun,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5047,EOURRES,5300,EOURRES,,44.2138373403,5.72894553965,47,,Éourres,Éourres,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5048,L EPINE,5700,L EPINE,,44.4248796516,5.61094352039,48,L',Épine,L'Épine,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5049,ESPARRON,5110,ESPARRON,,44.4627395922,5.89302848154,49,,Esparron,Esparron,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5050,ESPINASSES,5190,ESPINASSES,,44.4931786054,6.23271348149,50,,Espinasses,Espinasses,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5051,ETOILE ST CYRICE,5700,ETOILE ST CYRICE,,44.3209708871,5.64377761466,51,,Étoile-Saint-Cyrice,Étoile-Saint-Cyrice,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5052,EYGLIERS,5600,EYGLIERS,,44.6925244966,6.66688741371,52,,Eygliers,Eygliers,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5053,GARDE COLOMBE,5300,GARDE COLOMBE,EYGUIANS,44.3570512046,5.78336490177,53,,Garde-Colombe,Garde-Colombe,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5053,GARDE COLOMBE,5300,GARDE COLOMBE,LAGRAND,44.3570512046,5.78336490177,53,,Garde-Colombe,Garde-Colombe,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5053,GARDE COLOMBE,5300,GARDE COLOMBE,ST GENIS,44.3570512046,5.78336490177,53,,Garde-Colombe,Garde-Colombe,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5054,LA FARE EN CHAMPSAUR,5500,LA FARE EN CHAMPSAUR,,44.6653766254,6.05826038858,54,La,Fare-en-Champsaur,La Fare-en-Champsaur,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5055,LA FAURIE,5140,LA FAURIE,,44.5674587303,5.74040594768,55,La,Faurie,La Faurie,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5056,FOREST ST JULIEN,5260,FOREST ST JULIEN,,44.6298014381,6.14107531251,56,,Forest-Saint-Julien,Forest-Saint-Julien,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5057,FOUILLOUSE,5130,FOUILLOUSE,,44.4531851404,6.00063153146,57,,Fouillouse,Fouillouse,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5058,FREISSINIERES,5310,FREISSINIERES,,44.7364921825,6.45937704084,58,,Freissinières,Freissinières,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5059,LA FREISSINOUSE,5000,LA FREISSINOUSE,,44.535534165,6.00812804583,59,La,Freissinouse,La Freissinouse,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5060,FURMEYER,5400,FURMEYER,,44.5368984209,5.87585358829,60,,Furmeyer,Furmeyer,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5061,GAP,5000,GAP,,44.5798600596,6.06486052138,61,,Gap,Gap,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5061,GAP,5000,GAP,ROMETTE,44.5798600596,6.06486052138,61,,Gap,Gap,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5062,LE GLAIZIL,5800,LE GLAIZIL,,44.7481922763,5.97487247782,62,Le,Glaizil,Le Glaizil,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5063,LA GRAVE,5320,LA GRAVE,,45.0601102401,6.28406977587,63,La,Grave,La Grave,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5064,LA CHAPELLE EN VALGAUDEMAR,5800,LA CHAPELLE EN VALGAUDEMAR,,44.8166177861,6.2425779341,64,La,Chapelle-en-Valgaudémar,La Chapelle-en-Valgaudémar,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5065,GUILLESTRE,5600,GUILLESTRE,,44.6680987436,6.7010158705,65,,Guillestre,Guillestre,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5066,LA HAUTE BEAUME,5140,LA HAUTE BEAUME,,44.5686788407,5.62845703761,66,La,Haute-Beaume,La Haute-Beaume,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5068,JARJAYES,5130,JARJAYES,,44.50532996,6.10808996032,68,,Jarjayes,Jarjayes,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5070,LARAGNE MONTEGLIN,5300,LARAGNE MONTEGLIN,,44.3340345431,5.81650957133,70,,Laragne-Montéglin,Laragne-Montéglin,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5071,LARDIER ET VALENCA,5110,LARDIER ET VALENCA,,44.4366046505,5.97348526761,71,,Lardier-et-Valença,Lardier-et-Valença,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5072,LAYE,5500,LAYE,,44.6417417308,6.07103943315,72,,Laye,Laye,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5073,LAZER,5300,LAZER,,44.3517300661,5.84995060396,73,,Lazer,Lazer,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5074,LETTRET,5130,LETTRET,,44.4811639998,6.07258452754,74,,Lettret,Lettret,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5075,MANTEYER,5400,MANTEYER,,44.5333321119,5.94989667477,75,,Manteyer,Manteyer,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5076,MEREUIL,5700,MEREUIL,,44.3839741469,5.71259098721,76,,Méreuil,Méreuil,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5077,MOLINES EN QUEYRAS,5350,MOLINES EN QUEYRAS,,44.7166553487,6.89190806069,77,,Molines-en-Queyras,Molines-en-Queyras,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5078,MONETIER ALLEMONT,5110,MONETIER ALLEMONT,,44.3930962547,5.93397190265,78,,Monêtier-Allemont,Monêtier-Allemont,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5079,LE MONETIER LES BAINS,5220,LE MONETIER LES BAINS,,44.9956106766,6.46966497275,79,Le,Monêtier-les-Bains,Le Monêtier-les-Bains,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5080,MONTBRAND,5140,MONTBRAND,,44.5915829146,5.66765951559,80,,Montbrand,Montbrand,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5081,MONTCLUS,5700,MONTCLUS,,44.4149343324,5.66992087691,81,,Montclus,Montclus,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5082,MONT DAUPHIN,5600,MONT DAUPHIN,,44.6690562408,6.62465091015,82,,Mont-Dauphin,Mont-Dauphin,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5084,MONTGARDIN,5230,MONTGARDIN,,44.543584781,6.23305665739,84,,Montgardin,Montgardin,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5085,MONTGENEVRE,5100,MONTGENEVRE,,44.9389932221,6.72778259009,85,,Montgenèvre,Montgenèvre,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5086,MONTJAY,5150,MONTJAY,,44.3664380919,5.61455270536,86,,Montjay,Montjay,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5087,MONTMAUR,5400,MONTMAUR,,44.5897361415,5.88561114683,87,,Montmaur,Montmaur,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5088,MONTMORIN,5150,MONTMORIN,,44.4501067794,5.55351371982,88,,Montmorin,Montmorin,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5089,MONTROND,5700,MONTROND,,44.3867626789,5.74626176371,89,,Montrond,Montrond,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5090,LA MOTTE EN CHAMPSAUR,5500,LA MOTTE EN CHAMPSAUR,,44.7407367523,6.12930149849,90,La,Motte-en-Champsaur,La Motte-en-Champsaur,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5091,MOYDANS,5150,MOYDANS,,44.4099745186,5.50897346198,91,,Moydans,Moydans,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5092,NEFFES,5000,NEFFES,,44.4958417609,6.02550737641,92,,Neffes,Neffes,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5093,NEVACHE,5100,NEVACHE,,45.0441286352,6.59862935541,93,,Névache,Névache,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5094,NOSSAGE ET BENEVENT,5700,NOSSAGE ET BENEVENT,,44.3097205577,5.75452221708,94,,Nossage-et-Bénévent,Nossage-et-Bénévent,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5095,LE NOYER,5500,LE NOYER,,44.6999993894,6.00102694215,95,Le,Noyer,Le Noyer,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5096,ORCIERES,5170,ORCIERES,,44.6840982803,6.34897527824,96,,Orcières,Orcières,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5096,ORCIERES,5170,ORCIERES,MERLETTE,44.6840982803,6.34897527824,96,,Orcières,Orcières,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5097,ORPIERRE,5700,ORPIERRE,,44.3146933801,5.70618607588,97,,Orpierre,Orpierre,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5098,LES ORRES,5200,LES ORRES,,44.4850666477,6.57973142216,98,Les,Orres,Les Orres,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5099,OZE,5400,OZE,,44.5058294764,5.79875486134,99,,Oze,Oze,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5100,PELLEAUTIER,5000,PELLEAUTIER,,44.5102662977,5.99877739937,100,,Pelleautier,Pelleautier,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5101,PELVOUX,5340,PELVOUX,,44.899639207,6.43586430867,101,,Vallouise-Pelvoux,Vallouise-Pelvoux,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5101,PELVOUX,5340,PELVOUX,AILEFROIDE,44.899639207,6.43586430867,101,,Vallouise-Pelvoux,Vallouise-Pelvoux,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5102,LA PIARRE,5700,LA PIARRE,,44.4784087163,5.64803205429,102,La,Piarre,La Piarre,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5103,LE POET,5300,LE POET,,44.2789746584,5.90131318428,103,Le,Poët,Le Poët,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5104,POLIGNY,5500,POLIGNY,,44.6848876675,6.03431860597,104,,Poligny,Poligny,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5106,PRUNIERES,5230,PRUNIERES,,44.5433412641,6.33640431241,106,,Prunières,Prunières,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5107,PUY ST ANDRE,5100,PUY ST ANDRE,,44.8909036618,6.56677781045,107,,Puy-Saint-André,Puy-Saint-André,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5108,PUY ST EUSEBE,5200,PUY ST EUSEBE,,44.5685889454,6.41129829522,108,,Puy-Saint-Eusèbe,Puy-Saint-Eusèbe,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5109,PUY ST PIERRE,5100,PUY ST PIERRE,,44.8973152572,6.60470761793,109,,Puy-Saint-Pierre,Puy-Saint-Pierre,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5110,PUY ST VINCENT,5290,PUY ST VINCENT,,44.8144250588,6.4647473741,110,,Puy-Saint-Vincent,Puy-Saint-Vincent,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5111,PUY SANIERES,5200,PUY SANIERES,,44.5556949894,6.43667418858,111,,Puy-Sanières,Puy-Sanières,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5112,RABOU,5400,RABOU,,44.6061461258,6.01077928936,112,,Rabou,Rabou,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5113,RAMBAUD,5000,RAMBAUD,,44.5468227894,6.13166613475,113,,Rambaud,Rambaud,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5114,REALLON,5160,REALLON,,44.6107753971,6.36046039369,114,,Réallon,Réallon,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5115,REMOLLON,5190,REMOLLON,,44.4716185236,6.15977657931,115,,Remollon,Remollon,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5116,REOTIER,5600,REOTIER,,44.6813566833,6.56457884975,116,,Réotier,Réotier,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5117,RIBEYRET,5150,RIBEYRET,,44.4107304576,5.55802862069,117,,Ribeyret,Ribeyret,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5118,VAL BUECH MEOUGE,5300,VAL BUECH MEOUGE,ANTONAVES,44.2207910151,5.84342231314,118,,Val Buëch-Méouge,Val Buëch-Méouge,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5118,VAL BUECH MEOUGE,5300,VAL BUECH MEOUGE,CHATEAUNEUF DE CHABRE,44.2207910151,5.84342231314,118,,Val Buëch-Méouge,Val Buëch-Méouge,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5118,VAL BUECH MEOUGE,5300,VAL BUECH MEOUGE,RIBIERS,44.2207910151,5.84342231314,118,,Val Buëch-Méouge,Val Buëch-Méouge,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5119,RISOUL,5600,RISOUL,,44.6240380773,6.62317402744,119,,Risoul,Risoul,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5120,RISTOLAS,5460,RISTOLAS,,44.7358493135,6.99202108042,120,,Ristolas,Ristolas,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5121,ROCHEBRUNE,5190,ROCHEBRUNE,,44.452250356,6.19249346455,121,,Rochebrune,Rochebrune,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5122,LA ROCHE DE RAME,5310,LA ROCHE DE RAME,,44.7656681153,6.61644369734,122,La,Roche-de-Rame,La Roche-de-Rame,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5123,LA ROCHE DES ARNAUDS,5400,LA ROCHE DES ARNAUDS,,44.5838170428,5.96045927972,123,La,Roche-des-Arnauds,La Roche-des-Arnauds,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5124,LA ROCHETTE,5000,LA ROCHETTE,,44.5932114107,6.14883098142,124,La,Rochette,La Rochette,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5126,ROSANS,5150,ROSANS,,44.399871954,5.46146531817,126,,Rosans,Rosans,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5127,ROUSSET,5190,ROUSSET,,44.4843785512,6.2666913091,127,,Rousset,Rousset,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5128,ST ANDRE D EMBRUN,5200,ST ANDRE D EMBRUN,,44.5866569322,6.57072142721,128,,Saint-André-d'Embrun,Saint-André-d'Embrun,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5129,ST ANDRE DE ROSANS,5150,ST ANDRE DE ROSANS,,44.3647393809,5.52097371787,129,,Saint-André-de-Rosans,Saint-André-de-Rosans,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5130,ST APOLLINAIRE,5160,ST APOLLINAIRE,,44.5578704136,6.3539003526,130,,Saint-Apollinaire,Saint-Apollinaire,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5131,ST AUBAN D OZE,5400,ST AUBAN D OZE,,44.4883156877,5.84847801019,131,,Saint-Auban-d'Oze,Saint-Auban-d'Oze,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5132,ST BONNET EN CHAMPSAUR,5500,ST BONNET EN CHAMPSAUR,,44.6823269908,6.10286050171,132,,Saint-Bonnet-en-Champsaur,Saint-Bonnet-en-Champsaur,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5132,ST BONNET EN CHAMPSAUR,5500,ST BONNET EN CHAMPSAUR,BENEVENT ET CHARBILLAC,44.6823269908,6.10286050171,132,,Saint-Bonnet-en-Champsaur,Saint-Bonnet-en-Champsaur,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5132,ST BONNET EN CHAMPSAUR,5500,ST BONNET EN CHAMPSAUR,LES INFOURNAS,44.6823269908,6.10286050171,132,,Saint-Bonnet-en-Champsaur,Saint-Bonnet-en-Champsaur,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5133,ST CHAFFREY,5330,ST CHAFFREY,,44.9346479572,6.60139023926,133,,Saint-Chaffrey,Saint-Chaffrey,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5134,ST CLEMENT SUR DURANCE,5600,ST CLEMENT SUR DURANCE,,44.6478034969,6.56074041767,134,,Saint-Clément-sur-Durance,Saint-Clément-sur-Durance,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5135,STE COLOMBE,5700,STE COLOMBE,,44.2889272211,5.66834974647,135,,Sainte-Colombe,Sainte-Colombe,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5136,ST CREPIN,5600,ST CREPIN,,44.7243260485,6.63555027257,136,,Saint-Crépin,Saint-Crépin,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5139,LE DEVOLUY,5250,LE DEVOLUY,,44.6775419763,5.94047368781,139,,Dévoluy,Dévoluy,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5139,LE DEVOLUY,5250,LE DEVOLUY,AGNIERES EN DEVOLUY,44.6775419763,5.94047368781,139,,Dévoluy,Dévoluy,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5139,LE DEVOLUY,5250,LE DEVOLUY,LA CLUSE,44.6775419763,5.94047368781,139,,Dévoluy,Dévoluy,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5139,LE DEVOLUY,5250,LE DEVOLUY,ST DISDIER,44.6775419763,5.94047368781,139,,Dévoluy,Dévoluy,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5139,LE DEVOLUY,5250,LE DEVOLUY,SUPERDEVOLUY,44.6775419763,5.94047368781,139,,Dévoluy,Dévoluy,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5140,ST ETIENNE LE LAUS,5130,ST ETIENNE LE LAUS,,44.5080346822,6.16323058106,140,,Saint-Étienne-le-Laus,Saint-Étienne-le-Laus,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5141,ST EUSEBE EN CHAMPSAUR,5500,ST EUSEBE EN CHAMPSAUR,,44.7224269114,6.03646608621,141,,Saint-Eusèbe-en-Champsaur,Saint-Eusèbe-en-Champsaur,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5142,ST FIRMIN,5800,ST FIRMIN EN VALGODEMARD,,44.7934858148,6.02571058056,142,,Saint-Firmin,Saint-Firmin,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5144,ST JACQUES EN VALGODEMARD,5800,ST JACQUES EN VALGODEMARD,,44.7740637825,6.06964600691,144,,Saint-Jacques-en-Valgodemard,Saint-Jacques-en-Valgodemard,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5145,ST JEAN ST NICOLAS,5260,ST JEAN ST NICOLAS,,44.6744954225,6.23085847467,145,,Saint-Jean-Saint-Nicolas,Saint-Jean-Saint-Nicolas,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5145,ST JEAN ST NICOLAS,5260,ST JEAN ST NICOLAS,LE PONT DU FOSSE,44.6744954225,6.23085847467,145,,Saint-Jean-Saint-Nicolas,Saint-Jean-Saint-Nicolas,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5146,ST JULIEN EN BEAUCHENE,5140,ST JULIEN EN BEAUCHENE,,44.6264570637,5.71979975938,146,,Saint-Julien-en-Beauchêne,Saint-Julien-en-Beauchêne,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5147,ST JULIEN EN CHAMPSAUR,5500,ST JULIEN EN CHAMPSAUR,,44.6685288872,6.129921943,147,,Saint-Julien-en-Champsaur,Saint-Julien-en-Champsaur,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5148,ST LAURENT DU CROS,5500,ST LAURENT DU CROS,,44.6342790978,6.10886829684,148,,Saint-Laurent-du-Cros,Saint-Laurent-du-Cros,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5149,ST LEGER LES MELEZES,5260,ST LEGER LES MELEZES,,44.6468761009,6.21171867053,149,,Saint-Léger-les-Mélèzes,Saint-Léger-les-Mélèzes,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5150,STE MARIE,5150,STE MARIE,,44.4639998729,5.47415675357,150,,Sainte-Marie,Sainte-Marie,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5151,ST MARTIN DE QUEYRIERES,5120,ST MARTIN DE QUEYRIERES,,44.8375257288,6.58255010654,151,,Saint-Martin-de-Queyrières,Saint-Martin-de-Queyrières,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5152,ST MAURICE EN VALGODEMARD,5800,ST MAURICE EN VALGODEMARD,,44.7981863804,6.11278800187,152,,Saint-Maurice-en-Valgodemard,Saint-Maurice-en-Valgodemard,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5153,ST MICHEL DE CHAILLOL,5260,ST MICHEL DE CHAILLOL,,44.6888900905,6.16982010891,153,,Saint-Michel-de-Chaillol,Saint-Michel-de-Chaillol,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5154,ST PIERRE D ARGENCON,5140,ST PIERRE D ARGENCON,,44.5199464873,5.69046512909,154,,Saint-Pierre-d'Argençon,Saint-Pierre-d'Argençon,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5155,ST PIERRE AVEZ,5300,ST PIERRE AVEZ,,44.2485428256,5.76838211104,155,,Saint-Pierre-Avez,Saint-Pierre-Avez,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5156,ST SAUVEUR,5200,ST SAUVEUR,,44.5365820455,6.52920301665,156,,Saint-Sauveur,Saint-Sauveur,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5157,ST VERAN,5350,ST VERAN,,44.684530806,6.8905743766,157,,Saint-Véran,Saint-Véran,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5158,LE SAIX,5400,LE SAIX,,44.4638478254,5.83381616664,158,Le,Saix,Le Saix,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5159,SALEON,5300,SALEON,,44.3201551058,5.77527169353,159,,Saléon,Saléon,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5160,SALERANS,5300,SALERANS,,44.2421298245,5.70096058268,160,,Salérans,Salérans,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5161,LA SALLE LES ALPES,5240,LA SALLE LES ALPES,,44.9526685727,6.5653564178,161,La,Salle-les-Alpes,La Salle-les-Alpes,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5162,LA SAULCE,5110,LA SAULCE,,44.4305124468,6.01099383219,162,La,Saulce,La Saulce,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5163,LE SAUZE DU LAC,5160,LE SAUZE DU LAC,,44.485574244,6.32317639258,163,Le,Sauze-du-Lac,Le Sauze-du-Lac,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5164,SAVINES LE LAC,5160,SAVINES LE LAC,,44.5273256992,6.39368059257,164,,Savines-le-Lac,Savines-le-Lac,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5165,SAVOURNON,5700,SAVOURNON,,44.4240775993,5.79927741464,165,,Savournon,Savournon,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5166,SERRES,5700,SERRES,,44.4213858087,5.71549751124,166,,Serres,Serres,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5167,SIGOTTIER,5700,SIGOTTIER,,44.458049604,5.68531113946,167,,Sigottier,Sigottier,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5168,SIGOYER,5130,SIGOYER,,44.4802333692,5.96951792615,168,,Sigoyer,Sigoyer,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5169,SORBIERS,5150,SORBIERS,,44.3625387102,5.57325324553,169,,Sorbiers,Sorbiers,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5170,TALLARD,5130,TALLARD,,44.459122073,6.04485602868,170,,Tallard,Tallard,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5171,THEUS,5190,THEUS,,44.4852106101,6.19688253047,171,,Théus,Théus,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5172,TRESCLEOUX,5700,TRESCLEOUX,,44.3549508677,5.71825730245,172,,Trescléoux,Trescléoux,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5173,UPAIX,5300,UPAIX,,44.3196073716,5.88311432396,173,,Upaix,Upaix,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5174,VAL DES PRES,5100,VAL DES PRES,,44.9505522191,6.66654834986,174,,Val-des-Prés,Val-des-Prés,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5175,VALLOUISE,5290,VALLOUISE,,44.8308280468,6.41197014257,175,,Vallouise,Vallouise,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5176,VALSERRES,5130,VALSERRES,,44.4892842965,6.13789393583,176,,Valserres,Valserres,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5177,VARS,5560,VARS,,44.5925220925,6.71351456892,177,,Vars,Vars,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5178,VENTAVON,5300,VENTAVON,,44.3856656442,5.88845374579,178,,Ventavon,Ventavon,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5179,VEYNES,5400,VEYNES,,44.5571529708,5.82286002514,179,,Veynes,Veynes,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5180,LES VIGNEAUX,5120,LES VIGNEAUX,,44.8212773186,6.53677172828,180,Les,Vigneaux,Les Vigneaux,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5181,VILLAR D ARENE,5480,VILLAR D ARENE,,44.999721765,6.36428454594,181,,Villar-d'Arêne,Villar-d'Arêne,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5182,VILLAR LOUBIERE,5800,VILLAR LOUBIERE,,44.8367273336,6.13559761308,182,,Villar-Loubière,Villar-Loubière,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5183,VILLAR ST PANCRACE,5100,VILLAR ST PANCRACE,,44.8395685825,6.65048294053,183,,Villar-Saint-Pancrace,Villar-Saint-Pancrace,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
5184,VITROLLES,5110,VITROLLES,,44.4246526523,5.94769008285,184,,Vitrolles,Vitrolles,5,Hautes-Alpes,93,Provence-Alpes-Côte d'Azur
6001,AIGLUN,6910,AIGLUN,,43.8524188,6.934942333,1,,Aiglun,Aiglun,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6002,AMIRAT,6910,AMIRAT,,43.8962973508,6.81255950456,2,,Amirat,Amirat,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6003,ANDON,6750,ANDON,,43.7769856778,6.82318647257,3,,Andon,Andon,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6003,ANDON,6750,ANDON,THORENC,43.7769856778,6.82318647257,3,,Andon,Andon,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6004,ANTIBES,6160,ANTIBES,CAP D ANTIBES,43.587465146,7.10635418256,4,,Antibes,Antibes,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6004,ANTIBES,6160,ANTIBES,JUAN LES PINS,43.587465146,7.10635418256,4,,Antibes,Antibes,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6004,ANTIBES,6600,ANTIBES,,43.587465146,7.10635418256,4,,Antibes,Antibes,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6004,ANTIBES,6600,ANTIBES,LA FONTONNE,43.587465146,7.10635418256,4,,Antibes,Antibes,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6005,ASCROS,6260,ASCROS,,43.9193423,7.01550684755,5,,Ascros,Ascros,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6006,ASPREMONT,6790,ASPREMONT,,43.7750731742,7.24475771199,6,,Aspremont,Aspremont,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6007,AURIBEAU SUR SIAGNE,6810,AURIBEAU SUR SIAGNE,,43.612406867,6.91211356657,7,,Auribeau-sur-Siagne,Auribeau-sur-Siagne,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6008,AUVARE,6260,AUVARE,,44.0074205826,6.91467896828,8,,Auvare,Auvare,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6009,BAIROLS,6420,BAIROLS,,43.9870510207,7.11969473058,9,,Bairols,Bairols,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6010,LE BAR SUR LOUP,6620,LE BAR SUR LOUP,,43.6992943088,6.96272779051,10,Le,Bar-sur-Loup,Le Bar-sur-Loup,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6011,BEAULIEU SUR MER,6310,BEAULIEU SUR MER,,43.7079039397,7.33256934881,11,,Beaulieu-sur-Mer,Beaulieu-sur-Mer,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6012,BEAUSOLEIL,6240,BEAUSOLEIL,,43.747724383,7.42222154586,12,,Beausoleil,Beausoleil,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6013,BELVEDERE,6450,BELVEDERE,,44.0564872239,7.37383120022,13,,Belvédère,Belvédère,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6014,BENDEJUN,6390,BENDEJUN,,43.8303269547,7.28447130629,14,,Bendejun,Bendejun,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6015,BERRE LES ALPES,6390,BERRE LES ALPES,,43.8378738812,7.33076301102,15,,Berre-les-Alpes,Berre-les-Alpes,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6016,BEUIL,6470,BEUIL,,44.1081242884,6.97212458083,16,,Beuil,Beuil,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6017,BEZAUDUN LES ALPES,6510,BEZAUDUN LES ALPES,,43.803621969,7.09357147024,17,,Bézaudun-les-Alpes,Bézaudun-les-Alpes,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6018,BIOT,6410,BIOT,,43.6276558141,7.08318612891,18,,Biot,Biot,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6019,BLAUSASC,6440,BLAUSASC,,43.7934680936,7.36033477624,19,,Blausasc,Blausasc,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6020,LA BOLLENE VESUBIE,6450,LA BOLLENE VESUBIE,,43.9951453194,7.37184375426,20,La,Bollène-Vésubie,La Bollène-Vésubie,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6021,BONSON,6830,BONSON,,43.8622934824,7.1827870979,21,,Bonson,Bonson,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6022,BOUYON,6510,BOUYON,,43.8254399796,7.12553872813,22,,Bouyon,Bouyon,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6023,BREIL SUR ROYA,6540,BREIL SUR ROYA,,43.9404304718,7.50391615024,23,,Breil-sur-Roya,Breil-sur-Roya,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6024,BRIANCONNET,6850,BRIANCONNET,,43.8649498097,6.74501344628,24,,Briançonnet,Briançonnet,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6025,LE BROC,6510,LE BROC,,43.8147822086,7.15907633922,25,Le,Broc,Le Broc,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6026,CABRIS,6530,CABRIS,,43.6599560638,6.87825509814,26,,Cabris,Cabris,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6027,CAGNES SUR MER,6800,CAGNES SUR MER,,43.6715162078,7.15275703379,27,,Cagnes-sur-Mer,Cagnes-sur-Mer,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6027,CAGNES SUR MER,6800,CAGNES SUR MER,CROS DE CAGNES,43.6715162078,7.15275703379,27,,Cagnes-sur-Mer,Cagnes-sur-Mer,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6028,CAILLE,6750,CAILLE,,43.7686953119,6.75666650268,28,,Caille,Caille,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6029,CANNES,6150,CANNES,CANNES LA BOCCA,43.5526202843,7.00427592728,29,,Cannes,Cannes,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6029,CANNES,6400,CANNES,,43.5526202843,7.00427592728,29,,Cannes,Cannes,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6030,LE CANNET,6110,LE CANNET,,43.5729768613,7.00642057758,30,Le,Cannet,Le Cannet,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6030,LE CANNET,6110,LE CANNET,ROCHEVILLE,43.5729768613,7.00642057758,30,Le,Cannet,Le Cannet,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6031,CANTARON,6340,CANTARON,,43.7712030999,7.31316892728,31,,Cantaron,Cantaron,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6032,CAP D AIL,6320,CAP D AIL,,43.7247392327,7.40161617522,32,,Cap-d'Ail,Cap-d'Ail,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6033,CARROS,6510,CARROS,,43.7850390592,7.18414159904,33,,Carros,Carros,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6034,CASTAGNIERS,6670,CASTAGNIERS,,43.7921108913,7.2277413307,34,,Castagniers,Castagniers,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6035,CASTELLAR,6500,CASTELLAR,,43.8220566609,7.49891571145,35,,Castellar,Castellar,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6036,CASTILLON,6500,CASTILLON,,43.8361451105,7.46455247487,36,,Castillon,Castillon,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6037,CAUSSOLS,6460,CAUSSOLS,,43.7373447398,6.90247233198,37,,Caussols,Caussols,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6038,CHATEAUNEUF GRASSE,6740,CHATEAUNEUF GRASSE,,43.6656345765,6.9791405929,38,,Châteauneuf-Grasse,Châteauneuf-Grasse,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6039,CHATEAUNEUF VILLEVIEILLE,6390,CHATEAUNEUF VILLEVIEILLE,,43.7999098278,7.29412423176,39,,Châteauneuf-Villevieille,Châteauneuf-Villevieille,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6040,CHATEAUNEUF D ENTRAUNES,6470,CHATEAUNEUF D ENTRAUNES,,44.1639701619,6.83506555715,40,,Châteauneuf-d'Entraunes,Châteauneuf-d'Entraunes,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6041,CIPIERES,6620,CIPIERES,,43.7695926923,6.92814526285,41,,Cipières,Cipières,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6042,CLANS,6420,CLANS,,44.0049097261,7.17876366942,42,,Clans,Clans,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6043,COARAZE,6390,COARAZE,,43.8664901786,7.29913349222,43,,Coaraze,Coaraze,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6044,LA COLLE SUR LOUP,6480,LA COLLE SUR LOUP,,43.6871701387,7.09773643833,44,La,Colle-sur-Loup,La Colle-sur-Loup,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6045,COLLONGUES,6910,COLLONGUES,,43.8935981177,6.86383142444,45,,Collongues,Collongues,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6046,COLOMARS,6670,COLOMARS,,43.7594234187,7.22263249849,46,,Colomars,Colomars,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6047,CONSEGUDES,6510,CONSEGUDES,,43.8406957218,7.04503670887,47,,Conségudes,Conségudes,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6048,CONTES,6390,CONTES,,43.8092959296,7.3242451162,48,,Contes,Contes,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6049,COURMES,6620,COURMES,,43.7525533793,7.01737606418,49,,Courmes,Courmes,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6050,COURSEGOULES,6140,COURSEGOULES,,43.7915580349,7.03622643471,50,,Coursegoules,Coursegoules,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6051,LA CROIX SUR ROUDOULE,6260,LA CROIX SUR ROUDOULE,,44.0003096653,6.87019911618,51,La,Croix-sur-Roudoule,La Croix-sur-Roudoule,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6052,CUEBRIS,6910,CUEBRIS,,43.8892125828,7.00448794285,52,,Cuébris,Cuébris,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6053,DALUIS,6470,DALUIS,,44.0387244451,6.79997095786,53,,Daluis,Daluis,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6054,DRAP,6340,DRAP,,43.7582432488,7.33724090222,54,,Drap,Drap,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6055,DURANUS,6670,DURANUS,,43.8940350601,7.28022686159,55,,Duranus,Duranus,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6056,ENTRAUNES,6470,ENTRAUNES,,44.2146960621,6.76292204018,56,,Entraunes,Entraunes,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6056,ENTRAUNES,6470,ENTRAUNES,ESTEING,44.2146960621,6.76292204018,56,,Entraunes,Entraunes,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6057,L ESCARENE,6440,L ESCARENE,,43.8339717674,7.36565804143,57,L',Escarène,L'Escarène,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6058,ESCRAGNOLLES,6460,ESCRAGNOLLES,,43.7294033373,6.7833728891,58,,Escragnolles,Escragnolles,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6059,EZE,6360,EZE,,43.7289260198,7.36057188413,59,,Èze,Èze,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6060,FALICON,6950,FALICON,,43.752536584,7.26554536957,60,,Falicon,Falicon,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6061,LES FERRES,6510,LES FERRES,,43.8424232733,7.09342755196,61,Les,Ferres,Les Ferres,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6062,FONTAN,6540,FONTAN,,44.0219052577,7.54428215207,62,,Fontan,Fontan,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6063,GARS,6850,GARS,,43.8605226685,6.81676294521,63,,Gars,Gars,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6064,GATTIERES,6510,GATTIERES,,43.7652195423,7.16877468184,64,,Gattières,Gattières,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6065,LA GAUDE,6610,LA GAUDE,,43.721423765,7.16039926444,65,La,Gaude,La Gaude,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6066,GILETTE,6830,GILETTE,,43.8481962471,7.1613234113,66,,Gilette,Gilette,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6067,GORBIO,6500,GORBIO,,43.7860281963,7.44603824995,67,,Gorbio,Gorbio,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6068,GOURDON,6620,GOURDON,,43.7242493778,6.96356065093,68,,Gourdon,Gourdon,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6069,GRASSE,6130,GRASSE,,43.655639428,6.93190508233,69,,Grasse,Grasse,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6069,GRASSE,6130,GRASSE,LE PLAN DE GRASSE,43.655639428,6.93190508233,69,,Grasse,Grasse,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6069,GRASSE,6130,GRASSE,PLASCASSIER,43.655639428,6.93190508233,69,,Grasse,Grasse,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6069,GRASSE,6520,GRASSE,MAGAGNOSC,43.655639428,6.93190508233,69,,Grasse,Grasse,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6070,GREOLIERES,6620,GREOLIERES,,43.8070006506,6.93196496167,70,,Gréolières,Gréolières,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6071,GUILLAUMES,6470,GUILLAUMES,,44.088068317,6.87493501534,71,,Guillaumes,Guillaumes,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6072,ILONSE,6420,ILONSE,,44.0347982787,7.08976475841,72,,Ilonse,Ilonse,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6073,ISOLA,6420,ISOLA,,44.1872585336,7.07488062255,73,,Isola,Isola,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6074,LANTOSQUE,6450,LANTOSQUE,,43.9706457549,7.30485730116,74,,Lantosque,Lantosque,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6075,LEVENS,6670,LEVENS,,43.8500976014,7.24033027327,75,,Levens,Levens,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6075,LEVENS,6670,LEVENS,PLAN DU VAR,43.8500976014,7.24033027327,75,,Levens,Levens,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6076,LIEUCHE,6260,LIEUCHE,,44.0015669365,7.03241078566,76,,Lieuche,Lieuche,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6077,LUCERAM,6440,LUCERAM,,43.9005726905,7.35265575307,77,,Lucéram,Lucéram,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6077,LUCERAM,6440,LUCERAM,PEIRA CAVA,43.9005726905,7.35265575307,77,,Lucéram,Lucéram,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6078,MALAUSSENE,6710,MALAUSSENE,,43.9153232562,7.14112063451,78,,Malaussène,Malaussène,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6079,MANDELIEU LA NAPOULE,6210,MANDELIEU LA NAPOULE,,43.5380510468,6.91808936542,79,,Mandelieu-la-Napoule,Mandelieu-la-Napoule,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6079,MANDELIEU LA NAPOULE,6210,MANDELIEU LA NAPOULE,LA NAPOULE,43.5380510468,6.91808936542,79,,Mandelieu-la-Napoule,Mandelieu-la-Napoule,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6080,MARIE,6420,MARIE,,44.0346077069,7.15744653179,80,,Marie,Marie,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6081,LE MAS,6910,LE MAS,,43.8369129507,6.85894074749,81,Le,Mas,Le Mas,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6082,MASSOINS,6710,MASSOINS,,43.9477022711,7.12430252381,82,,Massoins,Massoins,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6083,MENTON,6500,MENTON,,43.7908233727,7.49365612374,83,,Menton,Menton,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6084,MOUANS SARTOUX,6370,MOUANS SARTOUX,,43.6185304023,6.96494426706,84,,Mouans-Sartoux,Mouans-Sartoux,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6085,MOUGINS,6250,MOUGINS,,43.5961410556,7.00129444919,85,,Mougins,Mougins,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6086,MOULINET,6380,MOULINET,,43.9536991394,7.41400405447,86,,Moulinet,Moulinet,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6087,LES MUJOULS,6910,LES MUJOULS,,43.8719847668,6.85912279446,87,Les,Mujouls,Les Mujouls,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6088,NICE,6000,NICE,,43.7119992661,7.23826889465,88,,Nice,Nice,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6088,NICE,6100,NICE,,43.7119992661,7.23826889465,88,,Nice,Nice,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6088,NICE,6200,NICE,,43.7119992661,7.23826889465,88,,Nice,Nice,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6088,NICE,6200,NICE,ST ISIDORE,43.7119992661,7.23826889465,88,,Nice,Nice,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6088,NICE,6200,NICE,ST ROMAN DE BELLET,43.7119992661,7.23826889465,88,,Nice,Nice,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6088,NICE,6300,NICE,,43.7119992661,7.23826889465,88,,Nice,Nice,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6089,OPIO,6650,OPIO,,43.6575610719,7.00860864158,89,,Opio,Opio,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6090,PEGOMAS,6580,PEGOMAS,,43.5865553156,6.92224837943,90,,Pégomas,Pégomas,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6091,PEILLE,6440,PEILLE,,43.8064648293,7.41111016741,91,,Peille,Peille,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6091,PEILLE,6440,PEILLE,LA GRAVE DE PEILLE,43.8064648293,7.41111016741,91,,Peille,Peille,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6092,PEILLON,6440,PEILLON,,43.7727352898,7.3714797073,92,,Peillon,Peillon,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6093,LA PENNE,6260,LA PENNE,,43.9246861706,6.94222404957,93,La,Penne,La Penne,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6094,PEONE,6470,PEONE,,44.1299250439,6.92098583862,94,,Péone,Péone,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6094,PEONE,6470,PEONE,VALBERG,44.1299250439,6.92098583862,94,,Péone,Péone,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6095,PEYMEINADE,6530,PEYMEINADE,,43.6305294544,6.88166520884,95,,Peymeinade,Peymeinade,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6096,PIERLAS,6260,PIERLAS,,44.0346760802,7.01697586732,96,,Pierlas,Pierlas,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6097,PIERREFEU,6910,PIERREFEU,,43.8851912789,7.0710081371,97,,Pierrefeu,Pierrefeu,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6098,PUGET ROSTANG,6260,PUGET ROSTANG,,43.9872004758,6.93685582204,98,,Puget-Rostang,Puget-Rostang,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6099,PUGET THENIERS,6260,PUGET THENIERS,,43.9521649219,6.90609311435,99,,Puget-Théniers,Puget-Théniers,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6100,REVEST LES ROCHES,6830,REVEST LES ROCHES,,43.8831297435,7.16778277098,100,,Revest-les-Roches,Revest-les-Roches,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6101,RIGAUD,6260,RIGAUD,,43.9929332886,6.97505083413,101,,Rigaud,Rigaud,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6102,RIMPLAS,6420,RIMPLAS,,44.1027644402,7.14505338526,102,,Rimplas,Rimplas,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6103,ROQUEBILLIERE,6450,ROQUEBILLIERE,,44.0270951827,7.29269467197,103,,Roquebillière,Roquebillière,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6104,ROQUEBRUNE CAP MARTIN,6190,ROQUEBRUNE CAP MARTIN,,43.7638278953,7.4587361611,104,,Roquebrune-Cap-Martin,Roquebrune-Cap-Martin,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6104,ROQUEBRUNE CAP MARTIN,6190,ROQUEBRUNE CAP MARTIN,CARNOLES,43.7638278953,7.4587361611,104,,Roquebrune-Cap-Martin,Roquebrune-Cap-Martin,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6105,ROQUEFORT LES PINS,6330,ROQUEFORT LES PINS,,43.6741732535,7.0530127273,105,,Roquefort-les-Pins,Roquefort-les-Pins,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6106,ROQUESTERON,6910,ROQUESTERON,,43.8677906196,7.02413611655,106,,Roquestéron,Roquestéron,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6107,LA ROQUE EN PROVENCE,6910,LA ROQUE EN PROVENCE,,43.8491534174,6.99011980424,107,La,Roque-en-Provence,La Roque-en-Provence,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6108,LA ROQUETTE SUR SIAGNE,6550,LA ROQUETTE SUR SIAGNE,,43.5849092456,6.95049794824,108,La,Roquette-sur-Siagne,La Roquette-sur-Siagne,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6109,LA ROQUETTE SUR VAR,6670,LA ROQUETTE SUR VAR,,43.8332735566,7.20466243305,109,La,Roquette-sur-Var,La Roquette-sur-Var,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6110,ROUBION,6420,ROUBION,,44.1085047639,7.02802973178,110,,Roubion,Roubion,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6111,ROURE,6420,ROURE,,44.1189138677,7.06680296507,111,,Roure,Roure,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6112,LE ROURET,6650,LE ROURET,,43.6795612779,7.00962553615,112,Le,Rouret,Le Rouret,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6113,STE AGNES,6500,STE AGNES,,43.8055344567,7.46128496982,113,,Sainte-Agnès,Sainte-Agnès,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6114,ST ANDRE DE LA ROCHE,6730,ST ANDRE DE LA ROCHE,,43.745876244,7.28871372647,114,,Saint-André-de-la-Roche,Saint-André-de-la-Roche,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6115,ST ANTONIN,6260,ST ANTONIN,,43.9078444006,6.97661268948,115,,Saint-Antonin,Saint-Antonin,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6116,ST AUBAN,6850,ST AUBAN,,43.8309790119,6.74006506104,116,,Saint-Auban,Saint-Auban,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6117,ST BLAISE,6670,ST BLAISE,,43.8129337899,7.23022336252,117,,Saint-Blaise,Saint-Blaise,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6118,ST CEZAIRE SUR SIAGNE,6530,ST CEZAIRE SUR SIAGNE,,43.6594259137,6.80329376165,118,,Saint-Cézaire-sur-Siagne,Saint-Cézaire-sur-Siagne,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6119,ST DALMAS LE SELVAGE,6660,ST DALMAS LE SELVAGE,,44.2894656829,6.84084539624,119,,Saint-Dalmas-le-Selvage,Saint-Dalmas-le-Selvage,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6120,ST ETIENNE DE TINEE,6660,ST ETIENNE DE TINEE,,44.2491492809,6.92452445063,120,,Saint-Étienne-de-Tinée,Saint-Étienne-de-Tinée,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6120,ST ETIENNE DE TINEE,6660,ST ETIENNE DE TINEE,AURON,44.2491492809,6.92452445063,120,,Saint-Étienne-de-Tinée,Saint-Étienne-de-Tinée,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6121,ST JEAN CAP FERRAT,6230,ST JEAN CAP FERRAT,,43.687178893,7.32993632437,121,,Saint-Jean-Cap-Ferrat,Saint-Jean-Cap-Ferrat,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6122,ST JEANNET,6640,ST JEANNET,,43.758845389,7.14073211756,122,,Saint-Jeannet,Saint-Jeannet,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6123,ST LAURENT DU VAR,6700,ST LAURENT DU VAR,,43.6859625772,7.18218791815,123,,Saint-Laurent-du-Var,Saint-Laurent-du-Var,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6124,ST LEGER,6260,ST LEGER,,44.0026597689,6.82309684994,124,,Saint-Léger,Saint-Léger,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6125,ST MARTIN D ENTRAUNES,6470,ST MARTIN D ENTRAUNES,,44.1494414084,6.74588335024,125,,Saint-Martin-d'Entraunes,Saint-Martin-d'Entraunes,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6126,ST MARTIN DU VAR,6670,ST MARTIN DU VAR,,43.8166172421,7.19929220425,126,,Saint-Martin-du-Var,Saint-Martin-du-Var,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6127,ST MARTIN VESUBIE,6450,ST MARTIN VESUBIE,,44.1020722047,7.30244071608,127,,Saint-Martin-Vésubie,Saint-Martin-Vésubie,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6127,ST MARTIN VESUBIE,6450,ST MARTIN VESUBIE,LE BOREON,44.1020722047,7.30244071608,127,,Saint-Martin-Vésubie,Saint-Martin-Vésubie,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6128,ST PAUL DE VENCE,6570,ST PAUL DE VENCE,,43.6950190291,7.12116951865,128,,Saint-Paul-de-Vence,Saint-Paul-de-Vence,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6129,ST SAUVEUR SUR TINEE,6420,ST SAUVEUR SUR TINEE,,44.1210676615,7.11814485955,129,,Saint-Sauveur-sur-Tinée,Saint-Sauveur-sur-Tinée,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6130,ST VALLIER DE THIEY,6460,ST VALLIER DE THIEY,,43.696895033,6.85062748704,130,,Saint-Vallier-de-Thiey,Saint-Vallier-de-Thiey,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6131,SALLAGRIFFON,6910,SALLAGRIFFON,,43.8807695445,6.91373768806,131,,Sallagriffon,Sallagriffon,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6132,SAORGE,6540,SAORGE,,43.9935318832,7.53767348426,132,,Saorge,Saorge,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6133,SAUZE,6470,SAUZE,,44.0852199876,6.80072324584,133,,Sauze,Sauze,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6134,SERANON,6750,SERANON,,43.7656662777,6.6904329696,134,,Séranon,Séranon,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6134,SERANON,6750,SERANON,LE LOGIS DU PIN,43.7656662777,6.6904329696,134,,Séranon,Séranon,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6135,SIGALE,6910,SIGALE,,43.8757999129,6.96558618108,135,,Sigale,Sigale,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6136,SOSPEL,6380,SOSPEL,,43.8844607415,7.44794904955,136,,Sospel,Sospel,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6137,SPERACEDES,6530,SPERACEDES,,43.6524506812,6.85685670407,137,,Spéracèdes,Spéracèdes,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6138,THEOULE SUR MER,6590,THEOULE SUR MER,,43.4985345439,6.92965248383,138,,Théoule-sur-Mer,Théoule-sur-Mer,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6138,THEOULE SUR MER,6590,THEOULE SUR MER,MIRAMAR,43.4985345439,6.92965248383,138,,Théoule-sur-Mer,Théoule-sur-Mer,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6139,THIERY,6710,THIERY,,43.9741390022,7.0424567137,139,,Thiéry,Thiéry,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6140,LE TIGNET,6530,LE TIGNET,,43.623581463,6.85261791799,140,Le,Tignet,Le Tignet,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6141,TOUDON,6830,TOUDON,,43.8914641921,7.10840770616,141,,Toudon,Toudon,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6142,TOUET DE L ESCARENE,6440,TOUET DE L ESCARENE,,43.8520343234,7.37799210413,142,,Touët-de-l'Escarène,Touët-de-l'Escarène,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6143,TOUET SUR VAR,6710,TOUET SUR VAR,,43.9433123484,7.01347640084,143,,Touët-sur-Var,Touët-sur-Var,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6144,LA TOUR,6420,LA TOUR,,43.9626877314,7.19994914649,144,La,Tour,La Tour,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6145,TOURETTE DU CHATEAU,6830,TOURETTE DU CHATEAU,,43.8782752079,7.13734511498,145,,Tourette-du-Château,Tourette-du-Château,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6146,TOURNEFORT,6420,TOURNEFORT,,43.9413988757,7.15850315093,146,,Tournefort,Tournefort,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6147,TOURRETTE LEVENS,6690,TOURRETTE LEVENS,,43.7860737213,7.2725568209,147,,Tourrette-Levens,Tourrette-Levens,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6148,TOURRETTES SUR LOUP,6140,TOURRETTES SUR LOUP,,43.7174536642,7.03989376281,148,,Tourrettes-sur-Loup,Tourrettes-sur-Loup,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6149,LA TRINITE,6340,LA TRINITE,,43.7433480978,7.33946073911,149,La,Trinité,La Trinité,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6150,LA TURBIE,6320,LA TURBIE,,43.7451419024,7.39791811712,150,La,Turbie,La Turbie,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6151,UTELLE,6450,UTELLE,,43.9273181411,7.23864462941,151,,Utelle,Utelle,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6151,UTELLE,6450,UTELLE,ST JEAN LA RIVIERE,43.9273181411,7.23864462941,151,,Utelle,Utelle,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6152,VALBONNE,6560,VALBONNE,,43.628288325,7.02954476696,152,,Valbonne,Valbonne,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6152,VALBONNE,6560,VALBONNE,SOPHIA ANTIPOLIS,43.628288325,7.02954476696,152,,Valbonne,Valbonne,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6153,VALDEBLORE,6420,VALDEBLORE,,44.1127596533,7.19571891201,153,,Valdeblore,Valdeblore,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6154,VALDEROURE,6750,VALDEROURE,,43.7942044466,6.71003568639,154,,Valderoure,Valderoure,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6155,VALLAURIS,6220,VALLAURIS,,43.5766472999,7.05836612893,155,,Vallauris,Vallauris,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6155,VALLAURIS,6220,VALLAURIS,LE GOLFE JUAN,43.5766472999,7.05836612893,155,,Vallauris,Vallauris,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6156,VENANSON,6450,VENANSON,,44.0413364561,7.23918508448,156,,Venanson,Venanson,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6157,VENCE,6140,VENCE,,43.7384640641,7.10194436087,157,,Vence,Vence,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6158,VILLARS SUR VAR,6710,VILLARS SUR VAR,,43.9518663257,7.08024521071,158,,Villars-sur-Var,Villars-sur-Var,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6159,VILLEFRANCHE SUR MER,6230,VILLEFRANCHE SUR MER,,43.7122153121,7.32225410533,159,,Villefranche-sur-Mer,Villefranche-sur-Mer,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6160,VILLENEUVE D ENTRAUNES,6470,VILLENEUVE D ENTRAUNES,,44.1221416341,6.78190515159,160,,Villeneuve-d'Entraunes,Villeneuve-d'Entraunes,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6161,VILLENEUVE LOUBET,6270,VILLENEUVE LOUBET,,43.6492560734,7.10728434873,161,,Villeneuve-Loubet,Villeneuve-Loubet,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6162,LA BRIGUE,6430,LA BRIGUE,,44.0714555606,7.65147828925,162,La,Brigue,La Brigue,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6163,TENDE,6430,TENDE,,44.0997669286,7.54053179417,163,,Tende,Tende,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
6163,TENDE,6430,TENDE,ST DALMAS DE TENDE,44.0997669286,7.54053179417,163,,Tende,Tende,6,Alpes-Maritimes,93,Provence-Alpes-Côte d'Azur
7001,ACCONS,7160,ACCONS,,44.8885328213,4.39242968382,1,,Accons,Accons,7,Ardèche,84,Auvergne-Rhône-Alpes
7002,AILHON,7200,AILHON,,44.5986106793,4.33305809833,2,,Ailhon,Ailhon,7,Ardèche,84,Auvergne-Rhône-Alpes
7003,AIZAC,7530,AIZAC,,44.7196762414,4.3306729158,3,,Aizac,Aizac,7,Ardèche,84,Auvergne-Rhône-Alpes
7004,AJOUX,7000,AJOUX,,44.7550573905,4.48565370819,4,,Ajoux,Ajoux,7,Ardèche,84,Auvergne-Rhône-Alpes
7005,ALBA LA ROMAINE,7400,ALBA LA ROMAINE,,44.5544439142,4.59597695147,5,,Alba-la-Romaine,Alba-la-Romaine,7,Ardèche,84,Auvergne-Rhône-Alpes
7006,ALBON D ARDECHE,7190,ALBON D ARDECHE,,44.8152938105,4.42503897561,6,,Albon-d'Ardèche,Albon-d'Ardèche,7,Ardèche,84,Auvergne-Rhône-Alpes
7007,ALBOUSSIERE,7440,ALBOUSSIERE,,44.9397584607,4.73413213329,7,,Alboussière,Alboussière,7,Ardèche,84,Auvergne-Rhône-Alpes
7008,ALISSAS,7210,ALISSAS,,44.7070337034,4.6240636083,8,,Alissas,Alissas,7,Ardèche,84,Auvergne-Rhône-Alpes
7009,ANDANCE,7340,ANDANCE,,45.2279590632,4.79738593664,9,,Andance,Andance,7,Ardèche,84,Auvergne-Rhône-Alpes
7010,ANNONAY,7100,ANNONAY,,45.2460902392,4.65026947295,10,,Annonay,Annonay,7,Ardèche,84,Auvergne-Rhône-Alpes
7011,ANTRAIGUES SUR VOLANE,7530,ANTRAIGUES SUR VOLANE,,44.7393183247,4.35517589387,11,,Antraigues-sur-Volane,Antraigues-sur-Volane,7,Ardèche,84,Auvergne-Rhône-Alpes
7012,ARCENS,7310,ARCENS,,44.9005086707,4.31778204029,12,,Arcens,Arcens,7,Ardèche,84,Auvergne-Rhône-Alpes
7013,ARDOIX,7290,ARDOIX,,45.1913605655,4.74320427692,13,,Ardoix,Ardoix,7,Ardèche,84,Auvergne-Rhône-Alpes
7014,ARLEBOSC,7410,ARLEBOSC,,45.0403037816,4.63051185018,14,,Arlebosc,Arlebosc,7,Ardèche,84,Auvergne-Rhône-Alpes
7015,ARRAS SUR RHONE,7370,ARRAS SUR RHONE,,45.1436776654,4.8036312124,15,,Arras-sur-Rhône,Arras-sur-Rhône,7,Ardèche,84,Auvergne-Rhône-Alpes
7016,ASPERJOC,7600,ASPERJOC,,44.6950645443,4.35089629456,16,,Asperjoc,Asperjoc,7,Ardèche,84,Auvergne-Rhône-Alpes
7017,LES ASSIONS,7140,LES ASSIONS,,44.4254776855,4.1848933711,17,Les,Assions,Les Assions,7,Ardèche,84,Auvergne-Rhône-Alpes
7018,ASTET,7330,ASTET,,44.6847057844,4.02649606307,18,,Astet,Astet,7,Ardèche,84,Auvergne-Rhône-Alpes
7019,AUBENAS,7200,AUBENAS,,44.6102127084,4.39638981424,19,,Aubenas,Aubenas,7,Ardèche,84,Auvergne-Rhône-Alpes
7020,AUBIGNAS,7400,AUBIGNAS,,44.5854785853,4.63389075909,20,,Aubignas,Aubignas,7,Ardèche,84,Auvergne-Rhône-Alpes
7022,BAIX,7210,BAIX,,44.7063788805,4.75106497533,22,,Baix,Baix,7,Ardèche,84,Auvergne-Rhône-Alpes
7023,BALAZUC,7120,BALAZUC,,44.5082860556,4.37244725798,23,,Balazuc,Balazuc,7,Ardèche,84,Auvergne-Rhône-Alpes
7024,BANNE,7460,BANNE,,44.3607782702,4.15113804507,24,,Banne,Banne,7,Ardèche,84,Auvergne-Rhône-Alpes
7025,BARNAS,7330,BARNAS,,44.6689664287,4.15925851766,25,,Barnas,Barnas,7,Ardèche,84,Auvergne-Rhône-Alpes
7026,LE BEAGE,7630,LE BEAGE,,44.8556634675,4.13043465741,26,Le,Béage,Le Béage,7,Ardèche,84,Auvergne-Rhône-Alpes
7027,BEAUCHASTEL,7800,BEAUCHASTEL,,44.8294995047,4.79715382132,27,,Beauchastel,Beauchastel,7,Ardèche,84,Auvergne-Rhône-Alpes
7028,BEAULIEU,7460,BEAULIEU,,44.3546311078,4.25062082781,28,,Beaulieu,Beaulieu,7,Ardèche,84,Auvergne-Rhône-Alpes
7029,BEAUMONT,7110,BEAUMONT,,44.5426503498,4.17181045381,29,,Beaumont,Beaumont,7,Ardèche,84,Auvergne-Rhône-Alpes
7030,BEAUVENE,7190,BEAUVENE,,44.8695132682,4.51162362429,30,,Beauvène,Beauvène,7,Ardèche,84,Auvergne-Rhône-Alpes
7031,BERRIAS ET CASTELJAU,7460,BERRIAS ET CASTELJAU,,44.3832302552,4.20749715425,31,,Berrias-et-Casteljau,Berrias-et-Casteljau,7,Ardèche,84,Auvergne-Rhône-Alpes
7031,BERRIAS ET CASTELJAU,7460,BERRIAS ET CASTELJAU,CASTELJAU,44.3832302552,4.20749715425,31,,Berrias-et-Casteljau,Berrias-et-Casteljau,7,Ardèche,84,Auvergne-Rhône-Alpes
7032,BERZEME,7580,BERZEME,,44.6552336996,4.57203294139,32,,Berzème,Berzème,7,Ardèche,84,Auvergne-Rhône-Alpes
7033,BESSAS,7150,BESSAS,,44.3375930033,4.2983262809,33,,Bessas,Bessas,7,Ardèche,84,Auvergne-Rhône-Alpes
7034,BIDON,7700,BIDON,,44.3567960749,4.54452567772,34,,Bidon,Bidon,7,Ardèche,84,Auvergne-Rhône-Alpes
7035,BOFFRES,7440,BOFFRES,,44.919020401,4.69606511142,35,,Boffres,Boffres,7,Ardèche,84,Auvergne-Rhône-Alpes
7036,BOGY,7340,BOGY,,45.2801067144,4.76743317012,36,,Bogy,Bogy,7,Ardèche,84,Auvergne-Rhône-Alpes
7037,BOREE,7310,BOREE,,44.8950782143,4.22692375234,37,,Borée,Borée,7,Ardèche,84,Auvergne-Rhône-Alpes
7038,BORNE,7590,BORNE,,44.6172223655,4.04171006026,38,,Borne,Borne,7,Ardèche,84,Auvergne-Rhône-Alpes
7039,BOZAS,7410,BOZAS,,45.0587357457,4.64835888675,39,,Bozas,Bozas,7,Ardèche,84,Auvergne-Rhône-Alpes
7040,BOUCIEU LE ROI,7270,BOUCIEU LE ROI,,45.0288428449,4.68820163954,40,,Boucieu-le-Roi,Boucieu-le-Roi,7,Ardèche,84,Auvergne-Rhône-Alpes
7041,BOULIEU LES ANNONAY,7100,BOULIEU LES ANNONAY,,45.2673233399,4.64536894386,41,,Boulieu-lès-Annonay,Boulieu-lès-Annonay,7,Ardèche,84,Auvergne-Rhône-Alpes
7042,BOURG ST ANDEOL,7700,BOURG ST ANDEOL,,44.3830866916,4.61398365069,42,,Bourg-Saint-Andéol,Bourg-Saint-Andéol,7,Ardèche,84,Auvergne-Rhône-Alpes
7044,BROSSAINC,7340,BROSSAINC,,45.3271714387,4.67851483083,44,,Brossainc,Brossainc,7,Ardèche,84,Auvergne-Rhône-Alpes
7045,BURZET,7450,BURZET,,44.748232889,4.23385605882,45,,Burzet,Burzet,7,Ardèche,84,Auvergne-Rhône-Alpes
7047,CELLIER DU LUC,7590,CELLIER DU LUC,,44.6734677534,3.90176725594,47,,Cellier-du-Luc,Cellier-du-Luc,7,Ardèche,84,Auvergne-Rhône-Alpes
7048,CHALENCON,7240,CHALENCON,,44.8797912682,4.55772069308,48,,Chalencon,Chalencon,7,Ardèche,84,Auvergne-Rhône-Alpes
7049,LE CHAMBON,7160,LE CHAMBON,,44.8299065845,4.29925007724,49,Le,Chambon,Le Chambon,7,Ardèche,84,Auvergne-Rhône-Alpes
7050,CHAMBONAS,7140,CHAMBONAS,,44.4263051358,4.12909504882,50,,Chambonas,Chambonas,7,Ardèche,84,Auvergne-Rhône-Alpes
7051,CHAMPAGNE,7340,CHAMPAGNE,,45.2767867383,4.79826211581,51,,Champagne,Champagne,7,Ardèche,84,Auvergne-Rhône-Alpes
7052,CHAMPIS,7440,CHAMPIS,,44.9556198694,4.75241851344,52,,Champis,Champis,7,Ardèche,84,Auvergne-Rhône-Alpes
7053,CHANDOLAS,7230,CHANDOLAS,,44.4136372398,4.24331144158,53,,Chandolas,Chandolas,7,Ardèche,84,Auvergne-Rhône-Alpes
7054,CHANEAC,7310,CHANEAC,,44.9300424452,4.30692688194,54,,Chanéac,Chanéac,7,Ardèche,84,Auvergne-Rhône-Alpes
7055,CHARMES SUR RHONE,7800,CHARMES SUR RHONE,,44.8667597066,4.83342054458,55,,Charmes-sur-Rhône,Charmes-sur-Rhône,7,Ardèche,84,Auvergne-Rhône-Alpes
7056,CHARNAS,7340,CHARNAS,,45.342858843,4.73157804984,56,,Charnas,Charnas,7,Ardèche,84,Auvergne-Rhône-Alpes
7058,CHASSIERS,7110,CHASSIERS,,44.5660636279,4.30216796986,58,,Chassiers,Chassiers,7,Ardèche,84,Auvergne-Rhône-Alpes
7059,CHATEAUBOURG,7130,CHATEAUBOURG,,44.9874244104,4.83836116211,59,,Châteaubourg,Châteaubourg,7,Ardèche,84,Auvergne-Rhône-Alpes
7060,CHATEAUNEUF DE VERNOUX,7240,CHATEAUNEUF DE VERNOUX,,44.9249240478,4.64219110032,60,,Châteauneuf-de-Vernoux,Châteauneuf-de-Vernoux,7,Ardèche,84,Auvergne-Rhône-Alpes
7061,CHAUZON,7120,CHAUZON,,44.4913888491,4.34449609751,61,,Chauzon,Chauzon,7,Ardèche,84,Auvergne-Rhône-Alpes
7062,CHAZEAUX,7110,CHAZEAUX,,44.5951061566,4.2977955993,62,,Chazeaux,Chazeaux,7,Ardèche,84,Auvergne-Rhône-Alpes
7063,CHEMINAS,7300,CHEMINAS,,45.1307657233,4.74087907307,63,,Cheminas,Cheminas,7,Ardèche,84,Auvergne-Rhône-Alpes
7064,LE CHEYLARD,7160,LE CHEYLARD,,44.9076391858,4.42108479359,64,Le,Cheylard,Le Cheylard,7,Ardèche,84,Auvergne-Rhône-Alpes
7065,CHIROLS,7380,CHIROLS,,44.6879981327,4.2951862476,65,,Chirols,Chirols,7,Ardèche,84,Auvergne-Rhône-Alpes
7066,CHOMERAC,7210,CHOMERAC,,44.7102364616,4.67022375795,66,,Chomérac,Chomérac,7,Ardèche,84,Auvergne-Rhône-Alpes
7067,COLOMBIER LE CARDINAL,7430,COLOMBIER LE CARDINAL,,45.2645730149,4.74565260834,67,,Colombier-le-Cardinal,Colombier-le-Cardinal,7,Ardèche,84,Auvergne-Rhône-Alpes
7068,COLOMBIER LE JEUNE,7270,COLOMBIER LE JEUNE,,45.0115690732,4.71536623274,68,,Colombier-le-Jeune,Colombier-le-Jeune,7,Ardèche,84,Auvergne-Rhône-Alpes
7069,COLOMBIER LE VIEUX,7410,COLOMBIER LE VIEUX,,45.0590148291,4.69898079433,69,,Colombier-le-Vieux,Colombier-le-Vieux,7,Ardèche,84,Auvergne-Rhône-Alpes
7070,CORNAS,7130,CORNAS,,44.9664601048,4.84063933956,70,,Cornas,Cornas,7,Ardèche,84,Auvergne-Rhône-Alpes
7071,COUCOURON,7470,COUCOURON,,44.7949192399,3.96166497009,71,,Coucouron,Coucouron,7,Ardèche,84,Auvergne-Rhône-Alpes
7072,COUX,7000,COUX,,44.7432838199,4.62306333755,72,,Coux,Coux,7,Ardèche,84,Auvergne-Rhône-Alpes
7073,LE CRESTET,7270,LE CRESTET,,45.0098651232,4.63903742378,73,Le,Crestet,Le Crestet,7,Ardèche,84,Auvergne-Rhône-Alpes
7074,CREYSSEILLES,7000,CREYSSEILLES,,44.7598496931,4.53023781121,74,,Creysseilles,Creysseilles,7,Ardèche,84,Auvergne-Rhône-Alpes
7075,CROS DE GEORAND,7510,CROS DE GEORAND,,44.7994633979,4.12786466116,75,,Cros-de-Géorand,Cros-de-Géorand,7,Ardèche,84,Auvergne-Rhône-Alpes
7075,CROS DE GEORAND,7630,CROS DE GEORAND,,44.7994633979,4.12786466116,75,,Cros-de-Géorand,Cros-de-Géorand,7,Ardèche,84,Auvergne-Rhône-Alpes
7076,CRUAS,7350,CRUAS,,44.6566420857,4.75658824385,76,,Cruas,Cruas,7,Ardèche,84,Auvergne-Rhône-Alpes
7077,DARBRES,7170,DARBRES,,44.6599554077,4.51592801942,77,,Darbres,Darbres,7,Ardèche,84,Auvergne-Rhône-Alpes
7078,DAVEZIEUX,7430,DAVEZIEUX,,45.2551141043,4.70210482466,78,,Davézieux,Davézieux,7,Ardèche,84,Auvergne-Rhône-Alpes
7079,DESAIGNES,7570,DESAIGNES,,44.992108782,4.50096602027,79,,Désaignes,Désaignes,7,Ardèche,84,Auvergne-Rhône-Alpes
7080,DEVESSET,7320,DEVESSET,,45.0660987902,4.39288168953,80,,Devesset,Devesset,7,Ardèche,84,Auvergne-Rhône-Alpes
7081,DOMPNAC,7260,DOMPNAC,,44.5628656588,4.1071679513,81,,Dompnac,Dompnac,7,Ardèche,84,Auvergne-Rhône-Alpes
7082,DORNAS,7160,DORNAS,,44.8519150741,4.35842422121,82,,Dornas,Dornas,7,Ardèche,84,Auvergne-Rhône-Alpes
7083,DUNIERE SUR EYRIEUX,7360,DUNIERE SUR EYRIEUX,,44.8344426554,4.65115576904,83,,Dunière-sur-Eyrieux,Dunière-sur-Eyrieux,7,Ardèche,84,Auvergne-Rhône-Alpes
7084,ECLASSAN,7370,ECLASSAN,,45.1606533164,4.74888485543,84,,Eclassan,Eclassan,7,Ardèche,84,Auvergne-Rhône-Alpes
7085,EMPURANY,7270,EMPURANY,,45.026338574,4.59724737009,85,,Empurany,Empurany,7,Ardèche,84,Auvergne-Rhône-Alpes
7086,ETABLES,7300,ETABLES,,45.0948570024,4.73950529738,86,,Étables,Étables,7,Ardèche,84,Auvergne-Rhône-Alpes
7087,FABRAS,7380,FABRAS,,44.6484389575,4.28119591056,87,,Fabras,Fabras,7,Ardèche,84,Auvergne-Rhône-Alpes
7088,FAUGERES,7230,FAUGERES,,44.4791202629,4.13971608703,88,,Faugères,Faugères,7,Ardèche,84,Auvergne-Rhône-Alpes
7089,FELINES,7340,FELINES,,45.3160676288,4.72706276274,89,,Félines,Félines,7,Ardèche,84,Auvergne-Rhône-Alpes
7090,FLAVIAC,7000,FLAVIAC,,44.7507352776,4.66001992522,90,,Flaviac,Flaviac,7,Ardèche,84,Auvergne-Rhône-Alpes
7091,FONS,7200,FONS,,44.5814496268,4.34709805042,91,,Fons,Fons,7,Ardèche,84,Auvergne-Rhône-Alpes
7092,FREYSSENET,7000,FREYSSENET,,44.6886194228,4.54695114844,92,,Freyssenet,Freyssenet,7,Ardèche,84,Auvergne-Rhône-Alpes
7093,GENESTELLE,7530,GENESTELLE,,44.7366148286,4.38522824539,93,,Genestelle,Genestelle,7,Ardèche,84,Auvergne-Rhône-Alpes
7094,GILHAC ET BRUZAC,7800,GILHAC ET BRUZAC,,44.8632410732,4.74540308425,94,,Gilhac-et-Bruzac,Gilhac-et-Bruzac,7,Ardèche,84,Auvergne-Rhône-Alpes
7095,GILHOC SUR ORMEZE,7270,GILHOC SUR ORMEZE,,44.9855282825,4.67742067444,95,,Gilhoc-sur-Ormèze,Gilhoc-sur-Ormèze,7,Ardèche,84,Auvergne-Rhône-Alpes
7096,GLUIRAS,7190,GLUIRAS,,44.8455602063,4.52583208054,96,,Gluiras,Gluiras,7,Ardèche,84,Auvergne-Rhône-Alpes
7097,GLUN,7300,GLUN,,45.0070163516,4.82465206981,97,,Glun,Glun,7,Ardèche,84,Auvergne-Rhône-Alpes
7098,GOURDON,7000,GOURDON,,44.7294058074,4.45478498235,98,,Gourdon,Gourdon,7,Ardèche,84,Auvergne-Rhône-Alpes
7099,GRAS,7700,GRAS,,44.4453699955,4.5342825809,99,,Gras,Gras,7,Ardèche,84,Auvergne-Rhône-Alpes
7100,GRAVIERES,7140,GRAVIERES,,44.4213974082,4.07179678867,100,,Gravières,Gravières,7,Ardèche,84,Auvergne-Rhône-Alpes
7101,GROSPIERRES,7120,GROSPIERRES,,44.3930827383,4.28692737418,101,,Grospierres,Grospierres,7,Ardèche,84,Auvergne-Rhône-Alpes
7102,GUILHERAND GRANGES,7500,GUILHERAND GRANGES,,44.9301318307,4.86629603942,102,,Guilherand-Granges,Guilherand-Granges,7,Ardèche,84,Auvergne-Rhône-Alpes
7103,INTRES,7310,INTRES,,44.9864739922,4.3441511813,103,,Intres,Intres,7,Ardèche,84,Auvergne-Rhône-Alpes
7103,INTRES,7320,INTRES,,44.9864739922,4.3441511813,103,,Intres,Intres,7,Ardèche,84,Auvergne-Rhône-Alpes
7104,ISSAMOULENC,7190,ISSAMOULENC,,44.7883137241,4.45570599159,104,,Issamoulenc,Issamoulenc,7,Ardèche,84,Auvergne-Rhône-Alpes
7105,ISSANLAS,7510,ISSANLAS,,44.7669383227,4.01543417126,105,,Issanlas,Issanlas,7,Ardèche,84,Auvergne-Rhône-Alpes
7105,ISSANLAS,7660,ISSANLAS,,44.7669383227,4.01543417126,105,,Issanlas,Issanlas,7,Ardèche,84,Auvergne-Rhône-Alpes
7106,ISSARLES,7470,ISSARLES,,44.8523471555,4.05339793965,106,,Issarlès,Issarlès,7,Ardèche,84,Auvergne-Rhône-Alpes
7107,JAUJAC,7380,JAUJAC,,44.6298203979,4.23815139749,107,,Jaujac,Jaujac,7,Ardèche,84,Auvergne-Rhône-Alpes
7108,JAUNAC,7160,JAUNAC,,44.9198243034,4.39270254716,108,,Jaunac,Jaunac,7,Ardèche,84,Auvergne-Rhône-Alpes
7109,JOANNAS,7110,JOANNAS,,44.5725478202,4.24633374271,109,,Joannas,Joannas,7,Ardèche,84,Auvergne-Rhône-Alpes
7110,JOYEUSE,7260,JOYEUSE,,44.470531994,4.24753667585,110,,Joyeuse,Joyeuse,7,Ardèche,84,Auvergne-Rhône-Alpes
7111,JUVINAS,7600,JUVINAS,,44.7043758122,4.3072836289,111,,Juvinas,Juvinas,7,Ardèche,84,Auvergne-Rhône-Alpes
7112,LABASTIDE SUR BESORGUES,7600,LABASTIDE SUR BESORGUES,,44.7487657631,4.29812295465,112,,Labastide-sur-Bésorgues,Labastide-sur-Bésorgues,7,Ardèche,84,Auvergne-Rhône-Alpes
7113,LABASTIDE DE VIRAC,7150,LABASTIDE DE VIRAC,,44.3533257557,4.41978848183,113,,Labastide-de-Virac,Labastide-de-Virac,7,Ardèche,84,Auvergne-Rhône-Alpes
7114,LABATIE D ANDAURE,7570,LABATIE D ANDAURE,,45.0327655678,4.49525714027,114,,Labatie-d'Andaure,Labatie-d'Andaure,7,Ardèche,84,Auvergne-Rhône-Alpes
7115,LABEAUME,7120,LABEAUME,,44.4680870605,4.31105082566,115,,Labeaume,Labeaume,7,Ardèche,84,Auvergne-Rhône-Alpes
7116,LABEGUDE,7200,LABEGUDE,,44.6420481125,4.35738052578,116,,Labégude,Labégude,7,Ardèche,84,Auvergne-Rhône-Alpes
7117,LABLACHERE,7230,LABLACHERE,,44.4602290219,4.21397084394,117,,Lablachère,Lablachère,7,Ardèche,84,Auvergne-Rhône-Alpes
7118,LABOULE,7110,LABOULE,,44.5955201697,4.16972538359,118,,Laboule,Laboule,7,Ardèche,84,Auvergne-Rhône-Alpes
7119,LE LAC D ISSARLES,7470,LE LAC D ISSARLES,,44.8233324216,4.07465283031,119,Le,Lac-d'Issarlès,Le Lac-d'Issarlès,7,Ardèche,84,Auvergne-Rhône-Alpes
7120,LACHAMP RAPHAEL,7530,LACHAMP RAPHAEL,,44.8006451695,4.28759440522,120,,Lachamp-Raphaël,Lachamp-Raphaël,7,Ardèche,84,Auvergne-Rhône-Alpes
7121,LACHAPELLE GRAILLOUSE,7470,LACHAPELLE GRAILLOUSE,,44.8117618119,4.01886475359,121,,Lachapelle-Graillouse,Lachapelle-Graillouse,7,Ardèche,84,Auvergne-Rhône-Alpes
7122,LACHAPELLE SOUS AUBENAS,7200,LACHAPELLE SOUS AUBENAS,,44.5622616562,4.35906947518,122,,Lachapelle-sous-Aubenas,Lachapelle-sous-Aubenas,7,Ardèche,84,Auvergne-Rhône-Alpes
7123,LACHAPELLE SOUS CHANEAC,7310,LACHAPELLE SOUS CHANEAC,,44.9541324494,4.31904223748,123,,Lachapelle-sous-Chanéac,Lachapelle-sous-Chanéac,7,Ardèche,84,Auvergne-Rhône-Alpes
7124,LAFARRE,7520,LAFARRE,,45.0784792733,4.51659927273,124,,Lafarre,Lafarre,7,Ardèche,84,Auvergne-Rhône-Alpes
7126,LAGORCE,7150,LAGORCE,,44.4449016448,4.43141639823,126,,Lagorce,Lagorce,7,Ardèche,84,Auvergne-Rhône-Alpes
7127,LALEVADE D ARDECHE,7380,LALEVADE D ARDECHE,,44.6521200865,4.31392373316,127,,Lalevade-d'Ardèche,Lalevade-d'Ardèche,7,Ardèche,84,Auvergne-Rhône-Alpes
7128,LALOUVESC,7520,LALOUVESC,,45.1156584818,4.52555631475,128,,Lalouvesc,Lalouvesc,7,Ardèche,84,Auvergne-Rhône-Alpes
7129,LAMASTRE,7270,LAMASTRE,,44.9822539936,4.59081075503,129,,Lamastre,Lamastre,7,Ardèche,84,Auvergne-Rhône-Alpes
7130,LANARCE,7660,LANARCE,,44.7292437972,3.99688544753,130,,Lanarce,Lanarce,7,Ardèche,84,Auvergne-Rhône-Alpes
7131,LANAS,7200,LANAS,,44.5369264194,4.38390095509,131,,Lanas,Lanas,7,Ardèche,84,Auvergne-Rhône-Alpes
7132,LARGENTIERE,7110,LARGENTIERE,,44.5338484702,4.30128054451,132,,Largentière,Largentière,7,Ardèche,84,Auvergne-Rhône-Alpes
7133,LARNAS,7220,LARNAS,,44.4543456079,4.59606913482,133,,Larnas,Larnas,7,Ardèche,84,Auvergne-Rhône-Alpes
7134,LAURAC EN VIVARAIS,7110,LAURAC EN VIVARAIS,,44.5033057192,4.28830492174,134,,Laurac-en-Vivarais,Laurac-en-Vivarais,7,Ardèche,84,Auvergne-Rhône-Alpes
7135,LAVAL D AURELLE,7590,LAVAL D AURELLE,,44.5722739306,3.97264927735,135,,Laval-d'Aurelle,Laval-d'Aurelle,7,Ardèche,84,Auvergne-Rhône-Alpes
7136,LAVEYRUNE,48250,LAVEYRUNE,,44.6207258816,3.91933819175,136,,Laveyrune,Laveyrune,7,Ardèche,84,Auvergne-Rhône-Alpes
7137,LAVILLATTE,7660,LAVILLATTE,,44.7411283435,3.95196079179,137,,Lavillatte,Lavillatte,7,Ardèche,84,Auvergne-Rhône-Alpes
7138,LAVILLEDIEU,7170,LAVILLEDIEU,,44.5821673927,4.44782050169,138,,Lavilledieu,Lavilledieu,7,Ardèche,84,Auvergne-Rhône-Alpes
7139,LAVIOLLE,7530,LAVIOLLE,,44.7621284676,4.33354571625,139,,Laviolle,Laviolle,7,Ardèche,84,Auvergne-Rhône-Alpes
7140,LEMPS,7610,LEMPS,,45.0972922386,4.778993534,140,,Lemps,Lemps,7,Ardèche,84,Auvergne-Rhône-Alpes
7141,LENTILLERES,7200,LENTILLERES,,44.6092419896,4.29820132309,141,,Lentillères,Lentillères,7,Ardèche,84,Auvergne-Rhône-Alpes
7142,LESPERON,7660,LESPERON,,44.7342873614,3.89547616799,142,,Lespéron,Lespéron,7,Ardèche,84,Auvergne-Rhône-Alpes
7143,LIMONY,7340,LIMONY,,45.3534326814,4.75026547747,143,,Limony,Limony,7,Ardèche,84,Auvergne-Rhône-Alpes
7144,LOUBARESSE,7110,LOUBARESSE,,44.5912762985,4.05088477316,144,,Loubaresse,Loubaresse,7,Ardèche,84,Auvergne-Rhône-Alpes
7145,LUSSAS,7170,LUSSAS,,44.6192177092,4.46630461903,145,,Lussas,Lussas,7,Ardèche,84,Auvergne-Rhône-Alpes
7146,LYAS,7000,LYAS,,44.7568405764,4.59691440375,146,,Lyas,Lyas,7,Ardèche,84,Auvergne-Rhône-Alpes
7147,MALARCE SUR LA THINES,7140,MALARCE SUR LA THINES,,44.4780272206,4.05011924136,147,,Malarce-sur-la-Thines,Malarce-sur-la-Thines,7,Ardèche,84,Auvergne-Rhône-Alpes
7147,MALARCE SUR LA THINES,7140,MALARCE SUR LA THINES,LAFIGERE,44.4780272206,4.05011924136,147,,Malarce-sur-la-Thines,Malarce-sur-la-Thines,7,Ardèche,84,Auvergne-Rhône-Alpes
7147,MALARCE SUR LA THINES,7140,MALARCE SUR LA THINES,THINES,44.4780272206,4.05011924136,147,,Malarce-sur-la-Thines,Malarce-sur-la-Thines,7,Ardèche,84,Auvergne-Rhône-Alpes
7148,MALBOSC,7140,MALBOSC,,44.349514949,4.07246027808,148,,Malbosc,Malbosc,7,Ardèche,84,Auvergne-Rhône-Alpes
7149,MARCOLS LES EAUX,7190,MARCOLS LES EAUX,,44.8058528887,4.3890569537,149,,Marcols-les-Eaux,Marcols-les-Eaux,7,Ardèche,84,Auvergne-Rhône-Alpes
7150,MARIAC,7160,MARIAC,,44.8859638189,4.35337034088,150,,Mariac,Mariac,7,Ardèche,84,Auvergne-Rhône-Alpes
7151,MARS,7320,MARS,,45.0104543843,4.3290005107,151,,Mars,Mars,7,Ardèche,84,Auvergne-Rhône-Alpes
7152,MAUVES,7300,MAUVES,,45.0288028345,4.81953244192,152,,Mauves,Mauves,7,Ardèche,84,Auvergne-Rhône-Alpes
7153,MAYRES,7330,MAYRES,,44.6646143476,4.10092674787,153,,Mayres,Mayres,7,Ardèche,84,Auvergne-Rhône-Alpes
7154,MAZAN L ABBAYE,7510,MAZAN L ABBAYE,,44.7246364592,4.08102609773,154,,Mazan-l'Abbaye,Mazan-l'Abbaye,7,Ardèche,84,Auvergne-Rhône-Alpes
7155,MERCUER,7200,MERCUER,,44.6260697061,4.35077904415,155,,Mercuer,Mercuer,7,Ardèche,84,Auvergne-Rhône-Alpes
7156,MEYRAS,7380,MEYRAS,,44.677562906,4.26001410237,156,,Meyras,Meyras,7,Ardèche,84,Auvergne-Rhône-Alpes
7157,MEYSSE,7400,MEYSSE,,44.6193386638,4.71649252868,157,,Meysse,Meysse,7,Ardèche,84,Auvergne-Rhône-Alpes
7158,MEZILHAC,7530,MEZILHAC,,44.8068921216,4.34184721755,158,,Mézilhac,Mézilhac,7,Ardèche,84,Auvergne-Rhône-Alpes
7159,MIRABEL,7170,MIRABEL,,44.5960574224,4.49616494242,159,,Mirabel,Mirabel,7,Ardèche,84,Auvergne-Rhône-Alpes
7160,MONESTIER,7690,MONESTIER,,45.199947411,4.5047555186,160,,Monestier,Monestier,7,Ardèche,84,Auvergne-Rhône-Alpes
7161,MONTPEZAT SOUS BAUZON,7560,MONTPEZAT SOUS BAUZON,,44.7167471772,4.19649706588,161,,Montpezat-sous-Bauzon,Montpezat-sous-Bauzon,7,Ardèche,84,Auvergne-Rhône-Alpes
7162,MONTREAL,7110,MONTREAL,,44.5182302311,4.29420556332,162,,Montréal,Montréal,7,Ardèche,84,Auvergne-Rhône-Alpes
7163,MONTSELGUES,7140,MONTSELGUES,,44.5332760746,4.0037872182,163,,Montselgues,Montselgues,7,Ardèche,84,Auvergne-Rhône-Alpes
7165,NONIERES,7160,NONIERES,,44.9277079206,4.47689363843,165,,Nonières,Nonières,7,Ardèche,84,Auvergne-Rhône-Alpes
7166,NOZIERES,7270,NOZIERES,,45.0340943157,4.54645934869,166,,Nozières,Nozières,7,Ardèche,84,Auvergne-Rhône-Alpes
7167,LES OLLIERES SUR EYRIEUX,7360,LES OLLIERES SUR EYRIEUX,,44.8101851934,4.61974464994,167,Les,Ollières-sur-Eyrieux,Les Ollières-sur-Eyrieux,7,Ardèche,84,Auvergne-Rhône-Alpes
7168,ORGNAC L AVEN,7150,ORGNAC L AVEN,,44.3101002443,4.42369715287,168,,Orgnac-l'Aven,Orgnac-l'Aven,7,Ardèche,84,Auvergne-Rhône-Alpes
7169,OZON,7370,OZON,,45.1631858608,4.79658585866,169,,Ozon,Ozon,7,Ardèche,84,Auvergne-Rhône-Alpes
7170,PAILHARES,7410,PAILHARES,,45.0796648371,4.55727839601,170,,Pailharès,Pailharès,7,Ardèche,84,Auvergne-Rhône-Alpes
7171,PAYZAC,7230,PAYZAC,,44.4550131939,4.14925400268,171,,Payzac,Payzac,7,Ardèche,84,Auvergne-Rhône-Alpes
7172,PEAUGRES,7340,PEAUGRES,,45.2857807098,4.72747805706,172,,Peaugres,Peaugres,7,Ardèche,84,Auvergne-Rhône-Alpes
7173,PEREYRES,7450,PEREYRES,,44.7797076255,4.26098772626,173,,Péreyres,Péreyres,7,Ardèche,84,Auvergne-Rhône-Alpes
7174,PEYRAUD,7340,PEYRAUD,,45.2964031304,4.78361394668,174,,Peyraud,Peyraud,7,Ardèche,84,Auvergne-Rhône-Alpes
7175,LE PLAGNAL,7590,LE PLAGNAL,,44.6933752514,3.95120350218,175,Le,Plagnal,Le Plagnal,7,Ardèche,84,Auvergne-Rhône-Alpes
7176,PLANZOLLES,7230,PLANZOLLES,,44.4862153265,4.15764539553,176,,Planzolles,Planzolles,7,Ardèche,84,Auvergne-Rhône-Alpes
7177,PLATS,7300,PLATS,,45.0156478685,4.78034594921,177,,Plats,Plats,7,Ardèche,84,Auvergne-Rhône-Alpes
7178,PONT DE LABEAUME,7380,PONT DE LABEAUME,,44.6657587714,4.29738768266,178,,Pont-de-Labeaume,Pont-de-Labeaume,7,Ardèche,84,Auvergne-Rhône-Alpes
7179,POURCHERES,7000,POURCHERES,,44.7392683074,4.50440952703,179,,Pourchères,Pourchères,7,Ardèche,84,Auvergne-Rhône-Alpes
7181,LE POUZIN,7250,LE POUZIN,,44.751745622,4.74858526888,181,Le,Pouzin,Le Pouzin,7,Ardèche,84,Auvergne-Rhône-Alpes
7182,PRADES,7380,PRADES,,44.632791917,4.31054797837,182,,Prades,Prades,7,Ardèche,84,Auvergne-Rhône-Alpes
7183,PRADONS,7120,PRADONS,,44.473019743,4.37584445661,183,,Pradons,Pradons,7,Ardèche,84,Auvergne-Rhône-Alpes
7184,PRANLES,7000,PRANLES,,44.7808511718,4.57520431156,184,,Pranles,Pranles,7,Ardèche,84,Auvergne-Rhône-Alpes
7185,PREAUX,7290,PREAUX,,45.1362813279,4.65371648034,185,,Préaux,Préaux,7,Ardèche,84,Auvergne-Rhône-Alpes
7186,PRIVAS,7000,PRIVAS,,44.7225391147,4.59449538667,186,,Privas,Privas,7,Ardèche,84,Auvergne-Rhône-Alpes
7187,PRUNET,7110,PRUNET,,44.5954398585,4.25387564472,187,,Prunet,Prunet,7,Ardèche,84,Auvergne-Rhône-Alpes
7188,QUINTENAS,7290,QUINTENAS,,45.1922513246,4.69504598366,188,,Quintenas,Quintenas,7,Ardèche,84,Auvergne-Rhône-Alpes
7189,RIBES,7260,RIBES,,44.5036367428,4.19775293307,189,,Ribes,Ribes,7,Ardèche,84,Auvergne-Rhône-Alpes
7190,ROCHECOLOMBE,7200,ROCHECOLOMBE,,44.5184712658,4.44840556481,190,,Rochecolombe,Rochecolombe,7,Ardèche,84,Auvergne-Rhône-Alpes
7191,ROCHEMAURE,7400,ROCHEMAURE,,44.589264353,4.69211996285,191,,Rochemaure,Rochemaure,7,Ardèche,84,Auvergne-Rhône-Alpes
7192,ROCHEPAULE,7320,ROCHEPAULE,,45.0856715901,4.45942671204,192,,Rochepaule,Rochepaule,7,Ardèche,84,Auvergne-Rhône-Alpes
7193,ROCHER,7110,ROCHER,,44.5805813909,4.2790675943,193,,Rocher,Rocher,7,Ardèche,84,Auvergne-Rhône-Alpes
7194,ROCHESSAUVE,7210,ROCHESSAUVE,,44.6806789523,4.61124085602,194,,Rochessauve,Rochessauve,7,Ardèche,84,Auvergne-Rhône-Alpes
7195,LA ROCHETTE,7310,LA ROCHETTE,,44.9217587491,4.23164949821,195,La,Rochette,La Rochette,7,Ardèche,84,Auvergne-Rhône-Alpes
7196,ROCLES,7110,ROCLES,,44.5680037839,4.20813131738,196,,Rocles,Rocles,7,Ardèche,84,Auvergne-Rhône-Alpes
7197,ROIFFIEUX,7100,ROIFFIEUX,,45.2189397431,4.64922144398,197,,Roiffieux,Roiffieux,7,Ardèche,84,Auvergne-Rhône-Alpes
7198,ROMPON,7250,ROMPON,,44.7787195427,4.73009856646,198,,Rompon,Rompon,7,Ardèche,84,Auvergne-Rhône-Alpes
7198,ROMPON,7800,ROMPON,,44.7787195427,4.73009856646,198,,Rompon,Rompon,7,Ardèche,84,Auvergne-Rhône-Alpes
7199,ROSIERES,7260,ROSIERES,,44.492135712,4.26203943269,199,,Rosières,Rosières,7,Ardèche,84,Auvergne-Rhône-Alpes
7200,LE ROUX,7560,LE ROUX,,44.7211157335,4.14333586468,200,Le,Roux,Le Roux,7,Ardèche,84,Auvergne-Rhône-Alpes
7201,RUOMS,7120,RUOMS,,44.4479030757,4.34786189328,201,,Ruoms,Ruoms,7,Ardèche,84,Auvergne-Rhône-Alpes
7202,SABLIERES,7260,SABLIERES,,44.5437864312,4.06325976737,202,,Sablières,Sablières,7,Ardèche,84,Auvergne-Rhône-Alpes
7203,SAGNES ET GOUDOULET,7450,SAGNES ET GOUDOULET,,44.8014042397,4.215812246,203,,Sagnes-et-Goudoulet,Sagnes-et-Goudoulet,7,Ardèche,84,Auvergne-Rhône-Alpes
7204,ST AGREVE,7320,ST AGREVE,,45.0052980637,4.41285687419,204,,Saint-Agrève,Saint-Agrève,7,Ardèche,84,Auvergne-Rhône-Alpes
7204,ST AGREVE,7320,ST AGREVE,LE POUZAT,45.0052980637,4.41285687419,204,,Saint-Agrève,Saint-Agrève,7,Ardèche,84,Auvergne-Rhône-Alpes
7205,ST ALBAN D AY,7790,ST ALBAN D AY,,45.1862322828,4.63179784217,205,,Saint-Alban-d'Ay,Saint-Alban-d'Ay,7,Ardèche,84,Auvergne-Rhône-Alpes
7206,ST ALBAN EN MONTAGNE,7590,ST ALBAN EN MONTAGNE,,44.7104160839,3.91795005767,206,,Saint-Alban-en-Montagne,Saint-Alban-en-Montagne,7,Ardèche,84,Auvergne-Rhône-Alpes
7207,ST ALBAN AURIOLLES,7120,ST ALBAN AURIOLLES,,44.4342653055,4.29515757348,207,,Saint-Alban-Auriolles,Saint-Alban-Auriolles,7,Ardèche,84,Auvergne-Rhône-Alpes
7207,ST ALBAN AURIOLLES,7120,ST ALBAN AURIOLLES,AURIOLLES,44.4342653055,4.29515757348,207,,Saint-Alban-Auriolles,Saint-Alban-Auriolles,7,Ardèche,84,Auvergne-Rhône-Alpes
7208,ST ANDEOL DE BERG,7170,ST ANDEOL DE BERG,,44.530020748,4.53661194159,208,,Saint-Andéol-de-Berg,Saint-Andéol-de-Berg,7,Ardèche,84,Auvergne-Rhône-Alpes
7209,ST ANDEOL DE FOURCHADES,7160,ST ANDEOL DE FOURCHADES,,44.844995475,4.28822767491,209,,Saint-Andéol-de-Fourchades,Saint-Andéol-de-Fourchades,7,Ardèche,84,Auvergne-Rhône-Alpes
7210,ST ANDEOL DE VALS,7600,ST ANDEOL DE VALS,,44.6925998295,4.40100662602,210,,Saint-Andéol-de-Vals,Saint-Andéol-de-Vals,7,Ardèche,84,Auvergne-Rhône-Alpes
7211,ST ANDRE DE CRUZIERES,7460,ST ANDRE DE CRUZIERES,,44.315163637,4.21241172389,211,,Saint-André-de-Cruzières,Saint-André-de-Cruzières,7,Ardèche,84,Auvergne-Rhône-Alpes
7212,ST ANDRE EN VIVARAIS,7690,ST ANDRE EN VIVARAIS,,45.1164853445,4.40960303294,212,,Saint-André-en-Vivarais,Saint-André-en-Vivarais,7,Ardèche,84,Auvergne-Rhône-Alpes
7213,ST ANDRE LACHAMP,7230,ST ANDRE LACHAMP,,44.5075453223,4.15221275695,213,,Saint-André-Lachamp,Saint-André-Lachamp,7,Ardèche,84,Auvergne-Rhône-Alpes
7214,ST APOLLINAIRE DE RIAS,7240,ST APOLLINAIRE DE RIAS,,44.9204690722,4.59052402464,214,,Saint-Apollinaire-de-Rias,Saint-Apollinaire-de-Rias,7,Ardèche,84,Auvergne-Rhône-Alpes
7215,ST BARTHELEMY LE MEIL,7160,ST BARTHELEMY LE MEIL,,44.884662739,4.47884532485,215,,Saint-Barthélemy-le-Meil,Saint-Barthélemy-le-Meil,7,Ardèche,84,Auvergne-Rhône-Alpes
7216,ST BARTHELEMY GROZON,7270,ST BARTHELEMY GROZON,,44.9596196474,4.64950098004,216,,Saint-Barthélemy-Grozon,Saint-Barthélemy-Grozon,7,Ardèche,84,Auvergne-Rhône-Alpes
7217,ST BARTHELEMY LE PLAIN,7300,ST BARTHELEMY LE PLAIN,,45.0454601149,4.74368013077,217,,Saint-Barthélemy-le-Plain,Saint-Barthélemy-le-Plain,7,Ardèche,84,Auvergne-Rhône-Alpes
7218,ST BASILE,7270,ST BASILE,,44.9490355283,4.56160011901,218,,Saint-Basile,Saint-Basile,7,Ardèche,84,Auvergne-Rhône-Alpes
7219,ST BAUZILE,7210,ST BAUZILE,,44.672668231,4.66869584538,219,,Saint-Bauzile,Saint-Bauzile,7,Ardèche,84,Auvergne-Rhône-Alpes
7220,ST CHRISTOL,7160,ST CHRISTOL,,44.8696306888,4.43824066016,220,,Saint-Christol,Saint-Christol,7,Ardèche,84,Auvergne-Rhône-Alpes
7221,ST CIERGE LA SERRE,7800,ST CIERGE LA SERRE,,44.7935573483,4.6802724287,221,,Saint-Cierge-la-Serre,Saint-Cierge-la-Serre,7,Ardèche,84,Auvergne-Rhône-Alpes
7222,ST CIERGE SOUS LE CHEYLARD,7160,ST CIERGE SOUS LE CHEYLARD,,44.926149144,4.45359829884,222,,Saint-Cierge-sous-le-Cheylard,Saint-Cierge-sous-le-Cheylard,7,Ardèche,84,Auvergne-Rhône-Alpes
7223,ST CIRGUES DE PRADES,7380,ST CIRGUES DE PRADES,,44.6202111771,4.27429398841,223,,Saint-Cirgues-de-Prades,Saint-Cirgues-de-Prades,7,Ardèche,84,Auvergne-Rhône-Alpes
7224,ST CIRGUES EN MONTAGNE,7510,ST CIRGUES EN MONTAGNE,,44.7681344892,4.0890257431,224,,Saint-Cirgues-en-Montagne,Saint-Cirgues-en-Montagne,7,Ardèche,84,Auvergne-Rhône-Alpes
7225,ST CLAIR,7430,ST CLAIR,,45.2755892948,4.68712205,225,,Saint-Clair,Saint-Clair,7,Ardèche,84,Auvergne-Rhône-Alpes
7226,ST CLEMENT,7310,ST CLEMENT,,44.9499994905,4.26018660775,226,,Saint-Clément,Saint-Clément,7,Ardèche,84,Auvergne-Rhône-Alpes
7227,ST CYR,7430,ST CYR,,45.2490601224,4.73642640952,227,,Saint-Cyr,Saint-Cyr,7,Ardèche,84,Auvergne-Rhône-Alpes
7228,ST DESIRAT,7340,ST DESIRAT,,45.2586090044,4.77940382807,228,,Saint-Désirat,Saint-Désirat,7,Ardèche,84,Auvergne-Rhône-Alpes
7229,ST DIDIER SOUS AUBENAS,7200,ST DIDIER SOUS AUBENAS,,44.6117595327,4.42022719051,229,,Saint-Didier-sous-Aubenas,Saint-Didier-sous-Aubenas,7,Ardèche,84,Auvergne-Rhône-Alpes
7230,ST ETIENNE DE BOULOGNE,7200,ST ETIENNE DE BOULOGNE,,44.7003919648,4.47316819572,230,,Saint-Étienne-de-Boulogne,Saint-Étienne-de-Boulogne,7,Ardèche,84,Auvergne-Rhône-Alpes
7231,ST ETIENNE DE FONTBELLON,7200,ST ETIENNE DE FONTBELLON,,44.5985890529,4.37388980251,231,,Saint-Étienne-de-Fontbellon,Saint-Étienne-de-Fontbellon,7,Ardèche,84,Auvergne-Rhône-Alpes
7232,ST ETIENNE DE LUGDARES,7590,ST ETIENNE DE LUGDARES,,44.6472996058,3.97320731219,232,,Saint-Étienne-de-Lugdarès,Saint-Étienne-de-Lugdarès,7,Ardèche,84,Auvergne-Rhône-Alpes
7233,ST ETIENNE DE SERRE,7190,ST ETIENNE DE SERRE,,44.8012229727,4.52362326693,233,,Saint-Étienne-de-Serre,Saint-Étienne-de-Serre,7,Ardèche,84,Auvergne-Rhône-Alpes
7234,ST ETIENNE DE VALOUX,7340,ST ETIENNE DE VALOUX,,45.2429551073,4.77699144156,234,,Saint-Étienne-de-Valoux,Saint-Étienne-de-Valoux,7,Ardèche,84,Auvergne-Rhône-Alpes
7235,STE EULALIE,7510,STE EULALIE,,44.8287147176,4.18597793288,235,,Sainte-Eulalie,Sainte-Eulalie,7,Ardèche,84,Auvergne-Rhône-Alpes
7236,ST FELICIEN,7410,ST FELICIEN,,45.0768793363,4.60975134284,236,,Saint-Félicien,Saint-Félicien,7,Ardèche,84,Auvergne-Rhône-Alpes
7237,ST FORTUNAT SUR EYRIEUX,7360,ST FORTUNAT SUR EYRIEUX,,44.8349868514,4.68914271532,237,,Saint-Fortunat-sur-Eyrieux,Saint-Fortunat-sur-Eyrieux,7,Ardèche,84,Auvergne-Rhône-Alpes
7238,ST GENEST DE BEAUZON,7230,ST GENEST DE BEAUZON,,44.4451273836,4.18697019048,238,,Saint-Genest-de-Beauzon,Saint-Genest-de-Beauzon,7,Ardèche,84,Auvergne-Rhône-Alpes
7239,ST GENEST LACHAMP,7160,ST GENEST LACHAMP,,44.8439891457,4.42424753719,239,,Saint-Genest-Lachamp,Saint-Genest-Lachamp,7,Ardèche,84,Auvergne-Rhône-Alpes
7239,ST GENEST LACHAMP,7190,ST GENEST LACHAMP,,44.8439891457,4.42424753719,239,,Saint-Genest-Lachamp,Saint-Genest-Lachamp,7,Ardèche,84,Auvergne-Rhône-Alpes
7240,ST GEORGES LES BAINS,7800,ST GEORGES LES BAINS,,44.8592532012,4.8096971497,240,,Saint-Georges-les-Bains,Saint-Georges-les-Bains,7,Ardèche,84,Auvergne-Rhône-Alpes
7241,ST GERMAIN,7170,ST GERMAIN,,44.5531057942,4.44825609237,241,,Saint-Germain,Saint-Germain,7,Ardèche,84,Auvergne-Rhône-Alpes
7242,ST GINEIS EN COIRON,7580,ST GINEIS EN COIRON,,44.6337066136,4.53894294674,242,,Saint-Gineis-en-Coiron,Saint-Gineis-en-Coiron,7,Ardèche,84,Auvergne-Rhône-Alpes
7243,ST JACQUES D ATTICIEUX,7340,ST JACQUES D ATTICIEUX,,45.3318263343,4.66333060833,243,,Saint-Jacques-d'Atticieux,Saint-Jacques-d'Atticieux,7,Ardèche,84,Auvergne-Rhône-Alpes
7244,ST JEAN CHAMBRE,7240,ST JEAN CHAMBRE,,44.9083362092,4.5616408271,244,,Saint-Jean-Chambre,Saint-Jean-Chambre,7,Ardèche,84,Auvergne-Rhône-Alpes
7245,ST JEAN DE MUZOLS,7300,ST JEAN DE MUZOLS,,45.0791512889,4.79987361991,245,,Saint-Jean-de-Muzols,Saint-Jean-de-Muzols,7,Ardèche,84,Auvergne-Rhône-Alpes
7247,ST JEAN LE CENTENIER,7580,ST JEAN LE CENTENIER,,44.5873773407,4.53812653504,247,,Saint-Jean-le-Centenier,Saint-Jean-le-Centenier,7,Ardèche,84,Auvergne-Rhône-Alpes
7248,ST JEAN ROURE,7160,ST JEAN ROURE,,44.9554453172,4.41885438779,248,,Saint-Jean-Roure,Saint-Jean-Roure,7,Ardèche,84,Auvergne-Rhône-Alpes
7249,ST JEURE D ANDAURE,7320,ST JEURE D ANDAURE,,45.0478373681,4.46395685246,249,,Saint-Jeure-d'Andaure,Saint-Jeure-d'Andaure,7,Ardèche,84,Auvergne-Rhône-Alpes
7250,ST JEURE D AY,7290,ST JEURE D AY,,45.1448039852,4.71028966691,250,,Saint-Jeure-d'Ay,Saint-Jeure-d'Ay,7,Ardèche,84,Auvergne-Rhône-Alpes
7251,ST JOSEPH DES BANCS,7530,ST JOSEPH DES BANCS,,44.748161488,4.41397525518,251,,Saint-Joseph-des-Bancs,Saint-Joseph-des-Bancs,7,Ardèche,84,Auvergne-Rhône-Alpes
7252,ST JULIEN BOUTIERES,7310,ST JULIEN BOUTIERES,,44.9687620295,4.34665154898,252,,Saint-Julien-Boutières,Saint-Julien-Boutières,7,Ardèche,84,Auvergne-Rhône-Alpes
7253,ST JULIEN DU GUA,7190,ST JULIEN DU GUA,,44.7707970545,4.44037521655,253,,Saint-Julien-du-Gua,Saint-Julien-du-Gua,7,Ardèche,84,Auvergne-Rhône-Alpes
7254,ST JULIEN DU SERRE,7200,ST JULIEN DU SERRE,,44.6600211072,4.40395881158,254,,Saint-Julien-du-Serre,Saint-Julien-du-Serre,7,Ardèche,84,Auvergne-Rhône-Alpes
7255,ST JULIEN EN ST ALBAN,7000,ST JULIEN EN ST ALBAN,,44.7586202476,4.69261801015,255,,Saint-Julien-en-Saint-Alban,Saint-Julien-en-Saint-Alban,7,Ardèche,84,Auvergne-Rhône-Alpes
7256,ST JULIEN LABROUSSE,7160,ST JULIEN LABROUSSE,,44.9079658376,4.51640015413,256,,Saint-Julien-Labrousse,Saint-Julien-Labrousse,7,Ardèche,84,Auvergne-Rhône-Alpes
7257,ST JULIEN LE ROUX,7240,ST JULIEN LE ROUX,,44.8675203578,4.67592482949,257,,Saint-Julien-le-Roux,Saint-Julien-le-Roux,7,Ardèche,84,Auvergne-Rhône-Alpes
7258,ST JULIEN VOCANCE,7690,ST JULIEN VOCANCE,,45.167010931,4.4877776042,258,,Saint-Julien-Vocance,Saint-Julien-Vocance,7,Ardèche,84,Auvergne-Rhône-Alpes
7259,ST JUST D ARDECHE,7700,ST JUST D ARDECHE,,44.2932027853,4.62448546044,259,,Saint-Just-d'Ardèche,Saint-Just-d'Ardèche,7,Ardèche,84,Auvergne-Rhône-Alpes
7260,ST LAGER BRESSAC,7210,ST LAGER BRESSAC,,44.6916750931,4.71621277437,260,,Saint-Lager-Bressac,Saint-Lager-Bressac,7,Ardèche,84,Auvergne-Rhône-Alpes
7261,ST LAURENT DU PAPE,7800,ST LAURENT DU PAPE,,44.8232083643,4.7449478067,261,,Saint-Laurent-du-Pape,Saint-Laurent-du-Pape,7,Ardèche,84,Auvergne-Rhône-Alpes
7262,ST LAURENT LES BAINS,7590,ST LAURENT LES BAINS,,44.5971365641,3.95806929863,262,,Saint-Laurent-les-Bains,Saint-Laurent-les-Bains,7,Ardèche,84,Auvergne-Rhône-Alpes
7263,ST LAURENT SOUS COIRON,7170,ST LAURENT SOUS COIRON,,44.6641637879,4.48470756924,263,,Saint-Laurent-sous-Coiron,Saint-Laurent-sous-Coiron,7,Ardèche,84,Auvergne-Rhône-Alpes
7264,ST MARCEL D ARDECHE,7700,ST MARCEL D ARDECHE,,44.3299965394,4.60656490626,264,,Saint-Marcel-d'Ardèche,Saint-Marcel-d'Ardèche,7,Ardèche,84,Auvergne-Rhône-Alpes
7265,ST MARCEL LES ANNONAY,7100,ST MARCEL LES ANNONAY,,45.2885682592,4.62947742876,265,,Saint-Marcel-lès-Annonay,Saint-Marcel-lès-Annonay,7,Ardèche,84,Auvergne-Rhône-Alpes
7266,STE MARGUERITE LAFIGERE,7140,STE MARGUERITE LAFIGERE,,44.4845583529,3.99775571578,266,,Sainte-Marguerite-Lafigère,Sainte-Marguerite-Lafigère,7,Ardèche,84,Auvergne-Rhône-Alpes
7267,ST MARTIAL,7310,ST MARTIAL,,44.8627438374,4.25132239874,267,,Saint-Martial,Saint-Martial,7,Ardèche,84,Auvergne-Rhône-Alpes
7268,ST MARTIN D ARDECHE,7700,ST MARTIN D ARDECHE,,44.3068621325,4.57318657163,268,,Saint-Martin-d'Ardèche,Saint-Martin-d'Ardèche,7,Ardèche,84,Auvergne-Rhône-Alpes
7269,ST MARTIN DE VALAMAS,7310,ST MARTIN DE VALAMAS,,44.9365910249,4.37076169199,269,,Saint-Martin-de-Valamas,Saint-Martin-de-Valamas,7,Ardèche,84,Auvergne-Rhône-Alpes
7270,ST MARTIN SUR LAVEZON,7400,ST MARTIN SUR LAVEZON,,44.6347374682,4.65644801966,270,,Saint-Martin-sur-Lavezon,Saint-Martin-sur-Lavezon,7,Ardèche,84,Auvergne-Rhône-Alpes
7270,ST MARTIN SUR LAVEZON,7400,ST MARTIN SUR LAVEZON,ST MARTIN L INFERIEUR,44.6347374682,4.65644801966,270,,Saint-Martin-sur-Lavezon,Saint-Martin-sur-Lavezon,7,Ardèche,84,Auvergne-Rhône-Alpes
7272,ST MAURICE D ARDECHE,7200,ST MAURICE D ARDECHE,,44.5189878085,4.40483643435,272,,Saint-Maurice-d'Ardèche,Saint-Maurice-d'Ardèche,7,Ardèche,84,Auvergne-Rhône-Alpes
7273,ST MAURICE D IBIE,7170,ST MAURICE D IBIE,,44.4835993539,4.4824459032,273,,Saint-Maurice-d'Ibie,Saint-Maurice-d'Ibie,7,Ardèche,84,Auvergne-Rhône-Alpes
7274,ST MAURICE EN CHALENCON,7190,ST MAURICE EN CHALENCON,,44.8548081951,4.58245400667,274,,Saint-Maurice-en-Chalencon,Saint-Maurice-en-Chalencon,7,Ardèche,84,Auvergne-Rhône-Alpes
7275,ST MELANY,7260,ST MELANY,,44.5391165984,4.12911827009,275,,Saint-Mélany,Saint-Mélany,7,Ardèche,84,Auvergne-Rhône-Alpes
7276,ST MICHEL D AURANCE,7160,ST MICHEL D AURANCE,,44.8987179716,4.45675877839,276,,Saint-Michel-d'Aurance,Saint-Michel-d'Aurance,7,Ardèche,84,Auvergne-Rhône-Alpes
7277,ST MICHEL DE BOULOGNE,7200,ST MICHEL DE BOULOGNE,,44.6991000225,4.4327463684,277,,Saint-Michel-de-Boulogne,Saint-Michel-de-Boulogne,7,Ardèche,84,Auvergne-Rhône-Alpes
7278,ST MICHEL DE CHABRILLANOUX,7360,ST MICHEL DE CHABRILLANOUX,,44.8373734527,4.61203758015,278,,Saint-Michel-de-Chabrillanoux,Saint-Michel-de-Chabrillanoux,7,Ardèche,84,Auvergne-Rhône-Alpes
7279,ST MONTAN,7220,ST MONTAN,,44.4341193457,4.63258472487,279,,Saint-Montan,Saint-Montan,7,Ardèche,84,Auvergne-Rhône-Alpes
7280,ST PAUL LE JEUNE,7460,ST PAUL LE JEUNE,,44.3323751475,4.15520621175,280,,Saint-Paul-le-Jeune,Saint-Paul-le-Jeune,7,Ardèche,84,Auvergne-Rhône-Alpes
7281,ST PERAY,7130,ST PERAY,,44.9410716967,4.82459034307,281,,Saint-Péray,Saint-Péray,7,Ardèche,84,Auvergne-Rhône-Alpes
7282,ST PIERRE DE COLOMBIER,7450,ST PIERRE DE COLOMBIER,,44.7102969449,4.26637389652,282,,Saint-Pierre-de-Colombier,Saint-Pierre-de-Colombier,7,Ardèche,84,Auvergne-Rhône-Alpes
7283,ST PIERRE LA ROCHE,7400,ST PIERRE LA ROCHE,,44.6539287603,4.6218849184,283,,Saint-Pierre-la-Roche,Saint-Pierre-la-Roche,7,Ardèche,84,Auvergne-Rhône-Alpes
7284,ST PIERRE ST JEAN,7140,ST PIERRE ST JEAN,,44.4825896332,4.10164484089,284,,Saint-Pierre-Saint-Jean,Saint-Pierre-Saint-Jean,7,Ardèche,84,Auvergne-Rhône-Alpes
7284,ST PIERRE ST JEAN,7140,ST PIERRE ST JEAN,ST JEAN DE POURCHARESSE,44.4825896332,4.10164484089,284,,Saint-Pierre-Saint-Jean,Saint-Pierre-Saint-Jean,7,Ardèche,84,Auvergne-Rhône-Alpes
7285,ST PIERRE SUR DOUX,7520,ST PIERRE SUR DOUX,,45.1287002576,4.48532647522,285,,Saint-Pierre-sur-Doux,Saint-Pierre-sur-Doux,7,Ardèche,84,Auvergne-Rhône-Alpes
7286,ST PIERREVILLE,7190,ST PIERREVILLE,,44.819009873,4.48134078114,286,,Saint-Pierreville,Saint-Pierreville,7,Ardèche,84,Auvergne-Rhône-Alpes
7287,ST PONS,7580,ST PONS,,44.5987337858,4.57192531163,287,,Saint-Pons,Saint-Pons,7,Ardèche,84,Auvergne-Rhône-Alpes
7288,ST PRIEST,7000,ST PRIEST,,44.7131469303,4.53562187216,288,,Saint-Priest,Saint-Priest,7,Ardèche,84,Auvergne-Rhône-Alpes
7289,ST PRIVAT,7200,ST PRIVAT,,44.6285700497,4.42285507734,289,,Saint-Privat,Saint-Privat,7,Ardèche,84,Auvergne-Rhône-Alpes
7290,ST PRIX,7270,ST PRIX,,44.9471894695,4.50107572096,290,,Saint-Prix,Saint-Prix,7,Ardèche,84,Auvergne-Rhône-Alpes
7291,ST REMEZE,7700,ST REMEZE,,44.3781737875,4.49634983061,291,,Saint-Remèze,Saint-Remèze,7,Ardèche,84,Auvergne-Rhône-Alpes
7292,ST ROMAIN D AY,7290,ST ROMAIN D AY,,45.1657034684,4.68149456221,292,,Saint-Romain-d'Ay,Saint-Romain-d'Ay,7,Ardèche,84,Auvergne-Rhône-Alpes
7293,ST ROMAIN DE LERPS,7130,ST ROMAIN DE LERPS,,44.9763077401,4.79626374125,293,,Saint-Romain-de-Lerps,Saint-Romain-de-Lerps,7,Ardèche,84,Auvergne-Rhône-Alpes
7294,ST SAUVEUR DE CRUZIERES,7460,ST SAUVEUR DE CRUZIERES,,44.2949958385,4.25831849321,294,,Saint-Sauveur-de-Cruzières,Saint-Sauveur-de-Cruzières,7,Ardèche,84,Auvergne-Rhône-Alpes
7295,ST SAUVEUR DE MONTAGUT,7190,ST SAUVEUR DE MONTAGUT,,44.8202271347,4.57255179328,295,,Saint-Sauveur-de-Montagut,Saint-Sauveur-de-Montagut,7,Ardèche,84,Auvergne-Rhône-Alpes
7296,ST SERNIN,7200,ST SERNIN,,44.5764544041,4.38197564257,296,,Saint-Sernin,Saint-Sernin,7,Ardèche,84,Auvergne-Rhône-Alpes
7297,ST SYLVESTRE,7440,ST SYLVESTRE,,44.9879627989,4.74987587664,297,,Saint-Sylvestre,Saint-Sylvestre,7,Ardèche,84,Auvergne-Rhône-Alpes
7298,ST SYMPHORIEN SOUS CHOMERAC,7210,ST SYMPHORIEN SOUS CHOMERAC,,44.7285052281,4.705839287,298,,Saint-Symphorien-sous-Chomérac,Saint-Symphorien-sous-Chomérac,7,Ardèche,84,Auvergne-Rhône-Alpes
7299,ST SYMPHORIEN DE MAHUN,7290,ST SYMPHORIEN DE MAHUN,,45.1563624084,4.54974393041,299,,Saint-Symphorien-de-Mahun,Saint-Symphorien-de-Mahun,7,Ardèche,84,Auvergne-Rhône-Alpes
7300,ST THOME,7220,ST THOME,,44.5023905897,4.61908212557,300,,Saint-Thomé,Saint-Thomé,7,Ardèche,84,Auvergne-Rhône-Alpes
7301,ST VICTOR,7410,ST VICTOR,,45.0991678025,4.6879539211,301,,Saint-Victor,Saint-Victor,7,Ardèche,84,Auvergne-Rhône-Alpes
7302,ST VINCENT DE BARRES,7210,ST VINCENT DE BARRES,,44.6573798022,4.70779936262,302,,Saint-Vincent-de-Barrès,Saint-Vincent-de-Barrès,7,Ardèche,84,Auvergne-Rhône-Alpes
7303,ST VINCENT DE DURFORT,7360,ST VINCENT DE DURFORT,,44.7919043427,4.63828690293,303,,Saint-Vincent-de-Durfort,Saint-Vincent-de-Durfort,7,Ardèche,84,Auvergne-Rhône-Alpes
7304,SALAVAS,7150,SALAVAS,,44.3884517023,4.36713374298,304,,Salavas,Salavas,7,Ardèche,84,Auvergne-Rhône-Alpes
7305,LES SALELLES,7140,LES SALELLES,,44.4365281515,4.09837976088,305,Les,Salelles,Les Salelles,7,Ardèche,84,Auvergne-Rhône-Alpes
7306,SAMPZON,7120,SAMPZON,,44.4123532588,4.33204376099,306,,Sampzon,Sampzon,7,Ardèche,84,Auvergne-Rhône-Alpes
7307,SANILHAC,7110,SANILHAC,,44.5327416488,4.23228758163,307,,Sanilhac,Sanilhac,7,Ardèche,84,Auvergne-Rhône-Alpes
7308,SARRAS,7370,SARRAS,,45.1872505321,4.78407766188,308,,Sarras,Sarras,7,Ardèche,84,Auvergne-Rhône-Alpes
7309,SATILLIEU,7290,SATILLIEU,,45.1414705974,4.58761138968,309,,Satillieu,Satillieu,7,Ardèche,84,Auvergne-Rhône-Alpes
7310,SAVAS,7430,SAVAS,,45.3013144956,4.67282946981,310,,Savas,Savas,7,Ardèche,84,Auvergne-Rhône-Alpes
7311,SCEAUTRES,7400,SCEAUTRES,,44.6167012065,4.60776940667,311,,Sceautres,Sceautres,7,Ardèche,84,Auvergne-Rhône-Alpes
7312,SECHERAS,7610,SECHERAS,,45.1291892251,4.77685404459,312,,Sécheras,Sécheras,7,Ardèche,84,Auvergne-Rhône-Alpes
7313,SERRIERES,7340,SERRIERES,,45.313905067,4.7629922758,313,,Serrières,Serrières,7,Ardèche,84,Auvergne-Rhône-Alpes
7314,SILHAC,7240,SILHAC,,44.8740499723,4.61339409596,314,,Silhac,Silhac,7,Ardèche,84,Auvergne-Rhône-Alpes
7315,LA SOUCHE,7380,LA SOUCHE,,44.6277336502,4.16243697678,315,La,Souche,La Souche,7,Ardèche,84,Auvergne-Rhône-Alpes
7316,SOYONS,7130,SOYONS,,44.8920478488,4.85094581265,316,,Soyons,Soyons,7,Ardèche,84,Auvergne-Rhône-Alpes
7317,TALENCIEUX,7340,TALENCIEUX,,45.219237035,4.77408036091,317,,Talencieux,Talencieux,7,Ardèche,84,Auvergne-Rhône-Alpes
7318,TAURIERS,7110,TAURIERS,,44.5518944269,4.2730187542,318,,Tauriers,Tauriers,7,Ardèche,84,Auvergne-Rhône-Alpes
7319,LE TEIL,7400,LE TEIL,,44.5432149708,4.66470569343,319,Le,Teil,Le Teil,7,Ardèche,84,Auvergne-Rhône-Alpes
7321,THORRENC,7340,THORRENC,,45.2336802249,4.75948467595,321,,Thorrenc,Thorrenc,7,Ardèche,84,Auvergne-Rhône-Alpes
7322,THUEYTS,7330,THUEYTS,,44.6744968668,4.21216612879,322,,Thueyts,Thueyts,7,Ardèche,84,Auvergne-Rhône-Alpes
7323,TOULAUD,7130,TOULAUD,,44.9021816561,4.79271125843,323,,Toulaud,Toulaud,7,Ardèche,84,Auvergne-Rhône-Alpes
7324,TOURNON SUR RHONE,7300,TOURNON SUR RHONE,,45.0535734386,4.81493474284,324,,Tournon-sur-Rhône,Tournon-sur-Rhône,7,Ardèche,84,Auvergne-Rhône-Alpes
7325,UCEL,7200,UCEL,,44.6426743916,4.38805305163,325,,Ucel,Ucel,7,Ardèche,84,Auvergne-Rhône-Alpes
7326,USCLADES ET RIEUTORD,7510,USCLADES ET RIEUTORD,,44.7709560867,4.1691617243,326,,Usclades-et-Rieutord,Usclades-et-Rieutord,7,Ardèche,84,Auvergne-Rhône-Alpes
7327,UZER,7110,UZER,,44.5176949506,4.3270953047,327,,Uzer,Uzer,7,Ardèche,84,Auvergne-Rhône-Alpes
7328,VAGNAS,7150,VAGNAS,,44.3596132039,4.34424564679,328,,Vagnas,Vagnas,7,Ardèche,84,Auvergne-Rhône-Alpes
7329,VALGORGE,7110,VALGORGE,,44.5942570326,4.11258793798,329,,Valgorge,Valgorge,7,Ardèche,84,Auvergne-Rhône-Alpes
7330,VALLON PONT D ARC,7150,VALLON PONT D ARC,,44.4003012449,4.40537086036,330,,Vallon-Pont-d'Arc,Vallon-Pont-d'Arc,7,Ardèche,84,Auvergne-Rhône-Alpes
7331,VALS LES BAINS,7600,VALS LES BAINS,,44.6671044258,4.3432629413,331,,Vals-les-Bains,Vals-les-Bains,7,Ardèche,84,Auvergne-Rhône-Alpes
7332,VALVIGNERES,7400,VALVIGNERES,,44.5005207324,4.56110237736,332,,Valvignères,Valvignères,7,Ardèche,84,Auvergne-Rhône-Alpes
7333,VANOSC,7690,VANOSC,,45.2240895311,4.53452254097,333,,Vanosc,Vanosc,7,Ardèche,84,Auvergne-Rhône-Alpes
7334,LES VANS,7140,LES VANS,,44.392206287,4.11524168722,334,Les,Vans,Les Vans,7,Ardèche,84,Auvergne-Rhône-Alpes
7334,LES VANS,7140,LES VANS,BRAHIC,44.392206287,4.11524168722,334,Les,Vans,Les Vans,7,Ardèche,84,Auvergne-Rhône-Alpes
7334,LES VANS,7140,LES VANS,CHASSAGNES,44.392206287,4.11524168722,334,Les,Vans,Les Vans,7,Ardèche,84,Auvergne-Rhône-Alpes
7334,LES VANS,7140,LES VANS,NAVES,44.392206287,4.11524168722,334,Les,Vans,Les Vans,7,Ardèche,84,Auvergne-Rhône-Alpes
7335,VAUDEVANT,7410,VAUDEVANT,,45.1081841616,4.60737715144,335,,Vaudevant,Vaudevant,7,Ardèche,84,Auvergne-Rhône-Alpes
7336,VERNON,7260,VERNON,,44.506232057,4.2261837868,336,,Vernon,Vernon,7,Ardèche,84,Auvergne-Rhône-Alpes
7337,VERNOSC LES ANNONAY,7430,VERNOSC LES ANNONAY,,45.2215835502,4.72347943452,337,,Vernosc-lès-Annonay,Vernosc-lès-Annonay,7,Ardèche,84,Auvergne-Rhône-Alpes
7338,VERNOUX EN VIVARAIS,7240,VERNOUX EN VIVARAIS,,44.9051558168,4.64438568731,338,,Vernoux-en-Vivarais,Vernoux-en-Vivarais,7,Ardèche,84,Auvergne-Rhône-Alpes
7339,VESSEAUX,7200,VESSEAUX,,44.6575169192,4.44419824196,339,,Vesseaux,Vesseaux,7,Ardèche,84,Auvergne-Rhône-Alpes
7340,VEYRAS,7000,VEYRAS,,44.735095562,4.55638123378,340,,Veyras,Veyras,7,Ardèche,84,Auvergne-Rhône-Alpes
7341,VILLENEUVE DE BERG,7170,VILLENEUVE DE BERG,,44.549017078,4.50337369457,341,,Villeneuve-de-Berg,Villeneuve-de-Berg,7,Ardèche,84,Auvergne-Rhône-Alpes
7342,VILLEVOCANCE,7690,VILLEVOCANCE,,45.2257853403,4.59519658449,342,,Villevocance,Villevocance,7,Ardèche,84,Auvergne-Rhône-Alpes
7343,VINEZAC,7110,VINEZAC,,44.5464277181,4.33294323997,343,,Vinezac,Vinezac,7,Ardèche,84,Auvergne-Rhône-Alpes
7344,VINZIEUX,7340,VINZIEUX,,45.330873084,4.70162834233,344,,Vinzieux,Vinzieux,7,Ardèche,84,Auvergne-Rhône-Alpes
7345,VION,7610,VION,,45.1117422465,4.79854763187,345,,Vion,Vion,7,Ardèche,84,Auvergne-Rhône-Alpes
7346,VIVIERS,7220,VIVIERS,,44.4834547319,4.66986126314,346,,Viviers,Viviers,7,Ardèche,84,Auvergne-Rhône-Alpes
7347,VOCANCE,7690,VOCANCE,,45.1946478686,4.56450150023,347,,Vocance,Vocance,7,Ardèche,84,Auvergne-Rhône-Alpes
7348,VOGUE,7200,VOGUE,,44.5576610723,4.41089587827,348,,Vogüé,Vogüé,7,Ardèche,84,Auvergne-Rhône-Alpes
7349,LA VOULTE SUR RHONE,7800,LA VOULTE SUR RHONE,,44.8015403943,4.78018830634,349,La,Voulte-sur-Rhône,La Voulte-sur-Rhône,7,Ardèche,84,Auvergne-Rhône-Alpes
8001,ACY ROMANCE,8300,ACY ROMANCE,,49.4918922835,4.32704942138,1,,Acy-Romance,Acy-Romance,8,Ardennes,44,Grand Est
8003,AIGLEMONT,8090,AIGLEMONT,,49.7902205775,4.77008728822,3,,Aiglemont,Aiglemont,8,Ardennes,44,Grand Est
8004,AIRE,8190,AIRE,,49.4707483815,4.16388872575,4,,Aire,Aire,8,Ardennes,44,Grand Est
8005,ALINCOURT,8310,ALINCOURT,,49.4030540812,4.34518271318,5,,Alincourt,Alincourt,8,Ardennes,44,Grand Est
8006,ALLAND HUY ET SAUSSEUIL,8130,ALLAND HUY ET SAUSSEUIL,,49.5119483771,4.54919404707,6,,Alland'Huy-et-Sausseuil,Alland'Huy-et-Sausseuil,8,Ardennes,44,Grand Est
8008,AMAGNE,8300,AMAGNE,,49.5164052633,4.50308478101,8,,Amagne,Amagne,8,Ardennes,44,Grand Est
8010,AMBLY FLEURY,8130,AMBLY FLEURY,,49.4891172634,4.48932267943,10,,Ambly-Fleury,Ambly-Fleury,8,Ardennes,44,Grand Est
8011,ANCHAMPS,8500,ANCHAMPS,,49.9247359313,4.66426745168,11,,Anchamps,Anchamps,8,Ardennes,44,Grand Est
8013,ANGECOURT,8450,ANGECOURT,,49.6350718482,4.97725786194,13,,Angecourt,Angecourt,8,Ardennes,44,Grand Est
8014,ANNELLES,8310,ANNELLES,,49.4316285312,4.41382133592,14,,Annelles,Annelles,8,Ardennes,44,Grand Est
8015,ANTHENY,8260,ANTHENY,,49.8444301625,4.30477603028,15,,Antheny,Antheny,8,Ardennes,44,Grand Est
8016,AOUSTE,8290,AOUSTE,,49.7952206071,4.31356214296,16,,Aouste,Aouste,8,Ardennes,44,Grand Est
8017,APREMONT,8250,APREMONT,,49.259356566,4.96764027649,17,,Apremont,Apremont,8,Ardennes,44,Grand Est
8018,ARDEUIL ET MONTFAUXELLES,8400,ARDEUIL ET MONTFAUXELLES,,49.26723401,4.70481330264,18,,Ardeuil-et-Montfauxelles,Ardeuil-et-Montfauxelles,8,Ardennes,44,Grand Est
8019,LES GRANDES ARMOISES,8390,LES GRANDES ARMOISES,,49.5292881443,4.89884201753,19,Les,Grandes-Armoises,Les Grandes-Armoises,8,Ardennes,44,Grand Est
8020,LES PETITES ARMOISES,8390,LES PETITES ARMOISES,,49.500852413,4.82851805787,20,Les,Petites-Armoises,Les Petites-Armoises,8,Ardennes,44,Grand Est
8021,ARNICOURT,8300,ARNICOURT,,49.5539593023,4.34161870964,21,,Arnicourt,Arnicourt,8,Ardennes,44,Grand Est
8022,ARREUX,8090,ARREUX,,49.8258643025,4.6613050432,22,,Arreux,Arreux,8,Ardennes,44,Grand Est
8023,ARTAISE LE VIVIER,8390,ARTAISE LE VIVIER,,49.5719309826,4.89264459284,23,,Artaise-le-Vivier,Artaise-le-Vivier,8,Ardennes,44,Grand Est
8024,ASFELD,8190,ASFELD,,49.473325858,4.12377173054,24,,Asfeld,Asfeld,8,Ardennes,44,Grand Est
8025,ATTIGNY,8130,ATTIGNY,,49.4761720465,4.58492485149,25,,Attigny,Attigny,8,Ardennes,44,Grand Est
8026,AUBIGNY LES POTHEES,8150,AUBIGNY LES POTHEES,,49.7645065042,4.42763979942,26,,Aubigny-les-Pothées,Aubigny-les-Pothées,8,Ardennes,44,Grand Est
8027,AUBONCOURT VAUZELLES,8270,AUBONCOURT VAUZELLES,,49.5558989331,4.48296681132,27,,Auboncourt-Vauzelles,Auboncourt-Vauzelles,8,Ardennes,44,Grand Est
8028,AUBRIVES,8320,AUBRIVES,,50.0873542875,4.76694917171,28,,Aubrives,Aubrives,8,Ardennes,44,Grand Est
8029,AUFLANCE,8370,AUFLANCE,,49.6152631354,5.29558462267,29,,Auflance,Auflance,8,Ardennes,44,Grand Est
8030,AUGE,8380,AUGE,,49.8571900309,4.26843508085,30,,Auge,Auge,8,Ardennes,44,Grand Est
8031,AURE,8400,AURE,,49.2765437387,4.6239215772,31,,Aure,Aure,8,Ardennes,44,Grand Est
8032,AUSSONCE,8310,AUSSONCE,,49.3455509602,4.32720413528,32,,Aussonce,Aussonce,8,Ardennes,44,Grand Est
8033,AUTHE,8240,AUTHE,,49.460146318,4.87768322282,33,,Authe,Authe,8,Ardennes,44,Grand Est
8034,AUTRECOURT ET POURRON,8210,AUTRECOURT ET POURRON,,49.6066112918,5.01792506561,34,,Autrecourt-et-Pourron,Autrecourt-et-Pourron,8,Ardennes,44,Grand Est
8035,AUTRUCHE,8240,AUTRUCHE,,49.4541460978,4.90568109184,35,,Autruche,Autruche,8,Ardennes,44,Grand Est
8036,AUTRY,8250,AUTRY,,49.265704992,4.8311535079,36,,Autry,Autry,8,Ardennes,44,Grand Est
8037,AUVILLERS LES FORGES,8260,AUVILLERS LES FORGES,,49.8661184339,4.36077137657,37,,Auvillers-les-Forges,Auvillers-les-Forges,8,Ardennes,44,Grand Est
8037,AUVILLERS LES FORGES,8260,AUVILLERS LES FORGES,MON IDEE,49.8661184339,4.36077137657,37,,Auvillers-les-Forges,Auvillers-les-Forges,8,Ardennes,44,Grand Est
8038,AVANCON,8300,AVANCON,,49.4808582492,4.25135015983,38,,Avançon,Avançon,8,Ardennes,44,Grand Est
8039,AVAUX,8190,AVAUX,,49.4659424316,4.06887306041,39,,Avaux,Avaux,8,Ardennes,44,Grand Est
8040,LES AYVELLES,8000,LES AYVELLES,,49.7217923857,4.75240985606,40,Les,Ayvelles,Les Ayvelles,8,Ardennes,44,Grand Est
8041,BAALONS,8430,BAALONS,,49.6016790531,4.66754593239,41,,Baâlons,Baâlons,8,Ardennes,44,Grand Est
8042,BALAIVES ET BUTZ,8160,BALAIVES ET BUTZ,,49.6725974863,4.71841917003,42,,Balaives-et-Butz,Balaives-et-Butz,8,Ardennes,44,Grand Est
8043,BALAN,8200,BALAN,,49.6897594511,4.96358286973,43,,Balan,Balan,8,Ardennes,44,Grand Est
8044,BALHAM,8190,BALHAM,,49.4914098748,4.15107633205,44,,Balham,Balham,8,Ardennes,44,Grand Est
8045,BALLAY,8400,BALLAY,,49.4216971928,4.75197348184,45,,Ballay,Ballay,8,Ardennes,44,Grand Est
8046,BANOGNE RECOUVRANCE,8220,BANOGNE RECOUVRANCE,,49.5702609587,4.13247064343,46,,Banogne-Recouvrance,Banogne-Recouvrance,8,Ardennes,44,Grand Est
8047,BARBAISE,8430,BARBAISE,,49.6766851443,4.57966392629,47,,Barbaise,Barbaise,8,Ardennes,44,Grand Est
8048,BARBY,8300,BARBY,,49.5250969786,4.31538215563,48,,Barby,Barby,8,Ardennes,44,Grand Est
8049,BAR LES BUZANCY,8240,BAR LES BUZANCY,,49.446594391,4.95764414152,49,,Bar-lès-Buzancy,Bar-lès-Buzancy,8,Ardennes,44,Grand Est
8052,BAYONVILLE,8240,BAYONVILLE,,49.3964581365,5.01111628168,52,,Bayonville,Bayonville,8,Ardennes,44,Grand Est
8053,BAZEILLES,8140,BAZEILLES,,49.6774342411,4.98515455089,53,,Bazeilles,Bazeilles,8,Ardennes,44,Grand Est
8055,BEAUMONT EN ARGONNE,8210,BEAUMONT EN ARGONNE,,49.5275973525,5.03536973288,55,,Beaumont-en-Argonne,Beaumont-en-Argonne,8,Ardennes,44,Grand Est
8056,BEFFU ET LE MORTHOMME,8250,BEFFU ET LE MORTHOMME,,49.372094938,4.89223564312,56,,Beffu-et-le-Morthomme,Beffu-et-le-Morthomme,8,Ardennes,44,Grand Est
8057,BELLEVILLE ET CHATILLON SUR BAR,8240,BELLEVILLE ET CHATILLON SUR BAR,,49.4653139409,4.8175627334,57,,Belleville-et-Châtillon-sur-Bar,Belleville-et-Châtillon-sur-Bar,8,Ardennes,44,Grand Est
8057,BELLEVILLE ET CHATILLON SUR BAR,8240,BELLEVILLE ET CHATILLON SUR BAR,CHATILLON SUR BAR,49.4653139409,4.8175627334,57,,Belleville-et-Châtillon-sur-Bar,Belleville-et-Châtillon-sur-Bar,8,Ardennes,44,Grand Est
8058,BELVAL,8090,BELVAL,,49.7730174963,4.63758771996,58,,Belval,Belval,8,Ardennes,44,Grand Est
8059,BELVAL BOIS DES DAMES,8240,BELVAL BOIS DES DAMES,,49.4815181071,5.04412784142,59,,Belval-Bois-des-Dames,Belval-Bois-des-Dames,8,Ardennes,44,Grand Est
8060,BERGNICOURT,8300,BERGNICOURT,,49.4194188436,4.24742333455,60,,Bergnicourt,Bergnicourt,8,Ardennes,44,Grand Est
8061,LA BERLIERE,8240,LA BERLIERE,,49.526668904,4.93724494551,61,La,Berlière,La Berlière,8,Ardennes,44,Grand Est
8062,BERTONCOURT,8300,BERTONCOURT,,49.5391943846,4.40140045221,62,,Bertoncourt,Bertoncourt,8,Ardennes,44,Grand Est
8063,LA BESACE,8450,LA BESACE,,49.5535273676,4.97012957408,63,La,Besace,La Besace,8,Ardennes,44,Grand Est
8064,BIERMES,8300,BIERMES,,49.4766763751,4.3845292895,64,,Biermes,Biermes,8,Ardennes,44,Grand Est
8065,BIEVRES,8370,BIEVRES,,49.5573406504,5.27746314985,65,,Bièvres,Bièvres,8,Ardennes,44,Grand Est
8066,BIGNICOURT,8310,BIGNICOURT,,49.3901473074,4.42198979893,66,,Bignicourt,Bignicourt,8,Ardennes,44,Grand Est
8067,BLAGNY,8110,BLAGNY,,49.6239121874,5.19807738578,67,,Blagny,Blagny,8,Ardennes,44,Grand Est
8069,BLANCHEFOSSE ET BAY,8290,BLANCHEFOSSE ET BAY,,49.7665831974,4.25235197584,69,,Blanchefosse-et-Bay,Blanchefosse-et-Bay,8,Ardennes,44,Grand Est
8069,BLANCHEFOSSE ET BAY,8290,BLANCHEFOSSE ET BAY,BAY,49.7665831974,4.25235197584,69,,Blanchefosse-et-Bay,Blanchefosse-et-Bay,8,Ardennes,44,Grand Est
8070,BLANZY LA SALONNAISE,8190,BLANZY LA SALONNAISE,,49.4878479316,4.19048684048,70,,Blanzy-la-Salonnaise,Blanzy-la-Salonnaise,8,Ardennes,44,Grand Est
8071,BLOMBAY,8260,BLOMBAY,,49.8159741255,4.45410147722,71,,Blombay,Blombay,8,Ardennes,44,Grand Est
8072,BOSSEVAL ET BRIANCOURT,8350,BOSSEVAL ET BRIANCOURT,,49.7636993145,4.86885405706,72,,Bosseval-et-Briancourt,Bosseval-et-Briancourt,8,Ardennes,44,Grand Est
8073,BOSSUS LES RUMIGNY,8290,BOSSUS LES RUMIGNY,,49.8398418664,4.25581170778,73,,Bossus-lès-Rumigny,Bossus-lès-Rumigny,8,Ardennes,44,Grand Est
8074,BOUCONVILLE,8250,BOUCONVILLE,,49.2583527847,4.77291946342,74,,Bouconville,Bouconville,8,Ardennes,44,Grand Est
8075,BOULT AUX BOIS,8240,BOULT AUX BOIS,,49.4191409109,4.83535525707,75,,Boult-aux-Bois,Boult-aux-Bois,8,Ardennes,44,Grand Est
8076,BOULZICOURT,8410,BOULZICOURT,,49.6898690039,4.69742034088,76,,Boulzicourt,Boulzicourt,8,Ardennes,44,Grand Est
8077,BOURCQ,8400,BOURCQ,,49.3822218647,4.61799279484,77,,Bourcq,Bourcq,8,Ardennes,44,Grand Est
8078,BOURG FIDELE,8230,BOURG FIDELE,,49.8889233145,4.55443692603,78,,Bourg-Fidèle,Bourg-Fidèle,8,Ardennes,44,Grand Est
8079,BOUTANCOURT,8160,BOUTANCOURT,,49.6815427414,4.76990515866,79,,Boutancourt,Boutancourt,8,Ardennes,44,Grand Est
8080,BOUVELLEMONT,8430,BOUVELLEMONT,,49.583627508,4.66481668205,80,,Bouvellemont,Bouvellemont,8,Ardennes,44,Grand Est
8081,BOGNY SUR MEUSE,8120,BOGNY SUR MEUSE,,49.8477354025,4.7508301548,81,,Bogny-sur-Meuse,Bogny-sur-Meuse,8,Ardennes,44,Grand Est
8082,BRECY BRIERES,8400,BRECY BRIERES,,49.3320872015,4.75555118868,82,,Brécy-Brières,Brécy-Brières,8,Ardennes,44,Grand Est
8083,BREVILLY,8140,BREVILLY,,49.6589910342,5.07963407809,83,,Brévilly,Brévilly,8,Ardennes,44,Grand Est
8084,BRIENNE SUR AISNE,8190,BRIENNE SUR AISNE,,49.4260069494,4.06243994616,84,,Brienne-sur-Aisne,Brienne-sur-Aisne,8,Ardennes,44,Grand Est
8085,BRIEULLES SUR BAR,8240,BRIEULLES SUR BAR,,49.481616678,4.86131007494,85,,Brieulles-sur-Bar,Brieulles-sur-Bar,8,Ardennes,44,Grand Est
8086,BRIQUENAY,8240,BRIQUENAY,,49.4034934616,4.86933971508,86,,Briquenay,Briquenay,8,Ardennes,44,Grand Est
8087,BROGNON,8380,BROGNON,,49.9327420219,4.28047046988,87,,Brognon,Brognon,8,Ardennes,44,Grand Est
8088,BULSON,8450,BULSON,,49.6288213726,4.91421651084,88,,Bulson,Bulson,8,Ardennes,44,Grand Est
8089,BUZANCY,8240,BUZANCY,,49.418434996,4.97363448798,89,,Buzancy,Buzancy,8,Ardennes,44,Grand Est
8089,BUZANCY,8240,BUZANCY,SIVRY LES BUZANCY,49.418434996,4.97363448798,89,,Buzancy,Buzancy,8,Ardennes,44,Grand Est
8090,CARIGNAN,8110,CARIGNAN,,49.6387329311,5.1718269254,90,,Carignan,Carignan,8,Ardennes,44,Grand Est
8090,CARIGNAN,8110,CARIGNAN,WE,49.6387329311,5.1718269254,90,,Carignan,Carignan,8,Ardennes,44,Grand Est
8092,CAUROY,8310,CAUROY,,49.3512411409,4.45940865282,92,,Cauroy,Cauroy,8,Ardennes,44,Grand Est
8094,CERNION,8260,CERNION,,49.7993830158,4.42616779121,94,,Cernion,Cernion,8,Ardennes,44,Grand Est
8095,CHAGNY,8430,CHAGNY,,49.5720292475,4.69971401833,95,,Chagny,Chagny,8,Ardennes,44,Grand Est
8096,CHALANDRY ELAIRE,8160,CHALANDRY ELAIRE,,49.7077474752,4.75646154251,96,,Chalandry-Elaire,Chalandry-Elaire,8,Ardennes,44,Grand Est
8097,CHALLERANGE,8400,CHALLERANGE,,49.3083659488,4.74594763415,97,,Challerange,Challerange,8,Ardennes,44,Grand Est
8098,CHAMPIGNEULLE,8250,CHAMPIGNEULLE,,49.3514936339,4.9279688945,98,,Champigneulle,Champigneulle,8,Ardennes,44,Grand Est
8099,CHAMPIGNEUL SUR VENCE,8430,CHAMPIGNEUL SUR VENCE,,49.7043891652,4.66376922101,99,,Champigneul-sur-Vence,Champigneul-sur-Vence,8,Ardennes,44,Grand Est
8100,CHAMPLIN,8260,CHAMPLIN,,49.8350055566,4.32636290916,100,,Champlin,Champlin,8,Ardennes,44,Grand Est
8101,LA CHAPELLE,8200,LA CHAPELLE,,49.7607573258,5.02411858823,101,La,Chapelle,La Chapelle,8,Ardennes,44,Grand Est
8102,CHAPPES,8220,CHAPPES,,49.6074225712,4.2794051826,102,,Chappes,Chappes,8,Ardennes,44,Grand Est
8103,CHARBOGNE,8130,CHARBOGNE,,49.5061470622,4.59006670557,103,,Charbogne,Charbogne,8,Ardennes,44,Grand Est
8104,CHARDENY,8400,CHARDENY,,49.4194659277,4.59579314559,104,,Chardeny,Chardeny,8,Ardennes,44,Grand Est
8105,CHARLEVILLE MEZIERES,8000,CHARLEVILLE MEZIERES,,49.7752965803,4.71724655966,105,,Charleville-Mézières,Charleville-Mézières,8,Ardennes,44,Grand Est
8106,CHARNOIS,8600,CHARNOIS,,50.1025146166,4.83111370773,106,,Charnois,Charnois,8,Ardennes,44,Grand Est
8107,CHATEAU PORCIEN,8360,CHATEAU PORCIEN,,49.5340250071,4.24761875411,107,,Château-Porcien,Château-Porcien,8,Ardennes,44,Grand Est
8109,CHATEL CHEHERY,8250,CHATEL CHEHERY,,49.2790366403,4.95238223114,109,,Chatel-Chéhéry,Chatel-Chéhéry,8,Ardennes,44,Grand Est
8110,LE CHATELET SUR SORMONNE,8150,LE CHATELET SUR SORMONNE,,49.8401358132,4.51748909775,110,Le,Châtelet-sur-Sormonne,Le Châtelet-sur-Sormonne,8,Ardennes,44,Grand Est
8111,LE CHATELET SUR RETOURNE,8300,LE CHATELET SUR RETOURNE,,49.4102528694,4.27415473111,111,Le,Châtelet-sur-Retourne,Le Châtelet-sur-Retourne,8,Ardennes,44,Grand Est
8113,CHAUMONT PORCIEN,8220,CHAUMONT PORCIEN,,49.6486344865,4.23823742678,113,,Chaumont-Porcien,Chaumont-Porcien,8,Ardennes,44,Grand Est
8113,CHAUMONT PORCIEN,8220,CHAUMONT PORCIEN,LOGNY LES CHAUMONT,49.6486344865,4.23823742678,113,,Chaumont-Porcien,Chaumont-Porcien,8,Ardennes,44,Grand Est
8113,CHAUMONT PORCIEN,8220,CHAUMONT PORCIEN,WADIMONT,49.6486344865,4.23823742678,113,,Chaumont-Porcien,Chaumont-Porcien,8,Ardennes,44,Grand Est
8115,CHEMERY CHEHERY,8350,CHEMERY CHEHERY,CHEHERY,49.6121866829,4.86796266494,115,,Chémery-Chéhéry,Chémery-Chéhéry,8,Ardennes,44,Grand Est
8115,CHEMERY CHEHERY,8450,CHEMERY CHEHERY,,49.6121866829,4.86796266494,115,,Chémery-Chéhéry,Chémery-Chéhéry,8,Ardennes,44,Grand Est
8115,CHEMERY CHEHERY,8450,CHEMERY CHEHERY,CONNAGE,49.6121866829,4.86796266494,115,,Chémery-Chéhéry,Chémery-Chéhéry,8,Ardennes,44,Grand Est
8115,CHEMERY CHEHERY,8450,CHEMERY CHEHERY,MALMY,49.6121866829,4.86796266494,115,,Chémery-Chéhéry,Chémery-Chéhéry,8,Ardennes,44,Grand Est
8116,BAIRON ET SES ENVIRONS,8390,BAIRON ET SES ENVIRONS,LE CHESNE,49.511753823,4.76986600812,116,,Bairon et ses environs,Bairon et ses environs,8,Ardennes,44,Grand Est
8116,BAIRON ET SES ENVIRONS,8390,BAIRON ET SES ENVIRONS,LOUVERGNY,49.511753823,4.76986600812,116,,Bairon et ses environs,Bairon et ses environs,8,Ardennes,44,Grand Est
8116,BAIRON ET SES ENVIRONS,8400,BAIRON ET SES ENVIRONS,LES ALLEUX,49.511753823,4.76986600812,116,,Bairon et ses environs,Bairon et ses environs,8,Ardennes,44,Grand Est
8117,CHESNOIS AUBONCOURT,8270,CHESNOIS AUBONCOURT,,49.5610454523,4.56741706583,117,,Chesnois-Auboncourt,Chesnois-Auboncourt,8,Ardennes,44,Grand Est
8119,CHEVEUGES,8350,CHEVEUGES,,49.6628247903,4.88904844255,119,,Cheveuges,Cheveuges,8,Ardennes,44,Grand Est
8120,CHEVIERES,8250,CHEVIERES,,49.3232883064,4.89352975191,120,,Chevières,Chevières,8,Ardennes,44,Grand Est
8121,CHILLY,8260,CHILLY,,49.8419441004,4.47063788106,121,,Chilly,Chilly,8,Ardennes,44,Grand Est
8122,CHOOZ,8600,CHOOZ,,50.0907731291,4.80080378655,122,,Chooz,Chooz,8,Ardennes,44,Grand Est
8123,CHUFFILLY ROCHE,8130,CHUFFILLY ROCHE,,49.4510357652,4.62193241864,123,,Chuffilly-Roche,Chuffilly-Roche,8,Ardennes,44,Grand Est
8124,CLAVY WARBY,8460,CLAVY WARBY,,49.7451097313,4.54792951567,124,,Clavy-Warby,Clavy-Warby,8,Ardennes,44,Grand Est
8125,CLIRON,8090,CLIRON,,49.8088052199,4.6118553488,125,,Cliron,Cliron,8,Ardennes,44,Grand Est
8126,CONDE LES HERPY,8360,CONDE LES HERPY,,49.5425026908,4.19795434047,126,,Condé-lès-Herpy,Condé-lès-Herpy,8,Ardennes,44,Grand Est
8128,CONDE LES AUTRY,8250,CONDE LES AUTRY,,49.2451972649,4.85549543299,128,,Condé-lès-Autry,Condé-lès-Autry,8,Ardennes,44,Grand Est
8130,CONTREUVE,8400,CONTREUVE,,49.3619911598,4.61559567137,130,,Contreuve,Contreuve,8,Ardennes,44,Grand Est
8131,CORNAY,8250,CORNAY,,49.2986310738,4.93353443761,131,,Cornay,Cornay,8,Ardennes,44,Grand Est
8132,CORNY MACHEROMENIL,8270,CORNY MACHEROMENIL,,49.5821949538,4.45459243485,132,,Corny-Machéroménil,Corny-Machéroménil,8,Ardennes,44,Grand Est
8133,COUCY,8300,COUCY,,49.5095800024,4.46207840046,133,,Coucy,Coucy,8,Ardennes,44,Grand Est
8134,COULOMMES ET MARQUENY,8130,COULOMMES ET MARQUENY,,49.4295524989,4.57430150955,134,,Coulommes-et-Marqueny,Coulommes-et-Marqueny,8,Ardennes,44,Grand Est
8135,LA CROIX AUX BOIS,8400,LA CROIX AUX BOIS,,49.4054523521,4.78917859814,135,La,Croix-aux-Bois,La Croix-aux-Bois,8,Ardennes,44,Grand Est
8136,DAIGNY,8140,DAIGNY,,49.7049209581,5.0012134779,136,,Daigny,Daigny,8,Ardennes,44,Grand Est
8137,DAMOUZY,8090,DAMOUZY,,49.8078625356,4.68275479067,137,,Damouzy,Damouzy,8,Ardennes,44,Grand Est
8138,LES DEUX VILLES,8110,LES DEUX VILLES,,49.6544288446,5.23054948304,138,Les,Deux-Villes,Les Deux-Villes,8,Ardennes,44,Grand Est
8139,DEVILLE,8800,DEVILLE,,49.8750647112,4.69366948073,139,,Deville,Deville,8,Ardennes,44,Grand Est
8140,DOM LE MESNIL,8160,DOM LE MESNIL,,49.6858585977,4.80997399118,140,,Dom-le-Mesnil,Dom-le-Mesnil,8,Ardennes,44,Grand Est
8140,DOM LE MESNIL,8160,DOM LE MESNIL,PONT A BAR,49.6858585977,4.80997399118,140,,Dom-le-Mesnil,Dom-le-Mesnil,8,Ardennes,44,Grand Est
8141,DOMMERY,8460,DOMMERY,,49.6799788217,4.48172197244,141,,Dommery,Dommery,8,Ardennes,44,Grand Est
8142,DONCHERY,8350,DONCHERY,,49.7374565797,4.89027824131,142,,Donchery,Donchery,8,Ardennes,44,Grand Est
8143,DOUMELY BEGNY,8220,DOUMELY BEGNY,,49.6337317142,4.30573701489,143,,Doumely-Bégny,Doumely-Bégny,8,Ardennes,44,Grand Est
8144,DOUX,8300,DOUX,,49.516603776,4.42838018465,144,,Doux,Doux,8,Ardennes,44,Grand Est
8145,DOUZY,8140,DOUZY,,49.6732112,5.03586246087,145,,Douzy,Douzy,8,Ardennes,44,Grand Est
8145,DOUZY,8140,DOUZY,MAIRY,49.6732112,5.03586246087,145,,Douzy,Douzy,8,Ardennes,44,Grand Est
8146,DRAIZE,8220,DRAIZE,,49.6529770497,4.33345611843,146,,Draize,Draize,8,Ardennes,44,Grand Est
8147,DRICOURT,8310,DRICOURT,,49.3935675536,4.51560795965,147,,Dricourt,Dricourt,8,Ardennes,44,Grand Est
8148,L ECAILLE,8300,L ECAILLE,,49.4095997981,4.20955430997,148,L',Écaille,L'Écaille,8,Ardennes,44,Grand Est
8149,L ECHELLE,8150,L ECHELLE,,49.8052823927,4.48398953543,149,L',Échelle,L'Échelle,8,Ardennes,44,Grand Est
8150,ECLY,8300,ECLY,,49.5544898631,4.28769534327,150,,Écly,Écly,8,Ardennes,44,Grand Est
8151,ECORDAL,8130,ECORDAL,,49.5343083794,4.58778435914,151,,Écordal,Écordal,8,Ardennes,44,Grand Est
8152,ELAN,8160,ELAN,,49.6560633013,4.75289542099,152,,Élan,Élan,8,Ardennes,44,Grand Est
8153,ESCOMBRES ET LE CHESNOIS,8110,ESCOMBRES ET LE CHESNOIS,,49.697280399,5.12609721908,153,,Escombres-et-le-Chesnois,Escombres-et-le-Chesnois,8,Ardennes,44,Grand Est
8154,ESTREBAY,8260,ESTREBAY,,49.8253440088,4.34568774297,154,,Estrebay,Estrebay,8,Ardennes,44,Grand Est
8155,ETALLE,8260,ETALLE,,49.8528034918,4.44618670271,155,,Étalle,Étalle,8,Ardennes,44,Grand Est
8156,ETEIGNIERES,8260,ETEIGNIERES,,49.8907338972,4.40306549202,156,,Éteignières,Éteignières,8,Ardennes,44,Grand Est
8158,ETREPIGNY,8160,ETREPIGNY,,49.6869749564,4.7486340191,158,,Étrépigny,Étrépigny,8,Ardennes,44,Grand Est
8159,EUILLY ET LOMBUT,8210,EUILLY ET LOMBUT,,49.6288481448,5.11368214907,159,,Euilly-et-Lombut,Euilly-et-Lombut,8,Ardennes,44,Grand Est
8160,EVIGNY,8090,EVIGNY,,49.7271036646,4.6755894996,160,,Évigny,Évigny,8,Ardennes,44,Grand Est
8161,EXERMONT,8250,EXERMONT,,49.2945448996,5.02222022722,161,,Exermont,Exermont,8,Ardennes,44,Grand Est
8162,FAGNON,8090,FAGNON,,49.7325267992,4.61914614937,162,,Fagnon,Fagnon,8,Ardennes,44,Grand Est
8163,FAISSAULT,8270,FAISSAULT,,49.6089496847,4.50566340675,163,,Faissault,Faissault,8,Ardennes,44,Grand Est
8164,FALAISE,8400,FALAISE,,49.383860062,4.7411656616,164,,Falaise,Falaise,8,Ardennes,44,Grand Est
8165,FAUX,8270,FAUX,,49.5369829788,4.50393939951,165,,Faux,Faux,8,Ardennes,44,Grand Est
8166,FEPIN,8170,FEPIN,,50.0221616884,4.70723354036,166,,Fépin,Fépin,8,Ardennes,44,Grand Est
8167,LA FEREE,8290,LA FEREE,,49.7581958842,4.30411303081,167,La,Férée,La Férée,8,Ardennes,44,Grand Est
8168,LA FERTE SUR CHIERS,8370,LA FERTE SUR CHIERS,,49.5723724698,5.24000841484,168,La,Ferté-sur-Chiers,La Ferté-sur-Chiers,8,Ardennes,44,Grand Est
8169,FLAIGNES HAVYS,8260,FLAIGNES HAVYS,,49.8189516311,4.39912003911,169,,Flaignes-Havys,Flaignes-Havys,8,Ardennes,44,Grand Est
8169,FLAIGNES HAVYS,8260,FLAIGNES HAVYS,HAVYS,49.8189516311,4.39912003911,169,,Flaignes-Havys,Flaignes-Havys,8,Ardennes,44,Grand Est
8170,FLEIGNEUX,8200,FLEIGNEUX,,49.7761707649,4.96364089015,170,,Fleigneux,Fleigneux,8,Ardennes,44,Grand Est
8171,FLEVILLE,8250,FLEVILLE,,49.3088637287,4.98128015071,171,,Fléville,Fléville,8,Ardennes,44,Grand Est
8172,FLIGNY,8380,FLIGNY,,49.8835632271,4.26492638229,172,,Fligny,Fligny,8,Ardennes,44,Grand Est
8173,FLIZE,8160,FLIZE,,49.6952977524,4.77517346922,173,,Flize,Flize,8,Ardennes,44,Grand Est
8174,FLOING,8200,FLOING,,49.7233281136,4.93287278052,174,,Floing,Floing,8,Ardennes,44,Grand Est
8175,FOISCHES,8600,FOISCHES,,50.129087843,4.77469221045,175,,Foisches,Foisches,8,Ardennes,44,Grand Est
8176,FOSSE,8240,FOSSE,,49.4497857688,5.00506242697,176,,Fossé,Fossé,8,Ardennes,44,Grand Est
8178,FRAILLICOURT,8220,FRAILLICOURT,,49.6642796699,4.1683433147,178,,Fraillicourt,Fraillicourt,8,Ardennes,44,Grand Est
8179,FRANCHEVAL,8140,FRANCHEVAL,,49.720311451,5.07545452741,179,,Francheval,Francheval,8,Ardennes,44,Grand Est
8180,LA FRANCHEVILLE,8000,LA FRANCHEVILLE,,49.7255789105,4.70732068022,180,La,Francheville,La Francheville,8,Ardennes,44,Grand Est
8182,LE FRETY,8290,LE FRETY,,49.734490847,4.26196353021,182,Le,Fréty,Le Fréty,8,Ardennes,44,Grand Est
8183,FROMELENNES,8600,FROMELENNES,,50.114956311,4.86298935774,183,,Fromelennes,Fromelennes,8,Ardennes,44,Grand Est
8183,FROMELENNES,8600,FROMELENNES,FLOHIMONT,50.114956311,4.86298935774,183,,Fromelennes,Fromelennes,8,Ardennes,44,Grand Est
8184,FROMY,8370,FROMY,,49.6013937108,5.25624601587,184,,Fromy,Fromy,8,Ardennes,44,Grand Est
8185,FUMAY,8170,FUMAY,,49.9726262845,4.66006812542,185,,Fumay,Fumay,8,Ardennes,44,Grand Est
8186,GERMONT,8240,GERMONT,,49.432933415,4.87916676236,186,,Germont,Germont,8,Ardennes,44,Grand Est
8187,GERNELLE,8440,GERNELLE,,49.7731869901,4.82389442529,187,,Gernelle,Gernelle,8,Ardennes,44,Grand Est
8188,GESPUNSART,8700,GESPUNSART,,49.8176588618,4.84437565328,188,,Gespunsart,Gespunsart,8,Ardennes,44,Grand Est
8189,GIRONDELLE,8260,GIRONDELLE,,49.8523190175,4.38644508861,189,,Girondelle,Girondelle,8,Ardennes,44,Grand Est
8189,GIRONDELLE,8260,GIRONDELLE,FOULZY,49.8523190175,4.38644508861,189,,Girondelle,Girondelle,8,Ardennes,44,Grand Est
8190,GIVET,8600,GIVET,,50.1424845969,4.83512248031,190,,Givet,Givet,8,Ardennes,44,Grand Est
8191,GIVONNE,8200,GIVONNE,,49.732476511,4.99727933761,191,,Givonne,Givonne,8,Ardennes,44,Grand Est
8192,GIVRON,8220,GIVRON,,49.6564549525,4.29619414129,192,,Givron,Givron,8,Ardennes,44,Grand Est
8193,GIVRY,8130,GIVRY,,49.4889972004,4.53139534067,193,,Givry,Givry,8,Ardennes,44,Grand Est
8194,GLAIRE,8200,GLAIRE,,49.7168781678,4.9057121108,194,,Glaire,Glaire,8,Ardennes,44,Grand Est
8195,GOMONT,8190,GOMONT,,49.5102104714,4.1678828444,195,,Gomont,Gomont,8,Ardennes,44,Grand Est
8196,GRANDCHAMP,8270,GRANDCHAMP,,49.6525314481,4.40676539021,196,,Grandchamp,Grandchamp,8,Ardennes,44,Grand Est
8197,GRANDHAM,8250,GRANDHAM,,49.2909334684,4.85500439411,197,,Grandham,Grandham,8,Ardennes,44,Grand Est
8198,GRANDPRE,8250,GRANDPRE,,49.3557413178,4.86541939254,198,,Grandpré,Grandpré,8,Ardennes,44,Grand Est
8198,GRANDPRE,8250,GRANDPRE,TERMES,49.3557413178,4.86541939254,198,,Grandpré,Grandpré,8,Ardennes,44,Grand Est
8199,LA GRANDVILLE,8700,LA GRANDVILLE,,49.7864206755,4.80382569831,199,La,Grandville,La Grandville,8,Ardennes,44,Grand Est
8200,GRIVY LOISY,8400,GRIVY LOISY,,49.426649562,4.64037015346,200,,Grivy-Loisy,Grivy-Loisy,8,Ardennes,44,Grand Est
8201,GRUYERES,8430,GRUYERES,,49.7096886364,4.60418894057,201,,Gruyères,Gruyères,8,Ardennes,44,Grand Est
8202,GUE D HOSSUS,8230,GUE D HOSSUS,,49.9534600751,4.53901296551,202,,Gué-d'Hossus,Gué-d'Hossus,8,Ardennes,44,Grand Est
8203,GUIGNICOURT SUR VENCE,8430,GUIGNICOURT SUR VENCE,,49.6886825808,4.63302128326,203,,Guignicourt-sur-Vence,Guignicourt-sur-Vence,8,Ardennes,44,Grand Est
8204,GUINCOURT,8130,GUINCOURT,,49.5563378123,4.62813425759,204,,Guincourt,Guincourt,8,Ardennes,44,Grand Est
8205,HAGNICOURT,8430,HAGNICOURT,,49.6109645612,4.58423752594,205,,Hagnicourt,Hagnicourt,8,Ardennes,44,Grand Est
8206,HAM LES MOINES,8090,HAM LES MOINES,,49.7946547958,4.58829051555,206,,Ham-les-Moines,Ham-les-Moines,8,Ardennes,44,Grand Est
8207,HAM SUR MEUSE,8600,HAM SUR MEUSE,,50.0894618864,4.78296461056,207,,Ham-sur-Meuse,Ham-sur-Meuse,8,Ardennes,44,Grand Est
8208,HANNAPPES,8290,HANNAPPES,,49.8168662674,4.23446602627,208,,Hannappes,Hannappes,8,Ardennes,44,Grand Est
8209,HANNOGNE ST MARTIN,8160,HANNOGNE ST MARTIN,,49.67277982,4.82911892902,209,,Hannogne-Saint-Martin,Hannogne-Saint-Martin,8,Ardennes,44,Grand Est
8210,HANNOGNE ST REMY,8220,HANNOGNE ST REMY,,49.6062120313,4.14321052174,210,,Hannogne-Saint-Rémy,Hannogne-Saint-Rémy,8,Ardennes,44,Grand Est
8211,HARAUCOURT,8450,HARAUCOURT,,49.6241121193,4.95360724408,211,,Haraucourt,Haraucourt,8,Ardennes,44,Grand Est
8212,HARCY,8150,HARCY,,49.8527000599,4.55802852688,212,,Harcy,Harcy,8,Ardennes,44,Grand Est
8214,HARGNIES,8170,HARGNIES,,50.0091831933,4.79099611271,214,,Hargnies,Hargnies,8,Ardennes,44,Grand Est
8215,HARRICOURT,8240,HARRICOURT,,49.439083054,4.92347818635,215,,Harricourt,Harricourt,8,Ardennes,44,Grand Est
8216,HAUDRECY,8090,HAUDRECY,,49.7836785457,4.61216217842,216,,Haudrecy,Haudrecy,8,Ardennes,44,Grand Est
8217,HAULME,8800,HAULME,,49.8579040636,4.79255790765,217,,Haulmé,Haulmé,8,Ardennes,44,Grand Est
8218,LES HAUTES RIVIERES,8800,LES HAUTES RIVIERES,,49.9089878632,4.84738950465,218,Les,Hautes-Rivières,Les Hautes-Rivières,8,Ardennes,44,Grand Est
8218,LES HAUTES RIVIERES,8800,LES HAUTES RIVIERES,LINCHAMPS,49.9089878632,4.84738950465,218,Les,Hautes-Rivières,Les Hautes-Rivières,8,Ardennes,44,Grand Est
8219,HAUTEVILLE,8300,HAUTEVILLE,,49.5827671359,4.2987122942,219,,Hauteville,Hauteville,8,Ardennes,44,Grand Est
8220,HAUVINE,8310,HAUVINE,,49.3159803218,4.41170841421,220,,Hauviné,Hauviné,8,Ardennes,44,Grand Est
8222,HAYBES,8170,HAYBES,,49.9928060436,4.73512767453,222,,Haybes,Haybes,8,Ardennes,44,Grand Est
8223,HERBEUVAL,8370,HERBEUVAL,,49.6014736891,5.35301379214,223,,Herbeuval,Herbeuval,8,Ardennes,44,Grand Est
8225,HERPY L ARLESIENNE,8360,HERPY L ARLESIENNE,,49.5259538637,4.18823352667,225,,Herpy-l'Arlésienne,Herpy-l'Arlésienne,8,Ardennes,44,Grand Est
8226,HIERGES,8320,HIERGES,,50.0988093717,4.73380069028,226,,Hierges,Hierges,8,Ardennes,44,Grand Est
8228,LA HORGNE,8430,LA HORGNE,,49.623710068,4.67671887567,228,La,Horgne,La Horgne,8,Ardennes,44,Grand Est
8229,HOUDILCOURT,8190,HOUDILCOURT,,49.4226756545,4.1256886512,229,,Houdilcourt,Houdilcourt,8,Ardennes,44,Grand Est
8230,HOULDIZY,8090,HOULDIZY,,49.812785683,4.6695029327,230,,Houldizy,Houldizy,8,Ardennes,44,Grand Est
8232,ILLY,8200,ILLY,,49.7575218759,4.98043472837,232,,Illy,Illy,8,Ardennes,44,Grand Est
8233,IMECOURT,8240,IMECOURT,,49.3711784415,4.97493982778,233,,Imécourt,Imécourt,8,Ardennes,44,Grand Est
8234,INAUMONT,8300,INAUMONT,,49.5628562714,4.31902902428,234,,Inaumont,Inaumont,8,Ardennes,44,Grand Est
8235,ISSANCOURT ET RUMEL,8440,ISSANCOURT ET RUMEL,,49.756913062,4.82778351995,235,,Issancourt-et-Rumel,Issancourt-et-Rumel,8,Ardennes,44,Grand Est
8236,JANDUN,8430,JANDUN,,49.6747371177,4.55946990967,236,,Jandun,Jandun,8,Ardennes,44,Grand Est
8237,JOIGNY SUR MEUSE,8700,JOIGNY SUR MEUSE,,49.8321076843,4.76385704202,237,,Joigny-sur-Meuse,Joigny-sur-Meuse,8,Ardennes,44,Grand Est
8238,JONVAL,8130,JONVAL,,49.5691976392,4.66304334779,238,,Jonval,Jonval,8,Ardennes,44,Grand Est
8239,JUNIVILLE,8310,JUNIVILLE,,49.3942990916,4.37988598391,239,,Juniville,Juniville,8,Ardennes,44,Grand Est
8240,JUSTINE HERBIGNY,8270,JUSTINE HERBIGNY,,49.6051538292,4.32575291795,240,,Justine-Herbigny,Justine-Herbigny,8,Ardennes,44,Grand Est
8242,LAIFOUR,8800,LAIFOUR,,49.902088382,4.69565397804,242,,Laifour,Laifour,8,Ardennes,44,Grand Est
8242,LAIFOUR,8800,LAIFOUR,LA PETITE COMMUNE,49.902088382,4.69565397804,242,,Laifour,Laifour,8,Ardennes,44,Grand Est
8243,LALOBBE,8460,LALOBBE,,49.6690137375,4.37030357977,243,,Lalobbe,Lalobbe,8,Ardennes,44,Grand Est
8244,LAMETZ,8130,LAMETZ,,49.5265430662,4.68902629394,244,,Lametz,Lametz,8,Ardennes,44,Grand Est
8245,LANCON,8250,LANCON,,49.2748052641,4.88800053643,245,,Lançon,Lançon,8,Ardennes,44,Grand Est
8246,LANDRES ET ST GEORGES,8240,LANDRES ET ST GEORGES,,49.3522247331,5.01297002283,246,,Landres-et-Saint-Georges,Landres-et-Saint-Georges,8,Ardennes,44,Grand Est
8247,LANDRICHAMPS,8600,LANDRICHAMPS,,50.0794047362,4.82747884196,247,,Landrichamps,Landrichamps,8,Ardennes,44,Grand Est
8248,LAUNOIS SUR VENCE,8430,LAUNOIS SUR VENCE,,49.6645721589,4.52730784056,248,,Launois-sur-Vence,Launois-sur-Vence,8,Ardennes,44,Grand Est
8249,LAVAL MORENCY,8150,LAVAL MORENCY,,49.8301398821,4.49355605109,249,,Laval-Morency,Laval-Morency,8,Ardennes,44,Grand Est
8250,LEFFINCOURT,8310,LEFFINCOURT,,49.3814874349,4.55085342469,250,,Leffincourt,Leffincourt,8,Ardennes,44,Grand Est
8251,LEPRON LES VALLEES,8150,LEPRON LES VALLEES,,49.749207,4.45628552785,251,,Lépron-les-Vallées,Lépron-les-Vallées,8,Ardennes,44,Grand Est
8252,LETANNE,8210,LETANNE,,49.5441296129,5.08001114382,252,,Létanne,Létanne,8,Ardennes,44,Grand Est
8254,LIART,8290,LIART,,49.7612029643,4.34152094033,254,,Liart,Liart,8,Ardennes,44,Grand Est
8255,LINAY,8110,LINAY,,49.6161969407,5.22890359934,255,,Linay,Linay,8,Ardennes,44,Grand Est
8256,LIRY,8400,LIRY,,49.3047517017,4.6426157479,256,,Liry,Liry,8,Ardennes,44,Grand Est
8257,LOGNY BOGNY,8150,LOGNY BOGNY,,49.7757948758,4.38773514062,257,,Logny-Bogny,Logny-Bogny,8,Ardennes,44,Grand Est
8259,LONGWE,8400,LONGWE,,49.3862720162,4.80042380919,259,,Longwé,Longwé,8,Ardennes,44,Grand Est
8260,LONNY,8150,LONNY,,49.8154069628,4.58567968969,260,,Lonny,Lonny,8,Ardennes,44,Grand Est
8262,LUCQUY,8300,LUCQUY,,49.5371334292,4.47628128678,262,,Lucquy,Lucquy,8,Ardennes,44,Grand Est
8263,LUMES,8440,LUMES,,49.7351968419,4.78694401385,263,,Lumes,Lumes,8,Ardennes,44,Grand Est
8264,MACHAULT,8310,MACHAULT,,49.3529253335,4.50744242096,264,,Machault,Machault,8,Ardennes,44,Grand Est
8268,MAISONCELLE ET VILLERS,8450,MAISONCELLE ET VILLERS,,49.5898272419,4.91848316067,268,,Maisoncelle-et-Villers,Maisoncelle-et-Villers,8,Ardennes,44,Grand Est
8269,MALANDRY,8370,MALANDRY,,49.5756613588,5.18088695115,269,,Malandry,Malandry,8,Ardennes,44,Grand Est
8271,MANRE,8400,MANRE,,49.2568173255,4.65534541081,271,,Manre,Manre,8,Ardennes,44,Grand Est
8272,MARANWEZ,8460,MARANWEZ,,49.727693434,4.34150288978,272,,Maranwez,Maranwez,8,Ardennes,44,Grand Est
8273,MARBY,8260,MARBY,,49.8351105906,4.42694488413,273,,Marby,Marby,8,Ardennes,44,Grand Est
8274,MARCQ,8250,MARCQ,,49.3140144117,4.91977304358,274,,Marcq,Marcq,8,Ardennes,44,Grand Est
8275,MARGNY,8370,MARGNY,,49.6171394298,5.36137071894,275,,Margny,Margny,8,Ardennes,44,Grand Est
8276,MARGUT,8370,MARGUT,,49.5804831297,5.2715842279,276,,Margut,Margut,8,Ardennes,44,Grand Est
8277,MARLEMONT,8290,MARLEMONT,,49.7465157727,4.3812273313,277,,Marlemont,Marlemont,8,Ardennes,44,Grand Est
8278,MARQUIGNY,8390,MARQUIGNY,,49.5485160355,4.70534618848,278,,Marquigny,Marquigny,8,Ardennes,44,Grand Est
8279,MARS SOUS BOURCQ,8400,MARS SOUS BOURCQ,,49.4008386029,4.64097483841,279,,Mars-sous-Bourcq,Mars-sous-Bourcq,8,Ardennes,44,Grand Est
8280,MARVAUX VIEUX,8400,MARVAUX VIEUX,,49.2876432373,4.69052619792,280,,Marvaux-Vieux,Marvaux-Vieux,8,Ardennes,44,Grand Est
8281,MATTON ET CLEMENCY,8110,MATTON ET CLEMENCY,,49.6763970622,5.22558942774,281,,Matton-et-Clémency,Matton-et-Clémency,8,Ardennes,44,Grand Est
8282,MAUBERT FONTAINE,8260,MAUBERT FONTAINE,,49.8821419517,4.43262736768,282,,Maubert-Fontaine,Maubert-Fontaine,8,Ardennes,44,Grand Est
8283,MAZERNY,8430,MAZERNY,,49.610898748,4.61820857627,283,,Mazerny,Mazerny,8,Ardennes,44,Grand Est
8284,LES MAZURES,8500,LES MAZURES,,49.8968391326,4.63190460974,284,Les,Mazures,Les Mazures,8,Ardennes,44,Grand Est
8286,MENIL ANNELLES,8310,MENIL ANNELLES,,49.4366027969,4.44985286744,286,,Ménil-Annelles,Ménil-Annelles,8,Ardennes,44,Grand Est
8287,MENIL LEPINOIS,8310,MENIL LEPINOIS,,49.3745768771,4.28760480612,287,,Ménil-Lépinois,Ménil-Lépinois,8,Ardennes,44,Grand Est
8288,MESMONT,8270,MESMONT,,49.6185115837,4.3990996122,288,,Mesmont,Mesmont,8,Ardennes,44,Grand Est
8289,MESSINCOURT,8110,MESSINCOURT,,49.6912180369,5.15283901706,289,,Messincourt,Messincourt,8,Ardennes,44,Grand Est
8291,MOGUES,8110,MOGUES,,49.6597587514,5.28307640436,291,,Mogues,Mogues,8,Ardennes,44,Grand Est
8293,MOIRY,8370,MOIRY,,49.6001021932,5.27891887123,293,,Moiry,Moiry,8,Ardennes,44,Grand Est
8294,LA MONCELLE,8140,LA MONCELLE,,49.6929049378,4.99641991785,294,La,Moncelle,La Moncelle,8,Ardennes,44,Grand Est
8295,MONDIGNY,8430,MONDIGNY,,49.7118483439,4.63666533431,295,,Mondigny,Mondigny,8,Ardennes,44,Grand Est
8296,MONTCHEUTIN,8250,MONTCHEUTIN,,49.2882211618,4.80310887786,296,,Montcheutin,Montcheutin,8,Ardennes,44,Grand Est
8297,MONTCORNET,8090,MONTCORNET,,49.8356951738,4.65045194231,297,,Montcornet,Montcornet,8,Ardennes,44,Grand Est
8298,MONTCY NOTRE DAME,8090,MONTCY NOTRE DAME,,49.7906751202,4.74345986239,298,,Montcy-Notre-Dame,Montcy-Notre-Dame,8,Ardennes,44,Grand Est
8300,LE MONT DIEU,8390,LE MONT DIEU,,49.5494900352,4.85937044031,300,Le,Mont-Dieu,Le Mont-Dieu,8,Ardennes,44,Grand Est
8301,MONTGON,8390,MONTGON,,49.5124383754,4.71891434142,301,,Montgon,Montgon,8,Ardennes,44,Grand Est
8302,MONTHERME,8800,MONTHERME,,49.8995880396,4.74792062514,302,,Monthermé,Monthermé,8,Ardennes,44,Grand Est
8302,MONTHERME,8800,MONTHERME,LES HAUTS BUTTES,49.8995880396,4.74792062514,302,,Monthermé,Monthermé,8,Ardennes,44,Grand Est
8302,MONTHERME,8800,MONTHERME,PHADE,49.8995880396,4.74792062514,302,,Monthermé,Monthermé,8,Ardennes,44,Grand Est
8303,MONTHOIS,8400,MONTHOIS,,49.3091411656,4.70416534611,303,,Monthois,Monthois,8,Ardennes,44,Grand Est
8304,MONTIGNY SUR MEUSE,8170,MONTIGNY SUR MEUSE,,50.047712335,4.7107437226,304,,Montigny-sur-Meuse,Montigny-sur-Meuse,8,Ardennes,44,Grand Est
8305,MONTIGNY SUR VENCE,8430,MONTIGNY SUR VENCE,,49.6451389564,4.61254914109,305,,Montigny-sur-Vence,Montigny-sur-Vence,8,Ardennes,44,Grand Est
8306,MONT LAURENT,8130,MONT LAURENT,,49.4637340301,4.47834727509,306,,Mont-Laurent,Mont-Laurent,8,Ardennes,44,Grand Est
8307,MONTMEILLANT,8220,MONTMEILLANT,,49.7025931592,4.32852997041,307,,Montmeillant,Montmeillant,8,Ardennes,44,Grand Est
8308,MONT ST MARTIN,8400,MONT ST MARTIN,,49.3314585967,4.64133268542,308,,Mont-Saint-Martin,Mont-Saint-Martin,8,Ardennes,44,Grand Est
8309,MONT ST REMY,8310,MONT ST REMY,,49.3867440492,4.4768822942,309,,Mont-Saint-Remy,Mont-Saint-Remy,8,Ardennes,44,Grand Est
8310,MOURON,8250,MOURON,,49.3152143572,4.78476402519,310,,Mouron,Mouron,8,Ardennes,44,Grand Est
8311,MOUZON,8210,MOUZON,,49.5898672379,5.07669736879,311,,Mouzon,Mouzon,8,Ardennes,44,Grand Est
8311,MOUZON,8210,MOUZON,AMBLIMONT,49.5898672379,5.07669736879,311,,Mouzon,Mouzon,8,Ardennes,44,Grand Est
8312,MURTIN ET BOGNY,8150,MURTIN ET BOGNY,,49.8101486815,4.53946924815,312,,Murtin-et-Bogny,Murtin-et-Bogny,8,Ardennes,44,Grand Est
8313,NANTEUIL SUR AISNE,8300,NANTEUIL SUR AISNE,,49.5032675297,4.29634828525,313,,Nanteuil-sur-Aisne,Nanteuil-sur-Aisne,8,Ardennes,44,Grand Est
8314,NEUFLIZE,8300,NEUFLIZE,,49.4065310007,4.31198213958,314,,Neuflize,Neuflize,8,Ardennes,44,Grand Est
8315,NEUFMAISON,8460,NEUFMAISON,,49.7574971491,4.51243402336,315,,Neufmaison,Neufmaison,8,Ardennes,44,Grand Est
8316,NEUFMANIL,8700,NEUFMANIL,,49.8226585503,4.8066677893,316,,Neufmanil,Neufmanil,8,Ardennes,44,Grand Est
8317,LA NEUVILLE A MAIRE,8450,LA NEUVILLE A MAIRE,,49.579703757,4.8560124833,317,La,Neuville-à-Maire,La Neuville-à-Maire,8,Ardennes,44,Grand Est
8318,LA NEUVILLE AUX JOUTES,8380,LA NEUVILLE AUX JOUTES,,49.9331443854,4.24087440967,318,La,Neuville-aux-Joûtes,La Neuville-aux-Joûtes,8,Ardennes,44,Grand Est
8319,NEUVILLE LEZ BEAULIEU,8380,NEUVILLE LEZ BEAULIEU,,49.9062756338,4.35291936249,319,,Neuville-lez-Beaulieu,Neuville-lez-Beaulieu,8,Ardennes,44,Grand Est
8319,NEUVILLE LEZ BEAULIEU,8380,NEUVILLE LEZ BEAULIEU,BEAULIEU,49.9062756338,4.35291936249,319,,Neuville-lez-Beaulieu,Neuville-lez-Beaulieu,8,Ardennes,44,Grand Est
8320,LA NEUVILLE EN TOURNE A FUY,8310,NEUVILLE EN TOURNE A FUY,,49.3465795894,4.3925824819,320,La,Neuville-en-Tourne-à-Fuy,La Neuville-en-Tourne-à-Fuy,8,Ardennes,44,Grand Est
8321,NEUVILLE DAY,8130,NEUVILLE DAY,,49.5000423938,4.69190726788,321,,Neuville-Day,Neuville-Day,8,Ardennes,44,Grand Est
8322,NEUVILLE LES THIS,8090,NEUVILLE LES THIS,,49.7327847632,4.58984068094,322,,Neuville-lès-This,Neuville-lès-This,8,Ardennes,44,Grand Est
8323,LA NEUVILLE LES WASIGNY,8270,LA NEUVILLE LES WASIGNY,,49.6473588097,4.35965112592,323,La,Neuville-lès-Wasigny,La Neuville-lès-Wasigny,8,Ardennes,44,Grand Est
8324,NEUVIZY,8430,NEUVIZY,,49.626891276,4.53916344302,324,,Neuvizy,Neuvizy,8,Ardennes,44,Grand Est
8325,NOIRVAL,8400,NOIRVAL,,49.4583795403,4.79434525791,325,,Noirval,Noirval,8,Ardennes,44,Grand Est
8326,NOUART,8240,NOUART,,49.4505639742,5.05484805727,326,,Nouart,Nouart,8,Ardennes,44,Grand Est
8326,NOUART,8240,NOUART,LE CHAMPY,49.4505639742,5.05484805727,326,,Nouart,Nouart,8,Ardennes,44,Grand Est
8327,NOUVION SUR MEUSE,8160,NOUVION SUR MEUSE,,49.708839768,4.79759753674,327,,Nouvion-sur-Meuse,Nouvion-sur-Meuse,8,Ardennes,44,Grand Est
8328,NOUZONVILLE,8700,NOUZONVILLE,,49.819774473,4.75390800409,328,,Nouzonville,Nouzonville,8,Ardennes,44,Grand Est
8328,NOUZONVILLE,8700,NOUZONVILLE,MEILLIER FONTAINE,49.819774473,4.75390800409,328,,Nouzonville,Nouzonville,8,Ardennes,44,Grand Est
8329,NOVION PORCIEN,8270,NOVION PORCIEN,,49.5959529049,4.42856653433,329,,Novion-Porcien,Novion-Porcien,8,Ardennes,44,Grand Est
8329,NOVION PORCIEN,8270,NOVION PORCIEN,PROVISY,49.5959529049,4.42856653433,329,,Novion-Porcien,Novion-Porcien,8,Ardennes,44,Grand Est
8330,NOVY CHEVRIERES,8300,NOVY CHEVRIERES,,49.5461151318,4.44233077955,330,,Novy-Chevrières,Novy-Chevrières,8,Ardennes,44,Grand Est
8331,NOYERS PONT MAUGIS,8350,NOYERS PONT MAUGIS,,49.6588347678,4.9300445508,331,,Noyers-Pont-Maugis,Noyers-Pont-Maugis,8,Ardennes,44,Grand Est
8332,OCHES,8240,OCHES,,49.5072452442,4.92861182903,332,,Oches,Oches,8,Ardennes,44,Grand Est
8333,OLIZY PRIMAT,8250,OLIZY PRIMAT,,49.3578421825,4.78297338685,333,,Olizy-Primat,Olizy-Primat,8,Ardennes,44,Grand Est
8333,OLIZY PRIMAT,8250,OLIZY PRIMAT,PRIMAT,49.3578421825,4.78297338685,333,,Olizy-Primat,Olizy-Primat,8,Ardennes,44,Grand Est
8334,OMICOURT,8450,OMICOURT,,49.6297467119,4.82675764027,334,,Omicourt,Omicourt,8,Ardennes,44,Grand Est
8335,OMONT,8430,OMONT,,49.5934262613,4.73941860083,335,,Omont,Omont,8,Ardennes,44,Grand Est
8336,OSNES,8110,OSNES,,49.6585879647,5.15768970071,336,,Osnes,Osnes,8,Ardennes,44,Grand Est
8338,PAUVRES,8310,PAUVRES,,49.4128321727,4.50025321007,338,,Pauvres,Pauvres,8,Ardennes,44,Grand Est
8339,PERTHES,8300,PERTHES,,49.4486431408,4.35514092983,339,,Perthes,Perthes,8,Ardennes,44,Grand Est
8340,POILCOURT SYDNEY,8190,POILCOURT SYDNEY,,49.4196830149,4.09489893984,340,,Poilcourt-Sydney,Poilcourt-Sydney,8,Ardennes,44,Grand Est
8341,POIX TERRON,8430,POIX TERRON,,49.6441162488,4.65261121158,341,,Poix-Terron,Poix-Terron,8,Ardennes,44,Grand Est
8342,POURU AUX BOIS,8140,POURU AUX BOIS,,49.7100198144,5.09834036842,342,,Pouru-aux-Bois,Pouru-aux-Bois,8,Ardennes,44,Grand Est
8343,POURU ST REMY,8140,POURU ST REMY,,49.6809685609,5.08867231139,343,,Pouru-Saint-Remy,Pouru-Saint-Remy,8,Ardennes,44,Grand Est
8344,PREZ,8290,PREZ,,49.8014597717,4.36121089611,344,,Prez,Prez,8,Ardennes,44,Grand Est
8344,PREZ,8290,PREZ,LA CERLEAU,49.8014597717,4.36121089611,344,,Prez,Prez,8,Ardennes,44,Grand Est
8346,PRIX LES MEZIERES,8000,PRIX LES MEZIERES,,49.7480987384,4.6856741205,346,,Prix-lès-Mézières,Prix-lès-Mézières,8,Ardennes,44,Grand Est
8347,PUILLY ET CHARBEAUX,8370,PUILLY ET CHARBEAUX,,49.6363781013,5.27519284704,347,,Puilly-et-Charbeaux,Puilly-et-Charbeaux,8,Ardennes,44,Grand Est
8348,PUISEUX,8270,PUISEUX,,49.5944900597,4.53674361589,348,,Puiseux,Puiseux,8,Ardennes,44,Grand Est
8349,PURE,8110,PURE,,49.6818291341,5.18018679003,349,,Pure,Pure,8,Ardennes,44,Grand Est
8350,QUATRE CHAMPS,8400,QUATRE CHAMPS,,49.4459378032,4.77673410789,350,,Quatre-Champs,Quatre-Champs,8,Ardennes,44,Grand Est
8351,QUILLY,8400,QUILLY,,49.4054549894,4.58222403304,351,,Quilly,Quilly,8,Ardennes,44,Grand Est
8352,RAILLICOURT,8430,RAILLICOURT,,49.6525273198,4.59097690404,352,,Raillicourt,Raillicourt,8,Ardennes,44,Grand Est
8353,RANCENNES,8600,RANCENNES,,50.1212664141,4.82244516309,353,,Rancennes,Rancennes,8,Ardennes,44,Grand Est
8354,RAUCOURT ET FLABA,8450,RAUCOURT ET FLABA,,49.5891690543,4.96192418756,354,,Raucourt-et-Flaba,Raucourt-et-Flaba,8,Ardennes,44,Grand Est
8355,REGNIOWEZ,8230,REGNIOWEZ,,49.9257731775,4.41489817556,355,,Regniowez,Regniowez,8,Ardennes,44,Grand Est
8356,REMAUCOURT,8220,REMAUCOURT,,49.6012128351,4.23209127771,356,,Remaucourt,Remaucourt,8,Ardennes,44,Grand Est
8357,REMILLY AILLICOURT,8450,REMILLY AILLICOURT,,49.6485856467,4.99371291541,357,,Remilly-Aillicourt,Remilly-Aillicourt,8,Ardennes,44,Grand Est
8358,REMILLY LES POTHEES,8150,REMILLY LES POTHEES,,49.7839350894,4.54735597538,358,,Remilly-les-Pothées,Remilly-les-Pothées,8,Ardennes,44,Grand Est
8360,RENNEVILLE,8220,RENNEVILLE,,49.6508149655,4.13569086647,360,,Renneville,Renneville,8,Ardennes,44,Grand Est
8361,RENWEZ,8150,RENWEZ,,49.8491275921,4.60517090507,361,,Renwez,Renwez,8,Ardennes,44,Grand Est
8362,RETHEL,8300,RETHEL,,49.5131807048,4.3835241644,362,,Rethel,Rethel,8,Ardennes,44,Grand Est
8362,RETHEL,8300,RETHEL,PARGNY RESSON,49.5131807048,4.3835241644,362,,Rethel,Rethel,8,Ardennes,44,Grand Est
8363,REVIN,8500,REVIN,,49.9343612918,4.69218802167,363,,Revin,Revin,8,Ardennes,44,Grand Est
8364,RILLY SUR AISNE,8130,RILLY SUR AISNE,,49.4845290428,4.63687135237,364,,Rilly-sur-Aisne,Rilly-sur-Aisne,8,Ardennes,44,Grand Est
8365,RIMOGNE,8150,RIMOGNE,,49.8387403616,4.53539067168,365,,Rimogne,Rimogne,8,Ardennes,44,Grand Est
8366,ROCQUIGNY,8220,ROCQUIGNY,,49.7003603712,4.24698300901,366,,Rocquigny,Rocquigny,8,Ardennes,44,Grand Est
8366,ROCQUIGNY,8220,ROCQUIGNY,LA HARDOYE,49.7003603712,4.24698300901,366,,Rocquigny,Rocquigny,8,Ardennes,44,Grand Est
8366,ROCQUIGNY,8220,ROCQUIGNY,MAINBRESSON,49.7003603712,4.24698300901,366,,Rocquigny,Rocquigny,8,Ardennes,44,Grand Est
8366,ROCQUIGNY,8220,ROCQUIGNY,MAINBRESSY,49.7003603712,4.24698300901,366,,Rocquigny,Rocquigny,8,Ardennes,44,Grand Est
8367,ROCROI,8230,ROCROI,,49.9315002925,4.55842430748,367,,Rocroi,Rocroi,8,Ardennes,44,Grand Est
8367,ROCROI,8230,ROCROI,HIRAUMONT,49.9315002925,4.55842430748,367,,Rocroi,Rocroi,8,Ardennes,44,Grand Est
8367,ROCROI,8230,ROCROI,HONGREAU,49.9315002925,4.55842430748,367,,Rocroi,Rocroi,8,Ardennes,44,Grand Est
8368,ROIZY,8190,ROIZY,,49.4259330532,4.18557721616,368,,Roizy,Roizy,8,Ardennes,44,Grand Est
8369,LA ROMAGNE,8220,LA ROMAGNE,,49.6844748534,4.30631629815,369,La,Romagne,La Romagne,8,Ardennes,44,Grand Est
8370,ROUVROY SUR AUDRY,8150,ROUVROY SUR AUDRY,,49.7853360056,4.50242620208,370,,Rouvroy-sur-Audry,Rouvroy-sur-Audry,8,Ardennes,44,Grand Est
8370,ROUVROY SUR AUDRY,8150,ROUVROY SUR AUDRY,SERVION,49.7853360056,4.50242620208,370,,Rouvroy-sur-Audry,Rouvroy-sur-Audry,8,Ardennes,44,Grand Est
8371,RUBECOURT ET LAMECOURT,8140,RUBECOURT ET LAMECOURT,,49.6938022144,5.01813629169,371,,Rubécourt-et-Lamécourt,Rubécourt-et-Lamécourt,8,Ardennes,44,Grand Est
8372,RUBIGNY,8220,RUBIGNY,,49.6884295021,4.20625864095,372,,Rubigny,Rubigny,8,Ardennes,44,Grand Est
8373,RUMIGNY,8290,RUMIGNY,,49.8046346416,4.27087313138,373,,Rumigny,Rumigny,8,Ardennes,44,Grand Est
8374,LA SABOTTERIE,8130,LA SABOTTERIE,,49.5447543677,4.66996190814,374,La,Sabotterie,La Sabotterie,8,Ardennes,44,Grand Est
8375,SACHY,8110,SACHY,,49.6698224813,5.12802048203,375,,Sachy,Sachy,8,Ardennes,44,Grand Est
8376,SAILLY,8110,SAILLY,,49.6003253149,5.17350598808,376,,Sailly,Sailly,8,Ardennes,44,Grand Est
8377,ST AIGNAN,8350,ST AIGNAN,,49.6549320402,4.85224753054,377,,Saint-Aignan,Saint-Aignan,8,Ardennes,44,Grand Est
8378,ST CLEMENT A ARNES,8310,ST CLEMENT A ARNES,,49.296522428,4.43477270417,378,,Saint-Clément-à-Arnes,Saint-Clément-à-Arnes,8,Ardennes,44,Grand Est
8379,ST ETIENNE A ARNES,8310,ST ETIENNE A ARNES,,49.3075159297,4.51140608204,379,,Saint-Étienne-à-Arnes,Saint-Étienne-à-Arnes,8,Ardennes,44,Grand Est
8380,ST FERGEUX,8360,ST FERGEUX,,49.5733658942,4.20117468938,380,,Saint-Fergeux,Saint-Fergeux,8,Ardennes,44,Grand Est
8380,ST FERGEUX,8360,ST FERGEUX,CHAUDION,49.5733658942,4.20117468938,380,,Saint-Fergeux,Saint-Fergeux,8,Ardennes,44,Grand Est
8381,ST GERMAINMONT,8190,ST GERMAINMONT,,49.5206305047,4.13492327626,381,,Saint-Germainmont,Saint-Germainmont,8,Ardennes,44,Grand Est
8382,ST JEAN AUX BOIS,8220,ST JEAN AUX BOIS,,49.7249592025,4.30626886123,382,,Saint-Jean-aux-Bois,Saint-Jean-aux-Bois,8,Ardennes,44,Grand Est
8383,ST JUVIN,8250,ST JUVIN,,49.3375839286,4.95354630856,383,,Saint-Juvin,Saint-Juvin,8,Ardennes,44,Grand Est
8384,ST LAMBERT ET MONT DE JEUX,8130,ST LAMBERT ET MONT DE JEUX,,49.4980282741,4.62474617403,384,,Saint-Lambert-et-Mont-de-Jeux,Saint-Lambert-et-Mont-de-Jeux,8,Ardennes,44,Grand Est
8385,ST LAURENT,8090,ST LAURENT,,49.759138963,4.77607432501,385,,Saint-Laurent,Saint-Laurent,8,Ardennes,44,Grand Est
8386,ST LOUP EN CHAMPAGNE,8300,ST LOUP EN CHAMPAGNE,,49.4528238096,4.21534946763,386,,Saint-Loup-en-Champagne,Saint-Loup-en-Champagne,8,Ardennes,44,Grand Est
8387,ST LOUP TERRIER,8130,ST LOUP TERRIER,,49.5716750699,4.61321601262,387,,Saint-Loup-Terrier,Saint-Loup-Terrier,8,Ardennes,44,Grand Est
8388,ST MARCEAU,8160,ST MARCEAU,,49.7064000301,4.720080151,388,,Saint-Marceau,Saint-Marceau,8,Ardennes,44,Grand Est
8389,ST MARCEL,8460,ST MARCEL,,49.7678945166,4.57513627257,389,,Saint-Marcel,Saint-Marcel,8,Ardennes,44,Grand Est
8390,STE MARIE,8400,STE MARIE,,49.3713339748,4.67168297398,390,,Sainte-Marie,Sainte-Marie,8,Ardennes,44,Grand Est
8391,ST MENGES,8200,ST MENGES,,49.759865829,4.93156116451,391,,Saint-Menges,Saint-Menges,8,Ardennes,44,Grand Est
8392,ST MOREL,8400,ST MOREL,,49.3388158737,4.69739675474,392,,Saint-Morel,Saint-Morel,8,Ardennes,44,Grand Est
8393,ST PIERRE A ARNES,8310,ST PIERRE A ARNES,,49.2962803328,4.46414717799,393,,Saint-Pierre-à-Arnes,Saint-Pierre-à-Arnes,8,Ardennes,44,Grand Est
8394,ST PIERREMONT,8240,ST PIERREMONT,,49.4891932273,4.94533489491,394,,Saint-Pierremont,Saint-Pierremont,8,Ardennes,44,Grand Est
8395,ST PIERRE SUR VENCE,8430,ST PIERRE SUR VENCE,,49.7017731605,4.68314991741,395,,Saint-Pierre-sur-Vence,Saint-Pierre-sur-Vence,8,Ardennes,44,Grand Est
8396,ST QUENTIN LE PETIT,8220,ST QUENTIN LE PETIT,,49.5855722435,4.08056425414,396,,Saint-Quentin-le-Petit,Saint-Quentin-le-Petit,8,Ardennes,44,Grand Est
8397,ST REMY LE PETIT,8300,ST REMY LE PETIT,,49.399507719,4.23173978008,397,,Saint-Remy-le-Petit,Saint-Remy-le-Petit,8,Ardennes,44,Grand Est
8398,STE VAUBOURG,8130,STE VAUBOURG,,49.4597154807,4.59237076112,398,,Sainte-Vaubourg,Sainte-Vaubourg,8,Ardennes,44,Grand Est
8399,SAPOGNE SUR MARCHE,8370,SAPOGNE SUR MARCHE,,49.5978665854,5.3136409637,399,,Sapogne-sur-Marche,Sapogne-sur-Marche,8,Ardennes,44,Grand Est
8400,SAPOGNE ET FEUCHERES,8160,SAPOGNE ET FEUCHERES,,49.6594664485,4.80045594688,400,,Sapogne-et-Feuchères,Sapogne-et-Feuchères,8,Ardennes,44,Grand Est
8401,SAULCES CHAMPENOISES,8130,SAULCES CHAMPENOISES,,49.4466612892,4.50417073987,401,,Saulces-Champenoises,Saulces-Champenoises,8,Ardennes,44,Grand Est
8402,SAULCES MONCLIN,8270,SAULCES MONCLIN,,49.5762159457,4.50750212768,402,,Saulces-Monclin,Saulces-Monclin,8,Ardennes,44,Grand Est
8403,SAULT LES RETHEL,8300,SAULT LES RETHEL,,49.4872135038,4.35605993632,403,,Sault-lès-Rethel,Sault-lès-Rethel,8,Ardennes,44,Grand Est
8404,SAULT ST REMY,8190,SAULT ST REMY,,49.4246765802,4.15669310399,404,,Sault-Saint-Remy,Sault-Saint-Remy,8,Ardennes,44,Grand Est
8405,SAUVILLE,8390,SAUVILLE,,49.5508316655,4.78667759397,405,,Sauville,Sauville,8,Ardennes,44,Grand Est
8406,SAVIGNY SUR AISNE,8400,SAVIGNY SUR AISNE,,49.359523814,4.72150823362,406,,Savigny-sur-Aisne,Savigny-sur-Aisne,8,Ardennes,44,Grand Est
8407,SECHAULT,8250,SECHAULT,,49.2714856559,4.73688759461,407,,Séchault,Séchault,8,Ardennes,44,Grand Est
8408,SECHEVAL,8150,SECHEVAL,,49.859604641,4.66995420304,408,,Sécheval,Sécheval,8,Ardennes,44,Grand Est
8409,SEDAN,8200,SEDAN,,49.697058875,4.9298505244,409,,Sedan,Sedan,8,Ardennes,44,Grand Est
8410,SEMIDE,8400,SEMIDE,,49.3291026008,4.57642997944,410,,Semide,Semide,8,Ardennes,44,Grand Est
8411,SEMUY,8130,SEMUY,,49.4956881485,4.66294910211,411,,Semuy,Semuy,8,Ardennes,44,Grand Est
8412,SENUC,8250,SENUC,,49.3080610685,4.85209059171,412,,Senuc,Senuc,8,Ardennes,44,Grand Est
8413,SERAINCOURT,8220,SERAINCOURT,,49.6220794201,4.18799249278,413,,Seraincourt,Seraincourt,8,Ardennes,44,Grand Est
8413,SERAINCOURT,8220,SERAINCOURT,FOREST,49.6220794201,4.18799249278,413,,Seraincourt,Seraincourt,8,Ardennes,44,Grand Est
8415,SERY,8270,SERY,,49.5893011177,4.36138115369,415,,Sery,Sery,8,Ardennes,44,Grand Est
8416,SEUIL,8300,SEUIL,,49.474185421,4.45289966908,416,,Seuil,Seuil,8,Ardennes,44,Grand Est
8417,SEVIGNY LA FORET,8230,SEVIGNY LA FORET,,49.8792027455,4.47923063972,417,,Sévigny-la-Forêt,Sévigny-la-Forêt,8,Ardennes,44,Grand Est
8418,SEVIGNY WALEPPE,8220,SEVIGNY WALEPPE,,49.6171678844,4.08045801984,418,,Sévigny-Waleppe,Sévigny-Waleppe,8,Ardennes,44,Grand Est
8419,SIGNY L ABBAYE,8460,SIGNY L ABBAYE,,49.7029435011,4.40415204398,419,,Signy-l'Abbaye,Signy-l'Abbaye,8,Ardennes,44,Grand Est
8419,SIGNY L ABBAYE,8460,SIGNY L ABBAYE,LIBRECY,49.7029435011,4.40415204398,419,,Signy-l'Abbaye,Signy-l'Abbaye,8,Ardennes,44,Grand Est
8420,SIGNY LE PETIT,8380,SIGNY LE PETIT,,49.9309499097,4.2990463026,420,,Signy-le-Petit,Signy-le-Petit,8,Ardennes,44,Grand Est
8420,SIGNY LE PETIT,8380,SIGNY LE PETIT,LA GRUERIE,49.9309499097,4.2990463026,420,,Signy-le-Petit,Signy-le-Petit,8,Ardennes,44,Grand Est
8421,SIGNY MONTLIBERT,8370,SIGNY MONTLIBERT,,49.5783230756,5.3108582465,421,,Signy-Montlibert,Signy-Montlibert,8,Ardennes,44,Grand Est
8422,SINGLY,8430,SINGLY,,49.6418383616,4.70598126209,422,,Singly,Singly,8,Ardennes,44,Grand Est
8424,SOMMAUTHE,8240,SOMMAUTHE,,49.5025511911,4.98687284799,424,,Sommauthe,Sommauthe,8,Ardennes,44,Grand Est
8425,SOMMERANCE,8250,SOMMERANCE,,49.3263866648,4.99327810692,425,,Sommerance,Sommerance,8,Ardennes,44,Grand Est
8426,SON,8300,SON,,49.5802891216,4.26717062031,426,,Son,Son,8,Ardennes,44,Grand Est
8427,SORBON,8300,SORBON,,49.5514575157,4.37200155561,427,,Sorbon,Sorbon,8,Ardennes,44,Grand Est
8428,SORCY BAUTHEMONT,8270,SORCY BAUTHEMONT,,49.5413667574,4.53840369427,428,,Sorcy-Bauthémont,Sorcy-Bauthémont,8,Ardennes,44,Grand Est
8429,SORMONNE,8150,SORMONNE,,49.8083418844,4.56343699968,429,,Sormonne,Sormonne,8,Ardennes,44,Grand Est
8430,STONNE,8390,STONNE,,49.5495309711,4.92206804747,430,,Stonne,Stonne,8,Ardennes,44,Grand Est
8431,SUGNY,8400,SUGNY,,49.3554291298,4.66082182219,431,,Sugny,Sugny,8,Ardennes,44,Grand Est
8432,SURY,8090,SURY,,49.7658504549,4.61209618307,432,,Sury,Sury,8,Ardennes,44,Grand Est
8433,SUZANNE,8130,SUZANNE,,49.5196448821,4.63904554401,433,,Suzanne,Suzanne,8,Ardennes,44,Grand Est
8434,SY,8390,SY,,49.5140552485,4.87192502711,434,,Sy,Sy,8,Ardennes,44,Grand Est
8435,TAGNON,8300,TAGNON,,49.4497696397,4.2922571776,435,,Tagnon,Tagnon,8,Ardennes,44,Grand Est
8436,TAILLETTE,8230,TAILLETTE,,49.9272427923,4.47480367257,436,,Taillette,Taillette,8,Ardennes,44,Grand Est
8437,TAILLY,8240,TAILLY,,49.4074530876,5.06834679927,437,,Tailly,Tailly,8,Ardennes,44,Grand Est
8437,TAILLY,8240,TAILLY,ANDEVANNE,49.4074530876,5.06834679927,437,,Tailly,Tailly,8,Ardennes,44,Grand Est
8437,TAILLY,8240,TAILLY,BARRICOURT,49.4074530876,5.06834679927,437,,Tailly,Tailly,8,Ardennes,44,Grand Est
8437,TAILLY,8240,TAILLY,REMONVILLE,49.4074530876,5.06834679927,437,,Tailly,Tailly,8,Ardennes,44,Grand Est
8438,TAIZY,8360,TAIZY,,49.5106550755,4.25851649147,438,,Taizy,Taizy,8,Ardennes,44,Grand Est
8439,TANNAY,8390,TANNAY,,49.5244351516,4.82484959766,439,,Tannay,Tannay,8,Ardennes,44,Grand Est
8440,TARZY,8380,TARZY,,49.8762495136,4.29660246007,440,,Tarzy,Tarzy,8,Ardennes,44,Grand Est
8443,TERRON SUR AISNE,8400,TERRON SUR AISNE,,49.4555446057,4.7079258597,443,,Terron-sur-Aisne,Terron-sur-Aisne,8,Ardennes,44,Grand Est
8444,TETAIGNE,8110,TETAIGNE,,49.6494350396,5.11700090478,444,,Tétaigne,Tétaigne,8,Ardennes,44,Grand Est
8445,THELONNE,8350,THELONNE,,49.6482212901,4.94566360048,445,,Thelonne,Thelonne,8,Ardennes,44,Grand Est
8446,THENORGUES,8240,THENORGUES,,49.4060946457,4.92137626366,446,,Thénorgues,Thénorgues,8,Ardennes,44,Grand Est
8448,THILAY,8800,THILAY,,49.8995068878,4.80748273961,448,,Thilay,Thilay,8,Ardennes,44,Grand Est
8448,THILAY,8800,THILAY,NOHAN,49.8995068878,4.80748273961,448,,Thilay,Thilay,8,Ardennes,44,Grand Est
8449,THIN LE MOUTIER,8460,THIN LE MOUTIER,,49.7150524983,4.51191807216,449,,Thin-le-Moutier,Thin-le-Moutier,8,Ardennes,44,Grand Est
8450,THIS,8090,THIS,,49.7524093055,4.61053535908,450,,This,This,8,Ardennes,44,Grand Est
8451,LE THOUR,8190,LE THOUR,,49.5452885038,4.09027414774,451,Le,Thour,Le Thour,8,Ardennes,44,Grand Est
8452,THUGNY TRUGNY,8300,THUGNY TRUGNY,,49.4774101682,4.42112351465,452,,Thugny-Trugny,Thugny-Trugny,8,Ardennes,44,Grand Est
8453,TOGES,8400,TOGES,,49.4255298264,4.78533693531,453,,Toges,Toges,8,Ardennes,44,Grand Est
8454,TOULIGNY,8430,TOULIGNY,,49.6767256577,4.61499383082,454,,Touligny,Touligny,8,Ardennes,44,Grand Est
8455,TOURCELLES CHAUMONT,8400,TOURCELLES CHAUMONT,,49.3980524816,4.60160757563,455,,Tourcelles-Chaumont,Tourcelles-Chaumont,8,Ardennes,44,Grand Est
8456,TOURNAVAUX,8800,TOURNAVAUX,,49.8728127638,4.78394804684,456,,Tournavaux,Tournavaux,8,Ardennes,44,Grand Est
8457,TOURNES,8090,TOURNES,,49.7992413246,4.63988652343,457,,Tournes,Tournes,8,Ardennes,44,Grand Est
8458,TOURTERON,8130,TOURTERON,,49.5406354028,4.64242519656,458,,Tourteron,Tourteron,8,Ardennes,44,Grand Est
8459,TREMBLOIS LES CARIGNAN,8110,TREMBLOIS LES CARIGNAN,,49.6624363858,5.26029307221,459,,Tremblois-lès-Carignan,Tremblois-lès-Carignan,8,Ardennes,44,Grand Est
8460,TREMBLOIS LES ROCROI,8150,TREMBLOIS LES ROCROI,,49.8448912084,4.50057688801,460,,Tremblois-lès-Rocroi,Tremblois-lès-Rocroi,8,Ardennes,44,Grand Est
8461,VANDY,8400,VANDY,,49.4408407157,4.71763738316,461,,Vandy,Vandy,8,Ardennes,44,Grand Est
8462,VAUX CHAMPAGNE,8130,VAUX CHAMPAGNE,,49.4463918173,4.54892328965,462,,Vaux-Champagne,Vaux-Champagne,8,Ardennes,44,Grand Est
8463,VAUX EN DIEULET,8240,VAUX EN DIEULET,,49.4785340396,4.9918664976,463,,Vaux-en-Dieulet,Vaux-en-Dieulet,8,Ardennes,44,Grand Est
8464,VAUX LES MOURON,8250,VAUX LES MOURON,,49.2980685273,4.78431750699,464,,Vaux-lès-Mouron,Vaux-lès-Mouron,8,Ardennes,44,Grand Est
8465,VAUX LES RUBIGNY,8220,VAUX LES RUBIGNY,,49.6904205323,4.18309988343,465,,Vaux-lès-Rubigny,Vaux-lès-Rubigny,8,Ardennes,44,Grand Est
8466,VAUX LES MOUZON,8210,VAUX LES MOUZON,,49.60312563,5.13703497339,466,,Vaux-lès-Mouzon,Vaux-lès-Mouzon,8,Ardennes,44,Grand Est
8467,VAUX MONTREUIL,8270,VAUX MONTREUIL,,49.5833689396,4.556489457,467,,Vaux-Montreuil,Vaux-Montreuil,8,Ardennes,44,Grand Est
8468,VAUX VILLAINE,8150,VAUX VILLAINE,,49.7691050274,4.4726961277,468,,Vaux-Villaine,Vaux-Villaine,8,Ardennes,44,Grand Est
8469,VENDRESSE,8160,VENDRESSE,,49.6036580688,4.79161432829,469,,Vendresse,Vendresse,8,Ardennes,44,Grand Est
8469,VENDRESSE,8160,VENDRESSE,LA CASSINE,49.6036580688,4.79161432829,469,,Vendresse,Vendresse,8,Ardennes,44,Grand Est
8470,VERPEL,8240,VERPEL,,49.3818565442,4.92885901444,470,,Verpel,Verpel,8,Ardennes,44,Grand Est
8471,VERRIERES,8390,VERRIERES,,49.4959923626,4.88538309039,471,,Verrières,Verrières,8,Ardennes,44,Grand Est
8472,VIEL ST REMY,8270,VIEL ST REMY,,49.632572198,4.48367438059,472,,Viel-Saint-Remy,Viel-Saint-Remy,8,Ardennes,44,Grand Est
8472,VIEL ST REMY,8270,VIEL ST REMY,MARGY,49.632572198,4.48367438059,472,,Viel-Saint-Remy,Viel-Saint-Remy,8,Ardennes,44,Grand Est
8473,VIEUX LES ASFELD,8190,VIEUX LES ASFELD,,49.4510393454,4.10610399621,473,,Vieux-lès-Asfeld,Vieux-lès-Asfeld,8,Ardennes,44,Grand Est
8475,VILLERS CERNAY,8140,VILLERS CERNAY,,49.7388470514,5.05000071887,475,,Villers-Cernay,Villers-Cernay,8,Ardennes,44,Grand Est
8476,VILLERS DEVANT LE THOUR,8190,VILLERS DEVANT LE THOUR,,49.5043027928,4.076652193,476,,Villers-devant-le-Thour,Villers-devant-le-Thour,8,Ardennes,44,Grand Est
8477,VILLERS DEVANT MOUZON,8210,VILLERS DEVANT MOUZON,,49.6273486226,5.01783217122,477,,Villers-devant-Mouzon,Villers-devant-Mouzon,8,Ardennes,44,Grand Est
8478,VILLERS LE TILLEUL,8430,VILLERS LE TILLEUL,,49.6274458529,4.72758462411,478,,Villers-le-Tilleul,Villers-le-Tilleul,8,Ardennes,44,Grand Est
8479,VILLERS LE TOURNEUR,8430,VILLERS LE TOURNEUR,,49.6220295169,4.56406530841,479,,Villers-le-Tourneur,Villers-le-Tourneur,8,Ardennes,44,Grand Est
8480,VILLERS SEMEUSE,8000,VILLERS SEMEUSE,,49.7411448562,4.7510548564,480,,Villers-Semeuse,Villers-Semeuse,8,Ardennes,44,Grand Est
8481,VILLERS SUR BAR,8350,VILLERS SUR BAR,,49.6817820293,4.85514229057,481,,Villers-sur-Bar,Villers-sur-Bar,8,Ardennes,44,Grand Est
8482,VILLERS SUR LE MONT,8430,VILLERS SUR LE MONT,,49.6632700126,4.67936469498,482,,Villers-sur-le-Mont,Villers-sur-le-Mont,8,Ardennes,44,Grand Est
8483,VILLE SUR LUMES,8440,VILLE SUR LUMES,,49.7557065835,4.79606849833,483,,Ville-sur-Lumes,Ville-sur-Lumes,8,Ardennes,44,Grand Est
8484,VILLE SUR RETOURNE,8310,VILLE SUR RETOURNE,,49.3939644349,4.44716012221,484,,Ville-sur-Retourne,Ville-sur-Retourne,8,Ardennes,44,Grand Est
8485,VILLY,8370,VILLY,,49.59384897,5.2214022788,485,,Villy,Villy,8,Ardennes,44,Grand Est
8486,VIREUX MOLHAIN,8320,VIREUX MOLHAIN,,50.0772550914,4.70502555078,486,,Vireux-Molhain,Vireux-Molhain,8,Ardennes,44,Grand Est
8487,VIREUX WALLERAND,8320,VIREUX WALLERAND,,50.0568483332,4.74702825636,487,,Vireux-Wallerand,Vireux-Wallerand,8,Ardennes,44,Grand Est
8488,VIVIER AU COURT,8440,VIVIER AU COURT,,49.7320421095,4.82406160373,488,,Vivier-au-Court,Vivier-au-Court,8,Ardennes,44,Grand Est
8489,VONCQ,8400,VONCQ,,49.4736949482,4.68141832029,489,,Voncq,Voncq,8,Ardennes,44,Grand Est
8490,VOUZIERS,8400,VOUZIERS,,49.4000316087,4.70126283768,490,,Vouziers,Vouziers,8,Ardennes,44,Grand Est
8490,VOUZIERS,8400,VOUZIERS,BLAISE,49.4000316087,4.70126283768,490,,Vouziers,Vouziers,8,Ardennes,44,Grand Est
8491,VRIGNE AUX BOIS,8330,VRIGNE AUX BOIS,,49.7370150528,4.85605381662,491,,Vrigne aux Bois,Vrigne aux Bois,8,Ardennes,44,Grand Est
8492,VRIGNE MEUSE,8350,VRIGNE MEUSE,,49.704875935,4.83462850241,492,,Vrigne-Meuse,Vrigne-Meuse,8,Ardennes,44,Grand Est
8493,VRIZY,8400,VRIZY,,49.4330472487,4.67320049789,493,,Vrizy,Vrizy,8,Ardennes,44,Grand Est
8494,WADELINCOURT,8200,WADELINCOURT,,49.6805479174,4.93056331877,494,,Wadelincourt,Wadelincourt,8,Ardennes,44,Grand Est
8496,WAGNON,8270,WAGNON,,49.6470472996,4.44569183886,496,,Wagnon,Wagnon,8,Ardennes,44,Grand Est
8497,WARCQ,8000,WARCQ,,49.7682874486,4.66643502857,497,,Warcq,Warcq,8,Ardennes,44,Grand Est
8498,WARNECOURT,8090,WARNECOURT,,49.7325377304,4.65582481048,498,,Warnécourt,Warnécourt,8,Ardennes,44,Grand Est
8499,WASIGNY,8270,WASIGNY,,49.6279041536,4.35768813318,499,,Wasigny,Wasigny,8,Ardennes,44,Grand Est
8500,WIGNICOURT,8270,WIGNICOURT,,49.586756413,4.58208905132,500,,Wignicourt,Wignicourt,8,Ardennes,44,Grand Est
8501,WILLIERS,8110,WILLIERS,,49.6689882863,5.30073584121,501,,Williers,Williers,8,Ardennes,44,Grand Est
8502,YONCQ,8210,YONCQ,,49.5680543716,5.01664912304,502,,Yoncq,Yoncq,8,Ardennes,44,Grand Est
8503,YVERNAUMONT,8430,YVERNAUMONT,,49.6747132911,4.66268790823,503,,Yvernaumont,Yvernaumont,8,Ardennes,44,Grand Est
9001,AIGUES JUNTES,9240,AIGUES JUNTES,,43.0506179616,1.47151123009,1,,Aigues-Juntes,Aigues-Juntes,9,Ariège,76,Occitanie
9002,AIGUES VIVES,9600,AIGUES VIVES,,43.0006575328,1.87695638902,2,,Aigues-Vives,Aigues-Vives,9,Ariège,76,Occitanie
9003,L AIGUILLON,9300,L AIGUILLON,,42.9097070011,1.90052351136,3,L',Aiguillon,L'Aiguillon,9,Ariège,76,Occitanie
9004,ALBIES,9310,ALBIES,,42.7544533927,1.69800818291,4,,Albiès,Albiès,9,Ariège,76,Occitanie
9005,ALEU,9320,ALEU,,42.893742098,1.26749393485,5,,Aleu,Aleu,9,Ariège,76,Occitanie
9006,ALLIAT,9400,ALLIAT,,42.8159020056,1.58114167435,6,,Alliat,Alliat,9,Ariège,76,Occitanie
9007,ALLIERES,9240,ALLIERES,,43.0385438599,1.36390734959,7,,Allières,Allières,9,Ariège,76,Occitanie
9008,ALOS,9200,ALOS,,42.8984884142,1.12940450041,8,,Alos,Alos,9,Ariège,76,Occitanie
9009,ALZEN,9240,ALZEN,,42.9813926821,1.45571678885,9,,Alzen,Alzen,9,Ariège,76,Occitanie
9011,ANTRAS,9800,ANTRAS,,42.8732630887,0.901092072285,11,,Antras,Antras,9,Ariège,76,Occitanie
9012,APPY,9250,APPY,,42.8035768143,1.74201642035,12,,Appy,Appy,9,Ariège,76,Occitanie
9013,ARABAUX,9000,ARABAUX,,42.980519234,1.63390482016,13,,Arabaux,Arabaux,9,Ariège,76,Occitanie
9014,ARGEIN,9800,ARGEIN,,42.924142227,0.990874865954,14,,Argein,Argein,9,Ariège,76,Occitanie
9015,ARIGNAC,9400,ARIGNAC,,42.8760950162,1.5950781123,15,,Arignac,Arignac,9,Ariège,76,Occitanie
9016,ARNAVE,9400,ARNAVE,,42.8522962517,1.64611606147,16,,Arnave,Arnave,9,Ariège,76,Occitanie
9017,ARRIEN EN BETHMALE,9800,ARRIEN EN BETHMALE,,42.8837512369,1.05330418003,17,,Arrien-en-Bethmale,Arrien-en-Bethmale,9,Ariège,76,Occitanie
9018,ARROUT,9800,ARROUT,,42.9475606635,1.01996413494,18,,Arrout,Arrout,9,Ariège,76,Occitanie
9019,ARTIGAT,9130,ARTIGAT,,43.1431527184,1.44685366225,19,,Artigat,Artigat,9,Ariège,76,Occitanie
9020,ARTIGUES,9460,ARTIGUES,,42.6901417235,2.03778896431,20,,Artigues,Artigues,9,Ariège,76,Occitanie
9021,ARTIX,9120,ARTIX,,43.0669064267,1.56256658589,21,,Artix,Artix,9,Ariège,76,Occitanie
9022,ARVIGNA,9100,ARVIGNA,,43.0592469458,1.72868094967,22,,Arvigna,Arvigna,9,Ariège,76,Occitanie
9023,ASCOU,9110,ASCOU,,42.7192720915,1.93149239536,23,,Ascou,Ascou,9,Ariège,76,Occitanie
9024,ASTON,9310,ASTON,,42.680832026,1.65945312513,24,,Aston,Aston,9,Ariège,76,Occitanie
9025,AUCAZEIN,9800,AUCAZEIN,,42.9208994328,0.966291342849,25,,Aucazein,Aucazein,9,Ariège,76,Occitanie
9026,AUDRESSEIN,9800,AUDRESSEIN,,42.9305094861,1.02016808143,26,,Audressein,Audressein,9,Ariège,76,Occitanie
9027,AUGIREIN,9800,AUGIREIN,,42.9108818851,0.916808840124,27,,Augirein,Augirein,9,Ariège,76,Occitanie
9028,AULOS,9310,AULOS,,42.7914566689,1.67185866566,28,,Aulos,Aulos,9,Ariège,76,Occitanie
9029,AULUS LES BAINS,9140,AULUS LES BAINS,,42.7666138667,1.35664183654,29,,Aulus-les-Bains,Aulus-les-Bains,9,Ariège,76,Occitanie
9030,AUZAT,9220,AUZAT,,42.7023538618,1.44304412674,30,,Auzat,Auzat,9,Ariège,76,Occitanie
9031,AXIAT,9250,AXIAT,,42.7997046069,1.76100321573,31,,Axiat,Axiat,9,Ariège,76,Occitanie
9032,AX LES THERMES,9110,AX LES THERMES,,42.6873576342,1.81825994194,32,,Ax-les-Thermes,Ax-les-Thermes,9,Ariège,76,Occitanie
9033,BAGERT,9230,BAGERT,,43.07935173,1.07008338409,33,,Bagert,Bagert,9,Ariège,76,Occitanie
9034,BALACET,9800,BALACET,,42.8882588426,0.983446901785,34,,Balacet,Balacet,9,Ariège,76,Occitanie
9035,BALAGUERES,9800,BALAGUERES,,42.9673311242,1.0176138943,35,,Balaguères,Balaguères,9,Ariège,76,Occitanie
9035,BALAGUERES,9800,BALAGUERES,BALAGUE,42.9673311242,1.0176138943,35,,Balaguères,Balaguères,9,Ariège,76,Occitanie
9037,BARJAC,9230,BARJAC,,43.062297676,1.13524619399,37,,Barjac,Barjac,9,Ariège,76,Occitanie
9038,LA BASTIDE DE BESPLAS,9350,LA BASTIDE DE BESPLAS,,43.1691827843,1.27411323859,38,La,Bastide-de-Besplas,La Bastide-de-Besplas,9,Ariège,76,Occitanie
9039,LA BASTIDE DE BOUSIGNAC,9500,LA BASTIDE DE BOUSIGNAC,,43.0585487701,1.88443383803,39,La,Bastide-de-Bousignac,La Bastide-de-Bousignac,9,Ariège,76,Occitanie
9040,LA BASTIDE DE LORDAT,9700,LA BASTIDE DE LORDAT,,43.1401849075,1.71514606106,40,La,Bastide-de-Lordat,La Bastide-de-Lordat,9,Ariège,76,Occitanie
9041,LA BASTIDE DU SALAT,9160,LA BASTIDE DU SALAT,,43.0614872214,1.00017697542,41,La,Bastide-du-Salat,La Bastide-du-Salat,9,Ariège,76,Occitanie
9042,LA BASTIDE DE SEROU,9240,LA BASTIDE DE SEROU,,43.0242795037,1.42677885373,42,La,Bastide-de-Sérou,La Bastide-de-Sérou,9,Ariège,76,Occitanie
9043,LA BASTIDE SUR L HERS,9600,LA BASTIDE SUR L HERS,,42.9509022615,1.89982042742,43,La,Bastide-sur-l'Hers,La Bastide-sur-l'Hers,9,Ariège,76,Occitanie
9044,BAULOU,9000,BAULOU,,43.0181651187,1.54215154554,44,,Baulou,Baulou,9,Ariège,76,Occitanie
9045,BEDEILHAC ET AYNAT,9400,BEDEILHAC ET AYNAT,,42.8795852019,1.56719798954,45,,Bédeilhac-et-Aynat,Bédeilhac-et-Aynat,9,Ariège,76,Occitanie
9046,BEDEILLE,9230,BEDEILLE,,43.0834902757,1.10102979799,46,,Bédeille,Bédeille,9,Ariège,76,Occitanie
9047,BELESTA,9300,BELESTA,,42.8934707761,1.95274389658,47,,Bélesta,Bélesta,9,Ariège,76,Occitanie
9048,BELLOC,9600,BELLOC,,43.0072443769,1.93332486097,48,,Belloc,Belloc,9,Ariège,76,Occitanie
9049,BENAC,9000,BENAC,,42.9583402851,1.52803181268,49,,Bénac,Bénac,9,Ariège,76,Occitanie
9050,BENAGUES,9100,BENAGUES,,43.0777992381,1.60901857498,50,,Benagues,Benagues,9,Ariège,76,Occitanie
9051,BENAIX,9300,BENAIX,,42.895006557,1.85092848655,51,,Bénaix,Bénaix,9,Ariège,76,Occitanie
9052,BESSET,9500,BESSET,,43.072738948,1.84596031443,52,,Besset,Besset,9,Ariège,76,Occitanie
9053,BESTIAC,9250,BESTIAC,,42.786687605,1.80050215253,53,,Bestiac,Bestiac,9,Ariège,76,Occitanie
9054,BETCHAT,9160,BETCHAT,,43.0851470548,1.02888628828,54,,Betchat,Betchat,9,Ariège,76,Occitanie
9055,BETHMALE,9800,BETHMALE,,42.854188599,1.07758523335,55,,Bethmale,Bethmale,9,Ariège,76,Occitanie
9056,BEZAC,9100,BEZAC,,43.1525065182,1.57354933771,56,,Bézac,Bézac,9,Ariège,76,Occitanie
9057,BIERT,9320,BIERT,,42.9046164394,1.3087912454,57,,Biert,Biert,9,Ariège,76,Occitanie
9058,BOMPAS,9400,BOMPAS,,42.8628959409,1.61987890527,58,,Bompas,Bompas,9,Ariège,76,Occitanie
9059,BONAC IRAZEIN,9800,BONAC IRAZEIN,,42.840844647,0.982612922888,59,,Bonac-Irazein,Bonac-Irazein,9,Ariège,76,Occitanie
9060,BONNAC,9100,BONNAC,,43.1726271399,1.58703667646,60,,Bonnac,Bonnac,9,Ariège,76,Occitanie
9061,LES BORDES SUR ARIZE,9350,LES BORDES SUR ARIZE,,43.1130459282,1.36643495416,61,Les,Bordes-sur-Arize,Les Bordes-sur-Arize,9,Ariège,76,Occitanie
9062,LES BORDES SUR LEZ,9800,LES BORDES SUR LEZ,,42.8335034576,1.03424218178,62,,Bordes-Uchentein,Bordes-Uchentein,9,Ariège,76,Occitanie
9063,LE BOSC,9000,LE BOSC,,42.9329534185,1.44571486476,63,Le,Bosc,Le Bosc,9,Ariège,76,Occitanie
9064,BOUAN,9310,BOUAN,,42.7934480459,1.644027623,64,,Bouan,Bouan,9,Ariège,76,Occitanie
9065,BOUSSENAC,9320,BOUSSENAC,,42.9078454205,1.39109839522,65,,Boussenac,Boussenac,9,Ariège,76,Occitanie
9066,BRASSAC,9000,BRASSAC,,42.9322144767,1.51243170827,66,,Brassac,Brassac,9,Ariège,76,Occitanie
9067,BRIE,9700,BRIE,,43.2070860138,1.51654093771,67,,Brie,Brie,9,Ariège,76,Occitanie
9068,BURRET,9000,BURRET,,42.9518821093,1.47226587915,68,,Burret,Burret,9,Ariège,76,Occitanie
9069,BUZAN,9800,BUZAN,,42.9574233332,0.959841625997,69,,Buzan,Buzan,9,Ariège,76,Occitanie
9070,LES CABANNES,9310,LES CABANNES,,42.7860666312,1.68644152228,70,Les,Cabannes,Les Cabannes,9,Ariège,76,Occitanie
9071,CADARCET,9240,CADARCET,,43.0136914773,1.50207192139,71,,Cadarcet,Cadarcet,9,Ariège,76,Occitanie
9072,CALZAN,9120,CALZAN,,43.0326048013,1.74084762651,72,,Calzan,Calzan,9,Ariège,76,Occitanie
9073,CAMARADE,9290,CAMARADE,,43.0693773441,1.27737161361,73,,Camarade,Camarade,9,Ariège,76,Occitanie
9074,CAMON,9500,CAMON,,43.021523452,1.96173407084,74,,Camon,Camon,9,Ariège,76,Occitanie
9075,CAMPAGNE SUR ARIZE,9350,CAMPAGNE SUR ARIZE,,43.1197500573,1.32148049798,75,,Campagne-sur-Arize,Campagne-sur-Arize,9,Ariège,76,Occitanie
9076,CANTE,9700,CANTE,,43.2487009717,1.52354253407,76,,Canté,Canté,9,Ariège,76,Occitanie
9077,CAPOULET ET JUNAC,9400,CAPOULET ET JUNAC,,42.7919607747,1.57570997337,77,,Capoulet-et-Junac,Capoulet-et-Junac,9,Ariège,76,Occitanie
9078,CARCANIERES,9460,CARCANIERES,,42.7083661653,2.12368180993,78,,Carcanières,Carcanières,9,Ariège,76,Occitanie
9079,CARLA BAYLE,9130,CARLA BAYLE,,43.1575348436,1.37254711167,79,,Carla-Bayle,Carla-Bayle,9,Ariège,76,Occitanie
9080,CARLA DE ROQUEFORT,9300,CARLA DE ROQUEFORT,,42.9751735118,1.762010526,80,,Carla-de-Roquefort,Carla-de-Roquefort,9,Ariège,76,Occitanie
9081,LE CARLARET,9100,LE CARLARET,,43.1307609911,1.69416376028,81,Le,Carlaret,Le Carlaret,9,Ariège,76,Occitanie
9082,CASTELNAU DURBAN,9420,CASTELNAU DURBAN,,42.9928317992,1.33850962777,82,,Castelnau-Durban,Castelnau-Durban,9,Ariège,76,Occitanie
9083,CASTERAS,9130,CASTERAS,,43.1273605512,1.40393071226,83,,Castéras,Castéras,9,Ariège,76,Occitanie
9084,CASTEX,9350,CASTEX,,43.1657585272,1.31957552098,84,,Castex,Castex,9,Ariège,76,Occitanie
9085,CASTILLON EN COUSERANS,9800,CASTILLON EN COUSERANS,,42.9165658331,1.05571963783,85,,Castillon-en-Couserans,Castillon-en-Couserans,9,Ariège,76,Occitanie
9086,CAUMONT,9160,CAUMONT,,43.024099366,1.07931622344,86,,Caumont,Caumont,9,Ariège,76,Occitanie
9087,CAUSSOU,9250,CAUSSOU,,42.7721552831,1.82193324504,87,,Caussou,Caussou,9,Ariège,76,Occitanie
9088,CAYCHAX,9250,CAYCHAX,,42.8074176311,1.72818525568,88,,Caychax,Caychax,9,Ariège,76,Occitanie
9089,CAZALS DES BAYLES,9500,CAZALS DES BAYLES,,43.0932624655,1.93968174187,89,,Cazals-des-Baylès,Cazals-des-Baylès,9,Ariège,76,Occitanie
9090,CAZAUX,9120,CAZAUX,,43.0532282657,1.51928694563,90,,Cazaux,Cazaux,9,Ariège,76,Occitanie
9091,CAZAVET,9160,CAZAVET,,42.9980540032,1.03529654774,91,,Cazavet,Cazavet,9,Ariège,76,Occitanie
9092,CAZENAVE SERRES ET ALLENS,9400,CAZENAVE SERRES ET ALLENS,,42.8420776047,1.6911129703,92,,Cazenave-Serres-et-Allens,Cazenave-Serres-et-Allens,9,Ariège,76,Occitanie
9093,CELLES,9000,CELLES,,42.916897965,1.6969424831,93,,Celles,Celles,9,Ariège,76,Occitanie
9094,CERIZOLS,9230,CERIZOLS,,43.1195647903,1.06738869925,94,,Cérizols,Cérizols,9,Ariège,76,Occitanie
9095,CESCAU,9800,CESCAU,,42.9288304478,1.04814357665,95,,Cescau,Cescau,9,Ariège,76,Occitanie
9096,CHATEAU VERDUN,9310,CHATEAU VERDUN,,42.7815593895,1.67722786249,96,,Château-Verdun,Château-Verdun,9,Ariège,76,Occitanie
9097,CLERMONT,9420,CLERMONT,,43.0372197049,1.29061556438,97,,Clermont,Clermont,9,Ariège,76,Occitanie
9098,CONTRAZY,9230,CONTRAZY,,43.0578787696,1.20695998398,98,,Contrazy,Contrazy,9,Ariège,76,Occitanie
9099,COS,9000,COS,,42.9801592841,1.56921939947,99,,Cos,Cos,9,Ariège,76,Occitanie
9100,COUFLENS,9140,COUFLENS,,42.7531759671,1.18280630585,100,,Couflens,Couflens,9,Ariège,76,Occitanie
9100,COUFLENS,9140,COUFLENS,SALAU,42.7531759671,1.18280630585,100,,Couflens,Couflens,9,Ariège,76,Occitanie
9101,COUSSA,9120,COUSSA,,43.0646378158,1.68939869661,101,,Coussa,Coussa,9,Ariège,76,Occitanie
9102,COUTENS,9500,COUTENS,,43.0675702569,1.82251157352,102,,Coutens,Coutens,9,Ariège,76,Occitanie
9103,CRAMPAGNA,9120,CRAMPAGNA,,43.0283504432,1.58947099246,103,,Crampagna,Crampagna,9,Ariège,76,Occitanie
9104,DALOU,9120,DALOU,,43.0288762332,1.64821776091,104,,Dalou,Dalou,9,Ariège,76,Occitanie
9105,DAUMAZAN SUR ARIZE,9350,DAUMAZAN SUR ARIZE,,43.1445751789,1.30386151934,105,,Daumazan-sur-Arize,Daumazan-sur-Arize,9,Ariège,76,Occitanie
9106,DREUILHE,9300,DREUILHE,,42.9440531861,1.87119790216,106,,Dreuilhe,Dreuilhe,9,Ariège,76,Occitanie
9107,DUN,9600,DUN,,43.0256431764,1.79296928754,107,,Dun,Dun,9,Ariège,76,Occitanie
9107,DUN,9600,DUN,ENGRAVIES,43.0256431764,1.79296928754,107,,Dun,Dun,9,Ariège,76,Occitanie
9107,DUN,9600,DUN,MERVIEL,43.0256431764,1.79296928754,107,,Dun,Dun,9,Ariège,76,Occitanie
9107,DUN,9600,DUN,SENESSE DE SENABUGUE,43.0256431764,1.79296928754,107,,Dun,Dun,9,Ariège,76,Occitanie
9108,DURBAN SUR ARIZE,9240,DURBAN SUR ARIZE,,43.0204997035,1.34729463737,108,,Durban-sur-Arize,Durban-sur-Arize,9,Ariège,76,Occitanie
9109,DURFORT,9130,DURFORT,,43.1953151642,1.46374682599,109,,Durfort,Durfort,9,Ariège,76,Occitanie
9110,ENCOURTIECH,9200,ENCOURTIECH,,42.9635581924,1.19263328033,110,,Encourtiech,Encourtiech,9,Ariège,76,Occitanie
9111,ENGOMER,9800,ENGOMER,,42.9396656596,1.06331255023,111,,Engomer,Engomer,9,Ariège,76,Occitanie
9113,ERCE,9140,ERCE,,42.8341376861,1.30310687762,113,,Ercé,Ercé,9,Ariège,76,Occitanie
9114,ERP,9200,ERP,,42.9350909858,1.20010016005,114,,Erp,Erp,9,Ariège,76,Occitanie
9115,ESCLAGNE,9600,ESCLAGNE,,42.9809223416,1.8433667162,115,,Esclagne,Esclagne,9,Ariège,76,Occitanie
9116,ESCOSSE,9100,ESCOSSE,,43.1280159573,1.54879602096,116,,Escosse,Escosse,9,Ariège,76,Occitanie
9117,ESPLAS,9700,ESPLAS,,43.1866045103,1.4996220667,117,,Esplas,Esplas,9,Ariège,76,Occitanie
9118,ESPLAS DE SEROU,9420,ESPLAS DE SEROU,,42.9593666989,1.35387826212,118,,Esplas-de-Sérou,Esplas-de-Sérou,9,Ariège,76,Occitanie
9119,EYCHEIL,9200,EYCHEIL,,42.9553633761,1.15625348151,119,,Eycheil,Eycheil,9,Ariège,76,Occitanie
9120,FABAS,9230,FABAS,,43.1194009842,1.11405257208,120,,Fabas,Fabas,9,Ariège,76,Occitanie
9121,FERRIERES SUR ARIEGE,9000,FERRIERES SUR ARIEGE,,42.9382958572,1.60726284155,121,,Ferrières-sur-Ariège,Ferrières-sur-Ariège,9,Ariège,76,Occitanie
9122,FOIX,9000,FOIX,,42.9658502274,1.61037495894,122,,Foix,Foix,9,Ariège,76,Occitanie
9123,FORNEX,9350,FORNEX,,43.1641429511,1.24167256274,123,,Fornex,Fornex,9,Ariège,76,Occitanie
9124,LE FOSSAT,9130,LE FOSSAT,,43.1796907844,1.41506357343,124,Le,Fossat,Le Fossat,9,Ariège,76,Occitanie
9125,FOUGAX ET BARRINEUF,9300,FOUGAX ET BARRINEUF,,42.8725013217,1.89849774718,125,,Fougax-et-Barrineuf,Fougax-et-Barrineuf,9,Ariège,76,Occitanie
9126,FREYCHENET,9300,FREYCHENET,,42.8918256148,1.73000481921,126,,Freychenet,Freychenet,9,Ariège,76,Occitanie
9127,GABRE,9290,GABRE,,43.0659024827,1.42088834338,127,,Gabre,Gabre,9,Ariège,76,Occitanie
9128,GAJAN,9190,GAJAN,,43.0372259317,1.13491653966,128,,Gajan,Gajan,9,Ariège,76,Occitanie
9129,GALEY,9800,GALEY,,42.9459014431,0.901835208774,129,,Galey,Galey,9,Ariège,76,Occitanie
9130,GANAC,9000,GANAC,,42.9307441238,1.55979316845,130,,Ganac,Ganac,9,Ariège,76,Occitanie
9131,GARANOU,9250,GARANOU,,42.7646194319,1.74523092663,131,,Garanou,Garanou,9,Ariège,76,Occitanie
9132,GAUDIES,9700,GAUDIES,,43.173172647,1.73046105986,132,,Gaudiès,Gaudiès,9,Ariège,76,Occitanie
9133,GENAT,9400,GENAT,,42.828781368,1.56589342437,133,,Génat,Génat,9,Ariège,76,Occitanie
9134,GESTIES,9220,GESTIES,,42.7184954311,1.58490734862,134,,Gestiès,Gestiès,9,Ariège,76,Occitanie
9135,GOULIER,9220,GOULIER,,42.7438691043,1.50611189303,135,,Goulier,Goulier,9,Ariège,76,Occitanie
9136,GOURBIT,9400,GOURBIT,,42.828206908,1.51547643918,136,,Gourbit,Gourbit,9,Ariège,76,Occitanie
9137,GUDAS,9120,GUDAS,,43.0023566445,1.6795837904,137,,Gudas,Gudas,9,Ariège,76,Occitanie
9138,L HERM,9000,L HERM,,42.9730292924,1.67888356727,138,L',Herm,L'Herm,9,Ariège,76,Occitanie
9139,L HOSPITALET PRES L ANDORRE,9390,L HOSPITALET PRES L ANDORRE,,42.595464483,1.7777228207,139,L',Hospitalet-près-l'Andorre,L'Hospitalet-près-l'Andorre,9,Ariège,76,Occitanie
9140,IGNAUX,9110,IGNAUX,,42.7427436536,1.84606817097,140,,Ignaux,Ignaux,9,Ariège,76,Occitanie
9141,ILLARTEIN,9800,ILLARTEIN,,42.9224446922,0.954341364793,141,,Illartein,Illartein,9,Ariège,76,Occitanie
9142,ILHAT,9300,ILHAT,,42.9512575133,1.7851302515,142,,Ilhat,Ilhat,9,Ariège,76,Occitanie
9143,ILLIER ET LARAMADE,9220,ILLIER ET LARAMADE,,42.7879303453,1.54250853578,143,,Illier-et-Laramade,Illier-et-Laramade,9,Ariège,76,Occitanie
9145,LES ISSARDS,9100,LES ISSARDS,,43.0794860719,1.73901376752,145,Les,Issards,Les Issards,9,Ariège,76,Occitanie
9146,JUSTINIAC,9700,JUSTINIAC,,43.2144559342,1.48880175894,146,,Justiniac,Justiniac,9,Ariège,76,Occitanie
9147,LABATUT,9700,LABATUT,,43.2621796954,1.521626119,147,,Labatut,Labatut,9,Ariège,76,Occitanie
9148,LACAVE,9160,LACAVE,,43.0470411913,1.01294653058,148,,Lacave,Lacave,9,Ariège,76,Occitanie
9149,LACOURT,9200,LACOURT,,42.9330702659,1.17065014177,149,,Lacourt,Lacourt,9,Ariège,76,Occitanie
9150,LAGARDE,9500,LAGARDE,,43.0463308145,1.93595085091,150,,Lagarde,Lagarde,9,Ariège,76,Occitanie
9151,LANOUX,9130,LANOUX,,43.1200797845,1.42372651979,151,,Lanoux,Lanoux,9,Ariège,76,Occitanie
9152,LAPEGE,9400,LAPEGE,,42.8044576552,1.55503910056,152,,Lapège,Lapège,9,Ariège,76,Occitanie
9153,LAPENNE,9500,LAPENNE,,43.1445048252,1.77562717427,153,,Lapenne,Lapenne,9,Ariège,76,Occitanie
9154,LARBONT,9240,LARBONT,,42.9931704458,1.40017931392,154,,Larbont,Larbont,9,Ariège,76,Occitanie
9155,LARCAT,9310,LARCAT,,42.7686691724,1.64646916937,155,,Larcat,Larcat,9,Ariège,76,Occitanie
9156,LARNAT,9310,LARNAT,,42.7968380129,1.62850019482,156,,Larnat,Larnat,9,Ariège,76,Occitanie
9157,LAROQUE D OLMES,9600,LAROQUE D OLMES,,42.9675623246,1.87181602964,157,,Laroque-d'Olmes,Laroque-d'Olmes,9,Ariège,76,Occitanie
9158,LASSERRE,9230,LASSERRE,,43.0766218333,1.16412918224,158,,Lasserre,Lasserre,9,Ariège,76,Occitanie
9159,LASSUR,9310,LASSUR,,42.7411070071,1.71830324497,159,,Lassur,Lassur,9,Ariège,76,Occitanie
9160,LAVELANET,9300,LAVELANET,,42.9336867312,1.84586953504,160,,Lavelanet,Lavelanet,9,Ariège,76,Occitanie
9161,LERAN,9600,LERAN,,42.9847156991,1.91842200411,161,,Léran,Léran,9,Ariège,76,Occitanie
9162,LERCOUL,9220,LERCOUL,,42.7098891708,1.52802126242,162,,Lercoul,Lercoul,9,Ariège,76,Occitanie
9163,LESCOUSSE,9100,LESCOUSSE,,43.1491432364,1.51328236533,163,,Lescousse,Lescousse,9,Ariège,76,Occitanie
9164,LESCURE,9420,LESCURE,,43.0086403307,1.24160413045,164,,Lescure,Lescure,9,Ariège,76,Occitanie
9165,LESPARROU,9300,LESPARROU,,42.9276359247,1.93926031529,165,,Lesparrou,Lesparrou,9,Ariège,76,Occitanie
9166,LEYCHERT,9300,LEYCHERT,,42.9461349991,1.72568604456,166,,Leychert,Leychert,9,Ariège,76,Occitanie
9167,LEZAT SUR LEZE,9210,LEZAT SUR LEZE,,43.280210332,1.34476803133,167,,Lézat-sur-Lèze,Lézat-sur-Lèze,9,Ariège,76,Occitanie
9168,LIEURAC,9300,LIEURAC,,42.9850209725,1.79078001145,168,,Lieurac,Lieurac,9,Ariège,76,Occitanie
9169,LIMBRASSAC,9600,LIMBRASSAC,,43.0110104704,1.84353239924,169,,Limbrassac,Limbrassac,9,Ariège,76,Occitanie
9170,LISSAC,9700,LISSAC,,43.267238666,1.51045674353,170,,Lissac,Lissac,9,Ariège,76,Occitanie
9171,LORDAT,9250,LORDAT,,42.7985041105,1.78455165815,171,,Lordat,Lordat,9,Ariège,76,Occitanie
9172,LOUBAUT,9350,LOUBAUT,,43.1881252111,1.28310345305,172,,Loubaut,Loubaut,9,Ariège,76,Occitanie
9173,LOUBENS,9120,LOUBENS,,43.0430976217,1.5546667982,173,,Loubens,Loubens,9,Ariège,76,Occitanie
9174,LOUBIERES,9000,LOUBIERES,,43.0097766189,1.59427619307,174,,Loubières,Loubières,9,Ariège,76,Occitanie
9175,LUDIES,9100,LUDIES,,43.1204279792,1.71727869146,175,,Ludiès,Ludiès,9,Ariège,76,Occitanie
9176,LUZENAC,9250,LUZENAC,,42.7200086673,1.74308334552,176,,Luzenac,Luzenac,9,Ariège,76,Occitanie
9177,MADIERE,9100,MADIERE,,43.1091748089,1.51121426863,177,,Madière,Madière,9,Ariège,76,Occitanie
9178,MALEGOUDE,9500,MALEGOUDE,,43.1120592505,1.93343728109,178,,Malegoude,Malegoude,9,Ariège,76,Occitanie
9179,MALLEON,9120,MALLEON,,43.0330014748,1.71547943736,179,,Malléon,Malléon,9,Ariège,76,Occitanie
9180,MANSES,9500,MANSES,,43.1073463894,1.8140686681,180,,Manses,Manses,9,Ariège,76,Occitanie
9181,LE MAS D AZIL,9290,LE MAS D AZIL,,43.0692744925,1.34937176896,181,Le,Mas-d'Azil,Le Mas-d'Azil,9,Ariège,76,Occitanie
9182,MASSAT,9320,MASSAT,,42.8756880579,1.37725705929,182,,Massat,Massat,9,Ariège,76,Occitanie
9183,MAUVEZIN DE PRAT,9160,MAUVEZIN DE PRAT,,43.0324532484,0.992112596886,183,,Mauvezin-de-Prat,Mauvezin-de-Prat,9,Ariège,76,Occitanie
9184,MAUVEZIN DE STE CROIX,9230,MAUVEZIN DE STE CROIX,,43.0768014168,1.23243008757,184,,Mauvezin-de-Sainte-Croix,Mauvezin-de-Sainte-Croix,9,Ariège,76,Occitanie
9185,MAZERES,9270,MAZERES,,43.2305454388,1.67905538155,185,,Mazères,Mazères,9,Ariège,76,Occitanie
9186,MERAS,9350,MERAS,,43.1823470835,1.3078528703,186,,Méras,Méras,9,Ariège,76,Occitanie
9187,MERCENAC,9160,MERCENAC,,43.0533409773,1.06840881939,187,,Mercenac,Mercenac,9,Ariège,76,Occitanie
9188,MERCUS GARRABET,9400,MERCUS GARRABET,,42.8795171828,1.6442180232,188,,Mercus-Garrabet,Mercus-Garrabet,9,Ariège,76,Occitanie
9188,MERCUS GARRABET,9400,MERCUS GARRABET,AMPLAING,42.8795171828,1.6442180232,188,,Mercus-Garrabet,Mercus-Garrabet,9,Ariège,76,Occitanie
9189,MERENS LES VALS,9110,MERENS LES VALS,,42.6279300711,1.83436205641,189,,Mérens-les-Vals,Mérens-les-Vals,9,Ariège,76,Occitanie
9190,MERIGON,9230,MERIGON,,43.0906597398,1.20465385881,190,,Mérigon,Mérigon,9,Ariège,76,Occitanie
9192,MIGLOS,9400,MIGLOS,,42.7798466891,1.60572144685,192,,Miglos,Miglos,9,Ariège,76,Occitanie
9193,MIJANES,9460,MIJANES,,42.7102290438,2.01220690511,193,,Mijanès,Mijanès,9,Ariège,76,Occitanie
9194,MIREPOIX,9500,MIREPOIX,,43.1093995072,1.86916991814,194,,Mirepoix,Mirepoix,9,Ariège,76,Occitanie
9195,MONESPLE,9130,MONESPLE,,43.0953255323,1.47652178879,195,,Monesple,Monesple,9,Ariège,76,Occitanie
9196,MONTAGAGNE,9240,MONTAGAGNE,,42.9638469564,1.41313214864,196,,Montagagne,Montagagne,9,Ariège,76,Occitanie
9197,MONTAILLOU,9110,MONTAILLOU,,42.7801887504,1.90255667259,197,,Montaillou,Montaillou,9,Ariège,76,Occitanie
9198,MONTARDIT,9230,MONTARDIT,,43.0695601112,1.18585733449,198,,Montardit,Montardit,9,Ariège,76,Occitanie
9199,MONTAUT,9700,MONTAUT,,43.186244822,1.65640135229,199,,Montaut,Montaut,9,Ariège,76,Occitanie
9200,MONTBEL,9600,MONTBEL,,42.9784689513,1.96705977285,200,,Montbel,Montbel,9,Ariège,76,Occitanie
9201,MONTEGUT EN COUSERANS,9200,MONTEGUT EN COUSERANS,,42.9834376015,1.09301526592,201,,Montégut-en-Couserans,Montégut-en-Couserans,9,Ariège,76,Occitanie
9202,MONTEGUT PLANTAUREL,9120,MONTEGUT PLANTAUREL,,43.0757579679,1.49683578194,202,,Montégut-Plantaurel,Montégut-Plantaurel,9,Ariège,76,Occitanie
9203,MONTELS,9240,MONTELS,,43.0059500308,1.46985998541,203,,Montels,Montels,9,Ariège,76,Occitanie
9204,MONTESQUIEU AVANTES,9200,MONTESQUIEU AVANTES,,43.0350392135,1.21647131708,204,,Montesquieu-Avantès,Montesquieu-Avantès,9,Ariège,76,Occitanie
9205,MONTFA,9350,MONTFA,,43.1005107546,1.28811198649,205,,Montfa,Montfa,9,Ariège,76,Occitanie
9206,MONTFERRIER,9300,MONTFERRIER,,42.8659061967,1.77079235235,206,,Montferrier,Montferrier,9,Ariège,76,Occitanie
9207,MONTGAILLARD,9330,MONTGAILLARD,,42.9377639768,1.64370144319,207,,Montgaillard,Montgaillard,9,Ariège,76,Occitanie
9208,MONTGAUCH,9160,MONTGAUCH,,42.9945304885,1.07283965181,208,,Montgauch,Montgauch,9,Ariège,76,Occitanie
9209,MONTJOIE EN COUSERANS,9200,MONTJOIE EN COUSERANS,,43.0152342505,1.17565361444,209,,Montjoie-en-Couserans,Montjoie-en-Couserans,9,Ariège,76,Occitanie
9209,MONTJOIE EN COUSERANS,9200,MONTJOIE EN COUSERANS,AUDINAC LES BAINS,43.0152342505,1.17565361444,209,,Montjoie-en-Couserans,Montjoie-en-Couserans,9,Ariège,76,Occitanie
9210,MONTOULIEU,9000,MONTOULIEU,,42.9040427071,1.60501449774,210,,Montoulieu,Montoulieu,9,Ariège,76,Occitanie
9211,MONTSEGUR,9300,MONTSEGUR,,42.8427100582,1.82683539568,211,,Montségur,Montségur,9,Ariège,76,Occitanie
9212,MONTSERON,9240,MONTSERON,,43.0208884939,1.31655733885,212,,Montseron,Montseron,9,Ariège,76,Occitanie
9213,MOULIN NEUF,9500,MOULIN NEUF,,43.072355696,1.94815938033,213,,Moulin-Neuf,Moulin-Neuf,9,Ariège,76,Occitanie
9214,MOULIS,9200,MOULIS,,42.9438845213,1.09852869482,214,,Moulis,Moulis,9,Ariège,76,Occitanie
9215,NALZEN,9300,NALZEN,,42.9192383655,1.74790658512,215,,Nalzen,Nalzen,9,Ariège,76,Occitanie
9216,NESCUS,9240,NESCUS,,42.9917806971,1.43531153938,216,,Nescus,Nescus,9,Ariège,76,Occitanie
9217,NIAUX,9400,NIAUX,,42.8161799796,1.59941091068,217,,Niaux,Niaux,9,Ariège,76,Occitanie
9218,ORGEIX,9110,ORGEIX,,42.6754737936,1.87610097386,218,,Orgeix,Orgeix,9,Ariège,76,Occitanie
9219,ORGIBET,9800,ORGIBET,,42.9263246353,0.938509265904,219,,Orgibet,Orgibet,9,Ariège,76,Occitanie
9220,ORLU,9110,ORLU,,42.6581771284,1.93694023792,220,,Orlu,Orlu,9,Ariège,76,Occitanie
9221,ORNOLAC USSAT LES BAINS,9400,ORNOLAC USSAT LES BAINS,,42.822281281,1.64644484913,221,,Ornolac-Ussat-les-Bains,Ornolac-Ussat-les-Bains,9,Ariège,76,Occitanie
9222,ORUS,9220,ORUS,,42.7941338895,1.51226852239,222,,Orus,Orus,9,Ariège,76,Occitanie
9223,OUST,9140,OUST,,42.8601715616,1.23475872502,223,,Oust,Oust,9,Ariège,76,Occitanie
9224,PAILHES,9130,PAILHES,,43.1002752651,1.44680858932,224,,Pailhès,Pailhès,9,Ariège,76,Occitanie
9225,PAMIERS,9100,PAMIERS,,43.1234860673,1.61523399037,225,,Pamiers,Pamiers,9,Ariège,76,Occitanie
9226,PECH,9310,PECH,,42.7627973715,1.68798310232,226,,Pech,Pech,9,Ariège,76,Occitanie
9227,PEREILLE,9300,PEREILLE,,42.9291256621,1.80848741794,227,,Péreille,Péreille,9,Ariège,76,Occitanie
9228,PERLES ET CASTELET,9110,PERLES ET CASTELET,,42.7186237095,1.77447704915,228,,Perles-et-Castelet,Perles-et-Castelet,9,Ariège,76,Occitanie
9229,LE PEYRAT,9600,LE PEYRAT,,42.9558799437,1.92773507045,229,Le,Peyrat,Le Peyrat,9,Ariège,76,Occitanie
9230,LE PLA,9460,LE PLA,,42.6879125968,2.05616287931,230,Le,Pla,Le Pla,9,Ariège,76,Occitanie
9231,LE PORT,9320,LE PORT,,42.8356989476,1.38584993584,231,Le,Port,Le Port,9,Ariège,76,Occitanie
9232,PRADES,9110,PRADES,,42.7908714582,1.86049568072,232,,Prades,Prades,9,Ariège,76,Occitanie
9233,PRADETTES,9600,PRADETTES,,42.9912311784,1.82115951779,233,,Pradettes,Pradettes,9,Ariège,76,Occitanie
9234,PRADIERES,9000,PRADIERES,,42.9611572235,1.65602192048,234,,Pradières,Pradières,9,Ariège,76,Occitanie
9235,PRAT BONREPAUX,9160,PRAT BONREPAUX,,43.0312285165,1.02890152351,235,,Prat-Bonrepaux,Prat-Bonrepaux,9,Ariège,76,Occitanie
9236,PRAYOLS,9000,PRAYOLS,,42.9217087412,1.60137253645,236,,Prayols,Prayols,9,Ariège,76,Occitanie
9237,LE PUCH,9460,LE PUCH,,42.7207669696,2.09926724082,237,Le,Puch,Le Puch,9,Ariège,76,Occitanie
9238,LES PUJOLS,9100,LES PUJOLS,,43.0917243273,1.71845001991,238,Les,Pujols,Les Pujols,9,Ariège,76,Occitanie
9239,QUERIGUT,9460,QUERIGUT,,42.6833720456,2.11436748471,239,,Quérigut,Quérigut,9,Ariège,76,Occitanie
9240,QUIE,9400,QUIE,,42.8425853909,1.58955333024,240,,Quié,Quié,9,Ariège,76,Occitanie
9241,RABAT LES TROIS SEIGNEURS,9400,RABAT LES TROIS SEIGNEURS,,42.8457065317,1.49314286744,241,,Rabat-les-Trois-Seigneurs,Rabat-les-Trois-Seigneurs,9,Ariège,76,Occitanie
9242,RAISSAC,9300,RAISSAC,,42.9416681862,1.81553522822,242,,Raissac,Raissac,9,Ariège,76,Occitanie
9243,REGAT,9600,REGAT,,42.9885315709,1.88732041565,243,,Régat,Régat,9,Ariège,76,Occitanie
9244,RIEUCROS,9500,RIEUCROS,,43.0760375517,1.76327077527,244,,Rieucros,Rieucros,9,Ariège,76,Occitanie
9245,RIEUX DE PELLEPORT,9120,RIEUX DE PELLEPORT,,43.0579984327,1.59934492972,245,,Rieux-de-Pelleport,Rieux-de-Pelleport,9,Ariège,76,Occitanie
9246,RIMONT,9420,RIMONT,,42.9878381325,1.28379661881,246,,Rimont,Rimont,9,Ariège,76,Occitanie
9247,RIVERENERT,9200,RIVERENERT,,42.951363295,1.26184128734,247,,Rivèrenert,Rivèrenert,9,Ariège,76,Occitanie
9249,ROQUEFIXADE,9300,ROQUEFIXADE,,42.9332936149,1.76440291493,249,,Roquefixade,Roquefixade,9,Ariège,76,Occitanie
9250,ROQUEFORT LES CASCADES,9300,ROQUEFORT LES CASCADES,,42.9610533574,1.74338927244,250,,Roquefort-les-Cascades,Roquefort-les-Cascades,9,Ariège,76,Occitanie
9251,ROUMENGOUX,9500,ROUMENGOUX,,43.0761211234,1.92438263558,251,,Roumengoux,Roumengoux,9,Ariège,76,Occitanie
9252,ROUZE,9460,ROUZE,,42.740259004,2.07130727473,252,,Rouze,Rouze,9,Ariège,76,Occitanie
9253,SABARAT,9350,SABARAT,,43.0975088252,1.39998085844,253,,Sabarat,Sabarat,9,Ariège,76,Occitanie
9254,ST AMADOU,9100,ST AMADOU,,43.1112959391,1.71491289388,254,,Saint-Amadou,Saint-Amadou,9,Ariège,76,Occitanie
9255,ST AMANS,9100,ST AMANS,,43.1595834502,1.5479581856,255,,Saint-Amans,Saint-Amans,9,Ariège,76,Occitanie
9256,ST BAUZEIL,9120,ST BAUZEIL,,43.082423951,1.58105497045,256,,Saint-Bauzeil,Saint-Bauzeil,9,Ariège,76,Occitanie
9257,STE CROIX VOLVESTRE,9230,STE CROIX VOLVESTRE,,43.1197309589,1.17100553891,257,,Sainte-Croix-Volvestre,Sainte-Croix-Volvestre,9,Ariège,76,Occitanie
9258,ST FELIX DE RIEUTORD,9120,ST FELIX DE RIEUTORD,,43.0441550256,1.66977441855,258,,Saint-Félix-de-Rieutord,Saint-Félix-de-Rieutord,9,Ariège,76,Occitanie
9259,ST FELIX DE TOURNEGAT,9500,ST FELIX DE TOURNEGAT,,43.1236879845,1.75631301429,259,,Saint-Félix-de-Tournegat,Saint-Félix-de-Tournegat,9,Ariège,76,Occitanie
9260,STE FOI,9500,STE FOI,,43.1257603127,1.9172086969,260,,Sainte-Foi,Sainte-Foi,9,Ariège,76,Occitanie
9261,ST GIRONS,9200,ST GIRONS,,42.9752923045,1.15326595533,261,,Saint-Girons,Saint-Girons,9,Ariège,76,Occitanie
9262,ST JEAN D AIGUES VIVES,9300,ST JEAN D AIGUES VIVES,,42.9189081448,1.87680686803,262,,Saint-Jean-d'Aigues-Vives,Saint-Jean-d'Aigues-Vives,9,Ariège,76,Occitanie
9263,ST JEAN DU CASTILLONNAIS,9800,ST JEAN DU CASTILLONNAIS,,42.9521955213,0.928566795359,263,,Saint-Jean-du-Castillonnais,Saint-Jean-du-Castillonnais,9,Ariège,76,Occitanie
9264,ST JEAN DE VERGES,9000,ST JEAN DE VERGES,,43.0129896461,1.63277165832,264,,Saint-Jean-de-Verges,Saint-Jean-de-Verges,9,Ariège,76,Occitanie
9264,ST JEAN DE VERGES,9000,ST JEAN DE VERGES,VILLENEUVE DU BOSC,43.0129896461,1.63277165832,264,,Saint-Jean-de-Verges,Saint-Jean-de-Verges,9,Ariège,76,Occitanie
9265,ST JEAN DU FALGA,9100,ST JEAN DU FALGA,,43.0862015725,1.62439338858,265,,Saint-Jean-du-Falga,Saint-Jean-du-Falga,9,Ariège,76,Occitanie
9266,ST JULIEN DE GRAS CAPOU,9500,ST JULIEN DE GRAS CAPOU,,43.0367157947,1.85651898742,266,,Saint-Julien-de-Gras-Capou,Saint-Julien-de-Gras-Capou,9,Ariège,76,Occitanie
9267,ST LARY,9800,ST LARY,,42.9005720195,0.868448000614,267,,Saint-Lary,Saint-Lary,9,Ariège,76,Occitanie
9268,ST LIZIER,9190,ST LIZIER,,42.9998808556,1.12929754476,268,,Saint-Lizier,Saint-Lizier,9,Ariège,76,Occitanie
9269,ST MARTIN DE CARALP,9000,ST MARTIN DE CARALP,,42.9919399277,1.53796781616,269,,Saint-Martin-de-Caralp,Saint-Martin-de-Caralp,9,Ariège,76,Occitanie
9270,ST MARTIN D OYDES,9100,ST MARTIN D OYDES,,43.1661314489,1.48369592125,270,,Saint-Martin-d'Oydes,Saint-Martin-d'Oydes,9,Ariège,76,Occitanie
9271,ST MICHEL,9100,ST MICHEL,,43.1325345547,1.50200100913,271,,Saint-Michel,Saint-Michel,9,Ariège,76,Occitanie
9272,ST PAUL DE JARRAT,9000,ST PAUL DE JARRAT,,42.8959154584,1.67206872652,272,,Saint-Paul-de-Jarrat,Saint-Paul-de-Jarrat,9,Ariège,76,Occitanie
9273,ST PIERRE DE RIVIERE,9000,ST PIERRE DE RIVIERE,,42.9664677033,1.55739954781,273,,Saint-Pierre-de-Rivière,Saint-Pierre-de-Rivière,9,Ariège,76,Occitanie
9274,ST QUENTIN LA TOUR,9500,ST QUENTIN LA TOUR,,43.0310774856,1.90668418489,274,,Saint-Quentin-la-Tour,Saint-Quentin-la-Tour,9,Ariège,76,Occitanie
9275,ST QUIRC,9700,ST QUIRC,,43.2790165591,1.50232499606,275,,Saint-Quirc,Saint-Quirc,9,Ariège,76,Occitanie
9276,ST VICTOR ROUZAUD,9100,ST VICTOR ROUZAUD,,43.0879036515,1.54799869593,276,,Saint-Victor-Rouzaud,Saint-Victor-Rouzaud,9,Ariège,76,Occitanie
9277,ST YBARS,9210,ST YBARS,,43.2421519244,1.39839166457,277,,Saint-Ybars,Saint-Ybars,9,Ariège,76,Occitanie
9279,SALSEIN,9800,SALSEIN,,42.9042685919,1.00165723674,279,,Salsein,Salsein,9,Ariège,76,Occitanie
9280,SAURAT,9400,SAURAT,,42.883738441,1.50479848751,280,,Saurat,Saurat,9,Ariège,76,Occitanie
9280,SAURAT,9400,SAURAT,PRAT COMMUNAL,42.883738441,1.50479848751,280,,Saurat,Saurat,9,Ariège,76,Occitanie
9281,SAUTEL,9300,SAUTEL,,42.9679030208,1.81630442967,281,,Sautel,Sautel,9,Ariège,76,Occitanie
9282,SAVERDUN,9700,SAVERDUN,,43.2265667321,1.57373634569,282,,Saverdun,Saverdun,9,Ariège,76,Occitanie
9283,SAVIGNAC LES ORMEAUX,9110,SAVIGNAC LES ORMEAUX,,42.6824950812,1.77944672349,283,,Savignac-les-Ormeaux,Savignac-les-Ormeaux,9,Ariège,76,Occitanie
9284,SEGURA,9120,SEGURA,,43.032230727,1.69275443805,284,,Ségura,Ségura,9,Ariège,76,Occitanie
9285,SEIX,9140,SEIX,,42.8178331497,1.14814568291,285,,Seix,Seix,9,Ariège,76,Occitanie
9286,SEM,9220,SEM,,42.756066889,1.52380971071,286,,Sem,Sem,9,Ariège,76,Occitanie
9287,SENCONAC,9250,SENCONAC,,42.8128619696,1.71656632425,287,,Senconac,Senconac,9,Ariège,76,Occitanie
9289,LORP SENTARAILLE,9190,LORP SENTARAILLE,,43.0159566285,1.10827434601,289,,Lorp-Sentaraille,Lorp-Sentaraille,9,Ariège,76,Occitanie
9290,SENTEIN,9800,SENTEIN,,42.8358878297,0.920341995173,290,,Sentein,Sentein,9,Ariège,76,Occitanie
9291,SENTENAC D OUST,9140,SENTENAC D OUST,,42.8662042438,1.14021822329,291,,Sentenac-d'Oust,Sentenac-d'Oust,9,Ariège,76,Occitanie
9292,SENTENAC DE SEROU,9240,SENTENAC DE SEROU,,42.9454493638,1.3950868349,292,,Sentenac-de-Sérou,Sentenac-de-Sérou,9,Ariège,76,Occitanie
9293,SERRES SUR ARGET,9000,SERRES SUR ARGET,,42.9701435492,1.5043958432,293,,Serres-sur-Arget,Serres-sur-Arget,9,Ariège,76,Occitanie
9294,SIEURAS,9130,SIEURAS,,43.1922641286,1.34376526117,294,,Sieuras,Sieuras,9,Ariège,76,Occitanie
9295,SIGUER,9220,SIGUER,,42.7082898185,1.55315444474,295,,Siguer,Siguer,9,Ariège,76,Occitanie
9296,SINSAT,9310,SINSAT,,42.8010692971,1.66015755913,296,,Sinsat,Sinsat,9,Ariège,76,Occitanie
9297,SOR,9800,SOR,,42.9194982505,1.01182252209,297,,Sor,Sor,9,Ariège,76,Occitanie
9298,SORGEAT,9110,SORGEAT,,42.7441981533,1.88816623684,298,,Sorgeat,Sorgeat,9,Ariège,76,Occitanie
9299,SOUEIX ROGALLE,9140,SOUEIX ROGALLE,,42.8967635679,1.19463483145,299,,Soueix-Rogalle,Soueix-Rogalle,9,Ariège,76,Occitanie
9299,SOUEIX ROGALLE,9140,SOUEIX ROGALLE,ROGALLE,42.8967635679,1.19463483145,299,,Soueix-Rogalle,Soueix-Rogalle,9,Ariège,76,Occitanie
9300,SOULA,9000,SOULA,,42.9407714858,1.69062685843,300,,Soula,Soula,9,Ariège,76,Occitanie
9301,SOULAN,9320,SOULAN,,42.9162917459,1.2434207,301,,Soulan,Soulan,9,Ariège,76,Occitanie
9302,SUC ET SENTENAC,9220,SUC ET SENTENAC,,42.8014213581,1.45539428256,302,,Suc-et-Sentenac,Suc-et-Sentenac,9,Ariège,76,Occitanie
9303,SURBA,9400,SURBA,,42.8596289181,1.5816290081,303,,Surba,Surba,9,Ariège,76,Occitanie
9304,SUZAN,9240,SUZAN,,43.0294117749,1.43489468125,304,,Suzan,Suzan,9,Ariège,76,Occitanie
9305,TABRE,9600,TABRE,,42.9887730653,1.85527536591,305,,Tabre,Tabre,9,Ariège,76,Occitanie
9306,TARASCON SUR ARIEGE,9400,TARASCON SUR ARIEGE,,42.8461514685,1.59648461871,306,,Tarascon-sur-Ariège,Tarascon-sur-Ariège,9,Ariège,76,Occitanie
9306,TARASCON SUR ARIEGE,9400,TARASCON SUR ARIEGE,BANAT,42.8461514685,1.59648461871,306,,Tarascon-sur-Ariège,Tarascon-sur-Ariège,9,Ariège,76,Occitanie
9307,TAURIGNAN CASTET,9160,TAURIGNAN CASTET,,43.0599556813,1.10196353645,307,,Taurignan-Castet,Taurignan-Castet,9,Ariège,76,Occitanie
9308,TAURIGNAN VIEUX,9190,TAURIGNAN VIEUX,,43.0434128319,1.11433094449,308,,Taurignan-Vieux,Taurignan-Vieux,9,Ariège,76,Occitanie
9309,TEILHET,9500,TEILHET,,43.0973378632,1.78252133871,309,,Teilhet,Teilhet,9,Ariège,76,Occitanie
9310,THOUARS SUR ARIZE,9350,THOUARS SUR ARIZE,,43.1819071244,1.23707299493,310,,Thouars-sur-Arize,Thouars-sur-Arize,9,Ariège,76,Occitanie
9311,TIGNAC,9110,TIGNAC,,42.7506713601,1.79915182635,311,,Tignac,Tignac,9,Ariège,76,Occitanie
9312,LA TOUR DU CRIEU,9100,LA TOUR DU CRIEU,,43.1046663015,1.6669791178,312,La,Tour-du-Crieu,La Tour-du-Crieu,9,Ariège,76,Occitanie
9313,TOURTOUSE,9230,TOURTOUSE,,43.090555845,1.13719287262,313,,Tourtouse,Tourtouse,9,Ariège,76,Occitanie
9314,TOURTROL,9500,TOURTROL,,43.0689607654,1.79942821361,314,,Tourtrol,Tourtrol,9,Ariège,76,Occitanie
9315,TREMOULET,9700,TREMOULET,,43.1581445353,1.71715487166,315,,Trémoulet,Trémoulet,9,Ariège,76,Occitanie
9316,TROYE D ARIEGE,9500,TROYE D ARIEGE,,43.0211388767,1.88727210169,316,,Troye-d'Ariège,Troye-d'Ariège,9,Ariège,76,Occitanie
9317,UCHENTEIN,9800,UCHENTEIN,,42.8878016644,1.00663579015,317,,Uchentein,Uchentein,9,Ariège,76,Occitanie
9318,UNAC,9250,UNAC,,42.7587499994,1.77948818668,318,,Unac,Unac,9,Ariège,76,Occitanie
9319,UNZENT,9100,UNZENT,,43.1713840996,1.53294419243,319,,Unzent,Unzent,9,Ariège,76,Occitanie
9320,URS,9310,URS,,42.778113198,1.73489746587,320,,Urs,Urs,9,Ariège,76,Occitanie
9321,USSAT,9400,USSAT,,42.8339319836,1.62186318062,321,,Ussat,Ussat,9,Ariège,76,Occitanie
9322,USTOU,9140,USTOU,,42.7738686412,1.2631778859,322,,Ustou,Ustou,9,Ariège,76,Occitanie
9322,USTOU,9140,USTOU,TREIN D USTOU,42.7738686412,1.2631778859,322,,Ustou,Ustou,9,Ariège,76,Occitanie
9323,VALS,9500,VALS,,43.1015841649,1.75587607399,323,,Vals,Vals,9,Ariège,76,Occitanie
9324,VARILHES,9120,VARILHES,,43.0547690657,1.63364245482,324,,Varilhes,Varilhes,9,Ariège,76,Occitanie
9325,VAYCHIS,9110,VAYCHIS,,42.7440162413,1.82180762746,325,,Vaychis,Vaychis,9,Ariège,76,Occitanie
9326,VEBRE,9310,VEBRE,,42.7696976304,1.71781381664,326,,Vèbre,Vèbre,9,Ariège,76,Occitanie
9327,VENTENAC,9120,VENTENAC,,42.9973884693,1.72364720344,327,,Ventenac,Ventenac,9,Ariège,76,Occitanie
9328,VERDUN,9310,VERDUN,,42.8060735388,1.69239160264,328,,Verdun,Verdun,9,Ariège,76,Occitanie
9329,VERNAJOUL,9000,VERNAJOUL,,42.9930828905,1.59212252039,329,,Vernajoul,Vernajoul,9,Ariège,76,Occitanie
9330,VERNAUX,9250,VERNAUX,,42.786305834,1.78239713936,330,,Vernaux,Vernaux,9,Ariège,76,Occitanie
9331,LE VERNET,9700,LE VERNET,,43.1871732316,1.60423543704,331,Le,Vernet,Le Vernet,9,Ariège,76,Occitanie
9332,VERNIOLLE,9340,VERNIOLLE,,43.0809118963,1.6610092477,332,,Verniolle,Verniolle,9,Ariège,76,Occitanie
9334,VICDESSOS,9220,VICDESSOS,,42.7733893717,1.51114862986,334,,Vicdessos,Vicdessos,9,Ariège,76,Occitanie
9335,VILLENEUVE,9800,VILLENEUVE,,42.9501020691,0.983231865255,335,,Villeneuve,Villeneuve,9,Ariège,76,Occitanie
9336,VILLENEUVE D OLMES,9300,VILLENEUVE D OLMES,,42.9114883664,1.82063042142,336,,Villeneuve-d'Olmes,Villeneuve-d'Olmes,9,Ariège,76,Occitanie
9338,VILLENEUVE DU LATOU,9130,VILLENEUVE DU LATOU,,43.2032906079,1.43351747814,338,,Villeneuve-du-Latou,Villeneuve-du-Latou,9,Ariège,76,Occitanie
9339,VILLENEUVE DU PAREAGE,9100,VILLENEUVE DU PAREAGE,,43.1586748014,1.64287363091,339,,Villeneuve-du-Paréage,Villeneuve-du-Paréage,9,Ariège,76,Occitanie
9340,VIRA,9120,VIRA,,43.0488081188,1.75644029197,340,,Vira,Vira,9,Ariège,76,Occitanie
9341,VIVIES,9500,VIVIES,,43.0609822099,1.77874541747,341,,Viviès,Viviès,9,Ariège,76,Occitanie
9342,STE SUZANNE,9130,STE SUZANNE,,43.2055435306,1.3790061442,342,,Sainte-Suzanne,Sainte-Suzanne,9,Ariège,76,Occitanie
10002,AILLEVILLE,10200,AILLEVILLE,,48.2549995704,4.69449329268,2,,Ailleville,Ailleville,10,Aube,44,Grand Est
10003,AIX VILLEMAUR PALIS,10160,AIX VILLEMAUR PALIS,,48.1975745771,3.73632794113,3,,Aix-Villemaur-Pâlis,Aix-Villemaur-Pâlis,10,Aube,44,Grand Est
10003,AIX VILLEMAUR PALIS,10190,AIX VILLEMAUR PALIS,PALIS,48.1975745771,3.73632794113,3,,Aix-Villemaur-Pâlis,Aix-Villemaur-Pâlis,10,Aube,44,Grand Est
10003,AIX VILLEMAUR PALIS,10190,AIX VILLEMAUR PALIS,VILLEMAUR SUR VANNE,48.1975745771,3.73632794113,3,,Aix-Villemaur-Pâlis,Aix-Villemaur-Pâlis,10,Aube,44,Grand Est
10004,ALLIBAUDIERES,10700,ALLIBAUDIERES,,48.5863997964,4.12292771236,4,,Allibaudières,Allibaudières,10,Aube,44,Grand Est
10005,AMANCE,10140,AMANCE,,48.2918540736,4.4870489216,5,,Amance,Amance,10,Aube,44,Grand Est
10006,ARCIS SUR AUBE,10700,ARCIS SUR AUBE,,48.5278271069,4.14197110248,6,,Arcis-sur-Aube,Arcis-sur-Aube,10,Aube,44,Grand Est
10007,ARCONVILLE,10200,ARCONVILLE,,48.156151635,4.72890746398,7,,Arconville,Arconville,10,Aube,44,Grand Est
10008,ARGANCON,10140,ARGANCON,,48.2503053567,4.59608826027,8,,Argançon,Argançon,10,Aube,44,Grand Est
10009,ARRELLES,10340,ARRELLES,,48.0462178704,4.27438158257,9,,Arrelles,Arrelles,10,Aube,44,Grand Est
10010,ARREMBECOURT,10330,ARREMBECOURT,,48.5432958598,4.60648952219,10,,Arrembécourt,Arrembécourt,10,Aube,44,Grand Est
10011,ARRENTIERES,10200,ARRENTIERES,,48.2679922026,4.73910370169,11,,Arrentières,Arrentières,10,Aube,44,Grand Est
10012,ARSONVAL,10200,ARSONVAL,,48.2796312677,4.65067065173,12,,Arsonval,Arsonval,10,Aube,44,Grand Est
10013,ASSENAY,10320,ASSENAY,,48.178622926,4.06510904999,13,,Assenay,Assenay,10,Aube,44,Grand Est
10014,ASSENCIERES,10220,ASSENCIERES,,48.3556065426,4.20154600861,14,,Assencières,Assencières,10,Aube,44,Grand Est
10015,AUBETERRE,10150,AUBETERRE,,48.4223977311,4.10677615502,15,,Aubeterre,Aubeterre,10,Aube,44,Grand Est
10017,AULNAY,10240,AULNAY,,48.4783453651,4.41533916578,17,,Aulnay,Aulnay,10,Aube,44,Grand Est
10018,AUXON,10130,AUXON,,48.0923181622,3.92346188322,18,,Auxon,Auxon,10,Aube,44,Grand Est
10019,VAL D AUZON,10220,VAL D AUZON,,48.4004769736,4.35930739825,19,,Val-d'Auzon,Val-d'Auzon,10,Aube,44,Grand Est
10019,VAL D AUZON,10220,VAL D AUZON,MONTANGON,48.4004769736,4.35930739825,19,,Val-d'Auzon,Val-d'Auzon,10,Aube,44,Grand Est
10019,VAL D AUZON,10220,VAL D AUZON,VILLEHARDOUIN,48.4004769736,4.35930739825,19,,Val-d'Auzon,Val-d'Auzon,10,Aube,44,Grand Est
10020,AVANT LES MARCILLY,10400,AVANT LES MARCILLY,,48.432335175,3.56013369363,20,,Avant-lès-Marcilly,Avant-lès-Marcilly,10,Aube,44,Grand Est
10021,AVANT LES RAMERUPT,10240,AVANT LES RAMERUPT,,48.4485130831,4.25845008745,21,,Avant-lès-Ramerupt,Avant-lès-Ramerupt,10,Aube,44,Grand Est
10022,AVIREY LINGEY,10340,AVIREY LINGEY,,48.028003129,4.29702645691,22,,Avirey-Lingey,Avirey-Lingey,10,Aube,44,Grand Est
10023,AVON LA PEZE,10290,AVON LA PEZE,,48.3874423014,3.65147515296,23,,Avon-la-Pèze,Avon-la-Pèze,10,Aube,44,Grand Est
10024,AVREUIL,10130,AVREUIL,,48.0531394763,3.99802844236,24,,Avreuil,Avreuil,10,Aube,44,Grand Est
10025,BAGNEUX LA FOSSE,10340,BAGNEUX LA FOSSE,,47.9987642556,4.291107685,25,,Bagneux-la-Fosse,Bagneux-la-Fosse,10,Aube,44,Grand Est
10026,BAILLY LE FRANC,10330,BAILLY LE FRANC,,48.5224912363,4.65913360168,26,,Bailly-le-Franc,Bailly-le-Franc,10,Aube,44,Grand Est
10027,BALIGNICOURT,10330,BALIGNICOURT,,48.5295826019,4.45448981807,27,,Balignicourt,Balignicourt,10,Aube,44,Grand Est
10028,BALNOT LA GRANGE,10210,BALNOT LA GRANGE,,47.9884933352,4.19819242962,28,,Balnot-la-Grange,Balnot-la-Grange,10,Aube,44,Grand Est
10029,BALNOT SUR LAIGNES,10110,BALNOT SUR LAIGNES,,48.0369726727,4.35269608813,29,,Balnot-sur-Laignes,Balnot-sur-Laignes,10,Aube,44,Grand Est
10030,BARBEREY ST SULPICE,10600,BARBEREY ST SULPICE,,48.3271986159,4.01643187202,30,,Barberey-Saint-Sulpice,Barberey-Saint-Sulpice,10,Aube,44,Grand Est
10031,BARBUISE,10400,BARBUISE,,48.5509217232,3.562035391,31,,Barbuise,Barbuise,10,Aube,44,Grand Est
10031,BARBUISE,10400,BARBUISE,COURTAVANT,48.5509217232,3.562035391,31,,Barbuise,Barbuise,10,Aube,44,Grand Est
10032,BAROVILLE,10200,BAROVILLE,,48.1827443847,4.74092531324,32,,Baroville,Baroville,10,Aube,44,Grand Est
10033,BAR SUR AUBE,10200,BAR SUR AUBE,,48.2339751025,4.71595700167,33,,Bar-sur-Aube,Bar-sur-Aube,10,Aube,44,Grand Est
10034,BAR SUR SEINE,10110,BAR SUR SEINE,,48.1127221863,4.37880982139,34,,Bar-sur-Seine,Bar-sur-Seine,10,Aube,44,Grand Est
10035,BAYEL,10310,BAYEL,,48.1924942272,4.79087455896,35,,Bayel,Bayel,10,Aube,44,Grand Est
10037,BERCENAY EN OTHE,10190,BERCENAY EN OTHE,,48.1931400495,3.89760036901,37,,Bercenay-en-Othe,Bercenay-en-Othe,10,Aube,44,Grand Est
10038,BERCENAY LE HAYER,10290,BERCENAY LE HAYER,,48.3318095412,3.58999948378,38,,Bercenay-le-Hayer,Bercenay-le-Hayer,10,Aube,44,Grand Est
10039,BERGERES,10200,BERGERES,,48.1844237719,4.66943083986,39,,Bergères,Bergères,10,Aube,44,Grand Est
10040,BERNON,10130,BERNON,,47.9870798337,3.99246812197,40,,Bernon,Bernon,10,Aube,44,Grand Est
10041,BERTIGNOLLES,10110,BERTIGNOLLES,,48.1367453807,4.51279936147,41,,Bertignolles,Bertignolles,10,Aube,44,Grand Est
10042,BERULLE,10160,BERULLE,,48.1707680299,3.6716310036,42,,Bérulle,Bérulle,10,Aube,44,Grand Est
10043,BESSY,10170,BESSY,,48.5413728268,4.02489066641,43,,Bessy,Bessy,10,Aube,44,Grand Est
10044,BETIGNICOURT,10500,BETIGNICOURT,,48.4499267207,4.45503670756,44,,Bétignicourt,Bétignicourt,10,Aube,44,Grand Est
10045,BEUREY,10140,BEUREY,,48.1758848753,4.47296130877,45,,Beurey,Beurey,10,Aube,44,Grand Est
10046,BLAINCOURT SUR AUBE,10500,BLAINCOURT SUR AUBE,,48.3814393214,4.45229884656,46,,Blaincourt-sur-Aube,Blaincourt-sur-Aube,10,Aube,44,Grand Est
10047,BLIGNICOURT,10500,BLIGNICOURT,,48.453428637,4.54117489655,47,,Blignicourt,Blignicourt,10,Aube,44,Grand Est
10048,BLIGNY,10200,BLIGNY,,48.1737009624,4.6045187109,48,,Bligny,Bligny,10,Aube,44,Grand Est
10049,LES BORDES AUMONT,10800,LES BORDES AUMONT,,48.1880650067,4.11899705959,49,Les,Bordes-Aumont,Les Bordes-Aumont,10,Aube,44,Grand Est
10050,BOSSANCOURT,10140,BOSSANCOURT,,48.2832853612,4.61146739237,50,,Bossancourt,Bossancourt,10,Aube,44,Grand Est
10051,BOUILLY,10320,BOUILLY,,48.1864006342,3.99123563409,51,,Bouilly,Bouilly,10,Aube,44,Grand Est
10052,BOULAGES,10380,BOULAGES,,48.5873035501,3.92269900337,52,,Boulages,Boulages,10,Aube,44,Grand Est
10053,BOURANTON,10270,BOURANTON,,48.3149302747,4.1804938417,53,,Bouranton,Bouranton,10,Aube,44,Grand Est
10054,BOURDENAY,10290,BOURDENAY,,48.36105163,3.59076650047,54,,Bourdenay,Bourdenay,10,Aube,44,Grand Est
10055,BOURGUIGNONS,10110,BOURGUIGNONS,,48.1412628808,4.3624282849,55,,Bourguignons,Bourguignons,10,Aube,44,Grand Est
10056,BOUY LUXEMBOURG,10220,BOUY LUXEMBOURG,,48.3750872651,4.24288832976,56,,Bouy-Luxembourg,Bouy-Luxembourg,10,Aube,44,Grand Est
10057,BOUY SUR ORVIN,10400,BOUY SUR ORVIN,,48.4230447097,3.49742400527,57,,Bouy-sur-Orvin,Bouy-sur-Orvin,10,Aube,44,Grand Est
10058,BRAGELOGNE BEAUVOIR,10340,BRAGELOGNE BEAUVOIR,,47.9698706523,4.26657498666,58,,Bragelogne-Beauvoir,Bragelogne-Beauvoir,10,Aube,44,Grand Est
10058,BRAGELOGNE BEAUVOIR,10340,BRAGELOGNE BEAUVOIR,BEAUVOIR SUR SARCE,47.9698706523,4.26657498666,58,,Bragelogne-Beauvoir,Bragelogne-Beauvoir,10,Aube,44,Grand Est
10059,BRAUX,10500,BRAUX,,48.4830951533,4.45819017109,59,,Braux,Braux,10,Aube,44,Grand Est
10060,BREVIANDES,10450,BREVIANDES,,48.2562259501,4.10756293028,60,,Bréviandes,Bréviandes,10,Aube,44,Grand Est
10060,BREVIANDES,10450,BREVIANDES,VILLEPART,48.2562259501,4.10756293028,60,,Bréviandes,Bréviandes,10,Aube,44,Grand Est
10061,BREVONNES,10220,BREVONNES,,48.3493701075,4.40891289591,61,,Brévonnes,Brévonnes,10,Aube,44,Grand Est
10062,BRIEL SUR BARSE,10140,BRIEL SUR BARSE,,48.2131293074,4.35408912876,62,,Briel-sur-Barse,Briel-sur-Barse,10,Aube,44,Grand Est
10063,BRIENNE LA VIEILLE,10500,BRIENNE LA VIEILLE,,48.3730744162,4.54688493664,63,,Brienne-la-Vieille,Brienne-la-Vieille,10,Aube,44,Grand Est
10064,BRIENNE LE CHATEAU,10500,BRIENNE LE CHATEAU,,48.3974195288,4.53928532253,64,,Brienne-le-Château,Brienne-le-Château,10,Aube,44,Grand Est
10065,BRILLECOURT,10240,BRILLECOURT,,48.4771455797,4.37072298984,65,,Brillecourt,Brillecourt,10,Aube,44,Grand Est
10066,BUCEY EN OTHE,10190,BUCEY EN OTHE,,48.2547509425,3.86739826398,66,,Bucey-en-Othe,Bucey-en-Othe,10,Aube,44,Grand Est
10067,BUCHERES,10800,BUCHERES,,48.2364830009,4.11414103262,67,,Buchères,Buchères,10,Aube,44,Grand Est
10067,BUCHERES,10800,BUCHERES,COURGERENNES,48.2364830009,4.11414103262,67,,Buchères,Buchères,10,Aube,44,Grand Est
10068,BUXEUIL,10110,BUXEUIL,,48.0540843697,4.40552369519,68,,Buxeuil,Buxeuil,10,Aube,44,Grand Est
10069,BUXIERES SUR ARCE,10110,BUXIERES SUR ARCE,,48.1371746662,4.45605728723,69,,Buxières-sur-Arce,Buxières-sur-Arce,10,Aube,44,Grand Est
10070,CELLES SUR OURCE,10110,CELLES SUR OURCE,,48.0738193958,4.42247353986,70,,Celles-sur-Ource,Celles-sur-Ource,10,Aube,44,Grand Est
10071,CHACENAY,10110,CHACENAY,,48.1073040053,4.53224056056,71,,Chacenay,Chacenay,10,Aube,44,Grand Est
10072,LA CHAISE,10500,LA CHAISE,,48.358428601,4.67140610567,72,La,Chaise,La Chaise,10,Aube,44,Grand Est
10073,CHALETTE SUR VOIRE,10500,CHALETTE SUR VOIRE,,48.4518666815,4.42460080275,73,,Chalette-sur-Voire,Chalette-sur-Voire,10,Aube,44,Grand Est
10074,CHAMOY,10130,CHAMOY,,48.1141180012,3.96559597097,74,,Chamoy,Chamoy,10,Aube,44,Grand Est
10075,CHAMPFLEURY,10700,CHAMPFLEURY,,48.6142848196,4.0310704592,75,,Champfleury,Champfleury,10,Aube,44,Grand Est
10076,CHAMPIGNOL LEZ MONDEVILLE,10200,CHAMPIGNOL LEZ MONDEVILLE,,48.1252290684,4.67939293455,76,,Champignol-lez-Mondeville,Champignol-lez-Mondeville,10,Aube,44,Grand Est
10077,CHAMPIGNY SUR AUBE,10700,CHAMPIGNY SUR AUBE,,48.5677006703,4.07229528495,77,,Champigny-sur-Aube,Champigny-sur-Aube,10,Aube,44,Grand Est
10078,CHAMP SUR BARSE,10140,CHAMP SUR BARSE,,48.2389704336,4.41368768098,78,,Champ-sur-Barse,Champ-sur-Barse,10,Aube,44,Grand Est
10079,CHANNES,10340,CHANNES,,47.9409115952,4.26640819878,79,,Channes,Channes,10,Aube,44,Grand Est
10080,CHAOURCE,10210,CHAOURCE,,48.0548103736,4.14007416764,80,,Chaource,Chaource,10,Aube,44,Grand Est
10081,LA CHAPELLE ST LUC,10600,LA CHAPELLE ST LUC,,48.3161316832,4.03517553523,81,La,Chapelle-Saint-Luc,La Chapelle-Saint-Luc,10,Aube,44,Grand Est
10082,CHAPELLE VALLON,10700,CHAPELLE VALLON,,48.4345746566,4.04213440275,82,,Chapelle-Vallon,Chapelle-Vallon,10,Aube,44,Grand Est
10083,CHAPPES,10260,CHAPPES,,48.1659482698,4.25688351486,83,,Chappes,Chappes,10,Aube,44,Grand Est
10084,CHARMONT SOUS BARBUISE,10150,CHARMONT SOUS BARBUISE,,48.4150600401,4.18047013925,84,,Charmont-sous-Barbuise,Charmont-sous-Barbuise,10,Aube,44,Grand Est
10084,CHARMONT SOUS BARBUISE,10150,CHARMONT SOUS BARBUISE,FONTAINE LUYERES,48.4150600401,4.18047013925,84,,Charmont-sous-Barbuise,Charmont-sous-Barbuise,10,Aube,44,Grand Est
10085,CHARMOY,10290,CHARMOY,,48.3932867782,3.58043109373,85,,Charmoy,Charmoy,10,Aube,44,Grand Est
10086,CHARNY LE BACHOT,10380,CHARNY LE BACHOT,,48.5389297252,3.95231242172,86,,Charny-le-Bachot,Charny-le-Bachot,10,Aube,44,Grand Est
10087,CHASEREY,10210,CHASEREY,,47.9530607748,4.07447909576,87,,Chaserey,Chaserey,10,Aube,44,Grand Est
10089,CHATRES,10510,CHATRES,,48.4931286007,3.83670871212,89,,Châtres,Châtres,10,Aube,44,Grand Est
10090,CHAUCHIGNY,10170,CHAUCHIGNY,,48.4261431924,3.98046866414,90,,Chauchigny,Chauchigny,10,Aube,44,Grand Est
10091,CHAUDREY,10240,CHAUDREY,,48.4851020119,4.24517454348,91,,Chaudrey,Chaudrey,10,Aube,44,Grand Est
10092,CHAUFFOUR LES BAILLY,10110,CHAUFFOUR LES BAILLY,,48.1856806779,4.30967778622,92,,Chauffour-lès-Bailly,Chauffour-lès-Bailly,10,Aube,44,Grand Est
10093,CHAUMESNIL,10500,CHAUMESNIL,,48.3578609264,4.61254890669,93,,Chaumesnil,Chaumesnil,10,Aube,44,Grand Est
10094,CHAVANGES,10330,CHAVANGES,,48.5158465508,4.56655874954,94,,Chavanges,Chavanges,10,Aube,44,Grand Est
10095,LE CHENE,10700,LE CHENE,,48.5628565037,4.17589544005,95,Le,Chêne,Le Chêne,10,Aube,44,Grand Est
10096,CHENNEGY,10190,CHENNEGY,,48.2170539239,3.84917512614,96,,Chennegy,Chennegy,10,Aube,44,Grand Est
10097,CHERVEY,10110,CHERVEY,,48.1347612309,4.49011321758,97,,Chervey,Chervey,10,Aube,44,Grand Est
10098,CHESLEY,10210,CHESLEY,,47.9769865279,4.11159585943,98,,Chesley,Chesley,10,Aube,44,Grand Est
10099,CHESSY LES PRES,10130,CHESSY LES PRES,,48.0137819738,3.93361482434,99,,Chessy-les-Prés,Chessy-les-Prés,10,Aube,44,Grand Est
10100,CLEREY,10390,CLEREY,,48.208429876,4.1939087968,100,,Clérey,Clérey,10,Aube,44,Grand Est
10101,COCLOIS,10240,COCLOIS,,48.4708420035,4.33090572683,101,,Coclois,Coclois,10,Aube,44,Grand Est
10102,COLOMBE LA FOSSE,10200,COLOMBE LA FOSSE,,48.2710119286,4.79010684615,102,,Colombé-la-Fosse,Colombé-la-Fosse,10,Aube,44,Grand Est
10103,COLOMBE LE SEC,10200,COLOMBE LE SEC,,48.2556375156,4.80343883572,103,,Colombé-le-Sec,Colombé-le-Sec,10,Aube,44,Grand Est
10104,CORMOST,10800,CORMOST,,48.1565309093,4.13264501348,104,,Cormost,Cormost,10,Aube,44,Grand Est
10105,COURCELLES SUR VOIRE,10500,COURCELLES SUR VOIRE,,48.4822201574,4.52921510366,105,,Courcelles-sur-Voire,Courcelles-sur-Voire,10,Aube,44,Grand Est
10106,COURCEROY,10400,COURCEROY,,48.4683742502,3.40826279907,106,,Courceroy,Courceroy,10,Aube,44,Grand Est
10107,COURSAN EN OTHE,10130,COURSAN EN OTHE,,48.0778186819,3.82587613771,107,,Coursan-en-Othe,Coursan-en-Othe,10,Aube,44,Grand Est
10108,COURTAOULT,10130,COURTAOULT,,48.0248110791,3.87198564953,108,,Courtaoult,Courtaoult,10,Aube,44,Grand Est
10109,COURTENOT,10260,COURTENOT,,48.1546612208,4.31372014435,109,,Courtenot,Courtenot,10,Aube,44,Grand Est
10110,COURTERANGES,10270,COURTERANGES,,48.2683513867,4.22551791779,110,,Courteranges,Courteranges,10,Aube,44,Grand Est
10111,COURTERON,10250,COURTERON,,48.0158955868,4.46256663692,111,,Courteron,Courteron,10,Aube,44,Grand Est
10112,COUSSEGREY,10210,COUSSEGREY,,47.95007971,4.03054692613,112,,Coussegrey,Coussegrey,10,Aube,44,Grand Est
10113,COUVIGNON,10200,COUVIGNON,,48.204390818,4.66976546789,113,,Couvignon,Couvignon,10,Aube,44,Grand Est
10113,COUVIGNON,10200,COUVIGNON,VAL PERDU,48.204390818,4.66976546789,113,,Couvignon,Couvignon,10,Aube,44,Grand Est
10114,CRANCEY,10100,CRANCEY,,48.5188432879,3.63661201802,114,,Crancey,Crancey,10,Aube,44,Grand Est
10115,CRENEY PRES TROYES,10150,CRENEY PRES TROYES,,48.342847974,4.13898615195,115,,Creney-près-Troyes,Creney-près-Troyes,10,Aube,44,Grand Est
10115,CRENEY PRES TROYES,10150,CRENEY PRES TROYES,ARGENTOLLES,48.342847974,4.13898615195,115,,Creney-près-Troyes,Creney-près-Troyes,10,Aube,44,Grand Est
10116,CRESANTIGNES,10320,CRESANTIGNES,,48.1427730557,4.02038435351,116,,Crésantignes,Crésantignes,10,Aube,44,Grand Est
10117,CRESPY LE NEUF,10500,CRESPY LE NEUF,,48.3984963901,4.60980302355,117,,Crespy-le-Neuf,Crespy-le-Neuf,10,Aube,44,Grand Est
10118,LES CROUTES,10130,LES CROUTES,,47.9934515972,3.86370610292,118,Les,Croûtes,Les Croûtes,10,Aube,44,Grand Est
10119,CUNFIN,10360,CUNFIN,,48.0441976568,4.66975092005,119,,Cunfin,Cunfin,10,Aube,44,Grand Est
10120,CUSSANGY,10210,CUSSANGY,,48.0230498915,4.09099167975,120,,Cussangy,Cussangy,10,Aube,44,Grand Est
10121,DAMPIERRE,10240,DAMPIERRE,,48.5651902607,4.36459312482,121,,Dampierre,Dampierre,10,Aube,44,Grand Est
10122,DAVREY,10130,DAVREY,,48.0459988862,3.96029506309,122,,Davrey,Davrey,10,Aube,44,Grand Est
10123,DIENVILLE,10500,DIENVILLE,,48.3297297619,4.5139323628,123,,Dienville,Dienville,10,Aube,44,Grand Est
10124,DIERREY ST JULIEN,10190,DIERREY ST JULIEN,,48.3050645625,3.83470708192,124,,Dierrey-Saint-Julien,Dierrey-Saint-Julien,10,Aube,44,Grand Est
10125,DIERREY ST PIERRE,10190,DIERREY ST PIERRE,,48.3325291626,3.83136359134,125,,Dierrey-Saint-Pierre,Dierrey-Saint-Pierre,10,Aube,44,Grand Est
10126,DOLANCOURT,10200,DOLANCOURT,,48.2661527498,4.60812858632,126,,Dolancourt,Dolancourt,10,Aube,44,Grand Est
10127,DOMMARTIN LE COQ,10240,DOMMARTIN LE COQ,,48.4976185098,4.35585501464,127,,Dommartin-le-Coq,Dommartin-le-Coq,10,Aube,44,Grand Est
10128,DONNEMENT,10330,DONNEMENT,,48.519771783,4.42815499728,128,,Donnement,Donnement,10,Aube,44,Grand Est
10129,DOSCHES,10220,DOSCHES,,48.3146008777,4.26804909725,129,,Dosches,Dosches,10,Aube,44,Grand Est
10130,DOSNON,10700,DOSNON,,48.6149582778,4.24653182563,130,,Dosnon,Dosnon,10,Aube,44,Grand Est
10131,DROUPT ST BASLE,10170,DROUPT ST BASLE,,48.4804439943,3.95701676,131,,Droupt-Saint-Basle,Droupt-Saint-Basle,10,Aube,44,Grand Est
10132,DROUPT STE MARIE,10170,DROUPT STE MARIE,,48.5020825173,3.93946313114,132,,Droupt-Sainte-Marie,Droupt-Sainte-Marie,10,Aube,44,Grand Est
10133,EAUX PUISEAUX,10130,EAUX PUISEAUX,,48.1167185548,3.88681885834,133,,Eaux-Puiseaux,Eaux-Puiseaux,10,Aube,44,Grand Est
10134,ECHEMINES,10350,ECHEMINES,,48.3904941746,3.83580680573,134,,Échemines,Échemines,10,Aube,44,Grand Est
10135,ECLANCE,10200,ECLANCE,,48.3074667085,4.64323131646,135,,Éclance,Éclance,10,Aube,44,Grand Est
10136,EGUILLY SOUS BOIS,10110,EGUILLY SOUS BOIS,,48.1388297838,4.53815136871,136,,Éguilly-sous-Bois,Éguilly-sous-Bois,10,Aube,44,Grand Est
10137,ENGENTE,10200,ENGENTE,,48.2800809608,4.76374569172,137,,Engente,Engente,10,Aube,44,Grand Est
10138,EPAGNE,10500,EPAGNE,,48.3975318023,4.4698095219,138,,Épagne,Épagne,10,Aube,44,Grand Est
10139,EPOTHEMONT,10500,EPOTHEMONT,,48.4152756334,4.66162028772,139,,Épothémont,Épothémont,10,Aube,44,Grand Est
10140,ERVY LE CHATEL,10130,ERVY LE CHATEL,,48.0557945163,3.90532960286,140,,Ervy-le-Châtel,Ervy-le-Châtel,10,Aube,44,Grand Est
10141,ESSOYES,10360,ESSOYES,,48.0494900211,4.53941791707,141,,Essoyes,Essoyes,10,Aube,44,Grand Est
10142,ESTISSAC,10190,ESTISSAC,,48.2557294277,3.81076673969,142,,Estissac,Estissac,10,Aube,44,Grand Est
10142,ESTISSAC,10190,ESTISSAC,THUISY,48.2557294277,3.81076673969,142,,Estissac,Estissac,10,Aube,44,Grand Est
10143,ETOURVY,10210,ETOURVY,,47.9506558304,4.12736391483,143,,Étourvy,Étourvy,10,Aube,44,Grand Est
10144,ETRELLES SUR AUBE,10170,ETRELLES SUR AUBE,,48.5592832077,3.8771042148,144,,Étrelles-sur-Aube,Étrelles-sur-Aube,10,Aube,44,Grand Est
10145,FAUX VILLECERF,10290,FAUX VILLECERF,,48.3298226873,3.7399683031,145,,Faux-Villecerf,Faux-Villecerf,10,Aube,44,Grand Est
10146,FAY LES MARCILLY,10290,FAY LES MARCILLY,,48.401359163,3.60590310441,146,,Fay-lès-Marcilly,Fay-lès-Marcilly,10,Aube,44,Grand Est
10147,FAYS LA CHAPELLE,10320,FAYS LA CHAPELLE,,48.1311314696,4.0199211207,147,,Fays-la-Chapelle,Fays-la-Chapelle,10,Aube,44,Grand Est
10148,FERREUX QUINCEY,10400,FERREUX QUINCEY,,48.4624429856,3.60107070347,148,,Ferreux-Quincey,Ferreux-Quincey,10,Aube,44,Grand Est
10148,FERREUX QUINCEY,10400,FERREUX QUINCEY,QUINCEY,48.4624429856,3.60107070347,148,,Ferreux-Quincey,Ferreux-Quincey,10,Aube,44,Grand Est
10149,FEUGES,10150,FEUGES,,48.396564153,4.1086742347,149,,Feuges,Feuges,10,Aube,44,Grand Est
10150,FONTAINE,10200,FONTAINE,,48.2092891642,4.71805068623,150,,Fontaine,Fontaine,10,Aube,44,Grand Est
10151,FONTAINE LES GRES,10280,FONTAINE LES GRES,,48.4128351962,3.88910514408,151,,Fontaine-les-Grès,Fontaine-les-Grès,10,Aube,44,Grand Est
10153,FONTAINE MACON,10400,FONTAINE MACON,,48.4575603934,3.5070361262,153,,Fontaine-Mâcon,Fontaine-Mâcon,10,Aube,44,Grand Est
10154,FONTENAY DE BOSSERY,10400,FONTENAY DE BOSSERY,,48.4502901876,3.4644594068,154,,Fontenay-de-Bossery,Fontenay-de-Bossery,10,Aube,44,Grand Est
10155,FONTETTE,10360,FONTETTE,,48.0762843513,4.61637603965,155,,Fontette,Fontette,10,Aube,44,Grand Est
10156,FONTVANNES,10190,FONTVANNES,,48.2823364218,3.87111996216,156,,Fontvannes,Fontvannes,10,Aube,44,Grand Est
10157,LA FOSSE CORDUAN,10100,LA FOSSE CORDUAN,,48.4404732902,3.6537864039,157,La,Fosse-Corduan,La Fosse-Corduan,10,Aube,44,Grand Est
10158,FOUCHERES,10260,FOUCHERES,,48.1485731236,4.27075945691,158,,Fouchères,Fouchères,10,Aube,44,Grand Est
10159,FRALIGNES,10110,FRALIGNES,,48.1641131783,4.37961204554,159,,Fralignes,Fralignes,10,Aube,44,Grand Est
10160,FRAVAUX,10200,FRAVAUX,,48.2361442581,4.64187603164,160,,Fravaux,Fravaux,10,Aube,44,Grand Est
10161,FRESNAY,10200,FRESNAY,,48.3091765405,4.75726662976,161,,Fresnay,Fresnay,10,Aube,44,Grand Est
10162,FRESNOY LE CHATEAU,10270,FRESNOY LE CHATEAU,,48.2201181209,4.23354437753,162,,Fresnoy-le-Château,Fresnoy-le-Château,10,Aube,44,Grand Est
10163,FULIGNY,10200,FULIGNY,,48.3403541519,4.69850669182,163,,Fuligny,Fuligny,10,Aube,44,Grand Est
10164,GELANNES,10100,GELANNES,,48.4754200511,3.6678407267,164,,Gélannes,Gélannes,10,Aube,44,Grand Est
10165,GERAUDOT,10220,GERAUDOT,,48.3065900914,4.32289147943,165,,Géraudot,Géraudot,10,Aube,44,Grand Est
10166,LES GRANDES CHAPELLES,10170,LES GRANDES CHAPELLES,,48.4684641557,4.02789249999,166,Les,Grandes-Chapelles,Les Grandes-Chapelles,10,Aube,44,Grand Est
10167,GRANDVILLE,10700,GRANDVILLE,,48.5941600232,4.23537333825,167,,Grandville,Grandville,10,Aube,44,Grand Est
10168,LES GRANGES,10210,LES GRANGES,,48.0567039113,4.06179024934,168,Les,Granges,Les Granges,10,Aube,44,Grand Est
10169,GUMERY,10400,GUMERY,,48.4396051909,3.42197876151,169,,Gumery,Gumery,10,Aube,44,Grand Est
10170,GYE SUR SEINE,10250,GYE SUR SEINE,,48.0123873198,4.4395162779,170,,Gyé-sur-Seine,Gyé-sur-Seine,10,Aube,44,Grand Est
10171,HAMPIGNY,10500,HAMPIGNY,,48.4605262856,4.60136674938,171,,Hampigny,Hampigny,10,Aube,44,Grand Est
10172,HERBISSE,10700,HERBISSE,,48.6151829352,4.12161191885,172,,Herbisse,Herbisse,10,Aube,44,Grand Est
10173,ISLE AUMONT,10800,ISLE AUMONT,,48.2054661706,4.12228896976,173,,Isle-Aumont,Isle-Aumont,10,Aube,44,Grand Est
10174,ISLE AUBIGNY,10240,ISLE AUBIGNY,,48.5438287791,4.28206755992,174,,Isle-Aubigny,Isle-Aubigny,10,Aube,44,Grand Est
10175,JASSEINES,10330,JASSEINES,,48.5090150718,4.39400392389,175,,Jasseines,Jasseines,10,Aube,44,Grand Est
10176,JAUCOURT,10200,JAUCOURT,,48.2539202754,4.64112952114,176,,Jaucourt,Jaucourt,10,Aube,44,Grand Est
10177,JAVERNANT,10320,JAVERNANT,,48.1590647301,3.99847725785,177,,Javernant,Javernant,10,Aube,44,Grand Est
10178,JESSAINS,10140,JESSAINS,,48.2848174581,4.56960734893,178,,Jessains,Jessains,10,Aube,44,Grand Est
10179,JEUGNY,10320,JEUGNY,,48.1156606446,4.05456641907,179,,Jeugny,Jeugny,10,Aube,44,Grand Est
10180,JONCREUIL,10330,JONCREUIL,,48.5270359202,4.62391326662,180,,Joncreuil,Joncreuil,10,Aube,44,Grand Est
10181,JULLY SUR SARCE,10260,JULLY SUR SARCE,,48.1093784032,4.27896296747,181,,Jully-sur-Sarce,Jully-sur-Sarce,10,Aube,44,Grand Est
10182,JUVANCOURT,10310,JUVANCOURT,,48.1165186849,4.81270579284,182,,Juvancourt,Juvancourt,10,Aube,44,Grand Est
10183,JUVANZE,10140,JUVANZE,,48.3237109111,4.58905857262,183,,Juvanzé,Juvanzé,10,Aube,44,Grand Est
10184,JUZANVIGNY,10500,JUZANVIGNY,,48.4164316249,4.60987981766,184,,Juzanvigny,Juzanvigny,10,Aube,44,Grand Est
10185,LAGESSE,10210,LAGESSE,,48.01607316,4.13301378601,185,,Lagesse,Lagesse,10,Aube,44,Grand Est
10186,LAINES AUX BOIS,10120,LAINES AUX BOIS,,48.2286422657,3.97898834535,186,,Laines-aux-Bois,Laines-aux-Bois,10,Aube,44,Grand Est
10187,LANDREVILLE,10110,LANDREVILLE,,48.0711739648,4.46588961717,187,,Landreville,Landreville,10,Aube,44,Grand Est
10188,LANTAGES,10210,LANTAGES,,48.0769887467,4.20669407653,188,,Lantages,Lantages,10,Aube,44,Grand Est
10189,LASSICOURT,10500,LASSICOURT,,48.4357415556,4.50042412067,189,,Lassicourt,Lassicourt,10,Aube,44,Grand Est
10190,LAUBRESSEL,10270,LAUBRESSEL,,48.3004850515,4.22586095829,190,,Laubressel,Laubressel,10,Aube,44,Grand Est
10191,LAVAU,10150,LAVAU,,48.3334867186,4.09283972542,191,,Lavau,Lavau,10,Aube,44,Grand Est
10191,LAVAU,10150,LAVAU,LA VALLOTE,48.3334867186,4.09283972542,191,,Lavau,Lavau,10,Aube,44,Grand Est
10192,LENTILLES,10330,LENTILLES,,48.4879545588,4.62902346103,192,,Lentilles,Lentilles,10,Aube,44,Grand Est
10193,LESMONT,10500,LESMONT,,48.4302411801,4.42438844514,193,,Lesmont,Lesmont,10,Aube,44,Grand Est
10194,LEVIGNY,10200,LEVIGNY,,48.2978727553,4.70743033255,194,,Lévigny,Lévigny,10,Aube,44,Grand Est
10195,LHUITRE,10700,LHUITRE,,48.5811831935,4.27395119742,195,,Lhuître,Lhuître,10,Aube,44,Grand Est
10196,LIGNIERES,10130,LIGNIERES,,47.9569372916,3.96407269438,196,,Lignières,Lignières,10,Aube,44,Grand Est
10197,LIGNOL LE CHATEAU,10200,LIGNOL LE CHATEAU,,48.2134404984,4.81180296521,197,,Lignol-le-Château,Lignol-le-Château,10,Aube,44,Grand Est
10198,LIREY,10320,LIREY,,48.1605014296,4.03885762812,198,,Lirey,Lirey,10,Aube,44,Grand Est
10199,LOCHES SUR OURCE,10110,LOCHES SUR OURCE,,48.0701287498,4.50451246943,199,,Loches-sur-Ource,Loches-sur-Ource,10,Aube,44,Grand Est
10200,LA LOGE AUX CHEVRES,10140,LA LOGE AUX CHEVRES,,48.2694215855,4.40750585645,200,La,Loge-aux-Chèvres,La Loge-aux-Chèvres,10,Aube,44,Grand Est
10201,LA LOGE POMBLIN,10210,LA LOGE POMBLIN,,48.0675064407,4.04451121185,201,La,Loge-Pomblin,La Loge-Pomblin,10,Aube,44,Grand Est
10202,LES LOGES MARGUERON,10210,LES LOGES MARGUERON,,48.0989054968,4.11142843878,202,Les,Loges-Margueron,Les Loges-Margueron,10,Aube,44,Grand Est
10203,LONGCHAMP SUR AUJON,10310,LONGCHAMP SUR AUJON,,48.1525191193,4.81837981298,203,,Longchamp-sur-Aujon,Longchamp-sur-Aujon,10,Aube,44,Grand Est
10204,LONGEVILLE SUR MOGNE,10320,LONGEVILLE SUR MOGNE,,48.1468654256,4.06439073239,204,,Longeville-sur-Mogne,Longeville-sur-Mogne,10,Aube,44,Grand Est
10205,LONGPRE LE SEC,10140,LONGPRE LE SEC,,48.1876628751,4.52819150084,205,,Longpré-le-Sec,Longpré-le-Sec,10,Aube,44,Grand Est
10206,LONGSOLS,10240,LONGSOLS,,48.4258761682,4.28517563382,206,,Longsols,Longsols,10,Aube,44,Grand Est
10207,LONGUEVILLE SUR AUBE,10170,LONGUEVILLE SUR AUBE,,48.5509934474,3.91289272795,207,,Longueville-sur-Aube,Longueville-sur-Aube,10,Aube,44,Grand Est
10208,LA LOUPTIERE THENARD,10400,LA LOUPTIERE THENARD,,48.3848337142,3.43747423179,208,La,Louptière-Thénard,La Louptière-Thénard,10,Aube,44,Grand Est
10209,LUSIGNY SUR BARSE,10270,LUSIGNY SUR BARSE,,48.2629805812,4.27634825277,209,,Lusigny-sur-Barse,Lusigny-sur-Barse,10,Aube,44,Grand Est
10210,LUYERES,10150,LUYERES,,48.3801080696,4.19418132479,210,,Luyères,Luyères,10,Aube,44,Grand Est
10211,MACEY,10300,MACEY,,48.3057699165,3.91365964088,211,,Macey,Macey,10,Aube,44,Grand Est
10211,MACEY,10300,MACEY,GRANGE L EVEQUE,48.3057699165,3.91365964088,211,,Macey,Macey,10,Aube,44,Grand Est
10212,MACHY,10320,MACHY,,48.1456167821,4.03573129763,212,,Machy,Machy,10,Aube,44,Grand Est
10213,MAGNANT,10110,MAGNANT,,48.1677499424,4.4209760731,213,,Magnant,Magnant,10,Aube,44,Grand Est
10214,MAGNICOURT,10240,MAGNICOURT,,48.4585859701,4.38223049773,214,,Magnicourt,Magnicourt,10,Aube,44,Grand Est
10215,MAGNY FOUCHARD,10140,MAGNY FOUCHARD,,48.2350365053,4.54539283369,215,,Magny-Fouchard,Magny-Fouchard,10,Aube,44,Grand Est
10216,MAILLY LE CAMP,10230,MAILLY LE CAMP,,48.6742219512,4.18559230801,216,,Mailly-le-Camp,Mailly-le-Camp,10,Aube,44,Grand Est
10217,MAISON DES CHAMPS,10140,MAISON DES CHAMPS,,48.2437981025,4.57090591016,217,,Maison-des-Champs,Maison-des-Champs,10,Aube,44,Grand Est
10218,MAISONS LES CHAOURCE,10210,MAISONS LES CHAOURCE,,48.0086838705,4.16340357951,218,,Maisons-lès-Chaource,Maisons-lès-Chaource,10,Aube,44,Grand Est
10219,MAISONS LES SOULAINES,10200,MAISONS LES SOULAINES,,48.2950290982,4.78298618577,219,,Maisons-lès-Soulaines,Maisons-lès-Soulaines,10,Aube,44,Grand Est
10220,MAIZIERES LA GRANDE PAROISSE,10510,MAIZIERES LA GRANDE PAROISSE,,48.5043607411,3.78940830122,220,,Maizières-la-Grande-Paroisse,Maizières-la-Grande-Paroisse,10,Aube,44,Grand Est
10220,MAIZIERES LA GRANDE PAROISSE,10510,MAIZIERES LA GRANDE PAROISSE,LES GRANGES,48.5043607411,3.78940830122,220,,Maizières-la-Grande-Paroisse,Maizières-la-Grande-Paroisse,10,Aube,44,Grand Est
10221,MAIZIERES LES BRIENNE,10500,MAIZIERES LES BRIENNE,,48.4303148659,4.6007889307,221,,Maizières-lès-Brienne,Maizières-lès-Brienne,10,Aube,44,Grand Est
10222,MARAYE EN OTHE,10160,MARAYE EN OTHE,,48.1523036289,3.86532738324,222,,Maraye-en-Othe,Maraye-en-Othe,10,Aube,44,Grand Est
10223,MARCILLY LE HAYER,10290,MARCILLY LE HAYER,,48.3312815098,3.64419365693,223,,Marcilly-le-Hayer,Marcilly-le-Hayer,10,Aube,44,Grand Est
10224,MARIGNY LE CHATEL,10350,MARIGNY LE CHATEL,,48.4016409293,3.72139203898,224,,Marigny-le-Châtel,Marigny-le-Châtel,10,Aube,44,Grand Est
10225,MARNAY SUR SEINE,10400,MARNAY SUR SEINE,,48.5151408738,3.55397183293,225,,Marnay-sur-Seine,Marnay-sur-Seine,10,Aube,44,Grand Est
10226,MAROLLES LES BAILLY,10110,MAROLLES LES BAILLY,,48.1841528588,4.34621650756,226,,Marolles-lès-Bailly,Marolles-lès-Bailly,10,Aube,44,Grand Est
10227,MAROLLES SOUS LIGNIERES,10130,MAROLLES SOUS LIGNIERES,,47.9606826185,3.91959636064,227,,Marolles-sous-Lignières,Marolles-sous-Lignières,10,Aube,44,Grand Est
10228,MATHAUX,10500,MATHAUX,,48.3555193873,4.46117528248,228,,Mathaux,Mathaux,10,Aube,44,Grand Est
10229,MAUPAS,10320,MAUPAS,,48.137803272,4.07882418849,229,,Maupas,Maupas,10,Aube,44,Grand Est
10230,MERGEY,10600,MERGEY,,48.395565383,4.03305791455,230,,Mergey,Mergey,10,Aube,44,Grand Est
10231,LE MERIOT,10400,LE MERIOT,,48.5105625489,3.44198941975,231,Le,Mériot,Le Mériot,10,Aube,44,Grand Est
10232,MERREY SUR ARCE,10110,MERREY SUR ARCE,,48.0958413373,4.40492577623,232,,Merrey-sur-Arce,Merrey-sur-Arce,10,Aube,44,Grand Est
10233,MERY SUR SEINE,10170,MERY SUR SEINE,,48.5090415373,3.89883069261,233,,Méry-sur-Seine,Méry-sur-Seine,10,Aube,44,Grand Est
10234,MESGRIGNY,10170,MESGRIGNY,,48.479960001,3.86431513011,234,,Mesgrigny,Mesgrigny,10,Aube,44,Grand Est
10235,MESNIL LA COMTESSE,10700,MESNIL LA COMTESSE,,48.4981106187,4.19774579416,235,,Mesnil-la-Comtesse,Mesnil-la-Comtesse,10,Aube,44,Grand Est
10236,MESNIL LETTRE,10240,MESNIL LETTRE,,48.4610108497,4.25893438516,236,,Mesnil-Lettre,Mesnil-Lettre,10,Aube,44,Grand Est
10237,MESNIL ST LOUP,10190,MESNIL ST LOUP,,48.3083238587,3.77156922851,237,,Mesnil-Saint-Loup,Mesnil-Saint-Loup,10,Aube,44,Grand Est
10238,MESNIL ST PERE,10140,MESNIL ST PERE,,48.2511690464,4.34558452983,238,,Mesnil-Saint-Père,Mesnil-Saint-Père,10,Aube,44,Grand Est
10239,MESNIL SELLIERES,10220,MESNIL SELLIERES,,48.3358644177,4.20667057813,239,,Mesnil-Sellières,Mesnil-Sellières,10,Aube,44,Grand Est
10240,MESSON,10190,MESSON,,48.2636235898,3.91533250888,240,,Messon,Messon,10,Aube,44,Grand Est
10241,METZ ROBERT,10210,METZ ROBERT,,48.0694714886,4.09978778841,241,,Metz-Robert,Metz-Robert,10,Aube,44,Grand Est
10242,MEURVILLE,10200,MEURVILLE,,48.2042902297,4.60493225781,242,,Meurville,Meurville,10,Aube,44,Grand Est
10243,MOLINS SUR AUBE,10500,MOLINS SUR AUBE,,48.4332955789,4.38844568734,243,,Molins-sur-Aube,Molins-sur-Aube,10,Aube,44,Grand Est
10245,MONTAULIN,10270,MONTAULIN,,48.2479487774,4.1973065201,245,,Montaulin,Montaulin,10,Aube,44,Grand Est
10245,MONTAULIN,10270,MONTAULIN,DAUDES,48.2479487774,4.1973065201,245,,Montaulin,Montaulin,10,Aube,44,Grand Est
10246,MONTCEAUX LES VAUDES,10260,MONTCEAUX LES VAUDES,,48.148825142,4.1578812358,246,,Montceaux-lès-Vaudes,Montceaux-lès-Vaudes,10,Aube,44,Grand Est
10247,MONTFEY,10130,MONTFEY,,48.0665324378,3.87246147303,247,,Montfey,Montfey,10,Aube,44,Grand Est
10248,MONTGUEUX,10300,MONTGUEUX,,48.2976863348,3.95463268701,248,,Montgueux,Montgueux,10,Aube,44,Grand Est
10249,MONTIERAMEY,10270,MONTIERAMEY,,48.2350578158,4.31390448767,249,,Montiéramey,Montiéramey,10,Aube,44,Grand Est
10250,MONTIER EN L ISLE,10200,MONTIER EN L ISLE,,48.2695334218,4.68288952065,250,,Montier-en-l'Isle,Montier-en-l'Isle,10,Aube,44,Grand Est
10251,MONTIGNY LES MONTS,10130,MONTIGNY LES MONTS,,48.0917650744,3.9649860818,251,,Montigny-les-Monts,Montigny-les-Monts,10,Aube,44,Grand Est
10252,MONTMARTIN LE HAUT,10140,MONTMARTIN LE HAUT,,48.2094879831,4.54987689229,252,,Montmartin-le-Haut,Montmartin-le-Haut,10,Aube,44,Grand Est
10253,MONTMORENCY BEAUFORT,10330,MONTMORENCY BEAUFORT,,48.4860560595,4.55616218734,253,,Montmorency-Beaufort,Montmorency-Beaufort,10,Aube,44,Grand Est
10254,MONTPOTHIER,10400,MONTPOTHIER,,48.5762344915,3.51599225081,254,,Montpothier,Montpothier,10,Aube,44,Grand Est
10255,MONTREUIL SUR BARSE,10270,MONTREUIL SUR BARSE,,48.2169516287,4.28658363089,255,,Montreuil-sur-Barse,Montreuil-sur-Barse,10,Aube,44,Grand Est
10256,MONTSUZAIN,10150,MONTSUZAIN,,48.4457957709,4.14056971998,256,,Montsuzain,Montsuzain,10,Aube,44,Grand Est
10257,MOREMBERT,10240,MOREMBERT,,48.5033982569,4.33076650572,257,,Morembert,Morembert,10,Aube,44,Grand Est
10258,MORVILLIERS,10500,MORVILLIERS,,48.3786765454,4.63547205459,258,,Morvilliers,Morvilliers,10,Aube,44,Grand Est
10259,LA MOTTE TILLY,10400,LA MOTTE TILLY,,48.4758552545,3.44037577469,259,La,Motte-Tilly,La Motte-Tilly,10,Aube,44,Grand Est
10260,MOUSSEY,10800,MOUSSEY,,48.2189146741,4.09309786071,260,,Moussey,Moussey,10,Aube,44,Grand Est
10261,MUSSY SUR SEINE,10250,MUSSY SUR SEINE,,47.9845203158,4.50679375168,261,,Mussy-sur-Seine,Mussy-sur-Seine,10,Aube,44,Grand Est
10262,NEUVILLE SUR SEINE,10250,NEUVILLE SUR SEINE,,48.0346867571,4.40826042704,262,,Neuville-sur-Seine,Neuville-sur-Seine,10,Aube,44,Grand Est
10263,NEUVILLE SUR VANNE,10190,NEUVILLE SUR VANNE,,48.2525559272,3.77471634627,263,,Neuville-sur-Vanne,Neuville-sur-Vanne,10,Aube,44,Grand Est
10264,NOE LES MALLETS,10360,NOE LES MALLETS,,48.0984070868,4.56182185285,264,,Noé-les-Mallets,Noé-les-Mallets,10,Aube,44,Grand Est
10265,LES NOES PRES TROYES,10420,LES NOES PRES TROYES,,48.3033387671,4.04203482801,265,Les,Noës-près-Troyes,Les Noës-près-Troyes,10,Aube,44,Grand Est
10266,NOGENT EN OTHE,10160,NOGENT EN OTHE,,48.1307541602,3.79686914903,266,,Nogent-en-Othe,Nogent-en-Othe,10,Aube,44,Grand Est
10267,NOGENT SUR AUBE,10240,NOGENT SUR AUBE,,48.4846626534,4.29910667758,267,,Nogent-sur-Aube,Nogent-sur-Aube,10,Aube,44,Grand Est
10268,NOGENT SUR SEINE,10400,NOGENT SUR SEINE,,48.4962576328,3.49983013092,268,,Nogent-sur-Seine,Nogent-sur-Seine,10,Aube,44,Grand Est
10269,NOZAY,10700,NOZAY,,48.5002763071,4.08266438587,269,,Nozay,Nozay,10,Aube,44,Grand Est
10270,ONJON,10220,ONJON,,48.3987860925,4.27579822531,270,,Onjon,Onjon,10,Aube,44,Grand Est
10271,ORIGNY LE SEC,10510,ORIGNY LE SEC,,48.4630378717,3.77926165141,271,,Origny-le-Sec,Origny-le-Sec,10,Aube,44,Grand Est
10272,ORMES,10700,ORMES,,48.555534468,4.1130717526,272,,Ormes,Ormes,10,Aube,44,Grand Est
10273,ORTILLON,10700,ORTILLON,,48.4923353048,4.23005258544,273,,Ortillon,Ortillon,10,Aube,44,Grand Est
10274,ORVILLIERS ST JULIEN,10170,ORVILLIERS ST JULIEN,,48.4387695312,3.82249131327,274,,Orvilliers-Saint-Julien,Orvilliers-Saint-Julien,10,Aube,44,Grand Est
10275,OSSEY LES TROIS MAISONS,10100,OSSEY LES TROIS MAISONS,,48.4386027369,3.74395562708,275,,Ossey-les-Trois-Maisons,Ossey-les-Trois-Maisons,10,Aube,44,Grand Est
10276,PAISY COSDON,10160,PAISY COSDON,,48.2147753548,3.69896514252,276,,Paisy-Cosdon,Paisy-Cosdon,10,Aube,44,Grand Est
10278,PARGUES,10210,PARGUES,,48.0183119376,4.20486733483,278,,Pargues,Pargues,10,Aube,44,Grand Est
10279,PARS LES CHAVANGES,10330,PARS LES CHAVANGES,,48.5173117634,4.50334630462,279,,Pars-lès-Chavanges,Pars-lès-Chavanges,10,Aube,44,Grand Est
10280,PARS LES ROMILLY,10100,PARS LES ROMILLY,,48.4812645725,3.72853000882,280,,Pars-lès-Romilly,Pars-lès-Romilly,10,Aube,44,Grand Est
10281,LE PAVILLON STE JULIE,10350,LE PAVILLON STE JULIE,,48.3665011556,3.9015348936,281,Le,Pavillon-Sainte-Julie,Le Pavillon-Sainte-Julie,10,Aube,44,Grand Est
10282,PAYNS,10600,PAYNS,,48.3754453406,3.95779312203,282,,Payns,Payns,10,Aube,44,Grand Est
10283,PEL ET DER,10500,PEL ET DER,,48.3952042908,4.41036179152,283,,Pel-et-Der,Pel-et-Der,10,Aube,44,Grand Est
10284,PERIGNY LA ROSE,10400,PERIGNY LA ROSE,,48.55364742,3.62433153025,284,,Périgny-la-Rose,Périgny-la-Rose,10,Aube,44,Grand Est
10285,PERTHES LES BRIENNE,10500,PERTHES LES BRIENNE,,48.4340121047,4.54181585962,285,,Perthes-lès-Brienne,Perthes-lès-Brienne,10,Aube,44,Grand Est
10286,PETIT MESNIL,10500,PETIT MESNIL,,48.3405305907,4.62293165674,286,,Petit-Mesnil,Petit-Mesnil,10,Aube,44,Grand Est
10287,PINEY,10220,PINEY,,48.3314574257,4.35909414684,287,,Piney,Piney,10,Aube,44,Grand Est
10288,PLAINES ST LANGE,10250,PLAINES ST LANGE,,47.9974095194,4.47394656864,288,,Plaines-Saint-Lange,Plaines-Saint-Lange,10,Aube,44,Grand Est
10289,PLANCY L ABBAYE,10380,PLANCY L ABBAYE,,48.5851575322,3.98528853809,289,,Plancy-l'Abbaye,Plancy-l'Abbaye,10,Aube,44,Grand Est
10289,PLANCY L ABBAYE,10380,PLANCY L ABBAYE,VIAPRES LE GRAND,48.5851575322,3.98528853809,289,,Plancy-l'Abbaye,Plancy-l'Abbaye,10,Aube,44,Grand Est
10290,PLANTY,10160,PLANTY,,48.2679798119,3.65050759721,290,,Planty,Planty,10,Aube,44,Grand Est
10291,PLESSIS BARBUISE,10400,PLESSIS BARBUISE,,48.5730831109,3.58602602539,291,,Plessis-Barbuise,Plessis-Barbuise,10,Aube,44,Grand Est
10293,POIVRES,10700,POIVRES,,48.6829849086,4.27703317765,293,,Poivres,Poivres,10,Aube,44,Grand Est
10294,POLIGNY,10110,POLIGNY,,48.1751501958,4.34626027201,294,,Poligny,Poligny,10,Aube,44,Grand Est
10295,POLISOT,10110,POLISOT,,48.0731308574,4.35467201881,295,,Polisot,Polisot,10,Aube,44,Grand Est
10296,POLISY,10110,POLISY,,48.0575849334,4.36193308689,296,,Polisy,Polisy,10,Aube,44,Grand Est
10297,PONT STE MARIE,10150,PONT STE MARIE,,48.3193392667,4.09949303348,297,,Pont-Sainte-Marie,Pont-Sainte-Marie,10,Aube,44,Grand Est
10298,PONT SUR SEINE,10400,PONT SUR SEINE,,48.509386823,3.59718207099,298,,Pont-sur-Seine,Pont-sur-Seine,10,Aube,44,Grand Est
10299,POUAN LES VALLEES,10700,POUAN LES VALLEES,,48.5339280475,4.06177485223,299,,Pouan-les-Vallées,Pouan-les-Vallées,10,Aube,44,Grand Est
10300,POUGY,10240,POUGY,,48.4368741428,4.3487522917,300,,Pougy,Pougy,10,Aube,44,Grand Est
10301,POUY SUR VANNES,10290,POUY SUR VANNES,,48.2978458721,3.59984902807,301,,Pouy-sur-Vannes,Pouy-sur-Vannes,10,Aube,44,Grand Est
10302,PRASLIN,10210,PRASLIN,,48.0459143506,4.20718637657,302,,Praslin,Praslin,10,Aube,44,Grand Est
10303,PRECY NOTRE DAME,10500,PRECY NOTRE DAME,,48.4023333581,4.43620676129,303,,Précy-Notre-Dame,Précy-Notre-Dame,10,Aube,44,Grand Est
10304,PRECY ST MARTIN,10500,PRECY ST MARTIN,,48.4166599572,4.45638796255,304,,Précy-Saint-Martin,Précy-Saint-Martin,10,Aube,44,Grand Est
10305,PREMIERFAIT,10170,PREMIERFAIT,,48.5029794134,4.02255796125,305,,Prémierfait,Prémierfait,10,Aube,44,Grand Est
10306,PROVERVILLE,10200,PROVERVILLE,,48.2326139251,4.67236770859,306,,Proverville,Proverville,10,Aube,44,Grand Est
10307,PRUGNY,10190,PRUGNY,,48.2473913847,3.94199151664,307,,Prugny,Prugny,10,Aube,44,Grand Est
10308,PRUNAY BELLEVILLE,10350,PRUNAY BELLEVILLE,,48.3664762511,3.7828232388,308,,Prunay-Belleville,Prunay-Belleville,10,Aube,44,Grand Est
10309,PRUSY,10210,PRUSY,,47.9739129756,4.05129486893,309,,Prusy,Prusy,10,Aube,44,Grand Est
10310,PUITS ET NUISEMENT,10140,PUITS ET NUISEMENT,,48.215021538,4.51130773229,310,,Puits-et-Nuisement,Puits-et-Nuisement,10,Aube,44,Grand Est
10312,RACINES,10130,RACINES,,48.0483905715,3.84645827817,312,,Racines,Racines,10,Aube,44,Grand Est
10313,RADONVILLIERS,10500,RADONVILLIERS,,48.3337407635,4.47438100931,313,,Radonvilliers,Radonvilliers,10,Aube,44,Grand Est
10314,RAMERUPT,10240,RAMERUPT,,48.5223260562,4.30433768781,314,,Ramerupt,Ramerupt,10,Aube,44,Grand Est
10314,RAMERUPT,10240,RAMERUPT,ROMAINES,48.5223260562,4.30433768781,314,,Ramerupt,Ramerupt,10,Aube,44,Grand Est
10315,RANCES,10500,RANCES,,48.4620840236,4.55442425435,315,,Rances,Rances,10,Aube,44,Grand Est
10316,RHEGES,10170,RHEGES,,48.5320121391,3.99460836996,316,,Rhèges,Rhèges,10,Aube,44,Grand Est
10317,LES RICEYS,10340,LES RICEYS,,47.9874878792,4.36950096332,317,Les,Riceys,Les Riceys,10,Aube,44,Grand Est
10318,RIGNY LA NONNEUSE,10290,RIGNY LA NONNEUSE,,48.4140043943,3.64891002916,318,,Rigny-la-Nonneuse,Rigny-la-Nonneuse,10,Aube,44,Grand Est
10319,RIGNY LE FERRON,10160,RIGNY LE FERRON,,48.2023856771,3.62729053332,319,,Rigny-le-Ferron,Rigny-le-Ferron,10,Aube,44,Grand Est
10320,RILLY STE SYRE,10280,RILLY STE SYRE,,48.4502459762,3.96773143034,320,,Rilly-Sainte-Syre,Rilly-Sainte-Syre,10,Aube,44,Grand Est
10321,LA RIVIERE DE CORPS,10440,LA RIVIERE DE CORPS,,48.2801548843,4.0140574686,321,La,Rivière-de-Corps,La Rivière-de-Corps,10,Aube,44,Grand Est
10323,ROMILLY SUR SEINE,10100,ROMILLY SUR SEINE,,48.5185939749,3.72154151549,323,,Romilly-sur-Seine,Romilly-sur-Seine,10,Aube,44,Grand Est
10324,RONCENAY,10320,RONCENAY,,48.1967315175,4.04486518985,324,,Roncenay,Roncenay,10,Aube,44,Grand Est
10325,ROSIERES PRES TROYES,10430,ROSIERES PRES TROYES,,48.2626897407,4.06345677209,325,,Rosières-près-Troyes,Rosières-près-Troyes,10,Aube,44,Grand Est
10325,ROSIERES PRES TROYES,10430,ROSIERES PRES TROYES,VIELAINES,48.2626897407,4.06345677209,325,,Rosières-près-Troyes,Rosières-près-Troyes,10,Aube,44,Grand Est
10326,ROSNAY L HOPITAL,10500,ROSNAY L HOPITAL,,48.4577724478,4.50578895388,326,,Rosnay-l'Hôpital,Rosnay-l'Hôpital,10,Aube,44,Grand Est
10327,LA ROTHIERE,10500,LA ROTHIERE,,48.3381400722,4.58555287029,327,La,Rothière,La Rothière,10,Aube,44,Grand Est
10328,ROUILLY SACEY,10220,ROUILLY SACEY,,48.3472856402,4.27393742571,328,,Rouilly-Sacey,Rouilly-Sacey,10,Aube,44,Grand Est
10329,ROUILLY ST LOUP,10800,ROUILLY ST LOUP,,48.2647207301,4.1494550348,329,,Rouilly-Saint-Loup,Rouilly-Saint-Loup,10,Aube,44,Grand Est
10329,ROUILLY ST LOUP,10800,ROUILLY ST LOUP,ROUILLEROT,48.2647207301,4.1494550348,329,,Rouilly-Saint-Loup,Rouilly-Saint-Loup,10,Aube,44,Grand Est
10330,ROUVRES LES VIGNES,10200,ROUVRES LES VIGNES,,48.2383642107,4.82708902094,330,,Rouvres-les-Vignes,Rouvres-les-Vignes,10,Aube,44,Grand Est
10331,RUMILLY LES VAUDES,10260,RUMILLY LES VAUDES,,48.1254003173,4.19515234884,331,,Rumilly-lès-Vaudes,Rumilly-lès-Vaudes,10,Aube,44,Grand Est
10332,RUVIGNY,10410,RUVIGNY,,48.272139831,4.18869827764,332,,Ruvigny,Ruvigny,10,Aube,44,Grand Est
10333,ST ANDRE LES VERGERS,10120,ST ANDRE LES VERGERS,,48.2782659904,4.04840305057,333,,Saint-André-les-Vergers,Saint-André-les-Vergers,10,Aube,44,Grand Est
10334,ST AUBIN,10400,ST AUBIN,,48.4828875357,3.55326963748,334,,Saint-Aubin,Saint-Aubin,10,Aube,44,Grand Est
10335,ST BENOIST SUR VANNE,10160,ST BENOIST SUR VANNE,,48.2352398291,3.66828750117,335,,Saint-Benoist-sur-Vanne,Saint-Benoist-sur-Vanne,10,Aube,44,Grand Est
10336,ST BENOIT SUR SEINE,10180,ST BENOIT SUR SEINE,,48.3800523103,4.05187885884,336,,Saint-Benoît-sur-Seine,Saint-Benoît-sur-Seine,10,Aube,44,Grand Est
10337,ST CHRISTOPHE DODINICOURT,10500,ST CHRISTOPHE DODINICOURT,,48.4331543584,4.47362272662,337,,Saint-Christophe-Dodinicourt,Saint-Christophe-Dodinicourt,10,Aube,44,Grand Est
10338,ST ETIENNE SOUS BARBUISE,10700,ST ETIENNE SOUS BARBUISE,,48.4925522857,4.11536192405,338,,Saint-Étienne-sous-Barbuise,Saint-Étienne-sous-Barbuise,10,Aube,44,Grand Est
10339,ST FLAVY,10350,ST FLAVY,,48.4064559956,3.77621221951,339,,Saint-Flavy,Saint-Flavy,10,Aube,44,Grand Est
10340,ST GERMAIN,10120,ST GERMAIN,,48.2535079309,4.01065245328,340,,Saint-Germain,Saint-Germain,10,Aube,44,Grand Est
10340,ST GERMAIN,10120,ST GERMAIN,CHEVILLELE,48.2535079309,4.01065245328,340,,Saint-Germain,Saint-Germain,10,Aube,44,Grand Est
10340,ST GERMAIN,10120,ST GERMAIN,LEPINE,48.2535079309,4.01065245328,340,,Saint-Germain,Saint-Germain,10,Aube,44,Grand Est
10341,ST HILAIRE SOUS ROMILLY,10100,ST HILAIRE SOUS ROMILLY,,48.4978643652,3.65717692243,341,,Saint-Hilaire-sous-Romilly,Saint-Hilaire-sous-Romilly,10,Aube,44,Grand Est
10342,ST JEAN DE BONNEVAL,10320,ST JEAN DE BONNEVAL,,48.1719801314,4.05020732222,342,,Saint-Jean-de-Bonneval,Saint-Jean-de-Bonneval,10,Aube,44,Grand Est
10343,ST JULIEN LES VILLAS,10800,ST JULIEN LES VILLAS,,48.2745172927,4.10297688057,343,,Saint-Julien-les-Villas,Saint-Julien-les-Villas,10,Aube,44,Grand Est
10344,ST LEGER PRES TROYES,10800,ST LEGER PRES TROYES,,48.2395381009,4.07306377461,344,,Saint-Léger-près-Troyes,Saint-Léger-près-Troyes,10,Aube,44,Grand Est
10345,ST LEGER SOUS BRIENNE,10500,ST LEGER SOUS BRIENNE,,48.4113235789,4.50613488827,345,,Saint-Léger-sous-Brienne,Saint-Léger-sous-Brienne,10,Aube,44,Grand Est
10346,ST LEGER SOUS MARGERIE,10330,ST LEGER SOUS MARGERIE,,48.530849094,4.48665807166,346,,Saint-Léger-sous-Margerie,Saint-Léger-sous-Margerie,10,Aube,44,Grand Est
10347,ST LOUP DE BUFFIGNY,10100,ST LOUP DE BUFFIGNY,,48.4446677907,3.63034199913,347,,Saint-Loup-de-Buffigny,Saint-Loup-de-Buffigny,10,Aube,44,Grand Est
10348,ST LUPIEN,10350,ST LUPIEN,,48.3644950452,3.69973385805,348,,Saint-Lupien,Saint-Lupien,10,Aube,44,Grand Est
10349,ST LYE,10180,ST LYE,,48.3442113534,3.9810222993,349,,Saint-Lyé,Saint-Lyé,10,Aube,44,Grand Est
10349,ST LYE,10180,ST LYE,BARBEREY AUX MOINES,48.3442113534,3.9810222993,349,,Saint-Lyé,Saint-Lyé,10,Aube,44,Grand Est
10350,ST MARDS EN OTHE,10160,ST MARDS EN OTHE,,48.1634046099,3.78937817149,350,,Saint-Mards-en-Othe,Saint-Mards-en-Othe,10,Aube,44,Grand Est
10351,ST MARTIN DE BOSSENAY,10100,ST MARTIN DE BOSSENAY,,48.4444963623,3.68962305417,351,,Saint-Martin-de-Bossenay,Saint-Martin-de-Bossenay,10,Aube,44,Grand Est
10352,STE MAURE,10150,STE MAURE,,48.3566905258,4.07198737935,352,,Sainte-Maure,Sainte-Maure,10,Aube,44,Grand Est
10352,STE MAURE,10150,STE MAURE,CULOISON,48.3566905258,4.07198737935,352,,Sainte-Maure,Sainte-Maure,10,Aube,44,Grand Est
10353,ST MESMIN,10280,ST MESMIN,,48.4369664082,3.89872863616,353,,Saint-Mesmin,Saint-Mesmin,10,Aube,44,Grand Est
10354,ST NABORD SUR AUBE,10700,ST NABORD SUR AUBE,,48.5128918959,4.20166023679,354,,Saint-Nabord-sur-Aube,Saint-Nabord-sur-Aube,10,Aube,44,Grand Est
10355,ST NICOLAS LA CHAPELLE,10400,ST NICOLAS LA CHAPELLE,,48.5229300856,3.47856484513,355,,Saint-Nicolas-la-Chapelle,Saint-Nicolas-la-Chapelle,10,Aube,44,Grand Est
10356,ST OULPH,10170,ST OULPH,,48.5266441925,3.87406836475,356,,Saint-Oulph,Saint-Oulph,10,Aube,44,Grand Est
10357,ST PARRES AUX TERTRES,10410,ST PARRES AUX TERTRES,,48.2913084954,4.13302448391,357,,Saint-Parres-aux-Tertres,Saint-Parres-aux-Tertres,10,Aube,44,Grand Est
10358,ST PARRES LES VAUDES,10260,ST PARRES LES VAUDES,,48.1734919803,4.21620941399,358,,Saint-Parres-lès-Vaudes,Saint-Parres-lès-Vaudes,10,Aube,44,Grand Est
10359,ST PHAL,10130,ST PHAL,,48.1142637321,4.00171688367,359,,Saint-Phal,Saint-Phal,10,Aube,44,Grand Est
10360,ST POUANGE,10120,ST POUANGE,,48.224064228,4.04074318461,360,,Saint-Pouange,Saint-Pouange,10,Aube,44,Grand Est
10361,ST REMY SOUS BARBUISE,10700,ST REMY SOUS BARBUISE,,48.4777025802,4.13036926093,361,,Saint-Remy-sous-Barbuise,Saint-Remy-sous-Barbuise,10,Aube,44,Grand Est
10362,STE SAVINE,10300,STE SAVINE,,48.2963408998,4.02333022061,362,,Sainte-Savine,Sainte-Savine,10,Aube,44,Grand Est
10363,ST THIBAULT,10800,ST THIBAULT,,48.2010515888,4.14696728723,363,,Saint-Thibault,Saint-Thibault,10,Aube,44,Grand Est
10364,ST USAGE,10360,ST USAGE,,48.0990942326,4.63420644531,364,,Saint-Usage,Saint-Usage,10,Aube,44,Grand Est
10365,SALON,10700,SALON,,48.6427112307,4.01781744354,365,,Salon,Salon,10,Aube,44,Grand Est
10366,SAULCY,10200,SAULCY,,48.2775769597,4.83542439731,366,,Saulcy,Saulcy,10,Aube,44,Grand Est
10367,LA SAULSOTTE,10400,LA SAULSOTTE,,48.5502087528,3.50552882992,367,La,Saulsotte,La Saulsotte,10,Aube,44,Grand Est
10368,SAVIERES,10600,SAVIERES,,48.4057771573,3.935288338,368,,Savières,Savières,10,Aube,44,Grand Est
10369,SEMOINE,10700,SEMOINE,,48.6735130864,4.09378069924,369,,Semoine,Semoine,10,Aube,44,Grand Est
10370,SOLIGNY LES ETANGS,10400,SOLIGNY LES ETANGS,,48.4021938714,3.51624678712,370,,Soligny-les-Étangs,Soligny-les-Étangs,10,Aube,44,Grand Est
10371,SOMMEVAL,10320,SOMMEVAL,,48.159126703,3.95489771109,371,,Sommeval,Sommeval,10,Aube,44,Grand Est
10372,SOULAINES DHUYS,10200,SOULAINES DHUYS,,48.3784420023,4.71797387888,372,,Soulaines-Dhuys,Soulaines-Dhuys,10,Aube,44,Grand Est
10373,SOULIGNY,10320,SOULIGNY,,48.2082516391,3.99414213688,373,,Souligny,Souligny,10,Aube,44,Grand Est
10374,SPOY,10200,SPOY,,48.2276371502,4.61406016756,374,,Spoy,Spoy,10,Aube,44,Grand Est
10375,THENNELIERES,10410,THENNELIERES,,48.2893448693,4.18526592573,375,,Thennelières,Thennelières,10,Aube,44,Grand Est
10376,THIEFFRAIN,10140,THIEFFRAIN,,48.2003070687,4.43842199513,376,,Thieffrain,Thieffrain,10,Aube,44,Grand Est
10377,THIL,10200,THIL,,48.3409418137,4.78858247296,377,,Thil,Thil,10,Aube,44,Grand Est
10378,THORS,10200,THORS,,48.3080677394,4.80493827415,378,,Thors,Thors,10,Aube,44,Grand Est
10379,TORCY LE GRAND,10700,TORCY LE GRAND,,48.5234326195,4.16799145735,379,,Torcy-le-Grand,Torcy-le-Grand,10,Aube,44,Grand Est
10380,TORCY LE PETIT,10700,TORCY LE PETIT,,48.5222887571,4.18694712376,380,,Torcy-le-Petit,Torcy-le-Petit,10,Aube,44,Grand Est
10381,TORVILLIERS,10440,TORVILLIERS,,48.2754934679,3.97217127639,381,,Torvilliers,Torvilliers,10,Aube,44,Grand Est
10382,TRAINEL,10400,TRAINEL,,48.4140944853,3.45656011239,382,,Traînel,Traînel,10,Aube,44,Grand Est
10383,TRANCAULT,10290,TRANCAULT,,48.3711938779,3.53292444285,383,,Trancault,Trancault,10,Aube,44,Grand Est
10384,TRANNES,10140,TRANNES,,48.3069705851,4.5990873423,384,,Trannes,Trannes,10,Aube,44,Grand Est
10386,TROUANS,10700,TROUANS,,48.6388005085,4.24987699201,386,,Trouans,Trouans,10,Aube,44,Grand Est
10386,TROUANS,10700,TROUANS,TROUAN LE GRAND,48.6388005085,4.24987699201,386,,Trouans,Trouans,10,Aube,44,Grand Est
10387,TROYES,10000,TROYES,,48.2967099637,4.07827967525,387,,Troyes,Troyes,10,Aube,44,Grand Est
10388,TURGY,10210,TURGY,,48.0300363367,4.05312999836,388,,Turgy,Turgy,10,Aube,44,Grand Est
10389,UNIENVILLE,10140,UNIENVILLE,,48.3173788807,4.54850083928,389,,Unienville,Unienville,10,Aube,44,Grand Est
10390,URVILLE,10200,URVILLE,,48.1668057798,4.66928684991,390,,Urville,Urville,10,Aube,44,Grand Est
10391,VAILLY,10150,VAILLY,,48.3723387031,4.12583904396,391,,Vailly,Vailly,10,Aube,44,Grand Est
10392,VALLANT ST GEORGES,10170,VALLANT ST GEORGES,,48.4606930046,3.88605241357,392,,Vallant-Saint-Georges,Vallant-Saint-Georges,10,Aube,44,Grand Est
10393,VALLENTIGNY,10500,VALLENTIGNY,,48.442454066,4.61489587199,393,,Vallentigny,Vallentigny,10,Aube,44,Grand Est
10394,VALLIERES,10210,VALLIERES,,47.9992814172,4.06184063227,394,,Vallières,Vallières,10,Aube,44,Grand Est
10395,VANLAY,10210,VANLAY,,48.0188179751,4.01889408699,395,,Vanlay,Vanlay,10,Aube,44,Grand Est
10396,VAUCHASSIS,10190,VAUCHASSIS,,48.2092064967,3.92631536187,396,,Vauchassis,Vauchassis,10,Aube,44,Grand Est
10397,VAUCHONVILLIERS,10140,VAUCHONVILLIERS,,48.266444897,4.53268851278,397,,Vauchonvilliers,Vauchonvilliers,10,Aube,44,Grand Est
10398,VAUCOGNE,10240,VAUCOGNE,,48.5333241035,4.34483013658,398,,Vaucogne,Vaucogne,10,Aube,44,Grand Est
10399,VAUDES,10260,VAUDES,,48.1755264671,4.1822235389,399,,Vaudes,Vaudes,10,Aube,44,Grand Est
10400,VAUPOISSON,10700,VAUPOISSON,,48.5007015249,4.216312839,400,,Vaupoisson,Vaupoisson,10,Aube,44,Grand Est
10401,VENDEUVRE SUR BARSE,10140,VENDEUVRE SUR BARSE,,48.2461425886,4.46090923858,401,,Vendeuvre-sur-Barse,Vendeuvre-sur-Barse,10,Aube,44,Grand Est
10402,LA VENDUE MIGNOT,10800,LA VENDUE MIGNOT,,48.1456208933,4.10314764777,402,La,Vendue-Mignot,La Vendue-Mignot,10,Aube,44,Grand Est
10403,VERNONVILLIERS,10200,VERNONVILLIERS,,48.3146477146,4.67938075644,403,,Vernonvilliers,Vernonvilliers,10,Aube,44,Grand Est
10404,VERPILLIERES SUR OURCE,10360,VERPILLIERES SUR OURCE,,48.0406701366,4.5860718335,404,,Verpillières-sur-Ource,Verpillières-sur-Ource,10,Aube,44,Grand Est
10405,VERRICOURT,10240,VERRICOURT,,48.4529660787,4.33421102008,405,,Verricourt,Verricourt,10,Aube,44,Grand Est
10406,VERRIERES,10390,VERRIERES,,48.2345711401,4.15425792921,406,,Verrières,Verrières,10,Aube,44,Grand Est
10408,VIAPRES LE PETIT,10380,VIAPRES LE PETIT,,48.5822319187,4.05387093659,408,,Viâpres-le-Petit,Viâpres-le-Petit,10,Aube,44,Grand Est
10409,VILLACERF,10600,VILLACERF,,48.407677761,4.00228117316,409,,Villacerf,Villacerf,10,Aube,44,Grand Est
10410,VILLADIN,10290,VILLADIN,,48.316863996,3.69033893209,410,,Villadin,Villadin,10,Aube,44,Grand Est
10411,LA VILLE AUX BOIS,10500,LA VILLE AUX BOIS,,48.4038925077,4.68731356148,411,La,Ville-aux-Bois,La Ville-aux-Bois,10,Aube,44,Grand Est
10412,VILLECHETIF,10410,VILLECHETIF,,48.3182253913,4.14937893854,412,,Villechétif,Villechétif,10,Aube,44,Grand Est
10412,VILLECHETIF,10410,VILLECHETIF,BELLEY,48.3182253913,4.14937893854,412,,Villechétif,Villechétif,10,Aube,44,Grand Est
10414,VILLELOUP,10350,VILLELOUP,,48.3546192059,3.86858896275,414,,Villeloup,Villeloup,10,Aube,44,Grand Est
10416,VILLEMEREUIL,10800,VILLEMEREUIL,,48.2017413737,4.08326296474,416,,Villemereuil,Villemereuil,10,Aube,44,Grand Est
10417,VILLEMOIRON EN OTHE,10160,VILLEMOIRON EN OTHE,,48.1996782401,3.79189787326,417,,Villemoiron-en-Othe,Villemoiron-en-Othe,10,Aube,44,Grand Est
10418,VILLEMORIEN,10110,VILLEMORIEN,,48.0798060719,4.2947230418,418,,Villemorien,Villemorien,10,Aube,44,Grand Est
10419,VILLEMOYENNE,10260,VILLEMOYENNE,,48.1878192236,4.24708790908,419,,Villemoyenne,Villemoyenne,10,Aube,44,Grand Est
10420,VILLENAUXE LA GRANDE,10370,VILLENAUXE LA GRANDE,,48.5945779935,3.5477753332,420,,Villenauxe-la-Grande,Villenauxe-la-Grande,10,Aube,44,Grand Est
10420,VILLENAUXE LA GRANDE,10370,VILLENAUXE LA GRANDE,DIVAL,48.5945779935,3.5477753332,420,,Villenauxe-la-Grande,Villenauxe-la-Grande,10,Aube,44,Grand Est
10421,LA VILLENEUVE AU CHATELOT,10400,LA VILLENEUVE AU CHATELOT,,48.5495270783,3.60717347149,421,La,Villeneuve-au-Châtelot,La Villeneuve-au-Châtelot,10,Aube,44,Grand Est
10422,VILLENEUVE AU CHEMIN,10130,VILLENEUVE AU CHEMIN,,48.089137016,3.83985245464,422,,Villeneuve-au-Chemin,Villeneuve-au-Chemin,10,Aube,44,Grand Est
10423,LA VILLENEUVE AU CHENE,10140,LA VILLENEUVE AU CHENE,,48.2487579972,4.39081684128,423,La,Villeneuve-au-Chêne,La Villeneuve-au-Chêne,10,Aube,44,Grand Est
10424,VILLERET,10330,VILLERET,,48.481503616,4.58651626543,424,,Villeret,Villeret,10,Aube,44,Grand Est
10425,VILLERY,10320,VILLERY,,48.1734982781,4.01734084562,425,,Villery,Villery,10,Aube,44,Grand Est
10426,VILLE SOUS LA FERTE,10310,VILLE SOUS LA FERTE,,48.1358669154,4.77205760299,426,,Ville-sous-la-Ferté,Ville-sous-la-Ferté,10,Aube,44,Grand Est
10426,VILLE SOUS LA FERTE,10310,VILLE SOUS LA FERTE,CLAIRVAUX SUR AUBE,48.1358669154,4.77205760299,426,,Ville-sous-la-Ferté,Ville-sous-la-Ferté,10,Aube,44,Grand Est
10427,VILLE SUR ARCE,10110,VILLE SUR ARCE,,48.1090387806,4.4449720869,427,,Ville-sur-Arce,Ville-sur-Arce,10,Aube,44,Grand Est
10428,VILLE SUR TERRE,10200,VILLE SUR TERRE,,48.3359047589,4.7348362857,428,,Ville-sur-Terre,Ville-sur-Terre,10,Aube,44,Grand Est
10429,VILLETTE SUR AUBE,10700,VILLETTE SUR AUBE,,48.5289053559,4.10868142527,429,,Villette-sur-Aube,Villette-sur-Aube,10,Aube,44,Grand Est
10430,VILLIERS HERBISSE,10700,VILLIERS HERBISSE,,48.6429563975,4.122661962,430,,Villiers-Herbisse,Villiers-Herbisse,10,Aube,44,Grand Est
10431,VILLIERS LE BOIS,10210,VILLIERS LE BOIS,,47.9552225736,4.19042875753,431,,Villiers-le-Bois,Villiers-le-Bois,10,Aube,44,Grand Est
10432,VILLIERS SOUS PRASLIN,10210,VILLIERS SOUS PRASLIN,,48.0606648453,4.25215912758,432,,Villiers-sous-Praslin,Villiers-sous-Praslin,10,Aube,44,Grand Est
10433,VILLY EN TRODES,10140,VILLY EN TRODES,,48.1969362165,4.38937663411,433,,Villy-en-Trodes,Villy-en-Trodes,10,Aube,44,Grand Est
10434,VILLY LE BOIS,10800,VILLY LE BOIS,,48.1675453288,4.08980540279,434,,Villy-le-Bois,Villy-le-Bois,10,Aube,44,Grand Est
10435,VILLY LE MARECHAL,10800,VILLY LE MARECHAL,,48.1889486496,4.07411625371,435,,Villy-le-Maréchal,Villy-le-Maréchal,10,Aube,44,Grand Est
10436,VINETS,10700,VINETS,,48.5452188766,4.22519162033,436,,Vinets,Vinets,10,Aube,44,Grand Est
10437,VIREY SOUS BAR,10260,VIREY SOUS BAR,,48.1344044788,4.30578605656,437,,Virey-sous-Bar,Virey-sous-Bar,10,Aube,44,Grand Est
10438,VITRY LE CROISE,10110,VITRY LE CROISE,,48.1434911643,4.57858150099,438,,Vitry-le-Croisé,Vitry-le-Croisé,10,Aube,44,Grand Est
10439,VIVIERS SUR ARTAUT,10110,VIVIERS SUR ARTAUT,,48.097855701,4.49784658697,439,,Viviers-sur-Artaut,Viviers-sur-Artaut,10,Aube,44,Grand Est
10440,VOIGNY,10200,VOIGNY,,48.2360883713,4.76532474234,440,,Voigny,Voigny,10,Aube,44,Grand Est
10441,VOSNON,10130,VOSNON,,48.1085969671,3.83539152599,441,,Vosnon,Vosnon,10,Aube,44,Grand Est
10442,VOUE,10150,VOUE,,48.459643518,4.12408661799,442,,Voué,Voué,10,Aube,44,Grand Est
10443,VOUGREY,10210,VOUGREY,,48.0875972332,4.23598090063,443,,Vougrey,Vougrey,10,Aube,44,Grand Est
10444,VULAINES,10160,VULAINES,,48.2404690972,3.62649011278,444,,Vulaines,Vulaines,10,Aube,44,Grand Est
10445,YEVRES LE PETIT,10500,YEVRES LE PETIT,,48.4793934797,4.49202256762,445,,Yèvres-le-Petit,Yèvres-le-Petit,10,Aube,44,Grand Est
11001,AIGUES VIVES,11800,AIGUES VIVES,,43.2351468241,2.5327825562,1,,Aigues-Vives,Aigues-Vives,11,Aude,76,Occitanie
11002,AIROUX,11320,AIROUX,,43.3676910136,1.87455000904,2,,Airoux,Airoux,11,Aude,76,Occitanie
11003,AJAC,11300,AJAC,,43.0449880503,2.13672463655,3,,Ajac,Ajac,11,Aude,76,Occitanie
11004,ALAIGNE,11240,ALAIGNE,,43.0978286628,2.09203368421,4,,Alaigne,Alaigne,11,Aude,76,Occitanie
11005,ALAIRAC,11290,ALAIRAC,,43.1820306523,2.2370439206,5,,Alairac,Alairac,11,Aude,76,Occitanie
11006,ALBAS,11360,ALBAS,,43.0056644881,2.7267548127,6,,Albas,Albas,11,Aude,76,Occitanie
11007,ALBIERES,11330,ALBIERES,,42.946718737,2.44639291505,7,,Albières,Albières,11,Aude,76,Occitanie
11008,ALET LES BAINS,11580,ALET LES BAINS,,42.9943909775,2.25844650226,8,,Alet-les-Bains,Alet-les-Bains,11,Aude,76,Occitanie
11009,ALZONNE,11170,ALZONNE,,43.2652998624,2.17459434048,9,,Alzonne,Alzonne,11,Aude,76,Occitanie
11010,ANTUGNAC,11190,ANTUGNAC,,42.9622347688,2.21863246536,10,,Antugnac,Antugnac,11,Aude,76,Occitanie
11011,ARAGON,11600,ARAGON,,43.298766565,2.30707240315,11,,Aragon,Aragon,11,Aude,76,Occitanie
11012,ARGELIERS,11120,ARGELIERS,,43.3110246072,2.91115452781,12,,Argeliers,Argeliers,11,Aude,76,Occitanie
11013,ARGENS MINERVOIS,11200,ARGENS MINERVOIS,,43.2458340699,2.77062357779,13,,Argens-Minervois,Argens-Minervois,11,Aude,76,Occitanie
11014,ARMISSAN,11110,ARMISSAN,,43.187641628,3.08927705991,14,,Armissan,Armissan,11,Aude,76,Occitanie
11015,ARQUES,11190,ARQUES,,42.9472177334,2.38719202465,15,,Arques,Arques,11,Aude,76,Occitanie
11016,ARQUETTES EN VAL,11220,ARQUETTES EN VAL,,43.1050377635,2.49669784552,16,,Arquettes-en-Val,Arquettes-en-Val,11,Aude,76,Occitanie
11017,ARTIGUES,11140,ARTIGUES,,42.7912861146,2.20717290807,17,,Artigues,Artigues,11,Aude,76,Occitanie
11018,ARZENS,11290,ARZENS,,43.2008547509,2.20537916173,18,,Arzens,Arzens,11,Aude,76,Occitanie
11019,AUNAT,11140,AUNAT,,42.7889932939,2.10048727427,19,,Aunat,Aunat,11,Aude,76,Occitanie
11020,AURIAC,11330,AURIAC,,42.9256351469,2.4945438405,20,,Auriac,Auriac,11,Aude,76,Occitanie
11021,AXAT,11140,AXAT,,42.7974645623,2.24059640574,21,,Axat,Axat,11,Aude,76,Occitanie
11022,AZILLE,11700,AZILLE,,43.2737058086,2.65746942689,22,,Azille,Azille,11,Aude,76,Occitanie
11023,BADENS,11800,BADENS,,43.2207146123,2.50470148133,23,,Badens,Badens,11,Aude,76,Occitanie
11024,BAGES,11100,BAGES,,43.1176416685,2.97718129024,24,,Bages,Bages,11,Aude,76,Occitanie
11025,BAGNOLES,11600,BAGNOLES,,43.2681435431,2.44889439842,25,,Bagnoles,Bagnoles,11,Aude,76,Occitanie
11026,BARAIGNE,11410,BARAIGNE,,43.3308772937,1.82285483659,26,,Baraigne,Baraigne,11,Aude,76,Occitanie
11027,BARBAIRA,11800,BARBAIRA,,43.1754326219,2.51471602722,27,,Barbaira,Barbaira,11,Aude,76,Occitanie
11028,BELCAIRE,11340,BELCAIRE,,42.831546255,1.94592570583,28,,Belcaire,Belcaire,11,Aude,76,Occitanie
11029,BELCASTEL ET BUC,11580,BELCASTEL ET BUC,,43.0278574306,2.34929567664,29,,Belcastel-et-Buc,Belcastel-et-Buc,11,Aude,76,Occitanie
11030,BELFLOU,11410,BELFLOU,,43.3189976269,1.798174168,30,,Belflou,Belflou,11,Aude,76,Occitanie
11031,BELFORT SUR REBENTY,11140,BELFORT SUR REBENTY,,42.8301323698,2.04993539204,31,,Belfort-sur-Rebenty,Belfort-sur-Rebenty,11,Aude,76,Occitanie
11032,BELLEGARDE DU RAZES,11240,BELLEGARDE DU RAZES,,43.1076849546,2.05088227839,32,,Bellegarde-du-Razès,Bellegarde-du-Razès,11,Aude,76,Occitanie
11033,BELPECH,11420,BELPECH,,43.2034214828,1.76339036009,33,,Belpech,Belpech,11,Aude,76,Occitanie
11034,BELVEZE DU RAZES,11240,BELVEZE DU RAZES,,43.1243426589,2.09178940091,34,,Belvèze-du-Razès,Belvèze-du-Razès,11,Aude,76,Occitanie
11035,BELVIANES ET CAVIRAC,11500,BELVIANES ET CAVIRAC,,42.8470909079,2.21251651397,35,,Belvianes-et-Cavirac,Belvianes-et-Cavirac,11,Aude,76,Occitanie
11036,BELVIS,11340,BELVIS,,42.8560597727,2.07533369425,36,,Belvis,Belvis,11,Aude,76,Occitanie
11037,BERRIAC,11000,BERRIAC,,43.21409862,2.40773271657,37,,Berriac,Berriac,11,Aude,76,Occitanie
11038,BESSEDE DE SAULT,11140,BESSEDE DE SAULT,,42.7846761103,2.13835524399,38,,Bessède-de-Sault,Bessède-de-Sault,11,Aude,76,Occitanie
11039,LA BEZOLE,11300,LA BEZOLE,,43.0291058072,2.09555791235,39,La,Bezole,La Bezole,11,Aude,76,Occitanie
11040,BIZANET,11200,BIZANET,,43.1482543689,2.86981441339,40,,Bizanet,Bizanet,11,Aude,76,Occitanie
11041,BIZE MINERVOIS,11120,BIZE MINERVOIS,,43.3290216831,2.87155465865,41,,Bize-Minervois,Bize-Minervois,11,Aude,76,Occitanie
11042,BLOMAC,11700,BLOMAC,,43.2134329065,2.58856166964,42,,Blomac,Blomac,11,Aude,76,Occitanie
11043,BOUILHONNAC,11800,BOUILHONNAC,,43.232345623,2.44410859417,43,,Bouilhonnac,Bouilhonnac,11,Aude,76,Occitanie
11044,BOUISSE,11330,BOUISSE,,42.9841940805,2.43400425918,44,,Bouisse,Bouisse,11,Aude,76,Occitanie
11045,BOURIEGE,11300,BOURIEGE,,42.9957636831,2.16146598721,45,,Bouriège,Bouriège,11,Aude,76,Occitanie
11046,BOURIGEOLE,11300,BOURIGEOLE,,42.9855167789,2.12392685607,46,,Bourigeole,Bourigeole,11,Aude,76,Occitanie
11047,LE BOUSQUET,11140,LE BOUSQUET,,42.7166535371,2.1756411124,47,Le,Bousquet,Le Bousquet,11,Aude,76,Occitanie
11048,BOUTENAC,11200,BOUTENAC,,43.1464757563,2.79815661148,48,,Boutenac,Boutenac,11,Aude,76,Occitanie
11049,BRAM,11150,BRAM,,43.2514206471,2.11029895207,49,,Bram,Bram,11,Aude,76,Occitanie
11051,BREZILHAC,11270,BREZILHAC,,43.1675996324,2.07535870975,51,,Brézilhac,Brézilhac,11,Aude,76,Occitanie
11052,BROUSSES ET VILLARET,11390,BROUSSES ET VILLARET,,43.3433075784,2.24334040085,52,,Brousses-et-Villaret,Brousses-et-Villaret,11,Aude,76,Occitanie
11053,BRUGAIROLLES,11300,BRUGAIROLLES,,43.1356849654,2.16049752521,53,,Brugairolles,Brugairolles,11,Aude,76,Occitanie
11054,LES BRUNELS,11400,LES BRUNELS,,43.4099338658,2.04826719599,54,Les,Brunels,Les Brunels,11,Aude,76,Occitanie
11055,BUGARACH,11190,BUGARACH,,42.8784709373,2.35059589731,55,,Bugarach,Bugarach,11,Aude,76,Occitanie
11056,CABRESPINE,11160,CABRESPINE,,43.3764538568,2.47186785708,56,,Cabrespine,Cabrespine,11,Aude,76,Occitanie
11057,CAHUZAC,11420,CAHUZAC,,43.1910457149,1.8547719586,57,,Cahuzac,Cahuzac,11,Aude,76,Occitanie
11058,CAILHAU,11240,CAILHAU,,43.1451890144,2.13010881163,58,,Cailhau,Cailhau,11,Aude,76,Occitanie
11059,CAILHAVEL,11240,CAILHAVEL,,43.1654349576,2.12414531475,59,,Cailhavel,Cailhavel,11,Aude,76,Occitanie
11060,CAILLA,11140,CAILLA,,42.810901034,2.19671298076,60,,Cailla,Cailla,11,Aude,76,Occitanie
11061,CAMBIEURE,11240,CAMBIEURE,,43.1266480601,2.12787543715,61,,Cambieure,Cambieure,11,Aude,76,Occitanie
11062,CAMPAGNA DE SAULT,11140,CAMPAGNA DE SAULT,,42.7583797479,2.04379622139,62,,Campagna-de-Sault,Campagna-de-Sault,11,Aude,76,Occitanie
11063,CAMPAGNE SUR AUDE,11260,CAMPAGNE SUR AUDE,,42.9155446824,2.20241626572,63,,Campagne-sur-Aude,Campagne-sur-Aude,11,Aude,76,Occitanie
11064,CAMPLONG D AUDE,11200,CAMPLONG D AUDE,,43.1336113618,2.65379340398,64,,Camplong-d'Aude,Camplong-d'Aude,11,Aude,76,Occitanie
11065,CAMPS SUR L AGLY,11190,CAMPS SUR L AGLY,,42.8638864709,2.41909357872,65,,Camps-sur-l'Agly,Camps-sur-l'Agly,11,Aude,76,Occitanie
11066,CAMURAC,11340,CAMURAC,,42.79912779,1.92368266374,66,,Camurac,Camurac,11,Aude,76,Occitanie
11067,CANET,11200,CANET,,43.2263270796,2.83123999016,67,,Canet,Canet,11,Aude,76,Occitanie
11068,CAPENDU,11700,CAPENDU,,43.1836102247,2.5538350475,68,,Capendu,Capendu,11,Aude,76,Occitanie
11069,CARCASSONNE,11000,CARCASSONNE,,43.2093798444,2.34398855385,69,,Carcassonne,Carcassonne,11,Aude,76,Occitanie
11069,CARCASSONNE,11000,CARCASSONNE,GREZES HERMINIS,43.2093798444,2.34398855385,69,,Carcassonne,Carcassonne,11,Aude,76,Occitanie
11069,CARCASSONNE,11000,CARCASSONNE,MAQUENS,43.2093798444,2.34398855385,69,,Carcassonne,Carcassonne,11,Aude,76,Occitanie
11069,CARCASSONNE,11000,CARCASSONNE,MONTLEGUN,43.2093798444,2.34398855385,69,,Carcassonne,Carcassonne,11,Aude,76,Occitanie
11069,CARCASSONNE,11000,CARCASSONNE,MONTREDON,43.2093798444,2.34398855385,69,,Carcassonne,Carcassonne,11,Aude,76,Occitanie
11069,CARCASSONNE,11000,CARCASSONNE,VILLALBE,43.2093798444,2.34398855385,69,,Carcassonne,Carcassonne,11,Aude,76,Occitanie
11070,CARLIPA,11170,CARLIPA,,43.306335097,2.12148270363,70,,Carlipa,Carlipa,11,Aude,76,Occitanie
11071,CASCASTEL DES CORBIERES,11360,CASCASTEL DES CORBIERES,,42.9806877582,2.74761489893,71,,Cascastel-des-Corbières,Cascastel-des-Corbières,11,Aude,76,Occitanie
11072,LA CASSAIGNE,11270,LA CASSAIGNE,,43.1959217452,1.98943286044,72,La,Cassaigne,La Cassaigne,11,Aude,76,Occitanie
11073,CASSAIGNES,11190,CASSAIGNES,,42.9479700119,2.29713238713,73,,Cassaignes,Cassaignes,11,Aude,76,Occitanie
11074,LES CASSES,11320,LES CASSES,,43.4266610754,1.86444537803,74,Les,Cassés,Les Cassés,11,Aude,76,Occitanie
11075,CASTANS,11160,CASTANS,,43.4141480376,2.48796143931,75,,Castans,Castans,11,Aude,76,Occitanie
11076,CASTELNAUDARY,11400,CASTELNAUDARY,,43.3230173159,1.96073752488,76,,Castelnaudary,Castelnaudary,11,Aude,76,Occitanie
11077,CASTELNAU D AUDE,11700,CASTELNAU D AUDE,,43.2334707961,2.67749002021,77,,Castelnau-d'Aude,Castelnau-d'Aude,11,Aude,76,Occitanie
11078,CASTELRENG,11300,CASTELRENG,,43.0207809156,2.12731730664,78,,Castelreng,Castelreng,11,Aude,76,Occitanie
11079,CAUDEBRONDE,11390,CAUDEBRONDE,,43.3873063189,2.30289301086,79,,Caudebronde,Caudebronde,11,Aude,76,Occitanie
11080,VAL DE LAMBRONNE,11230,VAL DE LAMBRONNE,CAUDEVAL,43.074926897,1.97483631739,80,,Val de Lambronne,Val de Lambronne,11,Aude,76,Occitanie
11080,VAL DE LAMBRONNE,11230,VAL DE LAMBRONNE,GUEYTES ET LABASTIDE,43.074926897,1.97483631739,80,,Val de Lambronne,Val de Lambronne,11,Aude,76,Occitanie
11081,CAUNES MINERVOIS,11160,CAUNES MINERVOIS,,43.3327963198,2.51635482478,81,,Caunes-Minervois,Caunes-Minervois,11,Aude,76,Occitanie
11082,CAUNETTE SUR LAUQUET,11250,CAUNETTE SUR LAUQUET,,43.027894163,2.42690541874,82,,Caunette-sur-Lauquet,Caunette-sur-Lauquet,11,Aude,76,Occitanie
11083,CAUNETTES EN VAL,11220,CAUNETTES EN VAL,,43.0641782017,2.56293845393,83,,Caunettes-en-Val,Caunettes-en-Val,11,Aude,76,Occitanie
11084,CAUX ET SAUZENS,11170,CAUX ET SAUZENS,,43.2258961133,2.25442069615,84,,Caux-et-Sauzens,Caux-et-Sauzens,11,Aude,76,Occitanie
11085,CAVANAC,11570,CAVANAC,,43.1689835434,2.33479005955,85,,Cavanac,Cavanac,11,Aude,76,Occitanie
11086,CAVES,11510,CAVES,,42.9370852126,2.96042838757,86,,Caves,Caves,11,Aude,76,Occitanie
11087,CAZALRENOUX,11270,CAZALRENOUX,,43.1886848888,1.94714608711,87,,Cazalrenoux,Cazalrenoux,11,Aude,76,Occitanie
11088,CAZILHAC,11570,CAZILHAC,,43.1781298027,2.35922152634,88,,Cazilhac,Cazilhac,11,Aude,76,Occitanie
11089,CENNE MONESTIES,11170,CENNE MONESTIES,,43.3239782132,2.12966126404,89,,Cenne-Monestiés,Cenne-Monestiés,11,Aude,76,Occitanie
11090,CEPIE,11300,CEPIE,,43.1079654901,2.23811930779,90,,Cépie,Cépie,11,Aude,76,Occitanie
11091,CHALABRE,11230,CHALABRE,,42.9817651679,2.01049024009,91,,Chalabre,Chalabre,11,Aude,76,Occitanie
11092,CITOU,11160,CITOU,,43.3755029489,2.53256904263,92,,Citou,Citou,11,Aude,76,Occitanie
11093,LE CLAT,11140,LE CLAT,,42.7871660993,2.17404309802,93,Le,Clat,Le Clat,11,Aude,76,Occitanie
11094,CLERMONT SUR LAUQUET,11250,CLERMONT SUR LAUQUET,,43.0464846976,2.4293167495,94,,Clermont-sur-Lauquet,Clermont-sur-Lauquet,11,Aude,76,Occitanie
11095,COMIGNE,11700,COMIGNE,,43.1656693314,2.58551535137,95,,Comigne,Comigne,11,Aude,76,Occitanie
11096,COMUS,11340,COMUS,,42.8284437871,1.88864024045,96,,Comus,Comus,11,Aude,76,Occitanie
11097,CONILHAC DE LA MONTAGNE,11190,CONILHAC DE LA MONTAGNE,,42.9790867705,2.20524985286,97,,Conilhac-de-la-Montagne,Conilhac-de-la-Montagne,11,Aude,76,Occitanie
11098,CONILHAC CORBIERES,11200,CONILHAC CORBIERES,,43.1847654132,2.71572755182,98,,Conilhac-Corbières,Conilhac-Corbières,11,Aude,76,Occitanie
11099,CONQUES SUR ORBIEL,11600,CONQUES SUR ORBIEL,,43.2842108379,2.38445720467,99,,Conques-sur-Orbiel,Conques-sur-Orbiel,11,Aude,76,Occitanie
11100,CORBIERES,11230,CORBIERES,,43.0452528312,1.99980584945,100,,Corbières,Corbières,11,Aude,76,Occitanie
11101,COUDONS,11500,COUDONS,,42.8636835943,2.11985831912,101,,Coudons,Coudons,11,Aude,76,Occitanie
11102,COUFFOULENS,11250,COUFFOULENS,,43.1550733435,2.30512897888,102,,Couffoulens,Couffoulens,11,Aude,76,Occitanie
11103,COUIZA,11190,COUIZA,,42.9352199297,2.27047605296,103,,Couiza,Couiza,11,Aude,76,Occitanie
11104,COUNOZOULS,11140,COUNOZOULS,,42.701706795,2.23007242592,104,,Counozouls,Counozouls,11,Aude,76,Occitanie
11105,COURNANEL,11300,COURNANEL,,43.0297699245,2.23528266508,105,,Cournanel,Cournanel,11,Aude,76,Occitanie
11106,COURSAN,11110,COURSAN,,43.247330951,3.05926886129,106,,Coursan,Coursan,11,Aude,76,Occitanie
11107,COURTAULY,11230,COURTAULY,,43.0409426208,2.04467462056,107,,Courtauly,Courtauly,11,Aude,76,Occitanie
11108,LA COURTETE,11240,LA COURTETE,,43.1421990579,2.03235561707,108,La,Courtète,La Courtète,11,Aude,76,Occitanie
11109,COUSTAUSSA,11190,COUSTAUSSA,,42.9367588935,2.28608186295,109,,Coustaussa,Coustaussa,11,Aude,76,Occitanie
11110,COUSTOUGE,11220,COUSTOUGE,,43.0570093399,2.74399307866,110,,Coustouge,Coustouge,11,Aude,76,Occitanie
11111,CRUSCADES,11200,CRUSCADES,,43.200032094,2.81675386265,111,,Cruscades,Cruscades,11,Aude,76,Occitanie
11112,CUBIERES SUR CINOBLE,11190,CUBIERES SUR CINOBLE,,42.8630454111,2.46622412753,112,,Cubières-sur-Cinoble,Cubières-sur-Cinoble,11,Aude,76,Occitanie
11113,CUCUGNAN,11350,CUCUGNAN,,42.8512642371,2.60941588614,113,,Cucugnan,Cucugnan,11,Aude,76,Occitanie
11114,CUMIES,11410,CUMIES,,43.2996308196,1.83222991092,114,,Cumiès,Cumiès,11,Aude,76,Occitanie
11115,CUXAC CABARDES,11390,CUXAC CABARDES,,43.3915075028,2.2785219316,115,,Cuxac-Cabardès,Cuxac-Cabardès,11,Aude,76,Occitanie
11116,CUXAC D AUDE,11590,CUXAC D AUDE,,43.2578013318,3.00547549948,116,,Cuxac-d'Aude,Cuxac-d'Aude,11,Aude,76,Occitanie
11117,DAVEJEAN,11330,DAVEJEAN,,42.9586371128,2.60630831881,117,,Davejean,Davejean,11,Aude,76,Occitanie
11118,DERNACUEILLETTE,11330,DERNACUEILLETTE,,42.9287190285,2.59769588441,118,,Dernacueillette,Dernacueillette,11,Aude,76,Occitanie
11119,LA DIGNE D AMONT,11300,LA DIGNE D AMONT,,43.0391924198,2.16079139515,119,La,Digne-d'Amont,La Digne-d'Amont,11,Aude,76,Occitanie
11120,LA DIGNE D AVAL,11300,LA DIGNE D AVAL,,43.045934192,2.17910836448,120,La,Digne-d'Aval,La Digne-d'Aval,11,Aude,76,Occitanie
11121,DONAZAC,11240,DONAZAC,,43.0792117133,2.12081734346,121,,Donazac,Donazac,11,Aude,76,Occitanie
11122,DOUZENS,11700,DOUZENS,,43.1792686737,2.61186266424,122,,Douzens,Douzens,11,Aude,76,Occitanie
11123,DUILHAC SOUS PEYREPERTUSE,11350,DUILHAC SOUS PEYREPERTUSE,,42.8656597,2.56758604959,123,,Duilhac-sous-Peyrepertuse,Duilhac-sous-Peyrepertuse,11,Aude,76,Occitanie
11124,DURBAN CORBIERES,11360,DURBAN CORBIERES,,42.9976164909,2.81275011537,124,,Durban-Corbières,Durban-Corbières,11,Aude,76,Occitanie
11125,EMBRES ET CASTELMAURE,11360,EMBRES ET CASTELMAURE,,42.9295753013,2.81822778929,125,,Embres-et-Castelmaure,Embres-et-Castelmaure,11,Aude,76,Occitanie
11126,ESCALES,11200,ESCALES,,43.2228544112,2.70834893328,126,,Escales,Escales,11,Aude,76,Occitanie
11127,ESCOULOUBRE,11140,ESCOULOUBRE,,42.7315992556,2.1323327803,127,,Escouloubre,Escouloubre,11,Aude,76,Occitanie
11128,ESCUEILLENS ET ST JUST,11240,ESCUEILLENS ET ST JUST,,43.100423517,2.02148370571,128,,Escueillens-et-Saint-Just-de-Bélengard,Escueillens-et-Saint-Just-de-Bélengard,11,Aude,76,Occitanie
11128,ESCUEILLENS ET ST JUST,11240,ESCUEILLENS ET ST JUST,ST JUST DE BELENGARD,43.100423517,2.02148370571,128,,Escueillens-et-Saint-Just-de-Bélengard,Escueillens-et-Saint-Just-de-Bélengard,11,Aude,76,Occitanie
11129,ESPERAZA,11260,ESPERAZA,,42.9316895316,2.22104640259,129,,Espéraza,Espéraza,11,Aude,76,Occitanie
11130,ESPEZEL,11340,ESPEZEL,,42.8429058129,2.02561550138,130,,Espezel,Espezel,11,Aude,76,Occitanie
11131,FA,11260,FA,,42.9276860891,2.17956693905,131,,Fa,Fa,11,Aude,76,Occitanie
11132,FABREZAN,11200,FABREZAN,,43.1259965939,2.70495303156,132,,Fabrezan,Fabrezan,11,Aude,76,Occitanie
11133,FAJAC EN VAL,11220,FAJAC EN VAL,,43.1210342703,2.46311809989,133,,Fajac-en-Val,Fajac-en-Val,11,Aude,76,Occitanie
11134,FAJAC LA RELENQUE,11410,FAJAC LA RELENQUE,,43.2824532203,1.72705539612,134,,Fajac-la-Relenque,Fajac-la-Relenque,11,Aude,76,Occitanie
11135,LA FAJOLLE,11140,LA FAJOLLE,,42.7525421686,1.96514650646,135,La,Fajolle,La Fajolle,11,Aude,76,Occitanie
11136,FANJEAUX,11270,FANJEAUX,,43.1870163521,2.03666099746,136,,Fanjeaux,Fanjeaux,11,Aude,76,Occitanie
11137,FELINES TERMENES,11330,FELINES TERMENES,,42.984590933,2.61454462855,137,,Félines-Termenès,Félines-Termenès,11,Aude,76,Occitanie
11138,FENDEILLE,11400,FENDEILLE,,43.2708066932,1.94828248478,138,,Fendeille,Fendeille,11,Aude,76,Occitanie
11139,FENOUILLET DU RAZES,11240,FENOUILLET DU RAZES,,43.1559454776,2.02984840412,139,,Fenouillet-du-Razès,Fenouillet-du-Razès,11,Aude,76,Occitanie
11140,FERRALS LES CORBIERES,11200,FERRALS LES CORBIERES,,43.1431544039,2.7387140866,140,,Ferrals-les-Corbières,Ferrals-les-Corbières,11,Aude,76,Occitanie
11141,FERRAN,11240,FERRAN,,43.1530485554,2.09024312493,141,,Ferran,Ferran,11,Aude,76,Occitanie
11142,FESTES ET ST ANDRE,11300,FESTES ET ST ANDRE,,42.9618144716,2.11582079753,142,,Festes-et-Saint-André,Festes-et-Saint-André,11,Aude,76,Occitanie
11143,FEUILLA,11510,FEUILLA,,42.9291375888,2.90138923544,143,,Feuilla,Feuilla,11,Aude,76,Occitanie
11144,FITOU,11510,FITOU,,42.8959362575,2.96671997397,144,,Fitou,Fitou,11,Aude,76,Occitanie
11144,FITOU,11510,FITOU,CABANES DE FITOU,42.8959362575,2.96671997397,144,,Fitou,Fitou,11,Aude,76,Occitanie
11145,FLEURY,11560,FLEURY D AUDE,,43.214412802,3.17666583157,145,,Fleury,Fleury,11,Aude,76,Occitanie
11145,FLEURY,11560,FLEURY D AUDE,ST PIERRE LA MER,43.214412802,3.17666583157,145,,Fleury,Fleury,11,Aude,76,Occitanie
11146,FLOURE,11800,FLOURE,,43.1786208741,2.48513485536,146,,Floure,Floure,11,Aude,76,Occitanie
11147,FONTANES DE SAULT,11140,FONTANES DE SAULT,,42.7683692415,2.08206759306,147,,Fontanès-de-Sault,Fontanès-de-Sault,11,Aude,76,Occitanie
11148,FONTCOUVERTE,11700,FONTCOUVERTE,,43.1679703026,2.68609403746,148,,Fontcouverte,Fontcouverte,11,Aude,76,Occitanie
11149,FONTERS DU RAZES,11400,FONTERS DU RAZES,,43.2377047151,1.92343159857,149,,Fonters-du-Razès,Fonters-du-Razès,11,Aude,76,Occitanie
11150,FONTIERS CABARDES,11390,FONTIERS CABARDES,,43.3746615673,2.25320474783,150,,Fontiers-Cabardès,Fontiers-Cabardès,11,Aude,76,Occitanie
11151,FONTIES D AUDE,11800,FONTIES D AUDE,,43.1874440297,2.45756099074,151,,Fontiès-d'Aude,Fontiès-d'Aude,11,Aude,76,Occitanie
11152,FONTJONCOUSE,11360,FONTJONCOUSE,,43.0447254649,2.79293722382,152,,Fontjoncouse,Fontjoncouse,11,Aude,76,Occitanie
11153,LA FORCE,11270,LA FORCE,,43.1975835427,2.08437892262,153,La,Force,La Force,11,Aude,76,Occitanie
11154,FOURNES CABARDES,11600,FOURNES CABARDES,,43.3569463468,2.40965154801,154,,Fournes-Cabardès,Fournes-Cabardès,11,Aude,76,Occitanie
11155,FOURTOU,11190,FOURTOU,,42.90966081,2.44256346248,155,,Fourtou,Fourtou,11,Aude,76,Occitanie
11156,FRAISSE CABARDES,11600,FRAISSE CABARDES,,43.3295843066,2.27147589687,156,,Fraisse-Cabardès,Fraisse-Cabardès,11,Aude,76,Occitanie
11157,FRAISSE DES CORBIERES,11360,FRAISSE DES CORBIERES,,42.9614186239,2.87149437178,157,,Fraissé-des-Corbières,Fraissé-des-Corbières,11,Aude,76,Occitanie
11158,GAJA ET VILLEDIEU,11300,GAJA ET VILLEDIEU,,43.079734068,2.18579685129,158,,Gaja-et-Villedieu,Gaja-et-Villedieu,11,Aude,76,Occitanie
11159,GAJA LA SELVE,11270,GAJA LA SELVE,,43.2002672406,1.88364731098,159,,Gaja-la-Selve,Gaja-la-Selve,11,Aude,76,Occitanie
11160,GALINAGUES,11140,GALINAGUES,,42.8064138721,2.04766915652,160,,Galinagues,Galinagues,11,Aude,76,Occitanie
11161,GARDIE,11250,GARDIE,,43.0747878075,2.2989044146,161,,Gardie,Gardie,11,Aude,76,Occitanie
11162,GENERVILLE,11270,GENERVILLE,,43.2104473587,1.931378621,162,,Generville,Generville,11,Aude,76,Occitanie
11163,GINCLA,11140,GINCLA,,42.7642420371,2.33214858941,163,,Gincla,Gincla,11,Aude,76,Occitanie
11164,GINESTAS,11120,GINESTAS,,43.2758588523,2.87717593442,164,,Ginestas,Ginestas,11,Aude,76,Occitanie
11165,GINOLES,11500,GINOLES,,42.864787536,2.15713578249,165,,Ginoles,Ginoles,11,Aude,76,Occitanie
11166,GOURVIEILLE,11410,GOURVIEILLE,,43.3362096434,1.78856389792,166,,Gourvieille,Gourvieille,11,Aude,76,Occitanie
11167,GRAMAZIE,11240,GRAMAZIE,,43.1373081633,2.09746996303,167,,Gramazie,Gramazie,11,Aude,76,Occitanie
11168,GRANES,11500,GRANES,,42.89537535,2.25328133982,168,,Granès,Granès,11,Aude,76,Occitanie
11169,GREFFEIL,11250,GREFFEIL,,43.0745101503,2.39378914841,169,,Greffeil,Greffeil,11,Aude,76,Occitanie
11170,GRUISSAN,11430,GRUISSAN,,43.1040163547,3.08229513652,170,,Gruissan,Gruissan,11,Aude,76,Occitanie
11170,GRUISSAN,11430,GRUISSAN,GRUISSAN PLAGE,43.1040163547,3.08229513652,170,,Gruissan,Gruissan,11,Aude,76,Occitanie
11172,HOMPS,11200,HOMPS,,43.2673621854,2.72098282202,172,,Homps,Homps,11,Aude,76,Occitanie
11173,HOUNOUX,11240,HOUNOUX,,43.1333954781,2.00312155493,173,,Hounoux,Hounoux,11,Aude,76,Occitanie
11174,LES ILHES,11380,LES ILHES,,43.3583194485,2.37506698668,174,Les,Ilhes,Les Ilhes,11,Aude,76,Occitanie
11175,ISSEL,11400,ISSEL,,43.3675679616,1.99804792983,175,,Issel,Issel,11,Aude,76,Occitanie
11176,JONQUIERES,11220,JONQUIERES,,43.0394758457,2.72351814266,176,,Jonquières,Jonquières,11,Aude,76,Occitanie
11177,JOUCOU,11140,JOUCOU,,42.82469022,2.08483548472,177,,Joucou,Joucou,11,Aude,76,Occitanie
11178,LABASTIDE D ANJOU,11320,LABASTIDE D ANJOU,,43.3468106616,1.85317961566,178,,Labastide-d'Anjou,Labastide-d'Anjou,11,Aude,76,Occitanie
11179,LABASTIDE EN VAL,11220,LABASTIDE EN VAL,,43.0648889852,2.47120935323,179,,Labastide-en-Val,Labastide-en-Val,11,Aude,76,Occitanie
11180,LABASTIDE ESPARBAIRENQUE,11380,LABASTIDE ESPARBAIRENQUE,,43.3934090766,2.40300635339,180,,Labastide-Esparbairenque,Labastide-Esparbairenque,11,Aude,76,Occitanie
11181,LABECEDE LAURAGAIS,11400,LABECEDE LAURAGAIS,,43.3964166976,2.01100168074,181,,Labécède-Lauragais,Labécède-Lauragais,11,Aude,76,Occitanie
11182,LACOMBE,11310,LACOMBE,,43.4060884767,2.24016063772,182,,Lacombe,Lacombe,11,Aude,76,Occitanie
11183,LADERN SUR LAUQUET,11250,LADERN SUR LAUQUET,,43.1012521113,2.3863195178,183,,Ladern-sur-Lauquet,Ladern-sur-Lauquet,11,Aude,76,Occitanie
11184,LAFAGE,11420,LAFAGE,,43.1655958394,1.86743843652,184,,Lafage,Lafage,11,Aude,76,Occitanie
11185,LAGRASSE,11220,LAGRASSE,,43.0957230349,2.60436046295,185,,Lagrasse,Lagrasse,11,Aude,76,Occitanie
11186,LAIRIERE,11330,LAIRIERE,,43.0231109524,2.48132587257,186,,Lairière,Lairière,11,Aude,76,Occitanie
11187,LANET,11330,LANET,,42.9662795152,2.48612053622,187,,Lanet,Lanet,11,Aude,76,Occitanie
11188,LA PALME,11480,LA PALME,,42.9660377916,2.99265326968,188,La,Palme,La Palme,11,Aude,76,Occitanie
11189,LAPRADE,11390,LAPRADE,,43.440478585,2.25257707156,189,,Laprade,Laprade,11,Aude,76,Occitanie
11190,LA REDORTE,11700,LA REDORTE,,43.2477384944,2.64987073042,190,La,Redorte,La Redorte,11,Aude,76,Occitanie
11191,LAROQUE DE FA,11330,LAROQUE DE FA,,42.9503532669,2.55912967767,191,,Laroque-de-Fa,Laroque-de-Fa,11,Aude,76,Occitanie
11192,LASBORDES,11400,LASBORDES,,43.2996016528,2.06333742676,192,,Lasbordes,Lasbordes,11,Aude,76,Occitanie
11193,LASSERRE DE PROUILLE,11270,LASSERRE DE PROUILLE,,43.1807085123,2.08506229435,193,,Lasserre-de-Prouille,Lasserre-de-Prouille,11,Aude,76,Occitanie
11194,LASTOURS,11600,LASTOURS,,43.326249809,2.37863845502,194,,Lastours,Lastours,11,Aude,76,Occitanie
11195,LAURABUC,11400,LAURABUC,,43.2605975976,1.99494391094,195,,Laurabuc,Laurabuc,11,Aude,76,Occitanie
11196,LAURAC,11270,LAURAC,,43.2303422976,1.9693292407,196,,Laurac,Laurac,11,Aude,76,Occitanie
11197,LAURAGUEL,11300,LAURAGUEL,,43.0965194461,2.17010023085,197,,Lauraguel,Lauraguel,11,Aude,76,Occitanie
11198,LAURE MINERVOIS,11800,LAURE MINERVOIS,,43.271055447,2.50991120089,198,,Laure-Minervois,Laure-Minervois,11,Aude,76,Occitanie
11199,LAVALETTE,11290,LAVALETTE,,43.1922487019,2.27058563177,199,,Lavalette,Lavalette,11,Aude,76,Occitanie
11200,LESPINASSIERE,11160,LESPINASSIERE,,43.4088985719,2.54776717601,200,,Lespinassière,Lespinassière,11,Aude,76,Occitanie
11201,LEUC,11250,LEUC,,43.1412292044,2.33961454504,201,,Leuc,Leuc,11,Aude,76,Occitanie
11202,LEUCATE,11370,LEUCATE,,42.8993650672,3.02676038573,202,,Leucate,Leucate,11,Aude,76,Occitanie
11202,LEUCATE,11370,LEUCATE,LA FRANQUI,42.8993650672,3.02676038573,202,,Leucate,Leucate,11,Aude,76,Occitanie
11202,LEUCATE,11370,LEUCATE,PORT LEUCATE,42.8993650672,3.02676038573,202,,Leucate,Leucate,11,Aude,76,Occitanie
11203,LEZIGNAN CORBIERES,11200,LEZIGNAN CORBIERES,,43.2036730565,2.76215028732,203,,Lézignan-Corbières,Lézignan-Corbières,11,Aude,76,Occitanie
11204,LIGNAIROLLES,11240,LIGNAIROLLES,,43.0945401019,2.00127253702,204,,Lignairolles,Lignairolles,11,Aude,76,Occitanie
11205,LIMOUSIS,11600,LIMOUSIS,,43.3274801411,2.40460608517,205,,Limousis,Limousis,11,Aude,76,Occitanie
11206,LIMOUX,11300,LIMOUX,,43.0499293279,2.23958811407,206,,Limoux,Limoux,11,Aude,76,Occitanie
11207,LOUPIA,11300,LOUPIA,,43.0619787093,2.12549217524,207,,Loupia,Loupia,11,Aude,76,Occitanie
11208,LA LOUVIERE LAURAGAIS,11410,LA LOUVIERE LAURAGAIS,,43.2673283014,1.75078785936,208,La,Louvière-Lauragais,La Louvière-Lauragais,11,Aude,76,Occitanie
11209,LUC SUR AUDE,11190,LUC SUR AUDE,,42.9640628627,2.28123974033,209,,Luc-sur-Aude,Luc-sur-Aude,11,Aude,76,Occitanie
11210,LUC SUR ORBIEU,11200,LUC SUR ORBIEU,,43.1757442805,2.78804456863,210,,Luc-sur-Orbieu,Luc-sur-Orbieu,11,Aude,76,Occitanie
11211,MAGRIE,11300,MAGRIE,,43.0249180261,2.20415031047,211,,Magrie,Magrie,11,Aude,76,Occitanie
11212,MAILHAC,11120,MAILHAC,,43.3076774057,2.83059491685,212,,Mailhac,Mailhac,11,Aude,76,Occitanie
11213,MAISONS,11330,MAISONS,,42.9292639263,2.64024224391,213,,Maisons,Maisons,11,Aude,76,Occitanie
11214,MALRAS,11300,MALRAS,,43.0609311842,2.16809253539,214,,Malras,Malras,11,Aude,76,Occitanie
11215,MALVES EN MINERVOIS,11600,MALVES EN MINERVOIS,,43.250190518,2.45343864014,215,,Malves-en-Minervois,Malves-en-Minervois,11,Aude,76,Occitanie
11216,MALVIES,11300,MALVIES,,43.1174399011,2.18395387404,216,,Malviès,Malviès,11,Aude,76,Occitanie
11217,MARCORIGNAN,11120,MARCORIGNAN,,43.2247080876,2.91473054807,217,,Marcorignan,Marcorignan,11,Aude,76,Occitanie
11218,MARQUEIN,11410,MARQUEIN,,43.2994946211,1.72364772647,218,,Marquein,Marquein,11,Aude,76,Occitanie
11219,MARSA,11140,MARSA,,42.8197040579,2.1393561286,219,,Marsa,Marsa,11,Aude,76,Occitanie
11220,MARSEILLETTE,11800,MARSEILLETTE,,43.2106331935,2.54884252568,220,,Marseillette,Marseillette,11,Aude,76,Occitanie
11221,LES MARTYS,11390,LES MARTYS,,43.4219858305,2.31009266044,221,Les,Martys,Les Martys,11,Aude,76,Occitanie
11222,MAS CABARDES,11380,MAS CABARDES,,43.3995255593,2.35766754646,222,,Mas-Cabardès,Mas-Cabardès,11,Aude,76,Occitanie
11223,MAS DES COURS,11570,MAS DES COURS,,43.1316087577,2.42671683067,223,,Mas-des-Cours,Mas-des-Cours,11,Aude,76,Occitanie
11224,MASSAC,11330,MASSAC,,42.9160146126,2.55792629782,224,,Massac,Massac,11,Aude,76,Occitanie
11225,MAS SAINTES PUELLES,11400,MAS SAINTES PUELLES,,43.314008864,1.87769752208,225,,Mas-Saintes-Puelles,Mas-Saintes-Puelles,11,Aude,76,Occitanie
11226,MAYREVILLE,11420,MAYREVILLE,,43.2297104101,1.84758314766,226,,Mayreville,Mayreville,11,Aude,76,Occitanie
11227,MAYRONNES,11220,MAYRONNES,,43.0462604281,2.52945819038,227,,Mayronnes,Mayronnes,11,Aude,76,Occitanie
11228,MAZEROLLES DU RAZES,11240,MAZEROLLES DU RAZES,,43.1364410454,2.06601390083,228,,Mazerolles-du-Razès,Mazerolles-du-Razès,11,Aude,76,Occitanie
11229,MAZUBY,11140,MAZUBY,,42.7876134652,2.03335100934,229,,Mazuby,Mazuby,11,Aude,76,Occitanie
11230,MERIAL,11140,MERIAL,,42.7713628009,1.96356419202,230,,Mérial,Mérial,11,Aude,76,Occitanie
11231,MEZERVILLE,11410,MEZERVILLE,,43.2510059536,1.78428235297,231,,Mézerville,Mézerville,11,Aude,76,Occitanie
11232,MIRAVAL CABARDES,11380,MIRAVAL CABARDES,,43.3813815441,2.34060793315,232,,Miraval-Cabardès,Miraval-Cabardès,11,Aude,76,Occitanie
11233,MIREPEISSET,11120,MIREPEISSET,,43.2844349747,2.89700023741,233,,Mirepeisset,Mirepeisset,11,Aude,76,Occitanie
11234,MIREVAL LAURAGAIS,11400,MIREVAL LAURAGAIS,,43.2640320173,1.96819488021,234,,Mireval-Lauragais,Mireval-Lauragais,11,Aude,76,Occitanie
11235,MISSEGRE,11580,MISSEGRE,,43.0021750779,2.37277504963,235,,Missègre,Missègre,11,Aude,76,Occitanie
11236,MOLANDIER,11420,MOLANDIER,,43.2516930483,1.72403903685,236,,Molandier,Molandier,11,Aude,76,Occitanie
11238,MOLLEVILLE,11410,MOLLEVILLE,,43.3122283518,1.83599327392,238,,Molleville,Molleville,11,Aude,76,Occitanie
11239,MONTAURIOL,11410,MONTAURIOL,,43.2752849467,1.83225943159,239,,Montauriol,Montauriol,11,Aude,76,Occitanie
11240,MONTAZELS,11190,MONTAZELS,,42.9517592122,2.24298643273,240,,Montazels,Montazels,11,Aude,76,Occitanie
11241,MONTBRUN DES CORBIERES,11700,MONTBRUN DES CORBIERES,,43.2010816161,2.6810058702,241,,Montbrun-des-Corbières,Montbrun-des-Corbières,11,Aude,76,Occitanie
11242,MONTCLAR,11250,MONTCLAR,,43.1369169209,2.24273890747,242,,Montclar,Montclar,11,Aude,76,Occitanie
11243,MONTFERRAND,11320,MONTFERRAND,,43.368751028,1.82823175156,243,,Montferrand,Montferrand,11,Aude,76,Occitanie
11244,MONTFORT SUR BOULZANE,11140,MONTFORT SUR BOULZANE,,42.7299809586,2.3081472407,244,,Montfort-sur-Boulzane,Montfort-sur-Boulzane,11,Aude,76,Occitanie
11245,MONTGAILLARD,11330,MONTGAILLARD,,42.9046014463,2.62879585851,245,,Montgaillard,Montgaillard,11,Aude,76,Occitanie
11246,MONTGRADAIL,11240,MONTGRADAIL,,43.1233667557,2.03299800063,246,,Montgradail,Montgradail,11,Aude,76,Occitanie
11247,MONTHAUT,11240,MONTHAUT,,43.0818910359,2.05925585786,247,,Monthaut,Monthaut,11,Aude,76,Occitanie
11248,MONTIRAT,11800,MONTIRAT,,43.168735923,2.4320979662,248,,Montirat,Montirat,11,Aude,76,Occitanie
11249,MONTJARDIN,11230,MONTJARDIN,,42.9795968014,2.05064516411,249,,Montjardin,Montjardin,11,Aude,76,Occitanie
11250,MONTJOI,11330,MONTJOI,,42.9961480629,2.47905879175,250,,Montjoi,Montjoi,11,Aude,76,Occitanie
11251,MONTLAUR,11220,MONTLAUR,,43.1285553787,2.56525134026,251,,Montlaur,Montlaur,11,Aude,76,Occitanie
11252,MONTMAUR,11320,MONTMAUR,,43.4003889656,1.84463626478,252,,Montmaur,Montmaur,11,Aude,76,Occitanie
11253,MONTOLIEU,11170,MONTOLIEU,,43.3135618602,2.21473143338,253,,Montolieu,Montolieu,11,Aude,76,Occitanie
11254,MONTREAL,11290,MONTREAL,,43.2000530731,2.14809473682,254,,Montréal,Montréal,11,Aude,76,Occitanie
11255,MONTREDON DES CORBIERES,11100,MONTREDON DES CORBIERES,,43.1813624756,2.92455318608,255,,Montredon-des-Corbières,Montredon-des-Corbières,11,Aude,76,Occitanie
11256,MONTSERET,11200,MONTSERET,,43.1053577967,2.80704090239,256,,Montséret,Montséret,11,Aude,76,Occitanie
11257,MONZE,11800,MONZE,,43.1532561314,2.46648904534,257,,Monze,Monze,11,Aude,76,Occitanie
11258,MOUSSAN,11120,MOUSSAN,,43.2305950634,2.95635636294,258,,Moussan,Moussan,11,Aude,76,Occitanie
11259,MOUSSOULENS,11170,MOUSSOULENS,,43.2811009539,2.23830032758,259,,Moussoulens,Moussoulens,11,Aude,76,Occitanie
11260,MOUTHOUMET,11330,MOUTHOUMET,,42.9638535564,2.53168181453,260,,Mouthoumet,Mouthoumet,11,Aude,76,Occitanie
11261,MOUX,11700,MOUX,,43.1727610204,2.64604983695,261,,Moux,Moux,11,Aude,76,Occitanie
11262,NARBONNE,11100,NARBONNE,,43.1652399898,3.02023868739,262,,Narbonne,Narbonne,11,Aude,76,Occitanie
11262,NARBONNE,11100,NARBONNE,NARBONNE PLAGE,43.1652399898,3.02023868739,262,,Narbonne,Narbonne,11,Aude,76,Occitanie
11263,NEBIAS,11500,NEBIAS,,42.8902940685,2.10479303783,263,,Nébias,Nébias,11,Aude,76,Occitanie
11264,NEVIAN,11200,NEVIAN,,43.2036456469,2.88578890635,264,,Névian,Névian,11,Aude,76,Occitanie
11265,NIORT DE SAULT,11140,NIORT DE SAULT,,42.7837312275,1.99823215105,265,,Niort-de-Sault,Niort-de-Sault,11,Aude,76,Occitanie
11266,PORT LA NOUVELLE,11210,PORT LA NOUVELLE,,43.0210644931,3.03976442192,266,,Port-la-Nouvelle,Port-la-Nouvelle,11,Aude,76,Occitanie
11267,ORNAISONS,11200,ORNAISONS,,43.1784619561,2.83659066279,267,,Ornaisons,Ornaisons,11,Aude,76,Occitanie
11268,ORSANS,11270,ORSANS,,43.1546289109,1.97945728495,268,,Orsans,Orsans,11,Aude,76,Occitanie
11269,OUVEILLAN,11590,OUVEILLAN,,43.2931875374,2.96999154918,269,,Ouveillan,Ouveillan,11,Aude,76,Occitanie
11270,PADERN,11350,PADERN,,42.8691333738,2.6512224935,270,,Padern,Padern,11,Aude,76,Occitanie
11271,PALAIRAC,11330,PALAIRAC,,42.9574272598,2.65821174317,271,,Palairac,Palairac,11,Aude,76,Occitanie
11272,PALAJA,11570,PALAJA,,43.1671058484,2.38726861011,272,,Palaja,Palaja,11,Aude,76,Occitanie
11273,PARAZA,11200,PARAZA,,43.2596066026,2.81616059215,273,,Paraza,Paraza,11,Aude,76,Occitanie
11274,PAULIGNE,11300,PAULIGNE,,43.0749163178,2.14740375945,274,,Pauligne,Pauligne,11,Aude,76,Occitanie
11275,PAYRA SUR L HERS,11410,PAYRA SUR L HERS,,43.2622935263,1.87477238509,275,,Payra-sur-l'Hers,Payra-sur-l'Hers,11,Aude,76,Occitanie
11276,PAZIOLS,11350,PAZIOLS,,42.8544727746,2.7156141334,276,,Paziols,Paziols,11,Aude,76,Occitanie
11277,PECHARIC ET LE PY,11420,PECHARIC ET LE PY,,43.193546034,1.82736532628,277,,Pécharic-et-le-Py,Pécharic-et-le-Py,11,Aude,76,Occitanie
11278,PECH LUNA,11420,PECH LUNA,,43.2103140883,1.83766149326,278,,Pech-Luna,Pech-Luna,11,Aude,76,Occitanie
11279,PENNAUTIER,11610,PENNAUTIER,,43.2513741105,2.31315453913,279,,Pennautier,Pennautier,11,Aude,76,Occitanie
11280,PEPIEUX,11700,PEPIEUX,,43.2970451526,2.6863317434,280,,Pépieux,Pépieux,11,Aude,76,Occitanie
11281,PEXIORA,11150,PEXIORA,,43.2688186857,2.04018606486,281,,Pexiora,Pexiora,11,Aude,76,Occitanie
11282,PEYREFITTE DU RAZES,11230,PEYREFITTE DU RAZES,,43.05907818,2.02221796276,282,,Peyrefitte-du-Razès,Peyrefitte-du-Razès,11,Aude,76,Occitanie
11283,PEYREFITTE SUR L HERS,11420,PEYREFITTE SUR L HERS,,43.2460152327,1.81716569517,283,,Peyrefitte-sur-l'Hers,Peyrefitte-sur-l'Hers,11,Aude,76,Occitanie
11284,PEYRENS,11400,PEYRENS,,43.3618303056,1.96005404074,284,,Peyrens,Peyrens,11,Aude,76,Occitanie
11285,PEYRIAC DE MER,11440,PEYRIAC DE MER,,43.0903004686,2.9485053119,285,,Peyriac-de-Mer,Peyriac-de-Mer,11,Aude,76,Occitanie
11286,PEYRIAC MINERVOIS,11160,PEYRIAC MINERVOIS,,43.2937181047,2.56669298957,286,,Peyriac-Minervois,Peyriac-Minervois,11,Aude,76,Occitanie
11287,PEYROLLES,11190,PEYROLLES,,42.9635540616,2.33849254211,287,,Peyrolles,Peyrolles,11,Aude,76,Occitanie
11288,PEZENS,11170,PEZENS,,43.2548914724,2.24772297673,288,,Pezens,Pezens,11,Aude,76,Occitanie
11289,PIEUSSE,11300,PIEUSSE,,43.0830763343,2.25477345035,289,,Pieusse,Pieusse,11,Aude,76,Occitanie
11290,PLAIGNE,11420,PLAIGNE,,43.1706673671,1.80732697623,290,,Plaigne,Plaigne,11,Aude,76,Occitanie
11291,PLAVILLA,11270,PLAVILLA,,43.1414188507,1.90688009289,291,,Plavilla,Plavilla,11,Aude,76,Occitanie
11292,LA POMAREDE,11400,LA POMAREDE,,43.4072581456,1.94393147936,292,La,Pomarède,La Pomarède,11,Aude,76,Occitanie
11293,POMAS,11250,POMAS,,43.1071292824,2.28053295596,293,,Pomas,Pomas,11,Aude,76,Occitanie
11294,POMY,11300,POMY,,43.0607354639,2.05930441377,294,,Pomy,Pomy,11,Aude,76,Occitanie
11295,PORTEL DES CORBIERES,11490,PORTEL DES CORBIERES,,43.0517396235,2.9064611409,295,,Portel-des-Corbières,Portel-des-Corbières,11,Aude,76,Occitanie
11296,POUZOLS MINERVOIS,11120,POUZOLS MINERVOIS,,43.2851566482,2.81263595942,296,,Pouzols-Minervois,Pouzols-Minervois,11,Aude,76,Occitanie
11297,PRADELLES CABARDES,11380,PRADELLES CABARDES,,43.4116401638,2.43867398823,297,,Pradelles-Cabardès,Pradelles-Cabardès,11,Aude,76,Occitanie
11298,PRADELLES EN VAL,11220,PRADELLES EN VAL,,43.1438838012,2.51561585441,298,,Pradelles-en-Val,Pradelles-en-Val,11,Aude,76,Occitanie
11299,PREIXAN,11250,PREIXAN,,43.143749642,2.28019165667,299,,Preixan,Preixan,11,Aude,76,Occitanie
11300,PUGINIER,11400,PUGINIER,,43.3823007411,1.92646643513,300,,Puginier,Puginier,11,Aude,76,Occitanie
11301,PUICHERIC,11700,PUICHERIC,,43.2293360442,2.61236027776,301,,Puichéric,Puichéric,11,Aude,76,Occitanie
11302,PUILAURENS,11140,PUILAURENS,,42.8132848695,2.29884099838,302,,Puilaurens,Puilaurens,11,Aude,76,Occitanie
11302,PUILAURENS,11140,PUILAURENS,LAPRADELLE,42.8132848695,2.29884099838,302,,Puilaurens,Puilaurens,11,Aude,76,Occitanie
11303,PUIVERT,11230,PUIVERT,,42.9088493046,2.05940105724,303,,Puivert,Puivert,11,Aude,76,Occitanie
11304,QUILLAN,11500,QUILLAN,,42.8757498926,2.19080612724,304,,Quillan,Quillan,11,Aude,76,Occitanie
11304,QUILLAN,11500,QUILLAN,BRENAC,42.8757498926,2.19080612724,304,,Quillan,Quillan,11,Aude,76,Occitanie
11305,QUINTILLAN,11360,QUINTILLAN,,42.9592603481,2.70315796115,305,,Quintillan,Quintillan,11,Aude,76,Occitanie
11306,QUIRBAJOU,11500,QUIRBAJOU,,42.8361565518,2.16870682885,306,,Quirbajou,Quirbajou,11,Aude,76,Occitanie
11307,RAISSAC D AUDE,11200,RAISSAC D AUDE,,43.2283330728,2.87745345663,307,,Raissac-d'Aude,Raissac-d'Aude,11,Aude,76,Occitanie
11308,RAISSAC SUR LAMPY,11170,RAISSAC SUR LAMPY,,43.2772178927,2.16063217887,308,,Raissac-sur-Lampy,Raissac-sur-Lampy,11,Aude,76,Occitanie
11309,RENNES LE CHATEAU,11190,RENNES LE CHATEAU,,42.9139113416,2.27362239522,309,,Rennes-le-Château,Rennes-le-Château,11,Aude,76,Occitanie
11310,RENNES LES BAINS,11190,RENNES LES BAINS,,42.9243873687,2.34144050489,310,,Rennes-les-Bains,Rennes-les-Bains,11,Aude,76,Occitanie
11311,RIBAUTE,11220,RIBAUTE,,43.1073015434,2.64058878352,311,,Ribaute,Ribaute,11,Aude,76,Occitanie
11312,RIBOUISSE,11270,RIBOUISSE,,43.1712295534,1.90430739678,312,,Ribouisse,Ribouisse,11,Aude,76,Occitanie
11313,RICAUD,11400,RICAUD,,43.3468605546,1.89555306899,313,,Ricaud,Ricaud,11,Aude,76,Occitanie
11314,RIEUX EN VAL,11220,RIEUX EN VAL,,43.0785029535,2.54068701739,314,,Rieux-en-Val,Rieux-en-Val,11,Aude,76,Occitanie
11315,RIEUX MINERVOIS,11160,RIEUX MINERVOIS,,43.2683807595,2.59121140963,315,,Rieux-Minervois,Rieux-Minervois,11,Aude,76,Occitanie
11316,RIVEL,11230,RIVEL,,42.9209596609,2.00316756108,316,,Rivel,Rivel,11,Aude,76,Occitanie
11317,RODOME,11140,RODOME,,42.7964846966,2.0672394673,317,,Rodome,Rodome,11,Aude,76,Occitanie
11318,ROQUECOURBE MINERVOIS,11700,ROQUECOURBE MINERVOIS,,43.2149762004,2.65409823353,318,,Roquecourbe-Minervois,Roquecourbe-Minervois,11,Aude,76,Occitanie
11319,ROQUEFERE,11380,ROQUEFERE,,43.3939652788,2.37477905225,319,,Roquefère,Roquefère,11,Aude,76,Occitanie
11320,ROQUEFEUIL,11340,ROQUEFEUIL,,42.8466988532,1.99530342832,320,,Roquefeuil,Roquefeuil,11,Aude,76,Occitanie
11321,ROQUEFORT DE SAULT,11140,ROQUEFORT DE SAULT,,42.7387338006,2.19993961506,321,,Roquefort-de-Sault,Roquefort-de-Sault,11,Aude,76,Occitanie
11322,ROQUEFORT DES CORBIERES,11540,ROQUEFORT DES CORBIERES,,42.9921607802,2.9297362776,322,,Roquefort-des-Corbières,Roquefort-des-Corbières,11,Aude,76,Occitanie
11323,ROQUETAILLADE,11300,ROQUETAILLADE,,42.9986140318,2.20719582906,323,,Roquetaillade,Roquetaillade,11,Aude,76,Occitanie
11324,ROUBIA,11200,ROUBIA,,43.2494642548,2.79738174883,324,,Roubia,Roubia,11,Aude,76,Occitanie
11325,ROUFFIAC D AUDE,11250,ROUFFIAC D AUDE,,43.1246778558,2.28305296985,325,,Rouffiac-d'Aude,Rouffiac-d'Aude,11,Aude,76,Occitanie
11326,ROUFFIAC DES CORBIERES,11350,ROUFFIAC DES CORBIERES,,42.8890175915,2.55494937495,326,,Rouffiac-des-Corbières,Rouffiac-des-Corbières,11,Aude,76,Occitanie
11327,ROULLENS,11290,ROULLENS,,43.1658809134,2.26951945766,327,,Roullens,Roullens,11,Aude,76,Occitanie
11328,ROUTIER,11240,ROUTIER,,43.1062544863,2.13189477025,328,,Routier,Routier,11,Aude,76,Occitanie
11329,ROUVENAC,11260,ROUVENAC,,42.9338644188,2.14709009746,329,,Rouvenac,Rouvenac,11,Aude,76,Occitanie
11330,RUSTIQUES,11800,RUSTIQUES,,43.2179978056,2.47940555787,330,,Rustiques,Rustiques,11,Aude,76,Occitanie
11331,ST AMANS,11270,ST AMANS,,43.2274024701,1.88551132685,331,,Saint-Amans,Saint-Amans,11,Aude,76,Occitanie
11332,ST ANDRE DE ROQUELONGUE,11200,ST ANDRE DE ROQUELONGUE,,43.0927941425,2.84905910088,332,,Saint-André-de-Roquelongue,Saint-André-de-Roquelongue,11,Aude,76,Occitanie
11333,ST BENOIT,11230,ST BENOIT,,43.0096303677,2.06434477495,333,,Saint-Benoît,Saint-Benoît,11,Aude,76,Occitanie
11334,STE CAMELLE,11410,STE CAMELLE,,43.2710599599,1.78451738107,334,,Sainte-Camelle,Sainte-Camelle,11,Aude,76,Occitanie
11335,STE COLOMBE SUR GUETTE,11140,STE COLOMBE SUR GUETTE,,42.7526963615,2.24828882061,335,,Sainte-Colombe-sur-Guette,Sainte-Colombe-sur-Guette,11,Aude,76,Occitanie
11336,STE COLOMBE SUR L HERS,11230,STE COLOMBE SUR L HERS,,42.9464872452,1.96310689852,336,,Sainte-Colombe-sur-l'Hers,Sainte-Colombe-sur-l'Hers,11,Aude,76,Occitanie
11337,ST COUAT D AUDE,11700,ST COUAT D AUDE,,43.2084644434,2.63710097186,337,,Saint-Couat-d'Aude,Saint-Couat-d'Aude,11,Aude,76,Occitanie
11338,ST COUAT DU RAZES,11300,ST COUAT DU RAZES,,42.9974690927,2.10792269276,338,,Saint-Couat-du-Razès,Saint-Couat-du-Razès,11,Aude,76,Occitanie
11339,ST DENIS,11310,ST DENIS,,43.3563040517,2.21782788415,339,,Saint-Denis,Saint-Denis,11,Aude,76,Occitanie
11340,STE EULALIE,11170,STE EULALIE,,43.2427203696,2.20565895321,340,,Sainte-Eulalie,Sainte-Eulalie,11,Aude,76,Occitanie
11341,ST FERRIOL,11500,ST FERRIOL,,42.8955156292,2.22569769963,341,,Saint-Ferriol,Saint-Ferriol,11,Aude,76,Occitanie
11342,ST FRICHOUX,11800,ST FRICHOUX,,43.2478259273,2.55888801821,342,,Saint-Frichoux,Saint-Frichoux,11,Aude,76,Occitanie
11343,ST GAUDERIC,11270,ST GAUDERIC,,43.1264567389,1.95859327013,343,,Saint-Gaudéric,Saint-Gaudéric,11,Aude,76,Occitanie
11344,ST HILAIRE,11250,ST HILAIRE,,43.0789376145,2.33839378811,344,,Saint-Hilaire,Saint-Hilaire,11,Aude,76,Occitanie
11345,ST JEAN DE BARROU,11360,ST JEAN DE BARROU,,42.9606338061,2.82790440419,345,,Saint-Jean-de-Barrou,Saint-Jean-de-Barrou,11,Aude,76,Occitanie
11346,ST JEAN DE PARACOL,11260,ST JEAN DE PARACOL,,42.9381797742,2.10903562937,346,,Saint-Jean-de-Paracol,Saint-Jean-de-Paracol,11,Aude,76,Occitanie
11347,ST JULIA DE BEC,11500,ST JULIA DE BEC,,42.8601894521,2.25401672855,347,,Saint-Julia-de-Bec,Saint-Julia-de-Bec,11,Aude,76,Occitanie
11348,ST JULIEN DE BRIOLA,11270,ST JULIEN DE BRIOLA,,43.160649081,1.9453680798,348,,Saint-Julien-de-Briola,Saint-Julien-de-Briola,11,Aude,76,Occitanie
11350,ST JUST ET LE BEZU,11500,ST JUST ET LE BEZU,,42.8783013645,2.28754203844,350,,Saint-Just-et-le-Bézu,Saint-Just-et-le-Bézu,11,Aude,76,Occitanie
11351,ST LAURENT DE LA CABRERISSE,11220,ST LAURENT DE LA CABRERISSE,,43.0835460249,2.71390822803,351,,Saint-Laurent-de-la-Cabrerisse,Saint-Laurent-de-la-Cabrerisse,11,Aude,76,Occitanie
11352,ST LOUIS ET PARAHOU,11500,ST LOUIS ET PARAHOU,,42.851588066,2.32197109444,352,,Saint-Louis-et-Parahou,Saint-Louis-et-Parahou,11,Aude,76,Occitanie
11353,ST MARCEL SUR AUDE,11120,ST MARCEL SUR AUDE,,43.2513488574,2.92392517153,353,,Saint-Marcel-sur-Aude,Saint-Marcel-sur-Aude,11,Aude,76,Occitanie
11354,ST MARTIN DES PUITS,11220,ST MARTIN DES PUITS,,43.0366640999,2.57569156938,354,,Saint-Martin-des-Puits,Saint-Martin-des-Puits,11,Aude,76,Occitanie
11355,ST MARTIN DE VILLEREGLAN,11300,ST MARTIN DE VILLEREGLAN,,43.1038939734,2.21100886207,355,,Saint-Martin-de-Villereglan,Saint-Martin-de-Villereglan,11,Aude,76,Occitanie
11356,ST MARTIN LALANDE,11400,ST MARTIN LALANDE,,43.3015260631,2.01594632957,356,,Saint-Martin-Lalande,Saint-Martin-Lalande,11,Aude,76,Occitanie
11357,ST MARTIN LE VIEIL,11170,ST MARTIN LE VIEIL,,43.2942127146,2.15397156403,357,,Saint-Martin-le-Vieil,Saint-Martin-le-Vieil,11,Aude,76,Occitanie
11358,ST MARTIN LYS,11500,ST MARTIN LYS,,42.8243329025,2.23823385379,358,,Saint-Martin-Lys,Saint-Martin-Lys,11,Aude,76,Occitanie
11359,ST MICHEL DE LANES,11410,ST MICHEL DE LANES,,43.3265442555,1.75090038228,359,,Saint-Michel-de-Lanès,Saint-Michel-de-Lanès,11,Aude,76,Occitanie
11360,ST NAZAIRE D AUDE,11120,ST NAZAIRE D AUDE,,43.2489566147,2.89118063794,360,,Saint-Nazaire-d'Aude,Saint-Nazaire-d'Aude,11,Aude,76,Occitanie
11361,ST PAPOUL,11400,ST PAPOUL,,43.3374046005,2.04475421603,361,,Saint-Papoul,Saint-Papoul,11,Aude,76,Occitanie
11362,ST PAULET,11320,ST PAULET,,43.4046115427,1.88105647745,362,,Saint-Paulet,Saint-Paulet,11,Aude,76,Occitanie
11363,ST PIERRE DES CHAMPS,11220,ST PIERRE DES CHAMPS,,43.0493029283,2.6079517501,363,,Saint-Pierre-des-Champs,Saint-Pierre-des-Champs,11,Aude,76,Occitanie
11364,ST POLYCARPE,11300,ST POLYCARPE,,43.0331548632,2.30004919732,364,,Saint-Polycarpe,Saint-Polycarpe,11,Aude,76,Occitanie
11365,ST SERNIN,11420,ST SERNIN,,43.2285542275,1.80569705902,365,,Saint-Sernin,Saint-Sernin,11,Aude,76,Occitanie
11366,STE VALIERE,11120,STE VALIERE,,43.2746311471,2.84527328137,366,,Sainte-Valière,Sainte-Valière,11,Aude,76,Occitanie
11367,SAISSAC,11310,SAISSAC,,43.3660090567,2.16153708941,367,,Saissac,Saissac,11,Aude,76,Occitanie
11368,SALLELES CABARDES,11600,SALLELES CABARDES,,43.3201466432,2.42096628155,368,,Sallèles-Cabardès,Sallèles-Cabardès,11,Aude,76,Occitanie
11369,SALLELES D AUDE,11590,SALLELES D AUDE,,43.2700227368,2.93528136754,369,,Sallèles-d'Aude,Sallèles-d'Aude,11,Aude,76,Occitanie
11370,SALLES D AUDE,11110,SALLES D AUDE,,43.2361241545,3.10717237313,370,,Salles-d'Aude,Salles-d'Aude,11,Aude,76,Occitanie
11371,SALLES SUR L HERS,11410,SALLES SUR L HERS,,43.29456777,1.78132422207,371,,Salles-sur-l'Hers,Salles-sur-l'Hers,11,Aude,76,Occitanie
11372,SALSIGNE,11600,SALSIGNE,,43.3220104211,2.35660329319,372,,Salsigne,Salsigne,11,Aude,76,Occitanie
11373,SALVEZINES,11140,SALVEZINES,,42.7749408531,2.28540415468,373,,Salvezines,Salvezines,11,Aude,76,Occitanie
11374,SALZA,11330,SALZA,,42.9854396478,2.5059068011,374,,Salza,Salza,11,Aude,76,Occitanie
11375,SEIGNALENS,11240,SEIGNALENS,,43.0990678158,1.96711035013,375,,Seignalens,Seignalens,11,Aude,76,Occitanie
11376,LA SERPENT,11190,LA SERPENT,,42.9646308122,2.17458556586,376,La,Serpent,La Serpent,11,Aude,76,Occitanie
11377,SERRES,11190,SERRES,,42.9445748833,2.32874834613,377,,Serres,Serres,11,Aude,76,Occitanie
11378,SERVIES EN VAL,11220,SERVIES EN VAL,,43.0946905132,2.52557493954,378,,Serviès-en-Val,Serviès-en-Val,11,Aude,76,Occitanie
11379,SIGEAN,11130,SIGEAN,,43.0395983609,2.98124209143,379,,Sigean,Sigean,11,Aude,76,Occitanie
11380,SONNAC SUR L HERS,11230,SONNAC SUR L HERS,,43.0152206316,2.00202806625,380,,Sonnac-sur-l'Hers,Sonnac-sur-l'Hers,11,Aude,76,Occitanie
11381,SOUGRAIGNE,11190,SOUGRAIGNE,,42.9048326016,2.37483494186,381,,Sougraigne,Sougraigne,11,Aude,76,Occitanie
11382,SOUILHANELS,11400,SOUILHANELS,,43.3559262458,1.91704210416,382,,Souilhanels,Souilhanels,11,Aude,76,Occitanie
11383,SOUILHE,11400,SOUILHE,,43.3687280247,1.91691905449,383,,Souilhe,Souilhe,11,Aude,76,Occitanie
11384,SOULATGE,11330,SOULATGE,,42.879514086,2.50365865424,384,,Soulatgé,Soulatgé,11,Aude,76,Occitanie
11385,SOUPEX,11320,SOUPEX,,43.3819001261,1.89059609045,385,,Soupex,Soupex,11,Aude,76,Occitanie
11386,TALAIRAN,11220,TALAIRAN,,43.0299409472,2.66938593164,386,,Talairan,Talairan,11,Aude,76,Occitanie
11387,TAURIZE,11220,TAURIZE,,43.0644951313,2.50638468749,387,,Taurize,Taurize,11,Aude,76,Occitanie
11388,TERMES,11330,TERMES,,43.0033668711,2.57033427463,388,,Termes,Termes,11,Aude,76,Occitanie
11389,TERROLES,11580,TERROLES,,42.9913709572,2.342696874,389,,Terroles,Terroles,11,Aude,76,Occitanie
11390,THEZAN DES CORBIERES,11200,THEZAN DES CORBIERES,,43.0893654385,2.78310314277,390,,Thézan-des-Corbières,Thézan-des-Corbières,11,Aude,76,Occitanie
11391,LA TOURETTE CABARDES,11380,LA TOURETTE CABARDES,,43.3821297406,2.32541957732,391,La,Tourette-Cabardès,La Tourette-Cabardès,11,Aude,76,Occitanie
11392,TOURNISSAN,11220,TOURNISSAN,,43.0840008987,2.66022505516,392,,Tournissan,Tournissan,11,Aude,76,Occitanie
11393,TOUROUZELLE,11200,TOUROUZELLE,,43.247184256,2.72709501548,393,,Tourouzelle,Tourouzelle,11,Aude,76,Occitanie
11394,TOURREILLES,11300,TOURREILLES,,43.0200947771,2.16958923912,394,,Tourreilles,Tourreilles,11,Aude,76,Occitanie
11395,TRASSANEL,11160,TRASSANEL,,43.3480032097,2.44282271412,395,,Trassanel,Trassanel,11,Aude,76,Occitanie
11396,TRAUSSE,11160,TRAUSSE,,43.318218657,2.56855106815,396,,Trausse,Trausse,11,Aude,76,Occitanie
11397,TREBES,11800,TREBES,,43.2054644637,2.45954102477,397,,Trèbes,Trèbes,11,Aude,76,Occitanie
11398,TREILLES,11510,TREILLES,,42.9180396161,2.9426895388,398,,Treilles,Treilles,11,Aude,76,Occitanie
11399,TREVILLE,11400,TREVILLE,,43.3828691726,1.95497022333,399,,Tréville,Tréville,11,Aude,76,Occitanie
11400,TREZIERS,11230,TREZIERS,,43.0557381475,1.96306629097,400,,Tréziers,Tréziers,11,Aude,76,Occitanie
11401,TUCHAN,11350,TUCHAN,,42.9063160941,2.72809318439,401,,Tuchan,Tuchan,11,Aude,76,Occitanie
11402,VALMIGERE,11580,VALMIGERE,,42.9838312232,2.38430278672,402,,Valmigère,Valmigère,11,Aude,76,Occitanie
11404,VENTENAC CABARDES,11610,VENTENAC CABARDES,,43.275168057,2.28232242512,404,,Ventenac-Cabardès,Ventenac-Cabardès,11,Aude,76,Occitanie
11405,VENTENAC EN MINERVOIS,11120,VENTENAC EN MINERVOIS,,43.2520790063,2.85589136189,405,,Ventenac-en-Minervois,Ventenac-en-Minervois,11,Aude,76,Occitanie
11406,VERAZA,11580,VERAZA,,42.9927983213,2.30791216337,406,,Véraza,Véraza,11,Aude,76,Occitanie
11407,VERDUN EN LAURAGAIS,11400,VERDUN EN LAURAGAIS,,43.3677103686,2.07806580446,407,,Verdun-en-Lauragais,Verdun-en-Lauragais,11,Aude,76,Occitanie
11408,VERZEILLE,11250,VERZEILLE,,43.1202007028,2.32860736272,408,,Verzeille,Verzeille,11,Aude,76,Occitanie
11409,VIGNEVIEILLE,11330,VIGNEVIEILLE,,43.0156409984,2.53032066323,409,,Vignevieille,Vignevieille,11,Aude,76,Occitanie
11410,VILLALIER,11600,VILLALIER,,43.2521774853,2.41886505909,410,,Villalier,Villalier,11,Aude,76,Occitanie
11411,VILLANIERE,11600,VILLANIERE,,43.3489710835,2.3498111989,411,,Villanière,Villanière,11,Aude,76,Occitanie
11412,VILLARDEBELLE,11580,VILLARDEBELLE,,43.012050439,2.40517931832,412,,Villardebelle,Villardebelle,11,Aude,76,Occitanie
11413,VILLARDONNEL,11600,VILLARDONNEL,,43.3345079684,2.31365896939,413,,Villardonnel,Villardonnel,11,Aude,76,Occitanie
11414,VILLAR EN VAL,11220,VILLAR EN VAL,,43.0808550222,2.44510735168,414,,Villar-en-Val,Villar-en-Val,11,Aude,76,Occitanie
11415,VILLAR ST ANSELME,11250,VILLAR ST ANSELME,,43.0573045654,2.29925892511,415,,Villar-Saint-Anselme,Villar-Saint-Anselme,11,Aude,76,Occitanie
11416,VILLARZEL CABARDES,11600,VILLARZEL CABARDES,,43.2840663729,2.46920880268,416,,Villarzel-Cabardès,Villarzel-Cabardès,11,Aude,76,Occitanie
11417,VILLARZEL DU RAZES,11300,VILLARZEL DU RAZES,,43.1426266338,2.20324687273,417,,Villarzel-du-Razès,Villarzel-du-Razès,11,Aude,76,Occitanie
11418,VILLASAVARY,11150,VILLASAVARY,,43.2319320444,2.03314267274,418,,Villasavary,Villasavary,11,Aude,76,Occitanie
11419,VILLAUTOU,11420,VILLAUTOU,,43.1617806666,1.83707235249,419,,Villautou,Villautou,11,Aude,76,Occitanie
11420,VILLEBAZY,11250,VILLEBAZY,,43.0558531373,2.33940629653,420,,Villebazy,Villebazy,11,Aude,76,Occitanie
11421,VILLEDAIGNE,11200,VILLEDAIGNE,,43.2113999899,2.85510092401,421,,Villedaigne,Villedaigne,11,Aude,76,Occitanie
11422,VILLEDUBERT,11800,VILLEDUBERT,,43.2318046897,2.41699108756,422,,Villedubert,Villedubert,11,Aude,76,Occitanie
11423,VILLEFLOURE,11570,VILLEFLOURE,,43.1293275132,2.3891099941,423,,Villefloure,Villefloure,11,Aude,76,Occitanie
11424,VILLEFORT,11230,VILLEFORT,,42.9512982212,2.04814958894,424,,Villefort,Villefort,11,Aude,76,Occitanie
11425,VILLEGAILHENC,11600,VILLEGAILHENC,,43.2718025661,2.34692667896,425,,Villegailhenc,Villegailhenc,11,Aude,76,Occitanie
11426,VILLEGLY,11600,VILLEGLY,,43.2930208254,2.43699100253,426,,Villegly,Villegly,11,Aude,76,Occitanie
11427,VILLELONGUE D AUDE,11300,VILLELONGUE D AUDE,,43.0554333537,2.09254975337,427,,Villelongue-d'Aude,Villelongue-d'Aude,11,Aude,76,Occitanie
11428,VILLEMAGNE,11310,VILLEMAGNE,,43.3677856314,2.10938684579,428,,Villemagne,Villemagne,11,Aude,76,Occitanie
11429,VILLEMOUSTAUSSOU,11620,VILLEMOUSTAUSSOU,,43.2495624385,2.36587017807,429,,Villemoustaussou,Villemoustaussou,11,Aude,76,Occitanie
11430,VILLENEUVE LA COMPTAL,11400,VILLENEUVE LA COMPTAL,,43.285181262,1.91990066613,430,,Villeneuve-la-Comptal,Villeneuve-la-Comptal,11,Aude,76,Occitanie
11431,VILLENEUVE LES CORBIERES,11360,VILLENEUVE LES CORBIERES,,42.9584800361,2.7700013115,431,,Villeneuve-les-Corbières,Villeneuve-les-Corbières,11,Aude,76,Occitanie
11432,VILLENEUVE LES MONTREAL,11290,VILLENEUVE LES MONTREAL,,43.178928019,2.11283533112,432,,Villeneuve-lès-Montréal,Villeneuve-lès-Montréal,11,Aude,76,Occitanie
11433,VILLENEUVE MINERVOIS,11160,VILLENEUVE MINERVOIS,,43.3289695308,2.46851612776,433,,Villeneuve-Minervois,Villeneuve-Minervois,11,Aude,76,Occitanie
11434,VILLEPINTE,11150,VILLEPINTE,,43.2808698633,2.09551779472,434,,Villepinte,Villepinte,11,Aude,76,Occitanie
11435,VILLEROUGE TERMENES,11330,VILLEROUGE TERMENES,,43.0054052099,2.63468678953,435,,Villerouge-Termenès,Villerouge-Termenès,11,Aude,76,Occitanie
11436,VILLESEQUE DES CORBIERES,11360,VILLESEQUE DES CORBIERES,,43.0182115243,2.85759896438,436,,Villesèque-des-Corbières,Villesèque-des-Corbières,11,Aude,76,Occitanie
11437,VILLESEQUELANDE,11170,VILLESEQUELANDE,,43.2329788288,2.23031885355,437,,Villesèquelande,Villesèquelande,11,Aude,76,Occitanie
11438,VILLESISCLE,11150,VILLESISCLE,,43.2278470431,2.08978989468,438,,Villesiscle,Villesiscle,11,Aude,76,Occitanie
11439,VILLESPY,11170,VILLESPY,,43.3220982034,2.09853439649,439,,Villespy,Villespy,11,Aude,76,Occitanie
11440,VILLETRITOULS,11220,VILLETRITOULS,,43.0752738,2.48956786102,440,,Villetritouls,Villetritouls,11,Aude,76,Occitanie
11441,VINASSAN,11110,VINASSAN,,43.2054224322,3.09538889939,441,,Vinassan,Vinassan,11,Aude,76,Occitanie
12001,AGEN D AVEYRON,12630,AGEN D AVEYRON,,44.3513031939,2.69339680513,1,,Agen-d'Aveyron,Agen-d'Aveyron,12,Aveyron,76,Occitanie
12002,AGUESSAC,12520,AGUESSAC,,44.1613901609,3.06898957805,2,,Aguessac,Aguessac,12,Aveyron,76,Occitanie
12003,LES ALBRES,12220,LES ALBRES,,44.5408024664,2.18023147565,3,Les,Albres,Les Albres,12,Aveyron,76,Occitanie
12004,ALMONT LES JUNIES,12300,ALMONT LES JUNIES,,44.6009371852,2.31097212819,4,,Almont-les-Junies,Almont-les-Junies,12,Aveyron,76,Occitanie
12006,ALRANCE,12430,ALRANCE,,44.1352241599,2.67560444399,6,,Alrance,Alrance,12,Aveyron,76,Occitanie
12007,AMBEYRAC,12260,AMBEYRAC,,44.4943534957,1.9357162857,7,,Ambeyrac,Ambeyrac,12,Aveyron,76,Occitanie
12008,ANGLARS ST FELIX,12390,ANGLARS ST FELIX,,44.41616534,2.2384963959,8,,Anglars-Saint-Félix,Anglars-Saint-Félix,12,Aveyron,76,Occitanie
12009,ARNAC SUR DOURDOU,12360,ARNAC SUR DOURDOU,,43.7187010455,2.94592121582,9,,Arnac-sur-Dourdou,Arnac-sur-Dourdou,12,Aveyron,76,Occitanie
12010,ARQUES,12290,ARQUES,,44.3220848863,2.80404631161,10,,Arques,Arques,12,Aveyron,76,Occitanie
12011,ARVIEU,12120,ARVIEU,,44.1907834578,2.66728393102,11,,Arvieu,Arvieu,12,Aveyron,76,Occitanie
12012,ASPRIERES,12700,ASPRIERES,,44.555388853,2.13842661504,12,,Asprières,Asprières,12,Aveyron,76,Occitanie
12013,AUBIN,12110,AUBIN,,44.5318545051,2.25591796926,13,,Aubin,Aubin,12,Aveyron,76,Occitanie
12013,AUBIN,12110,AUBIN,COMBES,44.5318545051,2.25591796926,13,,Aubin,Aubin,12,Aveyron,76,Occitanie
12013,AUBIN,12110,AUBIN,LE GUA,44.5318545051,2.25591796926,13,,Aubin,Aubin,12,Aveyron,76,Occitanie
12015,AURIAC LAGAST,12120,AURIAC LAGAST,,44.1446380176,2.59650757415,15,,Auriac-Lagast,Auriac-Lagast,12,Aveyron,76,Occitanie
12016,AUZITS,12390,AUZITS,,44.4995549267,2.32166840443,16,,Auzits,Auzits,12,Aveyron,76,Occitanie
12017,AYSSENES,12430,AYSSENES,,44.092111179,2.76570045651,17,,Ayssènes,Ayssènes,12,Aveyron,76,Occitanie
12018,BALAGUIER D OLT,12260,BALAGUIER D OLT,,44.5189126443,1.97719263772,18,,Balaguier-d'Olt,Balaguier-d'Olt,12,Aveyron,76,Occitanie
12019,BALAGUIER SUR RANCE,12380,BALAGUIER SUR RANCE,,43.8958843359,2.576705609,19,,Balaguier-sur-Rance,Balaguier-sur-Rance,12,Aveyron,76,Occitanie
12020,BALSAC,12510,BALSAC,,44.40028476,2.45563526521,20,,Balsac,Balsac,12,Aveyron,76,Occitanie
12021,LE BAS SEGALA,12200,LE BAS SEGALA,LA BASTIDE L EVEQUE,44.3386235465,2.14341113213,21,Le,Bas Ségala,Le Bas Ségala,12,Aveyron,76,Occitanie
12021,LE BAS SEGALA,12200,LE BAS SEGALA,ST SALVADOU,44.3386235465,2.14341113213,21,Le,Bas Ségala,Le Bas Ségala,12,Aveyron,76,Occitanie
12021,LE BAS SEGALA,12240,LE BAS SEGALA,VABRE TIZAC,44.3386235465,2.14341113213,21,Le,Bas Ségala,Le Bas Ségala,12,Aveyron,76,Occitanie
12022,LA BASTIDE PRADINES,12490,LA BASTIDE PRADINES,,44.0105483436,3.05634130776,22,La,Bastide-Pradines,La Bastide-Pradines,12,Aveyron,76,Occitanie
12023,LA BASTIDE SOLAGES,12550,LA BASTIDE SOLAGES,,43.9499780867,2.52484421466,23,La,Bastide-Solages,La Bastide-Solages,12,Aveyron,76,Occitanie
12024,BELCASTEL,12390,BELCASTEL,,44.3944148302,2.33164860958,24,,Belcastel,Belcastel,12,Aveyron,76,Occitanie
12025,BELMONT SUR RANCE,12370,BELMONT SUR RANCE,,43.8248066613,2.75112588712,25,,Belmont-sur-Rance,Belmont-sur-Rance,12,Aveyron,76,Occitanie
12026,BERTHOLENE,12310,BERTHOLENE,,44.3951997527,2.78341531364,26,,Bertholène,Bertholène,12,Aveyron,76,Occitanie
12027,BESSUEJOULS,12500,BESSUEJOULS,,44.5198421781,2.71668578006,27,,Bessuéjouls,Bessuéjouls,12,Aveyron,76,Occitanie
12028,BOISSE PENCHOT,12300,BOISSE PENCHOT,,44.5806056647,2.21149187356,28,,Boisse-Penchot,Boisse-Penchot,12,Aveyron,76,Occitanie
12029,BOR ET BAR,12270,BOR ET BAR,,44.2000941717,2.08598279329,29,,Bor-et-Bar,Bor-et-Bar,12,Aveyron,76,Occitanie
12030,BOUILLAC,12300,BOUILLAC,,44.5734390459,2.17431868785,30,,Bouillac,Bouillac,12,Aveyron,76,Occitanie
12031,BOURNAZEL,12390,BOURNAZEL,,44.4601104771,2.30271323299,31,,Bournazel,Bournazel,12,Aveyron,76,Occitanie
12032,BOUSSAC,12160,BOUSSAC,,44.2956919564,2.37751335609,32,,Boussac,Boussac,12,Aveyron,76,Occitanie
12033,BOZOULS,12340,BOZOULS,,44.4675229225,2.70795409779,33,,Bozouls,Bozouls,12,Aveyron,76,Occitanie
12034,BRANDONNET,12350,BRANDONNET,,44.3823756053,2.1374677844,34,,Brandonnet,Brandonnet,12,Aveyron,76,Occitanie
12035,BRASC,12550,BRASC,,43.9790937141,2.57293179162,35,,Brasc,Brasc,12,Aveyron,76,Occitanie
12036,BROMMAT,12600,BROMMAT,,44.8284548691,2.70476777705,36,,Brommat,Brommat,12,Aveyron,76,Occitanie
12037,BROQUIES,12480,BROQUIES,,44.0179354928,2.70623918127,37,,Broquiès,Broquiès,12,Aveyron,76,Occitanie
12038,BROUSSE LE CHATEAU,12480,BROUSSE LE CHATEAU,,44.0062723535,2.63789868887,38,,Brousse-le-Château,Brousse-le-Château,12,Aveyron,76,Occitanie
12039,BRUSQUE,12360,BRUSQUE,,43.7649625087,2.94838918036,39,,Brusque,Brusque,12,Aveyron,76,Occitanie
12041,CABANES,12800,CABANES,,44.191255782,2.29209486155,41,,Cabanès,Cabanès,12,Aveyron,76,Occitanie
12042,CALMELS ET LE VIALA,12400,CALMELS ET LE VIALA,,43.95542291,2.75841981289,42,,Calmels-et-le-Viala,Calmels-et-le-Viala,12,Aveyron,76,Occitanie
12043,CALMONT,12450,CALMONT,,44.2590321912,2.52423447426,43,,Calmont,Calmont,12,Aveyron,76,Occitanie
12043,CALMONT,12450,CALMONT,CEIGNAC,44.2590321912,2.52423447426,43,,Calmont,Calmont,12,Aveyron,76,Occitanie
12044,CAMARES,12360,CAMARES,,43.8183603178,2.88523152665,44,,Camarès,Camarès,12,Aveyron,76,Occitanie
12045,CAMBOULAZET,12160,CAMBOULAZET,,44.2226481528,2.44714046316,45,,Camboulazet,Camboulazet,12,Aveyron,76,Occitanie
12046,CAMJAC,12800,CAMJAC,,44.1792869286,2.39518735368,46,,Camjac,Camjac,12,Aveyron,76,Occitanie
12047,CAMPAGNAC,12560,CAMPAGNAC,,44.4014190434,3.08798041232,47,,Campagnac,Campagnac,12,Aveyron,76,Occitanie
12048,CAMPOURIEZ,12140,CAMPOURIEZ,,44.6808744001,2.60936956955,48,,Campouriez,Campouriez,12,Aveyron,76,Occitanie
12048,CAMPOURIEZ,12140,CAMPOURIEZ,BANHARS,44.6808744001,2.60936956955,48,,Campouriez,Campouriez,12,Aveyron,76,Occitanie
12048,CAMPOURIEZ,12460,CAMPOURIEZ,,44.6808744001,2.60936956955,48,,Campouriez,Campouriez,12,Aveyron,76,Occitanie
12049,CAMPUAC,12580,CAMPUAC,,44.5663603478,2.5847677536,49,,Campuac,Campuac,12,Aveyron,76,Occitanie
12050,CANET DE SALARS,12290,CANET DE SALARS,,44.2325871392,2.7377561154,50,,Canet-de-Salars,Canet-de-Salars,12,Aveyron,76,Occitanie
12051,CANTOIN,12420,CANTOIN,,44.8387938503,2.82485729528,51,,Cantoin,Cantoin,12,Aveyron,76,Occitanie
12052,CAPDENAC GARE,12700,CAPDENAC GARE,,44.5596095618,2.06863929998,52,,Capdenac-Gare,Capdenac-Gare,12,Aveyron,76,Occitanie
12053,LA CAPELLE BALAGUIER,12260,LA CAPELLE BALAGUIER,,44.4390516926,1.9259338711,53,La,Capelle-Balaguier,La Capelle-Balaguier,12,Aveyron,76,Occitanie
12054,LA CAPELLE BLEYS,12240,LA CAPELLE BLEYS,,44.3037455115,2.17927140431,54,La,Capelle-Bleys,La Capelle-Bleys,12,Aveyron,76,Occitanie
12055,LA CAPELLE BONANCE,12130,LA CAPELLE BONANCE,,44.4515040312,3.02655921339,55,La,Capelle-Bonance,La Capelle-Bonance,12,Aveyron,76,Occitanie
12056,BARAQUEVILLE,12160,BARAQUEVILLE,,44.2810938954,2.44964862339,56,,Baraqueville,Baraqueville,12,Aveyron,76,Occitanie
12056,BARAQUEVILLE,12160,BARAQUEVILLE,VORS,44.2810938954,2.44964862339,56,,Baraqueville,Baraqueville,12,Aveyron,76,Occitanie
12057,CASSAGNES BEGONHES,12120,CASSAGNES BEGONHES,,44.1789643613,2.51462788603,57,,Cassagnes-Bégonhès,Cassagnes-Bégonhès,12,Aveyron,76,Occitanie
12058,CASSUEJOULS,12210,CASSUEJOULS,,44.7223970654,2.81116036535,58,,Cassuéjouls,Cassuéjouls,12,Aveyron,76,Occitanie
12059,CASTANET,12240,CASTANET,,44.2930906891,2.30836294143,59,,Castanet,Castanet,12,Aveyron,76,Occitanie
12060,CASTELMARY,12800,CASTELMARY,,44.1721922742,2.24442015696,60,,Castelmary,Castelmary,12,Aveyron,76,Occitanie
12061,CASTELNAU DE MANDAILLES,12500,CASTELNAU DE MANDAILLES,,44.5310923776,2.88749197911,61,,Castelnau-de-Mandailles,Castelnau-de-Mandailles,12,Aveyron,76,Occitanie
12062,CASTELNAU PEGAYROLS,12620,CASTELNAU PEGAYROLS,,44.1404700763,2.92770802784,62,,Castelnau-Pégayrols,Castelnau-Pégayrols,12,Aveyron,76,Occitanie
12063,LA CAVALERIE,12230,LA CAVALERIE,,44.0119187928,3.1838932062,63,La,Cavalerie,La Cavalerie,12,Aveyron,76,Occitanie
12064,LE CAYROL,12500,LE CAYROL,,44.5856833966,2.78393702162,64,Le,Cayrol,Le Cayrol,12,Aveyron,76,Occitanie
12065,CENTRES,12120,CENTRES,,44.1676419665,2.4408941815,65,,Centrès,Centrès,12,Aveyron,76,Occitanie
12066,CLAIRVAUX D AVEYRON,12330,CLAIRVAUX D AVEYRON,,44.4068685217,2.40873081566,66,,Clairvaux-d'Aveyron,Clairvaux-d'Aveyron,12,Aveyron,76,Occitanie
12067,LE CLAPIER,12540,LE CLAPIER,,43.8281226174,3.17391582367,67,Le,Clapier,Le Clapier,12,Aveyron,76,Occitanie
12068,COLOMBIES,12240,COLOMBIES,,44.3435829134,2.32786073616,68,,Colombiès,Colombiès,12,Aveyron,76,Occitanie
12069,COMBRET,12370,COMBRET,,43.8406339417,2.68483003393,69,,Combret,Combret,12,Aveyron,76,Occitanie
12070,COMPEYRE,12520,COMPEYRE,,44.175783777,3.10274106819,70,,Compeyre,Compeyre,12,Aveyron,76,Occitanie
12071,COMPOLIBAT,12350,COMPOLIBAT,,44.3793468455,2.1863960416,71,,Compolibat,Compolibat,12,Aveyron,76,Occitanie
12072,COMPREGNAC,12100,COMPREGNAC,,44.0964783684,2.97961913091,72,,Comprégnac,Comprégnac,12,Aveyron,76,Occitanie
12073,COMPS LA GRAND VILLE,12120,COMPS LA GRAND VILLE,,44.2241967002,2.56911408448,73,,Comps-la-Grand-Ville,Comps-la-Grand-Ville,12,Aveyron,76,Occitanie
12074,CONDOM D AUBRAC,12470,CONDOM D AUBRAC,,44.6054295973,2.88073175235,74,,Condom-d'Aubrac,Condom-d'Aubrac,12,Aveyron,76,Occitanie
12075,CONNAC,12170,CONNAC,,44.0203997041,2.59454534597,75,,Connac,Connac,12,Aveyron,76,Occitanie
12076,CONQUES EN ROUERGUE,12320,CONQUES EN ROUERGUE,,44.5952473002,2.4201643341,76,,Conques-en-Rouergue,Conques-en-Rouergue,12,Aveyron,76,Occitanie
12076,CONQUES EN ROUERGUE,12320,CONQUES EN ROUERGUE,GRAND VABRE,44.5952473002,2.4201643341,76,,Conques-en-Rouergue,Conques-en-Rouergue,12,Aveyron,76,Occitanie
12076,CONQUES EN ROUERGUE,12320,CONQUES EN ROUERGUE,NOAILHAC,44.5952473002,2.4201643341,76,,Conques-en-Rouergue,Conques-en-Rouergue,12,Aveyron,76,Occitanie
12076,CONQUES EN ROUERGUE,12320,CONQUES EN ROUERGUE,ST CYPRIEN SUR DOURDOU,44.5952473002,2.4201643341,76,,Conques-en-Rouergue,Conques-en-Rouergue,12,Aveyron,76,Occitanie
12077,CORNUS,12540,CORNUS,,43.8924370352,3.20078895944,77,,Cornus,Cornus,12,Aveyron,76,Occitanie
12078,LES COSTES GOZON,12400,LES COSTES GOZON,,44.0165312389,2.80008517701,78,Les,Costes-Gozon,Les Costes-Gozon,12,Aveyron,76,Occitanie
12079,COUBISOU,12190,COUBISOU,,44.5755974174,2.73237270649,79,,Coubisou,Coubisou,12,Aveyron,76,Occitanie
12080,COUPIAC,12550,COUPIAC,,43.947128591,2.59314085517,80,,Coupiac,Coupiac,12,Aveyron,76,Occitanie
12082,LA COUVERTOIRADE,12230,LA COUVERTOIRADE,,43.9292282018,3.28890121279,82,La,Couvertoirade,La Couvertoirade,12,Aveyron,76,Occitanie
12083,CRANSAC,12110,CRANSAC,,44.5186350592,2.28781785665,83,,Cransac,Cransac,12,Aveyron,76,Occitanie
12084,CREISSELS,12100,CREISSELS,,44.064990103,3.05961986973,84,,Creissels,Creissels,12,Aveyron,76,Occitanie
12085,CRESPIN,12800,CRESPIN,,44.153846974,2.25678856386,85,,Crespin,Crespin,12,Aveyron,76,Occitanie
12086,LA CRESSE,12640,LA CRESSE,,44.1676766844,3.15670064587,86,La,Cresse,La Cresse,12,Aveyron,76,Occitanie
12088,CURIERES,12210,CURIERES,,44.6493232738,2.89170192914,88,,Curières,Curières,12,Aveyron,76,Occitanie
12089,DECAZEVILLE,12300,DECAZEVILLE,,44.567273002,2.25243853064,89,,Decazeville,Decazeville,12,Aveyron,76,Occitanie
12090,DRUELLE,12000,DRUELLE,,44.3609252009,2.47671597719,90,,Druelle Balsac,Druelle Balsac,12,Aveyron,76,Occitanie
12090,DRUELLE,12000,DRUELLE,AMPIAC,44.3609252009,2.47671597719,90,,Druelle Balsac,Druelle Balsac,12,Aveyron,76,Occitanie
12090,DRUELLE,12510,DRUELLE,,44.3609252009,2.47671597719,90,,Druelle Balsac,Druelle Balsac,12,Aveyron,76,Occitanie
12091,DRULHE,12350,DRULHE,,44.4558448387,2.13481083586,91,,Drulhe,Drulhe,12,Aveyron,76,Occitanie
12092,DURENQUE,12170,DURENQUE,,44.10079135,2.62177074145,92,,Durenque,Durenque,12,Aveyron,76,Occitanie
12093,LE FEL,12140,LE FEL,,44.6696449626,2.51772189007,93,Le,Fel,Le Fel,12,Aveyron,76,Occitanie
12094,ENTRAYGUES SUR TRUYERE,12140,ENTRAYGUES SUR TRUYERE,,44.6544227279,2.57843798744,94,,Entraygues-sur-Truyère,Entraygues-sur-Truyère,12,Aveyron,76,Occitanie
12095,ESCANDOLIERES,12390,ESCANDOLIERES,,44.4713685405,2.34981095365,95,,Escandolières,Escandolières,12,Aveyron,76,Occitanie
12096,ESPALION,12500,ESPALION,,44.5188717043,2.76426608276,96,,Espalion,Espalion,12,Aveyron,76,Occitanie
12097,ESPEYRAC,12140,ESPEYRAC,,44.619095045,2.51940471481,97,,Espeyrac,Espeyrac,12,Aveyron,76,Occitanie
12098,ESTAING,12190,ESTAING,,44.5694224223,2.68750805663,98,,Estaing,Estaing,12,Aveyron,76,Occitanie
12099,FAYET,12360,FAYET,,43.8035569048,2.97747865298,99,,Fayet,Fayet,12,Aveyron,76,Occitanie
12100,FIRMI,12300,FIRMI,,44.5476858944,2.32505415911,100,,Firmi,Firmi,12,Aveyron,76,Occitanie
12101,FLAGNAC,12300,FLAGNAC,,44.5935121569,2.2651648768,101,,Flagnac,Flagnac,12,Aveyron,76,Occitanie
12102,FLAVIN,12450,FLAVIN,,44.2880137822,2.62175551429,102,,Flavin,Flavin,12,Aveyron,76,Occitanie
12103,FLORENTIN LA CAPELLE,12140,FLORENTIN LA CAPELLE,,44.6389814573,2.64439043133,103,,Florentin-la-Capelle,Florentin-la-Capelle,12,Aveyron,76,Occitanie
12104,FOISSAC,12260,FOISSAC,,44.5065119928,2.00769890689,104,,Foissac,Foissac,12,Aveyron,76,Occitanie
12105,LA FOUILLADE,12270,LA FOUILLADE,,44.2345851449,2.04784404559,105,La,Fouillade,La Fouillade,12,Aveyron,76,Occitanie
12106,GABRIAC,12340,GABRIAC,,44.4619669953,2.80472572393,106,,Gabriac,Gabriac,12,Aveyron,76,Occitanie
12107,GAILLAC D AVEYRON,12310,GAILLAC D AVEYRON,,44.3616362767,2.90279259177,107,,Gaillac-d'Aveyron,Gaillac-d'Aveyron,12,Aveyron,76,Occitanie
12108,GALGAN,12220,GALGAN,,44.5034642584,2.17991482623,108,,Galgan,Galgan,12,Aveyron,76,Occitanie
12109,GISSAC,12360,GISSAC,,43.868273324,2.90640154939,109,,Gissac,Gissac,12,Aveyron,76,Occitanie
12110,GOLINHAC,12140,GOLINHAC,,44.5959231172,2.58644592091,110,,Golinhac,Golinhac,12,Aveyron,76,Occitanie
12111,GOUTRENS,12390,GOUTRENS,,44.4387236873,2.36634361552,111,,Goutrens,Goutrens,12,Aveyron,76,Occitanie
12113,GRAMOND,12160,GRAMOND,,44.2633838194,2.3681220954,113,,Gramond,Gramond,12,Aveyron,76,Occitanie
12115,L HOSPITALET DU LARZAC,12230,L HOSPITALET DU LARZAC,,43.9737680653,3.20839520735,115,L',Hospitalet-du-Larzac,L'Hospitalet-du-Larzac,12,Aveyron,76,Occitanie
12116,HUPARLAC,12460,HUPARLAC,,44.7114576875,2.75356213678,116,,Huparlac,Huparlac,12,Aveyron,76,Occitanie
12118,LACROIX BARREZ,12600,LACROIX BARREZ,,44.7740969163,2.64249748711,118,,Lacroix-Barrez,Lacroix-Barrez,12,Aveyron,76,Occitanie
12119,LAGUIOLE,12210,LAGUIOLE,,44.6942493054,2.86714579983,119,,Laguiole,Laguiole,12,Aveyron,76,Occitanie
12120,LAISSAC SEVERAC L EGLISE,12310,LAISSAC SEVERAC L EGLISE,,44.3644570513,2.81804424075,120,,Laissac-Sévérac l'Église,Laissac-Sévérac l'Église,12,Aveyron,76,Occitanie
12120,LAISSAC SEVERAC L EGLISE,12310,LAISSAC SEVERAC L EGLISE,SEVERAC L EGLISE,44.3644570513,2.81804424075,120,,Laissac-Sévérac l'Église,Laissac-Sévérac l'Église,12,Aveyron,76,Occitanie
12121,LANUEJOULS,12350,LANUEJOULS,,44.4236494726,2.16109197679,121,,Lanuéjouls,Lanuéjouls,12,Aveyron,76,Occitanie
12122,LAPANOUSE DE CERNON,12230,LAPANOUSE DE CERNON,,44.0062686145,3.09638242965,122,,Lapanouse-de-Cernon,Lapanouse-de-Cernon,12,Aveyron,76,Occitanie
12124,LASSOUTS,12500,LASSOUTS,,44.4866947453,2.85455705942,124,,Lassouts,Lassouts,12,Aveyron,76,Occitanie
12125,LAVAL ROQUECEZIERE,12380,LAVAL ROQUECEZIERE,,43.8122894492,2.63515019916,125,,Laval-Roquecezière,Laval-Roquecezière,12,Aveyron,76,Occitanie
12127,LEDERGUES,12170,LEDERGUES,,44.0802539644,2.4399944588,127,,Lédergues,Lédergues,12,Aveyron,76,Occitanie
12128,LESCURE JAOUL,12440,LESCURE JAOUL,,44.225497867,2.1468963703,128,,Lescure-Jaoul,Lescure-Jaoul,12,Aveyron,76,Occitanie
12129,LESTRADE ET THOUELS,12430,LESTRADE ET THOUELS,,44.054162276,2.64776017353,129,,Lestrade-et-Thouels,Lestrade-et-Thouels,12,Aveyron,76,Occitanie
12130,LIVINHAC LE HAUT,12300,LIVINHAC LE HAUT,,44.5972432577,2.21621082428,130,,Livinhac-le-Haut,Livinhac-le-Haut,12,Aveyron,76,Occitanie
12131,LA LOUBIERE,12740,LA LOUBIERE,,44.3909930617,2.65068486955,131,La,Loubière,La Loubière,12,Aveyron,76,Occitanie
12133,LUC LA PRIMAUBE,12450,LUC LA PRIMAUBE,,44.3056658484,2.5364516694,133,,Luc-la-Primaube,Luc-la-Primaube,12,Aveyron,76,Occitanie
12133,LUC LA PRIMAUBE,12450,LUC LA PRIMAUBE,LA PRIMAUBE,44.3056658484,2.5364516694,133,,Luc-la-Primaube,Luc-la-Primaube,12,Aveyron,76,Occitanie
12134,LUGAN,12220,LUGAN,,44.4835018832,2.25588264756,134,,Lugan,Lugan,12,Aveyron,76,Occitanie
12135,LUNAC,12270,LUNAC,,44.2405098268,2.10038614517,135,,Lunac,Lunac,12,Aveyron,76,Occitanie
12136,MALEVILLE,12350,MALEVILLE,,44.3999943584,2.10361790019,136,,Maleville,Maleville,12,Aveyron,76,Occitanie
12137,MANHAC,12160,MANHAC,,44.2605706406,2.4781869588,137,,Manhac,Manhac,12,Aveyron,76,Occitanie
12138,MARCILLAC VALLON,12330,MARCILLAC VALLON,,44.4772361351,2.46487970585,138,,Marcillac-Vallon,Marcillac-Vallon,12,Aveyron,76,Occitanie
12139,MARNHAGUES ET LATOUR,12540,MARNHAGUES ET LATOUR,,43.8794497161,3.04168349472,139,,Marnhagues-et-Latour,Marnhagues-et-Latour,12,Aveyron,76,Occitanie
12140,MARTIEL,12200,MARTIEL,,44.3811674619,1.91298331714,140,,Martiel,Martiel,12,Aveyron,76,Occitanie
12141,MARTRIN,12550,MARTRIN,,43.9287986498,2.63394013332,141,,Martrin,Martrin,12,Aveyron,76,Occitanie
12142,MAYRAN,12390,MAYRAN,,44.3890735468,2.37196392635,142,,Mayran,Mayran,12,Aveyron,76,Occitanie
12143,MELAGUES,12360,MELAGUES,,43.7281707219,3.01706208309,143,,Mélagues,Mélagues,12,Aveyron,76,Occitanie
12144,MELJAC,12120,MELJAC,,44.133704443,2.43387018423,144,,Meljac,Meljac,12,Aveyron,76,Occitanie
12145,MILLAU,12100,MILLAU,,44.0976252203,3.11705384129,145,,Millau,Millau,12,Aveyron,76,Occitanie
12146,LE MONASTERE,12000,LE MONASTERE,,44.331592843,2.5872623881,146,Le,Monastère,Le Monastère,12,Aveyron,76,Occitanie
12147,MONTAGNOL,12360,MONTAGNOL,,43.831157935,3.02461521529,147,,Montagnol,Montagnol,12,Aveyron,76,Occitanie
12147,MONTAGNOL,12360,MONTAGNOL,CENOMES,43.831157935,3.02461521529,147,,Montagnol,Montagnol,12,Aveyron,76,Occitanie
12148,MONTBAZENS,12220,MONTBAZENS,,44.476808221,2.21289087598,148,,Montbazens,Montbazens,12,Aveyron,76,Occitanie
12149,MONTCLAR,12550,MONTCLAR,,43.9795757997,2.63093615826,149,,Montclar,Montclar,12,Aveyron,76,Occitanie
12150,MONTEILS,12200,MONTEILS,,44.2654912574,1.98762525429,150,,Monteils,Monteils,12,Aveyron,76,Occitanie
12151,MONTEZIC,12460,MONTEZIC,,44.7168656794,2.64085756114,151,,Montézic,Montézic,12,Aveyron,76,Occitanie
12152,MONTFRANC,12380,MONTFRANC,,43.8408623643,2.57981205316,152,,Montfranc,Montfranc,12,Aveyron,76,Occitanie
12153,MONTJAUX,12490,MONTJAUX,,44.100139359,2.90415727953,153,,Montjaux,Montjaux,12,Aveyron,76,Occitanie
12154,MONTLAUR,12400,MONTLAUR,,43.8651798638,2.83361605924,154,,Montlaur,Montlaur,12,Aveyron,76,Occitanie
12155,FONDAMENTE,12540,FONDAMENTE,,43.8617533926,3.11468710973,155,,Fondamente,Fondamente,12,Aveyron,76,Occitanie
12156,MONTPEYROUX,12210,MONTPEYROUX,,44.6349727499,2.78247380179,156,,Montpeyroux,Montpeyroux,12,Aveyron,76,Occitanie
12157,MONTROZIER,12630,MONTROZIER,,44.394981764,2.72127812252,157,,Montrozier,Montrozier,12,Aveyron,76,Occitanie
12157,MONTROZIER,12630,MONTROZIER,GAGES,44.394981764,2.72127812252,157,,Montrozier,Montrozier,12,Aveyron,76,Occitanie
12158,MONTSALES,12260,MONTSALES,,44.4857721529,1.97611373855,158,,Montsalès,Montsalès,12,Aveyron,76,Occitanie
12159,MORLHON LE HAUT,12200,MORLHON LE HAUT,,44.3218697288,2.08182819744,159,,Morlhon-le-Haut,Morlhon-le-Haut,12,Aveyron,76,Occitanie
12160,MOSTUEJOULS,12720,MOSTUEJOULS,,44.2252486217,3.18271814024,160,,Mostuéjouls,Mostuéjouls,12,Aveyron,76,Occitanie
12161,MOURET,12330,MOURET,,44.5143822131,2.52108945379,161,,Mouret,Mouret,12,Aveyron,76,Occitanie
12162,MOYRAZES,12160,MOYRAZES,,44.3275804769,2.42736716891,162,,Moyrazès,Moyrazès,12,Aveyron,76,Occitanie
12163,MURASSON,12370,MURASSON,,43.7612201142,2.7618005022,163,,Murasson,Murasson,12,Aveyron,76,Occitanie
12164,MUR DE BARREZ,12600,MUR DE BARREZ,,44.8759902067,2.68059365877,164,,Mur-de-Barrez,Mur-de-Barrez,12,Aveyron,76,Occitanie
12165,MURET LE CHATEAU,12330,MURET LE CHATEAU,,44.4957128112,2.57147635051,165,,Muret-le-Château,Muret-le-Château,12,Aveyron,76,Occitanie
12166,MUROLS,12600,MUROLS,,44.7608204119,2.5775403488,166,,Murols,Murols,12,Aveyron,76,Occitanie
12167,NAJAC,12270,NAJAC,,44.211295506,1.96305922109,167,,Najac,Najac,12,Aveyron,76,Occitanie
12168,NANT,12230,NANT,,44.0206117427,3.28565334346,168,,Nant,Nant,12,Aveyron,76,Occitanie
12169,NAUCELLE,12800,NAUCELLE,,44.1898575403,2.34366368782,169,,Naucelle,Naucelle,12,Aveyron,76,Occitanie
12170,NAUSSAC,12700,NAUSSAC,,44.5070770958,2.09742065884,170,,Naussac,Naussac,12,Aveyron,76,Occitanie
12171,NAUVIALE,12330,NAUVIALE,,44.5133117554,2.43273501132,171,,Nauviale,Nauviale,12,Aveyron,76,Occitanie
12172,LE NAYRAC,12190,LE NAYRAC,,44.605562454,2.66952444111,172,Le,Nayrac,Le Nayrac,12,Aveyron,76,Occitanie
12174,OLEMPS,12510,OLEMPS,,44.338471851,2.54774198749,174,,Olemps,Olemps,12,Aveyron,76,Occitanie
12175,OLS ET RINHODES,12260,OLS ET RINHODES,,44.4615013525,1.94693062235,175,,Ols-et-Rinhodes,Ols-et-Rinhodes,12,Aveyron,76,Occitanie
12176,ONET LE CHATEAU,12000,ONET LE CHATEAU,,44.3825530939,2.56000835023,176,,Onet-le-Château,Onet-le-Château,12,Aveyron,76,Occitanie
12176,ONET LE CHATEAU,12850,ONET LE CHATEAU,,44.3825530939,2.56000835023,176,,Onet-le-Château,Onet-le-Château,12,Aveyron,76,Occitanie
12176,ONET LE CHATEAU,12850,ONET LE CHATEAU,QUATRE SAISONS,44.3825530939,2.56000835023,176,,Onet-le-Château,Onet-le-Château,12,Aveyron,76,Occitanie
12177,PALMAS D AVEYRON,12310,PALMAS D AVEYRON,,44.4009437729,2.84225329377,177,,Palmas d'Aveyron,Palmas d'Aveyron,12,Aveyron,76,Occitanie
12177,PALMAS D AVEYRON,12310,PALMAS D AVEYRON,COUSSERGUES,44.4009437729,2.84225329377,177,,Palmas d'Aveyron,Palmas d'Aveyron,12,Aveyron,76,Occitanie
12177,PALMAS D AVEYRON,12340,PALMAS D AVEYRON,CRUEJOULS,44.4009437729,2.84225329377,177,,Palmas d'Aveyron,Palmas d'Aveyron,12,Aveyron,76,Occitanie
12178,PAULHE,12520,PAULHE,,44.1486388779,3.11148254471,178,,Paulhe,Paulhe,12,Aveyron,76,Occitanie
12179,PEUX ET COUFFOULEUX,12360,PEUX ET COUFFOULEUX,,43.7634161386,2.8805204264,179,,Peux-et-Couffouleux,Peux-et-Couffouleux,12,Aveyron,76,Occitanie
12180,PEYRELEAU,12720,PEYRELEAU,,44.1757756277,3.20495893117,180,,Peyreleau,Peyreleau,12,Aveyron,76,Occitanie
12181,PEYRUSSE LE ROC,12220,PEYRUSSE LE ROC,,44.5026320672,2.13437459711,181,,Peyrusse-le-Roc,Peyrusse-le-Roc,12,Aveyron,76,Occitanie
12182,PIERREFICHE,12130,PIERREFICHE,,44.4338467479,2.92167553343,182,,Pierrefiche,Pierrefiche,12,Aveyron,76,Occitanie
12183,PLAISANCE,12550,PLAISANCE,,43.929841907,2.56266384044,183,,Plaisance,Plaisance,12,Aveyron,76,Occitanie
12184,POMAYROLS,12130,POMAYROLS,,44.4867594811,3.05866574301,184,,Pomayrols,Pomayrols,12,Aveyron,76,Occitanie
12185,PONT DE SALARS,12290,PONT DE SALARS,,44.277717255,2.70931526263,185,,Pont-de-Salars,Pont-de-Salars,12,Aveyron,76,Occitanie
12186,POUSTHOMY,12380,POUSTHOMY,,43.8560294243,2.59842075923,186,,Pousthomy,Pousthomy,12,Aveyron,76,Occitanie
12187,PRADES D AUBRAC,12470,PRADES D AUBRAC,,44.5418402145,2.97032674456,187,,Prades-d'Aubrac,Prades-d'Aubrac,12,Aveyron,76,Occitanie
12188,PRADES SALARS,12290,PRADES SALARS,,44.2493343798,2.7929953835,188,,Prades-Salars,Prades-Salars,12,Aveyron,76,Occitanie
12189,PRADINAS,12240,PRADINAS,,44.2472252922,2.26867905755,189,,Pradinas,Pradinas,12,Aveyron,76,Occitanie
12190,PREVINQUIERES,12350,PREVINQUIERES,,44.3659665402,2.24437860842,190,,Prévinquières,Prévinquières,12,Aveyron,76,Occitanie
12191,PRIVEZAC,12350,PRIVEZAC,,44.4103475612,2.19453209894,191,,Privezac,Privezac,12,Aveyron,76,Occitanie
12192,MOUNES PROHENCOUX,12370,MOUNES PROHENCOUX,,43.7982858454,2.81918000593,192,,Mounes-Prohencoux,Mounes-Prohencoux,12,Aveyron,76,Occitanie
12193,PRUINES,12320,PRUINES,,44.534819715,2.4875783018,193,,Pruines,Pruines,12,Aveyron,76,Occitanie
12194,QUINS,12800,QUINS,,44.2295906034,2.37852465105,194,,Quins,Quins,12,Aveyron,76,Occitanie
12194,QUINS,12800,QUINS,LA MOTHE,44.2295906034,2.37852465105,194,,Quins,Quins,12,Aveyron,76,Occitanie
12195,REBOURGUIL,12400,REBOURGUIL,,43.8850659251,2.75663967565,195,,Rebourguil,Rebourguil,12,Aveyron,76,Occitanie
12197,REQUISTA,12170,REQUISTA,,44.031961026,2.54899148777,197,,Réquista,Réquista,12,Aveyron,76,Occitanie
12198,RIEUPEYROUX,12240,RIEUPEYROUX,,44.3085754076,2.22948755681,198,,Rieupeyroux,Rieupeyroux,12,Aveyron,76,Occitanie
12199,RIGNAC,12390,RIGNAC,,44.4038329146,2.29228871208,199,,Rignac,Rignac,12,Aveyron,76,Occitanie
12200,RIVIERE SUR TARN,12640,RIVIERE SUR TARN,,44.2108724191,3.12758575217,200,,Rivière-sur-Tarn,Rivière-sur-Tarn,12,Aveyron,76,Occitanie
12200,RIVIERE SUR TARN,12640,RIVIERE SUR TARN,BOYNE,44.2108724191,3.12758575217,200,,Rivière-sur-Tarn,Rivière-sur-Tarn,12,Aveyron,76,Occitanie
12201,RODELLE,12340,RODELLE,,44.4813052355,2.6223380773,201,,Rodelle,Rodelle,12,Aveyron,76,Occitanie
12202,RODEZ,12000,RODEZ,,44.3582426254,2.5672793892,202,,Rodez,Rodez,12,Aveyron,76,Occitanie
12203,ROQUEFORT SUR SOULZON,12250,ROQUEFORT SUR SOULZON,,43.9706930615,2.98176110631,203,,Roquefort-sur-Soulzon,Roquefort-sur-Soulzon,12,Aveyron,76,Occitanie
12204,LA ROQUE STE MARGUERITE,12100,LA ROQUE STE MARGUERITE,,44.1093444691,3.24200220208,204,La,Roque-Sainte-Marguerite,La Roque-Sainte-Marguerite,12,Aveyron,76,Occitanie
12205,LA ROUQUETTE,12200,LA ROUQUETTE,,44.3097319748,1.9747853664,205,La,Rouquette,La Rouquette,12,Aveyron,76,Occitanie
12206,ROUSSENNAC,12220,ROUSSENNAC,,44.4475523507,2.25412829032,206,,Roussennac,Roussennac,12,Aveyron,76,Occitanie
12207,RULLAC ST CIRQ,12120,RULLAC ST CIRQ,,44.1192120935,2.47693953343,207,,Rullac-Saint-Cirq,Rullac-Saint-Cirq,12,Aveyron,76,Occitanie
12208,ST AFFRIQUE,12400,ST AFFRIQUE,,43.9656694471,2.86653125625,208,,Saint-Affrique,Saint-Affrique,12,Aveyron,76,Occitanie
12209,ST AMANS DES COTS,12460,ST AMANS DES COTS,,44.6868560667,2.69135482513,209,,Saint-Amans-des-Cots,Saint-Amans-des-Cots,12,Aveyron,76,Occitanie
12210,ST ANDRE DE NAJAC,12270,ST ANDRE DE NAJAC,,44.1791526825,2.02109386002,210,,Saint-André-de-Najac,Saint-André-de-Najac,12,Aveyron,76,Occitanie
12211,ST ANDRE DE VEZINES,12720,ST ANDRE DE VEZINES,,44.1476911332,3.27124677006,211,,Saint-André-de-Vézines,Saint-André-de-Vézines,12,Aveyron,76,Occitanie
12212,ST BEAULIZE,12540,ST BEAULIZE,,43.9061421509,3.10001497795,212,,Saint-Beaulize,Saint-Beaulize,12,Aveyron,76,Occitanie
12213,ST BEAUZELY,12620,ST BEAUZELY,,44.1692617514,2.96666926692,213,,Saint-Beauzély,Saint-Beauzély,12,Aveyron,76,Occitanie
12214,ST CHELY D AUBRAC,12470,ST CHELY D AUBRAC,,44.5918935634,2.94672559934,214,,Saint-Chély-d'Aubrac,Saint-Chély-d'Aubrac,12,Aveyron,76,Occitanie
12214,ST CHELY D AUBRAC,12470,ST CHELY D AUBRAC,AUBRAC,44.5918935634,2.94672559934,214,,Saint-Chély-d'Aubrac,Saint-Chély-d'Aubrac,12,Aveyron,76,Occitanie
12215,ST CHRISTOPHE VALLON,12330,ST CHRISTOPHE VALLON,,44.4799129535,2.39660455917,215,,Saint-Christophe-Vallon,Saint-Christophe-Vallon,12,Aveyron,76,Occitanie
12216,ST COME D OLT,12500,ST COME D OLT,,44.5314215162,2.82190819996,216,,Saint-Côme-d'Olt,Saint-Côme-d'Olt,12,Aveyron,76,Occitanie
12217,STE CROIX,12260,STE CROIX,,44.4143206609,1.96803908132,217,,Sainte-Croix,Sainte-Croix,12,Aveyron,76,Occitanie
12219,STE EULALIE D OLT,12130,STE EULALIE D OLT,,44.4661013084,2.91950653018,219,,Sainte-Eulalie-d'Olt,Sainte-Eulalie-d'Olt,12,Aveyron,76,Occitanie
12220,STE EULALIE DE CERNON,12230,STE EULALIE DE CERNON,,43.960884427,3.13884690066,220,,Sainte-Eulalie-de-Cernon,Sainte-Eulalie-de-Cernon,12,Aveyron,76,Occitanie
12221,ST FELIX DE LUNEL,12320,ST FELIX DE LUNEL,,44.5645678324,2.51363543065,221,,Saint-Félix-de-Lunel,Saint-Félix-de-Lunel,12,Aveyron,76,Occitanie
12222,ST FELIX DE SORGUES,12400,ST FELIX DE SORGUES,,43.8825601072,2.98664718926,222,,Saint-Félix-de-Sorgues,Saint-Félix-de-Sorgues,12,Aveyron,76,Occitanie
12223,ARGENCES EN AUBRAC,12210,ARGENCES EN AUBRAC,ALPUECH,44.8003905234,2.7434750028,223,,Argences en Aubrac,Argences en Aubrac,12,Aveyron,76,Occitanie
12223,ARGENCES EN AUBRAC,12210,ARGENCES EN AUBRAC,LACALM,44.8003905234,2.7434750028,223,,Argences en Aubrac,Argences en Aubrac,12,Aveyron,76,Occitanie
12223,ARGENCES EN AUBRAC,12210,ARGENCES EN AUBRAC,LA TERRISSE,44.8003905234,2.7434750028,223,,Argences en Aubrac,Argences en Aubrac,12,Aveyron,76,Occitanie
12223,ARGENCES EN AUBRAC,12420,ARGENCES EN AUBRAC,GRAISSAC,44.8003905234,2.7434750028,223,,Argences en Aubrac,Argences en Aubrac,12,Aveyron,76,Occitanie
12223,ARGENCES EN AUBRAC,12420,ARGENCES EN AUBRAC,STE GENEVIEVE SUR ARGENCE,44.8003905234,2.7434750028,223,,Argences en Aubrac,Argences en Aubrac,12,Aveyron,76,Occitanie
12223,ARGENCES EN AUBRAC,12420,ARGENCES EN AUBRAC,VITRAC EN VIADENE,44.8003905234,2.7434750028,223,,Argences en Aubrac,Argences en Aubrac,12,Aveyron,76,Occitanie
12224,ST GENIEZ D OLT ET D AUBRAC,12130,ST GENIEZ D OLT ET D AUBRAC,,44.4732674724,2.98156818185,224,,Saint Geniez d'Olt et d'Aubrac,Saint Geniez d'Olt et d'Aubrac,12,Aveyron,76,Occitanie
12224,ST GENIEZ D OLT ET D AUBRAC,12130,ST GENIEZ D OLT ET D AUBRAC,AURELLE VERLAC,44.4732674724,2.98156818185,224,,Saint Geniez d'Olt et d'Aubrac,Saint Geniez d'Olt et d'Aubrac,12,Aveyron,76,Occitanie
12225,ST GEORGES DE LUZENCON,12100,ST GEORGES DE LUZENCON,,44.0565556182,2.98714381525,225,,Saint-Georges-de-Luzençon,Saint-Georges-de-Luzençon,12,Aveyron,76,Occitanie
12226,ST HIPPOLYTE,12140,ST HIPPOLYTE,,44.7257623817,2.58415334807,226,,Saint-Hippolyte,Saint-Hippolyte,12,Aveyron,76,Occitanie
12226,ST HIPPOLYTE,12140,ST HIPPOLYTE,PONS,44.7257623817,2.58415334807,226,,Saint-Hippolyte,Saint-Hippolyte,12,Aveyron,76,Occitanie
12227,ST IGEST,12260,ST IGEST,,44.4455113511,2.09133013053,227,,Saint-Igest,Saint-Igest,12,Aveyron,76,Occitanie
12228,ST IZAIRE,12480,ST IZAIRE,,43.9709276715,2.70338873518,228,,Saint-Izaire,Saint-Izaire,12,Aveyron,76,Occitanie
12229,ST JEAN D ALCAPIES,12250,ST JEAN D ALCAPIES,,43.9486517779,2.96702587366,229,,Saint-Jean-d'Alcapiès,Saint-Jean-d'Alcapiès,12,Aveyron,76,Occitanie
12230,ST JEAN DELNOUS,12170,ST JEAN DELNOUS,,44.0414081,2.49245464315,230,,Saint-Jean-Delnous,Saint-Jean-Delnous,12,Aveyron,76,Occitanie
12231,ST JEAN DU BRUEL,12230,ST JEAN DU BRUEL,,44.0262087023,3.38224826789,231,,Saint-Jean-du-Bruel,Saint-Jean-du-Bruel,12,Aveyron,76,Occitanie
12232,ST JEAN ET ST PAUL,12250,ST JEAN ET ST PAUL,,43.9289280982,3.02921567332,232,,Saint-Jean-et-Saint-Paul,Saint-Jean-et-Saint-Paul,12,Aveyron,76,Occitanie
12233,ST JUERY,12550,ST JUERY,,43.9242584641,2.69629046616,233,,Saint-Juéry,Saint-Juéry,12,Aveyron,76,Occitanie
12234,STE JULIETTE SUR VIAUR,12120,STE JULIETTE SUR VIAUR,,44.2186396438,2.50113925253,234,,Sainte-Juliette-sur-Viaur,Sainte-Juliette-sur-Viaur,12,Aveyron,76,Occitanie
12235,ST JUST SUR VIAUR,12170,ST JUST SUR VIAUR,,44.1200489541,2.37849533385,235,,Saint-Just-sur-Viaur,Saint-Just-sur-Viaur,12,Aveyron,76,Occitanie
12235,ST JUST SUR VIAUR,12800,ST JUST SUR VIAUR,,44.1200489541,2.37849533385,235,,Saint-Just-sur-Viaur,Saint-Just-sur-Viaur,12,Aveyron,76,Occitanie
12236,ST LAURENT DE LEVEZOU,12620,ST LAURENT DE LEVEZOU,,44.2089764028,2.93837029184,236,,Saint-Laurent-de-Lévézou,Saint-Laurent-de-Lévézou,12,Aveyron,76,Occitanie
12237,ST LAURENT D OLT,12560,ST LAURENT D OLT,,44.4481544099,3.10140451864,237,,Saint-Laurent-d'Olt,Saint-Laurent-d'Olt,12,Aveyron,76,Occitanie
12238,ST LEONS,12780,ST LEONS,,44.2231129382,2.98202907949,238,,Saint-Léons,Saint-Léons,12,Aveyron,76,Occitanie
12239,ST MARTIN DE LENNE,12130,ST MARTIN DE LENNE,,44.4245376835,2.96547959933,239,,Saint-Martin-de-Lenne,Saint-Martin-de-Lenne,12,Aveyron,76,Occitanie
12240,ST PARTHEM,12300,ST PARTHEM,,44.6329765505,2.29151431858,240,,Saint-Parthem,Saint-Parthem,12,Aveyron,76,Occitanie
12241,STE RADEGONDE,12850,STE RADEGONDE,,44.3313046455,2.63272943848,241,,Sainte-Radegonde,Sainte-Radegonde,12,Aveyron,76,Occitanie
12242,ST REMY,12200,ST REMY,,44.3952055876,2.04173574254,242,,Saint-Rémy,Saint-Rémy,12,Aveyron,76,Occitanie
12243,ST ROME DE CERNON,12490,ST ROME DE CERNON,,44.0092815524,2.98362792023,243,,Saint-Rome-de-Cernon,Saint-Rome-de-Cernon,12,Aveyron,76,Occitanie
12244,ST ROME DE TARN,12490,ST ROME DE TARN,,44.0328970436,2.8904868402,244,,Saint-Rome-de-Tarn,Saint-Rome-de-Tarn,12,Aveyron,76,Occitanie
12246,ST SANTIN,12300,ST SANTIN,,44.6433670294,2.25787660358,246,,Saint-Santin,Saint-Santin,12,Aveyron,76,Occitanie
12247,ST SATURNIN DE LENNE,12560,ST SATURNIN DE LENNE,,44.409127962,3.02218493838,247,,Saint-Saturnin-de-Lenne,Saint-Saturnin-de-Lenne,12,Aveyron,76,Occitanie
12248,ST SERNIN SUR RANCE,12380,ST SERNIN SUR RANCE,,43.8788015376,2.62311158409,248,,Saint-Sernin-sur-Rance,Saint-Sernin-sur-Rance,12,Aveyron,76,Occitanie
12249,ST SEVER DU MOUSTIER,12370,ST SEVER DU MOUSTIER,,43.7720630211,2.69278651558,249,,Saint-Sever-du-Moustier,Saint-Sever-du-Moustier,12,Aveyron,76,Occitanie
12250,ST SYMPHORIEN DE THENIERES,12460,ST SYMPHORIEN DE THENIERES,,44.7403646791,2.70823948666,250,,Saint-Symphorien-de-Thénières,Saint-Symphorien-de-Thénières,12,Aveyron,76,Occitanie
12250,ST SYMPHORIEN DE THENIERES,12460,ST SYMPHORIEN DE THENIERES,ST GERVAIS,44.7403646791,2.70823948666,250,,Saint-Symphorien-de-Thénières,Saint-Symphorien-de-Thénières,12,Aveyron,76,Occitanie
12251,ST VICTOR ET MELVIEU,12400,ST VICTOR ET MELVIEU,,44.0507837492,2.81021922503,251,,Saint-Victor-et-Melvieu,Saint-Victor-et-Melvieu,12,Aveyron,76,Occitanie
12251,ST VICTOR ET MELVIEU,12400,ST VICTOR ET MELVIEU,MELVIEU,44.0507837492,2.81021922503,251,,Saint-Victor-et-Melvieu,Saint-Victor-et-Melvieu,12,Aveyron,76,Occitanie
12252,SALLES COURBATIES,12260,SALLES COURBATIES,,44.4813598633,2.08513535949,252,,Salles-Courbatiès,Salles-Courbatiès,12,Aveyron,76,Occitanie
12253,SALLES CURAN,12410,SALLES CURAN,,44.159383352,2.78625740284,253,,Salles-Curan,Salles-Curan,12,Aveyron,76,Occitanie
12254,SALLES LA SOURCE,12330,SALLES LA SOURCE,,44.4437884405,2.5250957268,254,,Salles-la-Source,Salles-la-Source,12,Aveyron,76,Occitanie
12255,SALMIECH,12120,SALMIECH,,44.1937884405,2.58717278711,255,,Salmiech,Salmiech,12,Aveyron,76,Occitanie
12256,SALVAGNAC CAJARC,12260,SALVAGNAC CAJARC,,44.4543375215,1.87232547648,256,,Salvagnac-Cajarc,Salvagnac-Cajarc,12,Aveyron,76,Occitanie
12257,CAUSSE ET DIEGE,12700,CAUSSE ET DIEGE,,44.5282954974,2.03482919718,257,,Causse-et-Diège,Causse-et-Diège,12,Aveyron,76,Occitanie
12257,CAUSSE ET DIEGE,12700,CAUSSE ET DIEGE,GELLE,44.5282954974,2.03482919718,257,,Causse-et-Diège,Causse-et-Diège,12,Aveyron,76,Occitanie
12257,CAUSSE ET DIEGE,12700,CAUSSE ET DIEGE,LOUPIAC,44.5282954974,2.03482919718,257,,Causse-et-Diège,Causse-et-Diège,12,Aveyron,76,Occitanie
12258,LA SALVETAT PEYRALES,12440,LA SALVETAT PEYRALES,,44.2210453595,2.19673523902,258,La,Salvetat-Peyralès,La Salvetat-Peyralès,12,Aveyron,76,Occitanie
12259,SANVENSA,12200,SANVENSA,,44.2844997959,2.04052425454,259,,Sanvensa,Sanvensa,12,Aveyron,76,Occitanie
12260,SAUCLIERES,12230,SAUCLIERES,,43.9797470303,3.37516953256,260,,Sauclières,Sauclières,12,Aveyron,76,Occitanie
12261,SAUJAC,12260,SAUJAC,,44.4777699225,1.90179850313,261,,Saujac,Saujac,12,Aveyron,76,Occitanie
12262,SAUVETERRE DE ROUERGUE,12800,SAUVETERRE DE ROUERGUE,,44.2478234048,2.31504773118,262,,Sauveterre-de-Rouergue,Sauveterre-de-Rouergue,12,Aveyron,76,Occitanie
12263,SAVIGNAC,12200,SAVIGNAC,,44.3599482177,1.95859629036,263,,Savignac,Savignac,12,Aveyron,76,Occitanie
12264,SEBAZAC CONCOURES,12740,SEBAZAC CONCOURES,,44.4232783345,2.6111835082,264,,Sébazac-Concourès,Sébazac-Concourès,12,Aveyron,76,Occitanie
12265,SEBRAZAC,12190,SEBRAZAC,,44.537422675,2.65492320608,265,,Sébrazac,Sébrazac,12,Aveyron,76,Occitanie
12266,SEGUR,12290,SEGUR,,44.2904669851,2.8437834647,266,,Ségur,Ségur,12,Aveyron,76,Occitanie
12267,LA SELVE,12170,LA SELVE,,44.1061917162,2.53638704269,267,La,Selve,La Selve,12,Aveyron,76,Occitanie
12268,SENERGUES,12320,SENERGUES,,44.6062228544,2.46311277374,268,,Sénergues,Sénergues,12,Aveyron,76,Occitanie
12269,LA SERRE,12380,LA SERRE,,43.8936026764,2.65558670644,269,La,Serre,La Serre,12,Aveyron,76,Occitanie
12270,SEVERAC D AVEYRON,12150,SEVERAC D AVEYRON,,44.3000638764,3.08915100601,270,,Sévérac d'Aveyron,Sévérac d'Aveyron,12,Aveyron,76,Occitanie
12270,SEVERAC D AVEYRON,12150,SEVERAC D AVEYRON,BUZEINS,44.3000638764,3.08915100601,270,,Sévérac d'Aveyron,Sévérac d'Aveyron,12,Aveyron,76,Occitanie
12270,SEVERAC D AVEYRON,12150,SEVERAC D AVEYRON,LAPANOUSE,44.3000638764,3.08915100601,270,,Sévérac d'Aveyron,Sévérac d'Aveyron,12,Aveyron,76,Occitanie
12270,SEVERAC D AVEYRON,12150,SEVERAC D AVEYRON,LAVERNHE,44.3000638764,3.08915100601,270,,Sévérac d'Aveyron,Sévérac d'Aveyron,12,Aveyron,76,Occitanie
12270,SEVERAC D AVEYRON,12150,SEVERAC D AVEYRON,RECOULES PREVINQUIERES,44.3000638764,3.08915100601,270,,Sévérac d'Aveyron,Sévérac d'Aveyron,12,Aveyron,76,Occitanie
12272,SONNAC,12700,SONNAC,,44.5381449172,2.11452059209,272,,Sonnac,Sonnac,12,Aveyron,76,Occitanie
12273,SOULAGES BONNEVAL,12210,SOULAGES BONNEVAL,,44.6772152529,2.77442908262,273,,Soulages-Bonneval,Soulages-Bonneval,12,Aveyron,76,Occitanie
12274,SYLVANES,12360,SYLVANES,,43.8251925157,2.95288652879,274,,Sylvanès,Sylvanès,12,Aveyron,76,Occitanie
12275,TAURIAC DE CAMARES,12360,TAURIAC DE CAMARES,,43.7765492565,3.03077499172,275,,Tauriac-de-Camarès,Tauriac-de-Camarès,12,Aveyron,76,Occitanie
12276,TAURIAC DE NAUCELLE,12800,TAURIAC DE NAUCELLE,,44.1453272598,2.32325860909,276,,Tauriac-de-Naucelle,Tauriac-de-Naucelle,12,Aveyron,76,Occitanie
12277,TAUSSAC,12600,TAUSSAC,,44.8248753514,2.63587650055,277,,Taussac,Taussac,12,Aveyron,76,Occitanie
12278,TAYRAC,12440,TAYRAC,,44.2036765147,2.24341011271,278,,Tayrac,Tayrac,12,Aveyron,76,Occitanie
12280,THERONDELS,12600,THERONDELS,,44.8944584471,2.7419390961,280,,Thérondels,Thérondels,12,Aveyron,76,Occitanie
12281,TOULONJAC,12200,TOULONJAC,,44.380429669,1.99865710824,281,,Toulonjac,Toulonjac,12,Aveyron,76,Occitanie
12282,TOURNEMIRE,12250,TOURNEMIRE,,43.9686808628,3.02681795473,282,,Tournemire,Tournemire,12,Aveyron,76,Occitanie
12283,TREMOUILLES,12290,TREMOUILLES,,44.2395334463,2.63787503677,283,,Trémouilles,Trémouilles,12,Aveyron,76,Occitanie
12284,LE TRUEL,12430,LE TRUEL,,44.0536064205,2.74751943168,284,Le,Truel,Le Truel,12,Aveyron,76,Occitanie
12286,VABRES L ABBAYE,12400,VABRES L ABBAYE,,43.9213497351,2.81975476984,286,,Vabres-l'Abbaye,Vabres-l'Abbaye,12,Aveyron,76,Occitanie
12287,VAILHOURLES,12200,VAILHOURLES,,44.3082129467,1.90937722563,287,,Vailhourles,Vailhourles,12,Aveyron,76,Occitanie
12288,VALADY,12330,VALADY,,44.4471578876,2.44255701566,288,,Valady,Valady,12,Aveyron,76,Occitanie
12288,VALADY,12330,VALADY,NUCES,44.4471578876,2.44255701566,288,,Valady,Valady,12,Aveyron,76,Occitanie
12289,VALZERGUES,12220,VALZERGUES,,44.5055186879,2.22147187127,289,,Valzergues,Valzergues,12,Aveyron,76,Occitanie
12290,VAUREILLES,12220,VAUREILLES,,44.4533693497,2.18642390603,290,,Vaureilles,Vaureilles,12,Aveyron,76,Occitanie
12291,VERRIERES,12520,VERRIERES,,44.2168374801,3.05097559285,291,,Verrières,Verrières,12,Aveyron,76,Occitanie
12292,VERSOLS ET LAPEYRE,12400,VERSOLS ET LAPEYRE,,43.8998471678,2.93585871543,292,,Versols-et-Lapeyre,Versols-et-Lapeyre,12,Aveyron,76,Occitanie
12293,VEYREAU,12720,VEYREAU,,44.1818960497,3.30715443972,293,,Veyreau,Veyreau,12,Aveyron,76,Occitanie
12294,VEZINS DE LEVEZOU,12780,VEZINS DE LEVEZOU,,44.2679981187,2.92575800141,294,,Vézins-de-Lévézou,Vézins-de-Lévézou,12,Aveyron,76,Occitanie
12295,VIALA DU PAS DE JAUX,12250,VIALA DU PAS DE JAUX,,43.9592074418,3.06767634768,295,,Viala-du-Pas-de-Jaux,Viala-du-Pas-de-Jaux,12,Aveyron,76,Occitanie
12296,VIALA DU TARN,12490,VIALA DU TARN,,44.0855039631,2.83906955497,296,,Viala-du-Tarn,Viala-du-Tarn,12,Aveyron,76,Occitanie
12297,LE VIBAL,12290,LE VIBAL,,44.3214095463,2.74143536761,297,Le,Vibal,Le Vibal,12,Aveyron,76,Occitanie
12298,VILLECOMTAL,12580,VILLECOMTAL,,44.5432878165,2.57548637915,298,,Villecomtal,Villecomtal,12,Aveyron,76,Occitanie
12299,VILLEFRANCHE DE PANAT,12430,VILLEFRANCHE DE PANAT,,44.0971941681,2.71527258024,299,,Villefranche-de-Panat,Villefranche-de-Panat,12,Aveyron,76,Occitanie
12300,VILLEFRANCHE DE ROUERGUE,12200,VILLEFRANCHE DE ROUERGUE,,44.3499160171,2.03103287572,300,,Villefranche-de-Rouergue,Villefranche-de-Rouergue,12,Aveyron,76,Occitanie
12301,VILLENEUVE,12260,VILLENEUVE,,44.4470516334,2.0285315392,301,,Villeneuve,Villeneuve,12,Aveyron,76,Occitanie
12303,VIMENET,12310,VIMENET,,44.4000547322,2.92952468147,303,,Vimenet,Vimenet,12,Aveyron,76,Occitanie
12305,VIVIEZ,12110,VIVIEZ,,44.5563780482,2.22049316685,305,,Viviez,Viviez,12,Aveyron,76,Occitanie
12307,CURAN,12410,CURAN,,44.1978533166,2.85380503746,307,,Curan,Curan,12,Aveyron,76,Occitanie
13001,AIX EN PROVENCE,13080,AIX EN PROVENCE,LUYNES,43.5360708378,5.39857444582,1,,Aix-en-Provence,Aix-en-Provence,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13001,AIX EN PROVENCE,13090,AIX EN PROVENCE,,43.5360708378,5.39857444582,1,,Aix-en-Provence,Aix-en-Provence,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13001,AIX EN PROVENCE,13100,AIX EN PROVENCE,,43.5360708378,5.39857444582,1,,Aix-en-Provence,Aix-en-Provence,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13001,AIX EN PROVENCE,13100,AIX EN PROVENCE,LA DURANNE,43.5360708378,5.39857444582,1,,Aix-en-Provence,Aix-en-Provence,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13001,AIX EN PROVENCE,13290,AIX EN PROVENCE,LES MILLES,43.5360708378,5.39857444582,1,,Aix-en-Provence,Aix-en-Provence,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13001,AIX EN PROVENCE,13540,AIX EN PROVENCE,PUYRICARD,43.5360708378,5.39857444582,1,,Aix-en-Provence,Aix-en-Provence,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13002,ALLAUCH,13190,ALLAUCH,,43.3533145712,5.51134767187,2,,Allauch,Allauch,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13002,ALLAUCH,13190,ALLAUCH,LE LOGIS NEUF,43.3533145712,5.51134767187,2,,Allauch,Allauch,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13003,ALLEINS,13980,ALLEINS,,43.7074197745,5.15105029207,3,,Alleins,Alleins,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13003,ALLEINS,13980,ALLEINS,LE BASTIDON,43.7074197745,5.15105029207,3,,Alleins,Alleins,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13004,ARLES,13104,ARLES,MAS THIBERT,43.5468692378,4.66215642574,4,,Arles,Arles,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13004,ARLES,13123,ARLES,L ALBARON,43.5468692378,4.66215642574,4,,Arles,Arles,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13004,ARLES,13129,ARLES,SALIN DE GIRAUD,43.5468692378,4.66215642574,4,,Arles,Arles,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13004,ARLES,13200,ARLES,,43.5468692378,4.66215642574,4,,Arles,Arles,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13004,ARLES,13200,ARLES,LE SAMBUC,43.5468692378,4.66215642574,4,,Arles,Arles,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13004,ARLES,13280,ARLES,MOULES,43.5468692378,4.66215642574,4,,Arles,Arles,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13004,ARLES,13280,ARLES,RAPHELE LES ARLES,43.5468692378,4.66215642574,4,,Arles,Arles,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13005,AUBAGNE,13400,AUBAGNE,,43.2934843764,5.56331273477,5,,Aubagne,Aubagne,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13005,AUBAGNE,13400,AUBAGNE,CHARREL,43.2934843764,5.56331273477,5,,Aubagne,Aubagne,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13006,AUREILLE,13930,AUREILLE,,43.7000726884,4.94496642243,6,,Aureille,Aureille,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13007,AURIOL,13390,AURIOL,,43.3607946934,5.6583901873,7,,Auriol,Auriol,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13008,AURONS,13121,AURONS,,43.6715125585,5.14903600713,8,,Aurons,Aurons,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13009,LA BARBEN,13330,LA BARBEN,,43.6183855123,5.21385052651,9,La,Barben,La Barben,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13010,BARBENTANE,13570,BARBENTANE,,43.894724982,4.74934042876,10,,Barbentane,Barbentane,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13011,LES BAUX DE PROVENCE,13520,LES BAUX DE PROVENCE,,43.745808725,4.80218162499,11,Les,Baux-de-Provence,Les Baux-de-Provence,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13012,BEAURECUEIL,13100,BEAURECUEIL,,43.5220542383,5.545935902,12,,Beaurecueil,Beaurecueil,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13013,BELCODENE,13720,BELCODENE,,43.4220302139,5.58376148513,13,,Belcodène,Belcodène,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13013,BELCODENE,13720,BELCODENE,LA POMME,43.4220302139,5.58376148513,13,,Belcodène,Belcodène,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13014,BERRE L ETANG,13130,BERRE L ETANG,,43.5036442498,5.1606136975,14,,Berre-l'Étang,Berre-l'Étang,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13015,BOUC BEL AIR,13320,BOUC BEL AIR,,43.4472296617,5.41237056144,15,,Bouc-Bel-Air,Bouc-Bel-Air,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13016,LA BOUILLADISSE,13720,LA BOUILLADISSE,,43.397192269,5.61980749093,16,La,Bouilladisse,La Bouilladisse,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13016,LA BOUILLADISSE,13720,LA BOUILLADISSE,LE PIGEONNIER,43.397192269,5.61980749093,16,La,Bouilladisse,La Bouilladisse,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13016,LA BOUILLADISSE,13720,LA BOUILLADISSE,LES BOYERS,43.397192269,5.61980749093,16,La,Bouilladisse,La Bouilladisse,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13016,LA BOUILLADISSE,13720,LA BOUILLADISSE,LES GORGUETTES,43.397192269,5.61980749093,16,La,Bouilladisse,La Bouilladisse,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13017,BOULBON,13150,BOULBON,,43.867766322,4.69754706735,17,,Boulbon,Boulbon,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13018,CABANNES,13440,CABANNES,,43.8584804516,4.95793402726,18,,Cabannes,Cabannes,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13019,CABRIES,13480,CABRIES,,43.4496421919,5.34976663984,19,,Cabriès,Cabriès,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13019,CABRIES,13480,CABRIES,CALAS,43.4496421919,5.34976663984,19,,Cabriès,Cabriès,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13020,CADOLIVE,13950,CADOLIVE,,43.3941966153,5.53223354589,20,,Cadolive,Cadolive,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13021,CARRY LE ROUET,13620,CARRY LE ROUET,,43.3427663021,5.15373765817,21,,Carry-le-Rouet,Carry-le-Rouet,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13021,CARRY LE ROUET,13620,CARRY LE ROUET,LE ROUET,43.3427663021,5.15373765817,21,,Carry-le-Rouet,Carry-le-Rouet,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13022,CASSIS,13260,CASSIS,,43.2230470065,5.55117810991,22,,Cassis,Cassis,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13023,CEYRESTE,13600,CEYRESTE,,43.2238501508,5.63636473345,23,,Ceyreste,Ceyreste,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13024,CHARLEVAL,13350,CHARLEVAL,,43.721111879,5.24576645287,24,,Charleval,Charleval,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13025,CHATEAUNEUF LE ROUGE,13790,CHATEAUNEUF LE ROUGE,,43.4912659702,5.56821681952,25,,Châteauneuf-le-Rouge,Châteauneuf-le-Rouge,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13026,CHATEAUNEUF LES MARTIGUES,13220,CHATEAUNEUF LES MARTIGUES,,43.3865906403,5.14753822568,26,,Châteauneuf-les-Martigues,Châteauneuf-les-Martigues,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13026,CHATEAUNEUF LES MARTIGUES,13220,CHATEAUNEUF LES MARTIGUES,LA MEDE,43.3865906403,5.14753822568,26,,Châteauneuf-les-Martigues,Châteauneuf-les-Martigues,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13027,CHATEAURENARD,13160,CHATEAURENARD,,43.8824707581,4.83990004648,27,,Châteaurenard,Châteaurenard,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13028,LA CIOTAT,13600,LA CIOTAT,,43.1926410861,5.61318955778,28,La,Ciotat,La Ciotat,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13029,CORNILLON CONFOUX,13250,CORNILLON CONFOUX,,43.5783400746,5.07659575248,29,,Cornillon-Confoux,Cornillon-Confoux,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13030,CUGES LES PINS,13780,CUGES LES PINS,,43.2805717043,5.71024615753,30,,Cuges-les-Pins,Cuges-les-Pins,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13031,LA DESTROUSSE,13112,LA DESTROUSSE,,43.3764232809,5.59885932444,31,La,Destrousse,La Destrousse,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13032,EGUILLES,13510,EGUILLES,,43.5711208794,5.32885751916,32,,Éguilles,Éguilles,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13033,ENSUES LA REDONNE,13820,ENSUES LA REDONNE,,43.3536539601,5.19960307575,33,,Ensuès-la-Redonne,Ensuès-la-Redonne,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13033,ENSUES LA REDONNE,13820,ENSUES LA REDONNE,LA REDONNE,43.3536539601,5.19960307575,33,,Ensuès-la-Redonne,Ensuès-la-Redonne,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13034,EYGALIERES,13810,EYGALIERES,,43.7617047487,4.95211983638,34,,Eygalières,Eygalières,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13035,EYGUIERES,13430,EYGUIERES,,43.7025562953,5.01557705248,35,,Eyguières,Eyguières,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13036,EYRAGUES,13630,EYRAGUES,,43.84304663,4.84289529399,36,,Eyragues,Eyragues,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13037,LA FARE LES OLIVIERS,13580,LA FARE LES OLIVIERS,,43.5544485185,5.20247621443,37,La,Fare-les-Oliviers,La Fare-les-Oliviers,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13038,FONTVIEILLE,13990,FONTVIEILLE,,43.7232610704,4.72310168882,38,,Fontvieille,Fontvieille,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13039,FOS SUR MER,13270,FOS SUR MER,,43.4556812107,4.90452133912,39,,Fos-sur-Mer,Fos-sur-Mer,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13040,FUVEAU,13710,FUVEAU,,43.4594948454,5.55305747206,40,,Fuveau,Fuveau,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13040,FUVEAU,13710,FUVEAU,LA BARQUE,43.4594948454,5.55305747206,40,,Fuveau,Fuveau,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13040,FUVEAU,13710,FUVEAU,LA BEGUDE,43.4594948454,5.55305747206,40,,Fuveau,Fuveau,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13041,GARDANNE,13120,GARDANNE,,43.4526063249,5.47963860272,41,,Gardanne,Gardanne,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13041,GARDANNE,13120,GARDANNE,BIVER,43.4526063249,5.47963860272,41,,Gardanne,Gardanne,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13041,GARDANNE,13120,GARDANNE,NOTRE DAME,43.4526063249,5.47963860272,41,,Gardanne,Gardanne,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13042,GEMENOS,13420,GEMENOS,,43.2981044185,5.64391464048,42,,Gémenos,Gémenos,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13043,GIGNAC LA NERTHE,13180,GIGNAC LA NERTHE,,43.3905074118,5.22834752895,43,,Gignac-la-Nerthe,Gignac-la-Nerthe,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13043,GIGNAC LA NERTHE,13180,GIGNAC LA NERTHE,LAURE,43.3905074118,5.22834752895,43,,Gignac-la-Nerthe,Gignac-la-Nerthe,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13044,GRANS,13450,GRANS,,43.6142225937,5.04474558898,44,,Grans,Grans,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13045,GRAVESON,13690,GRAVESON,,43.8539111696,4.76717369182,45,,Graveson,Graveson,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13046,GREASQUE,13850,GREASQUE,,43.4262029745,5.54685109195,46,,Gréasque,Gréasque,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13047,ISTRES,13118,ISTRES,ENTRESSEN,43.5502689105,4.9511813524,47,,Istres,Istres,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13047,ISTRES,13800,ISTRES,,43.5502689105,4.9511813524,47,,Istres,Istres,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13048,JOUQUES,13490,JOUQUES,,43.6334137827,5.65675796328,48,,Jouques,Jouques,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13049,LAMANON,13113,LAMANON,,43.704757813,5.0886270398,49,,Lamanon,Lamanon,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13050,LAMBESC,13410,LAMBESC,,43.6615217681,5.25191446931,50,,Lambesc,Lambesc,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13051,LANCON PROVENCE,13680,LANCON PROVENCE,,43.5760406168,5.15953601477,51,,Lançon-Provence,Lançon-Provence,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13052,MAILLANE,13910,MAILLANE,,43.8230609796,4.78456570653,52,,Maillane,Maillane,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13053,MALLEMORT,13370,MALLEMORT,,43.7285832667,5.18297726548,53,,Mallemort,Mallemort,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13053,MALLEMORT,13370,MALLEMORT,NOTRE DAME,43.7285832667,5.18297726548,53,,Mallemort,Mallemort,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13054,MARIGNANE,13700,MARIGNANE,,43.4172068362,5.21221904548,54,,Marignane,Marignane,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13056,MARTIGUES,13117,MARTIGUES,LAVERA,43.3798920489,5.04945402314,56,,Martigues,Martigues,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13056,MARTIGUES,13500,MARTIGUES,,43.3798920489,5.04945402314,56,,Martigues,Martigues,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13056,MARTIGUES,13500,MARTIGUES,CARRO,43.3798920489,5.04945402314,56,,Martigues,Martigues,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13056,MARTIGUES,13500,MARTIGUES,CROIX STE,43.3798920489,5.04945402314,56,,Martigues,Martigues,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13056,MARTIGUES,13500,MARTIGUES,LA COURONNE CARRO,43.3798920489,5.04945402314,56,,Martigues,Martigues,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13057,MAS BLANC DES ALPILLES,13103,MAS BLANC DES ALPILLES,,43.7878902175,4.76385153358,57,,Mas-Blanc-des-Alpilles,Mas-Blanc-des-Alpilles,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13058,MAUSSANE LES ALPILLES,13520,MAUSSANE LES ALPILLES,,43.7145004234,4.83333459578,58,,Maussane-les-Alpilles,Maussane-les-Alpilles,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13059,MEYRARGUES,13650,MEYRARGUES,,43.6244876475,5.52271513103,59,,Meyrargues,Meyrargues,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13060,MEYREUIL,13590,MEYREUIL,,43.4910522546,5.50062811927,60,,Meyreuil,Meyreuil,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13061,ST PIERRE DE MEZOARGUES,13150,ST PIERRE DE MEZOARGUES,,43.8612350805,4.65739524122,61,,Saint-Pierre-de-Mézoargues,Saint-Pierre-de-Mézoargues,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13062,MIMET,13105,MIMET,,43.4128431127,5.49899491017,62,,Mimet,Mimet,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13063,MIRAMAS,13140,MIRAMAS,,43.5842134124,5.01384348574,63,,Miramas,Miramas,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13064,MOLLEGES,13940,MOLLEGES,,43.8053410404,4.9488694514,64,,Mollégès,Mollégès,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13065,MOURIES,13890,MOURIES,,43.7008454163,4.89038486174,65,,Mouriès,Mouriès,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13066,NOVES,13550,NOVES,,43.8584523466,4.89952194493,66,,Noves,Noves,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13066,NOVES,13550,NOVES,PALUD DE NOVES,43.8584523466,4.89952194493,66,,Noves,Noves,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13067,ORGON,13660,ORGON,,43.779613013,5.02340520998,67,,Orgon,Orgon,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13068,PARADOU,13520,PARADOU,,43.7082242761,4.77828143607,68,,Paradou,Paradou,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13069,PELISSANNE,13330,PELISSANNE,,43.6308385635,5.15957327275,69,,Pélissanne,Pélissanne,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13070,LA PENNE SUR HUVEAUNE,13821,LA PENNE SUR HUVEAUNE,,43.2772079729,5.51850914394,70,La,Penne-sur-Huveaune,La Penne-sur-Huveaune,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13070,LA PENNE SUR HUVEAUNE,13821,LA PENNE SUR HUVEAUNE,BASTIDONNE,43.2772079729,5.51850914394,70,La,Penne-sur-Huveaune,La Penne-sur-Huveaune,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13071,LES PENNES MIRABEAU,13170,LES PENNES MIRABEAU,,43.4026791235,5.31547094057,71,Les,Pennes-Mirabeau,Les Pennes-Mirabeau,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13071,LES PENNES MIRABEAU,13170,LES PENNES MIRABEAU,LA GAVOTTE,43.4026791235,5.31547094057,71,Les,Pennes-Mirabeau,Les Pennes-Mirabeau,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13071,LES PENNES MIRABEAU,13170,LES PENNES MIRABEAU,LES CADENEAUX,43.4026791235,5.31547094057,71,Les,Pennes-Mirabeau,Les Pennes-Mirabeau,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13072,PEYNIER,13790,PEYNIER,,43.439686405,5.62516789037,72,,Peynier,Peynier,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13072,PEYNIER,13790,PEYNIER,LES MICHELS,43.439686405,5.62516789037,72,,Peynier,Peynier,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13073,PEYPIN,13124,PEYPIN,,43.3847877457,5.57131918284,73,,Peypin,Peypin,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13074,PEYROLLES EN PROVENCE,13860,PEYROLLES EN PROVENCE,,43.6284676935,5.57695734831,74,,Peyrolles-en-Provence,Peyrolles-en-Provence,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13075,PLAN DE CUQUES,13380,PLAN DE CUQUES,,43.3670927162,5.46488623464,75,,Plan-de-Cuques,Plan-de-Cuques,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13076,PLAN D ORGON,13750,PLAN D ORGON,,43.8181261115,5.00228183234,76,,Plan-d'Orgon,Plan-d'Orgon,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13077,PORT DE BOUC,13110,PORT DE BOUC,,43.4233518835,4.99266322339,77,,Port-de-Bouc,Port-de-Bouc,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13078,PORT ST LOUIS DU RHONE,13230,PORT ST LOUIS DU RHONE,,43.4148193741,4.80679663589,78,,Port-Saint-Louis-du-Rhône,Port-Saint-Louis-du-Rhône,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13079,PUYLOUBIER,13114,PUYLOUBIER,,43.5186378192,5.67758737833,79,,Puyloubier,Puyloubier,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13079,PUYLOUBIER,13114,PUYLOUBIER,LE BASTIDON,43.5186378192,5.67758737833,79,,Puyloubier,Puyloubier,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13080,LE PUY STE REPARADE,13610,LE PUY STE REPARADE,,43.6518285649,5.43958389565,80,Le,Puy-Sainte-Réparade,Le Puy-Sainte-Réparade,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13081,ROGNAC,13340,ROGNAC,,43.4971347324,5.23106220021,81,,Rognac,Rognac,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13082,ROGNES,13840,ROGNES,,43.6648781836,5.35218247017,82,,Rognes,Rognes,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13083,ROGNONAS,13870,ROGNONAS,,43.9000833173,4.79857977479,83,,Rognonas,Rognonas,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13084,LA ROQUE D ANTHERON,13640,LA ROQUE D ANTHERON,,43.7176409517,5.30157633559,84,La,Roque-d'Anthéron,La Roque-d'Anthéron,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13085,ROQUEFORT LA BEDOULE,13830,ROQUEFORT LA BEDOULE,,43.2510907217,5.62904634328,85,,Roquefort-la-Bédoule,Roquefort-la-Bédoule,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13085,ROQUEFORT LA BEDOULE,13830,ROQUEFORT LA BEDOULE,ROQUEFORT,43.2510907217,5.62904634328,85,,Roquefort-la-Bédoule,Roquefort-la-Bédoule,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13086,ROQUEVAIRE,13360,ROQUEVAIRE,,43.3437864693,5.59772643152,86,,Roquevaire,Roquevaire,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13086,ROQUEVAIRE,13360,ROQUEVAIRE,LA BEGUDE,43.3437864693,5.59772643152,86,,Roquevaire,Roquevaire,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13086,ROQUEVAIRE,13360,ROQUEVAIRE,PONT DE L ETOILE,43.3437864693,5.59772643152,86,,Roquevaire,Roquevaire,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13087,ROUSSET,13790,ROUSSET,,43.4841496165,5.62380799416,87,,Rousset,Rousset,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13088,LE ROVE,13740,LE ROVE,,43.3653374562,5.25292240431,88,Le,Rove,Le Rove,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13088,LE ROVE,13740,LE ROVE,LE DOUARD,43.3653374562,5.25292240431,88,Le,Rove,Le Rove,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13088,LE ROVE,13740,LE ROVE,LE PIGEONNIER,43.3653374562,5.25292240431,88,Le,Rove,Le Rove,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13089,ST ANDIOL,13670,ST ANDIOL,,43.8307575371,4.95027358923,89,,Saint-Andiol,Saint-Andiol,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13090,ST ANTONIN SUR BAYON,13100,ST ANTONIN SUR BAYON,,43.517185261,5.59403460953,90,,Saint-Antonin-sur-Bayon,Saint-Antonin-sur-Bayon,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13091,ST CANNAT,13760,ST CANNAT,,43.6136286667,5.30698257258,91,,Saint-Cannat,Saint-Cannat,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13092,ST CHAMAS,13250,ST CHAMAS,,43.5436905217,5.07329988936,92,,Saint-Chamas,Saint-Chamas,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13093,ST ESTEVE JANSON,13610,ST ESTEVE JANSON,,43.6923695558,5.38958912604,93,,Saint-Estève-Janson,Saint-Estève-Janson,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13094,ST ETIENNE DU GRES,13103,ST ETIENNE DU GRES,,43.7849429124,4.73566400662,94,,Saint-Étienne-du-Grès,Saint-Étienne-du-Grès,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13095,ST MARC JAUMEGARDE,13100,ST MARC JAUMEGARDE,,43.5561849254,5.52206993794,95,,Saint-Marc-Jaumegarde,Saint-Marc-Jaumegarde,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13096,SAINTES MARIES DE LA MER,13460,SAINTES MARIES DE LA MER,,43.50398297,4.46396357194,96,,Saintes-Maries-de-la-Mer,Saintes-Maries-de-la-Mer,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13097,ST MARTIN DE CRAU,13310,ST MARTIN DE CRAU,,43.6119214615,4.85701475567,97,,Saint-Martin-de-Crau,Saint-Martin-de-Crau,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13098,ST MITRE LES REMPARTS,13920,ST MITRE LES REMPARTS,,43.4554029916,5.01494725455,98,,Saint-Mitre-les-Remparts,Saint-Mitre-les-Remparts,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13099,ST PAUL LES DURANCE,13115,ST PAUL LES DURANCE,,43.6854814023,5.75372558845,99,,Saint-Paul-lès-Durance,Saint-Paul-lès-Durance,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13100,ST REMY DE PROVENCE,13210,ST REMY DE PROVENCE,,43.7834249015,4.85366665286,100,,Saint-Rémy-de-Provence,Saint-Rémy-de-Provence,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13101,ST SAVOURNIN,13119,ST SAVOURNIN,,43.4057248147,5.53101491431,101,,Saint-Savournin,Saint-Savournin,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13102,ST VICTORET,13700,ST VICTORET,PAS DES LANCIERS,43.4142378837,5.25398700813,102,,Saint-Victoret,Saint-Victoret,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13102,ST VICTORET,13730,ST VICTORET,,43.4142378837,5.25398700813,102,,Saint-Victoret,Saint-Victoret,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13103,SALON DE PROVENCE,13300,SALON DE PROVENCE,,43.6462885238,5.06814649576,103,,Salon-de-Provence,Salon-de-Provence,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13104,SAUSSET LES PINS,13960,SAUSSET LES PINS,,43.3456690305,5.11765506999,104,,Sausset-les-Pins,Sausset-les-Pins,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13105,SENAS,13560,SENAS,,43.7446066018,5.08687884411,105,,Sénas,Sénas,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13106,SEPTEMES LES VALLONS,13240,SEPTEMES LES VALLONS,,43.3934944571,5.3808040555,106,,Septèmes-les-Vallons,Septèmes-les-Vallons,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13106,SEPTEMES LES VALLONS,13240,SEPTEMES LES VALLONS,LA ROUGIERE,43.3934944571,5.3808040555,106,,Septèmes-les-Vallons,Septèmes-les-Vallons,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13107,SIMIANE COLLONGUE,13109,SIMIANE COLLONGUE,,43.411551859,5.43796924424,107,,Simiane-Collongue,Simiane-Collongue,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13108,TARASCON,13150,TARASCON,,43.7939885332,4.68614849204,108,,Tarascon,Tarascon,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13109,LE THOLONET,13100,LE THOLONET,,43.5196914136,5.50819253944,109,Le,Tholonet,Le Tholonet,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13110,TRETS,13530,TRETS,,43.4378087269,5.6999524319,110,,Trets,Trets,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13110,TRETS,13530,TRETS,CADENET,43.4378087269,5.6999524319,110,,Trets,Trets,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13111,VAUVENARGUES,13126,VAUVENARGUES,,43.559011599,5.61324103718,111,,Vauvenargues,Vauvenargues,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13112,VELAUX,13880,VELAUX,,43.5239854581,5.24996424883,112,,Velaux,Velaux,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13113,VENELLES,13770,VENELLES,,43.5928673895,5.49138381609,113,,Venelles,Venelles,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13114,VENTABREN,13122,VENTABREN,,43.5422528534,5.30622321985,114,,Ventabren,Ventabren,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13114,VENTABREN,13122,VENTABREN,LE PIGEONNIER,43.5422528534,5.30622321985,114,,Ventabren,Ventabren,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13115,VERNEGUES,13116,VERNEGUES,,43.6841394366,5.18825526479,115,,Vernègues,Vernègues,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13116,VERQUIERES,13670,VERQUIERES,,43.8386551607,4.9188995104,116,,Verquières,Verquières,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13117,VITROLLES,13127,VITROLLES,,43.4497831674,5.26357787665,117,,Vitrolles,Vitrolles,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13118,COUDOUX,13111,COUDOUX,,43.5600515363,5.25422160677,118,,Coudoux,Coudoux,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13119,CARNOUX EN PROVENCE,13470,CARNOUX EN PROVENCE,,43.2584851646,5.56581431221,119,,Carnoux-en-Provence,Carnoux-en-Provence,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13201,MARSEILLE 01,13001,MARSEILLE,,43.2999009436,5.38227869795,201,,Marseille 01,Marseille 01,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13202,MARSEILLE 02,13002,MARSEILLE,,43.3126964178,5.36364983265,202,,Marseille 02,Marseille 02,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13203,MARSEILLE 03,13003,MARSEILLE,,43.3121200046,5.38010981423,203,,Marseille 03,Marseille 03,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13204,MARSEILLE 04,13004,MARSEILLE,,43.306733355,5.40087488752,204,,Marseille 04,Marseille 04,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13205,MARSEILLE 05,13005,MARSEILLE,,43.2928061953,5.3975770959,205,,Marseille 05,Marseille 05,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13206,MARSEILLE 06,13006,MARSEILLE,,43.2870989546,5.38104785394,206,,Marseille 06,Marseille 06,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13207,MARSEILLE 07,13007,MARSEILLE,,43.28251778,5.36323440435,207,,Marseille 07,Marseille 07,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13208,MARSEILLE 08,13008,MARSEILLE,,43.2417271832,5.37464436293,208,,Marseille 08,Marseille 08,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13208,MARSEILLE 08,13008,MARSEILLE,CALLELONGUE,43.2417271832,5.37464436293,208,,Marseille 08,Marseille 08,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13208,MARSEILLE 08,13008,MARSEILLE,LES GOUDES,43.2417271832,5.37464436293,208,,Marseille 08,Marseille 08,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13209,MARSEILLE 09,13009,MARSEILLE,,43.2340834927,5.45249683067,209,,Marseille 09,Marseille 09,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13209,MARSEILLE 09,13009,MARSEILLE,LES BAUMETTES,43.2340834927,5.45249683067,209,,Marseille 09,Marseille 09,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13209,MARSEILLE 09,13009,MARSEILLE,VAUFREGE,43.2340834927,5.45249683067,209,,Marseille 09,Marseille 09,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13210,MARSEILLE 10,13010,MARSEILLE,,43.2755155435,5.42681101081,210,,Marseille 10,Marseille 10,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13211,MARSEILLE 11,13011,MARSEILLE,,43.2884471881,5.48381466229,211,,Marseille 11,Marseille 11,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13211,MARSEILLE 11,13011,MARSEILLE,LA VALENTINE,43.2884471881,5.48381466229,211,,Marseille 11,Marseille 11,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13212,MARSEILLE 12,13012,MARSEILLE,,43.3077683678,5.43991567678,212,,Marseille 12,Marseille 12,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13213,MARSEILLE 13,13013,MARSEILLE,,43.3492780005,5.43372238738,213,,Marseille 13,Marseille 13,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13213,MARSEILLE 13,13013,MARSEILLE,CHATEAU GOMBERT,43.3492780005,5.43372238738,213,,Marseille 13,Marseille 13,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13213,MARSEILLE 13,13013,MARSEILLE,CROIX ROUGE,43.3492780005,5.43372238738,213,,Marseille 13,Marseille 13,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13213,MARSEILLE 13,13013,MARSEILLE,LA BATARELLE,43.3492780005,5.43372238738,213,,Marseille 13,Marseille 13,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13213,MARSEILLE 13,13013,MARSEILLE,LES OLIVES,43.3492780005,5.43372238738,213,,Marseille 13,Marseille 13,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13213,MARSEILLE 13,13013,MARSEILLE,ST MITRE,43.3492780005,5.43372238738,213,,Marseille 13,Marseille 13,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13214,MARSEILLE 14,13014,MARSEILLE,,43.3455018067,5.39408915769,214,,Marseille 14,Marseille 14,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13215,MARSEILLE 15,13015,MARSEILLE,,43.358794754,5.363407897,215,,Marseille 15,Marseille 15,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13216,MARSEILLE 16,13016,MARSEILLE,,43.3649054121,5.31337828595,216,,Marseille 16,Marseille 16,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
13216,MARSEILLE 16,13016,MARSEILLE,L ESTAQUE,43.3649054121,5.31337828595,216,,Marseille 16,Marseille 16,13,Bouches-du-Rhône,93,Provence-Alpes-Côte d'Azur
14001,ABLON,14600,ABLON,,49.3942506112,0.286767648584,1,,Ablon,Ablon,14,Calvados,28,Normandie
14002,ACQUEVILLE,14220,ACQUEVILLE,,48.9707723348,-0.370569787923,2,,Acqueville,Acqueville,14,Calvados,28,Normandie
14003,AGY,14400,AGY,,49.2376795165,-0.779194569684,3,,Agy,Agy,14,Calvados,28,Normandie
14004,AIGNERVILLE,14710,AIGNERVILLE,,49.3261867283,-0.918465184477,4,,Aignerville,Aignerville,14,Calvados,28,Normandie
14005,AIRAN,14370,AIRAN,,49.0953124219,-0.145440484479,5,,Valambray,Valambray,14,Calvados,28,Normandie
14006,AMAYE SUR ORNE,14210,AMAYE SUR ORNE,,49.0911214253,-0.434224922695,6,,Amayé-sur-Orne,Amayé-sur-Orne,14,Calvados,28,Normandie
14007,AMAYE SUR SEULLES,14310,AMAYE SUR SEULLES,,49.0820646121,-0.717699539626,7,,Amayé-sur-Seulles,Amayé-sur-Seulles,14,Calvados,28,Normandie
14008,AMBLIE,14480,AMBLIE,,49.2848897685,-0.501466969459,8,,Amblie,Amblie,14,Calvados,28,Normandie
14009,AMFREVILLE,14860,AMFREVILLE,,49.2501431721,-0.241578864111,9,,Amfreville,Amfreville,14,Calvados,28,Normandie
14011,ANCTOVILLE,14240,ANCTOVILLE,,49.1129887811,-0.690829418472,11,,Aurseulles,Aurseulles,14,Calvados,28,Normandie
14011,ANCTOVILLE,14240,ANCTOVILLE,FEUGUEROLLES SUR SEULLES,49.1129887811,-0.690829418472,11,,Aurseulles,Aurseulles,14,Calvados,28,Normandie
14011,ANCTOVILLE,14240,ANCTOVILLE,ORBOIS,49.1129887811,-0.690829418472,11,,Aurseulles,Aurseulles,14,Calvados,28,Normandie
14011,ANCTOVILLE,14240,ANCTOVILLE,SERMENTOT,49.1129887811,-0.690829418472,11,,Aurseulles,Aurseulles,14,Calvados,28,Normandie
14012,ANGERVILLE,14430,ANGERVILLE,,49.2443066978,-0.0275594103031,12,,Angerville,Angerville,14,Calvados,28,Normandie
14013,ANGOVILLE,14220,ANGOVILLE,,48.9437761025,-0.373433111551,13,,Angoville,Angoville,14,Calvados,28,Normandie
14014,COLOMBY ANGUERNY,14610,COLOMBY ANGUERNY,,49.2653517186,-0.396935454008,14,,Colomby-Anguerny,Colomby-Anguerny,14,Calvados,28,Normandie
14014,COLOMBY ANGUERNY,14610,COLOMBY ANGUERNY,COLOMBY SUR THAON,49.2653517186,-0.396935454008,14,,Colomby-Anguerny,Colomby-Anguerny,14,Calvados,28,Normandie
14015,ANISY,14610,ANISY,,49.25086166,-0.398796097225,15,,Anisy,Anisy,14,Calvados,28,Normandie
14016,ANNEBAULT,14430,ANNEBAULT,,49.248966024,0.054049962802,16,,Annebault,Annebault,14,Calvados,28,Normandie
14019,ARGANCHY,14400,ARGANCHY,,49.2299804943,-0.731623390683,19,,Arganchy,Arganchy,14,Calvados,28,Normandie
14020,ARGENCES,14370,ARGENCES,,49.1339723471,-0.158515066328,20,,Argences,Argences,14,Calvados,28,Normandie
14021,ARROMANCHES LES BAINS,14117,ARROMANCHES LES BAINS,,49.3357973304,-0.620617244161,21,,Arromanches-les-Bains,Arromanches-les-Bains,14,Calvados,28,Normandie
14022,ASNELLES,14960,ASNELLES,,49.3336519158,-0.584632970661,22,,Asnelles,Asnelles,14,Calvados,28,Normandie
14023,ASNIERES EN BESSIN,14710,ASNIERES EN BESSIN,,49.3600707823,-0.938258707013,23,,Asnières-en-Bessin,Asnières-en-Bessin,14,Calvados,28,Normandie
14024,AUBERVILLE,14640,AUBERVILLE,,49.311097988,-0.0268928739803,24,,Auberville,Auberville,14,Calvados,28,Normandie
14025,AUBIGNY,14700,AUBIGNY,,48.9145772293,-0.215543424215,25,,Aubigny,Aubigny,14,Calvados,28,Normandie
14026,AUDRIEU,14250,AUDRIEU,,49.2046566574,-0.604235735984,26,,Audrieu,Audrieu,14,Calvados,28,Normandie
14027,AUNAY SUR ODON,14260,AUNAY SUR ODON,,49.0144785445,-0.633929762668,27,Les,Monts d'Aunay,Les Monts d'Aunay,14,Calvados,28,Normandie
14030,AUTHIE,14280,AUTHIE,,49.205811786,-0.430653066001,30,,Authie,Authie,14,Calvados,28,Normandie
14031,LES AUTHIEUX PAPION,14140,LES AUTHIEUX PAPION,,49.0637363101,0.0531291462817,31,Les,Authieux-Papion,Les Authieux-Papion,14,Calvados,28,Normandie
14032,LES AUTHIEUX SUR CALONNE,14130,LES AUTHIEUX SUR CALONNE,,49.284118224,0.275543466655,32,Les,Authieux-sur-Calonne,Les Authieux-sur-Calonne,14,Calvados,28,Normandie
14033,AUVILLARS,14340,AUVILLARS,,49.1974017349,0.0757798080926,33,,Auvillars,Auvillars,14,Calvados,28,Normandie
14034,AVENAY,14210,AVENAY,,49.0957497746,-0.464408003458,34,,Avenay,Avenay,14,Calvados,28,Normandie
14035,BALLEROY SUR DROME,14490,BALLEROY SUR DROME,,49.1801660324,-0.839275566606,35,,Balleroy-sur-Drôme,Balleroy-sur-Drôme,14,Calvados,28,Normandie
14035,BALLEROY SUR DROME,14490,BALLEROY SUR DROME,VAUBADON,49.1801660324,-0.839275566606,35,,Balleroy-sur-Drôme,Balleroy-sur-Drôme,14,Calvados,28,Normandie
14036,BANNEVILLE LA CAMPAGNE,14940,BANNEVILLE LA CAMPAGNE,,49.1686883347,-0.224327204511,36,,Banneville-la-Campagne,Banneville-la-Campagne,14,Calvados,28,Normandie
14037,MALHERBE SUR AJON,14260,MALHERBE SUR AJON,BANNEVILLE SUR AJON,49.0615972046,-0.567996392999,37,,Malherbe-sur-Ajon,Malherbe-sur-Ajon,14,Calvados,28,Normandie
14037,MALHERBE SUR AJON,14260,MALHERBE SUR AJON,ST AGNAN LE MALHERBE,49.0615972046,-0.567996392999,37,,Malherbe-sur-Ajon,Malherbe-sur-Ajon,14,Calvados,28,Normandie
14038,BANVILLE,14480,BANVILLE,,49.3091629714,-0.485951971964,38,,Banville,Banville,14,Calvados,28,Normandie
14039,BARBERY,14220,BARBERY,,49.0142202148,-0.345317052278,39,,Barbery,Barbery,14,Calvados,28,Normandie
14040,BARBEVILLE,14400,BARBEVILLE,,49.2738503472,-0.75503800867,40,,Barbeville,Barbeville,14,Calvados,28,Normandie
14041,BARNEVILLE LA BERTRAN,14600,BARNEVILLE LA BERTRAN,,49.3913668809,0.182164681877,41,,Barneville-la-Bertran,Barneville-la-Bertran,14,Calvados,28,Normandie
14042,BARON SUR ODON,14210,BARON SUR ODON,,49.1280853524,-0.481929855729,42,,Baron-sur-Odon,Baron-sur-Odon,14,Calvados,28,Normandie
14043,BAROU EN AUGE,14620,BAROU EN AUGE,,48.9297518504,-0.0386142732111,43,,Barou-en-Auge,Barou-en-Auge,14,Calvados,28,Normandie
14044,BASLY,14610,BASLY,,49.2759084631,-0.426109621277,44,,Basly,Basly,14,Calvados,28,Normandie
14045,BASSENEVILLE,14670,BASSENEVILLE,,49.2067455591,-0.135002451422,45,,Basseneville,Basseneville,14,Calvados,28,Normandie
14046,BAVENT,14860,BAVENT,,49.2260268215,-0.182663216453,46,,Bavent,Bavent,14,Calvados,28,Normandie
14046,BAVENT,14860,BAVENT,ROBEHOMME,49.2260268215,-0.182663216453,46,,Bavent,Bavent,14,Calvados,28,Normandie
14047,BAYEUX,14400,BAYEUX,,49.2776559195,-0.704625495378,47,,Bayeux,Bayeux,14,Calvados,28,Normandie
14049,BAZENVILLE,14480,BAZENVILLE,,49.3006069672,-0.584555830953,49,,Bazenville,Bazenville,14,Calvados,28,Normandie
14050,LA BAZOQUE,14490,LA BAZOQUE,,49.1535081338,-0.877591469858,50,La,Bazoque,La Bazoque,14,Calvados,28,Normandie
14053,BEAUMAIS,14620,BEAUMAIS,,48.8992282698,-0.0710822591803,53,,Beaumais,Beaumais,14,Calvados,28,Normandie
14054,BEAUMESNIL,14380,BEAUMESNIL,,48.8967676433,-0.968365915831,54,,Beaumesnil,Beaumesnil,14,Calvados,28,Normandie
14055,BEAUMONT EN AUGE,14950,BEAUMONT EN AUGE,,49.2673749732,0.110186947305,55,,Beaumont-en-Auge,Beaumont-en-Auge,14,Calvados,28,Normandie
14056,BAUQUAY,14260,BAUQUAY,,49.0338854492,-0.620210148364,56,,Bauquay,Bauquay,14,Calvados,28,Normandie
14057,BELLENGREVILLE,14370,BELLENGREVILLE,,49.1202677758,-0.233407879923,57,,Bellengreville,Bellengreville,14,Calvados,28,Normandie
14059,BENERVILLE SUR MER,14910,BENERVILLE SUR MER,,49.3390106956,0.0477938566692,59,,Benerville-sur-Mer,Benerville-sur-Mer,14,Calvados,28,Normandie
14060,BENOUVILLE,14970,BENOUVILLE,,49.2437018681,-0.287859699989,60,,Bénouville,Bénouville,14,Calvados,28,Normandie
14061,SOULEUVRE EN BOCAGE,14260,SOULEUVRE EN BOCAGE,MONTAMY,48.9311056012,-0.829700488318,61,,Souleuvre en Bocage,Souleuvre en Bocage,14,Calvados,28,Normandie
14061,SOULEUVRE EN BOCAGE,14350,SOULEUVRE EN BOCAGE,BEAULIEU,48.9311056012,-0.829700488318,61,,Souleuvre en Bocage,Souleuvre en Bocage,14,Calvados,28,Normandie
14061,SOULEUVRE EN BOCAGE,14350,SOULEUVRE EN BOCAGE,BURES LES MONTS,48.9311056012,-0.829700488318,61,,Souleuvre en Bocage,Souleuvre en Bocage,14,Calvados,28,Normandie
14061,SOULEUVRE EN BOCAGE,14350,SOULEUVRE EN BOCAGE,CAMPEAUX,48.9311056012,-0.829700488318,61,,Souleuvre en Bocage,Souleuvre en Bocage,14,Calvados,28,Normandie
14061,SOULEUVRE EN BOCAGE,14350,SOULEUVRE EN BOCAGE,CARVILLE,48.9311056012,-0.829700488318,61,,Souleuvre en Bocage,Souleuvre en Bocage,14,Calvados,28,Normandie
14061,SOULEUVRE EN BOCAGE,14350,SOULEUVRE EN BOCAGE,ETOUVY,48.9311056012,-0.829700488318,61,,Souleuvre en Bocage,Souleuvre en Bocage,14,Calvados,28,Normandie
14061,SOULEUVRE EN BOCAGE,14350,SOULEUVRE EN BOCAGE,LA FERRIERE AU DOYEN,48.9311056012,-0.829700488318,61,,Souleuvre en Bocage,Souleuvre en Bocage,14,Calvados,28,Normandie
14061,SOULEUVRE EN BOCAGE,14350,SOULEUVRE EN BOCAGE,LA FERRIERE HARANG,48.9311056012,-0.829700488318,61,,Souleuvre en Bocage,Souleuvre en Bocage,14,Calvados,28,Normandie
14061,SOULEUVRE EN BOCAGE,14350,SOULEUVRE EN BOCAGE,LA GRAVERIE,48.9311056012,-0.829700488318,61,,Souleuvre en Bocage,Souleuvre en Bocage,14,Calvados,28,Normandie
14061,SOULEUVRE EN BOCAGE,14350,SOULEUVRE EN BOCAGE,LE BENY BOCAGE,48.9311056012,-0.829700488318,61,,Souleuvre en Bocage,Souleuvre en Bocage,14,Calvados,28,Normandie
14061,SOULEUVRE EN BOCAGE,14350,SOULEUVRE EN BOCAGE,LE RECULEY,48.9311056012,-0.829700488318,61,,Souleuvre en Bocage,Souleuvre en Bocage,14,Calvados,28,Normandie
14061,SOULEUVRE EN BOCAGE,14350,SOULEUVRE EN BOCAGE,LE TOURNEUR,48.9311056012,-0.829700488318,61,,Souleuvre en Bocage,Souleuvre en Bocage,14,Calvados,28,Normandie
14061,SOULEUVRE EN BOCAGE,14350,SOULEUVRE EN BOCAGE,MALLOUE,48.9311056012,-0.829700488318,61,,Souleuvre en Bocage,Souleuvre en Bocage,14,Calvados,28,Normandie
14061,SOULEUVRE EN BOCAGE,14350,SOULEUVRE EN BOCAGE,MONT BERTRAND,48.9311056012,-0.829700488318,61,,Souleuvre en Bocage,Souleuvre en Bocage,14,Calvados,28,Normandie
14061,SOULEUVRE EN BOCAGE,14350,SOULEUVRE EN BOCAGE,MONTCHAUVET,48.9311056012,-0.829700488318,61,,Souleuvre en Bocage,Souleuvre en Bocage,14,Calvados,28,Normandie
14061,SOULEUVRE EN BOCAGE,14350,SOULEUVRE EN BOCAGE,ST DENIS MAISONCELLES,48.9311056012,-0.829700488318,61,,Souleuvre en Bocage,Souleuvre en Bocage,14,Calvados,28,Normandie
14061,SOULEUVRE EN BOCAGE,14350,SOULEUVRE EN BOCAGE,STE MARIE LAUMONT,48.9311056012,-0.829700488318,61,,Souleuvre en Bocage,Souleuvre en Bocage,14,Calvados,28,Normandie
14061,SOULEUVRE EN BOCAGE,14350,SOULEUVRE EN BOCAGE,ST MARTIN DES BESACES,48.9311056012,-0.829700488318,61,,Souleuvre en Bocage,Souleuvre en Bocage,14,Calvados,28,Normandie
14061,SOULEUVRE EN BOCAGE,14350,SOULEUVRE EN BOCAGE,ST MARTIN DON,48.9311056012,-0.829700488318,61,,Souleuvre en Bocage,Souleuvre en Bocage,14,Calvados,28,Normandie
14061,SOULEUVRE EN BOCAGE,14350,SOULEUVRE EN BOCAGE,ST OUEN DES BESACES,48.9311056012,-0.829700488318,61,,Souleuvre en Bocage,Souleuvre en Bocage,14,Calvados,28,Normandie
14061,SOULEUVRE EN BOCAGE,14350,SOULEUVRE EN BOCAGE,ST PIERRE TARENTAINE,48.9311056012,-0.829700488318,61,,Souleuvre en Bocage,Souleuvre en Bocage,14,Calvados,28,Normandie
14062,BENY SUR MER,14440,BENY SUR MER,,49.2906537032,-0.436236066881,62,,Bény-sur-Mer,Bény-sur-Mer,14,Calvados,28,Normandie
14063,BERNESQ,14710,BERNESQ,,49.2737716158,-0.931449567611,63,,Bernesq,Bernesq,14,Calvados,28,Normandie
14064,BERNIERES D AILLY,14170,BERNIERES D AILLY,,48.9474636631,-0.102286178069,64,,Bernières-d'Ailly,Bernières-d'Ailly,14,Calvados,28,Normandie
14066,BERNIERES SUR MER,14990,BERNIERES SUR MER,,49.3194698748,-0.417743184424,66,,Bernières-sur-Mer,Bernières-sur-Mer,14,Calvados,28,Normandie
14068,BIEVILLE BEUVILLE,14112,BIEVILLE BEUVILLE,,49.2394857474,-0.336897222292,68,,Biéville-Beuville,Biéville-Beuville,14,Calvados,28,Normandie
14068,BIEVILLE BEUVILLE,14112,BIEVILLE BEUVILLE,BIEVILLE SUR ORNE,49.2394857474,-0.336897222292,68,,Biéville-Beuville,Biéville-Beuville,14,Calvados,28,Normandie
14069,BEUVILLERS,14100,BEUVILLERS,,49.1262889873,0.249746310711,69,,Beuvillers,Beuvillers,14,Calvados,28,Normandie
14070,BEUVRON EN AUGE,14430,BEUVRON EN AUGE,,49.1960004062,-0.042455878979,70,,Beuvron-en-Auge,Beuvron-en-Auge,14,Calvados,28,Normandie
14073,LA BIGNE,14260,LA BIGNE,,49.0113181173,-0.717996157351,73,La,Bigne,La Bigne,14,Calvados,28,Normandie
14074,BILLY,14370,BILLY,,49.0888230854,-0.194956475623,74,,Billy,Billy,14,Calvados,28,Normandie
14075,BISSIERES,14370,BISSIERES,,49.1136628313,-0.0896981022455,75,,Bissières,Bissières,14,Calvados,28,Normandie
14076,BLAINVILLE SUR ORNE,14550,BLAINVILLE SUR ORNE,,49.2281536645,-0.304322423176,76,,Blainville-sur-Orne,Blainville-sur-Orne,14,Calvados,28,Normandie
14077,BLANGY LE CHATEAU,14130,BLANGY LE CHATEAU,,49.246735105,0.283212958407,77,,Blangy-le-Château,Blangy-le-Château,14,Calvados,28,Normandie
14078,BLAY,14400,BLAY,,49.2804374516,-0.836134690578,78,,Blay,Blay,14,Calvados,28,Normandie
14079,BLONVILLE SUR MER,14910,BLONVILLE SUR MER,,49.322264685,0.0322391996049,79,,Blonville-sur-Mer,Blonville-sur-Mer,14,Calvados,28,Normandie
14080,LE BO,14690,LE BO,,48.9063561928,-0.441664417518,80,Le,Bô,Le Bô,14,Calvados,28,Normandie
14081,BOISSEY,14170,BOISSEY,,49.0197538694,0.0500632254083,81,,Boissey,Boissey,14,Calvados,28,Normandie
14082,LA BOISSIERE,14340,LA BOISSIERE,,49.129950391,0.132026565149,82,La,Boissière,La Boissière,14,Calvados,28,Normandie
14083,BONNEBOSQ,14340,BONNEBOSQ,,49.2183141516,0.0906070280253,83,,Bonnebosq,Bonnebosq,14,Calvados,28,Normandie
14084,BONNEMAISON,14260,BONNEMAISON,,49.0036461584,-0.58574099643,84,,Bonnemaison,Bonnemaison,14,Calvados,28,Normandie
14085,BONNEVILLE LA LOUVET,14130,BONNEVILLE LA LOUVET,,49.2730898592,0.341971829681,85,,Bonneville-la-Louvet,Bonneville-la-Louvet,14,Calvados,28,Normandie
14086,BONNEVILLE SUR TOUQUES,14800,BONNEVILLE SUR TOUQUES,,49.3299368884,0.119217690355,86,,Bonneville-sur-Touques,Bonneville-sur-Touques,14,Calvados,28,Normandie
14087,BONNOEIL,14700,BONNOEIL,,48.9225053811,-0.36568142159,87,,Bonnœil,Bonnœil,14,Calvados,28,Normandie
14088,BONS TASSILLY,14420,BONS TASSILLY,,48.9592439721,-0.230410534534,88,,Bons-Tassilly,Bons-Tassilly,14,Calvados,28,Normandie
14089,BOUGY,14210,BOUGY,,49.1086067044,-0.522373258036,89,,Bougy,Bougy,14,Calvados,28,Normandie
14090,BOULON,14220,BOULON,,49.047524459,-0.37826606912,90,,Boulon,Boulon,14,Calvados,28,Normandie
14091,BOURGEAUVILLE,14430,BOURGEAUVILLE,,49.2681454005,0.0583853935141,91,,Bourgeauville,Bourgeauville,14,Calvados,28,Normandie
14092,BOURGUEBUS,14540,BOURGUEBUS,,49.1159775311,-0.289489702409,92,,Bourguébus,Bourguébus,14,Calvados,28,Normandie
14093,BRANVILLE,14430,BRANVILLE,,49.2691883633,0.0233678399188,93,,Branville,Branville,14,Calvados,28,Normandie
14096,BREMOY,14260,BREMOY,,48.998435085,-0.780841649522,96,,Brémoy,Brémoy,14,Calvados,28,Normandie
14097,BRETTEVILLE LE RABET,14190,BRETTEVILLE LE RABET,,49.0297158634,-0.258286160153,97,,Bretteville-le-Rabet,Bretteville-le-Rabet,14,Calvados,28,Normandie
14098,BRETTEVILLE L ORGUEILLEUSE,14740,BRETTEVILLE L ORGUEILLEUSE,,49.2151517714,-0.51673661621,98,,Thue et Mue,Thue et Mue,14,Calvados,28,Normandie
14099,BRETTEVILLE SUR DIVES,14170,BRETTEVILLE SUR DIVES,,49.0361671804,0.00163683700471,99,,Bretteville-sur-Dives,Bretteville-sur-Dives,14,Calvados,28,Normandie
14100,BRETTEVILLE SUR LAIZE,14680,BRETTEVILLE SUR LAIZE,,49.0498397509,-0.326047063424,100,,Bretteville-sur-Laize,Bretteville-sur-Laize,14,Calvados,28,Normandie
14101,BRETTEVILLE SUR ODON,14760,BRETTEVILLE SUR ODON,,49.1731671291,-0.422220210671,101,,Bretteville-sur-Odon,Bretteville-sur-Odon,14,Calvados,28,Normandie
14102,LE BREUIL EN AUGE,14130,LE BREUIL EN AUGE,,49.2278153726,0.218766616218,102,Le,Breuil-en-Auge,Le Breuil-en-Auge,14,Calvados,28,Normandie
14103,LE BREUIL EN BESSIN,14330,LE BREUIL EN BESSIN,,49.2546153237,-0.855456824722,103,Le,Breuil-en-Bessin,Le Breuil-en-Bessin,14,Calvados,28,Normandie
14104,LE BREVEDENT,14130,LE BREVEDENT,,49.2269826384,0.293881828632,104,Le,Brévedent,Le Brévedent,14,Calvados,28,Normandie
14106,BREVILLE LES MONTS,14860,BREVILLE LES MONTS,,49.2360824637,-0.221042310396,106,,Bréville-les-Monts,Bréville-les-Monts,14,Calvados,28,Normandie
14107,BRICQUEVILLE,14710,BRICQUEVILLE,,49.2893189025,-0.956918427952,107,,Bricqueville,Bricqueville,14,Calvados,28,Normandie
14109,BROUAY,14250,BROUAY,,49.2097089493,-0.562946879725,109,,Brouay,Brouay,14,Calvados,28,Normandie
14110,BRUCOURT,14160,BRUCOURT,,49.2468046177,-0.103823258822,110,,Brucourt,Brucourt,14,Calvados,28,Normandie
14111,BUCEELS,14250,BUCEELS,,49.1869481935,-0.639612687818,111,,Bucéels,Bucéels,14,Calvados,28,Normandie
14116,LE BU SUR ROUVRES,14190,LE BU SUR ROUVRES,,49.0324526222,-0.176721251591,116,Le,Bû-sur-Rouvres,Le Bû-sur-Rouvres,14,Calvados,28,Normandie
14117,CABOURG,14390,CABOURG,,49.2834863414,-0.125512945279,117,,Cabourg,Cabourg,14,Calvados,28,Normandie
14118,CAEN,14000,CAEN,,49.1847936737,-0.369801713036,118,,Caen,Caen,14,Calvados,28,Normandie
14119,CAGNY,14630,CAGNY,,49.154208668,-0.260672944073,119,,Cagny,Cagny,14,Calvados,28,Normandie
14120,CAHAGNES,14240,CAHAGNES,,49.0671203242,-0.762793901462,120,,Cahagnes,Cahagnes,14,Calvados,28,Normandie
14121,CAHAGNOLLES,14490,CAHAGNOLLES,,49.1633520253,-0.776151012142,121,,Cahagnolles,Cahagnolles,14,Calvados,28,Normandie
14122,LA CAINE,14210,LA CAINE,,49.0352374243,-0.515765831706,122,La,Caine,La Caine,14,Calvados,28,Normandie
14123,CAIRON,14610,CAIRON,,49.2341225093,-0.437898319221,123,,Cairon,Cairon,14,Calvados,28,Normandie
14124,LA CAMBE,14230,LA CAMBE,,49.3447032998,-1.0093041857,124,La,Cambe,La Cambe,14,Calvados,28,Normandie
14125,CAMBES EN PLAINE,14610,CAMBES EN PLAINE,,49.2314887593,-0.383466697712,125,,Cambes-en-Plaine,Cambes-en-Plaine,14,Calvados,28,Normandie
14126,CAMBREMER,14340,CAMBREMER,,49.1466063945,0.0539056758479,126,,Cambremer,Cambremer,14,Calvados,28,Normandie
14126,CAMBREMER,14340,CAMBREMER,GRANDOUET,49.1466063945,0.0539056758479,126,,Cambremer,Cambremer,14,Calvados,28,Normandie
14126,CAMBREMER,14340,CAMBREMER,ST AUBIN SUR ALGOT,49.1466063945,0.0539056758479,126,,Cambremer,Cambremer,14,Calvados,28,Normandie
14126,CAMBREMER,14340,CAMBREMER,ST PAIR DU MONT,49.1466063945,0.0539056758479,126,,Cambremer,Cambremer,14,Calvados,28,Normandie
14127,CAMPAGNOLLES,14500,CAMPAGNOLLES,,48.8893011028,-0.935281266873,127,,Campagnolles,Campagnolles,14,Calvados,28,Normandie
14128,CAMPANDRE VALCONGRAIN,14260,CAMPANDRE VALCONGRAIN,,48.98135248,-0.58496345565,128,,Campandré-Valcongrain,Campandré-Valcongrain,14,Calvados,28,Normandie
14130,CAMPIGNY,14490,CAMPIGNY,,49.2492188135,-0.800557652048,130,,Campigny,Campigny,14,Calvados,28,Normandie
14131,CANAPVILLE,14800,CANAPVILLE,,49.317093511,0.136082039095,131,,Canapville,Canapville,14,Calvados,28,Normandie
14132,CANCHY,14230,CANCHY,,49.3308597284,-0.984893554822,132,,Canchy,Canchy,14,Calvados,28,Normandie
14134,CANTELOUP,14370,CANTELOUP,,49.1366110489,-0.121312594699,134,,Canteloup,Canteloup,14,Calvados,28,Normandie
14135,CARCAGNY,14740,CARCAGNY,,49.2391999485,-0.61396538716,135,,Carcagny,Carcagny,14,Calvados,28,Normandie
14136,CARDONVILLE,14230,CARDONVILLE,,49.3459718219,-1.05341223436,136,,Cardonville,Cardonville,14,Calvados,28,Normandie
14137,CARPIQUET,14650,CARPIQUET,,49.1866163191,-0.451124708669,137,,Carpiquet,Carpiquet,14,Calvados,28,Normandie
14138,CARTIGNY L EPINAY,14330,CARTIGNY L EPINAY,,49.2392752495,-1.00695398636,138,,Cartigny-l'Épinay,Cartigny-l'Épinay,14,Calvados,28,Normandie
14140,CASTILLON,14490,CASTILLON,,49.1953923219,-0.797563519369,140,,Castillon,Castillon,14,Calvados,28,Normandie
14141,CASTILLON EN AUGE,14140,CASTILLON EN AUGE,,49.0346382534,0.0919325582529,141,,Castillon-en-Auge,Castillon-en-Auge,14,Calvados,28,Normandie
14142,CASTILLY,14330,CASTILLY,,49.2738212001,-1.01618091821,142,,Castilly,Castilly,14,Calvados,28,Normandie
14143,CAUMONT L EVENTE,14240,CAUMONT L EVENTE,,49.0964311403,-0.812430741234,143,,Caumont-sur-Aure,Caumont-sur-Aure,14,Calvados,28,Normandie
14145,CAUVICOURT,14190,CAUVICOURT,,49.0475103086,-0.259897379565,145,,Cauvicourt,Cauvicourt,14,Calvados,28,Normandie
14146,CAUVILLE,14770,CAUVILLE,,48.9518531525,-0.564152577979,146,,Cauville,Cauville,14,Calvados,28,Normandie
14147,CERNAY,14290,CERNAY,,49.0163802116,0.323907540392,147,,Cernay,Cernay,14,Calvados,28,Normandie
14149,CESNY AUX VIGNES,14270,CESNY AUX VIGNES,,49.086293057,-0.120979196135,149,,Cesny-aux-Vignes,Cesny-aux-Vignes,14,Calvados,28,Normandie
14150,CESNY BOIS HALBOUT,14220,CESNY BOIS HALBOUT,,48.9853267914,-0.394469235831,150,,Cesny-Bois-Halbout,Cesny-Bois-Halbout,14,Calvados,28,Normandie
14151,CHAMP DU BOULT,14380,CHAMP DU BOULT,,48.7900523867,-0.999943525576,151,,Champ-du-Boult,Champ-du-Boult,14,Calvados,28,Normandie
14157,CHEUX,14210,CHEUX,,49.169230558,-0.529372523327,157,,Cheux,Cheux,14,Calvados,28,Normandie
14158,CHICHEBOVILLE,14370,CHICHEBOVILLE,,49.1051343014,-0.219931014458,158,,Chicheboville,Chicheboville,14,Calvados,28,Normandie
14159,CHOUAIN,14250,CHOUAIN,,49.2097153188,-0.640731980971,159,,Chouain,Chouain,14,Calvados,28,Normandie
14160,CINTHEAUX,14680,CINTHEAUX,,49.0657733566,-0.283693859149,160,,Cintheaux,Cintheaux,14,Calvados,28,Normandie
14161,CLARBEC,14130,CLARBEC,,49.2411702401,0.134575376763,161,,Clarbec,Clarbec,14,Calvados,28,Normandie
14162,CLECY,14570,CLECY,,48.9025554763,-0.493582660892,162,,Clécy,Clécy,14,Calvados,28,Normandie
14163,CLEVILLE,14370,CLEVILLE,,49.1440586411,-0.0944377388042,163,,Cléville,Cléville,14,Calvados,28,Normandie
14164,CLINCHAMPS SUR ORNE,14320,CLINCHAMPS SUR ORNE,,49.0827905311,-0.403970928177,164,,Clinchamps-sur-Orne,Clinchamps-sur-Orne,14,Calvados,28,Normandie
14165,COLLEVILLE SUR MER,14710,COLLEVILLE SUR MER,,49.3503236918,-0.848462469065,165,,Colleville-sur-Mer,Colleville-sur-Mer,14,Calvados,28,Normandie
14166,COLLEVILLE MONTGOMERY,14880,COLLEVILLE MONTGOMERY,,49.2745125128,-0.301395397787,166,,Colleville-Montgomery,Colleville-Montgomery,14,Calvados,28,Normandie
14167,COLOMBELLES,14460,COLOMBELLES,,49.1982444899,-0.295509264279,167,,Colombelles,Colombelles,14,Calvados,28,Normandie
14168,COLOMBIERES,14710,COLOMBIERES,,49.3031615554,-0.988453131673,168,,Colombières,Colombières,14,Calvados,28,Normandie
14169,COLOMBIERS SUR SEULLES,14480,COLOMBIERS SUR SEULLES,,49.2992378071,-0.506001365428,169,,Colombiers-sur-Seulles,Colombiers-sur-Seulles,14,Calvados,28,Normandie
14171,COMBRAY,14220,COMBRAY,,48.9461421049,-0.445696929099,171,,Combray,Combray,14,Calvados,28,Normandie
14172,COMMES,14520,COMMES,,49.3349999304,-0.739011655856,172,,Commes,Commes,14,Calvados,28,Normandie
14173,CONDE SUR IFS,14270,CONDE SUR IFS,,49.0365024572,-0.139687850488,173,,Condé-sur-Ifs,Condé-sur-Ifs,14,Calvados,28,Normandie
14174,CONDE EN NORMANDIE,14110,CONDE EN NORMANDIE,,48.8496985115,-0.562504676497,174,,Condé-en-Normandie,Condé-en-Normandie,14,Calvados,28,Normandie
14174,CONDE EN NORMANDIE,14110,CONDE EN NORMANDIE,PROUSSY,48.8496985115,-0.562504676497,174,,Condé-en-Normandie,Condé-en-Normandie,14,Calvados,28,Normandie
14174,CONDE EN NORMANDIE,14110,CONDE EN NORMANDIE,ST GERMAIN DU CRIOULT,48.8496985115,-0.562504676497,174,,Condé-en-Normandie,Condé-en-Normandie,14,Calvados,28,Normandie
14174,CONDE EN NORMANDIE,14770,CONDE EN NORMANDIE,LA CHAPELLE ENGERBOLD,48.8496985115,-0.562504676497,174,,Condé-en-Normandie,Condé-en-Normandie,14,Calvados,28,Normandie
14174,CONDE EN NORMANDIE,14770,CONDE EN NORMANDIE,LENAULT,48.8496985115,-0.562504676497,174,,Condé-en-Normandie,Condé-en-Normandie,14,Calvados,28,Normandie
14174,CONDE EN NORMANDIE,14770,CONDE EN NORMANDIE,ST PIERRE LA VIEILLE,48.8496985115,-0.562504676497,174,,Condé-en-Normandie,Condé-en-Normandie,14,Calvados,28,Normandie
14175,CONDE SUR SEULLES,14400,CONDE SUR SEULLES,,49.2211365701,-0.641273751306,175,,Condé-sur-Seulles,Condé-sur-Seulles,14,Calvados,28,Normandie
14176,CONTEVILLE,14540,CONTEVILLE,,49.0890900332,-0.245186821417,176,,Conteville,Conteville,14,Calvados,28,Normandie
14177,COQUAINVILLIERS,14130,COQUAINVILLIERS,,49.2024600046,0.197315117058,177,,Coquainvilliers,Coquainvilliers,14,Calvados,28,Normandie
14179,CORDEBUGLE,14100,CORDEBUGLE,,49.1134254456,0.379835846184,179,,Cordebugle,Cordebugle,14,Calvados,28,Normandie
14180,CORDEY,14700,CORDEY,,48.8516569717,-0.222722260888,180,,Cordey,Cordey,14,Calvados,28,Normandie
14181,CORMELLES LE ROYAL,14123,CORMELLES LE ROYAL,,49.1547732827,-0.327418700171,181,,Cormelles-le-Royal,Cormelles-le-Royal,14,Calvados,28,Normandie
14182,CORMOLAIN,14240,CORMOLAIN,,49.1293685393,-0.863042429184,182,,Cormolain,Cormolain,14,Calvados,28,Normandie
14183,COSSESSEVILLE,14690,COSSESSEVILLE,,48.8946326135,-0.425187172224,183,,Cossesseville,Cossesseville,14,Calvados,28,Normandie
14184,COTTUN,14400,COTTUN,,49.2679469163,-0.785755484018,184,,Cottun,Cottun,14,Calvados,28,Normandie
14185,COUDRAY RABUT,14130,COUDRAY RABUT,,49.3036835114,0.180598886038,185,,Coudray-Rabut,Coudray-Rabut,14,Calvados,28,Normandie
14186,COULOMBS,14480,COULOMBS,,49.2487073696,-0.568008824876,186,,Coulombs,Coulombs,14,Calvados,28,Normandie
14189,COUPESARTE,14140,COUPESARTE,,49.0552846062,0.103766314916,189,,Coupesarte,Coupesarte,14,Calvados,28,Normandie
14190,COURCY,14170,COURCY,,48.9695873658,-0.0337679175987,190,,Courcy,Courcy,14,Calvados,28,Normandie
14191,COURSEULLES SUR MER,14470,COURSEULLES SUR MER,,49.3205636426,-0.44837253643,191,,Courseulles-sur-Mer,Courseulles-sur-Mer,14,Calvados,28,Normandie
14192,COURSON,14380,COURSON,,48.8527420218,-1.08893219913,192,,Courson,Courson,14,Calvados,28,Normandie
14193,COURTONNE LA MEURDRAC,14100,COURTONNE LA MEURDRAC,,49.1202461329,0.329059637996,193,,Courtonne-la-Meurdrac,Courtonne-la-Meurdrac,14,Calvados,28,Normandie
14194,COURTONNE LES DEUX EGLISES,14290,COURTONNE LES DEUX EGLISES,,49.0888311326,0.37808461753,194,,Courtonne-les-Deux-Églises,Courtonne-les-Deux-Églises,14,Calvados,28,Normandie
14194,COURTONNE LES DEUX EGLISES,14290,COURTONNE LES DEUX EGLISES,ST PAUL DE COURTONNE,49.0888311326,0.37808461753,194,,Courtonne-les-Deux-Églises,Courtonne-les-Deux-Églises,14,Calvados,28,Normandie
14195,COURVAUDON,14260,COURVAUDON,,49.026847125,-0.573849021265,195,,Courvaudon,Courvaudon,14,Calvados,28,Normandie
14196,CREPON,14480,CREPON,,49.314511496,-0.545657702381,196,,Crépon,Crépon,14,Calvados,28,Normandie
14197,CRESSERONS,14440,CRESSERONS,,49.2876312473,-0.354257196415,197,,Cresserons,Cresserons,14,Calvados,28,Normandie
14198,CRESSEVEUILLE,14430,CRESSEVEUILLE,,49.2387417598,0.0117533890211,198,,Cresseveuille,Cresseveuille,14,Calvados,28,Normandie
14200,CREULLY,14480,CREULLY,,49.2864524812,-0.543327621635,200,,Creully sur Seulles,Creully sur Seulles,14,Calvados,28,Normandie
14201,CREVECOEUR EN AUGE,14340,CREVECOEUR EN AUGE,,49.1235516932,0.0205660147705,201,,Crèvecœur-en-Auge,Crèvecœur-en-Auge,14,Calvados,28,Normandie
14202,CRICQUEBOEUF,14113,CRICQUEBOEUF,,49.3965340563,0.139094735146,202,,Cricquebœuf,Cricquebœuf,14,Calvados,28,Normandie
14203,CRICQUEVILLE EN AUGE,14430,CRICQUEVILLE EN AUGE,,49.2371263261,-0.0732802659784,203,,Cricqueville-en-Auge,Cricqueville-en-Auge,14,Calvados,28,Normandie
14204,CRICQUEVILLE EN BESSIN,14450,CRICQUEVILLE EN BESSIN,,49.3793344286,-1.00061282058,204,,Cricqueville-en-Bessin,Cricqueville-en-Bessin,14,Calvados,28,Normandie
14205,CRISTOT,14250,CRISTOT,,49.1941809977,-0.570855686258,205,,Cristot,Cristot,14,Calvados,28,Normandie
14206,CROCY,14620,CROCY,,48.8770844881,-0.0517680920662,206,,Crocy,Crocy,14,Calvados,28,Normandie
14207,CROISILLES,14220,CROISILLES,,49.0034155904,-0.452080807326,207,,Croisilles,Croisilles,14,Calvados,28,Normandie
14208,CROISSANVILLE,14370,CROISSANVILLE,,49.1119995118,-0.108655295022,208,,Croissanville,Croissanville,14,Calvados,28,Normandie
14209,CROUAY,14400,CROUAY,,49.2690354674,-0.817371239475,209,,Crouay,Crouay,14,Calvados,28,Normandie
14211,CULEY LE PATRY,14220,CULEY LE PATRY,,48.9563571016,-0.531158873693,211,,Culey-le-Patry,Culey-le-Patry,14,Calvados,28,Normandie
14212,CULLY,14480,CULLY,,49.2504600647,-0.527911089923,212,,Cully,Cully,14,Calvados,28,Normandie
14214,CUSSY,14400,CUSSY,,49.2837425646,-0.762152432612,214,,Cussy,Cussy,14,Calvados,28,Normandie
14215,CUVERVILLE,14840,CUVERVILLE,,49.1917821033,-0.261435495897,215,,Cuverville,Cuverville,14,Calvados,28,Normandie
14216,DAMBLAINVILLE,14620,DAMBLAINVILLE,,48.9219313717,-0.126876878581,216,,Damblainville,Damblainville,14,Calvados,28,Normandie
14217,DAMPIERRE,14350,DAMPIERRE,,49.04149665,-0.86631773085,217,,Dampierre,Dampierre,14,Calvados,28,Normandie
14218,DANESTAL,14430,DANESTAL,,49.2435804635,0.0329636078608,218,,Danestal,Danestal,14,Calvados,28,Normandie
14219,DANVOU LA FERRIERE,14770,DANVOU LA FERRIERE,,48.963907646,-0.679740681534,219,,Danvou-la-Ferrière,Danvou-la-Ferrière,14,Calvados,28,Normandie
14219,DANVOU LA FERRIERE,14770,DANVOU LA FERRIERE,LA FERRIERE DUVAL,48.963907646,-0.679740681534,219,,Danvou-la-Ferrière,Danvou-la-Ferrière,14,Calvados,28,Normandie
14220,DEAUVILLE,14800,DEAUVILLE,,49.3543800887,0.0744665786308,220,,Deauville,Deauville,14,Calvados,28,Normandie
14221,DEMOUVILLE,14840,DEMOUVILLE,,49.1778085857,-0.263135480926,221,,Démouville,Démouville,14,Calvados,28,Normandie
14223,LE DETROIT,14690,LE DETROIT,,48.8738596732,-0.345051519298,223,Le,Détroit,Le Détroit,14,Calvados,28,Normandie
14224,DEUX JUMEAUX,14230,DEUX JUMEAUX,,49.3547956014,-0.968791450871,224,,Deux-Jumeaux,Deux-Jumeaux,14,Calvados,28,Normandie
14225,DIVES SUR MER,14160,DIVES SUR MER,,49.2829553851,-0.0891775344573,225,,Dives-sur-Mer,Dives-sur-Mer,14,Calvados,28,Normandie
14226,DONNAY,14220,DONNAY,,48.9469344961,-0.41732907803,226,,Donnay,Donnay,14,Calvados,28,Normandie
14227,DOUVILLE EN AUGE,14430,DOUVILLE EN AUGE,,49.2639806447,-0.0212654288375,227,,Douville-en-Auge,Douville-en-Auge,14,Calvados,28,Normandie
14228,DOUVRES LA DELIVRANDE,14440,DOUVRES LA DELIVRANDE,,49.2895943056,-0.39232887843,228,,Douvres-la-Délivrande,Douvres-la-Délivrande,14,Calvados,28,Normandie
14228,DOUVRES LA DELIVRANDE,14440,DOUVRES LA DELIVRANDE,TAILLEVILLE,49.2895943056,-0.39232887843,228,,Douvres-la-Délivrande,Douvres-la-Délivrande,14,Calvados,28,Normandie
14229,DOZULE,14430,DOZULE,,49.2290318466,-0.0448932247721,229,,Dozulé,Dozulé,14,Calvados,28,Normandie
14230,DRUBEC,14130,DRUBEC,,49.2496863496,0.104043882828,230,,Drubec,Drubec,14,Calvados,28,Normandie
14231,BEAUFOUR DRUVAL,14340,BEAUFOUR DRUVAL,,49.21417643,0.0234847434149,231,,Beaufour-Druval,Beaufour-Druval,14,Calvados,28,Normandie
14231,BEAUFOUR DRUVAL,14340,BEAUFOUR DRUVAL,BEAUFOUR,49.21417643,0.0234847434149,231,,Beaufour-Druval,Beaufour-Druval,14,Calvados,28,Normandie
14231,BEAUFOUR DRUVAL,14340,BEAUFOUR DRUVAL,ST AUBIN LEBIZAY,49.21417643,0.0234847434149,231,,Beaufour-Druval,Beaufour-Druval,14,Calvados,28,Normandie
14232,DUCY STE MARGUERITE,14250,DUCY STE MARGUERITE,,49.2258240786,-0.611130880075,232,,Ducy-Sainte-Marguerite,Ducy-Sainte-Marguerite,14,Calvados,28,Normandie
14235,ECRAMMEVILLE,14710,ECRAMMEVILLE,,49.3216546397,-0.942347542858,235,,Écrammeville,Écrammeville,14,Calvados,28,Normandie
14236,ELLON,14250,ELLON,,49.2276401197,-0.68136443609,236,,Ellon,Ellon,14,Calvados,28,Normandie
14237,EMIEVILLE,14630,EMIEVILLE,,49.1536061686,-0.224040672945,237,,Émiéville,Émiéville,14,Calvados,28,Normandie
14238,ENGLESQUEVILLE EN AUGE,14800,ENGLESQUEVILLE EN AUGE,,49.3318566528,0.149882595019,238,,Englesqueville-en-Auge,Englesqueville-en-Auge,14,Calvados,28,Normandie
14239,ENGLESQUEVILLE LA PERCEE,14710,ENGLESQUEVILLE LA PERCEE,,49.3794043122,-0.951377407959,239,,Englesqueville-la-Percée,Englesqueville-la-Percée,14,Calvados,28,Normandie
14240,EPANEY,14170,EPANEY,,48.9454875275,-0.159422504691,240,,Épaney,Épaney,14,Calvados,28,Normandie
14241,EPINAY SUR ODON,14310,EPINAY SUR ODON,,49.0688314523,-0.619078817289,241,,Épinay-sur-Odon,Épinay-sur-Odon,14,Calvados,28,Normandie
14242,EPRON,14610,EPRON,,49.2200299835,-0.364587830546,242,,Épron,Épron,14,Calvados,28,Normandie
14243,EQUEMAUVILLE,14600,EQUEMAUVILLE,,49.4031514807,0.209917592829,243,,Équemauville,Équemauville,14,Calvados,28,Normandie
14244,ERAINES,14700,ERAINES,,48.9048554681,-0.157109731827,244,,Eraines,Eraines,14,Calvados,28,Normandie
14245,ERNES,14270,ERNES,,49.0114314442,-0.125222684946,245,,Ernes,Ernes,14,Calvados,28,Normandie
14246,ESCOVILLE,14850,ESCOVILLE,,49.2057509185,-0.245625093928,246,,Escoville,Escoville,14,Calvados,28,Normandie
14248,ESPINS,14220,ESPINS,,48.9996788,-0.414663338937,248,,Espins,Espins,14,Calvados,28,Normandie
14249,ESQUAY NOTRE DAME,14210,ESQUAY NOTRE DAME,,49.112806588,-0.471107823804,249,,Esquay-Notre-Dame,Esquay-Notre-Dame,14,Calvados,28,Normandie
14250,ESQUAY SUR SEULLES,14400,ESQUAY SUR SEULLES,,49.2716519336,-0.622418899697,250,,Esquay-sur-Seulles,Esquay-sur-Seulles,14,Calvados,28,Normandie
14251,ESSON,14220,ESSON,,48.9690516026,-0.459505012941,251,,Esson,Esson,14,Calvados,28,Normandie
14252,ESTREES LA CAMPAGNE,14190,ESTREES LA CAMPAGNE,,49.0079918917,-0.243058159151,252,,Estrées-la-Campagne,Estrées-la-Campagne,14,Calvados,28,Normandie
14254,ETERVILLE,14930,ETERVILLE,,49.1478744808,-0.428813516348,254,,Éterville,Éterville,14,Calvados,28,Normandie
14256,ETREHAM,14400,ETREHAM,,49.3208069288,-0.793272480023,256,,Étréham,Étréham,14,Calvados,28,Normandie
14257,EVRECY,14210,EVRECY,,49.0940016709,-0.501372307136,257,,Évrecy,Évrecy,14,Calvados,28,Normandie
14258,FALAISE,14700,FALAISE,,48.8957800281,-0.193401711782,258,,Falaise,Falaise,14,Calvados,28,Normandie
14260,FAUGUERNON,14100,FAUGUERNON,,49.1890209562,0.268855843357,260,,Fauguernon,Fauguernon,14,Calvados,28,Normandie
14261,LE FAULQ,14130,LE FAULQ,,49.2387229175,0.317924575048,261,Le,Faulq,Le Faulq,14,Calvados,28,Normandie
14266,FEUGUEROLLES BULLY,14320,FEUGUEROLLES BULLY,,49.1119321886,-0.407584971568,266,,Feuguerolles-Bully,Feuguerolles-Bully,14,Calvados,28,Normandie
14266,FEUGUEROLLES BULLY,14320,FEUGUEROLLES BULLY,BULLY,49.1119321886,-0.407584971568,266,,Feuguerolles-Bully,Feuguerolles-Bully,14,Calvados,28,Normandie
14268,FIERVILLE BRAY,14190,FIERVILLE BRAY,,49.0647146044,-0.179594065485,268,,Fierville-Bray,Fierville-Bray,14,Calvados,28,Normandie
14268,FIERVILLE BRAY,14190,FIERVILLE BRAY,BRAY LA CAMPAGNE,49.0647146044,-0.179594065485,268,,Fierville-Bray,Fierville-Bray,14,Calvados,28,Normandie
14269,FIERVILLE LES PARCS,14130,FIERVILLE LES PARCS,,49.2438838452,0.230712490551,269,,Fierville-les-Parcs,Fierville-les-Parcs,14,Calvados,28,Normandie
14270,FIRFOL,14100,FIRFOL,,49.1520628876,0.321640339093,270,,Firfol,Firfol,14,Calvados,28,Normandie
14271,FLEURY SUR ORNE,14123,FLEURY SUR ORNE,,49.1444292345,-0.377619145275,271,,Fleury-sur-Orne,Fleury-sur-Orne,14,Calvados,28,Normandie
14272,LA FOLIE,14710,LA FOLIE,,49.2627408591,-0.970341008154,272,La,Folie,La Folie,14,Calvados,28,Normandie
14273,LA FOLLETIERE ABENON,14290,LA FOLLETIERE ABENON,,48.973853559,0.408000802048,273,La,Folletière-Abenon,La Folletière-Abenon,14,Calvados,28,Normandie
14274,FONTAINE ETOUPEFOUR,14790,FONTAINE ETOUPEFOUR,,49.1379993992,-0.454203302795,274,,Fontaine-Étoupefour,Fontaine-Étoupefour,14,Calvados,28,Normandie
14275,FONTAINE HENRY,14610,FONTAINE HENRY,,49.2777846535,-0.465842356858,275,,Fontaine-Henry,Fontaine-Henry,14,Calvados,28,Normandie
14276,FONTAINE LE PIN,14190,FONTAINE LE PIN,,48.975148023,-0.291372010508,276,,Fontaine-le-Pin,Fontaine-le-Pin,14,Calvados,28,Normandie
14277,FONTENAY LE MARMION,14320,FONTENAY LE MARMION,,49.0870178586,-0.34496703891,277,,Fontenay-le-Marmion,Fontenay-le-Marmion,14,Calvados,28,Normandie
14278,FONTENAY LE PESNEL,14250,FONTENAY LE PESNEL,,49.1736040754,-0.580438390492,278,,Fontenay-le-Pesnel,Fontenay-le-Pesnel,14,Calvados,28,Normandie
14279,FONTENERMONT,14380,FONTENERMONT,,48.8211507511,-1.10018701267,279,,Fontenermont,Fontenermont,14,Calvados,28,Normandie
14280,FORMENTIN,14340,FORMENTIN,,49.2030787417,0.135813686591,280,,Formentin,Formentin,14,Calvados,28,Normandie
14281,FORMIGNY,14710,FORMIGNY,,49.3358463072,-0.896417546016,281,,Formigny La Bataille,Formigny La Bataille,14,Calvados,28,Normandie
14282,FOULOGNES,14240,FOULOGNES,,49.1398449339,-0.805956268367,282,,Foulognes,Foulognes,14,Calvados,28,Normandie
14283,FOURCHES,14620,FOURCHES,,48.8585273495,-0.0742432451781,283,,Fourches,Fourches,14,Calvados,28,Normandie
14284,FOURNEAUX LE VAL,14700,FOURNEAUX LE VAL,,48.8625465885,-0.269829926137,284,,Fourneaux-le-Val,Fourneaux-le-Val,14,Calvados,28,Normandie
14285,LE FOURNET,14340,LE FOURNET,,49.2002891187,0.111722528216,285,Le,Fournet,Le Fournet,14,Calvados,28,Normandie
14286,FOURNEVILLE,14600,FOURNEVILLE,,49.3575079529,0.232481397585,286,,Fourneville,Fourneville,14,Calvados,28,Normandie
14287,FRENOUVILLE,14630,FRENOUVILLE,,49.132745489,-0.249029686831,287,,Frénouville,Frénouville,14,Calvados,28,Normandie
14288,LE FRESNE CAMILLY,14480,LE FRESNE CAMILLY,,49.2562338542,-0.487666830521,288,Le,Fresne-Camilly,Le Fresne-Camilly,14,Calvados,28,Normandie
14289,FRESNE LA MERE,14700,FRESNE LA MERE,,48.8880480669,-0.125057878602,289,,Fresné-la-Mère,Fresné-la-Mère,14,Calvados,28,Normandie
14290,FRESNEY LE PUCEUX,14680,FRESNEY LE PUCEUX,,49.0660952631,-0.3530701321,290,,Fresney-le-Puceux,Fresney-le-Puceux,14,Calvados,28,Normandie
14291,FRESNEY LE VIEUX,14220,FRESNEY LE VIEUX,,49.0051029939,-0.38627329445,291,,Fresney-le-Vieux,Fresney-le-Vieux,14,Calvados,28,Normandie
14293,FUMICHON,14590,FUMICHON,,49.1702401097,0.376680814752,293,,Fumichon,Fumichon,14,Calvados,28,Normandie
14294,GARCELLES SECQUEVILLE,14540,GARCELLES SECQUEVILLE,,49.096717576,-0.28075370018,294,,Garcelles-Secqueville,Garcelles-Secqueville,14,Calvados,28,Normandie
14296,LE GAST,14380,LE GAST,,48.7918183256,-1.06068857386,296,Le,Gast,Le Gast,14,Calvados,28,Normandie
14297,GAVRUS,14210,GAVRUS,,49.1167295393,-0.507962422295,297,,Gavrus,Gavrus,14,Calvados,28,Normandie
14298,GEFOSSE FONTENAY,14230,GEFOSSE FONTENAY,,49.3614124958,-1.0850580278,298,,Géfosse-Fontenay,Géfosse-Fontenay,14,Calvados,28,Normandie
14299,GENNEVILLE,14600,GENNEVILLE,,49.3598603886,0.273712320272,299,,Genneville,Genneville,14,Calvados,28,Normandie
14300,GERROTS,14430,GERROTS,,49.1935491536,0.00245764968859,300,,Gerrots,Gerrots,14,Calvados,28,Normandie
14301,GIBERVILLE,14730,GIBERVILLE,,49.1781576948,-0.285160708636,301,,Giberville,Giberville,14,Calvados,28,Normandie
14302,GLANVILLE,14950,GLANVILLE,,49.2767710581,0.0750189891546,302,,Glanville,Glanville,14,Calvados,28,Normandie
14303,GLOS,14100,GLOS,,49.1247880255,0.28133061061,303,,Glos,Glos,14,Calvados,28,Normandie
14304,GONNEVILLE SUR HONFLEUR,14600,GONNEVILLE SUR HONFLEUR,,49.381480242,0.236373405465,304,,Gonneville-sur-Honfleur,Gonneville-sur-Honfleur,14,Calvados,28,Normandie
14305,GONNEVILLE SUR MER,14510,GONNEVILLE SUR MER,,49.2895459348,-0.0363153826405,305,,Gonneville-sur-Mer,Gonneville-sur-Mer,14,Calvados,28,Normandie
14306,GONNEVILLE EN AUGE,14810,GONNEVILLE EN AUGE,,49.2592446922,-0.188358824575,306,,Gonneville-en-Auge,Gonneville-en-Auge,14,Calvados,28,Normandie
14307,GOUPILLIERES,14210,GOUPILLIERES,,49.0323973591,-0.479373998591,307,,Goupillières,Goupillières,14,Calvados,28,Normandie
14308,GOUSTRANVILLE,14430,GOUSTRANVILLE,,49.2227892778,-0.108383385209,308,,Goustranville,Goustranville,14,Calvados,28,Normandie
14309,GOUVIX,14680,GOUVIX,,49.0365523786,-0.308971879429,309,,Gouvix,Gouvix,14,Calvados,28,Normandie
14310,GRAINVILLE LANGANNERIE,14190,GRAINVILLE LANGANNERIE,,49.009033334,-0.271865445173,310,,Grainville-Langannerie,Grainville-Langannerie,14,Calvados,28,Normandie
14311,GRAINVILLE SUR ODON,14210,GRAINVILLE SUR ODON,,49.1386270116,-0.531672189444,311,,Grainville-sur-Odon,Grainville-sur-Odon,14,Calvados,28,Normandie
14312,GRANDCAMP MAISY,14450,GRANDCAMP MAISY,,49.3754390087,-1.04464900141,312,,Grandcamp-Maisy,Grandcamp-Maisy,14,Calvados,28,Normandie
14312,GRANDCAMP MAISY,14450,GRANDCAMP MAISY,MAISY,49.3754390087,-1.04464900141,312,,Grandcamp-Maisy,Grandcamp-Maisy,14,Calvados,28,Normandie
14313,GRANDCHAMP LE CHATEAU,14140,GRANDCHAMP LE CHATEAU,,49.0843788037,0.075363026467,313,,Grandchamp-le-Château,Grandchamp-le-Château,14,Calvados,28,Normandie
14316,GRANGUES,14160,GRANGUES,,49.2620621593,-0.0581924167296,316,,Grangues,Grangues,14,Calvados,28,Normandie
14318,GRAYE SUR MER,14470,GRAYE SUR MER,,49.3293694096,-0.486683718791,318,,Graye-sur-Mer,Graye-sur-Mer,14,Calvados,28,Normandie
14319,GRENTHEVILLE,14540,GRENTHEVILLE,,49.1460180153,-0.293578977625,319,,Grentheville,Grentheville,14,Calvados,28,Normandie
14320,GRIMBOSQ,14220,GRIMBOSQ,,49.0446792733,-0.443624384517,320,,Grimbosq,Grimbosq,14,Calvados,28,Normandie
14322,GUERON,14400,GUERON,,49.2476651518,-0.709843889765,322,,Guéron,Guéron,14,Calvados,28,Normandie
14325,HERMANVILLE SUR MER,14880,HERMANVILLE SUR MER,,49.2832471592,-0.32297738333,325,,Hermanville-sur-Mer,Hermanville-sur-Mer,14,Calvados,28,Normandie
14326,HERMIVAL LES VAUX,14100,HERMIVAL LES VAUX,,49.1662017057,0.291278344411,326,,Hermival-les-Vaux,Hermival-les-Vaux,14,Calvados,28,Normandie
14327,HEROUVILLE ST CLAIR,14200,HEROUVILLE ST CLAIR,,49.2073560619,-0.331022626025,327,,Hérouville-Saint-Clair,Hérouville-Saint-Clair,14,Calvados,28,Normandie
14328,HEROUVILLETTE,14850,HEROUVILLETTE,,49.2140427905,-0.260172250622,328,,Hérouvillette,Hérouvillette,14,Calvados,28,Normandie
14329,HEULAND,14430,HEULAND,,49.2648018759,-0.00106358427932,329,,Heuland,Heuland,14,Calvados,28,Normandie
14331,HIEVILLE,14170,HIEVILLE,,49.0222258456,-0.0030449548537,331,,Hiéville,Hiéville,14,Calvados,28,Normandie
14332,LA HOGUETTE,14700,LA HOGUETTE,,48.8530698263,-0.160675825982,332,La,Hoguette,La Hoguette,14,Calvados,28,Normandie
14333,HONFLEUR,14600,HONFLEUR,,49.4129449727,0.237579361279,333,,Honfleur,Honfleur,14,Calvados,28,Normandie
14333,HONFLEUR,14600,HONFLEUR,VASOUY,49.4129449727,0.237579361279,333,,Honfleur,Honfleur,14,Calvados,28,Normandie
14334,L HOTELLERIE,14100,L HOTELLERIE,,49.140980571,0.407126860632,334,L',Hôtellerie,L'Hôtellerie,14,Calvados,28,Normandie
14335,HOTOT EN AUGE,14430,HOTOT EN AUGE,,49.1774252803,-0.076370337762,335,,Hotot-en-Auge,Hotot-en-Auge,14,Calvados,28,Normandie
14335,HOTOT EN AUGE,14430,HOTOT EN AUGE,BROCOTTES,49.1774252803,-0.076370337762,335,,Hotot-en-Auge,Hotot-en-Auge,14,Calvados,28,Normandie
14335,HOTOT EN AUGE,14430,HOTOT EN AUGE,LE HAM,49.1774252803,-0.076370337762,335,,Hotot-en-Auge,Hotot-en-Auge,14,Calvados,28,Normandie
14336,HOTTOT LES BAGUES,14250,HOTTOT LES BAGUES,,49.1460867246,-0.653546590924,336,,Hottot-les-Bagues,Hottot-les-Bagues,14,Calvados,28,Normandie
14337,LA HOUBLONNIERE,14340,LA HOUBLONNIERE,,49.1233026249,0.102461250649,337,La,Houblonnière,La Houblonnière,14,Calvados,28,Normandie
14338,HOULGATE,14510,HOULGATE,,49.2974071263,-0.0677562139709,338,,Houlgate,Houlgate,14,Calvados,28,Normandie
14339,HUBERT FOLIE,14540,HUBERT FOLIE,,49.1284545506,-0.316040778146,339,,Hubert-Folie,Hubert-Folie,14,Calvados,28,Normandie
14341,IFS,14123,IFS,,49.1405779091,-0.342694064356,341,,Ifs,Ifs,14,Calvados,28,Normandie
14342,ISIGNY SUR MER,14230,ISIGNY SUR MER,,49.305761488,-1.10256916512,342,,Isigny-sur-Mer,Isigny-sur-Mer,14,Calvados,28,Normandie
14343,LES ISLES BARDEL,14690,LES ISLES BARDEL,,48.837428826,-0.349054919014,343,Les,Isles-Bardel,Les Isles-Bardel,14,Calvados,28,Normandie
14344,JANVILLE,14670,JANVILLE,,49.1637989299,-0.16374713319,344,,Janville,Janville,14,Calvados,28,Normandie
14345,JORT,14170,JORT,,48.9733366542,-0.0728894346449,345,,Jort,Jort,14,Calvados,28,Normandie
14346,JUAYE MONDAYE,14250,JUAYE MONDAYE,,49.2049289957,-0.690556905355,346,,Juaye-Mondaye,Juaye-Mondaye,14,Calvados,28,Normandie
14347,JURQUES,14260,JURQUES,,49.0211940681,-0.745768584372,347,,Dialan sur Chaîne,Dialan sur Chaîne,14,Calvados,28,Normandie
14348,JUVIGNY SUR SEULLES,14250,JUVIGNY SUR SEULLES,,49.1556573472,-0.60264289279,348,,Juvigny-sur-Seulles,Juvigny-sur-Seulles,14,Calvados,28,Normandie
14349,LAIZE LA VILLE,14320,LAIZE LA VILLE,,49.0801189772,-0.380625006843,349,,Laize-Clinchamps,Laize-Clinchamps,14,Calvados,28,Normandie
14350,LA LANDE SUR DROME,14240,LA LANDE SUR DROME,,49.0732578587,-0.860019640388,350,La,Lande-sur-Drôme,La Lande-sur-Drôme,14,Calvados,28,Normandie
14352,LANDELLES ET COUPIGNY,14380,LANDELLES ET COUPIGNY,,48.8983991959,-1.00295458631,352,,Landelles-et-Coupigny,Landelles-et-Coupigny,14,Calvados,28,Normandie
14352,LANDELLES ET COUPIGNY,14380,LANDELLES ET COUPIGNY,ANNEBECQ,48.8983991959,-1.00295458631,352,,Landelles-et-Coupigny,Landelles-et-Coupigny,14,Calvados,28,Normandie
14353,LANDES SUR AJON,14310,LANDES SUR AJON,,49.0764930266,-0.572022073855,353,,Landes-sur-Ajon,Landes-sur-Ajon,14,Calvados,28,Normandie
14354,LANGRUNE SUR MER,14830,LANGRUNE SUR MER,,49.3129812752,-0.378266023421,354,,Langrune-sur-Mer,Langrune-sur-Mer,14,Calvados,28,Normandie
14355,LANTHEUIL,14480,LANTHEUIL,,49.2671813698,-0.516107321362,355,,Ponts sur Seulles,Ponts sur Seulles,14,Calvados,28,Normandie
14357,LASSY,14770,LASSY,,48.9136477151,-0.684142611751,357,,Terres de Druance,Terres de Druance,14,Calvados,28,Normandie
14358,LEAUPARTIE,14340,LEAUPARTIE,,49.1802281883,0.0527677741155,358,,Léaupartie,Léaupartie,14,Calvados,28,Normandie
14359,LECAUDE,14140,LECAUDE,,49.1038503615,0.084634441559,359,,Lécaude,Lécaude,14,Calvados,28,Normandie
14360,LEFFARD,14700,LEFFARD,,48.9163712497,-0.297239121567,360,,Leffard,Leffard,14,Calvados,28,Normandie
14362,LESSARD ET LE CHENE,14140,LESSARD ET LE CHENE,,49.0709184093,0.13127569904,362,,Lessard-et-le-Chêne,Lessard-et-le-Chêne,14,Calvados,28,Normandie
14364,LINGEVRES,14250,LINGEVRES,,49.1732681991,-0.674509816697,364,,Lingèvres,Lingèvres,14,Calvados,28,Normandie
14365,LION SUR MER,14780,LION SUR MER,,49.3007695684,-0.333954811691,365,,Lion-sur-Mer,Lion-sur-Mer,14,Calvados,28,Normandie
14366,LISIEUX,14100,LISIEUX,,49.1466628463,0.238274840452,366,,Lisieux,Lisieux,14,Calvados,28,Normandie
14367,LISON,14330,LISON,,49.2416115428,-1.05227521877,367,,Lison,Lison,14,Calvados,28,Normandie
14368,LISORES,14140,LISORES,,48.956342574,0.211979306387,368,,Lisores,Lisores,14,Calvados,28,Normandie
14369,LITTEAU,14490,LITTEAU,,49.1530752731,-0.909894193512,369,,Litteau,Litteau,14,Calvados,28,Normandie
14370,LE MOLAY LITTRY,14330,LE MOLAY LITTRY,,49.2344021109,-0.889241335163,370,Le,Molay-Littry,Le Molay-Littry,14,Calvados,28,Normandie
14370,LE MOLAY LITTRY,14330,LE MOLAY LITTRY,LE MOLAY,49.2344021109,-0.889241335163,370,Le,Molay-Littry,Le Molay-Littry,14,Calvados,28,Normandie
14371,LIVAROT PAYS D AUGE,14140,LIVAROT PAYS D AUGE,AUQUAINVILLE,49.005159699,0.165980940625,371,,Livarot-Pays-d'Auge,Livarot-Pays-d'Auge,14,Calvados,28,Normandie
14371,LIVAROT PAYS D AUGE,14140,LIVAROT PAYS D AUGE,BELLOU,49.005159699,0.165980940625,371,,Livarot-Pays-d'Auge,Livarot-Pays-d'Auge,14,Calvados,28,Normandie
14371,LIVAROT PAYS D AUGE,14140,LIVAROT PAYS D AUGE,CHEFFREVILLE TONNENCOURT,49.005159699,0.165980940625,371,,Livarot-Pays-d'Auge,Livarot-Pays-d'Auge,14,Calvados,28,Normandie
14371,LIVAROT PAYS D AUGE,14140,LIVAROT PAYS D AUGE,FERVAQUES,49.005159699,0.165980940625,371,,Livarot-Pays-d'Auge,Livarot-Pays-d'Auge,14,Calvados,28,Normandie
14371,LIVAROT PAYS D AUGE,14140,LIVAROT PAYS D AUGE,HEURTEVENT,49.005159699,0.165980940625,371,,Livarot-Pays-d'Auge,Livarot-Pays-d'Auge,14,Calvados,28,Normandie
14371,LIVAROT PAYS D AUGE,14140,LIVAROT PAYS D AUGE,LA CROUPTE,49.005159699,0.165980940625,371,,Livarot-Pays-d'Auge,Livarot-Pays-d'Auge,14,Calvados,28,Normandie
14371,LIVAROT PAYS D AUGE,14140,LIVAROT PAYS D AUGE,LE MESNIL BACLEY,49.005159699,0.165980940625,371,,Livarot-Pays-d'Auge,Livarot-Pays-d'Auge,14,Calvados,28,Normandie
14371,LIVAROT PAYS D AUGE,14140,LIVAROT PAYS D AUGE,LE MESNIL DURAND,49.005159699,0.165980940625,371,,Livarot-Pays-d'Auge,Livarot-Pays-d'Auge,14,Calvados,28,Normandie
14371,LIVAROT PAYS D AUGE,14140,LIVAROT PAYS D AUGE,LE MESNIL GERMAIN,49.005159699,0.165980940625,371,,Livarot-Pays-d'Auge,Livarot-Pays-d'Auge,14,Calvados,28,Normandie
14371,LIVAROT PAYS D AUGE,14140,LIVAROT PAYS D AUGE,LES AUTELS ST BAZILE,49.005159699,0.165980940625,371,,Livarot-Pays-d'Auge,Livarot-Pays-d'Auge,14,Calvados,28,Normandie
14371,LIVAROT PAYS D AUGE,14140,LIVAROT PAYS D AUGE,LES MOUTIERS HUBERT,49.005159699,0.165980940625,371,,Livarot-Pays-d'Auge,Livarot-Pays-d'Auge,14,Calvados,28,Normandie
14371,LIVAROT PAYS D AUGE,14140,LIVAROT PAYS D AUGE,LIVAROT,49.005159699,0.165980940625,371,,Livarot-Pays-d'Auge,Livarot-Pays-d'Auge,14,Calvados,28,Normandie
14371,LIVAROT PAYS D AUGE,14140,LIVAROT PAYS D AUGE,NOTRE DAME DE COURSON,49.005159699,0.165980940625,371,,Livarot-Pays-d'Auge,Livarot-Pays-d'Auge,14,Calvados,28,Normandie
14371,LIVAROT PAYS D AUGE,14140,LIVAROT PAYS D AUGE,STE MARGUERITE DES LOGES,49.005159699,0.165980940625,371,,Livarot-Pays-d'Auge,Livarot-Pays-d'Auge,14,Calvados,28,Normandie
14371,LIVAROT PAYS D AUGE,14140,LIVAROT PAYS D AUGE,ST MARTIN DU MESNIL OURY,49.005159699,0.165980940625,371,,Livarot-Pays-d'Auge,Livarot-Pays-d'Auge,14,Calvados,28,Normandie
14371,LIVAROT PAYS D AUGE,14140,LIVAROT PAYS D AUGE,ST MICHEL DE LIVET,49.005159699,0.165980940625,371,,Livarot-Pays-d'Auge,Livarot-Pays-d'Auge,14,Calvados,28,Normandie
14371,LIVAROT PAYS D AUGE,14140,LIVAROT PAYS D AUGE,ST OUEN LE HOUX,49.005159699,0.165980940625,371,,Livarot-Pays-d'Auge,Livarot-Pays-d'Auge,14,Calvados,28,Normandie
14371,LIVAROT PAYS D AUGE,14140,LIVAROT PAYS D AUGE,TORTISAMBERT,49.005159699,0.165980940625,371,,Livarot-Pays-d'Auge,Livarot-Pays-d'Auge,14,Calvados,28,Normandie
14371,LIVAROT PAYS D AUGE,14290,LIVAROT PAYS D AUGE,CERQUEUX,49.005159699,0.165980940625,371,,Livarot-Pays-d'Auge,Livarot-Pays-d'Auge,14,Calvados,28,Normandie
14371,LIVAROT PAYS D AUGE,14290,LIVAROT PAYS D AUGE,FAMILLY,49.005159699,0.165980940625,371,,Livarot-Pays-d'Auge,Livarot-Pays-d'Auge,14,Calvados,28,Normandie
14371,LIVAROT PAYS D AUGE,14290,LIVAROT PAYS D AUGE,MEULLES,49.005159699,0.165980940625,371,,Livarot-Pays-d'Auge,Livarot-Pays-d'Auge,14,Calvados,28,Normandie
14371,LIVAROT PAYS D AUGE,14290,LIVAROT PAYS D AUGE,PREAUX ST SEBASTIEN,49.005159699,0.165980940625,371,,Livarot-Pays-d'Auge,Livarot-Pays-d'Auge,14,Calvados,28,Normandie
14372,LIVRY,14240,LIVRY,,49.1134834167,-0.768370196105,372,,Livry,Livry,14,Calvados,28,Normandie
14372,LIVRY,14240,LIVRY,PARFOURU L ECLIN,49.1134834167,-0.768370196105,372,,Livry,Livry,14,Calvados,28,Normandie
14373,LE LOCHEUR,14210,LE LOCHEUR,,49.1052367253,-0.556085514135,373,Le,Locheur,Le Locheur,14,Calvados,28,Normandie
14374,LES LOGES,14240,LES LOGES,,49.0444032135,-0.801249419422,374,Les,Loges,Les Loges,14,Calvados,28,Normandie
14375,LES LOGES SAULCES,14700,LES LOGES SAULCES,,48.8646988987,-0.303404907346,375,Les,Loges-Saulces,Les Loges-Saulces,14,Calvados,28,Normandie
14376,LONGRAYE,14250,LONGRAYE,,49.1553645742,-0.700865082734,376,,Longraye,Longraye,14,Calvados,28,Normandie
14377,LONGUES SUR MER,14400,LONGUES SUR MER,,49.3316737353,-0.694238219905,377,,Longues-sur-Mer,Longues-sur-Mer,14,Calvados,28,Normandie
14378,LONGUEVILLE,14230,LONGUEVILLE,,49.3354924873,-0.957303295702,378,,Longueville,Longueville,14,Calvados,28,Normandie
14379,LONGVILLERS,14310,LONGVILLERS,,49.0464617085,-0.644154381572,379,,Longvillers,Longvillers,14,Calvados,28,Normandie
14380,LOUCELLES,14250,LOUCELLES,,49.225730744,-0.578019893606,380,,Loucelles,Loucelles,14,Calvados,28,Normandie
14381,LOUVAGNY,14170,LOUVAGNY,,48.9501421231,-0.0465642056704,381,,Louvagny,Louvagny,14,Calvados,28,Normandie
14382,LOUVIERES,14710,LOUVIERES,,49.3716484594,-0.92252269792,382,,Louvières,Louvières,14,Calvados,28,Normandie
14383,LOUVIGNY,14111,LOUVIGNY,,49.1552771224,-0.396704924192,383,,Louvigny,Louvigny,14,Calvados,28,Normandie
14384,LUC SUR MER,14530,LUC SUR MER,,49.3085348418,-0.357463109618,384,,Luc-sur-Mer,Luc-sur-Mer,14,Calvados,28,Normandie
14385,MAGNY EN BESSIN,14400,MAGNY EN BESSIN,,49.311643762,-0.666479750788,385,,Magny-en-Bessin,Magny-en-Bessin,14,Calvados,28,Normandie
14386,MAGNY LA CAMPAGNE,14270,MAGNY LA CAMPAGNE,,49.0494382034,-0.0987177011169,386,,Magny-la-Campagne,Magny-la-Campagne,14,Calvados,28,Normandie
14387,MAGNY LE FREULE,14270,MAGNY LE FREULE,,49.1037857446,-0.074544611656,387,,Magny-le-Freule,Magny-le-Freule,14,Calvados,28,Normandie
14389,MAISONCELLES PELVEY,14310,MAISONCELLES PELVEY,,49.059284458,-0.672639126991,389,,Maisoncelles-Pelvey,Maisoncelles-Pelvey,14,Calvados,28,Normandie
14390,MAISONCELLES SUR AJON,14210,MAISONCELLES SUR AJON,,49.0595594153,-0.539963670501,390,,Maisoncelles-sur-Ajon,Maisoncelles-sur-Ajon,14,Calvados,28,Normandie
14391,MAISONS,14400,MAISONS,,49.3161871346,-0.752920161833,391,,Maisons,Maisons,14,Calvados,28,Normandie
14393,MAIZET,14210,MAIZET,,49.0754358384,-0.454171502367,393,,Maizet,Maizet,14,Calvados,28,Normandie
14394,MAIZIERES,14190,MAIZIERES,,49.0141796622,-0.166976287789,394,,Maizières,Maizières,14,Calvados,28,Normandie
14396,MALTOT,14930,MALTOT,,49.1315977028,-0.422983283738,396,,Maltot,Maltot,14,Calvados,28,Normandie
14397,MANDEVILLE EN BESSIN,14710,MANDEVILLE EN BESSIN,,49.3069905991,-0.864918148377,397,,Mandeville-en-Bessin,Mandeville-en-Bessin,14,Calvados,28,Normandie
14398,MANERBE,14340,MANERBE,,49.1784066008,0.143884808036,398,,Manerbe,Manerbe,14,Calvados,28,Normandie
14399,MANNEVILLE LA PIPARD,14130,MANNEVILLE LA PIPARD,,49.2652939412,0.228360552544,399,,Manneville-la-Pipard,Manneville-la-Pipard,14,Calvados,28,Normandie
14400,LE MANOIR,14400,LE MANOIR,,49.2888976256,-0.599271328471,400,Le,Manoir,Le Manoir,14,Calvados,28,Normandie
14401,MANVIEUX,14117,MANVIEUX,,49.337721845,-0.658662548383,401,,Manvieux,Manvieux,14,Calvados,28,Normandie
14402,LE MARAIS LA CHAPELLE,14620,LE MARAIS LA CHAPELLE,,48.8820216125,-0.0225019105224,402,Le,Marais-la-Chapelle,Le Marais-la-Chapelle,14,Calvados,28,Normandie
14403,MAROLLES,14100,MAROLLES,,49.1378592392,0.3690277836,403,,Marolles,Marolles,14,Calvados,28,Normandie
14404,MARTAINVILLE,14220,MARTAINVILLE,,48.9474686277,-0.350260817596,404,,Martainville,Martainville,14,Calvados,28,Normandie
14405,MARTIGNY SUR L ANTE,14700,MARTIGNY SUR L ANTE,,48.890276343,-0.287046389252,405,,Martigny-sur-l'Ante,Martigny-sur-l'Ante,14,Calvados,28,Normandie
14406,MARTRAGNY,14740,MARTRAGNY,,49.2486390509,-0.60137367329,406,,Moulins en Bessin,Moulins en Bessin,14,Calvados,28,Normandie
14407,MATHIEU,14920,MATHIEU,,49.2562339956,-0.366730572403,407,,Mathieu,Mathieu,14,Calvados,28,Normandie
14408,MAY SUR ORNE,14320,MAY SUR ORNE,,49.0966958599,-0.379270597736,408,,May-sur-Orne,May-sur-Orne,14,Calvados,28,Normandie
14409,MERVILLE FRANCEVILLE PLAGE,14810,MERVILLE FRANCEVILLE PLAGE,,49.2723390059,-0.201985643028,409,,Merville-Franceville-Plage,Merville-Franceville-Plage,14,Calvados,28,Normandie
14410,MERY CORBON,14370,MERY CORBON,,49.1315507489,-0.0746479739995,410,,Méry-Bissières-en-Auge,Méry-Bissières-en-Auge,14,Calvados,28,Normandie
14411,MESLAY,14220,MESLAY,,48.9539488574,-0.392456343862,411,,Meslay,Meslay,14,Calvados,28,Normandie
14412,LE MESNIL AU GRAIN,14260,LE MESNIL AU GRAIN,,49.0467992669,-0.606121307613,412,Le,Mesnil-au-Grain,Le Mesnil-au-Grain,14,Calvados,28,Normandie
14413,LE MESNIL AUZOUF,14260,LE MESNIL AUZOUF,,48.9775747857,-0.735885885584,413,Le,Mesnil-Auzouf,Le Mesnil-Auzouf,14,Calvados,28,Normandie
14415,LE MESNIL BENOIST,14380,LE MESNIL BENOIST,,48.8680866805,-0.981435598529,415,Le,Mesnil-Benoist,Le Mesnil-Benoist,14,Calvados,28,Normandie
14416,LE MESNIL CAUSSOIS,14380,LE MESNIL CAUSSOIS,,48.855084438,-1.01089419215,416,Le,Mesnil-Caussois,Le Mesnil-Caussois,14,Calvados,28,Normandie
14417,MESNIL CLINCHAMPS,14380,MESNIL CLINCHAMPS,,48.8459218645,-0.982343394093,417,,Mesnil-Clinchamps,Mesnil-Clinchamps,14,Calvados,28,Normandie
14419,LE MESNIL EUDES,14100,LE MESNIL EUDES,,49.0931243426,0.174720146197,419,Le,Mesnil-Eudes,Le Mesnil-Eudes,14,Calvados,28,Normandie
14421,LE MESNIL GUILLAUME,14100,LE MESNIL GUILLAUME,,49.1048879579,0.282566648467,421,Le,Mesnil-Guillaume,Le Mesnil-Guillaume,14,Calvados,28,Normandie
14422,LE MESNIL MAUGER,14270,LE MESNIL MAUGER,,49.0767957339,0.0108836841983,422,Le,Mesnil-Mauger,Le Mesnil-Mauger,14,Calvados,28,Normandie
14422,LE MESNIL MAUGER,14270,LE MESNIL MAUGER,ECAJEUL,49.0767957339,0.0108836841983,422,Le,Mesnil-Mauger,Le Mesnil-Mauger,14,Calvados,28,Normandie
14422,LE MESNIL MAUGER,14270,LE MESNIL MAUGER,ST CRESPIN,49.0767957339,0.0108836841983,422,Le,Mesnil-Mauger,Le Mesnil-Mauger,14,Calvados,28,Normandie
14422,LE MESNIL MAUGER,14270,LE MESNIL MAUGER,STE MARIE AUX ANGLAIS,49.0767957339,0.0108836841983,422,Le,Mesnil-Mauger,Le Mesnil-Mauger,14,Calvados,28,Normandie
14423,LE MESNIL PATRY,14740,LE MESNIL PATRY,,49.1924874522,-0.543334121014,423,Le,Mesnil-Patry,Le Mesnil-Patry,14,Calvados,28,Normandie
14424,LE MESNIL ROBERT,14380,LE MESNIL ROBERT,,48.8801018014,-0.972914180513,424,Le,Mesnil-Robert,Le Mesnil-Robert,14,Calvados,28,Normandie
14425,LE MESNIL SIMON,14140,LE MESNIL SIMON,,49.0889837608,0.112641280521,425,Le,Mesnil-Simon,Le Mesnil-Simon,14,Calvados,28,Normandie
14426,LE MESNIL SUR BLANGY,14130,LE MESNIL SUR BLANGY,,49.2595243271,0.261073976764,426,Le,Mesnil-sur-Blangy,Le Mesnil-sur-Blangy,14,Calvados,28,Normandie
14427,LE MESNIL VILLEMENT,14690,LE MESNIL VILLEMENT,,48.8563899917,-0.376578654979,427,Le,Mesnil-Villement,Le Mesnil-Villement,14,Calvados,28,Normandie
14430,MEUVAINES,14960,MEUVAINES,,49.3256472804,-0.572440458403,430,,Meuvaines,Meuvaines,14,Calvados,28,Normandie
14431,MEZIDON CANON,14270,MEZIDON CANON,,49.0784400196,-0.0740245515937,431,,Mézidon Vallée d'Auge,Mézidon Vallée d'Auge,14,Calvados,28,Normandie
14431,MEZIDON CANON,14270,MEZIDON CANON,CANON,49.0784400196,-0.0740245515937,431,,Mézidon Vallée d'Auge,Mézidon Vallée d'Auge,14,Calvados,28,Normandie
14433,MITTOIS,14170,MITTOIS,,49.0057862251,0.03311651625,433,,Mittois,Mittois,14,Calvados,28,Normandie
14435,LES MONCEAUX,14100,LES MONCEAUX,,49.1107163291,0.130234447551,435,Les,Monceaux,Les Monceaux,14,Calvados,28,Normandie
14436,MONCEAUX EN BESSIN,14400,MONCEAUX EN BESSIN,,49.2503317019,-0.689321766659,436,,Monceaux-en-Bessin,Monceaux-en-Bessin,14,Calvados,28,Normandie
14437,MONDEVILLE,14120,MONDEVILLE,,49.1693649378,-0.310691187417,437,,Mondeville,Mondeville,14,Calvados,28,Normandie
14438,MONDRAINVILLE,14210,MONDRAINVILLE,,49.1419945444,-0.512296020857,438,,Mondrainville,Mondrainville,14,Calvados,28,Normandie
14439,MONFREVILLE,14230,MONFREVILLE,,49.3120766958,-1.03506679582,439,,Monfréville,Monfréville,14,Calvados,28,Normandie
14444,MONTEILLE,14270,MONTEILLE,,49.1067784175,0.0442106541766,444,,Monteille,Monteille,14,Calvados,28,Normandie
14445,MONTFIQUET,14490,MONTFIQUET,,49.1850644814,-0.888869892598,445,,Montfiquet,Montfiquet,14,Calvados,28,Normandie
14446,MONTIGNY,14210,MONTIGNY,,49.0408244826,-0.534452490976,446,,Montigny,Montigny,14,Calvados,28,Normandie
14448,MONTREUIL EN AUGE,14340,MONTREUIL EN AUGE,,49.1674447526,0.0706436457965,448,,Montreuil-en-Auge,Montreuil-en-Auge,14,Calvados,28,Normandie
14449,MONTS EN BESSIN,14310,MONTS EN BESSIN,,49.121907033,-0.611431669802,449,,Monts-en-Bessin,Monts-en-Bessin,14,Calvados,28,Normandie
14450,MONTVIETTE,14140,MONTVIETTE,,48.9903291551,0.0966905733575,450,,Montviette,Montviette,14,Calvados,28,Normandie
14452,MORTEAUX COULIBOEUF,14620,MORTEAUX COULIBOEUF,,48.9239845756,-0.081385727774,452,,Morteaux-Coulibœuf,Morteaux-Coulibœuf,14,Calvados,28,Normandie
14453,MOSLES,14400,MOSLES,,49.3054549485,-0.820523193825,453,,Mosles,Mosles,14,Calvados,28,Normandie
14454,MOUEN,14790,MOUEN,,49.1498499035,-0.488405654437,454,,Mouen,Mouen,14,Calvados,28,Normandie
14455,MOULINES,14220,MOULINES,,48.9921142499,-0.347452623429,455,,Moulines,Moulines,14,Calvados,28,Normandie
14456,MOULT,14370,MOULT,,49.1127187184,-0.165293838791,456,,Moult-Chicheboville,Moult-Chicheboville,14,Calvados,28,Normandie
14457,LES MOUTIERS EN AUGE,14620,LES MOUTIERS EN AUGE,,48.8983599621,0.00771639719015,457,Les,Moutiers-en-Auge,Les Moutiers-en-Auge,14,Calvados,28,Normandie
14458,LES MOUTIERS EN CINGLAIS,14220,LES MOUTIERS EN CINGLAIS,,49.0231039076,-0.437703643826,458,Les,Moutiers-en-Cinglais,Les Moutiers-en-Cinglais,14,Calvados,28,Normandie
14460,MOYAUX,14590,MOYAUX,,49.1950035194,0.351238827177,460,,Moyaux,Moyaux,14,Calvados,28,Normandie
14461,MUTRECY,14220,MUTRECY,,49.064377424,-0.422482448584,461,,Mutrécy,Mutrécy,14,Calvados,28,Normandie
14462,NEUILLY LA FORET,14230,NEUILLY LA FORET,,49.2714428036,-1.08918238998,462,,Neuilly-la-Forêt,Neuilly-la-Forêt,14,Calvados,28,Normandie
14465,NONANT,14400,NONANT,,49.2368440133,-0.647728935836,465,,Nonant,Nonant,14,Calvados,28,Normandie
14466,NOROLLES,14100,NOROLLES,,49.2032408439,0.236528733512,466,,Norolles,Norolles,14,Calvados,28,Normandie
14467,NORON L ABBAYE,14700,NORON L ABBAYE,,48.8960398002,-0.247654065673,467,,Noron-l'Abbaye,Noron-l'Abbaye,14,Calvados,28,Normandie
14468,NORON LA POTERIE,14490,NORON LA POTERIE,,49.2239444771,-0.775339384776,468,,Noron-la-Poterie,Noron-la-Poterie,14,Calvados,28,Normandie
14469,NORREY EN AUGE,14620,NORREY EN AUGE,,48.9134999987,-0.015141118433,469,,Norrey-en-Auge,Norrey-en-Auge,14,Calvados,28,Normandie
14473,NOTRE DAME DE LIVAYE,14340,NOTRE DAME DE LIVAYE,,49.1178873364,0.0412832698412,473,,Notre-Dame-de-Livaye,Notre-Dame-de-Livaye,14,Calvados,28,Normandie
14474,NOTRE DAME D ESTREES CORBON,14340,NOTRE DAME D ESTREES CORBON,,49.1354583044,-0.00148553423555,474,,Notre-Dame-d'Estrées-Corbon,Notre-Dame-d'Estrées-Corbon,14,Calvados,28,Normandie
14474,NOTRE DAME D ESTREES CORBON,14340,NOTRE DAME D ESTREES CORBON,CORBON,49.1354583044,-0.00148553423555,474,,Notre-Dame-d'Estrées-Corbon,Notre-Dame-d'Estrées-Corbon,14,Calvados,28,Normandie
14475,NOYERS MISSY,14210,NOYERS MISSY,,49.1241683725,-0.575352839603,475,,Val d'Arry,Val d'Arry,14,Calvados,28,Normandie
14475,NOYERS MISSY,14210,NOYERS MISSY,MISSY,49.1241683725,-0.575352839603,475,,Val d'Arry,Val d'Arry,14,Calvados,28,Normandie
14476,OLENDON,14170,OLENDON,,48.9685764486,-0.173141593521,476,,Olendon,Olendon,14,Calvados,28,Normandie
14477,ONDEFONTAINE,14260,ONDEFONTAINE,,48.9884037488,-0.6875759128,477,,Ondefontaine,Ondefontaine,14,Calvados,28,Normandie
14478,ORBEC,14290,ORBEC,,49.0236641773,0.384953822179,478,,Orbec,Orbec,14,Calvados,28,Normandie
14480,OSMANVILLE,14230,OSMANVILLE,,49.3339813037,-1.08866221246,480,,Osmanville,Osmanville,14,Calvados,28,Normandie
14481,LES OUBEAUX,14230,LES OUBEAUX,,49.2846754884,-1.05251610264,481,Les,Oubeaux,Les Oubeaux,14,Calvados,28,Normandie
14482,OUEZY,14270,OUEZY,,49.0852774139,-0.103965705023,482,,Ouézy,Ouézy,14,Calvados,28,Normandie
14483,OUFFIERES,14220,OUFFIERES,,49.0248508277,-0.488986897039,483,,Ouffières,Ouffières,14,Calvados,28,Normandie
14484,OUILLY DU HOULEY,14590,OUILLY DU HOULEY,,49.1654776443,0.340440661375,484,,Ouilly-du-Houley,Ouilly-du-Houley,14,Calvados,28,Normandie
14486,OUILLY LE TESSON,14190,OUILLY LE TESSON,,48.9909032572,-0.21312584412,486,,Ouilly-le-Tesson,Ouilly-le-Tesson,14,Calvados,28,Normandie
14487,OUILLY LE VICOMTE,14100,OUILLY LE VICOMTE,,49.1778461306,0.215312555979,487,,Ouilly-le-Vicomte,Ouilly-le-Vicomte,14,Calvados,28,Normandie
14488,OUISTREHAM,14150,OUISTREHAM,,49.2745280107,-0.257718317859,488,,Ouistreham,Ouistreham,14,Calvados,28,Normandie
14489,OUVILLE LA BIEN TOURNEE,14170,OUVILLE LA BIEN TOURNEE,,49.0524902658,-0.0302436306811,489,,Ouville-la-Bien-Tournée,Ouville-la-Bien-Tournée,14,Calvados,28,Normandie
14491,PARFOURU SUR ODON,14310,PARFOURU SUR ODON,,49.0889824331,-0.608826965285,491,,Parfouru-sur-Odon,Parfouru-sur-Odon,14,Calvados,28,Normandie
14492,PENNEDEPIE,14600,PENNEDEPIE,,49.4016561749,0.164048692054,492,,Pennedepie,Pennedepie,14,Calvados,28,Normandie
14493,PERCY EN AUGE,14270,PERCY EN AUGE,,49.0598706547,-0.060058486621,493,,Percy-en-Auge,Percy-en-Auge,14,Calvados,28,Normandie
14494,PERIERS EN AUGE,14160,PERIERS EN AUGE,,49.2645646622,-0.094148558043,494,,Périers-en-Auge,Périers-en-Auge,14,Calvados,28,Normandie
14495,PERIERS SUR LE DAN,14112,PERIERS SUR LE DAN,,49.2572933332,-0.337426191669,495,,Périers-sur-le-Dan,Périers-sur-le-Dan,14,Calvados,28,Normandie
14496,PERIGNY,14770,PERIGNY,,48.9188949047,-0.605057928006,496,,Périgny,Périgny,14,Calvados,28,Normandie
14497,PERRIERES,14170,PERRIERES,,48.9597084503,-0.125803038768,497,,Perrières,Perrières,14,Calvados,28,Normandie
14498,PERTHEVILLE NERS,14700,PERTHEVILLE NERS,,48.8758297587,-0.109353634654,498,,Pertheville-Ners,Pertheville-Ners,14,Calvados,28,Normandie
14499,PETIVILLE,14390,PETIVILLE,,49.242349527,-0.168464037407,499,,Petiville,Petiville,14,Calvados,28,Normandie
14500,PIERREFITTE EN AUGE,14130,PIERREFITTE EN AUGE,,49.254911944,0.200274296558,500,,Pierrefitte-en-Auge,Pierrefitte-en-Auge,14,Calvados,28,Normandie
14501,PIERREFITTE EN CINGLAIS,14690,PIERREFITTE EN CINGLAIS,,48.9047122291,-0.38866024742,501,,Pierrefitte-en-Cinglais,Pierrefitte-en-Cinglais,14,Calvados,28,Normandie
14502,PIERREPONT,14690,PIERREPONT,,48.8868297895,-0.321333043754,502,,Pierrepont,Pierrepont,14,Calvados,28,Normandie
14504,LE PIN,14590,LE PIN,,49.2139284087,0.335606713114,504,Le,Pin,Le Pin,14,Calvados,28,Normandie
14505,PLACY,14220,PLACY,,48.9787845418,-0.426591427647,505,,Placy,Placy,14,Calvados,28,Normandie
14506,PLANQUERY,14490,PLANQUERY,,49.1624080403,-0.826461213535,506,,Planquery,Planquery,14,Calvados,28,Normandie
14508,LE PLESSIS GRIMOULT,14770,LE PLESSIS GRIMOULT,,48.9606169985,-0.611666884099,508,Le,Plessis-Grimoult,Le Plessis-Grimoult,14,Calvados,28,Normandie
14509,PLUMETOT,14440,PLUMETOT,,49.2775537071,-0.361463362906,509,,Plumetot,Plumetot,14,Calvados,28,Normandie
14510,LA POMMERAYE,14690,LA POMMERAYE,,48.9094040854,-0.41548285835,510,La,Pommeraye,La Pommeraye,14,Calvados,28,Normandie
14511,PONT BELLANGER,14380,PONT BELLANGER,,48.9351528941,-0.982806859823,511,,Pont-Bellanger,Pont-Bellanger,14,Calvados,28,Normandie
14512,PONTECOULANT,14110,PONTECOULANT,,48.8918716437,-0.583734830586,512,,Pontécoulant,Pontécoulant,14,Calvados,28,Normandie
14513,PONT FARCY,14380,PONT FARCY,,48.9436924113,-1.03671909686,513,,Pont-Farcy,Pont-Farcy,14,Calvados,28,Normandie
14513,PONT FARCY,14380,PONT FARCY,PLEINES OEUVRES,48.9436924113,-1.03671909686,513,,Pont-Farcy,Pont-Farcy,14,Calvados,28,Normandie
14514,PONT L EVEQUE,14130,PONT L EVEQUE,,49.2805619214,0.187666368193,514,,Pont-l'Évêque,Pont-l'Évêque,14,Calvados,28,Normandie
14515,PORT EN BESSIN HUPPAIN,14520,PORT EN BESSIN HUPPAIN,,49.3396014282,-0.772708434394,515,,Port-en-Bessin-Huppain,Port-en-Bessin-Huppain,14,Calvados,28,Normandie
14515,PORT EN BESSIN HUPPAIN,14520,PORT EN BESSIN HUPPAIN,HUPPAIN,49.3396014282,-0.772708434394,515,,Port-en-Bessin-Huppain,Port-en-Bessin-Huppain,14,Calvados,28,Normandie
14516,POTIGNY,14420,POTIGNY,,48.9706322834,-0.247353467356,516,,Potigny,Potigny,14,Calvados,28,Normandie
14517,POUSSY LA CAMPAGNE,14540,POUSSY LA CAMPAGNE,,49.0788966211,-0.231130670136,517,,Poussy-la-Campagne,Poussy-la-Campagne,14,Calvados,28,Normandie
14519,PREAUX BOCAGE,14210,PREAUX BOCAGE,,49.0526321231,-0.509209645231,519,,Préaux-Bocage,Préaux-Bocage,14,Calvados,28,Normandie
14520,LE PRE D AUGE,14340,LE PRE D AUGE,,49.1496506404,0.145754077347,520,Le,Pré-d'Auge,Le Pré-d'Auge,14,Calvados,28,Normandie
14522,PRETREVILLE,14140,PRETREVILLE,,49.0722122018,0.25860530707,522,,Prêtreville,Prêtreville,14,Calvados,28,Normandie
14524,PUTOT EN AUGE,14430,PUTOT EN AUGE,,49.2131086875,-0.0664290983836,524,,Putot-en-Auge,Putot-en-Auge,14,Calvados,28,Normandie
14525,PUTOT EN BESSIN,14740,PUTOT EN BESSIN,,49.2138867223,-0.544027533268,525,,Putot-en-Bessin,Putot-en-Bessin,14,Calvados,28,Normandie
14527,BIEVILLE QUETIEVILLE,14270,BIEVILLE QUETIEVILLE,,49.116121463,-0.0357079622156,527,,Belle Vie en Auge,Belle Vie en Auge,14,Calvados,28,Normandie
14527,BIEVILLE QUETIEVILLE,14270,BIEVILLE QUETIEVILLE,BIEVILLE EN AUGE,49.116121463,-0.0357079622156,527,,Belle Vie en Auge,Belle Vie en Auge,14,Calvados,28,Normandie
14527,BIEVILLE QUETIEVILLE,14270,BIEVILLE QUETIEVILLE,QUETIEVILLE,49.116121463,-0.0357079622156,527,,Belle Vie en Auge,Belle Vie en Auge,14,Calvados,28,Normandie
14528,QUETTEVILLE,14130,QUETTEVILLE,,49.3419911166,0.300133346216,528,,Quetteville,Quetteville,14,Calvados,28,Normandie
14529,RANCHY,14400,RANCHY,,49.2552732659,-0.763716555064,529,,Ranchy,Ranchy,14,Calvados,28,Normandie
14530,RANVILLE,14860,RANVILLE,,49.2288226214,-0.263538892207,530,,Ranville,Ranville,14,Calvados,28,Normandie
14531,RAPILLY,14690,RAPILLY,,48.8569781675,-0.343804758393,531,,Rapilly,Rapilly,14,Calvados,28,Normandie
14533,REPENTIGNY,14340,REPENTIGNY,,49.1990064413,0.0426897498545,533,,Repentigny,Repentigny,14,Calvados,28,Normandie
14534,REUX,14130,REUX,,49.276489057,0.153456757288,534,,Reux,Reux,14,Calvados,28,Normandie
14535,REVIERS,14470,REVIERS,,49.2984627336,-0.465691637716,535,,Reviers,Reviers,14,Calvados,28,Normandie
14536,LA RIVIERE ST SAUVEUR,14600,LA RIVIERE ST SAUVEUR,,49.4080358178,0.268004849021,536,La,Rivière-Saint-Sauveur,La Rivière-Saint-Sauveur,14,Calvados,28,Normandie
14538,ROCQUANCOURT,14540,ROCQUANCOURT,,49.0957571442,-0.317250308981,538,,Rocquancourt,Rocquancourt,14,Calvados,28,Normandie
14540,ROCQUES,14100,ROCQUES,,49.1686946525,0.249417574053,540,,Rocques,Rocques,14,Calvados,28,Normandie
14541,LA ROQUE BAIGNARD,14340,LA ROQUE BAIGNARD,,49.1779193913,0.091587015818,541,La,Roque-Baignard,La Roque-Baignard,14,Calvados,28,Normandie
14542,ROSEL,14740,ROSEL,,49.2216532191,-0.450027445398,542,,Rosel,Rosel,14,Calvados,28,Normandie
14543,ROTS,14740,ROTS,LASSON,49.2060587409,-0.474725507611,543,,Rots,Rots,14,Calvados,28,Normandie
14543,ROTS,14740,ROTS,SECQUEVILLE EN BESSIN,49.2060587409,-0.474725507611,543,,Rots,Rots,14,Calvados,28,Normandie
14543,ROTS,14980,ROTS,,49.2060587409,-0.474725507611,543,,Rots,Rots,14,Calvados,28,Normandie
14544,ROUCAMPS,14260,ROUCAMPS,,48.9866203658,-0.6314276726,544,,Roucamps,Roucamps,14,Calvados,28,Normandie
14546,ROUVRES,14190,ROUVRES,,49.0036507919,-0.186702872571,546,,Rouvres,Rouvres,14,Calvados,28,Normandie
14547,RUBERCY,14710,RUBERCY,,49.2852909859,-0.886321608466,547,,Rubercy,Rubercy,14,Calvados,28,Normandie
14548,RUCQUEVILLE,14480,RUCQUEVILLE,,49.2580589795,-0.586109416555,548,,Rucqueville,Rucqueville,14,Calvados,28,Normandie
14550,RUMESNIL,14340,RUMESNIL,,49.1862090569,0.0220779097884,550,,Rumesnil,Rumesnil,14,Calvados,28,Normandie
14551,RUSSY,14710,RUSSY,,49.3281504095,-0.8189564904,551,,Russy,Russy,14,Calvados,28,Normandie
14552,RYES,14400,RYES,,49.3138908712,-0.627906482519,552,,Ryes,Ryes,14,Calvados,28,Normandie
14554,ST AIGNAN DE CRAMESNIL,14540,ST AIGNAN DE CRAMESNIL,,49.0816169321,-0.286022666698,554,,Saint-Aignan-de-Cramesnil,Saint-Aignan-de-Cramesnil,14,Calvados,28,Normandie
14555,ST ANDRE D HEBERTOT,14130,ST ANDRE D HEBERTOT,,49.3032209038,0.291910061832,555,,Saint-André-d'Hébertot,Saint-André-d'Hébertot,14,Calvados,28,Normandie
14556,ST ANDRE SUR ORNE,14320,ST ANDRE SUR ORNE,,49.1244015625,-0.388404822316,556,,Saint-André-sur-Orne,Saint-André-sur-Orne,14,Calvados,28,Normandie
14557,ST ARNOULT,14800,ST ARNOULT,,49.3316309924,0.0895728543881,557,,Saint-Arnoult,Saint-Arnoult,14,Calvados,28,Normandie
14558,ST AUBIN D ARQUENAY,14970,ST AUBIN D ARQUENAY,,49.2610054485,-0.283867469295,558,,Saint-Aubin-d'Arquenay,Saint-Aubin-d'Arquenay,14,Calvados,28,Normandie
14559,ST AUBIN DES BOIS,14380,ST AUBIN DES BOIS,,48.8324042173,-1.1311665271,559,,Saint-Aubin-des-Bois,Saint-Aubin-des-Bois,14,Calvados,28,Normandie
14562,ST AUBIN SUR MER,14750,ST AUBIN SUR MER,,49.3224328819,-0.39282763592,562,,Saint-Aubin-sur-Mer,Saint-Aubin-sur-Mer,14,Calvados,28,Normandie
14563,ST BENOIT D HEBERTOT,14130,ST BENOIT D HEBERTOT,,49.3249905572,0.272750820686,563,,Saint-Benoît-d'Hébertot,Saint-Benoît-d'Hébertot,14,Calvados,28,Normandie
14565,ST COME DE FRESNE,14960,ST COME DE FRESNE,,49.3265345546,-0.602210066475,565,,Saint-Côme-de-Fresné,Saint-Côme-de-Fresné,14,Calvados,28,Normandie
14566,ST CONTEST,14280,ST CONTEST,,49.2154597119,-0.400916151699,566,,Saint-Contest,Saint-Contest,14,Calvados,28,Normandie
14568,STE CROIX GRAND TONNE,14740,STE CROIX GRAND TONNE,,49.2337047582,-0.556033226741,568,,Sainte-Croix-Grand-Tonne,Sainte-Croix-Grand-Tonne,14,Calvados,28,Normandie
14569,STE CROIX SUR MER,14480,STE CROIX SUR MER,,49.316012626,-0.510000536108,569,,Sainte-Croix-sur-Mer,Sainte-Croix-sur-Mer,14,Calvados,28,Normandie
14570,VALORBIQUET,14290,VALORBIQUET,LA CHAPELLE YVON,49.0508380133,0.301847275517,570,,Valorbiquet,Valorbiquet,14,Calvados,28,Normandie
14570,VALORBIQUET,14290,VALORBIQUET,ST CYR DU RONCERAY,49.0508380133,0.301847275517,570,,Valorbiquet,Valorbiquet,14,Calvados,28,Normandie
14570,VALORBIQUET,14290,VALORBIQUET,ST JULIEN DE MAILLOC,49.0508380133,0.301847275517,570,,Valorbiquet,Valorbiquet,14,Calvados,28,Normandie
14570,VALORBIQUET,14290,VALORBIQUET,ST PIERRE DE MAILLOC,49.0508380133,0.301847275517,570,,Valorbiquet,Valorbiquet,14,Calvados,28,Normandie
14570,VALORBIQUET,14290,VALORBIQUET,TORDOUET,49.0508380133,0.301847275517,570,,Valorbiquet,Valorbiquet,14,Calvados,28,Normandie
14571,ST DENIS DE MAILLOC,14100,ST DENIS DE MAILLOC,,49.0982778471,0.314133133909,571,,Saint-Denis-de-Mailloc,Saint-Denis-de-Mailloc,14,Calvados,28,Normandie
14572,ST DENIS DE MERE,14110,ST DENIS DE MERE,,48.8648108622,-0.503133289019,572,,Saint-Denis-de-Méré,Saint-Denis-de-Méré,14,Calvados,28,Normandie
14574,ST DESIR,14100,ST DESIR,,49.1441541002,0.190725369715,574,,Saint-Désir,Saint-Désir,14,Calvados,28,Normandie
14575,ST ETIENNE LA THILLAYE,14950,ST ETIENNE LA THILLAYE,,49.2916915945,0.117829362713,575,,Saint-Étienne-la-Thillaye,Saint-Étienne-la-Thillaye,14,Calvados,28,Normandie
14576,VAL DE VIE,14140,VAL DE VIE,LA BREVIERE,48.9587088015,0.163639221018,576,,Val-de-Vie,Val-de-Vie,14,Calvados,28,Normandie
14576,VAL DE VIE,14140,VAL DE VIE,LA CHAPELLE HAUTE GRUE,48.9587088015,0.163639221018,576,,Val-de-Vie,Val-de-Vie,14,Calvados,28,Normandie
14576,VAL DE VIE,14140,VAL DE VIE,STE FOY DE MONTGOMMERY,48.9587088015,0.163639221018,576,,Val-de-Vie,Val-de-Vie,14,Calvados,28,Normandie
14576,VAL DE VIE,14140,VAL DE VIE,ST GERMAIN DE MONTGOMMERY,48.9587088015,0.163639221018,576,,Val-de-Vie,Val-de-Vie,14,Calvados,28,Normandie
14577,ST GABRIEL BRECY,14480,ST GABRIEL BRECY,,49.2707851378,-0.570933954743,577,,Saint-Gabriel-Brécy,Saint-Gabriel-Brécy,14,Calvados,28,Normandie
14578,ST GATIEN DES BOIS,14130,ST GATIEN DES BOIS,,49.3508650728,0.186845848605,578,,Saint-Gatien-des-Bois,Saint-Gatien-des-Bois,14,Calvados,28,Normandie
14579,SEULLINE,14260,SEULLINE,ST GEORGES D AUNAY,49.0267772033,-0.688629006888,579,,Seulline,Seulline,14,Calvados,28,Normandie
14579,SEULLINE,14310,SEULLINE,COULVAIN,49.0267772033,-0.688629006888,579,,Seulline,Seulline,14,Calvados,28,Normandie
14580,ST GEORGES EN AUGE,14140,ST GEORGES EN AUGE,,48.9884638353,0.0708706526618,580,,Saint-Georges-en-Auge,Saint-Georges-en-Auge,14,Calvados,28,Normandie
14581,ST GERMAIN D ECTOT,14240,ST GERMAIN D ECTOT,,49.1192443801,-0.715560792439,581,,Saint-Germain-d'Ectot,Saint-Germain-d'Ectot,14,Calvados,28,Normandie
14582,ST GERMAIN DE LIVET,14100,ST GERMAIN DE LIVET,,49.0768259488,0.197537877277,582,,Saint-Germain-de-Livet,Saint-Germain-de-Livet,14,Calvados,28,Normandie
14586,ST GERMAIN DU PERT,14230,ST GERMAIN DU PERT,,49.3303064004,-1.04253735344,586,,Saint-Germain-du-Pert,Saint-Germain-du-Pert,14,Calvados,28,Normandie
14587,ST GERMAIN LA BLANCHE HERBE,14280,ST GERMAIN LA BLANCHE HERBE,,49.195598359,-0.421595824393,587,,Saint-Germain-la-Blanche-Herbe,Saint-Germain-la-Blanche-Herbe,14,Calvados,28,Normandie
14588,ST GERMAIN LANGOT,14700,ST GERMAIN LANGOT,,48.9223267272,-0.327291220475,588,,Saint-Germain-Langot,Saint-Germain-Langot,14,Calvados,28,Normandie
14589,ST GERMAIN LE VASSON,14190,ST GERMAIN LE VASSON,,48.9999441182,-0.301428564884,589,,Saint-Germain-le-Vasson,Saint-Germain-le-Vasson,14,Calvados,28,Normandie
14590,STE HONORINE DE DUCY,14240,STE HONORINE DE DUCY,,49.1373059818,-0.785510429137,590,,Sainte-Honorine-de-Ducy,Sainte-Honorine-de-Ducy,14,Calvados,28,Normandie
14591,STE HONORINE DES PERTES,14520,STE HONORINE DES PERTES,,49.346789785,-0.810432609525,591,,Aure sur Mer,Aure sur Mer,14,Calvados,28,Normandie
14592,STE HONORINE DU FAY,14210,STE HONORINE DU FAY,,49.0700697986,-0.491095883025,592,,Sainte-Honorine-du-Fay,Sainte-Honorine-du-Fay,14,Calvados,28,Normandie
14593,ST HYMER,14130,ST HYMER,,49.2432932718,0.167395984438,593,,Saint-Hymer,Saint-Hymer,14,Calvados,28,Normandie
14595,ST JEAN DE LIVET,14100,ST JEAN DE LIVET,,49.0933846739,0.242347762493,595,,Saint-Jean-de-Livet,Saint-Jean-de-Livet,14,Calvados,28,Normandie
14596,ST JEAN DES ESSARTIERS,14350,ST JEAN DES ESSARTIERS,,49.0482041091,-0.818618439098,596,,Saint-Jean-des-Essartiers,Saint-Jean-des-Essartiers,14,Calvados,28,Normandie
14597,ST JEAN LE BLANC,14770,ST JEAN LE BLANC,,48.9391505079,-0.65777801355,597,,Saint-Jean-le-Blanc,Saint-Jean-le-Blanc,14,Calvados,28,Normandie
14598,ST JOUIN,14430,ST JOUIN,,49.2175501244,-0.0129782416911,598,,Saint-Jouin,Saint-Jouin,14,Calvados,28,Normandie
14600,ST JULIEN LE FAUCON,14140,ST JULIEN LE FAUCON,,49.0671059912,0.081277910801,600,,Saint-Julien-le-Faucon,Saint-Julien-le-Faucon,14,Calvados,28,Normandie
14601,ST JULIEN SUR CALONNE,14130,ST JULIEN SUR CALONNE,,49.2875577223,0.230516398049,601,,Saint-Julien-sur-Calonne,Saint-Julien-sur-Calonne,14,Calvados,28,Normandie
14602,ST LAMBERT,14570,ST LAMBERT,,48.9353175641,-0.537993649074,602,,Saint-Lambert,Saint-Lambert,14,Calvados,28,Normandie
14603,ST LAURENT DE CONDEL,14220,ST LAURENT DE CONDEL,,49.0261365757,-0.394821606161,603,,Saint-Laurent-de-Condel,Saint-Laurent-de-Condel,14,Calvados,28,Normandie
14604,ST LAURENT DU MONT,14340,ST LAURENT DU MONT,,49.1404300823,0.0272509600156,604,,Saint-Laurent-du-Mont,Saint-Laurent-du-Mont,14,Calvados,28,Normandie
14605,ST LAURENT SUR MER,14710,ST LAURENT SUR MER,,49.3577049946,-0.876012116729,605,,Saint-Laurent-sur-Mer,Saint-Laurent-sur-Mer,14,Calvados,28,Normandie
14606,ST LEGER DUBOSQ,14430,ST LEGER DUBOSQ,,49.2302039549,-0.0147539397439,606,,Saint-Léger-Dubosq,Saint-Léger-Dubosq,14,Calvados,28,Normandie
14607,ST LOUET SUR SEULLES,14310,ST LOUET SUR SEULLES,,49.0957495313,-0.67799363475,607,,Saint-Louet-sur-Seulles,Saint-Louet-sur-Seulles,14,Calvados,28,Normandie
14608,ST LOUP DE FRIBOIS,14340,ST LOUP DE FRIBOIS,,49.1087386238,0.0102155997362,608,,Saint-Loup-de-Fribois,Saint-Loup-de-Fribois,14,Calvados,28,Normandie
14609,ST LOUP HORS,14400,ST LOUP HORS,,49.2602090731,-0.730457240474,609,,Saint-Loup-Hors,Saint-Loup-Hors,14,Calvados,28,Normandie
14610,ST MANVIEU NORREY,14740,ST MANVIEU NORREY,,49.1866187514,-0.500649105973,610,,Saint-Manvieu-Norrey,Saint-Manvieu-Norrey,14,Calvados,28,Normandie
14610,ST MANVIEU NORREY,14740,ST MANVIEU NORREY,NORREY EN BESSIN,49.1866187514,-0.500649105973,610,,Saint-Manvieu-Norrey,Saint-Manvieu-Norrey,14,Calvados,28,Normandie
14611,ST MANVIEU BOCAGE,14380,ST MANVIEU BOCAGE,,48.8216812111,-0.964918933862,611,,Saint-Manvieu-Bocage,Saint-Manvieu-Bocage,14,Calvados,28,Normandie
14613,ST MARCOUF,14330,ST MARCOUF,,49.2571002463,-1.00212215315,613,,Saint-Marcouf,Saint-Marcouf,14,Calvados,28,Normandie
14614,STE MARGUERITE D ELLE,14330,STE MARGUERITE D ELLE,,49.2157426905,-0.986867595216,614,,Sainte-Marguerite-d'Elle,Sainte-Marguerite-d'Elle,14,Calvados,28,Normandie
14616,STE MARGUERITE DE VIETTE,14140,STE MARGUERITE DE VIETTE,,49.0119936332,0.0881323201564,616,,Sainte-Marguerite-de-Viette,Sainte-Marguerite-de-Viette,14,Calvados,28,Normandie
14619,STE MARIE OUTRE L EAU,14380,STE MARIE OUTRE L EAU,,48.9301625356,-1.02129252507,619,,Sainte-Marie-Outre-l'Eau,Sainte-Marie-Outre-l'Eau,14,Calvados,28,Normandie
14620,ST MARTIN AUX CHARTRAINS,14130,ST MARTIN AUX CHARTRAINS,,49.308417965,0.15439735263,620,,Saint-Martin-aux-Chartrains,Saint-Martin-aux-Chartrains,14,Calvados,28,Normandie
14621,ST MARTIN DE BIENFAITE LA CRESSONNIERE,14290,ST MARTIN BIENFAITE CRESSONNIERE,,49.0328920711,0.352774402255,621,,Saint-Martin-de-Bienfaite-la-Cressonnière,Saint-Martin-de-Bienfaite-la-Cressonnière,14,Calvados,28,Normandie
14621,ST MARTIN DE BIENFAITE LA CRESSONNIERE,14290,ST MARTIN BIENFAITE CRESSONNIERE,LA CRESSONNIERE,49.0328920711,0.352774402255,621,,Saint-Martin-de-Bienfaite-la-Cressonnière,Saint-Martin-de-Bienfaite-la-Cressonnière,14,Calvados,28,Normandie
14622,ST MARTIN DE BLAGNY,14710,ST MARTIN DE BLAGNY,,49.2491230144,-0.942947269441,622,,Saint-Martin-de-Blagny,Saint-Martin-de-Blagny,14,Calvados,28,Normandie
14623,ST MARTIN DE FONTENAY,14320,ST MARTIN DE FONTENAY,,49.1146147135,-0.351793766031,623,,Saint-Martin-de-Fontenay,Saint-Martin-de-Fontenay,14,Calvados,28,Normandie
14625,ST MARTIN DE LA LIEUE,14100,ST MARTIN DE LA LIEUE,,49.1102778699,0.224396339009,625,,Saint-Martin-de-la-Lieue,Saint-Martin-de-la-Lieue,14,Calvados,28,Normandie
14626,ST MARTIN DE MAILLOC,14100,ST MARTIN DE MAILLOC,,49.088406569,0.286444688377,626,,Saint-Martin-de-Mailloc,Saint-Martin-de-Mailloc,14,Calvados,28,Normandie
14627,ST MARTIN DE MIEUX,14700,ST MARTIN DE MIEUX,,48.8735138252,-0.240621816891,627,,Saint-Martin-de-Mieux,Saint-Martin-de-Mieux,14,Calvados,28,Normandie
14630,ST MARTIN DES ENTREES,14400,ST MARTIN DES ENTREES,,49.2574506991,-0.664949215648,630,,Saint-Martin-des-Entrées,Saint-Martin-des-Entrées,14,Calvados,28,Normandie
14635,ST OMER,14220,ST OMER,,48.9283510669,-0.443691657906,635,,Saint-Omer,Saint-Omer,14,Calvados,28,Normandie
14637,ST OUEN DU MESNIL OGER,14670,ST OUEN DU MESNIL OGER,,49.1575801048,-0.115265598688,637,,Saint-Ouen-du-Mesnil-Oger,Saint-Ouen-du-Mesnil-Oger,14,Calvados,28,Normandie
14639,ST OUEN LE PIN,14340,ST OUEN LE PIN,,49.1508388841,0.103659394584,639,,Saint-Ouen-le-Pin,Saint-Ouen-le-Pin,14,Calvados,28,Normandie
14640,ST PAIR,14670,ST PAIR,,49.1655310694,-0.186716961556,640,,Saint-Pair,Saint-Pair,14,Calvados,28,Normandie
14643,ST PAUL DU VERNAY,14490,ST PAUL DU VERNAY,,49.1967624116,-0.756637653868,643,,Saint-Paul-du-Vernay,Saint-Paul-du-Vernay,14,Calvados,28,Normandie
14644,ST PHILBERT DES CHAMPS,14130,ST PHILBERT DES CHAMPS,,49.2119587487,0.275544774425,644,,Saint-Philbert-des-Champs,Saint-Philbert-des-Champs,14,Calvados,28,Normandie
14645,ST PIERRE AZIF,14950,ST PIERRE AZIF,,49.292775444,0.0372567744774,645,,Saint-Pierre-Azif,Saint-Pierre-Azif,14,Calvados,28,Normandie
14646,ST PIERRE CANIVET,14700,ST PIERRE CANIVET,,48.9211270425,-0.230012764735,646,,Saint-Pierre-Canivet,Saint-Pierre-Canivet,14,Calvados,28,Normandie
14648,ST PIERRE DES IFS,14100,ST PIERRE DES IFS,,49.1146186654,0.161876822628,648,,Saint-Pierre-des-Ifs,Saint-Pierre-des-Ifs,14,Calvados,28,Normandie
14649,ST PIERRE DU BU,14700,ST PIERRE DU BU,,48.8646040738,-0.204189427047,649,,Saint-Pierre-du-Bû,Saint-Pierre-du-Bû,14,Calvados,28,Normandie
14650,ST PIERRE DU FRESNE,14260,ST PIERRE DU FRESNE,,49.0341253514,-0.765926909139,650,,Saint-Pierre-du-Fresne,Saint-Pierre-du-Fresne,14,Calvados,28,Normandie
14651,ST PIERRE DU JONQUET,14670,ST PIERRE DU JONQUET,,49.1658152003,-0.139476122836,651,,Saint-Pierre-du-Jonquet,Saint-Pierre-du-Jonquet,14,Calvados,28,Normandie
14652,ST PIERRE DU MONT,14450,ST PIERRE DU MONT,,49.3811236375,-0.975198693862,652,,Saint-Pierre-du-Mont,Saint-Pierre-du-Mont,14,Calvados,28,Normandie
14654,ST PIERRE SUR DIVES,14170,ST PIERRE SUR DIVES,,49.0158504483,-0.0407749520168,654,,Saint-Pierre-en-Auge,Saint-Pierre-en-Auge,14,Calvados,28,Normandie
14656,ST REMY,14570,ST REMY,,48.9398642478,-0.49006634789,656,,Saint-Rémy,Saint-Rémy,14,Calvados,28,Normandie
14657,ST SAMSON,14670,ST SAMSON,,49.1904929759,-0.15245429219,657,,Saint-Samson,Saint-Samson,14,Calvados,28,Normandie
14658,ST SEVER CALVADOS,14380,ST SEVER CALVADOS,,48.8217597918,-1.04661139436,658,,Noues de Sienne,Noues de Sienne,14,Calvados,28,Normandie
14659,ST SYLVAIN,14190,ST SYLVAIN,,49.0531521208,-0.215598170882,659,,Saint-Sylvain,Saint-Sylvain,14,Calvados,28,Normandie
14660,ST VAAST EN AUGE,14640,ST VAAST EN AUGE,,49.2905834736,0.0023023759062,660,,Saint-Vaast-en-Auge,Saint-Vaast-en-Auge,14,Calvados,28,Normandie
14661,ST VAAST SUR SEULLES,14250,ST VAAST SUR SEULLES,,49.136191031,-0.630172493075,661,,Saint-Vaast-sur-Seulles,Saint-Vaast-sur-Seulles,14,Calvados,28,Normandie
14662,ST VIGOR DES MEZERETS,14770,ST VIGOR DES MEZERETS,,48.9033619946,-0.633433708602,662,,Saint-Vigor-des-Mézerets,Saint-Vigor-des-Mézerets,14,Calvados,28,Normandie
14663,ST VIGOR LE GRAND,14400,ST VIGOR LE GRAND,,49.2877194467,-0.676858813101,663,,Saint-Vigor-le-Grand,Saint-Vigor-le-Grand,14,Calvados,28,Normandie
14664,SALLEN,14240,SALLEN,,49.1169448411,-0.839881279889,664,,Sallen,Sallen,14,Calvados,28,Normandie
14665,SALLENELLES,14121,SALLENELLES,,49.2637001725,-0.233420635581,665,,Sallenelles,Sallenelles,14,Calvados,28,Normandie
14666,SANNERVILLE,14940,SANNERVILLE,,49.183938753,-0.22288028462,666,,Sannerville,Sannerville,14,Calvados,28,Normandie
14667,SAON,14330,SAON,,49.2713788041,-0.863001782916,667,,Saon,Saon,14,Calvados,28,Normandie
14668,SAONNET,14330,SAONNET,,49.2721933862,-0.880981294808,668,,Saonnet,Saonnet,14,Calvados,28,Normandie
14669,SASSY,14170,SASSY,,48.9857053888,-0.139276273101,669,,Sassy,Sassy,14,Calvados,28,Normandie
14671,SEPT FRERES,14380,SEPT FRERES,,48.8642009505,-1.04020263222,671,,Sept-Frères,Sept-Frères,14,Calvados,28,Normandie
14672,SEPT VENTS,14240,SEPT VENTS,,49.0662712858,-0.837770438371,672,,Val de Drôme,Val de Drôme,14,Calvados,28,Normandie
14674,SOIGNOLLES,14190,SOIGNOLLES,,49.0293562485,-0.216616984997,674,,Soignolles,Soignolles,14,Calvados,28,Normandie
14675,SOLIERS,14540,SOLIERS,,49.1315274797,-0.287622280265,675,,Soliers,Soliers,14,Calvados,28,Normandie
14676,SOMMERVIEU,14400,SOMMERVIEU,,49.292637722,-0.645876714207,676,,Sommervieu,Sommervieu,14,Calvados,28,Normandie
14677,SOULANGY,14700,SOULANGY,,48.9404810595,-0.213994560503,677,,Soulangy,Soulangy,14,Calvados,28,Normandie
14678,SOUMONT ST QUENTIN,14420,SOUMONT ST QUENTIN,,48.979239508,-0.234726368793,678,,Soumont-Saint-Quentin,Soumont-Saint-Quentin,14,Calvados,28,Normandie
14679,SUBLES,14400,SUBLES,,49.2391973351,-0.749928429068,679,,Subles,Subles,14,Calvados,28,Normandie
14680,SULLY,14400,SULLY,,49.3003989779,-0.741732815376,680,,Sully,Sully,14,Calvados,28,Normandie
14681,SURRAIN,14710,SURRAIN,,49.32844781,-0.854850589431,681,,Surrain,Surrain,14,Calvados,28,Normandie
14682,SURVILLE,14130,SURVILLE,,49.3028988613,0.223364343411,682,,Surville,Surville,14,Calvados,28,Normandie
14684,TESSEL,14250,TESSEL,,49.1509531087,-0.568040470496,684,,Tessel,Tessel,14,Calvados,28,Normandie
14685,THAON,14610,THAON,,49.2549683004,-0.449172989846,685,,Thaon,Thaon,14,Calvados,28,Normandie
14687,LE THEIL EN AUGE,14130,LE THEIL EN AUGE,,49.346810194,0.250984349315,687,Le,Theil-en-Auge,Le Theil-en-Auge,14,Calvados,28,Normandie
14688,THIEVILLE,14170,THIEVILLE,,49.0367142903,-0.0442398501483,688,,Thiéville,Thiéville,14,Calvados,28,Normandie
14689,LE HOM,14220,LE HOM,CAUMONT SUR ORNE,48.9864514562,-0.485109067102,689,Le,Hom,Le Hom,14,Calvados,28,Normandie
14689,LE HOM,14220,LE HOM,CURCY SUR ORNE,48.9864514562,-0.485109067102,689,Le,Hom,Le Hom,14,Calvados,28,Normandie
14689,LE HOM,14220,LE HOM,HAMARS,48.9864514562,-0.485109067102,689,Le,Hom,Le Hom,14,Calvados,28,Normandie
14689,LE HOM,14220,LE HOM,ST MARTIN DE SALLEN,48.9864514562,-0.485109067102,689,Le,Hom,Le Hom,14,Calvados,28,Normandie
14689,LE HOM,14220,LE HOM,THURY HARCOURT,48.9864514562,-0.485109067102,689,Le,Hom,Le Hom,14,Calvados,28,Normandie
14690,TIERCEVILLE,14480,TIERCEVILLE,,49.2984533619,-0.527408226219,690,,Tierceville,Tierceville,14,Calvados,28,Normandie
14691,TILLY LA CAMPAGNE,14540,TILLY LA CAMPAGNE,,49.1102758522,-0.310762827705,691,,Tilly-la-Campagne,Tilly-la-Campagne,14,Calvados,28,Normandie
14692,TILLY SUR SEULLES,14250,TILLY SUR SEULLES,,49.1718691291,-0.62341766889,692,,Tilly-sur-Seulles,Tilly-sur-Seulles,14,Calvados,28,Normandie
14694,LE TORQUESNE,14130,LE TORQUESNE,,49.2181230377,0.169348180951,694,Le,Torquesne,Le Torquesne,14,Calvados,28,Normandie
14695,TORTEVAL QUESNAY,14240,TORTEVAL QUESNAY,,49.1498130853,-0.730039004181,695,,Torteval-Quesnay,Torteval-Quesnay,14,Calvados,28,Normandie
14695,TORTEVAL QUESNAY,14240,TORTEVAL QUESNAY,QUESNAY GUESNON,49.1498130853,-0.730039004181,695,,Torteval-Quesnay,Torteval-Quesnay,14,Calvados,28,Normandie
14697,L OUDON,14170,L OUDON,,48.9626128498,0.0329429977996,697,,Tôtes,Tôtes,14,Calvados,28,Normandie
14697,L OUDON,14170,L OUDON,AMMEVILLE,48.9626128498,0.0329429977996,697,,Tôtes,Tôtes,14,Calvados,28,Normandie
14697,L OUDON,14170,L OUDON,BERVILLE,48.9626128498,0.0329429977996,697,,Tôtes,Tôtes,14,Calvados,28,Normandie
14697,L OUDON,14170,L OUDON,ECOTS,48.9626128498,0.0329429977996,697,,Tôtes,Tôtes,14,Calvados,28,Normandie
14697,L OUDON,14170,L OUDON,GARNETOT,48.9626128498,0.0329429977996,697,,Tôtes,Tôtes,14,Calvados,28,Normandie
14697,L OUDON,14170,L OUDON,GRANDMESNIL,48.9626128498,0.0329429977996,697,,Tôtes,Tôtes,14,Calvados,28,Normandie
14697,L OUDON,14170,L OUDON,LIEURY,48.9626128498,0.0329429977996,697,,Tôtes,Tôtes,14,Calvados,28,Normandie
14697,L OUDON,14170,L OUDON,MONTPINCON,48.9626128498,0.0329429977996,697,,Tôtes,Tôtes,14,Calvados,28,Normandie
14697,L OUDON,14170,L OUDON,NOTRE DAME DE FRESNAY,48.9626128498,0.0329429977996,697,,Tôtes,Tôtes,14,Calvados,28,Normandie
14697,L OUDON,14170,L OUDON,ST MARTIN DE FRESNAY,48.9626128498,0.0329429977996,697,,Tôtes,Tôtes,14,Calvados,28,Normandie
14697,L OUDON,14170,L OUDON,TOTES,48.9626128498,0.0329429977996,697,,Tôtes,Tôtes,14,Calvados,28,Normandie
14698,TOUFFREVILLE,14940,TOUFFREVILLE,,49.1993620809,-0.225024326729,698,,Touffréville,Touffréville,14,Calvados,28,Normandie
14699,TOUQUES,14800,TOUQUES,,49.353602734,0.112669944999,699,,Touques,Touques,14,Calvados,28,Normandie
14700,TOUR EN BESSIN,14400,TOUR EN BESSIN,,49.293916906,-0.783768105699,700,,Tour-en-Bessin,Tour-en-Bessin,14,Calvados,28,Normandie
14701,TOURGEVILLE,14800,TOURGEVILLE,,49.3179026019,0.0798157742255,701,,Tourgéville,Tourgéville,14,Calvados,28,Normandie
14702,TOURNAY SUR ODON,14310,TOURNAY SUR ODON,,49.0961205952,-0.582928259678,702,,Tournay-sur-Odon,Tournay-sur-Odon,14,Calvados,28,Normandie
14703,TOURNEBU,14220,TOURNEBU,,48.9633348663,-0.326299876801,703,,Tournebu,Tournebu,14,Calvados,28,Normandie
14705,TOURNIERES,14330,TOURNIERES,,49.2304665973,-0.937851101881,705,,Tournières,Tournières,14,Calvados,28,Normandie
14706,TOURVILLE EN AUGE,14130,TOURVILLE EN AUGE,,49.3197258498,0.195138987607,706,,Tourville-en-Auge,Tourville-en-Auge,14,Calvados,28,Normandie
14707,TOURVILLE SUR ODON,14210,TOURVILLE SUR ODON,,49.1370651902,-0.500682712982,707,,Tourville-sur-Odon,Tourville-sur-Odon,14,Calvados,28,Normandie
14708,TRACY BOCAGE,14310,TRACY BOCAGE,,49.0726320347,-0.689428412856,708,,Tracy-Bocage,Tracy-Bocage,14,Calvados,28,Normandie
14709,TRACY SUR MER,14117,TRACY SUR MER,,49.3311618539,-0.644633136548,709,,Tracy-sur-Mer,Tracy-sur-Mer,14,Calvados,28,Normandie
14710,TREPREL,14690,TREPREL,,48.8986031258,-0.346809551963,710,,Tréprel,Tréprel,14,Calvados,28,Normandie
14711,TREVIERES,14710,TREVIERES,,49.2992384887,-0.916096268328,711,,Trévières,Trévières,14,Calvados,28,Normandie
14712,TROARN,14670,TROARN,,49.191539378,-0.183683620831,712,,Saline,Saline,14,Calvados,28,Normandie
14712,TROARN,14670,TROARN,BURES SUR DIVES,49.191539378,-0.183683620831,712,,Saline,Saline,14,Calvados,28,Normandie
14713,TROIS MONTS,14210,TROIS MONTS,,49.0488456416,-0.479036744908,713,,Trois-Monts,Trois-Monts,14,Calvados,28,Normandie
14714,LE TRONQUAY,14490,LE TRONQUAY,,49.2271134585,-0.819798634317,714,Le,Tronquay,Le Tronquay,14,Calvados,28,Normandie
14715,TROUVILLE SUR MER,14360,TROUVILLE SUR MER,,49.3721246762,0.102123470052,715,,Trouville-sur-Mer,Trouville-sur-Mer,14,Calvados,28,Normandie
14716,TRUNGY,14490,TRUNGY,,49.1936866317,-0.722397448796,716,,Trungy,Trungy,14,Calvados,28,Normandie
14719,URVILLE,14190,URVILLE,,49.025159713,-0.29441724721,719,,Urville,Urville,14,Calvados,28,Normandie
14720,USSY,14420,USSY,,48.9460697102,-0.290005556569,720,,Ussy,Ussy,14,Calvados,28,Normandie
14721,VACOGNES NEUILLY,14210,VACOGNES NEUILLY,,49.085434169,-0.534237961822,721,,Vacognes-Neuilly,Vacognes-Neuilly,14,Calvados,28,Normandie
14721,VACOGNES NEUILLY,14210,VACOGNES NEUILLY,NEUILLY LE MALHERBE,49.085434169,-0.534237961822,721,,Vacognes-Neuilly,Vacognes-Neuilly,14,Calvados,28,Normandie
14722,LA VACQUERIE,14240,LA VACQUERIE,,49.092081743,-0.855715721102,722,La,Vacquerie,La Vacquerie,14,Calvados,28,Normandie
14723,VALSEME,14340,VALSEME,,49.2376949859,0.0992010973025,723,,Valsemé,Valsemé,14,Calvados,28,Normandie
14724,VARAVILLE,14390,VARAVILLE,,49.2631541111,-0.145923580235,724,,Varaville,Varaville,14,Calvados,28,Normandie
14726,VALDALLIERE,14350,VALDALLIERE,LE DESERT,48.8636010778,-0.658303472061,726,,Valdallière,Valdallière,14,Calvados,28,Normandie
14726,VALDALLIERE,14350,VALDALLIERE,MONTCHAMP,48.8636010778,-0.658303472061,726,,Valdallière,Valdallière,14,Calvados,28,Normandie
14726,VALDALLIERE,14350,VALDALLIERE,ST CHARLES DE PERCY,48.8636010778,-0.658303472061,726,,Valdallière,Valdallière,14,Calvados,28,Normandie
14726,VALDALLIERE,14410,VALDALLIERE,BERNIERES LE PATRY,48.8636010778,-0.658303472061,726,,Valdallière,Valdallière,14,Calvados,28,Normandie
14726,VALDALLIERE,14410,VALDALLIERE,BURCY,48.8636010778,-0.658303472061,726,,Valdallière,Valdallière,14,Calvados,28,Normandie
14726,VALDALLIERE,14410,VALDALLIERE,CHENEDOLLE,48.8636010778,-0.658303472061,726,,Valdallière,Valdallière,14,Calvados,28,Normandie
14726,VALDALLIERE,14410,VALDALLIERE,ESTRY,48.8636010778,-0.658303472061,726,,Valdallière,Valdallière,14,Calvados,28,Normandie
14726,VALDALLIERE,14410,VALDALLIERE,LA ROCQUE,48.8636010778,-0.658303472061,726,,Valdallière,Valdallière,14,Calvados,28,Normandie
14726,VALDALLIERE,14410,VALDALLIERE,LE THEIL BOCAGE,48.8636010778,-0.658303472061,726,,Valdallière,Valdallière,14,Calvados,28,Normandie
14726,VALDALLIERE,14410,VALDALLIERE,PIERRES,48.8636010778,-0.658303472061,726,,Valdallière,Valdallière,14,Calvados,28,Normandie
14726,VALDALLIERE,14410,VALDALLIERE,PRESLES,48.8636010778,-0.658303472061,726,,Valdallière,Valdallière,14,Calvados,28,Normandie
14726,VALDALLIERE,14410,VALDALLIERE,RULLY,48.8636010778,-0.658303472061,726,,Valdallière,Valdallière,14,Calvados,28,Normandie
14726,VALDALLIERE,14410,VALDALLIERE,VASSY,48.8636010778,-0.658303472061,726,,Valdallière,Valdallière,14,Calvados,28,Normandie
14726,VALDALLIERE,14410,VALDALLIERE,VIESSOIX,48.8636010778,-0.658303472061,726,,Valdallière,Valdallière,14,Calvados,28,Normandie
14728,VAUCELLES,14400,VAUCELLES,,49.2838991523,-0.735454508917,728,,Vaucelles,Vaucelles,14,Calvados,28,Normandie
14729,VAUDELOGES,14170,VAUDELOGES,,48.9463592003,0.000650207338176,729,,Vaudeloges,Vaudeloges,14,Calvados,28,Normandie
14731,VAUVILLE,14800,VAUVILLE,,49.308998288,0.05853051607,731,,Vauville,Vauville,14,Calvados,28,Normandie
14732,VAUX SUR AURE,14400,VAUX SUR AURE,,49.3070647663,-0.710057114678,732,,Vaux-sur-Aure,Vaux-sur-Aure,14,Calvados,28,Normandie
14733,VAUX SUR SEULLES,14400,VAUX SUR SEULLES,,49.2615529375,-0.627652636172,733,,Vaux-sur-Seulles,Vaux-sur-Seulles,14,Calvados,28,Normandie
14734,VENDES,14250,VENDES,,49.1420282533,-0.600989613075,734,,Vendes,Vendes,14,Calvados,28,Normandie
14735,VENDEUVRE,14170,VENDEUVRE,,49.0067565786,-0.0828921340383,735,,Vendeuvre,Vendeuvre,14,Calvados,28,Normandie
14735,VENDEUVRE,14170,VENDEUVRE,ESCURES SUR FAVIERES,49.0067565786,-0.0828921340383,735,,Vendeuvre,Vendeuvre,14,Calvados,28,Normandie
14735,VENDEUVRE,14170,VENDEUVRE,GRISY,49.0067565786,-0.0828921340383,735,,Vendeuvre,Vendeuvre,14,Calvados,28,Normandie
14737,VERSAINVILLE,14700,VERSAINVILLE,,48.9197726496,-0.171525463565,737,,Versainville,Versainville,14,Calvados,28,Normandie
14738,VERSON,14790,VERSON,,49.1637623946,-0.463308814178,738,,Verson,Verson,14,Calvados,28,Normandie
14739,VER SUR MER,14114,VER SUR MER,,49.3338501523,-0.528813485245,739,,Ver-sur-Mer,Ver-sur-Mer,14,Calvados,28,Normandie
14740,LA VESPIERE FRIARDEL,14290,LA VESPIERE FRIARDEL,,49.0111903523,0.424749958219,740,La,Vespière-Friardel,La Vespière-Friardel,14,Calvados,28,Normandie
14740,LA VESPIERE FRIARDEL,14290,LA VESPIERE FRIARDEL,FRIARDEL,49.0111903523,0.424749958219,740,La,Vespière-Friardel,La Vespière-Friardel,14,Calvados,28,Normandie
14741,LE VEY,14570,LE VEY,,48.9166093894,-0.461914999707,741,Le,Vey,Le Vey,14,Calvados,28,Normandie
14742,VICQUES,14170,VICQUES,,48.953167057,-0.0742489726679,742,,Vicques,Vicques,14,Calvados,28,Normandie
14743,VICTOT PONTFOL,14430,VICTOT PONTFOL,,49.1680232284,-0.00948313586649,743,,Victot-Pontfol,Victot-Pontfol,14,Calvados,28,Normandie
14744,VIENNE EN BESSIN,14400,VIENNE EN BESSIN,,49.2812059817,-0.627760995032,744,,Vienne-en-Bessin,Vienne-en-Bessin,14,Calvados,28,Normandie
14745,VIERVILLE SUR MER,14710,VIERVILLE SUR MER,,49.3669899516,-0.901543572922,745,,Vierville-sur-Mer,Vierville-sur-Mer,14,Calvados,28,Normandie
14747,VIEUX,14930,VIEUX,,49.1128141033,-0.442946705773,747,,Vieux,Vieux,14,Calvados,28,Normandie
14748,VIEUX BOURG,14130,VIEUX BOURG,,49.3128478491,0.245450743611,748,,Vieux-Bourg,Vieux-Bourg,14,Calvados,28,Normandie
14749,VIEUX FUME,14270,VIEUX FUME,,49.0611126926,-0.12942772433,749,,Vieux-Fumé,Vieux-Fumé,14,Calvados,28,Normandie
14750,VIEUX PONT EN AUGE,14140,VIEUX PONT EN AUGE,,49.0422984376,0.0529788090028,750,,Vieux-Pont-en-Auge,Vieux-Pont-en-Auge,14,Calvados,28,Normandie
14751,VIGNATS,14700,VIGNATS,,48.8540077803,-0.102407702289,751,,Vignats,Vignats,14,Calvados,28,Normandie
14752,VILLERS BOCAGE,14310,VILLERS BOCAGE,,49.0782420975,-0.652664428102,752,,Villers-Bocage,Villers-Bocage,14,Calvados,28,Normandie
14753,VILLERS CANIVET,14420,VILLERS CANIVET,,48.9336012913,-0.261312485378,753,,Villers-Canivet,Villers-Canivet,14,Calvados,28,Normandie
14754,VILLERS SUR MER,14640,VILLERS SUR MER,,49.3110756382,0.00599012303306,754,,Villers-sur-Mer,Villers-sur-Mer,14,Calvados,28,Normandie
14755,VILLERVILLE,14113,VILLERVILLE,,49.3908763153,0.123148235679,755,,Villerville,Villerville,14,Calvados,28,Normandie
14756,LA VILLETTE,14570,LA VILLETTE,,48.9090423979,-0.543205008591,756,La,Villette,La Villette,14,Calvados,28,Normandie
14757,VILLIERS LE SEC,14480,VILLIERS LE SEC,,49.292941046,-0.565610877314,757,,Villiers-le-Sec,Villiers-le-Sec,14,Calvados,28,Normandie
14758,VILLONS LES BUISSONS,14610,VILLONS LES BUISSONS,,49.2361922294,-0.411267584761,758,,Villons-les-Buissons,Villons-les-Buissons,14,Calvados,28,Normandie
14759,VILLY LEZ FALAISE,14700,VILLY LEZ FALAISE,,48.9003361872,-0.136145311949,759,,Villy-lez-Falaise,Villy-lez-Falaise,14,Calvados,28,Normandie
14760,VILLY BOCAGE,14310,VILLY BOCAGE,,49.1039219543,-0.641975562637,760,,Villy-Bocage,Villy-Bocage,14,Calvados,28,Normandie
14761,VIMONT,14370,VIMONT,,49.1416235427,-0.194706905612,761,,Vimont,Vimont,14,Calvados,28,Normandie
14762,VIRE NORMANDIE,14500,VIRE NORMANDIE,,48.8512498219,-0.889601911342,762,,Vire Normandie,Vire Normandie,14,Calvados,28,Normandie
14762,VIRE NORMANDIE,14500,VIRE NORMANDIE,COULONCES,48.8512498219,-0.889601911342,762,,Vire Normandie,Vire Normandie,14,Calvados,28,Normandie
14762,VIRE NORMANDIE,14500,VIRE NORMANDIE,LA LANDE VAUMONT,48.8512498219,-0.889601911342,762,,Vire Normandie,Vire Normandie,14,Calvados,28,Normandie
14762,VIRE NORMANDIE,14500,VIRE NORMANDIE,MAISONCELLES LA JOURDAN,48.8512498219,-0.889601911342,762,,Vire Normandie,Vire Normandie,14,Calvados,28,Normandie
14762,VIRE NORMANDIE,14500,VIRE NORMANDIE,ROULLOURS,48.8512498219,-0.889601911342,762,,Vire Normandie,Vire Normandie,14,Calvados,28,Normandie
14762,VIRE NORMANDIE,14500,VIRE NORMANDIE,ST GERMAIN DE TALLEVENDE,48.8512498219,-0.889601911342,762,,Vire Normandie,Vire Normandie,14,Calvados,28,Normandie
14762,VIRE NORMANDIE,14500,VIRE NORMANDIE,ST MARTIN DE TALLEVENDE,48.8512498219,-0.889601911342,762,,Vire Normandie,Vire Normandie,14,Calvados,28,Normandie
14762,VIRE NORMANDIE,14500,VIRE NORMANDIE,TRUTTEMER LE GRAND,48.8512498219,-0.889601911342,762,,Vire Normandie,Vire Normandie,14,Calvados,28,Normandie
14762,VIRE NORMANDIE,14500,VIRE NORMANDIE,TRUTTEMER LE PETIT,48.8512498219,-0.889601911342,762,,Vire Normandie,Vire Normandie,14,Calvados,28,Normandie
14762,VIRE NORMANDIE,14500,VIRE NORMANDIE,VAUDRY,48.8512498219,-0.889601911342,762,,Vire Normandie,Vire Normandie,14,Calvados,28,Normandie
14763,VOUILLY,14230,VOUILLY,,49.29589718,-1.03229772745,763,,Vouilly,Vouilly,14,Calvados,28,Normandie
14764,PONT D OUILLY,14690,PONT D OUILLY,,48.8768624805,-0.409034326859,764,,Pont-d'Ouilly,Pont-d'Ouilly,14,Calvados,28,Normandie
15001,ALLANCHE,15160,ALLANCHE,,45.2312708319,2.95300274076,1,,Allanche,Allanche,15,Cantal,84,Auvergne-Rhône-Alpes
15002,ALLEUZE,15100,ALLEUZE,,44.9602873313,3.09831933936,2,,Alleuze,Alleuze,15,Cantal,84,Auvergne-Rhône-Alpes
15003,ALLY,15700,ALLY,,45.1644412736,2.32569985798,3,,Ally,Ally,15,Cantal,84,Auvergne-Rhône-Alpes
15003,ALLY,15700,ALLY,DRIGNAC,45.1644412736,2.32569985798,3,,Ally,Ally,15,Cantal,84,Auvergne-Rhône-Alpes
15004,ANDELAT,15100,ANDELAT,,45.0699830319,3.05933407794,4,,Andelat,Andelat,15,Cantal,84,Auvergne-Rhône-Alpes
15005,ANGLARDS DE ST FLOUR,15100,ANGLARDS DE ST FLOUR,,44.9887543021,3.15851184449,5,,Anglards-de-Saint-Flour,Anglards-de-Saint-Flour,15,Cantal,84,Auvergne-Rhône-Alpes
15006,ANGLARDS DE SALERS,15380,ANGLARDS DE SALERS,,45.1968681606,2.46563596922,6,,Anglards-de-Salers,Anglards-de-Salers,15,Cantal,84,Auvergne-Rhône-Alpes
15007,ANTERRIEUX,15110,ANTERRIEUX,,44.8313754887,3.05707779467,7,,Anterrieux,Anterrieux,15,Cantal,84,Auvergne-Rhône-Alpes
15008,ANTIGNAC,15240,ANTIGNAC,,45.3513149351,2.56147315935,8,,Antignac,Antignac,15,Cantal,84,Auvergne-Rhône-Alpes
15009,APCHON,15400,APCHON,,45.24485231,2.69776842052,9,,Apchon,Apchon,15,Cantal,84,Auvergne-Rhône-Alpes
15010,ARCHES,15200,ARCHES,,45.2984854251,2.31775819342,10,,Arches,Arches,15,Cantal,84,Auvergne-Rhône-Alpes
15011,ARNAC,15150,ARNAC,,45.0633298258,2.22626708333,11,,Arnac,Arnac,15,Cantal,84,Auvergne-Rhône-Alpes
15012,ARPAJON SUR CERE,15130,ARPAJON SUR CERE,,44.8763047408,2.46427067251,12,,Arpajon-sur-Cère,Arpajon-sur-Cère,15,Cantal,84,Auvergne-Rhône-Alpes
15013,AURIAC L EGLISE,15500,AURIAC L EGLISE,,45.264190905,3.12606134453,13,,Auriac-l'Église,Auriac-l'Église,15,Cantal,84,Auvergne-Rhône-Alpes
15014,AURILLAC,15000,AURILLAC,,44.9245233686,2.44162453828,14,,Aurillac,Aurillac,15,Cantal,84,Auvergne-Rhône-Alpes
15015,AUZERS,15240,AUZERS,,45.2759935312,2.46518628982,15,,Auzers,Auzers,15,Cantal,84,Auvergne-Rhône-Alpes
15016,AYRENS,15250,AYRENS,,44.9909323836,2.32370356489,16,,Ayrens,Ayrens,15,Cantal,84,Auvergne-Rhône-Alpes
15017,BADAILHAC,15800,BADAILHAC,,44.9142134347,2.62817849396,17,,Badailhac,Badailhac,15,Cantal,84,Auvergne-Rhône-Alpes
15018,BARRIAC LES BOSQUETS,15700,BARRIAC LES BOSQUETS,,45.1477013694,2.26530852152,18,,Barriac-les-Bosquets,Barriac-les-Bosquets,15,Cantal,84,Auvergne-Rhône-Alpes
15019,BASSIGNAC,15240,BASSIGNAC,,45.3148849641,2.40014280339,19,,Bassignac,Bassignac,15,Cantal,84,Auvergne-Rhône-Alpes
15020,BEAULIEU,15270,BEAULIEU,,45.4651616363,2.52086997748,20,,Beaulieu,Beaulieu,15,Cantal,84,Auvergne-Rhône-Alpes
15021,BOISSET,15600,BOISSET,,44.7857362078,2.25506235075,21,,Boisset,Boisset,15,Cantal,84,Auvergne-Rhône-Alpes
15022,BONNAC,15500,BONNAC,,45.2099376788,3.14660869619,22,,Bonnac,Bonnac,15,Cantal,84,Auvergne-Rhône-Alpes
15024,BRAGEAC,15700,BRAGEAC,,45.2038604898,2.26559675808,24,,Brageac,Brageac,15,Cantal,84,Auvergne-Rhône-Alpes
15025,ALBEPIERRE BREDONS,15300,ALBEPIERRE BREDONS,,45.0773628092,2.82044100103,25,,Albepierre-Bredons,Albepierre-Bredons,15,Cantal,84,Auvergne-Rhône-Alpes
15026,BREZONS,15230,BREZONS,,45.0097700141,2.79211398004,26,,Brezons,Brezons,15,Cantal,84,Auvergne-Rhône-Alpes
15027,CALVINET,15340,CALVINET,,44.7211847531,2.36192054351,27,,Calvinet,Calvinet,15,Cantal,84,Auvergne-Rhône-Alpes
15028,CARLAT,15130,CARLAT,,44.8833132335,2.57177332561,28,,Carlat,Carlat,15,Cantal,84,Auvergne-Rhône-Alpes
15029,CASSANIOUZE,15340,CASSANIOUZE,,44.673900395,2.38094248357,29,,Cassaniouze,Cassaniouze,15,Cantal,84,Auvergne-Rhône-Alpes
15030,CAYROLS,15290,CAYROLS,,44.8285518718,2.22684718249,30,,Cayrols,Cayrols,15,Cantal,84,Auvergne-Rhône-Alpes
15031,CELLES,15170,CELLES,,45.1067360877,2.95322787455,31,,Celles,Celles,15,Cantal,84,Auvergne-Rhône-Alpes
15032,CELOUX,15500,CELOUX,,45.1381000206,3.2607636006,32,,Celoux,Celoux,15,Cantal,84,Auvergne-Rhône-Alpes
15033,CEZENS,15230,CEZENS,,44.9845893263,2.8586925887,33,,Cézens,Cézens,15,Cantal,84,Auvergne-Rhône-Alpes
15034,CHALIERS,15320,CHALIERS,,44.9701617114,3.23790370512,34,,Chaliers,Chaliers,15,Cantal,84,Auvergne-Rhône-Alpes
15035,CHALINARGUES,15170,CHALINARGUES,,45.1621460049,2.93209906595,35,,Chalinargues,Chalinargues,15,Cantal,84,Auvergne-Rhône-Alpes
15036,CHALVIGNAC,15200,CHALVIGNAC,,45.2475912738,2.26458985433,36,,Chalvignac,Chalvignac,15,Cantal,84,Auvergne-Rhône-Alpes
15037,CHAMPAGNAC,15350,CHAMPAGNAC,,45.3633081405,2.40307399126,37,,Champagnac,Champagnac,15,Cantal,84,Auvergne-Rhône-Alpes
15038,CHAMPS SUR TARENTAINE MARCHAL,15270,CHAMPS SUR TARENTAINE MARCHAL,,45.4040107728,2.60404165506,38,,Champs-sur-Tarentaine-Marchal,Champs-sur-Tarentaine-Marchal,15,Cantal,84,Auvergne-Rhône-Alpes
15038,CHAMPS SUR TARENTAINE MARCHAL,15270,CHAMPS SUR TARENTAINE MARCHAL,MARCHAL,45.4040107728,2.60404165506,38,,Champs-sur-Tarentaine-Marchal,Champs-sur-Tarentaine-Marchal,15,Cantal,84,Auvergne-Rhône-Alpes
15040,CHANTERELLE,15190,CHANTERELLE,,45.3772414628,2.83331975255,40,,Chanterelle,Chanterelle,15,Cantal,84,Auvergne-Rhône-Alpes
15041,LA CHAPELLE D ALAGNON,15300,LA CHAPELLE D ALAGNON,,45.1050602104,2.90845752826,41,La,Chapelle-d'Alagnon,La Chapelle-d'Alagnon,15,Cantal,84,Auvergne-Rhône-Alpes
15042,LA CHAPELLE LAURENT,15500,LA CHAPELLE LAURENT,,45.1837226378,3.24249081953,42,La,Chapelle-Laurent,La Chapelle-Laurent,15,Cantal,84,Auvergne-Rhône-Alpes
15043,CHARMENSAC,15500,CHARMENSAC,,45.2264013054,3.07503500969,43,,Charmensac,Charmensac,15,Cantal,84,Auvergne-Rhône-Alpes
15044,CHASTEL SUR MURAT,15300,CHASTEL SUR MURAT,,45.1360561715,2.84458101678,44,,Chastel-sur-Murat,Chastel-sur-Murat,15,Cantal,84,Auvergne-Rhône-Alpes
15045,CHAUDES AIGUES,15110,CHAUDES AIGUES,,44.848937782,2.9982305989,45,,Chaudes-Aigues,Chaudes-Aigues,15,Cantal,84,Auvergne-Rhône-Alpes
15046,CHAUSSENAC,15700,CHAUSSENAC,,45.1764193419,2.27292726174,46,,Chaussenac,Chaussenac,15,Cantal,84,Auvergne-Rhône-Alpes
15047,CHAVAGNAC,15300,CHAVAGNAC,,45.1639015885,2.88197558399,47,,Chavagnac,Chavagnac,15,Cantal,84,Auvergne-Rhône-Alpes
15048,CHAZELLES,15500,CHAZELLES,,45.1057762736,3.32678478247,48,,Chazelles,Chazelles,15,Cantal,84,Auvergne-Rhône-Alpes
15049,CHEYLADE,15400,CHEYLADE,,45.1989830549,2.72030952547,49,,Cheylade,Cheylade,15,Cantal,84,Auvergne-Rhône-Alpes
15050,LE CLAUX,15400,LE CLAUX,,45.150079853,2.69958551259,50,Le,Claux,Le Claux,15,Cantal,84,Auvergne-Rhône-Alpes
15051,CLAVIERES,15320,CLAVIERES,,44.9934261034,3.31053306448,51,,Clavières,Clavières,15,Cantal,84,Auvergne-Rhône-Alpes
15052,COLLANDRES,15400,COLLANDRES,,45.2155092221,2.64103296391,52,,Collandres,Collandres,15,Cantal,84,Auvergne-Rhône-Alpes
15053,COLTINES,15170,COLTINES,,45.0930970766,2.9967659946,53,,Coltines,Coltines,15,Cantal,84,Auvergne-Rhône-Alpes
15054,CONDAT,15190,CONDAT,,45.3488007633,2.79188252959,54,,Condat,Condat,15,Cantal,84,Auvergne-Rhône-Alpes
15055,COREN,15100,COREN,,45.0875492811,3.10817742662,55,,Coren,Coren,15,Cantal,84,Auvergne-Rhône-Alpes
15056,CRANDELLES,15250,CRANDELLES,,44.9569827507,2.36041501133,56,,Crandelles,Crandelles,15,Cantal,84,Auvergne-Rhône-Alpes
15057,CROS DE MONTVERT,15150,CROS DE MONTVERT,,45.0589618548,2.1589666532,57,,Cros-de-Montvert,Cros-de-Montvert,15,Cantal,84,Auvergne-Rhône-Alpes
15058,CROS DE RONESQUE,15130,CROS DE RONESQUE,,44.8616190704,2.60679689587,58,,Cros-de-Ronesque,Cros-de-Ronesque,15,Cantal,84,Auvergne-Rhône-Alpes
15059,CUSSAC,15430,CUSSAC,,44.981338765,2.93616298746,59,,Cussac,Cussac,15,Cantal,84,Auvergne-Rhône-Alpes
15060,DEUX VERGES,15110,DEUX VERGES,,44.7997732435,3.01819855071,60,,Deux-Verges,Deux-Verges,15,Cantal,84,Auvergne-Rhône-Alpes
15061,DIENNE,15300,DIENNE,,45.1652515543,2.8073654716,61,,Dienne,Dienne,15,Cantal,84,Auvergne-Rhône-Alpes
15063,DRUGEAC,15140,DRUGEAC,,45.1625110675,2.38866153977,63,,Drugeac,Drugeac,15,Cantal,84,Auvergne-Rhône-Alpes
15064,ESCORAILLES,15700,ESCORAILLES,,45.1690574549,2.33215850964,64,,Escorailles,Escorailles,15,Cantal,84,Auvergne-Rhône-Alpes
15065,ESPINASSE,15110,ESPINASSE,,44.869517892,2.93031023884,65,,Espinasse,Espinasse,15,Cantal,84,Auvergne-Rhône-Alpes
15066,LE FALGOUX,15380,LE FALGOUX,,45.1449310681,2.6388267484,66,Le,Falgoux,Le Falgoux,15,Cantal,84,Auvergne-Rhône-Alpes
15067,LE FAU,15140,LE FAU,,45.1089339679,2.60020993092,67,Le,Fau,Le Fau,15,Cantal,84,Auvergne-Rhône-Alpes
15069,FERRIERES ST MARY,15170,FERRIERES ST MARY,,45.1721196123,3.08204262828,69,,Ferrières-Saint-Mary,Ferrières-Saint-Mary,15,Cantal,84,Auvergne-Rhône-Alpes
15070,FONTANGES,15140,FONTANGES,,45.1090707389,2.51731759077,70,,Fontanges,Fontanges,15,Cantal,84,Auvergne-Rhône-Alpes
15072,FREIX ANGLARDS,15310,FREIX ANGLARDS,,45.0176478672,2.37730578519,72,,Freix-Anglards,Freix-Anglards,15,Cantal,84,Auvergne-Rhône-Alpes
15073,FRIDEFONT,15110,FRIDEFONT,,44.9060191099,3.09513699893,73,,Fridefont,Fridefont,15,Cantal,84,Auvergne-Rhône-Alpes
15074,GIOU DE MAMOU,15130,GIOU DE MAMOU,,44.9352952684,2.50768962826,74,,Giou-de-Mamou,Giou-de-Mamou,15,Cantal,84,Auvergne-Rhône-Alpes
15075,GIRGOLS,15310,GIRGOLS,,45.0493144178,2.52185538424,75,,Girgols,Girgols,15,Cantal,84,Auvergne-Rhône-Alpes
15076,GLENAT,15150,GLENAT,,44.9040297506,2.1719411094,76,,Glénat,Glénat,15,Cantal,84,Auvergne-Rhône-Alpes
15077,GOURDIEGES,15230,GOURDIEGES,,44.9465137217,2.88202644949,77,,Gourdièges,Gourdièges,15,Cantal,84,Auvergne-Rhône-Alpes
15078,JABRUN,15110,JABRUN,,44.793937351,2.96316583017,78,,Jabrun,Jabrun,15,Cantal,84,Auvergne-Rhône-Alpes
15079,JALEYRAC,15200,JALEYRAC,,45.2697268838,2.36782201629,79,,Jaleyrac,Jaleyrac,15,Cantal,84,Auvergne-Rhône-Alpes
15080,JOURSAC,15170,JOURSAC,,45.165021316,3.01361412899,80,,Joursac,Joursac,15,Cantal,84,Auvergne-Rhône-Alpes
15081,JOU SOUS MONJOU,15800,JOU SOUS MONJOU,,44.9403094709,2.66250496336,81,,Jou-sous-Monjou,Jou-sous-Monjou,15,Cantal,84,Auvergne-Rhône-Alpes
15082,JUNHAC,15120,JUNHAC,,44.6956285648,2.4519931764,82,,Junhac,Junhac,15,Cantal,84,Auvergne-Rhône-Alpes
15083,JUSSAC,15250,JUSSAC,,44.9952366348,2.41802314499,83,,Jussac,Jussac,15,Cantal,84,Auvergne-Rhône-Alpes
15084,LABESSERETTE,15120,LABESSERETTE,,44.7413427756,2.4803305695,84,,Labesserette,Labesserette,15,Cantal,84,Auvergne-Rhône-Alpes
15085,LABROUSSE,15130,LABROUSSE,,44.8520781165,2.54931356081,85,,Labrousse,Labrousse,15,Cantal,84,Auvergne-Rhône-Alpes
15086,LACAPELLE BARRES,15230,LACAPELLE BARRES,,44.9504311526,2.72347580122,86,,Lacapelle-Barrès,Lacapelle-Barrès,15,Cantal,84,Auvergne-Rhône-Alpes
15087,LACAPELLE DEL FRAISSE,15120,LACAPELLE DEL FRAISSE,,44.7730782584,2.43049970218,87,,Lacapelle-del-Fraisse,Lacapelle-del-Fraisse,15,Cantal,84,Auvergne-Rhône-Alpes
15088,LACAPELLE VIESCAMP,15150,LACAPELLE VIESCAMP,,44.9257153749,2.27440087718,88,,Lacapelle-Viescamp,Lacapelle-Viescamp,15,Cantal,84,Auvergne-Rhône-Alpes
15089,LADINHAC,15120,LADINHAC,,44.767177873,2.50974723754,89,,Ladinhac,Ladinhac,15,Cantal,84,Auvergne-Rhône-Alpes
15090,LAFEUILLADE EN VEZIE,15130,LAFEUILLADE EN VEZIE,,44.7932691483,2.44817059954,90,,Lafeuillade-en-Vézie,Lafeuillade-en-Vézie,15,Cantal,84,Auvergne-Rhône-Alpes
15091,LANDEYRAT,15160,LANDEYRAT,,45.2728910978,2.8723635219,91,,Landeyrat,Landeyrat,15,Cantal,84,Auvergne-Rhône-Alpes
15092,LANOBRE,15270,LANOBRE,,45.4344225439,2.54974390079,92,,Lanobre,Lanobre,15,Cantal,84,Auvergne-Rhône-Alpes
15092,LANOBRE,15270,LANOBRE,GRANGES,45.4344225439,2.54974390079,92,,Lanobre,Lanobre,15,Cantal,84,Auvergne-Rhône-Alpes
15093,LAPEYRUGUE,15120,LAPEYRUGUE,,44.7296807893,2.53384738931,93,,Lapeyrugue,Lapeyrugue,15,Cantal,84,Auvergne-Rhône-Alpes
15094,LAROQUEBROU,15150,LAROQUEBROU,,44.974801878,2.19344155664,94,,Laroquebrou,Laroquebrou,15,Cantal,84,Auvergne-Rhône-Alpes
15095,LAROQUEVIEILLE,15250,LAROQUEVIEILLE,,45.0241515201,2.52853115841,95,,Laroquevieille,Laroquevieille,15,Cantal,84,Auvergne-Rhône-Alpes
15096,LASCELLE,15590,LASCELLE MANDAILLES,,45.0335568437,2.57470927987,96,,Lascelle,Lascelle,15,Cantal,84,Auvergne-Rhône-Alpes
15097,LASTIC,15500,LASTIC,,45.1115073317,3.2185679587,97,,Lastic,Lastic,15,Cantal,84,Auvergne-Rhône-Alpes
15098,LAURIE,15500,LAURIE,,45.2848648325,3.08243685205,98,,Laurie,Laurie,15,Cantal,84,Auvergne-Rhône-Alpes
15099,LAVASTRIE,15260,LAVASTRIE,,44.9348007487,3.04219086155,99,,Lavastrie,Lavastrie,15,Cantal,84,Auvergne-Rhône-Alpes
15100,LAVEISSENET,15300,LAVEISSENET,,45.077034187,2.87823772951,100,,Laveissenet,Laveissenet,15,Cantal,84,Auvergne-Rhône-Alpes
15101,LAVEISSIERE,15300,LAVEISSIERE,,45.1035229375,2.78285034579,101,,Laveissière,Laveissière,15,Cantal,84,Auvergne-Rhône-Alpes
15101,LAVEISSIERE,15300,LAVEISSIERE,LE LIORAN,45.1035229375,2.78285034579,101,,Laveissière,Laveissière,15,Cantal,84,Auvergne-Rhône-Alpes
15101,LAVEISSIERE,15300,LAVEISSIERE,SUPER LIORAN,45.1035229375,2.78285034579,101,,Laveissière,Laveissière,15,Cantal,84,Auvergne-Rhône-Alpes
15102,LAVIGERIE,15300,LAVIGERIE,,45.1256103713,2.732545,102,,Lavigerie,Lavigerie,15,Cantal,84,Auvergne-Rhône-Alpes
15103,LEUCAMP,15120,LEUCAMP,,44.7847764014,2.53963344667,103,,Leucamp,Leucamp,15,Cantal,84,Auvergne-Rhône-Alpes
15104,LEYNHAC,15600,LEYNHAC,,44.740738643,2.28088383558,104,,Leynhac,Leynhac,15,Cantal,84,Auvergne-Rhône-Alpes
15105,LEYVAUX,43450,LEYVAUX,,45.3249295413,3.08895370131,105,,Leyvaux,Leyvaux,15,Cantal,84,Auvergne-Rhône-Alpes
15106,LIEUTADES,15110,LIEUTADES,,44.8309397731,2.89836316505,106,,Lieutadès,Lieutadès,15,Cantal,84,Auvergne-Rhône-Alpes
15107,LORCIERES,15320,LORCIERES,,44.9525593557,3.29080870665,107,,Lorcières,Lorcières,15,Cantal,84,Auvergne-Rhône-Alpes
15108,VAL D ARCOMIE,15320,VAL D ARCOMIE,BOURNONCLES,44.9384891268,3.20556437292,108,,Val d'Arcomie,Val d'Arcomie,15,Cantal,84,Auvergne-Rhône-Alpes
15108,VAL D ARCOMIE,15320,VAL D ARCOMIE,FAVEROLLES,44.9384891268,3.20556437292,108,,Val d'Arcomie,Val d'Arcomie,15,Cantal,84,Auvergne-Rhône-Alpes
15108,VAL D ARCOMIE,15320,VAL D ARCOMIE,LOUBARESSE,44.9384891268,3.20556437292,108,,Val d'Arcomie,Val d'Arcomie,15,Cantal,84,Auvergne-Rhône-Alpes
15108,VAL D ARCOMIE,15320,VAL D ARCOMIE,ST JUST,44.9384891268,3.20556437292,108,,Val d'Arcomie,Val d'Arcomie,15,Cantal,84,Auvergne-Rhône-Alpes
15108,VAL D ARCOMIE,15320,VAL D ARCOMIE,ST MARC,44.9384891268,3.20556437292,108,,Val d'Arcomie,Val d'Arcomie,15,Cantal,84,Auvergne-Rhône-Alpes
15110,LUGARDE,15190,LUGARDE,,45.2883942354,2.75838366165,110,,Lugarde,Lugarde,15,Cantal,84,Auvergne-Rhône-Alpes
15111,MADIC,15210,MADIC,,45.372048674,2.45917498583,111,,Madic,Madic,15,Cantal,84,Auvergne-Rhône-Alpes
15112,MALBO,15230,MALBO,,44.9805509198,2.752942179,112,,Malbo,Malbo,15,Cantal,84,Auvergne-Rhône-Alpes
15113,MANDAILLES ST JULIEN,15590,MANDAILLES ST JULIEN,,45.0776793977,2.66430120889,113,,Mandailles-Saint-Julien,Mandailles-Saint-Julien,15,Cantal,84,Auvergne-Rhône-Alpes
15113,MANDAILLES ST JULIEN,15590,MANDAILLES ST JULIEN,ST JULIEN DE JORDANNE,45.0776793977,2.66430120889,113,,Mandailles-Saint-Julien,Mandailles-Saint-Julien,15,Cantal,84,Auvergne-Rhône-Alpes
15114,MARCENAT,15190,MARCENAT,,45.3162875719,2.85577542136,114,,Marcenat,Marcenat,15,Cantal,84,Auvergne-Rhône-Alpes
15116,MARCHASTEL,15400,MARCHASTEL,,45.273977504,2.72930331123,116,,Marchastel,Marchastel,15,Cantal,84,Auvergne-Rhône-Alpes
15117,MARCOLES,15220,MARCOLES,,44.7824610941,2.35726989792,117,,Marcolès,Marcolès,15,Cantal,84,Auvergne-Rhône-Alpes
15118,MARMANHAC,15250,MARMANHAC,,45.0074931721,2.47840612613,118,,Marmanhac,Marmanhac,15,Cantal,84,Auvergne-Rhône-Alpes
15119,MASSIAC,15500,MASSIAC,,45.2454117687,3.19978709447,119,,Massiac,Massiac,15,Cantal,84,Auvergne-Rhône-Alpes
15120,MAURIAC,15200,MAURIAC,,45.2183220694,2.31678475408,120,,Mauriac,Mauriac,15,Cantal,84,Auvergne-Rhône-Alpes
15121,MAURINES,15110,MAURINES,,44.870056489,3.08208971264,121,,Maurines,Maurines,15,Cantal,84,Auvergne-Rhône-Alpes
15122,MAURS,15600,MAURS,,44.7119433423,2.17308591391,122,,Maurs,Maurs,15,Cantal,84,Auvergne-Rhône-Alpes
15123,MEALLET,15200,MEALLET,,45.2627638015,2.41469819713,123,,Méallet,Méallet,15,Cantal,84,Auvergne-Rhône-Alpes
15124,MENET,15400,MENET,,45.2974849213,2.57917392075,124,,Menet,Menet,15,Cantal,84,Auvergne-Rhône-Alpes
15125,MENTIERES,15100,MENTIERES,,45.0786565074,3.14231362985,125,,Mentières,Mentières,15,Cantal,84,Auvergne-Rhône-Alpes
15126,MOLEDES,15500,MOLEDES,,45.2669112637,3.04292536589,126,,Molèdes,Molèdes,15,Cantal,84,Auvergne-Rhône-Alpes
15127,MOLOMPIZE,15500,MOLOMPIZE,,45.2333599435,3.12615906672,127,,Molompize,Molompize,15,Cantal,84,Auvergne-Rhône-Alpes
15128,LA MONSELIE,15240,LA MONSELIE,,45.321229006,2.55132616965,128,La,Monselie,La Monselie,15,Cantal,84,Auvergne-Rhône-Alpes
15129,MONTBOUDIF,15190,MONTBOUDIF,,45.3704230499,2.73226065206,129,,Montboudif,Montboudif,15,Cantal,84,Auvergne-Rhône-Alpes
15130,MONTCHAMP,15100,MONTCHAMP,,45.0691032448,3.21504140983,130,,Montchamp,Montchamp,15,Cantal,84,Auvergne-Rhône-Alpes
15131,LE MONTEIL,15240,LE MONTEIL,,45.2991575794,2.50786569253,131,Le,Monteil,Le Monteil,15,Cantal,84,Auvergne-Rhône-Alpes
15132,MONTGRELEIX,15190,MONTGRELEIX,,45.3555338617,2.88889825644,132,,Montgreleix,Montgreleix,15,Cantal,84,Auvergne-Rhône-Alpes
15133,MONTMURAT,15600,MONTMURAT,,44.6310973852,2.2008554498,133,,Montmurat,Montmurat,15,Cantal,84,Auvergne-Rhône-Alpes
15134,MONTSALVY,15120,MONTSALVY,,44.6956739577,2.49434704088,134,,Montsalvy,Montsalvy,15,Cantal,84,Auvergne-Rhône-Alpes
15135,MONTVERT,15150,MONTVERT,,44.9956514856,2.16312803314,135,,Montvert,Montvert,15,Cantal,84,Auvergne-Rhône-Alpes
15136,MOURJOU,15340,MOURJOU,,44.6974161055,2.31659060855,136,,Mourjou,Mourjou,15,Cantal,84,Auvergne-Rhône-Alpes
15137,MOUSSAGES,15380,MOUSSAGES,,45.2336429755,2.47256542244,137,,Moussages,Moussages,15,Cantal,84,Auvergne-Rhône-Alpes
15138,MURAT,15300,MURAT,,45.1111408605,2.86065494152,138,,Murat,Murat,15,Cantal,84,Auvergne-Rhône-Alpes
15139,NARNHAC,15230,NARNHAC,,44.9347231602,2.76628642462,139,,Narnhac,Narnhac,15,Cantal,84,Auvergne-Rhône-Alpes
15140,NAUCELLES,15250,NAUCELLES,,44.952486045,2.42117716236,140,,Naucelles,Naucelles,15,Cantal,84,Auvergne-Rhône-Alpes
15141,NEUSSARGUES MOISSAC,15170,NEUSSARGUES MOISSAC,,45.1323703921,2.98529315948,141,,Neussargues en Pinatelle,Neussargues en Pinatelle,15,Cantal,84,Auvergne-Rhône-Alpes
15142,NEUVEGLISE,15260,NEUVEGLISE,,44.9309902257,2.97942663368,142,,Neuvéglise-sur-Truyère,Neuvéglise-sur-Truyère,15,Cantal,84,Auvergne-Rhône-Alpes
15143,NIEUDAN,15150,NIEUDAN,,44.9831085193,2.24617399797,143,,Nieudan,Nieudan,15,Cantal,84,Auvergne-Rhône-Alpes
15144,OMPS,15290,OMPS,,44.880430829,2.26818388104,144,,Omps,Omps,15,Cantal,84,Auvergne-Rhône-Alpes
15145,ORADOUR,15260,ORADOUR,,44.9260155127,2.91790153122,145,,Oradour,Oradour,15,Cantal,84,Auvergne-Rhône-Alpes
15146,PAILHEROLS,15800,PAILHEROLS,,44.9569851027,2.704502835,146,,Pailherols,Pailherols,15,Cantal,84,Auvergne-Rhône-Alpes
15147,PARLAN,15290,PARLAN,,44.8274613306,2.17322961599,147,,Parlan,Parlan,15,Cantal,84,Auvergne-Rhône-Alpes
15148,PAULHAC,15430,PAULHAC,,45.0202868074,2.88735170231,148,,Paulhac,Paulhac,15,Cantal,84,Auvergne-Rhône-Alpes
15149,PAULHENC,15230,PAULHENC,,44.8849681536,2.81626761404,149,,Paulhenc,Paulhenc,15,Cantal,84,Auvergne-Rhône-Alpes
15151,PEYRUSSE,15170,PEYRUSSE,,45.2109595955,3.03006287758,151,,Peyrusse,Peyrusse,15,Cantal,84,Auvergne-Rhône-Alpes
15152,PIERREFORT,15230,PIERREFORT,,44.9288418231,2.83819601697,152,,Pierrefort,Pierrefort,15,Cantal,84,Auvergne-Rhône-Alpes
15153,PLEAUX,15700,PLEAUX,,45.1304319566,2.24934594486,153,,Pleaux,Pleaux,15,Cantal,84,Auvergne-Rhône-Alpes
15153,PLEAUX,15700,PLEAUX,LOUPIAC,45.1304319566,2.24934594486,153,,Pleaux,Pleaux,15,Cantal,84,Auvergne-Rhône-Alpes
15153,PLEAUX,15700,PLEAUX,ST CHRISTOPHE LES GORGES,45.1304319566,2.24934594486,153,,Pleaux,Pleaux,15,Cantal,84,Auvergne-Rhône-Alpes
15153,PLEAUX,15700,PLEAUX,TOURNIAC,45.1304319566,2.24934594486,153,,Pleaux,Pleaux,15,Cantal,84,Auvergne-Rhône-Alpes
15154,POLMINHAC,15800,POLMINHAC,,44.9524740825,2.57588922139,154,,Polminhac,Polminhac,15,Cantal,84,Auvergne-Rhône-Alpes
15155,PRADIERS,15160,PRADIERS,,45.2839972375,2.92137599236,155,,Pradiers,Pradiers,15,Cantal,84,Auvergne-Rhône-Alpes
15156,PRUNET,15130,PRUNET,,44.8227813134,2.47531798605,156,,Prunet,Prunet,15,Cantal,84,Auvergne-Rhône-Alpes
15157,QUEZAC,15600,QUEZAC,,44.7584182677,2.18352283274,157,,Quézac,Quézac,15,Cantal,84,Auvergne-Rhône-Alpes
15158,RAGEADE,15500,RAGEADE,,45.114689469,3.26841308091,158,,Rageade,Rageade,15,Cantal,84,Auvergne-Rhône-Alpes
15159,RAULHAC,15800,RAULHAC,,44.9019840026,2.65720291985,159,,Raulhac,Raulhac,15,Cantal,84,Auvergne-Rhône-Alpes
15160,REILHAC,15250,REILHAC,,44.9700663332,2.42282064857,160,,Reilhac,Reilhac,15,Cantal,84,Auvergne-Rhône-Alpes
15161,REZENTIERES,15170,REZENTIERES,,45.1342001841,3.10566449995,161,,Rézentières,Rézentières,15,Cantal,84,Auvergne-Rhône-Alpes
15162,RIOM ES MONTAGNES,15400,RIOM ES MONTAGNES,,45.2897248338,2.65677992591,162,,Riom-ès-Montagnes,Riom-ès-Montagnes,15,Cantal,84,Auvergne-Rhône-Alpes
15163,ROANNES ST MARY,15220,ROANNES ST MARY,,44.8450017276,2.39860201238,163,,Roannes-Saint-Mary,Roannes-Saint-Mary,15,Cantal,84,Auvergne-Rhône-Alpes
15164,ROFFIAC,15100,ROFFIAC,,45.04821174,3.01633980652,164,,Roffiac,Roffiac,15,Cantal,84,Auvergne-Rhône-Alpes
15165,ROUFFIAC,15150,ROUFFIAC,,45.0286072517,2.14375536336,165,,Rouffiac,Rouffiac,15,Cantal,84,Auvergne-Rhône-Alpes
15166,ROUMEGOUX,15290,ROUMEGOUX,,44.8599842405,2.18842696551,166,,Roumégoux,Roumégoux,15,Cantal,84,Auvergne-Rhône-Alpes
15167,ROUZIERS,15600,ROUZIERS,,44.7965226108,2.20974187733,167,,Rouziers,Rouziers,15,Cantal,84,Auvergne-Rhône-Alpes
15168,RUYNES EN MARGERIDE,15320,RUYNES EN MARGERIDE,,45.0046123239,3.22362001629,168,,Ruynes-en-Margeride,Ruynes-en-Margeride,15,Cantal,84,Auvergne-Rhône-Alpes
15169,SAIGNES,15240,SAIGNES,,45.3309843638,2.48182368898,169,,Saignes,Saignes,15,Cantal,84,Auvergne-Rhône-Alpes
15170,ST AMANDIN,15190,ST AMANDIN,,45.3284028896,2.70800428863,170,,Saint-Amandin,Saint-Amandin,15,Cantal,84,Auvergne-Rhône-Alpes
15171,STE ANASTASIE,15170,STE ANASTASIE,,45.1805333884,2.97521265115,171,,Sainte-Anastasie,Sainte-Anastasie,15,Cantal,84,Auvergne-Rhône-Alpes
15172,ST ANTOINE,15220,ST ANTOINE,,44.7494339962,2.33617223491,172,,Saint-Antoine,Saint-Antoine,15,Cantal,84,Auvergne-Rhône-Alpes
15173,ST BONNET DE CONDAT,15190,ST BONNET DE CONDAT,,45.2809310303,2.8038235634,173,,Saint-Bonnet-de-Condat,Saint-Bonnet-de-Condat,15,Cantal,84,Auvergne-Rhône-Alpes
15174,ST BONNET DE SALERS,15140,ST BONNET DE SALERS,,45.1605077564,2.47969406134,174,,Saint-Bonnet-de-Salers,Saint-Bonnet-de-Salers,15,Cantal,84,Auvergne-Rhône-Alpes
15175,ST CERNIN,15310,ST CERNIN,,45.0531263429,2.42554577219,175,,Saint-Cernin,Saint-Cernin,15,Cantal,84,Auvergne-Rhône-Alpes
15176,ST CHAMANT,15140,ST CHAMANT,,45.0923673037,2.44545518519,176,,Saint-Chamant,Saint-Chamant,15,Cantal,84,Auvergne-Rhône-Alpes
15178,ST CIRGUES DE JORDANNE,15590,ST CIRGUES DE JORDANNE,,45.0405601303,2.60513223834,178,,Saint-Cirgues-de-Jordanne,Saint-Cirgues-de-Jordanne,15,Cantal,84,Auvergne-Rhône-Alpes
15179,ST CIRGUES DE MALBERT,15140,ST CIRGUES DE MALBERT,,45.086472449,2.37952207311,179,,Saint-Cirgues-de-Malbert,Saint-Cirgues-de-Malbert,15,Cantal,84,Auvergne-Rhône-Alpes
15180,ST CLEMENT,15800,ST CLEMENT,,44.9753617677,2.6760260238,180,,Saint-Clément,Saint-Clément,15,Cantal,84,Auvergne-Rhône-Alpes
15181,ST CONSTANT FOURNOULES,15600,ST CONSTANT FOURNOULES,,44.6885284522,2.24626593038,181,,Saint-Constant-Fournoulès,Saint-Constant-Fournoulès,15,Cantal,84,Auvergne-Rhône-Alpes
15181,ST CONSTANT FOURNOULES,15600,ST CONSTANT FOURNOULES,FOURNOULES,44.6885284522,2.24626593038,181,,Saint-Constant-Fournoulès,Saint-Constant-Fournoulès,15,Cantal,84,Auvergne-Rhône-Alpes
15182,ST ETIENNE CANTALES,15150,ST ETIENNE CANTALES,,44.9496115234,2.2437307084,182,,Saint-Étienne-Cantalès,Saint-Étienne-Cantalès,15,Cantal,84,Auvergne-Rhône-Alpes
15183,ST ETIENNE DE CARLAT,15130,ST ETIENNE DE CARLAT,,44.9128030117,2.58461670892,183,,Saint-Étienne-de-Carlat,Saint-Étienne-de-Carlat,15,Cantal,84,Auvergne-Rhône-Alpes
15184,ST ETIENNE DE MAURS,15600,ST ETIENNE DE MAURS,,44.7216425562,2.22943690133,184,,Saint-Étienne-de-Maurs,Saint-Étienne-de-Maurs,15,Cantal,84,Auvergne-Rhône-Alpes
15185,ST ETIENNE DE CHOMEIL,15400,ST ETIENNE DE CHOMEIL,,45.3443815045,2.61922055075,185,,Saint-Étienne-de-Chomeil,Saint-Étienne-de-Chomeil,15,Cantal,84,Auvergne-Rhône-Alpes
15186,STE EULALIE,15140,STE EULALIE,,45.1248339578,2.38105299616,186,,Sainte-Eulalie,Sainte-Eulalie,15,Cantal,84,Auvergne-Rhône-Alpes
15187,ST FLOUR,15100,ST FLOUR,,45.0278919825,3.08320563232,187,,Saint-Flour,Saint-Flour,15,Cantal,84,Auvergne-Rhône-Alpes
15188,ST GEORGES,15100,ST GEORGES,,45.0195024779,3.13844362927,188,,Saint-Georges,Saint-Georges,15,Cantal,84,Auvergne-Rhône-Alpes
15189,ST GERONS,15150,ST GERONS,,44.9415058026,2.20469091333,189,,Saint-Gérons,Saint-Gérons,15,Cantal,84,Auvergne-Rhône-Alpes
15190,ST HIPPOLYTE,15400,ST HIPPOLYTE,,45.2193859021,2.70277477856,190,,Saint-Hippolyte,Saint-Hippolyte,15,Cantal,84,Auvergne-Rhône-Alpes
15191,ST ILLIDE,15310,ST ILLIDE,,45.050570658,2.32079424966,191,,Saint-Illide,Saint-Illide,15,Cantal,84,Auvergne-Rhône-Alpes
15192,ST JACQUES DES BLATS,15800,ST JACQUES DES BLATS,,45.0554659628,2.72104045324,192,,Saint-Jacques-des-Blats,Saint-Jacques-des-Blats,15,Cantal,84,Auvergne-Rhône-Alpes
15194,ST JULIEN DE TOURSAC,15600,ST JULIEN DE TOURSAC,,44.7745665182,2.20161181896,194,,Saint-Julien-de-Toursac,Saint-Julien-de-Toursac,15,Cantal,84,Auvergne-Rhône-Alpes
15196,ST MAMET LA SALVETAT,15220,ST MAMET LA SALVETAT,,44.8563990707,2.30496359467,196,,Saint-Mamet-la-Salvetat,Saint-Mamet-la-Salvetat,15,Cantal,84,Auvergne-Rhône-Alpes
15198,STE MARIE,15230,STE MARIE,,44.8925014312,2.88292989924,198,,Sainte-Marie,Sainte-Marie,15,Cantal,84,Auvergne-Rhône-Alpes
15199,ST MARTIAL,15110,ST MARTIAL,,44.8874161517,3.0435828016,199,,Saint-Martial,Saint-Martial,15,Cantal,84,Auvergne-Rhône-Alpes
15200,ST MARTIN CANTALES,15140,ST MARTIN CANTALES,,45.0888141636,2.30515856288,200,,Saint-Martin-Cantalès,Saint-Martin-Cantalès,15,Cantal,84,Auvergne-Rhône-Alpes
15201,ST MARTIN SOUS VIGOUROUX,15230,ST MARTIN SOUS VIGOUROUX,,44.9352892796,2.79358439032,201,,Saint-Martin-sous-Vigouroux,Saint-Martin-sous-Vigouroux,15,Cantal,84,Auvergne-Rhône-Alpes
15202,ST MARTIN VALMEROUX,15140,ST MARTIN VALMEROUX,,45.1201305737,2.44307130609,202,,Saint-Martin-Valmeroux,Saint-Martin-Valmeroux,15,Cantal,84,Auvergne-Rhône-Alpes
15202,ST MARTIN VALMEROUX,15140,ST MARTIN VALMEROUX,ST REMY DE SALERS,45.1201305737,2.44307130609,202,,Saint-Martin-Valmeroux,Saint-Martin-Valmeroux,15,Cantal,84,Auvergne-Rhône-Alpes
15203,ST MARY LE PLAIN,15500,ST MARY LE PLAIN,,45.1760315691,3.14393366298,203,,Saint-Mary-le-Plain,Saint-Mary-le-Plain,15,Cantal,84,Auvergne-Rhône-Alpes
15204,ST PAUL DES LANDES,15250,ST PAUL DES LANDES,,44.9507306137,2.30039279193,204,,Saint-Paul-des-Landes,Saint-Paul-des-Landes,15,Cantal,84,Auvergne-Rhône-Alpes
15205,ST PAUL DE SALERS,15140,ST PAUL DE SALERS,,45.1371845632,2.5580296522,205,,Saint-Paul-de-Salers,Saint-Paul-de-Salers,15,Cantal,84,Auvergne-Rhône-Alpes
15206,ST PIERRE,15350,ST PIERRE,,45.3953414957,2.38207391325,206,,Saint-Pierre,Saint-Pierre,15,Cantal,84,Auvergne-Rhône-Alpes
15207,ST PONCY,15500,ST PONCY,,45.1594604904,3.19462037388,207,,Saint-Poncy,Saint-Poncy,15,Cantal,84,Auvergne-Rhône-Alpes
15208,ST PROJET DE SALERS,15140,ST PROJET DE SALERS,,45.0821687828,2.55897131007,208,,Saint-Projet-de-Salers,Saint-Projet-de-Salers,15,Cantal,84,Auvergne-Rhône-Alpes
15209,ST REMY DE CHAUDES AIGUES,15110,ST REMY DE CHAUDES AIGUES,,44.7672253376,3.02660075086,209,,Saint-Rémy-de-Chaudes-Aigues,Saint-Rémy-de-Chaudes-Aigues,15,Cantal,84,Auvergne-Rhône-Alpes
15211,ST SANTIN CANTALES,15150,ST SANTIN CANTALES,,45.0207379963,2.23474554785,211,,Saint-Santin-Cantalès,Saint-Santin-Cantalès,15,Cantal,84,Auvergne-Rhône-Alpes
15212,ST SANTIN DE MAURS,15600,ST SANTIN DE MAURS,,44.6554891093,2.2106562412,212,,Saint-Santin-de-Maurs,Saint-Santin-de-Maurs,15,Cantal,84,Auvergne-Rhône-Alpes
15213,ST SATURNIN,15190,ST SATURNIN,,45.2334692107,2.79417752257,213,,Saint-Saturnin,Saint-Saturnin,15,Cantal,84,Auvergne-Rhône-Alpes
15214,ST SAURY,15290,ST SAURY,,44.8786965628,2.12428515169,214,,Saint-Saury,Saint-Saury,15,Cantal,84,Auvergne-Rhône-Alpes
15215,ST SIMON,15130,ST SIMON,,44.9695334779,2.49738848961,215,,Saint-Simon,Saint-Simon,15,Cantal,84,Auvergne-Rhône-Alpes
15216,ST URCIZE,15110,ST URCIZE,,44.7054618442,2.97925067316,216,,Saint-Urcize,Saint-Urcize,15,Cantal,84,Auvergne-Rhône-Alpes
15217,ST VICTOR,15150,ST VICTOR,,45.0097901662,2.2880455218,217,,Saint-Victor,Saint-Victor,15,Cantal,84,Auvergne-Rhône-Alpes
15218,ST VINCENT DE SALERS,15380,ST VINCENT DE SALERS,,45.2074874318,2.53894340125,218,,Saint-Vincent-de-Salers,Saint-Vincent-de-Salers,15,Cantal,84,Auvergne-Rhône-Alpes
15219,SALERS,15140,SALERS,,45.1450926367,2.49622722458,219,,Salers,Salers,15,Cantal,84,Auvergne-Rhône-Alpes
15220,SALINS,15200,SALINS,,45.187761472,2.40079505751,220,,Salins,Salins,15,Cantal,84,Auvergne-Rhône-Alpes
15221,SANSAC DE MARMIESSE,15130,SANSAC DE MARMIESSE,,44.8902213985,2.34319236958,221,,Sansac-de-Marmiesse,Sansac-de-Marmiesse,15,Cantal,84,Auvergne-Rhône-Alpes
15222,SANSAC VEINAZES,15120,SANSAC VEINAZES,,44.745141044,2.42655661494,222,,Sansac-Veinazès,Sansac-Veinazès,15,Cantal,84,Auvergne-Rhône-Alpes
15223,SAUVAT,15240,SAUVAT,,45.3075112596,2.44172378671,223,,Sauvat,Sauvat,15,Cantal,84,Auvergne-Rhône-Alpes
15224,LA SEGALASSIERE,15290,LA SEGALASSIERE,,44.8934183165,2.20866783261,224,La,Ségalassière,La Ségalassière,15,Cantal,84,Auvergne-Rhône-Alpes
15225,SEGUR LES VILLAS,15300,SEGUR LES VILLAS,,45.2080545733,2.82298394403,225,,Ségur-les-Villas,Ségur-les-Villas,15,Cantal,84,Auvergne-Rhône-Alpes
15226,SENEZERGUES,15340,SENEZERGUES,,44.7190472251,2.4072188609,226,,Sénezergues,Sénezergues,15,Cantal,84,Auvergne-Rhône-Alpes
15227,SERIERS,15100,SERIERS,,44.97204752,3.03247148385,227,,Sériers,Sériers,15,Cantal,84,Auvergne-Rhône-Alpes
15228,SIRAN,15150,SIRAN,,44.9505638532,2.12045291632,228,,Siran,Siran,15,Cantal,84,Auvergne-Rhône-Alpes
15229,SOULAGES,15100,SOULAGES,,45.0897253064,3.26165456011,229,,Soulages,Soulages,15,Cantal,84,Auvergne-Rhône-Alpes
15230,SOURNIAC,15200,SOURNIAC,,45.2752903351,2.33484723544,230,,Sourniac,Sourniac,15,Cantal,84,Auvergne-Rhône-Alpes
15231,TALIZAT,15170,TALIZAT,,45.1234934076,3.05334351272,231,,Talizat,Talizat,15,Cantal,84,Auvergne-Rhône-Alpes
15232,TANAVELLE,15100,TANAVELLE,,45.0263494142,2.98445909466,232,,Tanavelle,Tanavelle,15,Cantal,84,Auvergne-Rhône-Alpes
15233,TEISSIERES DE CORNET,15250,TEISSIERES DE CORNET,,44.9756690067,2.36199545657,233,,Teissières-de-Cornet,Teissières-de-Cornet,15,Cantal,84,Auvergne-Rhône-Alpes
15234,TEISSIERES LES BOULIES,15130,TEISSIERES LES BOULIES,,44.8209679734,2.53827855316,234,,Teissières-lès-Bouliès,Teissières-lès-Bouliès,15,Cantal,84,Auvergne-Rhône-Alpes
15235,LES TERNES,15100,LES TERNES,,44.9962837997,2.99411909646,235,Les,Ternes,Les Ternes,15,Cantal,84,Auvergne-Rhône-Alpes
15236,THIEZAC,15800,THIEZAC,,45.0180519173,2.6624257459,236,,Thiézac,Thiézac,15,Cantal,84,Auvergne-Rhône-Alpes
15237,TIVIERS,15100,TIVIERS,,45.0745302568,3.17567191659,237,,Tiviers,Tiviers,15,Cantal,84,Auvergne-Rhône-Alpes
15238,TOURNEMIRE,15310,TOURNEMIRE,,45.0547955278,2.49043476858,238,,Tournemire,Tournemire,15,Cantal,84,Auvergne-Rhône-Alpes
15240,TREMOUILLE,15270,TREMOUILLE,,45.3852208463,2.67025702101,240,,Trémouille,Trémouille,15,Cantal,84,Auvergne-Rhône-Alpes
15241,LA TRINITAT,15110,LA TRINITAT,,44.752159656,2.94545314762,241,La,Trinitat,La Trinitat,15,Cantal,84,Auvergne-Rhône-Alpes
15242,LE TRIOULOU,15600,LE TRIOULOU,,44.671238319,2.19126428388,242,Le,Trioulou,Le Trioulou,15,Cantal,84,Auvergne-Rhône-Alpes
15243,TRIZAC,15400,TRIZAC,,45.2442328606,2.5444240314,243,,Trizac,Trizac,15,Cantal,84,Auvergne-Rhône-Alpes
15244,USSEL,15300,USSEL,,45.0740146626,2.95237927853,244,,Ussel,Ussel,15,Cantal,84,Auvergne-Rhône-Alpes
15245,VABRES,15100,VABRES,,45.0375713324,3.19551902127,245,,Vabres,Vabres,15,Cantal,84,Auvergne-Rhône-Alpes
15246,VALETTE,15400,VALETTE,,45.2638149565,2.60128580793,246,,Valette,Valette,15,Cantal,84,Auvergne-Rhône-Alpes
15247,VALJOUZE,15170,VALJOUZE,,45.1637773707,3.0607165142,247,,Valjouze,Valjouze,15,Cantal,84,Auvergne-Rhône-Alpes
15248,VALUEJOLS,15300,VALUEJOLS,,45.0532332221,2.90711082999,248,,Valuéjols,Valuéjols,15,Cantal,84,Auvergne-Rhône-Alpes
15249,LE VAULMIER,15380,LE VAULMIER,,45.1838522202,2.58597218884,249,Le,Vaulmier,Le Vaulmier,15,Cantal,84,Auvergne-Rhône-Alpes
15250,VEBRET,15240,VEBRET,,45.3556816292,2.51538998658,250,,Vebret,Vebret,15,Cantal,84,Auvergne-Rhône-Alpes
15251,VEDRINES ST LOUP,15100,VEDRINES ST LOUP,,45.0527660016,3.27223653153,251,,Védrines-Saint-Loup,Védrines-Saint-Loup,15,Cantal,84,Auvergne-Rhône-Alpes
15252,VELZIC,15590,VELZIC,,44.995618273,2.55559240833,252,,Velzic,Velzic,15,Cantal,84,Auvergne-Rhône-Alpes
15253,VERNOLS,15160,VERNOLS,,45.2190007812,2.88326804068,253,,Vernols,Vernols,15,Cantal,84,Auvergne-Rhône-Alpes
15254,VEYRIERES,15350,VEYRIERES,,45.3204825413,2.36848020715,254,,Veyrières,Veyrières,15,Cantal,84,Auvergne-Rhône-Alpes
15255,VEZAC,15130,VEZAC,,44.8954464372,2.5219529711,255,,Vézac,Vézac,15,Cantal,84,Auvergne-Rhône-Alpes
15256,VEZE,15160,VEZE,,45.278399535,2.97817620096,256,,Vèze,Vèze,15,Cantal,84,Auvergne-Rhône-Alpes
15257,VEZELS ROUSSY,15130,VEZELS ROUSSY,,44.8057590781,2.58172571715,257,,Vezels-Roussy,Vezels-Roussy,15,Cantal,84,Auvergne-Rhône-Alpes
15258,VIC SUR CERE,15800,VIC SUR CERE,,44.9716667765,2.62023551236,258,,Vic-sur-Cère,Vic-sur-Cère,15,Cantal,84,Auvergne-Rhône-Alpes
15259,VIEILLESPESSE,15500,VIEILLESPESSE,,45.116971217,3.15878002374,259,,Vieillespesse,Vieillespesse,15,Cantal,84,Auvergne-Rhône-Alpes
15260,VIEILLEVIE,15120,VIEILLEVIE,,44.6519777769,2.43471765124,260,,Vieillevie,Vieillevie,15,Cantal,84,Auvergne-Rhône-Alpes
15261,LE VIGEAN,15200,LE VIGEAN,,45.2234162063,2.3665656568,261,Le,Vigean,Le Vigean,15,Cantal,84,Auvergne-Rhône-Alpes
15262,VILLEDIEU,15100,VILLEDIEU,,44.9971827746,3.05455428908,262,,Villedieu,Villedieu,15,Cantal,84,Auvergne-Rhône-Alpes
15263,VIRARGUES,15300,VIRARGUES,,45.1293117032,2.89843918349,263,,Virargues,Virargues,15,Cantal,84,Auvergne-Rhône-Alpes
15264,VITRAC,15220,VITRAC,,44.8102298787,2.31375646936,264,,Vitrac,Vitrac,15,Cantal,84,Auvergne-Rhône-Alpes
15265,YDES,15210,YDES,,45.3433264058,2.44997355968,265,,Ydes,Ydes,15,Cantal,84,Auvergne-Rhône-Alpes
15266,YOLET,15130,YOLET,,44.9307481726,2.5382689478,266,,Yolet,Yolet,15,Cantal,84,Auvergne-Rhône-Alpes
15267,YTRAC,15000,YTRAC,,44.9161324548,2.36598098395,267,,Ytrac,Ytrac,15,Cantal,84,Auvergne-Rhône-Alpes
15267,YTRAC,15130,YTRAC,,44.9161324548,2.36598098395,267,,Ytrac,Ytrac,15,Cantal,84,Auvergne-Rhône-Alpes
15268,LE ROUGET PERS,15290,LE ROUGET PERS,,44.8574381373,2.23422151745,268,Le,Rouget-Pers,Le Rouget-Pers,15,Cantal,84,Auvergne-Rhône-Alpes
15268,LE ROUGET PERS,15290,LE ROUGET PERS,PERS,44.8574381373,2.23422151745,268,Le,Rouget-Pers,Le Rouget-Pers,15,Cantal,84,Auvergne-Rhône-Alpes
15269,BESSE,15140,BESSE,,45.1012134642,2.35475637278,269,,Besse,Besse,15,Cantal,84,Auvergne-Rhône-Alpes
16001,ABZAC,16500,ABZAC,,46.0995520567,0.715822844014,1,,Abzac,Abzac,16,Charente,75,Nouvelle-Aquitaine
16002,LES ADJOTS,16700,LES ADJOTS,,46.0787550512,0.203143435799,2,Les,Adjots,Les Adjots,16,Charente,75,Nouvelle-Aquitaine
16003,AGRIS,16110,AGRIS,,45.7764888287,0.328082067918,3,,Agris,Agris,16,Charente,75,Nouvelle-Aquitaine
16004,AIGNES ET PUYPEROUX,16190,AIGNES ET PUYPEROUX,,45.4499477283,0.139490170805,4,,Aignes-et-Puypéroux,Aignes-et-Puypéroux,16,Charente,75,Nouvelle-Aquitaine
16005,AIGRE,16140,AIGRE,,45.8891702634,-0.000376399361894,5,,Aigre,Aigre,16,Charente,75,Nouvelle-Aquitaine
16007,ALLOUE,16490,ALLOUE,,46.0204552645,0.5258351895,7,,Alloue,Alloue,16,Charente,75,Nouvelle-Aquitaine
16008,AMBERAC,16140,AMBERAC,,45.8562404894,0.0591293874218,8,,Ambérac,Ambérac,16,Charente,75,Nouvelle-Aquitaine
16009,AMBERNAC,16490,AMBERNAC,,45.9747688433,0.556409302245,9,,Ambernac,Ambernac,16,Charente,75,Nouvelle-Aquitaine
16010,AMBLEVILLE,16300,AMBLEVILLE,,45.5581634832,-0.224518438236,10,,Ambleville,Ambleville,16,Charente,75,Nouvelle-Aquitaine
16011,ANAIS,16560,ANAIS,,45.7689915038,0.206024179916,11,,Anais,Anais,16,Charente,75,Nouvelle-Aquitaine
16012,ANGEAC CHAMPAGNE,16130,ANGEAC CHAMPAGNE,,45.6137559506,-0.289585740498,12,,Angeac-Champagne,Angeac-Champagne,16,Charente,75,Nouvelle-Aquitaine
16013,ANGEAC CHARENTE,16120,ANGEAC CHARENTE,,45.6230428074,-0.0817275722505,13,,Angeac-Charente,Angeac-Charente,16,Charente,75,Nouvelle-Aquitaine
16014,ANGEDUC,16300,ANGEDUC,,45.4783133378,-0.0552960913383,14,,Angeduc,Angeduc,16,Charente,75,Nouvelle-Aquitaine
16015,ANGOULEME,16000,ANGOULEME,,45.6472585146,0.14514490683,15,,Angoulême,Angoulême,16,Charente,75,Nouvelle-Aquitaine
16016,ANSAC SUR VIENNE,16500,ANSAC SUR VIENNE,,45.9943029714,0.619730334325,16,,Ansac-sur-Vienne,Ansac-sur-Vienne,16,Charente,75,Nouvelle-Aquitaine
16017,ANVILLE,16170,ANVILLE,,45.8313102737,-0.121697535587,17,,Anville,Anville,16,Charente,75,Nouvelle-Aquitaine
16018,ARS,16130,ARS,,45.6370134609,-0.402334253854,18,,Ars,Ars,16,Charente,75,Nouvelle-Aquitaine
16019,ASNIERES SUR NOUERE,16290,ASNIERES SUR NOUERE,,45.7078415562,0.0487826997236,19,,Asnières-sur-Nouère,Asnières-sur-Nouère,16,Charente,75,Nouvelle-Aquitaine
16020,AUBETERRE SUR DRONNE,16390,AUBETERRE SUR DRONNE,,45.2609347512,0.16792049879,20,,Aubeterre-sur-Dronne,Aubeterre-sur-Dronne,16,Charente,75,Nouvelle-Aquitaine
16023,AUNAC,16460,AUNAC,,45.9151202066,0.255788080504,23,,Aunac-sur-Charente,Aunac-sur-Charente,16,Charente,75,Nouvelle-Aquitaine
16024,AUSSAC VADALLE,16560,AUSSAC VADALLE,,45.8215780288,0.215598559823,24,,Aussac-Vadalle,Aussac-Vadalle,16,Charente,75,Nouvelle-Aquitaine
16025,BAIGNES STE RADEGONDE,16360,BAIGNES STE RADEGONDE,,45.374443862,-0.254082617191,25,,Baignes-Sainte-Radegonde,Baignes-Sainte-Radegonde,16,Charente,75,Nouvelle-Aquitaine
16026,BALZAC,16430,BALZAC,,45.7127837956,0.134124616081,26,,Balzac,Balzac,16,Charente,75,Nouvelle-Aquitaine
16027,BARBEZIERES,16140,BARBEZIERES,,45.9119778347,-0.0882569539887,27,,Barbezières,Barbezières,16,Charente,75,Nouvelle-Aquitaine
16028,BARBEZIEUX ST HILAIRE,16300,BARBEZIEUX ST HILAIRE,,45.4780382344,-0.157746079718,28,,Barbezieux-Saint-Hilaire,Barbezieux-Saint-Hilaire,16,Charente,75,Nouvelle-Aquitaine
16028,BARBEZIEUX ST HILAIRE,16300,BARBEZIEUX ST HILAIRE,ST HILAIRE,45.4780382344,-0.157746079718,28,,Barbezieux-Saint-Hilaire,Barbezieux-Saint-Hilaire,16,Charente,75,Nouvelle-Aquitaine
16029,BARDENAC,16210,BARDENAC,,45.3005902681,-0.0148493213262,29,,Bardenac,Bardenac,16,Charente,75,Nouvelle-Aquitaine
16030,BARRET,16300,BARRET,,45.4891582999,-0.206308254385,30,,Barret,Barret,16,Charente,75,Nouvelle-Aquitaine
16031,BARRO,16700,BARRO,,46.0050083194,0.235825640846,31,,Barro,Barro,16,Charente,75,Nouvelle-Aquitaine
16032,BASSAC,16120,BASSAC,,45.6649796502,-0.0991802061474,32,,Bassac,Bassac,16,Charente,75,Nouvelle-Aquitaine
16033,BAYERS,16460,BAYERS,,45.924584434,0.225592494826,33,,Bayers,Bayers,16,Charente,75,Nouvelle-Aquitaine
16034,BAZAC,16210,BAZAC,,45.2211222947,0.0368876406088,34,,Bazac,Bazac,16,Charente,75,Nouvelle-Aquitaine
16035,BEAULIEU SUR SONNETTE,16450,BEAULIEU SUR SONNETTE,,45.9272378764,0.372798156825,35,,Beaulieu-sur-Sonnette,Beaulieu-sur-Sonnette,16,Charente,75,Nouvelle-Aquitaine
16036,BECHERESSE,16250,BECHERESSE,,45.5015198955,0.0786797939692,36,,Bécheresse,Bécheresse,16,Charente,75,Nouvelle-Aquitaine
16037,BELLON,16210,BELLON,,45.3129742539,0.116774132043,37,,Bellon,Bellon,16,Charente,75,Nouvelle-Aquitaine
16038,BENEST,16350,BENEST,,46.0386820793,0.452609537625,38,,Benest,Benest,16,Charente,75,Nouvelle-Aquitaine
16039,BERNAC,16700,BERNAC,,46.0522061088,0.170991094252,39,,Bernac,Bernac,16,Charente,75,Nouvelle-Aquitaine
16040,BERNEUIL,16480,BERNEUIL,,45.3908685702,-0.0668741248054,40,,Berneuil,Berneuil,16,Charente,75,Nouvelle-Aquitaine
16041,BESSAC,16250,BESSAC,,45.4311590741,-0.0136973551346,41,,Bessac,Bessac,16,Charente,75,Nouvelle-Aquitaine
16042,BESSE,16140,BESSE,,45.9581333221,0.0818571588343,42,,Bessé,Bessé,16,Charente,75,Nouvelle-Aquitaine
16044,BIOUSSAC,16700,BIOUSSAC,,46.0307876172,0.270410745983,44,,Bioussac,Bioussac,16,Charente,75,Nouvelle-Aquitaine
16045,BIRAC,16120,BIRAC,,45.5635790633,-0.0550695799339,45,,Birac,Birac,16,Charente,75,Nouvelle-Aquitaine
16046,BLANZAC PORCHERESSE,16250,BLANZAC PORCHERESSE,,45.4714654807,0.0236302142689,46,,Côteaux du Blanzacais,Côteaux du Blanzacais,16,Charente,75,Nouvelle-Aquitaine
16046,BLANZAC PORCHERESSE,16250,BLANZAC PORCHERESSE,PORCHERESSE,45.4714654807,0.0236302142689,46,,Côteaux du Blanzacais,Côteaux du Blanzacais,16,Charente,75,Nouvelle-Aquitaine
16047,BLANZAGUET ST CYBARD,16320,BLANZAGUET ST CYBARD,,45.4732165365,0.313168592903,47,,Blanzaguet-Saint-Cybard,Blanzaguet-Saint-Cybard,16,Charente,75,Nouvelle-Aquitaine
16048,BOISBRETEAU,16480,BOISBRETEAU,,45.3299592266,-0.159147291583,48,,Boisbreteau,Boisbreteau,16,Charente,75,Nouvelle-Aquitaine
16049,BONNES,16390,BONNES,,45.2363248091,0.143594569626,49,,Bonnes,Bonnes,16,Charente,75,Nouvelle-Aquitaine
16050,BONNEUIL,16120,BONNEUIL,,45.5758801915,-0.135149328713,50,,Bonneuil,Bonneuil,16,Charente,75,Nouvelle-Aquitaine
16051,BONNEVILLE,16170,BONNEVILLE,,45.8381451066,-0.0500530176925,51,,Bonneville,Bonneville,16,Charente,75,Nouvelle-Aquitaine
16052,BORS DE MONTMOREAU,16190,BORS DE MONTMOREAU,,45.3401040524,0.147879846098,52,,Bors Canton de Tude-et-Lavalette,Bors Canton de Tude-et-Lavalette,16,Charente,75,Nouvelle-Aquitaine
16053,BORS DE BAIGNES,16360,BORS DE BAIGNES,,45.3263203716,-0.199610560993,53,,Bors Canton de Charente-Sud,Bors Canton de Charente-Sud,16,Charente,75,Nouvelle-Aquitaine
16054,LE BOUCHAGE,16350,LE BOUCHAGE,,46.0493103798,0.376687406962,54,Le,Bouchage,Le Bouchage,16,Charente,75,Nouvelle-Aquitaine
16055,BOUEX,16410,BOUEX,,45.6246508567,0.318204980739,55,,Bouëx,Bouëx,16,Charente,75,Nouvelle-Aquitaine
16056,BOURG CHARENTE,16200,BOURG CHARENTE,,45.6659937822,-0.222233070692,56,,Bourg-Charente,Bourg-Charente,16,Charente,75,Nouvelle-Aquitaine
16057,BOUTEVILLE,16120,BOUTEVILLE,,45.6069622968,-0.127003754682,57,,Bouteville,Bouteville,16,Charente,75,Nouvelle-Aquitaine
16058,BOUTIERS ST TROJAN,16100,BOUTIERS ST TROJAN,,45.711028287,-0.296886698875,58,,Boutiers-Saint-Trojan,Boutiers-Saint-Trojan,16,Charente,75,Nouvelle-Aquitaine
16059,BRETTES,16240,BRETTES,,45.9974723832,0.029182060624,59,,Brettes,Brettes,16,Charente,75,Nouvelle-Aquitaine
16060,BREVILLE,16370,BREVILLE,,45.7891537145,-0.274845145483,60,,Bréville,Bréville,16,Charente,75,Nouvelle-Aquitaine
16061,BRIE,16590,BRIE,,45.7345393339,0.266852612131,61,,Brie,Brie,16,Charente,75,Nouvelle-Aquitaine
16062,BRIE SOUS BARBEZIEUX,16300,BRIE SOUS BARBEZIEUX,,45.4238677604,-0.0407675127777,62,,Brie-sous-Barbezieux,Brie-sous-Barbezieux,16,Charente,75,Nouvelle-Aquitaine
16063,BRIE SOUS CHALAIS,16210,BRIE SOUS CHALAIS,,45.3203360527,0.0123563993642,63,,Brie-sous-Chalais,Brie-sous-Chalais,16,Charente,75,Nouvelle-Aquitaine
16064,BRIGUEUIL,16420,BRIGUEUIL,,45.9509773487,0.869163472197,64,,Brigueuil,Brigueuil,16,Charente,75,Nouvelle-Aquitaine
16065,BRILLAC,16500,BRILLAC,,46.067503903,0.776760494264,65,,Brillac,Brillac,16,Charente,75,Nouvelle-Aquitaine
16066,BROSSAC,16480,BROSSAC,,45.3238579692,-0.0465934055861,66,,Brossac,Brossac,16,Charente,75,Nouvelle-Aquitaine
16067,BUNZAC,16110,BUNZAC,,45.6993800816,0.344082378962,67,,Bunzac,Bunzac,16,Charente,75,Nouvelle-Aquitaine
16068,CELLEFROUIN,16260,CELLEFROUIN,,45.8877573804,0.391851835711,68,,Cellefrouin,Cellefrouin,16,Charente,75,Nouvelle-Aquitaine
16069,CELLETTES,16230,CELLETTES,,45.8643336097,0.145594673488,69,,Cellettes,Cellettes,16,Charente,75,Nouvelle-Aquitaine
16070,CHABANAIS,16150,CHABANAIS,,45.8638291864,0.711713773005,70,,Chabanais,Chabanais,16,Charente,75,Nouvelle-Aquitaine
16071,CHABRAC,16150,CHABRAC,,45.926486151,0.746752143002,71,,Chabrac,Chabrac,16,Charente,75,Nouvelle-Aquitaine
16072,CHADURIE,16250,CHADURIE,,45.4963678637,0.142555563027,72,,Chadurie,Chadurie,16,Charente,75,Nouvelle-Aquitaine
16073,CHALAIS,16210,CHALAIS,,45.2739548684,0.0385508867289,73,,Chalais,Chalais,16,Charente,75,Nouvelle-Aquitaine
16073,CHALAIS,16210,CHALAIS,SERIGNAC,45.2739548684,0.0385508867289,73,,Chalais,Chalais,16,Charente,75,Nouvelle-Aquitaine
16073,CHALAIS,16210,CHALAIS,STE MARIE,45.2739548684,0.0385508867289,73,,Chalais,Chalais,16,Charente,75,Nouvelle-Aquitaine
16074,CHALLIGNAC,16300,CHALLIGNAC,,45.4236400106,-0.0845558170102,74,,Challignac,Challignac,16,Charente,75,Nouvelle-Aquitaine
16075,CHAMPAGNE VIGNY,16250,CHAMPAGNE VIGNY,,45.507125249,0.0414709267054,75,,Champagne-Vigny,Champagne-Vigny,16,Charente,75,Nouvelle-Aquitaine
16076,CHAMPAGNE MOUTON,16350,CHAMPAGNE MOUTON,,45.993087674,0.412273153255,76,,Champagne-Mouton,Champagne-Mouton,16,Charente,75,Nouvelle-Aquitaine
16077,CHAMPMILLON,16290,CHAMPMILLON,,45.6471775425,0.00523666300318,77,,Champmillon,Champmillon,16,Charente,75,Nouvelle-Aquitaine
16078,CHAMPNIERS,16430,CHAMPNIERS,,45.7166708711,0.196450542402,78,,Champniers,Champniers,16,Charente,75,Nouvelle-Aquitaine
16079,CHANTILLAC,16360,CHANTILLAC,,45.3264411162,-0.255453791343,79,,Chantillac,Chantillac,16,Charente,75,Nouvelle-Aquitaine
16081,LA CHAPELLE,16140,LA CHAPELLE,,45.8307508643,0.0517878633867,81,La,Chapelle,La Chapelle,16,Charente,75,Nouvelle-Aquitaine
16082,BOISNE LA TUDE,16320,BOISNE LA TUDE,CHARMANT,45.4838960103,0.177074136817,82,,Boisné-La Tude,Boisné-La Tude,16,Charente,75,Nouvelle-Aquitaine
16082,BOISNE LA TUDE,16320,BOISNE LA TUDE,CHAVENAT,45.4838960103,0.177074136817,82,,Boisné-La Tude,Boisné-La Tude,16,Charente,75,Nouvelle-Aquitaine
16082,BOISNE LA TUDE,16320,BOISNE LA TUDE,JUILLAGUET,45.4838960103,0.177074136817,82,,Boisné-La Tude,Boisné-La Tude,16,Charente,75,Nouvelle-Aquitaine
16083,CHARME,16140,CHARME,,45.9500924838,0.120032287303,83,,Charmé,Charmé,16,Charente,75,Nouvelle-Aquitaine
16084,CHARRAS,16380,CHARRAS,,45.5444379086,0.428737132242,84,,Charras,Charras,16,Charente,75,Nouvelle-Aquitaine
16085,CHASSENEUIL SUR BONNIEURE,16260,CHASSENEUIL SUR BONNIEURE,,45.8243471539,0.441043431658,85,,Chasseneuil-sur-Bonnieure,Chasseneuil-sur-Bonnieure,16,Charente,75,Nouvelle-Aquitaine
16086,CHASSENON,16150,CHASSENON,,45.8513916699,0.773197167344,86,,Chassenon,Chassenon,16,Charente,75,Nouvelle-Aquitaine
16087,CHASSIECQ,16350,CHASSIECQ,,45.9509527297,0.368543478454,87,,Chassiecq,Chassiecq,16,Charente,75,Nouvelle-Aquitaine
16088,CHASSORS,16200,CHASSORS,,45.7102602984,-0.206803437363,88,,Chassors,Chassors,16,Charente,75,Nouvelle-Aquitaine
16089,CHATEAUBERNARD,16100,CHATEAUBERNARD,,45.6720237128,-0.316074717824,89,,Châteaubernard,Châteaubernard,16,Charente,75,Nouvelle-Aquitaine
16090,CHATEAUNEUF SUR CHARENTE,16120,CHATEAUNEUF SUR CHARENTE,,45.5961486354,-0.0514205671468,90,,Châteauneuf-sur-Charente,Châteauneuf-sur-Charente,16,Charente,75,Nouvelle-Aquitaine
16091,CHATIGNAC,16480,CHATIGNAC,,45.3484001611,-0.00433406647489,91,,Châtignac,Châtignac,16,Charente,75,Nouvelle-Aquitaine
16093,CHAZELLES,16380,CHAZELLES,,45.6453528845,0.364874391871,93,,Chazelles,Chazelles,16,Charente,75,Nouvelle-Aquitaine
16094,CHENOMMET,16460,CHENOMMET,,45.9343002402,0.252955501308,94,,Chenommet,Chenommet,16,Charente,75,Nouvelle-Aquitaine
16095,CHENON,16460,CHENON,,45.9440915736,0.222439257515,95,,Chenon,Chenon,16,Charente,75,Nouvelle-Aquitaine
16096,CHERVES CHATELARS,16310,CHERVES CHATELARS,,45.8106961072,0.554054470604,96,,Cherves-Châtelars,Cherves-Châtelars,16,Charente,75,Nouvelle-Aquitaine
16097,CHERVES RICHEMONT,16370,CHERVES RICHEMONT,,45.7450738873,-0.337808012724,97,,Cherves-Richemont,Cherves-Richemont,16,Charente,75,Nouvelle-Aquitaine
16097,CHERVES RICHEMONT,16370,CHERVES RICHEMONT,RICHEMONT,45.7450738873,-0.337808012724,97,,Cherves-Richemont,Cherves-Richemont,16,Charente,75,Nouvelle-Aquitaine
16098,LA CHEVRERIE,16240,LA CHEVRERIE,,46.0444193002,0.136305747393,98,La,Chèvrerie,La Chèvrerie,16,Charente,75,Nouvelle-Aquitaine
16099,CHILLAC,16480,CHILLAC,,45.3611763101,-0.0902994967861,99,,Chillac,Chillac,16,Charente,75,Nouvelle-Aquitaine
16100,CHIRAC,16150,CHIRAC,,45.9131739872,0.685106701432,100,,Chirac,Chirac,16,Charente,75,Nouvelle-Aquitaine
16101,CLAIX,16440,CLAIX,,45.54965487,0.050592133605,101,,Claix,Claix,16,Charente,75,Nouvelle-Aquitaine
16102,COGNAC,16100,COGNAC,,45.6962326551,-0.33507941815,102,,Cognac,Cognac,16,Charente,75,Nouvelle-Aquitaine
16103,COMBIERS,16320,COMBIERS,,45.5110545763,0.408984921414,103,,Combiers,Combiers,16,Charente,75,Nouvelle-Aquitaine
16104,CONDAC,16700,CONDAC,,46.028495802,0.227925503689,104,,Condac,Condac,16,Charente,75,Nouvelle-Aquitaine
16105,CONDEON,16360,CONDEON,,45.4011643618,-0.134853631146,105,,Condéon,Condéon,16,Charente,75,Nouvelle-Aquitaine
16106,CONFOLENS,16500,CONFOLENS,,46.0191386034,0.658522279035,106,,Confolens,Confolens,16,Charente,75,Nouvelle-Aquitaine
16106,CONFOLENS,16500,CONFOLENS,ST GERMAIN DE CONFOLENS,46.0191386034,0.658522279035,106,,Confolens,Confolens,16,Charente,75,Nouvelle-Aquitaine
16107,COULGENS,16560,COULGENS,,45.8134827231,0.277167790315,107,,Coulgens,Coulgens,16,Charente,75,Nouvelle-Aquitaine
16108,COULONGES,16330,COULONGES,,45.8376302744,0.0898755953494,108,,Coulonges,Coulonges,16,Charente,75,Nouvelle-Aquitaine
16109,COURBILLAC,16200,COURBILLAC,,45.7730344217,-0.180841133968,109,,Courbillac,Courbillac,16,Charente,75,Nouvelle-Aquitaine
16110,COURCOME,16240,COURCOME,,45.9857430952,0.133351057987,110,,Courcôme,Courcôme,16,Charente,75,Nouvelle-Aquitaine
16111,COURGEAC,16190,COURGEAC,,45.3944594925,0.0818231361752,111,,Courgeac,Courgeac,16,Charente,75,Nouvelle-Aquitaine
16112,COURLAC,16210,COURLAC,,45.3012384064,0.0880679365563,112,,Courlac,Courlac,16,Charente,75,Nouvelle-Aquitaine
16113,LA COURONNE,16400,LA COURONNE,,45.6096373338,0.109121178707,113,La,Couronne,La Couronne,16,Charente,75,Nouvelle-Aquitaine
16114,COUTURE,16460,COUTURE,,45.9318430742,0.288257076109,114,,Couture,Couture,16,Charente,75,Nouvelle-Aquitaine
16115,CRESSAC ST GENIS,16250,CRESSAC ST GENIS,,45.4399527809,0.0196605456773,115,,Cressac-Saint-Genis,Cressac-Saint-Genis,16,Charente,75,Nouvelle-Aquitaine
16115,CRESSAC ST GENIS,16250,CRESSAC ST GENIS,ST GENIS DE BLANZAC,45.4399527809,0.0196605456773,115,,Cressac-Saint-Genis,Cressac-Saint-Genis,16,Charente,75,Nouvelle-Aquitaine
16116,CRITEUIL LA MAGDELEINE,16300,CRITEUIL LA MAGDELEINE,,45.5364679101,-0.2103850238,116,,Criteuil-la-Magdeleine,Criteuil-la-Magdeleine,16,Charente,75,Nouvelle-Aquitaine
16117,CURAC,16210,CURAC,,45.2960921096,0.0212788949535,117,,Curac,Curac,16,Charente,75,Nouvelle-Aquitaine
16118,DEVIAT,16190,DEVIAT,,45.4087322108,0.0148313347537,118,,Deviat,Deviat,16,Charente,75,Nouvelle-Aquitaine
16119,DIGNAC,16410,DIGNAC,,45.5542534164,0.283491007595,119,,Dignac,Dignac,16,Charente,75,Nouvelle-Aquitaine
16120,DIRAC,16410,DIRAC,,45.5986667875,0.241731538574,120,,Dirac,Dirac,16,Charente,75,Nouvelle-Aquitaine
16121,DOUZAT,16290,DOUZAT,,45.7009983146,-0.00261844248273,121,,Douzat,Douzat,16,Charente,75,Nouvelle-Aquitaine
16122,EBREON,16140,EBREON,,45.9419566214,0.0300094172426,122,,Ébréon,Ébréon,16,Charente,75,Nouvelle-Aquitaine
16123,ECHALLAT,16170,ECHALLAT,,45.7224468632,-0.04347825715,123,,Échallat,Échallat,16,Charente,75,Nouvelle-Aquitaine
16124,ECURAS,16220,ECURAS,,45.6822174766,0.569930576994,124,,Écuras,Écuras,16,Charente,75,Nouvelle-Aquitaine
16125,EDON,16320,EDON,,45.4885657865,0.351381025393,125,,Édon,Édon,16,Charente,75,Nouvelle-Aquitaine
16127,EMPURE,16240,EMPURE,,46.0245064509,0.0495912731396,127,,Empuré,Empuré,16,Charente,75,Nouvelle-Aquitaine
16128,EPENEDE,16490,EPENEDE,,46.069351116,0.537822207003,128,,Épenède,Épenède,16,Charente,75,Nouvelle-Aquitaine
16129,ERAVILLE,16120,ERAVILLE,,45.581342288,-0.0896868755833,129,,Éraville,Éraville,16,Charente,75,Nouvelle-Aquitaine
16130,LES ESSARDS,16210,LES ESSARDS,,45.237352605,0.111052044267,130,Les,Essards,Les Essards,16,Charente,75,Nouvelle-Aquitaine
16131,ESSE,16500,ESSE,,46.0262718319,0.720395594442,131,,Esse,Esse,16,Charente,75,Nouvelle-Aquitaine
16132,ETAGNAC,16150,ETAGNAC,,45.8907561281,0.783276589997,132,,Étagnac,Étagnac,16,Charente,75,Nouvelle-Aquitaine
16133,ETRIAC,16250,ETRIAC,,45.527140208,-0.00959232022065,133,,Étriac,Étriac,16,Charente,75,Nouvelle-Aquitaine
16134,EXIDEUIL,16150,EXIDEUIL,,45.8801902004,0.663734809553,134,,Exideuil,Exideuil,16,Charente,75,Nouvelle-Aquitaine
16135,EYMOUTHIERS,16220,EYMOUTHIERS,,45.6469383568,0.549961973717,135,,Eymouthiers,Eymouthiers,16,Charente,75,Nouvelle-Aquitaine
16136,LA FAYE,16700,LA FAYE,,46.0198359959,0.159355852391,136,La,Faye,La Faye,16,Charente,75,Nouvelle-Aquitaine
16137,FEUILLADE,16380,FEUILLADE,,45.5930624108,0.467173536421,137,,Feuillade,Feuillade,16,Charente,75,Nouvelle-Aquitaine
16138,FLEAC,16730,FLEAC,,45.6757321488,0.0883493158258,138,,Fléac,Fléac,16,Charente,75,Nouvelle-Aquitaine
16139,FLEURAC,16200,FLEURAC,,45.7181471826,-0.0857758671202,139,,Fleurac,Fleurac,16,Charente,75,Nouvelle-Aquitaine
16140,FONTCLAIREAU,16230,FONTCLAIREAU,,45.8918936624,0.196980841137,140,,Fontclaireau,Fontclaireau,16,Charente,75,Nouvelle-Aquitaine
16141,FONTENILLE,16230,FONTENILLE,,45.9129011037,0.174854978046,141,,Fontenille,Fontenille,16,Charente,75,Nouvelle-Aquitaine
16142,LA FORET DE TESSE,16240,LA FORET DE TESSE,,46.0765352941,0.0784986933953,142,La,Forêt-de-Tessé,La Forêt-de-Tessé,16,Charente,75,Nouvelle-Aquitaine
16143,FOUQUEBRUNE,16410,FOUQUEBRUNE,,45.5273705176,0.196901956839,143,,Fouquebrune,Fouquebrune,16,Charente,75,Nouvelle-Aquitaine
16144,FOUQUEURE,16140,FOUQUEURE,,45.8847149549,0.0756577865437,144,,Fouqueure,Fouqueure,16,Charente,75,Nouvelle-Aquitaine
16145,FOUSSIGNAC,16200,FOUSSIGNAC,,45.7127451252,-0.125082004293,145,,Foussignac,Foussignac,16,Charente,75,Nouvelle-Aquitaine
16146,GARAT,16410,GARAT,,45.6261876475,0.267214788148,146,,Garat,Garat,16,Charente,75,Nouvelle-Aquitaine
16147,GARDES LE PONTAROUX,16320,GARDES LE PONTAROUX,,45.5102605309,0.309516332026,147,,Gardes-le-Pontaroux,Gardes-le-Pontaroux,16,Charente,75,Nouvelle-Aquitaine
16148,GENAC BIGNAC,16170,GENAC BIGNAC,,45.8009500924,0.021470943172,148,,Genac-Bignac,Genac-Bignac,16,Charente,75,Nouvelle-Aquitaine
16148,GENAC BIGNAC,16170,GENAC BIGNAC,BIGNAC,45.8009500924,0.021470943172,148,,Genac-Bignac,Genac-Bignac,16,Charente,75,Nouvelle-Aquitaine
16149,GENOUILLAC,16270,GENOUILLAC,,45.848730367,0.576573242055,149,,Genouillac,Genouillac,16,Charente,75,Nouvelle-Aquitaine
16150,GENSAC LA PALLUE,16130,GENSAC LA PALLUE,,45.6572262883,-0.268790187481,150,,Gensac-la-Pallue,Gensac-la-Pallue,16,Charente,75,Nouvelle-Aquitaine
16151,GENTE,16130,GENTE,,45.636977239,-0.306975028365,151,,Genté,Genté,16,Charente,75,Nouvelle-Aquitaine
16152,GIMEUX,16130,GIMEUX,,45.6414379624,-0.36669079786,152,,Gimeux,Gimeux,16,Charente,75,Nouvelle-Aquitaine
16153,GONDEVILLE,16200,GONDEVILLE,,45.6684412124,-0.155937890008,153,,Gondeville,Gondeville,16,Charente,75,Nouvelle-Aquitaine
16154,GOND PONTOUVRE,16160,GOND PONTOUVRE,,45.6797580078,0.165506204221,154,,Gond-Pontouvre,Gond-Pontouvre,16,Charente,75,Nouvelle-Aquitaine
16155,LES GOURS,16140,LES GOURS,,45.9598718891,-0.0720164666624,155,Les,Gours,Les Gours,16,Charente,75,Nouvelle-Aquitaine
16156,GOURVILLE,16170,GOURVILLE,,45.8256659639,-0.0212286864189,156,,Gourville,Gourville,16,Charente,75,Nouvelle-Aquitaine
16157,LE GRAND MADIEU,16450,LE GRAND MADIEU,,45.9414000516,0.44751280825,157,Le,Grand-Madieu,Le Grand-Madieu,16,Charente,75,Nouvelle-Aquitaine
16158,GRASSAC,16380,GRASSAC,,45.5812707814,0.399196198105,158,,Grassac,Grassac,16,Charente,75,Nouvelle-Aquitaine
16160,GUIMPS,16300,GUIMPS,,45.465898134,-0.254673070491,160,,Guimps,Guimps,16,Charente,75,Nouvelle-Aquitaine
16161,GUIZENGEARD,16480,GUIZENGEARD,,45.3106179642,-0.123211363753,161,,Guizengeard,Guizengeard,16,Charente,75,Nouvelle-Aquitaine
16162,GURAT,16320,GURAT,,45.4358693402,0.265230281525,162,,Gurat,Gurat,16,Charente,75,Nouvelle-Aquitaine
16163,HIERSAC,16290,HIERSAC,,45.6755505146,0.00754154202178,163,,Hiersac,Hiersac,16,Charente,75,Nouvelle-Aquitaine
16164,HIESSE,16490,HIESSE,,46.0498472746,0.581727816732,164,,Hiesse,Hiesse,16,Charente,75,Nouvelle-Aquitaine
16165,HOULETTE,16200,HOULETTE,,45.7564757122,-0.210601025302,165,,Houlette,Houlette,16,Charente,75,Nouvelle-Aquitaine
16166,L ISLE D ESPAGNAC,16340,L ISLE D ESPAGNAC,,45.6638640977,0.199231746573,166,L',Isle-d'Espagnac,L'Isle-d'Espagnac,16,Charente,75,Nouvelle-Aquitaine
16167,JARNAC,16200,JARNAC,,45.6865355311,-0.164780604405,167,,Jarnac,Jarnac,16,Charente,75,Nouvelle-Aquitaine
16168,JAULDES,16560,JAULDES,,45.7808261202,0.250693934038,168,,Jauldes,Jauldes,16,Charente,75,Nouvelle-Aquitaine
16169,JAVREZAC,16100,JAVREZAC,,45.7029983448,-0.363647705405,169,,Javrezac,Javrezac,16,Charente,75,Nouvelle-Aquitaine
16170,JUIGNAC,16190,JUIGNAC,,45.3679418137,0.158358681368,170,,Juignac,Juignac,16,Charente,75,Nouvelle-Aquitaine
16171,JUILLAC LE COQ,16130,JUILLAC LE COQ,,45.5891696148,-0.256462985988,171,,Juillac-le-Coq,Juillac-le-Coq,16,Charente,75,Nouvelle-Aquitaine
16173,JUILLE,16230,JUILLE,,45.9263168412,0.141585678419,173,,Juillé,Juillé,16,Charente,75,Nouvelle-Aquitaine
16174,JULIENNE,16200,JULIENNE,,45.6903888196,-0.228857639974,174,,Julienne,Julienne,16,Charente,75,Nouvelle-Aquitaine
16175,VAL DES VIGNES,16250,VAL DES VIGNES,AUBEVILLE,45.5351688008,-0.0353997702364,175,,Val des Vignes,Val des Vignes,16,Charente,75,Nouvelle-Aquitaine
16175,VAL DES VIGNES,16250,VAL DES VIGNES,JURIGNAC,45.5351688008,-0.0353997702364,175,,Val des Vignes,Val des Vignes,16,Charente,75,Nouvelle-Aquitaine
16175,VAL DES VIGNES,16250,VAL DES VIGNES,MAINFONDS,45.5351688008,-0.0353997702364,175,,Val des Vignes,Val des Vignes,16,Charente,75,Nouvelle-Aquitaine
16175,VAL DES VIGNES,16250,VAL DES VIGNES,PEREUIL,45.5351688008,-0.0353997702364,175,,Val des Vignes,Val des Vignes,16,Charente,75,Nouvelle-Aquitaine
16176,LACHAISE,16300,LACHAISE,,45.5169284894,-0.24205405202,176,,Lachaise,Lachaise,16,Charente,75,Nouvelle-Aquitaine
16177,LADIVILLE,16120,LADIVILLE,,45.5127200885,-0.0639782676163,177,,Ladiville,Ladiville,16,Charente,75,Nouvelle-Aquitaine
16178,LAGARDE SUR LE NE,16300,LAGARDE SUR LE NE,,45.5153360206,-0.205805463982,178,,Lagarde-sur-le-Né,Lagarde-sur-le-Né,16,Charente,75,Nouvelle-Aquitaine
16180,LAPRADE,16390,LAPRADE,,45.2795486363,0.186050460414,180,,Laprade,Laprade,16,Charente,75,Nouvelle-Aquitaine
16181,LESSAC,16500,LESSAC,,46.0698879321,0.6490709569,181,,Lessac,Lessac,16,Charente,75,Nouvelle-Aquitaine
16182,LESTERPS,16420,LESTERPS,,46.012245179,0.791766541587,182,,Lesterps,Lesterps,16,Charente,75,Nouvelle-Aquitaine
16183,LESIGNAC DURAND,16310,LESIGNAC DURAND,,45.8150582723,0.6446900761,183,,Lésignac-Durand,Lésignac-Durand,16,Charente,75,Nouvelle-Aquitaine
16184,LICHERES,16460,LICHERES,,45.9042961914,0.215718804907,184,,Lichères,Lichères,16,Charente,75,Nouvelle-Aquitaine
16185,LIGNE,16140,LIGNE,,45.9156691196,0.10397491034,185,,Ligné,Ligné,16,Charente,75,Nouvelle-Aquitaine
16186,LIGNIERES SONNEVILLE,16130,LIGNIERES SONNEVILLE,,45.5660291672,-0.185136397799,186,,Lignières-Sonneville,Lignières-Sonneville,16,Charente,75,Nouvelle-Aquitaine
16187,LINARS,16730,LINARS,,45.6535202094,0.0737335176195,187,,Linars,Linars,16,Charente,75,Nouvelle-Aquitaine
16188,LE LINDOIS,16310,LE LINDOIS,,45.7508746711,0.590773518888,188,Le,Lindois,Le Lindois,16,Charente,75,Nouvelle-Aquitaine
16189,LONDIGNY,16700,LONDIGNY,,46.0846035892,0.140380117543,189,,Londigny,Londigny,16,Charente,75,Nouvelle-Aquitaine
16190,LONGRE,16240,LONGRE,,45.9956136861,-0.0236778823819,190,,Longré,Longré,16,Charente,75,Nouvelle-Aquitaine
16191,LONNES,16230,LONNES,,45.9391793197,0.181439974512,191,,Lonnes,Lonnes,16,Charente,75,Nouvelle-Aquitaine
16192,ROUMAZIERES LOUBERT,16270,ROUMAZIERES LOUBERT,,45.9100102437,0.573121657498,192,,Roumazières-Loubert,Roumazières-Loubert,16,Charente,75,Nouvelle-Aquitaine
16192,ROUMAZIERES LOUBERT,16270,ROUMAZIERES LOUBERT,ROUMAZIERES,45.9100102437,0.573121657498,192,,Roumazières-Loubert,Roumazières-Loubert,16,Charente,75,Nouvelle-Aquitaine
16193,LOUZAC ST ANDRE,16100,LOUZAC ST ANDRE,,45.7237365822,-0.39737034952,193,,Louzac-Saint-André,Louzac-Saint-André,16,Charente,75,Nouvelle-Aquitaine
16193,LOUZAC ST ANDRE,16100,LOUZAC ST ANDRE,ST ANDRE,45.7237365822,-0.39737034952,193,,Louzac-Saint-André,Louzac-Saint-André,16,Charente,75,Nouvelle-Aquitaine
16194,LUPSAULT,16140,LUPSAULT,,45.9351371201,-0.0650569575239,194,,Lupsault,Lupsault,16,Charente,75,Nouvelle-Aquitaine
16195,LUSSAC,16450,LUSSAC,,45.8580701828,0.466519460774,195,,Lussac,Lussac,16,Charente,75,Nouvelle-Aquitaine
16196,LUXE,16230,LUXE,,45.8951621262,0.124490429132,196,,Luxé,Luxé,16,Charente,75,Nouvelle-Aquitaine
16197,LA MAGDELEINE,16240,LA MAGDELEINE,,46.0486518125,0.0781578029437,197,La,Magdeleine,La Magdeleine,16,Charente,75,Nouvelle-Aquitaine
16198,MAGNAC LAVALETTE VILLARS,16320,MAGNAC LAVALETTE VILLARS,,45.5088381667,0.2552082811,198,,Magnac-Lavalette-Villars,Magnac-Lavalette-Villars,16,Charente,75,Nouvelle-Aquitaine
16199,MAGNAC SUR TOUVRE,16600,MAGNAC SUR TOUVRE,,45.6559132577,0.236141821195,199,,Magnac-sur-Touvre,Magnac-sur-Touvre,16,Charente,75,Nouvelle-Aquitaine
16200,MAINE DE BOIXE,16230,MAINE DE BOIXE,,45.8484283573,0.17815797308,200,,Maine-de-Boixe,Maine-de-Boixe,16,Charente,75,Nouvelle-Aquitaine
16202,MAINXE,16200,MAINXE,,45.6549959822,-0.185091590822,202,,Mainxe,Mainxe,16,Charente,75,Nouvelle-Aquitaine
16203,MAINZAC,16380,MAINZAC,,45.5538600835,0.476645844368,203,,Mainzac,Mainzac,16,Charente,75,Nouvelle-Aquitaine
16204,MALAVILLE,16120,MALAVILLE,,45.5525236965,-0.103272182432,204,,Bellevigne,Bellevigne,16,Charente,75,Nouvelle-Aquitaine
16205,MANOT,16500,MANOT,,45.9343000992,0.621390097085,205,,Manot,Manot,16,Charente,75,Nouvelle-Aquitaine
16206,MANSLE,16230,MANSLE,,45.8790882664,0.173859970412,206,,Mansle,Mansle,16,Charente,75,Nouvelle-Aquitaine
16207,MARCILLAC LANVILLE,16140,MARCILLAC LANVILLE,,45.8574304005,0.0183289081135,207,,Marcillac-Lanville,Marcillac-Lanville,16,Charente,75,Nouvelle-Aquitaine
16208,MAREUIL,16170,MAREUIL,,45.7724089753,-0.135920647839,208,,Mareuil,Mareuil,16,Charente,75,Nouvelle-Aquitaine
16209,MARILLAC LE FRANC,16110,MARILLAC LE FRANC,,45.7307516041,0.432572221643,209,,Marillac-le-Franc,Marillac-le-Franc,16,Charente,75,Nouvelle-Aquitaine
16210,MARSAC,16570,MARSAC,,45.7432168829,0.075225440865,210,,Marsac,Marsac,16,Charente,75,Nouvelle-Aquitaine
16211,MARTHON,16380,MARTHON,,45.6171874581,0.445486850648,211,,Marthon,Marthon,16,Charente,75,Nouvelle-Aquitaine
16212,MASSIGNAC,16310,MASSIGNAC,,45.77722919,0.656859261312,212,,Massignac,Massignac,16,Charente,75,Nouvelle-Aquitaine
16213,MAZEROLLES,16310,MAZEROLLES,,45.740251316,0.520413334007,213,,Mazerolles,Mazerolles,16,Charente,75,Nouvelle-Aquitaine
16214,MAZIERES,16270,MAZIERES,,45.8378171596,0.556363663192,214,,Mazières,Mazières,16,Charente,75,Nouvelle-Aquitaine
16215,MEDILLAC,16210,MEDILLAC,,45.2160430243,0.0140572392305,215,,Médillac,Médillac,16,Charente,75,Nouvelle-Aquitaine
16216,MERIGNAC,16200,MERIGNAC,,45.6959537144,-0.0708885459324,216,,Mérignac,Mérignac,16,Charente,75,Nouvelle-Aquitaine
16217,MERPINS,16100,MERPINS,,45.6698268314,-0.371814331652,217,,Merpins,Merpins,16,Charente,75,Nouvelle-Aquitaine
16218,MESNAC,16370,MESNAC,,45.7758612161,-0.342140667062,218,,Mesnac,Mesnac,16,Charente,75,Nouvelle-Aquitaine
16220,LES METAIRIES,16200,LES METAIRIES,,45.7072352152,-0.175701106278,220,Les,Métairies,Les Métairies,16,Charente,75,Nouvelle-Aquitaine
16221,MONS,16140,MONS,,45.874585555,-0.0332855724322,221,,Mons,Mons,16,Charente,75,Nouvelle-Aquitaine
16222,MONTBOYER,16620,MONTBOYER,,45.3315158314,0.0690097423513,222,,Montboyer,Montboyer,16,Charente,75,Nouvelle-Aquitaine
16223,MONTBRON,16220,MONTBRON,,45.667656871,0.504316384558,223,,Montbron,Montbron,16,Charente,75,Nouvelle-Aquitaine
16224,MONTMERAC,16300,MONTMERAC,LAMERAC,45.4462725205,-0.208192784801,224,,Montmérac,Montmérac,16,Charente,75,Nouvelle-Aquitaine
16224,MONTMERAC,16300,MONTMERAC,MONTCHAUDE,45.4462725205,-0.208192784801,224,,Montmérac,Montmérac,16,Charente,75,Nouvelle-Aquitaine
16225,MONTEMBOEUF,16310,MONTEMBOEUF,,45.7738787675,0.551136998408,225,,Montembœuf,Montembœuf,16,Charente,75,Nouvelle-Aquitaine
16226,MONTIGNAC CHARENTE,16330,MONTIGNAC CHARENTE,,45.7802147183,0.10266574824,226,,Montignac-Charente,Montignac-Charente,16,Charente,75,Nouvelle-Aquitaine
16227,MONTIGNAC LE COQ,16390,MONTIGNAC LE COQ,,45.3419148325,0.214682193579,227,,Montignac-le-Coq,Montignac-le-Coq,16,Charente,75,Nouvelle-Aquitaine
16228,MONTIGNE,16170,MONTIGNE,,45.8181805857,-0.0797270261267,228,,Montigné,Montigné,16,Charente,75,Nouvelle-Aquitaine
16229,MONTJEAN,16240,MONTJEAN,,46.0796805588,0.109828994536,229,,Montjean,Montjean,16,Charente,75,Nouvelle-Aquitaine
16230,MONTMOREAU ST CYBARD,16190,MONTMOREAU ST CYBARD,,45.4225777872,0.127349505692,230,,Montmoreau,Montmoreau,16,Charente,75,Nouvelle-Aquitaine
16231,MONTROLLET,16420,MONTROLLET,,45.9911418674,0.899879118608,231,,Montrollet,Montrollet,16,Charente,75,Nouvelle-Aquitaine
16232,MORNAC,16600,MORNAC,,45.6847658101,0.292715578408,232,,Mornac,Mornac,16,Charente,75,Nouvelle-Aquitaine
16233,MOSNAC,16120,MOSNAC,,45.615285295,-0.0132218881439,233,,Mosnac,Mosnac,16,Charente,75,Nouvelle-Aquitaine
16234,MOULIDARS,16290,MOULIDARS,,45.6676169254,-0.0379131392099,234,,Moulidars,Moulidars,16,Charente,75,Nouvelle-Aquitaine
16236,MOUTHIERS SUR BOEME,16440,MOUTHIERS SUR BOEME,,45.5576008503,0.122197442928,236,,Mouthiers-sur-Boëme,Mouthiers-sur-Boëme,16,Charente,75,Nouvelle-Aquitaine
16237,MOUTON,16460,MOUTON,,45.8874435614,0.236451070535,237,,Mouton,Mouton,16,Charente,75,Nouvelle-Aquitaine
16238,MOUTONNEAU,16460,MOUTONNEAU,,45.9131671826,0.22543914525,238,,Moutonneau,Moutonneau,16,Charente,75,Nouvelle-Aquitaine
16239,MOUZON,16310,MOUZON,,45.7954811744,0.611080409863,239,,Mouzon,Mouzon,16,Charente,75,Nouvelle-Aquitaine
16240,NABINAUD,16390,NABINAUD,,45.2933421272,0.210125540377,240,,Nabinaud,Nabinaud,16,Charente,75,Nouvelle-Aquitaine
16241,NANCLARS,16230,NANCLARS,,45.8437586253,0.223555807961,241,,Nanclars,Nanclars,16,Charente,75,Nouvelle-Aquitaine
16242,NANTEUIL EN VALLEE,16700,NANTEUIL EN VALLEE,,46.0061029111,0.32369225522,242,,Nanteuil-en-Vallée,Nanteuil-en-Vallée,16,Charente,75,Nouvelle-Aquitaine
16242,NANTEUIL EN VALLEE,16700,NANTEUIL EN VALLEE,AIZECQ,46.0061029111,0.32369225522,242,,Nanteuil-en-Vallée,Nanteuil-en-Vallée,16,Charente,75,Nouvelle-Aquitaine
16242,NANTEUIL EN VALLEE,16700,NANTEUIL EN VALLEE,MESSEUX,46.0061029111,0.32369225522,242,,Nanteuil-en-Vallée,Nanteuil-en-Vallée,16,Charente,75,Nouvelle-Aquitaine
16242,NANTEUIL EN VALLEE,16700,NANTEUIL EN VALLEE,MOUTARDON,46.0061029111,0.32369225522,242,,Nanteuil-en-Vallée,Nanteuil-en-Vallée,16,Charente,75,Nouvelle-Aquitaine
16242,NANTEUIL EN VALLEE,16700,NANTEUIL EN VALLEE,POUGNE,46.0061029111,0.32369225522,242,,Nanteuil-en-Vallée,Nanteuil-en-Vallée,16,Charente,75,Nouvelle-Aquitaine
16242,NANTEUIL EN VALLEE,16700,NANTEUIL EN VALLEE,ST GERVAIS,46.0061029111,0.32369225522,242,,Nanteuil-en-Vallée,Nanteuil-en-Vallée,16,Charente,75,Nouvelle-Aquitaine
16243,NERCILLAC,16200,NERCILLAC,,45.7234512916,-0.270638222071,243,,Nercillac,Nercillac,16,Charente,75,Nouvelle-Aquitaine
16244,NERSAC,16440,NERSAC,,45.6253691605,0.0621903047823,244,,Nersac,Nersac,16,Charente,75,Nouvelle-Aquitaine
16245,NIEUIL,16270,NIEUIL,,45.882845738,0.524200213781,245,,Nieuil,Nieuil,16,Charente,75,Nouvelle-Aquitaine
16246,NONAC,16190,NONAC,,45.4216451203,0.0552810681358,246,,Nonac,Nonac,16,Charente,75,Nouvelle-Aquitaine
16247,NONAVILLE,16120,NONAVILLE,,45.5327764361,-0.0843826858916,247,,Nonaville,Nonaville,16,Charente,75,Nouvelle-Aquitaine
16248,ORADOUR,16140,ORADOUR,,45.9118627713,-0.0373971324151,248,,Oradour,Oradour,16,Charente,75,Nouvelle-Aquitaine
16249,ORADOUR FANAIS,16500,ORADOUR FANAIS,,46.1149553879,0.782507477296,249,,Oradour-Fanais,Oradour-Fanais,16,Charente,75,Nouvelle-Aquitaine
16250,ORGEDEUIL,16220,ORGEDEUIL,,45.698367411,0.486392578455,250,,Orgedeuil,Orgedeuil,16,Charente,75,Nouvelle-Aquitaine
16251,ORIOLLES,16480,ORIOLLES,,45.3564714888,-0.1368388888,251,,Oriolles,Oriolles,16,Charente,75,Nouvelle-Aquitaine
16252,ORIVAL,16210,ORIVAL,,45.2829609263,0.0692144911966,252,,Orival,Orival,16,Charente,75,Nouvelle-Aquitaine
16253,PAIZAY NAUDOUIN EMBOURIE,16240,PAIZAY NAUDOUIN EMBOURIE,,46.0339157698,0.00154860718916,253,,Paizay-Naudouin-Embourie,Paizay-Naudouin-Embourie,16,Charente,75,Nouvelle-Aquitaine
16253,PAIZAY NAUDOUIN EMBOURIE,16240,PAIZAY NAUDOUIN EMBOURIE,EMBOURIE,46.0339157698,0.00154860718916,253,,Paizay-Naudouin-Embourie,Paizay-Naudouin-Embourie,16,Charente,75,Nouvelle-Aquitaine
16254,PALLUAUD,16390,PALLUAUD,,45.348051923,0.242588912427,254,,Palluaud,Palluaud,16,Charente,75,Nouvelle-Aquitaine
16255,PARZAC,16450,PARZAC,,45.9244427035,0.420746139781,255,,Parzac,Parzac,16,Charente,75,Nouvelle-Aquitaine
16256,PASSIRAC,16480,PASSIRAC,,45.3475668313,-0.0619236639057,256,,Passirac,Passirac,16,Charente,75,Nouvelle-Aquitaine
16258,PERIGNAC,16250,PERIGNAC,,45.4667323949,0.0826496107901,258,,Pérignac,Pérignac,16,Charente,75,Nouvelle-Aquitaine
16259,LA PERUSE,16270,LA PERUSE,,45.8777271675,0.624009757306,259,La,Péruse,La Péruse,16,Charente,75,Nouvelle-Aquitaine
16260,PILLAC,16390,PILLAC,,45.3189822009,0.18101331727,260,,Pillac,Pillac,16,Charente,75,Nouvelle-Aquitaine
16261,LES PINS,16260,LES PINS,,45.8124888516,0.36998318063,261,Les,Pins,Les Pins,16,Charente,75,Nouvelle-Aquitaine
16263,PLASSAC ROUFFIAC,16250,PLASSAC ROUFFIAC,,45.5245837373,0.0763918850702,263,,Plassac-Rouffiac,Plassac-Rouffiac,16,Charente,75,Nouvelle-Aquitaine
16264,PLEUVILLE,16490,PLEUVILLE,,46.0971776924,0.492356946293,264,,Pleuville,Pleuville,16,Charente,75,Nouvelle-Aquitaine
16267,POULLIGNAC,16190,POULLIGNAC,,45.3965100536,-0.010889797481,267,,Poullignac,Poullignac,16,Charente,75,Nouvelle-Aquitaine
16268,POURSAC,16700,POURSAC,,45.9617677073,0.265403568791,268,,Poursac,Poursac,16,Charente,75,Nouvelle-Aquitaine
16269,PRANZAC,16110,PRANZAC,,45.67389958,0.346548387034,269,,Pranzac,Pranzac,16,Charente,75,Nouvelle-Aquitaine
16270,PRESSIGNAC,16150,PRESSIGNAC,,45.8193617706,0.739906904422,270,,Pressignac,Pressignac,16,Charente,75,Nouvelle-Aquitaine
16271,PUYMOYEN,16400,PUYMOYEN,,45.6160185943,0.172580597742,271,,Puymoyen,Puymoyen,16,Charente,75,Nouvelle-Aquitaine
16272,PUYREAUX,16230,PUYREAUX,,45.86360531,0.210617195274,272,,Puyréaux,Puyréaux,16,Charente,75,Nouvelle-Aquitaine
16273,RAIX,16240,RAIX,,46.0041231051,0.11629336996,273,,Raix,Raix,16,Charente,75,Nouvelle-Aquitaine
16274,RANCOGNE,16110,RANCOGNE,,45.7024273965,0.394415078695,274,,Rancogne,Rancogne,16,Charente,75,Nouvelle-Aquitaine
16275,RANVILLE BREUILLAUD,16140,RANVILLE BREUILLAUD,,45.9094260001,-0.123952304555,275,,Ranville-Breuillaud,Ranville-Breuillaud,16,Charente,75,Nouvelle-Aquitaine
16276,REIGNAC,16360,REIGNAC,,45.4250019229,-0.174457411531,276,,Reignac,Reignac,16,Charente,75,Nouvelle-Aquitaine
16277,REPARSAC,16200,REPARSAC,,45.7367542144,-0.249443713353,277,,Réparsac,Réparsac,16,Charente,75,Nouvelle-Aquitaine
16279,RIOUX MARTIN,16210,RIOUX MARTIN,,45.2431688439,-0.00417605640764,279,,Rioux-Martin,Rioux-Martin,16,Charente,75,Nouvelle-Aquitaine
16280,RIVIERES,16110,RIVIERES,,45.7608747272,0.359373568678,280,,Rivières,Rivières,16,Charente,75,Nouvelle-Aquitaine
16281,LA ROCHEFOUCAULD,16110,LA ROCHEFOUCAULD,,45.7334327476,0.3937027955,281,La,Rochefoucauld,La Rochefoucauld,16,Charente,75,Nouvelle-Aquitaine
16282,LA ROCHETTE,16110,LA ROCHETTE,,45.7979119983,0.310269933524,282,La,Rochette,La Rochette,16,Charente,75,Nouvelle-Aquitaine
16283,RONSENAC,16320,RONSENAC,,45.4565998165,0.238466664692,283,,Ronsenac,Ronsenac,16,Charente,75,Nouvelle-Aquitaine
16284,ROUFFIAC,16210,ROUFFIAC,,45.2653712748,0.0982630036478,284,,Rouffiac,Rouffiac,16,Charente,75,Nouvelle-Aquitaine
16285,ROUGNAC,16320,ROUGNAC,,45.5371194351,0.354245072318,285,,Rougnac,Rougnac,16,Charente,75,Nouvelle-Aquitaine
16286,ROUILLAC,16170,ROUILLAC,,45.781947293,-0.074390136916,286,,Rouillac,Rouillac,16,Charente,75,Nouvelle-Aquitaine
16286,ROUILLAC,16170,ROUILLAC,PLAIZAC,45.781947293,-0.074390136916,286,,Rouillac,Rouillac,16,Charente,75,Nouvelle-Aquitaine
16286,ROUILLAC,16170,ROUILLAC,SONNEVILLE,45.781947293,-0.074390136916,286,,Rouillac,Rouillac,16,Charente,75,Nouvelle-Aquitaine
16287,ROULLET ST ESTEPHE,16440,ROULLET ST ESTEPHE,,45.5803788491,0.0313550273594,287,,Roullet-Saint-Estèphe,Roullet-Saint-Estèphe,16,Charente,75,Nouvelle-Aquitaine
16287,ROULLET ST ESTEPHE,16440,ROULLET ST ESTEPHE,ST ESTEPHE,45.5803788491,0.0313550273594,287,,Roullet-Saint-Estèphe,Roullet-Saint-Estèphe,16,Charente,75,Nouvelle-Aquitaine
16289,ROUSSINES,16310,ROUSSINES,,45.7228708787,0.617688422221,289,,Roussines,Roussines,16,Charente,75,Nouvelle-Aquitaine
16290,ROUZEDE,16220,ROUZEDE,,45.7200534944,0.562265132073,290,,Rouzède,Rouzède,16,Charente,75,Nouvelle-Aquitaine
16291,RUELLE SUR TOUVRE,16600,RUELLE SUR TOUVRE,,45.6836924747,0.231195852301,291,,Ruelle-sur-Touvre,Ruelle-sur-Touvre,16,Charente,75,Nouvelle-Aquitaine
16292,RUFFEC,16700,RUFFEC,,46.039455304,0.199192104707,292,,Ruffec,Ruffec,16,Charente,75,Nouvelle-Aquitaine
16293,ST ADJUTORY,16310,ST ADJUTORY,,45.7687840494,0.478102533662,293,,Saint-Adjutory,Saint-Adjutory,16,Charente,75,Nouvelle-Aquitaine
16294,ST AMANT DE MONTMOREAU,16190,ST AMANT DE MONTMOREAU,,45.4047927115,0.171291549718,294,,Saint-Amant-de-Montmoreau,Saint-Amant-de-Montmoreau,16,Charente,75,Nouvelle-Aquitaine
16295,ST AMANT DE BOIXE,16330,ST AMANT DE BOIXE,,45.8023519655,0.139724725099,295,,Saint-Amant-de-Boixe,Saint-Amant-de-Boixe,16,Charente,75,Nouvelle-Aquitaine
16296,ST AMANT DE BONNIEURE,16230,ST AMANT DE BONNIEURE,,45.859466527,0.29560744863,296,,Saint-Amant-de-Bonnieure,Saint-Amant-de-Bonnieure,16,Charente,75,Nouvelle-Aquitaine
16297,GRAVES ST AMANT,16120,GRAVES ST AMANT,,45.6383664934,-0.104970620109,297,,Graves-Saint-Amant,Graves-Saint-Amant,16,Charente,75,Nouvelle-Aquitaine
16297,GRAVES ST AMANT,16120,GRAVES ST AMANT,GRAVES,45.6383664934,-0.104970620109,297,,Graves-Saint-Amant,Graves-Saint-Amant,16,Charente,75,Nouvelle-Aquitaine
16298,ST AMANT DE NOUERE,16170,ST AMANT DE NOUERE,,45.7363123323,-0.00546098740035,298,,Saint-Amant-de-Nouère,Saint-Amant-de-Nouère,16,Charente,75,Nouvelle-Aquitaine
16300,ST ANGEAU,16230,ST ANGEAU,,45.8371884276,0.272464077721,300,,Val-de-Bonnieure,Val-de-Bonnieure,16,Charente,75,Nouvelle-Aquitaine
16301,ST AULAIS LA CHAPELLE,16300,ST AULAIS LA CHAPELLE,,45.4479230686,-0.0520828628972,301,,Saint-Aulais-la-Chapelle,Saint-Aulais-la-Chapelle,16,Charente,75,Nouvelle-Aquitaine
16302,ST AVIT,16210,ST AVIT,,45.2438261772,0.0442847703212,302,,Saint-Avit,Saint-Avit,16,Charente,75,Nouvelle-Aquitaine
16303,ST BONNET,16300,ST BONNET,,45.4739641055,-0.0892788794153,303,,Saint-Bonnet,Saint-Bonnet,16,Charente,75,Nouvelle-Aquitaine
16304,ST BRICE,16100,ST BRICE,,45.6902103203,-0.264553424103,304,,Saint-Brice,Saint-Brice,16,Charente,75,Nouvelle-Aquitaine
16306,ST CHRISTOPHE,16420,ST CHRISTOPHE,,46.0040190503,0.849752490764,306,,Saint-Christophe,Saint-Christophe,16,Charente,75,Nouvelle-Aquitaine
16307,ST CIERS SUR BONNIEURE,16230,ST CIERS SUR BONNIEURE,,45.8648580415,0.247717734132,307,,Saint-Ciers-sur-Bonnieure,Saint-Ciers-sur-Bonnieure,16,Charente,75,Nouvelle-Aquitaine
16308,ST CLAUD,16450,ST CLAUD,,45.8982985604,0.471600426637,308,,Saint-Claud,Saint-Claud,16,Charente,75,Nouvelle-Aquitaine
16309,STE COLOMBE,16230,STE COLOMBE,,45.8295299814,0.320048436517,309,,Sainte-Colombe,Sainte-Colombe,16,Charente,75,Nouvelle-Aquitaine
16310,ST COUTANT,16350,ST COUTANT,,45.9946101351,0.465813321452,310,,Saint-Coutant,Saint-Coutant,16,Charente,75,Nouvelle-Aquitaine
16312,ST CYBARDEAUX,16170,ST CYBARDEAUX,,45.7726989644,-0.0255477893935,312,,Saint-Cybardeaux,Saint-Cybardeaux,16,Charente,75,Nouvelle-Aquitaine
16314,ST EUTROPE,16190,ST EUTROPE,,45.4216917443,0.108407115609,314,,Saint-Eutrope,Saint-Eutrope,16,Charente,75,Nouvelle-Aquitaine
16315,ST FELIX,16480,ST FELIX,,45.3707836082,0.0116218652853,315,,Saint-Félix,Saint-Félix,16,Charente,75,Nouvelle-Aquitaine
16316,ST FORT SUR LE NE,16130,ST FORT SUR LE NE,,45.5831748847,-0.303510624665,316,,Saint-Fort-sur-le-Né,Saint-Fort-sur-le-Né,16,Charente,75,Nouvelle-Aquitaine
16317,ST FRAIGNE,16140,ST FRAIGNE,,45.9572719379,-0.0153853235153,317,,Saint-Fraigne,Saint-Fraigne,16,Charente,75,Nouvelle-Aquitaine
16318,ST FRONT,16460,ST FRONT,,45.8962473577,0.281694716965,318,,Saint-Front,Saint-Front,16,Charente,75,Nouvelle-Aquitaine
16320,ST GENIS D HIERSAC,16570,ST GENIS D HIERSAC,,45.7541273266,0.0365172036644,320,,Saint-Genis-d'Hiersac,Saint-Genis-d'Hiersac,16,Charente,75,Nouvelle-Aquitaine
16321,ST GEORGES,16700,ST GEORGES,,45.9760746261,0.269280643049,321,,Saint-Georges,Saint-Georges,16,Charente,75,Nouvelle-Aquitaine
16323,ST GERMAIN DE MONTBRON,16380,ST GERMAIN DE MONTBRON,,45.6336571325,0.413391881443,323,,Saint-Germain-de-Montbron,Saint-Germain-de-Montbron,16,Charente,75,Nouvelle-Aquitaine
16325,ST GOURSON,16700,ST GOURSON,,45.9521446438,0.312520807637,325,,Saint-Gourson,Saint-Gourson,16,Charente,75,Nouvelle-Aquitaine
16326,ST GROUX,16230,ST GROUX,,45.8908643906,0.158281898668,326,,Saint-Groux,Saint-Groux,16,Charente,75,Nouvelle-Aquitaine
16328,ST LAURENT DE BELZAGOT,16190,ST LAURENT DE BELZAGOT,,45.3818205096,0.108525623697,328,,Saint-Laurent-de-Belzagot,Saint-Laurent-de-Belzagot,16,Charente,75,Nouvelle-Aquitaine
16329,ST LAURENT DE CERIS,16450,ST LAURENT DE CERIS,,45.9432646636,0.49869730659,329,,Saint-Laurent-de-Céris,Saint-Laurent-de-Céris,16,Charente,75,Nouvelle-Aquitaine
16330,ST LAURENT DE COGNAC,16100,ST LAURENT DE COGNAC,,45.6950500912,-0.394353792222,330,,Saint-Laurent-de-Cognac,Saint-Laurent-de-Cognac,16,Charente,75,Nouvelle-Aquitaine
16331,ST LAURENT DES COMBES,16480,ST LAURENT DES COMBES,,45.3532898017,0.0345106941668,331,,Saint-Laurent-des-Combes,Saint-Laurent-des-Combes,16,Charente,75,Nouvelle-Aquitaine
16332,ST LEGER,16250,ST LEGER,,45.4552396012,0.0450762216981,332,,Saint-Léger,Saint-Léger,16,Charente,75,Nouvelle-Aquitaine
16334,ST MARTIAL,16190,ST MARTIAL,,45.3713184572,0.0580275903451,334,,Saint-Martial,Saint-Martial,16,Charente,75,Nouvelle-Aquitaine
16335,ST MARTIN DU CLOCHER,16700,ST MARTIN DU CLOCHER,,46.0649581339,0.149662855292,335,,Saint-Martin-du-Clocher,Saint-Martin-du-Clocher,16,Charente,75,Nouvelle-Aquitaine
16336,ST MARY,16260,ST MARY,,45.8452386598,0.362015823,336,,Saint-Mary,Saint-Mary,16,Charente,75,Nouvelle-Aquitaine
16337,ST MAURICE DES LIONS,16500,ST MAURICE DES LIONS,,45.9687818149,0.700155863505,337,,Saint-Maurice-des-Lions,Saint-Maurice-des-Lions,16,Charente,75,Nouvelle-Aquitaine
16338,ST MEDARD,16300,ST MEDARD DE BARBEZIEUX,,45.5085263849,-0.134402702237,338,,Saint-Médard,Saint-Médard,16,Charente,75,Nouvelle-Aquitaine
16339,AUGE ST MEDARD,16170,AUGE ST MEDARD,,45.8511416602,-0.0872262855566,339,,Auge-Saint-Médard,Auge-Saint-Médard,16,Charente,75,Nouvelle-Aquitaine
16339,AUGE ST MEDARD,16170,AUGE ST MEDARD,AUGE,45.8511416602,-0.0872262855566,339,,Auge-Saint-Médard,Auge-Saint-Médard,16,Charente,75,Nouvelle-Aquitaine
16339,AUGE ST MEDARD,16170,AUGE ST MEDARD,ST MEDARD,45.8511416602,-0.0872262855566,339,,Auge-Saint-Médard,Auge-Saint-Médard,16,Charente,75,Nouvelle-Aquitaine
16340,ST MEME LES CARRIERES,16720,ST MEME LES CARRIERES,,45.6446845538,-0.142912340299,340,,Saint-Même-les-Carrières,Saint-Même-les-Carrières,16,Charente,75,Nouvelle-Aquitaine
16341,ST MICHEL,16470,ST MICHEL,,45.6398574836,0.110389548668,341,,Saint-Michel,Saint-Michel,16,Charente,75,Nouvelle-Aquitaine
16342,ST PALAIS DU NE,16300,ST PALAIS DU NE,,45.5408910376,-0.28660127584,342,,Saint-Palais-du-Né,Saint-Palais-du-Né,16,Charente,75,Nouvelle-Aquitaine
16343,ST PREUIL,16130,ST PREUIL,,45.6007267045,-0.172169004384,343,,Saint-Preuil,Saint-Preuil,16,Charente,75,Nouvelle-Aquitaine
16344,ST PROJET ST CONSTANT,16110,ST PROJET ST CONSTANT,,45.7287178161,0.343048613864,344,,Saint-Projet-Saint-Constant,Saint-Projet-Saint-Constant,16,Charente,75,Nouvelle-Aquitaine
16345,ST QUENTIN SUR CHARENTE,16150,ST QUENTIN SUR CHARENTE,,45.838055704,0.675525418032,345,,Saint-Quentin-sur-Charente,Saint-Quentin-sur-Charente,16,Charente,75,Nouvelle-Aquitaine
16346,ST QUENTIN DE CHALAIS,16210,ST QUENTIN DE CHALAIS,,45.2429849506,0.0733899615391,346,,Saint-Quentin-de-Chalais,Saint-Quentin-de-Chalais,16,Charente,75,Nouvelle-Aquitaine
16347,ST ROMAIN,16210,ST ROMAIN,,45.2834371664,0.137230100319,347,,Saint-Romain,Saint-Romain,16,Charente,75,Nouvelle-Aquitaine
16348,ST SATURNIN,16290,ST SATURNIN,,45.6676447323,0.0400326832597,348,,Saint-Saturnin,Saint-Saturnin,16,Charente,75,Nouvelle-Aquitaine
16349,STE SEVERE,16200,STE SEVERE,,45.7641596067,-0.250325475008,349,,Sainte-Sévère,Sainte-Sévère,16,Charente,75,Nouvelle-Aquitaine
16350,ST SEVERIN,16390,ST SEVERIN,,45.3109814226,0.245248838194,350,,Saint-Séverin,Saint-Séverin,16,Charente,75,Nouvelle-Aquitaine
16351,ST SIMEUX,16120,ST SIMEUX,,45.6340380938,-0.0343232416979,351,,Saint-Simeux,Saint-Simeux,16,Charente,75,Nouvelle-Aquitaine
16352,ST SIMON,16120,ST SIMON,,45.6553833109,-0.0748911541972,352,,Saint-Simon,Saint-Simon,16,Charente,75,Nouvelle-Aquitaine
16353,ST SORNIN,16220,ST SORNIN,,45.6950797067,0.443014259379,353,,Saint-Sornin,Saint-Sornin,16,Charente,75,Nouvelle-Aquitaine
16354,STE SOULINE,16480,STE SOULINE,,45.3774799244,-0.0211237715554,354,,Sainte-Souline,Sainte-Souline,16,Charente,75,Nouvelle-Aquitaine
16355,ST SULPICE DE COGNAC,16370,ST SULPICE DE COGNAC,,45.7590478369,-0.409801090827,355,,Saint-Sulpice-de-Cognac,Saint-Sulpice-de-Cognac,16,Charente,75,Nouvelle-Aquitaine
16356,ST SULPICE DE RUFFEC,16460,ST SULPICE DE RUFFEC,,45.9372570852,0.318948540786,356,,Saint-Sulpice-de-Ruffec,Saint-Sulpice-de-Ruffec,16,Charente,75,Nouvelle-Aquitaine
16357,ST VALLIER,16480,ST VALLIER,,45.2881730966,-0.0840666739743,357,,Saint-Vallier,Saint-Vallier,16,Charente,75,Nouvelle-Aquitaine
16358,ST YRIEIX SUR CHARENTE,16710,ST YRIEIX SUR CHARENTE,,45.6818416961,0.128271343194,358,,Saint-Yrieix-sur-Charente,Saint-Yrieix-sur-Charente,16,Charente,75,Nouvelle-Aquitaine
16359,SALLES D ANGLES,16130,SALLES D ANGLES,,45.6192884058,-0.344548280548,359,,Salles-d'Angles,Salles-d'Angles,16,Charente,75,Nouvelle-Aquitaine
16360,SALLES DE BARBEZIEUX,16300,SALLES DE BARBEZIEUX,,45.4517011196,-0.121693762991,360,,Salles-de-Barbezieux,Salles-de-Barbezieux,16,Charente,75,Nouvelle-Aquitaine
16361,SALLES DE VILLEFAGNAN,16700,SALLES DE VILLEFAGNAN,,45.9599094427,0.17124025939,361,,Salles-de-Villefagnan,Salles-de-Villefagnan,16,Charente,75,Nouvelle-Aquitaine
16362,SALLES LAVALETTE,16190,SALLES LAVALETTE,,45.3880236045,0.23183888353,362,,Salles-Lavalette,Salles-Lavalette,16,Charente,75,Nouvelle-Aquitaine
16363,SAULGOND,16420,SAULGOND,,45.9563701497,0.786714506522,363,,Saulgond,Saulgond,16,Charente,75,Nouvelle-Aquitaine
16364,SAUVAGNAC,16310,SAUVAGNAC,,45.7495247956,0.647983960034,364,,Sauvagnac,Sauvagnac,16,Charente,75,Nouvelle-Aquitaine
16365,SAUVIGNAC,16480,SAUVIGNAC,,45.2586469785,-0.0772578283204,365,,Sauvignac,Sauvignac,16,Charente,75,Nouvelle-Aquitaine
16366,SEGONZAC,16130,SEGONZAC,,45.6180187853,-0.215554491203,366,,Segonzac,Segonzac,16,Charente,75,Nouvelle-Aquitaine
16368,SERS,16410,SERS,,45.5853962792,0.318693870271,368,,Sers,Sers,16,Charente,75,Nouvelle-Aquitaine
16369,SIGOGNE,16200,SIGOGNE,,45.7376347381,-0.165302283456,369,,Sigogne,Sigogne,16,Charente,75,Nouvelle-Aquitaine
16370,SIREUIL,16440,SIREUIL,,45.6203072664,0.0196009850937,370,,Sireuil,Sireuil,16,Charente,75,Nouvelle-Aquitaine
16372,SOUFFRIGNAC,16380,SOUFFRIGNAC,,45.5866525328,0.498091026273,372,,Souffrignac,Souffrignac,16,Charente,75,Nouvelle-Aquitaine
16373,SOUVIGNE,16240,SOUVIGNE,,45.9708257553,0.0505294709471,373,,Souvigné,Souvigné,16,Charente,75,Nouvelle-Aquitaine
16374,SOYAUX,16800,SOYAUX,,45.6397669318,0.206625703571,374,,Soyaux,Soyaux,16,Charente,75,Nouvelle-Aquitaine
16375,SUAUX,16260,SUAUX,,45.8479006288,0.513524239877,375,,Suaux,Suaux,16,Charente,75,Nouvelle-Aquitaine
16376,SURIS,16270,SURIS,,45.8489278877,0.622113104648,376,,Suris,Suris,16,Charente,75,Nouvelle-Aquitaine
16377,LA TACHE,16260,LA TACHE,,45.8701965185,0.362525662176,377,La,Tâche,La Tâche,16,Charente,75,Nouvelle-Aquitaine
16378,TAIZE AIZIE,16700,TAIZE AIZIE,,46.0636731179,0.247464724639,378,,Taizé-Aizie,Taizé-Aizie,16,Charente,75,Nouvelle-Aquitaine
16379,TAPONNAT FLEURIGNAC,16110,TAPONNAT FLEURIGNAC,,45.7739607246,0.417726239972,379,,Taponnat-Fleurignac,Taponnat-Fleurignac,16,Charente,75,Nouvelle-Aquitaine
16380,LE TATRE,16360,LE TATRE,,45.3977502437,-0.200743802609,380,Le,Tâtre,Le Tâtre,16,Charente,75,Nouvelle-Aquitaine
16381,THEIL RABIER,16240,THEIL RABIER,,46.0558979641,0.044130741302,381,,Theil-Rabier,Theil-Rabier,16,Charente,75,Nouvelle-Aquitaine
16382,TORSAC,16410,TORSAC,,45.5690420588,0.208774743637,382,,Torsac,Torsac,16,Charente,75,Nouvelle-Aquitaine
16383,TOURRIERS,16560,TOURRIERS,,45.7929702195,0.1939985026,383,,Tourriers,Tourriers,16,Charente,75,Nouvelle-Aquitaine
16384,TOUVERAC,16360,TOUVERAC,,45.3740871149,-0.201503841604,384,,Touvérac,Touvérac,16,Charente,75,Nouvelle-Aquitaine
16385,TOUVRE,16600,TOUVRE,,45.658185613,0.267862440841,385,,Touvre,Touvre,16,Charente,75,Nouvelle-Aquitaine
16386,TOUZAC,16120,TOUZAC,,45.5386944095,-0.149448377532,386,,Touzac,Touzac,16,Charente,75,Nouvelle-Aquitaine
16387,TRIAC LAUTRAIT,16200,TRIAC LAUTRAIT,,45.6828749184,-0.115750657737,387,,Triac-Lautrait,Triac-Lautrait,16,Charente,75,Nouvelle-Aquitaine
16387,TRIAC LAUTRAIT,16200,TRIAC LAUTRAIT,LANTIN,45.6828749184,-0.115750657737,387,,Triac-Lautrait,Triac-Lautrait,16,Charente,75,Nouvelle-Aquitaine
16388,TROIS PALIS,16730,TROIS PALIS,,45.6399613297,0.0464434707725,388,,Trois-Palis,Trois-Palis,16,Charente,75,Nouvelle-Aquitaine
16389,TURGON,16350,TURGON,,45.9523992009,0.410917379931,389,,Turgon,Turgon,16,Charente,75,Nouvelle-Aquitaine
16390,TUSSON,16140,TUSSON,,45.931556365,0.0704135702766,390,,Tusson,Tusson,16,Charente,75,Nouvelle-Aquitaine
16391,TUZIE,16700,TUZIE,,45.9714100031,0.15018986941,391,,Tuzie,Tuzie,16,Charente,75,Nouvelle-Aquitaine
16392,VALENCE,16460,VALENCE,,45.8823772883,0.317842370883,392,,Valence,Valence,16,Charente,75,Nouvelle-Aquitaine
16393,VARS,16330,VARS,,45.7564469034,0.136239937646,393,,Vars,Vars,16,Charente,75,Nouvelle-Aquitaine
16394,VAUX LAVALETTE,16320,VAUX LAVALETTE,,45.4166039956,0.240652397324,394,,Vaux-Lavalette,Vaux-Lavalette,16,Charente,75,Nouvelle-Aquitaine
16395,VAUX ROUILLAC,16170,VAUX ROUILLAC,,45.7417093777,-0.0897179678272,395,,Vaux-Rouillac,Vaux-Rouillac,16,Charente,75,Nouvelle-Aquitaine
16396,VENTOUSE,16460,VENTOUSE,,45.9125661232,0.324665203428,396,,Ventouse,Ventouse,16,Charente,75,Nouvelle-Aquitaine
16397,VERDILLE,16140,VERDILLE,,45.8810519501,-0.095445379465,397,,Verdille,Verdille,16,Charente,75,Nouvelle-Aquitaine
16398,VERNEUIL,16310,VERNEUIL,,45.7826832991,0.698625263391,398,,Verneuil,Verneuil,16,Charente,75,Nouvelle-Aquitaine
16399,VERRIERES,16130,VERRIERES,,45.5635438011,-0.262225205266,399,,Verrières,Verrières,16,Charente,75,Nouvelle-Aquitaine
16400,VERTEUIL SUR CHARENTE,16510,VERTEUIL SUR CHARENTE,,45.9780560567,0.226069473062,400,,Verteuil-sur-Charente,Verteuil-sur-Charente,16,Charente,75,Nouvelle-Aquitaine
16401,VERVANT,16330,VERVANT,,45.8353596714,0.129842160718,401,,Vervant,Vervant,16,Charente,75,Nouvelle-Aquitaine
16402,VIBRAC,16120,VIBRAC,,45.6431019638,-0.0593033677252,402,,Vibrac,Vibrac,16,Charente,75,Nouvelle-Aquitaine
16403,LE VIEUX CERIER,16350,LE VIEUX CERIER,,45.9701910018,0.447706258418,403,Le,Vieux-Cérier,Le Vieux-Cérier,16,Charente,75,Nouvelle-Aquitaine
16404,VIEUX RUFFEC,16350,VIEUX RUFFEC,,46.0162630355,0.379086474568,404,,Vieux-Ruffec,Vieux-Ruffec,16,Charente,75,Nouvelle-Aquitaine
16405,VIGNOLLES,16300,VIGNOLLES,,45.5067551698,-0.0973753032134,405,,Vignolles,Vignolles,16,Charente,75,Nouvelle-Aquitaine
16406,VILHONNEUR,16220,VILHONNEUR,,45.6749371405,0.413690557733,406,,Vilhonneur,Vilhonneur,16,Charente,75,Nouvelle-Aquitaine
16408,VILLEBOIS LAVALETTE,16320,VILLEBOIS LAVALETTE,,45.4787469231,0.282759824793,408,,Villebois-Lavalette,Villebois-Lavalette,16,Charente,75,Nouvelle-Aquitaine
16409,VILLEFAGNAN,16240,VILLEFAGNAN,,46.0118055338,0.0886198122699,409,,Villefagnan,Villefagnan,16,Charente,75,Nouvelle-Aquitaine
16410,VILLEGATS,16700,VILLEGATS,,45.9908940111,0.187834729941,410,,Villegats,Villegats,16,Charente,75,Nouvelle-Aquitaine
16411,VILLEJESUS,16140,VILLEJESUS,,45.9072730643,0.0325227133588,411,,Villejésus,Villejésus,16,Charente,75,Nouvelle-Aquitaine
16412,VILLEJOUBERT,16560,VILLEJOUBERT,,45.8074616252,0.173120462313,412,,Villejoubert,Villejoubert,16,Charente,75,Nouvelle-Aquitaine
16413,VILLIERS LE ROUX,16240,VILLIERS LE ROUX,,46.0512318303,0.109503463372,413,,Villiers-le-Roux,Villiers-le-Roux,16,Charente,75,Nouvelle-Aquitaine
16414,VILLOGNON,16230,VILLOGNON,,45.8607875247,0.108513069794,414,,Villognon,Villognon,16,Charente,75,Nouvelle-Aquitaine
16415,VINDELLE,16430,VINDELLE,,45.7209359116,0.107728857643,415,,Vindelle,Vindelle,16,Charente,75,Nouvelle-Aquitaine
16416,VITRAC ST VINCENT,16310,VITRAC ST VINCENT,,45.7977956824,0.494903805374,416,,Vitrac-Saint-Vincent,Vitrac-Saint-Vincent,16,Charente,75,Nouvelle-Aquitaine
16417,VIVILLE,16120,VIVILLE,,45.5253882542,-0.123672658113,417,,Viville,Viville,16,Charente,75,Nouvelle-Aquitaine
16418,VOEUIL ET GIGET,16400,VOEUIL ET GIGET,,45.5931085464,0.158699054904,418,,Vœuil-et-Giget,Vœuil-et-Giget,16,Charente,75,Nouvelle-Aquitaine
16419,VOUHARTE,16330,VOUHARTE,,45.807659891,0.0797642978998,419,,Vouharte,Vouharte,16,Charente,75,Nouvelle-Aquitaine
16420,VOULGEZAC,16250,VOULGEZAC,,45.5124136386,0.125737784928,420,,Voulgézac,Voulgézac,16,Charente,75,Nouvelle-Aquitaine
16421,VOUTHON,16220,VOUTHON,,45.660500114,0.447401075403,421,,Vouthon,Vouthon,16,Charente,75,Nouvelle-Aquitaine
16422,VOUZAN,16410,VOUZAN,,45.6000909965,0.359871662145,422,,Vouzan,Vouzan,16,Charente,75,Nouvelle-Aquitaine
16423,XAMBES,16330,XAMBES,,45.8237702139,0.106210551409,423,,Xambes,Xambes,16,Charente,75,Nouvelle-Aquitaine
16424,YVIERS,16210,YVIERS,,45.2735939808,-0.0246156679485,424,,Yviers,Yviers,16,Charente,75,Nouvelle-Aquitaine
16425,YVRAC ET MALLEYRAND,16110,YVRAC ET MALLEYRAND,,45.7349799572,0.466227721983,425,,Yvrac-et-Malleyrand,Yvrac-et-Malleyrand,16,Charente,75,Nouvelle-Aquitaine
17002,AGUDELLE,17500,AGUDELLE,,45.3822931603,-0.469634235538,2,,Agudelle,Agudelle,17,Charente-Maritime,75,Nouvelle-Aquitaine
17003,AIGREFEUILLE D AUNIS,17290,AIGREFEUILLE D AUNIS,,46.116506287,-0.944542003492,3,,Aigrefeuille-d'Aunis,Aigrefeuille-d'Aunis,17,Charente-Maritime,75,Nouvelle-Aquitaine
17004,ILE D AIX,17123,ILE D AIX,,46.0183336808,-1.1696249203,4,,Île-d'Aix,Île-d'Aix,17,Charente-Maritime,75,Nouvelle-Aquitaine
17005,ALLAS BOCAGE,17150,ALLAS BOCAGE,,45.3827600659,-0.497845683209,5,,Allas-Bocage,Allas-Bocage,17,Charente-Maritime,75,Nouvelle-Aquitaine
17006,ALLAS CHAMPAGNE,17500,ALLAS CHAMPAGNE,,45.4726406982,-0.339612863623,6,,Allas-Champagne,Allas-Champagne,17,Charente-Maritime,75,Nouvelle-Aquitaine
17007,ANAIS,17540,ANAIS,,46.175505871,-0.904331941311,7,,Anais,Anais,17,Charente-Maritime,75,Nouvelle-Aquitaine
17008,ANDILLY,17230,ANDILLY,,46.2630044811,-1.01765756102,8,,Andilly,Andilly,17,Charente-Maritime,75,Nouvelle-Aquitaine
17008,ANDILLY,17230,ANDILLY,SERIGNY,46.2630044811,-1.01765756102,8,,Andilly,Andilly,17,Charente-Maritime,75,Nouvelle-Aquitaine
17009,ANGLIERS,17540,ANGLIERS,,46.2061055614,-0.949106581868,9,,Angliers,Angliers,17,Charente-Maritime,75,Nouvelle-Aquitaine
17010,ANGOULINS,17690,ANGOULINS,,46.1067042744,-1.10617840927,10,,Angoulins,Angoulins,17,Charente-Maritime,75,Nouvelle-Aquitaine
17011,ANNEPONT,17350,ANNEPONT,,45.8451541952,-0.606940524552,11,,Annepont,Annepont,17,Charente-Maritime,75,Nouvelle-Aquitaine
17012,ANNEZAY,17380,ANNEZAY,,46.010330476,-0.69607751086,12,,Annezay,Annezay,17,Charente-Maritime,75,Nouvelle-Aquitaine
17013,ANTEZANT LA CHAPELLE,17400,ANTEZANT LA CHAPELLE,,45.9950682393,-0.482871743818,13,,Antezant-la-Chapelle,Antezant-la-Chapelle,17,Charente-Maritime,75,Nouvelle-Aquitaine
17013,ANTEZANT LA CHAPELLE,17400,ANTEZANT LA CHAPELLE,LA CHAPELLE BATON,45.9950682393,-0.482871743818,13,,Antezant-la-Chapelle,Antezant-la-Chapelle,17,Charente-Maritime,75,Nouvelle-Aquitaine
17015,ARCES,17120,ARCES,,45.5603861745,-0.873664735629,15,,Arces,Arces,17,Charente-Maritime,75,Nouvelle-Aquitaine
17016,ARCHIAC,17520,ARCHIAC,,45.5228923637,-0.301856482703,16,,Archiac,Archiac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17017,ARCHINGEAY,17380,ARCHINGEAY,,45.9283336011,-0.717220453831,17,,Archingeay,Archingeay,17,Charente-Maritime,75,Nouvelle-Aquitaine
17018,ARDILLIERES,17290,ARDILLIERES,,46.0498769657,-0.890135865297,18,,Ardillières,Ardillières,17,Charente-Maritime,75,Nouvelle-Aquitaine
17019,ARS EN RE,17590,ARS EN RE,,46.2111995219,-1.51504354145,19,,Ars-en-Ré,Ars-en-Ré,17,Charente-Maritime,75,Nouvelle-Aquitaine
17020,ARTHENAC,17520,ARTHENAC,,45.5031576669,-0.323776644453,20,,Arthenac,Arthenac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17021,ARVERT,17530,ARVERT,,45.7467177587,-1.12303418678,21,,Arvert,Arvert,17,Charente-Maritime,75,Nouvelle-Aquitaine
17022,ASNIERES LA GIRAUD,17400,ASNIERES LA GIRAUD,,45.8934744108,-0.510489870265,22,,Asnières-la-Giraud,Asnières-la-Giraud,17,Charente-Maritime,75,Nouvelle-Aquitaine
17023,AUJAC,17770,AUJAC,,45.8475001891,-0.394514249009,23,,Aujac,Aujac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17024,AULNAY,17470,AULNAY,,46.0203377417,-0.348095789107,24,,Aulnay,Aulnay,17,Charente-Maritime,75,Nouvelle-Aquitaine
17024,AULNAY,17470,AULNAY,SALLES LES AULNAY,46.0203377417,-0.348095789107,24,,Aulnay,Aulnay,17,Charente-Maritime,75,Nouvelle-Aquitaine
17025,AUMAGNE,17770,AUMAGNE,,45.880093124,-0.411702053139,25,,Aumagne,Aumagne,17,Charente-Maritime,75,Nouvelle-Aquitaine
17025,AUMAGNE,17770,AUMAGNE,CHAGNON,45.880093124,-0.411702053139,25,,Aumagne,Aumagne,17,Charente-Maritime,75,Nouvelle-Aquitaine
17026,AUTHON EBEON,17770,AUTHON EBEON,,45.8418838316,-0.426249552572,26,,Authon-Ébéon,Authon-Ébéon,17,Charente-Maritime,75,Nouvelle-Aquitaine
17026,AUTHON EBEON,17770,AUTHON EBEON,EBEON,45.8418838316,-0.426249552572,26,,Authon-Ébéon,Authon-Ébéon,17,Charente-Maritime,75,Nouvelle-Aquitaine
17027,AVY,17800,AVY,,45.5514357432,-0.508678091517,27,,Avy,Avy,17,Charente-Maritime,75,Nouvelle-Aquitaine
17028,AYTRE,17440,AYTRE,,46.134271716,-1.11454913295,28,,Aytré,Aytré,17,Charente-Maritime,75,Nouvelle-Aquitaine
17029,BAGNIZEAU,17160,BAGNIZEAU,,45.9004170603,-0.316541376972,29,,Bagnizeau,Bagnizeau,17,Charente-Maritime,75,Nouvelle-Aquitaine
17030,BALANZAC,17600,BALANZAC,,45.7481778738,-0.843964939234,30,,Balanzac,Balanzac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17031,BALLANS,17160,BALLANS,,45.8083081016,-0.225189641549,31,,Ballans,Ballans,17,Charente-Maritime,75,Nouvelle-Aquitaine
17032,BALLON,17290,BALLON,,46.0535112924,-0.97296366419,32,,Ballon,Ballon,17,Charente-Maritime,75,Nouvelle-Aquitaine
17033,LA BARDE,17360,LA BARDE,,45.1281301478,-0.0633132974486,33,La,Barde,La Barde,17,Charente-Maritime,75,Nouvelle-Aquitaine
17034,BARZAN,17120,BARZAN,,45.531214889,-0.867749982144,34,,Barzan,Barzan,17,Charente-Maritime,75,Nouvelle-Aquitaine
17035,BAZAUGES,17490,BAZAUGES,,45.9171649237,-0.164856392968,35,,Bazauges,Bazauges,17,Charente-Maritime,75,Nouvelle-Aquitaine
17036,BEAUGEAY,17620,BEAUGEAY,,45.8699571421,-1.00817611338,36,,Beaugeay,Beaugeay,17,Charente-Maritime,75,Nouvelle-Aquitaine
17037,BEAUVAIS SUR MATHA,17490,BEAUVAIS SUR MATHA,,45.8934079411,-0.179132842333,37,,Beauvais-sur-Matha,Beauvais-sur-Matha,17,Charente-Maritime,75,Nouvelle-Aquitaine
17038,BEDENAC,17210,BEDENAC,,45.1855001238,-0.31284209172,38,,Bedenac,Bedenac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17039,BELLUIRE,17800,BELLUIRE,,45.5301565729,-0.55598529375,39,,Belluire,Belluire,17,Charente-Maritime,75,Nouvelle-Aquitaine
17041,BENON,17170,BENON,,46.1994730581,-0.797193296126,41,,Benon,Benon,17,Charente-Maritime,75,Nouvelle-Aquitaine
17042,BERCLOUX,17770,BERCLOUX,,45.8393523202,-0.459046739442,42,,Bercloux,Bercloux,17,Charente-Maritime,75,Nouvelle-Aquitaine
17043,BERNAY ST MARTIN,17330,BERNAY ST MARTIN,,46.0666811311,-0.625648729603,43,,Bernay-Saint-Martin,Bernay-Saint-Martin,17,Charente-Maritime,75,Nouvelle-Aquitaine
17043,BERNAY ST MARTIN,17330,BERNAY ST MARTIN,ST MARTIN DE LA COUDRE,46.0666811311,-0.625648729603,43,,Bernay-Saint-Martin,Bernay-Saint-Martin,17,Charente-Maritime,75,Nouvelle-Aquitaine
17044,BERNEUIL,17460,BERNEUIL,,45.6570139489,-0.598024601666,44,,Berneuil,Berneuil,17,Charente-Maritime,75,Nouvelle-Aquitaine
17045,BEURLAY,17250,BEURLAY,,45.861283729,-0.845151509892,45,,Beurlay,Beurlay,17,Charente-Maritime,75,Nouvelle-Aquitaine
17046,BIGNAY,17400,BIGNAY,,45.9212951544,-0.601162350534,46,,Bignay,Bignay,17,Charente-Maritime,75,Nouvelle-Aquitaine
17047,BIRON,17800,BIRON,,45.5688029671,-0.483837894622,47,,Biron,Biron,17,Charente-Maritime,75,Nouvelle-Aquitaine
17048,BLANZAC LES MATHA,17160,BLANZAC LES MATHA,,45.8719666521,-0.354451050762,48,,Blanzac-lès-Matha,Blanzac-lès-Matha,17,Charente-Maritime,75,Nouvelle-Aquitaine
17049,BLANZAY SUR BOUTONNE,17470,BLANZAY SUR BOUTONNE,,46.0542310173,-0.42559316503,49,,Blanzay-sur-Boutonne,Blanzay-sur-Boutonne,17,Charente-Maritime,75,Nouvelle-Aquitaine
17050,BOIS,17240,BOIS,,45.4820911995,-0.6152588969,50,,Bois,Bois,17,Charente-Maritime,75,Nouvelle-Aquitaine
17051,LE BOIS PLAGE EN RE,17580,LE BOIS PLAGE EN RE,,46.1825283655,-1.37625908104,51,Le,Bois-Plage-en-Ré,Le Bois-Plage-en-Ré,17,Charente-Maritime,75,Nouvelle-Aquitaine
17052,BOISREDON,17150,BOISREDON,,45.3174383056,-0.543849481314,52,,Boisredon,Boisredon,17,Charente-Maritime,75,Nouvelle-Aquitaine
17053,BORDS,17430,BORDS,,45.8953761527,-0.785050392193,53,,Bords,Bords,17,Charente-Maritime,75,Nouvelle-Aquitaine
17054,BORESSE ET MARTRON,17270,BORESSE ET MARTRON,,45.2732901399,-0.126873222783,54,,Boresse-et-Martron,Boresse-et-Martron,17,Charente-Maritime,75,Nouvelle-Aquitaine
17055,BOSCAMNANT,17360,BOSCAMNANT,,45.1918411913,-0.0719487146419,55,,Boscamnant,Boscamnant,17,Charente-Maritime,75,Nouvelle-Aquitaine
17056,BOUGNEAU,17800,BOUGNEAU,,45.600075653,-0.505955746131,56,,Bougneau,Bougneau,17,Charente-Maritime,75,Nouvelle-Aquitaine
17057,BOUHET,17540,BOUHET,,46.1629168745,-0.85833337983,57,,Bouhet,Bouhet,17,Charente-Maritime,75,Nouvelle-Aquitaine
17058,BOURCEFRANC LE CHAPUS,17560,BOURCEFRANC LE CHAPUS,,45.8473362816,-1.13429637237,58,,Bourcefranc-le-Chapus,Bourcefranc-le-Chapus,17,Charente-Maritime,75,Nouvelle-Aquitaine
17059,BOURGNEUF,17220,BOURGNEUF,,46.1681538525,-1.02181156322,59,,Bourgneuf,Bourgneuf,17,Charente-Maritime,75,Nouvelle-Aquitaine
17060,BOUTENAC TOUVENT,17120,BOUTENAC TOUVENT,,45.4989856627,-0.760479056948,60,,Boutenac-Touvent,Boutenac-Touvent,17,Charente-Maritime,75,Nouvelle-Aquitaine
17061,BRAN,17210,BRAN,,45.3499135162,-0.261698224631,61,,Bran,Bran,17,Charente-Maritime,75,Nouvelle-Aquitaine
17062,BRESDON,17490,BRESDON,,45.8650109231,-0.145421933634,62,,Bresdon,Bresdon,17,Charente-Maritime,75,Nouvelle-Aquitaine
17063,BREUIL LA REORTE,17700,BREUIL LA REORTE,,46.0634336642,-0.695728258066,63,,Breuil-la-Réorte,Breuil-la-Réorte,17,Charente-Maritime,75,Nouvelle-Aquitaine
17064,BREUILLET,17920,BREUILLET,,45.6939630579,-1.05445584311,64,,Breuillet,Breuillet,17,Charente-Maritime,75,Nouvelle-Aquitaine
17065,BREUIL MAGNE,17870,BREUIL MAGNE,,46.0002799003,-0.966249355235,65,,Breuil-Magné,Breuil-Magné,17,Charente-Maritime,75,Nouvelle-Aquitaine
17066,BRIE SOUS ARCHIAC,17520,BRIE SOUS ARCHIAC,,45.4750262399,-0.300808640902,66,,Brie-sous-Archiac,Brie-sous-Archiac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17067,BRIE SOUS MATHA,17160,BRIE SOUS MATHA,,45.8171155217,-0.253480989934,67,,Brie-sous-Matha,Brie-sous-Matha,17,Charente-Maritime,75,Nouvelle-Aquitaine
17068,BRIE SOUS MORTAGNE,17120,BRIE SOUS MORTAGNE,,45.5025646841,-0.737666203174,68,,Brie-sous-Mortagne,Brie-sous-Mortagne,17,Charente-Maritime,75,Nouvelle-Aquitaine
17069,BRIVES SUR CHARENTE,17800,BRIVES SUR CHARENTE,,45.6665031177,-0.455953367996,69,,Brives-sur-Charente,Brives-sur-Charente,17,Charente-Maritime,75,Nouvelle-Aquitaine
17070,BRIZAMBOURG,17770,BRIZAMBOURG,,45.8065841077,-0.476018876791,70,,Brizambourg,Brizambourg,17,Charente-Maritime,75,Nouvelle-Aquitaine
17071,LA BROUSSE,17160,LA BROUSSE,,45.9023725634,-0.365190879008,71,La,Brousse,La Brousse,17,Charente-Maritime,75,Nouvelle-Aquitaine
17072,BURIE,17770,BURIE,,45.777851301,-0.428355211255,72,,Burie,Burie,17,Charente-Maritime,75,Nouvelle-Aquitaine
17073,BUSSAC SUR CHARENTE,17100,BUSSAC SUR CHARENTE,,45.7919880619,-0.624437131463,73,,Bussac-sur-Charente,Bussac-sur-Charente,17,Charente-Maritime,75,Nouvelle-Aquitaine
17074,BUSSAC FORET,17210,BUSSAC FORET,,45.2021566078,-0.378592509253,74,,Bussac-Forêt,Bussac-Forêt,17,Charente-Maritime,75,Nouvelle-Aquitaine
17075,CABARIOT,17430,CABARIOT,,45.9278243256,-0.849803658691,75,,Cabariot,Cabariot,17,Charente-Maritime,75,Nouvelle-Aquitaine
17076,CELLES,17520,CELLES,,45.6100158658,-0.386567366555,76,,Celles,Celles,17,Charente-Maritime,75,Nouvelle-Aquitaine
17077,CERCOUX,17270,CERCOUX,,45.1273503043,-0.207698631448,77,,Cercoux,Cercoux,17,Charente-Maritime,75,Nouvelle-Aquitaine
17078,CHADENAC,17800,CHADENAC,,45.5445094942,-0.450256839324,78,,Chadenac,Chadenac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17079,CHAILLEVETTE,17890,CHAILLEVETTE,,45.7303444149,-1.06814190261,79,,Chaillevette,Chaillevette,17,Charente-Maritime,75,Nouvelle-Aquitaine
17080,CHAMBON,17290,CHAMBON,,46.1091600914,-0.845524833213,80,,Chambon,Chambon,17,Charente-Maritime,75,Nouvelle-Aquitaine
17081,CHAMOUILLAC,17130,CHAMOUILLAC,,45.3058629174,-0.465409167934,81,,Chamouillac,Chamouillac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17082,CHAMPAGNAC,17500,CHAMPAGNAC,,45.4287046076,-0.377244103646,82,,Champagnac,Champagnac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17083,CHAMPAGNE,17620,CHAMPAGNE,,45.8298794044,-0.91672258016,83,,Champagne,Champagne,17,Charente-Maritime,75,Nouvelle-Aquitaine
17084,CHAMPAGNOLLES,17240,CHAMPAGNOLLES,,45.5097912928,-0.647721858096,84,,Champagnolles,Champagnolles,17,Charente-Maritime,75,Nouvelle-Aquitaine
17085,CHAMPDOLENT,17430,CHAMPDOLENT,,45.9141109921,-0.777335359216,85,,Champdolent,Champdolent,17,Charente-Maritime,75,Nouvelle-Aquitaine
17086,CHANIERS,17610,CHANIERS,,45.7339723464,-0.551943475215,86,,Chaniers,Chaniers,17,Charente-Maritime,75,Nouvelle-Aquitaine
17087,CHANTEMERLE SUR LA SOIE,17380,CHANTEMERLE SUR LA SOIE,,45.9732176097,-0.644264493345,87,,Chantemerle-sur-la-Soie,Chantemerle-sur-la-Soie,17,Charente-Maritime,75,Nouvelle-Aquitaine
17089,LA CHAPELLE DES POTS,17100,LA CHAPELLE DES POTS,,45.7644455528,-0.534261419507,89,La,Chapelle-des-Pots,La Chapelle-des-Pots,17,Charente-Maritime,75,Nouvelle-Aquitaine
17091,CHARRON,17230,CHARRON,,46.2936903771,-1.08092039856,91,,Charron,Charron,17,Charente-Maritime,75,Nouvelle-Aquitaine
17091,CHARRON,17230,CHARRON,BOURG CHAPON,46.2936903771,-1.08092039856,91,,Charron,Charron,17,Charente-Maritime,75,Nouvelle-Aquitaine
17092,CHARTUZAC,17130,CHARTUZAC,,45.3371917387,-0.416732007642,92,,Chartuzac,Chartuzac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17093,LE CHATEAU D OLERON,17480,LE CHATEAU D OLERON,,45.8813853125,-1.2166234835,93,Le,Château-d'Oléron,Le Château-d'Oléron,17,Charente-Maritime,75,Nouvelle-Aquitaine
17093,LE CHATEAU D OLERON,17480,LE CHATEAU D OLERON,LA CHEVALERIE,45.8813853125,-1.2166234835,93,Le,Château-d'Oléron,Le Château-d'Oléron,17,Charente-Maritime,75,Nouvelle-Aquitaine
17093,LE CHATEAU D OLERON,17480,LE CHATEAU D OLERON,LA GACONNIERE,45.8813853125,-1.2166234835,93,Le,Château-d'Oléron,Le Château-d'Oléron,17,Charente-Maritime,75,Nouvelle-Aquitaine
17093,LE CHATEAU D OLERON,17480,LE CHATEAU D OLERON,ORS,45.8813853125,-1.2166234835,93,Le,Château-d'Oléron,Le Château-d'Oléron,17,Charente-Maritime,75,Nouvelle-Aquitaine
17094,CHATELAILLON PLAGE,17340,CHATELAILLON PLAGE,,46.0727342481,-1.08262101984,94,,Châtelaillon-Plage,Châtelaillon-Plage,17,Charente-Maritime,75,Nouvelle-Aquitaine
17095,CHATENET,17210,CHATENET,,45.2987522613,-0.291493606073,95,,Chatenet,Chatenet,17,Charente-Maritime,75,Nouvelle-Aquitaine
17096,CHAUNAC,17130,CHAUNAC,,45.3568170486,-0.359382651655,96,,Chaunac,Chaunac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17097,LE CHAY,17600,LE CHAY,,45.6408933917,-0.902258961295,97,Le,Chay,Le Chay,17,Charente-Maritime,75,Nouvelle-Aquitaine
17098,CHENAC ST SEURIN D UZET,17120,CHENAC ST SEURIN D UZET,,45.5140296568,-0.812247078106,98,,Chenac-Saint-Seurin-d'Uzet,Chenac-Saint-Seurin-d'Uzet,17,Charente-Maritime,75,Nouvelle-Aquitaine
17099,CHEPNIERS,17210,CHEPNIERS,,45.2531986872,-0.326507193163,99,,Chepniers,Chepniers,17,Charente-Maritime,75,Nouvelle-Aquitaine
17100,CHERAC,17610,CHERAC,,45.7130812452,-0.446891176255,100,,Chérac,Chérac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17101,CHERBONNIERES,17470,CHERBONNIERES,,45.9677756754,-0.339891615561,101,,Cherbonnières,Cherbonnières,17,Charente-Maritime,75,Nouvelle-Aquitaine
17102,CHERMIGNAC,17460,CHERMIGNAC,,45.6928090808,-0.677270965738,102,,Chermignac,Chermignac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17103,CHERVETTES,17380,CHERVETTES,,46.0451644754,-0.701084855026,103,,Chervettes,Chervettes,17,Charente-Maritime,75,Nouvelle-Aquitaine
17104,CHEVANCEAUX,17210,CHEVANCEAUX,,45.2991036157,-0.212745427896,104,,Chevanceaux,Chevanceaux,17,Charente-Maritime,75,Nouvelle-Aquitaine
17105,CHIVES,17510,CHIVES,,45.9508835378,-0.121918052323,105,,Chives,Chives,17,Charente-Maritime,75,Nouvelle-Aquitaine
17106,CIERZAC,17520,CIERZAC,,45.561206889,-0.313695229366,106,,Cierzac,Cierzac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17107,CIRE D AUNIS,17290,CIRE D AUNIS,,46.0380687499,-0.930603200492,107,,Ciré-d'Aunis,Ciré-d'Aunis,17,Charente-Maritime,75,Nouvelle-Aquitaine
17108,CLAM,17500,CLAM,,45.492260471,-0.444850950523,108,,Clam,Clam,17,Charente-Maritime,75,Nouvelle-Aquitaine
17109,CLAVETTE,17220,CLAVETTE,,46.1423813762,-1.03660142128,109,,Clavette,Clavette,17,Charente-Maritime,75,Nouvelle-Aquitaine
17110,CLERAC,17270,CLERAC,,45.170720294,-0.234166115218,110,,Clérac,Clérac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17111,CLION,17240,CLION,,45.470800102,-0.509938882977,111,,Clion,Clion,17,Charente-Maritime,75,Nouvelle-Aquitaine
17112,LA CLISSE,17600,LA CLISSE,,45.7320429784,-0.75493581331,112,La,Clisse,La Clisse,17,Charente-Maritime,75,Nouvelle-Aquitaine
17113,LA CLOTTE,17360,LA CLOTTE,,45.1155746531,-0.143215362973,113,La,Clotte,La Clotte,17,Charente-Maritime,75,Nouvelle-Aquitaine
17114,COIVERT,17330,COIVERT,,46.0673425876,-0.462617046723,114,,Coivert,Coivert,17,Charente-Maritime,75,Nouvelle-Aquitaine
17115,COLOMBIERS,17460,COLOMBIERS,,45.6427817429,-0.555075273986,115,,Colombiers,Colombiers,17,Charente-Maritime,75,Nouvelle-Aquitaine
17116,CONSAC,17150,CONSAC,,45.4182495786,-0.588917528415,116,,Consac,Consac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17117,CONTRE,17470,CONTRE,,46.0122478554,-0.261363378689,117,,Contré,Contré,17,Charente-Maritime,75,Nouvelle-Aquitaine
17118,CORIGNAC,17130,CORIGNAC,,45.2448604983,-0.39280322021,118,,Corignac,Corignac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17119,CORME ECLUSE,17600,CORME ECLUSE,,45.6322589932,-0.864025818728,119,,Corme-Écluse,Corme-Écluse,17,Charente-Maritime,75,Nouvelle-Aquitaine
17120,CORME ROYAL,17600,CORME ROYAL,,45.7457848259,-0.809484393759,120,,Corme-Royal,Corme-Royal,17,Charente-Maritime,75,Nouvelle-Aquitaine
17121,LA COUARDE SUR MER,17670,LA COUARDE SUR MER,,46.2010718838,-1.4366169353,121,La,Couarde-sur-Mer,La Couarde-sur-Mer,17,Charente-Maritime,75,Nouvelle-Aquitaine
17122,COULONGES,17800,COULONGES,,45.6088801122,-0.428936735857,122,,Coulonges,Coulonges,17,Charente-Maritime,75,Nouvelle-Aquitaine
17124,COURANT,17330,COURANT,,46.0375312829,-0.587834905531,124,,Courant,Courant,17,Charente-Maritime,75,Nouvelle-Aquitaine
17125,COURCELLES,17400,COURCELLES,,45.9586702795,-0.481078558771,125,,Courcelles,Courcelles,17,Charente-Maritime,75,Nouvelle-Aquitaine
17126,COURCERAC,17160,COURCERAC,,45.8429574627,-0.366358535503,126,,Courcerac,Courcerac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17127,COURCON,17170,COURCON,,46.2550691794,-0.795122277526,127,,Courçon,Courçon,17,Charente-Maritime,75,Nouvelle-Aquitaine
17128,COURCOURY,17100,COURCOURY,,45.7030914699,-0.563571541525,128,,Courcoury,Courcoury,17,Charente-Maritime,75,Nouvelle-Aquitaine
17129,COURPIGNAC,17130,COURPIGNAC,,45.3142239112,-0.50021379321,129,,Courpignac,Courpignac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17130,COUX,17130,COUX,,45.3197414345,-0.409244232565,130,,Coux,Coux,17,Charente-Maritime,75,Nouvelle-Aquitaine
17131,COZES,17120,COZES,,45.576167197,-0.823756709657,131,,Cozes,Cozes,17,Charente-Maritime,75,Nouvelle-Aquitaine
17132,CRAMCHABAN,17170,CRAMCHABAN,,46.2148052495,-0.718162593168,132,,Cramchaban,Cramchaban,17,Charente-Maritime,75,Nouvelle-Aquitaine
17133,CRAVANS,17260,CRAVANS,,45.5951968202,-0.714548761582,133,,Cravans,Cravans,17,Charente-Maritime,75,Nouvelle-Aquitaine
17134,CRAZANNES,17350,CRAZANNES,,45.8503954667,-0.705638764922,134,,Crazannes,Crazannes,17,Charente-Maritime,75,Nouvelle-Aquitaine
17135,CRESSE,17160,CRESSE,,45.916026224,-0.212186175866,135,,Cressé,Cressé,17,Charente-Maritime,75,Nouvelle-Aquitaine
17136,CROIX CHAPEAU,17220,CROIX CHAPEAU,,46.1029311754,-0.998866254473,136,,Croix-Chapeau,Croix-Chapeau,17,Charente-Maritime,75,Nouvelle-Aquitaine
17137,LA CROIX COMTESSE,17330,LA CROIX COMTESSE,,46.0824925841,-0.504784352089,137,La,Croix-Comtesse,La Croix-Comtesse,17,Charente-Maritime,75,Nouvelle-Aquitaine
17138,DAMPIERRE SUR BOUTONNE,17470,DAMPIERRE SUR BOUTONNE,,46.0734166242,-0.387793874556,138,,Dampierre-sur-Boutonne,Dampierre-sur-Boutonne,17,Charente-Maritime,75,Nouvelle-Aquitaine
17139,DOEUIL SUR LE MIGNON,17330,DOEUIL SUR LE MIGNON,,46.1238616322,-0.549321763075,139,,Dœuil-sur-le-Mignon,Dœuil-sur-le-Mignon,17,Charente-Maritime,75,Nouvelle-Aquitaine
17140,DOLUS D OLERON,17550,DOLUS D OLERON,,45.9096185439,-1.25750953748,140,,Dolus-d'Oléron,Dolus-d'Oléron,17,Charente-Maritime,75,Nouvelle-Aquitaine
17141,DOMPIERRE SUR CHARENTE,17610,DOMPIERRE SUR CHARENTE,,45.7079445507,-0.496852759842,141,,Dompierre-sur-Charente,Dompierre-sur-Charente,17,Charente-Maritime,75,Nouvelle-Aquitaine
17142,DOMPIERRE SUR MER,17139,DOMPIERRE SUR MER,,46.1840946232,-1.06784317719,142,,Dompierre-sur-Mer,Dompierre-sur-Mer,17,Charente-Maritime,75,Nouvelle-Aquitaine
17142,DOMPIERRE SUR MER,17139,DOMPIERRE SUR MER,CHAGNOLET,46.1840946232,-1.06784317719,142,,Dompierre-sur-Mer,Dompierre-sur-Mer,17,Charente-Maritime,75,Nouvelle-Aquitaine
17143,LE DOUHET,17100,LE DOUHET,,45.8077681995,-0.578258177424,143,Le,Douhet,Le Douhet,17,Charente-Maritime,75,Nouvelle-Aquitaine
17145,ECHEBRUNE,17800,ECHEBRUNE,,45.5887183751,-0.452205793733,145,,Échebrune,Échebrune,17,Charente-Maritime,75,Nouvelle-Aquitaine
17146,ECHILLAIS,17620,ECHILLAIS,,45.9024659097,-0.953390493199,146,,Échillais,Échillais,17,Charente-Maritime,75,Nouvelle-Aquitaine
17146,ECHILLAIS,17620,ECHILLAIS,LA RENAISSANCE,45.9024659097,-0.953390493199,146,,Échillais,Échillais,17,Charente-Maritime,75,Nouvelle-Aquitaine
17147,ECOYEUX,17770,ECOYEUX,,45.8146188936,-0.51932413355,147,,Écoyeux,Écoyeux,17,Charente-Maritime,75,Nouvelle-Aquitaine
17148,ECURAT,17810,ECURAT,,45.7939911304,-0.694780604398,148,,Écurat,Écurat,17,Charente-Maritime,75,Nouvelle-Aquitaine
17149,LES EDUTS,17510,LES EDUTS,,45.9975741287,-0.206497538135,149,Les,Éduts,Les Éduts,17,Charente-Maritime,75,Nouvelle-Aquitaine
17150,LES EGLISES D ARGENTEUIL,17400,LES EGLISES D ARGENTEUIL,,45.978753015,-0.424376314206,150,Les,Églises-d'Argenteuil,Les Églises-d'Argenteuil,17,Charente-Maritime,75,Nouvelle-Aquitaine
17151,L EGUILLE,17600,L EGUILLE,,45.7019009166,-0.979905969423,151,L',Éguille,L'Éguille,17,Charente-Maritime,75,Nouvelle-Aquitaine
17152,EPARGNES,17120,EPARGNES,,45.5457212602,-0.795992154585,152,,Épargnes,Épargnes,17,Charente-Maritime,75,Nouvelle-Aquitaine
17153,ESNANDES,17137,ESNANDES,,46.2481463868,-1.11394343673,153,,Esnandes,Esnandes,17,Charente-Maritime,75,Nouvelle-Aquitaine
17154,LES ESSARDS,17250,LES ESSARDS,,45.7949558042,-0.770156195099,154,Les,Essards,Les Essards,17,Charente-Maritime,75,Nouvelle-Aquitaine
17155,ETAULES,17750,ETAULES,,45.7225172256,-1.10053747379,155,,Étaules,Étaules,17,Charente-Maritime,75,Nouvelle-Aquitaine
17156,EXPIREMONT,17130,EXPIREMONT,,45.3325890247,-0.374711458705,156,,Expiremont,Expiremont,17,Charente-Maritime,75,Nouvelle-Aquitaine
17157,FENIOUX,17350,FENIOUX,,45.891354381,-0.588645378204,157,,Fenioux,Fenioux,17,Charente-Maritime,75,Nouvelle-Aquitaine
17158,FERRIERES,17170,FERRIERES,,46.2293761837,-0.854091719945,158,,Ferrières,Ferrières,17,Charente-Maritime,75,Nouvelle-Aquitaine
17159,FLEAC SUR SEUGNE,17800,FLEAC SUR SEUGNE,,45.5312541741,-0.525777430829,159,,Fléac-sur-Seugne,Fléac-sur-Seugne,17,Charente-Maritime,75,Nouvelle-Aquitaine
17160,FLOIRAC,17120,FLOIRAC,,45.4763669964,-0.747580624551,160,,Floirac,Floirac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17161,LA FLOTTE,17630,LA FLOTTE,,46.1793660462,-1.32163012232,161,La,Flotte,La Flotte,17,Charente-Maritime,75,Nouvelle-Aquitaine
17162,FONTAINE CHALENDRAY,17510,FONTAINE CHALENDRAY,,45.9472253367,-0.176439795727,162,,Fontaine-Chalendray,Fontaine-Chalendray,17,Charente-Maritime,75,Nouvelle-Aquitaine
17163,FONTAINES D OZILLAC,17500,FONTAINES D OZILLAC,,45.3786053942,-0.382932208818,163,,Fontaines-d'Ozillac,Fontaines-d'Ozillac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17164,FONTCOUVERTE,17100,FONTCOUVERTE,,45.767715256,-0.590510817969,164,,Fontcouverte,Fontcouverte,17,Charente-Maritime,75,Nouvelle-Aquitaine
17165,FONTENET,17400,FONTENET,,45.9056728003,-0.463265177697,165,,Fontenet,Fontenet,17,Charente-Maritime,75,Nouvelle-Aquitaine
17166,FORGES,17290,FORGES,,46.1090753059,-0.892101839793,166,,Forges,Forges,17,Charente-Maritime,75,Nouvelle-Aquitaine
17166,FORGES,17290,FORGES,PUYDROUARD,46.1090753059,-0.892101839793,166,,Forges,Forges,17,Charente-Maritime,75,Nouvelle-Aquitaine
17167,LE FOUILLOUX,17270,LE FOUILLOUX,,45.2054910873,-0.125754357032,167,Le,Fouilloux,Le Fouilloux,17,Charente-Maritime,75,Nouvelle-Aquitaine
17168,FOURAS,17450,FOURAS,,45.9830753407,-1.07700521801,168,,Fouras,Fouras,17,Charente-Maritime,75,Nouvelle-Aquitaine
17169,LA FREDIERE,17770,LA FREDIERE,,45.861546377,-0.586815136016,169,La,Frédière,La Frédière,17,Charente-Maritime,75,Nouvelle-Aquitaine
17171,GEAY,17250,GEAY,,45.8619856256,-0.756393438538,171,,Geay,Geay,17,Charente-Maritime,75,Nouvelle-Aquitaine
17172,GEMOZAC,17260,GEMOZAC,,45.5615748972,-0.684082033771,172,,Gémozac,Gémozac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17173,LA GENETOUZE,17360,LA GENETOUZE,,45.2212946692,-0.0522747983265,173,La,Genétouze,La Genétouze,17,Charente-Maritime,75,Nouvelle-Aquitaine
17174,GENOUILLE,17430,GENOUILLE,,46.0190321633,-0.782334091478,174,,Genouillé,Genouillé,17,Charente-Maritime,75,Nouvelle-Aquitaine
17175,GERMIGNAC,17520,GERMIGNAC,,45.5608582363,-0.341121661221,175,,Germignac,Germignac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17176,GIBOURNE,17160,GIBOURNE,,45.9287373881,-0.312106629697,176,,Gibourne,Gibourne,17,Charente-Maritime,75,Nouvelle-Aquitaine
17177,LE GICQ,17160,LE GICQ,,45.9355569039,-0.250780805223,177,Le,Gicq,Le Gicq,17,Charente-Maritime,75,Nouvelle-Aquitaine
17178,GIVREZAC,17260,GIVREZAC,,45.5409958957,-0.632072583649,178,,Givrezac,Givrezac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17179,LES GONDS,17100,LES GONDS,,45.7062633213,-0.60684737481,179,Les,Gonds,Les Gonds,17,Charente-Maritime,75,Nouvelle-Aquitaine
17180,GOURVILLETTE,17490,GOURVILLETTE,,45.8892084332,-0.222544638177,180,,Gourvillette,Gourvillette,17,Charente-Maritime,75,Nouvelle-Aquitaine
17181,GRANDJEAN,17350,GRANDJEAN,,45.8753248293,-0.602567687993,181,,Grandjean,Grandjean,17,Charente-Maritime,75,Nouvelle-Aquitaine
17182,LA GREVE SUR MIGNON,17170,LA GREVE SUR MIGNON,,46.2475550874,-0.760121003243,182,La,Grève-sur-Mignon,La Grève-sur-Mignon,17,Charente-Maritime,75,Nouvelle-Aquitaine
17183,GREZAC,17120,GREZAC,,45.6032269459,-0.83530051461,183,,Grézac,Grézac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17184,LA GRIPPERIE ST SYMPHORIEN,17620,LA GRIPPERIE ST SYMPHORIEN,,45.7918128326,-0.952372788645,184,La,Gripperie-Saint-Symphorien,La Gripperie-Saint-Symphorien,17,Charente-Maritime,75,Nouvelle-Aquitaine
17185,LE GUA,17600,LE GUA,,45.7264855028,-0.965197924835,185,Le,Gua,Le Gua,17,Charente-Maritime,75,Nouvelle-Aquitaine
17186,LE GUE D ALLERE,17540,LE GUE D ALLERE,,46.1876266957,-0.870885218413,186,Le,Gué-d'Alleré,Le Gué-d'Alleré,17,Charente-Maritime,75,Nouvelle-Aquitaine
17187,GUITINIERES,17500,GUITINIERES,,45.443029378,-0.511210601117,187,,Guitinières,Guitinières,17,Charente-Maritime,75,Nouvelle-Aquitaine
17188,HAIMPS,17160,HAIMPS,,45.8625742975,-0.25542114028,188,,Haimps,Haimps,17,Charente-Maritime,75,Nouvelle-Aquitaine
17189,HIERS BROUAGE,17320,HIERS BROUAGE,,45.8435716434,-1.05203009674,189,,Hiers-Brouage,Hiers-Brouage,17,Charente-Maritime,75,Nouvelle-Aquitaine
17189,HIERS BROUAGE,17320,HIERS BROUAGE,BROUAGE,45.8435716434,-1.05203009674,189,,Hiers-Brouage,Hiers-Brouage,17,Charente-Maritime,75,Nouvelle-Aquitaine
17190,L HOUMEAU,17137,L HOUMEAU,,46.1935718627,-1.18641543002,190,L',Houmeau,L'Houmeau,17,Charente-Maritime,75,Nouvelle-Aquitaine
17191,LA JARD,17460,LA JARD,,45.6594237769,-0.573779997432,191,La,Jard,La Jard,17,Charente-Maritime,75,Nouvelle-Aquitaine
17192,JARNAC CHAMPAGNE,17520,JARNAC CHAMPAGNE,,45.5533573947,-0.405979459811,192,,Jarnac-Champagne,Jarnac-Champagne,17,Charente-Maritime,75,Nouvelle-Aquitaine
17193,LA JARNE,17220,LA JARNE,,46.1214512061,-1.07142455217,193,La,Jarne,La Jarne,17,Charente-Maritime,75,Nouvelle-Aquitaine
17194,LA JARRIE,17220,LA JARRIE,,46.1239232012,-1.01164042157,194,La,Jarrie,La Jarrie,17,Charente-Maritime,75,Nouvelle-Aquitaine
17195,LA JARRIE AUDOUIN,17330,LA JARRIE AUDOUIN,,46.035099239,-0.480660269998,195,La,Jarrie-Audouin,La Jarrie-Audouin,17,Charente-Maritime,75,Nouvelle-Aquitaine
17196,JAZENNES,17260,JAZENNES,,45.5828838035,-0.615889273247,196,,Jazennes,Jazennes,17,Charente-Maritime,75,Nouvelle-Aquitaine
17197,JONZAC,17500,JONZAC,,45.4419827405,-0.427614839205,197,,Jonzac,Jonzac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17198,JUICQ,17770,JUICQ,,45.8399129453,-0.577046782244,198,,Juicq,Juicq,17,Charente-Maritime,75,Nouvelle-Aquitaine
17199,JUSSAS,17130,JUSSAS,,45.2770542322,-0.356321987805,199,,Jussas,Jussas,17,Charente-Maritime,75,Nouvelle-Aquitaine
17200,LAGORD,17140,LAGORD,,46.1865247974,-1.15219925305,200,,Lagord,Lagord,17,Charente-Maritime,75,Nouvelle-Aquitaine
17201,LA LAIGNE,17170,LA LAIGNE,,46.2156875435,-0.755573639604,201,La,Laigne,La Laigne,17,Charente-Maritime,75,Nouvelle-Aquitaine
17202,LANDES,17380,LANDES,,45.9948050533,-0.600345343347,202,,Landes,Landes,17,Charente-Maritime,75,Nouvelle-Aquitaine
17203,LANDRAIS,17290,LANDRAIS,,46.0695634718,-0.857047499487,203,,Landrais,Landrais,17,Charente-Maritime,75,Nouvelle-Aquitaine
17204,LEOVILLE,17500,LEOVILLE,,45.3778692779,-0.333027025612,204,,Léoville,Léoville,17,Charente-Maritime,75,Nouvelle-Aquitaine
17205,LOIRE LES MARAIS,17870,LOIRE LES MARAIS,,45.9927474473,-0.927468396271,205,,Loire-les-Marais,Loire-les-Marais,17,Charente-Maritime,75,Nouvelle-Aquitaine
17206,LOIRE SUR NIE,17470,LOIRE SUR NIE,,45.9584075914,-0.289572002705,206,,Loiré-sur-Nie,Loiré-sur-Nie,17,Charente-Maritime,75,Nouvelle-Aquitaine
17207,LOIX,17111,LOIX,,46.2206970303,-1.44742224343,207,,Loix,Loix,17,Charente-Maritime,75,Nouvelle-Aquitaine
17208,LONGEVES,17230,LONGEVES,,46.2376082044,-0.974659436539,208,,Longèves,Longèves,17,Charente-Maritime,75,Nouvelle-Aquitaine
17209,LONZAC,17520,LONZAC,,45.5911112245,-0.396064768995,209,,Lonzac,Lonzac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17210,LORIGNAC,17240,LORIGNAC,,45.4601958891,-0.67473411902,210,,Lorignac,Lorignac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17211,LOULAY,17330,LOULAY,,46.0441648862,-0.513759292382,211,,Loulay,Loulay,17,Charente-Maritime,75,Nouvelle-Aquitaine
17212,LOUZIGNAC,17160,LOUZIGNAC,,45.8315187866,-0.228314120891,212,,Louzignac,Louzignac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17213,LOZAY,17330,LOZAY,,46.0435885992,-0.543351399248,213,,Lozay,Lozay,17,Charente-Maritime,75,Nouvelle-Aquitaine
17214,LUCHAT,17600,LUCHAT,,45.7151143734,-0.763297956769,214,,Luchat,Luchat,17,Charente-Maritime,75,Nouvelle-Aquitaine
17215,LUSSAC,17500,LUSSAC,,45.4672777871,-0.473062753484,215,,Lussac,Lussac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17216,LUSSANT,17430,LUSSANT,,45.9428507855,-0.822245809156,216,,Lussant,Lussant,17,Charente-Maritime,75,Nouvelle-Aquitaine
17217,MACQUEVILLE,17490,MACQUEVILLE,,45.7959532794,-0.203637900401,217,,Macqueville,Macqueville,17,Charente-Maritime,75,Nouvelle-Aquitaine
17218,MARANS,17230,MARANS,,46.3120332384,-0.979496238951,218,,Marans,Marans,17,Charente-Maritime,75,Nouvelle-Aquitaine
17219,MARENNES,17320,MARENNES,,45.8221269588,-1.10929995315,219,,Marennes,Marennes,17,Charente-Maritime,75,Nouvelle-Aquitaine
17220,MARIGNAC,17800,MARIGNAC,,45.5195994451,-0.472009798859,220,,Marignac,Marignac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17221,MARSAIS,17700,MARSAIS,,46.1217050535,-0.619473280005,221,,Marsais,Marsais,17,Charente-Maritime,75,Nouvelle-Aquitaine
17221,MARSAIS,17700,MARSAIS,BOISSE,46.1217050535,-0.619473280005,221,,Marsais,Marsais,17,Charente-Maritime,75,Nouvelle-Aquitaine
17222,MARSILLY,17137,MARSILLY,,46.2279815939,-1.13416579432,222,,Marsilly,Marsilly,17,Charente-Maritime,75,Nouvelle-Aquitaine
17223,MASSAC,17490,MASSAC,,45.8631244023,-0.217593308296,223,,Massac,Massac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17224,MATHA,17160,MATHA,,45.8637317103,-0.314996278369,224,,Matha,Matha,17,Charente-Maritime,75,Nouvelle-Aquitaine
17225,LES MATHES,17570,LES MATHES,,45.7059879117,-1.17086670363,225,Les,Mathes,Les Mathes,17,Charente-Maritime,75,Nouvelle-Aquitaine
17226,MAZERAY,17400,MAZERAY,,45.9158199464,-0.559708232455,226,,Mazeray,Mazeray,17,Charente-Maritime,75,Nouvelle-Aquitaine
17227,MAZEROLLES,17800,MAZEROLLES,,45.5589092028,-0.587964814148,227,,Mazerolles,Mazerolles,17,Charente-Maritime,75,Nouvelle-Aquitaine
17228,MEDIS,17600,MEDIS,,45.64232723,-0.957332811747,228,,Médis,Médis,17,Charente-Maritime,75,Nouvelle-Aquitaine
17229,MERIGNAC,17210,MERIGNAC,,45.329823943,-0.300959977347,229,,Mérignac,Mérignac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17230,MESCHERS SUR GIRONDE,17132,MESCHERS SUR GIRONDE,,45.5728672886,-0.94771675691,230,,Meschers-sur-Gironde,Meschers-sur-Gironde,17,Charente-Maritime,75,Nouvelle-Aquitaine
17231,MESSAC,17130,MESSAC,,45.3464492913,-0.313416526888,231,,Messac,Messac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17232,MEURSAC,17120,MEURSAC,,45.6523583243,-0.82013449004,232,,Meursac,Meursac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17233,MEUX,17500,MEUX,,45.44359095,-0.355376176711,233,,Meux,Meux,17,Charente-Maritime,75,Nouvelle-Aquitaine
17234,MIGRE,17330,MIGRE,,46.0822034021,-0.558494277058,234,,Migré,Migré,17,Charente-Maritime,75,Nouvelle-Aquitaine
17235,MIGRON,17770,MIGRON,,45.8057541534,-0.397192651315,235,,Migron,Migron,17,Charente-Maritime,75,Nouvelle-Aquitaine
17236,MIRAMBEAU,17150,MIRAMBEAU,,45.364556119,-0.565410361333,236,,Mirambeau,Mirambeau,17,Charente-Maritime,75,Nouvelle-Aquitaine
17236,MIRAMBEAU,17150,MIRAMBEAU,PETIT NIORT,45.364556119,-0.565410361333,236,,Mirambeau,Mirambeau,17,Charente-Maritime,75,Nouvelle-Aquitaine
17237,MOEZE,17780,MOEZE,,45.8887503165,-1.05150894917,237,,Moëze,Moëze,17,Charente-Maritime,75,Nouvelle-Aquitaine
17239,MONS,17160,MONS,,45.8060909657,-0.337202264129,239,,Mons,Mons,17,Charente-Maritime,75,Nouvelle-Aquitaine
17240,MONTENDRE,17130,MONTENDRE,,45.2880726146,-0.387479585943,240,,Montendre,Montendre,17,Charente-Maritime,75,Nouvelle-Aquitaine
17240,MONTENDRE,17130,MONTENDRE,CHARDES,45.2880726146,-0.387479585943,240,,Montendre,Montendre,17,Charente-Maritime,75,Nouvelle-Aquitaine
17240,MONTENDRE,17130,MONTENDRE,VALLET,45.2880726146,-0.387479585943,240,,Montendre,Montendre,17,Charente-Maritime,75,Nouvelle-Aquitaine
17241,MONTGUYON,17270,MONTGUYON,,45.2102562362,-0.16987214667,241,,Montguyon,Montguyon,17,Charente-Maritime,75,Nouvelle-Aquitaine
17242,MONTILS,17800,MONTILS,,45.6577145022,-0.511550722755,242,,Montils,Montils,17,Charente-Maritime,75,Nouvelle-Aquitaine
17243,MONTLIEU LA GARDE,17210,MONTLIEU LA GARDE,,45.2335398359,-0.270683431895,243,,Montlieu-la-Garde,Montlieu-la-Garde,17,Charente-Maritime,75,Nouvelle-Aquitaine
17244,MONTPELLIER DE MEDILLAN,17260,MONTPELLIER DE MEDILLAN,,45.633394289,-0.754060385506,244,,Montpellier-de-Médillan,Montpellier-de-Médillan,17,Charente-Maritime,75,Nouvelle-Aquitaine
17245,MONTROY,17220,MONTROY,,46.1551359156,-1.01723607292,245,,Montroy,Montroy,17,Charente-Maritime,75,Nouvelle-Aquitaine
17246,MORAGNE,17430,MORAGNE,,45.9768256622,-0.797162979537,246,,Moragne,Moragne,17,Charente-Maritime,75,Nouvelle-Aquitaine
17247,MORNAC SUR SEUDRE,17113,MORNAC SUR SEUDRE,,45.7079105893,-1.02132531485,247,,Mornac-sur-Seudre,Mornac-sur-Seudre,17,Charente-Maritime,75,Nouvelle-Aquitaine
17248,MORTAGNE SUR GIRONDE,17120,MORTAGNE SUR GIRONDE,,45.4982547943,-0.777581892089,248,,Mortagne-sur-Gironde,Mortagne-sur-Gironde,17,Charente-Maritime,75,Nouvelle-Aquitaine
17249,MORTIERS,17500,MORTIERS,,45.3992961436,-0.322350930618,249,,Mortiers,Mortiers,17,Charente-Maritime,75,Nouvelle-Aquitaine
17250,MOSNAC,17240,MOSNAC,,45.5027021197,-0.541750931671,250,,Mosnac,Mosnac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17252,LE MUNG,17350,LE MUNG,,45.8721844676,-0.714571429678,252,Le,Mung,Le Mung,17,Charente-Maritime,75,Nouvelle-Aquitaine
17253,MURON,17430,MURON,,46.0112293883,-0.863875531259,253,,Muron,Muron,17,Charente-Maritime,75,Nouvelle-Aquitaine
17254,NACHAMPS,17380,NACHAMPS,,46.0220340085,-0.624302957376,254,,Nachamps,Nachamps,17,Charente-Maritime,75,Nouvelle-Aquitaine
17255,NANCRAS,17600,NANCRAS,,45.7474799691,-0.875438147918,255,,Nancras,Nancras,17,Charente-Maritime,75,Nouvelle-Aquitaine
17256,NANTILLE,17770,NANTILLE,,45.8614440922,-0.48338081833,256,,Nantillé,Nantillé,17,Charente-Maritime,75,Nouvelle-Aquitaine
17257,NERE,17510,NERE,,45.9770392382,-0.242404369884,257,,Néré,Néré,17,Charente-Maritime,75,Nouvelle-Aquitaine
17258,NEUILLAC,17520,NEUILLAC,,45.5147014586,-0.399573394694,258,,Neuillac,Neuillac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17259,NEULLES,17500,NEULLES,,45.5096455454,-0.42809234056,259,,Neulles,Neulles,17,Charente-Maritime,75,Nouvelle-Aquitaine
17260,NEUVICQ,17270,NEUVICQ,,45.2543430051,-0.166383193961,260,,Neuvicq,Neuvicq,17,Charente-Maritime,75,Nouvelle-Aquitaine
17261,NEUVICQ LE CHATEAU,17490,NEUVICQ LE CHATEAU,,45.8124380764,-0.160919990251,261,,Neuvicq-le-Château,Neuvicq-le-Château,17,Charente-Maritime,75,Nouvelle-Aquitaine
17262,NIEUL LES SAINTES,17810,NIEUL LES SAINTES,,45.7541162328,-0.745291406801,262,,Nieul-lès-Saintes,Nieul-lès-Saintes,17,Charente-Maritime,75,Nouvelle-Aquitaine
17263,NIEUL LE VIROUIL,17150,NIEUL LE VIROUIL,,45.4135796399,-0.529109086758,263,,Nieul-le-Virouil,Nieul-le-Virouil,17,Charente-Maritime,75,Nouvelle-Aquitaine
17264,NIEUL SUR MER,17137,NIEUL SUR MER,,46.2087181622,-1.16596945116,264,,Nieul-sur-Mer,Nieul-sur-Mer,17,Charente-Maritime,75,Nouvelle-Aquitaine
17264,NIEUL SUR MER,17137,NIEUL SUR MER,LAUZIERES,46.2087181622,-1.16596945116,264,,Nieul-sur-Mer,Nieul-sur-Mer,17,Charente-Maritime,75,Nouvelle-Aquitaine
17265,NIEULLE SUR SEUDRE,17600,NIEULLE SUR SEUDRE,,45.7567012734,-1.02839049392,265,,Nieulle-sur-Seudre,Nieulle-sur-Seudre,17,Charente-Maritime,75,Nouvelle-Aquitaine
17266,LES NOUILLERS,17380,LES NOUILLERS,,45.9351578691,-0.656955735884,266,Les,Nouillers,Les Nouillers,17,Charente-Maritime,75,Nouvelle-Aquitaine
17267,NUAILLE D AUNIS,17540,NUAILLE D AUNIS,,46.2376314923,-0.92874328241,267,,Nuaillé-d'Aunis,Nuaillé-d'Aunis,17,Charente-Maritime,75,Nouvelle-Aquitaine
17268,NUAILLE SUR BOUTONNE,17470,NUAILLE SUR BOUTONNE,,46.0168530348,-0.410350228438,268,,Nuaillé-sur-Boutonne,Nuaillé-sur-Boutonne,17,Charente-Maritime,75,Nouvelle-Aquitaine
17269,ORIGNOLLES,17210,ORIGNOLLES,,45.2210229962,-0.23417146354,269,,Orignolles,Orignolles,17,Charente-Maritime,75,Nouvelle-Aquitaine
17270,OZILLAC,17500,OZILLAC,,45.4075198729,-0.402895489298,270,,Ozillac,Ozillac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17271,PAILLE,17470,PAILLE,,45.9859836336,-0.38918870806,271,,Paillé,Paillé,17,Charente-Maritime,75,Nouvelle-Aquitaine
17272,PERE,17700,PERE,,46.092214467,-0.816002236458,272,,Péré,Péré,17,Charente-Maritime,75,Nouvelle-Aquitaine
17273,PERIGNAC,17800,PERIGNAC,,45.6367129971,-0.459608407894,273,,Pérignac,Pérignac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17274,PERIGNY,17180,PERIGNY,,46.1574551352,-1.08886084864,274,,Périgny,Périgny,17,Charente-Maritime,75,Nouvelle-Aquitaine
17275,PESSINES,17810,PESSINES,,45.7245689026,-0.710843452649,275,,Pessines,Pessines,17,Charente-Maritime,75,Nouvelle-Aquitaine
17276,LE PIN,17210,LE PIN,,45.3165769615,-0.293306922217,276,Le,Pin,Le Pin,17,Charente-Maritime,75,Nouvelle-Aquitaine
17277,ESSOUVERT,17400,ESSOUVERT,LA BENATE,45.9951682951,-0.52001872758,277,,Essouvert,Essouvert,17,Charente-Maritime,75,Nouvelle-Aquitaine
17277,ESSOUVERT,17400,ESSOUVERT,ST DENIS DU PIN,45.9951682951,-0.52001872758,277,,Essouvert,Essouvert,17,Charente-Maritime,75,Nouvelle-Aquitaine
17278,PISANY,17600,PISANY,,45.7047969225,-0.794411464518,278,,Pisany,Pisany,17,Charente-Maritime,75,Nouvelle-Aquitaine
17279,PLASSAC,17240,PLASSAC,,45.4510770094,-0.578865597012,279,,Plassac,Plassac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17280,PLASSAY,17250,PLASSAY,,45.8251164352,-0.728894727031,280,,Plassay,Plassay,17,Charente-Maritime,75,Nouvelle-Aquitaine
17281,POLIGNAC,17210,POLIGNAC,,45.2800784632,-0.305820678246,281,,Polignac,Polignac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17282,POMMIERS MOULONS,17130,POMMIERS MOULONS,,45.3278061357,-0.348630198728,282,,Pommiers-Moulons,Pommiers-Moulons,17,Charente-Maritime,75,Nouvelle-Aquitaine
17282,POMMIERS MOULONS,17130,POMMIERS MOULONS,MOULONS,45.3278061357,-0.348630198728,282,,Pommiers-Moulons,Pommiers-Moulons,17,Charente-Maritime,75,Nouvelle-Aquitaine
17283,PONS,17800,PONS,,45.5770487335,-0.557449237102,283,,Pons,Pons,17,Charente-Maritime,75,Nouvelle-Aquitaine
17284,PONT L ABBE D ARNOULT,17250,PONT L ABBE D ARNOULT,,45.8257228994,-0.865695822843,284,,Pont-l'Abbé-d'Arnoult,Pont-l'Abbé-d'Arnoult,17,Charente-Maritime,75,Nouvelle-Aquitaine
17285,PORT D ENVAUX,17350,PORT D ENVAUX,,45.818051845,-0.672067298755,285,,Port-d'Envaux,Port-d'Envaux,17,Charente-Maritime,75,Nouvelle-Aquitaine
17286,LES PORTES EN RE,17880,LES PORTES EN RE,,46.2420561008,-1.50055338015,286,Les,Portes-en-Ré,Les Portes-en-Ré,17,Charente-Maritime,75,Nouvelle-Aquitaine
17287,POUILLAC,17210,POUILLAC,,45.2715894817,-0.258813906971,287,,Pouillac,Pouillac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17288,POURSAY GARNAUD,17400,POURSAY GARNAUD,,45.949447685,-0.452458051451,288,,Poursay-Garnaud,Poursay-Garnaud,17,Charente-Maritime,75,Nouvelle-Aquitaine
17289,PREGUILLAC,17460,PREGUILLAC,,45.6684430786,-0.624175706556,289,,Préguillac,Préguillac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17290,PRIGNAC,17160,PRIGNAC,,45.8325260204,-0.347339600431,290,,Prignac,Prignac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17291,PUILBOREAU,17138,PUILBOREAU,,46.1853935247,-1.11393605159,291,,Puilboreau,Puilboreau,17,Charente-Maritime,75,Nouvelle-Aquitaine
17292,PUY DU LAC,17380,PUY DU LAC,,45.9446549466,-0.753552985644,292,,Puy-du-Lac,Puy-du-Lac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17293,PUYRAVAULT,17700,PUYRAVAULT,,46.1338386234,-0.817268568399,293,,Puyravault,Puyravault,17,Charente-Maritime,75,Nouvelle-Aquitaine
17294,PUYROLLAND,17380,PUYROLLAND,,46.0332957375,-0.656009266758,294,,Puyrolland,Puyrolland,17,Charente-Maritime,75,Nouvelle-Aquitaine
17295,REAUX SUR TREFLE,17500,REAUX SUR TREFLE,,45.4656327459,-0.380359644815,295,,Réaux sur Trèfle,Réaux sur Trèfle,17,Charente-Maritime,75,Nouvelle-Aquitaine
17295,REAUX SUR TREFLE,17500,REAUX SUR TREFLE,MOINGS,45.4656327459,-0.380359644815,295,,Réaux sur Trèfle,Réaux sur Trèfle,17,Charente-Maritime,75,Nouvelle-Aquitaine
17295,REAUX SUR TREFLE,17500,REAUX SUR TREFLE,ST MAURICE DE TAVERNOLE,45.4656327459,-0.380359644815,295,,Réaux sur Trèfle,Réaux sur Trèfle,17,Charente-Maritime,75,Nouvelle-Aquitaine
17296,RETAUD,17460,RETAUD,,45.6729776474,-0.729506627362,296,,Rétaud,Rétaud,17,Charente-Maritime,75,Nouvelle-Aquitaine
17297,RIVEDOUX PLAGE,17940,RIVEDOUX PLAGE,,46.159061213,-1.28302289715,297,,Rivedoux-Plage,Rivedoux-Plage,17,Charente-Maritime,75,Nouvelle-Aquitaine
17298,RIOUX,17460,RIOUX,,45.6387910641,-0.708723744526,298,,Rioux,Rioux,17,Charente-Maritime,75,Nouvelle-Aquitaine
17299,ROCHEFORT,17300,ROCHEFORT,,45.9432344536,-0.972356445692,299,,Rochefort,Rochefort,17,Charente-Maritime,75,Nouvelle-Aquitaine
17300,LA ROCHELLE,17000,LA ROCHELLE,,46.1620643972,-1.17465702836,300,La,Rochelle,La Rochelle,17,Charente-Maritime,75,Nouvelle-Aquitaine
17300,LA ROCHELLE,17000,LA ROCHELLE,LA PALLICE,46.1620643972,-1.17465702836,300,La,Rochelle,La Rochelle,17,Charente-Maritime,75,Nouvelle-Aquitaine
17300,LA ROCHELLE,17000,LA ROCHELLE,VILLENEUVE LES SALINES,46.1620643972,-1.17465702836,300,La,Rochelle,La Rochelle,17,Charente-Maritime,75,Nouvelle-Aquitaine
17301,ROMAZIERES,17510,ROMAZIERES,,45.9906722092,-0.176069744425,301,,Romazières,Romazières,17,Charente-Maritime,75,Nouvelle-Aquitaine
17302,ROMEGOUX,17250,ROMEGOUX,,45.861176417,-0.80155939841,302,,Romegoux,Romegoux,17,Charente-Maritime,75,Nouvelle-Aquitaine
17303,LA RONDE,17170,LA RONDE,,46.299708575,-0.786738515903,303,La,Ronde,La Ronde,17,Charente-Maritime,75,Nouvelle-Aquitaine
17304,ROUFFIAC,17800,ROUFFIAC,,45.682529052,-0.492871184363,304,,Rouffiac,Rouffiac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17305,ROUFFIGNAC,17130,ROUFFIGNAC,,45.3361304693,-0.452069072521,305,,Rouffignac,Rouffignac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17306,ROYAN,17200,ROYAN,,45.6346574238,-1.01791403375,306,,Royan,Royan,17,Charente-Maritime,75,Nouvelle-Aquitaine
17307,SABLONCEAUX,17600,SABLONCEAUX,,45.7137265647,-0.895448885479,307,,Sablonceaux,Sablonceaux,17,Charente-Maritime,75,Nouvelle-Aquitaine
17308,ST AGNANT,17620,ST AGNANT,,45.8677929392,-0.952947405262,308,,Saint-Agnant,Saint-Agnant,17,Charente-Maritime,75,Nouvelle-Aquitaine
17309,ST AIGULIN,17360,ST AIGULIN,,45.1668978802,-0.0436327584694,309,,Saint-Aigulin,Saint-Aigulin,17,Charente-Maritime,75,Nouvelle-Aquitaine
17310,ST ANDRE DE LIDON,17260,ST ANDRE DE LIDON,,45.5833337815,-0.75733412108,310,,Saint-André-de-Lidon,Saint-André-de-Lidon,17,Charente-Maritime,75,Nouvelle-Aquitaine
17311,ST AUGUSTIN,17570,ST AUGUSTIN,,45.6859547185,-1.10616889515,311,,Saint-Augustin,Saint-Augustin,17,Charente-Maritime,75,Nouvelle-Aquitaine
17312,ST BONNET SUR GIRONDE,17150,ST BONNET SUR GIRONDE,,45.3405019965,-0.644960130156,312,,Saint-Bonnet-sur-Gironde,Saint-Bonnet-sur-Gironde,17,Charente-Maritime,75,Nouvelle-Aquitaine
17313,ST BRIS DES BOIS,17770,ST BRIS DES BOIS,,45.7690651719,-0.472533882609,313,,Saint-Bris-des-Bois,Saint-Bris-des-Bois,17,Charente-Maritime,75,Nouvelle-Aquitaine
17314,ST CESAIRE,17770,ST CESAIRE,,45.7554033226,-0.490821600158,314,,Saint-Césaire,Saint-Césaire,17,Charente-Maritime,75,Nouvelle-Aquitaine
17315,ST CHRISTOPHE,17220,ST CHRISTOPHE,,46.1399234323,-0.948048348977,315,,Saint-Christophe,Saint-Christophe,17,Charente-Maritime,75,Nouvelle-Aquitaine
17316,ST CIERS CHAMPAGNE,17520,ST CIERS CHAMPAGNE,,45.448387711,-0.302733350511,316,,Saint-Ciers-Champagne,Saint-Ciers-Champagne,17,Charente-Maritime,75,Nouvelle-Aquitaine
17317,ST CIERS DU TAILLON,17240,ST CIERS DU TAILLON,,45.4314531462,-0.633076943744,317,,Saint-Ciers-du-Taillon,Saint-Ciers-du-Taillon,17,Charente-Maritime,75,Nouvelle-Aquitaine
17318,ST CLEMENT DES BALEINES,17590,ST CLEMENT DES BALEINES,,46.2363966414,-1.53823691013,318,,Saint-Clément-des-Baleines,Saint-Clément-des-Baleines,17,Charente-Maritime,75,Nouvelle-Aquitaine
17318,ST CLEMENT DES BALEINES,17590,ST CLEMENT DES BALEINES,LE GILLIEUX,46.2363966414,-1.53823691013,318,,Saint-Clément-des-Baleines,Saint-Clément-des-Baleines,17,Charente-Maritime,75,Nouvelle-Aquitaine
17319,STE COLOMBE,17210,STE COLOMBE,,45.281890465,-0.273965618336,319,,Sainte-Colombe,Sainte-Colombe,17,Charente-Maritime,75,Nouvelle-Aquitaine
17320,ST COUTANT LE GRAND,17430,ST COUTANT LE GRAND,,45.9480944751,-0.787759581608,320,,Saint-Coutant-le-Grand,Saint-Coutant-le-Grand,17,Charente-Maritime,75,Nouvelle-Aquitaine
17321,ST CREPIN,17380,ST CREPIN,,45.9970417081,-0.740341875161,321,,Saint-Crépin,Saint-Crépin,17,Charente-Maritime,75,Nouvelle-Aquitaine
17322,ST CYR DU DORET,17170,ST CYR DU DORET,,46.2689963967,-0.827114338607,322,,Saint-Cyr-du-Doret,Saint-Cyr-du-Doret,17,Charente-Maritime,75,Nouvelle-Aquitaine
17323,ST DENIS D OLERON,17650,ST DENIS D OLERON,,46.0234747885,-1.38577964477,323,,Saint-Denis-d'Oléron,Saint-Denis-d'Oléron,17,Charente-Maritime,75,Nouvelle-Aquitaine
17324,ST DIZANT DU BOIS,17150,ST DIZANT DU BOIS,,45.4008726045,-0.570073818509,324,,Saint-Dizant-du-Bois,Saint-Dizant-du-Bois,17,Charente-Maritime,75,Nouvelle-Aquitaine
17325,ST DIZANT DU GUA,17240,ST DIZANT DU GUA,,45.4259413548,-0.710571856304,325,,Saint-Dizant-du-Gua,Saint-Dizant-du-Gua,17,Charente-Maritime,75,Nouvelle-Aquitaine
17326,ST EUGENE,17520,ST EUGENE,,45.498090644,-0.274132847243,326,,Saint-Eugène,Saint-Eugène,17,Charente-Maritime,75,Nouvelle-Aquitaine
17327,ST FELIX,17330,ST FELIX,,46.0937313231,-0.603804881739,327,,Saint-Félix,Saint-Félix,17,Charente-Maritime,75,Nouvelle-Aquitaine
17328,ST FORT SUR GIRONDE,17240,ST FORT SUR GIRONDE,,45.461784663,-0.722254131989,328,,Saint-Fort-sur-Gironde,Saint-Fort-sur-Gironde,17,Charente-Maritime,75,Nouvelle-Aquitaine
17329,ST FROULT,17780,ST FROULT,,45.9134705375,-1.06195684494,329,,Saint-Froult,Saint-Froult,17,Charente-Maritime,75,Nouvelle-Aquitaine
17330,STE GEMME,17250,STE GEMME,,45.7749267656,-0.892730905107,330,,Sainte-Gemme,Sainte-Gemme,17,Charente-Maritime,75,Nouvelle-Aquitaine
17331,ST GENIS DE SAINTONGE,17240,ST GENIS DE SAINTONGE,,45.4802811029,-0.569721710177,331,,Saint-Genis-de-Saintonge,Saint-Genis-de-Saintonge,17,Charente-Maritime,75,Nouvelle-Aquitaine
17332,ST GEORGES ANTIGNAC,17240,ST GEORGES ANTIGNAC,,45.4918057493,-0.482750821829,332,,Saint-Georges-Antignac,Saint-Georges-Antignac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17332,ST GEORGES ANTIGNAC,17240,ST GEORGES ANTIGNAC,ANTIGNAC,45.4918057493,-0.482750821829,332,,Saint-Georges-Antignac,Saint-Georges-Antignac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17333,ST GEORGES DE DIDONNE,17110,ST GEORGES DE DIDONNE,,45.6036278448,-0.980098297165,333,,Saint-Georges-de-Didonne,Saint-Georges-de-Didonne,17,Charente-Maritime,75,Nouvelle-Aquitaine
17334,ST GEORGES DE LONGUEPIERRE,17470,ST GEORGES DE LONGUEPIERRE,,46.0423797576,-0.396872256026,334,,Saint-Georges-de-Longuepierre,Saint-Georges-de-Longuepierre,17,Charente-Maritime,75,Nouvelle-Aquitaine
17335,ST GEORGES DES AGOUTS,17150,ST GEORGES DES AGOUTS,,45.3755482053,-0.650474546219,335,,Saint-Georges-des-Agoûts,Saint-Georges-des-Agoûts,17,Charente-Maritime,75,Nouvelle-Aquitaine
17336,ST GEORGES DES COTEAUX,17810,ST GEORGES DES COTEAUX,,45.7703624625,-0.701151604065,336,,Saint-Georges-des-Coteaux,Saint-Georges-des-Coteaux,17,Charente-Maritime,75,Nouvelle-Aquitaine
17337,ST GEORGES D OLERON,17190,ST GEORGES D OLERON,,45.9762465553,-1.32432969206,337,,Saint-Georges-d'Oléron,Saint-Georges-d'Oléron,17,Charente-Maritime,75,Nouvelle-Aquitaine
17337,ST GEORGES D OLERON,17190,ST GEORGES D OLERON,BOYARDVILLE,45.9762465553,-1.32432969206,337,,Saint-Georges-d'Oléron,Saint-Georges-d'Oléron,17,Charente-Maritime,75,Nouvelle-Aquitaine
17337,ST GEORGES D OLERON,17190,ST GEORGES D OLERON,CHAUCRE,45.9762465553,-1.32432969206,337,,Saint-Georges-d'Oléron,Saint-Georges-d'Oléron,17,Charente-Maritime,75,Nouvelle-Aquitaine
17337,ST GEORGES D OLERON,17190,ST GEORGES D OLERON,CHERAY,45.9762465553,-1.32432969206,337,,Saint-Georges-d'Oléron,Saint-Georges-d'Oléron,17,Charente-Maritime,75,Nouvelle-Aquitaine
17337,ST GEORGES D OLERON,17190,ST GEORGES D OLERON,DOMINO,45.9762465553,-1.32432969206,337,,Saint-Georges-d'Oléron,Saint-Georges-d'Oléron,17,Charente-Maritime,75,Nouvelle-Aquitaine
17337,ST GEORGES D OLERON,17190,ST GEORGES D OLERON,SAUZELLE,45.9762465553,-1.32432969206,337,,Saint-Georges-d'Oléron,Saint-Georges-d'Oléron,17,Charente-Maritime,75,Nouvelle-Aquitaine
17338,ST GEORGES DU BOIS,17700,ST GEORGES DU BOIS,,46.1500553419,-0.739061354783,338,,Saint-Georges-du-Bois,Saint-Georges-du-Bois,17,Charente-Maritime,75,Nouvelle-Aquitaine
17339,ST GERMAIN DE LUSIGNAN,17500,ST GERMAIN DE LUSIGNAN,,45.4642351151,-0.44363159116,339,,Saint-Germain-de-Lusignan,Saint-Germain-de-Lusignan,17,Charente-Maritime,75,Nouvelle-Aquitaine
17340,ST GERMAIN DE MARENCENNES,17700,ST GERMAIN DE MARENCENNES,,46.0675190065,-0.79880232463,340,,Saint-Germain-de-Marencennes,Saint-Germain-de-Marencennes,17,Charente-Maritime,75,Nouvelle-Aquitaine
17341,ST GERMAIN DE VIBRAC,17500,ST GERMAIN DE VIBRAC,,45.4214033132,-0.327825347194,341,,Saint-Germain-de-Vibrac,Saint-Germain-de-Vibrac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17342,ST GERMAIN DU SEUDRE,17240,ST GERMAIN DU SEUDRE,,45.5131586137,-0.685698200166,342,,Saint-Germain-du-Seudre,Saint-Germain-du-Seudre,17,Charente-Maritime,75,Nouvelle-Aquitaine
17343,ST GREGOIRE D ARDENNES,17240,ST GREGOIRE D ARDENNES,,45.5102408927,-0.508841326499,343,,Saint-Grégoire-d'Ardennes,Saint-Grégoire-d'Ardennes,17,Charente-Maritime,75,Nouvelle-Aquitaine
17344,ST HILAIRE DE VILLEFRANCHE,17770,ST HILAIRE DE VILLEFRANCHE,,45.8589827004,-0.536045938535,344,,Saint-Hilaire-de-Villefranche,Saint-Hilaire-de-Villefranche,17,Charente-Maritime,75,Nouvelle-Aquitaine
17345,ST HILAIRE DU BOIS,17500,ST HILAIRE DU BOIS,,45.4287275956,-0.488064971553,345,,Saint-Hilaire-du-Bois,Saint-Hilaire-du-Bois,17,Charente-Maritime,75,Nouvelle-Aquitaine
17346,ST HIPPOLYTE,17430,ST HIPPOLYTE,,45.9198703097,-0.905117939333,346,,Saint-Hippolyte,Saint-Hippolyte,17,Charente-Maritime,75,Nouvelle-Aquitaine
17347,ST JEAN D ANGELY,17400,ST JEAN D ANGELY,,45.9427172637,-0.519834100838,347,,Saint-Jean-d'Angély,Saint-Jean-d'Angély,17,Charente-Maritime,75,Nouvelle-Aquitaine
17348,ST JEAN D ANGLE,17620,ST JEAN D ANGLE,,45.8311345147,-0.972275648087,348,,Saint-Jean-d'Angle,Saint-Jean-d'Angle,17,Charente-Maritime,75,Nouvelle-Aquitaine
17349,ST JEAN DE LIVERSAY,17170,ST JEAN DE LIVERSAY,,46.2797585875,-0.882374660253,349,,Saint-Jean-de-Liversay,Saint-Jean-de-Liversay,17,Charente-Maritime,75,Nouvelle-Aquitaine
17350,ST JULIEN DE L ESCAP,17400,ST JULIEN DE L ESCAP,,45.9286301344,-0.485268545942,350,,Saint-Julien-de-l'Escap,Saint-Julien-de-l'Escap,17,Charente-Maritime,75,Nouvelle-Aquitaine
17351,ST JUST LUZAC,17320,ST JUST LUZAC,,45.7944979756,-1.0542452381,351,,Saint-Just-Luzac,Saint-Just-Luzac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17351,ST JUST LUZAC,17320,ST JUST LUZAC,LUZAC,45.7944979756,-1.0542452381,351,,Saint-Just-Luzac,Saint-Just-Luzac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17352,ST LAURENT DE LA BARRIERE,17380,ST LAURENT DE LA BARRIERE,,46.0319184838,-0.706348356697,352,,Saint-Laurent-de-la-Barrière,Saint-Laurent-de-la-Barrière,17,Charente-Maritime,75,Nouvelle-Aquitaine
17353,ST LAURENT DE LA PREE,17450,ST LAURENT DE LA PREE,,45.9878727507,-1.02745670693,353,,Saint-Laurent-de-la-Prée,Saint-Laurent-de-la-Prée,17,Charente-Maritime,75,Nouvelle-Aquitaine
17354,ST LEGER,17800,ST LEGER,,45.6164955032,-0.569645544567,354,,Saint-Léger,Saint-Léger,17,Charente-Maritime,75,Nouvelle-Aquitaine
17355,STE LHEURINE,17520,STE LHEURINE,,45.5224276512,-0.356868114482,355,,Sainte-Lheurine,Sainte-Lheurine,17,Charente-Maritime,75,Nouvelle-Aquitaine
17356,ST LOUP,17380,ST LOUP,,45.9997627208,-0.647620851494,356,,Saint-Loup,Saint-Loup,17,Charente-Maritime,75,Nouvelle-Aquitaine
17357,ST MAIGRIN,17520,ST MAIGRIN,,45.4152024373,-0.278686157018,357,,Saint-Maigrin,Saint-Maigrin,17,Charente-Maritime,75,Nouvelle-Aquitaine
17358,ST MANDE SUR BREDOIRE,17470,ST MANDE SUR BREDOIRE,,46.0372800682,-0.27822687633,358,,Saint-Mandé-sur-Brédoire,Saint-Mandé-sur-Brédoire,17,Charente-Maritime,75,Nouvelle-Aquitaine
17359,ST MARD,17700,ST MARD,,46.0930729273,-0.695778851,359,,Saint-Mard,Saint-Mard,17,Charente-Maritime,75,Nouvelle-Aquitaine
17360,STE MARIE DE RE,17740,STE MARIE DE RE,,46.1560367496,-1.32154377304,360,,Sainte-Marie-de-Ré,Sainte-Marie-de-Ré,17,Charente-Maritime,75,Nouvelle-Aquitaine
17361,ST MARTIAL,17330,ST MARTIAL,,46.0456994785,-0.456838157243,361,,Saint-Martial,Saint-Martial,17,Charente-Maritime,75,Nouvelle-Aquitaine
17362,ST MARTIAL DE MIRAMBEAU,17150,ST MARTIAL DE MIRAMBEAU,,45.3780654315,-0.594952157057,362,,Saint-Martial-de-Mirambeau,Saint-Martial-de-Mirambeau,17,Charente-Maritime,75,Nouvelle-Aquitaine
17363,ST MARTIAL DE VITATERNE,17500,ST MARTIAL DE VITATERNE,,45.4626102999,-0.430235388703,363,,Saint-Martial-de-Vitaterne,Saint-Martial-de-Vitaterne,17,Charente-Maritime,75,Nouvelle-Aquitaine
17364,ST MARTIAL SUR NE,17520,ST MARTIAL SUR NE,,45.5781186105,-0.370718965842,364,,Saint-Martial-sur-Né,Saint-Martial-sur-Né,17,Charente-Maritime,75,Nouvelle-Aquitaine
17365,ST MARTIN D ARY,17270,ST MARTIN D ARY,,45.2182774762,-0.20120779956,365,,Saint-Martin-d'Ary,Saint-Martin-d'Ary,17,Charente-Maritime,75,Nouvelle-Aquitaine
17366,ST MARTIN DE COUX,17360,ST MARTIN DE COUX,,45.1455143902,-0.11038952138,366,,Saint-Martin-de-Coux,Saint-Martin-de-Coux,17,Charente-Maritime,75,Nouvelle-Aquitaine
17367,ST MARTIN DE JUILLERS,17400,ST MARTIN DE JUILLERS,,45.9389657843,-0.344541979564,367,,Saint-Martin-de-Juillers,Saint-Martin-de-Juillers,17,Charente-Maritime,75,Nouvelle-Aquitaine
17369,ST MARTIN DE RE,17410,ST MARTIN DE RE,,46.1990908569,-1.36683779933,369,,Saint-Martin-de-Ré,Saint-Martin-de-Ré,17,Charente-Maritime,75,Nouvelle-Aquitaine
17372,ST MEDARD,17500,ST MEDARD,,45.4003157593,-0.353646613509,372,,Saint-Médard,Saint-Médard,17,Charente-Maritime,75,Nouvelle-Aquitaine
17373,ST MEDARD D AUNIS,17220,ST MEDARD D AUNIS,,46.1592023268,-0.967137851085,373,,Saint-Médard-d'Aunis,Saint-Médard-d'Aunis,17,Charente-Maritime,75,Nouvelle-Aquitaine
17374,STE MEME,17770,STE MEME,,45.8798297244,-0.456697276737,374,,Sainte-Même,Sainte-Même,17,Charente-Maritime,75,Nouvelle-Aquitaine
17375,ST NAZAIRE SUR CHARENTE,17780,ST NAZAIRE SUR CHARENTE,,45.943056045,-1.0281857286,375,,Saint-Nazaire-sur-Charente,Saint-Nazaire-sur-Charente,17,Charente-Maritime,75,Nouvelle-Aquitaine
17376,ST OUEN D AUNIS,17230,ST OUEN D AUNIS,,46.2295901157,-1.03191615683,376,,Saint-Ouen-d'Aunis,Saint-Ouen-d'Aunis,17,Charente-Maritime,75,Nouvelle-Aquitaine
17377,ST OUEN LA THENE,17490,ST OUEN LA THENE,,45.8643049345,-0.170063984534,377,,Saint-Ouen-la-Thène,Saint-Ouen-la-Thène,17,Charente-Maritime,75,Nouvelle-Aquitaine
17378,ST PALAIS DE NEGRIGNAC,17210,ST PALAIS DE NEGRIGNAC,,45.2745437517,-0.201995089844,378,,Saint-Palais-de-Négrignac,Saint-Palais-de-Négrignac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17379,ST PALAIS DE PHIOLIN,17800,ST PALAIS DE PHIOLIN,,45.5151577125,-0.591464489682,379,,Saint-Palais-de-Phiolin,Saint-Palais-de-Phiolin,17,Charente-Maritime,75,Nouvelle-Aquitaine
17380,ST PALAIS SUR MER,17420,ST PALAIS SUR MER,,45.6579754062,-1.10115118709,380,,Saint-Palais-sur-Mer,Saint-Palais-sur-Mer,17,Charente-Maritime,75,Nouvelle-Aquitaine
17380,ST PALAIS SUR MER,17420,ST PALAIS SUR MER,COURLAY SUR MER,45.6579754062,-1.10115118709,380,,Saint-Palais-sur-Mer,Saint-Palais-sur-Mer,17,Charente-Maritime,75,Nouvelle-Aquitaine
17381,ST PARDOULT,17400,ST PARDOULT,,46.0057367763,-0.455137574227,381,,Saint-Pardoult,Saint-Pardoult,17,Charente-Maritime,75,Nouvelle-Aquitaine
17382,ST PIERRE D AMILLY,17700,ST PIERRE D AMILLY,,46.1753767073,-0.698370030473,382,,Saint-Pierre-d'Amilly,Saint-Pierre-d'Amilly,17,Charente-Maritime,75,Nouvelle-Aquitaine
17383,ST PIERRE DE JUILLERS,17400,ST PIERRE DE JUILLERS,,45.9459752445,-0.384995955573,383,,Saint-Pierre-de-Juillers,Saint-Pierre-de-Juillers,17,Charente-Maritime,75,Nouvelle-Aquitaine
17384,ST PIERRE DE L ISLE,17330,ST PIERRE DE L ISLE,,46.0285452845,-0.444536284605,384,,Saint-Pierre-de-l'Isle,Saint-Pierre-de-l'Isle,17,Charente-Maritime,75,Nouvelle-Aquitaine
17385,ST PIERRE D OLERON,17310,ST PIERRE D OLERON,,45.9396931461,-1.30530935595,385,,Saint-Pierre-d'Oléron,Saint-Pierre-d'Oléron,17,Charente-Maritime,75,Nouvelle-Aquitaine
17385,ST PIERRE D OLERON,17310,ST PIERRE D OLERON,LA COTINIERE,45.9396931461,-1.30530935595,385,,Saint-Pierre-d'Oléron,Saint-Pierre-d'Oléron,17,Charente-Maritime,75,Nouvelle-Aquitaine
17386,ST PIERRE DU PALAIS,17270,ST PIERRE DU PALAIS,,45.1589862484,-0.150996652179,386,,Saint-Pierre-du-Palais,Saint-Pierre-du-Palais,17,Charente-Maritime,75,Nouvelle-Aquitaine
17387,ST PORCHAIRE,17250,ST PORCHAIRE,,45.8230381488,-0.774633081138,387,,Saint-Porchaire,Saint-Porchaire,17,Charente-Maritime,75,Nouvelle-Aquitaine
17388,ST QUANTIN DE RANCANNE,17800,ST QUANTIN DE RANCANNE,,45.5349054123,-0.596758868836,388,,Saint-Quantin-de-Rançanne,Saint-Quantin-de-Rançanne,17,Charente-Maritime,75,Nouvelle-Aquitaine
17389,STE RADEGONDE,17250,STE RADEGONDE,,45.8492187707,-0.872725907585,389,,Sainte-Radegonde,Sainte-Radegonde,17,Charente-Maritime,75,Nouvelle-Aquitaine
17390,STE RAMEE,17240,STE RAMEE,,45.419274916,-0.664112168706,390,,Sainte-Ramée,Sainte-Ramée,17,Charente-Maritime,75,Nouvelle-Aquitaine
17391,ST ROGATIEN,17220,ST ROGATIEN,,46.1454739,-1.06187828787,391,,Saint-Rogatien,Saint-Rogatien,17,Charente-Maritime,75,Nouvelle-Aquitaine
17392,ST ROMAIN SUR GIRONDE,17240,ST ROMAIN SUR GIRONDE,,45.450445089,-0.76304021283,392,,Saint-Romain-sur-Gironde,Saint-Romain-sur-Gironde,17,Charente-Maritime,75,Nouvelle-Aquitaine
17393,ST ROMAIN DE BENET,17600,ST ROMAIN DE BENET,,45.6918302258,-0.85616928455,393,,Saint-Romain-de-Benet,Saint-Romain-de-Benet,17,Charente-Maritime,75,Nouvelle-Aquitaine
17394,ST SATURNIN DU BOIS,17700,ST SATURNIN DU BOIS,,46.1401428747,-0.665133364912,394,,Saint-Saturnin-du-Bois,Saint-Saturnin-du-Bois,17,Charente-Maritime,75,Nouvelle-Aquitaine
17395,ST SAUVANT,17610,ST SAUVANT,,45.7324197214,-0.497484915481,395,,Saint-Sauvant,Saint-Sauvant,17,Charente-Maritime,75,Nouvelle-Aquitaine
17396,ST SAUVEUR D AUNIS,17540,ST SAUVEUR D AUNIS,,46.2102668971,-0.891705417189,396,,Saint-Sauveur-d'Aunis,Saint-Sauveur-d'Aunis,17,Charente-Maritime,75,Nouvelle-Aquitaine
17397,ST SAVINIEN,17350,ST SAVINIEN,,45.8862799418,-0.677457125043,397,,Saint-Savinien,Saint-Savinien,17,Charente-Maritime,75,Nouvelle-Aquitaine
17397,ST SAVINIEN,17350,ST SAVINIEN,AGONNAY,45.8862799418,-0.677457125043,397,,Saint-Savinien,Saint-Savinien,17,Charente-Maritime,75,Nouvelle-Aquitaine
17397,ST SAVINIEN,17350,ST SAVINIEN,COULONGE SUR CHARENTE,45.8862799418,-0.677457125043,397,,Saint-Savinien,Saint-Savinien,17,Charente-Maritime,75,Nouvelle-Aquitaine
17398,ST SEURIN DE PALENNE,17800,ST SEURIN DE PALENNE,,45.6222077247,-0.513310420639,398,,Saint-Seurin-de-Palenne,Saint-Seurin-de-Palenne,17,Charente-Maritime,75,Nouvelle-Aquitaine
17400,ST SEVER DE SAINTONGE,17800,ST SEVER DE SAINTONGE,,45.6909400588,-0.52078042473,400,,Saint-Sever-de-Saintonge,Saint-Sever-de-Saintonge,17,Charente-Maritime,75,Nouvelle-Aquitaine
17401,ST SEVERIN SUR BOUTONNE,17330,ST SEVERIN SUR BOUTONNE,,46.089265514,-0.428552923889,401,,Saint-Séverin-sur-Boutonne,Saint-Séverin-sur-Boutonne,17,Charente-Maritime,75,Nouvelle-Aquitaine
17402,ST SIGISMOND DE CLERMONT,17240,ST SIGISMOND DE CLERMONT,,45.4476679047,-0.546072790481,402,,Saint-Sigismond-de-Clermont,Saint-Sigismond-de-Clermont,17,Charente-Maritime,75,Nouvelle-Aquitaine
17403,ST SIMON DE BORDES,17500,ST SIMON DE BORDES,,45.410900172,-0.452211214542,403,,Saint-Simon-de-Bordes,Saint-Simon-de-Bordes,17,Charente-Maritime,75,Nouvelle-Aquitaine
17404,ST SIMON DE PELLOUAILLE,17260,ST SIMON DE PELLOUAILLE,,45.6096933438,-0.675236020671,404,,Saint-Simon-de-Pellouaille,Saint-Simon-de-Pellouaille,17,Charente-Maritime,75,Nouvelle-Aquitaine
17405,ST SORLIN DE CONAC,17150,ST SORLIN DE CONAC,,45.3565207322,-0.694899173419,405,,Saint-Sorlin-de-Conac,Saint-Sorlin-de-Conac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17406,ST SORNIN,17600,ST SORNIN,,45.770317043,-0.975040646755,406,,Saint-Sornin,Saint-Sornin,17,Charente-Maritime,75,Nouvelle-Aquitaine
17407,STE SOULLE,17220,STE SOULLE,,46.1930484901,-1.01648335022,407,,Sainte-Soulle,Sainte-Soulle,17,Charente-Maritime,75,Nouvelle-Aquitaine
17407,STE SOULLE,17220,STE SOULLE,LES GRANDES RIVIERES,46.1930484901,-1.01648335022,407,,Sainte-Soulle,Sainte-Soulle,17,Charente-Maritime,75,Nouvelle-Aquitaine
17408,ST SULPICE D ARNOULT,17250,ST SULPICE D ARNOULT,,45.8161997833,-0.826983052585,408,,Saint-Sulpice-d'Arnoult,Saint-Sulpice-d'Arnoult,17,Charente-Maritime,75,Nouvelle-Aquitaine
17409,ST SULPICE DE ROYAN,17200,ST SULPICE DE ROYAN,,45.6735457263,-1.00633243777,409,,Saint-Sulpice-de-Royan,Saint-Sulpice-de-Royan,17,Charente-Maritime,75,Nouvelle-Aquitaine
17410,ST THOMAS DE CONAC,17150,ST THOMAS DE CONAC,,45.391750965,-0.699077246125,410,,Saint-Thomas-de-Conac,Saint-Thomas-de-Conac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17411,ST TROJAN LES BAINS,17370,ST TROJAN LES BAINS,,45.8319189499,-1.22713007046,411,,Saint-Trojan-les-Bains,Saint-Trojan-les-Bains,17,Charente-Maritime,75,Nouvelle-Aquitaine
17412,ST VAIZE,17100,ST VAIZE,,45.8161479617,-0.621357223048,412,,Saint-Vaize,Saint-Vaize,17,Charente-Maritime,75,Nouvelle-Aquitaine
17413,ST VIVIEN,17220,ST VIVIEN,,46.0762768567,-1.04882454068,413,,Saint-Vivien,Saint-Vivien,17,Charente-Maritime,75,Nouvelle-Aquitaine
17414,ST XANDRE,17138,ST XANDRE,,46.2127706642,-1.09160546158,414,,Saint-Xandre,Saint-Xandre,17,Charente-Maritime,75,Nouvelle-Aquitaine
17415,SAINTES,17100,SAINTES,,45.7422300162,-0.649623035643,415,,Saintes,Saintes,17,Charente-Maritime,75,Nouvelle-Aquitaine
17416,SALEIGNES,17510,SALEIGNES,,46.0171498466,-0.185321832625,416,,Saleignes,Saleignes,17,Charente-Maritime,75,Nouvelle-Aquitaine
17417,SALIGNAC DE MIRAMBEAU,17130,SALIGNAC DE MIRAMBEAU,,45.3507156869,-0.481731376286,417,,Salignac-de-Mirambeau,Salignac-de-Mirambeau,17,Charente-Maritime,75,Nouvelle-Aquitaine
17418,SALIGNAC SUR CHARENTE,17800,SALIGNAC SUR CHARENTE,,45.6692835871,-0.415946816258,418,,Salignac-sur-Charente,Salignac-sur-Charente,17,Charente-Maritime,75,Nouvelle-Aquitaine
17420,SALLES SUR MER,17220,SALLES SUR MER,,46.1025147874,-1.05196380014,420,,Salles-sur-Mer,Salles-sur-Mer,17,Charente-Maritime,75,Nouvelle-Aquitaine
17421,SAUJON,17600,SAUJON,,45.6754417186,-0.936728980856,421,,Saujon,Saujon,17,Charente-Maritime,75,Nouvelle-Aquitaine
17422,SEIGNE,17510,SEIGNE,,45.9513360986,-0.213550216959,422,,Seigné,Seigné,17,Charente-Maritime,75,Nouvelle-Aquitaine
17423,SEMILLAC,17150,SEMILLAC,,45.4004704154,-0.591603220864,423,,Semillac,Semillac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17424,SEMOUSSAC,17150,SEMOUSSAC,,45.3899137022,-0.624747123124,424,,Semoussac,Semoussac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17425,SEMUSSAC,17120,SEMUSSAC,,45.5992753658,-0.915156967296,425,,Semussac,Semussac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17426,LE SEURE,17770,LE SEURE,,45.792713341,-0.355583104704,426,Le,Seure,Le Seure,17,Charente-Maritime,75,Nouvelle-Aquitaine
17427,SIECQ,17490,SIECQ,,45.839849714,-0.190558367177,427,,Siecq,Siecq,17,Charente-Maritime,75,Nouvelle-Aquitaine
17428,SONNAC,17160,SONNAC,,45.8306131038,-0.282090626459,428,,Sonnac,Sonnac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17429,SOUBISE,17780,SOUBISE,,45.9097262725,-1.00226108589,429,,Soubise,Soubise,17,Charente-Maritime,75,Nouvelle-Aquitaine
17430,SOUBRAN,17150,SOUBRAN,,45.3542631306,-0.519282663835,430,,Soubran,Soubran,17,Charente-Maritime,75,Nouvelle-Aquitaine
17431,SOULIGNONNE,17250,SOULIGNONNE,,45.7800875895,-0.792759962295,431,,Soulignonne,Soulignonne,17,Charente-Maritime,75,Nouvelle-Aquitaine
17432,SOUMERAS,17130,SOUMERAS,,45.2941676327,-0.437417270599,432,,Souméras,Souméras,17,Charente-Maritime,75,Nouvelle-Aquitaine
17433,SOUSMOULINS,17130,SOUSMOULINS,,45.3070848749,-0.327704818651,433,,Sousmoulins,Sousmoulins,17,Charente-Maritime,75,Nouvelle-Aquitaine
17434,SURGERES,17700,SURGERES,,46.1044773827,-0.753715269757,434,,Surgères,Surgères,17,Charente-Maritime,75,Nouvelle-Aquitaine
17435,TAILLANT,17350,TAILLANT,,45.9017839657,-0.622044572982,435,,Taillant,Taillant,17,Charente-Maritime,75,Nouvelle-Aquitaine
17436,TAILLEBOURG,17350,TAILLEBOURG,,45.8452927158,-0.635939473122,436,,Taillebourg,Taillebourg,17,Charente-Maritime,75,Nouvelle-Aquitaine
17437,TALMONT SUR GIRONDE,17120,TALMONT SUR GIRONDE,,45.54345601,-0.901968620389,437,,Talmont-sur-Gironde,Talmont-sur-Gironde,17,Charente-Maritime,75,Nouvelle-Aquitaine
17438,TANZAC,17260,TANZAC,,45.5575032026,-0.624877512569,438,,Tanzac,Tanzac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17439,TAUGON,17170,TAUGON,,46.3172452775,-0.831865741628,439,,Taugon,Taugon,17,Charente-Maritime,75,Nouvelle-Aquitaine
17440,TERNANT,17400,TERNANT,,45.9465741862,-0.561311450959,440,,Ternant,Ternant,17,Charente-Maritime,75,Nouvelle-Aquitaine
17441,TESSON,17460,TESSON,,45.6319623788,-0.656221839044,441,,Tesson,Tesson,17,Charente-Maritime,75,Nouvelle-Aquitaine
17442,THAIMS,17120,THAIMS,,45.6155314276,-0.791473092031,442,,Thaims,Thaims,17,Charente-Maritime,75,Nouvelle-Aquitaine
17443,THAIRE,17290,THAIRE,,46.0773803387,-1.00436533555,443,,Thairé,Thairé,17,Charente-Maritime,75,Nouvelle-Aquitaine
17444,THENAC,17460,THENAC,,45.6757625165,-0.651811940995,444,,Thénac,Thénac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17445,THEZAC,17600,THEZAC,,45.6764768852,-0.789153508281,445,,Thézac,Thézac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17446,THORS,17160,THORS,,45.8252238601,-0.316151081372,446,,Thors,Thors,17,Charente-Maritime,75,Nouvelle-Aquitaine
17447,LE THOU,17290,LE THOU,,46.0868700947,-0.937375206626,447,Le,Thou,Le Thou,17,Charente-Maritime,75,Nouvelle-Aquitaine
17448,TONNAY BOUTONNE,17380,TONNAY BOUTONNE,,45.9748907338,-0.710979318464,448,,Tonnay-Boutonne,Tonnay-Boutonne,17,Charente-Maritime,75,Nouvelle-Aquitaine
17449,TONNAY CHARENTE,17430,TONNAY CHARENTE,,45.9633766445,-0.874412067316,449,,Tonnay-Charente,Tonnay-Charente,17,Charente-Maritime,75,Nouvelle-Aquitaine
17450,TORXE,17380,TORXE,,45.9609696756,-0.637755810704,450,,Torxé,Torxé,17,Charente-Maritime,75,Nouvelle-Aquitaine
17451,LES TOUCHES DE PERIGNY,17160,LES TOUCHES DE PERIGNY,,45.9076315101,-0.26562144892,451,Les,Touches-de-Périgny,Les Touches-de-Périgny,17,Charente-Maritime,75,Nouvelle-Aquitaine
17452,LA TREMBLADE,17390,LA TREMBLADE,,45.7595222262,-1.19070707014,452,La,Tremblade,La Tremblade,17,Charente-Maritime,75,Nouvelle-Aquitaine
17452,LA TREMBLADE,17390,LA TREMBLADE,RONCE LES BAINS,45.7595222262,-1.19070707014,452,La,Tremblade,La Tremblade,17,Charente-Maritime,75,Nouvelle-Aquitaine
17453,TRIZAY,17250,TRIZAY,,45.8824159109,-0.903805758025,453,,Trizay,Trizay,17,Charente-Maritime,75,Nouvelle-Aquitaine
17454,TUGERAS ST MAURICE,17130,TUGERAS ST MAURICE,,45.3553716796,-0.407478613071,454,,Tugéras-Saint-Maurice,Tugéras-Saint-Maurice,17,Charente-Maritime,75,Nouvelle-Aquitaine
17454,TUGERAS ST MAURICE,17130,TUGERAS ST MAURICE,ST MAURICE DE LAURENCANNE,45.3553716796,-0.407478613071,454,,Tugéras-Saint-Maurice,Tugéras-Saint-Maurice,17,Charente-Maritime,75,Nouvelle-Aquitaine
17455,LA VALLEE,17250,LA VALLEE,,45.8908313129,-0.845996319512,455,La,Vallée,La Vallée,17,Charente-Maritime,75,Nouvelle-Aquitaine
17457,VANDRE,17700,VANDRE,,46.0532187101,-0.753825189117,457,La,Devise,La Devise,17,Charente-Maritime,75,Nouvelle-Aquitaine
17458,VANZAC,17500,VANZAC,,45.3586816198,-0.298855553768,458,,Vanzac,Vanzac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17459,VARAIZE,17400,VARAIZE,,45.9226742862,-0.426927895363,459,,Varaize,Varaize,17,Charente-Maritime,75,Nouvelle-Aquitaine
17460,VARZAY,17460,VARZAY,,45.7002243997,-0.743043174473,460,,Varzay,Varzay,17,Charente-Maritime,75,Nouvelle-Aquitaine
17461,VAUX SUR MER,17640,VAUX SUR MER,,45.6448017664,-1.05853577703,461,,Vaux-sur-Mer,Vaux-sur-Mer,17,Charente-Maritime,75,Nouvelle-Aquitaine
17462,VENERAND,17100,VENERAND,,45.7877540613,-0.548759404902,462,,Vénérand,Vénérand,17,Charente-Maritime,75,Nouvelle-Aquitaine
17463,VERGEROUX,17300,VERGEROUX,,45.9737006196,-0.986529132827,463,,Vergeroux,Vergeroux,17,Charente-Maritime,75,Nouvelle-Aquitaine
17464,VERGNE,17330,VERGNE,,46.0698805334,-0.514998278543,464,,Vergné,Vergné,17,Charente-Maritime,75,Nouvelle-Aquitaine
17465,LA VERGNE,17400,LA VERGNE,,45.9708906994,-0.567291200569,465,La,Vergne,La Vergne,17,Charente-Maritime,75,Nouvelle-Aquitaine
17466,VERINES,17540,VERINES,,46.1972486983,-0.972947355741,466,,Vérines,Vérines,17,Charente-Maritime,75,Nouvelle-Aquitaine
17467,VERVANT,17400,VERVANT,,45.9696119746,-0.445129006344,467,,Vervant,Vervant,17,Charente-Maritime,75,Nouvelle-Aquitaine
17468,VIBRAC,17130,VIBRAC,,45.3505626693,-0.340389510498,468,,Vibrac,Vibrac,17,Charente-Maritime,75,Nouvelle-Aquitaine
17469,VILLARS EN PONS,17260,VILLARS EN PONS,,45.6039717298,-0.628988620843,469,,Villars-en-Pons,Villars-en-Pons,17,Charente-Maritime,75,Nouvelle-Aquitaine
17470,VILLARS LES BOIS,17770,VILLARS LES BOIS,,45.7969827822,-0.435523123575,470,,Villars-les-Bois,Villars-les-Bois,17,Charente-Maritime,75,Nouvelle-Aquitaine
17471,LA VILLEDIEU,17470,LA VILLEDIEU,,46.0652942658,-0.322967627263,471,La,Villedieu,La Villedieu,17,Charente-Maritime,75,Nouvelle-Aquitaine
17472,VILLEDOUX,17230,VILLEDOUX,,46.2434012234,-1.07321328867,472,,Villedoux,Villedoux,17,Charente-Maritime,75,Nouvelle-Aquitaine
17473,VILLEMORIN,17470,VILLEMORIN,,45.9936606529,-0.308323028167,473,,Villemorin,Villemorin,17,Charente-Maritime,75,Nouvelle-Aquitaine
17474,VILLENEUVE LA COMTESSE,17330,VILLENEUVE LA COMTESSE,,46.0950730201,-0.490293993465,474,,Villeneuve-la-Comtesse,Villeneuve-la-Comtesse,17,Charente-Maritime,75,Nouvelle-Aquitaine
17474,VILLENEUVE LA COMTESSE,17330,VILLENEUVE LA COMTESSE,VILLENOUVELLE,46.0950730201,-0.490293993465,474,,Villeneuve-la-Comtesse,Villeneuve-la-Comtesse,17,Charente-Maritime,75,Nouvelle-Aquitaine
17476,VILLEXAVIER,17500,VILLEXAVIER,,45.3786120866,-0.439687126824,476,,Villexavier,Villexavier,17,Charente-Maritime,75,Nouvelle-Aquitaine
17477,VILLIERS COUTURE,17510,VILLIERS COUTURE,,45.9838256674,-0.152059311199,477,,Villiers-Couture,Villiers-Couture,17,Charente-Maritime,75,Nouvelle-Aquitaine
17478,VINAX,17510,VINAX,,46.0341296435,-0.212146477613,478,,Vinax,Vinax,17,Charente-Maritime,75,Nouvelle-Aquitaine
17479,VIROLLET,17260,VIROLLET,,45.5376067131,-0.728910757223,479,,Virollet,Virollet,17,Charente-Maritime,75,Nouvelle-Aquitaine
17480,VIRSON,17290,VIRSON,,46.1388630308,-0.88461577185,480,,Virson,Virson,17,Charente-Maritime,75,Nouvelle-Aquitaine
17481,VOISSAY,17400,VOISSAY,,45.9470700538,-0.602074440108,481,,Voissay,Voissay,17,Charente-Maritime,75,Nouvelle-Aquitaine
17482,VOUHE,17700,VOUHE,,46.1518956728,-0.797123329244,482,,Vouhé,Vouhé,17,Charente-Maritime,75,Nouvelle-Aquitaine
17483,YVES,17340,YVES,,46.0392686841,-1.03449244286,483,,Yves,Yves,17,Charente-Maritime,75,Nouvelle-Aquitaine
17483,YVES,17340,YVES,LES BOUCHOLEURS,46.0392686841,-1.03449244286,483,,Yves,Yves,17,Charente-Maritime,75,Nouvelle-Aquitaine
17484,PORT DES BARQUES,17730,PORT DES BARQUES,,45.9419529118,-1.07230587054,484,,Port-des-Barques,Port-des-Barques,17,Charente-Maritime,75,Nouvelle-Aquitaine
17485,LE GRAND VILLAGE PLAGE,17370,LE GRAND VILLAGE PLAGE,,45.8620787516,-1.24274513266,485,Le,Grand-Village-Plage,Le Grand-Village-Plage,17,Charente-Maritime,75,Nouvelle-Aquitaine
17486,LA BREE LES BAINS,17840,LA BREE LES BAINS,,46.0078502924,-1.35092343182,486,La,Brée-les-Bains,La Brée-les-Bains,17,Charente-Maritime,75,Nouvelle-Aquitaine
18001,ACHERES,18250,ACHERES,,47.2826534372,2.45877786434,1,,Achères,Achères,18,Cher,24,Centre-Val de Loire
18002,AINAY LE VIEIL,18200,AINAY LE VIEIL,,46.6641114038,2.54417379933,2,,Ainay-le-Vieil,Ainay-le-Vieil,18,Cher,24,Centre-Val de Loire
18003,LES AIX D ANGILLON,18220,LES AIX D ANGILLON,,47.192968882,2.56146410193,3,Les,Aix-d'Angillon,Les Aix-d'Angillon,18,Cher,24,Centre-Val de Loire
18004,ALLOGNY,18110,ALLOGNY,,47.2339866645,2.30681136882,4,,Allogny,Allogny,18,Cher,24,Centre-Val de Loire
18005,ALLOUIS,18500,ALLOUIS,,47.1778132314,2.24096841873,5,,Allouis,Allouis,18,Cher,24,Centre-Val de Loire
18006,ANNOIX,18340,ANNOIX,,46.9690286816,2.54279366157,6,,Annoix,Annoix,18,Cher,24,Centre-Val de Loire
18007,APREMONT SUR ALLIER,18150,APREMONT SUR ALLIER,,46.9110862876,3.01870489401,7,,Apremont-sur-Allier,Apremont-sur-Allier,18,Cher,24,Centre-Val de Loire
18008,ARCAY,18340,ARCAY,,46.9625352039,2.3359112711,8,,Arçay,Arçay,18,Cher,24,Centre-Val de Loire
18009,ARCOMPS,18200,ARCOMPS,,46.6760407934,2.42650950674,9,,Arcomps,Arcomps,18,Cher,24,Centre-Val de Loire
18009,ARCOMPS,18200,ARCOMPS,FOSSE NOUVELLE,46.6760407934,2.42650950674,9,,Arcomps,Arcomps,18,Cher,24,Centre-Val de Loire
18010,ARDENAIS,18170,ARDENAIS,,46.6450269496,2.3518517619,10,,Ardenais,Ardenais,18,Cher,24,Centre-Val de Loire
18011,ARGENT SUR SAULDRE,18410,ARGENT SUR SAULDRE,,47.5626482877,2.4300855539,11,,Argent-sur-Sauldre,Argent-sur-Sauldre,18,Cher,24,Centre-Val de Loire
18012,ARGENVIERES,18140,ARGENVIERES,,47.1407710364,2.99229956887,12,,Argenvières,Argenvières,18,Cher,24,Centre-Val de Loire
18013,ARPHEUILLES,18200,ARPHEUILLES,,46.791585762,2.56799217336,13,,Arpheuilles,Arpheuilles,18,Cher,24,Centre-Val de Loire
18014,ASSIGNY,18260,ASSIGNY,,47.4310286105,2.75030716643,14,,Assigny,Assigny,18,Cher,24,Centre-Val de Loire
18015,AUBIGNY SUR NERE,18700,AUBIGNY SUR NERE,,47.4861718263,2.42121788452,15,,Aubigny-sur-Nère,Aubigny-sur-Nère,18,Cher,24,Centre-Val de Loire
18016,AUBINGES,18220,AUBINGES,,47.2193143403,2.58567212156,16,,Aubinges,Aubinges,18,Cher,24,Centre-Val de Loire
18017,AUGY SUR AUBOIS,18600,AUGY SUR AUBOIS,,46.7829151486,2.85715092019,17,,Augy-sur-Aubois,Augy-sur-Aubois,18,Cher,24,Centre-Val de Loire
18018,AVORD,18520,AVORD,,47.0320503423,2.66580708853,18,,Avord,Avord,18,Cher,24,Centre-Val de Loire
18019,AZY,18220,AZY,,47.1920688038,2.71043899045,19,,Azy,Azy,18,Cher,24,Centre-Val de Loire
18020,BANNAY,18300,BANNAY,,47.3833182076,2.87338903587,20,,Bannay,Bannay,18,Cher,24,Centre-Val de Loire
18021,BANNEGON,18210,BANNEGON,,46.8069473041,2.7278310908,21,,Bannegon,Bannegon,18,Cher,24,Centre-Val de Loire
18022,BARLIEU,18260,BARLIEU,,47.4937866179,2.62277593565,22,,Barlieu,Barlieu,18,Cher,24,Centre-Val de Loire
18023,BAUGY,18800,BAUGY,,47.0713406909,2.71558067108,23,,Baugy,Baugy,18,Cher,24,Centre-Val de Loire
18024,BEDDES,18370,BEDDES,,46.6084346256,2.2100404185,24,,Beddes,Beddes,18,Cher,24,Centre-Val de Loire
18025,BEFFES,18320,BEFFES,,47.0930093416,3.00110748775,25,,Beffes,Beffes,18,Cher,24,Centre-Val de Loire
18026,BELLEVILLE SUR LOIRE,18240,BELLEVILLE SUR LOIRE,,47.5017852498,2.84619659629,26,,Belleville-sur-Loire,Belleville-sur-Loire,18,Cher,24,Centre-Val de Loire
18027,BENGY SUR CRAON,18520,BENGY SUR CRAON,,46.9996554419,2.73230531635,27,,Bengy-sur-Craon,Bengy-sur-Craon,18,Cher,24,Centre-Val de Loire
18028,BERRY BOUY,18500,BERRY BOUY,,47.1305841465,2.29645039352,28,,Berry-Bouy,Berry-Bouy,18,Cher,24,Centre-Val de Loire
18029,BESSAIS LE FROMENTAL,18210,BESSAIS LE FROMENTAL,,46.7475178311,2.77476595037,29,,Bessais-le-Fromental,Bessais-le-Fromental,18,Cher,24,Centre-Val de Loire
18030,BLANCAFORT,18410,BLANCAFORT,,47.5351356226,2.54166761341,30,,Blancafort,Blancafort,18,Cher,24,Centre-Val de Loire
18031,BLET,18350,BLET,,46.8822848044,2.73104674271,31,,Blet,Blet,18,Cher,24,Centre-Val de Loire
18032,BOULLERET,18240,BOULLERET,,47.4322257963,2.88490125663,32,,Boulleret,Boulleret,18,Cher,24,Centre-Val de Loire
18033,BOURGES,18000,BOURGES,,47.0749572013,2.40417137557,33,,Bourges,Bourges,18,Cher,24,Centre-Val de Loire
18034,BOUZAIS,18200,BOUZAIS,,46.704910988,2.47008751664,34,,Bouzais,Bouzais,18,Cher,24,Centre-Val de Loire
18035,BRECY,18220,BRECY,,47.1299888038,2.63016565896,35,,Brécy,Brécy,18,Cher,24,Centre-Val de Loire
18036,BRINAY,18120,BRINAY,,47.1640449226,2.11235689619,36,,Brinay,Brinay,18,Cher,24,Centre-Val de Loire
18037,BRINON SUR SAULDRE,18410,BRINON SUR SAULDRE,,47.5685456102,2.2264194575,37,,Brinon-sur-Sauldre,Brinon-sur-Sauldre,18,Cher,24,Centre-Val de Loire
18038,BRUERE ALLICHAMPS,18200,BRUERE ALLICHAMPS,,46.7757107356,2.42892835888,38,,Bruère-Allichamps,Bruère-Allichamps,18,Cher,24,Centre-Val de Loire
18039,BUE,18300,BUE,,47.3103309784,2.79357015319,39,,Bué,Bué,18,Cher,24,Centre-Val de Loire
18040,BUSSY,18130,BUSSY,,46.8966843974,2.62063037796,40,,Bussy,Bussy,18,Cher,24,Centre-Val de Loire
18041,LA CELETTE,18360,LA CELETTE,,46.6382674585,2.522655765,41,La,Celette,La Celette,18,Cher,24,Centre-Val de Loire
18042,LA CELLE,18200,LA CELLE,,46.770990086,2.45882023985,42,La,Celle,La Celle,18,Cher,24,Centre-Val de Loire
18043,LA CELLE CONDE,18160,LA CELLE CONDE,,46.7891133076,2.176621696,43,La,Celle-Condé,La Celle-Condé,18,Cher,24,Centre-Val de Loire
18044,CERBOIS,18120,CERBOIS,,47.1109466347,2.10554374746,44,,Cerbois,Cerbois,18,Cher,24,Centre-Val de Loire
18045,CHALIVOY MILON,18130,CHALIVOY MILON,,46.860388257,2.7018249242,45,,Chalivoy-Milon,Chalivoy-Milon,18,Cher,24,Centre-Val de Loire
18046,CHAMBON,18190,CHAMBON,,46.7780830686,2.33442238043,46,,Chambon,Chambon,18,Cher,24,Centre-Val de Loire
18047,LA CHAPELLE D ANGILLON,18380,LA CHAPELLE D ANGILLON,,47.3709734883,2.44003339096,47,La,Chapelle-d'Angillon,La Chapelle-d'Angillon,18,Cher,24,Centre-Val de Loire
18048,LA CHAPELLE HUGON,18150,LA CHAPELLE HUGON,,46.8956501509,2.95415961262,48,La,Chapelle-Hugon,La Chapelle-Hugon,18,Cher,24,Centre-Val de Loire
18049,LA CHAPELLE MONTLINARD,18140,LA CHAPELLE MONTLINARD,,47.1777742856,2.98551825153,49,La,Chapelle-Montlinard,La Chapelle-Montlinard,18,Cher,24,Centre-Val de Loire
18050,LA CHAPELLE ST URSIN,18570,LA CHAPELLE ST URSIN,,47.0576566337,2.31864168106,50,La,Chapelle-Saint-Ursin,La Chapelle-Saint-Ursin,18,Cher,24,Centre-Val de Loire
18051,LA CHAPELOTTE,18250,LA CHAPELOTTE,,47.3342226318,2.5989427382,51,La,Chapelotte,La Chapelotte,18,Cher,24,Centre-Val de Loire
18052,CHARENTON DU CHER,18210,CHARENTON DU CHER,,46.74275373,2.65293994234,52,,Charenton-du-Cher,Charenton-du-Cher,18,Cher,24,Centre-Val de Loire
18053,CHARENTONNAY,18140,CHARENTONNAY,,47.1420284173,2.86948989988,53,,Charentonnay,Charentonnay,18,Cher,24,Centre-Val de Loire
18054,CHARLY,18350,CHARLY,,46.9023025969,2.76293801009,54,,Charly,Charly,18,Cher,24,Centre-Val de Loire
18055,CHAROST,18290,CHAROST,,46.9913097017,2.1285312337,55,,Chârost,Chârost,18,Cher,24,Centre-Val de Loire
18056,CHASSY,18800,CHASSY,,47.0436728432,2.83281439128,56,,Chassy,Chassy,18,Cher,24,Centre-Val de Loire
18056,CHASSY,18800,CHASSY,DEJOINTES,47.0436728432,2.83281439128,56,,Chassy,Chassy,18,Cher,24,Centre-Val de Loire
18057,CHATEAUMEILLANT,18370,CHATEAUMEILLANT,,46.5546814085,2.20738539036,57,,Châteaumeillant,Châteaumeillant,18,Cher,24,Centre-Val de Loire
18058,CHATEAUNEUF SUR CHER,18190,CHATEAUNEUF SUR CHER,,46.8663441588,2.34034434393,58,,Châteauneuf-sur-Cher,Châteauneuf-sur-Cher,18,Cher,24,Centre-Val de Loire
18059,LE CHATELET,18170,LE CHATELET,,46.6281920568,2.29315665601,59,Le,Châtelet,Le Châtelet,18,Cher,24,Centre-Val de Loire
18060,CHAUMONT,18350,CHAUMONT,,46.8416938785,2.75008563898,60,,Chaumont,Chaumont,18,Cher,24,Centre-Val de Loire
18061,CHAUMOUX MARCILLY,18140,CHAUMOUX MARCILLY,,47.142667178,2.77994573074,61,,Chaumoux-Marcilly,Chaumoux-Marcilly,18,Cher,24,Centre-Val de Loire
18062,LE CHAUTAY,18150,LE CHAUTAY,,46.9852189195,2.9640713443,62,Le,Chautay,Le Chautay,18,Cher,24,Centre-Val de Loire
18063,CHAVANNES,18190,CHAVANNES,,46.8639364325,2.4016571402,63,,Chavannes,Chavannes,18,Cher,24,Centre-Val de Loire
18064,CHERY,18120,CHERY,,47.1163043248,2.02648760477,64,,Chéry,Chéry,18,Cher,24,Centre-Val de Loire
18065,CHEZAL BENOIT,18160,CHEZAL BENOIT,,46.8317666343,2.11116982783,65,,Chezal-Benoît,Chezal-Benoît,18,Cher,24,Centre-Val de Loire
18066,CIVRAY,18290,CIVRAY,,46.9738711356,2.18516682907,66,,Civray,Civray,18,Cher,24,Centre-Val de Loire
18067,CLEMONT,18410,CLEMONT,,47.562852584,2.33055121422,67,,Clémont,Clémont,18,Cher,24,Centre-Val de Loire
18068,COGNY,18130,COGNY,,46.8511371281,2.64397338804,68,,Cogny,Cogny,18,Cher,24,Centre-Val de Loire
18069,COLOMBIERS,18200,COLOMBIERS,,46.7001989151,2.5566301229,69,,Colombiers,Colombiers,18,Cher,24,Centre-Val de Loire
18070,CONCRESSAULT,18260,CONCRESSAULT,,47.4914771223,2.56973230744,70,,Concressault,Concressault,18,Cher,24,Centre-Val de Loire
18071,CONTRES,18130,CONTRES,,46.8574614784,2.49599485968,71,,Contres,Contres,18,Cher,24,Centre-Val de Loire
18072,CORNUSSE,18350,CORNUSSE,,46.9561295033,2.73264385975,72,,Cornusse,Cornusse,18,Cher,24,Centre-Val de Loire
18073,CORQUOY,18190,CORQUOY,,46.8920009794,2.29746216707,73,,Corquoy,Corquoy,18,Cher,24,Centre-Val de Loire
18074,COUARGUES,18300,COUARGUES,,47.2840191035,2.92686969695,74,,Couargues,Couargues,18,Cher,24,Centre-Val de Loire
18075,COURS LES BARRES,18320,COURS LES BARRES,,47.0221738133,3.03745097069,75,,Cours-les-Barres,Cours-les-Barres,18,Cher,24,Centre-Val de Loire
18076,COUST,18210,COUST,,46.683985112,2.5990481685,76,,Coust,Coust,18,Cher,24,Centre-Val de Loire
18077,COUY,18140,COUY,,47.1087981251,2.8281137217,77,,Couy,Couy,18,Cher,24,Centre-Val de Loire
18078,CREZANCAY SUR CHER,18190,CREZANCAY SUR CHER,,46.8142017646,2.34261889116,78,,Crézançay-sur-Cher,Crézançay-sur-Cher,18,Cher,24,Centre-Val de Loire
18079,CREZANCY EN SANCERRE,18300,CREZANCY EN SANCERRE,,47.3043105708,2.74787668704,79,,Crézancy-en-Sancerre,Crézancy-en-Sancerre,18,Cher,24,Centre-Val de Loire
18080,CROISY,18350,CROISY,,46.9301591235,2.82901791001,80,,Croisy,Croisy,18,Cher,24,Centre-Val de Loire
18081,CROSSES,18340,CROSSES,,47.0079967689,2.5680059159,81,,Crosses,Crosses,18,Cher,24,Centre-Val de Loire
18082,CUFFY,18150,CUFFY,,46.9693684862,3.03803252226,82,,Cuffy,Cuffy,18,Cher,24,Centre-Val de Loire
18083,CULAN,18270,CULAN,,46.5455100179,2.35583784131,83,,Culan,Culan,18,Cher,24,Centre-Val de Loire
18084,DAMPIERRE EN CROT,18260,DAMPIERRE EN CROT,,47.4559955079,2.57410394358,84,,Dampierre-en-Crot,Dampierre-en-Crot,18,Cher,24,Centre-Val de Loire
18085,DAMPIERRE EN GRACAY,18310,DAMPIERRE EN GRACAY,,47.1833400465,1.94119668443,85,,Dampierre-en-Graçay,Dampierre-en-Graçay,18,Cher,24,Centre-Val de Loire
18086,DREVANT,18200,DREVANT,,46.6982881178,2.52340058849,86,,Drevant,Drevant,18,Cher,24,Centre-Val de Loire
18087,DUN SUR AURON,18130,DUN SUR AURON,,46.8920491203,2.56065525402,87,,Dun-sur-Auron,Dun-sur-Auron,18,Cher,24,Centre-Val de Loire
18088,ENNORDRES,18380,ENNORDRES,,47.4226900181,2.40992073564,88,,Ennordres,Ennordres,18,Cher,24,Centre-Val de Loire
18089,EPINEUIL LE FLEURIEL,18360,EPINEUIL LE FLEURIEL,,46.5709100191,2.55643995576,89,,Épineuil-le-Fleuriel,Épineuil-le-Fleuriel,18,Cher,24,Centre-Val de Loire
18090,ETRECHY,18800,ETRECHY,,47.1597133509,2.7158646405,90,,Étréchy,Étréchy,18,Cher,24,Centre-Val de Loire
18091,FARGES ALLICHAMPS,18200,FARGES ALLICHAMPS,,46.7578876589,2.41076588761,91,,Farges-Allichamps,Farges-Allichamps,18,Cher,24,Centre-Val de Loire
18092,FARGES EN SEPTAINE,18800,FARGES EN SEPTAINE,,47.0658935028,2.64111638709,92,,Farges-en-Septaine,Farges-en-Septaine,18,Cher,24,Centre-Val de Loire
18093,FAVERDINES,18360,FAVERDINES,,46.6369138943,2.45989211577,93,,Faverdines,Faverdines,18,Cher,24,Centre-Val de Loire
18094,FEUX,18300,FEUX,,47.229346809,2.86665110758,94,,Feux,Feux,18,Cher,24,Centre-Val de Loire
18095,FLAVIGNY,18350,FLAVIGNY,,46.9616811492,2.80011813798,95,,Flavigny,Flavigny,18,Cher,24,Centre-Val de Loire
18096,FOECY,18500,FOECY,,47.1803415795,2.15455136481,96,,Foëcy,Foëcy,18,Cher,24,Centre-Val de Loire
18097,FUSSY,18110,FUSSY,,47.1409616832,2.42597712218,97,,Fussy,Fussy,18,Cher,24,Centre-Val de Loire
18098,GARDEFORT,18300,GARDEFORT,,47.2604545544,2.82377502838,98,,Gardefort,Gardefort,18,Cher,24,Centre-Val de Loire
18099,GARIGNY,18140,GARIGNY,,47.0876281649,2.88287839693,99,,Garigny,Garigny,18,Cher,24,Centre-Val de Loire
18100,GENOUILLY,18310,GENOUILLY,,47.1905784621,1.88477464044,100,,Genouilly,Genouilly,18,Cher,24,Centre-Val de Loire
18101,GERMIGNY L EXEMPT,18150,GERMIGNY L EXEMPT,,46.9149646451,2.88246532896,101,,Germigny-l'Exempt,Germigny-l'Exempt,18,Cher,24,Centre-Val de Loire
18102,GIVARDON,18600,GIVARDON,,46.83317184,2.80822119538,102,,Givardon,Givardon,18,Cher,24,Centre-Val de Loire
18103,GRACAY,18310,GRACAY,,47.1408422625,1.85668822147,103,,Graçay,Graçay,18,Cher,24,Centre-Val de Loire
18104,GROISES,18140,GROISES,,47.2059415173,2.8120139,104,,Groises,Groises,18,Cher,24,Centre-Val de Loire
18105,GRON,18800,GRON,,47.1164919396,2.73659516033,105,,Gron,Gron,18,Cher,24,Centre-Val de Loire
18106,GROSSOUVRE,18600,GROSSOUVRE,,46.8722999491,2.93595390975,106,,Grossouvre,Grossouvre,18,Cher,24,Centre-Val de Loire
18107,LA GROUTTE,18200,LA GROUTTE,,46.689480572,2.5127413479,107,La,Groutte,La Groutte,18,Cher,24,Centre-Val de Loire
18108,LA GUERCHE SUR L AUBOIS,18150,LA GUERCHE SUR L AUBOIS,,46.9518824158,2.94540320329,108,La,Guerche-sur-l'Aubois,La Guerche-sur-l'Aubois,18,Cher,24,Centre-Val de Loire
18109,HENRICHEMONT,18250,HENRICHEMONT,,47.2982614047,2.53601083137,109,,Henrichemont,Henrichemont,18,Cher,24,Centre-Val de Loire
18110,HERRY,18140,HERRY,,47.2284204871,2.9415312436,110,,Herry,Herry,18,Cher,24,Centre-Val de Loire
18111,HUMBLIGNY,18250,HUMBLIGNY,,47.2622294774,2.64819893365,111,,Humbligny,Humbligny,18,Cher,24,Centre-Val de Loire
18112,IDS ST ROCH,18170,IDS ST ROCH,,46.7139612641,2.25788543249,112,,Ids-Saint-Roch,Ids-Saint-Roch,18,Cher,24,Centre-Val de Loire
18113,IGNOL,18350,IGNOL,,46.9576245517,2.86419734171,113,,Ignol,Ignol,18,Cher,24,Centre-Val de Loire
18114,INEUIL,18160,INEUIL,,46.7689871764,2.27606025434,114,,Ineuil,Ineuil,18,Cher,24,Centre-Val de Loire
18115,IVOY LE PRE,18380,IVOY LE PRE,,47.355231934,2.5102170858,115,,Ivoy-le-Pré,Ivoy-le-Pré,18,Cher,24,Centre-Val de Loire
18116,JALOGNES,18300,JALOGNES,,47.2290743962,2.77827164434,116,,Jalognes,Jalognes,18,Cher,24,Centre-Val de Loire
18117,JARS,18260,JARS,,47.3912030155,2.66658069402,117,,Jars,Jars,18,Cher,24,Centre-Val de Loire
18118,JOUET SUR L AUBOIS,18320,JOUET SUR L AUBOIS,,47.0454813599,3.00331831093,118,,Jouet-sur-l'Aubois,Jouet-sur-l'Aubois,18,Cher,24,Centre-Val de Loire
18119,JUSSY CHAMPAGNE,18130,JUSSY CHAMPAGNE,,46.9911303193,2.646073906,119,,Jussy-Champagne,Jussy-Champagne,18,Cher,24,Centre-Val de Loire
18120,JUSSY LE CHAUDRIER,18140,JUSSY LE CHAUDRIER,,47.1213846381,2.94318254275,120,,Jussy-le-Chaudrier,Jussy-le-Chaudrier,18,Cher,24,Centre-Val de Loire
18121,LANTAN,18130,LANTAN,,46.9047420149,2.66151003852,121,,Lantan,Lantan,18,Cher,24,Centre-Val de Loire
18122,LAPAN,18340,LAPAN,,46.9209106861,2.29549352585,122,,Lapan,Lapan,18,Cher,24,Centre-Val de Loire
18123,LAVERDINES,18800,LAVERDINES,,47.0338272835,2.79301218306,123,,Laverdines,Laverdines,18,Cher,24,Centre-Val de Loire
18124,LAZENAY,18120,LAZENAY,,47.0669527618,2.0765874358,124,,Lazenay,Lazenay,18,Cher,24,Centre-Val de Loire
18125,LERE,18240,LERE,,47.4660367439,2.8732698385,125,,Léré,Léré,18,Cher,24,Centre-Val de Loire
18126,LEVET,18340,LEVET,,46.9301181385,2.39251813835,126,,Levet,Levet,18,Cher,24,Centre-Val de Loire
18127,LIGNIERES,18160,LIGNIERES,,46.7617210337,2.20479418633,127,,Lignières,Lignières,18,Cher,24,Centre-Val de Loire
18128,LIMEUX,18120,LIMEUX,,47.0765880688,2.12482425358,128,,Limeux,Limeux,18,Cher,24,Centre-Val de Loire
18129,LISSAY LOCHY,18340,LISSAY LOCHY,,46.9742881367,2.38711520359,129,,Lissay-Lochy,Lissay-Lochy,18,Cher,24,Centre-Val de Loire
18130,LOYE SUR ARNON,18170,LOYE SUR ARNON,,46.6333009116,2.40164471398,130,,Loye-sur-Arnon,Loye-sur-Arnon,18,Cher,24,Centre-Val de Loire
18131,LUGNY BOURBONNAIS,18350,LUGNY BOURBONNAIS,,46.9350965146,2.70318159119,131,,Lugny-Bourbonnais,Lugny-Bourbonnais,18,Cher,24,Centre-Val de Loire
18132,LUGNY CHAMPAGNE,18140,LUGNY CHAMPAGNE,,47.177490889,2.82796687911,132,,Lugny-Champagne,Lugny-Champagne,18,Cher,24,Centre-Val de Loire
18133,LUNERY,18400,LUNERY,,46.9383928285,2.25765806502,133,,Lunery,Lunery,18,Cher,24,Centre-Val de Loire
18134,LURY SUR ARNON,18120,LURY SUR ARNON,,47.1346444236,2.06403657777,134,,Lury-sur-Arnon,Lury-sur-Arnon,18,Cher,24,Centre-Val de Loire
18135,MAISONNAIS,18170,MAISONNAIS,,46.6480807843,2.21996762101,135,,Maisonnais,Maisonnais,18,Cher,24,Centre-Val de Loire
18136,MARCAIS,18170,MARCAIS,,46.6961285977,2.35313080489,136,,Marçais,Marçais,18,Cher,24,Centre-Val de Loire
18137,MAREUIL SUR ARNON,18290,MAREUIL SUR ARNON,,46.8786192829,2.16312700894,137,,Mareuil-sur-Arnon,Mareuil-sur-Arnon,18,Cher,24,Centre-Val de Loire
18138,MARMAGNE,18500,MARMAGNE,,47.0892553874,2.27075651411,138,,Marmagne,Marmagne,18,Cher,24,Centre-Val de Loire
18139,MARSEILLES LES AUBIGNY,18320,MARSEILLES LES AUBIGNY,,47.0700384029,2.9939315256,139,,Marseilles-lès-Aubigny,Marseilles-lès-Aubigny,18,Cher,24,Centre-Val de Loire
18140,MASSAY,18120,MASSAY,,47.1549090518,1.98560116565,140,,Massay,Massay,18,Cher,24,Centre-Val de Loire
18141,MEHUN SUR YEVRE,18500,MEHUN SUR YEVRE,,47.1356637883,2.22043045347,141,,Mehun-sur-Yèvre,Mehun-sur-Yèvre,18,Cher,24,Centre-Val de Loire
18142,MEILLANT,18200,MEILLANT,,46.7812952674,2.5067896851,142,,Meillant,Meillant,18,Cher,24,Centre-Val de Loire
18143,MENETOU COUTURE,18320,MENETOU COUTURE,,47.0419975114,2.93649799809,143,,Menetou-Couture,Menetou-Couture,18,Cher,24,Centre-Val de Loire
18144,MENETOU RATEL,18300,MENETOU RATEL,,47.3478965782,2.75157035853,144,,Menetou-Râtel,Menetou-Râtel,18,Cher,24,Centre-Val de Loire
18145,MENETOU SALON,18510,MENETOU SALON,,47.241732115,2.49021091816,145,,Menetou-Salon,Menetou-Salon,18,Cher,24,Centre-Val de Loire
18146,MENETREOL SOUS SANCERRE,18300,MENETREOL SOUS SANCERRE,,47.3182695839,2.86662649363,146,,Ménétréol-sous-Sancerre,Ménétréol-sous-Sancerre,18,Cher,24,Centre-Val de Loire
18147,MENETREOL SUR SAULDRE,18700,MENETREOL SUR SAULDRE,,47.4492645796,2.28919930446,147,,Ménétréol-sur-Sauldre,Ménétréol-sur-Sauldre,18,Cher,24,Centre-Val de Loire
18148,MEREAU,18120,MEREAU,,47.1794168881,2.05220231898,148,,Méreau,Méreau,18,Cher,24,Centre-Val de Loire
18149,MERY ES BOIS,18380,MERY ES BOIS,,47.3086144324,2.37874179175,149,,Méry-ès-Bois,Méry-ès-Bois,18,Cher,24,Centre-Val de Loire
18150,MERY SUR CHER,18100,MERY SUR CHER,,47.2476860248,1.99385861671,150,,Méry-sur-Cher,Méry-sur-Cher,18,Cher,24,Centre-Val de Loire
18151,MONTIGNY,18250,MONTIGNY,,47.2282072534,2.68756376755,151,,Montigny,Montigny,18,Cher,24,Centre-Val de Loire
18152,MONTLOUIS,18160,MONTLOUIS,,46.8069612124,2.2474231379,152,,Montlouis,Montlouis,18,Cher,24,Centre-Val de Loire
18153,MORLAC,18170,MORLAC,,46.7269891169,2.31713642544,153,,Morlac,Morlac,18,Cher,24,Centre-Val de Loire
18154,MORNAY BERRY,18350,MORNAY BERRY,,47.0542272715,2.86500900166,154,,Mornay-Berry,Mornay-Berry,18,Cher,24,Centre-Val de Loire
18155,MORNAY SUR ALLIER,18600,MORNAY SUR ALLIER,,46.8163723965,3.01072708056,155,,Mornay-sur-Allier,Mornay-sur-Allier,18,Cher,24,Centre-Val de Loire
18156,MOROGUES,18220,MOROGUES,,47.2574083445,2.59600177096,156,,Morogues,Morogues,18,Cher,24,Centre-Val de Loire
18157,MORTHOMIERS,18570,MORTHOMIERS,,47.0436630553,2.27235598629,157,,Morthomiers,Morthomiers,18,Cher,24,Centre-Val de Loire
18158,MOULINS SUR YEVRE,18390,MOULINS SUR YEVRE,,47.0934510675,2.52827991158,158,,Moulins-sur-Yèvre,Moulins-sur-Yèvre,18,Cher,24,Centre-Val de Loire
18159,NANCAY,18330,NANCAY,,47.3670326924,2.17549519635,159,,Nançay,Nançay,18,Cher,24,Centre-Val de Loire
18160,NERONDES,18350,NERONDES,,47.0040801539,2.83841274012,160,,Nérondes,Nérondes,18,Cher,24,Centre-Val de Loire
18161,NEUILLY EN DUN,18600,NEUILLY EN DUN,,46.8022857542,2.78268305253,161,,Neuilly-en-Dun,Neuilly-en-Dun,18,Cher,24,Centre-Val de Loire
18162,NEUILLY EN SANCERRE,18250,NEUILLY EN SANCERRE,,47.305628773,2.65561084881,162,,Neuilly-en-Sancerre,Neuilly-en-Sancerre,18,Cher,24,Centre-Val de Loire
18163,NEUVY DEUX CLOCHERS,18250,NEUVY DEUX CLOCHERS,,47.2805836865,2.68919945423,163,,Neuvy-Deux-Clochers,Neuvy-Deux-Clochers,18,Cher,24,Centre-Val de Loire
18164,NEUVY LE BARROIS,18600,NEUVY LE BARROIS,,46.8597953067,3.01962993984,164,,Neuvy-le-Barrois,Neuvy-le-Barrois,18,Cher,24,Centre-Val de Loire
18165,NEUVY SUR BARANGEON,18330,NEUVY SUR BARANGEON,,47.3220132955,2.2713674082,165,,Neuvy-sur-Barangeon,Neuvy-sur-Barangeon,18,Cher,24,Centre-Val de Loire
18166,NOHANT EN GOUT,18390,NOHANT EN GOUT,,47.0899990268,2.58066243646,166,,Nohant-en-Goût,Nohant-en-Goût,18,Cher,24,Centre-Val de Loire
18167,NOHANT EN GRACAY,18310,NOHANT EN GRACAY,,47.1336454344,1.91231886836,167,,Nohant-en-Graçay,Nohant-en-Graçay,18,Cher,24,Centre-Val de Loire
18168,LE NOYER,18260,LE NOYER,,47.3641996794,2.66561684391,168,Le,Noyer,Le Noyer,18,Cher,24,Centre-Val de Loire
18169,NOZIERES,18200,NOZIERES,,46.734983408,2.42074813599,169,,Nozières,Nozières,18,Cher,24,Centre-Val de Loire
18170,OIZON,18700,OIZON,,47.4531780432,2.51260180785,170,,Oizon,Oizon,18,Cher,24,Centre-Val de Loire
18171,ORCENAIS,18200,ORCENAIS,,46.7097819686,2.413665719,171,,Orcenais,Orcenais,18,Cher,24,Centre-Val de Loire
18172,ORVAL,18200,ORVAL,,46.7220226061,2.46720008987,172,,Orval,Orval,18,Cher,24,Centre-Val de Loire
18173,OSMERY,18130,OSMERY,,46.9385913345,2.65792888635,173,,Osmery,Osmery,18,Cher,24,Centre-Val de Loire
18174,OSMOY,18390,OSMOY,,47.0631160847,2.50571113255,174,,Osmoy,Osmoy,18,Cher,24,Centre-Val de Loire
18175,OUROUER LES BOURDELINS,18350,OUROUER LES BOURDELINS,,46.9212610646,2.79033730796,175,,Ourouer-les-Bourdelins,Ourouer-les-Bourdelins,18,Cher,24,Centre-Val de Loire
18176,PARASSY,18220,PARASSY,,47.2421364963,2.53814745107,176,,Parassy,Parassy,18,Cher,24,Centre-Val de Loire
18177,PARNAY,18130,PARNAY,,46.8513776331,2.55606472439,177,,Parnay,Parnay,18,Cher,24,Centre-Val de Loire
18178,LA PERCHE,18200,LA PERCHE,,46.6330688475,2.57380750974,178,La,Perche,La Perche,18,Cher,24,Centre-Val de Loire
18179,PIGNY,18110,PIGNY,,47.1639629987,2.4440003398,179,,Pigny,Pigny,18,Cher,24,Centre-Val de Loire
18180,PLAIMPIED GIVAUDINS,18340,PLAIMPIED GIVAUDINS,,47.0077517141,2.43617013189,180,,Plaimpied-Givaudins,Plaimpied-Givaudins,18,Cher,24,Centre-Val de Loire
18181,PLOU,18290,PLOU,,47.0315088379,2.14830052941,181,,Plou,Plou,18,Cher,24,Centre-Val de Loire
18182,POISIEUX,18290,POISIEUX,,47.0315698276,2.08940222035,182,,Poisieux,Poisieux,18,Cher,24,Centre-Val de Loire
18183,LE PONDY,18210,LE PONDY,,46.7998704926,2.63813016663,183,Le,Pondy,Le Pondy,18,Cher,24,Centre-Val de Loire
18184,PRECY,18140,PRECY,,47.0904100974,2.92907066727,184,,Précy,Précy,18,Cher,24,Centre-Val de Loire
18185,PRESLY,18380,PRESLY,,47.3870067033,2.32263755555,185,,Presly,Presly,18,Cher,24,Centre-Val de Loire
18186,PREUILLY,18120,PREUILLY,,47.0949322488,2.1637576201,186,,Preuilly,Preuilly,18,Cher,24,Centre-Val de Loire
18187,PREVERANGES,18370,PREVERANGES,,46.4533938495,2.25291754357,187,,Préveranges,Préveranges,18,Cher,24,Centre-Val de Loire
18188,PRIMELLES,18400,PRIMELLES,,46.9066203258,2.21272434941,188,,Primelles,Primelles,18,Cher,24,Centre-Val de Loire
18189,QUANTILLY,18110,QUANTILLY,,47.22453003,2.4453965974,189,,Quantilly,Quantilly,18,Cher,24,Centre-Val de Loire
18190,QUINCY,18120,QUINCY,,47.1301375038,2.15620533706,190,,Quincy,Quincy,18,Cher,24,Centre-Val de Loire
18191,RAYMOND,18130,RAYMOND,,46.9716860127,2.68986237741,191,,Raymond,Raymond,18,Cher,24,Centre-Val de Loire
18192,REIGNY,18270,REIGNY,,46.5900399077,2.34226885465,192,,Reigny,Reigny,18,Cher,24,Centre-Val de Loire
18193,REZAY,18170,REZAY,,46.6725219424,2.17463233251,193,,Rezay,Rezay,18,Cher,24,Centre-Val de Loire
18194,RIANS,18220,RIANS,,47.184837825,2.6197112281,194,,Rians,Rians,18,Cher,24,Centre-Val de Loire
18195,SAGONNE,18600,SAGONNE,,46.8604606569,2.82886441436,195,,Sagonne,Sagonne,18,Cher,24,Centre-Val de Loire
18196,ST AIGNAN DES NOYERS,18600,ST AIGNAN DES NOYERS,,46.755350283,2.82189743565,196,,Saint-Aignan-des-Noyers,Saint-Aignan-des-Noyers,18,Cher,24,Centre-Val de Loire
18197,ST AMAND MONTROND,18200,ST AMAND MONTROND,,46.726171431,2.52013626979,197,,Saint-Amand-Montrond,Saint-Amand-Montrond,18,Cher,24,Centre-Val de Loire
18198,ST AMBROIX,18290,ST AMBROIX,,46.935551772,2.12401478057,198,,Saint-Ambroix,Saint-Ambroix,18,Cher,24,Centre-Val de Loire
18199,ST BAUDEL,18160,ST BAUDEL,,46.8510186735,2.20484359931,199,,Saint-Baudel,Saint-Baudel,18,Cher,24,Centre-Val de Loire
18200,ST BOUIZE,18300,ST BOUIZE,,47.2707643696,2.89210091282,200,,Saint-Bouize,Saint-Bouize,18,Cher,24,Centre-Val de Loire
18201,ST CAPRAIS,18400,ST CAPRAIS,,46.9713797864,2.29866398637,201,,Saint-Caprais,Saint-Caprais,18,Cher,24,Centre-Val de Loire
18202,ST CEOLS,18220,ST CEOLS,,47.2192248164,2.6363263069,202,,Saint-Céols,Saint-Céols,18,Cher,24,Centre-Val de Loire
18203,ST CHRISTOPHE LE CHAUDRY,18270,ST CHRISTOPHE LE CHAUDRY,,46.5829439875,2.3889430213,203,,Saint-Christophe-le-Chaudry,Saint-Christophe-le-Chaudry,18,Cher,24,Centre-Val de Loire
18204,ST DENIS DE PALIN,18130,ST DENIS DE PALIN,,46.9330784286,2.51915760832,204,,Saint-Denis-de-Palin,Saint-Denis-de-Palin,18,Cher,24,Centre-Val de Loire
18205,ST DOULCHARD,18230,ST DOULCHARD,,47.1109300599,2.35703710189,205,,Saint-Doulchard,Saint-Doulchard,18,Cher,24,Centre-Val de Loire
18206,ST ELOY DE GY,18110,ST ELOY DE GY,,47.1692906668,2.33076138218,206,,Saint-Éloy-de-Gy,Saint-Éloy-de-Gy,18,Cher,24,Centre-Val de Loire
18207,ST FLORENT SUR CHER,18400,ST FLORENT SUR CHER,,46.9913946761,2.25409311039,207,,Saint-Florent-sur-Cher,Saint-Florent-sur-Cher,18,Cher,24,Centre-Val de Loire
18208,STE GEMME EN SANCERROIS,18240,STE GEMME EN SANCERROIS,,47.4003683857,2.82072264021,208,,Sainte-Gemme-en-Sancerrois,Sainte-Gemme-en-Sancerrois,18,Cher,24,Centre-Val de Loire
18209,ST GEORGES DE POISIEUX,18200,ST GEORGES DE POISIEUX,,46.6832008459,2.4809860973,209,,Saint-Georges-de-Poisieux,Saint-Georges-de-Poisieux,18,Cher,24,Centre-Val de Loire
18210,ST GEORGES SUR LA PREE,18100,ST GEORGES SUR LA PREE,,47.2199114874,1.94950200368,210,,Saint-Georges-sur-la-Prée,Saint-Georges-sur-la-Prée,18,Cher,24,Centre-Val de Loire
18211,ST GEORGES SUR MOULON,18110,ST GEORGES SUR MOULON,,47.1855306923,2.42096610291,211,,Saint-Georges-sur-Moulon,Saint-Georges-sur-Moulon,18,Cher,24,Centre-Val de Loire
18212,ST GERMAIN DES BOIS,18340,ST GERMAIN DES BOIS,,46.8892474462,2.45128555429,212,,Saint-Germain-des-Bois,Saint-Germain-des-Bois,18,Cher,24,Centre-Val de Loire
18213,ST GERMAIN DU PUY,18390,ST GERMAIN DU PUY,,47.1076641241,2.47889343421,213,,Saint-Germain-du-Puy,Saint-Germain-du-Puy,18,Cher,24,Centre-Val de Loire
18214,ST HILAIRE DE COURT,18100,ST HILAIRE DE COURT,,47.2008571175,2.00341879677,214,,Saint-Hilaire-de-Court,Saint-Hilaire-de-Court,18,Cher,24,Centre-Val de Loire
18215,ST HILAIRE DE GONDILLY,18320,ST HILAIRE DE GONDILLY,,47.0235769936,2.90253231971,215,,Saint-Hilaire-de-Gondilly,Saint-Hilaire-de-Gondilly,18,Cher,24,Centre-Val de Loire
18215,ST HILAIRE DE GONDILLY,18350,ST HILAIRE DE GONDILLY,ENTROIS,47.0235769936,2.90253231971,215,,Saint-Hilaire-de-Gondilly,Saint-Hilaire-de-Gondilly,18,Cher,24,Centre-Val de Loire
18216,ST HILAIRE EN LIGNIERES,18160,ST HILAIRE EN LIGNIERES,,46.7380344095,2.13022589921,216,,Saint-Hilaire-en-Lignières,Saint-Hilaire-en-Lignières,18,Cher,24,Centre-Val de Loire
18217,ST JEANVRIN,18370,ST JEANVRIN,,46.5964376118,2.2422951827,217,,Saint-Jeanvrin,Saint-Jeanvrin,18,Cher,24,Centre-Val de Loire
18218,ST JUST,18340,ST JUST,,46.9879440291,2.50186876753,218,,Saint-Just,Saint-Just,18,Cher,24,Centre-Val de Loire
18219,ST LAURENT,18330,ST LAURENT,,47.2418416122,2.18465127619,219,,Saint-Laurent,Saint-Laurent,18,Cher,24,Centre-Val de Loire
18220,ST LEGER LE PETIT,18140,ST LEGER LE PETIT,,47.1148472744,2.99914312574,220,,Saint-Léger-le-Petit,Saint-Léger-le-Petit,18,Cher,24,Centre-Val de Loire
18221,ST LOUP DES CHAUMES,18190,ST LOUP DES CHAUMES,,46.8257663512,2.38238392364,221,,Saint-Loup-des-Chaumes,Saint-Loup-des-Chaumes,18,Cher,24,Centre-Val de Loire
18222,STE LUNAISE,18340,STE LUNAISE,,46.914297356,2.35380751159,222,,Sainte-Lunaise,Sainte-Lunaise,18,Cher,24,Centre-Val de Loire
18223,ST MARTIN D AUXIGNY,18110,ST MARTIN D AUXIGNY,,47.2103197455,2.38165698999,223,,Saint-Martin-d'Auxigny,Saint-Martin-d'Auxigny,18,Cher,24,Centre-Val de Loire
18224,ST MARTIN DES CHAMPS,18140,ST MARTIN DES CHAMPS,,47.173174971,2.93500889323,224,,Saint-Martin-des-Champs,Saint-Martin-des-Champs,18,Cher,24,Centre-Val de Loire
18225,ST MAUR,18270,ST MAUR,,46.568652329,2.28797208225,225,,Saint-Maur,Saint-Maur,18,Cher,24,Centre-Val de Loire
18226,ST MICHEL DE VOLANGIS,18390,ST MICHEL DE VOLANGIS,,47.1432035311,2.48143699712,226,,Saint-Michel-de-Volangis,Saint-Michel-de-Volangis,18,Cher,24,Centre-Val de Loire
18227,STE MONTAINE,18700,STE MONTAINE,,47.5015231113,2.32162111568,227,,Sainte-Montaine,Sainte-Montaine,18,Cher,24,Centre-Val de Loire
18228,ST OUTRILLE,18310,ST OUTRILLE,,47.1395164801,1.81121677425,228,,Saint-Outrille,Saint-Outrille,18,Cher,24,Centre-Val de Loire
18229,ST PALAIS,18110,ST PALAIS,,47.2489222519,2.41081023513,229,,Saint-Palais,Saint-Palais,18,Cher,24,Centre-Val de Loire
18230,ST PIERRE LES BOIS,18170,ST PIERRE LES BOIS,,46.6716980525,2.28339234015,230,,Saint-Pierre-les-Bois,Saint-Pierre-les-Bois,18,Cher,24,Centre-Val de Loire
18231,ST PIERRE LES ETIEUX,18210,ST PIERRE LES ETIEUX,,46.7342983903,2.59346083362,231,,Saint-Pierre-les-Étieux,Saint-Pierre-les-Étieux,18,Cher,24,Centre-Val de Loire
18232,ST PRIEST LA MARCHE,18370,ST PRIEST LA MARCHE,,46.4490350158,2.18414967239,232,,Saint-Priest-la-Marche,Saint-Priest-la-Marche,18,Cher,24,Centre-Val de Loire
18233,ST SATUR,18300,ST SATUR,,47.3465019322,2.85418697553,233,,Saint-Satur,Saint-Satur,18,Cher,24,Centre-Val de Loire
18234,ST SATURNIN,18370,ST SATURNIN,,46.5062478492,2.24046753513,234,,Saint-Saturnin,Saint-Saturnin,18,Cher,24,Centre-Val de Loire
18235,STE SOLANGE,18220,STE SOLANGE,,47.1423943953,2.55094608842,235,,Sainte-Solange,Sainte-Solange,18,Cher,24,Centre-Val de Loire
18236,ST SYMPHORIEN,18190,ST SYMPHORIEN,,46.8074443579,2.3043522893,236,,Saint-Symphorien,Saint-Symphorien,18,Cher,24,Centre-Val de Loire
18237,STE THORETTE,18500,STE THORETTE,,47.0779345031,2.2069818556,237,,Sainte-Thorette,Sainte-Thorette,18,Cher,24,Centre-Val de Loire
18238,ST VITTE,18360,ST VITTE,,46.5392889664,2.52939957448,238,,Saint-Vitte,Saint-Vitte,18,Cher,24,Centre-Val de Loire
18239,SALIGNY LE VIF,18800,SALIGNY LE VIF,,47.0404678772,2.75315200181,239,,Saligny-le-Vif,Saligny-le-Vif,18,Cher,24,Centre-Val de Loire
18240,SANCERGUES,18140,SANCERGUES,,47.1626367318,2.89993573993,240,,Sancergues,Sancergues,18,Cher,24,Centre-Val de Loire
18241,SANCERRE,18300,SANCERRE,,47.3256994042,2.82156691385,241,,Sancerre,Sancerre,18,Cher,24,Centre-Val de Loire
18242,SANCOINS,18600,SANCOINS,,46.8245784906,2.91377950744,242,,Sancoins,Sancoins,18,Cher,24,Centre-Val de Loire
18243,SANTRANGES,18240,SANTRANGES,,47.5028938123,2.75871821581,243,,Santranges,Santranges,18,Cher,24,Centre-Val de Loire
18244,SAUGY,18290,SAUGY,,46.9677472143,2.11337504999,244,,Saugy,Saugy,18,Cher,24,Centre-Val de Loire
18245,SAULZAIS LE POTIER,18360,SAULZAIS LE POTIER,,46.6018523222,2.49199571585,245,,Saulzais-le-Potier,Saulzais-le-Potier,18,Cher,24,Centre-Val de Loire
18246,SAVIGNY EN SANCERRE,18240,SAVIGNY EN SANCERRE,,47.4437813454,2.7982040526,246,,Savigny-en-Sancerre,Savigny-en-Sancerre,18,Cher,24,Centre-Val de Loire
18247,SAVIGNY EN SEPTAINE,18390,SAVIGNY EN SEPTAINE,,47.0454926015,2.56818918732,247,,Savigny-en-Septaine,Savigny-en-Septaine,18,Cher,24,Centre-Val de Loire
18248,SENNECAY,18340,SENNECAY,,46.9523538586,2.43947669146,248,,Senneçay,Senneçay,18,Cher,24,Centre-Val de Loire
18249,SENS BEAUJEU,18300,SENS BEAUJEU,,47.334144202,2.68875381486,249,,Sens-Beaujeu,Sens-Beaujeu,18,Cher,24,Centre-Val de Loire
18250,SERRUELLES,18190,SERRUELLES,,46.8824777068,2.37986164458,250,,Serruelles,Serruelles,18,Cher,24,Centre-Val de Loire
18251,SEVRY,18140,SEVRY,,47.1381447281,2.82322289822,251,,Sévry,Sévry,18,Cher,24,Centre-Val de Loire
18252,SIDIAILLES,18270,SIDIAILLES,,46.5116849197,2.31463159762,252,,Sidiailles,Sidiailles,18,Cher,24,Centre-Val de Loire
18253,SOULANGIS,18220,SOULANGIS,,47.1798980762,2.51528453779,253,,Soulangis,Soulangis,18,Cher,24,Centre-Val de Loire
18254,SOYE EN SEPTAINE,18340,SOYE EN SEPTAINE,,47.0306784477,2.48782583308,254,,Soye-en-Septaine,Soye-en-Septaine,18,Cher,24,Centre-Val de Loire
18255,LE SUBDRAY,18570,LE SUBDRAY,,47.0183022938,2.30988777756,255,Le,Subdray,Le Subdray,18,Cher,24,Centre-Val de Loire
18256,SUBLIGNY,18260,SUBLIGNY,,47.3955907367,2.7523718933,256,,Subligny,Subligny,18,Cher,24,Centre-Val de Loire
18257,SURY PRES LERE,18240,SURY PRES LERE,,47.4832014247,2.84484460437,257,,Sury-près-Léré,Sury-près-Léré,18,Cher,24,Centre-Val de Loire
18258,SURY EN VAUX,18300,SURY EN VAUX,,47.3718751435,2.80397363416,258,,Sury-en-Vaux,Sury-en-Vaux,18,Cher,24,Centre-Val de Loire
18259,SURY ES BOIS,18260,SURY ES BOIS,,47.4600582347,2.71117919113,259,,Sury-ès-Bois,Sury-ès-Bois,18,Cher,24,Centre-Val de Loire
18260,TENDRON,18350,TENDRON,,46.9797256032,2.82004963594,260,,Tendron,Tendron,18,Cher,24,Centre-Val de Loire
18261,THAUMIERS,18210,THAUMIERS,,46.8218263799,2.67004544881,261,,Thaumiers,Thaumiers,18,Cher,24,Centre-Val de Loire
18262,THAUVENAY,18300,THAUVENAY,,47.3006910629,2.87323352744,262,,Thauvenay,Thauvenay,18,Cher,24,Centre-Val de Loire
18263,THENIOUX,18100,THENIOUX,,47.2616443934,1.93927302127,263,,Thénioux,Thénioux,18,Cher,24,Centre-Val de Loire
18264,THOU,18260,THOU,,47.4220683094,2.6720550629,264,,Thou,Thou,18,Cher,24,Centre-Val de Loire
18265,TORTERON,18320,TORTERON,,47.009472692,2.98349703012,265,,Torteron,Torteron,18,Cher,24,Centre-Val de Loire
18266,TOUCHAY,18160,TOUCHAY,,46.7151900372,2.21019388747,266,,Touchay,Touchay,18,Cher,24,Centre-Val de Loire
18267,TROUY,18570,TROUY,,47.0200069929,2.36619290348,267,,Trouy,Trouy,18,Cher,24,Centre-Val de Loire
18268,UZAY LE VENON,18190,UZAY LE VENON,,46.8241835073,2.45690051792,268,,Uzay-le-Venon,Uzay-le-Venon,18,Cher,24,Centre-Val de Loire
18269,VAILLY SUR SAULDRE,18260,VAILLY SUR SAULDRE,,47.4563676608,2.65132659906,269,,Vailly-sur-Sauldre,Vailly-sur-Sauldre,18,Cher,24,Centre-Val de Loire
18270,VALLENAY,18190,VALLENAY,,46.7727933629,2.37854027909,270,,Vallenay,Vallenay,18,Cher,24,Centre-Val de Loire
18270,VALLENAY,18190,VALLENAY,BIGNY VALLENAY,46.7727933629,2.37854027909,270,,Vallenay,Vallenay,18,Cher,24,Centre-Val de Loire
18271,VASSELAY,18110,VASSELAY,,47.1606916636,2.3864256936,271,,Vasselay,Vasselay,18,Cher,24,Centre-Val de Loire
18272,VEAUGUES,18300,VEAUGUES,,47.2655815273,2.75561064588,272,,Veaugues,Veaugues,18,Cher,24,Centre-Val de Loire
18273,VENESMES,18190,VENESMES,,46.8470958613,2.28228826634,273,,Venesmes,Venesmes,18,Cher,24,Centre-Val de Loire
18274,VERDIGNY,18300,VERDIGNY,,47.3488262505,2.81132446593,274,,Verdigny,Verdigny,18,Cher,24,Centre-Val de Loire
18275,VEREAUX,18600,VEREAUX,,46.8743707265,2.87737749635,275,,Vereaux,Vereaux,18,Cher,24,Centre-Val de Loire
18276,VERNAIS,18210,VERNAIS,,46.7670157945,2.70878494845,276,,Vernais,Vernais,18,Cher,24,Centre-Val de Loire
18277,VERNEUIL,18210,VERNEUIL,,46.8209400192,2.60143793073,277,,Verneuil,Verneuil,18,Cher,24,Centre-Val de Loire
18278,VESDUN,18360,VESDUN,,46.5533487781,2.43911843304,278,,Vesdun,Vesdun,18,Cher,24,Centre-Val de Loire
18279,VIERZON,18100,VIERZON,,47.2368836077,2.0790757049,279,,Vierzon,Vierzon,18,Cher,24,Centre-Val de Loire
18280,VIGNOUX SOUS LES AIX,18110,VIGNOUX SOUS LES AIX,,47.1836612099,2.46965607598,280,,Vignoux-sous-les-Aix,Vignoux-sous-les-Aix,18,Cher,24,Centre-Val de Loire
18281,VIGNOUX SUR BARANGEON,18500,VIGNOUX SUR BARANGEON,,47.2080522932,2.18031155899,281,,Vignoux-sur-Barangeon,Vignoux-sur-Barangeon,18,Cher,24,Centre-Val de Loire
18282,VILLABON,18800,VILLABON,,47.1024871236,2.67863881659,282,,Villabon,Villabon,18,Cher,24,Centre-Val de Loire
18283,VILLECELIN,18160,VILLECELIN,,46.8208130106,2.19519297741,283,,Villecelin,Villecelin,18,Cher,24,Centre-Val de Loire
18284,VILLEGENON,18260,VILLEGENON,,47.4175834406,2.59936995832,284,,Villegenon,Villegenon,18,Cher,24,Centre-Val de Loire
18285,VILLENEUVE SUR CHER,18400,VILLENEUVE SUR CHER,,47.0279195347,2.223362071,285,,Villeneuve-sur-Cher,Villeneuve-sur-Cher,18,Cher,24,Centre-Val de Loire
18286,VILLEQUIERS,18800,VILLEQUIERS,,47.0782048668,2.79121093343,286,,Villequiers,Villequiers,18,Cher,24,Centre-Val de Loire
18287,VINON,18300,VINON,,47.2824813545,2.83212165304,287,,Vinon,Vinon,18,Cher,24,Centre-Val de Loire
18288,VORLY,18340,VORLY,,46.9399216057,2.4731491323,288,,Vorly,Vorly,18,Cher,24,Centre-Val de Loire
18289,VORNAY,18130,VORNAY,,46.9659233978,2.59474797116,289,,Vornay,Vornay,18,Cher,24,Centre-Val de Loire
18290,VOUZERON,18330,VOUZERON,,47.2723604119,2.21589340689,290,,Vouzeron,Vouzeron,18,Cher,24,Centre-Val de Loire
19001,AFFIEUX,19260,AFFIEUX,,45.5079626044,1.76751292983,1,,Affieux,Affieux,19,Corrèze,75,Nouvelle-Aquitaine
19002,AIX,19200,AIX,,45.6131404682,2.39218552695,2,,Aix,Aix,19,Corrèze,75,Nouvelle-Aquitaine
19003,ALBIGNAC,19190,ALBIGNAC,,45.147448938,1.67744680624,3,,Albignac,Albignac,19,Corrèze,75,Nouvelle-Aquitaine
19004,ALBUSSAC,19380,ALBUSSAC,,45.1413289444,1.8139819421,4,,Albussac,Albussac,19,Corrèze,75,Nouvelle-Aquitaine
19005,ALLASSAC,19240,ALLASSAC,,45.2556220149,1.46918061552,5,,Allassac,Allassac,19,Corrèze,75,Nouvelle-Aquitaine
19006,ALLEYRAT,19200,ALLEYRAT,,45.5636726085,2.21836208439,6,,Alleyrat,Alleyrat,19,Corrèze,75,Nouvelle-Aquitaine
19007,ALTILLAC,19120,ALTILLAC,,44.9804416467,1.86889705524,7,,Altillac,Altillac,19,Corrèze,75,Nouvelle-Aquitaine
19008,AMBRUGEAT,19250,AMBRUGEAT,,45.5322152367,2.0852662893,8,,Ambrugeat,Ambrugeat,19,Corrèze,75,Nouvelle-Aquitaine
19009,LES ANGLES SUR CORREZE,19000,LES ANGLES SUR CORREZE,,45.3096037325,1.80359667854,9,Les,Angles-sur-Corrèze,Les Angles-sur-Corrèze,19,Corrèze,75,Nouvelle-Aquitaine
19010,ARGENTAT,19400,ARGENTAT,,45.1011667634,1.93436308377,10,,Argentat-sur-Dordogne,Argentat-sur-Dordogne,19,Corrèze,75,Nouvelle-Aquitaine
19011,ARNAC POMPADOUR,19230,ARNAC POMPADOUR,,45.410632637,1.36145818193,11,,Arnac-Pompadour,Arnac-Pompadour,19,Corrèze,75,Nouvelle-Aquitaine
19012,ASTAILLAC,19120,ASTAILLAC,,44.9515500002,1.83033967926,12,,Astaillac,Astaillac,19,Corrèze,75,Nouvelle-Aquitaine
19013,AUBAZINES,19190,AUBAZINES,,45.1857945286,1.68640722366,13,,Aubazines,Aubazines,19,Corrèze,75,Nouvelle-Aquitaine
19014,AURIAC,19220,AURIAC,,45.2002215851,2.13938146562,14,,Auriac,Auriac,19,Corrèze,75,Nouvelle-Aquitaine
19015,AYEN,19310,AYEN,,45.2543308546,1.32442831194,15,,Ayen,Ayen,19,Corrèze,75,Nouvelle-Aquitaine
19016,BAR,19800,BAR,,45.3421541864,1.81469639235,16,,Bar,Bar,19,Corrèze,75,Nouvelle-Aquitaine
19017,BASSIGNAC LE BAS,19430,BASSIGNAC LE BAS,,45.0251375943,1.86876602188,17,,Bassignac-le-Bas,Bassignac-le-Bas,19,Corrèze,75,Nouvelle-Aquitaine
19018,BASSIGNAC LE HAUT,19220,BASSIGNAC LE HAUT,,45.1996282807,2.06735382659,18,,Bassignac-le-Haut,Bassignac-le-Haut,19,Corrèze,75,Nouvelle-Aquitaine
19019,BEAULIEU SUR DORDOGNE,19120,BEAULIEU SUR DORDOGNE,,44.9808198297,1.82767287912,19,,Beaulieu-sur-Dordogne,Beaulieu-sur-Dordogne,19,Corrèze,75,Nouvelle-Aquitaine
19020,BEAUMONT,19390,BEAUMONT,,45.4239866507,1.79091632858,20,,Beaumont,Beaumont,19,Corrèze,75,Nouvelle-Aquitaine
19021,BELLECHASSAGNE,19290,BELLECHASSAGNE,,45.6486687844,2.22209059554,21,,Bellechassagne,Bellechassagne,19,Corrèze,75,Nouvelle-Aquitaine
19022,BENAYES,19510,BENAYES,,45.5230685233,1.46845374515,22,,Benayes,Benayes,19,Corrèze,75,Nouvelle-Aquitaine
19023,BEYNAT,19190,BEYNAT,,45.1315842922,1.7298060186,23,,Beynat,Beynat,19,Corrèze,75,Nouvelle-Aquitaine
19024,BEYSSAC,19230,BEYSSAC,,45.368379997,1.41904693308,24,,Beyssac,Beyssac,19,Corrèze,75,Nouvelle-Aquitaine
19025,BEYSSENAC,19230,BEYSSENAC,,45.4022168067,1.30367937423,25,,Beyssenac,Beyssenac,19,Corrèze,75,Nouvelle-Aquitaine
19026,BILHAC,19120,BILHAC,,44.9419839173,1.77725937607,26,,Bilhac,Bilhac,19,Corrèze,75,Nouvelle-Aquitaine
19027,BONNEFOND,19170,BONNEFOND,,45.5259255035,1.9864406371,27,,Bonnefond,Bonnefond,19,Corrèze,75,Nouvelle-Aquitaine
19028,BORT LES ORGUES,19110,BORT LES ORGUES,,45.3932255345,2.49171563604,28,,Bort-les-Orgues,Bort-les-Orgues,19,Corrèze,75,Nouvelle-Aquitaine
19029,BRANCEILLES,19500,BRANCEILLES,,45.0080597171,1.708482812,29,,Branceilles,Branceilles,19,Corrèze,75,Nouvelle-Aquitaine
19030,BRIGNAC LA PLAINE,19310,BRIGNAC LA PLAINE,,45.1769208427,1.33285501672,30,,Brignac-la-Plaine,Brignac-la-Plaine,19,Corrèze,75,Nouvelle-Aquitaine
19031,BRIVE LA GAILLARDE,19100,BRIVE LA GAILLARDE,,45.1435830664,1.51936836063,31,,Brive-la-Gaillarde,Brive-la-Gaillarde,19,Corrèze,75,Nouvelle-Aquitaine
19032,BRIVEZAC,19120,BRIVEZAC,,45.0208648286,1.83761486045,32,,Brivezac,Brivezac,19,Corrèze,75,Nouvelle-Aquitaine
19033,BUGEAT,19170,BUGEAT,,45.5900445661,1.93002669773,33,,Bugeat,Bugeat,19,Corrèze,75,Nouvelle-Aquitaine
19034,CAMPS ST MATHURIN LEOBAZEL,19430,CAMPS ST MATHURIN LEOBAZEL,,44.9925849683,1.99504075347,34,,Camps-Saint-Mathurin-Léobazel,Camps-Saint-Mathurin-Léobazel,19,Corrèze,75,Nouvelle-Aquitaine
19034,CAMPS ST MATHURIN LEOBAZEL,19430,CAMPS ST MATHURIN LEOBAZEL,ST MATHURIN LEOBAZEL,44.9925849683,1.99504075347,34,,Camps-Saint-Mathurin-Léobazel,Camps-Saint-Mathurin-Léobazel,19,Corrèze,75,Nouvelle-Aquitaine
19035,CHABRIGNAC,19350,CHABRIGNAC,,45.3140230281,1.34219381764,35,,Chabrignac,Chabrignac,19,Corrèze,75,Nouvelle-Aquitaine
19036,CHAMBERET,19370,CHAMBERET,,45.6025012498,1.71951080332,36,,Chamberet,Chamberet,19,Corrèze,75,Nouvelle-Aquitaine
19037,CHAMBOULIVE,19450,CHAMBOULIVE,,45.4270783097,1.70936524222,37,,Chamboulive,Chamboulive,19,Corrèze,75,Nouvelle-Aquitaine
19038,CHAMEYRAT,19330,CHAMEYRAT,,45.2478873538,1.70626007972,38,,Chameyrat,Chameyrat,19,Corrèze,75,Nouvelle-Aquitaine
19039,CHAMPAGNAC LA NOAILLE,19320,CHAMPAGNAC LA NOAILLE,,45.3065974134,1.99824201855,39,,Champagnac-la-Noaille,Champagnac-la-Noaille,19,Corrèze,75,Nouvelle-Aquitaine
19040,CHAMPAGNAC LA PRUNE,19320,CHAMPAGNAC LA PRUNE,,45.1894490228,1.93781703813,40,,Champagnac-la-Prune,Champagnac-la-Prune,19,Corrèze,75,Nouvelle-Aquitaine
19041,CHANAC LES MINES,19150,CHANAC LES MINES,,45.2703621999,1.81731506278,41,,Chanac-les-Mines,Chanac-les-Mines,19,Corrèze,75,Nouvelle-Aquitaine
19042,CHANTEIX,19330,CHANTEIX,,45.3124205901,1.6226623783,42,,Chanteix,Chanteix,19,Corrèze,75,Nouvelle-Aquitaine
19043,LA CHAPELLE AUX BROCS,19360,LA CHAPELLE AUX BROCS,,45.1437616861,1.62661152472,43,La,Chapelle-aux-Brocs,La Chapelle-aux-Brocs,19,Corrèze,75,Nouvelle-Aquitaine
19044,LA CHAPELLE AUX SAINTS,19120,LA CHAPELLE AUX SAINTS,,44.9831181613,1.71908951558,44,La,Chapelle-aux-Saints,La Chapelle-aux-Saints,19,Corrèze,75,Nouvelle-Aquitaine
19045,LA CHAPELLE ST GERAUD,19430,LA CHAPELLE ST GERAUD,,45.0490483837,1.94780274708,45,La,Chapelle-Saint-Géraud,La Chapelle-Saint-Géraud,19,Corrèze,75,Nouvelle-Aquitaine
19046,CHAPELLE SPINASSE,19300,CHAPELLE SPINASSE,,45.35845627,2.05247239518,46,,Chapelle-Spinasse,Chapelle-Spinasse,19,Corrèze,75,Nouvelle-Aquitaine
19047,CHARTRIER FERRIERE,19600,CHARTRIER FERRIERE,,45.0582166872,1.4341213591,47,,Chartrier-Ferrière,Chartrier-Ferrière,19,Corrèze,75,Nouvelle-Aquitaine
19048,LE CHASTANG,19190,LE CHASTANG,,45.1752926501,1.73283876352,48,Le,Chastang,Le Chastang,19,Corrèze,75,Nouvelle-Aquitaine
19049,CHASTEAUX,19600,CHASTEAUX,,45.0828146271,1.46748876327,49,,Chasteaux,Chasteaux,19,Corrèze,75,Nouvelle-Aquitaine
19050,CHAUFFOUR SUR VELL,19500,CHAUFFOUR SUR VELL,,45.0194374555,1.67141752339,50,,Chauffour-sur-Vell,Chauffour-sur-Vell,19,Corrèze,75,Nouvelle-Aquitaine
19051,CHAUMEIL,19390,CHAUMEIL,,45.4552902265,1.88020391469,51,,Chaumeil,Chaumeil,19,Corrèze,75,Nouvelle-Aquitaine
19052,CHAVANAC,19290,CHAVANAC,,45.6193925333,2.09923298827,52,,Chavanac,Chavanac,19,Corrèze,75,Nouvelle-Aquitaine
19053,CHAVEROCHE,19200,CHAVEROCHE,,45.56551046,2.25206611427,53,,Chaveroche,Chaveroche,19,Corrèze,75,Nouvelle-Aquitaine
19054,CHENAILLER MASCHEIX,19120,CHENAILLER MASCHEIX,,45.0608264132,1.82435897673,54,,Chenailler-Mascheix,Chenailler-Mascheix,19,Corrèze,75,Nouvelle-Aquitaine
19055,CHIRAC BELLEVUE,19160,CHIRAC BELLEVUE,,45.4528560123,2.30803671856,55,,Chirac-Bellevue,Chirac-Bellevue,19,Corrèze,75,Nouvelle-Aquitaine
19056,CLERGOUX,19320,CLERGOUX,,45.2759455396,1.95673708604,56,,Clergoux,Clergoux,19,Corrèze,75,Nouvelle-Aquitaine
19057,COLLONGES LA ROUGE,19500,COLLONGES LA ROUGE,,45.0661814484,1.65335823441,57,,Collonges-la-Rouge,Collonges-la-Rouge,19,Corrèze,75,Nouvelle-Aquitaine
19058,COMBRESSOL,19250,COMBRESSOL,,45.4687976652,2.16513566417,58,,Combressol,Combressol,19,Corrèze,75,Nouvelle-Aquitaine
19059,CONCEZE,19350,CONCEZE,,45.3604032352,1.33771562463,59,,Concèze,Concèze,19,Corrèze,75,Nouvelle-Aquitaine
19060,CONDAT SUR GANAVEIX,19140,CONDAT SUR GANAVEIX,,45.4824773803,1.59889305142,60,,Condat-sur-Ganaveix,Condat-sur-Ganaveix,19,Corrèze,75,Nouvelle-Aquitaine
19061,CORNIL,19150,CORNIL,,45.2137162961,1.69660747239,61,,Cornil,Cornil,19,Corrèze,75,Nouvelle-Aquitaine
19062,CORREZE,19800,CORREZE,,45.3655110046,1.87090523159,62,,Corrèze,Corrèze,19,Corrèze,75,Nouvelle-Aquitaine
19063,COSNAC,19360,COSNAC,,45.1307416236,1.58601909494,63,,Cosnac,Cosnac,19,Corrèze,75,Nouvelle-Aquitaine
19064,COUFFY SUR SARSONNE,19340,COUFFY SUR SARSONNE,,45.6719053866,2.34459730076,64,,Couffy-sur-Sarsonne,Couffy-sur-Sarsonne,19,Corrèze,75,Nouvelle-Aquitaine
19065,COURTEIX,19340,COURTEIX,,45.6460610624,2.34358593599,65,,Courteix,Courteix,19,Corrèze,75,Nouvelle-Aquitaine
19066,CUBLAC,19520,CUBLAC,,45.1562309473,1.30589609182,66,,Cublac,Cublac,19,Corrèze,75,Nouvelle-Aquitaine
19067,CUREMONTE,19500,CUREMONTE,,45.0035299076,1.75465208697,67,,Curemonte,Curemonte,19,Corrèze,75,Nouvelle-Aquitaine
19068,DAMPNIAT,19360,DAMPNIAT,,45.1638501631,1.63821621038,68,,Dampniat,Dampniat,19,Corrèze,75,Nouvelle-Aquitaine
19069,DARAZAC,19220,DARAZAC,,45.1741698919,2.0813262625,69,,Darazac,Darazac,19,Corrèze,75,Nouvelle-Aquitaine
19070,DARNETS,19300,DARNETS,,45.4241971768,2.11309756046,70,,Darnets,Darnets,19,Corrèze,75,Nouvelle-Aquitaine
19071,DAVIGNAC,19250,DAVIGNAC,,45.4911899589,2.07587737304,71,,Davignac,Davignac,19,Corrèze,75,Nouvelle-Aquitaine
19072,DONZENAC,19270,DONZENAC,,45.23129159,1.52635846524,72,,Donzenac,Donzenac,19,Corrèze,75,Nouvelle-Aquitaine
19073,EGLETONS,19300,EGLETONS,,45.4216623668,2.03629867165,73,,Égletons,Égletons,19,Corrèze,75,Nouvelle-Aquitaine
19074,L EGLISE AUX BOIS,19170,L EGLISE AUX BOIS,,45.657582706,1.7964084025,74,L',Église-aux-Bois,L'Église-aux-Bois,19,Corrèze,75,Nouvelle-Aquitaine
19075,ESPAGNAC,19150,ESPAGNAC,,45.2408229294,1.89882225318,75,,Espagnac,Espagnac,19,Corrèze,75,Nouvelle-Aquitaine
19076,ESPARTIGNAC,19140,ESPARTIGNAC,,45.3992793069,1.5950172659,76,,Espartignac,Espartignac,19,Corrèze,75,Nouvelle-Aquitaine
19077,ESTIVALS,19600,ESTIVALS,,45.0308663151,1.45856989292,77,,Estivals,Estivals,19,Corrèze,75,Nouvelle-Aquitaine
19078,ESTIVAUX,19410,ESTIVAUX,,45.318970397,1.49504198912,78,,Estivaux,Estivaux,19,Corrèze,75,Nouvelle-Aquitaine
19079,EYBURIE,19140,EYBURIE,,45.4690737187,1.64470023601,79,,Eyburie,Eyburie,19,Corrèze,75,Nouvelle-Aquitaine
19080,EYGURANDE,19340,EYGURANDE,,45.6767881141,2.42408510368,80,,Eygurande,Eygurande,19,Corrèze,75,Nouvelle-Aquitaine
19081,EYREIN,19800,EYREIN,,45.3273225675,1.94274800441,81,,Eyrein,Eyrein,19,Corrèze,75,Nouvelle-Aquitaine
19082,FAVARS,19330,FAVARS,,45.2632599727,1.67035512416,82,,Favars,Favars,19,Corrèze,75,Nouvelle-Aquitaine
19083,FEYT,19340,FEYT,,45.6844902907,2.47902463461,83,,Feyt,Feyt,19,Corrèze,75,Nouvelle-Aquitaine
19084,FORGES,19380,FORGES,,45.1588528226,1.86527330178,84,,Forgès,Forgès,19,Corrèze,75,Nouvelle-Aquitaine
19085,GIMEL LES CASCADES,19800,GIMEL LES CASCADES,,45.3041231992,1.84214988096,85,,Gimel-les-Cascades,Gimel-les-Cascades,19,Corrèze,75,Nouvelle-Aquitaine
19086,GOULLES,19430,GOULLES,,45.0195641507,2.09352892736,86,,Goulles,Goulles,19,Corrèze,75,Nouvelle-Aquitaine
19087,GOURDON MURAT,19170,GOURDON MURAT,,45.5473595192,1.9000182869,87,,Gourdon-Murat,Gourdon-Murat,19,Corrèze,75,Nouvelle-Aquitaine
19088,GRANDSAIGNE,19300,GRANDSAIGNE,,45.487529142,1.93017010413,88,,Grandsaigne,Grandsaigne,19,Corrèze,75,Nouvelle-Aquitaine
19089,GROS CHASTANG,19320,GROS CHASTANG,,45.21473166,2.00326104775,89,,Gros-Chastang,Gros-Chastang,19,Corrèze,75,Nouvelle-Aquitaine
19090,GUMOND,19320,GUMOND,,45.2198203568,1.96223391046,90,,Gumond,Gumond,19,Corrèze,75,Nouvelle-Aquitaine
19091,HAUTEFAGE,19400,HAUTEFAGE,,45.0916740521,1.9924978016,91,,Hautefage,Hautefage,19,Corrèze,75,Nouvelle-Aquitaine
19092,LE JARDIN,19300,LE JARDIN,,45.3210874234,2.05026860148,92,Le,Jardin,Le Jardin,19,Corrèze,75,Nouvelle-Aquitaine
19093,JUGEALS NAZARETH,19500,JUGEALS NAZARETH,,45.0889828477,1.55455491162,93,,Jugeals-Nazareth,Jugeals-Nazareth,19,Corrèze,75,Nouvelle-Aquitaine
19094,JUILLAC,19350,JUILLAC,,45.3226150546,1.29232118318,94,,Juillac,Juillac,19,Corrèze,75,Nouvelle-Aquitaine
19095,LACELLE,19170,LACELLE,,45.6427861496,1.83264328389,95,,Lacelle,Lacelle,19,Corrèze,75,Nouvelle-Aquitaine
19096,LADIGNAC SUR RONDELLES,19150,LADIGNAC SUR RONDELLES,,45.2302018148,1.82480345617,96,,Ladignac-sur-Rondelles,Ladignac-sur-Rondelles,19,Corrèze,75,Nouvelle-Aquitaine
19097,LAFAGE SUR SOMBRE,19320,LAFAGE SUR SOMBRE,,45.2926824126,2.07691880797,97,,Lafage-sur-Sombre,Lafage-sur-Sombre,19,Corrèze,75,Nouvelle-Aquitaine
19098,LAGARDE ENVAL,19150,LAGARDE ENVAL,,45.1855958017,1.81780911509,98,,Lagarde-Enval,Lagarde-Enval,19,Corrèze,75,Nouvelle-Aquitaine
19099,LAGLEYGEOLLE,19500,LAGLEYGEOLLE,,45.084341553,1.69557036344,99,,Lagleygeolle,Lagleygeolle,19,Corrèze,75,Nouvelle-Aquitaine
19100,LAGRAULIERE,19700,LAGRAULIERE,,45.3552708977,1.62385425403,100,,Lagraulière,Lagraulière,19,Corrèze,75,Nouvelle-Aquitaine
19101,LAGUENNE,19150,LAGUENNE,,45.2402545634,1.78668276464,101,,Laguenne,Laguenne,19,Corrèze,75,Nouvelle-Aquitaine
19102,LAMAZIERE BASSE,19160,LAMAZIERE BASSE,,45.3730034504,2.17148208461,102,,Lamazière-Basse,Lamazière-Basse,19,Corrèze,75,Nouvelle-Aquitaine
19103,LAMAZIERE HAUTE,19340,LAMAZIERE HAUTE,,45.6754049017,2.38584451894,103,,Lamazière-Haute,Lamazière-Haute,19,Corrèze,75,Nouvelle-Aquitaine
19104,LAMONGERIE,19510,LAMONGERIE,,45.540833083,1.58011081843,104,,Lamongerie,Lamongerie,19,Corrèze,75,Nouvelle-Aquitaine
19105,LANTEUIL,19190,LANTEUIL,,45.1177049487,1.64002369199,105,,Lanteuil,Lanteuil,19,Corrèze,75,Nouvelle-Aquitaine
19106,LAPLEAU,19550,LAPLEAU,,45.2972681387,2.15927130911,106,,Lapleau,Lapleau,19,Corrèze,75,Nouvelle-Aquitaine
19107,LARCHE,19600,LARCHE,,45.1137863095,1.41998991347,107,,Larche,Larche,19,Corrèze,75,Nouvelle-Aquitaine
19108,LAROCHE PRES FEYT,19340,LAROCHE PRES FEYT,,45.7066868401,2.4992746244,108,,Laroche-près-Feyt,Laroche-près-Feyt,19,Corrèze,75,Nouvelle-Aquitaine
19109,LASCAUX,19130,LASCAUX,,45.3405090712,1.37203323389,109,,Lascaux,Lascaux,19,Corrèze,75,Nouvelle-Aquitaine
19110,LATRONCHE,19160,LATRONCHE,,45.3080469107,2.2400154033,110,,Latronche,Latronche,19,Corrèze,75,Nouvelle-Aquitaine
19111,LAVAL SUR LUZEGE,19550,LAVAL SUR LUZEGE,,45.2541335786,2.14246404167,111,,Laval-sur-Luzège,Laval-sur-Luzège,19,Corrèze,75,Nouvelle-Aquitaine
19112,LESTARDS,19170,LESTARDS,,45.5412768254,1.86439774185,112,,Lestards,Lestards,19,Corrèze,75,Nouvelle-Aquitaine
19113,LIGINIAC,19160,LIGINIAC,,45.405035846,2.32343781175,113,,Liginiac,Liginiac,19,Corrèze,75,Nouvelle-Aquitaine
19114,LIGNAREIX,19200,LIGNAREIX,,45.6137197457,2.30195936647,114,,Lignareix,Lignareix,19,Corrèze,75,Nouvelle-Aquitaine
19115,LIGNEYRAC,19500,LIGNEYRAC,,45.0492268727,1.61825978828,115,,Ligneyrac,Ligneyrac,19,Corrèze,75,Nouvelle-Aquitaine
19116,LIOURDRES,19120,LIOURDRES,,44.9374662553,1.80814651147,116,,Liourdres,Liourdres,19,Corrèze,75,Nouvelle-Aquitaine
19117,LISSAC SUR COUZE,19600,LISSAC SUR COUZE,,45.1101297922,1.45969694671,117,,Lissac-sur-Couze,Lissac-sur-Couze,19,Corrèze,75,Nouvelle-Aquitaine
19118,LE LONZAC,19470,LE LONZAC,,45.4699663224,1.73115503451,118,Le,Lonzac,Le Lonzac,19,Corrèze,75,Nouvelle-Aquitaine
19119,LOSTANGES,19500,LOSTANGES,,45.0685363268,1.77496658652,119,,Lostanges,Lostanges,19,Corrèze,75,Nouvelle-Aquitaine
19120,LOUIGNAC,19310,LOUIGNAC,,45.2171199764,1.27073739721,120,,Louignac,Louignac,19,Corrèze,75,Nouvelle-Aquitaine
19121,LUBERSAC,19210,LUBERSAC,,45.454027392,1.40027554352,121,,Lubersac,Lubersac,19,Corrèze,75,Nouvelle-Aquitaine
19122,MADRANGES,19470,MADRANGES,,45.4674850296,1.79655382411,122,,Madranges,Madranges,19,Corrèze,75,Nouvelle-Aquitaine
19123,MALEMORT,19360,MALEMORT,,45.176099789,1.58386372231,123,,Malemort,Malemort,19,Corrèze,75,Nouvelle-Aquitaine
19123,MALEMORT,19360,MALEMORT,VENARSAL,45.176099789,1.58386372231,123,,Malemort,Malemort,19,Corrèze,75,Nouvelle-Aquitaine
19124,MANSAC,19520,MANSAC,,45.1603327019,1.38223903365,124,,Mansac,Mansac,19,Corrèze,75,Nouvelle-Aquitaine
19125,MARCILLAC LA CROISILLE,19320,MARCILLAC LA CROISILLE,,45.2528826231,2.0341111396,125,,Marcillac-la-Croisille,Marcillac-la-Croisille,19,Corrèze,75,Nouvelle-Aquitaine
19126,MARCILLAC LA CROZE,19500,MARCILLAC LA CROZE,,45.0331846728,1.73789456774,126,,Marcillac-la-Croze,Marcillac-la-Croze,19,Corrèze,75,Nouvelle-Aquitaine
19127,MARC LA TOUR,19150,MARC LA TOUR,,45.1977261458,1.85210029305,127,,Marc-la-Tour,Marc-la-Tour,19,Corrèze,75,Nouvelle-Aquitaine
19128,MARGERIDES,19200,MARGERIDES,,45.4564248108,2.41560570074,128,,Margerides,Margerides,19,Corrèze,75,Nouvelle-Aquitaine
19129,MASSERET,19510,MASSERET,,45.5414714695,1.51966936931,129,,Masseret,Masseret,19,Corrèze,75,Nouvelle-Aquitaine
19130,MAUSSAC,19250,MAUSSAC,,45.4704291606,2.12678268861,130,,Maussac,Maussac,19,Corrèze,75,Nouvelle-Aquitaine
19131,MEILHARDS,19510,MEILHARDS,,45.5471107848,1.63652060761,131,,Meilhards,Meilhards,19,Corrèze,75,Nouvelle-Aquitaine
19132,MENOIRE,19190,MENOIRE,,45.1054780025,1.79311643807,132,,Ménoire,Ménoire,19,Corrèze,75,Nouvelle-Aquitaine
19133,MERCOEUR,19430,MERCOEUR,,45.0115300882,1.95247311189,133,,Mercœur,Mercœur,19,Corrèze,75,Nouvelle-Aquitaine
19134,MERLINES,19340,MERLINES,,45.6253424705,2.45844193074,134,,Merlines,Merlines,19,Corrèze,75,Nouvelle-Aquitaine
19135,MESTES,19200,MESTES,,45.4933738169,2.31754187362,135,,Mestes,Mestes,19,Corrèze,75,Nouvelle-Aquitaine
19136,MEYMAC,19250,MEYMAC,,45.5542315824,2.14009183651,136,,Meymac,Meymac,19,Corrèze,75,Nouvelle-Aquitaine
19137,MEYRIGNAC L EGLISE,19800,MEYRIGNAC L EGLISE,,45.4036167689,1.86043218765,137,,Meyrignac-l'Église,Meyrignac-l'Église,19,Corrèze,75,Nouvelle-Aquitaine
19138,MEYSSAC,19500,MEYSSAC,,45.0503878402,1.68376616908,138,,Meyssac,Meyssac,19,Corrèze,75,Nouvelle-Aquitaine
19139,MILLEVACHES,19290,MILLEVACHES,,45.6456743847,2.08926438394,139,,Millevaches,Millevaches,19,Corrèze,75,Nouvelle-Aquitaine
19140,MONCEAUX SUR DORDOGNE,19400,MONCEAUX SUR DORDOGNE,,45.07174218,1.88908252368,140,,Monceaux-sur-Dordogne,Monceaux-sur-Dordogne,19,Corrèze,75,Nouvelle-Aquitaine
19141,MONESTIER MERLINES,19340,MONESTIER MERLINES,,45.6502055059,2.48933845927,141,,Monestier-Merlines,Monestier-Merlines,19,Corrèze,75,Nouvelle-Aquitaine
19142,MONESTIER PORT DIEU,19110,MONESTIER PORT DIEU,,45.496503482,2.48981642145,142,,Monestier-Port-Dieu,Monestier-Port-Dieu,19,Corrèze,75,Nouvelle-Aquitaine
19143,MONTAIGNAC ST HIPPOLYTE,19300,MONTAIGNAC ST HIPPOLYTE,,45.3462288973,2.01582895294,143,,Montaignac-Saint-Hippolyte,Montaignac-Saint-Hippolyte,19,Corrèze,75,Nouvelle-Aquitaine
19144,MONTGIBAUD,19210,MONTGIBAUD,,45.5088660524,1.4212214212,144,,Montgibaud,Montgibaud,19,Corrèze,75,Nouvelle-Aquitaine
19145,MOUSTIER VENTADOUR,19300,MOUSTIER VENTADOUR,,45.3794049102,2.10092572655,145,,Moustier-Ventadour,Moustier-Ventadour,19,Corrèze,75,Nouvelle-Aquitaine
19146,NAVES,19460,NAVES,,45.3149479371,1.75065002598,146,,Naves,Naves,19,Corrèze,75,Nouvelle-Aquitaine
19147,NESPOULS,19600,NESPOULS,,45.0563342157,1.50252648148,147,,Nespouls,Nespouls,19,Corrèze,75,Nouvelle-Aquitaine
19148,NEUVIC,19160,NEUVIC,,45.3714171238,2.25865560062,148,,Neuvic,Neuvic,19,Corrèze,75,Nouvelle-Aquitaine
19149,NEUVILLE,19380,NEUVILLE,,45.1095698093,1.84454335261,149,,Neuville,Neuville,19,Corrèze,75,Nouvelle-Aquitaine
19150,NOAILHAC,19500,NOAILHAC,,45.0827332005,1.61660799829,150,,Noailhac,Noailhac,19,Corrèze,75,Nouvelle-Aquitaine
19151,NOAILLES,19600,NOAILLES,,45.0985911705,1.51852120349,151,,Noailles,Noailles,19,Corrèze,75,Nouvelle-Aquitaine
19152,NONARDS,19120,NONARDS,,45.0199014556,1.80919700303,152,,Nonards,Nonards,19,Corrèze,75,Nouvelle-Aquitaine
19153,OBJAT,19130,OBJAT,,45.2632333605,1.41287237115,153,,Objat,Objat,19,Corrèze,75,Nouvelle-Aquitaine
19154,ORGNAC SUR VEZERE,19410,ORGNAC SUR VEZERE,,45.3405611146,1.46183907103,154,,Orgnac-sur-Vézère,Orgnac-sur-Vézère,19,Corrèze,75,Nouvelle-Aquitaine
19155,ORLIAC DE BAR,19390,ORLIAC DE BAR,,45.3747450967,1.80540631183,155,,Orliac-de-Bar,Orliac-de-Bar,19,Corrèze,75,Nouvelle-Aquitaine
19156,PALAZINGES,19190,PALAZINGES,,45.1645183918,1.69754162578,156,,Palazinges,Palazinges,19,Corrèze,75,Nouvelle-Aquitaine
19157,PALISSE,19160,PALISSE,,45.4339391784,2.20721338065,157,,Palisse,Palisse,19,Corrèze,75,Nouvelle-Aquitaine
19158,PANDRIGNES,19150,PANDRIGNES,,45.2212791943,1.85894195894,158,,Pandrignes,Pandrignes,19,Corrèze,75,Nouvelle-Aquitaine
19159,PERET BEL AIR,19300,PERET BEL AIR,,45.4725257232,2.02530353299,159,,Péret-Bel-Air,Péret-Bel-Air,19,Corrèze,75,Nouvelle-Aquitaine
19160,PEROLS SUR VEZERE,19170,PEROLS SUR VEZERE,,45.5751012606,2.0039583591,160,,Pérols-sur-Vézère,Pérols-sur-Vézère,19,Corrèze,75,Nouvelle-Aquitaine
19161,PERPEZAC LE BLANC,19310,PERPEZAC LE BLANC,,45.2136295259,1.32740284551,161,,Perpezac-le-Blanc,Perpezac-le-Blanc,19,Corrèze,75,Nouvelle-Aquitaine
19162,PERPEZAC LE NOIR,19410,PERPEZAC LE NOIR,,45.3370896874,1.55340576295,162,,Perpezac-le-Noir,Perpezac-le-Noir,19,Corrèze,75,Nouvelle-Aquitaine
19163,LE PESCHER,19190,LE PESCHER,,45.0826622801,1.75970042346,163,Le,Pescher,Le Pescher,19,Corrèze,75,Nouvelle-Aquitaine
19164,PEYRELEVADE,19290,PEYRELEVADE,,45.7101794638,2.03734286848,164,,Peyrelevade,Peyrelevade,19,Corrèze,75,Nouvelle-Aquitaine
19165,PEYRISSAC,19260,PEYRISSAC,,45.5060455559,1.694117673,165,,Peyrissac,Peyrissac,19,Corrèze,75,Nouvelle-Aquitaine
19166,PIERREFITTE,19450,PIERREFITTE,,45.4278645906,1.65028178088,166,,Pierrefitte,Pierrefitte,19,Corrèze,75,Nouvelle-Aquitaine
19167,CONFOLENT PORT DIEU,19200,CONFOLENT PORT DIEU,,45.5364773325,2.49849624237,167,,Confolent-Port-Dieu,Confolent-Port-Dieu,19,Corrèze,75,Nouvelle-Aquitaine
19168,PRADINES,19170,PRADINES,,45.5113262378,1.90969127344,168,,Pradines,Pradines,19,Corrèze,75,Nouvelle-Aquitaine
19169,PUY D ARNAC,19120,PUY D ARNAC,,45.0254500765,1.77183790945,169,,Puy-d'Arnac,Puy-d'Arnac,19,Corrèze,75,Nouvelle-Aquitaine
19170,QUEYSSAC LES VIGNES,19120,QUEYSSAC LES VIGNES,,44.9644974999,1.76788116489,170,,Queyssac-les-Vignes,Queyssac-les-Vignes,19,Corrèze,75,Nouvelle-Aquitaine
19171,REYGADE,19430,REYGADE,,45.019999835,1.90382640556,171,,Reygade,Reygade,19,Corrèze,75,Nouvelle-Aquitaine
19172,RILHAC TREIGNAC,19260,RILHAC TREIGNAC,,45.5272915329,1.69112353827,172,,Rilhac-Treignac,Rilhac-Treignac,19,Corrèze,75,Nouvelle-Aquitaine
19173,RILHAC XAINTRIE,19220,RILHAC XAINTRIE,,45.1727059294,2.18984077677,173,,Rilhac-Xaintrie,Rilhac-Xaintrie,19,Corrèze,75,Nouvelle-Aquitaine
19174,LA ROCHE CANILLAC,19320,LA ROCHE CANILLAC,,45.1986182258,1.96353579663,174,La,Roche-Canillac,La Roche-Canillac,19,Corrèze,75,Nouvelle-Aquitaine
19175,ROCHE LE PEYROUX,19160,ROCHE LE PEYROUX,,45.4277118588,2.37982336368,175,,Roche-le-Peyroux,Roche-le-Peyroux,19,Corrèze,75,Nouvelle-Aquitaine
19176,ROSIERS D EGLETONS,19300,ROSIERS D EGLETONS,,45.3879504108,2.01084744458,176,,Rosiers-d'Égletons,Rosiers-d'Égletons,19,Corrèze,75,Nouvelle-Aquitaine
19177,ROSIERS DE JUILLAC,19350,ROSIERS DE JUILLAC,,45.2869415245,1.30817336532,177,,Rosiers-de-Juillac,Rosiers-de-Juillac,19,Corrèze,75,Nouvelle-Aquitaine
19178,SADROC,19270,SADROC,,45.274045175,1.54972416906,178,,Sadroc,Sadroc,19,Corrèze,75,Nouvelle-Aquitaine
19179,SAILLAC,19500,SAILLAC,,45.0401470821,1.6412703898,179,,Saillac,Saillac,19,Corrèze,75,Nouvelle-Aquitaine
19180,ST ANGEL,19200,ST ANGEL,,45.4941878032,2.23150721951,180,,Saint-Angel,Saint-Angel,19,Corrèze,75,Nouvelle-Aquitaine
19181,ST AUGUSTIN,19390,ST AUGUSTIN,,45.4330402984,1.83259259547,181,,Saint-Augustin,Saint-Augustin,19,Corrèze,75,Nouvelle-Aquitaine
19182,ST AULAIRE,19130,ST AULAIRE,,45.2363536459,1.38790567067,182,,Saint-Aulaire,Saint-Aulaire,19,Corrèze,75,Nouvelle-Aquitaine
19183,ST BAZILE DE LA ROCHE,19320,ST BAZILE DE LA ROCHE,,45.1514682942,1.95404126432,183,,Saint-Bazile-de-la-Roche,Saint-Bazile-de-la-Roche,19,Corrèze,75,Nouvelle-Aquitaine
19184,ST BAZILE DE MEYSSAC,19500,ST BAZILE DE MEYSSAC,,45.0541480503,1.72389447366,184,,Saint-Bazile-de-Meyssac,Saint-Bazile-de-Meyssac,19,Corrèze,75,Nouvelle-Aquitaine
19185,ST BONNET AVALOUZE,19150,ST BONNET AVALOUZE,,45.2527688715,1.82147086421,185,,Saint-Bonnet-Avalouze,Saint-Bonnet-Avalouze,19,Corrèze,75,Nouvelle-Aquitaine
19186,ST BONNET ELVERT,19380,ST BONNET ELVERT,,45.1580000269,1.9167435017,186,,Saint-Bonnet-Elvert,Saint-Bonnet-Elvert,19,Corrèze,75,Nouvelle-Aquitaine
19187,ST BONNET LA RIVIERE,19130,ST BONNET LA RIVIERE,,45.2931005483,1.35936979218,187,,Saint-Bonnet-la-Rivière,Saint-Bonnet-la-Rivière,19,Corrèze,75,Nouvelle-Aquitaine
19188,ST BONNET L ENFANTIER,19410,ST BONNET L ENFANTIER,,45.29973714,1.52644911921,188,,Saint-Bonnet-l'Enfantier,Saint-Bonnet-l'Enfantier,19,Corrèze,75,Nouvelle-Aquitaine
19189,ST BONNET LES TOURS DE MERLE,19430,ST BONNET LES TOURS DE MERLE,,45.0549433234,2.05205394933,189,,Saint-Bonnet-les-Tours-de-Merle,Saint-Bonnet-les-Tours-de-Merle,19,Corrèze,75,Nouvelle-Aquitaine
19190,ST BONNET PRES BORT,19200,ST BONNET PRES BORT,,45.506914894,2.4263926381,190,,Saint-Bonnet-près-Bort,Saint-Bonnet-près-Bort,19,Corrèze,75,Nouvelle-Aquitaine
19191,ST CERNIN DE LARCHE,19600,ST CERNIN DE LARCHE,,45.0919444107,1.41237097897,191,,Saint-Cernin-de-Larche,Saint-Cernin-de-Larche,19,Corrèze,75,Nouvelle-Aquitaine
19192,ST CHAMANT,19380,ST CHAMANT,,45.1266782862,1.89882074872,192,,Saint-Chamant,Saint-Chamant,19,Corrèze,75,Nouvelle-Aquitaine
19193,ST CIRGUES LA LOUTRE,19220,ST CIRGUES LA LOUTRE,,45.0892873396,2.09970697485,193,,Saint-Cirgues-la-Loutre,Saint-Cirgues-la-Loutre,19,Corrèze,75,Nouvelle-Aquitaine
19194,ST CLEMENT,19700,ST CLEMENT,,45.3308184051,1.68701999554,194,,Saint-Clément,Saint-Clément,19,Corrèze,75,Nouvelle-Aquitaine
19195,ST CYPRIEN,19130,ST CYPRIEN,,45.2509684533,1.35607241092,195,,Saint-Cyprien,Saint-Cyprien,19,Corrèze,75,Nouvelle-Aquitaine
19196,ST CYR LA ROCHE,19130,ST CYR LA ROCHE,,45.2792183518,1.38416762976,196,,Saint-Cyr-la-Roche,Saint-Cyr-la-Roche,19,Corrèze,75,Nouvelle-Aquitaine
19198,ST ELOY LES TUILERIES,19210,ST ELOY LES TUILERIES,,45.4551252981,1.27792315725,198,,Saint-Éloy-les-Tuileries,Saint-Éloy-les-Tuileries,19,Corrèze,75,Nouvelle-Aquitaine
19199,ST ETIENNE AUX CLOS,19200,ST ETIENNE AUX CLOS,,45.5661403766,2.44957915705,199,,Saint-Étienne-aux-Clos,Saint-Étienne-aux-Clos,19,Corrèze,75,Nouvelle-Aquitaine
19200,ST ETIENNE LA GENESTE,19160,ST ETIENNE LA GENESTE,,45.4506998857,2.34906404541,200,,Saint-Étienne-la-Geneste,Saint-Étienne-la-Geneste,19,Corrèze,75,Nouvelle-Aquitaine
19201,ST EXUPERY LES ROCHES,19200,ST EXUPERY LES ROCHES,,45.5112778828,2.36990833562,201,,Saint-Exupéry-les-Roches,Saint-Exupéry-les-Roches,19,Corrèze,75,Nouvelle-Aquitaine
19202,STE FEREOLE,19270,STE FEREOLE,,45.2269634196,1.58885948296,202,,Sainte-Féréole,Sainte-Féréole,19,Corrèze,75,Nouvelle-Aquitaine
19203,STE FORTUNADE,19490,STE FORTUNADE,,45.2069322003,1.76313688229,203,,Sainte-Fortunade,Sainte-Fortunade,19,Corrèze,75,Nouvelle-Aquitaine
19204,ST FREJOUX,19200,ST FREJOUX,,45.5556968719,2.37794414526,204,,Saint-Fréjoux,Saint-Fréjoux,19,Corrèze,75,Nouvelle-Aquitaine
19205,ST GENIEZ O MERLE,19220,ST GENIEZ O MERLE,,45.08248923,2.05890392935,205,,Saint-Geniez-ô-Merle,Saint-Geniez-ô-Merle,19,Corrèze,75,Nouvelle-Aquitaine
19206,ST GERMAIN LAVOLPS,19290,ST GERMAIN LAVOLPS,,45.6074727471,2.2137903995,206,,Saint-Germain-Lavolps,Saint-Germain-Lavolps,19,Corrèze,75,Nouvelle-Aquitaine
19207,ST GERMAIN LES VERGNES,19330,ST GERMAIN LES VERGNES,,45.2671975982,1.62099868397,207,,Saint-Germain-les-Vergnes,Saint-Germain-les-Vergnes,19,Corrèze,75,Nouvelle-Aquitaine
19208,ST HILAIRE FOISSAC,19550,ST HILAIRE FOISSAC,,45.3237427477,2.11464630625,208,,Saint-Hilaire-Foissac,Saint-Hilaire-Foissac,19,Corrèze,75,Nouvelle-Aquitaine
19209,ST HILAIRE LES COURBES,19170,ST HILAIRE LES COURBES,,45.5983709539,1.82144682815,209,,Saint-Hilaire-les-Courbes,Saint-Hilaire-les-Courbes,19,Corrèze,75,Nouvelle-Aquitaine
19210,ST HILAIRE LUC,19160,ST HILAIRE LUC,,45.3460319369,2.20762618382,210,,Saint-Hilaire-Luc,Saint-Hilaire-Luc,19,Corrèze,75,Nouvelle-Aquitaine
19211,ST HILAIRE PEYROUX,19560,ST HILAIRE PEYROUX,,45.2103986023,1.64187495214,211,,Saint-Hilaire-Peyroux,Saint-Hilaire-Peyroux,19,Corrèze,75,Nouvelle-Aquitaine
19212,ST HILAIRE TAURIEUX,19400,ST HILAIRE TAURIEUX,,45.0831887822,1.83285780001,212,,Saint-Hilaire-Taurieux,Saint-Hilaire-Taurieux,19,Corrèze,75,Nouvelle-Aquitaine
19213,ST JAL,19700,ST JAL,,45.3931451216,1.6489461128,213,,Saint-Jal,Saint-Jal,19,Corrèze,75,Nouvelle-Aquitaine
19214,ST JULIEN AUX BOIS,19220,ST JULIEN AUX BOIS,,45.1239920937,2.1459890004,214,,Saint-Julien-aux-Bois,Saint-Julien-aux-Bois,19,Corrèze,75,Nouvelle-Aquitaine
19215,ST JULIEN LE PELERIN,19430,ST JULIEN LE PELERIN,,45.001798962,2.06379083317,215,,Saint-Julien-le-Pèlerin,Saint-Julien-le-Pèlerin,19,Corrèze,75,Nouvelle-Aquitaine
19216,ST JULIEN LE VENDOMOIS,19210,ST JULIEN LE VENDOMOIS,,45.4578911899,1.32071021435,216,,Saint-Julien-le-Vendômois,Saint-Julien-le-Vendômois,19,Corrèze,75,Nouvelle-Aquitaine
19217,ST JULIEN MAUMONT,19500,ST JULIEN MAUMONT,,45.034476008,1.70931422691,217,,Saint-Julien-Maumont,Saint-Julien-Maumont,19,Corrèze,75,Nouvelle-Aquitaine
19218,ST JULIEN PRES BORT,19110,ST JULIEN PRES BORT,,45.4321597537,2.4346162221,218,,Saint-Julien-près-Bort,Saint-Julien-près-Bort,19,Corrèze,75,Nouvelle-Aquitaine
19219,STE MARIE LAPANOUZE,19160,STE MARIE LAPANOUZE,,45.4328750969,2.34596943981,219,,Sainte-Marie-Lapanouze,Sainte-Marie-Lapanouze,19,Corrèze,75,Nouvelle-Aquitaine
19220,ST MARTIAL DE GIMEL,19150,ST MARTIAL DE GIMEL,,45.2721132058,1.88642214024,220,,Saint-Martial-de-Gimel,Saint-Martial-de-Gimel,19,Corrèze,75,Nouvelle-Aquitaine
19221,ST MARTIAL ENTRAYGUES,19400,ST MARTIAL ENTRAYGUES,,45.1273004065,1.96861582967,221,,Saint-Martial-Entraygues,Saint-Martial-Entraygues,19,Corrèze,75,Nouvelle-Aquitaine
19222,ST MARTIN LA MEANNE,19320,ST MARTIN LA MEANNE,,45.1711303234,1.99473483674,222,,Saint-Martin-la-Méanne,Saint-Martin-la-Méanne,19,Corrèze,75,Nouvelle-Aquitaine
19223,ST MARTIN SEPERT,19210,ST MARTIN SEPERT,,45.443440592,1.48235840703,223,,Saint-Martin-Sepert,Saint-Martin-Sepert,19,Corrèze,75,Nouvelle-Aquitaine
19225,ST MERD DE LAPLEAU,19320,ST MERD DE LAPLEAU,,45.248513057,2.09602630818,225,,Saint-Merd-de-Lapleau,Saint-Merd-de-Lapleau,19,Corrèze,75,Nouvelle-Aquitaine
19226,ST MERD LES OUSSINES,19170,ST MERD LES OUSSINES,,45.6228763461,2.03219704649,226,,Saint-Merd-les-Oussines,Saint-Merd-les-Oussines,19,Corrèze,75,Nouvelle-Aquitaine
19227,ST MEXANT,19330,ST MEXANT,,45.2895303019,1.67629634388,227,,Saint-Mexant,Saint-Mexant,19,Corrèze,75,Nouvelle-Aquitaine
19228,ST PANTALEON DE LAPLEAU,19160,ST PANTALEON DE LAPLEAU,,45.32011422,2.20178736947,228,,Saint-Pantaléon-de-Lapleau,Saint-Pantaléon-de-Lapleau,19,Corrèze,75,Nouvelle-Aquitaine
19229,ST PANTALEON DE LARCHE,19600,ST PANTALEON DE LARCHE,,45.1467893413,1.43249583574,229,,Saint-Pantaléon-de-Larche,Saint-Pantaléon-de-Larche,19,Corrèze,75,Nouvelle-Aquitaine
19230,ST PARDOUX CORBIER,19210,ST PARDOUX CORBIER,,45.4428777879,1.45525591811,230,,Saint-Pardoux-Corbier,Saint-Pardoux-Corbier,19,Corrèze,75,Nouvelle-Aquitaine
19231,ST PARDOUX LA CROISILLE,19320,ST PARDOUX LA CROISILLE,,45.2494070918,1.96891800713,231,,Saint-Pardoux-la-Croisille,Saint-Pardoux-la-Croisille,19,Corrèze,75,Nouvelle-Aquitaine
19232,ST PARDOUX LE NEUF,19200,ST PARDOUX LE NEUF,,45.6127807921,2.33140885442,232,,Saint-Pardoux-le-Neuf,Saint-Pardoux-le-Neuf,19,Corrèze,75,Nouvelle-Aquitaine
19233,ST PARDOUX LE VIEUX,19200,ST PARDOUX LE VIEUX,,45.6022320235,2.27197616263,233,,Saint-Pardoux-le-Vieux,Saint-Pardoux-le-Vieux,19,Corrèze,75,Nouvelle-Aquitaine
19234,ST PARDOUX L ORTIGIER,19270,ST PARDOUX L ORTIGIER,,45.2998663219,1.58155594244,234,,Saint-Pardoux-l'Ortigier,Saint-Pardoux-l'Ortigier,19,Corrèze,75,Nouvelle-Aquitaine
19235,ST PAUL,19150,ST PAUL,,45.2063786861,1.89796054696,235,,Saint-Paul,Saint-Paul,19,Corrèze,75,Nouvelle-Aquitaine
19236,ST PRIEST DE GIMEL,19800,ST PRIEST DE GIMEL,,45.3086188465,1.8936669424,236,,Saint-Priest-de-Gimel,Saint-Priest-de-Gimel,19,Corrèze,75,Nouvelle-Aquitaine
19237,ST PRIVAT,19220,ST PRIVAT,,45.136566968,2.07738034635,237,,Saint-Privat,Saint-Privat,19,Corrèze,75,Nouvelle-Aquitaine
19238,ST REMY,19290,ST REMY,,45.6543418115,2.27375449222,238,,Saint-Rémy,Saint-Rémy,19,Corrèze,75,Nouvelle-Aquitaine
19239,ST ROBERT,19310,ST ROBERT,,45.2536741713,1.29535319885,239,,Saint-Robert,Saint-Robert,19,Corrèze,75,Nouvelle-Aquitaine
19240,ST SALVADOUR,19700,ST SALVADOUR,,45.3906265922,1.76634717247,240,,Saint-Salvadour,Saint-Salvadour,19,Corrèze,75,Nouvelle-Aquitaine
19241,ST SETIERS,19290,ST SETIERS,,45.696753232,2.1173595487,241,,Saint-Setiers,Saint-Setiers,19,Corrèze,75,Nouvelle-Aquitaine
19242,ST SOLVE,19130,ST SOLVE,,45.3063549675,1.403519902,242,,Saint-Solve,Saint-Solve,19,Corrèze,75,Nouvelle-Aquitaine
19243,ST SORNIN LAVOLPS,19230,ST SORNIN LAVOLPS,,45.3741557979,1.37135404677,243,,Saint-Sornin-Lavolps,Saint-Sornin-Lavolps,19,Corrèze,75,Nouvelle-Aquitaine
19244,ST SULPICE LES BOIS,19250,ST SULPICE LES BOIS,,45.613289994,2.14152354992,244,,Saint-Sulpice-les-Bois,Saint-Sulpice-les-Bois,19,Corrèze,75,Nouvelle-Aquitaine
19245,ST SYLVAIN,19380,ST SYLVAIN,,45.1805974733,1.88835230175,245,,Saint-Sylvain,Saint-Sylvain,19,Corrèze,75,Nouvelle-Aquitaine
19246,ST VIANCE,19240,ST VIANCE,,45.2102922064,1.46418044061,246,,Saint-Viance,Saint-Viance,19,Corrèze,75,Nouvelle-Aquitaine
19247,ST VICTOUR,19200,ST VICTOUR,,45.4654766822,2.38592172613,247,,Saint-Victour,Saint-Victour,19,Corrèze,75,Nouvelle-Aquitaine
19248,ST YBARD,19140,ST YBARD,,45.4491159844,1.51880664164,248,,Saint-Ybard,Saint-Ybard,19,Corrèze,75,Nouvelle-Aquitaine
19249,ST YRIEIX LE DEJALAT,19300,ST YRIEIX LE DEJALAT,,45.4549609024,1.96986165302,249,,Saint-Yrieix-le-Déjalat,Saint-Yrieix-le-Déjalat,19,Corrèze,75,Nouvelle-Aquitaine
19250,SALON LA TOUR,19510,SALON LA TOUR,,45.5022179707,1.53380254271,250,,Salon-la-Tour,Salon-la-Tour,19,Corrèze,75,Nouvelle-Aquitaine
19251,SARRAN,19800,SARRAN,,45.4121016966,1.93125887963,251,,Sarran,Sarran,19,Corrèze,75,Nouvelle-Aquitaine
19252,SARROUX,19110,SARROUX,,45.4307878428,2.46791610907,252,,Sarroux - Saint Julien,Sarroux - Saint Julien,19,Corrèze,75,Nouvelle-Aquitaine
19253,SEGONZAC,19310,SEGONZAC,,45.2767795103,1.26250500836,253,,Segonzac,Segonzac,19,Corrèze,75,Nouvelle-Aquitaine
19254,SEGUR LE CHATEAU,19230,SEGUR LE CHATEAU,,45.427657429,1.30428297034,254,,Ségur-le-Château,Ségur-le-Château,19,Corrèze,75,Nouvelle-Aquitaine
19255,SEILHAC,19700,SEILHAC,,45.3668078049,1.72303452473,255,,Seilhac,Seilhac,19,Corrèze,75,Nouvelle-Aquitaine
19256,SERANDON,19160,SERANDON,,45.3506057867,2.32469690406,256,,Sérandon,Sérandon,19,Corrèze,75,Nouvelle-Aquitaine
19257,SERILHAC,19190,SERILHAC,,45.0983013338,1.73319338162,257,,Sérilhac,Sérilhac,19,Corrèze,75,Nouvelle-Aquitaine
19258,SERVIERES LE CHATEAU,19220,SERVIERES LE CHATEAU,,45.1398764504,2.02231486142,258,,Servières-le-Château,Servières-le-Château,19,Corrèze,75,Nouvelle-Aquitaine
19259,SEXCLES,19430,SEXCLES,,45.0477027294,2.01682416184,259,,Sexcles,Sexcles,19,Corrèze,75,Nouvelle-Aquitaine
19260,SIONIAC,19120,SIONIAC,,44.9776918533,1.80010891382,260,,Sioniac,Sioniac,19,Corrèze,75,Nouvelle-Aquitaine
19261,SORNAC,19290,SORNAC,,45.6694408619,2.18045644477,261,,Sornac,Sornac,19,Corrèze,75,Nouvelle-Aquitaine
19262,SOUDAINE LAVINADIERE,19370,SOUDAINE LAVINADIERE,,45.5456519392,1.72687990718,262,,Soudaine-Lavinadière,Soudaine-Lavinadière,19,Corrèze,75,Nouvelle-Aquitaine
19263,SOUDEILLES,19300,SOUDEILLES,,45.4460190027,2.06358162972,263,,Soudeilles,Soudeilles,19,Corrèze,75,Nouvelle-Aquitaine
19264,SOURSAC,19550,SOURSAC,,45.2617933713,2.20363976923,264,,Soursac,Soursac,19,Corrèze,75,Nouvelle-Aquitaine
19265,TARNAC,19170,TARNAC,,45.6728907622,1.95565232605,265,,Tarnac,Tarnac,19,Corrèze,75,Nouvelle-Aquitaine
19266,THALAMY,19200,THALAMY,,45.5068066637,2.45854941771,266,,Thalamy,Thalamy,19,Corrèze,75,Nouvelle-Aquitaine
19268,TOY VIAM,19170,TOY VIAM,,45.6453715634,1.93016871499,268,,Toy-Viam,Toy-Viam,19,Corrèze,75,Nouvelle-Aquitaine
19269,TREIGNAC,19260,TREIGNAC,,45.5524776279,1.80105006647,269,,Treignac,Treignac,19,Corrèze,75,Nouvelle-Aquitaine
19270,TROCHE,19230,TROCHE,,45.396965868,1.44794561231,270,,Troche,Troche,19,Corrèze,75,Nouvelle-Aquitaine
19271,TUDEILS,19120,TUDEILS,,45.0497594208,1.78452745167,271,,Tudeils,Tudeils,19,Corrèze,75,Nouvelle-Aquitaine
19272,TULLE,19000,TULLE,,45.2731516999,1.76313875655,272,,Tulle,Tulle,19,Corrèze,75,Nouvelle-Aquitaine
19273,TURENNE,19500,TURENNE,,45.0610532957,1.57106480469,273,,Turenne,Turenne,19,Corrèze,75,Nouvelle-Aquitaine
19274,USSAC,19270,USSAC,,45.1901861032,1.51268724107,274,,Ussac,Ussac,19,Corrèze,75,Nouvelle-Aquitaine
19275,USSEL,19200,USSEL,,45.5502290763,2.30449906488,275,,Ussel,Ussel,19,Corrèze,75,Nouvelle-Aquitaine
19275,USSEL,19200,USSEL,LA TOURETTE,45.5502290763,2.30449906488,275,,Ussel,Ussel,19,Corrèze,75,Nouvelle-Aquitaine
19275,USSEL,19200,USSEL,ST DEZERY,45.5502290763,2.30449906488,275,,Ussel,Ussel,19,Corrèze,75,Nouvelle-Aquitaine
19276,UZERCHE,19140,UZERCHE,,45.4238857521,1.56525480659,276,,Uzerche,Uzerche,19,Corrèze,75,Nouvelle-Aquitaine
19277,VALIERGUES,19200,VALIERGUES,,45.4747797354,2.28180692007,277,,Valiergues,Valiergues,19,Corrèze,75,Nouvelle-Aquitaine
19278,VARETZ,19240,VARETZ,,45.1887606718,1.43403558868,278,,Varetz,Varetz,19,Corrèze,75,Nouvelle-Aquitaine
19279,VARS SUR ROSEIX,19130,VARS SUR ROSEIX,,45.261022525,1.3726827806,279,,Vars-sur-Roseix,Vars-sur-Roseix,19,Corrèze,75,Nouvelle-Aquitaine
19280,VEGENNES,19120,VEGENNES,,44.9822250905,1.75103935795,280,,Végennes,Végennes,19,Corrèze,75,Nouvelle-Aquitaine
19281,VEIX,19260,VEIX,,45.5013644265,1.83804197666,281,,Veix,Veix,19,Corrèze,75,Nouvelle-Aquitaine
19283,VEYRIERES,19200,VEYRIERES,,45.4890424221,2.39561651317,283,,Veyrières,Veyrières,19,Corrèze,75,Nouvelle-Aquitaine
19284,VIAM,19170,VIAM,,45.6182241437,1.88700559018,284,,Viam,Viam,19,Corrèze,75,Nouvelle-Aquitaine
19285,VIGEOIS,19410,VIGEOIS,,45.3786371667,1.51888196425,285,,Vigeois,Vigeois,19,Corrèze,75,Nouvelle-Aquitaine
19286,VIGNOLS,19130,VIGNOLS,,45.328176854,1.40068283398,286,,Vignols,Vignols,19,Corrèze,75,Nouvelle-Aquitaine
19287,VITRAC SUR MONTANE,19800,VITRAC SUR MONTANE,,45.3727168836,1.93317365283,287,,Vitrac-sur-Montane,Vitrac-sur-Montane,19,Corrèze,75,Nouvelle-Aquitaine
19288,VOUTEZAC,19130,VOUTEZAC,,45.2931226913,1.44040079279,288,,Voutezac,Voutezac,19,Corrèze,75,Nouvelle-Aquitaine
19289,YSSANDON,19310,YSSANDON,,45.2044238204,1.38366828391,289,,Yssandon,Yssandon,19,Corrèze,75,Nouvelle-Aquitaine
21001,AGENCOURT,21700,AGENCOURT,,47.1250474303,4.98564114053,1,,Agencourt,Agencourt,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21002,AGEY,21410,AGEY,,47.2873713877,4.76268467218,2,,Agey,Agey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21003,AHUY,21121,AHUY,,47.3713563421,5.02129727446,3,,Ahuy,Ahuy,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21004,AIGNAY LE DUC,21510,AIGNAY LE DUC,,47.6717538581,4.7507180753,4,,Aignay-le-Duc,Aignay-le-Duc,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21005,AISEREY,21110,AISEREY,,47.1728785893,5.16876522456,5,,Aiserey,Aiserey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21006,AISEY SUR SEINE,21400,AISEY SUR SEINE,,47.7479865538,4.57814842131,6,,Aisey-sur-Seine,Aisey-sur-Seine,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21007,AISY SOUS THIL,21390,AISY SOUS THIL,,47.3934525171,4.27887372398,7,,Aisy-sous-Thil,Aisy-sous-Thil,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21008,ALISE STE REINE,21150,ALISE STE REINE,,47.5355145985,4.48820121343,8,,Alise-Sainte-Reine,Alise-Sainte-Reine,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21009,ALLEREY,21230,ALLEREY,,47.1905039427,4.42062511101,9,,Allerey,Allerey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21010,ALOXE CORTON,21420,ALOXE CORTON,,47.0682393129,4.86123205088,10,,Aloxe-Corton,Aloxe-Corton,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21011,AMPILLY LES BORDES,21450,AMPILLY LES BORDES,,47.6389194024,4.62698815562,11,,Ampilly-les-Bordes,Ampilly-les-Bordes,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21012,AMPILLY LE SEC,21400,AMPILLY LE SEC,,47.8100690603,4.50329961504,12,,Ampilly-le-Sec,Ampilly-le-Sec,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21013,ANCEY,21410,ANCEY,,47.3337177684,4.82268168932,13,,Ancey,Ancey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21014,ANTHEUIL,21360,ANTHEUIL,,47.1764418313,4.74942827901,14,,Antheuil,Antheuil,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21015,ANTIGNY LA VILLE,21230,ANTIGNY LA VILLE,,47.1012813081,4.55975234629,15,,Antigny-la-Ville,Antigny-la-Ville,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21016,ARCEAU,21310,ARCEAU,,47.3759150158,5.19629196424,16,,Arceau,Arceau,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21017,ARCENANT,21700,ARCENANT,,47.1403914793,4.83749940217,17,,Arcenant,Arcenant,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21018,ARCEY,21410,ARCEY,,47.2746433472,4.82435366127,18,,Arcey,Arcey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21020,ARCONCEY,21320,ARCONCEY,,47.2158291471,4.46108652997,20,,Arconcey,Arconcey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21021,ARC SUR TILLE,21560,ARC SUR TILLE,,47.3382300377,5.20032142407,21,,Arc-sur-Tille,Arc-sur-Tille,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21022,ARGILLY,21700,ARGILLY,,47.0685552309,5.02486486976,22,,Argilly,Argilly,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21023,ARNAY LE DUC,21230,ARNAY LE DUC,,47.1275592202,4.47951494721,23,,Arnay-le-Duc,Arnay-le-Duc,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21024,ARNAY SOUS VITTEAUX,21350,ARNAY SOUS VITTEAUX,,47.4372947607,4.49901520895,24,,Arnay-sous-Vitteaux,Arnay-sous-Vitteaux,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21025,ARRANS,21500,ARRANS,,47.6899641108,4.3280716946,25,,Arrans,Arrans,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21026,ASNIERES EN MONTAGNE,21500,ASNIERES EN MONTAGNE,,47.7130312269,4.29307220961,26,,Asnières-en-Montagne,Asnières-en-Montagne,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21027,ASNIERES LES DIJON,21380,ASNIERES LES DIJON,,47.3872203652,5.04520527186,27,,Asnières-lès-Dijon,Asnières-lès-Dijon,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21028,ATHEE,21130,ATHEE,,47.2251419104,5.3617920944,28,,Athée,Athée,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21029,ATHIE,21500,ATHIE,,47.5619563649,4.25784537484,29,,Athie,Athie,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21030,AUBAINE,21360,AUBAINE,,47.129772588,4.7343345695,30,,Aubaine,Aubaine,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21031,AUBIGNY EN PLAINE,21170,AUBIGNY EN PLAINE,,47.1277492064,5.16492120772,31,,Aubigny-en-Plaine,Aubigny-en-Plaine,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21032,AUBIGNY LA RONCE,21340,AUBIGNY LA RONCE,,46.9880450918,4.59634588492,32,,Aubigny-la-Ronce,Aubigny-la-Ronce,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21033,AUBIGNY LES SOMBERNON,21540,AUBIGNY LES SOMBERNON,,47.3007242662,4.64273787867,33,,Aubigny-lès-Sombernon,Aubigny-lès-Sombernon,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21034,AUTRICOURT,21570,AUTRICOURT,,47.9932834773,4.62176742822,34,,Autricourt,Autricourt,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21035,AUVILLARS SUR SAONE,21250,AUVILLARS SUR SAONE,,47.0592766074,5.10776303879,35,,Auvillars-sur-Saône,Auvillars-sur-Saône,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21036,AUXANT,21360,AUXANT,,47.1298867764,4.61921408774,36,,Auxant,Auxant,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21037,AUXEY DURESSES,21190,AUXEY DURESSES,,46.985207614,4.7314121472,37,,Auxey-Duresses,Auxey-Duresses,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21038,AUXONNE,21130,AUXONNE,,47.1886126051,5.41450389351,38,,Auxonne,Auxonne,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21039,AVELANGES,21120,AVELANGES,,47.5873364734,5.03060719887,39,,Avelanges,Avelanges,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21040,AVOSNES,21350,AVOSNES,,47.3684679996,4.63263397614,40,,Avosnes,Avosnes,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21041,AVOT,21580,AVOT,,47.6170677584,5.00757406973,41,,Avot,Avot,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21042,BAGNOT,21700,BAGNOT,,47.0500499735,5.07330457799,42,,Bagnot,Bagnot,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21043,BAIGNEUX LES JUIFS,21450,BAIGNEUX LES JUIFS,,47.6010296046,4.6514540386,43,,Baigneux-les-Juifs,Baigneux-les-Juifs,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21044,BALOT,21330,BALOT,,47.8198079163,4.44334432169,44,,Balot,Balot,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21045,BARBIREY SUR OUCHE,21410,BARBIREY SUR OUCHE,,47.2520386403,4.74025320877,45,,Barbirey-sur-Ouche,Barbirey-sur-Ouche,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21046,BARD LE REGULIER,21430,BARD LE REGULIER,,47.1461439865,4.3014564581,46,,Bard-le-Régulier,Bard-le-Régulier,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21047,BARD LES EPOISSES,21460,BARD LES EPOISSES,,47.5282299954,4.22511845186,47,,Bard-lès-Époisses,Bard-lès-Époisses,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21048,BARGES,21910,BARGES,,47.2092455725,5.05951734595,48,,Barges,Barges,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21049,BARJON,21580,BARJON,,47.6163680497,4.9625109297,49,,Barjon,Barjon,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21050,BAUBIGNY,21340,BAUBIGNY,,46.9901330844,4.68217029734,50,,Baubigny,Baubigny,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21051,BAULME LA ROCHE,21410,BAULME LA ROCHE,,47.3480241996,4.79422345686,51,,Baulme-la-Roche,Baulme-la-Roche,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21052,BEAULIEU,21510,BEAULIEU,,47.7261392413,4.73343312021,52,,Beaulieu,Beaulieu,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21053,BEAUMONT SUR VINGEANNE,21310,BEAUMONT SUR VINGEANNE,,47.4625396249,5.34749332128,53,,Beaumont-sur-Vingeanne,Beaumont-sur-Vingeanne,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21054,BEAUNE,21200,BEAUNE,,47.0255189366,4.83767985985,54,,Beaune,Beaune,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21055,BEAUNOTTE,21510,BEAUNOTTE,,47.6813180742,4.7162410659,55,,Beaunotte,Beaunotte,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21056,BEIRE LE CHATEL,21310,BEIRE LE CHATEL,,47.4180183198,5.21317142788,56,,Beire-le-Châtel,Beire-le-Châtel,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21057,BEIRE LE FORT,21110,BEIRE LE FORT,,47.2376253044,5.26097283802,57,,Beire-le-Fort,Beire-le-Fort,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21058,BELAN SUR OURCE,21570,BELAN SUR OURCE,,47.9515958894,4.63862833374,58,,Belan-sur-Ource,Belan-sur-Ource,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21059,BELLEFOND,21490,BELLEFOND,,47.3801760764,5.06389579154,59,,Bellefond,Bellefond,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21060,BELLENEUVE,21310,BELLENEUVE,,47.3575073832,5.27408508272,60,,Belleneuve,Belleneuve,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21061,BELLENOD SUR SEINE,21510,BELLENOD SUR SEINE,,47.6951717753,4.64536877264,61,,Bellenod-sur-Seine,Bellenod-sur-Seine,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21062,BELLENOT SOUS POUILLY,21320,BELLENOT SOUS POUILLY,,47.2869221987,4.54627615974,62,,Bellenot-sous-Pouilly,Bellenot-sous-Pouilly,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21063,BENEUVRE,21290,BENEUVRE,,47.6934400919,4.93578663109,63,,Beneuvre,Beneuvre,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21064,BENOISEY,21500,BENOISEY,,47.5769533201,4.39518979239,64,,Benoisey,Benoisey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21065,BESSEY EN CHAUME,21360,BESSEY EN CHAUME,,47.0874165911,4.74194703702,65,,Bessey-en-Chaume,Bessey-en-Chaume,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21066,BESSEY LA COUR,21360,BESSEY LA COUR,,47.1033767349,4.60499470224,66,,Bessey-la-Cour,Bessey-la-Cour,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21067,BESSEY LES CITEAUX,21110,BESSEY LES CITEAUX,,47.1451368647,5.14759031997,67,,Bessey-lès-Cîteaux,Bessey-lès-Cîteaux,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21068,BEUREY BAUGUAY,21320,BEUREY BAUGUAY,,47.2316421588,4.43039067853,68,,Beurey-Bauguay,Beurey-Bauguay,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21069,BEURIZOT,21350,BEURIZOT,,47.3555109503,4.49612543597,69,,Beurizot,Beurizot,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21070,BEVY,21220,BEVY,,47.1820024357,4.83965418214,70,,Bévy,Bévy,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21071,BEZE,21310,BEZE,,47.4660482708,5.27867233051,71,,Bèze,Bèze,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21072,BEZOUOTTE,21310,BEZOUOTTE,,47.3838896873,5.3354189969,72,,Bézouotte,Bézouotte,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21073,BIERRE LES SEMUR,21390,BIERRE LES SEMUR,,47.4224799453,4.29555641395,73,,Bierre-lès-Semur,Bierre-lès-Semur,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21074,BILLEY,21130,BILLEY,,47.1423914967,5.41973357014,74,,Billey,Billey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21075,BILLY LES CHANCEAUX,21450,BILLY LES CHANCEAUX,,47.5530033313,4.73431352528,75,,Billy-lès-Chanceaux,Billy-lès-Chanceaux,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21076,BINGES,21270,BINGES,,47.3252506577,5.2690666865,76,,Binges,Binges,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21077,BISSEY LA COTE,21520,BISSEY LA COTE,,47.9115041699,4.70686086144,77,,Bissey-la-Côte,Bissey-la-Côte,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21078,BISSEY LA PIERRE,21330,BISSEY LA PIERRE,,47.8431150468,4.42590743381,78,,Bissey-la-Pierre,Bissey-la-Pierre,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21079,BLAGNY SUR VINGEANNE,21310,BLAGNY SUR VINGEANNE,,47.4395134717,5.3551085135,79,,Blagny-sur-Vingeanne,Blagny-sur-Vingeanne,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21080,BLAISY BAS,21540,BLAISY BAS,,47.3693914963,4.73506402999,80,,Blaisy-Bas,Blaisy-Bas,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21081,BLAISY HAUT,21540,BLAISY HAUT,,47.361227533,4.7672740237,81,,Blaisy-Haut,Blaisy-Haut,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21082,BLANCEY,21320,BLANCEY,,47.3027962841,4.46007256895,82,,Blancey,Blancey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21083,BLANOT,21430,BLANOT,,47.1787083917,4.23562665908,83,,Blanot,Blanot,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21084,SOURCE SEINE,21690,SOURCE SEINE,,47.4889159636,4.6965588014,84,,Source-Seine,Source-Seine,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21084,SOURCE SEINE,21690,SOURCE SEINE,ST GERMAIN SOURCE SEINE,47.4889159636,4.6965588014,84,,Source-Seine,Source-Seine,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21085,BLIGNY LE SEC,21440,BLIGNY LE SEC,,47.4483490502,4.74031700396,85,,Bligny-le-Sec,Bligny-le-Sec,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21086,BLIGNY LES BEAUNE,21200,BLIGNY LES BEAUNE,,46.9826471816,4.82973563081,86,,Bligny-lès-Beaune,Bligny-lès-Beaune,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21087,BLIGNY SUR OUCHE,21360,BLIGNY SUR OUCHE,,47.1150954708,4.68344440559,87,,Bligny-sur-Ouche,Bligny-sur-Ouche,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21088,BONCOURT LE BOIS,21700,BONCOURT LE BOIS,,47.1377542581,4.99799682538,88,,Boncourt-le-Bois,Boncourt-le-Bois,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21089,BONNENCONTRE,21250,BONNENCONTRE,,47.0865135284,5.14243397683,89,,Bonnencontre,Bonnencontre,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21090,BOUDREVILLE,21520,BOUDREVILLE,,47.9294547335,4.8328363763,90,,Boudreville,Boudreville,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21091,BOUHEY,21360,BOUHEY,,47.2011527973,4.6776500221,91,,Bouhey,Bouhey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21092,BOUILLAND,21420,BOUILLAND,,47.1352672328,4.77123835553,92,,Bouilland,Bouilland,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21093,BOUIX,21330,BOUIX,,47.8995552729,4.4682965536,93,,Bouix,Bouix,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21094,BOURBERAIN,21610,BOURBERAIN,,47.5090799793,5.29223191696,94,,Bourberain,Bourberain,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21095,BOUSSELANGE,21250,BOUSSELANGE,,46.9939920712,5.25309460316,95,,Bousselange,Bousselange,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21096,BOUSSENOIS,21260,BOUSSENOIS,,47.6263032872,5.20576055486,96,,Boussenois,Boussenois,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21097,BOUSSEY,21350,BOUSSEY,,47.361027083,4.54086129653,97,,Boussey,Boussey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21098,BOUX SOUS SALMAISE,21690,BOUX SOUS SALMAISE,,47.4731727042,4.64424292231,98,,Boux-sous-Salmaise,Boux-sous-Salmaise,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21099,BOUZE LES BEAUNE,21200,BOUZE LES BEAUNE,,47.0543679441,4.77184750805,99,,Bouze-lès-Beaune,Bouze-lès-Beaune,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21100,BRAIN,21350,BRAIN,,47.4630889217,4.50508352891,100,,Brain,Brain,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21101,BRAUX,21390,BRAUX,,47.4108469631,4.41872277915,101,,Braux,Braux,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21102,BRAZEY EN MORVAN,21430,BRAZEY EN MORVAN,,47.1724443227,4.27604243318,102,,Brazey-en-Morvan,Brazey-en-Morvan,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21103,BRAZEY EN PLAINE,21470,BRAZEY EN PLAINE,,47.1384086462,5.21195206146,103,,Brazey-en-Plaine,Brazey-en-Plaine,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21104,BREMUR ET VAUROIS,21400,BREMUR ET VAUROIS,,47.7295472183,4.60931120174,104,,Brémur-et-Vaurois,Brémur-et-Vaurois,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21105,BRESSEY SUR TILLE,21560,BRESSEY SUR TILLE,,47.3084523992,5.18436143116,105,,Bressey-sur-Tille,Bressey-sur-Tille,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21106,BRETENIERE,21110,BRETENIERE,,47.2358783125,5.10342361177,106,,Bretenière,Bretenière,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21107,BRETIGNY,21490,BRETIGNY,,47.3935524929,5.09969847149,107,,Bretigny,Bretigny,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21108,BRIANNY,21390,BRIANNY,,47.4121182222,4.37434558764,108,,Brianny,Brianny,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21109,BRION SUR OURCE,21570,BRION SUR OURCE BELAN,,47.9060657741,4.66987212053,109,,Brion-sur-Ource,Brion-sur-Ource,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21110,BROCHON,21220,BROCHON,,47.2386657395,4.95610732845,110,,Brochon,Brochon,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21111,BROGNON,21490,BROGNON,,47.4109519413,5.16845513342,111,,Brognon,Brognon,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21112,BROIN,21250,BROIN,,47.0864209474,5.10439169166,112,,Broin,Broin,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21113,BROINDON,21220,BROINDON,,47.1957469906,5.03994792471,113,,Broindon,Broindon,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21114,BUFFON,21500,BUFFON,,47.6568975951,4.27470162202,114,,Buffon,Buffon,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21115,BUNCEY,21400,BUNCEY,,47.8168675282,4.57713874625,115,,Buncey,Buncey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21116,BURE LES TEMPLIERS,21290,BURE LES TEMPLIERS,,47.7321717407,4.91091716802,116,,Bure-les-Templiers,Bure-les-Templiers,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21117,BUSSEAUT,21510,BUSSEAUT,,47.7386819112,4.65218348231,117,,Busseaut,Busseaut,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21118,BUSSEROTTE ET MONTENAILLE,21580,BUSSEROTTE ET MONTENAILLE,,47.6625787147,4.98033576461,118,,Busserotte-et-Montenaille,Busserotte-et-Montenaille,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21119,BUSSIERES,21580,BUSSIERES,,47.6606253629,4.9610123004,119,,Bussières,Bussières,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21120,LA BUSSIERE SUR OUCHE,21360,LA BUSSIERE SUR OUCHE,,47.2265782926,4.69946543531,120,La,Bussière-sur-Ouche,La Bussière-sur-Ouche,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21121,BUSSY LA PESLE,21540,BUSSY LA PESLE,,47.3566273735,4.71361342126,121,,Bussy-la-Pesle,Bussy-la-Pesle,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21122,BUSSY LE GRAND,21150,BUSSY LE GRAND,,47.5762719733,4.52287854456,122,,Bussy-le-Grand,Bussy-le-Grand,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21123,BUXEROLLES,21290,BUXEROLLES,,47.8077545652,4.95548684515,123,,Buxerolles,Buxerolles,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21124,CENSEREY,21430,CENSEREY,,47.1975226763,4.33557297312,124,,Censerey,Censerey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21125,CERILLY,21330,CERILLY,,47.8557543819,4.48870489274,125,,Cérilly,Cérilly,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21126,CESSEY SUR TILLE,21110,CESSEY SUR TILLE,,47.2826894363,5.22925864232,126,,Cessey-sur-Tille,Cessey-sur-Tille,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21127,CHAIGNAY,21120,CHAIGNAY,,47.4767317204,5.06048874496,127,,Chaignay,Chaignay,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21128,CHAILLY SUR ARMANCON,21320,CHAILLY SUR ARMANCON,,47.2731849062,4.47732423155,128,,Chailly-sur-Armançon,Chailly-sur-Armançon,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21129,CHAMBAIN,21290,CHAMBAIN,,47.7950502005,4.91900088796,129,,Chambain,Chambain,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21130,CHAMBEIRE,21110,CHAMBEIRE,,47.2827087412,5.27183255465,130,,Chambeire,Chambeire,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21131,CHAMBLANC,21250,CHAMBLANC,,47.0222258183,5.16878563446,131,,Chamblanc,Chamblanc,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21132,CHAMBOEUF,21220,CHAMBOEUF,,47.227568941,4.90257555999,132,,Chambœuf,Chambœuf,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21133,CHAMBOLLE MUSIGNY,21220,CHAMBOLLE MUSIGNY,,47.1867543205,4.94446710185,133,,Chambolle-Musigny,Chambolle-Musigny,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21134,CHAMESSON,21400,CHAMESSON,,47.7827583784,4.54118131867,134,,Chamesson,Chamesson,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21135,CHAMPAGNE SUR VINGEANNE,21310,CHAMPAGNE SUR VINGEANNE,,47.4405994754,5.40662688051,135,,Champagne-sur-Vingeanne,Champagne-sur-Vingeanne,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21136,CHAMPAGNY,21440,CHAMPAGNY,,47.4615477148,4.76760990741,136,,Champagny,Champagny,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21137,CHAMP D OISEAU,21500,CHAMP D OISEAU,,47.5549844256,4.34798073365,137,,Champ-d'Oiseau,Champ-d'Oiseau,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21138,CHAMPDOTRE,21130,CHAMPDOTRE,,47.1825397994,5.30038457052,138,,Champdôtre,Champdôtre,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21139,CHAMPEAU EN MORVAN,21210,CHAMPEAU EN MORVAN,,47.2729958688,4.15587880467,139,,Champeau-en-Morvan,Champeau-en-Morvan,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21140,CHAMPIGNOLLES,21230,CHAMPIGNOLLES,,47.0511019463,4.56775348817,140,,Champignolles,Champignolles,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21141,CHAMPRENAULT,21690,CHAMPRENAULT,,47.4004814438,4.68028370836,141,,Champrenault,Champrenault,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21142,CHANCEAUX,21440,CHANCEAUX,,47.5252827866,4.7394570164,142,,Chanceaux,Chanceaux,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21143,CHANNAY,21330,CHANNAY,,47.8910741862,4.31948318267,143,,Channay,Channay,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21144,CHARENCEY,21690,CHARENCEY,,47.4202471522,4.67046145441,144,,Charencey,Charencey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21145,CHARIGNY,21140,CHARIGNY,,47.4334660182,4.42062588907,145,,Charigny,Charigny,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21146,CHARMES,21310,CHARMES,,47.3723080464,5.35030839785,146,,Charmes,Charmes,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21147,CHARNY,21350,CHARNY,,47.331944975,4.42573713876,147,,Charny,Charny,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21148,CHARREY SUR SAONE,21170,CHARREY SUR SAONE,,47.0987888037,5.15412539566,148,,Charrey-sur-Saône,Charrey-sur-Saône,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21149,CHARREY SUR SEINE,21400,CHARREY SUR SEINE,,47.9524790973,4.54026647468,149,,Charrey-sur-Seine,Charrey-sur-Seine,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21150,CHASSAGNE MONTRACHET,21190,CHASSAGNE MONTRACHET,,46.9323632752,4.72732420216,150,,Chassagne-Montrachet,Chassagne-Montrachet,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21151,CHASSEY,21150,CHASSEY,,47.4742431505,4.44465203399,151,,Chassey,Chassey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21152,CHATEAUNEUF,21320,CHATEAUNEUF,,47.2193918621,4.65146586699,152,,Châteauneuf,Châteauneuf,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21153,CHATELLENOT,21320,CHATELLENOT,,47.237576799,4.48751343081,153,,Châtellenot,Châtellenot,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21154,CHATILLON SUR SEINE,21400,CHATILLON SUR SEINE,,47.8582160336,4.59192199701,154,,Châtillon-sur-Seine,Châtillon-sur-Seine,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21155,CHAUDENAY LA VILLE,21360,CHAUDENAY LA VILLE,,47.1621875521,4.64388033261,155,,Chaudenay-la-Ville,Chaudenay-la-Ville,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21156,CHAUDENAY LE CHATEAU,21360,CHAUDENAY LE CHATEAU,,47.1794258557,4.64303707891,156,,Chaudenay-le-Château,Chaudenay-le-Château,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21157,CHAUGEY,21290,CHAUGEY,,47.7462238697,4.95019253448,157,,Chaugey,Chaugey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21158,CHAUME ET COURCHAMP,21610,CHAUME ET COURCHAMP,,47.5806895666,5.36162357765,158,,Chaume-et-Courchamp,Chaume-et-Courchamp,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21158,CHAUME ET COURCHAMP,21610,CHAUME ET COURCHAMP,COURCHAMP,47.5806895666,5.36162357765,158,,Chaume-et-Courchamp,Chaume-et-Courchamp,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21159,LA CHAUME,21520,LA CHAUME,,47.8814002109,4.8228651027,159,La,Chaume,La Chaume,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21160,CHAUME LES BAIGNEUX,21450,CHAUME LES BAIGNEUX,,47.6324839446,4.57347915813,160,,Chaume-lès-Baigneux,Chaume-lès-Baigneux,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21161,CHAUMONT LE BOIS,21400,CHAUMONT LE BOIS,,47.9345088372,4.59278668831,161,,Chaumont-le-Bois,Chaumont-le-Bois,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21162,CHAUX,21700,CHAUX,,47.1272831301,4.90718762417,162,,Chaux,Chaux,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21163,CHAZEUIL,21260,CHAZEUIL,,47.5584131204,5.28562266214,163,,Chazeuil,Chazeuil,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21164,CHAZILLY,21320,CHAZILLY,,47.183253219,4.58248518042,164,,Chazilly,Chazilly,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21165,CHEMIN D AISEY,21400,CHEMIN D AISEY,,47.7323713365,4.55214602407,165,,Chemin-d'Aisey,Chemin-d'Aisey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21166,CHENOVE,21300,CHENOVE,,47.2926136721,5.00488353457,166,,Chenôve,Chenôve,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21167,CHEUGE,21310,CHEUGE,,47.3898873125,5.38120926401,167,,Cheuge,Cheuge,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21168,CHEVANNAY,21540,CHEVANNAY,,47.3905655203,4.65394687054,168,,Chevannay,Chevannay,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21169,CHEVANNES,21220,CHEVANNES,,47.1587967474,4.83357896263,169,,Chevannes,Chevannes,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21170,CHEVIGNY EN VALIERE,21200,CHEVIGNY EN VALIERE,,46.9686024857,4.98151445256,170,,Chevigny-en-Valière,Chevigny-en-Valière,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21171,CHEVIGNY ST SAUVEUR,21800,CHEVIGNY ST SAUVEUR,,47.2999007268,5.13656967893,171,,Chevigny-Saint-Sauveur,Chevigny-Saint-Sauveur,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21172,CHIVRES,21820,CHIVRES,,46.9678867333,5.09111140101,172,,Chivres,Chivres,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21173,CHOREY LES BEAUNE,21200,CHOREY LES BEAUNE,,47.0494684617,4.86962267371,173,,Chorey-les-Beaune,Chorey-les-Beaune,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21175,CIREY LES PONTAILLER,21270,CIREY LES PONTAILLER,,47.3029215077,5.30975861654,175,,Cirey-lès-Pontailler,Cirey-lès-Pontailler,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21176,CIVRY EN MONTAGNE,21320,CIVRY EN MONTAGNE,,47.289478828,4.60603583394,176,,Civry-en-Montagne,Civry-en-Montagne,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21177,CLAMEREY,21390,CLAMEREY,,47.3870569397,4.42237212486,177,,Clamerey,Clamerey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21178,CLEMENCEY,21220,CLEMENCEY,,47.2553233042,4.88465417983,178,,Clémencey,Clémencey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21179,CLENAY,21490,CLENAY,,47.4135413138,5.11448988395,179,,Clénay,Clénay,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21180,CLERY,21270,CLERY,,47.2954771926,5.50214108598,180,,Cléry,Cléry,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21181,CLOMOT,21230,CLOMOT,,47.1923530419,4.48684407065,181,,Clomot,Clomot,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21182,COLLONGES LES BEVY,21220,COLLONGES LES BEVY,,47.1715437386,4.83755928779,182,,Collonges-lès-Bévy,Collonges-lès-Bévy,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21183,COLLONGES LES PREMIERES,21110,COLLONGES LES PREMIERES,,47.2250095232,5.29242565659,183,,Collonges-lès-Premières,Collonges-lès-Premières,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21184,COLOMBIER,21360,COLOMBIER,,47.168419932,4.67469855204,184,,Colombier,Colombier,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21185,COMBERTAULT,21200,COMBERTAULT,,46.9921948598,4.90342366807,185,,Combertault,Combertault,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21186,COMBLANCHIEN,21700,COMBLANCHIEN,,47.1030661161,4.91805213591,186,,Comblanchien,Comblanchien,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21187,COMMARIN,21320,COMMARIN,,47.2495525979,4.65314181407,187,,Commarin,Commarin,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21189,CORBERON,21250,CORBERON,,47.0107613074,5.00919232333,189,,Corberon,Corberon,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21190,CORCELLES LES ARTS,21190,CORCELLES LES ARTS,,46.9450397158,4.79678522994,190,,Corcelles-les-Arts,Corcelles-les-Arts,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21191,CORCELLES LES CITEAUX,21910,CORCELLES LES CITEAUX,,47.1692110216,5.08258953072,191,,Corcelles-lès-Cîteaux,Corcelles-lès-Cîteaux,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21192,CORCELLES LES MONTS,21160,CORCELLES LES MONTS,,47.3040555868,4.94246444675,192,,Corcelles-les-Monts,Corcelles-les-Monts,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21193,CORGENGOUX,21250,CORGENGOUX,,46.9883081581,5.01123700929,193,,Corgengoux,Corgengoux,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21194,CORGOLOIN,21700,CORGOLOIN,,47.0819087095,4.93531164133,194,,Corgoloin,Corgoloin,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21195,CORMOT LE GRAND,21340,CORMOT LE GRAND,,46.9758355763,4.64101278682,195,,Cormot-Vauchignon,Cormot-Vauchignon,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21196,CORPEAU,21190,CORPEAU,,46.9274010331,4.75964773647,196,,Corpeau,Corpeau,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21197,CORPOYER LA CHAPELLE,21150,CORPOYER LA CHAPELLE,,47.549075893,4.60962809642,197,,Corpoyer-la-Chapelle,Corpoyer-la-Chapelle,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21198,CORROMBLES,21460,CORROMBLES,,47.5184691895,4.20257094353,198,,Corrombles,Corrombles,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21199,CORSAINT,21460,CORSAINT,,47.5470766157,4.20200544222,199,,Corsaint,Corsaint,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21200,COUCHEY,21160,COUCHEY,,47.2620834886,4.96092501578,200,,Couchey,Couchey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21201,COULMIER LE SEC,21400,COULMIER LE SEC,,47.7550385883,4.49604694384,201,,Coulmier-le-Sec,Coulmier-le-Sec,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21202,COURBAN,21520,COURBAN,,47.9155781312,4.73943669739,202,,Courban,Courban,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21203,COURCELLES FREMOY,21460,COURCELLES FREMOY,,47.4471425118,4.18664982284,203,,Courcelles-Frémoy,Courcelles-Frémoy,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21204,COURCELLES LES MONTBARD,21500,COURCELLES LES MONTBARD,,47.5920415206,4.39246271634,204,,Courcelles-lès-Montbard,Courcelles-lès-Montbard,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21205,COURCELLES LES SEMUR,21140,COURCELLES LES SEMUR,,47.4487720046,4.29278256716,205,,Courcelles-lès-Semur,Courcelles-lès-Semur,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21207,COURLON,21580,COURLON,,47.64572911,5.00801867422,207,,Courlon,Courlon,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21208,COURTIVRON,21120,COURTIVRON,,47.5345406355,4.95268156841,208,,Courtivron,Courtivron,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21209,COUTERNON,21560,COUTERNON,,47.3303548853,5.15014868393,209,,Couternon,Couternon,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21210,CREANCEY,21320,CREANCEY,,47.2528641586,4.59321134854,210,,Créancey,Créancey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21211,CRECEY SUR TILLE,21120,CRECEY SUR TILLE,,47.5701202623,5.14132427261,211,,Crécey-sur-Tille,Crécey-sur-Tille,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21212,CREPAND,21500,CREPAND,,47.6070603908,4.31019985069,212,,Crépand,Crépand,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21213,CRIMOLOIS,21800,CRIMOLOIS,,47.2702959266,5.12442359823,213,,Crimolois,Crimolois,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21214,CRUGEY,21360,CRUGEY,,47.1815437899,4.68076674992,214,,Crugey,Crugey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21215,CUISEREY,21310,CUISEREY,,47.370648619,5.31774907926,215,,Cuiserey,Cuiserey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21216,CULETRE,21230,CULETRE,,47.1468951211,4.56864595469,216,,Culètre,Culètre,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21217,CURLEY,21220,CURLEY,,47.2040051178,4.91078289972,217,,Curley,Curley,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21218,CURTIL ST SEINE,21380,CURTIL ST SEINE,,47.4607387752,4.92920241031,218,,Curtil-Saint-Seine,Curtil-Saint-Seine,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21219,CURTIL VERGY,21220,CURTIL VERGY,,47.1707777407,4.89403990669,219,,Curtil-Vergy,Curtil-Vergy,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21220,CUSSEY LES FORGES,21580,CUSSEY LES FORGES,,47.6376201743,5.0838274211,220,,Cussey-les-Forges,Cussey-les-Forges,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21221,CUSSY LA COLONNE,21360,CUSSY LA COLONNE,,47.0424415882,4.6482234956,221,,Cussy-la-Colonne,Cussy-la-Colonne,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21222,CUSSY LE CHATEL,21230,CUSSY LE CHATEL,,47.1643515054,4.58476170388,222,,Cussy-le-Châtel,Cussy-le-Châtel,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21223,DAIX,21121,DAIX,,47.3605540938,4.96901845433,223,,Daix,Daix,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21224,DAMPIERRE EN MONTAGNE,21350,DAMPIERRE EN MONTAGNE,,47.438807648,4.55900692915,224,,Dampierre-en-Montagne,Dampierre-en-Montagne,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21225,DAMPIERRE ET FLEE,21310,DAMPIERRE ET FLEE,,47.4802529906,5.35222754258,225,,Dampierre-et-Flée,Dampierre-et-Flée,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21226,DARCEY,21150,DARCEY,,47.5468714078,4.56688774894,226,,Darcey,Darcey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21227,DAROIS,21121,DAROIS,,47.3938381056,4.9323709651,227,,Darois,Darois,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21228,DETAIN ET BRUANT,21220,DETAIN ET BRUANT,,47.1748111785,4.78802289796,228,,Détain-et-Bruant,Détain-et-Bruant,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21229,DIANCEY,21430,DIANCEY,,47.1747778525,4.36718162662,229,,Diancey,Diancey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21230,DIENAY,21120,DIENAY,,47.5223127059,5.05949122065,230,,Diénay,Diénay,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21231,DIJON,21000,DIJON,,47.3229437965,5.03788805877,231,,Dijon,Dijon,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21232,DOMPIERRE EN MORVAN,21390,DOMPIERRE EN MORVAN,,47.4004660876,4.23216160368,232,,Dompierre-en-Morvan,Dompierre-en-Morvan,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21233,DRAMBON,21270,DRAMBON,,47.3340095649,5.37849298375,233,,Drambon,Drambon,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21234,DREE,21540,DREE,,47.343829262,4.69577530485,234,,Drée,Drée,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21235,DUESME,21510,DUESME,,47.6369569478,4.69319876077,235,,Duesme,Duesme,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21236,EBATY,21190,EBATY,,46.9293787687,4.78013604846,236,,Ébaty,Ébaty,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21237,ECHALOT,21510,ECHALOT,,47.6074344727,4.83509767429,237,,Échalot,Échalot,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21238,ECHANNAY,21540,ECHANNAY,,47.2810233886,4.68018125598,238,,Échannay,Échannay,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21239,ECHENON,21170,ECHENON,,47.1196116033,5.286880332,239,,Échenon,Échenon,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21240,ECHEVANNES,21120,ECHEVANNES,,47.5470867695,5.1597666211,240,,Échevannes,Échevannes,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21241,ECHEVRONNE,21420,ECHEVRONNE,,47.1076811377,4.83499706184,241,,Échevronne,Échevronne,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21242,ECHIGEY,21110,ECHIGEY,,47.1778490493,5.19986871339,242,,Échigey,Échigey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21243,ECUTIGNY,21360,ECUTIGNY,,47.0841130528,4.6234249566,243,,Écutigny,Écutigny,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21244,EGUILLY,21320,EGUILLY,,47.3010847339,4.49992675685,244,,Éguilly,Éguilly,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21245,EPAGNY,21380,EPAGNY,,47.4523461757,5.04973678654,245,,Épagny,Épagny,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21246,EPERNAY SOUS GEVREY,21220,EPERNAY SOUS GEVREY,,47.1745053731,5.03736521835,246,,Épernay-sous-Gevrey,Épernay-sous-Gevrey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21247,EPOISSES,21460,EPOISSES,,47.5033289277,4.17120505436,247,,Époisses,Époisses,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21248,ERINGES,21500,ERINGES,,47.5887565678,4.46703598761,248,,Éringes,Éringes,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21249,ESBARRES,21170,ESBARRES,,47.0910694543,5.19824663928,249,,Esbarres,Esbarres,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21250,ESSAROIS,21290,ESSAROIS,,47.7575910417,4.7939937712,250,,Essarois,Essarois,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21251,ESSEY,21320,ESSEY,,47.2063506091,4.52226390582,251,,Essey,Essey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21252,ETAIS,21500,ETAIS,,47.7014131668,4.44028509951,252,,Étais,Étais,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21253,ETALANTE,21510,ETALANTE,,47.629388545,4.76665603938,253,,Étalante,Étalante,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21254,L ETANG VERGY,21220,L ETANG VERGY,,47.1826265714,4.87602500249,254,L',Étang-Vergy,L'Étang-Vergy,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21255,ETAULES,21121,ETAULES,,47.4105824508,4.95035757108,255,,Étaules,Étaules,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21256,ETEVAUX,21270,ETEVAUX,,47.3235446456,5.32194001465,256,,Étevaux,Étevaux,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21257,ETORMAY,21450,ETORMAY,,47.5978554183,4.58158673769,257,,Étormay,Étormay,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21258,ETROCHEY,21400,ETROCHEY,,47.8893912326,4.51963624928,258,,Étrochey,Étrochey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21259,FAIN LES MONTBARD,21500,FAIN LES MONTBARD,,47.6174941653,4.40346528629,259,,Fain-lès-Montbard,Fain-lès-Montbard,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21260,FAIN LES MOUTIERS,21500,FAIN LES MOUTIERS,,47.5841078834,4.21121321982,260,,Fain-lès-Moutiers,Fain-lès-Moutiers,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21261,FAUVERNEY,21110,FAUVERNEY,,47.2625030215,5.15013738098,261,,Fauverney,Fauverney,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21262,FAVEROLLES LES LUCEY,21290,FAVEROLLES LES LUCEY,,47.8271140625,4.86669283243,262,,Faverolles-lès-Lucey,Faverolles-lès-Lucey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21263,FENAY,21600,FENAY,,47.2451587687,5.0541254561,263,,Fénay,Fénay,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21264,LE FETE,21230,LE FETE,,47.1799351721,4.50838011186,264,Le,Fête,Le Fête,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21265,FIXIN,21220,FIXIN,,47.2483860424,4.96654178119,265,,Fixin,Fixin,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21266,FLACEY,21490,FLACEY,,47.4343838109,5.13481855634,266,,Flacey,Flacey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21267,FLAGEY ECHEZEAUX,21640,FLAGEY ECHEZEAUX,,47.1594712834,4.98251892542,267,,Flagey-Echézeaux,Flagey-Echézeaux,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21268,FLAGEY LES AUXONNE,21130,FLAGEY LES AUXONNE,,47.1312658767,5.3944404843,268,,Flagey-lès-Auxonne,Flagey-lès-Auxonne,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21269,FLAMMERANS,21130,FLAMMERANS,,47.230058163,5.44115548466,269,,Flammerans,Flammerans,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21270,FLAVIGNEROT,21160,FLAVIGNEROT,,47.2778682909,4.91791708848,270,,Flavignerot,Flavignerot,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21271,FLAVIGNY SUR OZERAIN,21150,FLAVIGNY SUR OZERAIN,,47.5087961778,4.51921128225,271,,Flavigny-sur-Ozerain,Flavigny-sur-Ozerain,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21272,FLEE,21140,FLEE,,47.4407816289,4.33705444779,272,,Flée,Flée,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21273,FLEUREY SUR OUCHE,21410,FLEUREY SUR OUCHE,,47.3003433625,4.86448475268,273,,Fleurey-sur-Ouche,Fleurey-sur-Ouche,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21274,FOISSY,21230,FOISSY,,47.1251182221,4.55154801206,274,,Foissy,Foissy,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21275,FONCEGRIVE,21260,FONCEGRIVE,,47.6173922862,5.14245423253,275,,Foncegrive,Foncegrive,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21276,FONTAINES EN DUESMOIS,21450,FONTAINES EN DUESMOIS,,47.6493383803,4.53511047065,276,,Fontaines-en-Duesmois,Fontaines-en-Duesmois,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21277,FONTAINE FRANCAISE,21610,FONTAINE FRANCAISE,,47.5330200113,5.36407135172,277,,Fontaine-Française,Fontaine-Française,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21278,FONTAINE LES DIJON,21121,FONTAINE LES DIJON,,47.347590176,5.0247087947,278,,Fontaine-lès-Dijon,Fontaine-lès-Dijon,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21279,FONTAINES LES SECHES,21330,FONTAINES LES SECHES,,47.7844430098,4.35896530181,279,,Fontaines-les-Sèches,Fontaines-les-Sèches,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21280,FONTANGY,21390,FONTANGY,,47.3457961806,4.3719767381,280,,Fontangy,Fontangy,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21281,FONTENELLE,21610,FONTENELLE,,47.502916038,5.36176306939,281,,Fontenelle,Fontenelle,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21282,FORLEANS,21460,FORLEANS,,47.4712138957,4.20681713671,282,,Forléans,Forléans,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21283,FRAIGNOT ET VESVROTTE,21580,FRAIGNOT ET VESVROTTE,,47.6494704786,4.9384456562,283,,Fraignot-et-Vesvrotte,Fraignot-et-Vesvrotte,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21284,FRANCHEVILLE,21440,FRANCHEVILLE,,47.4593035477,4.87971245404,284,,Francheville,Francheville,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21285,FRANXAULT,21170,FRANXAULT,,47.0475621038,5.26433353481,285,,Franxault,Franxault,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21286,FRENOIS,21120,FRENOIS,,47.5214332358,4.8907289987,286,,Frénois,Frénois,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21287,FRESNES,21500,FRESNES,,47.6097085896,4.43871548951,287,,Fresnes,Fresnes,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21288,FROLOIS,21150,FROLOIS,,47.5293434145,4.6477491814,288,,Frôlois,Frôlois,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21289,FUSSEY,21700,FUSSEY,,47.1289690421,4.81617367712,289,,Fussey,Fussey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21290,GEMEAUX,21120,GEMEAUX,,47.4772510476,5.14006797437,290,,Gemeaux,Gemeaux,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21291,GENAY,21140,GENAY,,47.5245250886,4.28444386819,291,,Genay,Genay,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21292,GENLIS,21110,GENLIS,,47.2407395134,5.2209768706,292,,Genlis,Genlis,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21293,GERGUEIL,21410,GERGUEIL,,47.2376944982,4.81254550284,293,,Gergueil,Gergueil,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21294,GERLAND,21700,GERLAND,,47.1023774932,5.02325039471,294,,Gerland,Gerland,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21295,GEVREY CHAMBERTIN,21220,GEVREY CHAMBERTIN,,47.2200822946,4.98301612333,295,,Gevrey-Chambertin,Gevrey-Chambertin,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21296,GEVROLLES,21520,GEVROLLES,,47.9895635621,4.7506884064,296,,Gevrolles,Gevrolles,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21297,GILLY LES CITEAUX,21640,GILLY LES CITEAUX,,47.1779786537,4.99751060917,297,,Gilly-lès-Cîteaux,Gilly-lès-Cîteaux,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21298,GISSEY LE VIEIL,21350,GISSEY LE VIEIL,,47.3221932401,4.4861696452,298,,Gissey-le-Vieil,Gissey-le-Vieil,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21299,GISSEY SOUS FLAVIGNY,21150,GISSEY SOUS FLAVIGNY,,47.5177863232,4.58559367649,299,,Gissey-sous-Flavigny,Gissey-sous-Flavigny,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21300,GISSEY SUR OUCHE,21410,GISSEY SUR OUCHE,,47.2606866553,4.78299335665,300,,Gissey-sur-Ouche,Gissey-sur-Ouche,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21301,GLANON,21250,GLANON,,47.0397116967,5.10712051482,301,,Glanon,Glanon,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21302,GOMMEVILLE,21400,GOMMEVILLE,,47.9569435042,4.48444403788,302,,Gomméville,Gomméville,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21303,LES GOULLES,21520,LES GOULLES,,47.8780247601,4.90572088017,303,Les,Goulles,Les Goulles,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21304,GRANCEY LE CHATEAU NEUVELLE,21580,GRANCEY LE CHATEAU,,47.6736710294,5.02411190173,304,,Grancey-le-Château-Neuvelle,Grancey-le-Château-Neuvelle,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21304,GRANCEY LE CHATEAU NEUVELLE,21580,GRANCEY LE CHATEAU,NEUVELLE LES GRANCEY,47.6736710294,5.02411190173,304,,Grancey-le-Château-Neuvelle,Grancey-le-Château-Neuvelle,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21305,GRANCEY SUR OURCE,21570,GRANCEY SUR OURCE,,47.9996727259,4.57740862706,305,,Grancey-sur-Ource,Grancey-sur-Ource,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21306,GRENANT LES SOMBERNON,21540,GRENANT LES SOMBERNON,,47.2689950151,4.71215611881,306,,Grenant-lès-Sombernon,Grenant-lès-Sombernon,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21307,GRESIGNY STE REINE,21150,GRESIGNY STE REINE,,47.5517042908,4.49530823362,307,,Grésigny-Sainte-Reine,Grésigny-Sainte-Reine,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21308,GRIGNON,21150,GRIGNON,,47.5585918408,4.40554023735,308,,Grignon,Grignon,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21309,GRISELLES,21330,GRISELLES,,47.8761341376,4.36546770066,309,,Griselles,Griselles,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21310,GROSBOIS EN MONTAGNE,21540,GROSBOIS EN MONTAGNE,,47.3192163677,4.59964141298,310,,Grosbois-en-Montagne,Grosbois-en-Montagne,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21311,GROSBOIS LES TICHEY,21250,GROSBOIS LES TICHEY,,47.0090636768,5.2366823459,311,,Grosbois-lès-Tichey,Grosbois-lès-Tichey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21312,GURGY LA VILLE,21290,GURGY LA VILLE,,47.8522196324,4.93836423447,312,,Gurgy-la-Ville,Gurgy-la-Ville,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21313,GURGY LE CHATEAU,21290,GURGY LE CHATEAU,,47.8288662718,4.92751897409,313,,Gurgy-le-Château,Gurgy-le-Château,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21314,HAUTEROCHE,21150,HAUTEROCHE,,47.4884901774,4.57055360819,314,,Hauteroche,Hauteroche,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21315,HAUTEVILLE LES DIJON,21121,HAUTEVILLE LES DIJON,,47.3757754757,4.98131727526,315,,Hauteville-lès-Dijon,Hauteville-lès-Dijon,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21316,HEUILLEY SUR SAONE,21270,HEUILLEY SUR SAONE,,47.3358079869,5.46489125739,316,,Heuilley-sur-Saône,Heuilley-sur-Saône,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21317,IS SUR TILLE,21120,IS SUR TILLE,,47.5263318229,5.10204412547,317,,Is-sur-Tille,Is-sur-Tille,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21319,IZEURE,21110,IZEURE,,47.1662982943,5.12029891779,319,,Izeure,Izeure,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21320,IZIER,21110,IZIER,,47.2813484064,5.19627066906,320,,Izier,Izier,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21321,JAILLY LES MOULINS,21150,JAILLY LES MOULINS,,47.4626863743,4.59400738183,321,,Jailly-les-Moulins,Jailly-les-Moulins,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21322,JALLANGES,21250,JALLANGES,,46.9844079109,5.17269015437,322,,Jallanges,Jallanges,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21323,JANCIGNY,21310,JANCIGNY,,47.3834784994,5.42112913608,323,,Jancigny,Jancigny,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21324,JEUX LES BARD,21460,JEUX LES BARD,,47.5318872622,4.25532260801,324,,Jeux-lès-Bard,Jeux-lès-Bard,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21325,JOUEY,21230,JOUEY,,47.1581126666,4.4375513171,325,,Jouey,Jouey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21326,JOURS LES BAIGNEUX,21450,JOURS LES BAIGNEUX,,47.6253821222,4.60399533048,326,,Jours-lès-Baigneux,Jours-lès-Baigneux,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21327,VAL MONT,21340,VAL MONT,IVRY EN MONTAGNE,47.0387310414,4.59082588585,327,,Val-Mont,Val-Mont,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21327,VAL MONT,21340,VAL MONT,JOURS EN VAUX,47.0387310414,4.59082588585,327,,Val-Mont,Val-Mont,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21328,JUILLENAY,21210,JUILLENAY,,47.3613728511,4.27210840782,328,,Juillenay,Juillenay,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21329,JUILLY,21140,JUILLY,,47.4902826787,4.40108414456,329,,Juilly,Juilly,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21330,LABERGEMENT FOIGNEY,21110,LABERGEMENT FOIGNEY,,47.2578064369,5.24786490582,330,,Labergement-Foigney,Labergement-Foigney,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21331,LABERGEMENT LES AUXONNE,21130,LABERGEMENT LES AUXONNE,,47.1593953022,5.37863306845,331,,Labergement-lès-Auxonne,Labergement-lès-Auxonne,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21332,LABERGEMENT LES SEURRE,21820,LABERGEMENT LES SEURRE,,46.9957329015,5.08792946182,332,,Labergement-lès-Seurre,Labergement-lès-Seurre,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21333,LABRUYERE,21250,LABRUYERE,,47.0383104584,5.14163984518,333,,Labruyère,Labruyère,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21334,LACANCHE,21230,LACANCHE,,47.0750677068,4.55599388043,334,,Lacanche,Lacanche,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21335,LACOUR D ARCENAY,21210,LACOUR D ARCENAY,,47.3599600361,4.23532067428,335,,Lacour-d'Arcenay,Lacour-d'Arcenay,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21336,LAIGNES,21330,LAIGNES,,47.8261016815,4.36829803111,336,,Laignes,Laignes,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21337,LAMARCHE SUR SAONE,21760,LAMARCHE SUR SAONE,,47.272628371,5.3700531105,337,,Lamarche-sur-Saône,Lamarche-sur-Saône,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21338,LAMARGELLE,21440,LAMARGELLE,,47.5203693141,4.83729025625,338,,Lamargelle,Lamargelle,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21339,LANTENAY,21370,LANTENAY,,47.3437761758,4.86573170898,339,,Lantenay,Lantenay,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21340,LANTHES,21250,LANTHES,,46.9990158896,5.20408699445,340,,Lanthes,Lanthes,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21341,LANTILLY,21140,LANTILLY,,47.5421056928,4.37838223017,341,,Lantilly,Lantilly,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21342,LAPERRIERE SUR SAONE,21170,LAPERRIERE SUR SAONE,,47.1063954039,5.34614793707,342,,Laperrière-sur-Saône,Laperrière-sur-Saône,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21343,LARREY,21330,LARREY,,47.897345637,4.42446756868,343,,Larrey,Larrey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21344,LECHATELET,21250,LECHATELET,,47.0530922759,5.14157005423,344,,Lechâtelet,Lechâtelet,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21345,LERY,21440,LERY,,47.5563340151,4.84629917449,345,,Léry,Léry,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21346,LEUGLAY,21290,LEUGLAY,,47.8137240712,4.81901866828,346,,Leuglay,Leuglay,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21347,LEVERNOIS,21200,LEVERNOIS,,46.9937203239,4.87163680948,347,,Levernois,Levernois,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21348,LICEY SUR VINGEANNE,21610,LICEY SUR VINGEANNE,,47.4868270493,5.37313028131,348,,Licey-sur-Vingeanne,Licey-sur-Vingeanne,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21349,LIERNAIS,21430,LIERNAIS,,47.2198460424,4.28865681404,349,,Liernais,Liernais,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21350,LIGNEROLLES,21520,LIGNEROLLES,,47.9017137167,4.88758854906,350,,Lignerolles,Lignerolles,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21351,LONGCHAMP,21110,LONGCHAMP,,47.2617509825,5.295682026,351,,Longchamp,Longchamp,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21352,LONGEAULT,21110,LONGEAULT,,47.2217035106,5.25044095624,352,,Longeault,Longeault,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21353,LONGECOURT EN PLAINE,21110,LONGECOURT EN PLAINE,,47.1979962119,5.14631523953,353,,Longecourt-en-Plaine,Longecourt-en-Plaine,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21354,LONGECOURT LES CULETRE,21230,LONGECOURT LES CULETRE,,47.1604658111,4.55141809098,354,,Longecourt-lès-Culêtre,Longecourt-lès-Culêtre,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21355,LONGVIC,21600,LONGVIC,,47.285832398,5.05278368483,355,,Longvic,Longvic,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21356,LOSNE,21170,LOSNE,,47.0776286494,5.2762318698,356,,Losne,Losne,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21357,LOUESME,21520,LOUESME,,47.8893078984,4.76394651159,357,,Louesme,Louesme,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21358,LUCENAY LE DUC,21150,LUCENAY LE DUC,,47.6225345197,4.49972011434,358,,Lucenay-le-Duc,Lucenay-le-Duc,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21359,LUCEY,21290,LUCEY,,47.8522055495,4.8627475209,359,,Lucey,Lucey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21360,LUSIGNY SUR OUCHE,21360,LUSIGNY SUR OUCHE,,47.0825921168,4.68300097663,360,,Lusigny-sur-Ouche,Lusigny-sur-Ouche,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21361,LUX,21120,LUX,,47.4883380646,5.21763359421,361,,Lux,Lux,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21362,MACONGE,21320,MACONGE,,47.2256941351,4.57793028997,362,,Maconge,Maconge,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21363,MAGNIEN,21230,MAGNIEN,,47.1094556345,4.43331214138,363,,Magnien,Magnien,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21364,MAGNY LAMBERT,21450,MAGNY LAMBERT,,47.6788317802,4.58658221612,364,,Magny-Lambert,Magny-Lambert,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21365,MAGNY LA VILLE,21140,MAGNY LA VILLE,,47.479838166,4.42998686533,365,,Magny-la-Ville,Magny-la-Ville,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21366,MAGNY LES AUBIGNY,21170,MAGNY LES AUBIGNY,,47.1123094522,5.16861149822,366,,Magny-lès-Aubigny,Magny-lès-Aubigny,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21367,MAGNY MONTARLOT,21130,MAGNY MONTARLOT,,47.2486656169,5.34673748926,367,,Magny-Montarlot,Magny-Montarlot,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21368,MAGNY LES VILLERS,21700,MAGNY LES VILLERS,,47.0941869069,4.87453394791,368,,Magny-lès-Villers,Magny-lès-Villers,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21369,MAGNY ST MEDARD,21310,MAGNY ST MEDARD,,47.3813252899,5.24751740638,369,,Magny-Saint-Médard,Magny-Saint-Médard,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21370,MAGNY SUR TILLE,21110,MAGNY SUR TILLE,,47.2739632759,5.17279163884,370,,Magny-sur-Tille,Magny-sur-Tille,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21371,LES MAILLYS,21130,LES MAILLYS,,47.1456968654,5.32935525121,371,Les,Maillys,Les Maillys,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21372,MAISEY LE DUC,21400,MAISEY LE DUC,,47.8448667792,4.67600444524,372,,Maisey-le-Duc,Maisey-le-Duc,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21373,MALAIN,21410,MALAIN,,47.3212468007,4.79374280109,373,,Mâlain,Mâlain,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21374,MALIGNY,21230,MALIGNY,,47.0845064806,4.50527523907,374,,Maligny,Maligny,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21375,MANLAY,21430,MANLAY,,47.1203533728,4.33317142966,375,,Manlay,Manlay,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21376,MARANDEUIL,21270,MARANDEUIL,,47.3453618819,5.34081776332,376,,Marandeuil,Marandeuil,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21377,MARCELLOIS,21350,MARCELLOIS,,47.3488163449,4.61557807923,377,,Marcellois,Marcellois,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21378,MARCENAY,21330,MARCENAY,,47.8611145117,4.40461993622,378,,Marcenay,Marcenay,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21379,MARCHESEUIL,21430,MARCHESEUIL,,47.1449732022,4.37213113513,379,,Marcheseuil,Marcheseuil,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21380,MARCIGNY SOUS THIL,21390,MARCIGNY SOUS THIL,,47.3913107616,4.38452242711,380,,Marcigny-sous-Thil,Marcigny-sous-Thil,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21381,MARCILLY ET DRACY,21350,MARCILLY ET DRACY,,47.4053843394,4.49597262896,381,,Marcilly-et-Dracy,Marcilly-et-Dracy,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21382,MARCILLY OGNY,21320,MARCILLY OGNY,,47.2485958076,4.40242796827,382,,Marcilly-Ogny,Marcilly-Ogny,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21383,MARCILLY SUR TILLE,21120,MARCILLY SUR TILLE,,47.5125107909,5.12883412558,383,,Marcilly-sur-Tille,Marcilly-sur-Tille,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21384,MAREY LES FUSSEY,21700,MAREY LES FUSSEY,,47.1216169151,4.86215236749,384,,Marey-lès-Fussey,Marey-lès-Fussey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21385,MAREY SUR TILLE,21120,MAREY SUR TILLE,,47.5943124661,5.08179187571,385,,Marey-sur-Tille,Marey-sur-Tille,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21386,MARIGNY LE CAHOUET,21150,MARIGNY LE CAHOUET,,47.4553560135,4.45343866835,386,,Marigny-le-Cahouët,Marigny-le-Cahouët,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21387,MARIGNY LES REULLEE,21200,MARIGNY LES REULLEE,,47.0078863586,4.95818948974,387,,Marigny-lès-Reullée,Marigny-lès-Reullée,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21388,MARLIENS,21110,MARLIENS,,47.2164536426,5.17800484677,388,,Marliens,Marliens,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21389,MARMAGNE,21500,MARMAGNE,,47.6397063583,4.38235676513,389,,Marmagne,Marmagne,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21390,MARSANNAY LA COTE,21160,MARSANNAY LA COTE,,47.2790059419,4.98450898516,390,,Marsannay-la-Côte,Marsannay-la-Côte,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21391,MARSANNAY LE BOIS,21380,MARSANNAY LE BOIS,,47.4402798544,5.09615121255,391,,Marsannay-le-Bois,Marsannay-le-Bois,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21392,MARTROIS,21320,MARTROIS,,47.3068577261,4.54607714165,392,,Martrois,Martrois,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21393,MASSINGY,21400,MASSINGY,,47.9022741112,4.59925018266,393,,Massingy,Massingy,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21394,MASSINGY LES SEMUR,21140,MASSINGY LES SEMUR,,47.5164585084,4.40567595298,394,,Massingy-lès-Semur,Massingy-lès-Semur,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21395,MASSINGY LES VITTEAUX,21350,MASSINGY LES VITTEAUX,,47.395705764,4.59045021974,395,,Massingy-lès-Vitteaux,Massingy-lès-Vitteaux,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21396,MAUVILLY,21510,MAUVILLY,,47.7096666465,4.70428549427,396,,Mauvilly,Mauvilly,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21397,MAVILLY MANDELOT,21190,MAVILLY MANDELOT,,47.0605876593,4.73091558724,397,,Mavilly-Mandelot,Mavilly-Mandelot,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21398,MAXILLY SUR SAONE,21270,MAXILLY SUR SAONE,,47.3312115435,5.41954426061,398,,Maxilly-sur-Saône,Maxilly-sur-Saône,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21399,MEILLY SUR ROUVRES,21320,MEILLY SUR ROUVRES,,47.2060045019,4.55254034085,399,,Meilly-sur-Rouvres,Meilly-sur-Rouvres,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21400,LE MEIX,21580,LE MEIX,,47.594167838,4.94307607169,400,Le,Meix,Le Meix,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21401,MELOISEY,21190,MELOISEY,,47.034440345,4.72026948117,401,,Meloisey,Meloisey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21402,MENESBLE,21290,MENESBLE,,47.778606574,4.90206528914,402,,Menesble,Menesble,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21403,MENESSAIRE,21430,MENESSAIRE,,47.1335338458,4.15144155728,403,,Ménessaire,Ménessaire,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21404,MENETREUX LE PITOIS,21150,MENETREUX LE PITOIS,,47.559690561,4.45956971302,404,,Ménétreux-le-Pitois,Ménétreux-le-Pitois,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21405,MERCEUIL,21190,MERCEUIL,,46.9538298366,4.84790991199,405,,Merceuil,Merceuil,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21406,MESMONT,21540,MESMONT,,47.3122266518,4.74410039616,406,,Mesmont,Mesmont,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21407,MESSANGES,21220,MESSANGES,,47.1618456396,4.86963425286,407,,Messanges,Messanges,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21408,MESSIGNY ET VANTOUX,21380,MESSIGNY ET VANTOUX,,47.4189090037,5.00144598531,408,,Messigny-et-Vantoux,Messigny-et-Vantoux,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21408,MESSIGNY ET VANTOUX,21380,MESSIGNY ET VANTOUX,VANTOUX LES DIJON,47.4189090037,5.00144598531,408,,Messigny-et-Vantoux,Messigny-et-Vantoux,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21409,MEUILLEY,21700,MEUILLEY,,47.1410996463,4.87828584204,409,,Meuilley,Meuilley,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21410,MEULSON,21510,MEULSON,,47.6921345522,4.70218066483,410,,Meulson,Meulson,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21411,MEURSANGES,21200,MEURSANGES,,46.9815545496,4.94294705881,411,,Meursanges,Meursanges,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21412,MEURSAULT,21190,MEURSAULT,,46.9702784903,4.77216959331,412,,Meursault,Meursault,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21413,MILLERY,21140,MILLERY,,47.5135595984,4.31750870868,413,,Millery,Millery,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21414,MIMEURE,21230,MIMEURE,,47.1523999496,4.49524544815,414,,Mimeure,Mimeure,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21415,MINOT,21510,MINOT,,47.6626858601,4.87161006058,415,,Minot,Minot,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21416,MIREBEAU SUR BEZE,21310,MIREBEAU SUR BEZE,,47.4024616873,5.32235906024,416,,Mirebeau-sur-Bèze,Mirebeau-sur-Bèze,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21417,MISSERY,21210,MISSERY,,47.3113991733,4.3748363235,417,,Missery,Missery,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21418,MOITRON,21510,MOITRON,,47.681789677,4.80835298822,418,,Moitron,Moitron,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21419,MOLESME,21330,MOLESME,,47.94702901,4.38133026701,419,,Molesme,Molesme,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21420,MOLINOT,21340,MOLINOT,,47.0112430427,4.57969818527,420,,Molinot,Molinot,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21421,MOLOY,21120,MOLOY,,47.5393714062,4.92171403878,421,,Moloy,Moloy,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21422,MOLPHEY,21210,MOLPHEY,,47.33634862,4.20666118075,422,,Molphey,Molphey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21423,MONTAGNY LES BEAUNE,21200,MONTAGNY LES BEAUNE,,46.9849200277,4.8539733561,423,,Montagny-lès-Beaune,Montagny-lès-Beaune,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21424,MONTAGNY LES SEURRE,21250,MONTAGNY LES SEURRE,,47.0221158476,5.25126130211,424,,Montagny-lès-Seurre,Montagny-lès-Seurre,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21425,MONTBARD,21500,MONTBARD,,47.6576730148,4.35039569753,425,,Montbard,Montbard,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21426,MONTBERTHAULT,21460,MONTBERTHAULT,,47.4611172528,4.15752601087,426,,Montberthault,Montberthault,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21427,MONTCEAU ET ECHARNANT,21360,MONTCEAU ET ECHARNANT,,47.0609045646,4.6666655237,427,,Montceau-et-Écharnant,Montceau-et-Écharnant,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21428,MONTHELIE,21190,MONTHELIE,,46.999641101,4.76134767684,428,,Monthelie,Monthelie,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21429,MONTIGNY MONTFORT,21500,MONTIGNY MONTFORT,,47.5835649426,4.33909618195,429,,Montigny-Montfort,Montigny-Montfort,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21430,MONTIGNY ST BARTHELEMY,21390,MONTIGNY ST BARTHELEMY,,47.4200644388,4.26152648889,430,,Montigny-Saint-Barthélemy,Montigny-Saint-Barthélemy,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21431,MONTIGNY SUR ARMANCON,21140,MONTIGNY SUR ARMANCON,,47.4400198641,4.37366154755,431,,Montigny-sur-Armançon,Montigny-sur-Armançon,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21432,MONTIGNY SUR AUBE,21520,MONTIGNY SUR AUBE,,47.9541936296,4.75595683457,432,,Montigny-sur-Aube,Montigny-sur-Aube,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21433,MONTIGNY MORNAY VILLENEUVE VINGEANNE,21610,MONTIGNY MORNAY VILLENEUVE VINGE,,47.5685941578,5.45036193766,433,,Montigny-Mornay-Villeneuve-sur-Vingeanne,Montigny-Mornay-Villeneuve-sur-Vingeanne,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21433,MONTIGNY MORNAY VILLENEUVE VINGEANNE,21610,MONTIGNY MORNAY VILLENEUVE VINGE,LA VILLENEUVE SUR VINGEANNE,47.5685941578,5.45036193766,433,,Montigny-Mornay-Villeneuve-sur-Vingeanne,Montigny-Mornay-Villeneuve-sur-Vingeanne,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21433,MONTIGNY MORNAY VILLENEUVE VINGEANNE,21610,MONTIGNY MORNAY VILLENEUVE VINGE,MORNAY,47.5685941578,5.45036193766,433,,Montigny-Mornay-Villeneuve-sur-Vingeanne,Montigny-Mornay-Villeneuve-sur-Vingeanne,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21434,MONTLAY EN AUXOIS,21210,MONTLAY EN AUXOIS,,47.3313381255,4.26775681243,434,,Montlay-en-Auxois,Montlay-en-Auxois,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21435,MONTLIOT ET COURCELLES,21400,MONTLIOT ET COURCELLES,,47.8949444484,4.56001662806,435,,Montliot-et-Courcelles,Montliot-et-Courcelles,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21436,MONTMAIN,21250,MONTMAIN,,47.0277836478,5.05930420641,436,,Montmain,Montmain,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21437,MONTMANCON,21270,MONTMANCON,,47.355013072,5.37495539638,437,,Montmançon,Montmançon,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21438,MONTMOYEN,21290,MONTMOYEN,,47.7302494396,4.79445159568,438,,Montmoyen,Montmoyen,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21439,MONTOILLOT,21540,MONTOILLOT,,47.2727014053,4.65838043081,439,,Montoillot,Montoillot,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21440,MONTOT,21170,MONTOT,,47.1525081033,5.24351442373,440,,Montot,Montot,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21441,MONT ST JEAN,21320,MONT ST JEAN,,47.2858528062,4.4113796914,441,,Mont-Saint-Jean,Mont-Saint-Jean,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21442,MOREY ST DENIS,21220,MOREY ST DENIS,,47.1999076803,4.96207733314,442,,Morey-Saint-Denis,Morey-Saint-Denis,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21444,MOSSON,21400,MOSSON,,47.9206276016,4.62892350243,444,,Mosson,Mosson,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21445,LA MOTTE TERNANT,21210,LA MOTTE TERNANT,,47.3168367609,4.32727965395,445,La,Motte-Ternant,La Motte-Ternant,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21446,MOUTIERS ST JEAN,21500,MOUTIERS ST JEAN,,47.5623920251,4.2256453588,446,,Moutiers-Saint-Jean,Moutiers-Saint-Jean,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21447,MUSIGNY,21230,MUSIGNY,,47.1649661089,4.5232598082,447,,Musigny,Musigny,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21448,MUSSY LA FOSSE,21150,MUSSY LA FOSSE,,47.5214378007,4.44456513,448,,Mussy-la-Fosse,Mussy-la-Fosse,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21449,NAN SOUS THIL,21390,NAN SOUS THIL,,47.3809368143,4.35907058516,449,,Nan-sous-Thil,Nan-sous-Thil,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21450,NANTOUX,21190,NANTOUX,,47.0346400359,4.75951042223,450,,Nantoux,Nantoux,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21451,NESLE ET MASSOULT,21330,NESLE ET MASSOULT,,47.7780284045,4.42668944062,451,,Nesle-et-Massoult,Nesle-et-Massoult,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21452,NEUILLY LES DIJON,21800,NEUILLY LES DIJON,,47.2764524522,5.10401476317,452,,Neuilly-lès-Dijon,Neuilly-lès-Dijon,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21454,NICEY,21330,NICEY,,47.8654500555,4.29442620527,454,,Nicey,Nicey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21455,NOD SUR SEINE,21400,NOD SUR SEINE,,47.7726814986,4.5989721378,455,,Nod-sur-Seine,Nod-sur-Seine,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21456,NOGENT LES MONTBARD,21500,NOGENT LES MONTBARD,,47.6042846627,4.36951243241,456,,Nogent-lès-Montbard,Nogent-lès-Montbard,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21457,NOIDAN,21390,NOIDAN,,47.3467171158,4.40982170354,457,,Noidan,Noidan,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21458,NOIRON SOUS GEVREY,21910,NOIRON SOUS GEVREY,,47.1883989512,5.08502769038,458,,Noiron-sous-Gevrey,Noiron-sous-Gevrey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21459,NOIRON SUR BEZE,21310,NOIRON SUR BEZE,,47.4394112405,5.29872318112,459,,Noiron-sur-Bèze,Noiron-sur-Bèze,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21460,NOIRON SUR SEINE,21400,NOIRON SUR SEINE,,47.9386038243,4.46986601367,460,,Noiron-sur-Seine,Noiron-sur-Seine,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21461,NOLAY,21340,NOLAY,,46.958968613,4.61935844911,461,,Nolay,Nolay,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21461,NOLAY,21340,NOLAY,CIREY LES NOLAY,46.958968613,4.61935844911,461,,Nolay,Nolay,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21462,NORGES LA VILLE,21490,NORGES LA VILLE,,47.4070463216,5.06948581601,462,,Norges-la-Ville,Norges-la-Ville,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21463,NORMIER,21390,NORMIER,,47.3633828394,4.43446785437,463,,Normier,Normier,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21464,NUITS ST GEORGES,21700,NUITS ST GEORGES,,47.146518392,4.9385500417,464,,Nuits-Saint-Georges,Nuits-Saint-Georges,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21465,OBTREE,21400,OBTREE,,47.9312137105,4.56510115112,465,,Obtrée,Obtrée,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21466,OIGNY,21450,OIGNY,,47.5832673026,4.72467354211,466,,Oigny,Oigny,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21467,OISILLY,21310,OISILLY,,47.416992177,5.36192344642,467,,Oisilly,Oisilly,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21468,ORAIN,21610,ORAIN,,47.6102265012,5.43501562658,468,,Orain,Orain,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21469,ORGEUX,21490,ORGEUX,,47.3642286227,5.15182250045,469,,Orgeux,Orgeux,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21470,ORIGNY,21510,ORIGNY,,47.712880266,4.63220686716,470,,Origny,Origny,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21471,ORRET,21450,ORRET,,47.6088070958,4.7017226745,471,,Orret,Orret,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21472,ORVILLE,21260,ORVILLE,,47.5606296412,5.21672083508,472,,Orville,Orville,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21473,OUGES,21600,OUGES,,47.2613998117,5.0782109526,473,,Ouges,Ouges,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21474,PAGNY LA VILLE,21250,PAGNY LA VILLE,,47.0574519111,5.16871339995,474,,Pagny-la-Ville,Pagny-la-Ville,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21475,PAGNY LE CHATEAU,21250,PAGNY LE CHATEAU,,47.0499538846,5.21153347867,475,,Pagny-le-Château,Pagny-le-Château,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21476,PAINBLANC,21360,PAINBLANC,,47.1486717783,4.62462875846,476,,Painblanc,Painblanc,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21477,PANGES,21540,PANGES,,47.3739134919,4.80267156096,477,,Panges,Panges,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21478,PASQUES,21370,PASQUES,,47.3774354507,4.85215697056,478,,Pasques,Pasques,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21479,PELLEREY,21440,PELLEREY,,47.511765818,4.78886869504,479,,Pellerey,Pellerey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21480,PERNAND VERGELESSES,21420,PERNAND VERGELESSES,,47.0804785498,4.8493094586,480,,Pernand-Vergelesses,Pernand-Vergelesses,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21481,PERRIGNY LES DIJON,21160,PERRIGNY LES DIJON,,47.25760727,5.02045780663,481,,Perrigny-lès-Dijon,Perrigny-lès-Dijon,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21482,PERRIGNY SUR L OGNON,21270,PERRIGNY SUR L OGNON,,47.3052827359,5.4638499105,482,,Perrigny-sur-l'Ognon,Perrigny-sur-l'Ognon,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21483,PICHANGES,21120,PICHANGES,,47.4561192838,5.15835923398,483,,Pichanges,Pichanges,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21484,PLANAY,21500,PLANAY,,47.7372836307,4.37480750049,484,,Planay,Planay,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21485,PLOMBIERES LES DIJON,21370,PLOMBIERES LES DIJON,,47.3386201573,4.95005416615,485,,Plombières-lès-Dijon,Plombières-lès-Dijon,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21486,PLUVAULT,21110,PLUVAULT,,47.2083724566,5.24622576767,486,,Pluvault,Pluvault,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21487,PLUVET,21110,PLUVET,,47.1981396931,5.2636859618,487,,Pluvet,Pluvet,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21488,POINCON LES LARREY,21330,POINCON LES LARREY,,47.8689387712,4.45628317014,488,,Poinçon-lès-Larrey,Poinçon-lès-Larrey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21489,POISEUL LA GRANGE,21440,POISEUL LA GRANGE,,47.5770887187,4.79359106349,489,,Poiseul-la-Grange,Poiseul-la-Grange,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21490,POISEUL LA VILLE ET LAPERRIERE,21450,POISEUL LA VILLE ET LAPERRIERE,,47.5699836945,4.66438928448,490,,Poiseul-la-Ville-et-Laperrière,Poiseul-la-Ville-et-Laperrière,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21491,POISEUL LES SAULX,21120,POISEUL LES SAULX,,47.5785116858,4.98176331953,491,,Poiseul-lès-Saulx,Poiseul-lès-Saulx,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21492,POMMARD,21630,POMMARD,,47.0136884402,4.79517699243,492,,Pommard,Pommard,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21493,PONCEY LES ATHEE,21130,PONCEY LES ATHEE,,47.2359787197,5.38473838617,493,,Poncey-lès-Athée,Poncey-lès-Athée,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21494,PONCEY SUR L IGNON,21440,PONCEY SUR L IGNON,,47.4903530445,4.75222297541,494,,Poncey-sur-l'Ignon,Poncey-sur-l'Ignon,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21495,PONT,21130,PONT,,47.1789967649,5.32490570486,495,,Pont,Pont,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21496,PONTAILLER SUR SAONE,21270,PONTAILLER SUR SAONE,,47.3015930017,5.41228237116,496,,Pontailler-sur-Saône,Pontailler-sur-Saône,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21497,PONT ET MASSENE,21140,PONT ET MASSENE,,47.4690088064,4.35812492053,497,,Pont-et-Massène,Pont-et-Massène,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21498,POSANGES,21350,POSANGES,,47.4218431099,4.52541298855,498,,Posanges,Posanges,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21499,POTHIERES,21400,POTHIERES,,47.917342261,4.49676949979,499,,Pothières,Pothières,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21500,POUILLENAY,21150,POUILLENAY,,47.5031151334,4.46065492588,500,,Pouillenay,Pouillenay,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21501,POUILLY EN AUXOIS,21320,POUILLY EN AUXOIS,,47.2627722067,4.55560519834,501,,Pouilly-en-Auxois,Pouilly-en-Auxois,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21502,POUILLY SUR SAONE,21250,POUILLY SUR SAONE,,47.0111994809,5.12146704894,502,,Pouilly-sur-Saône,Pouilly-sur-Saône,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21503,POUILLY SUR VINGEANNE,21610,POUILLY SUR VINGEANNE,,47.5403544137,5.45162179953,503,,Pouilly-sur-Vingeanne,Pouilly-sur-Vingeanne,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21504,PRALON,21410,PRALON,,47.3104934057,4.77503055215,504,,Prâlon,Prâlon,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21505,PRECY SOUS THIL,21390,PRECY SOUS THIL,,47.4014711109,4.3167537134,505,,Précy-sous-Thil,Précy-sous-Thil,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21506,PREMEAUX PRISSEY,21700,PREMEAUX PRISSEY,,47.1049527311,4.9428568446,506,,Premeaux-Prissey,Premeaux-Prissey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21506,PREMEAUX PRISSEY,21700,PREMEAUX PRISSEY,PRISSEY,47.1049527311,4.9428568446,506,,Premeaux-Prissey,Premeaux-Prissey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21507,PREMIERES,21110,PREMIERES,,47.2386347373,5.2928819796,507,,Premières,Premières,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21508,PRENOIS,21370,PRENOIS,,47.3750238747,4.90589671665,508,,Prenois,Prenois,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21510,PRUSLY SUR OURCE,21400,PRUSLY SUR OURCE,,47.8802357803,4.64331266439,510,,Prusly-sur-Ource,Prusly-sur-Ource,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21511,PUITS,21400,PUITS,,47.7269454549,4.46741190187,511,,Puits,Puits,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21512,PULIGNY MONTRACHET,21190,PULIGNY MONTRACHET,,46.9467556144,4.75926217237,512,,Puligny-Montrachet,Puligny-Montrachet,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21513,QUEMIGNY POISOT,21220,QUEMIGNY POISOT,,47.236074247,4.84869116559,513,,Quemigny-Poisot,Quemigny-Poisot,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21514,QUEMIGNY SUR SEINE,21510,QUEMIGNY SUR SEINE,,47.65567229,4.6594167963,514,,Quemigny-sur-Seine,Quemigny-sur-Seine,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21515,QUETIGNY,21800,QUETIGNY,,47.3195635398,5.11575310181,515,,Quetigny,Quetigny,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21516,QUINCEROT,21500,QUINCEROT,,47.6174314295,4.27133698568,516,,Quincerot,Quincerot,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21517,QUINCEY,21700,QUINCEY,,47.1072396532,4.9719971557,517,,Quincey,Quincey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21518,QUINCY LE VICOMTE,21500,QUINCY LE VICOMTE,,47.6174690195,4.23973284667,518,,Quincy-le-Vicomte,Quincy-le-Vicomte,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21519,RECEY SUR OURCE,21290,RECEY SUR OURCE,,47.7803300768,4.86261547003,519,,Recey-sur-Ource,Recey-sur-Ource,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21520,REMILLY EN MONTAGNE,21540,REMILLY EN MONTAGNE,,47.29010269,4.72932529701,520,,Remilly-en-Montagne,Remilly-en-Montagne,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21521,REMILLY SUR TILLE,21560,REMILLY SUR TILLE,,47.3073839906,5.23140523397,521,,Remilly-sur-Tille,Remilly-sur-Tille,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21522,RENEVE,21310,RENEVE,,47.4109093151,5.40765013154,522,,Renève,Renève,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21523,REULLE VERGY,21220,REULLE VERGY,,47.1883731781,4.89680427712,523,,Reulle-Vergy,Reulle-Vergy,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21524,RIEL LES EAUX,21570,RIEL LES EAUX,,47.9883595832,4.6810588617,524,,Riel-les-Eaux,Riel-les-Eaux,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21525,LA ROCHE EN BRENIL,21530,LA ROCHE EN BRENIL,,47.3820372605,4.17164198526,525,La,Roche-en-Brenil,La Roche-en-Brenil,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21526,ROCHEFORT SUR BREVON,21510,ROCHEFORT SUR BREVON,,47.7409734699,4.70623994168,526,,Rochefort-sur-Brévon,Rochefort-sur-Brévon,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21527,LA ROCHEPOT,21340,LA ROCHEPOT,,46.9575762678,4.67620707547,527,La,Rochepot,La Rochepot,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21528,LA ROCHE VANNEAU,21150,LA ROCHE VANNEAU,,47.4708864303,4.52859660017,528,La,Roche-Vanneau,La Roche-Vanneau,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21529,ROILLY,21390,ROILLY,,47.4171745779,4.34078793389,529,,Roilly,Roilly,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21530,ROUGEMONT,21500,ROUGEMONT,,47.670003344,4.25730473718,530,,Rougemont,Rougemont,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21531,ROUVRAY,21530,ROUVRAY,,47.4174670252,4.11150660638,531,,Rouvray,Rouvray,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21532,ROUVRES EN PLAINE,21110,ROUVRES EN PLAINE,,47.2386287717,5.13846471292,532,,Rouvres-en-Plaine,Rouvres-en-Plaine,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21533,ROUVRES SOUS MEILLY,21320,ROUVRES SOUS MEILLY,,47.2062707536,4.5897431954,533,,Rouvres-sous-Meilly,Rouvres-sous-Meilly,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21534,RUFFEY LES BEAUNE,21200,RUFFEY LES BEAUNE,,47.0225171386,4.92262846681,534,,Ruffey-lès-Beaune,Ruffey-lès-Beaune,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21535,RUFFEY LES ECHIREY,21490,RUFFEY LES ECHIREY,,47.3676972071,5.08462624452,535,,Ruffey-lès-Echirey,Ruffey-lès-Echirey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21536,SACQUENAY,21260,SACQUENAY,,47.5842099756,5.32132637871,536,,Sacquenay,Sacquenay,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21537,SAFFRES,21350,SAFFRES,,47.3662639449,4.58064061534,537,,Saffres,Saffres,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21538,ST ANDEUX,21530,ST ANDEUX,,47.3957430173,4.0930702878,538,,Saint-Andeux,Saint-Andeux,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21539,ST ANTHOT,21540,ST ANTHOT,,47.3208885867,4.64348045859,539,,Saint-Anthot,Saint-Anthot,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21540,ST APOLLINAIRE,21850,ST APOLLINAIRE,,47.336030345,5.09322584658,540,,Saint-Apollinaire,Saint-Apollinaire,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21541,ST AUBIN,21190,ST AUBIN,,46.9504940966,4.71144303201,541,,Saint-Aubin,Saint-Aubin,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21542,ST BERNARD,21700,ST BERNARD,,47.1597856871,5.02381955265,542,,Saint-Bernard,Saint-Bernard,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21543,ST BROING LES MOINES,21290,ST BROING LES MOINES,,47.7009540963,4.84214518878,543,,Saint-Broing-les-Moines,Saint-Broing-les-Moines,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21544,STE COLOMBE EN AUXOIS,21350,STE COLOMBE EN AUXOIS,,47.4272975926,4.46203899793,544,,Sainte-Colombe-en-Auxois,Sainte-Colombe-en-Auxois,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21545,STE COLOMBE SUR SEINE,21400,STE COLOMBE SUR SEINE,,47.8608080678,4.52802612244,545,,Sainte-Colombe-sur-Seine,Sainte-Colombe-sur-Seine,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21546,ST DIDIER,21210,ST DIDIER,,47.3168067442,4.17339058953,546,,Saint-Didier,Saint-Didier,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21547,ST EUPHRONE,21140,ST EUPHRONE,,47.4668464015,4.39181152483,547,,Saint-Euphrône,Saint-Euphrône,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21548,ST GERMAIN DE MODEON,21530,ST GERMAIN DE MODEON,,47.3721000365,4.12417498472,548,,Saint-Germain-de-Modéon,Saint-Germain-de-Modéon,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21549,ST GERMAIN LE ROCHEUX,21510,ST GERMAIN LE ROCHEUX,,47.7561639307,4.66343630237,549,,Saint-Germain-le-Rocheux,Saint-Germain-le-Rocheux,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21550,ST GERMAIN LES SENAILLY,21500,ST GERMAIN LES SENAILLY,,47.5980519223,4.28133138948,550,,Saint-Germain-lès-Senailly,Saint-Germain-lès-Senailly,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21552,ST HELIER,21690,ST HELIER,,47.389027921,4.68824509954,552,,Saint-Hélier,Saint-Hélier,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21553,ST JEAN DE BOEUF,21410,ST JEAN DE BOEUF,,47.2113480166,4.76378552245,553,,Saint-Jean-de-Bœuf,Saint-Jean-de-Bœuf,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21554,ST JEAN DE LOSNE,21170,ST JEAN DE LOSNE,,47.1017053631,5.26653480307,554,,Saint-Jean-de-Losne,Saint-Jean-de-Losne,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21555,ST JULIEN,21490,ST JULIEN,,47.396053652,5.14161061569,555,,Saint-Julien,Saint-Julien,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21556,ST LEGER TRIEY,21270,ST LEGER TRIEY,,47.3166201603,5.35525672839,556,,Saint-Léger-Triey,Saint-Léger-Triey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21557,ST MARC SUR SEINE,21450,ST MARC SUR SEINE,,47.7000984346,4.59972785822,557,,Saint-Marc-sur-Seine,Saint-Marc-sur-Seine,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21558,STE MARIE LA BLANCHE,21200,STE MARIE LA BLANCHE,,46.9740829451,4.89156932927,558,,Sainte-Marie-la-Blanche,Sainte-Marie-la-Blanche,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21559,STE MARIE SUR OUCHE,21410,STE MARIE SUR OUCHE,,47.2887325943,4.80366866589,559,,Sainte-Marie-sur-Ouche,Sainte-Marie-sur-Ouche,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21559,STE MARIE SUR OUCHE,21410,STE MARIE SUR OUCHE,PONT DE PANY,47.2887325943,4.80366866589,559,,Sainte-Marie-sur-Ouche,Sainte-Marie-sur-Ouche,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21560,ST MARTIN DE LA MER,21210,ST MARTIN DE LA MER,,47.2373074547,4.22929777568,560,,Saint-Martin-de-la-Mer,Saint-Martin-de-la-Mer,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21561,ST MARTIN DU MONT,21440,ST MARTIN DU MONT,,47.4148513561,4.81194710404,561,,Saint-Martin-du-Mont,Saint-Martin-du-Mont,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21562,ST MAURICE SUR VINGEANNE,21610,ST MAURICE SUR VINGEANNE,,47.5793795925,5.39828562769,562,,Saint-Maurice-sur-Vingeanne,Saint-Maurice-sur-Vingeanne,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21563,ST MESMIN,21540,ST MESMIN,,47.3458158582,4.65536994421,563,,Saint-Mesmin,Saint-Mesmin,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21564,ST NICOLAS LES CITEAUX,21700,ST NICOLAS LES CITEAUX,,47.1291027258,5.08467105168,564,,Saint-Nicolas-lès-Cîteaux,Saint-Nicolas-lès-Cîteaux,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21565,ST PHILIBERT,21220,ST PHILIBERT,,47.2033144278,5.01321708085,565,,Saint-Philibert,Saint-Philibert,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21566,ST PIERRE EN VAUX,21230,ST PIERRE EN VAUX,,47.0569877471,4.52938380841,566,,Saint-Pierre-en-Vaux,Saint-Pierre-en-Vaux,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21567,ST PRIX LES ARNAY,21230,ST PRIX LES ARNAY,,47.1127122088,4.50442046153,567,,Saint-Prix-lès-Arnay,Saint-Prix-lès-Arnay,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21568,ST REMY,21500,ST REMY,,47.6514487621,4.30074825954,568,,Saint-Rémy,Saint-Rémy,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21569,ST ROMAIN,21190,ST ROMAIN,,47.0125065355,4.70264461223,569,,Saint-Romain,Saint-Romain,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21570,STE SABINE,21320,STE SABINE,,47.1875125214,4.62169164492,570,,Sainte-Sabine,Sainte-Sabine,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21571,ST SAUVEUR,21270,ST SAUVEUR,,47.3580525419,5.40644354145,571,,Saint-Sauveur,Saint-Sauveur,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21572,ST SEINE EN BACHE,21130,ST SEINE EN BACHE,,47.1160354094,5.37724582306,572,,Saint-Seine-en-Bâche,Saint-Seine-en-Bâche,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21573,ST SEINE L ABBAYE,21440,ST SEINE L ABBAYE,,47.4414380051,4.7823076914,573,,Saint-Seine-l'Abbaye,Saint-Seine-l'Abbaye,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21574,ST SEINE SUR VINGEANNE,21610,ST SEINE SUR VINGEANNE,,47.5168029251,5.43323666573,574,,Saint-Seine-sur-Vingeanne,Saint-Seine-sur-Vingeanne,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21575,ST SYMPHORIEN SUR SAONE,21170,ST SYMPHORIEN SUR SAONE,,47.0909529728,5.31775457592,575,,Saint-Symphorien-sur-Saône,Saint-Symphorien-sur-Saône,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21576,ST THIBAULT,21350,ST THIBAULT,,47.3777085565,4.46624986198,576,,Saint-Thibault,Saint-Thibault,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21577,ST USAGE,21170,ST USAGE,,47.1093598345,5.25017482483,577,,Saint-Usage,Saint-Usage,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21578,ST VICTOR SUR OUCHE,21410,ST VICTOR SUR OUCHE,,47.2323029806,4.74865693638,578,,Saint-Victor-sur-Ouche,Saint-Victor-sur-Ouche,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21579,SALIVES,21580,SALIVES,,47.6048838106,4.9005997334,579,,Salives,Salives,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21580,SALMAISE,21690,SALMAISE,,47.4550732587,4.67554399967,580,,Salmaise,Salmaise,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21581,SAMEREY,21170,SAMEREY,,47.0897010705,5.36611406242,581,,Samerey,Samerey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21582,SANTENAY,21590,SANTENAY,,46.9174107559,4.6933727007,582,,Santenay,Santenay,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21583,SANTOSSE,21340,SANTOSSE,,47.0068228015,4.63439106262,583,,Santosse,Santosse,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21584,SAULIEU,21210,SAULIEU,,47.2840306915,4.23192469784,584,,Saulieu,Saulieu,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21585,SAULON LA CHAPELLE,21910,SAULON LA CHAPELLE,,47.2106852941,5.09385756752,585,,Saulon-la-Chapelle,Saulon-la-Chapelle,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21586,SAULON LA RUE,21910,SAULON LA RUE,,47.2262535872,5.05001697299,586,,Saulon-la-Rue,Saulon-la-Rue,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21587,SAULX LE DUC,21120,SAULX LE DUC,,47.5504780732,5.02209606556,587,,Saulx-le-Duc,Saulx-le-Duc,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21588,SAUSSEY,21360,SAUSSEY,,47.0636406891,4.60953530207,588,,Saussey,Saussey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21589,SAUSSY,21380,SAUSSY,,47.4624808325,4.98146339505,589,,Saussy,Saussy,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21590,SAVIGNY LES BEAUNE,21420,SAVIGNY LES BEAUNE,,47.0806321437,4.80342918013,590,,Savigny-lès-Beaune,Savigny-lès-Beaune,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21591,SAVIGNY LE SEC,21380,SAVIGNY LE SEC,,47.434029559,5.04178102646,591,,Savigny-le-Sec,Savigny-le-Sec,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21592,SAVIGNY SOUS MALAIN,21540,SAVIGNY SOUS MALAIN,,47.3316328215,4.75386301231,592,,Savigny-sous-Mâlain,Savigny-sous-Mâlain,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21593,SAVILLY,21430,SAVILLY,,47.1223537467,4.27774791948,593,,Savilly,Savilly,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21594,SAVOISY,21500,SAVOISY,,47.7365116714,4.39978655066,594,,Savoisy,Savoisy,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21595,SAVOLLES,21310,SAVOLLES,,47.3778180325,5.27546231852,595,,Savolles,Savolles,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21596,SAVOUGES,21910,SAVOUGES,,47.1806736269,5.05798309916,596,,Savouges,Savouges,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21597,SEGROIS,21220,SEGROIS,,47.1609641771,4.89333944973,597,,Segrois,Segrois,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21598,SEIGNY,21150,SEIGNY,,47.5771836934,4.43932756147,598,,Seigny,Seigny,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21599,SELONGEY,21260,SELONGEY,,47.5949698633,5.19951068819,599,,Selongey,Selongey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21600,SEMAREY,21320,SEMAREY,,47.2681629756,4.62645384177,600,,Semarey,Semarey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21601,SEMEZANGES,21220,SEMEZANGES,,47.2155736002,4.85104991117,601,,Semezanges,Semezanges,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21602,SEMOND,21450,SEMOND,,47.7126613354,4.57244998829,602,,Semond,Semond,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21603,SEMUR EN AUXOIS,21140,SEMUR EN AUXOIS,,47.4909559267,4.34106366761,603,,Semur-en-Auxois,Semur-en-Auxois,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21604,SENAILLY,21500,SENAILLY,,47.5827263059,4.26729697814,604,,Senailly,Senailly,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21605,SENNECEY LES DIJON,21800,SENNECEY LES DIJON,,47.2920875173,5.10418551217,605,,Sennecey-lès-Dijon,Sennecey-lès-Dijon,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21606,LADOIX SERRIGNY,21550,LADOIX SERRIGNY,,47.0628547875,4.91151199048,606,,Ladoix-Serrigny,Ladoix-Serrigny,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21607,SEURRE,21250,SEURRE,,47.0074486166,5.15220213947,607,,Seurre,Seurre,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21608,SINCEY LES ROUVRAY,21530,SINCEY LES ROUVRAY,,47.4334510862,4.13103171651,608,,Sincey-lès-Rouvray,Sincey-lès-Rouvray,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21609,SOIRANS,21110,SOIRANS,,47.2105383431,5.29909358295,609,,Soirans,Soirans,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21610,SOISSONS SUR NACEY,21270,SOISSONS SUR NACEY,,47.2498583278,5.46166675348,610,,Soissons-sur-Nacey,Soissons-sur-Nacey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21611,SOMBERNON,21540,SOMBERNON,,47.3105432951,4.70317881741,611,,Sombernon,Sombernon,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21612,SOUHEY,21140,SOUHEY,,47.4876530398,4.421356507,612,,Souhey,Souhey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21613,SOUSSEY SUR BRIONNE,21350,SOUSSEY SUR BRIONNE,,47.3267546782,4.53446696852,613,,Soussey-sur-Brionne,Soussey-sur-Brionne,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21614,SPOY,21120,SPOY,,47.4458196658,5.18698392171,614,,Spoy,Spoy,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21615,SUSSEY,21430,SUSSEY,,47.2149293227,4.37242208694,615,,Sussey,Sussey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21615,SUSSEY,21430,SUSSEY,LE MAUPAS,47.2149293227,4.37242208694,615,,Sussey,Sussey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21616,TAILLY,21190,TAILLY,,46.9655888224,4.81679158031,616,,Tailly,Tailly,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21617,TALANT,21240,TALANT,,47.3388300285,4.99779516001,617,,Talant,Talant,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21618,TALMAY,21270,TALMAY,,47.3663925379,5.46063337832,618,,Talmay,Talmay,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21619,TANAY,21310,TANAY,,47.413987007,5.27742154521,619,,Tanay,Tanay,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21620,TARSUL,21120,TARSUL,,47.5185851932,4.9796405504,620,,Tarsul,Tarsul,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21621,TART L ABBAYE,21110,TART L ABBAYE,,47.183548045,5.24201161674,621,,Tart-l'Abbaye,Tart-l'Abbaye,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21622,TART LE BAS,21110,TART LE BAS,,47.206605373,5.22359243318,622,,Tart-le-Bas,Tart-le-Bas,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21623,TART LE HAUT,21110,TART LE HAUT,,47.1958586219,5.20512336576,623,,Tart-le-Haut,Tart-le-Haut,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21624,TELLECEY,21270,TELLECEY,,47.2904695595,5.29922695853,624,,Tellecey,Tellecey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21625,TERNANT,21220,TERNANT,,47.2001341536,4.83291859588,625,,Ternant,Ternant,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21626,TERREFONDREE,21290,TERREFONDREE,,47.7278892776,4.84233839612,626,,Terrefondrée,Terrefondrée,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21627,THENISSEY,21150,THENISSEY,,47.4964918792,4.61948858454,627,,Thenissey,Thenissey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21628,THOIRES,21570,THOIRES,,47.9407836384,4.6819546564,628,,Thoires,Thoires,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21629,THOISY LA BERCHERE,21210,THOISY LA BERCHERE,,47.2605521734,4.33200696212,629,,Thoisy-la-Berchère,Thoisy-la-Berchère,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21630,THOISY LE DESERT,21320,THOISY LE DESERT,,47.2496181728,4.5209241508,630,,Thoisy-le-Désert,Thoisy-le-Désert,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21631,THOMIREY,21360,THOMIREY,,47.0875870318,4.58687586309,631,,Thomirey,Thomirey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21632,THOREY EN PLAINE,21110,THOREY EN PLAINE,,47.2150904574,5.13836316838,632,,Thorey-en-Plaine,Thorey-en-Plaine,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21633,THOREY SOUS CHARNY,21350,THOREY SOUS CHARNY,,47.3298168474,4.44950750431,633,,Thorey-sous-Charny,Thorey-sous-Charny,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21634,THOREY SUR OUCHE,21360,THOREY SUR OUCHE,,47.1486545288,4.693092626,634,,Thorey-sur-Ouche,Thorey-sur-Ouche,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21635,THOSTE,21460,THOSTE,,47.4356430005,4.22726106818,635,,Thoste,Thoste,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21636,THURY,21340,THURY,,47.0285432675,4.52701419303,636,,Thury,Thury,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21637,TICHEY,21250,TICHEY,,47.0128057736,5.28875732536,637,,Tichey,Tichey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21638,TIL CHATEL,21120,TIL CHATEL,,47.5191360715,5.17912297276,638,,Til-Châtel,Til-Châtel,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21639,TILLENAY,21130,TILLENAY,,47.1889382841,5.3514431505,639,,Tillenay,Tillenay,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21640,TORCY ET POULIGNY,21460,TORCY ET POULIGNY,,47.4974003502,4.23612600971,640,,Torcy-et-Pouligny,Torcy-et-Pouligny,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21641,TOUILLON,21500,TOUILLON,,47.6589160745,4.43658043241,641,,Touillon,Touillon,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21642,TOUTRY,21460,TOUTRY,,47.5014647498,4.12836956975,642,,Toutry,Toutry,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21643,TRECLUN,21130,TRECLUN,,47.192371052,5.28848866288,643,,Tréclun,Tréclun,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21644,TROCHERES,21310,TROCHERES,,47.3481781981,5.31468752935,644,,Trochères,Trochères,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21645,TROUHANS,21170,TROUHANS,,47.1528128162,5.27088407904,645,,Trouhans,Trouhans,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21646,TROUHAUT,21440,TROUHAUT,,47.3907339814,4.76320070709,646,,Trouhaut,Trouhaut,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21647,TRUGNY,21250,TRUGNY,,46.9718830068,5.14601366703,647,,Trugny,Trugny,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21648,TURCEY,21540,TURCEY,,47.4086361991,4.71869514548,648,,Turcey,Turcey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21649,UNCEY LE FRANC,21350,UNCEY LE FRANC,,47.3434622037,4.57522013121,649,,Uncey-le-Franc,Uncey-le-Franc,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21650,URCY,21220,URCY,,47.2641409242,4.84612094396,650,,Urcy,Urcy,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21651,VAL SUZON,21121,VAL SUZON,,47.4271421558,4.90886346086,651,,Val-Suzon,Val-Suzon,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21652,VANDENESSE EN AUXOIS,21320,VANDENESSE EN AUXOIS,,47.2234387567,4.62083828495,652,,Vandenesse-en-Auxois,Vandenesse-en-Auxois,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21653,VANNAIRE,21400,VANNAIRE,,47.9154562474,4.57079661731,653,,Vannaire,Vannaire,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21655,VANVEY,21400,VANVEY,,47.8418840697,4.72616973539,655,,Vanvey,Vanvey,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21656,VARANGES,21110,VARANGES,,47.2348268799,5.19088383489,656,,Varanges,Varanges,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21657,VAROIS ET CHAIGNOT,21490,VAROIS ET CHAIGNOT,,47.3554476666,5.1255645784,657,,Varois-et-Chaignot,Varois-et-Chaignot,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21658,VAUCHIGNON,21340,VAUCHIGNON,,46.9815406669,4.65368563098,658,,Vauchignon,Vauchignon,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21659,VAUX SAULES,21440,VAUX SAULES,,47.4746631132,4.82267310049,659,,Vaux-Saules,Vaux-Saules,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21660,VEILLY,21360,VEILLY,,47.1271334446,4.59658929547,660,,Veilly,Veilly,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21661,VELARS SUR OUCHE,21370,VELARS SUR OUCHE,,47.3186399344,4.90983945871,661,,Velars-sur-Ouche,Velars-sur-Ouche,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21662,VELOGNY,21350,VELOGNY,,47.4020910842,4.46540583752,662,,Velogny,Velogny,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21663,VENAREY LES LAUMES,21150,VENAREY LES LAUMES,,47.5377240472,4.43966492373,663,,Venarey-les-Laumes,Venarey-les-Laumes,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21663,VENAREY LES LAUMES,21150,VENAREY LES LAUMES,LES LAUMES,47.5377240472,4.43966492373,663,,Venarey-les-Laumes,Venarey-les-Laumes,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21664,VERDONNET,21330,VERDONNET,,47.742034197,4.33534875629,664,,Verdonnet,Verdonnet,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21665,VERNOIS LES VESVRES,21260,VERNOIS LES VESVRES,,47.652604247,5.15024471811,665,,Vernois-lès-Vesvres,Vernois-lès-Vesvres,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21666,VERNOT,21120,VERNOT,,47.491246958,4.96330752528,666,,Vernot,Vernot,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21667,VERONNES,21260,VERONNES,,47.5355759344,5.23841425575,667,,Véronnes,Véronnes,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21667,VERONNES,21260,VERONNES,VERONNES LES PETITES,47.5355759344,5.23841425575,667,,Véronnes,Véronnes,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21669,VERREY SOUS DREE,21540,VERREY SOUS DREE,,47.368425324,4.68776283971,669,,Verrey-sous-Drée,Verrey-sous-Drée,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21670,VERREY SOUS SALMAISE,21690,VERREY SOUS SALMAISE,,47.4384612168,4.67446130828,670,,Verrey-sous-Salmaise,Verrey-sous-Salmaise,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21671,VERTAULT,21330,VERTAULT,,47.9246819197,4.32699218845,671,,Vertault,Vertault,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21672,VESVRES,21350,VESVRES,,47.3763024557,4.52885694721,672,,Vesvres,Vesvres,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21673,VEUVEY SUR OUCHE,21360,VEUVEY SUR OUCHE,,47.182310206,4.71718039392,673,,Veuvey-sur-Ouche,Veuvey-sur-Ouche,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21674,VEUXHAULLES SUR AUBE,21520,VEUXHAULLES SUR AUBE,,47.9393395308,4.81167181822,674,,Veuxhaulles-sur-Aube,Veuxhaulles-sur-Aube,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21675,VIANGES,21430,VIANGES,,47.1657435342,4.32416073393,675,,Vianges,Vianges,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21676,VIC DE CHASSENAY,21140,VIC DE CHASSENAY,,47.4708474849,4.26265511038,676,,Vic-de-Chassenay,Vic-de-Chassenay,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21677,VIC DES PRES,21360,VIC DES PRES,,47.1093098641,4.63600568257,677,,Vic-des-Prés,Vic-des-Prés,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21678,VIC SOUS THIL,21390,VIC SOUS THIL,,47.3670141656,4.31767568757,678,,Vic-sous-Thil,Vic-sous-Thil,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21679,VIEILMOULIN,21540,VIEILMOULIN,,47.3142426405,4.67284381693,679,,Vieilmoulin,Vieilmoulin,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21680,VIELVERGE,21270,VIELVERGE,,47.2683512325,5.45243261786,680,,Vielverge,Vielverge,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21681,VIEUX CHATEAU,21460,VIEUX CHATEAU,,47.4716319276,4.13565741481,681,,Vieux-Château,Vieux-Château,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21682,VIEVIGNE,21310,VIEVIGNE,,47.435689036,5.24044596193,682,,Viévigne,Viévigne,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21683,VIEVY,21230,VIEVY,,47.0576684335,4.45616718775,683,,Viévy,Viévy,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21684,VIGNOLES,21200,VIGNOLES,,47.025772583,4.88700502505,684,,Vignoles,Vignoles,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21685,VILLAINES EN DUESMOIS,21450,VILLAINES EN DUESMOIS,,47.6870774985,4.51967425037,685,,Villaines-en-Duesmois,Villaines-en-Duesmois,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21686,VILLAINES LES PREVOTES,21500,VILLAINES LES PREVOTES,,47.5537362446,4.30749786549,686,,Villaines-les-Prévôtes,Villaines-les-Prévôtes,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21687,VILLARGOIX,21210,VILLARGOIX,,47.29233714,4.29085327301,687,,Villargoix,Villargoix,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21688,VILLARS FONTAINE,21700,VILLARS FONTAINE,,47.1500426655,4.90096219055,688,,Villars-Fontaine,Villars-Fontaine,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21689,VILLARS ET VILLENOTTE,21140,VILLARS ET VILLENOTTE,,47.5089545362,4.37864819259,689,,Villars-et-Villenotte,Villars-et-Villenotte,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21690,VILLEBERNY,21350,VILLEBERNY,,47.4361931172,4.60070277203,690,,Villeberny,Villeberny,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21691,VILLEBICHOT,21700,VILLEBICHOT,,47.1401359622,5.04221971149,691,,Villebichot,Villebichot,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21692,VILLECOMTE,21120,VILLECOMTE,,47.4993295209,5.01912639867,692,,Villecomte,Villecomte,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21693,VILLEDIEU,21330,VILLEDIEU,,47.9076292898,4.38350951271,693,,Villedieu,Villedieu,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21694,VILLEFERRY,21350,VILLEFERRY,,47.4498866756,4.52944557667,694,,Villeferry,Villeferry,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21695,LA VILLENEUVE LES CONVERS,21450,LA VILLENEUVE LES CONVERS,,47.5761672449,4.59152552704,695,La,Villeneuve-les-Convers,La Villeneuve-les-Convers,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21696,VILLENEUVE SOUS CHARIGNY,21140,VILLENEUVE SOUS CHARIGNY,,47.4364542441,4.39988256271,696,,Villeneuve-sous-Charigny,Villeneuve-sous-Charigny,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21698,VILLERS LA FAYE,21700,VILLERS LA FAYE,,47.1087180571,4.88391750001,698,,Villers-la-Faye,Villers-la-Faye,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21699,VILLERS LES POTS,21130,VILLERS LES POTS,,47.2102286765,5.34734391445,699,,Villers-les-Pots,Villers-les-Pots,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21700,VILLERS PATRAS,21400,VILLERS PATRAS,,47.9345473428,4.5466694292,700,,Villers-Patras,Villers-Patras,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21701,VILLERS ROTIN,21130,VILLERS ROTIN,,47.156020714,5.40682525494,701,,Villers-Rotin,Villers-Rotin,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21702,VILLEY SUR TILLE,21120,VILLEY SUR TILLE,,47.5679614823,5.1093341205,702,,Villey-sur-Tille,Villey-sur-Tille,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21703,VILLIERS EN MORVAN,21430,VILLIERS EN MORVAN,,47.1432363648,4.25988582154,703,,Villiers-en-Morvan,Villiers-en-Morvan,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21704,VILLIERS LE DUC,21400,VILLIERS LE DUC,,47.792546579,4.70386832988,704,,Villiers-le-Duc,Villiers-le-Duc,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21705,VILLOTTE ST SEINE,21690,VILLOTTE ST SEINE,,47.4297198383,4.71318867842,705,,Villotte-Saint-Seine,Villotte-Saint-Seine,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21706,VILLOTTE SUR OURCE,21400,VILLOTTE SUR OURCE,,47.8665293113,4.6920408128,706,,Villotte-sur-Ource,Villotte-sur-Ource,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21707,VILLY EN AUXOIS,21350,VILLY EN AUXOIS,,47.4144498577,4.62905226658,707,,Villy-en-Auxois,Villy-en-Auxois,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21708,VILLY LE MOUTIER,21250,VILLY LE MOUTIER,,47.0390086783,4.98680226747,708,,Villy-le-Moutier,Villy-le-Moutier,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21709,VISERNY,21500,VISERNY,,47.5627164354,4.28367240429,709,,Viserny,Viserny,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21710,VITTEAUX,21350,VITTEAUX,,47.3974282764,4.54312292794,710,,Vitteaux,Vitteaux,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21711,VIX,21400,VIX,,47.9073526426,4.53670759724,711,,Vix,Vix,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21712,VOLNAY,21190,VOLNAY,,46.9995837009,4.78217107692,712,,Volnay,Volnay,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21713,VONGES,21270,VONGES,,47.2896205229,5.39637772205,713,,Vonges,Vonges,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21714,VOSNE ROMANEE,21700,VOSNE ROMANEE,,47.1598072854,4.95546198904,714,,Vosne-Romanée,Vosne-Romanée,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21715,VOUDENAY,21230,VOUDENAY,,47.0944234603,4.38030260977,715,,Voudenay,Voudenay,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21716,VOUGEOT,21640,VOUGEOT,,47.174271631,4.95989664941,716,,Vougeot,Vougeot,21,Côte-d'Or,27,Bourgogne-Franche-Comté
21717,VOULAINES LES TEMPLIERS,21290,VOULAINES LES TEMPLIERS,,47.8306417712,4.77656655885,717,,Voulaines-les-Templiers,Voulaines-les-Templiers,21,Côte-d'Or,27,Bourgogne-Franche-Comté
22001,ALLINEUC,22460,ALLINEUC,,48.3113091496,-2.86736098744,1,,Allineuc,Allineuc,22,Côtes-d'Armor,53,Bretagne
22002,ANDEL,22400,ANDEL,,48.4944929623,-2.55104146851,2,,Andel,Andel,22,Côtes-d'Armor,53,Bretagne
22003,AUCALEUC,22100,AUCALEUC,,48.4513138492,-2.12726351859,3,,Aucaleuc,Aucaleuc,22,Côtes-d'Armor,53,Bretagne
22004,BEGARD,22140,BEGARD,,48.6345594689,-3.29153247621,4,,Bégard,Bégard,22,Côtes-d'Armor,53,Bretagne
22004,BEGARD,22140,BEGARD,BOTLEZAN,48.6345594689,-3.29153247621,4,,Bégard,Bégard,22,Côtes-d'Armor,53,Bretagne
22004,BEGARD,22140,BEGARD,GUENEZAN,48.6345594689,-3.29153247621,4,,Bégard,Bégard,22,Côtes-d'Armor,53,Bretagne
22004,BEGARD,22140,BEGARD,TREZELAN,48.6345594689,-3.29153247621,4,,Bégard,Bégard,22,Côtes-d'Armor,53,Bretagne
22005,BELLE ISLE EN TERRE,22810,BELLE ISLE EN TERRE,,48.5310285208,-3.38188859591,5,,Belle-Isle-en-Terre,Belle-Isle-en-Terre,22,Côtes-d'Armor,53,Bretagne
22005,BELLE ISLE EN TERRE,22810,BELLE ISLE EN TERRE,LOCMARIA,48.5310285208,-3.38188859591,5,,Belle-Isle-en-Terre,Belle-Isle-en-Terre,22,Côtes-d'Armor,53,Bretagne
22006,BERHET,22140,BERHET,,48.6978080873,-3.30901645688,6,,Berhet,Berhet,22,Côtes-d'Armor,53,Bretagne
22008,BOBITAL,22100,BOBITAL,,48.4144897659,-2.09704568121,8,,Bobital,Bobital,22,Côtes-d'Armor,53,Bretagne
22009,LE BODEO,22320,LE BODEO,,48.3263875308,-2.92155269412,9,Le,Bodéo,Le Bodéo,22,Côtes-d'Armor,53,Bretagne
22011,BOQUEHO,22170,BOQUEHO,,48.4679009136,-2.98514567915,11,,Boqueho,Boqueho,22,Côtes-d'Armor,53,Bretagne
22012,LA BOUILLIE,22240,LA BOUILLIE,,48.583703743,-2.44206037787,12,La,Bouillie,La Bouillie,22,Côtes-d'Armor,53,Bretagne
22013,BOURBRIAC,22390,BOURBRIAC,,48.465978498,-3.19983137965,13,,Bourbriac,Bourbriac,22,Côtes-d'Armor,53,Bretagne
22014,BOURSEUL,22130,BOURSEUL,,48.4671372478,-2.26507368746,14,,Bourseul,Bourseul,22,Côtes-d'Armor,53,Bretagne
22015,BREHAND,22510,BREHAND,,48.393898689,-2.58915945139,15,,Bréhand,Bréhand,22,Côtes-d'Armor,53,Bretagne
22016,ILE DE BREHAT,22870,ILE DE BREHAT,,48.851165388,-3.00126760584,16,,Île-de-Bréhat,Île-de-Bréhat,22,Côtes-d'Armor,53,Bretagne
22018,BRELIDY,22140,BRELIDY,,48.6489532985,-3.22763480343,18,,Brélidy,Brélidy,22,Côtes-d'Armor,53,Bretagne
22019,BRINGOLO,22170,BRINGOLO,,48.5771136363,-3.00615550724,19,,Bringolo,Bringolo,22,Côtes-d'Armor,53,Bretagne
22020,BROONS,22250,BROONS,,48.3108134882,-2.24133807266,20,,Broons,Broons,22,Côtes-d'Armor,53,Bretagne
22021,BRUSVILY,22100,BRUSVILY,,48.3939754306,-2.11740584106,21,,Brusvily,Brusvily,22,Côtes-d'Armor,53,Bretagne
22023,BULAT PESTIVIEN,22160,BULAT PESTIVIEN,,48.4345552125,-3.33259319991,23,,Bulat-Pestivien,Bulat-Pestivien,22,Côtes-d'Armor,53,Bretagne
22024,CALANHEL,22160,CALANHEL,,48.4405735546,-3.47617281469,24,,Calanhel,Calanhel,22,Côtes-d'Armor,53,Bretagne
22025,CALLAC,22160,CALLAC DE BRETAGNE,,48.4223048141,-3.41625904984,25,,Callac,Callac,22,Côtes-d'Armor,53,Bretagne
22026,CALORGUEN,22100,CALORGUEN,,48.4051166885,-2.02621322021,26,,Calorguen,Calorguen,22,Côtes-d'Armor,53,Bretagne
22027,LE CAMBOUT,22210,LE CAMBOUT,,48.0588495692,-2.62105802782,27,Le,Cambout,Le Cambout,22,Côtes-d'Armor,53,Bretagne
22028,CAMLEZ,22450,CAMLEZ,,48.7831858864,-3.31551057887,28,,Camlez,Camlez,22,Côtes-d'Armor,53,Bretagne
22029,CANIHUEL,22480,CANIHUEL,,48.3372803987,-3.09549135055,29,,Canihuel,Canihuel,22,Côtes-d'Armor,53,Bretagne
22030,CAOUENNEC LANVEZEAC,22300,CAOUENNEC LANVEZEAC,,48.7062365317,-3.36791409215,30,,Caouënnec-Lanvézéac,Caouënnec-Lanvézéac,22,Côtes-d'Armor,53,Bretagne
22030,CAOUENNEC LANVEZEAC,22300,CAOUENNEC LANVEZEAC,LANVEZEAC,48.7062365317,-3.36791409215,30,,Caouënnec-Lanvézéac,Caouënnec-Lanvézéac,22,Côtes-d'Armor,53,Bretagne
22031,CARNOET,22160,CARNOET,,48.3597303691,-3.53248136925,31,,Carnoët,Carnoët,22,Côtes-d'Armor,53,Bretagne
22032,CAULNES,22350,CAULNES,,48.302140665,-2.16070598296,32,,Caulnes,Caulnes,22,Côtes-d'Armor,53,Bretagne
22033,CAUREL,22530,CAUREL,,48.2174929186,-3.03912120085,33,,Caurel,Caurel,22,Côtes-d'Armor,53,Bretagne
22034,CAVAN,22140,CAVAN,,48.6827644007,-3.35255264768,34,,Cavan,Cavan,22,Côtes-d'Armor,53,Bretagne
22035,LES CHAMPS GERAUX,22630,LES CHAMPS GERAUX,,48.4255779287,-1.97788304243,35,Les,Champs-Géraux,Les Champs-Géraux,22,Côtes-d'Armor,53,Bretagne
22036,LA CHAPELLE BLANCHE,22350,LA CHAPELLE BLANCHE,,48.2679026363,-2.12896816388,36,La,Chapelle-Blanche,La Chapelle-Blanche,22,Côtes-d'Armor,53,Bretagne
22037,LA CHAPELLE NEUVE,22160,LA CHAPELLE NEUVE,,48.4656069567,-3.4406776147,37,La,Chapelle-Neuve,La Chapelle-Neuve,22,Côtes-d'Armor,53,Bretagne
22038,CHATELAUDREN,22170,CHATELAUDREN,,48.5398477577,-2.97194076452,38,,Châtelaudren,Châtelaudren,22,Côtes-d'Armor,53,Bretagne
22039,LA CHEZE,22210,LA CHEZE,,48.1346308811,-2.66260913976,39,La,Chèze,La Chèze,22,Côtes-d'Armor,53,Bretagne
22040,COADOUT,22970,COADOUT,,48.5151774425,-3.17890331471,40,,Coadout,Coadout,22,Côtes-d'Armor,53,Bretagne
22041,COATASCORN,22140,COATASCORN,,48.6698011801,-3.25550198639,41,,Coatascorn,Coatascorn,22,Côtes-d'Armor,53,Bretagne
22042,COATREVEN,22450,COATREVEN,,48.7630037964,-3.32610620924,42,,Coatréven,Coatréven,22,Côtes-d'Armor,53,Bretagne
22043,COETLOGON,22210,COETLOGON,,48.1328534354,-2.54349043634,43,,Coëtlogon,Coëtlogon,22,Côtes-d'Armor,53,Bretagne
22044,COETMIEUX,22400,COETMIEUX,,48.4866224829,-2.59223420538,44,,Coëtmieux,Coëtmieux,22,Côtes-d'Armor,53,Bretagne
22045,COHINIAC,22800,COHINIAC,,48.4521950063,-2.94662716848,45,,Cohiniac,Cohiniac,22,Côtes-d'Armor,53,Bretagne
22046,LE MENE,22330,LE MENE,COLLINEE,48.2937312654,-2.52246803693,46,Le,Mené,Le Mené,22,Côtes-d'Armor,53,Bretagne
22046,LE MENE,22330,LE MENE,LANGOURLA,48.2937312654,-2.52246803693,46,Le,Mené,Le Mené,22,Côtes-d'Armor,53,Bretagne
22046,LE MENE,22330,LE MENE,LE GOURAY,48.2937312654,-2.52246803693,46,Le,Mené,Le Mené,22,Côtes-d'Armor,53,Bretagne
22046,LE MENE,22330,LE MENE,PLESSALA,48.2937312654,-2.52246803693,46,Le,Mené,Le Mené,22,Côtes-d'Armor,53,Bretagne
22046,LE MENE,22330,LE MENE,ST GILLES DU MENE,48.2937312654,-2.52246803693,46,Le,Mené,Le Mené,22,Côtes-d'Armor,53,Bretagne
22046,LE MENE,22330,LE MENE,ST GOUENO,48.2937312654,-2.52246803693,46,Le,Mené,Le Mené,22,Côtes-d'Armor,53,Bretagne
22046,LE MENE,22330,LE MENE,ST JACUT DU MENE,48.2937312654,-2.52246803693,46,Le,Mené,Le Mené,22,Côtes-d'Armor,53,Bretagne
22047,CORLAY,22320,CORLAY,,48.3097462956,-3.0377460051,47,,Corlay,Corlay,22,Côtes-d'Armor,53,Bretagne
22048,CORSEUL,22130,CORSEUL,,48.4828884637,-2.16620752689,48,,Corseul,Corseul,22,Côtes-d'Armor,53,Bretagne
22049,CREHEN,22130,CREHEN,,48.5403697104,-2.19064428046,49,,Créhen,Créhen,22,Côtes-d'Armor,53,Bretagne
22050,DINAN,22100,DINAN,,48.4557709953,-2.04816149693,50,,Dinan,Dinan,22,Côtes-d'Armor,53,Bretagne
22052,DUAULT,22160,DUAULT,,48.3648237544,-3.43619932088,52,,Duault,Duault,22,Côtes-d'Armor,53,Bretagne
22053,EREAC,22250,EREAC,,48.2743206835,-2.34355511126,53,,Éréac,Éréac,22,Côtes-d'Armor,53,Bretagne
22054,ERQUY,22430,ERQUY,,48.6194186311,-2.45858442922,54,,Erquy,Erquy,22,Côtes-d'Armor,53,Bretagne
22054,ERQUY,22430,ERQUY,CAROUAL,48.6194186311,-2.45858442922,54,,Erquy,Erquy,22,Côtes-d'Armor,53,Bretagne
22054,ERQUY,22430,ERQUY,LES HOPITAUX,48.6194186311,-2.45858442922,54,,Erquy,Erquy,22,Côtes-d'Armor,53,Bretagne
22055,BINIC ETABLES SUR MER,22520,BINIC ETABLES SUR MER,BINIC,48.6255350477,-2.8413899481,55,,Binic-Étables-sur-Mer,Binic-Étables-sur-Mer,22,Côtes-d'Armor,53,Bretagne
22055,BINIC ETABLES SUR MER,22680,BINIC ETABLES SUR MER,,48.6255350477,-2.8413899481,55,,Binic-Étables-sur-Mer,Binic-Étables-sur-Mer,22,Côtes-d'Armor,53,Bretagne
22056,EVRAN,22630,EVRAN,,48.3848559678,-1.97961537709,56,,Évran,Évran,22,Côtes-d'Armor,53,Bretagne
22057,LE FAOUET,22290,LE FAOUET,,48.6788862743,-3.07342665968,57,Le,Faouët,Le Faouët,22,Côtes-d'Armor,53,Bretagne
22059,LE FOEIL,22800,LE FOEIL,,48.4178424268,-2.91449094191,59,Le,Fœil,Le Fœil,22,Côtes-d'Armor,53,Bretagne
22060,GAUSSON,22150,GAUSSON,,48.2927028032,-2.76114606915,60,,Gausson,Gausson,22,Côtes-d'Armor,53,Bretagne
22061,GLOMEL,22110,GLOMEL,,48.2058975829,-3.38820300244,61,,Glomel,Glomel,22,Côtes-d'Armor,53,Bretagne
22062,GOMENE,22230,GOMENE,,48.1798510762,-2.49181773591,62,,Gomené,Gomené,22,Côtes-d'Armor,53,Bretagne
22063,GOMMENEC H,22290,GOMMENEC H,,48.63755375,-3.04800718456,63,,Gommenec'h,Gommenec'h,22,Côtes-d'Armor,53,Bretagne
22064,GOUAREC,22570,GOUAREC,,48.2358108068,-3.1953780576,64,,Gouarec,Gouarec,22,Côtes-d'Armor,53,Bretagne
22065,GOUDELIN,22290,GOUDELIN,,48.6033329233,-3.02259063099,65,,Goudelin,Goudelin,22,Côtes-d'Armor,53,Bretagne
22067,GRACES,22200,GRACES,,48.5450744926,-3.18200897738,67,,Grâces,Grâces,22,Côtes-d'Armor,53,Bretagne
22067,GRACES,22200,GRACES,GOURLAN GRACES,48.5450744926,-3.18200897738,67,,Grâces,Grâces,22,Côtes-d'Armor,53,Bretagne
22068,GRACE UZEL,22460,GRACE UZEL,,48.2502051876,-2.79367583323,68,,Grâce-Uzel,Grâce-Uzel,22,Côtes-d'Armor,53,Bretagne
22069,GUENROC,22350,GUENROC,,48.3136181449,-2.08136290156,69,,Guenroc,Guenroc,22,Côtes-d'Armor,53,Bretagne
22070,GUINGAMP,22200,GUINGAMP,,48.5612759498,-3.15330223539,70,,Guingamp,Guingamp,22,Côtes-d'Armor,53,Bretagne
22071,GUITTE,22350,GUITTE,,48.2908086069,-2.10295058858,71,,Guitté,Guitté,22,Côtes-d'Armor,53,Bretagne
22072,GURUNHUEL,22390,GURUNHUEL,,48.5082694097,-3.28883122732,72,,Gurunhuel,Gurunhuel,22,Côtes-d'Armor,53,Bretagne
22073,LA HARMOYE,22320,LA HARMOYE,,48.3463965,-2.96935303524,73,La,Harmoye,La Harmoye,22,Côtes-d'Armor,53,Bretagne
22074,LE HAUT CORLAY,22320,LE HAUT CORLAY,,48.3406704165,-3.03202477497,74,Le,Haut-Corlay,Le Haut-Corlay,22,Côtes-d'Armor,53,Bretagne
22075,HEMONSTOIR,22600,HEMONSTOIR,,48.1571543373,-2.84985311411,75,,Hémonstoir,Hémonstoir,22,Côtes-d'Armor,53,Bretagne
22076,HENANBIHEN,22550,HENANBIHEN,,48.557629556,-2.38155761099,76,,Hénanbihen,Hénanbihen,22,Côtes-d'Armor,53,Bretagne
22077,HENANSAL,22400,HENANSAL,,48.5382499804,-2.44821651079,77,,Hénansal,Hénansal,22,Côtes-d'Armor,53,Bretagne
22078,HENGOAT,22450,HENGOAT,,48.7483921174,-3.20205680693,78,,Hengoat,Hengoat,22,Côtes-d'Armor,53,Bretagne
22079,HENON,22150,HENON,,48.3866424703,-2.68322883028,79,,Hénon,Hénon,22,Côtes-d'Armor,53,Bretagne
22081,HILLION,22120,HILLION,,48.5029884697,-2.64614749418,81,,Hillion,Hillion,22,Côtes-d'Armor,53,Bretagne
22081,HILLION,22120,HILLION,ST RENE HILLION,48.5029884697,-2.64614749418,81,,Hillion,Hillion,22,Côtes-d'Armor,53,Bretagne
22082,LE HINGLE,22100,LE HINGLE,,48.3975920314,-2.08327333205,82,Le,Hinglé,Le Hinglé,22,Côtes-d'Armor,53,Bretagne
22083,ILLIFAUT,22230,ILLIFAUT,,48.1441743723,-2.3357832336,83,,Illifaut,Illifaut,22,Côtes-d'Armor,53,Bretagne
22084,JUGON LES LACS COMMUNE NOUVELLE,22270,JUGON LES LACS COMMUNE NOUVELLE,,48.4166591067,-2.32522349689,84,,Jugon-les-Lacs - Commune nouvelle,Jugon-les-Lacs - Commune nouvelle,22,Côtes-d'Armor,53,Bretagne
22084,JUGON LES LACS COMMUNE NOUVELLE,22270,JUGON LES LACS COMMUNE NOUVELLE,DOLO,48.4166591067,-2.32522349689,84,,Jugon-les-Lacs - Commune nouvelle,Jugon-les-Lacs - Commune nouvelle,22,Côtes-d'Armor,53,Bretagne
22084,JUGON LES LACS COMMUNE NOUVELLE,22270,JUGON LES LACS COMMUNE NOUVELLE,LESCOUET JUGON,48.4166591067,-2.32522349689,84,,Jugon-les-Lacs - Commune nouvelle,Jugon-les-Lacs - Commune nouvelle,22,Côtes-d'Armor,53,Bretagne
22084,JUGON LES LACS COMMUNE NOUVELLE,22270,JUGON LES LACS COMMUNE NOUVELLE,ST IGNEUC,48.4166591067,-2.32522349689,84,,Jugon-les-Lacs - Commune nouvelle,Jugon-les-Lacs - Commune nouvelle,22,Côtes-d'Armor,53,Bretagne
22085,KERBORS,22610,KERBORS,,48.8236353401,-3.1817520424,85,,Kerbors,Kerbors,22,Côtes-d'Armor,53,Bretagne
22086,KERFOT,22500,KERFOT,,48.7371295698,-3.03104722579,86,,Kerfot,Kerfot,22,Côtes-d'Armor,53,Bretagne
22087,KERGRIST MOELOU,22110,KERGRIST MOELOU,,48.29472326,-3.32538204171,87,,Kergrist-Moëlou,Kergrist-Moëlou,22,Côtes-d'Armor,53,Bretagne
22088,KERIEN,22480,KERIEN,,48.4091455104,-3.22991247823,88,,Kerien,Kerien,22,Côtes-d'Armor,53,Bretagne
22090,KERMARIA SULARD,22450,KERMARIA SULARD,,48.7735327314,-3.37254108422,90,,Kermaria-Sulard,Kermaria-Sulard,22,Côtes-d'Armor,53,Bretagne
22091,KERMOROC H,22140,KERMOROC H,,48.614435841,-3.20327360757,91,,Kermoroc'h,Kermoroc'h,22,Côtes-d'Armor,53,Bretagne
22092,KERPERT,22480,KERPERT,,48.3896278473,-3.13681075222,92,,Kerpert,Kerpert,22,Côtes-d'Armor,53,Bretagne
22093,LAMBALLE,22400,LAMBALLE,,48.4684404751,-2.49534815954,93,,Lamballe,Lamballe,22,Côtes-d'Armor,53,Bretagne
22093,LAMBALLE,22400,LAMBALLE,LA POTERIE,48.4684404751,-2.49534815954,93,,Lamballe,Lamballe,22,Côtes-d'Armor,53,Bretagne
22093,LAMBALLE,22400,LAMBALLE,MAROUE,48.4684404751,-2.49534815954,93,,Lamballe,Lamballe,22,Côtes-d'Armor,53,Bretagne
22093,LAMBALLE,22400,LAMBALLE,MESLIN,48.4684404751,-2.49534815954,93,,Lamballe,Lamballe,22,Côtes-d'Armor,53,Bretagne
22093,LAMBALLE,22400,LAMBALLE,ST AARON,48.4684404751,-2.49534815954,93,,Lamballe,Lamballe,22,Côtes-d'Armor,53,Bretagne
22093,LAMBALLE,22400,LAMBALLE,TREGOMAR,48.4684404751,-2.49534815954,93,,Lamballe,Lamballe,22,Côtes-d'Armor,53,Bretagne
22094,LANCIEUX,22770,LANCIEUX,,48.6012844665,-2.14729508559,94,,Lancieux,Lancieux,22,Côtes-d'Armor,53,Bretagne
22095,LANDEBAERON,22140,LANDEBAERON,,48.6375635962,-3.20497735412,95,,Landebaëron,Landebaëron,22,Côtes-d'Armor,53,Bretagne
22096,LANDEBIA,22130,LANDEBIA,,48.5152409812,-2.33740214876,96,,Landébia,Landébia,22,Côtes-d'Armor,53,Bretagne
22097,LA LANDEC,22980,LA LANDEC,,48.4261108974,-2.18321076589,97,La,Landec,La Landec,22,Côtes-d'Armor,53,Bretagne
22098,LANDEHEN,22400,LANDEHEN,,48.4222879155,-2.54229267406,98,,Landéhen,Landéhen,22,Côtes-d'Armor,53,Bretagne
22099,LANFAINS,22800,LANFAINS,,48.3653621373,-2.91178425275,99,,Lanfains,Lanfains,22,Côtes-d'Armor,53,Bretagne
22100,LANGAST,22150,LANGAST,,48.2837507892,-2.65609713563,100,,Langast,Langast,22,Côtes-d'Armor,53,Bretagne
22101,LANGOAT,22450,LANGOAT,,48.7347929151,-3.29767130606,101,,Langoat,Langoat,22,Côtes-d'Armor,53,Bretagne
22103,LANGROLAY SUR RANCE,22490,LANGROLAY SUR RANCE,,48.5530223296,-2.01383816063,103,,Langrolay-sur-Rance,Langrolay-sur-Rance,22,Côtes-d'Armor,53,Bretagne
22104,LANGUEDIAS,22980,LANGUEDIAS,,48.3929940858,-2.20566739672,104,,Languédias,Languédias,22,Côtes-d'Armor,53,Bretagne
22105,LANGUENAN,22130,LANGUENAN,,48.5115119338,-2.13296467458,105,,Languenan,Languenan,22,Côtes-d'Armor,53,Bretagne
22106,LANGUEUX,22360,LANGUEUX,,48.5000522834,-2.70972282168,106,,Langueux,Langueux,22,Côtes-d'Armor,53,Bretagne
22106,LANGUEUX,22360,LANGUEUX,LES GREVES LANGUEUX,48.5000522834,-2.70972282168,106,,Langueux,Langueux,22,Côtes-d'Armor,53,Bretagne
22107,LANISCAT,22570,LANISCAT,,48.2402807469,-3.13725653018,107,,Bon Repos sur Blavet,Bon Repos sur Blavet,22,Côtes-d'Armor,53,Bretagne
22108,LANLEFF,22290,LANLEFF,,48.693037024,-3.04509377932,108,,Lanleff,Lanleff,22,Côtes-d'Armor,53,Bretagne
22109,LANLOUP,22580,LANLOUP,,48.7099285646,-2.96884384171,109,,Lanloup,Lanloup,22,Côtes-d'Armor,53,Bretagne
22110,LANMERIN,22300,LANMERIN,,48.7402849617,-3.35598717898,110,,Lanmérin,Lanmérin,22,Côtes-d'Armor,53,Bretagne
22111,LANMODEZ,22610,LANMODEZ,,48.8449357716,-3.09870207936,111,,Lanmodez,Lanmodez,22,Côtes-d'Armor,53,Bretagne
22112,LANNEBERT,22290,LANNEBERT,,48.6506329837,-3.00889441473,112,,Lannebert,Lannebert,22,Côtes-d'Armor,53,Bretagne
22113,LANNION,22300,LANNION,,48.7433496707,-3.46159799691,113,,Lannion,Lannion,22,Côtes-d'Armor,53,Bretagne
22113,LANNION,22300,LANNION,BEG LEGUER SERVEL,48.7433496707,-3.46159799691,113,,Lannion,Lannion,22,Côtes-d'Armor,53,Bretagne
22114,LANRELAS,22250,LANRELAS,,48.2463148524,-2.29585343012,114,,Lanrelas,Lanrelas,22,Côtes-d'Armor,53,Bretagne
22115,LANRIVAIN,22480,LANRIVAIN,,48.3590715356,-3.21502057949,115,,Lanrivain,Lanrivain,22,Côtes-d'Armor,53,Bretagne
22116,LANRODEC,22170,LANRODEC,,48.4997744033,-3.04323930161,116,,Lanrodec,Lanrodec,22,Côtes-d'Armor,53,Bretagne
22117,LANTIC,22410,LANTIC,,48.6040161525,-2.89860242151,117,,Lantic,Lantic,22,Côtes-d'Armor,53,Bretagne
22117,LANTIC,22410,LANTIC,NOTRE DAME DE LA COUR,48.6040161525,-2.89860242151,117,,Lantic,Lantic,22,Côtes-d'Armor,53,Bretagne
22118,LANVALLAY,22100,LANVALLAY,,48.451759809,-2.01053362747,118,,Lanvallay,Lanvallay,22,Côtes-d'Armor,53,Bretagne
22118,LANVALLAY,22100,LANVALLAY,ST SOLEN,48.451759809,-2.01053362747,118,,Lanvallay,Lanvallay,22,Côtes-d'Armor,53,Bretagne
22118,LANVALLAY,22100,LANVALLAY,TRESSAINT,48.451759809,-2.01053362747,118,,Lanvallay,Lanvallay,22,Côtes-d'Armor,53,Bretagne
22119,LANVELLEC,22420,LANVELLEC,,48.6072769742,-3.52795086821,119,,Lanvellec,Lanvellec,22,Côtes-d'Armor,53,Bretagne
22121,LANVOLLON,22290,LANVOLLON,,48.6334353937,-2.98974658224,121,,Lanvollon,Lanvollon,22,Côtes-d'Armor,53,Bretagne
22122,LAURENAN,22230,LAURENAN,,48.214928975,-2.51844644739,122,,Laurenan,Laurenan,22,Côtes-d'Armor,53,Bretagne
22123,LEHON,22100,LEHON,,48.4386870664,-2.05362352252,123,,Léhon,Léhon,22,Côtes-d'Armor,53,Bretagne
22124,LESCOUET GOUAREC,22570,LESCOUET GOUAREC,,48.1688076407,-3.20708353658,124,,Lescouët-Gouarec,Lescouët-Gouarec,22,Côtes-d'Armor,53,Bretagne
22126,LE LESLAY,22800,LE LESLAY,,48.4240369028,-2.96496738118,126,Le,Leslay,Le Leslay,22,Côtes-d'Armor,53,Bretagne
22127,LEZARDRIEUX,22740,LEZARDRIEUX,,48.8036302757,-3.1089212671,127,,Lézardrieux,Lézardrieux,22,Côtes-d'Armor,53,Bretagne
22127,LEZARDRIEUX,22740,LEZARDRIEUX,KERMOUSTER,48.8036302757,-3.1089212671,127,,Lézardrieux,Lézardrieux,22,Côtes-d'Armor,53,Bretagne
22128,LOCARN,22340,LOCARN,,48.3325702433,-3.41128829706,128,,Locarn,Locarn,22,Côtes-d'Armor,53,Bretagne
22129,LOC ENVEL,22810,LOC ENVEL,,48.5098602173,-3.40963775172,129,,Loc-Envel,Loc-Envel,22,Côtes-d'Armor,53,Bretagne
22131,LOGUIVY PLOUGRAS,22780,LOGUIVY PLOUGRAS,,48.5106432062,-3.49141017809,131,,Loguivy-Plougras,Loguivy-Plougras,22,Côtes-d'Armor,53,Bretagne
22132,LOHUEC,22160,LOHUEC,,48.4622299477,-3.52706033812,132,,Lohuec,Lohuec,22,Côtes-d'Armor,53,Bretagne
22133,LOSCOUET SUR MEU,22230,LOSCOUET SUR MEU,,48.1756621507,-2.25102250347,133,,Loscouët-sur-Meu,Loscouët-sur-Meu,22,Côtes-d'Armor,53,Bretagne
22134,LOUANNEC,22700,LOUANNEC,,48.784228067,-3.41180213141,134,,Louannec,Louannec,22,Côtes-d'Armor,53,Bretagne
22135,LOUARGAT,22540,LOUARGAT,,48.5612002546,-3.34101341684,135,,Louargat,Louargat,22,Côtes-d'Armor,53,Bretagne
22135,LOUARGAT,22540,LOUARGAT,ST ELOI,48.5612002546,-3.34101341684,135,,Louargat,Louargat,22,Côtes-d'Armor,53,Bretagne
22136,LOUDEAC,22600,LOUDEAC,,48.1740293909,-2.75097544378,136,,Loudéac,Loudéac,22,Côtes-d'Armor,53,Bretagne
22137,MAEL CARHAIX,22340,MAEL CARHAIX,,48.2882583933,-3.41055453588,137,,Maël-Carhaix,Maël-Carhaix,22,Côtes-d'Armor,53,Bretagne
22138,MAEL PESTIVIEN,22160,MAEL PESTIVIEN,,48.4041439455,-3.29047753101,138,,Maël-Pestivien,Maël-Pestivien,22,Côtes-d'Armor,53,Bretagne
22139,MAGOAR,22480,MAGOAR,,48.4014791797,-3.17683150535,139,,Magoar,Magoar,22,Côtes-d'Armor,53,Bretagne
22140,LA MALHOURE,22640,LA MALHOURE,,48.3961928659,-2.49404154442,140,La,Malhoure,La Malhoure,22,Côtes-d'Armor,53,Bretagne
22141,MANTALLOT,22450,MANTALLOT,,48.7102228776,-3.29192042144,141,,Mantallot,Mantallot,22,Côtes-d'Armor,53,Bretagne
22143,MATIGNON,22550,MATIGNON,,48.6017682782,-2.29021789488,143,,Matignon,Matignon,22,Côtes-d'Armor,53,Bretagne
22144,LA MEAUGON,22440,LA MEAUGON,,48.5044768613,-2.84729418508,144,La,Méaugon,La Méaugon,22,Côtes-d'Armor,53,Bretagne
22145,MEGRIT,22270,MEGRIT,,48.38979598,-2.26731642249,145,,Mégrit,Mégrit,22,Côtes-d'Armor,53,Bretagne
22146,MELLIONNEC,22110,MELLIONNEC,,48.1687655698,-3.28980026504,146,,Mellionnec,Mellionnec,22,Côtes-d'Armor,53,Bretagne
22147,MERDRIGNAC,22230,MERDRIGNAC,,48.1936252026,-2.39598361343,147,,Merdrignac,Merdrignac,22,Côtes-d'Armor,53,Bretagne
22148,MERILLAC,22230,MERILLAC,,48.249384359,-2.39127521375,148,,Mérillac,Mérillac,22,Côtes-d'Armor,53,Bretagne
22149,MERLEAC,22460,MERLEAC,,48.2725811849,-2.91089143126,149,,Merléac,Merléac,22,Côtes-d'Armor,53,Bretagne
22150,LE MERZER,22200,LE MERZER,,48.5816215935,-3.07507907802,150,Le,Merzer,Le Merzer,22,Côtes-d'Armor,53,Bretagne
22152,MINIHY TREGUIER,22220,MINIHY TREGUIER,,48.7680136612,-3.25834095336,152,,Minihy-Tréguier,Minihy-Tréguier,22,Côtes-d'Armor,53,Bretagne
22153,MONCONTOUR,22510,MONCONTOUR DE BRETAGNE,,48.3581980369,-2.63225581837,153,,Moncontour,Moncontour,22,Côtes-d'Armor,53,Bretagne
22154,MORIEUX,22400,MORIEUX,,48.520297083,-2.60995239978,154,,Morieux,Morieux,22,Côtes-d'Armor,53,Bretagne
22155,LA MOTTE,22600,LA MOTTE,,48.2311050733,-2.70470655082,155,La,Motte,La Motte,22,Côtes-d'Armor,53,Bretagne
22156,MOUSTERU,22200,MOUSTERU,,48.5285458399,-3.23462058455,156,,Moustéru,Moustéru,22,Côtes-d'Armor,53,Bretagne
22157,LE MOUSTOIR,22340,LE MOUSTOIR,,48.2739501884,-3.49152606471,157,Le,Moustoir,Le Moustoir,22,Côtes-d'Armor,53,Bretagne
22158,MUR DE BRETAGNE,22530,MUR DE BRETAGNE,,48.1957524474,-2.9837108711,158,,Guerlédan,Guerlédan,22,Côtes-d'Armor,53,Bretagne
22160,NOYAL,22400,NOYAL,,48.4508228232,-2.46340477987,160,,Noyal,Noyal,22,Côtes-d'Armor,53,Bretagne
22161,PABU,22200,PABU,,48.58310885,-3.14288475584,161,,Pabu,Pabu,22,Côtes-d'Armor,53,Bretagne
22162,PAIMPOL,22500,PAIMPOL,,48.7733386239,-3.05459397042,162,,Paimpol,Paimpol,22,Côtes-d'Armor,53,Bretagne
22163,PAULE,22340,PAULE,,48.2274085967,-3.46128178015,163,,Paule,Paule,22,Côtes-d'Armor,53,Bretagne
22164,PEDERNEC,22540,PEDERNEC,,48.5880169887,-3.27690620211,164,,Pédernec,Pédernec,22,Côtes-d'Armor,53,Bretagne
22164,PEDERNEC,22540,PEDERNEC,LE QUINQUIS,48.5880169887,-3.27690620211,164,,Pédernec,Pédernec,22,Côtes-d'Armor,53,Bretagne
22165,PENGUILY,22510,PENGUILY,,48.3715193547,-2.49421726278,165,,Penguily,Penguily,22,Côtes-d'Armor,53,Bretagne
22166,PENVENAN,22710,PENVENAN,,48.816014407,-3.30224745841,166,,Penvénan,Penvénan,22,Côtes-d'Armor,53,Bretagne
22166,PENVENAN,22710,PENVENAN,BUGUELES,48.816014407,-3.30224745841,166,,Penvénan,Penvénan,22,Côtes-d'Armor,53,Bretagne
22166,PENVENAN,22710,PENVENAN,PORT BLANC,48.816014407,-3.30224745841,166,,Penvénan,Penvénan,22,Côtes-d'Armor,53,Bretagne
22167,PERRET,22570,PERRET,,48.1863752153,-3.14630117054,167,,Perret,Perret,22,Côtes-d'Armor,53,Bretagne
22168,PERROS GUIREC,22700,PERROS GUIREC,,48.8094393417,-3.46769227891,168,,Perros-Guirec,Perros-Guirec,22,Côtes-d'Armor,53,Bretagne
22168,PERROS GUIREC,22700,PERROS GUIREC,LA CLARTE,48.8094393417,-3.46769227891,168,,Perros-Guirec,Perros-Guirec,22,Côtes-d'Armor,53,Bretagne
22168,PERROS GUIREC,22700,PERROS GUIREC,PLOUMANACH,48.8094393417,-3.46769227891,168,,Perros-Guirec,Perros-Guirec,22,Côtes-d'Armor,53,Bretagne
22169,PEUMERIT QUINTIN,22480,PEUMERIT QUINTIN,,48.3635016654,-3.28335906879,169,,Peumerit-Quintin,Peumerit-Quintin,22,Côtes-d'Armor,53,Bretagne
22170,PLAINE HAUTE,22800,PLAINE HAUTE,,48.4435359708,-2.85882396738,170,,Plaine-Haute,Plaine-Haute,22,Côtes-d'Armor,53,Bretagne
22171,PLAINTEL,22940,PLAINTEL,,48.4105972441,-2.81039949193,171,,Plaintel,Plaintel,22,Côtes-d'Armor,53,Bretagne
22172,PLANCOET,22130,PLANCOET,,48.5148616647,-2.21936660339,172,,Plancoët,Plancoët,22,Côtes-d'Armor,53,Bretagne
22173,PLANGUENOUAL,22400,PLANGUENOUAL,,48.5332542143,-2.56797956377,173,,Planguenoual,Planguenoual,22,Côtes-d'Armor,53,Bretagne
22173,PLANGUENOUAL,22400,PLANGUENOUAL,LA COTENTIN PLANGUENOUAL,48.5332542143,-2.56797956377,173,,Planguenoual,Planguenoual,22,Côtes-d'Armor,53,Bretagne
22174,PLEBOULLE,22550,PLEBOULLE,,48.5946127518,-2.33355800652,174,,Pléboulle,Pléboulle,22,Côtes-d'Armor,53,Bretagne
22175,PLEDELIAC,22270,PLEDELIAC,,48.46875643,-2.37175488421,175,,Plédéliac,Plédéliac,22,Côtes-d'Armor,53,Bretagne
22176,PLEDRAN,22960,PLEDRAN,,48.4430297904,-2.745361546,176,,Plédran,Plédran,22,Côtes-d'Armor,53,Bretagne
22177,PLEGUIEN,22290,PLEGUIEN,,48.634729938,-2.948078639,177,,Pléguien,Pléguien,22,Côtes-d'Armor,53,Bretagne
22178,PLEHEDEL,22290,PLEHEDEL,,48.6949303191,-3.00638352485,178,,Pléhédel,Pléhédel,22,Côtes-d'Armor,53,Bretagne
22179,FREHEL,22240,FREHEL,,48.6348223959,-2.36202603588,179,,Fréhel,Fréhel,22,Côtes-d'Armor,53,Bretagne
22179,FREHEL,22240,FREHEL,LA CARQUOIS,48.6348223959,-2.36202603588,179,,Fréhel,Fréhel,22,Côtes-d'Armor,53,Bretagne
22179,FREHEL,22240,FREHEL,SABLES D OR LES PINS,48.6348223959,-2.36202603588,179,,Fréhel,Fréhel,22,Côtes-d'Armor,53,Bretagne
22180,PLELAN LE PETIT,22980,PLELAN LE PETIT,,48.427284639,-2.22409129331,180,,Plélan-le-Petit,Plélan-le-Petit,22,Côtes-d'Armor,53,Bretagne
22181,PLELAUFF,22570,PLELAUFF,,48.1984075827,-3.20337969883,181,,Plélauff,Plélauff,22,Côtes-d'Armor,53,Bretagne
22182,PLELO,22170,PLELO,,48.5539490397,-2.92735554544,182,,Plélo,Plélo,22,Côtes-d'Armor,53,Bretagne
22182,PLELO,22170,PLELO,ST JEAN PLELO,48.5539490397,-2.92735554544,182,,Plélo,Plélo,22,Côtes-d'Armor,53,Bretagne
22183,LES MOULINS,22210,LES MOULINS,LA FERRIERE,48.1860837585,-2.5859903316,183,,Plémet,Plémet,22,Côtes-d'Armor,53,Bretagne
22183,LES MOULINS,22210,LES MOULINS,PLEMET,48.1860837585,-2.5859903316,183,,Plémet,Plémet,22,Côtes-d'Armor,53,Bretagne
22184,PLEMY,22150,PLEMY,,48.3378874008,-2.67639170288,184,,Plémy,Plémy,22,Côtes-d'Armor,53,Bretagne
22185,PLENEE JUGON,22640,PLENEE JUGON,,48.3556203022,-2.41594505184,185,,Plénée-Jugon,Plénée-Jugon,22,Côtes-d'Armor,53,Bretagne
22186,PLENEUF VAL ANDRE,22370,PLENEUF VAL ANDRE,,48.5840054611,-2.53016887694,186,,Pléneuf-Val-André,Pléneuf-Val-André,22,Côtes-d'Armor,53,Bretagne
22187,PLERIN,22190,PLERIN,,48.5441732134,-2.77004635921,187,,Plérin,Plérin,22,Côtes-d'Armor,53,Bretagne
22187,PLERIN,22190,PLERIN,LES ROSAIRES,48.5441732134,-2.77004635921,187,,Plérin,Plérin,22,Côtes-d'Armor,53,Bretagne
22187,PLERIN,22190,PLERIN,ST LAURENT DE LA MER,48.5441732134,-2.77004635921,187,,Plérin,Plérin,22,Côtes-d'Armor,53,Bretagne
22188,PLERNEUF,22170,PLERNEUF,,48.5062272479,-2.88034239328,188,,Plerneuf,Plerneuf,22,Côtes-d'Armor,53,Bretagne
22189,PLESIDY,22720,PLESIDY,,48.4414674679,-3.12592291873,189,,Plésidy,Plésidy,22,Côtes-d'Armor,53,Bretagne
22190,PLESLIN TRIGAVOU,22490,PLESLIN TRIGAVOU,,48.5279257425,-2.07094753611,190,,Pleslin-Trigavou,Pleslin-Trigavou,22,Côtes-d'Armor,53,Bretagne
22190,PLESLIN TRIGAVOU,22490,PLESLIN TRIGAVOU,TRIGAVOU,48.5279257425,-2.07094753611,190,,Pleslin-Trigavou,Pleslin-Trigavou,22,Côtes-d'Armor,53,Bretagne
22192,PLESSIX BALISSON,22650,PLESSIX BALISSON,,48.5383652723,-2.1421049053,192,,Plessix-Balisson,Plessix-Balisson,22,Côtes-d'Armor,53,Bretagne
22193,PLESTAN,22640,PLESTAN,,48.4145560996,-2.44084892144,193,,Plestan,Plestan,22,Côtes-d'Armor,53,Bretagne
22194,PLESTIN LES GREVES,22310,PLESTIN LES GREVES,,48.6476598998,-3.6197891944,194,,Plestin-les-Grèves,Plestin-les-Grèves,22,Côtes-d'Armor,53,Bretagne
22194,PLESTIN LES GREVES,22310,PLESTIN LES GREVES,ST EFFLAM,48.6476598998,-3.6197891944,194,,Plestin-les-Grèves,Plestin-les-Grèves,22,Côtes-d'Armor,53,Bretagne
22195,PLEUBIAN,22610,PLEUBIAN,,48.8434804253,-3.13340767618,195,,Pleubian,Pleubian,22,Côtes-d'Armor,53,Bretagne
22195,PLEUBIAN,22610,PLEUBIAN,L ARMOR,48.8434804253,-3.13340767618,195,,Pleubian,Pleubian,22,Côtes-d'Armor,53,Bretagne
22196,PLEUDANIEL,22740,PLEUDANIEL,,48.7611404918,-3.15433424139,196,,Pleudaniel,Pleudaniel,22,Côtes-d'Armor,53,Bretagne
22197,PLEUDIHEN SUR RANCE,22690,PLEUDIHEN SUR RANCE,,48.5092937336,-1.94145904907,197,,Pleudihen-sur-Rance,Pleudihen-sur-Rance,22,Côtes-d'Armor,53,Bretagne
22198,PLEUMEUR BODOU,22560,PLEUMEUR BODOU,,48.7852851364,-3.51721058191,198,,Pleumeur-Bodou,Pleumeur-Bodou,22,Côtes-d'Armor,53,Bretagne
22198,PLEUMEUR BODOU,22560,PLEUMEUR BODOU,ILE GRANDE,48.7852851364,-3.51721058191,198,,Pleumeur-Bodou,Pleumeur-Bodou,22,Côtes-d'Armor,53,Bretagne
22198,PLEUMEUR BODOU,22560,PLEUMEUR BODOU,LANDRELLEC,48.7852851364,-3.51721058191,198,,Pleumeur-Bodou,Pleumeur-Bodou,22,Côtes-d'Armor,53,Bretagne
22199,PLEUMEUR GAUTIER,22740,PLEUMEUR GAUTIER,,48.8001346688,-3.15198645075,199,,Pleumeur-Gautier,Pleumeur-Gautier,22,Côtes-d'Armor,53,Bretagne
22200,PLEVEN,22130,PLEVEN,,48.4911438301,-2.32130813288,200,,Pléven,Pléven,22,Côtes-d'Armor,53,Bretagne
22201,PLEVENON,22240,PLEVENON,,48.6575564031,-2.32020473916,201,,Plévenon,Plévenon,22,Côtes-d'Armor,53,Bretagne
22202,PLEVIN,22340,PLEVIN,,48.2237458886,-3.51633070374,202,,Plévin,Plévin,22,Côtes-d'Armor,53,Bretagne
22203,PLOEUC L HERMITAGE,22150,PLOEUC L HERMITAGE,,48.3440952237,-2.76649702896,203,,Plœuc-L'Hermitage,Plœuc-L'Hermitage,22,Côtes-d'Armor,53,Bretagne
22203,PLOEUC L HERMITAGE,22150,PLOEUC L HERMITAGE,L HERMITAGE LORGE,48.3440952237,-2.76649702896,203,,Plœuc-L'Hermitage,Plœuc-L'Hermitage,22,Côtes-d'Armor,53,Bretagne
22204,PLOEZAL,22260,PLOEZAL,,48.7171371071,-3.18015723949,204,,Ploëzal,Ploëzal,22,Côtes-d'Armor,53,Bretagne
22205,PLOREC SUR ARGUENON,22130,PLOREC SUR ARGUENON,,48.4632496274,-2.30133142884,205,,Plorec-sur-Arguenon,Plorec-sur-Arguenon,22,Côtes-d'Armor,53,Bretagne
22206,PLOUAGAT,22170,PLOUAGAT,,48.5303656854,-2.98952865818,206,,Plouagat,Plouagat,22,Côtes-d'Armor,53,Bretagne
22207,PLOUARET,22420,PLOUARET,,48.6193915255,-3.48297540773,207,,Plouaret,Plouaret,22,Côtes-d'Armor,53,Bretagne
22208,PLOUASNE,22830,PLOUASNE,,48.3117473235,-2.00863040053,208,,Plouasne,Plouasne,22,Côtes-d'Armor,53,Bretagne
22209,PLOUBALAY,22650,PLOUBALAY,,48.5596688192,-2.12838362694,209,,Beaussais-sur-Mer,Beaussais-sur-Mer,22,Côtes-d'Armor,53,Bretagne
22210,PLOUBAZLANEC,22620,PLOUBAZLANEC,,48.80535932,-3.04524739912,210,,Ploubazlanec,Ploubazlanec,22,Côtes-d'Armor,53,Bretagne
22210,PLOUBAZLANEC,22620,PLOUBAZLANEC,L ARCOUEST,48.80535932,-3.04524739912,210,,Ploubazlanec,Ploubazlanec,22,Côtes-d'Armor,53,Bretagne
22210,PLOUBAZLANEC,22620,PLOUBAZLANEC,LOGUIVY DE LA MER,48.80535932,-3.04524739912,210,,Ploubazlanec,Ploubazlanec,22,Côtes-d'Armor,53,Bretagne
22211,PLOUBEZRE,22300,PLOUBEZRE,,48.685519576,-3.44839512307,211,,Ploubezre,Ploubezre,22,Côtes-d'Armor,53,Bretagne
22212,PLOUEC DU TRIEUX,22260,PLOUEC DU TRIEUX,,48.6722943477,-3.18747531315,212,,Plouëc-du-Trieux,Plouëc-du-Trieux,22,Côtes-d'Armor,53,Bretagne
22213,PLOUER SUR RANCE,22490,PLOUER SUR RANCE,,48.5248482927,-2.00660319758,213,,Plouër-sur-Rance,Plouër-sur-Rance,22,Côtes-d'Armor,53,Bretagne
22214,PLOUEZEC,22470,PLOUEZEC,,48.7387813748,-2.9791439557,214,,Plouézec,Plouézec,22,Côtes-d'Armor,53,Bretagne
22214,PLOUEZEC,22470,PLOUEZEC,LE QUESTEL,48.7387813748,-2.9791439557,214,,Plouézec,Plouézec,22,Côtes-d'Armor,53,Bretagne
22215,PLOUFRAGAN,22440,PLOUFRAGAN,,48.4877482968,-2.80851630928,215,,Ploufragan,Ploufragan,22,Côtes-d'Armor,53,Bretagne
22215,PLOUFRAGAN,22440,PLOUFRAGAN,LES CROIX,48.4877482968,-2.80851630928,215,,Ploufragan,Ploufragan,22,Côtes-d'Armor,53,Bretagne
22216,PLOUGONVER,22810,PLOUGONVER,,48.4852045977,-3.37517285292,216,,Plougonver,Plougonver,22,Côtes-d'Armor,53,Bretagne
22217,PLOUGRAS,22780,PLOUGRAS,,48.4964328915,-3.56487485198,217,,Plougras,Plougras,22,Côtes-d'Armor,53,Bretagne
22218,PLOUGRESCANT,22820,PLOUGRESCANT,,48.8389480714,-3.24154914571,218,,Plougrescant,Plougrescant,22,Côtes-d'Armor,53,Bretagne
22219,PLOUGUENAST,22150,PLOUGUENAST,,48.2772549866,-2.71012681366,219,,Plouguenast,Plouguenast,22,Côtes-d'Armor,53,Bretagne
22220,PLOUGUERNEVEL,22110,PLOUGUERNEVEL,,48.2340717388,-3.24711732443,220,,Plouguernével,Plouguernével,22,Côtes-d'Armor,53,Bretagne
22221,PLOUGUIEL,22220,PLOUGUIEL,,48.8047926753,-3.2454956347,221,,Plouguiel,Plouguiel,22,Côtes-d'Armor,53,Bretagne
22222,PLOUHA,22580,PLOUHA,,48.6822316529,-2.93147403458,222,,Plouha,Plouha,22,Côtes-d'Armor,53,Bretagne
22222,PLOUHA,22580,PLOUHA,KEREGAL,48.6822316529,-2.93147403458,222,,Plouha,Plouha,22,Côtes-d'Armor,53,Bretagne
22223,PLOUISY,22200,PLOUISY,,48.5834889346,-3.19153287341,223,,Plouisy,Plouisy,22,Côtes-d'Armor,53,Bretagne
22224,PLOULEC H,22300,PLOULEC H,,48.7189901633,-3.50164887821,224,,Ploulec'h,Ploulec'h,22,Côtes-d'Armor,53,Bretagne
22224,PLOULEC H,22300,PLOULEC H,LE YAUDET,48.7189901633,-3.50164887821,224,,Ploulec'h,Ploulec'h,22,Côtes-d'Armor,53,Bretagne
22225,PLOUMAGOAR,22970,PLOUMAGOAR,,48.5304587178,-3.109565675,225,,Ploumagoar,Ploumagoar,22,Côtes-d'Armor,53,Bretagne
22226,PLOUMILLIAU,22300,PLOUMILLIAU,,48.6842832137,-3.51392982207,226,,Ploumilliau,Ploumilliau,22,Côtes-d'Armor,53,Bretagne
22227,PLOUNERIN,22780,PLOUNERIN,,48.5667945613,-3.54835047587,227,,Plounérin,Plounérin,22,Côtes-d'Armor,53,Bretagne
22228,PLOUNEVEZ MOEDEC,22810,PLOUNEVEZ MOEDEC,,48.5514852561,-3.44431318727,228,,Plounévez-Moëdec,Plounévez-Moëdec,22,Côtes-d'Armor,53,Bretagne
22229,PLOUNEVEZ QUINTIN,22110,PLOUNEVEZ QUINTIN,,48.2853424694,-3.24078209284,229,,Plounévez-Quintin,Plounévez-Quintin,22,Côtes-d'Armor,53,Bretagne
22231,PLOURAC H,22160,PLOURAC H,,48.4185427687,-3.55150480178,231,,Plourac'h,Plourac'h,22,Côtes-d'Armor,53,Bretagne
22232,PLOURHAN,22410,PLOURHAN,,48.6312552228,-2.88203367765,232,,Plourhan,Plourhan,22,Côtes-d'Armor,53,Bretagne
22233,PLOURIVO,22860,PLOURIVO,,48.7416286084,-3.09320460347,233,,Plourivo,Plourivo,22,Côtes-d'Armor,53,Bretagne
22234,PLOUVARA,22170,PLOUVARA,,48.4973028138,-2.92552470033,234,,Plouvara,Plouvara,22,Côtes-d'Armor,53,Bretagne
22235,PLOUZELAMBRE,22420,PLOUZELAMBRE,,48.6478103938,-3.53679598491,235,,Plouzélambre,Plouzélambre,22,Côtes-d'Armor,53,Bretagne
22236,PLUDUAL,22290,PLUDUAL,,48.6666296854,-2.98679017544,236,,Pludual,Pludual,22,Côtes-d'Armor,53,Bretagne
22237,PLUDUNO,22130,PLUDUNO,,48.5232615716,-2.28079325793,237,,Pluduno,Pluduno,22,Côtes-d'Armor,53,Bretagne
22238,PLUFUR,22310,PLUFUR,,48.6108539647,-3.57279775883,238,,Plufur,Plufur,22,Côtes-d'Armor,53,Bretagne
22239,PLUMAUDAN,22350,PLUMAUDAN,,48.3560244309,-2.11489038909,239,,Plumaudan,Plumaudan,22,Côtes-d'Armor,53,Bretagne
22240,PLUMAUGAT,22250,PLUMAUGAT,,48.2450296898,-2.23091188525,240,,Plumaugat,Plumaugat,22,Côtes-d'Armor,53,Bretagne
22241,PLUMIEUX,22210,PLUMIEUX,,48.1061077392,-2.59155562582,241,,Plumieux,Plumieux,22,Côtes-d'Armor,53,Bretagne
22242,PLURIEN,22240,PLURIEN,,48.6095445151,-2.39978553879,242,,Plurien,Plurien,22,Côtes-d'Armor,53,Bretagne
22243,PLUSQUELLEC,22160,PLUSQUELLEC,,48.3993408756,-3.48393074891,243,,Plusquellec,Plusquellec,22,Côtes-d'Armor,53,Bretagne
22244,PLUSSULIEN,22320,PLUSSULIEN,,48.2785043926,-3.0656896089,244,,Plussulien,Plussulien,22,Côtes-d'Armor,53,Bretagne
22245,PLUZUNET,22140,PLUZUNET,,48.6363773229,-3.3712110927,245,,Pluzunet,Pluzunet,22,Côtes-d'Armor,53,Bretagne
22246,POMMERET,22120,POMMERET,,48.4585503252,-2.62222031469,246,,Pommeret,Pommeret,22,Côtes-d'Armor,53,Bretagne
22247,POMMERIT JAUDY,22450,POMMERIT JAUDY,,48.7243391137,-3.24215851402,247,,Pommerit-Jaudy,Pommerit-Jaudy,22,Côtes-d'Armor,53,Bretagne
22248,POMMERIT LE VICOMTE,22200,POMMERIT LE VICOMTE,,48.6180167491,-3.10285419509,248,,Pommerit-le-Vicomte,Pommerit-le-Vicomte,22,Côtes-d'Armor,53,Bretagne
22249,PONT MELVEZ,22390,PONT MELVEZ,,48.4740063925,-3.2849339382,249,,Pont-Melvez,Pont-Melvez,22,Côtes-d'Armor,53,Bretagne
22250,PONTRIEUX,22260,PONTRIEUX,,48.6998384627,-3.1590834016,250,,Pontrieux,Pontrieux,22,Côtes-d'Armor,53,Bretagne
22251,PORDIC,22590,PORDIC,,48.5702275208,-2.82517190036,251,,Pordic,Pordic,22,Côtes-d'Armor,53,Bretagne
22251,PORDIC,22590,PORDIC,LA VILLE LOUAIS,48.5702275208,-2.82517190036,251,,Pordic,Pordic,22,Côtes-d'Armor,53,Bretagne
22251,PORDIC,22590,PORDIC,TREMELOIR,48.5702275208,-2.82517190036,251,,Pordic,Pordic,22,Côtes-d'Armor,53,Bretagne
22253,POULDOURAN,22450,POULDOURAN,,48.7636243392,-3.20380242873,253,,Pouldouran,Pouldouran,22,Côtes-d'Armor,53,Bretagne
22254,PRAT,22140,PRAT,,48.6822524197,-3.29802324655,254,,Prat,Prat,22,Côtes-d'Armor,53,Bretagne
22255,LA PRENESSAYE,22210,LA PRENESSAYE,,48.185718113,-2.64785441869,255,La,Prénessaye,La Prénessaye,22,Côtes-d'Armor,53,Bretagne
22256,QUEMPER GUEZENNEC,22260,QUEMPER GUEZENNEC,,48.7006441281,-3.10832259045,256,,Quemper-Guézennec,Quemper-Guézennec,22,Côtes-d'Armor,53,Bretagne
22257,QUEMPERVEN,22450,QUEMPERVEN,,48.7254448619,-3.3365775536,257,,Quemperven,Quemperven,22,Côtes-d'Armor,53,Bretagne
22258,QUESSOY,22120,QUESSOY,,48.4272969207,-2.65707016677,258,,Quessoy,Quessoy,22,Côtes-d'Armor,53,Bretagne
22258,QUESSOY,22120,QUESSOY,L HOPITAL,48.4272969207,-2.65707016677,258,,Quessoy,Quessoy,22,Côtes-d'Armor,53,Bretagne
22259,QUEVERT,22100,QUEVERT,,48.463126899,-2.08562877928,259,,Quévert,Quévert,22,Côtes-d'Armor,53,Bretagne
22259,QUEVERT,22100,QUEVERT,L AUBLETTE QUEVERT,48.463126899,-2.08562877928,259,,Quévert,Quévert,22,Côtes-d'Armor,53,Bretagne
22260,LE QUILLIO,22460,LE QUILLIO,,48.2401978664,-2.89821390268,260,Le,Quillio,Le Quillio,22,Côtes-d'Armor,53,Bretagne
22261,QUINTENIC,22400,QUINTENIC,,48.5102353944,-2.42178978603,261,,Quintenic,Quintenic,22,Côtes-d'Armor,53,Bretagne
22262,QUINTIN,22800,QUINTIN,,48.4030218249,-2.91221040842,262,,Quintin,Quintin,22,Côtes-d'Armor,53,Bretagne
22263,LE QUIOU,22630,LE QUIOU,,48.345885697,-1.99810856153,263,Le,Quiou,Le Quiou,22,Côtes-d'Armor,53,Bretagne
22264,LA ROCHE DERRIEN,22450,LA ROCHE DERRIEN,,48.7456370261,-3.25762802773,264,La,Roche-Derrien,La Roche-Derrien,22,Côtes-d'Armor,53,Bretagne
22265,ROSPEZ,22300,ROSPEZ,,48.7363664722,-3.38603599093,265,,Rospez,Rospez,22,Côtes-d'Armor,53,Bretagne
22266,ROSTRENEN,22110,ROSTRENEN,,48.2215427736,-3.31179704668,266,,Rostrenen,Rostrenen,22,Côtes-d'Armor,53,Bretagne
22266,ROSTRENEN,22110,ROSTRENEN,BONEN,48.2215427736,-3.31179704668,266,,Rostrenen,Rostrenen,22,Côtes-d'Armor,53,Bretagne
22267,ROUILLAC,22250,ROUILLAC,,48.3069863373,-2.36929726654,267,,Rouillac,Rouillac,22,Côtes-d'Armor,53,Bretagne
22268,RUCA,22550,RUCA,,48.5614490125,-2.33540451705,268,,Ruca,Ruca,22,Côtes-d'Armor,53,Bretagne
22269,RUNAN,22260,RUNAN,,48.6867514768,-3.22734590799,269,,Runan,Runan,22,Côtes-d'Armor,53,Bretagne
22271,ST ADRIEN,22390,ST ADRIEN,,48.4898946488,-3.13256978406,271,,Saint-Adrien,Saint-Adrien,22,Côtes-d'Armor,53,Bretagne
22272,ST AGATHON,22200,ST AGATHON,,48.5628506514,-3.10081732497,272,,Saint-Agathon,Saint-Agathon,22,Côtes-d'Armor,53,Bretagne
22273,ST ALBAN,22400,ST ALBAN,,48.5551884911,-2.50893906207,273,,Saint-Alban,Saint-Alban,22,Côtes-d'Armor,53,Bretagne
22274,ST ANDRE DES EAUX,22630,ST ANDRE DES EAUX,,48.3731003808,-2.01421441815,274,,Saint-André-des-Eaux,Saint-André-des-Eaux,22,Côtes-d'Armor,53,Bretagne
22275,ST BARNABE,22600,ST BARNABE,,48.1383637671,-2.70188479455,275,,Saint-Barnabé,Saint-Barnabé,22,Côtes-d'Armor,53,Bretagne
22276,ST BIHY,22800,ST BIHY,,48.3758879251,-2.96669144631,276,,Saint-Bihy,Saint-Bihy,22,Côtes-d'Armor,53,Bretagne
22277,ST BRANDAN,22800,ST BRANDAN,,48.3903359027,-2.86345055821,277,,Saint-Brandan,Saint-Brandan,22,Côtes-d'Armor,53,Bretagne
22278,ST BRIEUC,22000,ST BRIEUC,,48.5149806053,-2.76154552773,278,,Saint-Brieuc,Saint-Brieuc,22,Côtes-d'Armor,53,Bretagne
22279,ST CARADEC,22600,ST CARADEC,,48.1922379278,-2.86751466131,279,,Saint-Caradec,Saint-Caradec,22,Côtes-d'Armor,53,Bretagne
22280,ST CARNE,22100,ST CARNE,,48.4195714776,-2.05460996507,280,,Saint-Carné,Saint-Carné,22,Côtes-d'Armor,53,Bretagne
22281,ST CARREUC,22150,ST CARREUC,,48.4036055637,-2.7500378807,281,,Saint-Carreuc,Saint-Carreuc,22,Côtes-d'Armor,53,Bretagne
22282,ST CAST LE GUILDO,22380,ST CAST LE GUILDO,,48.6037155601,-2.24912296088,282,,Saint-Cast-le-Guildo,Saint-Cast-le-Guildo,22,Côtes-d'Armor,53,Bretagne
22282,ST CAST LE GUILDO,22380,ST CAST LE GUILDO,NOTRE DAME DU GUILDO,48.6037155601,-2.24912296088,282,,Saint-Cast-le-Guildo,Saint-Cast-le-Guildo,22,Côtes-d'Armor,53,Bretagne
22283,ST CLET,22260,ST CLET,,48.6699370955,-3.13667326185,283,,Saint-Clet,Saint-Clet,22,Côtes-d'Armor,53,Bretagne
22284,ST CONNAN,22480,ST CONNAN,,48.412323087,-3.07608940512,284,,Saint-Connan,Saint-Connan,22,Côtes-d'Armor,53,Bretagne
22285,ST CONNEC,22530,ST CONNEC,,48.1800139025,-2.92593080092,285,,Saint-Connec,Saint-Connec,22,Côtes-d'Armor,53,Bretagne
22286,ST DENOUAL,22400,ST DENOUAL,,48.5186895679,-2.38405563335,286,,Saint-Denoual,Saint-Denoual,22,Côtes-d'Armor,53,Bretagne
22287,ST DONAN,22800,ST DONAN,,48.4657753573,-2.8865935093,287,,Saint-Donan,Saint-Donan,22,Côtes-d'Armor,53,Bretagne
22288,ST ETIENNE DU GUE DE L ISLE,22210,ST ETIENNE DU GUE DE L ISLE,,48.0856987745,-2.64402858884,288,,Saint-Étienne-du-Gué-de-l'Isle,Saint-Étienne-du-Gué-de-l'Isle,22,Côtes-d'Armor,53,Bretagne
22289,ST FIACRE,22720,ST FIACRE,,48.4553482894,-3.05227274488,289,,Saint-Fiacre,Saint-Fiacre,22,Côtes-d'Armor,53,Bretagne
22290,ST GELVEN,22570,ST GELVEN,,48.2278105935,-3.08912680587,290,,Saint-Gelven,Saint-Gelven,22,Côtes-d'Armor,53,Bretagne
22291,ST GILDAS,22800,ST GILDAS,,48.4221536178,-3.00656525522,291,,Saint-Gildas,Saint-Gildas,22,Côtes-d'Armor,53,Bretagne
22293,ST GILLES LES BOIS,22290,ST GILLES LES BOIS,,48.6570226311,-3.09056968713,293,,Saint-Gilles-les-Bois,Saint-Gilles-les-Bois,22,Côtes-d'Armor,53,Bretagne
22294,ST GILLES PLIGEAUX,22480,ST GILLES PLIGEAUX,,48.3796644952,-3.08710992512,294,,Saint-Gilles-Pligeaux,Saint-Gilles-Pligeaux,22,Côtes-d'Armor,53,Bretagne
22295,ST GILLES VIEUX MARCHE,22530,ST GILLES VIEUX MARCHE,,48.2503674727,-2.96951412596,295,,Saint-Gilles-Vieux-Marché,Saint-Gilles-Vieux-Marché,22,Côtes-d'Armor,53,Bretagne
22296,ST GLEN,22510,ST GLEN,,48.3522657851,-2.52156644539,296,,Saint-Glen,Saint-Glen,22,Côtes-d'Armor,53,Bretagne
22298,ST GUEN,22530,ST GUEN,,48.2110116935,-2.91380300415,298,,Saint-Guen,Saint-Guen,22,Côtes-d'Armor,53,Bretagne
22299,ST HELEN,22100,ST HELEN,,48.4661154661,-1.95919641216,299,,Saint-Hélen,Saint-Hélen,22,Côtes-d'Armor,53,Bretagne
22300,ST HERVE,22460,ST HERVE,,48.2737787659,-2.81191749639,300,,Saint-Hervé,Saint-Hervé,22,Côtes-d'Armor,53,Bretagne
22302,ST JACUT DE LA MER,22750,ST JACUT DE LA MER,,48.5879964752,-2.1932468359,302,,Saint-Jacut-de-la-Mer,Saint-Jacut-de-la-Mer,22,Côtes-d'Armor,53,Bretagne
22304,ST JEAN KERDANIEL,22170,ST JEAN KERDANIEL,,48.5525813879,-3.03402824762,304,,Saint-Jean-Kerdaniel,Saint-Jean-Kerdaniel,22,Côtes-d'Armor,53,Bretagne
22305,ST JOUAN DE L ISLE,22350,ST JOUAN DE L ISLE,,48.266340555,-2.17849741496,305,,Saint-Jouan-de-l'Isle,Saint-Jouan-de-l'Isle,22,Côtes-d'Armor,53,Bretagne
22306,ST JUDOCE,22630,ST JUDOCE,,48.3625267167,-1.95432639485,306,,Saint-Judoce,Saint-Judoce,22,Côtes-d'Armor,53,Bretagne
22307,ST JULIEN,22940,ST JULIEN,,48.4480990562,-2.81685604656,307,,Saint-Julien,Saint-Julien,22,Côtes-d'Armor,53,Bretagne
22308,ST JUVAT,22630,ST JUVAT,,48.3619921147,-2.05476636465,308,,Saint-Juvat,Saint-Juvat,22,Côtes-d'Armor,53,Bretagne
22309,ST LAUNEUC,22230,ST LAUNEUC,,48.2322069844,-2.35423564217,309,,Saint-Launeuc,Saint-Launeuc,22,Côtes-d'Armor,53,Bretagne
22310,ST LAURENT,22140,ST LAURENT,,48.6153469206,-3.24192782376,310,,Saint-Laurent,Saint-Laurent,22,Côtes-d'Armor,53,Bretagne
22311,ST LORMEL,22130,ST LORMEL,,48.5521979972,-2.23507551889,311,,Saint-Lormel,Saint-Lormel,22,Côtes-d'Armor,53,Bretagne
22312,ST MADEN,22350,ST MADEN,,48.3373513586,-2.06855041218,312,,Saint-Maden,Saint-Maden,22,Côtes-d'Armor,53,Bretagne
22313,ST MARTIN DES PRES,22320,ST MARTIN DES PRES,,48.3055992864,-2.96456859759,313,,Saint-Martin-des-Prés,Saint-Martin-des-Prés,22,Côtes-d'Armor,53,Bretagne
22314,ST MAUDAN,22600,ST MAUDAN,,48.1114125678,-2.76769637126,314,,Saint-Maudan,Saint-Maudan,22,Côtes-d'Armor,53,Bretagne
22315,ST MAUDEZ,22980,ST MAUDEZ,,48.4547574615,-2.18331618664,315,,Saint-Maudez,Saint-Maudez,22,Côtes-d'Armor,53,Bretagne
22316,ST MAYEUX,22320,ST MAYEUX,,48.2645956467,-3.01553906728,316,,Saint-Mayeux,Saint-Mayeux,22,Côtes-d'Armor,53,Bretagne
22317,ST MELOIR DES BOIS,22980,ST MELOIR DES BOIS,,48.4415973403,-2.25735689122,317,,Saint-Méloir-des-Bois,Saint-Méloir-des-Bois,22,Côtes-d'Armor,53,Bretagne
22318,ST MICHEL DE PLELAN,22980,ST MICHEL DE PLELAN,,48.4627340053,-2.22149059682,318,,Saint-Michel-de-Plélan,Saint-Michel-de-Plélan,22,Côtes-d'Armor,53,Bretagne
22319,ST MICHEL EN GREVE,22300,ST MICHEL EN GREVE,,48.6744448926,-3.5570602946,319,,Saint-Michel-en-Grève,Saint-Michel-en-Grève,22,Côtes-d'Armor,53,Bretagne
22320,ST NICODEME,22160,ST NICODEME,,48.3537346868,-3.33942059612,320,,Saint-Nicodème,Saint-Nicodème,22,Côtes-d'Armor,53,Bretagne
22321,ST NICOLAS DU PELEM,22480,ST NICOLAS DU PELEM,,48.3228229089,-3.16405050641,321,,Saint-Nicolas-du-Pélem,Saint-Nicolas-du-Pélem,22,Côtes-d'Armor,53,Bretagne
22322,ST PEVER,22720,ST PEVER,,48.4822950613,-3.09102052353,322,,Saint-Péver,Saint-Péver,22,Côtes-d'Armor,53,Bretagne
22323,ST POTAN,22550,ST POTAN,,48.5574203635,-2.29323126178,323,,Saint-Pôtan,Saint-Pôtan,22,Côtes-d'Armor,53,Bretagne
22324,ST QUAY PERROS,22700,ST QUAY PERROS,,48.7821597009,-3.44898067096,324,,Saint-Quay-Perros,Saint-Quay-Perros,22,Côtes-d'Armor,53,Bretagne
22325,ST QUAY PORTRIEUX,22410,ST QUAY PORTRIEUX,,48.6542475006,-2.84382337656,325,,Saint-Quay-Portrieux,Saint-Quay-Portrieux,22,Côtes-d'Armor,53,Bretagne
22325,ST QUAY PORTRIEUX,22410,ST QUAY PORTRIEUX,KERTUGAL,48.6542475006,-2.84382337656,325,,Saint-Quay-Portrieux,Saint-Quay-Portrieux,22,Côtes-d'Armor,53,Bretagne
22326,ST RIEUL,22270,ST RIEUL,,48.4428159884,-2.42162290786,326,,Saint-Rieul,Saint-Rieul,22,Côtes-d'Armor,53,Bretagne
22327,ST SAMSON SUR RANCE,22100,ST SAMSON SUR RANCE,,48.4914561731,-2.01805917808,327,,Saint-Samson-sur-Rance,Saint-Samson-sur-Rance,22,Côtes-d'Armor,53,Bretagne
22327,ST SAMSON SUR RANCE,22100,ST SAMSON SUR RANCE,LA HISSE ST SAMSON,48.4914561731,-2.01805917808,327,,Saint-Samson-sur-Rance,Saint-Samson-sur-Rance,22,Côtes-d'Armor,53,Bretagne
22328,ST SERVAIS,22160,ST SERVAIS,,48.3866899196,-3.37424456505,328,,Saint-Servais,Saint-Servais,22,Côtes-d'Armor,53,Bretagne
22330,ST THELO,22460,ST THELO,,48.2459300421,-2.84098515337,330,,Saint-Thélo,Saint-Thélo,22,Côtes-d'Armor,53,Bretagne
22331,STE TREPHINE,22480,STE TREPHINE,,48.2666933558,-3.16936458058,331,,Sainte-Tréphine,Sainte-Tréphine,22,Côtes-d'Armor,53,Bretagne
22332,ST TRIMOEL,22510,ST TRIMOEL,,48.3933041985,-2.5412449971,332,,Saint-Trimoël,Saint-Trimoël,22,Côtes-d'Armor,53,Bretagne
22333,ST VRAN,22230,ST VRAN,,48.2433929784,-2.45697339258,333,,Saint-Vran,Saint-Vran,22,Côtes-d'Armor,53,Bretagne
22334,ST IGEAUX,22570,ST IGEAUX,,48.2777505103,-3.11474670653,334,,Saint-Igeaux,Saint-Igeaux,22,Côtes-d'Armor,53,Bretagne
22335,SENVEN LEHART,22720,SENVEN LEHART,,48.4354167411,-3.06449717783,335,,Senven-Léhart,Senven-Léhart,22,Côtes-d'Armor,53,Bretagne
22337,SEVIGNAC,22250,SEVIGNAC,,48.3321356963,-2.3200523158,337,,Sévignac,Sévignac,22,Côtes-d'Armor,53,Bretagne
22338,SQUIFFIEC,22200,SQUIFFIEC,,48.6379229078,-3.16710907614,338,,Squiffiec,Squiffiec,22,Côtes-d'Armor,53,Bretagne
22339,TADEN,22100,TADEN,,48.4879367095,-2.05402856575,339,,Taden,Taden,22,Côtes-d'Armor,53,Bretagne
22340,TONQUEDEC,22140,TONQUEDEC,,48.6751108479,-3.40013620109,340,,Tonquédec,Tonquédec,22,Côtes-d'Armor,53,Bretagne
22341,TRAMAIN,22640,TRAMAIN,,48.3998800442,-2.38881241338,341,,Tramain,Tramain,22,Côtes-d'Armor,53,Bretagne
22342,TREBEDAN,22980,TREBEDAN,,48.4057639956,-2.16287110256,342,,Trébédan,Trébédan,22,Côtes-d'Armor,53,Bretagne
22343,TREBEURDEN,22560,TREBEURDEN,,48.7707775944,-3.55769238632,343,,Trébeurden,Trébeurden,22,Côtes-d'Armor,53,Bretagne
22344,TREBRIVAN,22340,TREBRIVAN,,48.3102383072,-3.48210939966,344,,Trébrivan,Trébrivan,22,Côtes-d'Armor,53,Bretagne
22345,TREBRY,22510,TREBRY,,48.3456919515,-2.55853331378,345,,Trébry,Trébry,22,Côtes-d'Armor,53,Bretagne
22346,TREDANIEL,22510,TREDANIEL,,48.346590108,-2.6057121928,346,,Trédaniel,Trédaniel,22,Côtes-d'Armor,53,Bretagne
22347,TREDARZEC,22220,TREDARZEC,,48.7908718928,-3.20159345047,347,,Trédarzec,Trédarzec,22,Côtes-d'Armor,53,Bretagne
22348,TREDIAS,22250,TREDIAS,,48.3653903588,-2.22290375179,348,,Trédias,Trédias,22,Côtes-d'Armor,53,Bretagne
22349,TREDREZ LOCQUEMEAU,22300,TREDREZ LOCQUEMEAU,,48.7075368111,-3.56441252563,349,,Trédrez-Locquémeau,Trédrez-Locquémeau,22,Côtes-d'Armor,53,Bretagne
22349,TREDREZ LOCQUEMEAU,22300,TREDREZ LOCQUEMEAU,LOCQUEMEAU,48.7075368111,-3.56441252563,349,,Trédrez-Locquémeau,Trédrez-Locquémeau,22,Côtes-d'Armor,53,Bretagne
22350,TREDUDER,22310,TREDUDER,,48.6542493069,-3.56641535268,350,,Tréduder,Tréduder,22,Côtes-d'Armor,53,Bretagne
22351,TREFFRIN,22340,TREFFRIN,,48.2985314083,-3.52731682316,351,,Treffrin,Treffrin,22,Côtes-d'Armor,53,Bretagne
22352,TREFUMEL,22630,TREFUMEL,,48.3373946389,-2.02418425238,352,,Tréfumel,Tréfumel,22,Côtes-d'Armor,53,Bretagne
22353,TREGASTEL,22730,TREGASTEL,,48.817321796,-3.50640333126,353,,Trégastel,Trégastel,22,Côtes-d'Armor,53,Bretagne
22354,TREGLAMUS,22540,TREGLAMUS,,48.5568884006,-3.25462345127,354,,Tréglamus,Tréglamus,22,Côtes-d'Armor,53,Bretagne
22356,TREGOMEUR,22590,TREGOMEUR,,48.5678577171,-2.88321807622,356,,Trégomeur,Trégomeur,22,Côtes-d'Armor,53,Bretagne
22357,TREGON,22650,TREGON,,48.5667372061,-2.1838363617,357,,Trégon,Trégon,22,Côtes-d'Armor,53,Bretagne
22358,TREGONNEAU,22200,TREGONNEAU,,48.6141977359,-3.16293062562,358,,Trégonneau,Trégonneau,22,Côtes-d'Armor,53,Bretagne
22359,TREGROM,22420,TREGROM,,48.5946464284,-3.40266097261,359,,Trégrom,Trégrom,22,Côtes-d'Armor,53,Bretagne
22360,TREGUEUX,22950,TREGUEUX,,48.480075769,-2.74506421541,360,,Trégueux,Trégueux,22,Côtes-d'Armor,53,Bretagne
22360,TREGUEUX,22950,TREGUEUX,CREAC H TREGUEUX,48.480075769,-2.74506421541,360,,Trégueux,Trégueux,22,Côtes-d'Armor,53,Bretagne
22361,TREGUIDEL,22290,TREGUIDEL,,48.6002375732,-2.94288237882,361,,Tréguidel,Tréguidel,22,Côtes-d'Armor,53,Bretagne
22362,TREGUIER,22220,TREGUIER,,48.7850465816,-3.23178776538,362,,Tréguier,Tréguier,22,Côtes-d'Armor,53,Bretagne
22363,TRELEVERN,22660,TRELEVERN,,48.801785558,-3.36967789456,363,,Trélévern,Trélévern,22,Côtes-d'Armor,53,Bretagne
22364,TRELIVAN,22100,TRELIVAN,,48.4303990334,-2.10633550903,364,,Trélivan,Trélivan,22,Côtes-d'Armor,53,Bretagne
22365,TREMARGAT,22110,TREMARGAT,,48.3299865723,-3.26674956087,365,,Trémargat,Trémargat,22,Côtes-d'Armor,53,Bretagne
22366,TREMEL,22310,TREMEL,,48.6047752602,-3.61826523039,366,,Trémel,Trémel,22,Côtes-d'Armor,53,Bretagne
22368,TREMEREUC,22490,TREMEREUC,,48.5608248662,-2.06398861728,368,,Tréméreuc,Tréméreuc,22,Côtes-d'Armor,53,Bretagne
22369,TREMEUR,22250,TREMEUR,,48.351008857,-2.26842512279,369,,Trémeur,Trémeur,22,Côtes-d'Armor,53,Bretagne
22370,TREMEVEN,22290,TREMEVEN,,48.671108578,-3.0320602067,370,,Tréméven,Tréméven,22,Côtes-d'Armor,53,Bretagne
22371,TREMOREL,22230,TREMOREL,,48.1914259889,-2.30214292641,371,,Trémorel,Trémorel,22,Côtes-d'Armor,53,Bretagne
22372,TREMUSON,22440,TREMUSON,,48.5248190208,-2.84790774522,372,,Trémuson,Trémuson,22,Côtes-d'Armor,53,Bretagne
22373,TREOGAN,22340,TREOGAN,,48.1867140981,-3.53008460226,373,,Tréogan,Tréogan,22,Côtes-d'Armor,53,Bretagne
22375,TRESSIGNAUX,22290,TRESSIGNAUX,,48.6103866448,-2.97487593888,375,,Tressignaux,Tressignaux,22,Côtes-d'Armor,53,Bretagne
22376,TREVE,22600,TREVE,,48.2160142902,-2.80698312231,376,,Trévé,Trévé,22,Côtes-d'Armor,53,Bretagne
22377,TREVENEUC,22410,TREVENEUC,,48.6598480306,-2.87375934756,377,,Tréveneuc,Tréveneuc,22,Côtes-d'Armor,53,Bretagne
22378,TREVEREC,22290,TREVEREC,,48.659497706,-3.05457326642,378,,Trévérec,Trévérec,22,Côtes-d'Armor,53,Bretagne
22379,TREVOU TREGUIGNEC,22660,TREVOU TREGUIGNEC,,48.8121355763,-3.34499178706,379,,Trévou-Tréguignec,Trévou-Tréguignec,22,Côtes-d'Armor,53,Bretagne
22380,TREVRON,22100,TREVRON,,48.389428188,-2.06825084533,380,,Trévron,Trévron,22,Côtes-d'Armor,53,Bretagne
22381,TREZENY,22450,TREZENY,,48.7546408684,-3.36053531612,381,,Trézény,Trézény,22,Côtes-d'Armor,53,Bretagne
22383,TROGUERY,22450,TROGUERY,,48.7607516735,-3.22457645255,383,,Troguéry,Troguéry,22,Côtes-d'Armor,53,Bretagne
22384,UZEL,22460,UZEL PRES L OUST,,48.2806260052,-2.84761696775,384,,Uzel,Uzel,22,Côtes-d'Armor,53,Bretagne
22385,LA VICOMTE SUR RANCE,22690,LA VICOMTE SUR RANCE,,48.4879334459,-1.98287622329,385,La,Vicomté-sur-Rance,La Vicomté-sur-Rance,22,Côtes-d'Armor,53,Bretagne
22386,LE VIEUX BOURG,22800,LE VIEUX BOURG,,48.3910051078,-3.00385012187,386,Le,Vieux-Bourg,Le Vieux-Bourg,22,Côtes-d'Armor,53,Bretagne
22387,LE VIEUX MARCHE,22420,LE VIEUX MARCHE,,48.6138736839,-3.43816740638,387,Le,Vieux-Marché,Le Vieux-Marché,22,Côtes-d'Armor,53,Bretagne
22388,VILDE GUINGALAN,22980,VILDE GUINGALAN,,48.4365421209,-2.1517921556,388,,Vildé-Guingalan,Vildé-Guingalan,22,Côtes-d'Armor,53,Bretagne
22389,YFFINIAC,22120,YFFINIAC,,48.4685015878,-2.68098120309,389,,Yffiniac,Yffiniac,22,Côtes-d'Armor,53,Bretagne
22390,YVIAS,22930,YVIAS,,48.7151767703,-3.04560013662,390,,Yvias,Yvias,22,Côtes-d'Armor,53,Bretagne
22391,YVIGNAC LA TOUR,22350,YVIGNAC LA TOUR,,48.3488527307,-2.17094067762,391,,Yvignac-la-Tour,Yvignac-la-Tour,22,Côtes-d'Armor,53,Bretagne
23001,AHUN,23150,AHUN,,46.0861690765,2.02430101436,1,,Ahun,Ahun,23,Creuse,75,Nouvelle-Aquitaine
23002,AJAIN,23380,AJAIN,,46.2109887646,1.9959623106,2,,Ajain,Ajain,23,Creuse,75,Nouvelle-Aquitaine
23003,ALLEYRAT,23200,ALLEYRAT,,45.9878416933,2.15421492891,3,,Alleyrat,Alleyrat,23,Creuse,75,Nouvelle-Aquitaine
23004,ANZEME,23000,ANZEME,,46.2540912663,1.84997964766,4,,Anzême,Anzême,23,Creuse,75,Nouvelle-Aquitaine
23005,ARFEUILLE CHATAIN,23700,ARFEUILLE CHATAIN,,46.0604129524,2.43471824628,5,,Arfeuille-Châtain,Arfeuille-Châtain,23,Creuse,75,Nouvelle-Aquitaine
23006,ARRENES,23210,ARRENES,,46.0724415399,1.56731050759,6,,Arrènes,Arrènes,23,Creuse,75,Nouvelle-Aquitaine
23007,ARS,23480,ARS,,45.9991439171,2.08403361225,7,,Ars,Ars,23,Creuse,75,Nouvelle-Aquitaine
23008,AUBUSSON,23200,AUBUSSON,,45.9509017497,2.16588054786,8,,Aubusson,Aubusson,23,Creuse,75,Nouvelle-Aquitaine
23009,AUGE,23170,AUGE,,46.2367809239,2.33256543891,9,,Auge,Auge,23,Creuse,75,Nouvelle-Aquitaine
23010,AUGERES,23210,AUGERES,,46.0799619042,1.72763541074,10,,Augères,Augères,23,Creuse,75,Nouvelle-Aquitaine
23011,AULON,23210,AULON,,46.0918100257,1.69586417103,11,,Aulon,Aulon,23,Creuse,75,Nouvelle-Aquitaine
23012,AURIAT,23400,AURIAT,,45.8709814148,1.63389938351,12,,Auriat,Auriat,23,Creuse,75,Nouvelle-Aquitaine
23013,AUZANCES,23700,AUZANCES,,46.0263051505,2.50665292923,13,,Auzances,Auzances,23,Creuse,75,Nouvelle-Aquitaine
23014,AZAT CHATENET,23210,AZAT CHATENET,,46.0866252044,1.76923265041,14,,Azat-Châtenet,Azat-Châtenet,23,Creuse,75,Nouvelle-Aquitaine
23015,AZERABLES,23160,AZERABLES,,46.3524585788,1.47492272648,15,,Azerables,Azerables,23,Creuse,75,Nouvelle-Aquitaine
23016,BANIZE,23120,BANIZE,,45.9368272021,1.99756920373,16,,Banize,Banize,23,Creuse,75,Nouvelle-Aquitaine
23017,BASVILLE,23260,BASVILLE,,45.8577538427,2.41245450186,17,,Basville,Basville,23,Creuse,75,Nouvelle-Aquitaine
23018,BAZELAT,23160,BAZELAT,,46.3502358102,1.53198134056,18,,Bazelat,Bazelat,23,Creuse,75,Nouvelle-Aquitaine
23019,BEISSAT,23260,BEISSAT,,45.7647646424,2.28346440159,19,,Beissat,Beissat,23,Creuse,75,Nouvelle-Aquitaine
23020,BELLEGARDE EN MARCHE,23190,BELLEGARDE EN MARCHE,,45.9815413082,2.29291200261,20,,Bellegarde-en-Marche,Bellegarde-en-Marche,23,Creuse,75,Nouvelle-Aquitaine
23021,BENEVENT L ABBAYE,23210,BENEVENT L ABBAYE,,46.1177051494,1.62940216696,21,,Bénévent-l'Abbaye,Bénévent-l'Abbaye,23,Creuse,75,Nouvelle-Aquitaine
23022,BETETE,23270,BETETE,,46.3616170895,2.06970642299,22,,Bétête,Bétête,23,Creuse,75,Nouvelle-Aquitaine
23023,BLAUDEIX,23140,BLAUDEIX,,46.2296903027,2.08515076921,23,,Blaudeix,Blaudeix,23,Creuse,75,Nouvelle-Aquitaine
23024,BLESSAC,23200,BLESSAC,,45.9634823691,2.11186170514,24,,Blessac,Blessac,23,Creuse,75,Nouvelle-Aquitaine
23025,BONNAT,23220,BONNAT,,46.3230193342,1.91345063136,25,,Bonnat,Bonnat,23,Creuse,75,Nouvelle-Aquitaine
23026,BORD ST GEORGES,23230,BORD ST GEORGES,,46.2516533293,2.28716245928,26,,Bord-Saint-Georges,Bord-Saint-Georges,23,Creuse,75,Nouvelle-Aquitaine
23027,BOSMOREAU LES MINES,23400,BOSMOREAU LES MINES,,46.0023571531,1.754330245,27,,Bosmoreau-les-Mines,Bosmoreau-les-Mines,23,Creuse,75,Nouvelle-Aquitaine
23028,BOSROGER,23200,BOSROGER,,46.0038432661,2.25085603328,28,,Bosroger,Bosroger,23,Creuse,75,Nouvelle-Aquitaine
23029,LE BOURG D HEM,23220,LE BOURG D HEM,,46.3013219598,1.84102963635,29,Le,Bourg-d'Hem,Le Bourg-d'Hem,23,Creuse,75,Nouvelle-Aquitaine
23030,BOURGANEUF,23400,BOURGANEUF,,45.9488316106,1.74900640908,30,,Bourganeuf,Bourganeuf,23,Creuse,75,Nouvelle-Aquitaine
23031,BOUSSAC,23600,BOUSSAC,,46.3477988383,2.2230835624,31,,Boussac,Boussac,23,Creuse,75,Nouvelle-Aquitaine
23032,BOUSSAC BOURG,23600,BOUSSAC BOURG,,46.3680932326,2.21561135415,32,,Boussac-Bourg,Boussac-Bourg,23,Creuse,75,Nouvelle-Aquitaine
23033,LA BRIONNE,23000,LA BRIONNE,,46.1643596252,1.78886937699,33,La,Brionne,La Brionne,23,Creuse,75,Nouvelle-Aquitaine
23034,BROUSSE,23700,BROUSSE,,45.9721485733,2.45298546881,34,,Brousse,Brousse,23,Creuse,75,Nouvelle-Aquitaine
23035,BUDELIERE,23170,BUDELIERE,,46.2207179959,2.47512106932,35,,Budelière,Budelière,23,Creuse,75,Nouvelle-Aquitaine
23036,BUSSIERE DUNOISE,23320,BUSSIERE DUNOISE,,46.2557563737,1.76028995506,36,,Bussière-Dunoise,Bussière-Dunoise,23,Creuse,75,Nouvelle-Aquitaine
23037,BUSSIERE NOUVELLE,23700,BUSSIERE NOUVELLE,,46.0136722652,2.41956472448,37,,Bussière-Nouvelle,Bussière-Nouvelle,23,Creuse,75,Nouvelle-Aquitaine
23038,BUSSIERE ST GEORGES,23600,BUSSIERE ST GEORGES,,46.4017907068,2.1551143589,38,,Bussière-Saint-Georges,Bussière-Saint-Georges,23,Creuse,75,Nouvelle-Aquitaine
23039,LA CELLE DUNOISE,23800,LA CELLE DUNOISE,,46.311979886,1.78178481462,39,La,Celle-Dunoise,La Celle-Dunoise,23,Creuse,75,Nouvelle-Aquitaine
23040,LA CELLE SOUS GOUZON,23230,LA CELLE SOUS GOUZON,,46.2188855114,2.20927433054,40,La,Celle-sous-Gouzon,La Celle-sous-Gouzon,23,Creuse,75,Nouvelle-Aquitaine
23041,LA CELLETTE,23350,LA CELLETTE,,46.4013328197,2.00952314466,41,La,Cellette,La Cellette,23,Creuse,75,Nouvelle-Aquitaine
23042,CEYROUX,23210,CEYROUX,,46.0590279799,1.66489308266,42,,Ceyroux,Ceyroux,23,Creuse,75,Nouvelle-Aquitaine
23043,CHAMBERAUD,23480,CHAMBERAUD,,46.0500466747,2.04421088679,43,,Chamberaud,Chamberaud,23,Creuse,75,Nouvelle-Aquitaine
23044,CHAMBON STE CROIX,23220,CHAMBON STE CROIX,,46.3528332177,1.76950395174,44,,Chambon-Sainte-Croix,Chambon-Sainte-Croix,23,Creuse,75,Nouvelle-Aquitaine
23045,CHAMBON SUR VOUEIZE,23170,CHAMBON SUR VOUEIZE,,46.1927327161,2.41857573125,45,,Chambon-sur-Voueize,Chambon-sur-Voueize,23,Creuse,75,Nouvelle-Aquitaine
23046,CHAMBONCHARD,23110,CHAMBONCHARD,,46.1608851988,2.5415892944,46,,Chambonchard,Chambonchard,23,Creuse,75,Nouvelle-Aquitaine
23047,CHAMBORAND,23240,CHAMBORAND,,46.1570722156,1.57302585912,47,,Chamborand,Chamborand,23,Creuse,75,Nouvelle-Aquitaine
23048,CHAMPAGNAT,23190,CHAMPAGNAT,,46.0202394723,2.28670592425,48,,Champagnat,Champagnat,23,Creuse,75,Nouvelle-Aquitaine
23049,CHAMPSANGLARD,23220,CHAMPSANGLARD,,46.2806561579,1.88931352646,49,,Champsanglard,Champsanglard,23,Creuse,75,Nouvelle-Aquitaine
23050,LA CHAPELLE BALOUE,23160,LA CHAPELLE BALOUE,,46.3589545585,1.56868823995,50,La,Chapelle-Baloue,La Chapelle-Baloue,23,Creuse,75,Nouvelle-Aquitaine
23051,LA CHAPELLE ST MARTIAL,23250,LA CHAPELLE ST MARTIAL,,46.0253131218,1.9223831649,51,La,Chapelle-Saint-Martial,La Chapelle-Saint-Martial,23,Creuse,75,Nouvelle-Aquitaine
23052,LA CHAPELLE TAILLEFERT,23000,LA CHAPELLE TAILLEFERT,,46.1137729071,1.8336197788,52,La,Chapelle-Taillefert,La Chapelle-Taillefert,23,Creuse,75,Nouvelle-Aquitaine
23053,CHARD,23700,CHARD,,45.9497905818,2.49042284928,53,,Chard,Chard,23,Creuse,75,Nouvelle-Aquitaine
23054,CHARRON,23700,CHARRON,,46.0375605731,2.56407684022,54,,Charron,Charron,23,Creuse,75,Nouvelle-Aquitaine
23055,CHATELARD,23700,CHATELARD,,45.963784452,2.46736302039,55,,Châtelard,Châtelard,23,Creuse,75,Nouvelle-Aquitaine
23056,CHATELUS LE MARCHEIX,23430,CHATELUS LE MARCHEIX,,46.0069474646,1.62547774124,56,,Châtelus-le-Marcheix,Châtelus-le-Marcheix,23,Creuse,75,Nouvelle-Aquitaine
23057,CHATELUS MALVALEIX,23270,CHATELUS MALVALEIX,,46.2969350634,2.03300541639,57,,Châtelus-Malvaleix,Châtelus-Malvaleix,23,Creuse,75,Nouvelle-Aquitaine
23058,LE CHAUCHET,23130,LE CHAUCHET,,46.1103548502,2.32892564693,58,Le,Chauchet,Le Chauchet,23,Creuse,75,Nouvelle-Aquitaine
23059,LA CHAUSSADE,23200,LA CHAUSSADE,,45.9876295804,2.24885532126,59,La,Chaussade,La Chaussade,23,Creuse,75,Nouvelle-Aquitaine
23060,CHAVANAT,23250,CHAVANAT,,45.9523570692,1.96630866887,60,,Chavanat,Chavanat,23,Creuse,75,Nouvelle-Aquitaine
23061,CHENERAILLES,23130,CHENERAILLES,,46.1127495212,2.17151822277,61,,Chénérailles,Chénérailles,23,Creuse,75,Nouvelle-Aquitaine
23062,CHENIERS,23220,CHENIERS,,46.3514072872,1.8276144193,62,,Chéniers,Chéniers,23,Creuse,75,Nouvelle-Aquitaine
23063,CLAIRAVAUX,23500,CLAIRAVAUX,,45.7760335955,2.17700112411,63,,Clairavaux,Clairavaux,23,Creuse,75,Nouvelle-Aquitaine
23064,CLUGNAT,23270,CLUGNAT,,46.2993581624,2.11966414545,64,,Clugnat,Clugnat,23,Creuse,75,Nouvelle-Aquitaine
23065,COLONDANNES,23800,COLONDANNES,,46.288107572,1.61692069958,65,,Colondannes,Colondannes,23,Creuse,75,Nouvelle-Aquitaine
23066,LE COMPAS,23700,LE COMPAS,,46.0039185769,2.4526231554,66,Le,Compas,Le Compas,23,Creuse,75,Nouvelle-Aquitaine
23067,LA COURTINE,23100,LA COURTINE,,45.7301505844,2.24382828385,67,La,Courtine,La Courtine,23,Creuse,75,Nouvelle-Aquitaine
23068,CRESSAT,23140,CRESSAT,,46.1345372274,2.09295080536,68,,Cressat,Cressat,23,Creuse,75,Nouvelle-Aquitaine
23069,CROCQ,23260,CROCQ,,45.8502209227,2.36477428271,69,,Crocq,Crocq,23,Creuse,75,Nouvelle-Aquitaine
23070,CROZANT,23160,CROZANT,,46.3822860468,1.61270659121,70,,Crozant,Crozant,23,Creuse,75,Nouvelle-Aquitaine
23071,CROZE,23500,CROZE,,45.8244062153,2.16780878793,71,,Croze,Croze,23,Creuse,75,Nouvelle-Aquitaine
23072,DOMEYROT,23140,DOMEYROT,,46.2491823013,2.14721070641,72,,Domeyrot,Domeyrot,23,Creuse,75,Nouvelle-Aquitaine
23073,DONTREIX,23700,DONTREIX,,45.978839337,2.5468586332,73,,Dontreix,Dontreix,23,Creuse,75,Nouvelle-Aquitaine
23074,LE DONZEIL,23480,LE DONZEIL,,46.03538708,1.98532939253,74,Le,Donzeil,Le Donzeil,23,Creuse,75,Nouvelle-Aquitaine
23075,DUN LE PALESTEL,23800,DUN LE PALESTEL,,46.3032821577,1.66218300041,75,,Dun-le-Palestel,Dun-le-Palestel,23,Creuse,75,Nouvelle-Aquitaine
23076,EVAUX LES BAINS,23110,EVAUX LES BAINS,,46.168925905,2.49933110004,76,,Évaux-les-Bains,Évaux-les-Bains,23,Creuse,75,Nouvelle-Aquitaine
23077,FAUX LA MONTAGNE,23340,FAUX LA MONTAGNE,,45.7429564648,1.93500942222,77,,Faux-la-Montagne,Faux-la-Montagne,23,Creuse,75,Nouvelle-Aquitaine
23078,FAUX MAZURAS,23400,FAUX MAZURAS,,45.9224179922,1.77972681023,78,,Faux-Mazuras,Faux-Mazuras,23,Creuse,75,Nouvelle-Aquitaine
23079,FELLETIN,23500,FELLETIN,,45.889520976,2.18271124708,79,,Felletin,Felletin,23,Creuse,75,Nouvelle-Aquitaine
23080,FENIERS,23100,FENIERS,,45.7440562611,2.12615175724,80,,Féniers,Féniers,23,Creuse,75,Nouvelle-Aquitaine
23081,FLAYAT,23260,FLAYAT,,45.7785868628,2.38619915341,81,,Flayat,Flayat,23,Creuse,75,Nouvelle-Aquitaine
23082,FLEURAT,23320,FLEURAT,,46.2324388485,1.68303685298,82,,Fleurat,Fleurat,23,Creuse,75,Nouvelle-Aquitaine
23083,FONTANIERES,23110,FONTANIERES,,46.1071405466,2.51926577888,83,,Fontanières,Fontanières,23,Creuse,75,Nouvelle-Aquitaine
23084,LA FORET DU TEMPLE,23360,LA FORET DU TEMPLE,,46.4271220659,1.90602362472,84,La,Forêt-du-Temple,La Forêt-du-Temple,23,Creuse,75,Nouvelle-Aquitaine
23086,FRANSECHES,23480,FRANSECHES,,46.0285507814,2.03995506962,86,,Fransèches,Fransèches,23,Creuse,75,Nouvelle-Aquitaine
23087,FRESSELINES,23450,FRESSELINES,,46.3778373605,1.69897316161,87,,Fresselines,Fresselines,23,Creuse,75,Nouvelle-Aquitaine
23088,GARTEMPE,23320,GARTEMPE,,46.1523609453,1.72597584698,88,,Gartempe,Gartempe,23,Creuse,75,Nouvelle-Aquitaine
23089,GENOUILLAC,23350,GENOUILLAC,,46.3446574595,1.9934844532,89,,Genouillac,Genouillac,23,Creuse,75,Nouvelle-Aquitaine
23090,GENTIOUX PIGEROLLES,23340,GENTIOUX PIGEROLLES,,45.7846764541,2.00852107248,90,,Gentioux-Pigerolles,Gentioux-Pigerolles,23,Creuse,75,Nouvelle-Aquitaine
23090,GENTIOUX PIGEROLLES,23340,GENTIOUX PIGEROLLES,PIGEROLLES,45.7846764541,2.00852107248,90,,Gentioux-Pigerolles,Gentioux-Pigerolles,23,Creuse,75,Nouvelle-Aquitaine
23091,GIOUX,23500,GIOUX,,45.8012431656,2.11443257853,91,,Gioux,Gioux,23,Creuse,75,Nouvelle-Aquitaine
23092,GLENIC,23380,GLENIC,,46.2297069592,1.94120796612,92,,Glénic,Glénic,23,Creuse,75,Nouvelle-Aquitaine
23093,GOUZON,23230,GOUZON,,46.1872230665,2.23078389232,93,,Gouzon,Gouzon,23,Creuse,75,Nouvelle-Aquitaine
23093,GOUZON,23230,GOUZON,GOUZOUGNAT,46.1872230665,2.23078389232,93,,Gouzon,Gouzon,23,Creuse,75,Nouvelle-Aquitaine
23093,GOUZON,23230,GOUZON,LES FORGES,46.1872230665,2.23078389232,93,,Gouzon,Gouzon,23,Creuse,75,Nouvelle-Aquitaine
23095,LE GRAND BOURG,23240,LE GRAND BOURG,,46.1597264029,1.65508431934,95,Le,Grand-Bourg,Le Grand-Bourg,23,Creuse,75,Nouvelle-Aquitaine
23096,GUERET,23000,GUERET,,46.1632121428,1.87078672735,96,,Guéret,Guéret,23,Creuse,75,Nouvelle-Aquitaine
23097,ISSOUDUN LETRIEIX,23130,ISSOUDUN LETRIEIX,,46.0660443592,2.16414045636,97,,Issoudun-Létrieix,Issoudun-Létrieix,23,Creuse,75,Nouvelle-Aquitaine
23098,JALESCHES,23270,JALESCHES,,46.2952982934,2.08236208756,98,,Jalesches,Jalesches,23,Creuse,75,Nouvelle-Aquitaine
23099,JANAILLAT,23250,JANAILLAT,,46.0480658385,1.77462877291,99,,Janaillat,Janaillat,23,Creuse,75,Nouvelle-Aquitaine
23100,JARNAGES,23140,JARNAGES,,46.1901556115,2.0803969544,100,,Jarnages,Jarnages,23,Creuse,75,Nouvelle-Aquitaine
23101,JOUILLAT,23220,JOUILLAT,,46.2681750557,1.93780051823,101,,Jouillat,Jouillat,23,Creuse,75,Nouvelle-Aquitaine
23102,LADAPEYRE,23270,LADAPEYRE,,46.2487275281,2.05005881672,102,,Ladapeyre,Ladapeyre,23,Creuse,75,Nouvelle-Aquitaine
23103,LAFAT,23800,LAFAT,,46.3383631539,1.6058357703,103,,Lafat,Lafat,23,Creuse,75,Nouvelle-Aquitaine
23104,LAVAUFRANCHE,23600,LAVAUFRANCHE,,46.3054306554,2.27199399628,104,,Lavaufranche,Lavaufranche,23,Creuse,75,Nouvelle-Aquitaine
23105,LAVAVEIX LES MINES,23150,LAVAVEIX LES MINES,,46.0743634605,2.09358271092,105,,Lavaveix-les-Mines,Lavaveix-les-Mines,23,Creuse,75,Nouvelle-Aquitaine
23106,LEPAUD,23170,LEPAUD,,46.2313447495,2.38424161518,106,,Lépaud,Lépaud,23,Creuse,75,Nouvelle-Aquitaine
23107,LEPINAS,23150,LEPINAS,,46.0586619387,1.93693233899,107,,Lépinas,Lépinas,23,Creuse,75,Nouvelle-Aquitaine
23108,LEYRAT,23600,LEYRAT,,46.3583997455,2.29375289146,108,,Leyrat,Leyrat,23,Creuse,75,Nouvelle-Aquitaine
23109,LINARD,23220,LINARD,,46.3666691845,1.87647741216,109,,Linard,Linard,23,Creuse,75,Nouvelle-Aquitaine
23110,LIOUX LES MONGES,23700,LIOUX LES MONGES,,45.9406455555,2.4446876759,110,,Lioux-les-Monges,Lioux-les-Monges,23,Creuse,75,Nouvelle-Aquitaine
23111,LIZIERES,23240,LIZIERES,,46.2087390078,1.57238796935,111,,Lizières,Lizières,23,Creuse,75,Nouvelle-Aquitaine
23112,LOURDOUEIX ST PIERRE,23360,LOURDOUEIX ST PIERRE,,46.3997480565,1.82842448851,112,,Lourdoueix-Saint-Pierre,Lourdoueix-Saint-Pierre,23,Creuse,75,Nouvelle-Aquitaine
23112,LOURDOUEIX ST PIERRE,23360,LOURDOUEIX ST PIERRE,LIGNAUD,46.3997480565,1.82842448851,112,,Lourdoueix-Saint-Pierre,Lourdoueix-Saint-Pierre,23,Creuse,75,Nouvelle-Aquitaine
23113,LUPERSAT,23190,LUPERSAT,,45.9899835422,2.35823852546,113,,Lupersat,Lupersat,23,Creuse,75,Nouvelle-Aquitaine
23114,LUSSAT,23170,LUSSAT,,46.1860711865,2.33160367389,114,,Lussat,Lussat,23,Creuse,75,Nouvelle-Aquitaine
23115,MAGNAT L ETRANGE,23260,MAGNAT L ETRANGE,,45.795032018,2.26882619571,115,,Magnat-l'Étrange,Magnat-l'Étrange,23,Creuse,75,Nouvelle-Aquitaine
23116,MAINSAT,23700,MAINSAT,,46.0476583582,2.38634930311,116,,Mainsat,Mainsat,23,Creuse,75,Nouvelle-Aquitaine
23117,MAISON FEYNE,23800,MAISON FEYNE,,46.3472689397,1.67021270506,117,,Maison-Feyne,Maison-Feyne,23,Creuse,75,Nouvelle-Aquitaine
23118,MAISONNISSES,23150,MAISONNISSES,,46.0608263373,1.90309804931,118,,Maisonnisses,Maisonnisses,23,Creuse,75,Nouvelle-Aquitaine
23119,MALLERET,23260,MALLERET,,45.7623976444,2.32269567343,119,,Malleret,Malleret,23,Creuse,75,Nouvelle-Aquitaine
23120,MALLERET BOUSSAC,23600,MALLERET BOUSSAC,,46.3416170909,2.15084552451,120,,Malleret-Boussac,Malleret-Boussac,23,Creuse,75,Nouvelle-Aquitaine
23121,MALVAL,23220,MALVAL,,46.3569550844,1.8980565354,121,,Malval,Malval,23,Creuse,75,Nouvelle-Aquitaine
23122,MANSAT LA COURRIERE,23400,MANSAT LA COURRIERE,,45.9652490444,1.79570521606,122,,Mansat-la-Courrière,Mansat-la-Courrière,23,Creuse,75,Nouvelle-Aquitaine
23123,LES MARS,23700,LES MARS,,45.9954123132,2.49152199993,123,Les,Mars,Les Mars,23,Creuse,75,Nouvelle-Aquitaine
23124,MARSAC,23210,MARSAC,,46.100183678,1.58421912003,124,,Marsac,Marsac,23,Creuse,75,Nouvelle-Aquitaine
23125,LE MAS D ARTIGE,23100,LE MAS D ARTIGE,,45.7343897624,2.1863210182,125,Le,Mas-d'Artige,Le Mas-d'Artige,23,Creuse,75,Nouvelle-Aquitaine
23126,MASBARAUD MERIGNAT,23400,MASBARAUD MERIGNAT,,45.9760519529,1.72040604395,126,,Masbaraud-Mérignat,Masbaraud-Mérignat,23,Creuse,75,Nouvelle-Aquitaine
23127,MAUTES,23190,MAUTES,,45.936197398,2.38523342152,127,,Mautes,Mautes,23,Creuse,75,Nouvelle-Aquitaine
23128,MAZEIRAT,23150,MAZEIRAT,,46.1381078382,1.98735515173,128,,Mazeirat,Mazeirat,23,Creuse,75,Nouvelle-Aquitaine
23129,LA MAZIERE AUX BONS HOMMES,23260,MAZIERE AUX BONS HOMMES,,45.8897027199,2.44364829354,129,La,Mazière-aux-Bons-Hommes,La Mazière-aux-Bons-Hommes,23,Creuse,75,Nouvelle-Aquitaine
23130,MEASNES,23360,MEASNES,,46.4230950685,1.77996331744,130,,Méasnes,Méasnes,23,Creuse,75,Nouvelle-Aquitaine
23131,MERINCHAL,23420,MERINCHAL,,45.9084057409,2.49421673584,131,,Mérinchal,Mérinchal,23,Creuse,75,Nouvelle-Aquitaine
23132,MONTAIGUT LE BLANC,23320,MONTAIGUT LE BLANC,,46.1184508913,1.73277255271,132,,Montaigut-le-Blanc,Montaigut-le-Blanc,23,Creuse,75,Nouvelle-Aquitaine
23133,MONTBOUCHER,23400,MONTBOUCHER,,45.9478595748,1.68034287927,133,,Montboucher,Montboucher,23,Creuse,75,Nouvelle-Aquitaine
23134,LE MONTEIL AU VICOMTE,23460,LE MONTEIL AU VICOMTE,,45.9095469012,1.94488903825,134,Le,Monteil-au-Vicomte,Le Monteil-au-Vicomte,23,Creuse,75,Nouvelle-Aquitaine
23136,MORTROUX,23220,MORTROUX,,46.3956198653,1.90748729651,136,,Mortroux,Mortroux,23,Creuse,75,Nouvelle-Aquitaine
23137,MOURIOUX VIEILLEVILLE,23210,MOURIOUX VIEILLEVILLE,,46.079416865,1.63796262107,137,,Mourioux-Vieilleville,Mourioux-Vieilleville,23,Creuse,75,Nouvelle-Aquitaine
23138,MOUTIER D AHUN,23150,MOUTIER D AHUN,,46.0997586109,2.0630190962,138,,Moutier-d'Ahun,Moutier-d'Ahun,23,Creuse,75,Nouvelle-Aquitaine
23139,MOUTIER MALCARD,23220,MOUTIER MALCARD,,46.3835305321,1.95043016159,139,,Moutier-Malcard,Moutier-Malcard,23,Creuse,75,Nouvelle-Aquitaine
23140,MOUTIER ROZEILLE,23200,MOUTIER ROZEILLE,,45.9183117798,2.19838575108,140,,Moutier-Rozeille,Moutier-Rozeille,23,Creuse,75,Nouvelle-Aquitaine
23141,NAILLAT,23800,NAILLAT,,46.258586675,1.65007660909,141,,Naillat,Naillat,23,Creuse,75,Nouvelle-Aquitaine
23142,NEOUX,23200,NEOUX,,45.9157589917,2.26372873002,142,,Néoux,Néoux,23,Creuse,75,Nouvelle-Aquitaine
23143,NOTH,23300,NOTH,,46.2422286438,1.58264517372,143,,Noth,Noth,23,Creuse,75,Nouvelle-Aquitaine
23144,LA NOUAILLE,23500,LA NOUAILLE,,45.8393812197,2.06990945927,144,La,Nouaille,La Nouaille,23,Creuse,75,Nouvelle-Aquitaine
23145,NOUHANT,23170,NOUHANT,,46.287074332,2.39209394225,145,,Nouhant,Nouhant,23,Creuse,75,Nouvelle-Aquitaine
23146,NOUZERINES,23600,NOUZERINES,,46.3891832834,2.11086671444,146,,Nouzerines,Nouzerines,23,Creuse,75,Nouvelle-Aquitaine
23147,NOUZEROLLES,23360,NOUZEROLLES,,46.383379168,1.74040246165,147,,Nouzerolles,Nouzerolles,23,Creuse,75,Nouvelle-Aquitaine
23148,NOUZIERS,23350,NOUZIERS,,46.4236220738,1.96158183611,148,,Nouziers,Nouziers,23,Creuse,75,Nouvelle-Aquitaine
23149,PARSAC RIMONDEIX,23140,PARSAC RIMONDEIX,,46.1973815424,2.1397496521,149,,Parsac-Rimondeix,Parsac-Rimondeix,23,Creuse,75,Nouvelle-Aquitaine
23149,PARSAC RIMONDEIX,23140,PARSAC RIMONDEIX,RIMONDEIX,46.1973815424,2.1397496521,149,,Parsac-Rimondeix,Parsac-Rimondeix,23,Creuse,75,Nouvelle-Aquitaine
23150,PEYRABOUT,23000,PEYRABOUT,,46.1007482768,1.91647736586,150,,Peyrabout,Peyrabout,23,Creuse,75,Nouvelle-Aquitaine
23151,PEYRAT LA NONIERE,23130,PEYRAT LA NONIERE,,46.0802725066,2.25227584192,151,,Peyrat-la-Nonière,Peyrat-la-Nonière,23,Creuse,75,Nouvelle-Aquitaine
23152,PIERREFITTE,23130,PIERREFITTE,,46.1520867476,2.23278629425,152,,Pierrefitte,Pierrefitte,23,Creuse,75,Nouvelle-Aquitaine
23154,PIONNAT,23140,PIONNAT,,46.1700387523,2.02653941278,154,,Pionnat,Pionnat,23,Creuse,75,Nouvelle-Aquitaine
23155,PONTARION,23250,PONTARION,,45.9957082481,1.84611482202,155,,Pontarion,Pontarion,23,Creuse,75,Nouvelle-Aquitaine
23156,PONTCHARRAUD,23260,PONTCHARRAUD,,45.861496557,2.27462161303,156,,Pontcharraud,Pontcharraud,23,Creuse,75,Nouvelle-Aquitaine
23157,LA POUGE,23250,LA POUGE,,45.9737443345,1.9401973026,157,La,Pouge,La Pouge,23,Creuse,75,Nouvelle-Aquitaine
23158,POUSSANGES,23500,POUSSANGES,,45.8165124742,2.22140949104,158,,Poussanges,Poussanges,23,Creuse,75,Nouvelle-Aquitaine
23159,PUY MALSIGNAT,23130,PUY MALSIGNAT,,46.0356584906,2.21430412833,159,,Puy-Malsignat,Puy-Malsignat,23,Creuse,75,Nouvelle-Aquitaine
23160,RETERRE,23110,RETERRE,,46.1065631303,2.46758526788,160,,Reterre,Reterre,23,Creuse,75,Nouvelle-Aquitaine
23162,ROCHES,23270,ROCHES,,46.2810003098,1.99050170917,162,,Roches,Roches,23,Creuse,75,Nouvelle-Aquitaine
23164,ROUGNAT,23700,ROUGNAT,,46.0578019066,2.49617686559,164,,Rougnat,Rougnat,23,Creuse,75,Nouvelle-Aquitaine
23165,ROYERE DE VASSIVIERE,23460,ROYERE DE VASSIVIERE,,45.8375355848,1.90167387635,165,,Royère-de-Vassivière,Royère-de-Vassivière,23,Creuse,75,Nouvelle-Aquitaine
23166,SAGNAT,23800,SAGNAT,,46.313083165,1.62441629818,166,,Sagnat,Sagnat,23,Creuse,75,Nouvelle-Aquitaine
23167,SANNAT,23110,SANNAT,,46.1229856338,2.41539962939,167,,Sannat,Sannat,23,Creuse,75,Nouvelle-Aquitaine
23168,SARDENT,23250,SARDENT,,46.0459895877,1.85886765501,168,,Sardent,Sardent,23,Creuse,75,Nouvelle-Aquitaine
23169,LA SAUNIERE,23000,LA SAUNIERE,,46.1285230367,1.94133792755,169,La,Saunière,La Saunière,23,Creuse,75,Nouvelle-Aquitaine
23170,SAVENNES,23000,SAVENNES,,46.1090525957,1.88611350462,170,,Savennes,Savennes,23,Creuse,75,Nouvelle-Aquitaine
23171,SERMUR,23700,SERMUR,,45.9695912053,2.41544170829,171,,Sermur,Sermur,23,Creuse,75,Nouvelle-Aquitaine
23172,LA SERRE BUSSIERE VIEILLE,23190,LA SERRE BUSSIERE VIEILLE,,46.0560109698,2.33476198361,172,La,Serre-Bussière-Vieille,La Serre-Bussière-Vieille,23,Creuse,75,Nouvelle-Aquitaine
23173,SOUBREBOST,23250,SOUBREBOST,,45.9625506165,1.83880495702,173,,Soubrebost,Soubrebost,23,Creuse,75,Nouvelle-Aquitaine
23174,SOUMANS,23600,SOUMANS,,46.3041417254,2.33116198481,174,,Soumans,Soumans,23,Creuse,75,Nouvelle-Aquitaine
23175,SOUS PARSAT,23150,SOUS PARSAT,,46.0580187213,1.97890165632,175,,Sous-Parsat,Sous-Parsat,23,Creuse,75,Nouvelle-Aquitaine
23176,LA SOUTERRAINE,23300,LA SOUTERRAINE,,46.2366579943,1.47591088913,176,La,Souterraine,La Souterraine,23,Creuse,75,Nouvelle-Aquitaine
23177,ST AGNANT DE VERSILLAT,23300,ST AGNANT DE VERSILLAT,,46.2809892727,1.50573009278,177,,Saint-Agnant-de-Versillat,Saint-Agnant-de-Versillat,23,Creuse,75,Nouvelle-Aquitaine
23178,ST AGNANT PRES CROCQ,23260,ST AGNANT PRES CROCQ,,45.8065352344,2.33755197927,178,,Saint-Agnant-près-Crocq,Saint-Agnant-près-Crocq,23,Creuse,75,Nouvelle-Aquitaine
23179,ST ALPINIEN,23200,ST ALPINIEN,,45.962112522,2.24239447602,179,,Saint-Alpinien,Saint-Alpinien,23,Creuse,75,Nouvelle-Aquitaine
23180,ST AMAND,23200,ST AMAND,,45.9768729666,2.20679373252,180,,Saint-Amand,Saint-Amand,23,Creuse,75,Nouvelle-Aquitaine
23181,ST AMAND JARTOUDEIX,23400,ST AMAND JARTOUDEIX,,45.9176401302,1.66886742127,181,,Saint-Amand-Jartoudeix,Saint-Amand-Jartoudeix,23,Creuse,75,Nouvelle-Aquitaine
23182,ST AVIT DE TARDES,23200,ST AVIT DE TARDES,,45.9202464802,2.29914606711,182,,Saint-Avit-de-Tardes,Saint-Avit-de-Tardes,23,Creuse,75,Nouvelle-Aquitaine
23183,ST AVIT LE PAUVRE,23480,ST AVIT LE PAUVRE,,45.993398023,2.04843748291,183,,Saint-Avit-le-Pauvre,Saint-Avit-le-Pauvre,23,Creuse,75,Nouvelle-Aquitaine
23184,ST BARD,23260,ST BARD,,45.9133006129,2.40762090733,184,,Saint-Bard,Saint-Bard,23,Creuse,75,Nouvelle-Aquitaine
23185,ST CHABRAIS,23130,ST CHABRAIS,,46.1247745498,2.20712501606,185,,Saint-Chabrais,Saint-Chabrais,23,Creuse,75,Nouvelle-Aquitaine
23186,ST CHRISTOPHE,23000,ST CHRISTOPHE,,46.0966479541,1.86066366309,186,,Saint-Christophe,Saint-Christophe,23,Creuse,75,Nouvelle-Aquitaine
23187,ST DIZIER LA TOUR,23130,ST DIZIER LA TOUR,,46.1424414999,2.15051617488,187,,Saint-Dizier-la-Tour,Saint-Dizier-la-Tour,23,Creuse,75,Nouvelle-Aquitaine
23188,ST DIZIER LES DOMAINES,23270,ST DIZIER LES DOMAINES,,46.3228482905,2.04735395376,188,,Saint-Dizier-les-Domaines,Saint-Dizier-les-Domaines,23,Creuse,75,Nouvelle-Aquitaine
23189,ST DIZIER LEYRENNE,23400,ST DIZIER LEYRENNE,,46.0272675127,1.70488354974,189,,Saint-Dizier-Leyrenne,Saint-Dizier-Leyrenne,23,Creuse,75,Nouvelle-Aquitaine
23190,ST DOMET,23190,ST DOMET,,46.050744775,2.29544986459,190,,Saint-Domet,Saint-Domet,23,Creuse,75,Nouvelle-Aquitaine
23191,ST ELOI,23000,ST ELOI,,46.0784457753,1.81701009377,191,,Saint-Éloi,Saint-Éloi,23,Creuse,75,Nouvelle-Aquitaine
23192,ST ETIENNE DE FURSAC,23290,ST ETIENNE DE FURSAC,,46.1269796893,1.53252260223,192,,Fursac,Fursac,23,Creuse,75,Nouvelle-Aquitaine
23193,STE FEYRE,23000,STE FEYRE,,46.1570987846,1.91328237303,193,,Sainte-Feyre,Sainte-Feyre,23,Creuse,75,Nouvelle-Aquitaine
23194,STE FEYRE LA MONTAGNE,23500,STE FEYRE LA MONTAGNE,,45.8948418395,2.23233670208,194,,Sainte-Feyre-la-Montagne,Sainte-Feyre-la-Montagne,23,Creuse,75,Nouvelle-Aquitaine
23195,ST FIEL,23000,ST FIEL,,46.2132149531,1.89527202748,195,,Saint-Fiel,Saint-Fiel,23,Creuse,75,Nouvelle-Aquitaine
23196,ST FRION,23500,ST FRION,,45.8644020911,2.22307984483,196,,Saint-Frion,Saint-Frion,23,Creuse,75,Nouvelle-Aquitaine
23197,ST GEORGES LA POUGE,23250,ST GEORGES LA POUGE,,45.9974282432,1.96275929639,197,,Saint-Georges-la-Pouge,Saint-Georges-la-Pouge,23,Creuse,75,Nouvelle-Aquitaine
23198,ST GEORGES NIGREMONT,23500,ST GEORGES NIGREMONT,,45.8360016114,2.27335627417,198,,Saint-Georges-Nigremont,Saint-Georges-Nigremont,23,Creuse,75,Nouvelle-Aquitaine
23199,ST GERMAIN BEAUPRE,23160,ST GERMAIN BEAUPRE,,46.3160359158,1.55742406465,199,,Saint-Germain-Beaupré,Saint-Germain-Beaupré,23,Creuse,75,Nouvelle-Aquitaine
23199,ST GERMAIN BEAUPRE,23160,ST GERMAIN BEAUPRE,FORGEVIEILLE,46.3160359158,1.55742406465,199,,Saint-Germain-Beaupré,Saint-Germain-Beaupré,23,Creuse,75,Nouvelle-Aquitaine
23200,ST GOUSSAUD,23430,ST GOUSSAUD,,46.0310651725,1.56127982806,200,,Saint-Goussaud,Saint-Goussaud,23,Creuse,75,Nouvelle-Aquitaine
23201,ST HILAIRE LA PLAINE,23150,ST HILAIRE LA PLAINE,,46.1156085065,1.98483910793,201,,Saint-Hilaire-la-Plaine,Saint-Hilaire-la-Plaine,23,Creuse,75,Nouvelle-Aquitaine
23202,ST HILAIRE LE CHATEAU,23250,ST HILAIRE LE CHATEAU,,45.993749867,1.89775218863,202,,Saint-Hilaire-le-Château,Saint-Hilaire-le-Château,23,Creuse,75,Nouvelle-Aquitaine
23203,ST JULIEN LA GENETE,23110,ST JULIEN LA GENETE,,46.1420634611,2.47460350528,203,,Saint-Julien-la-Genête,Saint-Julien-la-Genête,23,Creuse,75,Nouvelle-Aquitaine
23204,ST JULIEN LE CHATEL,23130,ST JULIEN LE CHATEL,,46.118231154,2.26998040696,204,,Saint-Julien-le-Châtel,Saint-Julien-le-Châtel,23,Creuse,75,Nouvelle-Aquitaine
23205,ST JUNIEN LA BREGERE,23400,ST JUNIEN LA BREGERE,,45.8846270047,1.74417138808,205,,Saint-Junien-la-Bregère,Saint-Junien-la-Bregère,23,Creuse,75,Nouvelle-Aquitaine
23206,ST LAURENT,23000,ST LAURENT,,46.1609419116,1.96011304253,206,,Saint-Laurent,Saint-Laurent,23,Creuse,75,Nouvelle-Aquitaine
23207,ST LEGER BRIDEREIX,23300,ST LEGER BRIDEREIX,,46.2806353119,1.57830675905,207,,Saint-Léger-Bridereix,Saint-Léger-Bridereix,23,Creuse,75,Nouvelle-Aquitaine
23208,ST LEGER LE GUERETOIS,23000,ST LEGER LE GUERETOIS,,46.1507777907,1.8151398543,208,,Saint-Léger-le-Guérétois,Saint-Léger-le-Guérétois,23,Creuse,75,Nouvelle-Aquitaine
23209,ST LOUP,23130,ST LOUP,,46.1463514525,2.28115998053,209,,Saint-Loup,Saint-Loup,23,Creuse,75,Nouvelle-Aquitaine
23210,ST MAIXANT,23200,ST MAIXANT,,45.9987180402,2.19897902296,210,,Saint-Maixant,Saint-Maixant,23,Creuse,75,Nouvelle-Aquitaine
23211,ST MARC A FRONGIER,23200,ST MARC A FRONGIER,,45.9193165134,2.11056144909,211,,Saint-Marc-à-Frongier,Saint-Marc-à-Frongier,23,Creuse,75,Nouvelle-Aquitaine
23212,ST MARC A LOUBAUD,23460,ST MARC A LOUBAUD,,45.8434161746,1.99092518594,212,,Saint-Marc-à-Loubaud,Saint-Marc-à-Loubaud,23,Creuse,75,Nouvelle-Aquitaine
23213,ST MARIEN,23600,ST MARIEN,,46.4127359723,2.21573799955,213,,Saint-Marien,Saint-Marien,23,Creuse,75,Nouvelle-Aquitaine
23214,ST MARTIAL LE MONT,23150,ST MARTIAL LE MONT,,46.0501750563,2.09062935629,214,,Saint-Martial-le-Mont,Saint-Martial-le-Mont,23,Creuse,75,Nouvelle-Aquitaine
23215,ST MARTIAL LE VIEUX,23100,ST MARTIAL LE VIEUX,,45.6896336571,2.30842367741,215,,Saint-Martial-le-Vieux,Saint-Martial-le-Vieux,23,Creuse,75,Nouvelle-Aquitaine
23216,ST MARTIN CHATEAU,23460,ST MARTIN CHATEAU,,45.8498336813,1.81176141675,216,,Saint-Martin-Château,Saint-Martin-Château,23,Creuse,75,Nouvelle-Aquitaine
23217,ST MARTIN STE CATHERINE,23430,ST MARTIN STE CATHERINE,,45.9426860436,1.56102508429,217,,Saint-Martin-Sainte-Catherine,Saint-Martin-Sainte-Catherine,23,Creuse,75,Nouvelle-Aquitaine
23217,ST MARTIN STE CATHERINE,23430,ST MARTIN STE CATHERINE,LE THEIL,45.9426860436,1.56102508429,217,,Saint-Martin-Sainte-Catherine,Saint-Martin-Sainte-Catherine,23,Creuse,75,Nouvelle-Aquitaine
23218,ST MAURICE PRES CROCQ,23260,ST MAURICE PRES CROCQ,,45.8576074833,2.31542471203,218,,Saint-Maurice-près-Crocq,Saint-Maurice-près-Crocq,23,Creuse,75,Nouvelle-Aquitaine
23219,ST MAURICE LA SOUTERRAINE,23300,ST MAURICE LA SOUTERRAINE,,46.2106603665,1.42584956204,219,,Saint-Maurice-la-Souterraine,Saint-Maurice-la-Souterraine,23,Creuse,75,Nouvelle-Aquitaine
23219,ST MAURICE LA SOUTERRAINE,23300,ST MAURICE LA SOUTERRAINE,LE DOGNON,46.2106603665,1.42584956204,219,,Saint-Maurice-la-Souterraine,Saint-Maurice-la-Souterraine,23,Creuse,75,Nouvelle-Aquitaine
23220,ST MEDARD LA ROCHETTE,23200,ST MEDARD LA ROCHETTE,,46.0220936926,2.14641995346,220,,Saint-Médard-la-Rochette,Saint-Médard-la-Rochette,23,Creuse,75,Nouvelle-Aquitaine
23220,ST MEDARD LA ROCHETTE,23200,ST MEDARD LA ROCHETTE,FOURNEAUX,46.0220936926,2.14641995346,220,,Saint-Médard-la-Rochette,Saint-Médard-la-Rochette,23,Creuse,75,Nouvelle-Aquitaine
23220,ST MEDARD LA ROCHETTE,23200,ST MEDARD LA ROCHETTE,LA ROCHETTE,46.0220936926,2.14641995346,220,,Saint-Médard-la-Rochette,Saint-Médard-la-Rochette,23,Creuse,75,Nouvelle-Aquitaine
23221,ST MERD LA BREUILLE,23100,ST MERD LA BREUILLE,,45.7335016527,2.4306254941,221,,Saint-Merd-la-Breuille,Saint-Merd-la-Breuille,23,Creuse,75,Nouvelle-Aquitaine
23222,ST MICHEL DE VEISSE,23480,ST MICHEL DE VEISSE,,45.9536199326,2.05002532305,222,,Saint-Michel-de-Veisse,Saint-Michel-de-Veisse,23,Creuse,75,Nouvelle-Aquitaine
23223,ST MOREIL,23400,ST MOREIL,,45.8603059195,1.69391361996,223,,Saint-Moreil,Saint-Moreil,23,Creuse,75,Nouvelle-Aquitaine
23224,ST ORADOUX DE CHIROUZE,23100,ST ORADOUX DE CHIROUZE,,45.7286217698,2.32501480873,224,,Saint-Oradoux-de-Chirouze,Saint-Oradoux-de-Chirouze,23,Creuse,75,Nouvelle-Aquitaine
23225,ST ORADOUX PRES CROCQ,23260,ST ORADOUX PRES CROCQ,,45.8887496199,2.37555654665,225,,Saint-Oradoux-près-Crocq,Saint-Oradoux-près-Crocq,23,Creuse,75,Nouvelle-Aquitaine
23226,ST PARDOUX D ARNET,23260,ST PARDOUX D ARNET,,45.881920162,2.32602565268,226,,Saint-Pardoux-d'Arnet,Saint-Pardoux-d'Arnet,23,Creuse,75,Nouvelle-Aquitaine
23227,ST PARDOUX MORTEROLLES,23400,ST PARDOUX MORTEROLLES,,45.9054465384,1.82901180429,227,,Saint-Pardoux-Morterolles,Saint-Pardoux-Morterolles,23,Creuse,75,Nouvelle-Aquitaine
23228,ST PARDOUX LE NEUF,23200,ST PARDOUX LE NEUF,,45.9364998667,2.22787618622,228,,Saint-Pardoux-le-Neuf,Saint-Pardoux-le-Neuf,23,Creuse,75,Nouvelle-Aquitaine
23229,ST PARDOUX LES CARDS,23150,ST PARDOUX LES CARDS,,46.0920870349,2.12456060893,229,,Saint-Pardoux-les-Cards,Saint-Pardoux-les-Cards,23,Creuse,75,Nouvelle-Aquitaine
23230,ST PIERRE CHERIGNAT,23430,ST PIERRE CHERIGNAT,,45.9598091677,1.61399219926,230,,Saint-Pierre-Chérignat,Saint-Pierre-Chérignat,23,Creuse,75,Nouvelle-Aquitaine
23231,ST PIERRE DE FURSAC,23290,ST PIERRE DE FURSAC,,46.1678836205,1.49194021267,231,,Saint-Pierre-de-Fursac,Saint-Pierre-de-Fursac,23,Creuse,75,Nouvelle-Aquitaine
23232,ST PIERRE BELLEVUE,23460,ST PIERRE BELLEVUE,,45.9074959957,1.89628129619,232,,Saint-Pierre-Bellevue,Saint-Pierre-Bellevue,23,Creuse,75,Nouvelle-Aquitaine
23232,ST PIERRE BELLEVUE,23460,ST PIERRE BELLEVUE,LE COMPEIX,45.9074959957,1.89628129619,232,,Saint-Pierre-Bellevue,Saint-Pierre-Bellevue,23,Creuse,75,Nouvelle-Aquitaine
23233,ST PIERRE LE BOST,23600,ST PIERRE LE BOST,,46.3965019066,2.26230447209,233,,Saint-Pierre-le-Bost,Saint-Pierre-le-Bost,23,Creuse,75,Nouvelle-Aquitaine
23234,ST PRIEST,23110,ST PRIEST,,46.0938953777,2.35623802038,234,,Saint-Priest,Saint-Priest,23,Creuse,75,Nouvelle-Aquitaine
23235,ST PRIEST LA FEUILLE,23300,ST PRIEST LA FEUILLE,,46.1984256064,1.53565448112,235,,Saint-Priest-la-Feuille,Saint-Priest-la-Feuille,23,Creuse,75,Nouvelle-Aquitaine
23236,ST PRIEST LA PLAINE,23240,ST PRIEST LA PLAINE,,46.2038557137,1.6315718964,236,,Saint-Priest-la-Plaine,Saint-Priest-la-Plaine,23,Creuse,75,Nouvelle-Aquitaine
23237,ST PRIEST PALUS,23400,ST PRIEST PALUS,,45.8948915885,1.67641334578,237,,Saint-Priest-Palus,Saint-Priest-Palus,23,Creuse,75,Nouvelle-Aquitaine
23238,ST QUENTIN LA CHABANNE,23500,ST QUENTIN LA CHABANNE,,45.8786461454,2.14631978008,238,,Saint-Quentin-la-Chabanne,Saint-Quentin-la-Chabanne,23,Creuse,75,Nouvelle-Aquitaine
23239,ST SEBASTIEN,23160,ST SEBASTIEN,,46.3933331691,1.53031685925,239,,Saint-Sébastien,Saint-Sébastien,23,Creuse,75,Nouvelle-Aquitaine
23240,ST SILVAIN BAS LE ROC,23600,ST SILVAIN BAS LE ROC,,46.3319366836,2.21828718051,240,,Saint-Silvain-Bas-le-Roc,Saint-Silvain-Bas-le-Roc,23,Creuse,75,Nouvelle-Aquitaine
23241,ST SILVAIN BELLEGARDE,23190,ST SILVAIN BELLEGARDE,,45.9661631879,2.30737772119,241,,Saint-Silvain-Bellegarde,Saint-Silvain-Bellegarde,23,Creuse,75,Nouvelle-Aquitaine
23242,ST SILVAIN MONTAIGUT,23320,ST SILVAIN MONTAIGUT,,46.1412485496,1.76065637075,242,,Saint-Silvain-Montaigut,Saint-Silvain-Montaigut,23,Creuse,75,Nouvelle-Aquitaine
23243,ST SILVAIN SOUS TOULX,23140,ST SILVAIN SOUS TOULX,,46.2595513591,2.18852889263,243,,Saint-Silvain-sous-Toulx,Saint-Silvain-sous-Toulx,23,Creuse,75,Nouvelle-Aquitaine
23244,ST SULPICE LE DUNOIS,23800,ST SULPICE LE DUNOIS,,46.2979968823,1.72607860282,244,,Saint-Sulpice-le-Dunois,Saint-Sulpice-le-Dunois,23,Creuse,75,Nouvelle-Aquitaine
23245,ST SULPICE LE GUERETOIS,23000,ST SULPICE LE GUERETOIS,,46.2020232419,1.82822095758,245,,Saint-Sulpice-le-Guérétois,Saint-Sulpice-le-Guérétois,23,Creuse,75,Nouvelle-Aquitaine
23246,ST SULPICE LES CHAMPS,23480,ST SULPICE LES CHAMPS,,45.9883129474,2.02002682411,246,,Saint-Sulpice-les-Champs,Saint-Sulpice-les-Champs,23,Creuse,75,Nouvelle-Aquitaine
23247,ST VAURY,23320,ST VAURY,,46.1999085805,1.74904977519,247,,Saint-Vaury,Saint-Vaury,23,Creuse,75,Nouvelle-Aquitaine
23248,ST VICTOR EN MARCHE,23000,ST VICTOR EN MARCHE,,46.1180726796,1.79785648439,248,,Saint-Victor-en-Marche,Saint-Victor-en-Marche,23,Creuse,75,Nouvelle-Aquitaine
23249,ST YRIEIX LA MONTAGNE,23460,ST YRIEIX LA MONTAGNE,,45.875697564,2.00324510072,249,,Saint-Yrieix-la-Montagne,Saint-Yrieix-la-Montagne,23,Creuse,75,Nouvelle-Aquitaine
23250,ST YRIEIX LES BOIS,23150,ST YRIEIX LES BOIS,,46.0936669043,1.95720229248,250,,Saint-Yrieix-les-Bois,Saint-Yrieix-les-Bois,23,Creuse,75,Nouvelle-Aquitaine
23251,TARDES,23170,TARDES,,46.1414668676,2.35456258635,251,,Tardes,Tardes,23,Creuse,75,Nouvelle-Aquitaine
23252,TERCILLAT,23350,TERCILLAT,,46.403527358,2.05486198243,252,,Tercillat,Tercillat,23,Creuse,75,Nouvelle-Aquitaine
23253,THAURON,23250,THAURON,,46.0016473985,1.80289051069,253,,Thauron,Thauron,23,Creuse,75,Nouvelle-Aquitaine
23254,TOULX STE CROIX,23600,TOULX STE CROIX,,46.291110215,2.21724671243,254,,Toulx-Sainte-Croix,Toulx-Sainte-Croix,23,Creuse,75,Nouvelle-Aquitaine
23255,TROIS FONDS,23230,TROIS FONDS,,46.2431666421,2.23617811093,255,,Trois-Fonds,Trois-Fonds,23,Creuse,75,Nouvelle-Aquitaine
23257,VALLIERE,23120,VALLIERE,,45.9068796825,2.03866677382,257,,Vallière,Vallière,23,Creuse,75,Nouvelle-Aquitaine
23258,VAREILLES,23300,VAREILLES,,46.3050162519,1.45603078301,258,,Vareilles,Vareilles,23,Creuse,75,Nouvelle-Aquitaine
23259,VERNEIGES,23170,VERNEIGES,,46.2641701184,2.34076152374,259,,Verneiges,Verneiges,23,Creuse,75,Nouvelle-Aquitaine
23260,VIDAILLAT,23250,VIDAILLAT,,45.9552228626,1.89790597519,260,,Vidaillat,Vidaillat,23,Creuse,75,Nouvelle-Aquitaine
23261,VIERSAT,23170,VIERSAT,,46.2630214571,2.44518015435,261,,Viersat,Viersat,23,Creuse,75,Nouvelle-Aquitaine
23262,VIGEVILLE,23140,VIGEVILLE,,46.1599934316,2.07290602418,262,,Vigeville,Vigeville,23,Creuse,75,Nouvelle-Aquitaine
23263,VILLARD,23800,VILLARD,,46.3295092994,1.69939460292,263,,Villard,Villard,23,Creuse,75,Nouvelle-Aquitaine
23264,LA VILLEDIEU,23340,LA VILLEDIEU,,45.7327546531,1.89245043112,264,La,Villedieu,La Villedieu,23,Creuse,75,Nouvelle-Aquitaine
23265,LA VILLENEUVE,23260,LA VILLENEUVE,,45.8965825577,2.41574071294,265,La,Villeneuve,La Villeneuve,23,Creuse,75,Nouvelle-Aquitaine
23266,LA VILLETELLE,23260,LA VILLETELLE,,45.9243228727,2.33832040232,266,La,Villetelle,La Villetelle,23,Creuse,75,Nouvelle-Aquitaine
24001,ABJAT SUR BANDIAT,24300,ABJAT SUR BANDIAT,,45.5817657415,0.759998823063,1,,Abjat-sur-Bandiat,Abjat-sur-Bandiat,24,Dordogne,75,Nouvelle-Aquitaine
24002,AGONAC,24460,AGONAC,,45.281599202,0.745318376451,2,,Agonac,Agonac,24,Dordogne,75,Nouvelle-Aquitaine
24004,AJAT,24210,AJAT,,45.1611118041,1.02760334282,4,,Ajat,Ajat,24,Dordogne,75,Nouvelle-Aquitaine
24005,ALLES SUR DORDOGNE,24480,ALLES SUR DORDOGNE,,44.861392005,0.874642007489,5,,Alles-sur-Dordogne,Alles-sur-Dordogne,24,Dordogne,75,Nouvelle-Aquitaine
24006,ALLAS LES MINES,24220,ALLAS LES MINES,,44.828046683,1.07371384285,6,,Allas-les-Mines,Allas-les-Mines,24,Dordogne,75,Nouvelle-Aquitaine
24007,ALLEMANS,24600,ALLEMANS,,45.288412571,0.305272710344,7,,Allemans,Allemans,24,Dordogne,75,Nouvelle-Aquitaine
24008,ANGOISSE,24270,ANGOISSE,,45.4299135686,1.15056975659,8,,Angoisse,Angoisse,24,Dordogne,75,Nouvelle-Aquitaine
24009,ANLHIAC,24160,ANLHIAC,,45.3214606994,1.12082705606,9,,Anlhiac,Anlhiac,24,Dordogne,75,Nouvelle-Aquitaine
24010,ANNESSE ET BEAULIEU,24430,ANNESSE ET BEAULIEU,,45.1826946936,0.595337077035,10,,Annesse-et-Beaulieu,Annesse-et-Beaulieu,24,Dordogne,75,Nouvelle-Aquitaine
24011,ANTONNE ET TRIGONANT,24420,ANTONNE ET TRIGONANT,,45.2300537681,0.831654901001,11,,Antonne-et-Trigonant,Antonne-et-Trigonant,24,Dordogne,75,Nouvelle-Aquitaine
24012,ARCHIGNAC,24590,ARCHIGNAC,,45.0157581227,1.29160105992,12,,Archignac,Archignac,24,Dordogne,75,Nouvelle-Aquitaine
24014,AUBAS,24290,AUBAS,,45.0793796872,1.19807010544,14,,Aubas,Aubas,24,Dordogne,75,Nouvelle-Aquitaine
24015,AUDRIX,24260,AUDRIX,,44.8830682379,0.948494393836,15,,Audrix,Audrix,24,Dordogne,75,Nouvelle-Aquitaine
24016,AUGIGNAC,24300,AUGIGNAC,,45.5838223647,0.702320065534,16,,Augignac,Augignac,24,Dordogne,75,Nouvelle-Aquitaine
24018,AURIAC DU PERIGORD,24290,AURIAC DU PERIGORD,,45.1125569337,1.12728719792,18,,Auriac-du-Périgord,Auriac-du-Périgord,24,Dordogne,75,Nouvelle-Aquitaine
24019,AZERAT,24210,AZERAT,,45.1597230552,1.10636160477,19,,Azerat,Azerat,24,Dordogne,75,Nouvelle-Aquitaine
24020,LA BACHELLERIE,24210,LA BACHELLERIE,,45.1339229192,1.16376326914,20,La,Bachellerie,La Bachellerie,24,Dordogne,75,Nouvelle-Aquitaine
24021,BADEFOLS D ANS,24390,BADEFOLS D ANS,,45.2288608199,1.20372454119,21,,Badefols-d'Ans,Badefols-d'Ans,24,Dordogne,75,Nouvelle-Aquitaine
24022,BADEFOLS SUR DORDOGNE,24150,BADEFOLS SUR DORDOGNE,,44.8391204649,0.800580246575,22,,Badefols-sur-Dordogne,Badefols-sur-Dordogne,24,Dordogne,75,Nouvelle-Aquitaine
24023,BANEUIL,24150,BANEUIL,,44.8529470727,0.69340604246,23,,Baneuil,Baneuil,24,Dordogne,75,Nouvelle-Aquitaine
24024,BARDOU,24560,BARDOU,,44.7409359131,0.686088955421,24,,Bardou,Bardou,24,Dordogne,75,Nouvelle-Aquitaine
24025,BARS,24210,BARS,,45.0948929916,1.05073972207,25,,Bars,Bars,24,Dordogne,75,Nouvelle-Aquitaine
24026,BASSILLAC,24330,BASSILLAC,,45.1789592433,0.820956969026,26,,Bassillac et Auberoche,Bassillac et Auberoche,24,Dordogne,75,Nouvelle-Aquitaine
24027,BAYAC,24150,BAYAC,,44.8047466213,0.7227976753,27,,Bayac,Bayac,24,Dordogne,75,Nouvelle-Aquitaine
24028,BEAUMONTOIS EN PERIGORD,24440,BEAUMONTOIS EN PERIGORD,BEAUMONT DU PERIGORD,44.773801258,0.75409629331,28,,Beaumontois en Périgord,Beaumontois en Périgord,24,Dordogne,75,Nouvelle-Aquitaine
24028,BEAUMONTOIS EN PERIGORD,24440,BEAUMONTOIS EN PERIGORD,BORN DE CHAMPS,44.773801258,0.75409629331,28,,Beaumontois en Périgord,Beaumontois en Périgord,24,Dordogne,75,Nouvelle-Aquitaine
24028,BEAUMONTOIS EN PERIGORD,24440,BEAUMONTOIS EN PERIGORD,LABOUQUERIE,44.773801258,0.75409629331,28,,Beaumontois en Périgord,Beaumontois en Périgord,24,Dordogne,75,Nouvelle-Aquitaine
24028,BEAUMONTOIS EN PERIGORD,24440,BEAUMONTOIS EN PERIGORD,NOJALS ET CLOTTE,44.773801258,0.75409629331,28,,Beaumontois en Périgord,Beaumontois en Périgord,24,Dordogne,75,Nouvelle-Aquitaine
24028,BEAUMONTOIS EN PERIGORD,24440,BEAUMONTOIS EN PERIGORD,STE SABINE BORN,44.773801258,0.75409629331,28,,Beaumontois en Périgord,Beaumontois en Périgord,24,Dordogne,75,Nouvelle-Aquitaine
24029,BEAUPOUYET,24400,BEAUPOUYET,,44.9961149734,0.285254464127,29,,Beaupouyet,Beaupouyet,24,Dordogne,75,Nouvelle-Aquitaine
24030,BEAUREGARD DE TERRASSON,24120,BEAUREGARD DE TERRASSON,,45.1558279291,1.22862494284,30,,Beauregard-de-Terrasson,Beauregard-de-Terrasson,24,Dordogne,75,Nouvelle-Aquitaine
24031,BEAUREGARD ET BASSAC,24140,BEAUREGARD ET BASSAC,,44.9936188028,0.632602848395,31,,Beauregard-et-Bassac,Beauregard-et-Bassac,24,Dordogne,75,Nouvelle-Aquitaine
24032,BEAURONNE,24400,BEAURONNE,,45.1003503966,0.363141785914,32,,Beauronne,Beauronne,24,Dordogne,75,Nouvelle-Aquitaine
24033,BEAUSSAC,24340,BEAUSSAC,,45.509706302,0.482040858745,33,,Beaussac,Beaussac,24,Dordogne,75,Nouvelle-Aquitaine
24034,BELEYMAS,24140,BELEYMAS,,44.9869120353,0.491344255346,34,,Beleymas,Beleymas,24,Dordogne,75,Nouvelle-Aquitaine
24035,PAYS DE BELVES,24170,PAYS DE BELVES,BELVES,44.7462034463,0.980486487157,35,,Pays de Belvès,Pays de Belvès,24,Dordogne,75,Nouvelle-Aquitaine
24035,PAYS DE BELVES,24170,PAYS DE BELVES,FONGALOP,44.7462034463,0.980486487157,35,,Pays de Belvès,Pays de Belvès,24,Dordogne,75,Nouvelle-Aquitaine
24035,PAYS DE BELVES,24170,PAYS DE BELVES,ST AMAND DE BELVES,44.7462034463,0.980486487157,35,,Pays de Belvès,Pays de Belvès,24,Dordogne,75,Nouvelle-Aquitaine
24036,BERBIGUIERES,24220,BERBIGUIERES,,44.8394794581,1.04517998895,36,,Berbiguières,Berbiguières,24,Dordogne,75,Nouvelle-Aquitaine
24037,BERGERAC,24100,BERGERAC,,44.8543751872,0.486529423457,37,,Bergerac,Bergerac,24,Dordogne,75,Nouvelle-Aquitaine
24038,BERTRIC BUREE,24320,BERTRIC BUREE,,45.309051457,0.354531985356,38,,Bertric-Burée,Bertric-Burée,24,Dordogne,75,Nouvelle-Aquitaine
24039,BESSE,24550,BESSE,,44.6649913736,1.11317333778,39,,Besse,Besse,24,Dordogne,75,Nouvelle-Aquitaine
24040,BEYNAC ET CAZENAC,24220,BEYNAC ET CAZENAC,,44.8572702356,1.13222078955,40,,Beynac-et-Cazenac,Beynac-et-Cazenac,24,Dordogne,75,Nouvelle-Aquitaine
24041,BEZENAC,24220,BEZENAC,,44.8475673485,1.08633675361,41,,Bézenac,Bézenac,24,Dordogne,75,Nouvelle-Aquitaine
24042,BIRAS,24310,BIRAS,,45.2868677633,0.638949936557,42,,Biras,Biras,24,Dordogne,75,Nouvelle-Aquitaine
24043,BIRON,24540,BIRON,,44.6225958112,0.874123602504,43,,Biron,Biron,24,Dordogne,75,Nouvelle-Aquitaine
24044,BLIS ET BORN,24330,BLIS ET BORN,,45.1694612818,0.908122714829,44,,Blis-et-Born,Blis-et-Born,24,Dordogne,75,Nouvelle-Aquitaine
24045,BOISSE,24560,BOISSE,,44.7162436565,0.656505019107,45,,Boisse,Boisse,24,Dordogne,75,Nouvelle-Aquitaine
24046,BOISSEUILH,24390,BOISSEUILH,,45.2814362613,1.17805421579,46,,Boisseuilh,Boisseuilh,24,Dordogne,75,Nouvelle-Aquitaine
24047,LA BOISSIERE D ANS,24640,LA BOISSIERE D ANS,,45.2320869292,0.973794251496,47,La,Boissière-d'Ans,La Boissière-d'Ans,24,Dordogne,75,Nouvelle-Aquitaine
24048,BONNEVILLE ET ST AVIT DE FUMADIERES,24230,BONNEVILLE ST AVIT DE FUMADIERES,,44.8838960782,0.0812094359486,48,,Bonneville-et-Saint-Avit-de-Fumadières,Bonneville-et-Saint-Avit-de-Fumadières,24,Dordogne,75,Nouvelle-Aquitaine
24050,BORREZE,24590,BORREZE,,44.9746545829,1.39100783698,50,,Borrèze,Borrèze,24,Dordogne,75,Nouvelle-Aquitaine
24051,BOSSET,24130,BOSSET,,44.9518343801,0.362233138174,51,,Bosset,Bosset,24,Dordogne,75,Nouvelle-Aquitaine
24052,BOUILLAC,24480,BOUILLAC,,44.7652428032,0.920412186744,52,,Bouillac,Bouillac,24,Dordogne,75,Nouvelle-Aquitaine
24053,BOULAZAC ISLE MANOIRE,24330,BOULAZAC ISLE MANOIRE,ST LAURENT SUR MANOIRE,45.1737726952,0.769368950586,53,,Boulazac Isle Manoire,Boulazac Isle Manoire,24,Dordogne,75,Nouvelle-Aquitaine
24053,BOULAZAC ISLE MANOIRE,24750,BOULAZAC ISLE MANOIRE,,45.1737726952,0.769368950586,53,,Boulazac Isle Manoire,Boulazac Isle Manoire,24,Dordogne,75,Nouvelle-Aquitaine
24053,BOULAZAC ISLE MANOIRE,24750,BOULAZAC ISLE MANOIRE,ATUR,45.1737726952,0.769368950586,53,,Boulazac Isle Manoire,Boulazac Isle Manoire,24,Dordogne,75,Nouvelle-Aquitaine
24054,BOUNIAGUES,24560,BOUNIAGUES,,44.7568266794,0.5279714145,54,,Bouniagues,Bouniagues,24,Dordogne,75,Nouvelle-Aquitaine
24055,BOURDEILLES,24310,BOURDEILLES,,45.3187110964,0.581100424712,55,,Bourdeilles,Bourdeilles,24,Dordogne,75,Nouvelle-Aquitaine
24056,LE BOURDEIX,24300,LE BOURDEIX,,45.5790760433,0.635522742578,56,Le,Bourdeix,Le Bourdeix,24,Dordogne,75,Nouvelle-Aquitaine
24057,BOURG DES MAISONS,24320,BOURG DES MAISONS,,45.3372753112,0.434494589359,57,,Bourg-des-Maisons,Bourg-des-Maisons,24,Dordogne,75,Nouvelle-Aquitaine
24058,BOURG DU BOST,24600,BOURG DU BOST,,45.2670647024,0.263235867564,58,,Bourg-du-Bost,Bourg-du-Bost,24,Dordogne,75,Nouvelle-Aquitaine
24059,BOURGNAC,24400,BOURGNAC,,45.0173532557,0.397961446009,59,,Bourgnac,Bourgnac,24,Dordogne,75,Nouvelle-Aquitaine
24060,BOURNIQUEL,24150,BOURNIQUEL,,44.8081327137,0.770438206217,60,,Bourniquel,Bourniquel,24,Dordogne,75,Nouvelle-Aquitaine
24061,BOURROU,24110,BOURROU,,45.0481265841,0.604278043651,61,,Bourrou,Bourrou,24,Dordogne,75,Nouvelle-Aquitaine
24062,BOUTEILLES ST SEBASTIEN,24320,BOUTEILLES ST SEBASTIEN,,45.3484141889,0.289312148374,62,,Bouteilles-Saint-Sébastien,Bouteilles-Saint-Sébastien,24,Dordogne,75,Nouvelle-Aquitaine
24063,BOUZIC,24250,BOUZIC,,44.7210017816,1.21718976678,63,,Bouzic,Bouzic,24,Dordogne,75,Nouvelle-Aquitaine
24064,BRANTOME EN PERIGORD,24310,BRANTOME EN PERIGORD,,45.360114867,0.65590360604,64,,Brantôme en Périgord,Brantôme en Périgord,24,Dordogne,75,Nouvelle-Aquitaine
24064,BRANTOME EN PERIGORD,24310,BRANTOME EN PERIGORD,ST JULIEN DE BOURDEILLES,45.360114867,0.65590360604,64,,Brantôme en Périgord,Brantôme en Périgord,24,Dordogne,75,Nouvelle-Aquitaine
24065,BREUILH,24380,BREUILH,,45.057656809,0.75290475531,65,,Breuilh,Breuilh,24,Dordogne,75,Nouvelle-Aquitaine
24066,BROUCHAUD,24210,BROUCHAUD,,45.2049887022,0.997159768342,66,,Brouchaud,Brouchaud,24,Dordogne,75,Nouvelle-Aquitaine
24067,LE BUGUE,24260,LE BUGUE,,44.9264427991,0.924662888033,67,Le,Bugue,Le Bugue,24,Dordogne,75,Nouvelle-Aquitaine
24068,LE BUISSON DE CADOUIN,24480,LE BUISSON DE CADOUIN,,44.8209697046,0.890058168742,68,Le,Buisson-de-Cadouin,Le Buisson-de-Cadouin,24,Dordogne,75,Nouvelle-Aquitaine
24068,LE BUISSON DE CADOUIN,24480,LE BUISSON DE CADOUIN,CADOUIN,44.8209697046,0.890058168742,68,Le,Buisson-de-Cadouin,Le Buisson-de-Cadouin,24,Dordogne,75,Nouvelle-Aquitaine
24068,LE BUISSON DE CADOUIN,24480,LE BUISSON DE CADOUIN,PALEYRAC,44.8209697046,0.890058168742,68,Le,Buisson-de-Cadouin,Le Buisson-de-Cadouin,24,Dordogne,75,Nouvelle-Aquitaine
24069,BUSSAC,24350,BUSSAC,,45.266644062,0.602717592385,69,,Bussac,Bussac,24,Dordogne,75,Nouvelle-Aquitaine
24070,BUSSEROLLES,24360,BUSSEROLLES,,45.6716660842,0.646120502455,70,,Busserolles,Busserolles,24,Dordogne,75,Nouvelle-Aquitaine
24071,BUSSIERE BADIL,24360,BUSSIERE BADIL,,45.6446204602,0.601830504987,71,,Bussière-Badil,Bussière-Badil,24,Dordogne,75,Nouvelle-Aquitaine
24073,CALES,24150,CALES,,44.8631926385,0.815037014409,73,,Calès,Calès,24,Dordogne,75,Nouvelle-Aquitaine
24074,CALVIAC EN PERIGORD,24370,CALVIAC EN PERIGORD,,44.8610771393,1.32210433458,74,,Calviac-en-Périgord,Calviac-en-Périgord,24,Dordogne,75,Nouvelle-Aquitaine
24075,CAMPAGNAC LES QUERCY,24550,CAMPAGNAC LES QUERCY,,44.6954132053,1.17519301119,75,,Campagnac-lès-Quercy,Campagnac-lès-Quercy,24,Dordogne,75,Nouvelle-Aquitaine
24076,CAMPAGNE,24260,CAMPAGNE,,44.8978505909,0.977579666373,76,,Campagne,Campagne,24,Dordogne,75,Nouvelle-Aquitaine
24077,CAMPSEGRET,24140,CAMPSEGRET,,44.9423167258,0.567665908192,77,,Campsegret,Campsegret,24,Dordogne,75,Nouvelle-Aquitaine
24079,CANTILLAC,24530,CANTILLAC,,45.4000368216,0.643672265068,79,,Cantillac,Cantillac,24,Dordogne,75,Nouvelle-Aquitaine
24080,CAPDROT,24540,CAPDROT,,44.6788316075,0.945174141112,80,,Capdrot,Capdrot,24,Dordogne,75,Nouvelle-Aquitaine
24081,CARLUX,24370,CARLUX,,44.8869300675,1.35986365521,81,,Carlux,Carlux,24,Dordogne,75,Nouvelle-Aquitaine
24082,CARSAC AILLAC,24200,CARSAC AILLAC,,44.8504427417,1.27427497558,82,,Carsac-Aillac,Carsac-Aillac,24,Dordogne,75,Nouvelle-Aquitaine
24083,CARSAC DE GURSON,24610,CARSAC DE GURSON,,44.9404735859,0.0946785961046,83,,Carsac-de-Gurson,Carsac-de-Gurson,24,Dordogne,75,Nouvelle-Aquitaine
24084,CARVES,24170,CARVES,,44.7854908288,1.06057699804,84,,Carves,Carves,24,Dordogne,75,Nouvelle-Aquitaine
24085,LA CASSAGNE,24120,LA CASSAGNE,,45.0565548056,1.30647398924,85,La,Cassagne,La Cassagne,24,Dordogne,75,Nouvelle-Aquitaine
24086,CASTELNAUD LA CHAPELLE,24250,CASTELNAUD LA CHAPELLE,,44.8017266303,1.13058711758,86,,Castelnaud-la-Chapelle,Castelnaud-la-Chapelle,24,Dordogne,75,Nouvelle-Aquitaine
24086,CASTELNAUD LA CHAPELLE,24250,CASTELNAUD LA CHAPELLE,LA CHAPELLE PECHAUD,44.8017266303,1.13058711758,86,,Castelnaud-la-Chapelle,Castelnaud-la-Chapelle,24,Dordogne,75,Nouvelle-Aquitaine
24087,CASTELS,24220,CASTELS,,44.8705381917,1.08038180095,87,,Castels et Bézenac,Castels et Bézenac,24,Dordogne,75,Nouvelle-Aquitaine
24088,CAUSE DE CLERANS,24150,CAUSE DE CLERANS,,44.8732898421,0.672743624139,88,,Cause-de-Clérans,Cause-de-Clérans,24,Dordogne,75,Nouvelle-Aquitaine
24089,CAZOULES,24370,CAZOULES,,44.882273616,1.42894748653,89,,Cazoulès,Cazoulès,24,Dordogne,75,Nouvelle-Aquitaine
24090,CELLES,24600,CELLES,,45.2925292578,0.412424261019,90,,Celles,Celles,24,Dordogne,75,Nouvelle-Aquitaine
24091,CENAC ET ST JULIEN,24250,CENAC ET ST JULIEN,,44.7868370138,1.20346249044,91,,Cénac-et-Saint-Julien,Cénac-et-Saint-Julien,24,Dordogne,75,Nouvelle-Aquitaine
24092,CENDRIEUX,24380,CENDRIEUX,,44.9992323824,0.826953355328,92,,Cendrieux,Cendrieux,24,Dordogne,75,Nouvelle-Aquitaine
24093,CERCLES,24320,CERCLES,,45.367110589,0.469505189702,93,,Cercles,Cercles,24,Dordogne,75,Nouvelle-Aquitaine
24094,CHALAGNAC,24380,CHALAGNAC,,45.0928504071,0.680786253441,94,,Chalagnac,Chalagnac,24,Dordogne,75,Nouvelle-Aquitaine
24095,CHALAIS,24800,CHALAIS,,45.5032613972,0.940330298311,95,,Chalais,Chalais,24,Dordogne,75,Nouvelle-Aquitaine
24096,CHAMPAGNAC DE BELAIR,24530,CHAMPAGNAC DE BELAIR,,45.400469208,0.69507098451,96,,Champagnac-de-Belair,Champagnac-de-Belair,24,Dordogne,75,Nouvelle-Aquitaine
24097,CHAMPAGNE ET FONTAINE,24320,CHAMPAGNE ET FONTAINE,,45.4290860632,0.336264937907,97,,Champagne-et-Fontaine,Champagne-et-Fontaine,24,Dordogne,75,Nouvelle-Aquitaine
24098,CHAMPCEVINEL,24750,CHAMPCEVINEL,,45.2212466708,0.725037549995,98,,Champcevinel,Champcevinel,24,Dordogne,75,Nouvelle-Aquitaine
24099,CHAMPEAUX ET LA CHAPELLE POMMIER,24340,CHAMPEAUX ET LA CHAPELLE POMMIER,,45.4680082324,0.581009876861,99,,Champeaux-et-la-Chapelle-Pommier,Champeaux-et-la-Chapelle-Pommier,24,Dordogne,75,Nouvelle-Aquitaine
24100,CHAMPNIERS ET REILHAC,24360,CHAMPNIERS ET REILHAC,,45.6717236599,0.721225907489,100,,Champniers-et-Reilhac,Champniers-et-Reilhac,24,Dordogne,75,Nouvelle-Aquitaine
24101,CHAMPS ROMAIN,24470,CHAMPS ROMAIN,,45.5404045752,0.771560707867,101,,Champs-Romain,Champs-Romain,24,Dordogne,75,Nouvelle-Aquitaine
24102,CHANCELADE,24650,CHANCELADE,,45.2100121087,0.655273444534,102,,Chancelade,Chancelade,24,Dordogne,75,Nouvelle-Aquitaine
24103,LE CHANGE,24640,LE CHANGE,,45.2019174878,0.891287872856,103,Le,Change,Le Change,24,Dordogne,75,Nouvelle-Aquitaine
24104,CHANTERAC,24190,CHANTERAC,,45.1674514266,0.438130563991,104,,Chantérac,Chantérac,24,Dordogne,75,Nouvelle-Aquitaine
24105,CHAPDEUIL,24320,CHAPDEUIL,,45.3426145124,0.466888679679,105,,Chapdeuil,Chapdeuil,24,Dordogne,75,Nouvelle-Aquitaine
24106,LA CHAPELLE AUBAREIL,24290,LA CHAPELLE AUBAREIL,,45.0104993556,1.18942636109,106,La,Chapelle-Aubareil,La Chapelle-Aubareil,24,Dordogne,75,Nouvelle-Aquitaine
24107,LA CHAPELLE FAUCHER,24530,LA CHAPELLE FAUCHER,,45.3743763047,0.761777486719,107,La,Chapelle-Faucher,La Chapelle-Faucher,24,Dordogne,75,Nouvelle-Aquitaine
24108,LA CHAPELLE GONAGUET,24350,LA CHAPELLE GONAGUET,,45.2301791258,0.619050495497,108,La,Chapelle-Gonaguet,La Chapelle-Gonaguet,24,Dordogne,75,Nouvelle-Aquitaine
24109,LA CHAPELLE GRESIGNAC,24320,LA CHAPELLE GRESIGNAC,,45.3896352806,0.339347115652,109,La,Chapelle-Grésignac,La Chapelle-Grésignac,24,Dordogne,75,Nouvelle-Aquitaine
24110,LA CHAPELLE MONTABOURLET,24320,LA CHAPELLE MONTABOURLET,,45.394949122,0.457653171852,110,La,Chapelle-Montabourlet,La Chapelle-Montabourlet,24,Dordogne,75,Nouvelle-Aquitaine
24111,LA CHAPELLE MONTMOREAU,24300,LA CHAPELLE MONTMOREAU,,45.4499413801,0.644225209813,111,La,Chapelle-Montmoreau,La Chapelle-Montmoreau,24,Dordogne,75,Nouvelle-Aquitaine
24113,LA CHAPELLE ST JEAN,24390,LA CHAPELLE ST JEAN,,45.1954042265,1.16437857319,113,La,Chapelle-Saint-Jean,La Chapelle-Saint-Jean,24,Dordogne,75,Nouvelle-Aquitaine
24114,CHASSAIGNES,24600,CHASSAIGNES,,45.2543656522,0.249815310877,114,,Chassaignes,Chassaignes,24,Dordogne,75,Nouvelle-Aquitaine
24115,CHATEAU L EVEQUE,24460,CHATEAU L EVEQUE,,45.2584930468,0.686400919033,115,,Château-l'Évêque,Château-l'Évêque,24,Dordogne,75,Nouvelle-Aquitaine
24116,CHATRES,24120,CHATRES,,45.1873956526,1.19479801127,116,,Châtres,Châtres,24,Dordogne,75,Nouvelle-Aquitaine
24117,CHAVAGNAC,24120,CHAVAGNAC,,45.0871076181,1.37000176576,117,Les,Coteaux Périgourdins,Les Coteaux Périgourdins,24,Dordogne,75,Nouvelle-Aquitaine
24119,CHERVAL,24320,CHERVAL,,45.3879420014,0.384934329038,119,,Cherval,Cherval,24,Dordogne,75,Nouvelle-Aquitaine
24120,CHERVEIX CUBAS,24390,CHERVEIX CUBAS,,45.2878322795,1.11115123114,120,,Cherveix-Cubas,Cherveix-Cubas,24,Dordogne,75,Nouvelle-Aquitaine
24121,CHOURGNAC,24640,CHOURGNAC,,45.2344208721,1.05353436123,121,,Chourgnac,Chourgnac,24,Dordogne,75,Nouvelle-Aquitaine
24122,CLADECH,24170,CLADECH,,44.808812009,1.07412405501,122,,Cladech,Cladech,24,Dordogne,75,Nouvelle-Aquitaine
24123,CLERMONT DE BEAUREGARD,24140,CLERMONT DE BEAUREGARD,,44.9493110442,0.647710803163,123,,Clermont-de-Beauregard,Clermont-de-Beauregard,24,Dordogne,75,Nouvelle-Aquitaine
24124,CLERMONT D EXCIDEUIL,24160,CLERMONT D EXCIDEUIL,,45.3645597684,1.04715685137,124,,Clermont-d'Excideuil,Clermont-d'Excideuil,24,Dordogne,75,Nouvelle-Aquitaine
24126,COLOMBIER,24560,COLOMBIER,,44.7822680004,0.517959139365,126,,Colombier,Colombier,24,Dordogne,75,Nouvelle-Aquitaine
24127,COLY,24120,COLY,,45.0864639476,1.26501102507,127,,Coly,Coly,24,Dordogne,75,Nouvelle-Aquitaine
24128,COMBERANCHE ET EPELUCHE,24600,COMBERANCHE ET EPELUCHE,,45.2768659289,0.280086546492,128,,Comberanche-et-Épeluche,Comberanche-et-Épeluche,24,Dordogne,75,Nouvelle-Aquitaine
24129,CONDAT SUR TRINCOU,24530,CONDAT SUR TRINCOU,,45.3674164886,0.715843212132,129,,Condat-sur-Trincou,Condat-sur-Trincou,24,Dordogne,75,Nouvelle-Aquitaine
24130,CONDAT SUR VEZERE,24570,CONDAT SUR VEZERE,,45.1064273912,1.22906271409,130,,Condat-sur-Vézère,Condat-sur-Vézère,24,Dordogne,75,Nouvelle-Aquitaine
24131,CONNEZAC,24300,CONNEZAC,,45.5150974832,0.530385430073,131,,Connezac,Connezac,24,Dordogne,75,Nouvelle-Aquitaine
24132,CONNE DE LABARDE,24560,CONNE DE LABARDE,,44.7817676025,0.553346911349,132,,Conne-de-Labarde,Conne-de-Labarde,24,Dordogne,75,Nouvelle-Aquitaine
24133,LA COQUILLE,24450,LA COQUILLE,,45.5444015671,0.965624918469,133,La,Coquille,La Coquille,24,Dordogne,75,Nouvelle-Aquitaine
24134,CORGNAC SUR L ISLE,24800,CORGNAC SUR L ISLE,,45.3733273094,0.944659017976,134,,Corgnac-sur-l'Isle,Corgnac-sur-l'Isle,24,Dordogne,75,Nouvelle-Aquitaine
24135,CORNILLE,24750,CORNILLE,,45.2457104683,0.779065971787,135,,Cornille,Cornille,24,Dordogne,75,Nouvelle-Aquitaine
24136,COUBJOURS,24390,COUBJOURS,,45.2416866211,1.25356332891,136,,Coubjours,Coubjours,24,Dordogne,75,Nouvelle-Aquitaine
24137,COULAURES,24420,COULAURES,,45.3003467761,0.973890352625,137,,Coulaures,Coulaures,24,Dordogne,75,Nouvelle-Aquitaine
24138,COULOUNIEIX CHAMIERS,24660,COULOUNIEIX CHAMIERS,,45.1650799772,0.687592493639,138,,Coulounieix-Chamiers,Coulounieix-Chamiers,24,Dordogne,75,Nouvelle-Aquitaine
24139,COURSAC,24430,COURSAC,,45.1270017517,0.642895051046,139,,Coursac,Coursac,24,Dordogne,75,Nouvelle-Aquitaine
24140,COURS DE PILE,24520,COURS DE PILE,,44.8364921675,0.557691807136,140,,Cours-de-Pile,Cours-de-Pile,24,Dordogne,75,Nouvelle-Aquitaine
24141,COUTURES,24320,COUTURES,,45.3275321431,0.395477673293,141,,Coutures,Coutures,24,Dordogne,75,Nouvelle-Aquitaine
24142,COUX ET BIGAROQUE MOUZENS,24220,COUX ET BIGAROQUE MOUZENS,,44.8481194805,0.967817151938,142,,Coux et Bigaroque-Mouzens,Coux et Bigaroque-Mouzens,24,Dordogne,75,Nouvelle-Aquitaine
24142,COUX ET BIGAROQUE MOUZENS,24220,COUX ET BIGAROQUE MOUZENS,MOUZENS,44.8481194805,0.967817151938,142,,Coux et Bigaroque-Mouzens,Coux et Bigaroque-Mouzens,24,Dordogne,75,Nouvelle-Aquitaine
24143,COUZE ET ST FRONT,24150,COUZE ET ST FRONT,,44.8255934988,0.722166655982,143,,Couze-et-Saint-Front,Couze-et-Saint-Front,24,Dordogne,75,Nouvelle-Aquitaine
24144,CREYSSAC,24350,CREYSSAC,,45.3128787513,0.549800150717,144,,Creyssac,Creyssac,24,Dordogne,75,Nouvelle-Aquitaine
24145,CREYSSE,24100,CREYSSE,,44.8643965465,0.548982733811,145,,Creysse,Creysse,24,Dordogne,75,Nouvelle-Aquitaine
24146,CREYSSENSAC ET PISSOT,24380,CREYSSENSAC ET PISSOT,,45.0708049621,0.668083362179,146,,Creyssensac-et-Pissot,Creyssensac-et-Pissot,24,Dordogne,75,Nouvelle-Aquitaine
24147,CUBJAC,24640,CUBJAC,,45.2253686677,0.939009779749,147,,Cubjac-Auvézère-Val d'Ans,Cubjac-Auvézère-Val d'Ans,24,Dordogne,75,Nouvelle-Aquitaine
24148,CUNEGES,24240,CUNEGES,,44.7797858613,0.374061836484,148,,Cunèges,Cunèges,24,Dordogne,75,Nouvelle-Aquitaine
24150,DAGLAN,24250,DAGLAN,,44.7481705464,1.18720141568,150,,Daglan,Daglan,24,Dordogne,75,Nouvelle-Aquitaine
24151,DOISSAT,24170,DOISSAT,,44.7217623924,1.08561401475,151,,Doissat,Doissat,24,Dordogne,75,Nouvelle-Aquitaine
24152,DOMME,24250,DOMME,,44.8035117633,1.24433028521,152,,Domme,Domme,24,Dordogne,75,Nouvelle-Aquitaine
24153,LA DORNAC,24120,LA DORNAC,,45.0734567352,1.34321886485,153,La,Dornac,La Dornac,24,Dordogne,75,Nouvelle-Aquitaine
24154,DOUCHAPT,24350,DOUCHAPT,,45.2320756784,0.4456872925,154,,Douchapt,Douchapt,24,Dordogne,75,Nouvelle-Aquitaine
24155,DOUVILLE,24140,DOUVILLE,,45.0019813607,0.595182446811,155,,Douville,Douville,24,Dordogne,75,Nouvelle-Aquitaine
24156,LA DOUZE,24330,LA DOUZE,,45.0680583989,0.86230765342,156,La,Douze,La Douze,24,Dordogne,75,Nouvelle-Aquitaine
24157,DOUZILLAC,24190,DOUZILLAC,,45.0903485072,0.407727332302,157,,Douzillac,Douzillac,24,Dordogne,75,Nouvelle-Aquitaine
24158,DUSSAC,24270,DUSSAC,,45.3955225277,1.07316644218,158,,Dussac,Dussac,24,Dordogne,75,Nouvelle-Aquitaine
24159,ECHOURGNAC,24410,ECHOURGNAC,,45.1290868682,0.223067920753,159,,Échourgnac,Échourgnac,24,Dordogne,75,Nouvelle-Aquitaine
24160,EGLISE NEUVE DE VERGT,24380,EGLISE NEUVE DE VERGT,,45.0887254033,0.732677000493,160,,Église-Neuve-de-Vergt,Église-Neuve-de-Vergt,24,Dordogne,75,Nouvelle-Aquitaine
24161,EGLISE NEUVE D ISSAC,24400,EGLISE NEUVE D ISSAC,,44.9811034103,0.427826698139,161,,Église-Neuve-d'Issac,Église-Neuve-d'Issac,24,Dordogne,75,Nouvelle-Aquitaine
24162,ESCOIRE,24420,ESCOIRE,,45.2075114447,0.851453516232,162,,Escoire,Escoire,24,Dordogne,75,Nouvelle-Aquitaine
24163,ETOUARS,24360,ETOUARS,,45.6088582273,0.615747812595,163,,Étouars,Étouars,24,Dordogne,75,Nouvelle-Aquitaine
24164,EXCIDEUIL,24160,EXCIDEUIL,,45.3293054433,1.06217894627,164,,Excideuil,Excideuil,24,Dordogne,75,Nouvelle-Aquitaine
24165,EYGURANDE ET GARDEDEUIL,24700,EYGURANDE ET GARDEDEUIL,,45.0789974169,0.121214949187,165,,Eygurande-et-Gardedeuil,Eygurande-et-Gardedeuil,24,Dordogne,75,Nouvelle-Aquitaine
24166,EYLIAC,24330,EYLIAC,,45.1550053689,0.855718097369,166,,Eyliac,Eyliac,24,Dordogne,75,Nouvelle-Aquitaine
24167,EYMET,24500,EYMET,,44.6734878738,0.394630652209,167,,Eymet,Eymet,24,Dordogne,75,Nouvelle-Aquitaine
24168,PLAISANCE,24560,PLAISANCE,,44.6984219504,0.555541273455,168,,Plaisance,Plaisance,24,Dordogne,75,Nouvelle-Aquitaine
24168,PLAISANCE,24560,PLAISANCE,FALGUEYRAT,44.6984219504,0.555541273455,168,,Plaisance,Plaisance,24,Dordogne,75,Nouvelle-Aquitaine
24168,PLAISANCE,24560,PLAISANCE,MANDACOU,44.6984219504,0.555541273455,168,,Plaisance,Plaisance,24,Dordogne,75,Nouvelle-Aquitaine
24170,EYVIRAT,24460,EYVIRAT,,45.3287238212,0.738018257754,170,,Eyvirat,Eyvirat,24,Dordogne,75,Nouvelle-Aquitaine
24171,EYZERAC,24800,EYZERAC,,45.3874309913,0.902512907037,171,,Eyzerac,Eyzerac,24,Dordogne,75,Nouvelle-Aquitaine
24172,LES EYZIES DE TAYAC SIREUIL,24620,LES EYZIES DE TAYAC SIREUIL,,44.9350703851,1.04035256381,172,Les,Eyzies-de-Tayac-Sireuil,Les Eyzies-de-Tayac-Sireuil,24,Dordogne,75,Nouvelle-Aquitaine
24172,LES EYZIES DE TAYAC SIREUIL,24620,LES EYZIES DE TAYAC SIREUIL,SIREUIL,44.9350703851,1.04035256381,172,Les,Eyzies-de-Tayac-Sireuil,Les Eyzies-de-Tayac-Sireuil,24,Dordogne,75,Nouvelle-Aquitaine
24174,FANLAC,24290,FANLAC,,45.0690605544,1.09300790001,174,,Fanlac,Fanlac,24,Dordogne,75,Nouvelle-Aquitaine
24175,LES FARGES,24290,LES FARGES,,45.1147728849,1.19318015196,175,Les,Farges,Les Farges,24,Dordogne,75,Nouvelle-Aquitaine
24176,FAURILLES,24560,FAURILLES,,44.7025777604,0.687070174214,176,,Faurilles,Faurilles,24,Dordogne,75,Nouvelle-Aquitaine
24177,FAUX,24560,FAUX,,44.7855307145,0.646344267604,177,,Faux,Faux,24,Dordogne,75,Nouvelle-Aquitaine
24178,FESTALEMPS,24410,FESTALEMPS,,45.2174671633,0.24226835854,178,,Festalemps,Festalemps,24,Dordogne,75,Nouvelle-Aquitaine
24179,LA FEUILLADE,24120,LA FEUILLADE,,45.1156060328,1.39759185904,179,La,Feuillade,La Feuillade,24,Dordogne,75,Nouvelle-Aquitaine
24180,FIRBEIX,24450,FIRBEIX,,45.5924010837,0.9420529735,180,,Firbeix,Firbeix,24,Dordogne,75,Nouvelle-Aquitaine
24181,FLAUGEAC,24240,FLAUGEAC,,44.7544183869,0.440840093984,181,,Flaugeac,Flaugeac,24,Dordogne,75,Nouvelle-Aquitaine
24182,LE FLEIX,24130,LE FLEIX,,44.880919054,0.262559942743,182,Le,Fleix,Le Fleix,24,Dordogne,75,Nouvelle-Aquitaine
24183,FLEURAC,24580,FLEURAC,,44.9972453848,1.0048661306,183,,Fleurac,Fleurac,24,Dordogne,75,Nouvelle-Aquitaine
24184,FLORIMONT GAUMIER,24250,FLORIMONT GAUMIER,,44.708224337,1.23864373133,184,,Florimont-Gaumier,Florimont-Gaumier,24,Dordogne,75,Nouvelle-Aquitaine
24186,FONROQUE,24500,FONROQUE,,44.7047696483,0.40989488566,186,,Fonroque,Fonroque,24,Dordogne,75,Nouvelle-Aquitaine
24188,FOSSEMAGNE,24210,FOSSEMAGNE,,45.1187435642,0.988118643995,188,,Fossemagne,Fossemagne,24,Dordogne,75,Nouvelle-Aquitaine
24189,FOUGUEYROLLES,33220,FOUGUEYROLLES,,44.8684209151,0.188754198495,189,,Fougueyrolles,Fougueyrolles,24,Dordogne,75,Nouvelle-Aquitaine
24190,FOULEIX,24380,FOULEIX,,44.9755144028,0.673637945149,190,,Fouleix,Fouleix,24,Dordogne,75,Nouvelle-Aquitaine
24191,FRAISSE,24130,FRAISSE,,44.9336348822,0.303365596651,191,,Fraisse,Fraisse,24,Dordogne,75,Nouvelle-Aquitaine
24192,GABILLOU,24210,GABILLOU,,45.2059650119,1.03227173677,192,,Gabillou,Gabillou,24,Dordogne,75,Nouvelle-Aquitaine
24193,GAGEAC ET ROUILLAC,24240,GAGEAC ET ROUILLAC,,44.8038484874,0.357689330262,193,,Gageac-et-Rouillac,Gageac-et-Rouillac,24,Dordogne,75,Nouvelle-Aquitaine
24194,GARDONNE,24680,GARDONNE,,44.831194856,0.330972182621,194,,Gardonne,Gardonne,24,Dordogne,75,Nouvelle-Aquitaine
24195,GAUGEAC,24540,GAUGEAC,,44.6662613577,0.879002663068,195,,Gaugeac,Gaugeac,24,Dordogne,75,Nouvelle-Aquitaine
24196,GENIS,24160,GENIS,,45.3306277228,1.16364841419,196,,Génis,Génis,24,Dordogne,75,Nouvelle-Aquitaine
24197,GINESTET,24130,GINESTET,,44.904098467,0.437644888806,197,,Ginestet,Ginestet,24,Dordogne,75,Nouvelle-Aquitaine
24198,LA GONTERIE BOULOUNEIX,24310,LA GONTERIE BOULOUNEIX,,45.381722826,0.58447803795,198,La,Gonterie-Boulouneix,La Gonterie-Boulouneix,24,Dordogne,75,Nouvelle-Aquitaine
24199,GOUT ROSSIGNOL,24320,GOUT ROSSIGNOL,,45.4163367159,0.40556165007,199,,Gout-Rossignol,Gout-Rossignol,24,Dordogne,75,Nouvelle-Aquitaine
24200,GRAND BRASSAC,24350,GRAND BRASSAC,,45.3001059626,0.485790051085,200,,Grand-Brassac,Grand-Brassac,24,Dordogne,75,Nouvelle-Aquitaine
24202,GRANGES D ANS,24390,GRANGES D ANS,,45.2106803727,1.11630422946,202,,Granges-d'Ans,Granges-d'Ans,24,Dordogne,75,Nouvelle-Aquitaine
24203,LES GRAULGES,24340,LES GRAULGES,,45.5010956195,0.444988079007,203,Les,Graulges,Les Graulges,24,Dordogne,75,Nouvelle-Aquitaine
24204,GREZES,24120,GREZES,,45.108448343,1.36103693211,204,,Grèzes,Grèzes,24,Dordogne,75,Nouvelle-Aquitaine
24205,GRIGNOLS,24110,GRIGNOLS,,45.0847043136,0.539775704647,205,,Grignols,Grignols,24,Dordogne,75,Nouvelle-Aquitaine
24206,GRIVES,24170,GRIVES,,44.762997809,1.07763758967,206,,Grives,Grives,24,Dordogne,75,Nouvelle-Aquitaine
24207,GROLEJAC,24250,GROLEJAC,,44.809654235,1.29264376506,207,,Groléjac,Groléjac,24,Dordogne,75,Nouvelle-Aquitaine
24208,GRUN BORDAS,24380,GRUN BORDAS,,45.0461305125,0.642046080067,208,,Grun-Bordas,Grun-Bordas,24,Dordogne,75,Nouvelle-Aquitaine
24209,HAUTEFAYE,24300,HAUTEFAYE,,45.5360605721,0.508242554831,209,,Hautefaye,Hautefaye,24,Dordogne,75,Nouvelle-Aquitaine
24210,HAUTEFORT,24390,HAUTEFORT,,45.2589200751,1.1354185818,210,,Hautefort,Hautefort,24,Dordogne,75,Nouvelle-Aquitaine
24211,ISSAC,24400,ISSAC,,45.0150209065,0.451710444544,211,,Issac,Issac,24,Dordogne,75,Nouvelle-Aquitaine
24212,ISSIGEAC,24560,ISSIGEAC,,44.7277197609,0.605495408497,212,,Issigeac,Issigeac,24,Dordogne,75,Nouvelle-Aquitaine
24213,JAURE,24140,JAURE,,45.0547363387,0.557056437586,213,,Jaure,Jaure,24,Dordogne,75,Nouvelle-Aquitaine
24214,JAVERLHAC ET LA CHAPELLE ST ROBERT,24300,JAVERLHAC LA CHAPELLE ST ROBERT,,45.5610374028,0.55048383986,214,,Javerlhac-et-la-Chapelle-Saint-Robert,Javerlhac-et-la-Chapelle-Saint-Robert,24,Dordogne,75,Nouvelle-Aquitaine
24215,JAYAC,24590,JAYAC,,45.0299010059,1.3515117974,215,,Jayac,Jayac,24,Dordogne,75,Nouvelle-Aquitaine
24216,LA JEMAYE,24410,LA JEMAYE,,45.1605027107,0.2625699124,216,La,Jemaye-Ponteyraud,La Jemaye-Ponteyraud,24,Dordogne,75,Nouvelle-Aquitaine
24217,JOURNIAC,24260,JOURNIAC,,44.9659647609,0.885753776591,217,,Journiac,Journiac,24,Dordogne,75,Nouvelle-Aquitaine
24218,JUMILHAC LE GRAND,24630,JUMILHAC LE GRAND,,45.5051973113,1.08066464254,218,,Jumilhac-le-Grand,Jumilhac-le-Grand,24,Dordogne,75,Nouvelle-Aquitaine
24220,LACROPTE,24380,LACROPTE,,45.0488336059,0.831249852194,220,,Lacropte,Lacropte,24,Dordogne,75,Nouvelle-Aquitaine
24221,RUDEAU LADOSSE,24340,RUDEAU LADOSSE,,45.490529686,0.533061381093,221,,Rudeau-Ladosse,Rudeau-Ladosse,24,Dordogne,75,Nouvelle-Aquitaine
24222,LA FORCE,24130,LA FORCE,,44.8787624535,0.359531311117,222,La,Force,La Force,24,Dordogne,75,Nouvelle-Aquitaine
24223,LALINDE,24150,LALINDE,,44.8557735546,0.74158538401,223,,Lalinde,Lalinde,24,Dordogne,75,Nouvelle-Aquitaine
24224,LAMONZIE MONTASTRUC,24520,LAMONZIE MONTASTRUC,,44.901484714,0.588394489219,224,,Lamonzie-Montastruc,Lamonzie-Montastruc,24,Dordogne,75,Nouvelle-Aquitaine
24225,LAMONZIE ST MARTIN,24680,LAMONZIE ST MARTIN,,44.8334514819,0.387772567159,225,,Lamonzie-Saint-Martin,Lamonzie-Saint-Martin,24,Dordogne,75,Nouvelle-Aquitaine
24226,LAMOTHE MONTRAVEL,24230,LAMOTHE MONTRAVEL,,44.8527560372,0.0166870245724,226,,Lamothe-Montravel,Lamothe-Montravel,24,Dordogne,75,Nouvelle-Aquitaine
24227,LANOUAILLE,24270,LANOUAILLE,,45.3777105918,1.13200271217,227,,Lanouaille,Lanouaille,24,Dordogne,75,Nouvelle-Aquitaine
24228,LANQUAIS,24150,LANQUAIS,,44.8142906911,0.668926310438,228,,Lanquais,Lanquais,24,Dordogne,75,Nouvelle-Aquitaine
24229,LE LARDIN ST LAZARE,24570,LE LARDIN ST LAZARE,,45.1363136207,1.23246240823,229,Le,Lardin-Saint-Lazare,Le Lardin-Saint-Lazare,24,Dordogne,75,Nouvelle-Aquitaine
24230,LARZAC,24170,LARZAC,,44.7393792492,1.00004312034,230,,Larzac,Larzac,24,Dordogne,75,Nouvelle-Aquitaine
24231,LAVALADE,24540,LAVALADE,,44.6942905203,0.860342369929,231,,Lavalade,Lavalade,24,Dordogne,75,Nouvelle-Aquitaine
24232,LAVAUR,24550,LAVAUR,,44.6249045727,1.02459640897,232,,Lavaur,Lavaur,24,Dordogne,75,Nouvelle-Aquitaine
24233,LAVEYSSIERE,24130,LAVEYSSIERE,,44.9353073634,0.439267190843,233,,Laveyssière,Laveyssière,24,Dordogne,75,Nouvelle-Aquitaine
24234,LES LECHES,24400,LES LECHES,,44.9811088243,0.386613584642,234,Les,Lèches,Les Lèches,24,Dordogne,75,Nouvelle-Aquitaine
24235,LEGUILLAC DE CERCLES,24340,LEGUILLAC DE CERCLES,,45.3875065059,0.519215706618,235,,Léguillac-de-Cercles,Léguillac-de-Cercles,24,Dordogne,75,Nouvelle-Aquitaine
24236,LEGUILLAC DE L AUCHE,24110,LEGUILLAC DE L AUCHE,,45.1872234326,0.553068373507,236,,Léguillac-de-l'Auche,Léguillac-de-l'Auche,24,Dordogne,75,Nouvelle-Aquitaine
24237,LEMBRAS,24100,LEMBRAS,,44.8921699535,0.521484527262,237,,Lembras,Lembras,24,Dordogne,75,Nouvelle-Aquitaine
24238,LEMPZOURS,24800,LEMPZOURS,,45.354947204,0.823741307608,238,,Lempzours,Lempzours,24,Dordogne,75,Nouvelle-Aquitaine
24240,LIMEUIL,24510,LIMEUIL,,44.8968110738,0.895947678696,240,,Limeuil,Limeuil,24,Dordogne,75,Nouvelle-Aquitaine
24241,LIMEYRAT,24210,LIMEYRAT,,45.1652433698,0.977335652741,241,,Limeyrat,Limeyrat,24,Dordogne,75,Nouvelle-Aquitaine
24242,LIORAC SUR LOUYRE,24520,LIORAC SUR LOUYRE,,44.8959905533,0.64158293825,242,,Liorac-sur-Louyre,Liorac-sur-Louyre,24,Dordogne,75,Nouvelle-Aquitaine
24243,LISLE,24350,LISLE,,45.2711447548,0.557283730215,243,,Lisle,Lisle,24,Dordogne,75,Nouvelle-Aquitaine
24244,LOLME,24540,LOLME,,44.7104272337,0.846288269978,244,,Lolme,Lolme,24,Dordogne,75,Nouvelle-Aquitaine
24245,LOUBEJAC,24550,LOUBEJAC,,44.6047590618,1.07450251604,245,,Loubejac,Loubejac,24,Dordogne,75,Nouvelle-Aquitaine
24246,LUNAS,24130,LUNAS,,44.9246503547,0.399062068302,246,,Lunas,Lunas,24,Dordogne,75,Nouvelle-Aquitaine
24247,LUSIGNAC,24320,LUSIGNAC,,45.3253621541,0.315458294924,247,,Lusignac,Lusignac,24,Dordogne,75,Nouvelle-Aquitaine
24248,LUSSAS ET NONTRONNEAU,24300,LUSSAS ET NONTRONNEAU,,45.5207501374,0.580509528779,248,,Lussas-et-Nontronneau,Lussas-et-Nontronneau,24,Dordogne,75,Nouvelle-Aquitaine
24249,MANAURIE,24620,MANAURIE,,44.9627734986,0.988296013654,249,,Manaurie,Manaurie,24,Dordogne,75,Nouvelle-Aquitaine
24251,MANZAC SUR VERN,24110,MANZAC SUR VERN,,45.08449412,0.590479755164,251,,Manzac-sur-Vern,Manzac-sur-Vern,24,Dordogne,75,Nouvelle-Aquitaine
24252,MARCILLAC ST QUENTIN,24200,MARCILLAC ST QUENTIN,,44.9556998288,1.19988431809,252,,Marcillac-Saint-Quentin,Marcillac-Saint-Quentin,24,Dordogne,75,Nouvelle-Aquitaine
24253,MAREUIL,24340,MAREUIL,,45.4407517575,0.447904772653,253,,Mareuil en Périgord,Mareuil en Périgord,24,Dordogne,75,Nouvelle-Aquitaine
24254,MARNAC,24220,MARNAC,,44.8296793291,1.02879857641,254,,Marnac,Marnac,24,Dordogne,75,Nouvelle-Aquitaine
24255,MARQUAY,24620,MARQUAY,,44.9410957148,1.12837683704,255,,Marquay,Marquay,24,Dordogne,75,Nouvelle-Aquitaine
24256,MARSAC SUR L ISLE,24430,MARSAC SUR L ISLE,,45.1820442564,0.651480923403,256,,Marsac-sur-l'Isle,Marsac-sur-l'Isle,24,Dordogne,75,Nouvelle-Aquitaine
24257,MARSALES,24540,MARSALES,,44.699702929,0.890622734446,257,,Marsalès,Marsalès,24,Dordogne,75,Nouvelle-Aquitaine
24258,MARSANEIX,24750,MARSANEIX,,45.0905756509,0.779022746879,258,,Marsaneix,Marsaneix,24,Dordogne,75,Nouvelle-Aquitaine
24259,MAURENS,24140,MAURENS,,44.9268775631,0.482243594531,259,,Maurens,Maurens,24,Dordogne,75,Nouvelle-Aquitaine
24260,MAUZAC ET GRAND CASTANG,24150,MAUZAC ET GRAND CASTANG,,44.8823545617,0.780345381452,260,,Mauzac-et-Grand-Castang,Mauzac-et-Grand-Castang,24,Dordogne,75,Nouvelle-Aquitaine
24260,MAUZAC ET GRAND CASTANG,24150,MAUZAC ET GRAND CASTANG,GRAND CASTANG,44.8823545617,0.780345381452,260,,Mauzac-et-Grand-Castang,Mauzac-et-Grand-Castang,24,Dordogne,75,Nouvelle-Aquitaine
24261,MAUZENS ET MIREMONT,24260,MAUZENS ET MIREMONT,,44.9907571497,0.929133081058,261,,Mauzens-et-Miremont,Mauzens-et-Miremont,24,Dordogne,75,Nouvelle-Aquitaine
24262,MAYAC,24420,MAYAC,,45.2792834899,0.951561736801,262,,Mayac,Mayac,24,Dordogne,75,Nouvelle-Aquitaine
24263,MAZEYROLLES,24550,MAZEYROLLES,,44.6732432158,1.00752492118,263,,Mazeyrolles,Mazeyrolles,24,Dordogne,75,Nouvelle-Aquitaine
24264,MENESPLET,24700,MENESPLET,,45.0036275997,0.10542960424,264,,Ménesplet,Ménesplet,24,Dordogne,75,Nouvelle-Aquitaine
24266,MENSIGNAC,24350,MENSIGNAC,,45.221163286,0.55882117248,266,,Mensignac,Mensignac,24,Dordogne,75,Nouvelle-Aquitaine
24267,MESCOULES,24240,MESCOULES,,44.7414241256,0.425737207718,267,,Mescoules,Mescoules,24,Dordogne,75,Nouvelle-Aquitaine
24268,MEYRALS,24220,MEYRALS,,44.9077685753,1.07031759796,268,,Meyrals,Meyrals,24,Dordogne,75,Nouvelle-Aquitaine
24269,MIALET,24450,MIALET,,45.5689474684,0.897873313459,269,,Mialet,Mialet,24,Dordogne,75,Nouvelle-Aquitaine
24270,MILHAC D AUBEROCHE,24330,MILHAC D AUBEROCHE,,45.1139835828,0.933226505915,270,,Milhac-d'Auberoche,Milhac-d'Auberoche,24,Dordogne,75,Nouvelle-Aquitaine
24271,MILHAC DE NONTRON,24470,MILHAC DE NONTRON,,45.4728630664,0.791457302989,271,,Milhac-de-Nontron,Milhac-de-Nontron,24,Dordogne,75,Nouvelle-Aquitaine
24272,MINZAC,24610,MINZAC,,44.9616610497,0.0299559568134,272,,Minzac,Minzac,24,Dordogne,75,Nouvelle-Aquitaine
24273,MOLIERES,24480,MOLIERES,,44.8119248894,0.82474050426,273,,Molières,Molières,24,Dordogne,75,Nouvelle-Aquitaine
24274,MONBAZILLAC,24240,MONBAZILLAC,,44.7946831977,0.480958013552,274,,Monbazillac,Monbazillac,24,Dordogne,75,Nouvelle-Aquitaine
24276,MONESTIER,24240,MONESTIER,,44.7765593236,0.315283463531,276,,Monestier,Monestier,24,Dordogne,75,Nouvelle-Aquitaine
24277,MONFAUCON,24130,MONFAUCON,,44.9145875796,0.255669856393,277,,Monfaucon,Monfaucon,24,Dordogne,75,Nouvelle-Aquitaine
24278,MONMADALES,24560,MONMADALES,,44.7682307722,0.617535162339,278,,Monmadalès,Monmadalès,24,Dordogne,75,Nouvelle-Aquitaine
24279,MONMARVES,24560,MONMARVES,,44.7052439204,0.611301484868,279,,Monmarvès,Monmarvès,24,Dordogne,75,Nouvelle-Aquitaine
24280,MONPAZIER,24540,MONPAZIER,,44.6807686841,0.894082333283,280,,Monpazier,Monpazier,24,Dordogne,75,Nouvelle-Aquitaine
24281,MONSAC,24440,MONSAC,,44.7795909631,0.692501614089,281,,Monsac,Monsac,24,Dordogne,75,Nouvelle-Aquitaine
24282,MONSAGUEL,24560,MONSAGUEL,,44.7389162676,0.571474458124,282,,Monsaguel,Monsaguel,24,Dordogne,75,Nouvelle-Aquitaine
24283,MONSEC,24340,MONSEC,,45.4247092152,0.543843105151,283,,Monsec,Monsec,24,Dordogne,75,Nouvelle-Aquitaine
24284,MONTAGNAC D AUBEROCHE,24210,MONTAGNAC D AUBEROCHE,,45.1876962177,0.953868591338,284,,Montagnac-d'Auberoche,Montagnac-d'Auberoche,24,Dordogne,75,Nouvelle-Aquitaine
24285,MONTAGNAC LA CREMPSE,24140,MONTAGNAC LA CREMPSE,,44.978515633,0.542942043095,285,,Montagnac-la-Crempse,Montagnac-la-Crempse,24,Dordogne,75,Nouvelle-Aquitaine
24286,MONTAGRIER,24350,MONTAGRIER,,45.2718810746,0.486166925043,286,,Montagrier,Montagrier,24,Dordogne,75,Nouvelle-Aquitaine
24287,MONTAUT,24560,MONTAUT,,44.750445073,0.646117472579,287,,Montaut,Montaut,24,Dordogne,75,Nouvelle-Aquitaine
24288,MONTAZEAU,24230,MONTAZEAU,,44.902644776,0.130890418216,288,,Montazeau,Montazeau,24,Dordogne,75,Nouvelle-Aquitaine
24289,MONTCARET,24230,MONTCARET,,44.8618483354,0.0660005005906,289,,Montcaret,Montcaret,24,Dordogne,75,Nouvelle-Aquitaine
24290,MONTFERRAND DU PERIGORD,24440,MONTFERRAND DU PERIGORD,,44.7585180837,0.874945849363,290,,Montferrand-du-Périgord,Montferrand-du-Périgord,24,Dordogne,75,Nouvelle-Aquitaine
24291,MONTIGNAC,24290,MONTIGNAC,,45.0627368774,1.15422675286,291,,Montignac,Montignac,24,Dordogne,75,Nouvelle-Aquitaine
24292,MONTPEYROUX,24610,MONTPEYROUX,,44.9152258027,0.0627373400055,292,,Montpeyroux,Montpeyroux,24,Dordogne,75,Nouvelle-Aquitaine
24293,MONPLAISANT,24170,MONPLAISANT,,44.7933486992,0.995152605862,293,,Monplaisant,Monplaisant,24,Dordogne,75,Nouvelle-Aquitaine
24294,MONTPON MENESTEROL,24700,MONTPON MENESTEROL,,45.0196715762,0.155545015448,294,,Montpon-Ménestérol,Montpon-Ménestérol,24,Dordogne,75,Nouvelle-Aquitaine
24295,MONTREM,24110,MONTREM,,45.1310326965,0.58097170515,295,,Montrem,Montrem,24,Dordogne,75,Nouvelle-Aquitaine
24296,MOULEYDIER,24520,MOULEYDIER,,44.8599634791,0.617224513886,296,,Mouleydier,Mouleydier,24,Dordogne,75,Nouvelle-Aquitaine
24297,MOULIN NEUF,24700,MOULIN NEUF,,45.0000110213,0.0583950714149,297,,Moulin-Neuf,Moulin-Neuf,24,Dordogne,75,Nouvelle-Aquitaine
24299,MUSSIDAN,24400,MUSSIDAN,,45.0296732356,0.364777087199,299,,Mussidan,Mussidan,24,Dordogne,75,Nouvelle-Aquitaine
24300,NABIRAT,24250,NABIRAT,,44.7651982661,1.29075150159,300,,Nabirat,Nabirat,24,Dordogne,75,Nouvelle-Aquitaine
24301,NADAILLAC,24590,NADAILLAC,,45.0316530259,1.40035024166,301,,Nadaillac,Nadaillac,24,Dordogne,75,Nouvelle-Aquitaine
24302,NAILHAC,24390,NAILHAC,,45.2237342898,1.15487998204,302,,Nailhac,Nailhac,24,Dordogne,75,Nouvelle-Aquitaine
24303,NANTEUIL AURIAC DE BOURZAC,24320,NANTEUIL AURIAC DE BOURZAC,,45.3801182824,0.287647763692,303,,Nanteuil-Auriac-de-Bourzac,Nanteuil-Auriac-de-Bourzac,24,Dordogne,75,Nouvelle-Aquitaine
24303,NANTEUIL AURIAC DE BOURZAC,24320,NANTEUIL AURIAC DE BOURZAC,AURIAC DE BOURZAC,45.3801182824,0.287647763692,303,,Nanteuil-Auriac-de-Bourzac,Nanteuil-Auriac-de-Bourzac,24,Dordogne,75,Nouvelle-Aquitaine
24304,NANTHEUIL,24800,NANTHEUIL,,45.4263272071,0.956262787704,304,,Nantheuil,Nantheuil,24,Dordogne,75,Nouvelle-Aquitaine
24305,NANTHIAT,24800,NANTHIAT,,45.4081424618,0.985034812196,305,,Nanthiat,Nanthiat,24,Dordogne,75,Nouvelle-Aquitaine
24306,NASTRINGUES,24230,NASTRINGUES,,44.8721088815,0.15007807252,306,,Nastringues,Nastringues,24,Dordogne,75,Nouvelle-Aquitaine
24307,NAUSSANNES,24440,NAUSSANNES,,44.7501679867,0.718768871608,307,,Naussannes,Naussannes,24,Dordogne,75,Nouvelle-Aquitaine
24308,NEGRONDES,24460,NEGRONDES,,45.3402218527,0.878687082758,308,,Négrondes,Négrondes,24,Dordogne,75,Nouvelle-Aquitaine
24309,NEUVIC,24190,NEUVIC,,45.0893401063,0.466172128928,309,,Neuvic,Neuvic,24,Dordogne,75,Nouvelle-Aquitaine
24311,NONTRON,24300,NONTRON,,45.5303295535,0.683993810564,311,,Nontron,Nontron,24,Dordogne,75,Nouvelle-Aquitaine
24312,NOTRE DAME DE SANILHAC,24660,NOTRE DAME DE SANILHAC,,45.1273768173,0.712740186269,312,,Sanilhac,Sanilhac,24,Dordogne,75,Nouvelle-Aquitaine
24313,ORLIAC,24170,ORLIAC,,44.7131022876,1.06171600092,313,,Orliac,Orliac,24,Dordogne,75,Nouvelle-Aquitaine
24314,ORLIAGUET,24370,ORLIAGUET,,44.9107564817,1.38158275558,314,,Orliaguet,Orliaguet,24,Dordogne,75,Nouvelle-Aquitaine
24316,PARCOUL CHENAUD,24410,PARCOUL CHENAUD,,45.1915224992,0.0356230850507,316,,Parcoul-Chenaud,Parcoul-Chenaud,24,Dordogne,75,Nouvelle-Aquitaine
24316,PARCOUL CHENAUD,24410,PARCOUL CHENAUD,CHENAUD,45.1915224992,0.0356230850507,316,,Parcoul-Chenaud,Parcoul-Chenaud,24,Dordogne,75,Nouvelle-Aquitaine
24317,PAULIN,24590,PAULIN,,44.9952310896,1.33418977695,317,,Paulin,Paulin,24,Dordogne,75,Nouvelle-Aquitaine
24318,PAUNAT,24510,PAUNAT,,44.9027724899,0.854531456858,318,,Paunat,Paunat,24,Dordogne,75,Nouvelle-Aquitaine
24319,PAUSSAC ET ST VIVIEN,24310,PAUSSAC ET ST VIVIEN,,45.3463059633,0.539590809201,319,,Paussac-et-Saint-Vivien,Paussac-et-Saint-Vivien,24,Dordogne,75,Nouvelle-Aquitaine
24320,PAYZAC,24270,PAYZAC,,45.4219045977,1.22669113048,320,,Payzac,Payzac,24,Dordogne,75,Nouvelle-Aquitaine
24321,PAZAYAC,24120,PAZAYAC,,45.1245283179,1.37517151889,321,,Pazayac,Pazayac,24,Dordogne,75,Nouvelle-Aquitaine
24322,PERIGUEUX,24000,PERIGUEUX,,45.1918704873,0.711825549847,322,,Périgueux,Périgueux,24,Dordogne,75,Nouvelle-Aquitaine
24323,PETIT BERSAC,24600,PETIT BERSAC,,45.2695075194,0.227208403812,323,,Petit-Bersac,Petit-Bersac,24,Dordogne,75,Nouvelle-Aquitaine
24324,PEYRIGNAC,24210,PEYRIGNAC,,45.1587056018,1.19562965886,324,,Peyrignac,Peyrignac,24,Dordogne,75,Nouvelle-Aquitaine
24325,PEYRILLAC ET MILLAC,24370,PEYRILLAC ET MILLAC,,44.8912941721,1.4075345057,325,,Peyrillac-et-Millac,Peyrillac-et-Millac,24,Dordogne,75,Nouvelle-Aquitaine
24326,PEYZAC LE MOUSTIER,24620,PEYZAC LE MOUSTIER,,44.9822248475,1.07992123223,326,,Peyzac-le-Moustier,Peyzac-le-Moustier,24,Dordogne,75,Nouvelle-Aquitaine
24327,PEZULS,24510,PEZULS,,44.9095112076,0.805057677324,327,,Pezuls,Pezuls,24,Dordogne,75,Nouvelle-Aquitaine
24328,PIEGUT PLUVIERS,24360,PIEGUT PLUVIERS,,45.6318996846,0.699945364852,328,,Piégut-Pluviers,Piégut-Pluviers,24,Dordogne,75,Nouvelle-Aquitaine
24329,LE PIZOU,24700,LE PIZOU,,45.0367853074,0.0721083731877,329,Le,Pizou,Le Pizou,24,Dordogne,75,Nouvelle-Aquitaine
24330,PLAZAC,24580,PLAZAC,,45.0437255305,1.03815328208,330,,Plazac,Plazac,24,Dordogne,75,Nouvelle-Aquitaine
24331,POMPORT,24240,POMPORT,,44.7950411851,0.412973178168,331,,Pomport,Pomport,24,Dordogne,75,Nouvelle-Aquitaine
24333,PONTEYRAUD,24410,PONTEYRAUD,,45.1884972543,0.239988567337,333,,Ponteyraud,Ponteyraud,24,Dordogne,75,Nouvelle-Aquitaine
24334,PONTOURS,24150,PONTOURS,,44.8272888569,0.771900914466,334,,Pontours,Pontours,24,Dordogne,75,Nouvelle-Aquitaine
24335,PORT STE FOY ET PONCHAPT,33220,PORT STE FOY ET PONCHAPT,,44.8622535573,0.208854675888,335,,Port-Sainte-Foy-et-Ponchapt,Port-Sainte-Foy-et-Ponchapt,24,Dordogne,75,Nouvelle-Aquitaine
24336,PRATS DE CARLUX,24370,PRATS DE CARLUX,,44.8917275129,1.30870869193,336,,Prats-de-Carlux,Prats-de-Carlux,24,Dordogne,75,Nouvelle-Aquitaine
24337,PRATS DU PERIGORD,24550,PRATS DU PERIGORD,,44.6850784903,1.06710028522,337,,Prats-du-Périgord,Prats-du-Périgord,24,Dordogne,75,Nouvelle-Aquitaine
24338,PRESSIGNAC VICQ,24150,PRESSIGNAC VICQ,,44.8939755718,0.727634787867,338,,Pressignac-Vicq,Pressignac-Vicq,24,Dordogne,75,Nouvelle-Aquitaine
24339,PREYSSAC D EXCIDEUIL,24160,PREYSSAC D EXCIDEUIL,,45.3419567033,1.10725833882,339,,Preyssac-d'Excideuil,Preyssac-d'Excideuil,24,Dordogne,75,Nouvelle-Aquitaine
24340,PRIGONRIEUX,24130,PRIGONRIEUX,,44.8690011937,0.409883661138,340,,Prigonrieux,Prigonrieux,24,Dordogne,75,Nouvelle-Aquitaine
24341,PROISSANS,24200,PROISSANS,,44.9314130626,1.24431944303,341,,Proissans,Proissans,24,Dordogne,75,Nouvelle-Aquitaine
24344,PUYRENIER,24340,PUYRENIER,,45.4810598857,0.471311481124,344,,Puyrenier,Puyrenier,24,Dordogne,75,Nouvelle-Aquitaine
24345,QUEYSSAC,24140,QUEYSSAC,,44.9151488173,0.532361895474,345,,Queyssac,Queyssac,24,Dordogne,75,Nouvelle-Aquitaine
24346,QUINSAC,24530,QUINSAC,,45.4362563574,0.701396321628,346,,Quinsac,Quinsac,24,Dordogne,75,Nouvelle-Aquitaine
24347,RAMPIEUX,24440,RAMPIEUX,,44.7033705088,0.800770543563,347,,Rampieux,Rampieux,24,Dordogne,75,Nouvelle-Aquitaine
24348,RAZAC D EYMET,24500,RAZAC D EYMET,,44.6976329536,0.457312934463,348,,Razac-d'Eymet,Razac-d'Eymet,24,Dordogne,75,Nouvelle-Aquitaine
24349,RAZAC DE SAUSSIGNAC,24240,RAZAC DE SAUSSIGNAC,,44.8135434686,0.290129313866,349,,Razac-de-Saussignac,Razac-de-Saussignac,24,Dordogne,75,Nouvelle-Aquitaine
24350,RAZAC SUR L ISLE,24430,RAZAC SUR L ISLE,,45.1605883444,0.615843501221,350,,Razac-sur-l'Isle,Razac-sur-l'Isle,24,Dordogne,75,Nouvelle-Aquitaine
24351,RIBAGNAC,24240,RIBAGNAC,,44.7574541307,0.485586188502,351,,Ribagnac,Ribagnac,24,Dordogne,75,Nouvelle-Aquitaine
24352,RIBERAC,24600,RIBERAC,,45.2457883325,0.334782848632,352,,Ribérac,Ribérac,24,Dordogne,75,Nouvelle-Aquitaine
24353,LA ROCHEBEAUCOURT ET ARGENTINE,24340,LA ROCHEBEAUCOURT ET ARGENTINE,,45.4660887787,0.382652455353,353,La,Rochebeaucourt-et-Argentine,La Rochebeaucourt-et-Argentine,24,Dordogne,75,Nouvelle-Aquitaine
24354,LA ROCHE CHALAIS,24490,LA ROCHE CHALAIS,,45.1355934306,0.0550563261049,354,La,Roche-Chalais,La Roche-Chalais,24,Dordogne,75,Nouvelle-Aquitaine
24354,LA ROCHE CHALAIS,24490,LA ROCHE CHALAIS,ST MICHEL DE RIVIERE,45.1355934306,0.0550563261049,354,La,Roche-Chalais,La Roche-Chalais,24,Dordogne,75,Nouvelle-Aquitaine
24354,LA ROCHE CHALAIS,24490,LA ROCHE CHALAIS,ST MICHEL L ECLUSE ET LEPARON,45.1355934306,0.0550563261049,354,La,Roche-Chalais,La Roche-Chalais,24,Dordogne,75,Nouvelle-Aquitaine
24355,LA ROQUE GAGEAC,24250,LA ROQUE GAGEAC,,44.8177613658,1.19861057115,355,La,Roque-Gageac,La Roque-Gageac,24,Dordogne,75,Nouvelle-Aquitaine
24356,ROUFFIGNAC ST CERNIN DE REILHAC,24580,ROUFFIGNAC ST CERNIN DE REILHAC,,45.0511195053,0.96628484329,356,,Rouffignac-Saint-Cernin-de-Reilhac,Rouffignac-Saint-Cernin-de-Reilhac,24,Dordogne,75,Nouvelle-Aquitaine
24356,ROUFFIGNAC ST CERNIN DE REILHAC,24580,ROUFFIGNAC ST CERNIN DE REILHAC,ST CERNIN DE REILLAC,45.0511195053,0.96628484329,356,,Rouffignac-Saint-Cernin-de-Reilhac,Rouffignac-Saint-Cernin-de-Reilhac,24,Dordogne,75,Nouvelle-Aquitaine
24357,ROUFFIGNAC DE SIGOULES,24240,ROUFFIGNAC DE SIGOULES,,44.7794080781,0.444335706895,357,,Rouffignac-de-Sigoulès,Rouffignac-de-Sigoulès,24,Dordogne,75,Nouvelle-Aquitaine
24359,SADILLAC,24500,SADILLAC,,44.729700474,0.490342737888,359,,Sadillac,Sadillac,24,Dordogne,75,Nouvelle-Aquitaine
24360,SAGELAT,24170,SAGELAT,,44.7848099741,1.02305020725,360,,Sagelat,Sagelat,24,Dordogne,75,Nouvelle-Aquitaine
24361,ST AGNE,24520,ST AGNE,,44.8351095994,0.623652141486,361,,Saint-Agne,Saint-Agne,24,Dordogne,75,Nouvelle-Aquitaine
24362,STE ALVERE ST LAURENT LES BATONS,24510,STE ALVERE ST LAURENT LES BATONS,,44.9471700299,0.810464512122,362,,Val de Louyre et Caudeau,Val de Louyre et Caudeau,24,Dordogne,75,Nouvelle-Aquitaine
24362,STE ALVERE ST LAURENT LES BATONS,24510,STE ALVERE ST LAURENT LES BATONS,ST LAURENT DES BATONS,44.9471700299,0.810464512122,362,,Val de Louyre et Caudeau,Val de Louyre et Caudeau,24,Dordogne,75,Nouvelle-Aquitaine
24364,ST AMAND DE COLY,24290,ST AMAND DE COLY,,45.0521923341,1.24653200545,364,,Saint-Amand-de-Coly,Saint-Amand-de-Coly,24,Dordogne,75,Nouvelle-Aquitaine
24365,ST AMAND DE VERGT,24380,ST AMAND DE VERGT,,44.993940385,0.695744310753,365,,Saint-Amand-de-Vergt,Saint-Amand-de-Vergt,24,Dordogne,75,Nouvelle-Aquitaine
24366,ST ANDRE D ALLAS,24200,ST ANDRE D ALLAS,,44.8912301574,1.1476803246,366,,Saint-André-d'Allas,Saint-André-d'Allas,24,Dordogne,75,Nouvelle-Aquitaine
24367,ST ANDRE DE DOUBLE,24190,ST ANDRE DE DOUBLE,,45.1409249857,0.325384566649,367,,Saint-André-de-Double,Saint-André-de-Double,24,Dordogne,75,Nouvelle-Aquitaine
24368,ST ANTOINE CUMOND,24410,ST ANTOINE CUMOND,,45.2460003212,0.201941101845,368,,Saint-Antoine-Cumond,Saint-Antoine-Cumond,24,Dordogne,75,Nouvelle-Aquitaine
24369,ST ANTOINE D AUBEROCHE,24330,ST ANTOINE D AUBEROCHE,,45.1402647504,0.942192426763,369,,Saint-Antoine-d'Auberoche,Saint-Antoine-d'Auberoche,24,Dordogne,75,Nouvelle-Aquitaine
24370,ST ANTOINE DE BREUILH,24230,ST ANTOINE DE BREUILH,,44.8413756885,0.144778277474,370,,Saint-Antoine-de-Breuilh,Saint-Antoine-de-Breuilh,24,Dordogne,75,Nouvelle-Aquitaine
24371,ST AQUILIN,24110,ST AQUILIN,,45.1911088395,0.488721317785,371,,Saint-Aquilin,Saint-Aquilin,24,Dordogne,75,Nouvelle-Aquitaine
24372,ST ASTIER,24110,ST ASTIER,,45.1484357281,0.519678090961,372,,Saint-Astier,Saint-Astier,24,Dordogne,75,Nouvelle-Aquitaine
24373,ST AUBIN DE CADELECH,24500,ST AUBIN DE CADELECH,,44.6857557345,0.492017178221,373,,Saint-Aubin-de-Cadelech,Saint-Aubin-de-Cadelech,24,Dordogne,75,Nouvelle-Aquitaine
24374,ST AUBIN DE LANQUAIS,24560,ST AUBIN DE LANQUAIS,,44.7969706456,0.602118050756,374,,Saint-Aubin-de-Lanquais,Saint-Aubin-de-Lanquais,24,Dordogne,75,Nouvelle-Aquitaine
24375,ST AUBIN DE NABIRAT,24250,ST AUBIN DE NABIRAT,,44.731908542,1.28437608808,375,,Saint-Aubin-de-Nabirat,Saint-Aubin-de-Nabirat,24,Dordogne,75,Nouvelle-Aquitaine
24376,ST AULAYE PUYMANGOU,24410,ST AULAYE PUYMANGOU,,45.1823303924,0.136303399419,376,,Saint Aulaye-Puymangou,Saint Aulaye-Puymangou,24,Dordogne,75,Nouvelle-Aquitaine
24376,ST AULAYE PUYMANGOU,24410,ST AULAYE PUYMANGOU,PUYMANGOU,45.1823303924,0.136303399419,376,,Saint Aulaye-Puymangou,Saint Aulaye-Puymangou,24,Dordogne,75,Nouvelle-Aquitaine
24377,ST AVIT DE VIALARD,24260,ST AVIT DE VIALARD,,44.9453704965,0.862154837196,377,,Saint-Avit-de-Vialard,Saint-Avit-de-Vialard,24,Dordogne,75,Nouvelle-Aquitaine
24378,ST AVIT RIVIERE,24540,ST AVIT RIVIERE,,44.7401593829,0.906593699917,378,,Saint-Avit-Rivière,Saint-Avit-Rivière,24,Dordogne,75,Nouvelle-Aquitaine
24379,ST AVIT SENIEUR,24440,ST AVIT SENIEUR,,44.7750925645,0.824289637501,379,,Saint-Avit-Sénieur,Saint-Avit-Sénieur,24,Dordogne,75,Nouvelle-Aquitaine
24380,ST BARTHELEMY DE BELLEGARDE,24700,ST BARTHELEMY DE BELLEGARDE,,45.0801047856,0.193152734323,380,,Saint-Barthélemy-de-Bellegarde,Saint-Barthélemy-de-Bellegarde,24,Dordogne,75,Nouvelle-Aquitaine
24381,ST BARTHELEMY DE BUSSIERE,24360,ST BARTHELEMY DE BUSSIERE,,45.6415499133,0.747042269307,381,,Saint-Barthélemy-de-Bussière,Saint-Barthélemy-de-Bussière,24,Dordogne,75,Nouvelle-Aquitaine
24382,ST CAPRAISE DE LALINDE,24150,ST CAPRAISE DE LALINDE,,44.8463175043,0.655294285249,382,,Saint-Capraise-de-Lalinde,Saint-Capraise-de-Lalinde,24,Dordogne,75,Nouvelle-Aquitaine
24383,ST CAPRAISE D EYMET,24500,ST CAPRAISE D EYMET,,44.711179433,0.51051059596,383,,Saint-Capraise-d'Eymet,Saint-Capraise-d'Eymet,24,Dordogne,75,Nouvelle-Aquitaine
24384,ST CASSIEN,24540,ST CASSIEN,,44.6794684315,0.845364100419,384,,Saint-Cassien,Saint-Cassien,24,Dordogne,75,Nouvelle-Aquitaine
24385,ST CERNIN DE LABARDE,24560,ST CERNIN DE LABARDE,,44.7634460851,0.584640392889,385,,Saint-Cernin-de-Labarde,Saint-Cernin-de-Labarde,24,Dordogne,75,Nouvelle-Aquitaine
24386,ST CERNIN DE L HERM,24550,ST CERNIN DE L HERM,,44.6540649921,1.04585610677,386,,Saint-Cernin-de-l'Herm,Saint-Cernin-de-l'Herm,24,Dordogne,75,Nouvelle-Aquitaine
24388,ST CHAMASSY,24260,ST CHAMASSY,,44.8725532878,0.921257476514,388,,Saint-Chamassy,Saint-Chamassy,24,Dordogne,75,Nouvelle-Aquitaine
24389,ST CIRQ,24260,ST CIRQ,,44.9360696288,0.967920705814,389,,Saint-Cirq,Saint-Cirq,24,Dordogne,75,Nouvelle-Aquitaine
24390,ST CREPIN D AUBEROCHE,24330,ST CREPIN D AUBEROCHE,,45.1177639491,0.895951381831,390,,Saint-Crépin-d'Auberoche,Saint-Crépin-d'Auberoche,24,Dordogne,75,Nouvelle-Aquitaine
24391,ST CREPIN DE RICHEMONT,24310,ST CREPIN DE RICHEMONT,,45.4226145327,0.608773293473,391,,Saint-Crépin-de-Richemont,Saint-Crépin-de-Richemont,24,Dordogne,75,Nouvelle-Aquitaine
24392,ST CREPIN ET CARLUCET,24590,ST CREPIN ET CARLUCET,,44.9565947973,1.27615948362,392,,Saint-Crépin-et-Carlucet,Saint-Crépin-et-Carlucet,24,Dordogne,75,Nouvelle-Aquitaine
24393,STE CROIX,24440,STE CROIX,,44.7327105467,0.828547816645,393,,Sainte-Croix,Sainte-Croix,24,Dordogne,75,Nouvelle-Aquitaine
24394,STE CROIX DE MAREUIL,24340,STE CROIX DE MAREUIL,,45.4655946689,0.422846944104,394,,Sainte-Croix-de-Mareuil,Sainte-Croix-de-Mareuil,24,Dordogne,75,Nouvelle-Aquitaine
24395,ST CYBRANET,24250,ST CYBRANET,,44.7804732346,1.1587393695,395,,Saint-Cybranet,Saint-Cybranet,24,Dordogne,75,Nouvelle-Aquitaine
24396,ST CYPRIEN,24220,ST CYPRIEN,,44.8825740011,1.02538984639,396,,Saint-Cyprien,Saint-Cyprien,24,Dordogne,75,Nouvelle-Aquitaine
24397,ST CYR LES CHAMPAGNES,24270,ST CYR LES CHAMPAGNES,,45.3724597502,1.29106108817,397,,Saint-Cyr-les-Champagnes,Saint-Cyr-les-Champagnes,24,Dordogne,75,Nouvelle-Aquitaine
24398,ST ESTEPHE,24360,ST ESTEPHE,,45.609832852,0.657993310994,398,,Saint-Estèphe,Saint-Estèphe,24,Dordogne,75,Nouvelle-Aquitaine
24399,ST ETIENNE DE PUYCORBIER,24400,ST ETIENNE DE PUYCORBIER,,45.0929275989,0.323452712792,399,,Saint-Étienne-de-Puycorbier,Saint-Étienne-de-Puycorbier,24,Dordogne,75,Nouvelle-Aquitaine
24401,STE EULALIE D ANS,24640,STE EULALIE D ANS,,45.2493030255,1.02147550691,401,,Sainte-Eulalie-d'Ans,Sainte-Eulalie-d'Ans,24,Dordogne,75,Nouvelle-Aquitaine
24402,STE EULALIE D EYMET,24500,STE EULALIE D EYMET,,44.7093311565,0.35938458115,402,,Sainte-Eulalie-d'Eymet,Sainte-Eulalie-d'Eymet,24,Dordogne,75,Nouvelle-Aquitaine
24403,ST FELIX DE BOURDEILLES,24340,ST FELIX DE BOURDEILLES,,45.4073734663,0.565567317606,403,,Saint-Félix-de-Bourdeilles,Saint-Félix-de-Bourdeilles,24,Dordogne,75,Nouvelle-Aquitaine
24404,ST FELIX DE REILLAC ET MORTEMART,24260,ST FELIX DE REILLAC ET MORTEMART,,45.0147855996,0.89152600187,404,,Saint-Félix-de-Reillac-et-Mortemart,Saint-Félix-de-Reillac-et-Mortemart,24,Dordogne,75,Nouvelle-Aquitaine
24405,ST FELIX DE VILLADEIX,24510,ST FELIX DE VILLADEIX,,44.9319312699,0.677290095994,405,,Saint-Félix-de-Villadeix,Saint-Félix-de-Villadeix,24,Dordogne,75,Nouvelle-Aquitaine
24406,STE FOY DE BELVES,24170,STE FOY DE BELVES,,44.731269814,1.03294229921,406,,Sainte-Foy-de-Belvès,Sainte-Foy-de-Belvès,24,Dordogne,75,Nouvelle-Aquitaine
24407,STE FOY DE LONGAS,24510,STE FOY DE LONGAS,,44.9286563489,0.755646260789,407,,Sainte-Foy-de-Longas,Sainte-Foy-de-Longas,24,Dordogne,75,Nouvelle-Aquitaine
24408,ST FRONT D ALEMPS,24460,ST FRONT D ALEMPS,,45.324593189,0.78971362781,408,,Saint-Front-d'Alemps,Saint-Front-d'Alemps,24,Dordogne,75,Nouvelle-Aquitaine
24409,ST FRONT DE PRADOUX,24400,ST FRONT DE PRADOUX,,45.0595554075,0.360152317983,409,,Saint-Front-de-Pradoux,Saint-Front-de-Pradoux,24,Dordogne,75,Nouvelle-Aquitaine
24410,ST FRONT LA RIVIERE,24300,ST FRONT LA RIVIERE,,45.4652036046,0.719685871549,410,,Saint-Front-la-Rivière,Saint-Front-la-Rivière,24,Dordogne,75,Nouvelle-Aquitaine
24411,ST FRONT SUR NIZONNE,24300,ST FRONT SUR NIZONNE,,45.477464175,0.627327027878,411,,Saint-Front-sur-Nizonne,Saint-Front-sur-Nizonne,24,Dordogne,75,Nouvelle-Aquitaine
24412,ST GENIES,24590,ST GENIES,,44.9950404568,1.24436332323,412,,Saint-Geniès,Saint-Geniès,24,Dordogne,75,Nouvelle-Aquitaine
24413,ST GEORGES BLANCANEIX,24130,ST GEORGES BLANCANEIX,,44.9178103997,0.356218213042,413,,Saint-Georges-Blancaneix,Saint-Georges-Blancaneix,24,Dordogne,75,Nouvelle-Aquitaine
24414,ST GEORGES DE MONTCLARD,24140,ST GEORGES DE MONTCLARD,,44.9331773121,0.610874864188,414,,Saint-Georges-de-Montclard,Saint-Georges-de-Montclard,24,Dordogne,75,Nouvelle-Aquitaine
24415,ST GERAUD DE CORPS,24700,ST GERAUD DE CORPS,,44.9478425672,0.239383729477,415,,Saint-Géraud-de-Corps,Saint-Géraud-de-Corps,24,Dordogne,75,Nouvelle-Aquitaine
24416,ST GERMAIN DE BELVES,24170,ST GERMAIN DE BELVES,,44.806098303,1.03606561398,416,,Saint-Germain-de-Belvès,Saint-Germain-de-Belvès,24,Dordogne,75,Nouvelle-Aquitaine
24417,ST GERMAIN DES PRES,24160,ST GERMAIN DES PRES,,45.3458327209,1.00011342587,417,,Saint-Germain-des-Prés,Saint-Germain-des-Prés,24,Dordogne,75,Nouvelle-Aquitaine
24418,ST GERMAIN DU SALEMBRE,24190,ST GERMAIN DU SALEMBRE,,45.127972537,0.437779642589,418,,Saint-Germain-du-Salembre,Saint-Germain-du-Salembre,24,Dordogne,75,Nouvelle-Aquitaine
24419,ST GERMAIN ET MONS,24520,ST GERMAIN ET MONS,,44.8286582128,0.591211611663,419,,Saint-Germain-et-Mons,Saint-Germain-et-Mons,24,Dordogne,75,Nouvelle-Aquitaine
24420,ST GERY,24400,ST GERY,,44.9760796886,0.326025224409,420,,Saint-Géry,Saint-Géry,24,Dordogne,75,Nouvelle-Aquitaine
24421,ST GEYRAC,24330,ST GEYRAC,,45.0745744027,0.907034401577,421,,Saint-Geyrac,Saint-Geyrac,24,Dordogne,75,Nouvelle-Aquitaine
24422,ST HILAIRE D ESTISSAC,24140,ST HILAIRE D ESTISSAC,,45.014476321,0.505394906557,422,,Saint-Hilaire-d'Estissac,Saint-Hilaire-d'Estissac,24,Dordogne,75,Nouvelle-Aquitaine
24423,STE INNOCENCE,24500,STE INNOCENCE,,44.7249335654,0.394228419824,423,,Sainte-Innocence,Sainte-Innocence,24,Dordogne,75,Nouvelle-Aquitaine
24424,ST JEAN D ATAUX,24190,ST JEAN D ATAUX,,45.1344343643,0.394306386157,424,,Saint-Jean-d'Ataux,Saint-Jean-d'Ataux,24,Dordogne,75,Nouvelle-Aquitaine
24425,ST JEAN DE COLE,24800,ST JEAN DE COLE,,45.4135771577,0.83539033285,425,,Saint-Jean-de-Côle,Saint-Jean-de-Côle,24,Dordogne,75,Nouvelle-Aquitaine
24426,ST JEAN D ESTISSAC,24140,ST JEAN D ESTISSAC,,45.0375932892,0.504834756989,426,,Saint-Jean-d'Estissac,Saint-Jean-d'Estissac,24,Dordogne,75,Nouvelle-Aquitaine
24427,ST JEAN D EYRAUD,24140,ST JEAN D EYRAUD,,44.9558893594,0.454934411397,427,,Saint-Jean-d'Eyraud,Saint-Jean-d'Eyraud,24,Dordogne,75,Nouvelle-Aquitaine
24428,ST JORY DE CHALAIS,24800,ST JORY DE CHALAIS,,45.4894020576,0.897020061184,428,,Saint-Jory-de-Chalais,Saint-Jory-de-Chalais,24,Dordogne,75,Nouvelle-Aquitaine
24429,ST JORY LAS BLOUX,24160,ST JORY LAS BLOUX,,45.3421390696,0.9470147152,429,,Saint-Jory-las-Bloux,Saint-Jory-las-Bloux,24,Dordogne,75,Nouvelle-Aquitaine
24431,ST JULIEN DE CREMPSE,24140,ST JULIEN DE CREMPSE,,44.9499013406,0.521917380257,431,,Saint-Julien-de-Crempse,Saint-Julien-de-Crempse,24,Dordogne,75,Nouvelle-Aquitaine
24432,ST JULIEN DE LAMPON,24370,ST JULIEN DE LAMPON,,44.8619888633,1.38181461735,432,,Saint-Julien-de-Lampon,Saint-Julien-de-Lampon,24,Dordogne,75,Nouvelle-Aquitaine
24433,ST JULIEN D EYMET,24500,ST JULIEN D EYMET,,44.7219746526,0.434660918306,433,,Saint-Julien-d'Eymet,Saint-Julien-d'Eymet,24,Dordogne,75,Nouvelle-Aquitaine
24434,ST JUST,24320,ST JUST,,45.335415501,0.498491614935,434,,Saint-Just,Saint-Just,24,Dordogne,75,Nouvelle-Aquitaine
24436,ST LAURENT DES HOMMES,24400,ST LAURENT DES HOMMES,,45.0440019083,0.253242646867,436,,Saint-Laurent-des-Hommes,Saint-Laurent-des-Hommes,24,Dordogne,75,Nouvelle-Aquitaine
24437,ST LAURENT DES VIGNES,24100,ST LAURENT DES VIGNES,,44.823708986,0.445904004567,437,,Saint-Laurent-des-Vignes,Saint-Laurent-des-Vignes,24,Dordogne,75,Nouvelle-Aquitaine
24438,ST LAURENT LA VALLEE,24170,ST LAURENT LA VALLEE,,44.7515526653,1.11564221671,438,,Saint-Laurent-la-Vallée,Saint-Laurent-la-Vallée,24,Dordogne,75,Nouvelle-Aquitaine
24441,ST LEON D ISSIGEAC,24560,ST LEON D ISSIGEAC,,44.7210156479,0.699300856862,441,,Saint-Léon-d'Issigeac,Saint-Léon-d'Issigeac,24,Dordogne,75,Nouvelle-Aquitaine
24442,ST LEON SUR L ISLE,24110,ST LEON SUR L ISLE,,45.1169557791,0.49957697448,442,,Saint-Léon-sur-l'Isle,Saint-Léon-sur-l'Isle,24,Dordogne,75,Nouvelle-Aquitaine
24443,ST LEON SUR VEZERE,24290,ST LEON SUR VEZERE,,45.0180206005,1.08035107771,443,,Saint-Léon-sur-Vézère,Saint-Léon-sur-Vézère,24,Dordogne,75,Nouvelle-Aquitaine
24444,ST LOUIS EN L ISLE,24400,ST LOUIS EN L ISLE,,45.0621473038,0.388564917962,444,,Saint-Louis-en-l'Isle,Saint-Louis-en-l'Isle,24,Dordogne,75,Nouvelle-Aquitaine
24445,ST MARCEL DU PERIGORD,24510,ST MARCEL DU PERIGORD,,44.916346803,0.703469495464,445,,Saint-Marcel-du-Périgord,Saint-Marcel-du-Périgord,24,Dordogne,75,Nouvelle-Aquitaine
24446,ST MARCORY,24540,ST MARCORY,,44.7229809189,0.927966166506,446,,Saint-Marcory,Saint-Marcory,24,Dordogne,75,Nouvelle-Aquitaine
24447,STE MARIE DE CHIGNAC,24330,STE MARIE DE CHIGNAC,,45.1154835454,0.825518871437,447,,Sainte-Marie-de-Chignac,Sainte-Marie-de-Chignac,24,Dordogne,75,Nouvelle-Aquitaine
24448,ST MARTIAL D ALBAREDE,24160,ST MARTIAL D ALBAREDE,,45.3214362718,1.03713768464,448,,Saint-Martial-d'Albarède,Saint-Martial-d'Albarède,24,Dordogne,75,Nouvelle-Aquitaine
24449,ST MARTIAL D ARTENSET,24700,ST MARTIAL D ARTENSET,,45.0016039267,0.217039600164,449,,Saint-Martial-d'Artenset,Saint-Martial-d'Artenset,24,Dordogne,75,Nouvelle-Aquitaine
24450,ST MARTIAL DE NABIRAT,24250,ST MARTIAL DE NABIRAT,,44.7473185081,1.24789673551,450,,Saint-Martial-de-Nabirat,Saint-Martial-de-Nabirat,24,Dordogne,75,Nouvelle-Aquitaine
24451,ST MARTIAL DE VALETTE,24300,ST MARTIAL DE VALETTE,,45.5124949186,0.633563294913,451,,Saint-Martial-de-Valette,Saint-Martial-de-Valette,24,Dordogne,75,Nouvelle-Aquitaine
24452,ST MARTIAL VIVEYROL,24320,ST MARTIAL VIVEYROL,,45.3606582227,0.336407219846,452,,Saint-Martial-Viveyrol,Saint-Martial-Viveyrol,24,Dordogne,75,Nouvelle-Aquitaine
24453,ST MARTIN DE FRESSENGEAS,24800,ST MARTIN DE FRESSENGEAS,,45.4533715908,0.841754032741,453,,Saint-Martin-de-Fressengeas,Saint-Martin-de-Fressengeas,24,Dordogne,75,Nouvelle-Aquitaine
24454,ST MARTIN DE GURSON,24610,ST MARTIN DE GURSON,,44.9613529177,0.116208017065,454,,Saint-Martin-de-Gurson,Saint-Martin-de-Gurson,24,Dordogne,75,Nouvelle-Aquitaine
24455,ST MARTIN DE RIBERAC,24600,ST MARTIN DE RIBERAC,,45.2201729451,0.36189635056,455,,Saint-Martin-de-Ribérac,Saint-Martin-de-Ribérac,24,Dordogne,75,Nouvelle-Aquitaine
24456,ST MARTIN DES COMBES,24140,ST MARTIN DES COMBES,,44.9646004591,0.620912139417,456,,Saint-Martin-des-Combes,Saint-Martin-des-Combes,24,Dordogne,75,Nouvelle-Aquitaine
24457,ST MARTIN L ASTIER,24400,ST MARTIN L ASTIER,,45.0589553311,0.323281642748,457,,Saint-Martin-l'Astier,Saint-Martin-l'Astier,24,Dordogne,75,Nouvelle-Aquitaine
24458,ST MARTIN LE PIN,24300,ST MARTIN LE PIN,,45.552556707,0.625868911507,458,,Saint-Martin-le-Pin,Saint-Martin-le-Pin,24,Dordogne,75,Nouvelle-Aquitaine
24459,ST MAIME DE PEREYROL,24380,ST MAIME DE PEREYROL,,45.019275797,0.654801325879,459,,Saint-Maime-de-Péreyrol,Saint-Maime-de-Péreyrol,24,Dordogne,75,Nouvelle-Aquitaine
24460,ST MEARD DE DRONE,24600,ST MEARD DE DRONE,,45.2508643912,0.40995292242,460,,Saint-Méard-de-Drône,Saint-Méard-de-Drône,24,Dordogne,75,Nouvelle-Aquitaine
24461,ST MEARD DE GURCON,24610,ST MEARD DE GURCON,,44.9112714239,0.177925010262,461,,Saint-Méard-de-Gurçon,Saint-Méard-de-Gurçon,24,Dordogne,75,Nouvelle-Aquitaine
24462,ST MEDARD DE MUSSIDAN,24400,ST MEDARD DE MUSSIDAN,,45.0220026441,0.331868096414,462,,Saint-Médard-de-Mussidan,Saint-Médard-de-Mussidan,24,Dordogne,75,Nouvelle-Aquitaine
24463,ST MEDARD D EXCIDEUIL,24160,ST MEDARD D EXCIDEUIL,,45.3470023438,1.0861340351,463,,Saint-Médard-d'Excideuil,Saint-Médard-d'Excideuil,24,Dordogne,75,Nouvelle-Aquitaine
24464,ST MESMIN,24270,ST MESMIN,,45.3466363763,1.23648515355,464,,Saint-Mesmin,Saint-Mesmin,24,Dordogne,75,Nouvelle-Aquitaine
24465,ST MICHEL DE DOUBLE,24400,ST MICHEL DE DOUBLE,,45.0913598278,0.274915293207,465,,Saint-Michel-de-Double,Saint-Michel-de-Double,24,Dordogne,75,Nouvelle-Aquitaine
24466,ST MICHEL DE MONTAIGNE,24230,ST MICHEL DE MONTAIGNE,,44.8790451767,0.0249387583377,466,,Saint-Michel-de-Montaigne,Saint-Michel-de-Montaigne,24,Dordogne,75,Nouvelle-Aquitaine
24468,ST MICHEL DE VILLADEIX,24380,ST MICHEL DE VILLADEIX,,44.9924485985,0.731493013563,468,,Saint-Michel-de-Villadeix,Saint-Michel-de-Villadeix,24,Dordogne,75,Nouvelle-Aquitaine
24470,STE MONDANE,24370,STE MONDANE,,44.8354016232,1.34672272166,470,,Sainte-Mondane,Sainte-Mondane,24,Dordogne,75,Nouvelle-Aquitaine
24471,STE NATHALENE,24200,STE NATHALENE,,44.9135267404,1.28096434427,471,,Sainte-Nathalène,Sainte-Nathalène,24,Dordogne,75,Nouvelle-Aquitaine
24472,ST NEXANS,24520,ST NEXANS,,44.8061899952,0.550308411387,472,,Saint-Nexans,Saint-Nexans,24,Dordogne,75,Nouvelle-Aquitaine
24473,STE ORSE,24210,STE ORSE,,45.2054528162,1.07317374722,473,,Sainte-Orse,Sainte-Orse,24,Dordogne,75,Nouvelle-Aquitaine
24474,ST PANCRACE,24530,ST PANCRACE,,45.4239359504,0.665300930124,474,,Saint-Pancrace,Saint-Pancrace,24,Dordogne,75,Nouvelle-Aquitaine
24475,ST PANTALY D ANS,24640,ST PANTALY D ANS,,45.244899363,0.993977905861,475,,Saint-Pantaly-d'Ans,Saint-Pantaly-d'Ans,24,Dordogne,75,Nouvelle-Aquitaine
24476,ST PANTALY D EXCIDEUIL,24160,ST PANTALY D EXCIDEUIL,,45.31204529,1.01148957185,476,,Saint-Pantaly-d'Excideuil,Saint-Pantaly-d'Excideuil,24,Dordogne,75,Nouvelle-Aquitaine
24477,ST PARDOUX DE DRONE,24600,ST PARDOUX DE DRONE,,45.2231080483,0.419036833254,477,,Saint-Pardoux-de-Drône,Saint-Pardoux-de-Drône,24,Dordogne,75,Nouvelle-Aquitaine
24478,ST PARDOUX ET VIELVIC,24170,ST PARDOUX ET VIELVIC,,44.7703624875,0.96095833872,478,,Saint-Pardoux-et-Vielvic,Saint-Pardoux-et-Vielvic,24,Dordogne,75,Nouvelle-Aquitaine
24479,ST PARDOUX LA RIVIERE,24470,ST PARDOUX LA RIVIERE,,45.5030521403,0.748077099274,479,,Saint-Pardoux-la-Rivière,Saint-Pardoux-la-Rivière,24,Dordogne,75,Nouvelle-Aquitaine
24480,ST PAUL DE SERRE,24380,ST PAUL DE SERRE,,45.0809931657,0.633952317617,480,,Saint-Paul-de-Serre,Saint-Paul-de-Serre,24,Dordogne,75,Nouvelle-Aquitaine
24481,ST PAUL LA ROCHE,24800,ST PAUL LA ROCHE,,45.4833291569,0.989443506607,481,,Saint-Paul-la-Roche,Saint-Paul-la-Roche,24,Dordogne,75,Nouvelle-Aquitaine
24482,ST PAUL LIZONNE,24320,ST PAUL LIZONNE,,45.3169310321,0.283269437916,482,,Saint-Paul-Lizonne,Saint-Paul-Lizonne,24,Dordogne,75,Nouvelle-Aquitaine
24483,ST PERDOUX,24560,ST PERDOUX,,44.736661676,0.529957013225,483,,Saint-Perdoux,Saint-Perdoux,24,Dordogne,75,Nouvelle-Aquitaine
24484,ST PIERRE DE CHIGNAC,24330,ST PIERRE DE CHIGNAC,,45.110891602,0.863217911356,484,,Saint-Pierre-de-Chignac,Saint-Pierre-de-Chignac,24,Dordogne,75,Nouvelle-Aquitaine
24485,ST PIERRE DE COLE,24800,ST PIERRE DE COLE,,45.3807175566,0.807692228631,485,,Saint-Pierre-de-Côle,Saint-Pierre-de-Côle,24,Dordogne,75,Nouvelle-Aquitaine
24486,ST PIERRE DE FRUGIE,24450,ST PIERRE DE FRUGIE,,45.5841655969,1.00632128921,486,,Saint-Pierre-de-Frugie,Saint-Pierre-de-Frugie,24,Dordogne,75,Nouvelle-Aquitaine
24487,ST PIERRE D EYRAUD,24130,ST PIERRE D EYRAUD,,44.8735238216,0.322188451159,487,,Saint-Pierre-d'Eyraud,Saint-Pierre-d'Eyraud,24,Dordogne,75,Nouvelle-Aquitaine
24488,ST POMPONT,24170,ST POMPONT,,44.7148110231,1.13582118282,488,,Saint-Pompont,Saint-Pompont,24,Dordogne,75,Nouvelle-Aquitaine
24489,ST PRIEST LES FOUGERES,24450,ST PRIEST LES FOUGERES,,45.5411692729,1.02433031105,489,,Saint-Priest-les-Fougères,Saint-Priest-les-Fougères,24,Dordogne,75,Nouvelle-Aquitaine
24490,ST PRIVAT DES PRES,24410,ST PRIVAT DES PRES,,45.2186240618,0.192239513618,490,,Saint Privat en Périgord,Saint Privat en Périgord,24,Dordogne,75,Nouvelle-Aquitaine
24491,ST RABIER,24210,ST RABIER,,45.1748183664,1.14559506206,491,,Saint-Rabier,Saint-Rabier,24,Dordogne,75,Nouvelle-Aquitaine
24492,STE RADEGONDE,24560,STE RADEGONDE,,44.6845072022,0.677670644919,492,,Sainte-Radegonde,Sainte-Radegonde,24,Dordogne,75,Nouvelle-Aquitaine
24493,ST RAPHAEL,24160,ST RAPHAEL,,45.3040514722,1.07314023244,493,,Saint-Raphaël,Saint-Raphaël,24,Dordogne,75,Nouvelle-Aquitaine
24494,ST REMY,24700,ST REMY,,44.9555652213,0.179456869496,494,,Saint-Rémy,Saint-Rémy,24,Dordogne,75,Nouvelle-Aquitaine
24495,ST ROMAIN DE MONPAZIER,24540,ST ROMAIN DE MONPAZIER,,44.7199482027,0.882032615949,495,,Saint-Romain-de-Monpazier,Saint-Romain-de-Monpazier,24,Dordogne,75,Nouvelle-Aquitaine
24496,ST ROMAIN ET ST CLEMENT,24800,ST ROMAIN ET ST CLEMENT,,45.4243804635,0.872078702147,496,,Saint-Romain-et-Saint-Clément,Saint-Romain-et-Saint-Clément,24,Dordogne,75,Nouvelle-Aquitaine
24498,ST SAUD LACOUSSIERE,24470,ST SAUD LACOUSSIERE,,45.5365082688,0.839648874583,498,,Saint-Saud-Lacoussière,Saint-Saud-Lacoussière,24,Dordogne,75,Nouvelle-Aquitaine
24499,ST SAUVEUR,24520,ST SAUVEUR,,44.8734911381,0.583950004245,499,,Saint-Sauveur,Saint-Sauveur,24,Dordogne,75,Nouvelle-Aquitaine
24500,ST SAUVEUR LALANDE,24700,ST SAUVEUR LALANDE,,44.9770754792,0.252681840344,500,,Saint-Sauveur-Lalande,Saint-Sauveur-Lalande,24,Dordogne,75,Nouvelle-Aquitaine
24501,ST SEURIN DE PRATS,24230,ST SEURIN DE PRATS,,44.8322841,0.0683826988285,501,,Saint-Seurin-de-Prats,Saint-Seurin-de-Prats,24,Dordogne,75,Nouvelle-Aquitaine
24502,ST SEVERIN D ESTISSAC,24190,ST SEVERIN D ESTISSAC,,45.0508855162,0.473469569395,502,,Saint-Séverin-d'Estissac,Saint-Séverin-d'Estissac,24,Dordogne,75,Nouvelle-Aquitaine
24503,ST SULPICE DE MAREUIL,24340,ST SULPICE DE MAREUIL,,45.4663985969,0.521742525814,503,,Saint-Sulpice-de-Mareuil,Saint-Sulpice-de-Mareuil,24,Dordogne,75,Nouvelle-Aquitaine
24504,ST SULPICE DE ROUMAGNAC,24600,ST SULPICE DE ROUMAGNAC,,45.1997873183,0.396312149822,504,,Saint-Sulpice-de-Roumagnac,Saint-Sulpice-de-Roumagnac,24,Dordogne,75,Nouvelle-Aquitaine
24505,ST SULPICE D EXCIDEUIL,24800,ST SULPICE D EXCIDEUIL,,45.3955893974,1.01725751316,505,,Saint-Sulpice-d'Excideuil,Saint-Sulpice-d'Excideuil,24,Dordogne,75,Nouvelle-Aquitaine
24507,STE TRIE,24160,STE TRIE,,45.2931649261,1.21244167771,507,,Sainte-Trie,Sainte-Trie,24,Dordogne,75,Nouvelle-Aquitaine
24508,ST VICTOR,24350,ST VICTOR,,45.2628084047,0.442634663994,508,,Saint-Victor,Saint-Victor,24,Dordogne,75,Nouvelle-Aquitaine
24509,ST VINCENT DE CONNEZAC,24190,ST VINCENT DE CONNEZAC,,45.1654628762,0.392273102143,509,,Saint-Vincent-de-Connezac,Saint-Vincent-de-Connezac,24,Dordogne,75,Nouvelle-Aquitaine
24510,ST VINCENT DE COSSE,24220,ST VINCENT DE COSSE,,44.8391680454,1.10957506457,510,,Saint-Vincent-de-Cosse,Saint-Vincent-de-Cosse,24,Dordogne,75,Nouvelle-Aquitaine
24511,ST VINCENT JALMOUTIERS,24410,ST VINCENT JALMOUTIERS,,45.1841041729,0.193684078767,511,,Saint-Vincent-Jalmoutiers,Saint-Vincent-Jalmoutiers,24,Dordogne,75,Nouvelle-Aquitaine
24512,ST VINCENT LE PALUEL,24200,ST VINCENT LE PALUEL,,44.8833667708,1.27542467394,512,,Saint-Vincent-le-Paluel,Saint-Vincent-le-Paluel,24,Dordogne,75,Nouvelle-Aquitaine
24513,ST VINCENT SUR L ISLE,24420,ST VINCENT SUR L ISLE,,45.2416009556,0.899777757535,513,,Saint-Vincent-sur-l'Isle,Saint-Vincent-sur-l'Isle,24,Dordogne,75,Nouvelle-Aquitaine
24514,ST VIVIEN,24230,ST VIVIEN,,44.8935898149,0.103204434117,514,,Saint-Vivien,Saint-Vivien,24,Dordogne,75,Nouvelle-Aquitaine
24515,SALAGNAC,24160,SALAGNAC,,45.3155697873,1.21187713732,515,,Salagnac,Salagnac,24,Dordogne,75,Nouvelle-Aquitaine
24515,SALAGNAC,24160,SALAGNAC,CLAIRVIVRE,45.3155697873,1.21187713732,515,,Salagnac,Salagnac,24,Dordogne,75,Nouvelle-Aquitaine
24516,SALIGNAC EYVIGUES,24590,SALIGNAC EYVIGUES,,44.9451208201,1.35948434383,516,,Salignac-Eyvigues,Salignac-Eyvigues,24,Dordogne,75,Nouvelle-Aquitaine
24517,SALLES DE BELVES,24170,SALLES DE BELVES,,44.7165357238,0.998995067473,517,,Salles-de-Belvès,Salles-de-Belvès,24,Dordogne,75,Nouvelle-Aquitaine
24518,SALON,24380,SALON,,45.0281445298,0.774813216159,518,,Salon,Salon,24,Dordogne,75,Nouvelle-Aquitaine
24519,SARLANDE,24270,SARLANDE,,45.4503411384,1.11555486206,519,,Sarlande,Sarlande,24,Dordogne,75,Nouvelle-Aquitaine
24520,SARLAT LA CANEDA,24200,SARLAT LA CANEDA,,44.8983558068,1.2066486429,520,,Sarlat-la-Canéda,Sarlat-la-Canéda,24,Dordogne,75,Nouvelle-Aquitaine
24521,SARLIAC SUR L ISLE,24420,SARLIAC SUR L ISLE,,45.2398639331,0.866391236032,521,,Sarliac-sur-l'Isle,Sarliac-sur-l'Isle,24,Dordogne,75,Nouvelle-Aquitaine
24522,SARRAZAC,24800,SARRAZAC,,45.4378608397,1.0348457868,522,,Sarrazac,Sarrazac,24,Dordogne,75,Nouvelle-Aquitaine
24523,SAUSSIGNAC,24240,SAUSSIGNAC,,44.8026310555,0.320357088763,523,,Saussignac,Saussignac,24,Dordogne,75,Nouvelle-Aquitaine
24524,SAVIGNAC DE MIREMONT,24260,SAVIGNAC DE MIREMONT,,44.9640593259,0.946655287926,524,,Savignac-de-Miremont,Savignac-de-Miremont,24,Dordogne,75,Nouvelle-Aquitaine
24525,SAVIGNAC DE NONTRON,24300,SAVIGNAC DE NONTRON,,45.5479530134,0.718268014166,525,,Savignac-de-Nontron,Savignac-de-Nontron,24,Dordogne,75,Nouvelle-Aquitaine
24526,SAVIGNAC LEDRIER,24270,SAVIGNAC LEDRIER,,45.3819993943,1.19868234314,526,,Savignac-Lédrier,Savignac-Lédrier,24,Dordogne,75,Nouvelle-Aquitaine
24527,SAVIGNAC LES EGLISES,24420,SAVIGNAC LES EGLISES,,45.2753181041,0.911755704648,527,,Savignac-les-Églises,Savignac-les-Églises,24,Dordogne,75,Nouvelle-Aquitaine
24528,SCEAU ST ANGEL,24300,SCEAU ST ANGEL,,45.4836989525,0.673994579504,528,,Sceau-Saint-Angel,Sceau-Saint-Angel,24,Dordogne,75,Nouvelle-Aquitaine
24529,SEGONZAC,24600,SEGONZAC,,45.2018222099,0.438933354109,529,,Segonzac,Segonzac,24,Dordogne,75,Nouvelle-Aquitaine
24530,SENCENAC PUY DE FOURCHES,24310,SENCENAC PUY DE FOURCHES,,45.315549526,0.681686845317,530,,Sencenac-Puy-de-Fourches,Sencenac-Puy-de-Fourches,24,Dordogne,75,Nouvelle-Aquitaine
24531,SERGEAC,24290,SERGEAC,,44.9987285651,1.11549602522,531,,Sergeac,Sergeac,24,Dordogne,75,Nouvelle-Aquitaine
24532,SERRES ET MONTGUYARD,24500,SERRES ET MONTGUYARD,,44.6757898351,0.44420320019,532,,Serres-et-Montguyard,Serres-et-Montguyard,24,Dordogne,75,Nouvelle-Aquitaine
24533,SERVANCHES,24410,SERVANCHES,,45.1333452116,0.164750778267,533,,Servanches,Servanches,24,Dordogne,75,Nouvelle-Aquitaine
24534,SIGOULES,24240,SIGOULES,,44.757893297,0.39899735625,534,,Sigoulès,Sigoulès,24,Dordogne,75,Nouvelle-Aquitaine
24535,SIMEYROLS,24370,SIMEYROLS,,44.9148518315,1.33870597483,535,,Simeyrols,Simeyrols,24,Dordogne,75,Nouvelle-Aquitaine
24536,SINGLEYRAC,24500,SINGLEYRAC,,44.7349910503,0.462492025992,536,,Singleyrac,Singleyrac,24,Dordogne,75,Nouvelle-Aquitaine
24537,SIORAC DE RIBERAC,24600,SIORAC DE RIBERAC,,45.1835928036,0.346380237367,537,,Siorac-de-Ribérac,Siorac-de-Ribérac,24,Dordogne,75,Nouvelle-Aquitaine
24538,SIORAC EN PERIGORD,24170,SIORAC EN PERIGORD,,44.8168407105,0.979352621358,538,,Siorac-en-Périgord,Siorac-en-Périgord,24,Dordogne,75,Nouvelle-Aquitaine
24540,SORGES ET LIGUEUX EN PERIGORD,24420,SORGES ET LIGUEUX EN PERIGORD,,45.2917072354,0.84997318452,540,,Sorges et Ligueux en Périgord,Sorges et Ligueux en Périgord,24,Dordogne,75,Nouvelle-Aquitaine
24540,SORGES ET LIGUEUX EN PERIGORD,24460,SORGES ET LIGUEUX EN PERIGORD,LIGUEUX,45.2917072354,0.84997318452,540,,Sorges et Ligueux en Périgord,Sorges et Ligueux en Périgord,24,Dordogne,75,Nouvelle-Aquitaine
24541,SOUDAT,24360,SOUDAT,,45.6218935044,0.567963968853,541,,Soudat,Soudat,24,Dordogne,75,Nouvelle-Aquitaine
24542,SOULAURES,24540,SOULAURES,,44.6413390052,0.914860213402,542,,Soulaures,Soulaures,24,Dordogne,75,Nouvelle-Aquitaine
24543,SOURZAC,24400,SOURZAC,,45.0492143458,0.415407786838,543,,Sourzac,Sourzac,24,Dordogne,75,Nouvelle-Aquitaine
24544,TAMNIES,24620,TAMNIES,,44.9766760202,1.14965103627,544,,Tamniès,Tamniès,24,Dordogne,75,Nouvelle-Aquitaine
24545,TEILLOTS,24390,TEILLOTS,,45.2592697088,1.21947483225,545,,Teillots,Teillots,24,Dordogne,75,Nouvelle-Aquitaine
24546,TEMPLE LAGUYON,24390,TEMPLE LAGUYON,,45.231240965,1.09729455909,546,,Temple-Laguyon,Temple-Laguyon,24,Dordogne,75,Nouvelle-Aquitaine
24547,TERRASSON LAVILLEDIEU,24120,TERRASSON LAVILLEDIEU,,45.1181463598,1.29916463367,547,,Terrasson-Lavilledieu,Terrasson-Lavilledieu,24,Dordogne,75,Nouvelle-Aquitaine
24548,TEYJAT,24300,TEYJAT,,45.5905740595,0.579549924927,548,,Teyjat,Teyjat,24,Dordogne,75,Nouvelle-Aquitaine
24549,THENAC,24240,THENAC,,44.7458412342,0.354889717281,549,,Thénac,Thénac,24,Dordogne,75,Nouvelle-Aquitaine
24549,THENAC,24240,THENAC,MONBOS,44.7458412342,0.354889717281,549,,Thénac,Thénac,24,Dordogne,75,Nouvelle-Aquitaine
24549,THENAC,24240,THENAC,PUYGUILHEM,44.7458412342,0.354889717281,549,,Thénac,Thénac,24,Dordogne,75,Nouvelle-Aquitaine
24550,THENON,24210,THENON,,45.1324103618,1.06369846915,550,,Thenon,Thenon,24,Dordogne,75,Nouvelle-Aquitaine
24551,THIVIERS,24800,THIVIERS,,45.4286304056,0.912184321254,551,,Thiviers,Thiviers,24,Dordogne,75,Nouvelle-Aquitaine
24552,THONAC,24290,THONAC,,45.0373590317,1.10562801671,552,,Thonac,Thonac,24,Dordogne,75,Nouvelle-Aquitaine
24553,TOCANE ST APRE,24350,TOCANE ST APRE,,45.2373278199,0.496959112951,553,,Tocane-Saint-Apre,Tocane-Saint-Apre,24,Dordogne,75,Nouvelle-Aquitaine
24554,LA TOUR BLANCHE,24320,LA TOUR BLANCHE,,45.3671927431,0.426955885949,554,La,Tour-Blanche-Cercles,La Tour-Blanche-Cercles,24,Dordogne,75,Nouvelle-Aquitaine
24555,TOURTOIRAC,24390,TOURTOIRAC,,45.2725889625,1.05559610435,555,,Tourtoirac,Tourtoirac,24,Dordogne,75,Nouvelle-Aquitaine
24557,TRELISSAC,24750,TRELISSAC,,45.2097381668,0.778416322658,557,,Trélissac,Trélissac,24,Dordogne,75,Nouvelle-Aquitaine
24558,TREMOLAT,24510,TREMOLAT,,44.874717216,0.833953321497,558,,Trémolat,Trémolat,24,Dordogne,75,Nouvelle-Aquitaine
24559,TURSAC,24620,TURSAC,,44.9720327079,1.04234396021,559,,Tursac,Tursac,24,Dordogne,75,Nouvelle-Aquitaine
24560,URVAL,24480,URVAL,,44.7991868804,0.940823739952,560,,Urval,Urval,24,Dordogne,75,Nouvelle-Aquitaine
24561,VALEUIL,24310,VALEUIL,,45.3279332402,0.628068409439,561,,Valeuil,Valeuil,24,Dordogne,75,Nouvelle-Aquitaine
24562,VALLEREUIL,24190,VALLEREUIL,,45.0715539505,0.499522594604,562,,Vallereuil,Vallereuil,24,Dordogne,75,Nouvelle-Aquitaine
24563,VALOJOULX,24290,VALOJOULX,,45.0168357588,1.14780270034,563,,Valojoulx,Valojoulx,24,Dordogne,75,Nouvelle-Aquitaine
24564,VANXAINS,24600,VANXAINS,,45.2121494971,0.28978883887,564,,Vanxains,Vanxains,24,Dordogne,75,Nouvelle-Aquitaine
24565,VARAIGNES,24360,VARAIGNES,,45.6108636989,0.530300494504,565,,Varaignes,Varaignes,24,Dordogne,75,Nouvelle-Aquitaine
24566,VARENNES,24150,VARENNES,,44.8341720876,0.668144001791,566,,Varennes,Varennes,24,Dordogne,75,Nouvelle-Aquitaine
24567,VAUNAC,24800,VAUNAC,,45.3698317098,0.867627664361,567,,Vaunac,Vaunac,24,Dordogne,75,Nouvelle-Aquitaine
24568,VELINES,24230,VELINES,,44.8569027334,0.112194568411,568,,Vélines,Vélines,24,Dordogne,75,Nouvelle-Aquitaine
24569,VENDOIRE,24320,VENDOIRE,,45.4123053163,0.288800712815,569,,Vendoire,Vendoire,24,Dordogne,75,Nouvelle-Aquitaine
24570,VERDON,24520,VERDON,,44.8143389523,0.630459003039,570,,Verdon,Verdon,24,Dordogne,75,Nouvelle-Aquitaine
24571,VERGT,24380,VERGT,,45.0414204199,0.710309790886,571,,Vergt,Vergt,24,Dordogne,75,Nouvelle-Aquitaine
24572,VERGT DE BIRON,24540,VERGT DE BIRON,,44.6363482611,0.84946470497,572,,Vergt-de-Biron,Vergt-de-Biron,24,Dordogne,75,Nouvelle-Aquitaine
24573,VERTEILLAC,24320,VERTEILLAC,,45.3499977855,0.376056089953,573,,Verteillac,Verteillac,24,Dordogne,75,Nouvelle-Aquitaine
24574,VEYRIGNAC,24370,VEYRIGNAC,,44.8228660534,1.32545209892,574,,Veyrignac,Veyrignac,24,Dordogne,75,Nouvelle-Aquitaine
24575,VEYRINES DE DOMME,24250,VEYRINES DE DOMME,,44.8003671958,1.10571535957,575,,Veyrines-de-Domme,Veyrines-de-Domme,24,Dordogne,75,Nouvelle-Aquitaine
24576,VEYRINES DE VERGT,24380,VEYRINES DE VERGT,,44.9902399091,0.77321349644,576,,Veyrines-de-Vergt,Veyrines-de-Vergt,24,Dordogne,75,Nouvelle-Aquitaine
24577,VEZAC,24220,VEZAC,,44.8405623629,1.17297806576,577,,Vézac,Vézac,24,Dordogne,75,Nouvelle-Aquitaine
24579,VIEUX MAREUIL,24340,VIEUX MAREUIL,,45.4339336677,0.501483953393,579,,Vieux-Mareuil,Vieux-Mareuil,24,Dordogne,75,Nouvelle-Aquitaine
24580,VILLAC,24120,VILLAC,,45.1842724664,1.24822097021,580,,Villac,Villac,24,Dordogne,75,Nouvelle-Aquitaine
24581,VILLAMBLARD,24140,VILLAMBLARD,,45.0273572281,0.557956026355,581,,Villamblard,Villamblard,24,Dordogne,75,Nouvelle-Aquitaine
24582,VILLARS,24530,VILLARS,,45.4222375236,0.766527343161,582,,Villars,Villars,24,Dordogne,75,Nouvelle-Aquitaine
24584,VILLEFRANCHE DE LONCHAT,24610,VILLEFRANCHE DE LONCHAT,,44.9555044342,0.0588917910628,584,,Villefranche-de-Lonchat,Villefranche-de-Lonchat,24,Dordogne,75,Nouvelle-Aquitaine
24585,VILLEFRANCHE DU PERIGORD,24550,VILLEFRANCHE DU PERIGORD,,44.6334322041,1.10745055962,585,,Villefranche-du-Périgord,Villefranche-du-Périgord,24,Dordogne,75,Nouvelle-Aquitaine
24586,VILLETOUREIX,24600,VILLETOUREIX,,45.2729009279,0.359083456128,586,,Villetoureix,Villetoureix,24,Dordogne,75,Nouvelle-Aquitaine
24587,VITRAC,24200,VITRAC,,44.8394527349,1.22440725926,587,,Vitrac,Vitrac,24,Dordogne,75,Nouvelle-Aquitaine
25001,ABBANS DESSOUS,25320,ABBANS DESSOUS,,47.1340668703,5.87286847337,1,,Abbans-Dessous,Abbans-Dessous,25,Doubs,27,Bourgogne-Franche-Comté
25002,ABBANS DESSUS,25440,ABBANS DESSUS,,47.1269966057,5.88626408283,2,,Abbans-Dessus,Abbans-Dessus,25,Doubs,27,Bourgogne-Franche-Comté
25003,ABBENANS,25340,ABBENANS,,47.4960879279,6.45060175112,3,,Abbenans,Abbenans,25,Doubs,27,Bourgogne-Franche-Comté
25004,ABBEVILLERS,25310,ABBEVILLERS,,47.427439192,6.92162186226,4,,Abbévillers,Abbévillers,25,Doubs,27,Bourgogne-Franche-Comté
25005,ACCOLANS,25250,ACCOLANS,,47.4906236179,6.53413561581,5,,Accolans,Accolans,25,Doubs,27,Bourgogne-Franche-Comté
25006,ADAM LES PASSAVANT,25360,ADAM LES PASSAVANT,,47.3045713356,6.36894975342,6,,Adam-lès-Passavant,Adam-lès-Passavant,25,Doubs,27,Bourgogne-Franche-Comté
25007,ADAM LES VERCEL,25530,ADAM LES VERCEL,,47.1623507424,6.39047378128,7,,Adam-lès-Vercel,Adam-lès-Vercel,25,Doubs,27,Bourgogne-Franche-Comté
25008,AIBRE,25750,AIBRE,,47.5518330621,6.69787276239,8,,Aibre,Aibre,25,Doubs,27,Bourgogne-Franche-Comté
25009,AISSEY,25360,AISSEY,,47.2699271702,6.32693976026,9,,Aïssey,Aïssey,25,Doubs,27,Bourgogne-Franche-Comté
25011,ALLENJOIE,25490,ALLENJOIE,,47.5354537745,6.8931467059,11,,Allenjoie,Allenjoie,25,Doubs,27,Bourgogne-Franche-Comté
25012,LES ALLIES,25300,LES ALLIES,,46.9491879536,6.44757027908,12,Les,Alliés,Les Alliés,25,Doubs,27,Bourgogne-Franche-Comté
25013,ALLONDANS,25550,ALLONDANS,,47.5223475188,6.75133390116,13,,Allondans,Allondans,25,Doubs,27,Bourgogne-Franche-Comté
25014,AMAGNEY,25220,AMAGNEY,,47.3053386559,6.14827312973,14,,Amagney,Amagney,25,Doubs,27,Bourgogne-Franche-Comté
25015,AMANCEY,25330,AMANCEY,,47.0311972081,6.07639327081,15,,Amancey,Amancey,25,Doubs,27,Bourgogne-Franche-Comté
25016,AMATHAY VESIGNEUX,25330,AMATHAY VESIGNEUX,,47.0231471184,6.19580927282,16,,Amathay-Vésigneux,Amathay-Vésigneux,25,Doubs,27,Bourgogne-Franche-Comté
25017,AMONDANS,25330,AMONDANS,,47.0715292841,6.03510768705,17,,Amondans,Amondans,25,Doubs,27,Bourgogne-Franche-Comté
25018,ANTEUIL,25340,ANTEUIL,,47.3758227575,6.57605269789,18,,Anteuil,Anteuil,25,Doubs,27,Bourgogne-Franche-Comté
25018,ANTEUIL,25340,ANTEUIL,GLAINANS,47.3758227575,6.57605269789,18,,Anteuil,Anteuil,25,Doubs,27,Bourgogne-Franche-Comté
25018,ANTEUIL,25340,ANTEUIL,TOURNEDOZ,47.3758227575,6.57605269789,18,,Anteuil,Anteuil,25,Doubs,27,Bourgogne-Franche-Comté
25019,APPENANS,25250,APPENANS,,47.4512206188,6.56926667052,19,,Appenans,Appenans,25,Doubs,27,Bourgogne-Franche-Comté
25020,ARBOUANS,25400,ARBOUANS,,47.4885179108,6.80807595575,20,,Arbouans,Arbouans,25,Doubs,27,Bourgogne-Franche-Comté
25021,ARC ET SENANS,25610,ARC ET SENANS,,47.0351046371,5.76978366413,21,,Arc-et-Senans,Arc-et-Senans,25,Doubs,27,Bourgogne-Franche-Comté
25022,ARCEY,25750,ARCEY,,47.5194914432,6.65186946515,22,,Arcey,Arcey,25,Doubs,27,Bourgogne-Franche-Comté
25024,ARCON,25300,ARCON,,46.9658473885,6.37870197364,24,,Arçon,Arçon,25,Doubs,27,Bourgogne-Franche-Comté
25025,ARC SOUS CICON,25520,ARC SOUS CICON,,47.052125982,6.3997146018,25,,Arc-sous-Cicon,Arc-sous-Cicon,25,Doubs,27,Bourgogne-Franche-Comté
25026,ARC SOUS MONTENOT,25270,ARC SOUS MONTENOT,,46.9151827645,6.00599288547,26,,Arc-sous-Montenot,Arc-sous-Montenot,25,Doubs,27,Bourgogne-Franche-Comté
25027,ARGUEL,25720,ARGUEL,,47.1842585224,6.00851020634,27,,Arguel,Arguel,25,Doubs,27,Bourgogne-Franche-Comté
25029,AUBONNE,25520,AUBONNE,,47.0367261474,6.34292904726,29,,Aubonne,Aubonne,25,Doubs,27,Bourgogne-Franche-Comté
25030,AUDEUX,25170,AUDEUX,,47.2584882134,5.8691323825,30,,Audeux,Audeux,25,Doubs,27,Bourgogne-Franche-Comté
25031,AUDINCOURT,25400,AUDINCOURT,,47.4811676376,6.85493983157,31,,Audincourt,Audincourt,25,Doubs,27,Bourgogne-Franche-Comté
25032,AUTECHAUX,25110,AUTECHAUX,,47.380865842,6.38532434702,32,,Autechaux,Autechaux,25,Doubs,27,Bourgogne-Franche-Comté
25033,AUTECHAUX ROIDE,25150,AUTECHAUX ROIDE,,47.3889980473,6.8102011305,33,,Autechaux-Roide,Autechaux-Roide,25,Doubs,27,Bourgogne-Franche-Comté
25035,LES AUXONS,25870,LES AUXONS,,47.3044311346,5.9681629274,35,Les,Auxons,Les Auxons,25,Doubs,27,Bourgogne-Franche-Comté
25035,LES AUXONS,25870,LES AUXONS,AUXON DESSOUS,47.3044311346,5.9681629274,35,Les,Auxons,Les Auxons,25,Doubs,27,Bourgogne-Franche-Comté
25036,AVANNE AVENEY,25720,AVANNE AVENEY,,47.2013939319,5.9554996988,36,,Avanne-Aveney,Avanne-Aveney,25,Doubs,27,Bourgogne-Franche-Comté
25036,AVANNE AVENEY,25720,AVANNE AVENEY,AVENEY,47.2013939319,5.9554996988,36,,Avanne-Aveney,Avanne-Aveney,25,Doubs,27,Bourgogne-Franche-Comté
25038,AVILLEY,25680,AVILLEY,,47.4289797534,6.26888707781,38,,Avilley,Avilley,25,Doubs,27,Bourgogne-Franche-Comté
25039,AVOUDREY,25690,AVOUDREY,,47.1384879691,6.43337259012,39,,Avoudrey,Avoudrey,25,Doubs,27,Bourgogne-Franche-Comté
25040,BADEVEL,25490,BADEVEL,,47.5014450746,6.93860867226,40,,Badevel,Badevel,25,Doubs,27,Bourgogne-Franche-Comté
25041,BANNANS,25560,BANNANS,,46.899147439,6.23727233424,41,,Bannans,Bannans,25,Doubs,27,Bourgogne-Franche-Comté
25042,LE BARBOUX,25210,LE BARBOUX,,47.1217397037,6.72580320278,42,Le,Barboux,Le Barboux,25,Doubs,27,Bourgogne-Franche-Comté
25043,BART,25420,BART,,47.4914667979,6.76751047027,43,,Bart,Bart,25,Doubs,27,Bourgogne-Franche-Comté
25044,BARTHERANS,25440,BARTHERANS,,47.03684296,5.9254921225,44,,Bartherans,Bartherans,25,Doubs,27,Bourgogne-Franche-Comté
25045,BATTENANS LES MINES,25640,BATTENANS LES MINES,,47.4052335763,6.27229432169,45,,Battenans-les-Mines,Battenans-les-Mines,25,Doubs,27,Bourgogne-Franche-Comté
25046,BATTENANS VARIN,25380,BATTENANS VARIN,,47.2421347156,6.71515082386,46,,Battenans-Varin,Battenans-Varin,25,Doubs,27,Bourgogne-Franche-Comté
25047,BAUME LES DAMES,25110,BAUME LES DAMES,,47.3544401776,6.34345370275,47,,Baume-les-Dames,Baume-les-Dames,25,Doubs,27,Bourgogne-Franche-Comté
25047,BAUME LES DAMES,25110,BAUME LES DAMES,CHAMPVANS LES BAUME,47.3544401776,6.34345370275,47,,Baume-les-Dames,Baume-les-Dames,25,Doubs,27,Bourgogne-Franche-Comté
25048,BAVANS,25550,BAVANS,,47.4835085219,6.72519340865,48,,Bavans,Bavans,25,Doubs,27,Bourgogne-Franche-Comté
25049,BELFAYS,25470,BELFAYS,,47.2593740587,6.90390396771,49,,Belfays,Belfays,25,Doubs,27,Bourgogne-Franche-Comté
25050,LE BELIEU,25500,LE BELIEU,,47.1215194397,6.63156586666,50,Le,Bélieu,Le Bélieu,25,Doubs,27,Bourgogne-Franche-Comté
25051,BELLEHERBE,25380,BELLEHERBE,,47.2636257295,6.65161515081,51,,Belleherbe,Belleherbe,25,Doubs,27,Bourgogne-Franche-Comté
25051,BELLEHERBE,25380,BELLEHERBE,DROITFONTAINE,47.2636257295,6.65161515081,51,,Belleherbe,Belleherbe,25,Doubs,27,Bourgogne-Franche-Comté
25052,BELMONT,25530,BELMONT,,47.2190028003,6.36198595062,52,,Belmont,Belmont,25,Doubs,27,Bourgogne-Franche-Comté
25053,BELVOIR,25430,BELVOIR,,47.3204509984,6.61674302233,53,,Belvoir,Belvoir,25,Doubs,27,Bourgogne-Franche-Comté
25054,BERCHE,25420,BERCHE,,47.4657374003,6.7470940732,54,,Berche,Berche,25,Doubs,27,Bourgogne-Franche-Comté
25055,BERTHELANGE,25410,BERTHELANGE,,47.2012632054,5.78031837648,55,,Berthelange,Berthelange,25,Doubs,27,Bourgogne-Franche-Comté
25056,BESANCON,25000,BESANCON,,47.2553872249,6.01948696494,56,,Besançon,Besançon,25,Doubs,27,Bourgogne-Franche-Comté
25057,BETHONCOURT,25200,BETHONCOURT,,47.5411126642,6.8003750959,57,,Bethoncourt,Bethoncourt,25,Doubs,27,Bourgogne-Franche-Comté
25058,BEURE,25720,BEURE,,47.2060766708,6.00526129105,58,,Beure,Beure,25,Doubs,27,Bourgogne-Franche-Comté
25059,BEUTAL,25250,BEUTAL,,47.4748011239,6.63576492619,59,,Beutal,Beutal,25,Doubs,27,Bourgogne-Franche-Comté
25060,BIANS LES USIERS,25520,BIANS LES USIERS,,46.9636846209,6.27269283538,60,,Bians-les-Usiers,Bians-les-Usiers,25,Doubs,27,Bourgogne-Franche-Comté
25061,BIEF,25190,BIEF,,47.3224089924,6.77498580034,61,,Bief,Bief,25,Doubs,27,Bourgogne-Franche-Comté
25062,LE BIZOT,25210,LE BIZOT,,47.1364585701,6.67291290642,62,Le,Bizot,Le Bizot,25,Doubs,27,Bourgogne-Franche-Comté
25063,BLAMONT,25310,BLAMONT,,47.3898085939,6.85552174846,63,,Blamont,Blamont,25,Doubs,27,Bourgogne-Franche-Comté
25065,BLARIANS,25640,BLARIANS,,47.4115548955,6.17767904595,65,,Blarians,Blarians,25,Doubs,27,Bourgogne-Franche-Comté
25066,BLUSSANGEAUX,25250,BLUSSANGEAUX,,47.4352037788,6.61831164486,66,,Blussangeaux,Blussangeaux,25,Doubs,27,Bourgogne-Franche-Comté
25067,BLUSSANS,25250,BLUSSANS,,47.4134789985,6.61025158511,67,,Blussans,Blussans,25,Doubs,27,Bourgogne-Franche-Comté
25070,BOLANDOZ,25330,BOLANDOZ,,47.0062281273,6.11491332446,70,,Bolandoz,Bolandoz,25,Doubs,27,Bourgogne-Franche-Comté
25071,BONDEVAL,25230,BONDEVAL,,47.4336532746,6.8448325548,71,,Bondeval,Bondeval,25,Doubs,27,Bourgogne-Franche-Comté
25072,BONNAL,25680,BONNAL,,47.5072386954,6.36538228844,72,,Bonnal,Bonnal,25,Doubs,27,Bourgogne-Franche-Comté
25073,BONNAY,25870,BONNAY,,47.3292152038,6.04469587885,73,,Bonnay,Bonnay,25,Doubs,27,Bourgogne-Franche-Comté
25074,BONNETAGE,25210,BONNETAGE,,47.1802952744,6.73897461794,74,,Bonnétage,Bonnétage,25,Doubs,27,Bourgogne-Franche-Comté
25075,BONNEVAUX,25560,BONNEVAUX,,46.8091056758,6.19702516593,75,,Bonnevaux,Bonnevaux,25,Doubs,27,Bourgogne-Franche-Comté
25077,LA BOSSE,25210,LA BOSSE,,47.1440495943,6.64869054384,77,La,Bosse,La Bosse,25,Doubs,27,Bourgogne-Franche-Comté
25078,BOUCLANS,25360,BOUCLANS,,47.2413584714,6.23301360703,78,,Bouclans,Bouclans,25,Doubs,27,Bourgogne-Franche-Comté
25079,BOUJAILLES,25560,BOUJAILLES,,46.8844605742,6.07920196701,79,,Boujailles,Boujailles,25,Doubs,27,Bourgogne-Franche-Comté
25082,BOURGUIGNON,25150,BOURGUIGNON,,47.4128203171,6.77408413997,82,,Bourguignon,Bourguignon,25,Doubs,27,Bourgogne-Franche-Comté
25083,BOURNOIS,25250,BOURNOIS,,47.4883948482,6.49638983869,83,,Bournois,Bournois,25,Doubs,27,Bourgogne-Franche-Comté
25084,BOUSSIERES,25320,BOUSSIERES,,47.1514507148,5.90144299782,84,,Boussières,Boussières,25,Doubs,27,Bourgogne-Franche-Comté
25085,BOUVERANS,25560,BOUVERANS,,46.8406087592,6.2111033425,85,,Bouverans,Bouverans,25,Doubs,27,Bourgogne-Franche-Comté
25086,BRAILLANS,25640,BRAILLANS,,47.3119102403,6.08660032243,86,,Braillans,Braillans,25,Doubs,27,Bourgogne-Franche-Comté
25087,BRANNE,25340,BRANNE,,47.3794678972,6.47979347027,87,,Branne,Branne,25,Doubs,27,Bourgogne-Franche-Comté
25088,BRECONCHAUX,25640,BRECONCHAUX,,47.3418237888,6.27061644005,88,,Breconchaux,Breconchaux,25,Doubs,27,Bourgogne-Franche-Comté
25089,BREMONDANS,25530,BREMONDANS,,47.231963581,6.3971630777,89,,Bremondans,Bremondans,25,Doubs,27,Bourgogne-Franche-Comté
25090,BRERES,25440,BRERES,,47.0577166935,5.86441681721,90,,Brères,Brères,25,Doubs,27,Bourgogne-Franche-Comté
25091,LES BRESEUX,25120,LES BRESEUX,,47.2746972567,6.80149118385,91,Les,Bréseux,Les Bréseux,25,Doubs,27,Bourgogne-Franche-Comté
25092,LA BRETENIERE,25640,LA BRETENIERE,,47.3774609296,6.27686840892,92,La,Bretenière,La Bretenière,25,Doubs,27,Bourgogne-Franche-Comté
25093,BRETIGNEY,25250,BRETIGNEY,,47.4867078503,6.63497624853,93,,Bretigney,Bretigney,25,Doubs,27,Bourgogne-Franche-Comté
25094,BRETIGNEY NOTRE DAME,25110,BRETIGNEY NOTRE DAME,,47.309289583,6.30363374981,94,,Bretigney-Notre-Dame,Bretigney-Notre-Dame,25,Doubs,27,Bourgogne-Franche-Comté
25095,BRETONVILLERS,25380,BRETONVILLERS,,47.2158579813,6.61718921929,95,,Bretonvillers,Bretonvillers,25,Doubs,27,Bourgogne-Franche-Comté
25096,BREY ET MAISON DU BOIS,25240,BREY ET MAISON DU BOIS,,46.7468077071,6.24254842118,96,,Brey-et-Maison-du-Bois,Brey-et-Maison-du-Bois,25,Doubs,27,Bourgogne-Franche-Comté
25097,BROGNARD,25600,BROGNARD,,47.5276797579,6.86515531674,97,,Brognard,Brognard,25,Doubs,27,Bourgogne-Franche-Comté
25098,BUFFARD,25440,BUFFARD,,47.0326137172,5.8283117131,98,,Buffard,Buffard,25,Doubs,27,Bourgogne-Franche-Comté
25099,BUGNY,25520,BUGNY,,46.9977736511,6.35684298467,99,,Bugny,Bugny,25,Doubs,27,Bourgogne-Franche-Comté
25100,BULLE,25560,BULLE,,46.9056438268,6.20178714478,100,,Bulle,Bulle,25,Doubs,27,Bourgogne-Franche-Comté
25101,BURGILLE,25170,BURGILLE,,47.2616950181,5.7819919512,101,,Burgille,Burgille,25,Doubs,27,Bourgogne-Franche-Comté
25101,BURGILLE,25170,BURGILLE,CHAZOY,47.2616950181,5.7819919512,101,,Burgille,Burgille,25,Doubs,27,Bourgogne-Franche-Comté
25101,BURGILLE,25170,BURGILLE,CORDIRON,47.2616950181,5.7819919512,101,,Burgille,Burgille,25,Doubs,27,Bourgogne-Franche-Comté
25102,BURNEVILLERS,25470,BURNEVILLERS,,47.3323007347,7.02071000712,102,,Burnevillers,Burnevillers,25,Doubs,27,Bourgogne-Franche-Comté
25103,BUSY,25320,BUSY,,47.1679810214,5.95431754442,103,,Busy,Busy,25,Doubs,27,Bourgogne-Franche-Comté
25104,BY,25440,BY,,47.0123694644,5.89880744574,104,,By,By,25,Doubs,27,Bourgogne-Franche-Comté
25105,BYANS SUR DOUBS,25320,BYANS SUR DOUBS,,47.1129773426,5.84743903869,105,,Byans-sur-Doubs,Byans-sur-Doubs,25,Doubs,27,Bourgogne-Franche-Comté
25106,CADEMENE,25290,CADEMENE,,47.095187126,6.02752897644,106,,Cademène,Cademène,25,Doubs,27,Bourgogne-Franche-Comté
25107,CENDREY,25640,CENDREY,,47.39870448,6.24551247571,107,,Cendrey,Cendrey,25,Doubs,27,Bourgogne-Franche-Comté
25108,CERNAY L EGLISE,25120,CERNAY L EGLISE,,47.2506553278,6.83349587828,108,,Cernay-l'Église,Cernay-l'Église,25,Doubs,27,Bourgogne-Franche-Comté
25109,CESSEY,25440,CESSEY,,47.1132588738,5.92330961673,109,,Cessey,Cessey,25,Doubs,27,Bourgogne-Franche-Comté
25110,CHAFFOIS,25300,CHAFFOIS,,46.9171603801,6.25633927954,110,,Chaffois,Chaffois,25,Doubs,27,Bourgogne-Franche-Comté
25111,CHALEZE,25220,CHALEZE,,47.261122307,6.09541799233,111,,Chalèze,Chalèze,25,Doubs,27,Bourgogne-Franche-Comté
25112,CHALEZEULE,25220,CHALEZEULE,,47.2566492293,6.0658783817,112,,Chalezeule,Chalezeule,25,Doubs,27,Bourgogne-Franche-Comté
25113,CHAMESEY,25380,CHAMESEY,,47.2367237059,6.63188874418,113,,Chamesey,Chamesey,25,Doubs,27,Bourgogne-Franche-Comté
25114,CHAMESOL,25190,CHAMESOL,,47.3463369115,6.84435970249,114,,Chamesol,Chamesol,25,Doubs,27,Bourgogne-Franche-Comté
25115,CHAMPAGNEY,25170,CHAMPAGNEY,,47.2553049807,5.89205974379,115,,Champagney,Champagney,25,Doubs,27,Bourgogne-Franche-Comté
25116,CHAMPLIVE,25360,CHAMPLIVE,,47.2883174221,6.24419443876,116,,Champlive,Champlive,25,Doubs,27,Bourgogne-Franche-Comté
25117,CHAMPOUX,25640,CHAMPOUX,,47.3407046094,6.1358095916,117,,Champoux,Champoux,25,Doubs,27,Bourgogne-Franche-Comté
25119,CHAMPVANS LES MOULINS,25170,CHAMPVANS LES MOULINS,,47.2566266106,5.90746432732,119,,Champvans-les-Moulins,Champvans-les-Moulins,25,Doubs,27,Bourgogne-Franche-Comté
25120,CHANTRANS,25330,CHANTRANS,,47.0477460774,6.16353019875,120,,Chantrans,Chantrans,25,Doubs,27,Bourgogne-Franche-Comté
25121,CHAPELLE DES BOIS,25240,CHAPELLE DES BOIS,,46.606672007,6.10775555362,121,,Chapelle-des-Bois,Chapelle-des-Bois,25,Doubs,27,Bourgogne-Franche-Comté
25122,CHAPELLE D HUIN,25270,CHAPELLE D HUIN,,46.9339958875,6.16570353934,122,,Chapelle-d'Huin,Chapelle-d'Huin,25,Doubs,27,Bourgogne-Franche-Comté
25123,CHARBONNIERES LES SAPINS,25620,CHARBONNIERES LES SAPINS,,47.1502515733,6.20783187059,123,,Charbonnières-les-Sapins,Charbonnières-les-Sapins,25,Doubs,27,Bourgogne-Franche-Comté
25124,CHARMAUVILLERS,25470,CHARMAUVILLERS,,47.2312832249,6.91715529579,124,,Charmauvillers,Charmauvillers,25,Doubs,27,Bourgogne-Franche-Comté
25125,CHARMOILLE,25380,CHARMOILLE,,47.2394562597,6.66850732349,125,,Charmoille,Charmoille,25,Doubs,27,Bourgogne-Franche-Comté
25126,CHARNAY,25440,CHARNAY,,47.128142281,5.95023128953,126,,Charnay,Charnay,25,Doubs,27,Bourgogne-Franche-Comté
25127,CHARQUEMONT,25140,CHARQUEMONT,,47.2015092122,6.84277738183,127,,Charquemont,Charquemont,25,Doubs,27,Bourgogne-Franche-Comté
25127,CHARQUEMONT,25140,CHARQUEMONT,LE BOULOIS,47.2015092122,6.84277738183,127,,Charquemont,Charquemont,25,Doubs,27,Bourgogne-Franche-Comté
25129,CHASSAGNE ST DENIS,25290,CHASSAGNE ST DENIS,,47.0837133795,6.11093691858,129,,Chassagne-Saint-Denis,Chassagne-Saint-Denis,25,Doubs,27,Bourgogne-Franche-Comté
25130,CHATEAUVIEUX LES FOSSES,25840,CHATEAUVIEUX LES FOSSES,,47.0577014117,6.20017409479,130,,Châteauvieux-les-Fossés,Châteauvieux-les-Fossés,25,Doubs,27,Bourgogne-Franche-Comté
25131,CHATELBLANC,25240,CHATELBLANC,,46.6650467334,6.10624023722,131,,Châtelblanc,Châtelblanc,25,Doubs,27,Bourgogne-Franche-Comté
25132,CHATILLON GUYOTTE,25640,CHATILLON GUYOTTE,,47.3279230073,6.16849759872,132,,Châtillon-Guyotte,Châtillon-Guyotte,25,Doubs,27,Bourgogne-Franche-Comté
25133,CHATILLON LE DUC,25870,CHATILLON LE DUC,,47.3060186922,6.00103214604,133,,Châtillon-le-Duc,Châtillon-le-Duc,25,Doubs,27,Bourgogne-Franche-Comté
25134,CHATILLON SUR LISON,25440,CHATILLON SUR LISON,,47.0727898717,5.98071309837,134,,Châtillon-sur-Lison,Châtillon-sur-Lison,25,Doubs,27,Bourgogne-Franche-Comté
25136,CHAUCENNE,25170,CHAUCENNE,,47.2844716693,5.89456296262,136,,Chaucenne,Chaucenne,25,Doubs,27,Bourgogne-Franche-Comté
25137,CHAUDEFONTAINE,25640,CHAUDEFONTAINE,,47.3459986661,6.1610867561,137,,Chaudefontaine,Chaudefontaine,25,Doubs,27,Bourgogne-Franche-Comté
25138,LES TERRES DE CHAUX,25190,LES TERRES DE CHAUX,,47.3166269465,6.72873465263,138,Les,Terres-de-Chaux,Les Terres-de-Chaux,25,Doubs,27,Bourgogne-Franche-Comté
25139,LA CHAUX,25650,LA CHAUX,,47.0192694963,6.41470158275,139,La,Chaux,La Chaux,25,Doubs,27,Bourgogne-Franche-Comté
25140,CHAUX LES CLERVAL,25340,CHAUX LES CLERVAL,,47.371588153,6.51577154602,140,,Chaux-lès-Clerval,Chaux-lès-Clerval,25,Doubs,27,Bourgogne-Franche-Comté
25141,CHAUX LES PASSAVANT,25530,CHAUX LES PASSAVANT,,47.23589775,6.35007228666,141,,Chaux-lès-Passavant,Chaux-lès-Passavant,25,Doubs,27,Bourgogne-Franche-Comté
25142,CHAUX NEUVE,25240,CHAUX NEUVE,,46.6555343865,6.15249971809,142,,Chaux-Neuve,Chaux-Neuve,25,Doubs,27,Bourgogne-Franche-Comté
25143,CHAY,25440,CHAY,,47.0335238576,5.86151030657,143,,Chay,Chay,25,Doubs,27,Bourgogne-Franche-Comté
25145,CHAZOT,25430,CHAZOT,,47.328216535,6.53902040431,145,,Chazot,Chazot,25,Doubs,27,Bourgogne-Franche-Comté
25147,CHEMAUDIN,25320,CHEMAUDIN,,47.2179705804,5.89261031415,147,,Chemaudin et Vaux,Chemaudin et Vaux,25,Doubs,27,Bourgogne-Franche-Comté
25148,LA CHENALOTTE,25500,LA CHENALOTTE,,47.1081527318,6.68735425042,148,La,Chenalotte,La Chenalotte,25,Doubs,27,Bourgogne-Franche-Comté
25149,CHENECEY BUILLON,25440,CHENECEY BUILLON,,47.138479482,5.97528493565,149,,Chenecey-Buillon,Chenecey-Buillon,25,Doubs,27,Bourgogne-Franche-Comté
25150,CHEVIGNEY SUR L OGNON,25170,CHEVIGNEY SUR L OGNON,,47.293137629,5.84651506697,150,,Chevigney-sur-l'Ognon,Chevigney-sur-l'Ognon,25,Doubs,27,Bourgogne-Franche-Comté
25151,CHEVIGNEY LES VERCEL,25530,CHEVIGNEY LES VERCEL,,47.1651371592,6.36768229649,151,,Chevigney-lès-Vercel,Chevigney-lès-Vercel,25,Doubs,27,Bourgogne-Franche-Comté
25152,LA CHEVILLOTTE,25620,LA CHEVILLOTTE,,47.2203448918,6.15732727631,152,La,Chevillotte,La Chevillotte,25,Doubs,27,Bourgogne-Franche-Comté
25153,CHEVROZ,25870,CHEVROZ,,47.3313541877,5.99948362535,153,,Chevroz,Chevroz,25,Doubs,27,Bourgogne-Franche-Comté
25154,CHOUZELOT,25440,CHOUZELOT,,47.1232109749,5.9086321902,154,,Chouzelot,Chouzelot,25,Doubs,27,Bourgogne-Franche-Comté
25155,CLERON,25330,CLERON,,47.0815649921,6.07062488637,155,,Cléron,Cléron,25,Doubs,27,Bourgogne-Franche-Comté
25156,CLERVAL,25340,CLERVAL,,47.3991705551,6.50259246766,156,,Pays de Clerval,Pays de Clerval,25,Doubs,27,Bourgogne-Franche-Comté
25157,LA CLUSE ET MIJOUX,25300,LA CLUSE ET MIJOUX,,46.8683471293,6.39291993544,157,La,Cluse-et-Mijoux,La Cluse-et-Mijoux,25,Doubs,27,Bourgogne-Franche-Comté
25159,COLOMBIER FONTAINE,25260,COLOMBIER FONTAINE,,47.4473299299,6.69006728254,159,,Colombier-Fontaine,Colombier-Fontaine,25,Doubs,27,Bourgogne-Franche-Comté
25160,LES COMBES,25500,LES COMBES,,47.0470386207,6.53657573654,160,Les,Combes,Les Combes,25,Doubs,27,Bourgogne-Franche-Comté
25161,CONSOLATION MAISONNETTES,25390,CONSOLATION MAISONNETTES,,47.1545771077,6.60275781844,161,,Consolation-Maisonnettes,Consolation-Maisonnettes,25,Doubs,27,Bourgogne-Franche-Comté
25162,CORCELLES FERRIERES,25410,CORCELLES FERRIERES,,47.2266648284,5.80424536213,162,,Corcelles-Ferrières,Corcelles-Ferrières,25,Doubs,27,Bourgogne-Franche-Comté
25163,CORCELLE MIESLOT,25640,CORCELLE MIESLOT,,47.3615588639,6.18318655579,163,,Corcelle-Mieslot,Corcelle-Mieslot,25,Doubs,27,Bourgogne-Franche-Comté
25164,CORCONDRAY,25410,CORCONDRAY,,47.2259805171,5.8274819544,164,,Corcondray,Corcondray,25,Doubs,27,Bourgogne-Franche-Comté
25166,COTEBRUNE,25360,COTEBRUNE,,47.251986988,6.31062707998,166,,Côtebrune,Côtebrune,25,Doubs,27,Bourgogne-Franche-Comté
25170,COURCELLES LES MONTBELIARD,25420,COURCELLES LES MONTBELIARD,,47.4947051288,6.78554896767,170,,Courcelles-lès-Montbéliard,Courcelles-lès-Montbéliard,25,Doubs,27,Bourgogne-Franche-Comté
25171,COURCELLES,25440,COURCELLES,,47.1007094404,5.95901905679,171,,Courcelles,Courcelles,25,Doubs,27,Bourgogne-Franche-Comté
25172,COURCHAPON,25170,COURCHAPON,,47.2583713466,5.75169248956,172,,Courchapon,Courchapon,25,Doubs,27,Bourgogne-Franche-Comté
25173,COUR ST MAURICE,25380,COUR ST MAURICE,,47.2638180328,6.70989733057,173,,Cour-Saint-Maurice,Cour-Saint-Maurice,25,Doubs,27,Bourgogne-Franche-Comté
25174,COURTEFONTAINE,25470,COURTEFONTAINE,,47.3214778627,6.91192058012,174,,Courtefontaine,Courtefontaine,25,Doubs,27,Bourgogne-Franche-Comté
25175,COURTETAIN ET SALANS,25530,COURTETAIN ET SALANS,,47.2598307803,6.426501842,175,,Courtetain-et-Salans,Courtetain-et-Salans,25,Doubs,27,Bourgogne-Franche-Comté
25176,COURVIERES,25560,COURVIERES,,46.8640134656,6.09939604174,176,,Courvières,Courvières,25,Doubs,27,Bourgogne-Franche-Comté
25177,CROSEY LE GRAND,25340,CROSEY LE GRAND,,47.3472420831,6.52726297298,177,,Crosey-le-Grand,Crosey-le-Grand,25,Doubs,27,Bourgogne-Franche-Comté
25178,CROSEY LE PETIT,25340,CROSEY LE PETIT,,47.3425794838,6.48466014508,178,,Crosey-le-Petit,Crosey-le-Petit,25,Doubs,27,Bourgogne-Franche-Comté
25179,LE CROUZET,25240,LE CROUZET,,46.7066820687,6.13160874463,179,Le,Crouzet,Le Crouzet,25,Doubs,27,Bourgogne-Franche-Comté
25180,CROUZET MIGETTE,25270,CROUZET MIGETTE,,46.9567035839,6.01221278274,180,,Crouzet-Migette,Crouzet-Migette,25,Doubs,27,Bourgogne-Franche-Comté
25181,CUBRIAL,25680,CUBRIAL,,47.5040745898,6.40318225414,181,,Cubrial,Cubrial,25,Doubs,27,Bourgogne-Franche-Comté
25182,CUBRY,25680,CUBRY,,47.4984298197,6.42360175883,182,,Cubry,Cubry,25,Doubs,27,Bourgogne-Franche-Comté
25183,CUSANCE,25110,CUSANCE,,47.322634572,6.43863811319,183,,Cusance,Cusance,25,Doubs,27,Bourgogne-Franche-Comté
25184,CUSE ET ADRISANS,25680,CUSE ET ADRISANS,,47.4833066926,6.38838690365,184,,Cuse-et-Adrisans,Cuse-et-Adrisans,25,Doubs,27,Bourgogne-Franche-Comté
25185,CUSSEY SUR LISON,25440,CUSSEY SUR LISON,,47.0622363983,5.95767179131,185,,Cussey-sur-Lison,Cussey-sur-Lison,25,Doubs,27,Bourgogne-Franche-Comté
25186,CUSSEY SUR L OGNON,25870,CUSSEY SUR L OGNON,,47.3277456972,5.94001490559,186,,Cussey-sur-l'Ognon,Cussey-sur-l'Ognon,25,Doubs,27,Bourgogne-Franche-Comté
25187,DAMBELIN,25150,DAMBELIN,,47.3691799616,6.66774947853,187,,Dambelin,Dambelin,25,Doubs,27,Bourgogne-Franche-Comté
25187,DAMBELIN,25150,DAMBELIN,MAMBOUHANS,47.3691799616,6.66774947853,187,,Dambelin,Dambelin,25,Doubs,27,Bourgogne-Franche-Comté
25188,DAMBENOIS,25600,DAMBENOIS,,47.5444605529,6.87199682255,188,,Dambenois,Dambenois,25,Doubs,27,Bourgogne-Franche-Comté
25189,DAMMARTIN LES TEMPLIERS,25110,DAMMARTIN LES TEMPLIERS,,47.2933253789,6.28044164723,189,,Dammartin-les-Templiers,Dammartin-les-Templiers,25,Doubs,27,Bourgogne-Franche-Comté
25190,DAMPIERRE LES BOIS,25490,DAMPIERRE LES BOIS,,47.5074760402,6.91564600074,190,,Dampierre-les-Bois,Dampierre-les-Bois,25,Doubs,27,Bourgogne-Franche-Comté
25191,DAMPIERRE SUR LE DOUBS,25420,DAMPIERRE SUR LE DOUBS,,47.4671316033,6.73247135542,191,,Dampierre-sur-le-Doubs,Dampierre-sur-le-Doubs,25,Doubs,27,Bourgogne-Franche-Comté
25192,DAMPJOUX,25190,DAMPJOUX,,47.3387302294,6.74818152982,192,,Dampjoux,Dampjoux,25,Doubs,27,Bourgogne-Franche-Comté
25193,DAMPRICHARD,25450,DAMPRICHARD,,47.2378466799,6.8737017298,193,,Damprichard,Damprichard,25,Doubs,27,Bourgogne-Franche-Comté
25194,DANNEMARIE,25310,DANNEMARIE,,47.3889972456,6.9000175163,194,,Dannemarie,Dannemarie,25,Doubs,27,Bourgogne-Franche-Comté
25195,DANNEMARIE SUR CRETE,25410,DANNEMARIE SUR CRETE,,47.2062426754,5.86495393288,195,,Dannemarie-sur-Crète,Dannemarie-sur-Crète,25,Doubs,27,Bourgogne-Franche-Comté
25196,DASLE,25230,DASLE,,47.4824687942,6.89668230526,196,,Dasle,Dasle,25,Doubs,27,Bourgogne-Franche-Comté
25197,DELUZ,25960,DELUZ,,47.2971963372,6.19334216229,197,,Deluz,Deluz,25,Doubs,27,Bourgogne-Franche-Comté
25198,DESANDANS,25750,DESANDANS,,47.5387786208,6.67184461827,198,,Désandans,Désandans,25,Doubs,27,Bourgogne-Franche-Comté
25199,DESERVILLERS,25330,DESERVILLERS,,46.999299694,6.0729610264,199,,Déservillers,Déservillers,25,Doubs,27,Bourgogne-Franche-Comté
25200,DEVECEY,25870,DEVECEY,,47.3240979046,6.01735548201,200,,Devecey,Devecey,25,Doubs,27,Bourgogne-Franche-Comté
25201,DOMMARTIN,25300,DOMMARTIN,,46.9289461841,6.30964767556,201,,Dommartin,Dommartin,25,Doubs,27,Bourgogne-Franche-Comté
25202,DOMPIERRE LES TILLEULS,25560,DOMPIERRE LES TILLEULS,,46.8866090769,6.16384701034,202,,Dompierre-les-Tilleuls,Dompierre-les-Tilleuls,25,Doubs,27,Bourgogne-Franche-Comté
25203,DOMPREL,25510,DOMPREL,,47.1975252218,6.4793837741,203,,Domprel,Domprel,25,Doubs,27,Bourgogne-Franche-Comté
25204,DOUBS,25300,DOUBS,,46.9311755655,6.35996491031,204,,Doubs,Doubs,25,Doubs,27,Bourgogne-Franche-Comté
25207,DUNG,25550,DUNG,,47.5064924582,6.7452490029,207,,Dung,Dung,25,Doubs,27,Bourgogne-Franche-Comté
25208,DURNES,25580,DURNES,,47.1065731832,6.22362576213,208,,Durnes,Durnes,25,Doubs,27,Bourgogne-Franche-Comté
25209,ECHAY,25440,ECHAY,,47.0470107099,5.94984491488,209,,Échay,Échay,25,Doubs,27,Bourgogne-Franche-Comté
25210,ECHENANS,25550,ECHENANS,,47.527383331,6.69266176707,210,,Échenans,Échenans,25,Doubs,27,Bourgogne-Franche-Comté
25211,ECHEVANNES,25580,ECHEVANNES,,47.0707841808,6.23691834165,211,,Échevannes,Échevannes,25,Doubs,27,Bourgogne-Franche-Comté
25212,ECOLE VALENTIN,25480,ECOLE VALENTIN,,47.2744126676,5.98881788092,212,,École-Valentin,École-Valentin,25,Doubs,27,Bourgogne-Franche-Comté
25212,ECOLE VALENTIN,25480,ECOLE VALENTIN,VALENTIN,47.2744126676,5.98881788092,212,,École-Valentin,École-Valentin,25,Doubs,27,Bourgogne-Franche-Comté
25213,LES ECORCES,25140,LES ECORCES,,47.2036110847,6.79732970212,213,Les,Écorces,Les Écorces,25,Doubs,27,Bourgogne-Franche-Comté
25214,ECOT,25150,ECOT,,47.4230386547,6.73233189748,214,,Écot,Écot,25,Doubs,27,Bourgogne-Franche-Comté
25215,L ECOUVOTTE,25640,L ECOUVOTTE,,47.3436819821,6.25275354958,215,L',Écouvotte,L'Écouvotte,25,Doubs,27,Bourgogne-Franche-Comté
25216,ECURCEY,25150,ECURCEY,,47.4031119157,6.8050274649,216,,Écurcey,Écurcey,25,Doubs,27,Bourgogne-Franche-Comté
25217,EMAGNY,25170,EMAGNY,,47.3039067497,5.86864846171,217,,Émagny,Émagny,25,Doubs,27,Bourgogne-Franche-Comté
25218,EPENOUSE,25530,EPENOUSE,,47.2134260446,6.39751646264,218,,Épenouse,Épenouse,25,Doubs,27,Bourgogne-Franche-Comté
25219,EPENOY,25800,EPENOY,,47.1339254136,6.38483756366,219,,Épenoy,Épenoy,25,Doubs,27,Bourgogne-Franche-Comté
25220,EPEUGNEY,25290,EPEUGNEY,,47.1273381655,6.01995883629,220,,Épeugney,Épeugney,25,Doubs,27,Bourgogne-Franche-Comté
25221,ESNANS,25110,ESNANS,,47.3328813955,6.32371158656,221,,Esnans,Esnans,25,Doubs,27,Bourgogne-Franche-Comté
25222,ETALANS,25580,ETALANS,,47.1617288935,6.26148839275,222,,Étalans,Étalans,25,Doubs,27,Bourgogne-Franche-Comté
25223,ETERNOZ,25330,ETERNOZ,,47.0155751982,6.00196889347,223,,Éternoz,Éternoz,25,Doubs,27,Bourgogne-Franche-Comté
25223,ETERNOZ,25330,ETERNOZ,ALAISE,47.0155751982,6.00196889347,223,,Éternoz,Éternoz,25,Doubs,27,Bourgogne-Franche-Comté
25223,ETERNOZ,25330,ETERNOZ,COULANS SUR LIZON,47.0155751982,6.00196889347,223,,Éternoz,Éternoz,25,Doubs,27,Bourgogne-Franche-Comté
25223,ETERNOZ,25330,ETERNOZ,DOULAIZE,47.0155751982,6.00196889347,223,,Éternoz,Éternoz,25,Doubs,27,Bourgogne-Franche-Comté
25223,ETERNOZ,25330,ETERNOZ,REFRANCHE,47.0155751982,6.00196889347,223,,Éternoz,Éternoz,25,Doubs,27,Bourgogne-Franche-Comté
25224,ETOUVANS,25260,ETOUVANS,,47.4565278158,6.72060828166,224,,Étouvans,Étouvans,25,Doubs,27,Bourgogne-Franche-Comté
25225,ETRABONNE,25170,ETRABONNE,,47.2323641161,5.73738220709,225,,Étrabonne,Étrabonne,25,Doubs,27,Bourgogne-Franche-Comté
25226,ETRAPPE,25250,ETRAPPE,,47.4727580805,6.58015754257,226,,Étrappe,Étrappe,25,Doubs,27,Bourgogne-Franche-Comté
25227,ETRAY,25800,ETRAY,,47.1243273869,6.33533326186,227,,Étray,Étray,25,Doubs,27,Bourgogne-Franche-Comté
25228,ETUPES,25460,ETUPES,,47.5062197763,6.87353312717,228,,Étupes,Étupes,25,Doubs,27,Bourgogne-Franche-Comté
25229,EVILLERS,25520,EVILLERS,,46.9954680913,6.2217853268,229,,Évillers,Évillers,25,Doubs,27,Bourgogne-Franche-Comté
25230,EXINCOURT,25400,EXINCOURT,,47.5014159474,6.83497235294,230,,Exincourt,Exincourt,25,Doubs,27,Bourgogne-Franche-Comté
25231,EYSSON,25530,EYSSON,,47.2027293841,6.4306951805,231,,Eysson,Eysson,25,Doubs,27,Bourgogne-Franche-Comté
25232,FAIMBE,25250,FAIMBE,,47.4837994752,6.61593943088,232,,Faimbe,Faimbe,25,Doubs,27,Bourgogne-Franche-Comté
25233,FALLERANS,25580,FALLERANS,,47.1385232143,6.29267245041,233,,Fallerans,Fallerans,25,Doubs,27,Bourgogne-Franche-Comté
25234,FERRIERES LE LAC,25470,FERRIERES LE LAC,,47.2682073244,6.89003480192,234,,Ferrières-le-Lac,Ferrières-le-Lac,25,Doubs,27,Bourgogne-Franche-Comté
25235,FERRIERES LES BOIS,25410,FERRIERES LES BOIS,,47.2091232902,5.80270382568,235,,Ferrières-les-Bois,Ferrières-les-Bois,25,Doubs,27,Bourgogne-Franche-Comté
25236,FERTANS,25330,FERTANS,,47.0554375235,6.06471764594,236,,Fertans,Fertans,25,Doubs,27,Bourgogne-Franche-Comté
25237,FESCHES LE CHATEL,25490,FESCHES LE CHATEL,,47.5196727271,6.90103094864,237,,Fesches-le-Châtel,Fesches-le-Châtel,25,Doubs,27,Bourgogne-Franche-Comté
25238,FESSEVILLERS,25470,FESSEVILLERS,,47.2807563461,6.91972627259,238,,Fessevillers,Fessevillers,25,Doubs,27,Bourgogne-Franche-Comté
25239,FEULE,25190,FEULE,,47.3509896425,6.73538806097,239,,Feule,Feule,25,Doubs,27,Bourgogne-Franche-Comté
25240,LES FINS,25500,LES FINS,,47.0783776419,6.62882589619,240,Les,Fins,Les Fins,25,Doubs,27,Bourgogne-Franche-Comté
25241,FLAGEY,25330,FLAGEY,,47.047536026,6.11183883357,241,,Flagey,Flagey,25,Doubs,27,Bourgogne-Franche-Comté
25242,FLAGEY RIGNEY,25640,FLAGEY RIGNEY,,47.4143951141,6.22003091799,242,,Flagey-Rigney,Flagey-Rigney,25,Doubs,27,Bourgogne-Franche-Comté
25243,FLANGEBOUCHE,25390,FLANGEBOUCHE,,47.1212999202,6.47198800405,243,,Flangebouche,Flangebouche,25,Doubs,27,Bourgogne-Franche-Comté
25244,FLEUREY,25190,FLEUREY,,47.3010695647,6.77725606578,244,,Fleurey,Fleurey,25,Doubs,27,Bourgogne-Franche-Comté
25245,FONTAIN,25660,FONTAIN,,47.1923642064,6.03885344956,245,,Fontain,Fontain,25,Doubs,27,Bourgogne-Franche-Comté
25246,FONTAINE LES CLERVAL,25340,FONTAINE LES CLERVAL,,47.4214306838,6.46911668275,246,,Fontaine-lès-Clerval,Fontaine-lès-Clerval,25,Doubs,27,Bourgogne-Franche-Comté
25247,FONTENELLE MONTBY,25340,FONTENELLE MONTBY,,47.4482472127,6.41103507401,247,,Fontenelle-Montby,Fontenelle-Montby,25,Doubs,27,Bourgogne-Franche-Comté
25248,LES FONTENELLES,25210,LES FONTENELLES,,47.1921428841,6.75410194074,248,Les,Fontenelles,Les Fontenelles,25,Doubs,27,Bourgogne-Franche-Comté
25249,FONTENOTTE,25110,FONTENOTTE,,47.3757047558,6.31361871512,249,,Fontenotte,Fontenotte,25,Doubs,27,Bourgogne-Franche-Comté
25250,FOUCHERANS,25620,FOUCHERANS,,47.1497458218,6.14575080055,250,,Foucherans,Foucherans,25,Doubs,27,Bourgogne-Franche-Comté
25251,FOURBANNE,25110,FOURBANNE,,47.3358691374,6.30644627353,251,,Fourbanne,Fourbanne,25,Doubs,27,Bourgogne-Franche-Comté
25252,FOURCATIER ET MAISON NEUVE,25370,FOURCATIER ET MAISON NEUVE,,46.764021115,6.31302285575,252,,Fourcatier-et-Maison-Neuve,Fourcatier-et-Maison-Neuve,25,Doubs,27,Bourgogne-Franche-Comté
25253,FOURG,25440,FOURG,,47.0951444247,5.80295783516,253,,Fourg,Fourg,25,Doubs,27,Bourgogne-Franche-Comté
25254,LES FOURGS,25300,LES FOURGS,,46.8402465243,6.41622226679,254,Les,Fourgs,Les Fourgs,25,Doubs,27,Bourgogne-Franche-Comté
25255,FOURNET BLANCHEROCHE,25140,FOURNET BLANCHEROCHE,,47.1662085748,6.82524139303,255,,Fournet-Blancheroche,Fournet-Blancheroche,25,Doubs,27,Bourgogne-Franche-Comté
25256,FRAMBOUHANS,25140,FRAMBOUHANS,,47.2003135641,6.77668113804,256,,Frambouhans,Frambouhans,25,Doubs,27,Bourgogne-Franche-Comté
25257,FRANEY,25170,FRANEY,,47.2589627246,5.81341333993,257,,Franey,Franey,25,Doubs,27,Bourgogne-Franche-Comté
25258,FRANOIS,25770,FRANOIS,,47.2253616069,5.92497161799,258,,Franois,Franois,25,Doubs,27,Bourgogne-Franche-Comté
25259,FRASNE,25560,FRASNE,,46.852012619,6.14605787966,259,,Frasne,Frasne,25,Doubs,27,Bourgogne-Franche-Comté
25261,FROIDEVAUX,25190,FROIDEVAUX,,47.3004945102,6.69275819676,261,,Froidevaux,Froidevaux,25,Doubs,27,Bourgogne-Franche-Comté
25262,FUANS,25390,FUANS,,47.1275313249,6.59080450829,262,,Fuans,Fuans,25,Doubs,27,Bourgogne-Franche-Comté
25263,GELLIN,25240,GELLIN,,46.7269667022,6.23705241764,263,,Gellin,Gellin,25,Doubs,27,Bourgogne-Franche-Comté
25264,GEMONVAL,25250,GEMONVAL,,47.5346313053,6.59224255961,264,,Gémonval,Gémonval,25,Doubs,27,Bourgogne-Franche-Comté
25265,GENEUILLE,25870,GENEUILLE,,47.3201113238,5.97104762863,265,,Geneuille,Geneuille,25,Doubs,27,Bourgogne-Franche-Comté
25266,GENEY,25250,GENEY,,47.4831400587,6.565729093,266,,Geney,Geney,25,Doubs,27,Bourgogne-Franche-Comté
25267,GENNES,25660,GENNES,,47.247507773,6.13112207726,267,,Gennes,Gennes,25,Doubs,27,Bourgogne-Franche-Comté
25268,GERMEFONTAINE,25510,GERMEFONTAINE,,47.2253724682,6.48362626311,268,,Germéfontaine,Germéfontaine,25,Doubs,27,Bourgogne-Franche-Comté
25269,GERMONDANS,25640,GERMONDANS,,47.4138937472,6.20033950857,269,,Germondans,Germondans,25,Doubs,27,Bourgogne-Franche-Comté
25270,GEVRESIN,25270,GEVRESIN,,46.9637143049,6.04884299454,270,,Gevresin,Gevresin,25,Doubs,27,Bourgogne-Franche-Comté
25271,GILLEY,25650,GILLEY,,47.0543794362,6.48814154004,271,,Gilley,Gilley,25,Doubs,27,Bourgogne-Franche-Comté
25273,GLAMONDANS,25360,GLAMONDANS,,47.2663945557,6.28683241536,273,,Glamondans,Glamondans,25,Doubs,27,Bourgogne-Franche-Comté
25274,GLAY,25310,GLAY,,47.4034720263,6.89423716637,274,,Glay,Glay,25,Doubs,27,Bourgogne-Franche-Comté
25275,GLERE,25190,GLERE,,47.348314704,6.98432644991,275,,Glère,Glère,25,Doubs,27,Bourgogne-Franche-Comté
25275,GLERE,25190,GLERE,MONTURSIN,47.348314704,6.98432644991,275,,Glère,Glère,25,Doubs,27,Bourgogne-Franche-Comté
25275,GLERE,25190,GLERE,VERNOIS LE FOL,47.348314704,6.98432644991,275,,Glère,Glère,25,Doubs,27,Bourgogne-Franche-Comté
25276,GONDENANS MONTBY,25340,GONDENANS MONTBY,,47.4439969549,6.45702381116,276,,Gondenans-Montby,Gondenans-Montby,25,Doubs,27,Bourgogne-Franche-Comté
25277,GONDENANS LES MOULINS,25680,GONDENANS LES MOULINS,,47.4652888265,6.38278266131,277,,Gondenans-les-Moulins,Gondenans-les-Moulins,25,Doubs,27,Bourgogne-Franche-Comté
25278,GONSANS,25360,GONSANS,,47.2264643937,6.29743977089,278,,Gonsans,Gonsans,25,Doubs,27,Bourgogne-Franche-Comté
25279,GOUHELANS,25680,GOUHELANS,,47.4542701523,6.35769950748,279,,Gouhelans,Gouhelans,25,Doubs,27,Bourgogne-Franche-Comté
25280,GOUMOIS,25470,GOUMOIS,,47.2664069171,6.93962528255,280,,Goumois,Goumois,25,Doubs,27,Bourgogne-Franche-Comté
25281,GOUX LES DAMBELIN,25150,GOUX LES DAMBELIN,,47.3975712606,6.67771122183,281,,Goux-lès-Dambelin,Goux-lès-Dambelin,25,Doubs,27,Bourgogne-Franche-Comté
25282,GOUX LES USIERS,25520,GOUX LES USIERS,,46.9810184806,6.29488743137,282,,Goux-les-Usiers,Goux-les-Usiers,25,Doubs,27,Bourgogne-Franche-Comté
25283,GOUX SOUS LANDET,25440,GOUX SOUS LANDET,,47.0782912931,5.93334206195,283,,Goux-sous-Landet,Goux-sous-Landet,25,Doubs,27,Bourgogne-Franche-Comté
25284,GRAND CHARMONT,25200,GRAND CHARMONT,,47.5338572805,6.82318700398,284,,Grand-Charmont,Grand-Charmont,25,Doubs,27,Bourgogne-Franche-Comté
25285,GRAND COMBE CHATELEU,25570,GRAND COMBE CHATELEU,,47.0158422087,6.55961041125,285,,Grand'Combe-Châteleu,Grand'Combe-Châteleu,25,Doubs,27,Bourgogne-Franche-Comté
25286,GRAND COMBE DES BOIS,25210,GRAND COMBE DES BOIS,,47.1358743178,6.77974865056,286,,Grand'Combe-des-Bois,Grand'Combe-des-Bois,25,Doubs,27,Bourgogne-Franche-Comté
25287,GRANDFONTAINE,25320,GRANDFONTAINE,,47.1989052804,5.90032789854,287,,Grandfontaine,Grandfontaine,25,Doubs,27,Bourgogne-Franche-Comté
25288,FOURNETS LUISANS,25390,FOURNETS LUISANS,,47.1017956393,6.55927911483,288,,Fournets-Luisans,Fournets-Luisans,25,Doubs,27,Bourgogne-Franche-Comté
25288,FOURNETS LUISANS,25390,FOURNETS LUISANS,LUISANS,47.1017956393,6.55927911483,288,,Fournets-Luisans,Fournets-Luisans,25,Doubs,27,Bourgogne-Franche-Comté
25289,GRANDFONTAINE SUR CREUSE,25510,GRANDFONTAINE SUR CREUSE,,47.1838098895,6.45611835105,289,,Grandfontaine-sur-Creuse,Grandfontaine-sur-Creuse,25,Doubs,27,Bourgogne-Franche-Comté
25290,LA GRANGE,25380,LA GRANGE,,47.284355739,6.67077242827,290,La,Grange,La Grange,25,Doubs,27,Bourgogne-Franche-Comté
25293,GRANGES NARBOZ,25300,GRANGES NARBOZ,,46.8783998916,6.32016359916,293,,Granges-Narboz,Granges-Narboz,25,Doubs,27,Bourgogne-Franche-Comté
25295,LES GRANGETTES,25160,LES GRANGETTES,,46.8316716607,6.31512657026,295,Les,Grangettes,Les Grangettes,25,Doubs,27,Bourgogne-Franche-Comté
25296,LES GRAS,25790,LES GRAS,,46.9868721802,6.53969439313,296,Les,Gras,Les Gras,25,Doubs,27,Bourgogne-Franche-Comté
25297,LE GRATTERIS,25620,LE GRATTERIS,,47.1811561648,6.1317775238,297,Le,Gratteris,Le Gratteris,25,Doubs,27,Bourgogne-Franche-Comté
25298,GROSBOIS,25110,GROSBOIS,,47.3463250363,6.30460689266,298,,Grosbois,Grosbois,25,Doubs,27,Bourgogne-Franche-Comté
25299,GUILLON LES BAINS,25110,GUILLON LES BAINS,,47.3160190122,6.39442107084,299,,Guillon-les-Bains,Guillon-les-Bains,25,Doubs,27,Bourgogne-Franche-Comté
25300,GUYANS DURNES,25580,GUYANS DURNES,,47.1294465972,6.24421111235,300,,Guyans-Durnes,Guyans-Durnes,25,Doubs,27,Bourgogne-Franche-Comté
25301,GUYANS VENNES,25390,GUYANS VENNES,,47.1669293709,6.58340413101,301,,Guyans-Vennes,Guyans-Vennes,25,Doubs,27,Bourgogne-Franche-Comté
25303,HAUTERIVE LA FRESSE,25650,HAUTERIVE LA FRESSE,,46.9672807433,6.45727448334,303,,Hauterive-la-Fresse,Hauterive-la-Fresse,25,Doubs,27,Bourgogne-Franche-Comté
25304,HERIMONCOURT,25310,HERIMONCOURT,,47.4359019021,6.8851676894,304,,Hérimoncourt,Hérimoncourt,25,Doubs,27,Bourgogne-Franche-Comté
25305,L HOPITAL DU GROSBOIS,25620,L HOPITAL DU GROSBOIS,,47.1800594325,6.21184360344,305,L',Hôpital-du-Grosbois,L'Hôpital-du-Grosbois,25,Doubs,27,Bourgogne-Franche-Comté
25306,L HOPITAL ST LIEFFROY,25340,L HOPITAL ST LIEFFROY,,47.3994827456,6.45624958709,306,L',Hôpital-Saint-Lieffroy,L'Hôpital-Saint-Lieffroy,25,Doubs,27,Bourgogne-Franche-Comté
25307,LES HOPITAUX NEUFS,25370,LES HOPITAUX NEUFS,,46.7853001951,6.39130917442,307,Les,Hôpitaux-Neufs,Les Hôpitaux-Neufs,25,Doubs,27,Bourgogne-Franche-Comté
25308,LES HOPITAUX VIEUX,25370,LES HOPITAUX VIEUX,,46.8032464609,6.39161147033,308,Les,Hôpitaux-Vieux,Les Hôpitaux-Vieux,25,Doubs,27,Bourgogne-Franche-Comté
25309,HOUTAUD,25300,HOUTAUD,,46.9119596503,6.30024980473,309,,Houtaud,Houtaud,25,Doubs,27,Bourgogne-Franche-Comté
25310,HUANNE MONTMARTIN,25680,HUANNE MONTMARTIN,,47.43194694,6.34212745604,310,,Huanne-Montmartin,Huanne-Montmartin,25,Doubs,27,Bourgogne-Franche-Comté
25311,HYEMONDANS,25250,HYEMONDANS,,47.3809106703,6.63896940193,311,,Hyémondans,Hyémondans,25,Doubs,27,Bourgogne-Franche-Comté
25312,HYEVRE MAGNY,25110,HYEVRE MAGNY,,47.3605502302,6.43447037882,312,,Hyèvre-Magny,Hyèvre-Magny,25,Doubs,27,Bourgogne-Franche-Comté
25313,HYEVRE PAROISSE,25110,HYEVRE PAROISSE,,47.3738771154,6.43305986527,313,,Hyèvre-Paroisse,Hyèvre-Paroisse,25,Doubs,27,Bourgogne-Franche-Comté
25313,HYEVRE PAROISSE,25110,HYEVRE PAROISSE,BOIS LA VILLE,47.3738771154,6.43305986527,313,,Hyèvre-Paroisse,Hyèvre-Paroisse,25,Doubs,27,Bourgogne-Franche-Comté
25314,INDEVILLERS,25470,INDEVILLERS,,47.3122685986,6.96941804742,314,,Indevillers,Indevillers,25,Doubs,27,Bourgogne-Franche-Comté
25315,L ISLE SUR LE DOUBS,25250,L ISLE SUR LE DOUBS,,47.4239335933,6.58904515422,315,L',Isle-sur-le-Doubs,L'Isle-sur-le-Doubs,25,Doubs,27,Bourgogne-Franche-Comté
25316,ISSANS,25550,ISSANS,,47.5266990034,6.73156159928,316,,Issans,Issans,25,Doubs,27,Bourgogne-Franche-Comté
25317,JALLERANGE,25170,JALLERANGE,,47.2569810694,5.71944312657,317,,Jallerange,Jallerange,25,Doubs,27,Bourgogne-Franche-Comté
25318,JOUGNE,25370,JOUGNE,,46.7640858972,6.40386569268,318,,Jougne,Jougne,25,Doubs,27,Bourgogne-Franche-Comté
25319,LABERGEMENT DU NAVOIS,25270,LABERGEMENT DU NAVOIS,,46.9745899291,6.08230605665,319,,Labergement-du-Navois,Labergement-du-Navois,25,Doubs,27,Bourgogne-Franche-Comté
25320,LABERGEMENT STE MARIE,25160,LABERGEMENT STE MARIE,,46.7792090782,6.276761432,320,,Labergement-Sainte-Marie,Labergement-Sainte-Marie,25,Doubs,27,Bourgogne-Franche-Comté
25320,LABERGEMENT STE MARIE,25160,LABERGEMENT STE MARIE,GRANGES STE MARIE,46.7792090782,6.276761432,320,,Labergement-Sainte-Marie,Labergement-Sainte-Marie,25,Doubs,27,Bourgogne-Franche-Comté
25321,VILLERS LE LAC,25130,VILLERS LE LAC,,47.0702797882,6.68940922893,321,,Villers-le-Lac,Villers-le-Lac,25,Doubs,27,Bourgogne-Franche-Comté
25322,LAIRE,25550,LAIRE,,47.550304631,6.73502312394,322,,Laire,Laire,25,Doubs,27,Bourgogne-Franche-Comté
25323,LAISSEY,25820,LAISSEY,,47.3011905975,6.22843372024,323,,Laissey,Laissey,25,Doubs,27,Bourgogne-Franche-Comté
25324,LANANS,25360,LANANS,,47.2920385717,6.44816877704,324,,Lanans,Lanans,25,Doubs,27,Bourgogne-Franche-Comté
25325,LANDRESSE,25530,LANDRESSE,,47.2603830516,6.48915118252,325,,Landresse,Landresse,25,Doubs,27,Bourgogne-Franche-Comté
25326,LANTENNE VERTIERE,25170,LANTENNE VERTIERE,,47.2313250719,5.77272088831,326,,Lantenne-Vertière,Lantenne-Vertière,25,Doubs,27,Bourgogne-Franche-Comté
25327,LANTHENANS,25250,LANTHENANS,,47.391506382,6.62489828601,327,,Lanthenans,Lanthenans,25,Doubs,27,Bourgogne-Franche-Comté
25328,LARNOD,25720,LARNOD,,47.1791216551,5.97342861508,328,,Larnod,Larnod,25,Doubs,27,Bourgogne-Franche-Comté
25329,LAVAL LE PRIEURE,25210,LAVAL LE PRIEURE,,47.1804847309,6.62165201565,329,,Laval-le-Prieuré,Laval-le-Prieuré,25,Doubs,27,Bourgogne-Franche-Comté
25330,LAVANS QUINGEY,25440,LAVANS QUINGEY,,47.0867490631,5.89657298007,330,,Lavans-Quingey,Lavans-Quingey,25,Doubs,27,Bourgogne-Franche-Comté
25331,LAVANS VUILLAFANS,25580,LAVANS VUILLAFANS,,47.0803552824,6.25869822959,331,,Lavans-Vuillafans,Lavans-Vuillafans,25,Doubs,27,Bourgogne-Franche-Comté
25332,LAVERNAY,25170,LAVERNAY,,47.2443013496,5.81882619384,332,,Lavernay,Lavernay,25,Doubs,27,Bourgogne-Franche-Comté
25333,LAVIRON,25510,LAVIRON,,47.2548002468,6.55940694682,333,,Laviron,Laviron,25,Doubs,27,Bourgogne-Franche-Comté
25334,LEVIER,25270,LEVIER,,46.9571786318,6.11754093497,334,,Levier,Levier,25,Doubs,27,Bourgogne-Franche-Comté
25334,LEVIER,25270,LEVIER,GRANGES MAILLOT,46.9571786318,6.11754093497,334,,Levier,Levier,25,Doubs,27,Bourgogne-Franche-Comté
25335,LIEBVILLERS,25190,LIEBVILLERS,,47.331104314,6.78398007896,335,,Liebvillers,Liebvillers,25,Doubs,27,Bourgogne-Franche-Comté
25336,LIESLE,25440,LIESLE,,47.0662026944,5.8125818072,336,,Liesle,Liesle,25,Doubs,27,Bourgogne-Franche-Comté
25338,LIZINE,25330,LIZINE,,47.0547273161,5.99282066337,338,,Lizine,Lizine,25,Doubs,27,Bourgogne-Franche-Comté
25339,LODS,25930,LODS,,47.0494709187,6.25575647951,339,,Lods,Lods,25,Doubs,27,Bourgogne-Franche-Comté
25340,LOMBARD,25440,LOMBARD,,47.0807072893,5.8497136325,340,,Lombard,Lombard,25,Doubs,27,Bourgogne-Franche-Comté
25341,LOMONT SUR CRETE,25110,LOMONT SUR CRETE,,47.3380402467,6.43834071215,341,,Lomont-sur-Crête,Lomont-sur-Crête,25,Doubs,27,Bourgogne-Franche-Comté
25342,LONGECHAUX,25690,LONGECHAUX,,47.1654550513,6.43707765655,342,,Longechaux,Longechaux,25,Doubs,27,Bourgogne-Franche-Comté
25343,LONGEMAISON,25690,LONGEMAISON,,47.0823785209,6.45684579091,343,,Longemaison,Longemaison,25,Doubs,27,Bourgogne-Franche-Comté
25344,LONGEVELLE LES RUSSEY,25380,LONGEVELLE LES RUSSEY,,47.2228806948,6.65562910931,344,,Longevelle-lès-Russey,Longevelle-lès-Russey,25,Doubs,27,Bourgogne-Franche-Comté
25345,LONGEVELLE SUR DOUBS,25260,LONGEVELLE SUR DOUBS,,47.4597602248,6.64960735527,345,,Longevelle-sur-Doubs,Longevelle-sur-Doubs,25,Doubs,27,Bourgogne-Franche-Comté
25346,LONGEVILLE,25330,LONGEVILLE,,47.0287693296,6.22873559182,346,,Longeville,Longeville,25,Doubs,27,Bourgogne-Franche-Comté
25347,LA LONGEVILLE,25650,LA LONGEVILLE,,47.0182144094,6.46715621587,347,La,Longeville,La Longeville,25,Doubs,27,Bourgogne-Franche-Comté
25348,LONGEVILLES MONT D OR,25370,LONGEVILLES MONT D OR,,46.7450112683,6.3307842634,348,,Longevilles-Mont-d'Or,Longevilles-Mont-d'Or,25,Doubs,27,Bourgogne-Franche-Comté
25349,LORAY,25390,LORAY,,47.1596505995,6.4966589281,349,,Loray,Loray,25,Doubs,27,Bourgogne-Franche-Comté
25350,LOUGRES,25260,LOUGRES,,47.471685071,6.68351356482,350,,Lougres,Lougres,25,Doubs,27,Bourgogne-Franche-Comté
25351,LE LUHIER,25210,LE LUHIER,,47.1703571491,6.6776498858,351,Le,Luhier,Le Luhier,25,Doubs,27,Bourgogne-Franche-Comté
25354,LUXIOL,25110,LUXIOL,,47.3794904167,6.34776109043,354,,Luxiol,Luxiol,25,Doubs,27,Bourgogne-Franche-Comté
25355,MAGNY CHATELARD,25360,MAGNY CHATELARD,,47.2346921872,6.32859768861,355,,Magny-Châtelard,Magny-Châtelard,25,Doubs,27,Bourgogne-Franche-Comté
25356,MAICHE,25120,MAICHE,,47.2397795382,6.79879675226,356,,Maîche,Maîche,25,Doubs,27,Bourgogne-Franche-Comté
25357,MAISONS DU BOIS LIEVREMONT,25650,MAISONS DU BOIS LIEVREMONT,,46.9795599744,6.41425984955,357,,Maisons-du-Bois-Lièvremont,Maisons-du-Bois-Lièvremont,25,Doubs,27,Bourgogne-Franche-Comté
25357,MAISONS DU BOIS LIEVREMONT,25650,MAISONS DU BOIS LIEVREMONT,LIEVREMONT,46.9795599744,6.41425984955,357,,Maisons-du-Bois-Lièvremont,Maisons-du-Bois-Lièvremont,25,Doubs,27,Bourgogne-Franche-Comté
25359,MALANS,25330,MALANS,,47.0442451594,6.02925476489,359,,Malans,Malans,25,Doubs,27,Bourgogne-Franche-Comté
25360,MALBRANS,25620,MALBRANS,,47.1237345308,6.08899892431,360,,Malbrans,Malbrans,25,Doubs,27,Bourgogne-Franche-Comté
25361,MALBUISSON,25160,MALBUISSON,,46.7987711533,6.31210934151,361,,Malbuisson,Malbuisson,25,Doubs,27,Bourgogne-Franche-Comté
25362,MALPAS,25160,MALPAS,,46.827268522,6.28836948347,362,,Malpas,Malpas,25,Doubs,27,Bourgogne-Franche-Comté
25364,MAMIROLLE,25620,MAMIROLLE,,47.198418447,6.16243839446,364,,Mamirolle,Mamirolle,25,Doubs,27,Bourgogne-Franche-Comté
25365,MANCENANS,25250,MANCENANS,,47.4633299289,6.54251300425,365,,Mancenans,Mancenans,25,Doubs,27,Bourgogne-Franche-Comté
25366,MANCENANS LIZERNE,25120,MANCENANS LIZERNE,,47.2580575467,6.7736680531,366,,Mancenans-Lizerne,Mancenans-Lizerne,25,Doubs,27,Bourgogne-Franche-Comté
25367,MANDEURE,25350,MANDEURE,,47.4326690481,6.810330244,367,,Mandeure,Mandeure,25,Doubs,27,Bourgogne-Franche-Comté
25368,MARCHAUX,25640,MARCHAUX,,47.3224782465,6.12169829591,368,,Marchaux-Chaudefontaine,Marchaux-Chaudefontaine,25,Doubs,27,Bourgogne-Franche-Comté
25369,MARVELISE,25250,MARVELISE,,47.5218664917,6.59359255229,369,,Marvelise,Marvelise,25,Doubs,27,Bourgogne-Franche-Comté
25370,MATHAY,25700,MATHAY,,47.4423652641,6.77022580965,370,,Mathay,Mathay,25,Doubs,27,Bourgogne-Franche-Comté
25371,MAZEROLLES LE SALIN,25170,MAZEROLLES LE SALIN,,47.2443847246,5.86507797537,371,,Mazerolles-le-Salin,Mazerolles-le-Salin,25,Doubs,27,Bourgogne-Franche-Comté
25372,MEDIERE,25250,MEDIERE,,47.4632149393,6.60260963747,372,,Médière,Médière,25,Doubs,27,Bourgogne-Franche-Comté
25373,LE MEMONT,25210,LE MEMONT,,47.1601369438,6.68774987996,373,Le,Mémont,Le Mémont,25,Doubs,27,Bourgogne-Franche-Comté
25374,MERCEY LE GRAND,25410,MERCEY LE GRAND,,47.2119822043,5.75247892377,374,,Mercey-le-Grand,Mercey-le-Grand,25,Doubs,27,Bourgogne-Franche-Comté
25374,MERCEY LE GRAND,25410,MERCEY LE GRAND,COTTIER,47.2119822043,5.75247892377,374,,Mercey-le-Grand,Mercey-le-Grand,25,Doubs,27,Bourgogne-Franche-Comté
25375,MEREY SOUS MONTROND,25660,MEREY SOUS MONTROND,,47.163496196,6.06429888721,375,,Mérey-sous-Montrond,Mérey-sous-Montrond,25,Doubs,27,Bourgogne-Franche-Comté
25376,MEREY VIEILLEY,25870,MEREY VIEILLEY,,47.3332322953,6.06387544689,376,,Mérey-Vieilley,Mérey-Vieilley,25,Doubs,27,Bourgogne-Franche-Comté
25377,MESANDANS,25680,MESANDANS,,47.4298209681,6.37991516024,377,,Mésandans,Mésandans,25,Doubs,27,Bourgogne-Franche-Comté
25378,MESLIERES,25310,MESLIERES,,47.4180608315,6.89298727209,378,,Meslières,Meslières,25,Doubs,27,Bourgogne-Franche-Comté
25379,MESMAY,25440,MESMAY,,47.0580777963,5.85050213365,379,,Mesmay,Mesmay,25,Doubs,27,Bourgogne-Franche-Comté
25380,METABIEF,25370,METABIEF,,46.7648830862,6.35324497812,380,,Métabief,Métabief,25,Doubs,27,Bourgogne-Franche-Comté
25381,MISEREY SALINES,25480,MISEREY SALINES,,47.2878400774,5.97360872254,381,,Miserey-Salines,Miserey-Salines,25,Doubs,27,Bourgogne-Franche-Comté
25382,MONCEY,25870,MONCEY,,47.3618627403,6.13558805053,382,,Moncey,Moncey,25,Doubs,27,Bourgogne-Franche-Comté
25383,MONCLEY,25170,MONCLEY,,47.3031902882,5.90271359007,383,,Moncley,Moncley,25,Doubs,27,Bourgogne-Franche-Comté
25384,MONDON,25680,MONDON,,47.4482880477,6.31212513097,384,,Mondon,Mondon,25,Doubs,27,Bourgogne-Franche-Comté
25385,MONTAGNEY SERVIGNEY,25680,MONTAGNEY SERVIGNEY,,47.4668786084,6.30394133413,385,,Montagney-Servigney,Montagney-Servigney,25,Doubs,27,Bourgogne-Franche-Comté
25385,MONTAGNEY SERVIGNEY,25680,MONTAGNEY SERVIGNEY,SERVIGNEY,47.4668786084,6.30394133413,385,,Montagney-Servigney,Montagney-Servigney,25,Doubs,27,Bourgogne-Franche-Comté
25386,MONTANCY,25190,MONTANCY,,47.3558454875,7.03093441343,386,,Montancy,Montancy,25,Doubs,27,Bourgogne-Franche-Comté
25387,MONTANDON,25190,MONTANDON,,47.2987632167,6.83499766381,387,,Montandon,Montandon,25,Doubs,27,Bourgogne-Franche-Comté
25388,MONTBELIARD,25200,MONTBELIARD,,47.5155169816,6.79148147353,388,,Montbéliard,Montbéliard,25,Doubs,27,Bourgogne-Franche-Comté
25389,MONTBELIARDOT,25210,MONTBELIARDOT,,47.1811166643,6.67085756871,389,,Montbéliardot,Montbéliardot,25,Doubs,27,Bourgogne-Franche-Comté
25390,MONTBENOIT,25650,MONTBENOIT,,46.9835127856,6.46539409357,390,,Montbenoît,Montbenoît,25,Doubs,27,Bourgogne-Franche-Comté
25391,MONT DE LAVAL,25210,MONT DE LAVAL,,47.161609954,6.63636805448,391,,Mont-de-Laval,Mont-de-Laval,25,Doubs,27,Bourgogne-Franche-Comté
25392,MONT DE VOUGNEY,25120,MONT DE VOUGNEY,,47.2418796782,6.73992900985,392,,Mont-de-Vougney,Mont-de-Vougney,25,Doubs,27,Bourgogne-Franche-Comté
25392,MONT DE VOUGNEY,25120,MONT DE VOUGNEY,LE FRIOLAIS,47.2418796782,6.73992900985,392,,Mont-de-Vougney,Mont-de-Vougney,25,Doubs,27,Bourgogne-Franche-Comté
25393,MONTECHEROUX,25190,MONTECHEROUX,,47.3486585895,6.7984378999,393,,Montécheroux,Montécheroux,25,Doubs,27,Bourgogne-Franche-Comté
25394,MONTENOIS,25260,MONTENOIS,,47.4932375673,6.65827943249,394,,Montenois,Montenois,25,Doubs,27,Bourgogne-Franche-Comté
25395,MONTFAUCON,25660,MONTFAUCON,,47.2418571296,6.08660837587,395,,Montfaucon,Montfaucon,25,Doubs,27,Bourgogne-Franche-Comté
25397,MONTFERRAND LE CHATEAU,25320,MONTFERRAND LE CHATEAU,,47.1864306678,5.91877377873,397,,Montferrand-le-Château,Montferrand-le-Château,25,Doubs,27,Bourgogne-Franche-Comté
25398,MONTFLOVIN,25650,MONTFLOVIN,,46.9945339119,6.4355909855,398,,Montflovin,Montflovin,25,Doubs,27,Bourgogne-Franche-Comté
25399,MONTFORT,25440,MONTFORT,,47.0588588225,5.9008979533,399,,Montfort,Montfort,25,Doubs,27,Bourgogne-Franche-Comté
25400,MONTGESOYE,25111,MONTGESOYE,,47.0879214162,6.19273775491,400,,Montgesoye,Montgesoye,25,Doubs,27,Bourgogne-Franche-Comté
25401,MONTIVERNAGE,25110,MONTIVERNAGE,,47.3105623861,6.42153165074,401,,Montivernage,Montivernage,25,Doubs,27,Bourgogne-Franche-Comté
25402,MONTJOIE LE CHATEAU,25190,MONTJOIE LE CHATEAU,,47.3462625605,6.89671768398,402,,Montjoie-le-Château,Montjoie-le-Château,25,Doubs,27,Bourgogne-Franche-Comté
25403,MONTLEBON,25500,MONTLEBON,,47.023060564,6.62116670386,403,,Montlebon,Montlebon,25,Doubs,27,Bourgogne-Franche-Comté
25404,MONTMAHOUX,25270,MONTMAHOUX,,46.9830184805,6.03348984475,404,,Montmahoux,Montmahoux,25,Doubs,27,Bourgogne-Franche-Comté
25405,MONTPERREUX,25160,MONTPERREUX,,46.8204797871,6.34452081973,405,,Montperreux,Montperreux,25,Doubs,27,Bourgogne-Franche-Comté
25406,MONTROND LE CHATEAU,25660,MONTROND LE CHATEAU,,47.1455882714,6.04310785947,406,,Montrond-le-Château,Montrond-le-Château,25,Doubs,27,Bourgogne-Franche-Comté
25408,MONTUSSAINT,25680,MONTUSSAINT,,47.4360060247,6.2912957658,408,,Montussaint,Montussaint,25,Doubs,27,Bourgogne-Franche-Comté
25410,MORRE,25660,MORRE,,47.2196069824,6.07140212978,410,,Morre,Morre,25,Doubs,27,Bourgogne-Franche-Comté
25411,MORTEAU,25500,MORTEAU,,47.063408704,6.58305657113,411,,Morteau,Morteau,25,Doubs,27,Bourgogne-Franche-Comté
25413,MOUTHE,25240,MOUTHE,,46.6820888251,6.20564143464,413,,Mouthe,Mouthe,25,Doubs,27,Bourgogne-Franche-Comté
25414,LE MOUTHEROT,25170,LE MOUTHEROT,,47.2434663593,5.73147517763,414,Le,Moutherot,Le Moutherot,25,Doubs,27,Bourgogne-Franche-Comté
25415,MOUTHIER HAUTE PIERRE,25920,MOUTHIER HAUTE PIERRE,,47.033913371,6.27516743858,415,,Mouthier-Haute-Pierre,Mouthier-Haute-Pierre,25,Doubs,27,Bourgogne-Franche-Comté
25416,MYON,25440,MYON,,47.0100095777,5.94019899271,416,,Myon,Myon,25,Doubs,27,Bourgogne-Franche-Comté
25417,NAISEY LES GRANGES,25360,NAISEY LES GRANGES,,47.2083549506,6.2346662977,417,,Naisey-les-Granges,Naisey-les-Granges,25,Doubs,27,Bourgogne-Franche-Comté
25417,NAISEY LES GRANGES,25360,NAISEY LES GRANGES,GRANGES DE VIENNEY,47.2083549506,6.2346662977,417,,Naisey-les-Granges,Naisey-les-Granges,25,Doubs,27,Bourgogne-Franche-Comté
25418,NANCRAY,25360,NANCRAY,,47.2500159074,6.17754148907,418,,Nancray,Nancray,25,Doubs,27,Bourgogne-Franche-Comté
25419,NANS,25680,NANS,,47.4763314915,6.41042032823,419,,Nans,Nans,25,Doubs,27,Bourgogne-Franche-Comté
25420,NANS SOUS STE ANNE,25330,NANS SOUS STE ANNE,,46.9768468942,5.9927570154,420,,Nans-sous-Sainte-Anne,Nans-sous-Sainte-Anne,25,Doubs,27,Bourgogne-Franche-Comté
25421,NARBIEF,25210,NARBIEF,,47.1296431442,6.69379616051,421,,Narbief,Narbief,25,Doubs,27,Bourgogne-Franche-Comté
25422,NEUCHATEL URTIERE,25150,NEUCHATEL URTIERE,,47.3748161465,6.73046629435,422,,Neuchâtel-Urtière,Neuchâtel-Urtière,25,Doubs,27,Bourgogne-Franche-Comté
25424,LES PREMIERS SAPINS,25580,LES PREMIERS SAPINS,ATHOSE,47.0885558465,6.3501261359,424,Les,Premiers Sapins,Les Premiers Sapins,25,Doubs,27,Bourgogne-Franche-Comté
25424,LES PREMIERS SAPINS,25580,LES PREMIERS SAPINS,CHASNANS,47.0885558465,6.3501261359,424,Les,Premiers Sapins,Les Premiers Sapins,25,Doubs,27,Bourgogne-Franche-Comté
25424,LES PREMIERS SAPINS,25580,LES PREMIERS SAPINS,HAUTEPIERRE LE CHATELET,47.0885558465,6.3501261359,424,Les,Premiers Sapins,Les Premiers Sapins,25,Doubs,27,Bourgogne-Franche-Comté
25424,LES PREMIERS SAPINS,25580,LES PREMIERS SAPINS,NODS,47.0885558465,6.3501261359,424,Les,Premiers Sapins,Les Premiers Sapins,25,Doubs,27,Bourgogne-Franche-Comté
25424,LES PREMIERS SAPINS,25580,LES PREMIERS SAPINS,RANTECHAUX,47.0885558465,6.3501261359,424,Les,Premiers Sapins,Les Premiers Sapins,25,Doubs,27,Bourgogne-Franche-Comté
25424,LES PREMIERS SAPINS,25580,LES PREMIERS SAPINS,VANCLANS,47.0885558465,6.3501261359,424,Les,Premiers Sapins,Les Premiers Sapins,25,Doubs,27,Bourgogne-Franche-Comté
25425,NOEL CERNEUX,25500,NOEL CERNEUX,,47.1042523876,6.65938834993,425,,Noël-Cerneux,Noël-Cerneux,25,Doubs,27,Bourgogne-Franche-Comté
25426,NOIREFONTAINE,25190,NOIREFONTAINE,,47.3468412202,6.76426619817,426,,Noirefontaine,Noirefontaine,25,Doubs,27,Bourgogne-Franche-Comté
25427,NOIRONTE,25170,NOIRONTE,,47.2734382771,5.87070017811,427,,Noironte,Noironte,25,Doubs,27,Bourgogne-Franche-Comté
25428,NOMMAY,25600,NOMMAY,,47.5395755772,6.84698766674,428,,Nommay,Nommay,25,Doubs,27,Bourgogne-Franche-Comté
25429,NOVILLARS,25220,NOVILLARS,,47.2881176746,6.12951986203,429,,Novillars,Novillars,25,Doubs,27,Bourgogne-Franche-Comté
25430,OLLANS,25640,OLLANS,,47.4203421425,6.24528812863,430,,Ollans,Ollans,25,Doubs,27,Bourgogne-Franche-Comté
25431,ONANS,25250,ONANS,,47.5003692831,6.60417908896,431,,Onans,Onans,25,Doubs,27,Bourgogne-Franche-Comté
25432,ORCHAMPS VENNES,25390,ORCHAMPS VENNES,,47.1113422758,6.51419326091,432,,Orchamps-Vennes,Orchamps-Vennes,25,Doubs,27,Bourgogne-Franche-Comté
25433,ORGEANS BLANCHEFONTAINE,25120,ORGEANS BLANCHEFONTAINE,,47.266487614,6.75428346905,433,,Orgeans-Blanchefontaine,Orgeans-Blanchefontaine,25,Doubs,27,Bourgogne-Franche-Comté
25433,ORGEANS BLANCHEFONTAINE,25120,ORGEANS BLANCHEFONTAINE,BLANCHEFONTAINE,47.266487614,6.75428346905,433,,Orgeans-Blanchefontaine,Orgeans-Blanchefontaine,25,Doubs,27,Bourgogne-Franche-Comté
25434,ORNANS,25290,ORNANS,,47.0978221604,6.15005274633,434,,Ornans,Ornans,25,Doubs,27,Bourgogne-Franche-Comté
25434,ORNANS,25620,ORNANS,BONNEVAUX LE PRIEURE,47.0978221604,6.15005274633,434,,Ornans,Ornans,25,Doubs,27,Bourgogne-Franche-Comté
25435,ORSANS,25530,ORSANS,,47.2505392758,6.38074499024,435,,Orsans,Orsans,25,Doubs,27,Bourgogne-Franche-Comté
25436,ORVE,25430,ORVE,,47.3380513604,6.55949907645,436,,Orve,Orve,25,Doubs,27,Bourgogne-Franche-Comté
25437,OSSE,25360,OSSE,,47.2716954435,6.21232234224,437,,Osse,Osse,25,Doubs,27,Bourgogne-Franche-Comté
25438,OSSELLE ROUTELLE,25320,OSSELLE ROUTELLE,,47.1480275253,5.86345612302,438,,Osselle-Routelle,Osselle-Routelle,25,Doubs,27,Bourgogne-Franche-Comté
25438,OSSELLE ROUTELLE,25410,OSSELLE ROUTELLE,ROUTELLE,47.1480275253,5.86345612302,438,,Osselle-Routelle,Osselle-Routelle,25,Doubs,27,Bourgogne-Franche-Comté
25439,OUGNEY DOUVOT,25640,OUGNEY DOUVOT,,47.319923277,6.27540481731,439,,Ougney-Douvot,Ougney-Douvot,25,Doubs,27,Bourgogne-Franche-Comté
25440,OUHANS,25520,OUHANS,,47.0012375922,6.30185859901,440,,Ouhans,Ouhans,25,Doubs,27,Bourgogne-Franche-Comté
25441,OUVANS,25530,OUVANS,,47.276121899,6.48979491245,441,,Ouvans,Ouvans,25,Doubs,27,Bourgogne-Franche-Comté
25442,OYE ET PALLET,25160,OYE ET PALLET,,46.8519222715,6.33846967053,442,,Oye-et-Pallet,Oye-et-Pallet,25,Doubs,27,Bourgogne-Franche-Comté
25443,PALANTINE,25440,PALANTINE,,47.0996048728,5.94145056885,443,,Palantine,Palantine,25,Doubs,27,Bourgogne-Franche-Comté
25444,PALISE,25870,PALISE,,47.3657225036,6.09170359133,444,,Palise,Palise,25,Doubs,27,Bourgogne-Franche-Comté
25445,PAROY,25440,PAROY,,47.0354181237,5.8834858808,445,,Paroy,Paroy,25,Doubs,27,Bourgogne-Franche-Comté
25446,PASSAVANT,25360,PASSAVANT,,47.2781329553,6.39496038555,446,,Passavant,Passavant,25,Doubs,27,Bourgogne-Franche-Comté
25447,PASSONFONTAINE,25690,PASSONFONTAINE,,47.0942932136,6.42244619836,447,,Passonfontaine,Passonfontaine,25,Doubs,27,Bourgogne-Franche-Comté
25448,PELOUSEY,25170,PELOUSEY,,47.2782210495,5.92416021087,448,,Pelousey,Pelousey,25,Doubs,27,Bourgogne-Franche-Comté
25449,PESEUX,25190,PESEUX,,47.3190983851,6.6862333778,449,,Péseux,Péseux,25,Doubs,27,Bourgogne-Franche-Comté
25450,PESSANS,25440,PESSANS,,47.073375698,5.8864885222,450,,Pessans,Pessans,25,Doubs,27,Bourgogne-Franche-Comté
25451,PETITE CHAUX,25240,PETITE CHAUX,,46.6790696272,6.16339396313,451,,Petite-Chaux,Petite-Chaux,25,Doubs,27,Bourgogne-Franche-Comté
25452,PIERREFONTAINE LES BLAMONT,25310,PIERREFONTAINE LES BLAMONT,,47.371018044,6.82492212107,452,,Pierrefontaine-lès-Blamont,Pierrefontaine-lès-Blamont,25,Doubs,27,Bourgogne-Franche-Comté
25453,PIERREFONTAINE LES VARANS,25510,PIERREFONTAINE LES VARANS,,47.2194158941,6.5503792835,453,,Pierrefontaine-les-Varans,Pierrefontaine-les-Varans,25,Doubs,27,Bourgogne-Franche-Comté
25454,PIREY,25480,PIREY,,47.2606621308,5.96639565137,454,,Pirey,Pirey,25,Doubs,27,Bourgogne-Franche-Comté
25455,PLACEY,25170,PLACEY,,47.2582388477,5.84946760643,455,,Placey,Placey,25,Doubs,27,Bourgogne-Franche-Comté
25456,PLAIMBOIS DU MIROIR,25210,PLAIMBOIS DU MIROIR,,47.1947537606,6.64914884876,456,,Plaimbois-du-Miroir,Plaimbois-du-Miroir,25,Doubs,27,Bourgogne-Franche-Comté
25457,PLAIMBOIS VENNES,25390,PLAIMBOIS VENNES,,47.1838898249,6.54689658239,457,,Plaimbois-Vennes,Plaimbois-Vennes,25,Doubs,27,Bourgogne-Franche-Comté
25458,LES PLAINS ET GRANDS ESSARTS,25470,LES PLAINS ET GRANDS ESSARTS,,47.3035920869,6.89839278467,458,Les,Plains-et-Grands-Essarts,Les Plains-et-Grands-Essarts,25,Doubs,27,Bourgogne-Franche-Comté
25459,LA PLANEE,25160,LA PLANEE,,46.8422329336,6.27289248045,459,La,Planée,La Planée,25,Doubs,27,Bourgogne-Franche-Comté
25460,POINTVILLERS,25440,POINTVILLERS,,47.0662924884,5.91153073564,460,Le,Val,Le Val,25,Doubs,27,Bourgogne-Franche-Comté
25461,POMPIERRE SUR DOUBS,25340,POMPIERRE SUR DOUBS,,47.4236052372,6.52647968524,461,,Pompierre-sur-Doubs,Pompierre-sur-Doubs,25,Doubs,27,Bourgogne-Franche-Comté
25462,PONTARLIER,25300,PONTARLIER,,46.9119730882,6.38914602031,462,,Pontarlier,Pontarlier,25,Doubs,27,Bourgogne-Franche-Comté
25463,PONT DE ROIDE VERMONDANS,25150,PONT DE ROIDE VERMONDANS,,47.3852773517,6.7626810066,463,,Pont-de-Roide-Vermondans,Pont-de-Roide-Vermondans,25,Doubs,27,Bourgogne-Franche-Comté
25463,PONT DE ROIDE VERMONDANS,25150,PONT DE ROIDE VERMONDANS,VERMONDANS,47.3852773517,6.7626810066,463,,Pont-de-Roide-Vermondans,Pont-de-Roide-Vermondans,25,Doubs,27,Bourgogne-Franche-Comté
25464,LES PONTETS,25240,LES PONTETS,,46.7213975225,6.16314736913,464,Les,Pontets,Les Pontets,25,Doubs,27,Bourgogne-Franche-Comté
25465,PONT LES MOULINS,25110,PONT LES MOULINS,,47.325697924,6.36650905984,465,,Pont-les-Moulins,Pont-les-Moulins,25,Doubs,27,Bourgogne-Franche-Comté
25466,POUILLEY FRANCAIS,25410,POUILLEY FRANCAIS,,47.2061915063,5.84308550678,466,,Pouilley-Français,Pouilley-Français,25,Doubs,27,Bourgogne-Franche-Comté
25467,POUILLEY LES VIGNES,25115,POUILLEY LES VIGNES,,47.2602504664,5.93645991139,467,,Pouilley-les-Vignes,Pouilley-les-Vignes,25,Doubs,27,Bourgogne-Franche-Comté
25468,POULIGNEY LUSANS,25640,POULIGNEY LUSANS,,47.3352091384,6.20063332873,468,,Pouligney-Lusans,Pouligney-Lusans,25,Doubs,27,Bourgogne-Franche-Comté
25468,POULIGNEY LUSANS,25640,POULIGNEY LUSANS,LUSANS,47.3352091384,6.20063332873,468,,Pouligney-Lusans,Pouligney-Lusans,25,Doubs,27,Bourgogne-Franche-Comté
25469,PRESENTEVILLERS,25550,PRESENTEVILLERS,,47.5012810913,6.72665419806,469,,Présentevillers,Présentevillers,25,Doubs,27,Bourgogne-Franche-Comté
25470,LA PRETIERE,25250,LA PRETIERE,,47.4467319002,6.61016502451,470,La,Prétière,La Prétière,25,Doubs,27,Bourgogne-Franche-Comté
25471,PROVENCHERE,25380,PROVENCHERE,,47.2895523062,6.64338373642,471,,Provenchère,Provenchère,25,Doubs,27,Bourgogne-Franche-Comté
25472,PUESSANS,25680,PUESSANS,,47.434521432,6.32409895511,472,,Puessans,Puessans,25,Doubs,27,Bourgogne-Franche-Comté
25473,PUGEY,25720,PUGEY,,47.1714944079,5.99435390384,473,,Pugey,Pugey,25,Doubs,27,Bourgogne-Franche-Comté
25474,LE PUY,25640,LE PUY,,47.3456027875,6.23053427438,474,Le,Puy,Le Puy,25,Doubs,27,Bourgogne-Franche-Comté
25475,QUINGEY,25440,QUINGEY,,47.1025398446,5.88098542141,475,,Quingey,Quingey,25,Doubs,27,Bourgogne-Franche-Comté
25476,RAHON,25430,RAHON,,47.3270427606,6.58186088547,476,,Rahon,Rahon,25,Doubs,27,Bourgogne-Franche-Comté
25477,RANCENAY,25320,RANCENAY,,47.185849528,5.94490685267,477,,Rancenay,Rancenay,25,Doubs,27,Bourgogne-Franche-Comté
25478,RANDEVILLERS,25430,RANDEVILLERS,,47.3073217418,6.52699892784,478,,Randevillers,Randevillers,25,Doubs,27,Bourgogne-Franche-Comté
25479,RANG,25250,RANG,,47.4234030731,6.56255299785,479,,Rang,Rang,25,Doubs,27,Bourgogne-Franche-Comté
25481,RAYNANS,25550,RAYNANS,,47.5368460098,6.72116733981,481,,Raynans,Raynans,25,Doubs,27,Bourgogne-Franche-Comté
25482,RECOLOGNE,25170,RECOLOGNE,,47.2745847308,5.83450368861,482,,Recologne,Recologne,25,Doubs,27,Bourgogne-Franche-Comté
25483,RECULFOZ,25240,RECULFOZ,,46.71075258,6.14869649896,483,,Reculfoz,Reculfoz,25,Doubs,27,Bourgogne-Franche-Comté
25485,REMONDANS VAIVRE,25150,REMONDANS VAIVRE,,47.3797020661,6.70946865104,485,,Rémondans-Vaivre,Rémondans-Vaivre,25,Doubs,27,Bourgogne-Franche-Comté
25485,REMONDANS VAIVRE,25150,REMONDANS VAIVRE,VAIVRE,47.3797020661,6.70946865104,485,,Rémondans-Vaivre,Rémondans-Vaivre,25,Doubs,27,Bourgogne-Franche-Comté
25486,REMORAY BOUJEONS,25160,REMORAY BOUJEONS,,46.7590078628,6.21661455982,486,,Remoray-Boujeons,Remoray-Boujeons,25,Doubs,27,Bourgogne-Franche-Comté
25486,REMORAY BOUJEONS,25160,REMORAY BOUJEONS,BOUJEONS,46.7590078628,6.21661455982,486,,Remoray-Boujeons,Remoray-Boujeons,25,Doubs,27,Bourgogne-Franche-Comté
25487,RENEDALE,25520,RENEDALE,,47.0151528366,6.27602313986,487,,Renédale,Renédale,25,Doubs,27,Bourgogne-Franche-Comté
25488,RENNES SUR LOUE,25440,RENNES SUR LOUE,,47.0154149111,5.85842566439,488,,Rennes-sur-Loue,Rennes-sur-Loue,25,Doubs,27,Bourgogne-Franche-Comté
25489,REUGNEY,25330,REUGNEY,,47.0103494602,6.15267217668,489,,Reugney,Reugney,25,Doubs,27,Bourgogne-Franche-Comté
25490,RIGNEY,25640,RIGNEY,,47.3885471699,6.18175113626,490,,Rigney,Rigney,25,Doubs,27,Bourgogne-Franche-Comté
25491,RIGNOSOT,25640,RIGNOSOT,,47.3835277032,6.1996281942,491,,Rignosot,Rignosot,25,Doubs,27,Bourgogne-Franche-Comté
25492,RILLANS,25110,RILLANS,,47.4134765055,6.36658431781,492,,Rillans,Rillans,25,Doubs,27,Bourgogne-Franche-Comté
25493,LA RIVIERE DRUGEON,25560,LA RIVIERE DRUGEON,,46.873799003,6.21508005837,493,La,Rivière-Drugeon,La Rivière-Drugeon,25,Doubs,27,Bourgogne-Franche-Comté
25494,ROCHEJEAN,25370,ROCHEJEAN,,46.7262310922,6.304807031,494,,Rochejean,Rochejean,25,Doubs,27,Bourgogne-Franche-Comté
25495,ROCHE LEZ BEAUPRE,25220,ROCHE LEZ BEAUPRE,,47.2817555255,6.10801938836,495,,Roche-lez-Beaupré,Roche-lez-Beaupré,25,Doubs,27,Bourgogne-Franche-Comté
25496,ROCHE LES CLERVAL,25340,ROCHE LES CLERVAL,,47.3634845097,6.46991658309,496,,Roche-lès-Clerval,Roche-lès-Clerval,25,Doubs,27,Bourgogne-Franche-Comté
25497,ROCHES LES BLAMONT,25310,ROCHES LES BLAMONT,,47.4106474482,6.85440566573,497,,Roches-lès-Blamont,Roches-lès-Blamont,25,Doubs,27,Bourgogne-Franche-Comté
25498,ROGNON,25680,ROGNON,,47.4193128831,6.30787470895,498,,Rognon,Rognon,25,Doubs,27,Bourgogne-Franche-Comté
25499,ROMAIN,25680,ROMAIN,,47.4439581352,6.37976637275,499,,Romain,Romain,25,Doubs,27,Bourgogne-Franche-Comté
25500,RONCHAUX,25440,RONCHAUX,,47.0427610393,5.90349548517,500,,Ronchaux,Ronchaux,25,Doubs,27,Bourgogne-Franche-Comté
25501,RONDEFONTAINE,25240,RONDEFONTAINE,,46.7329359308,6.18194952328,501,,Rondefontaine,Rondefontaine,25,Doubs,27,Bourgogne-Franche-Comté
25502,ROSET FLUANS,25410,ROSET FLUANS,,47.1536312565,5.83267592874,502,,Roset-Fluans,Roset-Fluans,25,Doubs,27,Bourgogne-Franche-Comté
25503,ROSIERES SUR BARBECHE,25190,ROSIERES SUR BARBECHE,,47.3149794367,6.65958662273,503,,Rosières-sur-Barbèche,Rosières-sur-Barbèche,25,Doubs,27,Bourgogne-Franche-Comté
25504,ROSUREUX,25380,ROSUREUX,,47.2139578431,6.68360932268,504,,Rosureux,Rosureux,25,Doubs,27,Bourgogne-Franche-Comté
25505,ROUGEMONT,25680,ROUGEMONT,,47.4795835178,6.34800687837,505,,Rougemont,Rougemont,25,Doubs,27,Bourgogne-Franche-Comté
25505,ROUGEMONT,25680,ROUGEMONT,CHAZELOT,47.4795835178,6.34800687837,505,,Rougemont,Rougemont,25,Doubs,27,Bourgogne-Franche-Comté
25505,ROUGEMONT,25680,ROUGEMONT,MONTFERNEY,47.4795835178,6.34800687837,505,,Rougemont,Rougemont,25,Doubs,27,Bourgogne-Franche-Comté
25505,ROUGEMONT,25680,ROUGEMONT,MORCHAMPS,47.4795835178,6.34800687837,505,,Rougemont,Rougemont,25,Doubs,27,Bourgogne-Franche-Comté
25506,ROUGEMONTOT,25640,ROUGEMONTOT,,47.3899184482,6.26250218169,506,,Rougemontot,Rougemontot,25,Doubs,27,Bourgogne-Franche-Comté
25507,ROUHE,25440,ROUHE,,47.0820091949,5.96566947588,507,,Rouhe,Rouhe,25,Doubs,27,Bourgogne-Franche-Comté
25508,ROULANS,25640,ROULANS,,47.3176462512,6.23539545398,508,,Roulans,Roulans,25,Doubs,27,Bourgogne-Franche-Comté
25510,RUFFEY LE CHATEAU,25170,RUFFEY LE CHATEAU,,47.2827813349,5.8036207849,510,,Ruffey-le-Château,Ruffey-le-Château,25,Doubs,27,Bourgogne-Franche-Comté
25511,RUREY,25290,RUREY,,47.0956339388,5.99767264147,511,,Rurey,Rurey,25,Doubs,27,Bourgogne-Franche-Comté
25512,LE RUSSEY,25210,LE RUSSEY,,47.1549185878,6.73440290446,512,Le,Russey,Le Russey,25,Doubs,27,Bourgogne-Franche-Comté
25513,STE ANNE,25270,STE ANNE,,46.9506104043,5.98454603407,513,,Sainte-Anne,Sainte-Anne,25,Doubs,27,Bourgogne-Franche-Comté
25514,ST ANTOINE,25370,ST ANTOINE,,46.7821317328,6.33373558381,514,,Saint-Antoine,Saint-Antoine,25,Doubs,27,Bourgogne-Franche-Comté
25515,STE COLOMBE,25300,STE COLOMBE,,46.8735551517,6.27545445456,515,,Sainte-Colombe,Sainte-Colombe,25,Doubs,27,Bourgogne-Franche-Comté
25516,ST GEORGES ARMONT,25340,ST GEORGES ARMONT,,47.4042841362,6.54940433653,516,,Saint-Georges-Armont,Saint-Georges-Armont,25,Doubs,27,Bourgogne-Franche-Comté
25517,ST GORGON MAIN,25520,ST GORGON MAIN,,47.0172420756,6.33611929324,517,,Saint-Gorgon-Main,Saint-Gorgon-Main,25,Doubs,27,Bourgogne-Franche-Comté
25518,ST HILAIRE,25640,ST HILAIRE,,47.334610103,6.24519966915,518,,Saint-Hilaire,Saint-Hilaire,25,Doubs,27,Bourgogne-Franche-Comté
25519,ST HIPPOLYTE,25190,ST HIPPOLYTE,,47.3121318128,6.81240649339,519,,Saint-Hippolyte,Saint-Hippolyte,25,Doubs,27,Bourgogne-Franche-Comté
25519,ST HIPPOLYTE,25190,ST HIPPOLYTE,MOUILLEVILLERS,47.3121318128,6.81240649339,519,,Saint-Hippolyte,Saint-Hippolyte,25,Doubs,27,Bourgogne-Franche-Comté
25520,ST JUAN,25360,ST JUAN,,47.2893791065,6.3435136184,520,,Saint-Juan,Saint-Juan,25,Doubs,27,Bourgogne-Franche-Comté
25521,ST JULIEN LES MONTBELIARD,25550,ST JULIEN LES MONTBELIARD,,47.5191858536,6.7064941383,521,,Saint-Julien-lès-Montbéliard,Saint-Julien-lès-Montbéliard,25,Doubs,27,Bourgogne-Franche-Comté
25522,ST JULIEN LES RUSSEY,25210,ST JULIEN LES RUSSEY,,47.2167228369,6.72957115326,522,,Saint-Julien-lès-Russey,Saint-Julien-lès-Russey,25,Doubs,27,Bourgogne-Franche-Comté
25523,STE MARIE,25113,STE MARIE,,47.5016165362,6.69552545899,523,,Sainte-Marie,Sainte-Marie,25,Doubs,27,Bourgogne-Franche-Comté
25524,ST MAURICE COLOMBIER,25260,ST MAURICE COLOMBIER,,47.4316270724,6.65024114123,524,,Saint-Maurice-Colombier,Saint-Maurice-Colombier,25,Doubs,27,Bourgogne-Franche-Comté
25524,ST MAURICE COLOMBIER,25260,ST MAURICE COLOMBIER,COLOMBIER CHATELOT,47.4316270724,6.65024114123,524,,Saint-Maurice-Colombier,Saint-Maurice-Colombier,25,Doubs,27,Bourgogne-Franche-Comté
25525,ST POINT LAC,25160,ST POINT LAC,,46.8101118248,6.29601510086,525,,Saint-Point-Lac,Saint-Point-Lac,25,Doubs,27,Bourgogne-Franche-Comté
25526,STE SUZANNE,25630,STE SUZANNE,,47.5077397674,6.76777970529,526,,Sainte-Suzanne,Sainte-Suzanne,25,Doubs,27,Bourgogne-Franche-Comté
25527,ST VIT,25410,ST VIT,,47.1854090254,5.82031300598,527,,Saint-Vit,Saint-Vit,25,Doubs,27,Bourgogne-Franche-Comté
25527,ST VIT,25410,ST VIT,ANTORPE,47.1854090254,5.82031300598,527,,Saint-Vit,Saint-Vit,25,Doubs,27,Bourgogne-Franche-Comté
25528,SAMSON,25440,SAMSON,,47.0533990427,5.87931888602,528,,Samson,Samson,25,Doubs,27,Bourgogne-Franche-Comté
25529,SANCEY,25430,SANCEY,,47.2902129246,6.5630744882,529,,Sancey,Sancey,25,Doubs,27,Bourgogne-Franche-Comté
25529,SANCEY,25430,SANCEY,SANCEY LE LONG,47.2902129246,6.5630744882,529,,Sancey,Sancey,25,Doubs,27,Bourgogne-Franche-Comté
25531,SANTOCHE,25340,SANTOCHE,,47.4092325393,6.5028747061,531,,Santoche,Santoche,25,Doubs,27,Bourgogne-Franche-Comté
25532,SAONE,25660,SAONE,,47.214147166,6.1131738065,532,,Saône,Saône,25,Doubs,27,Bourgogne-Franche-Comté
25533,SARAZ,25330,SARAZ,,46.9920963588,5.97458498115,533,,Saraz,Saraz,25,Doubs,27,Bourgogne-Franche-Comté
25534,SARRAGEOIS,25240,SARRAGEOIS,,46.7039578947,6.23498989806,534,,Sarrageois,Sarrageois,25,Doubs,27,Bourgogne-Franche-Comté
25535,SAULES,25580,SAULES,,47.1247786594,6.20093911163,535,,Saules,Saules,25,Doubs,27,Bourgogne-Franche-Comté
25536,SAUVAGNEY,25170,SAUVAGNEY,,47.3218215365,5.91157630752,536,,Sauvagney,Sauvagney,25,Doubs,27,Bourgogne-Franche-Comté
25537,SCEY MAISIERES,25290,SCEY MAISIERES,,47.1092770576,6.07924521048,537,,Scey-Maisières,Scey-Maisières,25,Doubs,27,Bourgogne-Franche-Comté
25537,SCEY MAISIERES,25290,SCEY MAISIERES,MAISIERES NOTRE DAME,47.1092770576,6.07924521048,537,,Scey-Maisières,Scey-Maisières,25,Doubs,27,Bourgogne-Franche-Comté
25538,SECHIN,25110,SECHIN,,47.3399206396,6.28584511436,538,,Séchin,Séchin,25,Doubs,27,Bourgogne-Franche-Comté
25539,SELONCOURT,25230,SELONCOURT,,47.4569305468,6.86452195563,539,,Seloncourt,Seloncourt,25,Doubs,27,Bourgogne-Franche-Comté
25540,SEMONDANS,25750,SEMONDANS,,47.5402222251,6.69276123061,540,,Semondans,Semondans,25,Doubs,27,Bourgogne-Franche-Comté
25541,SEPTFONTAINES,25270,SEPTFONTAINES,,46.9757494238,6.17849639031,541,,Septfontaines,Septfontaines,25,Doubs,27,Bourgogne-Franche-Comté
25542,SERRE LES SAPINS,25770,SERRE LES SAPINS,,47.2393718046,5.9349388655,542,,Serre-les-Sapins,Serre-les-Sapins,25,Doubs,27,Bourgogne-Franche-Comté
25544,SERVIN,25430,SERVIN,,47.3054149371,6.47182127802,544,,Servin,Servin,25,Doubs,27,Bourgogne-Franche-Comté
25545,SILLEY AMANCEY,25330,SILLEY AMANCEY,,47.0325022535,6.13704551483,545,,Silley-Amancey,Silley-Amancey,25,Doubs,27,Bourgogne-Franche-Comté
25546,SILLEY BLEFOND,25110,SILLEY BLEFOND,,47.3218769577,6.33058126061,546,,Silley-Bléfond,Silley-Bléfond,25,Doubs,27,Bourgogne-Franche-Comté
25547,SOCHAUX,25600,SOCHAUX,,47.5147289222,6.82988348099,547,,Sochaux,Sochaux,25,Doubs,27,Bourgogne-Franche-Comté
25548,SOLEMONT,25190,SOLEMONT,,47.345000797,6.70680878537,548,,Solemont,Solemont,25,Doubs,27,Bourgogne-Franche-Comté
25549,SOMBACOUR,25520,SOMBACOUR,,46.9502322879,6.24102046225,549,,Sombacour,Sombacour,25,Doubs,27,Bourgogne-Franche-Comté
25550,LA SOMMETTE,25510,LA SOMMETTE,,47.1917958148,6.50974763085,550,La,Sommette,La Sommette,25,Doubs,27,Bourgogne-Franche-Comté
25551,SOULCE CERNAY,25190,SOULCE CERNAY,,47.3275454808,6.86807912248,551,,Soulce-Cernay,Soulce-Cernay,25,Doubs,27,Bourgogne-Franche-Comté
25552,SOURANS,25250,SOURANS,,47.4100667582,6.64130121934,552,,Sourans,Sourans,25,Doubs,27,Bourgogne-Franche-Comté
25553,SOYE,25250,SOYE,,47.4521143455,6.50365970066,553,,Soye,Soye,25,Doubs,27,Bourgogne-Franche-Comté
25554,SURMONT,25380,SURMONT,,47.2726795746,6.61174843961,554,,Surmont,Surmont,25,Doubs,27,Bourgogne-Franche-Comté
25555,TAILLECOURT,25400,TAILLECOURT,,47.4947013752,6.85393764134,555,,Taillecourt,Taillecourt,25,Doubs,27,Bourgogne-Franche-Comté
25556,TALLANS,25680,TALLANS,,47.4108608327,6.28718922953,556,,Tallans,Tallans,25,Doubs,27,Bourgogne-Franche-Comté
25557,TALLENAY,25870,TALLENAY,,47.3056934565,6.02035372248,557,,Tallenay,Tallenay,25,Doubs,27,Bourgogne-Franche-Comté
25558,TARCENAY,25620,TARCENAY,,47.1661327751,6.10532851218,558,,Tarcenay,Tarcenay,25,Doubs,27,Bourgogne-Franche-Comté
25559,THIEBOUHANS,25470,THIEBOUHANS,,47.2711359611,6.8433767391,559,,Thiébouhans,Thiébouhans,25,Doubs,27,Bourgogne-Franche-Comté
25560,THISE,25220,THISE,,47.2862704749,6.08224495009,560,,Thise,Thise,25,Doubs,27,Bourgogne-Franche-Comté
25561,THORAISE,25320,THORAISE,,47.169790589,5.9098007107,561,,Thoraise,Thoraise,25,Doubs,27,Bourgogne-Franche-Comté
25562,THULAY,25310,THULAY,,47.4291611668,6.86009658737,562,,Thulay,Thulay,25,Doubs,27,Bourgogne-Franche-Comté
25563,THUREY LE MONT,25870,THUREY LE MONT,,47.3753171699,6.13573809341,563,,Thurey-le-Mont,Thurey-le-Mont,25,Doubs,27,Bourgogne-Franche-Comté
25564,TORPES,25320,TORPES,,47.1724802997,5.88041302138,564,,Torpes,Torpes,25,Doubs,27,Bourgogne-Franche-Comté
25565,TOUILLON ET LOUTELET,25370,TOUILLON ET LOUTELET,,46.7940131071,6.35258937692,565,,Touillon-et-Loutelet,Touillon-et-Loutelet,25,Doubs,27,Bourgogne-Franche-Comté
25566,LA TOUR DE SCAY,25640,LA TOUR DE SCAY,,47.3825738249,6.22493151895,566,La,Tour-de-Sçay,La Tour-de-Sçay,25,Doubs,27,Bourgogne-Franche-Comté
25567,TOURNANS,25680,TOURNANS,,47.3995202196,6.31218752509,567,,Tournans,Tournans,25,Doubs,27,Bourgogne-Franche-Comté
25569,TREPOT,25620,TREPOT,,47.1693297526,6.16989385238,569,,Trépot,Trépot,25,Doubs,27,Bourgogne-Franche-Comté
25570,TRESSANDANS,25680,TRESSANDANS,,47.4975648917,6.33629844755,570,,Tressandans,Tressandans,25,Doubs,27,Bourgogne-Franche-Comté
25571,TREVILLERS,25470,TREVILLERS,,47.2838183983,6.87447144463,571,,Trévillers,Trévillers,25,Doubs,27,Bourgogne-Franche-Comté
25572,TROUVANS,25680,TROUVANS,,47.4202800543,6.34806537347,572,,Trouvans,Trouvans,25,Doubs,27,Bourgogne-Franche-Comté
25573,URTIERE,25470,URTIERE,,47.2569443601,6.92336911492,573,,Urtière,Urtière,25,Doubs,27,Bourgogne-Franche-Comté
25574,UZELLE,25340,UZELLE,,47.4657206904,6.44232255032,574,,Uzelle,Uzelle,25,Doubs,27,Bourgogne-Franche-Comté
25575,VAIRE ARCIER,25220,VAIRE ARCIER,,47.2751664682,6.15290244914,575,,Vaire,Vaire,25,Doubs,27,Bourgogne-Franche-Comté
25575,VAIRE ARCIER,25220,VAIRE ARCIER,ARCIER,47.2751664682,6.15290244914,575,,Vaire,Vaire,25,Doubs,27,Bourgogne-Franche-Comté
25576,VAIRE LE PETIT,25220,VAIRE LE PETIT,,47.2889525866,6.14880400581,576,,Vaire-le-Petit,Vaire-le-Petit,25,Doubs,27,Bourgogne-Franche-Comté
25578,VALDAHON,25800,VALDAHON,,47.1633469819,6.33226254465,578,,Valdahon,Valdahon,25,Doubs,27,Bourgogne-Franche-Comté
25579,VAL DE ROULANS,25640,VAL DE ROULANS,,47.3602096754,6.26791337918,579,,Val-de-Roulans,Val-de-Roulans,25,Doubs,27,Bourgogne-Franche-Comté
25580,VALENTIGNEY,25700,VALENTIGNEY,,47.4647367767,6.82570788244,580,,Valentigney,Valentigney,25,Doubs,27,Bourgogne-Franche-Comté
25582,VALLEROY,25870,VALLEROY,,47.3860163944,6.12490691273,582,,Valleroy,Valleroy,25,Doubs,27,Bourgogne-Franche-Comté
25583,VALONNE,25190,VALONNE,,47.3423640011,6.66820326069,583,,Valonne,Valonne,25,Doubs,27,Bourgogne-Franche-Comté
25584,VALOREILLE,25190,VALOREILLE,,47.2938610141,6.74127046044,584,,Valoreille,Valoreille,25,Doubs,27,Bourgogne-Franche-Comté
25586,VANDONCOURT,25230,VANDONCOURT,,47.458634296,6.90721789075,586,,Vandoncourt,Vandoncourt,25,Doubs,27,Bourgogne-Franche-Comté
25587,VAUCHAMPS,25360,VAUCHAMPS,,47.2691499884,6.24816158681,587,,Vauchamps,Vauchamps,25,Doubs,27,Bourgogne-Franche-Comté
25588,VAUCLUSE,25380,VAUCLUSE,,47.2483159585,6.69118061201,588,,Vaucluse,Vaucluse,25,Doubs,27,Bourgogne-Franche-Comté
25589,VAUCLUSOTTE,25380,VAUCLUSOTTE,,47.2774412762,6.73416218466,589,,Vauclusotte,Vauclusotte,25,Doubs,27,Bourgogne-Franche-Comté
25590,VAUDRIVILLERS,25360,VAUDRIVILLERS,,47.2837223022,6.42788857639,590,,Vaudrivillers,Vaudrivillers,25,Doubs,27,Bourgogne-Franche-Comté
25591,VAUFREY,25190,VAUFREY,,47.3405522044,6.93117850184,591,,Vaufrey,Vaufrey,25,Doubs,27,Bourgogne-Franche-Comté
25592,VAUX ET CHANTEGRUE,25160,VAUX ET CHANTEGRUE,,46.7990861399,6.23427007701,592,,Vaux-et-Chantegrue,Vaux-et-Chantegrue,25,Doubs,27,Bourgogne-Franche-Comté
25593,VAUX LES PRES,25770,VAUX LES PRES,,47.236235248,5.88394107726,593,,Vaux-les-Prés,Vaux-les-Prés,25,Doubs,27,Bourgogne-Franche-Comté
25594,VELESMES ESSARTS,25410,VELESMES ESSARTS,,47.1891068844,5.86952993228,594,,Velesmes-Essarts,Velesmes-Essarts,25,Doubs,27,Bourgogne-Franche-Comté
25595,VELLEROT LES BELVOIR,25430,VELLEROT LES BELVOIR,,47.3476484278,6.590540119,595,,Vellerot-lès-Belvoir,Vellerot-lès-Belvoir,25,Doubs,27,Bourgogne-Franche-Comté
25596,VELLEROT LES VERCEL,25530,VELLEROT LES VERCEL,,47.2462544184,6.44292570625,596,,Vellerot-lès-Vercel,Vellerot-lès-Vercel,25,Doubs,27,Bourgogne-Franche-Comté
25597,VELLEVANS,25430,VELLEVANS,,47.3069330762,6.50266698549,597,,Vellevans,Vellevans,25,Doubs,27,Bourgogne-Franche-Comté
25598,VENISE,25870,VENISE,,47.3499226615,6.11345012494,598,,Venise,Venise,25,Doubs,27,Bourgogne-Franche-Comté
25599,VENNANS,25640,VENNANS,,47.3297119389,6.23268452112,599,,Vennans,Vennans,25,Doubs,27,Bourgogne-Franche-Comté
25600,VENNES,25390,VENNES,,47.1578266243,6.54060919316,600,,Vennes,Vennes,25,Doubs,27,Bourgogne-Franche-Comté
25601,VERCEL VILLEDIEU LE CAMP,25530,VERCEL VILLEDIEU LE CAMP,,47.1891393068,6.38184480034,601,,Vercel-Villedieu-le-Camp,Vercel-Villedieu-le-Camp,25,Doubs,27,Bourgogne-Franche-Comté
25602,VERGRANNE,25110,VERGRANNE,,47.4092336161,6.39127050338,602,,Vergranne,Vergranne,25,Doubs,27,Bourgogne-Franche-Comté
25604,VERNE,25110,VERNE,,47.3971039015,6.35183309634,604,,Verne,Verne,25,Doubs,27,Bourgogne-Franche-Comté
25605,VERNIERFONTAINE,25580,VERNIERFONTAINE,,47.1102498067,6.29541475035,605,,Vernierfontaine,Vernierfontaine,25,Doubs,27,Bourgogne-Franche-Comté
25607,VERNOIS LES BELVOIR,25430,VERNOIS LES BELVOIR,,47.3215864263,6.64047635728,607,,Vernois-lès-Belvoir,Vernois-lès-Belvoir,25,Doubs,27,Bourgogne-Franche-Comté
25608,LE VERNOY,25750,LE VERNOY,,47.5668061551,6.67140627153,608,Le,Vernoy,Le Vernoy,25,Doubs,27,Bourgogne-Franche-Comté
25609,VERRIERES DE JOUX,25300,VERRIERES DE JOUX,,46.886228497,6.44711021726,609,,Verrières-de-Joux,Verrières-de-Joux,25,Doubs,27,Bourgogne-Franche-Comté
25610,VERRIERES DU GROSBOIS,25580,VERRIERES DU GROSBOIS,,47.1921039411,6.28219587259,610,,Verrières-du-Grosbois,Verrières-du-Grosbois,25,Doubs,27,Bourgogne-Franche-Comté
25611,LA VEZE,25660,LA VEZE,,47.1973736223,6.07326086918,611,La,Vèze,La Vèze,25,Doubs,27,Bourgogne-Franche-Comté
25612,VIEILLEY,25870,VIEILLEY,,47.3366982085,6.0850051577,612,,Vieilley,Vieilley,25,Doubs,27,Bourgogne-Franche-Comté
25613,VIETHOREY,25340,VIETHOREY,,47.4208244987,6.42192640608,613,,Viéthorey,Viéthorey,25,Doubs,27,Bourgogne-Franche-Comté
25614,VIEUX CHARMONT,25600,VIEUX CHARMONT,,47.5237437505,6.84347198387,614,,Vieux-Charmont,Vieux-Charmont,25,Doubs,27,Bourgogne-Franche-Comté
25615,VILLARS LES BLAMONT,25310,VILLARS LES BLAMONT,,47.3679686248,6.87102265307,615,,Villars-lès-Blamont,Villars-lès-Blamont,25,Doubs,27,Bourgogne-Franche-Comté
25616,VILLARS ST GEORGES,25410,VILLARS ST GEORGES,,47.1248376906,5.82242208897,616,,Villars-Saint-Georges,Villars-Saint-Georges,25,Doubs,27,Bourgogne-Franche-Comté
25617,VILLARS SOUS DAMPJOUX,25190,VILLARS SOUS DAMPJOUX,,47.3616773268,6.75143878477,617,,Villars-sous-Dampjoux,Villars-sous-Dampjoux,25,Doubs,27,Bourgogne-Franche-Comté
25618,VILLARS SOUS ECOT,25150,VILLARS SOUS ECOT,,47.4190484335,6.69834595037,618,,Villars-sous-Écot,Villars-sous-Écot,25,Doubs,27,Bourgogne-Franche-Comté
25619,LES VILLEDIEU,25240,LES VILLEDIEU,,46.7134756385,6.26609889484,619,Les,Villedieu,Les Villedieu,25,Doubs,27,Bourgogne-Franche-Comté
25620,VILLE DU PONT,25650,VILLE DU PONT,,46.9996929987,6.49767988013,620,,Ville-du-Pont,Ville-du-Pont,25,Doubs,27,Bourgogne-Franche-Comté
25621,VILLENEUVE D AMONT,25270,VILLENEUVE D AMONT,,46.9391063375,6.04167200953,621,,Villeneuve-d'Amont,Villeneuve-d'Amont,25,Doubs,27,Bourgogne-Franche-Comté
25622,VILLERS BUZON,25170,VILLERS BUZON,,47.228690115,5.85222262162,622,,Villers-Buzon,Villers-Buzon,25,Doubs,27,Bourgogne-Franche-Comté
25623,VILLERS CHIEF,25530,VILLERS CHIEF,,47.2235047047,6.43819001348,623,,Villers-Chief,Villers-Chief,25,Doubs,27,Bourgogne-Franche-Comté
25624,VILLERS GRELOT,25640,VILLERS GRELOT,,47.3615500448,6.23513809301,624,,Villers-Grélot,Villers-Grélot,25,Doubs,27,Bourgogne-Franche-Comté
25625,VILLERS LA COMBE,25510,VILLERS LA COMBE,,47.2405589115,6.47308632933,625,,Villers-la-Combe,Villers-la-Combe,25,Doubs,27,Bourgogne-Franche-Comté
25626,VILLERS ST MARTIN,25110,VILLERS ST MARTIN,,47.3422489797,6.40450092241,626,,Villers-Saint-Martin,Villers-Saint-Martin,25,Doubs,27,Bourgogne-Franche-Comté
25627,VILLERS SOUS CHALAMONT,25270,VILLERS SOUS CHALAMONT,,46.9017661774,6.04590580745,627,,Villers-sous-Chalamont,Villers-sous-Chalamont,25,Doubs,27,Bourgogne-Franche-Comté
25628,VILLERS SOUS MONTROND,25620,VILLERS SOUS MONTROND,,47.1453733993,6.08965365162,628,,Villers-sous-Montrond,Villers-sous-Montrond,25,Doubs,27,Bourgogne-Franche-Comté
25629,VOILLANS,25110,VOILLANS,,47.3909535657,6.4185381191,629,,Voillans,Voillans,25,Doubs,27,Bourgogne-Franche-Comté
25630,VOIRES,25580,VOIRES,,47.099837579,6.25737122884,630,,Voires,Voires,25,Doubs,27,Bourgogne-Franche-Comté
25631,VORGES LES PINS,25320,VORGES LES PINS,,47.1527974661,5.92977645633,631,,Vorges-les-Pins,Vorges-les-Pins,25,Doubs,27,Bourgogne-Franche-Comté
25632,VOUJEAUCOURT,25420,VOUJEAUCOURT,,47.4733631824,6.78304416053,632,,Voujeaucourt,Voujeaucourt,25,Doubs,27,Bourgogne-Franche-Comté
25633,VUILLAFANS,25840,VUILLAFANS,,47.063463484,6.22114618291,633,,Vuillafans,Vuillafans,25,Doubs,27,Bourgogne-Franche-Comté
25634,VUILLECIN,25300,VUILLECIN,,46.9530445219,6.3333452653,634,,Vuillecin,Vuillecin,25,Doubs,27,Bourgogne-Franche-Comté
25635,VYT LES BELVOIR,25430,VYT LES BELVOIR,,47.34826508,6.62592546022,635,,Vyt-lès-Belvoir,Vyt-lès-Belvoir,25,Doubs,27,Bourgogne-Franche-Comté
26001,SOLAURE EN DIOIS,26150,SOLAURE EN DIOIS,AIX EN DIOIS,44.7024063065,5.38335100127,1,,Solaure en Diois,Solaure en Diois,26,Drôme,84,Auvergne-Rhône-Alpes
26001,SOLAURE EN DIOIS,26150,SOLAURE EN DIOIS,MOLIERES GLANDAZ,44.7024063065,5.38335100127,1,,Solaure en Diois,Solaure en Diois,26,Drôme,84,Auvergne-Rhône-Alpes
26002,ALBON,26140,ALBON,,45.2388897722,4.86235251807,2,,Albon,Albon,26,Drôme,84,Auvergne-Rhône-Alpes
26003,ALEYRAC,26770,ALEYRAC,,44.4989099133,4.94589832405,3,,Aleyrac,Aleyrac,26,Drôme,84,Auvergne-Rhône-Alpes
26004,ALIXAN,26300,ALIXAN,,44.9822063371,5.01703375369,4,,Alixan,Alixan,26,Drôme,84,Auvergne-Rhône-Alpes
26005,ALLAN,26780,ALLAN,,44.4942339425,4.79847467163,5,,Allan,Allan,26,Drôme,84,Auvergne-Rhône-Alpes
26006,ALLEX,26400,ALLEX,,44.7665515552,4.91520351074,6,,Allex,Allex,26,Drôme,84,Auvergne-Rhône-Alpes
26007,AMBONIL,26800,AMBONIL,,44.7918323658,4.90939338153,7,,Ambonil,Ambonil,26,Drôme,84,Auvergne-Rhône-Alpes
26008,ANCONE,26200,ANCONE,,44.5798033579,4.72035521485,8,,Ancône,Ancône,26,Drôme,84,Auvergne-Rhône-Alpes
26009,ANDANCETTE,26140,ANDANCETTE,,45.2464246163,4.81244292927,9,,Andancette,Andancette,26,Drôme,84,Auvergne-Rhône-Alpes
26009,ANDANCETTE,26140,ANDANCETTE,CREUX DE LA THINE,45.2464246163,4.81244292927,9,,Andancette,Andancette,26,Drôme,84,Auvergne-Rhône-Alpes
26010,ANNEYRON,26140,ANNEYRON,,45.2718637753,4.89169921081,10,,Anneyron,Anneyron,26,Drôme,84,Auvergne-Rhône-Alpes
26011,AOUSTE SUR SYE,26400,AOUSTE SUR SYE,,44.706679476,5.05730534436,11,,Aouste-sur-Sye,Aouste-sur-Sye,26,Drôme,84,Auvergne-Rhône-Alpes
26012,ARNAYON,26470,ARNAYON,,44.4936243979,5.31166231331,12,,Arnayon,Arnayon,26,Drôme,84,Auvergne-Rhône-Alpes
26013,ARPAVON,26110,ARPAVON,,44.370914993,5.27351034083,13,,Arpavon,Arpavon,26,Drôme,84,Auvergne-Rhône-Alpes
26014,ARTHEMONAY,26260,ARTHEMONAY,,45.1410571357,5.06059762904,14,,Arthémonay,Arthémonay,26,Drôme,84,Auvergne-Rhône-Alpes
26015,AUBENASSON,26340,AUBENASSON,,44.6779492335,5.14503680871,15,,Aubenasson,Aubenasson,26,Drôme,84,Auvergne-Rhône-Alpes
26016,AUBRES,26110,AUBRES,,44.3893044332,5.1543771035,16,,Aubres,Aubres,26,Drôme,84,Auvergne-Rhône-Alpes
26017,AUCELON,26340,AUCELON,,44.6170780241,5.35427948419,17,,Aucelon,Aucelon,26,Drôme,84,Auvergne-Rhône-Alpes
26018,AULAN,26570,AULAN,,44.2300209805,5.42219540267,18,,Aulan,Aulan,26,Drôme,84,Auvergne-Rhône-Alpes
26019,AUREL,26340,AUREL,,44.6925597423,5.29816067809,19,,Aurel,Aurel,26,Drôme,84,Auvergne-Rhône-Alpes
26020,LA REPARA AURIPLES,26400,LA REPARA AURIPLES,,44.6614747098,4.9942784244,20,La,Répara-Auriples,La Répara-Auriples,26,Drôme,84,Auvergne-Rhône-Alpes
26021,AUTICHAMP,26400,AUTICHAMP,,44.6832370782,4.97341500658,21,,Autichamp,Autichamp,26,Drôme,84,Auvergne-Rhône-Alpes
26022,BALLONS,26560,BALLONS,,44.2517233325,5.64403556412,22,,Ballons,Ballons,26,Drôme,84,Auvergne-Rhône-Alpes
26023,BARBIERES,26300,BARBIERES,,44.9407100008,5.1621339854,23,,Barbières,Barbières,26,Drôme,84,Auvergne-Rhône-Alpes
26024,BARCELONNE,26120,BARCELONNE,,44.8669475417,5.05494635699,24,,Barcelonne,Barcelonne,26,Drôme,84,Auvergne-Rhône-Alpes
26025,BARNAVE,26310,BARNAVE,,44.6573608737,5.3674248212,25,,Barnave,Barnave,26,Drôme,84,Auvergne-Rhône-Alpes
26026,BARRET DE LIOURE,26570,BARRET DE LIOURE,,44.1754211867,5.51622125908,26,,Barret-de-Lioure,Barret-de-Lioure,26,Drôme,84,Auvergne-Rhône-Alpes
26027,BARSAC,26150,BARSAC,,44.7264116116,5.30407617795,27,,Barsac,Barsac,26,Drôme,84,Auvergne-Rhône-Alpes
26028,BATHERNAY,26260,BATHERNAY,,45.1829648207,4.99799784463,28,,Bathernay,Bathernay,26,Drôme,84,Auvergne-Rhône-Alpes
26030,LA BATIE DES FONDS,26310,LA BATIE DES FONDS,,44.5149199104,5.62968857726,30,La,Bâtie-des-Fonds,La Bâtie-des-Fonds,26,Drôme,84,Auvergne-Rhône-Alpes
26031,LA BATIE ROLLAND,26160,LA BATIE ROLLAND,,44.557639861,4.86691277503,31,La,Bâtie-Rolland,La Bâtie-Rolland,26,Drôme,84,Auvergne-Rhône-Alpes
26032,LA BAUME CORNILLANE,26120,LA BAUME CORNILLANE,,44.8296348413,5.04790034542,32,La,Baume-Cornillane,La Baume-Cornillane,26,Drôme,84,Auvergne-Rhône-Alpes
26033,LA BAUME DE TRANSIT,26790,LA BAUME DE TRANSIT,,44.3277094008,4.86882973562,33,La,Baume-de-Transit,La Baume-de-Transit,26,Drôme,84,Auvergne-Rhône-Alpes
26034,LA BAUME D HOSTUN,26730,LA BAUME D HOSTUN,,45.0617922342,5.22017217257,34,La,Baume-d'Hostun,La Baume-d'Hostun,26,Drôme,84,Auvergne-Rhône-Alpes
26035,BEAUFORT SUR GERVANNE,26400,BEAUFORT SUR GERVANNE,,44.7778627524,5.14914685983,35,,Beaufort-sur-Gervanne,Beaufort-sur-Gervanne,26,Drôme,84,Auvergne-Rhône-Alpes
26036,BEAUMONT EN DIOIS,26310,BEAUMONT EN DIOIS,,44.564352829,5.47041349374,36,,Beaumont-en-Diois,Beaumont-en-Diois,26,Drôme,84,Auvergne-Rhône-Alpes
26037,BEAUMONT LES VALENCE,26760,BEAUMONT LES VALENCE,,44.8734831183,4.9471828168,37,,Beaumont-lès-Valence,Beaumont-lès-Valence,26,Drôme,84,Auvergne-Rhône-Alpes
26038,BEAUMONT MONTEUX,26600,BEAUMONT MONTEUX,,45.0333702322,4.92164362037,38,,Beaumont-Monteux,Beaumont-Monteux,26,Drôme,84,Auvergne-Rhône-Alpes
26039,BEAUREGARD BARET,26300,BEAUREGARD BARET,,45.0062739995,5.17662985645,39,,Beauregard-Baret,Beauregard-Baret,26,Drôme,84,Auvergne-Rhône-Alpes
26040,BEAURIERES,26310,BEAURIERES,,44.5601180012,5.55366934247,40,,Beaurières,Beaurières,26,Drôme,84,Auvergne-Rhône-Alpes
26041,BEAUSEMBLANT,26240,BEAUSEMBLANT,,45.2124337402,4.84068109312,41,,Beausemblant,Beausemblant,26,Drôme,84,Auvergne-Rhône-Alpes
26042,BEAUVALLON,26800,BEAUVALLON,,44.8560524455,4.90520228557,42,,Beauvallon,Beauvallon,26,Drôme,84,Auvergne-Rhône-Alpes
26043,BEAUVOISIN,26170,BEAUVOISIN,,44.3007007366,5.2126241299,43,,Beauvoisin,Beauvoisin,26,Drôme,84,Auvergne-Rhône-Alpes
26045,LA BEGUDE DE MAZENC,26160,LA BEGUDE DE MAZENC,,44.5522749544,4.93110207079,45,La,Bégude-de-Mazenc,La Bégude-de-Mazenc,26,Drôme,84,Auvergne-Rhône-Alpes
26046,BELLECOMBE TARENDOL,26110,BELLECOMBE TARENDOL,,44.3570805263,5.35605853142,46,,Bellecombe-Tarendol,Bellecombe-Tarendol,26,Drôme,84,Auvergne-Rhône-Alpes
26047,BELLEGARDE EN DIOIS,26470,BELLEGARDE EN DIOIS,,44.5369730578,5.43406204674,47,,Bellegarde-en-Diois,Bellegarde-en-Diois,26,Drôme,84,Auvergne-Rhône-Alpes
26048,BENIVAY OLLON,26170,BENIVAY OLLON,,44.3110239354,5.18792865009,48,,Bénivay-Ollon,Bénivay-Ollon,26,Drôme,84,Auvergne-Rhône-Alpes
26049,BESAYES,26300,BESAYES,,44.9656211698,5.08769941869,49,,Bésayes,Bésayes,26,Drôme,84,Auvergne-Rhône-Alpes
26050,BESIGNAN,26110,BESIGNAN,,44.320135403,5.32102321882,50,,Bésignan,Bésignan,26,Drôme,84,Auvergne-Rhône-Alpes
26051,BEZAUDUN SUR BINE,26460,BEZAUDUN SUR BINE,,44.6116512795,5.17571070396,51,,Bézaudun-sur-Bîne,Bézaudun-sur-Bîne,26,Drôme,84,Auvergne-Rhône-Alpes
26052,BONLIEU SUR ROUBION,26160,BONLIEU SUR ROUBION,,44.5876371833,4.86759550989,52,,Bonlieu-sur-Roubion,Bonlieu-sur-Roubion,26,Drôme,84,Auvergne-Rhône-Alpes
26054,BOUCHET,26790,BOUCHET,,44.2980175655,4.88499526137,54,,Bouchet,Bouchet,26,Drôme,84,Auvergne-Rhône-Alpes
26055,BOULC,26410,BOULC,,44.6379699146,5.58442914276,55,,Boulc,Boulc,26,Drôme,84,Auvergne-Rhône-Alpes
26055,BOULC,26410,BOULC,BONNEVAL EN DIOIS,44.6379699146,5.58442914276,55,,Boulc,Boulc,26,Drôme,84,Auvergne-Rhône-Alpes
26055,BOULC,26410,BOULC,RAVEL ET FERRIERS,44.6379699146,5.58442914276,55,,Boulc,Boulc,26,Drôme,84,Auvergne-Rhône-Alpes
26056,BOURDEAUX,26460,BOURDEAUX,,44.5787747922,5.15114352176,56,,Bourdeaux,Bourdeaux,26,Drôme,84,Auvergne-Rhône-Alpes
26057,BOURG DE PEAGE,26300,BOURG DE PEAGE,,45.0171592111,5.05096546169,57,,Bourg-de-Péage,Bourg-de-Péage,26,Drôme,84,Auvergne-Rhône-Alpes
26058,BOURG LES VALENCE,26500,BOURG LES VALENCE,,44.9651094188,4.89411636376,58,,Bourg-lès-Valence,Bourg-lès-Valence,26,Drôme,84,Auvergne-Rhône-Alpes
26059,BOUVANTE,26190,BOUVANTE,,44.9352511723,5.30425747099,59,,Bouvante,Bouvante,26,Drôme,84,Auvergne-Rhône-Alpes
26060,BOUVIERES,26460,BOUVIERES,,44.5208961489,5.22148982488,60,,Bouvières,Bouvières,26,Drôme,84,Auvergne-Rhône-Alpes
26061,BREN,26260,BREN,,45.1431766661,4.94152540117,61,,Bren,Bren,26,Drôme,84,Auvergne-Rhône-Alpes
26062,BRETTE,26340,BRETTE,,44.592205773,5.32569722951,62,,Brette,Brette,26,Drôme,84,Auvergne-Rhône-Alpes
26063,BUIS LES BARONNIES,26170,BUIS LES BARONNIES,,44.276084918,5.27102720538,63,,Buis-les-Baronnies,Buis-les-Baronnies,26,Drôme,84,Auvergne-Rhône-Alpes
26064,CHABEUIL,26120,CHABEUIL,,44.9082255842,5.00743034189,64,,Chabeuil,Chabeuil,26,Drôme,84,Auvergne-Rhône-Alpes
26064,CHABEUIL,26120,CHABEUIL,PARLANGES,44.9082255842,5.00743034189,64,,Chabeuil,Chabeuil,26,Drôme,84,Auvergne-Rhône-Alpes
26065,CHABRILLAN,26400,CHABRILLAN,,44.713669067,4.96072243187,65,,Chabrillan,Chabrillan,26,Drôme,84,Auvergne-Rhône-Alpes
26066,LE CHAFFAL,26190,LE CHAFFAL,,44.8732571795,5.17850564774,66,Le,Chaffal,Le Chaffal,26,Drôme,84,Auvergne-Rhône-Alpes
26066,LE CHAFFAL,26190,LE CHAFFAL,LA VACHERIE,44.8732571795,5.17850564774,66,Le,Chaffal,Le Chaffal,26,Drôme,84,Auvergne-Rhône-Alpes
26067,CHALANCON,26470,CHALANCON,,44.5274785721,5.33947845963,67,,Chalancon,Chalancon,26,Drôme,84,Auvergne-Rhône-Alpes
26068,LE CHALON,26350,LE CHALON,,45.1545545543,5.08934603191,68,Le,Chalon,Le Chalon,26,Drôme,84,Auvergne-Rhône-Alpes
26069,CHAMALOC,26150,CHAMALOC,,44.8119455812,5.38565949855,69,,Chamaloc,Chamaloc,26,Drôme,84,Auvergne-Rhône-Alpes
26070,CHAMARET,26230,CHAMARET,,44.3946636765,4.87604393903,70,,Chamaret,Chamaret,26,Drôme,84,Auvergne-Rhône-Alpes
26071,CHANOS CURSON,26600,CHANOS CURSON,,45.0572285587,4.91832006545,71,,Chanos-Curson,Chanos-Curson,26,Drôme,84,Auvergne-Rhône-Alpes
26072,CHANTEMERLE LES BLES,26600,CHANTEMERLE LES BLES,,45.1232050838,4.88926895841,72,,Chantemerle-les-Blés,Chantemerle-les-Blés,26,Drôme,84,Auvergne-Rhône-Alpes
26073,CHANTEMERLE LES GRIGNAN,26230,CHANTEMERLE LES GRIGNAN,,44.4050171729,4.83512935454,73,,Chantemerle-lès-Grignan,Chantemerle-lès-Grignan,26,Drôme,84,Auvergne-Rhône-Alpes
26074,LA CHAPELLE EN VERCORS,26420,LA CHAPELLE EN VERCORS,,44.9725101475,5.41245391515,74,La,Chapelle-en-Vercors,La Chapelle-en-Vercors,26,Drôme,84,Auvergne-Rhône-Alpes
26074,LA CHAPELLE EN VERCORS,26420,LA CHAPELLE EN VERCORS,LES BARRAQUES EN VERCORS,44.9725101475,5.41245391515,74,La,Chapelle-en-Vercors,La Chapelle-en-Vercors,26,Drôme,84,Auvergne-Rhône-Alpes
26075,LA CHARCE,26470,LA CHARCE,,44.4668723535,5.44466918876,75,La,Charce,La Charce,26,Drôme,84,Auvergne-Rhône-Alpes
26076,CHARENS,26310,CHARENS,,44.5423083646,5.50825607995,76,,Charens,Charens,26,Drôme,84,Auvergne-Rhône-Alpes
26077,CHARMES SUR L HERBASSE,26260,CHARMES SUR L HERBASSE,,45.1571098565,5.0153381269,77,,Charmes-sur-l'Herbasse,Charmes-sur-l'Herbasse,26,Drôme,84,Auvergne-Rhône-Alpes
26078,CHAROLS,26450,CHAROLS,,44.5895894991,4.95411000129,78,,Charols,Charols,26,Drôme,84,Auvergne-Rhône-Alpes
26079,CHARPEY,26300,CHARPEY,,44.9434242792,5.07948371958,79,,Charpey,Charpey,26,Drôme,84,Auvergne-Rhône-Alpes
26079,CHARPEY,26300,CHARPEY,ST DIDIER DE CHARPEY,44.9434242792,5.07948371958,79,,Charpey,Charpey,26,Drôme,84,Auvergne-Rhône-Alpes
26080,CHASTEL ARNAUD,26340,CHASTEL ARNAUD,,44.6594105975,5.20902825087,80,,Chastel-Arnaud,Chastel-Arnaud,26,Drôme,84,Auvergne-Rhône-Alpes
26081,CHATEAUDOUBLE,26120,CHATEAUDOUBLE,,44.8995418259,5.09042220366,81,,Châteaudouble,Châteaudouble,26,Drôme,84,Auvergne-Rhône-Alpes
26082,CHATEAUNEUF DE BORDETTE,26110,CHATEAUNEUF DE BORDETTE,,44.3367876244,5.17129087386,82,,Châteauneuf-de-Bordette,Châteauneuf-de-Bordette,26,Drôme,84,Auvergne-Rhône-Alpes
26083,CHATEAUNEUF DE GALAURE,26330,CHATEAUNEUF DE GALAURE,,45.2359387102,4.95905904864,83,,Châteauneuf-de-Galaure,Châteauneuf-de-Galaure,26,Drôme,84,Auvergne-Rhône-Alpes
26084,CHATEAUNEUF SUR ISERE,26300,CHATEAUNEUF SUR ISERE,,45.0093944288,4.95283719514,84,,Châteauneuf-sur-Isère,Châteauneuf-sur-Isère,26,Drôme,84,Auvergne-Rhône-Alpes
26085,CHATEAUNEUF DU RHONE,26780,CHATEAUNEUF DU RHONE,,44.5034768004,4.72492988631,85,,Châteauneuf-du-Rhône,Châteauneuf-du-Rhône,26,Drôme,84,Auvergne-Rhône-Alpes
26086,CHATILLON EN DIOIS,26410,CHATILLON EN DIOIS,,44.7022249654,5.48618538025,86,,Châtillon-en-Diois,Châtillon-en-Diois,26,Drôme,84,Auvergne-Rhône-Alpes
26087,CHATILLON ST JEAN,26750,CHATILLON ST JEAN,,45.095956421,5.13398617216,87,,Châtillon-Saint-Jean,Châtillon-Saint-Jean,26,Drôme,84,Auvergne-Rhône-Alpes
26088,CHATUZANGE LE GOUBET,26300,CHATUZANGE LE GOUBET,,45.0109934947,5.09724548894,88,,Chatuzange-le-Goubet,Chatuzange-le-Goubet,26,Drôme,84,Auvergne-Rhône-Alpes
26088,CHATUZANGE LE GOUBET,26300,CHATUZANGE LE GOUBET,PIZANCON,45.0109934947,5.09724548894,88,,Chatuzange-le-Goubet,Chatuzange-le-Goubet,26,Drôme,84,Auvergne-Rhône-Alpes
26089,CHAUDEBONNE,26110,CHAUDEBONNE,,44.4749699502,5.23878850401,89,,Chaudebonne,Chaudebonne,26,Drôme,84,Auvergne-Rhône-Alpes
26090,LA CHAUDIERE,26340,LA CHAUDIERE,,44.6310856733,5.22468176139,90,La,Chaudière,La Chaudière,26,Drôme,84,Auvergne-Rhône-Alpes
26091,CHAUVAC LAUX MONTAUX,26510,CHAUVAC LAUX MONTAUX,,44.3222598989,5.53474260728,91,,Chauvac-Laux-Montaux,Chauvac-Laux-Montaux,26,Drôme,84,Auvergne-Rhône-Alpes
26091,CHAUVAC LAUX MONTAUX,26510,CHAUVAC LAUX MONTAUX,LAUX MONTAUX,44.3222598989,5.53474260728,91,,Chauvac-Laux-Montaux,Chauvac-Laux-Montaux,26,Drôme,84,Auvergne-Rhône-Alpes
26092,CHAVANNES,26260,CHAVANNES,,45.1039110552,4.92378813351,92,,Chavannes,Chavannes,26,Drôme,84,Auvergne-Rhône-Alpes
26093,CLANSAYES,26130,CLANSAYES,,44.3776230906,4.80624945597,93,,Clansayes,Clansayes,26,Drôme,84,Auvergne-Rhône-Alpes
26094,CLAVEYSON,26240,CLAVEYSON,,45.1726420303,4.93358199655,94,,Claveyson,Claveyson,26,Drôme,84,Auvergne-Rhône-Alpes
26095,CLEON D ANDRAN,26450,CLEON D ANDRAN,,44.6098641585,4.93231560292,95,,Cléon-d'Andran,Cléon-d'Andran,26,Drôme,84,Auvergne-Rhône-Alpes
26096,CLERIEUX,26260,CLERIEUX,,45.0801761382,4.95477665507,96,,Clérieux,Clérieux,26,Drôme,84,Auvergne-Rhône-Alpes
26097,CLIOUSCLAT,26270,CLIOUSCLAT,,44.7223033154,4.83295265702,97,,Cliousclat,Cliousclat,26,Drôme,84,Auvergne-Rhône-Alpes
26098,COBONNE,26400,COBONNE,,44.7564934592,5.07201219882,98,,Cobonne,Cobonne,26,Drôme,84,Auvergne-Rhône-Alpes
26099,COLONZELLE,26230,COLONZELLE,,44.3849707988,4.89981524521,99,,Colonzelle,Colonzelle,26,Drôme,84,Auvergne-Rhône-Alpes
26100,COMBOVIN,26120,COMBOVIN,,44.8585100129,5.10771313744,100,,Combovin,Combovin,26,Drôme,84,Auvergne-Rhône-Alpes
26101,COMPS,26220,COMPS,,44.5517875724,5.1045697988,101,,Comps,Comps,26,Drôme,84,Auvergne-Rhône-Alpes
26102,CONDILLAC,26740,CONDILLAC,,44.6388037984,4.82780234545,102,,Condillac,Condillac,26,Drôme,84,Auvergne-Rhône-Alpes
26103,CONDORCET,26110,CONDORCET,,44.4150216526,5.17856915513,103,,Condorcet,Condorcet,26,Drôme,84,Auvergne-Rhône-Alpes
26104,CORNILLAC,26510,CORNILLAC,,44.4397962744,5.40427048802,104,,Cornillac,Cornillac,26,Drôme,84,Auvergne-Rhône-Alpes
26105,CORNILLON SUR L OULE,26510,CORNILLON SUR L OULE,,44.4553268167,5.36026006175,105,,Cornillon-sur-l'Oule,Cornillon-sur-l'Oule,26,Drôme,84,Auvergne-Rhône-Alpes
26106,LA COUCOURDE,26740,LA COUCOURDE,,44.6415506371,4.78559017675,106,La,Coucourde,La Coucourde,26,Drôme,84,Auvergne-Rhône-Alpes
26106,LA COUCOURDE,26740,LA COUCOURDE,DERBIERES,44.6415506371,4.78559017675,106,La,Coucourde,La Coucourde,26,Drôme,84,Auvergne-Rhône-Alpes
26107,CREPOL,26350,CREPOL,,45.1749370452,5.06902738769,107,,Crépol,Crépol,26,Drôme,84,Auvergne-Rhône-Alpes
26108,CREST,26400,CREST,,44.7335665907,5.0221758413,108,,Crest,Crest,26,Drôme,84,Auvergne-Rhône-Alpes
26110,CROZES HERMITAGE,26600,CROZES HERMITAGE,,45.0984942531,4.844233369,110,,Crozes-Hermitage,Crozes-Hermitage,26,Drôme,84,Auvergne-Rhône-Alpes
26111,CRUPIES,26460,CRUPIES,,44.5539294848,5.18083316886,111,,Crupies,Crupies,26,Drôme,84,Auvergne-Rhône-Alpes
26112,CURNIER,26110,CURNIER,,44.387658631,5.23421106174,112,,Curnier,Curnier,26,Drôme,84,Auvergne-Rhône-Alpes
26113,DIE,26150,DIE,,44.7482217645,5.37640774818,113,,Die,Die,26,Drôme,84,Auvergne-Rhône-Alpes
26114,DIEULEFIT,26220,DIEULEFIT,,44.5310329912,5.06528375642,114,,Dieulefit,Dieulefit,26,Drôme,84,Auvergne-Rhône-Alpes
26115,DIVAJEU,26400,DIVAJEU,,44.6994155653,5.00951244768,115,,Divajeu,Divajeu,26,Drôme,84,Auvergne-Rhône-Alpes
26116,DONZERE,26290,DONZERE,,44.4321090221,4.70892869285,116,,Donzère,Donzère,26,Drôme,84,Auvergne-Rhône-Alpes
26117,ECHEVIS,26190,ECHEVIS,,45.024234316,5.38546643164,117,,Échevis,Échevis,26,Drôme,84,Auvergne-Rhône-Alpes
26118,EPINOUZE,26210,EPINOUZE,,45.3116175099,4.93837201077,118,,Épinouze,Épinouze,26,Drôme,84,Auvergne-Rhône-Alpes
26119,EROME,26600,EROME,,45.1267358937,4.8356936988,119,,Érôme,Érôme,26,Drôme,84,Auvergne-Rhône-Alpes
26121,ESPELUCHE,26780,ESPELUCHE,,44.521406146,4.82247674959,121,,Espeluche,Espeluche,26,Drôme,84,Auvergne-Rhône-Alpes
26122,ESPENEL,26340,ESPENEL,,44.6853594311,5.23465969716,122,,Espenel,Espenel,26,Drôme,84,Auvergne-Rhône-Alpes
26123,ESTABLET,26470,ESTABLET,,44.5021554085,5.4402415482,123,,Establet,Establet,26,Drôme,84,Auvergne-Rhône-Alpes
26124,ETOILE SUR RHONE,26800,ETOILE SUR RHONE,,44.8313893504,4.87476252885,124,,Étoile-sur-Rhône,Étoile-sur-Rhône,26,Drôme,84,Auvergne-Rhône-Alpes
26124,ETOILE SUR RHONE,26800,ETOILE SUR RHONE,LA PAILLASSE,44.8313893504,4.87476252885,124,,Étoile-sur-Rhône,Étoile-sur-Rhône,26,Drôme,84,Auvergne-Rhône-Alpes
26125,EURRE,26400,EURRE,,44.758262486,4.97623533568,125,,Eurre,Eurre,26,Drôme,84,Auvergne-Rhône-Alpes
26126,EYGALAYES,26560,EYGALAYES,,44.2211440236,5.59423968429,126,,Eygalayes,Eygalayes,26,Drôme,84,Auvergne-Rhône-Alpes
26127,EYGALIERS,26170,EYGALIERS,,44.2403379768,5.28466764247,127,,Eygaliers,Eygaliers,26,Drôme,84,Auvergne-Rhône-Alpes
26128,EYGLUY ESCOULIN,26400,EYGLUY ESCOULIN,,44.7982493088,5.20984977249,128,,Eygluy-Escoulin,Eygluy-Escoulin,26,Drôme,84,Auvergne-Rhône-Alpes
26129,EYMEUX,26730,EYMEUX,,45.0663071333,5.18254259284,129,,Eymeux,Eymeux,26,Drôme,84,Auvergne-Rhône-Alpes
26129,EYMEUX,26730,EYMEUX,L ECANCIERE,45.0663071333,5.18254259284,129,,Eymeux,Eymeux,26,Drôme,84,Auvergne-Rhône-Alpes
26130,EYROLES,26110,EYROLES,,44.417094329,5.24106129822,130,,Eyroles,Eyroles,26,Drôme,84,Auvergne-Rhône-Alpes
26131,EYZAHUT,26160,EYZAHUT,,44.5613261494,5.00354083733,131,,Eyzahut,Eyzahut,26,Drôme,84,Auvergne-Rhône-Alpes
26133,FAY LE CLOS,26240,FAY LE CLOS,,45.2202693272,4.90751711804,133,,Fay-le-Clos,Fay-le-Clos,26,Drôme,84,Auvergne-Rhône-Alpes
26134,FELINES SUR RIMANDOULE,26160,FELINES SUR RIMANDOULE,,44.5855071367,5.05544129691,134,,Félines-sur-Rimandoule,Félines-sur-Rimandoule,26,Drôme,84,Auvergne-Rhône-Alpes
26135,FERRASSIERES,26570,FERRASSIERES,,44.1391681651,5.49040783709,135,,Ferrassières,Ferrassières,26,Drôme,84,Auvergne-Rhône-Alpes
26136,VAL MARAVEL,26310,VAL MARAVEL,,44.5919888409,5.58664096693,136,,Val-Maravel,Val-Maravel,26,Drôme,84,Auvergne-Rhône-Alpes
26136,VAL MARAVEL,26310,VAL MARAVEL,LA BATIE CREMEZIN,44.5919888409,5.58664096693,136,,Val-Maravel,Val-Maravel,26,Drôme,84,Auvergne-Rhône-Alpes
26136,VAL MARAVEL,26310,VAL MARAVEL,LE PILHON,44.5919888409,5.58664096693,136,,Val-Maravel,Val-Maravel,26,Drôme,84,Auvergne-Rhône-Alpes
26137,FRANCILLON SUR ROUBION,26400,FRANCILLON SUR ROUBION,,44.6168821671,5.07198362932,137,,Francillon-sur-Roubion,Francillon-sur-Roubion,26,Drôme,84,Auvergne-Rhône-Alpes
26138,LA GARDE ADHEMAR,26700,LA GARDE ADHEMAR,,44.3910321281,4.74995521601,138,La,Garde-Adhémar,La Garde-Adhémar,26,Drôme,84,Auvergne-Rhône-Alpes
26139,GENISSIEUX,26750,GENISSIEUX,,45.0819148,5.09023746222,139,,Génissieux,Génissieux,26,Drôme,84,Auvergne-Rhône-Alpes
26140,GEYSSANS,26750,GEYSSANS,,45.1234537531,5.09201692077,140,,Geyssans,Geyssans,26,Drôme,84,Auvergne-Rhône-Alpes
26141,GIGORS ET LOZERON,26400,GIGORS ET LOZERON,,44.8113617403,5.11358630739,141,,Gigors-et-Lozeron,Gigors-et-Lozeron,26,Drôme,84,Auvergne-Rhône-Alpes
26142,GLANDAGE,26410,GLANDAGE,,44.6985798592,5.61337993572,142,,Glandage,Glandage,26,Drôme,84,Auvergne-Rhône-Alpes
26143,LE GRAND SERRE,26530,LE GRAND SERRE,,45.2625506689,5.10224795583,143,Le,Grand-Serre,Le Grand-Serre,26,Drôme,84,Auvergne-Rhône-Alpes
26144,GRANE,26400,GRANE,,44.7208645789,4.89731877792,144,,Grane,Grane,26,Drôme,84,Auvergne-Rhône-Alpes
26145,LES GRANGES GONTARDES,26290,LES GRANGES GONTARDES,,44.4276255565,4.75667290767,145,Les,Granges-Gontardes,Les Granges-Gontardes,26,Drôme,84,Auvergne-Rhône-Alpes
26146,GRIGNAN,26230,GRIGNAN,,44.4369175751,4.90180143483,146,,Grignan,Grignan,26,Drôme,84,Auvergne-Rhône-Alpes
26147,GUMIANE,26470,GUMIANE,,44.5072339417,5.26749702819,147,,Gumiane,Gumiane,26,Drôme,84,Auvergne-Rhône-Alpes
26148,HAUTERIVES,26390,HAUTERIVES,,45.2531318961,5.03890051162,148,,Hauterives,Hauterives,26,Drôme,84,Auvergne-Rhône-Alpes
26149,HOSTUN,26730,HOSTUN,,45.0322321943,5.20271143898,149,,Hostun,Hostun,26,Drôme,84,Auvergne-Rhône-Alpes
26150,IZON LA BRUISSE,26560,IZON LA BRUISSE,,44.2626520875,5.60226769891,150,,Izon-la-Bruisse,Izon-la-Bruisse,26,Drôme,84,Auvergne-Rhône-Alpes
26152,JONCHERES,26310,JONCHERES,,44.5654041673,5.40190869751,152,,Jonchères,Jonchères,26,Drôme,84,Auvergne-Rhône-Alpes
26153,LABOREL,26560,LABOREL,,44.2884885903,5.59159509018,153,,Laborel,Laborel,26,Drôme,84,Auvergne-Rhône-Alpes
26154,LACHAU,26560,LACHAU,,44.2161718587,5.65182288638,154,,Lachau,Lachau,26,Drôme,84,Auvergne-Rhône-Alpes
26155,LAPEYROUSE MORNAY,26210,LAPEYROUSE MORNAY,,45.3275989913,4.98910838258,155,,Lapeyrouse-Mornay,Lapeyrouse-Mornay,26,Drôme,84,Auvergne-Rhône-Alpes
26156,LARNAGE,26600,LARNAGE,,45.0989410685,4.86289979161,156,,Larnage,Larnage,26,Drôme,84,Auvergne-Rhône-Alpes
26157,LA LAUPIE,26740,LA LAUPIE,,44.608225236,4.85111658916,157,La,Laupie,La Laupie,26,Drôme,84,Auvergne-Rhône-Alpes
26159,LAVAL D AIX,26150,LAVAL D AIX,,44.7360932981,5.45230545498,159,,Laval-d'Aix,Laval-d'Aix,26,Drôme,84,Auvergne-Rhône-Alpes
26160,LAVEYRON,26240,LAVEYRON,,45.2037746922,4.81998155798,160,,Laveyron,Laveyron,26,Drôme,84,Auvergne-Rhône-Alpes
26161,LEMPS,26510,LEMPS,,44.3531580457,5.41264543298,161,,Lemps,Lemps,26,Drôme,84,Auvergne-Rhône-Alpes
26162,LENS LESTANG,26210,LENS LESTANG,,45.2960581981,5.04115943203,162,,Lens-Lestang,Lens-Lestang,26,Drôme,84,Auvergne-Rhône-Alpes
26163,LEONCEL,26190,LEONCEL,,44.9241441002,5.20544856273,163,,Léoncel,Léoncel,26,Drôme,84,Auvergne-Rhône-Alpes
26164,LESCHES EN DIOIS,26310,LESCHES EN DIOIS,,44.5936637073,5.51737877593,164,,Lesches-en-Diois,Lesches-en-Diois,26,Drôme,84,Auvergne-Rhône-Alpes
26165,LIVRON SUR DROME,26250,LIVRON SUR DROME,,44.7874186099,4.83899378303,165,,Livron-sur-Drôme,Livron-sur-Drôme,26,Drôme,84,Auvergne-Rhône-Alpes
26165,LIVRON SUR DROME,26250,LIVRON SUR DROME,LES PETITS ROBINS,44.7874186099,4.83899378303,165,,Livron-sur-Drôme,Livron-sur-Drôme,26,Drôme,84,Auvergne-Rhône-Alpes
26166,LORIOL SUR DROME,26270,LORIOL SUR DROME,,44.7499742267,4.80530615488,166,,Loriol-sur-Drôme,Loriol-sur-Drôme,26,Drôme,84,Auvergne-Rhône-Alpes
26167,LUC EN DIOIS,26310,LUC EN DIOIS,,44.6107770597,5.45872622815,167,,Luc-en-Diois,Luc-en-Diois,26,Drôme,84,Auvergne-Rhône-Alpes
26168,LUS LA CROIX HAUTE,26620,LUS LA CROIX HAUTE,,44.6828031377,5.72316122016,168,,Lus-la-Croix-Haute,Lus-la-Croix-Haute,26,Drôme,84,Auvergne-Rhône-Alpes
26169,MALATAVERNE,26780,MALATAVERNE,,44.4735237549,4.74537048186,169,,Malataverne,Malataverne,26,Drôme,84,Auvergne-Rhône-Alpes
26170,MALISSARD,26120,MALISSARD,,44.9007368084,4.95855438773,170,,Malissard,Malissard,26,Drôme,84,Auvergne-Rhône-Alpes
26171,MANAS,26160,MANAS,,44.6017015334,4.97988711048,171,,Manas,Manas,26,Drôme,84,Auvergne-Rhône-Alpes
26172,MANTHES,26210,MANTHES,,45.3074924961,5.00340183347,172,,Manthes,Manthes,26,Drôme,84,Auvergne-Rhône-Alpes
26173,MARCHES,26300,MARCHES,,44.9785812313,5.1153753818,173,,Marches,Marches,26,Drôme,84,Auvergne-Rhône-Alpes
26174,MARGES,26260,MARGES,,45.1319031248,5.03106608255,174,,Margès,Margès,26,Drôme,84,Auvergne-Rhône-Alpes
26175,MARIGNAC EN DIOIS,26150,MARIGNAC EN DIOIS,,44.8102170504,5.3344028956,175,,Marignac-en-Diois,Marignac-en-Diois,26,Drôme,84,Auvergne-Rhône-Alpes
26176,MARSANNE,26740,MARSANNE,,44.640273082,4.88311372596,176,,Marsanne,Marsanne,26,Drôme,84,Auvergne-Rhône-Alpes
26177,MARSAZ,26260,MARSAZ,,45.1211976778,4.932214307,177,,Marsaz,Marsaz,26,Drôme,84,Auvergne-Rhône-Alpes
26178,MENGLON,26410,MENGLON,,44.6581977014,5.47305848726,178,,Menglon,Menglon,26,Drôme,84,Auvergne-Rhône-Alpes
26179,MERCUROL VEAUNES,26600,MERCUROL VEAUNES,,45.0714881151,4.88879562324,179,,Mercurol-Veaunes,Mercurol-Veaunes,26,Drôme,84,Auvergne-Rhône-Alpes
26179,MERCUROL VEAUNES,26600,MERCUROL VEAUNES,VEAUNES,45.0714881151,4.88879562324,179,,Mercurol-Veaunes,Mercurol-Veaunes,26,Drôme,84,Auvergne-Rhône-Alpes
26180,MERINDOL LES OLIVIERS,26170,MERINDOL LES OLIVIERS,,44.2725055479,5.1684408091,180,,Mérindol-les-Oliviers,Mérindol-les-Oliviers,26,Drôme,84,Auvergne-Rhône-Alpes
26181,MEVOUILLON,26560,MEVOUILLON,,44.2346171686,5.47885128912,181,,Mévouillon,Mévouillon,26,Drôme,84,Auvergne-Rhône-Alpes
26182,MIRABEL AUX BARONNIES,26110,MIRABEL AUX BARONNIES,,44.3146283758,5.10177171848,182,,Mirabel-aux-Baronnies,Mirabel-aux-Baronnies,26,Drôme,84,Auvergne-Rhône-Alpes
26183,MIRABEL ET BLACONS,26400,MIRABEL ET BLACONS,,44.7140812623,5.12302837932,183,,Mirabel-et-Blacons,Mirabel-et-Blacons,26,Drôme,84,Auvergne-Rhône-Alpes
26184,MIRIBEL,26350,MIRIBEL,,45.1976909238,5.10654579635,184,,Miribel,Miribel,26,Drôme,84,Auvergne-Rhône-Alpes
26185,MIRMANDE,26270,MIRMANDE,,44.6886093237,4.844585911,185,,Mirmande,Mirmande,26,Drôme,84,Auvergne-Rhône-Alpes
26186,MISCON,26310,MISCON,,44.6243253261,5.51425703083,186,,Miscon,Miscon,26,Drôme,84,Auvergne-Rhône-Alpes
26188,MOLLANS SUR OUVEZE,26170,MOLLANS SUR OUVEZE,,44.2339086816,5.19464049254,188,,Mollans-sur-Ouvèze,Mollans-sur-Ouvèze,26,Drôme,84,Auvergne-Rhône-Alpes
26189,MONTAUBAN SUR L OUVEZE,26170,MONTAUBAN SUR L OUVEZE,,44.2773478714,5.51851244253,189,,Montauban-sur-l'Ouvèze,Montauban-sur-l'Ouvèze,26,Drôme,84,Auvergne-Rhône-Alpes
26190,MONTAULIEU,26110,MONTAULIEU,,44.3578984951,5.21799658575,190,,Montaulieu,Montaulieu,26,Drôme,84,Auvergne-Rhône-Alpes
26191,MONTBOUCHER SUR JABRON,26740,MONTBOUCHER SUR JABRON,,44.5542986445,4.8150831884,191,,Montboucher-sur-Jabron,Montboucher-sur-Jabron,26,Drôme,84,Auvergne-Rhône-Alpes
26192,MONTBRISON SUR LEZ,26770,MONTBRISON SUR LEZ,,44.4341876361,5.02273025287,192,,Montbrison-sur-Lez,Montbrison-sur-Lez,26,Drôme,84,Auvergne-Rhône-Alpes
26193,MONTBRUN LES BAINS,26570,MONTBRUN LES BAINS,,44.1877148289,5.43426917221,193,,Montbrun-les-Bains,Montbrun-les-Bains,26,Drôme,84,Auvergne-Rhône-Alpes
26194,MONTCHENU,26350,MONTCHENU,,45.1920320499,5.03982894227,194,,Montchenu,Montchenu,26,Drôme,84,Auvergne-Rhône-Alpes
26195,MONTCLAR SUR GERVANNE,26400,MONTCLAR SUR GERVANNE,,44.7478398549,5.15785545996,195,,Montclar-sur-Gervanne,Montclar-sur-Gervanne,26,Drôme,84,Auvergne-Rhône-Alpes
26196,MONTELEGER,26760,MONTELEGER,,44.8558469973,4.92784684928,196,,Montéléger,Montéléger,26,Drôme,84,Auvergne-Rhône-Alpes
26197,MONTELIER,26120,MONTELIER,,44.9426423498,5.02096570564,197,,Montélier,Montélier,26,Drôme,84,Auvergne-Rhône-Alpes
26197,MONTELIER,26120,MONTELIER,FAUCONNIERES,44.9426423498,5.02096570564,197,,Montélier,Montélier,26,Drôme,84,Auvergne-Rhône-Alpes
26198,MONTELIMAR,26200,MONTELIMAR,,44.5540845734,4.74869387765,198,,Montélimar,Montélimar,26,Drôme,84,Auvergne-Rhône-Alpes
26199,MONTFERRAND LA FARE,26510,MONTFERRAND LA FARE,,44.3419027323,5.44998013402,199,,Montferrand-la-Fare,Montferrand-la-Fare,26,Drôme,84,Auvergne-Rhône-Alpes
26200,MONTFROC,26560,MONTFROC,,44.1708554906,5.64511167638,200,,Montfroc,Montfroc,26,Drôme,84,Auvergne-Rhône-Alpes
26201,MONTGUERS,26170,MONTGUERS,,44.301845332,5.47245831893,201,,Montguers,Montguers,26,Drôme,84,Auvergne-Rhône-Alpes
26202,MONTJOUX,26220,MONTJOUX,,44.4893401386,5.10300091233,202,,Montjoux,Montjoux,26,Drôme,84,Auvergne-Rhône-Alpes
26203,MONTJOYER,26230,MONTJOYER,,44.4826616889,4.87794122757,203,,Montjoyer,Montjoyer,26,Drôme,84,Auvergne-Rhône-Alpes
26204,MONTLAUR EN DIOIS,26310,MONTLAUR EN DIOIS,,44.6269804686,5.41568808413,204,,Montlaur-en-Diois,Montlaur-en-Diois,26,Drôme,84,Auvergne-Rhône-Alpes
26205,MONTMAUR EN DIOIS,26150,MONTMAUR EN DIOIS,,44.6780171902,5.36466101369,205,,Montmaur-en-Diois,Montmaur-en-Diois,26,Drôme,84,Auvergne-Rhône-Alpes
26206,MONTMEYRAN,26120,MONTMEYRAN,,44.8361396041,4.98453227246,206,,Montmeyran,Montmeyran,26,Drôme,84,Auvergne-Rhône-Alpes
26207,MONTMIRAL,26750,MONTMIRAL,,45.1534746191,5.16002782914,207,,Montmiral,Montmiral,26,Drôme,84,Auvergne-Rhône-Alpes
26208,MONTOISON,26800,MONTOISON,,44.7975529073,4.93043285806,208,,Montoison,Montoison,26,Drôme,84,Auvergne-Rhône-Alpes
26209,MONTREAL LES SOURCES,26510,MONTREAL LES SOURCES,,44.4006997235,5.30675272365,209,,Montréal-les-Sources,Montréal-les-Sources,26,Drôme,84,Auvergne-Rhône-Alpes
26210,MONTRIGAUD,26350,MONTRIGAUD,,45.2264302385,5.14289630898,210,,Montrigaud,Montrigaud,26,Drôme,84,Auvergne-Rhône-Alpes
26211,MONTSEGUR SUR LAUZON,26130,MONTSEGUR SUR LAUZON,,44.3674930757,4.85732107832,211,,Montségur-sur-Lauzon,Montségur-sur-Lauzon,26,Drôme,84,Auvergne-Rhône-Alpes
26212,MONTVENDRE,26120,MONTVENDRE,,44.8660693577,5.01205997736,212,,Montvendre,Montvendre,26,Drôme,84,Auvergne-Rhône-Alpes
26213,MORAS EN VALLOIRE,26210,MORAS EN VALLOIRE,,45.2853226876,4.99962084513,213,,Moras-en-Valloire,Moras-en-Valloire,26,Drôme,84,Auvergne-Rhône-Alpes
26214,MORNANS,26460,MORNANS,,44.6208884748,5.13309651112,214,,Mornans,Mornans,26,Drôme,84,Auvergne-Rhône-Alpes
26215,LA MOTTE CHALANCON,26470,LA MOTTE CHALANCON,,44.4953213004,5.38478825225,215,La,Motte-Chalancon,La Motte-Chalancon,26,Drôme,84,Auvergne-Rhône-Alpes
26216,LA MOTTE DE GALAURE,26240,LA MOTTE DE GALAURE,,45.1987797497,4.90084017437,216,La,Motte-de-Galaure,La Motte-de-Galaure,26,Drôme,84,Auvergne-Rhône-Alpes
26217,LA MOTTE FANJAS,26190,LA MOTTE FANJAS,,45.0452802942,5.27059572584,217,La,Motte-Fanjas,La Motte-Fanjas,26,Drôme,84,Auvergne-Rhône-Alpes
26218,MOURS ST EUSEBE,26540,MOURS ST EUSEBE,,45.0710106759,5.05923924282,218,,Mours-Saint-Eusèbe,Mours-Saint-Eusèbe,26,Drôme,84,Auvergne-Rhône-Alpes
26219,MUREILS,26240,MUREILS,,45.2154706138,4.92828423422,219,,Mureils,Mureils,26,Drôme,84,Auvergne-Rhône-Alpes
26220,NYONS,26110,NYONS,,44.3564879814,5.12880832,220,,Nyons,Nyons,26,Drôme,84,Auvergne-Rhône-Alpes
26221,OMBLEZE,26400,OMBLEZE,,44.8638431214,5.23078207101,221,,Omblèze,Omblèze,26,Drôme,84,Auvergne-Rhône-Alpes
26222,ORCINAS,26220,ORCINAS,,44.5484783973,5.13762227553,222,,Orcinas,Orcinas,26,Drôme,84,Auvergne-Rhône-Alpes
26223,ORIOL EN ROYANS,26190,ORIOL EN ROYANS,,44.9897441027,5.25074981876,223,,Oriol-en-Royans,Oriol-en-Royans,26,Drôme,84,Auvergne-Rhône-Alpes
26224,OURCHES,26120,OURCHES,,44.8061327174,5.04664200561,224,,Ourches,Ourches,26,Drôme,84,Auvergne-Rhône-Alpes
26225,PARNANS,26750,PARNANS,,45.1132787214,5.15915280668,225,,Parnans,Parnans,26,Drôme,84,Auvergne-Rhône-Alpes
26226,LE PEGUE,26770,LE PEGUE,,44.4443252877,5.06911053601,226,Le,Pègue,Le Pègue,26,Drôme,84,Auvergne-Rhône-Alpes
26227,PELONNE,26510,PELONNE,,44.3822334481,5.38062257159,227,,Pelonne,Pelonne,26,Drôme,84,Auvergne-Rhône-Alpes
26228,PENNES LE SEC,26340,PENNES LE SEC,,44.6368369642,5.32049274034,228,,Pennes-le-Sec,Pennes-le-Sec,26,Drôme,84,Auvergne-Rhône-Alpes
26229,LA PENNE SUR L OUVEZE,26170,LA PENNE SUR L OUVEZE,,44.2503681815,5.23252395755,229,La,Penne-sur-l'Ouvèze,La Penne-sur-l'Ouvèze,26,Drôme,84,Auvergne-Rhône-Alpes
26231,PEYRINS,26380,PEYRINS,,45.0998567072,5.04564040404,231,,Peyrins,Peyrins,26,Drôme,84,Auvergne-Rhône-Alpes
26232,PEYRUS,26120,PEYRUS,,44.9069574255,5.12184117739,232,,Peyrus,Peyrus,26,Drôme,84,Auvergne-Rhône-Alpes
26233,PIEGON,26110,PIEGON,,44.298616176,5.12425949306,233,,Piégon,Piégon,26,Drôme,84,Auvergne-Rhône-Alpes
26234,PIEGROS LA CLASTRE,26400,PIEGROS LA CLASTRE,,44.6852387812,5.09750399955,234,,Piégros-la-Clastre,Piégros-la-Clastre,26,Drôme,84,Auvergne-Rhône-Alpes
26235,PIERRELATTE,26700,PIERRELATTE,,44.3606937732,4.68715113615,235,,Pierrelatte,Pierrelatte,26,Drôme,84,Auvergne-Rhône-Alpes
26236,PIERRELONGUE,26170,PIERRELONGUE,,44.2495510618,5.21417355869,236,,Pierrelongue,Pierrelongue,26,Drôme,84,Auvergne-Rhône-Alpes
26238,LES PILLES,26110,LES PILLES,,44.3778726864,5.19915828289,238,Les,Pilles,Les Pilles,26,Drôme,84,Auvergne-Rhône-Alpes
26239,PLAISIANS,26170,PLAISIANS,,44.2251384838,5.337199685,239,,Plaisians,Plaisians,26,Drôme,84,Auvergne-Rhône-Alpes
26240,PLAN DE BAIX,26400,PLAN DE BAIX,,44.824829666,5.16640460986,240,,Plan-de-Baix,Plan-de-Baix,26,Drôme,84,Auvergne-Rhône-Alpes
26241,LE POET CELARD,26460,LE POET CELARD,,44.5979359897,5.10082918034,241,Le,Poët-Célard,Le Poët-Célard,26,Drôme,84,Auvergne-Rhône-Alpes
26242,LE POET EN PERCIP,26170,LE POET EN PERCIP,,44.2484174261,5.39418333523,242,Le,Poët-en-Percip,Le Poët-en-Percip,26,Drôme,84,Auvergne-Rhône-Alpes
26243,LE POET LAVAL,26160,LE POET LAVAL,,44.5259749457,5.00162993339,243,Le,Poët-Laval,Le Poët-Laval,26,Drôme,84,Auvergne-Rhône-Alpes
26244,LE POET SIGILLAT,26110,LE POET SIGILLAT,,44.3631938216,5.31860156663,244,Le,Poët-Sigillat,Le Poët-Sigillat,26,Drôme,84,Auvergne-Rhône-Alpes
26245,POMMEROL,26470,POMMEROL,,44.4372174843,5.46497895783,245,,Pommerol,Pommerol,26,Drôme,84,Auvergne-Rhône-Alpes
26246,PONET ET ST AUBAN,26150,PONET ET ST AUBAN,,44.7786630225,5.31353103618,246,,Ponet-et-Saint-Auban,Ponet-et-Saint-Auban,26,Drôme,84,Auvergne-Rhône-Alpes
26247,PONSAS,26240,PONSAS,,45.1572964733,4.84232290007,247,,Ponsas,Ponsas,26,Drôme,84,Auvergne-Rhône-Alpes
26248,PONTAIX,26150,PONTAIX,,44.7530320262,5.25667519527,248,,Pontaix,Pontaix,26,Drôme,84,Auvergne-Rhône-Alpes
26249,PONT DE BARRET,26160,PONT DE BARRET,,44.5935802494,5.00432288162,249,,Pont-de-Barret,Pont-de-Barret,26,Drôme,84,Auvergne-Rhône-Alpes
26250,PONT DE L ISERE,26600,PONT DE L ISERE,,45.015193999,4.88159850923,250,,Pont-de-l'Isère,Pont-de-l'Isère,26,Drôme,84,Auvergne-Rhône-Alpes
26251,PORTES EN VALDAINE,26160,PORTES EN VALDAINE,,44.5218419344,4.91432737685,251,,Portes-en-Valdaine,Portes-en-Valdaine,26,Drôme,84,Auvergne-Rhône-Alpes
26252,PORTES LES VALENCE,26800,PORTES LES VALENCE,,44.8757833249,4.88408311324,252,,Portes-lès-Valence,Portes-lès-Valence,26,Drôme,84,Auvergne-Rhône-Alpes
26253,POYOLS,26310,POYOLS,,44.5978410842,5.41795435334,253,,Poyols,Poyols,26,Drôme,84,Auvergne-Rhône-Alpes
26254,PRADELLE,26340,PRADELLE,,44.6207509256,5.28093626176,254,,Pradelle,Pradelle,26,Drôme,84,Auvergne-Rhône-Alpes
26255,LES PRES,26310,LES PRES,,44.5345063882,5.57603358262,255,Les,Prés,Les Prés,26,Drôme,84,Auvergne-Rhône-Alpes
26256,PROPIAC,26170,PROPIAC,,44.276584707,5.20940872423,256,,Propiac,Propiac,26,Drôme,84,Auvergne-Rhône-Alpes
26257,PUYGIRON,26160,PUYGIRON,,44.5400904645,4.85331314988,257,,Puygiron,Puygiron,26,Drôme,84,Auvergne-Rhône-Alpes
26258,PUY ST MARTIN,26450,PUY ST MARTIN,,44.6239658602,4.97170950071,258,,Puy-Saint-Martin,Puy-Saint-Martin,26,Drôme,84,Auvergne-Rhône-Alpes
26259,RATIERES,26330,RATIERES,,45.1750778592,4.97082735127,259,,Ratières,Ratières,26,Drôme,84,Auvergne-Rhône-Alpes
26261,REAUVILLE,26230,REAUVILLE,,44.4518475355,4.83915693075,261,,Réauville,Réauville,26,Drôme,84,Auvergne-Rhône-Alpes
26262,RECOUBEAU JANSAC,26310,RECOUBEAU JANSAC,,44.6516625854,5.40361308462,262,,Recoubeau-Jansac,Recoubeau-Jansac,26,Drôme,84,Auvergne-Rhône-Alpes
26262,RECOUBEAU JANSAC,26310,RECOUBEAU JANSAC,JANSAC,44.6516625854,5.40361308462,262,,Recoubeau-Jansac,Recoubeau-Jansac,26,Drôme,84,Auvergne-Rhône-Alpes
26263,REILHANETTE,26570,REILHANETTE,,44.1712233115,5.40493973585,263,,Reilhanette,Reilhanette,26,Drôme,84,Auvergne-Rhône-Alpes
26264,REMUZAT,26510,REMUZAT,,44.4038075452,5.35604206561,264,,Rémuzat,Rémuzat,26,Drôme,84,Auvergne-Rhône-Alpes
26266,RIMON ET SAVEL,26340,RIMON ET SAVEL,,44.6628049106,5.31268642432,266,,Rimon-et-Savel,Rimon-et-Savel,26,Drôme,84,Auvergne-Rhône-Alpes
26267,RIOMS,26170,RIOMS,,44.2699144259,5.46075081243,267,,Rioms,Rioms,26,Drôme,84,Auvergne-Rhône-Alpes
26268,ROCHEBAUDIN,26160,ROCHEBAUDIN,,44.5748560489,5.03096278757,268,,Rochebaudin,Rochebaudin,26,Drôme,84,Auvergne-Rhône-Alpes
26269,ROCHEBRUNE,26110,ROCHEBRUNE,,44.3264290154,5.23698675047,269,,Rochebrune,Rochebrune,26,Drôme,84,Auvergne-Rhône-Alpes
26270,ROCHECHINARD,26190,ROCHECHINARD,,45.0272825113,5.24326609957,270,,Rochechinard,Rochechinard,26,Drôme,84,Auvergne-Rhône-Alpes
26271,LA ROCHE DE GLUN,26600,LA ROCHE DE GLUN,,45.0186564711,4.85709493142,271,La,Roche-de-Glun,La Roche-de-Glun,26,Drôme,84,Auvergne-Rhône-Alpes
26272,ROCHEFORT EN VALDAINE,26160,ROCHEFORT EN VALDAINE,,44.5125480322,4.85754422248,272,,Rochefort-en-Valdaine,Rochefort-en-Valdaine,26,Drôme,84,Auvergne-Rhône-Alpes
26273,ROCHEFORT SAMSON,26300,ROCHEFORT SAMSON,,44.975050381,5.16619542188,273,,Rochefort-Samson,Rochefort-Samson,26,Drôme,84,Auvergne-Rhône-Alpes
26274,ROCHEFOURCHAT,26340,ROCHEFOURCHAT,,44.6049174484,5.2414538181,274,,Rochefourchat,Rochefourchat,26,Drôme,84,Auvergne-Rhône-Alpes
26275,ROCHEGUDE,26790,ROCHEGUDE,,44.2556674573,4.83716476858,275,,Rochegude,Rochegude,26,Drôme,84,Auvergne-Rhône-Alpes
26276,ROCHE ST SECRET BECONNE,26770,ROCHE ST SECRET BECONNE,,44.4794711138,5.04276010512,276,,Roche-Saint-Secret-Béconne,Roche-Saint-Secret-Béconne,26,Drôme,84,Auvergne-Rhône-Alpes
26276,ROCHE ST SECRET BECONNE,26770,ROCHE ST SECRET BECONNE,BECONNE,44.4794711138,5.04276010512,276,,Roche-Saint-Secret-Béconne,Roche-Saint-Secret-Béconne,26,Drôme,84,Auvergne-Rhône-Alpes
26277,LA ROCHE SUR GRANE,26400,LA ROCHE SUR GRANE,,44.6750858387,4.94016512732,277,La,Roche-sur-Grane,La Roche-sur-Grane,26,Drôme,84,Auvergne-Rhône-Alpes
26278,LA ROCHE SUR LE BUIS,26170,LA ROCHE SUR LE BUIS,,44.2625812469,5.34101183886,278,La,Roche-sur-le-Buis,La Roche-sur-le-Buis,26,Drôme,84,Auvergne-Rhône-Alpes
26279,LA ROCHETTE DU BUIS,26170,LA ROCHETTE DU BUIS,,44.2610706065,5.42166701119,279,La,Rochette-du-Buis,La Rochette-du-Buis,26,Drôme,84,Auvergne-Rhône-Alpes
26281,ROMANS SUR ISERE,26100,ROMANS SUR ISERE,,45.0547531985,5.03797756012,281,,Romans-sur-Isère,Romans-sur-Isère,26,Drôme,84,Auvergne-Rhône-Alpes
26282,ROMEYER,26150,ROMEYER,,44.7925236938,5.43666252412,282,,Romeyer,Romeyer,26,Drôme,84,Auvergne-Rhône-Alpes
26283,ROTTIER,26470,ROTTIER,,44.4781523568,5.41659304244,283,,Rottier,Rottier,26,Drôme,84,Auvergne-Rhône-Alpes
26284,ROUSSAS,26230,ROUSSAS,,44.4429508183,4.78702172828,284,,Roussas,Roussas,26,Drôme,84,Auvergne-Rhône-Alpes
26285,ROUSSET LES VIGNES,26770,ROUSSET LES VIGNES,,44.4229909149,5.06698396202,285,,Rousset-les-Vignes,Rousset-les-Vignes,26,Drôme,84,Auvergne-Rhône-Alpes
26286,ROUSSIEUX,26510,ROUSSIEUX,,44.3315645396,5.47886581658,286,,Roussieux,Roussieux,26,Drôme,84,Auvergne-Rhône-Alpes
26287,ROYNAC,26450,ROYNAC,,44.648273557,4.93406395958,287,,Roynac,Roynac,26,Drôme,84,Auvergne-Rhône-Alpes
26288,SAHUNE,26510,SAHUNE,,44.4062765625,5.27186320127,288,,Sahune,Sahune,26,Drôme,84,Auvergne-Rhône-Alpes
26289,SAILLANS,26340,SAILLANS,,44.6950992434,5.18821011414,289,,Saillans,Saillans,26,Drôme,84,Auvergne-Rhône-Alpes
26290,ST AGNAN EN VERCORS,26420,ST AGNAN EN VERCORS,,44.896792332,5.44160263815,290,,Saint-Agnan-en-Vercors,Saint-Agnan-en-Vercors,26,Drôme,84,Auvergne-Rhône-Alpes
26290,ST AGNAN EN VERCORS,26420,ST AGNAN EN VERCORS,COL DE ROUSSET,44.896792332,5.44160263815,290,,Saint-Agnan-en-Vercors,Saint-Agnan-en-Vercors,26,Drôme,84,Auvergne-Rhône-Alpes
26290,ST AGNAN EN VERCORS,26420,ST AGNAN EN VERCORS,ROUSSET EN VERCORS,44.896792332,5.44160263815,290,,Saint-Agnan-en-Vercors,Saint-Agnan-en-Vercors,26,Drôme,84,Auvergne-Rhône-Alpes
26291,ST ANDEOL,26150,ST ANDEOL,,44.8078998133,5.26289331722,291,,Saint-Andéol,Saint-Andéol,26,Drôme,84,Auvergne-Rhône-Alpes
26292,ST AUBAN SUR L OUVEZE,26170,ST AUBAN SUR L OUVEZE,,44.2973942019,5.43012241748,292,,Saint-Auban-sur-l'Ouvèze,Saint-Auban-sur-l'Ouvèze,26,Drôme,84,Auvergne-Rhône-Alpes
26293,ST AVIT,26330,ST AVIT,,45.1990810013,4.96469186325,293,,Saint-Avit,Saint-Avit,26,Drôme,84,Auvergne-Rhône-Alpes
26294,ST BARDOUX,26260,ST BARDOUX,,45.0826295018,4.98379455691,294,,Saint-Bardoux,Saint-Bardoux,26,Drôme,84,Auvergne-Rhône-Alpes
26295,ST BARTHELEMY DE VALS,26240,ST BARTHELEMY DE VALS,,45.1624784084,4.87594608232,295,,Saint-Barthélemy-de-Vals,Saint-Barthélemy-de-Vals,26,Drôme,84,Auvergne-Rhône-Alpes
26296,ST BENOIT EN DIOIS,26340,ST BENOIT EN DIOIS,,44.6553242476,5.26495865272,296,,Saint-Benoit-en-Diois,Saint-Benoit-en-Diois,26,Drôme,84,Auvergne-Rhône-Alpes
26297,ST BONNET DE VALCLERIEUX,26350,ST BONNET DE VALCLERIEUX,,45.1953398866,5.14844415977,297,,Saint-Bonnet-de-Valclérieux,Saint-Bonnet-de-Valclérieux,26,Drôme,84,Auvergne-Rhône-Alpes
26298,ST CHRISTOPHE ET LE LARIS,26350,ST CHRISTOPHE ET LE LARIS,,45.2165901189,5.07080071319,298,,Saint-Christophe-et-le-Laris,Saint-Christophe-et-le-Laris,26,Drôme,84,Auvergne-Rhône-Alpes
26299,STE CROIX,26150,STE CROIX,,44.7715735756,5.28737455542,299,,Sainte-Croix,Sainte-Croix,26,Drôme,84,Auvergne-Rhône-Alpes
26300,ST DIZIER EN DIOIS,26310,ST DIZIER EN DIOIS,,44.5106852385,5.4869564348,300,,Saint-Dizier-en-Diois,Saint-Dizier-en-Diois,26,Drôme,84,Auvergne-Rhône-Alpes
26301,ST DONAT SUR L HERBASSE,26260,ST DONAT SUR L HERBASSE,,45.1260127512,4.98461565912,301,,Saint-Donat-sur-l'Herbasse,Saint-Donat-sur-l'Herbasse,26,Drôme,84,Auvergne-Rhône-Alpes
26302,STE EULALIE EN ROYANS,26190,STE EULALIE EN ROYANS,,45.0447456413,5.34170450082,302,,Sainte-Eulalie-en-Royans,Sainte-Eulalie-en-Royans,26,Drôme,84,Auvergne-Rhône-Alpes
26303,STE EUPHEMIE SUR OUVEZE,26170,STE EUPHEMIE SUR OUVEZE,,44.3011387229,5.3913641468,303,,Sainte-Euphémie-sur-Ouvèze,Sainte-Euphémie-sur-Ouvèze,26,Drôme,84,Auvergne-Rhône-Alpes
26304,ST FERREOL TRENTE PAS,26110,ST FERREOL TRENTE PAS,,44.4418058243,5.22377937288,304,,Saint-Ferréol-Trente-Pas,Saint-Ferréol-Trente-Pas,26,Drôme,84,Auvergne-Rhône-Alpes
26305,ST GERVAIS SUR ROUBION,26160,ST GERVAIS SUR ROUBION,,44.5837379912,4.89798294347,305,,Saint-Gervais-sur-Roubion,Saint-Gervais-sur-Roubion,26,Drôme,84,Auvergne-Rhône-Alpes
26306,STE JALLE,26110,STE JALLE,,44.3334508898,5.27890908641,306,,Sainte-Jalle,Sainte-Jalle,26,Drôme,84,Auvergne-Rhône-Alpes
26307,ST JEAN EN ROYANS,26190,ST JEAN EN ROYANS,,45.0069955398,5.29934748057,307,,Saint-Jean-en-Royans,Saint-Jean-en-Royans,26,Drôme,84,Auvergne-Rhône-Alpes
26308,ST JULIEN EN QUINT,26150,ST JULIEN EN QUINT,,44.852719675,5.29754292865,308,,Saint-Julien-en-Quint,Saint-Julien-en-Quint,26,Drôme,84,Auvergne-Rhône-Alpes
26309,ST JULIEN EN VERCORS,26420,ST JULIEN EN VERCORS,,45.0565543942,5.44625083689,309,,Saint-Julien-en-Vercors,Saint-Julien-en-Vercors,26,Drôme,84,Auvergne-Rhône-Alpes
26310,ST LAURENT D ONAY,26350,ST LAURENT D ONAY,,45.1781215025,5.1110797449,310,,Saint-Laurent-d'Onay,Saint-Laurent-d'Onay,26,Drôme,84,Auvergne-Rhône-Alpes
26311,ST LAURENT EN ROYANS,26190,ST LAURENT EN ROYANS,,45.0096991551,5.34660172162,311,,Saint-Laurent-en-Royans,Saint-Laurent-en-Royans,26,Drôme,84,Auvergne-Rhône-Alpes
26312,ST MARCEL LES SAUZET,26740,ST MARCEL LES SAUZET,,44.6007862089,4.80435722429,312,,Saint-Marcel-lès-Sauzet,Saint-Marcel-lès-Sauzet,26,Drôme,84,Auvergne-Rhône-Alpes
26313,ST MARCEL LES VALENCE,26320,ST MARCEL LES VALENCE,,44.9723228658,4.95099042538,313,,Saint-Marcel-lès-Valence,Saint-Marcel-lès-Valence,26,Drôme,84,Auvergne-Rhône-Alpes
26314,ST MARTIN D AOUT,26330,ST MARTIN D AOUT,,45.2222381407,4.98682933126,314,,Saint-Martin-d'Août,Saint-Martin-d'Août,26,Drôme,84,Auvergne-Rhône-Alpes
26315,ST MARTIN EN VERCORS,26420,ST MARTIN EN VERCORS,,45.0160606703,5.44906004178,315,,Saint-Martin-en-Vercors,Saint-Martin-en-Vercors,26,Drôme,84,Auvergne-Rhône-Alpes
26316,ST MARTIN LE COLONEL,26190,ST MARTIN LE COLONEL,,44.9852575045,5.27975659014,316,,Saint-Martin-le-Colonel,Saint-Martin-le-Colonel,26,Drôme,84,Auvergne-Rhône-Alpes
26317,ST MAURICE SUR EYGUES,26110,ST MAURICE SUR EYGUES,,44.3029325431,5.00596735706,317,,Saint-Maurice-sur-Eygues,Saint-Maurice-sur-Eygues,26,Drôme,84,Auvergne-Rhône-Alpes
26318,ST MAY,26510,ST MAY,,44.4246891734,5.32934515143,318,,Saint-May,Saint-May,26,Drôme,84,Auvergne-Rhône-Alpes
26319,ST MICHEL SUR SAVASSE,26750,ST MICHEL SUR SAVASSE,,45.1463901355,5.12162175387,319,,Saint-Michel-sur-Savasse,Saint-Michel-sur-Savasse,26,Drôme,84,Auvergne-Rhône-Alpes
26320,ST NAZAIRE EN ROYANS,26190,ST NAZAIRE EN ROYANS,,45.0573449267,5.24447209111,320,,Saint-Nazaire-en-Royans,Saint-Nazaire-en-Royans,26,Drôme,84,Auvergne-Rhône-Alpes
26321,ST NAZAIRE LE DESERT,26340,ST NAZAIRE LE DESERT,,44.5598132934,5.27103009679,321,,Saint-Nazaire-le-Désert,Saint-Nazaire-le-Désert,26,Drôme,84,Auvergne-Rhône-Alpes
26322,ST PANTALEON LES VIGNES,26770,ST PANTALEON LES VIGNES,,44.3954034563,5.04330124496,322,,Saint-Pantaléon-les-Vignes,Saint-Pantaléon-les-Vignes,26,Drôme,84,Auvergne-Rhône-Alpes
26323,ST PAUL LES ROMANS,26750,ST PAUL LES ROMANS,,45.0586462417,5.12685510741,323,,Saint-Paul-lès-Romans,Saint-Paul-lès-Romans,26,Drôme,84,Auvergne-Rhône-Alpes
26324,ST PAUL TROIS CHATEAUX,26130,ST PAUL TROIS CHATEAUX,,44.3482808478,4.75744934383,324,,Saint-Paul-Trois-Châteaux,Saint-Paul-Trois-Châteaux,26,Drôme,84,Auvergne-Rhône-Alpes
26325,ST RAMBERT D ALBON,26140,ST RAMBERT D ALBON,,45.2891075425,4.8312134754,325,,Saint-Rambert-d'Albon,Saint-Rambert-d'Albon,26,Drôme,84,Auvergne-Rhône-Alpes
26326,ST RESTITUT,26130,ST RESTITUT,,44.327032986,4.79615124312,326,,Saint-Restitut,Saint-Restitut,26,Drôme,84,Auvergne-Rhône-Alpes
26327,ST ROMAN,26410,ST ROMAN,,44.6889512746,5.42687100416,327,,Saint-Roman,Saint-Roman,26,Drôme,84,Auvergne-Rhône-Alpes
26328,ST SAUVEUR EN DIOIS,26340,ST SAUVEUR EN DIOIS,,44.6743762083,5.16582168009,328,,Saint-Sauveur-en-Diois,Saint-Sauveur-en-Diois,26,Drôme,84,Auvergne-Rhône-Alpes
26329,ST SAUVEUR GOUVERNET,26110,ST SAUVEUR GOUVERNET,,44.3303880901,5.37614176075,329,,Saint-Sauveur-Gouvernet,Saint-Sauveur-Gouvernet,26,Drôme,84,Auvergne-Rhône-Alpes
26330,ST SORLIN EN VALLOIRE,26210,ST SORLIN EN VALLOIRE,,45.2782053024,4.95829922451,330,,Saint-Sorlin-en-Valloire,Saint-Sorlin-en-Valloire,26,Drôme,84,Auvergne-Rhône-Alpes
26331,ST THOMAS EN ROYANS,26190,ST THOMAS EN ROYANS,,45.049388788,5.29226972009,331,,Saint-Thomas-en-Royans,Saint-Thomas-en-Royans,26,Drôme,84,Auvergne-Rhône-Alpes
26332,ST UZE,26240,ST UZE,,45.1900433327,4.86747069605,332,,Saint-Uze,Saint-Uze,26,Drôme,84,Auvergne-Rhône-Alpes
26333,ST VALLIER,26240,ST VALLIER,,45.1794682912,4.8198371003,333,,Saint-Vallier,Saint-Vallier,26,Drôme,84,Auvergne-Rhône-Alpes
26334,SALETTES,26160,SALETTES,,44.5660899687,4.96944510091,334,,Salettes,Salettes,26,Drôme,84,Auvergne-Rhône-Alpes
26335,SALLES SOUS BOIS,26770,SALLES SOUS BOIS,,44.4707924508,4.94008224904,335,,Salles-sous-Bois,Salles-sous-Bois,26,Drôme,84,Auvergne-Rhône-Alpes
26336,SAOU,26400,SAOU,,44.6470256973,5.11072905542,336,,Saou,Saou,26,Drôme,84,Auvergne-Rhône-Alpes
26337,SAULCE SUR RHONE,26270,SAULCE SUR RHONE,,44.7042406134,4.79084711633,337,,Saulce-sur-Rhône,Saulce-sur-Rhône,26,Drôme,84,Auvergne-Rhône-Alpes
26337,SAULCE SUR RHONE,26270,SAULCE SUR RHONE,LES REYS DE SAULCE,44.7042406134,4.79084711633,337,,Saulce-sur-Rhône,Saulce-sur-Rhône,26,Drôme,84,Auvergne-Rhône-Alpes
26338,SAUZET,26740,SAUZET,,44.5897268038,4.82526007849,338,,Sauzet,Sauzet,26,Drôme,84,Auvergne-Rhône-Alpes
26339,SAVASSE,26740,SAVASSE,,44.6067336832,4.77822199887,339,,Savasse,Savasse,26,Drôme,84,Auvergne-Rhône-Alpes
26339,SAVASSE,26740,SAVASSE,L HOMME D ARMES,44.6067336832,4.77822199887,339,,Savasse,Savasse,26,Drôme,84,Auvergne-Rhône-Alpes
26340,SEDERON,26560,SEDERON,,44.1975046499,5.55416093649,340,,Séderon,Séderon,26,Drôme,84,Auvergne-Rhône-Alpes
26341,SERVES SUR RHONE,26600,SERVES SUR RHONE,,45.1418386958,4.83109226677,341,,Serves-sur-Rhône,Serves-sur-Rhône,26,Drôme,84,Auvergne-Rhône-Alpes
26342,SOLERIEUX,26130,SOLERIEUX,,44.3477984022,4.82557061801,342,,Solérieux,Solérieux,26,Drôme,84,Auvergne-Rhône-Alpes
26343,SOUSPIERRE,26160,SOUSPIERRE,,44.538558166,4.96747535293,343,,Souspierre,Souspierre,26,Drôme,84,Auvergne-Rhône-Alpes
26344,SOYANS,26400,SOYANS,,44.6375234986,5.02542323659,344,,Soyans,Soyans,26,Drôme,84,Auvergne-Rhône-Alpes
26345,SUZE LA ROUSSE,26790,SUZE LA ROUSSE,,44.2929309503,4.84420435248,345,,Suze-la-Rousse,Suze-la-Rousse,26,Drôme,84,Auvergne-Rhône-Alpes
26346,SUZE,26400,SUZE,,44.7580022895,5.10621589285,346,,Suze,Suze,26,Drôme,84,Auvergne-Rhône-Alpes
26347,TAIN L HERMITAGE,26600,TAIN L HERMITAGE,,45.0710728431,4.85070614193,347,,Tain-l'Hermitage,Tain-l'Hermitage,26,Drôme,84,Auvergne-Rhône-Alpes
26348,TAULIGNAN,26770,TAULIGNAN,,44.4589108654,4.97848630034,348,,Taulignan,Taulignan,26,Drôme,84,Auvergne-Rhône-Alpes
26349,TERSANNE,26390,TERSANNE,,45.2159447789,5.01614531458,349,,Tersanne,Tersanne,26,Drôme,84,Auvergne-Rhône-Alpes
26350,TEYSSIERES,26220,TEYSSIERES,,44.4574320364,5.14299816408,350,,Teyssières,Teyssières,26,Drôme,84,Auvergne-Rhône-Alpes
26351,LES TONILS,26460,LES TONILS,,44.5863590312,5.21024609596,351,Les,Tonils,Les Tonils,26,Drôme,84,Auvergne-Rhône-Alpes
26352,LA TOUCHE,26160,LA TOUCHE,,44.5183828304,4.8861428282,352,La,Touche,La Touche,26,Drôme,84,Auvergne-Rhône-Alpes
26353,LES TOURRETTES,26740,LES TOURRETTES,,44.6648052297,4.80343747078,353,Les,Tourrettes,Les Tourrettes,26,Drôme,84,Auvergne-Rhône-Alpes
26354,TRESCHENU CREYERS,26410,TRESCHENU CREYERS,,44.7365015053,5.54201158842,354,,Treschenu-Creyers,Treschenu-Creyers,26,Drôme,84,Auvergne-Rhône-Alpes
26354,TRESCHENU CREYERS,26410,TRESCHENU CREYERS,CREYERS,44.7365015053,5.54201158842,354,,Treschenu-Creyers,Treschenu-Creyers,26,Drôme,84,Auvergne-Rhône-Alpes
26355,TRIORS,26750,TRIORS,,45.0951576073,5.11184336958,355,,Triors,Triors,26,Drôme,84,Auvergne-Rhône-Alpes
26356,TRUINAS,26460,TRUINAS,,44.5767207491,5.08359812134,356,,Truinas,Truinas,26,Drôme,84,Auvergne-Rhône-Alpes
26357,TULETTE,26790,TULETTE,,44.2840624868,4.93792582794,357,,Tulette,Tulette,26,Drôme,84,Auvergne-Rhône-Alpes
26358,UPIE,26120,UPIE,,44.7990451826,4.98046228806,358,,Upie,Upie,26,Drôme,84,Auvergne-Rhône-Alpes
26359,VACHERES EN QUINT,26150,VACHERES EN QUINT,,44.7857176216,5.25018392974,359,,Vachères-en-Quint,Vachères-en-Quint,26,Drôme,84,Auvergne-Rhône-Alpes
26360,VALAURIE,26230,VALAURIE,,44.420602445,4.81063104822,360,,Valaurie,Valaurie,26,Drôme,84,Auvergne-Rhône-Alpes
26361,VALDROME,26310,VALDROME,,44.4979512918,5.56721863741,361,,Valdrôme,Valdrôme,26,Drôme,84,Auvergne-Rhône-Alpes
26362,VALENCE,26000,VALENCE,,44.9229811667,4.91444013136,362,,Valence,Valence,26,Drôme,84,Auvergne-Rhône-Alpes
26363,VALOUSE,26110,VALOUSE,,44.4594883519,5.19108928218,363,,Valouse,Valouse,26,Drôme,84,Auvergne-Rhône-Alpes
26364,VASSIEUX EN VERCORS,26420,VASSIEUX EN VERCORS,,44.8802743526,5.3741063802,364,,Vassieux-en-Vercors,Vassieux-en-Vercors,26,Drôme,84,Auvergne-Rhône-Alpes
26365,VAUNAVEYS LA ROCHETTE,26400,VAUNAVEYS LA ROCHETTE,,44.7780399465,5.03229421632,365,,Vaunaveys-la-Rochette,Vaunaveys-la-Rochette,26,Drôme,84,Auvergne-Rhône-Alpes
26365,VAUNAVEYS LA ROCHETTE,26400,VAUNAVEYS LA ROCHETTE,LA ROCHETTE SUR CREST,44.7780399465,5.03229421632,365,,Vaunaveys-la-Rochette,Vaunaveys-la-Rochette,26,Drôme,84,Auvergne-Rhône-Alpes
26367,VENTEROL,26110,VENTEROL,,44.3977805769,5.09846513358,367,,Venterol,Venterol,26,Drôme,84,Auvergne-Rhône-Alpes
26368,VERCHENY,26340,VERCHENY,,44.7147022562,5.24834854956,368,,Vercheny,Vercheny,26,Drôme,84,Auvergne-Rhône-Alpes
26369,VERCLAUSE,26510,VERCLAUSE,,44.3954497171,5.40735314381,369,,Verclause,Verclause,26,Drôme,84,Auvergne-Rhône-Alpes
26370,VERCOIRAN,26170,VERCOIRAN,,44.2937794965,5.35057643289,370,,Vercoiran,Vercoiran,26,Drôme,84,Auvergne-Rhône-Alpes
26371,VERONNE,26340,VERONNE,,44.7363252417,5.20695979719,371,,Véronne,Véronne,26,Drôme,84,Auvergne-Rhône-Alpes
26372,VERS SUR MEOUGE,26560,VERS SUR MEOUGE,,44.2358606593,5.55110208603,372,,Vers-sur-Méouge,Vers-sur-Méouge,26,Drôme,84,Auvergne-Rhône-Alpes
26373,VESC,26220,VESC,,44.5098284454,5.15787850108,373,,Vesc,Vesc,26,Drôme,84,Auvergne-Rhône-Alpes
26374,VILLEBOIS LES PINS,5700,VILLEBOIS LES PINS,,44.3195776951,5.59771630114,374,,Villebois-les-Pins,Villebois-les-Pins,26,Drôme,84,Auvergne-Rhône-Alpes
26375,VILLEFRANCHE LE CHATEAU,26560,VILLEFRANCHE LE CHATEAU,,44.2152597669,5.50831127895,375,,Villefranche-le-Château,Villefranche-le-Château,26,Drôme,84,Auvergne-Rhône-Alpes
26376,VILLEPERDRIX,26510,VILLEPERDRIX,,44.4557494791,5.29971413853,376,,Villeperdrix,Villeperdrix,26,Drôme,84,Auvergne-Rhône-Alpes
26377,VINSOBRES,26110,VINSOBRES,,44.3356176138,5.0506163995,377,,Vinsobres,Vinsobres,26,Drôme,84,Auvergne-Rhône-Alpes
26378,VOLVENT,26470,VOLVENT,,44.5680273375,5.35010817768,378,,Volvent,Volvent,26,Drôme,84,Auvergne-Rhône-Alpes
26379,GRANGES LES BEAUMONT,26600,GRANGES LES BEAUMONT,,45.0478824506,4.97487229012,379,,Granges-les-Beaumont,Granges-les-Beaumont,26,Drôme,84,Auvergne-Rhône-Alpes
26380,GERVANS,26600,GERVANS,,45.1078817162,4.83145777292,380,,Gervans,Gervans,26,Drôme,84,Auvergne-Rhône-Alpes
26381,JAILLANS,26300,JAILLANS,,45.0288659081,5.16948509567,381,,Jaillans,Jaillans,26,Drôme,84,Auvergne-Rhône-Alpes
26382,ST VINCENT LA COMMANDERIE,26300,ST VINCENT LA COMMANDERIE,,44.9323647343,5.12947887915,382,,Saint-Vincent-la-Commanderie,Saint-Vincent-la-Commanderie,26,Drôme,84,Auvergne-Rhône-Alpes
27001,ACLOU,27800,ACLOU,,49.1655546426,0.698293841066,1,,Aclou,Aclou,27,Eure,28,Normandie
27002,ACON,27570,ACON,,48.7694578544,1.10492466757,2,,Acon,Acon,27,Eure,28,Normandie
27003,ACQUIGNY,27400,ACQUIGNY,,49.1648717394,1.1765581743,3,,Acquigny,Acquigny,27,Eure,28,Normandie
27003,ACQUIGNY,27400,ACQUIGNY,LES PLANCHES,49.1648717394,1.1765581743,3,,Acquigny,Acquigny,27,Eure,28,Normandie
27004,AIGLEVILLE,27120,AIGLEVILLE,,49.0059752091,1.42580857291,4,,Aigleville,Aigleville,27,Eure,28,Normandie
27005,AILLY,27600,AILLY,,49.1575246748,1.24431447068,5,,Ailly,Ailly,27,Eure,28,Normandie
27006,AIZIER,27500,AIZIER,,49.4307181998,0.626081675933,6,,Aizier,Aizier,27,Eure,28,Normandie
27008,ALIZAY,27460,ALIZAY,,49.3219074958,1.17828361031,8,,Alizay,Alizay,27,Eure,28,Normandie
27009,AMBENAY,27250,AMBENAY,,48.8402151816,0.73972567335,9,,Ambenay,Ambenay,27,Eure,28,Normandie
27010,AMECOURT,27140,AMECOURT,,49.3767401093,1.7332985708,10,,Amécourt,Amécourt,27,Eure,28,Normandie
27011,AMFREVILLE ST AMAND,27370,AMFREVILLE ST AMAND,,49.2130667488,0.925129802159,11,,Amfreville-Saint-Amand,Amfreville-Saint-Amand,27,Eure,28,Normandie
27011,AMFREVILLE ST AMAND,27370,AMFREVILLE ST AMAND,ST AMAND DES HAUTES TERRES,49.2130667488,0.925129802159,11,,Amfreville-Saint-Amand,Amfreville-Saint-Amand,27,Eure,28,Normandie
27012,AMFREVILLE LES CHAMPS,27380,AMFREVILLE LES CHAMPS,,49.3126551036,1.32149788001,12,,Amfreville-les-Champs,Amfreville-les-Champs,27,Eure,28,Normandie
27013,AMFREVILLE SOUS LES MONTS,27380,AMFREVILLE SOUS LES MONTS,,49.3028161633,1.26637402141,13,,Amfreville-sous-les-Monts,Amfreville-sous-les-Monts,27,Eure,28,Normandie
27014,AMFREVILLE SUR ITON,27400,AMFREVILLE SUR ITON,,49.1567317794,1.14647315258,14,,Amfreville-sur-Iton,Amfreville-sur-Iton,27,Eure,28,Normandie
27015,ANDE,27430,ANDE,,49.2280273549,1.24535712004,15,,Andé,Andé,27,Eure,28,Normandie
27016,LES ANDELYS,27700,LES ANDELYS,,49.2459086784,1.42805086604,16,Les,Andelys,Les Andelys,27,Eure,28,Normandie
27017,ANGERVILLE LA CAMPAGNE,27930,ANGERVILLE LA CAMPAGNE,,48.9889739894,1.15705136083,17,,Angerville-la-Campagne,Angerville-la-Campagne,27,Eure,28,Normandie
27018,APPEVILLE ANNEBAULT,27290,APPEVILLE ANNEBAULT,,49.322265478,0.651047926386,18,,Appeville-Annebault,Appeville-Annebault,27,Eure,28,Normandie
27019,ARMENTIERES SUR AVRE,27820,ARMENTIERES SUR AVRE,,48.6804230358,0.805130428787,19,,Armentières-sur-Avre,Armentières-sur-Avre,27,Eure,28,Normandie
27020,ARNIERES SUR ITON,27180,ARNIERES SUR ITON,,48.9895768522,1.10363451837,20,,Arnières-sur-Iton,Arnières-sur-Iton,27,Eure,28,Normandie
27021,ASNIERES,27260,ASNIERES,,49.2048420248,0.401991351271,21,,Asnières,Asnières,27,Eure,28,Normandie
27022,LE VAL D HAZEY,27600,LE VAL D HAZEY,STE BARBE SUR GAILLON,49.1751629599,1.33329956002,22,Le,Val d'Hazey,Le Val d'Hazey,27,Eure,28,Normandie
27022,LE VAL D HAZEY,27600,LE VAL D HAZEY,VIEUX VILLEZ,49.1751629599,1.33329956002,22,Le,Val d'Hazey,Le Val d'Hazey,27,Eure,28,Normandie
27022,LE VAL D HAZEY,27940,LE VAL D HAZEY,AUBEVOYE,49.1751629599,1.33329956002,22,Le,Val d'Hazey,Le Val d'Hazey,27,Eure,28,Normandie
27023,AULNAY SUR ITON,27180,AULNAY SUR ITON,,48.9939471399,1.05509802791,23,,Aulnay-sur-Iton,Aulnay-sur-Iton,27,Eure,28,Normandie
27025,AUTHEUIL AUTHOUILLET,27490,AUTHEUIL AUTHOUILLET,,49.1004320886,1.2947778203,25,,Autheuil-Authouillet,Autheuil-Authouillet,27,Eure,28,Normandie
27025,AUTHEUIL AUTHOUILLET,27490,AUTHEUIL AUTHOUILLET,AUTHOUILLET,49.1004320886,1.2947778203,25,,Autheuil-Authouillet,Autheuil-Authouillet,27,Eure,28,Normandie
27026,AUTHEVERNES,27420,AUTHEVERNES,,49.2194914224,1.63268872658,26,,Authevernes,Authevernes,27,Eure,28,Normandie
27027,LES AUTHIEUX,27220,LES AUTHIEUX,,48.898268785,1.23438595727,27,Les,Authieux,Les Authieux,27,Eure,28,Normandie
27028,AUTHOU,27290,AUTHOU,,49.2260820687,0.696761680662,28,,Authou,Authou,27,Eure,28,Normandie
27031,AVIRON,27930,AVIRON,,49.0566321831,1.12002720385,31,,Aviron,Aviron,27,Eure,28,Normandie
27032,CHAMBOIS,27240,CHAMBOIS,AVRILLY,48.9282451292,1.14607632884,32,,Chambois,Chambois,27,Eure,28,Normandie
27032,CHAMBOIS,27240,CHAMBOIS,CORNEUIL,48.9282451292,1.14607632884,32,,Chambois,Chambois,27,Eure,28,Normandie
27032,CHAMBOIS,27240,CHAMBOIS,THOMER LA SOGNE,48.9282451292,1.14607632884,32,,Chambois,Chambois,27,Eure,28,Normandie
27033,BACQUEPUIS,27930,BACQUEPUIS,,49.0908435068,1.04555863725,33,,Bacquepuis,Bacquepuis,27,Eure,28,Normandie
27034,BACQUEVILLE,27440,BACQUEVILLE,,49.3219203458,1.36658657658,34,,Bacqueville,Bacqueville,27,Eure,28,Normandie
27035,BAILLEUL LA VALLEE,27260,BAILLEUL LA VALLEE,,49.1971101863,0.431397706561,35,,Bailleul-la-Vallée,Bailleul-la-Vallée,27,Eure,28,Normandie
27036,BALINES,27130,BALINES,,48.7482752525,0.977506777957,36,,Bâlines,Bâlines,27,Eure,28,Normandie
27037,BARC,27170,BARC,,49.0794545996,0.824738866399,37,,Barc,Barc,27,Eure,28,Normandie
27038,LES BARILS,27130,LES BARILS,,48.7252205742,0.8185826173,38,Les,Barils,Les Barils,27,Eure,28,Normandie
27039,BARNEVILLE SUR SEINE,27310,BARNEVILLE SUR SEINE,,49.3845601903,0.854432442318,39,,Barneville-sur-Seine,Barneville-sur-Seine,27,Eure,28,Normandie
27040,BARQUET,27170,BARQUET,,49.0394990811,0.854645934918,40,,Barquet,Barquet,27,Eure,28,Normandie
27042,BARVILLE,27230,BARVILLE,,49.157858783,0.485044896768,42,,Barville,Barville,27,Eure,28,Normandie
27043,LES BAUX DE BRETEUIL,27160,LES BAUX DE BRETEUIL,,48.8665244592,0.801607378601,43,Les,Baux-de-Breteuil,Les Baux-de-Breteuil,27,Eure,28,Normandie
27044,LES BAUX STE CROIX,27180,LES BAUX STE CROIX,,48.9670536048,1.10669165131,44,Les,Baux-Sainte-Croix,Les Baux-Sainte-Croix,27,Eure,28,Normandie
27045,BAZINCOURT SUR EPTE,27140,BAZINCOURT SUR EPTE,,49.3259295252,1.7576865093,45,,Bazincourt-sur-Epte,Bazincourt-sur-Epte,27,Eure,28,Normandie
27046,BAZOQUES,27230,BAZOQUES,,49.1705243129,0.549677585318,46,,Bazoques,Bazoques,27,Eure,28,Normandie
27047,BEAUBRAY,27190,BEAUBRAY,,48.9105126717,0.900919598121,47,,Beaubray,Beaubray,27,Eure,28,Normandie
27048,BEAUFICEL EN LYONS,27480,BEAUFICEL EN LYONS,,49.4044391914,1.52480610334,48,,Beauficel-en-Lyons,Beauficel-en-Lyons,27,Eure,28,Normandie
27049,MESNIL EN OUCHE,27270,MESNIL EN OUCHE,LA ROUSSIERE,49.0090520551,0.696415474297,49,,Mesnil-en-Ouche,Mesnil-en-Ouche,27,Eure,28,Normandie
27049,MESNIL EN OUCHE,27330,MESNIL EN OUCHE,BOSC RENOULT EN OUCHE,49.0090520551,0.696415474297,49,,Mesnil-en-Ouche,Mesnil-en-Ouche,27,Eure,28,Normandie
27049,MESNIL EN OUCHE,27330,MESNIL EN OUCHE,EPINAY,49.0090520551,0.696415474297,49,,Mesnil-en-Ouche,Mesnil-en-Ouche,27,Eure,28,Normandie
27049,MESNIL EN OUCHE,27330,MESNIL EN OUCHE,GISAY LA COUDRE,49.0090520551,0.696415474297,49,,Mesnil-en-Ouche,Mesnil-en-Ouche,27,Eure,28,Normandie
27049,MESNIL EN OUCHE,27330,MESNIL EN OUCHE,LA BARRE EN OUCHE,49.0090520551,0.696415474297,49,,Mesnil-en-Ouche,Mesnil-en-Ouche,27,Eure,28,Normandie
27049,MESNIL EN OUCHE,27330,MESNIL EN OUCHE,ST PIERRE DU MESNIL,49.0090520551,0.696415474297,49,,Mesnil-en-Ouche,Mesnil-en-Ouche,27,Eure,28,Normandie
27049,MESNIL EN OUCHE,27330,MESNIL EN OUCHE,THEVRAY,49.0090520551,0.696415474297,49,,Mesnil-en-Ouche,Mesnil-en-Ouche,27,Eure,28,Normandie
27049,MESNIL EN OUCHE,27410,MESNIL EN OUCHE,AJOU,49.0090520551,0.696415474297,49,,Mesnil-en-Ouche,Mesnil-en-Ouche,27,Eure,28,Normandie
27049,MESNIL EN OUCHE,27410,MESNIL EN OUCHE,BEAUMESNIL,49.0090520551,0.696415474297,49,,Mesnil-en-Ouche,Mesnil-en-Ouche,27,Eure,28,Normandie
27049,MESNIL EN OUCHE,27410,MESNIL EN OUCHE,GOUTTIERES,49.0090520551,0.696415474297,49,,Mesnil-en-Ouche,Mesnil-en-Ouche,27,Eure,28,Normandie
27049,MESNIL EN OUCHE,27410,MESNIL EN OUCHE,GRANCHAIN,49.0090520551,0.696415474297,49,,Mesnil-en-Ouche,Mesnil-en-Ouche,27,Eure,28,Normandie
27049,MESNIL EN OUCHE,27410,MESNIL EN OUCHE,JONQUERETS DE LIVET,49.0090520551,0.696415474297,49,,Mesnil-en-Ouche,Mesnil-en-Ouche,27,Eure,28,Normandie
27049,MESNIL EN OUCHE,27410,MESNIL EN OUCHE,LANDEPEREUSE,49.0090520551,0.696415474297,49,,Mesnil-en-Ouche,Mesnil-en-Ouche,27,Eure,28,Normandie
27049,MESNIL EN OUCHE,27410,MESNIL EN OUCHE,ST AUBIN DES HAYES,49.0090520551,0.696415474297,49,,Mesnil-en-Ouche,Mesnil-en-Ouche,27,Eure,28,Normandie
27049,MESNIL EN OUCHE,27410,MESNIL EN OUCHE,ST AUBIN LE GUICHARD,49.0090520551,0.696415474297,49,,Mesnil-en-Ouche,Mesnil-en-Ouche,27,Eure,28,Normandie
27049,MESNIL EN OUCHE,27410,MESNIL EN OUCHE,STE MARGUERITE EN OUCHE,49.0090520551,0.696415474297,49,,Mesnil-en-Ouche,Mesnil-en-Ouche,27,Eure,28,Normandie
27049,MESNIL EN OUCHE,27410,MESNIL EN OUCHE,THEVRAY,49.0090520551,0.696415474297,49,,Mesnil-en-Ouche,Mesnil-en-Ouche,27,Eure,28,Normandie
27050,BEAUMONTEL,27170,BEAUMONTEL,,49.0956151725,0.780814703576,50,,Beaumontel,Beaumontel,27,Eure,28,Normandie
27051,BEAUMONT LE ROGER,27170,BEAUMONT LE ROGER,,49.0592104532,0.754833574426,51,,Beaumont-le-Roger,Beaumont-le-Roger,27,Eure,28,Normandie
27052,LE BEC HELLOUIN,27800,LE BEC HELLOUIN,,49.2249212859,0.726506401236,52,Le,Bec-Hellouin,Le Bec-Hellouin,27,Eure,28,Normandie
27053,LE BEC THOMAS,27370,LE BEC THOMAS,,49.2389327797,0.975785168185,53,Le,Bec-Thomas,Le Bec-Thomas,27,Eure,28,Normandie
27054,BEMECOURT,27160,BEMECOURT,,48.8432221726,0.848460496719,54,,Bémécourt,Bémécourt,27,Eure,28,Normandie
27055,BERENGEVILLE LA CAMPAGNE,27110,BERENGEVILLE LA CAMPAGNE,,49.1133913572,1.05847119892,55,,Bérengeville-la-Campagne,Bérengeville-la-Campagne,27,Eure,28,Normandie
27056,BERNAY,27300,BERNAY,,49.0925877345,0.58959175237,56,,Bernay,Bernay,27,Eure,28,Normandie
27057,BERNIENVILLE,27180,BERNIENVILLE,,49.0744161962,1.02876644978,57,,Bernienville,Bernienville,27,Eure,28,Normandie
27058,BERNIERES SUR SEINE,27700,BERNIERES SUR SEINE,,49.2356150972,1.3466570759,58,,Bernières-sur-Seine,Bernières-sur-Seine,27,Eure,28,Normandie
27059,BERNOUVILLE,27660,BERNOUVILLE,,49.289676339,1.6744453419,59,,Bernouville,Bernouville,27,Eure,28,Normandie
27061,BERTHOUVILLE,27800,BERTHOUVILLE,,49.1711234427,0.622865365942,61,,Berthouville,Berthouville,27,Eure,28,Normandie
27062,BERVILLE EN ROUMOIS,27520,BERVILLE EN ROUMOIS,,49.2948046774,0.827614672405,62,Les,Monts du Roumois,Les Monts du Roumois,27,Eure,28,Normandie
27063,BERVILLE LA CAMPAGNE,27170,BERVILLE LA CAMPAGNE,,49.0261594843,0.89327089992,63,,Berville-la-Campagne,Berville-la-Campagne,27,Eure,28,Normandie
27064,BERVILLE SUR MER,27210,BERVILLE SUR MER,,49.4308635089,0.357667188037,64,,Berville-sur-Mer,Berville-sur-Mer,27,Eure,28,Normandie
27065,BEUZEVILLE,27210,BEUZEVILLE,,49.3380079083,0.342706713598,65,,Beuzeville,Beuzeville,27,Eure,28,Normandie
27066,BEZU LA FORET,27480,BEZU LA FORET,,49.3958627299,1.6243958073,66,,Bézu-la-Forêt,Bézu-la-Forêt,27,Eure,28,Normandie
27067,BEZU ST ELOI,27660,BEZU ST ELOI,,49.304670197,1.68309214752,67,,Bézu-Saint-Éloi,Bézu-Saint-Éloi,27,Eure,28,Normandie
27068,BOIS ANZERAY,27330,BOIS ANZERAY,,48.9262450221,0.691751943479,68,,Bois-Anzeray,Bois-Anzeray,27,Eure,28,Normandie
27069,BOIS ARNAULT,27250,BOIS ARNAULT,,48.816398716,0.748244384454,69,,Bois-Arnault,Bois-Arnault,27,Eure,28,Normandie
27070,BOISEMONT,27150,BOISEMONT,,49.2925439055,1.49018837893,70,,Boisemont,Boisemont,27,Eure,28,Normandie
27071,LE BOIS HELLAIN,27260,LE BOIS HELLAIN,,49.2759556083,0.391123392323,71,Le,Bois-Hellain,Le Bois-Hellain,27,Eure,28,Normandie
27072,BOIS JEROME ST OUEN,27620,BOIS JEROME ST OUEN,,49.1028777663,1.54843437067,72,,Bois-Jérôme-Saint-Ouen,Bois-Jérôme-Saint-Ouen,27,Eure,28,Normandie
27073,BOIS LE ROI,27220,BOIS LE ROI,,48.8610331627,1.34099240808,73,,Bois-le-Roi,Bois-le-Roi,27,Eure,28,Normandie
27074,BOISNEY,27800,BOISNEY,,49.1561456712,0.649696309423,74,,Boisney,Boisney,27,Eure,28,Normandie
27075,BOIS NORMAND PRES LYRE,27330,BOIS NORMAND PRES LYRE,,48.9005939883,0.693720098144,75,,Bois-Normand-près-Lyre,Bois-Normand-près-Lyre,27,Eure,28,Normandie
27076,BOISSET LES PREVANCHES,27120,BOISSET LES PREVANCHES,,48.9662323678,1.33275154134,76,,Boisset-les-Prévanches,Boisset-les-Prévanches,27,Eure,28,Normandie
27077,BOISSEY LE CHATEL,27520,BOISSEY LE CHATEL,,49.2718051513,0.781442206023,77,,Boissey-le-Châtel,Boissey-le-Châtel,27,Eure,28,Normandie
27078,LA BOISSIERE,27220,LA BOISSIERE,,48.9511536533,1.36900012303,78,La,Boissière,La Boissière,27,Eure,28,Normandie
27079,BOISSY LAMBERVILLE,27300,BOISSY LAMBERVILLE,,49.1567974903,0.578455295801,79,,Boissy-Lamberville,Boissy-Lamberville,27,Eure,28,Normandie
27081,BONCOURT,27120,BONCOURT,,49.0203312992,1.29712333835,81,,Boncourt,Boncourt,27,Eure,28,Normandie
27082,LA BONNEVILLE SUR ITON,27190,LA BONNEVILLE SUR ITON,,48.997516605,1.03540164833,82,La,Bonneville-sur-Iton,La Bonneville-sur-Iton,27,Eure,28,Normandie
27083,BONNEVILLE APTOT,27290,BONNEVILLE APTOT,,49.2595144093,0.751526070144,83,,Bonneville-Aptot,Bonneville-Aptot,27,Eure,28,Normandie
27085,FLANCOURT CRESCY EN ROUMOIS,27310,FLANCOURT CRESCY EN ROUMOIS,,49.3166032869,0.807172708005,85,,Flancourt-Crescy-en-Roumois,Flancourt-Crescy-en-Roumois,27,Eure,28,Normandie
27085,FLANCOURT CRESCY EN ROUMOIS,27310,FLANCOURT CRESCY EN ROUMOIS,EPREVILLE EN ROUMOIS,49.3166032869,0.807172708005,85,,Flancourt-Crescy-en-Roumois,Flancourt-Crescy-en-Roumois,27,Eure,28,Normandie
27085,FLANCOURT CRESCY EN ROUMOIS,27310,FLANCOURT CRESCY EN ROUMOIS,FLANCOURT CATELON,49.3166032869,0.807172708005,85,,Flancourt-Crescy-en-Roumois,Flancourt-Crescy-en-Roumois,27,Eure,28,Normandie
27089,BOSC RENOULT EN ROUMOIS,27520,BOSC RENOULT EN ROUMOIS,,49.2989521674,0.772087753969,89,,Thénouville,Thénouville,27,Eure,28,Normandie
27090,LE BOSC ROGER EN ROUMOIS,27670,LE BOSC ROGER EN ROUMOIS,,49.2933518508,0.922353150022,90,,Bosroumois,Bosroumois,27,Eure,28,Normandie
27091,BOSGOUET,27310,BOSGOUET,,49.3542020873,0.846231180237,91,,Bosgouet,Bosgouet,27,Eure,28,Normandie
27092,BOSGUERARD DE MARCOUVILLE,27520,BOSGUERARD DE MARCOUVILLE,,49.2675107989,0.844017203432,92,,Bosguérard-de-Marcouville,Bosguérard-de-Marcouville,27,Eure,28,Normandie
27093,BOSNORMAND,27670,BOSNORMAND,,49.2777576203,0.909497972922,93,,Bosnormand,Bosnormand,27,Eure,28,Normandie
27094,BOSQUENTIN,27480,BOSQUENTIN,,49.4131822482,1.58863474823,94,,Bosquentin,Bosquentin,27,Eure,28,Normandie
27095,BOSROBERT,27800,BOSROBERT,,49.2201107981,0.765612349329,95,,Bosrobert,Bosrobert,27,Eure,28,Normandie
27096,LES BOTTEREAUX,27250,LES BOTTEREAUX,,48.8712188786,0.676548267263,96,Les,Bottereaux,Les Bottereaux,27,Eure,28,Normandie
27096,LES BOTTEREAUX,27250,LES BOTTEREAUX,LES FRETILS,48.8712188786,0.676548267263,96,Les,Bottereaux,Les Bottereaux,27,Eure,28,Normandie
27096,LES BOTTEREAUX,27250,LES BOTTEREAUX,VAUX SUR RISLE,48.8712188786,0.676548267263,96,Les,Bottereaux,Les Bottereaux,27,Eure,28,Normandie
27097,BOUAFLES,27700,BOUAFLES,,49.2013162618,1.38671055772,97,,Bouafles,Bouafles,27,Eure,28,Normandie
27098,BOUCHEVILLIERS,27150,BOUCHEVILLIERS,,49.3984802284,1.71160727896,98,,Bouchevilliers,Bouchevilliers,27,Eure,28,Normandie
27099,LE BOULAY MORIN,27930,LE BOULAY MORIN,,49.0763402724,1.18739745292,99,Le,Boulay-Morin,Le Boulay-Morin,27,Eure,28,Normandie
27100,BOULLEVILLE,27210,BOULLEVILLE,,49.3705498609,0.393309012752,100,,Boulleville,Boulleville,27,Eure,28,Normandie
27100,BOULLEVILLE,27210,BOULLEVILLE,LA LANDE,49.3705498609,0.393309012752,100,,Boulleville,Boulleville,27,Eure,28,Normandie
27101,BOUQUELON,27500,BOUQUELON,,49.3995142843,0.49146423829,101,,Bouquelon,Bouquelon,27,Eure,28,Normandie
27102,BOUQUETOT,27310,BOUQUETOT,,49.3610544538,0.770400982625,102,,Bouquetot,Bouquetot,27,Eure,28,Normandie
27103,BOURG ACHARD,27310,BOURG ACHARD,,49.3450741127,0.80915990601,103,,Bourg-Achard,Bourg-Achard,27,Eure,28,Normandie
27104,BOURG BEAUDOUIN,27380,BOURG BEAUDOUIN,,49.384064596,1.30432026998,104,,Bourg-Beaudouin,Bourg-Beaudouin,27,Eure,28,Normandie
27105,GRAND BOURGTHEROULDE,27520,GRAND BOURGTHEROULDE,BOSC BENARD COMMIN,49.2995530921,0.876522751918,105,,Grand Bourgtheroulde,Grand Bourgtheroulde,27,Eure,28,Normandie
27105,GRAND BOURGTHEROULDE,27520,GRAND BOURGTHEROULDE,BOSCHERVILLE,49.2995530921,0.876522751918,105,,Grand Bourgtheroulde,Grand Bourgtheroulde,27,Eure,28,Normandie
27105,GRAND BOURGTHEROULDE,27520,GRAND BOURGTHEROULDE,BOURGTHEROULDE INFREVILLE,49.2995530921,0.876522751918,105,,Grand Bourgtheroulde,Grand Bourgtheroulde,27,Eure,28,Normandie
27105,GRAND BOURGTHEROULDE,27520,GRAND BOURGTHEROULDE,INFREVILLE,49.2995530921,0.876522751918,105,,Grand Bourgtheroulde,Grand Bourgtheroulde,27,Eure,28,Normandie
27105,GRAND BOURGTHEROULDE,27520,GRAND BOURGTHEROULDE,THUIT HEBERT,49.2995530921,0.876522751918,105,,Grand Bourgtheroulde,Grand Bourgtheroulde,27,Eure,28,Normandie
27106,BOURNAINVILLE FAVEROLLES,27230,BOURNAINVILLE FAVEROLLES,,49.122899642,0.50099426931,106,,Bournainville-Faverolles,Bournainville-Faverolles,27,Eure,28,Normandie
27106,BOURNAINVILLE FAVEROLLES,27230,BOURNAINVILLE FAVEROLLES,BOURNAINVILLE,49.122899642,0.50099426931,106,,Bournainville-Faverolles,Bournainville-Faverolles,27,Eure,28,Normandie
27106,BOURNAINVILLE FAVEROLLES,27230,BOURNAINVILLE FAVEROLLES,FAVEROLLES LES MARES,49.122899642,0.50099426931,106,,Bournainville-Faverolles,Bournainville-Faverolles,27,Eure,28,Normandie
27107,BOURNEVILLE STE CROIX,27500,BOURNEVILLE STE CROIX,,49.39187586,0.620240697299,107,,Bourneville-Sainte-Croix,Bourneville-Sainte-Croix,27,Eure,28,Normandie
27107,BOURNEVILLE STE CROIX,27500,BOURNEVILLE STE CROIX,STE CROIX SUR AIZIER,49.39187586,0.620240697299,107,,Bourneville-Sainte-Croix,Bourneville-Sainte-Croix,27,Eure,28,Normandie
27108,BOURTH,27580,BOURTH,,48.7632114791,0.806179122315,108,,Bourth,Bourth,27,Eure,28,Normandie
27109,BRAY,27170,BRAY,,49.1042865972,0.836034010242,109,,Bray,Bray,27,Eure,28,Normandie
27110,BRESTOT,27350,BRESTOT,,49.3428492268,0.68056972384,110,,Brestot,Brestot,27,Eure,28,Normandie
27111,BRETAGNOLLES,27220,BRETAGNOLLES,,48.946568834,1.34129302634,111,,Bretagnolles,Bretagnolles,27,Eure,28,Normandie
27112,BRETEUIL,27160,BRETEUIL,,48.8525387012,0.899890466074,112,,Breteuil,Breteuil,27,Eure,28,Normandie
27112,BRETEUIL,27160,BRETEUIL,CINTRAY,48.8525387012,0.899890466074,112,,Breteuil,Breteuil,27,Eure,28,Normandie
27112,BRETEUIL,27160,BRETEUIL,LA GUEROULDE,48.8525387012,0.899890466074,112,,Breteuil,Breteuil,27,Eure,28,Normandie
27113,BRETIGNY,27800,BRETIGNY,,49.2149558389,0.662526045546,113,,Brétigny,Brétigny,27,Eure,28,Normandie
27114,BREUILPONT,27640,BREUILPONT,,48.9649279458,1.43949488173,114,,Breuilpont,Breuilpont,27,Eure,28,Normandie
27115,BREUX SUR AVRE,27570,BREUX SUR AVRE,,48.767360989,1.07169620391,115,,Breux-sur-Avre,Breux-sur-Avre,27,Eure,28,Normandie
27116,BRIONNE,27800,BRIONNE,,49.187842587,0.712154019961,116,,Brionne,Brionne,27,Eure,28,Normandie
27117,BROGLIE,27270,BROGLIE,,49.0033194682,0.525834728262,117,,Broglie,Broglie,27,Eure,28,Normandie
27118,BROSVILLE,27930,BROSVILLE,,49.1097913222,1.11344092166,118,,Brosville,Brosville,27,Eure,28,Normandie
27119,BUEIL,27730,BUEIL,,48.9343375603,1.44434816017,119,,Bueil,Bueil,27,Eure,28,Normandie
27120,BUREY,27190,BUREY,,48.9922829548,0.944514139448,120,,Burey,Burey,27,Eure,28,Normandie
27123,CAILLOUET ORGEVILLE,27120,CAILLOUET ORGEVILLE,,49.0034124144,1.31639623095,123,,Caillouet-Orgeville,Caillouet-Orgeville,27,Eure,28,Normandie
27124,CAILLY SUR EURE,27490,CAILLY SUR EURE,,49.1194337911,1.21848512049,124,,Cailly-sur-Eure,Cailly-sur-Eure,27,Eure,28,Normandie
27125,CALLEVILLE,27800,CALLEVILLE,,49.1904141623,0.7558527668,125,,Calleville,Calleville,27,Eure,28,Normandie
27126,CAMPIGNY,27500,CAMPIGNY,,49.317818102,0.561573922841,126,,Campigny,Campigny,27,Eure,28,Normandie
27127,CANAPPEVILLE,27400,CANAPPEVILLE,,49.1572590127,1.08933240354,127,,Canappeville,Canappeville,27,Eure,28,Normandie
27129,CAORCHES ST NICOLAS,27300,CAORCHES ST NICOLAS,,49.0782233141,0.547592654037,129,,Caorches-Saint-Nicolas,Caorches-Saint-Nicolas,27,Eure,28,Normandie
27129,CAORCHES ST NICOLAS,27300,CAORCHES ST NICOLAS,ST NICOLAS DU BOSC L ABBE,49.0782233141,0.547592654037,129,,Caorches-Saint-Nicolas,Caorches-Saint-Nicolas,27,Eure,28,Normandie
27130,CAPELLE LES GRANDS,27270,CAPELLE LES GRANDS,,49.0486679011,0.472112443094,130,,Capelle-les-Grands,Capelle-les-Grands,27,Eure,28,Normandie
27131,CARSIX,27300,CARSIX,,49.1414433173,0.67425797738,131,,Carsix,Carsix,27,Eure,28,Normandie
27132,CAUGE,27180,CAUGE,,49.0249871767,1.04289589513,132,,Caugé,Caugé,27,Eure,28,Normandie
27133,CAUMONT,27310,CAUMONT,,49.3666663347,0.908461916692,133,,Caumont,Caumont,27,Eure,28,Normandie
27134,CAUVERVILLE EN ROUMOIS,27350,CAUVERVILLE EN ROUMOIS,,49.3552369036,0.647055646241,134,,Cauverville-en-Roumois,Cauverville-en-Roumois,27,Eure,28,Normandie
27135,CESSEVILLE,27110,CESSEVILLE,,49.1795942946,0.976416349214,135,,Cesseville,Cesseville,27,Eure,28,Normandie
27136,CHAIGNES,27120,CHAIGNES,,49.0138196072,1.45166731752,136,,Chaignes,Chaignes,27,Eure,28,Normandie
27137,CHAISE DIEU DU THEIL,27580,CHAISE DIEU DU THEIL,,48.7557442906,0.770932355905,137,,Chaise-Dieu-du-Theil,Chaise-Dieu-du-Theil,27,Eure,28,Normandie
27138,CHAMBLAC,27270,CHAMBLAC,,48.9922969415,0.562558424938,138,,Chamblac,Chamblac,27,Eure,28,Normandie
27138,CHAMBLAC,27270,CHAMBLAC,BOSC MOREL,48.9922969415,0.562558424938,138,,Chamblac,Chamblac,27,Eure,28,Normandie
27139,CHAMBORD,27250,CHAMBORD,,48.8825741815,0.620980424578,139,,Chambord,Chambord,27,Eure,28,Normandie
27140,CHAMBRAY,27120,CHAMBRAY,,49.0800044787,1.31942825082,140,,Chambray,Chambray,27,Eure,28,Normandie
27141,CHAMP DOLENT,27190,CHAMP DOLENT,,48.9588653377,1.00803800645,141,,Champ-Dolent,Champ-Dolent,27,Eure,28,Normandie
27142,CHAMPENARD,27600,CHAMPENARD,,49.1071396766,1.32806618446,142,,Champenard,Champenard,27,Eure,28,Normandie
27143,CHAMPIGNOLLES,27330,CHAMPIGNOLLES,,48.9579288381,0.760809777033,143,,Champignolles,Champignolles,27,Eure,28,Normandie
27144,CHAMPIGNY LA FUTELAYE,27220,CHAMPIGNY LA FUTELAYE,,48.8696186987,1.30067720632,144,,Champigny-la-Futelaye,Champigny-la-Futelaye,27,Eure,28,Normandie
27146,LA CHAPELLE BAYVEL,27260,LA CHAPELLE BAYVEL,,49.2808661732,0.406356152617,146,La,Chapelle-Bayvel,La Chapelle-Bayvel,27,Eure,28,Normandie
27147,LA CHAPELLE DU BOIS DES FAULX,27930,LA CHAPELLE DU BOIS DES FAULX,,49.1080526721,1.17181025822,147,La,Chapelle-du-Bois-des-Faulx,La Chapelle-du-Bois-des-Faulx,27,Eure,28,Normandie
27148,LA CHAPELLE GAUTHIER,27270,LA CHAPELLE GAUTHIER,,48.9851351393,0.463962400697,148,La,Chapelle-Gauthier,La Chapelle-Gauthier,27,Eure,28,Normandie
27149,LA CHAPELLE HARENG,27230,LA CHAPELLE HARENG,,49.1113997335,0.417172283408,149,La,Chapelle-Hareng,La Chapelle-Hareng,27,Eure,28,Normandie
27150,LA CHAPELLE REANVILLE,27950,LA CHAPELLE REANVILLE,,49.0946165655,1.38085267285,150,La,Chapelle-Réanville,La Chapelle-Réanville,27,Eure,28,Normandie
27151,CHARLEVAL,27380,CHARLEVAL,,49.3796508002,1.38961447522,151,,Charleval,Charleval,27,Eure,28,Normandie
27152,CHATEAU SUR EPTE,27420,CHATEAU SUR EPTE,,49.2004173663,1.65874504917,152,,Château-sur-Epte,Château-sur-Epte,27,Eure,28,Normandie
27153,CHAUVINCOURT PROVEMONT,27150,CHAUVINCOURT PROVEMONT,,49.2808952315,1.64529134367,153,,Chauvincourt-Provemont,Chauvincourt-Provemont,27,Eure,28,Normandie
27153,CHAUVINCOURT PROVEMONT,27150,CHAUVINCOURT PROVEMONT,PROVEMONT,49.2808952315,1.64529134367,153,,Chauvincourt-Provemont,Chauvincourt-Provemont,27,Eure,28,Normandie
27154,CHAVIGNY BAILLEUL,27220,CHAVIGNY BAILLEUL,,48.8800655781,1.19622692013,154,,Chavigny-Bailleul,Chavigny-Bailleul,27,Eure,28,Normandie
27155,CHENNEBRUN,27820,CHENNEBRUN,,48.6841164258,0.774515882818,155,,Chennebrun,Chennebrun,27,Eure,28,Normandie
27156,CHERONVILLIERS,27250,CHERONVILLIERS,,48.7921490102,0.760679577502,156,,Chéronvilliers,Chéronvilliers,27,Eure,28,Normandie
27157,MARBOIS,27160,MARBOIS,LE CHESNE,48.8910449707,0.93590219795,157,,Marbois,Marbois,27,Eure,28,Normandie
27157,MARBOIS,27160,MARBOIS,ST DENIS DU BEHELAN,48.8910449707,0.93590219795,157,,Marbois,Marbois,27,Eure,28,Normandie
27157,MARBOIS,27240,MARBOIS,CHANTELOUP,48.8910449707,0.93590219795,157,,Marbois,Marbois,27,Eure,28,Normandie
27157,MARBOIS,27240,MARBOIS,LES ESSARTS,48.8910449707,0.93590219795,157,,Marbois,Marbois,27,Eure,28,Normandie
27158,CIERREY,27930,CIERREY,,49.0017720899,1.26604981286,158,,Cierrey,Cierrey,27,Eure,28,Normandie
27161,CLAVILLE,27180,CLAVILLE,,49.0485570128,1.01887133225,161,,Claville,Claville,27,Eure,28,Normandie
27162,COLLANDRES QUINCARNON,27190,COLLANDRES QUINCARNON,,48.9937537371,0.86271555609,162,,Collandres-Quincarnon,Collandres-Quincarnon,27,Eure,28,Normandie
27163,COLLETOT,27500,COLLETOT,,49.3499536771,0.623964655423,163,,Colletot,Colletot,27,Eure,28,Normandie
27164,COMBON,27170,COMBON,,49.0960144731,0.885456482131,164,,Combon,Combon,27,Eure,28,Normandie
27165,CONCHES EN OUCHE,27190,CONCHES EN OUCHE,,48.9547259686,0.93199579313,165,,Conches-en-Ouche,Conches-en-Ouche,27,Eure,28,Normandie
27167,CONDE SUR RISLE,27290,CONDE SUR RISLE,,49.3076008928,0.608409242024,167,,Condé-sur-Risle,Condé-sur-Risle,27,Eure,28,Normandie
27168,CONNELLES,27430,CONNELLES,,49.2604810004,1.27619983824,168,,Connelles,Connelles,27,Eure,28,Normandie
27169,CONTEVILLE,27210,CONTEVILLE,,49.4174960871,0.391276015721,169,,Conteville,Conteville,27,Eure,28,Normandie
27170,CORMEILLES,27260,CORMEILLES,,49.2517321161,0.384551732765,170,,Cormeilles,Cormeilles,27,Eure,28,Normandie
27171,LE CORMIER,27120,LE CORMIER,,48.9848531397,1.29682786078,171,Le,Cormier,Le Cormier,27,Eure,28,Normandie
27173,CORNEVILLE LA FOUQUETIERE,27300,CORNEVILLE LA FOUQUETIERE,,49.0598701998,0.703048495388,173,,Corneville-la-Fouquetière,Corneville-la-Fouquetière,27,Eure,28,Normandie
27174,CORNEVILLE SUR RISLE,27500,CORNEVILLE SUR RISLE,,49.3406636029,0.598502676262,174,,Corneville-sur-Risle,Corneville-sur-Risle,27,Eure,28,Normandie
27175,CORNY,27700,CORNY,,49.2829378657,1.45742747921,175,,Corny,Corny,27,Eure,28,Normandie
27176,COUDRAY,27150,COUDRAY,,49.3432979436,1.49682466931,176,,Coudray,Coudray,27,Eure,28,Normandie
27177,COUDRES,27220,COUDRES,,48.8631247309,1.23599075462,177,,Coudres,Coudres,27,Eure,28,Normandie
27179,COURBEPINE,27300,COURBEPINE,,49.1293094881,0.566197050846,179,,Courbépine,Courbépine,27,Eure,28,Normandie
27180,COURCELLES SUR SEINE,27940,COURCELLES SUR SEINE,,49.1852202831,1.36612835383,180,,Courcelles-sur-Seine,Courcelles-sur-Seine,27,Eure,28,Normandie
27181,COURDEMANCHE,27320,COURDEMANCHE,,48.8022859071,1.27664231305,181,,Courdemanche,Courdemanche,27,Eure,28,Normandie
27182,COURTEILLES,27130,COURTEILLES,,48.7385427936,0.999904902723,182,,Courteilles,Courteilles,27,Eure,28,Normandie
27183,LA COUTURE BOUSSEY,27750,LA COUTURE BOUSSEY,,48.9020135083,1.396314901,183,La,Couture-Boussey,La Couture-Boussey,27,Eure,28,Normandie
27184,CRASVILLE,27400,CRASVILLE,,49.205196716,1.07197851563,184,,Crasville,Crasville,27,Eure,28,Normandie
27185,CRESTOT,27110,CRESTOT,,49.2039621822,0.969531161079,185,,Crestot,Crestot,27,Eure,28,Normandie
27187,CRIQUEBEUF LA CAMPAGNE,27110,CRIQUEBEUF LA CAMPAGNE,,49.1985839238,0.997594751714,187,,Criquebeuf-la-Campagne,Criquebeuf-la-Campagne,27,Eure,28,Normandie
27188,CRIQUEBEUF SUR SEINE,27340,CRIQUEBEUF SUR SEINE,,49.2931111781,1.10157387414,188,,Criquebeuf-sur-Seine,Criquebeuf-sur-Seine,27,Eure,28,Normandie
27189,LA CROISILLE,27190,LA CROISILLE,,48.9904943471,0.97564552342,189,La,Croisille,La Croisille,27,Eure,28,Normandie
27190,CROISY SUR EURE,27120,CROISY SUR EURE,,49.0260942354,1.34202925312,190,,Croisy-sur-Eure,Croisy-sur-Eure,27,Eure,28,Normandie
27191,CLEF VALLEE D EURE,27490,CLEF VALLEE D EURE,ECARDENVILLE SUR EURE,49.1183025014,1.24841485646,191,,Clef Vallée d'Eure,Clef Vallée d'Eure,27,Eure,28,Normandie
27191,CLEF VALLEE D EURE,27490,CLEF VALLEE D EURE,FONTAINE HEUDEBOURG,49.1183025014,1.24841485646,191,,Clef Vallée d'Eure,Clef Vallée d'Eure,27,Eure,28,Normandie
27191,CLEF VALLEE D EURE,27490,CLEF VALLEE D EURE,LA CROIX ST LEUFROY,49.1183025014,1.24841485646,191,,Clef Vallée d'Eure,Clef Vallée d'Eure,27,Eure,28,Normandie
27192,CROSVILLE LA VIEILLE,27110,CROSVILLE LA VIEILLE,,49.1515342749,0.931538491399,192,,Crosville-la-Vieille,Crosville-la-Vieille,27,Eure,28,Normandie
27193,CROTH,27530,CROTH,,48.8507405664,1.36816168904,193,,Croth,Croth,27,Eure,28,Normandie
27194,CUVERVILLE,27700,CUVERVILLE,,49.2823822773,1.37038262519,194,,Cuverville,Cuverville,27,Eure,28,Normandie
27196,LES DAMPS,27340,LES DAMPS,,49.2941696239,1.17226957611,196,Les,Damps,Les Damps,27,Eure,28,Normandie
27198,MESNILS SUR ITON,27160,MESNILS SUR ITON,CONDE SUR ITON,48.8643668138,1.08042048322,198,,Mesnils-sur-Iton,Mesnils-sur-Iton,27,Eure,28,Normandie
27198,MESNILS SUR ITON,27240,MESNILS SUR ITON,DAMVILLE,48.8643668138,1.08042048322,198,,Mesnils-sur-Iton,Mesnils-sur-Iton,27,Eure,28,Normandie
27198,MESNILS SUR ITON,27240,MESNILS SUR ITON,GOUVILLE,48.8643668138,1.08042048322,198,,Mesnils-sur-Iton,Mesnils-sur-Iton,27,Eure,28,Normandie
27198,MESNILS SUR ITON,27240,MESNILS SUR ITON,LE RONCENAY,48.8643668138,1.08042048322,198,,Mesnils-sur-Iton,Mesnils-sur-Iton,27,Eure,28,Normandie
27198,MESNILS SUR ITON,27240,MESNILS SUR ITON,LE RONCENAY AUTHENAY,48.8643668138,1.08042048322,198,,Mesnils-sur-Iton,Mesnils-sur-Iton,27,Eure,28,Normandie
27198,MESNILS SUR ITON,27240,MESNILS SUR ITON,LE SACQ,48.8643668138,1.08042048322,198,,Mesnils-sur-Iton,Mesnils-sur-Iton,27,Eure,28,Normandie
27198,MESNILS SUR ITON,27240,MESNILS SUR ITON,LES MINIERES,48.8643668138,1.08042048322,198,,Mesnils-sur-Iton,Mesnils-sur-Iton,27,Eure,28,Normandie
27198,MESNILS SUR ITON,27240,MESNILS SUR ITON,MANTHELON,48.8643668138,1.08042048322,198,,Mesnils-sur-Iton,Mesnils-sur-Iton,27,Eure,28,Normandie
27199,DANGU,27720,DANGU,,49.2614400843,1.6883198485,199,,Dangu,Dangu,27,Eure,28,Normandie
27200,DARDEZ,27930,DARDEZ,,49.0839988552,1.20732659473,200,,Dardez,Dardez,27,Eure,28,Normandie
27201,DAUBEUF LA CAMPAGNE,27110,DAUBEUF LA CAMPAGNE,,49.1891854799,1.02944450993,201,,Daubeuf-la-Campagne,Daubeuf-la-Campagne,27,Eure,28,Normandie
27202,DAUBEUF PRES VATTEVILLE,27430,DAUBEUF PRES VATTEVILLE,,49.2606303253,1.31083595676,202,,Daubeuf-près-Vatteville,Daubeuf-près-Vatteville,27,Eure,28,Normandie
27203,DOUAINS,27120,DOUAINS,,49.0439101379,1.43121534978,203,,Douains,Douains,27,Eure,28,Normandie
27204,DOUDEAUVILLE EN VEXIN,27150,DOUDEAUVILLE EN VEXIN,,49.322695985,1.58935105876,204,,Doudeauville-en-Vexin,Doudeauville-en-Vexin,27,Eure,28,Normandie
27205,DOUVILLE SUR ANDELLE,27380,DOUVILLE SUR ANDELLE,,49.3329198858,1.30756774405,205,,Douville-sur-Andelle,Douville-sur-Andelle,27,Eure,28,Normandie
27206,DROISY,27320,DROISY,,48.7975773096,1.1258906091,206,,Droisy,Droisy,27,Eure,28,Normandie
27206,DROISY,27320,DROISY,PANLATTE,48.7975773096,1.1258906091,206,,Droisy,Droisy,27,Eure,28,Normandie
27207,DRUCOURT,27230,DRUCOURT,,49.116516097,0.459175546757,207,,Drucourt,Drucourt,27,Eure,28,Normandie
27208,DURANVILLE,27230,DURANVILLE,,49.1429973941,0.499704273238,208,,Duranville,Duranville,27,Eure,28,Normandie
27209,ECAQUELON,27290,ECAQUELON,,49.2887544007,0.716950300267,209,,Écaquelon,Écaquelon,27,Eure,28,Normandie
27210,ECARDENVILLE LA CAMPAGNE,27170,ECARDENVILLE LA CAMPAGNE,,49.1239824474,0.834768267271,210,,Écardenville-la-Campagne,Écardenville-la-Campagne,27,Eure,28,Normandie
27212,ECAUVILLE,27110,ECAUVILLE,,49.1260165853,0.991502116806,212,,Écauville,Écauville,27,Eure,28,Normandie
27213,VEXIN SUR EPTE,27420,VEXIN SUR EPTE,CAHAIGNES,49.1515466021,1.59773112902,213,,Vexin-sur-Epte,Vexin-sur-Epte,27,Eure,28,Normandie
27213,VEXIN SUR EPTE,27420,VEXIN SUR EPTE,CANTIERS,49.1515466021,1.59773112902,213,,Vexin-sur-Epte,Vexin-sur-Epte,27,Eure,28,Normandie
27213,VEXIN SUR EPTE,27510,VEXIN SUR EPTE,FONTENAY EN VEXIN,49.1515466021,1.59773112902,213,,Vexin-sur-Epte,Vexin-sur-Epte,27,Eure,28,Normandie
27213,VEXIN SUR EPTE,27510,VEXIN SUR EPTE,FORET LA FOLIE,49.1515466021,1.59773112902,213,,Vexin-sur-Epte,Vexin-sur-Epte,27,Eure,28,Normandie
27213,VEXIN SUR EPTE,27510,VEXIN SUR EPTE,GUITRY,49.1515466021,1.59773112902,213,,Vexin-sur-Epte,Vexin-sur-Epte,27,Eure,28,Normandie
27213,VEXIN SUR EPTE,27510,VEXIN SUR EPTE,PANILLEUSE,49.1515466021,1.59773112902,213,,Vexin-sur-Epte,Vexin-sur-Epte,27,Eure,28,Normandie
27213,VEXIN SUR EPTE,27510,VEXIN SUR EPTE,TOURNY,49.1515466021,1.59773112902,213,,Vexin-sur-Epte,Vexin-sur-Epte,27,Eure,28,Normandie
27213,VEXIN SUR EPTE,27630,VEXIN SUR EPTE,BERTHENONVILLE,49.1515466021,1.59773112902,213,,Vexin-sur-Epte,Vexin-sur-Epte,27,Eure,28,Normandie
27213,VEXIN SUR EPTE,27630,VEXIN SUR EPTE,BUS ST REMY,49.1515466021,1.59773112902,213,,Vexin-sur-Epte,Vexin-sur-Epte,27,Eure,28,Normandie
27213,VEXIN SUR EPTE,27630,VEXIN SUR EPTE,CIVIERES,49.1515466021,1.59773112902,213,,Vexin-sur-Epte,Vexin-sur-Epte,27,Eure,28,Normandie
27213,VEXIN SUR EPTE,27630,VEXIN SUR EPTE,DAMPSMESNIL,49.1515466021,1.59773112902,213,,Vexin-sur-Epte,Vexin-sur-Epte,27,Eure,28,Normandie
27213,VEXIN SUR EPTE,27630,VEXIN SUR EPTE,ECOS,49.1515466021,1.59773112902,213,,Vexin-sur-Epte,Vexin-sur-Epte,27,Eure,28,Normandie
27213,VEXIN SUR EPTE,27630,VEXIN SUR EPTE,FOURGES,49.1515466021,1.59773112902,213,,Vexin-sur-Epte,Vexin-sur-Epte,27,Eure,28,Normandie
27213,VEXIN SUR EPTE,27630,VEXIN SUR EPTE,FOURS EN VEXIN,49.1515466021,1.59773112902,213,,Vexin-sur-Epte,Vexin-sur-Epte,27,Eure,28,Normandie
27214,ECOUIS,27440,ECOUIS,,49.3066926655,1.42999721744,214,,Écouis,Écouis,27,Eure,28,Normandie
27215,ECQUETOT,27110,ECQUETOT,,49.1695457019,1.01305919876,215,,Ecquetot,Ecquetot,27,Eure,28,Normandie
27216,EMALLEVILLE,27930,EMALLEVILLE,,49.1016649726,1.1570133623,216,,Émalleville,Émalleville,27,Eure,28,Normandie
27217,EMANVILLE,27190,EMANVILLE,,49.0589220607,0.906701238061,217,,Émanville,Émanville,27,Eure,28,Normandie
27218,EPAIGNES,27260,EPAIGNES,,49.2733162513,0.45367421999,218,,Épaignes,Épaignes,27,Eure,28,Normandie
27219,EPEGARD,27110,EPEGARD,,49.1824523923,0.874988545882,219,,Épégard,Épégard,27,Eure,28,Normandie
27220,EPIEDS,27730,EPIEDS,,48.9349153283,1.39579544609,220,,Épieds,Épieds,27,Eure,28,Normandie
27222,EPREVILLE EN LIEUVIN,27560,EPREVILLE EN LIEUVIN,,49.2051733652,0.538408171627,222,,Épreville-en-Lieuvin,Épreville-en-Lieuvin,27,Eure,28,Normandie
27224,EPREVILLE PRES LE NEUBOURG,27110,EPREVILLE PRES LE NEUBOURG,,49.1295509765,0.876279430703,224,,Épreville-près-le-Neubourg,Épreville-près-le-Neubourg,27,Eure,28,Normandie
27226,ETREPAGNY,27150,ETREPAGNY,,49.3119944705,1.61614236385,226,,Étrépagny,Étrépagny,27,Eure,28,Normandie
27227,ETREVILLE,27350,ETREVILLE,,49.3795673555,0.65533275771,227,,Étréville,Étréville,27,Eure,28,Normandie
27228,ETURQUERAYE,27350,ETURQUERAYE,,49.3693948332,0.688772409605,228,,Éturqueraye,Éturqueraye,27,Eure,28,Normandie
27229,EVREUX,27000,EVREUX,,49.02015421,1.14164412464,229,,Évreux,Évreux,27,Eure,28,Normandie
27229,EVREUX,27000,EVREUX,LA MADELEINE,49.02015421,1.14164412464,229,,Évreux,Évreux,27,Eure,28,Normandie
27230,EZY SUR EURE,27530,EZY SUR EURE,,48.8709806514,1.41262980866,230,,Ézy-sur-Eure,Ézy-sur-Eure,27,Eure,28,Normandie
27231,FAINS,27120,FAINS,,48.99686242,1.38487242468,231,,Fains,Fains,27,Eure,28,Normandie
27232,FARCEAUX,27150,FARCEAUX,,49.2917599678,1.52826011548,232,,Farceaux,Farceaux,27,Eure,28,Normandie
27233,FATOUVILLE GRESTAIN,27210,FATOUVILLE GRESTAIN,,49.4132819634,0.332433959098,233,,Fatouville-Grestain,Fatouville-Grestain,27,Eure,28,Normandie
27234,FAUVILLE,27930,FAUVILLE,,49.0242697215,1.19907051079,234,,Fauville,Fauville,27,Eure,28,Normandie
27235,FAVEROLLES LA CAMPAGNE,27190,FAVEROLLES LA CAMPAGNE,,49.0206303776,0.927054936984,235,,Faverolles-la-Campagne,Faverolles-la-Campagne,27,Eure,28,Normandie
27237,LE FAVRIL,27230,LE FAVRIL,,49.1831075532,0.525458384869,237,Le,Favril,Le Favril,27,Eure,28,Normandie
27238,FERRIERES HAUT CLOCHER,27190,FERRIERES HAUT CLOCHER,,49.0203824794,0.987492004365,238,,Ferrières-Haut-Clocher,Ferrières-Haut-Clocher,27,Eure,28,Normandie
27239,FERRIERES ST HILAIRE,27270,FERRIERES ST HILAIRE,,49.0283010242,0.570153150904,239,,Ferrières-Saint-Hilaire,Ferrières-Saint-Hilaire,27,Eure,28,Normandie
27240,LA FERRIERE SUR RISLE,27760,LA FERRIERE SUR RISLE,,48.9777818863,0.787128070523,240,La,Ferrière-sur-Risle,La Ferrière-sur-Risle,27,Eure,28,Normandie
27241,FEUGUEROLLES,27110,FEUGUEROLLES,,49.1317754002,1.03389011854,241,,Feuguerolles,Feuguerolles,27,Eure,28,Normandie
27242,LE FIDELAIRE,27190,LE FIDELAIRE,,48.9436797791,0.80246046702,242,Le,Fidelaire,Le Fidelaire,27,Eure,28,Normandie
27243,FIQUEFLEUR EQUAINVILLE,27210,FIQUEFLEUR EQUAINVILLE,,49.3985981569,0.316862083899,243,,Fiquefleur-Équainville,Fiquefleur-Équainville,27,Eure,28,Normandie
27245,FLEURY LA FORET,27480,FLEURY LA FORET,,49.4265842006,1.55588075817,245,,Fleury-la-Forêt,Fleury-la-Forêt,27,Eure,28,Normandie
27246,FLEURY SUR ANDELLE,27380,FLEURY SUR ANDELLE,,49.3635499787,1.35185720942,246,,Fleury-sur-Andelle,Fleury-sur-Andelle,27,Eure,28,Normandie
27247,FLIPOU,27380,FLIPOU,,49.3128587162,1.28416486322,247,,Flipou,Flipou,27,Eure,28,Normandie
27248,FOLLEVILLE,27230,FOLLEVILLE,,49.1581049505,0.524835316917,248,,Folleville,Folleville,27,Eure,28,Normandie
27249,FONTAINE BELLENGER,27600,FONTAINE BELLENGER,,49.1802280169,1.2530305732,249,,Fontaine-Bellenger,Fontaine-Bellenger,27,Eure,28,Normandie
27251,FONTAINE L ABBE,27470,FONTAINE L ABBE,,49.0883291785,0.687966226442,251,,Fontaine-l'Abbé,Fontaine-l'Abbé,27,Eure,28,Normandie
27252,FONTAINE LA LOUVET,27230,FONTAINE LA LOUVET,,49.1584396153,0.445984273017,252,,Fontaine-la-Louvet,Fontaine-la-Louvet,27,Eure,28,Normandie
27253,FONTAINE LA SORET,27550,FONTAINE LA SORET,,49.1453794348,0.70849131197,253,,Fontaine-la-Soret,Fontaine-la-Soret,27,Eure,28,Normandie
27254,FONTAINE SOUS JOUY,27120,FONTAINE SOUS JOUY,,49.0644082735,1.28056210161,254,,Fontaine-sous-Jouy,Fontaine-sous-Jouy,27,Eure,28,Normandie
27256,LA FORET DU PARC,27220,LA FORET DU PARC,,48.927613225,1.24035993264,256,La,Forêt-du-Parc,La Forêt-du-Parc,27,Eure,28,Normandie
27258,FORT MOVILLE,27210,FORT MOVILLE,,49.3289644222,0.401718314243,258,,Fort-Moville,Fort-Moville,27,Eure,28,Normandie
27259,FOUCRAINVILLE,27220,FOUCRAINVILLE,,48.9274620915,1.31992754273,259,,Foucrainville,Foucrainville,27,Eure,28,Normandie
27260,FOULBEC,27210,FOULBEC,,49.3940595963,0.422225026398,260,,Foulbec,Foulbec,27,Eure,28,Normandie
27261,FOUQUEVILLE,27370,FOUQUEVILLE,,49.2221102,0.963471124164,261,,Fouqueville,Fouqueville,27,Eure,28,Normandie
27263,FOURMETOT,27500,FOURMETOT,,49.3782168182,0.574020669603,263,,Fourmetot,Fourmetot,27,Eure,28,Normandie
27265,FRANCHEVILLE,27160,FRANCHEVILLE,,48.7942723344,0.838965152911,265,,Francheville,Francheville,27,Eure,28,Normandie
27266,FRANQUEVILLE,27800,FRANQUEVILLE,,49.1730511445,0.68581354676,266,,Franqueville,Franqueville,27,Eure,28,Normandie
27267,FRENEUSE SUR RISLE,27290,FRENEUSE SUR RISLE,,49.2462632726,0.671497425624,267,,Freneuse-sur-Risle,Freneuse-sur-Risle,27,Eure,28,Normandie
27268,LE FRESNE,27190,LE FRESNE,,48.9567170463,0.975329849084,268,Le,Fresne,Le Fresne,27,Eure,28,Normandie
27269,FRESNE CAUVERVILLE,27260,FRESNE CAUVERVILLE,,49.2012892333,0.468795413271,269,,Fresne-Cauverville,Fresne-Cauverville,27,Eure,28,Normandie
27270,FRESNE L ARCHEVEQUE,27700,FRESNE L ARCHEVEQUE,,49.288073354,1.40975224515,270,,Fresne-l'Archevêque,Fresne-l'Archevêque,27,Eure,28,Normandie
27271,FRESNEY,27220,FRESNEY,,48.9473622629,1.30677941323,271,,Fresney,Fresney,27,Eure,28,Normandie
27273,GADENCOURT,27120,GADENCOURT,,48.9842332257,1.40341807937,273,,Gadencourt,Gadencourt,27,Eure,28,Normandie
27274,GAILLARDBOIS CRESSENVILLE,27440,GAILLARDBOIS CRESSENVILLE,,49.3391972504,1.39978566632,274,,Gaillardbois-Cressenville,Gaillardbois-Cressenville,27,Eure,28,Normandie
27275,GAILLON,27600,GAILLON,,49.1591132617,1.34701632223,275,,Gaillon,Gaillon,27,Eure,28,Normandie
27276,GAMACHES EN VEXIN,27150,GAMACHES EN VEXIN,,49.2664796169,1.61611517,276,,Gamaches-en-Vexin,Gamaches-en-Vexin,27,Eure,28,Normandie
27277,LA BARONNIE,27220,LA BARONNIE,GARENCIERES,48.9496964914,1.25678069483,277,La,Baronnie,La Baronnie,27,Eure,28,Normandie
27277,LA BARONNIE,27220,LA BARONNIE,QUESSIGNY,48.9496964914,1.25678069483,277,La,Baronnie,La Baronnie,27,Eure,28,Normandie
27278,GARENNES SUR EURE,27780,GARENNES SUR EURE,,48.9091632866,1.43367322183,278,,Garennes-sur-Eure,Garennes-sur-Eure,27,Eure,28,Normandie
27279,GASNY,27620,GASNY,,49.1020218463,1.59975187173,279,,Gasny,Gasny,27,Eure,28,Normandie
27280,GAUCIEL,27930,GAUCIEL,,49.0356183804,1.24708624979,280,,Gauciel,Gauciel,27,Eure,28,Normandie
27281,GAUDREVILLE LA RIVIERE,27190,GAUDREVILLE LA RIVIERE,,48.9601606074,1.0298861236,281,,Gaudreville-la-Rivière,Gaudreville-la-Rivière,27,Eure,28,Normandie
27282,GAUVILLE LA CAMPAGNE,27930,GAUVILLE LA CAMPAGNE,,49.0492815413,1.08358712088,282,,Gauville-la-Campagne,Gauville-la-Campagne,27,Eure,28,Normandie
27284,GISORS,27140,GISORS,,49.2786723691,1.76876649685,284,,Gisors,Gisors,27,Eure,28,Normandie
27285,GIVERNY,27620,GIVERNY,,49.0819438915,1.53288773518,285,,Giverny,Giverny,27,Eure,28,Normandie
27286,GIVERVILLE,27560,GIVERVILLE,,49.1913962513,0.56791752866,286,,Giverville,Giverville,27,Eure,28,Normandie
27287,GLISOLLES,27190,GLISOLLES,,48.9870513401,1.00330761999,287,,Glisolles,Glisolles,27,Eure,28,Normandie
27288,GLOS SUR RISLE,27290,GLOS SUR RISLE,,49.2723717396,0.68457116182,288,,Glos-sur-Risle,Glos-sur-Risle,27,Eure,28,Normandie
27289,LA GOULAFRIERE,27390,LA GOULAFRIERE,,48.9480738928,0.432319912039,289,La,Goulafrière,La Goulafrière,27,Eure,28,Normandie
27290,GOUPILLIERES,27170,GOUPILLIERES,,49.1218249959,0.765958514912,290,,Goupil-Othon,Goupil-Othon,27,Eure,28,Normandie
27291,GOURNAY LE GUERIN,27580,GOURNAY LE GUERIN,,48.7162632527,0.76932677625,291,,Gournay-le-Guérin,Gournay-le-Guérin,27,Eure,28,Normandie
27294,GRAINVILLE,27380,GRAINVILLE,,49.3450350135,1.3678564295,294,,Val d'Orger,Val d'Orger,27,Eure,28,Normandie
27295,GRAND CAMP,27270,GRAND CAMP,,49.0440587541,0.533720192611,295,,Grand-Camp,Grand-Camp,27,Eure,28,Normandie
27297,GRANDVILLIERS,27240,GRANDVILLIERS,,48.8083735212,1.07572686226,297,,Grandvilliers,Grandvilliers,27,Eure,28,Normandie
27297,GRANDVILLIERS,27240,GRANDVILLIERS,HELLENVILLIERS,48.8083735212,1.07572686226,297,,Grandvilliers,Grandvilliers,27,Eure,28,Normandie
27298,GRAVERON SEMERVILLE,27110,GRAVERON SEMERVILLE,,49.0949364148,0.966325721696,298,,Graveron-Sémerville,Graveron-Sémerville,27,Eure,28,Normandie
27299,GRAVIGNY,27930,GRAVIGNY,,49.0522263912,1.16057059772,299,,Gravigny,Gravigny,27,Eure,28,Normandie
27300,GROSLEY SUR RISLE,27170,GROSLEY SUR RISLE,,49.0447187021,0.80546586873,300,,Grosley-sur-Risle,Grosley-sur-Risle,27,Eure,28,Normandie
27301,GROSSOEUVRE,27220,GROSSOEUVRE,,48.9401893709,1.18649118902,301,,Grossœuvre,Grossœuvre,27,Eure,28,Normandie
27302,LE BOSC DU THEIL,27370,LE BOSC DU THEIL,LE GROS THEIL,49.2281464419,0.840519309317,302,Le,Bosc du Theil,Le Bosc du Theil,27,Eure,28,Normandie
27302,LE BOSC DU THEIL,27370,LE BOSC DU THEIL,ST NICOLAS DU BOSC,49.2281464419,0.840519309317,302,Le,Bosc du Theil,Le Bosc du Theil,27,Eure,28,Normandie
27304,GUERNY,27720,GUERNY,,49.2248519011,1.67988249527,304,,Guerny,Guerny,27,Eure,28,Normandie
27306,GUICHAINVILLE,27930,GUICHAINVILLE,,48.9802791155,1.17901944848,306,,Guichainville,Guichainville,27,Eure,28,Normandie
27307,GUISENIERS,27700,GUISENIERS,,49.2141853933,1.47845934906,307,,Guiseniers,Guiseniers,27,Eure,28,Normandie
27309,L HABIT,27220,L HABIT,,48.8748495048,1.34772656122,309,L',Habit,L'Habit,27,Eure,28,Normandie
27310,HACQUEVILLE,27150,HACQUEVILLE,,49.2818666204,1.55751068434,310,,Hacqueville,Hacqueville,27,Eure,28,Normandie
27311,HARCOURT,27800,HARCOURT,,49.1659154983,0.776027385524,311,,Harcourt,Harcourt,27,Eure,28,Normandie
27312,HARDENCOURT COCHEREL,27120,HARDENCOURT COCHEREL,,49.0418737569,1.31601000617,312,,Hardencourt-Cocherel,Hardencourt-Cocherel,27,Eure,28,Normandie
27313,LA HARENGERE,27370,LA HARENGERE,,49.231363184,1.00114554009,313,La,Harengère,La Harengère,27,Eure,28,Normandie
27315,HARQUENCY,27700,HARQUENCY,,49.2494239305,1.49992266549,315,,Harquency,Harquency,27,Eure,28,Normandie
27316,HAUVILLE,27350,HAUVILLE,,49.3970508691,0.766578744404,316,,Hauville,Hauville,27,Eure,28,Normandie
27317,LA HAYE AUBREE,27350,LA HAYE AUBREE,,49.394154606,0.693346519486,317,La,Haye-Aubrée,La Haye-Aubrée,27,Eure,28,Normandie
27318,LA HAYE DE CALLEVILLE,27800,LA HAYE DE CALLEVILLE,,49.187518982,0.777866352437,318,La,Haye-de-Calleville,La Haye-de-Calleville,27,Eure,28,Normandie
27319,LA HAYE DE ROUTOT,27350,LA HAYE DE ROUTOT,,49.4030066708,0.728082467511,319,La,Haye-de-Routot,La Haye-de-Routot,27,Eure,28,Normandie
27320,LA HAYE DU THEIL,27370,LA HAYE DU THEIL,,49.2376362253,0.875327964748,320,La,Haye-du-Theil,La Haye-du-Theil,27,Eure,28,Normandie
27321,LA HAYE LE COMTE,27400,LA HAYE LE COMTE,,49.1964315375,1.13412648905,321,La,Haye-le-Comte,La Haye-le-Comte,27,Eure,28,Normandie
27322,LA HAYE MALHERBE,27400,LA HAYE MALHERBE,,49.2372337967,1.06625693496,322,La,Haye-Malherbe,La Haye-Malherbe,27,Eure,28,Normandie
27323,LA HAYE ST SYLVESTRE,27330,LA HAYE ST SYLVESTRE,,48.9110081722,0.593509071777,323,La,Haye-Saint-Sylvestre,La Haye-Saint-Sylvestre,27,Eure,28,Normandie
27324,HEBECOURT,27150,HEBECOURT,,49.3572259501,1.7219611931,324,,Hébécourt,Hébécourt,27,Eure,28,Normandie
27325,HECMANVILLE,27800,HECMANVILLE,,49.1726011508,0.66363488889,325,,Hecmanville,Hecmanville,27,Eure,28,Normandie
27326,HECOURT,27120,HECOURT,,48.9884228127,1.42873530731,326,,Hécourt,Hécourt,27,Eure,28,Normandie
27327,HECTOMARE,27110,HECTOMARE,,49.1927415084,0.944362009841,327,,Hectomare,Hectomare,27,Eure,28,Normandie
27329,HENNEZIS,27700,HENNEZIS,,49.1906897417,1.45636250544,329,,Hennezis,Hennezis,27,Eure,28,Normandie
27330,HERQUEVILLE,27430,HERQUEVILLE,,49.2438082894,1.2734536146,330,,Herqueville,Herqueville,27,Eure,28,Normandie
27331,HEUBECOURT HARICOURT,27630,HEUBECOURT HARICOURT,,49.1301336149,1.56459912242,331,,Heubécourt-Haricourt,Heubécourt-Haricourt,27,Eure,28,Normandie
27331,HEUBECOURT HARICOURT,27630,HEUBECOURT HARICOURT,HARICOURT,49.1301336149,1.56459912242,331,,Heubécourt-Haricourt,Heubécourt-Haricourt,27,Eure,28,Normandie
27331,HEUBECOURT HARICOURT,27630,HEUBECOURT HARICOURT,HEUBECOURT,49.1301336149,1.56459912242,331,,Heubécourt-Haricourt,Heubécourt-Haricourt,27,Eure,28,Normandie
27332,HEUDEBOUVILLE,27400,HEUDEBOUVILLE,,49.1927471606,1.23713234517,332,,Heudebouville,Heudebouville,27,Eure,28,Normandie
27332,HEUDEBOUVILLE,27600,HEUDEBOUVILLE,,49.1927471606,1.23713234517,332,,Heudebouville,Heudebouville,27,Eure,28,Normandie
27333,HEUDICOURT,27860,HEUDICOURT,,49.3343880911,1.66267351729,333,,Heudicourt,Heudicourt,27,Eure,28,Normandie
27334,HEUDREVILLE EN LIEUVIN,27230,HEUDREVILLE EN LIEUVIN,,49.1933109292,0.503312662769,334,,Heudreville-en-Lieuvin,Heudreville-en-Lieuvin,27,Eure,28,Normandie
27335,HEUDREVILLE SUR EURE,27400,HEUDREVILLE SUR EURE,,49.1320841365,1.18870983275,335,,Heudreville-sur-Eure,Heudreville-sur-Eure,27,Eure,28,Normandie
27336,LA HEUNIERE,27950,LA HEUNIERE,,49.0649738684,1.41307259092,336,La,Heunière,La Heunière,27,Eure,28,Normandie
27337,HEUQUEVILLE,27700,HEUQUEVILLE,,49.2872109169,1.32070670028,337,,Heuqueville,Heuqueville,27,Eure,28,Normandie
27338,LES HOGUES,27910,LES HOGUES,,49.4202530059,1.40791828171,338,Les,Hogues,Les Hogues,27,Eure,28,Normandie
27339,HONDOUVILLE,27400,HONDOUVILLE,,49.1448656783,1.11623535424,339,,Hondouville,Hondouville,27,Eure,28,Normandie
27340,HONGUEMARE GUENOUVILLE,27310,HONGUEMARE GUENOUVILLE,,49.3781547887,0.815883000955,340,,Honguemare-Guenouville,Honguemare-Guenouville,27,Eure,28,Normandie
27341,L HOSMES,27570,L HOSMES,,48.7896934052,1.03144650872,341,L',Hosmes,L'Hosmes,27,Eure,28,Normandie
27342,HOUETTEVILLE,27400,HOUETTEVILLE,,49.1293925185,1.09038074785,342,,Houetteville,Houetteville,27,Eure,28,Normandie
27343,HOULBEC COCHEREL,27120,HOULBEC COCHEREL,,49.0645423479,1.36125674229,343,,Houlbec-Cocherel,Houlbec-Cocherel,27,Eure,28,Normandie
27344,HOULBEC PRES LE GROS THEIL,27370,HOULBEC PRES LE GROS THEIL,,49.2511635296,0.826990094932,344,,Houlbec-près-le-Gros-Theil,Houlbec-près-le-Gros-Theil,27,Eure,28,Normandie
27345,LA HOUSSAYE,27410,LA HOUSSAYE,,48.9981469161,0.800464578361,345,La,Houssaye,La Houssaye,27,Eure,28,Normandie
27346,HOUVILLE EN VEXIN,27440,HOUVILLE EN VEXIN,,49.2999641721,1.35759066784,346,,Houville-en-Vexin,Houville-en-Vexin,27,Eure,28,Normandie
27347,HUEST,27930,HUEST,,49.0369994127,1.20781487064,347,,Huest,Huest,27,Eure,28,Normandie
27348,IGOVILLE,27460,IGOVILLE,,49.3227331737,1.15098333541,348,,Igoville,Igoville,27,Eure,28,Normandie
27349,ILLEVILLE SUR MONTFORT,27290,ILLEVILLE SUR MONTFORT,,49.3191305181,0.709396511927,349,,Illeville-sur-Montfort,Illeville-sur-Montfort,27,Eure,28,Normandie
27350,ILLIERS L EVEQUE,27770,ILLIERS L EVEQUE,,48.8249944319,1.25958790614,350,,Illiers-l'Évêque,Illiers-l'Évêque,27,Eure,28,Normandie
27351,INCARVILLE,27400,INCARVILLE,,49.2521511771,1.15231947532,351,,Incarville,Incarville,27,Eure,28,Normandie
27353,IRREVILLE,27930,IRREVILLE,,49.0984880529,1.19967557825,353,,Irreville,Irreville,27,Eure,28,Normandie
27354,IVILLE,27110,IVILLE,,49.17694671,0.926307699955,354,,Iville,Iville,27,Eure,28,Normandie
27355,IVRY LA BATAILLE,27540,IVRY LA BATAILLE,,48.886265363,1.44688887159,355,,Ivry-la-Bataille,Ivry-la-Bataille,27,Eure,28,Normandie
27358,JOUY SUR EURE,27120,JOUY SUR EURE,,49.0495174919,1.29182535231,358,,Jouy-sur-Eure,Jouy-sur-Eure,27,Eure,28,Normandie
27359,JUIGNETTES,27250,JUIGNETTES,,48.8472706788,0.64345379282,359,,Juignettes,Juignettes,27,Eure,28,Normandie
27360,JUMELLES,27220,JUMELLES,,48.9167408883,1.21193085577,360,,Jumelles,Jumelles,27,Eure,28,Normandie
27361,LA LANDE ST LEGER,27210,LA LANDE ST LEGER,,49.2976910975,0.336083900968,361,La,Lande-Saint-Léger,La Lande-Saint-Léger,27,Eure,28,Normandie
27361,LA LANDE ST LEGER,27210,LA LANDE ST LEGER,ST LEGER SUR BONNEVILLE,49.2976910975,0.336083900968,361,La,Lande-Saint-Léger,La Lande-Saint-Léger,27,Eure,28,Normandie
27363,LE LANDIN,27350,LE LANDIN,,49.4067670559,0.803595521754,363,Le,Landin,Le Landin,27,Eure,28,Normandie
27364,LAUNAY,27470,LAUNAY,,49.1080587131,0.737298458469,364,,Launay,Launay,27,Eure,28,Normandie
27365,LERY,27690,LERY,,49.2875848843,1.19524982012,365,,Léry,Léry,27,Eure,28,Normandie
27366,LETTEGUIVES,27910,LETTEGUIVES,,49.4216840077,1.32704878095,366,,Letteguives,Letteguives,27,Eure,28,Normandie
27367,LIEUREY,27560,LIEUREY,,49.2331857585,0.500797947975,367,,Lieurey,Lieurey,27,Eure,28,Normandie
27368,LIGNEROLLES,27220,LIGNEROLLES,,48.8447224599,1.27333667286,368,,Lignerolles,Lignerolles,27,Eure,28,Normandie
27369,LILLY,27480,LILLY,,49.4029373278,1.56677975295,369,,Lilly,Lilly,27,Eure,28,Normandie
27370,LISORS,27440,LISORS,,49.3579867554,1.4732613577,370,,Lisors,Lisors,27,Eure,28,Normandie
27371,LIVET SUR AUTHOU,27800,LIVET SUR AUTHOU,,49.2288510022,0.654737767065,371,,Livet-sur-Authou,Livet-sur-Authou,27,Eure,28,Normandie
27372,LONGCHAMPS,27150,LONGCHAMPS,,49.3579667199,1.62115051175,372,,Longchamps,Longchamps,27,Eure,28,Normandie
27373,LORLEAU,27480,LORLEAU,,49.4214416378,1.50486927146,373,,Lorleau,Lorleau,27,Eure,28,Normandie
27374,LOUVERSEY,27190,LOUVERSEY,,48.9910763969,0.916140545325,374,,Louversey,Louversey,27,Eure,28,Normandie
27375,LOUVIERS,27400,LOUVIERS,,49.2206099164,1.15340030158,375,,Louviers,Louviers,27,Eure,28,Normandie
27376,LOUYE,27650,LOUYE,,48.7969011449,1.31913602258,376,,Louye,Louye,27,Eure,28,Normandie
27377,LYONS LA FORET,27480,LYONS LA FORET,,49.3936970778,1.47355856778,377,,Lyons-la-Forêt,Lyons-la-Forêt,27,Eure,28,Normandie
27378,LA MADELEINE DE NONANCOURT,27320,LA MADELEINE DE NONANCOURT,,48.7901504026,1.21800401971,378,La,Madeleine-de-Nonancourt,La Madeleine-de-Nonancourt,27,Eure,28,Normandie
27379,MAINNEVILLE,27150,MAINNEVILLE,,49.3748963411,1.68713802474,379,,Mainneville,Mainneville,27,Eure,28,Normandie
27380,MALLEVILLE SUR LE BEC,27800,MALLEVILLE SUR LE BEC,,49.2418789785,0.745972299525,380,,Malleville-sur-le-Bec,Malleville-sur-le-Bec,27,Eure,28,Normandie
27381,MALOUY,27300,MALOUY,,49.1279630257,0.528705330405,381,,Malouy,Malouy,27,Eure,28,Normandie
27382,MANDEVILLE,27370,MANDEVILLE,,49.2154721135,1.01292163537,382,,Mandeville,Mandeville,27,Eure,28,Normandie
27383,MANDRES,27130,MANDRES,,48.752840487,0.85124788629,383,,Mandres,Mandres,27,Eure,28,Normandie
27384,MANNEVILLE LA RAOULT,27210,MANNEVILLE LA RAOULT,,49.3687031435,0.327624616931,384,,Manneville-la-Raoult,Manneville-la-Raoult,27,Eure,28,Normandie
27385,MANNEVILLE SUR RISLE,27500,MANNEVILLE SUR RISLE,,49.3610819827,0.545943626497,385,,Manneville-sur-Risle,Manneville-sur-Risle,27,Eure,28,Normandie
27386,LE MANOIR,27460,LE MANOIR,,49.317522421,1.19943581573,386,Le,Manoir,Le Manoir,27,Eure,28,Normandie
27388,MARAIS VERNIER,27680,MARAIS VERNIER,,49.4380276828,0.464318330946,388,,Marais-Vernier,Marais-Vernier,27,Eure,28,Normandie
27389,MARBEUF,27110,MARBEUF,,49.1542331428,0.962852626564,389,,Marbeuf,Marbeuf,27,Eure,28,Normandie
27390,MARCILLY LA CAMPAGNE,27320,MARCILLY LA CAMPAGNE,,48.8270540549,1.18747667453,390,,Marcilly-la-Campagne,Marcilly-la-Campagne,27,Eure,28,Normandie
27391,MARCILLY SUR EURE,27810,MARCILLY SUR EURE,,48.8213584613,1.33287296161,391,,Marcilly-sur-Eure,Marcilly-sur-Eure,27,Eure,28,Normandie
27392,MARTAGNY,27150,MARTAGNY,,49.3955328477,1.6577250335,392,,Martagny,Martagny,27,Eure,28,Normandie
27393,MARTAINVILLE,27210,MARTAINVILLE,,49.3027207068,0.386116710697,393,,Martainville,Martainville,27,Eure,28,Normandie
27394,MARTOT,27340,MARTOT,,49.2780639581,1.07107069967,394,,Martot,Martot,27,Eure,28,Normandie
27395,MELICOURT,27390,MELICOURT,,48.912160796,0.502460109315,395,,Mélicourt,Mélicourt,27,Eure,28,Normandie
27396,MENESQUEVILLE,27850,MENESQUEVILLE,,49.3574004646,1.40684415026,396,,Ménesqueville,Ménesqueville,27,Eure,28,Normandie
27397,MENILLES,27120,MENILLES,,49.0388433559,1.37049652274,397,,Ménilles,Ménilles,27,Eure,28,Normandie
27398,MENNEVAL,27300,MENNEVAL,,49.1018758441,0.622134982944,398,,Menneval,Menneval,27,Eure,28,Normandie
27399,MERCEY,27950,MERCEY,,49.0825576267,1.39866609762,399,,Mercey,Mercey,27,Eure,28,Normandie
27400,MEREY,27640,MEREY,,48.9586730122,1.39284165552,400,,Merey,Merey,27,Eure,28,Normandie
27401,LE MESNIL FUGUET,27930,LE MESNIL FUGUET,,49.0730545444,1.11399083502,401,Le,Mesnil-Fuguet,Le Mesnil-Fuguet,27,Eure,28,Normandie
27402,LE MESNIL HARDRAY,27190,LE MESNIL HARDRAY,,48.9344270816,0.987060764745,402,Le,Mesnil-Hardray,Le Mesnil-Hardray,27,Eure,28,Normandie
27403,LE MESNIL JOURDAIN,27400,LE MESNIL JOURDAIN,,49.1815334603,1.12763185285,403,Le,Mesnil-Jourdain,Le Mesnil-Jourdain,27,Eure,28,Normandie
27404,MESNIL ROUSSET,27390,MESNIL ROUSSET,,48.8934556889,0.557278907345,404,,Mesnil-Rousset,Mesnil-Rousset,27,Eure,28,Normandie
27405,MESNIL SOUS VIENNE,27150,MESNIL SOUS VIENNE,,49.3852334918,1.6675843732,405,,Mesnil-sous-Vienne,Mesnil-sous-Vienne,27,Eure,28,Normandie
27406,MESNIL SUR L ESTREE,27650,MESNIL SUR L ESTREE,,48.7767004178,1.29009142642,406,,Mesnil-sur-l'Estrée,Mesnil-sur-l'Estrée,27,Eure,28,Normandie
27407,MESNIL VERCLIVES,27440,MESNIL VERCLIVES,,49.3227519896,1.46543126827,407,,Mesnil-Verclives,Mesnil-Verclives,27,Eure,28,Normandie
27408,MEZIERES EN VEXIN,27510,MEZIERES EN VEXIN,,49.1758883521,1.50318565777,408,,Mézières-en-Vexin,Mézières-en-Vexin,27,Eure,28,Normandie
27410,MISEREY,27930,MISEREY,,49.0197062779,1.26108481938,410,,Miserey,Miserey,27,Eure,28,Normandie
27411,MOISVILLE,27320,MOISVILLE,,48.8482003064,1.16604585189,411,,Moisville,Moisville,27,Eure,28,Normandie
27412,MONTAURE,27400,MONTAURE,,49.2297208339,1.09813170606,412,,Terres de Bord,Terres de Bord,27,Eure,28,Normandie
27413,MONTFORT SUR RISLE,27290,MONTFORT SUR RISLE,,49.2945278867,0.670560456278,413,,Montfort-sur-Risle,Montfort-sur-Risle,27,Eure,28,Normandie
27414,MONTREUIL L ARGILLE,27390,MONTREUIL L ARGILLE,,48.9396490466,0.468484360386,414,,Montreuil-l'Argillé,Montreuil-l'Argillé,27,Eure,28,Normandie
27414,MONTREUIL L ARGILLE,27390,MONTREUIL L ARGILLE,ST AQUILIN D AUGERONS,48.9396490466,0.468484360386,414,,Montreuil-l'Argillé,Montreuil-l'Argillé,27,Eure,28,Normandie
27415,MORAINVILLE JOUVEAUX,27260,MORAINVILLE JOUVEAUX,,49.2227027964,0.443976742663,415,,Morainville-Jouveaux,Morainville-Jouveaux,27,Eure,28,Normandie
27415,MORAINVILLE JOUVEAUX,27260,MORAINVILLE JOUVEAUX,JOUVEAUX,49.2227027964,0.443976742663,415,,Morainville-Jouveaux,Morainville-Jouveaux,27,Eure,28,Normandie
27416,BUIS SUR DAMVILLE,27240,BUIS SUR DAMVILLE,,48.8427924354,1.12161929005,416,,Buis-sur-Damville,Buis-sur-Damville,27,Eure,28,Normandie
27416,BUIS SUR DAMVILLE,27240,BUIS SUR DAMVILLE,BOISSY SUR DAMVILLE,48.8427924354,1.12161929005,416,,Buis-sur-Damville,Buis-sur-Damville,27,Eure,28,Normandie
27416,BUIS SUR DAMVILLE,27240,BUIS SUR DAMVILLE,CRETON,48.8427924354,1.12161929005,416,,Buis-sur-Damville,Buis-sur-Damville,27,Eure,28,Normandie
27417,MORGNY,27150,MORGNY,,49.377611169,1.57826158903,417,,Morgny,Morgny,27,Eure,28,Normandie
27418,MORSAN,27800,MORSAN,,49.1796481748,0.597511424785,418,,Morsan,Morsan,27,Eure,28,Normandie
27419,MOUETTES,27220,MOUETTES,,48.8886476337,1.36834248344,419,,Mouettes,Mouettes,27,Eure,28,Normandie
27420,MOUFLAINES,27420,MOUFLAINES,,49.2445398768,1.55531520317,420,,Mouflaines,Mouflaines,27,Eure,28,Normandie
27421,MOUSSEAUX NEUVILLE,27220,MOUSSEAUX NEUVILLE,,48.90687343,1.33849084974,421,,Mousseaux-Neuville,Mousseaux-Neuville,27,Eure,28,Normandie
27422,MUIDS,27430,MUIDS,,49.2265789248,1.28569054904,422,,Muids,Muids,27,Eure,28,Normandie
27423,MUZY,27650,MUZY,,48.7781253842,1.32569756656,423,,Muzy,Muzy,27,Eure,28,Normandie
27424,NAGEL SEEZ MESNIL,27190,NAGEL SEEZ MESNIL,,48.9247473098,0.940596231036,424,,Nagel-Séez-Mesnil,Nagel-Séez-Mesnil,27,Eure,28,Normandie
27425,NASSANDRES,27550,NASSANDRES,,49.1339917413,0.739306485861,425,,Nassandres sur Risle,Nassandres sur Risle,27,Eure,28,Normandie
27426,NEAUFLES ST MARTIN,27830,NEAUFLES ST MARTIN,,49.277330375,1.72635123012,426,,Neaufles-Saint-Martin,Neaufles-Saint-Martin,27,Eure,28,Normandie
27427,NEAUFLES AUVERGNY,27250,NEAUFLES AUVERGNY,,48.8743528336,0.74447514343,427,,Neaufles-Auvergny,Neaufles-Auvergny,27,Eure,28,Normandie
27427,NEAUFLES AUVERGNY,27250,NEAUFLES AUVERGNY,AUVERGNY,48.8743528336,0.74447514343,427,,Neaufles-Auvergny,Neaufles-Auvergny,27,Eure,28,Normandie
27427,NEAUFLES AUVERGNY,27250,NEAUFLES AUVERGNY,NEAUFLES SUR RISLE,48.8743528336,0.74447514343,427,,Neaufles-Auvergny,Neaufles-Auvergny,27,Eure,28,Normandie
27428,LE NEUBOURG,27110,LE NEUBOURG,,49.1482790328,0.897176574238,428,Le,Neubourg,Le Neubourg,27,Eure,28,Normandie
27429,NEUILLY,27730,NEUILLY,,48.9295320189,1.41731024629,429,,Neuilly,Neuilly,27,Eure,28,Normandie
27430,LA NEUVE GRANGE,27150,LA NEUVE GRANGE,,49.3599472523,1.5584583469,430,La,Neuve-Grange,La Neuve-Grange,27,Eure,28,Normandie
27431,LA NEUVE LYRE,27330,LA NEUVE LYRE,,48.9023268219,0.736464562108,431,La,Neuve-Lyre,La Neuve-Lyre,27,Eure,28,Normandie
27432,LA NEUVILLE DU BOSC,27890,LA NEUVILLE DU BOSC,,49.1943072566,0.815544482419,432,La,Neuville-du-Bosc,La Neuville-du-Bosc,27,Eure,28,Normandie
27433,NEUVILLE SUR AUTHOU,27800,NEUVILLE SUR AUTHOU,,49.204972332,0.628757401036,433,,Neuville-sur-Authou,Neuville-sur-Authou,27,Eure,28,Normandie
27434,NOARDS,27560,NOARDS,,49.2105065692,0.509712795627,434,,Noards,Noards,27,Eure,28,Normandie
27435,LA NOE POULAIN,27560,LA NOE POULAIN,,49.2706730509,0.519567816517,435,La,Noë-Poulain,La Noë-Poulain,27,Eure,28,Normandie
27436,NOGENT LE SEC,27190,NOGENT LE SEC,,48.9205199926,1.00453898439,436,,Nogent-le-Sec,Nogent-le-Sec,27,Eure,28,Normandie
27437,NOJEON EN VEXIN,27150,NOJEON EN VEXIN,,49.3291573194,1.55624296003,437,,Nojeon-en-Vexin,Nojeon-en-Vexin,27,Eure,28,Normandie
27438,NONANCOURT,27320,NONANCOURT,,48.7770036234,1.17931073126,438,,Nonancourt,Nonancourt,27,Eure,28,Normandie
27439,NORMANVILLE,27930,NORMANVILLE,,49.0725184712,1.16198827821,439,,Normanville,Normanville,27,Eure,28,Normandie
27440,NOTRE DAME DE L ISLE,27940,NOTRE DAME DE L ISLE,,49.1559698641,1.44753514433,440,,Notre-Dame-de-l'Isle,Notre-Dame-de-l'Isle,27,Eure,28,Normandie
27441,NOTRE DAME D EPINE,27800,NOTRE DAME D EPINE,,49.1946525177,0.597493274128,441,,Notre-Dame-d'Épine,Notre-Dame-d'Épine,27,Eure,28,Normandie
27442,NOTRE DAME DU HAMEL,27390,NOTRE DAME DU HAMEL,,48.8923200836,0.509886353695,442,,Notre-Dame-du-Hamel,Notre-Dame-du-Hamel,27,Eure,28,Normandie
27444,LE NOYER EN OUCHE,27410,LE NOYER EN OUCHE,,49.0060440557,0.761580792819,444,Le,Noyer-en-Ouche,Le Noyer-en-Ouche,27,Eure,28,Normandie
27445,NOYERS,27720,NOYERS,,49.2458221928,1.67352293727,445,,Noyers,Noyers,27,Eure,28,Normandie
27446,ORMES,27190,ORMES,,49.0563549136,0.956012588677,446,,Ormes,Ormes,27,Eure,28,Normandie
27447,ORVAUX,27190,ORVAUX,,48.9412455696,1.02129449463,447,Le,Val-Doré,Le Val-Doré,27,Eure,28,Normandie
27448,PACY SUR EURE,27120,PACY SUR EURE,,49.0221961311,1.39950768038,448,,Pacy-sur-Eure,Pacy-sur-Eure,27,Eure,28,Normandie
27451,PARVILLE,27180,PARVILLE,,49.031891922,1.09222136846,451,,Parville,Parville,27,Eure,28,Normandie
27452,PERRIERS LA CAMPAGNE,27170,PERRIERS LA CAMPAGNE,,49.1463092751,0.758180512994,452,,Perriers-la-Campagne,Perriers-la-Campagne,27,Eure,28,Normandie
27453,PERRIERS SUR ANDELLE,27910,PERRIERS SUR ANDELLE,,49.4084277874,1.36331833867,453,,Perriers-sur-Andelle,Perriers-sur-Andelle,27,Eure,28,Normandie
27454,PERRUEL,27910,PERRUEL,,49.4322323114,1.36153418506,454,,Perruel,Perruel,27,Eure,28,Normandie
27455,PIENCOURT,27230,PIENCOURT,,49.1715184928,0.407698895643,455,,Piencourt,Piencourt,27,Eure,28,Normandie
27456,PINTERVILLE,27400,PINTERVILLE,,49.1918740939,1.19163426958,456,,Pinterville,Pinterville,27,Eure,28,Normandie
27457,PISEUX,27130,PISEUX,,48.772122079,0.965747838797,457,,Piseux,Piseux,27,Eure,28,Normandie
27458,PITRES,27590,PITRES,,49.3311910772,1.21797145454,458,,Pîtres,Pîtres,27,Eure,28,Normandie
27459,LES PLACES,27230,LES PLACES,,49.1531446643,0.41691005358,459,Les,Places,Les Places,27,Eure,28,Normandie
27460,PLAINVILLE,27300,PLAINVILLE,,49.0789480792,0.499627134702,460,,Plainville,Plainville,27,Eure,28,Normandie
27462,LE PLANQUAY,27230,LE PLANQUAY,,49.0943788554,0.426951723046,462,Le,Planquay,Le Planquay,27,Eure,28,Normandie
27463,PLASNES,27300,PLASNES,,49.1376315129,0.624753060628,463,,Plasnes,Plasnes,27,Eure,28,Normandie
27464,LE PLESSIS GROHAN,27180,LE PLESSIS GROHAN,,48.9443249327,1.1332422549,464,Le,Plessis-Grohan,Le Plessis-Grohan,27,Eure,28,Normandie
27465,LE PLESSIS HEBERT,27120,LE PLESSIS HEBERT,,48.9816522888,1.36285630147,465,Le,Plessis-Hébert,Le Plessis-Hébert,27,Eure,28,Normandie
27466,LE PLESSIS STE OPPORTUNE,27170,LE PLESSIS STE OPPORTUNE,,49.0701628802,0.85595391396,466,Le,Plessis-Sainte-Opportune,Le Plessis-Sainte-Opportune,27,Eure,28,Normandie
27467,PONT AUDEMER,27500,PONT AUDEMER,,49.3463869637,0.533874716445,467,,Pont-Audemer,Pont-Audemer,27,Eure,28,Normandie
27467,PONT AUDEMER,27500,PONT AUDEMER,ST PAUL SUR RISLE,49.3463869637,0.533874716445,467,,Pont-Audemer,Pont-Audemer,27,Eure,28,Normandie
27468,PONT AUTHOU,27290,PONT AUTHOU,,49.2423269123,0.702222226343,468,,Pont-Authou,Pont-Authou,27,Eure,28,Normandie
27469,PONT DE L ARCHE,27340,PONT DE L ARCHE,,49.2909189807,1.14424790108,469,,Pont-de-l'Arche,Pont-de-l'Arche,27,Eure,28,Normandie
27470,PONT ST PIERRE,27360,PONT ST PIERRE,,49.3427460262,1.28756480304,470,,Pont-Saint-Pierre,Pont-Saint-Pierre,27,Eure,28,Normandie
27471,PORTE JOIE,27430,PORTE JOIE,,49.2524086765,1.25122427132,471,,Porte-de-Seine,Porte-de-Seine,27,Eure,28,Normandie
27472,PORTES,27190,PORTES,,49.0251871299,0.948263813504,472,,Portes,Portes,27,Eure,28,Normandie
27473,PORT MORT,27940,PORT MORT,,49.1735749166,1.4105023213,473,,Port-Mort,Port-Mort,27,Eure,28,Normandie
27474,POSES,27740,POSES,,49.299571684,1.24071359731,474,,Poses,Poses,27,Eure,28,Normandie
27475,LA POTERIE MATHIEU,27560,LA POTERIE MATHIEU,,49.2525189802,0.522008481634,475,La,Poterie-Mathieu,La Poterie-Mathieu,27,Eure,28,Normandie
27476,LES PREAUX,27500,LES PREAUX,,49.3281307997,0.480531070543,476,Les,Préaux,Les Préaux,27,Eure,28,Normandie
27477,PRESSAGNY L ORGUEILLEUX,27510,PRESSAGNY L ORGUEILLEUX,,49.1308034237,1.46515135716,477,,Pressagny-l'Orgueilleux,Pressagny-l'Orgueilleux,27,Eure,28,Normandie
27478,PREY,27220,PREY,,48.9578582818,1.21917165001,478,,Prey,Prey,27,Eure,28,Normandie
27480,PUCHAY,27150,PUCHAY,,49.3540348,1.52612367067,480,,Puchay,Puchay,27,Eure,28,Normandie
27481,PULLAY,27130,PULLAY,,48.727695545,0.864069956274,481,,Pullay,Pullay,27,Eure,28,Normandie
27482,LA PYLE,27370,LA PYLE,,49.199866993,0.897158347508,482,La,Pyle,La Pyle,27,Eure,28,Normandie
27483,QUATREMARE,27400,QUATREMARE,,49.1841622689,1.08114735197,483,,Quatremare,Quatremare,27,Eure,28,Normandie
27485,QUILLEBEUF SUR SEINE,27680,QUILLEBEUF SUR SEINE,,49.4700117305,0.501728586509,485,,Quillebeuf-sur-Seine,Quillebeuf-sur-Seine,27,Eure,28,Normandie
27486,QUITTEBEUF,27110,QUITTEBEUF,,49.1073075931,1.00532238081,486,,Quittebeuf,Quittebeuf,27,Eure,28,Normandie
27487,RADEPONT,27380,RADEPONT,,49.3531161459,1.32420205477,487,,Radepont,Radepont,27,Eure,28,Normandie
27488,RENNEVILLE,27910,RENNEVILLE,,49.3960826717,1.32607128725,488,,Renneville,Renneville,27,Eure,28,Normandie
27489,REUILLY,27930,REUILLY,,49.0728383808,1.22648323234,489,,Reuilly,Reuilly,27,Eure,28,Normandie
27490,RICHEVILLE,27420,RICHEVILLE,,49.2579692139,1.53784793468,490,,Richeville,Richeville,27,Eure,28,Normandie
27491,ROMAN,27240,ROMAN,,48.8362989646,1.04581828979,491,,Roman,Roman,27,Eure,28,Normandie
27492,ROMILLY LA PUTHENAYE,27170,ROMILLY LA PUTHENAYE,,49.0132474819,0.837767280682,492,,Romilly-la-Puthenaye,Romilly-la-Puthenaye,27,Eure,28,Normandie
27493,ROMILLY SUR ANDELLE,27610,ROMILLY SUR ANDELLE,,49.3343917409,1.25410011216,493,,Romilly-sur-Andelle,Romilly-sur-Andelle,27,Eure,28,Normandie
27495,LA ROQUETTE,27700,LA ROQUETTE,,49.2593238784,1.34339141954,495,La,Roquette,La Roquette,27,Eure,28,Normandie
27496,ROSAY SUR LIEURE,27790,ROSAY SUR LIEURE,,49.3746388684,1.42794431764,496,,Rosay-sur-Lieure,Rosay-sur-Lieure,27,Eure,28,Normandie
27497,ROUGEMONTIERS,27350,ROUGEMONTIERS,,49.3579322426,0.724785098446,497,,Rougemontiers,Rougemontiers,27,Eure,28,Normandie
27498,ROUGE PERRIERS,27110,ROUGE PERRIERS,,49.1460940587,0.833122827372,498,,Rouge-Perriers,Rouge-Perriers,27,Eure,28,Normandie
27500,ROUTOT,27350,ROUTOT,,49.3851066473,0.726281563291,500,,Routot,Routot,27,Eure,28,Normandie
27501,ROUVRAY,27120,ROUVRAY,,49.0668656108,1.33964798484,501,,Rouvray,Rouvray,27,Eure,28,Normandie
27502,RUGLES,27250,RUGLES,,48.8157669223,0.691368655422,502,,Rugles,Rugles,27,Eure,28,Normandie
27504,SACQUENVILLE,27930,SACQUENVILLE,,49.081232023,1.07443562823,504,,Sacquenville,Sacquenville,27,Eure,28,Normandie
27505,ST AGNAN DE CERNIERES,27390,ST AGNAN DE CERNIERES,,48.9501046061,0.531514828879,505,,Saint-Agnan-de-Cernières,Saint-Agnan-de-Cernières,27,Eure,28,Normandie
27507,ST ANDRE DE L EURE,27220,ST ANDRE DE L EURE,,48.9026554238,1.27831847187,507,,Saint-André-de-l'Eure,Saint-André-de-l'Eure,27,Eure,28,Normandie
27508,ST ANTONIN DE SOMMAIRE,27250,ST ANTONIN DE SOMMAIRE,,48.8295332796,0.662693828241,508,,Saint-Antonin-de-Sommaire,Saint-Antonin-de-Sommaire,27,Eure,28,Normandie
27510,ST AQUILIN DE PACY,27120,ST AQUILIN DE PACY,,49.009709894,1.3508836256,510,,Saint-Aquilin-de-Pacy,Saint-Aquilin-de-Pacy,27,Eure,28,Normandie
27511,ST AUBIN D ECROSVILLE,27110,ST AUBIN D ECROSVILLE,,49.1431219204,0.986919369779,511,,Saint-Aubin-d'Écrosville,Saint-Aubin-d'Écrosville,27,Eure,28,Normandie
27512,ST AUBIN DE SCELLON,27230,ST AUBIN DE SCELLON,,49.1770428434,0.47331713113,512,,Saint-Aubin-de-Scellon,Saint-Aubin-de-Scellon,27,Eure,28,Normandie
27514,ST AUBIN DU THENNEY,27270,ST AUBIN DU THENNEY,,49.0152361084,0.494315401677,514,,Saint-Aubin-du-Thenney,Saint-Aubin-du-Thenney,27,Eure,28,Normandie
27516,ST AUBIN LE VERTUEUX,27300,ST AUBIN LE VERTUEUX,,49.0593510154,0.611660740223,516,,Saint-Aubin-le-Vertueux,Saint-Aubin-le-Vertueux,27,Eure,28,Normandie
27517,ST AUBIN SUR GAILLON,27600,ST AUBIN SUR GAILLON,,49.132834827,1.33624192382,517,,Saint-Aubin-sur-Gaillon,Saint-Aubin-sur-Gaillon,27,Eure,28,Normandie
27518,ST AUBIN SUR QUILLEBEUF,27680,ST AUBIN SUR QUILLEBEUF,,49.4493583444,0.52491272728,518,,Saint-Aubin-sur-Quillebeuf,Saint-Aubin-sur-Quillebeuf,27,Eure,28,Normandie
27520,ST BENOIT DES OMBRES,27450,ST BENOIT DES OMBRES,,49.2265023608,0.618333897431,520,,Saint-Benoît-des-Ombres,Saint-Benoît-des-Ombres,27,Eure,28,Normandie
27521,ST CHRISTOPHE SUR AVRE,27820,ST CHRISTOPHE SUR AVRE,,48.6988286398,0.805102409427,521,,Saint-Christophe-sur-Avre,Saint-Christophe-sur-Avre,27,Eure,28,Normandie
27522,ST CHRISTOPHE SUR CONDE,27450,ST CHRISTOPHE SUR CONDE,,49.2853748479,0.600693146112,522,,Saint-Christophe-sur-Condé,Saint-Christophe-sur-Condé,27,Eure,28,Normandie
27523,ST CLAIR D ARCEY,27300,ST CLAIR D ARCEY,,49.0637803383,0.662870377669,523,,Saint-Clair-d'Arcey,Saint-Clair-d'Arcey,27,Eure,28,Normandie
27524,STE COLOMBE LA COMMANDERIE,27110,STE COLOMBE LA COMMANDERIE,,49.1073978726,0.935604057225,524,,Sainte-Colombe-la-Commanderie,Sainte-Colombe-la-Commanderie,27,Eure,28,Normandie
27525,STE COLOMBE PRES VERNON,27950,STE COLOMBE PRES VERNON,,49.094937175,1.34106749304,525,,Sainte-Colombe-près-Vernon,Sainte-Colombe-près-Vernon,27,Eure,28,Normandie
27527,ST CYR DE SALERNE,27800,ST CYR DE SALERNE,,49.1855526702,0.65343557534,527,,Saint-Cyr-de-Salerne,Saint-Cyr-de-Salerne,27,Eure,28,Normandie
27528,LE VAUDREUIL,27100,LE VAUDREUIL,,49.2609960336,1.19894900143,528,Le,Vaudreuil,Le Vaudreuil,27,Eure,28,Normandie
27529,ST CYR LA CAMPAGNE,27370,ST CYR LA CAMPAGNE,,49.2504333852,1.01944802807,529,,Saint-Cyr-la-Campagne,Saint-Cyr-la-Campagne,27,Eure,28,Normandie
27530,ST DENIS D AUGERONS,27390,ST DENIS D AUGERONS,,48.9149003102,0.471485013305,530,,Saint-Denis-d'Augerons,Saint-Denis-d'Augerons,27,Eure,28,Normandie
27531,ST DENIS DES MONTS,27520,ST DENIS DES MONTS,,49.256053123,0.806583378737,531,,Saint-Denis-des-Monts,Saint-Denis-des-Monts,27,Eure,28,Normandie
27533,ST DENIS LE FERMENT,27140,ST DENIS LE FERMENT,,49.3218230563,1.71779088308,533,,Saint-Denis-le-Ferment,Saint-Denis-le-Ferment,27,Eure,28,Normandie
27533,ST DENIS LE FERMENT,27140,ST DENIS LE FERMENT,ST PAER,49.3218230563,1.71779088308,533,,Saint-Denis-le-Ferment,Saint-Denis-le-Ferment,27,Eure,28,Normandie
27534,ST DIDIER DES BOIS,27370,ST DIDIER DES BOIS,,49.2421475882,1.03799697166,534,,Saint-Didier-des-Bois,Saint-Didier-des-Bois,27,Eure,28,Normandie
27535,ST ELIER,27190,ST ELIER,,48.9837315688,0.961361003567,535,,Saint-Élier,Saint-Élier,27,Eure,28,Normandie
27536,ST ELOI DE FOURQUES,27800,ST ELOI DE FOURQUES,,49.2356732201,0.802775022457,536,,Saint-Éloi-de-Fourques,Saint-Éloi-de-Fourques,27,Eure,28,Normandie
27537,ST ETIENNE DU VAUVRAY,27430,ST ETIENNE DU VAUVRAY,,49.2422112638,1.21540763977,537,,Saint-Étienne-du-Vauvray,Saint-Étienne-du-Vauvray,27,Eure,28,Normandie
27538,ST ETIENNE L ALLIER,27450,ST ETIENNE L ALLIER,,49.266311791,0.557474976869,538,,Saint-Étienne-l'Allier,Saint-Étienne-l'Allier,27,Eure,28,Normandie
27539,ST ETIENNE SOUS BAILLEUL,27920,ST ETIENNE SOUS BAILLEUL,,49.1157573494,1.40530942134,539,,Saint-Étienne-sous-Bailleul,Saint-Étienne-sous-Bailleul,27,Eure,28,Normandie
27540,STE GENEVIEVE LES GASNY,27620,STE GENEVIEVE LES GASNY,,49.0822659054,1.57046260822,540,,Sainte-Geneviève-lès-Gasny,Sainte-Geneviève-lès-Gasny,27,Eure,28,Normandie
27541,ST GEORGES DU MESNIL,27560,ST GEORGES DU MESNIL,,49.210758804,0.56657576286,541,,Saint-Georges-du-Mesnil,Saint-Georges-du-Mesnil,27,Eure,28,Normandie
27542,ST GEORGES DU VIEVRE,27450,ST GEORGES DU VIEVRE,,49.2421438594,0.579765029769,542,,Saint-Georges-du-Vièvre,Saint-Georges-du-Vièvre,27,Eure,28,Normandie
27543,ST GEORGES MOTEL,27710,ST GEORGES MOTEL,,48.7922570884,1.35958388782,543,,Saint-Georges-Motel,Saint-Georges-Motel,27,Eure,28,Normandie
27544,ST GERMAIN DE FRESNEY,27220,ST GERMAIN DE FRESNEY,,48.9585228548,1.28735678198,544,,Saint-Germain-de-Fresney,Saint-Germain-de-Fresney,27,Eure,28,Normandie
27545,ST GERMAIN DE PASQUIER,27370,ST GERMAIN DE PASQUIER,,49.2419000465,1.00241263346,545,,Saint-Germain-de-Pasquier,Saint-Germain-de-Pasquier,27,Eure,28,Normandie
27546,ST GERMAIN DES ANGLES,27930,ST GERMAIN DES ANGLES,,49.0884520129,1.13771355337,546,,Saint-Germain-des-Angles,Saint-Germain-des-Angles,27,Eure,28,Normandie
27547,ST GERMAIN LA CAMPAGNE,27230,ST GERMAIN LA CAMPAGNE,,49.0522921876,0.412852228157,547,,Saint-Germain-la-Campagne,Saint-Germain-la-Campagne,27,Eure,28,Normandie
27548,ST GERMAIN SUR AVRE,27320,ST GERMAIN SUR AVRE,,48.7727688563,1.26277953417,548,,Saint-Germain-sur-Avre,Saint-Germain-sur-Avre,27,Eure,28,Normandie
27549,ST GERMAIN VILLAGE,27500,ST GERMAIN VILLAGE,,49.3448117228,0.498708877957,549,,Saint-Germain-Village,Saint-Germain-Village,27,Eure,28,Normandie
27550,ST GREGOIRE DU VIEVRE,27450,ST GREGOIRE DU VIEVRE,,49.2451494234,0.625953044218,550,,Saint-Grégoire-du-Vièvre,Saint-Grégoire-du-Vièvre,27,Eure,28,Normandie
27551,ST JEAN DE LA LEQUERAYE,27560,ST JEAN DE LA LEQUERAYE,,49.2251680221,0.560341025425,551,,Saint-Jean-de-la-Léqueraye,Saint-Jean-de-la-Léqueraye,27,Eure,28,Normandie
27552,ST JEAN DU THENNEY,27270,ST JEAN DU THENNEY,,49.0154059263,0.458622529157,552,,Saint-Jean-du-Thenney,Saint-Jean-du-Thenney,27,Eure,28,Normandie
27553,ST JULIEN DE LA LIEGUE,27600,ST JULIEN DE LA LIEGUE,,49.1401487215,1.28700576113,553,,Saint-Julien-de-la-Liègue,Saint-Julien-de-la-Liègue,27,Eure,28,Normandie
27554,ST JUST,27950,ST JUST,,49.1061858744,1.4332609397,554,La,Chapelle-Longueville,La Chapelle-Longueville,27,Eure,28,Normandie
27555,ST LAURENT DES BOIS,27220,ST LAURENT DES BOIS,,48.8459827261,1.319178626,555,,Saint-Laurent-des-Bois,Saint-Laurent-des-Bois,27,Eure,28,Normandie
27556,ST LAURENT DU TENCEMENT,27390,ST LAURENT DU TENCEMENT,,48.8917851204,0.46057263416,556,,Saint-Laurent-du-Tencement,Saint-Laurent-du-Tencement,27,Eure,28,Normandie
27557,ST LEGER DE ROTES,27300,ST LEGER DE ROTES,,49.1126606736,0.662290539561,557,,Saint-Léger-de-Rôtes,Saint-Léger-de-Rôtes,27,Eure,28,Normandie
27558,ST LEGER DU GENNETEY,27520,ST LEGER DU GENNETEY,,49.2826233384,0.752219384803,558,,Saint-Léger-du-Gennetey,Saint-Léger-du-Gennetey,27,Eure,28,Normandie
27560,ST LUC,27930,ST LUC,,48.9736657666,1.23335452626,560,,Saint-Luc,Saint-Luc,27,Eure,28,Normandie
27561,ST MACLOU,27210,ST MACLOU,,49.36198979,0.413624402884,561,,Saint-Maclou,Saint-Maclou,27,Eure,28,Normandie
27562,ST MARCEL,27950,ST MARCEL,,49.0915999954,1.43925560775,562,,Saint-Marcel,Saint-Marcel,27,Eure,28,Normandie
27563,ST MARDS DE BLACARVILLE,27500,ST MARDS DE BLACARVILLE,,49.376852376,0.508559393935,563,,Saint-Mards-de-Blacarville,Saint-Mards-de-Blacarville,27,Eure,28,Normandie
27564,ST MARDS DE FRESNE,27230,ST MARDS DE FRESNE,,49.0805940442,0.454584726238,564,,Saint-Mards-de-Fresne,Saint-Mards-de-Fresne,27,Eure,28,Normandie
27565,LE LESME,27160,LE LESME,GUERNANVILLE,48.911287302,0.845987853541,565,Le,Lesme,Le Lesme,27,Eure,28,Normandie
27565,LE LESME,27160,LE LESME,STE MARGUERITE DE L AUTEL,48.911287302,0.845987853541,565,Le,Lesme,Le Lesme,27,Eure,28,Normandie
27567,STE MARIE DE VATIMESNIL,27150,STE MARIE DE VATIMESNIL,,49.2721801587,1.58233388295,567,,Sainte-Marie-de-Vatimesnil,Sainte-Marie-de-Vatimesnil,27,Eure,28,Normandie
27568,STE MARTHE,27190,STE MARTHE,,48.957887614,0.881178066443,568,,Sainte-Marthe,Sainte-Marthe,27,Eure,28,Normandie
27569,ST MARTIN DU TILLEUL,27300,ST MARTIN DU TILLEUL,,49.1062640143,0.526574332783,569,,Saint-Martin-du-Tilleul,Saint-Martin-du-Tilleul,27,Eure,28,Normandie
27570,ST MARTIN LA CAMPAGNE,27930,ST MARTIN LA CAMPAGNE,,49.0651299646,1.07718638182,570,,Saint-Martin-la-Campagne,Saint-Martin-la-Campagne,27,Eure,28,Normandie
27571,ST MARTIN ST FIRMIN,27450,ST MARTIN ST FIRMIN,,49.2935102103,0.559679927993,571,,Saint-Martin-Saint-Firmin,Saint-Martin-Saint-Firmin,27,Eure,28,Normandie
27572,ST MESLIN DU BOSC,27370,ST MESLIN DU BOSC,,49.2158652715,0.878606617749,572,,Saint-Meslin-du-Bosc,Saint-Meslin-du-Bosc,27,Eure,28,Normandie
27576,STE OPPORTUNE DU BOSC,27110,STE OPPORTUNE DU BOSC,,49.1679377889,0.840374168625,576,,Sainte-Opportune-du-Bosc,Sainte-Opportune-du-Bosc,27,Eure,28,Normandie
27577,STE OPPORTUNE LA MARE,27680,STE OPPORTUNE LA MARE,,49.4248481767,0.533630300174,577,,Sainte-Opportune-la-Mare,Sainte-Opportune-la-Mare,27,Eure,28,Normandie
27578,STE MARIE D ATTEZ,27160,STE MARIE D ATTEZ,DAME MARIE,48.7957619883,0.943848248614,578,,Sainte-Marie-d'Attez,Sainte-Marie-d'Attez,27,Eure,28,Normandie
27578,STE MARIE D ATTEZ,27160,STE MARIE D ATTEZ,ST NICOLAS D ATTEZ,48.7957619883,0.943848248614,578,,Sainte-Marie-d'Attez,Sainte-Marie-d'Attez,27,Eure,28,Normandie
27578,STE MARIE D ATTEZ,27160,STE MARIE D ATTEZ,ST OUEN D ATTEZ,48.7957619883,0.943848248614,578,,Sainte-Marie-d'Attez,Sainte-Marie-d'Attez,27,Eure,28,Normandie
27579,ST OUEN DE PONTCHEUIL,27370,ST OUEN DE PONTCHEUIL,,49.2347786793,0.950431640532,579,,Saint-Ouen-de-Pontcheuil,Saint-Ouen-de-Pontcheuil,27,Eure,28,Normandie
27580,ST OUEN DE THOUBERVILLE,27310,ST OUEN DE THOUBERVILLE,,49.3490825032,0.895681824857,580,,Saint-Ouen-de-Thouberville,Saint-Ouen-de-Thouberville,27,Eure,28,Normandie
27581,ST OUEN DES CHAMPS,27680,ST OUEN DES CHAMPS,,49.3989281855,0.528625217933,581,,Saint-Ouen-des-Champs,Saint-Ouen-des-Champs,27,Eure,28,Normandie
27582,ST OUEN DU TILLEUL,27670,ST OUEN DU TILLEUL,,49.3006192126,0.944908698044,582,,Saint-Ouen-du-Tilleul,Saint-Ouen-du-Tilleul,27,Eure,28,Normandie
27584,ST PAUL DE FOURQUES,27800,ST PAUL DE FOURQUES,,49.2179420109,0.794815809765,584,,Saint-Paul-de-Fourques,Saint-Paul-de-Fourques,27,Eure,28,Normandie
27586,ST PHILBERT SUR BOISSEY,27520,ST PHILBERT SUR BOISSEY,,49.2577068576,0.784330470452,586,,Saint-Philbert-sur-Boissey,Saint-Philbert-sur-Boissey,27,Eure,28,Normandie
27587,ST PHILBERT SUR RISLE,27290,ST PHILBERT SUR RISLE,,49.2773818224,0.647386865123,587,,Saint-Philbert-sur-Risle,Saint-Philbert-sur-Risle,27,Eure,28,Normandie
27588,ST PIERRE D AUTILS,27950,ST PIERRE D AUTILS,,49.1247226398,1.42757883958,588,,Saint-Pierre-d'Autils,Saint-Pierre-d'Autils,27,Eure,28,Normandie
27589,ST PIERRE DE BAILLEUL,27920,ST PIERRE DE BAILLEUL,,49.1263328488,1.38351691696,589,,Saint-Pierre-de-Bailleul,Saint-Pierre-de-Bailleul,27,Eure,28,Normandie
27590,ST PIERRE DE CERNIERES,27390,ST PIERRE DE CERNIERES,,48.9309306433,0.519530805974,590,,Saint-Pierre-de-Cernières,Saint-Pierre-de-Cernières,27,Eure,28,Normandie
27591,ST PIERRE DE CORMEILLES,27260,ST PIERRE DE CORMEILLES,,49.2383462213,0.365250085842,591,,Saint-Pierre-de-Cormeilles,Saint-Pierre-de-Cormeilles,27,Eure,28,Normandie
27592,ST PIERRE DE SALERNE,27800,ST PIERRE DE SALERNE,,49.2053766905,0.667415097582,592,,Saint-Pierre-de-Salerne,Saint-Pierre-de-Salerne,27,Eure,28,Normandie
27593,ST PIERRE DES FLEURS,27370,ST PIERRE DES FLEURS,,49.2492717737,0.962946697912,593,,Saint-Pierre-des-Fleurs,Saint-Pierre-des-Fleurs,27,Eure,28,Normandie
27594,ST PIERRE DES IFS,27450,ST PIERRE DES IFS,,49.2655678167,0.608547904899,594,,Saint-Pierre-des-Ifs,Saint-Pierre-des-Ifs,27,Eure,28,Normandie
27595,ST PIERRE DU BOSGUERARD,27370,ST PIERRE DU BOSGUERARD,,49.2603785752,0.889845765495,595,,Saint-Pierre-du-Bosguérard,Saint-Pierre-du-Bosguérard,27,Eure,28,Normandie
27597,ST PIERRE DU VAL,27210,ST PIERRE DU VAL,,49.390892944,0.366485992919,597,,Saint-Pierre-du-Val,Saint-Pierre-du-Val,27,Eure,28,Normandie
27598,ST PIERRE DU VAUVRAY,27430,ST PIERRE DU VAUVRAY,,49.2236978815,1.21846130643,598,,Saint-Pierre-du-Vauvray,Saint-Pierre-du-Vauvray,27,Eure,28,Normandie
27599,ST PIERRE LA GARENNE,27600,ST PIERRE LA GARENNE,,49.1475155899,1.3912826068,599,,Saint-Pierre-la-Garenne,Saint-Pierre-la-Garenne,27,Eure,28,Normandie
27600,ST QUENTIN DES ISLES,27270,ST QUENTIN DES ISLES,,49.0500489537,0.576147214631,600,,Saint-Quentin-des-Isles,Saint-Quentin-des-Isles,27,Eure,28,Normandie
27601,ST SAMSON DE LA ROQUE,27680,ST SAMSON DE LA ROQUE,,49.42576562,0.42505003229,601,,Saint-Samson-de-la-Roque,Saint-Samson-de-la-Roque,27,Eure,28,Normandie
27602,ST SEBASTIEN DE MORSENT,27180,ST SEBASTIEN DE MORSENT,,49.0125501489,1.07438380244,602,,Saint-Sébastien-de-Morsent,Saint-Sébastien-de-Morsent,27,Eure,28,Normandie
27603,ST SIMEON,27560,ST SIMEON,,49.2843032087,0.516823898884,603,,Saint-Siméon,Saint-Siméon,27,Eure,28,Normandie
27604,ST SULPICE DE GRIMBOUVILLE,27210,ST SULPICE DE GRIMBOUVILLE,,49.375170989,0.448722234228,604,,Saint-Sulpice-de-Grimbouville,Saint-Sulpice-de-Grimbouville,27,Eure,28,Normandie
27605,ST SYLVESTRE DE CORMEILLES,27260,ST SYLVESTRE DE CORMEILLES,,49.2473078097,0.420897481485,605,,Saint-Sylvestre-de-Cormeilles,Saint-Sylvestre-de-Cormeilles,27,Eure,28,Normandie
27606,ST SYMPHORIEN,27500,ST SYMPHORIEN,,49.3103896124,0.455835904436,606,,Saint-Symphorien,Saint-Symphorien,27,Eure,28,Normandie
27607,ST THURIEN,27680,ST THURIEN,,49.4031416314,0.550572077475,607,,Saint-Thurien,Saint-Thurien,27,Eure,28,Normandie
27608,ST VICTOR DE CHRETIENVILLE,27300,ST VICTOR DE CHRETIENVILLE,,49.0634247621,0.513495017507,608,,Saint-Victor-de-Chrétienville,Saint-Victor-de-Chrétienville,27,Eure,28,Normandie
27609,ST VICTOR D EPINE,27800,ST VICTOR D EPINE,,49.2117875506,0.598900183918,609,,Saint-Victor-d'Épine,Saint-Victor-d'Épine,27,Eure,28,Normandie
27610,ST VICTOR SUR AVRE,27130,ST VICTOR SUR AVRE,,48.6962855225,0.850882569116,610,,Saint-Victor-sur-Avre,Saint-Victor-sur-Avre,27,Eure,28,Normandie
27611,ST VIGOR,27930,ST VIGOR,,49.0751768839,1.26002954032,611,,Saint-Vigor,Saint-Vigor,27,Eure,28,Normandie
27612,ST VINCENT DES BOIS,27950,ST VINCENT DES BOIS,,49.0600942614,1.39380009269,612,,Saint-Vincent-des-Bois,Saint-Vincent-des-Bois,27,Eure,28,Normandie
27613,ST VINCENT DU BOULAY,27230,ST VINCENT DU BOULAY,,49.1009242479,0.489885896124,613,,Saint-Vincent-du-Boulay,Saint-Vincent-du-Boulay,27,Eure,28,Normandie
27614,SANCOURT,27150,SANCOURT,,49.3535843441,1.67606825753,614,,Sancourt,Sancourt,27,Eure,28,Normandie
27615,SASSEY,27930,SASSEY,,49.0502192536,1.2227926418,615,,Sassey,Sassey,27,Eure,28,Normandie
27616,LA SAUSSAYE,27370,LA SAUSSAYE,,49.2545361431,0.9861777025,616,La,Saussaye,La Saussaye,27,Eure,28,Normandie
27617,SAUSSAY LA CAMPAGNE,27150,SAUSSAY LA CAMPAGNE,,49.318922019,1.5084674826,617,,Saussay-la-Campagne,Saussay-la-Campagne,27,Eure,28,Normandie
27618,SEBECOURT,27190,SEBECOURT,,48.9755682262,0.831363916278,618,,Sébécourt,Sébécourt,27,Eure,28,Normandie
27620,SELLES,27500,SELLES,,49.30116779,0.488856322247,620,,Selles,Selles,27,Eure,28,Normandie
27621,SEREZ,27220,SEREZ,,48.9337049982,1.35814785401,621,,Serez,Serez,27,Eure,28,Normandie
27622,SERQUIGNY,27470,SERQUIGNY,,49.1148167511,0.707564259681,622,,Serquigny,Serquigny,27,Eure,28,Normandie
27623,SURTAUVILLE,27400,SURTAUVILLE,,49.1981722844,1.05434427068,623,,Surtauville,Surtauville,27,Eure,28,Normandie
27624,SURVILLE,27400,SURVILLE,,49.2013129731,1.10240662519,624,,Surville,Surville,27,Eure,28,Normandie
27625,SUZAY,27420,SUZAY,,49.2701087808,1.51348714459,625,,Suzay,Suzay,27,Eure,28,Normandie
27626,THEILLEMENT,27520,THEILLEMENT,,49.2885183897,0.796005386744,626,,Theillement,Theillement,27,Eure,28,Normandie
27627,LE THEIL NOLENT,27230,LE THEIL NOLENT,,49.1522591555,0.53418397178,627,Le,Theil-Nolent,Le Theil-Nolent,27,Eure,28,Normandie
27629,THIBERVILLE,27230,THIBERVILLE,,49.1322455831,0.440825641328,629,,Thiberville,Thiberville,27,Eure,28,Normandie
27630,THIBOUVILLE,27800,THIBOUVILLE,,49.1412155943,0.803549325292,630,,Thibouville,Thibouville,27,Eure,28,Normandie
27631,THIERVILLE,27290,THIERVILLE,,49.2634059401,0.71733189075,631,,Thierville,Thierville,27,Eure,28,Normandie
27632,LE THIL,27150,LE THIL EN VEXIN,,49.3074274016,1.55522758401,632,Le,Thil,Le Thil,27,Eure,28,Normandie
27633,LES THILLIERS EN VEXIN,27420,LES THILLIERS EN VEXIN,,49.2364605708,1.60505156907,633,Les,Thilliers-en-Vexin,Les Thilliers-en-Vexin,27,Eure,28,Normandie
27635,LE THUIT,27700,LE THUIT,,49.2597517999,1.367256434,635,Le,Thuit,Le Thuit,27,Eure,28,Normandie
27638,LE THUIT DE L OISON,27370,LE THUIT DE L OISON,,49.2568957879,0.939183220001,638,Le,Thuit de l'Oison,Le Thuit de l'Oison,27,Eure,28,Normandie
27638,LE THUIT DE L OISON,27370,LE THUIT DE L OISON,LE THUIT ANGER,49.2568957879,0.939183220001,638,Le,Thuit de l'Oison,Le Thuit de l'Oison,27,Eure,28,Normandie
27638,LE THUIT DE L OISON,27370,LE THUIT DE L OISON,LE THUIT SIMER,49.2568957879,0.939183220001,638,Le,Thuit de l'Oison,Le Thuit de l'Oison,27,Eure,28,Normandie
27640,TILLEUL DAME AGNES,27170,TILLEUL DAME AGNES,,49.0059849064,0.891031374979,640,,Tilleul-Dame-Agnès,Tilleul-Dame-Agnès,27,Eure,28,Normandie
27641,LE TILLEUL LAMBERT,27110,LE TILLEUL LAMBERT,,49.0813497086,0.931190842277,641,Le,Tilleul-Lambert,Le Tilleul-Lambert,27,Eure,28,Normandie
27642,LE TILLEUL OTHON,27170,LE TILLEUL OTHON,,49.1134110176,0.795971990008,642,Le,Tilleul-Othon,Le Tilleul-Othon,27,Eure,28,Normandie
27643,TILLIERES SUR AVRE,27570,TILLIERES SUR AVRE,,48.7611224544,1.02819870414,643,,Tillières-sur-Avre,Tillières-sur-Avre,27,Eure,28,Normandie
27644,TILLY,27510,TILLY,,49.1423281336,1.52870047144,644,,Tilly,Tilly,27,Eure,28,Normandie
27645,TOCQUEVILLE,27500,TOCQUEVILLE,,49.4088397191,0.613448165873,645,,Tocqueville,Tocqueville,27,Eure,28,Normandie
27646,LE TORPT,27210,LE TORPT,,49.3369627726,0.380725864104,646,Le,Torpt,Le Torpt,27,Eure,28,Normandie
27647,TOSNY,27700,TOSNY,,49.2233918119,1.36348561262,647,,Tosny,Tosny,27,Eure,28,Normandie
27648,TOSTES,27340,TOSTES,,49.261897448,1.11517874384,648,,Tostes,Tostes,27,Eure,28,Normandie
27649,TOUFFREVILLE,27440,TOUFFREVILLE,,49.3443475717,1.4360738543,649,,Touffreville,Touffreville,27,Eure,28,Normandie
27650,TOURNEDOS BOIS HUBERT,27180,TOURNEDOS BOIS HUBERT,,49.0739979281,0.987864506785,650,,Tournedos-Bois-Hubert,Tournedos-Bois-Hubert,27,Eure,28,Normandie
27651,TOURNEDOS SUR SEINE,27100,TOURNEDOS SUR SEINE,,49.2763036364,1.25674953028,651,,Tournedos-sur-Seine,Tournedos-sur-Seine,27,Eure,28,Normandie
27652,TOURNEVILLE,27930,TOURNEVILLE,,49.0948484016,1.10594742714,652,,Tourneville,Tourneville,27,Eure,28,Normandie
27654,TOURVILLE LA CAMPAGNE,27370,TOURVILLE LA CAMPAGNE,,49.2254432812,0.899258865151,654,,Tourville-la-Campagne,Tourville-la-Campagne,27,Eure,28,Normandie
27655,TOURVILLE SUR PONT AUDEMER,27500,TOURVILLE SUR PONT AUDEMER,,49.3212693418,0.517360617762,655,,Tourville-sur-Pont-Audemer,Tourville-sur-Pont-Audemer,27,Eure,28,Normandie
27656,TOUTAINVILLE,27500,TOUTAINVILLE,,49.3566331529,0.458677731967,656,,Toutainville,Toutainville,27,Eure,28,Normandie
27657,TOUVILLE,27290,TOUVILLE SUR MONTFORT,,49.304888337,0.752307587705,657,,Touville,Touville,27,Eure,28,Normandie
27658,LE TREMBLAY OMONVILLE,27110,LE TREMBLAY OMONVILLE,,49.1209729922,0.91472011137,658,Le,Tremblay-Omonville,Le Tremblay-Omonville,27,Eure,28,Normandie
27659,LA TRINITE,27930,LA TRINITE,,48.9869014631,1.23675599456,659,La,Trinité,La Trinité,27,Eure,28,Normandie
27660,LA TRINITE DE REVILLE,27270,LA TRINITE DE REVILLE,,48.9669200561,0.510644672881,660,La,Trinité-de-Réville,La Trinité-de-Réville,27,Eure,28,Normandie
27661,LA TRINITE DE THOUBERVILLE,27310,LA TRINITE DE THOUBERVILLE,,49.3649602642,0.877572204781,661,La,Trinité-de-Thouberville,La Trinité-de-Thouberville,27,Eure,28,Normandie
27662,TRIQUEVILLE,27500,TRIQUEVILLE,,49.3298951973,0.437354872089,662,,Triqueville,Triqueville,27,Eure,28,Normandie
27663,LE TRONCQ,27110,LE TRONCQ,,49.1928851454,0.916869342267,663,Le,Troncq,Le Troncq,27,Eure,28,Normandie
27664,LE TRONQUAY,27480,LE TRONQUAY,,49.4375421734,1.45434227209,664,Le,Tronquay,Le Tronquay,27,Eure,28,Normandie
27665,TROUVILLE LA HAULE,27680,TROUVILLE LA HAULE,,49.4202682765,0.579988906072,665,,Trouville-la-Haule,Trouville-la-Haule,27,Eure,28,Normandie
27666,LA VACHERIE,27400,LA VACHERIE,,49.1236655866,1.13657686446,666,La,Vacherie,La Vacherie,27,Eure,28,Normandie
27667,VALAILLES,27300,VALAILLES,,49.1179826401,0.617010373701,667,,Valailles,Valailles,27,Eure,28,Normandie
27668,LE VAL DAVID,27120,LE VAL DAVID,,48.9788560101,1.26307012857,668,Le,Val-David,Le Val-David,27,Eure,28,Normandie
27669,VALLETOT,27350,VALLETOT,,49.3668015037,0.612104316061,669,,Valletot,Valletot,27,Eure,28,Normandie
27670,VANDRIMARE,27380,VANDRIMARE,,49.3827490868,1.35158602965,670,,Vandrimare,Vandrimare,27,Eure,28,Normandie
27671,VANNECROCQ,27210,VANNECROCQ,,49.3030779386,0.428281652185,671,,Vannecrocq,Vannecrocq,27,Eure,28,Normandie
27672,VASCOEUIL,27910,VASCOEUIL,,49.4459502042,1.38564792564,672,,Vascœuil,Vascœuil,27,Eure,28,Normandie
27673,VATTEVILLE,27430,VATTEVILLE,,49.2802915027,1.28490028492,673,,Vatteville,Vatteville,27,Eure,28,Normandie
27674,VAUX SUR EURE,27120,VAUX SUR EURE,,49.0358655826,1.33388811683,674,,Vaux-sur-Eure,Vaux-sur-Eure,27,Eure,28,Normandie
27676,VENABLES,27940,VENABLES,,49.2012200794,1.29594833053,676,Les,Trois Lacs,Les Trois Lacs,27,Eure,28,Normandie
27677,VENON,27110,VENON,,49.1731568906,1.0516279972,677,,Venon,Venon,27,Eure,28,Normandie
27678,LES VENTES,27180,LES VENTES,,48.9558529022,1.06594660384,678,Les,Ventes,Les Ventes,27,Eure,28,Normandie
27679,VERNEUIL SUR AVRE,27130,VERNEUIL SUR AVRE,,48.7377022151,0.924765167388,679,,Verneuil d'Avre et d'Iton,Verneuil d'Avre et d'Iton,27,Eure,28,Normandie
27680,VERNEUSSES,27390,VERNEUSSES,,48.9094606653,0.425364858539,680,,Verneusses,Verneusses,27,Eure,28,Normandie
27681,VERNON,27200,VERNON,,49.0879728821,1.48501282787,681,,Vernon,Vernon,27,Eure,28,Normandie
27682,VESLY,27870,VESLY,,49.2426057527,1.6415768761,682,,Vesly,Vesly,27,Eure,28,Normandie
27683,VEZILLON,27700,VEZILLON,,49.2217888937,1.3986752654,683,,Vézillon,Vézillon,27,Eure,28,Normandie
27684,LE VIEIL EVREUX,27930,LE VIEIL EVREUX,,49.0036162938,1.21290711297,684,Le,Vieil-Évreux,Le Vieil-Évreux,27,Eure,28,Normandie
27685,LA VIEILLE LYRE,27330,LA VIEILLE LYRE,,48.9215744552,0.750049343604,685,La,Vieille-Lyre,La Vieille-Lyre,27,Eure,28,Normandie
27686,VIEUX PORT,27680,VIEUX PORT,,49.4269809388,0.6083368192,686,,Vieux-Port,Vieux-Port,27,Eure,28,Normandie
27689,VILLEGATS,27120,VILLEGATS,,48.9984526159,1.46395648175,689,,Villegats,Villegats,27,Eure,28,Normandie
27690,VILLERS EN VEXIN,27420,VILLERS EN VEXIN,,49.249845153,1.59021278187,690,,Villers-en-Vexin,Villers-en-Vexin,27,Eure,28,Normandie
27691,VILLERS SUR LE ROULE,27940,VILLERS SUR LE ROULE,,49.1911682593,1.3195023232,691,,Villers-sur-le-Roule,Villers-sur-le-Roule,27,Eure,28,Normandie
27692,VILLETTES,27110,VILLETTES,,49.1542620882,1.04564765622,692,,Villettes,Villettes,27,Eure,28,Normandie
27693,SYLVAINS LES MOULINS,27240,SYLVAINS LES MOULINS,,48.9028184134,1.10269659898,693,,Sylvains-Lès-Moulins,Sylvains-Lès-Moulins,27,Eure,28,Normandie
27693,SYLVAINS LES MOULINS,27240,SYLVAINS LES MOULINS,COULONGES,48.9028184134,1.10269659898,693,,Sylvains-Lès-Moulins,Sylvains-Lès-Moulins,27,Eure,28,Normandie
27693,SYLVAINS LES MOULINS,27240,SYLVAINS LES MOULINS,VILLALET,48.9028184134,1.10269659898,693,,Sylvains-Lès-Moulins,Sylvains-Lès-Moulins,27,Eure,28,Normandie
27694,VILLEZ SOUS BAILLEUL,27950,VILLEZ SOUS BAILLEUL,,49.1075931676,1.36528860734,694,,Villez-sous-Bailleul,Villez-sous-Bailleul,27,Eure,28,Normandie
27695,VILLEZ SUR LE NEUBOURG,27110,VILLEZ SUR LE NEUBOURG,,49.1471878741,0.859460348209,695,,Villez-sur-le-Neubourg,Villez-sur-le-Neubourg,27,Eure,28,Normandie
27696,VILLIERS EN DESOEUVRE,27640,VILLIERS EN DESOEUVRE,,48.9578709877,1.48073443882,696,,Villiers-en-Désœuvre,Villiers-en-Désœuvre,27,Eure,28,Normandie
27697,VIRONVAY,27400,VIRONVAY,,49.2064207106,1.2196737257,697,,Vironvay,Vironvay,27,Eure,28,Normandie
27698,VITOT,27110,VITOT,,49.1678035757,0.887186611829,698,,Vitot,Vitot,27,Eure,28,Normandie
27699,VOISCREVILLE,27520,VOISCREVILLE,,49.2802991689,0.766898302367,699,,Voiscreville,Voiscreville,27,Eure,28,Normandie
27700,VRAIVILLE,27370,VRAIVILLE,,49.2175782912,1.03654685327,700,,Vraiville,Vraiville,27,Eure,28,Normandie
27701,VAL DE REUIL,27100,VAL DE REUIL,,49.261335704,1.21147195365,701,,Val-de-Reuil,Val-de-Reuil,27,Eure,28,Normandie
28001,ABONDANT,28410,ABONDANT,,48.795057031,1.42001117062,1,,Abondant,Abondant,28,Eure-et-Loir,24,Centre-Val de Loire
28002,ALLAINES MERVILLIERS,28310,ALLAINES MERVILLIERS,,48.1924030448,1.82421313538,2,,Allaines-Mervilliers,Allaines-Mervilliers,28,Eure-et-Loir,24,Centre-Val de Loire
28002,ALLAINES MERVILLIERS,28310,ALLAINES MERVILLIERS,MERVILLIERS,48.1924030448,1.82421313538,2,,Allaines-Mervilliers,Allaines-Mervilliers,28,Eure-et-Loir,24,Centre-Val de Loire
28003,ALLAINVILLE,28500,ALLAINVILLE,,48.7214134233,1.29474223836,3,,Allainville,Allainville,28,Eure-et-Loir,24,Centre-Val de Loire
28004,ALLONNES,28150,ALLONNES,,48.3316342289,1.65200413227,4,,Allonnes,Allonnes,28,Eure-et-Loir,24,Centre-Val de Loire
28005,ALLUYES,28800,ALLUYES,,48.2289582761,1.36379289637,5,,Alluyes,Alluyes,28,Eure-et-Loir,24,Centre-Val de Loire
28006,AMILLY,28300,AMILLY,,48.4423011417,1.40659215552,6,,Amilly,Amilly,28,Eure-et-Loir,24,Centre-Val de Loire
28007,ANET,28260,ANET,,48.8531068723,1.44282251415,7,,Anet,Anet,28,Eure-et-Loir,24,Centre-Val de Loire
28008,ARDELLES,28170,ARDELLES,,48.5460809864,1.17906298832,8,,Ardelles,Ardelles,28,Eure-et-Loir,24,Centre-Val de Loire
28009,ARDELU,28700,ARDELU,,48.3548964975,1.91257395142,9,,Ardelu,Ardelu,28,Eure-et-Loir,24,Centre-Val de Loire
28010,ARGENVILLIERS,28480,ARGENVILLIERS,,48.2764030103,0.969137990852,10,,Argenvilliers,Argenvilliers,28,Eure-et-Loir,24,Centre-Val de Loire
28012,ARROU,28290,ARROU,,48.1120067473,1.08598331855,12,,Commune nouvelle d'Arrou,Commune nouvelle d'Arrou,28,Eure-et-Loir,24,Centre-Val de Loire
28013,AUNAY SOUS AUNEAU,28700,AUNAY SOUS AUNEAU,,48.4428553048,1.81371743832,13,,Aunay-sous-Auneau,Aunay-sous-Auneau,28,Eure-et-Loir,24,Centre-Val de Loire
28014,AUNAY SOUS CRECY,28500,AUNAY SOUS CRECY,,48.6575767516,1.30387542544,14,,Aunay-sous-Crécy,Aunay-sous-Crécy,28,Eure-et-Loir,24,Centre-Val de Loire
28015,AUNEAU BLEURY ST SYMPHORIEN,28700,AUNEAU BLEURY ST SYMPHORIEN,,48.4694338029,1.77316730051,15,,Auneau-Bleury-Saint-Symphorien,Auneau-Bleury-Saint-Symphorien,28,Eure-et-Loir,24,Centre-Val de Loire
28015,AUNEAU BLEURY ST SYMPHORIEN,28700,AUNEAU BLEURY ST SYMPHORIEN,BLEURY,48.4694338029,1.77316730051,15,,Auneau-Bleury-Saint-Symphorien,Auneau-Bleury-Saint-Symphorien,28,Eure-et-Loir,24,Centre-Val de Loire
28015,AUNEAU BLEURY ST SYMPHORIEN,28700,AUNEAU BLEURY ST SYMPHORIEN,BLEURY ST SYMPHORIEN,48.4694338029,1.77316730051,15,,Auneau-Bleury-Saint-Symphorien,Auneau-Bleury-Saint-Symphorien,28,Eure-et-Loir,24,Centre-Val de Loire
28016,LES AUTELS VILLEVILLON,28330,LES AUTELS VILLEVILLON,,48.1758855775,0.998907351037,16,Les,Autels-Villevillon,Les Autels-Villevillon,28,Eure-et-Loir,24,Centre-Val de Loire
28017,AUTHEUIL,28220,AUTHEUIL,,48.0068716323,1.29520633941,17,,Autheuil,Autheuil,28,Eure-et-Loir,24,Centre-Val de Loire
28018,AUTHON DU PERCHE,28330,AUTHON DU PERCHE,,48.2017280828,0.885473078137,18,,Authon-du-Perche,Authon-du-Perche,28,Eure-et-Loir,24,Centre-Val de Loire
28019,BAIGNEAUX,28140,BAIGNEAUX,,48.1283278233,1.82055061372,19,,Baigneaux,Baigneaux,28,Eure-et-Loir,24,Centre-Val de Loire
28021,BAILLEAU LE PIN,28120,BAILLEAU LE PIN,,48.3590646091,1.32280842634,21,,Bailleau-le-Pin,Bailleau-le-Pin,28,Eure-et-Loir,24,Centre-Val de Loire
28022,BAILLEAU L EVEQUE,28300,BAILLEAU L EVEQUE,,48.4875827521,1.39655721577,22,,Bailleau-l'Évêque,Bailleau-l'Évêque,28,Eure-et-Loir,24,Centre-Val de Loire
28023,BAILLEAU ARMENONVILLE,28320,BAILLEAU ARMENONVILLE,,48.527016484,1.64740884389,23,,Bailleau-Armenonville,Bailleau-Armenonville,28,Eure-et-Loir,24,Centre-Val de Loire
28023,BAILLEAU ARMENONVILLE,28320,BAILLEAU ARMENONVILLE,ARMENONVILLE LES GATINEAUX,48.527016484,1.64740884389,23,,Bailleau-Armenonville,Bailleau-Armenonville,28,Eure-et-Loir,24,Centre-Val de Loire
28024,BARJOUVILLE,28630,BARJOUVILLE,,48.4066421974,1.47210057567,24,,Barjouville,Barjouville,28,Eure-et-Loir,24,Centre-Val de Loire
28025,BARMAINVILLE,28310,BARMAINVILLE,,48.259028961,1.95473845597,25,,Barmainville,Barmainville,28,Eure-et-Loir,24,Centre-Val de Loire
28026,BAUDREVILLE,28310,BAUDREVILLE,,48.3260694084,1.89005723992,26,,Baudreville,Baudreville,28,Eure-et-Loir,24,Centre-Val de Loire
28027,LA BAZOCHE GOUET,28330,LA BAZOCHE GOUET,,48.140010199,0.970669440312,27,La,Bazoche-Gouet,La Bazoche-Gouet,28,Eure-et-Loir,24,Centre-Val de Loire
28028,BAZOCHES EN DUNOIS,28140,BAZOCHES EN DUNOIS,,48.0945521285,1.57159369016,28,,Bazoches-en-Dunois,Bazoches-en-Dunois,28,Eure-et-Loir,24,Centre-Val de Loire
28029,BAZOCHES LES HAUTES,28140,BAZOCHES LES HAUTES,,48.159923395,1.82026354206,29,,Bazoches-les-Hautes,Bazoches-les-Hautes,28,Eure-et-Loir,24,Centre-Val de Loire
28030,BEAUCHE,28270,BEAUCHE,,48.6775239767,0.979954691565,30,,Beauche,Beauche,28,Eure-et-Loir,24,Centre-Val de Loire
28031,BEAUMONT LES AUTELS,28480,BEAUMONT LES AUTELS,,48.239618229,0.952240810191,31,,Beaumont-les-Autels,Beaumont-les-Autels,28,Eure-et-Loir,24,Centre-Val de Loire
28032,BEAUVILLIERS,28150,BEAUVILLIERS,,48.3006109163,1.6537618086,32,,Beauvilliers,Beauvilliers,28,Eure-et-Loir,24,Centre-Val de Loire
28033,BELHOMERT GUEHOUVILLE,28240,BELHOMERT GUEHOUVILLE,,48.5054756735,1.05051096321,33,,Belhomert-Guéhouville,Belhomert-Guéhouville,28,Eure-et-Loir,24,Centre-Val de Loire
28034,BERCHERES ST GERMAIN,28300,BERCHERES ST GERMAIN,,48.5350712593,1.4681780093,34,,Berchères-Saint-Germain,Berchères-Saint-Germain,28,Eure-et-Loir,24,Centre-Val de Loire
28034,BERCHERES ST GERMAIN,28300,BERCHERES ST GERMAIN,ST GERMAIN LA GATINE,48.5350712593,1.4681780093,34,,Berchères-Saint-Germain,Berchères-Saint-Germain,28,Eure-et-Loir,24,Centre-Val de Loire
28035,BERCHERES LES PIERRES,28630,BERCHERES LES PIERRES,,48.3738944765,1.55553584326,35,,Berchères-les-Pierres,Berchères-les-Pierres,28,Eure-et-Loir,24,Centre-Val de Loire
28036,BERCHERES SUR VESGRE,28260,BERCHERES SUR VESGRE,,48.8437900512,1.55304230439,36,,Berchères-sur-Vesgre,Berchères-sur-Vesgre,28,Eure-et-Loir,24,Centre-Val de Loire
28037,BEROU LA MULOTIERE,28270,BEROU LA MULOTIERE,,48.7406485574,1.07282067612,37,,Bérou-la-Mulotière,Bérou-la-Mulotière,28,Eure-et-Loir,24,Centre-Val de Loire
28038,BETHONVILLIERS,28330,BETHONVILLIERS,,48.2309678406,0.900029304684,38,,Béthonvilliers,Béthonvilliers,28,Eure-et-Loir,24,Centre-Val de Loire
28039,BEVILLE LE COMTE,28700,BEVILLE LE COMTE,,48.4402167565,1.70331148118,39,,Béville-le-Comte,Béville-le-Comte,28,Eure-et-Loir,24,Centre-Val de Loire
28040,BILLANCELLES,28190,BILLANCELLES,,48.4938554286,1.21326841082,40,,Billancelles,Billancelles,28,Eure-et-Loir,24,Centre-Val de Loire
28041,BLANDAINVILLE,28120,BLANDAINVILLE,,48.3153780498,1.29204429237,41,,Blandainville,Blandainville,28,Eure-et-Loir,24,Centre-Val de Loire
28044,BOISGASSON,28220,BOISGASSON,,48.0492850975,1.14020760521,44,,Boisgasson,Boisgasson,28,Eure-et-Loir,24,Centre-Val de Loire
28045,BOISSY EN DROUAIS,28500,BOISSY EN DROUAIS,,48.7288229425,1.25197353346,45,,Boissy-en-Drouais,Boissy-en-Drouais,28,Eure-et-Loir,24,Centre-Val de Loire
28046,BOISSY LES PERCHE,28340,BOISSY LES PERCHE,,48.6806255579,0.901884911868,46,,Boissy-lès-Perche,Boissy-lès-Perche,28,Eure-et-Loir,24,Centre-Val de Loire
28047,BOISVILLE LA ST PERE,28150,BOISVILLE LA ST PERE,,48.3320420844,1.6998719539,47,,Boisville-la-Saint-Père,Boisville-la-Saint-Père,28,Eure-et-Loir,24,Centre-Val de Loire
28048,LA BOURDINIERE ST LOUP,28360,LA BOURDINIERE ST LOUP,,48.3170148124,1.43019186424,48,La,Bourdinière-Saint-Loup,La Bourdinière-Saint-Loup,28,Eure-et-Loir,24,Centre-Val de Loire
28048,LA BOURDINIERE ST LOUP,28360,LA BOURDINIERE ST LOUP,ST LOUP,48.3170148124,1.43019186424,48,La,Bourdinière-Saint-Loup,La Bourdinière-Saint-Loup,28,Eure-et-Loir,24,Centre-Val de Loire
28049,BONCE,28150,BONCE,,48.3077148864,1.51717452746,49,,Boncé,Boncé,28,Eure-et-Loir,24,Centre-Val de Loire
28050,BONCOURT,28260,BONCOURT,,48.846510259,1.46368452192,50,,Boncourt,Boncourt,28,Eure-et-Loir,24,Centre-Val de Loire
28051,BONNEVAL,28800,BONNEVAL,,48.1812188908,1.3853348704,51,,Bonneval,Bonneval,28,Eure-et-Loir,24,Centre-Val de Loire
28052,BOUGLAINVAL,28130,BOUGLAINVAL,,48.5606379303,1.51011696561,52,,Bouglainval,Bouglainval,28,Eure-et-Loir,24,Centre-Val de Loire
28053,LE BOULLAY LES DEUX EGLISES,28170,LE BOULLAY LES DEUX EGLISES,,48.6321325907,1.3266464472,53,Le,Boullay-les-Deux-Églises,Le Boullay-les-Deux-Églises,28,Eure-et-Loir,24,Centre-Val de Loire
28054,LE BOULLAY MIVOYE,28210,LE BOULLAY MIVOYE,,48.6541877494,1.40635782342,54,Le,Boullay-Mivoye,Le Boullay-Mivoye,28,Eure-et-Loir,24,Centre-Val de Loire
28055,LE BOULLAY THIERRY,28210,LE BOULLAY THIERRY,,48.6345919447,1.43287089864,55,Le,Boullay-Thierry,Le Boullay-Thierry,28,Eure-et-Loir,24,Centre-Val de Loire
28056,BOUTIGNY PROUAIS,28410,BOUTIGNY PROUAIS,,48.7343916136,1.56955678251,56,,Boutigny-Prouais,Boutigny-Prouais,28,Eure-et-Loir,24,Centre-Val de Loire
28056,BOUTIGNY PROUAIS,28410,BOUTIGNY PROUAIS,PROUAIS,48.7343916136,1.56955678251,56,,Boutigny-Prouais,Boutigny-Prouais,28,Eure-et-Loir,24,Centre-Val de Loire
28057,BOUVILLE,28800,BOUVILLE,,48.2618330754,1.37825366464,57,,Bouville,Bouville,28,Eure-et-Loir,24,Centre-Val de Loire
28058,BRECHAMPS,28210,BRECHAMPS,,48.67275991,1.52098330064,58,,Bréchamps,Bréchamps,28,Eure-et-Loir,24,Centre-Val de Loire
28059,BREZOLLES,28270,BREZOLLES,,48.6888038711,1.06430633901,59,,Brezolles,Brezolles,28,Eure-et-Loir,24,Centre-Val de Loire
28060,BRICONVILLE,28300,BRICONVILLE,,48.5160983011,1.38866084264,60,,Briconville,Briconville,28,Eure-et-Loir,24,Centre-Val de Loire
28061,BROU,28160,BROU,,48.2174479455,1.15740318839,61,,Brou,Brou,28,Eure-et-Loir,24,Centre-Val de Loire
28062,BROUE,28410,BROUE,,48.7523989393,1.52435606759,62,,Broué,Broué,28,Eure-et-Loir,24,Centre-Val de Loire
28063,BRUNELLES,28400,BRUNELLES,,48.3251844262,0.912576880083,63,,Brunelles,Brunelles,28,Eure-et-Loir,24,Centre-Val de Loire
28064,BU,28410,BU,,48.8022272101,1.49327920018,64,,Bû,Bû,28,Eure-et-Loir,24,Centre-Val de Loire
28065,BULLAINVILLE,28800,BULLAINVILLE,,48.1704047942,1.50701616135,65,,Bullainville,Bullainville,28,Eure-et-Loir,24,Centre-Val de Loire
28066,BULLOU,28160,BULLOU,,48.2353082399,1.24636704324,66,,Bullou,Bullou,28,Eure-et-Loir,24,Centre-Val de Loire
28067,CERNAY,28120,CERNAY,,48.3854124094,1.23047521955,67,,Cernay,Cernay,28,Eure-et-Loir,24,Centre-Val de Loire
28068,CHALLET,28300,CHALLET,,48.5557196797,1.42782660043,68,,Challet,Challet,28,Eure-et-Loir,24,Centre-Val de Loire
28070,CHAMPHOL,28300,CHAMPHOL,,48.4669825206,1.51056677548,70,,Champhol,Champhol,28,Eure-et-Loir,24,Centre-Val de Loire
28071,CHAMPROND EN GATINE,28240,CHAMPROND EN GATINE,,48.3959360565,1.07458299345,71,,Champrond-en-Gâtine,Champrond-en-Gâtine,28,Eure-et-Loir,24,Centre-Val de Loire
28072,CHAMPROND EN PERCHET,28400,CHAMPROND EN PERCHET,,48.3085191806,0.888503086856,72,,Champrond-en-Perchet,Champrond-en-Perchet,28,Eure-et-Loir,24,Centre-Val de Loire
28073,CHAMPSERU,28700,CHAMPSERU,,48.4910310864,1.64595417772,73,,Champseru,Champseru,28,Eure-et-Loir,24,Centre-Val de Loire
28074,LA CHAPELLE D AUNAINVILLE,28700,LA CHAPELLE D AUNAINVILLE,,48.4158203912,1.79760418666,74,La,Chapelle-d'Aunainville,La Chapelle-d'Aunainville,28,Eure-et-Loir,24,Centre-Val de Loire
28075,LA CHAPELLE DU NOYER,28200,LA CHAPELLE DU NOYER,,48.0424164901,1.31784334889,75,La,Chapelle-du-Noyer,La Chapelle-du-Noyer,28,Eure-et-Loir,24,Centre-Val de Loire
28076,LA CHAPELLE FORAINVILLIERS,28500,LA CHAPELLE FORAINVILLIERS,,48.7282722261,1.49116779483,76,La,Chapelle-Forainvilliers,La Chapelle-Forainvilliers,28,Eure-et-Loir,24,Centre-Val de Loire
28077,LA CHAPELLE FORTIN,28340,LA CHAPELLE FORTIN,,48.6478574812,0.862854348715,77,La,Chapelle-Fortin,La Chapelle-Fortin,28,Eure-et-Loir,24,Centre-Val de Loire
28078,CHAPELLE GUILLAUME,28330,CHAPELLE GUILLAUME,,48.1192028756,0.892832407853,78,,Chapelle-Guillaume,Chapelle-Guillaume,28,Eure-et-Loir,24,Centre-Val de Loire
28079,CHAPELLE ROYALE,28290,CHAPELLE ROYALE,,48.1530043069,1.04379392191,79,,Chapelle-Royale,Chapelle-Royale,28,Eure-et-Loir,24,Centre-Val de Loire
28080,CHARBONNIERES,28330,CHARBONNIERES,,48.1895067201,0.944303356548,80,,Charbonnières,Charbonnières,28,Eure-et-Loir,24,Centre-Val de Loire
28081,CHARONVILLE,28120,CHARONVILLE,,48.2828983569,1.31051879432,81,,Charonville,Charonville,28,Eure-et-Loir,24,Centre-Val de Loire
28082,CHARPONT,28500,CHARPONT,,48.6958429169,1.43243894312,82,,Charpont,Charpont,28,Eure-et-Loir,24,Centre-Val de Loire
28083,CHARRAY,28220,CHARRAY,,47.9676391544,1.34088223788,83,,Charray,Charray,28,Eure-et-Loir,24,Centre-Val de Loire
28084,CHARTAINVILLIERS,28130,CHARTAINVILLIERS,,48.5430971453,1.54564862938,84,,Chartainvilliers,Chartainvilliers,28,Eure-et-Loir,24,Centre-Val de Loire
28085,CHARTRES,28000,CHARTRES,,48.4471464884,1.50570610616,85,,Chartres,Chartres,28,Eure-et-Loir,24,Centre-Val de Loire
28086,CHASSANT,28480,CHASSANT,,48.2920478755,1.06748981615,86,,Chassant,Chassant,28,Eure-et-Loir,24,Centre-Val de Loire
28087,CHATAINCOURT,28270,CHATAINCOURT,,48.6926195809,1.23398469234,87,,Châtaincourt,Châtaincourt,28,Eure-et-Loir,24,Centre-Val de Loire
28088,CHATEAUDUN,28200,CHATEAUDUN,,48.0762457334,1.32636728625,88,,Châteaudun,Châteaudun,28,Eure-et-Loir,24,Centre-Val de Loire
28089,CHATEAUNEUF EN THYMERAIS,28170,CHATEAUNEUF EN THYMERAIS,,48.589125049,1.23775016473,89,,Châteauneuf-en-Thymerais,Châteauneuf-en-Thymerais,28,Eure-et-Loir,24,Centre-Val de Loire
28090,LES CHATELETS,28270,LES CHATELETS,,48.6595742637,1.01827113343,90,Les,Châtelets,Les Châtelets,28,Eure-et-Loir,24,Centre-Val de Loire
28091,LES CHATELLIERS NOTRE DAME,28120,LES CHATELLIERS NOTRE DAME,,48.3465496414,1.2099229364,91,Les,Châtelliers-Notre-Dame,Les Châtelliers-Notre-Dame,28,Eure-et-Loir,24,Centre-Val de Loire
28092,CHATENAY,28700,CHATENAY,,48.3552348827,1.88349529404,92,,Châtenay,Châtenay,28,Eure-et-Loir,24,Centre-Val de Loire
28093,CHATILLON EN DUNOIS,28290,CHATILLON EN DUNOIS,,48.1250699549,1.1825407088,93,,Châtillon-en-Dunois,Châtillon-en-Dunois,28,Eure-et-Loir,24,Centre-Val de Loire
28094,CHAUDON,28210,CHAUDON,,48.6542763925,1.49383187007,94,,Chaudon,Chaudon,28,Eure-et-Loir,24,Centre-Val de Loire
28095,CHAUFFOURS,28120,CHAUFFOURS,,48.3944888938,1.33691872297,95,,Chauffours,Chauffours,28,Eure-et-Loir,24,Centre-Val de Loire
28096,LA CHAUSSEE D IVRY,28260,LA CHAUSSEE D IVRY,,48.8886967439,1.48819034746,96,La,Chaussée-d'Ivry,La Chaussée-d'Ivry,28,Eure-et-Loir,24,Centre-Val de Loire
28098,CHERISY,28500,CHERISY,,48.7567062776,1.43470618307,98,,Cherisy,Cherisy,28,Eure-et-Loir,24,Centre-Val de Loire
28099,CHUISNES,28190,CHUISNES,,48.4370118675,1.1888981198,99,,Chuisnes,Chuisnes,28,Eure-et-Loir,24,Centre-Val de Loire
28100,CINTRAY,28300,CINTRAY,,48.4458871543,1.3658542518,100,,Cintray,Cintray,28,Eure-et-Loir,24,Centre-Val de Loire
28101,CIVRY,28200,CIVRY,,48.0852872974,1.48109587759,101,,Civry,Civry,28,Eure-et-Loir,24,Centre-Val de Loire
28102,CLEVILLIERS,28300,CLEVILLIERS,,48.5510764723,1.38436336991,102,,Clévilliers,Clévilliers,28,Eure-et-Loir,24,Centre-Val de Loire
28103,CLOYES SUR LE LOIR,28220,CLOYES SUR LE LOIR,,47.9934559542,1.22150535909,103,,Cloyes-les-Trois-Rivières,Cloyes-les-Trois-Rivières,28,Eure-et-Loir,24,Centre-Val de Loire
28104,COLTAINVILLE,28300,COLTAINVILLE,,48.4919525444,1.59662805553,104,,Coltainville,Coltainville,28,Eure-et-Loir,24,Centre-Val de Loire
28105,COMBRES,28480,COMBRES,,48.3366942783,1.06041193699,105,,Combres,Combres,28,Eure-et-Loir,24,Centre-Val de Loire
28106,CONIE MOLITARD,28200,CONIE MOLITARD,,48.1134838252,1.44167207684,106,,Conie-Molitard,Conie-Molitard,28,Eure-et-Loir,24,Centre-Val de Loire
28107,CORANCEZ,28630,CORANCEZ,,48.3673223163,1.5147876149,107,,Corancez,Corancez,28,Eure-et-Loir,24,Centre-Val de Loire
28108,CORMAINVILLE,28140,CORMAINVILLE,,48.1315186166,1.60577478136,108,,Cormainville,Cormainville,28,Eure-et-Loir,24,Centre-Val de Loire
28109,LES CORVEES LES YYS,28240,LES CORVEES LES YYS,,48.3652575039,1.14754318067,109,Les,Corvées-les-Yys,Les Corvées-les-Yys,28,Eure-et-Loir,24,Centre-Val de Loire
28110,LE COUDRAY,28630,LE COUDRAY,,48.41900521,1.50280738837,110,Le,Coudray,Le Coudray,28,Eure-et-Loir,24,Centre-Val de Loire
28111,COUDRAY AU PERCHE,28330,COUDRAY AU PERCHE,,48.2383164259,0.857009664222,111,,Coudray-au-Perche,Coudray-au-Perche,28,Eure-et-Loir,24,Centre-Val de Loire
28112,COUDRECEAU,28400,COUDRECEAU,,48.3474351192,0.92333913843,112,,Coudreceau,Coudreceau,28,Eure-et-Loir,24,Centre-Val de Loire
28113,COULOMBS,28210,COULOMBS,,48.6558925205,1.55323037747,113,,Coulombs,Coulombs,28,Eure-et-Loir,24,Centre-Val de Loire
28114,COURBEHAYE,28140,COURBEHAYE,,48.1487812359,1.60780606369,114,,Courbehaye,Courbehaye,28,Eure-et-Loir,24,Centre-Val de Loire
28115,COURTALAIN,28290,COURTALAIN,,48.083883008,1.13673645483,115,,Courtalain,Courtalain,28,Eure-et-Loir,24,Centre-Val de Loire
28116,COURVILLE SUR EURE,28190,COURVILLE SUR EURE,,48.4522710093,1.24921701438,116,,Courville-sur-Eure,Courville-sur-Eure,28,Eure-et-Loir,24,Centre-Val de Loire
28117,CRECY COUVE,28500,CRECY COUVE,,48.6799400303,1.28280471955,117,,Crécy-Couvé,Crécy-Couvé,28,Eure-et-Loir,24,Centre-Val de Loire
28118,CROISILLES,28210,CROISILLES,,48.6907785003,1.49908136395,118,,Croisilles,Croisilles,28,Eure-et-Loir,24,Centre-Val de Loire
28119,LA CROIX DU PERCHE,28480,LA CROIX DU PERCHE,,48.2811953008,1.04194353092,119,La,Croix-du-Perche,La Croix-du-Perche,28,Eure-et-Loir,24,Centre-Val de Loire
28120,CRUCEY VILLAGES,28270,CRUCEY VILLAGES,,48.6685590578,1.10718898581,120,,Crucey-Villages,Crucey-Villages,28,Eure-et-Loir,24,Centre-Val de Loire
28120,CRUCEY VILLAGES,28270,CRUCEY VILLAGES,MAINTERNE,48.6685590578,1.10718898581,120,,Crucey-Villages,Crucey-Villages,28,Eure-et-Loir,24,Centre-Val de Loire
28120,CRUCEY VILLAGES,28270,CRUCEY VILLAGES,VITRAY SOUS BREZOLLES,48.6685590578,1.10718898581,120,,Crucey-Villages,Crucey-Villages,28,Eure-et-Loir,24,Centre-Val de Loire
28121,DAMBRON,28140,DAMBRON,,48.1163250641,1.87193508112,121,,Dambron,Dambron,28,Eure-et-Loir,24,Centre-Val de Loire
28122,DAMMARIE,28360,DAMMARIE,,48.3378217549,1.49741837777,122,,Dammarie,Dammarie,28,Eure-et-Loir,24,Centre-Val de Loire
28123,DAMPIERRE SOUS BROU,28160,DAMPIERRE SOUS BROU,,48.2244450667,1.10899937921,123,,Dampierre-sous-Brou,Dampierre-sous-Brou,28,Eure-et-Loir,24,Centre-Val de Loire
28124,DAMPIERRE SUR AVRE,28350,DAMPIERRE SUR AVRE,,48.7574894001,1.14268157055,124,,Dampierre-sur-Avre,Dampierre-sur-Avre,28,Eure-et-Loir,24,Centre-Val de Loire
28126,DANCY,28800,DANCY,,48.1527632149,1.46203314181,126,,Dancy,Dancy,28,Eure-et-Loir,24,Centre-Val de Loire
28127,DANGEAU,28160,DANGEAU,,48.1969504376,1.27633745372,127,,Dangeau,Dangeau,28,Eure-et-Loir,24,Centre-Val de Loire
28128,DANGERS,28190,DANGERS,,48.5096674489,1.35007819193,128,,Dangers,Dangers,28,Eure-et-Loir,24,Centre-Val de Loire
28129,DENONVILLE,28700,DENONVILLE,,48.3929135935,1.79949258254,129,,Denonville,Denonville,28,Eure-et-Loir,24,Centre-Val de Loire
28130,DIGNY,28250,DIGNY,,48.5287429775,1.13541399668,130,,Digny,Digny,28,Eure-et-Loir,24,Centre-Val de Loire
28132,DONNEMAIN ST MAMES,28200,DONNEMAIN ST MAMES,,48.1003971896,1.36973493796,132,,Donnemain-Saint-Mamès,Donnemain-Saint-Mamès,28,Eure-et-Loir,24,Centre-Val de Loire
28133,DOUY,28220,DOUY,,48.0366150829,1.27967684752,133,,Douy,Douy,28,Eure-et-Loir,24,Centre-Val de Loire
28134,DREUX,28100,DREUX,,48.7485203213,1.35931854112,134,,Dreux,Dreux,28,Eure-et-Loir,24,Centre-Val de Loire
28135,DROUE SUR DROUETTE,28230,DROUE SUR DROUETTE,,48.6019197892,1.70074924736,135,,Droue-sur-Drouette,Droue-sur-Drouette,28,Eure-et-Loir,24,Centre-Val de Loire
28136,ECLUZELLES,28500,ECLUZELLES,,48.7084339399,1.42195083018,136,,Écluzelles,Écluzelles,28,Eure-et-Loir,24,Centre-Val de Loire
28137,ECROSNES,28320,ECROSNES,,48.5539831477,1.73345464654,137,,Écrosnes,Écrosnes,28,Eure-et-Loir,24,Centre-Val de Loire
28139,EPEAUTROLLES,28120,EPEAUTROLLES,,48.31308475,1.33312111882,139,,Épeautrolles,Épeautrolles,28,Eure-et-Loir,24,Centre-Val de Loire
28140,EPERNON,28230,EPERNON,,48.5941135535,1.68537156703,140,,Épernon,Épernon,28,Eure-et-Loir,24,Centre-Val de Loire
28141,ERMENONVILLE LA GRANDE,28120,ERMENONVILLE LA GRANDE,,48.3356889061,1.38093715514,141,,Ermenonville-la-Grande,Ermenonville-la-Grande,28,Eure-et-Loir,24,Centre-Val de Loire
28142,ERMENONVILLE LA PETITE,28120,ERMENONVILLE LA PETITE,,48.2920874684,1.34785065317,142,,Ermenonville-la-Petite,Ermenonville-la-Petite,28,Eure-et-Loir,24,Centre-Val de Loire
28143,ESCORPAIN,28270,ESCORPAIN,,48.719747149,1.21490953697,143,,Escorpain,Escorpain,28,Eure-et-Loir,24,Centre-Val de Loire
28144,LES ETILLEUX,28330,LES ETILLEUX,,48.2447661829,0.81627272785,144,Les,Étilleux,Les Étilleux,28,Eure-et-Loir,24,Centre-Val de Loire
28146,FAVEROLLES,28210,FAVEROLLES,,48.6810277192,1.58421456616,146,,Faverolles,Faverolles,28,Eure-et-Loir,24,Centre-Val de Loire
28147,FAVIERES,28170,FAVIERES,,48.5320826189,1.22893932026,147,,Favières,Favières,28,Eure-et-Loir,24,Centre-Val de Loire
28148,LE FAVRIL,28190,LE FAVRIL,,48.4602165787,1.11125925385,148,Le,Favril,Le Favril,28,Eure-et-Loir,24,Centre-Val de Loire
28149,LA FERTE VIDAME,28340,LA FERTE VIDAME,,48.6000288384,0.88283888665,149,La,Ferté-Vidame,La Ferté-Vidame,28,Eure-et-Loir,24,Centre-Val de Loire
28150,LA FERTE VILLENEUIL,28220,LA FERTE VILLENEUIL,,47.9839726431,1.34319975365,150,La,Ferté-Villeneuil,La Ferté-Villeneuil,28,Eure-et-Loir,24,Centre-Val de Loire
28151,FESSANVILLIERS MATTANVILLIERS,28270,FESSANVILLIERS MATTANVILLIERS,,48.7036704915,1.03522178527,151,,Fessanvilliers-Mattanvilliers,Fessanvilliers-Mattanvilliers,28,Eure-et-Loir,24,Centre-Val de Loire
28153,FLACEY,28800,FLACEY,,48.1513426394,1.33294278767,153,,Flacey,Flacey,28,Eure-et-Loir,24,Centre-Val de Loire
28154,FONTAINE LA GUYON,28190,FONTAINE LA GUYON,,48.4754751752,1.2975045091,154,,Fontaine-la-Guyon,Fontaine-la-Guyon,28,Eure-et-Loir,24,Centre-Val de Loire
28155,FONTAINE LES RIBOUTS,28170,FONTAINE LES RIBOUTS,,48.6550452018,1.24758940203,155,,Fontaine-les-Ribouts,Fontaine-les-Ribouts,28,Eure-et-Loir,24,Centre-Val de Loire
28156,FONTAINE SIMON,28240,FONTAINE SIMON,,48.5088733732,0.992529524714,156,,Fontaine-Simon,Fontaine-Simon,28,Eure-et-Loir,24,Centre-Val de Loire
28157,FONTENAY SUR CONIE,28140,FONTENAY SUR CONIE,,48.1656552776,1.68239888522,157,,Fontenay-sur-Conie,Fontenay-sur-Conie,28,Eure-et-Loir,24,Centre-Val de Loire
28158,FONTENAY SUR EURE,28630,FONTENAY SUR EURE,,48.4059853535,1.42241249487,158,,Fontenay-sur-Eure,Fontenay-sur-Eure,28,Eure-et-Loir,24,Centre-Val de Loire
28159,LA FRAMBOISIERE,28250,LA FRAMBOISIERE,,48.597334686,1.01588556317,159,La,Framboisière,La Framboisière,28,Eure-et-Loir,24,Centre-Val de Loire
28160,FRANCOURVILLE,28700,FRANCOURVILLE,,48.4018212449,1.66249055604,160,,Francourville,Francourville,28,Eure-et-Loir,24,Centre-Val de Loire
28161,FRAZE,28160,FRAZE,,48.2597341794,1.09855168328,161,,Frazé,Frazé,28,Eure-et-Loir,24,Centre-Val de Loire
28162,FRESNAY LE COMTE,28360,FRESNAY LE COMTE,,48.3043561656,1.47797333912,162,,Fresnay-le-Comte,Fresnay-le-Comte,28,Eure-et-Loir,24,Centre-Val de Loire
28163,FRESNAY LE GILMERT,28300,FRESNAY LE GILMERT,,48.5129802184,1.4182025742,163,,Fresnay-le-Gilmert,Fresnay-le-Gilmert,28,Eure-et-Loir,24,Centre-Val de Loire
28164,FRESNAY L EVEQUE,28310,FRESNAY L EVEQUE,,48.2584598646,1.81447858393,164,,Fresnay-l'Évêque,Fresnay-l'Évêque,28,Eure-et-Loir,24,Centre-Val de Loire
28165,FRETIGNY,28480,FRETIGNY,,48.3640025075,1.0070715112,165,,Frétigny,Frétigny,28,Eure-et-Loir,24,Centre-Val de Loire
28166,FRIAIZE,28240,FRIAIZE,,48.429903453,1.12215343236,166,,Friaize,Friaize,28,Eure-et-Loir,24,Centre-Val de Loire
28167,FRUNCE,28190,FRUNCE,,48.4055650725,1.19764831132,167,,Fruncé,Fruncé,28,Eure-et-Loir,24,Centre-Val de Loire
28168,GALLARDON,28320,GALLARDON,,48.5338026197,1.68926878076,168,,Gallardon,Gallardon,28,Eure-et-Loir,24,Centre-Val de Loire
28168,GALLARDON,28320,GALLARDON,MONTLOUET,48.5338026197,1.68926878076,168,,Gallardon,Gallardon,28,Eure-et-Loir,24,Centre-Val de Loire
28169,GARANCIERES EN BEAUCE,28700,GARANCIERES EN BEAUCE,,48.433368289,1.91544327083,169,,Garancières-en-Beauce,Garancières-en-Beauce,28,Eure-et-Loir,24,Centre-Val de Loire
28170,GARANCIERES EN DROUAIS,28500,GARANCIERES EN DROUAIS,,48.7027343223,1.28199977538,170,,Garancières-en-Drouais,Garancières-en-Drouais,28,Eure-et-Loir,24,Centre-Val de Loire
28171,GARNAY,28500,GARNAY,,48.6998026129,1.33718579474,171,,Garnay,Garnay,28,Eure-et-Loir,24,Centre-Val de Loire
28172,GAS,28320,GAS,,48.5643910001,1.66759843253,172,,Gas,Gas,28,Eure-et-Loir,24,Centre-Val de Loire
28173,GASVILLE OISEME,28300,GASVILLE OISEME,,48.4727517815,1.55494978026,173,,Gasville-Oisème,Gasville-Oisème,28,Eure-et-Loir,24,Centre-Val de Loire
28175,LA GAUDAINE,28400,LA GAUDAINE,,48.2976074029,0.951537622095,175,La,Gaudaine,La Gaudaine,28,Eure-et-Loir,24,Centre-Val de Loire
28176,LE GAULT ST DENIS,28800,LE GAULT ST DENIS,,48.2461354624,1.48317191408,176,Le,Gault-Saint-Denis,Le Gault-Saint-Denis,28,Eure-et-Loir,24,Centre-Val de Loire
28177,GELLAINVILLE,28630,GELLAINVILLE,,48.4162199686,1.53530515243,177,,Gellainville,Gellainville,28,Eure-et-Loir,24,Centre-Val de Loire
28178,GERMAINVILLE,28500,GERMAINVILLE,,48.7450510494,1.47749303837,178,,Germainville,Germainville,28,Eure-et-Loir,24,Centre-Val de Loire
28180,GILLES,28260,GILLES,,48.9091969133,1.51807124557,180,,Gilles,Gilles,28,Eure-et-Loir,24,Centre-Val de Loire
28182,GOHORY,28160,GOHORY,,48.1653685282,1.22366989339,182,,Gohory,Gohory,28,Eure-et-Loir,24,Centre-Val de Loire
28183,GOMMERVILLE,28310,GOMMERVILLE,,48.3430832547,1.95281271777,183,,Gommerville,Gommerville,28,Eure-et-Loir,24,Centre-Val de Loire
28183,GOMMERVILLE,28310,GOMMERVILLE,GRANDVILLE GAUDREVILLE,48.3430832547,1.95281271777,183,,Gommerville,Gommerville,28,Eure-et-Loir,24,Centre-Val de Loire
28183,GOMMERVILLE,28700,GOMMERVILLE,ORLU,48.3430832547,1.95281271777,183,,Gommerville,Gommerville,28,Eure-et-Loir,24,Centre-Val de Loire
28184,GOUILLONS,28310,GOUILLONS,,48.3421221995,1.84393517435,184,,Gouillons,Gouillons,28,Eure-et-Loir,24,Centre-Val de Loire
28185,GOUSSAINVILLE,28410,GOUSSAINVILLE,,48.7829804111,1.56001143121,185,,Goussainville,Goussainville,28,Eure-et-Loir,24,Centre-Val de Loire
28185,GOUSSAINVILLE,28410,GOUSSAINVILLE,CHAMPAGNE,48.7829804111,1.56001143121,185,,Goussainville,Goussainville,28,Eure-et-Loir,24,Centre-Val de Loire
28187,GUAINVILLE,28260,GUAINVILLE,,48.9218839821,1.48299091693,187,,Guainville,Guainville,28,Eure-et-Loir,24,Centre-Val de Loire
28188,LE GUE DE LONGROI,28700,LE GUE DE LONGROI,,48.4912723078,1.70789056431,188,Le,Gué-de-Longroi,Le Gué-de-Longroi,28,Eure-et-Loir,24,Centre-Val de Loire
28189,GUILLEVILLE,28310,GUILLEVILLE,,48.2218869218,1.80247996905,189,,Guilleville,Guilleville,28,Eure-et-Loir,24,Centre-Val de Loire
28190,GUILLONVILLE,28140,GUILLONVILLE,,48.0936096295,1.64289190439,190,,Guillonville,Guillonville,28,Eure-et-Loir,24,Centre-Val de Loire
28191,HANCHES,28130,HANCHES,,48.5967671764,1.6482246442,191,,Hanches,Hanches,28,Eure-et-Loir,24,Centre-Val de Loire
28192,HAPPONVILLIERS,28480,HAPPONVILLIERS,,48.3299066055,1.10478004356,192,,Happonvilliers,Happonvilliers,28,Eure-et-Loir,24,Centre-Val de Loire
28193,HAVELU,28410,HAVELU,,48.7877502403,1.53388014582,193,,Havelu,Havelu,28,Eure-et-Loir,24,Centre-Val de Loire
28194,HOUVILLE LA BRANCHE,28700,HOUVILLE LA BRANCHE,,48.4443093655,1.63867238053,194,,Houville-la-Branche,Houville-la-Branche,28,Eure-et-Loir,24,Centre-Val de Loire
28195,HOUX,28130,HOUX,,48.569793408,1.62105336721,195,,Houx,Houx,28,Eure-et-Loir,24,Centre-Val de Loire
28196,ILLIERS COMBRAY,28120,ILLIERS COMBRAY,,48.3032872833,1.22693727509,196,,Illiers-Combray,Illiers-Combray,28,Eure-et-Loir,24,Centre-Val de Loire
28197,INTREVILLE,28310,INTREVILLE,,48.2982378814,1.93552953534,197,,Intréville,Intréville,28,Eure-et-Loir,24,Centre-Val de Loire
28198,JALLANS,28200,JALLANS,,48.0767840463,1.38589704833,198,,Jallans,Jallans,28,Eure-et-Loir,24,Centre-Val de Loire
28199,JANVILLE,28310,JANVILLE,,48.200814518,1.89039239434,199,,Janville,Janville,28,Eure-et-Loir,24,Centre-Val de Loire
28200,JAUDRAIS,28250,JAUDRAIS,,48.574248144,1.12322671781,200,,Jaudrais,Jaudrais,28,Eure-et-Loir,24,Centre-Val de Loire
28201,JOUY,28300,JOUY,,48.5156020381,1.54597077528,201,,Jouy,Jouy,28,Eure-et-Loir,24,Centre-Val de Loire
28202,LAMBLORE,28340,LAMBLORE,,48.6259517253,0.915645729308,202,,Lamblore,Lamblore,28,Eure-et-Loir,24,Centre-Val de Loire
28203,LANDELLES,28190,LANDELLES,,48.4626590612,1.19157539443,203,,Landelles,Landelles,28,Eure-et-Loir,24,Centre-Val de Loire
28204,LANGEY,28220,LANGEY,,48.0486333744,1.18485608196,204,,Langey,Langey,28,Eure-et-Loir,24,Centre-Val de Loire
28205,LANNERAY,28200,LANNERAY,,48.0865267047,1.22911535652,205,,Lanneray,Lanneray,28,Eure-et-Loir,24,Centre-Val de Loire
28206,LAONS,28270,LAONS,,48.6980280524,1.18635370769,206,,Laons,Laons,28,Eure-et-Loir,24,Centre-Val de Loire
28207,LETHUIN,28700,LETHUIN,,48.3768807843,1.86192482612,207,,Léthuin,Léthuin,28,Eure-et-Loir,24,Centre-Val de Loire
28208,LEVAINVILLE,28700,LEVAINVILLE,,48.4925727301,1.74748225078,208,,Levainville,Levainville,28,Eure-et-Loir,24,Centre-Val de Loire
28209,LEVES,28300,LEVES,,48.4799675774,1.46931768509,209,,Lèves,Lèves,28,Eure-et-Loir,24,Centre-Val de Loire
28210,LEVESVILLE LA CHENARD,28310,LEVESVILLE LA CHENARD,,48.3026078752,1.83081458653,210,,Levesville-la-Chenard,Levesville-la-Chenard,28,Eure-et-Loir,24,Centre-Val de Loire
28211,LOGRON,28200,LOGRON,,48.1408053653,1.25353586529,211,,Logron,Logron,28,Eure-et-Loir,24,Centre-Val de Loire
28212,LOIGNY LA BATAILLE,28140,LOIGNY LA BATAILLE,,48.126976397,1.72344653891,212,,Loigny-la-Bataille,Loigny-la-Bataille,28,Eure-et-Loir,24,Centre-Val de Loire
28213,LORMAYE,28210,LORMAYE,,48.6389252281,1.54309712562,213,,Lormaye,Lormaye,28,Eure-et-Loir,24,Centre-Val de Loire
28214,LA LOUPE,28240,LA LOUPE,,48.473175806,1.02235330632,214,La,Loupe,La Loupe,28,Eure-et-Loir,24,Centre-Val de Loire
28215,LOUVILLE LA CHENARD,28150,LOUVILLE LA CHENARD,,48.3262102975,1.79519469277,215,,Louville-la-Chenard,Louville-la-Chenard,28,Eure-et-Loir,24,Centre-Val de Loire
28216,LOUVILLIERS EN DROUAIS,28500,LOUVILLIERS EN DROUAIS,,48.7348522344,1.28314015139,216,,Louvilliers-en-Drouais,Louvilliers-en-Drouais,28,Eure-et-Loir,24,Centre-Val de Loire
28217,LOUVILLIERS LES PERCHE,28250,LOUVILLIERS LES PERCHE,,48.6161655057,1.06602415569,217,,Louvilliers-lès-Perche,Louvilliers-lès-Perche,28,Eure-et-Loir,24,Centre-Val de Loire
28218,LUCE,28110,LUCE,,48.434779098,1.45277569654,218,,Lucé,Lucé,28,Eure-et-Loir,24,Centre-Val de Loire
28219,LUIGNY,28480,LUIGNY,,48.2366504401,1.02792441815,219,,Luigny,Luigny,28,Eure-et-Loir,24,Centre-Val de Loire
28220,LUISANT,28600,LUISANT,,48.4232173062,1.46928323877,220,,Luisant,Luisant,28,Eure-et-Loir,24,Centre-Val de Loire
28221,LUMEAU,28140,LUMEAU,,48.106757981,1.7776103465,221,,Lumeau,Lumeau,28,Eure-et-Loir,24,Centre-Val de Loire
28222,LUPLANTE,28360,LUPLANTE,,48.2989041205,1.38417149076,222,,Luplanté,Luplanté,28,Eure-et-Loir,24,Centre-Val de Loire
28223,LURAY,28500,LURAY,,48.7158016965,1.39904091699,223,,Luray,Luray,28,Eure-et-Loir,24,Centre-Val de Loire
28224,LUTZ EN DUNOIS,28200,LUTZ EN DUNOIS,,48.0585028612,1.42179570533,224,,Lutz-en-Dunois,Lutz-en-Dunois,28,Eure-et-Loir,24,Centre-Val de Loire
28225,MAGNY,28120,MAGNY,,48.3445190999,1.27019288378,225,,Magny,Magny,28,Eure-et-Loir,24,Centre-Val de Loire
28226,MAILLEBOIS,28170,MAILLEBOIS,,48.6321135718,1.1501161639,226,,Maillebois,Maillebois,28,Eure-et-Loir,24,Centre-Val de Loire
28226,MAILLEBOIS,28170,MAILLEBOIS,BLEVY,48.6321135718,1.1501161639,226,,Maillebois,Maillebois,28,Eure-et-Loir,24,Centre-Val de Loire
28226,MAILLEBOIS,28170,MAILLEBOIS,DAMPIERRE SUR BLEVY,48.6321135718,1.1501161639,226,,Maillebois,Maillebois,28,Eure-et-Loir,24,Centre-Val de Loire
28227,MAINTENON,28130,MAINTENON,,48.5838765563,1.58410337254,227,,Maintenon,Maintenon,28,Eure-et-Loir,24,Centre-Val de Loire
28229,MAINVILLIERS,28300,MAINVILLIERS,,48.4625411561,1.44581970225,229,,Mainvilliers,Mainvilliers,28,Eure-et-Loir,24,Centre-Val de Loire
28230,MAISONS,28700,MAISONS,,48.4036829881,1.84481413865,230,,Maisons,Maisons,28,Eure-et-Loir,24,Centre-Val de Loire
28231,LA MANCELIERE,28270,LA MANCELIERE,,48.6429568791,0.985217307956,231,La,Mancelière,La Mancelière,28,Eure-et-Loir,24,Centre-Val de Loire
28232,MANOU,28240,MANOU,,48.5363856348,0.962933887077,232,,Manou,Manou,28,Eure-et-Loir,24,Centre-Val de Loire
28233,MARBOUE,28200,MARBOUE,,48.1196433379,1.30776071957,233,,Marboué,Marboué,28,Eure-et-Loir,24,Centre-Val de Loire
28234,MARCHEVILLE,28120,MARCHEVILLE,,48.3638497344,1.24329723832,234,,Marchéville,Marchéville,28,Eure-et-Loir,24,Centre-Val de Loire
28235,MARCHEZAIS,28410,MARCHEZAIS,,48.7749796746,1.51347142125,235,,Marchezais,Marchezais,28,Eure-et-Loir,24,Centre-Val de Loire
28236,MARGON,28400,MARGON,,48.337398478,0.850887620161,236,,Margon,Margon,28,Eure-et-Loir,24,Centre-Val de Loire
28237,MAROLLES LES BUIS,28400,MAROLLES LES BUIS,,48.366153704,0.94298127545,237,,Marolles-les-Buis,Marolles-les-Buis,28,Eure-et-Loir,24,Centre-Val de Loire
28239,MARVILLE MOUTIERS BRULE,28500,MARVILLE MOUTIERS BRULE,,48.6748561524,1.37887146982,239,,Marville-Moutiers-Brûlé,Marville-Moutiers-Brûlé,28,Eure-et-Loir,24,Centre-Val de Loire
28240,MEAUCE,28240,MEAUCE,,48.4843548386,0.985374374936,240,,Meaucé,Meaucé,28,Eure-et-Loir,24,Centre-Val de Loire
28241,LE MEE,28220,LE MEE,,47.9945008114,1.4091440341,241,Le,Mée,Le Mée,28,Eure-et-Loir,24,Centre-Val de Loire
28242,MEREGLISE,28120,MEREGLISE,,48.2935308635,1.18476040726,242,,Méréglise,Méréglise,28,Eure-et-Loir,24,Centre-Val de Loire
28243,MEROUVILLE,28310,MEROUVILLE,,48.3030421961,1.9009195604,243,,Mérouville,Mérouville,28,Eure-et-Loir,24,Centre-Val de Loire
28245,MESLAY LE GRENET,28120,MESLAY LE GRENET,,48.3706581245,1.38965705749,245,,Meslay-le-Grenet,Meslay-le-Grenet,28,Eure-et-Loir,24,Centre-Val de Loire
28246,MESLAY LE VIDAME,28360,MESLAY LE VIDAME,,48.2778375421,1.48218731208,246,,Meslay-le-Vidame,Meslay-le-Vidame,28,Eure-et-Loir,24,Centre-Val de Loire
28247,LE MESNIL SIMON,28260,LE MESNIL SIMON,,48.8900734081,1.53507885253,247,Le,Mesnil-Simon,Le Mesnil-Simon,28,Eure-et-Loir,24,Centre-Val de Loire
28248,LE MESNIL THOMAS,28250,LE MESNIL THOMAS,,48.5900545537,1.08955144793,248,Le,Mesnil-Thomas,Le Mesnil-Thomas,28,Eure-et-Loir,24,Centre-Val de Loire
28249,MEVOISINS,28130,MEVOISINS,,48.5527925971,1.59900194364,249,,Mévoisins,Mévoisins,28,Eure-et-Loir,24,Centre-Val de Loire
28250,MEZIERES AU PERCHE,28160,MEZIERES AU PERCHE,,48.2503282821,1.27119066216,250,,Mézières-au-Perche,Mézières-au-Perche,28,Eure-et-Loir,24,Centre-Val de Loire
28251,MEZIERES EN DROUAIS,28500,MEZIERES EN DROUAIS,,48.7254690446,1.4421064834,251,,Mézières-en-Drouais,Mézières-en-Drouais,28,Eure-et-Loir,24,Centre-Val de Loire
28252,MIERMAIGNE,28480,MIERMAIGNE,,48.2488776509,0.99749026867,252,,Miermaigne,Miermaigne,28,Eure-et-Loir,24,Centre-Val de Loire
28253,MIGNIERES,28630,MIGNIERES,,48.3607988018,1.42399697169,253,,Mignières,Mignières,28,Eure-et-Loir,24,Centre-Val de Loire
28254,MITTAINVILLIERS VERIGNY,28190,MITTAINVILLIERS VERIGNY,,48.499607648,1.31332241726,254,,Mittainvilliers-Vérigny,Mittainvilliers-Vérigny,28,Eure-et-Loir,24,Centre-Val de Loire
28254,MITTAINVILLIERS VERIGNY,28190,MITTAINVILLIERS VERIGNY,VERIGNY,48.499607648,1.31332241726,254,,Mittainvilliers-Vérigny,Mittainvilliers-Vérigny,28,Eure-et-Loir,24,Centre-Val de Loire
28255,MOINVILLE LA JEULIN,28700,MOINVILLE LA JEULIN,,48.3757776174,1.70002480039,255,,Moinville-la-Jeulin,Moinville-la-Jeulin,28,Eure-et-Loir,24,Centre-Val de Loire
28256,MOLEANS,28200,MOLEANS,,48.1124611479,1.40240862093,256,,Moléans,Moléans,28,Eure-et-Loir,24,Centre-Val de Loire
28257,MONDONVILLE ST JEAN,28700,MONDONVILLE ST JEAN,,48.3631185129,1.82435785403,257,,Mondonville-Saint-Jean,Mondonville-Saint-Jean,28,Eure-et-Loir,24,Centre-Val de Loire
28259,MONTBOISSIER,28800,MONTBOISSIER,,48.2296631604,1.40996586006,259,,Montboissier,Montboissier,28,Eure-et-Loir,24,Centre-Val de Loire
28260,MONTHARVILLE,28800,MONTHARVILLE,,48.1768570171,1.32504860803,260,,Montharville,Montharville,28,Eure-et-Loir,24,Centre-Val de Loire
28261,MONTIGNY LE CHARTIF,28120,MONTIGNY LE CHARTIF,,48.2909978482,1.13008902025,261,,Montigny-le-Chartif,Montigny-le-Chartif,28,Eure-et-Loir,24,Centre-Val de Loire
28262,MONTIGNY LE GANNELON,28220,MONTIGNY LE GANNELON,,48.0164067784,1.22640020803,262,,Montigny-le-Gannelon,Montigny-le-Gannelon,28,Eure-et-Loir,24,Centre-Val de Loire
28263,MONTIGNY SUR AVRE,28270,MONTIGNY SUR AVRE,,48.7203880517,1.01209586638,263,,Montigny-sur-Avre,Montigny-sur-Avre,28,Eure-et-Loir,24,Centre-Val de Loire
28264,MONTIREAU,28240,MONTIREAU,,48.4147284509,1.03540606287,264,,Montireau,Montireau,28,Eure-et-Loir,24,Centre-Val de Loire
28265,MONTLANDON,28240,MONTLANDON,,48.3881899209,1.02542042661,265,,Montlandon,Montlandon,28,Eure-et-Loir,24,Centre-Val de Loire
28267,MONTREUIL,28500,MONTREUIL,,48.7726380793,1.38032861219,267,,Montreuil,Montreuil,28,Eure-et-Loir,24,Centre-Val de Loire
28268,MORAINVILLE,28700,MORAINVILLE,,48.3797470678,1.83370952065,268,,Morainville,Morainville,28,Eure-et-Loir,24,Centre-Val de Loire
28269,MORANCEZ,28630,MORANCEZ,,48.3965615994,1.50333516313,269,,Morancez,Morancez,28,Eure-et-Loir,24,Centre-Val de Loire
28270,MORIERS,28800,MORIERS,,48.2206945142,1.44018817268,270,,Moriers,Moriers,28,Eure-et-Loir,24,Centre-Val de Loire
28271,MORVILLIERS,28340,MORVILLIERS,,48.6490907462,0.946747285207,271,,Morvilliers,Morvilliers,28,Eure-et-Loir,24,Centre-Val de Loire
28272,MOTTEREAU,28160,MOTTEREAU,,48.2518845768,1.1688416202,272,,Mottereau,Mottereau,28,Eure-et-Loir,24,Centre-Val de Loire
28273,MOULHARD,28160,MOULHARD,,48.2126188689,1.01237475524,273,,Moulhard,Moulhard,28,Eure-et-Loir,24,Centre-Val de Loire
28274,MOUTIERS,28150,MOUTIERS,,48.2956740987,1.76452451623,274,,Moutiers,Moutiers,28,Eure-et-Loir,24,Centre-Val de Loire
28275,NERON,28210,NERON,,48.5961826632,1.50307845744,275,,Néron,Néron,28,Eure-et-Loir,24,Centre-Val de Loire
28276,NEUVY EN BEAUCE,28310,NEUVY EN BEAUCE,,48.2759003271,1.87399760682,276,,Neuvy-en-Beauce,Neuvy-en-Beauce,28,Eure-et-Loir,24,Centre-Val de Loire
28277,NEUVY EN DUNOIS,28800,NEUVY EN DUNOIS,,48.2026068926,1.53607016392,277,,Neuvy-en-Dunois,Neuvy-en-Dunois,28,Eure-et-Loir,24,Centre-Val de Loire
28278,NOGENT LE PHAYE,28630,NOGENT LE PHAYE,,48.4518880415,1.57822633062,278,,Nogent-le-Phaye,Nogent-le-Phaye,28,Eure-et-Loir,24,Centre-Val de Loire
28279,NOGENT LE ROI,28210,NOGENT LE ROI,,48.6300718767,1.52405693927,279,,Nogent-le-Roi,Nogent-le-Roi,28,Eure-et-Loir,24,Centre-Val de Loire
28279,NOGENT LE ROI,28210,NOGENT LE ROI,VACHERESSES LES BASSES,48.6300718767,1.52405693927,279,,Nogent-le-Roi,Nogent-le-Roi,28,Eure-et-Loir,24,Centre-Val de Loire
28280,NOGENT LE ROTROU,28400,NOGENT LE ROTROU,,48.3176302432,0.804048016354,280,,Nogent-le-Rotrou,Nogent-le-Rotrou,28,Eure-et-Loir,24,Centre-Val de Loire
28281,NOGENT SUR EURE,28120,NOGENT SUR EURE,,48.3896168991,1.37569716428,281,,Nogent-sur-Eure,Nogent-sur-Eure,28,Eure-et-Loir,24,Centre-Val de Loire
28282,NONVILLIERS GRANDHOUX,28120,NONVILLIERS GRANDHOUX,,48.3396576649,1.14563096287,282,,Nonvilliers-Grandhoux,Nonvilliers-Grandhoux,28,Eure-et-Loir,24,Centre-Val de Loire
28283,NOTTONVILLE,28140,NOTTONVILLE,,48.1245767113,1.52530460831,283,,Nottonville,Nottonville,28,Eure-et-Loir,24,Centre-Val de Loire
28284,OINVILLE ST LIPHARD,28310,OINVILLE ST LIPHARD,,48.2356320829,1.92414549708,284,,Oinville-Saint-Liphard,Oinville-Saint-Liphard,28,Eure-et-Loir,24,Centre-Val de Loire
28285,OINVILLE SOUS AUNEAU,28700,OINVILLE SOUS AUNEAU,,48.4697612447,1.71422426544,285,,Oinville-sous-Auneau,Oinville-sous-Auneau,28,Eure-et-Loir,24,Centre-Val de Loire
28286,OLLE,28120,OLLE,,48.3861713176,1.29676910004,286,,Ollé,Ollé,28,Eure-et-Loir,24,Centre-Val de Loire
28287,ORGERES EN BEAUCE,28140,ORGERES EN BEAUCE,,48.1385604714,1.684325346,287,,Orgères-en-Beauce,Orgères-en-Beauce,28,Eure-et-Loir,24,Centre-Val de Loire
28289,ORMOY,28210,ORMOY,,48.6243546141,1.47177078539,289,,Ormoy,Ormoy,28,Eure-et-Loir,24,Centre-Val de Loire
28290,ORROUER,28190,ORROUER,,48.4059068462,1.27863713546,290,,Orrouer,Orrouer,28,Eure-et-Loir,24,Centre-Val de Loire
28291,OUARVILLE,28150,OUARVILLE,,48.3617202687,1.76951827841,291,,Ouarville,Ouarville,28,Eure-et-Loir,24,Centre-Val de Loire
28292,OUERRE,28500,OUERRE,,48.7080614292,1.48885870583,292,,Ouerre,Ouerre,28,Eure-et-Loir,24,Centre-Val de Loire
28293,OULINS,28260,OULINS,,48.8665989228,1.4893062268,293,,Oulins,Oulins,28,Eure-et-Loir,24,Centre-Val de Loire
28294,OYSONVILLE,28700,OYSONVILLE,,48.3928067068,1.94831483735,294,,Oysonville,Oysonville,28,Eure-et-Loir,24,Centre-Val de Loire
28295,OZOIR LE BREUIL,28200,OZOIR LE BREUIL,,48.0140288244,1.47582957378,295,,Ozoir-le-Breuil,Ozoir-le-Breuil,28,Eure-et-Loir,24,Centre-Val de Loire
28296,PERONVILLE,28140,PERONVILLE,,48.0586450823,1.5824310106,296,,Péronville,Péronville,28,Eure-et-Loir,24,Centre-Val de Loire
28298,PIERRES,28130,PIERRES,,48.5892951017,1.55200898008,298,,Pierres,Pierres,28,Eure-et-Loir,24,Centre-Val de Loire
28299,LES PINTHIERES,28210,LES PINTHIERES,,48.7047238564,1.57392519381,299,Les,Pinthières,Les Pinthières,28,Eure-et-Loir,24,Centre-Val de Loire
28300,POINVILLE,28310,POINVILLE,,48.1748951047,1.90040585204,300,,Poinville,Poinville,28,Eure-et-Loir,24,Centre-Val de Loire
28301,POISVILLIERS,28300,POISVILLIERS,,48.5063843494,1.46123630371,301,,Poisvilliers,Poisvilliers,28,Eure-et-Loir,24,Centre-Val de Loire
28302,PONTGOUIN,28190,PONTGOUIN,,48.4914581287,1.14548967214,302,,Pontgouin,Pontgouin,28,Eure-et-Loir,24,Centre-Val de Loire
28303,POUPRY,28140,POUPRY,,48.0996466123,1.83298152372,303,,Poupry,Poupry,28,Eure-et-Loir,24,Centre-Val de Loire
28304,PRASVILLE,28150,PRASVILLE,,48.2738416281,1.71166561057,304,,Prasville,Prasville,28,Eure-et-Loir,24,Centre-Val de Loire
28305,PRE ST EVROULT,28800,PRE ST EVROULT,,48.1878434456,1.4610005608,305,,Pré-Saint-Évroult,Pré-Saint-Évroult,28,Eure-et-Loir,24,Centre-Val de Loire
28306,PRE ST MARTIN,28800,PRE ST MARTIN,,48.2195680245,1.47285651616,306,,Pré-Saint-Martin,Pré-Saint-Martin,28,Eure-et-Loir,24,Centre-Val de Loire
28308,PRUDEMANCHE,28270,PRUDEMANCHE,,48.7214056667,1.13416780653,308,,Prudemanche,Prudemanche,28,Eure-et-Loir,24,Centre-Val de Loire
28309,PRUNAY LE GILLON,28360,PRUNAY LE GILLON,,48.3665143772,1.63539609302,309,,Prunay-le-Gillon,Prunay-le-Gillon,28,Eure-et-Loir,24,Centre-Val de Loire
28310,LA PUISAYE,28250,LA PUISAYE,,48.6126007617,0.96980653651,310,La,Puisaye,La Puisaye,28,Eure-et-Loir,24,Centre-Val de Loire
28311,LE PUISET,28310,LE PUISET,,48.2134406832,1.86245044314,311,Le,Puiset,Le Puiset,28,Eure-et-Loir,24,Centre-Val de Loire
28312,PUISEUX,28170,PUISEUX,,48.6389286317,1.36882043567,312,,Puiseux,Puiseux,28,Eure-et-Loir,24,Centre-Val de Loire
28313,RECLAINVILLE,28150,RECLAINVILLE,,48.3375337584,1.73962432713,313,,Réclainville,Réclainville,28,Eure-et-Loir,24,Centre-Val de Loire
28314,LES RESSUINTES,28340,LES RESSUINTES,,48.5952891998,0.926278842341,314,Les,Ressuintes,Les Ressuintes,28,Eure-et-Loir,24,Centre-Val de Loire
28315,REVERCOURT,28270,REVERCOURT,,48.7223591081,1.077287859,315,,Revercourt,Revercourt,28,Eure-et-Loir,24,Centre-Val de Loire
28316,ROHAIRE,28340,ROHAIRE,,48.6667438404,0.839547342501,316,,Rohaire,Rohaire,28,Eure-et-Loir,24,Centre-Val de Loire
28317,ROINVILLE,28700,ROINVILLE,,48.4427044137,1.7567393005,317,,Roinville,Roinville,28,Eure-et-Loir,24,Centre-Val de Loire
28318,ROMILLY SUR AIGRE,28220,ROMILLY SUR AIGRE,,47.9801700708,1.28298117562,318,,Romilly-sur-Aigre,Romilly-sur-Aigre,28,Eure-et-Loir,24,Centre-Val de Loire
28319,ROUVRAY ST DENIS,28310,ROUVRAY ST DENIS,,48.2770955308,1.94270719107,319,,Rouvray-Saint-Denis,Rouvray-Saint-Denis,28,Eure-et-Loir,24,Centre-Val de Loire
28321,ROUVRES,28260,ROUVRES,,48.8322998,1.48070112621,321,,Rouvres,Rouvres,28,Eure-et-Loir,24,Centre-Val de Loire
28322,RUEIL LA GADELIERE,28270,RUEIL LA GADELIERE,,48.7045261989,0.963451451882,322,,Rueil-la-Gadelière,Rueil-la-Gadelière,28,Eure-et-Loir,24,Centre-Val de Loire
28323,ST ANGE ET TORCAY,28170,ST ANGE ET TORCAY,,48.6495007318,1.21435953964,323,,Saint-Ange-et-Torçay,Saint-Ange-et-Torçay,28,Eure-et-Loir,24,Centre-Val de Loire
28324,ST ARNOULT DES BOIS,28190,ST ARNOULT DES BOIS,,48.4933065448,1.25476022855,324,,Saint-Arnoult-des-Bois,Saint-Arnoult-des-Bois,28,Eure-et-Loir,24,Centre-Val de Loire
28325,ST AUBIN DES BOIS,28300,ST AUBIN DES BOIS,,48.4631418835,1.34544472164,325,,Saint-Aubin-des-Bois,Saint-Aubin-des-Bois,28,Eure-et-Loir,24,Centre-Val de Loire
28326,ST AVIT LES GUESPIERES,28120,ST AVIT LES GUESPIERES,,48.2684999269,1.2627628625,326,,Saint-Avit-les-Guespières,Saint-Avit-les-Guespières,28,Eure-et-Loir,24,Centre-Val de Loire
28327,ST BOMER,28330,ST BOMER,,48.194965232,0.829197068789,327,,Saint-Bomer,Saint-Bomer,28,Eure-et-Loir,24,Centre-Val de Loire
28329,ST CHRISTOPHE,28200,ST CHRISTOPHE,,48.1319981863,1.36945668729,329,,Saint-Christophe,Saint-Christophe,28,Eure-et-Loir,24,Centre-Val de Loire
28330,ST CLOUD EN DUNOIS,28200,ST CLOUD EN DUNOIS,,48.0457576548,1.46955748581,330,,Villemaury,Villemaury,28,Eure-et-Loir,24,Centre-Val de Loire
28331,ST DENIS D AUTHOU,28480,ST DENIS D AUTHOU,,48.3325632205,0.996756265115,331,,Saint-Denis-d'Authou,Saint-Denis-d'Authou,28,Eure-et-Loir,24,Centre-Val de Loire
28332,STE GEMME MORONVAL,28500,STE GEMME MORONVAL,,48.7355373578,1.41198816896,332,,Sainte-Gemme-Moronval,Sainte-Gemme-Moronval,28,Eure-et-Loir,24,Centre-Val de Loire
28333,ST DENIS DES PUITS,28240,ST DENIS DES PUITS,,48.3801274748,1.16252048003,333,,Saint-Denis-des-Puits,Saint-Denis-des-Puits,28,Eure-et-Loir,24,Centre-Val de Loire
28334,ST DENIS LES PONTS,28200,ST DENIS LES PONTS,,48.0613637512,1.27228736611,334,,Saint-Denis-les-Ponts,Saint-Denis-les-Ponts,28,Eure-et-Loir,24,Centre-Val de Loire
28335,ST ELIPH,28240,ST ELIPH,,48.4496890449,1.03615954859,335,,Saint-Éliph,Saint-Éliph,28,Eure-et-Loir,24,Centre-Val de Loire
28336,ST EMAN,28120,ST EMAN,,48.3240677989,1.19980049404,336,,Saint-Éman,Saint-Éman,28,Eure-et-Loir,24,Centre-Val de Loire
28337,ST GEORGES SUR EURE,28190,ST GEORGES SUR EURE,,48.4195557033,1.35743485522,337,,Saint-Georges-sur-Eure,Saint-Georges-sur-Eure,28,Eure-et-Loir,24,Centre-Val de Loire
28339,ST GERMAIN LE GAILLARD,28190,ST GERMAIN LE GAILLARD,,48.4242025077,1.25461736967,339,,Saint-Germain-le-Gaillard,Saint-Germain-le-Gaillard,28,Eure-et-Loir,24,Centre-Val de Loire
28340,ST HILAIRE SUR YERRE,28220,ST HILAIRE SUR YERRE,,48.0311474511,1.21923079467,340,,Saint-Hilaire-sur-Yerre,Saint-Hilaire-sur-Yerre,28,Eure-et-Loir,24,Centre-Val de Loire
28341,ST JEAN DE REBERVILLIERS,28170,ST JEAN DE REBERVILLIERS,,48.6233222274,1.25762655972,341,,Saint-Jean-de-Rebervilliers,Saint-Jean-de-Rebervilliers,28,Eure-et-Loir,24,Centre-Val de Loire
28342,ST JEAN PIERRE FIXTE,28400,ST JEAN PIERRE FIXTE,,48.2924688955,0.830178092005,342,,Saint-Jean-Pierre-Fixte,Saint-Jean-Pierre-Fixte,28,Eure-et-Loir,24,Centre-Val de Loire
28343,ST LAURENT LA GATINE,28210,ST LAURENT LA GATINE,,48.6932133379,1.54011525092,343,,Saint-Laurent-la-Gâtine,Saint-Laurent-la-Gâtine,28,Eure-et-Loir,24,Centre-Val de Loire
28344,ST LEGER DES AUBEES,28700,ST LEGER DES AUBEES,,48.4142778005,1.74991232981,344,,Saint-Léger-des-Aubées,Saint-Léger-des-Aubées,28,Eure-et-Loir,24,Centre-Val de Loire
28346,ST LUBIN DE CRAVANT,28270,ST LUBIN DE CRAVANT,,48.7071767352,1.10138248239,346,,Saint-Lubin-de-Cravant,Saint-Lubin-de-Cravant,28,Eure-et-Loir,24,Centre-Val de Loire
28347,ST LUBIN DE LA HAYE,28410,ST LUBIN DE LA HAYE,,48.818368676,1.56052234981,347,,Saint-Lubin-de-la-Haye,Saint-Lubin-de-la-Haye,28,Eure-et-Loir,24,Centre-Val de Loire
28348,ST LUBIN DES JONCHERETS,28350,ST LUBIN DES JONCHERETS,,48.7509228383,1.18734034626,348,,Saint-Lubin-des-Joncherets,Saint-Lubin-des-Joncherets,28,Eure-et-Loir,24,Centre-Val de Loire
28349,ST LUCIEN,28210,ST LUCIEN,,48.6391626613,1.62330924951,349,,Saint-Lucien,Saint-Lucien,28,Eure-et-Loir,24,Centre-Val de Loire
28350,ST LUPERCE,28190,ST LUPERCE,,48.4372634991,1.30302415414,350,,Saint-Luperce,Saint-Luperce,28,Eure-et-Loir,24,Centre-Val de Loire
28351,ST MAIXME HAUTERIVE,28170,ST MAIXME HAUTERIVE,,48.5930292363,1.19005684059,351,,Saint-Maixme-Hauterive,Saint-Maixme-Hauterive,28,Eure-et-Loir,24,Centre-Val de Loire
28352,ST MARTIN DE NIGELLES,28130,ST MARTIN DE NIGELLES,,48.6131566659,1.6140648391,352,,Saint-Martin-de-Nigelles,Saint-Martin-de-Nigelles,28,Eure-et-Loir,24,Centre-Val de Loire
28353,ST MAUR SUR LE LOIR,28800,ST MAUR SUR LE LOIR,,48.1518179148,1.41832259921,353,,Saint-Maur-sur-le-Loir,Saint-Maur-sur-le-Loir,28,Eure-et-Loir,24,Centre-Val de Loire
28354,ST MAURICE ST GERMAIN,28240,ST MAURICE ST GERMAIN,,48.4900427542,1.07345601066,354,,Saint-Maurice-Saint-Germain,Saint-Maurice-Saint-Germain,28,Eure-et-Loir,24,Centre-Val de Loire
28355,ST OUEN MARCHEFROY,28260,ST OUEN MARCHEFROY,,48.8572176136,1.53020950146,355,,Saint-Ouen-Marchefroy,Saint-Ouen-Marchefroy,28,Eure-et-Loir,24,Centre-Val de Loire
28356,ST PELLERIN,28290,ST PELLERIN,,48.0724373117,1.14348881457,356,,Saint-Pellerin,Saint-Pellerin,28,Eure-et-Loir,24,Centre-Val de Loire
28357,ST PIAT,28130,ST PIAT,,48.5477876279,1.57936715019,357,,Saint-Piat,Saint-Piat,28,Eure-et-Loir,24,Centre-Val de Loire
28358,ST PREST,28300,ST PREST,,48.4920175304,1.5142471785,358,,Saint-Prest,Saint-Prest,28,Eure-et-Loir,24,Centre-Val de Loire
28359,ST REMY SUR AVRE,28380,ST REMY SUR AVRE,,48.7491986422,1.23713784044,359,,Saint-Rémy-sur-Avre,Saint-Rémy-sur-Avre,28,Eure-et-Loir,24,Centre-Val de Loire
28360,ST SAUVEUR MARVILLE,28170,ST SAUVEUR MARVILLE,,48.6047259778,1.2856796289,360,,Saint-Sauveur-Marville,Saint-Sauveur-Marville,28,Eure-et-Loir,24,Centre-Val de Loire
28360,ST SAUVEUR MARVILLE,28170,ST SAUVEUR MARVILLE,MARVILLE LES BOIS,48.6047259778,1.2856796289,360,,Saint-Sauveur-Marville,Saint-Sauveur-Marville,28,Eure-et-Loir,24,Centre-Val de Loire
28362,ST VICTOR DE BUTHON,28240,ST VICTOR DE BUTHON,,48.408433137,0.979282290732,362,,Saint-Victor-de-Buthon,Saint-Victor-de-Buthon,28,Eure-et-Loir,24,Centre-Val de Loire
28363,SAINVILLE,28700,SAINVILLE,,48.4153857478,1.88200624319,363,,Sainville,Sainville,28,Eure-et-Loir,24,Centre-Val de Loire
28364,SANCHEVILLE,28800,SANCHEVILLE,,48.1825259731,1.57949510769,364,,Sancheville,Sancheville,28,Eure-et-Loir,24,Centre-Val de Loire
28365,SANDARVILLE,28120,SANDARVILLE,,48.3458662386,1.34877776164,365,,Sandarville,Sandarville,28,Eure-et-Loir,24,Centre-Val de Loire
28366,SANTEUIL,28700,SANTEUIL,,48.3828802713,1.7401363101,366,,Santeuil,Santeuil,28,Eure-et-Loir,24,Centre-Val de Loire
28367,SANTILLY,28310,SANTILLY,,48.1486900011,1.87957339759,367,,Santilly,Santilly,28,Eure-et-Loir,24,Centre-Val de Loire
28368,LA SAUCELLE,28250,LA SAUCELLE,,48.6291051329,1.02634187849,368,La,Saucelle,La Saucelle,28,Eure-et-Loir,24,Centre-Val de Loire
28369,SAULNIERES,28500,SAULNIERES,,48.6650549297,1.26632253638,369,,Saulnières,Saulnières,28,Eure-et-Loir,24,Centre-Val de Loire
28370,SAUMERAY,28800,SAUMERAY,,48.2515939221,1.31885104886,370,,Saumeray,Saumeray,28,Eure-et-Loir,24,Centre-Val de Loire
28371,SAUSSAY,28260,SAUSSAY,,48.8508071385,1.41035357724,371,,Saussay,Saussay,28,Eure-et-Loir,24,Centre-Val de Loire
28372,SENANTES,28210,SENANTES,,48.6560652225,1.58443037368,372,,Senantes,Senantes,28,Eure-et-Loir,24,Centre-Val de Loire
28373,SENONCHES,28250,SENONCHES,,48.559589174,1.01905757079,373,,Senonches,Senonches,28,Eure-et-Loir,24,Centre-Val de Loire
28373,SENONCHES,28250,SENONCHES,LA VILLE AUX NONAINS,48.559589174,1.01905757079,373,,Senonches,Senonches,28,Eure-et-Loir,24,Centre-Val de Loire
28373,SENONCHES,28250,SENONCHES,TARDAIS,48.559589174,1.01905757079,373,,Senonches,Senonches,28,Eure-et-Loir,24,Centre-Val de Loire
28374,SERAZEREUX,28170,SERAZEREUX,,48.5949002026,1.43635782347,374,,Serazereux,Serazereux,28,Eure-et-Loir,24,Centre-Val de Loire
28375,SERVILLE,28410,SERVILLE,,48.7695206769,1.48740784688,375,,Serville,Serville,28,Eure-et-Loir,24,Centre-Val de Loire
28376,SOIZE,28330,SOIZE,,48.1691325251,0.890518984946,376,,Soizé,Soizé,28,Eure-et-Loir,24,Centre-Val de Loire
28377,SOREL MOUSSEL,28260,SOREL MOUSSEL,,48.8293779269,1.39390446206,377,,Sorel-Moussel,Sorel-Moussel,28,Eure-et-Loir,24,Centre-Val de Loire
28378,SOUANCE AU PERCHE,28400,SOUANCE AU PERCHE,,48.2707984058,0.834784803134,378,,Souancé-au-Perche,Souancé-au-Perche,28,Eure-et-Loir,24,Centre-Val de Loire
28379,SOULAIRES,28130,SOULAIRES,,48.5176795466,1.58912796204,379,,Soulaires,Soulaires,28,Eure-et-Loir,24,Centre-Val de Loire
28380,SOURS,28630,SOURS,,48.4134954931,1.59645296484,380,,Sours,Sours,28,Eure-et-Loir,24,Centre-Val de Loire
28382,TERMINIERS,28140,TERMINIERS,,48.0896452774,1.72961042495,382,,Terminiers,Terminiers,28,Eure-et-Loir,24,Centre-Val de Loire
28383,THEUVILLE,28150,THEUVILLE,PEZY,48.3326223196,1.59154245059,383,,Theuville,Theuville,28,Eure-et-Loir,24,Centre-Val de Loire
28383,THEUVILLE,28360,THEUVILLE,,48.3326223196,1.59154245059,383,,Theuville,Theuville,28,Eure-et-Loir,24,Centre-Val de Loire
28385,LE THIEULIN,28240,LE THIEULIN,,48.4073892468,1.13977357716,385,Le,Thieulin,Le Thieulin,28,Eure-et-Loir,24,Centre-Val de Loire
28386,THIMERT GATELLES,28170,THIMERT GATELLES,,48.55496534,1.26206894057,386,,Thimert-Gâtelles,Thimert-Gâtelles,28,Eure-et-Loir,24,Centre-Val de Loire
28386,THIMERT GATELLES,28170,THIMERT GATELLES,GATELLES,48.55496534,1.26206894057,386,,Thimert-Gâtelles,Thimert-Gâtelles,28,Eure-et-Loir,24,Centre-Val de Loire
28387,THIRON GARDAIS,28480,THIRON GARDAIS,,48.3047563926,1.01132470631,387,,Thiron-Gardais,Thiron-Gardais,28,Eure-et-Loir,24,Centre-Val de Loire
28388,THIVARS,28630,THIVARS,,48.3824570521,1.44888885738,388,,Thivars,Thivars,28,Eure-et-Loir,24,Centre-Val de Loire
28389,THIVILLE,28200,THIVILLE,,48.0192686469,1.36596589142,389,,Thiville,Thiville,28,Eure-et-Loir,24,Centre-Val de Loire
28390,TILLAY LE PENEUX,28140,TILLAY LE PENEUX,,48.1589221633,1.76370317108,390,,Tillay-le-Péneux,Tillay-le-Péneux,28,Eure-et-Loir,24,Centre-Val de Loire
28391,TOURY,28310,TOURY,,48.1954185813,1.94344855336,391,,Toury,Toury,28,Eure-et-Loir,24,Centre-Val de Loire
28392,TRANCRAINVILLE,28310,TRANCRAINVILLE,,48.2378117353,1.86150944495,392,,Trancrainville,Trancrainville,28,Eure-et-Loir,24,Centre-Val de Loire
28393,TREMBLAY LES VILLAGES,28170,TREMBLAY LES VILLAGES,,48.5881626072,1.36867843258,393,,Tremblay-les-Villages,Tremblay-les-Villages,28,Eure-et-Loir,24,Centre-Val de Loire
28393,TREMBLAY LES VILLAGES,28170,TREMBLAY LES VILLAGES,CHENE CHENU,48.5881626072,1.36867843258,393,,Tremblay-les-Villages,Tremblay-les-Villages,28,Eure-et-Loir,24,Centre-Val de Loire
28393,TREMBLAY LES VILLAGES,28170,TREMBLAY LES VILLAGES,ECUBLE,48.5881626072,1.36867843258,393,,Tremblay-les-Villages,Tremblay-les-Villages,28,Eure-et-Loir,24,Centre-Val de Loire
28393,TREMBLAY LES VILLAGES,28170,TREMBLAY LES VILLAGES,GIRONVILLE ET NEUVILLE,48.5881626072,1.36867843258,393,,Tremblay-les-Villages,Tremblay-les-Villages,28,Eure-et-Loir,24,Centre-Val de Loire
28393,TREMBLAY LES VILLAGES,28170,TREMBLAY LES VILLAGES,ST CHERON DES CHAMPS,48.5881626072,1.36867843258,393,,Tremblay-les-Villages,Tremblay-les-Villages,28,Eure-et-Loir,24,Centre-Val de Loire
28393,TREMBLAY LES VILLAGES,28170,TREMBLAY LES VILLAGES,THEUVY ACHERES,48.5881626072,1.36867843258,393,,Tremblay-les-Villages,Tremblay-les-Villages,28,Eure-et-Loir,24,Centre-Val de Loire
28394,TREON,28500,TREON,,48.6752456837,1.32503557014,394,,Tréon,Tréon,28,Eure-et-Loir,24,Centre-Val de Loire
28395,TRIZAY COUTRETOT ST SERGE,28400,TRIZAY COUTRETOT ST SERGE,,48.2928451299,0.878132374708,395,,Trizay-Coutretot-Saint-Serge,Trizay-Coutretot-Saint-Serge,28,Eure-et-Loir,24,Centre-Val de Loire
28396,TRIZAY LES BONNEVAL,28800,TRIZAY LES BONNEVAL,,48.1997904547,1.33237948177,396,,Trizay-lès-Bonneval,Trizay-lès-Bonneval,28,Eure-et-Loir,24,Centre-Val de Loire
28397,UMPEAU,28700,UMPEAU,,48.4684563155,1.66580333613,397,,Umpeau,Umpeau,28,Eure-et-Loir,24,Centre-Val de Loire
28398,UNVERRE,28160,UNVERRE,,48.188963533,1.07242438332,398,,Unverre,Unverre,28,Eure-et-Loir,24,Centre-Val de Loire
28400,VARIZE,28140,VARIZE,,48.082379258,1.52561665156,400,,Varize,Varize,28,Eure-et-Loir,24,Centre-Val de Loire
28401,VAUPILLON,28240,VAUPILLON,,48.4601431127,0.969676262145,401,,Vaupillon,Vaupillon,28,Eure-et-Loir,24,Centre-Val de Loire
28403,VER LES CHARTRES,28630,VER LES CHARTRES,,48.3780916809,1.48044063443,403,,Ver-lès-Chartres,Ver-lès-Chartres,28,Eure-et-Loir,24,Centre-Val de Loire
28404,VERNOUILLET,28500,VERNOUILLET,,48.7197917196,1.35089721897,404,,Vernouillet,Vernouillet,28,Eure-et-Loir,24,Centre-Val de Loire
28405,VERT EN DROUAIS,28500,VERT EN DROUAIS,,48.7510492874,1.29542201706,405,,Vert-en-Drouais,Vert-en-Drouais,28,Eure-et-Loir,24,Centre-Val de Loire
28406,EOLE EN BEAUCE,28140,EOLE EN BEAUCE,GERMIGNONVILLE,48.2158073726,1.69304337817,406,,Eole-en-Beauce,Eole-en-Beauce,28,Eure-et-Loir,24,Centre-Val de Loire
28406,EOLE EN BEAUCE,28150,EOLE EN BEAUCE,BAIGNOLET,48.2158073726,1.69304337817,406,,Eole-en-Beauce,Eole-en-Beauce,28,Eure-et-Loir,24,Centre-Val de Loire
28406,EOLE EN BEAUCE,28150,EOLE EN BEAUCE,FAINS LA FOLIE,48.2158073726,1.69304337817,406,,Eole-en-Beauce,Eole-en-Beauce,28,Eure-et-Loir,24,Centre-Val de Loire
28406,EOLE EN BEAUCE,28150,EOLE EN BEAUCE,VIABON,48.2158073726,1.69304337817,406,,Eole-en-Beauce,Eole-en-Beauce,28,Eure-et-Loir,24,Centre-Val de Loire
28407,VICHERES,28480,VICHERES,,48.2648756716,0.907051525279,407,,Vichères,Vichères,28,Eure-et-Loir,24,Centre-Val de Loire
28408,VIERVILLE,28700,VIERVILLE,,48.3843640334,1.90039348579,408,,Vierville,Vierville,28,Eure-et-Loir,24,Centre-Val de Loire
28409,VIEUVICQ,28120,VIEUVICQ,,48.2613605126,1.20658103343,409,,Vieuvicq,Vieuvicq,28,Eure-et-Loir,24,Centre-Val de Loire
28410,VILLAMPUY,28200,VILLAMPUY,,48.0405392356,1.511218856,410,,Villampuy,Villampuy,28,Eure-et-Loir,24,Centre-Val de Loire
28411,VILLARS,28150,VILLARS,,48.2311272458,1.54414100936,411,,Villars,Villars,28,Eure-et-Loir,24,Centre-Val de Loire
28412,VILLEAU,28150,VILLEAU,,48.2407735425,1.59750899742,412,,Villeau,Villeau,28,Eure-et-Loir,24,Centre-Val de Loire
28414,VILLEBON,28190,VILLEBON,,48.3895094771,1.19915993293,414,,Villebon,Villebon,28,Eure-et-Loir,24,Centre-Val de Loire
28415,VILLEMEUX SUR EURE,28210,VILLEMEUX SUR EURE,,48.6710644205,1.4573632348,415,,Villemeux-sur-Eure,Villemeux-sur-Eure,28,Eure-et-Loir,24,Centre-Val de Loire
28417,VILLIERS LE MORHIER,28130,VILLIERS LE MORHIER,,48.6211492274,1.57247991458,417,,Villiers-le-Morhier,Villiers-le-Morhier,28,Eure-et-Loir,24,Centre-Val de Loire
28418,VILLIERS ST ORIEN,28800,VILLIERS ST ORIEN,,48.1426331097,1.49462124785,418,,Villiers-Saint-Orien,Villiers-Saint-Orien,28,Eure-et-Loir,24,Centre-Val de Loire
28419,VITRAY EN BEAUCE,28360,VITRAY EN BEAUCE,,48.276512121,1.42369112578,419,,Vitray-en-Beauce,Vitray-en-Beauce,28,Eure-et-Loir,24,Centre-Val de Loire
28421,VOISE,28700,VOISE,,48.4021777408,1.70860045511,421,,Voise,Voise,28,Eure-et-Loir,24,Centre-Val de Loire
28422,LES VILLAGES VOVEENS,28150,LES VILLAGES VOVEENS,MONTAINVILLE,48.269861494,1.6311008246,422,Les,Villages Vovéens,Les Villages Vovéens,28,Eure-et-Loir,24,Centre-Val de Loire
28422,LES VILLAGES VOVEENS,28150,LES VILLAGES VOVEENS,ROUVRAY ST FLORENTIN,48.269861494,1.6311008246,422,Les,Villages Vovéens,Les Villages Vovéens,28,Eure-et-Loir,24,Centre-Val de Loire
28422,LES VILLAGES VOVEENS,28150,LES VILLAGES VOVEENS,VILLENEUVE ST NICOLAS,48.269861494,1.6311008246,422,Les,Villages Vovéens,Les Villages Vovéens,28,Eure-et-Loir,24,Centre-Val de Loire
28422,LES VILLAGES VOVEENS,28150,LES VILLAGES VOVEENS,VOVES,48.269861494,1.6311008246,422,Les,Villages Vovéens,Les Villages Vovéens,28,Eure-et-Loir,24,Centre-Val de Loire
28423,YERMENONVILLE,28130,YERMENONVILLE,,48.5487143528,1.62251535797,423,,Yermenonville,Yermenonville,28,Eure-et-Loir,24,Centre-Val de Loire
28424,YEVRES,28160,YEVRES,,48.1911088268,1.1911717339,424,,Yèvres,Yèvres,28,Eure-et-Loir,24,Centre-Val de Loire
28425,YMERAY,28320,YMERAY,,48.5089746618,1.7030629617,425,,Ymeray,Ymeray,28,Eure-et-Loir,24,Centre-Val de Loire
28426,YMONVILLE,28150,YMONVILLE,,48.2495983689,1.75055866938,426,,Ymonville,Ymonville,28,Eure-et-Loir,24,Centre-Val de Loire
29001,ARGOL,29560,ARGOL,,48.2496052712,-4.29867172624,1,,Argol,Argol,29,Finistère,53,Bretagne
29002,ARZANO,29300,ARZANO,,47.8927527459,-3.44326646496,2,,Arzano,Arzano,29,Finistère,53,Bretagne
29003,AUDIERNE,29770,AUDIERNE,,48.0241077574,-4.54448750165,3,,Audierne,Audierne,29,Finistère,53,Bretagne
29003,AUDIERNE,29770,AUDIERNE,ESQUIBIEN,48.0241077574,-4.54448750165,3,,Audierne,Audierne,29,Finistère,53,Bretagne
29004,BANNALEC,29380,BANNALEC,,47.9350863729,-3.69536960662,4,,Bannalec,Bannalec,29,Finistère,53,Bretagne
29005,BAYE,29300,BAYE,,47.8554611987,-3.61273705722,5,,Baye,Baye,29,Finistère,53,Bretagne
29006,BENODET,29950,BENODET,,47.8780985371,-4.07751294821,6,,Bénodet,Bénodet,29,Finistère,53,Bretagne
29007,BERRIEN,29690,BERRIEN,,48.4027305051,-3.76524444228,7,,Berrien,Berrien,29,Finistère,53,Bretagne
29008,BEUZEC CAP SIZUN,29790,BEUZEC CAP SIZUN,,48.0717542642,-4.50670939299,8,,Beuzec-Cap-Sizun,Beuzec-Cap-Sizun,29,Finistère,53,Bretagne
29010,BODILIS,29400,BODILIS,,48.5153978832,-4.12063096019,10,,Bodilis,Bodilis,29,Finistère,53,Bretagne
29011,BOHARS,29820,BOHARS,,48.4318157776,-4.52163441138,11,,Bohars,Bohars,29,Finistère,53,Bretagne
29012,BOLAZEC,29640,BOLAZEC,,48.4425412035,-3.59509375301,12,,Bolazec,Bolazec,29,Finistère,53,Bretagne
29013,BOTMEUR,29690,BOTMEUR,,48.3762281047,-3.92275613595,13,,Botmeur,Botmeur,29,Finistère,53,Bretagne
29014,BOTSORHEL,29650,BOTSORHEL,,48.5059126772,-3.62885625476,14,,Botsorhel,Botsorhel,29,Finistère,53,Bretagne
29015,BOURG BLANC,29860,BOURG BLANC,,48.4956224464,-4.49991805356,15,,Bourg-Blanc,Bourg-Blanc,29,Finistère,53,Bretagne
29016,BRASPARTS,29190,BRASPARTS,,48.319245053,-3.95010933279,16,,Brasparts,Brasparts,29,Finistère,53,Bretagne
29017,BRELES,29810,BRELES,,48.4709032483,-4.68640383039,17,,Brélès,Brélès,29,Finistère,53,Bretagne
29018,BRENNILIS,29690,BRENNILIS,,48.3596142812,-3.85052670212,18,,Brennilis,Brennilis,29,Finistère,53,Bretagne
29019,BREST,29200,BREST,,48.4004997828,-4.5027907853,19,,Brest,Brest,29,Finistère,53,Bretagne
29020,BRIEC,29510,BRIEC,,48.0935971343,-4.02078477146,20,,Briec,Briec,29,Finistère,53,Bretagne
29021,BRIGNOGAN PLAGE,29890,BRIGNOGAN PLAGE,,48.6673259175,-4.33395670694,21,,Plounéour-Brignogan-plages,Plounéour-Brignogan-plages,29,Finistère,53,Bretagne
29022,CAMARET SUR MER,29570,CAMARET SUR MER,,48.2683595403,-4.58763072295,22,,Camaret-sur-Mer,Camaret-sur-Mer,29,Finistère,53,Bretagne
29023,CARANTEC,29660,CARANTEC,,48.6553588661,-3.91293697247,23,,Carantec,Carantec,29,Finistère,53,Bretagne
29024,CARHAIX PLOUGUER,29270,CARHAIX PLOUGUER,,48.2687578823,-3.56626363851,24,,Carhaix-Plouguer,Carhaix-Plouguer,29,Finistère,53,Bretagne
29025,CAST,29150,CAST,,48.1490980732,-4.11928421519,25,,Cast,Cast,29,Finistère,53,Bretagne
29026,CHATEAULIN,29150,CHATEAULIN,,48.2032973995,-4.07350636234,26,,Châteaulin,Châteaulin,29,Finistère,53,Bretagne
29027,CHATEAUNEUF DU FAOU,29520,CHATEAUNEUF DU FAOU,,48.1889732859,-3.82126129764,27,,Châteauneuf-du-Faou,Châteauneuf-du-Faou,29,Finistère,53,Bretagne
29028,CLEDEN CAP SIZUN,29770,CLEDEN CAP SIZUN,,48.0539041456,-4.65683195318,28,,Cléden-Cap-Sizun,Cléden-Cap-Sizun,29,Finistère,53,Bretagne
29029,CLEDEN POHER,29270,CLEDEN POHER,,48.2430990086,-3.66446573212,29,,Cléden-Poher,Cléden-Poher,29,Finistère,53,Bretagne
29030,CLEDER,29233,CLEDER,,48.6605439147,-4.12447333857,30,,Cléder,Cléder,29,Finistère,53,Bretagne
29031,CLOHARS CARNOET,29360,CLOHARS CARNOET,,47.7943407144,-3.56681364982,31,,Clohars-Carnoët,Clohars-Carnoët,29,Finistère,53,Bretagne
29031,CLOHARS CARNOET,29360,CLOHARS CARNOET,LE POULDU,47.7943407144,-3.56681364982,31,,Clohars-Carnoët,Clohars-Carnoët,29,Finistère,53,Bretagne
29032,CLOHARS FOUESNANT,29950,CLOHARS FOUESNANT,,47.8977205626,-4.08306379638,32,,Clohars-Fouesnant,Clohars-Fouesnant,29,Finistère,53,Bretagne
29033,LE CLOITRE PLEYBEN,29190,LE CLOITRE PLEYBEN,,48.2580934437,-3.89646330855,33,Le,Cloître-Pleyben,Le Cloître-Pleyben,29,Finistère,53,Bretagne
29034,LE CLOITRE ST THEGONNEC,29410,LE CLOITRE ST THEGONNEC,,48.4598098914,-3.79230371205,34,Le,Cloître-Saint-Thégonnec,Le Cloître-Saint-Thégonnec,29,Finistère,53,Bretagne
29035,COAT MEAL,29870,COAT MEAL,,48.5056460194,-4.53993489301,35,,Coat-Méal,Coat-Méal,29,Finistère,53,Bretagne
29036,COLLOREC,29530,COLLOREC,,48.2916222474,-3.77150132255,36,,Collorec,Collorec,29,Finistère,53,Bretagne
29037,COMBRIT,29120,COMBRIT,,47.8868597702,-4.16414997559,37,,Combrit,Combrit,29,Finistère,53,Bretagne
29038,COMMANA,29450,COMMANA,,48.4102306361,-3.97012687185,38,,Commana,Commana,29,Finistère,53,Bretagne
29039,CONCARNEAU,29900,CONCARNEAU,,47.8966260003,-3.90716871821,39,,Concarneau,Concarneau,29,Finistère,53,Bretagne
29040,LE CONQUET,29217,LE CONQUET,,48.3555977933,-4.76234204122,40,Le,Conquet,Le Conquet,29,Finistère,53,Bretagne
29041,CORAY,29370,CORAY,,48.0658709885,-3.85508306366,41,,Coray,Coray,29,Finistère,53,Bretagne
29042,CROZON,29160,CROZON,,48.2449715382,-4.49170347769,42,,Crozon,Crozon,29,Finistère,53,Bretagne
29042,CROZON,29160,CROZON,LE FRET,48.2449715382,-4.49170347769,42,,Crozon,Crozon,29,Finistère,53,Bretagne
29042,CROZON,29160,CROZON,MORGAT,48.2449715382,-4.49170347769,42,,Crozon,Crozon,29,Finistère,53,Bretagne
29043,DAOULAS,29460,DAOULAS,,48.3556171756,-4.26327586414,43,,Daoulas,Daoulas,29,Finistère,53,Bretagne
29044,DINEAULT,29150,DINEAULT,,48.2166455285,-4.16674767247,44,,Dinéault,Dinéault,29,Finistère,53,Bretagne
29045,DIRINON,29460,DIRINON,,48.4011232723,-4.26930832464,45,,Dirinon,Dirinon,29,Finistère,53,Bretagne
29046,DOUARNENEZ,29100,DOUARNENEZ,,48.0806526556,-4.32784220122,46,,Douarnenez,Douarnenez,29,Finistère,53,Bretagne
29046,DOUARNENEZ,29100,DOUARNENEZ,TREBOUL,48.0806526556,-4.32784220122,46,,Douarnenez,Douarnenez,29,Finistère,53,Bretagne
29047,LE DRENNEC,29860,LE DRENNEC,,48.5330700302,-4.38126617487,47,Le,Drennec,Le Drennec,29,Finistère,53,Bretagne
29048,EDERN,29510,EDERN,,48.1199968279,-3.96022667408,48,,Edern,Edern,29,Finistère,53,Bretagne
29049,ELLIANT,29370,ELLIANT,,48.0078908681,-3.90263136694,49,,Elliant,Elliant,29,Finistère,53,Bretagne
29051,ERGUE GABERIC,29500,ERGUE GABERIC,,48.0120306692,-4.0074505723,51,,Ergué-Gabéric,Ergué-Gabéric,29,Finistère,53,Bretagne
29053,LE FAOU,29590,LE FAOU,,48.3059334041,-4.1421494556,53,Le,Faou,Le Faou,29,Finistère,53,Bretagne
29054,LA FEUILLEE,29690,LA FEUILLEE,,48.391234337,-3.85236345367,54,La,Feuillée,La Feuillée,29,Finistère,53,Bretagne
29055,LE FOLGOET,29260,LE FOLGOET,,48.5666216124,-4.35251906312,55,Le,Folgoët,Le Folgoët,29,Finistère,53,Bretagne
29056,LA FOREST LANDERNEAU,29800,LA FOREST LANDERNEAU,,48.4342711427,-4.31491864921,56,La,Forest-Landerneau,La Forest-Landerneau,29,Finistère,53,Bretagne
29057,LA FORET FOUESNANT,29940,LA FORET FOUESNANT,,47.9201547936,-3.96862628403,57,La,Forêt-Fouesnant,La Forêt-Fouesnant,29,Finistère,53,Bretagne
29058,FOUESNANT,29170,FOUESNANT,,47.8783078862,-4.01834628474,58,,Fouesnant,Fouesnant,29,Finistère,53,Bretagne
29058,FOUESNANT,29170,FOUESNANT,BEGMEIL,47.8783078862,-4.01834628474,58,,Fouesnant,Fouesnant,29,Finistère,53,Bretagne
29059,GARLAN,29610,GARLAN,,48.6042377038,-3.766678483,59,,Garlan,Garlan,29,Finistère,53,Bretagne
29060,GOUESNACH,29950,GOUESNACH,,47.9170974917,-4.11118575976,60,,Gouesnach,Gouesnach,29,Finistère,53,Bretagne
29061,GOUESNOU,29850,GOUESNOU,,48.446717951,-4.46327934378,61,,Gouesnou,Gouesnou,29,Finistère,53,Bretagne
29062,GOUEZEC,29190,GOUEZEC,,48.1700681159,-3.97240970472,62,,Gouézec,Gouézec,29,Finistère,53,Bretagne
29063,GOULIEN,29770,GOULIEN,,48.0579729785,-4.59260441586,63,,Goulien,Goulien,29,Finistère,53,Bretagne
29064,GOULVEN,29890,GOULVEN,,48.6242161678,-4.29555054376,64,,Goulven,Goulven,29,Finistère,53,Bretagne
29065,GOURLIZON,29710,GOURLIZON,,48.0256773072,-4.2699574074,65,,Gourlizon,Gourlizon,29,Finistère,53,Bretagne
29066,GUENGAT,29180,GUENGAT,,48.0409767863,-4.19583398149,66,,Guengat,Guengat,29,Finistère,53,Bretagne
29067,GUERLESQUIN,29650,GUERLESQUIN,,48.5315286912,-3.58783458581,67,,Guerlesquin,Guerlesquin,29,Finistère,53,Bretagne
29068,GUICLAN,29410,GUICLAN,,48.5431945117,-3.97996345952,68,,Guiclan,Guiclan,29,Finistère,53,Bretagne
29069,GUILERS,29820,GUILERS,,48.4189328656,-4.56200695639,69,,Guilers,Guilers,29,Finistère,53,Bretagne
29070,GUILER SUR GOYEN,29710,GUILER SUR GOYEN,,48.0170524015,-4.36131542798,70,,Guiler-sur-Goyen,Guiler-sur-Goyen,29,Finistère,53,Bretagne
29071,GUILLIGOMARC H,29300,GUILLIGOMARC H,,47.9433635346,-3.42652509453,71,,Guilligomarc'h,Guilligomarc'h,29,Finistère,53,Bretagne
29072,GUILVINEC,29730,GUILVINEC,,47.8012448483,-4.28617785695,72,,Guilvinec,Guilvinec,29,Finistère,53,Bretagne
29073,GUIMAEC,29620,GUIMAEC,,48.6787759026,-3.7057088823,73,,Guimaëc,Guimaëc,29,Finistère,53,Bretagne
29074,GUIMILIAU,29400,GUIMILIAU,,48.4813913832,-3.99675935444,74,,Guimiliau,Guimiliau,29,Finistère,53,Bretagne
29075,GUIPAVAS,29490,GUIPAVAS,,48.4307008294,-4.39621450084,75,,Guipavas,Guipavas,29,Finistère,53,Bretagne
29076,GUIPRONVEL,29290,GUIPRONVEL,,48.4913026079,-4.56960723712,76,,Milizac-Guipronvel,Milizac-Guipronvel,29,Finistère,53,Bretagne
29077,GUISSENY,29880,GUISSENY,,48.6169101259,-4.41110953523,77,,Guissény,Guissény,29,Finistère,53,Bretagne
29078,HANVEC,29460,HANVEC,,48.330060559,-4.13907964389,78,,Hanvec,Hanvec,29,Finistère,53,Bretagne
29079,HENVIC,29670,HENVIC,,48.6328783034,-3.93010149284,79,,Henvic,Henvic,29,Finistère,53,Bretagne
29080,HOPITAL CAMFROUT,29460,L HOPITAL CAMFROUT,,48.3241129491,-4.23905082012,80,,Hôpital-Camfrout,Hôpital-Camfrout,29,Finistère,53,Bretagne
29081,HUELGOAT,29690,HUELGOAT,,48.3615514061,-3.75817537147,81,,Huelgoat,Huelgoat,29,Finistère,53,Bretagne
29082,ILE DE BATZ,29253,ILE DE BATZ,,48.7453786907,-4.01403204565,82,,Île-de-Batz,Île-de-Batz,29,Finistère,53,Bretagne
29083,ILE DE SEIN,29990,ILE DE SEIN,,48.0384480983,-4.8577259366,83,,Île-de-Sein,Île-de-Sein,29,Finistère,53,Bretagne
29084,ILE MOLENE,29259,ILE MOLENE,,48.3954039142,-4.96040618355,84,,Île-Molène,Île-Molène,29,Finistère,53,Bretagne
29085,ILE TUDY,29980,ILE TUDY,,47.8527643575,-4.16160884308,85,,Île-Tudy,Île-Tudy,29,Finistère,53,Bretagne
29086,IRVILLAC,29460,IRVILLAC,,48.3668454247,-4.20163770975,86,,Irvillac,Irvillac,29,Finistère,53,Bretagne
29087,LE JUCH,29100,LE JUCH,,48.0555333379,-4.2656946244,87,Le,Juch,Le Juch,29,Finistère,53,Bretagne
29089,KERGLOFF,29270,KERGLOFF,,48.2804454858,-3.64568859404,89,,Kergloff,Kergloff,29,Finistère,53,Bretagne
29090,KERLAZ,29100,KERLAZ,,48.0912560808,-4.26315829954,90,,Kerlaz,Kerlaz,29,Finistère,53,Bretagne
29091,KERLOUAN,29890,KERLOUAN,,48.6474993428,-4.37874276667,91,,Kerlouan,Kerlouan,29,Finistère,53,Bretagne
29093,KERNILIS,29260,KERNILIS,,48.5750998545,-4.41083287138,93,,Kernilis,Kernilis,29,Finistère,53,Bretagne
29094,KERNOUES,29260,KERNOUES,,48.5939427293,-4.34472609199,94,,Kernouës,Kernouës,29,Finistère,53,Bretagne
29095,KERSAINT PLABENNEC,29860,KERSAINT PLABENNEC,,48.4792039548,-4.36259963569,95,,Kersaint-Plabennec,Kersaint-Plabennec,29,Finistère,53,Bretagne
29097,LAMPAUL GUIMILIAU,29400,LAMPAUL GUIMILIAU,,48.4836867404,-4.04069753915,97,,Lampaul-Guimiliau,Lampaul-Guimiliau,29,Finistère,53,Bretagne
29098,LAMPAUL PLOUARZEL,29810,LAMPAUL PLOUARZEL,,48.4502704596,-4.76463637416,98,,Lampaul-Plouarzel,Lampaul-Plouarzel,29,Finistère,53,Bretagne
29099,LAMPAUL PLOUDALMEZEAU,29830,LAMPAUL PLOUDALMEZEAU,,48.5533742758,-4.64744640703,99,,Lampaul-Ploudalmézeau,Lampaul-Ploudalmézeau,29,Finistère,53,Bretagne
29100,LANARVILY,29260,LANARVILY,,48.5609189419,-4.38280376453,100,,Lanarvily,Lanarvily,29,Finistère,53,Bretagne
29101,LANDEDA,29870,LANDEDA,,48.5889449364,-4.57696807959,101,,Landéda,Landéda,29,Finistère,53,Bretagne
29101,LANDEDA,29870,LANDEDA,ABERWRACH,48.5889449364,-4.57696807959,101,,Landéda,Landéda,29,Finistère,53,Bretagne
29102,LANDELEAU,29530,LANDELEAU,,48.2527721686,-3.73269779844,102,,Landeleau,Landeleau,29,Finistère,53,Bretagne
29103,LANDERNEAU,29800,LANDERNEAU,,48.4507528968,-4.2643453824,103,,Landerneau,Landerneau,29,Finistère,53,Bretagne
29104,LANDEVENNEC,29560,LANDEVENNEC,,48.2809218863,-4.31254922353,104,,Landévennec,Landévennec,29,Finistère,53,Bretagne
29105,LANDIVISIAU,29400,LANDIVISIAU,,48.5160095172,-4.06130712731,105,,Landivisiau,Landivisiau,29,Finistère,53,Bretagne
29106,LANDREVARZEC,29510,LANDREVARZEC,,48.0895670142,-4.0756482989,106,,Landrévarzec,Landrévarzec,29,Finistère,53,Bretagne
29107,LANDUDAL,29510,LANDUDAL,,48.0564737827,-3.97703879663,107,,Landudal,Landudal,29,Finistère,53,Bretagne
29108,LANDUDEC,29710,LANDUDEC,,47.995931967,-4.32763243057,108,,Landudec,Landudec,29,Finistère,53,Bretagne
29109,LANDUNVEZ,29840,LANDUNVEZ,,48.5343990673,-4.72936031932,109,,Landunvez,Landunvez,29,Finistère,53,Bretagne
29110,LANGOLEN,29510,LANGOLEN,,48.0746211195,-3.91654516046,110,,Langolen,Langolen,29,Finistère,53,Bretagne
29111,LANHOUARNEAU,29430,LANHOUARNEAU,,48.5756574548,-4.20447791215,111,,Lanhouarneau,Lanhouarneau,29,Finistère,53,Bretagne
29112,LANILDUT,29840,LANILDUT,,48.4817500302,-4.73355077306,112,,Lanildut,Lanildut,29,Finistère,53,Bretagne
29113,LANMEUR,29620,LANMEUR,,48.6422856772,-3.72150726113,113,,Lanmeur,Lanmeur,29,Finistère,53,Bretagne
29114,LANNEANOU,29640,LANNEANOU,,48.4980971712,-3.66516986251,114,,Lannéanou,Lannéanou,29,Finistère,53,Bretagne
29115,LANNEDERN,29190,LANNEDERN,,48.2892302936,-3.90201748494,115,,Lannédern,Lannédern,29,Finistère,53,Bretagne
29116,LANNEUFFRET,29400,LANNEUFFRET,,48.49529484,-4.20213358144,116,,Lanneuffret,Lanneuffret,29,Finistère,53,Bretagne
29117,LANNILIS,29870,LANNILIS,,48.569680781,-4.52393031535,117,,Lannilis,Lannilis,29,Finistère,53,Bretagne
29119,LANRIVOARE,29290,LANRIVOARE,,48.4660390019,-4.63526600246,119,,Lanrivoaré,Lanrivoaré,29,Finistère,53,Bretagne
29120,LANVEOC,29160,LANVEOC,,48.275777582,-4.44432214727,120,,Lanvéoc,Lanvéoc,29,Finistère,53,Bretagne
29122,LAZ,29520,LAZ,,48.135398381,-3.83990955332,122,,Laz,Laz,29,Finistère,53,Bretagne
29123,LENNON,29190,LENNON,,48.2050048931,-3.90339473931,123,,Lennon,Lennon,29,Finistère,53,Bretagne
29124,LESNEVEN,29260,LESNEVEN,,48.5801251196,-4.31293757639,124,,Lesneven,Lesneven,29,Finistère,53,Bretagne
29125,LEUHAN,29390,LEUHAN,,48.1006333351,-3.77816667644,125,,Leuhan,Leuhan,29,Finistère,53,Bretagne
29126,LOC BREVALAIRE,29260,LOC BREVALAIRE,,48.5541583182,-4.40680890796,126,,Loc-Brévalaire,Loc-Brévalaire,29,Finistère,53,Bretagne
29128,LOC EGUINER,29400,LOC EGUINER,,48.4763432198,-4.09270685013,128,,Loc-Eguiner,Loc-Eguiner,29,Finistère,53,Bretagne
29129,LOCMARIA BERRIEN,29690,LOCMARIA BERRIEN,,48.3580778048,-3.70022410218,129,,Locmaria-Berrien,Locmaria-Berrien,29,Finistère,53,Bretagne
29130,LOCMARIA PLOUZANE,29280,LOCMARIA PLOUZANE,,48.3707952955,-4.65000050558,130,,Locmaria-Plouzané,Locmaria-Plouzané,29,Finistère,53,Bretagne
29131,LOCMELAR,29400,LOCMELAR,,48.450277854,-4.05818755102,131,,Locmélar,Locmélar,29,Finistère,53,Bretagne
29132,LOCQUENOLE,29670,LOCQUENOLE,,48.6234794201,-3.86347353268,132,,Locquénolé,Locquénolé,29,Finistère,53,Bretagne
29133,LOCQUIREC,29241,LOCQUIREC,,48.6831366795,-3.66753133845,133,,Locquirec,Locquirec,29,Finistère,53,Bretagne
29134,LOCRONAN,29180,LOCRONAN,,48.0978281716,-4.21322277368,134,,Locronan,Locronan,29,Finistère,53,Bretagne
29135,LOCTUDY,29750,LOCTUDY,,47.8239824718,-4.1873881827,135,,Loctudy,Loctudy,29,Finistère,53,Bretagne
29136,LOCUNOLE,29310,LOCUNOLE,,47.9243454961,-3.48583985781,136,,Locunolé,Locunolé,29,Finistère,53,Bretagne
29137,LOGONNA DAOULAS,29460,LOGONNA DAOULAS,,48.3286587483,-4.28895101549,137,,Logonna-Daoulas,Logonna-Daoulas,29,Finistère,53,Bretagne
29139,LOPEREC,29590,LOPEREC,,48.3017361102,-4.03428132272,139,,Lopérec,Lopérec,29,Finistère,53,Bretagne
29140,LOPERHET,29470,LOPERHET,,48.3840209584,-4.31381265183,140,,Loperhet,Loperhet,29,Finistère,53,Bretagne
29141,LOQUEFFRET,29530,LOQUEFFRET,,48.3294972267,-3.85916862997,141,,Loqueffret,Loqueffret,29,Finistère,53,Bretagne
29142,LOTHEY,29190,LOTHEY,,48.1833807935,-4.02633181773,142,,Lothey,Lothey,29,Finistère,53,Bretagne
29143,MAHALON,29790,MAHALON,,48.0273634035,-4.41897227168,143,,Mahalon,Mahalon,29,Finistère,53,Bretagne
29144,LA MARTYRE,29800,LA MARTYRE,,48.4367154083,-4.17012102648,144,La,Martyre,La Martyre,29,Finistère,53,Bretagne
29145,CONFORT MEILARS,29790,CONFORT MEILARS,,48.0555503715,-4.43487649608,145,,Confort-Meilars,Confort-Meilars,29,Finistère,53,Bretagne
29146,MELGVEN,29140,MELGVEN,,47.9129471121,-3.84206992486,146,,Melgven,Melgven,29,Finistère,53,Bretagne
29147,MELLAC,29300,MELLAC,,47.8984536688,-3.58813673533,147,,Mellac,Mellac,29,Finistère,53,Bretagne
29148,MESPAUL,29420,MESPAUL,,48.6146743886,-4.0326431567,148,,Mespaul,Mespaul,29,Finistère,53,Bretagne
29149,MILIZAC,29290,MILIZAC,,48.4584867435,-4.56837656618,149,,Milizac,Milizac,29,Finistère,53,Bretagne
29150,MOELAN SUR MER,29350,MOELAN SUR MER,,47.8061076978,-3.64546918043,150,,Moëlan-sur-Mer,Moëlan-sur-Mer,29,Finistère,53,Bretagne
29150,MOELAN SUR MER,29350,MOELAN SUR MER,KERFANY LES PINS,47.8061076978,-3.64546918043,150,,Moëlan-sur-Mer,Moëlan-sur-Mer,29,Finistère,53,Bretagne
29151,MORLAIX,29600,MORLAIX,,48.5996746612,-3.82052019963,151,,Morlaix,Morlaix,29,Finistère,53,Bretagne
29152,MOTREFF,29270,MOTREFF,,48.2197857067,-3.56230710183,152,,Motreff,Motreff,29,Finistère,53,Bretagne
29153,NEVEZ,29920,NEVEZ,,47.8154082656,-3.77625131302,153,,Névez,Névez,29,Finistère,53,Bretagne
29153,NEVEZ,29920,NEVEZ,PORT MANECH,47.8154082656,-3.77625131302,153,,Névez,Névez,29,Finistère,53,Bretagne
29155,OUESSANT,29242,ILE D OUESSANT,,48.4601042319,-5.08534533544,155,,Ouessant,Ouessant,29,Finistère,53,Bretagne
29156,PENCRAN,29800,PENCRAN,,48.4436093794,-4.22125825174,156,,Pencran,Pencran,29,Finistère,53,Bretagne
29158,PENMARCH,29760,PENMARCH,,47.8121478804,-4.34003367039,158,,Penmarch,Penmarch,29,Finistère,53,Bretagne
29158,PENMARCH,29760,PENMARCH,ST GUENOLE,47.8121478804,-4.34003367039,158,,Penmarch,Penmarch,29,Finistère,53,Bretagne
29159,PEUMERIT,29710,PEUMERIT,,47.9464369545,-4.29604672323,159,,Peumerit,Peumerit,29,Finistère,53,Bretagne
29160,PLABENNEC,29860,PLABENNEC,,48.4938327293,-4.41894214636,160,,Plabennec,Plabennec,29,Finistère,53,Bretagne
29161,PLEUVEN,29170,PLEUVEN,,47.9151360356,-4.04624585302,161,,Pleuven,Pleuven,29,Finistère,53,Bretagne
29162,PLEYBEN,29190,PLEYBEN,,48.2381882091,-3.97526740601,162,,Pleyben,Pleyben,29,Finistère,53,Bretagne
29163,PLEYBER CHRIST,29410,PLEYBER CHRIST,,48.5106294625,-3.87174749946,163,,Pleyber-Christ,Pleyber-Christ,29,Finistère,53,Bretagne
29165,PLOBANNALEC LESCONIL,29740,PLOBANNALEC LESCONIL,,47.8217825314,-4.22818604821,165,,Plobannalec-Lesconil,Plobannalec-Lesconil,29,Finistère,53,Bretagne
29165,PLOBANNALEC LESCONIL,29740,PLOBANNALEC LESCONIL,LESCONIL,47.8217825314,-4.22818604821,165,,Plobannalec-Lesconil,Plobannalec-Lesconil,29,Finistère,53,Bretagne
29166,PLOEVEN,29550,PLOEVEN,,48.1580700444,-4.21291088253,166,,Ploéven,Ploéven,29,Finistère,53,Bretagne
29167,PLOGASTEL ST GERMAIN,29710,PLOGASTEL ST GERMAIN,,47.9772934585,-4.26371081296,167,,Plogastel-Saint-Germain,Plogastel-Saint-Germain,29,Finistère,53,Bretagne
29168,PLOGOFF,29770,PLOGOFF,,48.0349240899,-4.68065084033,168,,Plogoff,Plogoff,29,Finistère,53,Bretagne
29169,PLOGONNEC,29180,PLOGONNEC,,48.0728440242,-4.1591436628,169,,Plogonnec,Plogonnec,29,Finistère,53,Bretagne
29170,PLOMELIN,29700,PLOMELIN,,47.9356261766,-4.15034793937,170,,Plomelin,Plomelin,29,Finistère,53,Bretagne
29171,PLOMEUR,29120,PLOMEUR,,47.8382971548,-4.29445064417,171,,Plomeur,Plomeur,29,Finistère,53,Bretagne
29172,PLOMODIERN,29550,PLOMODIERN,,48.1808861007,-4.21781568947,172,,Plomodiern,Plomodiern,29,Finistère,53,Bretagne
29173,PLONEIS,29710,PLONEIS,,48.0113210792,-4.20934732708,173,,Plonéis,Plonéis,29,Finistère,53,Bretagne
29174,PLONEOUR LANVERN,29720,PLONEOUR LANVERN,,47.9084114652,-4.26536047982,174,,Plonéour-Lanvern,Plonéour-Lanvern,29,Finistère,53,Bretagne
29175,PLONEVEZ DU FAOU,29530,PLONEVEZ DU FAOU,,48.2558447304,-3.82335085497,175,,Plonévez-du-Faou,Plonévez-du-Faou,29,Finistère,53,Bretagne
29176,PLONEVEZ PORZAY,29550,PLONEVEZ PORZAY,,48.1272296746,-4.23927606607,176,,Plonévez-Porzay,Plonévez-Porzay,29,Finistère,53,Bretagne
29177,PLOUARZEL,29810,PLOUARZEL,,48.4388139541,-4.72098791588,177,,Plouarzel,Plouarzel,29,Finistère,53,Bretagne
29178,PLOUDALMEZEAU,29830,PLOUDALMEZEAU,,48.5380194426,-4.66954529014,178,,Ploudalmézeau,Ploudalmézeau,29,Finistère,53,Bretagne
29178,PLOUDALMEZEAU,29830,PLOUDALMEZEAU,PORTSALL,48.5380194426,-4.66954529014,178,,Ploudalmézeau,Ploudalmézeau,29,Finistère,53,Bretagne
29179,PLOUDANIEL,29260,PLOUDANIEL,,48.5230558972,-4.31368733592,179,,Ploudaniel,Ploudaniel,29,Finistère,53,Bretagne
29180,PLOUDIRY,29800,PLOUDIRY,,48.4572473494,-4.12922591839,180,,Ploudiry,Ploudiry,29,Finistère,53,Bretagne
29181,PLOUEDERN,29800,PLOUEDERN,,48.4827412998,-4.25245153196,181,,Plouédern,Plouédern,29,Finistère,53,Bretagne
29182,PLOUEGAT GUERAND,29620,PLOUEGAT GUERAND,,48.621971426,-3.6705039462,182,,Plouégat-Guérand,Plouégat-Guérand,29,Finistère,53,Bretagne
29183,PLOUEGAT MOYSAN,29650,PLOUEGAT MOYSAN,,48.5695720823,-3.61681684116,183,,Plouégat-Moysan,Plouégat-Moysan,29,Finistère,53,Bretagne
29184,PLOUENAN,29420,PLOUENAN,,48.6212293904,-3.981458033,184,,Plouénan,Plouénan,29,Finistère,53,Bretagne
29185,PLOUESCAT,29430,PLOUESCAT,,48.6657604594,-4.17989213924,185,,Plouescat,Plouescat,29,Finistère,53,Bretagne
29186,PLOUEZOC H,29252,PLOUEZOC H,,48.6370431885,-3.81553533985,186,,Plouezoc'h,Plouezoc'h,29,Finistère,53,Bretagne
29187,PLOUGAR,29440,PLOUGAR,,48.5563599197,-4.12888327343,187,,Plougar,Plougar,29,Finistère,53,Bretagne
29188,PLOUGASNOU,29630,PLOUGASNOU,,48.6775667227,-3.8123079305,188,,Plougasnou,Plougasnou,29,Finistère,53,Bretagne
29188,PLOUGASNOU,29630,PLOUGASNOU,PRIMEL TREGASTEL,48.6775667227,-3.8123079305,188,,Plougasnou,Plougasnou,29,Finistère,53,Bretagne
29189,PLOUGASTEL DAOULAS,29470,PLOUGASTEL DAOULAS,,48.3601932144,-4.38292105021,189,,Plougastel-Daoulas,Plougastel-Daoulas,29,Finistère,53,Bretagne
29190,PLOUGONVELIN,29217,PLOUGONVELIN,,48.3513274854,-4.71760872698,190,,Plougonvelin,Plougonvelin,29,Finistère,53,Bretagne
29190,PLOUGONVELIN,29217,PLOUGONVELIN,ST MATHIEU,48.3513274854,-4.71760872698,190,,Plougonvelin,Plougonvelin,29,Finistère,53,Bretagne
29191,PLOUGONVEN,29640,PLOUGONVEN,,48.5052769563,-3.72721201852,191,,Plougonven,Plougonven,29,Finistère,53,Bretagne
29192,PLOUGOULM,29250,PLOUGOULM,,48.655920654,-4.04176176078,192,,Plougoulm,Plougoulm,29,Finistère,53,Bretagne
29193,PLOUGOURVEST,29400,PLOUGOURVEST,,48.5535956493,-4.07375029221,193,,Plougourvest,Plougourvest,29,Finistère,53,Bretagne
29195,PLOUGUERNEAU,29880,PLOUGUERNEAU,,48.6030672193,-4.48961925754,195,,Plouguerneau,Plouguerneau,29,Finistère,53,Bretagne
29196,PLOUGUIN,29830,PLOUGUIN,,48.52926645,-4.59194113774,196,,Plouguin,Plouguin,29,Finistère,53,Bretagne
29197,PLOUHINEC,29780,PLOUHINEC,,48.0137327427,-4.48457090531,197,,Plouhinec,Plouhinec,29,Finistère,53,Bretagne
29198,PLOUIDER,29260,PLOUIDER,,48.602186011,-4.29367754752,198,,Plouider,Plouider,29,Finistère,53,Bretagne
29199,PLOUIGNEAU,29610,PLOUIGNEAU,,48.5736126699,-3.7040741716,199,,Plouigneau,Plouigneau,29,Finistère,53,Bretagne
29201,PLOUMOGUER,29810,PLOUMOGUER,,48.3995302533,-4.71529077573,201,,Ploumoguer,Ploumoguer,29,Finistère,53,Bretagne
29202,PLOUNEOUR MENEZ,29410,PLOUNEOUR MENEZ,,48.4451584128,-3.88112677396,202,,Plounéour-Ménez,Plounéour-Ménez,29,Finistère,53,Bretagne
29203,PLOUNEOUR TREZ,29890,PLOUNEOUR TREZ,,48.6478999131,-4.3294006233,203,,Plounéour-Trez,Plounéour-Trez,29,Finistère,53,Bretagne
29204,PLOUNEVENTER,29400,PLOUNEVENTER,,48.5209452812,-4.2090017471,204,,Plounéventer,Plounéventer,29,Finistère,53,Bretagne
29205,PLOUNEVEZEL,29270,PLOUNEVEZEL,,48.3124244266,-3.57451157796,205,,Plounévézel,Plounévézel,29,Finistère,53,Bretagne
29206,PLOUNEVEZ LOCHRIST,29430,PLOUNEVEZ LOCHRIST,,48.6198454528,-4.19874644192,206,,Plounévez-Lochrist,Plounévez-Lochrist,29,Finistère,53,Bretagne
29207,PLOURIN LES MORLAIX,29600,PLOURIN LES MORLAIX,,48.5219190432,-3.80057587276,207,,Plourin-lès-Morlaix,Plourin-lès-Morlaix,29,Finistère,53,Bretagne
29208,PLOURIN,29830,PLOURIN,,48.503013638,-4.68383669582,208,,Plourin,Plourin,29,Finistère,53,Bretagne
29209,PLOUVIEN,29860,PLOUVIEN,,48.5390737689,-4.46505517448,209,,Plouvien,Plouvien,29,Finistère,53,Bretagne
29210,PLOUVORN,29420,PLOUVORN,,48.5765978002,-4.02210161181,210,,Plouvorn,Plouvorn,29,Finistère,53,Bretagne
29211,PLOUYE,29690,PLOUYE,,48.3207546118,-3.73395205776,211,,Plouyé,Plouyé,29,Finistère,53,Bretagne
29212,PLOUZANE,29280,PLOUZANE,,48.3774359933,-4.59923891903,212,,Plouzané,Plouzané,29,Finistère,53,Bretagne
29213,PLOUZEVEDE,29440,PLOUZEVEDE,,48.5919150435,-4.0954616679,213,,Plouzévédé,Plouzévédé,29,Finistère,53,Bretagne
29214,PLOVAN,29720,PLOVAN,,47.9325748846,-4.36035398451,214,,Plovan,Plovan,29,Finistère,53,Bretagne
29215,PLOZEVET,29710,PLOZEVET,,47.9832115947,-4.40960157261,215,,Plozévet,Plozévet,29,Finistère,53,Bretagne
29216,PLUGUFFAN,29700,PLUGUFFAN,,47.972064955,-4.18686466839,216,,Pluguffan,Pluguffan,29,Finistère,53,Bretagne
29217,PONT AVEN,29930,PONT AVEN,,47.8724810212,-3.76601956645,217,,Pont-Aven,Pont-Aven,29,Finistère,53,Bretagne
29218,PONT CROIX,29790,PONT CROIX,,48.0467363685,-4.49625193041,218,,Pont-Croix,Pont-Croix,29,Finistère,53,Bretagne
29219,LE PONTHOU,29650,LE PONTHOU,,48.557360122,-3.63959984291,219,Le,Ponthou,Le Ponthou,29,Finistère,53,Bretagne
29220,PONT L ABBE,29120,PONT L ABBE,,47.8654885488,-4.21962319652,220,,Pont-l'Abbé,Pont-l'Abbé,29,Finistère,53,Bretagne
29221,PORSPODER,29840,PORSPODER,,48.498709078,-4.75352482968,221,,Porspoder,Porspoder,29,Finistère,53,Bretagne
29221,PORSPODER,29840,PORSPODER,LARRET,48.498709078,-4.75352482968,221,,Porspoder,Porspoder,29,Finistère,53,Bretagne
29222,PORT LAUNAY,29150,PORT LAUNAY,,48.2176622722,-4.08308169881,222,,Port-Launay,Port-Launay,29,Finistère,53,Bretagne
29224,POULDERGAT,29100,POULDERGAT,,48.0406832408,-4.32643354494,224,,Pouldergat,Pouldergat,29,Finistère,53,Bretagne
29225,POULDREUZIC,29710,POULDREUZIC,,47.9612717515,-4.36413265082,225,,Pouldreuzic,Pouldreuzic,29,Finistère,53,Bretagne
29226,POULLAN SUR MER,29100,POULLAN SUR MER,,48.0772593161,-4.39719631151,226,,Poullan-sur-Mer,Poullan-sur-Mer,29,Finistère,53,Bretagne
29227,POULLAOUEN,29246,POULLAOUEN,,48.3456851692,-3.63011278827,227,,Poullaouen,Poullaouen,29,Finistère,53,Bretagne
29228,PRIMELIN,29770,PRIMELIN,,48.0287724431,-4.60550316515,228,,Primelin,Primelin,29,Finistère,53,Bretagne
29229,QUEMENEVEN,29180,QUEMENEVEN,,48.1177103984,-4.14400654798,229,,Quéménéven,Quéménéven,29,Finistère,53,Bretagne
29230,QUERRIEN,29310,QUERRIEN,,47.9553937645,-3.54781313088,230,,Querrien,Querrien,29,Finistère,53,Bretagne
29232,QUIMPER,29000,QUIMPER,,47.9971425162,-4.09111944455,232,,Quimper,Quimper,29,Finistère,53,Bretagne
29233,QUIMPERLE,29300,QUIMPERLE,,47.8561303355,-3.55642894655,233,,Quimperlé,Quimperlé,29,Finistère,53,Bretagne
29234,REDENE,29300,REDENE,,47.8554814745,-3.48681564705,234,,Rédené,Rédené,29,Finistère,53,Bretagne
29235,LE RELECQ KERHUON,29480,LE RELECQ KERHUON,,48.403174987,-4.40146737314,235,Le,Relecq-Kerhuon,Le Relecq-Kerhuon,29,Finistère,53,Bretagne
29236,RIEC SUR BELON,29340,RIEC SUR BELON,,47.8492219169,-3.69069464862,236,,Riec-sur-Bélon,Riec-sur-Bélon,29,Finistère,53,Bretagne
29237,LA ROCHE MAURICE,29800,LA ROCHE MAURICE,,48.4731568512,-4.19434003063,237,La,Roche-Maurice,La Roche-Maurice,29,Finistère,53,Bretagne
29238,ROSCANVEL,29570,ROSCANVEL,,48.3183736714,-4.55863270014,238,,Roscanvel,Roscanvel,29,Finistère,53,Bretagne
29239,ROSCOFF,29680,ROSCOFF,,48.7123362901,-3.989722876,239,,Roscoff,Roscoff,29,Finistère,53,Bretagne
29240,ROSNOEN,29590,ROSNOEN,,48.2716564234,-4.19913863918,240,,Rosnoën,Rosnoën,29,Finistère,53,Bretagne
29241,ROSPORDEN,29140,ROSPORDEN,,47.9583041483,-3.80153477736,241,,Rosporden,Rosporden,29,Finistère,53,Bretagne
29241,ROSPORDEN,29140,ROSPORDEN,KERNEVEL,47.9583041483,-3.80153477736,241,,Rosporden,Rosporden,29,Finistère,53,Bretagne
29243,ST COULITZ,29150,ST COULITZ,,48.1819241474,-4.06727219736,243,,Saint-Coulitz,Saint-Coulitz,29,Finistère,53,Bretagne
29244,ST DERRIEN,29440,ST DERRIEN,,48.5448011454,-4.1857054177,244,,Saint-Derrien,Saint-Derrien,29,Finistère,53,Bretagne
29245,ST DIVY,29800,ST DIVY,,48.454416666,-4.33069811028,245,,Saint-Divy,Saint-Divy,29,Finistère,53,Bretagne
29246,ST ELOY,29460,ST ELOY,,48.3633240509,-4.11570482602,246,,Saint-Eloy,Saint-Eloy,29,Finistère,53,Bretagne
29247,ST EVARZEC,29170,ST EVARZEC,,47.9541694305,-4.00984047973,247,,Saint-Évarzec,Saint-Évarzec,29,Finistère,53,Bretagne
29248,ST FREGANT,29260,ST FREGANT,,48.5988235193,-4.37368796467,248,,Saint-Frégant,Saint-Frégant,29,Finistère,53,Bretagne
29249,ST GOAZEC,29520,ST GOAZEC,,48.1512436347,-3.76561570465,249,,Saint-Goazec,Saint-Goazec,29,Finistère,53,Bretagne
29250,ST HERNIN,29270,ST HERNIN,,48.2085491476,-3.61083616524,250,,Saint-Hernin,Saint-Hernin,29,Finistère,53,Bretagne
29251,ST JEAN DU DOIGT,29630,ST JEAN DU DOIGT,,48.6703251838,-3.76283111411,251,,Saint-Jean-du-Doigt,Saint-Jean-du-Doigt,29,Finistère,53,Bretagne
29252,ST JEAN TROLIMON,29120,ST JEAN TROLIMON,,47.8682602746,-4.30993250083,252,,Saint-Jean-Trolimon,Saint-Jean-Trolimon,29,Finistère,53,Bretagne
29254,ST MARTIN DES CHAMPS,29600,ST MARTIN DES CHAMPS,,48.5757887631,-3.85616057853,254,,Saint-Martin-des-Champs,Saint-Martin-des-Champs,29,Finistère,53,Bretagne
29255,ST MEEN,29260,ST MEEN,,48.5552363692,-4.25945997316,255,,Saint-Méen,Saint-Méen,29,Finistère,53,Bretagne
29256,ST NIC,29550,ST NIC,,48.2101254198,-4.28068132169,256,,Saint-Nic,Saint-Nic,29,Finistère,53,Bretagne
29257,ST PABU,29830,ST PABU,,48.5627924623,-4.62029383485,257,,Saint-Pabu,Saint-Pabu,29,Finistère,53,Bretagne
29259,ST POL DE LEON,29250,ST POL DE LEON,,48.6727716649,-3.98863587243,259,,Saint-Pol-de-Léon,Saint-Pol-de-Léon,29,Finistère,53,Bretagne
29260,ST RENAN,29290,ST RENAN,,48.4258372849,-4.62348821209,260,,Saint-Renan,Saint-Renan,29,Finistère,53,Bretagne
29261,ST RIVOAL,29190,ST RIVOAL,,48.3495310382,-3.98818824029,261,,Saint-Rivoal,Saint-Rivoal,29,Finistère,53,Bretagne
29262,ST SAUVEUR,29400,ST SAUVEUR,,48.4485765331,-4.00036157147,262,,Saint-Sauveur,Saint-Sauveur,29,Finistère,53,Bretagne
29263,ST SEGAL,29590,ST SEGAL,,48.2359944685,-4.07720037055,263,,Saint-Ségal,Saint-Ségal,29,Finistère,53,Bretagne
29264,ST SERVAIS,29400,ST SERVAIS,,48.515337215,-4.15865223031,264,,Saint-Servais,Saint-Servais,29,Finistère,53,Bretagne
29265,STE SEVE,29600,STE SEVE,,48.5607905718,-3.88888389593,265,,Sainte-Sève,Sainte-Sève,29,Finistère,53,Bretagne
29266,ST THEGONNEC LOC EGUINER,29410,ST THEGONNEC LOC EGUINER,,48.5113171528,-3.93813377559,266,,Saint-Thégonnec Loc-Eguiner,Saint-Thégonnec Loc-Eguiner,29,Finistère,53,Bretagne
29266,ST THEGONNEC LOC EGUINER,29410,ST THEGONNEC LOC EGUINER,LOC EGUINER ST THEGONNEC,48.5113171528,-3.93813377559,266,,Saint-Thégonnec Loc-Eguiner,Saint-Thégonnec Loc-Eguiner,29,Finistère,53,Bretagne
29267,ST THOIS,29520,ST THOIS,,48.1533398719,-3.90597828016,267,,Saint-Thois,Saint-Thois,29,Finistère,53,Bretagne
29268,ST THONAN,29800,ST THONAN,,48.4765748709,-4.32223178766,268,,Saint-Thonan,Saint-Thonan,29,Finistère,53,Bretagne
29269,ST THURIEN,29380,ST THURIEN,,47.9568349353,-3.62002977712,269,,Saint-Thurien,Saint-Thurien,29,Finistère,53,Bretagne
29270,ST URBAIN,29800,ST URBAIN,,48.3969194336,-4.22206761804,270,,Saint-Urbain,Saint-Urbain,29,Finistère,53,Bretagne
29271,ST VOUGAY,29440,ST VOUGAY,,48.589150749,-4.1458078023,271,,Saint-Vougay,Saint-Vougay,29,Finistère,53,Bretagne
29272,ST YVI,29140,ST YVI,,47.959882104,-3.93921852098,272,,Saint-Yvi,Saint-Yvi,29,Finistère,53,Bretagne
29273,SANTEC,29250,SANTEC,,48.7000595566,-4.02979290086,273,,Santec,Santec,29,Finistère,53,Bretagne
29274,SCAER,29390,SCAER,,48.0279903072,-3.73073835764,274,,Scaër,Scaër,29,Finistère,53,Bretagne
29275,SCRIGNAC,29640,SCRIGNAC,,48.4265058611,-3.66856108678,275,,Scrignac,Scrignac,29,Finistère,53,Bretagne
29276,SIBIRIL,29250,SIBIRIL,,48.6638575511,-4.07209865106,276,,Sibiril,Sibiril,29,Finistère,53,Bretagne
29277,SIZUN,29450,SIZUN,,48.3948012299,-4.04497181745,277,,Sizun,Sizun,29,Finistère,53,Bretagne
29278,SPEZET,29540,SPEZET,,48.1857593376,-3.69132351095,278,,Spézet,Spézet,29,Finistère,53,Bretagne
29279,TAULE,29670,TAULE,,48.6041383541,-3.89875729836,279,,Taulé,Taulé,29,Finistère,53,Bretagne
29280,TELGRUC SUR MER,29560,TELGRUC SUR MER,,48.2337280531,-4.35717463573,280,,Telgruc-sur-Mer,Telgruc-sur-Mer,29,Finistère,53,Bretagne
29281,TOURCH,29140,TOURCH,,48.0265824234,-3.82409792498,281,,Tourch,Tourch,29,Finistère,53,Bretagne
29282,TREBABU,29217,TREBABU,,48.3698825588,-4.73292975114,282,,Trébabu,Trébabu,29,Finistère,53,Bretagne
29284,TREFFIAGAT,29730,TREFFIAGAT,,47.8049042408,-4.2597485124,284,,Treffiagat,Treffiagat,29,Finistère,53,Bretagne
29285,TREFLAOUENAN,29440,TREFLAOUENAN,,48.6266043642,-4.1001544022,285,,Tréflaouénan,Tréflaouénan,29,Finistère,53,Bretagne
29286,TREFLEVENEZ,29800,TREFLEVENEZ,,48.4147323372,-4.17573320036,286,,Tréflévénez,Tréflévénez,29,Finistère,53,Bretagne
29287,TREFLEZ,29430,TREFLEZ,,48.6265878475,-4.25742484616,287,,Tréflez,Tréflez,29,Finistère,53,Bretagne
29288,TREGARANTEC,29260,TREGARANTEC,,48.549214184,-4.28952698165,288,,Trégarantec,Trégarantec,29,Finistère,53,Bretagne
29289,TREGARVAN,29560,TREGARVAN,,48.2369590372,-4.23152835996,289,,Trégarvan,Trégarvan,29,Finistère,53,Bretagne
29290,TREGLONOU,29870,TREGLONOU,,48.5473507088,-4.54852998212,290,,Tréglonou,Tréglonou,29,Finistère,53,Bretagne
29291,TREGOUREZ,29970,TREGOUREZ,,48.1057268762,-3.87130739986,291,,Trégourez,Trégourez,29,Finistère,53,Bretagne
29292,TREGUENNEC,29720,TREGUENNEC,,47.8842839467,-4.33896722365,292,,Tréguennec,Tréguennec,29,Finistère,53,Bretagne
29293,TREGUNC,29910,TREGUNC,,47.8380684896,-3.8408529946,293,,Trégunc,Trégunc,29,Finistère,53,Bretagne
29294,LE TREHOU,29450,LE TREHOU,,48.3938578197,-4.12922038869,294,Le,Tréhou,Le Tréhou,29,Finistère,53,Bretagne
29295,TREMAOUEZAN,29800,TREMAOUEZAN,,48.5091527499,-4.25573982304,295,,Trémaouézan,Trémaouézan,29,Finistère,53,Bretagne
29296,TREMEOC,29120,TREMEOC,,47.9067997461,-4.21984168318,296,,Tréméoc,Tréméoc,29,Finistère,53,Bretagne
29297,TREMEVEN,29300,TREMEVEN,,47.9020408602,-3.52520567711,297,,Tréméven,Tréméven,29,Finistère,53,Bretagne
29298,TREOGAT,29720,TREOGAT,,47.9104620726,-4.34010514157,298,,Tréogat,Tréogat,29,Finistère,53,Bretagne
29299,TREOUERGAT,29290,TREOUERGAT,,48.495243067,-4.60294644618,299,,Tréouergat,Tréouergat,29,Finistère,53,Bretagne
29300,LE TREVOUX,29380,LE TREVOUX,,47.8886988261,-3.65660819595,300,Le,Trévoux,Le Trévoux,29,Finistère,53,Bretagne
29301,TREZILIDE,29440,TREZILIDE,,48.609955967,-4.08366655408,301,,Trézilidé,Trézilidé,29,Finistère,53,Bretagne
29302,PONT DE BUIS LES QUIMERCH,29590,PONT DE BUIS LES QUIMERCH,,48.2755785211,-4.10701817475,302,,Pont-de-Buis-lès-Quimerch,Pont-de-Buis-lès-Quimerch,29,Finistère,53,Bretagne
29302,PONT DE BUIS LES QUIMERCH,29590,PONT DE BUIS LES QUIMERCH,QUIMERCH,48.2755785211,-4.10701817475,302,,Pont-de-Buis-lès-Quimerch,Pont-de-Buis-lès-Quimerch,29,Finistère,53,Bretagne
2A001,AFA,20167,AFA,,41.9844089346,8.79828936731,1,,Afa,Afa,2A,Corse-du-Sud,94,Corse
2A004,AJACCIO,20000,AJACCIO,,41.9347926638,8.70132275974,4,,Ajaccio,Ajaccio,2A,Corse-du-Sud,94,Corse
2A004,AJACCIO,20090,AJACCIO,,41.9347926638,8.70132275974,4,,Ajaccio,Ajaccio,2A,Corse-du-Sud,94,Corse
2A004,AJACCIO,20167,AJACCIO,MEZZAVIA,41.9347926638,8.70132275974,4,,Ajaccio,Ajaccio,2A,Corse-du-Sud,94,Corse
2A006,ALATA,20167,ALATA,,41.9735186682,8.73133206502,6,,Alata,Alata,2A,Corse-du-Sud,94,Corse
2A008,ALBITRECCIA,20128,ALBITRECCIA,,41.860474641,8.87771179182,8,,Albitreccia,Albitreccia,2A,Corse-du-Sud,94,Corse
2A008,ALBITRECCIA,20166,ALBITRECCIA,,41.860474641,8.87771179182,8,,Albitreccia,Albitreccia,2A,Corse-du-Sud,94,Corse
2A011,ALTAGENE,20112,ALTAGENE,,41.7091975922,9.07705100575,11,,Altagène,Altagène,2A,Corse-du-Sud,94,Corse
2A014,AMBIEGNA,20151,AMBIEGNA,,42.0878005416,8.77383533017,14,,Ambiegna,Ambiegna,2A,Corse-du-Sud,94,Corse
2A017,APPIETTO,20167,APPIETTO,,42.0032023283,8.73355643393,17,,Appietto,Appietto,2A,Corse-du-Sud,94,Corse
2A018,ARBELLARA,20110,ARBELLARA,,41.6711031024,8.98935818241,18,,Arbellara,Arbellara,2A,Corse-du-Sud,94,Corse
2A019,ARBORI,20160,ARBORI,,42.1278317001,8.79544743856,19,,Arbori,Arbori,2A,Corse-du-Sud,94,Corse
2A021,ARGIUSTA MORICCIO,20140,ARGIUSTA MORICCIO,,41.815640033,9.0324658577,21,,Argiusta-Moriccio,Argiusta-Moriccio,2A,Corse-du-Sud,94,Corse
2A022,ARRO,20151,ARRO,,42.0965260296,8.8040243417,22,,Arro,Arro,2A,Corse-du-Sud,94,Corse
2A024,AULLENE,20116,AULLENE,,41.8067898223,9.09266751602,24,,Aullène,Aullène,2A,Corse-du-Sud,94,Corse
2A026,AZILONE AMPAZA,20190,AZILONE AMPAZA,,41.8704409452,9.01683572798,26,,Azilone-Ampaza,Azilone-Ampaza,2A,Corse-du-Sud,94,Corse
2A027,AZZANA,20121,AZZANA,,42.1129499138,8.93094346781,27,,Azzana,Azzana,2A,Corse-du-Sud,94,Corse
2A028,BALOGNA,20160,BALOGNA,,42.1870054914,8.75386587299,28,,Balogna,Balogna,2A,Corse-du-Sud,94,Corse
2A031,BASTELICA,20119,BASTELICA,,41.988063407,9.04861808353,31,,Bastelica,Bastelica,2A,Corse-du-Sud,94,Corse
2A032,BASTELICACCIA,20129,BASTELICACCIA,,41.9352135675,8.83650751371,32,,Bastelicaccia,Bastelicaccia,2A,Corse-du-Sud,94,Corse
2A035,BELVEDERE CAMPOMORO,20110,BELVEDERE CAMPOMORO,,41.6174105713,8.82330409363,35,,Belvédère-Campomoro,Belvédère-Campomoro,2A,Corse-du-Sud,94,Corse
2A038,BILIA,20100,BILIA,,41.6222677793,8.90493830672,38,,Bilia,Bilia,2A,Corse-du-Sud,94,Corse
2A040,BOCOGNANO,20136,BOCOGNANO,,42.0887432861,9.07100445274,40,,Bocognano,Bocognano,2A,Corse-du-Sud,94,Corse
2A041,BONIFACIO,20169,BONIFACIO,,41.4354987425,9.18514890376,41,,Bonifacio,Bonifacio,2A,Corse-du-Sud,94,Corse
2A048,CALCATOGGIO,20111,CALCATOGGIO,,42.0284281165,8.73772286465,48,,Calcatoggio,Calcatoggio,2A,Corse-du-Sud,94,Corse
2A056,CAMPO,20142,CAMPO,,41.890691356,9.00098485443,56,,Campo,Campo,2A,Corse-du-Sud,94,Corse
2A060,CANNELLE,20151,CANNELLE,,42.047981907,8.81689575558,60,,Cannelle,Cannelle,2A,Corse-du-Sud,94,Corse
2A061,CARBINI,20170,CARBINI,,41.6757000728,9.16018139778,61,,Carbini,Carbini,2A,Corse-du-Sud,94,Corse
2A062,CARBUCCIA,20133,CARBUCCIA,,42.0302927596,8.93626600653,62,,Carbuccia,Carbuccia,2A,Corse-du-Sud,94,Corse
2A064,CARDO TORGIA,20190,CARDO TORGIA,,41.8528771718,8.97204670982,64,,Cardo-Torgia,Cardo-Torgia,2A,Corse-du-Sud,94,Corse
2A065,CARGESE,20130,CARGESE,,42.1544254567,8.62659836115,65,,Cargèse,Cargèse,2A,Corse-du-Sud,94,Corse
2A066,CARGIACA,20164,CARGIACA,,41.7315838648,9.04097819601,66,,Cargiaca,Cargiaca,2A,Corse-du-Sud,94,Corse
2A070,CASAGLIONE,20111,CASAGLIONE,,42.0695824094,8.75999305453,70,,Casaglione,Casaglione,2A,Corse-du-Sud,94,Corse
2A071,CASALABRIVA,20140,CASALABRIVA,,41.7604107379,8.92486438361,71,,Casalabriva,Casalabriva,2A,Corse-du-Sud,94,Corse
2A085,CAURO,20117,CAURO,,41.9042036105,8.89390218223,85,,Cauro,Cauro,2A,Corse-du-Sud,94,Corse
2A089,CIAMANNACCE,20134,CIAMANNACCE,,41.9728664628,9.13846288557,89,,Ciamannacce,Ciamannacce,2A,Corse-du-Sud,94,Corse
2A090,COGGIA,20118,COGGIA,SAGONE,42.1117512753,8.73524258056,90,,Coggia,Coggia,2A,Corse-du-Sud,94,Corse
2A090,COGGIA,20160,COGGIA,,42.1117512753,8.73524258056,90,,Coggia,Coggia,2A,Corse-du-Sud,94,Corse
2A091,COGNOCOLI MONTICCHI,20123,COGNOCOLI MONTICCHI,,41.8017412239,8.86559828533,91,,Cognocoli-Monticchi,Cognocoli-Monticchi,2A,Corse-du-Sud,94,Corse
2A091,COGNOCOLI MONTICCHI,20166,COGNOCOLI MONTICCHI,MARATO,41.8017412239,8.86559828533,91,,Cognocoli-Monticchi,Cognocoli-Monticchi,2A,Corse-du-Sud,94,Corse
2A092,CONCA,20135,CONCA,,41.7530813124,9.33031186511,92,,Conca,Conca,2A,Corse-du-Sud,94,Corse
2A094,CORRANO,20168,CORRANO,,41.8820885118,9.07104035554,94,,Corrano,Corrano,2A,Corse-du-Sud,94,Corse
2A098,COTI CHIAVARI,20138,COTI CHIAVARI,,41.769023404,8.75300439301,98,,Coti-Chiavari,Coti-Chiavari,2A,Corse-du-Sud,94,Corse
2A099,COZZANO,20148,COZZANO,,41.9335361782,9.18658901993,99,,Cozzano,Cozzano,2A,Corse-du-Sud,94,Corse
2A100,CRISTINACCE,20126,CRISTINACCE,,42.2434011098,8.85509262516,100,,Cristinacce,Cristinacce,2A,Corse-du-Sud,94,Corse
2A103,CUTTOLI CORTICCHIATO,20167,CUTTOLI CORTICCHIATO,,41.976733682,8.88406617978,103,,Cuttoli-Corticchiato,Cuttoli-Corticchiato,2A,Corse-du-Sud,94,Corse
2A104,ECCICA SUARELLA,20117,ECCICA SUARELLA,,41.9231596216,8.89401261483,104,,Eccica-Suarella,Eccica-Suarella,2A,Corse-du-Sud,94,Corse
2A108,EVISA,20126,EVISA,,42.285250229,8.82039690418,108,,Évisa,Évisa,2A,Corse-du-Sud,94,Corse
2A114,FIGARI,20114,FIGARI,,41.5163043789,9.12059415829,114,,Figari,Figari,2A,Corse-du-Sud,94,Corse
2A115,FOCE,20100,FOCE,,41.6219530665,9.03561496971,115,,Foce,Foce,2A,Corse-du-Sud,94,Corse
2A117,FORCIOLO,20190,FORCIOLO,,41.8481431897,9.01587239961,117,,Forciolo,Forciolo,2A,Corse-du-Sud,94,Corse
2A118,FOZZANO,20143,FOZZANO,,41.6987815052,8.98770991864,118,,Fozzano,Fozzano,2A,Corse-du-Sud,94,Corse
2A119,FRASSETO,20157,FRASSETO,,41.9199721408,9.03919953409,119,,Frasseto,Frasseto,2A,Corse-du-Sud,94,Corse
2A127,GIUNCHETO,20100,GIUNCHETO,,41.5862596308,8.95720217701,127,,Giuncheto,Giuncheto,2A,Corse-du-Sud,94,Corse
2A128,GRANACE,20100,GRANACE,,41.6403012602,9.01221939492,128,,Granace,Granace,2A,Corse-du-Sud,94,Corse
2A129,GROSSA,20100,GROSSA,,41.6027786723,8.8649278809,129,,Grossa,Grossa,2A,Corse-du-Sud,94,Corse
2A130,GROSSETO PRUGNA,20128,GROSSETO PRUGNA,,41.8837141916,8.87384830361,130,,Grosseto-Prugna,Grosseto-Prugna,2A,Corse-du-Sud,94,Corse
2A130,GROSSETO PRUGNA,20166,GROSSETO PRUGNA,PORTICCIO,41.8837141916,8.87384830361,130,,Grosseto-Prugna,Grosseto-Prugna,2A,Corse-du-Sud,94,Corse
2A131,GUAGNO,20160,GUAGNO,,42.1783646694,8.98389627863,131,,Guagno,Guagno,2A,Corse-du-Sud,94,Corse
2A132,GUARGUALE,20128,GUARGUALE,,41.8276384979,8.92761040252,132,,Guargualé,Guargualé,2A,Corse-du-Sud,94,Corse
2A133,GUITERA LES BAINS,20153,GUITERA LES BAINS,,41.9219361648,9.07857366526,133,,Guitera-les-Bains,Guitera-les-Bains,2A,Corse-du-Sud,94,Corse
2A139,LECCI,20137,LECCI,,41.6654830708,9.31906773443,139,,Lecci,Lecci,2A,Corse-du-Sud,94,Corse
2A141,LETIA,20160,LETIA,,42.214927495,8.87701144967,141,,Letia,Letia,2A,Corse-du-Sud,94,Corse
2A142,LEVIE,20170,LEVIE,,41.6516390001,9.11567295923,142,,Levie,Levie,2A,Corse-du-Sud,94,Corse
2A144,LOPIGNA,20139,LOPIGNA,,42.0901954462,8.85412364517,144,,Lopigna,Lopigna,2A,Corse-du-Sud,94,Corse
2A146,LORETO DI TALLANO,20165,LORETO DI TALLANO,,41.6987192388,9.03120917248,146,,Loreto-di-Tallano,Loreto-di-Tallano,2A,Corse-du-Sud,94,Corse
2A154,MARIGNANA,20141,MARIGNANA,,42.2083267447,8.72012145017,154,,Marignana,Marignana,2A,Corse-du-Sud,94,Corse
2A158,MELA,20112,MELA,,41.6843116353,9.09251801465,158,,Mela,Mela,2A,Corse-du-Sud,94,Corse
2A160,MOCA CROCE,20140,MOCA CROCE,,41.7880108212,9.00990338011,160,,Moca-Croce,Moca-Croce,2A,Corse-du-Sud,94,Corse
2A163,MONACIA D AULLENE,20171,MONACIA D AULLENE,,41.5249319364,9.01194250381,163,,Monacia-d'Aullène,Monacia-d'Aullène,2A,Corse-du-Sud,94,Corse
2A174,MURZO,20160,MURZO,,42.1585493633,8.84670847877,174,,Murzo,Murzo,2A,Corse-du-Sud,94,Corse
2A181,OCANA,20117,OCANA,,41.9503939626,8.909888839,181,,Ocana,Ocana,2A,Corse-du-Sud,94,Corse
2A186,OLIVESE,20140,OLIVESE,,41.8404362861,9.06885180924,186,,Olivese,Olivese,2A,Corse-du-Sud,94,Corse
2A189,OLMETO,20113,OLMETO,,41.7120554576,8.90482669447,189,,Olmeto,Olmeto,2A,Corse-du-Sud,94,Corse
2A191,OLMICCIA,20112,OLMICCIA,,41.6701338778,9.03297164192,191,,Olmiccia,Olmiccia,2A,Corse-du-Sud,94,Corse
2A196,ORTO,20125,ORTO,,42.1989987238,8.95741133196,196,,Orto,Orto,2A,Corse-du-Sud,94,Corse
2A197,OSANI,20147,OSANI,,42.3435694896,8.62327012341,197,,Osani,Osani,2A,Corse-du-Sud,94,Corse
2A198,OTA,20150,OTA,,42.2548660718,8.73294026195,198,,Ota,Ota,2A,Corse-du-Sud,94,Corse
2A198,OTA,20150,OTA,PORTO,42.2548660718,8.73294026195,198,,Ota,Ota,2A,Corse-du-Sud,94,Corse
2A200,PALNECA,20134,PALNECA,,41.9917474274,9.18910929636,200,,Palneca,Palneca,2A,Corse-du-Sud,94,Corse
2A203,PARTINELLO,20147,PARTINELLO,,42.3189049324,8.68973829673,203,,Partinello,Partinello,2A,Corse-du-Sud,94,Corse
2A204,PASTRICCIOLA,20121,PASTRICCIOLA,,42.1434520228,9.01623489472,204,,Pastricciola,Pastricciola,2A,Corse-du-Sud,94,Corse
2A209,PERI,20167,PERI,,42.003934282,8.91155032834,209,,Peri,Peri,2A,Corse-du-Sud,94,Corse
2A211,PETRETO BICCHISANO,20140,PETRETO BICCHISANO,,41.7703606471,8.96953349207,211,,Petreto-Bicchisano,Petreto-Bicchisano,2A,Corse-du-Sud,94,Corse
2A212,PIANA,20115,PIANA,,42.2260344023,8.62798195907,212,,Piana,Piana,2A,Corse-du-Sud,94,Corse
2A215,PIANOTTOLI CALDARELLO,20131,PIANOTTOLI CALDARELLO,,41.5046258305,9.04899145818,215,,Pianottoli-Caldarello,Pianottoli-Caldarello,2A,Corse-du-Sud,94,Corse
2A228,PIETROSELLA,20166,PIETROSELLA,,41.8283411519,8.8134589974,228,,Pietrosella,Pietrosella,2A,Corse-du-Sud,94,Corse
2A232,PILA CANALE,20123,PILA CANALE,,41.7923350727,8.89878366545,232,,Pila-Canale,Pila-Canale,2A,Corse-du-Sud,94,Corse
2A240,POGGIOLO,20125,POGGIOLO,,42.1628161972,8.89848173922,240,,Poggiolo,Poggiolo,2A,Corse-du-Sud,94,Corse
2A240,POGGIOLO,20160,POGGIOLO,GUAGNO LES BAINS,42.1628161972,8.89848173922,240,,Poggiolo,Poggiolo,2A,Corse-du-Sud,94,Corse
2A247,PORTO VECCHIO,20137,PORTO VECCHIO,,41.5924932873,9.25363087024,247,,Porto-Vecchio,Porto-Vecchio,2A,Corse-du-Sud,94,Corse
2A249,PROPRIANO,20110,PROPRIANO,,41.6504172843,8.89378639473,249,,Propriano,Propriano,2A,Corse-du-Sud,94,Corse
2A253,QUASQUARA,20142,QUASQUARA,,41.9087537102,9.00836117907,253,,Quasquara,Quasquara,2A,Corse-du-Sud,94,Corse
2A254,QUENZA,20122,QUENZA,,41.8077584567,9.2009533879,254,,Quenza,Quenza,2A,Corse-du-Sud,94,Corse
2A258,RENNO,20160,RENNO,,42.2067544831,8.8197756757,258,,Renno,Renno,2A,Corse-du-Sud,94,Corse
2A259,REZZA,20121,REZZA,,42.1240747257,8.95203254705,259,,Rezza,Rezza,2A,Corse-du-Sud,94,Corse
2A262,ROSAZIA,20121,ROSAZIA,,42.1258026167,8.86199135489,262,,Rosazia,Rosazia,2A,Corse-du-Sud,94,Corse
2A266,SALICE,20121,SALICE,,42.1045072661,8.90188848529,266,,Salice,Salice,2A,Corse-du-Sud,94,Corse
2A268,SAMPOLO,20134,SAMPOLO,,41.9468987764,9.12053513385,268,,Sampolo,Sampolo,2A,Corse-du-Sud,94,Corse
2A269,SARI SOLENZARA,20145,SARI SOLENZARA,,41.8133599634,9.35220683826,269,,Sari-Solenzara,Sari-Solenzara,2A,Corse-du-Sud,94,Corse
2A269,SARI SOLENZARA,20145,SARI SOLENZARA,SOLENZARA,41.8133599634,9.35220683826,269,,Sari-Solenzara,Sari-Solenzara,2A,Corse-du-Sud,94,Corse
2A270,SARI D ORCINO,20151,SARI D ORCINO,,42.0648114999,8.83377089939,270,,Sari-d'Orcino,Sari-d'Orcino,2A,Corse-du-Sud,94,Corse
2A271,SARROLA CARCOPINO,20167,SARROLA CARCOPINO,,42.0010171951,8.83437893445,271,,Sarrola-Carcopino,Sarrola-Carcopino,2A,Corse-du-Sud,94,Corse
2A272,SARTENE,20100,SARTENE,,41.5718443328,8.93538107497,272,,Sartène,Sartène,2A,Corse-du-Sud,94,Corse
2A276,SERRA DI FERRO,20140,SERRA DI FERRO,,41.7410474334,8.81250812436,276,,Serra-di-Ferro,Serra-di-Ferro,2A,Corse-du-Sud,94,Corse
2A276,SERRA DI FERRO,20140,SERRA DI FERRO,PORTO POLLO,41.7410474334,8.81250812436,276,,Serra-di-Ferro,Serra-di-Ferro,2A,Corse-du-Sud,94,Corse
2A278,SERRA DI SCOPAMENE,20127,SERRA DI SCOPAMENE,,41.778887385,9.10982740043,278,,Serra-di-Scopamène,Serra-di-Scopamène,2A,Corse-du-Sud,94,Corse
2A279,SERRIERA,20147,SERRIERA,,42.307049386,8.73249922249,279,,Serriera,Serriera,2A,Corse-du-Sud,94,Corse
2A282,SOCCIA,20125,SOCCIA,,42.2094271559,8.92258883331,282,,Soccia,Soccia,2A,Corse-du-Sud,94,Corse
2A284,SOLLACARO,20140,SOLLACARO,,41.7417710939,8.88010382989,284,,Sollacaro,Sollacaro,2A,Corse-du-Sud,94,Corse
2A285,SORBOLLANO,20152,SORBOLLANO,,41.7504592424,9.115742202,285,,Sorbollano,Sorbollano,2A,Corse-du-Sud,94,Corse
2A288,SOTTA,20146,SOTTA,,41.555960372,9.18208356103,288,,Sotta,Sotta,2A,Corse-du-Sud,94,Corse
2A295,SANT ANDREA D ORCINO,20151,SANT ANDREA D ORCINO,,42.0443583536,8.78903370453,295,,Sant'Andréa-d'Orcino,Sant'Andréa-d'Orcino,2A,Corse-du-Sud,94,Corse
2A300,SAN GAVINO DI CARBINI,20170,SAN GAVINO DI CARBINI,,41.6806017168,9.23310910423,300,,San-Gavino-di-Carbini,San-Gavino-di-Carbini,2A,Corse-du-Sud,94,Corse
2A308,STE LUCIE DE TALLANO,20112,STE LUCIE DE TALLANO,,41.6682904009,9.06116918689,308,,Sainte-Lucie-de-Tallano,Sainte-Lucie-de-Tallano,2A,Corse-du-Sud,94,Corse
2A310,SANTA MARIA FIGANIELLA,20143,SANTA MARIA FIGANIELLA,,41.725389582,9.00886511105,310,,Santa-Maria-Figaniella,Santa-Maria-Figaniella,2A,Corse-du-Sud,94,Corse
2A312,SANTA MARIA SICHE,20190,SANTA MARIA SICHE,,41.8839751228,8.97997119242,312,,Santa-Maria-Siché,Santa-Maria-Siché,2A,Corse-du-Sud,94,Corse
2A322,TASSO,20134,TASSO,,41.9505880211,9.09337409973,322,,Tasso,Tasso,2A,Corse-du-Sud,94,Corse
2A323,TAVACO,20167,TAVACO,,42.031523197,8.88435743542,323,,Tavaco,Tavaco,2A,Corse-du-Sud,94,Corse
2A324,TAVERA,20163,TAVERA,,42.0697944697,9.01609349195,324,,Tavera,Tavera,2A,Corse-du-Sud,94,Corse
2A326,TOLLA,20117,TOLLA,,41.9682067011,8.9690159805,326,,Tolla,Tolla,2A,Corse-du-Sud,94,Corse
2A330,UCCIANI,20133,UCCIANI,,42.0469909829,8.98239119896,330,,Ucciani,Ucciani,2A,Corse-du-Sud,94,Corse
2A331,URBALACONE,20128,URBALACONE,,41.8260298884,8.94923394489,331,,Urbalacone,Urbalacone,2A,Corse-du-Sud,94,Corse
2A336,VALLE DI MEZZANA,20167,VALLE DI MEZZANA,,42.0235582479,8.8195514546,336,,Valle-di-Mezzana,Valle-di-Mezzana,2A,Corse-du-Sud,94,Corse
2A345,VERO,20172,VERO,,42.0610612103,8.92182128111,345,,Vero,Vero,2A,Corse-du-Sud,94,Corse
2A348,VICO,20118,VICO,SAGONE,42.1506390695,8.73051107045,348,,Vico,Vico,2A,Corse-du-Sud,94,Corse
2A348,VICO,20160,VICO,,42.1506390695,8.73051107045,348,,Vico,Vico,2A,Corse-du-Sud,94,Corse
2A349,VIGGIANELLO,20110,VIGGIANELLO,,41.6691692239,8.94530373652,349,,Viggianello,Viggianello,2A,Corse-du-Sud,94,Corse
2A351,VILLANOVA,20167,VILLANOVA,,41.9635153362,8.66974621605,351,,Villanova,Villanova,2A,Corse-du-Sud,94,Corse
2A357,ZERUBIA,20116,ZERUBIA,,41.7511203775,9.05738566611,357,,Zérubia,Zérubia,2A,Corse-du-Sud,94,Corse
2A358,ZEVACO,20173,ZEVACO,,41.8861101401,9.04464465629,358,,Zévaco,Zévaco,2A,Corse-du-Sud,94,Corse
2A359,ZICAVO,20132,ZICAVO,,41.8845132229,9.1668409674,359,,Zicavo,Zicavo,2A,Corse-du-Sud,94,Corse
2A360,ZIGLIARA,20190,ZIGLIARA,,41.8346717318,8.9837582773,360,,Zigliara,Zigliara,2A,Corse-du-Sud,94,Corse
2A362,ZONZA,20124,ZONZA,,41.7213730193,9.26624005547,362,,Zonza,Zonza,2A,Corse-du-Sud,94,Corse
2A362,ZONZA,20144,ZONZA,STE LUCIE DE PORTO VECCHIO,41.7213730193,9.26624005547,362,,Zonza,Zonza,2A,Corse-du-Sud,94,Corse
2A363,ZOZA,20112,ZOZA,,41.721546993,9.07173348463,363,,Zoza,Zoza,2A,Corse-du-Sud,94,Corse
2B002,AGHIONE,20270,AGHIONE,,42.0985000918,9.41152525552,2,,Aghione,Aghione,2B,Haute-Corse,94,Corse
2B003,AITI,20244,AITI,,42.4043178377,9.22873647306,3,,Aiti,Aiti,2B,Haute-Corse,94,Corse
2B005,ALANDO,20212,ALANDO,,42.3072494054,9.28630204632,5,,Alando,Alando,2B,Haute-Corse,94,Corse
2B007,ALBERTACCE,20224,ALBERTACCE,,42.3117941076,8.92423033485,7,,Albertacce,Albertacce,2B,Haute-Corse,94,Corse
2B007,ALBERTACCE,20224,ALBERTACCE,VERGIO,42.3117941076,8.92423033485,7,,Albertacce,Albertacce,2B,Haute-Corse,94,Corse
2B009,ALERIA,20270,ALERIA,,42.1059105058,9.495645512,9,,Aléria,Aléria,2B,Haute-Corse,94,Corse
2B009,ALERIA,20270,ALERIA,VACAJA,42.1059105058,9.495645512,9,,Aléria,Aléria,2B,Haute-Corse,94,Corse
2B010,ALGAJOLA,20220,ALGAJOLA,,42.6050790894,8.85735931464,10,,Algajola,Algajola,2B,Haute-Corse,94,Corse
2B012,ALTIANI,20251,ALTIANI,,42.2194492715,9.27831968193,12,,Altiani,Altiani,2B,Haute-Corse,94,Corse
2B013,ALZI,20212,ALZI,,42.312646335,9.3147546564,13,,Alzi,Alzi,2B,Haute-Corse,94,Corse
2B015,AMPRIANI,20272,AMPRIANI,,42.2536571506,9.35731976414,15,,Ampriani,Ampriani,2B,Haute-Corse,94,Corse
2B016,ANTISANTI,20270,ANTISANTI,,42.1562385991,9.38406808296,16,,Antisanti,Antisanti,2B,Haute-Corse,94,Corse
2B020,AREGNO,20220,AREGNO,,42.591747308,8.8768922389,20,,Aregno,Aregno,2B,Haute-Corse,94,Corse
2B023,ASCO,20276,ASCO,,42.4346334244,8.99704854725,23,,Asco,Asco,2B,Haute-Corse,94,Corse
2B025,AVAPESSA,20225,AVAPESSA,,42.5591666963,8.89434767169,25,,Avapessa,Avapessa,2B,Haute-Corse,94,Corse
2B029,BARBAGGIO,20253,BARBAGGIO,,42.6841491468,9.36802297003,29,,Barbaggio,Barbaggio,2B,Haute-Corse,94,Corse
2B030,BARRETTALI,20228,BARRETTALI,,42.8731830827,9.36073943184,30,,Barrettali,Barrettali,2B,Haute-Corse,94,Corse
2B033,BASTIA,20200,BASTIA,,42.6864768806,9.42502133338,33,,Bastia,Bastia,2B,Haute-Corse,94,Corse
2B033,BASTIA,20200,BASTIA,CARDO,42.6864768806,9.42502133338,33,,Bastia,Bastia,2B,Haute-Corse,94,Corse
2B033,BASTIA,20600,BASTIA,,42.6864768806,9.42502133338,33,,Bastia,Bastia,2B,Haute-Corse,94,Corse
2B034,BELGODERE,20226,BELGODERE,,42.6078945875,9.01429591727,34,,Belgodère,Belgodère,2B,Haute-Corse,94,Corse
2B034,BELGODERE,20226,BELGODERE,LOZARI,42.6078945875,9.01429591727,34,,Belgodère,Belgodère,2B,Haute-Corse,94,Corse
2B036,BIGORNO,20252,BIGORNO,,42.5310349596,9.30225902799,36,,Bigorno,Bigorno,2B,Haute-Corse,94,Corse
2B037,BIGUGLIA,20620,BIGUGLIA,,42.6164219998,9.44048317398,37,,Biguglia,Biguglia,2B,Haute-Corse,94,Corse
2B037,BIGUGLIA,20620,BIGUGLIA,CASATORRA,42.6164219998,9.44048317398,37,,Biguglia,Biguglia,2B,Haute-Corse,94,Corse
2B039,BISINCHI,20235,BISINCHI,,42.485929834,9.31902586907,39,,Bisinchi,Bisinchi,2B,Haute-Corse,94,Corse
2B042,BORGO,20290,BORGO,,42.573930097,9.45690314359,42,,Borgo,Borgo,2B,Haute-Corse,94,Corse
2B042,BORGO,20290,BORGO,VALROSE,42.573930097,9.45690314359,42,,Borgo,Borgo,2B,Haute-Corse,94,Corse
2B043,BRANDO,20222,BRANDO,,42.7792996993,9.45029993821,43,,Brando,Brando,2B,Haute-Corse,94,Corse
2B043,BRANDO,20222,BRANDO,ERBALUNGA,42.7792996993,9.45029993821,43,,Brando,Brando,2B,Haute-Corse,94,Corse
2B043,BRANDO,20222,BRANDO,LAVASINA,42.7792996993,9.45029993821,43,,Brando,Brando,2B,Haute-Corse,94,Corse
2B045,BUSTANICO,20212,BUSTANICO,,42.3272213954,9.30862060139,45,,Bustanico,Bustanico,2B,Haute-Corse,94,Corse
2B046,CAGNANO,20228,CAGNANO,,42.8719957241,9.44402317459,46,,Cagnano,Cagnano,2B,Haute-Corse,94,Corse
2B047,CALACUCCIA,20224,CALACUCCIA,,42.3232788863,9.0338481491,47,,Calacuccia,Calacuccia,2B,Haute-Corse,94,Corse
2B049,CALENZANA,20214,CALENZANA,,42.4784267166,8.80369225001,49,,Calenzana,Calenzana,2B,Haute-Corse,94,Corse
2B049,CALENZANA,20214,CALENZANA,SUARE,42.4784267166,8.80369225001,49,,Calenzana,Calenzana,2B,Haute-Corse,94,Corse
2B049,CALENZANA,20260,CALENZANA,ARGENTELLA,42.4784267166,8.80369225001,49,,Calenzana,Calenzana,2B,Haute-Corse,94,Corse
2B050,CALVI,20260,CALVI,,42.5454874755,8.7595487404,50,,Calvi,Calvi,2B,Haute-Corse,94,Corse
2B051,CAMBIA,20244,CAMBIA,,42.3688581376,9.30244187201,51,,Cambia,Cambia,2B,Haute-Corse,94,Corse
2B052,CAMPANA,20229,CAMPANA,,42.3886009558,9.33996817013,52,,Campana,Campana,2B,Haute-Corse,94,Corse
2B053,CAMPI,20270,CAMPI,,42.2686230667,9.42370461257,53,,Campi,Campi,2B,Haute-Corse,94,Corse
2B054,CAMPILE,20290,CAMPILE,,42.4960198579,9.35529599495,54,,Campile,Campile,2B,Haute-Corse,94,Corse
2B055,CAMPITELLO,20252,CAMPITELLO,,42.526148234,9.3252006896,55,,Campitello,Campitello,2B,Haute-Corse,94,Corse
2B057,CANALE DI VERDE,20230,CANALE DI VERDE,,42.2649423913,9.50202161228,57,,Canale-di-Verde,Canale-di-Verde,2B,Haute-Corse,94,Corse
2B058,CANARI,20217,CANARI,,42.8430542103,9.34556643621,58,,Canari,Canari,2B,Haute-Corse,94,Corse
2B059,CANAVAGGIA,20235,CANAVAGGIA,,42.4966282507,9.23425707415,59,,Canavaggia,Canavaggia,2B,Haute-Corse,94,Corse
2B063,CARCHETO BRUSTICO,20229,CARCHETO BRUSTICO,,42.3547035924,9.36050471193,63,,Carcheto-Brustico,Carcheto-Brustico,2B,Haute-Corse,94,Corse
2B067,CARPINETO,20229,CARPINETO,,42.3559945667,9.37993794819,67,,Carpineto,Carpineto,2B,Haute-Corse,94,Corse
2B068,CARTICASI,20244,CARTICASI,,42.3479242987,9.30372029952,68,,Carticasi,Carticasi,2B,Haute-Corse,94,Corse
2B069,CASABIANCA,20237,CASABIANCA,,42.4472062738,9.37216855737,69,,Casabianca,Casabianca,2B,Haute-Corse,94,Corse
2B072,CASALTA,20215,CASALTA,,42.4353535822,9.42365261956,72,,Casalta,Casalta,2B,Haute-Corse,94,Corse
2B073,CASAMACCIOLI,20224,CASAMACCIOLI,,42.2828810078,8.9950833348,73,,Casamaccioli,Casamaccioli,2B,Haute-Corse,94,Corse
2B074,CASANOVA,20250,CASANOVA,,42.2616692125,9.15184231008,74,,Casanova,Casanova,2B,Haute-Corse,94,Corse
2B075,CASEVECCHIE,20270,CASEVECCHIE,,42.1355350741,9.36806507914,75,,Casevecchie,Casevecchie,2B,Haute-Corse,94,Corse
2B077,CASTELLARE DI CASINCA,20213,CASTELLARE DI CASINCA,,42.4705671665,9.50131455475,77,,Castellare-di-Casinca,Castellare-di-Casinca,2B,Haute-Corse,94,Corse
2B078,CASTELLARE DI MERCURIO,20212,CASTELLARE DI MERCURIO,,42.310112696,9.24655263263,78,,Castellare-di-Mercurio,Castellare-di-Mercurio,2B,Haute-Corse,94,Corse
2B079,CASTELLO DI ROSTINO,20235,CASTELLO DI ROSTINO,,42.471301177,9.29768308268,79,,Castello-di-Rostino,Castello-di-Rostino,2B,Haute-Corse,94,Corse
2B080,CASTIFAO,20218,CASTIFAO,,42.5259960689,9.11827028996,80,,Castifao,Castifao,2B,Haute-Corse,94,Corse
2B081,CASTIGLIONE,20218,CASTIGLIONE,,42.4094045132,9.11404108561,81,,Castiglione,Castiglione,2B,Haute-Corse,94,Corse
2B082,CASTINETA,20218,CASTINETA,,42.4281629327,9.27331954769,82,,Castineta,Castineta,2B,Haute-Corse,94,Corse
2B083,CASTIRLA,20236,CASTIRLA,,42.3685154876,9.12608884402,83,,Castirla,Castirla,2B,Haute-Corse,94,Corse
2B084,CATERI,20225,CATERI,,42.5692984321,8.89579087074,84,,Cateri,Cateri,2B,Haute-Corse,94,Corse
2B086,CENTURI,20238,CENTURI,,42.967184653,9.36449852317,86,,Centuri,Centuri,2B,Haute-Corse,94,Corse
2B087,CERVIONE,20221,CERVIONE,,42.3210930584,9.5154262188,87,,Cervione,Cervione,2B,Haute-Corse,94,Corse
2B087,CERVIONE,20221,CERVIONE,PRUNETE,42.3210930584,9.5154262188,87,,Cervione,Cervione,2B,Haute-Corse,94,Corse
2B088,CHIATRA,20230,CHIATRA DI VERDE,,42.2879316511,9.48866878904,88,,Chiatra,Chiatra,2B,Haute-Corse,94,Corse
2B093,CORBARA,20220,CORBARA,,42.6171380337,8.90165227126,93,,Corbara,Corbara,2B,Haute-Corse,94,Corse
2B093,CORBARA,20256,CORBARA,,42.6171380337,8.90165227126,93,,Corbara,Corbara,2B,Haute-Corse,94,Corse
2B095,CORSCIA,20224,CORSCIA,,42.3691487665,9.05557667176,95,,Corscia,Corscia,2B,Haute-Corse,94,Corse
2B096,CORTE,20250,CORTE,,42.2700703225,9.0828946136,96,,Corte,Corte,2B,Haute-Corse,94,Corse
2B097,COSTA,20226,COSTA,,42.5761332368,8.99907803384,97,,Costa,Costa,2B,Haute-Corse,94,Corse
2B101,CROCE,20237,CROCE,,42.4077831336,9.35731368031,101,,Croce,Croce,2B,Haute-Corse,94,Corse
2B102,CROCICCHIA,20290,CROCICCHIA,,42.4737480696,9.3523598624,102,,Crocicchia,Crocicchia,2B,Haute-Corse,94,Corse
2B105,ERBAJOLO,20212,ERBAJOLO,,42.2523377533,9.26307967113,105,,Erbajolo,Erbajolo,2B,Haute-Corse,94,Corse
2B106,ERONE,20244,ERONE,,42.3756203554,9.26708993339,106,,Érone,Érone,2B,Haute-Corse,94,Corse
2B107,ERSA,20275,ERSA,,42.9871049875,9.38235735891,107,,Ersa,Ersa,2B,Haute-Corse,94,Corse
2B109,FARINOLE,20253,FARINOLE,,42.7298003144,9.36530466703,109,,Farinole,Farinole,2B,Haute-Corse,94,Corse
2B110,FAVALELLO,20212,FAVALELLO DE BOZIO,,42.2881585012,9.24928255053,110,,Favalello,Favalello,2B,Haute-Corse,94,Corse
2B111,FELCE,20234,FELCE,,42.3462578102,9.42479183294,111,,Felce,Felce,2B,Haute-Corse,94,Corse
2B112,FELICETO,20225,FELICETO,,42.5493282775,8.93994072673,112,,Feliceto,Feliceto,2B,Haute-Corse,94,Corse
2B113,FICAJA,20237,FICAJA,,42.420940762,9.37847639312,113,,Ficaja,Ficaja,2B,Haute-Corse,94,Corse
2B116,FOCICCHIA,20212,FOCICCHIA,,42.250040051,9.29254377168,116,,Focicchia,Focicchia,2B,Haute-Corse,94,Corse
2B120,FURIANI,20600,FURIANI,,42.6523411195,9.4172647007,120,,Furiani,Furiani,2B,Haute-Corse,94,Corse
2B121,GALERIA,20245,GALERIA,,42.4081803478,8.70707754451,121,,Galéria,Galéria,2B,Haute-Corse,94,Corse
2B122,GAVIGNANO,20218,GAVIGNANO,,42.4228249345,9.25981637428,122,,Gavignano,Gavignano,2B,Haute-Corse,94,Corse
2B123,GHISONACCIA,20240,GHISONACCIA,,42.0437178075,9.42260770968,123,,Ghisonaccia,Ghisonaccia,2B,Haute-Corse,94,Corse
2B123,GHISONACCIA,20240,GHISONACCIA,ALZITONE,42.0437178075,9.42260770968,123,,Ghisonaccia,Ghisonaccia,2B,Haute-Corse,94,Corse
2B123,GHISONACCIA,20240,GHISONACCIA,ST ANTOINE,42.0437178075,9.42260770968,123,,Ghisonaccia,Ghisonaccia,2B,Haute-Corse,94,Corse
2B124,GHISONI,20227,GHISONI,,42.0908261041,9.21026781601,124,,Ghisoni,Ghisoni,2B,Haute-Corse,94,Corse
2B125,GIOCATOJO,20237,GIOCATOJO,,42.4437069082,9.34587387627,125,,Giocatojo,Giocatojo,2B,Haute-Corse,94,Corse
2B126,GIUNCAGGIO,20251,GIUNCAGGIO,,42.1914716312,9.38364577936,126,,Giuncaggio,Giuncaggio,2B,Haute-Corse,94,Corse
2B134,L ILE ROUSSE,20220,L ILE ROUSSE,,42.631545096,8.93355970672,134,L',Île-Rousse,L'Île-Rousse,2B,Haute-Corse,94,Corse
2B135,ISOLACCIO DI FIUMORBO,20243,ISOLACCIO DI FIUMORBO,,42.0157488768,9.26439576415,135,,Isolaccio-di-Fiumorbo,Isolaccio-di-Fiumorbo,2B,Haute-Corse,94,Corse
2B135,ISOLACCIO DI FIUMORBO,20243,ISOLACCIO DI FIUMORBO,AJIOLA,42.0157488768,9.26439576415,135,,Isolaccio-di-Fiumorbo,Isolaccio-di-Fiumorbo,2B,Haute-Corse,94,Corse
2B135,ISOLACCIO DI FIUMORBO,20243,ISOLACCIO DI FIUMORBO,PIETRAPOLA,42.0157488768,9.26439576415,135,,Isolaccio-di-Fiumorbo,Isolaccio-di-Fiumorbo,2B,Haute-Corse,94,Corse
2B136,LAMA,20218,LAMA,,42.5804431828,9.16621069911,136,,Lama,Lama,2B,Haute-Corse,94,Corse
2B137,LANO,20244,LANO,,42.3789860605,9.23567141765,137,,Lano,Lano,2B,Haute-Corse,94,Corse
2B138,LAVATOGGIO,20225,LAVATOGGIO,,42.5733311886,8.86153291586,138,,Lavatoggio,Lavatoggio,2B,Haute-Corse,94,Corse
2B140,LENTO,20252,LENTO,,42.5313679084,9.2630678299,140,,Lento,Lento,2B,Haute-Corse,94,Corse
2B143,LINGUIZZETTA,20230,LINGUIZZETTA,,42.2176450793,9.51024497679,143,,Linguizzetta,Linguizzetta,2B,Haute-Corse,94,Corse
2B143,LINGUIZZETTA,20230,LINGUIZZETTA,BRAVONE,42.2176450793,9.51024497679,143,,Linguizzetta,Linguizzetta,2B,Haute-Corse,94,Corse
2B145,LORETO DI CASINCA,20215,LORETO DI CASINCA,,42.4764297797,9.42503725117,145,,Loreto-di-Casinca,Loreto-di-Casinca,2B,Haute-Corse,94,Corse
2B147,LOZZI,20224,LOZZI,,42.3625664581,8.98031669387,147,,Lozzi,Lozzi,2B,Haute-Corse,94,Corse
2B148,LUCCIANA,20290,LUCCIANA,,42.5413974263,9.47269139438,148,,Lucciana,Lucciana,2B,Haute-Corse,94,Corse
2B148,LUCCIANA,20290,LUCCIANA,CASAMOZZA,42.5413974263,9.47269139438,148,,Lucciana,Lucciana,2B,Haute-Corse,94,Corse
2B148,LUCCIANA,20290,LUCCIANA,PORETTA,42.5413974263,9.47269139438,148,,Lucciana,Lucciana,2B,Haute-Corse,94,Corse
2B149,LUGO DI NAZZA,20240,LUGO DI NAZZA,,42.0755704255,9.32349396148,149,,Lugo-di-Nazza,Lugo-di-Nazza,2B,Haute-Corse,94,Corse
2B150,LUMIO,20260,LUMIO,,42.5800438458,8.82575573819,150,,Lumio,Lumio,2B,Haute-Corse,94,Corse
2B152,LURI,20228,LURI,,42.8934435302,9.40987964362,152,,Luri,Luri,2B,Haute-Corse,94,Corse
2B152,LURI,20228,LURI,SANTA SEVERA,42.8934435302,9.40987964362,152,,Luri,Luri,2B,Haute-Corse,94,Corse
2B153,MANSO,20245,MANSO,,42.3708830092,8.81890618067,153,,Manso,Manso,2B,Haute-Corse,94,Corse
2B155,MATRA,20270,MATRA,,42.2832313551,9.38756967026,155,,Matra,Matra,2B,Haute-Corse,94,Corse
2B156,MAUSOLEO,20259,MAUSOLEO,,42.4947585945,8.96434794881,156,,Mausoléo,Mausoléo,2B,Haute-Corse,94,Corse
2B157,MAZZOLA,20212,MAZZOLA,,42.3045556873,9.32842829627,157,,Mazzola,Mazzola,2B,Haute-Corse,94,Corse
2B159,MERIA,20287,MERIA,,42.9200015376,9.4368174556,159,,Meria,Meria,2B,Haute-Corse,94,Corse
2B161,MOITA,20270,MOITA,,42.2817067777,9.41036898711,161,,Moïta,Moïta,2B,Haute-Corse,94,Corse
2B162,MOLTIFAO,20218,MOLTIFAO,,42.4744158583,9.12322901577,162,,Moltifao,Moltifao,2B,Haute-Corse,94,Corse
2B164,MONACIA D OREZZA,20229,MONACIA D OREZZA,,42.3814112942,9.40502593518,164,,Monacia-d'Orezza,Monacia-d'Orezza,2B,Haute-Corse,94,Corse
2B165,MONCALE,20214,MONCALE,,42.5033810267,8.81947177573,165,,Moncale,Moncale,2B,Haute-Corse,94,Corse
2B166,MONTE,20290,MONTE,,42.4830064392,9.40129428843,166,,Monte,Monte,2B,Haute-Corse,94,Corse
2B167,MONTEGROSSO,20214,MONTEGROSSO,,42.543505718,8.85623474811,167,,Montegrosso,Montegrosso,2B,Haute-Corse,94,Corse
2B167,MONTEGROSSO,20214,MONTEGROSSO,CASSANO,42.543505718,8.85623474811,167,,Montegrosso,Montegrosso,2B,Haute-Corse,94,Corse
2B167,MONTEGROSSO,20214,MONTEGROSSO,MONTEMAGGIORE,42.543505718,8.85623474811,167,,Montegrosso,Montegrosso,2B,Haute-Corse,94,Corse
2B167,MONTEGROSSO,20214,MONTEGROSSO,ST RAINIER DE BALAGNE,42.543505718,8.85623474811,167,,Montegrosso,Montegrosso,2B,Haute-Corse,94,Corse
2B168,MONTICELLO,20220,MONTICELLO,,42.6216923341,8.96127656939,168,,Monticello,Monticello,2B,Haute-Corse,94,Corse
2B169,MOROSAGLIA,20218,MOROSAGLIA,,42.4476687598,9.25468558646,169,,Morosaglia,Morosaglia,2B,Haute-Corse,94,Corse
2B169,MOROSAGLIA,20218,MOROSAGLIA,PONTE LECCIA,42.4476687598,9.25468558646,169,,Morosaglia,Morosaglia,2B,Haute-Corse,94,Corse
2B170,MORSIGLIA,20238,MORSIGLIA,,42.9359197477,9.37347710388,170,,Morsiglia,Morsiglia,2B,Haute-Corse,94,Corse
2B171,MURACCIOLE,20219,MURACCIOLE,,42.1685019963,9.18999511821,171,,Muracciole,Muracciole,2B,Haute-Corse,94,Corse
2B172,MURATO,20239,MURATO,,42.5671895141,9.32704854356,172,,Murato,Murato,2B,Haute-Corse,94,Corse
2B173,MURO,20225,MURO,,42.5460439114,8.9171628153,173,,Muro,Muro,2B,Haute-Corse,94,Corse
2B175,NESSA,20225,NESSA,,42.5498317519,8.95572841625,175,,Nessa,Nessa,2B,Haute-Corse,94,Corse
2B176,NOCARIO,20229,NOCARIO,,42.3951851178,9.34913150736,176,,Nocario,Nocario,2B,Haute-Corse,94,Corse
2B177,NOCETA,20242,NOCETA,,42.2027615346,9.20974478492,177,,Noceta,Noceta,2B,Haute-Corse,94,Corse
2B178,NONZA,20217,NONZA,,42.7882252962,9.35435781818,178,,Nonza,Nonza,2B,Haute-Corse,94,Corse
2B179,NOVALE,20234,NOVALE,,42.3048180295,9.41089162229,179,,Novale,Novale,2B,Haute-Corse,94,Corse
2B180,NOVELLA,20226,NOVELLA,,42.5972996408,9.1035165681,180,,Novella,Novella,2B,Haute-Corse,94,Corse
2B182,OCCHIATANA,20226,OCCHIATANA,,42.59289857,9.00608149281,182,,Occhiatana,Occhiatana,2B,Haute-Corse,94,Corse
2B183,OGLIASTRO,20217,OGLIASTRO,,42.8221560057,9.35418915293,183,,Ogliastro,Ogliastro,2B,Haute-Corse,94,Corse
2B184,OLCANI,20217,OLCANI,,42.8060476882,9.38654004779,184,,Olcani,Olcani,2B,Haute-Corse,94,Corse
2B185,OLETTA,20232,OLETTA,,42.6416261877,9.33321314144,185,,Oletta,Oletta,2B,Haute-Corse,94,Corse
2B187,OLMETA DI CAPOCORSO,20217,OLMETA DI CAPOCORSO,,42.766973441,9.37798697409,187,,Olmeta-di-Capocorso,Olmeta-di-Capocorso,2B,Haute-Corse,94,Corse
2B188,OLMETA DI TUDA,20232,OLMETA DI TUDA,,42.6122409049,9.36435910828,188,,Olmeta-di-Tuda,Olmeta-di-Tuda,2B,Haute-Corse,94,Corse
2B190,OLMI CAPPELLA,20259,OLMI CAPPELLA,,42.5203092978,9.02008756946,190,,Olmi-Cappella,Olmi-Cappella,2B,Haute-Corse,94,Corse
2B192,OLMO,20290,OLMO,,42.5015366466,9.41349985144,192,,Olmo,Olmo,2B,Haute-Corse,94,Corse
2B193,OMESSA,20236,OMESSA,,42.3827760667,9.19362569461,193,,Omessa,Omessa,2B,Haute-Corse,94,Corse
2B193,OMESSA,20236,OMESSA,FRANCARDO,42.3827760667,9.19362569461,193,,Omessa,Omessa,2B,Haute-Corse,94,Corse
2B194,ORTALE,20234,ORTALE,,42.3108287185,9.43462429228,194,,Ortale,Ortale,2B,Haute-Corse,94,Corse
2B195,ORTIPORIO,20290,ORTIPORIO,,42.4562488801,9.34489704974,195,,Ortiporio,Ortiporio,2B,Haute-Corse,94,Corse
2B199,PALASCA,20226,PALASCA,,42.63006979,9.05733396893,199,,Palasca,Palasca,2B,Haute-Corse,94,Corse
2B201,PANCHERACCIA,20251,PANCHERACCIA,,42.1964708452,9.40495119655,201,,Pancheraccia,Pancheraccia,2B,Haute-Corse,94,Corse
2B201,PANCHERACCIA,20270,PANCHERACCIA,CASAPERTA,42.1964708452,9.40495119655,201,,Pancheraccia,Pancheraccia,2B,Haute-Corse,94,Corse
2B202,PARATA,20229,PARATA,,42.3671701227,9.41911803519,202,,Parata,Parata,2B,Haute-Corse,94,Corse
2B205,PATRIMONIO,20253,PATRIMONIO,,42.7066867042,9.3670488048,205,,Patrimonio,Patrimonio,2B,Haute-Corse,94,Corse
2B206,PENTA ACQUATELLA,20290,PENTA ACQUATELLA,,42.4636442257,9.37213628263,206,,Penta-Acquatella,Penta-Acquatella,2B,Haute-Corse,94,Corse
2B207,PENTA DI CASINCA,20213,PENTA DI CASINCA,,42.4548433729,9.49604243043,207,,Penta-di-Casinca,Penta-di-Casinca,2B,Haute-Corse,94,Corse
2B207,PENTA DI CASINCA,20213,PENTA DI CASINCA,FOLELLI,42.4548433729,9.49604243043,207,,Penta-di-Casinca,Penta-di-Casinca,2B,Haute-Corse,94,Corse
2B208,PERELLI,20234,PERELLI,,42.3142542051,9.38908614142,208,,Perelli,Perelli,2B,Haute-Corse,94,Corse
2B210,PERO CASEVECCHIE,20230,PERO CASEVECCHIE,,42.4163942737,9.46376889393,210,,Pero-Casevecchie,Pero-Casevecchie,2B,Haute-Corse,94,Corse
2B213,PIANELLO,20272,PIANELLO,,42.2974342822,9.3564849258,213,,Pianello,Pianello,2B,Haute-Corse,94,Corse
2B214,PIANO,20215,PIANO,,42.4410042851,9.39683527316,214,,Piano,Piano,2B,Haute-Corse,94,Corse
2B216,PIAZZALI,20234,PIAZZALI,,42.3198576834,9.40593909318,216,,Piazzali,Piazzali,2B,Haute-Corse,94,Corse
2B217,PIAZZOLE,20229,PIAZZOLE,,42.3944129733,9.40599362017,217,,Piazzole,Piazzole,2B,Haute-Corse,94,Corse
2B218,PIEDICORTE DI GAGGIO,20251,PIEDICORTE DI GAGGIO,,42.2145192461,9.32336450937,218,,Piedicorte-di-Gaggio,Piedicorte-di-Gaggio,2B,Haute-Corse,94,Corse
2B219,PIEDICROCE,20229,PIEDICROCE,,42.3839264526,9.36811133319,219,,Piedicroce,Piedicroce,2B,Haute-Corse,94,Corse
2B220,PIEDIGRIGGIO,20218,PIEDIGRIGGIO,,42.4465411561,9.18076402904,220,,Piedigriggio,Piedigriggio,2B,Haute-Corse,94,Corse
2B221,PIEDIPARTINO,20229,PIEDIPARTINO,,42.3571679541,9.34439923246,221,,Piedipartino,Piedipartino,2B,Haute-Corse,94,Corse
2B222,PIE D OREZZA,20229,PIE D OREZZA,,42.3741161221,9.33870632307,222,,Pie-d'Orezza,Pie-d'Orezza,2B,Haute-Corse,94,Corse
2B223,PIETRALBA,20218,PIETRALBA,,42.5426494388,9.18325880414,223,,Pietralba,Pietralba,2B,Haute-Corse,94,Corse
2B224,PIETRACORBARA,20233,PIETRACORBARA,,42.8446413084,9.43811996376,224,,Pietracorbara,Pietracorbara,2B,Haute-Corse,94,Corse
2B225,PIETRA DI VERDE,20230,PIETRA DI VERDE,,42.2923417061,9.44429684997,225,,Pietra-di-Verde,Pietra-di-Verde,2B,Haute-Corse,94,Corse
2B226,PIETRASERENA,20251,PIETRASERENA,,42.2338486385,9.35461476082,226,,Pietraserena,Pietraserena,2B,Haute-Corse,94,Corse
2B227,PIETRICAGGIO,20234,PIETRICAGGIO,,42.3292404506,9.37688638759,227,,Pietricaggio,Pietricaggio,2B,Haute-Corse,94,Corse
2B229,PIETROSO,20242,PIETROSO,,42.1256196536,9.30101859911,229,,Pietroso,Pietroso,2B,Haute-Corse,94,Corse
2B230,PIEVE,20246,PIEVE,,42.5857060069,9.27821414573,230,,Piève,Piève,2B,Haute-Corse,94,Corse
2B231,PIGNA,20220,PIGNA,,42.599340833,8.90040924261,231,,Pigna,Pigna,2B,Haute-Corse,94,Corse
2B233,PINO,20228,PINO,,42.9054355936,9.35409723128,233,,Pino,Pino,2B,Haute-Corse,94,Corse
2B234,PIOBETTA,20234,PIOBETTA,,42.340337842,9.36784123009,234,,Piobetta,Piobetta,2B,Haute-Corse,94,Corse
2B235,PIOGGIOLA,20259,PIOGGIOLA,,42.5232293967,8.97529988566,235,,Pioggiola,Pioggiola,2B,Haute-Corse,94,Corse
2B236,POGGIO DI NAZZA,20240,POGGIO DI NAZZA,,42.052431318,9.29203820739,236,,Poggio-di-Nazza,Poggio-di-Nazza,2B,Haute-Corse,94,Corse
2B238,POGGIO DI VENACO,20250,POGGIO DI VENACO,,42.2684750583,9.20706783524,238,,Poggio-di-Venaco,Poggio-di-Venaco,2B,Haute-Corse,94,Corse
2B239,POGGIO D OLETTA,20232,POGGIO D OLETTA,,42.65726441,9.35851486603,239,,Poggio-d'Oletta,Poggio-d'Oletta,2B,Haute-Corse,94,Corse
2B241,POGGIO MARINACCIO,20237,POGGIO MARINACCIO,,42.4359731293,9.34821605679,241,,Poggio-Marinaccio,Poggio-Marinaccio,2B,Haute-Corse,94,Corse
2B242,POGGIO MEZZANA,20230,POGGIO MEZZANA,,42.40140902,9.51195735668,242,,Poggio-Mezzana,Poggio-Mezzana,2B,Haute-Corse,94,Corse
2B243,POLVEROSO,20229,POLVEROSO,,42.4013407453,9.36126897942,243,,Polveroso,Polveroso,2B,Haute-Corse,94,Corse
2B244,POPOLASCA,20218,POPOLASCA,,42.437399462,9.13509572418,244,,Popolasca,Popolasca,2B,Haute-Corse,94,Corse
2B245,PORRI,20215,PORRI,,42.4493786063,9.44236110212,245,,Porri,Porri,2B,Haute-Corse,94,Corse
2B246,LA PORTA,20237,LA PORTA,,42.4241824082,9.35847182054,246,La,Porta,La Porta,2B,Haute-Corse,94,Corse
2B248,PRATO DI GIOVELLINA,20218,PRATO DI GIOVELLINA,,42.4191789343,9.16926425696,248,,Prato-di-Giovellina,Prato-di-Giovellina,2B,Haute-Corse,94,Corse
2B250,PRUNELLI DI CASACCONI,20290,PRUNELLI DI CASACCONI,,42.5104109341,9.40561122356,250,,Prunelli-di-Casacconi,Prunelli-di-Casacconi,2B,Haute-Corse,94,Corse
2B251,PRUNELLI DI FIUMORBO,20243,PRUNELLI DI FIUMORBO,,42.0072330034,9.36772895124,251,,Prunelli-di-Fiumorbo,Prunelli-di-Fiumorbo,2B,Haute-Corse,94,Corse
2B251,PRUNELLI DI FIUMORBO,20243,PRUNELLI DI FIUMORBO,ABBAZIA,42.0072330034,9.36772895124,251,,Prunelli-di-Fiumorbo,Prunelli-di-Fiumorbo,2B,Haute-Corse,94,Corse
2B251,PRUNELLI DI FIUMORBO,20243,PRUNELLI DI FIUMORBO,MORTA,42.0072330034,9.36772895124,251,,Prunelli-di-Fiumorbo,Prunelli-di-Fiumorbo,2B,Haute-Corse,94,Corse
2B252,PRUNO,20213,PRUNO,,42.4243509668,9.44755598733,252,,Pruno,Pruno,2B,Haute-Corse,94,Corse
2B252,PRUNO,20213,PRUNO,CHAMPLAN,42.4243509668,9.44755598733,252,,Pruno,Pruno,2B,Haute-Corse,94,Corse
2B255,QUERCITELLO,20237,QUERCITELLO,,42.4279847152,9.34339471958,255,,Quercitello,Quercitello,2B,Haute-Corse,94,Corse
2B256,RAPAGGIO,20229,RAPAGGIO,,42.3752462314,9.38418728241,256,,Rapaggio,Rapaggio,2B,Haute-Corse,94,Corse
2B257,RAPALE,20246,RAPALE,,42.6204498836,9.29645877204,257,,Rapale,Rapale,2B,Haute-Corse,94,Corse
2B260,RIVENTOSA,20250,RIVENTOSA,,42.2478162384,9.20244057132,260,,Riventosa,Riventosa,2B,Haute-Corse,94,Corse
2B261,ROGLIANO,20247,ROGLIANO,,42.9720512637,9.42539823747,261,,Rogliano,Rogliano,2B,Haute-Corse,94,Corse
2B261,ROGLIANO,20248,ROGLIANO,MACINAGGIO,42.9720512637,9.42539823747,261,,Rogliano,Rogliano,2B,Haute-Corse,94,Corse
2B263,ROSPIGLIANI,20242,ROSPIGLIANI,,42.1937798194,9.237935796,263,,Rospigliani,Rospigliani,2B,Haute-Corse,94,Corse
2B264,RUSIO,20244,RUSIO,,42.3537749145,9.25853604756,264,,Rusio,Rusio,2B,Haute-Corse,94,Corse
2B265,RUTALI,20239,RUTALI,,42.5827324452,9.38160373146,265,,Rutali,Rutali,2B,Haute-Corse,94,Corse
2B267,SALICETO,20218,SALICETO,,42.4110475003,9.26291373059,267,,Saliceto,Saliceto,2B,Haute-Corse,94,Corse
2B273,SCATA,20213,SCATA,,42.4221587053,9.40486548787,273,,Scata,Scata,2B,Haute-Corse,94,Corse
2B274,SCOLCA,20290,SCOLCA,,42.5393358572,9.36456503602,274,,Scolca,Scolca,2B,Haute-Corse,94,Corse
2B275,SERMANO,20212,SERMANO,,42.3157105384,9.26621042677,275,,Sermano,Sermano,2B,Haute-Corse,94,Corse
2B277,SERRA DI FIUMORBO,20243,SERRA DI FIUMORBO,,41.9716708905,9.33015262464,277,,Serra-di-Fiumorbo,Serra-di-Fiumorbo,2B,Haute-Corse,94,Corse
2B280,SILVARECCIO,20215,SILVARECCIO,,42.4530552903,9.41788528717,280,,Silvareccio,Silvareccio,2B,Haute-Corse,94,Corse
2B281,SISCO,20233,SISCO,,42.8144761556,9.44224958213,281,,Sisco,Sisco,2B,Haute-Corse,94,Corse
2B283,SOLARO,20240,SOLARO,,41.8795887459,9.32060656681,283,,Solaro,Solaro,2B,Haute-Corse,94,Corse
2B286,SORBO OCAGNANO,20213,SORBO OCAGNANO,,42.4824735958,9.48942771888,286,,Sorbo-Ocagnano,Sorbo-Ocagnano,2B,Haute-Corse,94,Corse
2B286,SORBO OCAGNANO,20213,SORBO OCAGNANO,QUERCIOLO,42.4824735958,9.48942771888,286,,Sorbo-Ocagnano,Sorbo-Ocagnano,2B,Haute-Corse,94,Corse
2B287,SORIO,20246,SORIO,,42.5778173926,9.24430317014,287,,Sorio,Sorio,2B,Haute-Corse,94,Corse
2B289,SOVERIA,20250,SOVERIA,,42.3496030616,9.14569806657,289,,Soveria,Soveria,2B,Haute-Corse,94,Corse
2B290,SPELONCATO,20226,SPELONCATO,,42.5743163085,8.96966763769,290,,Speloncato,Speloncato,2B,Haute-Corse,94,Corse
2B291,STAZZONA,20229,STAZZONA,,42.379383324,9.37520653497,291,,Stazzona,Stazzona,2B,Haute-Corse,94,Corse
2B292,SANT ANDREA DI BOZIO,20212,SANT ANDREA DI BOZIO,,42.2767589636,9.28470824076,292,,Sant'Andréa-di-Bozio,Sant'Andréa-di-Bozio,2B,Haute-Corse,94,Corse
2B293,SANT ANDREA DI COTONE,20221,SANT ANDREA DI COTONE,,42.3152789524,9.47199287722,293,,Sant'Andréa-di-Cotone,Sant'Andréa-di-Cotone,2B,Haute-Corse,94,Corse
2B296,SANT ANTONINO,20220,SANT ANTONINO,,42.5845277906,8.90827098079,296,,Sant'Antonino,Sant'Antonino,2B,Haute-Corse,94,Corse
2B297,SAN DAMIANO,20213,SAN DAMIANO,,42.4061531707,9.41439045887,297,,San-Damiano,San-Damiano,2B,Haute-Corse,94,Corse
2B298,ST FLORENT,20217,ST FLORENT,,42.6872130129,9.28317002062,298,,Saint-Florent,Saint-Florent,2B,Haute-Corse,94,Corse
2B299,SAN GAVINO D AMPUGNANI,20213,SAN GAVINO D AMPUGNANI,,42.4174866723,9.42542764593,299,,San-Gavino-d'Ampugnani,San-Gavino-d'Ampugnani,2B,Haute-Corse,94,Corse
2B301,SAN GAVINO DI TENDA,20246,SAN GAVINO DI TENDA,,42.6530617344,9.15172283351,301,,San-Gavino-di-Tenda,San-Gavino-di-Tenda,2B,Haute-Corse,94,Corse
2B302,SAN GIOVANNI DI MORIANI,20230,SAN GIOVANNI DI MORIANI,,42.3730963312,9.46541508806,302,,San-Giovanni-di-Moriani,San-Giovanni-di-Moriani,2B,Haute-Corse,94,Corse
2B303,SAN GIULIANO,20230,SAN GIULIANO,,42.2880809404,9.52881178736,303,,San-Giuliano,San-Giuliano,2B,Haute-Corse,94,Corse
2B303,SAN GIULIANO,20230,SAN GIULIANO,ALISTRO,42.2880809404,9.52881178736,303,,San-Giuliano,San-Giuliano,2B,Haute-Corse,94,Corse
2B304,SAN LORENZO,20244,SAN LORENZO,,42.3897522574,9.28923196943,304,,San-Lorenzo,San-Lorenzo,2B,Haute-Corse,94,Corse
2B305,SAN MARTINO DI LOTA,20200,SAN MARTINO DI LOTA,,42.7323992652,9.43163085599,305,,San-Martino-di-Lota,San-Martino-di-Lota,2B,Haute-Corse,94,Corse
2B305,SAN MARTINO DI LOTA,20200,SAN MARTINO DI LOTA,PIETRANERA,42.7323992652,9.43163085599,305,,San-Martino-di-Lota,San-Martino-di-Lota,2B,Haute-Corse,94,Corse
2B306,SANTA LUCIA DI MERCURIO,20250,SANTA LUCIA DI MERCURIO,,42.317846059,9.22025065863,306,,Santa-Lucia-di-Mercurio,Santa-Lucia-di-Mercurio,2B,Haute-Corse,94,Corse
2B307,SANTA LUCIA DI MORIANI,20230,SANTA LUCIA DI MORIANI,,42.3840957627,9.51068685295,307,,Santa-Lucia-di-Moriani,Santa-Lucia-di-Moriani,2B,Haute-Corse,94,Corse
2B309,SANTA MARIA DI LOTA,20200,SANTA MARIA DI LOTA,,42.7511136181,9.42463745833,309,,Santa-Maria-di-Lota,Santa-Maria-di-Lota,2B,Haute-Corse,94,Corse
2B309,SANTA MARIA DI LOTA,20200,SANTA MARIA DI LOTA,MIOMO,42.7511136181,9.42463745833,309,,Santa-Maria-di-Lota,Santa-Maria-di-Lota,2B,Haute-Corse,94,Corse
2B311,SANTA MARIA POGGIO,20221,SANTA MARIA POGGIO,,42.3499153056,9.50880691499,311,,Santa-Maria-Poggio,Santa-Maria-Poggio,2B,Haute-Corse,94,Corse
2B313,SAN NICOLAO,20230,SAN NICOLAO,,42.3681811247,9.50945182928,313,,San-Nicolao,San-Nicolao,2B,Haute-Corse,94,Corse
2B313,SAN NICOLAO,20230,SAN NICOLAO,MORIANI,42.3681811247,9.50945182928,313,,San-Nicolao,San-Nicolao,2B,Haute-Corse,94,Corse
2B313,SAN NICOLAO,20230,SAN NICOLAO,MORIANI PLAGE,42.3681811247,9.50945182928,313,,San-Nicolao,San-Nicolao,2B,Haute-Corse,94,Corse
2B314,SANTO PIETRO DI TENDA,20217,SANTO PIETRO DI TENDA,CASTA,42.6724371425,9.20380969804,314,,Santo-Pietro-di-Tenda,Santo-Pietro-di-Tenda,2B,Haute-Corse,94,Corse
2B314,SANTO PIETRO DI TENDA,20246,SANTO PIETRO DI TENDA,,42.6724371425,9.20380969804,314,,Santo-Pietro-di-Tenda,Santo-Pietro-di-Tenda,2B,Haute-Corse,94,Corse
2B315,SANTO PIETRO DI VENACO,20250,SANTO PIETRO DI VENACO,,42.2451439725,9.16595729369,315,,Santo-Pietro-di-Venaco,Santo-Pietro-di-Venaco,2B,Haute-Corse,94,Corse
2B316,SANTA REPARATA DI BALAGNA,20220,SANTA REPARATA DI BALAGNA,,42.6011280818,8.93155852318,316,,Santa-Reparata-di-Balagna,Santa-Reparata-di-Balagna,2B,Haute-Corse,94,Corse
2B317,SANTA REPARATA DI MORIANI,20230,SANTA REPARATA DI MORIANI,,42.3511091684,9.45558469245,317,,Santa-Reparata-di-Moriani,Santa-Reparata-di-Moriani,2B,Haute-Corse,94,Corse
2B318,TAGLIO ISOLACCIO,20230,TAGLIO ISOLACCIO,,42.4345865472,9.49734774126,318,,Taglio-Isolaccio,Taglio-Isolaccio,2B,Haute-Corse,94,Corse
2B319,TALASANI,20230,TALASANI,,42.4178789576,9.50731616445,319,,Talasani,Talasani,2B,Haute-Corse,94,Corse
2B319,TALASANI,20230,TALASANI,FIGARETTO,42.4178789576,9.50731616445,319,,Talasani,Talasani,2B,Haute-Corse,94,Corse
2B320,TALLONE,20270,TALLONE,,42.1844920407,9.46562453139,320,,Tallone,Tallone,2B,Haute-Corse,94,Corse
2B320,TALLONE,20270,TALLONE,PIANICCIA,42.1844920407,9.46562453139,320,,Tallone,Tallone,2B,Haute-Corse,94,Corse
2B321,TARRANO,20234,TARRANO,,42.3442277413,9.40173128284,321,,Tarrano,Tarrano,2B,Haute-Corse,94,Corse
2B327,TOMINO,20248,TOMINO,,42.9440187067,9.44630145154,327,,Tomino,Tomino,2B,Haute-Corse,94,Corse
2B328,TOX,20270,TOX,,42.2442032331,9.44292921906,328,,Tox,Tox,2B,Haute-Corse,94,Corse
2B329,TRALONCA,20250,TRALONCA,,42.3410940153,9.19284463541,329,,Tralonca,Tralonca,2B,Haute-Corse,94,Corse
2B332,URTACA,20218,URTACA,,42.6207885418,9.14775307705,332,,Urtaca,Urtaca,2B,Haute-Corse,94,Corse
2B333,VALLECALLE,20232,VALLECALLE,,42.5997369417,9.33661797877,333,,Vallecalle,Vallecalle,2B,Haute-Corse,94,Corse
2B334,VALLE D ALESANI,20234,VALLE D ALESANI,,42.3279231457,9.43443873515,334,,Valle-d'Alesani,Valle-d'Alesani,2B,Haute-Corse,94,Corse
2B335,VALLE DI CAMPOLORO,20221,VALLE DI CAMPOLORO,,42.3352411886,9.51479844418,335,,Valle-di-Campoloro,Valle-di-Campoloro,2B,Haute-Corse,94,Corse
2B337,VALLE DI ROSTINO,20235,VALLE DI ROSTINO,,42.4600748824,9.26425666892,337,,Valle-di-Rostino,Valle-di-Rostino,2B,Haute-Corse,94,Corse
2B338,VALLE D OREZZA,20229,VALLE D OREZZA,,42.3623635376,9.40202286355,338,,Valle-d'Orezza,Valle-d'Orezza,2B,Haute-Corse,94,Corse
2B339,VALLICA,20259,VALLICA,,42.517352745,9.05742075675,339,,Vallica,Vallica,2B,Haute-Corse,94,Corse
2B340,VELONE ORNETO,20230,VELONE ORNETO,,42.3913143891,9.45150821019,340,,Velone-Orneto,Velone-Orneto,2B,Haute-Corse,94,Corse
2B341,VENACO,20231,VENACO,,42.213994685,9.13754040802,341,,Venaco,Venaco,2B,Haute-Corse,94,Corse
2B342,VENTISERI,20240,VENTISERI,,41.9410129854,9.36287097036,342,,Ventiseri,Ventiseri,2B,Haute-Corse,94,Corse
2B342,VENTISERI,20240,VENTISERI,MIGNATAJA,41.9410129854,9.36287097036,342,,Ventiseri,Ventiseri,2B,Haute-Corse,94,Corse
2B342,VENTISERI,20240,VENTISERI,TRAVO,41.9410129854,9.36287097036,342,,Ventiseri,Ventiseri,2B,Haute-Corse,94,Corse
2B343,VENZOLASCA,20215,VENZOLASCA,,42.5014054825,9.49333701193,343,,Venzolasca,Venzolasca,2B,Haute-Corse,94,Corse
2B344,VERDESE,20229,VERDESE,,42.3941662475,9.37311777417,344,,Verdèse,Verdèse,2B,Haute-Corse,94,Corse
2B346,VESCOVATO,20215,VESCOVATO,,42.5156004933,9.47327679289,346,,Vescovato,Vescovato,2B,Haute-Corse,94,Corse
2B347,VEZZANI,20242,VEZZANI,,42.1573513565,9.292359887,347,,Vezzani,Vezzani,2B,Haute-Corse,94,Corse
2B350,VIGNALE,20290,VIGNALE,,42.5371661003,9.39510195579,350,,Vignale,Vignale,2B,Haute-Corse,94,Corse
2B352,VILLE DI PARASO,20279,VILLE DI PARASO,,42.5826432898,8.99012410909,352,,Ville-di-Paraso,Ville-di-Paraso,2B,Haute-Corse,94,Corse
2B353,VILLE DI PIETRABUGNO,20200,VILLE DI PIETRABUGNO,,42.7159914002,9.42737933318,353,,Ville-di-Pietrabugno,Ville-di-Pietrabugno,2B,Haute-Corse,94,Corse
2B354,VIVARIO,20219,VIVARIO,,42.1523820761,9.12065538823,354,,Vivario,Vivario,2B,Haute-Corse,94,Corse
2B354,VIVARIO,20219,VIVARIO,TATTONE,42.1523820761,9.12065538823,354,,Vivario,Vivario,2B,Haute-Corse,94,Corse
2B354,VIVARIO,20219,VIVARIO,VIZZAVONA,42.1523820761,9.12065538823,354,,Vivario,Vivario,2B,Haute-Corse,94,Corse
2B355,VOLPAJOLA,20290,VOLPAJOLA,,42.5200129525,9.36071505878,355,,Volpajola,Volpajola,2B,Haute-Corse,94,Corse
2B355,VOLPAJOLA,20290,VOLPAJOLA,BARCHETTA,42.5200129525,9.36071505878,355,,Volpajola,Volpajola,2B,Haute-Corse,94,Corse
2B356,ZALANA,20272,ZALANA,,42.2536105064,9.38216254299,356,,Zalana,Zalana,2B,Haute-Corse,94,Corse
2B361,ZILIA,20214,ZILIA,,42.5186011027,8.90376320006,361,,Zilia,Zilia,2B,Haute-Corse,94,Corse
2B364,ZUANI,20272,ZUANI,,42.264826425,9.34126627348,364,,Zuani,Zuani,2B,Haute-Corse,94,Corse
2B365,SAN GAVINO DI FIUMORBO,20243,SAN GAVINO DI FIUMORBO,,41.9714498244,9.24775602009,365,,San-Gavino-di-Fiumorbo,San-Gavino-di-Fiumorbo,2B,Haute-Corse,94,Corse
2B366,CHISA,20240,CHISA,,41.9210089906,9.26560816948,366,,Chisa,Chisa,2B,Haute-Corse,94,Corse
30001,AIGALIERS,30700,AIGALIERS,,44.0695522099,4.31087905786,1,,Aigaliers,Aigaliers,30,Gard,76,Occitanie
30002,AIGREMONT,30350,AIGREMONT,,43.9613248241,4.11795546515,2,,Aigremont,Aigremont,30,Gard,76,Occitanie
30003,AIGUES MORTES,30220,AIGUES MORTES,,43.5507249635,4.18349802063,3,,Aigues-Mortes,Aigues-Mortes,30,Gard,76,Occitanie
30004,AIGUES VIVES,30670,AIGUES VIVES,,43.7345579232,4.19079446148,4,,Aigues-Vives,Aigues-Vives,30,Gard,76,Occitanie
30005,AIGUEZE,30760,AIGUEZE,,44.3160186564,4.51739879151,5,,Aiguèze,Aiguèze,30,Gard,76,Occitanie
30006,AIMARGUES,30470,AIMARGUES,,43.6717803693,4.20375100125,6,,Aimargues,Aimargues,30,Gard,76,Occitanie
30007,ALES,30100,ALES,,44.1250099126,4.08828501262,7,,Alès,Alès,30,Gard,76,Occitanie
30008,ALLEGRE LES FUMADES,30500,ALLEGRE LES FUMADES,,44.1976013658,4.25339592955,8,,Allègre-les-Fumades,Allègre-les-Fumades,30,Gard,76,Occitanie
30009,ALZON,30770,ALZON,,43.9742117823,3.44750179641,9,,Alzon,Alzon,30,Gard,76,Occitanie
30010,ANDUZE,30140,ANDUZE,,44.0507893674,3.97484165309,10,,Anduze,Anduze,30,Gard,76,Occitanie
30011,LES ANGLES,30133,LES ANGLES,,43.946680289,4.75255256222,11,Les,Angles,Les Angles,30,Gard,76,Occitanie
30012,ARAMON,30390,ARAMON,,43.904199015,4.68302001901,12,,Aramon,Aramon,30,Gard,76,Occitanie
30013,ARGILLIERS,30210,ARGILLIERS,,43.9815740047,4.49083337312,13,,Argilliers,Argilliers,30,Gard,76,Occitanie
30014,ARPAILLARGUES ET AUREILLAC,30700,ARPAILLARGUES ET AUREILLAC,,43.9996930612,4.36386339286,14,,Arpaillargues-et-Aureillac,Arpaillargues-et-Aureillac,30,Gard,76,Occitanie
30015,ARPHY,30120,ARPHY,,44.0416960777,3.58679644026,15,,Arphy,Arphy,30,Gard,76,Occitanie
30016,ARRE,30120,ARRE,,43.9579987719,3.51894390176,16,,Arre,Arre,30,Gard,76,Occitanie
30017,ARRIGAS,30770,ARRIGAS,,43.9902038605,3.48117676072,17,,Arrigas,Arrigas,30,Gard,76,Occitanie
30018,ASPERES,30250,ASPERES,,43.806765526,4.03867369104,18,,Aspères,Aspères,30,Gard,76,Occitanie
30019,AUBAIS,30250,AUBAIS,,43.7490254454,4.1457680936,19,,Aubais,Aubais,30,Gard,76,Occitanie
30020,AUBORD,30620,AUBORD,,43.7512111654,4.31715969164,20,,Aubord,Aubord,30,Gard,76,Occitanie
30021,AUBUSSARGUES,30190,AUBUSSARGUES,,44.0100150626,4.32604375511,21,,Aubussargues,Aubussargues,30,Gard,76,Occitanie
30022,AUJAC,30450,AUJAC,,44.3551368588,4.01860850568,22,,Aujac,Aujac,30,Gard,76,Occitanie
30023,AUJARGUES,30250,AUJARGUES,,43.7914361164,4.1286879695,23,,Aujargues,Aujargues,30,Gard,76,Occitanie
30024,AULAS,30120,AULAS,,43.9989798888,3.58716072515,24,,Aulas,Aulas,30,Gard,76,Occitanie
30025,AUMESSAS,30770,AUMESSAS,,44.0113365754,3.51374005993,25,,Aumessas,Aumessas,30,Gard,76,Occitanie
30026,AVEZE,30120,AVEZE,,43.9759439124,3.59883235381,26,,Avèze,Avèze,30,Gard,76,Occitanie
30027,BAGARD,30140,BAGARD,,44.070391573,4.04387785111,27,,Bagard,Bagard,30,Gard,76,Occitanie
30028,BAGNOLS SUR CEZE,30200,BAGNOLS SUR CEZE,,44.1622496736,4.62477380854,28,,Bagnols-sur-Cèze,Bagnols-sur-Cèze,30,Gard,76,Occitanie
30029,BARJAC,30430,BARJAC,,44.3122415211,4.34841481782,29,,Barjac,Barjac,30,Gard,76,Occitanie
30030,BARON,30700,BARON,,44.0555735383,4.2751122058,30,,Baron,Baron,30,Gard,76,Occitanie
30031,LA BASTIDE D ENGRAS,30330,LA BASTIDE D ENGRAS,,44.0933546759,4.47729781673,31,La,Bastide-d'Engras,La Bastide-d'Engras,30,Gard,76,Occitanie
30032,BEAUCAIRE,30300,BEAUCAIRE,,43.7797429509,4.59380296865,32,,Beaucaire,Beaucaire,30,Gard,76,Occitanie
30033,BEAUVOISIN,30640,BEAUVOISIN,,43.6962644399,4.32219796472,33,,Beauvoisin,Beauvoisin,30,Gard,76,Occitanie
30033,BEAUVOISIN,30640,BEAUVOISIN,FRANQUEVAUX,43.6962644399,4.32219796472,33,,Beauvoisin,Beauvoisin,30,Gard,76,Occitanie
30034,BELLEGARDE,30127,BELLEGARDE,,43.7518139903,4.49573824509,34,,Bellegarde,Bellegarde,30,Gard,76,Occitanie
30035,BELVEZET,30580,BELVEZET,,44.0908103949,4.3554328805,35,,Belvézet,Belvézet,30,Gard,76,Occitanie
30036,BERNIS,30620,BERNIS,,43.7713363574,4.28342960175,36,,Bernis,Bernis,30,Gard,76,Occitanie
30037,BESSEGES,30160,BESSEGES,,44.290395291,4.10784015546,37,,Bessèges,Bessèges,30,Gard,76,Occitanie
30037,BESSEGES,30160,BESSEGES,FOUSSIGNARGUES,44.290395291,4.10784015546,37,,Bessèges,Bessèges,30,Gard,76,Occitanie
30038,BEZ ET ESPARON,30120,BEZ ET ESPARON,,43.9789082922,3.5343559846,38,,Bez-et-Esparon,Bez-et-Esparon,30,Gard,76,Occitanie
30039,BEZOUCE,30320,BEZOUCE,,43.8745362306,4.50048572074,39,,Bezouce,Bezouce,30,Gard,76,Occitanie
30040,BLANDAS,30770,BLANDAS,,43.9210570231,3.50734376894,40,,Blandas,Blandas,30,Gard,76,Occitanie
30041,BLAUZAC,30700,BLAUZAC,,43.9671760777,4.37507310101,41,,Blauzac,Blauzac,30,Gard,76,Occitanie
30042,BOISSET ET GAUJAC,30140,BOISSET ET GAUJAC,,44.0479381794,4.02713622056,42,,Boisset-et-Gaujac,Boisset-et-Gaujac,30,Gard,76,Occitanie
30043,BOISSIERES,30114,BOISSIERES,,43.7701911242,4.22986261144,43,,Boissières,Boissières,30,Gard,76,Occitanie
30044,BONNEVAUX,30450,BONNEVAUX,,44.3722246824,4.03585510313,44,,Bonnevaux,Bonnevaux,30,Gard,76,Occitanie
30045,BORDEZAC,30160,BORDEZAC,,44.3169443278,4.08675409339,45,,Bordezac,Bordezac,30,Gard,76,Occitanie
30046,BOUCOIRAN ET NOZIERES,30190,BOUCOIRAN ET NOZIERES,,43.9938436911,4.17905217935,46,,Boucoiran-et-Nozières,Boucoiran-et-Nozières,30,Gard,76,Occitanie
30047,BOUILLARGUES,30230,BOUILLARGUES,,43.801618461,4.4344842541,47,,Bouillargues,Bouillargues,30,Gard,76,Occitanie
30048,BOUQUET,30580,BOUQUET,,44.1534538358,4.29248194802,48,,Bouquet,Bouquet,30,Gard,76,Occitanie
30049,BOURDIC,30190,BOURDIC,,43.978017691,4.33488113527,49,,Bourdic,Bourdic,30,Gard,76,Occitanie
30050,BRAGASSARGUES,30260,BRAGASSARGUES,,43.9187684688,4.04532393955,50,,Bragassargues,Bragassargues,30,Gard,76,Occitanie
30051,BRANOUX LES TAILLADES,30110,BRANOUX LES TAILLADES,,44.2285932438,3.97464747718,51,,Branoux-les-Taillades,Branoux-les-Taillades,30,Gard,76,Occitanie
30052,BREAU ET SALAGOSSE,30120,BREAU ET SALAGOSSE,,44.0295400705,3.55673412919,52,,Bréau-et-Salagosse,Bréau-et-Salagosse,30,Gard,76,Occitanie
30053,BRIGNON,30190,BRIGNON,,43.9965615202,4.21356610961,53,,Brignon,Brignon,30,Gard,76,Occitanie
30054,BROUZET LES QUISSAC,30260,BROUZET LES QUISSAC,,43.863135128,3.98371432291,54,,Brouzet-lès-Quissac,Brouzet-lès-Quissac,30,Gard,76,Occitanie
30055,BROUZET LES ALES,30580,BROUZET LES ALES,,44.1326190305,4.2508193223,55,,Brouzet-lès-Alès,Brouzet-lès-Alès,30,Gard,76,Occitanie
30056,LA BRUGUIERE,30580,LA BRUGUIERE,,44.1032125221,4.40195083358,56,La,Bruguière,La Bruguière,30,Gard,76,Occitanie
30057,CABRIERES,30210,CABRIERES,,43.9078732541,4.46681487399,57,,Cabrières,Cabrières,30,Gard,76,Occitanie
30058,LA CADIERE ET CAMBO,30170,LA CADIERE ET CAMBO,,43.9631178166,3.80678957516,58,La,Cadière-et-Cambo,La Cadière-et-Cambo,30,Gard,76,Occitanie
30059,LE CAILAR,30740,LE CAILAR,,43.6585975961,4.24246060862,59,Le,Cailar,Le Cailar,30,Gard,76,Occitanie
30060,CAISSARGUES,30132,CAISSARGUES,,43.7920118667,4.39549848347,60,,Caissargues,Caissargues,30,Gard,76,Occitanie
30061,LA CALMETTE,30190,LA CALMETTE,,43.9263163144,4.26355372914,61,La,Calmette,La Calmette,30,Gard,76,Occitanie
30062,CALVISSON,30420,CALVISSON,,43.7876770094,4.18871767018,62,,Calvisson,Calvisson,30,Gard,76,Occitanie
30064,CAMPESTRE ET LUC,30770,CAMPESTRE ET LUC,,43.9384282987,3.40041205655,64,,Campestre-et-Luc,Campestre-et-Luc,30,Gard,76,Occitanie
30065,CANAULES ET ARGENTIERES,30350,CANAULES ET ARGENTIERES,,43.9793142432,4.04842734125,65,,Canaules-et-Argentières,Canaules-et-Argentières,30,Gard,76,Occitanie
30066,CANNES ET CLAIRAN,30260,CANNES ET CLAIRAN,,43.9112568752,4.08467380189,66,,Cannes-et-Clairan,Cannes-et-Clairan,30,Gard,76,Occitanie
30067,LA CAPELLE ET MASMOLENE,30700,LA CAPELLE ET MASMOLENE,,44.0473024322,4.53174105946,67,La,Capelle-et-Masmolène,La Capelle-et-Masmolène,30,Gard,76,Occitanie
30068,CARDET,30350,CARDET,,44.014875409,4.08912365782,68,,Cardet,Cardet,30,Gard,76,Occitanie
30069,CARNAS,30260,CARNAS,,43.8280194179,3.99134225562,69,,Carnas,Carnas,30,Gard,76,Occitanie
30070,CARSAN,30130,CARSAN,,44.2303398227,4.58526557136,70,,Carsan,Carsan,30,Gard,76,Occitanie
30071,CASSAGNOLES,30350,CASSAGNOLES,,44.0194347617,4.1266907842,71,,Cassagnoles,Cassagnoles,30,Gard,76,Occitanie
30072,CASTELNAU VALENCE,30190,CASTELNAU VALENCE,,44.0090295193,4.24382330483,72,,Castelnau-Valence,Castelnau-Valence,30,Gard,76,Occitanie
30073,CASTILLON DU GARD,30210,CASTILLON DU GARD,,43.9781824573,4.55612573863,73,,Castillon-du-Gard,Castillon-du-Gard,30,Gard,76,Occitanie
30074,CAUSSE BEGON,30750,CAUSSE BEGON,,44.0660478253,3.35485547926,74,,Causse-Bégon,Causse-Bégon,30,Gard,76,Occitanie
30075,CAVEIRAC,30820,CAVEIRAC,,43.8338668617,4.2678579617,75,,Caveirac,Caveirac,30,Gard,76,Occitanie
30076,CAVILLARGUES,30330,CAVILLARGUES,,44.1170150817,4.51753065913,76,,Cavillargues,Cavillargues,30,Gard,76,Occitanie
30077,CENDRAS,30480,CENDRAS,,44.150716856,4.03567283331,77,,Cendras,Cendras,30,Gard,76,Occitanie
30079,CHAMBON,30450,CHAMBON,,44.3064594979,4.01942334563,79,,Chambon,Chambon,30,Gard,76,Occitanie
30080,CHAMBORIGAUD,30530,CHAMBORIGAUD,,44.299833692,3.96212811106,80,,Chamborigaud,Chamborigaud,30,Gard,76,Occitanie
30081,CHUSCLAN,30200,CHUSCLAN,,44.1543328002,4.6882962223,81,,Chusclan,Chusclan,30,Gard,76,Occitanie
30081,CHUSCLAN,30200,CHUSCLAN,MARCOULE,44.1543328002,4.6882962223,81,,Chusclan,Chusclan,30,Gard,76,Occitanie
30082,CLARENSAC,30870,CLARENSAC,,43.8371741031,4.22473221709,82,,Clarensac,Clarensac,30,Gard,76,Occitanie
30083,CODOGNAN,30920,CODOGNAN,,43.7226508777,4.2269417423,83,,Codognan,Codognan,30,Gard,76,Occitanie
30084,CODOLET,30200,CODOLET,,44.1274492778,4.70097099002,84,,Codolet,Codolet,30,Gard,76,Occitanie
30085,COLLIAS,30210,COLLIAS,,43.9490522277,4.47176606244,85,,Collias,Collias,30,Gard,76,Occitanie
30086,COLLORGUES,30190,COLLORGUES,,44.0163774455,4.28741311644,86,,Collorgues,Collorgues,30,Gard,76,Occitanie
30087,COLOGNAC,30460,COLOGNAC,,44.0327998105,3.81596701649,87,,Colognac,Colognac,30,Gard,76,Occitanie
30088,COMBAS,30250,COMBAS,,43.8583420282,4.12087764486,88,,Combas,Combas,30,Gard,76,Occitanie
30089,COMPS,30300,COMPS,,43.848697153,4.59238144538,89,,Comps,Comps,30,Gard,76,Occitanie
30090,CONCOULES,30450,CONCOULES,,44.374273791,3.94567245426,90,,Concoules,Concoules,30,Gard,76,Occitanie
30091,CONGENIES,30111,CONGENIES,,43.7752563981,4.15881236462,91,,Congénies,Congénies,30,Gard,76,Occitanie
30092,CONNAUX,30330,CONNAUX,,44.0769164231,4.59924544378,92,,Connaux,Connaux,30,Gard,76,Occitanie
30093,CONQUEYRAC,30170,CONQUEYRAC,,43.938263941,3.90634952219,93,,Conqueyrac,Conqueyrac,30,Gard,76,Occitanie
30094,CORBES,30140,CORBES,,44.0747158788,3.95441606687,94,,Corbès,Corbès,30,Gard,76,Occitanie
30095,CORCONNE,30260,CORCONNE,,43.8722496037,3.9404508858,95,,Corconne,Corconne,30,Gard,76,Occitanie
30096,CORNILLON,30630,CORNILLON,,44.2191528402,4.49474987476,96,,Cornillon,Cornillon,30,Gard,76,Occitanie
30097,COURRY,30500,COURRY,,44.3027020253,4.16098011589,97,,Courry,Courry,30,Gard,76,Occitanie
30098,CRESPIAN,30260,CRESPIAN,,43.8837508539,4.11149749514,98,,Crespian,Crespian,30,Gard,76,Occitanie
30099,CROS,30170,CROS,,44.0012514545,3.82331277161,99,,Cros,Cros,30,Gard,76,Occitanie
30100,CRUVIERS LASCOURS,30360,CRUVIERS LASCOURS,,44.0122958419,4.19264442539,100,,Cruviers-Lascours,Cruviers-Lascours,30,Gard,76,Occitanie
30101,DEAUX,30360,DEAUX,,44.0696746429,4.15790538028,101,,Deaux,Deaux,30,Gard,76,Occitanie
30102,DIONS,30190,DIONS,,43.9259952676,4.29189609942,102,,Dions,Dions,30,Gard,76,Occitanie
30103,DOMAZAN,30390,DOMAZAN,,43.9372823194,4.6563619676,103,,Domazan,Domazan,30,Gard,76,Occitanie
30104,DOMESSARGUES,30350,DOMESSARGUES,,43.9732979744,4.16569285159,104,,Domessargues,Domessargues,30,Gard,76,Occitanie
30105,DOURBIES,30750,DOURBIES,,44.0634660378,3.4803051398,105,,Dourbies,Dourbies,30,Gard,76,Occitanie
30106,DURFORT ET ST MARTIN DE SOSSENAC,30170,DURFORT ET ST MARTIN DE SOSSENAC,,43.9854341188,3.95398099207,106,,Durfort-et-Saint-Martin-de-Sossenac,Durfort-et-Saint-Martin-de-Sossenac,30,Gard,76,Occitanie
30107,ESTEZARGUES,30390,ESTEZARGUES,,43.9542198084,4.63596567123,107,,Estézargues,Estézargues,30,Gard,76,Occitanie
30108,L ESTRECHURE,30124,L ESTRECHURE,,44.0974192863,3.78792074378,108,L',Estréchure,L'Estréchure,30,Gard,76,Occitanie
30109,EUZET,30360,EUZET,,44.0738028889,4.23568352437,109,,Euzet,Euzet,30,Gard,76,Occitanie
30110,FLAUX,30700,FLAUX,,44.0133314652,4.51091483883,110,,Flaux,Flaux,30,Gard,76,Occitanie
30111,FOISSAC,30700,FOISSAC,,44.0356153911,4.2996904256,111,,Foissac,Foissac,30,Gard,76,Occitanie
30112,FONS,30730,FONS,,43.9072744554,4.18054520529,112,,Fons,Fons,30,Gard,76,Occitanie
30113,FONS SUR LUSSAN,30580,FONS SUR LUSSAN,,44.1854366663,4.32720441708,113,,Fons-sur-Lussan,Fons-sur-Lussan,30,Gard,76,Occitanie
30114,FONTANES,30250,FONTANES,,43.8315622077,4.09323827432,114,,Fontanès,Fontanès,30,Gard,76,Occitanie
30115,FONTARECHES,30580,FONTARECHES,,44.1163250744,4.43122211916,115,,Fontarèches,Fontarèches,30,Gard,76,Occitanie
30116,FOURNES,30210,FOURNES,,43.9332827518,4.60385526319,116,,Fournès,Fournès,30,Gard,76,Occitanie
30117,FOURQUES,30300,FOURQUES,,43.7116543785,4.54884323842,117,,Fourques,Fourques,30,Gard,76,Occitanie
30119,FRESSAC,30170,FRESSAC,,43.9964171524,3.9209656443,119,,Fressac,Fressac,30,Gard,76,Occitanie
30120,GAGNIERES,30160,GAGNIERES,,44.315356746,4.12257109543,120,,Gagnières,Gagnières,30,Gard,76,Occitanie
30121,GAILHAN,30260,GAILHAN,,43.8468404118,4.02923303341,121,,Gailhan,Gailhan,30,Gard,76,Occitanie
30122,GAJAN,30730,GAJAN,,43.8985088246,4.22564270538,122,,Gajan,Gajan,30,Gard,76,Occitanie
30123,GALLARGUES LE MONTUEUX,30660,GALLARGUES LE MONTUEUX,,43.716675484,4.17183197752,123,,Gallargues-le-Montueux,Gallargues-le-Montueux,30,Gard,76,Occitanie
30124,LE GARN,30760,LE GARN,,44.3130601565,4.46524537631,124,Le,Garn,Le Garn,30,Gard,76,Occitanie
30125,GARONS,30128,GARONS,,43.7717357731,4.43848561872,125,,Garons,Garons,30,Gard,76,Occitanie
30126,GARRIGUES STE EULALIE,30190,GARRIGUES STE EULALIE,,43.9879024392,4.30556472108,126,,Garrigues-Sainte-Eulalie,Garrigues-Sainte-Eulalie,30,Gard,76,Occitanie
30127,GAUJAC,30330,GAUJAC,,44.0759473205,4.57117388121,127,,Gaujac,Gaujac,30,Gard,76,Occitanie
30128,GENERAC,30510,GENERAC,,43.7236593605,4.35870073363,128,,Générac,Générac,30,Gard,76,Occitanie
30129,GENERARGUES,30140,GENERARGUES,,44.0802693972,3.99219597696,129,,Générargues,Générargues,30,Gard,76,Occitanie
30130,GENOLHAC,30450,GENOLHAC,,44.3439338933,3.95189024113,130,,Génolhac,Génolhac,30,Gard,76,Occitanie
30130,GENOLHAC,30450,GENOLHAC,PONT DE RASTEL,44.3439338933,3.95189024113,130,,Génolhac,Génolhac,30,Gard,76,Occitanie
30131,GOUDARGUES,30630,GOUDARGUES,,44.2089882991,4.43936949575,131,,Goudargues,Goudargues,30,Gard,76,Occitanie
30132,LA GRAND COMBE,30110,LA GRAND COMBE,,44.2271512171,4.02984902103,132,La,Grand-Combe,La Grand-Combe,30,Gard,76,Occitanie
30132,LA GRAND COMBE,30110,LA GRAND COMBE,LA LEVADE,44.2271512171,4.02984902103,132,La,Grand-Combe,La Grand-Combe,30,Gard,76,Occitanie
30133,LE GRAU DU ROI,30240,LE GRAU DU ROI,,43.5072810882,4.16622948662,133,Le,Grau-du-Roi,Le Grau-du-Roi,30,Gard,76,Occitanie
30133,LE GRAU DU ROI,30240,LE GRAU DU ROI,PORT CAMARGUE,43.5072810882,4.16622948662,133,Le,Grau-du-Roi,Le Grau-du-Roi,30,Gard,76,Occitanie
30134,ISSIRAC,30760,ISSIRAC,,44.2705463056,4.47679766776,134,,Issirac,Issirac,30,Gard,76,Occitanie
30135,JONQUIERES ST VINCENT,30300,JONQUIERES ST VINCENT,,43.8279005842,4.55775920877,135,,Jonquières-Saint-Vincent,Jonquières-Saint-Vincent,30,Gard,76,Occitanie
30136,JUNAS,30250,JUNAS,,43.7615067362,4.11752159876,136,,Junas,Junas,30,Gard,76,Occitanie
30137,LAMELOUZE,30110,LAMELOUZE,,44.1973231084,3.96216462612,137,,Lamelouze,Lamelouze,30,Gard,76,Occitanie
30138,LANGLADE,30980,LANGLADE,,43.8045117845,4.25533661815,138,,Langlade,Langlade,30,Gard,76,Occitanie
30139,LANUEJOLS,30750,LANUEJOLS,,44.1272274287,3.37586274913,139,,Lanuéjols,Lanuéjols,30,Gard,76,Occitanie
30140,LASALLE,30460,LASALLE,,44.0428979358,3.85182045128,140,,Lasalle,Lasalle,30,Gard,76,Occitanie
30141,LAUDUN L ARDOISE,30290,LAUDUN L ARDOISE,,44.1029568271,4.66389513091,141,,Laudun-l'Ardoise,Laudun-l'Ardoise,30,Gard,76,Occitanie
30141,LAUDUN L ARDOISE,30290,LAUDUN L ARDOISE,L ARDOISE,44.1029568271,4.66389513091,141,,Laudun-l'Ardoise,Laudun-l'Ardoise,30,Gard,76,Occitanie
30142,LAVAL PRADEL,30110,LAVAL PRADEL,,44.2151183634,4.0646367548,142,,Laval-Pradel,Laval-Pradel,30,Gard,76,Occitanie
30143,LAVAL ST ROMAN,30760,LAVAL ST ROMAN,,44.300208195,4.50443092507,143,,Laval-Saint-Roman,Laval-Saint-Roman,30,Gard,76,Occitanie
30144,LECQUES,30250,LECQUES,,43.8432239983,4.05620169328,144,,Lecques,Lecques,30,Gard,76,Occitanie
30145,LEDENON,30210,LEDENON,,43.9087685833,4.5151493479,145,,Lédenon,Lédenon,30,Gard,76,Occitanie
30146,LEDIGNAN,30350,LEDIGNAN,,43.9854061148,4.10852681331,146,,Lédignan,Lédignan,30,Gard,76,Occitanie
30147,LEZAN,30350,LEZAN,,44.012896173,4.05098256075,147,,Lézan,Lézan,30,Gard,76,Occitanie
30148,LIOUC,30260,LIOUC,,43.8897491816,3.98276323477,148,,Liouc,Liouc,30,Gard,76,Occitanie
30149,LIRAC,30126,LIRAC,,44.0301466935,4.68213328807,149,,Lirac,Lirac,30,Gard,76,Occitanie
30150,LOGRIAN FLORIAN,30610,LOGRIAN FLORIAN,,43.946291935,4.02495373936,150,,Logrian-Florian,Logrian-Florian,30,Gard,76,Occitanie
30151,LUSSAN,30580,LUSSAN,,44.1667929571,4.37557833137,151,,Lussan,Lussan,30,Gard,76,Occitanie
30152,LES MAGES,30960,LES MAGES,,44.2281290306,4.17436325066,152,Les,Mages,Les Mages,30,Gard,76,Occitanie
30153,MALONS ET ELZE,30450,MALONS ET ELZE,,44.4227680052,4.01865417625,153,,Malons-et-Elze,Malons-et-Elze,30,Gard,76,Occitanie
30154,MANDAGOUT,30120,MANDAGOUT,,44.025220492,3.62733015524,154,,Mandagout,Mandagout,30,Gard,76,Occitanie
30155,MANDUEL,30129,MANDUEL,,43.8056903138,4.48826183061,155,,Manduel,Manduel,30,Gard,76,Occitanie
30156,MARGUERITTES,30320,MARGUERITTES,,43.8611502608,4.45020643923,156,,Marguerittes,Marguerittes,30,Gard,76,Occitanie
30157,MARS,30120,MARS,,44.0018593434,3.54814513626,157,,Mars,Mars,30,Gard,76,Occitanie
30158,MARTIGNARGUES,30360,MARTIGNARGUES,,44.0408533992,4.17785978935,158,,Martignargues,Martignargues,30,Gard,76,Occitanie
30159,LE MARTINET,30960,LE MARTINET,,44.2550484499,4.08132775879,159,Le,Martinet,Le Martinet,30,Gard,76,Occitanie
30160,MARUEJOLS LES GARDON,30350,MARUEJOLS LES GARDON,,44.0058651478,4.13598207367,160,,Maruéjols-lès-Gardon,Maruéjols-lès-Gardon,30,Gard,76,Occitanie
30161,MASSANES,30350,MASSANES,,44.0223485002,4.11089321183,161,,Massanes,Massanes,30,Gard,76,Occitanie
30162,MASSILLARGUES ATTUECH,30140,MASSILLARGUES ATTUECH,,44.0156314122,4.02183233884,162,,Massillargues-Attuech,Massillargues-Attuech,30,Gard,76,Occitanie
30163,MAURESSARGUES,30350,MAURESSARGUES,,43.9551103852,4.1622404326,163,,Mauressargues,Mauressargues,30,Gard,76,Occitanie
30164,MEJANNES LE CLAP,30430,MEJANNES LE CLAP,,44.2309207985,4.36426612667,164,,Méjannes-le-Clap,Méjannes-le-Clap,30,Gard,76,Occitanie
30165,MEJANNES LES ALES,30340,MEJANNES LES ALES,,44.10161097,4.15129605543,165,,Méjannes-lès-Alès,Méjannes-lès-Alès,30,Gard,76,Occitanie
30166,MEYNES,30840,MEYNES,,43.8773658406,4.55418222492,166,,Meynes,Meynes,30,Gard,76,Occitanie
30167,MEYRANNES,30410,MEYRANNES,,44.2779055654,4.15935326092,167,,Meyrannes,Meyrannes,30,Gard,76,Occitanie
30168,MIALET,30140,MIALET,,44.1249777837,3.93832235648,168,,Mialet,Mialet,30,Gard,76,Occitanie
30169,MILHAUD,30540,MILHAUD,,43.7887623974,4.30911976796,169,,Milhaud,Milhaud,30,Gard,76,Occitanie
30170,MOLIERES CAVAILLAC,30120,MOLIERES CAVAILLAC,,43.9707308268,3.571824012,170,,Molières-Cavaillac,Molières-Cavaillac,30,Gard,76,Occitanie
30171,MOLIERES SUR CEZE,30410,MOLIERES SUR CEZE,,44.261353293,4.14846746114,171,,Molières-sur-Cèze,Molières-sur-Cèze,30,Gard,76,Occitanie
30172,MONOBLET,30170,MONOBLET,,43.9963876562,3.87875634436,172,,Monoblet,Monoblet,30,Gard,76,Occitanie
30173,MONS,30340,MONS,,44.1210558749,4.17830623987,173,,Mons,Mons,30,Gard,76,Occitanie
30174,MONTAREN ET ST MEDIERS,30700,MONTAREN ET ST MEDIERS,,44.0446191857,4.38296878497,174,,Montaren-et-Saint-Médiers,Montaren-et-Saint-Médiers,30,Gard,76,Occitanie
30175,MONTCLUS,30630,MONTCLUS,,44.2694461878,4.41823005524,175,,Montclus,Montclus,30,Gard,76,Occitanie
30176,MONTDARDIER,30120,MONTDARDIER,,43.9336164212,3.57875988754,176,,Montdardier,Montdardier,30,Gard,76,Occitanie
30177,MONTEILS,30360,MONTEILS,,44.0893365296,4.18124085766,177,,Monteils,Monteils,30,Gard,76,Occitanie
30178,MONTFAUCON,30150,MONTFAUCON,,44.0783218138,4.74279403652,178,,Montfaucon,Montfaucon,30,Gard,76,Occitanie
30179,MONTFRIN,30490,MONTFRIN,,43.8750973783,4.59182307078,179,,Montfrin,Montfrin,30,Gard,76,Occitanie
30180,MONTIGNARGUES,30190,MONTIGNARGUES,,43.9318429657,4.20663063172,180,,Montignargues,Montignargues,30,Gard,76,Occitanie
30181,MONTMIRAT,30260,MONTMIRAT,,43.9030471279,4.117330808,181,,Montmirat,Montmirat,30,Gard,76,Occitanie
30182,MONTPEZAT,30730,MONTPEZAT,,43.8539397379,4.15932886885,182,,Montpezat,Montpezat,30,Gard,76,Occitanie
30183,MOULEZAN,30350,MOULEZAN,,43.9255132059,4.12932302147,183,,Moulézan,Moulézan,30,Gard,76,Occitanie
30184,MOUSSAC,30190,MOUSSAC,,43.9775652657,4.23531660629,184,,Moussac,Moussac,30,Gard,76,Occitanie
30185,MUS,30121,MUS,,43.7371547201,4.20278682851,185,,Mus,Mus,30,Gard,76,Occitanie
30186,NAGES ET SOLORGUES,30114,NAGES ET SOLORGUES,,43.7870618178,4.2329725276,186,,Nages-et-Solorgues,Nages-et-Solorgues,30,Gard,76,Occitanie
30187,NAVACELLES,30580,NAVACELLES,,44.1655136913,4.23593579297,187,,Navacelles,Navacelles,30,Gard,76,Occitanie
30188,NERS,30360,NERS,,44.0243244124,4.15845569543,188,,Ners,Ners,30,Gard,76,Occitanie
30189,NIMES,30000,NIMES,,43.844938394,4.34806796996,189,,Nîmes,Nîmes,30,Gard,76,Occitanie
30189,NIMES,30900,NIMES,,43.844938394,4.34806796996,189,,Nîmes,Nîmes,30,Gard,76,Occitanie
30189,NIMES,30900,NIMES,ST CESAIRE,43.844938394,4.34806796996,189,,Nîmes,Nîmes,30,Gard,76,Occitanie
30190,NOTRE DAME DE LA ROUVIERE,30570,NOTRE DAME DE LA ROUVIERE,,44.0592966102,3.71576905584,190,,Notre-Dame-de-la-Rouvière,Notre-Dame-de-la-Rouvière,30,Gard,76,Occitanie
30191,ORSAN,30200,ORSAN,,44.1348287261,4.66522827508,191,,Orsan,Orsan,30,Gard,76,Occitanie
30192,ORTHOUX SERIGNAC QUILHAN,30260,ORTHOUX SERIGNAC QUILHAN,,43.8876882882,4.0431656403,192,,Orthoux-Sérignac-Quilhan,Orthoux-Sérignac-Quilhan,30,Gard,76,Occitanie
30193,PARIGNARGUES,30730,PARIGNARGUES,,43.8696516481,4.21350667303,193,,Parignargues,Parignargues,30,Gard,76,Occitanie
30194,PEYREMALE,30160,PEYREMALE,,44.2970112153,4.05911180139,194,,Peyremale,Peyremale,30,Gard,76,Occitanie
30195,PEYROLLES,30124,PEYROLLES,,44.1048102089,3.82926189297,195,,Peyrolles,Peyrolles,30,Gard,76,Occitanie
30196,LE PIN,30330,LE PIN,,44.089332023,4.53499161129,196,Le,Pin,Le Pin,30,Gard,76,Occitanie
30197,LES PLANS,30340,LES PLANS,,44.1405053543,4.21212255981,197,Les,Plans,Les Plans,30,Gard,76,Occitanie
30198,LES PLANTIERS,30122,LES PLANTIERS,,44.108139948,3.71311936193,198,Les,Plantiers,Les Plantiers,30,Gard,76,Occitanie
30199,POMMIERS,30120,POMMIERS,,43.9481975416,3.61575937112,199,,Pommiers,Pommiers,30,Gard,76,Occitanie
30200,POMPIGNAN,30170,POMPIGNAN,,43.8962167009,3.85986243613,200,,Pompignan,Pompignan,30,Gard,76,Occitanie
30201,PONTEILS ET BRESIS,30450,PONTEILS ET BRESIS,,44.3981409467,3.96697312804,201,,Ponteils-et-Brésis,Ponteils-et-Brésis,30,Gard,76,Occitanie
30202,PONT ST ESPRIT,30130,PONT ST ESPRIT,,44.2526637065,4.63901945786,202,,Pont-Saint-Esprit,Pont-Saint-Esprit,30,Gard,76,Occitanie
30203,PORTES,30530,PORTES,,44.2634305741,4.03867170883,203,,Portes,Portes,30,Gard,76,Occitanie
30204,POTELIERES,30500,POTELIERES,,44.2243968087,4.22738343545,204,,Potelières,Potelières,30,Gard,76,Occitanie
30205,POUGNADORESSE,30330,POUGNADORESSE,,44.0910913985,4.50932689497,205,,Pougnadoresse,Pougnadoresse,30,Gard,76,Occitanie
30206,POULX,30320,POULX,,43.9145982171,4.42394747626,206,,Poulx,Poulx,30,Gard,76,Occitanie
30207,POUZILHAC,30210,POUZILHAC,,44.0390457714,4.5813397789,207,,Pouzilhac,Pouzilhac,30,Gard,76,Occitanie
30208,PUECHREDON,30610,PUECHREDON,,43.9436241797,4.05543868774,208,,Puechredon,Puechredon,30,Gard,76,Occitanie
30209,PUJAUT,30131,PUJAUT,,43.9993699011,4.76481099943,209,,Pujaut,Pujaut,30,Gard,76,Occitanie
30210,QUISSAC,30260,QUISSAC,,43.9179934684,3.99722251825,210,,Quissac,Quissac,30,Gard,76,Occitanie
30211,REDESSAN,30129,REDESSAN,,43.8345466774,4.51298942933,211,,Redessan,Redessan,30,Gard,76,Occitanie
30212,REMOULINS,30210,REMOULINS,,43.9405719288,4.56488938699,212,,Remoulins,Remoulins,30,Gard,76,Occitanie
30213,REVENS,30750,REVENS,,44.091237523,3.30243079472,213,,Revens,Revens,30,Gard,76,Occitanie
30214,RIBAUTE LES TAVERNES,30720,RIBAUTE LES TAVERNES,,44.0418878865,4.08501586323,214,,Ribaute-les-Tavernes,Ribaute-les-Tavernes,30,Gard,76,Occitanie
30215,RIVIERES,30430,RIVIERES,,44.2144809997,4.28853303845,215,,Rivières,Rivières,30,Gard,76,Occitanie
30216,ROBIAC ROCHESSADOULE,30160,ROBIAC ROCHESSADOULE,,44.272616356,4.10232819302,216,,Robiac-Rochessadoule,Robiac-Rochessadoule,30,Gard,76,Occitanie
30217,ROCHEFORT DU GARD,30650,ROCHEFORT DU GARD,,43.9811688272,4.68155728487,217,,Rochefort-du-Gard,Rochefort-du-Gard,30,Gard,76,Occitanie
30218,ROCHEGUDE,30430,ROCHEGUDE,,44.2390551255,4.28316039005,218,,Rochegude,Rochegude,30,Gard,76,Occitanie
30219,ROGUES,30120,ROGUES,,43.8787778933,3.5616521889,219,,Rogues,Rogues,30,Gard,76,Occitanie
30220,ROQUEDUR,30440,ROQUEDUR,,43.9784209094,3.66272021466,220,,Roquedur,Roquedur,30,Gard,76,Occitanie
30221,ROQUEMAURE,30150,ROQUEMAURE,,44.0414756862,4.7565701251,221,,Roquemaure,Roquemaure,30,Gard,76,Occitanie
30222,LA ROQUE SUR CEZE,30200,LA ROQUE SUR CEZE,,44.1901881744,4.51851871798,222,La,Roque-sur-Cèze,La Roque-sur-Cèze,30,Gard,76,Occitanie
30223,ROUSSON,30340,ROUSSON,,44.1972122109,4.15147892026,223,,Rousson,Rousson,30,Gard,76,Occitanie
30224,LA ROUVIERE,30190,LA ROUVIERE,,43.9199042762,4.24092022842,224,La,Rouvière,La Rouvière,30,Gard,76,Occitanie
30225,SABRAN,30200,SABRAN,,44.1502616277,4.5512334639,225,,Sabran,Sabran,30,Gard,76,Occitanie
30226,ST ALEXANDRE,30130,ST ALEXANDRE,,44.2239447184,4.63170135907,226,,Saint-Alexandre,Saint-Alexandre,30,Gard,76,Occitanie
30227,ST AMBROIX,30500,ST AMBROIX,,44.2524583659,4.19418573198,227,,Saint-Ambroix,Saint-Ambroix,30,Gard,76,Occitanie
30228,STE ANASTASIE,30190,STE ANASTASIE,,43.9319120085,4.34202199629,228,,Sainte-Anastasie,Sainte-Anastasie,30,Gard,76,Occitanie
30229,ST ANDRE DE MAJENCOULES,30570,ST ANDRE DE MAJENCOULES,,44.0274774754,3.67645577466,229,,Saint-André-de-Majencoules,Saint-André-de-Majencoules,30,Gard,76,Occitanie
30230,ST ANDRE DE ROQUEPERTUIS,30630,ST ANDRE DE ROQUEPERTUIS,,44.240660437,4.44801510807,230,,Saint-André-de-Roquepertuis,Saint-André-de-Roquepertuis,30,Gard,76,Occitanie
30231,ST ANDRE DE VALBORGNE,30940,ST ANDRE DE VALBORGNE,,44.1476476774,3.68551072033,231,,Saint-André-de-Valborgne,Saint-André-de-Valborgne,30,Gard,76,Occitanie
30232,ST ANDRE D OLERARGUES,30330,ST ANDRE D OLERARGUES,,44.1653858318,4.4963265641,232,,Saint-André-d'Olérargues,Saint-André-d'Olérargues,30,Gard,76,Occitanie
30233,ST BAUZELY,30730,ST BAUZELY,,43.9219966134,4.19218594435,233,,Saint-Bauzély,Saint-Bauzély,30,Gard,76,Occitanie
30234,ST BENEZET,30350,ST BENEZET,,43.9911168428,4.13585383193,234,,Saint-Bénézet,Saint-Bénézet,30,Gard,76,Occitanie
30235,ST BONNET DU GARD,30210,ST BONNET DU GARD,,43.9271833861,4.53872495872,235,,Saint-Bonnet-du-Gard,Saint-Bonnet-du-Gard,30,Gard,76,Occitanie
30236,ST BONNET DE SALENDRINQUE,30460,ST BONNET DE SALENDRINQUE,,44.0361215826,3.8716582524,236,,Saint-Bonnet-de-Salendrinque,Saint-Bonnet-de-Salendrinque,30,Gard,76,Occitanie
30237,ST BRES,30500,ST BRES,,44.2826357657,4.19801867404,237,,Saint-Brès,Saint-Brès,30,Gard,76,Occitanie
30238,ST BRESSON,30440,ST BRESSON,,43.954635238,3.64404651872,238,,Saint-Bresson,Saint-Bresson,30,Gard,76,Occitanie
30239,STE CECILE D ANDORGE,30110,STE CECILE D ANDORGE,,44.2612523618,3.98352862415,239,,Sainte-Cécile-d'Andorge,Sainte-Cécile-d'Andorge,30,Gard,76,Occitanie
30240,ST CESAIRE DE GAUZIGNAN,30360,ST CESAIRE DE GAUZIGNAN,,44.0366406737,4.20242445704,240,,Saint-Césaire-de-Gauzignan,Saint-Césaire-de-Gauzignan,30,Gard,76,Occitanie
30241,ST CHAPTES,30190,ST CHAPTES,,43.9676006581,4.2748195399,241,,Saint-Chaptes,Saint-Chaptes,30,Gard,76,Occitanie
30242,ST CHRISTOL DE RODIERES,30760,ST CHRISTOL DE RODIERES,,44.265811476,4.5140617936,242,,Saint-Christol-de-Rodières,Saint-Christol-de-Rodières,30,Gard,76,Occitanie
30243,ST CHRISTOL LES ALES,30380,ST CHRISTOL LES ALES,,44.0837854635,4.0766500677,243,,Saint-Christol-lès-Alès,Saint-Christol-lès-Alès,30,Gard,76,Occitanie
30244,ST CLEMENT,30260,ST CLEMENT,,43.8275634838,4.02576741682,244,,Saint-Clément,Saint-Clément,30,Gard,76,Occitanie
30245,ST COME ET MARUEJOLS,30870,ST COME ET MARUEJOLS,,43.8311717606,4.18822528852,245,,Saint-Côme-et-Maruéjols,Saint-Côme-et-Maruéjols,30,Gard,76,Occitanie
30246,STE CROIX DE CADERLE,30460,STE CROIX DE CADERLE,,44.0681849372,3.87021747137,246,,Sainte-Croix-de-Caderle,Sainte-Croix-de-Caderle,30,Gard,76,Occitanie
30247,ST DENIS,30500,ST DENIS,,44.2378320115,4.24892271537,247,,Saint-Denis,Saint-Denis,30,Gard,76,Occitanie
30248,ST DEZERY,30190,ST DEZERY,,43.9956592044,4.26527748265,248,,Saint-Dézéry,Saint-Dézéry,30,Gard,76,Occitanie
30249,ST DIONISY,30980,ST DIONISY,,43.8046137629,4.22332689655,249,,Saint-Dionisy,Saint-Dionisy,30,Gard,76,Occitanie
30250,ST ETIENNE DE L OLM,30360,ST ETIENNE DE L OLM,,44.0631842726,4.183408258,250,,Saint-Étienne-de-l'Olm,Saint-Étienne-de-l'Olm,30,Gard,76,Occitanie
30251,ST ETIENNE DES SORTS,30200,ST ETIENNE DES SORTS,,44.178936222,4.70010353904,251,,Saint-Étienne-des-Sorts,Saint-Étienne-des-Sorts,30,Gard,76,Occitanie
30252,ST FELIX DE PALLIERES,30140,ST FELIX DE PALLIERES,,44.0231873129,3.92590252525,252,,Saint-Félix-de-Pallières,Saint-Félix-de-Pallières,30,Gard,76,Occitanie
30253,ST FLORENT SUR AUZONNET,30960,ST FLORENT SUR AUZONNET,,44.2374003099,4.1069464512,253,,Saint-Florent-sur-Auzonnet,Saint-Florent-sur-Auzonnet,30,Gard,76,Occitanie
30254,ST GENIES DE COMOLAS,30150,ST GENIES DE COMOLAS,,44.0657349298,4.73124185894,254,,Saint-Geniès-de-Comolas,Saint-Geniès-de-Comolas,30,Gard,76,Occitanie
30255,ST GENIES DE MALGOIRES,30190,ST GENIES DE MALGOIRES,,43.94623194,4.2131714959,255,,Saint-Geniès-de-Malgoirès,Saint-Geniès-de-Malgoirès,30,Gard,76,Occitanie
30256,ST GERVAIS,30200,ST GERVAIS,,44.1950468347,4.5792517041,256,,Saint-Gervais,Saint-Gervais,30,Gard,76,Occitanie
30257,ST GERVASY,30320,ST GERVASY,,43.8745230811,4.46960919044,257,,Saint-Gervasy,Saint-Gervasy,30,Gard,76,Occitanie
30258,ST GILLES,30800,ST GILLES,,43.6582880911,4.4077842781,258,,Saint-Gilles,Saint-Gilles,30,Gard,76,Occitanie
30259,ST HILAIRE DE BRETHMAS,30560,ST HILAIRE DE BRETHMAS,,44.0920759418,4.12381898108,259,,Saint-Hilaire-de-Brethmas,Saint-Hilaire-de-Brethmas,30,Gard,76,Occitanie
30260,ST HILAIRE D OZILHAN,30210,ST HILAIRE D OZILHAN,,43.9723003096,4.59999456727,260,,Saint-Hilaire-d'Ozilhan,Saint-Hilaire-d'Ozilhan,30,Gard,76,Occitanie
30261,ST HIPPOLYTE DE CATON,30360,ST HIPPOLYTE DE CATON,,44.0722209366,4.20182055496,261,,Saint-Hippolyte-de-Caton,Saint-Hippolyte-de-Caton,30,Gard,76,Occitanie
30262,ST HIPPOLYTE DE MONTAIGU,30700,ST HIPPOLYTE DE MONTAIGU,,44.033439579,4.49431949045,262,,Saint-Hippolyte-de-Montaigu,Saint-Hippolyte-de-Montaigu,30,Gard,76,Occitanie
30263,ST HIPPOLYTE DU FORT,30170,ST HIPPOLYTE DU FORT,,43.9541335722,3.85306084784,263,,Saint-Hippolyte-du-Fort,Saint-Hippolyte-du-Fort,30,Gard,76,Occitanie
30264,ST JEAN DE CEYRARGUES,30360,ST JEAN DE CEYRARGUES,,44.0547404287,4.22631388818,264,,Saint-Jean-de-Ceyrargues,Saint-Jean-de-Ceyrargues,30,Gard,76,Occitanie
30265,ST JEAN DE CRIEULON,30610,ST JEAN DE CRIEULON,,43.9658514695,3.99917970846,265,,Saint-Jean-de-Crieulon,Saint-Jean-de-Crieulon,30,Gard,76,Occitanie
30266,ST JEAN DE MARUEJOLS ET AVEJAN,30430,ST JEAN DE MARUEJOLS ET AVEJAN,,44.2671009093,4.30048021617,266,,Saint-Jean-de-Maruéjols-et-Avéjan,Saint-Jean-de-Maruéjols-et-Avéjan,30,Gard,76,Occitanie
30267,ST JEAN DE SERRES,30350,ST JEAN DE SERRES,,43.9898474305,4.0762512043,267,,Saint-Jean-de-Serres,Saint-Jean-de-Serres,30,Gard,76,Occitanie
30268,ST JEAN DE VALERISCLE,30960,ST JEAN DE VALERISCLE,,44.2344720067,4.14029617463,268,,Saint-Jean-de-Valériscle,Saint-Jean-de-Valériscle,30,Gard,76,Occitanie
30269,ST JEAN DU GARD,30270,ST JEAN DU GARD,,44.1071750382,3.87785905992,269,,Saint-Jean-du-Gard,Saint-Jean-du-Gard,30,Gard,76,Occitanie
30270,ST JEAN DU PIN,30140,ST JEAN DU PIN,,44.1164232741,4.0327398577,270,,Saint-Jean-du-Pin,Saint-Jean-du-Pin,30,Gard,76,Occitanie
30271,ST JULIEN DE CASSAGNAS,30500,ST JULIEN DE CASSAGNAS,,44.2129255988,4.20424257202,271,,Saint-Julien-de-Cassagnas,Saint-Julien-de-Cassagnas,30,Gard,76,Occitanie
30272,ST JULIEN DE LA NEF,30440,ST JULIEN DE LA NEF,,43.9627238994,3.68479655301,272,,Saint-Julien-de-la-Nef,Saint-Julien-de-la-Nef,30,Gard,76,Occitanie
30273,ST JULIEN DE PEYROLAS,30760,ST JULIEN DE PEYROLAS,,44.2832227467,4.56872809849,273,,Saint-Julien-de-Peyrolas,Saint-Julien-de-Peyrolas,30,Gard,76,Occitanie
30274,ST JULIEN LES ROSIERS,30340,ST JULIEN LES ROSIERS,,44.1899717344,4.10039832397,274,,Saint-Julien-les-Rosiers,Saint-Julien-les-Rosiers,30,Gard,76,Occitanie
30275,ST JUST ET VACQUIERES,30580,ST JUST ET VACQUIERES,,44.1005216678,4.23587499395,275,,Saint-Just-et-Vacquières,Saint-Just-et-Vacquières,30,Gard,76,Occitanie
30276,ST LAURENT D AIGOUZE,30220,ST LAURENT D AIGOUZE,,43.5704667383,4.24257576884,276,,Saint-Laurent-d'Aigouze,Saint-Laurent-d'Aigouze,30,Gard,76,Occitanie
30277,ST LAURENT DE CARNOLS,30200,ST LAURENT DE CARNOLS,,44.2256485966,4.53204790849,277,,Saint-Laurent-de-Carnols,Saint-Laurent-de-Carnols,30,Gard,76,Occitanie
30278,ST LAURENT DES ARBRES,30126,ST LAURENT DES ARBRES,,44.0556997374,4.69243062934,278,,Saint-Laurent-des-Arbres,Saint-Laurent-des-Arbres,30,Gard,76,Occitanie
30279,ST LAURENT LA VERNEDE,30330,ST LAURENT LA VERNEDE,,44.1117248991,4.4613624707,279,,Saint-Laurent-la-Vernède,Saint-Laurent-la-Vernède,30,Gard,76,Occitanie
30280,ST LAURENT LE MINIER,30440,ST LAURENT LE MINIER,,43.9251433166,3.65288683571,280,,Saint-Laurent-le-Minier,Saint-Laurent-le-Minier,30,Gard,76,Occitanie
30281,ST MAMERT DU GARD,30730,ST MAMERT DU GARD,,43.883428013,4.17283448952,281,,Saint-Mamert-du-Gard,Saint-Mamert-du-Gard,30,Gard,76,Occitanie
30282,ST MARCEL DE CAREIRET,30330,ST MARCEL DE CAREIRET,,44.1419755663,4.4845481491,282,,Saint-Marcel-de-Careiret,Saint-Marcel-de-Careiret,30,Gard,76,Occitanie
30283,ST MARTIAL,30440,ST MARTIAL,,44.0382099804,3.74399705326,283,,Saint-Martial,Saint-Martial,30,Gard,76,Occitanie
30284,ST MARTIN DE VALGALGUES,30520,ST MARTIN DE VALGALGUES,,44.1658828602,4.0750003608,284,,Saint-Martin-de-Valgalgues,Saint-Martin-de-Valgalgues,30,Gard,76,Occitanie
30285,ST MAURICE DE CAZEVIEILLE,30360,ST MAURICE DE CAZEVIEILLE,,44.0353174953,4.24315062055,285,,Saint-Maurice-de-Cazevieille,Saint-Maurice-de-Cazevieille,30,Gard,76,Occitanie
30286,ST MAXIMIN,30700,ST MAXIMIN,,43.9881019257,4.45516450439,286,,Saint-Maximin,Saint-Maximin,30,Gard,76,Occitanie
30287,ST MICHEL D EUZET,30200,ST MICHEL D EUZET,,44.2062878701,4.55120347402,287,,Saint-Michel-d'Euzet,Saint-Michel-d'Euzet,30,Gard,76,Occitanie
30288,ST NAZAIRE,30200,ST NAZAIRE,,44.2000873648,4.62191805958,288,,Saint-Nazaire,Saint-Nazaire,30,Gard,76,Occitanie
30289,ST NAZAIRE DES GARDIES,30610,ST NAZAIRE DES GARDIES,,43.9803874312,4.0156093485,289,,Saint-Nazaire-des-Gardies,Saint-Nazaire-des-Gardies,30,Gard,76,Occitanie
30290,ST PAULET DE CAISSON,30130,ST PAULET DE CAISSON,,44.2607888624,4.58682211598,290,,Saint-Paulet-de-Caisson,Saint-Paulet-de-Caisson,30,Gard,76,Occitanie
30291,ST PAUL LA COSTE,30480,ST PAUL LA COSTE,,44.1540122871,3.97020875804,291,,Saint-Paul-la-Coste,Saint-Paul-la-Coste,30,Gard,76,Occitanie
30292,ST PONS LA CALM,30330,ST PONS LA CALM,,44.0990342324,4.55422780097,292,,Saint-Pons-la-Calm,Saint-Pons-la-Calm,30,Gard,76,Occitanie
30293,ST PRIVAT DE CHAMPCLOS,30430,ST PRIVAT DE CHAMPCLOS,,44.2763763526,4.35555026236,293,,Saint-Privat-de-Champclos,Saint-Privat-de-Champclos,30,Gard,76,Occitanie
30294,ST PRIVAT DES VIEUX,30340,ST PRIVAT DES VIEUX,,44.1416859983,4.13063351815,294,,Saint-Privat-des-Vieux,Saint-Privat-des-Vieux,30,Gard,76,Occitanie
30295,ST QUENTIN LA POTERIE,30700,ST QUENTIN LA POTERIE,,44.057337074,4.43872425053,295,,Saint-Quentin-la-Poterie,Saint-Quentin-la-Poterie,30,Gard,76,Occitanie
30296,ST ROMAN DE CODIERES,30440,ST ROMAN DE CODIERES,,44.0123424811,3.77355392484,296,,Saint-Roman-de-Codières,Saint-Roman-de-Codières,30,Gard,76,Occitanie
30297,ST SAUVEUR CAMPRIEU,30750,ST SAUVEUR CAMPRIEU,,44.1045314812,3.47514344344,297,,Saint-Sauveur-Camprieu,Saint-Sauveur-Camprieu,30,Gard,76,Occitanie
30297,ST SAUVEUR CAMPRIEU,30750,ST SAUVEUR CAMPRIEU,CAMPRIEU,44.1045314812,3.47514344344,297,,Saint-Sauveur-Camprieu,Saint-Sauveur-Camprieu,30,Gard,76,Occitanie
30298,ST SEBASTIEN D AIGREFEUILLE,30140,ST SEBASTIEN D AIGREFEUILLE,,44.1146870465,3.99030045103,298,,Saint-Sébastien-d'Aigrefeuille,Saint-Sébastien-d'Aigrefeuille,30,Gard,76,Occitanie
30299,ST SIFFRET,30700,ST SIFFRET,,44.0153596124,4.46857345963,299,,Saint-Siffret,Saint-Siffret,30,Gard,76,Occitanie
30300,ST THEODORIT,30260,ST THEODORIT,,43.9433900871,4.08343704008,300,,Saint-Théodorit,Saint-Théodorit,30,Gard,76,Occitanie
30301,ST VICTOR DES OULES,30700,ST VICTOR DES OULES,,44.0432790146,4.4844159444,301,,Saint-Victor-des-Oules,Saint-Victor-des-Oules,30,Gard,76,Occitanie
30302,ST VICTOR LA COSTE,30290,ST VICTOR LA COSTE,,44.0551218504,4.64162593876,302,,Saint-Victor-la-Coste,Saint-Victor-la-Coste,30,Gard,76,Occitanie
30303,ST VICTOR DE MALCAP,30500,ST VICTOR DE MALCAP,,44.2544238833,4.23077320622,303,,Saint-Victor-de-Malcap,Saint-Victor-de-Malcap,30,Gard,76,Occitanie
30304,SALAZAC,30760,SALAZAC,,44.2565156091,4.53650899935,304,,Salazac,Salazac,30,Gard,76,Occitanie
30305,SALINDRES,30340,SALINDRES,,44.1650140999,4.16135836746,305,,Salindres,Salindres,30,Gard,76,Occitanie
30306,SALINELLES,30250,SALINELLES,,43.8113201948,4.06673339715,306,,Salinelles,Salinelles,30,Gard,76,Occitanie
30307,LES SALLES DU GARDON,30110,LES SALLES DU GARDON,,44.194603836,4.01746803987,307,Les,Salles-du-Gardon,Les Salles-du-Gardon,30,Gard,76,Occitanie
30308,SANILHAC SAGRIES,30700,SANILHAC SAGRIES,,43.9548767362,4.4196061912,308,,Sanilhac-Sagriès,Sanilhac-Sagriès,30,Gard,76,Occitanie
30309,SARDAN,30260,SARDAN,,43.8666268066,4.03456687975,309,,Sardan,Sardan,30,Gard,76,Occitanie
30310,SAUMANE,30125,SAUMANE,,44.1191109205,3.76891045379,310,,Saumane,Saumane,30,Gard,76,Occitanie
30311,SAUVE,30610,SAUVE,,43.9373635895,3.95465985604,311,,Sauve,Sauve,30,Gard,76,Occitanie
30312,SAUVETERRE,30150,SAUVETERRE,,44.0168058845,4.80890320388,312,,Sauveterre,Sauveterre,30,Gard,76,Occitanie
30313,SAUZET,30190,SAUZET,,43.962257461,4.21739461052,313,,Sauzet,Sauzet,30,Gard,76,Occitanie
30314,SAVIGNARGUES,30350,SAVIGNARGUES,,43.9664958937,4.0802622327,314,,Savignargues,Savignargues,30,Gard,76,Occitanie
30315,SAZE,30650,SAZE,,43.9447000008,4.69826538851,315,,Saze,Saze,30,Gard,76,Occitanie
30316,SENECHAS,30450,SENECHAS,,44.3355438753,4.00159676417,316,,Sénéchas,Sénéchas,30,Gard,76,Occitanie
30317,SERNHAC,30210,SERNHAC,,43.9119026242,4.56096710639,317,,Sernhac,Sernhac,30,Gard,76,Occitanie
30318,SERVAS,30340,SERVAS,,44.1561490633,4.19299074511,318,,Servas,Servas,30,Gard,76,Occitanie
30319,SERVIERS ET LABAUME,30700,SERVIERS ET LABAUME,,44.0381898089,4.3433728744,319,,Serviers-et-Labaume,Serviers-et-Labaume,30,Gard,76,Occitanie
30320,SEYNES,30580,SEYNES,,44.1120676608,4.29388522611,320,,Seynes,Seynes,30,Gard,76,Occitanie
30321,SOMMIERES,30250,SOMMIERES,,43.7756118498,4.08232743857,321,,Sommières,Sommières,30,Gard,76,Occitanie
30322,SOUDORGUES,30460,SOUDORGUES,,44.0661076711,3.80654189355,322,,Soudorgues,Soudorgues,30,Gard,76,Occitanie
30323,SOUSTELLE,30110,SOUSTELLE,,44.1724100561,4.00032794354,323,,Soustelle,Soustelle,30,Gard,76,Occitanie
30324,SOUVIGNARGUES,30250,SOUVIGNARGUES,,43.8195872223,4.13553347163,324,,Souvignargues,Souvignargues,30,Gard,76,Occitanie
30325,SUMENE,30440,SUMENE,,43.9885776737,3.73217518553,325,,Sumène,Sumène,30,Gard,76,Occitanie
30325,SUMENE,30440,SUMENE,PONT D HERAULT,43.9885776737,3.73217518553,325,,Sumène,Sumène,30,Gard,76,Occitanie
30326,TAVEL,30126,TAVEL,,44.0091067079,4.69193151611,326,,Tavel,Tavel,30,Gard,76,Occitanie
30327,THARAUX,30430,THARAUX,,44.2419428616,4.32299911725,327,,Tharaux,Tharaux,30,Gard,76,Occitanie
30328,THEZIERS,30390,THEZIERS,,43.9037474433,4.6260789572,328,,Théziers,Théziers,30,Gard,76,Occitanie
30329,THOIRAS,30140,THOIRAS,,44.0651729545,3.92069060431,329,,Thoiras,Thoiras,30,Gard,76,Occitanie
30330,TORNAC,30140,TORNAC,,44.0186321006,3.98505349689,330,,Tornac,Tornac,30,Gard,76,Occitanie
30331,TRESQUES,30330,TRESQUES,,44.116981743,4.59282307489,331,,Tresques,Tresques,30,Gard,76,Occitanie
30332,TREVES,30750,TREVES,,44.0700313449,3.39129875689,332,,Trèves,Trèves,30,Gard,76,Occitanie
30333,UCHAUD,30620,UCHAUD,,43.7590865488,4.26505748262,333,,Uchaud,Uchaud,30,Gard,76,Occitanie
30334,UZES,30700,UZES,,44.0136590402,4.41600031556,334,,Uzès,Uzès,30,Gard,76,Occitanie
30335,VABRES,30460,VABRES,,44.0265776812,3.88553119423,335,,Vabres,Vabres,30,Gard,76,Occitanie
30336,VALLABREGUES,30300,VALLABREGUES,,43.8567379511,4.63352025788,336,,Vallabrègues,Vallabrègues,30,Gard,76,Occitanie
30337,VALLABRIX,30700,VALLABRIX,,44.0656231815,4.48392568742,337,,Vallabrix,Vallabrix,30,Gard,76,Occitanie
30338,VALLERARGUES,30580,VALLERARGUES,,44.1268462189,4.35725202722,338,,Vallérargues,Vallérargues,30,Gard,76,Occitanie
30339,VALLERAUGUE,30570,VALLERAUGUE,,44.0875534961,3.61946476562,339,,Valleraugue,Valleraugue,30,Gard,76,Occitanie
30339,VALLERAUGUE,30570,VALLERAUGUE,L ESPEROU,44.0875534961,3.61946476562,339,,Valleraugue,Valleraugue,30,Gard,76,Occitanie
30339,VALLERAUGUE,30570,VALLERAUGUE,PONT D HERAULT,44.0875534961,3.61946476562,339,,Valleraugue,Valleraugue,30,Gard,76,Occitanie
30340,VALLIGUIERES,30210,VALLIGUIERES,,44.0080792047,4.58999068542,340,,Valliguières,Valliguières,30,Gard,76,Occitanie
30341,VAUVERT,30600,VAUVERT,,43.6258468531,4.30597093294,341,,Vauvert,Vauvert,30,Gard,76,Occitanie
30341,VAUVERT,30600,VAUVERT,GALLICIAN,43.6258468531,4.30597093294,341,,Vauvert,Vauvert,30,Gard,76,Occitanie
30341,VAUVERT,30600,VAUVERT,MONTCALM,43.6258468531,4.30597093294,341,,Vauvert,Vauvert,30,Gard,76,Occitanie
30341,VAUVERT,30600,VAUVERT,SYLVEREAL,43.6258468531,4.30597093294,341,,Vauvert,Vauvert,30,Gard,76,Occitanie
30342,VENEJAN,30200,VENEJAN,,44.198514501,4.66816449365,342,,Vénéjan,Vénéjan,30,Gard,76,Occitanie
30343,VERFEUIL,30630,VERFEUIL,,44.1658255744,4.44866506669,343,,Verfeuil,Verfeuil,30,Gard,76,Occitanie
30344,VERGEZE,30310,VERGEZE,,43.7375360363,4.23360990528,344,,Vergèze,Vergèze,30,Gard,76,Occitanie
30345,LA VERNAREDE,30530,LA VERNAREDE,,44.2842521059,4.00752178813,345,La,Vernarède,La Vernarède,30,Gard,76,Occitanie
30346,VERS PONT DU GARD,30210,VERS PONT DU GARD,,43.9668458822,4.51945060223,346,,Vers-Pont-du-Gard,Vers-Pont-du-Gard,30,Gard,76,Occitanie
30347,VESTRIC ET CANDIAC,30600,VESTRIC ET CANDIAC,,43.7360708726,4.26452194567,347,,Vestric-et-Candiac,Vestric-et-Candiac,30,Gard,76,Occitanie
30348,VEZENOBRES,30360,VEZENOBRES,,44.0510480255,4.13455182602,348,,Vézénobres,Vézénobres,30,Gard,76,Occitanie
30349,VIC LE FESQ,30260,VIC LE FESQ,,43.8679462707,4.07716172546,349,,Vic-le-Fesq,Vic-le-Fesq,30,Gard,76,Occitanie
30350,LE VIGAN,30120,LE VIGAN,,43.9886565575,3.62522883405,350,Le,Vigan,Le Vigan,30,Gard,76,Occitanie
30351,VILLENEUVE LES AVIGNON,30400,VILLENEUVE LES AVIGNON,,43.9771673582,4.79466040665,351,,Villeneuve-lès-Avignon,Villeneuve-lès-Avignon,30,Gard,76,Occitanie
30352,VILLEVIEILLE,30250,VILLEVIEILLE,,43.7960985026,4.10074898591,352,,Villevieille,Villevieille,30,Gard,76,Occitanie
30353,VISSEC,30770,VISSEC,,43.8965012071,3.45303005726,353,,Vissec,Vissec,30,Gard,76,Occitanie
30354,MONTAGNAC,30350,MONTAGNAC,,43.9375478225,4.1527019626,354,,Montagnac,Montagnac,30,Gard,76,Occitanie
30355,ST PAUL LES FONTS,30330,ST PAUL LES FONTS,,44.0756512513,4.61675819093,355,,Saint-Paul-les-Fonts,Saint-Paul-les-Fonts,30,Gard,76,Occitanie
30356,RODILHAN,30230,RODILHAN,,43.825503937,4.43410669109,356,,Rodilhan,Rodilhan,30,Gard,76,Occitanie
31001,AGASSAC,31230,AGASSAC,,43.3720949705,0.885301441873,1,,Agassac,Agassac,31,Haute-Garonne,76,Occitanie
31002,AIGNES,31550,AIGNES,,43.3349232679,1.58645774821,2,,Aignes,Aignes,31,Haute-Garonne,76,Occitanie
31003,AIGREFEUILLE,31280,AIGREFEUILLE,,43.5693635882,1.58421445375,3,,Aigrefeuille,Aigrefeuille,31,Haute-Garonne,76,Occitanie
31004,AYGUESVIVES,31450,AYGUESVIVES,,43.4290243945,1.59439448927,4,,Ayguesvives,Ayguesvives,31,Haute-Garonne,76,Occitanie
31005,ALAN,31420,ALAN,,43.2200878042,0.927625726585,5,,Alan,Alan,31,Haute-Garonne,76,Occitanie
31006,ALBIAC,31460,ALBIAC,,43.5558012046,1.78268278702,6,,Albiac,Albiac,31,Haute-Garonne,76,Occitanie
31007,AMBAX,31230,AMBAX,,43.3680277125,0.937034657846,7,,Ambax,Ambax,31,Haute-Garonne,76,Occitanie
31008,ANAN,31230,ANAN,,43.3522518749,0.810075633308,8,,Anan,Anan,31,Haute-Garonne,76,Occitanie
31009,ANTICHAN DE FRONTIGNES,31510,ANTICHAN DE FRONTIGNES,,42.9696953465,0.676907980365,9,,Antichan-de-Frontignes,Antichan-de-Frontignes,31,Haute-Garonne,76,Occitanie
31010,ANTIGNAC,31110,ANTIGNAC,,42.8311657614,0.587701526157,10,,Antignac,Antignac,31,Haute-Garonne,76,Occitanie
31011,ARBAS,31160,ARBAS,,42.9925595052,0.905628547412,11,,Arbas,Arbas,31,Haute-Garonne,76,Occitanie
31012,ARBON,31160,ARBON,,43.000904094,0.731075064686,12,,Arbon,Arbon,31,Haute-Garonne,76,Occitanie
31013,ARDIEGE,31210,ARDIEGE,,43.0667699712,0.64401533309,13,,Ardiège,Ardiège,31,Haute-Garonne,76,Occitanie
31014,ARGUENOS,31160,ARGUENOS,,42.9592233941,0.72693971703,14,,Arguenos,Arguenos,31,Haute-Garonne,76,Occitanie
31015,ARGUT DESSOUS,31440,ARGUT DESSOUS,,42.8924379244,0.715516624447,15,,Argut-Dessous,Argut-Dessous,31,Haute-Garonne,76,Occitanie
31017,ARLOS,31440,ARLOS,,42.874241036,0.695211627437,17,,Arlos,Arlos,31,Haute-Garonne,76,Occitanie
31018,ARNAUD GUILHEM,31360,ARNAUD GUILHEM,,43.1465237831,0.899110471667,18,,Arnaud-Guilhem,Arnaud-Guilhem,31,Haute-Garonne,76,Occitanie
31019,ARTIGUE,31110,ARTIGUE,,42.8264001411,0.63971982942,19,,Artigue,Artigue,31,Haute-Garonne,76,Occitanie
31020,ASPET,31160,ASPET,,43.0114092218,0.805918611708,20,,Aspet,Aspet,31,Haute-Garonne,76,Occitanie
31021,ASPRET SARRAT,31800,ASPRET SARRAT,,43.0698319191,0.720036788419,21,,Aspret-Sarrat,Aspret-Sarrat,31,Haute-Garonne,76,Occitanie
31022,AUCAMVILLE,31140,AUCAMVILLE,,43.6713968117,1.42370910767,22,,Aucamville,Aucamville,31,Haute-Garonne,76,Occitanie
31023,AULON,31420,AULON,,43.190035341,0.813417936999,23,,Aulon,Aulon,31,Haute-Garonne,76,Occitanie
31024,AURAGNE,31190,AURAGNE,,43.3912857783,1.50909342818,24,,Auragne,Auragne,31,Haute-Garonne,76,Occitanie
31025,AUREVILLE,31320,AUREVILLE,,43.4804161473,1.45712269625,25,,Aureville,Aureville,31,Haute-Garonne,76,Occitanie
31026,AURIAC SUR VENDINELLE,31460,AURIAC SUR VENDINELLE,,43.519408834,1.82440314638,26,,Auriac-sur-Vendinelle,Auriac-sur-Vendinelle,31,Haute-Garonne,76,Occitanie
31027,AURIBAIL,31190,AURIBAIL,,43.3464885877,1.37524698634,27,,Auribail,Auribail,31,Haute-Garonne,76,Occitanie
31028,AURIGNAC,31420,AURIGNAC,,43.2137172821,0.871496161348,28,,Aurignac,Aurignac,31,Haute-Garonne,76,Occitanie
31029,AURIN,31570,AURIN,,43.5351953574,1.68120009719,29,,Aurin,Aurin,31,Haute-Garonne,76,Occitanie
31030,AUSSEING,31260,AUSSEING,,43.1540611652,1.03126801796,30,,Ausseing,Ausseing,31,Haute-Garonne,76,Occitanie
31031,AUSSON,31210,AUSSON,,43.0915876705,0.589975626665,31,,Ausson,Ausson,31,Haute-Garonne,76,Occitanie
31032,AUSSONNE,31840,AUSSONNE,,43.6836034494,1.32887054853,32,,Aussonne,Aussonne,31,Haute-Garonne,76,Occitanie
31033,AUTERIVE,31190,AUTERIVE,,43.3595991576,1.47742796554,33,,Auterive,Auterive,31,Haute-Garonne,76,Occitanie
31034,AUZAS,31360,AUZAS,,43.1744807427,0.894158891084,34,,Auzas,Auzas,31,Haute-Garonne,76,Occitanie
31035,AUZEVILLE TOLOSANE,31320,AUZEVILLE TOLOSANE,,43.5286122552,1.48721978973,35,,Auzeville-Tolosane,Auzeville-Tolosane,31,Haute-Garonne,76,Occitanie
31036,AUZIELLE,31650,AUZIELLE,,43.540140769,1.56831984014,36,,Auzielle,Auzielle,31,Haute-Garonne,76,Occitanie
31037,AVIGNONET LAURAGAIS,31290,AVIGNONET LAURAGAIS,,43.3769712959,1.77272704833,37,,Avignonet-Lauragais,Avignonet-Lauragais,31,Haute-Garonne,76,Occitanie
31038,AZAS,31380,AZAS,,43.7200477116,1.67437358788,38,,Azas,Azas,31,Haute-Garonne,76,Occitanie
31039,BACHAS,31420,BACHAS,,43.2487475475,0.941734227527,39,,Bachas,Bachas,31,Haute-Garonne,76,Occitanie
31040,BACHOS,31440,BACHOS,,42.8985486101,0.604716422837,40,,Bachos,Bachos,31,Haute-Garonne,76,Occitanie
31041,BAGIRY,31510,BAGIRY,,42.9798586112,0.624492350952,41,,Bagiry,Bagiry,31,Haute-Garonne,76,Occitanie
31042,BAGNERES DE LUCHON,31110,BAGNERES DE LUCHON,,42.736147731,0.626640395614,42,,Bagnères-de-Luchon,Bagnères-de-Luchon,31,Haute-Garonne,76,Occitanie
31042,BAGNERES DE LUCHON,31110,BAGNERES DE LUCHON,SUPERBAGNERES,42.736147731,0.626640395614,42,,Bagnères-de-Luchon,Bagnères-de-Luchon,31,Haute-Garonne,76,Occitanie
31043,BALESTA,31580,BALESTA,,43.2022153285,0.563416823751,43,,Balesta,Balesta,31,Haute-Garonne,76,Occitanie
31044,BALMA,31130,BALMA,,43.6115258731,1.50459138348,44,,Balma,Balma,31,Haute-Garonne,76,Occitanie
31045,BARBAZAN,31510,BARBAZAN,,43.0349167562,0.621843270665,45,,Barbazan,Barbazan,31,Haute-Garonne,76,Occitanie
31046,BAREN,31440,BAREN,,42.8676983193,0.634971126368,46,,Baren,Baren,31,Haute-Garonne,76,Occitanie
31047,BAX,31310,BAX,,43.2246503124,1.28820389123,47,,Bax,Bax,31,Haute-Garonne,76,Occitanie
31048,BAZIEGE,31450,BAZIEGE,,43.4622367436,1.62799581298,48,,Baziège,Baziège,31,Haute-Garonne,76,Occitanie
31049,BAZUS,31380,BAZUS,,43.7357441741,1.51960464279,49,,Bazus,Bazus,31,Haute-Garonne,76,Occitanie
31050,BEAUCHALOT,31360,BEAUCHALOT,,43.1144562142,0.869747129853,50,,Beauchalot,Beauchalot,31,Haute-Garonne,76,Occitanie
31051,BEAUFORT,31370,BEAUFORT,,43.4548618098,1.10650961074,51,,Beaufort,Beaufort,31,Haute-Garonne,76,Occitanie
31052,BEAUMONT SUR LEZE,31870,BEAUMONT SUR LEZE,,43.3798440302,1.35610208847,52,,Beaumont-sur-Lèze,Beaumont-sur-Lèze,31,Haute-Garonne,76,Occitanie
31053,BEAUPUY,31850,BEAUPUY,,43.6525825042,1.55869879525,53,,Beaupuy,Beaupuy,31,Haute-Garonne,76,Occitanie
31054,BEAUTEVILLE,31290,BEAUTEVILLE,,43.349128147,1.73283349188,54,,Beauteville,Beauteville,31,Haute-Garonne,76,Occitanie
31055,BEAUVILLE,31460,BEAUVILLE,,43.4675844934,1.7707274596,55,,Beauville,Beauville,31,Haute-Garonne,76,Occitanie
31056,BEAUZELLE,31700,BEAUZELLE,,43.667783714,1.37408906279,56,,Beauzelle,Beauzelle,31,Haute-Garonne,76,Occitanie
31057,BELBERAUD,31450,BELBERAUD,,43.5037794316,1.56972339327,57,,Belberaud,Belberaud,31,Haute-Garonne,76,Occitanie
31058,BELBEZE DE LAURAGAIS,31450,BELBEZE DE LAURAGAIS,,43.437002886,1.55679830955,58,,Belbèze-de-Lauragais,Belbèze-de-Lauragais,31,Haute-Garonne,76,Occitanie
31059,BELBEZE EN COMMINGES,31260,BELBEZE EN COMMINGES,,43.133868235,1.02965888187,59,,Belbèze-en-Comminges,Belbèze-en-Comminges,31,Haute-Garonne,76,Occitanie
31060,BELESTA EN LAURAGAIS,31540,BELESTA EN LAURAGAIS,,43.4395844562,1.83164556128,60,,Bélesta-en-Lauragais,Bélesta-en-Lauragais,31,Haute-Garonne,76,Occitanie
31061,BELLEGARDE STE MARIE,31530,BELLEGARDE STE MARIE,,43.6757290894,1.09962618337,61,,Bellegarde-Sainte-Marie,Bellegarde-Sainte-Marie,31,Haute-Garonne,76,Occitanie
31062,BELLESSERRE,31480,BELLESSERRE,,43.7873665231,1.10128941207,62,,Bellesserre,Bellesserre,31,Haute-Garonne,76,Occitanie
31063,BENQUE,31420,BENQUE,,43.2590244874,0.917904928606,63,,Benque,Benque,31,Haute-Garonne,76,Occitanie
31064,BENQUE DESSOUS ET DESSUS,31110,BENQUE DESSOUS ET DESSUS,,42.8204320616,0.541783667805,64,,Benque-Dessous-et-Dessus,Benque-Dessous-et-Dessus,31,Haute-Garonne,76,Occitanie
31065,BERAT,31370,BERAT,,43.377910708,1.17131231039,65,,Bérat,Bérat,31,Haute-Garonne,76,Occitanie
31066,BESSIERES,31660,BESSIERES,,43.796995756,1.58618244226,66,,Bessières,Bessières,31,Haute-Garonne,76,Occitanie
31067,BEZINS GARRAUX,31440,BEZINS GARRAUX,,42.9389029798,0.702058330112,67,,Bezins-Garraux,Bezins-Garraux,31,Haute-Garonne,76,Occitanie
31068,BILLIERE,31110,BILLIERE,,42.8163998268,0.528501869101,68,,Billière,Billière,31,Haute-Garonne,76,Occitanie
31069,BLAGNAC,31700,BLAGNAC,,43.6421867862,1.37886941086,69,,Blagnac,Blagnac,31,Haute-Garonne,76,Occitanie
31070,BLAJAN,31350,BLAJAN,,43.2538737528,0.647870154984,70,,Blajan,Blajan,31,Haute-Garonne,76,Occitanie
31071,BOIS DE LA PIERRE,31390,BOIS DE LA PIERRE,,43.3411967523,1.1619650342,71,,Bois-de-la-Pierre,Bois-de-la-Pierre,31,Haute-Garonne,76,Occitanie
31072,BOISSEDE,31230,BOISSEDE,,43.4071039513,0.826216857936,72,,Boissède,Boissède,31,Haute-Garonne,76,Occitanie
31073,BONDIGOUX,31340,BONDIGOUX,,43.8426819067,1.5434822211,73,,Bondigoux,Bondigoux,31,Haute-Garonne,76,Occitanie
31074,BONREPOS RIQUET,31590,BONREPOS RIQUET,,43.6764879436,1.62258710313,74,,Bonrepos-Riquet,Bonrepos-Riquet,31,Haute-Garonne,76,Occitanie
31075,BONREPOS SUR AUSSONNELLE,31470,BONREPOS SUR AUSSONNELLE,,43.5441107951,1.13701796182,75,,Bonrepos-sur-Aussonnelle,Bonrepos-sur-Aussonnelle,31,Haute-Garonne,76,Occitanie
31076,BORDES DE RIVIERE,31210,BORDES DE RIVIERE,,43.1236831368,0.634949755967,76,,Bordes-de-Rivière,Bordes-de-Rivière,31,Haute-Garonne,76,Occitanie
31077,LE BORN,31340,LE BORN,,43.8958696677,1.53902789526,77,Le,Born,Le Born,31,Haute-Garonne,76,Occitanie
31078,BOUDRAC,31580,BOUDRAC,,43.1873792108,0.517345335704,78,,Boudrac,Boudrac,31,Haute-Garonne,76,Occitanie
31079,BOULOC,31620,BOULOC,,43.7958003847,1.4191891401,79,,Bouloc,Bouloc,31,Haute-Garonne,76,Occitanie
31080,BOULOGNE SUR GESSE,31350,BOULOGNE SUR GESSE,,43.2904403081,0.650641474176,80,,Boulogne-sur-Gesse,Boulogne-sur-Gesse,31,Haute-Garonne,76,Occitanie
31081,BOURG D OUEIL,31110,BOURG D OUEIL,,42.8605605642,0.486883887759,81,,Bourg-d'Oueil,Bourg-d'Oueil,31,Haute-Garonne,76,Occitanie
31082,BOURG ST BERNARD,31570,BOURG ST BERNARD,,43.6062435509,1.69878224324,82,,Bourg-Saint-Bernard,Bourg-Saint-Bernard,31,Haute-Garonne,76,Occitanie
31083,BOUSSAN,31420,BOUSSAN,,43.2416263928,0.875149673224,83,,Boussan,Boussan,31,Haute-Garonne,76,Occitanie
31084,BOUSSENS,31360,BOUSSENS,,43.1763531912,0.960371645513,84,,Boussens,Boussens,31,Haute-Garonne,76,Occitanie
31085,BOUTX,31160,BOUTX,,42.9153786194,0.770355750474,85,,Boutx,Boutx,31,Haute-Garonne,76,Occitanie
31085,BOUTX,31160,BOUTX,COULEDOUX,42.9153786194,0.770355750474,85,,Boutx,Boutx,31,Haute-Garonne,76,Occitanie
31085,BOUTX,31440,BOUTX,,42.9153786194,0.770355750474,85,,Boutx,Boutx,31,Haute-Garonne,76,Occitanie
31085,BOUTX,31440,BOUTX,ARGUT DESSUS,42.9153786194,0.770355750474,85,,Boutx,Boutx,31,Haute-Garonne,76,Occitanie
31086,BOUZIN,31420,BOUZIN,,43.1908302921,0.883064615225,86,,Bouzin,Bouzin,31,Haute-Garonne,76,Occitanie
31087,BRAGAYRAC,31470,BRAGAYRAC,,43.4884997538,1.07003816483,87,,Bragayrac,Bragayrac,31,Haute-Garonne,76,Occitanie
31088,BRAX,31490,BRAX,,43.6156942044,1.23194886903,88,,Brax,Brax,31,Haute-Garonne,76,Occitanie
31089,BRETX,31530,BRETX,,43.7059673494,1.19620532147,89,,Bretx,Bretx,31,Haute-Garonne,76,Occitanie
31090,BRIGNEMONT,31480,BRIGNEMONT,,43.7692039824,0.998300952278,90,,Brignemont,Brignemont,31,Haute-Garonne,76,Occitanie
31091,BRUGUIERES,31150,BRUGUIERES,,43.7271508931,1.40915818124,91,,Bruguières,Bruguières,31,Haute-Garonne,76,Occitanie
31092,BURGALAYS,31440,BURGALAYS,,42.8843664785,0.636511847302,92,,Burgalays,Burgalays,31,Haute-Garonne,76,Occitanie
31093,LE BURGAUD,31330,LE BURGAUD,,43.7897007314,1.14613239704,93,Le,Burgaud,Le Burgaud,31,Haute-Garonne,76,Occitanie
31094,BUZET SUR TARN,31660,BUZET SUR TARN,,43.7685831064,1.61379658307,94,,Buzet-sur-Tarn,Buzet-sur-Tarn,31,Haute-Garonne,76,Occitanie
31095,CABANAC CAZAUX,31160,CABANAC CAZAUX,,43.0344383786,0.73986085522,95,,Cabanac-Cazaux,Cabanac-Cazaux,31,Haute-Garonne,76,Occitanie
31096,CABANAC SEGUENVILLE,31480,CABANAC SEGUENVILLE,,43.791486988,1.01984051649,96,,Cabanac-Séguenville,Cabanac-Séguenville,31,Haute-Garonne,76,Occitanie
31097,LE CABANIAL,31460,LE CABANIAL,,43.5220437405,1.86897842751,97,Le,Cabanial,Le Cabanial,31,Haute-Garonne,76,Occitanie
31098,CADOURS,31480,CADOURS,,43.7262666734,1.04672330873,98,,Cadours,Cadours,31,Haute-Garonne,76,Occitanie
31099,CAIGNAC,31560,CAIGNAC,,43.3198333866,1.70726784599,99,,Caignac,Caignac,31,Haute-Garonne,76,Occitanie
31100,CALMONT,31560,CALMONT,,43.2841584192,1.62632523244,100,,Calmont,Calmont,31,Haute-Garonne,76,Occitanie
31101,CAMBERNARD,31470,CAMBERNARD,,43.4619758547,1.17879278743,101,,Cambernard,Cambernard,31,Haute-Garonne,76,Occitanie
31102,CAMBIAC,31460,CAMBIAC,,43.4906846108,1.7895005846,102,,Cambiac,Cambiac,31,Haute-Garonne,76,Occitanie
31103,CANENS,31310,CANENS,,43.2165330138,1.33272050885,103,,Canens,Canens,31,Haute-Garonne,76,Occitanie
31104,CAPENS,31410,CAPENS,,43.3349294314,1.25981787784,104,,Capens,Capens,31,Haute-Garonne,76,Occitanie
31105,CARAGOUDES,31460,CARAGOUDES,,43.5007296473,1.71348658169,105,,Caragoudes,Caragoudes,31,Haute-Garonne,76,Occitanie
31106,CARAMAN,31460,CARAMAN,,43.5291002668,1.75296927178,106,,Caraman,Caraman,31,Haute-Garonne,76,Occitanie
31107,CARBONNE,31390,CARBONNE,,43.3014494679,1.21849966052,107,,Carbonne,Carbonne,31,Haute-Garonne,76,Occitanie
31108,CARDEILHAC,31350,CARDEILHAC,,43.1959659797,0.676356959979,108,,Cardeilhac,Cardeilhac,31,Haute-Garonne,76,Occitanie
31109,CASSAGNABERE TOURNAS,31420,CASSAGNABERE TOURNAS,,43.2311062604,0.799151440801,109,,Cassagnabère-Tournas,Cassagnabère-Tournas,31,Haute-Garonne,76,Occitanie
31110,CASSAGNE,31260,CASSAGNE,,43.1242700684,0.991401965489,110,,Cassagne,Cassagne,31,Haute-Garonne,76,Occitanie
31111,CASTAGNAC,31310,CASTAGNAC,,43.2357808923,1.34342211864,111,,Castagnac,Castagnac,31,Haute-Garonne,76,Occitanie
31112,CASTAGNEDE,31260,CASTAGNEDE,,43.051468722,0.977338440086,112,,Castagnède,Castagnède,31,Haute-Garonne,76,Occitanie
31113,CASTANET TOLOSAN,31320,CASTANET TOLOSAN,,43.5136166687,1.50383757047,113,,Castanet-Tolosan,Castanet-Tolosan,31,Haute-Garonne,76,Occitanie
31114,CASTELBIAGUE,31160,CASTELBIAGUE,,43.0356678546,0.927325532514,114,,Castelbiague,Castelbiague,31,Haute-Garonne,76,Occitanie
31115,CASTELGAILLARD,31230,CASTELGAILLARD,,43.352091246,0.901912825584,115,,Castelgaillard,Castelgaillard,31,Haute-Garonne,76,Occitanie
31116,CASTELGINEST,31780,CASTELGINEST,,43.6974132735,1.4355878363,116,,Castelginest,Castelginest,31,Haute-Garonne,76,Occitanie
31117,CASTELMAUROU,31180,CASTELMAUROU,,43.6845152261,1.53745049477,117,,Castelmaurou,Castelmaurou,31,Haute-Garonne,76,Occitanie
31118,CASTELNAU D ESTRETEFONDS,31620,CASTELNAU D ESTRETEFONDS,,43.7938485319,1.36164575228,118,,Castelnau-d'Estrétefonds,Castelnau-d'Estrétefonds,31,Haute-Garonne,76,Occitanie
31119,CASTELNAU PICAMPEAU,31430,CASTELNAU PICAMPEAU,,43.3086246303,1.01513029309,119,,Castelnau-Picampeau,Castelnau-Picampeau,31,Haute-Garonne,76,Occitanie
31120,LE CASTERA,31530,LE CASTERA,,43.6709769042,1.14478354083,120,Le,Castéra,Le Castéra,31,Haute-Garonne,76,Occitanie
31121,CASTERA VIGNOLES,31350,CASTERA VIGNOLES,,43.2764304672,0.782548294246,121,,Castéra-Vignoles,Castéra-Vignoles,31,Haute-Garonne,76,Occitanie
31122,CASTIES LABRANDE,31430,CASTIES LABRANDE,,43.3277537605,0.993442219827,122,,Casties-Labrande,Casties-Labrande,31,Haute-Garonne,76,Occitanie
31123,CASTILLON DE LARBOUST,31110,CASTILLON DE LARBOUST,,42.7401974688,0.557666969635,123,,Castillon-de-Larboust,Castillon-de-Larboust,31,Haute-Garonne,76,Occitanie
31124,CASTILLON DE ST MARTORY,31360,CASTILLON DE ST MARTORY,,43.1370495507,0.85105513739,124,,Castillon-de-Saint-Martory,Castillon-de-Saint-Martory,31,Haute-Garonne,76,Occitanie
31125,CATHERVIELLE,31110,CATHERVIELLE,,42.8234692001,0.507545845496,125,,Cathervielle,Cathervielle,31,Haute-Garonne,76,Occitanie
31126,CAUBIAC,31480,CAUBIAC,,43.7125921487,1.07397427745,126,,Caubiac,Caubiac,31,Haute-Garonne,76,Occitanie
31127,CAUBOUS,31110,CAUBOUS,,42.8472592399,0.521910394334,127,,Caubous,Caubous,31,Haute-Garonne,76,Occitanie
31128,CAUJAC,31190,CAUJAC,,43.2953977811,1.45851847854,128,,Caujac,Caujac,31,Haute-Garonne,76,Occitanie
31129,CAZARILH LASPENES,31110,CAZARILH LASPENES,,42.8031770171,0.581463334845,129,,Cazarilh-Laspènes,Cazarilh-Laspènes,31,Haute-Garonne,76,Occitanie
31130,CAZARIL TAMBOURES,31580,CAZARIL TAMBOURES,,43.1788077509,0.542510380737,130,,Cazaril-Tambourès,Cazaril-Tambourès,31,Haute-Garonne,76,Occitanie
31131,CAZAUNOUS,31160,CAZAUNOUS,,42.9882048056,0.724729046231,131,,Cazaunous,Cazaunous,31,Haute-Garonne,76,Occitanie
31132,CAZAUX LAYRISSE,31440,CAZAUX LAYRISSE,,42.8715028336,0.59693837031,132,,Cazaux-Layrisse,Cazaux-Layrisse,31,Haute-Garonne,76,Occitanie
31133,CAZEAUX DE LARBOUST,31110,CAZEAUX DE LARBOUST,,42.7515051317,0.532234410988,133,,Cazeaux-de-Larboust,Cazeaux-de-Larboust,31,Haute-Garonne,76,Occitanie
31134,CAZENEUVE MONTAUT,31420,CAZENEUVE MONTAUT,,43.181753746,0.862058674032,134,,Cazeneuve-Montaut,Cazeneuve-Montaut,31,Haute-Garonne,76,Occitanie
31135,CAZERES,31220,CAZERES,,43.2203354224,1.09307402728,135,,Cazères,Cazères,31,Haute-Garonne,76,Occitanie
31136,CEPET,31620,CEPET,,43.7456992513,1.43660975601,136,,Cépet,Cépet,31,Haute-Garonne,76,Occitanie
31137,CESSALES,31290,CESSALES,,43.4578290788,1.74447351258,137,,Cessales,Cessales,31,Haute-Garonne,76,Occitanie
31138,CHARLAS,31350,CHARLAS,,43.2348620217,0.69256790688,138,,Charlas,Charlas,31,Haute-Garonne,76,Occitanie
31139,CHAUM,31440,CHAUM,,42.9406957068,0.66516191816,139,,Chaum,Chaum,31,Haute-Garonne,76,Occitanie
31140,CHEIN DESSUS,31160,CHEIN DESSUS,,43.0047959072,0.875406568776,140,,Chein-Dessus,Chein-Dessus,31,Haute-Garonne,76,Occitanie
31141,CIADOUX,31350,CIADOUX,,43.2542454661,0.742080761345,141,,Ciadoux,Ciadoux,31,Haute-Garonne,76,Occitanie
31142,CIER DE LUCHON,31110,CIER DE LUCHON,,42.8554210387,0.587203790496,142,,Cier-de-Luchon,Cier-de-Luchon,31,Haute-Garonne,76,Occitanie
31143,CIER DE RIVIERE,31510,CIER DE RIVIERE,,43.0582093702,0.626576681878,143,,Cier-de-Rivière,Cier-de-Rivière,31,Haute-Garonne,76,Occitanie
31144,CIERP GAUD,31440,CIERP GAUD,,42.9126857929,0.634654016295,144,,Cierp-Gaud,Cierp-Gaud,31,Haute-Garonne,76,Occitanie
31144,CIERP GAUD,31440,CIERP GAUD,GAUD,42.9126857929,0.634654016295,144,,Cierp-Gaud,Cierp-Gaud,31,Haute-Garonne,76,Occitanie
31145,CINTEGABELLE,31550,CINTEGABELLE,,43.3068275347,1.53572104777,145,,Cintegabelle,Cintegabelle,31,Haute-Garonne,76,Occitanie
31146,CIRES,31110,CIRES,,42.8523193923,0.509249607327,146,,Cirès,Cirès,31,Haute-Garonne,76,Occitanie
31147,CLARAC,31210,CLARAC,,43.1137180327,0.6161886319,147,,Clarac,Clarac,31,Haute-Garonne,76,Occitanie
31148,CLERMONT LE FORT,31810,CLERMONT LE FORT,,43.4584289455,1.44262793201,148,,Clermont-le-Fort,Clermont-le-Fort,31,Haute-Garonne,76,Occitanie
31149,COLOMIERS,31770,COLOMIERS,,43.611551508,1.32700218407,149,,Colomiers,Colomiers,31,Haute-Garonne,76,Occitanie
31150,CORNEBARRIEU,31700,CORNEBARRIEU,,43.6486265729,1.32242964582,150,,Cornebarrieu,Cornebarrieu,31,Haute-Garonne,76,Occitanie
31151,CORRONSAC,31450,CORRONSAC,,43.4715894844,1.48988057955,151,,Corronsac,Corronsac,31,Haute-Garonne,76,Occitanie
31152,COUEILLES,31230,COUEILLES,,43.346722057,0.878316762368,152,,Coueilles,Coueilles,31,Haute-Garonne,76,Occitanie
31153,COULADERE,31220,COULADERE,,43.1961985642,1.09427022824,153,,Couladère,Couladère,31,Haute-Garonne,76,Occitanie
31155,COURET,31160,COURET,,43.0492105288,0.816891672701,155,,Couret,Couret,31,Haute-Garonne,76,Occitanie
31156,COX,31480,COX,,43.7604806634,1.04203619262,156,,Cox,Cox,31,Haute-Garonne,76,Occitanie
31157,CUGNAUX,31270,CUGNAUX,,43.5449211242,1.34286223759,157,,Cugnaux,Cugnaux,31,Haute-Garonne,76,Occitanie
31158,CUGURON,31210,CUGURON,,43.104325893,0.51568143449,158,,Cuguron,Cuguron,31,Haute-Garonne,76,Occitanie
31159,LE CUING,31210,LE CUING,,43.1471175091,0.611931912745,159,Le,Cuing,Le Cuing,31,Haute-Garonne,76,Occitanie
31160,DAUX,31700,DAUX,,43.6872531862,1.26634638544,160,,Daux,Daux,31,Haute-Garonne,76,Occitanie
31161,DEYME,31450,DEYME,,43.482390026,1.53265253499,161,,Deyme,Deyme,31,Haute-Garonne,76,Occitanie
31162,DONNEVILLE,31450,DONNEVILLE,,43.4727663351,1.55123674393,162,,Donneville,Donneville,31,Haute-Garonne,76,Occitanie
31163,DREMIL LAFAGE,31280,DREMIL LAFAGE,,43.5917450046,1.60317858456,163,,Drémil-Lafage,Drémil-Lafage,31,Haute-Garonne,76,Occitanie
31164,DRUDAS,31480,DRUDAS,,43.7622728422,1.09790048233,164,,Drudas,Drudas,31,Haute-Garonne,76,Occitanie
31165,EAUNES,31600,EAUNES,,43.4276929383,1.35897762267,165,,Eaunes,Eaunes,31,Haute-Garonne,76,Occitanie
31166,EMPEAUX,31470,EMPEAUX,,43.5369176255,1.07909737017,166,,Empeaux,Empeaux,31,Haute-Garonne,76,Occitanie
31167,ENCAUSSE LES THERMES,31160,ENCAUSSE LES THERMES,,43.0532848166,0.746258275934,167,,Encausse-les-Thermes,Encausse-les-Thermes,31,Haute-Garonne,76,Occitanie
31168,EOUX,31420,EOUX,,43.2774587805,0.882334397451,168,,Eoux,Eoux,31,Haute-Garonne,76,Occitanie
31169,ESCALQUENS,31750,ESCALQUENS,,43.5209125935,1.55243226997,169,,Escalquens,Escalquens,31,Haute-Garonne,76,Occitanie
31170,ESCANECRABE,31350,ESCANECRABE,,43.2716594526,0.757214642692,170,,Escanecrabe,Escanecrabe,31,Haute-Garonne,76,Occitanie
31171,ESPANES,31450,ESPANES,,43.4509538019,1.4870137127,171,,Espanès,Espanès,31,Haute-Garonne,76,Occitanie
31172,ESPARRON,31420,ESPARRON,,43.2611725663,0.811588466445,172,,Esparron,Esparron,31,Haute-Garonne,76,Occitanie
31173,ESPERCE,31190,ESPERCE,,43.2993657224,1.40063786328,173,,Esperce,Esperce,31,Haute-Garonne,76,Occitanie
31174,ESTADENS,31160,ESTADENS,,43.0338015189,0.844111123426,174,,Estadens,Estadens,31,Haute-Garonne,76,Occitanie
31175,ESTANCARBON,31800,ESTANCARBON,,43.108852598,0.781108335801,175,,Estancarbon,Estancarbon,31,Haute-Garonne,76,Occitanie
31176,ESTENOS,31440,ESTENOS,,42.9408620061,0.635528965839,176,,Esténos,Esténos,31,Haute-Garonne,76,Occitanie
31177,EUP,31440,EUP,,42.9263371588,0.684482235648,177,,Eup,Eup,31,Haute-Garonne,76,Occitanie
31178,FABAS,31230,FABAS,,43.3119614436,0.89311883238,178,,Fabas,Fabas,31,Haute-Garonne,76,Occitanie
31179,LE FAGET,31460,LE FAGET,,43.5648428927,1.8260785638,179,Le,Faget,Le Faget,31,Haute-Garonne,76,Occitanie
31180,FALGA,31540,FALGA,,43.480563098,1.84601018765,180,,Falga,Falga,31,Haute-Garonne,76,Occitanie
31181,LE FAUGA,31410,LE FAUGA,,43.3984327015,1.2940830427,181,Le,Fauga,Le Fauga,31,Haute-Garonne,76,Occitanie
31182,FENOUILLET,31150,FENOUILLET,,43.6848098988,1.39052053451,182,,Fenouillet,Fenouillet,31,Haute-Garonne,76,Occitanie
31183,FIGAROL,31260,FIGAROL,,43.086105599,0.905126707725,183,,Figarol,Figarol,31,Haute-Garonne,76,Occitanie
31184,FLOURENS,31130,FLOURENS,,43.5977742595,1.55391307823,184,,Flourens,Flourens,31,Haute-Garonne,76,Occitanie
31185,FOLCARDE,31290,FOLCARDE,,43.4059778673,1.79495498171,185,,Folcarde,Folcarde,31,Haute-Garonne,76,Occitanie
31186,FONBEAUZARD,31140,FONBEAUZARD,,43.6798211278,1.43492132755,186,,Fonbeauzard,Fonbeauzard,31,Haute-Garonne,76,Occitanie
31187,FONSORBES,31470,FONSORBES,,43.5317695743,1.23863670585,187,,Fonsorbes,Fonsorbes,31,Haute-Garonne,76,Occitanie
31188,FONTENILLES,31470,FONTENILLES,,43.5554168376,1.19738282576,188,,Fontenilles,Fontenilles,31,Haute-Garonne,76,Occitanie
31189,FORGUES,31370,FORGUES,,43.4311541464,1.04326431364,189,,Forgues,Forgues,31,Haute-Garonne,76,Occitanie
31190,FOS,31440,FOS,,42.8605439221,0.747535403685,190,,Fos,Fos,31,Haute-Garonne,76,Occitanie
31191,FOUGARON,31160,FOUGARON,,42.9816089588,0.935465370602,191,,Fougaron,Fougaron,31,Haute-Garonne,76,Occitanie
31192,FOURQUEVAUX,31450,FOURQUEVAUX,,43.498117622,1.62175051569,192,,Fourquevaux,Fourquevaux,31,Haute-Garonne,76,Occitanie
31193,LE FOUSSERET,31430,LE FOUSSERET,,43.2863317906,1.06495178565,193,Le,Fousseret,Le Fousseret,31,Haute-Garonne,76,Occitanie
31194,FRANCARVILLE,31460,FRANCARVILLE,,43.5867124964,1.74796127213,194,,Francarville,Francarville,31,Haute-Garonne,76,Occitanie
31195,FRANCAZAL,31260,FRANCAZAL,,43.008007201,0.99694808241,195,,Francazal,Francazal,31,Haute-Garonne,76,Occitanie
31196,FRANCON,31420,FRANCON,,43.26180406,0.988074142918,196,,Francon,Francon,31,Haute-Garonne,76,Occitanie
31197,FRANQUEVIELLE,31210,FRANQUEVIELLE,,43.1310604911,0.534188956985,197,,Franquevielle,Franquevielle,31,Haute-Garonne,76,Occitanie
31198,LE FRECHET,31360,LE FRECHET,,43.1898575519,0.931806959516,198,Le,Fréchet,Le Fréchet,31,Haute-Garonne,76,Occitanie
31199,FRONSAC,31440,FRONSAC,,42.9540641644,0.662252596199,199,,Fronsac,Fronsac,31,Haute-Garonne,76,Occitanie
31200,FRONTIGNAN DE COMMINGES,31510,FRONTIGNAN DE COMMINGES,,42.9631400152,0.665864279596,200,,Frontignan-de-Comminges,Frontignan-de-Comminges,31,Haute-Garonne,76,Occitanie
31201,FRONTIGNAN SAVES,31230,FRONTIGNAN SAVES,,43.3992758372,0.912650779846,201,,Frontignan-Savès,Frontignan-Savès,31,Haute-Garonne,76,Occitanie
31202,FRONTON,31620,FRONTON,,43.8517933672,1.37926505581,202,,Fronton,Fronton,31,Haute-Garonne,76,Occitanie
31203,FROUZINS,31270,FROUZINS,,43.5213246491,1.31308144472,203,,Frouzins,Frouzins,31,Haute-Garonne,76,Occitanie
31204,FUSTIGNAC,31430,FUSTIGNAC,,43.3012693197,0.983864621699,204,,Fustignac,Fustignac,31,Haute-Garonne,76,Occitanie
31205,GAGNAC SUR GARONNE,31150,GAGNAC SUR GARONNE,,43.7075932093,1.36359468155,205,,Gagnac-sur-Garonne,Gagnac-sur-Garonne,31,Haute-Garonne,76,Occitanie
31206,GAILLAC TOULZA,31550,GAILLAC TOULZA,,43.2567352836,1.45627950177,206,,Gaillac-Toulza,Gaillac-Toulza,31,Haute-Garonne,76,Occitanie
31207,GALIE,31510,GALIE,,42.9928750426,0.633738295094,207,,Galié,Galié,31,Haute-Garonne,76,Occitanie
31208,GANTIES,31160,GANTIES,,43.0640234495,0.836948319209,208,,Ganties,Ganties,31,Haute-Garonne,76,Occitanie
31209,GARAC,31480,GARAC,,43.6892862656,1.09168485036,209,,Garac,Garac,31,Haute-Garonne,76,Occitanie
31210,GARDOUCH,31290,GARDOUCH,,43.3848367131,1.67826808704,210,,Gardouch,Gardouch,31,Haute-Garonne,76,Occitanie
31211,GARGAS,31620,GARGAS,,43.7592156641,1.45920963091,211,,Gargas,Gargas,31,Haute-Garonne,76,Occitanie
31212,GARIDECH,31380,GARIDECH,,43.7133738852,1.55161276907,212,,Garidech,Garidech,31,Haute-Garonne,76,Occitanie
31213,GARIN,31110,GARIN,,42.807334211,0.497947731737,213,,Garin,Garin,31,Haute-Garonne,76,Occitanie
31215,GAURE,31590,GAURE,,43.6176900589,1.63698361771,215,,Gauré,Gauré,31,Haute-Garonne,76,Occitanie
31216,GEMIL,31380,GEMIL,,43.7390147281,1.59616914343,216,,Gémil,Gémil,31,Haute-Garonne,76,Occitanie
31217,GENOS,31510,GENOS,,42.9989362033,0.668863538548,217,,Génos,Génos,31,Haute-Garonne,76,Occitanie
31218,GENSAC DE BOULOGNE,31350,GENSAC DE BOULOGNE,,43.2589572773,0.603577328953,218,,Gensac-de-Boulogne,Gensac-de-Boulogne,31,Haute-Garonne,76,Occitanie
31219,GENSAC SUR GARONNE,31310,GENSAC SUR GARONNE,,43.2158175137,1.14295187522,219,,Gensac-sur-Garonne,Gensac-sur-Garonne,31,Haute-Garonne,76,Occitanie
31220,GIBEL,31560,GIBEL,,43.3026504146,1.67636064192,220,,Gibel,Gibel,31,Haute-Garonne,76,Occitanie
31221,GOUAUX DE LARBOUST,31110,GOUAUX DE LARBOUST,,42.7805722795,0.477306280003,221,,Gouaux-de-Larboust,Gouaux-de-Larboust,31,Haute-Garonne,76,Occitanie
31222,GOUAUX DE LUCHON,31110,GOUAUX DE LUCHON,,42.8507243735,0.641852997269,222,,Gouaux-de-Luchon,Gouaux-de-Luchon,31,Haute-Garonne,76,Occitanie
31223,GOUDEX,31230,GOUDEX,,43.379000165,0.956722532352,223,,Goudex,Goudex,31,Haute-Garonne,76,Occitanie
31224,GOURDAN POLIGNAN,31210,GOURDAN POLIGNAN,,43.0693789495,0.575641802967,224,,Gourdan-Polignan,Gourdan-Polignan,31,Haute-Garonne,76,Occitanie
31225,GOUTEVERNISSE,31310,GOUTEVERNISSE,,43.2140263014,1.17376592029,225,,Goutevernisse,Goutevernisse,31,Haute-Garonne,76,Occitanie
31226,GOUZENS,31310,GOUZENS,,43.1791349745,1.18187382693,226,,Gouzens,Gouzens,31,Haute-Garonne,76,Occitanie
31227,GOYRANS,31120,GOYRANS,,43.4816353803,1.42653323258,227,,Goyrans,Goyrans,31,Haute-Garonne,76,Occitanie
31228,GRAGNAGUE,31380,GRAGNAGUE,,43.6830952674,1.58238144646,228,,Gragnague,Gragnague,31,Haute-Garonne,76,Occitanie
31229,GRATENS,31430,GRATENS,,43.3258752014,1.12264895542,229,,Gratens,Gratens,31,Haute-Garonne,76,Occitanie
31230,GRATENTOUR,31150,GRATENTOUR,,43.7204638549,1.43434867607,230,,Gratentour,Gratentour,31,Haute-Garonne,76,Occitanie
31231,GRAZAC,31190,GRAZAC,,43.3114150359,1.44878574515,231,,Grazac,Grazac,31,Haute-Garonne,76,Occitanie
31232,GRENADE,31330,GRENADE,,43.7640507757,1.28038974975,232,,Grenade,Grenade,31,Haute-Garonne,76,Occitanie
31233,GREPIAC,31190,GREPIAC,,43.4078546478,1.45277463176,233,,Grépiac,Grépiac,31,Haute-Garonne,76,Occitanie
31234,LE GRES,31480,LE GRES,,43.718877124,1.0998867054,234,Le,Grès,Le Grès,31,Haute-Garonne,76,Occitanie
31235,GURAN,31440,GURAN,,42.8861684706,0.607040581049,235,,Guran,Guran,31,Haute-Garonne,76,Occitanie
31236,HERRAN,31160,HERRAN,,42.9719649863,0.885660776588,236,,Herran,Herran,31,Haute-Garonne,76,Occitanie
31237,HIS,31260,HIS,,43.0649908424,0.965815037259,237,,His,His,31,Haute-Garonne,76,Occitanie
31238,HUOS,31210,HUOS,,43.0693636592,0.6001814706,238,,Huos,Huos,31,Haute-Garonne,76,Occitanie
31239,L ISLE EN DODON,31230,L ISLE EN DODON,,43.3811500613,0.83387534106,239,L',Isle-en-Dodon,L'Isle-en-Dodon,31,Haute-Garonne,76,Occitanie
31240,ISSUS,31450,ISSUS,,43.422775887,1.50277699611,240,,Issus,Issus,31,Haute-Garonne,76,Occitanie
31241,IZAUT DE L HOTEL,31160,IZAUT DE L HOTEL,,43.0131099513,0.749757075075,241,,Izaut-de-l'Hôtel,Izaut-de-l'Hôtel,31,Haute-Garonne,76,Occitanie
31242,JURVIELLE,31110,JURVIELLE,,42.8293252731,0.475016792399,242,,Jurvielle,Jurvielle,31,Haute-Garonne,76,Occitanie
31243,JUZES,31540,JUZES,,43.4471276111,1.79347652101,243,,Juzes,Juzes,31,Haute-Garonne,76,Occitanie
31244,JUZET DE LUCHON,31110,JUZET DE LUCHON,,42.8030856588,0.629941748011,244,,Juzet-de-Luchon,Juzet-de-Luchon,31,Haute-Garonne,76,Occitanie
31245,JUZET D IZAUT,31160,JUZET D IZAUT,,42.9699962027,0.757388801207,245,,Juzet-d'Izaut,Juzet-d'Izaut,31,Haute-Garonne,76,Occitanie
31246,LABARTHE INARD,31800,LABARTHE INARD,,43.1078272135,0.832464946547,246,,Labarthe-Inard,Labarthe-Inard,31,Haute-Garonne,76,Occitanie
31247,LABARTHE RIVIERE,31800,LABARTHE RIVIERE,,43.0809091883,0.671429953556,247,,Labarthe-Rivière,Labarthe-Rivière,31,Haute-Garonne,76,Occitanie
31248,LABARTHE SUR LEZE,31860,LABARTHE SUR LEZE,,43.4585461585,1.40082976957,248,,Labarthe-sur-Lèze,Labarthe-sur-Lèze,31,Haute-Garonne,76,Occitanie
31249,LABASTIDE BEAUVOIR,31450,LABASTIDE BEAUVOIR,,43.4833061095,1.66361154425,249,,Labastide-Beauvoir,Labastide-Beauvoir,31,Haute-Garonne,76,Occitanie
31250,LABASTIDE CLERMONT,31370,LABASTIDE CLERMONT,,43.3495275482,1.10615893654,250,,Labastide-Clermont,Labastide-Clermont,31,Haute-Garonne,76,Occitanie
31251,LABASTIDE PAUMES,31230,LABASTIDE PAUMES,,43.3331852393,0.935633531928,251,,Labastide-Paumès,Labastide-Paumès,31,Haute-Garonne,76,Occitanie
31252,LABASTIDE ST SERNIN,31620,LABASTIDE ST SERNIN,,43.7357715003,1.4659716657,252,,Labastide-Saint-Sernin,Labastide-Saint-Sernin,31,Haute-Garonne,76,Occitanie
31253,LABASTIDETTE,31600,LABASTIDETTE,,43.4572488277,1.23711538242,253,,Labastidette,Labastidette,31,Haute-Garonne,76,Occitanie
31254,LABEGE,31670,LABEGE,,43.539137717,1.5206207109,254,,Labège,Labège,31,Haute-Garonne,76,Occitanie
31255,LABROQUERE,31510,LABROQUERE,,43.0422164523,0.591584694208,255,,Labroquère,Labroquère,31,Haute-Garonne,76,Occitanie
31256,LABRUYERE DORSA,31190,LABRUYERE DORSA,,43.4049029199,1.47157960767,256,,Labruyère-Dorsa,Labruyère-Dorsa,31,Haute-Garonne,76,Occitanie
31258,LACAUGNE,31390,LACAUGNE,,43.2812784348,1.28249585607,258,,Lacaugne,Lacaugne,31,Haute-Garonne,76,Occitanie
31259,LACROIX FALGARDE,31120,LACROIX FALGARDE,,43.5008164665,1.42391034404,259,,Lacroix-Falgarde,Lacroix-Falgarde,31,Haute-Garonne,76,Occitanie
31260,LAFFITE TOUPIERE,31360,LAFFITE TOUPIERE,,43.1654323189,0.913233525531,260,,Laffite-Toupière,Laffite-Toupière,31,Haute-Garonne,76,Occitanie
31261,LAFITTE VIGORDANE,31390,LAFITTE VIGORDANE,,43.3031701623,1.1622399774,261,,Lafitte-Vigordane,Lafitte-Vigordane,31,Haute-Garonne,76,Occitanie
31262,LAGARDE,31290,LAGARDE,,43.3452720322,1.69665313255,262,,Lagarde,Lagarde,31,Haute-Garonne,76,Occitanie
31263,LAGARDELLE SUR LEZE,31870,LAGARDELLE SUR LEZE,,43.4146075746,1.39259285705,263,,Lagardelle-sur-Lèze,Lagardelle-sur-Lèze,31,Haute-Garonne,76,Occitanie
31264,LAGRACE DIEU,31190,LAGRACE DIEU,,43.3373994555,1.40523002745,264,,Lagrâce-Dieu,Lagrâce-Dieu,31,Haute-Garonne,76,Occitanie
31265,LAGRAULET ST NICOLAS,31480,LAGRAULET ST NICOLAS,,43.788801434,1.07315140628,265,,Lagraulet-Saint-Nicolas,Lagraulet-Saint-Nicolas,31,Haute-Garonne,76,Occitanie
31266,LAHAGE,31370,LAHAGE,,43.4402741195,1.06577822679,266,,Lahage,Lahage,31,Haute-Garonne,76,Occitanie
31267,LAHITERE,31310,LAHITERE,,43.1417576866,1.18566582893,267,,Lahitère,Lahitère,31,Haute-Garonne,76,Occitanie
31268,LALOURET LAFFITEAU,31800,LALOURET LAFFITEAU,,43.1867504971,0.700251649847,268,,Lalouret-Laffiteau,Lalouret-Laffiteau,31,Haute-Garonne,76,Occitanie
31269,LAMASQUERE,31600,LAMASQUERE,,43.4845365161,1.24833943627,269,,Lamasquère,Lamasquère,31,Haute-Garonne,76,Occitanie
31270,LANDORTHE,31800,LANDORTHE,,43.1295155523,0.777804003503,270,,Landorthe,Landorthe,31,Haute-Garonne,76,Occitanie
31271,LANTA,31570,LANTA,,43.561512681,1.66802980553,271,,Lanta,Lanta,31,Haute-Garonne,76,Occitanie
31272,LAPEYRERE,31310,LAPEYRERE,,43.2048767713,1.3113589407,272,,Lapeyrère,Lapeyrère,31,Haute-Garonne,76,Occitanie
31273,LAPEYROUSE FOSSAT,31180,LAPEYROUSE FOSSAT,,43.70162146,1.5103595038,273,,Lapeyrouse-Fossat,Lapeyrouse-Fossat,31,Haute-Garonne,76,Occitanie
31274,LARCAN,31800,LARCAN,,43.1712212106,0.721733118542,274,,Larcan,Larcan,31,Haute-Garonne,76,Occitanie
31275,LAREOLE,31480,LAREOLE,,43.7417793374,1.02424859431,275,,Laréole,Laréole,31,Haute-Garonne,76,Occitanie
31276,LARROQUE,31580,LARROQUE,,43.1954638712,0.613620624172,276,,Larroque,Larroque,31,Haute-Garonne,76,Occitanie
31277,LASSERRE,31530,LASSERRE,,43.6358181095,1.19125199564,277,,Lasserre-Pradère,Lasserre-Pradère,31,Haute-Garonne,76,Occitanie
31278,LATOUE,31800,LATOUE,,43.1721599095,0.782166730616,278,,Latoue,Latoue,31,Haute-Garonne,76,Occitanie
31279,LATOUR,31310,LATOUR,,43.2018753864,1.2819924767,279,,Latour,Latour,31,Haute-Garonne,76,Occitanie
31280,LATRAPE,31310,LATRAPE,,43.2493492757,1.2909801197,280,,Latrape,Latrape,31,Haute-Garonne,76,Occitanie
31281,LAUNAC,31330,LAUNAC,,43.7503565656,1.17070596349,281,,Launac,Launac,31,Haute-Garonne,76,Occitanie
31282,LAUNAGUET,31140,LAUNAGUET,,43.6700766081,1.45516003737,282,,Launaguet,Launaguet,31,Haute-Garonne,76,Occitanie
31283,LAUTIGNAC,31370,LAUTIGNAC,,43.3790098177,1.06344919114,283,,Lautignac,Lautignac,31,Haute-Garonne,76,Occitanie
31284,LAUZERVILLE,31650,LAUZERVILLE,,43.5583468414,1.56690064606,284,,Lauzerville,Lauzerville,31,Haute-Garonne,76,Occitanie
31285,LAVALETTE,31590,LAVALETTE,,43.6393734438,1.59168661555,285,,Lavalette,Lavalette,31,Haute-Garonne,76,Occitanie
31286,LAVELANET DE COMMINGES,31220,LAVELANET DE COMMINGES,,43.2526939851,1.11470722168,286,,Lavelanet-de-Comminges,Lavelanet-de-Comminges,31,Haute-Garonne,76,Occitanie
31287,LAVERNOSE LACASSE,31410,LAVERNOSE LACASSE,,43.3913118725,1.24635316993,287,,Lavernose-Lacasse,Lavernose-Lacasse,31,Haute-Garonne,76,Occitanie
31288,LAYRAC SUR TARN,31340,LAYRAC SUR TARN,,43.8338907069,1.56284949072,288,,Layrac-sur-Tarn,Layrac-sur-Tarn,31,Haute-Garonne,76,Occitanie
31289,LECUSSAN,31580,LECUSSAN,,43.1552864516,0.49261323078,289,,Lécussan,Lécussan,31,Haute-Garonne,76,Occitanie
31290,LEGE,31440,LEGE,,42.8790994163,0.599847832302,290,,Lège,Lège,31,Haute-Garonne,76,Occitanie
31291,LEGUEVIN,31490,LEGUEVIN,,43.5919597795,1.2290264629,291,,Léguevin,Léguevin,31,Haute-Garonne,76,Occitanie
31292,LESCUNS,31220,LESCUNS,,43.2452668117,1.00463618554,292,,Lescuns,Lescuns,31,Haute-Garonne,76,Occitanie
31293,LESPINASSE,31150,LESPINASSE,,43.7158013757,1.38334888533,293,,Lespinasse,Lespinasse,31,Haute-Garonne,76,Occitanie
31294,LESPITEAU,31160,LESPITEAU,,43.0657396752,0.762308688736,294,,Lespiteau,Lespiteau,31,Haute-Garonne,76,Occitanie
31295,LESPUGUE,31350,LESPUGUE,,43.229615539,0.666919835845,295,,Lespugue,Lespugue,31,Haute-Garonne,76,Occitanie
31296,LESTELLE DE ST MARTORY,31360,LESTELLE DE ST MARTORY,,43.1187447773,0.902129686925,296,,Lestelle-de-Saint-Martory,Lestelle-de-Saint-Martory,31,Haute-Garonne,76,Occitanie
31297,LEVIGNAC,31530,LEVIGNAC,,43.6593918197,1.20389227339,297,,Lévignac,Lévignac,31,Haute-Garonne,76,Occitanie
31298,LEZ,31440,LEZ,,42.9086596697,0.705931265047,298,,Lez,Lez,31,Haute-Garonne,76,Occitanie
31299,LHERM,31600,LHERM,,43.4293377218,1.22334236594,299,,Lherm,Lherm,31,Haute-Garonne,76,Occitanie
31300,LIEOUX,31800,LIEOUX,,43.1495836513,0.773537344918,300,,Lieoux,Lieoux,31,Haute-Garonne,76,Occitanie
31301,LILHAC,31230,LILHAC,,43.2848643262,0.809582811778,301,,Lilhac,Lilhac,31,Haute-Garonne,76,Occitanie
31302,LODES,31800,LODES,,43.1663358604,0.657578380319,302,,Lodes,Lodes,31,Haute-Garonne,76,Occitanie
31303,LONGAGES,31410,LONGAGES,,43.3574229585,1.21425495972,303,,Longages,Longages,31,Haute-Garonne,76,Occitanie
31304,LOUBENS LAURAGAIS,31460,LOUBENS LAURAGAIS,,43.5744476065,1.78338318229,304,,Loubens-Lauragais,Loubens-Lauragais,31,Haute-Garonne,76,Occitanie
31305,LOUDET,31580,LOUDET,,43.1399868523,0.576361768273,305,,Loudet,Loudet,31,Haute-Garonne,76,Occitanie
31306,LOURDE,31510,LOURDE,,42.9847180635,0.658660755853,306,,Lourde,Lourde,31,Haute-Garonne,76,Occitanie
31307,LUNAX,31350,LUNAX,,43.3389641472,0.694211746451,307,,Lunax,Lunax,31,Haute-Garonne,76,Occitanie
31308,LUSCAN,31510,LUSCAN,,43.0133226155,0.626944464988,308,,Luscan,Luscan,31,Haute-Garonne,76,Occitanie
31309,LUSSAN ADEILHAC,31430,LUSSAN ADEILHAC,,43.2961912719,0.951967921196,309,,Lussan-Adeilhac,Lussan-Adeilhac,31,Haute-Garonne,76,Occitanie
31310,LUX,31290,LUX,,43.4310851091,1.78134765321,310,,Lux,Lux,31,Haute-Garonne,76,Occitanie
31311,LA MAGDELAINE SUR TARN,31340,LA MAGDELAINE SUR TARN,,43.8136980944,1.5350363614,311,La,Magdelaine-sur-Tarn,La Magdelaine-sur-Tarn,31,Haute-Garonne,76,Occitanie
31312,MAILHOLAS,31310,MAILHOLAS,,43.2454899283,1.25132543337,312,,Mailholas,Mailholas,31,Haute-Garonne,76,Occitanie
31313,MALVEZIE,31510,MALVEZIE,,43.0069388711,0.700137350744,313,,Malvezie,Malvezie,31,Haute-Garonne,76,Occitanie
31314,MANCIOUX,31360,MANCIOUX,,43.1668578934,0.941756954338,314,,Mancioux,Mancioux,31,Haute-Garonne,76,Occitanie
31315,MANE,31260,MANE,,43.080178576,0.9447955326,315,,Mane,Mane,31,Haute-Garonne,76,Occitanie
31316,MARIGNAC,31440,MARIGNAC,,42.8948589478,0.666041142898,316,,Marignac,Marignac,31,Haute-Garonne,76,Occitanie
31317,MARIGNAC LASCLARES,31430,MARIGNAC LASCLARES,,43.3049878647,1.10662037582,317,,Marignac-Lasclares,Marignac-Lasclares,31,Haute-Garonne,76,Occitanie
31318,MARIGNAC LASPEYRES,31220,MARIGNAC LASPEYRES,,43.2127779264,0.961748306481,318,,Marignac-Laspeyres,Marignac-Laspeyres,31,Haute-Garonne,76,Occitanie
31319,MARLIAC,31550,MARLIAC,,43.2236919388,1.47249380734,319,,Marliac,Marliac,31,Haute-Garonne,76,Occitanie
31320,MARQUEFAVE,31390,MARQUEFAVE,,43.3095545931,1.25946317951,320,,Marquefave,Marquefave,31,Haute-Garonne,76,Occitanie
31321,MARSOULAS,31260,MARSOULAS,,43.1065481361,0.993468900589,321,,Marsoulas,Marsoulas,31,Haute-Garonne,76,Occitanie
31322,MARTISSERRE,31230,MARTISSERRE,,43.3947730316,0.885338719938,322,,Martisserre,Martisserre,31,Haute-Garonne,76,Occitanie
31323,MARTRES DE RIVIERE,31210,MARTRES DE RIVIERE,,43.0829629746,0.64483561143,323,,Martres-de-Rivière,Martres-de-Rivière,31,Haute-Garonne,76,Occitanie
31324,MARTRES TOLOSANE,31220,MARTRES TOLOSANE,,43.2020024621,1.00117227256,324,,Martres-Tolosane,Martres-Tolosane,31,Haute-Garonne,76,Occitanie
31325,MASCARVILLE,31460,MASCARVILLE,,43.5587512833,1.75383087377,325,,Mascarville,Mascarville,31,Haute-Garonne,76,Occitanie
31326,MASSABRAC,31310,MASSABRAC,,43.2218312967,1.36710649569,326,,Massabrac,Massabrac,31,Haute-Garonne,76,Occitanie
31327,MAURAN,31220,MAURAN,,43.1846795745,1.02272813927,327,,Mauran,Mauran,31,Haute-Garonne,76,Occitanie
31328,MAUREMONT,31290,MAUREMONT,,43.4559384154,1.68152366041,328,,Mauremont,Mauremont,31,Haute-Garonne,76,Occitanie
31329,MAURENS,31540,MAURENS,,43.466341669,1.79779573538,329,,Maurens,Maurens,31,Haute-Garonne,76,Occitanie
31330,MAURESSAC,31190,MAURESSAC,,43.3212250025,1.43428309398,330,,Mauressac,Mauressac,31,Haute-Garonne,76,Occitanie
31331,MAUREVILLE,31460,MAUREVILLE,,43.5296067212,1.71445599659,331,,Maureville,Maureville,31,Haute-Garonne,76,Occitanie
31332,MAUVAISIN,31190,MAUVAISIN,,43.3594273416,1.53862839097,332,,Mauvaisin,Mauvaisin,31,Haute-Garonne,76,Occitanie
31333,MAUVEZIN,31230,MAUVEZIN,,43.3839989782,0.920647647087,333,,Mauvezin,Mauvezin,31,Haute-Garonne,76,Occitanie
31334,MAUZAC,31410,MAUZAC,,43.3756100753,1.30283298461,334,,Mauzac,Mauzac,31,Haute-Garonne,76,Occitanie
31335,MAYREGNE,31110,MAYREGNE,,42.8481521233,0.540039042931,335,,Mayrègne,Mayrègne,31,Haute-Garonne,76,Occitanie
31336,MAZERES SUR SALAT,31260,MAZERES SUR SALAT,,43.1324112099,0.963599428998,336,,Mazères-sur-Salat,Mazères-sur-Salat,31,Haute-Garonne,76,Occitanie
31337,MELLES,31440,MELLES,,42.8670134351,0.808921574218,337,,Melles,Melles,31,Haute-Garonne,76,Occitanie
31338,MENVILLE,31530,MENVILLE,,43.6792240578,1.18745822863,338,,Menville,Menville,31,Haute-Garonne,76,Occitanie
31339,MERENVIELLE,31530,MERENVIELLE,,43.6206862872,1.1676078873,339,,Mérenvielle,Mérenvielle,31,Haute-Garonne,76,Occitanie
31340,MERVILLA,31320,MERVILLA,,43.506330557,1.47317310348,340,,Mervilla,Mervilla,31,Haute-Garonne,76,Occitanie
31341,MERVILLE,31330,MERVILLE,,43.722156746,1.29598401915,341,,Merville,Merville,31,Haute-Garonne,76,Occitanie
31342,MILHAS,31160,MILHAS,,42.9775941,0.827567903546,342,,Milhas,Milhas,31,Haute-Garonne,76,Occitanie
31343,MIRAMBEAU,31230,MIRAMBEAU,,43.4059897744,0.863062543914,343,,Mirambeau,Mirambeau,31,Haute-Garonne,76,Occitanie
31344,MIRAMONT DE COMMINGES,31800,MIRAMONT DE COMMINGES,,43.0914345294,0.755642928853,344,,Miramont-de-Comminges,Miramont-de-Comminges,31,Haute-Garonne,76,Occitanie
31345,MIREMONT,31190,MIREMONT,,43.3768947053,1.41723287336,345,,Miremont,Miremont,31,Haute-Garonne,76,Occitanie
31346,MIREPOIX SUR TARN,31340,MIREPOIX SUR TARN,,43.8243902958,1.5774699768,346,,Mirepoix-sur-Tarn,Mirepoix-sur-Tarn,31,Haute-Garonne,76,Occitanie
31347,MOLAS,31230,MOLAS,,43.4003607782,0.779212974903,347,,Molas,Molas,31,Haute-Garonne,76,Occitanie
31348,MONCAUP,31160,MONCAUP,,42.9729218811,0.701637765798,348,,Moncaup,Moncaup,31,Haute-Garonne,76,Occitanie
31349,MONDAVEZAN,31220,MONDAVEZAN,,43.2413424227,1.04245489237,349,,Mondavezan,Mondavezan,31,Haute-Garonne,76,Occitanie
31350,MONDILHAN,31350,MONDILHAN,,43.2903291141,0.707925774344,350,,Mondilhan,Mondilhan,31,Haute-Garonne,76,Occitanie
31351,MONDONVILLE,31700,MONDONVILLE,,43.6630816074,1.28127988411,351,,Mondonville,Mondonville,31,Haute-Garonne,76,Occitanie
31352,MONDOUZIL,31850,MONDOUZIL,,43.6315742429,1.56216413003,352,,Mondouzil,Mondouzil,31,Haute-Garonne,76,Occitanie
31353,MONES,31370,MONES,,43.4181669738,1.03320759,353,,Monès,Monès,31,Haute-Garonne,76,Occitanie
31354,MONESTROL,31560,MONESTROL,,43.3322404814,1.66273586519,354,,Monestrol,Monestrol,31,Haute-Garonne,76,Occitanie
31355,MONS,31280,MONS,,43.6112261836,1.57399371785,355,,Mons,Mons,31,Haute-Garonne,76,Occitanie
31356,MONTAIGUT SUR SAVE,31530,MONTAIGUT SUR SAVE,,43.6803943619,1.2345663902,356,,Montaigut-sur-Save,Montaigut-sur-Save,31,Haute-Garonne,76,Occitanie
31357,MONTASTRUC DE SALIES,31160,MONTASTRUC DE SALIES,,43.0375060146,0.901473462822,357,,Montastruc-de-Salies,Montastruc-de-Salies,31,Haute-Garonne,76,Occitanie
31358,MONTASTRUC LA CONSEILLERE,31380,MONTASTRUC LA CONSEILLERE,,43.7221234765,1.58906433836,358,,Montastruc-la-Conseillère,Montastruc-la-Conseillère,31,Haute-Garonne,76,Occitanie
31359,MONTASTRUC SAVES,31370,MONTASTRUC SAVES,,43.3616344154,1.01900079597,359,,Montastruc-Savès,Montastruc-Savès,31,Haute-Garonne,76,Occitanie
31360,MONTAUBAN DE LUCHON,31110,MONTAUBAN DE LUCHON,,42.7905243609,0.623354384016,360,,Montauban-de-Luchon,Montauban-de-Luchon,31,Haute-Garonne,76,Occitanie
31361,MONTAUT,31410,MONTAUT,,43.3455690999,1.31066640833,361,,Montaut,Montaut,31,Haute-Garonne,76,Occitanie
31362,MONTBERAUD,31220,MONTBERAUD,,43.1595238311,1.15513584785,362,,Montberaud,Montberaud,31,Haute-Garonne,76,Occitanie
31363,MONTBERNARD,31230,MONTBERNARD,,43.3042093093,0.767840933325,363,,Montbernard,Montbernard,31,Haute-Garonne,76,Occitanie
31364,MONTBERON,31140,MONTBERON,,43.7203107344,1.48894998415,364,,Montberon,Montberon,31,Haute-Garonne,76,Occitanie
31365,MONTBRUN BOCAGE,31310,MONTBRUN BOCAGE,,43.1195780479,1.24932023681,365,,Montbrun-Bocage,Montbrun-Bocage,31,Haute-Garonne,76,Occitanie
31366,MONTBRUN LAURAGAIS,31450,MONTBRUN LAURAGAIS,,43.4558727053,1.51983884361,366,,Montbrun-Lauragais,Montbrun-Lauragais,31,Haute-Garonne,76,Occitanie
31367,MONTCLAR DE COMMINGES,31220,MONTCLAR DE COMMINGES,,43.1709624845,1.02937210204,367,,Montclar-de-Comminges,Montclar-de-Comminges,31,Haute-Garonne,76,Occitanie
31368,MONTCLAR LAURAGAIS,31290,MONTCLAR LAURAGAIS,,43.362728431,1.71364639022,368,,Montclar-Lauragais,Montclar-Lauragais,31,Haute-Garonne,76,Occitanie
31369,MONT DE GALIE,31510,MONT DE GALIE,,42.9926932624,0.646724731976,369,,Mont-de-Galié,Mont-de-Galié,31,Haute-Garonne,76,Occitanie
31370,MONTEGUT BOURJAC,31430,MONTEGUT BOURJAC,,43.2815929333,0.985102720246,370,,Montégut-Bourjac,Montégut-Bourjac,31,Haute-Garonne,76,Occitanie
31371,MONTEGUT LAURAGAIS,31540,MONTEGUT LAURAGAIS,,43.4775080465,1.93140539637,371,,Montégut-Lauragais,Montégut-Lauragais,31,Haute-Garonne,76,Occitanie
31372,MONTESPAN,31260,MONTESPAN,,43.085626713,0.859781561724,372,,Montespan,Montespan,31,Haute-Garonne,76,Occitanie
31373,MONTESQUIEU GUITTAUT,31230,MONTESQUIEU GUITTAUT,,43.3386291505,0.767202844141,373,,Montesquieu-Guittaut,Montesquieu-Guittaut,31,Haute-Garonne,76,Occitanie
31374,MONTESQUIEU LAURAGAIS,31450,MONTESQUIEU LAURAGAIS,,43.4073286541,1.62763249857,374,,Montesquieu-Lauragais,Montesquieu-Lauragais,31,Haute-Garonne,76,Occitanie
31375,MONTESQUIEU VOLVESTRE,31310,MONTESQUIEU VOLVESTRE,,43.192539602,1.21785196007,375,,Montesquieu-Volvestre,Montesquieu-Volvestre,31,Haute-Garonne,76,Occitanie
31376,MONTGAILLARD DE SALIES,31260,MONTGAILLARD DE SALIES,,43.0589200964,0.93221860467,376,,Montgaillard-de-Salies,Montgaillard-de-Salies,31,Haute-Garonne,76,Occitanie
31377,MONTGAILLARD LAURAGAIS,31290,MONTGAILLARD LAURAGAIS,,43.428491369,1.70265269114,377,,Montgaillard-Lauragais,Montgaillard-Lauragais,31,Haute-Garonne,76,Occitanie
31378,MONTGAILLARD SUR SAVE,31350,MONTGAILLARD SUR SAVE,,43.2545847834,0.717487034111,378,,Montgaillard-sur-Save,Montgaillard-sur-Save,31,Haute-Garonne,76,Occitanie
31379,MONTGAZIN,31410,MONTGAZIN,,43.3054212469,1.30333898897,379,,Montgazin,Montgazin,31,Haute-Garonne,76,Occitanie
31380,MONTGEARD,31560,MONTGEARD,,43.3434044557,1.64154767835,380,,Montgeard,Montgeard,31,Haute-Garonne,76,Occitanie
31381,MONTGISCARD,31450,MONTGISCARD,,43.4551909277,1.57052406322,381,,Montgiscard,Montgiscard,31,Haute-Garonne,76,Occitanie
31382,MONTGRAS,31370,MONTGRAS,,43.4499161707,1.06831999402,382,,Montgras,Montgras,31,Haute-Garonne,76,Occitanie
31383,MONTJOIRE,31380,MONTJOIRE,,43.7787304929,1.52666645507,383,,Montjoire,Montjoire,31,Haute-Garonne,76,Occitanie
31384,MONTLAUR,31450,MONTLAUR,,43.4853075603,1.5806088714,384,,Montlaur,Montlaur,31,Haute-Garonne,76,Occitanie
31385,MONTMAURIN,31350,MONTMAURIN,,43.2268173084,0.636100975805,385,,Montmaurin,Montmaurin,31,Haute-Garonne,76,Occitanie
31386,MONTOULIEU ST BERNARD,31420,MONTOULIEU ST BERNARD,,43.2309082471,0.906414880793,386,,Montoulieu-Saint-Bernard,Montoulieu-Saint-Bernard,31,Haute-Garonne,76,Occitanie
31387,MONTOUSSIN,31430,MONTOUSSIN,,43.2800737238,1.01115822318,387,,Montoussin,Montoussin,31,Haute-Garonne,76,Occitanie
31388,MONTPITOL,31380,MONTPITOL,,43.7047230773,1.64247367519,388,,Montpitol,Montpitol,31,Haute-Garonne,76,Occitanie
31389,MONTRABE,31850,MONTRABE,,43.6424532193,1.5288063297,389,,Montrabé,Montrabé,31,Haute-Garonne,76,Occitanie
31390,MONTREJEAU,31210,MONTREJEAU,,43.0885884936,0.558463262724,390,,Montréjeau,Montréjeau,31,Haute-Garonne,76,Occitanie
31391,MONTSAUNES,31260,MONTSAUNES,,43.1102814819,0.932230467606,391,,Montsaunès,Montsaunès,31,Haute-Garonne,76,Occitanie
31392,MOURVILLES BASSES,31460,MOURVILLES BASSES,,43.4889625635,1.69918635087,392,,Mourvilles-Basses,Mourvilles-Basses,31,Haute-Garonne,76,Occitanie
31393,MOURVILLES HAUTES,31540,MOURVILLES HAUTES,,43.4244396823,1.81979265347,393,,Mourvilles-Hautes,Mourvilles-Hautes,31,Haute-Garonne,76,Occitanie
31394,MOUSTAJON,31110,MOUSTAJON,,42.8148264223,0.591908165377,394,,Moustajon,Moustajon,31,Haute-Garonne,76,Occitanie
31395,MURET,31600,MURET,,43.4491077783,1.30784679414,395,,Muret,Muret,31,Haute-Garonne,76,Occitanie
31396,NAILLOUX,31560,NAILLOUX,,43.3652413733,1.61316756794,396,,Nailloux,Nailloux,31,Haute-Garonne,76,Occitanie
31397,NENIGAN,31350,NENIGAN,,43.3532194383,0.708883307401,397,,Nénigan,Nénigan,31,Haute-Garonne,76,Occitanie
31398,NIZAN GESSE,31350,NIZAN GESSE,,43.2304323776,0.596409287994,398,,Nizan-Gesse,Nizan-Gesse,31,Haute-Garonne,76,Occitanie
31399,NOE,31410,NOE,,43.3605188083,1.27051945218,399,,Noé,Noé,31,Haute-Garonne,76,Occitanie
31400,NOGARET,31540,NOGARET,,43.4925754832,1.93375847468,400,,Nogaret,Nogaret,31,Haute-Garonne,76,Occitanie
31401,NOUEILLES,31450,NOUEILLES,,43.4150222311,1.52583069326,401,,Noueilles,Noueilles,31,Haute-Garonne,76,Occitanie
31402,ODARS,31450,ODARS,,43.5219394516,1.59326586441,402,,Odars,Odars,31,Haute-Garonne,76,Occitanie
31403,ONDES,31330,ONDES,,43.7861277519,1.30409747557,403,,Ondes,Ondes,31,Haute-Garonne,76,Occitanie
31404,OO,31110,OO,,42.7417285924,0.496415293313,404,,Oô,Oô,31,Haute-Garonne,76,Occitanie
31405,ORE,31510,ORE,,42.9736103236,0.646200614078,405,,Ore,Ore,31,Haute-Garonne,76,Occitanie
31406,PALAMINY,31220,PALAMINY,,43.1942889645,1.06030325813,406,,Palaminy,Palaminy,31,Haute-Garonne,76,Occitanie
31407,PAULHAC,31380,PAULHAC,,43.7542223312,1.55478228901,407,,Paulhac,Paulhac,31,Haute-Garonne,76,Occitanie
31408,PAYSSOUS,31510,PAYSSOUS,,43.0343405914,0.717177002702,408,,Payssous,Payssous,31,Haute-Garonne,76,Occitanie
31409,PECHABOU,31320,PECHABOU,,43.5015132384,1.51044990153,409,,Péchabou,Péchabou,31,Haute-Garonne,76,Occitanie
31410,PECHBONNIEU,31140,PECHBONNIEU,,43.7112584886,1.45879137829,410,,Pechbonnieu,Pechbonnieu,31,Haute-Garonne,76,Occitanie
31411,PECHBUSQUE,31320,PECHBUSQUE,,43.5246551254,1.45720316264,411,,Pechbusque,Pechbusque,31,Haute-Garonne,76,Occitanie
31412,PEGUILHAN,31350,PEGUILHAN,,43.3182848209,0.703637578751,412,,Péguilhan,Péguilhan,31,Haute-Garonne,76,Occitanie
31413,PELLEPORT,31480,PELLEPORT,,43.7414209784,1.11866923915,413,,Pelleport,Pelleport,31,Haute-Garonne,76,Occitanie
31414,PEYRISSAS,31420,PEYRISSAS,,43.2881718442,0.911650250915,414,,Peyrissas,Peyrissas,31,Haute-Garonne,76,Occitanie
31415,PEYROUZET,31420,PEYROUZET,,43.2079044252,0.832151962989,415,,Peyrouzet,Peyrouzet,31,Haute-Garonne,76,Occitanie
31416,PEYSSIES,31390,PEYSSIES,,43.3257060217,1.18484909158,416,,Peyssies,Peyssies,31,Haute-Garonne,76,Occitanie
31417,PIBRAC,31820,PIBRAC,,43.6274385363,1.26139475262,417,,Pibrac,Pibrac,31,Haute-Garonne,76,Occitanie
31418,PIN BALMA,31130,PIN BALMA,,43.6223426383,1.53573594468,418,,Pin-Balma,Pin-Balma,31,Haute-Garonne,76,Occitanie
31419,LE PIN MURELET,31370,LE PIN MURELET,,43.3973997258,1.02131338284,419,Le,Pin-Murelet,Le Pin-Murelet,31,Haute-Garonne,76,Occitanie
31420,PINSAGUEL,31120,PINSAGUEL,,43.503057751,1.39393626333,420,,Pinsaguel,Pinsaguel,31,Haute-Garonne,76,Occitanie
31421,PINS JUSTARET,31860,PINS JUSTARET,,43.4824421099,1.3908759872,421,,Pins-Justaret,Pins-Justaret,31,Haute-Garonne,76,Occitanie
31422,PLAGNE,31220,PLAGNE,,43.1609212435,1.0589815244,422,,Plagne,Plagne,31,Haute-Garonne,76,Occitanie
31423,PLAGNOLE,31370,PLAGNOLE,,43.4114527316,1.06388606316,423,,Plagnole,Plagnole,31,Haute-Garonne,76,Occitanie
31424,PLAISANCE DU TOUCH,31830,PLAISANCE DU TOUCH,,43.557591642,1.28585811821,424,,Plaisance-du-Touch,Plaisance-du-Touch,31,Haute-Garonne,76,Occitanie
31425,LE PLAN,31220,LE PLAN,,43.1672002884,1.12227665636,425,Le,Plan,Le Plan,31,Haute-Garonne,76,Occitanie
31426,POINTIS DE RIVIERE,31210,POINTIS DE RIVIERE,,43.0908571473,0.625846864385,426,,Pointis-de-Rivière,Pointis-de-Rivière,31,Haute-Garonne,76,Occitanie
31427,POINTIS INARD,31800,POINTIS INARD,,43.0835798216,0.79692777032,427,,Pointis-Inard,Pointis-Inard,31,Haute-Garonne,76,Occitanie
31428,POLASTRON,31430,POLASTRON,,43.3196170581,0.951660805068,428,,Polastron,Polastron,31,Haute-Garonne,76,Occitanie
31429,POMPERTUZAT,31450,POMPERTUZAT,,43.4942932481,1.52109130847,429,,Pompertuzat,Pompertuzat,31,Haute-Garonne,76,Occitanie
31430,PONLAT TAILLEBOURG,31210,PONLAT TAILLEBOURG,,43.1122469519,0.596250257433,430,,Ponlat-Taillebourg,Ponlat-Taillebourg,31,Haute-Garonne,76,Occitanie
31431,PORTET D ASPET,31160,PORTET D ASPET,,42.9423055988,0.855583620604,431,,Portet-d'Aspet,Portet-d'Aspet,31,Haute-Garonne,76,Occitanie
31432,PORTET DE LUCHON,31110,PORTET DE LUCHON,,42.811445173,0.47107519018,432,,Portet-de-Luchon,Portet-de-Luchon,31,Haute-Garonne,76,Occitanie
31433,PORTET SUR GARONNE,31120,PORTET SUR GARONNE,,43.529395272,1.40251955724,433,,Portet-sur-Garonne,Portet-sur-Garonne,31,Haute-Garonne,76,Occitanie
31434,POUBEAU,31110,POUBEAU,,42.8277751816,0.492783211671,434,,Poubeau,Poubeau,31,Haute-Garonne,76,Occitanie
31435,POUCHARRAMET,31370,POUCHARRAMET,,43.423058709,1.16684453964,435,,Poucharramet,Poucharramet,31,Haute-Garonne,76,Occitanie
31436,POUY DE TOUGES,31430,POUY DE TOUGES,,43.3360758375,1.04935301244,436,,Pouy-de-Touges,Pouy-de-Touges,31,Haute-Garonne,76,Occitanie
31437,POUZE,31450,POUZE,,43.4340476482,1.53418307965,437,,Pouze,Pouze,31,Haute-Garonne,76,Occitanie
31438,PRADERE LES BOURGUETS,31530,PRADERE LES BOURGUETS,,43.6467477305,1.1551746882,438,,Pradère-les-Bourguets,Pradère-les-Bourguets,31,Haute-Garonne,76,Occitanie
31439,PRESERVILLE,31570,PRESERVILLE,,43.520415718,1.63189068715,439,,Préserville,Préserville,31,Haute-Garonne,76,Occitanie
31440,PROUPIARY,31360,PROUPIARY,,43.158322646,0.867952822303,440,,Proupiary,Proupiary,31,Haute-Garonne,76,Occitanie
31441,PRUNET,31460,PRUNET,,43.572419221,1.72976428437,441,,Prunet,Prunet,31,Haute-Garonne,76,Occitanie
31442,PUYDANIEL,31190,PUYDANIEL,,43.3315793441,1.42275207158,442,,Puydaniel,Puydaniel,31,Haute-Garonne,76,Occitanie
31443,PUYMAURIN,31230,PUYMAURIN,,43.3702723747,0.752565450774,443,,Puymaurin,Puymaurin,31,Haute-Garonne,76,Occitanie
31444,PUYSSEGUR,31480,PUYSSEGUR,,43.7519671546,1.06615759128,444,,Puysségur,Puysségur,31,Haute-Garonne,76,Occitanie
31445,QUINT FONSEGRIVES,31130,QUINT FONSEGRIVES,,43.5815682751,1.54212357917,445,,Quint-Fonsegrives,Quint-Fonsegrives,31,Haute-Garonne,76,Occitanie
31446,RAMONVILLE ST AGNE,31520,RAMONVILLE ST AGNE,,43.5441837911,1.47782372823,446,,Ramonville-Saint-Agne,Ramonville-Saint-Agne,31,Haute-Garonne,76,Occitanie
31447,RAZECUEILLE,31160,RAZECUEILLE,,42.9630940421,0.81413604771,447,,Razecueillé,Razecueillé,31,Haute-Garonne,76,Occitanie
31448,REBIGUE,31320,REBIGUE,,43.4900829382,1.47765829898,448,,Rebigue,Rebigue,31,Haute-Garonne,76,Occitanie
31449,REGADES,31800,REGADES,,43.0533534739,0.717699496738,449,,Régades,Régades,31,Haute-Garonne,76,Occitanie
31450,RENNEVILLE,31290,RENNEVILLE,,43.3790411122,1.7213166535,450,,Renneville,Renneville,31,Haute-Garonne,76,Occitanie
31451,REVEL,31250,REVEL,,43.4656037799,1.99689394052,451,,Revel,Revel,31,Haute-Garonne,76,Occitanie
31451,REVEL,31250,REVEL,ST FERREOL LE LAC,43.4656037799,1.99689394052,451,,Revel,Revel,31,Haute-Garonne,76,Occitanie
31452,RIEUCAZE,31800,RIEUCAZE,,43.0758069309,0.75229643929,452,,Rieucazé,Rieucazé,31,Haute-Garonne,76,Occitanie
31453,RIEUMAJOU,31290,RIEUMAJOU,,43.4120253183,1.80278732023,453,,Rieumajou,Rieumajou,31,Haute-Garonne,76,Occitanie
31454,RIEUMES,31370,RIEUMES,,43.4121701053,1.11454294712,454,,Rieumes,Rieumes,31,Haute-Garonne,76,Occitanie
31455,RIEUX VOLVESTRE,31310,RIEUX VOLVESTRE,,43.2555733864,1.20637769393,455,,Rieux-Volvestre,Rieux-Volvestre,31,Haute-Garonne,76,Occitanie
31456,RIOLAS,31230,RIOLAS,,43.3508257727,0.919273823199,456,,Riolas,Riolas,31,Haute-Garonne,76,Occitanie
31457,ROQUEFORT SUR GARONNE,31360,ROQUEFORT SUR GARONNE,,43.1598351922,0.986755873381,457,,Roquefort-sur-Garonne,Roquefort-sur-Garonne,31,Haute-Garonne,76,Occitanie
31458,ROQUES,31120,ROQUES,,43.5074145403,1.35458627554,458,,Roques,Roques,31,Haute-Garonne,76,Occitanie
31459,ROQUESERIERE,31380,ROQUESERIERE,,43.7337247412,1.63773402286,459,,Roquesérière,Roquesérière,31,Haute-Garonne,76,Occitanie
31460,ROQUETTES,31120,ROQUETTES,,43.4947364798,1.37416616378,460,,Roquettes,Roquettes,31,Haute-Garonne,76,Occitanie
31461,ROUEDE,31160,ROUEDE,,43.0564508263,0.882775908719,461,,Rouède,Rouède,31,Haute-Garonne,76,Occitanie
31462,ROUFFIAC TOLOSAN,31180,ROUFFIAC TOLOSAN,,43.6638973409,1.52541943073,462,,Rouffiac-Tolosan,Rouffiac-Tolosan,31,Haute-Garonne,76,Occitanie
31463,ROUMENS,31540,ROUMENS,,43.4646275014,1.93578142821,463,,Roumens,Roumens,31,Haute-Garonne,76,Occitanie
31464,SABONNERES,31370,SABONNERES,,43.4686663563,1.05725626778,464,,Sabonnères,Sabonnères,31,Haute-Garonne,76,Occitanie
31465,SACCOURVIELLE,31110,SACCOURVIELLE,,42.8184881907,0.568108332356,465,,Saccourvielle,Saccourvielle,31,Haute-Garonne,76,Occitanie
31466,SAIGUEDE,31470,SAIGUEDE,,43.5233769475,1.14231751485,466,,Saiguède,Saiguède,31,Haute-Garonne,76,Occitanie
31467,ST ALBAN,31140,ST ALBAN,,43.6927708878,1.41260524785,467,,Saint-Alban,Saint-Alban,31,Haute-Garonne,76,Occitanie
31468,ST ANDRE,31420,ST ANDRE,,43.2742895266,0.847168180191,468,,Saint-André,Saint-André,31,Haute-Garonne,76,Occitanie
31469,ST ARAILLE,31430,ST ARAILLE,,43.352440739,1.00393657756,469,,Saint-Araille,Saint-Araille,31,Haute-Garonne,76,Occitanie
31470,ST AVENTIN,31110,ST AVENTIN,,42.7594170368,0.576677377934,470,,Saint-Aventin,Saint-Aventin,31,Haute-Garonne,76,Occitanie
31471,ST BEAT,31440,ST BEAT,,42.9045912184,0.685235191061,471,,Saint-Béat,Saint-Béat,31,Haute-Garonne,76,Occitanie
31472,ST BERTRAND DE COMMINGES,31510,ST BERTRAND DE COMMINGES,,43.0239495224,0.552413106275,472,,Saint-Bertrand-de-Comminges,Saint-Bertrand-de-Comminges,31,Haute-Garonne,76,Occitanie
31473,ST CEZERT,31330,ST CEZERT,,43.7809324067,1.19152811145,473,,Saint-Cézert,Saint-Cézert,31,Haute-Garonne,76,Occitanie
31474,ST CHRISTAUD,31310,ST CHRISTAUD,,43.1924235552,1.12803637637,474,,Saint-Christaud,Saint-Christaud,31,Haute-Garonne,76,Occitanie
31475,ST CLAR DE RIVIERE,31600,ST CLAR DE RIVIERE,,43.4758983843,1.20896843964,475,,Saint-Clar-de-Rivière,Saint-Clar-de-Rivière,31,Haute-Garonne,76,Occitanie
31476,ST ELIX LE CHATEAU,31430,ST ELIX LE CHATEAU,,43.2818516873,1.13779524495,476,,Saint-Élix-le-Château,Saint-Élix-le-Château,31,Haute-Garonne,76,Occitanie
31477,ST ELIX SEGLAN,31420,ST ELIX SEGLAN,,43.1955942672,0.849139152455,477,,Saint-Élix-Séglan,Saint-Élix-Séglan,31,Haute-Garonne,76,Occitanie
31478,ST FELIX LAURAGAIS,31540,ST FELIX LAURAGAIS,,43.4505941916,1.90172958444,478,,Saint-Félix-Lauragais,Saint-Félix-Lauragais,31,Haute-Garonne,76,Occitanie
31479,ST FERREOL DE COMMINGES,31350,ST FERREOL DE COMMINGES,,43.3386598955,0.736025268663,479,,Saint-Ferréol-de-Comminges,Saint-Ferréol-de-Comminges,31,Haute-Garonne,76,Occitanie
31480,STE FOY D AIGREFEUILLE,31570,STE FOY D AIGREFEUILLE,,43.5509540982,1.60301367182,480,,Sainte-Foy-d'Aigrefeuille,Sainte-Foy-d'Aigrefeuille,31,Haute-Garonne,76,Occitanie
31481,STE FOY DE PEYROLIERES,31470,STE FOY DE PEYROLIERES,,43.4808986734,1.13693209455,481,,Sainte-Foy-de-Peyrolières,Sainte-Foy-de-Peyrolières,31,Haute-Garonne,76,Occitanie
31482,ST FRAJOU,31230,ST FRAJOU,,43.3310218183,0.85024475987,482,,Saint-Frajou,Saint-Frajou,31,Haute-Garonne,76,Occitanie
31483,ST GAUDENS,31800,ST GAUDENS,,43.1199260811,0.726613564199,483,,Saint-Gaudens,Saint-Gaudens,31,Haute-Garonne,76,Occitanie
31484,ST GENIES BELLEVUE,31180,ST GENIES BELLEVUE,,43.6848850771,1.48047033439,484,,Saint-Geniès-Bellevue,Saint-Geniès-Bellevue,31,Haute-Garonne,76,Occitanie
31485,ST GERMIER,31290,ST GERMIER,,43.4690645012,1.7252032772,485,,Saint-Germier,Saint-Germier,31,Haute-Garonne,76,Occitanie
31486,ST HILAIRE,31410,ST HILAIRE,,43.4206504787,1.26875220211,486,,Saint-Hilaire,Saint-Hilaire,31,Haute-Garonne,76,Occitanie
31487,ST IGNAN,31800,ST IGNAN,,43.1568513226,0.68984481098,487,,Saint-Ignan,Saint-Ignan,31,Haute-Garonne,76,Occitanie
31488,ST JEAN,31240,ST JEAN,,43.6632000218,1.50080213607,488,,Saint-Jean,Saint-Jean,31,Haute-Garonne,76,Occitanie
31489,ST JEAN LHERM,31380,ST JEAN LHERM,,43.6977269896,1.61794512613,489,,Saint-Jean-Lherm,Saint-Jean-Lherm,31,Haute-Garonne,76,Occitanie
31490,ST JORY,31790,ST JORY,,43.739344106,1.35625454624,490,,Saint-Jory,Saint-Jory,31,Haute-Garonne,76,Occitanie
31491,ST JULIA,31540,ST JULIA,,43.4940652738,1.89419935625,491,,Saint-Julia,Saint-Julia,31,Haute-Garonne,76,Occitanie
31492,ST JULIEN SUR GARONNE,31220,ST JULIEN SUR GARONNE,,43.2495379286,1.14676806452,492,,Saint-Julien-sur-Garonne,Saint-Julien-sur-Garonne,31,Haute-Garonne,76,Occitanie
31493,ST LARY BOUJEAN,31350,ST LARY BOUJEAN,,43.2218813039,0.740007283521,493,,Saint-Lary-Boujean,Saint-Lary-Boujean,31,Haute-Garonne,76,Occitanie
31494,ST LAURENT,31230,ST LAURENT,,43.3254205164,0.800339678541,494,,Saint-Laurent,Saint-Laurent,31,Haute-Garonne,76,Occitanie
31495,ST LEON,31560,ST LEON,,43.3970008535,1.56284460409,495,,Saint-Léon,Saint-Léon,31,Haute-Garonne,76,Occitanie
31496,STE LIVRADE,31530,STE LIVRADE,,43.6486109155,1.11198442378,496,,Sainte-Livrade,Sainte-Livrade,31,Haute-Garonne,76,Occitanie
31497,ST LOUP CAMMAS,31140,ST LOUP CAMMAS,,43.6978585888,1.47992781027,497,,Saint-Loup-Cammas,Saint-Loup-Cammas,31,Haute-Garonne,76,Occitanie
31498,ST LOUP EN COMMINGES,31350,ST LOUP EN COMMINGES,,43.2423391988,0.573273733666,498,,Saint-Loup-en-Comminges,Saint-Loup-en-Comminges,31,Haute-Garonne,76,Occitanie
31499,ST LYS,31470,ST LYS,,43.5100073275,1.19955711914,499,,Saint-Lys,Saint-Lys,31,Haute-Garonne,76,Occitanie
31500,ST MAMET,31110,ST MAMET,,42.7721497785,0.630003335404,500,,Saint-Mamet,Saint-Mamet,31,Haute-Garonne,76,Occitanie
31501,ST MARCEL PAULEL,31590,ST MARCEL PAULEL,,43.6566271869,1.61471686848,501,,Saint-Marcel-Paulel,Saint-Marcel-Paulel,31,Haute-Garonne,76,Occitanie
31502,ST MARCET,31800,ST MARCET,,43.1967853101,0.745570888474,502,,Saint-Marcet,Saint-Marcet,31,Haute-Garonne,76,Occitanie
31503,ST MARTORY,31360,ST MARTORY,,43.1434206034,0.936747778684,503,,Saint-Martory,Saint-Martory,31,Haute-Garonne,76,Occitanie
31504,ST MEDARD,31360,ST MEDARD,,43.1279328129,0.829383630531,504,,Saint-Médard,Saint-Médard,31,Haute-Garonne,76,Occitanie
31505,ST MICHEL,31220,ST MICHEL,,43.1590661919,1.08578310989,505,,Saint-Michel,Saint-Michel,31,Haute-Garonne,76,Occitanie
31506,ST ORENS DE GAMEVILLE,31650,ST ORENS DE GAMEVILLE,,43.5590787673,1.53513710649,506,,Saint-Orens-de-Gameville,Saint-Orens-de-Gameville,31,Haute-Garonne,76,Occitanie
31507,ST PAUL SUR SAVE,31530,ST PAUL SUR SAVE,,43.69951639,1.22058648021,507,,Saint-Paul-sur-Save,Saint-Paul-sur-Save,31,Haute-Garonne,76,Occitanie
31508,ST PAUL D OUEIL,31110,ST PAUL D OUEIL,,42.8382523889,0.555644090408,508,,Saint-Paul-d'Oueil,Saint-Paul-d'Oueil,31,Haute-Garonne,76,Occitanie
31509,ST PE D ARDET,31510,ST PE D ARDET,,42.9860697615,0.679972122876,509,,Saint-Pé-d'Ardet,Saint-Pé-d'Ardet,31,Haute-Garonne,76,Occitanie
31510,ST PE DELBOSC,31350,ST PE DELBOSC,,43.2673675811,0.694204654124,510,,Saint-Pé-Delbosc,Saint-Pé-Delbosc,31,Haute-Garonne,76,Occitanie
31511,ST PIERRE,31590,ST PIERRE,,43.6368366534,1.64168080442,511,,Saint-Pierre,Saint-Pierre,31,Haute-Garonne,76,Occitanie
31512,ST PIERRE DE LAGES,31570,ST PIERRE DE LAGES,,43.5681959478,1.62684494584,512,,Saint-Pierre-de-Lages,Saint-Pierre-de-Lages,31,Haute-Garonne,76,Occitanie
31513,ST PLANCARD,31580,ST PLANCARD,,43.1679631081,0.570009022734,513,,Saint-Plancard,Saint-Plancard,31,Haute-Garonne,76,Occitanie
31514,ST ROME,31290,ST ROME,,43.4150165631,1.67437209269,514,,Saint-Rome,Saint-Rome,31,Haute-Garonne,76,Occitanie
31515,ST RUSTICE,31620,ST RUSTICE,,43.8031334358,1.32817608757,515,,Saint-Rustice,Saint-Rustice,31,Haute-Garonne,76,Occitanie
31516,ST SAUVEUR,31790,ST SAUVEUR,,43.7513409309,1.3957911718,516,,Saint-Sauveur,Saint-Sauveur,31,Haute-Garonne,76,Occitanie
31517,ST SULPICE SUR LEZE,31410,ST SULPICE SUR LEZE,,43.3245529434,1.34127372326,517,,Saint-Sulpice-sur-Lèze,Saint-Sulpice-sur-Lèze,31,Haute-Garonne,76,Occitanie
31518,ST THOMAS,31470,ST THOMAS,,43.5147776927,1.08883796695,518,,Saint-Thomas,Saint-Thomas,31,Haute-Garonne,76,Occitanie
31519,ST VINCENT,31290,ST VINCENT,,43.440547263,1.75817528025,519,,Saint-Vincent,Saint-Vincent,31,Haute-Garonne,76,Occitanie
31520,SAJAS,31370,SAJAS,,43.3752549153,1.02521661317,520,,Sajas,Sajas,31,Haute-Garonne,76,Occitanie
31521,SALEICH,31260,SALEICH,,43.02832537,0.968226003804,521,,Saleich,Saleich,31,Haute-Garonne,76,Occitanie
31522,SALERM,31230,SALERM,,43.3071722167,0.824917497249,522,,Salerm,Salerm,31,Haute-Garonne,76,Occitanie
31523,SALIES DU SALAT,31260,SALIES DU SALAT,,43.1013456235,0.962933495319,523,,Salies-du-Salat,Salies-du-Salat,31,Haute-Garonne,76,Occitanie
31524,SALLES ET PRATVIEL,31110,SALLES ET PRATVIEL,,42.8332555084,0.606780460661,524,,Salles-et-Pratviel,Salles-et-Pratviel,31,Haute-Garonne,76,Occitanie
31525,SALLES SUR GARONNE,31390,SALLES SUR GARONNE,,43.2764220901,1.17237669844,525,,Salles-sur-Garonne,Salles-sur-Garonne,31,Haute-Garonne,76,Occitanie
31526,LA SALVETAT ST GILLES,31880,LA SALVETAT ST GILLES,,43.5754800218,1.26616319872,526,La,Salvetat-Saint-Gilles,La Salvetat-Saint-Gilles,31,Haute-Garonne,76,Occitanie
31527,LA SALVETAT LAURAGAIS,31460,LA SALVETAT LAURAGAIS,,43.547792475,1.80042409338,527,La,Salvetat-Lauragais,La Salvetat-Lauragais,31,Haute-Garonne,76,Occitanie
31528,SAMAN,31350,SAMAN,,43.2385588736,0.721968403674,528,,Saman,Saman,31,Haute-Garonne,76,Occitanie
31529,SAMOUILLAN,31420,SAMOUILLAN,,43.2672173655,0.949728378128,529,,Samouillan,Samouillan,31,Haute-Garonne,76,Occitanie
31530,SANA,31220,SANA,,43.2274229505,1.01117765255,530,,Sana,Sana,31,Haute-Garonne,76,Occitanie
31531,SARRECAVE,31350,SARRECAVE,,43.2147533166,0.594972592507,531,,Sarrecave,Sarrecave,31,Haute-Garonne,76,Occitanie
31532,SARREMEZAN,31350,SARREMEZAN,,43.2114854426,0.662154429607,532,,Sarremezan,Sarremezan,31,Haute-Garonne,76,Occitanie
31533,SAUBENS,31600,SAUBENS,,43.4793134879,1.3595994131,533,,Saubens,Saubens,31,Haute-Garonne,76,Occitanie
31534,SAUSSENS,31460,SAUSSENS,,43.590855656,1.72440998002,534,,Saussens,Saussens,31,Haute-Garonne,76,Occitanie
31535,SAUVETERRE DE COMMINGES,31510,SAUVETERRE DE COMMINGES,,43.0336613165,0.672650254321,535,,Sauveterre-de-Comminges,Sauveterre-de-Comminges,31,Haute-Garonne,76,Occitanie
31536,SAUX ET POMAREDE,31800,SAUX ET POMAREDE,,43.1487043658,0.704879959692,536,,Saux-et-Pomarède,Saux-et-Pomarède,31,Haute-Garonne,76,Occitanie
31537,SAVARTHES,31800,SAVARTHES,,43.1222207361,0.803999322883,537,,Savarthès,Savarthès,31,Haute-Garonne,76,Occitanie
31538,SAVERES,31370,SAVERES,,43.3725748924,1.10003164798,538,,Savères,Savères,31,Haute-Garonne,76,Occitanie
31539,SEDEILHAC,31580,SEDEILHAC,,43.1477203277,0.542746469406,539,,Sédeilhac,Sédeilhac,31,Haute-Garonne,76,Occitanie
31540,SEGREVILLE,31460,SEGREVILLE,,43.4914303481,1.74727223861,540,,Ségreville,Ségreville,31,Haute-Garonne,76,Occitanie
31541,SEILH,31840,SEILH,,43.6900953799,1.35675007167,541,,Seilh,Seilh,31,Haute-Garonne,76,Occitanie
31542,SEILHAN,31510,SEILHAN,,43.0527421754,0.576818914765,542,,Seilhan,Seilhan,31,Haute-Garonne,76,Occitanie
31543,SENARENS,31430,SENARENS,,43.3519005165,0.97595832154,543,,Sénarens,Sénarens,31,Haute-Garonne,76,Occitanie
31544,SENGOUAGNET,31160,SENGOUAGNET,,42.9551995435,0.784279293966,544,,Sengouagnet,Sengouagnet,31,Haute-Garonne,76,Occitanie
31545,SEPX,31360,SEPX,,43.1553410067,0.83700632268,545,,Sepx,Sepx,31,Haute-Garonne,76,Occitanie
31546,SEYRE,31560,SEYRE,,43.3641254095,1.66358744596,546,,Seyre,Seyre,31,Haute-Garonne,76,Occitanie
31547,SEYSSES,31600,SEYSSES,,43.4988915794,1.28690575634,547,,Seysses,Seysses,31,Haute-Garonne,76,Occitanie
31548,SIGNAC,31440,SIGNAC,,42.9101955817,0.617550184326,548,,Signac,Signac,31,Haute-Garonne,76,Occitanie
31549,SODE,31110,SODE,,42.8110087374,0.641959395323,549,,Sode,Sode,31,Haute-Garonne,76,Occitanie
31550,SOUEICH,31160,SOUEICH,,43.0509238346,0.782447684283,550,,Soueich,Soueich,31,Haute-Garonne,76,Occitanie
31551,TARABEL,31570,TARABEL,,43.512330223,1.67558246576,551,,Tarabel,Tarabel,31,Haute-Garonne,76,Occitanie
31552,TERREBASSE,31420,TERREBASSE,,43.2393487064,0.972479677167,552,,Terrebasse,Terrebasse,31,Haute-Garonne,76,Occitanie
31553,THIL,31530,THIL,,43.710151594,1.15302056631,553,,Thil,Thil,31,Haute-Garonne,76,Occitanie
31554,TOUILLE,31260,TOUILLE,,43.0835933902,0.981777860359,554,,Touille,Touille,31,Haute-Garonne,76,Occitanie
31555,TOULOUSE,31000,TOULOUSE,,43.5963814303,1.43167293364,555,,Toulouse,Toulouse,31,Haute-Garonne,76,Occitanie
31555,TOULOUSE,31100,TOULOUSE,,43.5963814303,1.43167293364,555,,Toulouse,Toulouse,31,Haute-Garonne,76,Occitanie
31555,TOULOUSE,31200,TOULOUSE,,43.5963814303,1.43167293364,555,,Toulouse,Toulouse,31,Haute-Garonne,76,Occitanie
31555,TOULOUSE,31300,TOULOUSE,,43.5963814303,1.43167293364,555,,Toulouse,Toulouse,31,Haute-Garonne,76,Occitanie
31555,TOULOUSE,31400,TOULOUSE,,43.5963814303,1.43167293364,555,,Toulouse,Toulouse,31,Haute-Garonne,76,Occitanie
31555,TOULOUSE,31500,TOULOUSE,,43.5963814303,1.43167293364,555,,Toulouse,Toulouse,31,Haute-Garonne,76,Occitanie
31556,LES TOURREILLES,31210,LES TOURREILLES,,43.1120356478,0.549865240853,556,Les,Tourreilles,Les Tourreilles,31,Haute-Garonne,76,Occitanie
31557,TOURNEFEUILLE,31170,TOURNEFEUILLE,,43.5781918597,1.33500697752,557,,Tournefeuille,Tournefeuille,31,Haute-Garonne,76,Occitanie
31558,TOUTENS,31460,TOUTENS,,43.4752896942,1.74625050707,558,,Toutens,Toutens,31,Haute-Garonne,76,Occitanie
31559,TREBONS DE LUCHON,31110,TREBONS DE LUCHON,,42.8038266545,0.568978868015,559,,Trébons-de-Luchon,Trébons-de-Luchon,31,Haute-Garonne,76,Occitanie
31560,TREBONS SUR LA GRASSE,31290,TREBONS SUR LA GRASSE,,43.4488110268,1.72135340112,560,,Trébons-sur-la-Grasse,Trébons-sur-la-Grasse,31,Haute-Garonne,76,Occitanie
31561,L UNION,31240,L UNION,,43.6542470428,1.48394847329,561,L',Union,L'Union,31,Haute-Garonne,76,Occitanie
31562,URAU,31260,URAU,,43.0005006675,0.955560343394,562,,Urau,Urau,31,Haute-Garonne,76,Occitanie
31563,VACQUIERS,31340,VACQUIERS,,43.7908380932,1.48368573442,563,,Vacquiers,Vacquiers,31,Haute-Garonne,76,Occitanie
31564,VALCABRERE,31510,VALCABRERE,,43.0300305757,0.586106281253,564,,Valcabrère,Valcabrère,31,Haute-Garonne,76,Occitanie
31565,VALENTINE,31800,VALENTINE,,43.0883480821,0.701390343132,565,,Valentine,Valentine,31,Haute-Garonne,76,Occitanie
31566,VALLEGUE,31290,VALLEGUE,,43.4256721332,1.75444745111,566,,Vallègue,Vallègue,31,Haute-Garonne,76,Occitanie
31567,VALLESVILLES,31570,VALLESVILLES,,43.5947686475,1.64987135863,567,,Vallesvilles,Vallesvilles,31,Haute-Garonne,76,Occitanie
31568,VARENNES,31450,VARENNES,,43.4764363476,1.69248446049,568,,Varennes,Varennes,31,Haute-Garonne,76,Occitanie
31569,VAUDREUILLE,31250,VAUDREUILLE,,43.4249087979,2.00114634186,569,,Vaudreuille,Vaudreuille,31,Haute-Garonne,76,Occitanie
31570,VAUX,31540,VAUX,,43.460564024,1.82846809824,570,,Vaux,Vaux,31,Haute-Garonne,76,Occitanie
31571,VENDINE,31460,VENDINE,,43.5907350293,1.7654488225,571,,Vendine,Vendine,31,Haute-Garonne,76,Occitanie
31572,VENERQUE,31810,VENERQUE,,43.4337432193,1.4666400302,572,,Venerque,Venerque,31,Haute-Garonne,76,Occitanie
31573,VERFEIL,31590,VERFEIL,,43.6637744377,1.67738560148,573,,Verfeil,Verfeil,31,Haute-Garonne,76,Occitanie
31574,VERNET,31810,VERNET,,43.4279795368,1.42137431875,574,,Vernet,Vernet,31,Haute-Garonne,76,Occitanie
31575,VIEILLE TOULOUSE,31320,VIEILLE TOULOUSE,,43.5275480052,1.43833639289,575,,Vieille-Toulouse,Vieille-Toulouse,31,Haute-Garonne,76,Occitanie
31576,VIEILLEVIGNE,31290,VIEILLEVIGNE,,43.407251234,1.6591257471,576,,Vieillevigne,Vieillevigne,31,Haute-Garonne,76,Occitanie
31577,VIGNAUX,31480,VIGNAUX,,43.6872018881,1.06696295842,577,,Vignaux,Vignaux,31,Haute-Garonne,76,Occitanie
31578,VIGOULET AUZIL,31320,VIGOULET AUZIL,,43.5076843146,1.44766808387,578,,Vigoulet-Auzil,Vigoulet-Auzil,31,Haute-Garonne,76,Occitanie
31579,VILLARIES,31380,VILLARIES,,43.7508797408,1.49097697457,579,,Villariès,Villariès,31,Haute-Garonne,76,Occitanie
31580,VILLATE,31860,VILLATE,,43.46763006,1.38192246692,580,,Villate,Villate,31,Haute-Garonne,76,Occitanie
31581,VILLAUDRIC,31620,VILLAUDRIC,,43.831729686,1.43579382014,581,,Villaudric,Villaudric,31,Haute-Garonne,76,Occitanie
31582,VILLEFRANCHE DE LAURAGAIS,31290,VILLEFRANCHE DE LAURAGAIS,,43.4067792695,1.71965126707,582,,Villefranche-de-Lauragais,Villefranche-de-Lauragais,31,Haute-Garonne,76,Occitanie
31583,VILLEMATIER,31340,VILLEMATIER,,43.8287331466,1.49684943742,583,,Villematier,Villematier,31,Haute-Garonne,76,Occitanie
31584,VILLEMUR SUR TARN,31340,VILLEMUR SUR TARN,,43.8644095962,1.49043812706,584,,Villemur-sur-Tarn,Villemur-sur-Tarn,31,Haute-Garonne,76,Occitanie
31585,VILLENEUVE DE RIVIERE,31800,VILLENEUVE DE RIVIERE,,43.1223437031,0.670046646703,585,,Villeneuve-de-Rivière,Villeneuve-de-Rivière,31,Haute-Garonne,76,Occitanie
31586,VILLENEUVE LECUSSAN,31580,VILLENEUVE LECUSSAN,,43.1343250681,0.485143916946,586,,Villeneuve-Lécussan,Villeneuve-Lécussan,31,Haute-Garonne,76,Occitanie
31587,VILLENEUVE LES BOULOC,31620,VILLENEUVE LES BOULOC,,43.7737816543,1.4238362081,587,,Villeneuve-lès-Bouloc,Villeneuve-lès-Bouloc,31,Haute-Garonne,76,Occitanie
31588,VILLENEUVE TOLOSANE,31270,VILLENEUVE TOLOSANE,,43.5266707182,1.34648807644,588,,Villeneuve-Tolosane,Villeneuve-Tolosane,31,Haute-Garonne,76,Occitanie
31589,VILLENOUVELLE,31290,VILLENOUVELLE,,43.4379199394,1.66303514968,589,,Villenouvelle,Villenouvelle,31,Haute-Garonne,76,Occitanie
31590,BINOS,31440,BINOS,,42.9047176947,0.608457875606,590,,Binos,Binos,31,Haute-Garonne,76,Occitanie
31591,ESCOULIS,31260,ESCOULIS,,43.1114465225,1.03132758116,591,,Escoulis,Escoulis,31,Haute-Garonne,76,Occitanie
31592,LARRA,31330,LARRA,,43.7340725626,1.22427334755,592,,Larra,Larra,31,Haute-Garonne,76,Occitanie
31593,CAZAC,31230,CAZAC,,43.347568969,0.952593670357,593,,Cazac,Cazac,31,Haute-Garonne,76,Occitanie
32001,AIGNAN,32290,AIGNAN,,43.6944329355,0.0870356646596,1,,Aignan,Aignan,32,Gers,76,Occitanie
32002,ANSAN,32270,ANSAN,,43.6870501224,0.781408599383,2,,Ansan,Ansan,32,Gers,76,Occitanie
32003,ANTRAS,32360,ANTRAS,,43.7297815118,0.444047087158,3,,Antras,Antras,32,Gers,76,Occitanie
32004,ARBLADE LE BAS,32720,ARBLADE LE BAS,,43.705221087,-0.17265437468,4,,Arblade-le-Bas,Arblade-le-Bas,32,Gers,76,Occitanie
32005,ARBLADE LE HAUT,32110,ARBLADE LE HAUT,,43.7407745105,-0.061788186637,5,,Arblade-le-Haut,Arblade-le-Haut,32,Gers,76,Occitanie
32007,ARDIZAS,32430,ARDIZAS,,43.7236838876,0.998970035909,7,,Ardizas,Ardizas,32,Gers,76,Occitanie
32008,ARMENTIEUX,32230,ARMENTIEUX,,43.5222602975,0.0972571471232,8,,Armentieux,Armentieux,32,Gers,76,Occitanie
32009,ARMOUS ET CAU,32230,ARMOUS ET CAU,,43.5727169655,0.187336904275,9,,Armous-et-Cau,Armous-et-Cau,32,Gers,76,Occitanie
32010,ARROUEDE,32140,ARROUEDE,,43.3599961769,0.583155910425,10,,Arrouède,Arrouède,32,Gers,76,Occitanie
32012,AUBIET,32270,AUBIET,,43.6535011222,0.784637869521,12,,Aubiet,Aubiet,32,Gers,76,Occitanie
32013,AUCH,32000,AUCH,,43.6534300414,0.575190250459,13,,Auch,Auch,32,Gers,76,Occitanie
32014,AUGNAX,32120,AUGNAX,,43.7236889467,0.773788884179,14,,Augnax,Augnax,32,Gers,76,Occitanie
32015,AUJAN MOURNEDE,32300,AUJAN MOURNEDE,,43.3758521622,0.501831576491,15,,Aujan-Mournède,Aujan-Mournède,32,Gers,76,Occitanie
32016,AURADE,32600,AURADE,,43.5585584214,1.07381651463,16,,Auradé,Auradé,32,Gers,76,Occitanie
32017,AURENSAN,32400,AURENSAN,,43.6203342241,-0.201948167555,17,,Aurensan,Aurensan,32,Gers,76,Occitanie
32018,AURIMONT,32450,AURIMONT,,43.5649829798,0.828905981101,18,,Aurimont,Aurimont,32,Gers,76,Occitanie
32019,AUTERIVE,32550,AUTERIVE,,43.5796421888,0.632117026282,19,,Auterive,Auterive,32,Gers,76,Occitanie
32020,AUX AUSSAT,32170,AUX AUSSAT,,43.4412432224,0.26287182015,20,,Aux-Aussat,Aux-Aussat,32,Gers,76,Occitanie
32021,AVENSAC,32120,AVENSAC,,43.8307167324,0.901736113802,21,,Avensac,Avensac,32,Gers,76,Occitanie
32022,AVERON BERGELLE,32290,AVERON BERGELLE,,43.7562294926,0.0657583607792,22,,Avéron-Bergelle,Avéron-Bergelle,32,Gers,76,Occitanie
32023,AVEZAN,32380,AVEZAN,,43.8832251075,0.801715325498,23,,Avezan,Avezan,32,Gers,76,Occitanie
32024,AYGUETINTE,32410,AYGUETINTE,,43.8324278378,0.418923178256,24,,Ayguetinte,Ayguetinte,32,Gers,76,Occitanie
32025,AYZIEU,32800,AYZIEU,,43.8583294171,-0.0256162050251,25,,Ayzieu,Ayzieu,32,Gers,76,Occitanie
32026,BAJONNETTE,32120,BAJONNETTE,,43.8100195778,0.766169172651,26,,Bajonnette,Bajonnette,32,Gers,76,Occitanie
32027,BARCELONNE DU GERS,32720,BARCELONNE DU GERS,,43.69328997,-0.212928744956,27,,Barcelonne-du-Gers,Barcelonne-du-Gers,32,Gers,76,Occitanie
32028,BARCUGNAN,32170,BARCUGNAN,,43.3739824992,0.398211268041,28,,Barcugnan,Barcugnan,32,Gers,76,Occitanie
32029,BARRAN,32350,BARRAN,,43.6220194252,0.448452323881,29,,Barran,Barran,32,Gers,76,Occitanie
32030,BARS,32300,BARS,,43.5112792278,0.301731537971,30,,Bars,Bars,32,Gers,76,Occitanie
32031,BASCOUS,32190,BASCOUS,,43.7945341833,0.136250344256,31,,Bascous,Bascous,32,Gers,76,Occitanie
32032,BASSOUES,32320,BASSOUES,,43.5746981282,0.250833714545,32,,Bassoues,Bassoues,32,Gers,76,Occitanie
32033,BAZIAN,32320,BAZIAN,,43.6685826026,0.317017713446,33,,Bazian,Bazian,32,Gers,76,Occitanie
32034,BAZUGUES,32170,BAZUGUES,,43.4509092165,0.34216662442,34,,Bazugues,Bazugues,32,Gers,76,Occitanie
32035,BEAUCAIRE,32410,BEAUCAIRE,,43.8370869594,0.375982093244,35,,Beaucaire,Beaucaire,32,Gers,76,Occitanie
32036,BEAUMARCHES,32160,BEAUMARCHES,,43.5864405522,0.106916356793,36,,Beaumarchés,Beaumarchés,32,Gers,76,Occitanie
32037,BEAUMONT,32100,BEAUMONT,,43.9377749989,0.290830760764,37,,Beaumont,Beaumont,32,Gers,76,Occitanie
32038,BEAUPUY,32600,BEAUPUY,,43.6453442033,1.01319503145,38,,Beaupuy,Beaupuy,32,Gers,76,Occitanie
32039,BECCAS,32730,BECCAS,,43.4328989193,0.15708543205,39,,Beccas,Beccas,32,Gers,76,Occitanie
32040,BEDECHAN,32450,BEDECHAN,,43.5735987591,0.792312827863,40,,Bédéchan,Bédéchan,32,Gers,76,Occitanie
32041,BELLEGARDE,32140,BELLEGARDE,,43.4269943275,0.632110124614,41,,Bellegarde,Bellegarde,32,Gers,76,Occitanie
32042,BELLOC ST CLAMENS,32300,BELLOC ST CLAMENS,,43.4569328563,0.436935279711,42,,Belloc-Saint-Clamens,Belloc-Saint-Clamens,32,Gers,76,Occitanie
32043,BELMONT,32190,BELMONT,,43.6885351272,0.247544246652,43,,Belmont,Belmont,32,Gers,76,Occitanie
32044,BERAUT,32100,BERAUT,,43.924159928,0.407251427599,44,,Béraut,Béraut,32,Gers,76,Occitanie
32045,BERDOUES,32300,BERDOUES,,43.4719860449,0.399739334252,45,,Berdoues,Berdoues,32,Gers,76,Occitanie
32046,BERNEDE,32400,BERNEDE,,43.6704966634,-0.220993320987,46,,Bernède,Bernède,32,Gers,76,Occitanie
32047,BERRAC,32480,BERRAC,,44.0109156469,0.549268735595,47,,Berrac,Berrac,32,Gers,76,Occitanie
32048,BETCAVE AGUIN,32420,BETCAVE AGUIN,,43.4451021606,0.683256837932,48,,Betcave-Aguin,Betcave-Aguin,32,Gers,76,Occitanie
32049,BETOUS,32110,BETOUS,,43.7179595422,0.0247471680215,49,,Bétous,Bétous,32,Gers,76,Occitanie
32050,BETPLAN,32730,BETPLAN,,43.4186571432,0.209559608431,50,,Betplan,Betplan,32,Gers,76,Occitanie
32051,BEZERIL,32130,BEZERIL,,43.5354869926,0.880550052099,51,,Bézéril,Bézéril,32,Gers,76,Occitanie
32052,BEZOLLES,32310,BEZOLLES,,43.8198696672,0.349413989939,52,,Bezolles,Bezolles,32,Gers,76,Occitanie
32053,BEZUES BAJON,32140,BEZUES BAJON,,43.3875155466,0.60816838305,53,,Bézues-Bajon,Bézues-Bajon,32,Gers,76,Occitanie
32054,BIRAN,32350,BIRAN,,43.6946727542,0.404239086653,54,,Biran,Biran,32,Gers,76,Occitanie
32055,BIVES,32380,BIVES,,43.8298621138,0.805981896154,55,,Bivès,Bivès,32,Gers,76,Occitanie
32056,BLANQUEFORT,32270,BLANQUEFORT,,43.6742793399,0.807524720457,56,,Blanquefort,Blanquefort,32,Gers,76,Occitanie
32057,BLAZIERT,32100,BLAZIERT,,43.9259342148,0.484820002979,57,,Blaziert,Blaziert,32,Gers,76,Occitanie
32058,BLOUSSON SERIAN,32230,BLOUSSON SERIAN,,43.4589317214,0.193609886231,58,,Blousson-Sérian,Blousson-Sérian,32,Gers,76,Occitanie
32059,BONAS,32410,BONAS,,43.7790627499,0.405172476722,59,,Bonas,Bonas,32,Gers,76,Occitanie
32060,BOUCAGNERES,32550,BOUCAGNERES,,43.5618344681,0.620002433688,60,,Boucagnères,Boucagnères,32,Gers,76,Occitanie
32061,BOULAUR,32450,BOULAUR,,43.550997171,0.765003194409,61,,Boulaur,Boulaur,32,Gers,76,Occitanie
32062,BOURROUILLAN,32370,BOURROUILLAN,,43.8280033037,-0.00793722543785,62,,Bourrouillan,Bourrouillan,32,Gers,76,Occitanie
32063,BOUZON GELLENAVE,32290,BOUZON GELLENAVE,,43.68786977,0.0280695424113,63,,Bouzon-Gellenave,Bouzon-Gellenave,32,Gers,76,Occitanie
32064,BRETAGNE D ARMAGNAC,32800,BRETAGNE D ARMAGNAC,,43.8896729368,0.135584671666,64,,Bretagne-d'Armagnac,Bretagne-d'Armagnac,32,Gers,76,Occitanie
32065,LE BROUILH MONBERT,32350,LE BROUILH MONBERT,,43.6604118625,0.395065055584,65,Le,Brouilh-Monbert,Le Brouilh-Monbert,32,Gers,76,Occitanie
32065,LE BROUILH MONBERT,32350,LE BROUILH MONBERT,MONBERT,43.6604118625,0.395065055584,65,Le,Brouilh-Monbert,Le Brouilh-Monbert,32,Gers,76,Occitanie
32066,BRUGNENS,32500,BRUGNENS,,43.8417873872,0.722552080652,66,,Brugnens,Brugnens,32,Gers,76,Occitanie
32067,CABAS LOUMASSES,32140,CABAS LOUMASSES,,43.3566837284,0.61023663197,67,,Cabas-Loumassès,Cabas-Loumassès,32,Gers,76,Occitanie
32068,CADEILHAN,32380,CADEILHAN,,43.8300563676,0.768446720469,68,,Cadeilhan,Cadeilhan,32,Gers,76,Occitanie
32069,CADEILLAN,32220,CADEILLAN,,43.420255903,0.841855457027,69,,Cadeillan,Cadeillan,32,Gers,76,Occitanie
32070,CAHUZAC SUR ADOUR,32400,CAHUZAC SUR ADOUR,,43.6366732371,-0.0272638825571,70,,Cahuzac-sur-Adour,Cahuzac-sur-Adour,32,Gers,76,Occitanie
32071,CAILLAVET,32190,CAILLAVET,,43.7058359941,0.338140296149,71,,Caillavet,Caillavet,32,Gers,76,Occitanie
32072,CALLIAN,32190,CALLIAN,,43.6320693939,0.264916820315,72,,Callian,Callian,32,Gers,76,Occitanie
32073,CAMPAGNE D ARMAGNAC,32800,CAMPAGNE D ARMAGNAC,,43.8609046088,0.00746700396669,73,,Campagne-d'Armagnac,Campagne-d'Armagnac,32,Gers,76,Occitanie
32074,CANNET,32400,CANNET,,43.6145950269,-0.0561794206259,74,,Cannet,Cannet,32,Gers,76,Occitanie
32075,CASSAIGNE,32100,CASSAIGNE,,43.9038282456,0.344845164454,75,,Cassaigne,Cassaigne,32,Gers,76,Occitanie
32076,CASTELNAU BARBARENS,32450,CASTELNAU BARBARENS,,43.5810895622,0.724408794123,76,,Castelnau-Barbarens,Castelnau-Barbarens,32,Gers,76,Occitanie
32077,CASTELNAU D ANGLES,32320,CASTELNAU D ANGLES,,43.6164688819,0.295821312988,77,,Castelnau-d'Anglès,Castelnau-d'Anglès,32,Gers,76,Occitanie
32078,CASTELNAU D ARBIEU,32500,CASTELNAU D ARBIEU,,43.8845411475,0.690784048541,78,,Castelnau-d'Arbieu,Castelnau-d'Arbieu,32,Gers,76,Occitanie
32079,CASTELNAU D AUZAN LABARRERE,32250,CASTELNAU D AUZAN LABARRERE,LABARRERE,43.9434923568,0.10186302495,79,,Castelnau d'Auzan Labarrère,Castelnau d'Auzan Labarrère,32,Gers,76,Occitanie
32079,CASTELNAU D AUZAN LABARRERE,32440,CASTELNAU D AUZAN LABARRERE,,43.9434923568,0.10186302495,79,,Castelnau d'Auzan Labarrère,Castelnau d'Auzan Labarrère,32,Gers,76,Occitanie
32080,CASTELNAU SUR L AUVIGNON,32100,CASTELNAU SUR L AUVIGNON,,43.9635207971,0.471654890782,80,,Castelnau-sur-l'Auvignon,Castelnau-sur-l'Auvignon,32,Gers,76,Occitanie
32081,CASTELNAVET,32290,CASTELNAVET,,43.6733145653,0.137370546736,81,,Castelnavet,Castelnavet,32,Gers,76,Occitanie
32082,CASTERA LECTOUROIS,32700,CASTERA LECTOUROIS,,43.9893066009,0.611642801216,82,,Castéra-Lectourois,Castéra-Lectourois,32,Gers,76,Occitanie
32083,CASTERA VERDUZAN,32410,CASTERA VERDUZAN,,43.8015571753,0.433315812149,83,,Castéra-Verduzan,Castéra-Verduzan,32,Gers,76,Occitanie
32084,CASTERON,32380,CASTERON,,43.9028241046,0.868621810839,84,,Castéron,Castéron,32,Gers,76,Occitanie
32085,CASTET ARROUY,32340,CASTET ARROUY,,43.9816866375,0.722854688091,85,,Castet-Arrouy,Castet-Arrouy,32,Gers,76,Occitanie
32086,CASTEX,32170,CASTEX,,43.3818340108,0.312547597054,86,,Castex,Castex,32,Gers,76,Occitanie
32087,CASTEX D ARMAGNAC,32240,CASTEX D ARMAGNAC,,43.8687260884,-0.165862662186,87,,Castex-d'Armagnac,Castex-d'Armagnac,32,Gers,76,Occitanie
32088,CASTILLON DEBATS,32190,CASTILLON DEBATS,,43.7311656777,0.219388894885,88,,Castillon-Debats,Castillon-Debats,32,Gers,76,Occitanie
32089,CASTILLON MASSAS,32360,CASTILLON MASSAS,,43.7164195467,0.541095638869,89,,Castillon-Massas,Castillon-Massas,32,Gers,76,Occitanie
32090,CASTILLON SAVES,32490,CASTILLON SAVES,,43.5641317623,0.983556394242,90,,Castillon-Savès,Castillon-Savès,32,Gers,76,Occitanie
32091,CASTIN,32810,CASTIN,,43.6939093096,0.536074019459,91,,Castin,Castin,32,Gers,76,Occitanie
32092,CATONVIELLE,32200,CATONVIELLE,,43.6560806019,0.962548344082,92,,Catonvielle,Catonvielle,32,Gers,76,Occitanie
32093,CAUMONT,32400,CAUMONT,,43.6914735474,-0.108660908583,93,,Caumont,Caumont,32,Gers,76,Occitanie
32094,CAUPENNE D ARMAGNAC,32110,CAUPENNE D ARMAGNAC,,43.7842826352,-0.0711396072349,94,,Caupenne-d'Armagnac,Caupenne-d'Armagnac,32,Gers,76,Occitanie
32095,CAUSSENS,32100,CAUSSENS,,43.9471704365,0.441602960063,95,,Caussens,Caussens,32,Gers,76,Occitanie
32096,CAZAUBON,32150,CAZAUBON,,43.9251854258,-0.028737932075,96,,Cazaubon,Cazaubon,32,Gers,76,Occitanie
32096,CAZAUBON,32150,CAZAUBON,BARBOTAN LES THERMES,43.9251854258,-0.028737932075,96,,Cazaubon,Cazaubon,32,Gers,76,Occitanie
32097,CAZAUX D ANGLES,32190,CAZAUX D ANGLES,,43.6564434737,0.265936475433,97,,Cazaux-d'Anglès,Cazaux-d'Anglès,32,Gers,76,Occitanie
32098,CAZAUX SAVES,32130,CAZAUX SAVES,,43.5452047263,0.978268681087,98,,Cazaux-Savès,Cazaux-Savès,32,Gers,76,Occitanie
32099,CAZAUX VILLECOMTAL,32230,CAZAUX VILLECOMTAL,,43.4477367972,0.174336175469,99,,Cazaux-Villecomtal,Cazaux-Villecomtal,32,Gers,76,Occitanie
32100,CAZENEUVE,32800,CAZENEUVE,,43.8897347457,0.163914639045,100,,Cazeneuve,Cazeneuve,32,Gers,76,Occitanie
32101,CERAN,32500,CERAN,,43.8154019755,0.698929197336,101,,Céran,Céran,32,Gers,76,Occitanie
32102,CEZAN,32410,CEZAN,,43.803230782,0.49733255319,102,,Cézan,Cézan,32,Gers,76,Occitanie
32103,CHELAN,32140,CHELAN,,43.3513221997,0.545230571621,103,,Chélan,Chélan,32,Gers,76,Occitanie
32104,CLERMONT POUYGUILLES,32300,CLERMONT POUYGUILLES,,43.4769070936,0.524132562872,104,,Clermont-Pouyguillès,Clermont-Pouyguillès,32,Gers,76,Occitanie
32105,CLERMONT SAVES,32600,CLERMONT SAVES,,43.6225633147,1.01355219293,105,,Clermont-Savès,Clermont-Savès,32,Gers,76,Occitanie
32106,COLOGNE,32430,COLOGNE,,43.7176064858,0.975053061253,106,,Cologne,Cologne,32,Gers,76,Occitanie
32107,CONDOM,32100,CONDOM,,43.9716968333,0.374186827134,107,,Condom,Condom,32,Gers,76,Occitanie
32108,CORNEILLAN,32400,CORNEILLAN,,43.6540927627,-0.18506977354,108,,Corneillan,Corneillan,32,Gers,76,Occitanie
32109,COULOUME MONDEBAT,32160,COULOUME MONDEBAT,,43.6352147895,0.114016958736,109,,Couloumé-Mondebat,Couloumé-Mondebat,32,Gers,76,Occitanie
32110,COURRENSAN,32330,COURRENSAN,,43.842950369,0.241076890642,110,,Courrensan,Courrensan,32,Gers,76,Occitanie
32111,COURTIES,32230,COURTIES,,43.5734871735,0.153733826312,111,,Courties,Courties,32,Gers,76,Occitanie
32112,CRASTES,32270,CRASTES,,43.7156978127,0.73114243925,112,,Crastes,Crastes,32,Gers,76,Occitanie
32113,CRAVENCERES,32110,CRAVENCERES,,43.7740057628,0.0298979307327,113,,Cravencères,Cravencères,32,Gers,76,Occitanie
32114,CUELAS,32300,CUELAS,,43.352415623,0.451048381547,114,,Cuélas,Cuélas,32,Gers,76,Occitanie
32115,DEMU,32190,DEMU,,43.7618537122,0.166713123878,115,,Dému,Dému,32,Gers,76,Occitanie
32116,DUFFORT,32170,DUFFORT,,43.3407927968,0.419689843256,116,,Duffort,Duffort,32,Gers,76,Occitanie
32117,DURAN,32810,DURAN,,43.672773032,0.561778895339,117,,Duran,Duran,32,Gers,76,Occitanie
32118,DURBAN,32260,DURBAN,,43.5390207852,0.5656981485,118,,Durban,Durban,32,Gers,76,Occitanie
32119,EAUZE,32800,EAUZE,,43.8607370613,0.105730356401,119,,Eauze,Eauze,32,Gers,76,Occitanie
32120,ENCAUSSE,32430,ENCAUSSE,,43.6939626991,1.03183507022,120,,Encausse,Encausse,32,Gers,76,Occitanie
32121,ENDOUFIELLE,32600,ENDOUFIELLE,,43.5444326144,1.02189690192,121,,Endoufielle,Endoufielle,32,Gers,76,Occitanie
32122,ESCLASSAN LABASTIDE,32140,ESCLASSAN LABASTIDE,,43.4161811903,0.552126229054,122,,Esclassan-Labastide,Esclassan-Labastide,32,Gers,76,Occitanie
32123,ESCORNEBOEUF,32200,ESCORNEBOEUF,,43.6455146384,0.911624650166,123,,Escornebœuf,Escornebœuf,32,Gers,76,Occitanie
32124,ESPAON,32220,ESPAON,,43.4291996718,0.870269920288,124,,Espaon,Espaon,32,Gers,76,Occitanie
32125,ESPAS,32370,ESPAS,,43.7784871018,0.0948196132159,125,,Espas,Espas,32,Gers,76,Occitanie
32126,ESTAMPES,32170,ESTAMPES,,43.4000716768,0.278946241773,126,,Estampes,Estampes,32,Gers,76,Occitanie
32127,ESTANG,32240,ESTANG,,43.8724476827,-0.104454073266,127,,Estang,Estang,32,Gers,76,Occitanie
32128,ESTIPOUY,32300,ESTIPOUY,,43.5489973187,0.387102294766,128,,Estipouy,Estipouy,32,Gers,76,Occitanie
32129,ESTRAMIAC,32380,ESTRAMIAC,,43.8350883642,0.849647373982,129,,Estramiac,Estramiac,32,Gers,76,Occitanie
32130,FAGET ABBATIAL,32450,FAGET ABBATIAL,,43.5060870997,0.690621166698,130,,Faget-Abbatial,Faget-Abbatial,32,Gers,76,Occitanie
32131,FLAMARENS,32340,FLAMARENS,,44.0211871961,0.792404807363,131,,Flamarens,Flamarens,32,Gers,76,Occitanie
32132,FLEURANCE,32500,FLEURANCE,,43.8402794076,0.64245586546,132,,Fleurance,Fleurance,32,Gers,76,Occitanie
32133,FOURCES,32250,FOURCES,,43.9951905461,0.228969106645,133,,Fourcès,Fourcès,32,Gers,76,Occitanie
32134,FREGOUVILLE,32490,FREGOUVILLE,,43.57691237,0.95458639452,134,,Frégouville,Frégouville,32,Gers,76,Occitanie
32135,FUSTEROUAU,32400,FUSTEROUAU,,43.6858644599,0.00594059782735,135,,Fustérouau,Fustérouau,32,Gers,76,Occitanie
32136,GALIAX,32160,GALIAX,,43.6125458385,0.0140366364635,136,,Galiax,Galiax,32,Gers,76,Occitanie
32138,GARRAVET,32220,GARRAVET,,43.4180047939,0.907154725619,138,,Garravet,Garravet,32,Gers,76,Occitanie
32139,GAUDONVILLE,32380,GAUDONVILLE,,43.8852287116,0.8453478098,139,,Gaudonville,Gaudonville,32,Gers,76,Occitanie
32140,GAUJAC,32220,GAUJAC,,43.4746394425,0.825606572307,140,,Gaujac,Gaujac,32,Gers,76,Occitanie
32141,GAUJAN,32420,GAUJAN,,43.4017018415,0.727114709215,141,,Gaujan,Gaujan,32,Gers,76,Occitanie
32142,GAVARRET SUR AULOUSTE,32390,GAVARRET SUR AULOUSTE,,43.7768059655,0.659322996742,142,,Gavarret-sur-Aulouste,Gavarret-sur-Aulouste,32,Gers,76,Occitanie
32143,GAZAUPOUY,32480,GAZAUPOUY,,44.0130398922,0.463572395827,143,,Gazaupouy,Gazaupouy,32,Gers,76,Occitanie
32144,GAZAX ET BACCARISSE,32230,GAZAX ET BACCARISSE,,43.6018826171,0.196554642255,144,,Gazax-et-Baccarisse,Gazax-et-Baccarisse,32,Gers,76,Occitanie
32145,GEE RIVIERE,32720,GEE RIVIERE,,43.6757883164,-0.186939058119,145,,Gée-Rivière,Gée-Rivière,32,Gers,76,Occitanie
32146,GIMBREDE,32340,GIMBREDE,,44.0374266467,0.719582060936,146,,Gimbrède,Gimbrède,32,Gers,76,Occitanie
32147,GIMONT,32200,GIMONT,,43.6206403859,0.880976872847,147,,Gimont,Gimont,32,Gers,76,Occitanie
32148,GISCARO,32200,GISCARO,,43.6065164877,0.93402419559,148,,Giscaro,Giscaro,32,Gers,76,Occitanie
32149,GONDRIN,32330,GONDRIN,,43.8786441863,0.260411080095,149,,Gondrin,Gondrin,32,Gers,76,Occitanie
32150,GOUTZ,32500,GOUTZ,,43.8098232902,0.732682485482,150,,Goutz,Goutz,32,Gers,76,Occitanie
32151,GOUX,32400,GOUX,,43.6167487404,-0.022105593973,151,,Goux,Goux,32,Gers,76,Occitanie
32152,HAGET,32730,HAGET,,43.4164577961,0.161717343606,152,,Haget,Haget,32,Gers,76,Occitanie
32153,HAULIES,32550,HAULIES,,43.5560180243,0.667262496662,153,,Haulies,Haulies,32,Gers,76,Occitanie
32154,HOMPS,32120,HOMPS,,43.8124316076,0.850387022005,154,,Homps,Homps,32,Gers,76,Occitanie
32155,LE HOUGA,32460,LE HOUGA,,43.7695328695,-0.185293783845,155,Le,Houga,Le Houga,32,Gers,76,Occitanie
32156,IDRAC RESPAILLES,32300,IDRAC RESPAILLES,,43.5147795841,0.463769960851,156,,Idrac-Respaillès,Idrac-Respaillès,32,Gers,76,Occitanie
32157,L ISLE ARNE,32270,L ISLE ARNE,,43.615929649,0.784325448468,157,L',Isle-Arné,L'Isle-Arné,32,Gers,76,Occitanie
32158,L ISLE BOUZON,32380,L ISLE BOUZON,,43.9244519386,0.735445599943,158,L',Isle-Bouzon,L'Isle-Bouzon,32,Gers,76,Occitanie
32159,L ISLE DE NOE,32300,L ISLE DE NOE,,43.584897204,0.415082105161,159,L',Isle-de-Noé,L'Isle-de-Noé,32,Gers,76,Occitanie
32160,L ISLE JOURDAIN,32600,L ISLE JOURDAIN,,43.6144926041,1.08127836593,160,L',Isle-Jourdain,L'Isle-Jourdain,32,Gers,76,Occitanie
32161,IZOTGES,32400,IZOTGES,,43.6550039282,-0.0184210252942,161,,Izotges,Izotges,32,Gers,76,Occitanie
32162,JEGUN,32360,JEGUN,,43.7570244971,0.444927159956,162,,Jegun,Jegun,32,Gers,76,Occitanie
32163,JU BELLOC,32160,JU BELLOC,,43.5761092184,0.0137806680297,163,,Jû-Belloc,Jû-Belloc,32,Gers,76,Occitanie
32164,JUILLAC,32230,JUILLAC,,43.542341334,0.12212200093,164,,Juillac,Juillac,32,Gers,76,Occitanie
32165,JUILLES,32200,JUILLES,,43.6069084453,0.82649870993,165,,Juilles,Juilles,32,Gers,76,Occitanie
32166,JUSTIAN,32190,JUSTIAN,,43.8146093181,0.30226086251,166,,Justian,Justian,32,Gers,76,Occitanie
32167,LAAS,32170,LAAS,,43.4720603975,0.301177050148,167,,Laas,Laas,32,Gers,76,Occitanie
32169,LABARTHE,32260,LABARTHE,,43.4711783649,0.577639627041,169,,Labarthe,Labarthe,32,Gers,76,Occitanie
32170,LABARTHETE,32400,LABARTHETE,,43.6283080545,-0.164158807539,170,,Labarthète,Labarthète,32,Gers,76,Occitanie
32171,LABASTIDE SAVES,32130,LABASTIDE SAVES,,43.5219724449,0.977226511284,171,,Labastide-Savès,Labastide-Savès,32,Gers,76,Occitanie
32172,LABEJAN,32300,LABEJAN,,43.5370051411,0.510006056574,172,,Labéjan,Labéjan,32,Gers,76,Occitanie
32173,LABRIHE,32120,LABRIHE,,43.7634344802,0.881329271621,173,,Labrihe,Labrihe,32,Gers,76,Occitanie
32174,LADEVEZE RIVIERE,32230,LADEVEZE RIVIERE,,43.5507415882,0.0840810625549,174,,Ladevèze-Rivière,Ladevèze-Rivière,32,Gers,76,Occitanie
32175,LADEVEZE VILLE,32230,LADEVEZE VILLE,,43.5362096999,0.060096709408,175,,Ladevèze-Ville,Ladevèze-Ville,32,Gers,76,Occitanie
32176,LAGARDE,32700,LAGARDE FIMARCON,,43.9688206645,0.565453209917,176,,Lagarde,Lagarde,32,Gers,76,Occitanie
32177,LAGARDE HACHAN,32300,LAGARDE HACHAN,,43.408755039,0.500171499744,177,,Lagarde-Hachan,Lagarde-Hachan,32,Gers,76,Occitanie
32178,LAGARDERE,32310,LAGARDERE,,43.842189141,0.322509664835,178,,Lagardère,Lagardère,32,Gers,76,Occitanie
32180,LAGRAULET DU GERS,32330,LAGRAULET DU GERS,,43.8903093881,0.200736474088,180,,Lagraulet-du-Gers,Lagraulet-du-Gers,32,Gers,76,Occitanie
32181,LAGUIAN MAZOUS,32170,LAGUIAN MAZOUS,,43.4163946487,0.251429144028,181,,Laguian-Mazous,Laguian-Mazous,32,Gers,76,Occitanie
32182,LAHAS,32130,LAHAS,,43.5626431381,0.89864407712,182,,Lahas,Lahas,32,Gers,76,Occitanie
32183,LAHITTE,32810,LAHITTE,,43.6575045721,0.680276833555,183,,Lahitte,Lahitte,32,Gers,76,Occitanie
32184,LALANNE,32500,LALANNE,,43.8047483333,0.675777091695,184,,Lalanne,Lalanne,32,Gers,76,Occitanie
32185,LALANNE ARQUE,32140,LALANNE ARQUE,,43.3265650182,0.640391489316,185,,Lalanne-Arqué,Lalanne-Arqué,32,Gers,76,Occitanie
32186,LAMAGUERE,32260,LAMAGUERE,,43.4865684671,0.683135998236,186,,Lamaguère,Lamaguère,32,Gers,76,Occitanie
32187,LAMAZERE,32300,LAMAZERE,,43.5614869327,0.452273282674,187,,Lamazère,Lamazère,32,Gers,76,Occitanie
32188,LAMOTHE GOAS,32500,LAMOTHE GOAS,,43.8685216606,0.570087377655,188,,Lamothe-Goas,Lamothe-Goas,32,Gers,76,Occitanie
32189,LANNEMAIGNAN,32240,LANNEMAIGNAN,,43.8989950256,-0.211156147496,189,,Lannemaignan,Lannemaignan,32,Gers,76,Occitanie
32190,LANNEPAX,32190,LANNEPAX,,43.808107132,0.224965235255,190,,Lannepax,Lannepax,32,Gers,76,Occitanie
32191,LANNE SOUBIRAN,32110,LANNE SOUBIRAN,,43.7419885132,-0.102687269527,191,,Lanne-Soubiran,Lanne-Soubiran,32,Gers,76,Occitanie
32192,LANNUX,32400,LANNUX,,43.6452094279,-0.223285876187,192,,Lannux,Lannux,32,Gers,76,Occitanie
32193,LAREE,32150,LAREE,,43.9065293068,-0.0565335680322,193,,Larée,Larée,32,Gers,76,Occitanie
32194,LARRESSINGLE,32100,LARRESSINGLE,,43.9368488739,0.315969643669,194,,Larressingle,Larressingle,32,Gers,76,Occitanie
32195,LARROQUE ENGALIN,32480,LARROQUE ENGALIN,,43.9878674787,0.546160014237,195,,Larroque-Engalin,Larroque-Engalin,32,Gers,76,Occitanie
32196,LARROQUE ST SERNIN,32410,LARROQUE ST SERNIN,,43.8298476261,0.46893020045,196,,Larroque-Saint-Sernin,Larroque-Saint-Sernin,32,Gers,76,Occitanie
32197,LARROQUE SUR L OSSE,32100,LARROQUE SUR L OSSE,,43.9755473747,0.282230658284,197,,Larroque-sur-l'Osse,Larroque-sur-l'Osse,32,Gers,76,Occitanie
32198,LARTIGUE,32450,LARTIGUE,,43.5343473965,0.710396986111,198,,Lartigue,Lartigue,32,Gers,76,Occitanie
32199,LASSERADE,32160,LASSERADE,,43.6298461765,0.0609345558619,199,,Lasserade,Lasserade,32,Gers,76,Occitanie
32200,LASSERAN,32550,LASSERAN,,43.6034958124,0.526620389373,200,,Lasséran,Lasséran,32,Gers,76,Occitanie
32201,LASSEUBE PROPRE,32550,LASSEUBE PROPRE,,43.5727715292,0.575516716894,201,,Lasseube-Propre,Lasseube-Propre,32,Gers,76,Occitanie
32202,LAUJUZAN,32110,LAUJUZAN,,43.8030824255,-0.115343246115,202,,Laujuzan,Laujuzan,32,Gers,76,Occitanie
32203,LAURAET,32330,LAURAET,,43.923512949,0.256724176995,203,,Lauraët,Lauraët,32,Gers,76,Occitanie
32204,LAVARDENS,32360,LAVARDENS,,43.7654411264,0.519747830067,204,,Lavardens,Lavardens,32,Gers,76,Occitanie
32205,LAVERAET,32230,LAVERAET,,43.5270584933,0.213549832473,205,,Laveraët,Laveraët,32,Gers,76,Occitanie
32206,LAYMONT,32220,LAYMONT,,43.4213039567,0.99482495821,206,,Laymont,Laymont,32,Gers,76,Occitanie
32207,LEBOULIN,32810,LEBOULIN,,43.670877929,0.660565721103,207,,Leboulin,Leboulin,32,Gers,76,Occitanie
32208,LECTOURE,32700,LECTOURE,,43.9405030561,0.650762392132,208,,Lectoure,Lectoure,32,Gers,76,Occitanie
32209,LELIN LAPUJOLLE,32400,LELIN LAPUJOLLE,,43.7070511405,-0.134532428412,209,,Lelin-Lapujolle,Lelin-Lapujolle,32,Gers,76,Occitanie
32210,LIAS,32600,LIAS,,43.5677012394,1.13315206231,210,,Lias,Lias,32,Gers,76,Occitanie
32211,LIAS D ARMAGNAC,32240,LIAS D ARMAGNAC,,43.8629702424,-0.0620305105879,211,,Lias-d'Armagnac,Lias-d'Armagnac,32,Gers,76,Occitanie
32212,LIGARDES,32480,LIGARDES,,44.0418693896,0.483572865332,212,,Ligardes,Ligardes,32,Gers,76,Occitanie
32213,LOMBEZ,32220,LOMBEZ,,43.4666661613,0.901915416413,213,,Lombez,Lombez,32,Gers,76,Occitanie
32214,LOUBEDAT,32110,LOUBEDAT,,43.7443131497,0.0317553412763,214,,Loubédat,Loubédat,32,Gers,76,Occitanie
32215,LOUBERSAN,32300,LOUBERSAN,,43.5013299383,0.511821177501,215,,Loubersan,Loubersan,32,Gers,76,Occitanie
32216,LOURTIES MONBRUN,32140,LOURTIES MONBRUN,,43.4572752251,0.546073005059,216,,Lourties-Monbrun,Lourties-Monbrun,32,Gers,76,Occitanie
32217,LOUSLITGES,32230,LOUSLITGES,,43.5994545053,0.156509791866,217,,Louslitges,Louslitges,32,Gers,76,Occitanie
32218,LOUSSOUS DEBAT,32290,LOUSSOUS DEBAT,,43.6567010743,0.0786423810254,218,,Loussous-Débat,Loussous-Débat,32,Gers,76,Occitanie
32219,LUPIAC,32290,LUPIAC,,43.6880530746,0.187356485668,219,,Lupiac,Lupiac,32,Gers,76,Occitanie
32220,LUPPE VIOLLES,32110,LUPPE VIOLLES,,43.7351599052,-0.143601198849,220,,Luppé-Violles,Luppé-Violles,32,Gers,76,Occitanie
32221,LUSSAN,32270,LUSSAN,,43.6197914762,0.739735287633,221,,Lussan,Lussan,32,Gers,76,Occitanie
32222,MAGNAN,32110,MAGNAN,,43.7625963893,-0.116396743253,222,,Magnan,Magnan,32,Gers,76,Occitanie
32223,MAGNAS,32380,MAGNAS,,43.8927817085,0.72358337576,223,,Magnas,Magnas,32,Gers,76,Occitanie
32224,MAIGNAUT TAUZIA,32310,MAIGNAUT TAUZIA,,43.888506896,0.399761933288,224,,Maignaut-Tauzia,Maignaut-Tauzia,32,Gers,76,Occitanie
32225,MALABAT,32730,MALABAT,,43.431964924,0.19281822284,225,,Malabat,Malabat,32,Gers,76,Occitanie
32226,MANAS BASTANOUS,32170,MANAS BASTANOUS,,43.3671107189,0.369648739951,226,,Manas-Bastanous,Manas-Bastanous,32,Gers,76,Occitanie
32227,MANCIET,32370,MANCIET,,43.8178857934,0.0585536753476,227,,Manciet,Manciet,32,Gers,76,Occitanie
32228,MANENT MONTANE,32140,MANENT MONTANE,,43.3340996973,0.604574455016,228,,Manent-Montané,Manent-Montané,32,Gers,76,Occitanie
32229,MANSEMPUY,32120,MANSEMPUY,,43.7436576149,0.811351998027,229,,Mansempuy,Mansempuy,32,Gers,76,Occitanie
32230,MANSENCOME,32310,MANSENCOME,,43.8747066073,0.330922370603,230,,Mansencôme,Mansencôme,32,Gers,76,Occitanie
32231,MARAMBAT,32190,MARAMBAT,,43.7786875916,0.323828466495,231,,Marambat,Marambat,32,Gers,76,Occitanie
32232,MARAVAT,32120,MARAVAT,,43.7604708957,0.765120308835,232,,Maravat,Maravat,32,Gers,76,Occitanie
32233,MARCIAC,32230,MARCIAC,,43.521249291,0.156300856185,233,,Marciac,Marciac,32,Gers,76,Occitanie
32234,MARESTAING,32490,MARESTAING,,43.5842074284,1.01573782647,234,,Marestaing,Marestaing,32,Gers,76,Occitanie
32235,MARGOUET MEYMES,32290,MARGOUET MEYMES,,43.7253897531,0.117825300708,235,,Margouët-Meymes,Margouët-Meymes,32,Gers,76,Occitanie
32236,MARGUESTAU,32150,MARGUESTAU,,43.8815660934,-0.0385530828581,236,,Marguestau,Marguestau,32,Gers,76,Occitanie
32237,MARSAN,32270,MARSAN,,43.6479275697,0.723516841894,237,,Marsan,Marsan,32,Gers,76,Occitanie
32238,MARSEILLAN,32170,MARSEILLAN,,43.4888848477,0.312582151294,238,,Marseillan,Marseillan,32,Gers,76,Occitanie
32239,MARSOLAN,32700,MARSOLAN,,43.9410871869,0.536253009909,239,,Marsolan,Marsolan,32,Gers,76,Occitanie
32240,MASCARAS,32230,MASCARAS,,43.5524108996,0.22300525689,240,,Mascaras,Mascaras,32,Gers,76,Occitanie
32241,MAS D AUVIGNON,32700,MAS D AUVIGNON,,43.8915218627,0.505530221508,241,,Mas-d'Auvignon,Mas-d'Auvignon,32,Gers,76,Occitanie
32242,MASSEUBE,32140,MASSEUBE,,43.4317876537,0.586727332554,242,,Masseube,Masseube,32,Gers,76,Occitanie
32243,MAULEON D ARMAGNAC,32240,MAULEON D ARMAGNAC,,43.9063722257,-0.153746683388,243,,Mauléon-d'Armagnac,Mauléon-d'Armagnac,32,Gers,76,Occitanie
32244,MAULICHERES,32400,MAULICHERES,,43.6863853062,-0.0854584441298,244,,Maulichères,Maulichères,32,Gers,76,Occitanie
32245,MAUMUSSON LAGUIAN,32400,MAUMUSSON LAGUIAN,,43.608957239,-0.0917440819432,245,,Maumusson-Laguian,Maumusson-Laguian,32,Gers,76,Occitanie
32246,MAUPAS,32240,MAUPAS,,43.8431026932,-0.129777631526,246,,Maupas,Maupas,32,Gers,76,Occitanie
32247,MAURENS,32200,MAURENS,,43.5849784687,0.916693670895,247,,Maurens,Maurens,32,Gers,76,Occitanie
32248,MAUROUX,32380,MAUROUX,,43.9070056495,0.824272401269,248,,Mauroux,Mauroux,32,Gers,76,Occitanie
32249,MAUVEZIN,32120,MAUVEZIN,,43.7315774069,0.866244798675,249,,Mauvezin,Mauvezin,32,Gers,76,Occitanie
32250,MEILHAN,32420,MEILHAN,,43.4221007304,0.677329916548,250,,Meilhan,Meilhan,32,Gers,76,Occitanie
32251,MERENS,32360,MERENS,,43.7510788643,0.549608367761,251,,Mérens,Mérens,32,Gers,76,Occitanie
32252,MIELAN,32170,MIELAN,,43.4293162971,0.310399473004,252,,Miélan,Miélan,32,Gers,76,Occitanie
32253,MIRADOUX,32340,MIRADOUX,,43.9947437393,0.758879620932,253,,Miradoux,Miradoux,32,Gers,76,Occitanie
32254,MIRAMONT D ASTARAC,32300,MIRAMONT D ASTARAC,,43.5464182509,0.466668332736,254,,Miramont-d'Astarac,Miramont-d'Astarac,32,Gers,76,Occitanie
32255,MIRAMONT LATOUR,32390,MIRAMONT LATOUR,,43.7757250966,0.687705475366,255,,Miramont-Latour,Miramont-Latour,32,Gers,76,Occitanie
32256,MIRANDE,32300,MIRANDE,,43.5186713962,0.410792361049,256,,Mirande,Mirande,32,Gers,76,Occitanie
32257,MIRANNES,32350,MIRANNES,,43.6243319554,0.38349486791,257,,Mirannes,Mirannes,32,Gers,76,Occitanie
32258,MIREPOIX,32390,MIREPOIX,,43.7442786824,0.670131677714,258,,Mirepoix,Mirepoix,32,Gers,76,Occitanie
32260,MONBARDON,32420,MONBARDON,,43.3806524383,0.716563554574,260,,Monbardon,Monbardon,32,Gers,76,Occitanie
32261,MONBLANC,32130,MONBLANC,,43.4639644757,0.992864484425,261,,Monblanc,Monblanc,32,Gers,76,Occitanie
32262,MONBRUN,32600,MONBRUN,,43.6652583352,1.03071023904,262,,Monbrun,Monbrun,32,Gers,76,Occitanie
32263,MONCASSIN,32300,MONCASSIN,,43.450007144,0.47497863531,263,,Moncassin,Moncassin,32,Gers,76,Occitanie
32264,MONCLAR,32150,MONCLAR D ARMAGNAC,,43.9137796457,-0.100727155987,264,,Monclar,Monclar,32,Gers,76,Occitanie
32265,MONCLAR SUR LOSSE,32300,MONCLAR SUR LOSSE,,43.5233141934,0.341115496141,265,,Monclar-sur-Losse,Monclar-sur-Losse,32,Gers,76,Occitanie
32266,MONCORNEIL GRAZAN,32260,MONCORNEIL GRAZAN,,43.4518395248,0.649860096402,266,,Moncorneil-Grazan,Moncorneil-Grazan,32,Gers,76,Occitanie
32267,MONFERRAN PLAVES,32260,MONFERRAN PLAVES,,43.4947854517,0.640118219989,267,,Monferran-Plavès,Monferran-Plavès,32,Gers,76,Occitanie
32268,MONFERRAN SAVES,32490,MONFERRAN SAVES,,43.6126690872,0.98080946812,268,,Monferran-Savès,Monferran-Savès,32,Gers,76,Occitanie
32269,MONFORT,32120,MONFORT,,43.7914130393,0.824811045204,269,,Monfort,Monfort,32,Gers,76,Occitanie
32270,MONGAUSY,32220,MONGAUSY,,43.5015625507,0.811133329778,270,,Mongausy,Mongausy,32,Gers,76,Occitanie
32271,MONGUILHEM,32240,MONGUILHEM,,43.8535441916,-0.189101888026,271,,Monguilhem,Monguilhem,32,Gers,76,Occitanie
32272,MONLAUR BERNET,32140,MONLAUR BERNET,,43.3481362028,0.512127645248,272,,Monlaur-Bernet,Monlaur-Bernet,32,Gers,76,Occitanie
32273,MONLEZUN,32230,MONLEZUN,,43.49557943,0.21741183427,273,,Monlezun,Monlezun,32,Gers,76,Occitanie
32274,MONLEZUN D ARMAGNAC,32240,MONLEZUN D ARMAGNAC,,43.8235244967,-0.14659664068,274,,Monlezun-d'Armagnac,Monlezun-d'Armagnac,32,Gers,76,Occitanie
32275,MONPARDIAC,32170,MONPARDIAC,,43.4636060332,0.245133551575,275,,Monpardiac,Monpardiac,32,Gers,76,Occitanie
32276,MONTADET,32220,MONTADET,,43.4355784077,0.904322141651,276,,Montadet,Montadet,32,Gers,76,Occitanie
32277,MONTAMAT,32220,MONTAMAT,,43.4874990259,0.855058932049,277,,Montamat,Montamat,32,Gers,76,Occitanie
32278,MONTAUT,32300,MONTAUT,,43.3996449694,0.421986128901,278,,Montaut,Montaut,32,Gers,76,Occitanie
32279,MONTAUT LES CRENEAUX,32810,MONTAUT LES CRENEAUX,,43.7018933107,0.665181948592,279,,Montaut-les-Créneaux,Montaut-les-Créneaux,32,Gers,76,Occitanie
32280,MONT D ASTARAC,32140,MONT D ASTARAC,,43.3319789875,0.57774131865,280,,Mont-d'Astarac,Mont-d'Astarac,32,Gers,76,Occitanie
32281,MONT DE MARRAST,32170,MONT DE MARRAST,,43.3881448989,0.372176339267,281,,Mont-de-Marrast,Mont-de-Marrast,32,Gers,76,Occitanie
32282,MONTEGUT,32550,MONTEGUT,,43.6420882254,0.668712450968,282,,Montégut,Montégut,32,Gers,76,Occitanie
32283,MONTEGUT ARROS,32730,MONTEGUT ARROS,,43.3828965427,0.225654672696,283,,Montégut-Arros,Montégut-Arros,32,Gers,76,Occitanie
32284,MONTEGUT SAVES,32220,MONTEGUT SAVES,,43.4377361422,0.959505366368,284,,Montégut-Savès,Montégut-Savès,32,Gers,76,Occitanie
32285,MONTESQUIOU,32320,MONTESQUIOU,,43.5780506568,0.33230525991,285,,Montesquiou,Montesquiou,32,Gers,76,Occitanie
32286,MONTESTRUC SUR GERS,32390,MONTESTRUC SUR GERS,,43.7935827191,0.627663665443,286,,Montestruc-sur-Gers,Montestruc-sur-Gers,32,Gers,76,Occitanie
32287,MONTIES,32420,MONTIES,,43.3922616846,0.681459994602,287,,Monties,Monties,32,Gers,76,Occitanie
32288,MONTIRON,32200,MONTIRON,,43.5877135301,0.856761805705,288,,Montiron,Montiron,32,Gers,76,Occitanie
32289,MONTPEZAT,32220,MONTPEZAT,,43.3888998554,0.977281314352,289,,Montpézat,Montpézat,32,Gers,76,Occitanie
32290,MONTREAL,32250,MONTREAL DU GERS,,43.9513728334,0.199673132444,290,,Montréal,Montréal,32,Gers,76,Occitanie
32291,MORMES,32240,MORMES,,43.7960870358,-0.151124261308,291,,Mormès,Mormès,32,Gers,76,Occitanie
32292,MOUCHAN,32330,MOUCHAN,,43.8996297752,0.306322638694,292,,Mouchan,Mouchan,32,Gers,76,Occitanie
32293,MOUCHES,32300,MOUCHES,,43.5571624102,0.42208735235,293,,Mouchès,Mouchès,32,Gers,76,Occitanie
32294,MOUREDE,32190,MOUREDE,,43.8007757314,0.288037071468,294,,Mourède,Mourède,32,Gers,76,Occitanie
32295,NIZAS,32130,NIZAS,,43.4963743485,0.987538468887,295,,Nizas,Nizas,32,Gers,76,Occitanie
32296,NOGARO,32110,NOGARO,,43.7612028008,-0.0306381303587,296,,Nogaro,Nogaro,32,Gers,76,Occitanie
32297,NOILHAN,32130,NOILHAN,,43.5317727969,0.936447899241,297,,Noilhan,Noilhan,32,Gers,76,Occitanie
32298,NOUGAROULET,32270,NOUGAROULET,,43.6899514871,0.717872769956,298,,Nougaroulet,Nougaroulet,32,Gers,76,Occitanie
32299,NOULENS,32800,NOULENS,,43.8042418992,0.161070491114,299,,Noulens,Noulens,32,Gers,76,Occitanie
32300,ORBESSAN,32260,ORBESSAN,,43.5464677583,0.616126674006,300,,Orbessan,Orbessan,32,Gers,76,Occitanie
32301,ORDAN LARROQUE,32350,ORDAN LARROQUE,,43.6837953357,0.477177140595,301,,Ordan-Larroque,Ordan-Larroque,32,Gers,76,Occitanie
32302,ORNEZAN,32260,ORNEZAN,,43.5131064051,0.597407861877,302,,Ornézan,Ornézan,32,Gers,76,Occitanie
32303,PALLANNE,32230,PALLANNE,,43.5031265045,0.266743802401,303,,Pallanne,Pallanne,32,Gers,76,Occitanie
32304,PANASSAC,32140,PANASSAC,,43.3849980024,0.56774616292,304,,Panassac,Panassac,32,Gers,76,Occitanie
32305,PANJAS,32110,PANJAS,,43.8278400425,-0.0758955881718,305,,Panjas,Panjas,32,Gers,76,Occitanie
32306,PAUILHAC,32500,PAUILHAC,,43.8842839137,0.620708224899,306,,Pauilhac,Pauilhac,32,Gers,76,Occitanie
32307,PAVIE,32550,PAVIE,,43.605614575,0.589850870884,307,,Pavie,Pavie,32,Gers,76,Occitanie
32308,PEBEES,32130,PEBEES,,43.4606720707,1.02549034693,308,,Pébées,Pébées,32,Gers,76,Occitanie
32309,PELLEFIGUE,32420,PELLEFIGUE,,43.4789558709,0.79093287265,309,,Pellefigue,Pellefigue,32,Gers,76,Occitanie
32310,PERCHEDE,32460,PERCHEDE,,43.7767358721,-0.139888973276,310,,Perchède,Perchède,32,Gers,76,Occitanie
32311,PERGAIN TAILLAC,32700,PERGAIN TAILLAC,,44.0552185676,0.595570550255,311,,Pergain-Taillac,Pergain-Taillac,32,Gers,76,Occitanie
32312,PESSAN,32550,PESSAN,,43.6087293249,0.664949028866,312,,Pessan,Pessan,32,Gers,76,Occitanie
32313,PESSOULENS,32380,PESSOULENS,,43.8577797759,0.881779501914,313,,Pessoulens,Pessoulens,32,Gers,76,Occitanie
32314,PEYRECAVE,32340,PEYRECAVE,,43.9964330761,0.80968636062,314,,Peyrecave,Peyrecave,32,Gers,76,Occitanie
32315,PEYRUSSE GRANDE,32320,PEYRUSSE GRANDE,,43.6297892475,0.222152970344,315,,Peyrusse-Grande,Peyrusse-Grande,32,Gers,76,Occitanie
32316,PEYRUSSE MASSAS,32360,PEYRUSSE MASSAS,,43.7394070802,0.556957801671,316,,Peyrusse-Massas,Peyrusse-Massas,32,Gers,76,Occitanie
32317,PEYRUSSE VIEILLE,32230,PEYRUSSE VIEILLE,,43.6275468011,0.1757963589,317,,Peyrusse-Vieille,Peyrusse-Vieille,32,Gers,76,Occitanie
32318,PIS,32500,PIS,,43.7866546974,0.708992924431,318,,Pis,Pis,32,Gers,76,Occitanie
32319,PLAISANCE,32160,PLAISANCE,,43.6007929078,0.0437492719772,319,,Plaisance,Plaisance,32,Gers,76,Occitanie
32320,PLIEUX,32340,PLIEUX,,43.9527887439,0.742854267705,320,,Plieux,Plieux,32,Gers,76,Occitanie
32321,POLASTRON,32130,POLASTRON,,43.5342676704,0.838990733571,321,,Polastron,Polastron,32,Gers,76,Occitanie
32322,POMPIAC,32130,POMPIAC,,43.5155437622,1.00396355344,322,,Pompiac,Pompiac,32,Gers,76,Occitanie
32323,PONSAMPERE,32300,PONSAMPERE,,43.458656795,0.370027372328,323,,Ponsampère,Ponsampère,32,Gers,76,Occitanie
32324,PONSAN SOUBIRAN,32300,PONSAN SOUBIRAN,,43.3504668886,0.47895342176,324,,Ponsan-Soubiran,Ponsan-Soubiran,32,Gers,76,Occitanie
32325,POUYDRAGUIN,32290,POUYDRAGUIN,,43.658939519,0.0369491589991,325,,Pouydraguin,Pouydraguin,32,Gers,76,Occitanie
32326,POUYLEBON,32320,POUYLEBON,,43.5445195823,0.30221228905,326,,Pouylebon,Pouylebon,32,Gers,76,Occitanie
32327,POUY LOUBRIN,32260,POUY LOUBRIN,,43.4652677756,0.611644470499,327,,Pouy-Loubrin,Pouy-Loubrin,32,Gers,76,Occitanie
32328,POUY ROQUELAURE,32480,POUY ROQUELAURE,,44.0383578636,0.521396806967,328,,Pouy-Roquelaure,Pouy-Roquelaure,32,Gers,76,Occitanie
32329,PRECHAC,32390,PRECHAC,,43.7937593553,0.571624025705,329,,Préchac,Préchac,32,Gers,76,Occitanie
32330,PRECHAC SUR ADOUR,32160,PRECHAC SUR ADOUR,,43.6019637021,0.000682000711766,330,,Préchac-sur-Adour,Préchac-sur-Adour,32,Gers,76,Occitanie
32331,PREIGNAN,32810,PREIGNAN,,43.7210485292,0.636835530591,331,,Preignan,Preignan,32,Gers,76,Occitanie
32332,PRENERON,32190,PRENERON,,43.7274569495,0.269445355242,332,,Préneron,Préneron,32,Gers,76,Occitanie
32333,PROJAN,32400,PROJAN,,43.6074466247,-0.232771033675,333,,Projan,Projan,32,Gers,76,Occitanie
32334,PUJAUDRAN,32600,PUJAUDRAN,,43.5897953804,1.17106411645,334,,Pujaudran,Pujaudran,32,Gers,76,Occitanie
32335,PUYCASQUIER,32120,PUYCASQUIER,,43.7472496042,0.74669365251,335,,Puycasquier,Puycasquier,32,Gers,76,Occitanie
32336,PUYLAUSIC,32220,PUYLAUSIC,,43.4440094082,0.934879831241,336,,Puylausic,Puylausic,32,Gers,76,Occitanie
32337,PUYSEGUR,32390,PUYSEGUR,,43.7729413958,0.591022229224,337,,Puységur,Puységur,32,Gers,76,Occitanie
32338,RAMOUZENS,32800,RAMOUZENS,,43.8102320971,0.189111394698,338,,Ramouzens,Ramouzens,32,Gers,76,Occitanie
32339,RAZENGUES,32600,RAZENGUES,,43.644588775,0.985675925252,339,,Razengues,Razengues,32,Gers,76,Occitanie
32340,REANS,32800,REANS,,43.8713310328,0.0375331415529,340,,Réans,Réans,32,Gers,76,Occitanie
32341,REJAUMONT,32390,REJAUMONT,,43.8138233726,0.5503994481,341,,Réjaumont,Réjaumont,32,Gers,76,Occitanie
32342,RICOURT,32230,RICOURT,,43.4886092256,0.178113627524,342,,Ricourt,Ricourt,32,Gers,76,Occitanie
32343,RIGUEPEU,32320,RIGUEPEU,,43.6535301876,0.33796916031,343,,Riguepeu,Riguepeu,32,Gers,76,Occitanie
32344,RISCLE,32400,RISCLE,,43.6454030402,-0.078542343724,344,,Riscle,Riscle,32,Gers,76,Occitanie
32345,LA ROMIEU,32480,LA ROMIEU,,43.9866068735,0.503612353282,345,La,Romieu,La Romieu,32,Gers,76,Occitanie
32346,ROQUEBRUNE,32190,ROQUEBRUNE,,43.7094190494,0.294605252318,346,,Roquebrune,Roquebrune,32,Gers,76,Occitanie
32347,ROQUEFORT,32390,ROQUEFORT,,43.756872831,0.587561985041,347,,Roquefort,Roquefort,32,Gers,76,Occitanie
32348,ROQUELAURE,32810,ROQUELAURE,,43.7239375503,0.593217986978,348,,Roquelaure,Roquelaure,32,Gers,76,Occitanie
32349,ROQUELAURE ST AUBIN,32430,ROQUELAURE ST AUBIN,,43.6639149759,0.985022023847,349,,Roquelaure-Saint-Aubin,Roquelaure-Saint-Aubin,32,Gers,76,Occitanie
32350,ROQUEPINE,32100,ROQUEPINE,,43.9083463279,0.468129432409,350,,Roquepine,Roquepine,32,Gers,76,Occitanie
32351,ROQUES,32310,ROQUES,,43.8412918051,0.297884331207,351,,Roques,Roques,32,Gers,76,Occitanie
32352,ROZES,32190,ROZES,,43.8014785166,0.360397755072,352,,Rozès,Rozès,32,Gers,76,Occitanie
32353,SABAILLAN,32420,SABAILLAN,,43.4421078031,0.811963584159,353,,Sabaillan,Sabaillan,32,Gers,76,Occitanie
32354,SABAZAN,32290,SABAZAN,,43.7096249561,0.0519402206437,354,,Sabazan,Sabazan,32,Gers,76,Occitanie
32355,SADEILLAN,32170,SADEILLAN,,43.3960094567,0.343407720125,355,,Sadeillan,Sadeillan,32,Gers,76,Occitanie
32356,ST ANDRE,32200,ST ANDRE,,43.5589723069,0.858056071738,356,,Saint-André,Saint-André,32,Gers,76,Occitanie
32357,STE ANNE,32430,STE ANNE,,43.7452215949,0.967980737817,357,,Sainte-Anne,Sainte-Anne,32,Gers,76,Occitanie
32358,ST ANTOINE,32340,ST ANTOINE,,44.0397973758,0.828235639409,358,,Saint-Antoine,Saint-Antoine,32,Gers,76,Occitanie
32359,ST ANTONIN,32120,ST ANTONIN,,43.7201944543,0.822219263011,359,,Saint-Antonin,Saint-Antonin,32,Gers,76,Occitanie
32360,ST ARAILLES,32350,ST ARAILLES,,43.6244874174,0.347280500051,360,,Saint-Arailles,Saint-Arailles,32,Gers,76,Occitanie
32361,ST ARROMAN,32300,ST ARROMAN,,43.4356200407,0.519787402216,361,,Saint-Arroman,Saint-Arroman,32,Gers,76,Occitanie
32362,ST AUNIX LENGROS,32160,ST AUNIX LENGROS,,43.5729419126,0.0566677604878,362,,Saint-Aunix-Lengros,Saint-Aunix-Lengros,32,Gers,76,Occitanie
32363,STE AURENCE CAZAUX,32300,STE AURENCE CAZAUX,,43.371037849,0.428715124753,363,,Sainte-Aurence-Cazaux,Sainte-Aurence-Cazaux,32,Gers,76,Occitanie
32364,ST AVIT FRANDAT,32700,ST AVIT FRANDAT,,43.9833844437,0.660436983062,364,,Saint-Avit-Frandat,Saint-Avit-Frandat,32,Gers,76,Occitanie
32365,ST BLANCARD,32140,ST BLANCARD,,43.3503699473,0.656853892371,365,,Saint-Blancard,Saint-Blancard,32,Gers,76,Occitanie
32366,ST BRES,32120,ST BRES,,43.7829005093,0.769140998538,366,,Saint-Brès,Saint-Brès,32,Gers,76,Occitanie
32367,ST CHRISTAUD,32320,ST CHRISTAUD,,43.5257567477,0.261157404352,367,,Saint-Christaud,Saint-Christaud,32,Gers,76,Occitanie
32368,STE CHRISTIE,32390,STE CHRISTIE,,43.7568936206,0.632111364488,368,,Sainte-Christie,Sainte-Christie,32,Gers,76,Occitanie
32369,STE CHRISTIE D ARMAGNAC,32370,STE CHRISTIE D ARMAGNAC,,43.7937355004,-0.00494982246103,369,,Sainte-Christie-d'Armagnac,Sainte-Christie-d'Armagnac,32,Gers,76,Occitanie
32370,ST CLAR,32380,ST CLAR,,43.8928262507,0.761393836193,370,,Saint-Clar,Saint-Clar,32,Gers,76,Occitanie
32371,ST CREAC,32380,ST CREAC,,43.9169566519,0.793230130965,371,,Saint-Créac,Saint-Créac,32,Gers,76,Occitanie
32372,ST CRICQ,32430,ST CRICQ,,43.6982927538,0.988565148255,372,,Saint-Cricq,Saint-Cricq,32,Gers,76,Occitanie
32373,STE DODE,32170,STE DODE,,43.4164604134,0.364817830284,373,,Sainte-Dode,Sainte-Dode,32,Gers,76,Occitanie
32374,ST ELIX,32450,ST ELIX,,43.4955140931,0.767486330739,374,,Saint-Élix-d'Astarac,Saint-Élix-d'Astarac,32,Gers,76,Occitanie
32375,ST ELIX THEUX,32300,ST ELIX THEUX,,43.4280095382,0.472914015633,375,,Saint-Élix-Theux,Saint-Élix-Theux,32,Gers,76,Occitanie
32376,STE GEMME,32120,STE GEMME,,43.7729198563,0.793831939268,376,,Sainte-Gemme,Sainte-Gemme,32,Gers,76,Occitanie
32377,ST GEORGES,32430,ST GEORGES,,43.7350584133,0.93023546644,377,,Saint-Georges,Saint-Georges,32,Gers,76,Occitanie
32378,ST GERME,32400,ST GERME,,43.6752505393,-0.146632518805,378,,Saint-Germé,Saint-Germé,32,Gers,76,Occitanie
32379,ST GERMIER,32200,ST GERMIER,,43.6735302735,0.958403748596,379,,Saint-Germier,Saint-Germier,32,Gers,76,Occitanie
32380,ST GRIEDE,32110,ST GRIEDE,,43.7245038099,-0.0964853387859,380,,Saint-Griède,Saint-Griède,32,Gers,76,Occitanie
32381,ST JEAN LE COMTAL,32550,ST JEAN LE COMTAL,,43.5737293634,0.514611237658,381,,Saint-Jean-le-Comtal,Saint-Jean-le-Comtal,32,Gers,76,Occitanie
32382,ST JEAN POUTGE,32190,ST JEAN POUTGE,,43.7345063644,0.381202830374,382,,Saint-Jean-Poutge,Saint-Jean-Poutge,32,Gers,76,Occitanie
32383,ST JUSTIN,32230,ST JUSTIN,,43.4833069212,0.148719879658,383,,Saint-Justin,Saint-Justin,32,Gers,76,Occitanie
32384,ST LARY,32360,ST LARY,,43.7203557859,0.4976745477,384,,Saint-Lary,Saint-Lary,32,Gers,76,Occitanie
32385,ST LEONARD,32380,ST LEONARD,,43.857899605,0.769694095196,385,,Saint-Léonard,Saint-Léonard,32,Gers,76,Occitanie
32386,ST LIZIER DU PLANTE,32220,ST LIZIER DU PLANTE,,43.4096987916,0.947438309494,386,,Saint-Lizier-du-Planté,Saint-Lizier-du-Planté,32,Gers,76,Occitanie
32387,ST LOUBE,32220,ST LOUBE,,43.4400778891,1.00559153521,387,,Saint-Loube,Saint-Loube,32,Gers,76,Occitanie
32388,STE MARIE,32200,STE MARIE,,43.6724633331,0.866328839626,388,,Sainte-Marie,Sainte-Marie,32,Gers,76,Occitanie
32389,ST MARTIN,32300,ST MARTIN,,43.5079691513,0.374354787173,389,,Saint-Martin,Saint-Martin,32,Gers,76,Occitanie
32390,ST MARTIN D ARMAGNAC,32110,ST MARTIN D ARMAGNAC,,43.7109968571,-0.0639870800498,390,,Saint-Martin-d'Armagnac,Saint-Martin-d'Armagnac,32,Gers,76,Occitanie
32391,ST MARTIN DE GOYNE,32480,ST MARTIN DE GOYNE,,44.0032506931,0.574560917356,391,,Saint-Martin-de-Goyne,Saint-Martin-de-Goyne,32,Gers,76,Occitanie
32392,ST MARTIN GIMOIS,32450,ST MARTIN GIMOIS,,43.5200991545,0.810352365768,392,,Saint-Martin-Gimois,Saint-Martin-Gimois,32,Gers,76,Occitanie
32393,ST MAUR,32300,ST MAUR,,43.4855046217,0.346681853223,393,,Saint-Maur,Saint-Maur,32,Gers,76,Occitanie
32394,ST MEDARD,32300,ST MEDARD,,43.4844581655,0.462191038357,394,,Saint-Médard,Saint-Médard,32,Gers,76,Occitanie
32395,STE MERE,32700,STE MERE,,44.0020371308,0.68395430788,395,,Sainte-Mère,Sainte-Mère,32,Gers,76,Occitanie
32396,ST MEZARD,32700,ST MEZARD,,44.0338368158,0.56893422575,396,,Saint-Mézard,Saint-Mézard,32,Gers,76,Occitanie
32397,ST MICHEL,32300,ST MICHEL,,43.4314884604,0.405781062799,397,,Saint-Michel,Saint-Michel,32,Gers,76,Occitanie
32398,ST MONT,32400,ST MONT,,43.644278367,-0.140293846717,398,,Saint-Mont,Saint-Mont,32,Gers,76,Occitanie
32399,ST ORENS,32120,ST ORENS,,43.712737333,0.915812441349,399,,Saint-Orens,Saint-Orens,32,Gers,76,Occitanie
32400,ST ORENS POUY PETIT,32100,ST ORENS POUY PETIT,,43.9123809326,0.438469357077,400,,Saint-Orens-Pouy-Petit,Saint-Orens-Pouy-Petit,32,Gers,76,Occitanie
32401,ST OST,32300,ST OST,,43.3761174714,0.465164448577,401,,Saint-Ost,Saint-Ost,32,Gers,76,Occitanie
32402,ST PAUL DE BAISE,32190,ST PAUL DE BAISE,,43.7805362747,0.372419299589,402,,Saint-Paul-de-Baïse,Saint-Paul-de-Baïse,32,Gers,76,Occitanie
32403,ST PIERRE D AUBEZIES,32290,ST PIERRE D AUBEZIES,,43.6493896565,0.162149090405,403,,Saint-Pierre-d'Aubézies,Saint-Pierre-d'Aubézies,32,Gers,76,Occitanie
32404,ST PUY,32310,ST PUY,,43.8684997872,0.45938385688,404,,Saint-Puy,Saint-Puy,32,Gers,76,Occitanie
32405,STE RADEGONDE,32500,STE RADEGONDE,,43.8415690782,0.585585218157,405,,Sainte-Radegonde,Sainte-Radegonde,32,Gers,76,Occitanie
32406,ST SAUVY,32270,ST SAUVY,,43.6994537624,0.811688166016,406,,Saint-Sauvy,Saint-Sauvy,32,Gers,76,Occitanie
32407,ST SOULAN,32220,ST SOULAN,,43.5085067561,0.860606290499,407,,Saint-Soulan,Saint-Soulan,32,Gers,76,Occitanie
32408,SALLES D ARMAGNAC,32370,SALLES D ARMAGNAC,,43.8113198455,-0.0432929443572,408,,Salles-d'Armagnac,Salles-d'Armagnac,32,Gers,76,Occitanie
32409,SAMARAN,32140,SAMARAN,,43.3917650626,0.530680850308,409,,Samaran,Samaran,32,Gers,76,Occitanie
32410,SAMATAN,32130,SAMATAN,,43.4935426323,0.934596447621,410,,Samatan,Samatan,32,Gers,76,Occitanie
32411,SANSAN,32260,SANSAN,,43.5278730462,0.612788955007,411,,Sansan,Sansan,32,Gers,76,Occitanie
32412,SARAMON,32450,SARAMON,,43.5243197387,0.760359973861,412,,Saramon,Saramon,32,Gers,76,Occitanie
32413,SARCOS,32420,SARCOS,,43.3702423264,0.681801871432,413,,Sarcos,Sarcos,32,Gers,76,Occitanie
32414,SARRAGACHIES,32400,SARRAGACHIES,,43.6843173122,-0.0504570702936,414,,Sarragachies,Sarragachies,32,Gers,76,Occitanie
32415,SARRAGUZAN,32170,SARRAGUZAN,,43.364816423,0.339588596362,415,,Sarraguzan,Sarraguzan,32,Gers,76,Occitanie
32416,SARRANT,32120,SARRANT,,43.7704131309,0.928322763871,416,,Sarrant,Sarrant,32,Gers,76,Occitanie
32417,LA SAUVETAT,32500,LA SAUVETAT,,43.8493406268,0.533837296503,417,La,Sauvetat,La Sauvetat,32,Gers,76,Occitanie
32418,SAUVETERRE,32220,SAUVETERRE,,43.4552121069,0.84746612387,418,,Sauveterre,Sauveterre,32,Gers,76,Occitanie
32419,SAUVIAC,32300,SAUVIAC,,43.4094830661,0.45725685306,419,,Sauviac,Sauviac,32,Gers,76,Occitanie
32420,SAUVIMONT,32220,SAUVIMONT,,43.449188285,0.967475829422,420,,Sauvimont,Sauvimont,32,Gers,76,Occitanie
32421,SAVIGNAC MONA,32130,SAVIGNAC MONA,,43.4856907857,1.010147796,421,,Savignac-Mona,Savignac-Mona,32,Gers,76,Occitanie
32422,SCIEURAC ET FLOURES,32230,SCIEURAC ET FLOURES,,43.5570732793,0.19848716736,422,,Scieurac-et-Flourès,Scieurac-et-Flourès,32,Gers,76,Occitanie
32423,SEAILLES,32190,SEAILLES,,43.7514305995,0.114512954869,423,,Séailles,Séailles,32,Gers,76,Occitanie
32424,SEGOS,32400,SEGOS,,43.6299075167,-0.261024966204,424,,Ségos,Ségos,32,Gers,76,Occitanie
32425,SEGOUFIELLE,32600,SEGOUFIELLE,,43.6308520432,1.13179553628,425,,Ségoufielle,Ségoufielle,32,Gers,76,Occitanie
32426,SEISSAN,32260,SEISSAN,,43.4981861217,0.571544728531,426,,Seissan,Seissan,32,Gers,76,Occitanie
32426,SEISSAN,32260,SEISSAN,ARTIGUEDIEU,43.4981861217,0.571544728531,426,,Seissan,Seissan,32,Gers,76,Occitanie
32427,SEMBOUES,32230,SEMBOUES,,43.4631952231,0.173358322537,427,,Sembouès,Sembouès,32,Gers,76,Occitanie
32428,SEMEZIES CACHAN,32450,SEMEZIES CACHAN,,43.5039796114,0.734910896244,428,,Sémézies-Cachan,Sémézies-Cachan,32,Gers,76,Occitanie
32429,SEMPESSERRE,32700,SEMPESSERRE,,44.0211935809,0.641183560701,429,,Sempesserre,Sempesserre,32,Gers,76,Occitanie
32430,SERE,32140,SERE,,43.404950777,0.645635504083,430,,Sère,Sère,32,Gers,76,Occitanie
32431,SEREMPUY,32120,SEREMPUY,,43.7615192489,0.821224548581,431,,Sérempuy,Sérempuy,32,Gers,76,Occitanie
32432,SEYSSES SAVES,32130,SEYSSES SAVES,,43.5120468834,1.04628669301,432,,Seysses-Savès,Seysses-Savès,32,Gers,76,Occitanie
32433,SIMORRE,32420,SIMORRE,,43.457392326,0.73670535692,433,,Simorre,Simorre,32,Gers,76,Occitanie
32434,SION,32110,SION,,43.7385172252,0.00694029639365,434,,Sion,Sion,32,Gers,76,Occitanie
32435,SIRAC,32430,SIRAC,,43.703053309,0.954070394877,435,,Sirac,Sirac,32,Gers,76,Occitanie
32436,SOLOMIAC,32120,SOLOMIAC,,43.8030207039,0.886775990796,436,,Solomiac,Solomiac,32,Gers,76,Occitanie
32437,SORBETS,32110,SORBETS,,43.7138869454,-0.0164094873395,437,,Sorbets,Sorbets,32,Gers,76,Occitanie
32438,TACHOIRES,32260,TACHOIRES,,43.4721937352,0.660115776589,438,,Tachoires,Tachoires,32,Gers,76,Occitanie
32439,TARSAC,32400,TARSAC,,43.667823646,-0.112497815463,439,,Tarsac,Tarsac,32,Gers,76,Occitanie
32440,TASQUE,32160,TASQUE,,43.6375200891,0.0112451913592,440,,Tasque,Tasque,32,Gers,76,Occitanie
32441,TAYBOSC,32120,TAYBOSC,,43.7860742927,0.741893706736,441,,Taybosc,Taybosc,32,Gers,76,Occitanie
32442,TERRAUBE,32700,TERRAUBE,,43.9023919124,0.554587221859,442,,Terraube,Terraube,32,Gers,76,Occitanie
32443,TERMES D ARMAGNAC,32400,TERMES D ARMAGNAC,,43.6736657601,-0.0144861541884,443,,Termes-d'Armagnac,Termes-d'Armagnac,32,Gers,76,Occitanie
32444,THOUX,32430,THOUX,,43.6822691836,0.992043234795,444,,Thoux,Thoux,32,Gers,76,Occitanie
32445,TIESTE URAGNOUX,32160,TIESTE URAGNOUX,,43.5522019884,0.032108280452,445,,Tieste-Uragnoux,Tieste-Uragnoux,32,Gers,76,Occitanie
32446,TILLAC,32170,TILLAC,,43.4781527025,0.264769117337,446,,Tillac,Tillac,32,Gers,76,Occitanie
32447,TIRENT PONTEJAC,32450,TIRENT PONTEJAC,,43.545808874,0.793604017462,447,,Tirent-Pontéjac,Tirent-Pontéjac,32,Gers,76,Occitanie
32448,TOUGET,32430,TOUGET,,43.6868075437,0.918063549202,448,,Touget,Touget,32,Gers,76,Occitanie
32449,TOUJOUSE,32240,TOUJOUSE,,43.8219002959,-0.178398036084,449,,Toujouse,Toujouse,32,Gers,76,Occitanie
32450,TOURDUN,32230,TOURDUN,,43.5472486577,0.160157307387,450,,Tourdun,Tourdun,32,Gers,76,Occitanie
32451,TOURNAN,32420,TOURNAN,,43.4242112009,0.792020449615,451,,Tournan,Tournan,32,Gers,76,Occitanie
32452,TOURNECOUPE,32380,TOURNECOUPE,,43.8620609128,0.827558996718,452,,Tournecoupe,Tournecoupe,32,Gers,76,Occitanie
32453,TOURRENQUETS,32390,TOURRENQUETS,,43.7491567368,0.701313757709,453,,Tourrenquets,Tourrenquets,32,Gers,76,Occitanie
32454,TRAVERSERES,32450,TRAVERSERES,,43.5310652573,0.649965347065,454,,Traversères,Traversères,32,Gers,76,Occitanie
32455,TRONCENS,32230,TRONCENS,,43.4545876376,0.220355779744,455,,Troncens,Troncens,32,Gers,76,Occitanie
32456,TUDELLE,32190,TUDELLE,,43.6815695476,0.287237004395,456,,Tudelle,Tudelle,32,Gers,76,Occitanie
32457,URDENS,32500,URDENS,,43.8627011865,0.712618673023,457,,Urdens,Urdens,32,Gers,76,Occitanie
32458,URGOSSE,32110,URGOSSE,,43.7383895034,-0.021627806112,458,,Urgosse,Urgosse,32,Gers,76,Occitanie
32459,VALENCE SUR BAISE,32310,VALENCE SUR BAISE,,43.8682182718,0.363460547299,459,,Valence-sur-Baïse,Valence-sur-Baïse,32,Gers,76,Occitanie
32460,VERGOIGNAN,32720,VERGOIGNAN,,43.7235746425,-0.188266221507,460,,Vergoignan,Vergoignan,32,Gers,76,Occitanie
32461,VERLUS,32400,VERLUS,,43.5997886169,-0.1995913501,461,,Verlus,Verlus,32,Gers,76,Occitanie
32462,VIC FEZENSAC,32190,VIC FEZENSAC,,43.762029942,0.297842185088,462,,Vic-Fezensac,Vic-Fezensac,32,Gers,76,Occitanie
32462,VIC FEZENSAC,32190,VIC FEZENSAC,LAGRAULAS,43.762029942,0.297842185088,462,,Vic-Fezensac,Vic-Fezensac,32,Gers,76,Occitanie
32463,VIELLA,32400,VIELLA,,43.6004267711,-0.138847798768,463,,Viella,Viella,32,Gers,76,Occitanie
32464,VILLECOMTAL SUR ARROS,32730,VILLECOMTAL SUR ARROS,,43.4001487787,0.200965512965,464,,Villecomtal-sur-Arros,Villecomtal-sur-Arros,32,Gers,76,Occitanie
32465,VILLEFRANCHE,32420,VILLEFRANCHE,,43.4214250557,0.734034219047,465,,Villefranche,Villefranche,32,Gers,76,Occitanie
32466,VIOZAN,32300,VIOZAN,,43.3951914041,0.469854981602,466,,Viozan,Viozan,32,Gers,76,Occitanie
32467,ST CAPRAIS,32200,ST CAPRAIS,,43.5946521434,0.793360450562,467,,Saint-Caprais,Saint-Caprais,32,Gers,76,Occitanie
32468,AUSSOS,32140,AUSSOS,,43.3752780405,0.641130457139,468,,Aussos,Aussos,32,Gers,76,Occitanie
33001,ABZAC,33230,ABZAC,,45.0106457764,-0.130179806678,1,,Abzac,Abzac,33,Gironde,75,Nouvelle-Aquitaine
33002,AILLAS,33124,AILLAS,,44.4835265148,-0.0662157307002,2,,Aillas,Aillas,33,Gironde,75,Nouvelle-Aquitaine
33003,AMBARES ET LAGRAVE,33440,AMBARES ET LAGRAVE,,44.9444897713,-0.500579133995,3,,Ambarès-et-Lagrave,Ambarès-et-Lagrave,33,Gironde,75,Nouvelle-Aquitaine
33004,AMBES,33810,AMBES,,45.0115337381,-0.548307688841,4,,Ambès,Ambès,33,Gironde,75,Nouvelle-Aquitaine
33005,ANDERNOS LES BAINS,33510,ANDERNOS LES BAINS,,44.754520164,-1.08109347037,5,,Andernos-les-Bains,Andernos-les-Bains,33,Gironde,75,Nouvelle-Aquitaine
33006,ANGLADE,33390,ANGLADE,,45.2115004641,-0.638219671471,6,,Anglade,Anglade,33,Gironde,75,Nouvelle-Aquitaine
33007,ARBANATS,33640,ARBANATS,,44.670996265,-0.40276700503,7,,Arbanats,Arbanats,33,Gironde,75,Nouvelle-Aquitaine
33008,ARBIS,33760,ARBIS,,44.6725868035,-0.2547200817,8,,Arbis,Arbis,33,Gironde,75,Nouvelle-Aquitaine
33009,ARCACHON,33120,ARCACHON,,44.6529002838,-1.17429790933,9,,Arcachon,Arcachon,33,Gironde,75,Nouvelle-Aquitaine
33010,ARCINS,33460,ARCINS,,45.0765827796,-0.70962789405,10,,Arcins,Arcins,33,Gironde,75,Nouvelle-Aquitaine
33011,ARES,33740,ARES,,44.7952271583,-1.07883221151,11,,Arès,Arès,33,Gironde,75,Nouvelle-Aquitaine
33012,ARSAC,33460,ARSAC,,44.9884555453,-0.701315089192,12,,Arsac,Arsac,33,Gironde,75,Nouvelle-Aquitaine
33013,ARTIGUES PRES BORDEAUX,33370,ARTIGUES PRES BORDEAUX,,44.8603385038,-0.490377864769,13,,Artigues-près-Bordeaux,Artigues-près-Bordeaux,33,Gironde,75,Nouvelle-Aquitaine
33014,LES ARTIGUES DE LUSSAC,33570,LES ARTIGUES DE LUSSAC,,44.9726275615,-0.140230577945,14,Les,Artigues-de-Lussac,Les Artigues-de-Lussac,33,Gironde,75,Nouvelle-Aquitaine
33015,ARVEYRES,33500,ARVEYRES,,44.8915609745,-0.2766650988,15,,Arveyres,Arveyres,33,Gironde,75,Nouvelle-Aquitaine
33016,ASQUES,33240,ASQUES,,44.948000841,-0.426140042682,16,,Asques,Asques,33,Gironde,75,Nouvelle-Aquitaine
33017,AUBIAC,33430,AUBIAC,,44.4707892679,-0.244457363295,17,,Aubiac,Aubiac,33,Gironde,75,Nouvelle-Aquitaine
33018,VAL DE VIRVEE,33240,VAL DE VIRVEE,AUBIE ET ESPESSAS,45.0218017593,-0.404998298287,18,,Val de Virvée,Val de Virvée,33,Gironde,75,Nouvelle-Aquitaine
33018,VAL DE VIRVEE,33240,VAL DE VIRVEE,SALIGNAC,45.0218017593,-0.404998298287,18,,Val de Virvée,Val de Virvée,33,Gironde,75,Nouvelle-Aquitaine
33018,VAL DE VIRVEE,33240,VAL DE VIRVEE,ST ANTOINE,45.0218017593,-0.404998298287,18,,Val de Virvée,Val de Virvée,33,Gironde,75,Nouvelle-Aquitaine
33019,AUDENGE,33980,AUDENGE,,44.7138966628,-0.944173294479,19,,Audenge,Audenge,33,Gironde,75,Nouvelle-Aquitaine
33020,AURIOLLES,33790,AURIOLLES,,44.7369365281,0.0444966358061,20,,Auriolles,Auriolles,33,Gironde,75,Nouvelle-Aquitaine
33021,AUROS,33124,AUROS,,44.5076532706,-0.163740689311,21,,Auros,Auros,33,Gironde,75,Nouvelle-Aquitaine
33022,AVENSAN,33480,AVENSAN,,45.005403307,-0.760815427452,22,,Avensan,Avensan,33,Gironde,75,Nouvelle-Aquitaine
33023,AYGUEMORTE LES GRAVES,33640,AYGUEMORTE LES GRAVES,,44.702438627,-0.484145189594,23,,Ayguemorte-les-Graves,Ayguemorte-les-Graves,33,Gironde,75,Nouvelle-Aquitaine
33024,BAGAS,33190,BAGAS,,44.6187804528,-0.0489905524561,24,,Bagas,Bagas,33,Gironde,75,Nouvelle-Aquitaine
33025,BAIGNEAUX,33760,BAIGNEAUX,,44.7239086463,-0.193758554002,25,,Baigneaux,Baigneaux,33,Gironde,75,Nouvelle-Aquitaine
33026,BALIZAC,33730,BALIZAC,,44.4864307909,-0.448728461334,26,,Balizac,Balizac,33,Gironde,75,Nouvelle-Aquitaine
33027,BARIE,33190,BARIE,,44.5710681518,-0.111645415983,27,,Barie,Barie,33,Gironde,75,Nouvelle-Aquitaine
33028,BARON,33750,BARON,,44.8235731803,-0.317485301092,28,,Baron,Baron,33,Gironde,75,Nouvelle-Aquitaine
33029,LE BARP,33114,LE BARP,,44.6268462922,-0.746697894211,29,Le,Barp,Le Barp,33,Gironde,75,Nouvelle-Aquitaine
33030,BARSAC,33720,BARSAC,,44.6017775611,-0.323930210636,30,,Barsac,Barsac,33,Gironde,75,Nouvelle-Aquitaine
33031,BASSANNE,33190,BASSANNE,,44.560543686,-0.0931882578624,31,,Bassanne,Bassanne,33,Gironde,75,Nouvelle-Aquitaine
33032,BASSENS,33530,BASSENS,,44.9084140601,-0.525868280903,32,,Bassens,Bassens,33,Gironde,75,Nouvelle-Aquitaine
33033,BAURECH,33880,BAURECH,,44.7274813784,-0.433879900007,33,,Baurech,Baurech,33,Gironde,75,Nouvelle-Aquitaine
33034,BAYAS,33230,BAYAS,,45.0676036545,-0.209456063522,34,,Bayas,Bayas,33,Gironde,75,Nouvelle-Aquitaine
33035,BAYON SUR GIRONDE,33710,BAYON SUR GIRONDE,,45.0433426263,-0.609639022961,35,,Bayon-sur-Gironde,Bayon-sur-Gironde,33,Gironde,75,Nouvelle-Aquitaine
33036,BAZAS,33430,BAZAS,,44.4424533682,-0.212326795621,36,,Bazas,Bazas,33,Gironde,75,Nouvelle-Aquitaine
33037,BEAUTIRAN,33640,BEAUTIRAN,,44.7034400813,-0.459169831543,37,,Beautiran,Beautiran,33,Gironde,75,Nouvelle-Aquitaine
33038,BEGADAN,33340,BEGADAN,,45.3670708459,-0.8958001736,38,,Bégadan,Bégadan,33,Gironde,75,Nouvelle-Aquitaine
33039,BEGLES,33130,BEGLES,,44.8016009051,-0.547755768448,39,,Bègles,Bègles,33,Gironde,75,Nouvelle-Aquitaine
33040,BEGUEY,33410,BEGUEY,,44.6493296399,-0.325359958986,40,,Béguey,Béguey,33,Gironde,75,Nouvelle-Aquitaine
33042,BELIN BELIET,33830,BELIN BELIET,,44.4861918644,-0.786911494042,42,,Belin-Béliet,Belin-Béliet,33,Gironde,75,Nouvelle-Aquitaine
33042,BELIN BELIET,33830,BELIN BELIET,BELIET,44.4861918644,-0.786911494042,42,,Belin-Béliet,Belin-Béliet,33,Gironde,75,Nouvelle-Aquitaine
33043,BELLEBAT,33760,BELLEBAT,,44.7382366196,-0.221985163561,43,,Bellebat,Bellebat,33,Gironde,75,Nouvelle-Aquitaine
33044,BELLEFOND,33760,BELLEFOND,,44.7676238446,-0.173810088464,44,,Bellefond,Bellefond,33,Gironde,75,Nouvelle-Aquitaine
33045,BELVES DE CASTILLON,33350,BELVES DE CASTILLON,,44.8784883678,-0.0279648094284,45,,Belvès-de-Castillon,Belvès-de-Castillon,33,Gironde,75,Nouvelle-Aquitaine
33046,BERNOS BEAULAC,33430,BERNOS BEAULAC,,44.3630023376,-0.259455361877,46,,Bernos-Beaulac,Bernos-Beaulac,33,Gironde,75,Nouvelle-Aquitaine
33047,BERSON,33390,BERSON,,45.1170453361,-0.574457509395,47,,Berson,Berson,33,Gironde,75,Nouvelle-Aquitaine
33048,BERTHEZ,33124,BERTHEZ,,44.4828924557,-0.128085813135,48,,Berthez,Berthez,33,Gironde,75,Nouvelle-Aquitaine
33049,BEYCHAC ET CAILLAU,33750,BEYCHAC ET CAILLAU,,44.8720986802,-0.371061505246,49,,Beychac-et-Caillau,Beychac-et-Caillau,33,Gironde,75,Nouvelle-Aquitaine
33050,BIEUJAC,33210,BIEUJAC,,44.5383649764,-0.154398483279,50,,Bieujac,Bieujac,33,Gironde,75,Nouvelle-Aquitaine
33051,BIGANOS,33380,BIGANOS,,44.6611063153,-0.948829442283,51,,Biganos,Biganos,33,Gironde,75,Nouvelle-Aquitaine
33051,BIGANOS,33380,BIGANOS,FACTURE,44.6611063153,-0.948829442283,51,,Biganos,Biganos,33,Gironde,75,Nouvelle-Aquitaine
33052,LES BILLAUX,33500,LES BILLAUX,,44.962026014,-0.242724056573,52,Les,Billaux,Les Billaux,33,Gironde,75,Nouvelle-Aquitaine
33053,BIRAC,33430,BIRAC,,44.4124016589,-0.143472733247,53,,Birac,Birac,33,Gironde,75,Nouvelle-Aquitaine
33054,BLAIGNAC,33190,BLAIGNAC,,44.5509350184,-0.0541157193066,54,,Blaignac,Blaignac,33,Gironde,75,Nouvelle-Aquitaine
33055,BLAIGNAN,33340,BLAIGNAN,,45.3230869763,-0.865864603623,55,,Blaignan,Blaignan,33,Gironde,75,Nouvelle-Aquitaine
33056,BLANQUEFORT,33290,BLANQUEFORT,,44.9231492741,-0.612268950301,56,,Blanquefort,Blanquefort,33,Gironde,75,Nouvelle-Aquitaine
33057,BLASIMON,33540,BLASIMON,,44.7502132956,-0.089121338882,57,,Blasimon,Blasimon,33,Gironde,75,Nouvelle-Aquitaine
33058,BLAYE,33390,BLAYE,,45.127899764,-0.669707092291,58,,Blaye,Blaye,33,Gironde,75,Nouvelle-Aquitaine
33059,BLESIGNAC,33670,BLESIGNAC,,44.7773252644,-0.254997261945,59,,Blésignac,Blésignac,33,Gironde,75,Nouvelle-Aquitaine
33060,BOMMES,33210,BOMMES,,44.5529791814,-0.344933084862,60,,Bommes,Bommes,33,Gironde,75,Nouvelle-Aquitaine
33061,BONNETAN,33370,BONNETAN,,44.8193051355,-0.410997563502,61,,Bonnetan,Bonnetan,33,Gironde,75,Nouvelle-Aquitaine
33062,BONZAC,33910,BONZAC,,45.0072015945,-0.221528081748,62,,Bonzac,Bonzac,33,Gironde,75,Nouvelle-Aquitaine
33063,BORDEAUX,33000,BORDEAUX,,44.8572445351,-0.57369678116,63,,Bordeaux,Bordeaux,33,Gironde,75,Nouvelle-Aquitaine
33063,BORDEAUX,33100,BORDEAUX,,44.8572445351,-0.57369678116,63,,Bordeaux,Bordeaux,33,Gironde,75,Nouvelle-Aquitaine
33063,BORDEAUX,33200,BORDEAUX,,44.8572445351,-0.57369678116,63,,Bordeaux,Bordeaux,33,Gironde,75,Nouvelle-Aquitaine
33063,BORDEAUX,33300,BORDEAUX,,44.8572445351,-0.57369678116,63,,Bordeaux,Bordeaux,33,Gironde,75,Nouvelle-Aquitaine
33063,BORDEAUX,33800,BORDEAUX,,44.8572445351,-0.57369678116,63,,Bordeaux,Bordeaux,33,Gironde,75,Nouvelle-Aquitaine
33064,BOSSUGAN,33350,BOSSUGAN,,44.7915487429,-0.0519671900335,64,,Bossugan,Bossugan,33,Gironde,75,Nouvelle-Aquitaine
33065,BOULIAC,33270,BOULIAC,,44.8160953539,-0.50487313207,65,,Bouliac,Bouliac,33,Gironde,75,Nouvelle-Aquitaine
33066,BOURDELLES,33190,BOURDELLES,,44.5585840825,0.00571308872513,66,,Bourdelles,Bourdelles,33,Gironde,75,Nouvelle-Aquitaine
33067,BOURG,33710,BOURG SUR GIRONDE,,45.0398664572,-0.547187308975,67,,Bourg,Bourg,33,Gironde,75,Nouvelle-Aquitaine
33068,BOURIDEYS,33113,BOURIDEYS,,44.3577116761,-0.47241853929,68,,Bourideys,Bourideys,33,Gironde,75,Nouvelle-Aquitaine
33069,LE BOUSCAT,33110,LE BOUSCAT,,44.8661298323,-0.602121558626,69,Le,Bouscat,Le Bouscat,33,Gironde,75,Nouvelle-Aquitaine
33070,BRACH,33480,BRACH,,45.0409201338,-0.948184300809,70,,Brach,Brach,33,Gironde,75,Nouvelle-Aquitaine
33071,BRANNE,33420,BRANNE,,44.8281852222,-0.180276839156,71,,Branne,Branne,33,Gironde,75,Nouvelle-Aquitaine
33072,BRANNENS,33124,BRANNENS,,44.5220010151,-0.152201164676,72,,Brannens,Brannens,33,Gironde,75,Nouvelle-Aquitaine
33073,BRAUD ET ST LOUIS,33820,BRAUD ET ST LOUIS,,45.2544518508,-0.646204430347,73,,Braud-et-Saint-Louis,Braud-et-Saint-Louis,33,Gironde,75,Nouvelle-Aquitaine
33074,BROUQUEYRAN,33124,BROUQUEYRAN,,44.4879887371,-0.177790358519,74,,Brouqueyran,Brouqueyran,33,Gironde,75,Nouvelle-Aquitaine
33075,BRUGES,33520,BRUGES,,44.8889469436,-0.600427664273,75,,Bruges,Bruges,33,Gironde,75,Nouvelle-Aquitaine
33076,BUDOS,33720,BUDOS,,44.5346733151,-0.399965558931,76,,Budos,Budos,33,Gironde,75,Nouvelle-Aquitaine
33077,CABANAC ET VILLAGRAINS,33650,CABANAC ET VILLAGRAINS,,44.5919178983,-0.549470960581,77,,Cabanac-et-Villagrains,Cabanac-et-Villagrains,33,Gironde,75,Nouvelle-Aquitaine
33078,CABARA,33420,CABARA,,44.8304622766,-0.161179708223,78,,Cabara,Cabara,33,Gironde,75,Nouvelle-Aquitaine
33079,CADARSAC,33750,CADARSAC,,44.8620505289,-0.283663613137,79,,Cadarsac,Cadarsac,33,Gironde,75,Nouvelle-Aquitaine
33080,CADAUJAC,33140,CADAUJAC,,44.7463102353,-0.529387094363,80,,Cadaujac,Cadaujac,33,Gironde,75,Nouvelle-Aquitaine
33081,CADILLAC,33410,CADILLAC,,44.640655028,-0.305411766484,81,,Cadillac,Cadillac,33,Gironde,75,Nouvelle-Aquitaine
33082,CADILLAC EN FRONSADAIS,33240,CADILLAC EN FRONSADAIS,,44.9623132376,-0.373407122831,82,,Cadillac-en-Fronsadais,Cadillac-en-Fronsadais,33,Gironde,75,Nouvelle-Aquitaine
33083,CAMARSAC,33750,CAMARSAC,,44.8255815323,-0.368684633032,83,,Camarsac,Camarsac,33,Gironde,75,Nouvelle-Aquitaine
33084,CAMBES,33880,CAMBES,,44.7403794347,-0.462515708887,84,,Cambes,Cambes,33,Gironde,75,Nouvelle-Aquitaine
33085,CAMBLANES ET MEYNAC,33360,CAMBLANES ET MEYNAC,,44.7664526073,-0.475866480687,85,,Camblanes-et-Meynac,Camblanes-et-Meynac,33,Gironde,75,Nouvelle-Aquitaine
33086,CAMIAC ET ST DENIS,33420,CAMIAC ET ST DENIS,,44.7961983028,-0.292259095735,86,,Camiac-et-Saint-Denis,Camiac-et-Saint-Denis,33,Gironde,75,Nouvelle-Aquitaine
33087,CAMIRAN,33190,CAMIRAN,,44.6347802416,-0.0680146855411,87,,Camiran,Camiran,33,Gironde,75,Nouvelle-Aquitaine
33088,CAMPS SUR L ISLE,33660,CAMPS SUR L ISLE,,45.016429862,-0.0351593823599,88,,Camps-sur-l'Isle,Camps-sur-l'Isle,33,Gironde,75,Nouvelle-Aquitaine
33089,CAMPUGNAN,33390,CAMPUGNAN,,45.1842358526,-0.560005135989,89,,Campugnan,Campugnan,33,Gironde,75,Nouvelle-Aquitaine
33090,CANEJAN,33610,CANEJAN,,44.7598891584,-0.65697711026,90,,Canéjan,Canéjan,33,Gironde,75,Nouvelle-Aquitaine
33091,CANTENAC,33460,CANTENAC,,45.0277700491,-0.674408400113,91,,Cantenac,Cantenac,33,Gironde,75,Nouvelle-Aquitaine
33092,CANTOIS,33760,CANTOIS,,44.6929745463,-0.21477102235,92,,Cantois,Cantois,33,Gironde,75,Nouvelle-Aquitaine
33093,CAPIAN,33550,CAPIAN,,44.716585777,-0.327839975905,93,,Capian,Capian,33,Gironde,75,Nouvelle-Aquitaine
33094,CAPLONG,33220,CAPLONG,,44.7672577691,0.150751886414,94,,Caplong,Caplong,33,Gironde,75,Nouvelle-Aquitaine
33095,CAPTIEUX,33840,CAPTIEUX,,44.2537330711,-0.28409017243,95,,Captieux,Captieux,33,Gironde,75,Nouvelle-Aquitaine
33096,CARBON BLANC,33560,CARBON BLANC,,44.9025299083,-0.501176652356,96,,Carbon-Blanc,Carbon-Blanc,33,Gironde,75,Nouvelle-Aquitaine
33097,CARCANS,33121,CARCANS,,45.0847969006,-1.04933512467,97,,Carcans,Carcans,33,Gironde,75,Nouvelle-Aquitaine
33097,CARCANS,33121,CARCANS,CARCANS PLAGE,45.0847969006,-1.04933512467,97,,Carcans,Carcans,33,Gironde,75,Nouvelle-Aquitaine
33098,CARDAN,33410,CARDAN,,44.682146358,-0.321636613083,98,,Cardan,Cardan,33,Gironde,75,Nouvelle-Aquitaine
33099,CARIGNAN DE BORDEAUX,33360,CARIGNAN DE BORDEAUX,,44.8094507679,-0.472244925995,99,,Carignan-de-Bordeaux,Carignan-de-Bordeaux,33,Gironde,75,Nouvelle-Aquitaine
33100,CARS,33390,CARS,,45.1284908523,-0.616952904879,100,,Cars,Cars,33,Gironde,75,Nouvelle-Aquitaine
33101,CARTELEGUE,33390,CARTELEGUE,,45.1912964207,-0.578370670079,101,,Cartelègue,Cartelègue,33,Gironde,75,Nouvelle-Aquitaine
33102,CASSEUIL,33190,CASSEUIL,,44.589045515,-0.118981195869,102,,Casseuil,Casseuil,33,Gironde,75,Nouvelle-Aquitaine
33103,CASTELMORON D ALBRET,33540,CASTELMORON D ALBRET,,44.6794782931,-0.0114847499916,103,,Castelmoron-d'Albret,Castelmoron-d'Albret,33,Gironde,75,Nouvelle-Aquitaine
33104,CASTELNAU DE MEDOC,33480,CASTELNAU DE MEDOC,,45.0074633162,-0.812848440368,104,,Castelnau-de-Médoc,Castelnau-de-Médoc,33,Gironde,75,Nouvelle-Aquitaine
33105,CASTELVIEL,33540,CASTELVIEL,,44.6665619029,-0.152086542362,105,,Castelviel,Castelviel,33,Gironde,75,Nouvelle-Aquitaine
33106,CASTETS EN DORTHE,33210,CASTETS EN DORTHE,,44.5558858175,-0.141301295122,106,,Castets et Castillon,Castets et Castillon,33,Gironde,75,Nouvelle-Aquitaine
33107,CASTILLON DE CASTETS,33210,CASTILLON DE CASTETS,,44.549611037,-0.109905368493,107,,Castillon-de-Castets,Castillon-de-Castets,33,Gironde,75,Nouvelle-Aquitaine
33108,CASTILLON LA BATAILLE,33350,CASTILLON LA BATAILLE,,44.8619054717,-0.0287795124609,108,,Castillon-la-Bataille,Castillon-la-Bataille,33,Gironde,75,Nouvelle-Aquitaine
33109,CASTRES GIRONDE,33640,CASTRES GIRONDE,,44.6858784947,-0.455026609065,109,,Castres-Gironde,Castres-Gironde,33,Gironde,75,Nouvelle-Aquitaine
33111,CAUDROT,33490,CAUDROT,,44.5846738826,-0.144878442229,111,,Caudrot,Caudrot,33,Gironde,75,Nouvelle-Aquitaine
33112,CAUMONT,33540,CAUMONT,,44.6957842338,-0.0109965772154,112,,Caumont,Caumont,33,Gironde,75,Nouvelle-Aquitaine
33113,CAUVIGNAC,33690,CAUVIGNAC,,44.4173147327,-0.0710548756183,113,,Cauvignac,Cauvignac,33,Gironde,75,Nouvelle-Aquitaine
33114,CAVIGNAC,33620,CAVIGNAC,,45.096401427,-0.381910358061,114,,Cavignac,Cavignac,33,Gironde,75,Nouvelle-Aquitaine
33115,CAZALIS,33113,CAZALIS,,44.3299796654,-0.393816052925,115,,Cazalis,Cazalis,33,Gironde,75,Nouvelle-Aquitaine
33116,CAZATS,33430,CAZATS,,44.473019034,-0.203200932598,116,,Cazats,Cazats,33,Gironde,75,Nouvelle-Aquitaine
33117,CAZAUGITAT,33790,CAZAUGITAT,,44.7152295174,0.0111309122135,117,,Cazaugitat,Cazaugitat,33,Gironde,75,Nouvelle-Aquitaine
33118,CENAC,33360,CENAC,,44.7826946723,-0.456684447802,118,,Cénac,Cénac,33,Gironde,75,Nouvelle-Aquitaine
33119,CENON,33150,CENON,,44.8548325665,-0.521018807062,119,,Cenon,Cenon,33,Gironde,75,Nouvelle-Aquitaine
33120,CERONS,33720,CERONS,,44.6273573941,-0.33978567872,120,,Cérons,Cérons,33,Gironde,75,Nouvelle-Aquitaine
33121,CESSAC,33760,CESSAC,,44.7415385665,-0.18530011295,121,,Cessac,Cessac,33,Gironde,75,Nouvelle-Aquitaine
33122,CESTAS,33610,CESTAS,,44.7237410966,-0.726825410115,122,,Cestas,Cestas,33,Gironde,75,Nouvelle-Aquitaine
33122,CESTAS,33610,CESTAS,GAZINET,44.7237410966,-0.726825410115,122,,Cestas,Cestas,33,Gironde,75,Nouvelle-Aquitaine
33123,CEZAC,33620,CEZAC,,45.0855145427,-0.431075960608,123,,Cézac,Cézac,33,Gironde,75,Nouvelle-Aquitaine
33124,CHAMADELLE,33230,CHAMADELLE,,45.0983652507,-0.0904776374453,124,,Chamadelle,Chamadelle,33,Gironde,75,Nouvelle-Aquitaine
33125,CISSAC MEDOC,33250,CISSAC MEDOC,,45.220435054,-0.848792413525,125,,Cissac-Médoc,Cissac-Médoc,33,Gironde,75,Nouvelle-Aquitaine
33126,CIVRAC DE BLAYE,33920,CIVRAC DE BLAYE,,45.1112083478,-0.461315031465,126,,Civrac-de-Blaye,Civrac-de-Blaye,33,Gironde,75,Nouvelle-Aquitaine
33127,CIVRAC SUR DORDOGNE,33350,CIVRAC SUR DORDOGNE,,44.830355683,-0.0779777729752,127,,Civrac-sur-Dordogne,Civrac-sur-Dordogne,33,Gironde,75,Nouvelle-Aquitaine
33128,CIVRAC EN MEDOC,33340,CIVRAC EN MEDOC,,45.3437734218,-0.916116550254,128,,Civrac-en-Médoc,Civrac-en-Médoc,33,Gironde,75,Nouvelle-Aquitaine
33129,CLEYRAC,33540,CLEYRAC,,44.7187705333,-0.0383372674584,129,,Cleyrac,Cleyrac,33,Gironde,75,Nouvelle-Aquitaine
33130,COIMERES,33210,COIMERES,,44.5004950775,-0.212633821506,130,,Coimères,Coimères,33,Gironde,75,Nouvelle-Aquitaine
33131,COIRAC,33540,COIRAC,,44.6861124973,-0.172140647906,131,,Coirac,Coirac,33,Gironde,75,Nouvelle-Aquitaine
33132,COMPS,33710,COMPS,,45.0671200196,-0.593624837355,132,,Comps,Comps,33,Gironde,75,Nouvelle-Aquitaine
33133,COUBEYRAC,33890,COUBEYRAC,,44.7835191619,0.0530247676718,133,,Coubeyrac,Coubeyrac,33,Gironde,75,Nouvelle-Aquitaine
33134,COUQUEQUES,33340,COUQUEQUES,,45.3438294429,-0.847957119677,134,,Couquèques,Couquèques,33,Gironde,75,Nouvelle-Aquitaine
33135,COURPIAC,33760,COURPIAC,,44.7547642391,-0.185776017902,135,,Courpiac,Courpiac,33,Gironde,75,Nouvelle-Aquitaine
33136,COURS DE MONSEGUR,33580,COURS DE MONSEGUR,,44.6517777176,0.127326594393,136,,Cours-de-Monségur,Cours-de-Monségur,33,Gironde,75,Nouvelle-Aquitaine
33137,COURS LES BAINS,33690,COURS LES BAINS,,44.3789499034,-0.00703420614383,137,,Cours-les-Bains,Cours-les-Bains,33,Gironde,75,Nouvelle-Aquitaine
33138,COUTRAS,33230,COUTRAS,,45.0390867207,-0.105373715279,138,,Coutras,Coutras,33,Gironde,75,Nouvelle-Aquitaine
33139,COUTURES,33580,COUTURES,,44.652450698,0.0315149165133,139,,Coutures,Coutures,33,Gironde,75,Nouvelle-Aquitaine
33140,CREON,33670,CREON,,44.770425384,-0.343606134456,140,,Créon,Créon,33,Gironde,75,Nouvelle-Aquitaine
33141,CROIGNON,33750,CROIGNON,,44.8210599121,-0.344003297739,141,,Croignon,Croignon,33,Gironde,75,Nouvelle-Aquitaine
33142,CUBNEZAIS,33620,CUBNEZAIS,,45.0728274718,-0.419591338026,142,,Cubnezais,Cubnezais,33,Gironde,75,Nouvelle-Aquitaine
33143,CUBZAC LES PONTS,33240,CUBZAC LES PONTS,,44.9696352985,-0.446132776747,143,,Cubzac-les-Ponts,Cubzac-les-Ponts,33,Gironde,75,Nouvelle-Aquitaine
33144,CUDOS,33430,CUDOS,,44.3779081527,-0.188470066299,144,,Cudos,Cudos,33,Gironde,75,Nouvelle-Aquitaine
33145,CURSAN,33670,CURSAN,,44.7957089304,-0.33460674853,145,,Cursan,Cursan,33,Gironde,75,Nouvelle-Aquitaine
33146,CUSSAC FORT MEDOC,33460,CUSSAC FORT MEDOC,,45.1199548347,-0.72618379335,146,,Cussac-Fort-Médoc,Cussac-Fort-Médoc,33,Gironde,75,Nouvelle-Aquitaine
33147,DAIGNAC,33420,DAIGNAC,,44.7959422928,-0.235707900811,147,,Daignac,Daignac,33,Gironde,75,Nouvelle-Aquitaine
33148,DARDENAC,33420,DARDENAC,,44.7835125524,-0.239650507452,148,,Dardenac,Dardenac,33,Gironde,75,Nouvelle-Aquitaine
33149,DAUBEZE,33540,DAUBEZE,,44.7103814414,-0.133821716061,149,,Daubèze,Daubèze,33,Gironde,75,Nouvelle-Aquitaine
33150,DIEULIVOL,33580,DIEULIVOL,,44.6779870781,0.103507198819,150,,Dieulivol,Dieulivol,33,Gironde,75,Nouvelle-Aquitaine
33151,DONNEZAC,33860,DONNEZAC,,45.2339831114,-0.445024799507,151,,Donnezac,Donnezac,33,Gironde,75,Nouvelle-Aquitaine
33152,DONZAC,33410,DONZAC,,44.6490849258,-0.256631952912,152,,Donzac,Donzac,33,Gironde,75,Nouvelle-Aquitaine
33153,DOULEZON,33350,DOULEZON,,44.7924343715,-0.00123843132534,153,,Doulezon,Doulezon,33,Gironde,75,Nouvelle-Aquitaine
33154,LES EGLISOTTES ET CHALAURES,33230,LES EGLISOTTES ET CHALAURES,,45.0928940224,-0.0328830508669,154,Les,Églisottes-et-Chalaures,Les Églisottes-et-Chalaures,33,Gironde,75,Nouvelle-Aquitaine
33155,ESCAUDES,33840,ESCAUDES,,44.3127064082,-0.208247896337,155,,Escaudes,Escaudes,33,Gironde,75,Nouvelle-Aquitaine
33156,ESCOUSSANS,33760,ESCOUSSANS,,44.6790444082,-0.275955218304,156,,Escoussans,Escoussans,33,Gironde,75,Nouvelle-Aquitaine
33157,ESPIET,33420,ESPIET,,44.7957699657,-0.26502782545,157,,Espiet,Espiet,33,Gironde,75,Nouvelle-Aquitaine
33158,LES ESSEINTES,33190,LES ESSEINTES,,44.6095361075,-0.0653601250201,158,Les,Esseintes,Les Esseintes,33,Gironde,75,Nouvelle-Aquitaine
33159,ETAULIERS,33820,ETAULIERS,,45.2209962068,-0.581677744347,159,,Étauliers,Étauliers,33,Gironde,75,Nouvelle-Aquitaine
33160,EYNESSE,33220,EYNESSE,,44.819717778,0.14910058165,160,,Eynesse,Eynesse,33,Gironde,75,Nouvelle-Aquitaine
33161,EYRANS,33390,EYRANS,,45.1935625916,-0.60988049368,161,,Eyrans,Eyrans,33,Gironde,75,Nouvelle-Aquitaine
33162,EYSINES,33320,EYSINES,,44.8797152377,-0.647899474657,162,,Eysines,Eysines,33,Gironde,75,Nouvelle-Aquitaine
33163,FALEYRAS,33760,FALEYRAS,,44.7638937093,-0.228692967915,163,,Faleyras,Faleyras,33,Gironde,75,Nouvelle-Aquitaine
33164,FARGUES,33210,FARGUES,,44.5353844514,-0.294974824238,164,,Fargues,Fargues,33,Gironde,75,Nouvelle-Aquitaine
33165,FARGUES ST HILAIRE,33370,FARGUES ST HILAIRE,,44.819815277,-0.439992385579,165,,Fargues-Saint-Hilaire,Fargues-Saint-Hilaire,33,Gironde,75,Nouvelle-Aquitaine
33166,LE FIEU,33230,LE FIEU,,45.0551327657,-0.0359458641255,166,Le,Fieu,Le Fieu,33,Gironde,75,Nouvelle-Aquitaine
33167,FLOIRAC,33270,FLOIRAC,,44.8338094646,-0.520646895866,167,,Floirac,Floirac,33,Gironde,75,Nouvelle-Aquitaine
33168,FLAUJAGUES,33350,FLAUJAGUES,,44.8318741437,0.0243569080199,168,,Flaujagues,Flaujagues,33,Gironde,75,Nouvelle-Aquitaine
33169,FLOUDES,33190,FLOUDES,,44.5700178825,-0.0629604195492,169,,Floudès,Floudès,33,Gironde,75,Nouvelle-Aquitaine
33170,FONTET,33190,FONTET,,44.5553150751,-0.0279073992518,170,,Fontet,Fontet,33,Gironde,75,Nouvelle-Aquitaine
33171,FOSSES ET BALEYSSAC,33190,FOSSES ET BALEYSSAC,,44.5970944275,0.0500249563956,171,,Fossès-et-Baleyssac,Fossès-et-Baleyssac,33,Gironde,75,Nouvelle-Aquitaine
33172,FOURS,33390,FOURS,,45.1747751409,-0.633340019213,172,,Fours,Fours,33,Gironde,75,Nouvelle-Aquitaine
33173,FRANCS,33570,FRANCS,,44.942978361,-0.00064276030543,173,,Francs,Francs,33,Gironde,75,Nouvelle-Aquitaine
33174,FRONSAC,33126,FRONSAC,,44.9220632004,-0.279859692474,174,,Fronsac,Fronsac,33,Gironde,75,Nouvelle-Aquitaine
33175,FRONTENAC,33760,FRONTENAC,,44.7297633138,-0.144178489946,175,,Frontenac,Frontenac,33,Gironde,75,Nouvelle-Aquitaine
33176,GABARNAC,33410,GABARNAC,,44.6165155968,-0.262725759039,176,,Gabarnac,Gabarnac,33,Gironde,75,Nouvelle-Aquitaine
33177,GAILLAN EN MEDOC,33340,GAILLAN EN MEDOC,,45.3129626409,-0.987597387066,177,,Gaillan-en-Médoc,Gaillan-en-Médoc,33,Gironde,75,Nouvelle-Aquitaine
33178,GAJAC,33430,GAJAC,,44.4363899327,-0.134945962959,178,,Gajac,Gajac,33,Gironde,75,Nouvelle-Aquitaine
33179,GALGON,33133,GALGON,,44.9904736061,-0.276833438691,179,,Galgon,Galgon,33,Gironde,75,Nouvelle-Aquitaine
33180,GANS,33430,GANS,,44.4543319425,-0.132059806791,180,,Gans,Gans,33,Gironde,75,Nouvelle-Aquitaine
33181,GARDEGAN ET TOURTIRAC,33350,GARDEGAN ET TOURTIRAC,,44.8947155186,-0.0166227907125,181,,Gardegan-et-Tourtirac,Gardegan-et-Tourtirac,33,Gironde,75,Nouvelle-Aquitaine
33182,GAURIAC,33710,GAURIAC,,45.0640707236,-0.629412029875,182,,Gauriac,Gauriac,33,Gironde,75,Nouvelle-Aquitaine
33183,GAURIAGUET,33240,GAURIAGUET,,45.0457476873,-0.395962651945,183,,Gauriaguet,Gauriaguet,33,Gironde,75,Nouvelle-Aquitaine
33184,GENERAC,33920,GENERAC,,45.1736695574,-0.545835107473,184,,Générac,Générac,33,Gironde,75,Nouvelle-Aquitaine
33185,GENISSAC,33420,GENISSAC,,44.8604999548,-0.249176861403,185,,Génissac,Génissac,33,Gironde,75,Nouvelle-Aquitaine
33186,GENSAC,33890,GENSAC,,44.7947598908,0.0843381724535,186,,Gensac,Gensac,33,Gironde,75,Nouvelle-Aquitaine
33187,GIRONDE SUR DROPT,33190,GIRONDE SUR DROPT,,44.5911619768,-0.0876331035649,187,,Gironde-sur-Dropt,Gironde-sur-Dropt,33,Gironde,75,Nouvelle-Aquitaine
33188,GISCOS,33840,GISCOS,,44.2661947283,-0.163156901321,188,,Giscos,Giscos,33,Gironde,75,Nouvelle-Aquitaine
33189,GORNAC,33540,GORNAC,,44.6632972482,-0.1839383774,189,,Gornac,Gornac,33,Gironde,75,Nouvelle-Aquitaine
33190,GOUALADE,33840,GOUALADE,,44.3174301368,-0.121216305853,190,,Goualade,Goualade,33,Gironde,75,Nouvelle-Aquitaine
33191,GOURS,33660,GOURS,,44.9980305708,0.0262519757386,191,,Gours,Gours,33,Gironde,75,Nouvelle-Aquitaine
33192,GRADIGNAN,33170,GRADIGNAN,,44.7709411201,-0.616137682116,192,,Gradignan,Gradignan,33,Gironde,75,Nouvelle-Aquitaine
33193,GRAYAN ET L HOPITAL,33590,GRAYAN ET L HOPITAL,,45.4351862635,-1.10881933696,193,,Grayan-et-l'Hôpital,Grayan-et-l'Hôpital,33,Gironde,75,Nouvelle-Aquitaine
33194,GREZILLAC,33420,GREZILLAC,,44.8168819983,-0.218779219407,194,,Grézillac,Grézillac,33,Gironde,75,Nouvelle-Aquitaine
33195,GRIGNOLS,33690,GRIGNOLS,,44.4096678184,-0.0256453531001,195,,Grignols,Grignols,33,Gironde,75,Nouvelle-Aquitaine
33196,GUILLAC,33420,GUILLAC,,44.8035781649,-0.2106680867,196,,Guillac,Guillac,33,Gironde,75,Nouvelle-Aquitaine
33197,GUILLOS,33720,GUILLOS,,44.5519470973,-0.518935394792,197,,Guillos,Guillos,33,Gironde,75,Nouvelle-Aquitaine
33198,GUITRES,33230,GUITRES,,45.0431099421,-0.186650688542,198,,Guîtres,Guîtres,33,Gironde,75,Nouvelle-Aquitaine
33199,GUJAN MESTRAS,33470,GUJAN MESTRAS,,44.5940361232,-1.08427455858,199,,Gujan-Mestras,Gujan-Mestras,33,Gironde,75,Nouvelle-Aquitaine
33200,LE HAILLAN,33185,LE HAILLAN,,44.8690837903,-0.684443512134,200,Le,Haillan,Le Haillan,33,Gironde,75,Nouvelle-Aquitaine
33201,HAUX,33550,HAUX,,44.7364762745,-0.365775340015,201,,Haux,Haux,33,Gironde,75,Nouvelle-Aquitaine
33202,HOSTENS,33125,HOSTENS,,44.4849173448,-0.656859781081,202,,Hostens,Hostens,33,Gironde,75,Nouvelle-Aquitaine
33203,HOURTIN,33990,HOURTIN,,45.1807913533,-1.06580080764,203,,Hourtin,Hourtin,33,Gironde,75,Nouvelle-Aquitaine
33204,HURE,33190,HURE,,44.537084406,-0.00499948026511,204,,Hure,Hure,33,Gironde,75,Nouvelle-Aquitaine
33205,ILLATS,33720,ILLATS,,44.6070822576,-0.380440319465,205,,Illats,Illats,33,Gironde,75,Nouvelle-Aquitaine
33206,ISLE ST GEORGES,33640,ISLE ST GEORGES,,44.726604598,-0.473681984875,206,,Isle-Saint-Georges,Isle-Saint-Georges,33,Gironde,75,Nouvelle-Aquitaine
33207,IZON,33450,IZON,,44.922024323,-0.355652161244,207,,Izon,Izon,33,Gironde,75,Nouvelle-Aquitaine
33208,JAU DIGNAC ET LOIRAC,33590,JAU DIGNAC ET LOIRAC,,45.4149833388,-0.96259528402,208,,Jau-Dignac-et-Loirac,Jau-Dignac-et-Loirac,33,Gironde,75,Nouvelle-Aquitaine
33209,JUGAZAN,33420,JUGAZAN,,44.7787058839,-0.149444168001,209,,Jugazan,Jugazan,33,Gironde,75,Nouvelle-Aquitaine
33210,JUILLAC,33890,JUILLAC,,44.8137050234,0.0536333384557,210,,Juillac,Juillac,33,Gironde,75,Nouvelle-Aquitaine
33211,LABARDE,33460,LABARDE,,45.0143357782,-0.64053807167,211,,Labarde,Labarde,33,Gironde,75,Nouvelle-Aquitaine
33212,LABESCAU,33690,LABESCAU,,44.4484673998,-0.0833418361423,212,,Labescau,Labescau,33,Gironde,75,Nouvelle-Aquitaine
33213,LA BREDE,33650,LA BREDE,,44.6801130358,-0.53691849986,213,La,Brède,La Brède,33,Gironde,75,Nouvelle-Aquitaine
33214,LACANAU,33680,LACANAU,,44.983404414,-1.10810794832,214,,Lacanau,Lacanau,33,Gironde,75,Nouvelle-Aquitaine
33214,LACANAU,33680,LACANAU,LACANAU OCEAN,44.983404414,-1.10810794832,214,,Lacanau,Lacanau,33,Gironde,75,Nouvelle-Aquitaine
33215,LADAUX,33760,LADAUX,,44.7032423261,-0.246749239195,215,,Ladaux,Ladaux,33,Gironde,75,Nouvelle-Aquitaine
33216,LADOS,33124,LADOS,,44.4687250597,-0.135994584664,216,,Lados,Lados,33,Gironde,75,Nouvelle-Aquitaine
33218,LAGORCE,33230,LAGORCE,,45.0781941491,-0.160779804845,218,,Lagorce,Lagorce,33,Gironde,75,Nouvelle-Aquitaine
33219,LA LANDE DE FRONSAC,33240,LA LANDE DE FRONSAC,,44.9839394849,-0.380437424573,219,La,Lande-de-Fronsac,La Lande-de-Fronsac,33,Gironde,75,Nouvelle-Aquitaine
33220,LAMARQUE,33460,LAMARQUE,,45.0938248209,-0.711211655735,220,,Lamarque,Lamarque,33,Gironde,75,Nouvelle-Aquitaine
33221,LAMOTHE LANDERRON,33190,LAMOTHE LANDERRON,,44.5644466731,0.0611018511899,221,,Lamothe-Landerron,Lamothe-Landerron,33,Gironde,75,Nouvelle-Aquitaine
33222,LALANDE DE POMEROL,33500,LALANDE DE POMEROL,,44.9568056428,-0.206253133932,222,,Lalande-de-Pomerol,Lalande-de-Pomerol,33,Gironde,75,Nouvelle-Aquitaine
33223,LANDERROUAT,33790,LANDERROUAT,,44.7425058294,0.160918010334,223,,Landerrouat,Landerrouat,33,Gironde,75,Nouvelle-Aquitaine
33224,LANDERROUET SUR SEGUR,33540,LANDERROUET SUR SEGUR,,44.6535808373,-0.0206044198936,224,,Landerrouet-sur-Ségur,Landerrouet-sur-Ségur,33,Gironde,75,Nouvelle-Aquitaine
33225,LANDIRAS,33720,LANDIRAS,,44.5665616702,-0.442802950502,225,,Landiras,Landiras,33,Gironde,75,Nouvelle-Aquitaine
33226,LANGOIRAN,33550,LANGOIRAN,,44.7077300213,-0.374262816088,226,,Langoiran,Langoiran,33,Gironde,75,Nouvelle-Aquitaine
33227,LANGON,33210,LANGON,,44.5376287285,-0.241841251091,227,,Langon,Langon,33,Gironde,75,Nouvelle-Aquitaine
33228,LANSAC,33710,LANSAC,,45.064093095,-0.534857408079,228,,Lansac,Lansac,33,Gironde,75,Nouvelle-Aquitaine
33229,LANTON,33138,LANTON,,44.7744744246,-0.976985580148,229,,Lanton,Lanton,33,Gironde,75,Nouvelle-Aquitaine
33229,LANTON,33138,LANTON,TAUSSAT,44.7744744246,-0.976985580148,229,,Lanton,Lanton,33,Gironde,75,Nouvelle-Aquitaine
33230,LAPOUYADE,33620,LAPOUYADE,,45.1108151595,-0.286501093262,230,,Lapouyade,Lapouyade,33,Gironde,75,Nouvelle-Aquitaine
33231,LAROQUE,33410,LAROQUE,,44.6603681588,-0.305926644431,231,,Laroque,Laroque,33,Gironde,75,Nouvelle-Aquitaine
33232,LARTIGUE,33840,LARTIGUE,,44.252892627,-0.0912924578468,232,,Lartigue,Lartigue,33,Gironde,75,Nouvelle-Aquitaine
33233,LARUSCADE,33620,LARUSCADE,,45.1237982623,-0.344529143591,233,,Laruscade,Laruscade,33,Gironde,75,Nouvelle-Aquitaine
33234,LATRESNE,33360,LATRESNE,,44.7900155493,-0.500800441782,234,,Latresne,Latresne,33,Gironde,75,Nouvelle-Aquitaine
33235,LAVAZAN,33690,LAVAZAN,,44.3913358368,-0.115846952236,235,,Lavazan,Lavazan,33,Gironde,75,Nouvelle-Aquitaine
33236,LEGE CAP FERRET,33950,LEGE CAP FERRET,,44.7609440223,-1.19293697437,236,,Lège-Cap-Ferret,Lège-Cap-Ferret,33,Gironde,75,Nouvelle-Aquitaine
33236,LEGE CAP FERRET,33950,LEGE CAP FERRET,LE CANON,44.7609440223,-1.19293697437,236,,Lège-Cap-Ferret,Lège-Cap-Ferret,33,Gironde,75,Nouvelle-Aquitaine
33236,LEGE CAP FERRET,33970,LEGE CAP FERRET,,44.7609440223,-1.19293697437,236,,Lège-Cap-Ferret,Lège-Cap-Ferret,33,Gironde,75,Nouvelle-Aquitaine
33236,LEGE CAP FERRET,33970,LEGE CAP FERRET,CAP FERRET,44.7609440223,-1.19293697437,236,,Lège-Cap-Ferret,Lège-Cap-Ferret,33,Gironde,75,Nouvelle-Aquitaine
33237,LEOGEATS,33210,LEOGEATS,,44.5068605106,-0.356393129904,237,,Léogeats,Léogeats,33,Gironde,75,Nouvelle-Aquitaine
33238,LEOGNAN,33850,LEOGNAN,,44.7191460436,-0.614707683753,238,,Léognan,Léognan,33,Gironde,75,Nouvelle-Aquitaine
33239,LERM ET MUSSET,33840,LERM ET MUSSET,,44.340461313,-0.151525582762,239,,Lerm-et-Musset,Lerm-et-Musset,33,Gironde,75,Nouvelle-Aquitaine
33240,LESPARRE MEDOC,33340,LESPARRE MEDOC,,45.2821023678,-0.935162142648,240,,Lesparre-Médoc,Lesparre-Médoc,33,Gironde,75,Nouvelle-Aquitaine
33241,LESTIAC SUR GARONNE,33550,LESTIAC SUR GARONNE,,44.6919250823,-0.37953441784,241,,Lestiac-sur-Garonne,Lestiac-sur-Garonne,33,Gironde,75,Nouvelle-Aquitaine
33242,LES LEVES ET THOUMEYRAGUES,33220,LES LEVES ET THOUMEYRAGUES,,44.7828737867,0.185216218657,242,Les,Lèves-et-Thoumeyragues,Les Lèves-et-Thoumeyragues,33,Gironde,75,Nouvelle-Aquitaine
33243,LIBOURNE,33500,LIBOURNE,,44.9130767869,-0.234709332068,243,,Libourne,Libourne,33,Gironde,75,Nouvelle-Aquitaine
33244,LIGNAN DE BAZAS,33430,LIGNAN DE BAZAS,,44.432658053,-0.276912828373,244,,Lignan-de-Bazas,Lignan-de-Bazas,33,Gironde,75,Nouvelle-Aquitaine
33245,LIGNAN DE BORDEAUX,33360,LIGNAN DE BORDEAUX,,44.7951113982,-0.429417377257,245,,Lignan-de-Bordeaux,Lignan-de-Bordeaux,33,Gironde,75,Nouvelle-Aquitaine
33246,LIGUEUX,33220,LIGUEUX,,44.7917917064,0.262913013471,246,,Ligueux,Ligueux,33,Gironde,75,Nouvelle-Aquitaine
33247,LISTRAC DE DUREZE,33790,LISTRAC DE DUREZE,,44.7607755904,0.0336581808427,247,,Listrac-de-Durèze,Listrac-de-Durèze,33,Gironde,75,Nouvelle-Aquitaine
33248,LISTRAC MEDOC,33480,LISTRAC MEDOC,,45.0683170003,-0.829346314707,248,,Listrac-Médoc,Listrac-Médoc,33,Gironde,75,Nouvelle-Aquitaine
33249,LORMONT,33310,LORMONT,,44.8765972567,-0.519774925453,249,,Lormont,Lormont,33,Gironde,75,Nouvelle-Aquitaine
33250,LOUBENS,33190,LOUBENS,,44.626465162,-0.0228626595268,250,,Loubens,Loubens,33,Gironde,75,Nouvelle-Aquitaine
33251,LOUCHATS,33125,LOUCHATS,,44.51328218,-0.57113615369,251,,Louchats,Louchats,33,Gironde,75,Nouvelle-Aquitaine
33252,LOUPES,33370,LOUPES,,44.810050839,-0.390948542123,252,,Loupes,Loupes,33,Gironde,75,Nouvelle-Aquitaine
33253,LOUPIAC,33410,LOUPIAC,,44.6282363269,-0.287216072924,253,,Loupiac,Loupiac,33,Gironde,75,Nouvelle-Aquitaine
33254,LOUPIAC DE LA REOLE,33190,LOUPIAC DE LA REOLE,,44.5412367139,-0.0440895943133,254,,Loupiac-de-la-Réole,Loupiac-de-la-Réole,33,Gironde,75,Nouvelle-Aquitaine
33255,LUCMAU,33840,LUCMAU,,44.2944038567,-0.34085237286,255,,Lucmau,Lucmau,33,Gironde,75,Nouvelle-Aquitaine
33256,LUDON MEDOC,33290,LUDON MEDOC,,44.9803239234,-0.590569121795,256,,Ludon-Médoc,Ludon-Médoc,33,Gironde,75,Nouvelle-Aquitaine
33257,LUGAIGNAC,33420,LUGAIGNAC,,44.8132322615,-0.193535633137,257,,Lugaignac,Lugaignac,33,Gironde,75,Nouvelle-Aquitaine
33258,LUGASSON,33760,LUGASSON,,44.7559772921,-0.155290604568,258,,Lugasson,Lugasson,33,Gironde,75,Nouvelle-Aquitaine
33259,LUGON ET L ILE DU CARNAY,33240,LUGON ET L ILE DU CARNAY,,44.9523815308,-0.354697747394,259,,Lugon-et-l'Île-du-Carnay,Lugon-et-l'Île-du-Carnay,33,Gironde,75,Nouvelle-Aquitaine
33260,LUGOS,33830,LUGOS,,44.4689386134,-0.915096258031,260,,Lugos,Lugos,33,Gironde,75,Nouvelle-Aquitaine
33261,LUSSAC,33570,LUSSAC,,44.9666973699,-0.0939830004604,261,,Lussac,Lussac,33,Gironde,75,Nouvelle-Aquitaine
33262,MACAU,33460,MACAU,,45.004972156,-0.612661871132,262,,Macau,Macau,33,Gironde,75,Nouvelle-Aquitaine
33263,MADIRAC,33670,MADIRAC,,44.7577269067,-0.397181377193,263,,Madirac,Madirac,33,Gironde,75,Nouvelle-Aquitaine
33264,MARANSIN,33230,MARANSIN,,45.073022085,-0.253636034832,264,,Maransin,Maransin,33,Gironde,75,Nouvelle-Aquitaine
33266,MARCENAIS,33620,MARCENAIS,,45.0595596341,-0.344873421107,266,,Marcenais,Marcenais,33,Gironde,75,Nouvelle-Aquitaine
33267,MARCILLAC,33860,MARCILLAC,,45.2741080516,-0.498919623056,267,,Marcillac,Marcillac,33,Gironde,75,Nouvelle-Aquitaine
33268,MARGAUX,33460,MARGAUX,,45.0468275892,-0.671911642441,268,,Margaux-Cantenac,Margaux-Cantenac,33,Gironde,75,Nouvelle-Aquitaine
33269,MARGUERON,33220,MARGUERON,,44.7662541658,0.255840872998,269,,Margueron,Margueron,33,Gironde,75,Nouvelle-Aquitaine
33270,MARIMBAULT,33430,MARIMBAULT,,44.4100701595,-0.258019615716,270,,Marimbault,Marimbault,33,Gironde,75,Nouvelle-Aquitaine
33271,MARIONS,33690,MARIONS,,44.3739498817,-0.0894365463696,271,,Marions,Marions,33,Gironde,75,Nouvelle-Aquitaine
33272,MARSAS,33620,MARSAS,,45.0629627303,-0.376243370882,272,,Marsas,Marsas,33,Gironde,75,Nouvelle-Aquitaine
33273,MARTIGNAS SUR JALLE,33127,MARTIGNAS SUR JALLE,,44.8450267218,-0.795499117928,273,,Martignas-sur-Jalle,Martignas-sur-Jalle,33,Gironde,75,Nouvelle-Aquitaine
33274,MARTILLAC,33650,MARTILLAC,,44.7153672874,-0.556994506438,274,,Martillac,Martillac,33,Gironde,75,Nouvelle-Aquitaine
33275,MARTRES,33760,MARTRES,,44.7073917593,-0.168195738605,275,,Martres,Martres,33,Gironde,75,Nouvelle-Aquitaine
33276,MASSEILLES,33690,MASSEILLES,,44.4025946002,-0.0592641456363,276,,Masseilles,Masseilles,33,Gironde,75,Nouvelle-Aquitaine
33277,MASSUGAS,33790,MASSUGAS,,44.7697025762,0.101172936078,277,,Massugas,Massugas,33,Gironde,75,Nouvelle-Aquitaine
33278,MAURIAC,33540,MAURIAC,,44.7460542854,-0.024421737317,278,,Mauriac,Mauriac,33,Gironde,75,Nouvelle-Aquitaine
33279,MAZERES,33210,MAZERES,,44.503806188,-0.250077983098,279,,Mazères,Mazères,33,Gironde,75,Nouvelle-Aquitaine
33280,MAZION,33390,MAZION,,45.1693324994,-0.605631141444,280,,Mazion,Mazion,33,Gironde,75,Nouvelle-Aquitaine
33281,MERIGNAC,33700,MERIGNAC,,44.8322953289,-0.681733084891,281,,Mérignac,Mérignac,33,Gironde,75,Nouvelle-Aquitaine
33282,MERIGNAS,33350,MERIGNAS,,44.785315227,-0.0842235112543,282,,Mérignas,Mérignas,33,Gironde,75,Nouvelle-Aquitaine
33283,MESTERRIEUX,33540,MESTERRIEUX,,44.6440577159,-0.0111745984691,283,,Mesterrieux,Mesterrieux,33,Gironde,75,Nouvelle-Aquitaine
33284,MIOS,33380,MIOS,,44.6179149385,-0.895043516013,284,,Mios,Mios,33,Gironde,75,Nouvelle-Aquitaine
33284,MIOS,33380,MIOS,CAUDOS,44.6179149385,-0.895043516013,284,,Mios,Mios,33,Gironde,75,Nouvelle-Aquitaine
33285,MOMBRIER,33710,MOMBRIER,,45.0776862842,-0.546789839877,285,,Mombrier,Mombrier,33,Gironde,75,Nouvelle-Aquitaine
33287,MONGAUZY,33190,MONGAUZY,,44.5716886086,0.0260082761358,287,,Mongauzy,Mongauzy,33,Gironde,75,Nouvelle-Aquitaine
33288,MONPRIMBLANC,33410,MONPRIMBLANC,,44.6308462675,-0.255341056142,288,,Monprimblanc,Monprimblanc,33,Gironde,75,Nouvelle-Aquitaine
33289,MONSEGUR,33580,MONSEGUR,,44.6462089079,0.0892904208174,289,,Monségur,Monségur,33,Gironde,75,Nouvelle-Aquitaine
33290,MONTAGNE,33570,MONTAGNE,,44.9320818849,-0.126958398554,290,,Montagne,Montagne,33,Gironde,75,Nouvelle-Aquitaine
33290,MONTAGNE,33570,MONTAGNE,PARSAC,44.9320818849,-0.126958398554,290,,Montagne,Montagne,33,Gironde,75,Nouvelle-Aquitaine
33290,MONTAGNE,33570,MONTAGNE,ST GEORGES,44.9320818849,-0.126958398554,290,,Montagne,Montagne,33,Gironde,75,Nouvelle-Aquitaine
33291,MONTAGOUDIN,33190,MONTAGOUDIN,,44.5808235972,0.0052094648538,291,,Montagoudin,Montagoudin,33,Gironde,75,Nouvelle-Aquitaine
33292,MONTIGNAC,33760,MONTIGNAC,,44.7130267691,-0.217710701052,292,,Montignac,Montignac,33,Gironde,75,Nouvelle-Aquitaine
33293,MONTUSSAN,33450,MONTUSSAN,,44.8830118626,-0.429149844535,293,,Montussan,Montussan,33,Gironde,75,Nouvelle-Aquitaine
33294,MORIZES,33190,MORIZES,,44.6140049596,-0.0976758090404,294,,Morizès,Morizès,33,Gironde,75,Nouvelle-Aquitaine
33295,MOUILLAC,33240,MOUILLAC,,45.0062622298,-0.348490908625,295,,Mouillac,Mouillac,33,Gironde,75,Nouvelle-Aquitaine
33296,MOULIETS ET VILLEMARTIN,33350,MOULIETS ET VILLEMARTIN,,44.8332902441,-0.0217836463631,296,,Mouliets-et-Villemartin,Mouliets-et-Villemartin,33,Gironde,75,Nouvelle-Aquitaine
33297,MOULIS EN MEDOC,33480,MOULIS EN MEDOC,,45.0531974317,-0.784543632595,297,,Moulis-en-Médoc,Moulis-en-Médoc,33,Gironde,75,Nouvelle-Aquitaine
33298,MOULON,33420,MOULON,,44.8575503157,-0.220590516596,298,,Moulon,Moulon,33,Gironde,75,Nouvelle-Aquitaine
33299,MOURENS,33410,MOURENS,,44.645047308,-0.211538957224,299,,Mourens,Mourens,33,Gironde,75,Nouvelle-Aquitaine
33300,NAUJAC SUR MER,33990,NAUJAC SUR MER,,45.2610002038,-1.05592808876,300,,Naujac-sur-Mer,Naujac-sur-Mer,33,Gironde,75,Nouvelle-Aquitaine
33301,NAUJAN ET POSTIAC,33420,NAUJAN ET POSTIAC,,44.7877867597,-0.184238478251,301,,Naujan-et-Postiac,Naujan-et-Postiac,33,Gironde,75,Nouvelle-Aquitaine
33302,NEAC,33500,NEAC,,44.9434879811,-0.177921280134,302,,Néac,Néac,33,Gironde,75,Nouvelle-Aquitaine
33303,NERIGEAN,33750,NERIGEAN,,44.843131883,-0.284955496145,303,,Nérigean,Nérigean,33,Gironde,75,Nouvelle-Aquitaine
33304,NEUFFONS,33580,NEUFFONS,,44.6479485924,0.0115623600622,304,,Neuffons,Neuffons,33,Gironde,75,Nouvelle-Aquitaine
33305,LE NIZAN,33430,LE NIZAN,,44.4702093406,-0.280941561777,305,Le,Nizan,Le Nizan,33,Gironde,75,Nouvelle-Aquitaine
33306,NOAILLAC,33190,NOAILLAC,,44.517026239,-0.0232977040899,306,,Noaillac,Noaillac,33,Gironde,75,Nouvelle-Aquitaine
33307,NOAILLAN,33730,NOAILLAN,,44.4800097936,-0.360121243141,307,,Noaillan,Noaillan,33,Gironde,75,Nouvelle-Aquitaine
33308,OMET,33410,OMET,,44.6554817395,-0.286590923535,308,,Omet,Omet,33,Gironde,75,Nouvelle-Aquitaine
33309,ORDONNAC,33340,ORDONNAC,,45.3073708232,-0.843285688562,309,,Ordonnac,Ordonnac,33,Gironde,75,Nouvelle-Aquitaine
33310,ORIGNE,33113,ORIGNE,,44.5038761051,-0.509904943904,310,,Origne,Origne,33,Gironde,75,Nouvelle-Aquitaine
33311,PAILLET,33550,PAILLET,,44.6854994592,-0.361810561695,311,,Paillet,Paillet,33,Gironde,75,Nouvelle-Aquitaine
33312,PAREMPUYRE,33290,PAREMPUYRE,,44.9541509661,-0.580766578801,312,,Parempuyre,Parempuyre,33,Gironde,75,Nouvelle-Aquitaine
33314,PAUILLAC,33250,PAUILLAC,,45.1990068041,-0.758200153521,314,,Pauillac,Pauillac,33,Gironde,75,Nouvelle-Aquitaine
33315,LES PEINTURES,33230,LES PEINTURES,,45.0651284509,-0.0878121434236,315,Les,Peintures,Les Peintures,33,Gironde,75,Nouvelle-Aquitaine
33316,PELLEGRUE,33790,PELLEGRUE,,44.7382200976,0.0952560866567,316,,Pellegrue,Pellegrue,33,Gironde,75,Nouvelle-Aquitaine
33317,PERISSAC,33240,PERISSAC,,45.0242960787,-0.316735068823,317,,Périssac,Périssac,33,Gironde,75,Nouvelle-Aquitaine
33318,PESSAC,33600,PESSAC,,44.7915990521,-0.676303166277,318,,Pessac,Pessac,33,Gironde,75,Nouvelle-Aquitaine
33319,PESSAC SUR DORDOGNE,33890,PESSAC SUR DORDOGNE,,44.8179368546,0.0937252585904,319,,Pessac-sur-Dordogne,Pessac-sur-Dordogne,33,Gironde,75,Nouvelle-Aquitaine
33320,PETIT PALAIS ET CORNEMPS,33570,PETIT PALAIS ET CORNEMPS,,44.9814131472,-0.0564567492299,320,,Petit-Palais-et-Cornemps,Petit-Palais-et-Cornemps,33,Gironde,75,Nouvelle-Aquitaine
33321,PEUJARD,33240,PEUJARD,,45.0484108625,-0.431387189025,321,,Peujard,Peujard,33,Gironde,75,Nouvelle-Aquitaine
33322,LE PIAN MEDOC,33290,LE PIAN MEDOC,,44.9562692622,-0.670672181899,322,Le,Pian-Médoc,Le Pian-Médoc,33,Gironde,75,Nouvelle-Aquitaine
33323,LE PIAN SUR GARONNE,33490,LE PIAN SUR GARONNE,,44.5821324623,-0.216934173908,323,Le,Pian-sur-Garonne,Le Pian-sur-Garonne,33,Gironde,75,Nouvelle-Aquitaine
33324,PINEUILH,33220,PINEUILH,,44.8236972399,0.233443926253,324,,Pineuilh,Pineuilh,33,Gironde,75,Nouvelle-Aquitaine
33325,PLASSAC,33390,PLASSAC,,45.097903949,-0.651002369546,325,,Plassac,Plassac,33,Gironde,75,Nouvelle-Aquitaine
33326,PLEINE SELVE,33820,PLEINE SELVE,,45.3271088387,-0.582258150086,326,,Pleine-Selve,Pleine-Selve,33,Gironde,75,Nouvelle-Aquitaine
33327,PODENSAC,33720,PODENSAC,,44.6459179766,-0.358722392268,327,,Podensac,Podensac,33,Gironde,75,Nouvelle-Aquitaine
33328,POMEROL,33500,POMEROL,,44.9313091859,-0.204692390776,328,,Pomerol,Pomerol,33,Gironde,75,Nouvelle-Aquitaine
33329,POMPEJAC,33730,POMPEJAC,,44.3995823965,-0.298890658986,329,,Pompéjac,Pompéjac,33,Gironde,75,Nouvelle-Aquitaine
33330,POMPIGNAC,33370,POMPIGNAC,,44.8541963522,-0.431857978579,330,,Pompignac,Pompignac,33,Gironde,75,Nouvelle-Aquitaine
33331,PONDAURAT,33190,PONDAURAT,,44.5317947567,-0.0798688844488,331,,Pondaurat,Pondaurat,33,Gironde,75,Nouvelle-Aquitaine
33332,PORCHERES,33660,PORCHERES,,45.0370123077,0.00132456186091,332,,Porchères,Porchères,33,Gironde,75,Nouvelle-Aquitaine
33333,LE PORGE,33680,LE PORGE,,44.867037132,-1.14092605911,333,Le,Porge,Le Porge,33,Gironde,75,Nouvelle-Aquitaine
33334,PORTETS,33640,PORTETS,,44.6812515729,-0.426367189428,334,,Portets,Portets,33,Gironde,75,Nouvelle-Aquitaine
33335,LE POUT,33670,LE POUT,,44.8044080843,-0.357222869901,335,Le,Pout,Le Pout,33,Gironde,75,Nouvelle-Aquitaine
33336,PRECHAC,33730,PRECHAC,,44.3872999991,-0.36765609959,336,,Préchac,Préchac,33,Gironde,75,Nouvelle-Aquitaine
33337,PREIGNAC,33210,PREIGNAC,,44.5701114534,-0.309617244092,337,,Preignac,Preignac,33,Gironde,75,Nouvelle-Aquitaine
33338,PRIGNAC EN MEDOC,33340,PRIGNAC EN MEDOC,,45.3185304134,-0.906635025936,338,,Prignac-en-Médoc,Prignac-en-Médoc,33,Gironde,75,Nouvelle-Aquitaine
33339,PRIGNAC ET MARCAMPS,33710,PRIGNAC ET MARCAMPS,,45.0258741295,-0.495032335028,339,,Prignac-et-Marcamps,Prignac-et-Marcamps,33,Gironde,75,Nouvelle-Aquitaine
33341,PUGNAC,33710,PUGNAC,,45.0835194414,-0.498613967058,341,,Pugnac,Pugnac,33,Gironde,75,Nouvelle-Aquitaine
33341,PUGNAC,33710,PUGNAC,LAFOSSE,45.0835194414,-0.498613967058,341,,Pugnac,Pugnac,33,Gironde,75,Nouvelle-Aquitaine
33342,PUISSEGUIN,33570,PUISSEGUIN,,44.9327886127,-0.0632513144683,342,,Puisseguin,Puisseguin,33,Gironde,75,Nouvelle-Aquitaine
33342,PUISSEGUIN,33570,PUISSEGUIN,MONBADON,44.9327886127,-0.0632513144683,342,,Puisseguin,Puisseguin,33,Gironde,75,Nouvelle-Aquitaine
33343,PUJOLS SUR CIRON,33210,PUJOLS SUR CIRON,,44.5707365487,-0.353176378763,343,,Pujols-sur-Ciron,Pujols-sur-Ciron,33,Gironde,75,Nouvelle-Aquitaine
33344,PUJOLS,33350,PUJOLS,,44.806319089,-0.0328874933658,344,,Pujols,Pujols,33,Gironde,75,Nouvelle-Aquitaine
33345,LE PUY,33580,LE PUY,,44.6595382268,0.0603849385467,345,Le,Puy,Le Puy,33,Gironde,75,Nouvelle-Aquitaine
33346,PUYBARBAN,33190,PUYBARBAN,,44.5479161456,-0.069264224566,346,,Puybarban,Puybarban,33,Gironde,75,Nouvelle-Aquitaine
33347,PUYNORMAND,33660,PUYNORMAND,,44.9814938189,-0.00176034544232,347,,Puynormand,Puynormand,33,Gironde,75,Nouvelle-Aquitaine
33348,QUEYRAC,33340,QUEYRAC,,45.3608901239,-1.00082649674,348,,Queyrac,Queyrac,33,Gironde,75,Nouvelle-Aquitaine
33349,QUINSAC,33360,QUINSAC,,44.7509609178,-0.490697647694,349,,Quinsac,Quinsac,33,Gironde,75,Nouvelle-Aquitaine
33350,RAUZAN,33420,RAUZAN,,44.7824119957,-0.131058944697,350,,Rauzan,Rauzan,33,Gironde,75,Nouvelle-Aquitaine
33351,REIGNAC,33860,REIGNAC,,45.2294125934,-0.51659465108,351,,Reignac,Reignac,33,Gironde,75,Nouvelle-Aquitaine
33352,LA REOLE,33190,LA REOLE,,44.5860904495,-0.042370404021,352,La,Réole,La Réole,33,Gironde,75,Nouvelle-Aquitaine
33353,RIMONS,33580,RIMONS,,44.6744095264,0.0144612295759,353,,Rimons,Rimons,33,Gironde,75,Nouvelle-Aquitaine
33354,RIOCAUD,33220,RIOCAUD,,44.7507112352,0.203198024954,354,,Riocaud,Riocaud,33,Gironde,75,Nouvelle-Aquitaine
33355,RIONS,33410,RIONS,,44.6684320932,-0.336471805521,355,,Rions,Rions,33,Gironde,75,Nouvelle-Aquitaine
33356,LA RIVIERE,33126,LA RIVIERE,,44.9377237217,-0.316177006649,356,La,Rivière,La Rivière,33,Gironde,75,Nouvelle-Aquitaine
33357,ROAILLAN,33210,ROAILLAN,,44.5054989017,-0.285142833143,357,,Roaillan,Roaillan,33,Gironde,75,Nouvelle-Aquitaine
33358,ROMAGNE,33760,ROMAGNE,,44.7639270453,-0.204211440747,358,,Romagne,Romagne,33,Gironde,75,Nouvelle-Aquitaine
33359,ROQUEBRUNE,33580,ROQUEBRUNE,,44.6257630925,0.0206819226945,359,,Roquebrune,Roquebrune,33,Gironde,75,Nouvelle-Aquitaine
33360,LA ROQUILLE,33220,LA ROQUILLE,,44.7853020324,0.232048719283,360,La,Roquille,La Roquille,33,Gironde,75,Nouvelle-Aquitaine
33361,RUCH,33350,RUCH,,44.773827226,-0.0332957025403,361,,Ruch,Ruch,33,Gironde,75,Nouvelle-Aquitaine
33362,SABLONS,33910,SABLONS,,45.0243699436,-0.182863354079,362,,Sablons,Sablons,33,Gironde,75,Nouvelle-Aquitaine
33363,SADIRAC,33670,SADIRAC,,44.7827506267,-0.38947986904,363,,Sadirac,Sadirac,33,Gironde,75,Nouvelle-Aquitaine
33364,SAILLANS,33141,SAILLANS,,44.9570462832,-0.271169418164,364,,Saillans,Saillans,33,Gironde,75,Nouvelle-Aquitaine
33365,ST AIGNAN,33126,ST AIGNAN,,44.9475875341,-0.297024133452,365,,Saint-Aignan,Saint-Aignan,33,Gironde,75,Nouvelle-Aquitaine
33366,ST ANDRE DE CUBZAC,33240,ST ANDRE DE CUBZAC,,44.9954623723,-0.43596742679,366,,Saint-André-de-Cubzac,Saint-André-de-Cubzac,33,Gironde,75,Nouvelle-Aquitaine
33367,ST ANDRE DU BOIS,33490,ST ANDRE DU BOIS,,44.6072494865,-0.187082711636,367,,Saint-André-du-Bois,Saint-André-du-Bois,33,Gironde,75,Nouvelle-Aquitaine
33369,ST ANDRE ET APPELLES,33220,ST ANDRE ET APPELLES,,44.8105147656,0.199159623027,369,,Saint-André-et-Appelles,Saint-André-et-Appelles,33,Gironde,75,Nouvelle-Aquitaine
33370,ST ANDRONY,33390,ST ANDRONY,,45.2014736682,-0.686129899592,370,,Saint-Androny,Saint-Androny,33,Gironde,75,Nouvelle-Aquitaine
33372,ST ANTOINE DU QUEYRET,33790,ST ANTOINE DU QUEYRET,,44.7623588466,0.00922484290059,372,,Saint-Antoine-du-Queyret,Saint-Antoine-du-Queyret,33,Gironde,75,Nouvelle-Aquitaine
33373,ST ANTOINE SUR L ISLE,33660,ST ANTOINE SUR L ISLE,,45.0470360982,0.0461113956176,373,,Saint-Antoine-sur-l'Isle,Saint-Antoine-sur-l'Isle,33,Gironde,75,Nouvelle-Aquitaine
33374,ST AUBIN DE BLAYE,33820,ST AUBIN DE BLAYE,,45.2588194568,-0.572897144494,374,,Saint-Aubin-de-Blaye,Saint-Aubin-de-Blaye,33,Gironde,75,Nouvelle-Aquitaine
33375,ST AUBIN DE BRANNE,33420,ST AUBIN DE BRANNE,,44.8109316671,-0.168129040807,375,,Saint-Aubin-de-Branne,Saint-Aubin-de-Branne,33,Gironde,75,Nouvelle-Aquitaine
33376,ST AUBIN DE MEDOC,33160,ST AUBIN DE MEDOC,,44.9333497941,-0.748518197327,376,,Saint-Aubin-de-Médoc,Saint-Aubin-de-Médoc,33,Gironde,75,Nouvelle-Aquitaine
33377,ST AVIT DE SOULEGE,33220,ST AVIT DE SOULEGE,,44.8116266609,0.120263165999,377,,Saint-Avit-de-Soulège,Saint-Avit-de-Soulège,33,Gironde,75,Nouvelle-Aquitaine
33378,ST AVIT ST NAZAIRE,33220,ST AVIT ST NAZAIRE,,44.8478527826,0.274744250182,378,,Saint-Avit-Saint-Nazaire,Saint-Avit-Saint-Nazaire,33,Gironde,75,Nouvelle-Aquitaine
33378,ST AVIT ST NAZAIRE,33220,ST AVIT ST NAZAIRE,ST NAZAIRE,44.8478527826,0.274744250182,378,,Saint-Avit-Saint-Nazaire,Saint-Avit-Saint-Nazaire,33,Gironde,75,Nouvelle-Aquitaine
33379,ST BRICE,33540,ST BRICE,,44.6937696181,-0.141055094504,379,,Saint-Brice,Saint-Brice,33,Gironde,75,Nouvelle-Aquitaine
33380,ST CAPRAIS DE BLAYE,33820,ST CAPRAIS DE BLAYE,,45.2880354799,-0.566771692266,380,,Saint-Caprais-de-Blaye,Saint-Caprais-de-Blaye,33,Gironde,75,Nouvelle-Aquitaine
33381,ST CAPRAIS DE BORDEAUX,33880,ST CAPRAIS DE BORDEAUX,,44.7540788366,-0.427673567177,381,,Saint-Caprais-de-Bordeaux,Saint-Caprais-de-Bordeaux,33,Gironde,75,Nouvelle-Aquitaine
33382,ST CHRISTOLY DE BLAYE,33920,ST CHRISTOLY DE BLAYE,,45.1439978714,-0.496519035518,382,,Saint-Christoly-de-Blaye,Saint-Christoly-de-Blaye,33,Gironde,75,Nouvelle-Aquitaine
33383,ST CHRISTOLY MEDOC,33340,ST CHRISTOLY MEDOC,,45.3574015951,-0.834464734201,383,,Saint-Christoly-Médoc,Saint-Christoly-Médoc,33,Gironde,75,Nouvelle-Aquitaine
33384,ST CHRISTOPHE DES BARDES,33330,ST CHRISTOPHE DES BARDES,,44.8971613275,-0.12024751685,384,,Saint-Christophe-des-Bardes,Saint-Christophe-des-Bardes,33,Gironde,75,Nouvelle-Aquitaine
33385,ST CHRISTOPHE DE DOUBLE,33230,ST CHRISTOPHE DE DOUBLE,,45.0834847193,0.0231131592109,385,,Saint-Christophe-de-Double,Saint-Christophe-de-Double,33,Gironde,75,Nouvelle-Aquitaine
33386,ST CIBARD,33570,ST CIBARD,,44.9384422603,-0.0230796373172,386,,Saint-Cibard,Saint-Cibard,33,Gironde,75,Nouvelle-Aquitaine
33387,ST CIERS D ABZAC,33910,ST CIERS D ABZAC,,45.0354269369,-0.288732170922,387,,Saint-Ciers-d'Abzac,Saint-Ciers-d'Abzac,33,Gironde,75,Nouvelle-Aquitaine
33388,ST CIERS DE CANESSE,33710,ST CIERS DE CANESSE,,45.0873767558,-0.602217523862,388,,Saint-Ciers-de-Canesse,Saint-Ciers-de-Canesse,33,Gironde,75,Nouvelle-Aquitaine
33389,ST CIERS SUR GIRONDE,33820,ST CIERS SUR GIRONDE,,45.2980782445,-0.648058188533,389,,Saint-Ciers-sur-Gironde,Saint-Ciers-sur-Gironde,33,Gironde,75,Nouvelle-Aquitaine
33390,STE COLOMBE,33350,STE COLOMBE,,44.8789588977,-0.0634971172004,390,,Sainte-Colombe,Sainte-Colombe,33,Gironde,75,Nouvelle-Aquitaine
33391,ST COME,33430,ST COME,,44.4249739922,-0.174304293799,391,,Saint-Côme,Saint-Côme,33,Gironde,75,Nouvelle-Aquitaine
33392,STE CROIX DU MONT,33410,STE CROIX DU MONT,,44.5979961934,-0.272147017146,392,,Sainte-Croix-du-Mont,Sainte-Croix-du-Mont,33,Gironde,75,Nouvelle-Aquitaine
33393,ST DENIS DE PILE,33910,ST DENIS DE PILE,,44.9879017706,-0.183958862029,393,,Saint-Denis-de-Pile,Saint-Denis-de-Pile,33,Gironde,75,Nouvelle-Aquitaine
33394,ST EMILION,33330,ST EMILION,,44.8999911844,-0.169955105989,394,,Saint-Émilion,Saint-Émilion,33,Gironde,75,Nouvelle-Aquitaine
33395,ST ESTEPHE,33180,ST ESTEPHE,,45.2509775886,-0.781613837384,395,,Saint-Estèphe,Saint-Estèphe,33,Gironde,75,Nouvelle-Aquitaine
33396,ST ETIENNE DE LISSE,33330,ST ETIENNE DE LISSE,,44.880960315,-0.0941588854927,396,,Saint-Étienne-de-Lisse,Saint-Étienne-de-Lisse,33,Gironde,75,Nouvelle-Aquitaine
33397,STE EULALIE,33560,STE EULALIE,,44.9016662079,-0.479039252599,397,,Sainte-Eulalie,Sainte-Eulalie,33,Gironde,75,Nouvelle-Aquitaine
33398,ST EXUPERY,33190,ST EXUPERY,,44.6331784012,-0.100374502546,398,,Saint-Exupéry,Saint-Exupéry,33,Gironde,75,Nouvelle-Aquitaine
33399,ST FELIX DE FONCAUDE,33540,ST FELIX DE FONCAUDE,,44.6525452619,-0.104673473731,399,,Saint-Félix-de-Foncaude,Saint-Félix-de-Foncaude,33,Gironde,75,Nouvelle-Aquitaine
33400,ST FERME,33580,ST FERME,,44.6953149671,0.0615909665111,400,,Saint-Ferme,Saint-Ferme,33,Gironde,75,Nouvelle-Aquitaine
33401,STE FLORENCE,33350,STE FLORENCE,,44.8173313426,-0.0924823027936,401,,Sainte-Florence,Sainte-Florence,33,Gironde,75,Nouvelle-Aquitaine
33402,STE FOY LA GRANDE,33220,STE FOY LA GRANDE,,44.8408428381,0.217540501109,402,,Sainte-Foy-la-Grande,Sainte-Foy-la-Grande,33,Gironde,75,Nouvelle-Aquitaine
33403,STE FOY LA LONGUE,33490,STE FOY LA LONGUE,,44.615535673,-0.142468985592,403,,Sainte-Foy-la-Longue,Sainte-Foy-la-Longue,33,Gironde,75,Nouvelle-Aquitaine
33404,STE GEMME,33580,STE GEMME,,44.6180963158,0.0736487066839,404,,Sainte-Gemme,Sainte-Gemme,33,Gironde,75,Nouvelle-Aquitaine
33405,ST GENES DE BLAYE,33390,ST GENES DE BLAYE,,45.1613439952,-0.668437136605,405,,Saint-Genès-de-Blaye,Saint-Genès-de-Blaye,33,Gironde,75,Nouvelle-Aquitaine
33406,ST GENES DE CASTILLON,33350,ST GENES DE CASTILLON,,44.899794746,-0.0641790008053,406,,Saint-Genès-de-Castillon,Saint-Genès-de-Castillon,33,Gironde,75,Nouvelle-Aquitaine
33407,ST GENES DE FRONSAC,33240,ST GENES DE FRONSAC,,45.0267396245,-0.345881901281,407,,Saint-Genès-de-Fronsac,Saint-Genès-de-Fronsac,33,Gironde,75,Nouvelle-Aquitaine
33408,ST GENES DE LOMBAUD,33670,ST GENES DE LOMBAUD,,44.7540640198,-0.378689815135,408,,Saint-Genès-de-Lombaud,Saint-Genès-de-Lombaud,33,Gironde,75,Nouvelle-Aquitaine
33409,ST GENIS DU BOIS,33760,ST GENIS DU BOIS,,44.6998143396,-0.183471650836,409,,Saint-Genis-du-Bois,Saint-Genis-du-Bois,33,Gironde,75,Nouvelle-Aquitaine
33411,ST GERMAIN DE GRAVE,33490,ST GERMAIN DE GRAVE,,44.6234269456,-0.217898533174,411,,Saint-Germain-de-Grave,Saint-Germain-de-Grave,33,Gironde,75,Nouvelle-Aquitaine
33412,ST GERMAIN D ESTEUIL,33340,ST GERMAIN D ESTEUIL,,45.2552033922,-0.895235523992,412,,Saint-Germain-d'Esteuil,Saint-Germain-d'Esteuil,33,Gironde,75,Nouvelle-Aquitaine
33413,ST GERMAIN DU PUCH,33750,ST GERMAIN DU PUCH,,44.8518771746,-0.334057269081,413,,Saint-Germain-du-Puch,Saint-Germain-du-Puch,33,Gironde,75,Nouvelle-Aquitaine
33414,ST GERMAIN DE LA RIVIERE,33240,ST GERMAIN DE LA RIVIERE,,44.9440812975,-0.330721356984,414,,Saint-Germain-de-la-Rivière,Saint-Germain-de-la-Rivière,33,Gironde,75,Nouvelle-Aquitaine
33415,ST GERVAIS,33240,ST GERVAIS,,45.0168841442,-0.466644365612,415,,Saint-Gervais,Saint-Gervais,33,Gironde,75,Nouvelle-Aquitaine
33416,ST GIRONS D AIGUEVIVES,33920,ST GIRONS D AIGUEVIVES,,45.1466416615,-0.538156488225,416,,Saint-Girons-d'Aiguevives,Saint-Girons-d'Aiguevives,33,Gironde,75,Nouvelle-Aquitaine
33417,STE HELENE,33480,STE HELENE,,44.9726615806,-0.913525242529,417,,Sainte-Hélène,Sainte-Hélène,33,Gironde,75,Nouvelle-Aquitaine
33418,ST HILAIRE DE LA NOAILLE,33190,ST HILAIRE DE LA NOAILLE,,44.6031750494,0.00530764786777,418,,Saint-Hilaire-de-la-Noaille,Saint-Hilaire-de-la-Noaille,33,Gironde,75,Nouvelle-Aquitaine
33419,ST HILAIRE DU BOIS,33540,ST HILAIRE DU BOIS,,44.6612294768,-0.0744756650033,419,,Saint-Hilaire-du-Bois,Saint-Hilaire-du-Bois,33,Gironde,75,Nouvelle-Aquitaine
33420,ST HIPPOLYTE,33330,ST HIPPOLYTE,,44.8740781768,-0.120995887254,420,,Saint-Hippolyte,Saint-Hippolyte,33,Gironde,75,Nouvelle-Aquitaine
33421,ST JEAN DE BLAIGNAC,33420,ST JEAN DE BLAIGNAC,,44.8093814613,-0.132286748685,421,,Saint-Jean-de-Blaignac,Saint-Jean-de-Blaignac,33,Gironde,75,Nouvelle-Aquitaine
33422,ST JEAN D ILLAC,33127,ST JEAN D ILLAC,,44.7934010703,-0.819193904642,422,,Saint-Jean-d'Illac,Saint-Jean-d'Illac,33,Gironde,75,Nouvelle-Aquitaine
33423,ST JULIEN BEYCHEVELLE,33250,ST JULIEN BEYCHEVELLE,,45.1572384693,-0.737068946375,423,,Saint-Julien-Beychevelle,Saint-Julien-Beychevelle,33,Gironde,75,Nouvelle-Aquitaine
33424,ST LAURENT MEDOC,33112,ST LAURENT MEDOC,,45.1404040788,-0.862340576235,424,,Saint-Laurent-Médoc,Saint-Laurent-Médoc,33,Gironde,75,Nouvelle-Aquitaine
33425,ST LAURENT D ARCE,33240,ST LAURENT D ARCE,,45.0430455574,-0.465863416208,425,,Saint-Laurent-d'Arce,Saint-Laurent-d'Arce,33,Gironde,75,Nouvelle-Aquitaine
33426,ST LAURENT DES COMBES,33330,ST LAURENT DES COMBES,,44.8731298275,-0.138268824153,426,,Saint-Laurent-des-Combes,Saint-Laurent-des-Combes,33,Gironde,75,Nouvelle-Aquitaine
33427,ST LAURENT DU BOIS,33540,ST LAURENT DU BOIS,,44.6406776819,-0.137803248191,427,,Saint-Laurent-du-Bois,Saint-Laurent-du-Bois,33,Gironde,75,Nouvelle-Aquitaine
33428,ST LAURENT DU PLAN,33190,ST LAURENT DU PLAN,,44.6255670089,-0.117013007902,428,,Saint-Laurent-du-Plan,Saint-Laurent-du-Plan,33,Gironde,75,Nouvelle-Aquitaine
33429,ST LEGER DE BALSON,33113,ST LEGER DE BALSON,,44.429710887,-0.441424293388,429,,Saint-Léger-de-Balson,Saint-Léger-de-Balson,33,Gironde,75,Nouvelle-Aquitaine
33431,ST LEON,33670,ST LEON,,44.7665724734,-0.276430909291,431,,Saint-Léon,Saint-Léon,33,Gironde,75,Nouvelle-Aquitaine
33432,ST LOUBERT,33210,ST LOUBERT,,44.5533634868,-0.168857964399,432,,Saint-Loubert,Saint-Loubert,33,Gironde,75,Nouvelle-Aquitaine
33433,ST LOUBES,33450,ST LOUBES,,44.9220849836,-0.430829260925,433,,Saint-Loubès,Saint-Loubès,33,Gironde,75,Nouvelle-Aquitaine
33434,ST LOUIS DE MONTFERRAND,33440,ST LOUIS DE MONTFERRAND,,44.9582743614,-0.533092800544,434,,Saint-Louis-de-Montferrand,Saint-Louis-de-Montferrand,33,Gironde,75,Nouvelle-Aquitaine
33435,ST MACAIRE,33490,ST MACAIRE,,44.5631655827,-0.232549072695,435,,Saint-Macaire,Saint-Macaire,33,Gironde,75,Nouvelle-Aquitaine
33436,ST MAGNE,33125,ST MAGNE,,44.5545369985,-0.658226760679,436,,Saint-Magne,Saint-Magne,33,Gironde,75,Nouvelle-Aquitaine
33437,ST MAGNE DE CASTILLON,33350,ST MAGNE DE CASTILLON,,44.8564111002,-0.0738465578343,437,,Saint-Magne-de-Castillon,Saint-Magne-de-Castillon,33,Gironde,75,Nouvelle-Aquitaine
33438,ST MAIXANT,33490,ST MAIXANT,,44.5770246409,-0.246142662745,438,,Saint-Maixant,Saint-Maixant,33,Gironde,75,Nouvelle-Aquitaine
33439,ST MARIENS,33620,ST MARIENS,,45.1211853951,-0.40505275637,439,,Saint-Mariens,Saint-Mariens,33,Gironde,75,Nouvelle-Aquitaine
33440,ST MARTIAL,33490,ST MARTIAL,,44.6326268868,-0.177651348773,440,,Saint-Martial,Saint-Martial,33,Gironde,75,Nouvelle-Aquitaine
33441,ST MARTIN LACAUSSADE,33390,ST MARTIN LACAUSSADE,,45.142779014,-0.642629661548,441,,Saint-Martin-Lacaussade,Saint-Martin-Lacaussade,33,Gironde,75,Nouvelle-Aquitaine
33442,ST MARTIN DE LAYE,33910,ST MARTIN DE LAYE,,45.0337147655,-0.232425034493,442,,Saint-Martin-de-Laye,Saint-Martin-de-Laye,33,Gironde,75,Nouvelle-Aquitaine
33443,ST MARTIN DE LERM,33540,ST MARTIN DE LERM,,44.6483155424,-0.0445021320501,443,,Saint-Martin-de-Lerm,Saint-Martin-de-Lerm,33,Gironde,75,Nouvelle-Aquitaine
33444,ST MARTIN DE SESCAS,33490,ST MARTIN DE SESCAS,,44.5868737063,-0.165403223369,444,,Saint-Martin-de-Sescas,Saint-Martin-de-Sescas,33,Gironde,75,Nouvelle-Aquitaine
33445,ST MARTIN DU BOIS,33910,ST MARTIN DU BOIS,,45.0202297197,-0.259099339397,445,,Saint-Martin-du-Bois,Saint-Martin-du-Bois,33,Gironde,75,Nouvelle-Aquitaine
33446,ST MARTIN DU PUY,33540,ST MARTIN DU PUY,,44.6732953187,-0.0351529488141,446,,Saint-Martin-du-Puy,Saint-Martin-du-Puy,33,Gironde,75,Nouvelle-Aquitaine
33447,ST MEDARD DE GUIZIERES,33230,ST MEDARD DE GUIZIERES,,45.0103439546,-0.0695991585994,447,,Saint-Médard-de-Guizières,Saint-Médard-de-Guizières,33,Gironde,75,Nouvelle-Aquitaine
33448,ST MEDARD D EYRANS,33650,ST MEDARD D EYRANS,,44.7113116565,-0.509379859547,448,,Saint-Médard-d'Eyrans,Saint-Médard-d'Eyrans,33,Gironde,75,Nouvelle-Aquitaine
33449,ST MEDARD EN JALLES,33160,ST MEDARD EN JALLES,,44.8832620816,-0.784239883546,449,,Saint-Médard-en-Jalles,Saint-Médard-en-Jalles,33,Gironde,75,Nouvelle-Aquitaine
33450,ST MICHEL DE CASTELNAU,33840,ST MICHEL DE CASTELNAU,,44.289942196,-0.0867837653969,450,,Saint-Michel-de-Castelnau,Saint-Michel-de-Castelnau,33,Gironde,75,Nouvelle-Aquitaine
33451,ST MICHEL DE FRONSAC,33126,ST MICHEL DE FRONSAC,,44.9260021181,-0.303356529349,451,,Saint-Michel-de-Fronsac,Saint-Michel-de-Fronsac,33,Gironde,75,Nouvelle-Aquitaine
33452,ST MICHEL DE RIEUFRET,33720,ST MICHEL DE RIEUFRET,,44.6247110153,-0.441646554916,452,,Saint-Michel-de-Rieufret,Saint-Michel-de-Rieufret,33,Gironde,75,Nouvelle-Aquitaine
33453,ST MICHEL DE LAPUJADE,33190,ST MICHEL DE LAPUJADE,,44.587647568,0.0785219724491,453,,Saint-Michel-de-Lapujade,Saint-Michel-de-Lapujade,33,Gironde,75,Nouvelle-Aquitaine
33454,ST MORILLON,33650,ST MORILLON,,44.6420351361,-0.519894969485,454,,Saint-Morillon,Saint-Morillon,33,Gironde,75,Nouvelle-Aquitaine
33456,ST PALAIS,33820,ST PALAIS,,45.3095196447,-0.598080918654,456,,Saint-Palais,Saint-Palais,33,Gironde,75,Nouvelle-Aquitaine
33457,ST PARDON DE CONQUES,33210,ST PARDON DE CONQUES,,44.5515180962,-0.188705431159,457,,Saint-Pardon-de-Conques,Saint-Pardon-de-Conques,33,Gironde,75,Nouvelle-Aquitaine
33458,ST PAUL,33390,ST PAUL,,45.1500987508,-0.590143244865,458,,Saint-Paul,Saint-Paul,33,Gironde,75,Nouvelle-Aquitaine
33459,ST PEY D ARMENS,33330,ST PEY D ARMENS,,44.8548887899,-0.118107156946,459,,Saint-Pey-d'Armens,Saint-Pey-d'Armens,33,Gironde,75,Nouvelle-Aquitaine
33460,ST PEY DE CASTETS,33350,ST PEY DE CASTETS,,44.81667563,-0.0640784363201,460,,Saint-Pey-de-Castets,Saint-Pey-de-Castets,33,Gironde,75,Nouvelle-Aquitaine
33461,ST PHILIPPE D AIGUILLE,33350,ST PHILIPPE D AIGUILLE,,44.9155884721,-0.0382526384701,461,,Saint-Philippe-d'Aiguille,Saint-Philippe-d'Aiguille,33,Gironde,75,Nouvelle-Aquitaine
33462,ST PHILIPPE DU SEIGNAL,33220,ST PHILIPPE DU SEIGNAL,,44.8241452564,0.25435223921,462,,Saint-Philippe-du-Seignal,Saint-Philippe-du-Seignal,33,Gironde,75,Nouvelle-Aquitaine
33463,ST PIERRE D AURILLAC,33490,ST PIERRE D AURILLAC,,44.5791928369,-0.192914222319,463,,Saint-Pierre-d'Aurillac,Saint-Pierre-d'Aurillac,33,Gironde,75,Nouvelle-Aquitaine
33464,ST PIERRE DE BAT,33760,ST PIERRE DE BAT,,44.6700408345,-0.219919179937,464,,Saint-Pierre-de-Bat,Saint-Pierre-de-Bat,33,Gironde,75,Nouvelle-Aquitaine
33465,ST PIERRE DE MONS,33210,ST PIERRE DE MONS,,44.5455986707,-0.213270603156,465,,Saint-Pierre-de-Mons,Saint-Pierre-de-Mons,33,Gironde,75,Nouvelle-Aquitaine
33466,ST QUENTIN DE BARON,33750,ST QUENTIN DE BARON,,44.8184515689,-0.281173860387,466,,Saint-Quentin-de-Baron,Saint-Quentin-de-Baron,33,Gironde,75,Nouvelle-Aquitaine
33467,ST QUENTIN DE CAPLONG,33220,ST QUENTIN DE CAPLONG,,44.7954078713,0.137421248916,467,,Saint-Quentin-de-Caplong,Saint-Quentin-de-Caplong,33,Gironde,75,Nouvelle-Aquitaine
33468,STE RADEGONDE,33350,STE RADEGONDE,,44.8030043488,0.0238709656269,468,,Sainte-Radegonde,Sainte-Radegonde,33,Gironde,75,Nouvelle-Aquitaine
33470,ST ROMAIN LA VIRVEE,33240,ST ROMAIN LA VIRVEE,,44.9594958204,-0.409988022855,470,,Saint-Romain-la-Virvée,Saint-Romain-la-Virvée,33,Gironde,75,Nouvelle-Aquitaine
33471,ST SAUVEUR,33250,ST SAUVEUR,,45.1949204348,-0.818681392214,471,,Saint-Sauveur,Saint-Sauveur,33,Gironde,75,Nouvelle-Aquitaine
33472,ST SAUVEUR DE PUYNORMAND,33660,ST SAUVEUR DE PUYNORMAND,,44.9957255079,-0.0320379299436,472,,Saint-Sauveur-de-Puynormand,Saint-Sauveur-de-Puynormand,33,Gironde,75,Nouvelle-Aquitaine
33473,ST SAVIN,33920,ST SAVIN,,45.1636697331,-0.441034693146,473,,Saint-Savin,Saint-Savin,33,Gironde,75,Nouvelle-Aquitaine
33474,ST SELVE,33650,ST SELVE,,44.6549808448,-0.472634663136,474,,Saint-Selve,Saint-Selve,33,Gironde,75,Nouvelle-Aquitaine
33475,ST SEURIN DE BOURG,33710,ST SEURIN DE BOURG,,45.0519405923,-0.58173269823,475,,Saint-Seurin-de-Bourg,Saint-Seurin-de-Bourg,33,Gironde,75,Nouvelle-Aquitaine
33476,ST SEURIN DE CADOURNE,33180,ST SEURIN DE CADOURNE,,45.2908187086,-0.80189992877,476,,Saint-Seurin-de-Cadourne,Saint-Seurin-de-Cadourne,33,Gironde,75,Nouvelle-Aquitaine
33477,ST SEURIN DE CURSAC,33390,ST SEURIN DE CURSAC,,45.1579570055,-0.627426326593,477,,Saint-Seurin-de-Cursac,Saint-Seurin-de-Cursac,33,Gironde,75,Nouvelle-Aquitaine
33478,ST SEURIN SUR L ISLE,33660,ST SEURIN SUR L ISLE,,45.0105769146,-0.0011367508014,478,,Saint-Seurin-sur-l'Isle,Saint-Seurin-sur-l'Isle,33,Gironde,75,Nouvelle-Aquitaine
33479,ST SEVE,33190,ST SEVE,,44.6068639564,-0.0241032514519,479,,Saint-Sève,Saint-Sève,33,Gironde,75,Nouvelle-Aquitaine
33480,ST SULPICE DE FALEYRENS,33330,ST SULPICE DE FALEYRENS,,44.8612319917,-0.188059653304,480,,Saint-Sulpice-de-Faleyrens,Saint-Sulpice-de-Faleyrens,33,Gironde,75,Nouvelle-Aquitaine
33481,ST SULPICE DE GUILLERAGUES,33580,ST SULPICE DE GUILLERAGUES,,44.6298211535,0.0478045586142,481,,Saint-Sulpice-de-Guilleragues,Saint-Sulpice-de-Guilleragues,33,Gironde,75,Nouvelle-Aquitaine
33482,ST SULPICE DE POMMIERS,33540,ST SULPICE DE POMMIERS,,44.6759704977,-0.113677268299,482,,Saint-Sulpice-de-Pommiers,Saint-Sulpice-de-Pommiers,33,Gironde,75,Nouvelle-Aquitaine
33483,ST SULPICE ET CAMEYRAC,33450,ST SULPICE ET CAMEYRAC,,44.9006929748,-0.386356667148,483,,Saint-Sulpice-et-Cameyrac,Saint-Sulpice-et-Cameyrac,33,Gironde,75,Nouvelle-Aquitaine
33484,ST SYMPHORIEN,33113,ST SYMPHORIEN,,44.4230431057,-0.540077855567,484,,Saint-Symphorien,Saint-Symphorien,33,Gironde,75,Nouvelle-Aquitaine
33485,STE TERRE,33350,STE TERRE,,44.8338850704,-0.120538591172,485,,Sainte-Terre,Sainte-Terre,33,Gironde,75,Nouvelle-Aquitaine
33486,ST TROJAN,33710,ST TROJAN,,45.0900998302,-0.580473676207,486,,Saint-Trojan,Saint-Trojan,33,Gironde,75,Nouvelle-Aquitaine
33487,ST VINCENT DE PAUL,33440,ST VINCENT DE PAUL,,44.9735956651,-0.481421340788,487,,Saint-Vincent-de-Paul,Saint-Vincent-de-Paul,33,Gironde,75,Nouvelle-Aquitaine
33488,ST VINCENT DE PERTIGNAS,33420,ST VINCENT DE PERTIGNAS,,44.798947903,-0.111709679595,488,,Saint-Vincent-de-Pertignas,Saint-Vincent-de-Pertignas,33,Gironde,75,Nouvelle-Aquitaine
33489,ST VIVIEN DE BLAYE,33920,ST VIVIEN DE BLAYE,,45.1041763192,-0.507779257628,489,,Saint-Vivien-de-Blaye,Saint-Vivien-de-Blaye,33,Gironde,75,Nouvelle-Aquitaine
33490,ST VIVIEN DE MEDOC,33590,ST VIVIEN DE MEDOC,,45.4448997838,-1.02531932797,490,,Saint-Vivien-de-Médoc,Saint-Vivien-de-Médoc,33,Gironde,75,Nouvelle-Aquitaine
33491,ST VIVIEN DE MONSEGUR,33580,ST VIVIEN DE MONSEGUR,,44.6162538241,0.116604904925,491,,Saint-Vivien-de-Monségur,Saint-Vivien-de-Monségur,33,Gironde,75,Nouvelle-Aquitaine
33492,ST YZAN DE SOUDIAC,33920,ST YZAN DE SOUDIAC,,45.1520899174,-0.405167820724,492,,Saint-Yzan-de-Soudiac,Saint-Yzan-de-Soudiac,33,Gironde,75,Nouvelle-Aquitaine
33493,ST YZANS DE MEDOC,33340,ST YZANS DE MEDOC,,45.3263969958,-0.813698621313,493,,Saint-Yzans-de-Médoc,Saint-Yzans-de-Médoc,33,Gironde,75,Nouvelle-Aquitaine
33494,SALAUNES,33160,SALAUNES,,44.9276818201,-0.845720627431,494,,Salaunes,Salaunes,33,Gironde,75,Nouvelle-Aquitaine
33496,SALLEBOEUF,33370,SALLEBOEUF,,44.8438707092,-0.39299225545,496,,Sallebœuf,Sallebœuf,33,Gironde,75,Nouvelle-Aquitaine
33498,SALLES,33770,SALLES,,44.5414583126,-0.883819514854,498,,Salles,Salles,33,Gironde,75,Nouvelle-Aquitaine
33499,LES SALLES DE CASTILLON,33350,LES SALLES DE CASTILLON,,44.9144882685,0.0055966007677,499,Les,Salles-de-Castillon,Les Salles-de-Castillon,33,Gironde,75,Nouvelle-Aquitaine
33500,SAMONAC,33710,SAMONAC,,45.0708411612,-0.571604822734,500,,Samonac,Samonac,33,Gironde,75,Nouvelle-Aquitaine
33501,SAUCATS,33650,SAUCATS,,44.6513032071,-0.631639793816,501,,Saucats,Saucats,33,Gironde,75,Nouvelle-Aquitaine
33502,SAUGON,33920,SAUGON,,45.185092781,-0.501188471075,502,,Saugon,Saugon,33,Gironde,75,Nouvelle-Aquitaine
33503,SAUMOS,33680,SAUMOS,,44.9121930542,-1.00529484975,503,,Saumos,Saumos,33,Gironde,75,Nouvelle-Aquitaine
33504,SAUTERNES,33210,SAUTERNES,,44.531082109,-0.341531541732,504,,Sauternes,Sauternes,33,Gironde,75,Nouvelle-Aquitaine
33505,LA SAUVE,33670,LA SAUVE,,44.7629611039,-0.311980129147,505,La,Sauve,La Sauve,33,Gironde,75,Nouvelle-Aquitaine
33506,SAUVETERRE DE GUYENNE,33540,SAUVETERRE DE GUYENNE,,44.7005227855,-0.0766193491952,506,,Sauveterre-de-Guyenne,Sauveterre-de-Guyenne,33,Gironde,75,Nouvelle-Aquitaine
33507,SAUVIAC,33430,SAUVIAC,,44.4000851943,-0.183884357604,507,,Sauviac,Sauviac,33,Gironde,75,Nouvelle-Aquitaine
33508,SAVIGNAC,33124,SAVIGNAC,,44.5147062102,-0.108846337932,508,,Savignac,Savignac,33,Gironde,75,Nouvelle-Aquitaine
33509,SAVIGNAC DE L ISLE,33910,SAVIGNAC DE L ISLE,,44.9865629262,-0.24028093176,509,,Savignac-de-l'Isle,Savignac-de-l'Isle,33,Gironde,75,Nouvelle-Aquitaine
33510,SEMENS,33490,SEMENS,,44.6132843909,-0.232960926916,510,,Semens,Semens,33,Gironde,75,Nouvelle-Aquitaine
33511,SENDETS,33690,SENDETS,,44.4268508366,-0.0945303098396,511,,Sendets,Sendets,33,Gironde,75,Nouvelle-Aquitaine
33512,SIGALENS,33690,SIGALENS,,44.4549781979,-0.03281524291,512,,Sigalens,Sigalens,33,Gironde,75,Nouvelle-Aquitaine
33513,SILLAS,33690,SILLAS,,44.3693679441,-0.0585641941826,513,,Sillas,Sillas,33,Gironde,75,Nouvelle-Aquitaine
33514,SOULAC SUR MER,33780,SOULAC SUR MER,,45.493296382,-1.10918123147,514,,Soulac-sur-Mer,Soulac-sur-Mer,33,Gironde,75,Nouvelle-Aquitaine
33515,SOULIGNAC,33760,SOULIGNAC,,44.6965920718,-0.288037993647,515,,Soulignac,Soulignac,33,Gironde,75,Nouvelle-Aquitaine
33516,SOUSSAC,33790,SOUSSAC,,44.7356053144,0.0112731568221,516,,Soussac,Soussac,33,Gironde,75,Nouvelle-Aquitaine
33517,SOUSSANS,33460,SOUSSANS,,45.0563042075,-0.697907697302,517,,Soussans,Soussans,33,Gironde,75,Nouvelle-Aquitaine
33518,TABANAC,33550,TABANAC,,44.7242938143,-0.41077713329,518,,Tabanac,Tabanac,33,Gironde,75,Nouvelle-Aquitaine
33519,LE TAILLAN MEDOC,33320,LE TAILLAN MEDOC,,44.9111778103,-0.680843022546,519,Le,Taillan-Médoc,Le Taillan-Médoc,33,Gironde,75,Nouvelle-Aquitaine
33520,TAILLECAVAT,33580,TAILLECAVAT,,44.6516434707,0.157497869369,520,,Taillecavat,Taillecavat,33,Gironde,75,Nouvelle-Aquitaine
33521,TALAIS,33590,TALAIS,,45.4760792837,-1.06152819999,521,,Talais,Talais,33,Gironde,75,Nouvelle-Aquitaine
33522,TALENCE,33400,TALENCE,,44.8060817507,-0.591124592873,522,,Talence,Talence,33,Gironde,75,Nouvelle-Aquitaine
33523,TARGON,33760,TARGON,,44.7345410468,-0.267821650496,523,,Targon,Targon,33,Gironde,75,Nouvelle-Aquitaine
33524,TARNES,33240,TARNES,,44.9760038738,-0.355091111216,524,,Tarnès,Tarnès,33,Gironde,75,Nouvelle-Aquitaine
33525,TAURIAC,33710,TAURIAC,,45.0535176036,-0.505943273443,525,,Tauriac,Tauriac,33,Gironde,75,Nouvelle-Aquitaine
33526,TAYAC,33570,TAYAC,,44.9605098149,-0.023701712084,526,,Tayac,Tayac,33,Gironde,75,Nouvelle-Aquitaine
33527,LE TEICH,33470,LE TEICH,,44.5781043558,-1.02426702104,527,Le,Teich,Le Teich,33,Gironde,75,Nouvelle-Aquitaine
33528,LE TEMPLE,33680,LE TEMPLE,,44.8635232625,-0.95904166915,528,Le,Temple,Le Temple,33,Gironde,75,Nouvelle-Aquitaine
33529,LA TESTE DE BUCH,33115,LA TESTE DE BUCH,PYLA PLAGE,44.5561823053,-1.17530826791,529,La,Teste-de-Buch,La Teste-de-Buch,33,Gironde,75,Nouvelle-Aquitaine
33529,LA TESTE DE BUCH,33115,LA TESTE DE BUCH,PYLA SUR MER,44.5561823053,-1.17530826791,529,La,Teste-de-Buch,La Teste-de-Buch,33,Gironde,75,Nouvelle-Aquitaine
33529,LA TESTE DE BUCH,33260,LA TESTE DE BUCH,,44.5561823053,-1.17530826791,529,La,Teste-de-Buch,La Teste-de-Buch,33,Gironde,75,Nouvelle-Aquitaine
33529,LA TESTE DE BUCH,33260,LA TESTE DE BUCH,CAZAUX,44.5561823053,-1.17530826791,529,La,Teste-de-Buch,La Teste-de-Buch,33,Gironde,75,Nouvelle-Aquitaine
33530,TEUILLAC,33710,TEUILLAC,,45.0962146262,-0.547066877584,530,,Teuillac,Teuillac,33,Gironde,75,Nouvelle-Aquitaine
33531,TIZAC DE CURTON,33420,TIZAC DE CURTON,,44.8247322767,-0.242853466608,531,,Tizac-de-Curton,Tizac-de-Curton,33,Gironde,75,Nouvelle-Aquitaine
33532,TIZAC DE LAPOUYADE,33620,TIZAC DE LAPOUYADE,,45.0701772537,-0.314253428253,532,,Tizac-de-Lapouyade,Tizac-de-Lapouyade,33,Gironde,75,Nouvelle-Aquitaine
33533,TOULENNE,33210,TOULENNE,,44.5644281696,-0.275202743497,533,,Toulenne,Toulenne,33,Gironde,75,Nouvelle-Aquitaine
33534,LE TOURNE,33550,LE TOURNE,,44.717281049,-0.397019832374,534,Le,Tourne,Le Tourne,33,Gironde,75,Nouvelle-Aquitaine
33535,TRESSES,33370,TRESSES,,44.8419124871,-0.469935856995,535,,Tresses,Tresses,33,Gironde,75,Nouvelle-Aquitaine
33536,LE TUZAN,33125,LE TUZAN,,44.4493819137,-0.600499889851,536,Le,Tuzan,Le Tuzan,33,Gironde,75,Nouvelle-Aquitaine
33537,UZESTE,33730,UZESTE,,44.4334139021,-0.324575198234,537,,Uzeste,Uzeste,33,Gironde,75,Nouvelle-Aquitaine
33538,VALEYRAC,33340,VALEYRAC,,45.3906041453,-0.901076272339,538,,Valeyrac,Valeyrac,33,Gironde,75,Nouvelle-Aquitaine
33539,VAYRES,33870,VAYRES,,44.8910656059,-0.32583268578,539,,Vayres,Vayres,33,Gironde,75,Nouvelle-Aquitaine
33540,VENDAYS MONTALIVET,33930,VENDAYS MONTALIVET,,45.3394672808,-1.10059258014,540,,Vendays-Montalivet,Vendays-Montalivet,33,Gironde,75,Nouvelle-Aquitaine
33541,VENSAC,33590,VENSAC,,45.3988518423,-1.07454435969,541,,Vensac,Vensac,33,Gironde,75,Nouvelle-Aquitaine
33542,VERAC,33240,VERAC,,44.9940061941,-0.336975920597,542,,Vérac,Vérac,33,Gironde,75,Nouvelle-Aquitaine
33543,VERDELAIS,33490,VERDELAIS,,44.592150286,-0.248104423488,543,,Verdelais,Verdelais,33,Gironde,75,Nouvelle-Aquitaine
33544,LE VERDON SUR MER,33123,LE VERDON SUR MER,,45.5385656326,-1.07975440879,544,Le,Verdon-sur-Mer,Le Verdon-sur-Mer,33,Gironde,75,Nouvelle-Aquitaine
33545,VERTHEUIL,33180,VERTHEUIL,,45.2541334712,-0.845409908523,545,,Vertheuil,Vertheuil,33,Gironde,75,Nouvelle-Aquitaine
33546,VIGNONET,33330,VIGNONET,,44.8496333181,-0.149970090399,546,,Vignonet,Vignonet,33,Gironde,75,Nouvelle-Aquitaine
33547,VILLANDRAUT,33730,VILLANDRAUT,,44.4500209214,-0.380716602185,547,,Villandraut,Villandraut,33,Gironde,75,Nouvelle-Aquitaine
33548,VILLEGOUGE,33141,VILLEGOUGE,,44.9726067996,-0.312917809378,548,,Villegouge,Villegouge,33,Gironde,75,Nouvelle-Aquitaine
33549,VILLENAVE DE RIONS,33550,VILLENAVE DE RIONS,,44.6900368353,-0.340475124809,549,,Villenave-de-Rions,Villenave-de-Rions,33,Gironde,75,Nouvelle-Aquitaine
33550,VILLENAVE D ORNON,33140,VILLENAVE D ORNON,,44.773185218,-0.558212256384,550,,Villenave-d'Ornon,Villenave-d'Ornon,33,Gironde,75,Nouvelle-Aquitaine
33550,VILLENAVE D ORNON,33140,VILLENAVE D ORNON,PONT DE LA MAYE,44.773185218,-0.558212256384,550,,Villenave-d'Ornon,Villenave-d'Ornon,33,Gironde,75,Nouvelle-Aquitaine
33551,VILLENEUVE,33710,VILLENEUVE,,45.0812887735,-0.63981215902,551,,Villeneuve,Villeneuve,33,Gironde,75,Nouvelle-Aquitaine
33552,VIRELADE,33720,VIRELADE,,44.6522568451,-0.395140990742,552,,Virelade,Virelade,33,Gironde,75,Nouvelle-Aquitaine
33553,VIRSAC,33240,VIRSAC,,45.0259020314,-0.435395776761,553,,Virsac,Virsac,33,Gironde,75,Nouvelle-Aquitaine
33554,YVRAC,33370,YVRAC,,44.8802372837,-0.466351132109,554,,Yvrac,Yvrac,33,Gironde,75,Nouvelle-Aquitaine
33555,MARCHEPRIME,33380,MARCHEPRIME,,44.7030054504,-0.856342535157,555,,Marcheprime,Marcheprime,33,Gironde,75,Nouvelle-Aquitaine
34001,ABEILHAN,34290,ABEILHAN,,43.4575019905,3.29923508306,1,,Abeilhan,Abeilhan,34,Hérault,76,Occitanie
34002,ADISSAN,34230,ADISSAN,,43.535473519,3.42608475989,2,,Adissan,Adissan,34,Hérault,76,Occitanie
34003,AGDE,34300,AGDE,,43.309129116,3.48447903133,3,,Agde,Agde,34,Hérault,76,Occitanie
34003,AGDE,34300,AGDE,LE CAP D AGDE,43.309129116,3.48447903133,3,,Agde,Agde,34,Hérault,76,Occitanie
34004,AGEL,34210,AGEL,,43.3482634234,2.85354795637,4,,Agel,Agel,34,Hérault,76,Occitanie
34005,AGONES,34190,AGONES,,43.9010523591,3.72652630409,5,,Agonès,Agonès,34,Hérault,76,Occitanie
34006,AIGNE,34210,AIGNE,,43.3255517298,2.79308395136,6,,Aigne,Aigne,34,Hérault,76,Occitanie
34007,AIGUES VIVES,34210,AIGUES VIVES,,43.3476601284,2.82493400005,7,,Aigues-Vives,Aigues-Vives,34,Hérault,76,Occitanie
34008,LES AIRES,34600,LES AIRES,,43.5695523361,3.07138973209,8,Les,Aires,Les Aires,34,Hérault,76,Occitanie
34009,ALIGNAN DU VENT,34290,ALIGNAN DU VENT,,43.4688122746,3.33925724714,9,,Alignan-du-Vent,Alignan-du-Vent,34,Hérault,76,Occitanie
34010,ANIANE,34150,ANIANE,,43.6835011433,3.59259437611,10,,Aniane,Aniane,34,Hérault,76,Occitanie
34011,ARBORAS,34150,ARBORAS,,43.7212717776,3.47409312363,11,,Arboras,Arboras,34,Hérault,76,Occitanie
34012,ARGELLIERS,34380,ARGELLIERS,,43.7249523063,3.67420720253,12,,Argelliers,Argelliers,34,Hérault,76,Occitanie
34013,ASPIRAN,34800,ASPIRAN,,43.5672233099,3.4481611939,13,,Aspiran,Aspiran,34,Hérault,76,Occitanie
34014,ASSAS,34820,ASSAS,,43.7104129973,3.89790490064,14,,Assas,Assas,34,Hérault,76,Occitanie
34015,ASSIGNAN,34360,ASSIGNAN,,43.3998665892,2.8868499773,15,,Assignan,Assignan,34,Hérault,76,Occitanie
34016,AUMELAS,34230,AUMELAS,,43.5777908844,3.61727878859,16,,Aumelas,Aumelas,34,Hérault,76,Occitanie
34017,AUMES,34530,AUMES,,43.4638784757,3.46993966613,17,,Aumes,Aumes,34,Hérault,76,Occitanie
34018,AUTIGNAC,34480,AUTIGNAC,,43.4997194171,3.16767512449,18,,Autignac,Autignac,34,Hérault,76,Occitanie
34019,AVENE,34260,AVENE,,43.7483953944,3.10467542388,19,,Avène,Avène,34,Hérault,76,Occitanie
34020,AZILLANET,34210,AZILLANET,,43.3236122497,2.74504101895,20,,Azillanet,Azillanet,34,Hérault,76,Occitanie
34021,BABEAU BOULDOUX,34360,BABEAU BOULDOUX,,43.4382106135,2.89298078323,21,,Babeau-Bouldoux,Babeau-Bouldoux,34,Hérault,76,Occitanie
34022,BAILLARGUES,34670,BAILLARGUES,,43.6581051757,4.01030491176,22,,Baillargues,Baillargues,34,Hérault,76,Occitanie
34023,BALARUC LES BAINS,34540,BALARUC LES BAINS,,43.4469007065,3.69072086799,23,,Balaruc-les-Bains,Balaruc-les-Bains,34,Hérault,76,Occitanie
34024,BALARUC LE VIEUX,34540,BALARUC LE VIEUX,,43.4659799669,3.69713367505,24,,Balaruc-le-Vieux,Balaruc-le-Vieux,34,Hérault,76,Occitanie
34025,BASSAN,34290,BASSAN,,43.4084026514,3.2545400418,25,,Bassan,Bassan,34,Hérault,76,Occitanie
34026,BEAUFORT,34210,BEAUFORT,,43.3040872042,2.76829342504,26,,Beaufort,Beaufort,34,Hérault,76,Occitanie
34027,BEAULIEU,34160,BEAULIEU,,43.7298984427,4.02118900985,27,,Beaulieu,Beaulieu,34,Hérault,76,Occitanie
34028,BEDARIEUX,34600,BEDARIEUX,,43.6125569074,3.16680787195,28,,Bédarieux,Bédarieux,34,Hérault,76,Occitanie
34029,BELARGA,34230,BELARGA,,43.5541458971,3.50006172125,29,,Bélarga,Bélarga,34,Hérault,76,Occitanie
34030,BERLOU,34360,BERLOU,,43.4985990248,2.94998951427,30,,Berlou,Berlou,34,Hérault,76,Occitanie
34031,BESSAN,34550,BESSAN,,43.3569862701,3.41660408543,31,,Bessan,Bessan,34,Hérault,76,Occitanie
34032,BEZIERS,34500,BEZIERS,,43.3475883319,3.23076754164,32,,Béziers,Béziers,34,Hérault,76,Occitanie
34033,BOISSERON,34160,BOISSERON,,43.7523646952,4.07621075324,33,,Boisseron,Boisseron,34,Hérault,76,Occitanie
34034,BOISSET,34220,BOISSET,,43.4017637442,2.69024605331,34,,Boisset,Boisset,34,Hérault,76,Occitanie
34035,LA BOISSIERE,34150,LA BOISSIERE,,43.6543984294,3.64428863872,35,La,Boissière,La Boissière,34,Hérault,76,Occitanie
34036,LE BOSC,34700,LE BOSC,,43.6957752498,3.38710687452,36,Le,Bosc,Le Bosc,34,Hérault,76,Occitanie
34037,BOUJAN SUR LIBRON,34760,BOUJAN SUR LIBRON,,43.3803202017,3.26313194789,37,,Boujan-sur-Libron,Boujan-sur-Libron,34,Hérault,76,Occitanie
34038,LE BOUSQUET D ORB,34260,LE BOUSQUET D ORB,,43.7025373477,3.15150280873,38,Le,Bousquet-d'Orb,Le Bousquet-d'Orb,34,Hérault,76,Occitanie
34039,BOUZIGUES,34140,BOUZIGUES,,43.4453207742,3.65707555068,39,,Bouzigues,Bouzigues,34,Hérault,76,Occitanie
34040,BRENAS,34650,BRENAS,,43.6412617949,3.25359817589,40,,Brenas,Brenas,34,Hérault,76,Occitanie
34041,BRIGNAC,34800,BRIGNAC,,43.6219841604,3.47081573374,41,,Brignac,Brignac,34,Hérault,76,Occitanie
34042,BRISSAC,34190,BRISSAC,,43.8680305219,3.70509691756,42,,Brissac,Brissac,34,Hérault,76,Occitanie
34043,BUZIGNARGUES,34160,BUZIGNARGUES,,43.771599327,3.99491070508,43,,Buzignargues,Buzignargues,34,Hérault,76,Occitanie
34044,CABREROLLES,34480,CABREROLLES,,43.5347800126,3.12207083233,44,,Cabrerolles,Cabrerolles,34,Hérault,76,Occitanie
34045,CABRIERES,34800,CABRIERES,,43.5777973112,3.34800607563,45,,Cabrières,Cabrières,34,Hérault,76,Occitanie
34046,CAMBON ET SALVERGUES,34330,CAMBON ET SALVERGUES,,43.625504452,2.89047084983,46,,Cambon-et-Salvergues,Cambon-et-Salvergues,34,Hérault,76,Occitanie
34047,CAMPAGNAN,34230,CAMPAGNAN,,43.5407744121,3.49239234848,47,,Campagnan,Campagnan,34,Hérault,76,Occitanie
34048,CAMPAGNE,34160,CAMPAGNE,,43.7858302535,4.03449984137,48,,Campagne,Campagne,34,Hérault,76,Occitanie
34049,CAMPLONG,34260,CAMPLONG,,43.6898005202,3.11179486832,49,,Camplong,Camplong,34,Hérault,76,Occitanie
34050,CANDILLARGUES,34130,CANDILLARGUES,,43.6184336518,4.06397943606,50,,Candillargues,Candillargues,34,Hérault,76,Occitanie
34051,CANET,34800,CANET,,43.5983184879,3.48279950593,51,,Canet,Canet,34,Hérault,76,Occitanie
34052,CAPESTANG,34310,CAPESTANG,,43.3217608814,3.04813542922,52,,Capestang,Capestang,34,Hérault,76,Occitanie
34053,CARLENCAS ET LEVAS,34600,CARLENCAS ET LEVAS,,43.6298590815,3.22034291707,53,,Carlencas-et-Levas,Carlencas-et-Levas,34,Hérault,76,Occitanie
34054,CASSAGNOLES,34210,CASSAGNOLES,,43.3973278254,2.60178289175,54,,Cassagnoles,Cassagnoles,34,Hérault,76,Occitanie
34055,CASTANET LE HAUT,34610,CASTANET LE HAUT,,43.6738023402,2.95490649454,55,,Castanet-le-Haut,Castanet-le-Haut,34,Hérault,76,Occitanie
34056,CASTELNAU DE GUERS,34120,CASTELNAU DE GUERS,,43.4352029154,3.47130498268,56,,Castelnau-de-Guers,Castelnau-de-Guers,34,Hérault,76,Occitanie
34057,CASTELNAU LE LEZ,34170,CASTELNAU LE LEZ,,43.6380218809,3.91265416028,57,,Castelnau-le-Lez,Castelnau-le-Lez,34,Hérault,76,Occitanie
34058,CASTRIES,34160,CASTRIES,,43.6886913473,3.97909849679,58,,Castries,Castries,34,Hérault,76,Occitanie
34059,LA CAUNETTE,34210,LA CAUNETTE,,43.3674487312,2.78721523132,59,La,Caunette,La Caunette,34,Hérault,76,Occitanie
34060,CAUSSE DE LA SELLE,34380,CAUSSE DE LA SELLE,,43.793318405,3.62922803202,60,,Causse-de-la-Selle,Causse-de-la-Selle,34,Hérault,76,Occitanie
34061,CAUSSES ET VEYRAN,34490,CAUSSES ET VEYRAN,,43.4679658506,3.09140817523,61,,Causses-et-Veyran,Causses-et-Veyran,34,Hérault,76,Occitanie
34062,CAUSSINIOJOULS,34600,CAUSSINIOJOULS,,43.5484030335,3.15563201931,62,,Caussiniojouls,Caussiniojouls,34,Hérault,76,Occitanie
34063,CAUX,34720,CAUX,,43.5044671037,3.36827222924,63,,Caux,Caux,34,Hérault,76,Occitanie
34064,LE CAYLAR,34520,LE CAYLAR,,43.8716890931,3.31157473989,64,Le,Caylar,Le Caylar,34,Hérault,76,Occitanie
34065,CAZEDARNES,34460,CAZEDARNES,,43.4234094585,3.0282977991,65,,Cazedarnes,Cazedarnes,34,Hérault,76,Occitanie
34066,CAZEVIEILLE,34270,CAZEVIEILLE,,43.7640929334,3.79487938999,66,,Cazevieille,Cazevieille,34,Hérault,76,Occitanie
34067,CAZILHAC,34190,CAZILHAC,,43.9181503248,3.69073251307,67,,Cazilhac,Cazilhac,34,Hérault,76,Occitanie
34068,CAZOULS D HERAULT,34120,CAZOULS D HERAULT,,43.5018486169,3.45727466019,68,,Cazouls-d'Hérault,Cazouls-d'Hérault,34,Hérault,76,Occitanie
34069,CAZOULS LES BEZIERS,34370,CAZOULS LES BEZIERS,,43.3976310061,3.09968408339,69,,Cazouls-lès-Béziers,Cazouls-lès-Béziers,34,Hérault,76,Occitanie
34070,CEBAZAN,34360,CEBAZAN,,43.4057714323,2.98753544728,70,,Cébazan,Cébazan,34,Hérault,76,Occitanie
34071,CEILHES ET ROCOZELS,34260,CEILHES ET ROCOZELS,,43.809322318,3.09184232431,71,,Ceilhes-et-Rocozels,Ceilhes-et-Rocozels,34,Hérault,76,Occitanie
34072,CELLES,34700,CELLES,,43.6663640232,3.35967873955,72,,Celles,Celles,34,Hérault,76,Occitanie
34073,CERS,34420,CERS,,43.3302709569,3.31729573152,73,,Cers,Cers,34,Hérault,76,Occitanie
34074,CESSENON SUR ORB,34460,CESSENON SUR ORB,,43.454255953,3.04105685842,74,,Cessenon-sur-Orb,Cessenon-sur-Orb,34,Hérault,76,Occitanie
34075,CESSERAS,34210,CESSERAS,,43.3311589874,2.70406695025,75,,Cesseras,Cesseras,34,Hérault,76,Occitanie
34076,CEYRAS,34800,CEYRAS,,43.6499208195,3.45967930935,76,,Ceyras,Ceyras,34,Hérault,76,Occitanie
34077,CLAPIERS,34830,CLAPIERS,,43.6620192267,3.88689748204,77,,Clapiers,Clapiers,34,Hérault,76,Occitanie
34078,CLARET,34270,CLARET,,43.8572510508,3.87648647293,78,,Claret,Claret,34,Hérault,76,Occitanie
34079,CLERMONT L HERAULT,34800,CLERMONT L HERAULT,,43.6338885209,3.41317415682,79,,Clermont-l'Hérault,Clermont-l'Hérault,34,Hérault,76,Occitanie
34080,COLOMBIERES SUR ORB,34390,COLOMBIERES SUR ORB,,43.5853145564,3.01084654208,80,,Colombières-sur-Orb,Colombières-sur-Orb,34,Hérault,76,Occitanie
34081,COLOMBIERS,34440,COLOMBIERS,,43.3154427074,3.14408489609,81,,Colombiers,Colombiers,34,Hérault,76,Occitanie
34082,COMBAILLAUX,34980,COMBAILLAUX,,43.6724651023,3.77949432527,82,,Combaillaux,Combaillaux,34,Hérault,76,Occitanie
34083,COMBES,34240,COMBES,,43.602411245,3.0476220061,83,,Combes,Combes,34,Hérault,76,Occitanie
34084,CORNEILHAN,34490,CORNEILHAN,,43.4019158731,3.19646909988,84,,Corneilhan,Corneilhan,34,Hérault,76,Occitanie
34085,COULOBRES,34290,COULOBRES,,43.4507697404,3.27495989155,85,,Coulobres,Coulobres,34,Hérault,76,Occitanie
34086,COURNIOU,34220,COURNIOU,,43.489357501,2.69325210308,86,,Courniou,Courniou,34,Hérault,76,Occitanie
34087,COURNONSEC,34660,COURNONSEC,,43.5404276115,3.70276147936,87,,Cournonsec,Cournonsec,34,Hérault,76,Occitanie
34088,COURNONTERRAL,34660,COURNONTERRAL,,43.5694241257,3.70642853592,88,,Cournonterral,Cournonterral,34,Hérault,76,Occitanie
34089,CREISSAN,34370,CREISSAN,,43.3771624265,2.99976062143,89,,Creissan,Creissan,34,Hérault,76,Occitanie
34090,LE CRES,34920,LE CRES,,43.6540978695,3.93608851243,90,Le,Crès,Le Crès,34,Hérault,76,Occitanie
34091,LE CROS,34520,LE CROS,,43.8802736977,3.36304927943,91,Le,Cros,Le Cros,34,Hérault,76,Occitanie
34092,CRUZY,34310,CRUZY,,43.3518501139,2.93451082669,92,,Cruzy,Cruzy,34,Hérault,76,Occitanie
34093,DIO ET VALQUIERES,34650,DIO ET VALQUIERES,,43.6690497036,3.22101697098,93,,Dio-et-Valquières,Dio-et-Valquières,34,Hérault,76,Occitanie
34094,ESPONDEILHAN,34290,ESPONDEILHAN,,43.4372418767,3.25795790915,94,,Espondeilhan,Espondeilhan,34,Hérault,76,Occitanie
34095,FABREGUES,34690,FABREGUES,,43.5344767016,3.77192951891,95,,Fabrègues,Fabrègues,34,Hérault,76,Occitanie
34096,FAUGERES,34600,FAUGERES,,43.5687652177,3.18349121003,96,,Faugères,Faugères,34,Hérault,76,Occitanie
34097,FELINES MINERVOIS,34210,FELINES MINERVOIS,,43.354036783,2.58187245659,97,,Félines-Minervois,Félines-Minervois,34,Hérault,76,Occitanie
34098,FERRALS LES MONTAGNES,34210,FERRALS LES MONTAGNES,,43.4188135877,2.6417753577,98,,Ferrals-les-Montagnes,Ferrals-les-Montagnes,34,Hérault,76,Occitanie
34099,FERRIERES LES VERRERIES,34190,FERRIERES LES VERRERIES,,43.8675537207,3.80028962389,99,,Ferrières-les-Verreries,Ferrières-les-Verreries,34,Hérault,76,Occitanie
34100,FERRIERES POUSSAROU,34360,FERRIERES POUSSAROU,,43.4833450309,2.903540512,100,,Ferrières-Poussarou,Ferrières-Poussarou,34,Hérault,76,Occitanie
34101,FLORENSAC,34510,FLORENSAC,,43.3858472782,3.4638416795,101,,Florensac,Florensac,34,Hérault,76,Occitanie
34102,FONTANES,34270,FONTANES,,43.7944026303,3.91839771103,102,,Fontanès,Fontanès,34,Hérault,76,Occitanie
34103,FONTES,34320,FONTES,,43.5419967541,3.39026556007,103,,Fontès,Fontès,34,Hérault,76,Occitanie
34104,FOS,34320,FOS,,43.5681193831,3.24200444843,104,,Fos,Fos,34,Hérault,76,Occitanie
34105,FOUZILHON,34480,FOUZILHON,,43.5057316875,3.23717233184,105,,Fouzilhon,Fouzilhon,34,Hérault,76,Occitanie
34106,FOZIERES,34700,FOZIERES,,43.7508754164,3.35595982822,106,,Fozières,Fozières,34,Hérault,76,Occitanie
34107,FRAISSE SUR AGOUT,34330,FRAISSE SUR AGOUT,,43.5926354982,2.79705531131,107,,Fraisse-sur-Agout,Fraisse-sur-Agout,34,Hérault,76,Occitanie
34108,FRONTIGNAN,34110,FRONTIGNAN,,43.4482458873,3.74923522416,108,,Frontignan,Frontignan,34,Hérault,76,Occitanie
34108,FRONTIGNAN,34110,FRONTIGNAN,LA PEYRADE,43.4482458873,3.74923522416,108,,Frontignan,Frontignan,34,Hérault,76,Occitanie
34109,GABIAN,34320,GABIAN,,43.5181480219,3.26449755932,109,,Gabian,Gabian,34,Hérault,76,Occitanie
34110,GALARGUES,34160,GALARGUES,,43.7823313364,4.00782608796,110,,Galargues,Galargues,34,Hérault,76,Occitanie
34111,GANGES,34190,GANGES,,43.9457300618,3.70523816055,111,,Ganges,Ganges,34,Hérault,76,Occitanie
34112,GARRIGUES,34160,GARRIGUES,,43.7983239512,4.01049087518,112,,Garrigues,Garrigues,34,Hérault,76,Occitanie
34113,GIGEAN,34770,GIGEAN,,43.4940932526,3.72353998798,113,,Gigean,Gigean,34,Hérault,76,Occitanie
34114,GIGNAC,34150,GIGNAC,,43.6393937216,3.57384628868,114,,Gignac,Gignac,34,Hérault,76,Occitanie
34115,GORNIES,34190,GORNIES,,43.8843569278,3.62462795605,115,,Gorniès,Gorniès,34,Hérault,76,Occitanie
34116,GRABELS,34790,GRABELS,,43.6507076799,3.79423262021,116,,Grabels,Grabels,34,Hérault,76,Occitanie
34117,GRAISSESSAC,34260,GRAISSESSAC,,43.6862996463,3.08522636762,117,,Graissessac,Graissessac,34,Hérault,76,Occitanie
34118,GUZARGUES,34820,GUZARGUES,,43.7292469184,3.9239639392,118,,Guzargues,Guzargues,34,Hérault,76,Occitanie
34119,HEREPIAN,34600,HEREPIAN,,43.591789169,3.11770187761,119,,Hérépian,Hérépian,34,Hérault,76,Occitanie
34120,JACOU,34830,JACOU,,43.6629846943,3.9119610777,120,,Jacou,Jacou,34,Hérault,76,Occitanie
34121,JONCELS,34650,JONCELS,,43.7662193916,3.18310096799,121,,Joncels,Joncels,34,Hérault,76,Occitanie
34122,JONQUIERES,34725,JONQUIERES,,43.673901625,3.47813467686,122,,Jonquières,Jonquières,34,Hérault,76,Occitanie
34123,JUVIGNAC,34990,JUVIGNAC,,43.6237646402,3.79579375182,123,,Juvignac,Juvignac,34,Hérault,76,Occitanie
34124,LACOSTE,34800,LACOSTE,,43.6572095085,3.42316601881,124,,Lacoste,Lacoste,34,Hérault,76,Occitanie
34125,LAGAMAS,34150,LAGAMAS,,43.6745844077,3.5321562547,125,,Lagamas,Lagamas,34,Hérault,76,Occitanie
34126,LAMALOU LES BAINS,34240,LAMALOU LES BAINS,,43.5964619142,3.08445405222,126,,Lamalou-les-Bains,Lamalou-les-Bains,34,Hérault,76,Occitanie
34127,LANSARGUES,34130,LANSARGUES,,43.6364436854,4.08270443666,127,,Lansargues,Lansargues,34,Hérault,76,Occitanie
34128,LAROQUE,34190,LAROQUE,,43.9256514661,3.73343293055,128,,Laroque,Laroque,34,Hérault,76,Occitanie
34129,LATTES,34970,LATTES,,43.5638594056,3.90226222861,129,,Lattes,Lattes,34,Hérault,76,Occitanie
34130,LAURENS,34480,LAURENS,,43.5218394157,3.1996305574,130,,Laurens,Laurens,34,Hérault,76,Occitanie
34131,LAURET,34270,LAURET,,43.8313701737,3.88187587,131,,Lauret,Lauret,34,Hérault,76,Occitanie
34132,LAUROUX,34700,LAUROUX,,43.7915234446,3.27831492551,132,,Lauroux,Lauroux,34,Hérault,76,Occitanie
34133,LAVALETTE,34700,LAVALETTE,,43.6926559892,3.27139234301,133,,Lavalette,Lavalette,34,Hérault,76,Occitanie
34134,LAVERUNE,34880,LAVERUNE,,43.5848099789,3.80168949969,134,,Lavérune,Lavérune,34,Hérault,76,Occitanie
34135,LESPIGNAN,34710,LESPIGNAN,,43.2737109255,3.16982256617,135,,Lespignan,Lespignan,34,Hérault,76,Occitanie
34136,LEZIGNAN LA CEBE,34120,LEZIGNAN LA CEBE,,43.4922078632,3.43960981956,136,,Lézignan-la-Cèbe,Lézignan-la-Cèbe,34,Hérault,76,Occitanie
34137,LIAUSSON,34800,LIAUSSON,,43.6402749752,3.36044063892,137,,Liausson,Liausson,34,Hérault,76,Occitanie
34138,LIEURAN CABRIERES,34800,LIEURAN CABRIERES,,43.5916311464,3.40878385733,138,,Lieuran-Cabrières,Lieuran-Cabrières,34,Hérault,76,Occitanie
34139,LIEURAN LES BEZIERS,34290,LIEURAN LES BEZIERS,,43.4122997013,3.23066232037,139,,Lieuran-lès-Béziers,Lieuran-lès-Béziers,34,Hérault,76,Occitanie
34140,LIGNAN SUR ORB,34490,LIGNAN SUR ORB,,43.382486634,3.17270457784,140,,Lignan-sur-Orb,Lignan-sur-Orb,34,Hérault,76,Occitanie
34141,LA LIVINIERE,34210,LA LIVINIERE,,43.3372267899,2.63272340257,141,La,Livinière,La Livinière,34,Hérault,76,Occitanie
34142,LODEVE,34700,LODEVE,,43.7307354901,3.30567667879,142,,Lodève,Lodève,34,Hérault,76,Occitanie
34143,LOUPIAN,34140,LOUPIAN,,43.4494192218,3.62638267982,143,,Loupian,Loupian,34,Hérault,76,Occitanie
34144,LUNAS,34650,LUNAS,,43.7089785299,3.20607492455,144,,Lunas,Lunas,34,Hérault,76,Occitanie
34145,LUNEL,34400,LUNEL,,43.6820481757,4.13566472301,145,,Lunel,Lunel,34,Hérault,76,Occitanie
34146,LUNEL VIEL,34400,LUNEL VIEL,,43.6826642395,4.08422296335,146,,Lunel-Viel,Lunel-Viel,34,Hérault,76,Occitanie
34147,MAGALAS,34480,MAGALAS,,43.4808688877,3.21744589266,147,,Magalas,Magalas,34,Hérault,76,Occitanie
34148,MARAUSSAN,34370,MARAUSSAN,,43.370567073,3.1537894615,148,,Maraussan,Maraussan,34,Hérault,76,Occitanie
34149,MARGON,34320,MARGON,,43.4864901755,3.30406918161,149,,Margon,Margon,34,Hérault,76,Occitanie
34150,MARSEILLAN,34340,MARSEILLAN,,43.3605776007,3.54718963761,150,,Marseillan,Marseillan,34,Hérault,76,Occitanie
34150,MARSEILLAN,34340,MARSEILLAN,MARSEILLAN PLAGE,43.3605776007,3.54718963761,150,,Marseillan,Marseillan,34,Hérault,76,Occitanie
34151,MARSILLARGUES,34590,MARSILLARGUES,,43.6268280772,4.14781957357,151,,Marsillargues,Marsillargues,34,Hérault,76,Occitanie
34152,MAS DE LONDRES,34380,MAS DE LONDRES,,43.7875830947,3.77381385903,152,,Mas-de-Londres,Mas-de-Londres,34,Hérault,76,Occitanie
34153,LES MATELLES,34270,LES MATELLES,,43.7279155498,3.8068218202,153,Les,Matelles,Les Matelles,34,Hérault,76,Occitanie
34154,MAUGUIO,34130,MAUGUIO,,43.5928279472,4.00425693337,154,,Mauguio,Mauguio,34,Hérault,76,Occitanie
34154,MAUGUIO,34280,MAUGUIO,CARNON PLAGE,43.5928279472,4.00425693337,154,,Mauguio,Mauguio,34,Hérault,76,Occitanie
34155,MAUREILHAN,34370,MAUREILHAN,,43.3515394407,3.10170846517,155,,Maureilhan,Maureilhan,34,Hérault,76,Occitanie
34156,MERIFONS,34800,MERIFONS,,43.6355182849,3.28015799164,156,,Mérifons,Mérifons,34,Hérault,76,Occitanie
34157,MEZE,34140,MEZE,,43.4292981138,3.57774572973,157,,Mèze,Mèze,34,Hérault,76,Occitanie
34158,MINERVE,34210,MINERVE,,43.3629052396,2.72160547585,158,,Minerve,Minerve,34,Hérault,76,Occitanie
34159,MIREVAL,34110,MIREVAL,,43.5170572388,3.80306531824,159,,Mireval,Mireval,34,Hérault,76,Occitanie
34160,MONS,34390,MONS,,43.5698246812,2.96307448549,160,,Mons,Mons,34,Hérault,76,Occitanie
34161,MONTADY,34310,MONTADY,,43.3285047609,3.11398724899,161,,Montady,Montady,34,Hérault,76,Occitanie
34162,MONTAGNAC,34530,MONTAGNAC,,43.4745284586,3.51074216978,162,,Montagnac,Montagnac,34,Hérault,76,Occitanie
34163,MONTARNAUD,34570,MONTARNAUD,,43.6470278582,3.69970280024,163,,Montarnaud,Montarnaud,34,Hérault,76,Occitanie
34164,MONTAUD,34160,MONTAUD,,43.7478625345,3.96019972895,164,,Montaud,Montaud,34,Hérault,76,Occitanie
34165,MONTBAZIN,34560,MONTBAZIN,,43.5327997823,3.67193055324,165,,Montbazin,Montbazin,34,Hérault,76,Occitanie
34166,MONTBLANC,34290,MONTBLANC,,43.3707718036,3.35755094036,166,,Montblanc,Montblanc,34,Hérault,76,Occitanie
34167,MONTELS,34310,MONTELS,,43.2896268975,3.02567631465,167,,Montels,Montels,34,Hérault,76,Occitanie
34168,MONTESQUIEU,34320,MONTESQUIEU,,43.5621026684,3.27145529278,168,,Montesquieu,Montesquieu,34,Hérault,76,Occitanie
34169,MONTFERRIER SUR LEZ,34980,MONTFERRIER SUR LEZ,,43.6690783518,3.86408542938,169,,Montferrier-sur-Lez,Montferrier-sur-Lez,34,Hérault,76,Occitanie
34170,MONTOULIERS,34310,MONTOULIERS,,43.3353694214,2.90623639932,170,,Montouliers,Montouliers,34,Hérault,76,Occitanie
34171,MONTOULIEU,34190,MONTOULIEU,,43.919242211,3.80187206005,171,,Montoulieu,Montoulieu,34,Hérault,76,Occitanie
34172,MONTPELLIER,34000,MONTPELLIER,,43.6134409138,3.86851657896,172,,Montpellier,Montpellier,34,Hérault,76,Occitanie
34172,MONTPELLIER,34070,MONTPELLIER,,43.6134409138,3.86851657896,172,,Montpellier,Montpellier,34,Hérault,76,Occitanie
34172,MONTPELLIER,34080,MONTPELLIER,,43.6134409138,3.86851657896,172,,Montpellier,Montpellier,34,Hérault,76,Occitanie
34172,MONTPELLIER,34090,MONTPELLIER,,43.6134409138,3.86851657896,172,,Montpellier,Montpellier,34,Hérault,76,Occitanie
34173,MONTPEYROUX,34150,MONTPEYROUX,,43.711158125,3.50240555203,173,,Montpeyroux,Montpeyroux,34,Hérault,76,Occitanie
34174,MOULES ET BAUCELS,34190,MOULES ET BAUCELS,,43.9497259922,3.75555165229,174,,Moulès-et-Baucels,Moulès-et-Baucels,34,Hérault,76,Occitanie
34175,MOUREZE,34800,MOUREZE,,43.6156849322,3.35101426907,175,,Mourèze,Mourèze,34,Hérault,76,Occitanie
34176,MUDAISON,34130,MUDAISON,,43.6402176481,4.03634822151,176,,Mudaison,Mudaison,34,Hérault,76,Occitanie
34177,MURLES,34980,MURLES,,43.7017303603,3.75723604182,177,,Murles,Murles,34,Hérault,76,Occitanie
34178,MURVIEL LES BEZIERS,34490,MURVIEL LES BEZIERS,,43.4576685107,3.13592247665,178,,Murviel-lès-Béziers,Murviel-lès-Béziers,34,Hérault,76,Occitanie
34179,MURVIEL LES MONTPELLIER,34570,MURVIEL LES MONTPELLIER,,43.6095675101,3.73935842429,179,,Murviel-lès-Montpellier,Murviel-lès-Montpellier,34,Hérault,76,Occitanie
34180,NEBIAN,34800,NEBIAN,,43.5995432245,3.43678111885,180,,Nébian,Nébian,34,Hérault,76,Occitanie
34181,NEFFIES,34320,NEFFIES,,43.5393423873,3.33354409395,181,,Neffiès,Neffiès,34,Hérault,76,Occitanie
34182,NEZIGNAN L EVEQUE,34120,NEZIGNAN L EVEQUE,,43.4214743639,3.4038201049,182,,Nézignan-l'Évêque,Nézignan-l'Évêque,34,Hérault,76,Occitanie
34183,NISSAN LEZ ENSERUNE,34440,NISSAN LEZ ENSERUNE,,43.2811833354,3.11227016,183,,Nissan-lez-Enserune,Nissan-lez-Enserune,34,Hérault,76,Occitanie
34184,NIZAS,34320,NIZAS,,43.5137142122,3.41956425429,184,,Nizas,Nizas,34,Hérault,76,Occitanie
34185,NOTRE DAME DE LONDRES,34380,NOTRE DAME DE LONDRES,,43.8324773443,3.75718423369,185,,Notre-Dame-de-Londres,Notre-Dame-de-Londres,34,Hérault,76,Occitanie
34186,OCTON,34800,OCTON,,43.6574397146,3.29580652136,186,,Octon,Octon,34,Hérault,76,Occitanie
34187,OLARGUES,34390,OLARGUES,,43.540135455,2.91772316116,187,,Olargues,Olargues,34,Hérault,76,Occitanie
34188,OLMET ET VILLECUN,34700,OLMET ET VILLECUN,,43.7080042614,3.29790307096,188,,Olmet-et-Villecun,Olmet-et-Villecun,34,Hérault,76,Occitanie
34189,OLONZAC,34210,OLONZAC,,43.2811329903,2.73729224863,189,,Olonzac,Olonzac,34,Hérault,76,Occitanie
34190,OUPIA,34210,OUPIA,,43.2886870933,2.77613204437,190,,Oupia,Oupia,34,Hérault,76,Occitanie
34191,PAILHES,34490,PAILHES,,43.4371324494,3.18478770413,191,,Pailhès,Pailhès,34,Hérault,76,Occitanie
34192,PALAVAS LES FLOTS,34250,PALAVAS LES FLOTS,,43.533536456,3.92620839566,192,,Palavas-les-Flots,Palavas-les-Flots,34,Hérault,76,Occitanie
34193,PARDAILHAN,34360,PARDAILHAN,,43.4450155578,2.83724812939,193,,Pardailhan,Pardailhan,34,Hérault,76,Occitanie
34194,PAULHAN,34230,PAULHAN,,43.5382461437,3.45878001705,194,,Paulhan,Paulhan,34,Hérault,76,Occitanie
34195,PEGAIROLLES DE BUEGES,34380,PEGAIROLLES DE BUEGES,,43.8051515536,3.57590546556,195,,Pégairolles-de-Buèges,Pégairolles-de-Buèges,34,Hérault,76,Occitanie
34196,PEGAIROLLES DE L ESCALETTE,34700,PEGAIROLLES DE L ESCALETTE,,43.8129638311,3.33457368813,196,,Pégairolles-de-l'Escalette,Pégairolles-de-l'Escalette,34,Hérault,76,Occitanie
34197,PERET,34800,PERET,,43.5749250914,3.39831770364,197,,Péret,Péret,34,Hérault,76,Occitanie
34198,PEROLS,34470,PEROLS,,43.5606642689,3.95175708518,198,,Pérols,Pérols,34,Hérault,76,Occitanie
34199,PEZENAS,34120,PEZENAS,,43.462990168,3.41651652125,199,,Pézenas,Pézenas,34,Hérault,76,Occitanie
34200,PEZENES LES MINES,34600,PEZENES LES MINES,,43.5990802709,3.24486558872,200,,Pézènes-les-Mines,Pézènes-les-Mines,34,Hérault,76,Occitanie
34201,PIERRERUE,34360,PIERRERUE,,43.4408898427,2.96353265776,201,,Pierrerue,Pierrerue,34,Hérault,76,Occitanie
34202,PIGNAN,34570,PIGNAN,,43.5855297709,3.74922617626,202,,Pignan,Pignan,34,Hérault,76,Occitanie
34203,PINET,34850,PINET,,43.4090371213,3.51249503942,203,,Pinet,Pinet,34,Hérault,76,Occitanie
34204,PLAISSAN,34230,PLAISSAN,,43.5590614886,3.52978704961,204,,Plaissan,Plaissan,34,Hérault,76,Occitanie
34205,LES PLANS,34700,LES PLANS,,43.7561210421,3.26564672986,205,Les,Plans,Les Plans,34,Hérault,76,Occitanie
34206,POILHES,34310,POILHES,,43.3045807211,3.08955688711,206,,Poilhes,Poilhes,34,Hérault,76,Occitanie
34207,POMEROLS,34810,POMEROLS,,43.3966621859,3.51470119674,207,,Pomérols,Pomérols,34,Hérault,76,Occitanie
34208,POPIAN,34230,POPIAN,,43.6241428585,3.53329416559,208,,Popian,Popian,34,Hérault,76,Occitanie
34209,PORTIRAGNES,34420,PORTIRAGNES,,43.301736727,3.3476310741,209,,Portiragnes,Portiragnes,34,Hérault,76,Occitanie
34210,LE POUGET,34230,LE POUGET,,43.5925476738,3.51727150563,210,Le,Pouget,Le Pouget,34,Hérault,76,Occitanie
34211,LE POUJOL SUR ORB,34600,LE POUJOL SUR ORB,,43.5830682965,3.05437993033,211,Le,Poujol-sur-Orb,Le Poujol-sur-Orb,34,Hérault,76,Occitanie
34212,POUJOLS,34700,POUJOLS,,43.7720254249,3.3186584195,212,,Poujols,Poujols,34,Hérault,76,Occitanie
34213,POUSSAN,34560,POUSSAN,,43.4938682747,3.66060526328,213,,Poussan,Poussan,34,Hérault,76,Occitanie
34214,POUZOLLES,34480,POUZOLLES,,43.4790289284,3.27343526543,214,,Pouzolles,Pouzolles,34,Hérault,76,Occitanie
34215,POUZOLS,34230,POUZOLS,,43.6204813753,3.50913792041,215,,Pouzols,Pouzols,34,Hérault,76,Occitanie
34216,LE PRADAL,34600,LE PRADAL,,43.6331733126,3.09603125078,216,Le,Pradal,Le Pradal,34,Hérault,76,Occitanie
34217,PRADES LE LEZ,34730,PRADES LE LEZ,,43.7072541597,3.86489213915,217,,Prades-le-Lez,Prades-le-Lez,34,Hérault,76,Occitanie
34218,PRADES SUR VERNAZOBRE,34360,PRADES SUR VERNAZOBRE,,43.4587254561,2.97637908047,218,,Prades-sur-Vernazobre,Prades-sur-Vernazobre,34,Hérault,76,Occitanie
34219,PREMIAN,34390,PREMIAN,,43.5395150426,2.82265477,219,,Prémian,Prémian,34,Hérault,76,Occitanie
34220,LE PUECH,34700,LE PUECH,,43.6831429742,3.32501216649,220,Le,Puech,Le Puech,34,Hérault,76,Occitanie
34221,PUECHABON,34150,PUECHABON,,43.7250949806,3.60615436685,221,,Puéchabon,Puéchabon,34,Hérault,76,Occitanie
34222,PUILACHER,34230,PUILACHER,,43.5667592874,3.50438177235,222,,Puilacher,Puilacher,34,Hérault,76,Occitanie
34223,PUIMISSON,34480,PUIMISSON,,43.4378848969,3.20792607822,223,,Puimisson,Puimisson,34,Hérault,76,Occitanie
34224,PUISSALICON,34480,PUISSALICON,,43.4539009157,3.23775688397,224,,Puissalicon,Puissalicon,34,Hérault,76,Occitanie
34225,PUISSERGUIER,34620,PUISSERGUIER,,43.3772339365,3.04590546447,225,,Puisserguier,Puisserguier,34,Hérault,76,Occitanie
34226,QUARANTE,34310,QUARANTE,,43.3507961482,2.980712669,226,,Quarante,Quarante,34,Hérault,76,Occitanie
34227,RESTINCLIERES,34160,RESTINCLIERES,,43.7241060431,4.04113886056,227,,Restinclières,Restinclières,34,Hérault,76,Occitanie
34228,RIEUSSEC,34220,RIEUSSEC,,43.4221214711,2.73234133546,228,,Rieussec,Rieussec,34,Hérault,76,Occitanie
34229,RIOLS,34220,RIOLS,,43.5020076583,2.7978815483,229,,Riols,Riols,34,Hérault,76,Occitanie
34230,LES RIVES,34520,LES RIVES,,43.8557506126,3.26405102266,230,Les,Rives,Les Rives,34,Hérault,76,Occitanie
34231,ROMIGUIERES,34650,ROMIGUIERES,,43.8174606939,3.23863631035,231,,Romiguières,Romiguières,34,Hérault,76,Occitanie
34232,ROQUEBRUN,34460,ROQUEBRUN,,43.5055301367,3.01020767963,232,,Roquebrun,Roquebrun,34,Hérault,76,Occitanie
34233,ROQUEREDONDE,34650,ROQUEREDONDE,,43.7962962251,3.20797377545,233,,Roqueredonde,Roqueredonde,34,Hérault,76,Occitanie
34234,ROQUESSELS,34320,ROQUESSELS,,43.5504361002,3.22634826627,234,,Roquessels,Roquessels,34,Hérault,76,Occitanie
34235,ROSIS,34610,ROSIS,,43.6281696619,2.99215452371,235,,Rosis,Rosis,34,Hérault,76,Occitanie
34236,ROUET,34380,LE ROUET,,43.8258792958,3.81594115677,236,,Rouet,Rouet,34,Hérault,76,Occitanie
34237,ROUJAN,34320,ROUJAN,,43.5109460252,3.30936586252,237,,Roujan,Roujan,34,Hérault,76,Occitanie
34238,ST ANDRE DE BUEGES,34190,ST ANDRE DE BUEGES,,43.8538870813,3.64957493521,238,,Saint-André-de-Buèges,Saint-André-de-Buèges,34,Hérault,76,Occitanie
34239,ST ANDRE DE SANGONIS,34725,ST ANDRE DE SANGONIS,,43.6502281651,3.50287642429,239,,Saint-André-de-Sangonis,Saint-André-de-Sangonis,34,Hérault,76,Occitanie
34240,ST AUNES,34130,ST AUNES,,43.6349513822,3.96578818387,240,,Saint-Aunès,Saint-Aunès,34,Hérault,76,Occitanie
34241,ST BAUZILLE DE LA SYLVE,34230,ST BAUZILLE DE LA SYLVE,,43.6102378186,3.55875643759,241,,Saint-Bauzille-de-la-Sylve,Saint-Bauzille-de-la-Sylve,34,Hérault,76,Occitanie
34242,ST BAUZILLE DE MONTMEL,34160,ST BAUZILLE DE MONTMEL,,43.776222742,3.95036124695,242,,Saint-Bauzille-de-Montmel,Saint-Bauzille-de-Montmel,34,Hérault,76,Occitanie
34243,ST BAUZILLE DE PUTOIS,34190,ST BAUZILLE DE PUTOIS,,43.9007200249,3.75901045062,243,,Saint-Bauzille-de-Putois,Saint-Bauzille-de-Putois,34,Hérault,76,Occitanie
34244,ST BRES,34670,ST BRES,,43.6665945893,4.03794071375,244,,Saint-Brès,Saint-Brès,34,Hérault,76,Occitanie
34245,ST CHINIAN,34360,ST CHINIAN,,43.4211029335,2.93138227583,245,,Saint-Chinian,Saint-Chinian,34,Hérault,76,Occitanie
34246,ST CHRISTOL,34400,ST CHRISTOL,,43.7236376937,4.07222709677,246,,Saint-Christol,Saint-Christol,34,Hérault,76,Occitanie
34247,ST CLEMENT DE RIVIERE,34980,ST CLEMENT DE RIVIERE,,43.6860686936,3.84318936676,247,,Saint-Clément-de-Rivière,Saint-Clément-de-Rivière,34,Hérault,76,Occitanie
34248,STE CROIX DE QUINTILLARGUES,34270,STE CROIX DE QUINTILLARGUES,,43.7680759693,3.91047320364,248,,Sainte-Croix-de-Quintillargues,Sainte-Croix-de-Quintillargues,34,Hérault,76,Occitanie
34249,ST DREZERY,34160,ST DREZERY,,43.7284558791,3.97774419373,249,,Saint-Drézéry,Saint-Drézéry,34,Hérault,76,Occitanie
34250,ST ETIENNE D ALBAGNAN,34390,ST ETIENNE D ALBAGNAN,,43.5283591648,2.86528483537,250,,Saint-Étienne-d'Albagnan,Saint-Étienne-d'Albagnan,34,Hérault,76,Occitanie
34251,ST ETIENNE DE GOURGAS,34700,ST ETIENNE DE GOURGAS,,43.7836963728,3.38499935958,251,,Saint-Étienne-de-Gourgas,Saint-Étienne-de-Gourgas,34,Hérault,76,Occitanie
34252,ST ETIENNE ESTRECHOUX,34260,ST ETIENNE ESTRECHOUX,,43.6605180139,3.10431751286,252,,Saint-Étienne-Estréchoux,Saint-Étienne-Estréchoux,34,Hérault,76,Occitanie
34253,ST FELIX DE L HERAS,34520,ST FELIX DE L HERAS,,43.8393766952,3.31453583361,253,,Saint-Félix-de-l'Héras,Saint-Félix-de-l'Héras,34,Hérault,76,Occitanie
34254,ST FELIX DE LODEZ,34725,ST FELIX DE LODEZ,,43.6608409227,3.46889642638,254,,Saint-Félix-de-Lodez,Saint-Félix-de-Lodez,34,Hérault,76,Occitanie
34255,ST GELY DU FESC,34980,ST GELY DU FESC,,43.6896523672,3.80969577473,255,,Saint-Gély-du-Fesc,Saint-Gély-du-Fesc,34,Hérault,76,Occitanie
34256,ST GENIES DES MOURGUES,34160,ST GENIES DES MOURGUES,,43.6972031545,4.03751252164,256,,Saint-Geniès-des-Mourgues,Saint-Geniès-des-Mourgues,34,Hérault,76,Occitanie
34257,ST GENIES DE VARENSAL,34610,ST GENIES DE VARENSAL,,43.6879655757,3.00291654936,257,,Saint-Geniès-de-Varensal,Saint-Geniès-de-Varensal,34,Hérault,76,Occitanie
34257,ST GENIES DE VARENSAL,34610,ST GENIES DE VARENSAL,PLAISANCE,43.6879655757,3.00291654936,257,,Saint-Geniès-de-Varensal,Saint-Geniès-de-Varensal,34,Hérault,76,Occitanie
34258,ST GENIES DE FONTEDIT,34480,ST GENIES DE FONTEDIT,,43.4689118509,3.17497763189,258,,Saint-Geniès-de-Fontedit,Saint-Geniès-de-Fontedit,34,Hérault,76,Occitanie
34259,ST GEORGES D ORQUES,34680,ST GEORGES D ORQUES,,43.6168353652,3.77376629814,259,,Saint-Georges-d'Orques,Saint-Georges-d'Orques,34,Hérault,76,Occitanie
34260,ST GERVAIS SUR MARE,34610,ST GERVAIS SUR MARE,,43.6671393403,3.0497555288,260,,Saint-Gervais-sur-Mare,Saint-Gervais-sur-Mare,34,Hérault,76,Occitanie
34261,ST GUILHEM LE DESERT,34150,ST GUILHEM LE DESERT,,43.756664412,3.54505481445,261,,Saint-Guilhem-le-Désert,Saint-Guilhem-le-Désert,34,Hérault,76,Occitanie
34262,ST GUIRAUD,34725,ST GUIRAUD,,43.6784032786,3.45134218605,262,,Saint-Guiraud,Saint-Guiraud,34,Hérault,76,Occitanie
34263,ST HILAIRE DE BEAUVOIR,34160,ST HILAIRE DE BEAUVOIR,,43.7530311064,4.01945192332,263,,Saint-Hilaire-de-Beauvoir,Saint-Hilaire-de-Beauvoir,34,Hérault,76,Occitanie
34264,ST JEAN DE BUEGES,34380,ST JEAN DE BUEGES,,43.8341627793,3.60534795017,264,,Saint-Jean-de-Buèges,Saint-Jean-de-Buèges,34,Hérault,76,Occitanie
34265,ST JEAN DE CORNIES,34160,ST JEAN DE CORNIES,,43.744776404,4.00334009324,265,,Saint-Jean-de-Cornies,Saint-Jean-de-Cornies,34,Hérault,76,Occitanie
34266,ST JEAN DE CUCULLES,34270,ST JEAN DE CUCULLES,,43.7516512162,3.83370653974,266,,Saint-Jean-de-Cuculles,Saint-Jean-de-Cuculles,34,Hérault,76,Occitanie
34267,ST JEAN DE FOS,34150,ST JEAN DE FOS,,43.6938600875,3.5464221395,267,,Saint-Jean-de-Fos,Saint-Jean-de-Fos,34,Hérault,76,Occitanie
34268,ST JEAN DE LA BLAQUIERE,34700,ST JEAN DE LA BLAQUIERE,,43.7101712397,3.42987273184,268,,Saint-Jean-de-la-Blaquière,Saint-Jean-de-la-Blaquière,34,Hérault,76,Occitanie
34269,ST JEAN DE MINERVOIS,34360,ST JEAN DE MINERVOIS,,43.3998259676,2.8186318647,269,,Saint-Jean-de-Minervois,Saint-Jean-de-Minervois,34,Hérault,76,Occitanie
34270,ST JEAN DE VEDAS,34430,ST JEAN DE VEDAS,,43.5716282319,3.83221847952,270,,Saint-Jean-de-Védas,Saint-Jean-de-Védas,34,Hérault,76,Occitanie
34271,ST JULIEN,34390,ST JULIEN,,43.5848869061,2.9022163453,271,,Saint-Julien,Saint-Julien,34,Hérault,76,Occitanie
34272,ST JUST,34400,ST JUST,,43.6561845666,4.1118455055,272,,Saint-Just,Saint-Just,34,Hérault,76,Occitanie
34273,ST MARTIN DE L ARCON,34390,ST MARTIN DE L ARCON,,43.5800016675,2.98506078379,273,,Saint-Martin-de-l'Arçon,Saint-Martin-de-l'Arçon,34,Hérault,76,Occitanie
34274,ST MARTIN DE LONDRES,34380,ST MARTIN DE LONDRES,,43.7926372953,3.70749671559,274,,Saint-Martin-de-Londres,Saint-Martin-de-Londres,34,Hérault,76,Occitanie
34276,ST MATHIEU DE TREVIERS,34270,ST MATHIEU DE TREVIERS,,43.7692073898,3.87002027505,276,,Saint-Mathieu-de-Tréviers,Saint-Mathieu-de-Tréviers,34,Hérault,76,Occitanie
34277,ST MAURICE NAVACELLES,34190,ST MAURICE NAVACELLES,,43.8448734239,3.5062650669,277,,Saint-Maurice-Navacelles,Saint-Maurice-Navacelles,34,Hérault,76,Occitanie
34277,ST MAURICE NAVACELLES,34520,ST MAURICE NAVACELLES,,43.8448734239,3.5062650669,277,,Saint-Maurice-Navacelles,Saint-Maurice-Navacelles,34,Hérault,76,Occitanie
34278,ST MICHEL,34520,ST MICHEL,,43.8408209485,3.40636511329,278,,Saint-Michel,Saint-Michel,34,Hérault,76,Occitanie
34279,ST NAZAIRE DE LADAREZ,34490,ST NAZAIRE DE LADAREZ,,43.5174613218,3.08019366504,279,,Saint-Nazaire-de-Ladarez,Saint-Nazaire-de-Ladarez,34,Hérault,76,Occitanie
34280,ST NAZAIRE DE PEZAN,34400,ST NAZAIRE DE PEZAN,,43.6351201941,4.11706198292,280,,Saint-Nazaire-de-Pézan,Saint-Nazaire-de-Pézan,34,Hérault,76,Occitanie
34281,ST PARGOIRE,34230,ST PARGOIRE,,43.5312864116,3.54356674694,281,,Saint-Pargoire,Saint-Pargoire,34,Hérault,76,Occitanie
34282,ST PAUL ET VALMALLE,34570,ST PAUL ET VALMALLE,,43.6147377182,3.67925764242,282,,Saint-Paul-et-Valmalle,Saint-Paul-et-Valmalle,34,Hérault,76,Occitanie
34283,ST PIERRE DE LA FAGE,34520,ST PIERRE DE LA FAGE,,43.7942818756,3.42249252339,283,,Saint-Pierre-de-la-Fage,Saint-Pierre-de-la-Fage,34,Hérault,76,Occitanie
34284,ST PONS DE THOMIERES,34220,ST PONS DE THOMIERES,,43.4788345407,2.74852691953,284,,Saint-Pons-de-Thomières,Saint-Pons-de-Thomières,34,Hérault,76,Occitanie
34285,ST PONS DE MAUCHIENS,34230,ST PONS DE MAUCHIENS,,43.5093525294,3.51237930309,285,,Saint-Pons-de-Mauchiens,Saint-Pons-de-Mauchiens,34,Hérault,76,Occitanie
34286,ST PRIVAT,34700,ST PRIVAT,,43.7510304618,3.43193440263,286,,Saint-Privat,Saint-Privat,34,Hérault,76,Occitanie
34287,ST SATURNIN DE LUCIAN,34725,ST SATURNIN DE LUCIAN,,43.7020641243,3.46562017139,287,,Saint-Saturnin-de-Lucian,Saint-Saturnin-de-Lucian,34,Hérault,76,Occitanie
34288,ST SERIES,34400,ST SERIES,,43.7355275594,4.1049570212,288,,Saint-Sériès,Saint-Sériès,34,Hérault,76,Occitanie
34289,ST THIBERY,34630,ST THIBERY,,43.3927611076,3.4008093198,289,,Saint-Thibéry,Saint-Thibéry,34,Hérault,76,Occitanie
34290,ST VINCENT DE BARBEYRARGUES,34730,ST VINCENT DE BARBEYRARGUES,,43.7107487308,3.87689704795,290,,Saint-Vincent-de-Barbeyrargues,Saint-Vincent-de-Barbeyrargues,34,Hérault,76,Occitanie
34291,ST VINCENT D OLARGUES,34390,ST VINCENT D OLARGUES,,43.5598159229,2.8730344645,291,,Saint-Vincent-d'Olargues,Saint-Vincent-d'Olargues,34,Hérault,76,Occitanie
34292,SALASC,34800,SALASC,,43.6220930985,3.31240348008,292,,Salasc,Salasc,34,Hérault,76,Occitanie
34293,LA SALVETAT SUR AGOUT,34330,LA SALVETAT SUR AGOUT,,43.6021759228,2.68705591152,293,La,Salvetat-sur-Agout,La Salvetat-sur-Agout,34,Hérault,76,Occitanie
34294,SATURARGUES,34400,SATURARGUES,,43.7142810482,4.11714344824,294,,Saturargues,Saturargues,34,Hérault,76,Occitanie
34295,SAUSSAN,34570,SAUSSAN,,43.569013259,3.78095911379,295,,Saussan,Saussan,34,Hérault,76,Occitanie
34296,SAUSSINES,34160,SAUSSINES,,43.758954777,4.05038800559,296,,Saussines,Saussines,34,Hérault,76,Occitanie
34297,SAUTEYRARGUES,34270,SAUTEYRARGUES,,43.830914793,3.91858398089,297,,Sauteyrargues,Sauteyrargues,34,Hérault,76,Occitanie
34298,SAUVIAN,34410,SAUVIAN,,43.2910373806,3.25406479284,298,,Sauvian,Sauvian,34,Hérault,76,Occitanie
34299,SERIGNAN,34410,SERIGNAN,,43.2733185307,3.29725083254,299,,Sérignan,Sérignan,34,Hérault,76,Occitanie
34300,SERVIAN,34290,SERVIAN,,43.4143351267,3.30645261407,300,,Servian,Servian,34,Hérault,76,Occitanie
34301,SETE,34200,SETE,,43.3917705831,3.64705148296,301,,Sète,Sète,34,Hérault,76,Occitanie
34302,SIRAN,34210,SIRAN,,43.3302941258,2.66325916558,302,,Siran,Siran,34,Hérault,76,Occitanie
34303,SORBS,34520,SORBS,,43.8909610556,3.40642995016,303,,Sorbs,Sorbs,34,Hérault,76,Occitanie
34304,SOUBES,34700,SOUBES,,43.7796056987,3.35201368511,304,,Soubès,Soubès,34,Hérault,76,Occitanie
34305,LE SOULIE,34330,LE SOULIE,,43.5430961388,2.68991254335,305,Le,Soulié,Le Soulié,34,Hérault,76,Occitanie
34306,SOUMONT,34700,SOUMONT,,43.7254656283,3.34970945877,306,,Soumont,Soumont,34,Hérault,76,Occitanie
34307,SUSSARGUES,34160,SUSSARGUES,,43.7090174126,3.99451003779,307,,Sussargues,Sussargues,34,Hérault,76,Occitanie
34308,TAUSSAC LA BILLIERE,34600,TAUSSAC LA BILLIERE,,43.6330025046,3.07651674533,308,,Taussac-la-Billière,Taussac-la-Billière,34,Hérault,76,Occitanie
34309,TEYRAN,34820,TEYRAN,,43.6849602174,3.92844546049,309,,Teyran,Teyran,34,Hérault,76,Occitanie
34310,THEZAN LES BEZIERS,34490,THEZAN LES BEZIERS,,43.4132925116,3.15810877475,310,,Thézan-lès-Béziers,Thézan-lès-Béziers,34,Hérault,76,Occitanie
34311,TOURBES,34120,TOURBES,,43.4426128717,3.37325063166,311,,Tourbes,Tourbes,34,Hérault,76,Occitanie
34312,LA TOUR SUR ORB,34260,LA TOUR SUR ORB,,43.6555250797,3.15144431615,312,La,Tour-sur-Orb,La Tour-sur-Orb,34,Hérault,76,Occitanie
34313,TRESSAN,34230,TRESSAN,,43.5728727057,3.48660359956,313,,Tressan,Tressan,34,Hérault,76,Occitanie
34314,LE TRIADOU,34270,LE TRIADOU,,43.7367810666,3.85739515015,314,Le,Triadou,Le Triadou,34,Hérault,76,Occitanie
34315,USCLAS D HERAULT,34230,USCLAS D HERAULT,,43.5218019107,3.46731273264,315,,Usclas-d'Hérault,Usclas-d'Hérault,34,Hérault,76,Occitanie
34316,USCLAS DU BOSC,34700,USCLAS DU BOSC,,43.7281811066,3.39872803324,316,,Usclas-du-Bosc,Usclas-du-Bosc,34,Hérault,76,Occitanie
34317,LA VACQUERIE ET ST MARTIN DE CASTRIES,34520,LA VACQUERIE ST MARTIN CASTRIES,,43.7940763777,3.481075382,317,La,Vacquerie-et-Saint-Martin-de-Castries,La Vacquerie-et-Saint-Martin-de-Castries,34,Hérault,76,Occitanie
34318,VACQUIERES,34270,VACQUIERES,,43.828951354,3.95059092593,318,,Vacquières,Vacquières,34,Hérault,76,Occitanie
34319,VAILHAN,34320,VAILHAN,,43.5542014743,3.29989404644,319,,Vailhan,Vailhan,34,Hérault,76,Occitanie
34320,VAILHAUQUES,34570,VAILHAUQUES,,43.6641779009,3.72842619012,320,,Vailhauquès,Vailhauquès,34,Hérault,76,Occitanie
34321,VALERGUES,34130,VALERGUES,,43.67240395,4.05870783975,321,,Valergues,Valergues,34,Hérault,76,Occitanie
34322,VALFLAUNES,34270,VALFLAUNES,,43.8020094105,3.86188285063,322,,Valflaunès,Valflaunès,34,Hérault,76,Occitanie
34323,VALMASCLE,34800,VALMASCLE,,43.6001846206,3.2999967216,323,,Valmascle,Valmascle,34,Hérault,76,Occitanie
34324,VALRAS PLAGE,34350,VALRAS PLAGE,,43.2476112282,3.28929431362,324,,Valras-Plage,Valras-Plage,34,Hérault,76,Occitanie
34325,VALROS,34290,VALROS,,43.419189427,3.36141259687,325,,Valros,Valros,34,Hérault,76,Occitanie
34326,VELIEUX,34220,VELIEUX,,43.3902090063,2.74495377415,326,,Vélieux,Vélieux,34,Hérault,76,Occitanie
34327,VENDARGUES,34740,VENDARGUES,,43.6610457488,3.96283033113,327,,Vendargues,Vendargues,34,Hérault,76,Occitanie
34328,VENDEMIAN,34230,VENDEMIAN,,43.5790567885,3.56253401848,328,,Vendémian,Vendémian,34,Hérault,76,Occitanie
34329,VENDRES,34350,VENDRES,,43.2581519414,3.22720260244,329,,Vendres,Vendres,34,Hérault,76,Occitanie
34330,VERARGUES,34400,VERARGUES,,43.708930199,4.09292324004,330,,Vérargues,Vérargues,34,Hérault,76,Occitanie
34331,VERRERIES DE MOUSSANS,34220,VERRERIES DE MOUSSANS,,43.4493841395,2.67914780973,331,,Verreries-de-Moussans,Verreries-de-Moussans,34,Hérault,76,Occitanie
34332,VIAS,34450,VIAS,,43.3131142636,3.39889710362,332,,Vias,Vias,34,Hérault,76,Occitanie
34333,VIC LA GARDIOLE,34110,VIC LA GARDIOLE,,43.4862693496,3.80342869911,333,,Vic-la-Gardiole,Vic-la-Gardiole,34,Hérault,76,Occitanie
34334,VIEUSSAN,34390,VIEUSSAN,,43.5401854893,3.00065269263,334,,Vieussan,Vieussan,34,Hérault,76,Occitanie
34335,VILLEMAGNE L ARGENTIERE,34600,VILLEMAGNE L ARGENTIERE,,43.6189067153,3.12143240976,335,,Villemagne-l'Argentière,Villemagne-l'Argentière,34,Hérault,76,Occitanie
34336,VILLENEUVE LES BEZIERS,34420,VILLENEUVE LES BEZIERS,,43.3172489997,3.28958494349,336,,Villeneuve-lès-Béziers,Villeneuve-lès-Béziers,34,Hérault,76,Occitanie
34336,VILLENEUVE LES BEZIERS,34500,VILLENEUVE LES BEZIERS,,43.3172489997,3.28958494349,336,,Villeneuve-lès-Béziers,Villeneuve-lès-Béziers,34,Hérault,76,Occitanie
34337,VILLENEUVE LES MAGUELONE,34750,VILLENEUVE LES MAGUELONE,,43.5249723481,3.85846205367,337,,Villeneuve-lès-Maguelone,Villeneuve-lès-Maguelone,34,Hérault,76,Occitanie
34338,VILLENEUVETTE,34800,VILLENEUVETTE,,43.6102089011,3.40077488317,338,,Villeneuvette,Villeneuvette,34,Hérault,76,Occitanie
34339,VILLESPASSANS,34360,VILLESPASSANS,,43.3817686884,2.91141941594,339,,Villespassans,Villespassans,34,Hérault,76,Occitanie
34340,VILLETELLE,34400,VILLETELLE,,43.7241089039,4.1368883121,340,,Villetelle,Villetelle,34,Hérault,76,Occitanie
34341,VILLEVEYRAC,34560,VILLEVEYRAC,,43.5001280333,3.59372946669,341,,Villeveyrac,Villeveyrac,34,Hérault,76,Occitanie
34342,VIOLS EN LAVAL,34380,VIOLS EN LAVAL,,43.7448968901,3.74026730313,342,,Viols-en-Laval,Viols-en-Laval,34,Hérault,76,Occitanie
34343,VIOLS LE FORT,34380,VIOLS LE FORT,,43.7367573026,3.69220324597,343,,Viols-le-Fort,Viols-le-Fort,34,Hérault,76,Occitanie
34344,LA GRANDE MOTTE,34280,LA GRANDE MOTTE,,43.5685394142,4.0758328467,344,La,Grande-Motte,La Grande-Motte,34,Hérault,76,Occitanie
35001,ACIGNE,35690,ACIGNE,,48.1464008617,-1.51879762112,1,,Acigné,Acigné,35,Ille-et-Vilaine,53,Bretagne
35002,AMANLIS,35150,AMANLIS,,47.9998215771,-1.495562097,2,,Amanlis,Amanlis,35,Ille-et-Vilaine,53,Bretagne
35003,ANDOUILLE NEUVILLE,35250,ANDOUILLE NEUVILLE,,48.2975724468,-1.5958926629,3,,Andouillé-Neuville,Andouillé-Neuville,35,Ille-et-Vilaine,53,Bretagne
35004,ANTRAIN,35560,ANTRAIN,,48.4623399244,-1.47875897631,4,,Antrain,Antrain,35,Ille-et-Vilaine,53,Bretagne
35005,ARBRISSEL,35130,ARBRISSEL,,47.9272051902,-1.29532585223,5,,Arbrissel,Arbrissel,35,Ille-et-Vilaine,53,Bretagne
35006,ARGENTRE DU PLESSIS,35370,ARGENTRE DU PLESSIS,,48.0483656903,-1.13833015473,6,,Argentré-du-Plessis,Argentré-du-Plessis,35,Ille-et-Vilaine,53,Bretagne
35007,AUBIGNE,35250,AUBIGNE,,48.2991937889,-1.63035557088,7,,Aubigné,Aubigné,35,Ille-et-Vilaine,53,Bretagne
35008,AVAILLES SUR SEICHE,35130,AVAILLES SUR SEICHE,,47.9580414935,-1.1853971789,8,,Availles-sur-Seiche,Availles-sur-Seiche,35,Ille-et-Vilaine,53,Bretagne
35009,BAGUER MORVAN,35120,BAGUER MORVAN,,48.5049240687,-1.77192284883,9,,Baguer-Morvan,Baguer-Morvan,35,Ille-et-Vilaine,53,Bretagne
35010,BAGUER PICAN,35120,BAGUER PICAN,,48.5500094703,-1.69120416285,10,,Baguer-Pican,Baguer-Pican,35,Ille-et-Vilaine,53,Bretagne
35011,BAILLE,35460,BAILLE,,48.3662045061,-1.37619867939,11,,Baillé,Baillé,35,Ille-et-Vilaine,53,Bretagne
35012,BAIN DE BRETAGNE,35470,BAIN DE BRETAGNE,,47.8302948158,-1.67649507218,12,,Bain-de-Bretagne,Bain-de-Bretagne,35,Ille-et-Vilaine,53,Bretagne
35013,BAINS SUR OUST,35600,BAINS SUR OUST,,47.7118573151,-2.0751766873,13,,Bains-sur-Oust,Bains-sur-Oust,35,Ille-et-Vilaine,53,Bretagne
35014,BAIS,35680,BAIS,,48.0108227925,-1.30560487998,14,,Bais,Bais,35,Ille-et-Vilaine,53,Bretagne
35015,BALAZE,35500,BALAZE,,48.1777363541,-1.18302870433,15,,Balazé,Balazé,35,Ille-et-Vilaine,53,Bretagne
35016,BAULON,35580,BAULON,,47.9948578338,-1.92813623016,16,,Baulon,Baulon,35,Ille-et-Vilaine,53,Bretagne
35017,LA BAUSSAINE,35190,LA BAUSSAINE,,48.3125973519,-1.89690518191,17,La,Baussaine,La Baussaine,35,Ille-et-Vilaine,53,Bretagne
35018,LA BAZOUGE DU DESERT,35420,LA BAZOUGE DU DESERT,,48.4439442954,-1.10950795869,18,La,Bazouge-du-Désert,La Bazouge-du-Désert,35,Ille-et-Vilaine,53,Bretagne
35019,BAZOUGES LA PEROUSE,35560,BAZOUGES LA PEROUSE,,48.4405619878,-1.56381100666,19,,Bazouges-la-Pérouse,Bazouges-la-Pérouse,35,Ille-et-Vilaine,53,Bretagne
35021,BEAUCE,35133,BEAUCE,,48.3472640722,-1.15617278837,21,,Beaucé,Beaucé,35,Ille-et-Vilaine,53,Bretagne
35022,BECHEREL,35190,BECHEREL,,48.2965087434,-1.9428236566,22,,Bécherel,Bécherel,35,Ille-et-Vilaine,53,Bretagne
35023,BEDEE,35137,BEDEE,,48.1867854557,-1.94992897957,23,,Bédée,Bédée,35,Ille-et-Vilaine,53,Bretagne
35024,BETTON,35830,BETTON,,48.1812360423,-1.64589552684,24,,Betton,Betton,35,Ille-et-Vilaine,53,Bretagne
35025,BILLE,35133,BILLE,,48.2936717926,-1.25154460555,25,,Billé,Billé,35,Ille-et-Vilaine,53,Bretagne
35026,BLERUAIS,35750,BLERUAIS,,48.116030785,-2.11549706882,26,,Bléruais,Bléruais,35,Ille-et-Vilaine,53,Bretagne
35027,BOISGERVILLY,35360,BOISGERVILLY,,48.1663042024,-2.08373655963,27,,Boisgervilly,Boisgervilly,35,Ille-et-Vilaine,53,Bretagne
35028,BOISTRUDAN,35150,BOISTRUDAN,,47.9757827279,-1.39686798859,28,,Boistrudan,Boistrudan,35,Ille-et-Vilaine,53,Bretagne
35029,BONNEMAIN,35270,BONNEMAIN,,48.4711533763,-1.7614236564,29,,Bonnemain,Bonnemain,35,Ille-et-Vilaine,53,Bretagne
35030,LA BOSSE DE BRETAGNE,35320,LA BOSSE DE BRETAGNE,,47.8682982413,-1.58824965901,30,La,Bosse-de-Bretagne,La Bosse-de-Bretagne,35,Ille-et-Vilaine,53,Bretagne
35031,LA BOUEXIERE,35340,LA BOUEXIERE,,48.1743733249,-1.43646357128,31,La,Bouëxière,La Bouëxière,35,Ille-et-Vilaine,53,Bretagne
35032,BOURGBARRE,35230,BOURGBARRE,,47.988587599,-1.62222742311,32,,Bourgbarré,Bourgbarré,35,Ille-et-Vilaine,53,Bretagne
35033,BOURG DES COMPTES,35890,BOURG DES COMPTES,,47.921351774,-1.72230702011,33,,Bourg-des-Comptes,Bourg-des-Comptes,35,Ille-et-Vilaine,53,Bretagne
35034,LA BOUSSAC,35120,LA BOUSSAC,,48.5167692001,-1.64943236951,34,La,Boussac,La Boussac,35,Ille-et-Vilaine,53,Bretagne
35035,BOVEL,35330,BOVEL,,47.9563331223,-1.95993951204,35,,Bovel,Bovel,35,Ille-et-Vilaine,53,Bretagne
35037,BREAL SOUS MONTFORT,35310,BREAL SOUS MONTFORT,,48.044637167,-1.86571618785,37,,Bréal-sous-Montfort,Bréal-sous-Montfort,35,Ille-et-Vilaine,53,Bretagne
35038,BREAL SOUS VITRE,35370,BREAL SOUS VITRE,,48.0921515475,-1.06109442521,38,,Bréal-sous-Vitré,Bréal-sous-Vitré,35,Ille-et-Vilaine,53,Bretagne
35039,BRECE,35530,BRECE,,48.1107701278,-1.48991638652,39,,Brécé,Brécé,35,Ille-et-Vilaine,53,Bretagne
35040,BRETEIL,35160,BRETEIL,,48.1381002626,-1.91036057871,40,,Breteil,Breteil,35,Ille-et-Vilaine,53,Bretagne
35041,BRIE,35150,BRIE,,47.9511752369,-1.55057030783,41,,Brie,Brie,35,Ille-et-Vilaine,53,Bretagne
35042,BRIELLES,35370,BRIELLES,,48.0111591648,-1.09792357132,42,,Brielles,Brielles,35,Ille-et-Vilaine,53,Bretagne
35044,BROUALAN,35120,BROUALAN,,48.4755754459,-1.66143618132,44,,Broualan,Broualan,35,Ille-et-Vilaine,53,Bretagne
35045,BRUC SUR AFF,35550,BRUC SUR AFF,,47.8141653768,-2.00570754872,45,,Bruc-sur-Aff,Bruc-sur-Aff,35,Ille-et-Vilaine,53,Bretagne
35046,LES BRULAIS,35330,LES BRULAIS,,47.8907025068,-2.05725373415,46,Les,Brulais,Les Brulais,35,Ille-et-Vilaine,53,Bretagne
35047,BRUZ,35170,BRUZ,,48.0261755313,-1.74817480915,47,,Bruz,Bruz,35,Ille-et-Vilaine,53,Bretagne
35047,BRUZ,35170,BRUZ,PONT REAN,48.0261755313,-1.74817480915,47,,Bruz,Bruz,35,Ille-et-Vilaine,53,Bretagne
35048,CAMPEL,35330,CAMPEL,,47.9399419517,-2.00815901738,48,,Campel,Campel,35,Ille-et-Vilaine,53,Bretagne
35049,CANCALE,35260,CANCALE,,48.6835209896,-1.8650827351,49,,Cancale,Cancale,35,Ille-et-Vilaine,53,Bretagne
35050,CARDROC,35190,CARDROC,,48.2846714834,-1.89382613494,50,,Cardroc,Cardroc,35,Ille-et-Vilaine,53,Bretagne
35051,CESSON SEVIGNE,35510,CESSON SEVIGNE,,48.1202268032,-1.59714447272,51,,Cesson-Sévigné,Cesson-Sévigné,35,Ille-et-Vilaine,53,Bretagne
35052,CHAMPEAUX,35500,CHAMPEAUX,,48.1387131553,-1.30145243997,52,,Champeaux,Champeaux,35,Ille-et-Vilaine,53,Bretagne
35053,CHANCE,35680,CHANCE,,48.0332363506,-1.37039069697,53,,Chancé,Chancé,35,Ille-et-Vilaine,53,Bretagne
35054,CHANTELOUP,35150,CHANTELOUP,,47.9537247439,-1.62469972831,54,,Chanteloup,Chanteloup,35,Ille-et-Vilaine,53,Bretagne
35055,CHANTEPIE,35135,CHANTEPIE,,48.0817134724,-1.6038253094,55,,Chantepie,Chantepie,35,Ille-et-Vilaine,53,Bretagne
35056,LA CHAPELLE AUX FILTZMEENS,35190,LA CHAPELLE AUX FILTZMEENS,,48.3817396388,-1.8184050063,56,La,Chapelle-aux-Filtzméens,La Chapelle-aux-Filtzméens,35,Ille-et-Vilaine,53,Bretagne
35057,LA CHAPELLE BOUEXIC,35330,LA CHAPELLE BOUEXIC,,47.9365752557,-1.92323342783,57,La,Chapelle-Bouëxic,La Chapelle-Bouëxic,35,Ille-et-Vilaine,53,Bretagne
35058,LA CHAPELLE CHAUSSEE,35630,LA CHAPELLE CHAUSSEE,,48.2664350425,-1.86414756163,58,La,Chapelle-Chaussée,La Chapelle-Chaussée,35,Ille-et-Vilaine,53,Bretagne
35059,LA CHAPELLE DES FOUGERETZ,35520,LA CHAPELLE DES FOUGERETZ,,48.1768937301,-1.74067185823,59,La,Chapelle-des-Fougeretz,La Chapelle-des-Fougeretz,35,Ille-et-Vilaine,53,Bretagne
35060,LA CHAPELLE DU LOU DU LAC,35360,LA CHAPELLE DU LOU DU LAC,,48.2222623755,-1.99322262284,60,La,Chapelle du Lou du Lac,La Chapelle du Lou du Lac,35,Ille-et-Vilaine,53,Bretagne
35060,LA CHAPELLE DU LOU DU LAC,35360,LA CHAPELLE DU LOU DU LAC,LE LOU DU LAC,48.2222623755,-1.99322262284,60,La,Chapelle du Lou du Lac,La Chapelle du Lou du Lac,35,Ille-et-Vilaine,53,Bretagne
35061,LA CHAPELLE ERBREE,35500,LA CHAPELLE ERBREE,,48.1402551265,-1.08972100382,61,La,Chapelle-Erbrée,La Chapelle-Erbrée,35,Ille-et-Vilaine,53,Bretagne
35062,LA CHAPELLE JANSON,35133,LA CHAPELLE JANSON,,48.3352768083,-1.08530349718,62,La,Chapelle-Janson,La Chapelle-Janson,35,Ille-et-Vilaine,53,Bretagne
35063,LA CHAPELLE ST AUBERT,35140,LA CHAPELLE ST AUBERT,,48.3100106351,-1.3143625945,63,La,Chapelle-Saint-Aubert,La Chapelle-Saint-Aubert,35,Ille-et-Vilaine,53,Bretagne
35064,LA CHAPELLE DE BRAIN,35660,LA CHAPELLE DE BRAIN,,47.6972959919,-1.93416464602,64,La,Chapelle-de-Brain,La Chapelle-de-Brain,35,Ille-et-Vilaine,53,Bretagne
35064,LA CHAPELLE DE BRAIN,35660,LA CHAPELLE DE BRAIN,BRAIN SUR VILAINE,47.6972959919,-1.93416464602,64,La,Chapelle-de-Brain,La Chapelle-de-Brain,35,Ille-et-Vilaine,53,Bretagne
35065,LA CHAPELLE THOUARAULT,35590,LA CHAPELLE THOUARAULT,,48.1252826202,-1.8581233152,65,La,Chapelle-Thouarault,La Chapelle-Thouarault,35,Ille-et-Vilaine,53,Bretagne
35066,CHARTRES DE BRETAGNE,35131,CHARTRES DE BRETAGNE,,48.0451561683,-1.70650297343,66,,Chartres-de-Bretagne,Chartres-de-Bretagne,35,Ille-et-Vilaine,53,Bretagne
35067,CHASNE SUR ILLET,35250,CHASNE SUR ILLET,,48.2351678517,-1.57162603055,67,,Chasné-sur-Illet,Chasné-sur-Illet,35,Ille-et-Vilaine,53,Bretagne
35068,CHATEAUBOURG,35220,CHATEAUBOURG,,48.120001247,-1.40402096437,68,,Châteaubourg,Châteaubourg,35,Ille-et-Vilaine,53,Bretagne
35068,CHATEAUBOURG,35220,CHATEAUBOURG,BROONS SUR VILAINE,48.120001247,-1.40402096437,68,,Châteaubourg,Châteaubourg,35,Ille-et-Vilaine,53,Bretagne
35068,CHATEAUBOURG,35220,CHATEAUBOURG,ST MELAINE,48.120001247,-1.40402096437,68,,Châteaubourg,Châteaubourg,35,Ille-et-Vilaine,53,Bretagne
35069,CHATEAUGIRON,35410,CHATEAUGIRON,,48.0441575779,-1.50016256863,69,,Châteaugiron,Châteaugiron,35,Ille-et-Vilaine,53,Bretagne
35070,CHATEAUNEUF D ILLE ET VILAINE,35430,CHATEAUNEUF D ILLE ET VILAINE,,48.5578928672,-1.92925036505,70,,Châteauneuf-d'Ille-et-Vilaine,Châteauneuf-d'Ille-et-Vilaine,35,Ille-et-Vilaine,53,Bretagne
35071,LE CHATELLIER,35133,LE CHATELLIER,,48.4263874529,-1.24618917742,71,Le,Châtellier,Le Châtellier,35,Ille-et-Vilaine,53,Bretagne
35072,CHATILLON EN VENDELAIS,35210,CHATILLON EN VENDELAIS,,48.2287242632,-1.16547272515,72,,Châtillon-en-Vendelais,Châtillon-en-Vendelais,35,Ille-et-Vilaine,53,Bretagne
35075,CHAUVIGNE,35490,CHAUVIGNE,,48.3766708439,-1.45244219408,75,,Chauvigné,Chauvigné,35,Ille-et-Vilaine,53,Bretagne
35076,CHAVAGNE,35310,CHAVAGNE,,48.0561574005,-1.78649242036,76,,Chavagne,Chavagne,35,Ille-et-Vilaine,53,Bretagne
35077,CHELUN,35640,CHELUN,,47.8550873481,-1.24349817887,77,,Chelun,Chelun,35,Ille-et-Vilaine,53,Bretagne
35078,CHERRUEIX,35120,CHERRUEIX,,48.5953506837,-1.7146028897,78,,Cherrueix,Cherrueix,35,Ille-et-Vilaine,53,Bretagne
35079,CHEVAIGNE,35250,CHEVAIGNE,,48.2251828699,-1.63670416498,79,,Chevaigné,Chevaigné,35,Ille-et-Vilaine,53,Bretagne
35080,CINTRE,35310,CINTRE,,48.108708615,-1.88667263001,80,,Cintré,Cintré,35,Ille-et-Vilaine,53,Bretagne
35081,CLAYES,35590,CLAYES,,48.1786926288,-1.8539725616,81,,Clayes,Clayes,35,Ille-et-Vilaine,53,Bretagne
35082,COESMES,35134,COESMES,,47.8725233348,-1.41640449384,82,,Coësmes,Coësmes,35,Ille-et-Vilaine,53,Bretagne
35083,COGLES,35460,COGLES,,48.4546950908,-1.36403299793,83,,Coglès,Coglès,35,Ille-et-Vilaine,53,Bretagne
35084,COMBLESSAC,35330,COMBLESSAC,,47.872461539,-2.08792248915,84,,Comblessac,Comblessac,35,Ille-et-Vilaine,53,Bretagne
35085,COMBOURG,35270,COMBOURG,,48.4083560805,-1.74519435019,85,,Combourg,Combourg,35,Ille-et-Vilaine,53,Bretagne
35086,COMBOURTILLE,35210,COMBOURTILLE,,48.2659507718,-1.26462449893,86,,Combourtillé,Combourtillé,35,Ille-et-Vilaine,53,Bretagne
35087,CORNILLE,35500,CORNILLE,,48.0822592123,-1.31389594614,87,,Cornillé,Cornillé,35,Ille-et-Vilaine,53,Bretagne
35088,CORPS NUDS,35150,CORPS NUDS,,47.9823871404,-1.57026704551,88,,Corps-Nuds,Corps-Nuds,35,Ille-et-Vilaine,53,Bretagne
35089,LA COUYERE,35320,LA COUYERE,,47.8939265628,-1.50149818667,89,La,Couyère,La Couyère,35,Ille-et-Vilaine,53,Bretagne
35090,CREVIN,35320,CREVIN,,47.9303708982,-1.65657334896,90,,Crevin,Crevin,35,Ille-et-Vilaine,53,Bretagne
35091,LE CROUAIS,35290,LE CROUAIS,,48.2095149128,-2.15053922323,91,Le,Crouais,Le Crouais,35,Ille-et-Vilaine,53,Bretagne
35092,CUGUEN,35270,CUGUEN,,48.4408107773,-1.65625423904,92,,Cuguen,Cuguen,35,Ille-et-Vilaine,53,Bretagne
35093,DINARD,35800,DINARD,,48.6241805945,-2.0619828606,93,,Dinard,Dinard,35,Ille-et-Vilaine,53,Bretagne
35094,DINGE,35440,DINGE,,48.3468879458,-1.71071061366,94,,Dingé,Dingé,35,Ille-et-Vilaine,53,Bretagne
35095,DOL DE BRETAGNE,35120,DOL DE BRETAGNE,,48.5364110141,-1.74135530934,95,,Dol-de-Bretagne,Dol-de-Bretagne,35,Ille-et-Vilaine,53,Bretagne
35096,DOMAGNE,35113,DOMAGNE,,48.0678122281,-1.40586957147,96,,Domagné,Domagné,35,Ille-et-Vilaine,53,Bretagne
35096,DOMAGNE,35113,DOMAGNE,CHAUMERE,48.0678122281,-1.40586957147,96,,Domagné,Domagné,35,Ille-et-Vilaine,53,Bretagne
35097,DOMALAIN,35680,DOMALAIN,,47.9990883279,-1.23942096028,97,,Domalain,Domalain,35,Ille-et-Vilaine,53,Bretagne
35098,LA DOMINELAIS,35390,LA DOMINELAIS,,47.7633908593,-1.67305925291,98,La,Dominelais,La Dominelais,35,Ille-et-Vilaine,53,Bretagne
35099,DOMLOUP,35410,DOMLOUP,,48.0670688041,-1.54288380182,99,,Domloup,Domloup,35,Ille-et-Vilaine,53,Bretagne
35100,DOMPIERRE DU CHEMIN,35210,DOMPIERRE DU CHEMIN,,48.2671470329,-1.14643708815,100,,Dompierre-du-Chemin,Dompierre-du-Chemin,35,Ille-et-Vilaine,53,Bretagne
35101,DOURDAIN,35450,DOURDAIN,,48.1930816063,-1.37940357602,101,,Dourdain,Dourdain,35,Ille-et-Vilaine,53,Bretagne
35102,DROUGES,35130,DROUGES,,47.9018762772,-1.26101928892,102,,Drouges,Drouges,35,Ille-et-Vilaine,53,Bretagne
35103,EANCE,35640,EANCE,,47.8237855193,-1.25227628941,103,,Eancé,Eancé,35,Ille-et-Vilaine,53,Bretagne
35104,EPINIAC,35120,EPINIAC,,48.4994671999,-1.70334615041,104,,Epiniac,Epiniac,35,Ille-et-Vilaine,53,Bretagne
35105,ERBREE,35500,ERBREE,,48.1094104255,-1.11698541654,105,,Erbrée,Erbrée,35,Ille-et-Vilaine,53,Bretagne
35106,ERCE EN LAMEE,35620,ERCE EN LAMEE,,47.818356677,-1.58275557873,106,,Ercé-en-Lamée,Ercé-en-Lamée,35,Ille-et-Vilaine,53,Bretagne
35107,ERCE PRES LIFFRE,35340,ERCE PRES LIFFRE,,48.2542003303,-1.51716704496,107,,Ercé-près-Liffré,Ercé-près-Liffré,35,Ille-et-Vilaine,53,Bretagne
35108,ESSE,35150,ESSE,,47.9478571796,-1.43042922929,108,,Essé,Essé,35,Ille-et-Vilaine,53,Bretagne
35109,ETRELLES,35370,ETRELLES,,48.0697223278,-1.21606173712,109,,Étrelles,Étrelles,35,Ille-et-Vilaine,53,Bretagne
35110,FEINS,35440,FEINS,,48.328121746,-1.62597037804,110,,Feins,Feins,35,Ille-et-Vilaine,53,Bretagne
35111,LE FERRE,35420,LE FERRE,,48.4864153557,-1.29478720847,111,Le,Ferré,Le Ferré,35,Ille-et-Vilaine,53,Bretagne
35112,FLEURIGNE,35133,FLEURIGNE,,48.3618873947,-1.11104186777,112,,Fleurigné,Fleurigné,35,Ille-et-Vilaine,53,Bretagne
35113,LA FONTENELLE,35560,LA FONTENELLE,,48.4664744513,-1.51179376231,113,La,Fontenelle,La Fontenelle,35,Ille-et-Vilaine,53,Bretagne
35114,FORGES LA FORET,35640,FORGES LA FORET,,47.8618191863,-1.29350551765,114,,Forges-la-Forêt,Forges-la-Forêt,35,Ille-et-Vilaine,53,Bretagne
35115,FOUGERES,35300,FOUGERES,,48.3524697115,-1.19431177241,115,,Fougères,Fougères,35,Ille-et-Vilaine,53,Bretagne
35116,LA FRESNAIS,35111,LA FRESNAIS,,48.5874275973,-1.83530541671,116,La,Fresnais,La Fresnais,35,Ille-et-Vilaine,53,Bretagne
35117,GAEL,35290,GAEL,,48.1184219135,-2.22847304876,117,,Gaël,Gaël,35,Ille-et-Vilaine,53,Bretagne
35118,GAHARD,35490,GAHARD,,48.2882041864,-1.52746285795,118,,Gahard,Gahard,35,Ille-et-Vilaine,53,Bretagne
35119,GENNES SUR SEICHE,35370,GENNES SUR SEICHE,,47.9983537714,-1.13315830309,119,,Gennes-sur-Seiche,Gennes-sur-Seiche,35,Ille-et-Vilaine,53,Bretagne
35120,GEVEZE,35850,GEVEZE,,48.2133254705,-1.79930703575,120,,Gévezé,Gévezé,35,Ille-et-Vilaine,53,Bretagne
35121,GOSNE,35140,GOSNE,,48.2503523895,-1.45860853595,121,,Gosné,Gosné,35,Ille-et-Vilaine,53,Bretagne
35122,LA GOUESNIERE,35350,LA GOUESNIERE,,48.6030701902,-1.88737306807,122,La,Gouesnière,La Gouesnière,35,Ille-et-Vilaine,53,Bretagne
35123,GOVEN,35580,GOVEN,,48.0094372843,-1.83854162867,123,,Goven,Goven,35,Ille-et-Vilaine,53,Bretagne
35124,GRAND FOUGERAY,35390,GRAND FOUGERAY,,47.7344338208,-1.72820099024,124,,Grand-Fougeray,Grand-Fougeray,35,Ille-et-Vilaine,53,Bretagne
35125,LA GUERCHE DE BRETAGNE,35130,LA GUERCHE DE BRETAGNE,,47.9442619164,-1.24421600669,125,La,Guerche-de-Bretagne,La Guerche-de-Bretagne,35,Ille-et-Vilaine,53,Bretagne
35126,GUICHEN,35580,GUICHEN,,47.9653877316,-1.79026270939,126,,Guichen,Guichen,35,Ille-et-Vilaine,53,Bretagne
35126,GUICHEN,35580,GUICHEN,PONT REAN,47.9653877316,-1.79026270939,126,,Guichen,Guichen,35,Ille-et-Vilaine,53,Bretagne
35127,GUIGNEN,35580,GUIGNEN,,47.9235807621,-1.8619758657,127,,Guignen,Guignen,35,Ille-et-Vilaine,53,Bretagne
35128,GUIPEL,35440,GUIPEL,,48.2923904516,-1.72349623823,128,,Guipel,Guipel,35,Ille-et-Vilaine,53,Bretagne
35130,HEDE BAZOUGES,35630,HEDE BAZOUGES,,48.3018808207,-1.77748405021,130,,Hédé-Bazouges,Hédé-Bazouges,35,Ille-et-Vilaine,53,Bretagne
35130,HEDE BAZOUGES,35630,HEDE BAZOUGES,BAZOUGES SOUS HEDE,48.3018808207,-1.77748405021,130,,Hédé-Bazouges,Hédé-Bazouges,35,Ille-et-Vilaine,53,Bretagne
35131,L HERMITAGE,35590,L HERMITAGE,,48.1221023417,-1.8166504183,131,L',Hermitage,L'Hermitage,35,Ille-et-Vilaine,53,Bretagne
35132,HIREL,35120,HIREL,,48.5948348848,-1.80915236052,132,,Hirel,Hirel,35,Ille-et-Vilaine,53,Bretagne
35132,HIREL,35120,HIREL,VILDE LA MARINE,48.5948348848,-1.80915236052,132,,Hirel,Hirel,35,Ille-et-Vilaine,53,Bretagne
35133,IFFENDIC,35750,IFFENDIC,,48.1137342706,-2.02411746064,133,,Iffendic,Iffendic,35,Ille-et-Vilaine,53,Bretagne
35134,LES IFFS,35630,LES IFFS,,48.2937886247,-1.86720916772,134,Les,Iffs,Les Iffs,35,Ille-et-Vilaine,53,Bretagne
35135,IRODOUER,35850,IRODOUER,,48.246152189,-1.95021443067,135,,Irodouër,Irodouër,35,Ille-et-Vilaine,53,Bretagne
35136,JANZE,35150,JANZE,,47.9428950804,-1.50038718382,136,,Janzé,Janzé,35,Ille-et-Vilaine,53,Bretagne
35137,JAVENE,35133,JAVENE,,48.3167949383,-1.2059813978,137,,Javené,Javené,35,Ille-et-Vilaine,53,Bretagne
35138,LAIGNELET,35133,LAIGNELET,,48.379896716,-1.152249408,138,,Laignelet,Laignelet,35,Ille-et-Vilaine,53,Bretagne
35139,LAILLE,35890,LAILLE,,47.9672360017,-1.71452676529,139,,Laillé,Laillé,35,Ille-et-Vilaine,53,Bretagne
35140,LALLEU,35320,LALLEU,,47.8562627684,-1.52230618592,140,,Lalleu,Lalleu,35,Ille-et-Vilaine,53,Bretagne
35141,LANDAVRAN,35450,LANDAVRAN,,48.1608061542,-1.28487259488,141,,Landavran,Landavran,35,Ille-et-Vilaine,53,Bretagne
35142,LANDEAN,35133,LANDEAN,,48.4131956969,-1.14760395074,142,,Landéan,Landéan,35,Ille-et-Vilaine,53,Bretagne
35143,LANDUJAN,35360,LANDUJAN,,48.2476416223,-2.00848370543,143,,Landujan,Landujan,35,Ille-et-Vilaine,53,Bretagne
35144,LANGAN,35850,LANGAN,,48.2363756072,-1.84565830249,144,,Langan,Langan,35,Ille-et-Vilaine,53,Bretagne
35145,LANGON,35660,LANGON,,47.733471612,-1.87194975237,145,,Langon,Langon,35,Ille-et-Vilaine,53,Bretagne
35146,LANGOUET,35630,LANGOUET,,48.2549305209,-1.80881548185,146,,Langouet,Langouet,35,Ille-et-Vilaine,53,Bretagne
35147,LANHELIN,35720,LANHELIN,,48.454615382,-1.83075963165,147,,Lanhélin,Lanhélin,35,Ille-et-Vilaine,53,Bretagne
35148,LANRIGAN,35270,LANRIGAN,,48.3912514208,-1.69125449269,148,,Lanrigan,Lanrigan,35,Ille-et-Vilaine,53,Bretagne
35149,LASSY,35580,LASSY,,47.9717256935,-1.86370504959,149,,Lassy,Lassy,35,Ille-et-Vilaine,53,Bretagne
35150,LECOUSSE,35133,LECOUSSE,,48.3600018318,-1.22059471096,150,,Lécousse,Lécousse,35,Ille-et-Vilaine,53,Bretagne
35151,LIEURON,35550,LIEURON,,47.851789688,-1.92917231951,151,,Lieuron,Lieuron,35,Ille-et-Vilaine,53,Bretagne
35152,LIFFRE,35340,LIFFRE,,48.204097581,-1.51420907793,152,,Liffré,Liffré,35,Ille-et-Vilaine,53,Bretagne
35153,LILLEMER,35111,LILLEMER,,48.5707435004,-1.85549449087,153,,Lillemer,Lillemer,35,Ille-et-Vilaine,53,Bretagne
35154,LIVRE SUR CHANGEON,35450,LIVRE SUR CHANGEON,,48.2273048831,-1.34883997825,154,,Livré-sur-Changeon,Livré-sur-Changeon,35,Ille-et-Vilaine,53,Bretagne
35155,LOHEAC,35550,LOHEAC,,47.8732108316,-1.888495597,155,,Lohéac,Lohéac,35,Ille-et-Vilaine,53,Bretagne
35156,LONGAULNAY,35190,LONGAULNAY,,48.3091791163,-1.94199814381,156,,Longaulnay,Longaulnay,35,Ille-et-Vilaine,53,Bretagne
35157,LE LOROUX,35133,LE LOROUX,,48.3948985787,-1.0831112937,157,Le,Loroux,Le Loroux,35,Ille-et-Vilaine,53,Bretagne
35159,LOURMAIS,35270,LOURMAIS,,48.4413517825,-1.73312494265,159,,Lourmais,Lourmais,35,Ille-et-Vilaine,53,Bretagne
35160,LOUTEHEL,35330,LOUTEHEL,,47.9372074975,-2.08044398757,160,,Loutehel,Loutehel,35,Ille-et-Vilaine,53,Bretagne
35161,LOUVIGNE DE BAIS,35680,LOUVIGNE DE BAIS,,48.0522095083,-1.33866165819,161,,Louvigné-de-Bais,Louvigné-de-Bais,35,Ille-et-Vilaine,53,Bretagne
35162,LOUVIGNE DU DESERT,35420,LOUVIGNE DU DESERT,,48.4868885908,-1.12369660788,162,,Louvigné-du-Désert,Louvigné-du-Désert,35,Ille-et-Vilaine,53,Bretagne
35163,LUITRE,35133,LUITRE,,48.293087363,-1.12955612436,163,,Luitré,Luitré,35,Ille-et-Vilaine,53,Bretagne
35164,MARCILLE RAOUL,35560,MARCILLE RAOUL,,48.3743980511,-1.6139420088,164,,Marcillé-Raoul,Marcillé-Raoul,35,Ille-et-Vilaine,53,Bretagne
35165,MARCILLE ROBERT,35240,MARCILLE ROBERT,,47.9584716788,-1.35356162459,165,,Marcillé-Robert,Marcillé-Robert,35,Ille-et-Vilaine,53,Bretagne
35166,MARPIRE,35220,MARPIRE,,48.1468901572,-1.35260722059,166,,Marpiré,Marpiré,35,Ille-et-Vilaine,53,Bretagne
35167,MARTIGNE FERCHAUD,35640,MARTIGNE FERCHAUD,,47.8281952315,-1.32808258577,167,,Martigné-Ferchaud,Martigné-Ferchaud,35,Ille-et-Vilaine,53,Bretagne
35168,MAURE DE BRETAGNE,35330,MAURE DE BRETAGNE,,47.8971012974,-1.99867854689,168,,Val d'Anast,Val d'Anast,35,Ille-et-Vilaine,53,Bretagne
35169,MAXENT,35380,MAXENT,,47.9788328525,-2.01519596065,169,,Maxent,Maxent,35,Ille-et-Vilaine,53,Bretagne
35170,MECE,35450,MECE,,48.2407410676,-1.28545546756,170,,Mecé,Mecé,35,Ille-et-Vilaine,53,Bretagne
35171,MEDREAC,35360,MEDREAC,,48.2612561283,-2.06133339808,171,,Médréac,Médréac,35,Ille-et-Vilaine,53,Bretagne
35172,MEILLAC,35270,MEILLAC,,48.4207786657,-1.82223857468,172,,Meillac,Meillac,35,Ille-et-Vilaine,53,Bretagne
35173,MELESSE,35520,MELESSE,,48.2193069814,-1.68950220558,173,,Melesse,Melesse,35,Ille-et-Vilaine,53,Bretagne
35174,MELLE,35420,MELLE,,48.4848259494,-1.19126091075,174,,Mellé,Mellé,35,Ille-et-Vilaine,53,Bretagne
35175,MERNEL,35330,MERNEL,,47.8982561483,-1.95140326249,175,,Mernel,Mernel,35,Ille-et-Vilaine,53,Bretagne
35176,GUIPRY MESSAC,35480,GUIPRY MESSAC,,47.8050001887,-1.79981410683,176,,Guipry-Messac,Guipry-Messac,35,Ille-et-Vilaine,53,Bretagne
35176,GUIPRY MESSAC,35480,GUIPRY MESSAC,GUIPRY,47.8050001887,-1.79981410683,176,,Guipry-Messac,Guipry-Messac,35,Ille-et-Vilaine,53,Bretagne
35177,LA MEZIERE,35520,LA MEZIERE,,48.2130666672,-1.75057215298,177,La,Mézière,La Mézière,35,Ille-et-Vilaine,53,Bretagne
35178,MEZIERES SUR COUESNON,35140,MEZIERES SUR COUESNON,,48.2971797234,-1.43837213468,178,,Mézières-sur-Couesnon,Mézières-sur-Couesnon,35,Ille-et-Vilaine,53,Bretagne
35179,MINIAC MORVAN,35540,MINIAC MORVAN,,48.5246933441,-1.89543357334,179,,Miniac-Morvan,Miniac-Morvan,35,Ille-et-Vilaine,53,Bretagne
35179,MINIAC MORVAN,35540,MINIAC MORVAN,VIEUX BOURG,48.5246933441,-1.89543357334,179,,Miniac-Morvan,Miniac-Morvan,35,Ille-et-Vilaine,53,Bretagne
35180,MINIAC SOUS BECHEREL,35190,MINIAC SOUS BECHEREL,,48.2810885875,-1.92727123797,180,,Miniac-sous-Bécherel,Miniac-sous-Bécherel,35,Ille-et-Vilaine,53,Bretagne
35181,LE MINIHIC SUR RANCE,35870,LE MINIHIC SUR RANCE,,48.5755697077,-2.01309736708,181,Le,Minihic-sur-Rance,Le Minihic-sur-Rance,35,Ille-et-Vilaine,53,Bretagne
35183,MONDEVERT,35370,MONDEVERT,,48.0841466424,-1.09097545112,183,,Mondevert,Mondevert,35,Ille-et-Vilaine,53,Bretagne
35184,MONTAUBAN DE BRETAGNE,35360,MONTAUBAN DE BRETAGNE,,48.2028996948,-2.06476180878,184,,Montauban-de-Bretagne,Montauban-de-Bretagne,35,Ille-et-Vilaine,53,Bretagne
35185,MONTAUTOUR,35210,MONTAUTOUR,,48.2045052863,-1.12123859813,185,,Montautour,Montautour,35,Ille-et-Vilaine,53,Bretagne
35186,MONT DOL,35120,MONT DOL,,48.5706528755,-1.75854747175,186,,Mont-Dol,Mont-Dol,35,Ille-et-Vilaine,53,Bretagne
35187,MONTERFIL,35160,MONTERFIL,,48.059933472,-1.99008537755,187,,Monterfil,Monterfil,35,Ille-et-Vilaine,53,Bretagne
35188,MONTFORT SUR MEU,35160,MONTFORT SUR MEU,,48.1261000349,-1.9630334162,188,,Montfort-sur-Meu,Montfort-sur-Meu,35,Ille-et-Vilaine,53,Bretagne
35189,MONTGERMONT,35760,MONTGERMONT,,48.1538376773,-1.71729477816,189,,Montgermont,Montgermont,35,Ille-et-Vilaine,53,Bretagne
35190,MONTHAULT,35420,MONTHAULT,,48.5121983633,-1.17562696085,190,,Monthault,Monthault,35,Ille-et-Vilaine,53,Bretagne
35191,MONTOURS,35460,MONTOURS,,48.4485377499,-1.30298513536,191,Les,Portes du Coglais,Les Portes du Coglais,35,Ille-et-Vilaine,53,Bretagne
35192,MONTREUIL DES LANDES,35210,MONTREUIL DES LANDES,,48.2507630076,-1.22879248978,192,,Montreuil-des-Landes,Montreuil-des-Landes,35,Ille-et-Vilaine,53,Bretagne
35193,MONTREUIL LE GAST,35520,MONTREUIL LE GAST,,48.2442583442,-1.72080742537,193,,Montreuil-le-Gast,Montreuil-le-Gast,35,Ille-et-Vilaine,53,Bretagne
35194,MONTREUIL SOUS PEROUSE,35500,MONTREUIL SOUS PEROUSE,,48.1510951344,-1.24238323574,194,,Montreuil-sous-Pérouse,Montreuil-sous-Pérouse,35,Ille-et-Vilaine,53,Bretagne
35195,MONTREUIL SUR ILLE,35440,MONTREUIL SUR ILLE,,48.3053148028,-1.6686934187,195,,Montreuil-sur-Ille,Montreuil-sur-Ille,35,Ille-et-Vilaine,53,Bretagne
35196,MORDELLES,35310,MORDELLES,,48.0857602983,-1.84210731238,196,,Mordelles,Mordelles,35,Ille-et-Vilaine,53,Bretagne
35197,MOUAZE,35250,MOUAZE,,48.2185090762,-1.60820353926,197,,Mouazé,Mouazé,35,Ille-et-Vilaine,53,Bretagne
35198,MOULINS,35680,MOULINS,,48.0087571587,-1.36287785518,198,,Moulins,Moulins,35,Ille-et-Vilaine,53,Bretagne
35199,MOUSSE,35130,MOUSSE,,47.9176957127,-1.27923276368,199,,Moussé,Moussé,35,Ille-et-Vilaine,53,Bretagne
35200,MOUTIERS,35130,MOUTIERS,,47.9759859892,-1.2051682133,200,,Moutiers,Moutiers,35,Ille-et-Vilaine,53,Bretagne
35201,MUEL,35290,MUEL,,48.119750069,-2.16170563295,201,,Muel,Muel,35,Ille-et-Vilaine,53,Bretagne
35202,LA NOE BLANCHE,35470,LA NOE BLANCHE,,47.795066917,-1.7451274838,202,La,Noë-Blanche,La Noë-Blanche,35,Ille-et-Vilaine,53,Bretagne
35203,LA NOUAYE,35137,LA NOUAYE,,48.1662174034,-1.97457609624,203,La,Nouaye,La Nouaye,35,Ille-et-Vilaine,53,Bretagne
35204,NOUVOITOU,35410,NOUVOITOU,,48.0299058856,-1.55180159639,204,,Nouvoitou,Nouvoitou,35,Ille-et-Vilaine,53,Bretagne
35205,NOYAL SOUS BAZOUGES,35560,NOYAL SOUS BAZOUGES,,48.4173130249,-1.62400476475,205,,Noyal-sous-Bazouges,Noyal-sous-Bazouges,35,Ille-et-Vilaine,53,Bretagne
35206,NOYAL CHATILLON SUR SEICHE,35230,NOYAL CHATILLON SUR SEICHE,,48.0524815273,-1.6633106381,206,,Noyal-Châtillon-sur-Seiche,Noyal-Châtillon-sur-Seiche,35,Ille-et-Vilaine,53,Bretagne
35206,NOYAL CHATILLON SUR SEICHE,35230,NOYAL CHATILLON SUR SEICHE,CHATILLON SUR SEICHE,48.0524815273,-1.6633106381,206,,Noyal-Châtillon-sur-Seiche,Noyal-Châtillon-sur-Seiche,35,Ille-et-Vilaine,53,Bretagne
35207,NOYAL SUR VILAINE,35530,NOYAL SUR VILAINE,,48.0914044616,-1.50548866524,207,,Noyal-sur-Vilaine,Noyal-sur-Vilaine,35,Ille-et-Vilaine,53,Bretagne
35208,ORGERES,35230,ORGERES,,47.9853322097,-1.66640026267,208,,Orgères,Orgères,35,Ille-et-Vilaine,53,Bretagne
35209,OSSE,35410,OSSE,,48.0586375184,-1.45913837377,209,,Ossé,Ossé,35,Ille-et-Vilaine,53,Bretagne
35210,PACE,35740,PACE,,48.152548244,-1.77716643787,210,,Pacé,Pacé,35,Ille-et-Vilaine,53,Bretagne
35211,PAIMPONT,35380,PAIMPONT,,48.030192406,-2.17342058709,211,,Paimpont,Paimpont,35,Ille-et-Vilaine,53,Bretagne
35212,PANCE,35320,PANCE,,47.8889653573,-1.64257071034,212,,Pancé,Pancé,35,Ille-et-Vilaine,53,Bretagne
35214,PARCE,35210,PARCE,,48.2710203075,-1.19491182796,214,,Parcé,Parcé,35,Ille-et-Vilaine,53,Bretagne
35215,PARIGNE,35133,PARIGNE,,48.4254993536,-1.19808031545,215,,Parigné,Parigné,35,Ille-et-Vilaine,53,Bretagne
35216,PARTHENAY DE BRETAGNE,35850,PARTHENAY DE BRETAGNE,,48.1884515911,-1.829797127,216,,Parthenay-de-Bretagne,Parthenay-de-Bretagne,35,Ille-et-Vilaine,53,Bretagne
35217,LE PERTRE,35370,LE PERTRE,,48.0341523357,-1.05602216053,217,Le,Pertre,Le Pertre,35,Ille-et-Vilaine,53,Bretagne
35218,LE PETIT FOUGERAY,35320,LE PETIT FOUGERAY,,47.9280508952,-1.61613769556,218,Le,Petit-Fougeray,Le Petit-Fougeray,35,Ille-et-Vilaine,53,Bretagne
35219,PIPRIAC,35550,PIPRIAC,,47.8116756729,-1.93781141913,219,,Pipriac,Pipriac,35,Ille-et-Vilaine,53,Bretagne
35220,PIRE SUR SEICHE,35150,PIRE SUR SEICHE,,48.0111877546,-1.42837195377,220,,Piré-sur-Seiche,Piré-sur-Seiche,35,Ille-et-Vilaine,53,Bretagne
35221,PLECHATEL,35470,PLECHATEL,,47.8751941838,-1.73375846531,221,,Pléchâtel,Pléchâtel,35,Ille-et-Vilaine,53,Bretagne
35221,PLECHATEL,35470,PLECHATEL,LE CHATELLIER,47.8751941838,-1.73375846531,221,,Pléchâtel,Pléchâtel,35,Ille-et-Vilaine,53,Bretagne
35222,PLEINE FOUGERES,35610,PLEINE FOUGERES,,48.5311376904,-1.57392395573,222,,Pleine-Fougères,Pleine-Fougères,35,Ille-et-Vilaine,53,Bretagne
35223,PLELAN LE GRAND,35380,PLELAN LE GRAND,,47.9968736139,-2.07990195207,223,,Plélan-le-Grand,Plélan-le-Grand,35,Ille-et-Vilaine,53,Bretagne
35224,PLERGUER,35540,PLERGUER,,48.5284745342,-1.84558109365,224,,Plerguer,Plerguer,35,Ille-et-Vilaine,53,Bretagne
35225,PLESDER,35720,PLESDER,,48.4180951281,-1.93003326564,225,,Plesder,Plesder,35,Ille-et-Vilaine,53,Bretagne
35226,PLEUGUENEUC,35720,PLEUGUENEUC,,48.4045431671,-1.88606418202,226,,Pleugueneuc,Pleugueneuc,35,Ille-et-Vilaine,53,Bretagne
35227,PLEUMELEUC,35137,PLEUMELEUC,,48.1830495916,-1.8929319831,227,,Pleumeleuc,Pleumeleuc,35,Ille-et-Vilaine,53,Bretagne
35228,PLEURTUIT,35730,PLEURTUIT,,48.5827256783,-2.05991444738,228,,Pleurtuit,Pleurtuit,35,Ille-et-Vilaine,53,Bretagne
35229,POCE LES BOIS,35500,POCE LES BOIS,,48.115220187,-1.25960973465,229,,Pocé-les-Bois,Pocé-les-Bois,35,Ille-et-Vilaine,53,Bretagne
35230,POILLEY,35420,POILLEY,,48.4663238804,-1.25699060086,230,,Poilley,Poilley,35,Ille-et-Vilaine,53,Bretagne
35231,POLIGNE,35320,POLIGNE,,47.8990359513,-1.68518288272,231,,Poligné,Poligné,35,Ille-et-Vilaine,53,Bretagne
35232,PRINCE,35210,PRINCE,,48.2370987257,-1.10577928863,232,,Princé,Princé,35,Ille-et-Vilaine,53,Bretagne
35233,QUEBRIAC,35190,QUEBRIAC,,48.3560951211,-1.81258742693,233,,Québriac,Québriac,35,Ille-et-Vilaine,53,Bretagne
35234,QUEDILLAC,35290,QUEDILLAC,,48.2378252875,-2.14354106551,234,,Quédillac,Quédillac,35,Ille-et-Vilaine,53,Bretagne
35235,RANNEE,35130,RANNEE,,47.9006592126,-1.22652975633,235,,Rannée,Rannée,35,Ille-et-Vilaine,53,Bretagne
35236,REDON,35600,REDON,,47.6609734327,-2.08008568692,236,,Redon,Redon,35,Ille-et-Vilaine,53,Bretagne
35237,RENAC,35660,RENAC,,47.7276163658,-1.9661103542,237,,Renac,Renac,35,Ille-et-Vilaine,53,Bretagne
35238,RENNES,35000,RENNES,,48.1119791219,-1.68186449144,238,,Rennes,Rennes,35,Ille-et-Vilaine,53,Bretagne
35238,RENNES,35200,RENNES,,48.1119791219,-1.68186449144,238,,Rennes,Rennes,35,Ille-et-Vilaine,53,Bretagne
35238,RENNES,35700,RENNES,,48.1119791219,-1.68186449144,238,,Rennes,Rennes,35,Ille-et-Vilaine,53,Bretagne
35239,RETIERS,35240,RETIERS,,47.9060282888,-1.34373706233,239,,Retiers,Retiers,35,Ille-et-Vilaine,53,Bretagne
35240,LE RHEU,35650,LE RHEU,,48.097397419,-1.78149989185,240,Le,Rheu,Le Rheu,35,Ille-et-Vilaine,53,Bretagne
35240,LE RHEU,35650,LE RHEU,MOIGNE,48.097397419,-1.78149989185,240,Le,Rheu,Le Rheu,35,Ille-et-Vilaine,53,Bretagne
35241,LA RICHARDAIS,35780,LA RICHARDAIS,,48.6095815819,-2.04362780576,241,La,Richardais,La Richardais,35,Ille-et-Vilaine,53,Bretagne
35242,RIMOU,35560,RIMOU,,48.3879829755,-1.52823061249,242,,Rimou,Rimou,35,Ille-et-Vilaine,53,Bretagne
35243,ROMAGNE,35133,ROMAGNE,,48.3445101485,-1.26180834878,243,,Romagné,Romagné,35,Ille-et-Vilaine,53,Bretagne
35244,ROMAZY,35490,ROMAZY,,48.3796845502,-1.49290381835,244,,Romazy,Romazy,35,Ille-et-Vilaine,53,Bretagne
35245,ROMILLE,35850,ROMILLE,,48.2223488257,-1.87904298142,245,,Romillé,Romillé,35,Ille-et-Vilaine,53,Bretagne
35246,ROZ LANDRIEUX,35120,ROZ LANDRIEUX,,48.5458114056,-1.81733391785,246,,Roz-Landrieux,Roz-Landrieux,35,Ille-et-Vilaine,53,Bretagne
35247,ROZ SUR COUESNON,35610,ROZ SUR COUESNON,,48.5961737576,-1.59343425986,247,,Roz-sur-Couesnon,Roz-sur-Couesnon,35,Ille-et-Vilaine,53,Bretagne
35248,SAINS,35610,SAINS,,48.5560551633,-1.60318300159,248,,Sains,Sains,35,Ille-et-Vilaine,53,Bretagne
35249,STE ANNE SUR VILAINE,35390,STE ANNE SUR VILAINE,,47.7338101776,-1.8052140064,249,,Sainte-Anne-sur-Vilaine,Sainte-Anne-sur-Vilaine,35,Ille-et-Vilaine,53,Bretagne
35250,ST ARMEL,35230,ST ARMEL,,48.0133072658,-1.58086136058,250,,Saint-Armel,Saint-Armel,35,Ille-et-Vilaine,53,Bretagne
35251,ST AUBIN D AUBIGNE,35250,ST AUBIN D AUBIGNE,,48.2620229996,-1.60028441082,251,,Saint-Aubin-d'Aubigné,Saint-Aubin-d'Aubigné,35,Ille-et-Vilaine,53,Bretagne
35252,ST AUBIN DES LANDES,35500,ST AUBIN DES LANDES,,48.1003176943,-1.29173658407,252,,Saint-Aubin-des-Landes,Saint-Aubin-des-Landes,35,Ille-et-Vilaine,53,Bretagne
35253,ST AUBIN DU CORMIER,35140,ST AUBIN DU CORMIER,,48.2585499654,-1.42136462406,253,,Saint-Aubin-du-Cormier,Saint-Aubin-du-Cormier,35,Ille-et-Vilaine,53,Bretagne
35254,ST AUBIN DU PAVAIL,35410,ST AUBIN DU PAVAIL,,48.0416999563,-1.45944428687,254,,Saint-Aubin-du-Pavail,Saint-Aubin-du-Pavail,35,Ille-et-Vilaine,53,Bretagne
35255,ST BENOIT DES ONDES,35114,ST BENOIT DES ONDES,,48.613788991,-1.86036735113,255,,Saint-Benoît-des-Ondes,Saint-Benoît-des-Ondes,35,Ille-et-Vilaine,53,Bretagne
35256,ST BRIAC SUR MER,35800,ST BRIAC SUR MER,,48.6163388225,-2.12358766004,256,,Saint-Briac-sur-Mer,Saint-Briac-sur-Mer,35,Ille-et-Vilaine,53,Bretagne
35257,ST BRICE EN COGLES,35460,ST BRICE EN COGLES,,48.4132416625,-1.3677414096,257,,Maen Roch,Maen Roch,35,Ille-et-Vilaine,53,Bretagne
35258,ST BRIEUC DES IFFS,35630,ST BRIEUC DES IFFS,,48.3034329234,-1.84362998531,258,,Saint-Brieuc-des-Iffs,Saint-Brieuc-des-Iffs,35,Ille-et-Vilaine,53,Bretagne
35259,ST BROLADRE,35120,ST BROLADRE,,48.5799892763,-1.6606819388,259,,Saint-Broladre,Saint-Broladre,35,Ille-et-Vilaine,53,Bretagne
35260,ST CHRISTOPHE DES BOIS,35210,ST CHRISTOPHE DES BOIS,,48.2263856432,-1.23572120052,260,,Saint-Christophe-des-Bois,Saint-Christophe-des-Bois,35,Ille-et-Vilaine,53,Bretagne
35261,ST CHRISTOPHE DE VALAINS,35140,ST CHRISTOPHE DE VALAINS,,48.3414704329,-1.45214167623,261,,Saint-Christophe-de-Valains,Saint-Christophe-de-Valains,35,Ille-et-Vilaine,53,Bretagne
35262,STE COLOMBE,35134,STE COLOMBE,,47.8955543395,-1.45748423621,262,,Sainte-Colombe,Sainte-Colombe,35,Ille-et-Vilaine,53,Bretagne
35263,ST COULOMB,35350,ST COULOMB,,48.677369423,-1.92087004371,263,,Saint-Coulomb,Saint-Coulomb,35,Ille-et-Vilaine,53,Bretagne
35264,ST DIDIER,35220,ST DIDIER,,48.0932158644,-1.35806845203,264,,Saint-Didier,Saint-Didier,35,Ille-et-Vilaine,53,Bretagne
35265,ST DOMINEUC,35190,ST DOMINEUC,,48.3604701508,-1.86929825665,265,,Saint-Domineuc,Saint-Domineuc,35,Ille-et-Vilaine,53,Bretagne
35266,ST ERBLON,35230,ST ERBLON,,48.019118287,-1.64173794803,266,,Saint-Erblon,Saint-Erblon,35,Ille-et-Vilaine,53,Bretagne
35267,ST ETIENNE EN COGLES,35460,ST ETIENNE EN COGLES,,48.3914853938,-1.33052797198,267,,Saint-Étienne-en-Coglès,Saint-Étienne-en-Coglès,35,Ille-et-Vilaine,53,Bretagne
35268,ST GANTON,35550,ST GANTON,,47.769209131,-1.89133276373,268,,Saint-Ganton,Saint-Ganton,35,Ille-et-Vilaine,53,Bretagne
35269,ST GEORGES DE CHESNE,35140,ST GEORGES DE CHESNE,,48.2704626521,-1.30957375429,269,,Saint-Georges-de-Chesné,Saint-Georges-de-Chesné,35,Ille-et-Vilaine,53,Bretagne
35270,ST GEORGES DE GREHAIGNE,35610,ST GEORGES DE GREHAIGNE,,48.5792508588,-1.54914551771,270,,Saint-Georges-de-Gréhaigne,Saint-Georges-de-Gréhaigne,35,Ille-et-Vilaine,53,Bretagne
35271,ST GEORGES DE REINTEMBAULT,35420,ST GEORGES DE REINTEMBAULT,,48.5129956863,-1.23490800183,271,,Saint-Georges-de-Reintembault,Saint-Georges-de-Reintembault,35,Ille-et-Vilaine,53,Bretagne
35272,ST GERMAIN DU PINEL,35370,ST GERMAIN DU PINEL,,48.0050253994,-1.17262707343,272,,Saint-Germain-du-Pinel,Saint-Germain-du-Pinel,35,Ille-et-Vilaine,53,Bretagne
35273,ST GERMAIN EN COGLES,35133,ST GERMAIN EN COGLES,,48.3974434735,-1.26442564597,273,,Saint-Germain-en-Coglès,Saint-Germain-en-Coglès,35,Ille-et-Vilaine,53,Bretagne
35274,ST GERMAIN SUR ILLE,35250,ST GERMAIN SUR ILLE,,48.2509838584,-1.65264903322,274,,Saint-Germain-sur-Ille,Saint-Germain-sur-Ille,35,Ille-et-Vilaine,53,Bretagne
35275,ST GILLES,35590,ST GILLES,,48.1520929859,-1.85374690386,275,,Saint-Gilles,Saint-Gilles,35,Ille-et-Vilaine,53,Bretagne
35276,ST GONDRAN,35630,ST GONDRAN,,48.2682672284,-1.8250126371,276,,Saint-Gondran,Saint-Gondran,35,Ille-et-Vilaine,53,Bretagne
35277,ST GONLAY,35750,ST GONLAY,,48.1132469608,-2.07658788542,277,,Saint-Gonlay,Saint-Gonlay,35,Ille-et-Vilaine,53,Bretagne
35278,ST GREGOIRE,35760,ST GREGOIRE,,48.1588840471,-1.68571943452,278,,Saint-Grégoire,Saint-Grégoire,35,Ille-et-Vilaine,53,Bretagne
35279,ST GUINOUX,35430,ST GUINOUX,,48.5793096828,-1.88805991274,279,,Saint-Guinoux,Saint-Guinoux,35,Ille-et-Vilaine,53,Bretagne
35280,ST HILAIRE DES LANDES,35140,ST HILAIRE DES LANDES,,48.3383000653,-1.36660733195,280,,Saint-Hilaire-des-Landes,Saint-Hilaire-des-Landes,35,Ille-et-Vilaine,53,Bretagne
35281,ST JACQUES DE LA LANDE,35136,ST JACQUES DE LA LANDE,,48.0755625884,-1.72405381322,281,,Saint-Jacques-de-la-Lande,Saint-Jacques-de-la-Lande,35,Ille-et-Vilaine,53,Bretagne
35282,ST JEAN SUR COUESNON,35140,ST JEAN SUR COUESNON,,48.2768095716,-1.36810224872,282,,Saint-Jean-sur-Couesnon,Saint-Jean-sur-Couesnon,35,Ille-et-Vilaine,53,Bretagne
35283,ST JEAN SUR VILAINE,35220,ST JEAN SUR VILAINE,,48.1210316467,-1.34032525907,283,,Saint-Jean-sur-Vilaine,Saint-Jean-sur-Vilaine,35,Ille-et-Vilaine,53,Bretagne
35284,ST JOUAN DES GUERETS,35430,ST JOUAN DES GUERETS,,48.6037491663,-1.96530816919,284,,Saint-Jouan-des-Guérets,Saint-Jouan-des-Guérets,35,Ille-et-Vilaine,53,Bretagne
35285,ST JUST,35550,ST JUST,,47.7645343074,-1.96896648754,285,,Saint-Just,Saint-Just,35,Ille-et-Vilaine,53,Bretagne
35286,ST LEGER DES PRES,35270,ST LEGER DES PRES,,48.388486199,-1.65745157773,286,,Saint-Léger-des-Prés,Saint-Léger-des-Prés,35,Ille-et-Vilaine,53,Bretagne
35287,ST LUNAIRE,35800,ST LUNAIRE,,48.620460045,-2.09655988835,287,,Saint-Lunaire,Saint-Lunaire,35,Ille-et-Vilaine,53,Bretagne
35288,ST MALO,35400,ST MALO,,48.6400443482,-1.98060627256,288,,Saint-Malo,Saint-Malo,35,Ille-et-Vilaine,53,Bretagne
35288,ST MALO,35400,ST MALO,CHATEAU MALO,48.6400443482,-1.98060627256,288,,Saint-Malo,Saint-Malo,35,Ille-et-Vilaine,53,Bretagne
35288,ST MALO,35400,ST MALO,PARAME,48.6400443482,-1.98060627256,288,,Saint-Malo,Saint-Malo,35,Ille-et-Vilaine,53,Bretagne
35288,ST MALO,35400,ST MALO,ROTHENEUF,48.6400443482,-1.98060627256,288,,Saint-Malo,Saint-Malo,35,Ille-et-Vilaine,53,Bretagne
35288,ST MALO,35400,ST MALO,ST SERVAN SUR MER,48.6400443482,-1.98060627256,288,,Saint-Malo,Saint-Malo,35,Ille-et-Vilaine,53,Bretagne
35289,ST MALO DE PHILY,35480,ST MALO DE PHILY,,47.8681860687,-1.80518886357,289,,Saint-Malo-de-Phily,Saint-Malo-de-Phily,35,Ille-et-Vilaine,53,Bretagne
35290,ST MALON SUR MEL,35750,ST MALON SUR MEL,,48.0944048491,-2.11182383067,290,,Saint-Malon-sur-Mel,Saint-Malon-sur-Mel,35,Ille-et-Vilaine,53,Bretagne
35291,ST MARCAN,35120,ST MARCAN,,48.5849299477,-1.627947289,291,,Saint-Marcan,Saint-Marcan,35,Ille-et-Vilaine,53,Bretagne
35292,ST MARC LE BLANC,35460,ST MARC LE BLANC,,48.3752704313,-1.40505053569,292,,Saint-Marc-le-Blanc,Saint-Marc-le-Blanc,35,Ille-et-Vilaine,53,Bretagne
35293,ST MARC SUR COUESNON,35140,ST MARC SUR COUESNON,,48.3067449884,-1.36642008631,293,,Saint-Marc-sur-Couesnon,Saint-Marc-sur-Couesnon,35,Ille-et-Vilaine,53,Bretagne
35294,STE MARIE,35600,STE MARIE,,47.6935116851,-2.01321068932,294,,Sainte-Marie,Sainte-Marie,35,Ille-et-Vilaine,53,Bretagne
35295,ST MAUGAN,35750,ST MAUGAN,,48.1367408206,-2.10234592553,295,,Saint-Maugan,Saint-Maugan,35,Ille-et-Vilaine,53,Bretagne
35296,ST MEDARD SUR ILLE,35250,ST MEDARD SUR ILLE,,48.2728481526,-1.67038135479,296,,Saint-Médard-sur-Ille,Saint-Médard-sur-Ille,35,Ille-et-Vilaine,53,Bretagne
35297,ST MEEN LE GRAND,35290,ST MEEN LE GRAND,,48.1925581351,-2.19497591455,297,,Saint-Méen-le-Grand,Saint-Méen-le-Grand,35,Ille-et-Vilaine,53,Bretagne
35299,ST MELOIR DES ONDES,35350,ST MELOIR DES ONDES,,48.6387125438,-1.90517612803,299,,Saint-Méloir-des-Ondes,Saint-Méloir-des-Ondes,35,Ille-et-Vilaine,53,Bretagne
35300,ST M HERVE,35500,ST M HERVE,,48.1724748255,-1.11096874338,300,,Saint-M'Hervé,Saint-M'Hervé,35,Ille-et-Vilaine,53,Bretagne
35301,ST M HERVON,35360,ST M HERVON,,48.2283386623,-2.06531705516,301,,Saint-M'Hervon,Saint-M'Hervon,35,Ille-et-Vilaine,53,Bretagne
35302,ST ONEN LA CHAPELLE,35290,ST ONEN LA CHAPELLE,,48.1695046488,-2.1556915013,302,,Saint-Onen-la-Chapelle,Saint-Onen-la-Chapelle,35,Ille-et-Vilaine,53,Bretagne
35303,ST OUEN LA ROUERIE,35460,ST OUEN LA ROUERIE,,48.454941971,-1.43630013291,303,,Saint-Ouen-la-Rouërie,Saint-Ouen-la-Rouërie,35,Ille-et-Vilaine,53,Bretagne
35304,ST OUEN DES ALLEUX,35140,ST OUEN DES ALLEUX,,48.3270441393,-1.42763675483,304,,Saint-Ouen-des-Alleux,Saint-Ouen-des-Alleux,35,Ille-et-Vilaine,53,Bretagne
35305,ST PERAN,35380,ST PERAN,,48.0566572293,-2.06502362263,305,,Saint-Péran,Saint-Péran,35,Ille-et-Vilaine,53,Bretagne
35306,ST PERE,35430,ST PERE,,48.5841780785,-1.9291577364,306,,Saint-Père,Saint-Père,35,Ille-et-Vilaine,53,Bretagne
35307,ST PERN,35190,ST PERN,,48.2809544263,-1.98293675576,307,,Saint-Pern,Saint-Pern,35,Ille-et-Vilaine,53,Bretagne
35308,ST PIERRE DE PLESGUEN,35720,ST PIERRE DE PLESGUEN,,48.4550260435,-1.88873891943,308,,Saint-Pierre-de-Plesguen,Saint-Pierre-de-Plesguen,35,Ille-et-Vilaine,53,Bretagne
35309,ST REMY DU PLAIN,35560,ST REMY DU PLAIN,,48.3758019019,-1.56690944074,309,,Saint-Rémy-du-Plain,Saint-Rémy-du-Plain,35,Ille-et-Vilaine,53,Bretagne
35310,ST SAUVEUR DES LANDES,35133,ST SAUVEUR DES LANDES,,48.3469024339,-1.31411458673,310,,Saint-Sauveur-des-Landes,Saint-Sauveur-des-Landes,35,Ille-et-Vilaine,53,Bretagne
35311,ST SEGLIN,35330,ST SEGLIN,,47.8510645344,-2.02652054855,311,,Saint-Séglin,Saint-Séglin,35,Ille-et-Vilaine,53,Bretagne
35312,ST SENOUX,35580,ST SENOUX,,47.9097827425,-1.7902930218,312,,Saint-Senoux,Saint-Senoux,35,Ille-et-Vilaine,53,Bretagne
35314,ST SULIAC,35430,ST SULIAC,,48.569900609,-1.96692288512,314,,Saint-Suliac,Saint-Suliac,35,Ille-et-Vilaine,53,Bretagne
35315,ST SULPICE LA FORET,35250,ST SULPICE LA FORET,,48.21225732,-1.57663512362,315,,Saint-Sulpice-la-Forêt,Saint-Sulpice-la-Forêt,35,Ille-et-Vilaine,53,Bretagne
35316,ST SULPICE DES LANDES,35390,ST SULPICE DES LANDES,,47.7769253675,-1.62552770861,316,,Saint-Sulpice-des-Landes,Saint-Sulpice-des-Landes,35,Ille-et-Vilaine,53,Bretagne
35317,ST SYMPHORIEN,35630,ST SYMPHORIEN,,48.280614851,-1.80334544561,317,,Saint-Symphorien,Saint-Symphorien,35,Ille-et-Vilaine,53,Bretagne
35318,ST THUAL,35190,ST THUAL,,48.3355023423,-1.93174889174,318,,Saint-Thual,Saint-Thual,35,Ille-et-Vilaine,53,Bretagne
35319,ST THURIAL,35310,ST THURIAL,,48.0335297683,-1.94037369583,319,,Saint-Thurial,Saint-Thurial,35,Ille-et-Vilaine,53,Bretagne
35320,ST UNIAC,35360,ST UNIAC,,48.1669783756,-2.03378556474,320,,Saint-Uniac,Saint-Uniac,35,Ille-et-Vilaine,53,Bretagne
35321,SAULNIERES,35320,SAULNIERES,,47.9194668221,-1.57761100368,321,,Saulnières,Saulnières,35,Ille-et-Vilaine,53,Bretagne
35322,LE SEL DE BRETAGNE,35320,LE SEL DE BRETAGNE,,47.8978195482,-1.60609578245,322,Le,Sel-de-Bretagne,Le Sel-de-Bretagne,35,Ille-et-Vilaine,53,Bretagne
35323,LA SELLE EN COGLES,35460,LA SELLE EN COGLES,,48.438498732,-1.33753495005,323,La,Selle-en-Coglès,La Selle-en-Coglès,35,Ille-et-Vilaine,53,Bretagne
35324,LA SELLE EN LUITRE,35133,LA SELLE EN LUITRE,,48.3210231542,-1.15116317986,324,La,Selle-en-Luitré,La Selle-en-Luitré,35,Ille-et-Vilaine,53,Bretagne
35325,LA SELLE GUERCHAISE,35130,LA SELLE GUERCHAISE,,47.942684871,-1.16948623291,325,La,Selle-Guerchaise,La Selle-Guerchaise,35,Ille-et-Vilaine,53,Bretagne
35326,SENS DE BRETAGNE,35490,SENS DE BRETAGNE,,48.3367315734,-1.55392717615,326,,Sens-de-Bretagne,Sens-de-Bretagne,35,Ille-et-Vilaine,53,Bretagne
35327,SERVON SUR VILAINE,35530,SERVON SUR VILAINE,,48.120843256,-1.45777238842,327,,Servon-sur-Vilaine,Servon-sur-Vilaine,35,Ille-et-Vilaine,53,Bretagne
35328,SIXT SUR AFF,35550,SIXT SUR AFF,,47.7748715245,-2.04938893664,328,,Sixt-sur-Aff,Sixt-sur-Aff,35,Ille-et-Vilaine,53,Bretagne
35329,SOUGEAL,35610,SOUGEAL,,48.5026972021,-1.51517240399,329,,Sougéal,Sougéal,35,Ille-et-Vilaine,53,Bretagne
35330,TAILLIS,35500,TAILLIS,,48.1846341773,-1.24010156402,330,,Taillis,Taillis,35,Ille-et-Vilaine,53,Bretagne
35331,TALENSAC,35160,TALENSAC,,48.0906335682,-1.92552544674,331,,Talensac,Talensac,35,Ille-et-Vilaine,53,Bretagne
35332,TEILLAY,35620,TEILLAY,,47.8077925322,-1.5223130707,332,,Teillay,Teillay,35,Ille-et-Vilaine,53,Bretagne
35333,LE THEIL DE BRETAGNE,35240,LE THEIL DE BRETAGNE,,47.917964054,-1.42174921295,333,Le,Theil-de-Bretagne,Le Theil-de-Bretagne,35,Ille-et-Vilaine,53,Bretagne
35334,THORIGNE FOUILLARD,35235,THORIGNE FOUILLARD,,48.1566082899,-1.58409867633,334,,Thorigné-Fouillard,Thorigné-Fouillard,35,Ille-et-Vilaine,53,Bretagne
35335,THOURIE,35134,THOURIE,,47.8486763783,-1.45762680863,335,,Thourie,Thourie,35,Ille-et-Vilaine,53,Bretagne
35336,LE TIERCENT,35460,LE TIERCENT,,48.3496552529,-1.42320230494,336,Le,Tiercent,Le Tiercent,35,Ille-et-Vilaine,53,Bretagne
35337,TINTENIAC,35190,TINTENIAC,,48.3231993668,-1.83043308458,337,,Tinténiac,Tinténiac,35,Ille-et-Vilaine,53,Bretagne
35338,TORCE,35370,TORCE,,48.06102043,-1.27189716204,338,,Torcé,Torcé,35,Ille-et-Vilaine,53,Bretagne
35339,TRANS LA FORET,35610,TRANS LA FORET,,48.4878512268,-1.60369226136,339,,Trans-la-Forêt,Trans-la-Forêt,35,Ille-et-Vilaine,53,Bretagne
35340,TREFFENDEL,35380,TREFFENDEL,,48.0313953352,-2.00818373637,340,,Treffendel,Treffendel,35,Ille-et-Vilaine,53,Bretagne
35341,TREMBLAY,35460,TREMBLAY,,48.4172708877,-1.45787643145,341,,Tremblay,Tremblay,35,Ille-et-Vilaine,53,Bretagne
35342,TREMEHEUC,35270,TREMEHEUC,,48.4389203583,-1.69745222978,342,,Trémeheuc,Trémeheuc,35,Ille-et-Vilaine,53,Bretagne
35343,TRESBOEUF,35320,TRESBOEUF,,47.8809229293,-1.55314201505,343,,Tresbœuf,Tresbœuf,35,Ille-et-Vilaine,53,Bretagne
35344,TRESSE,35720,TRESSE,,48.48091901,-1.87572379132,344,,Tressé,Tressé,35,Ille-et-Vilaine,53,Bretagne
35345,TREVERIEN,35190,TREVERIEN,,48.3708768368,-1.92035072104,345,,Trévérien,Trévérien,35,Ille-et-Vilaine,53,Bretagne
35346,TRIMER,35190,TRIMER,,48.3440055823,-1.89692594025,346,,Trimer,Trimer,35,Ille-et-Vilaine,53,Bretagne
35347,VAL D IZE,35450,VAL D IZE,,48.1899182799,-1.30478438424,347,,Val-d'Izé,Val-d'Izé,35,Ille-et-Vilaine,53,Bretagne
35348,VENDEL,35140,VENDEL,,48.2924693475,-1.30669420959,348,,Vendel,Vendel,35,Ille-et-Vilaine,53,Bretagne
35350,VERGEAL,35680,VERGEAL,,48.0357832819,-1.25557177588,350,,Vergéal,Vergéal,35,Ille-et-Vilaine,53,Bretagne
35351,LE VERGER,35160,LE VERGER,,48.0658260742,-1.93692739781,351,Le,Verger,Le Verger,35,Ille-et-Vilaine,53,Bretagne
35352,VERN SUR SEICHE,35770,VERN SUR SEICHE,,48.0525245014,-1.60521106164,352,,Vern-sur-Seiche,Vern-sur-Seiche,35,Ille-et-Vilaine,53,Bretagne
35353,VEZIN LE COQUET,35132,VEZIN LE COQUET,,48.1171829255,-1.74956473355,353,,Vezin-le-Coquet,Vezin-le-Coquet,35,Ille-et-Vilaine,53,Bretagne
35354,VIEUX VIEL,35610,VIEUX VIEL,,48.5010768031,-1.55308339453,354,,Vieux-Viel,Vieux-Viel,35,Ille-et-Vilaine,53,Bretagne
35355,VIEUX VY SUR COUESNON,35490,VIEUX VY SUR COUESNON,,48.3340738846,-1.49930744372,355,,Vieux-Vy-sur-Couesnon,Vieux-Vy-sur-Couesnon,35,Ille-et-Vilaine,53,Bretagne
35356,VIGNOC,35630,VIGNOC,,48.257222573,-1.76336508866,356,,Vignoc,Vignoc,35,Ille-et-Vilaine,53,Bretagne
35357,VILLAMEE,35420,VILLAMEE,,48.4562439704,-1.21840173852,357,,Villamée,Villamée,35,Ille-et-Vilaine,53,Bretagne
35358,LA VILLE ES NONAIS,35430,LA VILLE ES NONAIS,,48.5488601096,-1.95172640786,358,La,Ville-ès-Nonais,La Ville-ès-Nonais,35,Ille-et-Vilaine,53,Bretagne
35359,VISSEICHE,35130,VISSEICHE,,47.959768941,-1.29877248787,359,,Visseiche,Visseiche,35,Ille-et-Vilaine,53,Bretagne
35360,VITRE,35500,VITRE,,48.1140815063,-1.19370720718,360,,Vitré,Vitré,35,Ille-et-Vilaine,53,Bretagne
35361,LE VIVIER SUR MER,35960,LE VIVIER SUR MER,,48.597890871,-1.77994172139,361,Le,Vivier-sur-Mer,Le Vivier-sur-Mer,35,Ille-et-Vilaine,53,Bretagne
35362,LE TRONCHET,35540,LE TRONCHET,,48.4926615398,-1.84121493446,362,Le,Tronchet,Le Tronchet,35,Ille-et-Vilaine,53,Bretagne
35363,PONT PEAN,35131,PONT PEAN,,48.0111348801,-1.69601676125,363,,Pont-Péan,Pont-Péan,35,Ille-et-Vilaine,53,Bretagne
36001,AIGURANDE,36140,AIGURANDE,,46.455684191,1.83976972145,1,,Aigurande,Aigurande,36,Indre,24,Centre-Val de Loire
36002,AIZE,36150,AIZE,,47.1078990064,1.70661723859,2,,Aize,Aize,36,Indre,24,Centre-Val de Loire
36003,AMBRAULT,36120,AMBRAULT,,46.7837338524,1.94971417939,3,,Ambrault,Ambrault,36,Indre,24,Centre-Val de Loire
36004,ANJOUIN,36210,ANJOUIN,,47.2008943989,1.80745396051,4,,Anjouin,Anjouin,36,Indre,24,Centre-Val de Loire
36005,ARDENTES,36120,ARDENTES,,46.7359618133,1.82403149268,5,,Ardentes,Ardentes,36,Indre,24,Centre-Val de Loire
36006,ARGENTON SUR CREUSE,36200,ARGENTON SUR CREUSE,,46.5787862593,1.4926283101,6,,Argenton-sur-Creuse,Argenton-sur-Creuse,36,Indre,24,Centre-Val de Loire
36007,ARGY,36500,ARGY,,46.9410833796,1.46153298844,7,,Argy,Argy,36,Indre,24,Centre-Val de Loire
36008,ARPHEUILLES,36700,ARPHEUILLES,,46.8997863068,1.27397966099,8,,Arpheuilles,Arpheuilles,36,Indre,24,Centre-Val de Loire
36009,ARTHON,36330,ARTHON,,46.6931304896,1.71130740486,9,,Arthon,Arthon,36,Indre,24,Centre-Val de Loire
36010,AZAY LE FERRON,36290,AZAY LE FERRON,,46.8459784921,1.08124698726,10,,Azay-le-Ferron,Azay-le-Ferron,36,Indre,24,Centre-Val de Loire
36011,BAGNEUX,36210,BAGNEUX,,47.1677532735,1.75606280452,11,,Bagneux,Bagneux,36,Indre,24,Centre-Val de Loire
36012,BARAIZE,36270,BARAIZE,,46.480854966,1.57028016137,12,,Baraize,Baraize,36,Indre,24,Centre-Val de Loire
36013,BAUDRES,36110,BAUDRES,,47.0521492475,1.57305496545,13,,Baudres,Baudres,36,Indre,24,Centre-Val de Loire
36014,BAZAIGES,36270,BAZAIGES,,46.4844280424,1.5276226501,14,,Bazaiges,Bazaiges,36,Indre,24,Centre-Val de Loire
36015,BEAULIEU,36310,BEAULIEU,,46.3854812968,1.29760988524,15,,Beaulieu,Beaulieu,36,Indre,24,Centre-Val de Loire
36016,BELABRE,36370,BELABRE,,46.5555750256,1.16199318259,16,,Bélâbre,Bélâbre,36,Indre,24,Centre-Val de Loire
36017,LA BERTHENOUX,36400,LA BERTHENOUX,,46.6972820615,2.05729628607,17,La,Berthenoux,La Berthenoux,36,Indre,24,Centre-Val de Loire
36018,LE BLANC,36300,LE BLANC,,46.6346200347,1.10013221164,18,Le,Blanc,Le Blanc,36,Indre,24,Centre-Val de Loire
36019,BOMMIERS,36120,BOMMIERS,,46.7799451256,2.00352346772,19,,Bommiers,Bommiers,36,Indre,24,Centre-Val de Loire
36020,BONNEUIL,36310,BONNEUIL,,46.3833216056,1.24155343607,20,,Bonneuil,Bonneuil,36,Indre,24,Centre-Val de Loire
36021,LES BORDES,36100,LES BORDES,,46.9845708216,1.9639586533,21,Les,Bordes,Les Bordes,36,Indre,24,Centre-Val de Loire
36022,BOUESSE,36200,BOUESSE,,46.6263374879,1.68778572639,22,,Bouesse,Bouesse,36,Indre,24,Centre-Val de Loire
36023,BOUGES LE CHATEAU,36110,BOUGES LE CHATEAU,,47.0315026435,1.66537369681,23,,Bouges-le-Château,Bouges-le-Château,36,Indre,24,Centre-Val de Loire
36024,BRETAGNE,36110,BRETAGNE,,46.999120025,1.69787130226,24,,Bretagne,Bretagne,36,Indre,24,Centre-Val de Loire
36025,BRIANTES,36400,BRIANTES,,46.5505241827,2.02422131668,25,,Briantes,Briantes,36,Indre,24,Centre-Val de Loire
36026,BRION,36110,BRION,,46.9498101823,1.72891694176,26,,Brion,Brion,36,Indre,24,Centre-Val de Loire
36027,BRIVES,36100,BRIVES,,46.8571427122,1.92583011127,27,,Brives,Brives,36,Indre,24,Centre-Val de Loire
36028,LA BUXERETTE,36140,LA BUXERETTE,,46.4956309959,1.79829728284,28,La,Buxerette,La Buxerette,36,Indre,24,Centre-Val de Loire
36029,BUXEUIL,36150,BUXEUIL,,47.1269218412,1.69487135534,29,,Buxeuil,Buxeuil,36,Indre,24,Centre-Val de Loire
36030,BUXIERES D AILLAC,36230,BUXIERES D AILLAC,,46.6387685849,1.75300924427,30,,Buxières-d'Aillac,Buxières-d'Aillac,36,Indre,24,Centre-Val de Loire
36031,BUZANCAIS,36500,BUZANCAIS,,46.8875380464,1.41254913131,31,,Buzançais,Buzançais,36,Indre,24,Centre-Val de Loire
36032,CEAULMONT,36200,CEAULMONT,,46.5302709049,1.55681464943,32,,Ceaulmont,Ceaulmont,36,Indre,24,Centre-Val de Loire
36033,CELON,36200,CELON,,46.5315916798,1.51221513652,33,,Celon,Celon,36,Indre,24,Centre-Val de Loire
36034,CHABRIS,36210,CHABRIS,,47.2519122488,1.66198740226,34,,Chabris,Chabris,36,Indre,24,Centre-Val de Loire
36035,CHAILLAC,36310,CHAILLAC,,46.4260014616,1.2915620907,35,,Chaillac,Chaillac,36,Indre,24,Centre-Val de Loire
36036,CHALAIS,36370,CHALAIS,,46.5430861576,1.22189914233,36,,Chalais,Chalais,36,Indre,24,Centre-Val de Loire
36037,LA CHAMPENOISE,36100,LA CHAMPENOISE,,46.9393942859,1.79291889355,37,La,Champenoise,La Champenoise,36,Indre,24,Centre-Val de Loire
36038,CHAMPILLET,36160,CHAMPILLET,,46.5468094689,2.10739016471,38,,Champillet,Champillet,36,Indre,24,Centre-Val de Loire
36040,LA CHAPELLE ORTHEMALE,36500,LA CHAPELLE ORTHEMALE,,46.8327873497,1.45197774217,40,La,Chapelle-Orthemale,La Chapelle-Orthemale,36,Indre,24,Centre-Val de Loire
36041,LA CHAPELLE ST LAURIAN,36150,LA CHAPELLE ST LAURIAN,,47.0547841685,1.78297349808,41,La,Chapelle-Saint-Laurian,La Chapelle-Saint-Laurian,36,Indre,24,Centre-Val de Loire
36042,CHASSENEUIL,36800,CHASSENEUIL,,46.6570647712,1.49349614849,42,,Chasseneuil,Chasseneuil,36,Indre,24,Centre-Val de Loire
36043,CHASSIGNOLLES,36400,CHASSIGNOLLES,,46.5288369456,1.93596735168,43,,Chassignolles,Chassignolles,36,Indre,24,Centre-Val de Loire
36044,CHATEAUROUX,36000,CHATEAUROUX,,46.8029617828,1.69399812001,44,,Châteauroux,Châteauroux,36,Indre,24,Centre-Val de Loire
36045,CHATILLON SUR INDRE,36700,CHATILLON SUR INDRE,,46.9917064296,1.17509631629,45,,Châtillon-sur-Indre,Châtillon-sur-Indre,36,Indre,24,Centre-Val de Loire
36046,LA CHATRE,36400,LA CHATRE,,46.5738617061,1.99112468906,46,La,Châtre,La Châtre,36,Indre,24,Centre-Val de Loire
36047,LA CHATRE LANGLIN,36170,LA CHATRE LANGLIN,,46.4097629737,1.38754317805,47,La,Châtre-Langlin,La Châtre-Langlin,36,Indre,24,Centre-Val de Loire
36048,CHAVIN,36200,CHAVIN,,46.5625110461,1.61164678911,48,,Chavin,Chavin,36,Indre,24,Centre-Val de Loire
36049,CHAZELET,36170,CHAZELET,,46.5082146993,1.43370852289,49,,Chazelet,Chazelet,36,Indre,24,Centre-Val de Loire
36050,CHEZELLES,36500,CHEZELLES,,46.8939222483,1.56256220067,50,,Chezelles,Chezelles,36,Indre,24,Centre-Val de Loire
36051,CHITRAY,36800,CHITRAY,,46.6594985934,1.35961781194,51,,Chitray,Chitray,36,Indre,24,Centre-Val de Loire
36052,CHOUDAY,36100,CHOUDAY,,46.9211133197,2.05340256706,52,,Chouday,Chouday,36,Indre,24,Centre-Val de Loire
36053,CIRON,36300,CIRON,,46.6323391701,1.26102864126,53,,Ciron,Ciron,36,Indre,24,Centre-Val de Loire
36053,CIRON,36300,CIRON,SCOURY,46.6323391701,1.26102864126,53,,Ciron,Ciron,36,Indre,24,Centre-Val de Loire
36054,CLERE DU BOIS,36700,CLERE DU BOIS,,46.9425971085,1.10231213946,54,,Cléré-du-Bois,Cléré-du-Bois,36,Indre,24,Centre-Val de Loire
36055,CLION,36700,CLION,,46.9379936712,1.22161991718,55,,Clion,Clion,36,Indre,24,Centre-Val de Loire
36056,CLUIS,36340,CLUIS,,46.5352767635,1.74340331959,56,,Cluis,Cluis,36,Indre,24,Centre-Val de Loire
36057,COINGS,36130,COINGS,,46.8840276605,1.72143917623,57,,Coings,Coings,36,Indre,24,Centre-Val de Loire
36058,CONCREMIERS,36300,CONCREMIERS,,46.5939953457,1.01591887364,58,,Concremiers,Concremiers,36,Indre,24,Centre-Val de Loire
36059,CONDE,36100,CONDE,,46.8854658977,1.98706426644,59,,Condé,Condé,36,Indre,24,Centre-Val de Loire
36060,CREVANT,36140,CREVANT,,46.4692924616,1.93707320558,60,,Crevant,Crevant,36,Indre,24,Centre-Val de Loire
36061,CROZON SUR VAUVRE,36140,CROZON SUR VAUVRE,,46.481288114,1.88194807348,61,,Crozon-sur-Vauvre,Crozon-sur-Vauvre,36,Indre,24,Centre-Val de Loire
36062,CUZION,36190,CUZION,,46.4715144043,1.61693641258,62,,Cuzion,Cuzion,36,Indre,24,Centre-Val de Loire
36063,DEOLS,36130,DEOLS,,46.8403192166,1.70244266478,63,,Déols,Déols,36,Indre,24,Centre-Val de Loire
36064,DIORS,36130,DIORS,,46.8294887211,1.81115700532,64,,Diors,Diors,36,Indre,24,Centre-Val de Loire
36065,DIOU,36260,DIOU,,47.0454733452,2.00611539537,65,,Diou,Diou,36,Indre,24,Centre-Val de Loire
36066,DOUADIC,36300,DOUADIC,,46.7039740517,1.1179251996,66,,Douadic,Douadic,36,Indre,24,Centre-Val de Loire
36067,DUNET,36310,DUNET,,46.4695171037,1.28603119899,67,,Dunet,Dunet,36,Indre,24,Centre-Val de Loire
36068,DUN LE POELIER,36210,DUN LE POELIER,,47.2189643606,1.7473439973,68,,Dun-le-Poëlier,Dun-le-Poëlier,36,Indre,24,Centre-Val de Loire
36069,ECUEILLE,36240,ECUEILLE,,47.0772111869,1.35366792893,69,,Écueillé,Écueillé,36,Indre,24,Centre-Val de Loire
36070,EGUZON CHANTOME,36270,EGUZON CHANTOME,,46.4342273236,1.56931280113,70,,Éguzon-Chantôme,Éguzon-Chantôme,36,Indre,24,Centre-Val de Loire
36070,EGUZON CHANTOME,36270,EGUZON CHANTOME,CHANTOME,46.4342273236,1.56931280113,70,,Éguzon-Chantôme,Éguzon-Chantôme,36,Indre,24,Centre-Val de Loire
36071,ETRECHET,36120,ETRECHET,,46.7926466693,1.77773981101,71,,Étrechet,Étrechet,36,Indre,24,Centre-Val de Loire
36072,FAVEROLLES,36360,FAVEROLLES,,47.1720331258,1.38572161152,72,,Faverolles-en-Berry,Faverolles-en-Berry,36,Indre,24,Centre-Val de Loire
36073,FEUSINES,36160,FEUSINES,,46.5123371526,2.10013659358,73,,Feusines,Feusines,36,Indre,24,Centre-Val de Loire
36074,FLERE LA RIVIERE,36700,FLERE LA RIVIERE,,46.9965732721,1.09318209404,74,,Fléré-la-Rivière,Fléré-la-Rivière,36,Indre,24,Centre-Val de Loire
36075,FONTENAY,36150,FONTENAY,,47.0542552682,1.74236009323,75,,Fontenay,Fontenay,36,Indre,24,Centre-Val de Loire
36076,FONTGOMBAULT,36220,FONTGOMBAULT,,46.6744006653,0.971856321083,76,,Fontgombault,Fontgombault,36,Indre,24,Centre-Val de Loire
36077,FONTGUENAND,36600,FONTGUENAND,,47.2019729432,1.53449343992,77,,Fontguenand,Fontguenand,36,Indre,24,Centre-Val de Loire
36078,FOUGEROLLES,36230,FOUGEROLLES,,46.568597267,1.86981174393,78,,Fougerolles,Fougerolles,36,Indre,24,Centre-Val de Loire
36079,FRANCILLON,36110,FRANCILLON,,46.9464450461,1.55776812764,79,,Francillon,Francillon,36,Indre,24,Centre-Val de Loire
36080,FREDILLE,36180,FREDILLE,,47.001803975,1.47736111614,80,,Frédille,Frédille,36,Indre,24,Centre-Val de Loire
36081,GARGILESSE DAMPIERRE,36190,GARGILESSE DAMPIERRE,,46.5053300669,1.62060670843,81,,Gargilesse-Dampierre,Gargilesse-Dampierre,36,Indre,24,Centre-Val de Loire
36082,GEHEE,36240,GEHEE,,47.0384812055,1.49376819488,82,,Gehée,Gehée,36,Indre,24,Centre-Val de Loire
36083,GIROUX,36150,GIROUX,,47.0650697963,1.90667075271,83,,Giroux,Giroux,36,Indre,24,Centre-Val de Loire
36084,GOURNAY,36230,GOURNAY,,46.5963808086,1.73496195112,84,,Gournay,Gournay,36,Indre,24,Centre-Val de Loire
36085,GUILLY,36150,GUILLY,,47.0854429758,1.73470769593,85,,Guilly,Guilly,36,Indre,24,Centre-Val de Loire
36086,HEUGNES,36180,HEUGNES,,47.0279062029,1.38544122322,86,,Heugnes,Heugnes,36,Indre,24,Centre-Val de Loire
36087,INGRANDES,36300,INGRANDES,,46.5931498881,0.963558213736,87,,Ingrandes,Ingrandes,36,Indre,24,Centre-Val de Loire
36088,ISSOUDUN,36100,ISSOUDUN,,46.9489390293,2.00053790133,88,,Issoudun,Issoudun,36,Indre,24,Centre-Val de Loire
36089,JEU LES BOIS,36120,JEU LES BOIS,,46.6818941747,1.79462790894,89,,Jeu-les-Bois,Jeu-les-Bois,36,Indre,24,Centre-Val de Loire
36090,JEU MALOCHES,36240,JEU MALOCHES,,47.0475431378,1.44169166133,90,,Jeu-Maloches,Jeu-Maloches,36,Indre,24,Centre-Val de Loire
36091,LACS,36400,LACS,,46.5913517222,2.03649976093,91,,Lacs,Lacs,36,Indre,24,Centre-Val de Loire
36092,LANGE,36600,LANGE,,47.0739529202,1.50748224415,92,,Langé,Langé,36,Indre,24,Centre-Val de Loire
36093,LEVROUX,36110,LEVROUX,,46.9727032392,1.63027138239,93,,Levroux,Levroux,36,Indre,24,Centre-Val de Loire
36093,LEVROUX,36110,LEVROUX,ST MARTIN DE LAMPS,46.9727032392,1.63027138239,93,,Levroux,Levroux,36,Indre,24,Centre-Val de Loire
36094,LIGNAC,36370,LIGNAC,,46.4761367692,1.20092870282,94,,Lignac,Lignac,36,Indre,24,Centre-Val de Loire
36095,LIGNEROLLES,36160,LIGNEROLLES,,46.50077688,2.16356266069,95,,Lignerolles,Lignerolles,36,Indre,24,Centre-Val de Loire
36096,LINGE,36220,LINGE,,46.7553232246,1.11330278718,96,,Lingé,Lingé,36,Indre,24,Centre-Val de Loire
36097,LINIEZ,36150,LINIEZ,,47.0142256884,1.75629782161,97,,Liniez,Liniez,36,Indre,24,Centre-Val de Loire
36098,LIZERAY,36100,LIZERAY,,46.9850879886,1.9034021028,98,,Lizeray,Lizeray,36,Indre,24,Centre-Val de Loire
36099,LOURDOUEIX ST MICHEL,36140,LOURDOUEIX ST MICHEL,,46.4167963752,1.7255656955,99,,Lourdoueix-Saint-Michel,Lourdoueix-Saint-Michel,36,Indre,24,Centre-Val de Loire
36100,LOUROUER ST LAURENT,36400,LOUROUER ST LAURENT,,46.6194091093,2.01790100072,100,,Lourouer-Saint-Laurent,Lourouer-Saint-Laurent,36,Indre,24,Centre-Val de Loire
36101,LUANT,36350,LUANT,,46.7276279837,1.56239391822,101,,Luant,Luant,36,Indre,24,Centre-Val de Loire
36102,LUCAY LE LIBRE,36150,LUCAY LE LIBRE,,47.0943523888,1.91685079999,102,,Luçay-le-Libre,Luçay-le-Libre,36,Indre,24,Centre-Val de Loire
36103,LUCAY LE MALE,36360,LUCAY LE MALE,,47.1147802667,1.42699842882,103,,Luçay-le-Mâle,Luçay-le-Mâle,36,Indre,24,Centre-Val de Loire
36104,LURAIS,36220,LURAIS,,46.6878513491,0.937216531821,104,,Lurais,Lurais,36,Indre,24,Centre-Val de Loire
36105,LUREUIL,36220,LUREUIL,,46.7464715422,1.03810161496,105,,Lureuil,Lureuil,36,Indre,24,Centre-Val de Loire
36106,LUZERET,36800,LUZERET,,46.5476228385,1.38528688826,106,,Luzeret,Luzeret,36,Indre,24,Centre-Val de Loire
36107,LYE,36600,LYE,,47.2209145383,1.47358421341,107,,Lye,Lye,36,Indre,24,Centre-Val de Loire
36108,LYS ST GEORGES,36230,LYS ST GEORGES,,46.6459003254,1.81677559242,108,,Lys-Saint-Georges,Lys-Saint-Georges,36,Indre,24,Centre-Val de Loire
36109,LE MAGNY,36400,LE MAGNY,,46.5566903776,1.95895779843,109,Le,Magny,Le Magny,36,Indre,24,Centre-Val de Loire
36110,MAILLET,36340,MAILLET,,46.5804941943,1.67770625299,110,,Maillet,Maillet,36,Indre,24,Centre-Val de Loire
36111,MALICORNAY,36340,MALICORNAY,,46.5692681843,1.64580646533,111,,Malicornay,Malicornay,36,Indre,24,Centre-Val de Loire
36112,MARON,36120,MARON,,46.8006352308,1.85819593148,112,,Mâron,Mâron,36,Indre,24,Centre-Val de Loire
36113,MARTIZAY,36220,MARTIZAY,,46.7999203381,1.03953209001,113,,Martizay,Martizay,36,Indre,24,Centre-Val de Loire
36114,MAUVIERES,36370,MAUVIERES,,46.5783021107,1.10698917227,114,,Mauvières,Mauvières,36,Indre,24,Centre-Val de Loire
36115,MENETOU SUR NAHON,36210,MENETOU SUR NAHON,,47.2239556606,1.63663736153,115,,Menetou-sur-Nahon,Menetou-sur-Nahon,36,Indre,24,Centre-Val de Loire
36116,MENETREOLS SOUS VATAN,36150,MENETREOLS SOUS VATAN,,47.0086528841,1.83457489496,116,,Ménétréols-sous-Vatan,Ménétréols-sous-Vatan,36,Indre,24,Centre-Val de Loire
36117,LE MENOUX,36200,LE MENOUX,,46.5579151463,1.57409340054,117,Le,Menoux,Le Menoux,36,Indre,24,Centre-Val de Loire
36118,MEOBECQ,36500,MEOBECQ,,46.741999261,1.41358055071,118,,Méobecq,Méobecq,36,Indre,24,Centre-Val de Loire
36119,MERIGNY,36220,MERIGNY,,46.6307715976,0.932899384485,119,,Mérigny,Mérigny,36,Indre,24,Centre-Val de Loire
36120,MERS SUR INDRE,36230,MERS SUR INDRE,,46.6693679113,1.87334410692,120,,Mers-sur-Indre,Mers-sur-Indre,36,Indre,24,Centre-Val de Loire
36121,MEUNET PLANCHES,36100,MEUNET PLANCHES,,46.84026848,1.97384087757,121,,Meunet-Planches,Meunet-Planches,36,Indre,24,Centre-Val de Loire
36122,MEUNET SUR VATAN,36150,MEUNET SUR VATAN,,47.0914736081,1.8594546964,122,,Meunet-sur-Vatan,Meunet-sur-Vatan,36,Indre,24,Centre-Val de Loire
36123,MEZIERES EN BRENNE,36290,MEZIERES EN BRENNE,,46.8120642094,1.25669191407,123,,Mézières-en-Brenne,Mézières-en-Brenne,36,Indre,24,Centre-Val de Loire
36124,MIGNE,36800,MIGNE,,46.7122971226,1.30178557971,124,,Migné,Migné,36,Indre,24,Centre-Val de Loire
36125,MIGNY,36260,MIGNY,,47.0162486536,2.05279742123,125,,Migny,Migny,36,Indre,24,Centre-Val de Loire
36126,MONTCHEVRIER,36140,MONTCHEVRIER,,46.4789542772,1.75403274632,126,,Montchevrier,Montchevrier,36,Indre,24,Centre-Val de Loire
36127,MONTGIVRAY,36400,MONTGIVRAY,,46.5962273498,1.96629767733,127,,Montgivray,Montgivray,36,Indre,24,Centre-Val de Loire
36128,MONTIERCHAUME,36130,MONTIERCHAUME,,46.8664822009,1.77844723224,128,,Montierchaume,Montierchaume,36,Indre,24,Centre-Val de Loire
36129,MONTIPOURET,36230,MONTIPOURET,,46.6568299962,1.91357333323,129,,Montipouret,Montipouret,36,Indre,24,Centre-Val de Loire
36130,MONTLEVICQ,36400,MONTLEVICQ,,46.5799416307,2.08420798792,130,,Montlevicq,Montlevicq,36,Indre,24,Centre-Val de Loire
36131,MOSNAY,36200,MOSNAY,,46.6262386861,1.62506020727,131,,Mosnay,Mosnay,36,Indre,24,Centre-Val de Loire
36132,LA MOTTE FEUILLY,36160,LA MOTTE FEUILLY,,46.5478040001,2.07378782307,132,La,Motte-Feuilly,La Motte-Feuilly,36,Indre,24,Centre-Val de Loire
36133,MOUHERS,36340,MOUHERS,,46.5633034226,1.77994822174,133,,Mouhers,Mouhers,36,Indre,24,Centre-Val de Loire
36134,MOUHET,36170,MOUHET,,46.3892514963,1.44265053138,134,,Mouhet,Mouhet,36,Indre,24,Centre-Val de Loire
36135,MOULINS SUR CEPHONS,36110,MOULINS SUR CEPHONS,,47.0089111933,1.56079458695,135,,Moulins-sur-Céphons,Moulins-sur-Céphons,36,Indre,24,Centre-Val de Loire
36136,MURS,36700,MURS,,46.925404896,1.16199898894,136,,Murs,Murs,36,Indre,24,Centre-Val de Loire
36137,NEONS SUR CREUSE,36220,NEONS SUR CREUSE,,46.734899055,0.917095458404,137,,Néons-sur-Creuse,Néons-sur-Creuse,36,Indre,24,Centre-Val de Loire
36138,NERET,36400,NERET,,46.5724464938,2.14124450374,138,,Néret,Néret,36,Indre,24,Centre-Val de Loire
36139,NEUILLAY LES BOIS,36500,NEUILLAY LES BOIS,,46.7623476722,1.4785357393,139,,Neuillay-les-Bois,Neuillay-les-Bois,36,Indre,24,Centre-Val de Loire
36140,NEUVY PAILLOUX,36100,NEUVY PAILLOUX,,46.9035481163,1.85857404368,140,,Neuvy-Pailloux,Neuvy-Pailloux,36,Indre,24,Centre-Val de Loire
36141,NEUVY ST SEPULCHRE,36230,NEUVY ST SEPULCHRE,,46.5924338706,1.81276111894,141,,Neuvy-Saint-Sépulchre,Neuvy-Saint-Sépulchre,36,Indre,24,Centre-Val de Loire
36142,NIHERNE,36250,NIHERNE,,46.8018622942,1.55801304217,142,,Niherne,Niherne,36,Indre,24,Centre-Val de Loire
36143,NOHANT VIC,36400,NOHANT VIC,,46.6401357121,1.95677952761,143,,Nohant-Vic,Nohant-Vic,36,Indre,24,Centre-Val de Loire
36144,NURET LE FERRON,36800,NURET LE FERRON,,46.6891633663,1.42831929228,144,,Nuret-le-Ferron,Nuret-le-Ferron,36,Indre,24,Centre-Val de Loire
36145,OBTERRE,36290,OBTERRE,,46.9072520176,1.06310600327,145,,Obterre,Obterre,36,Indre,24,Centre-Val de Loire
36146,ORSENNES,36190,ORSENNES,,46.4793175378,1.69542411224,146,,Orsennes,Orsennes,36,Indre,24,Centre-Val de Loire
36147,ORVILLE,36210,ORVILLE,,47.1433950802,1.77650851914,147,,Orville,Orville,36,Indre,24,Centre-Val de Loire
36148,OULCHES,36800,OULCHES,,46.6025646156,1.31003931373,148,,Oulches,Oulches,36,Indre,24,Centre-Val de Loire
36149,PALLUAU SUR INDRE,36500,PALLUAU SUR INDRE,,46.9503340872,1.30699854717,149,,Palluau-sur-Indre,Palluau-sur-Indre,36,Indre,24,Centre-Val de Loire
36150,PARNAC,36170,PARNAC,,46.4410252486,1.46522668172,150,,Parnac,Parnac,36,Indre,24,Centre-Val de Loire
36152,PAUDY,36260,PAUDY,,47.0346681884,1.92029512181,152,,Paudy,Paudy,36,Indre,24,Centre-Val de Loire
36153,PAULNAY,36290,PAULNAY,,46.8615135571,1.14964344922,153,,Paulnay,Paulnay,36,Indre,24,Centre-Val de Loire
36154,LE PECHEREAU,36200,LE PECHEREAU,,46.5845928645,1.57047280121,154,Le,Pêchereau,Le Pêchereau,36,Indre,24,Centre-Val de Loire
36155,PELLEVOISIN,36180,PELLEVOISIN,,46.9860152232,1.42163571271,155,,Pellevoisin,Pellevoisin,36,Indre,24,Centre-Val de Loire
36156,PERASSAY,36160,PERASSAY,,46.4754394815,2.14469333686,156,,Pérassay,Pérassay,36,Indre,24,Centre-Val de Loire
36157,LA PEROUILLE,36350,LA PEROUILLE,,46.7096405826,1.51526051031,157,La,Pérouille,La Pérouille,36,Indre,24,Centre-Val de Loire
36158,BADECON LE PIN,36200,BADECON LE PIN,,46.532036139,1.60190321862,158,,Badecon-le-Pin,Badecon-le-Pin,36,Indre,24,Centre-Val de Loire
36159,LE POINCONNET,36330,LE POINCONNET,,46.7565614091,1.72996377685,159,Le,Poinçonnet,Le Poinçonnet,36,Indre,24,Centre-Val de Loire
36160,POMMIERS,36190,POMMIERS,,46.521846173,1.65349444143,160,,Pommiers,Pommiers,36,Indre,24,Centre-Val de Loire
36161,LE PONT CHRETIEN CHABENET,36800,LE PONT CHRETIEN CHABENET,,46.6284901287,1.50039089772,161,Le,Pont-Chrétien-Chabenet,Le Pont-Chrétien-Chabenet,36,Indre,24,Centre-Val de Loire
36162,POULAINES,36210,POULAINES,,47.1498063168,1.64502006889,162,,Poulaines,Poulaines,36,Indre,24,Centre-Val de Loire
36163,POULIGNY NOTRE DAME,36160,POULIGNY NOTRE DAME,,46.4730747606,2.00655850845,163,,Pouligny-Notre-Dame,Pouligny-Notre-Dame,36,Indre,24,Centre-Val de Loire
36164,POULIGNY ST MARTIN,36160,POULIGNY ST MARTIN,,46.5149038846,2.00555545984,164,,Pouligny-Saint-Martin,Pouligny-Saint-Martin,36,Indre,24,Centre-Val de Loire
36165,POULIGNY ST PIERRE,36300,POULIGNY ST PIERRE,,46.684081947,1.04652871345,165,,Pouligny-Saint-Pierre,Pouligny-Saint-Pierre,36,Indre,24,Centre-Val de Loire
36166,PREAUX,36240,PREAUX,,47.0305164797,1.30603010451,166,,Préaux,Préaux,36,Indre,24,Centre-Val de Loire
36167,PREUILLY LA VILLE,36220,PREUILLY LA VILLE,,46.6980523069,0.980437469492,167,,Preuilly-la-Ville,Preuilly-la-Ville,36,Indre,24,Centre-Val de Loire
36168,PRISSAC,36370,PRISSAC,,46.524638893,1.29857907076,168,,Prissac,Prissac,36,Indre,24,Centre-Val de Loire
36169,PRUNIERS,36120,PRUNIERS,,46.7812910587,2.05837343794,169,,Pruniers,Pruniers,36,Indre,24,Centre-Val de Loire
36170,REBOURSIN,36150,REBOURSIN,,47.1082025361,1.81207985903,170,,Reboursin,Reboursin,36,Indre,24,Centre-Val de Loire
36171,REUILLY,36260,REUILLY,,47.080059286,2.01080444481,171,,Reuilly,Reuilly,36,Indre,24,Centre-Val de Loire
36172,RIVARENNES,36800,RIVARENNES,,46.6136609061,1.37923363708,172,,Rivarennes,Rivarennes,36,Indre,24,Centre-Val de Loire
36173,ROSNAY,36300,ROSNAY,,46.7081092632,1.20629298649,173,,Rosnay,Rosnay,36,Indre,24,Centre-Val de Loire
36174,ROUSSINES,36170,ROUSSINES,,46.4618536752,1.37726376805,174,,Roussines,Roussines,36,Indre,24,Centre-Val de Loire
36175,ROUVRES LES BOIS,36110,ROUVRES LES BOIS,,47.0727033689,1.65279278952,175,,Rouvres-les-Bois,Rouvres-les-Bois,36,Indre,24,Centre-Val de Loire
36176,RUFFEC,36300,RUFFEC,,46.6238195664,1.1836718516,176,,Ruffec,Ruffec,36,Indre,24,Centre-Val de Loire
36177,SACIERGES ST MARTIN,36170,SACIERGES ST MARTIN,,46.5020933544,1.3636862665,177,,Sacierges-Saint-Martin,Sacierges-Saint-Martin,36,Indre,24,Centre-Val de Loire
36178,ST AIGNY,36300,ST AIGNY,,46.6332010189,1.00576918514,178,,Saint-Aigny,Saint-Aigny,36,Indre,24,Centre-Val de Loire
36179,ST AOUSTRILLE,36100,ST AOUSTRILLE,,46.9325345658,1.92998752621,179,,Saint-Aoustrille,Saint-Aoustrille,36,Indre,24,Centre-Val de Loire
36180,ST AOUT,36120,ST AOUT,,46.7258764894,1.96323578512,180,,Saint-Août,Saint-Août,36,Indre,24,Centre-Val de Loire
36181,ST AUBIN,36100,ST AUBIN,,46.861488808,2.03208809042,181,,Saint-Aubin,Saint-Aubin,36,Indre,24,Centre-Val de Loire
36182,ST BENOIT DU SAULT,36170,ST BENOIT DU SAULT,,46.4476641903,1.39936861789,182,,Saint-Benoît-du-Sault,Saint-Benoît-du-Sault,36,Indre,24,Centre-Val de Loire
36184,ST CHARTIER,36400,ST CHARTIER,,46.6758816931,1.98233064219,184,,Saint-Chartier,Saint-Chartier,36,Indre,24,Centre-Val de Loire
36185,ST CHRISTOPHE EN BAZELLE,36210,ST CHRISTOPHE EN BAZELLE,,47.1875900989,1.71219457331,185,,Saint-Christophe-en-Bazelle,Saint-Christophe-en-Bazelle,36,Indre,24,Centre-Val de Loire
36186,ST CHRISTOPHE EN BOUCHERIE,36400,ST CHRISTOPHE EN BOUCHERIE,,46.6796859188,2.11586073047,186,,Saint-Christophe-en-Boucherie,Saint-Christophe-en-Boucherie,36,Indre,24,Centre-Val de Loire
36187,ST CIVRAN,36170,ST CIVRAN,,46.4938472391,1.40425431776,187,,Saint-Civran,Saint-Civran,36,Indre,24,Centre-Val de Loire
36188,ST CYRAN DU JAMBOT,36700,ST CYRAN DU JAMBOT,,47.0224840365,1.15675534886,188,,Saint-Cyran-du-Jambot,Saint-Cyran-du-Jambot,36,Indre,24,Centre-Val de Loire
36189,ST DENIS DE JOUHET,36230,ST DENIS DE JOUHET,,46.5265897441,1.84504913909,189,,Saint-Denis-de-Jouhet,Saint-Denis-de-Jouhet,36,Indre,24,Centre-Val de Loire
36190,STE FAUSTE,36100,STE FAUSTE,,46.8545598385,1.87113964602,190,,Sainte-Fauste,Sainte-Fauste,36,Indre,24,Centre-Val de Loire
36191,ST FLORENTIN,36150,ST FLORENTIN,,47.0986279649,1.77918217074,191,,Saint-Florentin,Saint-Florentin,36,Indre,24,Centre-Val de Loire
36192,ST GAULTIER,36800,ST GAULTIER,,46.6480131941,1.43032381865,192,,Saint-Gaultier,Saint-Gaultier,36,Indre,24,Centre-Val de Loire
36193,STE GEMME,36500,STE GEMME,,46.8595428398,1.33333500724,193,,Sainte-Gemme,Sainte-Gemme,36,Indre,24,Centre-Val de Loire
36194,ST GENOU,36500,ST GENOU,,46.911791678,1.3504650552,194,,Saint-Genou,Saint-Genou,36,Indre,24,Centre-Val de Loire
36195,ST GEORGES SUR ARNON,36100,ST GEORGES SUR ARNON,,46.9864324213,2.06490343076,195,,Saint-Georges-sur-Arnon,Saint-Georges-sur-Arnon,36,Indre,24,Centre-Val de Loire
36196,ST GILLES,36170,ST GILLES,,46.4792987995,1.44895217006,196,,Saint-Gilles,Saint-Gilles,36,Indre,24,Centre-Val de Loire
36197,ST HILAIRE SUR BENAIZE,36370,ST HILAIRE SUR BENAIZE,,46.5569306116,1.06674152425,197,,Saint-Hilaire-sur-Benaize,Saint-Hilaire-sur-Benaize,36,Indre,24,Centre-Val de Loire
36198,ST LACTENCIN,36500,ST LACTENCIN,,46.8956769207,1.49717761772,198,,Saint-Lactencin,Saint-Lactencin,36,Indre,24,Centre-Val de Loire
36199,STE LIZAIGNE,36260,STE LIZAIGNE,,47.0110071957,1.99544056226,199,,Sainte-Lizaigne,Sainte-Lizaigne,36,Indre,24,Centre-Val de Loire
36200,ST MARCEL,36200,ST MARCEL,,46.6083971335,1.52347471994,200,,Saint-Marcel,Saint-Marcel,36,Indre,24,Centre-Val de Loire
36202,ST MAUR,36250,ST MAUR,,46.7890918907,1.62343965566,202,,Saint-Maur,Saint-Maur,36,Indre,24,Centre-Val de Loire
36202,ST MAUR,36250,ST MAUR,VILLERS LES ORMES,46.7890918907,1.62343965566,202,,Saint-Maur,Saint-Maur,36,Indre,24,Centre-Val de Loire
36203,ST MEDARD,36700,ST MEDARD,,47.0052566616,1.26515419638,203,,Saint-Médard,Saint-Médard,36,Indre,24,Centre-Val de Loire
36204,ST MICHEL EN BRENNE,36290,ST MICHEL EN BRENNE,,46.7879524316,1.1742571119,204,,Saint-Michel-en-Brenne,Saint-Michel-en-Brenne,36,Indre,24,Centre-Val de Loire
36205,ST PIERRE DE JARDS,36260,ST PIERRE DE JARDS,,47.1019578321,1.96965662578,205,,Saint-Pierre-de-Jards,Saint-Pierre-de-Jards,36,Indre,24,Centre-Val de Loire
36206,ST PIERRE DE LAMPS,36110,ST PIERRE DE LAMPS,,46.9680272917,1.51403453465,206,,Saint-Pierre-de-Lamps,Saint-Pierre-de-Lamps,36,Indre,24,Centre-Val de Loire
36207,ST PLANTAIRE,36190,ST PLANTAIRE,,46.432132753,1.64824478911,207,,Saint-Plantaire,Saint-Plantaire,36,Indre,24,Centre-Val de Loire
36208,STE SEVERE SUR INDRE,36160,STE SEVERE SUR INDRE,,46.5042219323,2.06769602901,208,,Sainte-Sévère-sur-Indre,Sainte-Sévère-sur-Indre,36,Indre,24,Centre-Val de Loire
36209,ST VALENTIN,36100,ST VALENTIN,,46.9621074542,1.85100713344,209,,Saint-Valentin,Saint-Valentin,36,Indre,24,Centre-Val de Loire
36210,SARZAY,36230,SARZAY,,46.5974798538,1.90704131796,210,,Sarzay,Sarzay,36,Indre,24,Centre-Val de Loire
36211,SASSIERGES ST GERMAIN,36120,SASSIERGES ST GERMAIN,,46.7550023432,1.89054741912,211,,Sassierges-Saint-Germain,Sassierges-Saint-Germain,36,Indre,24,Centre-Val de Loire
36212,SAULNAY,36290,SAULNAY,,46.8684293874,1.25671383741,212,,Saulnay,Saulnay,36,Indre,24,Centre-Val de Loire
36213,SAUZELLES,36220,SAUZELLES,,46.6542476574,0.985473920463,213,,Sauzelles,Sauzelles,36,Indre,24,Centre-Val de Loire
36214,SAZERAY,36160,SAZERAY,,46.4467096197,2.04531579946,214,,Sazeray,Sazeray,36,Indre,24,Centre-Val de Loire
36215,SEGRY,36100,SEGRY,,46.8846862374,2.09589433359,215,,Ségry,Ségry,36,Indre,24,Centre-Val de Loire
36216,SELLES SUR NAHON,36180,SELLES SUR NAHON,,47.0177325936,1.45548749764,216,,Selles-sur-Nahon,Selles-sur-Nahon,36,Indre,24,Centre-Val de Loire
36217,SEMBLECAY,36210,SEMBLECAY,,47.2185048101,1.69379686982,217,,Sembleçay,Sembleçay,36,Indre,24,Centre-Val de Loire
36218,SOUGE,36500,SOUGE,,46.9659142337,1.48046775044,218,,Sougé,Sougé,36,Indre,24,Centre-Val de Loire
36219,TENDU,36200,TENDU,,46.6521648275,1.56566205857,219,,Tendu,Tendu,36,Indre,24,Centre-Val de Loire
36220,THENAY,36800,THENAY,,46.5948540817,1.42889771052,220,,Thenay,Thenay,36,Indre,24,Centre-Val de Loire
36221,THEVET ST JULIEN,36400,THEVET ST JULIEN,,46.6344948494,2.06994108021,221,,Thevet-Saint-Julien,Thevet-Saint-Julien,36,Indre,24,Centre-Val de Loire
36222,THIZAY,36100,THIZAY,,46.8989940984,1.92934750416,222,,Thizay,Thizay,36,Indre,24,Centre-Val de Loire
36223,TILLY,36310,TILLY,,46.4050751872,1.21140507428,223,,Tilly,Tilly,36,Indre,24,Centre-Val de Loire
36224,TOURNON ST MARTIN,36220,TOURNON ST MARTIN,,46.7300364978,0.983181764115,224,,Tournon-Saint-Martin,Tournon-Saint-Martin,36,Indre,24,Centre-Val de Loire
36225,LE TRANGER,36700,LE TRANGER,,46.9730217907,1.24675037798,225,Le,Tranger,Le Tranger,36,Indre,24,Centre-Val de Loire
36226,TRANZAULT,36230,TRANZAULT,,46.622046927,1.85411308963,226,,Tranzault,Tranzault,36,Indre,24,Centre-Val de Loire
36227,URCIERS,36160,URCIERS,,46.528816556,2.14569047722,227,,Urciers,Urciers,36,Indre,24,Centre-Val de Loire
36228,VALENCAY,36600,VALENCAY,,47.1558433483,1.55858540806,228,,Valençay,Valençay,36,Indre,24,Centre-Val de Loire
36229,VAL FOUZON,36210,VAL FOUZON,PARPECAY,47.2049167423,1.59155940646,229,,Val-Fouzon,Val-Fouzon,36,Indre,24,Centre-Val de Loire
36229,VAL FOUZON,36210,VAL FOUZON,STE CECILE,47.2049167423,1.59155940646,229,,Val-Fouzon,Val-Fouzon,36,Indre,24,Centre-Val de Loire
36229,VAL FOUZON,36210,VAL FOUZON,VARENNES SUR FOUZON,47.2049167423,1.59155940646,229,,Val-Fouzon,Val-Fouzon,36,Indre,24,Centre-Val de Loire
36230,VATAN,36150,VATAN,,47.0538873718,1.82531478025,230,,Vatan,Vatan,36,Indre,24,Centre-Val de Loire
36231,VELLES,36330,VELLES,,46.69976205,1.63686768845,231,,Velles,Velles,36,Indre,24,Centre-Val de Loire
36232,VENDOEUVRES,36500,VENDOEUVRES,,46.7879906602,1.3602788548,232,,Vendœuvres,Vendœuvres,36,Indre,24,Centre-Val de Loire
36233,LA VERNELLE,36600,LA VERNELLE,,47.242881421,1.56367685749,233,La,Vernelle,La Vernelle,36,Indre,24,Centre-Val de Loire
36234,VERNEUIL SUR IGNERAIE,36400,VERNEUIL SUR IGNERAIE,,46.6542492124,2.01447840786,234,,Verneuil-sur-Igneraie,Verneuil-sur-Igneraie,36,Indre,24,Centre-Val de Loire
36235,VEUIL,36600,VEUIL,,47.1325742708,1.50980110531,235,,Veuil,Veuil,36,Indre,24,Centre-Val de Loire
36236,VICQ EXEMPLET,36400,VICQ EXEMPLET,,46.6210252622,2.14341177341,236,,Vicq-Exemplet,Vicq-Exemplet,36,Indre,24,Centre-Val de Loire
36237,VICQ SUR NAHON,36600,VICQ SUR NAHON,,47.1002749222,1.55803357778,237,,Vicq-sur-Nahon,Vicq-sur-Nahon,36,Indre,24,Centre-Val de Loire
36238,VIGOULANT,36160,VIGOULANT,,46.4389739269,2.08397250932,238,,Vigoulant,Vigoulant,36,Indre,24,Centre-Val de Loire
36239,VIGOUX,36170,VIGOUX,,46.517396736,1.47441263851,239,,Vigoux,Vigoux,36,Indre,24,Centre-Val de Loire
36240,VIJON,36160,VIJON,,46.4364401153,2.12180186479,240,,Vijon,Vijon,36,Indre,24,Centre-Val de Loire
36241,VILLEDIEU SUR INDRE,36320,VILLEDIEU SUR INDRE,,46.8446125894,1.51599368144,241,,Villedieu-sur-Indre,Villedieu-sur-Indre,36,Indre,24,Centre-Val de Loire
36242,VILLEGONGIS,36110,VILLEGONGIS,,46.9241338236,1.58960758667,242,,Villegongis,Villegongis,36,Indre,24,Centre-Val de Loire
36243,VILLEGOUIN,36500,VILLEGOUIN,,46.9735557689,1.35589306474,243,,Villegouin,Villegouin,36,Indre,24,Centre-Val de Loire
36244,VILLENTROIS,36600,VILLENTROIS,,47.1877948714,1.45972969397,244,,Villentrois,Villentrois,36,Indre,24,Centre-Val de Loire
36246,VILLIERS,36290,VILLIERS,,46.8845013105,1.19883846025,246,,Villiers,Villiers,36,Indre,24,Centre-Val de Loire
36247,VINEUIL,36110,VINEUIL,,46.9074666618,1.65377312715,247,,Vineuil,Vineuil,36,Indre,24,Centre-Val de Loire
36248,VOUILLON,36100,VOUILLON,,46.8199826355,1.9132066864,248,,Vouillon,Vouillon,36,Indre,24,Centre-Val de Loire
37001,ABILLY,37160,ABILLY,,46.9411244235,0.734787296451,1,,Abilly,Abilly,37,Indre-et-Loire,24,Centre-Val de Loire
37002,AMBILLOU,37340,AMBILLOU,,47.4520562833,0.446595189261,2,,Ambillou,Ambillou,37,Indre-et-Loire,24,Centre-Val de Loire
37003,AMBOISE,37400,AMBOISE,,47.3917237652,1.00023994675,3,,Amboise,Amboise,37,Indre-et-Loire,24,Centre-Val de Loire
37004,ANCHE,37500,ANCHE,,47.1322445676,0.307767797978,4,,Anché,Anché,37,Indre-et-Loire,24,Centre-Val de Loire
37005,ANTOGNY LE TILLAC,37800,ANTOGNY LE TILLAC,,46.9684491062,0.55478841999,5,,Antogny-le-Tillac,Antogny-le-Tillac,37,Indre-et-Loire,24,Centre-Val de Loire
37006,ARTANNES SUR INDRE,37260,ARTANNES SUR INDRE,,47.2760783229,0.594691444381,6,,Artannes-sur-Indre,Artannes-sur-Indre,37,Indre-et-Loire,24,Centre-Val de Loire
37007,ASSAY,37120,ASSAY,,47.0676563174,0.276362462975,7,,Assay,Assay,37,Indre-et-Loire,24,Centre-Val de Loire
37008,ATHEE SUR CHER,37270,ATHEE SUR CHER,,47.3147073086,0.902278800699,8,,Athée-sur-Cher,Athée-sur-Cher,37,Indre-et-Loire,24,Centre-Val de Loire
37009,AUTRECHE,37110,AUTRECHE,,47.5225157675,1.00231924384,9,,Autrèche,Autrèche,37,Indre-et-Loire,24,Centre-Val de Loire
37010,AUZOUER EN TOURAINE,37110,AUZOUER EN TOURAINE,,47.55445629,0.93712101113,10,,Auzouer-en-Touraine,Auzouer-en-Touraine,37,Indre-et-Loire,24,Centre-Val de Loire
37011,AVOINE,37420,AVOINE,,47.2237767737,0.18754463005,11,,Avoine,Avoine,37,Indre-et-Loire,24,Centre-Val de Loire
37012,AVON LES ROCHES,37220,AVON LES ROCHES,,47.1830390826,0.459616405273,12,,Avon-les-Roches,Avon-les-Roches,37,Indre-et-Loire,24,Centre-Val de Loire
37013,AVRILLE LES PONCEAUX,37340,AVRILLE LES PONCEAUX,,47.388268784,0.302971878104,13,,Avrillé-les-Ponceaux,Avrillé-les-Ponceaux,37,Indre-et-Loire,24,Centre-Val de Loire
37014,AZAY LE RIDEAU,37190,AZAY LE RIDEAU,,47.2729677305,0.469464530446,14,,Azay-le-Rideau,Azay-le-Rideau,37,Indre-et-Loire,24,Centre-Val de Loire
37015,AZAY SUR CHER,37270,AZAY SUR CHER,,47.3343646706,0.844886964045,15,,Azay-sur-Cher,Azay-sur-Cher,37,Indre-et-Loire,24,Centre-Val de Loire
37016,AZAY SUR INDRE,37310,AZAY SUR INDRE,,47.2038896853,0.943284326846,16,,Azay-sur-Indre,Azay-sur-Indre,37,Indre-et-Loire,24,Centre-Val de Loire
37018,BALLAN MIRE,37510,BALLAN MIRE,,47.3367884418,0.601376208725,18,,Ballan-Miré,Ballan-Miré,37,Indre-et-Loire,24,Centre-Val de Loire
37019,BARROU,37350,BARROU,,46.8814967134,0.77481154577,19,,Barrou,Barrou,37,Indre-et-Loire,24,Centre-Val de Loire
37020,BEAULIEU LES LOCHES,37600,BEAULIEU LES LOCHES,,47.1281892877,1.02294110179,20,,Beaulieu-lès-Loches,Beaulieu-lès-Loches,37,Indre-et-Loire,24,Centre-Val de Loire
37021,BEAUMONT LA RONCE,37360,BEAUMONT LA RONCE,,47.568810886,0.67274558764,21,,Beaumont-Louestault,Beaumont-Louestault,37,Indre-et-Loire,24,Centre-Val de Loire
37022,BEAUMONT EN VERON,37420,BEAUMONT EN VERON,,47.1939476684,0.186243013287,22,,Beaumont-en-Véron,Beaumont-en-Véron,37,Indre-et-Loire,24,Centre-Val de Loire
37023,BEAUMONT VILLAGE,37460,BEAUMONT VILLAGE,,47.1798992552,1.20434805914,23,,Beaumont-Village,Beaumont-Village,37,Indre-et-Loire,24,Centre-Val de Loire
37024,BENAIS,37140,BENAIS,,47.3156279814,0.217451239444,24,,Benais,Benais,37,Indre-et-Loire,24,Centre-Val de Loire
37025,BERTHENAY,37510,BERTHENAY,,47.3604120561,0.525356323149,25,,Berthenay,Berthenay,37,Indre-et-Loire,24,Centre-Val de Loire
37026,BETZ LE CHATEAU,37600,BETZ LE CHATEAU,,47.000465147,0.95244957404,26,,Betz-le-Château,Betz-le-Château,37,Indre-et-Loire,24,Centre-Val de Loire
37027,BLERE,37150,BLERE,,47.3074648411,0.987107533288,27,,Bléré,Bléré,37,Indre-et-Loire,24,Centre-Val de Loire
37028,BOSSAY SUR CLAISE,37290,BOSSAY SUR CLAISE,,46.8247426459,0.975476397199,28,,Bossay-sur-Claise,Bossay-sur-Claise,37,Indre-et-Loire,24,Centre-Val de Loire
37029,BOSSEE,37240,BOSSEE,,47.1125871267,0.733241640031,29,,Bossée,Bossée,37,Indre-et-Loire,24,Centre-Val de Loire
37030,LE BOULAY,37110,LE BOULAY,,47.5947862133,0.844355830752,30,Le,Boulay,Le Boulay,37,Indre-et-Loire,24,Centre-Val de Loire
37031,BOURGUEIL,37140,BOURGUEIL,,47.3103261914,0.17424139464,31,,Bourgueil,Bourgueil,37,Indre-et-Loire,24,Centre-Val de Loire
37032,BOURNAN,37240,BOURNAN,,47.0687895406,0.7310286673,32,,Bournan,Bournan,37,Indre-et-Loire,24,Centre-Val de Loire
37033,BOUSSAY,37290,BOUSSAY,,46.8484852345,0.892362482013,33,,Boussay,Boussay,37,Indre-et-Loire,24,Centre-Val de Loire
37034,BRASLOU,37120,BRASLOU,,46.9991365379,0.407502661528,34,,Braslou,Braslou,37,Indre-et-Loire,24,Centre-Val de Loire
37035,BRAYE SOUS FAYE,37120,BRAYE SOUS FAYE,,46.9823047601,0.328685005492,35,,Braye-sous-Faye,Braye-sous-Faye,37,Indre-et-Loire,24,Centre-Val de Loire
37036,BRAYE SUR MAULNE,37330,BRAYE SUR MAULNE,,47.5595510879,0.260858809315,36,,Braye-sur-Maulne,Braye-sur-Maulne,37,Indre-et-Loire,24,Centre-Val de Loire
37037,BRECHES,37330,BRECHES,,47.560326962,0.407664041185,37,,Brèches,Brèches,37,Indre-et-Loire,24,Centre-Val de Loire
37038,BREHEMONT,37130,BREHEMONT,,47.2893596195,0.361536652473,38,,Bréhémont,Bréhémont,37,Indre-et-Loire,24,Centre-Val de Loire
37039,BRIDORE,37600,BRIDORE,,47.0367101954,1.079173286,39,,Bridoré,Bridoré,37,Indre-et-Loire,24,Centre-Val de Loire
37040,BRIZAY,37220,BRIZAY,,47.0963197931,0.38766475605,40,,Brizay,Brizay,37,Indre-et-Loire,24,Centre-Val de Loire
37041,BUEIL EN TOURAINE,37370,BUEIL EN TOURAINE,,47.63053702,0.552522372258,41,,Bueil-en-Touraine,Bueil-en-Touraine,37,Indre-et-Loire,24,Centre-Val de Loire
37042,CANDES ST MARTIN,37500,CANDES ST MARTIN,,47.2026345011,0.0740845875144,42,,Candes-Saint-Martin,Candes-Saint-Martin,37,Indre-et-Loire,24,Centre-Val de Loire
37043,CANGEY,37530,CANGEY,,47.4887286786,1.06027361973,43,,Cangey,Cangey,37,Indre-et-Loire,24,Centre-Val de Loire
37044,LA CELLE GUENAND,37350,LA CELLE GUENAND,,46.9489706333,0.899411623178,44,La,Celle-Guenand,La Celle-Guenand,37,Indre-et-Loire,24,Centre-Val de Loire
37045,LA CELLE ST AVANT,37160,LA CELLE ST AVANT,,47.0207240474,0.615608165766,45,La,Celle-Saint-Avant,La Celle-Saint-Avant,37,Indre-et-Loire,24,Centre-Val de Loire
37046,CERE LA RONDE,37460,CERE LA RONDE,,47.2539221979,1.1815724802,46,,Céré-la-Ronde,Céré-la-Ronde,37,Indre-et-Loire,24,Centre-Val de Loire
37047,CERELLES,37390,CERELLES,,47.5032297847,0.684878284494,47,,Cerelles,Cerelles,37,Indre-et-Loire,24,Centre-Val de Loire
37048,CHAMBON,37290,CHAMBON,,46.838530543,0.826201928032,48,,Chambon,Chambon,37,Indre-et-Loire,24,Centre-Val de Loire
37049,CHAMBOURG SUR INDRE,37310,CHAMBOURG SUR INDRE,,47.1721202579,0.968767881944,49,,Chambourg-sur-Indre,Chambourg-sur-Indre,37,Indre-et-Loire,24,Centre-Val de Loire
37050,CHAMBRAY LES TOURS,37170,CHAMBRAY LES TOURS,,47.3312652065,0.716803612336,50,,Chambray-lès-Tours,Chambray-lès-Tours,37,Indre-et-Loire,24,Centre-Val de Loire
37051,CHAMPIGNY SUR VEUDE,37120,CHAMPIGNY SUR VEUDE,,47.0566092361,0.327929999931,51,,Champigny-sur-Veude,Champigny-sur-Veude,37,Indre-et-Loire,24,Centre-Val de Loire
37052,CHANCAY,37210,CHANCAY,,47.4496671434,0.88057915544,52,,Chançay,Chançay,37,Indre-et-Loire,24,Centre-Val de Loire
37053,CHANCEAUX PRES LOCHES,37600,CHANCEAUX PRES LOCHES,,47.1409474876,0.939622446092,53,,Chanceaux-près-Loches,Chanceaux-près-Loches,37,Indre-et-Loire,24,Centre-Val de Loire
37054,CHANCEAUX SUR CHOISILLE,37390,CHANCEAUX SUR CHOISILLE,,47.4781190153,0.703218955868,54,,Chanceaux-sur-Choisille,Chanceaux-sur-Choisille,37,Indre-et-Loire,24,Centre-Val de Loire
37055,CHANNAY SUR LATHAN,37330,CHANNAY SUR LATHAN,,47.4777083311,0.253067103583,55,,Channay-sur-Lathan,Channay-sur-Lathan,37,Indre-et-Loire,24,Centre-Val de Loire
37056,LA CHAPELLE AUX NAUX,37130,LA CHAPELLE AUX NAUX,,47.3134280853,0.417814420608,56,La,Chapelle-aux-Naux,La Chapelle-aux-Naux,37,Indre-et-Loire,24,Centre-Val de Loire
37057,LA CHAPELLE BLANCHE ST MARTIN,37240,LA CHAPELLE BLANCHE ST MARTIN,,47.0841623177,0.784624904578,57,La,Chapelle-Blanche-Saint-Martin,La Chapelle-Blanche-Saint-Martin,37,Indre-et-Loire,24,Centre-Val de Loire
37058,LA CHAPELLE SUR LOIRE,37140,LA CHAPELLE SUR LOIRE,,47.2509587327,0.215361451048,58,La,Chapelle-sur-Loire,La Chapelle-sur-Loire,37,Indre-et-Loire,24,Centre-Val de Loire
37059,CHARENTILLY,37390,CHARENTILLY,,47.4686972065,0.595174138773,59,,Charentilly,Charentilly,37,Indre-et-Loire,24,Centre-Val de Loire
37060,CHARGE,37530,CHARGE,,47.431662181,1.04123088283,60,,Chargé,Chargé,37,Indre-et-Loire,24,Centre-Val de Loire
37061,CHARNIZAY,37290,CHARNIZAY,,46.9160338109,0.990841161121,61,,Charnizay,Charnizay,37,Indre-et-Loire,24,Centre-Val de Loire
37062,CHATEAU LA VALLIERE,37330,CHATEAU LA VALLIERE,,47.5271484984,0.329691100223,62,,Château-la-Vallière,Château-la-Vallière,37,Indre-et-Loire,24,Centre-Val de Loire
37063,CHATEAU RENAULT,37110,CHATEAU RENAULT,,47.592456846,0.909725910608,63,,Château-Renault,Château-Renault,37,Indre-et-Loire,24,Centre-Val de Loire
37064,CHAUMUSSAY,37350,CHAUMUSSAY,,46.8762355769,0.851825400667,64,,Chaumussay,Chaumussay,37,Indre-et-Loire,24,Centre-Val de Loire
37065,CHAVEIGNES,37120,CHAVEIGNES,,47.0237454107,0.351516641316,65,,Chaveignes,Chaveignes,37,Indre-et-Loire,24,Centre-Val de Loire
37066,CHEDIGNY,37310,CHEDIGNY,,47.2225259343,0.991792696034,66,,Chédigny,Chédigny,37,Indre-et-Loire,24,Centre-Val de Loire
37067,CHEILLE,37190,CHEILLE,,47.2404359595,0.42271429375,67,,Cheillé,Cheillé,37,Indre-et-Loire,24,Centre-Val de Loire
37068,CHEMILLE SUR DEME,37370,CHEMILLE SUR DEME,,47.6592186716,0.668156723378,68,,Chemillé-sur-Dême,Chemillé-sur-Dême,37,Indre-et-Loire,24,Centre-Val de Loire
37069,CHEMILLE SUR INDROIS,37460,CHEMILLE SUR INDROIS,,47.1535504221,1.15871292042,69,,Chemillé-sur-Indrois,Chemillé-sur-Indrois,37,Indre-et-Loire,24,Centre-Val de Loire
37070,CHENONCEAUX,37150,CHENONCEAUX,,47.3396827189,1.07035126268,70,,Chenonceaux,Chenonceaux,37,Indre-et-Loire,24,Centre-Val de Loire
37071,CHEZELLES,37220,CHEZELLES,,47.0666739706,0.439662413548,71,,Chezelles,Chezelles,37,Indre-et-Loire,24,Centre-Val de Loire
37072,CHINON,37500,CHINON,,47.1723001595,0.245084664558,72,,Chinon,Chinon,37,Indre-et-Loire,24,Centre-Val de Loire
37073,CHISSEAUX,37150,CHISSEAUX,,47.3494308209,1.09603138908,73,,Chisseaux,Chisseaux,37,Indre-et-Loire,24,Centre-Val de Loire
37074,CHOUZE SUR LOIRE,37140,CHOUZE SUR LOIRE,,47.2459736126,0.109159987827,74,,Chouzé-sur-Loire,Chouzé-sur-Loire,37,Indre-et-Loire,24,Centre-Val de Loire
37075,CIGOGNE,37310,CIGOGNE,,47.2656141951,0.943216158829,75,,Cigogné,Cigogné,37,Indre-et-Loire,24,Centre-Val de Loire
37076,CINAIS,37500,CINAIS,,47.1577838408,0.176177175254,76,,Cinais,Cinais,37,Indre-et-Loire,24,Centre-Val de Loire
37077,CINQ MARS LA PILE,37130,CINQ MARS LA PILE,,47.3535034952,0.453317897903,77,,Cinq-Mars-la-Pile,Cinq-Mars-la-Pile,37,Indre-et-Loire,24,Centre-Val de Loire
37078,CIRAN,37240,CIRAN,,47.0515428726,0.873159518257,78,,Ciran,Ciran,37,Indre-et-Loire,24,Centre-Val de Loire
37079,CIVRAY DE TOURAINE,37150,CIVRAY DE TOURAINE,,47.3394574162,1.04082548728,79,,Civray-de-Touraine,Civray-de-Touraine,37,Indre-et-Loire,24,Centre-Val de Loire
37080,CIVRAY SUR ESVES,37160,CIVRAY SUR ESVES,,47.0362576539,0.7127037725,80,,Civray-sur-Esves,Civray-sur-Esves,37,Indre-et-Loire,24,Centre-Val de Loire
37081,CLERE LES PINS,37340,CLERE LES PINS,,47.4456343529,0.37899902505,81,,Cléré-les-Pins,Cléré-les-Pins,37,Indre-et-Loire,24,Centre-Val de Loire
37082,CONTINVOIR,37340,CONTINVOIR,,47.3679713688,0.235916489802,82,,Continvoir,Continvoir,37,Indre-et-Loire,24,Centre-Val de Loire
37083,CORMERY,37320,CORMERY,,47.2592432669,0.845873952159,83,,Cormery,Cormery,37,Indre-et-Loire,24,Centre-Val de Loire
37084,COUESMES,37330,COUESMES,,47.5537288182,0.360356955229,84,,Couesmes,Couesmes,37,Indre-et-Loire,24,Centre-Val de Loire
37085,COURCAY,37310,COURCAY,,47.2497992287,0.884516285898,85,,Courçay,Courçay,37,Indre-et-Loire,24,Centre-Val de Loire
37086,COURCELLES DE TOURAINE,37330,COURCELLES DE TOURAINE,,47.4894185578,0.333267121992,86,,Courcelles-de-Touraine,Courcelles-de-Touraine,37,Indre-et-Loire,24,Centre-Val de Loire
37087,COURCOUE,37120,COURCOUE,,47.0300727285,0.399538654652,87,,Courcoué,Courcoué,37,Indre-et-Loire,24,Centre-Val de Loire
37088,COUZIERS,37500,COUZIERS,,47.1679298412,0.0772163392705,88,,Couziers,Couziers,37,Indre-et-Loire,24,Centre-Val de Loire
37089,CRAVANT LES COTEAUX,37500,CRAVANT LES COTEAUX,,47.1685788687,0.341605997967,89,,Cravant-les-Côteaux,Cravant-les-Côteaux,37,Indre-et-Loire,24,Centre-Val de Loire
37090,CRISSAY SUR MANSE,37220,CRISSAY SUR MANSE,,47.1665198004,0.494306101792,90,,Crissay-sur-Manse,Crissay-sur-Manse,37,Indre-et-Loire,24,Centre-Val de Loire
37091,LA CROIX EN TOURAINE,37150,LA CROIX EN TOURAINE,,47.34896993,0.992396977664,91,La,Croix-en-Touraine,La Croix-en-Touraine,37,Indre-et-Loire,24,Centre-Val de Loire
37092,CROTELLES,37380,CROTELLES,,47.5385005841,0.822690179098,92,,Crotelles,Crotelles,37,Indre-et-Loire,24,Centre-Val de Loire
37093,CROUZILLES,37220,CROUZILLES,,47.1310109532,0.469231881313,93,,Crouzilles,Crouzilles,37,Indre-et-Loire,24,Centre-Val de Loire
37094,CUSSAY,37240,CUSSAY,,47.0190077261,0.768935236733,94,,Cussay,Cussay,37,Indre-et-Loire,24,Centre-Val de Loire
37095,DAME MARIE LES BOIS,37110,DAME MARIE LES BOIS,,47.5494780596,1.04682075879,95,,Dame-Marie-les-Bois,Dame-Marie-les-Bois,37,Indre-et-Loire,24,Centre-Val de Loire
37096,DIERRE,37150,DIERRE,,47.3536037001,0.956010471155,96,,Dierre,Dierre,37,Indre-et-Loire,24,Centre-Val de Loire
37097,DOLUS LE SEC,37310,DOLUS LE SEC,,47.1649485562,0.890511286523,97,,Dolus-le-Sec,Dolus-le-Sec,37,Indre-et-Loire,24,Centre-Val de Loire
37098,DRACHE,37800,DRACHE,,47.0667689178,0.63417537675,98,,Draché,Draché,37,Indre-et-Loire,24,Centre-Val de Loire
37099,DRUYE,37190,DRUYE,,47.2972672597,0.53435694,99,,Druye,Druye,37,Indre-et-Loire,24,Centre-Val de Loire
37100,EPEIGNE LES BOIS,37150,EPEIGNE LES BOIS,,47.2765013353,1.12347391282,100,,Épeigné-les-Bois,Épeigné-les-Bois,37,Indre-et-Loire,24,Centre-Val de Loire
37101,EPEIGNE SUR DEME,37370,EPEIGNE SUR DEME,,47.675476557,0.617884266098,101,,Épeigné-sur-Dême,Épeigné-sur-Dême,37,Indre-et-Loire,24,Centre-Val de Loire
37102,LES ESSARDS,37130,LES ESSARDS,,47.3426883416,0.2979916496,102,Les,Essards,Les Essards,37,Indre-et-Loire,24,Centre-Val de Loire
37103,ESVES LE MOUTIER,37240,ESVES LE MOUTIER,,47.0320001682,0.903999367241,103,,Esves-le-Moutier,Esves-le-Moutier,37,Indre-et-Loire,24,Centre-Val de Loire
37104,ESVRES,37320,ESVRES,,47.2932820552,0.79068276272,104,,Esvres,Esvres,37,Indre-et-Loire,24,Centre-Val de Loire
37105,FAYE LA VINEUSE,37120,FAYE LA VINEUSE,,46.952742358,0.343186442748,105,,Faye-la-Vineuse,Faye-la-Vineuse,37,Indre-et-Loire,24,Centre-Val de Loire
37106,LA FERRIERE,37110,LA FERRIERE,,47.6237299052,0.756396261973,106,La,Ferrière,La Ferrière,37,Indre-et-Loire,24,Centre-Val de Loire
37107,FERRIERE LARCON,37350,FERRIERE LARCON,,46.9960395019,0.874010150834,107,,Ferrière-Larçon,Ferrière-Larçon,37,Indre-et-Loire,24,Centre-Val de Loire
37108,FERRIERE SUR BEAULIEU,37600,FERRIERE SUR BEAULIEU,,47.1454064832,1.04844455169,108,,Ferrière-sur-Beaulieu,Ferrière-sur-Beaulieu,37,Indre-et-Loire,24,Centre-Val de Loire
37109,FONDETTES,37230,FONDETTES,,47.4111012484,0.603284318045,109,,Fondettes,Fondettes,37,Indre-et-Loire,24,Centre-Val de Loire
37110,FRANCUEIL,37150,FRANCUEIL,,47.3099460236,1.07846481627,110,,Francueil,Francueil,37,Indre-et-Loire,24,Centre-Val de Loire
37111,GENILLE,37460,GENILLE,,47.1845516654,1.10886263698,111,,Genillé,Genillé,37,Indre-et-Loire,24,Centre-Val de Loire
37112,GIZEUX,37340,GIZEUX,,47.3894530946,0.187822505403,112,,Gizeux,Gizeux,37,Indre-et-Loire,24,Centre-Val de Loire
37113,LE GRAND PRESSIGNY,37350,LE GRAND PRESSIGNY,,46.9180024855,0.816357986634,113,Le,Grand-Pressigny,Le Grand-Pressigny,37,Indre-et-Loire,24,Centre-Val de Loire
37114,LA GUERCHE,37350,LA GUERCHE,,46.893743302,0.725400888064,114,La,Guerche,La Guerche,37,Indre-et-Loire,24,Centre-Val de Loire
37115,DESCARTES,37160,DESCARTES,,46.9943964229,0.696948012849,115,,Descartes,Descartes,37,Indre-et-Loire,24,Centre-Val de Loire
37116,LES HERMITES,37110,LES HERMITES,,47.668662913,0.760117303641,116,Les,Hermites,Les Hermites,37,Indre-et-Loire,24,Centre-Val de Loire
37117,HOMMES,37340,HOMMES,,47.4233057028,0.283302137982,117,,Hommes,Hommes,37,Indre-et-Loire,24,Centre-Val de Loire
37118,HUISMES,37420,HUISMES,,47.2270033934,0.25509879488,118,,Huismes,Huismes,37,Indre-et-Loire,24,Centre-Val de Loire
37119,L ILE BOUCHARD,37220,L ILE BOUCHARD,,47.1228671821,0.423044659345,119,L',Île-Bouchard,L'Île-Bouchard,37,Indre-et-Loire,24,Centre-Val de Loire
37120,INGRANDES DE TOURAINE,37140,INGRANDES DE TOURAINE,,47.2987909138,0.271834095537,120,,Ingrandes-de-Touraine,Ingrandes-de-Touraine,37,Indre-et-Loire,24,Centre-Val de Loire
37121,JAULNAY,37120,JAULNAY,,46.9546212593,0.422081006076,121,,Jaulnay,Jaulnay,37,Indre-et-Loire,24,Centre-Val de Loire
37122,JOUE LES TOURS,37300,JOUE LES TOURS,,47.333556074,0.654546300116,122,,Joué-lès-Tours,Joué-lès-Tours,37,Indre-et-Loire,24,Centre-Val de Loire
37123,LANGEAIS,37130,LANGEAIS,,47.3498571755,0.367495526989,123,,Langeais,Langeais,37,Indre-et-Loire,24,Centre-Val de Loire
37124,LARCAY,37270,LARCAY,,47.352701653,0.776083557562,124,,Larçay,Larçay,37,Indre-et-Loire,24,Centre-Val de Loire
37125,LEMERE,37120,LEMERE,,47.0937316918,0.334993321235,125,,Lémeré,Lémeré,37,Indre-et-Loire,24,Centre-Val de Loire
37126,LERNE,37500,LERNE,,47.1396272073,0.110609738021,126,,Lerné,Lerné,37,Indre-et-Loire,24,Centre-Val de Loire
37127,LE LIEGE,37460,LE LIEGE,,47.2287701789,1.10899354005,127,Le,Liège,Le Liège,37,Indre-et-Loire,24,Centre-Val de Loire
37128,LIGNIERES DE TOURAINE,37130,LIGNIERES DE TOURAINE,,47.2948836484,0.417423372604,128,,Lignières-de-Touraine,Lignières-de-Touraine,37,Indre-et-Loire,24,Centre-Val de Loire
37129,LIGRE,37500,LIGRE,,47.1089496241,0.272083554217,129,,Ligré,Ligré,37,Indre-et-Loire,24,Centre-Val de Loire
37130,LIGUEIL,37240,LIGUEIL,,47.0423190397,0.817374235768,130,,Ligueil,Ligueil,37,Indre-et-Loire,24,Centre-Val de Loire
37131,LIMERAY,37530,LIMERAY,,47.4553218405,1.03130220301,131,,Limeray,Limeray,37,Indre-et-Loire,24,Centre-Val de Loire
37132,LOCHES,37600,LOCHES,,47.1201464232,0.97415185088,132,,Loches,Loches,37,Indre-et-Loire,24,Centre-Val de Loire
37133,LOCHE SUR INDROIS,37460,LOCHE SUR INDROIS,,47.0839468716,1.20363593791,133,,Loché-sur-Indrois,Loché-sur-Indrois,37,Indre-et-Loire,24,Centre-Val de Loire
37134,LOUANS,37320,LOUANS,,47.1794670914,0.739652182346,134,,Louans,Louans,37,Indre-et-Loire,24,Centre-Val de Loire
37135,LOUESTAULT,37370,LOUESTAULT,,47.6062030941,0.655292241515,135,,Louestault,Louestault,37,Indre-et-Loire,24,Centre-Val de Loire
37136,LE LOUROUX,37240,LE LOUROUX,,47.1561046765,0.761738298456,136,Le,Louroux,Le Louroux,37,Indre-et-Loire,24,Centre-Val de Loire
37137,LUBLE,37330,LUBLE,,47.5273559572,0.255884396012,137,,Lublé,Lublé,37,Indre-et-Loire,24,Centre-Val de Loire
37138,LUSSAULT SUR LOIRE,37400,LUSSAULT SUR LOIRE,,47.3899998844,0.920355361305,138,,Lussault-sur-Loire,Lussault-sur-Loire,37,Indre-et-Loire,24,Centre-Val de Loire
37139,LUYNES,37230,LUYNES,,47.4114770179,0.542304378191,139,,Luynes,Luynes,37,Indre-et-Loire,24,Centre-Val de Loire
37140,LUZE,37120,LUZE,,47.015289486,0.468618290088,140,,Luzé,Luzé,37,Indre-et-Loire,24,Centre-Val de Loire
37141,LUZILLE,37150,LUZILLE,,47.2651645386,1.06257825,141,,Luzillé,Luzillé,37,Indre-et-Loire,24,Centre-Val de Loire
37142,MAILLE,37800,MAILLE,,47.0514867017,0.587132519839,142,,Maillé,Maillé,37,Indre-et-Loire,24,Centre-Val de Loire
37143,MANTHELAN,37240,MANTHELAN,,47.1361002438,0.82161358566,143,,Manthelan,Manthelan,37,Indre-et-Loire,24,Centre-Val de Loire
37144,MARCAY,37500,MARCAY,,47.0829995703,0.209654496806,144,,Marçay,Marçay,37,Indre-et-Loire,24,Centre-Val de Loire
37145,MARCE SUR ESVES,37160,MARCE SUR ESVES,,47.0305269249,0.659737945823,145,,Marcé-sur-Esves,Marcé-sur-Esves,37,Indre-et-Loire,24,Centre-Val de Loire
37146,MARCILLY SUR MAULNE,37330,MARCILLY SUR MAULNE,,47.5485321042,0.224586992617,146,,Marcilly-sur-Maulne,Marcilly-sur-Maulne,37,Indre-et-Loire,24,Centre-Val de Loire
37147,MARCILLY SUR VIENNE,37800,MARCILLY SUR VIENNE,,47.0463483222,0.527587877222,147,,Marcilly-sur-Vienne,Marcilly-sur-Vienne,37,Indre-et-Loire,24,Centre-Val de Loire
37148,MARIGNY MARMANDE,37120,MARIGNY MARMANDE,,46.9765766171,0.481375571528,148,,Marigny-Marmande,Marigny-Marmande,37,Indre-et-Loire,24,Centre-Val de Loire
37149,MARRAY,37370,MARRAY,,47.6239178814,0.703746317881,149,,Marray,Marray,37,Indre-et-Loire,24,Centre-Val de Loire
37150,MAZIERES DE TOURAINE,37130,MAZIERES DE TOURAINE,,47.3913160682,0.429463842673,150,,Mazières-de-Touraine,Mazières-de-Touraine,37,Indre-et-Loire,24,Centre-Val de Loire
37151,LA MEMBROLLE SUR CHOISILLE,37390,LA MEMBROLLE SUR CHOISILLE,,47.4448360187,0.624543767532,151,La,Membrolle-sur-Choisille,La Membrolle-sur-Choisille,37,Indre-et-Loire,24,Centre-Val de Loire
37152,METTRAY,37390,METTRAY,,47.4542179515,0.65946644246,152,,Mettray,Mettray,37,Indre-et-Loire,24,Centre-Val de Loire
37153,MONNAIE,37380,MONNAIE,,47.4985433749,0.786215614417,153,,Monnaie,Monnaie,37,Indre-et-Loire,24,Centre-Val de Loire
37154,MONTBAZON,37250,MONTBAZON,,47.2839455811,0.706640870715,154,,Montbazon,Montbazon,37,Indre-et-Loire,24,Centre-Val de Loire
37155,MONTHODON,37110,MONTHODON,,47.6430916085,0.82248361857,155,,Monthodon,Monthodon,37,Indre-et-Loire,24,Centre-Val de Loire
37156,MONTLOUIS SUR LOIRE,37270,MONTLOUIS SUR LOIRE,,47.3824921367,0.841267944196,156,,Montlouis-sur-Loire,Montlouis-sur-Loire,37,Indre-et-Loire,24,Centre-Val de Loire
37157,MONTRESOR,37460,MONTRESOR,,47.1526392935,1.20166068898,157,,Montrésor,Montrésor,37,Indre-et-Loire,24,Centre-Val de Loire
37158,MONTREUIL EN TOURAINE,37530,MONTREUIL EN TOURAINE,,47.4879968822,0.95212009209,158,,Montreuil-en-Touraine,Montreuil-en-Touraine,37,Indre-et-Loire,24,Centre-Val de Loire
37159,MONTS,37260,MONTS,,47.2779892588,0.64548356483,159,,Monts,Monts,37,Indre-et-Loire,24,Centre-Val de Loire
37160,MORAND,37110,MORAND,,47.5634515197,1.0077505003,160,,Morand,Morand,37,Indre-et-Loire,24,Centre-Val de Loire
37161,MOSNES,37530,MOSNES,,47.4460775152,1.09838820184,161,,Mosnes,Mosnes,37,Indre-et-Loire,24,Centre-Val de Loire
37162,MOUZAY,37600,MOUZAY,,47.1043452782,0.900306738422,162,,Mouzay,Mouzay,37,Indre-et-Loire,24,Centre-Val de Loire
37163,NAZELLES NEGRON,37530,NAZELLES NEGRON,,47.432533517,0.946831274999,163,,Nazelles-Négron,Nazelles-Négron,37,Indre-et-Loire,24,Centre-Val de Loire
37165,NEUIL,37190,NEUIL,,47.1844634588,0.529053683282,165,,Neuil,Neuil,37,Indre-et-Loire,24,Centre-Val de Loire
37166,NEUILLE LE LIERRE,37380,NEUILLE LE LIERRE,,47.5114791555,0.916388700015,166,,Neuillé-le-Lierre,Neuillé-le-Lierre,37,Indre-et-Loire,24,Centre-Val de Loire
37167,NEUILLE PONT PIERRE,37360,NEUILLE PONT PIERRE,,47.5504384264,0.550024622575,167,,Neuillé-Pont-Pierre,Neuillé-Pont-Pierre,37,Indre-et-Loire,24,Centre-Val de Loire
37168,NEUILLY LE BRIGNON,37160,NEUILLY LE BRIGNON,,46.9710638354,0.781602671756,168,,Neuilly-le-Brignon,Neuilly-le-Brignon,37,Indre-et-Loire,24,Centre-Val de Loire
37169,NEUVILLE SUR BRENNE,37110,NEUVILLE SUR BRENNE,,47.6147253318,0.916732764369,169,,Neuville-sur-Brenne,Neuville-sur-Brenne,37,Indre-et-Loire,24,Centre-Val de Loire
37170,NEUVY LE ROI,37370,NEUVY LE ROI,,47.6044637053,0.589828004611,170,,Neuvy-le-Roi,Neuvy-le-Roi,37,Indre-et-Loire,24,Centre-Val de Loire
37171,NOIZAY,37210,NOIZAY,,47.4169793507,0.897184990068,171,,Noizay,Noizay,37,Indre-et-Loire,24,Centre-Val de Loire
37172,NOTRE DAME D OE,37390,NOTRE DAME D OE,,47.4541825862,0.709247164919,172,,Notre-Dame-d'Oé,Notre-Dame-d'Oé,37,Indre-et-Loire,24,Centre-Val de Loire
37173,NOUANS LES FONTAINES,37460,NOUANS LES FONTAINES,,47.1332306828,1.31135598285,173,,Nouans-les-Fontaines,Nouans-les-Fontaines,37,Indre-et-Loire,24,Centre-Val de Loire
37174,NOUATRE,37800,NOUATRE,,47.0331254987,0.560780750524,174,,Nouâtre,Nouâtre,37,Indre-et-Loire,24,Centre-Val de Loire
37175,NOUZILLY,37380,NOUZILLY,,47.5371290234,0.740464665905,175,,Nouzilly,Nouzilly,37,Indre-et-Loire,24,Centre-Val de Loire
37176,NOYANT DE TOURAINE,37800,NOYANT DE TOURAINE,,47.1020911221,0.567489914117,176,,Noyant-de-Touraine,Noyant-de-Touraine,37,Indre-et-Loire,24,Centre-Val de Loire
37177,ORBIGNY,37460,ORBIGNY,,47.2149787074,1.25683944841,177,,Orbigny,Orbigny,37,Indre-et-Loire,24,Centre-Val de Loire
37178,PANZOULT,37220,PANZOULT,,47.1645476117,0.407008827504,178,,Panzoult,Panzoult,37,Indre-et-Loire,24,Centre-Val de Loire
37179,PARCAY MESLAY,37210,PARCAY MESLAY,,47.4520948829,0.742956066986,179,,Parçay-Meslay,Parçay-Meslay,37,Indre-et-Loire,24,Centre-Val de Loire
37180,PARCAY SUR VIENNE,37220,PARCAY SUR VIENNE,,47.0916485316,0.479598033589,180,,Parçay-sur-Vienne,Parçay-sur-Vienne,37,Indre-et-Loire,24,Centre-Val de Loire
37181,PAULMY,37350,PAULMY,,46.9807863071,0.827379730234,181,,Paulmy,Paulmy,37,Indre-et-Loire,24,Centre-Val de Loire
37182,PERNAY,37230,PERNAY,,47.4537949892,0.518019971725,182,,Pernay,Pernay,37,Indre-et-Loire,24,Centre-Val de Loire
37183,PERRUSSON,37600,PERRUSSON,,47.0926524002,1.00808800981,183,,Perrusson,Perrusson,37,Indre-et-Loire,24,Centre-Val de Loire
37184,LE PETIT PRESSIGNY,37350,LE PETIT PRESSIGNY,,46.9099746457,0.917102483604,184,Le,Petit-Pressigny,Le Petit-Pressigny,37,Indre-et-Loire,24,Centre-Val de Loire
37185,POCE SUR CISSE,37530,POCE SUR CISSE,,47.4435625519,0.987369679483,185,,Pocé-sur-Cisse,Pocé-sur-Cisse,37,Indre-et-Loire,24,Centre-Val de Loire
37186,PONT DE RUAN,37260,PONT DE RUAN,,47.2649227442,0.564294280514,186,,Pont-de-Ruan,Pont-de-Ruan,37,Indre-et-Loire,24,Centre-Val de Loire
37187,PORTS,37800,PORTS SUR VIENNE,,47.0141782746,0.538082911824,187,,Ports,Ports,37,Indre-et-Loire,24,Centre-Val de Loire
37188,POUZAY,37800,POUZAY,,47.0824835833,0.546990290456,188,,Pouzay,Pouzay,37,Indre-et-Loire,24,Centre-Val de Loire
37189,PREUILLY SUR CLAISE,37290,PREUILLY SUR CLAISE,,46.8500029976,0.930585707169,189,,Preuilly-sur-Claise,Preuilly-sur-Claise,37,Indre-et-Loire,24,Centre-Val de Loire
37190,PUSSIGNY,37800,PUSSIGNY,,46.9896027807,0.54864410398,190,,Pussigny,Pussigny,37,Indre-et-Loire,24,Centre-Val de Loire
37191,RAZINES,37120,RAZINES,,46.9748443751,0.394788400898,191,,Razines,Razines,37,Indre-et-Loire,24,Centre-Val de Loire
37192,REIGNAC SUR INDRE,37310,REIGNAC SUR INDRE,,47.2227739628,0.916489887764,192,,Reignac-sur-Indre,Reignac-sur-Indre,37,Indre-et-Loire,24,Centre-Val de Loire
37193,RESTIGNE,37140,RESTIGNE,,47.2867984367,0.23554247776,193,,Restigné,Restigné,37,Indre-et-Loire,24,Centre-Val de Loire
37194,REUGNY,37380,REUGNY,,47.4859477646,0.872119692494,194,,Reugny,Reugny,37,Indre-et-Loire,24,Centre-Val de Loire
37195,LA RICHE,37520,LA RICHE,,47.3812692668,0.637590445417,195,La,Riche,La Riche,37,Indre-et-Loire,24,Centre-Val de Loire
37196,RICHELIEU,37120,RICHELIEU,,47.0143945569,0.315628916983,196,,Richelieu,Richelieu,37,Indre-et-Loire,24,Centre-Val de Loire
37197,RIGNY USSE,37420,RIGNY USSE,,47.2510833854,0.305125199741,197,,Rigny-Ussé,Rigny-Ussé,37,Indre-et-Loire,24,Centre-Val de Loire
37198,RILLE,37340,RILLE,,47.4462265152,0.218922496183,198,,Rillé,Rillé,37,Indre-et-Loire,24,Centre-Val de Loire
37199,RILLY SUR VIENNE,37220,RILLY SUR VIENNE,,47.055830392,0.499244209913,199,,Rilly-sur-Vienne,Rilly-sur-Vienne,37,Indre-et-Loire,24,Centre-Val de Loire
37200,RIVARENNES,37190,RIVARENNES,,47.2512605554,0.362365468313,200,,Rivarennes,Rivarennes,37,Indre-et-Loire,24,Centre-Val de Loire
37201,RIVIERE,37500,RIVIERE,,47.1446277658,0.266267354644,201,,Rivière,Rivière,37,Indre-et-Loire,24,Centre-Val de Loire
37202,LA ROCHE CLERMAULT,37500,LA ROCHE CLERMAULT,,47.128052489,0.213550560203,202,La,Roche-Clermault,La Roche-Clermault,37,Indre-et-Loire,24,Centre-Val de Loire
37203,ROCHECORBON,37210,ROCHECORBON,,47.4295541891,0.760919667695,203,,Rochecorbon,Rochecorbon,37,Indre-et-Loire,24,Centre-Val de Loire
37204,ROUZIERS DE TOURAINE,37360,ROUZIERS DE TOURAINE,,47.5311263676,0.647449146554,204,,Rouziers-de-Touraine,Rouziers-de-Touraine,37,Indre-et-Loire,24,Centre-Val de Loire
37205,SACHE,37190,SACHE,,47.2369371967,0.538217215608,205,,Saché,Saché,37,Indre-et-Loire,24,Centre-Val de Loire
37206,ST ANTOINE DU ROCHER,37360,ST ANTOINE DU ROCHER,,47.4959926483,0.631034135983,206,,Saint-Antoine-du-Rocher,Saint-Antoine-du-Rocher,37,Indre-et-Loire,24,Centre-Val de Loire
37207,ST AUBIN LE DEPEINT,37370,ST AUBIN LE DEPEINT,,47.6184568197,0.397095725554,207,,Saint-Aubin-le-Dépeint,Saint-Aubin-le-Dépeint,37,Indre-et-Loire,24,Centre-Val de Loire
37208,ST AVERTIN,37550,ST AVERTIN,,47.3571561121,0.737544440483,208,,Saint-Avertin,Saint-Avertin,37,Indre-et-Loire,24,Centre-Val de Loire
37209,ST BAULD,37310,ST BAULD,,47.1748289396,0.839355106635,209,,Saint-Bauld,Saint-Bauld,37,Indre-et-Loire,24,Centre-Val de Loire
37210,ST BENOIT LA FORET,37500,ST BENOIT LA FORET,,47.2128964711,0.321999441383,210,,Saint-Benoît-la-Forêt,Saint-Benoît-la-Forêt,37,Indre-et-Loire,24,Centre-Val de Loire
37211,ST BRANCHS,37320,ST BRANCHS,,47.224480617,0.75823839932,211,,Saint-Branchs,Saint-Branchs,37,Indre-et-Loire,24,Centre-Val de Loire
37212,STE CATHERINE DE FIERBOIS,37800,STE CATHERINE DE FIERBOIS,,47.1566893573,0.676679379745,212,,Sainte-Catherine-de-Fierbois,Sainte-Catherine-de-Fierbois,37,Indre-et-Loire,24,Centre-Val de Loire
37213,ST CHRISTOPHE SUR LE NAIS,37370,ST CHRISTOPHE SUR LE NAIS,,47.6235750307,0.473186358315,213,,Saint-Christophe-sur-le-Nais,Saint-Christophe-sur-le-Nais,37,Indre-et-Loire,24,Centre-Val de Loire
37214,ST CYR SUR LOIRE,37540,ST CYR SUR LOIRE,,47.4185650638,0.657856616894,214,,Saint-Cyr-sur-Loire,Saint-Cyr-sur-Loire,37,Indre-et-Loire,24,Centre-Val de Loire
37216,ST EPAIN,37800,ST EPAIN,,47.1529048549,0.578633849265,216,,Saint-Épain,Saint-Épain,37,Indre-et-Loire,24,Centre-Val de Loire
37217,ST ETIENNE DE CHIGNY,37230,ST ETIENNE DE CHIGNY,,47.3926426978,0.498863635836,217,,Saint-Étienne-de-Chigny,Saint-Étienne-de-Chigny,37,Indre-et-Loire,24,Centre-Val de Loire
37218,ST FLOVIER,37600,ST FLOVIER,,46.9710693566,1.02123750172,218,,Saint-Flovier,Saint-Flovier,37,Indre-et-Loire,24,Centre-Val de Loire
37219,ST GENOUPH,37510,ST GENOUPH,,47.3724173906,0.589386626061,219,,Saint-Genouph,Saint-Genouph,37,Indre-et-Loire,24,Centre-Val de Loire
37220,ST GERMAIN SUR VIENNE,37500,ST GERMAIN SUR VIENNE,,47.1805914468,0.110524551892,220,,Saint-Germain-sur-Vienne,Saint-Germain-sur-Vienne,37,Indre-et-Loire,24,Centre-Val de Loire
37221,ST HIPPOLYTE,37600,ST HIPPOLYTE,,47.0575770677,1.13256849166,221,,Saint-Hippolyte,Saint-Hippolyte,37,Indre-et-Loire,24,Centre-Val de Loire
37222,ST JEAN ST GERMAIN,37600,ST JEAN ST GERMAIN,,47.0847297585,1.05505942753,222,,Saint-Jean-Saint-Germain,Saint-Jean-Saint-Germain,37,Indre-et-Loire,24,Centre-Val de Loire
37223,ST LAURENT DE LIN,37330,ST LAURENT DE LIN,,47.5132118846,0.271661867347,223,,Saint-Laurent-de-Lin,Saint-Laurent-de-Lin,37,Indre-et-Loire,24,Centre-Val de Loire
37224,ST LAURENT EN GATINES,37380,ST LAURENT EN GATINES,,47.5850036242,0.770610827293,224,,Saint-Laurent-en-Gâtines,Saint-Laurent-en-Gâtines,37,Indre-et-Loire,24,Centre-Val de Loire
37225,ST MARTIN LE BEAU,37270,ST MARTIN LE BEAU,,47.3613543999,0.909919604886,225,,Saint-Martin-le-Beau,Saint-Martin-le-Beau,37,Indre-et-Loire,24,Centre-Val de Loire
37226,STE MAURE DE TOURAINE,37800,STE MAURE DE TOURAINE,,47.1150065072,0.638598437549,226,,Sainte-Maure-de-Touraine,Sainte-Maure-de-Touraine,37,Indre-et-Loire,24,Centre-Val de Loire
37227,ST MICHEL SUR LOIRE,37130,ST MICHEL SUR LOIRE,,47.3178105636,0.32657913623,227,,Saint-Michel-sur-Loire,Saint-Michel-sur-Loire,37,Indre-et-Loire,24,Centre-Val de Loire
37228,ST NICOLAS DE BOURGUEIL,37140,ST NICOLAS DE BOURGUEIL,,47.2962592586,0.130334613981,228,,Saint-Nicolas-de-Bourgueil,Saint-Nicolas-de-Bourgueil,37,Indre-et-Loire,24,Centre-Val de Loire
37229,ST NICOLAS DES MOTETS,37110,ST NICOLAS DES MOTETS,,47.5842753802,1.0342950178,229,,Saint-Nicolas-des-Motets,Saint-Nicolas-des-Motets,37,Indre-et-Loire,24,Centre-Val de Loire
37230,ST OUEN LES VIGNES,37530,ST OUEN LES VIGNES,,47.4793924491,1.00199980889,230,,Saint-Ouen-les-Vignes,Saint-Ouen-les-Vignes,37,Indre-et-Loire,24,Centre-Val de Loire
37231,ST PATERNE RACAN,37370,ST PATERNE RACAN,,47.5816905056,0.466637692187,231,,Saint-Paterne-Racan,Saint-Paterne-Racan,37,Indre-et-Loire,24,Centre-Val de Loire
37232,ST PATRICE,37130,ST PATRICE,,47.2909121663,0.301054429899,232,,Coteaux-sur-Loire,Coteaux-sur-Loire,37,Indre-et-Loire,24,Centre-Val de Loire
37233,ST PIERRE DES CORPS,37700,ST PIERRE DES CORPS,,47.3884488197,0.738011772072,233,,Saint-Pierre-des-Corps,Saint-Pierre-des-Corps,37,Indre-et-Loire,24,Centre-Val de Loire
37234,ST QUENTIN SUR INDROIS,37310,ST QUENTIN SUR INDROIS,,47.2088575557,1.03674729969,234,,Saint-Quentin-sur-Indrois,Saint-Quentin-sur-Indrois,37,Indre-et-Loire,24,Centre-Val de Loire
37236,ST REGLE,37530,ST REGLE,,47.4099495964,1.04070768948,236,,Saint-Règle,Saint-Règle,37,Indre-et-Loire,24,Centre-Val de Loire
37237,ST ROCH,37390,ST ROCH,,47.4460052887,0.574049173114,237,,Saint-Roch,Saint-Roch,37,Indre-et-Loire,24,Centre-Val de Loire
37238,ST SENOCH,37600,ST SENOCH,,47.0564904332,0.953203885022,238,,Saint-Senoch,Saint-Senoch,37,Indre-et-Loire,24,Centre-Val de Loire
37240,SAUNAY,37110,SAUNAY,,47.5987929508,0.95907236748,240,,Saunay,Saunay,37,Indre-et-Loire,24,Centre-Val de Loire
37241,SAVIGNE SUR LATHAN,37340,SAVIGNE SUR LATHAN,,47.4496709939,0.326681669854,241,,Savigné-sur-Lathan,Savigné-sur-Lathan,37,Indre-et-Loire,24,Centre-Val de Loire
37242,SAVIGNY EN VERON,37420,SAVIGNY EN VERON,,47.2111243782,0.130191774161,242,,Savigny-en-Véron,Savigny-en-Véron,37,Indre-et-Loire,24,Centre-Val de Loire
37243,SAVONNIERES,37510,SAVONNIERES,,47.3465342134,0.558778925825,243,,Savonnières,Savonnières,37,Indre-et-Loire,24,Centre-Val de Loire
37244,SAZILLY,37220,SAZILLY,,47.1257115061,0.349907855669,244,,Sazilly,Sazilly,37,Indre-et-Loire,24,Centre-Val de Loire
37245,SEMBLANCAY,37360,SEMBLANCAY,,47.5034499347,0.564845858172,245,,Semblançay,Semblançay,37,Indre-et-Loire,24,Centre-Val de Loire
37246,SENNEVIERES,37600,SENNEVIERES,,47.1126296687,1.10632859904,246,,Sennevières,Sennevières,37,Indre-et-Loire,24,Centre-Val de Loire
37247,SEPMES,37800,SEPMES,,47.0806987926,0.684349143477,247,,Sepmes,Sepmes,37,Indre-et-Loire,24,Centre-Val de Loire
37248,SEUILLY,37500,SEUILLY,,47.1268380183,0.160711623152,248,,Seuilly,Seuilly,37,Indre-et-Loire,24,Centre-Val de Loire
37249,SONZAY,37360,SONZAY,,47.5132295352,0.477318608406,249,,Sonzay,Sonzay,37,Indre-et-Loire,24,Centre-Val de Loire
37250,SORIGNY,37250,SORIGNY,,47.2310354675,0.686650122405,250,,Sorigny,Sorigny,37,Indre-et-Loire,24,Centre-Val de Loire
37251,SOUVIGNE,37330,SOUVIGNE,,47.5151982647,0.402687568024,251,,Souvigné,Souvigné,37,Indre-et-Loire,24,Centre-Val de Loire
37252,SOUVIGNY DE TOURAINE,37530,SOUVIGNY DE TOURAINE,,47.3983473379,1.08415329662,252,,Souvigny-de-Touraine,Souvigny-de-Touraine,37,Indre-et-Loire,24,Centre-Val de Loire
37253,SUBLAINES,37310,SUBLAINES,,47.2702127899,0.989804220269,253,,Sublaines,Sublaines,37,Indre-et-Loire,24,Centre-Val de Loire
37254,TAUXIGNY,37310,TAUXIGNY,,47.2077298094,0.829508341853,254,,Tauxigny-Saint-Bauld,Tauxigny-Saint-Bauld,37,Indre-et-Loire,24,Centre-Val de Loire
37255,TAVANT,37220,TAVANT,,47.1179057551,0.385766006554,255,,Tavant,Tavant,37,Indre-et-Loire,24,Centre-Val de Loire
37256,THENEUIL,37220,THENEUIL,,47.0997750575,0.433594767528,256,,Theneuil,Theneuil,37,Indre-et-Loire,24,Centre-Val de Loire
37257,THILOUZE,37260,THILOUZE,,47.2196901123,0.601021340969,257,,Thilouze,Thilouze,37,Indre-et-Loire,24,Centre-Val de Loire
37258,THIZAY,37500,THIZAY,,47.1642216869,0.141915191024,258,,Thizay,Thizay,37,Indre-et-Loire,24,Centre-Val de Loire
37259,TOURNON ST PIERRE,37290,TOURNON ST PIERRE,,46.7610344605,0.946565591011,259,,Tournon-Saint-Pierre,Tournon-Saint-Pierre,37,Indre-et-Loire,24,Centre-Val de Loire
37260,LA TOUR ST GELIN,37120,LA TOUR ST GELIN,,47.0616135081,0.395504647146,260,La,Tour-Saint-Gelin,La Tour-Saint-Gelin,37,Indre-et-Loire,24,Centre-Val de Loire
37261,TOURS,37000,TOURS,,47.3986382281,0.696526376417,261,,Tours,Tours,37,Indre-et-Loire,24,Centre-Val de Loire
37261,TOURS,37100,TOURS,,47.3986382281,0.696526376417,261,,Tours,Tours,37,Indre-et-Loire,24,Centre-Val de Loire
37261,TOURS,37200,TOURS,,47.3986382281,0.696526376417,261,,Tours,Tours,37,Indre-et-Loire,24,Centre-Val de Loire
37262,TROGUES,37220,TROGUES,,47.111278976,0.511032879391,262,,Trogues,Trogues,37,Indre-et-Loire,24,Centre-Val de Loire
37263,TRUYES,37320,TRUYES,,47.2855863976,0.851603945631,263,,Truyes,Truyes,37,Indre-et-Loire,24,Centre-Val de Loire
37264,VALLERES,37190,VALLERES,,47.3068592905,0.473611615141,264,,Vallères,Vallères,37,Indre-et-Loire,24,Centre-Val de Loire
37265,VARENNES,37600,VARENNES,,47.0709398168,0.912799456944,265,,Varennes,Varennes,37,Indre-et-Loire,24,Centre-Val de Loire
37266,VEIGNE,37250,VEIGNE,,47.2888419417,0.729965991438,266,,Veigné,Veigné,37,Indre-et-Loire,24,Centre-Val de Loire
37267,VERETZ,37270,VERETZ,,47.3522450777,0.810254495994,267,,Véretz,Véretz,37,Indre-et-Loire,24,Centre-Val de Loire
37268,VERNEUIL LE CHATEAU,37120,VERNEUIL LE CHATEAU,,47.0424242806,0.457350796118,268,,Verneuil-le-Château,Verneuil-le-Château,37,Indre-et-Loire,24,Centre-Val de Loire
37269,VERNEUIL SUR INDRE,37600,VERNEUIL SUR INDRE,,47.0358243641,1.0338624118,269,,Verneuil-sur-Indre,Verneuil-sur-Indre,37,Indre-et-Loire,24,Centre-Val de Loire
37270,VERNOU SUR BRENNE,37210,VERNOU SUR BRENNE,,47.4364709418,0.840522961701,270,,Vernou-sur-Brenne,Vernou-sur-Brenne,37,Indre-et-Loire,24,Centre-Val de Loire
37271,VILLAINES LES ROCHERS,37190,VILLAINES LES ROCHERS,,47.2202766951,0.496409535046,271,,Villaines-les-Rochers,Villaines-les-Rochers,37,Indre-et-Loire,24,Centre-Val de Loire
37272,VILLANDRY,37510,VILLANDRY,,47.331363549,0.500326807176,272,,Villandry,Villandry,37,Indre-et-Loire,24,Centre-Val de Loire
37273,LA VILLE AUX DAMES,37700,LA VILLE AUX DAMES,,47.3918448824,0.77454986041,273,La,Ville-aux-Dames,La Ville-aux-Dames,37,Indre-et-Loire,24,Centre-Val de Loire
37274,VILLEBOURG,37370,VILLEBOURG,,47.637086362,0.523444509985,274,,Villebourg,Villebourg,37,Indre-et-Loire,24,Centre-Val de Loire
37275,VILLEDOMAIN,37460,VILLEDOMAIN,,47.0438938302,1.25395399014,275,,Villedômain,Villedômain,37,Indre-et-Loire,24,Centre-Val de Loire
37276,VILLEDOMER,37110,VILLEDOMER,,47.5513740132,0.869987989658,276,,Villedômer,Villedômer,37,Indre-et-Loire,24,Centre-Val de Loire
37277,VILLELOIN COULANGE,37460,VILLELOIN COULANGE,,47.1350642786,1.23619125838,277,,Villeloin-Coulangé,Villeloin-Coulangé,37,Indre-et-Loire,24,Centre-Val de Loire
37278,VILLEPERDUE,37260,VILLEPERDUE,,47.1974342509,0.638407189109,278,,Villeperdue,Villeperdue,37,Indre-et-Loire,24,Centre-Val de Loire
37279,VILLIERS AU BOUIN,37330,VILLIERS AU BOUIN,,47.5831138552,0.283424137003,279,,Villiers-au-Bouin,Villiers-au-Bouin,37,Indre-et-Loire,24,Centre-Val de Loire
37280,VOU,37240,VOU,,47.0942514937,0.84684851199,280,,Vou,Vou,37,Indre-et-Loire,24,Centre-Val de Loire
37281,VOUVRAY,37210,VOUVRAY,,47.4270674878,0.803667208286,281,,Vouvray,Vouvray,37,Indre-et-Loire,24,Centre-Val de Loire
37282,YZEURES SUR CREUSE,37290,YZEURES SUR CREUSE,,46.7902872236,0.876208149129,282,,Yzeures-sur-Creuse,Yzeures-sur-Creuse,37,Indre-et-Loire,24,Centre-Val de Loire
38001,LES ABRETS EN DAUPHINE,38490,LES ABRETS EN DAUPHINE,,45.5418940561,5.59342426222,1,Les,Abrets en Dauphiné,Les Abrets en Dauphiné,38,Isère,84,Auvergne-Rhône-Alpes
38001,LES ABRETS EN DAUPHINE,38490,LES ABRETS EN DAUPHINE,FITILIEU,45.5418940561,5.59342426222,1,Les,Abrets en Dauphiné,Les Abrets en Dauphiné,38,Isère,84,Auvergne-Rhône-Alpes
38001,LES ABRETS EN DAUPHINE,38490,LES ABRETS EN DAUPHINE,LA BATIE DIVISIN,45.5418940561,5.59342426222,1,Les,Abrets en Dauphiné,Les Abrets en Dauphiné,38,Isère,84,Auvergne-Rhône-Alpes
38002,LES ADRETS,38190,LES ADRETS,,45.2586070645,5.99155979387,2,Les,Adrets,Les Adrets,38,Isère,84,Auvergne-Rhône-Alpes
38002,LES ADRETS,38190,LES ADRETS,PRAPOUTEL,45.2586070645,5.99155979387,2,Les,Adrets,Les Adrets,38,Isère,84,Auvergne-Rhône-Alpes
38003,AGNIN,38150,AGNIN,,45.3365990336,4.85997858164,3,,Agnin,Agnin,38,Isère,84,Auvergne-Rhône-Alpes
38004,L ALBENC,38470,L ALBENC,,45.2189306254,5.45835784112,4,L',Albenc,L'Albenc,38,Isère,84,Auvergne-Rhône-Alpes
38005,ALLEMOND,38114,ALLEMOND,,45.1710812431,6.01841266014,5,,Allemond,Allemond,38,Isère,84,Auvergne-Rhône-Alpes
38006,ALLEVARD,38580,ALLEVARD,,45.3798822282,6.11410440285,6,,Allevard,Allevard,38,Isère,84,Auvergne-Rhône-Alpes
38008,AMBEL,38970,AMBEL,,44.7995432334,5.93325975329,8,,Ambel,Ambel,38,Isère,84,Auvergne-Rhône-Alpes
38009,ANJOU,38150,ANJOU,,45.3485883521,4.88275176327,9,,Anjou,Anjou,38,Isère,84,Auvergne-Rhône-Alpes
38010,ANNOISIN CHATELANS,38460,ANNOISIN CHATELANS,,45.7607896686,5.29198081726,10,,Annoisin-Chatelans,Annoisin-Chatelans,38,Isère,84,Auvergne-Rhône-Alpes
38011,ANTHON,38280,ANTHON,,45.7847587355,5.15418784816,11,,Anthon,Anthon,38,Isère,84,Auvergne-Rhône-Alpes
38012,AOSTE,38490,AOSTE,,45.5976216526,5.61289945248,12,,Aoste,Aoste,38,Isère,84,Auvergne-Rhône-Alpes
38013,APPRIEU,38140,APPRIEU,,45.3973605604,5.4963701967,13,,Apprieu,Apprieu,38,Isère,84,Auvergne-Rhône-Alpes
38013,APPRIEU,38140,APPRIEU,LE RIVIER,45.3973605604,5.4963701967,13,,Apprieu,Apprieu,38,Isère,84,Auvergne-Rhône-Alpes
38014,ARANDON,38510,ARANDON,,45.7165626192,5.44443643787,14,,Arandon,Arandon,38,Isère,84,Auvergne-Rhône-Alpes
38015,ARTAS,38440,ARTAS,,45.537434677,5.16699367154,15,,Artas,Artas,38,Isère,84,Auvergne-Rhône-Alpes
38016,ARZAY,38260,ARZAY,,45.4323485765,5.16764986785,16,,Arzay,Arzay,38,Isère,84,Auvergne-Rhône-Alpes
38017,ASSIEU,38150,ASSIEU,,45.4098620584,4.87676181443,17,,Assieu,Assieu,38,Isère,84,Auvergne-Rhône-Alpes
38018,AUBERIVES EN ROYANS,38680,AUBERIVES EN ROYANS,,45.0650470606,5.31134637668,18,,Auberives-en-Royans,Auberives-en-Royans,38,Isère,84,Auvergne-Rhône-Alpes
38019,AUBERIVES SUR VAREZE,38550,AUBERIVES SUR VAREZE,,45.4264263459,4.81869609163,19,,Auberives-sur-Varèze,Auberives-sur-Varèze,38,Isère,84,Auvergne-Rhône-Alpes
38020,AURIS,38142,AURIS,,45.051711372,6.08358997481,20,,Auris,Auris,38,Isère,84,Auvergne-Rhône-Alpes
38022,LES AVENIERES VEYRINS THUELLIN,38630,LES AVENIERES VEYRINS THUELLIN,,45.6426437379,5.56958624007,22,Les,Avenières Veyrins-Thuellin,Les Avenières Veyrins-Thuellin,38,Isère,84,Auvergne-Rhône-Alpes
38022,LES AVENIERES VEYRINS THUELLIN,38630,LES AVENIERES VEYRINS THUELLIN,THUELLIN,45.6426437379,5.56958624007,22,Les,Avenières Veyrins-Thuellin,Les Avenières Veyrins-Thuellin,38,Isère,84,Auvergne-Rhône-Alpes
38022,LES AVENIERES VEYRINS THUELLIN,38630,LES AVENIERES VEYRINS THUELLIN,VEYRINS THUELLIN,45.6426437379,5.56958624007,22,Les,Avenières Veyrins-Thuellin,Les Avenières Veyrins-Thuellin,38,Isère,84,Auvergne-Rhône-Alpes
38023,AVIGNONET,38650,AVIGNONET,,44.9575987733,5.67358379925,23,,Avignonet,Avignonet,38,Isère,84,Auvergne-Rhône-Alpes
38025,BALBINS,38260,BALBINS,,45.3884053123,5.21673833566,25,,Balbins,Balbins,38,Isère,84,Auvergne-Rhône-Alpes
38026,LA BALME LES GROTTES,38390,LA BALME LES GROTTES,,45.8422873745,5.32038841338,26,La,Balme-les-Grottes,La Balme-les-Grottes,38,Isère,84,Auvergne-Rhône-Alpes
38027,BARRAUX,38530,BARRAUX,,45.4360335449,5.98047363076,27,,Barraux,Barraux,38,Isère,84,Auvergne-Rhône-Alpes
38029,LA BATIE MONTGASCON,38110,LA BATIE MONTGASCON,,45.575480567,5.54140939412,29,La,Bâtie-Montgascon,La Bâtie-Montgascon,38,Isère,84,Auvergne-Rhône-Alpes
38030,BEAUCROISSANT,38140,BEAUCROISSANT,,45.3427876459,5.46749723438,30,,Beaucroissant,Beaucroissant,38,Isère,84,Auvergne-Rhône-Alpes
38031,BEAUFIN,38970,BEAUFIN,,44.7869957954,5.95901244444,31,,Beaufin,Beaufin,38,Isère,84,Auvergne-Rhône-Alpes
38032,BEAUFORT,38270,BEAUFORT,,45.3294079707,5.11631301221,32,,Beaufort,Beaufort,38,Isère,84,Auvergne-Rhône-Alpes
38033,BEAULIEU,38470,BEAULIEU,,45.1910839451,5.3972824821,33,,Beaulieu,Beaulieu,38,Isère,84,Auvergne-Rhône-Alpes
38034,BEAUREPAIRE,38270,BEAUREPAIRE,,45.3365279901,5.04348562581,34,,Beaurepaire,Beaurepaire,38,Isère,84,Auvergne-Rhône-Alpes
38035,BEAUVOIR DE MARC,38440,BEAUVOIR DE MARC,,45.5199988215,5.07965100181,35,,Beauvoir-de-Marc,Beauvoir-de-Marc,38,Isère,84,Auvergne-Rhône-Alpes
38036,BEAUVOIR EN ROYANS,38160,BEAUVOIR EN ROYANS,,45.1173419609,5.35007651511,36,,Beauvoir-en-Royans,Beauvoir-en-Royans,38,Isère,84,Auvergne-Rhône-Alpes
38037,BELLEGARDE POUSSIEU,38270,BELLEGARDE POUSSIEU,,45.3784722869,4.95134769668,37,,Bellegarde-Poussieu,Bellegarde-Poussieu,38,Isère,84,Auvergne-Rhône-Alpes
38038,BELMONT,38690,BELMONT,,45.4741428026,5.36378238823,38,,Belmont,Belmont,38,Isère,84,Auvergne-Rhône-Alpes
38039,BERNIN,38190,BERNIN,,45.2653406999,5.86419475853,39,,Bernin,Bernin,38,Isère,84,Auvergne-Rhône-Alpes
38040,BESSE,38142,BESSE,,45.1044444895,6.20876758912,40,,Besse,Besse,38,Isère,84,Auvergne-Rhône-Alpes
38041,BESSINS,38160,BESSINS,,45.2046121407,5.2638406103,41,,Bessins,Bessins,38,Isère,84,Auvergne-Rhône-Alpes
38042,BEVENAIS,38690,BEVENAIS,,45.3935194103,5.39259917103,42,,Bévenais,Bévenais,38,Isère,84,Auvergne-Rhône-Alpes
38043,BILIEU,38850,BILIEU,,45.4461611441,5.55137429348,43,,Bilieu,Bilieu,38,Isère,84,Auvergne-Rhône-Alpes
38044,BIOL,38690,BIOL,,45.4963748824,5.36908721787,44,,Biol,Biol,38,Isère,84,Auvergne-Rhône-Alpes
38045,BIVIERS,38330,BIVIERS,,45.2431208379,5.79850862941,45,,Biviers,Biviers,38,Isère,84,Auvergne-Rhône-Alpes
38046,BIZONNES,38690,BIZONNES,,45.4468823262,5.37901659647,46,,Bizonnes,Bizonnes,38,Isère,84,Auvergne-Rhône-Alpes
38047,BLANDIN,38730,BLANDIN,,45.4796488543,5.44663594679,47,,Blandin,Blandin,38,Isère,84,Auvergne-Rhône-Alpes
38048,BONNEFAMILLE,38090,BONNEFAMILLE,,45.6005617207,5.11452103575,48,,Bonnefamille,Bonnefamille,38,Isère,84,Auvergne-Rhône-Alpes
38049,BOSSIEU,38260,BOSSIEU,,45.4177469183,5.14812089926,49,,Bossieu,Bossieu,38,Isère,84,Auvergne-Rhône-Alpes
38050,LE BOUCHAGE,38510,LE BOUCHAGE,,45.6694922312,5.52270028125,50,Le,Bouchage,Le Bouchage,38,Isère,84,Auvergne-Rhône-Alpes
38051,BOUGE CHAMBALUD,38150,BOUGE CHAMBALUD,,45.3199496154,4.89224793347,51,,Bougé-Chambalud,Bougé-Chambalud,38,Isère,84,Auvergne-Rhône-Alpes
38052,LE BOURG D OISANS,38520,LE BOURG D OISANS,,45.0344190241,6.03272664873,52,Le,Bourg-d'Oisans,Le Bourg-d'Oisans,38,Isère,84,Auvergne-Rhône-Alpes
38053,BOURGOIN JALLIEU,38300,BOURGOIN JALLIEU,,45.6022027954,5.27393762111,53,,Bourgoin-Jallieu,Bourgoin-Jallieu,38,Isère,84,Auvergne-Rhône-Alpes
38053,BOURGOIN JALLIEU,38300,BOURGOIN JALLIEU,JALLIEU,45.6022027954,5.27393762111,53,,Bourgoin-Jallieu,Bourgoin-Jallieu,38,Isère,84,Auvergne-Rhône-Alpes
38054,BOUVESSE QUIRIEU,38390,BOUVESSE QUIRIEU,,45.7838215113,5.41483452753,54,,Bouvesse-Quirieu,Bouvesse-Quirieu,38,Isère,84,Auvergne-Rhône-Alpes
38055,BRANGUES,38510,BRANGUES,,45.6960928828,5.53688016446,55,,Brangues,Brangues,38,Isère,84,Auvergne-Rhône-Alpes
38056,BRESSIEUX,38870,BRESSIEUX,,45.3220838,5.2775300501,56,,Bressieux,Bressieux,38,Isère,84,Auvergne-Rhône-Alpes
38057,BRESSON,38320,BRESSON,,45.1342682884,5.7510667411,57,,Bresson,Bresson,38,Isère,84,Auvergne-Rhône-Alpes
38058,BREZINS,38590,BREZINS,,45.3455407295,5.30808563402,58,,Brézins,Brézins,38,Isère,84,Auvergne-Rhône-Alpes
38059,BRIE ET ANGONNES,38320,BRIE ET ANGONNES,,45.1234335157,5.77865185697,59,,Brié-et-Angonnes,Brié-et-Angonnes,38,Isère,84,Auvergne-Rhône-Alpes
38060,BRION,38590,BRION,,45.2923920094,5.33971268378,60,,Brion,Brion,38,Isère,84,Auvergne-Rhône-Alpes
38061,LA BUISSE,38500,LA BUISSE,,45.331138203,5.62356410613,61,La,Buisse,La Buisse,38,Isère,84,Auvergne-Rhône-Alpes
38062,LA BUISSIERE,38530,LA BUISSIERE,,45.4086712829,5.98572771448,62,La,Buissière,La Buissière,38,Isère,84,Auvergne-Rhône-Alpes
38063,BURCIN,38690,BURCIN,,45.4333439641,5.44768050206,63,,Burcin,Burcin,38,Isère,84,Auvergne-Rhône-Alpes
38064,CESSIEU,38110,CESSIEU,,45.5689096926,5.3761675679,64,,Cessieu,Cessieu,38,Isère,84,Auvergne-Rhône-Alpes
38065,CHABONS,38690,CHABONS,,45.4485453876,5.42585886408,65,,Châbons,Châbons,38,Isère,84,Auvergne-Rhône-Alpes
38066,CHALON,38122,CHALON,,45.4519499171,4.9379744903,66,,Chalon,Chalon,38,Isère,84,Auvergne-Rhône-Alpes
38067,CHAMAGNIEU,38460,CHAMAGNIEU,,45.6881616804,5.16661541737,67,,Chamagnieu,Chamagnieu,38,Isère,84,Auvergne-Rhône-Alpes
38067,CHAMAGNIEU,38460,CHAMAGNIEU,MIANGES,45.6881616804,5.16661541737,67,,Chamagnieu,Chamagnieu,38,Isère,84,Auvergne-Rhône-Alpes
38068,CHAMPAGNIER,38800,CHAMPAGNIER,,45.11071592,5.72119318061,68,,Champagnier,Champagnier,38,Isère,84,Auvergne-Rhône-Alpes
38069,CHAMPIER,38260,CHAMPIER,,45.4576152351,5.29213304466,69,,Champier,Champier,38,Isère,84,Auvergne-Rhône-Alpes
38070,LE CHAMP PRES FROGES,38190,LE CHAMP PRES FROGES,,45.2814832308,5.93863147318,70,Le,Champ-près-Froges,Le Champ-près-Froges,38,Isère,84,Auvergne-Rhône-Alpes
38071,CHAMP SUR DRAC,38560,CHAMP SUR DRAC,,45.0692413973,5.72756303185,71,,Champ-sur-Drac,Champ-sur-Drac,38,Isère,84,Auvergne-Rhône-Alpes
38072,CHANAS,38150,CHANAS,,45.3178362934,4.83092383064,72,,Chanas,Chanas,38,Isère,84,Auvergne-Rhône-Alpes
38073,CHANTELOUVE,38740,CHANTELOUVE,,44.9859421745,5.97635547463,73,,Chantelouve,Chantelouve,38,Isère,84,Auvergne-Rhône-Alpes
38074,CHANTESSE,38470,CHANTESSE,,45.2459657752,5.44704031612,74,,Chantesse,Chantesse,38,Isère,84,Auvergne-Rhône-Alpes
38075,CHAPAREILLAN,38530,CHAPAREILLAN,,45.461591172,5.95800759236,75,,Chapareillan,Chapareillan,38,Isère,84,Auvergne-Rhône-Alpes
38076,LA CHAPELLE DE LA TOUR,38110,LA CHAPELLE DE LA TOUR,,45.5893265412,5.47141719996,76,La,Chapelle-de-la-Tour,La Chapelle-de-la-Tour,38,Isère,84,Auvergne-Rhône-Alpes
38077,LA CHAPELLE DE SURIEU,38150,LA CHAPELLE DE SURIEU,,45.3944898603,4.9163173069,77,La,Chapelle-de-Surieu,La Chapelle-de-Surieu,38,Isère,84,Auvergne-Rhône-Alpes
38078,LA CHAPELLE DU BARD,38580,LA CHAPELLE DU BARD,,45.4025988849,6.13800529096,78,La,Chapelle-du-Bard,La Chapelle-du-Bard,38,Isère,84,Auvergne-Rhône-Alpes
38080,CHARANCIEU,38490,CHARANCIEU,,45.5205135437,5.57906529008,80,,Charancieu,Charancieu,38,Isère,84,Auvergne-Rhône-Alpes
38081,CHARANTONNAY,38790,CHARANTONNAY,,45.5380647102,5.11609065065,81,,Charantonnay,Charantonnay,38,Isère,84,Auvergne-Rhône-Alpes
38082,CHARAVINES,38850,CHARAVINES,,45.4275810655,5.51841184282,82,,Charavines,Charavines,38,Isère,84,Auvergne-Rhône-Alpes
38083,CHARETTE,38390,CHARETTE,,45.8050684223,5.36209656875,83,,Charette,Charette,38,Isère,84,Auvergne-Rhône-Alpes
38084,CHARNECLES,38140,CHARNECLES,,45.3423976098,5.52675118378,84,,Charnècles,Charnècles,38,Isère,84,Auvergne-Rhône-Alpes
38085,CHARVIEU CHAVAGNEUX,38230,CHARVIEU CHAVAGNEUX,,45.7398511068,5.15111694568,85,,Charvieu-Chavagneux,Charvieu-Chavagneux,38,Isère,84,Auvergne-Rhône-Alpes
38085,CHARVIEU CHAVAGNEUX,38230,CHARVIEU CHAVAGNEUX,CHAVAGNEUX,45.7398511068,5.15111694568,85,,Charvieu-Chavagneux,Charvieu-Chavagneux,38,Isère,84,Auvergne-Rhône-Alpes
38086,CHASSELAY,38470,CHASSELAY,,45.2605536513,5.34184461228,86,,Chasselay,Chasselay,38,Isère,84,Auvergne-Rhône-Alpes
38087,CHASSE SUR RHONE,38670,CHASSE SUR RHONE,,45.5797669773,4.81420294395,87,,Chasse-sur-Rhône,Chasse-sur-Rhône,38,Isère,84,Auvergne-Rhône-Alpes
38089,CHASSIGNIEU,38730,CHASSIGNIEU,,45.501095883,5.51287218858,89,,Chassignieu,Chassignieu,38,Isère,84,Auvergne-Rhône-Alpes
38090,CHATEAU BERNARD,38650,CHATEAU BERNARD,,44.9815645437,5.56326109526,90,,Château-Bernard,Château-Bernard,38,Isère,84,Auvergne-Rhône-Alpes
38091,CHATEAUVILAIN,38300,CHATEAUVILAIN,,45.5138631652,5.33027661744,91,,Châteauvilain,Châteauvilain,38,Isère,84,Auvergne-Rhône-Alpes
38092,CHATELUS,38680,CHATELUS,,45.0542328632,5.38957416574,92,,Châtelus,Châtelus,38,Isère,84,Auvergne-Rhône-Alpes
38093,CHATENAY,38980,CHATENAY,,45.3294698557,5.22927458639,93,,Châtenay,Châtenay,38,Isère,84,Auvergne-Rhône-Alpes
38094,CHATONNAY,38440,CHATONNAY,,45.4770417686,5.22225829688,94,,Châtonnay,Châtonnay,38,Isère,84,Auvergne-Rhône-Alpes
38095,CHATTE,38160,CHATTE,,45.139921767,5.2802381895,95,,Chatte,Chatte,38,Isère,84,Auvergne-Rhône-Alpes
38097,CHAVANOZ,38230,CHAVANOZ,,45.7688834941,5.17259586722,97,,Chavanoz,Chavanoz,38,Isère,84,Auvergne-Rhône-Alpes
38098,CHELIEU,38730,CHELIEU,,45.5077827268,5.48032836102,98,,Chélieu,Chélieu,38,Isère,84,Auvergne-Rhône-Alpes
38099,CHEVRIERES,38160,CHEVRIERES,,45.1924932061,5.29333995855,99,,Chevrières,Chevrières,38,Isère,84,Auvergne-Rhône-Alpes
38100,LE CHEYLAS,38570,LE CHEYLAS,,45.3806432733,5.99862071859,100,Le,Cheylas,Le Cheylas,38,Isère,84,Auvergne-Rhône-Alpes
38101,CHEYSSIEU,38550,CHEYSSIEU,,45.4259695237,4.8429128403,101,,Cheyssieu,Cheyssieu,38,Isère,84,Auvergne-Rhône-Alpes
38102,CHEZENEUVE,38300,CHEZENEUVE,,45.5611018876,5.21723441393,102,,Chèzeneuve,Chèzeneuve,38,Isère,84,Auvergne-Rhône-Alpes
38103,CHICHILIANNE,38930,CHICHILIANNE,,44.8077793514,5.53905603141,103,,Chichilianne,Chichilianne,38,Isère,84,Auvergne-Rhône-Alpes
38104,CHIMILIN,38490,CHIMILIN,,45.5731073891,5.5894858919,104,,Chimilin,Chimilin,38,Isère,84,Auvergne-Rhône-Alpes
38105,CHIRENS,38850,CHIRENS,,45.4166318565,5.556774983,105,,Chirens,Chirens,38,Isère,84,Auvergne-Rhône-Alpes
38106,CHOLONGE,38220,CHOLONGE,,45.00953005,5.80042911012,106,,Cholonge,Cholonge,38,Isère,84,Auvergne-Rhône-Alpes
38107,CHONAS L AMBALLAN,38121,CHONAS L AMBALLAN,,45.464474261,4.80291707656,107,,Chonas-l'Amballan,Chonas-l'Amballan,38,Isère,84,Auvergne-Rhône-Alpes
38108,CHORANCHE,38680,CHORANCHE,,45.071206623,5.39354497889,108,,Choranche,Choranche,38,Isère,84,Auvergne-Rhône-Alpes
38109,CHOZEAU,38460,CHOZEAU,,45.6995373751,5.20945121769,109,,Chozeau,Chozeau,38,Isère,84,Auvergne-Rhône-Alpes
38110,CHUZELLES,38200,CHUZELLES,,45.5754949001,4.87825997327,110,,Chuzelles,Chuzelles,38,Isère,84,Auvergne-Rhône-Alpes
38111,CLAIX,38640,CLAIX,,45.1224170897,5.65707336922,111,,Claix,Claix,38,Isère,84,Auvergne-Rhône-Alpes
38112,CLAVANS EN HAUT OISANS,38142,CLAVANS EN HAUT OISANS,,45.1175117933,6.16207850111,112,,Clavans-en-Haut-Oisans,Clavans-en-Haut-Oisans,38,Isère,84,Auvergne-Rhône-Alpes
38113,CLELLES,38930,CLELLES EN TRIEVES,,44.8246711556,5.62901849156,113,,Clelles,Clelles,38,Isère,84,Auvergne-Rhône-Alpes
38114,CLONAS SUR VAREZE,38550,CLONAS SUR VAREZE,,45.4145802588,4.79236442681,114,,Clonas-sur-Varèze,Clonas-sur-Varèze,38,Isère,84,Auvergne-Rhône-Alpes
38115,ST MARTIN DE LA CLUZE,38650,ST MARTIN DE LA CLUZE,,44.9895989377,5.66101914988,115,,Saint-Martin-de-la-Cluze,Saint-Martin-de-la-Cluze,38,Isère,84,Auvergne-Rhône-Alpes
38116,COGNET,38350,COGNET,,44.8812149544,5.77569848329,116,,Cognet,Cognet,38,Isère,84,Auvergne-Rhône-Alpes
38117,COGNIN LES GORGES,38470,COGNIN LES GORGES,,45.1707745385,5.41758874544,117,,Cognin-les-Gorges,Cognin-les-Gorges,38,Isère,84,Auvergne-Rhône-Alpes
38118,COLOMBE,38690,COLOMBE,,45.3941269048,5.4492922949,118,,Colombe,Colombe,38,Isère,84,Auvergne-Rhône-Alpes
38120,LA COMBE DE LANCEY,38190,LA COMBE DE LANCEY,,45.1978039012,5.91875544895,120,La,Combe-de-Lancey,La Combe-de-Lancey,38,Isère,84,Auvergne-Rhône-Alpes
38121,COMMELLE,38260,COMMELLE,,45.4350654984,5.23161920864,121,,Commelle,Commelle,38,Isère,84,Auvergne-Rhône-Alpes
38124,CORBELIN,38630,CORBELIN,,45.6024192088,5.55271499318,124,,Corbelin,Corbelin,38,Isère,84,Auvergne-Rhône-Alpes
38125,CORDEAC,38710,CORDEAC,,44.813561824,5.84430938337,125,,Cordéac,Cordéac,38,Isère,84,Auvergne-Rhône-Alpes
38126,CORENC,38700,CORENC,,45.2267664587,5.75814470454,126,,Corenc,Corenc,38,Isère,84,Auvergne-Rhône-Alpes
38127,CORNILLON EN TRIEVES,38710,CORNILLON EN TRIEVES,,44.8352198682,5.70360682162,127,,Cornillon-en-Trièves,Cornillon-en-Trièves,38,Isère,84,Auvergne-Rhône-Alpes
38128,CORPS,38970,CORPS,,44.8199101322,5.94147206383,128,,Corps,Corps,38,Isère,84,Auvergne-Rhône-Alpes
38129,CORRENCON EN VERCORS,38250,CORRENCON EN VERCORS,,45.0115359906,5.51775245188,129,,Corrençon-en-Vercors,Corrençon-en-Vercors,38,Isère,84,Auvergne-Rhône-Alpes
38130,LA COTE ST ANDRE,38260,LA COTE ST ANDRE,,45.3834360946,5.26068376928,130,La,Côte-Saint-André,La Côte-Saint-André,38,Isère,84,Auvergne-Rhône-Alpes
38131,LES COTES D AREY,38138,LES COTES D AREY,,45.4567699874,4.87918171813,131,Les,Côtes-d'Arey,Les Côtes-d'Arey,38,Isère,84,Auvergne-Rhône-Alpes
38132,LES COTES DE CORPS,38970,LES COTES DE CORPS,,44.8410265089,5.93082364878,132,Les,Côtes-de-Corps,Les Côtes-de-Corps,38,Isère,84,Auvergne-Rhône-Alpes
38133,COUBLEVIE,38500,COUBLEVIE,,45.3567707495,5.61793464006,133,,Coublevie,Coublevie,38,Isère,84,Auvergne-Rhône-Alpes
38134,COUR ET BUIS,38122,COUR ET BUIS,,45.4403886535,5.01870532467,134,,Cour-et-Buis,Cour-et-Buis,38,Isère,84,Auvergne-Rhône-Alpes
38135,COURTENAY,38510,COURTENAY,,45.7362411824,5.3922316771,135,,Courtenay,Courtenay,38,Isère,84,Auvergne-Rhône-Alpes
38136,CRACHIER,38300,CRACHIER,,45.546295569,5.22002027978,136,,Crachier,Crachier,38,Isère,84,Auvergne-Rhône-Alpes
38137,CRAS,38210,CRAS,,45.272146184,5.43899099093,137,,Cras,Cras,38,Isère,84,Auvergne-Rhône-Alpes
38138,CREMIEU,38460,CREMIEU,,45.7316395606,5.25746884592,138,,Crémieu,Crémieu,38,Isère,84,Auvergne-Rhône-Alpes
38139,CREYS MEPIEU,38510,CREYS MEPIEU,,45.7442083563,5.46517571315,139,,Creys-Mépieu,Creys-Mépieu,38,Isère,84,Auvergne-Rhône-Alpes
38139,CREYS MEPIEU,38510,CREYS MEPIEU,MEPIEU,45.7442083563,5.46517571315,139,,Creys-Mépieu,Creys-Mépieu,38,Isère,84,Auvergne-Rhône-Alpes
38139,CREYS MEPIEU,38510,CREYS MEPIEU,PUSIGNIEU,45.7442083563,5.46517571315,139,,Creys-Mépieu,Creys-Mépieu,38,Isère,84,Auvergne-Rhône-Alpes
38140,CROLLES,38920,CROLLES,,45.2820293287,5.88883985593,140,,Crolles,Crolles,38,Isère,84,Auvergne-Rhône-Alpes
38141,CULIN,38300,CULIN,,45.5238182422,5.24556322676,141,,Culin,Culin,38,Isère,84,Auvergne-Rhône-Alpes
38144,DIEMOZ,38790,DIEMOZ,,45.5886339429,5.08869481653,144,,Diémoz,Diémoz,38,Isère,84,Auvergne-Rhône-Alpes
38146,DIZIMIEU,38460,DIZIMIEU,,45.7132191545,5.28778124525,146,,Dizimieu,Dizimieu,38,Isère,84,Auvergne-Rhône-Alpes
38147,DOISSIN,38730,DOISSIN,,45.5015004907,5.42840010455,147,,Doissin,Doissin,38,Isère,84,Auvergne-Rhône-Alpes
38148,DOLOMIEU,38110,DOLOMIEU,,45.6153086109,5.48684166259,148,,Dolomieu,Dolomieu,38,Isère,84,Auvergne-Rhône-Alpes
38149,DOMARIN,38300,DOMARIN,,45.581670022,5.24542934804,149,,Domarin,Domarin,38,Isère,84,Auvergne-Rhône-Alpes
38150,DOMENE,38420,DOMENE,,45.2040386681,5.83829604568,150,,Domène,Domène,38,Isère,84,Auvergne-Rhône-Alpes
38151,ECHIROLLES,38130,ECHIROLLES,,45.1471647181,5.71535600177,151,,Échirolles,Échirolles,38,Isère,84,Auvergne-Rhône-Alpes
38152,ECLOSE BADINIERES,38300,ECLOSE BADINIERES,,45.4917400376,5.30263669678,152,,Eclose-Badinières,Eclose-Badinières,38,Isère,84,Auvergne-Rhône-Alpes
38152,ECLOSE BADINIERES,38300,ECLOSE BADINIERES,BADINIERES,45.4917400376,5.30263669678,152,,Eclose-Badinières,Eclose-Badinières,38,Isère,84,Auvergne-Rhône-Alpes
38153,ENGINS,38360,ENGINS,,45.1930404027,5.60939772759,153,,Engins,Engins,38,Isère,84,Auvergne-Rhône-Alpes
38154,ENTRAIGUES,38740,ENTRAIGUES,,44.8898827456,5.96388383994,154,,Entraigues,Entraigues,38,Isère,84,Auvergne-Rhône-Alpes
38155,ENTRE DEUX GUIERS,38380,ENTRE DEUX GUIERS,,45.4156254861,5.75636251084,155,,Entre-deux-Guiers,Entre-deux-Guiers,38,Isère,84,Auvergne-Rhône-Alpes
38156,LES EPARRES,38300,LES EPARRES,,45.5380944764,5.29246902351,156,Les,Éparres,Les Éparres,38,Isère,84,Auvergne-Rhône-Alpes
38157,ESTRABLIN,38780,ESTRABLIN,,45.5136075933,4.95889651862,157,,Estrablin,Estrablin,38,Isère,84,Auvergne-Rhône-Alpes
38158,EYBENS,38320,EYBENS,,45.1518093288,5.74847494189,158,,Eybens,Eybens,38,Isère,84,Auvergne-Rhône-Alpes
38159,EYDOCHE,38690,EYDOCHE,,45.4427304269,5.33251979279,159,,Eydoche,Eydoche,38,Isère,84,Auvergne-Rhône-Alpes
38160,EYZIN PINET,38780,EYZIN PINET,,45.4781067325,4.99820728602,160,,Eyzin-Pinet,Eyzin-Pinet,38,Isère,84,Auvergne-Rhône-Alpes
38161,FARAMANS,38260,FARAMANS,,45.3879958398,5.15388883556,161,,Faramans,Faramans,38,Isère,84,Auvergne-Rhône-Alpes
38162,FAVERGES DE LA TOUR,38110,FAVERGES DE LA TOUR,,45.5942581619,5.51385290349,162,,Faverges-de-la-Tour,Faverges-de-la-Tour,38,Isère,84,Auvergne-Rhône-Alpes
38163,LA FERRIERE,38580,LA FERRIERE,,45.2788909914,6.08520247302,163,La,Ferrière,La Ferrière,38,Isère,84,Auvergne-Rhône-Alpes
38163,LA FERRIERE,38580,LA FERRIERE,LE PLEYNET,45.2788909914,6.08520247302,163,La,Ferrière,La Ferrière,38,Isère,84,Auvergne-Rhône-Alpes
38166,LA FLACHERE,38530,LA FLACHERE,,45.3994779329,5.96151992776,166,La,Flachère,La Flachère,38,Isère,84,Auvergne-Rhône-Alpes
38167,FLACHERES,38690,FLACHERES,,45.4696097996,5.31533464507,167,,Flachères,Flachères,38,Isère,84,Auvergne-Rhône-Alpes
38169,FONTAINE,38600,FONTAINE,,45.1930502486,5.67763066542,169,,Fontaine,Fontaine,38,Isère,84,Auvergne-Rhône-Alpes
38170,FONTANIL CORNILLON,38120,FONTANIL CORNILLON,,45.2540718462,5.66423363739,170,,Fontanil-Cornillon,Fontanil-Cornillon,38,Isère,84,Auvergne-Rhône-Alpes
38171,LA FORTERESSE,38590,LA FORTERESSE,,45.2944764587,5.40801022079,171,La,Forteresse,La Forteresse,38,Isère,84,Auvergne-Rhône-Alpes
38172,FOUR,38080,FOUR,,45.5768084385,5.19447045153,172,,Four,Four,38,Isère,84,Auvergne-Rhône-Alpes
38173,LE FRENEY D OISANS,38142,LE FRENEY D OISANS,,45.0782566577,6.12240202229,173,Le,Freney-d'Oisans,Le Freney-d'Oisans,38,Isère,84,Auvergne-Rhône-Alpes
38174,LA FRETTE,38260,LA FRETTE,,45.387791522,5.36332803893,174,La,Frette,La Frette,38,Isère,84,Auvergne-Rhône-Alpes
38175,FROGES,38190,FROGES,,45.2651956551,5.92632272539,175,,Froges,Froges,38,Isère,84,Auvergne-Rhône-Alpes
38176,FRONTONAS,38290,FRONTONAS,,45.6511615964,5.18582056366,176,,Frontonas,Frontonas,38,Isère,84,Auvergne-Rhône-Alpes
38176,FRONTONAS,38290,FRONTONAS,GONAS,45.6511615964,5.18582056366,176,,Frontonas,Frontonas,38,Isère,84,Auvergne-Rhône-Alpes
38177,LA GARDE,38520,LA GARDE,,45.0680531923,6.05570708995,177,La,Garde,La Garde,38,Isère,84,Auvergne-Rhône-Alpes
38179,GIERES,38610,GIERES,,45.1823272189,5.79171566019,179,,Gières,Gières,38,Isère,84,Auvergne-Rhône-Alpes
38180,GILLONNAY,38260,GILLONNAY,,45.3855913986,5.29779946515,180,,Gillonnay,Gillonnay,38,Isère,84,Auvergne-Rhône-Alpes
38181,GONCELIN,38570,GONCELIN,,45.3363840613,5.98797386744,181,,Goncelin,Goncelin,38,Isère,84,Auvergne-Rhône-Alpes
38182,LE GRAND LEMPS,38690,LE GRAND LEMPS,,45.3962642718,5.4186427969,182,Le,Grand-Lemps,Le Grand-Lemps,38,Isère,84,Auvergne-Rhône-Alpes
38183,GRANIEU,38490,GRANIEU,,45.6003801471,5.58926385249,183,,Granieu,Granieu,38,Isère,84,Auvergne-Rhône-Alpes
38184,GRENAY,38540,GRENAY,,45.6618405786,5.07693625818,184,,Grenay,Grenay,38,Isère,84,Auvergne-Rhône-Alpes
38185,GRENOBLE,38000,GRENOBLE,,45.1821215167,5.72133051752,185,,Grenoble,Grenoble,38,Isère,84,Auvergne-Rhône-Alpes
38185,GRENOBLE,38100,GRENOBLE,,45.1821215167,5.72133051752,185,,Grenoble,Grenoble,38,Isère,84,Auvergne-Rhône-Alpes
38186,GRESSE EN VERCORS,38650,GRESSE EN VERCORS,,44.8864255639,5.525804416,186,,Gresse-en-Vercors,Gresse-en-Vercors,38,Isère,84,Auvergne-Rhône-Alpes
38187,LE GUA,38450,LE GUA,,45.0133395562,5.6155445833,187,Le,Gua,Le Gua,38,Isère,84,Auvergne-Rhône-Alpes
38188,HERBEYS,38320,HERBEYS,,45.1435049357,5.79740586224,188,,Herbeys,Herbeys,38,Isère,84,Auvergne-Rhône-Alpes
38189,HEYRIEUX,38540,HEYRIEUX,,45.6280667803,5.05921319142,189,,Heyrieux,Heyrieux,38,Isère,84,Auvergne-Rhône-Alpes
38190,HIERES SUR AMBY,38118,HIERES SUR AMBY,,45.7996652612,5.29431190301,190,,Hières-sur-Amby,Hières-sur-Amby,38,Isère,84,Auvergne-Rhône-Alpes
38191,HUEZ,38750,HUEZ,,45.0969425761,6.08474810987,191,,Huez,Huez,38,Isère,84,Auvergne-Rhône-Alpes
38191,HUEZ,38750,HUEZ,L ALPE D HUEZ,45.0969425761,6.08474810987,191,,Huez,Huez,38,Isère,84,Auvergne-Rhône-Alpes
38192,HURTIERES,38570,HURTIERES,,45.2869643084,5.96787163787,192,,Hurtières,Hurtières,38,Isère,84,Auvergne-Rhône-Alpes
38193,L ISLE D ABEAU,38080,L ISLE D ABEAU,,45.6181733164,5.22290423925,193,L',Isle-d'Abeau,L'Isle-d'Abeau,38,Isère,84,Auvergne-Rhône-Alpes
38194,IZEAUX,38140,IZEAUX,,45.3435240269,5.4297566361,194,,Izeaux,Izeaux,38,Isère,84,Auvergne-Rhône-Alpes
38195,IZERON,38160,IZERON,,45.141987566,5.39486640938,195,,Izeron,Izeron,38,Isère,84,Auvergne-Rhône-Alpes
38197,JANNEYRIAS,38280,JANNEYRIAS,,45.7512046013,5.11448312594,197,,Janneyrias,Janneyrias,38,Isère,84,Auvergne-Rhône-Alpes
38198,JARCIEU,38270,JARCIEU,,45.3353751556,4.94914192553,198,,Jarcieu,Jarcieu,38,Isère,84,Auvergne-Rhône-Alpes
38199,JARDIN,38200,JARDIN,,45.492806451,4.91094457963,199,,Jardin,Jardin,38,Isère,84,Auvergne-Rhône-Alpes
38200,JARRIE,38560,JARRIE,,45.1111027437,5.74674097991,200,,Jarrie,Jarrie,38,Isère,84,Auvergne-Rhône-Alpes
38203,LAFFREY,38220,LAFFREY,,45.0299394484,5.7822153115,203,,Laffrey,Laffrey,38,Isère,84,Auvergne-Rhône-Alpes
38204,LALLEY,38930,LALLEY,,44.74656983,5.68463742085,204,,Lalley,Lalley,38,Isère,84,Auvergne-Rhône-Alpes
38205,LANS EN VERCORS,38250,LANS EN VERCORS,,45.1209452513,5.5907415298,205,,Lans-en-Vercors,Lans-en-Vercors,38,Isère,84,Auvergne-Rhône-Alpes
38206,LAVAL,38190,LAVAL,,45.2359002175,5.98150477487,206,,Laval,Laval,38,Isère,84,Auvergne-Rhône-Alpes
38207,LAVALDENS,38350,LAVALDENS,,44.9921098746,5.90329398345,207,,Lavaldens,Lavaldens,38,Isère,84,Auvergne-Rhône-Alpes
38208,LAVARS,38710,LAVARS,,44.8532022659,5.6791569867,208,,Lavars,Lavars,38,Isère,84,Auvergne-Rhône-Alpes
38209,LENTIOL,38270,LENTIOL,,45.2966624696,5.10443556596,209,,Lentiol,Lentiol,38,Isère,84,Auvergne-Rhône-Alpes
38210,LEYRIEU,38460,LEYRIEU,,45.7563040682,5.25503498481,210,,Leyrieu,Leyrieu,38,Isère,84,Auvergne-Rhône-Alpes
38211,LIEUDIEU,38440,LIEUDIEU,,45.4577150175,5.17899618537,211,,Lieudieu,Lieudieu,38,Isère,84,Auvergne-Rhône-Alpes
38212,LIVET ET GAVET,38220,LIVET ET GAVET,,45.0905954329,5.91904092614,212,,Livet-et-Gavet,Livet-et-Gavet,38,Isère,84,Auvergne-Rhône-Alpes
38212,LIVET ET GAVET,38220,LIVET ET GAVET,GAVET,45.0905954329,5.91904092614,212,,Livet-et-Gavet,Livet-et-Gavet,38,Isère,84,Auvergne-Rhône-Alpes
38212,LIVET ET GAVET,38220,LIVET ET GAVET,LIVET,45.0905954329,5.91904092614,212,,Livet-et-Gavet,Livet-et-Gavet,38,Isère,84,Auvergne-Rhône-Alpes
38212,LIVET ET GAVET,38220,LIVET ET GAVET,RIOUPEROUX,45.0905954329,5.91904092614,212,,Livet-et-Gavet,Livet-et-Gavet,38,Isère,84,Auvergne-Rhône-Alpes
38213,LONGECHENAL,38690,LONGECHENAL,,45.4228948785,5.3603298976,213,,Longechenal,Longechenal,38,Isère,84,Auvergne-Rhône-Alpes
38214,LUMBIN,38660,LUMBIN,,45.3040708213,5.91320283012,214,,Lumbin,Lumbin,38,Isère,84,Auvergne-Rhône-Alpes
38215,LUZINAY,38200,LUZINAY,,45.5897867329,4.95688934298,215,,Luzinay,Luzinay,38,Isère,84,Auvergne-Rhône-Alpes
38216,MALLEVAL EN VERCORS,38470,MALLEVAL EN VERCORS,,45.1534944618,5.44421168332,216,,Malleval-en-Vercors,Malleval-en-Vercors,38,Isère,84,Auvergne-Rhône-Alpes
38217,MARCIEU,38350,MARCIEU,,44.9175217316,5.69553334662,217,,Marcieu,Marcieu,38,Isère,84,Auvergne-Rhône-Alpes
38218,MARCILLOLES,38260,MARCILLOLES,,45.3465047773,5.17565086259,218,,Marcilloles,Marcilloles,38,Isère,84,Auvergne-Rhône-Alpes
38219,MARCOLLIN,38270,MARCOLLIN,,45.3104247313,5.08838951033,219,,Marcollin,Marcollin,38,Isère,84,Auvergne-Rhône-Alpes
38221,MARNANS,38980,MARNANS,,45.2892320026,5.24958974003,221,,Marnans,Marnans,38,Isère,84,Auvergne-Rhône-Alpes
38222,MASSIEU,38620,MASSIEU,,45.4374299246,5.59982037311,222,,Massieu,Massieu,38,Isère,84,Auvergne-Rhône-Alpes
38223,MAUBEC,38300,MAUBEC,,45.5660929436,5.25805770086,223,,Maubec,Maubec,38,Isère,84,Auvergne-Rhône-Alpes
38224,MAYRES SAVEL,38350,MAYRES SAVEL,,44.8864456242,5.71096631054,224,,Mayres-Savel,Mayres-Savel,38,Isère,84,Auvergne-Rhône-Alpes
38225,AUTRANS MEAUDRE EN VERCORS,38112,AUTRANS MEAUDRE EN VERCORS,,45.1321769623,5.52869303726,225,,Autrans-Méaudre en Vercors,Autrans-Méaudre en Vercors,38,Isère,84,Auvergne-Rhône-Alpes
38225,AUTRANS MEAUDRE EN VERCORS,38880,AUTRANS MEAUDRE EN VERCORS,AUTRANS,45.1321769623,5.52869303726,225,,Autrans-Méaudre en Vercors,Autrans-Méaudre en Vercors,38,Isère,84,Auvergne-Rhône-Alpes
38226,MENS,38710,MENS,,44.8147716057,5.75218343717,226,,Mens,Mens,38,Isère,84,Auvergne-Rhône-Alpes
38226,MENS,38710,MENS,ST GENIS,44.8147716057,5.75218343717,226,,Mens,Mens,38,Isère,84,Auvergne-Rhône-Alpes
38228,MERLAS,38620,MERLAS,,45.4393958201,5.65883758315,228,,Merlas,Merlas,38,Isère,84,Auvergne-Rhône-Alpes
38229,MEYLAN,38240,MEYLAN,,45.2123567232,5.78354910277,229,,Meylan,Meylan,38,Isère,84,Auvergne-Rhône-Alpes
38230,MEYRIE,38300,MEYRIE,,45.5602461635,5.2848381306,230,,Meyrié,Meyrié,38,Isère,84,Auvergne-Rhône-Alpes
38231,MEYRIEU LES ETANGS,38440,MEYRIEU LES ETANGS,,45.5155327339,5.19856670609,231,,Meyrieu-les-Étangs,Meyrieu-les-Étangs,38,Isère,84,Auvergne-Rhône-Alpes
38232,MEYSSIEZ,38440,MEYSSIEZ,,45.4623251025,5.06118662132,232,,Meyssiez,Meyssiez,38,Isère,84,Auvergne-Rhône-Alpes
38235,MIRIBEL LANCHATRE,38450,MIRIBEL LANCHATRE,,44.973161587,5.61005061532,235,,Miribel-Lanchâtre,Miribel-Lanchâtre,38,Isère,84,Auvergne-Rhône-Alpes
38236,MIRIBEL LES ECHELLES,38380,MIRIBEL LES ECHELLES,,45.4371806956,5.70815670688,236,,Miribel-les-Échelles,Miribel-les-Échelles,38,Isère,84,Auvergne-Rhône-Alpes
38237,MIZOEN,38142,MIZOEN,,45.0437665644,6.1821306394,237,,Mizoën,Mizoën,38,Isère,84,Auvergne-Rhône-Alpes
38238,MOIDIEU DETOURBE,38440,MOIDIEU DETOURBE,,45.5125997972,5.02180537959,238,,Moidieu-Détourbe,Moidieu-Détourbe,38,Isère,84,Auvergne-Rhône-Alpes
38239,MOIRANS,38430,MOIRANS,,45.3189486892,5.56212081976,239,,Moirans,Moirans,38,Isère,84,Auvergne-Rhône-Alpes
38240,MOISSIEU SUR DOLON,38270,MOISSIEU SUR DOLON,,45.3908938983,4.98729399155,240,,Moissieu-sur-Dolon,Moissieu-sur-Dolon,38,Isère,84,Auvergne-Rhône-Alpes
38241,MONESTIER D AMBEL,38970,MONESTIER D AMBEL,,44.7690427693,5.92456495865,241,,Monestier-d'Ambel,Monestier-d'Ambel,38,Isère,84,Auvergne-Rhône-Alpes
38242,MONESTIER DE CLERMONT,38650,MONESTIER DE CLERMONT,,44.9212938968,5.63637364417,242,,Monestier-de-Clermont,Monestier-de-Clermont,38,Isère,84,Auvergne-Rhône-Alpes
38243,LE MONESTIER DU PERCY,38930,LE MONESTIER DU PERCY,,44.7844141969,5.64878046907,243,Le,Monestier-du-Percy,Le Monestier-du-Percy,38,Isère,84,Auvergne-Rhône-Alpes
38244,MONSTEROUX MILIEU,38122,MONSTEROUX MILIEU,,45.4346067842,4.946596799,244,,Monsteroux-Milieu,Monsteroux-Milieu,38,Isère,84,Auvergne-Rhône-Alpes
38245,MONTAGNE,38160,MONTAGNE,,45.1427039465,5.20042343042,245,,Montagne,Montagne,38,Isère,84,Auvergne-Rhône-Alpes
38246,MONTAGNIEU,38110,MONTAGNIEU,,45.5264598257,5.45129139723,246,,Montagnieu,Montagnieu,38,Isère,84,Auvergne-Rhône-Alpes
38247,MONTALIEU VERCIEU,38390,MONTALIEU VERCIEU,,45.8117809573,5.40305590073,247,,Montalieu-Vercieu,Montalieu-Vercieu,38,Isère,84,Auvergne-Rhône-Alpes
38248,MONTAUD,38210,MONTAUD,,45.2598175642,5.5618750133,248,,Montaud,Montaud,38,Isère,84,Auvergne-Rhône-Alpes
38249,MONTBONNOT ST MARTIN,38330,MONTBONNOT ST MARTIN,,45.2214678068,5.81601552801,249,,Montbonnot-Saint-Martin,Montbonnot-Saint-Martin,38,Isère,84,Auvergne-Rhône-Alpes
38250,MONTCARRA,38890,MONTCARRA,,45.6090751689,5.39984266254,250,,Montcarra,Montcarra,38,Isère,84,Auvergne-Rhône-Alpes
38252,MONTCHABOUD,38220,MONTCHABOUD,,45.0928956374,5.76098049287,252,,Montchaboud,Montchaboud,38,Isère,84,Auvergne-Rhône-Alpes
38253,MONT DE LANS,38860,MONT DE LANS,,45.0159885994,6.13818619624,253,Les,Deux Alpes,Les Deux Alpes,38,Isère,84,Auvergne-Rhône-Alpes
38253,MONT DE LANS,38860,MONT DE LANS,L ALPE DE MONT DE LANS,45.0159885994,6.13818619624,253,Les,Deux Alpes,Les Deux Alpes,38,Isère,84,Auvergne-Rhône-Alpes
38254,MONTEYNARD,38770,MONTEYNARD,,44.9804441631,5.70223795368,254,,Monteynard,Monteynard,38,Isère,84,Auvergne-Rhône-Alpes
38255,MONTFALCON,38940,MONTFALCON,,45.2603695756,5.17123092674,255,,Montfalcon,Montfalcon,38,Isère,84,Auvergne-Rhône-Alpes
38256,MONTFERRAT,38620,MONTFERRAT,,45.4745293334,5.57778075316,256,,Montferrat,Montferrat,38,Isère,84,Auvergne-Rhône-Alpes
38257,MONTREVEL,38690,MONTREVEL,,45.4771327346,5.40739444993,257,,Montrevel,Montrevel,38,Isère,84,Auvergne-Rhône-Alpes
38258,MONT ST MARTIN,38120,MONT ST MARTIN,,45.2791662403,5.68372205186,258,,Mont-Saint-Martin,Mont-Saint-Martin,38,Isère,84,Auvergne-Rhône-Alpes
38259,MONTSEVEROUX,38122,MONTSEVEROUX,,45.4298742303,4.97438632901,259,,Montseveroux,Montseveroux,38,Isère,84,Auvergne-Rhône-Alpes
38260,MORAS,38460,MORAS,,45.6869533334,5.26058834706,260,,Moras,Moras,38,Isère,84,Auvergne-Rhône-Alpes
38261,MORESTEL,38510,MORESTEL,,45.6800024667,5.47063929574,261,,Morestel,Morestel,38,Isère,84,Auvergne-Rhône-Alpes
38263,MORETTE,38210,MORETTE,,45.2849341045,5.45116993516,263,,Morette,Morette,38,Isère,84,Auvergne-Rhône-Alpes
38264,LA MORTE,38350,LA MORTE,,45.0261280024,5.8619002551,264,La,Morte,La Morte,38,Isère,84,Auvergne-Rhône-Alpes
38265,LA MOTTE D AVEILLANS,38770,LA MOTTE D AVEILLANS,,44.9538042858,5.74061628368,265,La,Motte-d'Aveillans,La Motte-d'Aveillans,38,Isère,84,Auvergne-Rhône-Alpes
38266,LA MOTTE ST MARTIN,38770,LA MOTTE ST MARTIN,,44.9463782517,5.71905629,266,La,Motte-Saint-Martin,La Motte-Saint-Martin,38,Isère,84,Auvergne-Rhône-Alpes
38267,MOTTIER,38260,LE MOTTIER,,45.4232022991,5.30860721755,267,,Mottier,Mottier,38,Isère,84,Auvergne-Rhône-Alpes
38268,LE MOUTARET,38580,LE MOUTARET,,45.429092051,6.08168721021,268,Le,Moutaret,Le Moutaret,38,Isère,84,Auvergne-Rhône-Alpes
38269,LA MURE,38350,LA MURE D ISERE,,44.9099172773,5.78817442937,269,La,Mure,La Mure,38,Isère,84,Auvergne-Rhône-Alpes
38270,LA MURETTE,38140,LA MURETTE,,45.3817551694,5.54168814307,270,La,Murette,La Murette,38,Isère,84,Auvergne-Rhône-Alpes
38271,MURIANETTE,38420,MURIANETTE,,45.1884497097,5.82573215499,271,,Murianette,Murianette,38,Isère,84,Auvergne-Rhône-Alpes
38272,MURINAIS,38160,MURINAIS,,45.2105311405,5.31922284315,272,,Murinais,Murinais,38,Isère,84,Auvergne-Rhône-Alpes
38273,NANTES EN RATIER,38350,NANTES EN RATIER,,44.9282499518,5.82804955801,273,,Nantes-en-Ratier,Nantes-en-Ratier,38,Isère,84,Auvergne-Rhône-Alpes
38274,NANTOIN,38260,NANTOIN,,45.4385673443,5.26623101453,274,,Nantoin,Nantoin,38,Isère,84,Auvergne-Rhône-Alpes
38275,SERRE NERPOL,38470,SERRE NERPOL,,45.2573268964,5.37164810608,275,,Serre-Nerpol,Serre-Nerpol,38,Isère,84,Auvergne-Rhône-Alpes
38276,NIVOLAS VERMELLE,38300,NIVOLAS VERMELLE,,45.5617249552,5.30687058323,276,,Nivolas-Vermelle,Nivolas-Vermelle,38,Isère,84,Auvergne-Rhône-Alpes
38277,NOTRE DAME DE COMMIERS,38450,NOTRE DAME DE COMMIERS,,45.009447432,5.70400358606,277,,Notre-Dame-de-Commiers,Notre-Dame-de-Commiers,38,Isère,84,Auvergne-Rhône-Alpes
38278,NOTRE DAME DE L OSIER,38470,NOTRE DAME DE L OSIER,,45.2381695946,5.41516438736,278,,Notre-Dame-de-l'Osier,Notre-Dame-de-l'Osier,38,Isère,84,Auvergne-Rhône-Alpes
38279,NOTRE DAME DE MESAGE,38220,NOTRE DAME DE MESAGE,,45.0706423659,5.75220646667,279,,Notre-Dame-de-Mésage,Notre-Dame-de-Mésage,38,Isère,84,Auvergne-Rhône-Alpes
38280,NOTRE DAME DE VAULX,38144,NOTRE DAME DE VAULX,,44.9909492934,5.7414042236,280,,Notre-Dame-de-Vaulx,Notre-Dame-de-Vaulx,38,Isère,84,Auvergne-Rhône-Alpes
38281,NOYAREY,38360,NOYAREY,,45.2413524185,5.61932305431,281,,Noyarey,Noyarey,38,Isère,84,Auvergne-Rhône-Alpes
38282,OPTEVOZ,38460,OPTEVOZ,,45.7524418147,5.34043707575,282,,Optevoz,Optevoz,38,Isère,84,Auvergne-Rhône-Alpes
38283,ORIS EN RATTIER,38350,ORIS EN RATTIER,,44.933416965,5.88526419405,283,,Oris-en-Rattier,Oris-en-Rattier,38,Isère,84,Auvergne-Rhône-Alpes
38284,ORNACIEUX,38260,ORNACIEUX,,45.4048662888,5.21296646579,284,,Ornacieux,Ornacieux,38,Isère,84,Auvergne-Rhône-Alpes
38285,ORNON,38520,ORNON,,45.0400989796,5.9653991775,285,,Ornon,Ornon,38,Isère,84,Auvergne-Rhône-Alpes
38286,OULLES,38520,OULLES,,45.0738320664,5.96330569901,286,,Oulles,Oulles,38,Isère,84,Auvergne-Rhône-Alpes
38287,OYEU,38690,OYEU,,45.4274505456,5.48004933768,287,,Oyeu,Oyeu,38,Isère,84,Auvergne-Rhône-Alpes
38288,OYTIER ST OBLAS,38780,OYTIER ST OBLAS,,45.5646344835,5.03134323358,288,,Oytier-Saint-Oblas,Oytier-Saint-Oblas,38,Isère,84,Auvergne-Rhône-Alpes
38289,OZ,38114,OZ,,45.1279422675,6.07379501436,289,,Oz,Oz,38,Isère,84,Auvergne-Rhône-Alpes
38290,PACT,38270,PACT,,45.3540175741,4.98747435997,290,,Pact,Pact,38,Isère,84,Auvergne-Rhône-Alpes
38291,PAJAY,38260,PAJAY,,45.3585074391,5.12972839303,291,,Pajay,Pajay,38,Isère,84,Auvergne-Rhône-Alpes
38292,PALADRU,38850,PALADRU,,45.4823189588,5.55281402751,292,,Villages du Lac de Paladru,Villages du Lac de Paladru,38,Isère,84,Auvergne-Rhône-Alpes
38293,PANISSAGE,38730,PANISSAGE,,45.4946543767,5.45913903616,293,,Panissage,Panissage,38,Isère,84,Auvergne-Rhône-Alpes
38294,PANOSSAS,38460,PANOSSAS,,45.6757588794,5.1929687888,294,,Panossas,Panossas,38,Isère,84,Auvergne-Rhône-Alpes
38295,PARMILIEU,38390,PARMILIEU,,45.8448442617,5.35457902828,295,,Parmilieu,Parmilieu,38,Isère,84,Auvergne-Rhône-Alpes
38296,LE PASSAGE,38490,LE PASSAGE,,45.5254700733,5.51478241706,296,Le,Passage,Le Passage,38,Isère,84,Auvergne-Rhône-Alpes
38297,PASSINS,38510,PASSINS,,45.6918000182,5.42912409085,297,,Arandon-Passins,Arandon-Passins,38,Isère,84,Auvergne-Rhône-Alpes
38298,LE PEAGE DE ROUSSILLON,38550,LE PEAGE DE ROUSSILLON,,45.3691585738,4.78421704937,298,Le,Péage-de-Roussillon,Le Péage-de-Roussillon,38,Isère,84,Auvergne-Rhône-Alpes
38299,PELLAFOL,38970,PELLAFOL,,44.7819380023,5.88025716303,299,,Pellafol,Pellafol,38,Isère,84,Auvergne-Rhône-Alpes
38300,PENOL,38260,PENOL,,45.3732511215,5.18172674953,300,,Penol,Penol,38,Isère,84,Auvergne-Rhône-Alpes
38301,PERCY,38930,LE PERCY,,44.7891162119,5.62604125349,301,,Percy,Percy,38,Isère,84,Auvergne-Rhône-Alpes
38302,LE PERIER,38740,LE PERIER,,44.9390832517,5.98306798155,302,Le,Périer,Le Périer,38,Isère,84,Auvergne-Rhône-Alpes
38303,LA PIERRE,38570,LA PIERRE,,45.2948585917,5.94144345331,303,La,Pierre,La Pierre,38,Isère,84,Auvergne-Rhône-Alpes
38304,PIERRE CHATEL,38119,PIERRE CHATEL,,44.9565648579,5.77432306035,304,,Pierre-Châtel,Pierre-Châtel,38,Isère,84,Auvergne-Rhône-Alpes
38305,LE PIN,38730,LE PIN,,45.4600883499,5.50714951081,305,Le,Pin,Le Pin,38,Isère,84,Auvergne-Rhône-Alpes
38306,PINSOT,38580,PINSOT,,45.3416677243,6.12577339395,306,,Pinsot,Pinsot,38,Isère,84,Auvergne-Rhône-Alpes
38307,PISIEU,38270,PISIEU,,45.3869511038,5.0738316923,307,,Pisieu,Pisieu,38,Isère,84,Auvergne-Rhône-Alpes
38308,PLAN,38590,PLAN,,45.3171353918,5.39238954816,308,,Plan,Plan,38,Isère,84,Auvergne-Rhône-Alpes
38309,POISAT,38320,POISAT,,45.1541844702,5.77015327234,309,,Poisat,Poisat,38,Isère,84,Auvergne-Rhône-Alpes
38310,POLIENAS,38210,POLIENAS,,45.2499943618,5.4800749963,310,,Poliénas,Poliénas,38,Isère,84,Auvergne-Rhône-Alpes
38311,POMMIER DE BEAUREPAIRE,38260,POMMIER DE BEAUREPAIRE,,45.3927239084,5.1081971977,311,,Pommier-de-Beaurepaire,Pommier-de-Beaurepaire,38,Isère,84,Auvergne-Rhône-Alpes
38312,POMMIERS LA PLACETTE,38340,POMMIERS LA PLACETTE,,45.3198621194,5.6786107199,312,,Pommiers-la-Placette,Pommiers-la-Placette,38,Isère,84,Auvergne-Rhône-Alpes
38313,PONSONNAS,38350,PONSONNAS,,44.8891344285,5.79776361706,313,,Ponsonnas,Ponsonnas,38,Isère,84,Auvergne-Rhône-Alpes
38314,PONTCHARRA,38530,PONTCHARRA,,45.4199322754,6.02051894838,314,,Pontcharra,Pontcharra,38,Isère,84,Auvergne-Rhône-Alpes
38315,LE PONT DE BEAUVOISIN,38480,LE PONT DE BEAUVOISIN,,45.5318785509,5.66166638514,315,Le,Pont-de-Beauvoisin,Le Pont-de-Beauvoisin,38,Isère,84,Auvergne-Rhône-Alpes
38316,PONT DE CHERUY,38230,PONT DE CHERUY,,45.7516810527,5.17314203619,316,,Pont-de-Chéruy,Pont-de-Chéruy,38,Isère,84,Auvergne-Rhône-Alpes
38317,LE PONT DE CLAIX,38800,LE PONT DE CLAIX,,45.1259127487,5.70193593188,317,Le,Pont-de-Claix,Le Pont-de-Claix,38,Isère,84,Auvergne-Rhône-Alpes
38318,PONT EVEQUE,38780,PONT EVEQUE,,45.5342814016,4.92402983198,318,,Pont-Évêque,Pont-Évêque,38,Isère,84,Auvergne-Rhône-Alpes
38319,PONT EN ROYANS,38680,PONT EN ROYANS,,45.0609236607,5.34591472176,319,,Pont-en-Royans,Pont-en-Royans,38,Isère,84,Auvergne-Rhône-Alpes
38320,PORCIEU AMBLAGNIEU,38390,PORCIEU AMBLAGNIEU,,45.8376455099,5.3925679772,320,,Porcieu-Amblagnieu,Porcieu-Amblagnieu,38,Isère,84,Auvergne-Rhône-Alpes
38321,PREBOIS,38710,PREBOIS,,44.7780001917,5.71068390088,321,,Prébois,Prébois,38,Isère,84,Auvergne-Rhône-Alpes
38322,PRESLES,38680,PRESLES,,45.0933071522,5.40540834704,322,,Presles,Presles,38,Isère,84,Auvergne-Rhône-Alpes
38323,PRESSINS,38480,PRESSINS,,45.5266905652,5.63237187242,323,,Pressins,Pressins,38,Isère,84,Auvergne-Rhône-Alpes
38324,PRIMARETTE,38270,PRIMARETTE,,45.4076896858,5.03351016146,324,,Primarette,Primarette,38,Isère,84,Auvergne-Rhône-Alpes
38325,PROVEYSIEUX,38120,PROVEYSIEUX,,45.2874156428,5.71502203927,325,,Proveysieux,Proveysieux,38,Isère,84,Auvergne-Rhône-Alpes
38326,PRUNIERES,38350,PRUNIERES,,44.8984790286,5.75662171369,326,,Prunières,Prunières,38,Isère,84,Auvergne-Rhône-Alpes
38328,QUAIX EN CHARTREUSE,38950,QUAIX EN CHARTREUSE,,45.2568837675,5.73045716055,328,,Quaix-en-Chartreuse,Quaix-en-Chartreuse,38,Isère,84,Auvergne-Rhône-Alpes
38329,QUET EN BEAUMONT,38970,QUET EN BEAUMONT,,44.8366691536,5.87794751381,329,,Quet-en-Beaumont,Quet-en-Beaumont,38,Isère,84,Auvergne-Rhône-Alpes
38330,QUINCIEU,38470,QUINCIEU,,45.2780969864,5.38636938666,330,,Quincieu,Quincieu,38,Isère,84,Auvergne-Rhône-Alpes
38331,REAUMONT,38140,REAUMONT,,45.363201362,5.52298554589,331,,Réaumont,Réaumont,38,Isère,84,Auvergne-Rhône-Alpes
38332,RENAGE,38140,RENAGE,,45.3315741246,5.49397401462,332,,Renage,Renage,38,Isère,84,Auvergne-Rhône-Alpes
38333,RENCUREL,38680,RENCUREL,,45.1183407921,5.47287803693,333,,Rencurel,Rencurel,38,Isère,84,Auvergne-Rhône-Alpes
38334,REVEL,38420,REVEL,,45.1681206613,5.90991149845,334,,Revel,Revel,38,Isère,84,Auvergne-Rhône-Alpes
38335,REVEL TOURDAN,38270,REVEL TOURDAN,,45.3706106048,5.03128950015,335,,Revel-Tourdan,Revel-Tourdan,38,Isère,84,Auvergne-Rhône-Alpes
38336,REVENTIN VAUGRIS,38121,REVENTIN VAUGRIS,,45.4796682069,4.84348243877,336,,Reventin-Vaugris,Reventin-Vaugris,38,Isère,84,Auvergne-Rhône-Alpes
38337,RIVES,38140,RIVES SUR FURE,,45.3608957046,5.48709691309,337,,Rives,Rives,38,Isère,84,Auvergne-Rhône-Alpes
38338,LA RIVIERE,38210,LA RIVIERE,,45.2240239365,5.51354806573,338,La,Rivière,La Rivière,38,Isère,84,Auvergne-Rhône-Alpes
38339,ROCHE,38090,ROCHE,,45.5742314322,5.15591367556,339,,Roche,Roche,38,Isère,84,Auvergne-Rhône-Alpes
38339,ROCHE,38090,ROCHE,BOIS DE ROCHE,45.5742314322,5.15591367556,339,,Roche,Roche,38,Isère,84,Auvergne-Rhône-Alpes
38340,LES ROCHES DE CONDRIEU,38370,LES ROCHES DE CONDRIEU,,45.4535022843,4.76433812277,340,Les,Roches-de-Condrieu,Les Roches-de-Condrieu,38,Isère,84,Auvergne-Rhône-Alpes
38341,ROCHETOIRIN,38110,ROCHETOIRIN,,45.587700171,5.41599250559,341,,Rochetoirin,Rochetoirin,38,Isère,84,Auvergne-Rhône-Alpes
38342,ROISSARD,38650,ROISSARD,,44.884456212,5.63550064457,342,,Roissard,Roissard,38,Isère,84,Auvergne-Rhône-Alpes
38343,ROMAGNIEU,38480,ROMAGNIEU,,45.5623995351,5.63966032442,343,,Romagnieu,Romagnieu,38,Isère,84,Auvergne-Rhône-Alpes
38344,ROUSSILLON,38150,ROUSSILLON,,45.3783247724,4.81820898221,344,,Roussillon,Roussillon,38,Isère,84,Auvergne-Rhône-Alpes
38345,ROVON,38470,ROVON,,45.1840323083,5.46489348229,345,,Rovon,Rovon,38,Isère,84,Auvergne-Rhône-Alpes
38346,ROYAS,38440,ROYAS,,45.5045955755,5.10102856136,346,,Royas,Royas,38,Isère,84,Auvergne-Rhône-Alpes
38347,ROYBON,38940,ROYBON,,45.248160347,5.24908854809,347,,Roybon,Roybon,38,Isère,84,Auvergne-Rhône-Alpes
38348,RUY,38300,RUY,,45.590349924,5.344319552,348,,Ruy-Montceau,Ruy-Montceau,38,Isère,84,Auvergne-Rhône-Alpes
38348,RUY,38300,RUY,MONTCEAU,45.590349924,5.344319552,348,,Ruy-Montceau,Ruy-Montceau,38,Isère,84,Auvergne-Rhône-Alpes
38349,SABLONS,38550,SABLONS,,45.3212970524,4.78538205618,349,,Sablons,Sablons,38,Isère,84,Auvergne-Rhône-Alpes
38350,STE AGNES,38190,STE AGNES,,45.2103354169,5.95984837057,350,,Sainte-Agnès,Sainte-Agnès,38,Isère,84,Auvergne-Rhône-Alpes
38351,ST AGNIN SUR BION,38300,ST AGNIN SUR BION,,45.5389105231,5.23930299059,351,,Saint-Agnin-sur-Bion,Saint-Agnin-sur-Bion,38,Isère,84,Auvergne-Rhône-Alpes
38352,ST ALBAN DE ROCHE,38080,ST ALBAN DE ROCHE,,45.5871624015,5.2245791574,352,,Saint-Alban-de-Roche,Saint-Alban-de-Roche,38,Isère,84,Auvergne-Rhône-Alpes
38353,ST ALBAN DU RHONE,38370,ST ALBAN DU RHONE,,45.4179171487,4.75568259683,353,,Saint-Alban-du-Rhône,Saint-Alban-du-Rhône,38,Isère,84,Auvergne-Rhône-Alpes
38354,ST ALBIN DE VAULSERRE,38480,ST ALBIN DE VAULSERRE,,45.5014621021,5.69777354102,354,,Saint-Albin-de-Vaulserre,Saint-Albin-de-Vaulserre,38,Isère,84,Auvergne-Rhône-Alpes
38355,ST ANDEOL,38650,ST ANDEOL,,44.9498407462,5.51699741811,355,,Saint-Andéol,Saint-Andéol,38,Isère,84,Auvergne-Rhône-Alpes
38356,ST ANDRE EN ROYANS,38680,ST ANDRE EN ROYANS,,45.0832051163,5.33670560585,356,,Saint-André-en-Royans,Saint-André-en-Royans,38,Isère,84,Auvergne-Rhône-Alpes
38357,ST ANDRE LE GAZ,38490,ST ANDRE LE GAZ,,45.5473825383,5.53186055306,357,,Saint-André-le-Gaz,Saint-André-le-Gaz,38,Isère,84,Auvergne-Rhône-Alpes
38358,STE ANNE SUR GERVONDE,38440,STE ANNE SUR GERVONDE,,45.4997686454,5.23217277414,358,,Sainte-Anne-sur-Gervonde,Sainte-Anne-sur-Gervonde,38,Isère,84,Auvergne-Rhône-Alpes
38359,ST ANTOINE L ABBAYE,38160,ST ANTOINE L ABBAYE,,45.1722353559,5.21609050981,359,,Saint Antoine l'Abbaye,Saint Antoine l'Abbaye,38,Isère,84,Auvergne-Rhône-Alpes
38359,ST ANTOINE L ABBAYE,38160,ST ANTOINE L ABBAYE,DIONAY,45.1722353559,5.21609050981,359,,Saint Antoine l'Abbaye,Saint Antoine l'Abbaye,38,Isère,84,Auvergne-Rhône-Alpes
38360,ST APPOLINARD,38160,ST APPOLINARD,,45.1840589989,5.26050119803,360,,Saint-Appolinard,Saint-Appolinard,38,Isère,84,Auvergne-Rhône-Alpes
38361,ST AREY,38350,ST AREY,,44.8819813908,5.74254288729,361,,Saint-Arey,Saint-Arey,38,Isère,84,Auvergne-Rhône-Alpes
38362,ST AUPRE,38960,ST AUPRE,,45.4060612976,5.66175249107,362,,Saint-Aupre,Saint-Aupre,38,Isère,84,Auvergne-Rhône-Alpes
38363,ST BARTHELEMY,38270,ST BARTHELEMY DE BEAUREPAIRE,,45.3413935073,5.08049664145,363,,Saint-Barthélemy,Saint-Barthélemy,38,Isère,84,Auvergne-Rhône-Alpes
38364,ST BARTHELEMY DE SECHILIENNE,38220,ST BARTHELEMY DE SECHILIENNE,,45.0394014233,5.82200744978,364,,Saint-Barthélemy-de-Séchilienne,Saint-Barthélemy-de-Séchilienne,38,Isère,84,Auvergne-Rhône-Alpes
38365,ST BAUDILLE DE LA TOUR,38118,ST BAUDILLE DE LA TOUR,,45.7865412343,5.33779713984,365,,Saint-Baudille-de-la-Tour,Saint-Baudille-de-la-Tour,38,Isère,84,Auvergne-Rhône-Alpes
38366,ST BAUDILLE ET PIPET,38710,ST BAUDILLE ET PIPET,,44.7829562969,5.77824884395,366,,Saint-Baudille-et-Pipet,Saint-Baudille-et-Pipet,38,Isère,84,Auvergne-Rhône-Alpes
38367,ST BERNARD,38660,ST BERNARD DU TOUVET,,45.3540745049,5.89883217678,367,,Saint-Bernard,Saint-Bernard,38,Isère,84,Auvergne-Rhône-Alpes
38368,ST BLAISE DU BUIS,38140,ST BLAISE DU BUIS,,45.3803941059,5.51274680694,368,,Saint-Blaise-du-Buis,Saint-Blaise-du-Buis,38,Isère,84,Auvergne-Rhône-Alpes
38369,STE BLANDINE,38110,STE BLANDINE,,45.5450974508,5.43494721601,369,,Sainte-Blandine,Sainte-Blandine,38,Isère,84,Auvergne-Rhône-Alpes
38370,ST BONNET DE CHAVAGNE,38840,ST BONNET DE CHAVAGNE,,45.1216780854,5.22815557766,370,,Saint-Bonnet-de-Chavagne,Saint-Bonnet-de-Chavagne,38,Isère,84,Auvergne-Rhône-Alpes
38372,ST BUEIL,38620,ST BUEIL,,45.473709028,5.68057298861,372,,Saint-Bueil,Saint-Bueil,38,Isère,84,Auvergne-Rhône-Alpes
38373,ST CASSIEN,38500,ST CASSIEN,,45.3609994105,5.5511979426,373,,Saint-Cassien,Saint-Cassien,38,Isère,84,Auvergne-Rhône-Alpes
38374,ST CHEF,38890,ST CHEF,,45.6409450603,5.36204346539,374,,Saint-Chef,Saint-Chef,38,Isère,84,Auvergne-Rhône-Alpes
38375,ST CHRISTOPHE EN OISANS,38520,ST CHRISTOPHE EN OISANS,,44.9290846615,6.24454607842,375,,Saint-Christophe-en-Oisans,Saint-Christophe-en-Oisans,38,Isère,84,Auvergne-Rhône-Alpes
38376,ST CHRISTOPHE SUR GUIERS,38380,ST CHRISTOPHE SUR GUIERS,,45.4096520844,5.79306737331,376,,Saint-Christophe-sur-Guiers,Saint-Christophe-sur-Guiers,38,Isère,84,Auvergne-Rhône-Alpes
38377,ST CLAIR DE LA TOUR,38110,ST CLAIR DE LA TOUR,,45.5708091389,5.48838471268,377,,Saint-Clair-de-la-Tour,Saint-Clair-de-la-Tour,38,Isère,84,Auvergne-Rhône-Alpes
38378,ST CLAIR DU RHONE,38370,ST CLAIR DU RHONE,,45.4354138205,4.77287890453,378,,Saint-Clair-du-Rhône,Saint-Clair-du-Rhône,38,Isère,84,Auvergne-Rhône-Alpes
38379,ST CLAIR SUR GALAURE,38940,ST CLAIR SUR GALAURE,,45.2727108689,5.14759857127,379,,Saint-Clair-sur-Galaure,Saint-Clair-sur-Galaure,38,Isère,84,Auvergne-Rhône-Alpes
38380,ST DIDIER DE BIZONNES,38690,ST DIDIER DE BIZONNES,,45.4613188119,5.34474582164,380,,Saint-Didier-de-Bizonnes,Saint-Didier-de-Bizonnes,38,Isère,84,Auvergne-Rhône-Alpes
38381,ST DIDIER DE LA TOUR,38110,ST DIDIER DE LA TOUR,,45.5479526483,5.48734196729,381,,Saint-Didier-de-la-Tour,Saint-Didier-de-la-Tour,38,Isère,84,Auvergne-Rhône-Alpes
38382,ST EGREVE,38120,ST EGREVE,,45.2326617752,5.68410149189,382,,Saint-Égrève,Saint-Égrève,38,Isère,84,Auvergne-Rhône-Alpes
38383,ST ETIENNE DE CROSSEY,38960,ST ETIENNE DE CROSSEY,,45.3796872392,5.63667290374,383,,Saint-Étienne-de-Crossey,Saint-Étienne-de-Crossey,38,Isère,84,Auvergne-Rhône-Alpes
38384,ST ETIENNE DE ST GEOIRS,38590,ST ETIENNE DE ST GEOIRS,,45.3456023361,5.34838089834,384,,Saint-Étienne-de-Saint-Geoirs,Saint-Étienne-de-Saint-Geoirs,38,Isère,84,Auvergne-Rhône-Alpes
38386,ST GEOIRE EN VALDAINE,38620,ST GEOIRE EN VALDAINE,,45.4609404816,5.63629815728,386,,Saint-Geoire-en-Valdaine,Saint-Geoire-en-Valdaine,38,Isère,84,Auvergne-Rhône-Alpes
38387,ST GEOIRS,38590,ST GEOIRS,,45.3131912406,5.35408093497,387,,Saint-Geoirs,Saint-Geoirs,38,Isère,84,Auvergne-Rhône-Alpes
38388,ST GEORGES DE COMMIERS,38450,ST GEORGES DE COMMIERS,,45.0360297724,5.7185625705,388,,Saint-Georges-de-Commiers,Saint-Georges-de-Commiers,38,Isère,84,Auvergne-Rhône-Alpes
38389,ST GEORGES D ESPERANCHE,38790,ST GEORGES D ESPERANCHE,,45.5603583404,5.0798569447,389,,Saint-Georges-d'Espéranche,Saint-Georges-d'Espéranche,38,Isère,84,Auvergne-Rhône-Alpes
38389,ST GEORGES D ESPERANCHE,38790,ST GEORGES D ESPERANCHE,COMBE ROUSSE,45.5603583404,5.0798569447,389,,Saint-Georges-d'Espéranche,Saint-Georges-d'Espéranche,38,Isère,84,Auvergne-Rhône-Alpes
38390,ST GERVAIS,38470,ST GERVAIS,,45.1901934897,5.49780792819,390,,Saint-Gervais,Saint-Gervais,38,Isère,84,Auvergne-Rhône-Alpes
38391,ST GUILLAUME,38650,ST GUILLAUME,,44.9453659385,5.59084549964,391,,Saint-Guillaume,Saint-Guillaume,38,Isère,84,Auvergne-Rhône-Alpes
38392,ST HILAIRE DE BRENS,38460,ST HILAIRE DE BRENS,,45.6755777946,5.2906631807,392,,Saint-Hilaire-de-Brens,Saint-Hilaire-de-Brens,38,Isère,84,Auvergne-Rhône-Alpes
38393,ST HILAIRE DE LA COTE,38260,ST HILAIRE DE LA COTE,,45.389376572,5.32988018249,393,,Saint-Hilaire-de-la-Côte,Saint-Hilaire-de-la-Côte,38,Isère,84,Auvergne-Rhône-Alpes
38394,ST HILAIRE DU ROSIER,38840,ST HILAIRE DU ROSIER,,45.0907903238,5.25013852882,394,,Saint-Hilaire-du-Rosier,Saint-Hilaire-du-Rosier,38,Isère,84,Auvergne-Rhône-Alpes
38395,ST HILAIRE,38660,ST HILAIRE,,45.3128224472,5.87842750871,395,,Saint-Hilaire,Saint-Hilaire,38,Isère,84,Auvergne-Rhône-Alpes
38396,ST HONORE,38350,ST HONORE,,44.9580518735,5.82026145034,396,,Saint-Honoré,Saint-Honoré,38,Isère,84,Auvergne-Rhône-Alpes
38397,ST ISMIER,38330,ST ISMIER,,45.2519291498,5.82858729623,397,,Saint-Ismier,Saint-Ismier,38,Isère,84,Auvergne-Rhône-Alpes
38398,ST JEAN D AVELANNE,38480,ST JEAN D AVELANNE,,45.5079774289,5.66725503833,398,,Saint-Jean-d'Avelanne,Saint-Jean-d'Avelanne,38,Isère,84,Auvergne-Rhône-Alpes
38399,ST JEAN DE BOURNAY,38440,ST JEAN DE BOURNAY,,45.4982631773,5.14748470946,399,,Saint-Jean-de-Bournay,Saint-Jean-de-Bournay,38,Isère,84,Auvergne-Rhône-Alpes
38400,ST JEAN DE MOIRANS,38430,ST JEAN DE MOIRANS,,45.3369408381,5.58792748637,400,,Saint-Jean-de-Moirans,Saint-Jean-de-Moirans,38,Isère,84,Auvergne-Rhône-Alpes
38401,ST JEAN DE SOUDAIN,38110,ST JEAN DE SOUDAIN,,45.5852713494,5.43578432958,401,,Saint-Jean-de-Soudain,Saint-Jean-de-Soudain,38,Isère,84,Auvergne-Rhône-Alpes
38402,ST JEAN DE VAULX,38220,ST JEAN DE VAULX,,45.0171172339,5.75169663098,402,,Saint-Jean-de-Vaulx,Saint-Jean-de-Vaulx,38,Isère,84,Auvergne-Rhône-Alpes
38403,ST JEAN D HERANS,38710,ST JEAN D HERANS,,44.854865998,5.75330936496,403,,Saint-Jean-d'Hérans,Saint-Jean-d'Hérans,38,Isère,84,Auvergne-Rhône-Alpes
38404,ST JEAN LE VIEUX,38420,ST JEAN LE VIEUX,,45.2076677779,5.87763169081,404,,Saint-Jean-le-Vieux,Saint-Jean-le-Vieux,38,Isère,84,Auvergne-Rhône-Alpes
38405,ST JOSEPH DE RIVIERE,38134,ST JOSEPH DE RIVIERE,,45.3646525632,5.69102202537,405,,Saint-Joseph-de-Rivière,Saint-Joseph-de-Rivière,38,Isère,84,Auvergne-Rhône-Alpes
38406,ST JULIEN DE L HERMS,38122,ST JULIEN DE L HERMS,,45.4314158549,5.08605594007,406,,Saint-Julien-de-l'Herms,Saint-Julien-de-l'Herms,38,Isère,84,Auvergne-Rhône-Alpes
38407,ST JULIEN DE RAZ,38134,ST JULIEN DE RAZ,,45.34666149,5.66687556311,407,La,Sure en Chartreuse,La Sure en Chartreuse,38,Isère,84,Auvergne-Rhône-Alpes
38408,ST JUST CHALEYSSIN,38540,ST JUST CHALEYSSIN,,45.5866184586,5.00425101272,408,,Saint-Just-Chaleyssin,Saint-Just-Chaleyssin,38,Isère,84,Auvergne-Rhône-Alpes
38409,ST JUST DE CLAIX,38680,ST JUST DE CLAIX,,45.0770717878,5.27949580181,409,,Saint-Just-de-Claix,Saint-Just-de-Claix,38,Isère,84,Auvergne-Rhône-Alpes
38410,ST LATTIER,38840,ST LATTIER,,45.0933060303,5.18523637419,410,,Saint-Lattier,Saint-Lattier,38,Isère,84,Auvergne-Rhône-Alpes
38412,ST LAURENT DU PONT,38380,ST LAURENT DU PONT,,45.3801495125,5.73532117087,412,,Saint-Laurent-du-Pont,Saint-Laurent-du-Pont,38,Isère,84,Auvergne-Rhône-Alpes
38413,ST LAURENT EN BEAUMONT,38350,ST LAURENT EN BEAUMONT,,44.8850127628,5.84545502817,413,,Saint-Laurent-en-Beaumont,Saint-Laurent-en-Beaumont,38,Isère,84,Auvergne-Rhône-Alpes
38414,STE LUCE,38970,STE LUCE,,44.8504153429,5.90543846045,414,,Sainte-Luce,Sainte-Luce,38,Isère,84,Auvergne-Rhône-Alpes
38415,ST MARCEL BEL ACCUEIL,38080,ST MARCEL BEL ACCUEIL,,45.6480438859,5.23992920137,415,,Saint-Marcel-Bel-Accueil,Saint-Marcel-Bel-Accueil,38,Isère,84,Auvergne-Rhône-Alpes
38416,ST MARCELLIN,38160,ST MARCELLIN,,45.1534136901,5.31720171085,416,,Saint-Marcellin,Saint-Marcellin,38,Isère,84,Auvergne-Rhône-Alpes
38417,STE MARIE D ALLOIX,38660,STE MARIE D ALLOIX,,45.3845890951,5.97577742061,417,,Sainte-Marie-d'Alloix,Sainte-Marie-d'Alloix,38,Isère,84,Auvergne-Rhône-Alpes
38418,STE MARIE DU MONT,38660,STE MARIE DU MONT,,45.4062736697,5.93038202226,418,,Sainte-Marie-du-Mont,Sainte-Marie-du-Mont,38,Isère,84,Auvergne-Rhône-Alpes
38419,ST MARTIN DE CLELLES,38930,ST MARTIN DE CLELLES,,44.8467463314,5.60218937166,419,,Saint-Martin-de-Clelles,Saint-Martin-de-Clelles,38,Isère,84,Auvergne-Rhône-Alpes
38420,ST MARTIN DE VAULSERRE,38480,ST MARTIN DE VAULSERRE,,45.4927458548,5.68133755645,420,,Saint-Martin-de-Vaulserre,Saint-Martin-de-Vaulserre,38,Isère,84,Auvergne-Rhône-Alpes
38421,ST MARTIN D HERES,38400,ST MARTIN D HERES,,45.1762012553,5.76476360737,421,,Saint-Martin-d'Hères,Saint-Martin-d'Hères,38,Isère,84,Auvergne-Rhône-Alpes
38422,ST MARTIN D URIAGE,38410,ST MARTIN D URIAGE,,45.1533114965,5.85697188801,422,,Saint-Martin-d'Uriage,Saint-Martin-d'Uriage,38,Isère,84,Auvergne-Rhône-Alpes
38423,ST MARTIN LE VINOUX,38950,ST MARTIN LE VINOUX,,45.2194259388,5.71511762221,423,,Saint-Martin-le-Vinoux,Saint-Martin-le-Vinoux,38,Isère,84,Auvergne-Rhône-Alpes
38424,ST MAURICE EN TRIEVES,38930,ST MAURICE EN TRIEVES,,44.759901088,5.65305206004,424,,Saint-Maurice-en-Trièves,Saint-Maurice-en-Trièves,38,Isère,84,Auvergne-Rhône-Alpes
38425,ST MAURICE L EXIL,38550,ST MAURICE L EXIL,,45.3920113826,4.7770444785,425,,Saint-Maurice-l'Exil,Saint-Maurice-l'Exil,38,Isère,84,Auvergne-Rhône-Alpes
38426,ST MAXIMIN,38530,ST MAXIMIN,,45.4259637947,6.05007795734,426,,Saint-Maximin,Saint-Maximin,38,Isère,84,Auvergne-Rhône-Alpes
38427,ST MICHEL DE ST GEOIRS,38590,ST MICHEL DE ST GEOIRS,,45.3005100986,5.35914982472,427,,Saint-Michel-de-Saint-Geoirs,Saint-Michel-de-Saint-Geoirs,38,Isère,84,Auvergne-Rhône-Alpes
38428,ST MICHEL EN BEAUMONT,38350,ST MICHEL EN BEAUMONT,,44.8693565734,5.91329630908,428,,Saint-Michel-en-Beaumont,Saint-Michel-en-Beaumont,38,Isère,84,Auvergne-Rhône-Alpes
38429,ST MICHEL LES PORTES,38650,ST MICHEL LES PORTES,,44.8650406452,5.59014871863,429,,Saint-Michel-les-Portes,Saint-Michel-les-Portes,38,Isère,84,Auvergne-Rhône-Alpes
38430,ST MURY MONTEYMOND,38190,ST MURY MONTEYMOND,,45.2063585184,5.93278209691,430,,Saint-Mury-Monteymond,Saint-Mury-Monteymond,38,Isère,84,Auvergne-Rhône-Alpes
38431,ST NAZAIRE LES EYMES,38330,ST NAZAIRE LES EYMES,,45.2585764238,5.85231370954,431,,Saint-Nazaire-les-Eymes,Saint-Nazaire-les-Eymes,38,Isère,84,Auvergne-Rhône-Alpes
38432,ST NICOLAS DE MACHERIN,38500,ST NICOLAS DE MACHERIN,,45.4071927108,5.61586556764,432,,Saint-Nicolas-de-Macherin,Saint-Nicolas-de-Macherin,38,Isère,84,Auvergne-Rhône-Alpes
38433,ST NIZIER DU MOUCHEROTTE,38250,ST NIZIER DU MOUCHEROTTE,,45.1653613173,5.63185360757,433,,Saint-Nizier-du-Moucherotte,Saint-Nizier-du-Moucherotte,38,Isère,84,Auvergne-Rhône-Alpes
38434,ST ONDRAS,38490,ST ONDRAS,,45.5171531669,5.54742097432,434,,Saint-Ondras,Saint-Ondras,38,Isère,84,Auvergne-Rhône-Alpes
38435,ST PANCRASSE,38660,ST PANCRASSE,,45.2956716663,5.84889296712,435,,Saint-Pancrasse,Saint-Pancrasse,38,Isère,84,Auvergne-Rhône-Alpes
38436,ST PAUL DE VARCES,38760,ST PAUL DE VARCES,,45.0648676926,5.63101144999,436,,Saint-Paul-de-Varces,Saint-Paul-de-Varces,38,Isère,84,Auvergne-Rhône-Alpes
38437,ST PAUL D IZEAUX,38140,ST PAUL D IZEAUX,,45.31416401,5.43190717996,437,,Saint-Paul-d'Izeaux,Saint-Paul-d'Izeaux,38,Isère,84,Auvergne-Rhône-Alpes
38438,ST PAUL LES MONESTIER,38650,ST PAUL LES MONESTIER,,44.9274219036,5.61388149441,438,,Saint-Paul-lès-Monestier,Saint-Paul-lès-Monestier,38,Isère,84,Auvergne-Rhône-Alpes
38439,CRETS EN BELLEDONNE,38570,CRETS EN BELLEDONNE,MORETEL DE MAILLES,45.3573537268,6.0451681388,439,,Crêts en Belledonne,Crêts en Belledonne,38,Isère,84,Auvergne-Rhône-Alpes
38439,CRETS EN BELLEDONNE,38830,CRETS EN BELLEDONNE,ST PIERRE D ALLEVARD,45.3573537268,6.0451681388,439,,Crêts en Belledonne,Crêts en Belledonne,38,Isère,84,Auvergne-Rhône-Alpes
38440,ST PIERRE DE BRESSIEUX,38870,ST PIERRE DE BRESSIEUX,,45.301403987,5.29999858329,440,,Saint-Pierre-de-Bressieux,Saint-Pierre-de-Bressieux,38,Isère,84,Auvergne-Rhône-Alpes
38442,ST PIERRE DE CHARTREUSE,38380,ST PIERRE DE CHARTREUSE,,45.331212571,5.79772583855,442,,Saint-Pierre-de-Chartreuse,Saint-Pierre-de-Chartreuse,38,Isère,84,Auvergne-Rhône-Alpes
38443,ST PIERRE DE CHERENNES,38160,ST PIERRE DE CHERENNES,,45.1219175481,5.3858661597,443,,Saint-Pierre-de-Chérennes,Saint-Pierre-de-Chérennes,38,Isère,84,Auvergne-Rhône-Alpes
38444,ST PIERRE DE MEAROZ,38350,ST PIERRE DE MEAROZ,,44.8743730954,5.8195714854,444,,Saint-Pierre-de-Méaroz,Saint-Pierre-de-Méaroz,38,Isère,84,Auvergne-Rhône-Alpes
38445,ST PIERRE DE MESAGE,38220,ST PIERRE DE MESAGE,,45.048347708,5.76481736003,445,,Saint-Pierre-de-Mésage,Saint-Pierre-de-Mésage,38,Isère,84,Auvergne-Rhône-Alpes
38446,ST PIERRE D ENTREMONT,38380,ST PIERRE D ENTREMONT,,45.3856272189,5.8512057755,446,,Saint-Pierre-d'Entremont,Saint-Pierre-d'Entremont,38,Isère,84,Auvergne-Rhône-Alpes
38448,ST PRIM,38370,ST PRIM,,45.4453169317,4.80093261008,448,,Saint-Prim,Saint-Prim,38,Isère,84,Auvergne-Rhône-Alpes
38449,ST QUENTIN FALLAVIER,38070,ST QUENTIN FALLAVIER,,45.6396427762,5.10856784756,449,,Saint-Quentin-Fallavier,Saint-Quentin-Fallavier,38,Isère,84,Auvergne-Rhône-Alpes
38449,ST QUENTIN FALLAVIER,38070,ST QUENTIN FALLAVIER,FALLAVIER,45.6396427762,5.10856784756,449,,Saint-Quentin-Fallavier,Saint-Quentin-Fallavier,38,Isère,84,Auvergne-Rhône-Alpes
38450,ST QUENTIN SUR ISERE,38210,ST QUENTIN SUR ISERE,,45.277528088,5.54771829456,450,,Saint-Quentin-sur-Isère,Saint-Quentin-sur-Isère,38,Isère,84,Auvergne-Rhône-Alpes
38451,ST ROMAIN DE JALIONAS,38460,ST ROMAIN DE JALIONAS,,45.7520483819,5.22392059526,451,,Saint-Romain-de-Jalionas,Saint-Romain-de-Jalionas,38,Isère,84,Auvergne-Rhône-Alpes
38452,ST ROMAIN DE SURIEU,38150,ST ROMAIN DE SURIEU,,45.3898709024,4.88929060852,452,,Saint-Romain-de-Surieu,Saint-Romain-de-Surieu,38,Isère,84,Auvergne-Rhône-Alpes
38453,ST ROMANS,38160,ST ROMANS,,45.1052188553,5.31866035876,453,,Saint-Romans,Saint-Romans,38,Isère,84,Auvergne-Rhône-Alpes
38454,ST SAUVEUR,38160,ST SAUVEUR,,45.1455683904,5.34488906233,454,,Saint-Sauveur,Saint-Sauveur,38,Isère,84,Auvergne-Rhône-Alpes
38455,ST SAVIN,38300,ST SAVIN,,45.6254619795,5.32143071399,455,,Saint-Savin,Saint-Savin,38,Isère,84,Auvergne-Rhône-Alpes
38456,ST SEBASTIEN,38710,ST SEBASTIEN,,44.8487244503,5.80401345281,456,,Châtel-en-Trièves,Châtel-en-Trièves,38,Isère,84,Auvergne-Rhône-Alpes
38457,ST SIMEON DE BRESSIEUX,38870,ST SIMEON DE BRESSIEUX,,45.3258985851,5.26249818332,457,,Saint-Siméon-de-Bressieux,Saint-Siméon-de-Bressieux,38,Isère,84,Auvergne-Rhône-Alpes
38458,ST SORLIN DE MORESTEL,38510,ST SORLIN DE MORESTEL,,45.6330000577,5.47273562347,458,,Saint-Sorlin-de-Morestel,Saint-Sorlin-de-Morestel,38,Isère,84,Auvergne-Rhône-Alpes
38459,ST SORLIN DE VIENNE,38200,ST SORLIN DE VIENNE,,45.4732844152,4.93813857629,459,,Saint-Sorlin-de-Vienne,Saint-Sorlin-de-Vienne,38,Isère,84,Auvergne-Rhône-Alpes
38460,ST SULPICE DES RIVOIRES,38620,ST SULPICE DES RIVOIRES,,45.4674837996,5.6039470428,460,,Saint-Sulpice-des-Rivoires,Saint-Sulpice-des-Rivoires,38,Isère,84,Auvergne-Rhône-Alpes
38462,ST THEOFFREY,38119,ST THEOFFREY,,44.9910607379,5.77559158266,462,,Saint-Théoffrey,Saint-Théoffrey,38,Isère,84,Auvergne-Rhône-Alpes
38463,ST VERAND,38160,ST VERAND,,45.1823968662,5.34320073942,463,,Saint-Vérand,Saint-Vérand,38,Isère,84,Auvergne-Rhône-Alpes
38464,ST VICTOR DE CESSIEU,38110,ST VICTOR DE CESSIEU,,45.5400475666,5.38346631149,464,,Saint-Victor-de-Cessieu,Saint-Victor-de-Cessieu,38,Isère,84,Auvergne-Rhône-Alpes
38465,ST VICTOR DE MORESTEL,38510,ST VICTOR DE MORESTEL,,45.7025522178,5.49814056561,465,,Saint-Victor-de-Morestel,Saint-Victor-de-Morestel,38,Isère,84,Auvergne-Rhône-Alpes
38466,ST VINCENT DE MERCUZE,38660,ST VINCENT DE MERCUZE,,45.3749280893,5.95880834631,466,,Saint-Vincent-de-Mercuze,Saint-Vincent-de-Mercuze,38,Isère,84,Auvergne-Rhône-Alpes
38467,SALAGNON,38890,SALAGNON,,45.6684234149,5.35714106149,467,,Salagnon,Salagnon,38,Isère,84,Auvergne-Rhône-Alpes
38468,SALAISE SUR SANNE,38150,SALAISE SUR SANNE,,45.3442511828,4.80860532784,468,,Salaise-sur-Sanne,Salaise-sur-Sanne,38,Isère,84,Auvergne-Rhône-Alpes
38469,LA SALETTE FALLAVAUX,38970,LA SALETTE FALLAVAUX,,44.8433178683,5.98498987593,469,La,Salette-Fallavaux,La Salette-Fallavaux,38,Isère,84,Auvergne-Rhône-Alpes
38470,LA SALLE EN BEAUMONT,38350,LA SALLE EN BEAUMONT,,44.8600222062,5.86073436298,470,La,Salle-en-Beaumont,La Salle-en-Beaumont,38,Isère,84,Auvergne-Rhône-Alpes
38471,LE SAPPEY EN CHARTREUSE,38700,LE SAPPEY EN CHARTREUSE,,45.26521241,5.78391032229,471,Le,Sappey-en-Chartreuse,Le Sappey-en-Chartreuse,38,Isère,84,Auvergne-Rhône-Alpes
38472,SARCENAS,38700,SARCENAS,,45.2847568738,5.75992972544,472,,Sarcenas,Sarcenas,38,Isère,84,Auvergne-Rhône-Alpes
38473,SARDIEU,38260,SARDIEU,,45.3601004595,5.22079778885,473,,Sardieu,Sardieu,38,Isère,84,Auvergne-Rhône-Alpes
38474,SASSENAGE,38360,SASSENAGE,,45.2130962637,5.65242107292,474,,Sassenage,Sassenage,38,Isère,84,Auvergne-Rhône-Alpes
38475,SATOLAS ET BONCE,38290,SATOLAS ET BONCE,,45.682504844,5.12584106732,475,,Satolas-et-Bonce,Satolas-et-Bonce,38,Isère,84,Auvergne-Rhône-Alpes
38475,SATOLAS ET BONCE,38290,SATOLAS ET BONCE,BAS DE BONCE,45.682504844,5.12584106732,475,,Satolas-et-Bonce,Satolas-et-Bonce,38,Isère,84,Auvergne-Rhône-Alpes
38475,SATOLAS ET BONCE,38290,SATOLAS ET BONCE,BONCE,45.682504844,5.12584106732,475,,Satolas-et-Bonce,Satolas-et-Bonce,38,Isère,84,Auvergne-Rhône-Alpes
38475,SATOLAS ET BONCE,38290,SATOLAS ET BONCE,HAUT DE BONCE,45.682504844,5.12584106732,475,,Satolas-et-Bonce,Satolas-et-Bonce,38,Isère,84,Auvergne-Rhône-Alpes
38475,SATOLAS ET BONCE,38290,SATOLAS ET BONCE,LE CHAFFARD,45.682504844,5.12584106732,475,,Satolas-et-Bonce,Satolas-et-Bonce,38,Isère,84,Auvergne-Rhône-Alpes
38476,SAVAS MEPIN,38440,SAVAS MEPIN,,45.4968117369,5.06174427992,476,,Savas-Mépin,Savas-Mépin,38,Isère,84,Auvergne-Rhône-Alpes
38478,SECHILIENNE,38220,SECHILIENNE,,45.0726055114,5.83002564544,478,,Séchilienne,Séchilienne,38,Isère,84,Auvergne-Rhône-Alpes
38479,SEMONS,38260,SEMONS,,45.4317273834,5.19744019557,479,,Semons,Semons,38,Isère,84,Auvergne-Rhône-Alpes
38480,SEPTEME,38780,SEPTEME,,45.5481011598,4.98525636535,480,,Septème,Septème,38,Isère,84,Auvergne-Rhône-Alpes
38480,SEPTEME,38780,SEPTEME,LE PEAGE,45.5481011598,4.98525636535,480,,Septème,Septème,38,Isère,84,Auvergne-Rhône-Alpes
38480,SEPTEME,38780,SEPTEME,SOUS COTE,45.5481011598,4.98525636535,480,,Septème,Septème,38,Isère,84,Auvergne-Rhône-Alpes
38481,SEREZIN DE LA TOUR,38300,SEREZIN DE LA TOUR,,45.5546305415,5.34018745003,481,,Sérézin-de-la-Tour,Sérézin-de-la-Tour,38,Isère,84,Auvergne-Rhône-Alpes
38483,SERMERIEU,38510,SERMERIEU,,45.6665923233,5.40919117116,483,,Sermérieu,Sermérieu,38,Isère,84,Auvergne-Rhône-Alpes
38484,SERPAIZE,38200,SERPAIZE,,45.5576137521,4.91965362732,484,,Serpaize,Serpaize,38,Isère,84,Auvergne-Rhône-Alpes
38485,SEYSSINET PARISET,38170,SEYSSINET PARISET,,45.1731193462,5.66971556945,485,,Seyssinet-Pariset,Seyssinet-Pariset,38,Isère,84,Auvergne-Rhône-Alpes
38486,SEYSSINS,38180,SEYSSINS,,45.1548597914,5.67545398227,486,,Seyssins,Seyssins,38,Isère,84,Auvergne-Rhône-Alpes
38487,SEYSSUEL,38200,SEYSSUEL,,45.5601358269,4.84416709965,487,,Seyssuel,Seyssuel,38,Isère,84,Auvergne-Rhône-Alpes
38488,SICCIEU ST JULIEN ET CARISIEU,38460,SICCIEU ST JULIEN ET CARISIEU,,45.7304007028,5.31489253784,488,,Siccieu-Saint-Julien-et-Carisieu,Siccieu-Saint-Julien-et-Carisieu,38,Isère,84,Auvergne-Rhône-Alpes
38489,SIEVOZ,38350,SIEVOZ,,44.9050907619,5.85034623903,489,,Siévoz,Siévoz,38,Isère,84,Auvergne-Rhône-Alpes
38490,SILLANS,38590,SILLANS,,45.3482750894,5.39137219137,490,,Sillans,Sillans,38,Isère,84,Auvergne-Rhône-Alpes
38492,SINARD,38650,SINARD,,44.9430987654,5.65579105811,492,,Sinard,Sinard,38,Isère,84,Auvergne-Rhône-Alpes
38494,SOLEYMIEU,38460,SOLEYMIEU,,45.7033352747,5.35913663168,494,,Soleymieu,Soleymieu,38,Isère,84,Auvergne-Rhône-Alpes
38495,LA SONE,38840,LA SONE,,45.1166600305,5.28073341872,495,La,Sône,La Sône,38,Isère,84,Auvergne-Rhône-Alpes
38496,SONNAY,38150,SONNAY,,45.3566083614,4.91625352984,496,,Sonnay,Sonnay,38,Isère,84,Auvergne-Rhône-Alpes
38497,SOUSVILLE,38350,SOUSVILLE,,44.9098323482,5.80696662088,497,,Sousville,Sousville,38,Isère,84,Auvergne-Rhône-Alpes
38498,SUCCIEU,38300,SUCCIEU,,45.5295094349,5.34277529499,498,,Succieu,Succieu,38,Isère,84,Auvergne-Rhône-Alpes
38499,SUSVILLE,38350,SUSVILLE,,44.9261426887,5.76206988161,499,,Susville,Susville,38,Isère,84,Auvergne-Rhône-Alpes
38500,TECHE,38470,TECHE,,45.1776772771,5.37590743872,500,,Têche,Têche,38,Isère,84,Auvergne-Rhône-Alpes
38501,TENCIN,38570,TENCIN,,45.3111766526,5.95861963639,501,,Tencin,Tencin,38,Isère,84,Auvergne-Rhône-Alpes
38503,LA TERRASSE,38660,LA TERRASSE,,45.3253741747,5.93145053792,503,La,Terrasse,La Terrasse,38,Isère,84,Auvergne-Rhône-Alpes
38504,THEYS,38570,THEYS,,45.2975465976,6.01339575189,504,,Theys,Theys,38,Isère,84,Auvergne-Rhône-Alpes
38505,THODURE,38260,THODURE,,45.3158079255,5.15549854064,505,,Thodure,Thodure,38,Isère,84,Auvergne-Rhône-Alpes
38507,TIGNIEU JAMEYZIEU,38230,TIGNIEU JAMEYZIEU,,45.7344692085,5.18364277123,507,,Tignieu-Jameyzieu,Tignieu-Jameyzieu,38,Isère,84,Auvergne-Rhône-Alpes
38507,TIGNIEU JAMEYZIEU,38230,TIGNIEU JAMEYZIEU,JAMEYZIEU,45.7344692085,5.18364277123,507,,Tignieu-Jameyzieu,Tignieu-Jameyzieu,38,Isère,84,Auvergne-Rhône-Alpes
38508,TORCHEFELON,38690,TORCHEFELON,,45.5189293632,5.40282237683,508,,Torchefelon,Torchefelon,38,Isère,84,Auvergne-Rhône-Alpes
38509,LA TOUR DU PIN,38110,LA TOUR DU PIN,,45.5725876252,5.44527945798,509,La,Tour-du-Pin,La Tour-du-Pin,38,Isère,84,Auvergne-Rhône-Alpes
38511,LE TOUVET,38660,LE TOUVET,,45.3514959567,5.94615794112,511,Le,Touvet,Le Touvet,38,Isère,84,Auvergne-Rhône-Alpes
38512,TRAMOLE,38300,TRAMOLE,,45.5136127414,5.26771625978,512,,Tramolé,Tramolé,38,Isère,84,Auvergne-Rhône-Alpes
38513,TREFFORT,38650,TREFFORT,,44.9030153722,5.6617115745,513,,Treffort,Treffort,38,Isère,84,Auvergne-Rhône-Alpes
38514,TREMINIS,38710,TREMINIS,,44.7326500492,5.77205743161,514,,Tréminis,Tréminis,38,Isère,84,Auvergne-Rhône-Alpes
38515,TREPT,38460,TREPT,,45.6887973335,5.32417967781,515,,Trept,Trept,38,Isère,84,Auvergne-Rhône-Alpes
38516,LA TRONCHE,38700,LA TRONCHE,,45.2104409255,5.74343326974,516,La,Tronche,La Tronche,38,Isère,84,Auvergne-Rhône-Alpes
38517,TULLINS,38210,TULLINS,,45.2938791725,5.49006158623,517,,Tullins,Tullins,38,Isère,84,Auvergne-Rhône-Alpes
38517,TULLINS,38210,TULLINS,FURES,45.2938791725,5.49006158623,517,,Tullins,Tullins,38,Isère,84,Auvergne-Rhône-Alpes
38518,VALBONNAIS,38740,VALBONNAIS,,44.8979470948,5.9099695622,518,,Valbonnais,Valbonnais,38,Isère,84,Auvergne-Rhône-Alpes
38519,VALENCIN,38540,VALENCIN,,45.6076400854,5.02757104948,519,,Valencin,Valencin,38,Isère,84,Auvergne-Rhône-Alpes
38520,VALENCOGNE,38730,VALENCOGNE,,45.491469171,5.53064398602,520,,Valencogne,Valencogne,38,Isère,84,Auvergne-Rhône-Alpes
38521,LA VALETTE,38350,LA VALETTE,,44.9447156208,5.85272838136,521,La,Valette,La Valette,38,Isère,84,Auvergne-Rhône-Alpes
38522,VALJOUFFREY,38740,VALJOUFFREY,,44.8815349113,6.07950399615,522,,Valjouffrey,Valjouffrey,38,Isère,84,Auvergne-Rhône-Alpes
38523,VARACIEUX,38470,VARACIEUX,,45.2274015748,5.34211585345,523,,Varacieux,Varacieux,38,Isère,84,Auvergne-Rhône-Alpes
38524,VARCES ALLIERES ET RISSET,38760,VARCES ALLIERES ET RISSET,,45.0908638366,5.67685585853,524,,Varces-Allières-et-Risset,Varces-Allières-et-Risset,38,Isère,84,Auvergne-Rhône-Alpes
38525,VASSELIN,38890,VASSELIN,,45.6284390051,5.45056070487,525,,Vasselin,Vasselin,38,Isère,84,Auvergne-Rhône-Alpes
38526,VATILIEU,38470,VATILIEU,,45.2609367341,5.41140093645,526,,Vatilieu,Vatilieu,38,Isère,84,Auvergne-Rhône-Alpes
38527,VAUJANY,38114,VAUJANY,,45.1859501917,6.10152969645,527,,Vaujany,Vaujany,38,Isère,84,Auvergne-Rhône-Alpes
38528,VAULNAVEYS LE BAS,38410,VAULNAVEYS LE BAS,,45.0989005877,5.81149786551,528,,Vaulnaveys-le-Bas,Vaulnaveys-le-Bas,38,Isère,84,Auvergne-Rhône-Alpes
38529,VAULNAVEYS LE HAUT,38410,VAULNAVEYS LE HAUT,,45.1153983318,5.8400227882,529,,Vaulnaveys-le-Haut,Vaulnaveys-le-Haut,38,Isère,84,Auvergne-Rhône-Alpes
38529,VAULNAVEYS LE HAUT,38410,VAULNAVEYS LE HAUT,URIAGE,45.1153983318,5.8400227882,529,,Vaulnaveys-le-Haut,Vaulnaveys-le-Haut,38,Isère,84,Auvergne-Rhône-Alpes
38530,VAULX MILIEU,38090,VAULX MILIEU,,45.6155606962,5.18598476312,530,,Vaulx-Milieu,Vaulx-Milieu,38,Isère,84,Auvergne-Rhône-Alpes
38531,VELANNE,38620,VELANNE,,45.4889494528,5.64628578808,531,,Velanne,Velanne,38,Isère,84,Auvergne-Rhône-Alpes
38532,VENERIEU,38460,VENERIEU,,45.6596953757,5.27623741722,532,,Vénérieu,Vénérieu,38,Isère,84,Auvergne-Rhône-Alpes
38533,VENON,38610,VENON,,45.1733618225,5.81062446887,533,,Venon,Venon,38,Isère,84,Auvergne-Rhône-Alpes
38534,VENOSC,38520,VENOSC,,44.9729915456,6.10806519149,534,,Vénosc,Vénosc,38,Isère,84,Auvergne-Rhône-Alpes
38534,VENOSC,38860,VENOSC,L ALPE DE VENOSC,44.9729915456,6.10806519149,534,,Vénosc,Vénosc,38,Isère,84,Auvergne-Rhône-Alpes
38534,VENOSC,38860,VENOSC,LES DEUX ALPES,44.9729915456,6.10806519149,534,,Vénosc,Vénosc,38,Isère,84,Auvergne-Rhône-Alpes
38535,VERNAS,38460,VERNAS,,45.7770907345,5.26765483745,535,,Vernas,Vernas,38,Isère,84,Auvergne-Rhône-Alpes
38536,VERNIOZ,38150,VERNIOZ,,45.430347371,4.89879549763,536,,Vernioz,Vernioz,38,Isère,84,Auvergne-Rhône-Alpes
38537,LA VERPILLIERE,38290,LA VERPILLIERE,,45.637750025,5.14915516278,537,La,Verpillière,La Verpillière,38,Isère,84,Auvergne-Rhône-Alpes
38538,LE VERSOUD,38420,LE VERSOUD,,45.2221893714,5.85871984364,538,Le,Versoud,Le Versoud,38,Isère,84,Auvergne-Rhône-Alpes
38539,VERTRIEU,38390,VERTRIEU,,45.8703431976,5.36496686983,539,,Vertrieu,Vertrieu,38,Isère,84,Auvergne-Rhône-Alpes
38540,VEUREY VOROIZE,38113,VEUREY VOROIZE,,45.2694421656,5.60195085024,540,,Veurey-Voroize,Veurey-Voroize,38,Isère,84,Auvergne-Rhône-Alpes
38542,VEYSSILIEU,38460,VEYSSILIEU,,45.6805675587,5.22802915323,542,,Veyssilieu,Veyssilieu,38,Isère,84,Auvergne-Rhône-Alpes
38543,VEZERONCE CURTIN,38510,VEZERONCE CURTIN,,45.6543775384,5.46996277663,543,,Vézeronce-Curtin,Vézeronce-Curtin,38,Isère,84,Auvergne-Rhône-Alpes
38543,VEZERONCE CURTIN,38510,VEZERONCE CURTIN,CURTIN,45.6543775384,5.46996277663,543,,Vézeronce-Curtin,Vézeronce-Curtin,38,Isère,84,Auvergne-Rhône-Alpes
38544,VIENNE,38200,VIENNE,,45.520578372,4.88135156154,544,,Vienne,Vienne,38,Isère,84,Auvergne-Rhône-Alpes
38545,VIF,38450,VIF,,45.0430999483,5.67425076002,545,,Vif,Vif,38,Isère,84,Auvergne-Rhône-Alpes
38546,VIGNIEU,38890,VIGNIEU,,45.6317039689,5.42138365917,546,,Vignieu,Vignieu,38,Isère,84,Auvergne-Rhône-Alpes
38547,VILLARD BONNOT,38190,VILLARD BONNOT,,45.2446205727,5.88906725424,547,,Villard-Bonnot,Villard-Bonnot,38,Isère,84,Auvergne-Rhône-Alpes
38547,VILLARD BONNOT,38190,VILLARD BONNOT,BRIGNOUD,45.2446205727,5.88906725424,547,,Villard-Bonnot,Villard-Bonnot,38,Isère,84,Auvergne-Rhône-Alpes
38547,VILLARD BONNOT,38190,VILLARD BONNOT,LANCEY,45.2446205727,5.88906725424,547,,Villard-Bonnot,Villard-Bonnot,38,Isère,84,Auvergne-Rhône-Alpes
38548,VILLARD DE LANS,38250,VILLARD DE LANS,,45.0600847837,5.54420891959,548,,Villard-de-Lans,Villard-de-Lans,38,Isère,84,Auvergne-Rhône-Alpes
38549,VILLARD NOTRE DAME,38520,VILLARD NOTRE DAME,,45.008859137,6.03313652994,549,,Villard-Notre-Dame,Villard-Notre-Dame,38,Isère,84,Auvergne-Rhône-Alpes
38550,VILLARD RECULAS,38114,VILLARD RECULAS,,45.0936836388,6.0372345748,550,,Villard-Reculas,Villard-Reculas,38,Isère,84,Auvergne-Rhône-Alpes
38551,VILLARD REYMOND,38520,VILLARD REYMOND,,45.0290416543,6.00823716098,551,,Villard-Reymond,Villard-Reymond,38,Isère,84,Auvergne-Rhône-Alpes
38552,VILLARD ST CHRISTOPHE,38119,VILLARD ST CHRISTOPHE,,44.9860357514,5.81891639232,552,,Villard-Saint-Christophe,Villard-Saint-Christophe,38,Isère,84,Auvergne-Rhône-Alpes
38553,VILLEFONTAINE,38090,VILLEFONTAINE,,45.6136289454,5.15547673355,553,,Villefontaine,Villefontaine,38,Isère,84,Auvergne-Rhône-Alpes
38554,VILLEMOIRIEU,38460,VILLEMOIRIEU,,45.7139810956,5.23197765072,554,,Villemoirieu,Villemoirieu,38,Isère,84,Auvergne-Rhône-Alpes
38555,VILLENEUVE DE MARC,38440,VILLENEUVE DE MARC,,45.462997823,5.11493429723,555,,Villeneuve-de-Marc,Villeneuve-de-Marc,38,Isère,84,Auvergne-Rhône-Alpes
38556,VILLE SOUS ANJOU,38150,VILLE SOUS ANJOU,,45.3762742195,4.85882293241,556,,Ville-sous-Anjou,Ville-sous-Anjou,38,Isère,84,Auvergne-Rhône-Alpes
38557,VILLETTE D ANTHON,38280,VILLETTE D ANTHON,,45.7844347868,5.10742322844,557,,Villette-d'Anthon,Villette-d'Anthon,38,Isère,84,Auvergne-Rhône-Alpes
38557,VILLETTE D ANTHON,38280,VILLETTE D ANTHON,ASNIERES,45.7844347868,5.10742322844,557,,Villette-d'Anthon,Villette-d'Anthon,38,Isère,84,Auvergne-Rhône-Alpes
38557,VILLETTE D ANTHON,38280,VILLETTE D ANTHON,MONS,45.7844347868,5.10742322844,557,,Villette-d'Anthon,Villette-d'Anthon,38,Isère,84,Auvergne-Rhône-Alpes
38558,VILLETTE DE VIENNE,38200,VILLETTE DE VIENNE,,45.5891950037,4.91199377057,558,,Villette-de-Vienne,Villette-de-Vienne,38,Isère,84,Auvergne-Rhône-Alpes
38559,VINAY,38470,VINAY,,45.2147129367,5.40750863423,559,,Vinay,Vinay,38,Isère,84,Auvergne-Rhône-Alpes
38560,VIRIEU,38730,VIRIEU SUR BOURBRE,,45.4710107219,5.47722792273,560,,Virieu,Virieu,38,Isère,84,Auvergne-Rhône-Alpes
38561,VIRIVILLE,38980,VIRIVILLE,,45.3050296653,5.20262742852,561,,Viriville,Viriville,38,Isère,84,Auvergne-Rhône-Alpes
38562,VIZILLE,38220,VIZILLE,,45.075027515,5.77956710747,562,,Vizille,Vizille,38,Isère,84,Auvergne-Rhône-Alpes
38563,VOIRON,38500,VOIRON,,45.3791720843,5.58240310671,563,,Voiron,Voiron,38,Isère,84,Auvergne-Rhône-Alpes
38564,VOISSANT,38620,VOISSANT,,45.4793906375,5.70464271158,564,,Voissant,Voissant,38,Isère,84,Auvergne-Rhône-Alpes
38565,VOREPPE,38340,VOREPPE,,45.290046149,5.64284060664,565,,Voreppe,Voreppe,38,Isère,84,Auvergne-Rhône-Alpes
38566,VOUREY,38210,VOUREY,,45.3170286253,5.5223348378,566,,Vourey,Vourey,38,Isère,84,Auvergne-Rhône-Alpes
38567,CHAMROUSSE,38410,CHAMROUSSE,,45.1197427134,5.89438297795,567,,Chamrousse,Chamrousse,38,Isère,84,Auvergne-Rhône-Alpes
39001,ABERGEMENT LA RONCE,39500,ABERGEMENT LA RONCE,,47.0649669593,5.37826421743,1,,Abergement-la-Ronce,Abergement-la-Ronce,39,Jura,27,Bourgogne-Franche-Comté
39002,ABERGEMENT LE GRAND,39600,ABERGEMENT LE GRAND,,46.9142864988,5.67853452364,2,,Abergement-le-Grand,Abergement-le-Grand,39,Jura,27,Bourgogne-Franche-Comté
39003,ABERGEMENT LE PETIT,39800,ABERGEMENT LE PETIT,,46.906561832,5.69663602324,3,,Abergement-le-Petit,Abergement-le-Petit,39,Jura,27,Bourgogne-Franche-Comté
39004,ABERGEMENT LES THESY,39110,ABERGEMENT LES THESY,,46.9134980199,5.94569839295,4,,Abergement-lès-Thésy,Abergement-lès-Thésy,39,Jura,27,Bourgogne-Franche-Comté
39006,AIGLEPIERRE,39110,AIGLEPIERRE,,46.9553944509,5.80953794446,6,,Aiglepierre,Aiglepierre,39,Jura,27,Bourgogne-Franche-Comté
39007,ALIEZE,39270,ALIEZE,,46.5850122581,5.58578294146,7,,Alièze,Alièze,39,Jura,27,Bourgogne-Franche-Comté
39008,AMANGE,39700,AMANGE,,47.1669744984,5.5649107588,8,,Amange,Amange,39,Jura,27,Bourgogne-Franche-Comté
39009,ANDELOT EN MONTAGNE,39110,ANDELOT EN MONTAGNE,,46.8591094265,5.9289781242,9,,Andelot-en-Montagne,Andelot-en-Montagne,39,Jura,27,Bourgogne-Franche-Comté
39010,ANDELOT MORVAL,39320,ANDELOT MORVAL,,46.4237585921,5.42679898961,10,,Andelot-Morval,Andelot-Morval,39,Jura,27,Bourgogne-Franche-Comté
39010,ANDELOT MORVAL,39320,ANDELOT MORVAL,MORVAL,46.4237585921,5.42679898961,10,,Andelot-Morval,Andelot-Morval,39,Jura,27,Bourgogne-Franche-Comté
39011,ANNOIRE,39120,ANNOIRE,,46.9585671932,5.27702969888,11,,Annoire,Annoire,39,Jura,27,Bourgogne-Franche-Comté
39013,ARBOIS,39600,ARBOIS,,46.8941749828,5.77318253275,13,,Arbois,Arbois,39,Jura,27,Bourgogne-Franche-Comté
39014,ARCHELANGE,39290,ARCHELANGE,,47.1484873028,5.52058392702,14,,Archelange,Archelange,39,Jura,27,Bourgogne-Franche-Comté
39015,ARDON,39300,ARDON,,46.7729485271,5.87754754837,15,,Ardon,Ardon,39,Jura,27,Bourgogne-Franche-Comté
39016,ARINTHOD,39240,ARINTHOD,,46.4000913267,5.58894022336,16,,Arinthod,Arinthod,39,Jura,27,Bourgogne-Franche-Comté
39017,ARLAY,39140,ARLAY,,46.7695260312,5.52939356263,17,,Arlay,Arlay,39,Jura,27,Bourgogne-Franche-Comté
39017,ARLAY,39210,ARLAY,ST GERMAIN LES ARLAY,46.7695260312,5.52939356263,17,,Arlay,Arlay,39,Jura,27,Bourgogne-Franche-Comté
39018,AROMAS,39240,AROMAS,,46.2931394653,5.4882078411,18,,Aromas,Aromas,39,Jura,27,Bourgogne-Franche-Comté
39018,AROMAS,39240,AROMAS,CEFFIA,46.2931394653,5.4882078411,18,,Aromas,Aromas,39,Jura,27,Bourgogne-Franche-Comté
39019,LES ARSURES,39600,LES ARSURES,,46.953105661,5.78430690832,19,Les,Arsures,Les Arsures,39,Jura,27,Bourgogne-Franche-Comté
39020,ARSURE ARSURETTE,39250,ARSURE ARSURETTE,,46.7150975555,6.08022545368,20,,Arsure-Arsurette,Arsure-Arsurette,39,Jura,27,Bourgogne-Franche-Comté
39021,LA CHAILLEUSE,39270,LA CHAILLEUSE,ARTHENAS,46.5722801296,5.52869424012,21,La,Chailleuse,La Chailleuse,39,Jura,27,Bourgogne-Franche-Comté
39021,LA CHAILLEUSE,39270,LA CHAILLEUSE,ESSIA,46.5722801296,5.52869424012,21,La,Chailleuse,La Chailleuse,39,Jura,27,Bourgogne-Franche-Comté
39021,LA CHAILLEUSE,39270,LA CHAILLEUSE,VARESSIA,46.5722801296,5.52869424012,21,La,Chailleuse,La Chailleuse,39,Jura,27,Bourgogne-Franche-Comté
39021,LA CHAILLEUSE,39570,LA CHAILLEUSE,ST LAURENT LA ROCHE,46.5722801296,5.52869424012,21,La,Chailleuse,La Chailleuse,39,Jura,27,Bourgogne-Franche-Comté
39022,ASNANS BEAUVOISIN,39120,ASNANS BEAUVOISIN,,46.9370009894,5.39677070986,22,,Asnans-Beauvoisin,Asnans-Beauvoisin,39,Jura,27,Bourgogne-Franche-Comté
39022,ASNANS BEAUVOISIN,39120,ASNANS BEAUVOISIN,BEAUVOISIN,46.9370009894,5.39677070986,22,,Asnans-Beauvoisin,Asnans-Beauvoisin,39,Jura,27,Bourgogne-Franche-Comté
39024,AUDELANGE,39700,AUDELANGE,,47.14070332,5.58399367432,24,,Audelange,Audelange,39,Jura,27,Bourgogne-Franche-Comté
39025,AUGEA,39190,AUGEA,,46.5620792374,5.38938272802,25,,Augea,Augea,39,Jura,27,Bourgogne-Franche-Comté
39026,AUGERANS,39380,AUGERANS,,47.026326647,5.5838585111,26,,Augerans,Augerans,39,Jura,27,Bourgogne-Franche-Comté
39027,AUGISEY,39270,AUGISEY,,46.5548389482,5.49566271046,27,,Augisey,Augisey,39,Jura,27,Bourgogne-Franche-Comté
39028,AUMONT,39800,AUMONT,,46.9161293357,5.63272592575,28,,Aumont,Aumont,39,Jura,27,Bourgogne-Franche-Comté
39029,AUMUR,39410,AUMUR,,47.060981505,5.33712188503,29,,Aumur,Aumur,39,Jura,27,Bourgogne-Franche-Comté
39030,AUTHUME,39100,AUTHUME,,47.1250671722,5.50826789113,30,,Authume,Authume,39,Jura,27,Bourgogne-Franche-Comté
39031,AUXANGE,39700,AUXANGE,,47.1778341766,5.65519498249,31,,Auxange,Auxange,39,Jura,27,Bourgogne-Franche-Comté
39032,AVIGNON LES ST CLAUDE,39200,AVIGNON LES ST CLAUDE,,46.4011757711,5.84079043463,32,,Avignon-lès-Saint-Claude,Avignon-lès-Saint-Claude,39,Jura,27,Bourgogne-Franche-Comté
39034,BALAISEAUX,39120,BALAISEAUX,,46.9676282435,5.46210050966,34,,Balaiseaux,Balaiseaux,39,Jura,27,Bourgogne-Franche-Comté
39035,BALANOD,39160,BALANOD,,46.4554239007,5.35090483871,35,,Balanod,Balanod,39,Jura,27,Bourgogne-Franche-Comté
39036,LA BALME D EPY,39320,LA BALME D EPY,,46.3774826623,5.41729461959,36,La,Balme-d'Épy,La Balme-d'Épy,39,Jura,27,Bourgogne-Franche-Comté
39037,BANS,39380,BANS,,46.9829169242,5.58184356707,37,,Bans,Bans,39,Jura,27,Bourgogne-Franche-Comté
39038,BARESIA SUR L AIN,39130,BARESIA SUR L AIN,,46.5423863314,5.70324307586,38,,Barésia-sur-l'Ain,Barésia-sur-l'Ain,39,Jura,27,Bourgogne-Franche-Comté
39039,LA BARRE,39700,LA BARRE,,47.1570835686,5.6930067743,39,La,Barre,La Barre,39,Jura,27,Bourgogne-Franche-Comté
39040,BARRETAINE,39800,BARRETAINE,,46.810217532,5.71788289074,40,,Barretaine,Barretaine,39,Jura,27,Bourgogne-Franche-Comté
39041,BAUME LES MESSIEURS,39210,BAUME LES MESSIEURS,,46.7048674382,5.64562570074,41,,Baume-les-Messieurs,Baume-les-Messieurs,39,Jura,27,Bourgogne-Franche-Comté
39041,BAUME LES MESSIEURS,39570,BAUME LES MESSIEURS,,46.7048674382,5.64562570074,41,,Baume-les-Messieurs,Baume-les-Messieurs,39,Jura,27,Bourgogne-Franche-Comté
39042,BAVERANS,39100,BAVERANS,,47.1054660171,5.53628421421,42,,Baverans,Baverans,39,Jura,27,Bourgogne-Franche-Comté
39043,BEAUFORT,39190,BEAUFORT,,46.5858090332,5.43052572582,43,,Beaufort,Beaufort,39,Jura,27,Bourgogne-Franche-Comté
39045,BEFFIA,39270,BEFFIA,,46.5315651436,5.54829278779,45,,Beffia,Beffia,39,Jura,27,Bourgogne-Franche-Comté
39046,BELLECOMBE,39310,BELLECOMBE,,46.3093062436,5.90889687152,46,,Bellecombe,Bellecombe,39,Jura,27,Bourgogne-Franche-Comté
39047,BELLEFONTAINE,39400,BELLEFONTAINE,,46.5565262236,6.08239156513,47,,Bellefontaine,Bellefontaine,39,Jura,27,Bourgogne-Franche-Comté
39048,BELMONT,39380,BELMONT,,47.0281412034,5.60483401256,48,,Belmont,Belmont,39,Jura,27,Bourgogne-Franche-Comté
39049,BERSAILLIN,39800,BERSAILLIN,,46.8571794518,5.59533422537,49,,Bersaillin,Bersaillin,39,Jura,27,Bourgogne-Franche-Comté
39049,BERSAILLIN,39800,BERSAILLIN,LE BOUCHAUD,46.8571794518,5.59533422537,49,,Bersaillin,Bersaillin,39,Jura,27,Bourgogne-Franche-Comté
39049,BERSAILLIN,39800,BERSAILLIN,LE VISENEY,46.8571794518,5.59533422537,49,,Bersaillin,Bersaillin,39,Jura,27,Bourgogne-Franche-Comté
39050,BESAIN,39800,BESAIN,,46.7859315795,5.79619936745,50,,Besain,Besain,39,Jura,27,Bourgogne-Franche-Comté
39051,BIARNE,39290,BIARNE,,47.1454413505,5.45151056819,51,,Biarne,Biarne,39,Jura,27,Bourgogne-Franche-Comté
39052,BIEF DES MAISONS,39150,BIEF DES MAISONS,,46.7038559187,6.04967687137,52,,Bief-des-Maisons,Bief-des-Maisons,39,Jura,27,Bourgogne-Franche-Comté
39053,BIEF DU FOURG,39250,BIEF DU FOURG,,46.8204461045,6.11715675755,53,,Bief-du-Fourg,Bief-du-Fourg,39,Jura,27,Bourgogne-Franche-Comté
39054,BIEFMORIN,39800,BIEFMORIN,,46.9030690106,5.52936210858,54,,Biefmorin,Biefmorin,39,Jura,27,Bourgogne-Franche-Comté
39055,BILLECUL,39250,BILLECUL,,46.7464177883,6.05640813471,55,,Billecul,Billecul,39,Jura,27,Bourgogne-Franche-Comté
39056,BLETTERANS,39140,BLETTERANS,,46.7316211339,5.42781411933,56,,Bletterans,Bletterans,39,Jura,27,Bourgogne-Franche-Comté
39057,BLOIS SUR SEILLE,39210,BLOIS SUR SEILLE,,46.7464228951,5.67040219514,57,,Blois-sur-Seille,Blois-sur-Seille,39,Jura,27,Bourgogne-Franche-Comté
39058,BLYE,39130,BLYE,,46.6284281077,5.69599874066,58,,Blye,Blye,39,Jura,27,Bourgogne-Franche-Comté
39059,BOIS D AMONT,39220,BOIS D AMONT,,46.5361950227,6.1273377518,59,,Bois-d'Amont,Bois-d'Amont,39,Jura,27,Bourgogne-Franche-Comté
39060,BOIS DE GAND,39230,BOIS DE GAND,,46.8265028242,5.50052163033,60,,Bois-de-Gand,Bois-de-Gand,39,Jura,27,Bourgogne-Franche-Comté
39061,BOISSIA,39130,BOISSIA,,46.5806605913,5.7211528831,61,,Boissia,Boissia,39,Jura,27,Bourgogne-Franche-Comté
39062,LA BOISSIERE,39240,LA BOISSIERE,,46.4205726757,5.52149312243,62,La,Boissière,La Boissière,39,Jura,27,Bourgogne-Franche-Comté
39063,BONLIEU,39130,BONLIEU,,46.5968045882,5.8566003581,63,,Bonlieu,Bonlieu,39,Jura,27,Bourgogne-Franche-Comté
39064,BONNAUD,39190,BONNAUD,,46.6197553345,5.43048308112,64,,Bonnaud,Bonnaud,39,Jura,27,Bourgogne-Franche-Comté
39065,BONNEFONTAINE,39800,BONNEFONTAINE,,46.731940278,5.74703212327,65,,Bonnefontaine,Bonnefontaine,39,Jura,27,Bourgogne-Franche-Comté
39066,BORNAY,39570,BORNAY,,46.6118982818,5.55324402199,66,,Bornay,Bornay,39,Jura,27,Bourgogne-Franche-Comté
39068,LES BOUCHOUX,39370,LES BOUCHOUX,,46.2946998262,5.80456188916,68,Les,Bouchoux,Les Bouchoux,39,Jura,27,Bourgogne-Franche-Comté
39069,BOURCIA,39320,BOURCIA,,46.3470023878,5.40328519378,69,,Bourcia,Bourcia,39,Jura,27,Bourgogne-Franche-Comté
39070,BOURG DE SIROD,39300,BOURG DE SIROD,,46.7297500419,5.95586980597,70,,Bourg-de-Sirod,Bourg-de-Sirod,39,Jura,27,Bourgogne-Franche-Comté
39072,BRACON,39110,BRACON,,46.9256272441,5.86757188108,72,,Bracon,Bracon,39,Jura,27,Bourgogne-Franche-Comté
39073,BRAINANS,39800,BRAINANS,,46.8746666632,5.6278782462,73,,Brainans,Brainans,39,Jura,27,Bourgogne-Franche-Comté
39074,BRANS,39290,BRANS,,47.2238411039,5.57358869303,74,,Brans,Brans,39,Jura,27,Bourgogne-Franche-Comté
39075,BRERY,39230,BRERY,,46.7843657057,5.58058670254,75,,Bréry,Bréry,39,Jura,27,Bourgogne-Franche-Comté
39076,LA BRETENIERE,39700,LA BRETENIERE,,47.1293532558,5.66464601093,76,La,Bretenière,La Bretenière,39,Jura,27,Bourgogne-Franche-Comté
39077,BRETENIERES,39120,BRETENIERES,,46.9199425339,5.53800376406,77,,Bretenières,Bretenières,39,Jura,27,Bourgogne-Franche-Comté
39078,BREVANS,39100,BREVANS,,47.1007698445,5.52282272719,78,,Brevans,Brevans,39,Jura,27,Bourgogne-Franche-Comté
39079,BRIOD,39570,BRIOD,,46.6633058683,5.62550336964,79,,Briod,Briod,39,Jura,27,Bourgogne-Franche-Comté
39080,BROISSIA,39320,BROISSIA,,46.3562943901,5.43285662397,80,,Broissia,Broissia,39,Jura,27,Bourgogne-Franche-Comté
39081,BUVILLY,39800,BUVILLY,,46.8704853827,5.72629217171,81,,Buvilly,Buvilly,39,Jura,27,Bourgogne-Franche-Comté
39083,CENSEAU,39250,CENSEAU,,46.8165867767,6.07767180739,83,,Censeau,Censeau,39,Jura,27,Bourgogne-Franche-Comté
39084,CERNANS,39110,CERNANS,,46.9343211214,5.9394436656,84,,Cernans,Cernans,39,Jura,27,Bourgogne-Franche-Comté
39085,CERNIEBAUD,39250,CERNIEBAUD,,46.7365821804,6.1257959511,85,,Cerniébaud,Cerniébaud,39,Jura,27,Bourgogne-Franche-Comté
39086,CERNON,39240,CERNON,,46.4121311574,5.64360732811,86,,Cernon,Cernon,39,Jura,27,Bourgogne-Franche-Comté
39086,CERNON,39240,CERNON,VIREMONT,46.4121311574,5.64360732811,86,,Cernon,Cernon,39,Jura,27,Bourgogne-Franche-Comté
39088,CESANCEY,39570,CESANCEY,,46.6227403643,5.4919593002,88,,Cesancey,Cesancey,39,Jura,27,Bourgogne-Franche-Comté
39089,CEZIA,39240,CEZIA,,46.3602146611,5.57531503612,89,,Cézia,Cézia,39,Jura,27,Bourgogne-Franche-Comté
39090,CHAINEE DES COUPIS,39120,CHAINEE DES COUPIS,,46.9220207447,5.43463248879,90,,Chaînée-des-Coupis,Chaînée-des-Coupis,39,Jura,27,Bourgogne-Franche-Comté
39091,LES CHALESMES,39150,LES CHALESMES,,46.6920276055,6.02602567308,91,Les,Chalesmes,Les Chalesmes,39,Jura,27,Bourgogne-Franche-Comté
39092,CHAMBERIA,39270,CHAMBERIA,,46.4694472775,5.5703149623,92,,Chambéria,Chambéria,39,Jura,27,Bourgogne-Franche-Comté
39093,CHAMBLAY,39380,CHAMBLAY,,46.9910483853,5.70335674232,93,,Chamblay,Chamblay,39,Jura,27,Bourgogne-Franche-Comté
39094,CHAMOLE,39800,CHAMOLE,,46.8443055833,5.73874767152,94,,Chamole,Chamole,39,Jura,27,Bourgogne-Franche-Comté
39095,CHAMPAGNE SUR LOUE,39600,CHAMPAGNE SUR LOUE,,47.0328816629,5.80653382089,95,,Champagne-sur-Loue,Champagne-sur-Loue,39,Jura,27,Bourgogne-Franche-Comté
39096,CHAMPAGNEY,39290,CHAMPAGNEY,,47.2596370063,5.50446475584,96,,Champagney,Champagney,39,Jura,27,Bourgogne-Franche-Comté
39097,CHAMPAGNOLE,39300,CHAMPAGNOLE,,46.7445389751,5.8988102947,97,,Champagnole,Champagnole,39,Jura,27,Bourgogne-Franche-Comté
39099,CHAMPDIVERS,39500,CHAMPDIVERS,,47.0078921172,5.39015295804,99,,Champdivers,Champdivers,39,Jura,27,Bourgogne-Franche-Comté
39100,CHAMPROUGIER,39230,CHAMPROUGIER,,46.8785635805,5.51298451513,100,,Champrougier,Champrougier,39,Jura,27,Bourgogne-Franche-Comté
39101,CHAMPVANS,39100,CHAMPVANS,,47.1021842612,5.42048711833,101,,Champvans,Champvans,39,Jura,27,Bourgogne-Franche-Comté
39102,CHANCIA,1590,CHANCIA,,46.3456617858,5.64248681911,102,,Chancia,Chancia,39,Jura,27,Bourgogne-Franche-Comté
39103,LA CHAPELLE SUR FURIEUSE,39110,LA CHAPELLE SUR FURIEUSE,,46.9888982082,5.85798769424,103,La,Chapelle-sur-Furieuse,La Chapelle-sur-Furieuse,39,Jura,27,Bourgogne-Franche-Comté
39104,CHAPELLE VOLAND,39140,CHAPELLE VOLAND,,46.8008280334,5.38064900951,104,,Chapelle-Voland,Chapelle-Voland,39,Jura,27,Bourgogne-Franche-Comté
39105,CHAPOIS,39300,CHAPOIS,,46.8335678801,5.96598394958,105,,Chapois,Chapois,39,Jura,27,Bourgogne-Franche-Comté
39106,CHARCHILLA,39260,CHARCHILLA,,46.4688109023,5.71546918875,106,,Charchilla,Charchilla,39,Jura,27,Bourgogne-Franche-Comté
39107,CHARCIER,39130,CHARCIER,,46.6290312371,5.7635543146,107,,Charcier,Charcier,39,Jura,27,Bourgogne-Franche-Comté
39108,CHARENCY,39250,CHARENCY,,46.7665615855,5.99264951737,108,,Charency,Charency,39,Jura,27,Bourgogne-Franche-Comté
39109,CHAREZIER,39130,CHAREZIER,,46.6145121643,5.72720293776,109,,Charézier,Charézier,39,Jura,27,Bourgogne-Franche-Comté
39110,LA CHARME,39230,LA CHARME,,46.8485608193,5.54676526867,110,La,Charme,La Charme,39,Jura,27,Bourgogne-Franche-Comté
39111,CHARNOD,39240,CHARNOD,,46.3372486157,5.49365223765,111,,Charnod,Charnod,39,Jura,27,Bourgogne-Franche-Comté
39112,LA CHASSAGNE,39230,LA CHASSAGNE,,46.8673225647,5.45469725641,112,La,Chassagne,La Chassagne,39,Jura,27,Bourgogne-Franche-Comté
39113,CHASSAL,39360,CHASSAL,,46.3600964475,5.7849059894,113,,Chassal,Chassal,39,Jura,27,Bourgogne-Franche-Comté
39114,CHATEAU CHALON,39210,CHATEAU CHALON,,46.7594016188,5.64816991256,114,,Château-Chalon,Château-Chalon,39,Jura,27,Bourgogne-Franche-Comté
39115,CHATEAU DES PRES,39150,CHATEAU DES PRES,,46.5073298567,5.91261631292,115,,Château-des-Prés,Château-des-Prés,39,Jura,27,Bourgogne-Franche-Comté
39116,LA CHATELAINE,39600,LA CHATELAINE,,46.8586081976,5.8248269042,116,La,Châtelaine,La Châtelaine,39,Jura,27,Bourgogne-Franche-Comté
39117,CHATELAY,39380,CHATELAY,,47.0581445417,5.69769809053,117,,Chatelay,Chatelay,39,Jura,27,Bourgogne-Franche-Comté
39118,CHATEL DE JOUX,39130,CHATEL DE JOUX,,46.5296472131,5.80925298746,118,,Châtel-de-Joux,Châtel-de-Joux,39,Jura,27,Bourgogne-Franche-Comté
39119,LE CHATELEY,39230,LE CHATELEY,,46.8795560182,5.54443854939,119,Le,Chateley,Le Chateley,39,Jura,27,Bourgogne-Franche-Comté
39120,CHATELNEUF,39300,CHATELNEUF,,46.6660130297,5.91229926593,120,,Châtelneuf,Châtelneuf,39,Jura,27,Bourgogne-Franche-Comté
39121,CHATENOIS,39700,CHATENOIS,,47.1503115201,5.54630681574,121,,Châtenois,Châtenois,39,Jura,27,Bourgogne-Franche-Comté
39122,CHATILLON,39130,CHATILLON,,46.6626648157,5.7273967644,122,,Châtillon,Châtillon,39,Jura,27,Bourgogne-Franche-Comté
39123,CHATONNAY,39240,CHATONNAY,,46.4243378632,5.5461547417,123,,Chatonnay,Chatonnay,39,Jura,27,Bourgogne-Franche-Comté
39124,CHAUMERGY,39230,CHAUMERGY,,46.8433857304,5.47463338737,124,,Chaumergy,Chaumergy,39,Jura,27,Bourgogne-Franche-Comté
39126,LA CHAUMUSSE,39150,LA CHAUMUSSE,,46.6006202227,5.94399129597,126,La,Chaumusse,La Chaumusse,39,Jura,27,Bourgogne-Franche-Comté
39127,CHAUSSENANS,39800,CHAUSSENANS,,46.8246686639,5.74543213223,127,,Chaussenans,Chaussenans,39,Jura,27,Bourgogne-Franche-Comté
39128,CHAUSSIN,39120,CHAUSSIN,,46.973824579,5.40270953105,128,,Chaussin,Chaussin,39,Jura,27,Bourgogne-Franche-Comté
39129,CHAUX DES CROTENAY,39150,CHAUX DES CROTENAY,,46.6627966943,5.95679629516,129,,Chaux-des-Crotenay,Chaux-des-Crotenay,39,Jura,27,Bourgogne-Franche-Comté
39130,NANCHEZ,39150,NANCHEZ,CHAUX DES PRES,46.4998251911,5.8576069903,130,,Nanchez,Nanchez,39,Jura,27,Bourgogne-Franche-Comté
39130,NANCHEZ,39150,NANCHEZ,PRENOVEL,46.4998251911,5.8576069903,130,,Nanchez,Nanchez,39,Jura,27,Bourgogne-Franche-Comté
39131,LA CHAUX DU DOMBIEF,39150,CHAUX DU DOMBIEF,,46.607697996,5.90655686239,131,La,Chaux-du-Dombief,La Chaux-du-Dombief,39,Jura,27,Bourgogne-Franche-Comté
39132,LA CHAUX EN BRESSE,39230,LA CHAUX EN BRESSE,,46.8259443938,5.47657963096,132,La,Chaux-en-Bresse,La Chaux-en-Bresse,39,Jura,27,Bourgogne-Franche-Comté
39133,CHAUX CHAMPAGNY,39110,CHAUX CHAMPAGNY,,46.8831143398,5.87949383777,133,,Chaux-Champagny,Chaux-Champagny,39,Jura,27,Bourgogne-Franche-Comté
39133,CHAUX CHAMPAGNY,39110,CHAUX CHAMPAGNY,CHAMPAGNY,46.8831143398,5.87949383777,133,,Chaux-Champagny,Chaux-Champagny,39,Jura,27,Bourgogne-Franche-Comté
39134,CHAVERIA,39270,CHAVERIA,,46.4989504543,5.55730499833,134,,Chavéria,Chavéria,39,Jura,27,Bourgogne-Franche-Comté
39136,CHEMENOT,39230,CHEMENOT,,46.8599600621,5.52969682759,136,,Chemenot,Chemenot,39,Jura,27,Bourgogne-Franche-Comté
39137,CHEMILLA,39240,CHEMILLA,,46.3507935799,5.55838223354,137,,Chemilla,Chemilla,39,Jura,27,Bourgogne-Franche-Comté
39138,CHEMIN,39120,CHEMIN,,46.9769333115,5.31259454731,138,,Chemin,Chemin,39,Jura,27,Bourgogne-Franche-Comté
39139,CHENE BERNARD,39120,CHENE BERNARD,,46.917901439,5.46969645181,139,,Chêne-Bernard,Chêne-Bernard,39,Jura,27,Bourgogne-Franche-Comté
39140,CHENE SEC,39230,CHENE SEC,,46.8480769432,5.44386002073,140,,Chêne-Sec,Chêne-Sec,39,Jura,27,Bourgogne-Franche-Comté
39141,CHEVIGNY,39290,CHEVIGNY,,47.1765613583,5.47410569211,141,,Chevigny,Chevigny,39,Jura,27,Bourgogne-Franche-Comté
39142,CHEVREAUX,39190,CHEVREAUX,,46.5015228257,5.41955916196,142,,Chevreaux,Chevreaux,39,Jura,27,Bourgogne-Franche-Comté
39143,CHEVROTAINE,39130,CHEVROTAINE,,46.6528649109,5.86000659207,143,,Chevrotaine,Chevrotaine,39,Jura,27,Bourgogne-Franche-Comté
39145,CHILLE,39570,CHILLE,,46.6933516322,5.57237754147,145,,Chille,Chille,39,Jura,27,Bourgogne-Franche-Comté
39146,CHILLY LE VIGNOBLE,39570,CHILLY LE VIGNOBLE,,46.6596723262,5.49690965689,146,,Chilly-le-Vignoble,Chilly-le-Vignoble,39,Jura,27,Bourgogne-Franche-Comté
39147,CHILLY SUR SALINS,39110,CHILLY SUR SALINS,,46.8697346286,5.85796704483,147,,Chilly-sur-Salins,Chilly-sur-Salins,39,Jura,27,Bourgogne-Franche-Comté
39148,CHISSERIA,39240,CHISSERIA,,46.376482331,5.56694131773,148,,Chisséria,Chisséria,39,Jura,27,Bourgogne-Franche-Comté
39149,CHISSEY SUR LOUE,39380,CHISSEY SUR LOUE,,47.0603931252,5.74043299364,149,,Chissey-sur-Loue,Chissey-sur-Loue,39,Jura,27,Bourgogne-Franche-Comté
39150,CHOISEY,39100,CHOISEY,,47.0588384803,5.45057837104,150,,Choisey,Choisey,39,Jura,27,Bourgogne-Franche-Comté
39151,CHOUX,39370,CHOUX,,46.301599898,5.77007174218,151,,Choux,Choux,39,Jura,27,Bourgogne-Franche-Comté
39153,CIZE,39300,CIZE,,46.7198972063,5.91999404872,153,,Cize,Cize,39,Jura,27,Bourgogne-Franche-Comté
39154,CLAIRVAUX LES LACS,39130,CLAIRVAUX LES LACS,,46.5646560659,5.75476433887,154,,Clairvaux-les-Lacs,Clairvaux-les-Lacs,39,Jura,27,Bourgogne-Franche-Comté
39155,CLUCY,39110,CLUCY,,46.9461704055,5.91685452628,155,,Clucy,Clucy,39,Jura,27,Bourgogne-Franche-Comté
39156,COGNA,39130,COGNA,,46.5867008206,5.77312051776,156,,Cogna,Cogna,39,Jura,27,Bourgogne-Franche-Comté
39157,COISERETTE,39200,COISERETTE,,46.3381325786,5.8257562121,157,,Coiserette,Coiserette,39,Jura,27,Bourgogne-Franche-Comté
39158,COISIA,39240,COISIA,,46.303205968,5.58205302334,158,,Coisia,Coisia,39,Jura,27,Bourgogne-Franche-Comté
39159,COLONNE,39800,COLONNE,,46.8790834868,5.57100296371,159,,Colonne,Colonne,39,Jura,27,Bourgogne-Franche-Comté
39160,COMMENAILLES,39140,COMMENAILLES,,46.8055925733,5.43718044801,160,,Commenailles,Commenailles,39,Jura,27,Bourgogne-Franche-Comté
39162,CONDAMINE,39570,CONDAMINE,,46.649905188,5.43493044694,162,,Condamine,Condamine,39,Jura,27,Bourgogne-Franche-Comté
39163,CONDES,39240,CONDES,,46.3305432517,5.61760194459,163,,Condes,Condes,39,Jura,27,Bourgogne-Franche-Comté
39164,CONLIEGE,39570,CONLIEGE,,46.6513724979,5.61109105926,164,,Conliège,Conliège,39,Jura,27,Bourgogne-Franche-Comté
39165,CONTE,39300,CONTE,,46.7496326396,6.00775677762,165,,Conte,Conte,39,Jura,27,Bourgogne-Franche-Comté
39166,CORNOD,39240,CORNOD,,46.3075990777,5.54745998941,166,,Cornod,Cornod,39,Jura,27,Bourgogne-Franche-Comté
39167,COSGES,39140,COSGES,,46.7469594031,5.39199282971,167,,Cosges,Cosges,39,Jura,27,Bourgogne-Franche-Comté
39168,COURBETTE,39570,COURBETTE,,46.597757049,5.56912637088,168,,Courbette,Courbette,39,Jura,27,Bourgogne-Franche-Comté
39169,COURBOUZON,39570,COURBOUZON,,46.6530259011,5.52879804743,169,,Courbouzon,Courbouzon,39,Jura,27,Bourgogne-Franche-Comté
39170,COURLANS,39570,COURLANS,,46.6757012909,5.48680231929,170,,Courlans,Courlans,39,Jura,27,Bourgogne-Franche-Comté
39170,COURLANS,39570,COURLANS,CHAVANNE,46.6757012909,5.48680231929,170,,Courlans,Courlans,39,Jura,27,Bourgogne-Franche-Comté
39171,COURLAOUX,39570,COURLAOUX,,46.6694520389,5.44937543437,171,,Courlaoux,Courlaoux,39,Jura,27,Bourgogne-Franche-Comté
39172,COURTEFONTAINE,39700,COURTEFONTAINE,,47.132605121,5.79331394408,172,,Courtefontaine,Courtefontaine,39,Jura,27,Bourgogne-Franche-Comté
39173,COUSANCE,39190,COUSANCE,,46.5333527445,5.37741644744,173,,Cousance,Cousance,39,Jura,27,Bourgogne-Franche-Comté
39174,COYRIERE,39200,COYRIERE,,46.3404621083,5.84504322935,174,,Coyrière,Coyrière,39,Jura,27,Bourgogne-Franche-Comté
39175,COYRON,39260,COYRON,,46.5124158536,5.69815405752,175,,Coyron,Coyron,39,Jura,27,Bourgogne-Franche-Comté
39176,CRAMANS,39600,CRAMANS,,47.0073865371,5.78702671901,176,,Cramans,Cramans,39,Jura,27,Bourgogne-Franche-Comté
39177,HAUTEROCHE,39210,HAUTEROCHE,GRANGES SUR BAUME,46.6884401125,5.66906572619,177,,Hauteroche,Hauteroche,39,Jura,27,Bourgogne-Franche-Comté
39177,HAUTEROCHE,39570,HAUTEROCHE,CRANCOT,46.6884401125,5.66906572619,177,,Hauteroche,Hauteroche,39,Jura,27,Bourgogne-Franche-Comté
39177,HAUTEROCHE,39570,HAUTEROCHE,MIREBEL,46.6884401125,5.66906572619,177,,Hauteroche,Hauteroche,39,Jura,27,Bourgogne-Franche-Comté
39178,CRANS,39300,CRANS,,46.6951339522,5.98129931286,178,,Crans,Crans,39,Jura,27,Bourgogne-Franche-Comté
39179,CRENANS,39260,CRENANS,,46.4662863715,5.74165034768,179,,Crenans,Crenans,39,Jura,27,Bourgogne-Franche-Comté
39180,CRESSIA,39270,CRESSIA,,46.5268687403,5.48381777144,180,,Cressia,Cressia,39,Jura,27,Bourgogne-Franche-Comté
39182,CRISSEY,39100,CRISSEY,,47.0503243045,5.47819873684,182,,Crissey,Crissey,39,Jura,27,Bourgogne-Franche-Comté
39183,CROTENAY,39300,CROTENAY,,46.7546647764,5.81374887948,183,,Crotenay,Crotenay,39,Jura,27,Bourgogne-Franche-Comté
39184,LES CROZETS,39260,LES CROZETS,,46.4635033757,5.80467882912,184,Les,Crozets,Les Crozets,39,Jura,27,Bourgogne-Franche-Comté
39185,CUISIA,39190,CUISIA,,46.5467474362,5.40533542507,185,,Cuisia,Cuisia,39,Jura,27,Bourgogne-Franche-Comté
39186,CUTTURA,39170,CUTTURA,,46.4175271343,5.81942300559,186,,Cuttura,Cuttura,39,Jura,27,Bourgogne-Franche-Comté
39187,CUVIER,39250,CUVIER,,46.8392780314,6.06612873386,187,,Cuvier,Cuvier,39,Jura,27,Bourgogne-Franche-Comté
39188,DAMMARTIN MARPAIN,39290,DAMMARTIN MARPAIN,,47.2530677926,5.55397181022,188,,Dammartin-Marpain,Dammartin-Marpain,39,Jura,27,Bourgogne-Franche-Comté
39188,DAMMARTIN MARPAIN,39290,DAMMARTIN MARPAIN,MARPAIN,47.2530677926,5.55397181022,188,,Dammartin-Marpain,Dammartin-Marpain,39,Jura,27,Bourgogne-Franche-Comté
39189,DAMPARIS,39500,DAMPARIS,,47.0709486148,5.40719104716,189,,Damparis,Damparis,39,Jura,27,Bourgogne-Franche-Comté
39190,DAMPIERRE,39700,DAMPIERRE,,47.1650509305,5.74228998802,190,,Dampierre,Dampierre,39,Jura,27,Bourgogne-Franche-Comté
39190,DAMPIERRE,39700,DAMPIERRE,CHATEAUNEUF,47.1650509305,5.74228998802,190,,Dampierre,Dampierre,39,Jura,27,Bourgogne-Franche-Comté
39191,DARBONNAY,39230,DARBONNAY,,46.8253223066,5.60755327893,191,,Darbonnay,Darbonnay,39,Jura,27,Bourgogne-Franche-Comté
39192,DENEZIERES,39130,DENEZIERES,,46.6244113677,5.80050355357,192,,Denezières,Denezières,39,Jura,27,Bourgogne-Franche-Comté
39193,LE DESCHAUX,39120,LE DESCHAUX,,46.9505140483,5.49877819906,193,Le,Deschaux,Le Deschaux,39,Jura,27,Bourgogne-Franche-Comté
39194,DESNES,39140,DESNES,,46.7681304523,5.46790509865,194,,Desnes,Desnes,39,Jura,27,Bourgogne-Franche-Comté
39195,DESSIA,39320,DESSIA,,46.3848784163,5.50774352363,195,,Dessia,Dessia,39,Jura,27,Bourgogne-Franche-Comté
39196,LES DEUX FAYS,39230,LES DEUX FAYS,,46.8854345992,5.48750372118,196,Les,Deux-Fays,Les Deux-Fays,39,Jura,27,Bourgogne-Franche-Comté
39197,DIGNA,39190,DIGNA,,46.5182419302,5.38565811964,197,,Digna,Digna,39,Jura,27,Bourgogne-Franche-Comté
39198,DOLE,39100,DOLE,,47.0755452538,5.50062446099,198,,Dole,Dole,39,Jura,27,Bourgogne-Franche-Comté
39198,DOLE,39100,DOLE,GOUX,47.0755452538,5.50062446099,198,,Dole,Dole,39,Jura,27,Bourgogne-Franche-Comté
39198,DOLE,39100,DOLE,ST YLIE,47.0755452538,5.50062446099,198,,Dole,Dole,39,Jura,27,Bourgogne-Franche-Comté
39199,DOMBLANS,39210,DOMBLANS,,46.7623690449,5.59000211082,199,,Domblans,Domblans,39,Jura,27,Bourgogne-Franche-Comté
39200,DOMPIERRE SUR MONT,39270,DOMPIERRE SUR MONT,,46.5627677979,5.61274460974,200,,Dompierre-sur-Mont,Dompierre-sur-Mont,39,Jura,27,Bourgogne-Franche-Comté
39201,DOUCIER,39130,DOUCIER,,46.6499567029,5.77799951166,201,,Doucier,Doucier,39,Jura,27,Bourgogne-Franche-Comté
39202,DOURNON,39110,DOURNON,,46.9321643123,5.97063569174,202,,Dournon,Dournon,39,Jura,27,Bourgogne-Franche-Comté
39203,DOYE,39250,DOYE,,46.7678938966,6.01401382033,203,,Doye,Doye,39,Jura,27,Bourgogne-Franche-Comté
39204,DRAMELAY,39240,DRAMELAY,,46.4042095252,5.53007674794,204,,Dramelay,Dramelay,39,Jura,27,Bourgogne-Franche-Comté
39205,ECLANS NENON,39700,ECLANS NENON,,47.1038743929,5.61210284808,205,,Éclans-Nenon,Éclans-Nenon,39,Jura,27,Bourgogne-Franche-Comté
39205,ECLANS NENON,39700,ECLANS NENON,NENON,47.1038743929,5.61210284808,205,,Éclans-Nenon,Éclans-Nenon,39,Jura,27,Bourgogne-Franche-Comté
39206,ECLEUX,39600,ECLEUX,,46.9949161228,5.73686979775,206,,Écleux,Écleux,39,Jura,27,Bourgogne-Franche-Comté
39207,ECRILLE,39270,ECRILLE,,46.5022298608,5.63385105141,207,,Écrille,Écrille,39,Jura,27,Bourgogne-Franche-Comté
39208,ENTRE DEUX MONTS,39150,ENTRE DEUX MONTS,,46.6418277764,5.96335061438,208,,Entre-deux-Monts,Entre-deux-Monts,39,Jura,27,Bourgogne-Franche-Comté
39209,VAL D EPY,39160,VAL D EPY,,46.3756497717,5.39068206826,209,,Val-d'Épy,Val-d'Épy,39,Jura,27,Bourgogne-Franche-Comté
39209,VAL D EPY,39160,VAL D EPY,EPY LANERIA,46.3756497717,5.39068206826,209,,Val-d'Épy,Val-d'Épy,39,Jura,27,Bourgogne-Franche-Comté
39209,VAL D EPY,39160,VAL D EPY,NANTEY,46.3756497717,5.39068206826,209,,Val-d'Épy,Val-d'Épy,39,Jura,27,Bourgogne-Franche-Comté
39209,VAL D EPY,39160,VAL D EPY,POISOUX,46.3756497717,5.39068206826,209,,Val-d'Épy,Val-d'Épy,39,Jura,27,Bourgogne-Franche-Comté
39209,VAL D EPY,39160,VAL D EPY,SENAUD,46.3756497717,5.39068206826,209,,Val-d'Épy,Val-d'Épy,39,Jura,27,Bourgogne-Franche-Comté
39209,VAL D EPY,39320,VAL D EPY,FLORENTIA,46.3756497717,5.39068206826,209,,Val-d'Épy,Val-d'Épy,39,Jura,27,Bourgogne-Franche-Comté
39210,EQUEVILLON,39300,EQUEVILLON,,46.7607810101,5.94097617099,210,,Équevillon,Équevillon,39,Jura,27,Bourgogne-Franche-Comté
39211,LES ESSARDS TAIGNEVAUX,39120,LES ESSARDS TAIGNEVAUX,,46.9035384189,5.41523803268,211,Les,Essards-Taignevaux,Les Essards-Taignevaux,39,Jura,27,Bourgogne-Franche-Comté
39214,ESSERVAL TARTRE,39250,ESSERVAL TARTRE,,46.8306327742,6.03218629416,214,,Esserval-Tartre,Esserval-Tartre,39,Jura,27,Bourgogne-Franche-Comté
39216,ETIVAL,39130,ETIVAL,,46.4917221843,5.79388173937,216,,Étival,Étival,39,Jura,27,Bourgogne-Franche-Comté
39216,ETIVAL,39130,ETIVAL,LES RONCHAUX,46.4917221843,5.79388173937,216,,Étival,Étival,39,Jura,27,Bourgogne-Franche-Comté
39217,L ETOILE,39570,L ETOILE,,46.7160513036,5.53394517039,217,L',Étoile,L'Étoile,39,Jura,27,Bourgogne-Franche-Comté
39218,ETREPIGNEY,39700,ETREPIGNEY,,47.1104911888,5.6819169156,218,,Étrepigney,Étrepigney,39,Jura,27,Bourgogne-Franche-Comté
39219,EVANS,39700,EVANS,,47.1795880085,5.76480868684,219,,Évans,Évans,39,Jura,27,Bourgogne-Franche-Comté
39220,FALLETANS,39700,FALLETANS,,47.0819186526,5.56688411224,220,,Falletans,Falletans,39,Jura,27,Bourgogne-Franche-Comté
39221,LA FAVIERE,39250,LA FAVIERE,,46.7481190667,6.03667883413,221,La,Favière,La Favière,39,Jura,27,Bourgogne-Franche-Comté
39222,FAY EN MONTAGNE,39800,FAY EN MONTAGNE,,46.7536361498,5.717409823,222,,Fay-en-Montagne,Fay-en-Montagne,39,Jura,27,Bourgogne-Franche-Comté
39223,LA FERTE,39600,LA FERTE,,46.9403261024,5.65073456317,223,La,Ferté,La Ferté,39,Jura,27,Bourgogne-Franche-Comté
39224,FETIGNY,39240,FETIGNY,,46.4402403534,5.60165341688,224,,Fétigny,Fétigny,39,Jura,27,Bourgogne-Franche-Comté
39225,LE FIED,39800,LE FIED,,46.7740955741,5.71637200568,225,Le,Fied,Le Fied,39,Jura,27,Bourgogne-Franche-Comté
39227,FONCINE LE BAS,39520,FONCINE LE BAS,,46.6323096573,6.0304724219,227,,Foncine-le-Bas,Foncine-le-Bas,39,Jura,27,Bourgogne-Franche-Comté
39228,FONCINE LE HAUT,39460,FONCINE LE HAUT,,46.6590550612,6.06154968382,228,,Foncine-le-Haut,Foncine-le-Haut,39,Jura,27,Bourgogne-Franche-Comté
39229,FONTAINEBRUX,39140,FONTAINEBRUX,,46.6967309951,5.43737795518,229,,Fontainebrux,Fontainebrux,39,Jura,27,Bourgogne-Franche-Comté
39230,FONTENU,39130,FONTENU,,46.6719633574,5.81528631175,230,,Fontenu,Fontenu,39,Jura,27,Bourgogne-Franche-Comté
39232,FORT DU PLASNE,39150,FORT DU PLASNE,,46.6232367708,5.98199426982,232,,Fort-du-Plasne,Fort-du-Plasne,39,Jura,27,Bourgogne-Franche-Comté
39233,FOUCHERANS,39100,FOUCHERANS,,47.0856433661,5.44430100511,233,,Foucherans,Foucherans,39,Jura,27,Bourgogne-Franche-Comté
39234,FOULENAY,39230,FOULENAY,,46.8608876918,5.48627803896,234,,Foulenay,Foulenay,39,Jura,27,Bourgogne-Franche-Comté
39235,FRAISANS,39700,FRAISANS,,47.1244743587,5.75661818843,235,,Fraisans,Fraisans,39,Jura,27,Bourgogne-Franche-Comté
39236,FRANCHEVILLE,39230,FRANCHEVILLE,,46.8366646501,5.50234541581,236,,Francheville,Francheville,39,Jura,27,Bourgogne-Franche-Comté
39237,FRAROZ,39250,FRAROZ,,46.7287692147,6.09880430783,237,,Fraroz,Fraroz,39,Jura,27,Bourgogne-Franche-Comté
39238,FRASNE LES MEULIERES,39290,FRASNE LES MEULIERES,,47.1966162247,5.50343047257,238,,Frasne-les-Meulières,Frasne-les-Meulières,39,Jura,27,Bourgogne-Franche-Comté
39239,LA FRASNEE,39130,LA FRASNEE,,46.5611032871,5.80120457498,239,La,Frasnée,La Frasnée,39,Jura,27,Bourgogne-Franche-Comté
39240,LE FRASNOIS,39130,LE FRASNOIS,,46.6361110907,5.89061826384,240,Le,Frasnois,Le Frasnois,39,Jura,27,Bourgogne-Franche-Comté
39241,FREBUANS,39570,FREBUANS,,46.6495243028,5.48468530029,241,,Frébuans,Frébuans,39,Jura,27,Bourgogne-Franche-Comté
39244,FRONTENAY,39210,FRONTENAY,,46.7884051607,5.63215279855,244,,Frontenay,Frontenay,39,Jura,27,Bourgogne-Franche-Comté
39245,GATEY,39120,GATEY,,46.9455665112,5.45611135598,245,,Gatey,Gatey,39,Jura,27,Bourgogne-Franche-Comté
39246,GENDREY,39350,GENDREY,,47.1963062843,5.68474768742,246,,Gendrey,Gendrey,39,Jura,27,Bourgogne-Franche-Comté
39247,GENOD,39240,GENOD,,46.3574309784,5.52980105214,247,,Genod,Genod,39,Jura,27,Bourgogne-Franche-Comté
39248,GERAISE,39110,GERAISE,,46.9582339732,5.95511287439,248,,Geraise,Geraise,39,Jura,27,Bourgogne-Franche-Comté
39249,GERMIGNEY,39380,GERMIGNEY,,47.0384440113,5.68614106484,249,,Germigney,Germigney,39,Jura,27,Bourgogne-Franche-Comté
39250,GERUGE,39570,GERUGE,,46.625057705,5.53075865537,250,,Geruge,Geruge,39,Jura,27,Bourgogne-Franche-Comté
39251,GEVINGEY,39570,GEVINGEY,,46.6375986496,5.50938673759,251,,Gevingey,Gevingey,39,Jura,27,Bourgogne-Franche-Comté
39252,GEVRY,39100,GEVRY,,47.0322816848,5.44484425486,252,,Gevry,Gevry,39,Jura,27,Bourgogne-Franche-Comté
39253,GIGNY,39320,GIGNY,,46.4543990771,5.4715682164,253,,Gigny,Gigny,39,Jura,27,Bourgogne-Franche-Comté
39254,GILLOIS,39250,GILLOIS,,46.7301116843,6.03133695605,254,,Gillois,Gillois,39,Jura,27,Bourgogne-Franche-Comté
39255,GIZIA,39190,GIZIA,,46.5271746657,5.42480617606,255,,Gizia,Gizia,39,Jura,27,Bourgogne-Franche-Comté
39258,GRANDE RIVIERE,39150,GRANDE RIVIERE,,46.5345269468,5.90836886735,258,,Grande-Rivière,Grande-Rivière,39,Jura,27,Bourgogne-Franche-Comté
39258,GRANDE RIVIERE,39150,GRANDE RIVIERE,RIVIERE DEVANT,46.5345269468,5.90836886735,258,,Grande-Rivière,Grande-Rivière,39,Jura,27,Bourgogne-Franche-Comté
39259,GRANGE DE VAIVRE,39600,GRANGE DE VAIVRE,,46.9978542167,5.84207258909,259,,Grange-de-Vaivre,Grange-de-Vaivre,39,Jura,27,Bourgogne-Franche-Comté
39261,GRAYE ET CHARNAY,39320,GRAYE ET CHARNAY,,46.4731744736,5.44898680296,261,,Graye-et-Charnay,Graye-et-Charnay,39,Jura,27,Bourgogne-Franche-Comté
39262,GREDISANS,39290,GREDISANS,,47.1627557383,5.51775930119,262,,Gredisans,Gredisans,39,Jura,27,Bourgogne-Franche-Comté
39263,GROZON,39800,GROZON,,46.8897857608,5.69024153964,263,,Grozon,Grozon,39,Jura,27,Bourgogne-Franche-Comté
39264,GRUSSE,39190,GRUSSE,,46.5959318058,5.49854025937,264,,Grusse,Grusse,39,Jura,27,Bourgogne-Franche-Comté
39265,HAUTECOUR,39130,HAUTECOUR,,46.5618581979,5.78248121784,265,,Hautecour,Hautecour,39,Jura,27,Bourgogne-Franche-Comté
39266,LES HAYS,39120,LES HAYS,,46.9085311513,5.39082220543,266,Les,Hays,Les Hays,39,Jura,27,Bourgogne-Franche-Comté
39267,IVORY,39110,IVORY,,46.8986920683,5.84966493712,267,,Ivory,Ivory,39,Jura,27,Bourgogne-Franche-Comté
39268,IVREY,39110,IVREY,,46.9896694656,5.89705874388,268,,Ivrey,Ivrey,39,Jura,27,Bourgogne-Franche-Comté
39269,JEURRE,39360,JEURRE,,46.3689937737,5.70478472529,269,,Jeurre,Jeurre,39,Jura,27,Bourgogne-Franche-Comté
39270,JOUHE,39100,JOUHE,,47.1398982403,5.48513402798,270,,Jouhe,Jouhe,39,Jura,27,Bourgogne-Franche-Comté
39271,LAC DES ROUGES TRUITES,39150,LAC DES ROUGES TRUITES,,46.6006239723,6.01003067778,271,,Lac-des-Rouges-Truites,Lac-des-Rouges-Truites,39,Jura,27,Bourgogne-Franche-Comté
39272,LADOYE SUR SEILLE,39210,LADOYE SUR SEILLE,,46.7668743064,5.68304960571,272,,Ladoye-sur-Seille,Ladoye-sur-Seille,39,Jura,27,Bourgogne-Franche-Comté
39273,LAINS,39320,LAINS,,46.3895271897,5.48848801863,273,,Montlainsia,Montlainsia,39,Jura,27,Bourgogne-Franche-Comté
39274,LAJOUX,1410,LAJOUX,,46.3914322554,6.00896074386,274,,Lajoux,Lajoux,39,Jura,27,Bourgogne-Franche-Comté
39274,LAJOUX,39310,LAJOUX,,46.3914322554,6.00896074386,274,,Lajoux,Lajoux,39,Jura,27,Bourgogne-Franche-Comté
39275,LAMOURA,39310,LAMOURA,,46.4081181986,5.98341720791,275,,Lamoura,Lamoura,39,Jura,27,Bourgogne-Franche-Comté
39277,LE LARDERET,39300,LE LARDERET,,46.8187727556,5.94601155483,277,Le,Larderet,Le Larderet,39,Jura,27,Bourgogne-Franche-Comté
39278,LARGILLAY MARSONNAY,39130,LARGILLAY MARSONNAY,,46.5560198028,5.66558400939,278,,Largillay-Marsonnay,Largillay-Marsonnay,39,Jura,27,Bourgogne-Franche-Comté
39279,LARNAUD,39140,LARNAUD,,46.7068129495,5.45782797835,279,,Larnaud,Larnaud,39,Jura,27,Bourgogne-Franche-Comté
39280,LARRIVOIRE,39360,LARRIVOIRE,,46.338478336,5.78818524543,280,,Larrivoire,Larrivoire,39,Jura,27,Bourgogne-Franche-Comté
39281,LE LATET,39300,LE LATET,,46.8031363333,5.94173064599,281,Le,Latet,Le Latet,39,Jura,27,Bourgogne-Franche-Comté
39282,LA LATETTE,39250,LA LATETTE,,46.7518875591,6.09572549131,282,La,Latette,La Latette,39,Jura,27,Bourgogne-Franche-Comté
39283,LAVANCIA EPERCY,1590,LAVANCIA EPERCY,,46.3364527437,5.68491757236,283,,Lavancia-Epercy,Lavancia-Epercy,39,Jura,27,Bourgogne-Franche-Comté
39284,LAVANGEOT,39700,LAVANGEOT,,47.1511519623,5.60803139614,284,,Lavangeot,Lavangeot,39,Jura,27,Bourgogne-Franche-Comté
39285,LAVANS LES DOLE,39700,LAVANS LES DOLE,,47.1556350974,5.63422877694,285,,Lavans-lès-Dole,Lavans-lès-Dole,39,Jura,27,Bourgogne-Franche-Comté
39286,LAVANS LES ST CLAUDE,39170,LAVANS LES ST CLAUDE,,46.3901726307,5.77719970471,286,,Lavans-lès-Saint-Claude,Lavans-lès-Saint-Claude,39,Jura,27,Bourgogne-Franche-Comté
39286,LAVANS LES ST CLAUDE,39170,LAVANS LES ST CLAUDE,LIZON,46.3901726307,5.77719970471,286,,Lavans-lès-Saint-Claude,Lavans-lès-Saint-Claude,39,Jura,27,Bourgogne-Franche-Comté
39286,LAVANS LES ST CLAUDE,39170,LAVANS LES ST CLAUDE,PONTHOUX,46.3901726307,5.77719970471,286,,Lavans-lès-Saint-Claude,Lavans-lès-Saint-Claude,39,Jura,27,Bourgogne-Franche-Comté
39287,LAVANS SUR VALOUSE,39240,LAVANS SUR VALOUSE,,46.3377405441,5.57236465142,287,,Lavans-sur-Valouse,Lavans-sur-Valouse,39,Jura,27,Bourgogne-Franche-Comté
39288,LAVIGNY,39210,LAVIGNY,,46.717011316,5.60517764784,288,,Lavigny,Lavigny,39,Jura,27,Bourgogne-Franche-Comté
39288,LAVIGNY,39210,LAVIGNY,ROSNAY,46.717011316,5.60517764784,288,,Lavigny,Lavigny,39,Jura,27,Bourgogne-Franche-Comté
39289,LECT,39260,LECT,,46.3941077641,5.67259916552,289,,Lect,Lect,39,Jura,27,Bourgogne-Franche-Comté
39289,LECT,39260,LECT,VOUGLANS,46.3941077641,5.67259916552,289,,Lect,Lect,39,Jura,27,Bourgogne-Franche-Comté
39290,LEGNA,39240,LEGNA,,46.4245328121,5.60694899546,290,,Valzin en Petite Montagne,Valzin en Petite Montagne,39,Jura,27,Bourgogne-Franche-Comté
39291,LEMUY,39110,LEMUY,,46.889073622,5.98241424914,291,,Lemuy,Lemuy,39,Jura,27,Bourgogne-Franche-Comté
39292,LENT,39300,LENT,,46.7512558093,5.97544232672,292,,Lent,Lent,39,Jura,27,Bourgogne-Franche-Comté
39293,LESCHERES,39170,LESCHERES,,46.4602324129,5.829681829,293,,Leschères,Leschères,39,Jura,27,Bourgogne-Franche-Comté
39295,LOISIA,39320,LOISIA,,46.4942321244,5.46231630746,295,,Loisia,Loisia,39,Jura,27,Bourgogne-Franche-Comté
39296,LOMBARD,39230,LOMBARD,,46.7840338435,5.50626178194,296,,Lombard,Lombard,39,Jura,27,Bourgogne-Franche-Comté
39297,LONGCHAUMOIS,39400,LONGCHAUMOIS,,46.4634160554,5.96337151696,297,,Longchaumois,Longchaumois,39,Jura,27,Bourgogne-Franche-Comté
39298,LONGCOCHON,39250,LONGCOCHON,,46.7705510024,6.07491619337,298,,Longcochon,Longcochon,39,Jura,27,Bourgogne-Franche-Comté
39299,LONGWY SUR LE DOUBS,39120,LONGWY SUR LE DOUBS,,46.965571307,5.35428928661,299,,Longwy-sur-le-Doubs,Longwy-sur-le-Doubs,39,Jura,27,Bourgogne-Franche-Comté
39300,LONS LE SAUNIER,39000,LONS LE SAUNIER,,46.6744796278,5.55733212947,300,,Lons-le-Saunier,Lons-le-Saunier,39,Jura,27,Bourgogne-Franche-Comté
39301,LOULLE,39300,LOULLE,,46.7051852388,5.88350516541,301,,Loulle,Loulle,39,Jura,27,Bourgogne-Franche-Comté
39302,LOUVATANGE,39350,LOUVATANGE,,47.1895729875,5.72090559448,302,,Louvatange,Louvatange,39,Jura,27,Bourgogne-Franche-Comté
39303,LOUVENNE,39320,LOUVENNE,,46.4334398864,5.4778454951,303,,Louvenne,Louvenne,39,Jura,27,Bourgogne-Franche-Comté
39304,LE LOUVEROT,39210,LE LOUVEROT,,46.7328180633,5.58174416388,304,Le,Louverot,Le Louverot,39,Jura,27,Bourgogne-Franche-Comté
39305,LA LOYE,39380,LA LOYE,,47.0305064268,5.55264274121,305,La,Loye,La Loye,39,Jura,27,Bourgogne-Franche-Comté
39306,MACORNAY,39570,MACORNAY,,46.6419396973,5.54481097353,306,,Macornay,Macornay,39,Jura,27,Bourgogne-Franche-Comté
39307,MAISOD,39260,MAISOD,,46.4791457718,5.69233262933,307,,Maisod,Maisod,39,Jura,27,Bourgogne-Franche-Comté
39308,MALANGE,39700,MALANGE,,47.1838419032,5.61461645047,308,,Malange,Malange,39,Jura,27,Bourgogne-Franche-Comté
39309,MALLEREY,39190,MALLEREY,,46.6290480726,5.44743754408,309,,Mallerey,Mallerey,39,Jura,27,Bourgogne-Franche-Comté
39310,MANTRY,39230,MANTRY,,46.7949152079,5.55490459828,310,,Mantry,Mantry,39,Jura,27,Bourgogne-Franche-Comté
39312,MARIGNA SUR VALOUSE,39240,MARIGNA SUR VALOUSE,,46.4465766056,5.52802659429,312,,Marigna-sur-Valouse,Marigna-sur-Valouse,39,Jura,27,Bourgogne-Franche-Comté
39313,MARIGNY,39130,MARIGNY,,46.67956984,5.77875923329,313,,Marigny,Marigny,39,Jura,27,Bourgogne-Franche-Comté
39314,MARNEZIA,39270,MARNEZIA,,46.5759787765,5.63640981929,314,,Marnézia,Marnézia,39,Jura,27,Bourgogne-Franche-Comté
39315,MARNOZ,39110,MARNOZ,,46.9538587219,5.83609760607,315,,Marnoz,Marnoz,39,Jura,27,Bourgogne-Franche-Comté
39317,LA MARRE,39210,LA MARRE,,46.7337108015,5.70313863162,317,La,Marre,La Marre,39,Jura,27,Bourgogne-Franche-Comté
39318,MARTIGNA,39260,MARTIGNA,,46.3895643174,5.70098725643,318,,Martigna,Martigna,39,Jura,27,Bourgogne-Franche-Comté
39319,MATHENAY,39600,MATHENAY,,46.9295460326,5.67894165868,319,,Mathenay,Mathenay,39,Jura,27,Bourgogne-Franche-Comté
39320,MAYNAL,39190,MAYNAL,,46.5672563014,5.4124890744,320,,Maynal,Maynal,39,Jura,27,Bourgogne-Franche-Comté
39321,MENETRU LE VIGNOBLE,39210,MENETRU LE VIGNOBLE,,46.7745566014,5.63482619878,321,,Menétru-le-Vignoble,Menétru-le-Vignoble,39,Jura,27,Bourgogne-Franche-Comté
39322,MENETRUX EN JOUX,39130,MENETRUX EN JOUX,,46.6239722837,5.83804866069,322,,Menétrux-en-Joux,Menétrux-en-Joux,39,Jura,27,Bourgogne-Franche-Comté
39323,MENOTEY,39290,MENOTEY,,47.1678262318,5.50278789931,323,,Menotey,Menotey,39,Jura,27,Bourgogne-Franche-Comté
39324,MERONA,39270,MERONA,,46.5535338267,5.63571799156,324,,Mérona,Mérona,39,Jura,27,Bourgogne-Franche-Comté
39325,MESNAY,39600,MESNAY,,46.8999786113,5.81905626698,325,,Mesnay,Mesnay,39,Jura,27,Bourgogne-Franche-Comté
39326,MESNOIS,39130,MESNOIS,,46.5942298159,5.67721332871,326,,Mesnois,Mesnois,39,Jura,27,Bourgogne-Franche-Comté
39327,MESSIA SUR SORNE,39570,MESSIA SUR SORNE,,46.6631538185,5.51569429363,327,,Messia-sur-Sorne,Messia-sur-Sorne,39,Jura,27,Bourgogne-Franche-Comté
39328,MEUSSIA,39260,MEUSSIA,,46.4947553205,5.73946111468,328,,Meussia,Meussia,39,Jura,27,Bourgogne-Franche-Comté
39329,MIEGES,39250,MIEGES,,46.7873509128,6.04198451361,329,,Mièges,Mièges,39,Jura,27,Bourgogne-Franche-Comté
39329,MIEGES,39250,MIEGES,ESSERVAL COMBE,46.7873509128,6.04198451361,329,,Mièges,Mièges,39,Jura,27,Bourgogne-Franche-Comté
39329,MIEGES,39250,MIEGES,MOLPRE,46.7873509128,6.04198451361,329,,Mièges,Mièges,39,Jura,27,Bourgogne-Franche-Comté
39330,MIERY,39800,MIERY,,46.8065984707,5.66613178867,330,,Miéry,Miéry,39,Jura,27,Bourgogne-Franche-Comté
39331,MIGNOVILLARD,39250,MIGNOVILLARD,,46.7758072369,6.14394186083,331,,Mignovillard,Mignovillard,39,Jura,27,Bourgogne-Franche-Comté
39331,MIGNOVILLARD,39250,MIGNOVILLARD,COMMUNAILLES EN MONTAGNE,46.7758072369,6.14394186083,331,,Mignovillard,Mignovillard,39,Jura,27,Bourgogne-Franche-Comté
39331,MIGNOVILLARD,39250,MIGNOVILLARD,ESSAVILLY,46.7758072369,6.14394186083,331,,Mignovillard,Mignovillard,39,Jura,27,Bourgogne-Franche-Comté
39331,MIGNOVILLARD,39250,MIGNOVILLARD,FROIDEFONTAINE,46.7758072369,6.14394186083,331,,Mignovillard,Mignovillard,39,Jura,27,Bourgogne-Franche-Comté
39333,MOIRANS EN MONTAGNE,39260,MOIRANS EN MONTAGNE,,46.4388080084,5.73795329823,333,,Moirans-en-Montagne,Moirans-en-Montagne,39,Jura,27,Bourgogne-Franche-Comté
39334,MOIRON,39570,MOIRON,,46.6350035494,5.55979074338,334,,Moiron,Moiron,39,Jura,27,Bourgogne-Franche-Comté
39335,MOISSEY,39290,MOISSEY,,47.1885329382,5.53230467086,335,,Moissey,Moissey,39,Jura,27,Bourgogne-Franche-Comté
39336,MOLAIN,39800,MOLAIN,,46.8251611212,5.81711896426,336,,Molain,Molain,39,Jura,27,Bourgogne-Franche-Comté
39337,MOLAMBOZ,39600,MOLAMBOZ,,46.951688723,5.68053538603,337,,Molamboz,Molamboz,39,Jura,27,Bourgogne-Franche-Comté
39338,MOLAY,39500,MOLAY,,47.0155461717,5.42348925826,338,,Molay,Molay,39,Jura,27,Bourgogne-Franche-Comté
39339,MOLINGES,39360,MOLINGES,,46.35182997,5.76359169948,339,,Molinges,Molinges,39,Jura,27,Bourgogne-Franche-Comté
39341,LES MOLUNES,39310,LES MOLUNES,,46.3424655119,5.93198405387,341,Les,Molunes,Les Molunes,39,Jura,27,Bourgogne-Franche-Comté
39342,MONAY,39230,MONAY,,46.838724975,5.59503528853,342,,Monay,Monay,39,Jura,27,Bourgogne-Franche-Comté
39343,MONNETAY,39320,MONNETAY,,46.4576810006,5.51067177235,343,,Monnetay,Monnetay,39,Jura,27,Bourgogne-Franche-Comté
39344,MONNET LA VILLE,39300,MONNET LA VILLE,,46.7245823636,5.81585271352,344,,Monnet-la-Ville,Monnet-la-Ville,39,Jura,27,Bourgogne-Franche-Comté
39345,MONNIERES,39100,MONNIERES,,47.1140594899,5.46044213238,345,,Monnières,Monnières,39,Jura,27,Bourgogne-Franche-Comté
39346,MONTAGNA LE RECONDUIT,39160,MONTAGNA LE RECONDUIT,,46.4549069839,5.39107165834,346,,Montagna-le-Reconduit,Montagna-le-Reconduit,39,Jura,27,Bourgogne-Franche-Comté
39347,MONTAGNA LE TEMPLIER,39320,MONTAGNA LE TEMPLIER,,46.3585670926,5.46287459614,347,,Montagna-le-Templier,Montagna-le-Templier,39,Jura,27,Bourgogne-Franche-Comté
39348,MONTAIGU,39570,MONTAIGU,,46.6535706829,5.57330995671,348,,Montaigu,Montaigu,39,Jura,27,Bourgogne-Franche-Comté
39349,MONTAIN,39210,MONTAIN,,46.7209461149,5.57753660512,349,,Montain,Montain,39,Jura,27,Bourgogne-Franche-Comté
39350,MONTBARREY,39380,MONTBARREY,,47.0202426909,5.63748762259,350,,Montbarrey,Montbarrey,39,Jura,27,Bourgogne-Franche-Comté
39351,MONTCUSEL,39260,MONTCUSEL,,46.3561612254,5.66451408505,351,,Montcusel,Montcusel,39,Jura,27,Bourgogne-Franche-Comté
39352,MONTEPLAIN,39700,MONTEPLAIN,,47.1585531374,5.70976407571,352,,Monteplain,Monteplain,39,Jura,27,Bourgogne-Franche-Comté
39353,MONTFLEUR,39320,MONTFLEUR,,46.333233615,5.44582401435,353,,Montfleur,Montfleur,39,Jura,27,Bourgogne-Franche-Comté
39354,MONTHOLIER,39800,MONTHOLIER,,46.8945272746,5.63902103167,354,,Montholier,Montholier,39,Jura,27,Bourgogne-Franche-Comté
39355,MONTIGNY LES ARSURES,39600,MONTIGNY LES ARSURES,,46.9378384103,5.77619784355,355,,Montigny-lès-Arsures,Montigny-lès-Arsures,39,Jura,27,Bourgogne-Franche-Comté
39356,MONTIGNY SUR L AIN,39300,MONTIGNY SUR L AIN,,46.7050355286,5.79177737951,356,,Montigny-sur-l'Ain,Montigny-sur-l'Ain,39,Jura,27,Bourgogne-Franche-Comté
39359,MONTMARLON,39110,MONTMARLON,,46.87302574,5.96629816097,359,,Montmarlon,Montmarlon,39,Jura,27,Bourgogne-Franche-Comté
39360,MONTMIREY LA VILLE,39290,MONTMIREY LA VILLE,,47.2170742688,5.51651426821,360,,Montmirey-la-Ville,Montmirey-la-Ville,39,Jura,27,Bourgogne-Franche-Comté
39361,MONTMIREY LE CHATEAU,39290,MONTMIREY LE CHATEAU,,47.2299837949,5.53411913027,361,,Montmirey-le-Château,Montmirey-le-Château,39,Jura,27,Bourgogne-Franche-Comté
39362,MONTMOROT,39570,MONTMOROT,,46.6868843309,5.5196117795,362,,Montmorot,Montmorot,39,Jura,27,Bourgogne-Franche-Comté
39362,MONTMOROT,39570,MONTMOROT,SAVAGNA,46.6868843309,5.5196117795,362,,Montmorot,Montmorot,39,Jura,27,Bourgogne-Franche-Comté
39363,MONTREVEL,39320,MONTREVEL,,46.4260235257,5.49493800211,363,,Montrevel,Montrevel,39,Jura,27,Bourgogne-Franche-Comté
39364,MONTROND,39300,MONTROND,,46.7867874524,5.84385490665,364,,Montrond,Montrond,39,Jura,27,Bourgogne-Franche-Comté
39365,MONT SOUS VAUDREY,39380,MONT SOUS VAUDREY,,46.9631076045,5.59448295865,365,,Mont-sous-Vaudrey,Mont-sous-Vaudrey,39,Jura,27,Bourgogne-Franche-Comté
39366,MONT SUR MONNET,39300,MONT SUR MONNET,,46.7035726549,5.84464264755,366,,Mont-sur-Monnet,Mont-sur-Monnet,39,Jura,27,Bourgogne-Franche-Comté
39367,MORBIER,39400,MORBIER,,46.55692328,6.01610878304,367,,Morbier,Morbier,39,Jura,27,Bourgogne-Franche-Comté
39367,MORBIER,39400,MORBIER,TANCUA,46.55692328,6.01610878304,367,,Morbier,Morbier,39,Jura,27,Bourgogne-Franche-Comté
39368,HAUTS DE BIENNE,39400,HAUTS DE BIENNE,LA MOUILLE,46.5258425381,6.03328656487,368,,Hauts de Bienne,Hauts de Bienne,39,Jura,27,Bourgogne-Franche-Comté
39368,HAUTS DE BIENNE,39400,HAUTS DE BIENNE,LEZAT,46.5258425381,6.03328656487,368,,Hauts de Bienne,Hauts de Bienne,39,Jura,27,Bourgogne-Franche-Comté
39368,HAUTS DE BIENNE,39400,HAUTS DE BIENNE,MOREZ,46.5258425381,6.03328656487,368,,Hauts de Bienne,Hauts de Bienne,39,Jura,27,Bourgogne-Franche-Comté
39370,MOUCHARD,39330,MOUCHARD,,46.9775766117,5.78997395755,370,,Mouchard,Mouchard,39,Jura,27,Bourgogne-Franche-Comté
39370,MOUCHARD,39330,MOUCHARD,CERTEMERY,46.9775766117,5.78997395755,370,,Mouchard,Mouchard,39,Jura,27,Bourgogne-Franche-Comté
39372,MOURNANS CHARBONNY,39250,MOURNANS CHARBONNY,,46.7768224778,5.99039184457,372,,Mournans-Charbonny,Mournans-Charbonny,39,Jura,27,Bourgogne-Franche-Comté
39373,LES MOUSSIERES,39310,LES MOUSSIERES,,46.326906853,5.87285556861,373,Les,Moussières,Les Moussières,39,Jura,27,Bourgogne-Franche-Comté
39375,MOUTONNE,39270,MOUTONNE,,46.5305097539,5.56657538643,375,,Moutonne,Moutonne,39,Jura,27,Bourgogne-Franche-Comté
39376,MOUTOUX,39300,MOUTOUX,,46.790683672,5.94357919942,376,,Moutoux,Moutoux,39,Jura,27,Bourgogne-Franche-Comté
39377,MUTIGNEY,39290,MUTIGNEY,,47.2820712472,5.53021636732,377,,Mutigney,Mutigney,39,Jura,27,Bourgogne-Franche-Comté
39378,LES TROIS CHATEAUX,39160,LES TROIS CHATEAUX,CHAZELLES,46.4197619267,5.34914344308,378,Les,Trois Châteaux,Les Trois Châteaux,39,Jura,27,Bourgogne-Franche-Comté
39378,LES TROIS CHATEAUX,39160,LES TROIS CHATEAUX,L AUBEPIN,46.4197619267,5.34914344308,378,Les,Trois Châteaux,Les Trois Châteaux,39,Jura,27,Bourgogne-Franche-Comté
39378,LES TROIS CHATEAUX,39160,LES TROIS CHATEAUX,NANC LES ST AMOUR,46.4197619267,5.34914344308,378,Les,Trois Châteaux,Les Trois Châteaux,39,Jura,27,Bourgogne-Franche-Comté
39379,NANCE,39140,NANCE,,46.7487669868,5.42262133247,379,,Nance,Nance,39,Jura,27,Bourgogne-Franche-Comté
39380,NANCUISE,39270,NANCUISE,,46.4735098453,5.52975664064,380,,Nancuise,Nancuise,39,Jura,27,Bourgogne-Franche-Comté
39381,LES NANS,39300,LES NANS,,46.7942578925,5.97354217641,381,Les,Nans,Les Nans,39,Jura,27,Bourgogne-Franche-Comté
39385,NEUBLANS ABERGEMENT,39120,NEUBLANS ABERGEMENT,,46.9040741283,5.33914629457,385,,Neublans-Abergement,Neublans-Abergement,39,Jura,27,Bourgogne-Franche-Comté
39385,NEUBLANS ABERGEMENT,39120,NEUBLANS ABERGEMENT,ABERGEMENT ST JEAN,46.9040741283,5.33914629457,385,,Neublans-Abergement,Neublans-Abergement,39,Jura,27,Bourgogne-Franche-Comté
39386,NEUVILLEY,39800,NEUVILLEY,,46.8935992116,5.59976240857,386,,Neuvilley,Neuvilley,39,Jura,27,Bourgogne-Franche-Comté
39387,NEVY LES DOLE,39380,NEVY LES DOLE,,47.0024558065,5.5159174634,387,,Nevy-lès-Dole,Nevy-lès-Dole,39,Jura,27,Bourgogne-Franche-Comté
39388,NEVY SUR SEILLE,39210,NEVY SUR SEILLE,,46.7343478982,5.64536782346,388,,Nevy-sur-Seille,Nevy-sur-Seille,39,Jura,27,Bourgogne-Franche-Comté
39389,NEY,39300,NEY,,46.733302634,5.88549194483,389,,Ney,Ney,39,Jura,27,Bourgogne-Franche-Comté
39390,NOGNA,39570,NOGNA,,46.6029026515,5.64266648514,390,,Nogna,Nogna,39,Jura,27,Bourgogne-Franche-Comté
39391,NOZEROY,39250,NOZEROY,,46.7701820347,6.03611938375,391,,Nozeroy,Nozeroy,39,Jura,27,Bourgogne-Franche-Comté
39392,OFFLANGES,39290,OFFLANGES,,47.2038465796,5.56005963623,392,,Offlanges,Offlanges,39,Jura,27,Bourgogne-Franche-Comté
39393,ONGLIERES,39250,ONGLIERES,,46.8028448995,6.00043623502,393,,Onglières,Onglières,39,Jura,27,Bourgogne-Franche-Comté
39394,ONOZ,39270,ONOZ,,46.4480090992,5.66352512736,394,,Onoz,Onoz,39,Jura,27,Bourgogne-Franche-Comté
39395,ORBAGNA,39190,ORBAGNA,,46.5827744446,5.45159303337,395,,Orbagna,Orbagna,39,Jura,27,Bourgogne-Franche-Comté
39396,ORCHAMPS,39700,ORCHAMPS,,47.1540318861,5.66771529859,396,,Orchamps,Orchamps,39,Jura,27,Bourgogne-Franche-Comté
39397,ORGELET,39270,ORGELET,,46.5063359095,5.62225313092,397,,Orgelet,Orgelet,39,Jura,27,Bourgogne-Franche-Comté
39397,ORGELET,39270,ORGELET,SEZERIA,46.5063359095,5.62225313092,397,,Orgelet,Orgelet,39,Jura,27,Bourgogne-Franche-Comté
39398,OUGNEY,39350,OUGNEY,,47.2407071845,5.65989069882,398,,Ougney,Ougney,39,Jura,27,Bourgogne-Franche-Comté
39399,OUNANS,39380,OUNANS,,46.9901386128,5.66317598705,399,,Ounans,Ounans,39,Jura,27,Bourgogne-Franche-Comté
39400,OUR,39700,OUR,,47.1073810087,5.64890585265,400,,Our,Our,39,Jura,27,Bourgogne-Franche-Comté
39401,OUSSIERES,39800,OUSSIERES,,46.914654871,5.5977384615,401,,Oussières,Oussières,39,Jura,27,Bourgogne-Franche-Comté
39402,PAGNEY,39350,PAGNEY,,47.250391991,5.69969673686,402,,Pagney,Pagney,39,Jura,27,Bourgogne-Franche-Comté
39403,PAGNOZ,39330,PAGNOZ,,46.9741968646,5.82590898779,403,,Pagnoz,Pagnoz,39,Jura,27,Bourgogne-Franche-Comté
39404,PANNESSIERES,39570,PANNESSIERES,,46.6983683105,5.59466589018,404,,Pannessières,Pannessières,39,Jura,27,Bourgogne-Franche-Comté
39405,PARCEY,39100,PARCEY,,47.0223001054,5.47952052073,405,,Parcey,Parcey,39,Jura,27,Bourgogne-Franche-Comté
39406,LE PASQUIER,39300,LE PASQUIER,,46.7980920446,5.89702204167,406,Le,Pasquier,Le Pasquier,39,Jura,27,Bourgogne-Franche-Comté
39407,PASSENANS,39230,PASSENANS,,46.8041484132,5.62414168281,407,,Passenans,Passenans,39,Jura,27,Bourgogne-Franche-Comté
39408,PATORNAY,39130,PATORNAY,,46.5873552855,5.70873502503,408,,Patornay,Patornay,39,Jura,27,Bourgogne-Franche-Comté
39409,PEINTRE,39290,PEINTRE,,47.1980003971,5.47297179075,409,,Peintre,Peintre,39,Jura,27,Bourgogne-Franche-Comté
39411,PERRIGNY,39570,PERRIGNY,,46.676681519,5.59933078022,411,,Perrigny,Perrigny,39,Jura,27,Bourgogne-Franche-Comté
39412,PESEUX,39120,PESEUX,,46.9935199025,5.36641787398,412,,Peseux,Peseux,39,Jura,27,Bourgogne-Franche-Comté
39413,LA PESSE,39370,LA PESSE,,46.286468541,5.85238812673,413,La,Pesse,La Pesse,39,Jura,27,Bourgogne-Franche-Comté
39414,LE PETIT MERCEY,39350,LE PETIT MERCEY,,47.1961202234,5.74312951323,414,Le,Petit-Mercey,Le Petit-Mercey,39,Jura,27,Bourgogne-Franche-Comté
39415,PETIT NOIR,39120,PETIT NOIR,,46.9324882884,5.33765918128,415,,Petit-Noir,Petit-Noir,39,Jura,27,Bourgogne-Franche-Comté
39417,LES PIARDS,39150,LES PIARDS,,46.4964907135,5.82471455692,417,Les,Piards,Les Piards,39,Jura,27,Bourgogne-Franche-Comté
39418,PICARREAU,39800,PICARREAU,,46.7547633976,5.7569978699,418,,Picarreau,Picarreau,39,Jura,27,Bourgogne-Franche-Comté
39419,PILLEMOINE,39300,PILLEMOINE,,46.703904732,5.90805136913,419,,Pillemoine,Pillemoine,39,Jura,27,Bourgogne-Franche-Comté
39420,PIMORIN,39270,PIMORIN,,46.4958087462,5.50106506997,420,,Pimorin,Pimorin,39,Jura,27,Bourgogne-Franche-Comté
39421,LE PIN,39210,LE PIN,,46.7080786364,5.56997582613,421,Le,Pin,Le Pin,39,Jura,27,Bourgogne-Franche-Comté
39422,PLAINOISEAU,39210,PLAINOISEAU,,46.7252404633,5.55598412633,422,,Plainoiseau,Plainoiseau,39,Jura,27,Bourgogne-Franche-Comté
39423,PLAISIA,39270,PLAISIA,,46.5243667153,5.63724392564,423,,Plaisia,Plaisia,39,Jura,27,Bourgogne-Franche-Comté
39424,LES PLANCHES EN MONTAGNE,39150,LES PLANCHES EN MONTAGNE,,46.6636417264,6.00067607182,424,Les,Planches-en-Montagne,Les Planches-en-Montagne,39,Jura,27,Bourgogne-Franche-Comté
39424,LES PLANCHES EN MONTAGNE,39150,LES PLANCHES EN MONTAGNE,LA PERRENA,46.6636417264,6.00067607182,424,Les,Planches-en-Montagne,Les Planches-en-Montagne,39,Jura,27,Bourgogne-Franche-Comté
39425,LES PLANCHES PRES ARBOIS,39600,LES PLANCHES PRES ARBOIS,,46.8806181055,5.80844995624,425,Les,Planches-près-Arbois,Les Planches-près-Arbois,39,Jura,27,Bourgogne-Franche-Comté
39426,PLASNE,39210,PLASNE,,46.7948712678,5.69040057232,426,,Plasne,Plasne,39,Jura,27,Bourgogne-Franche-Comté
39426,PLASNE,39800,PLASNE,,46.7948712678,5.69040057232,426,,Plasne,Plasne,39,Jura,27,Bourgogne-Franche-Comté
39427,PLENISE,39250,PLENISE,,46.8151075065,6.0199524043,427,,Plénise,Plénise,39,Jura,27,Bourgogne-Franche-Comté
39428,PLENISETTE,39250,PLENISETTE,,46.8047324429,6.01569148453,428,,Plénisette,Plénisette,39,Jura,27,Bourgogne-Franche-Comté
39429,PLEURE,39120,PLEURE,,46.9155544802,5.46006716297,429,,Pleure,Pleure,39,Jura,27,Bourgogne-Franche-Comté
39430,PLUMONT,39700,PLUMONT,,47.1107713275,5.71692693356,430,,Plumont,Plumont,39,Jura,27,Bourgogne-Franche-Comté
39431,POIDS DE FIOLE,39570,POIDS DE FIOLE,,46.5944604506,5.62239260294,431,,Poids-de-Fiole,Poids-de-Fiole,39,Jura,27,Bourgogne-Franche-Comté
39432,POINTRE,39290,POINTRE,,47.2219181115,5.49075761084,432,,Pointre,Pointre,39,Jura,27,Bourgogne-Franche-Comté
39434,POLIGNY,39800,POLIGNY,,46.8222386118,5.73292565089,434,,Poligny,Poligny,39,Jura,27,Bourgogne-Franche-Comté
39435,PONT DE POITTE,39130,PONT DE POITTE,,46.5717848345,5.68822628964,435,,Pont-de-Poitte,Pont-de-Poitte,39,Jura,27,Bourgogne-Franche-Comté
39436,PONT D HERY,39110,PONT D HERY,,46.8762807232,5.89928454106,436,,Pont-d'Héry,Pont-d'Héry,39,Jura,27,Bourgogne-Franche-Comté
39436,PONT D HERY,39110,PONT D HERY,FONTENY,46.8762807232,5.89928454106,436,,Pont-d'Héry,Pont-d'Héry,39,Jura,27,Bourgogne-Franche-Comté
39436,PONT D HERY,39110,PONT D HERY,MOUTAINE,46.8762807232,5.89928454106,436,,Pont-d'Héry,Pont-d'Héry,39,Jura,27,Bourgogne-Franche-Comté
39437,PONT DU NAVOY,39300,PONT DU NAVOY,,46.7317231278,5.78965012447,437,,Pont-du-Navoy,Pont-du-Navoy,39,Jura,27,Bourgogne-Franche-Comté
39439,PORT LESNEY,39330,PORT LESNEY,,46.9984173967,5.81915102369,439,,Port-Lesney,Port-Lesney,39,Jura,27,Bourgogne-Franche-Comté
39439,PORT LESNEY,39600,PORT LESNEY,,46.9984173967,5.81915102369,439,,Port-Lesney,Port-Lesney,39,Jura,27,Bourgogne-Franche-Comté
39440,PRATZ,39170,PRATZ,,46.3813670671,5.75112817466,440,,Pratz,Pratz,39,Jura,27,Bourgogne-Franche-Comté
39441,PREMANON,39220,PREMANON,,46.4538422377,6.03492977371,441,,Prémanon,Prémanon,39,Jura,27,Bourgogne-Franche-Comté
39441,PREMANON,39400,PREMANON,,46.4538422377,6.03492977371,441,,Prémanon,Prémanon,39,Jura,27,Bourgogne-Franche-Comté
39441,PREMANON,39400,PREMANON,LA DOYE,46.4538422377,6.03492977371,441,,Prémanon,Prémanon,39,Jura,27,Bourgogne-Franche-Comté
39443,PRESILLY,39270,PRESILLY,,46.5577104313,5.58192329042,443,,Présilly,Présilly,39,Jura,27,Bourgogne-Franche-Comté
39444,PRETIN,39110,PRETIN,,46.9318790423,5.83502005911,444,,Pretin,Pretin,39,Jura,27,Bourgogne-Franche-Comté
39445,PUBLY,39570,PUBLY,,46.6295899553,5.65124745442,445,,Publy,Publy,39,Jura,27,Bourgogne-Franche-Comté
39446,PUPILLIN,39600,PUPILLIN,,46.8763553965,5.75235125424,446,,Pupillin,Pupillin,39,Jura,27,Bourgogne-Franche-Comté
39447,QUINTIGNY,39570,QUINTIGNY,,46.7343660477,5.52473105076,447,,Quintigny,Quintigny,39,Jura,27,Bourgogne-Franche-Comté
39448,RAHON,39120,RAHON,,46.9938029264,5.47383936755,448,,Rahon,Rahon,39,Jura,27,Bourgogne-Franche-Comté
39449,RAINANS,39290,RAINANS,,47.1574395405,5.47297750581,449,,Rainans,Rainans,39,Jura,27,Bourgogne-Franche-Comté
39451,RANCHOT,39700,RANCHOT,,47.1593990404,5.71910019081,451,,Ranchot,Ranchot,39,Jura,27,Bourgogne-Franche-Comté
39452,RANS,39700,RANS,,47.1379973135,5.71557221033,452,,Rans,Rans,39,Jura,27,Bourgogne-Franche-Comté
39453,RAVILLOLES,39170,RAVILLOLES,,46.4339212341,5.80032186037,453,,Ravilloles,Ravilloles,39,Jura,27,Bourgogne-Franche-Comté
39454,RECANOZ,39230,RECANOZ,,46.8075358585,5.50670929892,454,,Recanoz,Recanoz,39,Jura,27,Bourgogne-Franche-Comté
39455,REITHOUSE,39270,REITHOUSE,,46.5602761319,5.55586322234,455,,Reithouse,Reithouse,39,Jura,27,Bourgogne-Franche-Comté
39456,RELANS,39140,RELANS,,46.7663121226,5.43804546339,456,,Relans,Relans,39,Jura,27,Bourgogne-Franche-Comté
39457,LES REPOTS,39140,LES REPOTS,,46.6851813053,5.413825352,457,Les,Repôts,Les Repôts,39,Jura,27,Bourgogne-Franche-Comté
39458,REVIGNY,39570,REVIGNY,,46.627894009,5.60893861007,458,,Revigny,Revigny,39,Jura,27,Bourgogne-Franche-Comté
39460,LA RIXOUSE,39200,LA RIXOUSE,,46.4680659486,5.87063416847,460,La,Rixouse,La Rixouse,39,Jura,27,Bourgogne-Franche-Comté
39461,RIX,39250,RIX,,46.7622805418,6.05502144561,461,,Rix,Rix,39,Jura,27,Bourgogne-Franche-Comté
39462,ROCHEFORT SUR NENON,39700,ROCHEFORT SUR NENON,,47.1226290709,5.55631642318,462,,Rochefort-sur-Nenon,Rochefort-sur-Nenon,39,Jura,27,Bourgogne-Franche-Comté
39463,ROGNA,39360,ROGNA,,46.3276553176,5.74438712926,463,,Rogna,Rogna,39,Jura,27,Bourgogne-Franche-Comté
39464,ROMAIN,39350,ROMAIN,,47.2075786378,5.71828846623,464,,Romain,Romain,39,Jura,27,Bourgogne-Franche-Comté
39465,ROMANGE,39700,ROMANGE,,47.1606289716,5.59427997843,465,,Romange,Romange,39,Jura,27,Bourgogne-Franche-Comté
39466,ROSAY,39190,ROSAY,,46.5343336422,5.45282636433,466,,Rosay,Rosay,39,Jura,27,Bourgogne-Franche-Comté
39467,ROTALIER,39190,ROTALIER,,46.5832064756,5.47700815924,467,,Rotalier,Rotalier,39,Jura,27,Bourgogne-Franche-Comté
39468,ROTHONAY,39270,ROTHONAY,,46.515255602,5.52762290044,468,,Rothonay,Rothonay,39,Jura,27,Bourgogne-Franche-Comté
39469,ROUFFANGE,39350,ROUFFANGE,,47.2263565979,5.70867477091,469,,Rouffange,Rouffange,39,Jura,27,Bourgogne-Franche-Comté
39470,LES ROUSSES,39220,LES ROUSSES,,46.4984696055,6.07006530541,470,Les,Rousses,Les Rousses,39,Jura,27,Bourgogne-Franche-Comté
39470,LES ROUSSES,39220,LES ROUSSES,LA CURE,46.4984696055,6.07006530541,470,Les,Rousses,Les Rousses,39,Jura,27,Bourgogne-Franche-Comté
39470,LES ROUSSES,39400,LES ROUSSES,,46.4984696055,6.07006530541,470,Les,Rousses,Les Rousses,39,Jura,27,Bourgogne-Franche-Comté
39470,LES ROUSSES,39400,LES ROUSSES,LES ARCETS,46.4984696055,6.07006530541,470,Les,Rousses,Les Rousses,39,Jura,27,Bourgogne-Franche-Comté
39470,LES ROUSSES,39400,LES ROUSSES,LES RIVIERES,46.4984696055,6.07006530541,470,Les,Rousses,Les Rousses,39,Jura,27,Bourgogne-Franche-Comté
39471,RUFFEY SUR SEILLE,39140,RUFFEY SUR SEILLE,,46.7294818864,5.49587228599,471,,Ruffey-sur-Seille,Ruffey-sur-Seille,39,Jura,27,Bourgogne-Franche-Comté
39472,RYE,39230,RYE,,46.8805291309,5.42595524995,472,,Rye,Rye,39,Jura,27,Bourgogne-Franche-Comté
39473,SAFFLOZ,39130,SAFFLOZ,,46.6690423566,5.86347690885,473,,Saffloz,Saffloz,39,Jura,27,Bourgogne-Franche-Comté
39474,STE AGNES,39190,STE AGNES,,46.6148301302,5.47488282815,474,,Sainte-Agnès,Sainte-Agnès,39,Jura,27,Bourgogne-Franche-Comté
39475,ST AMOUR,39160,ST AMOUR,,46.4375471402,5.33561825227,475,,Saint-Amour,Saint-Amour,39,Jura,27,Bourgogne-Franche-Comté
39476,ST AUBIN,39410,ST AUBIN,,47.0326920185,5.33365017851,476,,Saint-Aubin,Saint-Aubin,39,Jura,27,Bourgogne-Franche-Comté
39477,ST BARAING,39120,ST BARAING,,46.9866522885,5.43420518172,477,,Saint-Baraing,Saint-Baraing,39,Jura,27,Bourgogne-Franche-Comté
39478,ST CLAUDE,39200,ST CLAUDE,,46.408600398,5.87556247635,478,,Saint-Claude,Saint-Claude,39,Jura,27,Bourgogne-Franche-Comté
39478,ST CLAUDE,39200,ST CLAUDE,CHAUMONT,46.408600398,5.87556247635,478,,Saint-Claude,Saint-Claude,39,Jura,27,Bourgogne-Franche-Comté
39478,ST CLAUDE,39200,ST CLAUDE,CHEVRY,46.408600398,5.87556247635,478,,Saint-Claude,Saint-Claude,39,Jura,27,Bourgogne-Franche-Comté
39478,ST CLAUDE,39200,ST CLAUDE,CINQUETRAL,46.408600398,5.87556247635,478,,Saint-Claude,Saint-Claude,39,Jura,27,Bourgogne-Franche-Comté
39478,ST CLAUDE,39200,ST CLAUDE,RANCHETTE,46.408600398,5.87556247635,478,,Saint-Claude,Saint-Claude,39,Jura,27,Bourgogne-Franche-Comté
39478,ST CLAUDE,39200,ST CLAUDE,VALFIN LES ST CLAUDE,46.408600398,5.87556247635,478,,Saint-Claude,Saint-Claude,39,Jura,27,Bourgogne-Franche-Comté
39479,ST CYR MONTMALIN,39600,ST CYR MONTMALIN,,46.959233635,5.72305044676,479,,Saint-Cyr-Montmalin,Saint-Cyr-Montmalin,39,Jura,27,Bourgogne-Franche-Comté
39479,ST CYR MONTMALIN,39600,ST CYR MONTMALIN,MONTMALIN,46.959233635,5.72305044676,479,,Saint-Cyr-Montmalin,Saint-Cyr-Montmalin,39,Jura,27,Bourgogne-Franche-Comté
39480,ST DIDIER,39570,ST DIDIER,,46.7082790966,5.51275222992,480,,Saint-Didier,Saint-Didier,39,Jura,27,Bourgogne-Franche-Comté
39481,ST GERMAIN EN MONTAGNE,39300,ST GERMAIN EN MONTAGNE,,46.7790941219,5.93796452376,481,,Saint-Germain-en-Montagne,Saint-Germain-en-Montagne,39,Jura,27,Bourgogne-Franche-Comté
39483,ST HYMETIERE,39240,ST HYMETIERE,,46.3608074335,5.54924854674,483,,Saint-Hymetière,Saint-Hymetière,39,Jura,27,Bourgogne-Franche-Comté
39484,ST JEAN D ETREUX,39160,ST JEAN D ETREUX,,46.4055764257,5.36106967349,484,,Saint-Jean-d'Étreux,Saint-Jean-d'Étreux,39,Jura,27,Bourgogne-Franche-Comté
39485,ST JULIEN,39320,ST JULIEN,,46.3963208,5.4577858737,485,,Val Suran,Val Suran,39,Jura,27,Bourgogne-Franche-Comté
39486,ST LAMAIN,39230,ST LAMAIN,,46.8042039085,5.59805956986,486,,Saint-Lamain,Saint-Lamain,39,Jura,27,Bourgogne-Franche-Comté
39487,ST LAURENT EN GRANDVAUX,39150,ST LAURENT EN GRANDVAUX,,46.5694227071,5.96258087921,487,,Saint-Laurent-en-Grandvaux,Saint-Laurent-en-Grandvaux,39,Jura,27,Bourgogne-Franche-Comté
39489,ST LOTHAIN,39230,ST LOTHAIN,,46.8280842983,5.63947643961,489,,Saint-Lothain,Saint-Lothain,39,Jura,27,Bourgogne-Franche-Comté
39490,ST LOUP,39120,ST LOUP,,46.9965194373,5.30532053825,490,,Saint-Loup,Saint-Loup,39,Jura,27,Bourgogne-Franche-Comté
39491,ST LUPICIN,39170,ST LUPICIN,,46.4096809138,5.7860900246,491,,Coteaux du Lizon,Coteaux du Lizon,39,Jura,27,Bourgogne-Franche-Comté
39492,ST MAUR,39570,ST MAUR,,46.6068663882,5.5959464108,492,,Saint-Maur,Saint-Maur,39,Jura,27,Bourgogne-Franche-Comté
39493,ST MAURICE CRILLAT,39130,ST MAURICE CRILLAT,,46.5680782643,5.83386083988,493,,Saint-Maurice-Crillat,Saint-Maurice-Crillat,39,Jura,27,Bourgogne-Franche-Comté
39493,ST MAURICE CRILLAT,39130,ST MAURICE CRILLAT,CRILLAT,46.5680782643,5.83386083988,493,,Saint-Maurice-Crillat,Saint-Maurice-Crillat,39,Jura,27,Bourgogne-Franche-Comté
39494,ST PIERRE,39150,ST PIERRE,,46.5683636515,5.90301429491,494,,Saint-Pierre,Saint-Pierre,39,Jura,27,Bourgogne-Franche-Comté
39495,ST THIEBAUD,39110,ST THIEBAUD,,46.9717927523,5.8808709853,495,,Saint-Thiébaud,Saint-Thiébaud,39,Jura,27,Bourgogne-Franche-Comté
39497,SAIZENAY,39110,SAIZENAY,,46.9701806797,5.93379044205,497,,Saizenay,Saizenay,39,Jura,27,Bourgogne-Franche-Comté
39498,SALANS,39700,SALANS,,47.1599346715,5.79436100645,498,,Salans,Salans,39,Jura,27,Bourgogne-Franche-Comté
39499,SALIGNEY,39350,SALIGNEY,,47.2229745913,5.63681673671,499,,Saligney,Saligney,39,Jura,27,Bourgogne-Franche-Comté
39500,SALINS LES BAINS,39110,SALINS LES BAINS,,46.9478351925,5.89762240995,500,,Salins-les-Bains,Salins-les-Bains,39,Jura,27,Bourgogne-Franche-Comté
39501,SAMPANS,39100,SAMPANS,,47.127381536,5.44517926709,501,,Sampans,Sampans,39,Jura,27,Bourgogne-Franche-Comté
39502,SANTANS,39380,SANTANS,,47.0417658474,5.66601850009,502,,Santans,Santans,39,Jura,27,Bourgogne-Franche-Comté
39503,SAPOIS,39300,SAPOIS,,46.7473328196,5.94642241824,503,,Sapois,Sapois,39,Jura,27,Bourgogne-Franche-Comté
39504,SARROGNA,39270,SARROGNA,,46.4739811377,5.61763286818,504,,Sarrogna,Sarrogna,39,Jura,27,Bourgogne-Franche-Comté
39504,SARROGNA,39270,SARROGNA,MARANGEA,46.4739811377,5.61763286818,504,,Sarrogna,Sarrogna,39,Jura,27,Bourgogne-Franche-Comté
39504,SARROGNA,39270,SARROGNA,MONTJOUVENT,46.4739811377,5.61763286818,504,,Sarrogna,Sarrogna,39,Jura,27,Bourgogne-Franche-Comté
39504,SARROGNA,39270,SARROGNA,NERMIER,46.4739811377,5.61763286818,504,,Sarrogna,Sarrogna,39,Jura,27,Bourgogne-Franche-Comté
39505,SAUGEOT,39130,SAUGEOT,,46.6037434659,5.82427975186,505,,Saugeot,Saugeot,39,Jura,27,Bourgogne-Franche-Comté
39506,SAVIGNA,39240,SAVIGNA,,46.4377867074,5.56847582473,506,,Savigna,Savigna,39,Jura,27,Bourgogne-Franche-Comté
39507,SELIGNEY,39120,SELIGNEY,,46.9441078421,5.54072749866,507,,Séligney,Séligney,39,Jura,27,Bourgogne-Franche-Comté
39508,SELLIERES,39230,SELLIERES,,46.8254197035,5.55675238512,508,,Sellières,Sellières,39,Jura,27,Bourgogne-Franche-Comté
39510,SEPTMONCEL,39310,SEPTMONCEL,,46.3731296175,5.9231555329,510,,Septmoncel les Molunes,Septmoncel les Molunes,39,Jura,27,Bourgogne-Franche-Comté
39511,SERGENAUX,39230,SERGENAUX,,46.887689456,5.46257846527,511,,Sergenaux,Sergenaux,39,Jura,27,Bourgogne-Franche-Comté
39512,SERGENON,39120,SERGENON,,46.9021977763,5.46665354041,512,,Sergenon,Sergenon,39,Jura,27,Bourgogne-Franche-Comté
39513,SERMANGE,39700,SERMANGE,,47.1972336994,5.64802600593,513,,Sermange,Sermange,39,Jura,27,Bourgogne-Franche-Comté
39514,SERRE LES MOULIERES,39700,SERRE LES MOULIERES,,47.2058304341,5.61405890901,514,,Serre-les-Moulières,Serre-les-Moulières,39,Jura,27,Bourgogne-Franche-Comté
39517,SIROD,39300,SIROD,,46.7231337184,5.99171836651,517,,Sirod,Sirod,39,Jura,27,Bourgogne-Franche-Comté
39517,SIROD,39300,SIROD,TREFFAY,46.7231337184,5.99171836651,517,,Sirod,Sirod,39,Jura,27,Bourgogne-Franche-Comté
39518,SONGESON,39130,SONGESON,,46.6469389585,5.83038429289,518,,Songeson,Songeson,39,Jura,27,Bourgogne-Franche-Comté
39519,SOUCIA,39130,SOUCIA,,46.5406307782,5.75722120552,519,,Soucia,Soucia,39,Jura,27,Bourgogne-Franche-Comté
39520,SOUVANS,39380,SOUVANS,,46.9790356987,5.55350631285,520,,Souvans,Souvans,39,Jura,27,Bourgogne-Franche-Comté
39522,SUPT,39300,SUPT,,46.8540902792,5.9817269733,522,,Supt,Supt,39,Jura,27,Bourgogne-Franche-Comté
39523,SYAM,39300,SYAM,,46.6963307591,5.95140796308,523,,Syam,Syam,39,Jura,27,Bourgogne-Franche-Comté
39525,TASSENIERES,39120,TASSENIERES,,46.9232742265,5.51257337685,525,,Tassenières,Tassenières,39,Jura,27,Bourgogne-Franche-Comté
39526,TAVAUX,39500,TAVAUX,,47.0377459686,5.40319060267,526,,Tavaux,Tavaux,39,Jura,27,Bourgogne-Franche-Comté
39527,TAXENNE,39350,TAXENNE,,47.2216655725,5.68668052048,527,,Taxenne,Taxenne,39,Jura,27,Bourgogne-Franche-Comté
39528,THERVAY,39290,THERVAY,,47.2404016854,5.609254394,528,,Thervay,Thervay,39,Jura,27,Bourgogne-Franche-Comté
39529,THESY,39110,THESY,,46.9050803544,5.92892150176,529,,Thésy,Thésy,39,Jura,27,Bourgogne-Franche-Comté
39530,THOIRETTE,39240,THOIRETTE,,46.2790299356,5.52403173219,530,,Thoirette-Coisia,Thoirette-Coisia,39,Jura,27,Bourgogne-Franche-Comté
39531,THOIRIA,39130,THOIRIA,,46.5197602704,5.74628964258,531,,Thoiria,Thoiria,39,Jura,27,Bourgogne-Franche-Comté
39532,THOISSIA,39160,THOISSIA,,46.4261882926,5.39554935173,532,,Thoissia,Thoissia,39,Jura,27,Bourgogne-Franche-Comté
39533,TOULOUSE LE CHATEAU,39230,TOULOUSE LE CHATEAU,,46.8222128801,5.58308413365,533,,Toulouse-le-Château,Toulouse-le-Château,39,Jura,27,Bourgogne-Franche-Comté
39534,LA TOUR DU MEIX,39270,LA TOUR DU MEIX,,46.5234739504,5.66329877831,534,La,Tour-du-Meix,La Tour-du-Meix,39,Jura,27,Bourgogne-Franche-Comté
39535,TOURMONT,39800,TOURMONT,,46.8662991195,5.66636250629,535,,Tourmont,Tourmont,39,Jura,27,Bourgogne-Franche-Comté
39537,TRENAL,39570,TRENAL,,46.6396568445,5.46886995687,537,,Trenal,Trenal,39,Jura,27,Bourgogne-Franche-Comté
39538,UXELLES,39130,UXELLES,,46.6023318887,5.79058754069,538,,Uxelles,Uxelles,39,Jura,27,Bourgogne-Franche-Comté
39539,VADANS,39600,VADANS,,46.9374946134,5.70594512087,539,,Vadans,Vadans,39,Jura,27,Bourgogne-Franche-Comté
39540,VALEMPOULIERES,39300,VALEMPOULIERES,,46.8281892664,5.87420654933,540,,Valempoulières,Valempoulières,39,Jura,27,Bourgogne-Franche-Comté
39542,VALFIN SUR VALOUSE,39240,VALFIN SUR VALOUSE,,46.373981963,5.52227876385,542,,Valfin-sur-Valouse,Valfin-sur-Valouse,39,Jura,27,Bourgogne-Franche-Comté
39543,VANNOZ,39300,VANNOZ,,46.7732781009,5.91191822689,543,,Vannoz,Vannoz,39,Jura,27,Bourgogne-Franche-Comté
39545,LE VAUDIOUX,39300,LE VAUDIOUX,,46.6887181066,5.92823875732,545,Le,Vaudioux,Le Vaudioux,39,Jura,27,Bourgogne-Franche-Comté
39546,VAUDREY,39380,VAUDREY,,46.9662782086,5.62672865692,546,,Vaudrey,Vaudrey,39,Jura,27,Bourgogne-Franche-Comté
39547,VAUX LES ST CLAUDE,39360,VAUX LES ST CLAUDE,,46.354121477,5.73284459773,547,,Vaux-lès-Saint-Claude,Vaux-lès-Saint-Claude,39,Jura,27,Bourgogne-Franche-Comté
39548,VAUX SUR POLIGNY,39800,VAUX SUR POLIGNY,,46.8230228356,5.72769447335,548,,Vaux-sur-Poligny,Vaux-sur-Poligny,39,Jura,27,Bourgogne-Franche-Comté
39549,VERCIA,39190,VERCIA,,46.5966616094,5.45423129376,549,,Vercia,Vercia,39,Jura,27,Bourgogne-Franche-Comté
39550,VERGES,39570,VERGES,,46.6591469123,5.68477020801,550,,Verges,Verges,39,Jura,27,Bourgogne-Franche-Comté
39551,VERIA,39160,VERIA,,46.4578080563,5.42219614387,551,,Véria,Véria,39,Jura,27,Bourgogne-Franche-Comté
39552,VERNANTOIS,39570,VERNANTOIS,,46.6270087892,5.57793673726,552,,Vernantois,Vernantois,39,Jura,27,Bourgogne-Franche-Comté
39553,LE VERNOIS,39210,LE VERNOIS,,46.7320324635,5.59406809872,553,Le,Vernois,Le Vernois,39,Jura,27,Bourgogne-Franche-Comté
39554,VERS EN MONTAGNE,39300,VERS EN MONTAGNE,,46.8221705965,5.91238852828,554,,Vers-en-Montagne,Vers-en-Montagne,39,Jura,27,Bourgogne-Franche-Comté
39555,VERS SOUS SELLIERES,39230,VERS SOUS SELLIERES,,46.8243900195,5.52600045116,555,,Vers-sous-Sellières,Vers-sous-Sellières,39,Jura,27,Bourgogne-Franche-Comté
39556,VERTAMBOZ,39130,VERTAMBOZ,,46.6012469433,5.7478516954,556,,Vertamboz,Vertamboz,39,Jura,27,Bourgogne-Franche-Comté
39557,VESCLES,39240,VESCLES,,46.3570164509,5.61102870166,557,,Vescles,Vescles,39,Jura,27,Bourgogne-Franche-Comté
39558,VEVY,39570,VEVY,,46.6664465955,5.66350133684,558,,Vevy,Vevy,39,Jura,27,Bourgogne-Franche-Comté
39559,LA VIEILLE LOYE,39380,LA VIEILLE LOYE,,47.05632725,5.63130626887,559,La,Vieille-Loye,La Vieille-Loye,39,Jura,27,Bourgogne-Franche-Comté
39560,VILLARD ST SAUVEUR,39200,VILLARD ST SAUVEUR,,46.3623029643,5.86316334323,560,,Villard-Saint-Sauveur,Villard-Saint-Sauveur,39,Jura,27,Bourgogne-Franche-Comté
39561,VILLARDS D HERIA,39260,VILLARDS D HERIA,,46.405625846,5.73583236127,561,,Villards-d'Héria,Villards-d'Héria,39,Jura,27,Bourgogne-Franche-Comté
39562,VILLARD SUR BIENNE,39150,VILLARD SUR BIENNE,LES CROZATS,46.4858586552,5.89736415206,562,,Villard-sur-Bienne,Villard-sur-Bienne,39,Jura,27,Bourgogne-Franche-Comté
39562,VILLARD SUR BIENNE,39200,VILLARD SUR BIENNE,,46.4858586552,5.89736415206,562,,Villard-sur-Bienne,Villard-sur-Bienne,39,Jura,27,Bourgogne-Franche-Comté
39564,VILLECHANTRIA,39320,VILLECHANTRIA,,46.3763797844,5.43861580418,564,,Villechantria,Villechantria,39,Jura,27,Bourgogne-Franche-Comté
39565,VILLENEUVE D AVAL,39600,VILLENEUVE D AVAL,,46.9695447398,5.74785928595,565,,Villeneuve-d'Aval,Villeneuve-d'Aval,39,Jura,27,Bourgogne-Franche-Comté
39566,VILLENEUVE LES CHARNOD,39240,VILLENEUVE LES CHARNOD,,46.3382972959,5.47691970978,566,,Villeneuve-lès-Charnod,Villeneuve-lès-Charnod,39,Jura,27,Bourgogne-Franche-Comté
39567,VILLENEUVE SOUS PYMONT,39570,VILLENEUVE SOUS PYMONT,,46.6949967041,5.55277319697,567,,Villeneuve-sous-Pymont,Villeneuve-sous-Pymont,39,Jura,27,Bourgogne-Franche-Comté
39568,VILLERSERINE,39800,VILLERSERINE,,46.8551002036,5.63948186279,568,,Villerserine,Villerserine,39,Jura,27,Bourgogne-Franche-Comté
39569,VILLERS FARLAY,39600,VILLERS FARLAY,,46.9938950841,5.75983191768,569,,Villers-Farlay,Villers-Farlay,39,Jura,27,Bourgogne-Franche-Comté
39570,VILLERS LES BOIS,39120,VILLERS LES BOIS,,46.9258758572,5.56896755251,570,,Villers-les-Bois,Villers-les-Bois,39,Jura,27,Bourgogne-Franche-Comté
39570,VILLERS LES BOIS,39800,VILLERS LES BOIS,,46.9258758572,5.56896755251,570,,Villers-les-Bois,Villers-les-Bois,39,Jura,27,Bourgogne-Franche-Comté
39571,VILLERS ROBERT,39120,VILLERS ROBERT,,46.967058004,5.5201774249,571,,Villers-Robert,Villers-Robert,39,Jura,27,Bourgogne-Franche-Comté
39572,VILLETTE LES ARBOIS,39600,VILLETTE LES ARBOIS,,46.9336037574,5.74365046163,572,,Villette-lès-Arbois,Villette-lès-Arbois,39,Jura,27,Bourgogne-Franche-Comté
39573,VILLETTE LES DOLE,39100,VILLETTE LES DOLE,,47.0447105798,5.49409739566,573,,Villette-lès-Dole,Villette-lès-Dole,39,Jura,27,Bourgogne-Franche-Comté
39574,VILLEVIEUX,39140,VILLEVIEUX,,46.7279455221,5.44793336275,574,,Villevieux,Villevieux,39,Jura,27,Bourgogne-Franche-Comté
39575,LE VILLEY,39230,LE VILLEY,,46.8482451972,5.507416249,575,Le,Villey,Le Villey,39,Jura,27,Bourgogne-Franche-Comté
39576,VINCELLES,39190,VINCELLES,,46.606775751,5.46350720419,576,,Val-Sonnette,Val-Sonnette,39,Jura,27,Bourgogne-Franche-Comté
39577,VINCENT FROIDEVILLE,39230,VINCENT FROIDEVILLE,,46.787754383,5.48243275672,577,,Vincent-Froideville,Vincent-Froideville,39,Jura,27,Bourgogne-Franche-Comté
39577,VINCENT FROIDEVILLE,39230,VINCENT FROIDEVILLE,FROIDEVILLE,46.787754383,5.48243275672,577,,Vincent-Froideville,Vincent-Froideville,39,Jura,27,Bourgogne-Franche-Comté
39579,VIRY,39360,VIRY,,46.2973592921,5.73090207526,579,,Viry,Viry,39,Jura,27,Bourgogne-Franche-Comté
39581,VITREUX,39350,VITREUX,,47.2539408578,5.67565099235,581,,Vitreux,Vitreux,39,Jura,27,Bourgogne-Franche-Comté
39582,VOITEUR,39210,VOITEUR,,46.743219074,5.60355195147,582,,Voiteur,Voiteur,39,Jura,27,Bourgogne-Franche-Comté
39583,VOSBLES,39240,VOSBLES,,46.330813164,5.52161214243,583,,Vosbles-Valfin,Vosbles-Valfin,39,Jura,27,Bourgogne-Franche-Comté
39584,VRIANGE,39700,VRIANGE,,47.1836632309,5.58403876359,584,,Vriange,Vriange,39,Jura,27,Bourgogne-Franche-Comté
39585,VULVOZ,39360,VULVOZ,,46.321205129,5.78533983995,585,,Vulvoz,Vulvoz,39,Jura,27,Bourgogne-Franche-Comté
39586,ARESCHES,39110,ARESCHES,,46.8933326292,5.91985610861,586,,Aresches,Aresches,39,Jura,27,Bourgogne-Franche-Comté
40001,AIRE SUR L ADOUR,40800,AIRE SUR L ADOUR,,43.6983525736,-0.265145513574,1,,Aire-sur-l'Adour,Aire-sur-l'Adour,40,Landes,75,Nouvelle-Aquitaine
40002,AMOU,40330,AMOU,,43.5938659835,-0.743678079626,2,,Amou,Amou,40,Landes,75,Nouvelle-Aquitaine
40003,ANGOUME,40990,ANGOUME,,43.7003558941,-1.14195857021,3,,Angoumé,Angoumé,40,Landes,75,Nouvelle-Aquitaine
40004,ANGRESSE,40150,ANGRESSE,,43.6586092575,-1.36340493112,4,,Angresse,Angresse,40,Landes,75,Nouvelle-Aquitaine
40005,ARBOUCAVE,40320,ARBOUCAVE,,43.6059261585,-0.44174435794,5,,Arboucave,Arboucave,40,Landes,75,Nouvelle-Aquitaine
40006,ARENGOSSE,40110,ARENGOSSE,,44.0337772733,-0.787351703043,6,,Arengosse,Arengosse,40,Landes,75,Nouvelle-Aquitaine
40007,ARGELOS,40700,ARGELOS,,43.581369897,-0.62145285271,7,,Argelos,Argelos,40,Landes,75,Nouvelle-Aquitaine
40008,ARGELOUSE,40430,ARGELOUSE,,44.3719794818,-0.63279226034,8,,Argelouse,Argelouse,40,Landes,75,Nouvelle-Aquitaine
40009,ARJUZANX,40110,ARJUZANX,,44.0173990827,-0.847223713681,9,,Arjuzanx,Arjuzanx,40,Landes,75,Nouvelle-Aquitaine
40011,ARSAGUE,40330,ARSAGUE,,43.5819217189,-0.789693590118,11,,Arsague,Arsague,40,Landes,75,Nouvelle-Aquitaine
40012,ARTASSENX,40090,ARTASSENX,,43.8455800178,-0.395744020774,12,,Artassenx,Artassenx,40,Landes,75,Nouvelle-Aquitaine
40013,ARTHEZ D ARMAGNAC,40190,ARTHEZ D ARMAGNAC,,43.8904301026,-0.247231913663,13,,Arthez-d'Armagnac,Arthez-d'Armagnac,40,Landes,75,Nouvelle-Aquitaine
40014,ARUE,40120,ARUE,,44.057804998,-0.355246482767,14,,Arue,Arue,40,Landes,75,Nouvelle-Aquitaine
40015,ARX,40310,ARX,,44.1125173381,0.0861919778242,15,,Arx,Arx,40,Landes,75,Nouvelle-Aquitaine
40016,AUBAGNAN,40700,AUBAGNAN,,43.66506931,-0.496132903423,16,,Aubagnan,Aubagnan,40,Landes,75,Nouvelle-Aquitaine
40017,AUDIGNON,40500,AUDIGNON,,43.7229002763,-0.591261477363,17,,Audignon,Audignon,40,Landes,75,Nouvelle-Aquitaine
40018,AUDON,40400,AUDON,,43.7994823596,-0.824876470862,18,,Audon,Audon,40,Landes,75,Nouvelle-Aquitaine
40019,AUREILHAN,40200,AUREILHAN,,44.2117478981,-1.1894638558,19,,Aureilhan,Aureilhan,40,Landes,75,Nouvelle-Aquitaine
40020,AURICE,40500,AURICE,,43.8029933289,-0.598930696866,20,,Aurice,Aurice,40,Landes,75,Nouvelle-Aquitaine
40021,AZUR,40140,AZUR,,43.798488604,-1.29632205852,21,,Azur,Azur,40,Landes,75,Nouvelle-Aquitaine
40022,BAHUS SOUBIRAN,40320,BAHUS SOUBIRAN,,43.6728345213,-0.345658334641,22,,Bahus-Soubiran,Bahus-Soubiran,40,Landes,75,Nouvelle-Aquitaine
40023,BAIGTS,40380,BAIGTS,,43.6872175637,-0.792579105468,23,,Baigts,Baigts,40,Landes,75,Nouvelle-Aquitaine
40024,BANOS,40500,BANOS,,43.7306552333,-0.621226009645,24,,Banos,Banos,40,Landes,75,Nouvelle-Aquitaine
40025,BASCONS,40090,BASCONS,,43.8308003292,-0.424817437129,25,,Bascons,Bascons,40,Landes,75,Nouvelle-Aquitaine
40026,BAS MAUCO,40500,BAS MAUCO,,43.794054808,-0.555863442222,26,,Bas-Mauco,Bas-Mauco,40,Landes,75,Nouvelle-Aquitaine
40027,BASSERCLES,40700,BASSERCLES,,43.559004732,-0.610454626162,27,,Bassercles,Bassercles,40,Landes,75,Nouvelle-Aquitaine
40028,BASTENNES,40360,BASTENNES,,43.6499735416,-0.777407464729,28,,Bastennes,Bastennes,40,Landes,75,Nouvelle-Aquitaine
40029,BATS,40320,BATS,,43.6533852455,-0.463880939052,29,,Bats,Bats,40,Landes,75,Nouvelle-Aquitaine
40030,BAUDIGNAN,40310,BAUDIGNAN,,44.0882703511,0.0392870669549,30,,Baudignan,Baudignan,40,Landes,75,Nouvelle-Aquitaine
40031,BEGAAR,40400,BEGAAR,,43.829501653,-0.85600618412,31,,Bégaar,Bégaar,40,Landes,75,Nouvelle-Aquitaine
40032,BELHADE,40410,BELHADE,,44.3726485881,-0.685242323003,32,,Belhade,Belhade,40,Landes,75,Nouvelle-Aquitaine
40033,BELIS,40120,BELIS,,44.0621942076,-0.467336759192,33,,Bélis,Bélis,40,Landes,75,Nouvelle-Aquitaine
40034,BELUS,40300,BELUS,,43.5927734459,-1.11715221654,34,,Bélus,Bélus,40,Landes,75,Nouvelle-Aquitaine
40035,BENESSE LES DAX,40180,BENESSE LES DAX,,43.6437620659,-1.03880590528,35,,Bénesse-lès-Dax,Bénesse-lès-Dax,40,Landes,75,Nouvelle-Aquitaine
40036,BENESSE MAREMNE,40230,BENESSE MAREMNE,,43.6346135825,-1.36478553572,36,,Bénesse-Maremne,Bénesse-Maremne,40,Landes,75,Nouvelle-Aquitaine
40037,BENQUET,40280,BENQUET,,43.8221288864,-0.507091491966,37,,Benquet,Benquet,40,Landes,75,Nouvelle-Aquitaine
40038,BERGOUEY,40250,BERGOUEY,,43.6679426448,-0.722927453879,38,,Bergouey,Bergouey,40,Landes,75,Nouvelle-Aquitaine
40039,BETBEZER D ARMAGNAC,40240,BETBEZER D ARMAGNAC,,43.9759245125,-0.165215093559,39,,Betbezer-d'Armagnac,Betbezer-d'Armagnac,40,Landes,75,Nouvelle-Aquitaine
40040,BEYLONGUE,40370,BEYLONGUE,,43.9207244949,-0.8280091119,40,,Beylongue,Beylongue,40,Landes,75,Nouvelle-Aquitaine
40041,BEYRIES,40700,BEYRIES,,43.5643183828,-0.639849466335,41,,Beyries,Beyries,40,Landes,75,Nouvelle-Aquitaine
40042,BIARROTTE,40390,BIARROTTE,,43.5574062993,-1.27377772361,42,,Biarrotte,Biarrotte,40,Landes,75,Nouvelle-Aquitaine
40043,BIAS,40170,BIAS,,44.1347298395,-1.22934507034,43,,Bias,Bias,40,Landes,75,Nouvelle-Aquitaine
40044,BIAUDOS,40390,BIAUDOS,,43.5455674571,-1.31286654056,44,,Biaudos,Biaudos,40,Landes,75,Nouvelle-Aquitaine
40046,BISCARROSSE,40600,BISCARROSSE,,44.409080109,-1.1773616947,46,,Biscarrosse,Biscarrosse,40,Landes,75,Nouvelle-Aquitaine
40046,BISCARROSSE,40600,BISCARROSSE,BISCARROSSE PLAGE,44.409080109,-1.1773616947,46,,Biscarrosse,Biscarrosse,40,Landes,75,Nouvelle-Aquitaine
40047,BONNEGARDE,40330,BONNEGARDE,,43.5640369667,-0.712916423938,47,,Bonnegarde,Bonnegarde,40,Landes,75,Nouvelle-Aquitaine
40048,BOOS,40370,BOOS,,43.8925332892,-0.99266705957,48,,Boos,Boos,40,Landes,75,Nouvelle-Aquitaine
40049,BORDERES ET LAMENSANS,40270,BORDERES ET LAMENSANS,,43.7774896377,-0.361022812651,49,,Bordères-et-Lamensans,Bordères-et-Lamensans,40,Landes,75,Nouvelle-Aquitaine
40050,BOSTENS,40090,BOSTENS,,43.9697953116,-0.369044479854,50,,Bostens,Bostens,40,Landes,75,Nouvelle-Aquitaine
40051,BOUGUE,40090,BOUGUE,,43.9036504711,-0.398154002649,51,,Bougue,Bougue,40,Landes,75,Nouvelle-Aquitaine
40052,BOURDALAT,40190,BOURDALAT,,43.8313755668,-0.215429089042,52,,Bourdalat,Bourdalat,40,Landes,75,Nouvelle-Aquitaine
40053,BOURRIOT BERGONCE,40120,BOURRIOT BERGONCE,,44.1506739659,-0.213232956554,53,,Bourriot-Bergonce,Bourriot-Bergonce,40,Landes,75,Nouvelle-Aquitaine
40054,BRASSEMPOUY,40330,BRASSEMPOUY,,43.6312997727,-0.701734631643,54,,Brassempouy,Brassempouy,40,Landes,75,Nouvelle-Aquitaine
40055,BRETAGNE DE MARSAN,40280,BRETAGNE DE MARSAN,,43.8365888593,-0.46531772433,55,,Bretagne-de-Marsan,Bretagne-de-Marsan,40,Landes,75,Nouvelle-Aquitaine
40056,BROCAS,40420,BROCAS,,44.0455210559,-0.542490022307,56,,Brocas,Brocas,40,Landes,75,Nouvelle-Aquitaine
40057,BUANES,40320,BUANES,,43.7151108086,-0.422669583311,57,,Buanes,Buanes,40,Landes,75,Nouvelle-Aquitaine
40058,CACHEN,40120,CACHEN,,44.0795116386,-0.417533535024,58,,Cachen,Cachen,40,Landes,75,Nouvelle-Aquitaine
40059,CAGNOTTE,40300,CAGNOTTE,,43.6046856723,-1.07724977616,59,,Cagnotte,Cagnotte,40,Landes,75,Nouvelle-Aquitaine
40060,CALLEN,40430,CALLEN,,44.2849211801,-0.461582251262,60,,Callen,Callen,40,Landes,75,Nouvelle-Aquitaine
40061,CAMPAGNE,40090,CAMPAGNE,,43.8621880955,-0.639177519978,61,,Campagne,Campagne,40,Landes,75,Nouvelle-Aquitaine
40062,CAMPET ET LAMOLERE,40090,CAMPET ET LAMOLERE,,43.9152250646,-0.58964747466,62,,Campet-et-Lamolère,Campet-et-Lamolère,40,Landes,75,Nouvelle-Aquitaine
40063,CANDRESSE,40180,CANDRESSE,,43.7108557763,-0.979606796213,63,,Candresse,Candresse,40,Landes,75,Nouvelle-Aquitaine
40064,CANENX ET REAUT,40090,CANENX ET REAUT,,43.9990336487,-0.464721878442,64,,Canenx-et-Réaut,Canenx-et-Réaut,40,Landes,75,Nouvelle-Aquitaine
40065,CAPBRETON,40130,CAPBRETON,,43.6323255498,-1.42907243956,65,,Capbreton,Capbreton,40,Landes,75,Nouvelle-Aquitaine
40066,CARCARES STE CROIX,40400,CARCARES STE CROIX,,43.8551538602,-0.755745605984,66,,Carcarès-Sainte-Croix,Carcarès-Sainte-Croix,40,Landes,75,Nouvelle-Aquitaine
40067,CARCEN PONSON,40400,CARCEN PONSON,,43.871874652,-0.81936363917,67,,Carcen-Ponson,Carcen-Ponson,40,Landes,75,Nouvelle-Aquitaine
40068,CASSEN,40380,CASSEN,,43.7598934223,-0.86548740659,68,,Cassen,Cassen,40,Landes,75,Nouvelle-Aquitaine
40069,CASTAIGNOS SOUSLENS,40700,CASTAIGNOS SOUSLENS,,43.5873760564,-0.648925857691,69,,Castaignos-Souslens,Castaignos-Souslens,40,Landes,75,Nouvelle-Aquitaine
40070,CASTANDET,40270,CASTANDET,,43.8073545404,-0.352224623353,70,,Castandet,Castandet,40,Landes,75,Nouvelle-Aquitaine
40071,CASTELNAU CHALOSSE,40360,CASTELNAU CHALOSSE,,43.6593581505,-0.852237101621,71,,Castelnau-Chalosse,Castelnau-Chalosse,40,Landes,75,Nouvelle-Aquitaine
40072,CASTELNAU TURSAN,40320,CASTELNAU TURSAN,,43.6549167711,-0.403076530172,72,,Castelnau-Tursan,Castelnau-Tursan,40,Landes,75,Nouvelle-Aquitaine
40073,CASTELNER,40700,CASTELNER,,43.5522725117,-0.591274524303,73,,Castelner,Castelner,40,Landes,75,Nouvelle-Aquitaine
40074,CASTEL SARRAZIN,40330,CASTEL SARRAZIN,,43.6176642945,-0.787879351472,74,,Castel-Sarrazin,Castel-Sarrazin,40,Landes,75,Nouvelle-Aquitaine
40075,CASTETS,40260,CASTETS,,43.87403966,-1.14528108058,75,,Castets,Castets,40,Landes,75,Nouvelle-Aquitaine
40076,CAUNA,40500,CAUNA,,43.782855551,-0.641043068936,76,,Cauna,Cauna,40,Landes,75,Nouvelle-Aquitaine
40077,CAUNEILLE,40300,CAUNEILLE,,43.5612550673,-1.0641894945,77,,Cauneille,Cauneille,40,Landes,75,Nouvelle-Aquitaine
40078,CAUPENNE,40250,CAUPENNE,,43.6842932274,-0.754439006059,78,,Caupenne,Caupenne,40,Landes,75,Nouvelle-Aquitaine
40079,CAZALIS,40700,CAZALIS,,43.6245965043,-0.66842783095,79,,Cazalis,Cazalis,40,Landes,75,Nouvelle-Aquitaine
40080,CAZERES SUR L ADOUR,40270,CAZERES SUR L ADOUR,,43.7643443034,-0.284507617354,80,,Cazères-sur-l'Adour,Cazères-sur-l'Adour,40,Landes,75,Nouvelle-Aquitaine
40081,CERE,40090,CERE,,43.9953544159,-0.546512240784,81,,Cère,Cère,40,Landes,75,Nouvelle-Aquitaine
40082,CLASSUN,40320,CLASSUN,,43.7152491166,-0.390818849951,82,,Classun,Classun,40,Landes,75,Nouvelle-Aquitaine
40083,CLEDES,40320,CLEDES,,43.6069139471,-0.374502586339,83,,Clèdes,Clèdes,40,Landes,75,Nouvelle-Aquitaine
40084,CLERMONT,40180,CLERMONT,,43.6485435994,-0.909862046514,84,,Clermont,Clermont,40,Landes,75,Nouvelle-Aquitaine
40085,COMMENSACQ,40210,COMMENSACQ,,44.2128891183,-0.830607602619,85,,Commensacq,Commensacq,40,Landes,75,Nouvelle-Aquitaine
40086,COUDURES,40500,COUDURES,,43.6880713339,-0.5043614536,86,,Coudures,Coudures,40,Landes,75,Nouvelle-Aquitaine
40087,CREON D ARMAGNAC,40240,CREON D ARMAGNAC,,43.9952890631,-0.0829196527802,87,,Créon-d'Armagnac,Créon-d'Armagnac,40,Landes,75,Nouvelle-Aquitaine
40088,DAX,40100,DAX,,43.7006746973,-1.06014429759,88,,Dax,Dax,40,Landes,75,Nouvelle-Aquitaine
40089,DOAZIT,40700,DOAZIT,,43.6926736265,-0.645863331273,89,,Doazit,Doazit,40,Landes,75,Nouvelle-Aquitaine
40090,DONZACQ,40360,DONZACQ,,43.6591843115,-0.806382992086,90,,Donzacq,Donzacq,40,Landes,75,Nouvelle-Aquitaine
40091,DUHORT BACHEN,40800,DUHORT BACHEN,,43.7174754402,-0.321523515178,91,,Duhort-Bachen,Duhort-Bachen,40,Landes,75,Nouvelle-Aquitaine
40092,DUMES,40500,DUMES,,43.7040138261,-0.582352624037,92,,Dumes,Dumes,40,Landes,75,Nouvelle-Aquitaine
40093,ESCALANS,40310,ESCALANS,,44.0058679413,0.0367153523007,93,,Escalans,Escalans,40,Landes,75,Nouvelle-Aquitaine
40094,ESCOURCE,40210,ESCOURCE,,44.1659417112,-1.04071046156,94,,Escource,Escource,40,Landes,75,Nouvelle-Aquitaine
40095,ESTIBEAUX,40290,ESTIBEAUX,,43.6088546655,-0.910488790314,95,,Estibeaux,Estibeaux,40,Landes,75,Nouvelle-Aquitaine
40096,ESTIGARDE,40240,ESTIGARDE,,44.0367068447,-0.115899203716,96,,Estigarde,Estigarde,40,Landes,75,Nouvelle-Aquitaine
40097,EUGENIE LES BAINS,40320,EUGENIE LES BAINS,,43.6942772667,-0.382600517074,97,,Eugénie-les-Bains,Eugénie-les-Bains,40,Landes,75,Nouvelle-Aquitaine
40098,EYRES MONCUBE,40500,EYRES MONCUBE,,43.7148219031,-0.552172666549,98,,Eyres-Moncube,Eyres-Moncube,40,Landes,75,Nouvelle-Aquitaine
40099,FARGUES,40500,FARGUES,,43.7251767308,-0.451258417068,99,,Fargues,Fargues,40,Landes,75,Nouvelle-Aquitaine
40100,LE FRECHE,40190,LE FRECHE,,43.92543698,-0.244737215406,100,Le,Frêche,Le Frêche,40,Landes,75,Nouvelle-Aquitaine
40101,GAAS,40350,GAAS,,43.6103431996,-1.04133365689,101,,Gaas,Gaas,40,Landes,75,Nouvelle-Aquitaine
40102,GABARRET,40310,GABARRET,,43.986768841,-0.00379328281561,102,,Gabarret,Gabarret,40,Landes,75,Nouvelle-Aquitaine
40103,GAILLERES,40090,GAILLERES,,43.9379918755,-0.3740739094,103,,Gaillères,Gaillères,40,Landes,75,Nouvelle-Aquitaine
40104,GAMARDE LES BAINS,40380,GAMARDE LES BAINS,,43.7278148495,-0.872597495596,104,,Gamarde-les-Bains,Gamarde-les-Bains,40,Landes,75,Nouvelle-Aquitaine
40105,GAREIN,40420,GAREIN,,44.0485811628,-0.649725235067,105,,Garein,Garein,40,Landes,75,Nouvelle-Aquitaine
40106,GARREY,40180,GARREY,,43.6697782294,-0.899363081457,106,,Garrey,Garrey,40,Landes,75,Nouvelle-Aquitaine
40107,GARROSSE,40110,GARROSSE,,44.0067597178,-0.944886603255,107,,Garrosse,Garrosse,40,Landes,75,Nouvelle-Aquitaine
40108,GASTES,40160,GASTES,,44.3206614614,-1.19398557217,108,,Gastes,Gastes,40,Landes,75,Nouvelle-Aquitaine
40109,GAUJACQ,40330,GAUJACQ,,43.6385051915,-0.741684144139,109,,Gaujacq,Gaujacq,40,Landes,75,Nouvelle-Aquitaine
40110,GEAUNE,40320,GEAUNE,,43.6324028996,-0.365846748561,110,,Geaune,Geaune,40,Landes,75,Nouvelle-Aquitaine
40111,GELOUX,40090,GELOUX,,43.9798766807,-0.640212463679,111,,Geloux,Geloux,40,Landes,75,Nouvelle-Aquitaine
40112,GIBRET,40380,GIBRET,,43.6815268209,-0.82247563225,112,,Gibret,Gibret,40,Landes,75,Nouvelle-Aquitaine
40113,GOOS,40180,GOOS,,43.729775958,-0.916998152375,113,,Goos,Goos,40,Landes,75,Nouvelle-Aquitaine
40114,GOURBERA,40990,GOURBERA,,43.8077905469,-1.04783992391,114,,Gourbera,Gourbera,40,Landes,75,Nouvelle-Aquitaine
40115,GOUSSE,40465,GOUSSE,,43.7741412631,-0.909077594702,115,,Gousse,Gousse,40,Landes,75,Nouvelle-Aquitaine
40116,GOUTS,40400,GOUTS,,43.7784036794,-0.77835350511,116,,Gouts,Gouts,40,Landes,75,Nouvelle-Aquitaine
40117,GRENADE SUR L ADOUR,40270,GRENADE SUR L ADOUR,,43.792570586,-0.422528940509,117,,Grenade-sur-l'Adour,Grenade-sur-l'Adour,40,Landes,75,Nouvelle-Aquitaine
40118,HABAS,40290,HABAS,,43.5613150777,-0.932453413286,118,,Habas,Habas,40,Landes,75,Nouvelle-Aquitaine
40119,HAGETMAU,40700,HAGETMAU,,43.6504279428,-0.590576524806,119,,Hagetmau,Hagetmau,40,Landes,75,Nouvelle-Aquitaine
40120,HASTINGUES,40300,HASTINGUES,,43.5160536898,-1.13714325683,120,,Hastingues,Hastingues,40,Landes,75,Nouvelle-Aquitaine
40121,HAURIET,40250,HAURIET,,43.732799424,-0.693297184159,121,,Hauriet,Hauriet,40,Landes,75,Nouvelle-Aquitaine
40122,HAUT MAUCO,40280,HAUT MAUCO,,43.8292265274,-0.562242768558,122,,Haut-Mauco,Haut-Mauco,40,Landes,75,Nouvelle-Aquitaine
40123,HERM,40990,HERM,,43.8073328601,-1.12483470338,123,,Herm,Herm,40,Landes,75,Nouvelle-Aquitaine
40124,HERRE,40310,HERRE,,44.0211717099,-0.0373071311054,124,,Herré,Herré,40,Landes,75,Nouvelle-Aquitaine
40125,HEUGAS,40180,HEUGAS,,43.6443078631,-1.08178746791,125,,Heugas,Heugas,40,Landes,75,Nouvelle-Aquitaine
40126,HINX,40180,HINX,,43.7095931585,-0.938441196059,126,,Hinx,Hinx,40,Landes,75,Nouvelle-Aquitaine
40127,HONTANX,40190,HONTANX,,43.815442383,-0.26010710402,127,,Hontanx,Hontanx,40,Landes,75,Nouvelle-Aquitaine
40128,HORSARRIEU,40700,HORSARRIEU,,43.6877129762,-0.601086715488,128,,Horsarrieu,Horsarrieu,40,Landes,75,Nouvelle-Aquitaine
40129,JOSSE,40230,JOSSE,,43.6448421155,-1.23659908302,129,,Josse,Josse,40,Landes,75,Nouvelle-Aquitaine
40130,LABASTIDE CHALOSSE,40700,LABASTIDE CHALOSSE,,43.6151282559,-0.607305517839,130,,Labastide-Chalosse,Labastide-Chalosse,40,Landes,75,Nouvelle-Aquitaine
40131,LABASTIDE D ARMAGNAC,40240,LABASTIDE D ARMAGNAC,,43.9476376722,-0.17524551528,131,,Labastide-d'Armagnac,Labastide-d'Armagnac,40,Landes,75,Nouvelle-Aquitaine
40132,LABATUT,40300,LABATUT,,43.5522821806,-0.998234258589,132,,Labatut,Labatut,40,Landes,75,Nouvelle-Aquitaine
40133,LABENNE,40530,LABENNE,,43.5956638895,-1.42969814773,133,,Labenne,Labenne,40,Landes,75,Nouvelle-Aquitaine
40134,LABOUHEYRE,40210,LABOUHEYRE,,44.2202753645,-0.906919616539,134,,Labouheyre,Labouheyre,40,Landes,75,Nouvelle-Aquitaine
40135,LABRIT,40420,LABRIT,,44.1262391646,-0.564277292069,135,,Labrit,Labrit,40,Landes,75,Nouvelle-Aquitaine
40136,LACAJUNTE,40320,LACAJUNTE,,43.5898300632,-0.418995024146,136,,Lacajunte,Lacajunte,40,Landes,75,Nouvelle-Aquitaine
40137,LACQUY,40120,LACQUY,,43.9565859062,-0.285182615294,137,,Lacquy,Lacquy,40,Landes,75,Nouvelle-Aquitaine
40138,LACRABE,40700,LACRABE,,43.6100626829,-0.592429512966,138,,Lacrabe,Lacrabe,40,Landes,75,Nouvelle-Aquitaine
40139,LAGLORIEUSE,40090,LAGLORIEUSE,,43.8644095018,-0.395751072937,139,,Laglorieuse,Laglorieuse,40,Landes,75,Nouvelle-Aquitaine
40140,LAGRANGE,40240,LAGRANGE,,43.9663777626,-0.0809775646535,140,,Lagrange,Lagrange,40,Landes,75,Nouvelle-Aquitaine
40141,LAHOSSE,40250,LAHOSSE,,43.7126711981,-0.788723585467,141,,Lahosse,Lahosse,40,Landes,75,Nouvelle-Aquitaine
40142,LALUQUE,40465,LALUQUE,,43.8510297418,-0.986364609887,142,,Laluque,Laluque,40,Landes,75,Nouvelle-Aquitaine
40143,LAMOTHE,40250,LAMOTHE,,43.7965160092,-0.660546557515,143,,Lamothe,Lamothe,40,Landes,75,Nouvelle-Aquitaine
40144,LARBEY,40250,LARBEY,,43.7034733507,-0.734220023815,144,,Larbey,Larbey,40,Landes,75,Nouvelle-Aquitaine
40145,LARRIVIERE ST SAVIN,40270,LARRIVIERE ST SAVIN,,43.7552492766,-0.424559880021,145,,Larrivière-Saint-Savin,Larrivière-Saint-Savin,40,Landes,75,Nouvelle-Aquitaine
40146,LATRILLE,40800,LATRILLE,,43.6327875774,-0.290847872857,146,,Latrille,Latrille,40,Landes,75,Nouvelle-Aquitaine
40147,LAUREDE,40250,LAUREDE,,43.7562133962,-0.789651425061,147,,Laurède,Laurède,40,Landes,75,Nouvelle-Aquitaine
40148,LAURET,40320,LAURET,,43.5638042916,-0.328125320173,148,,Lauret,Lauret,40,Landes,75,Nouvelle-Aquitaine
40149,LENCOUACQ,40120,LENCOUACQ,,44.1499967564,-0.390748526935,149,,Lencouacq,Lencouacq,40,Landes,75,Nouvelle-Aquitaine
40150,LEON,40550,LEON,,43.8492680925,-1.28087921075,150,,Léon,Léon,40,Landes,75,Nouvelle-Aquitaine
40151,LESGOR,40400,LESGOR,,43.8580379497,-0.908058109824,151,,Lesgor,Lesgor,40,Landes,75,Nouvelle-Aquitaine
40152,LESPERON,40260,LESPERON,,43.9630085483,-1.07688098141,152,,Lesperon,Lesperon,40,Landes,75,Nouvelle-Aquitaine
40153,LE LEUY,40250,LE LEUY,,43.8190060423,-0.652363549526,153,Le,Leuy,Le Leuy,40,Landes,75,Nouvelle-Aquitaine
40154,LEVIGNACQ,40170,LEVIGNACQ,,44.009843871,-1.15331315193,154,,Lévignacq,Lévignacq,40,Landes,75,Nouvelle-Aquitaine
40155,LINXE,40260,LINXE,,43.9292438732,-1.21865314425,155,,Linxe,Linxe,40,Landes,75,Nouvelle-Aquitaine
40156,LIPOSTHEY,40410,LIPOSTHEY,,44.3085319978,-0.885914043691,156,,Liposthey,Liposthey,40,Landes,75,Nouvelle-Aquitaine
40157,LIT ET MIXE,40170,LIT ET MIXE,,44.0200072678,-1.2760654515,157,,Lit-et-Mixe,Lit-et-Mixe,40,Landes,75,Nouvelle-Aquitaine
40158,LOSSE,40240,LOSSE,,44.09500776,-0.0901039836208,158,,Losse,Losse,40,Landes,75,Nouvelle-Aquitaine
40159,LOUER,40380,LOUER,,43.7564807293,-0.890892690652,159,,Louer,Louer,40,Landes,75,Nouvelle-Aquitaine
40160,LOURQUEN,40250,LOURQUEN,,43.7319897674,-0.793463231872,160,,Lourquen,Lourquen,40,Landes,75,Nouvelle-Aquitaine
40161,LUBBON,40240,LUBBON,,44.1156975996,-0.0167335813164,161,,Lubbon,Lubbon,40,Landes,75,Nouvelle-Aquitaine
40162,LUCBARDEZ ET BARGUES,40090,LUCBARDEZ ET BARGUES,,43.9749786498,-0.410271763085,162,,Lucbardez-et-Bargues,Lucbardez-et-Bargues,40,Landes,75,Nouvelle-Aquitaine
40163,LUE,40210,LUE,,44.2377202505,-0.9809457884,163,,Lüe,Lüe,40,Landes,75,Nouvelle-Aquitaine
40164,RETJONS,40120,RETJONS,,44.1358553322,-0.302200932273,164,,Retjons,Retjons,40,Landes,75,Nouvelle-Aquitaine
40165,LUGLON,40630,LUGLON,,44.083457314,-0.724837867165,165,,Luglon,Luglon,40,Landes,75,Nouvelle-Aquitaine
40166,LUSSAGNET,40270,LUSSAGNET,,43.774245256,-0.232415581474,166,,Lussagnet,Lussagnet,40,Landes,75,Nouvelle-Aquitaine
40167,LUXEY,40430,LUXEY,,44.2207948584,-0.515670146445,167,,Luxey,Luxey,40,Landes,75,Nouvelle-Aquitaine
40168,MAGESCQ,40140,MAGESCQ,,43.7734560223,-1.19972400188,168,,Magescq,Magescq,40,Landes,75,Nouvelle-Aquitaine
40169,MAILLAS,40120,MAILLAS,,44.2130883384,-0.177666855648,169,,Maillas,Maillas,40,Landes,75,Nouvelle-Aquitaine
40170,MAILLERES,40120,MAILLERES,,44.0265516785,-0.442069606179,170,,Maillères,Maillères,40,Landes,75,Nouvelle-Aquitaine
40171,MANO,40410,MANO,,44.425096568,-0.665908613551,171,,Mano,Mano,40,Landes,75,Nouvelle-Aquitaine
40172,MANT,40700,MANT,,43.5916606333,-0.502752256257,172,,Mant,Mant,40,Landes,75,Nouvelle-Aquitaine
40173,MARPAPS,40330,MARPAPS,,43.5732792701,-0.680116391217,173,,Marpaps,Marpaps,40,Landes,75,Nouvelle-Aquitaine
40174,MAURIES,40320,MAURIES,,43.6151264193,-0.336068922344,174,,Mauries,Mauries,40,Landes,75,Nouvelle-Aquitaine
40175,MAURRIN,40270,MAURRIN,,43.825810397,-0.37207191745,175,,Maurrin,Maurrin,40,Landes,75,Nouvelle-Aquitaine
40176,MAUVEZIN D ARMAGNAC,40240,MAUVEZIN D ARMAGNAC,,43.958814539,-0.131561949481,176,,Mauvezin-d'Armagnac,Mauvezin-d'Armagnac,40,Landes,75,Nouvelle-Aquitaine
40177,MAYLIS,40250,MAYLIS,,43.693003181,-0.700079393802,177,,Maylis,Maylis,40,Landes,75,Nouvelle-Aquitaine
40178,MAZEROLLES,40090,MAZEROLLES,,43.8855485291,-0.435459254424,178,,Mazerolles,Mazerolles,40,Landes,75,Nouvelle-Aquitaine
40179,MEES,40990,MEES,,43.7103483825,-1.12243161714,179,,Mées,Mées,40,Landes,75,Nouvelle-Aquitaine
40180,MEILHAN,40400,MEILHAN,,43.859612287,-0.695576864864,180,,Meilhan,Meilhan,40,Landes,75,Nouvelle-Aquitaine
40181,MESSANGES,40660,MESSANGES,,43.811704392,-1.36380228548,181,,Messanges,Messanges,40,Landes,75,Nouvelle-Aquitaine
40182,MEZOS,40170,MEZOS,,44.0869839413,-1.13332774767,182,,Mézos,Mézos,40,Landes,75,Nouvelle-Aquitaine
40183,MIMBASTE,40350,MIMBASTE,,43.646688515,-0.958884857354,183,,Mimbaste,Mimbaste,40,Landes,75,Nouvelle-Aquitaine
40184,MIMIZAN,40200,MIMIZAN,,44.1890027231,-1.24571912607,184,,Mimizan,Mimizan,40,Landes,75,Nouvelle-Aquitaine
40185,MIRAMONT SENSACQ,40320,MIRAMONT SENSACQ,,43.5937272264,-0.321552356121,185,,Miramont-Sensacq,Miramont-Sensacq,40,Landes,75,Nouvelle-Aquitaine
40186,MISSON,40290,MISSON,,43.5841785714,-0.958405767864,186,,Misson,Misson,40,Landes,75,Nouvelle-Aquitaine
40187,MOLIETS ET MAA,40660,MOLIETS ET MAA,,43.8581452244,-1.36096901357,187,,Moliets-et-Maa,Moliets-et-Maa,40,Landes,75,Nouvelle-Aquitaine
40188,MOMUY,40700,MOMUY,,43.616711786,-0.63979012639,188,,Momuy,Momuy,40,Landes,75,Nouvelle-Aquitaine
40189,MONGET,40700,MONGET,,43.5593376119,-0.534718563495,189,,Monget,Monget,40,Landes,75,Nouvelle-Aquitaine
40190,MONSEGUR,40700,MONSEGUR,,43.6151818106,-0.545319135414,190,,Monségur,Monségur,40,Landes,75,Nouvelle-Aquitaine
40191,MONTAUT,40500,MONTAUT,,43.7283439402,-0.656045169398,191,,Montaut,Montaut,40,Landes,75,Nouvelle-Aquitaine
40192,MONT DE MARSAN,40000,MONT DE MARSAN,,43.899361404,-0.490722577455,192,,Mont-de-Marsan,Mont-de-Marsan,40,Landes,75,Nouvelle-Aquitaine
40193,MONTEGUT,40190,MONTEGUT,,43.8736591774,-0.204038258587,193,,Montégut,Montégut,40,Landes,75,Nouvelle-Aquitaine
40194,MONTFORT EN CHALOSSE,40380,MONTFORT EN CHALOSSE,,43.7042977993,-0.836338362892,194,,Montfort-en-Chalosse,Montfort-en-Chalosse,40,Landes,75,Nouvelle-Aquitaine
40195,MONTGAILLARD,40500,MONTGAILLARD,,43.7574145049,-0.480089194679,195,,Montgaillard,Montgaillard,40,Landes,75,Nouvelle-Aquitaine
40196,MONTSOUE,40500,MONTSOUE,,43.7303405646,-0.506109191151,196,,Montsoué,Montsoué,40,Landes,75,Nouvelle-Aquitaine
40197,MORCENX,40110,MORCENX,,44.0410936254,-0.888623636398,197,,Morcenx,Morcenx,40,Landes,75,Nouvelle-Aquitaine
40198,MORGANX,40700,MORGANX,,43.6038610111,-0.572539507338,198,,Morganx,Morganx,40,Landes,75,Nouvelle-Aquitaine
40199,MOUSCARDES,40290,MOUSCARDES,,43.5892643922,-0.884133125413,199,,Mouscardès,Mouscardès,40,Landes,75,Nouvelle-Aquitaine
40200,MOUSTEY,40410,MOUSTEY,,44.3882918763,-0.750880080586,200,,Moustey,Moustey,40,Landes,75,Nouvelle-Aquitaine
40201,MUGRON,40250,MUGRON,,43.7412885635,-0.752690282166,201,,Mugron,Mugron,40,Landes,75,Nouvelle-Aquitaine
40202,NARROSSE,40180,NARROSSE,,43.6936975467,-1.00852335605,202,,Narrosse,Narrosse,40,Landes,75,Nouvelle-Aquitaine
40203,NASSIET,40330,NASSIET,,43.5984910447,-0.68911731976,203,,Nassiet,Nassiet,40,Landes,75,Nouvelle-Aquitaine
40204,NERBIS,40250,NERBIS,,43.7508546584,-0.723255362157,204,,Nerbis,Nerbis,40,Landes,75,Nouvelle-Aquitaine
40205,NOUSSE,40380,NOUSSE,,43.7218796772,-0.822154069593,205,,Nousse,Nousse,40,Landes,75,Nouvelle-Aquitaine
40206,OEYREGAVE,40300,OEYREGAVE,,43.5277445568,-1.09893426227,206,,Oeyregave,Oeyregave,40,Landes,75,Nouvelle-Aquitaine
40207,OEYRELUY,40180,OEYRELUY,,43.6725909194,-1.07802745569,207,,Oeyreluy,Oeyreluy,40,Landes,75,Nouvelle-Aquitaine
40208,ONARD,40380,ONARD,,43.7780268218,-0.832303249153,208,,Onard,Onard,40,Landes,75,Nouvelle-Aquitaine
40209,ONDRES,40440,ONDRES,,43.5663586756,-1.45381098217,209,,Ondres,Ondres,40,Landes,75,Nouvelle-Aquitaine
40210,ONESSE LAHARIE,40110,ONESSE LAHARIE,,44.0721406576,-1.02598711756,210,,Onesse-Laharie,Onesse-Laharie,40,Landes,75,Nouvelle-Aquitaine
40210,ONESSE LAHARIE,40110,ONESSE LAHARIE,LAHARIE,44.0721406576,-1.02598711756,210,,Onesse-Laharie,Onesse-Laharie,40,Landes,75,Nouvelle-Aquitaine
40211,ORIST,40300,ORIST,,43.6383171694,-1.17398532413,211,,Orist,Orist,40,Landes,75,Nouvelle-Aquitaine
40212,ORTHEVIELLE,40300,ORTHEVIELLE,,43.5643205172,-1.14690876609,212,,Orthevielle,Orthevielle,40,Landes,75,Nouvelle-Aquitaine
40213,ORX,40230,ORX,,43.6053566765,-1.37144651708,213,,Orx,Orx,40,Landes,75,Nouvelle-Aquitaine
40214,OSSAGES,40290,OSSAGES,,43.5579537208,-0.878000230136,214,,Ossages,Ossages,40,Landes,75,Nouvelle-Aquitaine
40215,OUSSE SUZAN,40110,OUSSE SUZAN,,43.9458730259,-0.752515437356,215,,Ousse-Suzan,Ousse-Suzan,40,Landes,75,Nouvelle-Aquitaine
40216,OZOURT,40380,OZOURT,,43.657983448,-0.878099266646,216,,Ozourt,Ozourt,40,Landes,75,Nouvelle-Aquitaine
40217,PARENTIS EN BORN,40160,PARENTIS EN BORN,,44.3452523952,-1.06520105528,217,,Parentis-en-Born,Parentis-en-Born,40,Landes,75,Nouvelle-Aquitaine
40218,PARLEBOSCQ,40310,PARLEBOSCQ,,43.9367664838,0.0351952892185,218,,Parleboscq,Parleboscq,40,Landes,75,Nouvelle-Aquitaine
40219,PAYROS CAZAUTETS,40320,PAYROS CAZAUTETS,,43.6256324466,-0.398159744539,219,,Payros-Cazautets,Payros-Cazautets,40,Landes,75,Nouvelle-Aquitaine
40220,PECORADE,40320,PECORADE,,43.6526242317,-0.360728054461,220,,Pécorade,Pécorade,40,Landes,75,Nouvelle-Aquitaine
40221,PERQUIE,40190,PERQUIE,,43.8652740787,-0.259063965036,221,,Perquie,Perquie,40,Landes,75,Nouvelle-Aquitaine
40222,PEY,40300,PEY,,43.6159999554,-1.20840982876,222,,Pey,Pey,40,Landes,75,Nouvelle-Aquitaine
40223,PEYRE,40700,PEYRE,,43.571666656,-0.557762855365,223,,Peyre,Peyre,40,Landes,75,Nouvelle-Aquitaine
40224,PEYREHORADE,40300,PEYREHORADE,,43.5583873014,-1.10698952951,224,,Peyrehorade,Peyrehorade,40,Landes,75,Nouvelle-Aquitaine
40225,PHILONDENX,40320,PHILONDENX,,43.5680469696,-0.429300751849,225,,Philondenx,Philondenx,40,Landes,75,Nouvelle-Aquitaine
40226,PIMBO,40320,PIMBO,,43.577275978,-0.376833921206,226,,Pimbo,Pimbo,40,Landes,75,Nouvelle-Aquitaine
40227,PISSOS,40410,PISSOS,,44.2958433032,-0.780642494163,227,,Pissos,Pissos,40,Landes,75,Nouvelle-Aquitaine
40227,PISSOS,40410,PISSOS,RICHET,44.2958433032,-0.780642494163,227,,Pissos,Pissos,40,Landes,75,Nouvelle-Aquitaine
40228,POMAREZ,40360,POMAREZ,,43.6222065603,-0.839267500115,228,,Pomarez,Pomarez,40,Landes,75,Nouvelle-Aquitaine
40229,PONTENX LES FORGES,40200,PONTENX LES FORGES,,44.2548041372,-1.08797105865,229,,Pontenx-les-Forges,Pontenx-les-Forges,40,Landes,75,Nouvelle-Aquitaine
40230,PONTONX SUR L ADOUR,40465,PONTONX SUR L ADOUR,,43.7980252651,-0.940526888731,230,,Pontonx-sur-l'Adour,Pontonx-sur-l'Adour,40,Landes,75,Nouvelle-Aquitaine
40231,PORT DE LANNE,40300,PORT DE LANNE,,43.567591642,-1.17971224774,231,,Port-de-Lanne,Port-de-Lanne,40,Landes,75,Nouvelle-Aquitaine
40232,POUDENX,40700,POUDENX,,43.5834078234,-0.591490517984,232,,Poudenx,Poudenx,40,Landes,75,Nouvelle-Aquitaine
40233,POUILLON,40350,POUILLON,,43.606586426,-1.00135872585,233,,Pouillon,Pouillon,40,Landes,75,Nouvelle-Aquitaine
40234,POUYDESSEAUX,40120,POUYDESSEAUX,,43.9875783387,-0.350021938022,234,,Pouydesseaux,Pouydesseaux,40,Landes,75,Nouvelle-Aquitaine
40235,POYANNE,40380,POYANNE,,43.7536761163,-0.815720310969,235,,Poyanne,Poyanne,40,Landes,75,Nouvelle-Aquitaine
40236,POYARTIN,40380,POYARTIN,,43.6881465627,-0.865094526104,236,,Poyartin,Poyartin,40,Landes,75,Nouvelle-Aquitaine
40237,PRECHACQ LES BAINS,40465,PRECHACQ LES BAINS,,43.7563106359,-0.919468711064,237,,Préchacq-les-Bains,Préchacq-les-Bains,40,Landes,75,Nouvelle-Aquitaine
40238,PUJO LE PLAN,40190,PUJO LE PLAN,,43.8618194805,-0.342281346691,238,,Pujo-le-Plan,Pujo-le-Plan,40,Landes,75,Nouvelle-Aquitaine
40239,PUYOL CAZALET,40320,PUYOL CAZALET,,43.6054018969,-0.401096682723,239,,Puyol-Cazalet,Puyol-Cazalet,40,Landes,75,Nouvelle-Aquitaine
40240,RENUNG,40270,RENUNG,,43.7467796821,-0.368876062066,240,,Renung,Renung,40,Landes,75,Nouvelle-Aquitaine
40242,RIMBEZ ET BAUDIETS,40310,RIMBEZ ET BAUDIETS,,44.0564510689,0.0490737699359,242,,Rimbez-et-Baudiets,Rimbez-et-Baudiets,40,Landes,75,Nouvelle-Aquitaine
40243,RION DES LANDES,40370,RION DES LANDES,,43.9348192825,-0.933954728483,243,,Rion-des-Landes,Rion-des-Landes,40,Landes,75,Nouvelle-Aquitaine
40244,RIVIERE SAAS ET GOURBY,40180,RIVIERE SAAS ET GOURBY,,43.6990141458,-1.17252770868,244,,Rivière-Saas-et-Gourby,Rivière-Saas-et-Gourby,40,Landes,75,Nouvelle-Aquitaine
40245,ROQUEFORT,40120,ROQUEFORT,,44.0367351546,-0.326573945505,245,,Roquefort,Roquefort,40,Landes,75,Nouvelle-Aquitaine
40246,SABRES,40630,SABRES,,44.1461218641,-0.734828540641,246,,Sabres,Sabres,40,Landes,75,Nouvelle-Aquitaine
40247,ST AGNET,40800,ST AGNET,,43.605692176,-0.271537689033,247,,Saint-Agnet,Saint-Agnet,40,Landes,75,Nouvelle-Aquitaine
40248,ST ANDRE DE SEIGNANX,40390,ST ANDRE DE SEIGNANX,,43.5655733874,-1.35841255114,248,,Saint-André-de-Seignanx,Saint-André-de-Seignanx,40,Landes,75,Nouvelle-Aquitaine
40249,ST AUBIN,40250,ST AUBIN,,43.7176125498,-0.720049420031,249,,Saint-Aubin,Saint-Aubin,40,Landes,75,Nouvelle-Aquitaine
40250,ST AVIT,40090,ST AVIT,,43.945079707,-0.45860276452,250,,Saint-Avit,Saint-Avit,40,Landes,75,Nouvelle-Aquitaine
40251,ST BARTHELEMY,40390,ST BARTHELEMY,,43.5201160694,-1.33110280888,251,,Saint-Barthélemy,Saint-Barthélemy,40,Landes,75,Nouvelle-Aquitaine
40252,STE COLOMBE,40700,STE COLOMBE,,43.6810025684,-0.556768147827,252,,Sainte-Colombe,Sainte-Colombe,40,Landes,75,Nouvelle-Aquitaine
40253,ST CRICQ CHALOSSE,40700,ST CRICQ CHALOSSE,,43.6542973883,-0.676258119211,253,,Saint-Cricq-Chalosse,Saint-Cricq-Chalosse,40,Landes,75,Nouvelle-Aquitaine
40254,ST CRICQ DU GAVE,40300,ST CRICQ DU GAVE,,43.5260659136,-1.01128645377,254,,Saint-Cricq-du-Gave,Saint-Cricq-du-Gave,40,Landes,75,Nouvelle-Aquitaine
40255,ST CRICQ VILLENEUVE,40190,ST CRICQ VILLENEUVE,,43.9004132095,-0.350052068781,255,,Saint-Cricq-Villeneuve,Saint-Cricq-Villeneuve,40,Landes,75,Nouvelle-Aquitaine
40256,ST ETIENNE D ORTHE,40300,ST ETIENNE D ORTHE,,43.5896884243,-1.18697310553,256,,Saint-Étienne-d'Orthe,Saint-Étienne-d'Orthe,40,Landes,75,Nouvelle-Aquitaine
40257,STE EULALIE EN BORN,40200,STE EULALIE EN BORN,,44.2779759643,-1.21402011254,257,,Sainte-Eulalie-en-Born,Sainte-Eulalie-en-Born,40,Landes,75,Nouvelle-Aquitaine
40258,STE FOY,40190,STE FOY,,43.9353210525,-0.326805898415,258,,Sainte-Foy,Sainte-Foy,40,Landes,75,Nouvelle-Aquitaine
40259,ST GEIN,40190,ST GEIN,,43.831513719,-0.306151041119,259,,Saint-Gein,Saint-Gein,40,Landes,75,Nouvelle-Aquitaine
40260,ST GEOURS D AURIBAT,40380,ST GEOURS D AURIBAT,,43.7550006241,-0.841178765033,260,,Saint-Geours-d'Auribat,Saint-Geours-d'Auribat,40,Landes,75,Nouvelle-Aquitaine
40261,ST GEOURS DE MAREMNE,40230,ST GEOURS DE MAREMNE,,43.6867582506,-1.23612727414,261,,Saint-Geours-de-Maremne,Saint-Geours-de-Maremne,40,Landes,75,Nouvelle-Aquitaine
40262,ST GOR,40120,ST GOR,,44.0704357947,-0.23554160647,262,,Saint-Gor,Saint-Gor,40,Landes,75,Nouvelle-Aquitaine
40263,ST JEAN DE LIER,40380,ST JEAN DE LIER,,43.7848343729,-0.880086131984,263,,Saint-Jean-de-Lier,Saint-Jean-de-Lier,40,Landes,75,Nouvelle-Aquitaine
40264,ST JEAN DE MARSACQ,40230,ST JEAN DE MARSACQ,,43.6185869716,-1.26201301334,264,,Saint-Jean-de-Marsacq,Saint-Jean-de-Marsacq,40,Landes,75,Nouvelle-Aquitaine
40265,ST JULIEN D ARMAGNAC,40240,ST JULIEN D ARMAGNAC,,43.9932669722,-0.139358278306,265,,Saint-Julien-d'Armagnac,Saint-Julien-d'Armagnac,40,Landes,75,Nouvelle-Aquitaine
40266,ST JULIEN EN BORN,40170,ST JULIEN EN BORN,,44.0910922901,-1.24835443544,266,,Saint-Julien-en-Born,Saint-Julien-en-Born,40,Landes,75,Nouvelle-Aquitaine
40267,ST JUSTIN,40240,ST JUSTIN,,43.998003454,-0.23036582936,267,,Saint-Justin,Saint-Justin,40,Landes,75,Nouvelle-Aquitaine
40268,ST LAURENT DE GOSSE,40390,ST LAURENT DE GOSSE,,43.5213103938,-1.28404719869,268,,Saint-Laurent-de-Gosse,Saint-Laurent-de-Gosse,40,Landes,75,Nouvelle-Aquitaine
40269,ST LON LES MINES,40300,ST LON LES MINES,,43.6208174786,-1.13368115341,269,,Saint-Lon-les-Mines,Saint-Lon-les-Mines,40,Landes,75,Nouvelle-Aquitaine
40270,ST LOUBOUER,40320,ST LOUBOUER,,43.6802066346,-0.418381695842,270,,Saint-Loubouer,Saint-Loubouer,40,Landes,75,Nouvelle-Aquitaine
40271,STE MARIE DE GOSSE,40390,STE MARIE DE GOSSE,,43.5519446169,-1.23258094215,271,,Sainte-Marie-de-Gosse,Sainte-Marie-de-Gosse,40,Landes,75,Nouvelle-Aquitaine
40272,ST MARTIN DE HINX,40390,ST MARTIN DE HINX,,43.5813482178,-1.27728349893,272,,Saint-Martin-de-Hinx,Saint-Martin-de-Hinx,40,Landes,75,Nouvelle-Aquitaine
40273,ST MARTIN DE SEIGNANX,40390,ST MARTIN DE SEIGNANX,,43.5323882683,-1.38747903614,273,,Saint-Martin-de-Seignanx,Saint-Martin-de-Seignanx,40,Landes,75,Nouvelle-Aquitaine
40274,ST MARTIN D ONEY,40090,ST MARTIN D ONEY,,43.9290627258,-0.652177788305,274,,Saint-Martin-d'Oney,Saint-Martin-d'Oney,40,Landes,75,Nouvelle-Aquitaine
40275,ST MAURICE SUR ADOUR,40270,ST MAURICE SUR ADOUR,,43.7918644129,-0.466687909034,275,,Saint-Maurice-sur-Adour,Saint-Maurice-sur-Adour,40,Landes,75,Nouvelle-Aquitaine
40276,ST MICHEL ESCALUS,40550,ST MICHEL ESCALUS,,43.8816211281,-1.25173443274,276,,Saint-Michel-Escalus,Saint-Michel-Escalus,40,Landes,75,Nouvelle-Aquitaine
40277,ST PANDELON,40180,ST PANDELON,,43.66874805,-1.03963619936,277,,Saint-Pandelon,Saint-Pandelon,40,Landes,75,Nouvelle-Aquitaine
40278,ST PAUL EN BORN,40200,ST PAUL EN BORN,,44.2009953973,-1.15027990694,278,,Saint-Paul-en-Born,Saint-Paul-en-Born,40,Landes,75,Nouvelle-Aquitaine
40279,ST PAUL LES DAX,40990,ST PAUL LES DAX,,43.7464083139,-1.08127980711,279,,Saint-Paul-lès-Dax,Saint-Paul-lès-Dax,40,Landes,75,Nouvelle-Aquitaine
40280,ST PERDON,40090,ST PERDON,,43.876642393,-0.592344644048,280,,Saint-Perdon,Saint-Perdon,40,Landes,75,Nouvelle-Aquitaine
40281,ST PIERRE DU MONT,40280,ST PIERRE DU MONT,,43.8710155534,-0.526374406295,281,,Saint-Pierre-du-Mont,Saint-Pierre-du-Mont,40,Landes,75,Nouvelle-Aquitaine
40282,ST SEVER,40500,ST SEVER,,43.7612851719,-0.566351543475,282,,Saint-Sever,Saint-Sever,40,Landes,75,Nouvelle-Aquitaine
40283,ST VINCENT DE PAUL,40990,ST VINCENT DE PAUL,,43.7664540568,-1.00192615246,283,,Saint-Vincent-de-Paul,Saint-Vincent-de-Paul,40,Landes,75,Nouvelle-Aquitaine
40284,ST VINCENT DE TYROSSE,40230,ST VINCENT DE TYROSSE,,43.659126452,-1.30038486512,284,,Saint-Vincent-de-Tyrosse,Saint-Vincent-de-Tyrosse,40,Landes,75,Nouvelle-Aquitaine
40285,ST YAGUEN,40400,ST YAGUEN,,43.9021086709,-0.742102443672,285,,Saint-Yaguen,Saint-Yaguen,40,Landes,75,Nouvelle-Aquitaine
40286,SAMADET,40320,SAMADET,,43.6301574528,-0.49120862479,286,,Samadet,Samadet,40,Landes,75,Nouvelle-Aquitaine
40287,SANGUINET,40460,SANGUINET,,44.4778702748,-1.06434519608,287,,Sanguinet,Sanguinet,40,Landes,75,Nouvelle-Aquitaine
40288,SARBAZAN,40120,SARBAZAN,,44.0138235777,-0.311945706682,288,,Sarbazan,Sarbazan,40,Landes,75,Nouvelle-Aquitaine
40289,SARRAZIET,40500,SARRAZIET,,43.7057325416,-0.501112212066,289,,Sarraziet,Sarraziet,40,Landes,75,Nouvelle-Aquitaine
40290,SARRON,40800,SARRON,,43.5898061385,-0.266240073155,290,,Sarron,Sarron,40,Landes,75,Nouvelle-Aquitaine
40291,SAUBION,40230,SAUBION,,43.6734751398,-1.33295419507,291,,Saubion,Saubion,40,Landes,75,Nouvelle-Aquitaine
40292,SAUBRIGUES,40230,SAUBRIGUES,,43.6077345999,-1.32232554723,292,,Saubrigues,Saubrigues,40,Landes,75,Nouvelle-Aquitaine
40293,SAUBUSSE,40180,SAUBUSSE,,43.6688378118,-1.18625608435,293,,Saubusse,Saubusse,40,Landes,75,Nouvelle-Aquitaine
40294,SAUGNAC ET CAMBRAN,40180,SAUGNAC ET CAMBRAN,,43.6729991208,-0.98928168376,294,,Saugnac-et-Cambran,Saugnac-et-Cambran,40,Landes,75,Nouvelle-Aquitaine
40295,SAUGNACQ ET MURET,40410,SAUGNACQ ET MURET,,44.3899336803,-0.855507920611,295,,Saugnacq-et-Muret,Saugnacq-et-Muret,40,Landes,75,Nouvelle-Aquitaine
40296,SEIGNOSSE,40510,SEIGNOSSE,,43.7021964029,-1.3970873051,296,,Seignosse,Seignosse,40,Landes,75,Nouvelle-Aquitaine
40297,LE SEN,40420,LE SEN,,44.1436565656,-0.50070045838,297,Le,Sen,Le Sen,40,Landes,75,Nouvelle-Aquitaine
40298,SERRES GASTON,40700,SERRES GASTON,,43.6658680832,-0.522780253556,298,,Serres-Gaston,Serres-Gaston,40,Landes,75,Nouvelle-Aquitaine
40299,SERRESLOUS ET ARRIBANS,40700,SERRESLOUS ET ARRIBANS,,43.6646772072,-0.643699359911,299,,Serreslous-et-Arribans,Serreslous-et-Arribans,40,Landes,75,Nouvelle-Aquitaine
40300,SEYRESSE,40180,SEYRESSE,,43.6832312889,-1.06040363915,300,,Seyresse,Seyresse,40,Landes,75,Nouvelle-Aquitaine
40301,SIEST,40180,SIEST,,43.650022681,-1.13330295872,301,,Siest,Siest,40,Landes,75,Nouvelle-Aquitaine
40302,SINDERES,40110,SINDERES,,44.0266965381,-0.983444158611,302,,Sindères,Sindères,40,Landes,75,Nouvelle-Aquitaine
40303,SOLFERINO,40210,SOLFERINO,,44.1265617122,-0.900366369017,303,,Solférino,Solférino,40,Landes,75,Nouvelle-Aquitaine
40304,SOORTS HOSSEGOR,40150,SOORTS HOSSEGOR,,43.6675688674,-1.41245896656,304,,Soorts-Hossegor,Soorts-Hossegor,40,Landes,75,Nouvelle-Aquitaine
40305,SORBETS,40320,SORBETS,,43.6409732133,-0.325945522623,305,,Sorbets,Sorbets,40,Landes,75,Nouvelle-Aquitaine
40306,SORDE L ABBAYE,40300,SORDE L ABBAYE,,43.5226484038,-1.04772749452,306,,Sorde-l'Abbaye,Sorde-l'Abbaye,40,Landes,75,Nouvelle-Aquitaine
40307,SORE,40430,SORE,,44.3064675641,-0.611382672476,307,,Sore,Sore,40,Landes,75,Nouvelle-Aquitaine
40308,SORT EN CHALOSSE,40180,SORT EN CHALOSSE,,43.6822168772,-0.92522663968,308,,Sort-en-Chalosse,Sort-en-Chalosse,40,Landes,75,Nouvelle-Aquitaine
40309,SOUPROSSE,40250,SOUPROSSE,,43.792726714,-0.716735897141,309,,Souprosse,Souprosse,40,Landes,75,Nouvelle-Aquitaine
40310,SOUSTONS,40140,SOUSTONS,,43.7498040758,-1.3280117155,310,,Soustons,Soustons,40,Landes,75,Nouvelle-Aquitaine
40311,TALLER,40260,TALLER,,43.8849394587,-1.06224013278,311,,Taller,Taller,40,Landes,75,Nouvelle-Aquitaine
40312,TARNOS,40220,TARNOS,,43.5367457997,-1.4653189934,312,,Tarnos,Tarnos,40,Landes,75,Nouvelle-Aquitaine
40313,TARTAS,40400,TARTAS,,43.8182878693,-0.782895218669,313,,Tartas,Tartas,40,Landes,75,Nouvelle-Aquitaine
40314,TERCIS LES BAINS,40180,TERCIS LES BAINS,,43.6706423841,-1.11540319377,314,,Tercis-les-Bains,Tercis-les-Bains,40,Landes,75,Nouvelle-Aquitaine
40315,TETHIEU,40990,TETHIEU,,43.746664517,-0.959617745338,315,,Téthieu,Téthieu,40,Landes,75,Nouvelle-Aquitaine
40316,TILH,40360,TILH,,43.5795107456,-0.837064182098,316,,Tilh,Tilh,40,Landes,75,Nouvelle-Aquitaine
40317,TOSSE,40230,TOSSE,,43.6978170443,-1.31723387155,317,,Tosse,Tosse,40,Landes,75,Nouvelle-Aquitaine
40318,TOULOUZETTE,40250,TOULOUZETTE,,43.7552090028,-0.673971025174,318,,Toulouzette,Toulouzette,40,Landes,75,Nouvelle-Aquitaine
40319,TRENSACQ,40630,TRENSACQ,,44.2234241155,-0.715163717444,319,,Trensacq,Trensacq,40,Landes,75,Nouvelle-Aquitaine
40320,UCHACQ ET PARENTIS,40090,UCHACQ ET PARENTIS,,43.945851007,-0.548232922366,320,,Uchacq-et-Parentis,Uchacq-et-Parentis,40,Landes,75,Nouvelle-Aquitaine
40321,URGONS,40320,URGONS,,43.6394572934,-0.432512659131,321,,Urgons,Urgons,40,Landes,75,Nouvelle-Aquitaine
40322,UZA,40170,UZA,,44.0334990797,-1.19626378992,322,,Uza,Uza,40,Landes,75,Nouvelle-Aquitaine
40323,VERT,40420,VERT,,44.0943105586,-0.611631223993,323,,Vert,Vert,40,Landes,75,Nouvelle-Aquitaine
40324,VICQ D AURIBAT,40380,VICQ D AURIBAT,,43.7846724276,-0.857341971227,324,,Vicq-d'Auribat,Vicq-d'Auribat,40,Landes,75,Nouvelle-Aquitaine
40325,VIELLE TURSAN,40320,VIELLE TURSAN,,43.6846565155,-0.463470521413,325,,Vielle-Tursan,Vielle-Tursan,40,Landes,75,Nouvelle-Aquitaine
40326,VIELLE ST GIRONS,40560,VIELLE ST GIRONS,,43.9395837379,-1.32552380465,326,,Vielle-Saint-Girons,Vielle-Saint-Girons,40,Landes,75,Nouvelle-Aquitaine
40326,VIELLE ST GIRONS,40560,VIELLE ST GIRONS,ST GIRONS,43.9395837379,-1.32552380465,326,,Vielle-Saint-Girons,Vielle-Saint-Girons,40,Landes,75,Nouvelle-Aquitaine
40326,VIELLE ST GIRONS,40560,VIELLE ST GIRONS,ST GIRONS PLAGE,43.9395837379,-1.32552380465,326,,Vielle-Saint-Girons,Vielle-Saint-Girons,40,Landes,75,Nouvelle-Aquitaine
40327,VIELLE SOUBIRAN,40240,VIELLE SOUBIRAN,,44.0556601833,-0.17137876019,327,,Vielle-Soubiran,Vielle-Soubiran,40,Landes,75,Nouvelle-Aquitaine
40328,VIEUX BOUCAU LES BAINS,40480,VIEUX BOUCAU LES BAINS,,43.7874374515,-1.39719537879,328,,Vieux-Boucau-les-Bains,Vieux-Boucau-les-Bains,40,Landes,75,Nouvelle-Aquitaine
40329,LE VIGNAU,40270,LE VIGNAU,,43.7817456803,-0.287331140578,329,Le,Vignau,Le Vignau,40,Landes,75,Nouvelle-Aquitaine
40330,VILLENAVE,40110,VILLENAVE,,43.965937453,-0.822038920542,330,,Villenave,Villenave,40,Landes,75,Nouvelle-Aquitaine
40331,VILLENEUVE DE MARSAN,40190,VILLENEUVE DE MARSAN,,43.9059002211,-0.304063529784,331,,Villeneuve-de-Marsan,Villeneuve-de-Marsan,40,Landes,75,Nouvelle-Aquitaine
40332,YCHOUX,40160,YCHOUX,,44.3573888433,-0.958507887991,332,,Ychoux,Ychoux,40,Landes,75,Nouvelle-Aquitaine
40333,YGOS ST SATURNIN,40110,YGOS ST SATURNIN,,43.9957133184,-0.720748822032,333,,Ygos-Saint-Saturnin,Ygos-Saint-Saturnin,40,Landes,75,Nouvelle-Aquitaine
40334,YZOSSE,40180,YZOSSE,,43.7169211073,-1.01616122338,334,,Yzosse,Yzosse,40,Landes,75,Nouvelle-Aquitaine
41001,AMBLOY,41310,AMBLOY,,47.7092016541,0.969332418982,1,,Ambloy,Ambloy,41,Loir-et-Cher,24,Centre-Val de Loire
41002,ANGE,41400,ANGE,,47.3165186023,1.23978157464,2,,Angé,Angé,41,Loir-et-Cher,24,Centre-Val de Loire
41003,AREINES,41100,AREINES,,47.7980489789,1.10277891267,3,,Areines,Areines,41,Loir-et-Cher,24,Centre-Val de Loire
41004,ARTINS,41800,ARTINS,,47.749928771,0.747280450383,4,,Artins,Artins,41,Loir-et-Cher,24,Centre-Val de Loire
41005,ARVILLE,41170,ARVILLE,,48.0654050928,0.959394908172,5,,Arville,Arville,41,Loir-et-Cher,24,Centre-Val de Loire
41006,AUTAINVILLE,41240,AUTAINVILLE,,47.8843101298,1.42053868965,6,,Autainville,Autainville,41,Loir-et-Cher,24,Centre-Val de Loire
41007,AUTHON,41310,AUTHON,,47.6375449174,0.883240643609,7,,Authon,Authon,41,Loir-et-Cher,24,Centre-Val de Loire
41008,AVARAY,41500,AVARAY,,47.7250804057,1.55500771639,8,,Avaray,Avaray,41,Loir-et-Cher,24,Centre-Val de Loire
41009,AVERDON,41330,AVERDON,,47.6855459731,1.30138038275,9,,Averdon,Averdon,41,Loir-et-Cher,24,Centre-Val de Loire
41010,AZE,41100,AZE,,47.8570277979,0.997062059366,10,,Azé,Azé,41,Loir-et-Cher,24,Centre-Val de Loire
41011,BAIGNEAUX,41290,BAIGNEAUX,,47.7751440028,1.25868838159,11,,Baigneaux,Baigneaux,41,Loir-et-Cher,24,Centre-Val de Loire
41012,BAILLOU,41170,BAILLOU,,47.9837179954,0.853704116727,12,,Baillou,Baillou,41,Loir-et-Cher,24,Centre-Val de Loire
41013,BAUZY,41250,BAUZY,,47.5290329996,1.61142205503,13,,Bauzy,Bauzy,41,Loir-et-Cher,24,Centre-Val de Loire
41014,BEAUCHENE,41170,BEAUCHENE,,47.9427844885,0.971276009612,14,,Beauchêne,Beauchêne,41,Loir-et-Cher,24,Centre-Val de Loire
41015,BEAUVILLIERS,41290,BEAUVILLIERS,,47.8400178588,1.25331168188,15,,Beauvilliers,Beauvilliers,41,Loir-et-Cher,24,Centre-Val de Loire
41016,BILLY,41130,BILLY,,47.3138942249,1.54417512576,16,,Billy,Billy,41,Loir-et-Cher,24,Centre-Val de Loire
41017,BINAS,41240,BINAS,,47.906478049,1.46560319599,17,,Binas,Binas,41,Loir-et-Cher,24,Centre-Val de Loire
41018,BLOIS,41000,BLOIS,,47.5817013938,1.30625551583,18,,Blois,Blois,41,Loir-et-Cher,24,Centre-Val de Loire
41019,BOISSEAU,41290,BOISSEAU,,47.7736389694,1.30617122072,19,,Boisseau,Boisseau,41,Loir-et-Cher,24,Centre-Val de Loire
41020,BONNEVEAU,41800,BONNEVEAU,,47.8126485369,0.760225315658,20,,Bonneveau,Bonneveau,41,Loir-et-Cher,24,Centre-Val de Loire
41022,BOUFFRY,41270,BOUFFRY,,48.0100096651,1.088414927,22,,Bouffry,Bouffry,41,Loir-et-Cher,24,Centre-Val de Loire
41024,BOURSAY,41270,BOURSAY,,48.0187748619,0.989150549999,24,,Boursay,Boursay,41,Loir-et-Cher,24,Centre-Val de Loire
41025,BRACIEUX,41250,BRACIEUX,,47.5513439823,1.543537457,25,,Bracieux,Bracieux,41,Loir-et-Cher,24,Centre-Val de Loire
41026,BREVAINVILLE,41160,BREVAINVILLE,,47.9489615518,1.26872113426,26,,Brévainville,Brévainville,41,Loir-et-Cher,24,Centre-Val de Loire
41027,BRIOU,41370,BRIOU,,47.8157886971,1.47190975066,27,,Briou,Briou,41,Loir-et-Cher,24,Centre-Val de Loire
41028,BUSLOUP,41160,BUSLOUP,,47.9044276,1.13612513733,28,,Busloup,Busloup,41,Loir-et-Cher,24,Centre-Val de Loire
41029,CANDE SUR BEUVRON,41120,CANDE SUR BEUVRON,,47.5069378563,1.26560344876,29,,Candé-sur-Beuvron,Candé-sur-Beuvron,41,Loir-et-Cher,24,Centre-Val de Loire
41030,CELLE,41360,CELLE,,47.8306173002,0.796511873772,30,,Cellé,Cellé,41,Loir-et-Cher,24,Centre-Val de Loire
41031,CELLETTES,41120,CELLETTES,,47.5283508082,1.38123647264,31,,Cellettes,Cellettes,41,Loir-et-Cher,24,Centre-Val de Loire
41032,CHAILLES,41120,CHAILLES,,47.5389507854,1.31598876114,32,,Chailles,Chailles,41,Loir-et-Cher,24,Centre-Val de Loire
41033,CHAMBON SUR CISSE,41190,CHAMBON SUR CISSE,,47.5571092655,1.21950862437,33,,Chambon-sur-Cisse,Chambon-sur-Cisse,41,Loir-et-Cher,24,Centre-Val de Loire
41034,CHAMBORD,41250,CHAMBORD,,47.6160275858,1.54153082337,34,,Chambord,Chambord,41,Loir-et-Cher,24,Centre-Val de Loire
41035,CHAMPIGNY EN BEAUCE,41330,CHAMPIGNY EN BEAUCE,,47.7177889703,1.25697557879,35,,Champigny-en-Beauce,Champigny-en-Beauce,41,Loir-et-Cher,24,Centre-Val de Loire
41036,CHAON,41600,CHAON,,47.6121257976,2.162800504,36,,Chaon,Chaon,41,Loir-et-Cher,24,Centre-Val de Loire
41037,LA CHAPELLE ENCHERIE,41290,LA CHAPELLE ENCHERIE,,47.8261991313,1.21209183111,37,La,Chapelle-Enchérie,La Chapelle-Enchérie,41,Loir-et-Cher,24,Centre-Val de Loire
41038,LA CHAPELLE MONTMARTIN,41320,LA CHAPELLE MONTMARTIN,,47.2591135701,1.75167253244,38,La,Chapelle-Montmartin,La Chapelle-Montmartin,41,Loir-et-Cher,24,Centre-Val de Loire
41039,LA CHAPELLE ST MARTIN EN PLAINE,41500,LA CHAPELLE ST MARTIN EN PLAINE,,47.7330590404,1.41192253742,39,La,Chapelle-Saint-Martin-en-Plaine,La Chapelle-Saint-Martin-en-Plaine,41,Loir-et-Cher,24,Centre-Val de Loire
41040,LA CHAPELLE VENDOMOISE,41330,LA CHAPELLE VENDOMOISE,,47.6758696503,1.23879753733,40,La,Chapelle-Vendômoise,La Chapelle-Vendômoise,41,Loir-et-Cher,24,Centre-Val de Loire
41041,LA CHAPELLE VICOMTESSE,41270,LA CHAPELLE VICOMTESSE,,47.9859108878,1.03373014435,41,La,Chapelle-Vicomtesse,La Chapelle-Vicomtesse,41,Loir-et-Cher,24,Centre-Val de Loire
41042,CHATEAUVIEUX,41110,CHATEAUVIEUX,,47.2199501992,1.36069389103,42,,Châteauvieux,Châteauvieux,41,Loir-et-Cher,24,Centre-Val de Loire
41043,CHATILLON SUR CHER,41130,CHATILLON SUR CHER,,47.2867451592,1.48367796005,43,,Châtillon-sur-Cher,Châtillon-sur-Cher,41,Loir-et-Cher,24,Centre-Val de Loire
41044,CHATRES SUR CHER,41320,CHATRES SUR CHER,,47.2992883629,1.93011370714,44,,Châtres-sur-Cher,Châtres-sur-Cher,41,Loir-et-Cher,24,Centre-Val de Loire
41045,CHAUMONT SUR LOIRE,41150,CHAUMONT SUR LOIRE,,47.4655792877,1.19931554619,45,,Chaumont-sur-Loire,Chaumont-sur-Loire,41,Loir-et-Cher,24,Centre-Val de Loire
41046,CHAUMONT SUR THARONNE,41600,CHAUMONT SUR THARONNE,,47.6135106337,1.92322250059,46,,Chaumont-sur-Tharonne,Chaumont-sur-Tharonne,41,Loir-et-Cher,24,Centre-Val de Loire
41047,LA CHAUSSEE ST VICTOR,41260,LA CHAUSSEE ST VICTOR,,47.6111559006,1.35763609326,47,La,Chaussée-Saint-Victor,La Chaussée-Saint-Victor,41,Loir-et-Cher,24,Centre-Val de Loire
41048,CHAUVIGNY DU PERCHE,41270,CHAUVIGNY DU PERCHE,,47.9595342882,1.07791272033,48,,Chauvigny-du-Perche,Chauvigny-du-Perche,41,Loir-et-Cher,24,Centre-Val de Loire
41049,CHEMERY,41700,CHEMERY,,47.3579321588,1.48828738299,49,,Chémery,Chémery,41,Loir-et-Cher,24,Centre-Val de Loire
41050,CHEVERNY,41700,CHEVERNY,,47.4773814435,1.45430435221,50,,Cheverny,Cheverny,41,Loir-et-Cher,24,Centre-Val de Loire
41051,CHISSAY EN TOURAINE,41400,CHISSAY EN TOURAINE,,47.3535043561,1.13579761791,51,,Chissay-en-Touraine,Chissay-en-Touraine,41,Loir-et-Cher,24,Centre-Val de Loire
41052,CHITENAY,41120,CHITENAY,,47.4910846499,1.36717520033,52,,Chitenay,Chitenay,41,Loir-et-Cher,24,Centre-Val de Loire
41053,CHOUE,41170,CHOUE,,47.9979943949,0.93157545943,53,,Choue,Choue,41,Loir-et-Cher,24,Centre-Val de Loire
41054,CHOUSSY,41700,CHOUSSY,,47.3651066896,1.34936749964,54,,Choussy,Choussy,41,Loir-et-Cher,24,Centre-Val de Loire
41055,CHOUZY SUR CISSE,41150,CHOUZY SUR CISSE,,47.5244470983,1.23897400473,55,,Valloire-sur-Cisse,Valloire-sur-Cisse,41,Loir-et-Cher,24,Centre-Val de Loire
41057,CONAN,41290,CONAN,,47.7409180871,1.29793582342,57,,Conan,Conan,41,Loir-et-Cher,24,Centre-Val de Loire
41058,CONCRIERS,41370,CONCRIERS,,47.7777377144,1.48251808287,58,,Concriers,Concriers,41,Loir-et-Cher,24,Centre-Val de Loire
41059,CONTRES,41700,CONTRES,,47.4210455026,1.43621364655,59,,Contres,Contres,41,Loir-et-Cher,24,Centre-Val de Loire
41060,CORMENON,41170,CORMENON,,47.962055026,0.907252808617,60,,Cormenon,Cormenon,41,Loir-et-Cher,24,Centre-Val de Loire
41061,CORMERAY,41120,CORMERAY,,47.4861379273,1.40302326426,61,,Cormeray,Cormeray,41,Loir-et-Cher,24,Centre-Val de Loire
41062,COUDDES,41700,COUDDES,,47.3643474319,1.40421925055,62,,Couddes,Couddes,41,Loir-et-Cher,24,Centre-Val de Loire
41063,COUFFY,41110,COUFFY,,47.2486177348,1.43680361575,63,,Couffy,Couffy,41,Loir-et-Cher,24,Centre-Val de Loire
41064,COULANGES,41150,COULANGES,,47.5390176116,1.20853973437,64,,Coulanges,Coulanges,41,Loir-et-Cher,24,Centre-Val de Loire
41065,COULOMMIERS LA TOUR,41100,COULOMMIERS LA TOUR,,47.777833171,1.13328035122,65,,Coulommiers-la-Tour,Coulommiers-la-Tour,41,Loir-et-Cher,24,Centre-Val de Loire
41066,COURBOUZON,41500,COURBOUZON,,47.6953682562,1.53490352676,66,,Courbouzon,Courbouzon,41,Loir-et-Cher,24,Centre-Val de Loire
41067,COUR CHEVERNY,41700,COUR CHEVERNY,,47.5102298859,1.4769737233,67,,Cour-Cheverny,Cour-Cheverny,41,Loir-et-Cher,24,Centre-Val de Loire
41068,COURMEMIN,41230,COURMEMIN,,47.4697320137,1.6274781438,68,,Courmemin,Courmemin,41,Loir-et-Cher,24,Centre-Val de Loire
41069,COUR SUR LOIRE,41500,COUR SUR LOIRE,,47.657935319,1.41502499081,69,,Cour-sur-Loire,Cour-sur-Loire,41,Loir-et-Cher,24,Centre-Val de Loire
41070,COUTURE SUR LOIR,41800,COUTURE SUR LOIR,,47.7491446758,0.685596092581,70,,Couture-sur-Loir,Couture-sur-Loir,41,Loir-et-Cher,24,Centre-Val de Loire
41071,CROUY SUR COSSON,41220,CROUY SUR COSSON,,47.6544025153,1.62332490974,71,,Crouy-sur-Cosson,Crouy-sur-Cosson,41,Loir-et-Cher,24,Centre-Val de Loire
41072,CRUCHERAY,41100,CRUCHERAY,,47.7318797043,1.09938100556,72,,Crucheray,Crucheray,41,Loir-et-Cher,24,Centre-Val de Loire
41073,DANZE,41160,DANZE,,47.9075675981,1.01369296158,73,,Danzé,Danzé,41,Loir-et-Cher,24,Centre-Val de Loire
41074,DHUIZON,41220,DHUIZON,,47.5875471453,1.67512625206,74,,Dhuizon,Dhuizon,41,Loir-et-Cher,24,Centre-Val de Loire
41075,DROUE,41270,DROUE,,48.0341247129,1.06588244097,75,,Droué,Droué,41,Loir-et-Cher,24,Centre-Val de Loire
41077,EPIAIS,41290,EPIAIS,,47.8125696504,1.25048989277,77,,Épiais,Épiais,41,Loir-et-Cher,24,Centre-Val de Loire
41078,EPUISAY,41360,EPUISAY,,47.8944978835,0.927766807558,78,,Épuisay,Épuisay,41,Loir-et-Cher,24,Centre-Val de Loire
41079,LES ESSARTS,41800,LES ESSARTS,,47.7323504174,0.712859536638,79,Les,Essarts,Les Essarts,41,Loir-et-Cher,24,Centre-Val de Loire
41080,FAVEROLLES SUR CHER,41400,FAVEROLLES SUR CHER,,47.3121076909,1.18291506332,80,,Faverolles-sur-Cher,Faverolles-sur-Cher,41,Loir-et-Cher,24,Centre-Val de Loire
41081,FAYE,41100,FAYE,,47.8035457994,1.18892925408,81,,Faye,Faye,41,Loir-et-Cher,24,Centre-Val de Loire
41082,FEINGS,41120,FEINGS,,47.4403763122,1.3693906983,82,,Feings,Feings,41,Loir-et-Cher,24,Centre-Val de Loire
41083,LA FERTE BEAUHARNAIS,41210,LA FERTE BEAUHARNAIS,,47.5392830492,1.85456317215,83,La,Ferté-Beauharnais,La Ferté-Beauharnais,41,Loir-et-Cher,24,Centre-Val de Loire
41084,LA FERTE IMBAULT,41300,LA FERTE IMBAULT,,47.4015726244,1.95894695638,84,La,Ferté-Imbault,La Ferté-Imbault,41,Loir-et-Cher,24,Centre-Val de Loire
41085,LA FERTE ST CYR,41220,LA FERTE ST CYR,,47.668097057,1.68657012929,85,La,Ferté-Saint-Cyr,La Ferté-Saint-Cyr,41,Loir-et-Cher,24,Centre-Val de Loire
41086,FONTAINES EN SOLOGNE,41250,FONTAINES EN SOLOGNE,,47.487762675,1.54970974025,86,,Fontaines-en-Sologne,Fontaines-en-Sologne,41,Loir-et-Cher,24,Centre-Val de Loire
41087,FONTAINE LES COTEAUX,41800,FONTAINE LES COTEAUX,,47.798872695,0.835689448447,87,,Fontaine-les-Coteaux,Fontaine-les-Coteaux,41,Loir-et-Cher,24,Centre-Val de Loire
41088,FONTAINE RAOUL,41270,FONTAINE RAOUL,,47.9744723154,1.13493872934,88,,Fontaine-Raoul,Fontaine-Raoul,41,Loir-et-Cher,24,Centre-Val de Loire
41089,LA FONTENELLE,41270,LA FONTENELLE,,48.0550550265,1.01388615653,89,La,Fontenelle,La Fontenelle,41,Loir-et-Cher,24,Centre-Val de Loire
41090,FORTAN,41360,FORTAN,,47.8442944617,0.914220715348,90,,Fortan,Fortan,41,Loir-et-Cher,24,Centre-Val de Loire
41091,FOSSE,41330,FOSSE,,47.6307150923,1.27701587955,91,,Fossé,Fossé,41,Loir-et-Cher,24,Centre-Val de Loire
41092,FOUGERES SUR BIEVRE,41120,FOUGERES SUR BIEVRE,,47.4498991602,1.34106048827,92,,Fougères-sur-Bièvre,Fougères-sur-Bièvre,41,Loir-et-Cher,24,Centre-Val de Loire
41093,FRANCAY,41190,FRANCAY,,47.6178643296,1.10778289349,93,,Françay,Françay,41,Loir-et-Cher,24,Centre-Val de Loire
41094,FRESNES,41700,FRESNES,,47.4383129016,1.41006263534,94,,Fresnes,Fresnes,41,Loir-et-Cher,24,Centre-Val de Loire
41095,FRETEVAL,41160,FRETEVAL,,47.8900107594,1.20628228428,95,,Fréteval,Fréteval,41,Loir-et-Cher,24,Centre-Val de Loire
41096,LE GAULT PERCHE,41270,LE GAULT PERCHE,,48.0992829662,0.989927474604,96,Le,Gault-du-Perche,Le Gault-du-Perche,41,Loir-et-Cher,24,Centre-Val de Loire
41097,GIEVRES,41130,GIEVRES,,47.2892098162,1.67026537552,97,,Gièvres,Gièvres,41,Loir-et-Cher,24,Centre-Val de Loire
41098,GOMBERGEAN,41310,GOMBERGEAN,,47.6521361252,1.07496641179,98,,Gombergean,Gombergean,41,Loir-et-Cher,24,Centre-Val de Loire
41099,GY EN SOLOGNE,41230,GY EN SOLOGNE,,47.3414775908,1.59439346887,99,,Gy-en-Sologne,Gy-en-Sologne,41,Loir-et-Cher,24,Centre-Val de Loire
41100,LES HAYES,41800,LES HAYES,,47.6990596775,0.780078483408,100,Les,Hayes,Les Hayes,41,Loir-et-Cher,24,Centre-Val de Loire
41101,HERBAULT,41190,HERBAULT,,47.6153995146,1.1561362769,101,,Herbault,Herbault,41,Loir-et-Cher,24,Centre-Val de Loire
41102,HOUSSAY,41800,HOUSSAY,,47.7446954686,0.947069193008,102,,Houssay,Houssay,41,Loir-et-Cher,24,Centre-Val de Loire
41103,HUISSEAU EN BEAUCE,41310,HUISSEAU EN BEAUCE,,47.7228645102,1.01092284734,103,,Huisseau-en-Beauce,Huisseau-en-Beauce,41,Loir-et-Cher,24,Centre-Val de Loire
41104,HUISSEAU SUR COSSON,41350,HUISSEAU SUR COSSON,,47.5946124012,1.45427249126,104,,Huisseau-sur-Cosson,Huisseau-sur-Cosson,41,Loir-et-Cher,24,Centre-Val de Loire
41105,JOSNES,41370,JOSNES,,47.791916148,1.53206628064,105,,Josnes,Josnes,41,Loir-et-Cher,24,Centre-Val de Loire
41106,LAMOTTE BEUVRON,41600,LAMOTTE BEUVRON,,47.6033008872,2.02321996926,106,,Lamotte-Beuvron,Lamotte-Beuvron,41,Loir-et-Cher,24,Centre-Val de Loire
41107,LANCE,41310,LANCE,,47.6845629215,1.06714179153,107,,Lancé,Lancé,41,Loir-et-Cher,24,Centre-Val de Loire
41108,LANCOME,41190,LANCOME,,47.652802696,1.12724958173,108,,Lancôme,Lancôme,41,Loir-et-Cher,24,Centre-Val de Loire
41109,LANDES LE GAULOIS,41190,LANDES LE GAULOIS,,47.6576915712,1.17175153465,109,,Landes-le-Gaulois,Landes-le-Gaulois,41,Loir-et-Cher,24,Centre-Val de Loire
41110,LANGON,41320,LANGON,,47.3082253246,1.83872499965,110,,Langon-sur-Cher,Langon-sur-Cher,41,Loir-et-Cher,24,Centre-Val de Loire
41112,LASSAY SUR CROISNE,41230,LASSAY SUR CROISNE,,47.3683917223,1.63492604577,112,,Lassay-sur-Croisne,Lassay-sur-Croisne,41,Loir-et-Cher,24,Centre-Val de Loire
41113,LAVARDIN,41800,LAVARDIN,,47.7369568868,0.886026450993,113,,Lavardin,Lavardin,41,Loir-et-Cher,24,Centre-Val de Loire
41114,LESTIOU,41500,LESTIOU,,47.7487658878,1.5656758407,114,,Lestiou,Lestiou,41,Loir-et-Cher,24,Centre-Val de Loire
41115,LIGNIERES,41160,LIGNIERES,,47.8628195017,1.19398273817,115,,Lignières,Lignières,41,Loir-et-Cher,24,Centre-Val de Loire
41116,LISLE,41100,LISLE,,47.8690069575,1.10711931801,116,,Lisle,Lisle,41,Loir-et-Cher,24,Centre-Val de Loire
41118,LOREUX,41200,LOREUX,,47.4034848676,1.84312740656,118,,Loreux,Loreux,41,Loir-et-Cher,24,Centre-Val de Loire
41119,LORGES,41370,LORGES,,47.8211948112,1.49873755779,119,,Lorges,Lorges,41,Loir-et-Cher,24,Centre-Val de Loire
41120,LUNAY,41360,LUNAY,,47.8098385704,0.904566521301,120,,Lunay,Lunay,41,Loir-et-Cher,24,Centre-Val de Loire
41121,LA MADELEINE VILLEFROUIN,41370,LA MADELEINE VILLEFROUIN,,47.7776509493,1.38986777758,121,La,Madeleine-Villefrouin,La Madeleine-Villefrouin,41,Loir-et-Cher,24,Centre-Val de Loire
41122,MARAY,41320,MARAY,,47.2356263735,1.87104210873,122,,Maray,Maray,41,Loir-et-Cher,24,Centre-Val de Loire
41123,MARCHENOIR,41370,MARCHENOIR,,47.8340531045,1.41066062469,123,,Marchenoir,Marchenoir,41,Loir-et-Cher,24,Centre-Val de Loire
41124,MARCILLY EN BEAUCE,41100,MARCILLY EN BEAUCE,,47.7585797087,1.00338364896,124,,Marcilly-en-Beauce,Marcilly-en-Beauce,41,Loir-et-Cher,24,Centre-Val de Loire
41125,MARCILLY EN GAULT,41210,MARCILLY EN GAULT,,47.4626952674,1.87087192257,125,,Marcilly-en-Gault,Marcilly-en-Gault,41,Loir-et-Cher,24,Centre-Val de Loire
41126,MAREUIL SUR CHER,41110,MAREUIL SUR CHER,,47.2756208547,1.30313031356,126,,Mareuil-sur-Cher,Mareuil-sur-Cher,41,Loir-et-Cher,24,Centre-Val de Loire
41127,LA MAROLLE EN SOLOGNE,41210,LA MAROLLE EN SOLOGNE,,47.5829832487,1.78891907492,127,La,Marolle-en-Sologne,La Marolle-en-Sologne,41,Loir-et-Cher,24,Centre-Val de Loire
41128,MAROLLES,41330,MAROLLES,,47.652671241,1.31004193193,128,,Marolles,Marolles,41,Loir-et-Cher,24,Centre-Val de Loire
41129,MASLIVES,41250,MASLIVES,,47.6329050849,1.48289377715,129,,Maslives,Maslives,41,Loir-et-Cher,24,Centre-Val de Loire
41130,MAVES,41500,MAVES,,47.7379268366,1.35279251946,130,,Maves,Maves,41,Loir-et-Cher,24,Centre-Val de Loire
41131,MAZANGE,41100,MAZANGE,,47.8402255423,0.948834044312,131,,Mazangé,Mazangé,41,Loir-et-Cher,24,Centre-Val de Loire
41132,MEHERS,41140,MEHERS,,47.3272275407,1.45220950578,132,,Méhers,Méhers,41,Loir-et-Cher,24,Centre-Val de Loire
41134,MENARS,41500,MENARS,,47.6472843948,1.3998472166,134,,Menars,Menars,41,Loir-et-Cher,24,Centre-Val de Loire
41135,MENNETOU SUR CHER,41320,MENNETOU SUR CHER,,47.2927801953,1.8752007489,135,,Mennetou-sur-Cher,Mennetou-sur-Cher,41,Loir-et-Cher,24,Centre-Val de Loire
41136,MER,41500,MER,,47.7134200176,1.50398022578,136,,Mer,Mer,41,Loir-et-Cher,24,Centre-Val de Loire
41137,MESLAND,41150,MESLAND,,47.5174737534,1.10007537516,137,,Mesland,Mesland,41,Loir-et-Cher,24,Centre-Val de Loire
41138,MESLAY,41100,MESLAY,,47.8158272648,1.11151070429,138,,Meslay,Meslay,41,Loir-et-Cher,24,Centre-Val de Loire
41139,MEUSNES,41130,MEUSNES,,47.2459267939,1.50092263509,139,,Meusnes,Meusnes,41,Loir-et-Cher,24,Centre-Val de Loire
41140,MILLANCAY,41200,MILLANCAY,,47.4506811357,1.78045310661,140,,Millançay,Millançay,41,Loir-et-Cher,24,Centre-Val de Loire
41141,MOISY,41160,MOISY,,47.9185905171,1.31867272655,141,,Moisy,Moisy,41,Loir-et-Cher,24,Centre-Val de Loire
41142,VALENCISSE,41190,VALENCISSE,MOLINEUF,47.5802707156,1.22561710259,142,,Valencisse,Valencisse,41,Loir-et-Cher,24,Centre-Val de Loire
41142,VALENCISSE,41190,VALENCISSE,ORCHAISE,47.5802707156,1.22561710259,142,,Valencisse,Valencisse,41,Loir-et-Cher,24,Centre-Val de Loire
41143,MONDOUBLEAU,41170,MONDOUBLEAU,,47.9867303784,0.895813338933,143,,Mondoubleau,Mondoubleau,41,Loir-et-Cher,24,Centre-Val de Loire
41144,MONTEAUX,41150,MONTEAUX,,47.4857270055,1.10591640742,144,,Monteaux,Monteaux,41,Loir-et-Cher,24,Centre-Val de Loire
41145,MONTHOU SUR BIEVRE,41120,MONTHOU SUR BIEVRE,,47.4622240218,1.27087030683,145,,Monthou-sur-Bièvre,Monthou-sur-Bièvre,41,Loir-et-Cher,24,Centre-Val de Loire
41146,MONTHOU SUR CHER,41400,MONTHOU SUR CHER,,47.351720705,1.29095407473,146,,Monthou-sur-Cher,Monthou-sur-Cher,41,Loir-et-Cher,24,Centre-Val de Loire
41147,LES MONTILS,41120,LES MONTILS,,47.5035507545,1.3044545594,147,Les,Montils,Les Montils,41,Loir-et-Cher,24,Centre-Val de Loire
41148,MONTLIVAULT,41350,MONTLIVAULT,,47.6359771926,1.44589857808,148,,Montlivault,Montlivault,41,Loir-et-Cher,24,Centre-Val de Loire
41149,MONTOIRE SUR LE LOIR,41800,MONTOIRE SUR LE LOIR,,47.7650269644,0.854948191169,149,,Montoire-sur-le-Loir,Montoire-sur-le-Loir,41,Loir-et-Cher,24,Centre-Val de Loire
41149,MONTOIRE SUR LE LOIR,41800,MONTOIRE SUR LE LOIR,ST QUENTIN LES TROO,47.7650269644,0.854948191169,149,,Montoire-sur-le-Loir,Montoire-sur-le-Loir,41,Loir-et-Cher,24,Centre-Val de Loire
41150,MONT PRES CHAMBORD,41250,MONT PRES CHAMBORD,,47.5576074814,1.44802815155,150,,Mont-près-Chambord,Mont-près-Chambord,41,Loir-et-Cher,24,Centre-Val de Loire
41151,MONTRICHARD VAL DE CHER,41400,MONTRICHARD VAL DE CHER,,47.3602548411,1.1835710637,151,,Montrichard Val de Cher,Montrichard Val de Cher,41,Loir-et-Cher,24,Centre-Val de Loire
41151,MONTRICHARD VAL DE CHER,41400,MONTRICHARD VAL DE CHER,BOURRE,47.3602548411,1.1835710637,151,,Montrichard Val de Cher,Montrichard Val de Cher,41,Loir-et-Cher,24,Centre-Val de Loire
41152,MONTRIEUX EN SOLOGNE,41210,MONTRIEUX EN SOLOGNE,,47.5504272914,1.71812517711,152,,Montrieux-en-Sologne,Montrieux-en-Sologne,41,Loir-et-Cher,24,Centre-Val de Loire
41153,MONTROUVEAU,41800,MONTROUVEAU,,47.7062763559,0.713887918524,153,,Montrouveau,Montrouveau,41,Loir-et-Cher,24,Centre-Val de Loire
41154,MOREE,41160,MOREE,,47.9158762496,1.24887473197,154,,Morée,Morée,41,Loir-et-Cher,24,Centre-Val de Loire
41155,MUIDES SUR LOIRE,41500,MUIDES SUR LOIRE,,47.6651395103,1.53313366555,155,,Muides-sur-Loire,Muides-sur-Loire,41,Loir-et-Cher,24,Centre-Val de Loire
41156,MULSANS,41500,MULSANS,,47.6949446536,1.38085020196,156,,Mulsans,Mulsans,41,Loir-et-Cher,24,Centre-Val de Loire
41157,MUR DE SOLOGNE,41230,MUR DE SOLOGNE,,47.4172155186,1.59888181618,157,,Mur-de-Sologne,Mur-de-Sologne,41,Loir-et-Cher,24,Centre-Val de Loire
41158,NAVEIL,41100,NAVEIL,,47.7865941509,1.02283866344,158,,Naveil,Naveil,41,Loir-et-Cher,24,Centre-Val de Loire
41159,NEUNG SUR BEUVRON,41210,NEUNG SUR BEUVRON,,47.5356505563,1.81134758711,159,,Neung-sur-Beuvron,Neung-sur-Beuvron,41,Loir-et-Cher,24,Centre-Val de Loire
41160,NEUVY,41250,NEUVY,,47.568021007,1.5863718132,160,,Neuvy,Neuvy,41,Loir-et-Cher,24,Centre-Val de Loire
41161,NOUAN LE FUZELIER,41600,NOUAN LE FUZELIER,,47.5365531093,2.0295223621,161,,Nouan-le-Fuzelier,Nouan-le-Fuzelier,41,Loir-et-Cher,24,Centre-Val de Loire
41163,NOURRAY,41310,NOURRAY,,47.7184151238,1.05012298309,163,,Nourray,Nourray,41,Loir-et-Cher,24,Centre-Val de Loire
41164,NOYERS SUR CHER,41140,NOYERS SUR CHER,,47.2831417944,1.41063801702,164,,Noyers-sur-Cher,Noyers-sur-Cher,41,Loir-et-Cher,24,Centre-Val de Loire
41165,OIGNY,41170,OIGNY,,48.0640326764,0.911161000483,165,,Oigny,Oigny,41,Loir-et-Cher,24,Centre-Val de Loire
41166,OISLY,41700,OISLY,,47.3944986004,1.37611606492,166,,Oisly,Oisly,41,Loir-et-Cher,24,Centre-Val de Loire
41167,ONZAIN,41150,ONZAIN,,47.5123997667,1.16507315908,167,,Veuzain-sur-Loire,Veuzain-sur-Loire,41,Loir-et-Cher,24,Centre-Val de Loire
41168,ORCAY,41300,ORCAY,,47.3052212436,2.12165116654,168,,Orçay,Orçay,41,Loir-et-Cher,24,Centre-Val de Loire
41170,OUCHAMPS,41120,OUCHAMPS,,47.474967269,1.31995907778,170,,Ouchamps,Ouchamps,41,Loir-et-Cher,24,Centre-Val de Loire
41171,OUCQUES,41290,OUCQUES,,47.8191691674,1.29908240443,171,,Oucques La Nouvelle,Oucques La Nouvelle,41,Loir-et-Cher,24,Centre-Val de Loire
41172,OUZOUER LE DOYEN,41160,OUZOUER LE DOYEN,,47.9431198106,1.34205640205,172,,Ouzouer-le-Doyen,Ouzouer-le-Doyen,41,Loir-et-Cher,24,Centre-Val de Loire
41173,BEAUCE LA ROMAINE,41160,BEAUCE LA ROMAINE,LA COLOMBE,47.9026361174,1.53361751623,173,,Beauce la Romaine,Beauce la Romaine,41,Loir-et-Cher,24,Centre-Val de Loire
41173,BEAUCE LA ROMAINE,41160,BEAUCE LA ROMAINE,SEMERVILLE,47.9026361174,1.53361751623,173,,Beauce la Romaine,Beauce la Romaine,41,Loir-et-Cher,24,Centre-Val de Loire
41173,BEAUCE LA ROMAINE,41240,BEAUCE LA ROMAINE,MEMBROLLES,47.9026361174,1.53361751623,173,,Beauce la Romaine,Beauce la Romaine,41,Loir-et-Cher,24,Centre-Val de Loire
41173,BEAUCE LA ROMAINE,41240,BEAUCE LA ROMAINE,OUZOUER LE MARCHE,47.9026361174,1.53361751623,173,,Beauce la Romaine,Beauce la Romaine,41,Loir-et-Cher,24,Centre-Val de Loire
41173,BEAUCE LA ROMAINE,41240,BEAUCE LA ROMAINE,PRENOUVELLON,47.9026361174,1.53361751623,173,,Beauce la Romaine,Beauce la Romaine,41,Loir-et-Cher,24,Centre-Val de Loire
41173,BEAUCE LA ROMAINE,41240,BEAUCE LA ROMAINE,TRIPLEVILLE,47.9026361174,1.53361751623,173,,Beauce la Romaine,Beauce la Romaine,41,Loir-et-Cher,24,Centre-Val de Loire
41173,BEAUCE LA ROMAINE,41240,BEAUCE LA ROMAINE,VERDES,47.9026361174,1.53361751623,173,,Beauce la Romaine,Beauce la Romaine,41,Loir-et-Cher,24,Centre-Val de Loire
41174,PERIGNY,41100,PERIGNY,,47.7509656602,1.15035370714,174,,Périgny,Périgny,41,Loir-et-Cher,24,Centre-Val de Loire
41175,PEZOU,41100,PEZOU,,47.8682993601,1.14633634068,175,,Pezou,Pezou,41,Loir-et-Cher,24,Centre-Val de Loire
41176,PIERREFITTE SUR SAULDRE,41300,PIERREFITTE SUR SAULDRE,,47.5270515255,2.12726759593,176,,Pierrefitte-sur-Sauldre,Pierrefitte-sur-Sauldre,41,Loir-et-Cher,24,Centre-Val de Loire
41177,LE PLESSIS DORIN,41170,LE PLESSIS DORIN,,48.0884605817,0.861585224185,177,Le,Plessis-Dorin,Le Plessis-Dorin,41,Loir-et-Cher,24,Centre-Val de Loire
41178,LE PLESSIS L ECHELLE,41370,LE PLESSIS L ECHELLE,,47.8112764958,1.42478155888,178,Le,Plessis-l'Échelle,Le Plessis-l'Échelle,41,Loir-et-Cher,24,Centre-Val de Loire
41179,LE POISLAY,41270,LE POISLAY,,48.0706482799,1.07027921906,179,Le,Poislay,Le Poislay,41,Loir-et-Cher,24,Centre-Val de Loire
41180,PONTLEVOY,41400,PONTLEVOY,,47.3967107707,1.2300894563,180,,Pontlevoy,Pontlevoy,41,Loir-et-Cher,24,Centre-Val de Loire
41181,POUILLE,41110,POUILLE,,47.3076458828,1.27817094314,181,,Pouillé,Pouillé,41,Loir-et-Cher,24,Centre-Val de Loire
41182,PRAY,41190,PRAY,,47.6844730122,1.11113363302,182,,Pray,Pray,41,Loir-et-Cher,24,Centre-Val de Loire
41184,PRUNAY CASSEREAU,41310,PRUNAY CASSEREAU,,47.6845526893,0.912628813807,184,,Prunay-Cassereau,Prunay-Cassereau,41,Loir-et-Cher,24,Centre-Val de Loire
41185,PRUNIERS EN SOLOGNE,41200,PRUNIERS EN SOLOGNE,,47.3404974973,1.67985209939,185,,Pruniers-en-Sologne,Pruniers-en-Sologne,41,Loir-et-Cher,24,Centre-Val de Loire
41186,RAHART,41160,RAHART,,47.869943021,1.06095189274,186,,Rahart,Rahart,41,Loir-et-Cher,24,Centre-Val de Loire
41187,RENAY,41100,RENAY,,47.8392110621,1.17285657827,187,,Renay,Renay,41,Loir-et-Cher,24,Centre-Val de Loire
41188,RHODON,41290,RHODON,,47.7544339274,1.2671219497,188,,Rhodon,Rhodon,41,Loir-et-Cher,24,Centre-Val de Loire
41189,RILLY SUR LOIRE,41150,RILLY SUR LOIRE,,47.4587549693,1.14496206826,189,,Rilly-sur-Loire,Rilly-sur-Loire,41,Loir-et-Cher,24,Centre-Val de Loire
41190,ROCE,41100,ROCE,,47.8136847101,1.14871194274,190,,Rocé,Rocé,41,Loir-et-Cher,24,Centre-Val de Loire
41191,ROCHES,41370,ROCHES,,47.7943892917,1.45364994644,191,,Roches,Roches,41,Loir-et-Cher,24,Centre-Val de Loire
41192,LES ROCHES L EVEQUE,41800,LES ROCHES L EVEQUE,,47.7861085409,0.88757317848,192,Les,Roches-l'Évêque,Les Roches-l'Évêque,41,Loir-et-Cher,24,Centre-Val de Loire
41193,ROMILLY,41270,ROMILLY,,47.9513759342,1.0256628804,193,,Romilly,Romilly,41,Loir-et-Cher,24,Centre-Val de Loire
41194,ROMORANTIN LANTHENAY,41200,ROMORANTIN LANTHENAY,,47.3745226341,1.74456385409,194,,Romorantin-Lanthenay,Romorantin-Lanthenay,41,Loir-et-Cher,24,Centre-Val de Loire
41195,ROUGEOU,41230,ROUGEOU,,47.3612590702,1.54270279933,195,,Rougeou,Rougeou,41,Loir-et-Cher,24,Centre-Val de Loire
41196,RUAN SUR EGVONNE,41270,RUAN SUR EGVONNE,,48.0144840391,1.13649766481,196,,Ruan-sur-Egvonne,Ruan-sur-Egvonne,41,Loir-et-Cher,24,Centre-Val de Loire
41197,ST AGIL,41170,ST AGIL,,48.0389452705,0.934575958906,197,,Saint-Agil,Saint-Agil,41,Loir-et-Cher,24,Centre-Val de Loire
41198,ST AIGNAN,41110,ST AIGNAN,,47.2506398056,1.33425450761,198,,Saint-Aignan,Saint-Aignan,41,Loir-et-Cher,24,Centre-Val de Loire
41199,ST AMAND LONGPRE,41310,ST AMAND LONGPRE,,47.6840022904,1.00078397415,199,,Saint-Amand-Longpré,Saint-Amand-Longpré,41,Loir-et-Cher,24,Centre-Val de Loire
41200,STE ANNE,41100,STE ANNE,,47.7590221575,1.08301309316,200,,Sainte-Anne,Sainte-Anne,41,Loir-et-Cher,24,Centre-Val de Loire
41201,ST ARNOULT,41800,ST ARNOULT,,47.7074785599,0.869606203276,201,,Saint-Arnoult,Saint-Arnoult,41,Loir-et-Cher,24,Centre-Val de Loire
41202,ST AVIT,41170,ST AVIT,,48.0921152856,0.912928613276,202,,Saint-Avit,Saint-Avit,41,Loir-et-Cher,24,Centre-Val de Loire
41203,ST BOHAIRE,41330,ST BOHAIRE,,47.6438472496,1.23917912625,203,,Saint-Bohaire,Saint-Bohaire,41,Loir-et-Cher,24,Centre-Val de Loire
41204,ST CLAUDE DE DIRAY,41350,ST CLAUDE DE DIRAY,,47.6176924713,1.41960449512,204,,Saint-Claude-de-Diray,Saint-Claude-de-Diray,41,Loir-et-Cher,24,Centre-Val de Loire
41205,ST CYR DU GAULT,41190,ST CYR DU GAULT,,47.6208770485,1.02593703871,205,,Saint-Cyr-du-Gault,Saint-Cyr-du-Gault,41,Loir-et-Cher,24,Centre-Val de Loire
41206,ST DENIS SUR LOIRE,41000,ST DENIS SUR LOIRE,,47.6337713111,1.37369539893,206,,Saint-Denis-sur-Loire,Saint-Denis-sur-Loire,41,Loir-et-Cher,24,Centre-Val de Loire
41207,ST DYE SUR LOIRE,41500,ST DYE SUR LOIRE,,47.6513917962,1.49460349559,207,,Saint-Dyé-sur-Loire,Saint-Dyé-sur-Loire,41,Loir-et-Cher,24,Centre-Val de Loire
41208,ST ETIENNE DES GUERETS,41190,ST ETIENNE DES GUERETS,,47.5981445724,1.06361410844,208,,Saint-Étienne-des-Guérets,Saint-Étienne-des-Guérets,41,Loir-et-Cher,24,Centre-Val de Loire
41209,ST FIRMIN DES PRES,41100,ST FIRMIN DES PRES,,47.8445817483,1.10132047346,209,,Saint-Firmin-des-Prés,Saint-Firmin-des-Prés,41,Loir-et-Cher,24,Centre-Val de Loire
41210,STE GEMMES,41290,STE GEMMES,,47.7960525233,1.24957595436,210,,Sainte-Gemmes,Sainte-Gemmes,41,Loir-et-Cher,24,Centre-Val de Loire
41211,ST GEORGES SUR CHER,41400,ST GEORGES SUR CHER,,47.3092315675,1.13723329231,211,,Saint-Georges-sur-Cher,Saint-Georges-sur-Cher,41,Loir-et-Cher,24,Centre-Val de Loire
41212,ST GERVAIS LA FORET,41350,ST GERVAIS LA FORET,,47.5607456407,1.36138962511,212,,Saint-Gervais-la-Forêt,Saint-Gervais-la-Forêt,41,Loir-et-Cher,24,Centre-Val de Loire
41213,ST GOURGON,41310,ST GOURGON,,47.6608251122,1.0124485184,213,,Saint-Gourgon,Saint-Gourgon,41,Loir-et-Cher,24,Centre-Val de Loire
41214,ST HILAIRE LA GRAVELLE,41160,ST HILAIRE LA GRAVELLE,,47.931232261,1.18263470311,214,,Saint-Hilaire-la-Gravelle,Saint-Hilaire-la-Gravelle,41,Loir-et-Cher,24,Centre-Val de Loire
41215,ST JACQUES DES GUERETS,41800,ST JACQUES DES GUERETS,,47.766301423,0.790395766895,215,,Saint-Jacques-des-Guérets,Saint-Jacques-des-Guérets,41,Loir-et-Cher,24,Centre-Val de Loire
41216,ST JEAN FROIDMENTEL,41160,ST JEAN FROIDMENTEL,,47.9615429305,1.21005272695,216,,Saint-Jean-Froidmentel,Saint-Jean-Froidmentel,41,Loir-et-Cher,24,Centre-Val de Loire
41217,ST JULIEN DE CHEDON,41400,ST JULIEN DE CHEDON,,47.3196038461,1.20817366204,217,,Saint-Julien-de-Chédon,Saint-Julien-de-Chédon,41,Loir-et-Cher,24,Centre-Val de Loire
41218,ST JULIEN SUR CHER,41320,ST JULIEN SUR CHER,,47.266322353,1.77970795893,218,,Saint-Julien-sur-Cher,Saint-Julien-sur-Cher,41,Loir-et-Cher,24,Centre-Val de Loire
41219,ST LAURENT DES BOIS,41240,ST LAURENT DES BOIS,,47.8584857447,1.46351980095,219,,Saint-Laurent-des-Bois,Saint-Laurent-des-Bois,41,Loir-et-Cher,24,Centre-Val de Loire
41220,ST LAURENT NOUAN,41220,ST LAURENT NOUAN,,47.709174366,1.62466277515,220,,Saint-Laurent-Nouan,Saint-Laurent-Nouan,41,Loir-et-Cher,24,Centre-Val de Loire
41220,ST LAURENT NOUAN,41220,ST LAURENT NOUAN,NOUAN SUR LOIRE,47.709174366,1.62466277515,220,,Saint-Laurent-Nouan,Saint-Laurent-Nouan,41,Loir-et-Cher,24,Centre-Val de Loire
41221,ST LEONARD EN BEAUCE,41370,ST LEONARD EN BEAUCE,,47.8234026259,1.36495808434,221,,Saint-Léonard-en-Beauce,Saint-Léonard-en-Beauce,41,Loir-et-Cher,24,Centre-Val de Loire
41222,ST LOUP,41320,ST LOUP,,47.2551705649,1.82390956625,222,,Saint-Loup,Saint-Loup,41,Loir-et-Cher,24,Centre-Val de Loire
41223,ST LUBIN EN VERGONNOIS,41190,ST LUBIN EN VERGONNOIS,,47.6173686118,1.2119692197,223,,Saint-Lubin-en-Vergonnois,Saint-Lubin-en-Vergonnois,41,Loir-et-Cher,24,Centre-Val de Loire
41224,ST MARC DU COR,41170,ST MARC DU COR,,47.9680036234,0.968517516884,224,,Saint-Marc-du-Cor,Saint-Marc-du-Cor,41,Loir-et-Cher,24,Centre-Val de Loire
41225,ST MARTIN DES BOIS,41800,ST MARTIN DES BOIS,,47.7252568405,0.824501862365,225,,Saint-Martin-des-Bois,Saint-Martin-des-Bois,41,Loir-et-Cher,24,Centre-Val de Loire
41226,ST OUEN,41100,ST OUEN,,47.8279128555,1.07133932119,226,,Saint-Ouen,Saint-Ouen,41,Loir-et-Cher,24,Centre-Val de Loire
41228,ST RIMAY,41800,ST RIMAY,,47.7696926711,0.915812743496,228,,Saint-Rimay,Saint-Rimay,41,Loir-et-Cher,24,Centre-Val de Loire
41229,ST ROMAIN SUR CHER,41140,ST ROMAIN SUR CHER,,47.318457927,1.38640795013,229,,Saint-Romain-sur-Cher,Saint-Romain-sur-Cher,41,Loir-et-Cher,24,Centre-Val de Loire
41230,ST SULPICE DE POMMERAY,41000,ST SULPICE DE POMMERAY,,47.6028440111,1.26254994847,230,,Saint-Sulpice-de-Pommeray,Saint-Sulpice-de-Pommeray,41,Loir-et-Cher,24,Centre-Val de Loire
41231,ST VIATRE,41210,ST VIATRE,,47.5143980793,1.93403680491,231,,Saint-Viâtre,Saint-Viâtre,41,Loir-et-Cher,24,Centre-Val de Loire
41232,SALBRIS,41300,SALBRIS,,47.4134591452,2.04499057381,232,,Salbris,Salbris,41,Loir-et-Cher,24,Centre-Val de Loire
41233,SAMBIN,41120,SAMBIN,,47.4324580301,1.27722712969,233,,Sambin,Sambin,41,Loir-et-Cher,24,Centre-Val de Loire
41234,SANTENAY,41190,SANTENAY,,47.5654037881,1.10267700813,234,,Santenay,Santenay,41,Loir-et-Cher,24,Centre-Val de Loire
41235,SARGE SUR BRAYE,41170,SARGE SUR BRAYE,,47.9281548503,0.863555438639,235,,Sargé-sur-Braye,Sargé-sur-Braye,41,Loir-et-Cher,24,Centre-Val de Loire
41236,SASNIERES,41310,SASNIERES,,47.720161352,0.921027740309,236,,Sasnières,Sasnières,41,Loir-et-Cher,24,Centre-Val de Loire
41237,SASSAY,41700,SASSAY,,47.3923085381,1.44630857754,237,,Sassay,Sassay,41,Loir-et-Cher,24,Centre-Val de Loire
41238,SAVIGNY SUR BRAYE,41360,SAVIGNY SUR BRAYE,,47.86966042,0.831215929044,238,,Savigny-sur-Braye,Savigny-sur-Braye,41,Loir-et-Cher,24,Centre-Val de Loire
41239,SEIGY,41110,SEIGY,,47.2536329104,1.3822063371,239,,Seigy,Seigy,41,Loir-et-Cher,24,Centre-Val de Loire
41240,SEILLAC,41150,SEILLAC,,47.5491979348,1.16402336964,240,,Seillac,Seillac,41,Loir-et-Cher,24,Centre-Val de Loire
41241,SELLES ST DENIS,41300,SELLES ST DENIS,,47.3757781053,1.90655896666,241,,Selles-Saint-Denis,Selles-Saint-Denis,41,Loir-et-Cher,24,Centre-Val de Loire
41242,SELLES SUR CHER,41130,SELLES SUR CHER,,47.2776447687,1.56387082496,242,,Selles-sur-Cher,Selles-sur-Cher,41,Loir-et-Cher,24,Centre-Val de Loire
41243,SELOMMES,41100,SELOMMES,,47.7654431209,1.21145906213,243,,Selommes,Selommes,41,Loir-et-Cher,24,Centre-Val de Loire
41245,SERIS,41500,SERIS,,47.7567732237,1.50775532778,245,,Séris,Séris,41,Loir-et-Cher,24,Centre-Val de Loire
41246,SEUR,41120,SEUR,,47.5120775823,1.33780476755,246,,Seur,Seur,41,Loir-et-Cher,24,Centre-Val de Loire
41247,SOINGS EN SOLOGNE,41230,SOINGS EN SOLOGNE,,47.4114968867,1.52845454728,247,,Soings-en-Sologne,Soings-en-Sologne,41,Loir-et-Cher,24,Centre-Val de Loire
41248,SOUDAY,41170,SOUDAY,,48.0447092121,0.846594759389,248,,Couëtron-au-Perche,Couëtron-au-Perche,41,Loir-et-Cher,24,Centre-Val de Loire
41249,SOUESMES,41300,SOUESMES,,47.4571829313,2.17085302343,249,,Souesmes,Souesmes,41,Loir-et-Cher,24,Centre-Val de Loire
41250,SOUGE,41800,SOUGE,,47.7786428573,0.725882199174,250,,Sougé,Sougé,41,Loir-et-Cher,24,Centre-Val de Loire
41251,SOUVIGNY EN SOLOGNE,41600,SOUVIGNY EN SOLOGNE,,47.6491986207,2.16743831481,251,,Souvigny-en-Sologne,Souvigny-en-Sologne,41,Loir-et-Cher,24,Centre-Val de Loire
41252,SUEVRES,41500,SUEVRES,,47.6814203099,1.4544890001,252,,Suèvres,Suèvres,41,Loir-et-Cher,24,Centre-Val de Loire
41253,TALCY,41370,TALCY,,47.7683772018,1.4383898244,253,,Talcy,Talcy,41,Loir-et-Cher,24,Centre-Val de Loire
41254,LE TEMPLE,41170,LE TEMPLE,,47.9312855723,0.933806688391,254,Le,Temple,Le Temple,41,Loir-et-Cher,24,Centre-Val de Loire
41255,TERNAY,41800,TERNAY,,47.7345836701,0.771871183813,255,,Ternay,Ternay,41,Loir-et-Cher,24,Centre-Val de Loire
41256,THEILLAY,41300,THEILLAY,,47.3189665938,2.0395588923,256,,Theillay,Theillay,41,Loir-et-Cher,24,Centre-Val de Loire
41257,THENAY,41400,THENAY,,47.3940920291,1.30329198207,257,,Thenay,Thenay,41,Loir-et-Cher,24,Centre-Val de Loire
41258,THESEE,41140,THESEE,,47.324633057,1.33039153971,258,,Thésée,Thésée,41,Loir-et-Cher,24,Centre-Val de Loire
41259,THORE LA ROCHETTE,41100,THORE LA ROCHETTE,,47.7859819999,0.970946922736,259,,Thoré-la-Rochette,Thoré-la-Rochette,41,Loir-et-Cher,24,Centre-Val de Loire
41260,THOURY,41220,THOURY,,47.6144182456,1.61074783397,260,,Thoury,Thoury,41,Loir-et-Cher,24,Centre-Val de Loire
41261,TOURAILLES,41190,TOURAILLES,,47.6905723771,1.15651693665,261,,Tourailles,Tourailles,41,Loir-et-Cher,24,Centre-Val de Loire
41262,TOUR EN SOLOGNE,41250,TOUR EN SOLOGNE,,47.5471719848,1.51109071624,262,,Tour-en-Sologne,Tour-en-Sologne,41,Loir-et-Cher,24,Centre-Val de Loire
41263,TREHET,41800,TREHET,,47.7421836538,0.633907374107,263,,Tréhet,Tréhet,41,Loir-et-Cher,24,Centre-Val de Loire
41265,TROO,41800,TROO,,47.788753217,0.778673124197,265,,Troo,Troo,41,Loir-et-Cher,24,Centre-Val de Loire
41266,VALAIRE,41120,VALAIRE,,47.474463527,1.25582446624,266,,Valaire,Valaire,41,Loir-et-Cher,24,Centre-Val de Loire
41267,VALLIERES LES GRANDES,41400,VALLIERES LES GRANDES,,47.4108790791,1.14756502799,267,,Vallières-les-Grandes,Vallières-les-Grandes,41,Loir-et-Cher,24,Centre-Val de Loire
41268,VEILLEINS,41230,VEILLEINS,,47.4276616233,1.6882174614,268,,Veilleins,Veilleins,41,Loir-et-Cher,24,Centre-Val de Loire
41269,VENDOME,41100,VENDOME,,47.8013026692,1.06106057175,269,,Vendôme,Vendôme,41,Loir-et-Cher,24,Centre-Val de Loire
41271,VERNOU EN SOLOGNE,41230,VERNOU EN SOLOGNE,,47.4985467561,1.69294036106,271,,Vernou-en-Sologne,Vernou-en-Sologne,41,Loir-et-Cher,24,Centre-Val de Loire
41272,VEUVES,41150,VEUVES,,47.4770239944,1.12982260565,272,,Veuves,Veuves,41,Loir-et-Cher,24,Centre-Val de Loire
41273,VIEVY LE RAYE,41290,VIEVY LE RAYE,,47.8716770852,1.29829930078,273,,Vievy-le-Rayé,Vievy-le-Rayé,41,Loir-et-Cher,24,Centre-Val de Loire
41273,VIEVY LE RAYE,41290,VIEVY LE RAYE,ECOMAN,47.8716770852,1.29829930078,273,,Vievy-le-Rayé,Vievy-le-Rayé,41,Loir-et-Cher,24,Centre-Val de Loire
41273,VIEVY LE RAYE,41290,VIEVY LE RAYE,LA BOSSE,47.8716770852,1.29829930078,273,,Vievy-le-Rayé,Vievy-le-Rayé,41,Loir-et-Cher,24,Centre-Val de Loire
41274,VILLAVARD,41800,VILLAVARD,,47.7519622395,0.904582155249,274,,Villavard,Villavard,41,Loir-et-Cher,24,Centre-Val de Loire
41275,LA VILLE AUX CLERCS,41160,LA VILLE AUX CLERCS,,47.9188435468,1.09671366028,275,La,Ville-aux-Clercs,La Ville-aux-Clercs,41,Loir-et-Cher,24,Centre-Val de Loire
41276,VILLEBAROU,41000,VILLEBAROU,,47.6266800111,1.3190312927,276,,Villebarou,Villebarou,41,Loir-et-Cher,24,Centre-Val de Loire
41277,VILLEBOUT,41270,VILLEBOUT,,47.9872618416,1.17317838683,277,,Villebout,Villebout,41,Loir-et-Cher,24,Centre-Val de Loire
41278,VILLECHAUVE,41310,VILLECHAUVE,,47.6438531799,0.949822221437,278,,Villechauve,Villechauve,41,Loir-et-Cher,24,Centre-Val de Loire
41279,VILLEDIEU LE CHATEAU,41800,VILLEDIEU LE CHATEAU,,47.7145584824,0.640231512213,279,,Villedieu-le-Château,Villedieu-le-Château,41,Loir-et-Cher,24,Centre-Val de Loire
41280,VILLEFRANCHE SUR CHER,41200,VILLEFRANCHE SUR CHER,,47.3061059173,1.75364567539,280,,Villefranche-sur-Cher,Villefranche-sur-Cher,41,Loir-et-Cher,24,Centre-Val de Loire
41281,VILLEFRANCOEUR,41330,VILLEFRANCOEUR,,47.696504262,1.20343836438,281,,Villefrancœur,Villefrancœur,41,Loir-et-Cher,24,Centre-Val de Loire
41282,VILLEHERVIERS,41200,VILLEHERVIERS,,47.3599602222,1.81188563537,282,,Villeherviers,Villeherviers,41,Loir-et-Cher,24,Centre-Val de Loire
41283,VILLEMARDY,41100,VILLEMARDY,,47.7277491747,1.1866948747,283,,Villemardy,Villemardy,41,Loir-et-Cher,24,Centre-Val de Loire
41284,VILLENEUVE FROUVILLE,41290,VILLENEUVE FROUVILLE,,47.7852258019,1.3172421296,284,,Villeneuve-Frouville,Villeneuve-Frouville,41,Loir-et-Cher,24,Centre-Val de Loire
41285,VILLENY,41220,VILLENY,,47.6229791934,1.75261229637,285,,Villeny,Villeny,41,Loir-et-Cher,24,Centre-Val de Loire
41286,VILLEPORCHER,41310,VILLEPORCHER,,47.6445356816,0.986385518358,286,,Villeporcher,Villeporcher,41,Loir-et-Cher,24,Centre-Val de Loire
41287,VILLERABLE,41100,VILLERABLE,,47.7565335742,1.04015224879,287,,Villerable,Villerable,41,Loir-et-Cher,24,Centre-Val de Loire
41288,VILLERBON,41000,VILLERBON,,47.664005074,1.35678076769,288,,Villerbon,Villerbon,41,Loir-et-Cher,24,Centre-Val de Loire
41289,VILLERMAIN,41240,VILLERMAIN,,47.8593158315,1.53203800155,289,,Villermain,Villermain,41,Loir-et-Cher,24,Centre-Val de Loire
41290,VILLEROMAIN,41100,VILLEROMAIN,,47.7179483833,1.14691831404,290,,Villeromain,Villeromain,41,Loir-et-Cher,24,Centre-Val de Loire
41291,VILLETRUN,41100,VILLETRUN,,47.7867092973,1.16554441978,291,,Villetrun,Villetrun,41,Loir-et-Cher,24,Centre-Val de Loire
41292,VILLEXANTON,41500,VILLEXANTON,,47.7415309033,1.44574467546,292,,Villexanton,Villexanton,41,Loir-et-Cher,24,Centre-Val de Loire
41293,VILLIERSFAUX,41100,VILLIERSFAUX,,47.7456821504,0.981092856048,293,,Villiersfaux,Villiersfaux,41,Loir-et-Cher,24,Centre-Val de Loire
41294,VILLIERS SUR LOIR,41100,VILLIERS SUR LOIR,,47.8095517796,0.997400046062,294,,Villiers-sur-Loir,Villiers-sur-Loir,41,Loir-et-Cher,24,Centre-Val de Loire
41295,VINEUIL,41350,VINEUIL,,47.5862925993,1.38832136173,295,,Vineuil,Vineuil,41,Loir-et-Cher,24,Centre-Val de Loire
41296,VOUZON,41600,VOUZON,,47.6431910467,2.04960919519,296,,Vouzon,Vouzon,41,Loir-et-Cher,24,Centre-Val de Loire
41297,YVOY LE MARRON,41600,YVOY LE MARRON,,47.6366514233,1.84803202811,297,,Yvoy-le-Marron,Yvoy-le-Marron,41,Loir-et-Cher,24,Centre-Val de Loire
42001,ABOEN,42380,ABOEN,,45.412713546,4.12725438172,1,,Aboën,Aboën,42,Loire,84,Auvergne-Rhône-Alpes
42002,AILLEUX,42130,AILLEUX,,45.7991604687,3.93365168543,2,,Ailleux,Ailleux,42,Loire,84,Auvergne-Rhône-Alpes
42003,AMBIERLE,42820,AMBIERLE,,46.1059597472,3.89158878833,3,,Ambierle,Ambierle,42,Loire,84,Auvergne-Rhône-Alpes
42004,AMIONS,42260,AMIONS,,45.8770079561,4.04077213225,4,,Amions,Amions,42,Loire,84,Auvergne-Rhône-Alpes
42005,ANDREZIEUX BOUTHEON,42160,ANDREZIEUX BOUTHEON,,45.5349913099,4.27650426979,5,,Andrézieux-Bouthéon,Andrézieux-Bouthéon,42,Loire,84,Auvergne-Rhône-Alpes
42006,APINAC,42550,APINAC,,45.3913756385,3.99457780325,6,,Apinac,Apinac,42,Loire,84,Auvergne-Rhône-Alpes
42007,ARCINGES,42460,ARCINGES,,46.1346681524,4.28747149673,7,,Arcinges,Arcinges,42,Loire,84,Auvergne-Rhône-Alpes
42008,ARCON,42370,ARCON,,46.0086270746,3.86961031415,8,,Arcon,Arcon,42,Loire,84,Auvergne-Rhône-Alpes
42009,ARTHUN,42130,ARTHUN,,45.7685617882,4.03822937233,9,,Arthun,Arthun,42,Loire,84,Auvergne-Rhône-Alpes
42010,AVEIZIEUX,42330,AVEIZIEUX,,45.5692764354,4.37903111201,10,,Aveizieux,Aveizieux,42,Loire,84,Auvergne-Rhône-Alpes
42011,BALBIGNY,42510,BALBIGNY,,45.8294764785,4.18332533699,11,,Balbigny,Balbigny,42,Loire,84,Auvergne-Rhône-Alpes
42012,BARD,42600,BARD,,45.5922264726,3.99761908102,12,,Bard,Bard,42,Loire,84,Auvergne-Rhône-Alpes
42013,BELLEGARDE EN FOREZ,42210,BELLEGARDE EN FOREZ,,45.6408137826,4.30888510025,13,,Bellegarde-en-Forez,Bellegarde-en-Forez,42,Loire,84,Auvergne-Rhône-Alpes
42014,BELLEROCHE,42670,BELLEROCHE,,46.1603955892,4.40905088553,14,,Belleroche,Belleroche,42,Loire,84,Auvergne-Rhône-Alpes
42015,BELMONT DE LA LOIRE,42670,BELMONT DE LA LOIRE,,46.1622604253,4.35026085349,15,,Belmont-de-la-Loire,Belmont-de-la-Loire,42,Loire,84,Auvergne-Rhône-Alpes
42016,LA BENISSON DIEU,42720,LA BENISSON DIEU,,46.1590458728,4.04769279694,16,La,Bénisson-Dieu,La Bénisson-Dieu,42,Loire,84,Auvergne-Rhône-Alpes
42017,LE BESSAT,42660,LE BESSAT,,45.3743662773,4.51511479228,17,Le,Bessat,Le Bessat,42,Loire,84,Auvergne-Rhône-Alpes
42018,BESSEY,42520,BESSEY,,45.3909624319,4.70262242235,18,,Bessey,Bessey,42,Loire,84,Auvergne-Rhône-Alpes
42019,BOEN SUR LIGNON,42130,BOEN SUR LIGNON,,45.7506601362,4.00890511945,19,,Boën-sur-Lignon,Boën-sur-Lignon,42,Loire,84,Auvergne-Rhône-Alpes
42020,BOISSET LES MONTROND,42210,BOISSET LES MONTROND,,45.6250619213,4.2076229179,20,,Boisset-lès-Montrond,Boisset-lès-Montrond,42,Loire,84,Auvergne-Rhône-Alpes
42021,BOISSET ST PRIEST,42560,BOISSET ST PRIEST,,45.5129034516,4.1187324401,21,,Boisset-Saint-Priest,Boisset-Saint-Priest,42,Loire,84,Auvergne-Rhône-Alpes
42022,BONSON,42160,BONSON,,45.5220857407,4.22151504235,22,,Bonson,Bonson,42,Loire,84,Auvergne-Rhône-Alpes
42023,BOURG ARGENTAL,42220,BOURG ARGENTAL,,45.3037339216,4.56653101213,23,,Bourg-Argental,Bourg-Argental,42,Loire,84,Auvergne-Rhône-Alpes
42025,BOYER,42460,BOYER,,46.0971669978,4.21483763027,25,,Boyer,Boyer,42,Loire,84,Auvergne-Rhône-Alpes
42026,BRIENNON,42720,BRIENNON,,46.1473425939,4.07729342349,26,,Briennon,Briennon,42,Loire,84,Auvergne-Rhône-Alpes
42027,BULLY,42260,BULLY,,45.9292601554,3.99269700935,27,,Bully,Bully,42,Loire,84,Auvergne-Rhône-Alpes
42028,BURDIGNES,42220,BURDIGNES,,45.2639294858,4.55951022656,28,,Burdignes,Burdignes,42,Loire,84,Auvergne-Rhône-Alpes
42029,BUSSIERES,42510,BUSSIERES,,45.8395855736,4.26450714832,29,,Bussières,Bussières,42,Loire,84,Auvergne-Rhône-Alpes
42030,BUSSY ALBIEUX,42260,BUSSY ALBIEUX,,45.7952908968,4.0351714589,30,,Bussy-Albieux,Bussy-Albieux,42,Loire,84,Auvergne-Rhône-Alpes
42031,CALOIRE,42240,CALOIRE,,45.4145267518,4.2349238858,31,,Caloire,Caloire,42,Loire,84,Auvergne-Rhône-Alpes
42032,CELLIEU,42320,CELLIEU,,45.5213671549,4.53017673396,32,,Cellieu,Cellieu,42,Loire,84,Auvergne-Rhône-Alpes
42033,LE CERGNE,42460,LE CERGNE,,46.1207669259,4.30390743723,33,Le,Cergne,Le Cergne,42,Loire,84,Auvergne-Rhône-Alpes
42034,CERVIERES,42440,CERVIERES,,45.8524253224,3.75375770217,34,,Cervières,Cervières,42,Loire,84,Auvergne-Rhône-Alpes
42035,CEZAY,42130,CEZAY,,45.8025245236,3.96521124284,35,,Cezay,Cezay,42,Loire,84,Auvergne-Rhône-Alpes
42036,CHAGNON,42800,CHAGNON,,45.5337555804,4.55361256767,36,,Chagnon,Chagnon,42,Loire,84,Auvergne-Rhône-Alpes
42037,CHALAIN D UZORE,42600,CHALAIN D UZORE,,45.6726268892,4.06032311486,37,,Chalain-d'Uzore,Chalain-d'Uzore,42,Loire,84,Auvergne-Rhône-Alpes
42038,CHALAIN LE COMTAL,42600,CHALAIN LE COMTAL,,45.6411269141,4.17221132927,38,,Chalain-le-Comtal,Chalain-le-Comtal,42,Loire,84,Auvergne-Rhône-Alpes
42039,CHALMAZEL JEANSAGNIERE,42920,CHALMAZEL JEANSAGNIERE,,45.6980828693,3.82450309755,39,,Chalmazel-Jeansagnière,Chalmazel-Jeansagnière,42,Loire,84,Auvergne-Rhône-Alpes
42039,CHALMAZEL JEANSAGNIERE,42920,CHALMAZEL JEANSAGNIERE,JEANSAGNIERE,45.6980828693,3.82450309755,39,,Chalmazel-Jeansagnière,Chalmazel-Jeansagnière,42,Loire,84,Auvergne-Rhône-Alpes
42040,LA CHAMBA,42440,LA CHAMBA,,45.7553340997,3.76221120048,40,La,Chamba,La Chamba,42,Loire,84,Auvergne-Rhône-Alpes
42041,CHAMBEON,42110,CHAMBEON,,45.6971949141,4.17325766885,41,,Chambéon,Chambéon,42,Loire,84,Auvergne-Rhône-Alpes
42042,CHAMBLES,42170,CHAMBLES,,45.4520672053,4.22612997142,42,,Chambles,Chambles,42,Loire,84,Auvergne-Rhône-Alpes
42043,CHAMBOEUF,42330,CHAMBOEUF,,45.5743049771,4.32163705727,43,,Chambœuf,Chambœuf,42,Loire,84,Auvergne-Rhône-Alpes
42044,LE CHAMBON FEUGEROLLES,42500,LE CHAMBON FEUGEROLLES,,45.3886064564,4.33196255695,44,Le,Chambon-Feugerolles,Le Chambon-Feugerolles,42,Loire,84,Auvergne-Rhône-Alpes
42045,LA CHAMBONIE,42440,LA CHAMBONIE,,45.7435606657,3.7579660525,45,La,Chambonie,La Chambonie,42,Loire,84,Auvergne-Rhône-Alpes
42046,CHAMPDIEU,42600,CHAMPDIEU,,45.6413921035,4.0502490993,46,,Champdieu,Champdieu,42,Loire,84,Auvergne-Rhône-Alpes
42047,CHAMPOLY,42430,CHAMPOLY,,45.8535201477,3.84402489217,47,,Champoly,Champoly,42,Loire,84,Auvergne-Rhône-Alpes
42048,CHANDON,42190,CHANDON,,46.1467528549,4.20223388149,48,,Chandon,Chandon,42,Loire,84,Auvergne-Rhône-Alpes
42049,CHANGY,42310,CHANGY,,46.1458600113,3.89966724243,49,,Changy,Changy,42,Loire,84,Auvergne-Rhône-Alpes
42050,LA CHAPELLE EN LAFAYE,42380,LA CHAPELLE EN LAFAYE,,45.4593011559,3.98511801378,50,La,Chapelle-en-Lafaye,La Chapelle-en-Lafaye,42,Loire,84,Auvergne-Rhône-Alpes
42051,LA CHAPELLE VILLARS,42410,LA CHAPELLE VILLARS,,45.4780450334,4.7142051612,51,La,Chapelle-Villars,La Chapelle-Villars,42,Loire,84,Auvergne-Rhône-Alpes
42052,CHARLIEU,42190,CHARLIEU,,46.166320434,4.17407848439,52,,Charlieu,Charlieu,42,Loire,84,Auvergne-Rhône-Alpes
42053,CHATEAUNEUF,42800,CHATEAUNEUF,,45.5146610622,4.63386392848,53,,Châteauneuf,Châteauneuf,42,Loire,84,Auvergne-Rhône-Alpes
42054,CHATELNEUF,42940,CHATELNEUF,,45.6388781024,3.97962390788,54,,Châtelneuf,Châtelneuf,42,Loire,84,Auvergne-Rhône-Alpes
42055,CHATELUS,42140,CHATELUS,,45.5897631201,4.46215474469,55,,Châtelus,Châtelus,42,Loire,84,Auvergne-Rhône-Alpes
42056,CHAVANAY,42410,CHAVANAY,,45.4187757598,4.7287178869,56,,Chavanay,Chavanay,42,Loire,84,Auvergne-Rhône-Alpes
42058,CHAZELLES SUR LAVIEU,42560,CHAZELLES SUR LAVIEU,,45.5423204003,4.00189316326,58,,Chazelles-sur-Lavieu,Chazelles-sur-Lavieu,42,Loire,84,Auvergne-Rhône-Alpes
42059,CHAZELLES SUR LYON,42140,CHAZELLES SUR LYON,,45.6292859414,4.37386809116,59,,Chazelles-sur-Lyon,Chazelles-sur-Lyon,42,Loire,84,Auvergne-Rhône-Alpes
42060,CHENEREILLES,42560,CHENEREILLES,,45.4862625628,4.08984378023,60,,Chenereilles,Chenereilles,42,Loire,84,Auvergne-Rhône-Alpes
42061,CHERIER,42430,CHERIER,,45.9681660896,3.88935883166,61,,Cherier,Cherier,42,Loire,84,Auvergne-Rhône-Alpes
42062,CHEVRIERES,42140,CHEVRIERES,,45.5903538558,4.40832402261,62,,Chevrières,Chevrières,42,Loire,84,Auvergne-Rhône-Alpes
42063,CHIRASSIMONT,42114,CHIRASSIMONT,,45.9134499537,4.28281506917,63,,Chirassimont,Chirassimont,42,Loire,84,Auvergne-Rhône-Alpes
42064,CHUYER,42410,CHUYER,,45.4555253092,4.70245486643,64,,Chuyer,Chuyer,42,Loire,84,Auvergne-Rhône-Alpes
42065,CIVENS,42110,CIVENS,,45.7678114571,4.23353082374,65,,Civens,Civens,42,Loire,84,Auvergne-Rhône-Alpes
42066,CLEPPE,42110,CLEPPE,,45.760405726,4.17661128061,66,,Cleppé,Cleppé,42,Loire,84,Auvergne-Rhône-Alpes
42067,COLOMBIER,42220,COLOMBIER,,45.3540411435,4.59395914999,67,,Colombier,Colombier,42,Loire,84,Auvergne-Rhône-Alpes
42068,COMBRE,42840,COMBRE,,46.0218651012,4.2635295,68,,Combre,Combre,42,Loire,84,Auvergne-Rhône-Alpes
42069,COMMELLE VERNAY,42120,COMMELLE VERNAY,,45.994800702,4.06003885957,69,,Commelle-Vernay,Commelle-Vernay,42,Loire,84,Auvergne-Rhône-Alpes
42070,CORDELLE,42123,CORDELLE,,45.9426002325,4.05941352074,70,,Cordelle,Cordelle,42,Loire,84,Auvergne-Rhône-Alpes
42071,LE COTEAU,42120,LE COTEAU,,46.0172302823,4.09095787437,71,Le,Coteau,Le Coteau,42,Loire,84,Auvergne-Rhône-Alpes
42072,LA COTE EN COUZAN,42111,LA COTE EN COUZAN,,45.7709475758,3.82885508011,72,La,Côte-en-Couzan,La Côte-en-Couzan,42,Loire,84,Auvergne-Rhône-Alpes
42073,COTTANCE,42360,COTTANCE,,45.794436641,4.29628802664,73,,Cottance,Cottance,42,Loire,84,Auvergne-Rhône-Alpes
42074,COUTOUVRE,42460,COUTOUVRE,,46.0732580486,4.20593657124,74,,Coutouvre,Coutouvre,42,Loire,84,Auvergne-Rhône-Alpes
42075,CRAINTILLEUX,42210,CRAINTILLEUX,,45.57428192,4.22555188945,75,,Craintilleux,Craintilleux,42,Loire,84,Auvergne-Rhône-Alpes
42076,CREMEAUX,42260,CREMEAUX,,45.9152976183,3.92450322981,76,,Cremeaux,Cremeaux,42,Loire,84,Auvergne-Rhône-Alpes
42077,CROIZET SUR GAND,42540,CROIZET SUR GAND,,45.9126247194,4.21879856118,77,,Croizet-sur-Gand,Croizet-sur-Gand,42,Loire,84,Auvergne-Rhône-Alpes
42078,LE CROZET,42310,LE CROZET,,46.1733609918,3.83820754323,78,Le,Crozet,Le Crozet,42,Loire,84,Auvergne-Rhône-Alpes
42079,CUINZIER,42460,CUINZIER,,46.1241485363,4.26553210603,79,,Cuinzier,Cuinzier,42,Loire,84,Auvergne-Rhône-Alpes
42081,CUZIEU,42330,CUZIEU,,45.6062985124,4.26545207151,81,,Cuzieu,Cuzieu,42,Loire,84,Auvergne-Rhône-Alpes
42082,DANCE,42260,DANCE,,45.9072579151,4.03433219646,82,,Dancé,Dancé,42,Loire,84,Auvergne-Rhône-Alpes
42083,DARGOIRE,42800,DARGOIRE,,45.5627238803,4.67407383096,83,,Dargoire,Dargoire,42,Loire,84,Auvergne-Rhône-Alpes
42084,DEBATS RIVIERE D ORPRA,42130,DEBATS RIVIERE D ORPRA,,45.7576644008,3.9420526909,84,,Débats-Rivière-d'Orpra,Débats-Rivière-d'Orpra,42,Loire,84,Auvergne-Rhône-Alpes
42085,DOIZIEUX,42740,DOIZIEUX,,45.4182832028,4.58919336955,85,,Doizieux,Doizieux,42,Loire,84,Auvergne-Rhône-Alpes
42086,ECOCHE,42670,ECOCHE,,46.1511332102,4.30522495875,86,,Écoche,Écoche,42,Loire,84,Auvergne-Rhône-Alpes
42087,ECOTAY L OLME,42600,ECOTAY L OLME,,45.5867257895,4.04549513063,87,,Écotay-l'Olme,Écotay-l'Olme,42,Loire,84,Auvergne-Rhône-Alpes
42088,EPERCIEUX ST PAUL,42110,EPERCIEUX ST PAUL,,45.7905917572,4.20144222446,88,,Épercieux-Saint-Paul,Épercieux-Saint-Paul,42,Loire,84,Auvergne-Rhône-Alpes
42089,ESSERTINES EN CHATELNEUF,42600,ESSERTINES EN CHATELNEUF,,45.6183532974,3.99924034774,89,,Essertines-en-Châtelneuf,Essertines-en-Châtelneuf,42,Loire,84,Auvergne-Rhône-Alpes
42090,ESSERTINES EN DONZY,42360,ESSERTINES EN DONZY,,45.7576341389,4.33837762975,90,,Essertines-en-Donzy,Essertines-en-Donzy,42,Loire,84,Auvergne-Rhône-Alpes
42091,ESTIVAREILLES,42380,ESTIVAREILLES,,45.4305784876,4.01661876407,91,,Estivareilles,Estivareilles,42,Loire,84,Auvergne-Rhône-Alpes
42092,L ETRAT,42580,L ETRAT,,45.4943470893,4.37157976104,92,L',Étrat,L'Étrat,42,Loire,84,Auvergne-Rhône-Alpes
42093,FARNAY,42320,FARNAY,,45.4850828855,4.60234719042,93,,Farnay,Farnay,42,Loire,84,Auvergne-Rhône-Alpes
42094,FEURS,42110,FEURS,,45.7323040855,4.22324153789,94,,Feurs,Feurs,42,Loire,84,Auvergne-Rhône-Alpes
42095,FIRMINY,42700,FIRMINY,,45.3788146093,4.28879456136,95,,Firminy,Firminy,42,Loire,84,Auvergne-Rhône-Alpes
42096,FONTANES,42140,FONTANES,,45.5421701244,4.43125355325,96,,Fontanès,Fontanès,42,Loire,84,Auvergne-Rhône-Alpes
42097,LA FOUILLOUSE,42480,LA FOUILLOUSE,,45.5010028075,4.31920755114,97,La,Fouillouse,La Fouillouse,42,Loire,84,Auvergne-Rhône-Alpes
42098,FOURNEAUX,42470,FOURNEAUX,,45.9427445306,4.27204000195,98,,Fourneaux,Fourneaux,42,Loire,84,Auvergne-Rhône-Alpes
42099,FRAISSES,42490,FRAISSES,,45.3843710509,4.26046841789,99,,Fraisses,Fraisses,42,Loire,84,Auvergne-Rhône-Alpes
42100,LA GIMOND,42140,LA GIMOND,,45.5562348005,4.4140487377,100,La,Gimond,La Gimond,42,Loire,84,Auvergne-Rhône-Alpes
42101,GRAIX,42220,GRAIX,,45.3603739622,4.56046533126,101,,Graix,Graix,42,Loire,84,Auvergne-Rhône-Alpes
42102,GRAMMOND,42140,GRAMMOND,,45.5739004046,4.44195002454,102,,Grammond,Grammond,42,Loire,84,Auvergne-Rhône-Alpes
42103,LA GRAND CROIX,42320,LA GRAND CROIX,,45.5040795864,4.55860381813,103,La,Grand-Croix,La Grand-Croix,42,Loire,84,Auvergne-Rhône-Alpes
42104,LA GRESLE,42460,LA GRESLE,,46.0720149444,4.2760425142,104,La,Gresle,La Gresle,42,Loire,84,Auvergne-Rhône-Alpes
42105,GREZIEUX LE FROMENTAL,42600,GREZIEUX LE FROMENTAL,,45.6147736513,4.15456122619,105,,Grézieux-le-Fromental,Grézieux-le-Fromental,42,Loire,84,Auvergne-Rhône-Alpes
42106,GREZOLLES,42260,GREZOLLES,,45.8639049425,3.94846074499,106,,Grézolles,Grézolles,42,Loire,84,Auvergne-Rhône-Alpes
42107,GUMIERES,42560,GUMIERES,,45.5273238675,3.97708738007,107,,Gumières,Gumières,42,Loire,84,Auvergne-Rhône-Alpes
42108,L HOPITAL LE GRAND,42210,L HOPITAL LE GRAND,,45.5919035351,4.19660173459,108,L',Hôpital-le-Grand,L'Hôpital-le-Grand,42,Loire,84,Auvergne-Rhône-Alpes
42109,L HOPITAL SOUS ROCHEFORT,42130,L HOPITAL SOUS ROCHEFORT,,45.772221105,3.93582231796,109,L',Hôpital-sous-Rochefort,L'Hôpital-sous-Rochefort,42,Loire,84,Auvergne-Rhône-Alpes
42110,L HORME,42152,L HORME,,45.4920272407,4.54055415513,110,L',Horme,L'Horme,42,Loire,84,Auvergne-Rhône-Alpes
42112,JARNOSSE,42460,JARNOSSE,,46.100691759,4.24588298664,112,,Jarnosse,Jarnosse,42,Loire,84,Auvergne-Rhône-Alpes
42113,JAS,42110,JAS,,45.7433214178,4.31929738485,113,,Jas,Jas,42,Loire,84,Auvergne-Rhône-Alpes
42115,JONZIEUX,42660,JONZIEUX,,45.3199228804,4.36218674695,115,,Jonzieux,Jonzieux,42,Loire,84,Auvergne-Rhône-Alpes
42116,JURE,42430,JURE,,45.8915714272,3.89406445527,116,,Juré,Juré,42,Loire,84,Auvergne-Rhône-Alpes
42117,LAVIEU,42560,LAVIEU,,45.5403767442,4.04199917394,117,,Lavieu,Lavieu,42,Loire,84,Auvergne-Rhône-Alpes
42118,LAY,42470,LAY,,45.9686920698,4.24074411631,118,,Lay,Lay,42,Loire,84,Auvergne-Rhône-Alpes
42119,LEIGNEUX,42130,LEIGNEUX,,45.7434903751,3.98404823618,119,,Leigneux,Leigneux,42,Loire,84,Auvergne-Rhône-Alpes
42120,LENTIGNY,42155,LENTIGNY,,45.9921445608,3.97528031163,120,,Lentigny,Lentigny,42,Loire,84,Auvergne-Rhône-Alpes
42121,LERIGNEUX,42600,LERIGNEUX,,45.5945077198,3.94680301947,121,,Lérigneux,Lérigneux,42,Loire,84,Auvergne-Rhône-Alpes
42122,LEZIGNEUX,42600,LEZIGNEUX,,45.5599481407,4.05663327551,122,,Lézigneux,Lézigneux,42,Loire,84,Auvergne-Rhône-Alpes
42123,LORETTE,42420,LORETTE,,45.5111870937,4.58123296493,123,,Lorette,Lorette,42,Loire,84,Auvergne-Rhône-Alpes
42124,LUPE,42520,LUPE,,45.3732296224,4.70055587706,124,,Lupé,Lupé,42,Loire,84,Auvergne-Rhône-Alpes
42125,LURE,42260,LURE,,45.88323165,3.92941983729,125,,Luré,Luré,42,Loire,84,Auvergne-Rhône-Alpes
42126,LURIECQ,42380,LURIECQ,,45.4513938679,4.07349582691,126,,Luriecq,Luriecq,42,Loire,84,Auvergne-Rhône-Alpes
42127,MABLY,42300,MABLY,,46.0919456873,4.0633211833,127,,Mably,Mably,42,Loire,84,Auvergne-Rhône-Alpes
42127,MABLY,42300,MABLY,LES TUILERIES,46.0919456873,4.0633211833,127,,Mably,Mably,42,Loire,84,Auvergne-Rhône-Alpes
42128,MACHEZAL,42114,MACHEZAL,,45.925872477,4.31533789059,128,,Machézal,Machézal,42,Loire,84,Auvergne-Rhône-Alpes
42129,MACLAS,42520,MACLAS,,45.3609273148,4.6976820732,129,,Maclas,Maclas,42,Loire,84,Auvergne-Rhône-Alpes
42130,MAGNEUX HAUTE RIVE,42600,MAGNEUX HAUTE RIVE,,45.6697850452,4.17067905601,130,,Magneux-Haute-Rive,Magneux-Haute-Rive,42,Loire,84,Auvergne-Rhône-Alpes
42131,MAIZILLY,42750,MAIZILLY,,46.1758631519,4.24319004515,131,,Maizilly,Maizilly,42,Loire,84,Auvergne-Rhône-Alpes
42132,MALLEVAL,42520,MALLEVAL,,45.3854577768,4.72587258984,132,,Malleval,Malleval,42,Loire,84,Auvergne-Rhône-Alpes
42133,MARCENOD,42140,MARCENOD,,45.5728199532,4.48177097511,133,,Marcenod,Marcenod,42,Loire,84,Auvergne-Rhône-Alpes
42134,MARCILLY LE CHATEL,42130,MARCILLY LE CHATEL,,45.6888989207,4.02877328272,134,,Marcilly-le-Châtel,Marcilly-le-Châtel,42,Loire,84,Auvergne-Rhône-Alpes
42135,MARCLOPT,42210,MARCLOPT,,45.6639852846,4.22116537367,135,,Marclopt,Marclopt,42,Loire,84,Auvergne-Rhône-Alpes
42136,MARCOUX,42130,MARCOUX,,45.7040618289,3.99795142783,136,,Marcoux,Marcoux,42,Loire,84,Auvergne-Rhône-Alpes
42137,MARGERIE CHANTAGRET,42560,MARGERIE CHANTAGRET,,45.5249803676,4.05302501039,137,,Margerie-Chantagret,Margerie-Chantagret,42,Loire,84,Auvergne-Rhône-Alpes
42138,MARINGES,42140,MARINGES,,45.660883929,4.35369654471,138,,Maringes,Maringes,42,Loire,84,Auvergne-Rhône-Alpes
42139,MARLHES,42660,MARLHES,,45.2930506564,4.39678475293,139,,Marlhes,Marlhes,42,Loire,84,Auvergne-Rhône-Alpes
42140,MAROLS,42560,MAROLS,,45.4747627904,4.03920281904,140,,Marols,Marols,42,Loire,84,Auvergne-Rhône-Alpes
42141,MARS,42750,MARS,,46.1494620298,4.2514137726,141,,Mars,Mars,42,Loire,84,Auvergne-Rhône-Alpes
42142,MERLE LEIGNEC,42380,MERLE LEIGNEC,,45.3740740437,4.02451160759,142,,Merle-Leignec,Merle-Leignec,42,Loire,84,Auvergne-Rhône-Alpes
42143,MIZERIEUX,42110,MIZERIEUX,,45.790715516,4.16562342856,143,,Mizérieux,Mizérieux,42,Loire,84,Auvergne-Rhône-Alpes
42145,MONTAGNY,42840,MONTAGNY,,46.032936055,4.22546405807,145,,Montagny,Montagny,42,Loire,84,Auvergne-Rhône-Alpes
42146,MONTARCHER,42380,MONTARCHER,,45.458211291,3.99028156923,146,,Montarcher,Montarcher,42,Loire,84,Auvergne-Rhône-Alpes
42147,MONTBRISON,42600,MONTBRISON,,45.6008365515,4.0713982073,147,,Montbrison,Montbrison,42,Loire,84,Auvergne-Rhône-Alpes
42147,MONTBRISON,42600,MONTBRISON,MOINGT,45.6008365515,4.0713982073,147,,Montbrison,Montbrison,42,Loire,84,Auvergne-Rhône-Alpes
42148,MONTCHAL,42360,MONTCHAL,,45.8240674151,4.32824050303,148,,Montchal,Montchal,42,Loire,84,Auvergne-Rhône-Alpes
42149,MONTROND LES BAINS,42210,MONTROND LES BAINS,,45.6352746305,4.24093173504,149,,Montrond-les-Bains,Montrond-les-Bains,42,Loire,84,Auvergne-Rhône-Alpes
42150,MONTVERDUN,42130,MONTVERDUN,,45.712457493,4.0770159438,150,,Montverdun,Montverdun,42,Loire,84,Auvergne-Rhône-Alpes
42151,MORNAND EN FOREZ,42600,MORNAND EN FOREZ,,45.6717929888,4.11953674559,151,,Mornand-en-Forez,Mornand-en-Forez,42,Loire,84,Auvergne-Rhône-Alpes
42152,NANDAX,42720,NANDAX,,46.0988370504,4.1699750449,152,,Nandax,Nandax,42,Loire,84,Auvergne-Rhône-Alpes
42153,NEAUX,42470,NEAUX,,45.9582864228,4.16729809028,153,,Neaux,Neaux,42,Loire,84,Auvergne-Rhône-Alpes
42154,NERONDE,42510,NERONDE,,45.8360580562,4.23058831333,154,,Néronde,Néronde,42,Loire,84,Auvergne-Rhône-Alpes
42155,NERVIEUX,42510,NERVIEUX,,45.8118000128,4.14151199652,155,,Nervieux,Nervieux,42,Loire,84,Auvergne-Rhône-Alpes
42156,NEULISE,42590,NEULISE,,45.900100869,4.16834898085,156,,Neulise,Neulise,42,Loire,84,Auvergne-Rhône-Alpes
42157,NOAILLY,42640,NOAILLY,,46.1414386815,4.00417536538,157,,Noailly,Noailly,42,Loire,84,Auvergne-Rhône-Alpes
42158,LES NOES,42370,LES NOES,,46.0347302948,3.83628210331,158,Les,Noës,Les Noës,42,Loire,84,Auvergne-Rhône-Alpes
42159,NOIRETABLE,42440,NOIRETABLE,,45.805483325,3.7440778928,159,,Noirétable,Noirétable,42,Loire,84,Auvergne-Rhône-Alpes
42160,NOLLIEUX,42260,NOLLIEUX,,45.8183058614,3.98993321679,160,,Nollieux,Nollieux,42,Loire,84,Auvergne-Rhône-Alpes
42161,NOTRE DAME DE BOISSET,42120,NOTRE DAME DE BOISSET,,45.9892923941,4.13471430995,161,,Notre-Dame-de-Boisset,Notre-Dame-de-Boisset,42,Loire,84,Auvergne-Rhône-Alpes
42162,OUCHES,42155,OUCHES,,46.0133225099,3.9959200777,162,,Ouches,Ouches,42,Loire,84,Auvergne-Rhône-Alpes
42163,LA PACAUDIERE,42310,LA PACAUDIERE,,46.1848662423,3.88005761466,163,La,Pacaudière,La Pacaudière,42,Loire,84,Auvergne-Rhône-Alpes
42164,PALOGNEUX,42990,PALOGNEUX,,45.7431305633,3.92569530158,164,,Palogneux,Palogneux,42,Loire,84,Auvergne-Rhône-Alpes
42165,PANISSIERES,42360,PANISSIERES,,45.7937087884,4.34408742272,165,,Panissières,Panissières,42,Loire,84,Auvergne-Rhône-Alpes
42166,PARIGNY,42120,PARIGNY,,45.9876941251,4.09212986006,166,,Parigny,Parigny,42,Loire,84,Auvergne-Rhône-Alpes
42167,PAVEZIN,42410,PAVEZIN,,45.4650232925,4.66054627244,167,,Pavezin,Pavezin,42,Loire,84,Auvergne-Rhône-Alpes
42168,PELUSSIN,42410,PELUSSIN,,45.4230806352,4.65991656851,168,,Pélussin,Pélussin,42,Loire,84,Auvergne-Rhône-Alpes
42169,PERIGNEUX,42380,PERIGNEUX,,45.4473694806,4.14260046979,169,,Périgneux,Périgneux,42,Loire,84,Auvergne-Rhône-Alpes
42170,PERREUX,42120,PERREUX,,46.0404897361,4.14491926088,170,,Perreux,Perreux,42,Loire,84,Auvergne-Rhône-Alpes
42171,PINAY,42590,PINAY,,45.8713622247,4.13461976155,171,,Pinay,Pinay,42,Loire,84,Auvergne-Rhône-Alpes
42172,PLANFOY,42660,PLANFOY,,45.3861155626,4.431442219,172,,Planfoy,Planfoy,42,Loire,84,Auvergne-Rhône-Alpes
42173,POMMIERS,42260,POMMIERS,,45.8312779492,4.07654195266,173,,Pommiers,Pommiers,42,Loire,84,Auvergne-Rhône-Alpes
42174,PONCINS,42110,PONCINS,,45.7272583786,4.15060346621,174,,Poncins,Poncins,42,Loire,84,Auvergne-Rhône-Alpes
42175,POUILLY LES FEURS,42110,POUILLY LES FEURS,,45.8008382114,4.23299692399,175,,Pouilly-lès-Feurs,Pouilly-lès-Feurs,42,Loire,84,Auvergne-Rhône-Alpes
42176,POUILLY LES NONAINS,42155,POUILLY LES NONAINS,,46.0446906414,3.97809193807,176,,Pouilly-les-Nonains,Pouilly-les-Nonains,42,Loire,84,Auvergne-Rhône-Alpes
42177,POUILLY SOUS CHARLIEU,42720,POUILLY SOUS CHARLIEU,,46.1354023515,4.13250530885,177,,Pouilly-sous-Charlieu,Pouilly-sous-Charlieu,42,Loire,84,Auvergne-Rhône-Alpes
42178,PRADINES,42630,PRADINES,,46.0000456451,4.17170231294,178,,Pradines,Pradines,42,Loire,84,Auvergne-Rhône-Alpes
42179,PRALONG,42600,PRALONG,,45.6602466343,4.02060723692,179,,Pralong,Pralong,42,Loire,84,Auvergne-Rhône-Alpes
42180,PRECIEUX,42600,PRECIEUX,,45.5884894185,4.14915558403,180,,Précieux,Précieux,42,Loire,84,Auvergne-Rhône-Alpes
42181,REGNY,42630,REGNY,,45.9973316564,4.21374559587,181,,Régny,Régny,42,Loire,84,Auvergne-Rhône-Alpes
42182,RENAISON,42370,RENAISON,,46.0476606321,3.90857597535,182,,Renaison,Renaison,42,Loire,84,Auvergne-Rhône-Alpes
42183,LA RICAMARIE,42150,LA RICAMARIE,,45.4033695674,4.36890566227,183,La,Ricamarie,La Ricamarie,42,Loire,84,Auvergne-Rhône-Alpes
42184,RIORGES,42153,RIORGES,,46.0429114311,4.03375590925,184,,Riorges,Riorges,42,Loire,84,Auvergne-Rhône-Alpes
42185,RIVAS,42340,RIVAS,,45.5885484014,4.25049605154,185,,Rivas,Rivas,42,Loire,84,Auvergne-Rhône-Alpes
42186,RIVE DE GIER,42800,RIVE DE GIER,,45.5232368969,4.61000981421,186,,Rive-de-Gier,Rive-de-Gier,42,Loire,84,Auvergne-Rhône-Alpes
42187,ROANNE,42300,ROANNE,,46.0449112487,4.0797045647,187,,Roanne,Roanne,42,Loire,84,Auvergne-Rhône-Alpes
42187,ROANNE,42300,ROANNE,L ARSENAL,46.0449112487,4.0797045647,187,,Roanne,Roanne,42,Loire,84,Auvergne-Rhône-Alpes
42188,ROCHE,42600,ROCHE,,45.6162113424,3.92144569626,188,,Roche,Roche,42,Loire,84,Auvergne-Rhône-Alpes
42189,ROCHE LA MOLIERE,42230,ROCHE LA MOLIERE,,45.4259230436,4.32495559821,189,,Roche-la-Molière,Roche-la-Molière,42,Loire,84,Auvergne-Rhône-Alpes
42191,ROISEY,42520,ROISEY,,45.391280992,4.65752995946,191,,Roisey,Roisey,42,Loire,84,Auvergne-Rhône-Alpes
42192,ROZIER COTES D AUREC,42380,ROZIER COTES D AUREC,,45.3763346252,4.1143178936,192,,Rozier-Côtes-d'Aurec,Rozier-Côtes-d'Aurec,42,Loire,84,Auvergne-Rhône-Alpes
42193,ROZIER EN DONZY,42810,ROZIER EN DONZY,,45.803570531,4.27277111132,193,,Rozier-en-Donzy,Rozier-en-Donzy,42,Loire,84,Auvergne-Rhône-Alpes
42194,SAIL LES BAINS,42310,SAIL LES BAINS,,46.2388754339,3.84257216516,194,,Sail-les-Bains,Sail-les-Bains,42,Loire,84,Auvergne-Rhône-Alpes
42195,SAIL SOUS COUZAN,42890,SAIL SOUS COUZAN,,45.7364287162,3.96098461916,195,,Sail-sous-Couzan,Sail-sous-Couzan,42,Loire,84,Auvergne-Rhône-Alpes
42196,STE AGATHE EN DONZY,42510,STE AGATHE EN DONZY,,45.8360909597,4.30544297684,196,,Sainte-Agathe-en-Donzy,Sainte-Agathe-en-Donzy,42,Loire,84,Auvergne-Rhône-Alpes
42197,STE AGATHE LA BOUTERESSE,42130,STE AGATHE LA BOUTERESSE,,45.7435935956,4.05118283654,197,,Sainte-Agathe-la-Bouteresse,Sainte-Agathe-la-Bouteresse,42,Loire,84,Auvergne-Rhône-Alpes
42198,ST ALBAN LES EAUX,42370,ST ALBAN LES EAUX,,46.0060538443,3.93140741051,198,,Saint-Alban-les-Eaux,Saint-Alban-les-Eaux,42,Loire,84,Auvergne-Rhône-Alpes
42199,ST ANDRE D APCHON,42370,ST ANDRE D APCHON,,46.0272361831,3.93483213636,199,,Saint-André-d'Apchon,Saint-André-d'Apchon,42,Loire,84,Auvergne-Rhône-Alpes
42200,ST ANDRE LE PUY,42210,ST ANDRE LE PUY,,45.646913833,4.26396291539,200,,Saint-André-le-Puy,Saint-André-le-Puy,42,Loire,84,Auvergne-Rhône-Alpes
42201,ST APPOLINARD,42520,ST APPOLINARD,,45.3455074313,4.64751344414,201,,Saint-Appolinard,Saint-Appolinard,42,Loire,84,Auvergne-Rhône-Alpes
42202,ST BARTHELEMY LESTRA,42110,ST BARTHELEMY LESTRA,,45.718628364,4.32852077786,202,,Saint-Barthélemy-Lestra,Saint-Barthélemy-Lestra,42,Loire,84,Auvergne-Rhône-Alpes
42203,ST BONNET DES QUARTS,42310,ST BONNET DES QUARTS,,46.1301853846,3.82816715097,203,,Saint-Bonnet-des-Quarts,Saint-Bonnet-des-Quarts,42,Loire,84,Auvergne-Rhône-Alpes
42204,ST BONNET LE CHATEAU,42380,ST BONNET LE CHATEAU,,45.4251682562,4.06509003822,204,,Saint-Bonnet-le-Château,Saint-Bonnet-le-Château,42,Loire,84,Auvergne-Rhône-Alpes
42205,ST BONNET LE COURREAU,42940,ST BONNET LE COURREAU,,45.655161946,3.9261116009,205,,Saint-Bonnet-le-Courreau,Saint-Bonnet-le-Courreau,42,Loire,84,Auvergne-Rhône-Alpes
42206,ST BONNET LES OULES,42330,ST BONNET LES OULES,,45.5412626706,4.33171249228,206,,Saint-Bonnet-les-Oules,Saint-Bonnet-les-Oules,42,Loire,84,Auvergne-Rhône-Alpes
42207,ST CHAMOND,42400,ST CHAMOND,,45.4698319517,4.50184989506,207,,Saint-Chamond,Saint-Chamond,42,Loire,84,Auvergne-Rhône-Alpes
42207,ST CHAMOND,42400,ST CHAMOND,IZIEUX,45.4698319517,4.50184989506,207,,Saint-Chamond,Saint-Chamond,42,Loire,84,Auvergne-Rhône-Alpes
42207,ST CHAMOND,42400,ST CHAMOND,ST JULIEN EN JAREZ,45.4698319517,4.50184989506,207,,Saint-Chamond,Saint-Chamond,42,Loire,84,Auvergne-Rhône-Alpes
42207,ST CHAMOND,42400,ST CHAMOND,ST MARTIN EN COAILLEUX,45.4698319517,4.50184989506,207,,Saint-Chamond,Saint-Chamond,42,Loire,84,Auvergne-Rhône-Alpes
42208,ST CHRISTO EN JAREZ,42320,ST CHRISTO EN JAREZ,,45.5362867258,4.4671653165,208,,Saint-Christo-en-Jarez,Saint-Christo-en-Jarez,42,Loire,84,Auvergne-Rhône-Alpes
42209,STE COLOMBE SUR GAND,42540,STE COLOMBE SUR GAND,,45.8677779412,4.28292761777,209,,Sainte-Colombe-sur-Gand,Sainte-Colombe-sur-Gand,42,Loire,84,Auvergne-Rhône-Alpes
42210,STE CROIX EN JAREZ,42800,STE CROIX EN JAREZ,,45.4769382751,4.63422150362,210,,Sainte-Croix-en-Jarez,Sainte-Croix-en-Jarez,42,Loire,84,Auvergne-Rhône-Alpes
42211,ST CYPRIEN,42160,ST CYPRIEN,,45.5399613626,4.22968401574,211,,Saint-Cyprien,Saint-Cyprien,42,Loire,84,Auvergne-Rhône-Alpes
42212,ST CYR DE FAVIERES,42123,ST CYR DE FAVIERES,,45.9616955216,4.10513740414,212,,Saint-Cyr-de-Favières,Saint-Cyr-de-Favières,42,Loire,84,Auvergne-Rhône-Alpes
42213,ST CYR DE VALORGES,42114,ST CYR DE VALORGES,,45.8893402235,4.31305449394,213,,Saint-Cyr-de-Valorges,Saint-Cyr-de-Valorges,42,Loire,84,Auvergne-Rhône-Alpes
42214,ST CYR LES VIGNES,42210,ST CYR LES VIGNES,,45.6766538011,4.28890743405,214,,Saint-Cyr-les-Vignes,Saint-Cyr-les-Vignes,42,Loire,84,Auvergne-Rhône-Alpes
42215,ST DENIS DE CABANNE,42750,ST DENIS DE CABANNE,,46.1763743544,4.20748749926,215,,Saint-Denis-de-Cabanne,Saint-Denis-de-Cabanne,42,Loire,84,Auvergne-Rhône-Alpes
42216,ST DENIS SUR COISE,42140,ST DENIS SUR COISE,,45.6115793741,4.43091520016,216,,Saint-Denis-sur-Coise,Saint-Denis-sur-Coise,42,Loire,84,Auvergne-Rhône-Alpes
42217,ST DIDIER SUR ROCHEFORT,42111,ST DIDIER SUR ROCHEFORT,,45.7934338068,3.86367748356,217,,Saint-Didier-sur-Rochefort,Saint-Didier-sur-Rochefort,42,Loire,84,Auvergne-Rhône-Alpes
42218,ST ETIENNE,42000,ST ETIENNE,,45.4301235512,4.37913997076,218,,Saint-Étienne,Saint-Étienne,42,Loire,84,Auvergne-Rhône-Alpes
42218,ST ETIENNE,42100,ST ETIENNE,,45.4301235512,4.37913997076,218,,Saint-Étienne,Saint-Étienne,42,Loire,84,Auvergne-Rhône-Alpes
42218,ST ETIENNE,42100,ST ETIENNE,ROCHETAILLEE,45.4301235512,4.37913997076,218,,Saint-Étienne,Saint-Étienne,42,Loire,84,Auvergne-Rhône-Alpes
42218,ST ETIENNE,42100,ST ETIENNE,TERRENOIRE,45.4301235512,4.37913997076,218,,Saint-Étienne,Saint-Étienne,42,Loire,84,Auvergne-Rhône-Alpes
42218,ST ETIENNE,42230,ST ETIENNE,ST VICTOR SUR LOIRE,45.4301235512,4.37913997076,218,,Saint-Étienne,Saint-Étienne,42,Loire,84,Auvergne-Rhône-Alpes
42219,ST ETIENNE LE MOLARD,42130,ST ETIENNE LE MOLARD,,45.739928029,4.09717656731,219,,Saint-Étienne-le-Molard,Saint-Étienne-le-Molard,42,Loire,84,Auvergne-Rhône-Alpes
42220,ST FORGEUX LESPINASSE,42640,ST FORGEUX LESPINASSE,,46.1390822509,3.94339360836,220,,Saint-Forgeux-Lespinasse,Saint-Forgeux-Lespinasse,42,Loire,84,Auvergne-Rhône-Alpes
42221,STE FOY ST SULPICE,42110,STE FOY ST SULPICE,,45.7766813055,4.1090035213,221,,Sainte-Foy-Saint-Sulpice,Sainte-Foy-Saint-Sulpice,42,Loire,84,Auvergne-Rhône-Alpes
42222,ST GALMIER,42330,ST GALMIER,,45.6033793655,4.31450535656,222,,Saint-Galmier,Saint-Galmier,42,Loire,84,Auvergne-Rhône-Alpes
42223,ST GENEST LERPT,42530,ST GENEST LERPT,,45.4557410438,4.33134504197,223,,Saint-Genest-Lerpt,Saint-Genest-Lerpt,42,Loire,84,Auvergne-Rhône-Alpes
42224,ST GENEST MALIFAUX,42660,ST GENEST MALIFAUX,,45.3472458116,4.43132626452,224,,Saint-Genest-Malifaux,Saint-Genest-Malifaux,42,Loire,84,Auvergne-Rhône-Alpes
42225,GENILAC,42800,GENILAC,,45.5351394274,4.57332962492,225,,Genilac,Genilac,42,Loire,84,Auvergne-Rhône-Alpes
42225,GENILAC,42800,GENILAC,LA CULA,45.5351394274,4.57332962492,225,,Genilac,Genilac,42,Loire,84,Auvergne-Rhône-Alpes
42226,ST GEORGES DE BAROILLE,42510,ST GEORGES DE BAROILLE,,45.8535792416,4.10712450945,226,,Saint-Georges-de-Baroille,Saint-Georges-de-Baroille,42,Loire,84,Auvergne-Rhône-Alpes
42227,ST GEORGES EN COUZAN,42990,ST GEORGES EN COUZAN,,45.7069401539,3.930271238,227,,Saint-Georges-en-Couzan,Saint-Georges-en-Couzan,42,Loire,84,Auvergne-Rhône-Alpes
42228,ST GEORGES HAUTE VILLE,42610,ST GEORGES HAUTE VILLE,,45.5439493832,4.09234828784,228,,Saint-Georges-Haute-Ville,Saint-Georges-Haute-Ville,42,Loire,84,Auvergne-Rhône-Alpes
42229,ST GERMAIN LA MONTAGNE,42670,ST GERMAIN LA MONTAGNE,,46.1990372012,4.39130879538,229,,Saint-Germain-la-Montagne,Saint-Germain-la-Montagne,42,Loire,84,Auvergne-Rhône-Alpes
42230,ST GERMAIN LAVAL,42260,ST GERMAIN LAVAL,,45.8308448728,4.01988837409,230,,Saint-Germain-Laval,Saint-Germain-Laval,42,Loire,84,Auvergne-Rhône-Alpes
42231,ST GERMAIN LESPINASSE,42640,ST GERMAIN LESPINASSE,,46.1088139575,3.96414737669,231,,Saint-Germain-Lespinasse,Saint-Germain-Lespinasse,42,Loire,84,Auvergne-Rhône-Alpes
42232,ST HAON LE CHATEL,42370,ST HAON LE CHATEL,,46.0647653123,3.91707635373,232,,Saint-Haon-le-Châtel,Saint-Haon-le-Châtel,42,Loire,84,Auvergne-Rhône-Alpes
42233,ST HAON LE VIEUX,42370,ST HAON LE VIEUX,,46.0741993269,3.90464894951,233,,Saint-Haon-le-Vieux,Saint-Haon-le-Vieux,42,Loire,84,Auvergne-Rhône-Alpes
42234,ST HEAND,42570,ST HEAND,,45.5305252119,4.38203656297,234,,Saint-Héand,Saint-Héand,42,Loire,84,Auvergne-Rhône-Alpes
42235,ST HILAIRE CUSSON LA VALMITTE,42380,ST HILAIRE CUSSON LA VALMITTE,,45.3679237574,4.05975538069,235,,Saint-Hilaire-Cusson-la-Valmitte,Saint-Hilaire-Cusson-la-Valmitte,42,Loire,84,Auvergne-Rhône-Alpes
42236,ST HILAIRE SOUS CHARLIEU,42190,ST HILAIRE SOUS CHARLIEU,,46.1210288218,4.1833614828,236,,Saint-Hilaire-sous-Charlieu,Saint-Hilaire-sous-Charlieu,42,Loire,84,Auvergne-Rhône-Alpes
42237,ST JEAN BONNEFONDS,42650,ST JEAN BONNEFONDS,,45.4568786158,4.44848172147,237,,Saint-Jean-Bonnefonds,Saint-Jean-Bonnefonds,42,Loire,84,Auvergne-Rhône-Alpes
42238,ST JEAN LA VETRE,42440,ST JEAN LA VETRE,,45.7746461063,3.79161072319,238,,Saint-Jean-la-Vêtre,Saint-Jean-la-Vêtre,42,Loire,84,Auvergne-Rhône-Alpes
42239,ST JEAN ST MAURICE SUR LOIRE,42155,ST JEAN ST MAURICE SUR LOIRE,,45.9576591068,3.98572458074,239,,Saint-Jean-Saint-Maurice-sur-Loire,Saint-Jean-Saint-Maurice-sur-Loire,42,Loire,84,Auvergne-Rhône-Alpes
42239,ST JEAN ST MAURICE SUR LOIRE,42155,ST JEAN ST MAURICE SUR LOIRE,ST MAURICE SUR LOIRE,45.9576591068,3.98572458074,239,,Saint-Jean-Saint-Maurice-sur-Loire,Saint-Jean-Saint-Maurice-sur-Loire,42,Loire,84,Auvergne-Rhône-Alpes
42240,ST JEAN SOLEYMIEUX,42560,ST JEAN SOLEYMIEUX,,45.500965191,4.01116096667,240,,Saint-Jean-Soleymieux,Saint-Jean-Soleymieux,42,Loire,84,Auvergne-Rhône-Alpes
42241,ST JODARD,42590,ST JODARD,,45.8878181316,4.12707045019,241,,Saint-Jodard,Saint-Jodard,42,Loire,84,Auvergne-Rhône-Alpes
42242,ST JOSEPH,42800,ST JOSEPH,,45.5552073766,4.61943614225,242,,Saint-Joseph,Saint-Joseph,42,Loire,84,Auvergne-Rhône-Alpes
42243,ST JULIEN D ODDES,42260,ST JULIEN D ODDES,,45.8516697408,3.99121351399,243,,Saint-Julien-d'Oddes,Saint-Julien-d'Oddes,42,Loire,84,Auvergne-Rhône-Alpes
42245,ST JULIEN LA VETRE,42440,ST JULIEN LA VETRE,,45.8203021782,3.81786181803,245,,Saint-Julien-la-Vêtre,Saint-Julien-la-Vêtre,42,Loire,84,Auvergne-Rhône-Alpes
42246,ST JULIEN MOLIN MOLETTE,42220,ST JULIEN MOLIN MOLETTE,,45.3215951313,4.61802793895,246,,Saint-Julien-Molin-Molette,Saint-Julien-Molin-Molette,42,Loire,84,Auvergne-Rhône-Alpes
42247,ST JUST EN BAS,42990,ST JUST EN BAS,,45.7314533102,3.88013674986,247,,Saint-Just-en-Bas,Saint-Just-en-Bas,42,Loire,84,Auvergne-Rhône-Alpes
42248,ST JUST EN CHEVALET,42430,ST JUST EN CHEVALET,,45.9235907262,3.84329480251,248,,Saint-Just-en-Chevalet,Saint-Just-en-Chevalet,42,Loire,84,Auvergne-Rhône-Alpes
42249,ST JUST LA PENDUE,42540,ST JUST LA PENDUE,,45.8875493245,4.24162884175,249,,Saint-Just-la-Pendue,Saint-Just-la-Pendue,42,Loire,84,Auvergne-Rhône-Alpes
42251,ST LAURENT LA CONCHE,42210,ST LAURENT LA CONCHE,,45.6890006035,4.23185003367,251,,Saint-Laurent-la-Conche,Saint-Laurent-la-Conche,42,Loire,84,Auvergne-Rhône-Alpes
42252,ST LAURENT ROCHEFORT,42130,ST LAURENT ROCHEFORT,,45.7745356504,3.90649306671,252,,Saint-Laurent-Rochefort,Saint-Laurent-Rochefort,42,Loire,84,Auvergne-Rhône-Alpes
42253,ST LEGER SUR ROANNE,42155,ST LEGER SUR ROANNE,,46.0416783061,4.00124204353,253,,Saint-Léger-sur-Roanne,Saint-Léger-sur-Roanne,42,Loire,84,Auvergne-Rhône-Alpes
42254,ST MARCEL DE FELINES,42122,ST MARCEL DE FELINES,,45.8614554366,4.1876629386,254,,Saint-Marcel-de-Félines,Saint-Marcel-de-Félines,42,Loire,84,Auvergne-Rhône-Alpes
42255,ST MARCEL D URFE,42430,ST MARCEL D URFE,,45.8708472787,3.87977382868,255,,Saint-Marcel-d'Urfé,Saint-Marcel-d'Urfé,42,Loire,84,Auvergne-Rhône-Alpes
42256,ST MARCELLIN EN FOREZ,42680,ST MARCELLIN EN FOREZ,,45.4892854611,4.16817576447,256,,Saint-Marcellin-en-Forez,Saint-Marcellin-en-Forez,42,Loire,84,Auvergne-Rhône-Alpes
42257,ST MARTIN D ESTREAUX,42620,ST MARTIN D ESTREAUX,,46.2089488528,3.81781028236,257,,Saint-Martin-d'Estréaux,Saint-Martin-d'Estréaux,42,Loire,84,Auvergne-Rhône-Alpes
42259,ST MARTIN LA PLAINE,42800,ST MARTIN LA PLAINE,,45.5522879045,4.59128489318,259,,Saint-Martin-la-Plaine,Saint-Martin-la-Plaine,42,Loire,84,Auvergne-Rhône-Alpes
42260,ST MARTIN LA SAUVETE,42260,ST MARTIN LA SAUVETE,,45.8366527059,3.92057216059,260,,Saint-Martin-la-Sauveté,Saint-Martin-la-Sauveté,42,Loire,84,Auvergne-Rhône-Alpes
42261,ST MARTIN LESTRA,42110,ST MARTIN LESTRA,,45.731526312,4.3688277633,261,,Saint-Martin-Lestra,Saint-Martin-Lestra,42,Loire,84,Auvergne-Rhône-Alpes
42262,ST MAURICE EN GOURGOIS,42240,ST MAURICE EN GOURGOIS,,45.4080254859,4.18440068436,262,,Saint-Maurice-en-Gourgois,Saint-Maurice-en-Gourgois,42,Loire,84,Auvergne-Rhône-Alpes
42264,ST MEDARD EN FOREZ,42330,ST MEDARD EN FOREZ,,45.5923485305,4.36215864374,264,,Saint-Médard-en-Forez,Saint-Médard-en-Forez,42,Loire,84,Auvergne-Rhône-Alpes
42265,ST MICHEL SUR RHONE,42410,ST MICHEL SUR RHONE,,45.4436606281,4.73367710517,265,,Saint-Michel-sur-Rhône,Saint-Michel-sur-Rhône,42,Loire,84,Auvergne-Rhône-Alpes
42266,ST NIZIER DE FORNAS,42380,ST NIZIER DE FORNAS,,45.4047039086,4.0825460326,266,,Saint-Nizier-de-Fornas,Saint-Nizier-de-Fornas,42,Loire,84,Auvergne-Rhône-Alpes
42267,ST NIZIER SOUS CHARLIEU,42190,ST NIZIER SOUS CHARLIEU,,46.1624812464,4.12649769645,267,,Saint-Nizier-sous-Charlieu,Saint-Nizier-sous-Charlieu,42,Loire,84,Auvergne-Rhône-Alpes
42268,ST PAUL DE VEZELIN,42590,ST PAUL DE VEZELIN,,45.8892083156,4.08289919803,268,,Saint-Paul-de-Vézelin,Saint-Paul-de-Vézelin,42,Loire,84,Auvergne-Rhône-Alpes
42269,ST PAUL D UZORE,42600,ST PAUL D UZORE,,45.6749292943,4.09004893235,269,,Saint-Paul-d'Uzore,Saint-Paul-d'Uzore,42,Loire,84,Auvergne-Rhône-Alpes
42270,ST PAUL EN CORNILLON,42240,ST PAUL EN CORNILLON,,45.3957585018,4.23942143752,270,,Saint-Paul-en-Cornillon,Saint-Paul-en-Cornillon,42,Loire,84,Auvergne-Rhône-Alpes
42271,ST PAUL EN JAREZ,42740,ST PAUL EN JAREZ,,45.4705874641,4.57893504841,271,,Saint-Paul-en-Jarez,Saint-Paul-en-Jarez,42,Loire,84,Auvergne-Rhône-Alpes
42272,ST PIERRE DE BOEUF,42520,ST PIERRE DE BOEUF,,45.3766717807,4.74426644516,272,,Saint-Pierre-de-Bœuf,Saint-Pierre-de-Bœuf,42,Loire,84,Auvergne-Rhône-Alpes
42273,ST PIERRE LA NOAILLE,42190,ST PIERRE LA NOAILLE,,46.182835478,4.10333966313,273,,Saint-Pierre-la-Noaille,Saint-Pierre-la-Noaille,42,Loire,84,Auvergne-Rhône-Alpes
42274,ST POLGUES,42260,ST POLGUES,,45.909623488,3.981388774,274,,Saint-Polgues,Saint-Polgues,42,Loire,84,Auvergne-Rhône-Alpes
42275,ST PRIEST EN JAREZ,42270,ST PRIEST EN JAREZ,,45.4752840967,4.37334466896,275,,Saint-Priest-en-Jarez,Saint-Priest-en-Jarez,42,Loire,84,Auvergne-Rhône-Alpes
42276,ST PRIEST LA PRUGNE,42830,ST PRIEST LA PRUGNE,,45.9460820015,3.74227575904,276,,Saint-Priest-la-Prugne,Saint-Priest-la-Prugne,42,Loire,84,Auvergne-Rhône-Alpes
42277,ST PRIEST LA ROCHE,42590,ST PRIEST LA ROCHE,,45.9201905216,4.10633617946,277,,Saint-Priest-la-Roche,Saint-Priest-la-Roche,42,Loire,84,Auvergne-Rhône-Alpes
42278,ST PRIEST LA VETRE,42440,ST PRIEST LA VETRE,,45.8015242352,3.80979787497,278,,Saint-Priest-la-Vêtre,Saint-Priest-la-Vêtre,42,Loire,84,Auvergne-Rhône-Alpes
42279,ST JUST ST RAMBERT,42170,ST JUST ST RAMBERT,,45.4903250932,4.25026509629,279,,Saint-Just-Saint-Rambert,Saint-Just-Saint-Rambert,42,Loire,84,Auvergne-Rhône-Alpes
42279,ST JUST ST RAMBERT,42170,ST JUST ST RAMBERT,ST JUST SUR LOIRE,45.4903250932,4.25026509629,279,,Saint-Just-Saint-Rambert,Saint-Just-Saint-Rambert,42,Loire,84,Auvergne-Rhône-Alpes
42280,ST REGIS DU COIN,42660,ST REGIS DU COIN,,45.288644573,4.45522567746,280,,Saint-Régis-du-Coin,Saint-Régis-du-Coin,42,Loire,84,Auvergne-Rhône-Alpes
42281,ST RIRAND,42370,ST RIRAND,,46.0709034928,3.83743975757,281,,Saint-Rirand,Saint-Rirand,42,Loire,84,Auvergne-Rhône-Alpes
42282,ST ROMAIN D URFE,42430,ST ROMAIN D URFE,,45.8856641842,3.81783953207,282,,Saint-Romain-d'Urfé,Saint-Romain-d'Urfé,42,Loire,84,Auvergne-Rhône-Alpes
42283,ST ROMAIN EN JAREZ,42800,ST ROMAIN EN JAREZ,,45.5651376948,4.54150317464,283,,Saint-Romain-en-Jarez,Saint-Romain-en-Jarez,42,Loire,84,Auvergne-Rhône-Alpes
42284,ST ROMAIN LA MOTTE,42640,ST ROMAIN LA MOTTE,,46.0816280349,3.9953263339,284,,Saint-Romain-la-Motte,Saint-Romain-la-Motte,42,Loire,84,Auvergne-Rhône-Alpes
42285,ST ROMAIN LE PUY,42610,ST ROMAIN LE PUY,,45.5569265459,4.13106130921,285,,Saint-Romain-le-Puy,Saint-Romain-le-Puy,42,Loire,84,Auvergne-Rhône-Alpes
42286,ST ROMAIN LES ATHEUX,42660,ST ROMAIN LES ATHEUX,,45.3660975776,4.37077848051,286,,Saint-Romain-les-Atheux,Saint-Romain-les-Atheux,42,Loire,84,Auvergne-Rhône-Alpes
42287,ST SAUVEUR EN RUE,42220,ST SAUVEUR EN RUE,,45.2712880618,4.50055395379,287,,Saint-Sauveur-en-Rue,Saint-Sauveur-en-Rue,42,Loire,84,Auvergne-Rhône-Alpes
42288,ST SIXTE,42130,ST SIXTE,,45.7724079231,3.9742199747,288,,Saint-Sixte,Saint-Sixte,42,Loire,84,Auvergne-Rhône-Alpes
42289,ST SYMPHORIEN DE LAY,42470,ST SYMPHORIEN DE LAY,,45.9435843496,4.21665537628,289,,Saint-Symphorien-de-Lay,Saint-Symphorien-de-Lay,42,Loire,84,Auvergne-Rhône-Alpes
42290,ST THOMAS LA GARDE,42600,ST THOMAS LA GARDE,,45.5713650794,4.08597131676,290,,Saint-Thomas-la-Garde,Saint-Thomas-la-Garde,42,Loire,84,Auvergne-Rhône-Alpes
42291,ST THURIN,42111,ST THURIN,,45.818834546,3.88477929539,291,,Saint-Thurin,Saint-Thurin,42,Loire,84,Auvergne-Rhône-Alpes
42293,ST VICTOR SUR RHINS,42630,ST VICTOR SUR RHINS,,46.0058474894,4.28322306743,293,,Saint-Victor-sur-Rhins,Saint-Victor-sur-Rhins,42,Loire,84,Auvergne-Rhône-Alpes
42294,ST VINCENT DE BOISSET,42120,ST VINCENT DE BOISSET,,46.0087202985,4.11586749882,294,,Saint-Vincent-de-Boisset,Saint-Vincent-de-Boisset,42,Loire,84,Auvergne-Rhône-Alpes
42295,LES SALLES,42440,LES SALLES,,45.8549120107,3.78440621184,295,Les,Salles,Les Salles,42,Loire,84,Auvergne-Rhône-Alpes
42296,SALT EN DONZY,42110,SALT EN DONZY,,45.7335095274,4.28266323222,296,,Salt-en-Donzy,Salt-en-Donzy,42,Loire,84,Auvergne-Rhône-Alpes
42297,SALVIZINET,42110,SALVIZINET,,45.7593642516,4.27548067122,297,,Salvizinet,Salvizinet,42,Loire,84,Auvergne-Rhône-Alpes
42298,SAUVAIN,42990,SAUVAIN,,45.6641357765,3.86010526625,298,,Sauvain,Sauvain,42,Loire,84,Auvergne-Rhône-Alpes
42299,SAVIGNEUX,42600,SAVIGNEUX,,45.6223963471,4.1003957272,299,,Savigneux,Savigneux,42,Loire,84,Auvergne-Rhône-Alpes
42300,SEVELINGES,42460,SEVELINGES,,46.0993826373,4.28754411377,300,,Sevelinges,Sevelinges,42,Loire,84,Auvergne-Rhône-Alpes
42301,SOLEYMIEUX,42560,SOLEYMIEUX,,45.5062879483,4.06396167678,301,,Soleymieux,Soleymieux,42,Loire,84,Auvergne-Rhône-Alpes
42302,SORBIERS,42290,SORBIERS,,45.4997083615,4.43728891788,302,,Sorbiers,Sorbiers,42,Loire,84,Auvergne-Rhône-Alpes
42303,SOUTERNON,42260,SOUTERNON,,45.8803291451,3.99029716142,303,,Souternon,Souternon,42,Loire,84,Auvergne-Rhône-Alpes
42304,SURY LE COMTAL,42450,SURY LE COMTAL,,45.5417167026,4.18146739816,304,,Sury-le-Comtal,Sury-le-Comtal,42,Loire,84,Auvergne-Rhône-Alpes
42305,LA TALAUDIERE,42350,LA TALAUDIERE,,45.4783411216,4.42393692912,305,La,Talaudière,La Talaudière,42,Loire,84,Auvergne-Rhône-Alpes
42306,TARENTAISE,42660,TARENTAISE,,45.3612036739,4.49235219509,306,,Tarentaise,Tarentaise,42,Loire,84,Auvergne-Rhône-Alpes
42307,TARTARAS,42800,TARTARAS,,45.5540344541,4.66360649191,307,,Tartaras,Tartaras,42,Loire,84,Auvergne-Rhône-Alpes
42308,LA TERRASSE SUR DORLAY,42740,LA TERRASSE SUR DORLAY,,45.4477648412,4.5788900746,308,La,Terrasse-sur-Dorlay,La Terrasse-sur-Dorlay,42,Loire,84,Auvergne-Rhône-Alpes
42310,THELIS LA COMBE,42220,THELIS LA COMBE,,45.3361183193,4.54730534012,310,,Thélis-la-Combe,Thélis-la-Combe,42,Loire,84,Auvergne-Rhône-Alpes
42311,LA TOUR EN JAREZ,42580,LA TOUR EN JAREZ,,45.4883528278,4.39780519433,311,La,Tour-en-Jarez,La Tour-en-Jarez,42,Loire,84,Auvergne-Rhône-Alpes
42312,LA TOURETTE,42380,LA TOURETTE,,45.423867276,4.08330513601,312,La,Tourette,La Tourette,42,Loire,84,Auvergne-Rhône-Alpes
42313,TRELINS,42130,TRELINS,,45.7251404881,4.00078301382,313,,Trelins,Trelins,42,Loire,84,Auvergne-Rhône-Alpes
42314,LA TUILIERE,42830,LA TUILIERE,,45.9586791748,3.81278919577,314,La,Tuilière,La Tuilière,42,Loire,84,Auvergne-Rhône-Alpes
42315,UNIAS,42210,UNIAS,,45.6071257773,4.2265660188,315,,Unias,Unias,42,Loire,84,Auvergne-Rhône-Alpes
42316,UNIEUX,42240,UNIEUX,,45.4069770909,4.2690668711,316,,Unieux,Unieux,42,Loire,84,Auvergne-Rhône-Alpes
42317,URBISE,42310,URBISE,,46.2509914533,3.88650116496,317,,Urbise,Urbise,42,Loire,84,Auvergne-Rhône-Alpes
42318,USSON EN FOREZ,42550,USSON EN FOREZ,,45.3918648082,3.93424665058,318,,Usson-en-Forez,Usson-en-Forez,42,Loire,84,Auvergne-Rhône-Alpes
42319,VALEILLE,42110,VALEILLE,,45.7074991536,4.28122611074,319,,Valeille,Valeille,42,Loire,84,Auvergne-Rhône-Alpes
42320,VALFLEURY,42320,VALFLEURY,,45.5416204634,4.51147591578,320,,Valfleury,Valfleury,42,Loire,84,Auvergne-Rhône-Alpes
42321,LA VALLA SUR ROCHEFORT,42111,LA VALLA SUR ROCHEFORT,,45.7570573069,3.84373877987,321,La,Valla-sur-Rochefort,La Valla-sur-Rochefort,42,Loire,84,Auvergne-Rhône-Alpes
42322,LA VALLA EN GIER,42131,LA VALLA EN GIER,,45.4052097313,4.52491972285,322,La,Valla-en-Gier,La Valla-en-Gier,42,Loire,84,Auvergne-Rhône-Alpes
42323,VEAUCHE,42340,VEAUCHE,,45.5621736685,4.28985644507,323,,Veauche,Veauche,42,Loire,84,Auvergne-Rhône-Alpes
42324,VEAUCHETTE,42340,VEAUCHETTE,,45.5619081956,4.25092229875,324,,Veauchette,Veauchette,42,Loire,84,Auvergne-Rhône-Alpes
42325,VENDRANGES,42590,VENDRANGES,,45.9331197699,4.13535953151,325,,Vendranges,Vendranges,42,Loire,84,Auvergne-Rhône-Alpes
42326,VERANNE,42520,VERANNE,,45.3722142523,4.63484498928,326,,Véranne,Véranne,42,Loire,84,Auvergne-Rhône-Alpes
42327,VERIN,42410,VERIN,,45.4600485183,4.74079429125,327,,Vérin,Vérin,42,Loire,84,Auvergne-Rhône-Alpes
42328,VERRIERES EN FOREZ,42600,VERRIERES EN FOREZ,,45.5680113401,3.98919241451,328,,Verrières-en-Forez,Verrières-en-Forez,42,Loire,84,Auvergne-Rhône-Alpes
42329,LA VERSANNE,42220,LA VERSANNE,,45.3205754592,4.50642756991,329,La,Versanne,La Versanne,42,Loire,84,Auvergne-Rhône-Alpes
42330,VILLARS,42390,VILLARS,,45.4687923748,4.35203419027,330,,Villars,Villars,42,Loire,84,Auvergne-Rhône-Alpes
42331,VILLEMONTAIS,42155,VILLEMONTAIS,,45.9825018383,3.93640831898,331,,Villemontais,Villemontais,42,Loire,84,Auvergne-Rhône-Alpes
42332,VILLEREST,42300,VILLEREST,,45.9976820966,4.02783295013,332,,Villerest,Villerest,42,Loire,84,Auvergne-Rhône-Alpes
42333,VILLERS,42460,VILLERS,,46.1249923509,4.22717855984,333,,Villers,Villers,42,Loire,84,Auvergne-Rhône-Alpes
42334,VIOLAY,42780,VIOLAY,,45.8519288736,4.34938627101,334,,Violay,Violay,42,Loire,84,Auvergne-Rhône-Alpes
42335,VIRICELLES,42140,VIRICELLES,,45.6550209584,4.38155703358,335,,Viricelles,Viricelles,42,Loire,84,Auvergne-Rhône-Alpes
42336,VIRIGNEUX,42140,VIRIGNEUX,,45.6872242929,4.34645637144,336,,Virigneux,Virigneux,42,Loire,84,Auvergne-Rhône-Alpes
42337,VIVANS,42310,VIVANS,,46.1823567174,3.93687924544,337,,Vivans,Vivans,42,Loire,84,Auvergne-Rhône-Alpes
42338,VOUGY,42720,VOUGY,,46.0930613385,4.12402637585,338,,Vougy,Vougy,42,Loire,84,Auvergne-Rhône-Alpes
42339,CHAUSSETERRE,42430,CHAUSSETERRE,,45.9015168526,3.77091785307,339,,Chausseterre,Chausseterre,42,Loire,84,Auvergne-Rhône-Alpes
43001,AGNAT,43100,AGNAT,,45.3450418284,3.4501817683,1,,Agnat,Agnat,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43002,AIGUILHE,43000,AIGUILHE,,45.0530693199,3.88524729137,2,,Aiguilhe,Aiguilhe,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43003,ALLEGRE,43270,ALLEGRE,,45.1884321561,3.68990996082,3,,Allègre,Allègre,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43004,ALLEYRAC,43150,ALLEYRAC,,44.8944815222,3.98915009385,4,,Alleyrac,Alleyrac,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43005,ALLEYRAS,43580,ALLEYRAS,,44.9071449693,3.68020035357,5,,Alleyras,Alleyras,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43006,ALLY,43380,ALLY,,45.1502850873,3.3167176948,6,,Ally,Ally,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43007,ARAULES,43200,ARAULES,,45.0650385961,4.16874927244,7,,Araules,Araules,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43008,ARLEMPDES,43490,ARLEMPDES,,44.8669171298,3.90308376688,8,,Arlempdes,Arlempdes,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43009,ARLET,43380,ARLET,,45.1126240159,3.41043296989,9,,Arlet,Arlet,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43010,ARSAC EN VELAY,43700,ARSAC EN VELAY,,44.9936614826,3.95457009632,10,,Arsac-en-Velay,Arsac-en-Velay,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43011,AUBAZAT,43380,AUBAZAT,,45.1283416697,3.44490053055,11,,Aubazat,Aubazat,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43012,AUREC SUR LOIRE,43110,AUREC SUR LOIRE,,45.3688645706,4.20459797548,12,,Aurec-sur-Loire,Aurec-sur-Loire,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43013,VISSAC AUTEYRAC,43300,VISSAC AUTEYRAC,,45.1163766085,3.62418252046,13,,Vissac-Auteyrac,Vissac-Auteyrac,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43013,VISSAC AUTEYRAC,43300,VISSAC AUTEYRAC,VISSAC,45.1163766085,3.62418252046,13,,Vissac-Auteyrac,Vissac-Auteyrac,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43014,AUTRAC,43450,AUTRAC,,45.3367716906,3.12941961398,14,,Autrac,Autrac,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43015,AUVERS,43300,AUVERS,,44.9966929064,3.39158114274,15,,Auvers,Auvers,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43016,AUZON,43390,AUZON,,45.387357804,3.37851265715,16,,Auzon,Auzon,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43017,AZERAT,43390,AZERAT,,45.353475703,3.3990093293,17,,Azérat,Azérat,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43018,BAINS,43370,BAINS,,44.9961387777,3.76352409678,18,,Bains,Bains,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43019,BARGES,43340,BARGES,,44.8340369786,3.88416858573,19,,Barges,Barges,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43020,BAS EN BASSET,43210,BAS EN BASSET,,45.3159119273,4.104826748,20,,Bas-en-Basset,Bas-en-Basset,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43021,BEAULIEU,43800,BEAULIEU,,45.1427890679,3.95088637654,21,,Beaulieu,Beaulieu,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43022,BEAUMONT,43100,BEAUMONT,,45.3118081089,3.33070531118,22,,Beaumont,Beaumont,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43023,BEAUNE SUR ARZON,43500,BEAUNE SUR ARZON,,45.2883722336,3.82077578795,23,,Beaune-sur-Arzon,Beaune-sur-Arzon,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43024,BEAUX,43200,BEAUX,,45.1942542124,4.08248145168,24,,Beaux,Beaux,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43025,BEAUZAC,43590,BEAUZAC,,45.2567472819,4.09279084729,25,,Beauzac,Beauzac,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43026,BELLEVUE LA MONTAGNE,43350,BELLEVUE LA MONTAGNE,,45.2169858786,3.81493834452,26,,Bellevue-la-Montagne,Bellevue-la-Montagne,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43027,BERBEZIT,43160,BERBEZIT,,45.2869739062,3.59469337193,27,,Berbezit,Berbezit,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43028,BESSAMOREL,43200,BESSAMOREL,,45.1306448726,4.07952494849,28,,Bessamorel,Bessamorel,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43029,LA BESSEYRE ST MARY,43170,LA BESSEYRE ST MARY,,44.9713807962,3.41459641129,29,La,Besseyre-Saint-Mary,La Besseyre-Saint-Mary,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43030,BLANZAC,43350,BLANZAC,,45.1107801226,3.85037132513,30,,Blanzac,Blanzac,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43031,BLASSAC,43380,BLASSAC,,45.1611462033,3.38427748233,31,,Blassac,Blassac,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43032,BLAVOZY,43700,BLAVOZY,,45.0625246612,3.97204508531,32,,Blavozy,Blavozy,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43033,BLESLE,43450,BLESLE,,45.3179155382,3.17686146037,33,,Blesle,Blesle,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43034,BOISSET,43500,BOISSET,,45.3167286043,3.9815552467,34,,Boisset,Boisset,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43035,BONNEVAL,43160,BONNEVAL,,45.3071974521,3.73265669732,35,,Bonneval,Bonneval,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43036,BORNE,43350,BORNE,,45.0944224247,3.7919088569,36,,Borne,Borne,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43037,LE BOUCHET ST NICOLAS,43510,LE BOUCHET ST NICOLAS,,44.8870691928,3.78767619023,37,Le,Bouchet-Saint-Nicolas,Le Bouchet-Saint-Nicolas,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43038,BOURNONCLE ST PIERRE,43360,BOURNONCLE ST PIERRE,,45.3431536681,3.32396530998,38,,Bournoncle-Saint-Pierre,Bournoncle-Saint-Pierre,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43038,BOURNONCLE ST PIERRE,43360,BOURNONCLE ST PIERRE,ARVANT,45.3431536681,3.32396530998,38,,Bournoncle-Saint-Pierre,Bournoncle-Saint-Pierre,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43039,LE BRIGNON,43370,LE BRIGNON,,44.9156334152,3.87741582593,39,Le,Brignon,Le Brignon,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43040,BRIOUDE,43100,BRIOUDE,,45.2964378846,3.38526303303,40,,Brioude,Brioude,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43041,BRIVES CHARENSAC,43700,BRIVES CHARENSAC,,45.0463267089,3.92401115104,41,,Brives-Charensac,Brives-Charensac,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43042,CAYRES,43510,CAYRES,,44.9221545341,3.81071346525,42,,Cayres,Cayres,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43043,CEAUX D ALLEGRE,43270,CEAUX D ALLEGRE,,45.1831789556,3.7598519485,43,,Céaux-d'Allègre,Céaux-d'Allègre,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43044,CERZAT,43380,CERZAT,,45.1591392099,3.47227025846,44,,Cerzat,Cerzat,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43045,CEYSSAC,43000,CEYSSAC,,45.03515198,3.83028543045,45,,Ceyssac,Ceyssac,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43046,CHADRAC,43770,CHADRAC,,45.0610194806,3.90105321748,46,,Chadrac,Chadrac,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43047,CHADRON,43150,CHADRON,,44.9500949064,3.93743261861,47,,Chadron,Chadron,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43048,LA CHAISE DIEU,43160,LA CHAISE DIEU,,45.3187166909,3.69286458356,48,La,Chaise-Dieu,La Chaise-Dieu,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43049,CHAMALIERES SUR LOIRE,43800,CHAMALIERES SUR LOIRE,,45.1874021192,3.98106749222,49,,Chamalières-sur-Loire,Chamalières-sur-Loire,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43050,CHAMBEZON,43410,CHAMBEZON,,45.3818265401,3.24008015302,50,,Chambezon,Chambezon,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43051,LE CHAMBON SUR LIGNON,43400,LE CHAMBON SUR LIGNON,,45.0524632592,4.31966525163,51,Le,Chambon-sur-Lignon,Le Chambon-sur-Lignon,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43052,CHAMPAGNAC LE VIEUX,43440,CHAMPAGNAC LE VIEUX,,45.3545907565,3.50450522586,52,,Champagnac-le-Vieux,Champagnac-le-Vieux,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43053,CHAMPCLAUSE,43260,CHAMPCLAUSE,,45.0245461662,4.16078636885,53,,Champclause,Champclause,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43053,CHAMPCLAUSE,43430,CHAMPCLAUSE,,45.0245461662,4.16078636885,53,,Champclause,Champclause,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43054,CHANALEILLES,43170,CHANALEILLES,,44.8573929724,3.48785990455,54,,Chanaleilles,Chanaleilles,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43055,CHANIAT,43100,CHANIAT,,45.3163724348,3.48010403585,55,,Chaniat,Chaniat,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43056,CHANTEUGES,43300,CHANTEUGES,,45.0682663311,3.53025936017,56,,Chanteuges,Chanteuges,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43057,LA CHAPELLE BERTIN,43270,LA CHAPELLE BERTIN,,45.2171452983,3.64756861773,57,La,Chapelle-Bertin,La Chapelle-Bertin,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43058,LA CHAPELLE D AUREC,43120,LA CHAPELLE D AUREC,,45.3308947174,4.205185256,58,La,Chapelle-d'Aurec,La Chapelle-d'Aurec,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43059,LA CHAPELLE GENESTE,43160,LA CHAPELLE GENESTE,,45.3533341327,3.67413937548,59,La,Chapelle-Geneste,La Chapelle-Geneste,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43060,CHARRAIX,43300,CHARRAIX,,45.0254209003,3.55934731457,60,,Charraix,Charraix,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43061,CHASPINHAC,43700,CHASPINHAC,,45.0878247699,3.93080497141,61,,Chaspinhac,Chaspinhac,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43062,CHASPUZAC,43320,CHASPUZAC,,45.065005117,3.75027511306,62,,Chaspuzac,Chaspuzac,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43063,CHASSAGNES,43230,CHASSAGNES,,45.219501871,3.55045183907,63,,Chassagnes,Chassagnes,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43064,CHASSIGNOLLES,43440,CHASSIGNOLLES,,45.3998501161,3.48700463133,64,,Chassignolles,Chassignolles,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43065,CHASTEL,43300,CHASTEL,,45.0627745452,3.32138281225,65,,Chastel,Chastel,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43066,CHAUDEYROLLES,43430,CHAUDEYROLLES,,44.9461920956,4.19593297286,66,,Chaudeyrolles,Chaudeyrolles,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43067,CHAVANIAC LAFAYETTE,43230,CHAVANIAC LAFAYETTE,,45.1574777226,3.58519893756,67,,Chavaniac-Lafayette,Chavaniac-Lafayette,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43068,CHAZELLES,43300,CHAZELLES,,45.0219434462,3.48997094743,68,,Chazelles,Chazelles,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43069,CHENEREILLES,43190,CHENEREILLES,,45.1369212766,4.23744715977,69,,Chenereilles,Chenereilles,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43070,CHILHAC,43380,CHILHAC,,45.1574337378,3.44059442779,70,,Chilhac,Chilhac,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43071,CHOMELIX,43500,CHOMELIX,,45.2590104905,3.82139785966,71,,Chomelix,Chomelix,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43072,LA CHOMETTE,43230,LA CHOMETTE,,45.2277308638,3.46727338521,72,La,Chomette,La Chomette,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43073,CISTRIERES,43160,CISTRIERES,,45.3298020864,3.6091137956,73,,Cistrières,Cistrières,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43074,COHADE,43100,COHADE,,45.3350700352,3.3706620305,74,,Cohade,Cohade,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43075,COLLAT,43230,COLLAT,,45.2438954518,3.60182440614,75,,Collat,Collat,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43076,CONNANGLES,43160,CONNANGLES,,45.2988796944,3.64574676641,76,,Connangles,Connangles,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43077,COSTAROS,43490,COSTAROS,,44.8920718655,3.84194495017,77,,Costaros,Costaros,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43078,COUBON,43700,COUBON,,44.9998083728,3.92720931438,78,,Coubon,Coubon,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43079,COUTEUGES,43230,COUTEUGES,,45.1845653807,3.49674774,79,,Couteuges,Couteuges,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43080,CRAPONNE SUR ARZON,43500,CRAPONNE SUR ARZON,,45.3318549451,3.85314004519,80,,Craponne-sur-Arzon,Craponne-sur-Arzon,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43082,CRONCE,43300,CRONCE,,45.0823765645,3.36213253048,82,,Cronce,Cronce,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43083,CUBELLES,43170,CUBELLES,,44.9971156776,3.56438618299,83,,Cubelles,Cubelles,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43084,CUSSAC SUR LOIRE,43370,CUSSAC SUR LOIRE,,44.9849156994,3.87473452853,84,,Cussac-sur-Loire,Cussac-sur-Loire,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43085,DESGES,43300,DESGES,,45.0221277538,3.44215460004,85,,Desges,Desges,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43086,DOMEYRAT,43230,DOMEYRAT,,45.2455973792,3.5055844005,86,,Domeyrat,Domeyrat,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43087,DUNIERES,43220,DUNIERES,,45.2146495519,4.34572012446,87,,Dunières,Dunières,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43088,ESPALEM,43450,ESPALEM,,45.3026834057,3.2330870914,88,,Espalem,Espalem,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43089,ESPALY ST MARCEL,43000,ESPALY ST MARCEL,,45.0463892072,3.85796806236,89,,Espaly-Saint-Marcel,Espaly-Saint-Marcel,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43090,ESPLANTAS VAZEILLES,43170,ESPLANTAS VAZEILLES,,44.9033054571,3.54670461918,90,,Esplantas-Vazeilles,Esplantas-Vazeilles,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43090,ESPLANTAS VAZEILLES,43580,ESPLANTAS VAZEILLES,VAZEILLES PRES SAUGUES,44.9033054571,3.54670461918,90,,Esplantas-Vazeilles,Esplantas-Vazeilles,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43091,LES ESTABLES,43150,LES ESTABLES,,44.9067714525,4.14098993726,91,Les,Estables,Les Estables,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43092,FAY SUR LIGNON,43430,FAY SUR LIGNON,,44.9856971989,4.21170048593,92,,Fay-sur-Lignon,Fay-sur-Lignon,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43093,FELINES,43160,FELINES,,45.2648012481,3.7529763308,93,,Félines,Félines,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43094,FERRUSSAC,43300,FERRUSSAC,,45.0855730719,3.40516664941,94,,Ferrussac,Ferrussac,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43095,FIX ST GENEYS,43320,FIX ST GENEYS,,45.1415144345,3.66508807208,95,,Fix-Saint-Geneys,Fix-Saint-Geneys,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43096,FONTANNES,43100,FONTANNES,,45.2888928849,3.43014729837,96,,Fontannes,Fontannes,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43097,FREYCENET LA CUCHE,43150,FREYCENET LA CUCHE,,44.8975326433,4.09393366506,97,,Freycenet-la-Cuche,Freycenet-la-Cuche,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43098,FREYCENET LA TOUR,43150,FREYCENET LA TOUR,,44.9354123707,4.07021557989,98,,Freycenet-la-Tour,Freycenet-la-Tour,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43099,FRUGERES LES MINES,43250,FRUGERES LES MINES,,45.3867198593,3.30887287866,99,,Frugerès-les-Mines,Frugerès-les-Mines,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43100,FRUGIERES LE PIN,43230,FRUGIERES LE PIN,,45.2712815558,3.49959793979,100,,Frugières-le-Pin,Frugières-le-Pin,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43101,GOUDET,43150,GOUDET,,44.8933556241,3.92125459334,101,,Goudet,Goudet,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43102,GRAZAC,43200,GRAZAC,,45.1919622476,4.18410968345,102,,Grazac,Grazac,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43103,GRENIER MONTGON,43450,GRENIER MONTGON,,45.2856647126,3.21042825764,103,,Grenier-Montgon,Grenier-Montgon,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43104,GREZES,43170,GREZES,,44.9105106855,3.46856675689,104,,Grèzes,Grèzes,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43105,JAVAUGUES,43100,JAVAUGUES,,45.2916285035,3.49051315669,105,,Javaugues,Javaugues,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43106,JAX,43230,JAX,,45.1696697646,3.61747634855,106,,Jax,Jax,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43107,JOSAT,43230,JOSAT,,45.214081059,3.61014506252,107,,Josat,Josat,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43108,JULLIANGES,43500,JULLIANGES,,45.3062906797,3.78210420131,108,,Jullianges,Jullianges,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43109,LAFARRE,43490,LAFARRE,,44.8371506318,3.98918688187,109,,Lafarre,Lafarre,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43110,LAMOTHE,43100,LAMOTHE,,45.3149483053,3.42693431019,110,,Lamothe,Lamothe,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43111,LANDOS,43340,LANDOS,,44.8498332614,3.84500846795,111,,Landos,Landos,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43111,LANDOS,43340,LANDOS,LA SAUVETAT,44.8498332614,3.84500846795,111,,Landos,Landos,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43112,LANGEAC,43300,LANGEAC,,45.0822541836,3.47806203807,112,,Langeac,Langeac,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43113,LANTRIAC,43260,LANTRIAC,,44.9893833018,4.00363662506,113,,Lantriac,Lantriac,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43114,LAPTE,43200,LAPTE,,45.1797852011,4.23494194876,114,,Lapte,Lapte,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43115,LAUSSONNE,43150,LAUSSONNE,,44.9669881177,4.05206714167,115,,Laussonne,Laussonne,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43116,LAVAL SUR DOULON,43440,LAVAL SUR DOULON,,45.3548249294,3.5677264526,116,,Laval-sur-Doulon,Laval-sur-Doulon,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43117,LAVAUDIEU,43100,LAVAUDIEU,,45.2627483752,3.45499387355,117,,Lavaudieu,Lavaudieu,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43118,LAVOUTE CHILHAC,43380,LAVOUTE CHILHAC,,45.1559334997,3.41127089302,118,,Lavoûte-Chilhac,Lavoûte-Chilhac,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43119,LAVOUTE SUR LOIRE,43800,LAVOUTE SUR LOIRE,,45.1201169543,3.90303538058,119,,Lavoûte-sur-Loire,Lavoûte-sur-Loire,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43120,LEMPDES SUR ALLAGNON,43410,LEMPDES SUR ALLAGNON,,45.3790751564,3.28028166005,120,,Lempdes-sur-Allagnon,Lempdes-sur-Allagnon,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43121,LEOTOING,43410,LEOTOING,,45.3528081691,3.24026520445,121,,Léotoing,Léotoing,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43122,LISSAC,43350,LISSAC,,45.1342038026,3.75987831256,122,,Lissac,Lissac,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43123,LORLANGES,43360,LORLANGES,,45.3239819521,3.26284919895,123,,Lorlanges,Lorlanges,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43124,LOUDES,43320,LOUDES,,45.0980880485,3.74470915932,124,,Loudes,Loudes,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43125,LUBILHAC,43100,LUBILHAC,,45.243301665,3.25239685673,125,,Lubilhac,Lubilhac,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43126,MALREVERS,43800,MALREVERS,,45.0987649866,3.96694371079,126,,Malrevers,Malrevers,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43127,MALVALETTE,43210,MALVALETTE,,45.3550434728,4.15297814562,127,,Malvalette,Malvalette,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43128,MALVIERES,43160,MALVIERES,,45.3426664598,3.72579573822,128,,Malvières,Malvières,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43129,LE MAS DE TENCE,43190,LE MAS DE TENCE,,45.1189181156,4.36893159168,129,Le,Mas-de-Tence,Le Mas-de-Tence,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43130,MAZET ST VOY,43520,MAZET ST VOY,,45.04385116,4.24003740348,130,,Mazet-Saint-Voy,Mazet-Saint-Voy,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43131,MAZERAT AUROUZE,43230,MAZERAT AUROUZE,,45.1875462661,3.5663385327,131,,Mazerat-Aurouze,Mazerat-Aurouze,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43132,MAZEYRAT D ALLIER,43300,MAZEYRAT D ALLIER,,45.128318526,3.52792705537,132,,Mazeyrat-d'Allier,Mazeyrat-d'Allier,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43132,MAZEYRAT D ALLIER,43300,MAZEYRAT D ALLIER,REILHAC,45.128318526,3.52792705537,132,,Mazeyrat-d'Allier,Mazeyrat-d'Allier,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43132,MAZEYRAT D ALLIER,43300,MAZEYRAT D ALLIER,ST EBLE,45.128318526,3.52792705537,132,,Mazeyrat-d'Allier,Mazeyrat-d'Allier,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43133,MERCOEUR,43100,MERCOEUR,,45.1875792537,3.30357255266,133,,Mercœur,Mercœur,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43134,MEZERES,43800,MEZERES,,45.167914341,4.0135758548,134,,Mézères,Mézères,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43135,LE MONASTIER SUR GAZEILLE,43150,LE MONASTIER SUR GAZEILLE,,44.9345419113,4.00710354299,135,Le,Monastier-sur-Gazeille,Le Monastier-sur-Gazeille,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43136,MONISTROL D ALLIER,43580,MONISTROL D ALLIER,,44.9763403351,3.63605677462,136,,Monistrol-d'Allier,Monistrol-d'Allier,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43137,MONISTROL SUR LOIRE,43120,MONISTROL SUR LOIRE,,45.2906264528,4.18362572726,137,,Monistrol-sur-Loire,Monistrol-sur-Loire,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43138,MONLET,43270,MONLET,,45.2287759624,3.7139620012,138,,Monlet,Monlet,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43139,MONTCLARD,43230,MONTCLARD,,45.2636813655,3.58054318044,139,,Montclard,Montclard,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43140,LE MONTEIL,43700,LE MONTEIL,,45.067076923,3.91276258788,140,Le,Monteil,Le Monteil,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43141,MONTFAUCON EN VELAY,43290,MONTFAUCON EN VELAY,,45.1844564784,4.32318621334,141,,Montfaucon-en-Velay,Montfaucon-en-Velay,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43142,MONTREGARD,43290,MONTREGARD,,45.1595314738,4.35494636429,142,,Montregard,Montregard,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43143,MONTUSCLAT,43260,MONTUSCLAT,,45.0097274069,4.11399485944,143,,Montusclat,Montusclat,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43144,MOUDEYRES,43150,MOUDEYRES,,44.9490292294,4.10321682269,144,,Moudeyres,Moudeyres,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43145,OUIDES,43510,OUIDES,,44.9060432903,3.7403767146,145,,Ouides,Ouides,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43147,PAULHAC,43100,PAULHAC,,45.2953490869,3.34066049847,147,,Paulhac,Paulhac,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43148,PAULHAGUET,43230,PAULHAGUET,,45.2181062254,3.51504547862,148,,Paulhaguet,Paulhaguet,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43149,PEBRAC,43300,PEBRAC,,45.0291359693,3.52107311207,149,,Pébrac,Pébrac,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43150,LE PERTUIS,43200,LE PERTUIS,,45.1022318676,4.06426000884,150,Le,Pertuis,Le Pertuis,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43151,PINOLS,43300,PINOLS,,45.0393522436,3.38513092079,151,,Pinols,Pinols,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43152,POLIGNAC,43000,POLIGNAC,,45.0800346307,3.8608598463,152,,Polignac,Polignac,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43153,PONT SALOMON,43330,PONT SALOMON,,45.3352775627,4.24335306756,153,,Pont-Salomon,Pont-Salomon,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43154,PRADELLES,43420,PRADELLES,,44.7650507799,3.87177102948,154,,Pradelles,Pradelles,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43155,PRADES,43300,PRADES,,45.0245718989,3.59400900107,155,,Prades,Prades,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43156,PRESAILLES,43150,PRESAILLES,,44.890701673,4.04430480363,156,,Présailles,Présailles,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43157,LE PUY EN VELAY,43000,LE PUY EN VELAY,,45.0276366659,3.89535229067,157,Le,Puy-en-Velay,Le Puy-en-Velay,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43158,QUEYRIERES,43260,QUEYRIERES,,45.0703483996,4.10438606163,158,,Queyrières,Queyrières,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43159,RAUCOULES,43290,RAUCOULES,,45.1915194359,4.28286048078,159,,Raucoules,Raucoules,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43160,RAURET,43340,RAURET,,44.8046724953,3.80135032681,160,,Rauret,Rauret,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43162,RETOURNAC,43130,RETOURNAC,,45.2190833833,4.0281941736,162,,Retournac,Retournac,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43163,RIOTORD,43220,RIOTORD,,45.2349277703,4.42239825506,163,,Riotord,Riotord,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43164,ROCHE EN REGNIER,43810,ROCHE EN REGNIER,,45.2332759866,3.94803768811,164,,Roche-en-Régnier,Roche-en-Régnier,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43165,ROSIERES,43800,ROSIERES,,45.1310458657,4.00299232791,165,,Rosières,Rosières,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43166,ST ANDRE DE CHALENCON,43130,ST ANDRE DE CHALENCON,,45.2750942654,3.95362357307,166,,Saint-André-de-Chalencon,Saint-André-de-Chalencon,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43167,ST ARCONS D ALLIER,43300,ST ARCONS D ALLIER,,45.0843286639,3.56334774649,167,,Saint-Arcons-d'Allier,Saint-Arcons-d'Allier,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43168,ST ARCONS DE BARGES,43420,ST ARCONS DE BARGES,,44.8335033818,3.92431470071,168,,Saint-Arcons-de-Barges,Saint-Arcons-de-Barges,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43169,ST AUSTREMOINE,43380,ST AUSTREMOINE,,45.1191226529,3.35731362857,169,,Saint-Austremoine,Saint-Austremoine,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43170,ST BEAUZIRE,43100,ST BEAUZIRE,,45.2770787888,3.28293781034,170,,Saint-Beauzire,Saint-Beauzire,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43171,ST BERAIN,43300,ST BERAIN,,45.0301711811,3.62996384671,171,,Saint-Bérain,Saint-Bérain,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43172,ST BONNET LE FROID,43290,ST BONNET LE FROID,,45.143785338,4.43283781114,172,,Saint-Bonnet-le-Froid,Saint-Bonnet-le-Froid,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43173,ST CHRISTOPHE D ALLIER,43340,ST CHRISTOPHE D ALLIER,,44.8488448376,3.7017331101,173,,Saint-Christophe-d'Allier,Saint-Christophe-d'Allier,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43174,ST CHRISTOPHE SUR DOLAISON,43370,ST CHRISTOPHE SUR DOLAISON,,44.9899054151,3.82630657587,174,,Saint-Christophe-sur-Dolaison,Saint-Christophe-sur-Dolaison,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43175,ST CIRGUES,43380,ST CIRGUES,,45.1344047659,3.38998443053,175,,Saint-Cirgues,Saint-Cirgues,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43176,ST DIDIER D ALLIER,43580,ST DIDIER D ALLIER,,44.9797771177,3.69651684323,176,,Saint-Didier-d'Allier,Saint-Didier-d'Allier,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43177,ST DIDIER EN VELAY,43140,ST DIDIER EN VELAY,,45.3102257705,4.28479871103,177,,Saint-Didier-en-Velay,Saint-Didier-en-Velay,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43178,ST DIDIER SUR DOULON,43440,ST DIDIER SUR DOULON,,45.3048407835,3.54764770855,178,,Saint-Didier-sur-Doulon,Saint-Didier-sur-Doulon,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43180,ST ETIENNE DU VIGAN,43420,ST ETIENNE DU VIGAN,,44.7892500054,3.83546695032,180,,Saint-Étienne-du-Vigan,Saint-Étienne-du-Vigan,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43181,ST ETIENNE LARDEYROL,43260,ST ETIENNE LARDEYROL,,45.0855006006,4.00620064069,181,,Saint-Étienne-Lardeyrol,Saint-Étienne-Lardeyrol,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43182,ST ETIENNE SUR BLESLE,43450,ST ETIENNE SUR BLESLE,,45.3041441888,3.12573075931,182,,Saint-Étienne-sur-Blesle,Saint-Étienne-sur-Blesle,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43183,STE EUGENIE DE VILLENEUVE,43230,STE EUGENIE DE VILLENEUVE,,45.1418528547,3.63036535323,183,,Sainte-Eugénie-de-Villeneuve,Sainte-Eugénie-de-Villeneuve,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43184,ST FERREOL D AUROURE,43330,ST FERREOL D AUROURE,,45.3569721044,4.25874245507,184,,Saint-Ferréol-d'Auroure,Saint-Ferréol-d'Auroure,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43185,STE FLORINE,43250,STE FLORINE,,45.4018591959,3.30958888923,185,,Sainte-Florine,Sainte-Florine,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43186,ST FRONT,43550,ST FRONT,,44.9731979795,4.13915382699,186,,Saint-Front,Saint-Front,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43187,ST GENEYS PRES ST PAULIEN,43350,ST GENEYS PRES ST PAULIEN,,45.1738034245,3.83061970488,187,,Saint-Geneys-près-Saint-Paulien,Saint-Geneys-près-Saint-Paulien,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43188,ST GEORGES D AURAC,43230,ST GEORGES D AURAC,,45.1592040018,3.55160746234,188,,Saint-Georges-d'Aurac,Saint-Georges-d'Aurac,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43189,ST GEORGES LAGRICOL,43500,ST GEORGES LAGRICOL,,45.3036551402,3.88836615913,189,,Saint-Georges-Lagricol,Saint-Georges-Lagricol,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43190,ST GERMAIN LAPRADE,43700,ST GERMAIN LAPRADE,,45.0328118142,3.97768212024,190,,Saint-Germain-Laprade,Saint-Germain-Laprade,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43191,ST GERON,43360,ST GERON,,45.3353129399,3.29409683606,191,,Saint-Géron,Saint-Géron,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43192,ST HAON,43340,ST HAON,,44.8551736176,3.76182411764,192,,Saint-Haon,Saint-Haon,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43193,ST HILAIRE,43390,ST HILAIRE,,45.3812027822,3.43912460224,193,,Saint-Hilaire,Saint-Hilaire,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43194,ST HOSTIEN,43260,ST HOSTIEN,,45.0776971566,4.04872632629,194,,Saint-Hostien,Saint-Hostien,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43195,ST ILPIZE,43380,ST ILPIZE,,45.2003952069,3.40653237209,195,,Saint-Ilpize,Saint-Ilpize,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43196,ST JEAN D AUBRIGOUX,43500,ST JEAN D AUBRIGOUX,,45.3634716974,3.81088454906,196,,Saint-Jean-d'Aubrigoux,Saint-Jean-d'Aubrigoux,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43197,ST JEAN DE NAY,43320,ST JEAN DE NAY,,45.0653883769,3.68972683982,197,,Saint-Jean-de-Nay,Saint-Jean-de-Nay,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43198,ST JEAN LACHALM,43510,ST JEAN LACHALM,,44.9424426092,3.72370302131,198,,Saint-Jean-Lachalm,Saint-Jean-Lachalm,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43199,ST JEURES,43200,ST JEURES,,45.1042127113,4.21151710813,199,,Saint-Jeures,Saint-Jeures,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43200,ST JULIEN CHAPTEUIL,43260,ST JULIEN CHAPTEUIL,,45.0298500492,4.07958442261,200,,Saint-Julien-Chapteuil,Saint-Julien-Chapteuil,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43201,ST JULIEN D ANCE,43500,ST JULIEN D ANCE,,45.3060820741,3.92419181239,201,,Saint-Julien-d'Ance,Saint-Julien-d'Ance,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43202,ST JULIEN DES CHAZES,43300,ST JULIEN DES CHAZES,,45.0480264349,3.57559827157,202,,Saint-Julien-des-Chazes,Saint-Julien-des-Chazes,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43203,ST JULIEN DU PINET,43200,ST JULIEN DU PINET,,45.1468584191,4.04767763095,203,,Saint-Julien-du-Pinet,Saint-Julien-du-Pinet,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43204,ST JULIEN MOLHESABATE,43220,ST JULIEN MOLHESABATE,,45.1899135019,4.43701562195,204,,Saint-Julien-Molhesabate,Saint-Julien-Molhesabate,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43205,ST JUST MALMONT,43240,ST JUST MALMONT,,45.3464620965,4.32210479945,205,,Saint-Just-Malmont,Saint-Just-Malmont,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43206,ST JUST PRES BRIOUDE,43100,ST JUST PRES BRIOUDE,,45.2327467078,3.3312524212,206,,Saint-Just-près-Brioude,Saint-Just-près-Brioude,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43207,ST LAURENT CHABREUGES,43100,ST LAURENT CHABREUGES,,45.2768738182,3.34696183552,207,,Saint-Laurent-Chabreuges,Saint-Laurent-Chabreuges,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43208,STE MARGUERITE,43230,STE MARGUERITE,,45.2105754797,3.58257296293,208,,Sainte-Marguerite,Sainte-Marguerite,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43210,ST MARTIN DE FUGERES,43150,ST MARTIN DE FUGERES,,44.9073562308,3.94556277638,210,,Saint-Martin-de-Fugères,Saint-Martin-de-Fugères,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43211,ST MAURICE DE LIGNON,43200,ST MAURICE DE LIGNON,,45.2200624958,4.1349880615,211,,Saint-Maurice-de-Lignon,Saint-Maurice-de-Lignon,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43212,ST PAL DE CHALENCON,43500,ST PAL DE CHALENCON,,45.3474785194,3.96287922674,212,,Saint-Pal-de-Chalencon,Saint-Pal-de-Chalencon,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43213,ST PAL DE MONS,43620,ST PAL DE MONS,,45.2420793243,4.28312816043,213,,Saint-Pal-de-Mons,Saint-Pal-de-Mons,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43214,ST PAL DE SENOUIRE,43160,ST PAL DE SENOUIRE,,45.2575508782,3.64684459973,214,,Saint-Pal-de-Senouire,Saint-Pal-de-Senouire,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43215,ST PAUL DE TARTAS,43420,ST PAUL DE TARTAS,,44.7990652286,3.89356890721,215,,Saint-Paul-de-Tartas,Saint-Paul-de-Tartas,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43216,ST PAULIEN,43350,ST PAULIEN,,45.135004008,3.82064144653,216,,Saint-Paulien,Saint-Paulien,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43217,ST PIERRE DU CHAMP,43810,ST PIERRE DU CHAMP,,45.2465337146,3.88792807023,217,,Saint-Pierre-du-Champ,Saint-Pierre-du-Champ,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43218,ST PIERRE EYNAC,43260,ST PIERRE EYNAC,,45.0467218155,4.02721222555,218,,Saint-Pierre-Eynac,Saint-Pierre-Eynac,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43219,ST PREJET ARMANDON,43230,ST PREJET ARMANDON,,45.2494259464,3.55233411018,219,,Saint-Préjet-Armandon,Saint-Préjet-Armandon,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43220,ST PREJET D ALLIER,43580,ST PREJET D ALLIER,,44.9200322739,3.6252137957,220,,Saint-Préjet-d'Allier,Saint-Préjet-d'Allier,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43221,ST PRIVAT D ALLIER,43580,ST PRIVAT D ALLIER,,45.0017025965,3.66961658789,221,,Saint-Privat-d'Allier,Saint-Privat-d'Allier,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43222,ST PRIVAT DU DRAGON,43380,ST PRIVAT DU DRAGON,,45.194796266,3.44322904217,222,,Saint-Privat-du-Dragon,Saint-Privat-du-Dragon,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43223,ST ROMAIN LACHALM,43620,ST ROMAIN LACHALM,,45.2650235989,4.33989066795,223,,Saint-Romain-Lachalm,Saint-Romain-Lachalm,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43224,STE SIGOLENE,43600,STE SIGOLENE,,45.2470426213,4.22851598334,224,,Sainte-Sigolène,Sainte-Sigolène,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43225,ST VENERAND,43580,ST VENERAND,,44.8753908292,3.67498479809,225,,Saint-Vénérand,Saint-Vénérand,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43226,ST VERT,43440,ST VERT,,45.3769366563,3.54177146046,226,,Saint-Vert,Saint-Vert,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43227,ST VICTOR MALESCOURS,43140,ST VICTOR MALESCOURS,,45.2943304706,4.32399276443,227,,Saint-Victor-Malescours,Saint-Victor-Malescours,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43228,ST VICTOR SUR ARLANC,43500,ST VICTOR SUR ARLANC,,45.3365023071,3.77629585977,228,,Saint-Victor-sur-Arlanc,Saint-Victor-sur-Arlanc,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43229,ST VIDAL,43320,ST VIDAL,,45.0748772285,3.79903420873,229,,Saint-Vidal,Saint-Vidal,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43230,ST VINCENT,43800,ST VINCENT,,45.1521679091,3.89844373867,230,,Saint-Vincent,Saint-Vincent,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43231,SALETTES,43150,SALETTES,,44.866504818,3.98094125791,231,,Salettes,Salettes,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43232,SALZUIT,43230,SALZUIT,,45.2095833426,3.48272203191,232,,Salzuit,Salzuit,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43233,SANSSAC L EGLISE,43320,SANSSAC L EGLISE,,45.0461886154,3.7913435493,233,,Sanssac-l'Église,Sanssac-l'Église,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43234,SAUGUES,43170,SAUGUES,,44.9482034569,3.53883236387,234,,Saugues,Saugues,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43236,LA SEAUVE SUR SEMENE,43140,LA SEAUVE SUR SEMENE,,45.2951430004,4.24948806021,236,La,Séauve-sur-Semène,La Séauve-sur-Semène,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43237,SEMBADEL,43160,SEMBADEL,,45.2716430233,3.69436180182,237,,Sembadel,Sembadel,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43238,SENEUJOLS,43510,SENEUJOLS,,44.9541814501,3.78325926498,238,,Séneujols,Séneujols,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43239,SIAUGUES STE MARIE,43300,SIAUGUES STE MARIE,,45.0787632702,3.62714763448,239,,Siaugues-Sainte-Marie,Siaugues-Sainte-Marie,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43239,SIAUGUES STE MARIE,43300,SIAUGUES STE MARIE,STE MARIE DES CHAZES,45.0787632702,3.62714763448,239,,Siaugues-Sainte-Marie,Siaugues-Sainte-Marie,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43240,SOLIGNAC SOUS ROCHE,43130,SOLIGNAC SOUS ROCHE,,45.2521425669,3.99655287323,240,,Solignac-sous-Roche,Solignac-sous-Roche,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43241,SOLIGNAC SUR LOIRE,43370,SOLIGNAC SUR LOIRE,,44.9577428375,3.88118652171,241,,Solignac-sur-Loire,Solignac-sur-Loire,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43242,TAILHAC,43300,TAILHAC,,45.0498915029,3.4568585058,242,,Tailhac,Tailhac,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43244,TENCE,43190,TENCE,,45.1133166621,4.3002040908,244,,Tence,Tence,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43245,THORAS,43170,THORAS,,44.859407492,3.56070986719,245,,Thoras,Thoras,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43245,THORAS,43580,THORAS,CROISANCES,44.859407492,3.56070986719,245,,Thoras,Thoras,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43246,TIRANGES,43530,TIRANGES,,45.2875962706,4.01198563489,246,,Tiranges,Tiranges,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43247,TORSIAC,43450,TORSIAC,,45.3520799718,3.19806819425,247,,Torsiac,Torsiac,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43249,VALPRIVAS,43210,VALPRIVAS,,45.3212577135,4.04688215633,249,,Valprivas,Valprivas,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43250,VALS LE CHASTEL,43230,VALS LE CHASTEL,,45.2714155146,3.52832787466,250,,Vals-le-Chastel,Vals-le-Chastel,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43251,VALS PRES LE PUY,43750,VALS PRES LE PUY,,45.0239274743,3.86582485038,251,,Vals-près-le-Puy,Vals-près-le-Puy,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43252,VARENNES ST HONORAT,43270,VARENNES ST HONORAT,,45.1773120745,3.64466347824,252,,Varennes-Saint-Honorat,Varennes-Saint-Honorat,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43253,LES VASTRES,43430,LES VASTRES,,44.9902033673,4.26962911111,253,Les,Vastres,Les Vastres,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43254,VAZEILLES LIMANDRE,43320,VAZEILLES LIMANDRE,,45.1168091286,3.69404145976,254,,Vazeilles-Limandre,Vazeilles-Limandre,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43256,VENTEUGES,43170,VENTEUGES,,44.9815131763,3.48283306813,256,,Venteuges,Venteuges,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43257,VERGEZAC,43320,VERGEZAC,,45.0324468442,3.73446025932,257,,Vergezac,Vergezac,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43258,VERGONGHEON,43360,VERGONGHEON,,45.3722827688,3.33088237226,258,,Vergongheon,Vergongheon,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43259,VERNASSAL,43270,VERNASSAL,,45.1465444602,3.71122620061,259,,Vernassal,Vernassal,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43260,LE VERNET,43320,LE VERNET,,45.0385735501,3.66238494498,260,Le,Vernet,Le Vernet,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43261,VEZEZOUX,43390,VEZEZOUX,,45.4052201383,3.34832487866,261,,Vézézoux,Vézézoux,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43262,VIEILLE BRIOUDE,43100,VIEILLE BRIOUDE,,45.2509891916,3.40398248919,262,,Vieille-Brioude,Vieille-Brioude,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43263,VIELPRAT,43490,VIELPRAT,,44.8507243693,3.95129069214,263,,Vielprat,Vielprat,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43264,VILLENEUVE D ALLIER,43380,VILLENEUVE D ALLIER,,45.1942784893,3.37774101314,264,,Villeneuve-d'Allier,Villeneuve-d'Allier,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43265,LES VILLETTES,43600,LES VILLETTES,,45.2404575715,4.17878792262,265,Les,Villettes,Les Villettes,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43267,VOREY,43800,VOREY,,45.1929802448,3.90530679663,267,,Vorey,Vorey,43,Haute-Loire,84,Auvergne-Rhône-Alpes
43268,YSSINGEAUX,43200,YSSINGEAUX,,45.1393761299,4.13104937731,268,,Yssingeaux,Yssingeaux,43,Haute-Loire,84,Auvergne-Rhône-Alpes
44001,ABBARETZ,44170,ABBARETZ,,47.5560384833,-1.49327262094,1,,Abbaretz,Abbaretz,44,Loire-Atlantique,52,Pays de la Loire
44002,AIGREFEUILLE SUR MAINE,44140,AIGREFEUILLE SUR MAINE,,47.0719256611,-1.41556455507,2,,Aigrefeuille-sur-Maine,Aigrefeuille-sur-Maine,44,Loire-Atlantique,52,Pays de la Loire
44003,ANCENIS,44150,ANCENIS,,47.3833270209,-1.18241880879,3,,Ancenis,Ancenis,44,Loire-Atlantique,52,Pays de la Loire
44005,CHAUMES EN RETZ,44320,CHAUMES EN RETZ,ARTHON EN RETZ,47.1592162214,-1.95412512421,5,,Chaumes-en-Retz,Chaumes-en-Retz,44,Loire-Atlantique,52,Pays de la Loire
44005,CHAUMES EN RETZ,44320,CHAUMES EN RETZ,LA SICAUDAIS,47.1592162214,-1.95412512421,5,,Chaumes-en-Retz,Chaumes-en-Retz,44,Loire-Atlantique,52,Pays de la Loire
44005,CHAUMES EN RETZ,44680,CHAUMES EN RETZ,CHEMERE,47.1592162214,-1.95412512421,5,,Chaumes-en-Retz,Chaumes-en-Retz,44,Loire-Atlantique,52,Pays de la Loire
44006,ASSERAC,44410,ASSERAC,,47.4379772072,-2.41272577216,6,,Assérac,Assérac,44,Loire-Atlantique,52,Pays de la Loire
44006,ASSERAC,44410,ASSERAC,PONT D ARMES,47.4379772072,-2.41272577216,6,,Assérac,Assérac,44,Loire-Atlantique,52,Pays de la Loire
44007,AVESSAC,44460,AVESSAC,,47.635436304,-1.97050871505,7,,Avessac,Avessac,44,Loire-Atlantique,52,Pays de la Loire
44009,BASSE GOULAINE,44115,BASSE GOULAINE,,47.2120137336,-1.46246852595,9,,Basse-Goulaine,Basse-Goulaine,44,Loire-Atlantique,52,Pays de la Loire
44010,BATZ SUR MER,44740,BATZ SUR MER,,47.2844487793,-2.474374647,10,,Batz-sur-Mer,Batz-sur-Mer,44,Loire-Atlantique,52,Pays de la Loire
44012,LA BERNERIE EN RETZ,44760,LA BERNERIE EN RETZ,,47.0856704602,-2.03170205961,12,La,Bernerie-en-Retz,La Bernerie-en-Retz,44,Loire-Atlantique,52,Pays de la Loire
44013,BESNE,44160,BESNE,,47.3800629578,-2.07409913989,13,,Besné,Besné,44,Loire-Atlantique,52,Pays de la Loire
44014,LE BIGNON,44140,LE BIGNON,,47.1004098153,-1.50942309254,14,Le,Bignon,Le Bignon,44,Loire-Atlantique,52,Pays de la Loire
44015,BLAIN,44130,BLAIN,,47.4628210409,-1.76789479614,15,,Blain,Blain,44,Loire-Atlantique,52,Pays de la Loire
44015,BLAIN,44130,BLAIN,ST EMILIEN DE BLAIN,47.4628210409,-1.76789479614,15,,Blain,Blain,44,Loire-Atlantique,52,Pays de la Loire
44015,BLAIN,44130,BLAIN,ST OMER DE BLAIN,47.4628210409,-1.76789479614,15,,Blain,Blain,44,Loire-Atlantique,52,Pays de la Loire
44016,LA BOISSIERE DU DORE,44430,LA BOISSIERE DU DORE,,47.2350761826,-1.20383839178,16,La,Boissière-du-Doré,La Boissière-du-Doré,44,Loire-Atlantique,52,Pays de la Loire
44017,BONNOEUVRE,44540,BONNOEUVRE,,47.5197897111,-1.2316486796,17,,Bonnœuvre,Bonnœuvre,44,Loire-Atlantique,52,Pays de la Loire
44018,BOUAYE,44830,BOUAYE,,47.1473396882,-1.6725437059,18,,Bouaye,Bouaye,44,Loire-Atlantique,52,Pays de la Loire
44019,BOUEE,44260,BOUEE,,47.304903513,-1.91266951692,19,,Bouée,Bouée,44,Loire-Atlantique,52,Pays de la Loire
44020,BOUGUENAIS,44340,BOUGUENAIS,,47.1709061678,-1.61739752858,20,,Bouguenais,Bouguenais,44,Loire-Atlantique,52,Pays de la Loire
44020,BOUGUENAIS,44340,BOUGUENAIS,LES COUETS,47.1709061678,-1.61739752858,20,,Bouguenais,Bouguenais,44,Loire-Atlantique,52,Pays de la Loire
44021,VILLENEUVE EN RETZ,44580,VILLENEUVE EN RETZ,BOURGNEUF EN RETZ,47.0381472853,-1.92586734325,21,,Villeneuve-en-Retz,Villeneuve-en-Retz,44,Loire-Atlantique,52,Pays de la Loire
44021,VILLENEUVE EN RETZ,44580,VILLENEUVE EN RETZ,FRESNAY EN RETZ,47.0381472853,-1.92586734325,21,,Villeneuve-en-Retz,Villeneuve-en-Retz,44,Loire-Atlantique,52,Pays de la Loire
44021,VILLENEUVE EN RETZ,44580,VILLENEUVE EN RETZ,ST CYR EN RETZ,47.0381472853,-1.92586734325,21,,Villeneuve-en-Retz,Villeneuve-en-Retz,44,Loire-Atlantique,52,Pays de la Loire
44022,BOUSSAY,44190,BOUSSAY,,47.053455839,-1.16316059855,22,,Boussay,Boussay,44,Loire-Atlantique,52,Pays de la Loire
44023,BOUVRON,44130,BOUVRON,,47.4171698448,-1.87739194688,23,,Bouvron,Bouvron,44,Loire-Atlantique,52,Pays de la Loire
44024,BRAINS,44830,BRAINS,,47.1701591492,-1.72606479691,24,,Brains,Brains,44,Loire-Atlantique,52,Pays de la Loire
44025,CAMPBON,44750,CAMPBON,,47.4160977851,-1.96776519689,25,,Campbon,Campbon,44,Loire-Atlantique,52,Pays de la Loire
44026,CARQUEFOU,44470,CARQUEFOU,,47.3017191833,-1.47277200095,26,,Carquefou,Carquefou,44,Loire-Atlantique,52,Pays de la Loire
44027,CASSON,44390,CASSON,,47.3920308092,-1.55573718805,27,,Casson,Casson,44,Loire-Atlantique,52,Pays de la Loire
44028,LE CELLIER,44850,LE CELLIER,,47.3374810886,-1.35950738339,28,Le,Cellier,Le Cellier,44,Loire-Atlantique,52,Pays de la Loire
44029,DIVATTE SUR LOIRE,44450,DIVATTE SUR LOIRE,BARBECHAT,47.2808739279,-1.34947633422,29,,Divatte-sur-Loire,Divatte-sur-Loire,44,Loire-Atlantique,52,Pays de la Loire
44029,DIVATTE SUR LOIRE,44450,DIVATTE SUR LOIRE,LA CHAPELLE BASSE MER,47.2808739279,-1.34947633422,29,,Divatte-sur-Loire,Divatte-sur-Loire,44,Loire-Atlantique,52,Pays de la Loire
44030,LA CHAPELLE DES MARAIS,44410,LA CHAPELLE DES MARAIS,,47.4371350794,-2.24565131355,30,La,Chapelle-des-Marais,La Chapelle-des-Marais,44,Loire-Atlantique,52,Pays de la Loire
44031,LA CHAPELLE GLAIN,44670,LA CHAPELLE GLAIN,,47.6202560031,-1.19540234544,31,La,Chapelle-Glain,La Chapelle-Glain,44,Loire-Atlantique,52,Pays de la Loire
44032,LA CHAPELLE HEULIN,44330,LA CHAPELLE HEULIN,,47.1722913827,-1.34993806222,32,La,Chapelle-Heulin,La Chapelle-Heulin,44,Loire-Atlantique,52,Pays de la Loire
44033,LA CHAPELLE LAUNAY,44260,LA CHAPELLE LAUNAY,,47.3530614373,-1.98191491086,33,La,Chapelle-Launay,La Chapelle-Launay,44,Loire-Atlantique,52,Pays de la Loire
44035,LA CHAPELLE SUR ERDRE,44240,LA CHAPELLE SUR ERDRE,,47.3035629617,-1.56078596697,35,La,Chapelle-sur-Erdre,La Chapelle-sur-Erdre,44,Loire-Atlantique,52,Pays de la Loire
44036,CHATEAUBRIANT,44110,CHATEAUBRIANT,,47.7221583524,-1.38664809937,36,,Châteaubriant,Châteaubriant,44,Loire-Atlantique,52,Pays de la Loire
44037,CHATEAU THEBAUD,44690,CHATEAU THEBAUD,,47.1158057933,-1.43844838155,37,,Château-Thébaud,Château-Thébaud,44,Loire-Atlantique,52,Pays de la Loire
44038,CHAUVE,44320,CHAUVE,,47.1588661346,-2.01200123653,38,,Chauvé,Chauvé,44,Loire-Atlantique,52,Pays de la Loire
44039,CHEIX EN RETZ,44640,CHEIX EN RETZ,,47.1811170912,-1.79840995834,39,,Cheix-en-Retz,Cheix-en-Retz,44,Loire-Atlantique,52,Pays de la Loire
44041,LA CHEVROLIERE,44118,LA CHEVROLIERE,,47.0891104813,-1.596195347,41,La,Chevrolière,La Chevrolière,44,Loire-Atlantique,52,Pays de la Loire
44041,LA CHEVROLIERE,44118,LA CHEVROLIERE,PASSAY,47.0891104813,-1.596195347,41,La,Chevrolière,La Chevrolière,44,Loire-Atlantique,52,Pays de la Loire
44043,CLISSON,44190,CLISSON,,47.097896998,-1.27042598067,43,,Clisson,Clisson,44,Loire-Atlantique,52,Pays de la Loire
44044,CONQUEREUIL,44290,CONQUEREUIL,,47.6488519078,-1.76222816396,44,,Conquereuil,Conquereuil,44,Loire-Atlantique,52,Pays de la Loire
44045,CORDEMAIS,44360,CORDEMAIS,,47.2968036073,-1.8357108105,45,,Cordemais,Cordemais,44,Loire-Atlantique,52,Pays de la Loire
44046,CORSEPT,44560,CORSEPT,,47.2600100383,-2.09386404628,46,,Corsept,Corsept,44,Loire-Atlantique,52,Pays de la Loire
44047,COUERON,44220,COUERON,,47.2309985029,-1.72929002573,47,,Couëron,Couëron,44,Loire-Atlantique,52,Pays de la Loire
44047,COUERON,44220,COUERON,LA CHABOSSIERE,47.2309985029,-1.72929002573,47,,Couëron,Couëron,44,Loire-Atlantique,52,Pays de la Loire
44048,COUFFE,44521,COUFFE,,47.3885628195,-1.29271841264,48,,Couffé,Couffé,44,Loire-Atlantique,52,Pays de la Loire
44049,LE CROISIC,44490,LE CROISIC,,47.2922743449,-2.52277844422,49,Le,Croisic,Le Croisic,44,Loire-Atlantique,52,Pays de la Loire
44050,CROSSAC,44160,CROSSAC,,47.4044611195,-2.15336667516,50,,Crossac,Crossac,44,Loire-Atlantique,52,Pays de la Loire
44051,DERVAL,44590,DERVAL,,47.6576802483,-1.67772975598,51,,Derval,Derval,44,Loire-Atlantique,52,Pays de la Loire
44052,DONGES,44480,DONGES,,47.3392097617,-2.0741602335,52,,Donges,Donges,44,Loire-Atlantique,52,Pays de la Loire
44053,DREFFEAC,44530,DREFFEAC,,47.4684630967,-2.05940448032,53,,Drefféac,Drefféac,44,Loire-Atlantique,52,Pays de la Loire
44054,ERBRAY,44110,ERBRAY,,47.6690929055,-1.32185881383,54,,Erbray,Erbray,44,Loire-Atlantique,52,Pays de la Loire
44055,LA BAULE ESCOUBLAC,44500,LA BAULE,,47.2909720179,-2.3538291745,55,La,Baule-Escoublac,La Baule-Escoublac,44,Loire-Atlantique,52,Pays de la Loire
44056,FAY DE BRETAGNE,44130,FAY DE BRETAGNE,,47.3905699563,-1.79530261946,56,,Fay-de-Bretagne,Fay-de-Bretagne,44,Loire-Atlantique,52,Pays de la Loire
44057,FEGREAC,44460,FEGREAC,,47.5805909749,-2.02458187409,57,,Fégréac,Fégréac,44,Loire-Atlantique,52,Pays de la Loire
44058,FERCE,44660,FERCE,,47.8000443229,-1.38761214981,58,,Fercé,Fercé,44,Loire-Atlantique,52,Pays de la Loire
44061,FROSSAY,44320,FROSSAY,,47.2455073631,-1.93405826107,61,,Frossay,Frossay,44,Loire-Atlantique,52,Pays de la Loire
44062,LE GAVRE,44130,LE GAVRE,,47.5279203716,-1.79412055817,62,Le,Gâvre,Le Gâvre,44,Loire-Atlantique,52,Pays de la Loire
44063,GETIGNE,44190,GETIGNE,,47.0812318041,-1.21795705627,63,,Gétigné,Gétigné,44,Loire-Atlantique,52,Pays de la Loire
44064,GORGES,44190,GORGES,,47.1044539402,-1.31077198119,64,,Gorges,Gorges,44,Loire-Atlantique,52,Pays de la Loire
44065,GRAND AUVERNE,44520,GRAND AUVERNE,,47.5758381027,-1.31165475959,65,,Grand-Auverné,Grand-Auverné,44,Loire-Atlantique,52,Pays de la Loire
44066,GRANDCHAMPS DES FONTAINES,44119,GRANDCHAMPS DES FONTAINES,,47.3606796643,-1.61754475868,66,,Grandchamps-des-Fontaines,Grandchamps-des-Fontaines,44,Loire-Atlantique,52,Pays de la Loire
44067,GUEMENE PENFAO,44290,GUEMENE PENFAO,,47.6278891238,-1.83077595154,67,,Guémené-Penfao,Guémené-Penfao,44,Loire-Atlantique,52,Pays de la Loire
44067,GUEMENE PENFAO,44290,GUEMENE PENFAO,BESLE SUR VILAINE,47.6278891238,-1.83077595154,67,,Guémené-Penfao,Guémené-Penfao,44,Loire-Atlantique,52,Pays de la Loire
44067,GUEMENE PENFAO,44290,GUEMENE PENFAO,GUENOUVRY,47.6278891238,-1.83077595154,67,,Guémené-Penfao,Guémené-Penfao,44,Loire-Atlantique,52,Pays de la Loire
44068,GUENROUET,44530,GUENROUET,,47.5034137503,-1.95561601434,68,,Guenrouet,Guenrouet,44,Loire-Atlantique,52,Pays de la Loire
44068,GUENROUET,44530,GUENROUET,NOTRE DAME DE GRACE,47.5034137503,-1.95561601434,68,,Guenrouet,Guenrouet,44,Loire-Atlantique,52,Pays de la Loire
44069,GUERANDE,44350,GUERANDE,,47.3313320913,-2.41703658813,69,,Guérande,Guérande,44,Loire-Atlantique,52,Pays de la Loire
44069,GUERANDE,44350,GUERANDE,CLIS,47.3313320913,-2.41703658813,69,,Guérande,Guérande,44,Loire-Atlantique,52,Pays de la Loire
44069,GUERANDE,44350,GUERANDE,LA MADELEINE,47.3313320913,-2.41703658813,69,,Guérande,Guérande,44,Loire-Atlantique,52,Pays de la Loire
44069,GUERANDE,44350,GUERANDE,SAILLE,47.3313320913,-2.41703658813,69,,Guérande,Guérande,44,Loire-Atlantique,52,Pays de la Loire
44070,LA HAIE FOUASSIERE,44690,LA HAIE FOUASSIERE,,47.1613180471,-1.40043269231,70,La,Haie-Fouassière,La Haie-Fouassière,44,Loire-Atlantique,52,Pays de la Loire
44071,HAUTE GOULAINE,44115,HAUTE GOULAINE,,47.1960138107,-1.41049118184,71,,Haute-Goulaine,Haute-Goulaine,44,Loire-Atlantique,52,Pays de la Loire
44072,HERBIGNAC,44410,HERBIGNAC,,47.4472411995,-2.31338629512,72,,Herbignac,Herbignac,44,Loire-Atlantique,52,Pays de la Loire
44072,HERBIGNAC,44410,HERBIGNAC,POMPAS,47.4472411995,-2.31338629512,72,,Herbignac,Herbignac,44,Loire-Atlantique,52,Pays de la Loire
44073,HERIC,44810,HERIC,,47.4207936826,-1.63717757742,73,,Héric,Héric,44,Loire-Atlantique,52,Pays de la Loire
44074,INDRE,44610,INDRE,,47.1985008197,-1.6717810311,74,,Indre,Indre,44,Loire-Atlantique,52,Pays de la Loire
44074,INDRE,44610,INDRE,BASSE INDRE,47.1985008197,-1.6717810311,74,,Indre,Indre,44,Loire-Atlantique,52,Pays de la Loire
44074,INDRE,44610,INDRE,HAUTE INDRE,47.1985008197,-1.6717810311,74,,Indre,Indre,44,Loire-Atlantique,52,Pays de la Loire
44075,ISSE,44520,ISSE,,47.6173601577,-1.4560368491,75,,Issé,Issé,44,Loire-Atlantique,52,Pays de la Loire
44076,JANS,44170,JANS,,47.6241270257,-1.60441413686,76,,Jans,Jans,44,Loire-Atlantique,52,Pays de la Loire
44077,JOUE SUR ERDRE,44440,JOUE SUR ERDRE,,47.5092437538,-1.42943494948,77,,Joué-sur-Erdre,Joué-sur-Erdre,44,Loire-Atlantique,52,Pays de la Loire
44077,JOUE SUR ERDRE,44440,JOUE SUR ERDRE,NOTRE DAME DES LANGUEURS,47.5092437538,-1.42943494948,77,,Joué-sur-Erdre,Joué-sur-Erdre,44,Loire-Atlantique,52,Pays de la Loire
44078,JUIGNE DES MOUTIERS,44670,JUIGNE DES MOUTIERS,,47.689317272,-1.21237889264,78,,Juigné-des-Moutiers,Juigné-des-Moutiers,44,Loire-Atlantique,52,Pays de la Loire
44079,LE LANDREAU,44430,LE LANDREAU,,47.2077664835,-1.29844309328,79,Le,Landreau,Le Landreau,44,Loire-Atlantique,52,Pays de la Loire
44080,LAVAU SUR LOIRE,44260,LAVAU SUR LOIRE,,47.320865437,-1.95839196459,80,,Lavau-sur-Loire,Lavau-sur-Loire,44,Loire-Atlantique,52,Pays de la Loire
44081,LEGE,44650,LEGE,,46.9003706986,-1.57917124705,81,,Legé,Legé,44,Loire-Atlantique,52,Pays de la Loire
44082,LIGNE,44850,LIGNE,,47.4007599346,-1.38128028486,82,,Ligné,Ligné,44,Loire-Atlantique,52,Pays de la Loire
44083,LA LIMOUZINIERE,44310,LA LIMOUZINIERE,,46.9872257466,-1.64166009462,83,La,Limouzinière,La Limouzinière,44,Loire-Atlantique,52,Pays de la Loire
44084,LE LOROUX BOTTEREAU,44430,LE LOROUX BOTTEREAU,,47.2333976166,-1.33170187264,84,Le,Loroux-Bottereau,Le Loroux-Bottereau,44,Loire-Atlantique,52,Pays de la Loire
44085,LOUISFERT,44110,LOUISFERT,,47.6709262374,-1.42601382779,85,,Louisfert,Louisfert,44,Loire-Atlantique,52,Pays de la Loire
44086,LUSANGER,44590,LUSANGER,,47.6764268397,-1.58177485033,86,,Lusanger,Lusanger,44,Loire-Atlantique,52,Pays de la Loire
44087,MACHECOUL ST MEME,44270,MACHECOUL ST MEME,,46.9910784513,-1.82345670327,87,,Machecoul-Saint-Même,Machecoul-Saint-Même,44,Loire-Atlantique,52,Pays de la Loire
44087,MACHECOUL ST MEME,44270,MACHECOUL ST MEME,ST MEME LE TENU,46.9910784513,-1.82345670327,87,,Machecoul-Saint-Même,Machecoul-Saint-Même,44,Loire-Atlantique,52,Pays de la Loire
44088,MAISDON SUR SEVRE,44690,MAISDON SUR SEVRE,,47.116363643,-1.38887040907,88,,Maisdon-sur-Sèvre,Maisdon-sur-Sèvre,44,Loire-Atlantique,52,Pays de la Loire
44089,MALVILLE,44260,MALVILLE,,47.3431404212,-1.85411536756,89,,Malville,Malville,44,Loire-Atlantique,52,Pays de la Loire
44090,LA MARNE,44270,LA MARNE,,46.9913891791,-1.71864413598,90,La,Marne,La Marne,44,Loire-Atlantique,52,Pays de la Loire
44091,MARSAC SUR DON,44170,MARSAC SUR DON,,47.5923270414,-1.69651775245,91,,Marsac-sur-Don,Marsac-sur-Don,44,Loire-Atlantique,52,Pays de la Loire
44092,MASSERAC,44290,MASSERAC,,47.6758102411,-1.90458344966,92,,Massérac,Massérac,44,Loire-Atlantique,52,Pays de la Loire
44093,MAUMUSSON,44540,MAUMUSSON,,47.4827042599,-1.11902046549,93,,Maumusson,Maumusson,44,Loire-Atlantique,52,Pays de la Loire
44094,MAUVES SUR LOIRE,44470,MAUVES SUR LOIRE,,47.3161301081,-1.40277407662,94,,Mauves-sur-Loire,Mauves-sur-Loire,44,Loire-Atlantique,52,Pays de la Loire
44095,LA MEILLERAYE DE BRETAGNE,44520,LA MEILLERAYE DE BRETAGNE,,47.5639720194,-1.39409136304,95,La,Meilleraye-de-Bretagne,La Meilleraye-de-Bretagne,44,Loire-Atlantique,52,Pays de la Loire
44096,MESANGER,44522,MESANGER,,47.4288834101,-1.22583390119,96,,Mésanger,Mésanger,44,Loire-Atlantique,52,Pays de la Loire
44097,MESQUER,44420,MESQUER,,47.396034846,-2.47063226926,97,,Mesquer,Mesquer,44,Loire-Atlantique,52,Pays de la Loire
44097,MESQUER,44420,MESQUER,QUIMIAC,47.396034846,-2.47063226926,97,,Mesquer,Mesquer,44,Loire-Atlantique,52,Pays de la Loire
44098,MISSILLAC,44780,MISSILLAC,,47.4881482821,-2.17765365439,98,,Missillac,Missillac,44,Loire-Atlantique,52,Pays de la Loire
44099,MOISDON LA RIVIERE,44520,MOISDON LA RIVIERE,,47.6222503824,-1.37465777304,99,,Moisdon-la-Rivière,Moisdon-la-Rivière,44,Loire-Atlantique,52,Pays de la Loire
44100,MONNIERES,44690,MONNIERES,,47.1202035218,-1.34924956417,100,,Monnières,Monnières,44,Loire-Atlantique,52,Pays de la Loire
44101,LA MONTAGNE,44620,LA MONTAGNE,,47.1849548503,-1.6848281963,101,La,Montagne,La Montagne,44,Loire-Atlantique,52,Pays de la Loire
44102,MONTBERT,44140,MONTBERT,,47.0679433931,-1.4701852733,102,,Montbert,Montbert,44,Loire-Atlantique,52,Pays de la Loire
44103,MONTOIR DE BRETAGNE,44550,MONTOIR DE BRETAGNE,,47.3232368527,-2.1467366036,103,,Montoir-de-Bretagne,Montoir-de-Bretagne,44,Loire-Atlantique,52,Pays de la Loire
44104,MONTRELAIS,44370,MONTRELAIS,,47.4026410683,-0.971865462189,104,,Montrelais,Montrelais,44,Loire-Atlantique,52,Pays de la Loire
44105,MOUAIS,44590,MOUAIS,,47.7049782547,-1.66473754092,105,,Mouais,Mouais,44,Loire-Atlantique,52,Pays de la Loire
44106,LES MOUTIERS EN RETZ,44760,LES MOUTIERS EN RETZ,,47.0616219129,-1.99001867615,106,Les,Moutiers-en-Retz,Les Moutiers-en-Retz,44,Loire-Atlantique,52,Pays de la Loire
44107,MOUZEIL,44850,MOUZEIL,,47.437869782,-1.33099515958,107,,Mouzeil,Mouzeil,44,Loire-Atlantique,52,Pays de la Loire
44108,MOUZILLON,44330,MOUZILLON,,47.1293076835,-1.27378859416,108,,Mouzillon,Mouzillon,44,Loire-Atlantique,52,Pays de la Loire
44109,NANTES,44000,NANTES,,47.2316356767,-1.54831008605,109,,Nantes,Nantes,44,Loire-Atlantique,52,Pays de la Loire
44109,NANTES,44100,NANTES,,47.2316356767,-1.54831008605,109,,Nantes,Nantes,44,Loire-Atlantique,52,Pays de la Loire
44109,NANTES,44200,NANTES,,47.2316356767,-1.54831008605,109,,Nantes,Nantes,44,Loire-Atlantique,52,Pays de la Loire
44109,NANTES,44300,NANTES,,47.2316356767,-1.54831008605,109,,Nantes,Nantes,44,Loire-Atlantique,52,Pays de la Loire
44110,NORT SUR ERDRE,44390,NORT SUR ERDRE,,47.447122285,-1.51824792558,110,,Nort-sur-Erdre,Nort-sur-Erdre,44,Loire-Atlantique,52,Pays de la Loire
44111,NOTRE DAME DES LANDES,44130,NOTRE DAME DES LANDES,,47.3753590043,-1.71598291146,111,,Notre-Dame-des-Landes,Notre-Dame-des-Landes,44,Loire-Atlantique,52,Pays de la Loire
44112,NOYAL SUR BRUTZ,44110,NOYAL SUR BRUTZ,,47.7711430561,-1.343155162,112,,Noyal-sur-Brutz,Noyal-sur-Brutz,44,Loire-Atlantique,52,Pays de la Loire
44113,NOZAY,44170,NOZAY,,47.5728858483,-1.60097664616,113,,Nozay,Nozay,44,Loire-Atlantique,52,Pays de la Loire
44114,ORVAULT,44700,ORVAULT,,47.2739815478,-1.62323898524,114,,Orvault,Orvault,44,Loire-Atlantique,52,Pays de la Loire
44115,OUDON,44521,OUDON,,47.3554034497,-1.27120259646,115,,Oudon,Oudon,44,Loire-Atlantique,52,Pays de la Loire
44116,PAIMBOEUF,44560,PAIMBOEUF,,47.2863992883,-2.02807425552,116,,Paimbœuf,Paimbœuf,44,Loire-Atlantique,52,Pays de la Loire
44117,LE PALLET,44330,LE PALLET,,47.1439366329,-1.34541765527,117,Le,Pallet,Le Pallet,44,Loire-Atlantique,52,Pays de la Loire
44118,PANNECE,44440,PANNECE,,47.487718052,-1.21642621731,118,,Pannecé,Pannecé,44,Loire-Atlantique,52,Pays de la Loire
44119,PAULX,44270,PAULX,,46.95778792,-1.77844057969,119,,Paulx,Paulx,44,Loire-Atlantique,52,Pays de la Loire
44120,LE PELLERIN,44640,LE PELLERIN,,47.2229017049,-1.8250130027,120,Le,Pellerin,Le Pellerin,44,Loire-Atlantique,52,Pays de la Loire
44121,PETIT AUVERNE,44670,PETIT AUVERNE,,47.6113610952,-1.27773597546,121,,Petit-Auverné,Petit-Auverné,44,Loire-Atlantique,52,Pays de la Loire
44122,PETIT MARS,44390,PETIT MARS,,47.4051549703,-1.45655214384,122,,Petit-Mars,Petit-Mars,44,Loire-Atlantique,52,Pays de la Loire
44123,PIERRIC,44290,PIERRIC,,47.6890990862,-1.77486264797,123,,Pierric,Pierric,44,Loire-Atlantique,52,Pays de la Loire
44124,LE PIN,44540,LE PIN,,47.5921101403,-1.1470215048,124,Le,Pin,Le Pin,44,Loire-Atlantique,52,Pays de la Loire
44125,PIRIAC SUR MER,44420,PIRIAC SUR MER,,47.3778694474,-2.51735551427,125,,Piriac-sur-Mer,Piriac-sur-Mer,44,Loire-Atlantique,52,Pays de la Loire
44126,LA PLAINE SUR MER,44770,LA PLAINE SUR MER,,47.1443222693,-2.18345496077,126,La,Plaine-sur-Mer,La Plaine-sur-Mer,44,Loire-Atlantique,52,Pays de la Loire
44127,LA PLANCHE,44140,LA PLANCHE,,47.0198919821,-1.4401309566,127,La,Planche,La Planche,44,Loire-Atlantique,52,Pays de la Loire
44128,PLESSE,44630,PLESSE,,47.5557486835,-1.89050130746,128,,Plessé,Plessé,44,Loire-Atlantique,52,Pays de la Loire
44128,PLESSE,44630,PLESSE,LE COUDRAY,47.5557486835,-1.89050130746,128,,Plessé,Plessé,44,Loire-Atlantique,52,Pays de la Loire
44128,PLESSE,44630,PLESSE,LE DRESNY,47.5557486835,-1.89050130746,128,,Plessé,Plessé,44,Loire-Atlantique,52,Pays de la Loire
44129,PONTCHATEAU,44160,PONTCHATEAU,,47.433636363,-2.09556089039,129,,Pontchâteau,Pontchâteau,44,Loire-Atlantique,52,Pays de la Loire
44129,PONTCHATEAU,44160,PONTCHATEAU,ST GUILLAUME,47.433636363,-2.09556089039,129,,Pontchâteau,Pontchâteau,44,Loire-Atlantique,52,Pays de la Loire
44129,PONTCHATEAU,44160,PONTCHATEAU,ST ROCH,47.433636363,-2.09556089039,129,,Pontchâteau,Pontchâteau,44,Loire-Atlantique,52,Pays de la Loire
44130,PONT ST MARTIN,44860,PONT ST MARTIN,,47.126582936,-1.56594193848,130,,Pont-Saint-Martin,Pont-Saint-Martin,44,Loire-Atlantique,52,Pays de la Loire
44130,PONT ST MARTIN,44860,PONT ST MARTIN,VIAIS,47.126582936,-1.56594193848,130,,Pont-Saint-Martin,Pont-Saint-Martin,44,Loire-Atlantique,52,Pays de la Loire
44131,PORNIC,44210,PORNIC,,47.1223972452,-2.05182334479,131,,Pornic,Pornic,44,Loire-Atlantique,52,Pays de la Loire
44131,PORNIC,44210,PORNIC,LE CLION SUR MER,47.1223972452,-2.05182334479,131,,Pornic,Pornic,44,Loire-Atlantique,52,Pays de la Loire
44131,PORNIC,44210,PORNIC,STE MARIE,47.1223972452,-2.05182334479,131,,Pornic,Pornic,44,Loire-Atlantique,52,Pays de la Loire
44132,PORNICHET,44380,PORNICHET,,47.2615791665,-2.31415650821,132,,Pornichet,Pornichet,44,Loire-Atlantique,52,Pays de la Loire
44133,PORT ST PERE,44710,PORT ST PERE,,47.1406810974,-1.76837740762,133,,Port-Saint-Père,Port-Saint-Père,44,Loire-Atlantique,52,Pays de la Loire
44134,POUILLE LES COTEAUX,44522,POUILLE LES COTEAUX,,47.4677099319,-1.17461677561,134,,Pouillé-les-Côteaux,Pouillé-les-Côteaux,44,Loire-Atlantique,52,Pays de la Loire
44135,LE POULIGUEN,44510,LE POULIGUEN,,47.2716208982,-2.43210265977,135,Le,Pouliguen,Le Pouliguen,44,Loire-Atlantique,52,Pays de la Loire
44136,PREFAILLES,44770,PREFAILLES,,47.131466657,-2.21391536402,136,,Préfailles,Préfailles,44,Loire-Atlantique,52,Pays de la Loire
44137,PRINQUIAU,44260,PRINQUIAU,,47.3735148609,-2.01845241954,137,,Prinquiau,Prinquiau,44,Loire-Atlantique,52,Pays de la Loire
44138,PUCEUL,44390,PUCEUL,,47.5188688924,-1.61966399474,138,,Puceul,Puceul,44,Loire-Atlantique,52,Pays de la Loire
44139,QUILLY,44750,QUILLY,,47.4618178033,-1.9408388462,139,,Quilly,Quilly,44,Loire-Atlantique,52,Pays de la Loire
44140,LA REGRIPPIERE,44330,LA REGRIPPIERE,,47.1851327192,-1.19445859002,140,La,Regrippière,La Regrippière,44,Loire-Atlantique,52,Pays de la Loire
44141,LA REMAUDIERE,44430,LA REMAUDIERE,,47.2265214538,-1.23167770963,141,La,Remaudière,La Remaudière,44,Loire-Atlantique,52,Pays de la Loire
44142,REMOUILLE,44140,REMOUILLE,,47.0385516844,-1.38749002799,142,,Remouillé,Remouillé,44,Loire-Atlantique,52,Pays de la Loire
44143,REZE,44400,REZE,,47.1762338904,-1.54966399893,143,,Rezé,Rezé,44,Loire-Atlantique,52,Pays de la Loire
44143,REZE,44400,REZE,PONT ROUSSEAU,47.1762338904,-1.54966399893,143,,Rezé,Rezé,44,Loire-Atlantique,52,Pays de la Loire
44144,RIAILLE,44440,RIAILLE,,47.5209726669,-1.31205698332,144,,Riaillé,Riaillé,44,Loire-Atlantique,52,Pays de la Loire
44145,ROUANS,44640,ROUANS,,47.1763592414,-1.84313535499,145,,Rouans,Rouans,44,Loire-Atlantique,52,Pays de la Loire
44146,ROUGE,44660,ROUGE,,47.7704881323,-1.43409809918,146,,Rougé,Rougé,44,Loire-Atlantique,52,Pays de la Loire
44148,RUFFIGNE,44660,RUFFIGNE,,47.764464663,-1.52121555918,148,,Ruffigné,Ruffigné,44,Loire-Atlantique,52,Pays de la Loire
44149,SAFFRE,44390,SAFFRE,,47.4914246553,-1.57940883784,149,,Saffré,Saffré,44,Loire-Atlantique,52,Pays de la Loire
44150,ST AIGNAN GRANDLIEU,44860,ST AIGNAN GRANDLIEU,,47.1331072085,-1.62317141788,150,,Saint-Aignan-Grandlieu,Saint-Aignan-Grandlieu,44,Loire-Atlantique,52,Pays de la Loire
44151,ST ANDRE DES EAUX,44117,ST ANDRE DES EAUX,,47.3234376088,-2.31269060612,151,,Saint-André-des-Eaux,Saint-André-des-Eaux,44,Loire-Atlantique,52,Pays de la Loire
44152,STE ANNE SUR BRIVET,44160,STE ANNE SUR BRIVET,,47.4517932363,-2.01858894478,152,,Sainte-Anne-sur-Brivet,Sainte-Anne-sur-Brivet,44,Loire-Atlantique,52,Pays de la Loire
44153,ST AUBIN DES CHATEAUX,44110,ST AUBIN DES CHATEAUX,,47.7056440734,-1.47872960477,153,,Saint-Aubin-des-Châteaux,Saint-Aubin-des-Châteaux,44,Loire-Atlantique,52,Pays de la Loire
44154,ST BREVIN LES PINS,44250,ST BREVIN LES PINS,,47.2380057754,-2.1516650013,154,,Saint-Brevin-les-Pins,Saint-Brevin-les-Pins,44,Loire-Atlantique,52,Pays de la Loire
44154,ST BREVIN LES PINS,44250,ST BREVIN LES PINS,MINDIN,47.2380057754,-2.1516650013,154,,Saint-Brevin-les-Pins,Saint-Brevin-les-Pins,44,Loire-Atlantique,52,Pays de la Loire
44154,ST BREVIN LES PINS,44250,ST BREVIN LES PINS,ST BREVIN L OCEAN,47.2380057754,-2.1516650013,154,,Saint-Brevin-les-Pins,Saint-Brevin-les-Pins,44,Loire-Atlantique,52,Pays de la Loire
44155,ST COLOMBAN,44310,ST COLOMBAN,,47.0251492396,-1.55906118865,155,,Saint-Colomban,Saint-Colomban,44,Loire-Atlantique,52,Pays de la Loire
44155,ST COLOMBAN,44310,ST COLOMBAN,PONT JAMES,47.0251492396,-1.55906118865,155,,Saint-Colomban,Saint-Colomban,44,Loire-Atlantique,52,Pays de la Loire
44156,CORCOUE SUR LOGNE,44650,CORCOUE SUR LOGNE,,46.9532455273,-1.60060237938,156,,Corcoué-sur-Logne,Corcoué-sur-Logne,44,Loire-Atlantique,52,Pays de la Loire
44156,CORCOUE SUR LOGNE,44650,CORCOUE SUR LOGNE,LA BENATE,46.9532455273,-1.60060237938,156,,Corcoué-sur-Logne,Corcoué-sur-Logne,44,Loire-Atlantique,52,Pays de la Loire
44156,CORCOUE SUR LOGNE,44650,CORCOUE SUR LOGNE,ST JEAN DE CORCOUE,46.9532455273,-1.60060237938,156,,Corcoué-sur-Logne,Corcoué-sur-Logne,44,Loire-Atlantique,52,Pays de la Loire
44157,ST ETIENNE DE MER MORTE,44270,ST ETIENNE DE MER MORTE,,46.9452735798,-1.71830813548,157,,Saint-Étienne-de-Mer-Morte,Saint-Étienne-de-Mer-Morte,44,Loire-Atlantique,52,Pays de la Loire
44158,ST ETIENNE DE MONTLUC,44360,ST ETIENNE DE MONTLUC,,47.265112989,-1.78426879748,158,,Saint-Étienne-de-Montluc,Saint-Étienne-de-Montluc,44,Loire-Atlantique,52,Pays de la Loire
44159,ST FIACRE SUR MAINE,44690,ST FIACRE SUR MAINE,,47.1431877393,-1.41565837757,159,,Saint-Fiacre-sur-Maine,Saint-Fiacre-sur-Maine,44,Loire-Atlantique,52,Pays de la Loire
44160,ST GEREON,44150,ST GEREON,,47.3707222612,-1.20922739659,160,,Saint-Géréon,Saint-Géréon,44,Loire-Atlantique,52,Pays de la Loire
44161,ST GILDAS DES BOIS,44530,ST GILDAS DES BOIS,,47.5090375076,-2.05985402604,161,,Saint-Gildas-des-Bois,Saint-Gildas-des-Bois,44,Loire-Atlantique,52,Pays de la Loire
44162,ST HERBLAIN,44800,ST HERBLAIN,,47.2243762412,-1.63434818692,162,,Saint-Herblain,Saint-Herblain,44,Loire-Atlantique,52,Pays de la Loire
44163,VAIR SUR LOIRE,44150,VAIR SUR LOIRE,ANETZ,47.4063801215,-1.11538134874,163,,Vair-sur-Loire,Vair-sur-Loire,44,Loire-Atlantique,52,Pays de la Loire
44163,VAIR SUR LOIRE,44150,VAIR SUR LOIRE,ST HERBLON,47.4063801215,-1.11538134874,163,,Vair-sur-Loire,Vair-sur-Loire,44,Loire-Atlantique,52,Pays de la Loire
44164,ST HILAIRE DE CHALEONS,44680,ST HILAIRE DE CHALEONS,,47.0958948849,-1.87983637967,164,,Saint-Hilaire-de-Chaléons,Saint-Hilaire-de-Chaléons,44,Loire-Atlantique,52,Pays de la Loire
44165,ST HILAIRE DE CLISSON,44190,ST HILAIRE DE CLISSON,,47.0595826465,-1.3165732334,165,,Saint-Hilaire-de-Clisson,Saint-Hilaire-de-Clisson,44,Loire-Atlantique,52,Pays de la Loire
44166,ST JEAN DE BOISEAU,44640,ST JEAN DE BOISEAU,,47.1940918482,-1.71963718328,166,,Saint-Jean-de-Boiseau,Saint-Jean-de-Boiseau,44,Loire-Atlantique,52,Pays de la Loire
44168,ST JOACHIM,44720,ST JOACHIM,,47.3721622371,-2.24296601109,168,,Saint-Joachim,Saint-Joachim,44,Loire-Atlantique,52,Pays de la Loire
44169,ST JULIEN DE CONCELLES,44450,ST JULIEN DE CONCELLES,,47.246301757,-1.40639571844,169,,Saint-Julien-de-Concelles,Saint-Julien-de-Concelles,44,Loire-Atlantique,52,Pays de la Loire
44170,ST JULIEN DE VOUVANTES,44670,ST JULIEN DE VOUVANTES,,47.653118581,-1.22941680644,170,,Saint-Julien-de-Vouvantes,Saint-Julien-de-Vouvantes,44,Loire-Atlantique,52,Pays de la Loire
44171,ST LEGER LES VIGNES,44710,ST LEGER LES VIGNES,,47.1429694401,-1.7140364404,171,,Saint-Léger-les-Vignes,Saint-Léger-les-Vignes,44,Loire-Atlantique,52,Pays de la Loire
44172,STE LUCE SUR LOIRE,44980,STE LUCE SUR LOIRE,,47.2536231327,-1.4728987153,172,,Sainte-Luce-sur-Loire,Sainte-Luce-sur-Loire,44,Loire-Atlantique,52,Pays de la Loire
44173,ST LUMINE DE CLISSON,44190,ST LUMINE DE CLISSON,,47.0796411393,-1.36298171094,173,,Saint-Lumine-de-Clisson,Saint-Lumine-de-Clisson,44,Loire-Atlantique,52,Pays de la Loire
44174,ST LUMINE DE COUTAIS,44310,ST LUMINE DE COUTAIS,,47.0525345648,-1.72353750567,174,,Saint-Lumine-de-Coutais,Saint-Lumine-de-Coutais,44,Loire-Atlantique,52,Pays de la Loire
44175,ST LYPHARD,44410,ST LYPHARD,,47.3781648768,-2.33524206651,175,,Saint-Lyphard,Saint-Lyphard,44,Loire-Atlantique,52,Pays de la Loire
44176,ST MALO DE GUERSAC,44550,ST MALO DE GUERSAC,,47.3561985163,-2.17203654541,176,,Saint-Malo-de-Guersac,Saint-Malo-de-Guersac,44,Loire-Atlantique,52,Pays de la Loire
44178,ST MARS DE COUTAIS,44680,ST MARS DE COUTAIS,,47.0798520838,-1.75208948345,178,,Saint-Mars-de-Coutais,Saint-Mars-de-Coutais,44,Loire-Atlantique,52,Pays de la Loire
44179,ST MARS DU DESERT,44850,ST MARS DU DESERT,,47.357199355,-1.44530337399,179,,Saint-Mars-du-Désert,Saint-Mars-du-Désert,44,Loire-Atlantique,52,Pays de la Loire
44180,ST MARS LA JAILLE,44540,ST MARS LA JAILLE,,47.5309227299,-1.19441075506,180,,Vallons-de-l'Erdre,Vallons-de-l'Erdre,44,Loire-Atlantique,52,Pays de la Loire
44182,ST MICHEL CHEF CHEF,44730,ST MICHEL CHEF CHEF,,47.1755629767,-2.12731093246,182,,Saint-Michel-Chef-Chef,Saint-Michel-Chef-Chef,44,Loire-Atlantique,52,Pays de la Loire
44182,ST MICHEL CHEF CHEF,44730,ST MICHEL CHEF CHEF,THARON PLAGE,47.1755629767,-2.12731093246,182,,Saint-Michel-Chef-Chef,Saint-Michel-Chef-Chef,44,Loire-Atlantique,52,Pays de la Loire
44183,ST MOLF,44350,ST MOLF,,47.3882223801,-2.41488913268,183,,Saint-Molf,Saint-Molf,44,Loire-Atlantique,52,Pays de la Loire
44184,ST NAZAIRE,44600,ST NAZAIRE,,47.2802857028,-2.25379927249,184,,Saint-Nazaire,Saint-Nazaire,44,Loire-Atlantique,52,Pays de la Loire
44184,ST NAZAIRE,44600,ST NAZAIRE,ST MARC SUR MER,47.2802857028,-2.25379927249,184,,Saint-Nazaire,Saint-Nazaire,44,Loire-Atlantique,52,Pays de la Loire
44185,ST NICOLAS DE REDON,44460,ST NICOLAS DE REDON,,47.6362405155,-2.05685289452,185,,Saint-Nicolas-de-Redon,Saint-Nicolas-de-Redon,44,Loire-Atlantique,52,Pays de la Loire
44186,STE PAZANNE,44680,STE PAZANNE,,47.0839938747,-1.81814462702,186,,Sainte-Pazanne,Sainte-Pazanne,44,Loire-Atlantique,52,Pays de la Loire
44187,ST PERE EN RETZ,44320,ST PERE EN RETZ,,47.2253813658,-2.06604710896,187,,Saint-Père-en-Retz,Saint-Père-en-Retz,44,Loire-Atlantique,52,Pays de la Loire
44188,ST PHILBERT DE GRAND LIEU,44310,ST PHILBERT DE GRAND LIEU,,47.0602912538,-1.65711361304,188,,Saint-Philbert-de-Grand-Lieu,Saint-Philbert-de-Grand-Lieu,44,Loire-Atlantique,52,Pays de la Loire
44189,STE REINE DE BRETAGNE,44160,STE REINE DE BRETAGNE,,47.4437503517,-2.18092999441,189,,Sainte-Reine-de-Bretagne,Sainte-Reine-de-Bretagne,44,Loire-Atlantique,52,Pays de la Loire
44190,ST SEBASTIEN SUR LOIRE,44230,ST SEBASTIEN SUR LOIRE,,47.2031024794,-1.4992042742,190,,Saint-Sébastien-sur-Loire,Saint-Sébastien-sur-Loire,44,Loire-Atlantique,52,Pays de la Loire
44191,ST SULPICE DES LANDES,44540,ST SULPICE DES LANDES,,47.5679310764,-1.2230395032,191,,Saint-Sulpice-des-Landes,Saint-Sulpice-des-Landes,44,Loire-Atlantique,52,Pays de la Loire
44192,ST VIAUD,44320,ST VIAUD,,47.2476012305,-1.99328592854,192,,Saint-Viaud,Saint-Viaud,44,Loire-Atlantique,52,Pays de la Loire
44193,ST VINCENT DES LANDES,44590,ST VINCENT DES LANDES,,47.6556800047,-1.50486133438,193,,Saint-Vincent-des-Landes,Saint-Vincent-des-Landes,44,Loire-Atlantique,52,Pays de la Loire
44194,SAUTRON,44880,SAUTRON,,47.273226935,-1.6804726586,194,,Sautron,Sautron,44,Loire-Atlantique,52,Pays de la Loire
44195,SAVENAY,44260,SAVENAY,,47.3570647854,-1.91842119102,195,,Savenay,Savenay,44,Loire-Atlantique,52,Pays de la Loire
44196,SEVERAC,44530,SEVERAC,,47.554402668,-2.06655618594,196,,Sévérac,Sévérac,44,Loire-Atlantique,52,Pays de la Loire
44197,SION LES MINES,44590,SION LES MINES,,47.7291886842,-1.58109954157,197,,Sion-les-Mines,Sion-les-Mines,44,Loire-Atlantique,52,Pays de la Loire
44198,LES SORINIERES,44840,LES SORINIERES,,47.1414647152,-1.5202224711,198,Les,Sorinières,Les Sorinières,44,Loire-Atlantique,52,Pays de la Loire
44199,SOUDAN,44110,SOUDAN,,47.7255705926,-1.29052393382,199,,Soudan,Soudan,44,Loire-Atlantique,52,Pays de la Loire
44200,SOULVACHE,44660,SOULVACHE,,47.8212049695,-1.44807093134,200,,Soulvache,Soulvache,44,Loire-Atlantique,52,Pays de la Loire
44201,SUCE SUR ERDRE,44240,SUCE SUR ERDRE,,47.3536547402,-1.51945689724,201,,Sucé-sur-Erdre,Sucé-sur-Erdre,44,Loire-Atlantique,52,Pays de la Loire
44202,TEILLE,44440,TEILLE,,47.4641557957,-1.28707085178,202,,Teillé,Teillé,44,Loire-Atlantique,52,Pays de la Loire
44203,LE TEMPLE DE BRETAGNE,44360,LE TEMPLE DE BRETAGNE,,47.3284386017,-1.79041386059,203,Le,Temple-de-Bretagne,Le Temple-de-Bretagne,44,Loire-Atlantique,52,Pays de la Loire
44204,THOUARE SUR LOIRE,44470,THOUARE SUR LOIRE,,47.2763256866,-1.43162198248,204,,Thouaré-sur-Loire,Thouaré-sur-Loire,44,Loire-Atlantique,52,Pays de la Loire
44205,LES TOUCHES,44390,LES TOUCHES,,47.4523563976,-1.42756883238,205,Les,Touches,Les Touches,44,Loire-Atlantique,52,Pays de la Loire
44206,TOUVOIS,44650,TOUVOIS,,46.9065013281,-1.67969033397,206,,Touvois,Touvois,44,Loire-Atlantique,52,Pays de la Loire
44207,TRANS SUR ERDRE,44440,TRANS SUR ERDRE,,47.4785800953,-1.36363954678,207,,Trans-sur-Erdre,Trans-sur-Erdre,44,Loire-Atlantique,52,Pays de la Loire
44208,TREFFIEUX,44170,TREFFIEUX,,47.6131430435,-1.52831796122,208,,Treffieux,Treffieux,44,Loire-Atlantique,52,Pays de la Loire
44209,TREILLIERES,44119,TREILLIERES,,47.3178218372,-1.62577302041,209,,Treillières,Treillières,44,Loire-Atlantique,52,Pays de la Loire
44210,TRIGNAC,44570,TRIGNAC,,47.3125328416,-2.20700045585,210,,Trignac,Trignac,44,Loire-Atlantique,52,Pays de la Loire
44211,LA TURBALLE,44420,LA TURBALLE,,47.3507474102,-2.48853439808,211,La,Turballe,La Turballe,44,Loire-Atlantique,52,Pays de la Loire
44211,LA TURBALLE,44420,LA TURBALLE,TRESCALAN,47.3507474102,-2.48853439808,211,La,Turballe,La Turballe,44,Loire-Atlantique,52,Pays de la Loire
44212,VALLET,44330,VALLET,,47.1715153308,-1.26146566077,212,,Vallet,Vallet,44,Loire-Atlantique,52,Pays de la Loire
44213,LOIREAUXENCE,44370,LOIREAUXENCE,BELLIGNE,47.4023133343,-1.0243274407,213,,Loireauxence,Loireauxence,44,Loire-Atlantique,52,Pays de la Loire
44213,LOIREAUXENCE,44370,LOIREAUXENCE,LA CHAPELLE ST SAUVEUR,47.4023133343,-1.0243274407,213,,Loireauxence,Loireauxence,44,Loire-Atlantique,52,Pays de la Loire
44213,LOIREAUXENCE,44370,LOIREAUXENCE,LA ROUXIERE,47.4023133343,-1.0243274407,213,,Loireauxence,Loireauxence,44,Loire-Atlantique,52,Pays de la Loire
44213,LOIREAUXENCE,44370,LOIREAUXENCE,VARADES,47.4023133343,-1.0243274407,213,,Loireauxence,Loireauxence,44,Loire-Atlantique,52,Pays de la Loire
44214,VAY,44170,VAY,,47.5453431813,-1.70810408082,214,,Vay,Vay,44,Loire-Atlantique,52,Pays de la Loire
44215,VERTOU,44120,VERTOU,,47.1603732625,-1.47052291749,215,,Vertou,Vertou,44,Loire-Atlantique,52,Pays de la Loire
44215,VERTOU,44120,VERTOU,BEAUTOUR,47.1603732625,-1.47052291749,215,,Vertou,Vertou,44,Loire-Atlantique,52,Pays de la Loire
44216,VIEILLEVIGNE,44116,VIEILLEVIGNE,,46.9713066859,-1.41881822472,216,,Vieillevigne,Vieillevigne,44,Loire-Atlantique,52,Pays de la Loire
44217,VIGNEUX DE BRETAGNE,44360,VIGNEUX DE BRETAGNE,,47.3196231868,-1.72042944177,217,,Vigneux-de-Bretagne,Vigneux-de-Bretagne,44,Loire-Atlantique,52,Pays de la Loire
44217,VIGNEUX DE BRETAGNE,44360,VIGNEUX DE BRETAGNE,LA PAQUELAIS,47.3196231868,-1.72042944177,217,,Vigneux-de-Bretagne,Vigneux-de-Bretagne,44,Loire-Atlantique,52,Pays de la Loire
44218,VILLEPOT,44110,VILLEPOT,,47.7700054749,-1.28499992375,218,,Villepot,Villepot,44,Loire-Atlantique,52,Pays de la Loire
44219,VRITZ,44540,VRITZ,,47.5890460017,-1.07270635632,219,,Vritz,Vritz,44,Loire-Atlantique,52,Pays de la Loire
44220,VUE,44640,VUE,,47.1941974605,-1.9008768965,220,,Vue,Vue,44,Loire-Atlantique,52,Pays de la Loire
44221,LA CHEVALLERAIS,44810,LA CHEVALLERAIS,,47.477183891,-1.66493224738,221,La,Chevallerais,La Chevallerais,44,Loire-Atlantique,52,Pays de la Loire
44222,LA ROCHE BLANCHE,44522,LA ROCHE BLANCHE,,47.4377425248,-1.14429842548,222,La,Roche-Blanche,La Roche-Blanche,44,Loire-Atlantique,52,Pays de la Loire
44223,GENESTON,44140,GENESTON,,47.0596610494,-1.52602858693,223,,Geneston,Geneston,44,Loire-Atlantique,52,Pays de la Loire
44224,LA GRIGONNAIS,44170,LA GRIGONNAIS,,47.5178441484,-1.67992818531,224,La,Grigonnais,La Grigonnais,44,Loire-Atlantique,52,Pays de la Loire
45001,ADON,45230,ADON,,47.7642683732,2.7978849949,1,,Adon,Adon,45,Loiret,24,Centre-Val de Loire
45002,AILLANT SUR MILLERON,45230,AILLANT SUR MILLERON,,47.7948800184,2.93667863104,2,,Aillant-sur-Milleron,Aillant-sur-Milleron,45,Loiret,24,Centre-Val de Loire
45004,AMILLY,45200,AMILLY,,47.9867116879,2.78217519303,4,,Amilly,Amilly,45,Loiret,24,Centre-Val de Loire
45005,ANDONVILLE,45480,ANDONVILLE,,48.2740984762,2.02318881395,5,,Andonville,Andonville,45,Loiret,24,Centre-Val de Loire
45006,ARDON,45160,ARDON,,47.7881590342,1.88281454667,6,,Ardon,Ardon,45,Loiret,24,Centre-Val de Loire
45008,ARTENAY,45410,ARTENAY,,48.076236639,1.88000171801,8,,Artenay,Artenay,45,Loiret,24,Centre-Val de Loire
45009,ASCHERES LE MARCHE,45170,ASCHERES LE MARCHE,,48.1120145126,2.01033081704,9,,Aschères-le-Marché,Aschères-le-Marché,45,Loiret,24,Centre-Val de Loire
45010,ASCOUX,45300,ASCOUX,,48.1271076632,2.24907417441,10,,Ascoux,Ascoux,45,Loiret,24,Centre-Val de Loire
45011,ATTRAY,45170,ATTRAY,,48.1198576861,2.13126925085,11,,Attray,Attray,45,Loiret,24,Centre-Val de Loire
45012,AUDEVILLE,45300,AUDEVILLE,,48.2801416057,2.24290241893,12,,Audeville,Audeville,45,Loiret,24,Centre-Val de Loire
45013,AUGERVILLE LA RIVIERE,45330,AUGERVILLE LA RIVIERE,,48.2530425199,2.41870995196,13,,Augerville-la-Rivière,Augerville-la-Rivière,45,Loiret,24,Centre-Val de Loire
45014,AULNAY LA RIVIERE,45390,AULNAY LA RIVIERE,,48.2087279915,2.3732598732,14,,Aulnay-la-Rivière,Aulnay-la-Rivière,45,Loiret,24,Centre-Val de Loire
45015,AUTRUY SUR JUINE,45480,AUTRUY SUR JUINE,,48.2768020165,2.09163157788,15,,Autruy-sur-Juine,Autruy-sur-Juine,45,Loiret,24,Centre-Val de Loire
45016,AUTRY LE CHATEL,45500,AUTRY LE CHATEL,,47.5949029716,2.60209574772,16,,Autry-le-Châtel,Autry-le-Châtel,45,Loiret,24,Centre-Val de Loire
45017,AUVILLIERS EN GATINAIS,45270,AUVILLIERS EN GATINAIS,,47.9653555404,2.49120445064,17,,Auvilliers-en-Gâtinais,Auvilliers-en-Gâtinais,45,Loiret,24,Centre-Val de Loire
45018,AUXY,45340,AUXY,,48.1092099568,2.48643135047,18,,Auxy,Auxy,45,Loiret,24,Centre-Val de Loire
45019,BACCON,45130,BACCON,,47.8850157049,1.62389157933,19,,Baccon,Baccon,45,Loiret,24,Centre-Val de Loire
45020,LE BARDON,45130,LE BARDON,,47.8419806069,1.64858410606,20,Le,Bardon,Le Bardon,45,Loiret,24,Centre-Val de Loire
45021,BARVILLE EN GATINAIS,45340,BARVILLE EN GATINAIS,,48.1116745133,2.39944970618,21,,Barville-en-Gâtinais,Barville-en-Gâtinais,45,Loiret,24,Centre-Val de Loire
45022,BATILLY EN GATINAIS,45340,BATILLY EN GATINAIS,,48.0832267087,2.36953010916,22,,Batilly-en-Gâtinais,Batilly-en-Gâtinais,45,Loiret,24,Centre-Val de Loire
45023,BATILLY EN PUISAYE,45420,BATILLY EN PUISAYE,,47.6114153398,2.88555549083,23,,Batilly-en-Puisaye,Batilly-en-Puisaye,45,Loiret,24,Centre-Val de Loire
45024,BAULE,45130,BAULE,,47.8106703162,1.66824027351,24,,Baule,Baule,45,Loiret,24,Centre-Val de Loire
45025,BAZOCHES LES GALLERANDES,45480,BAZOCHES LES GALLERANDES,,48.1622351028,2.05200165302,25,,Bazoches-les-Gallerandes,Bazoches-les-Gallerandes,45,Loiret,24,Centre-Val de Loire
45025,BAZOCHES LES GALLERANDES,45480,BAZOCHES LES GALLERANDES,IZY,48.1622351028,2.05200165302,25,,Bazoches-les-Gallerandes,Bazoches-les-Gallerandes,45,Loiret,24,Centre-Val de Loire
45026,BAZOCHES SUR LE BETZ,45210,BAZOCHES SUR LE BETZ,,48.1323472577,2.99028877216,26,,Bazoches-sur-le-Betz,Bazoches-sur-le-Betz,45,Loiret,24,Centre-Val de Loire
45027,BEAUCHAMPS SUR HUILLARD,45270,BEAUCHAMPS SUR HUILLARD,,47.9441060194,2.45463738927,27,,Beauchamps-sur-Huillard,Beauchamps-sur-Huillard,45,Loiret,24,Centre-Val de Loire
45028,BEAUGENCY,45190,BEAUGENCY,,47.7781494309,1.63946818857,28,,Beaugency,Beaugency,45,Loiret,24,Centre-Val de Loire
45029,BEAULIEU SUR LOIRE,45630,BEAULIEU SUR LOIRE,,47.5351183131,2.80260257631,29,,Beaulieu-sur-Loire,Beaulieu-sur-Loire,45,Loiret,24,Centre-Val de Loire
45030,BEAUNE LA ROLANDE,45340,BEAUNE LA ROLANDE,,48.0745543416,2.42978796262,30,,Beaune-la-Rolande,Beaune-la-Rolande,45,Loiret,24,Centre-Val de Loire
45031,BELLEGARDE,45270,BELLEGARDE,,47.9802482438,2.41619642926,31,,Bellegarde,Bellegarde,45,Loiret,24,Centre-Val de Loire
45032,LE BIGNON MIRABEAU,45210,LE BIGNON MIRABEAU,,48.1476188103,2.93466561379,32,Le,Bignon-Mirabeau,Le Bignon-Mirabeau,45,Loiret,24,Centre-Val de Loire
45033,BOESSES,45390,BOESSES,,48.1484373328,2.4331616188,33,,Boësses,Boësses,45,Loiret,24,Centre-Val de Loire
45034,BOIGNY SUR BIONNE,45760,BOIGNY SUR BIONNE,,47.9317973301,2.01895568907,34,,Boigny-sur-Bionne,Boigny-sur-Bionne,45,Loiret,24,Centre-Val de Loire
45035,BOISCOMMUN,45340,BOISCOMMUN,,48.0389368902,2.35695030268,35,,Boiscommun,Boiscommun,45,Loiret,24,Centre-Val de Loire
45035,BOISCOMMUN,45340,BOISCOMMUN,CHEMAULT,48.0389368902,2.35695030268,35,,Boiscommun,Boiscommun,45,Loiret,24,Centre-Val de Loire
45036,BOISMORAND,45290,BOISMORAND,,47.7798249576,2.72028920172,36,,Boismorand,Boismorand,45,Loiret,24,Centre-Val de Loire
45036,BOISMORAND,45290,BOISMORAND,LES BEZARDS,47.7798249576,2.72028920172,36,,Boismorand,Boismorand,45,Loiret,24,Centre-Val de Loire
45037,BOISSEAUX,45480,BOISSEAUX,,48.2537728973,1.98567766927,37,,Boisseaux,Boisseaux,45,Loiret,24,Centre-Val de Loire
45038,BONDAROY,45300,BONDAROY,,48.1853742556,2.28093740952,38,,Bondaroy,Bondaroy,45,Loiret,24,Centre-Val de Loire
45039,BONNEE,45460,BONNEE,,47.7942659998,2.39324729287,39,,Bonnée,Bonnée,45,Loiret,24,Centre-Val de Loire
45040,BONNY SUR LOIRE,45420,BONNY SUR LOIRE,,47.5773091567,2.83916317894,40,,Bonny-sur-Loire,Bonny-sur-Loire,45,Loiret,24,Centre-Val de Loire
45041,BORDEAUX EN GATINAIS,45340,BORDEAUX EN GATINAIS,,48.1008672557,2.53676265112,41,,Bordeaux-en-Gâtinais,Bordeaux-en-Gâtinais,45,Loiret,24,Centre-Val de Loire
45042,LES BORDES,45460,LES BORDES,,47.8163497112,2.43671034937,42,Les,Bordes,Les Bordes,45,Loiret,24,Centre-Val de Loire
45043,BOU,45430,BOU,,47.8704664522,2.04729734716,43,,Bou,Bou,45,Loiret,24,Centre-Val de Loire
45044,BOUGY LEZ NEUVILLE,45170,BOUGY LEZ NEUVILLE,,48.0420549019,2.02334838722,44,,Bougy-lez-Neuville,Bougy-lez-Neuville,45,Loiret,24,Centre-Val de Loire
45045,BOUILLY EN GATINAIS,45300,BOUILLY EN GATINAIS,,48.0981078999,2.28756909213,45,,Bouilly-en-Gâtinais,Bouilly-en-Gâtinais,45,Loiret,24,Centre-Val de Loire
45046,BOULAY LES BARRES,45140,BOULAY LES BARRES,,47.9749370817,1.77690207955,46,,Boulay-les-Barres,Boulay-les-Barres,45,Loiret,24,Centre-Val de Loire
45047,BOUZONVILLE AUX BOIS,45300,BOUZONVILLE AUX BOIS,,48.1043167535,2.2461940499,47,,Bouzonville-aux-Bois,Bouzonville-aux-Bois,45,Loiret,24,Centre-Val de Loire
45049,BOUZY LA FORET,45460,BOUZY LA FORET,,47.8653801463,2.36802219641,49,,Bouzy-la-Forêt,Bouzy-la-Forêt,45,Loiret,24,Centre-Val de Loire
45050,BOYNES,45300,BOYNES,,48.1171101331,2.36040518404,50,,Boynes,Boynes,45,Loiret,24,Centre-Val de Loire
45051,BRAY EN VAL,45460,BRAY EN VAL,,47.8291578282,2.38062384404,51,,Bray-Saint-Aignan,Bray-Saint-Aignan,45,Loiret,24,Centre-Val de Loire
45052,BRETEAU,45250,BRETEAU,,47.6770246795,2.88898357418,52,,Breteau,Breteau,45,Loiret,24,Centre-Val de Loire
45053,BRIARE,45250,BRIARE,,47.6450008152,2.74519258713,53,,Briare,Briare,45,Loiret,24,Centre-Val de Loire
45054,BRIARRES SUR ESSONNE,45390,BRIARRES SUR ESSONNE,,48.2263298092,2.41359729566,54,,Briarres-sur-Essonne,Briarres-sur-Essonne,45,Loiret,24,Centre-Val de Loire
45055,BRICY,45310,BRICY,,48.0009914345,1.77493058456,55,,Bricy,Bricy,45,Loiret,24,Centre-Val de Loire
45056,BROMEILLES,45390,BROMEILLES,,48.1829452149,2.4957743226,56,,Bromeilles,Bromeilles,45,Loiret,24,Centre-Val de Loire
45058,BUCY LE ROI,45410,BUCY LE ROI,,48.0594994911,1.92098402984,58,,Bucy-le-Roi,Bucy-le-Roi,45,Loiret,24,Centre-Val de Loire
45059,BUCY ST LIPHARD,45140,BUCY ST LIPHARD,,47.9390462233,1.75401078259,59,,Bucy-Saint-Liphard,Bucy-Saint-Liphard,45,Loiret,24,Centre-Val de Loire
45060,LA BUSSIERE,45230,LA BUSSIERE,,47.7346543928,2.74663448165,60,La,Bussière,La Bussière,45,Loiret,24,Centre-Val de Loire
45061,CEPOY,45120,CEPOY,,48.0508863567,2.74010416872,61,,Cepoy,Cepoy,45,Loiret,24,Centre-Val de Loire
45062,CERCOTTES,45520,CERCOTTES,,47.9871091205,1.89710126122,62,,Cercottes,Cercottes,45,Loiret,24,Centre-Val de Loire
45063,CERDON,45620,CERDON,,47.6299186758,2.38147298677,63,,Cerdon,Cerdon,45,Loiret,24,Centre-Val de Loire
45064,CERNOY EN BERRY,45360,CERNOY EN BERRY,,47.5461071494,2.65258926935,64,,Cernoy-en-Berry,Cernoy-en-Berry,45,Loiret,24,Centre-Val de Loire
45065,CESARVILLE DOSSAINVILLE,45300,CESARVILLE DOSSAINVILLE,,48.2628087686,2.28517501246,65,,Césarville-Dossainville,Césarville-Dossainville,45,Loiret,24,Centre-Val de Loire
45065,CESARVILLE DOSSAINVILLE,45300,CESARVILLE DOSSAINVILLE,DOSSAINVILLE,48.2628087686,2.28517501246,65,,Césarville-Dossainville,Césarville-Dossainville,45,Loiret,24,Centre-Val de Loire
45066,CHAILLY EN GATINAIS,45260,CHAILLY EN GATINAIS,,47.936543644,2.5277446897,66,,Chailly-en-Gâtinais,Chailly-en-Gâtinais,45,Loiret,24,Centre-Val de Loire
45067,CHAINGY,45380,CHAINGY,,47.8914794989,1.77358113463,67,,Chaingy,Chaingy,45,Loiret,24,Centre-Val de Loire
45068,CHALETTE SUR LOING,45120,CHALETTE SUR LOING,,48.0188121595,2.73578852299,68,,Châlette-sur-Loing,Châlette-sur-Loing,45,Loiret,24,Centre-Val de Loire
45069,CHAMBON LA FORET,45340,CHAMBON LA FORET,,48.0490368743,2.28348190868,69,,Chambon-la-Forêt,Chambon-la-Forêt,45,Loiret,24,Centre-Val de Loire
45070,CHAMPOULET,45420,CHAMPOULET,,47.6510461411,2.9231554998,70,,Champoulet,Champoulet,45,Loiret,24,Centre-Val de Loire
45072,CHANTEAU,45400,CHANTEAU,,47.9761797581,1.9646366293,72,,Chanteau,Chanteau,45,Loiret,24,Centre-Val de Loire
45073,CHANTECOQ,45320,CHANTECOQ,,48.0427948313,2.97001053267,73,,Chantecoq,Chantecoq,45,Loiret,24,Centre-Val de Loire
45074,LA CHAPELLE ONZERAIN,45310,LA CHAPELLE ONZERAIN,,48.0330297955,1.61102196598,74,La,Chapelle-Onzerain,La Chapelle-Onzerain,45,Loiret,24,Centre-Val de Loire
45075,LA CHAPELLE ST MESMIN,45380,LA CHAPELLE ST MESMIN,,47.8887063362,1.82847184827,75,La,Chapelle-Saint-Mesmin,La Chapelle-Saint-Mesmin,45,Loiret,24,Centre-Val de Loire
45076,LA CHAPELLE ST SEPULCRE,45210,LA CHAPELLE ST SEPULCRE,,48.0148810621,2.83674164309,76,La,Chapelle-Saint-Sépulcre,La Chapelle-Saint-Sépulcre,45,Loiret,24,Centre-Val de Loire
45077,LA CHAPELLE SUR AVEYRON,45230,LA CHAPELLE SUR AVEYRON,,47.8707521485,2.87448096563,77,La,Chapelle-sur-Aveyron,La Chapelle-sur-Aveyron,45,Loiret,24,Centre-Val de Loire
45078,CHAPELON,45270,CHAPELON,,48.0368069969,2.5779425659,78,,Chapelon,Chapelon,45,Loiret,24,Centre-Val de Loire
45079,LE CHARME,45230,LE CHARME,,47.8016829146,2.99256273741,79,Le,Charme,Le Charme,45,Loiret,24,Centre-Val de Loire
45080,CHARMONT EN BEAUCE,45480,CHARMONT EN BEAUCE,,48.2376605763,2.11465872922,80,,Charmont-en-Beauce,Charmont-en-Beauce,45,Loiret,24,Centre-Val de Loire
45081,CHARSONVILLE,45130,CHARSONVILLE,,47.9286176741,1.57432513599,81,,Charsonville,Charsonville,45,Loiret,24,Centre-Val de Loire
45082,CHATEAUNEUF SUR LOIRE,45110,CHATEAUNEUF SUR LOIRE,,47.8851472101,2.23195371871,82,,Châteauneuf-sur-Loire,Châteauneuf-sur-Loire,45,Loiret,24,Centre-Val de Loire
45083,CHATEAU RENARD,45220,CHATEAU RENARD,,47.9266379127,2.92209191737,83,,Château-Renard,Château-Renard,45,Loiret,24,Centre-Val de Loire
45084,CHATENOY,45260,CHATENOY,,47.9161167127,2.40625738372,84,,Châtenoy,Châtenoy,45,Loiret,24,Centre-Val de Loire
45085,CHATILLON COLIGNY,45230,CHATILLON COLIGNY,,47.8233390876,2.87503736622,85,,Châtillon-Coligny,Châtillon-Coligny,45,Loiret,24,Centre-Val de Loire
45086,CHATILLON LE ROI,45480,CHATILLON LE ROI,,48.1641485048,2.10220628219,86,,Châtillon-le-Roi,Châtillon-le-Roi,45,Loiret,24,Centre-Val de Loire
45087,CHATILLON SUR LOIRE,45360,CHATILLON SUR LOIRE,,47.5721313447,2.73594191784,87,,Châtillon-sur-Loire,Châtillon-sur-Loire,45,Loiret,24,Centre-Val de Loire
45088,CHAUSSY,45480,CHAUSSY,,48.1629842121,1.99352125948,88,,Chaussy,Chaussy,45,Loiret,24,Centre-Val de Loire
45089,CHECY,45430,CHECY,,47.9024535913,2.02294070962,89,,Chécy,Chécy,45,Loiret,24,Centre-Val de Loire
45091,CHEVANNES,45210,CHEVANNES,,48.1314865857,2.85958457085,91,,Chevannes,Chevannes,45,Loiret,24,Centre-Val de Loire
45092,CHEVILLON SUR HUILLARD,45700,CHEVILLON SUR HUILLARD,,47.9668347404,2.62231250927,92,,Chevillon-sur-Huillard,Chevillon-sur-Huillard,45,Loiret,24,Centre-Val de Loire
45093,CHEVILLY,45520,CHEVILLY,,48.0300099024,1.89353421396,93,,Chevilly,Chevilly,45,Loiret,24,Centre-Val de Loire
45094,CHEVRY SOUS LE BIGNON,45210,CHEVRY SOUS LE BIGNON,,48.145082059,2.89381357786,94,,Chevry-sous-le-Bignon,Chevry-sous-le-Bignon,45,Loiret,24,Centre-Val de Loire
45095,CHILLEURS AUX BOIS,45170,CHILLEURS AUX BOIS,,48.0570941701,2.14189355578,95,,Chilleurs-aux-Bois,Chilleurs-aux-Bois,45,Loiret,24,Centre-Val de Loire
45096,LES CHOUX,45290,LES CHOUX,,47.787194965,2.65608741368,96,Les,Choux,Les Choux,45,Loiret,24,Centre-Val de Loire
45097,CHUELLES,45220,CHUELLES,,47.9969133671,2.96866607719,97,,Chuelles,Chuelles,45,Loiret,24,Centre-Val de Loire
45098,CLERY ST ANDRE,45370,CLERY ST ANDRE,,47.8147484728,1.76833503654,98,,Cléry-Saint-André,Cléry-Saint-André,45,Loiret,24,Centre-Val de Loire
45099,COINCES,45310,COINCES,,48.0172747838,1.73272649419,99,,Coinces,Coinces,45,Loiret,24,Centre-Val de Loire
45100,COMBLEUX,45800,COMBLEUX,,47.9000269485,1.98662135152,100,,Combleux,Combleux,45,Loiret,24,Centre-Val de Loire
45101,COMBREUX,45530,COMBREUX,,47.9564996872,2.30829387776,101,,Combreux,Combreux,45,Loiret,24,Centre-Val de Loire
45102,CONFLANS SUR LOING,45700,CONFLANS SUR LOING,,47.9459451483,2.77660641899,102,,Conflans-sur-Loing,Conflans-sur-Loing,45,Loiret,24,Centre-Val de Loire
45103,CORBEILLES,45490,CORBEILLES,,48.0745693135,2.55961870773,103,,Corbeilles,Corbeilles,45,Loiret,24,Centre-Val de Loire
45104,CORQUILLEROY,45120,CORQUILLEROY,,48.0408609614,2.69380653826,104,,Corquilleroy,Corquilleroy,45,Loiret,24,Centre-Val de Loire
45105,CORTRAT,45700,CORTRAT,,47.9068076955,2.76916633215,105,,Cortrat,Cortrat,45,Loiret,24,Centre-Val de Loire
45107,COUDROY,45260,COUDROY,,47.9047107806,2.47473580534,107,,Coudroy,Coudroy,45,Loiret,24,Centre-Val de Loire
45108,COULLONS,45720,COULLONS,,47.6179791467,2.50615129884,108,,Coullons,Coullons,45,Loiret,24,Centre-Val de Loire
45109,COULMIERS,45130,COULMIERS,,47.9319011305,1.6588665459,109,,Coulmiers,Coulmiers,45,Loiret,24,Centre-Val de Loire
45110,COURCELLES,45300,COURCELLES,,48.0980883547,2.32759731775,110,,Courcelles,Courcelles,45,Loiret,24,Centre-Val de Loire
45111,COURCY AUX LOGES,45300,COURCY AUX LOGES,,48.0546832244,2.21728739508,111,,Courcy-aux-Loges,Courcy-aux-Loges,45,Loiret,24,Centre-Val de Loire
45112,LA COUR MARIGNY,45260,LA COUR MARIGNY,,47.8905390311,2.58364447475,112,La,Cour-Marigny,La Cour-Marigny,45,Loiret,24,Centre-Val de Loire
45113,COURTEMAUX,45320,COURTEMAUX,,48.0444652344,2.93270092042,113,,Courtemaux,Courtemaux,45,Loiret,24,Centre-Val de Loire
45114,COURTEMPIERRE,45490,COURTEMPIERRE,,48.0948306091,2.63504660906,114,,Courtempierre,Courtempierre,45,Loiret,24,Centre-Val de Loire
45115,COURTENAY,45320,COURTENAY,,48.0219252443,3.06237469889,115,,Courtenay,Courtenay,45,Loiret,24,Centre-Val de Loire
45116,CRAVANT,45190,CRAVANT,,47.8334827429,1.58096102473,116,,Cravant,Cravant,45,Loiret,24,Centre-Val de Loire
45118,CROTTES EN PITHIVERAIS,45170,CROTTES EN PITHIVERAIS,,48.1141192289,2.06490558208,118,,Crottes-en-Pithiverais,Crottes-en-Pithiverais,45,Loiret,24,Centre-Val de Loire
45118,CROTTES EN PITHIVERAIS,45170,CROTTES EN PITHIVERAIS,TEILLAY ST BENOIT,48.1141192289,2.06490558208,118,,Crottes-en-Pithiverais,Crottes-en-Pithiverais,45,Loiret,24,Centre-Val de Loire
45119,DADONVILLE,45300,DADONVILLE,,48.1525880159,2.26941914573,119,,Dadonville,Dadonville,45,Loiret,24,Centre-Val de Loire
45120,DAMMARIE EN PUISAYE,45420,DAMMARIE EN PUISAYE,,47.6332322961,2.86087598326,120,,Dammarie-en-Puisaye,Dammarie-en-Puisaye,45,Loiret,24,Centre-Val de Loire
45121,DAMMARIE SUR LOING,45230,DAMMARIE SUR LOING,,47.7817425428,2.87377434188,121,,Dammarie-sur-Loing,Dammarie-sur-Loing,45,Loiret,24,Centre-Val de Loire
45122,DAMPIERRE EN BURLY,45570,DAMPIERRE EN BURLY,,47.76127445,2.54303804345,122,,Dampierre-en-Burly,Dampierre-en-Burly,45,Loiret,24,Centre-Val de Loire
45123,DARVOY,45150,DARVOY,,47.8524672479,2.08968604599,123,,Darvoy,Darvoy,45,Loiret,24,Centre-Val de Loire
45124,DESMONTS,45390,DESMONTS,,48.2267906645,2.4997128367,124,,Desmonts,Desmonts,45,Loiret,24,Centre-Val de Loire
45125,DIMANCHEVILLE,45390,DIMANCHEVILLE,,48.2388526443,2.42400106585,125,,Dimancheville,Dimancheville,45,Loiret,24,Centre-Val de Loire
45126,DONNERY,45450,DONNERY,,47.9140447102,2.10376821418,126,,Donnery,Donnery,45,Loiret,24,Centre-Val de Loire
45127,DORDIVES,45680,DORDIVES,,48.147384869,2.77436813203,127,,Dordives,Dordives,45,Loiret,24,Centre-Val de Loire
45129,DOUCHY MONTCORBON,45220,DOUCHY MONTCORBON,,47.941934324,3.04405678632,129,,Douchy-Montcorbon,Douchy-Montcorbon,45,Loiret,24,Centre-Val de Loire
45129,DOUCHY MONTCORBON,45220,DOUCHY MONTCORBON,MONTCORBON,47.941934324,3.04405678632,129,,Douchy-Montcorbon,Douchy-Montcorbon,45,Loiret,24,Centre-Val de Loire
45130,DRY,45370,DRY,,47.7916018674,1.7282632469,130,,Dry,Dry,45,Loiret,24,Centre-Val de Loire
45131,ECHILLEUSES,45390,ECHILLEUSES,,48.1695380389,2.44182563479,131,,Échilleuses,Échilleuses,45,Loiret,24,Centre-Val de Loire
45132,EGRY,45340,EGRY,,48.1008974565,2.44089841893,132,,Égry,Égry,45,Loiret,24,Centre-Val de Loire
45133,ENGENVILLE,45300,ENGENVILLE,,48.2343065606,2.23634182312,133,,Engenville,Engenville,45,Loiret,24,Centre-Val de Loire
45134,EPIEDS EN BEAUCE,45130,EPIEDS EN BEAUCE,,47.9627066917,1.60390030402,134,,Épieds-en-Beauce,Épieds-en-Beauce,45,Loiret,24,Centre-Val de Loire
45135,ERCEVILLE,45480,ERCEVILLE,,48.2454305059,2.0267549057,135,,Erceville,Erceville,45,Loiret,24,Centre-Val de Loire
45136,ERVAUVILLE,45320,ERVAUVILLE,,48.0942224093,2.98276692031,136,,Ervauville,Ervauville,45,Loiret,24,Centre-Val de Loire
45137,ESCRENNES,45300,ESCRENNES,,48.1364055687,2.18433088094,137,,Escrennes,Escrennes,45,Loiret,24,Centre-Val de Loire
45138,ESCRIGNELLES,45250,ESCRIGNELLES,,47.7126670746,2.81932848772,138,,Escrignelles,Escrignelles,45,Loiret,24,Centre-Val de Loire
45139,ESTOUY,45300,ESTOUY,,48.1896152924,2.32216627868,139,,Estouy,Estouy,45,Loiret,24,Centre-Val de Loire
45141,FAVERELLES,45420,FAVERELLES,,47.5870072341,2.93687989156,141,,Faverelles,Faverelles,45,Loiret,24,Centre-Val de Loire
45142,FAY AUX LOGES,45450,FAY AUX LOGES,,47.9326007256,2.16025043268,142,,Fay-aux-Loges,Fay-aux-Loges,45,Loiret,24,Centre-Val de Loire
45143,FEINS EN GATINAIS,45230,FEINS EN GATINAIS,,47.746772016,2.83706429423,143,,Feins-en-Gâtinais,Feins-en-Gâtinais,45,Loiret,24,Centre-Val de Loire
45144,FEROLLES,45150,FEROLLES,,47.8252781829,2.11682260412,144,,Férolles,Férolles,45,Loiret,24,Centre-Val de Loire
45145,FERRIERES EN GATINAIS,45210,FERRIERES EN GATINAIS,,48.1055360921,2.80426860413,145,,Ferrières-en-Gâtinais,Ferrières-en-Gâtinais,45,Loiret,24,Centre-Val de Loire
45146,LA FERTE ST AUBIN,45240,LA FERTE ST AUBIN,,47.7119750833,1.92714666074,146,La,Ferté-Saint-Aubin,La Ferté-Saint-Aubin,45,Loiret,24,Centre-Val de Loire
45147,FLEURY LES AUBRAIS,45400,FLEURY LES AUBRAIS,,47.9389266189,1.9198911565,147,,Fleury-les-Aubrais,Fleury-les-Aubrais,45,Loiret,24,Centre-Val de Loire
45148,FONTENAY SUR LOING,45210,FONTENAY SUR LOING,,48.0832664847,2.76885759173,148,,Fontenay-sur-Loing,Fontenay-sur-Loing,45,Loiret,24,Centre-Val de Loire
45149,FOUCHEROLLES,45320,FOUCHEROLLES,,48.0971691274,3.0183707714,149,,Foucherolles,Foucherolles,45,Loiret,24,Centre-Val de Loire
45150,FREVILLE DU GATINAIS,45270,FREVILLE DU GATINAIS,,48.0208335468,2.44856219378,150,,Fréville-du-Gâtinais,Fréville-du-Gâtinais,45,Loiret,24,Centre-Val de Loire
45151,GAUBERTIN,45340,GAUBERTIN,,48.1258029948,2.42594783192,151,,Gaubertin,Gaubertin,45,Loiret,24,Centre-Val de Loire
45152,GEMIGNY,45310,GEMIGNY,,47.9627627539,1.70271671519,152,,Gémigny,Gémigny,45,Loiret,24,Centre-Val de Loire
45153,GERMIGNY DES PRES,45110,GERMIGNY DES PRES,,47.8392387669,2.26614623984,153,,Germigny-des-Prés,Germigny-des-Prés,45,Loiret,24,Centre-Val de Loire
45154,GIDY,45520,GIDY,,47.9913355091,1.83586877013,154,,Gidy,Gidy,45,Loiret,24,Centre-Val de Loire
45155,GIEN,45500,GIEN,,47.71227944,2.66549550204,155,,Gien,Gien,45,Loiret,24,Centre-Val de Loire
45155,GIEN,45500,GIEN,ARRABLOY,47.71227944,2.66549550204,155,,Gien,Gien,45,Loiret,24,Centre-Val de Loire
45156,GIROLLES,45120,GIROLLES,,48.0724580297,2.71767735428,156,,Girolles,Girolles,45,Loiret,24,Centre-Val de Loire
45157,GIVRAINES,45300,GIVRAINES,,48.1510160951,2.38245438786,157,,Givraines,Givraines,45,Loiret,24,Centre-Val de Loire
45158,GONDREVILLE,45490,GONDREVILLE,,48.0527905601,2.65050023399,158,,Gondreville,Gondreville,45,Loiret,24,Centre-Val de Loire
45159,GRANGERMONT,45390,GRANGERMONT,,48.1878936504,2.42926240539,159,,Grangermont,Grangermont,45,Loiret,24,Centre-Val de Loire
45160,GRENEVILLE EN BEAUCE,45480,GRENEVILLE EN BEAUCE,,48.189368971,2.11144995859,160,,Greneville-en-Beauce,Greneville-en-Beauce,45,Loiret,24,Centre-Val de Loire
45160,GRENEVILLE EN BEAUCE,45480,GRENEVILLE EN BEAUCE,GUIGNONVILLE,48.189368971,2.11144995859,160,,Greneville-en-Beauce,Greneville-en-Beauce,45,Loiret,24,Centre-Val de Loire
45161,GRISELLES,45210,GRISELLES,,48.0735835169,2.83901936313,161,,Griselles,Griselles,45,Loiret,24,Centre-Val de Loire
45162,GUIGNEVILLE,45300,GUIGNEVILLE,,48.2157779441,2.17390368051,162,,Guigneville,Guigneville,45,Loiret,24,Centre-Val de Loire
45162,GUIGNEVILLE,45300,GUIGNEVILLE,SEBOUVILLE,48.2157779441,2.17390368051,162,,Guigneville,Guigneville,45,Loiret,24,Centre-Val de Loire
45164,GUILLY,45600,GUILLY,,47.8054009428,2.27151487858,164,,Guilly,Guilly,45,Loiret,24,Centre-Val de Loire
45165,GY LES NONAINS,45220,GY LES NONAINS,,47.9287539201,2.84603975187,165,,Gy-les-Nonains,Gy-les-Nonains,45,Loiret,24,Centre-Val de Loire
45166,HUETRE,45520,HUETRE,,48.0255590389,1.80141041043,166,,Huêtre,Huêtre,45,Loiret,24,Centre-Val de Loire
45167,HUISSEAU SUR MAUVES,45130,HUISSEAU SUR MAUVES,,47.8939094608,1.70052467994,167,,Huisseau-sur-Mauves,Huisseau-sur-Mauves,45,Loiret,24,Centre-Val de Loire
45168,INGRANNES,45450,INGRANNES,,47.9939802289,2.2247865408,168,,Ingrannes,Ingrannes,45,Loiret,24,Centre-Val de Loire
45169,INGRE,45140,INGRE,,47.916936377,1.82353479018,169,,Ingré,Ingré,45,Loiret,24,Centre-Val de Loire
45170,INTVILLE LA GUETARD,45300,INTVILLE LA GUETARD,,48.2546123622,2.21608120252,170,,Intville-la-Guétard,Intville-la-Guétard,45,Loiret,24,Centre-Val de Loire
45171,ISDES,45620,ISDES,,47.6638922012,2.2607343727,171,,Isdes,Isdes,45,Loiret,24,Centre-Val de Loire
45173,JARGEAU,45150,JARGEAU,,47.8539370317,2.12063354743,173,,Jargeau,Jargeau,45,Loiret,24,Centre-Val de Loire
45174,JOUY EN PITHIVERAIS,45480,JOUY EN PITHIVERAIS,,48.1497403128,2.13178750003,174,,Jouy-en-Pithiverais,Jouy-en-Pithiverais,45,Loiret,24,Centre-Val de Loire
45175,JOUY LE POTIER,45370,JOUY LE POTIER,,47.747623391,1.80832266526,175,,Jouy-le-Potier,Jouy-le-Potier,45,Loiret,24,Centre-Val de Loire
45176,JURANVILLE,45340,JURANVILLE,,48.0610597586,2.48280852307,176,,Juranville,Juranville,45,Loiret,24,Centre-Val de Loire
45177,LAAS,45300,LAAS,,48.1209542123,2.21821996129,177,,Laas,Laas,45,Loiret,24,Centre-Val de Loire
45178,LADON,45270,LADON,,48.0095614328,2.52562698613,178,,Ladon,Ladon,45,Loiret,24,Centre-Val de Loire
45179,LAILLY EN VAL,45740,LAILLY EN VAL,,47.7471091867,1.71487513333,179,,Lailly-en-Val,Lailly-en-Val,45,Loiret,24,Centre-Val de Loire
45180,LANGESSE,45290,LANGESSE,,47.8171567468,2.6450051213,180,,Langesse,Langesse,45,Loiret,24,Centre-Val de Loire
45181,LEOUVILLE,45480,LEOUVILLE,,48.2233393249,2.08701663707,181,,Léouville,Léouville,45,Loiret,24,Centre-Val de Loire
45182,LIGNY LE RIBAULT,45240,LIGNY LE RIBAULT,,47.6832812025,1.79078141951,182,,Ligny-le-Ribault,Ligny-le-Ribault,45,Loiret,24,Centre-Val de Loire
45183,LION EN BEAUCE,45410,LION EN BEAUCE,,48.1323882717,1.93217383047,183,,Lion-en-Beauce,Lion-en-Beauce,45,Loiret,24,Centre-Val de Loire
45184,LION EN SULLIAS,45600,LION EN SULLIAS,,47.7235377028,2.47883011151,184,,Lion-en-Sullias,Lion-en-Sullias,45,Loiret,24,Centre-Val de Loire
45185,LOMBREUIL,45700,LOMBREUIL,,47.9423074217,2.62713331194,185,,Lombreuil,Lombreuil,45,Loiret,24,Centre-Val de Loire
45186,LORCY,45490,LORCY,,48.0422457251,2.52798759325,186,,Lorcy,Lorcy,45,Loiret,24,Centre-Val de Loire
45187,LORRIS,45260,LORRIS,,47.86274799,2.49842209081,187,,Lorris,Lorris,45,Loiret,24,Centre-Val de Loire
45188,LOURY,45470,LOURY,,48.0101727408,2.09187881925,188,,Loury,Loury,45,Loiret,24,Centre-Val de Loire
45189,LOUZOUER,45210,LOUZOUER,,48.0344043134,2.86967177555,189,,Louzouer,Louzouer,45,Loiret,24,Centre-Val de Loire
45191,LE MALESHERBOIS,45300,LE MALESHERBOIS,MANCHECOURT,48.2894150644,2.39799452821,191,Le,Malesherbois,Le Malesherbois,45,Loiret,24,Centre-Val de Loire
45191,LE MALESHERBOIS,45330,LE MALESHERBOIS,COUDRAY,48.2894150644,2.39799452821,191,Le,Malesherbois,Le Malesherbois,45,Loiret,24,Centre-Val de Loire
45191,LE MALESHERBOIS,45330,LE MALESHERBOIS,LABROSSE,48.2894150644,2.39799452821,191,Le,Malesherbois,Le Malesherbois,45,Loiret,24,Centre-Val de Loire
45191,LE MALESHERBOIS,45330,LE MALESHERBOIS,MAINVILLIERS,48.2894150644,2.39799452821,191,Le,Malesherbois,Le Malesherbois,45,Loiret,24,Centre-Val de Loire
45191,LE MALESHERBOIS,45330,LE MALESHERBOIS,MALESHERBES,48.2894150644,2.39799452821,191,Le,Malesherbois,Le Malesherbois,45,Loiret,24,Centre-Val de Loire
45191,LE MALESHERBOIS,45330,LE MALESHERBOIS,NANGEVILLE,48.2894150644,2.39799452821,191,Le,Malesherbois,Le Malesherbois,45,Loiret,24,Centre-Val de Loire
45191,LE MALESHERBOIS,45330,LE MALESHERBOIS,ORVEAU BELLESAUVE,48.2894150644,2.39799452821,191,Le,Malesherbois,Le Malesherbois,45,Loiret,24,Centre-Val de Loire
45193,MARCILLY EN VILLETTE,45240,MARCILLY EN VILLETTE,,47.7717120414,2.03429227556,193,,Marcilly-en-Villette,Marcilly-en-Villette,45,Loiret,24,Centre-Val de Loire
45194,MARDIE,45430,MARDIE,,47.8973215597,2.06871909957,194,,Mardié,Mardié,45,Loiret,24,Centre-Val de Loire
45194,MARDIE,45430,MARDIE,PONT AUX MOINES,47.8973215597,2.06871909957,194,,Mardié,Mardié,45,Loiret,24,Centre-Val de Loire
45195,MAREAU AUX BOIS,45300,MAREAU AUX BOIS,,48.0983163957,2.19061248638,195,,Mareau-aux-Bois,Mareau-aux-Bois,45,Loiret,24,Centre-Val de Loire
45196,MAREAU AUX PRES,45370,MAREAU AUX PRES,,47.8459261373,1.76588121396,196,,Mareau-aux-Prés,Mareau-aux-Prés,45,Loiret,24,Centre-Val de Loire
45197,MARIGNY LES USAGES,45760,MARIGNY LES USAGES,,47.9586997126,2.01335739058,197,,Marigny-les-Usages,Marigny-les-Usages,45,Loiret,24,Centre-Val de Loire
45198,MARSAINVILLIERS,45300,MARSAINVILLIERS,,48.2129131551,2.27113030175,198,,Marsainvilliers,Marsainvilliers,45,Loiret,24,Centre-Val de Loire
45199,MELLEROY,45220,MELLEROY,,47.8880039489,2.96021631597,199,,Melleroy,Melleroy,45,Loiret,24,Centre-Val de Loire
45200,MENESTREAU EN VILLETTE,45240,MENESTREAU EN VILLETTE,,47.7097936661,2.03537007798,200,,Ménestreau-en-Villette,Ménestreau-en-Villette,45,Loiret,24,Centre-Val de Loire
45201,MERINVILLE,45210,MERINVILLE,,48.0904715969,2.94133692583,201,,Mérinville,Mérinville,45,Loiret,24,Centre-Val de Loire
45202,MESSAS,45190,MESSAS,,47.8085997033,1.63534579701,202,,Messas,Messas,45,Loiret,24,Centre-Val de Loire
45203,MEUNG SUR LOIRE,45130,MEUNG SUR LOIRE,,47.8397230739,1.69811579508,203,,Meung-sur-Loire,Meung-sur-Loire,45,Loiret,24,Centre-Val de Loire
45204,MEZIERES LEZ CLERY,45370,MEZIERES LEZ CLERY,,47.8109765649,1.81743017495,204,,Mézières-lez-Cléry,Mézières-lez-Cléry,45,Loiret,24,Centre-Val de Loire
45205,MEZIERES EN GATINAIS,45270,MEZIERES EN GATINAIS,,48.024673349,2.48281736581,205,,Mézières-en-Gâtinais,Mézières-en-Gâtinais,45,Loiret,24,Centre-Val de Loire
45206,MIGNERES,45490,MIGNERES,,48.0485463347,2.62158631692,206,,Mignères,Mignères,45,Loiret,24,Centre-Val de Loire
45207,MIGNERETTE,45490,MIGNERETTE,,48.0611321152,2.60460243093,207,,Mignerette,Mignerette,45,Loiret,24,Centre-Val de Loire
45208,MONTARGIS,45200,MONTARGIS,,47.9988427684,2.73588922015,208,,Montargis,Montargis,45,Loiret,24,Centre-Val de Loire
45209,MONTBARROIS,45340,MONTBARROIS,,48.0489827491,2.39677087385,209,,Montbarrois,Montbarrois,45,Loiret,24,Centre-Val de Loire
45210,MONTBOUY,45230,MONTBOUY,,47.8669064793,2.82234591317,210,,Montbouy,Montbouy,45,Loiret,24,Centre-Val de Loire
45212,MONTCRESSON,45700,MONTCRESSON,,47.9110568348,2.8050629684,212,,Montcresson,Montcresson,45,Loiret,24,Centre-Val de Loire
45213,MONTEREAU,45260,MONTEREAU,,47.8353730505,2.56194977117,213,,Montereau,Montereau,45,Loiret,24,Centre-Val de Loire
45214,MONTIGNY,45170,MONTIGNY,,48.1085281835,2.1130722211,214,,Montigny,Montigny,45,Loiret,24,Centre-Val de Loire
45215,MONTLIARD,45340,MONTLIARD,,48.0177704281,2.40056790867,215,,Montliard,Montliard,45,Loiret,24,Centre-Val de Loire
45216,MORMANT SUR VERNISSON,45700,MORMANT SUR VERNISSON,,47.9416433516,2.72924665655,216,,Mormant-sur-Vernisson,Mormant-sur-Vernisson,45,Loiret,24,Centre-Val de Loire
45217,MORVILLE EN BEAUCE,45300,MORVILLE EN BEAUCE,,48.2550792825,2.15858235508,217,,Morville-en-Beauce,Morville-en-Beauce,45,Loiret,24,Centre-Val de Loire
45218,LE MOULINET SUR SOLIN,45290,LE MOULINET SUR SOLIN,,47.817291457,2.61111743107,218,Le,Moulinet-sur-Solin,Le Moulinet-sur-Solin,45,Loiret,24,Centre-Val de Loire
45219,MOULON,45270,MOULON,,48.018833927,2.59545812326,219,,Moulon,Moulon,45,Loiret,24,Centre-Val de Loire
45220,NANCRAY SUR RIMARDE,45340,NANCRAY SUR RIMARDE,,48.0673023784,2.3232297826,220,,Nancray-sur-Rimarde,Nancray-sur-Rimarde,45,Loiret,24,Centre-Val de Loire
45222,NARGIS,45210,NARGIS,,48.1109318458,2.73790103266,222,,Nargis,Nargis,45,Loiret,24,Centre-Val de Loire
45223,NESPLOY,45270,NESPLOY,,47.9957733046,2.36897540988,223,,Nesploy,Nesploy,45,Loiret,24,Centre-Val de Loire
45224,NEUVILLE AUX BOIS,45170,NEUVILLE AUX BOIS,,48.0689002609,2.0592389448,224,,Neuville-aux-Bois,Neuville-aux-Bois,45,Loiret,24,Centre-Val de Loire
45225,LA NEUVILLE SUR ESSONNE,45390,LA NEUVILLE SUR ESSONNE,,48.1799204528,2.37951846195,225,La,Neuville-sur-Essonne,La Neuville-sur-Essonne,45,Loiret,24,Centre-Val de Loire
45226,NEUVY EN SULLIAS,45510,NEUVY EN SULLIAS,,47.7747351435,2.24319824191,226,,Neuvy-en-Sullias,Neuvy-en-Sullias,45,Loiret,24,Centre-Val de Loire
45227,NEVOY,45500,NEVOY,,47.7333698571,2.59240936936,227,,Nevoy,Nevoy,45,Loiret,24,Centre-Val de Loire
45228,NIBELLE,45340,NIBELLE,,48.0140757503,2.31162556576,228,,Nibelle,Nibelle,45,Loiret,24,Centre-Val de Loire
45229,NOGENT SUR VERNISSON,45290,NOGENT SUR VERNISSON,,47.8386989042,2.72939898012,229,,Nogent-sur-Vernisson,Nogent-sur-Vernisson,45,Loiret,24,Centre-Val de Loire
45230,NOYERS,45260,NOYERS,,47.9091167277,2.53634979888,230,,Noyers,Noyers,45,Loiret,24,Centre-Val de Loire
45231,OISON,45170,OISON,,48.1345415806,1.97598018826,231,,Oison,Oison,45,Loiret,24,Centre-Val de Loire
45232,OLIVET,45160,OLIVET,,47.8546997503,1.88823884753,232,,Olivet,Olivet,45,Loiret,24,Centre-Val de Loire
45233,ONDREVILLE SUR ESSONNE,45390,ONDREVILLE SUR ESSONNE,,48.198009658,2.41619329627,233,,Ondreville-sur-Essonne,Ondreville-sur-Essonne,45,Loiret,24,Centre-Val de Loire
45234,ORLEANS,45000,ORLEANS,,47.8828634214,1.91610357477,234,,Orléans,Orléans,45,Loiret,24,Centre-Val de Loire
45234,ORLEANS,45100,ORLEANS,,47.8828634214,1.91610357477,234,,Orléans,Orléans,45,Loiret,24,Centre-Val de Loire
45234,ORLEANS,45100,ORLEANS,LA SOURCE,47.8828634214,1.91610357477,234,,Orléans,Orléans,45,Loiret,24,Centre-Val de Loire
45235,ORMES,45140,ORMES,,47.9478927355,1.8071403661,235,,Ormes,Ormes,45,Loiret,24,Centre-Val de Loire
45237,ORVILLE,45390,ORVILLE,,48.2420197745,2.45858952893,237,,Orville,Orville,45,Loiret,24,Centre-Val de Loire
45238,OUSSON SUR LOIRE,45250,OUSSON SUR LOIRE,,47.6001760382,2.79533560153,238,,Ousson-sur-Loire,Ousson-sur-Loire,45,Loiret,24,Centre-Val de Loire
45239,OUSSOY EN GATINAIS,45290,OUSSOY EN GATINAIS,,47.9013234863,2.63700159517,239,,Oussoy-en-Gâtinais,Oussoy-en-Gâtinais,45,Loiret,24,Centre-Val de Loire
45240,OUTARVILLE,45480,OUTARVILLE,,48.2142854596,2.02173092617,240,,Outarville,Outarville,45,Loiret,24,Centre-Val de Loire
45240,OUTARVILLE,45480,OUTARVILLE,ALLAINVILLE EN BEAUCE,48.2142854596,2.02173092617,240,,Outarville,Outarville,45,Loiret,24,Centre-Val de Loire
45240,OUTARVILLE,45480,OUTARVILLE,FARONVILLE,48.2142854596,2.02173092617,240,,Outarville,Outarville,45,Loiret,24,Centre-Val de Loire
45240,OUTARVILLE,45480,OUTARVILLE,ST PERAVY EPREUX,48.2142854596,2.02173092617,240,,Outarville,Outarville,45,Loiret,24,Centre-Val de Loire
45240,OUTARVILLE,45480,OUTARVILLE,TEILLAY LE GAUDIN,48.2142854596,2.02173092617,240,,Outarville,Outarville,45,Loiret,24,Centre-Val de Loire
45241,OUVROUER LES CHAMPS,45150,OUVROUER LES CHAMPS,,47.8367781695,2.17987819757,241,,Ouvrouer-les-Champs,Ouvrouer-les-Champs,45,Loiret,24,Centre-Val de Loire
45242,OUZOUER DES CHAMPS,45290,OUZOUER DES CHAMPS,,47.8765424809,2.70945964762,242,,Ouzouer-des-Champs,Ouzouer-des-Champs,45,Loiret,24,Centre-Val de Loire
45243,OUZOUER SOUS BELLEGARDE,45270,OUZOUER SOUS BELLEGARDE,,47.9891839789,2.4796703966,243,,Ouzouer-sous-Bellegarde,Ouzouer-sous-Bellegarde,45,Loiret,24,Centre-Val de Loire
45244,OUZOUER SUR LOIRE,45570,OUZOUER SUR LOIRE,,47.7843937,2.47737458282,244,,Ouzouer-sur-Loire,Ouzouer-sur-Loire,45,Loiret,24,Centre-Val de Loire
45245,OUZOUER SUR TREZEE,45250,OUZOUER SUR TREZEE,,47.6754280344,2.81002906457,245,,Ouzouer-sur-Trézée,Ouzouer-sur-Trézée,45,Loiret,24,Centre-Val de Loire
45246,PANNECIERES,45300,PANNECIERES,,48.2928470394,2.14266488895,246,,Pannecières,Pannecières,45,Loiret,24,Centre-Val de Loire
45247,PANNES,45700,PANNES,,48.0121009913,2.66878562628,247,,Pannes,Pannes,45,Loiret,24,Centre-Val de Loire
45248,PATAY,45310,PATAY,,48.050944058,1.69412591416,248,,Patay,Patay,45,Loiret,24,Centre-Val de Loire
45249,PAUCOURT,45200,PAUCOURT,,48.038602522,2.78809051879,249,,Paucourt,Paucourt,45,Loiret,24,Centre-Val de Loire
45250,PERS EN GATINAIS,45210,PERS EN GATINAIS,,48.1174253817,2.90621310678,250,,Pers-en-Gâtinais,Pers-en-Gâtinais,45,Loiret,24,Centre-Val de Loire
45251,PIERREFITTE ES BOIS,45360,PIERREFITTE ES BOIS,,47.5109534667,2.69207750108,251,,Pierrefitte-ès-Bois,Pierrefitte-ès-Bois,45,Loiret,24,Centre-Val de Loire
45252,PITHIVIERS,45300,PITHIVIERS,,48.1805102613,2.24994341572,252,,Pithiviers,Pithiviers,45,Loiret,24,Centre-Val de Loire
45253,PITHIVIERS LE VIEIL,45300,PITHIVIERS LE VIEIL,,48.1732947804,2.20648380316,253,,Pithiviers-le-Vieil,Pithiviers-le-Vieil,45,Loiret,24,Centre-Val de Loire
45253,PITHIVIERS LE VIEIL,45300,PITHIVIERS LE VIEIL,BOUZONVILLE EN BEAUCE,48.1732947804,2.20648380316,253,,Pithiviers-le-Vieil,Pithiviers-le-Vieil,45,Loiret,24,Centre-Val de Loire
45254,POILLY LEZ GIEN,45500,POILLY LEZ GIEN,,47.6604278925,2.59453051579,254,,Poilly-lez-Gien,Poilly-lez-Gien,45,Loiret,24,Centre-Val de Loire
45255,PREFONTAINES,45490,PREFONTAINES,,48.1067340084,2.69095308156,255,,Préfontaines,Préfontaines,45,Loiret,24,Centre-Val de Loire
45256,PRESNOY,45260,PRESNOY,,47.9580605247,2.5589554736,256,,Presnoy,Presnoy,45,Loiret,24,Centre-Val de Loire
45257,PRESSIGNY LES PINS,45290,PRESSIGNY LES PINS,,47.8777160651,2.76127674486,257,,Pressigny-les-Pins,Pressigny-les-Pins,45,Loiret,24,Centre-Val de Loire
45258,PUISEAUX,45390,PUISEAUX,,48.2093647379,2.4665704993,258,,Puiseaux,Puiseaux,45,Loiret,24,Centre-Val de Loire
45259,QUIERS SUR BEZONDE,45270,QUIERS SUR BEZONDE,,47.9880213405,2.42617062061,259,,Quiers-sur-Bézonde,Quiers-sur-Bézonde,45,Loiret,24,Centre-Val de Loire
45260,RAMOULU,45300,RAMOULU,,48.2288396246,2.305844038,260,,Ramoulu,Ramoulu,45,Loiret,24,Centre-Val de Loire
45261,REBRECHIEN,45470,REBRECHIEN,,47.9935958151,2.02397089801,261,,Rebréchien,Rebréchien,45,Loiret,24,Centre-Val de Loire
45262,ROUVRAY STE CROIX,45310,ROUVRAY STE CROIX,,48.0566296755,1.73556462334,262,,Rouvray-Sainte-Croix,Rouvray-Sainte-Croix,45,Loiret,24,Centre-Val de Loire
45263,ROUVRES ST JEAN,45300,ROUVRES ST JEAN,,48.3243507576,2.21045535164,263,,Rouvres-Saint-Jean,Rouvres-Saint-Jean,45,Loiret,24,Centre-Val de Loire
45264,ROZIERES EN BEAUCE,45130,ROZIERES EN BEAUCE,,47.9392344638,1.70879021438,264,,Rozières-en-Beauce,Rozières-en-Beauce,45,Loiret,24,Centre-Val de Loire
45265,ROZOY LE VIEIL,45210,ROZOY LE VIEIL,,48.1183432161,2.95141929917,265,,Rozoy-le-Vieil,Rozoy-le-Vieil,45,Loiret,24,Centre-Val de Loire
45266,RUAN,45410,RUAN,,48.1088949762,1.92407655033,266,,Ruan,Ruan,45,Loiret,24,Centre-Val de Loire
45267,ST AIGNAN DES GUES,45460,ST AIGNAN DES GUES,,47.8384060176,2.33354867238,267,,Saint-Aignan-des-Gués,Saint-Aignan-des-Gués,45,Loiret,24,Centre-Val de Loire
45268,ST AIGNAN LE JAILLARD,45600,ST AIGNAN LE JAILLARD,,47.7328416705,2.4226468337,268,,Saint-Aignan-le-Jaillard,Saint-Aignan-le-Jaillard,45,Loiret,24,Centre-Val de Loire
45269,ST AY,45130,ST AY,,47.8651975362,1.74675988816,269,,Saint-Ay,Saint-Ay,45,Loiret,24,Centre-Val de Loire
45270,ST BENOIT SUR LOIRE,45730,ST BENOIT SUR LOIRE,,47.8087057942,2.31731895237,270,,Saint-Benoît-sur-Loire,Saint-Benoît-sur-Loire,45,Loiret,24,Centre-Val de Loire
45271,ST BRISSON SUR LOIRE,45500,ST BRISSON SUR LOIRE,,47.6322639103,2.67602869119,271,,Saint-Brisson-sur-Loire,Saint-Brisson-sur-Loire,45,Loiret,24,Centre-Val de Loire
45272,ST CYR EN VAL,45590,ST CYR EN VAL,,47.8140953159,1.95982379984,272,,Saint-Cyr-en-Val,Saint-Cyr-en-Val,45,Loiret,24,Centre-Val de Loire
45273,ST DENIS DE L HOTEL,45550,ST DENIS DE L HOTEL,,47.8835425588,2.15388829188,273,,Saint-Denis-de-l'Hôtel,Saint-Denis-de-l'Hôtel,45,Loiret,24,Centre-Val de Loire
45274,ST DENIS EN VAL,45560,ST DENIS EN VAL,,47.8811057198,1.97259377851,274,,Saint-Denis-en-Val,Saint-Denis-en-Val,45,Loiret,24,Centre-Val de Loire
45275,ST FIRMIN DES BOIS,45220,ST FIRMIN DES BOIS,,47.9697416021,2.91006248346,275,,Saint-Firmin-des-Bois,Saint-Firmin-des-Bois,45,Loiret,24,Centre-Val de Loire
45276,ST FIRMIN SUR LOIRE,45360,ST FIRMIN SUR LOIRE,,47.6056395298,2.69936576002,276,,Saint-Firmin-sur-Loire,Saint-Firmin-sur-Loire,45,Loiret,24,Centre-Val de Loire
45277,ST FLORENT,45600,ST FLORENT,,47.6846303969,2.45341866592,277,,Saint-Florent,Saint-Florent,45,Loiret,24,Centre-Val de Loire
45278,STE GENEVIEVE DES BOIS,45230,STE GENEVIEVE DES BOIS,,47.8115452338,2.79379001836,278,,Sainte-Geneviève-des-Bois,Sainte-Geneviève-des-Bois,45,Loiret,24,Centre-Val de Loire
45279,ST GERMAIN DES PRES,45220,ST GERMAIN DES PRES,,47.9656628356,2.84890321782,279,,Saint-Germain-des-Prés,Saint-Germain-des-Prés,45,Loiret,24,Centre-Val de Loire
45280,ST GONDON,45500,ST GONDON,,47.6883834117,2.53960539003,280,,Saint-Gondon,Saint-Gondon,45,Loiret,24,Centre-Val de Loire
45281,ST HILAIRE LES ANDRESIS,45320,ST HILAIRE LES ANDRESIS,,48.0545962538,3.01538737178,281,,Saint-Hilaire-les-Andrésis,Saint-Hilaire-les-Andrésis,45,Loiret,24,Centre-Val de Loire
45282,ST HILAIRE ST MESMIN,45160,ST HILAIRE ST MESMIN,,47.8500863067,1.83403154937,282,,Saint-Hilaire-Saint-Mesmin,Saint-Hilaire-Saint-Mesmin,45,Loiret,24,Centre-Val de Loire
45283,ST HILAIRE SUR PUISEAUX,45700,ST HILAIRE SUR PUISEAUX,,47.9071529346,2.69855393407,283,,Saint-Hilaire-sur-Puiseaux,Saint-Hilaire-sur-Puiseaux,45,Loiret,24,Centre-Val de Loire
45284,ST JEAN DE BRAYE,45800,ST JEAN DE BRAYE,,47.9178497622,1.97241570235,284,,Saint-Jean-de-Braye,Saint-Jean-de-Braye,45,Loiret,24,Centre-Val de Loire
45285,ST JEAN DE LA RUELLE,45140,ST JEAN DE LA RUELLE,,47.9116141172,1.87108144709,285,,Saint-Jean-de-la-Ruelle,Saint-Jean-de-la-Ruelle,45,Loiret,24,Centre-Val de Loire
45286,ST JEAN LE BLANC,45650,ST JEAN LE BLANC,,47.8828915837,1.93333711886,286,,Saint-Jean-le-Blanc,Saint-Jean-le-Blanc,45,Loiret,24,Centre-Val de Loire
45287,ST LOUP DE GONOIS,45210,ST LOUP DE GONOIS,,48.0636348255,2.92514568518,287,,Saint-Loup-de-Gonois,Saint-Loup-de-Gonois,45,Loiret,24,Centre-Val de Loire
45288,ST LOUP DES VIGNES,45340,ST LOUP DES VIGNES,,48.0412459451,2.42721936357,288,,Saint-Loup-des-Vignes,Saint-Loup-des-Vignes,45,Loiret,24,Centre-Val de Loire
45289,ST LYE LA FORET,45170,ST LYE LA FORET,,48.0349544365,1.9722303277,289,,Saint-Lyé-la-Forêt,Saint-Lyé-la-Forêt,45,Loiret,24,Centre-Val de Loire
45290,ST MARTIN D ABBAT,45110,ST MARTIN D ABBAT,,47.8813399374,2.30587611938,290,,Saint-Martin-d'Abbat,Saint-Martin-d'Abbat,45,Loiret,24,Centre-Val de Loire
45291,ST MARTIN SUR OCRE,45500,ST MARTIN SUR OCRE,,47.6468281409,2.64446873404,291,,Saint-Martin-sur-Ocre,Saint-Martin-sur-Ocre,45,Loiret,24,Centre-Val de Loire
45292,ST MAURICE SUR AVEYRON,45230,ST MAURICE SUR AVEYRON,,47.8464241054,2.96065663752,292,,Saint-Maurice-sur-Aveyron,Saint-Maurice-sur-Aveyron,45,Loiret,24,Centre-Val de Loire
45293,ST MAURICE SUR FESSARD,45700,ST MAURICE SUR FESSARD,,47.9911796658,2.61527422656,293,,Saint-Maurice-sur-Fessard,Saint-Maurice-sur-Fessard,45,Loiret,24,Centre-Val de Loire
45294,ST MICHEL,45340,ST MICHEL,,48.0638465323,2.37213193925,294,,Saint-Michel,Saint-Michel,45,Loiret,24,Centre-Val de Loire
45296,ST PERAVY LA COLOMBE,45310,ST PERAVY LA COLOMBE,,48.0029552851,1.69290201478,296,,Saint-Péravy-la-Colombe,Saint-Péravy-la-Colombe,45,Loiret,24,Centre-Val de Loire
45297,ST PERE SUR LOIRE,45600,ST PERE SUR LOIRE,,47.7808203416,2.37330699983,297,,Saint-Père-sur-Loire,Saint-Père-sur-Loire,45,Loiret,24,Centre-Val de Loire
45298,ST PRYVE ST MESMIN,45750,ST PRYVE ST MESMIN,,47.8804643643,1.85481238728,298,,Saint-Pryvé-Saint-Mesmin,Saint-Pryvé-Saint-Mesmin,45,Loiret,24,Centre-Val de Loire
45299,ST SIGISMOND,45310,ST SIGISMOND,,47.9825689104,1.66590651011,299,,Saint-Sigismond,Saint-Sigismond,45,Loiret,24,Centre-Val de Loire
45300,SANDILLON,45640,SANDILLON,,47.836250282,2.03008361702,300,,Sandillon,Sandillon,45,Loiret,24,Centre-Val de Loire
45301,SANTEAU,45170,SANTEAU,,48.0939958516,2.15275063221,301,,Santeau,Santeau,45,Loiret,24,Centre-Val de Loire
45302,SARAN,45770,SARAN,,47.9498311047,1.87809693517,302,,Saran,Saran,45,Loiret,24,Centre-Val de Loire
45303,SCEAUX DU GATINAIS,45490,SCEAUX DU GATINAIS,,48.1208120916,2.59052971179,303,,Sceaux-du-Gâtinais,Sceaux-du-Gâtinais,45,Loiret,24,Centre-Val de Loire
45305,SEICHEBRIERES,45530,SEICHEBRIERES,,47.9818237634,2.27403899045,305,,Seichebrières,Seichebrières,45,Loiret,24,Centre-Val de Loire
45306,LA SELLE EN HERMOY,45210,LA SELLE EN HERMOY,,48.0055574782,2.88981309888,306,La,Selle-en-Hermoy,La Selle-en-Hermoy,45,Loiret,24,Centre-Val de Loire
45307,LA SELLE SUR LE BIED,45210,LA SELLE SUR LE BIED,,48.0762829727,2.88839623055,307,La,Selle-sur-le-Bied,La Selle-sur-le-Bied,45,Loiret,24,Centre-Val de Loire
45308,SEMOY,45400,SEMOY,,47.9380661879,1.96315992234,308,,Semoy,Semoy,45,Loiret,24,Centre-Val de Loire
45309,SENNELY,45240,SENNELY,,47.7015601032,2.13332178838,309,,Sennely,Sennely,45,Loiret,24,Centre-Val de Loire
45310,SERMAISES,45300,SERMAISES,,48.2977181828,2.20317765493,310,,Sermaises,Sermaises,45,Loiret,24,Centre-Val de Loire
45311,SIGLOY,45110,SIGLOY,,47.839361227,2.22257822761,311,,Sigloy,Sigloy,45,Loiret,24,Centre-Val de Loire
45312,SOLTERRE,45700,SOLTERRE,,47.912040887,2.73568856223,312,,Solterre,Solterre,45,Loiret,24,Centre-Val de Loire
45313,SOUGY,45410,SOUGY,,48.0581126003,1.80118276124,313,,Sougy,Sougy,45,Loiret,24,Centre-Val de Loire
45314,SULLY LA CHAPELLE,45450,SULLY LA CHAPELLE,,47.9841057198,2.16895228227,314,,Sully-la-Chapelle,Sully-la-Chapelle,45,Loiret,24,Centre-Val de Loire
45315,SULLY SUR LOIRE,45600,SULLY SUR LOIRE,,47.7526229305,2.35647713689,315,,Sully-sur-Loire,Sully-sur-Loire,45,Loiret,24,Centre-Val de Loire
45316,SURY AUX BOIS,45530,SURY AUX BOIS,,47.9537349646,2.35973695052,316,,Sury-aux-Bois,Sury-aux-Bois,45,Loiret,24,Centre-Val de Loire
45317,TAVERS,45190,TAVERS,,47.7633212034,1.59342598459,317,,Tavers,Tavers,45,Loiret,24,Centre-Val de Loire
45320,THIGNONVILLE,45300,THIGNONVILLE,,48.2716724773,2.17538321237,320,,Thignonville,Thignonville,45,Loiret,24,Centre-Val de Loire
45321,THIMORY,45260,THIMORY,,47.9255273898,2.59448373377,321,,Thimory,Thimory,45,Loiret,24,Centre-Val de Loire
45322,THORAILLES,45210,THORAILLES,,48.0258698005,2.89559068746,322,,Thorailles,Thorailles,45,Loiret,24,Centre-Val de Loire
45323,THOU,45420,THOU,,47.580299862,2.89635000419,323,,Thou,Thou,45,Loiret,24,Centre-Val de Loire
45324,TIGY,45510,TIGY,,47.7792107424,2.18510724975,324,,Tigy,Tigy,45,Loiret,24,Centre-Val de Loire
45325,TIVERNON,45170,TIVERNON,,48.1581839443,1.93815249902,325,,Tivernon,Tivernon,45,Loiret,24,Centre-Val de Loire
45326,TOURNOISIS,45310,TOURNOISIS,,48.0048588695,1.6244204938,326,,Tournoisis,Tournoisis,45,Loiret,24,Centre-Val de Loire
45327,TRAINOU,45470,TRAINOU,,47.9652949917,2.10398859914,327,,Traînou,Traînou,45,Loiret,24,Centre-Val de Loire
45328,TREILLES EN GATINAIS,45490,TREILLES EN GATINAIS,,48.0787762501,2.66540887052,328,,Treilles-en-Gâtinais,Treilles-en-Gâtinais,45,Loiret,24,Centre-Val de Loire
45329,TRIGUERES,45220,TRIGUERES,,47.9392051381,2.99479900894,329,,Triguères,Triguères,45,Loiret,24,Centre-Val de Loire
45330,TRINAY,45410,TRINAY,,48.0843446656,1.95253961783,330,,Trinay,Trinay,45,Loiret,24,Centre-Val de Loire
45331,VANNES SUR COSSON,45510,VANNES SUR COSSON,,47.7179640819,2.20904913519,331,,Vannes-sur-Cosson,Vannes-sur-Cosson,45,Loiret,24,Centre-Val de Loire
45332,VARENNES CHANGY,45290,VARENNES CHANGY,,47.8623950177,2.6635119696,332,,Varennes-Changy,Varennes-Changy,45,Loiret,24,Centre-Val de Loire
45333,VENNECY,45760,VENNECY,,47.9551852043,2.05249130958,333,,Vennecy,Vennecy,45,Loiret,24,Centre-Val de Loire
45334,VIEILLES MAISONS SUR JOUDRY,45260,VIEILLES MAISONS SUR JOUDRY,,47.8838478178,2.43774998138,334,,Vieilles-Maisons-sur-Joudry,Vieilles-Maisons-sur-Joudry,45,Loiret,24,Centre-Val de Loire
45335,VIENNE EN VAL,45510,VIENNE EN VAL,,47.7825716269,2.12412852097,335,,Vienne-en-Val,Vienne-en-Val,45,Loiret,24,Centre-Val de Loire
45336,VIGLAIN,45600,VIGLAIN,,47.7320234498,2.29028303384,336,,Viglain,Viglain,45,Loiret,24,Centre-Val de Loire
45337,VILLAMBLAIN,45310,VILLAMBLAIN,,48.0129025653,1.55246267039,337,,Villamblain,Villamblain,45,Loiret,24,Centre-Val de Loire
45338,VILLEMANDEUR,45700,VILLEMANDEUR,,47.9830187489,2.70512428321,338,,Villemandeur,Villemandeur,45,Loiret,24,Centre-Val de Loire
45339,VILLEMOUTIERS,45270,VILLEMOUTIERS,,47.9898633359,2.55885928957,339,,Villemoutiers,Villemoutiers,45,Loiret,24,Centre-Val de Loire
45340,VILLEMURLIN,45600,VILLEMURLIN,,47.6816641134,2.34263674784,340,,Villemurlin,Villemurlin,45,Loiret,24,Centre-Val de Loire
45341,VILLENEUVE SUR CONIE,45310,VILLENEUVE SUR CONIE,,48.0476576442,1.64874485341,341,,Villeneuve-sur-Conie,Villeneuve-sur-Conie,45,Loiret,24,Centre-Val de Loire
45342,VILLEREAU,45170,VILLEREAU,,48.0736372897,1.99220026778,342,,Villereau,Villereau,45,Loiret,24,Centre-Val de Loire
45343,VILLEVOQUES,45700,VILLEVOQUES,,48.0294754819,2.62616957754,343,,Villevoques,Villevoques,45,Loiret,24,Centre-Val de Loire
45344,VILLORCEAU,45190,VILLORCEAU,,47.8000109233,1.59585305756,344,,Villorceau,Villorceau,45,Loiret,24,Centre-Val de Loire
45345,VIMORY,45700,VIMORY,,47.9486850817,2.67841520293,345,,Vimory,Vimory,45,Loiret,24,Centre-Val de Loire
45346,VITRY AUX LOGES,45530,VITRY AUX LOGES,,47.931092233,2.25115568567,346,,Vitry-aux-Loges,Vitry-aux-Loges,45,Loiret,24,Centre-Val de Loire
45347,VRIGNY,45300,VRIGNY,,48.0740801124,2.24775186267,347,,Vrigny,Vrigny,45,Loiret,24,Centre-Val de Loire
45348,YEVRE LA VILLE,45300,YEVRE LA VILLE,,48.1427599293,2.32302626395,348,,Yèvre-la-Ville,Yèvre-la-Ville,45,Loiret,24,Centre-Val de Loire
45348,YEVRE LA VILLE,45300,YEVRE LA VILLE,YEVRE LE CHATEL,48.1427599293,2.32302626395,348,,Yèvre-la-Ville,Yèvre-la-Ville,45,Loiret,24,Centre-Val de Loire
46001,ALBAS,46140,ALBAS,,44.461394449,1.24016214719,1,,Albas,Albas,46,Lot,76,Occitanie
46002,ALBIAC,46500,ALBIAC,,44.7677846601,1.81316980755,2,,Albiac,Albiac,46,Lot,76,Occitanie
46003,ALVIGNAC,46500,ALVIGNAC,,44.8338975947,1.68546472783,3,,Alvignac,Alvignac,46,Lot,76,Occitanie
46004,ANGLARS,46120,ANGLARS,,44.7474871579,1.89850060381,4,,Anglars,Anglars,46,Lot,76,Occitanie
46005,ANGLARS JUILLAC,46140,ANGLARS JUILLAC,,44.4865010847,1.20855960816,5,,Anglars-Juillac,Anglars-Juillac,46,Lot,76,Occitanie
46006,ANGLARS NOZAC,46300,ANGLARS NOZAC,,44.7827204407,1.40862887217,6,,Anglars-Nozac,Anglars-Nozac,46,Lot,76,Occitanie
46007,ARCAMBAL,46090,ARCAMBAL,,44.4543698318,1.54748898105,7,,Arcambal,Arcambal,46,Lot,76,Occitanie
46008,LES ARQUES,46250,LES ARQUES,,44.5993732049,1.24553751761,8,Les,Arques,Les Arques,46,Lot,76,Occitanie
46009,ASSIER,46320,ASSIER,,44.6722095867,1.87432181008,9,,Assier,Assier,46,Lot,76,Occitanie
46010,AUJOLS,46090,AUJOLS,,44.4243818386,1.54157592497,10,,Aujols,Aujols,46,Lot,76,Occitanie
46011,AUTOIRE,46400,AUTOIRE,,44.8583081491,1.82558835092,11,,Autoire,Autoire,46,Lot,76,Occitanie
46012,AYNAC,46120,AYNAC,,44.7879676429,1.85677368836,12,,Aynac,Aynac,46,Lot,76,Occitanie
46013,BACH,46230,BACH,,44.3460184133,1.68033699425,13,,Bach,Bach,46,Lot,76,Occitanie
46014,BAGAT EN QUERCY,46800,BAGAT EN QUERCY,,44.3844144129,1.22801974152,14,,Bagat-en-Quercy,Bagat-en-Quercy,46,Lot,76,Occitanie
46015,BAGNAC SUR CELE,46270,BAGNAC SUR CELE,,44.6622315963,2.14887166878,15,,Bagnac-sur-Célé,Bagnac-sur-Célé,46,Lot,76,Occitanie
46016,BALADOU,46600,BALADOU,,44.9240844611,1.55512235753,16,,Baladou,Baladou,46,Lot,76,Occitanie
46017,BANNES,46400,BANNES,,44.8140344075,1.91798481725,17,,Bannes,Bannes,46,Lot,76,Occitanie
46018,LE BASTIT,46500,LE BASTIT,,44.7155811431,1.67355931002,18,Le,Bastit,Le Bastit,46,Lot,76,Occitanie
46020,BEAUREGARD,46260,BEAUREGARD,,44.3524666438,1.77958568726,20,,Beauregard,Beauregard,46,Lot,76,Occitanie
46021,BEDUER,46100,BEDUER,,44.5711043331,1.92666440325,21,,Béduer,Béduer,46,Lot,76,Occitanie
46022,BELAYE,46140,BELAYE,,44.4513136237,1.18975972414,22,,Bélaye,Bélaye,46,Lot,76,Occitanie
46023,BELFORT DU QUERCY,46230,BELFORT DU QUERCY,,44.2691232817,1.54997739016,23,,Belfort-du-Quercy,Belfort-du-Quercy,46,Lot,76,Occitanie
46024,BELMONT BRETENOUX,46130,BELMONT BRETENOUX,,44.8887906556,1.88174957113,24,,Belmont-Bretenoux,Belmont-Bretenoux,46,Lot,76,Occitanie
46026,BELMONT STE FOI,46230,BELMONT STE FOI,,44.2897689625,1.63023470112,26,,Belmont-Sainte-Foi,Belmont-Sainte-Foi,46,Lot,76,Occitanie
46027,BERGANTY,46090,BERGANTY,,44.443494853,1.62900308265,27,,Berganty,Berganty,46,Lot,76,Occitanie
46028,BETAILLE,46110,BETAILLE,,44.9424463587,1.73672663964,28,,Bétaille,Bétaille,46,Lot,76,Occitanie
46029,BIARS SUR CERE,46130,BIARS SUR CERE,,44.9281999917,1.85705877079,29,,Biars-sur-Cère,Biars-sur-Cère,46,Lot,76,Occitanie
46030,BIO,46500,BIO,,44.777991663,1.78785016782,30,,Bio,Bio,46,Lot,76,Occitanie
46031,BLARS,46330,BLARS,,44.5750441827,1.72683431794,31,,Blars,Blars,46,Lot,76,Occitanie
46032,BOISSIERES,46150,BOISSIERES,,44.5478641839,1.40820020858,32,,Boissières,Boissières,46,Lot,76,Occitanie
46033,LE BOULVE,46800,LE BOULVE,,44.4210869463,1.1472613313,33,Le,Boulvé,Le Boulvé,46,Lot,76,Occitanie
46034,LE BOURG,46120,LE BOURG,,44.7061859872,1.90968231759,34,Le,Bourg,Le Bourg,46,Lot,76,Occitanie
46035,BOUSSAC,46100,BOUSSAC,,44.5955239384,1.91907377788,35,,Boussac,Boussac,46,Lot,76,Occitanie
46036,LE BOUYSSOU,46120,LE BOUYSSOU,,44.6937761808,1.94655674369,36,Le,Bouyssou,Le Bouyssou,46,Lot,76,Occitanie
46037,BOUZIES,46330,BOUZIES,,44.4798801695,1.63591249054,37,,Bouziès,Bouziès,46,Lot,76,Occitanie
46038,BRETENOUX,46130,BRETENOUX,,44.9142894383,1.84254876727,38,,Bretenoux,Bretenoux,46,Lot,76,Occitanie
46039,BRENGUES,46320,BRENGUES,,44.5785357536,1.83285932775,39,,Brengues,Brengues,46,Lot,76,Occitanie
46040,CABRERETS,46330,CABRERETS,,44.5167459006,1.6198024468,40,,Cabrerets,Cabrerets,46,Lot,76,Occitanie
46041,CADRIEU,46160,CADRIEU,,44.4996325916,1.87599580969,41,,Cadrieu,Cadrieu,46,Lot,76,Occitanie
46042,CAHORS,46000,CAHORS,,44.4507370916,1.44075837848,42,,Cahors,Cahors,46,Lot,76,Occitanie
46043,CAHUS,46130,CAHUS,,44.9631975501,1.91432329808,43,,Cahus,Cahus,46,Lot,76,Occitanie
46044,CAILLAC,46140,CAILLAC,,44.4923815798,1.34696885739,44,,Caillac,Caillac,46,Lot,76,Occitanie
46045,CAJARC,46160,CAJARC,,44.494117576,1.83859373388,45,,Cajarc,Cajarc,46,Lot,76,Occitanie
46046,CALAMANE,46150,CALAMANE,,44.5198122343,1.41032272742,46,,Calamane,Calamane,46,Lot,76,Occitanie
46047,CALES,46350,CALES,,44.7953417042,1.54576222775,47,,Calès,Calès,46,Lot,76,Occitanie
46049,CALVIGNAC,46160,CALVIGNAC,,44.4491098487,1.78767969019,49,,Calvignac,Calvignac,46,Lot,76,Occitanie
46050,CAMBAYRAC,46140,CAMBAYRAC,,44.4298765431,1.28736987423,50,,Cambayrac,Cambayrac,46,Lot,76,Occitanie
46051,CAMBES,46100,CAMBES,,44.621451936,1.92659175923,51,,Cambes,Cambes,46,Lot,76,Occitanie
46052,CAMBOULIT,46100,CAMBOULIT,,44.5998481428,1.95270053805,52,,Camboulit,Camboulit,46,Lot,76,Occitanie
46053,CAMBURAT,46100,CAMBURAT,,44.6443373032,1.99856962596,53,,Camburat,Camburat,46,Lot,76,Occitanie
46054,CANIAC DU CAUSSE,46240,CANIAC DU CAUSSE,,44.6300356626,1.65611420144,54,,Caniac-du-Causse,Caniac-du-Causse,46,Lot,76,Occitanie
46055,CAPDENAC,46100,CAPDENAC,,44.5849536866,2.06705309524,55,,Capdenac,Capdenac,46,Lot,76,Occitanie
46056,CARAYAC,46160,CARAYAC,,44.5413491991,1.92274875489,56,,Carayac,Carayac,46,Lot,76,Occitanie
46057,CARDAILLAC,46100,CARDAILLAC,,44.6824882751,2.0138257443,57,,Cardaillac,Cardaillac,46,Lot,76,Occitanie
46058,CARENNAC,46110,CARENNAC,,44.9054973553,1.72378675129,58,,Carennac,Carennac,46,Lot,76,Occitanie
46059,CARLUCET,46500,CARLUCET,,44.731542751,1.61018363639,59,,Carlucet,Carlucet,46,Lot,76,Occitanie
46060,CARNAC ROUFFIAC,46140,CARNAC ROUFFIAC,,44.4171586039,1.22177505149,60,,Carnac-Rouffiac,Carnac-Rouffiac,46,Lot,76,Occitanie
46061,CASSAGNES,46700,CASSAGNES,,44.5630523777,1.1285177981,61,,Cassagnes,Cassagnes,46,Lot,76,Occitanie
46062,CASTELFRANC,46140,CASTELFRANC,,44.5046854945,1.22357367692,62,,Castelfranc,Castelfranc,46,Lot,76,Occitanie
46063,CASTELNAU MONTRATIER,46170,CASTELNAU MONTRATIER,,44.2618964554,1.34787377419,63,,Castelnau Montratier-Sainte Alauzie,Castelnau Montratier-Sainte Alauzie,46,Lot,76,Occitanie
46064,CATUS,46150,CATUS,,44.5584146549,1.34151024683,64,,Catus,Catus,46,Lot,76,Occitanie
46065,CAVAGNAC,46110,CAVAGNAC,,45.0155853706,1.63895264848,65,,Cavagnac,Cavagnac,46,Lot,76,Occitanie
46066,CAZALS,46250,CAZALS,,44.6457631404,1.22061089675,66,,Cazals,Cazals,46,Lot,76,Occitanie
46067,CAZILLAC,46600,CAZILLAC,,44.9883601521,1.59742105562,67,,Cazillac,Cazillac,46,Lot,76,Occitanie
46068,CENEVIERES,46330,CENEVIERES,,44.4455008428,1.73871325371,68,,Cénevières,Cénevières,46,Lot,76,Occitanie
46069,CEZAC,46170,CEZAC,,44.3482882762,1.34038736361,69,,Cézac,Cézac,46,Lot,76,Occitanie
46070,CIEURAC,46230,CIEURAC,,44.3708750908,1.49838889295,70,,Cieurac,Cieurac,46,Lot,76,Occitanie
46072,CONCORES,46310,CONCORES,,44.6672951054,1.39090015895,72,,Concorès,Concorès,46,Lot,76,Occitanie
46073,CONCOTS,46260,CONCOTS,,44.3966512584,1.6428976522,73,,Concots,Concots,46,Lot,76,Occitanie
46074,CONDAT,46110,CONDAT,,44.9916728807,1.67036030848,74,,Condat,Condat,46,Lot,76,Occitanie
46075,CORN,46100,CORN,,44.6061471898,1.88882538803,75,,Corn,Corn,46,Lot,76,Occitanie
46076,CORNAC,46130,CORNAC,,44.9018775155,1.9180519973,76,,Cornac,Cornac,46,Lot,76,Occitanie
46077,COURS,46090,COURS,,44.5237046868,1.53038009913,77,,Cours,Cours,46,Lot,76,Occitanie
46078,COUZOU,46500,COUZOU,,44.7694704061,1.63206532223,78,,Couzou,Couzou,46,Lot,76,Occitanie
46079,CRAS,46360,CRAS,,44.5567067306,1.54091897332,79,,Cras,Cras,46,Lot,76,Occitanie
46080,CRAYSSAC,46150,CRAYSSAC,,44.5167654701,1.32994493249,80,,Crayssac,Crayssac,46,Lot,76,Occitanie
46081,CREGOLS,46330,CREGOLS,,44.429580085,1.6857006854,81,,Crégols,Crégols,46,Lot,76,Occitanie
46082,CREMPS,46230,CREMPS,,44.3877412529,1.58297001651,82,,Cremps,Cremps,46,Lot,76,Occitanie
46083,CRESSENSAC,46600,CRESSENSAC,,45.0196029129,1.52229424789,83,,Cressensac,Cressensac,46,Lot,76,Occitanie
46084,CREYSSE,46600,CREYSSE,,44.8990704572,1.59459247498,84,,Creysse,Creysse,46,Lot,76,Occitanie
46085,CUZAC,46270,CUZAC,,44.5805664104,2.14397659301,85,,Cuzac,Cuzac,46,Lot,76,Occitanie
46086,CUZANCE,46600,CUZANCE,,44.9618841392,1.52986914567,86,,Cuzance,Cuzance,46,Lot,76,Occitanie
46087,DEGAGNAC,46340,DEGAGNAC,,44.6693459592,1.32745596587,87,,Dégagnac,Dégagnac,46,Lot,76,Occitanie
46088,DOUELLE,46140,DOUELLE,,44.4711707333,1.35541899313,88,,Douelle,Douelle,46,Lot,76,Occitanie
46089,DURAVEL,46700,DURAVEL,,44.5165803218,1.08355706377,89,,Duravel,Duravel,46,Lot,76,Occitanie
46090,DURBANS,46320,DURBANS,,44.6792000888,1.76742895294,90,,Durbans,Durbans,46,Lot,76,Occitanie
46091,ESCAMPS,46230,ESCAMPS,,44.3645701478,1.61954720229,91,,Escamps,Escamps,46,Lot,76,Occitanie
46092,ESCLAUZELS,46090,ESCLAUZELS,,44.4322856045,1.59801365045,92,,Esclauzels,Esclauzels,46,Lot,76,Occitanie
46093,ESPAGNAC STE EULALIE,46320,ESPAGNAC STE EULALIE,,44.5924781077,1.86121136331,93,,Espagnac-Sainte-Eulalie,Espagnac-Sainte-Eulalie,46,Lot,76,Occitanie
46094,ESPEDAILLAC,46320,ESPEDAILLAC,,44.6313262107,1.77517916048,94,,Espédaillac,Espédaillac,46,Lot,76,Occitanie
46095,ESPERE,46090,ESPERE,,44.5141184572,1.37059320975,95,,Espère,Espère,46,Lot,76,Occitanie
46096,ESPEYROUX,46120,ESPEYROUX,,44.7651724416,1.9255839312,96,,Espeyroux,Espeyroux,46,Lot,76,Occitanie
46097,ESTAL,46130,ESTAL,,44.9193285418,1.91880253206,97,,Estal,Estal,46,Lot,76,Occitanie
46098,FAJOLES,46300,FAJOLES,,44.8070487445,1.38268887877,98,,Fajoles,Fajoles,46,Lot,76,Occitanie
46099,FARGUES,46800,FARGUES,,44.4024667075,1.1836288875,99,,Fargues,Fargues,46,Lot,76,Occitanie
46100,FAYCELLES,46100,FAYCELLES,,44.5656631085,1.99228446364,100,,Faycelles,Faycelles,46,Lot,76,Occitanie
46101,FELZINS,46270,FELZINS,,44.6095612656,2.15053532047,101,,Felzins,Felzins,46,Lot,76,Occitanie
46102,FIGEAC,46100,FIGEAC,,44.6055314625,2.02540303775,102,,Figeac,Figeac,46,Lot,76,Occitanie
46103,ST PAUL FLAUGNAC,46170,ST PAUL FLAUGNAC,,44.2910612603,1.40914730428,103,,Saint-Paul-Flaugnac,Saint-Paul-Flaugnac,46,Lot,76,Occitanie
46103,ST PAUL FLAUGNAC,46170,ST PAUL FLAUGNAC,ST PAUL DE LOUBRESSAC,44.2910612603,1.40914730428,103,,Saint-Paul-Flaugnac,Saint-Paul-Flaugnac,46,Lot,76,Occitanie
46104,FLAUJAC GARE,46320,FLAUJAC GARE,,44.707043004,1.77185249529,104,,Flaujac-Gare,Flaujac-Gare,46,Lot,76,Occitanie
46105,FLAUJAC POUJOLS,46090,FLAUJAC POUJOLS,,44.4105069107,1.49806736819,105,,Flaujac-Poujols,Flaujac-Poujols,46,Lot,76,Occitanie
46106,FLOIRAC,46600,FLOIRAC,,44.9059208514,1.67151833797,106,,Floirac,Floirac,46,Lot,76,Occitanie
46107,FLORESSAS,46700,FLORESSAS,,44.4579068501,1.1145883767,107,,Floressas,Floressas,46,Lot,76,Occitanie
46108,FONS,46100,FONS,,44.6597558601,1.9530741182,108,,Fons,Fons,46,Lot,76,Occitanie
46109,FONTANES,46230,FONTANES,,44.3250209825,1.49032915385,109,,Fontanes,Fontanes,46,Lot,76,Occitanie
46111,FOURMAGNAC,46100,FOURMAGNAC,,44.6657638987,1.98087268416,111,,Fourmagnac,Fourmagnac,46,Lot,76,Occitanie
46112,FRANCOULES,46090,FRANCOULES,,44.5564275436,1.4876971855,112,,Francoulès,Francoulès,46,Lot,76,Occitanie
46113,FRAYSSINET,46310,FRAYSSINET,,44.6491132224,1.48577844538,113,,Frayssinet,Frayssinet,46,Lot,76,Occitanie
46114,FRAYSSINET LE GELAT,46250,FRAYSSINET LE GELAT,,44.5902258251,1.14402120648,114,,Frayssinet-le-Gélat,Frayssinet-le-Gélat,46,Lot,76,Occitanie
46115,FRAYSSINHES,46400,FRAYSSINHES,,44.8726104417,1.94132532683,115,,Frayssinhes,Frayssinhes,46,Lot,76,Occitanie
46116,FRONTENAC,46160,FRONTENAC,,44.5445619042,1.97060806346,116,,Frontenac,Frontenac,46,Lot,76,Occitanie
46117,GAGNAC SUR CERE,46130,GAGNAC SUR CERE,,44.9387966672,1.88455364485,117,,Gagnac-sur-Cère,Gagnac-sur-Cère,46,Lot,76,Occitanie
46118,GIGNAC,46600,GIGNAC,,44.9899523314,1.46272754104,118,,Gignac,Gignac,46,Lot,76,Occitanie
46119,GIGOUZAC,46150,GIGOUZAC,,44.5837680481,1.43277988907,119,,Gigouzac,Gigouzac,46,Lot,76,Occitanie
46120,GINDOU,46250,GINDOU,,44.6266225054,1.26418092568,120,,Gindou,Gindou,46,Lot,76,Occitanie
46121,GINOUILLAC,46300,GINOUILLAC,,44.726806862,1.53672996864,121,,Ginouillac,Ginouillac,46,Lot,76,Occitanie
46122,GINTRAC,46130,GINTRAC,,44.8834895326,1.75273757543,122,,Gintrac,Gintrac,46,Lot,76,Occitanie
46123,GIRAC,46130,GIRAC,,44.9270878845,1.82484872808,123,,Girac,Girac,46,Lot,76,Occitanie
46124,GLANES,46130,GLANES,,44.9190594045,1.87327454002,124,,Glanes,Glanes,46,Lot,76,Occitanie
46125,GORSES,46210,GORSES,,44.7935683292,2.0315083819,125,,Gorses,Gorses,46,Lot,76,Occitanie
46126,GOUJOUNAC,46250,GOUJOUNAC,,44.5750476412,1.20089199371,126,,Goujounac,Goujounac,46,Lot,76,Occitanie
46127,GOURDON,46300,GOURDON,,44.734795608,1.37712768596,127,,Gourdon,Gourdon,46,Lot,76,Occitanie
46128,GRAMAT,46500,GRAMAT,,44.7635603644,1.71620459363,128,,Gramat,Gramat,46,Lot,76,Occitanie
46129,GREALOU,46160,GREALOU,,44.5419774396,1.8758160179,129,,Gréalou,Gréalou,46,Lot,76,Occitanie
46130,GREZELS,46700,GREZELS,,44.4680281643,1.14965078788,130,,Grézels,Grézels,46,Lot,76,Occitanie
46131,GREZES,46320,GREZES,,44.6207172976,1.82420709505,131,,Grèzes,Grèzes,46,Lot,76,Occitanie
46132,ISSENDOLUS,46500,ISSENDOLUS,,44.7387423552,1.77955688281,132,,Issendolus,Issendolus,46,Lot,76,Occitanie
46133,ISSEPTS,46320,ISSEPTS,,44.6769137032,1.91696387098,133,,Issepts,Issepts,46,Lot,76,Occitanie
46134,LES JUNIES,46150,LES JUNIES,,44.5406763067,1.22362619969,134,Les,Junies,Les Junies,46,Lot,76,Occitanie
46135,LABASTIDE DU HAUT MONT,46210,LABASTIDE DU HAUT MONT,,44.828705363,2.11362965204,135,,Labastide-du-Haut-Mont,Labastide-du-Haut-Mont,46,Lot,76,Occitanie
46136,LABASTIDE DU VERT,46150,LABASTIDE DU VERT,,44.5151357381,1.26565315705,136,,Labastide-du-Vert,Labastide-du-Vert,46,Lot,76,Occitanie
46137,LABASTIDE MARNHAC,46090,LABASTIDE MARNHAC,,44.386742628,1.38959761841,137,,Labastide-Marnhac,Labastide-Marnhac,46,Lot,76,Occitanie
46138,COEUR DE CAUSSE,46240,COEUR DE CAUSSE,BEAUMAT,44.6484325779,1.58028882268,138,,Cœur de Causse,Cœur de Causse,46,Lot,76,Occitanie
46138,COEUR DE CAUSSE,46240,COEUR DE CAUSSE,FONTANES DU CAUSSE,44.6484325779,1.58028882268,138,,Cœur de Causse,Cœur de Causse,46,Lot,76,Occitanie
46138,COEUR DE CAUSSE,46240,COEUR DE CAUSSE,LABASTIDE MURAT,44.6484325779,1.58028882268,138,,Cœur de Causse,Cœur de Causse,46,Lot,76,Occitanie
46138,COEUR DE CAUSSE,46240,COEUR DE CAUSSE,ST SAUVEUR LA VALLEE,44.6484325779,1.58028882268,138,,Cœur de Causse,Cœur de Causse,46,Lot,76,Occitanie
46138,COEUR DE CAUSSE,46240,COEUR DE CAUSSE,VAILLAC,44.6484325779,1.58028882268,138,,Cœur de Causse,Cœur de Causse,46,Lot,76,Occitanie
46139,LABATHUDE,46120,LABATHUDE,,44.7362766747,1.98534615992,139,,Labathude,Labathude,46,Lot,76,Occitanie
46140,LABURGADE,46230,LABURGADE,,44.3889348605,1.54038779175,140,,Laburgade,Laburgade,46,Lot,76,Occitanie
46142,LACAPELLE CABANAC,46700,LACAPELLE CABANAC,,44.4729332661,1.07281848621,142,,Lacapelle-Cabanac,Lacapelle-Cabanac,46,Lot,76,Occitanie
46143,LACAPELLE MARIVAL,46120,LACAPELLE MARIVAL,,44.7253588918,1.93631584115,143,,Lacapelle-Marival,Lacapelle-Marival,46,Lot,76,Occitanie
46144,LACAVE,46200,LACAVE,,44.8404498298,1.55849192483,144,,Lacave,Lacave,46,Lot,76,Occitanie
46145,LACHAPELLE AUZAC,46200,LACHAPELLE AUZAC,,44.9380023584,1.48031197912,145,,Lachapelle-Auzac,Lachapelle-Auzac,46,Lot,76,Occitanie
46146,LADIRAT,46400,LADIRAT,,44.8212245702,1.96821823356,146,,Ladirat,Ladirat,46,Lot,76,Occitanie
46147,LAGARDELLE,46220,LAGARDELLE,,44.4870907058,1.17069333497,147,,Lagardelle,Lagardelle,46,Lot,76,Occitanie
46148,LALBENQUE,46230,LALBENQUE,,44.3330164253,1.56498727825,148,,Lalbenque,Lalbenque,46,Lot,76,Occitanie
46149,LAMAGDELAINE,46090,LAMAGDELAINE,,44.4740446239,1.50163136259,149,,Lamagdelaine,Lamagdelaine,46,Lot,76,Occitanie
46151,LAMOTHE CASSEL,46240,LAMOTHE CASSEL,,44.6123944867,1.51460026091,151,,Lamothe-Cassel,Lamothe-Cassel,46,Lot,76,Occitanie
46152,LAMOTHE FENELON,46350,LAMOTHE FENELON,,44.827504864,1.42494397821,152,,Lamothe-Fénelon,Lamothe-Fénelon,46,Lot,76,Occitanie
46153,LANZAC,46200,LANZAC,,44.8698887305,1.46972764942,153,,Lanzac,Lanzac,46,Lot,76,Occitanie
46154,LARAMIERE,46260,LARAMIERE,,44.359541732,1.87031104921,154,,Laramière,Laramière,46,Lot,76,Occitanie
46155,LARNAGOL,46160,LARNAGOL,,44.488515893,1.77727129228,155,,Larnagol,Larnagol,46,Lot,76,Occitanie
46156,LAROQUE DES ARCS,46090,LAROQUE DES ARCS,,44.4871654588,1.47065780509,156,,Bellefont-La Rauze,Bellefont-La Rauze,46,Lot,76,Occitanie
46157,LARROQUE TOIRAC,46160,LARROQUE TOIRAC,,44.5204913779,1.93257507348,157,,Larroque-Toirac,Larroque-Toirac,46,Lot,76,Occitanie
46158,LASCABANES,46800,LASCABANES,,44.3369299667,1.29272637382,158,,Lascabanes,Lascabanes,46,Lot,76,Occitanie
46159,LATOUILLE LENTILLAC,46400,LATOUILLE LENTILLAC,,44.8510729625,1.98049668844,159,,Latouille-Lentillac,Latouille-Lentillac,46,Lot,76,Occitanie
46160,LATRONQUIERE,46210,LATRONQUIERE,,44.8008999113,2.07093870837,160,,Latronquière,Latronquière,46,Lot,76,Occitanie
46161,LAURESSES,46210,LAURESSES,,44.7840183574,2.10885299987,161,,Lauresses,Lauresses,46,Lot,76,Occitanie
46162,LAUZES,46360,LAUZES,,44.5630061039,1.57779528998,162,,Lauzès,Lauzès,46,Lot,76,Occitanie
46163,LAVAL DE CERE,46130,LAVAL DE CERE,,44.9447136022,1.93517453774,163,,Laval-de-Cère,Laval-de-Cère,46,Lot,76,Occitanie
46164,LAVERCANTIERE,46340,LAVERCANTIERE,,44.6271991011,1.3231090399,164,,Lavercantière,Lavercantière,46,Lot,76,Occitanie
46165,LAVERGNE,46500,LAVERGNE,,44.8015351339,1.76588588621,165,,Lavergne,Lavergne,46,Lot,76,Occitanie
46167,LENTILLAC DU CAUSSE,46330,LENTILLAC DU CAUSSE,,44.5528093464,1.63790392885,167,,Lentillac-du-Causse,Lentillac-du-Causse,46,Lot,76,Occitanie
46168,LENTILLAC ST BLAISE,46100,LENTILLAC ST BLAISE,,44.5887571233,2.11729481925,168,,Lentillac-Saint-Blaise,Lentillac-Saint-Blaise,46,Lot,76,Occitanie
46169,LEOBARD,46300,LEOBARD,,44.7183844111,1.30807324893,169,,Léobard,Léobard,46,Lot,76,Occitanie
46170,LEYME,46120,LEYME,,44.7838029634,1.89700443992,170,,Leyme,Leyme,46,Lot,76,Occitanie
46171,LHERM,46150,LHERM,,44.5699106208,1.24112071813,171,,Lherm,Lherm,46,Lot,76,Occitanie
46172,LHOSPITALET,46170,LHOSPITALET,,44.3536160886,1.42807252232,172,,Lhospitalet,Lhospitalet,46,Lot,76,Occitanie
46173,LIMOGNE EN QUERCY,46260,LIMOGNE EN QUERCY,,44.3966427227,1.77829583648,173,,Limogne-en-Quercy,Limogne-en-Quercy,46,Lot,76,Occitanie
46174,LINAC,46270,LINAC,,44.6765020305,2.11729351761,174,,Linac,Linac,46,Lot,76,Occitanie
46175,LISSAC ET MOURET,46100,LISSAC ET MOURET,,44.6228358432,1.97160948476,175,,Lissac-et-Mouret,Lissac-et-Mouret,46,Lot,76,Occitanie
46176,LIVERNON,46320,LIVERNON,,44.6474230613,1.84523595274,176,,Livernon,Livernon,46,Lot,76,Occitanie
46177,LOUBRESSAC,46130,LOUBRESSAC,,44.8660989972,1.79065883803,177,,Loubressac,Loubressac,46,Lot,76,Occitanie
46178,LOUPIAC,46350,LOUPIAC,,44.826492947,1.47337068734,178,,Loupiac,Loupiac,46,Lot,76,Occitanie
46179,LUGAGNAC,46260,LUGAGNAC,,44.412063989,1.72089592713,179,,Lugagnac,Lugagnac,46,Lot,76,Occitanie
46180,LUNAN,46100,LUNAN,,44.6107026353,2.08424669582,180,,Lunan,Lunan,46,Lot,76,Occitanie
46181,LUNEGARDE,46240,LUNEGARDE,,44.6839954762,1.69297449351,181,,Lunegarde,Lunegarde,46,Lot,76,Occitanie
46182,LUZECH,46140,LUZECH,,44.4857575598,1.27596849228,182,,Luzech,Luzech,46,Lot,76,Occitanie
46183,MARCILHAC SUR CELE,46160,MARCILHAC SUR CELE,,44.5442171606,1.77401104203,183,,Marcilhac-sur-Célé,Marcilhac-sur-Célé,46,Lot,76,Occitanie
46184,MARMINIAC,46250,MARMINIAC,,44.6622898108,1.18403946197,184,,Marminiac,Marminiac,46,Lot,76,Occitanie
46185,MARTEL,46600,MARTEL,,44.9401536689,1.60734355344,185,,Martel,Martel,46,Lot,76,Occitanie
46186,MASCLAT,46350,MASCLAT,,44.8326739925,1.38072033414,186,,Masclat,Masclat,46,Lot,76,Occitanie
46187,MAUROUX,46700,MAUROUX,,44.4529573701,1.04640335319,187,,Mauroux,Mauroux,46,Lot,76,Occitanie
46188,MAXOU,46090,MAXOU,,44.5460613398,1.44746075267,188,,Maxou,Maxou,46,Lot,76,Occitanie
46189,MAYRINHAC LENTOUR,46500,MAYRINHAC LENTOUR,,44.8130239525,1.80546272438,189,,Mayrinhac-Lentour,Mayrinhac-Lentour,46,Lot,76,Occitanie
46190,MECHMONT,46150,MECHMONT,,44.5830981041,1.46298986385,190,,Mechmont,Mechmont,46,Lot,76,Occitanie
46191,MERCUES,46090,MERCUES,,44.4979192471,1.38947522255,191,,Mercuès,Mercuès,46,Lot,76,Occitanie
46192,MEYRONNE,46200,MEYRONNE,,44.8678105571,1.58876671405,192,,Meyronne,Meyronne,46,Lot,76,Occitanie
46193,MIERS,46500,MIERS,,44.8603418758,1.70422523019,193,,Miers,Miers,46,Lot,76,Occitanie
46194,MILHAC,46300,MILHAC,,44.7967572097,1.34841422414,194,,Milhac,Milhac,46,Lot,76,Occitanie
46195,MOLIERES,46120,MOLIERES,,44.7900404678,1.94025068217,195,,Molières,Molières,46,Lot,76,Occitanie
46196,MONTAMEL,46310,MONTAMEL,,44.6081820856,1.46461699053,196,,Montamel,Montamel,46,Lot,76,Occitanie
46197,LE MONTAT,46090,LE MONTAT,,44.3804715298,1.45487916761,197,Le,Montat,Le Montat,46,Lot,76,Occitanie
46198,MONTBRUN,46160,MONTBRUN,,44.5112155003,1.90302940937,198,,Montbrun,Montbrun,46,Lot,76,Occitanie
46199,MONTCABRIER,46700,MONTCABRIER,,44.5509026435,1.0816003002,199,,Montcabrier,Montcabrier,46,Lot,76,Occitanie
46200,MONTCLERA,46250,MONTCLERA,,44.6203221327,1.19833953446,200,,Montcléra,Montcléra,46,Lot,76,Occitanie
46201,MONTCUQ EN QUERCY BLANC,46800,MONTCUQ EN QUERCY BLANC,,44.335497631,1.21192283879,201,,Montcuq-en-Quercy-Blanc,Montcuq-en-Quercy-Blanc,46,Lot,76,Occitanie
46201,MONTCUQ EN QUERCY BLANC,46800,MONTCUQ EN QUERCY BLANC,BELMONTET,44.335497631,1.21192283879,201,,Montcuq-en-Quercy-Blanc,Montcuq-en-Quercy-Blanc,46,Lot,76,Occitanie
46201,MONTCUQ EN QUERCY BLANC,46800,MONTCUQ EN QUERCY BLANC,LEBREIL,44.335497631,1.21192283879,201,,Montcuq-en-Quercy-Blanc,Montcuq-en-Quercy-Blanc,46,Lot,76,Occitanie
46201,MONTCUQ EN QUERCY BLANC,46800,MONTCUQ EN QUERCY BLANC,STE CROIX,44.335497631,1.21192283879,201,,Montcuq-en-Quercy-Blanc,Montcuq-en-Quercy-Blanc,46,Lot,76,Occitanie
46201,MONTCUQ EN QUERCY BLANC,46800,MONTCUQ EN QUERCY BLANC,VALPRIONDE,44.335497631,1.21192283879,201,,Montcuq-en-Quercy-Blanc,Montcuq-en-Quercy-Blanc,46,Lot,76,Occitanie
46202,MONTDOUMERC,46230,MONTDOUMERC,,44.2911561581,1.50143339699,202,,Montdoumerc,Montdoumerc,46,Lot,76,Occitanie
46203,MONTET ET BOUXAL,46210,MONTET ET BOUXAL,,44.7484825909,2.03579923388,203,,Montet-et-Bouxal,Montet-et-Bouxal,46,Lot,76,Occitanie
46204,MONTFAUCON,46240,MONTFAUCON,,44.6868814093,1.58516687281,204,,Montfaucon,Montfaucon,46,Lot,76,Occitanie
46205,MONTGESTY,46150,MONTGESTY,,44.5751144304,1.29312666435,205,,Montgesty,Montgesty,46,Lot,76,Occitanie
46206,MONTLAUZUN,46800,MONTLAUZUN,,44.296637625,1.19344943646,206,,Montlauzun,Montlauzun,46,Lot,76,Occitanie
46207,MONTREDON,46270,MONTREDON,,44.6130585802,2.18467035164,207,,Montredon,Montredon,46,Lot,76,Occitanie
46208,MONTVALENT,46600,MONTVALENT,,44.8740684333,1.63555769286,208,,Montvalent,Montvalent,46,Lot,76,Occitanie
46209,NADAILLAC DE ROUGE,46350,NADAILLAC DE ROUGE,,44.8500289786,1.43013868148,209,,Nadaillac-de-Rouge,Nadaillac-de-Rouge,46,Lot,76,Occitanie
46210,NADILLAC,46360,NADILLAC,,44.5639146169,1.51053421394,210,,Nadillac,Nadillac,46,Lot,76,Occitanie
46211,NUZEJOULS,46150,NUZEJOULS,,44.5360995607,1.373984961,211,,Nuzéjouls,Nuzéjouls,46,Lot,76,Occitanie
46212,ORNIAC,46330,ORNIAC,,44.5429306051,1.67747447618,212,,Orniac,Orniac,46,Lot,76,Occitanie
46213,PADIRAC,46500,PADIRAC,,44.8503221914,1.75624205079,213,,Padirac,Padirac,46,Lot,76,Occitanie
46214,PARNAC,46140,PARNAC,,44.4811948006,1.31867316292,214,,Parnac,Parnac,46,Lot,76,Occitanie
46215,PAYRAC,46350,PAYRAC,,44.7938474371,1.46578080794,215,,Payrac,Payrac,46,Lot,76,Occitanie
46216,PAYRIGNAC,46300,PAYRIGNAC,,44.7629337139,1.34345331858,216,,Payrignac,Payrignac,46,Lot,76,Occitanie
46217,PERN,46170,PERN,,44.3345123172,1.40726154278,217,,Pern,Pern,46,Lot,76,Occitanie
46218,PESCADOIRES,46220,PESCADOIRES,,44.4994584805,1.15835515488,218,,Pescadoires,Pescadoires,46,Lot,76,Occitanie
46219,PEYRILLES,46310,PEYRILLES,,44.6213062372,1.39338062663,219,,Peyrilles,Peyrilles,46,Lot,76,Occitanie
46220,PINSAC,46200,PINSAC,,44.8610534659,1.51873154289,220,,Pinsac,Pinsac,46,Lot,76,Occitanie
46221,PLANIOLES,46100,PLANIOLES,,44.6420092578,2.02813348021,221,,Planioles,Planioles,46,Lot,76,Occitanie
46222,POMAREDE,46250,POMAREDE,,44.5585100329,1.17344386369,222,,Pomarède,Pomarède,46,Lot,76,Occitanie
46223,PONTCIRQ,46150,PONTCIRQ,,44.5453068327,1.26488009495,223,,Pontcirq,Pontcirq,46,Lot,76,Occitanie
46224,PRADINES,46090,PRADINES,,44.464606105,1.38849304756,224,,Pradines,Pradines,46,Lot,76,Occitanie
46225,PRAYSSAC,46220,PRAYSSAC,,44.5137023932,1.18723639208,225,,Prayssac,Prayssac,46,Lot,76,Occitanie
46226,PRENDEIGNES,46270,PRENDEIGNES,,44.6977109541,2.07671859473,226,,Prendeignes,Prendeignes,46,Lot,76,Occitanie
46227,PROMILHANES,46260,PROMILHANES,,44.3763205696,1.83026899393,227,,Promilhanes,Promilhanes,46,Lot,76,Occitanie
46228,PRUDHOMAT,46130,PRUDHOMAT,,44.896756286,1.81736131587,228,,Prudhomat,Prudhomat,46,Lot,76,Occitanie
46229,PUYBRUN,46130,PUYBRUN,,44.9213943929,1.78724150128,229,,Puybrun,Puybrun,46,Lot,76,Occitanie
46230,PUYJOURDES,46260,PUYJOURDES,,44.401822561,1.85525892069,230,,Puyjourdes,Puyjourdes,46,Lot,76,Occitanie
46231,PUY L EVEQUE,46700,PUY L EVEQUE,,44.5166104791,1.13576954751,231,,Puy-l'Évêque,Puy-l'Évêque,46,Lot,76,Occitanie
46232,LES QUATRE ROUTES DU LOT,46110,LES QUATRE ROUTES DU LOT,,44.9962499088,1.63795364343,232,Les,Quatre-Routes-du-Lot,Les Quatre-Routes-du-Lot,46,Lot,76,Occitanie
46233,QUISSAC,46320,QUISSAC,,44.6246336398,1.71917437055,233,,Quissac,Quissac,46,Lot,76,Occitanie
46234,RAMPOUX,46340,RAMPOUX,,44.6398724646,1.29448075247,234,,Rampoux,Rampoux,46,Lot,76,Occitanie
46235,REILHAC,46500,REILHAC,,44.7017845632,1.72572975091,235,,Reilhac,Reilhac,46,Lot,76,Occitanie
46236,REILHAGUET,46350,REILHAGUET,,44.7790219668,1.50802496994,236,,Reilhaguet,Reilhaguet,46,Lot,76,Occitanie
46237,REYREVIGNES,46320,REYREVIGNES,,44.6454378088,1.9117181352,237,,Reyrevignes,Reyrevignes,46,Lot,76,Occitanie
46238,RIGNAC,46500,RIGNAC,,44.8065259652,1.70450822621,238,,Rignac,Rignac,46,Lot,76,Occitanie
46239,LE ROC,46200,LE ROC,,44.8649069873,1.43907762141,239,Le,Roc,Le Roc,46,Lot,76,Occitanie
46240,ROCAMADOUR,46500,ROCAMADOUR,,44.8148525753,1.62220346544,240,,Rocamadour,Rocamadour,46,Lot,76,Occitanie
46241,ROUFFILHAC,46300,ROUFFILHAC,,44.7969748573,1.41366274165,241,,Rouffilhac,Rouffilhac,46,Lot,76,Occitanie
46242,RUDELLE,46120,RUDELLE,,44.7292835526,1.87794922914,242,,Rudelle,Rudelle,46,Lot,76,Occitanie
46243,RUEYRES,46120,RUEYRES,,44.7537580585,1.85554615594,243,,Rueyres,Rueyres,46,Lot,76,Occitanie
46244,SABADEL LATRONQUIERE,46210,SABADEL LATRONQUIERE,,44.7266880889,2.06622639208,244,,Sabadel-Latronquière,Sabadel-Latronquière,46,Lot,76,Occitanie
46245,SABADEL LAUZES,46360,SABADEL LAUZES,,44.5597362449,1.6075760767,245,,Sabadel-Lauzès,Sabadel-Lauzès,46,Lot,76,Occitanie
46246,SAIGNES,46500,SAIGNES,,44.7891039927,1.82108642363,246,,Saignes,Saignes,46,Lot,76,Occitanie
46247,SAILLAC,46260,SAILLAC,,44.3295466737,1.74148864793,247,,Saillac,Saillac,46,Lot,76,Occitanie
46248,STE ALAUZIE,46170,STE ALAUZIE,,44.3124307897,1.320128298,248,,Sainte-Alauzie,Sainte-Alauzie,46,Lot,76,Occitanie
46249,ST BRESSOU,46120,ST BRESSOU,,44.6967820287,1.9760982727,249,,Saint-Bressou,Saint-Bressou,46,Lot,76,Occitanie
46250,ST CAPRAIS,46250,ST CAPRAIS,,44.6129548228,1.15458264598,250,,Saint-Caprais,Saint-Caprais,46,Lot,76,Occitanie
46251,ST CERE,46400,ST CERE,,44.8531827667,1.89258045388,251,,Saint-Céré,Saint-Céré,46,Lot,76,Occitanie
46252,LES PECHS DU VERS,46360,LES PECHS DU VERS,ST CERNIN,44.5992781487,1.58540945458,252,Les,Pechs du Vers,Les Pechs du Vers,46,Lot,76,Occitanie
46252,LES PECHS DU VERS,46360,LES PECHS DU VERS,ST MARTIN DE VERS,44.5992781487,1.58540945458,252,Les,Pechs du Vers,Les Pechs du Vers,46,Lot,76,Occitanie
46253,ST CHAMARAND,46310,ST CHAMARAND,,44.6842825979,1.45932378106,253,,Saint-Chamarand,Saint-Chamarand,46,Lot,76,Occitanie
46254,ST CHELS,46160,ST CHELS,,44.5261400894,1.81487808912,254,,Saint-Chels,Saint-Chels,46,Lot,76,Occitanie
46255,ST CIRGUES,46210,ST CIRGUES,,44.7359417432,2.11647884119,255,,Saint-Cirgues,Saint-Cirgues,46,Lot,76,Occitanie
46256,ST CIRQ LAPOPIE,46330,ST CIRQ LAPOPIE,,44.456177199,1.65375113458,256,,Saint-Cirq-Lapopie,Saint-Cirq-Lapopie,46,Lot,76,Occitanie
46257,ST CIRQ MADELON,46300,ST CIRQ MADELON,,44.7896986381,1.31685222838,257,,Saint-Cirq-Madelon,Saint-Cirq-Madelon,46,Lot,76,Occitanie
46258,ST CIRQ SOUILLAGUET,46300,ST CIRQ SOUILLAGUET,,44.7052928988,1.44133375749,258,,Saint-Cirq-Souillaguet,Saint-Cirq-Souillaguet,46,Lot,76,Occitanie
46259,ST CLAIR,46300,ST CLAIR,,44.6939193723,1.39885688127,259,,Saint-Clair,Saint-Clair,46,Lot,76,Occitanie
46260,STE COLOMBE,46120,STE COLOMBE,,44.7204297846,2.01266388255,260,,Sainte-Colombe,Sainte-Colombe,46,Lot,76,Occitanie
46262,ST CYPRIEN,46800,ST CYPRIEN,,44.3040998679,1.26251296057,262,,Lendou-en-Quercy,Lendou-en-Quercy,46,Lot,76,Occitanie
46263,ST DAUNES,46800,ST DAUNES,,44.3601858859,1.22469783155,263,,Saint-Daunès,Saint-Daunès,46,Lot,76,Occitanie
46264,ST DENIS CATUS,46150,ST DENIS CATUS,,44.5692422139,1.38819379343,264,,Saint-Denis-Catus,Saint-Denis-Catus,46,Lot,76,Occitanie
46265,ST DENIS LES MARTEL,46600,ST DENIS LES MARTEL,,44.9453250919,1.6588440719,265,,Saint-Denis-lès-Martel,Saint-Denis-lès-Martel,46,Lot,76,Occitanie
46266,ST FELIX,46100,ST FELIX,,44.6124237532,2.11317845009,266,,Saint-Félix,Saint-Félix,46,Lot,76,Occitanie
46267,ST GERMAIN DU BEL AIR,46310,ST GERMAIN DU BEL AIR,,44.6460780421,1.44147433368,267,,Saint-Germain-du-Bel-Air,Saint-Germain-du-Bel-Air,46,Lot,76,Occitanie
46268,ST GERY,46330,ST GERY,,44.4863902583,1.6001798484,268,,Saint Géry-Vers,Saint Géry-Vers,46,Lot,76,Occitanie
46269,ST HILAIRE,46210,ST HILAIRE,,44.7884633484,2.15008064769,269,,Saint-Hilaire,Saint-Hilaire,46,Lot,76,Occitanie
46270,ST JEAN DE LAUR,46260,ST JEAN DE LAUR,,44.4301259055,1.83118318259,270,,Saint-Jean-de-Laur,Saint-Jean-de-Laur,46,Lot,76,Occitanie
46271,ST JEAN LESPINASSE,46400,ST JEAN LESPINASSE,,44.8581768481,1.85968660028,271,,Saint-Jean-Lespinasse,Saint-Jean-Lespinasse,46,Lot,76,Occitanie
46272,ST JEAN MIRABEL,46270,ST JEAN MIRABEL,,44.632783042,2.10766721275,272,,Saint-Jean-Mirabel,Saint-Jean-Mirabel,46,Lot,76,Occitanie
46273,ST LAURENT LES TOURS,46400,ST LAURENT LES TOURS,,44.8817459131,1.90434395957,273,,Saint-Laurent-les-Tours,Saint-Laurent-les-Tours,46,Lot,76,Occitanie
46274,ST LAURENT LOLMIE,46800,ST LAURENT LOLMIE,,44.2872527124,1.2312861209,274,,Saint-Laurent-Lolmie,Saint-Laurent-Lolmie,46,Lot,76,Occitanie
46276,ST MARTIN LABOUVAL,46330,ST MARTIN LABOUVAL,,44.4795975074,1.72861355188,276,,Saint-Martin-Labouval,Saint-Martin-Labouval,46,Lot,76,Occitanie
46277,ST MARTIN LE REDON,46700,ST MARTIN LE REDON,,44.5343809724,1.03448043775,277,,Saint-Martin-le-Redon,Saint-Martin-le-Redon,46,Lot,76,Occitanie
46278,ST MATRE,46800,ST MATRE,,44.4034192657,1.11751838823,278,,Saint-Matré,Saint-Matré,46,Lot,76,Occitanie
46279,ST MAURICE EN QUERCY,46120,ST MAURICE EN QUERCY,,44.7493866027,1.95646196861,279,,Saint-Maurice-en-Quercy,Saint-Maurice-en-Quercy,46,Lot,76,Occitanie
46280,ST MEDARD,46150,ST MEDARD,,44.5388913734,1.29488289178,280,,Saint-Médard,Saint-Médard,46,Lot,76,Occitanie
46281,ST MEDARD DE PRESQUE,46400,ST MEDARD DE PRESQUE,,44.8523355559,1.84227954038,281,,Saint-Médard-de-Presque,Saint-Médard-de-Presque,46,Lot,76,Occitanie
46282,ST MEDARD NICOURBY,46210,ST MEDARD NICOURBY,,44.7654922895,2.01091154394,282,,Saint-Médard-Nicourby,Saint-Médard-Nicourby,46,Lot,76,Occitanie
46283,ST MICHEL DE BANNIERES,46110,ST MICHEL DE BANNIERES,,44.9755608245,1.68434656642,283,,Saint-Michel-de-Bannières,Saint-Michel-de-Bannières,46,Lot,76,Occitanie
46284,ST MICHEL LOUBEJOU,46130,ST MICHEL LOUBEJOU,,44.8881827852,1.85309104006,284,,Saint-Michel-Loubéjou,Saint-Michel-Loubéjou,46,Lot,76,Occitanie
46285,ST PANTALEON,46800,ST PANTALEON,,44.3681341209,1.28400612786,285,,Saint-Pantaléon,Saint-Pantaléon,46,Lot,76,Occitanie
46286,ST PAUL DE VERN,46400,ST PAUL DE VERN,,44.8378106011,1.94214207363,286,,Saint-Paul-de-Vern,Saint-Paul-de-Vern,46,Lot,76,Occitanie
46288,ST PERDOUX,46100,ST PERDOUX,,44.6797707425,2.04878159126,288,,Saint-Perdoux,Saint-Perdoux,46,Lot,76,Occitanie
46289,ST PIERRE TOIRAC,46160,ST PIERRE TOIRAC,,44.5365701769,1.95356991688,289,,Saint-Pierre-Toirac,Saint-Pierre-Toirac,46,Lot,76,Occitanie
46290,ST PROJET,46300,ST PROJET,,44.7472704411,1.51114352201,290,,Saint-Projet,Saint-Projet,46,Lot,76,Occitanie
46292,ST SIMON,46320,ST SIMON,,44.7006878199,1.82811413555,292,,Saint-Simon,Saint-Simon,46,Lot,76,Occitanie
46293,ST SOZY,46200,ST SOZY,,44.8817461552,1.55074989769,293,,Saint-Sozy,Saint-Sozy,46,Lot,76,Occitanie
46294,ST SULPICE,46160,ST SULPICE,,44.5791760633,1.79550043647,294,,Saint-Sulpice,Saint-Sulpice,46,Lot,76,Occitanie
46295,ST VINCENT DU PENDIT,46400,ST VINCENT DU PENDIT,,44.8338408589,1.9041542502,295,,Saint-Vincent-du-Pendit,Saint-Vincent-du-Pendit,46,Lot,76,Occitanie
46296,ST VINCENT RIVE D OLT,46140,ST VINCENT RIVE D OLT,,44.4489621367,1.3138253164,296,,Saint-Vincent-Rive-d'Olt,Saint-Vincent-Rive-d'Olt,46,Lot,76,Occitanie
46297,SALVIAC,46340,SALVIAC,,44.6854910837,1.26478802676,297,,Salviac,Salviac,46,Lot,76,Occitanie
46298,SARRAZAC,46600,SARRAZAC,,45.0168784249,1.57945390824,298,,Sarrazac,Sarrazac,46,Lot,76,Occitanie
46299,SAULIAC SUR CELE,46330,SAULIAC SUR CELE,,44.5227418077,1.71784528789,299,,Sauliac-sur-Célé,Sauliac-sur-Célé,46,Lot,76,Occitanie
46300,SAUX,46800,SAUX,,44.3939091155,1.07823527054,300,,Saux,Saux,46,Lot,76,Occitanie
46301,SAUZET,46140,SAUZET,,44.418405817,1.25953529906,301,,Sauzet,Sauzet,46,Lot,76,Occitanie
46302,SENAILLAC LATRONQUIERE,46210,SENAILLAC LATRONQUIERE,,44.8362230698,2.07406186694,302,,Sénaillac-Latronquière,Sénaillac-Latronquière,46,Lot,76,Occitanie
46303,SENAILLAC LAUZES,46360,SENAILLAC LAUZES,,44.58919537,1.65078295529,303,,Sénaillac-Lauzès,Sénaillac-Lauzès,46,Lot,76,Occitanie
46304,SENIERGUES,46240,SENIERGUES,,44.714653063,1.560844102,304,,Séniergues,Séniergues,46,Lot,76,Occitanie
46305,SERIGNAC,46700,SERIGNAC,,44.4278702673,1.08610284521,305,,Sérignac,Sérignac,46,Lot,76,Occitanie
46306,SONAC,46320,SONAC,,44.6924652342,1.85472288011,306,,Sonac,Sonac,46,Lot,76,Occitanie
46307,SOTURAC,46700,SOTURAC,,44.5081490577,1.023587785,307,,Soturac,Soturac,46,Lot,76,Occitanie
46308,SOUCIRAC,46300,SOUCIRAC,,44.7068820239,1.50396524795,308,,Soucirac,Soucirac,46,Lot,76,Occitanie
46309,SOUILLAC,46200,SOUILLAC,,44.9069531608,1.46396482842,309,,Souillac,Souillac,46,Lot,76,Occitanie
46310,SOULOMES,46240,SOULOMES,,44.6269246895,1.59005920617,310,,Soulomès,Soulomès,46,Lot,76,Occitanie
46311,SOUSCEYRAC EN QUERCY,46190,SOUSCEYRAC EN QUERCY,,44.8806327764,2.03547276303,311,,Sousceyrac-en-Quercy,Sousceyrac-en-Quercy,46,Lot,76,Occitanie
46311,SOUSCEYRAC EN QUERCY,46190,SOUSCEYRAC EN QUERCY,CALVIAC,44.8806327764,2.03547276303,311,,Sousceyrac-en-Quercy,Sousceyrac-en-Quercy,46,Lot,76,Occitanie
46311,SOUSCEYRAC EN QUERCY,46190,SOUSCEYRAC EN QUERCY,COMIAC,44.8806327764,2.03547276303,311,,Sousceyrac-en-Quercy,Sousceyrac-en-Quercy,46,Lot,76,Occitanie
46311,SOUSCEYRAC EN QUERCY,46190,SOUSCEYRAC EN QUERCY,LACAM D OURCET,44.8806327764,2.03547276303,311,,Sousceyrac-en-Quercy,Sousceyrac-en-Quercy,46,Lot,76,Occitanie
46311,SOUSCEYRAC EN QUERCY,46190,SOUSCEYRAC EN QUERCY,LAMATIVIE,44.8806327764,2.03547276303,311,,Sousceyrac-en-Quercy,Sousceyrac-en-Quercy,46,Lot,76,Occitanie
46312,STRENQUELS,46110,STRENQUELS,,44.974737052,1.63626757537,312,,Strenquels,Strenquels,46,Lot,76,Occitanie
46313,TAURIAC,46130,TAURIAC,,44.9103810308,1.77420237883,313,,Tauriac,Tauriac,46,Lot,76,Occitanie
46314,TERROU,46120,TERROU,,44.7824469874,1.97624209767,314,,Terrou,Terrou,46,Lot,76,Occitanie
46315,TEYSSIEU,46190,TEYSSIEU,,44.9167422017,1.95733992168,315,,Teyssieu,Teyssieu,46,Lot,76,Occitanie
46316,THEDIRAC,46150,THEDIRAC,,44.5993894609,1.32059065502,316,,Thédirac,Thédirac,46,Lot,76,Occitanie
46317,THEGRA,46500,THEGRA,,44.8242153584,1.75171823166,317,,Thégra,Thégra,46,Lot,76,Occitanie
46318,THEMINES,46120,THEMINES,,44.736284304,1.82100865888,318,,Thémines,Thémines,46,Lot,76,Occitanie
46319,THEMINETTES,46120,THEMINETTES,,44.7180986465,1.84984788419,319,,Théminettes,Théminettes,46,Lot,76,Occitanie
46320,TOUR DE FAURE,46330,TOUR DE FAURE,,44.4763123907,1.68646550409,320,,Tour-de-Faure,Tour-de-Faure,46,Lot,76,Occitanie
46321,TOUZAC,46700,TOUZAC,,44.4962914866,1.05945113782,321,,Touzac,Touzac,46,Lot,76,Occitanie
46322,TRESPOUX RASSIELS,46090,TRESPOUX RASSIELS,,44.4215309294,1.35834286851,322,,Trespoux-Rassiels,Trespoux-Rassiels,46,Lot,76,Occitanie
46323,USSEL,46240,USSEL,,44.5918903079,1.49596449018,323,,Ussel,Ussel,46,Lot,76,Occitanie
46324,UZECH,46310,UZECH,,44.5932890914,1.38558218639,324,,Uzech,Uzech,46,Lot,76,Occitanie
46327,VALROUFIE,46090,VALROUFIE,,44.5135576262,1.48695613582,327,,Valroufié,Valroufié,46,Lot,76,Occitanie
46328,VARAIRE,46260,VARAIRE,,44.3684009412,1.71781204622,328,,Varaire,Varaire,46,Lot,76,Occitanie
46329,VAYLATS,46230,VAYLATS,,44.3249960013,1.64994675645,329,,Vaylats,Vaylats,46,Lot,76,Occitanie
46330,VAYRAC,46110,VAYRAC,,44.9450597014,1.69320675098,330,,Vayrac,Vayrac,46,Lot,76,Occitanie
46331,VERS,46090,VERS,,44.4913593122,1.54417907483,331,,Vers,Vers,46,Lot,76,Occitanie
46332,VIAZAC,46100,VIAZAC,,44.6523523693,2.07287851725,332,,Viazac,Viazac,46,Lot,76,Occitanie
46333,VIDAILLAC,46260,VIDAILLAC,,44.346009469,1.81940730105,333,,Vidaillac,Vidaillac,46,Lot,76,Occitanie
46334,LE VIGAN,46300,LE VIGAN,,44.7394789138,1.45239554217,334,Le,Vigan,Le Vigan,46,Lot,76,Occitanie
46335,VILLESEQUE,46090,VILLESEQUE,,44.3978080627,1.31439035193,335,,Villesèque,Villesèque,46,Lot,76,Occitanie
46336,VIRE SUR LOT,46700,VIRE SUR LOT,,44.4878353451,1.09829144521,336,,Vire-sur-Lot,Vire-sur-Lot,46,Lot,76,Occitanie
46337,MAYRAC,46200,MAYRAC,,44.8995623407,1.54645622061,337,,Mayrac,Mayrac,46,Lot,76,Occitanie
46338,BESSONIES,46210,BESSONIES,,44.8085327316,2.14444051482,338,,Bessonies,Bessonies,46,Lot,76,Occitanie
46339,ST JEAN LAGINESTE,46400,ST JEAN LAGINESTE,,44.8253267169,1.852018408,339,,Saint-Jean-Lagineste,Saint-Jean-Lagineste,46,Lot,76,Occitanie
46340,ST PIERRE LAFEUILLE,46090,ST PIERRE LAFEUILLE,,44.5174745884,1.45454544343,340,,Saint-Pierre-Lafeuille,Saint-Pierre-Lafeuille,46,Lot,76,Occitanie
47001,AGEN,47000,AGEN,,44.2028139104,0.625583928763,1,,Agen,Agen,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47002,AGME,47350,AGME,,44.4893814272,0.340441106525,2,,Agmé,Agmé,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47003,AGNAC,47800,AGNAC,,44.640821375,0.382742954809,3,,Agnac,Agnac,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47004,AIGUILLON,47190,AIGUILLON,,44.3012876866,0.358229103443,4,,Aiguillon,Aiguillon,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47005,ALLEMANS DU DROPT,47800,ALLEMANS DU DROPT,,44.6231983139,0.286538311278,5,,Allemans-du-Dropt,Allemans-du-Dropt,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47006,ALLEZ ET CAZENEUVE,47110,ALLEZ ET CAZENEUVE,,44.3819408307,0.628501205807,6,,Allez-et-Cazeneuve,Allez-et-Cazeneuve,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47007,ALLONS,47420,ALLONS,,44.1933826797,-0.0800075386017,7,,Allons,Allons,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47008,AMBRUS,47160,AMBRUS,,44.2306453539,0.246322512881,8,,Ambrus,Ambrus,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47009,ANDIRAN,47170,ANDIRAN,,44.1023256865,0.285513474394,9,,Andiran,Andiran,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47010,ANTAGNAC,47700,ANTAGNAC,,44.3562107379,-0.00209058322762,10,,Antagnac,Antagnac,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47011,ANTHE,47370,ANTHE,,44.3736020127,0.955224087107,11,,Anthé,Anthé,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47012,ANZEX,47700,ANZEX,,44.2862673358,0.170412994362,12,,Anzex,Anzex,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47013,ARGENTON,47250,ARGENTON,,44.3845067186,0.0666936093106,13,,Argenton,Argenton,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47014,ARMILLAC,47800,ARMILLAC,,44.5557248884,0.389925235964,14,,Armillac,Armillac,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47015,ASTAFFORT,47220,ASTAFFORT,,44.0646686165,0.658006549076,15,,Astaffort,Astaffort,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47016,AUBIAC,47310,AUBIAC,,44.1378277846,0.568271708498,16,,Aubiac,Aubiac,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47017,AURADOU,47140,AURADOU,,44.3409556685,0.814792058208,17,,Auradou,Auradou,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47018,AURIAC SUR DROPT,47120,AURIAC SUR DROPT,,44.6544707799,0.243508495124,18,,Auriac-sur-Dropt,Auriac-sur-Dropt,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47019,BAJAMONT,47480,BAJAMONT,,44.2587889036,0.701934519918,19,,Bajamont,Bajamont,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47020,BALEYSSAGUES,47120,BALEYSSAGUES,,44.6820321193,0.149443103335,20,,Baleyssagues,Baleyssagues,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47021,BARBASTE,47230,BARBASTE,,44.1586009186,0.244120736429,21,,Barbaste,Barbaste,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47022,BAZENS,47130,BAZENS,,44.2679299357,0.426598073489,22,,Bazens,Bazens,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47023,BEAUGAS,47290,BEAUGAS,,44.5114048641,0.623448360268,23,,Beaugas,Beaugas,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47024,BEAUPUY,47200,BEAUPUY,,44.5332209299,0.144907707512,24,,Beaupuy,Beaupuy,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47025,BEAUVILLE,47470,BEAUVILLE,,44.2747735697,0.896478238641,25,,Beauville,Beauville,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47026,BEAUZIAC,47700,BEAUZIAC,,44.3136376816,0.0325531377518,26,,Beauziac,Beauziac,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47027,BIAS,47300,BIAS,,44.410110122,0.669751567768,27,,Bias,Bias,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47028,BIRAC SUR TREC,47200,BIRAC SUR TREC,,44.4871002309,0.259780510047,28,,Birac-sur-Trec,Birac-sur-Trec,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47029,BLANQUEFORT SUR BRIOLANCE,47500,BLANQUEFORT SUR BRIOLANCE,,44.6091963287,0.960434375937,29,,Blanquefort-sur-Briolance,Blanquefort-sur-Briolance,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47030,BLAYMONT,47470,BLAYMONT,,44.29466299,0.859118613428,30,,Blaymont,Blaymont,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47031,BOE,47550,BOE,,44.1702699988,0.646760618523,31,,Boé,Boé,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47032,BON ENCONTRE,47240,BON ENCONTRE,,44.2084448019,0.689070804076,32,,Bon-Encontre,Bon-Encontre,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47033,BOUDY DE BEAUREGARD,47290,BOUDY DE BEAUREGARD,,44.5438485414,0.67747020369,33,,Boudy-de-Beauregard,Boudy-de-Beauregard,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47034,BOUGLON,47250,BOUGLON,,44.4036708289,0.100423976197,34,,Bouglon,Bouglon,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47035,BOURGOUGNAGUE,47410,BOURGOUGNAGUE,,44.6190672361,0.419708136105,35,,Bourgougnague,Bourgougnague,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47036,BOURLENS,47370,BOURLENS,,44.4254220753,0.972634935921,36,,Bourlens,Bourlens,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47037,BOURNEL,47210,BOURNEL,,44.6319527324,0.676958694096,37,,Bournel,Bournel,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47038,BOURRAN,47320,BOURRAN,,44.3326225834,0.40309319188,38,,Bourran,Bourran,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47039,BOUSSES,47420,BOUSSES,,44.1540947001,0.0821269395994,39,,Boussès,Boussès,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47040,BRAX,47310,BRAX,,44.201959917,0.557521330574,40,,Brax,Brax,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47041,BRUCH,47130,BRUCH,,44.20662795,0.405442754423,41,,Bruch,Bruch,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47042,BRUGNAC,47260,BRUGNAC,,44.450750609,0.45284547075,42,,Brugnac,Brugnac,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47043,BUZET SUR BAISE,47160,BUZET SUR BAISE,,44.2535434476,0.302105091596,43,,Buzet-sur-Baïse,Buzet-sur-Baïse,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47044,CAHUZAC,47330,CAHUZAC,,44.6603987371,0.562496296717,44,,Cahuzac,Cahuzac,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47045,CALIGNAC,47600,CALIGNAC,,44.1363247779,0.41705042577,45,,Calignac,Calignac,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47046,CALONGES,47430,CALONGES,,44.373357824,0.225583969332,46,,Calonges,Calonges,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47047,CAMBES,47350,CAMBES,,44.5962932231,0.277882299934,47,,Cambes,Cambes,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47048,CANCON,47290,CANCON,,44.5473985229,0.627800125632,48,,Cancon,Cancon,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47049,CASSENEUIL,47440,CASSENEUIL,,44.4480744833,0.62184226742,49,,Casseneuil,Casseneuil,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47050,CASSIGNAS,47340,CASSIGNAS,,44.3000939624,0.781102690761,50,,Cassignas,Cassignas,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47051,CASTELCULIER,47240,CASTELCULIER,,44.1880929707,0.710790193831,51,,Castelculier,Castelculier,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47052,CASTELJALOUX,47700,CASTELJALOUX,,44.3114746788,0.0845386197153,52,,Casteljaloux,Casteljaloux,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47053,CASTELLA,47340,CASTELLA,,44.3138960011,0.684442218715,53,,Castella,Castella,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47054,CASTELMORON SUR LOT,47260,CASTELMORON SUR LOT,,44.4077972964,0.481340704559,54,,Castelmoron-sur-Lot,Castelmoron-sur-Lot,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47055,CASTELNAUD DE GRATECAMBE,47290,CASTELNAUD DE GRATECAMBE,,44.5023184217,0.682922054385,55,,Castelnaud-de-Gratecambe,Castelnaud-de-Gratecambe,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47056,CASTELNAU SUR GUPIE,47180,CASTELNAU SUR GUPIE,,44.5803543889,0.140031725021,56,,Castelnau-sur-Gupie,Castelnau-sur-Gupie,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47057,CASTILLONNES,47330,CASTILLONNES,,44.6392690968,0.605732288011,57,,Castillonnès,Castillonnès,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47058,CAUBEYRES,47160,CAUBEYRES,,44.2579597615,0.21692393129,58,,Caubeyres,Caubeyres,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47059,CAUBON ST SAUVEUR,47120,CAUBON ST SAUVEUR,,44.5927480005,0.184135065483,59,,Caubon-Saint-Sauveur,Caubon-Saint-Sauveur,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47060,CAUDECOSTE,47220,CAUDECOSTE,,44.1187326422,0.737249892638,60,,Caudecoste,Caudecoste,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47061,CAUMONT SUR GARONNE,47430,CAUMONT SUR GARONNE,,44.4273646306,0.180258974885,61,,Caumont-sur-Garonne,Caumont-sur-Garonne,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47062,CAUZAC,47470,CAUZAC,,44.2746170844,0.821512520951,62,,Cauzac,Cauzac,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47063,CAVARC,47330,CAVARC,,44.6820226706,0.641513510032,63,,Cavarc,Cavarc,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47064,CAZIDEROQUE,47370,CAZIDEROQUE,,44.4042830502,0.930041418738,64,,Cazideroque,Cazideroque,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47065,CLAIRAC,47320,CLAIRAC,,44.371064569,0.38579130311,65,,Clairac,Clairac,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47066,CLERMONT DESSOUS,47130,CLERMONT DESSOUS,,44.2487245183,0.456237905112,66,,Clermont-Dessous,Clermont-Dessous,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47067,CLERMONT SOUBIRAN,47270,CLERMONT SOUBIRAN,,44.1412580934,0.848563434392,67,,Clermont-Soubiran,Clermont-Soubiran,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47068,COCUMONT,47250,COCUMONT,,44.453586027,0.0335952053533,68,,Cocumont,Cocumont,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47069,COLAYRAC ST CIRQ,47450,COLAYRAC ST CIRQ,,44.232339238,0.567143268052,69,,Colayrac-Saint-Cirq,Colayrac-Saint-Cirq,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47070,CONDEZAYGUES,47500,CONDEZAYGUES,,44.4875096841,0.909054769911,70,,Condezaygues,Condezaygues,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47071,COULX,47260,COULX,,44.4738657983,0.467367146854,71,,Coulx,Coulx,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47072,COURBIAC,47370,COURBIAC,,44.3764701728,1.04053101362,72,,Courbiac,Courbiac,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47073,COURS,47360,COURS,,44.3258792587,0.593180292489,73,,Cours,Cours,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47074,COUTHURES SUR GARONNE,47180,COUTHURES SUR GARONNE,,44.5203730714,0.068285349184,74,,Couthures-sur-Garonne,Couthures-sur-Garonne,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47075,LA CROIX BLANCHE,47340,LA CROIX BLANCHE,,44.2901521659,0.693082511784,75,La,Croix-Blanche,La Croix-Blanche,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47076,CUQ,47220,CUQ,,44.0810900261,0.716495336369,76,,Cuq,Cuq,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47077,CUZORN,47500,CUZORN,,44.5473969592,0.948776133607,77,,Cuzorn,Cuzorn,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47078,DAMAZAN,47160,DAMAZAN,,44.2886728778,0.275113045329,78,,Damazan,Damazan,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47079,DAUSSE,47140,DAUSSE,,44.3878408566,0.889281458819,79,,Dausse,Dausse,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47080,DEVILLAC,47210,DEVILLAC,,44.6084613514,0.808010274791,80,,Dévillac,Dévillac,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47081,DOLMAYRAC,47110,DOLMAYRAC,,44.3565526064,0.591606081403,81,,Dolmayrac,Dolmayrac,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47082,DONDAS,47470,DONDAS,,44.2400810523,0.853095395188,82,,Dondas,Dondas,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47083,DOUDRAC,47210,DOUDRAC,,44.6644247017,0.679748362651,83,,Doudrac,Doudrac,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47084,DOUZAINS,47330,DOUZAINS,,44.636309752,0.551520734246,84,,Douzains,Douzains,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47085,DURANCE,47420,DURANCE,,44.1573942132,0.162796058009,85,,Durance,Durance,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47086,DURAS,47120,DURAS,,44.6760156672,0.201414040802,86,,Duras,Duras,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47087,ENGAYRAC,47470,ENGAYRAC,,44.2525895888,0.900187175116,87,,Engayrac,Engayrac,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47088,ESCASSEFORT,47350,ESCASSEFORT,,44.5521873277,0.231716447116,88,,Escassefort,Escassefort,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47089,ESCLOTTES,47120,ESCLOTTES,,44.7150066075,0.150419188068,89,,Esclottes,Esclottes,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47090,ESPIENS,47600,ESPIENS,,44.1730548793,0.382704055577,90,,Espiens,Espiens,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47091,ESTILLAC,47310,ESTILLAC,,44.1691562918,0.577334843387,91,,Estillac,Estillac,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47092,FALS,47220,FALS,,44.1052716002,0.689254546889,92,,Fals,Fals,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47093,FARGUES SUR OURBISE,47700,FARGUES SUR OURBISE,,44.2302961715,0.157664110784,93,,Fargues-sur-Ourbise,Fargues-sur-Ourbise,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47094,FAUGUEROLLES,47400,FAUGUEROLLES,,44.4464635606,0.244369660356,94,,Fauguerolles,Fauguerolles,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47095,FAUILLET,47400,FAUILLET,,44.4250095732,0.290885125541,95,,Fauillet,Fauillet,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47096,FERRENSAC,47330,FERRENSAC,,44.6425090951,0.638877238535,96,,Ferrensac,Ferrensac,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47097,FEUGAROLLES,47230,FEUGAROLLES,,44.2178235565,0.360960245348,97,,Feugarolles,Feugarolles,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47098,FIEUX,47600,FIEUX,,44.0999645384,0.42354302505,98,,Fieux,Fieux,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47099,FONGRAVE,47260,FONGRAVE,,44.4050058325,0.529808285762,99,,Fongrave,Fongrave,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47100,FOULAYRONNES,47510,FOULAYRONNES,,44.2533403531,0.636982515974,100,,Foulayronnes,Foulayronnes,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47101,FOURQUES SUR GARONNE,47200,FOURQUES SUR GARONNE,,44.4554149219,0.154536756798,101,,Fourques-sur-Garonne,Fourques-sur-Garonne,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47102,FRANCESCAS,47600,FRANCESCAS,,44.0678806491,0.428251345616,102,,Francescas,Francescas,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47103,FRECHOU,47600,FRECHOU,,44.0838735477,0.334941623613,103,,Fréchou,Fréchou,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47104,FREGIMONT,47360,FREGIMONT,,44.2767583726,0.46313362397,104,,Frégimont,Frégimont,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47105,FRESPECH,47140,FRESPECH,,44.3146511449,0.822551704187,105,,Frespech,Frespech,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47106,FUMEL,47500,FUMEL,,44.5082127529,0.979557344106,106,,Fumel,Fumel,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47107,GALAPIAN,47190,GALAPIAN,,44.2992744201,0.411455620823,107,,Galapian,Galapian,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47108,GAUJAC,47200,GAUJAC,,44.4911956758,0.116008631379,108,,Gaujac,Gaujac,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47109,GAVAUDUN,47150,GAVAUDUN,,44.5686971966,0.893249631816,109,,Gavaudun,Gavaudun,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47110,GONTAUD DE NOGARET,47400,GONTAUD DE NOGARET,,44.4615606607,0.292147658255,110,,Gontaud-de-Nogaret,Gontaud-de-Nogaret,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47111,GRANGES SUR LOT,47260,GRANGES SUR LOT,,44.3674227861,0.470227995175,111,,Granges-sur-Lot,Granges-sur-Lot,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47112,GRATELOUP ST GAYRAND,47400,GRATELOUP ST GAYRAND,,44.4181613232,0.394930271449,112,,Grateloup-Saint-Gayrand,Grateloup-Saint-Gayrand,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47112,GRATELOUP ST GAYRAND,47400,GRATELOUP ST GAYRAND,ST GAYRAND,44.4181613232,0.394930271449,112,,Grateloup-Saint-Gayrand,Grateloup-Saint-Gayrand,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47113,GRAYSSAS,47270,GRAYSSAS,,44.1569683469,0.865584911985,113,,Grayssas,Grayssas,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47114,GREZET CAVAGNAN,47250,GREZET CAVAGNAN,,44.3800998821,0.125863668239,114,,Grézet-Cavagnan,Grézet-Cavagnan,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47115,GUERIN,47250,GUERIN,,44.4120569863,0.0588990507581,115,,Guérin,Guérin,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47117,HAUTEFAGE LA TOUR,47340,HAUTEFAGE LA TOUR,,44.3417684874,0.77342151475,117,,Hautefage-la-Tour,Hautefage-la-Tour,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47118,HAUTESVIGNES,47400,HAUTESVIGNES,,44.4602853307,0.347089526942,118,,Hautesvignes,Hautesvignes,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47119,HOUEILLES,47420,HOUEILLES,,44.1926576164,0.0420744981743,119,,Houeillès,Houeillès,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47120,JUSIX,47180,JUSIX,,44.5392859765,0.0488579062154,120,,Jusix,Jusix,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47121,LABASTIDE CASTEL AMOUROUX,47250,LABASTIDE CASTEL AMOUROUX,,44.3463957829,0.127187947229,121,,Labastide-Castel-Amouroux,Labastide-Castel-Amouroux,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47122,LABRETONIE,47350,LABRETONIE,,44.4898181572,0.372672787599,122,,Labretonie,Labretonie,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47123,LACAPELLE BIRON,47150,LACAPELLE BIRON,,44.6004856905,0.898890688918,123,,Lacapelle-Biron,Lacapelle-Biron,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47124,LACAUSSADE,47150,LACAUSSADE,,44.505417861,0.82335123083,124,,Lacaussade,Lacaussade,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47125,LACEPEDE,47360,LACEPEDE,,44.3190367578,0.481134942394,125,,Lacépède,Lacépède,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47126,LACHAPELLE,47350,LACHAPELLE,,44.5744654566,0.259640336825,126,,Lachapelle,Lachapelle,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47127,LAFITTE SUR LOT,47320,LAFITTE SUR LOT,,44.3535292844,0.43709855772,127,,Lafitte-sur-Lot,Lafitte-sur-Lot,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47128,LAFOX,47240,LAFOX,,44.1653179706,0.699221003684,128,,Lafox,Lafox,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47129,LAGARRIGUE,47190,LAGARRIGUE,,44.2900011672,0.385094294194,129,,Lagarrigue,Lagarrigue,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47130,LAGRUERE,47400,LAGRUERE,,44.3978769193,0.264943641793,130,,Lagruère,Lagruère,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47131,LAGUPIE,47180,LAGUPIE,,44.5681807509,0.110810277516,131,,Lagupie,Lagupie,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47132,LALANDUSSE,47330,LALANDUSSE,,44.6604597636,0.523652763107,132,,Lalandusse,Lalandusse,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47133,LAMONTJOIE,47310,LAMONTJOIE,,44.070163871,0.537584596425,133,,Lamontjoie,Lamontjoie,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47134,LANNES,47170,LANNES,,44.0233573212,0.28577304123,134,,Lannes,Lannes,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47134,LANNES,47170,LANNES,VILLENEUVE DE MEZIN,44.0233573212,0.28577304123,134,,Lannes,Lannes,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47135,LAPARADE,47260,LAPARADE,,44.4080359614,0.439432050771,135,,Laparade,Laparade,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47136,LAPERCHE,47800,LAPERCHE,,44.5457026494,0.414727811733,136,,Laperche,Laperche,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47137,LAPLUME,47310,LAPLUME,,44.1172103179,0.538616105986,137,,Laplume,Laplume,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47138,LAROQUE TIMBAUT,47340,LAROQUE TIMBAUT,,44.2774473869,0.755893948106,138,,Laroque-Timbaut,Laroque-Timbaut,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47139,LASSERRE,47600,LASSERRE,,44.0743983066,0.386505126998,139,,Lasserre,Lasserre,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47140,LAUGNAC,47360,LAUGNAC,,44.299488439,0.608669377753,140,,Laugnac,Laugnac,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47141,LAUSSOU,47150,LAUSSOU,,44.576580457,0.779457385145,141,,Laussou,Laussou,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47142,LAUZUN,47410,LAUZUN,,44.6453836626,0.470249599127,142,,Lauzun,Lauzun,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47143,LAVARDAC,47230,LAVARDAC,,44.182938902,0.301778436575,143,,Lavardac,Lavardac,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47144,LAVERGNE,47800,LAVERGNE,,44.5852993705,0.41382016735,144,,Lavergne,Lavergne,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47145,LAYRAC,47390,LAYRAC,,44.1205847258,0.655048484192,145,,Layrac,Layrac,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47146,LEDAT,47300,LEDAT,,44.4466584765,0.667402522354,146,,Lédat,Lédat,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47147,LEVIGNAC DE GUYENNE,47120,LEVIGNAC DE GUYENNE,,44.6218474674,0.203943341999,147,,Lévignac-de-Guyenne,Lévignac-de-Guyenne,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47148,LEYRITZ MONCASSIN,47700,LEYRITZ MONCASSIN,,44.3329592283,0.176252040355,148,,Leyritz-Moncassin,Leyritz-Moncassin,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47150,LONGUEVILLE,47200,LONGUEVILLE,,44.4626368534,0.219860231765,150,,Longueville,Longueville,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47151,LOUBES BERNAC,47120,LOUBES BERNAC,,44.7338357158,0.311069124069,151,,Loubès-Bernac,Loubès-Bernac,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47152,LOUGRATTE,47290,LOUGRATTE,,44.5903746539,0.624298099523,152,,Lougratte,Lougratte,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47154,LUSIGNAN PETIT,47360,LUSIGNAN PETIT,,44.2620019955,0.517409737452,154,,Lusignan-Petit,Lusignan-Petit,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47155,MADAILLAN,47360,MADAILLAN,,44.276969573,0.581273435521,155,,Madaillan,Madaillan,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47156,MARCELLUS,47200,MARCELLUS,,44.4802329351,0.075926421979,156,,Marcellus,Marcellus,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47157,MARMANDE,47200,MARMANDE,,44.5055176845,0.172174280849,157,,Marmande,Marmande,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47158,MARMONT PACHAS,47220,MARMONT PACHAS,,44.0932080837,0.589921736383,158,,Marmont-Pachas,Marmont-Pachas,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47159,LE MAS D AGENAIS,47430,LE MAS D AGENAIS,,44.3991025317,0.200249161005,159,Le,Mas-d'Agenais,Le Mas-d'Agenais,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47160,MASQUIERES,47370,MASQUIERES,,44.4068801244,1.04211541582,160,,Masquières,Masquières,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47161,MASSELS,47140,MASSELS,,44.3174299116,0.856329698479,161,,Massels,Massels,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47162,MASSOULES,47140,MASSOULES,,44.3404289337,0.870691115892,162,,Massoulès,Massoulès,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47163,MAUVEZIN SUR GUPIE,47200,MAUVEZIN SUR GUPIE,,44.5607815958,0.175677075585,163,,Mauvezin-sur-Gupie,Mauvezin-sur-Gupie,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47164,MAZIERES NARESSE,47210,MAZIERES NARESSE,,44.6558763266,0.707696576814,164,,Mazières-Naresse,Mazières-Naresse,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47165,MEILHAN SUR GARONNE,47180,MEILHAN SUR GARONNE,,44.5066850051,0.0288921045777,165,,Meilhan-sur-Garonne,Meilhan-sur-Garonne,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47167,MEZIN,47170,MEZIN,,44.0600445974,0.267088147392,167,,Mézin,Mézin,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47168,MIRAMONT DE GUYENNE,47800,MIRAMONT DE GUYENNE,,44.5833450029,0.356429133423,168,,Miramont-de-Guyenne,Miramont-de-Guyenne,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47169,MOIRAX,47310,MOIRAX,,44.1399410012,0.608405659619,169,,Moirax,Moirax,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47170,MONBAHUS,47290,MONBAHUS,,44.5387781694,0.525350023126,170,,Monbahus,Monbahus,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47171,MONBALEN,47340,MONBALEN,,44.3137893686,0.73929150738,171,,Monbalen,Monbalen,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47172,MONCAUT,47310,MONCAUT,,44.1440174531,0.496996373302,172,,Moncaut,Moncaut,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47173,MONCLAR,47380,MONCLAR,,44.4444866326,0.520661988159,173,,Monclar,Monclar,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47174,MONCRABEAU,47600,MONCRABEAU,,44.0410471215,0.367666157379,174,,Moncrabeau,Moncrabeau,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47175,MONFLANQUIN,47150,MONFLANQUIN,,44.5202002935,0.758095744834,175,,Monflanquin,Monflanquin,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47176,MONGAILLARD,47230,MONGAILLARD,,44.2096650364,0.295708893452,176,,Mongaillard,Mongaillard,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47177,MONHEURT,47160,MONHEURT,,44.3328995113,0.305811875676,177,,Monheurt,Monheurt,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47178,MONSEGUR,47150,MONSEGUR,,44.4844384296,0.878232304572,178,,Monségur,Monségur,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47179,MONSEMPRON LIBOS,47500,MONSEMPRON LIBOS,,44.5013903927,0.930633157359,179,,Monsempron-Libos,Monsempron-Libos,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47180,MONTAGNAC SUR AUVIGNON,47600,MONTAGNAC SUR AUVIGNON,,44.1657823187,0.457096230702,180,,Montagnac-sur-Auvignon,Montagnac-sur-Auvignon,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47181,MONTAGNAC SUR LEDE,47150,MONTAGNAC SUR LEDE,,44.5376312229,0.847318852557,181,,Montagnac-sur-Lède,Montagnac-sur-Lède,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47182,MONTASTRUC,47380,MONTASTRUC,,44.4946475387,0.518588500828,182,,Montastruc,Montastruc,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47183,MONTAURIOL,47330,MONTAURIOL,,44.6130510503,0.581048526726,183,,Montauriol,Montauriol,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47184,MONTAUT,47210,MONTAUT,,44.6058245899,0.682418964393,184,,Montaut,Montaut,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47185,MONTAYRAL,47500,MONTAYRAL,,44.4683407612,0.983236181699,185,,Montayral,Montayral,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47186,MONTESQUIEU,47130,MONTESQUIEU,,44.2108785728,0.45197105625,186,,Montesquieu,Montesquieu,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47187,MONTETON,47120,MONTETON,,44.613155335,0.251822971507,187,,Monteton,Monteton,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47188,MONTIGNAC DE LAUZUN,47800,MONTIGNAC DE LAUZUN,,44.5694175834,0.472687225332,188,,Montignac-de-Lauzun,Montignac-de-Lauzun,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47189,MONTIGNAC TOUPINERIE,47350,MONTIGNAC TOUPINERIE,,44.5486272611,0.352052970743,189,,Montignac-Toupinerie,Montignac-Toupinerie,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47190,MONTPEZAT,47360,MONTPEZAT,,44.3420285974,0.53448370378,190,,Montpezat,Montpezat,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47191,MONTPOUILLAN,47200,MONTPOUILLAN,,44.4620929897,0.0955335933936,191,,Montpouillan,Montpouillan,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47192,MONVIEL,47290,MONVIEL,,44.5658538876,0.546679303269,192,,Monviel,Monviel,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47193,MOULINET,47290,MOULINET,,44.5439854826,0.583636615495,193,,Moulinet,Moulinet,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47194,MOUSTIER,47800,MOUSTIER,,44.6425608196,0.301343634171,194,,Moustier,Moustier,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47195,NERAC,47600,NERAC,,44.1321004332,0.343358299602,195,,Nérac,Nérac,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47196,NICOLE,47190,NICOLE,,44.3322287429,0.33213854821,196,,Nicole,Nicole,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47197,NOMDIEU,47600,NOMDIEU,,44.0797133373,0.469920586067,197,,Nomdieu,Nomdieu,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47198,PAILLOLES,47440,PAILLOLES,,44.4750480047,0.647784350745,198,,Pailloles,Pailloles,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47199,PARDAILLAN,47120,PARDAILLAN,,44.6681214341,0.268212802868,199,,Pardaillan,Pardaillan,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47200,PARRANQUET,47210,PARRANQUET,,44.6614016738,0.816025305504,200,,Parranquet,Parranquet,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47201,LE PASSAGE,47520,LE PASSAGE,,44.1921120609,0.594677375756,201,Le,Passage,Le Passage,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47202,PAULHIAC,47150,PAULHIAC,,44.5751133813,0.826150001354,202,,Paulhiac,Paulhiac,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47203,PENNE D AGENAIS,47140,PENNE D AGENAIS,,44.3792871944,0.839426839865,203,,Penne-d'Agenais,Penne-d'Agenais,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47204,PEYRIERE,47350,PEYRIERE,,44.5764574409,0.304876574867,204,,Peyrière,Peyrière,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47205,PINDERES,47700,PINDERES,,44.2721306877,0.000151355744744,205,,Pindères,Pindères,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47206,PINEL HAUTERIVE,47380,PINEL HAUTERIVE,,44.4630091453,0.568242882595,206,,Pinel-Hauterive,Pinel-Hauterive,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47206,PINEL HAUTERIVE,47380,PINEL HAUTERIVE,ST PIERRE DE CAUBEL,44.4630091453,0.568242882595,206,,Pinel-Hauterive,Pinel-Hauterive,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47207,POMPIEY,47230,POMPIEY,,44.1976392176,0.207144727468,207,,Pompiey,Pompiey,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47208,POMPOGNE,47420,POMPOGNE,,44.2402054979,0.0580974233061,208,,Pompogne,Pompogne,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47209,PONT DU CASSE,47480,PONT DU CASSE,,44.2355202874,0.675831855662,209,,Pont-du-Casse,Pont-du-Casse,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47210,PORT STE MARIE,47130,PORT STE MARIE,,44.2605042795,0.374453245526,210,,Port-Sainte-Marie,Port-Sainte-Marie,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47211,POUDENAS,47170,POUDENAS,,44.0412174467,0.200335986892,211,,Poudenas,Poudenas,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47212,POUSSIGNAC,47700,POUSSIGNAC,,44.3527772053,0.074662493896,212,,Poussignac,Poussignac,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47213,PRAYSSAS,47360,PRAYSSAS,,44.2940807275,0.514265870524,213,,Prayssas,Prayssas,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47214,PUCH D AGENAIS,47160,PUCH D AGENAIS,,44.3292849237,0.260560126348,214,,Puch-d'Agenais,Puch-d'Agenais,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47215,PUJOLS,47300,PUJOLS,,44.3705410959,0.698420769964,215,,Pujols,Pujols,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47216,PUYMICLAN,47350,PUYMICLAN,,44.5134325386,0.314763378622,216,,Puymiclan,Puymiclan,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47217,PUYMIROL,47270,PUYMIROL,,44.1878890197,0.805532157902,217,,Puymirol,Puymirol,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47218,PUYSSERAMPION,47800,PUYSSERAMPION,,44.599458637,0.319375464096,218,,Puysserampion,Puysserampion,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47219,RAYET,47210,RAYET,,44.6694271783,0.76939339875,219,,Rayet,Rayet,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47220,RAZIMET,47160,RAZIMET,,44.3521277171,0.230708031809,220,,Razimet,Razimet,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47221,REAUP LISSE,47170,REAUP LISSE,,44.1005390753,0.210196910865,221,,Réaup-Lisse,Réaup-Lisse,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47221,REAUP LISSE,47170,REAUP LISSE,LISSE,44.1005390753,0.210196910865,221,,Réaup-Lisse,Réaup-Lisse,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47222,LA REUNION,47700,LA REUNION,,44.2830696786,0.118267808801,222,La,Réunion,La Réunion,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47223,RIVES,47210,RIVES,,44.655524312,0.738165575634,223,,Rives,Rives,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47224,ROMESTAING,47250,ROMESTAING,,44.4174259334,0.0188689173948,224,,Romestaing,Romestaing,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47225,ROQUEFORT,47310,ROQUEFORT,,44.1762971751,0.557230787746,225,,Roquefort,Roquefort,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47226,ROUMAGNE,47800,ROUMAGNE,,44.6252081244,0.342065324394,226,,Roumagne,Roumagne,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47227,RUFFIAC,47700,RUFFIAC,,44.368227915,0.0368814395107,227,,Ruffiac,Ruffiac,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47228,ST ANTOINE DE FICALBA,47340,ST ANTOINE DE FICALBA,,44.3406134125,0.720236260275,228,,Saint-Antoine-de-Ficalba,Saint-Antoine-de-Ficalba,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47229,ST ASTIER,47120,ST ASTIER,,44.7283518416,0.27199901218,229,,Saint-Astier,Saint-Astier,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47230,ST AUBIN,47150,ST AUBIN,,44.4754463347,0.839909036314,230,,Saint-Aubin,Saint-Aubin,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47231,ST AVIT,47350,ST AVIT,,44.5763841705,0.222115057407,231,,Saint-Avit,Saint-Avit,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47232,ST BARTHELEMY D AGENAIS,47350,ST BARTHELEMY D AGENAIS,,44.5242838812,0.370593568879,232,,Saint-Barthélemy-d'Agenais,Saint-Barthélemy-d'Agenais,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47233,STE BAZEILLE,47180,STE BAZEILLE,,44.5304887615,0.103191443155,233,,Sainte-Bazeille,Sainte-Bazeille,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47234,ST CAPRAIS DE LERM,47270,ST CAPRAIS DE LERM,,44.2154136367,0.755929667163,234,,Saint-Caprais-de-Lerm,Saint-Caprais-de-Lerm,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47235,ST COLOMB DE LAUZUN,47410,ST COLOMB DE LAUZUN,,44.6082356833,0.47829320163,235,,Saint-Colomb-de-Lauzun,Saint-Colomb-de-Lauzun,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47236,STE COLOMBE DE DURAS,47120,STE COLOMBE DE DURAS,,44.695891392,0.119530622673,236,,Sainte-Colombe-de-Duras,Sainte-Colombe-de-Duras,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47237,STE COLOMBE DE VILLENEUVE,47300,STE COLOMBE DE VILLENEUVE,,44.3573026111,0.6571276816,237,,Sainte-Colombe-de-Villeneuve,Sainte-Colombe-de-Villeneuve,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47238,STE COLOMBE EN BRUILHOIS,47310,STE COLOMBE EN BRUILHOIS,,44.1868209409,0.516380488388,238,,Sainte-Colombe-en-Bruilhois,Sainte-Colombe-en-Bruilhois,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47239,ST ETIENNE DE FOUGERES,47380,ST ETIENNE DE FOUGERES,,44.413992435,0.559008333546,239,,Saint-Étienne-de-Fougères,Saint-Étienne-de-Fougères,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47240,ST ETIENNE DE VILLEREAL,47210,ST ETIENNE DE VILLEREAL,,44.6120850351,0.762177045599,240,,Saint-Étienne-de-Villeréal,Saint-Étienne-de-Villeréal,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47241,ST EUTROPE DE BORN,47210,ST EUTROPE DE BORN,,44.5780901973,0.708453980649,241,,Saint-Eutrope-de-Born,Saint-Eutrope-de-Born,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47242,ST FRONT SUR LEMANCE,47500,ST FRONT SUR LEMANCE,,44.5647070591,0.998320383054,242,,Saint-Front-sur-Lémance,Saint-Front-sur-Lémance,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47244,STE GEMME MARTAILLAC,47250,STE GEMME MARTAILLAC,,44.3678329817,0.161788672712,244,,Sainte-Gemme-Martaillac,Sainte-Gemme-Martaillac,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47245,ST GERAUD,47120,ST GERAUD,,44.6226768076,0.157164651677,245,,Saint-Géraud,Saint-Géraud,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47246,ST HILAIRE DE LUSIGNAN,47450,ST HILAIRE DE LUSIGNAN,,44.2477536964,0.53261592446,246,,Saint-Hilaire-de-Lusignan,Saint-Hilaire-de-Lusignan,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47247,ST JEAN DE DURAS,47120,ST JEAN DE DURAS,,44.6891665815,0.302703154617,247,,Saint-Jean-de-Duras,Saint-Jean-de-Duras,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47248,ST JEAN DE THURAC,47270,ST JEAN DE THURAC,,44.1570353521,0.740266774824,248,,Saint-Jean-de-Thurac,Saint-Jean-de-Thurac,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47249,ST LAURENT,47130,ST LAURENT,,44.2377251151,0.403349174796,249,,Saint-Laurent,Saint-Laurent,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47250,ST LEGER,47160,ST LEGER,,44.3004219091,0.313824130963,250,,Saint-Léger,Saint-Léger,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47251,ST LEON,47160,ST LEON,,44.2871805336,0.239913996126,251,,Saint-Léon,Saint-Léon,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47252,STE LIVRADE SUR LOT,47110,STE LIVRADE SUR LOT,,44.4058755457,0.599870593015,252,,Sainte-Livrade-sur-Lot,Sainte-Livrade-sur-Lot,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47253,STE MARTHE,47430,STE MARTHE,,44.415315629,0.148396676303,253,,Sainte-Marthe,Sainte-Marthe,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47254,ST MARTIN CURTON,47700,ST MARTIN CURTON,,44.3311233288,-0.0251870734295,254,,Saint-Martin-Curton,Saint-Martin-Curton,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47255,ST MARTIN DE BEAUVILLE,47270,ST MARTIN DE BEAUVILLE,,44.2289840345,0.818300384725,255,,Saint-Martin-de-Beauville,Saint-Martin-de-Beauville,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47256,ST MARTIN DE VILLEREAL,47210,ST MARTIN DE VILLEREAL,,44.6406306447,0.813494311456,256,,Saint-Martin-de-Villeréal,Saint-Martin-de-Villeréal,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47257,ST MARTIN PETIT,47180,ST MARTIN PETIT,,44.5709496293,0.0914080530224,257,,Saint-Martin-Petit,Saint-Martin-Petit,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47258,STE MAURE DE PEYRIAC,47170,STE MAURE DE PEYRIAC,,44.0097358393,0.154355200219,258,,Sainte-Maure-de-Peyriac,Sainte-Maure-de-Peyriac,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47259,ST MAURICE DE LESTAPEL,47290,ST MAURICE DE LESTAPEL,,44.5819605641,0.573856388093,259,,Saint-Maurice-de-Lestapel,Saint-Maurice-de-Lestapel,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47260,ST MAURIN,47270,ST MAURIN,,44.2158468038,0.893637045733,260,,Saint-Maurin,Saint-Maurin,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47262,ST NICOLAS DE LA BALERME,47220,ST NICOLAS DE LA BALERME,,44.1311576443,0.756519172378,262,,Saint-Nicolas-de-la-Balerme,Saint-Nicolas-de-la-Balerme,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47263,ST PARDOUX DU BREUIL,47200,ST PARDOUX DU BREUIL,,44.4713724451,0.199107066412,263,,Saint-Pardoux-du-Breuil,Saint-Pardoux-du-Breuil,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47264,ST PARDOUX ISAAC,47800,ST PARDOUX ISAAC,,44.6122576161,0.378163344287,264,,Saint-Pardoux-Isaac,Saint-Pardoux-Isaac,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47265,ST PASTOUR,47290,ST PASTOUR,,44.48597732,0.602513041284,265,,Saint-Pastour,Saint-Pastour,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47266,ST PE ST SIMON,47170,ST PE ST SIMON,,44.0026069036,0.0935459187354,266,,Saint-Pé-Saint-Simon,Saint-Pé-Saint-Simon,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47267,ST PIERRE DE BUZET,47160,ST PIERRE DE BUZET,,44.258213795,0.270170927246,267,,Saint-Pierre-de-Buzet,Saint-Pierre-de-Buzet,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47269,ST PIERRE DE CLAIRAC,47270,ST PIERRE DE CLAIRAC,,44.1802043993,0.752007994419,269,,Saint-Pierre-de-Clairac,Saint-Pierre-de-Clairac,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47271,ST PIERRE SUR DROPT,47120,ST PIERRE SUR DROPT,,44.6486668035,0.206503642426,271,,Saint-Pierre-sur-Dropt,Saint-Pierre-sur-Dropt,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47272,ST QUENTIN DU DROPT,47330,ST QUENTIN DU DROPT,,44.6813277192,0.599100664337,272,,Saint-Quentin-du-Dropt,Saint-Quentin-du-Dropt,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47273,ST ROBERT,47340,ST ROBERT,,44.2501427402,0.78976956588,273,,Saint-Robert,Saint-Robert,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47274,ST ROMAIN LE NOBLE,47270,ST ROMAIN LE NOBLE,,44.1539136424,0.776879635948,274,,Saint-Romain-le-Noble,Saint-Romain-le-Noble,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47275,ST SALVY,47360,ST SALVY,,44.300872479,0.449936308844,275,,Saint-Salvy,Saint-Salvy,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47276,ST SARDOS,47360,ST SARDOS,,44.3387503829,0.489881579397,276,,Saint-Sardos,Saint-Sardos,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47277,ST SAUVEUR DE MEILHAN,47180,ST SAUVEUR DE MEILHAN,,44.4853614152,-0.00584375673838,277,,Saint-Sauveur-de-Meilhan,Saint-Sauveur-de-Meilhan,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47278,ST SERNIN,47120,ST SERNIN,,44.7063934803,0.238983913386,278,,Saint-Sernin,Saint-Sernin,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47279,ST SIXTE,47220,ST SIXTE,,44.1281007992,0.783277975172,279,,Saint-Sixte,Saint-Sixte,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47280,ST SYLVESTRE SUR LOT,47140,ST SYLVESTRE SUR LOT,,44.4179177939,0.808784719613,280,,Saint-Sylvestre-sur-Lot,Saint-Sylvestre-sur-Lot,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47281,ST URCISSE,47270,ST URCISSE,,44.1644001524,0.826832844696,281,,Saint-Urcisse,Saint-Urcisse,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47282,ST VINCENT DE LAMONTJOIE,47310,ST VINCENT DE LAMONTJOIE,,44.0929123328,0.499718250468,282,,Saint-Vincent-de-Lamontjoie,Saint-Vincent-de-Lamontjoie,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47283,ST VITE,47500,ST VITE,,44.4672729873,0.933693165235,283,,Saint-Vite,Saint-Vite,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47284,SALLES,47150,SALLES,,44.5297987522,0.890077944745,284,,Salles,Salles,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47285,SAMAZAN,47250,SAMAZAN,,44.4348291944,0.109706411573,285,,Samazan,Samazan,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47286,SAUMEJAN,47420,SAUMEJAN,,44.2320209567,-0.0209875332561,286,,Sauméjan,Sauméjan,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47287,SAUMONT,47600,SAUMONT,,44.1272954616,0.455699983638,287,,Saumont,Saumont,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47288,SAUVAGNAS,47340,SAUVAGNAS,,44.2464985095,0.749862858533,288,,Sauvagnas,Sauvagnas,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47289,LA SAUVETAT DE SAVERES,47270,LA SAUVETAT DE SAVERES,,44.2306316088,0.795887481537,289,La,Sauvetat-de-Savères,La Sauvetat-de-Savères,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47290,LA SAUVETAT DU DROPT,47800,LA SAUVETAT DU DROPT,,44.6497758491,0.331517448954,290,La,Sauvetat-du-Dropt,La Sauvetat-du-Dropt,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47291,LA SAUVETAT SUR LEDE,47150,LA SAUVETAT SUR LEDE,,44.4818655678,0.716683447873,291,La,Sauvetat-sur-Lède,La Sauvetat-sur-Lède,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47292,SAUVETERRE LA LEMANCE,47500,SAUVETERRE LA LEMANCE,,44.5874333431,1.03791242819,292,,Sauveterre-la-Lémance,Sauveterre-la-Lémance,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47293,SAUVETERRE ST DENIS,47220,SAUVETERRE ST DENIS,,44.1498250979,0.700092181215,293,,Sauveterre-Saint-Denis,Sauveterre-Saint-Denis,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47294,SAVIGNAC DE DURAS,47120,SAVIGNAC DE DURAS,,44.7173874905,0.184068178944,294,,Savignac-de-Duras,Savignac-de-Duras,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47295,SAVIGNAC SUR LEYZE,47150,SAVIGNAC SUR LEYZE,,44.4830217019,0.792652792523,295,,Savignac-sur-Leyze,Savignac-sur-Leyze,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47296,SEGALAS,47410,SEGALAS,,44.5896223889,0.531957524154,296,,Ségalas,Ségalas,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47297,SEMBAS,47360,SEMBAS,,44.3229002449,0.645981767236,297,,Sembas,Sembas,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47298,SENESTIS,47430,SENESTIS,,44.4216441879,0.237961024607,298,,Sénestis,Sénestis,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47299,SERIGNAC PEBOUDOU,47410,SERIGNAC PEBOUDOU,,44.6157569967,0.53170226693,299,,Sérignac-Péboudou,Sérignac-Péboudou,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47300,SERIGNAC SUR GARONNE,47310,SERIGNAC SUR GARONNE,,44.2156168834,0.494137756364,300,,Sérignac-sur-Garonne,Sérignac-sur-Garonne,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47301,SEYCHES,47350,SEYCHES,,44.5491210196,0.292236597438,301,,Seyches,Seyches,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47302,SOS,47170,SOS,,44.0568059441,0.125536637089,302,,Sos,Sos,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47302,SOS,47170,SOS,GUEYZE,44.0568059441,0.125536637089,302,,Sos,Sos,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47302,SOS,47170,SOS,MEYLAN,44.0568059441,0.125536637089,302,,Sos,Sos,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47303,SOUMENSAC,47120,SOUMENSAC,,44.6884449209,0.336573282726,303,,Soumensac,Soumensac,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47304,TAILLEBOURG,47200,TAILLEBOURG,,44.4466946358,0.203133379028,304,,Taillebourg,Taillebourg,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47305,TAYRAC,47270,TAYRAC,,44.2060027013,0.836072075558,305,,Tayrac,Tayrac,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47306,LE TEMPLE SUR LOT,47110,LE TEMPLE SUR LOT,,44.3754139489,0.525660139927,306,Le,Temple-sur-Lot,Le Temple-sur-Lot,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47307,THEZAC,47370,THEZAC,,44.4336390624,1.01578855539,307,,Thézac,Thézac,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47308,THOUARS SUR GARONNE,47230,THOUARS SUR GARONNE,,44.2534794236,0.330942072097,308,,Thouars-sur-Garonne,Thouars-sur-Garonne,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47309,TOMBEBOEUF,47380,TOMBEBOEUF,,44.5192333821,0.452312617124,309,,Tombebœuf,Tombebœuf,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47310,TONNEINS,47400,TONNEINS,,44.3836784997,0.324905750145,310,,Tonneins,Tonneins,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47311,TOURLIAC,47210,TOURLIAC,,44.6842108376,0.806386025708,311,,Tourliac,Tourliac,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47312,TOURNON D AGENAIS,47370,TOURNON D AGENAIS,,44.3925100376,0.996018809285,312,,Tournon-d'Agenais,Tournon-d'Agenais,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47313,TOURTRES,47380,TOURTRES,,44.5103644427,0.418309119769,313,,Tourtrès,Tourtrès,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47314,TREMONS,47140,TREMONS,,44.4176253146,0.889471727423,314,,Trémons,Trémons,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47315,TRENTELS,47140,TRENTELS,,44.4456964361,0.863931953648,315,,Trentels,Trentels,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47316,VARES,47400,VARES,,44.4325501757,0.356074701312,316,,Varès,Varès,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47317,VERTEUIL D AGENAIS,47260,VERTEUIL D AGENAIS,,44.4685862849,0.404058956078,317,,Verteuil-d'Agenais,Verteuil-d'Agenais,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47318,VIANNE,47230,VIANNE,,44.2122017906,0.322783861284,318,,Vianne,Vianne,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47319,VILLEBRAMAR,47380,VILLEBRAMAR,,44.5252006493,0.476034019359,319,,Villebramar,Villebramar,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47320,VILLEFRANCHE DU QUEYRAN,47160,VILLEFRANCHE DU QUEYRAN,,44.3035817658,0.212075905878,320,,Villefranche-du-Queyran,Villefranche-du-Queyran,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47321,VILLENEUVE DE DURAS,47120,VILLENEUVE DE DURAS,,44.7431410072,0.236705343695,321,,Villeneuve-de-Duras,Villeneuve-de-Duras,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47323,VILLENEUVE SUR LOT,47300,VILLENEUVE SUR LOT,,44.4251092866,0.742526167632,323,,Villeneuve-sur-Lot,Villeneuve-sur-Lot,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47324,VILLEREAL,47210,VILLEREAL,,44.6330682255,0.760362133124,324,,Villeréal,Villeréal,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47325,VILLETON,47400,VILLETON,,44.3713219264,0.278872642453,325,,Villeton,Villeton,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47326,VIRAZEIL,47200,VIRAZEIL,,44.5118708784,0.238920831275,326,,Virazeil,Virazeil,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47327,XAINTRAILLES,47230,XAINTRAILLES,,44.207500641,0.256573989587,327,,Xaintrailles,Xaintrailles,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
47328,ST GEORGES,47370,ST GEORGES,,44.4415435954,0.927467853868,328,,Saint-Georges,Saint-Georges,47,Lot-et-Garonne,75,Nouvelle-Aquitaine
48001,ALBARET LE COMTAL,48310,ALBARET LE COMTAL,,44.8676627998,3.14383678125,1,,Albaret-le-Comtal,Albaret-le-Comtal,48,Lozère,76,Occitanie
48002,ALBARET STE MARIE,48200,ALBARET STE MARIE,,44.8864422814,3.25064355451,2,,Albaret-Sainte-Marie,Albaret-Sainte-Marie,48,Lozère,76,Occitanie
48002,ALBARET STE MARIE,48200,ALBARET STE MARIE,LA GARDE,44.8864422814,3.25064355451,2,,Albaret-Sainte-Marie,Albaret-Sainte-Marie,48,Lozère,76,Occitanie
48003,ALLENC,48190,ALLENC,,44.5522761044,3.67650603753,3,,Allenc,Allenc,48,Lozère,76,Occitanie
48004,ALTIER,48800,ALTIER,,44.459680191,3.85680054017,4,,Altier,Altier,48,Lozère,76,Occitanie
48005,ANTRENAS,48100,ANTRENAS,,44.5881108325,3.25393256642,5,,Antrenas,Antrenas,48,Lozère,76,Occitanie
48007,ARZENC D APCHER,48310,ARZENC D APCHER,,44.8446740951,3.11215598428,7,,Arzenc-d'Apcher,Arzenc-d'Apcher,48,Lozère,76,Occitanie
48008,ARZENC DE RANDON,48170,ARZENC DE RANDON,,44.6534793285,3.6149096338,8,,Arzenc-de-Randon,Arzenc-de-Randon,48,Lozère,76,Occitanie
48009,AUMONT AUBRAC,48130,AUMONT AUBRAC,,44.7233900467,3.28132614741,9,,Peyre en Aubrac,Peyre en Aubrac,48,Lozère,76,Occitanie
48010,AUROUX,48600,AUROUX,,44.7574886567,3.72694597224,10,,Auroux,Auroux,48,Lozère,76,Occitanie
48012,LES MONTS VERTS,48200,LES MONTS VERTS,,44.8489134822,3.22180661807,12,Les,Monts-Verts,Les Monts-Verts,48,Lozère,76,Occitanie
48012,LES MONTS VERTS,48200,LES MONTS VERTS,ARCOMIE,44.8489134822,3.22180661807,12,Les,Monts-Verts,Les Monts-Verts,48,Lozère,76,Occitanie
48012,LES MONTS VERTS,48200,LES MONTS VERTS,BERC,44.8489134822,3.22180661807,12,Les,Monts-Verts,Les Monts-Verts,48,Lozère,76,Occitanie
48013,BADAROUX,48000,BADAROUX,,44.5399034165,3.55404283598,13,,Badaroux,Badaroux,48,Lozère,76,Occitanie
48014,BAGNOLS LES BAINS,48190,BAGNOLS LES BAINS,,44.5110337689,3.66278959249,14,,Bagnols-les-Bains,Bagnols-les-Bains,48,Lozère,76,Occitanie
48015,PIED DE BORNE,48800,PIED DE BORNE,,44.4805501734,3.9685848144,15,,Pied-de-Borne,Pied-de-Borne,48,Lozère,76,Occitanie
48016,BALSIEGES,48000,BALSIEGES,,44.4754747624,3.44126913972,16,,Balsièges,Balsièges,48,Lozère,76,Occitanie
48017,BANASSAC CANILHAC,48500,BANASSAC CANILHAC,,44.4325411696,3.17908745544,17,,Banassac-Canilhac,Banassac-Canilhac,48,Lozère,76,Occitanie
48017,BANASSAC CANILHAC,48500,BANASSAC CANILHAC,CANILHAC,44.4325411696,3.17908745544,17,,Banassac-Canilhac,Banassac-Canilhac,48,Lozère,76,Occitanie
48018,BARJAC,48000,BARJAC,,44.5220189708,3.40925330342,18,,Barjac,Barjac,48,Lozère,76,Occitanie
48019,BARRE DES CEVENNES,48400,BARRE DES CEVENNES,,44.2534596387,3.67089692402,19,,Barre-des-Cévennes,Barre-des-Cévennes,48,Lozère,76,Occitanie
48020,BASSURELS,48400,BASSURELS,,44.1576500165,3.60529692869,20,,Bassurels,Bassurels,48,Lozère,76,Occitanie
48021,LA BASTIDE PUYLAURENT,48250,LA BASTIDE PUYLAURENT,,44.5655559984,3.88803843314,21,La,Bastide-Puylaurent,La Bastide-Puylaurent,48,Lozère,76,Occitanie
48023,BELVEZET,48170,BELVEZET,,44.5556527766,3.74112796046,23,,Belvezet,Belvezet,48,Lozère,76,Occitanie
48025,LES BESSONS,48200,LES BESSONS,,44.77338024,3.24344025739,25,Les,Bessons,Les Bessons,48,Lozère,76,Occitanie
48026,BLAVIGNAC,48200,BLAVIGNAC,,44.8710692879,3.28412988196,26,,Blavignac,Blavignac,48,Lozère,76,Occitanie
48027,LE BLEYMARD,48190,LE BLEYMARD,,44.5107452501,3.74294007436,27,,Mont Lozère et Goulet,Mont Lozère et Goulet,48,Lozère,76,Occitanie
48028,LES BONDONS,48400,LES BONDONS,,44.3983249079,3.63428329173,28,Les,Bondons,Les Bondons,48,Lozère,76,Occitanie
48029,LE BORN,48000,LE BORN,,44.587957569,3.55902737103,29,Le,Born,Le Born,48,Lozère,76,Occitanie
48030,BRENOUX,48000,BRENOUX,,44.4873961112,3.53320153981,30,,Brenoux,Brenoux,48,Lozère,76,Occitanie
48031,BRION,48310,BRION,,44.7498734557,3.07854846372,31,,Brion,Brion,48,Lozère,76,Occitanie
48031,BRION,48310,BRION,LA CHALDETTE,44.7498734557,3.07854846372,31,,Brion,Brion,48,Lozère,76,Occitanie
48032,LE BUISSON,48100,LE BUISSON,,44.6289447122,3.22357816109,32,Le,Buisson,Le Buisson,48,Lozère,76,Occitanie
48034,LA CANOURGUE,48500,LA CANOURGUE,,44.4193711769,3.25711827627,34,La,Canourgue,La Canourgue,48,Lozère,76,Occitanie
48034,LA CANOURGUE,48500,LA CANOURGUE,AUXILLAC,44.4193711769,3.25711827627,34,La,Canourgue,La Canourgue,48,Lozère,76,Occitanie
48034,LA CANOURGUE,48500,LA CANOURGUE,LA CAPELLE,44.4193711769,3.25711827627,34,La,Canourgue,La Canourgue,48,Lozère,76,Occitanie
48034,LA CANOURGUE,48500,LA CANOURGUE,MONTJEZIEU,44.4193711769,3.25711827627,34,La,Canourgue,La Canourgue,48,Lozère,76,Occitanie
48036,CASSAGNAS,48400,CASSAGNAS,,44.2769981093,3.73641506874,36,,Cassagnas,Cassagnas,48,Lozère,76,Occitanie
48037,CHADENET,48190,CHADENET,,44.5118082071,3.64019508936,37,,Chadenet,Chadenet,48,Lozère,76,Occitanie
48038,CHAMBON LE CHATEAU,48600,CHAMBON LE CHATEAU,,44.8556410691,3.65464522563,38,,Chambon-le-Château,Chambon-le-Château,48,Lozère,76,Occitanie
48039,CHANAC,48230,CHANAC,,44.4502200567,3.34457977647,39,,Chanac,Chanac,48,Lozère,76,Occitanie
48039,CHANAC,48230,CHANAC,LE VILLARD,44.4502200567,3.34457977647,39,,Chanac,Chanac,48,Lozère,76,Occitanie
48040,CHASSERADES,48250,CHASSERADES,,44.5519524888,3.82030750728,40,,Chasseradès,Chasseradès,48,Lozère,76,Occitanie
48041,CHASTANIER,48300,CHASTANIER,,44.722639702,3.75305127719,41,,Chastanier,Chastanier,48,Lozère,76,Occitanie
48042,CHASTEL NOUVEL,48000,CHASTEL NOUVEL,,44.5707532071,3.49280598377,42,,Chastel-Nouvel,Chastel-Nouvel,48,Lozère,76,Occitanie
48043,CHATEAUNEUF DE RANDON,48170,CHATEAUNEUF DE RANDON,,44.6479862625,3.68590972615,43,,Châteauneuf-de-Randon,Châteauneuf-de-Randon,48,Lozère,76,Occitanie
48044,CHAUCHAILLES,48310,CHAUCHAILLES,,44.7932282466,3.07355645186,44,,Chauchailles,Chauchailles,48,Lozère,76,Occitanie
48045,CHAUDEYRAC,48170,CHAUDEYRAC,,44.6417519852,3.75085560939,45,,Chaudeyrac,Chaudeyrac,48,Lozère,76,Occitanie
48046,CHAULHAC,48140,CHAULHAC,,44.9200127514,3.26546644463,46,,Chaulhac,Chaulhac,48,Lozère,76,Occitanie
48047,LA CHAZE DE PEYRE,48130,LA CHAZE DE PEYRE,,44.7026908168,3.24188242885,47,La,Chaze-de-Peyre,La Chaze-de-Peyre,48,Lozère,76,Occitanie
48048,CHEYLARD L EVEQUE,48300,CHEYLARD L EVEQUE,,44.6315294707,3.80974114923,48,,Cheylard-l'Évêque,Cheylard-l'Évêque,48,Lozère,76,Occitanie
48050,BEDOUES COCURES,48400,BEDOUES COCURES,,44.3539456684,3.61955972065,50,,Bédouès-Cocurès,Bédouès-Cocurès,48,Lozère,76,Occitanie
48050,BEDOUES COCURES,48400,BEDOUES COCURES,BEDOUES,44.3539456684,3.61955972065,50,,Bédouès-Cocurès,Bédouès-Cocurès,48,Lozère,76,Occitanie
48051,LE COLLET DE DEZE,48160,LE COLLET DE DEZE,,44.2554480808,3.92521509971,51,Le,Collet-de-Dèze,Le Collet-de-Dèze,48,Lozère,76,Occitanie
48053,CUBIERES,48190,CUBIERES,,44.475323724,3.7868022391,53,,Cubières,Cubières,48,Lozère,76,Occitanie
48054,CUBIERETTES,48190,CUBIERETTES,,44.4437323687,3.78750024039,54,,Cubiérettes,Cubiérettes,48,Lozère,76,Occitanie
48055,CULTURES,48230,CULTURES,,44.4911701749,3.38102042197,55,,Cultures,Cultures,48,Lozère,76,Occitanie
48056,ESCLANEDES,48230,ESCLANEDES,,44.4813587557,3.36743197603,56,,Esclanèdes,Esclanèdes,48,Lozère,76,Occitanie
48057,ESTABLES,48700,ESTABLES,,44.6759595262,3.50876760941,57,,Estables,Estables,48,Lozère,76,Occitanie
48058,LA FAGE MONTIVERNOUX,48310,LA FAGE MONTIVERNOUX,,44.7472604379,3.15153193685,58,La,Fage-Montivernoux,La Fage-Montivernoux,48,Lozère,76,Occitanie
48059,LA FAGE ST JULIEN,48200,LA FAGE ST JULIEN,,44.7994035281,3.20844931182,59,La,Fage-Saint-Julien,La Fage-Saint-Julien,48,Lozère,76,Occitanie
48060,FAU DE PEYRE,48130,FAU DE PEYRE,,44.7389442204,3.21015921593,60,,Fau-de-Peyre,Fau-de-Peyre,48,Lozère,76,Occitanie
48061,FLORAC TROIS RIVIERES,48400,FLORAC TROIS RIVIERES,,44.3162079295,3.58273527435,61,,Florac Trois Rivières,Florac Trois Rivières,48,Lozère,76,Occitanie
48061,FLORAC TROIS RIVIERES,48400,FLORAC TROIS RIVIERES,LA SALLE PRUNET,44.3162079295,3.58273527435,61,,Florac Trois Rivières,Florac Trois Rivières,48,Lozère,76,Occitanie
48063,FONTANS,48700,FONTANS,,44.7447322312,3.37636258527,63,,Fontans,Fontans,48,Lozère,76,Occitanie
48064,FOURNELS,48310,FOURNELS,,44.8115219718,3.12932702251,64,,Fournels,Fournels,48,Lozère,76,Occitanie
48065,FRAISSINET DE FOURQUES,48400,FRAISSINET DE FOURQUES,,44.2089087169,3.53134006271,65,,Fraissinet-de-Fourques,Fraissinet-de-Fourques,48,Lozère,76,Occitanie
48067,GABRIAC,48110,GABRIAC,,44.1784497946,3.71130756296,67,,Gabriac,Gabriac,48,Lozère,76,Occitanie
48068,GABRIAS,48100,GABRIAS,,44.5531806601,3.37940446458,68,,Gabrias,Gabrias,48,Lozère,76,Occitanie
48069,GATUZIERES,48150,GATUZIERES,,44.1950868621,3.49763818762,69,,Gatuzières,Gatuzières,48,Lozère,76,Occitanie
48070,GRANDRIEU,48600,GRANDRIEU,,44.7775011056,3.64068574736,70,,Grandrieu,Grandrieu,48,Lozère,76,Occitanie
48071,GRANDVALS,48260,GRANDVALS,,44.7313582398,3.05208837669,71,,Grandvals,Grandvals,48,Lozère,76,Occitanie
48072,GREZES,48100,GREZES,,44.5156264154,3.34372091162,72,,Grèzes,Grèzes,48,Lozère,76,Occitanie
48073,LES HERMAUX,48340,LES HERMAUX,,44.5235838442,3.13204161699,73,Les,Hermaux,Les Hermaux,48,Lozère,76,Occitanie
48074,HURES LA PARADE,48150,HURES LA PARADE,,44.2477855743,3.38671686597,74,,Hures-la-Parade,Hures-la-Parade,48,Lozère,76,Occitanie
48075,ISPAGNAC,48320,ISPAGNAC,,44.3984529023,3.53146290562,75,,Ispagnac,Ispagnac,48,Lozère,76,Occitanie
48076,JAVOLS,48130,JAVOLS,,44.6928904154,3.33324794395,76,,Javols,Javols,48,Lozère,76,Occitanie
48077,JULIANGES,48140,JULIANGES,,44.9365351295,3.31930842194,77,,Julianges,Julianges,48,Lozère,76,Occitanie
48078,LACHAMP,48100,LACHAMP,,44.608308126,3.37038501649,78,,Lachamp,Lachamp,48,Lozère,76,Occitanie
48079,LAJO,48120,LAJO,,44.8288847769,3.439863707,79,,Lajo,Lajo,48,Lozère,76,Occitanie
48080,LANGOGNE,48300,LANGOGNE,,44.7237405653,3.83363110341,80,,Langogne,Langogne,48,Lozère,76,Occitanie
48081,LANUEJOLS,48000,LANUEJOLS,,44.4904680879,3.58785456631,81,,Lanuéjols,Lanuéjols,48,Lozère,76,Occitanie
48082,LAUBERT,48170,LAUBERT,,44.5972040067,3.64498618428,82,,Laubert,Laubert,48,Lozère,76,Occitanie
48083,LES LAUBIES,48700,LES LAUBIES,,44.7021823175,3.44235183055,83,Les,Laubies,Les Laubies,48,Lozère,76,Occitanie
48084,LAVAL ATGER,48600,LAVAL ATGER,,44.8022090372,3.70257877423,84,,Laval-Atger,Laval-Atger,48,Lozère,76,Occitanie
48085,LAVAL DU TARN,48500,LAVAL DU TARN,,44.3736109677,3.34460539469,85,,Laval-du-Tarn,Laval-du-Tarn,48,Lozère,76,Occitanie
48086,LUC,48250,LUC,,44.6366875851,3.86550888829,86,,Luc,Luc,48,Lozère,76,Occitanie
48087,MALBOUZON,48270,MALBOUZON,,44.7070956214,3.13030307295,87,,Prinsuéjols-Malbouzon,Prinsuéjols-Malbouzon,48,Lozère,76,Occitanie
48088,LA MALENE,48210,LA MALENE,,44.3020788352,3.31365173313,88,La,Malène,La Malène,48,Lozère,76,Occitanie
48089,LE MALZIEU FORAIN,48140,LE MALZIEU FORAIN,,44.8695737618,3.38991258654,89,Le,Malzieu-Forain,Le Malzieu-Forain,48,Lozère,76,Occitanie
48090,LE MALZIEU VILLE,48140,LE MALZIEU VILLE,,44.8630758811,3.33643761858,90,Le,Malzieu-Ville,Le Malzieu-Ville,48,Lozère,76,Occitanie
48091,MARCHASTEL,48260,MARCHASTEL,,44.6417575332,3.10788054889,91,,Marchastel,Marchastel,48,Lozère,76,Occitanie
48092,MARVEJOLS,48100,MARVEJOLS,,44.5581181858,3.28780886743,92,,Marvejols,Marvejols,48,Lozère,76,Occitanie
48093,MAS D ORCIERES,48190,MAS D ORCIERES,,44.4558840853,3.71168776074,93,,Mas-d'Orcières,Mas-d'Orcières,48,Lozère,76,Occitanie
48094,LE MASSEGROS,48500,LE MASSEGROS,,44.2937484234,3.15910137508,94,,Massegros Causses Gorges,Massegros Causses Gorges,48,Lozère,76,Occitanie
48095,MENDE,48000,MENDE,,44.5294508592,3.48086881176,95,,Mende,Mende,48,Lozère,76,Occitanie
48096,MEYRUEIS,48150,MEYRUEIS,,44.1719020208,3.4516638341,96,,Meyrueis,Meyrueis,48,Lozère,76,Occitanie
48097,MOISSAC VALLEE FRANCAISE,48110,MOISSAC VALLEE FRANCAISE,,44.157860146,3.79127521457,97,,Moissac-Vallée-Française,Moissac-Vallée-Française,48,Lozère,76,Occitanie
48098,MOLEZON,48110,MOLEZON,,44.2188601955,3.69509468816,98,,Molezon,Molezon,48,Lozère,76,Occitanie
48099,BOURGS SUR COLAGNE,48100,BOURGS SUR COLAGNE,CHIRAC,44.5109335104,3.22653031553,99,,Bourgs sur Colagne,Bourgs sur Colagne,48,Lozère,76,Occitanie
48099,BOURGS SUR COLAGNE,48100,BOURGS SUR COLAGNE,LE MONASTIER PIN MORIES,44.5109335104,3.22653031553,99,,Bourgs sur Colagne,Bourgs sur Colagne,48,Lozère,76,Occitanie
48099,BOURGS SUR COLAGNE,48100,BOURGS SUR COLAGNE,PIN MORIES,44.5109335104,3.22653031553,99,,Bourgs sur Colagne,Bourgs sur Colagne,48,Lozère,76,Occitanie
48100,MONTBEL,48170,MONTBEL,,44.5963342329,3.71249068186,100,,Montbel,Montbel,48,Lozère,76,Occitanie
48101,MONTBRUN,48210,MONTBRUN,,44.3167366431,3.50947194935,101,,Montbrun,Montbrun,48,Lozère,76,Occitanie
48103,MONTRODAT,48100,MONTRODAT,,44.5574972812,3.32949888703,103,,Montrodat,Montrodat,48,Lozère,76,Occitanie
48104,NASBINALS,48260,NASBINALS,,44.6444140961,3.04893734063,104,,Nasbinals,Nasbinals,48,Lozère,76,Occitanie
48105,NAUSSAC FONTANES,48300,NAUSSAC FONTANES,,44.7573739721,3.8000356543,105,,Naussac-Fontanes,Naussac-Fontanes,48,Lozère,76,Occitanie
48105,NAUSSAC FONTANES,48300,NAUSSAC FONTANES,FONTANES,44.7573739721,3.8000356543,105,,Naussac-Fontanes,Naussac-Fontanes,48,Lozère,76,Occitanie
48106,NOALHAC,48310,NOALHAC,,44.7988546205,3.11483101458,106,,Noalhac,Noalhac,48,Lozère,76,Occitanie
48107,PALHERS,48100,PALHERS,,44.5204094847,3.30780766253,107,,Palhers,Palhers,48,Lozère,76,Occitanie
48108,LA PANOUSE,48600,LA PANOUSE,,44.7368256595,3.58794717174,108,La,Panouse,La Panouse,48,Lozère,76,Occitanie
48110,PAULHAC EN MARGERIDE,48140,PAULHAC EN MARGERIDE,,44.9435917668,3.38544485053,110,,Paulhac-en-Margeride,Paulhac-en-Margeride,48,Lozère,76,Occitanie
48111,PELOUSE,48000,PELOUSE,,44.5725282842,3.60664798147,111,,Pelouse,Pelouse,48,Lozère,76,Occitanie
48112,PIERREFICHE,48300,PIERREFICHE,,44.685286546,3.73234366061,112,,Pierrefiche,Pierrefiche,48,Lozère,76,Occitanie
48115,LE POMPIDOU,48110,LE POMPIDOU,,44.2034836971,3.65983483372,115,Le,Pompidou,Le Pompidou,48,Lozère,76,Occitanie
48116,PONT DE MONTVERT SUD MONT LOZERE,48220,PONT DE MONTVERT SUD MONT LOZERE,FRAISSINET DE LOZERE,44.3742853816,3.76917997857,116,,Pont de Montvert - Sud Mont Lozère,Pont de Montvert - Sud Mont Lozère,48,Lozère,76,Occitanie
48116,PONT DE MONTVERT SUD MONT LOZERE,48220,PONT DE MONTVERT SUD MONT LOZERE,LE PONT DE MONTVERT,44.3742853816,3.76917997857,116,,Pont de Montvert - Sud Mont Lozère,Pont de Montvert - Sud Mont Lozère,48,Lozère,76,Occitanie
48116,PONT DE MONTVERT SUD MONT LOZERE,48220,PONT DE MONTVERT SUD MONT LOZERE,ST MAURICE DE VENTALON,44.3742853816,3.76917997857,116,,Pont de Montvert - Sud Mont Lozère,Pont de Montvert - Sud Mont Lozère,48,Lozère,76,Occitanie
48117,POURCHARESSES,48800,POURCHARESSES,,44.434038613,3.89869744048,117,,Pourcharesses,Pourcharesses,48,Lozère,76,Occitanie
48119,PREVENCHERES,48800,PREVENCHERES,,44.5189016289,3.92049351127,119,,Prévenchères,Prévenchères,48,Lozère,76,Occitanie
48120,PRINSUEJOLS,48100,PRINSUEJOLS,,44.6685331995,3.1689820351,120,,Prinsuéjols,Prinsuéjols,48,Lozère,76,Occitanie
48121,PRUNIERES,48200,PRUNIERES,,44.8174063677,3.3349356186,121,,Prunières,Prunières,48,Lozère,76,Occitanie
48122,QUEZAC,48320,QUEZAC,,44.3551038651,3.51863359908,122,,Quézac,Quézac,48,Lozère,76,Occitanie
48122,QUEZAC,48320,QUEZAC,BLAJOUX,44.3551038651,3.51863359908,122,,Quézac,Quézac,48,Lozère,76,Occitanie
48123,RECOULES D AUBRAC,48260,RECOULES D AUBRAC,,44.6928193837,3.03731293861,123,,Recoules-d'Aubrac,Recoules-d'Aubrac,48,Lozère,76,Occitanie
48124,RECOULES DE FUMAS,48100,RECOULES DE FUMAS,,44.6318260486,3.3333350494,124,,Recoules-de-Fumas,Recoules-de-Fumas,48,Lozère,76,Occitanie
48125,LE RECOUX,48500,LE RECOUX,,44.3437335898,3.16392711408,125,Le,Recoux,Le Recoux,48,Lozère,76,Occitanie
48126,RIBENNES,48700,RIBENNES,,44.6498972232,3.386003003,126,,Ribennes,Ribennes,48,Lozère,76,Occitanie
48127,RIEUTORT DE RANDON,48700,RIEUTORT DE RANDON,,44.6238353175,3.4886504946,127,,Rieutort-de-Randon,Rieutort-de-Randon,48,Lozère,76,Occitanie
48128,RIMEIZE,48200,RIMEIZE,,44.764646204,3.31719285575,128,,Rimeize,Rimeize,48,Lozère,76,Occitanie
48129,ROCLES,48300,ROCLES,,44.7026866095,3.78578869081,129,,Rocles,Rocles,48,Lozère,76,Occitanie
48130,ROUSSES,48400,ROUSSES,,44.1935759156,3.57352564571,130,,Rousses,Rousses,48,Lozère,76,Occitanie
48131,LE ROZIER,48150,LE ROZIER,,44.1953326945,3.21846877697,131,Le,Rozier,Le Rozier,48,Lozère,76,Occitanie
48132,ST ALBAN SUR LIMAGNOLE,48120,ST ALBAN SUR LIMAGNOLE,,44.796708709,3.40321537339,132,,Saint-Alban-sur-Limagnole,Saint-Alban-sur-Limagnole,48,Lozère,76,Occitanie
48133,ST AMANS,48700,ST AMANS,,44.6683375494,3.45507468285,133,,Saint-Amans,Saint-Amans,48,Lozère,76,Occitanie
48135,ST ANDRE CAPCEZE,48800,ST ANDRE CAPCEZE,,44.4177897224,3.94229613948,135,,Saint-André-Capcèze,Saint-André-Capcèze,48,Lozère,76,Occitanie
48136,ST ANDRE DE LANCIZE,48240,ST ANDRE DE LANCIZE,,44.2738839787,3.79461663158,136,,Saint-André-de-Lancize,Saint-André-de-Lancize,48,Lozère,76,Occitanie
48137,ST BAUZILE,48000,ST BAUZILE,,44.4690744788,3.49143883359,137,,Saint-Bauzile,Saint-Bauzile,48,Lozère,76,Occitanie
48138,ST BONNET DE CHIRAC,48100,ST BONNET DE CHIRAC,,44.5036552745,3.27693458859,138,,Saint-Bonnet-de-Chirac,Saint-Bonnet-de-Chirac,48,Lozère,76,Occitanie
48139,ST BONNET DE MONTAUROUX,48600,ST BONNET DE MONTAUROUX,,44.8174157178,3.72227564708,139,,Saint Bonnet-Laval,Saint Bonnet-Laval,48,Lozère,76,Occitanie
48139,ST BONNET DE MONTAUROUX,48600,ST BONNET DE MONTAUROUX,CHAPEAUROUX,44.8174157178,3.72227564708,139,,Saint Bonnet-Laval,Saint Bonnet-Laval,48,Lozère,76,Occitanie
48140,ST CHELY D APCHER,48200,ST CHELY D APCHER,,44.8126363545,3.2751576154,140,,Saint-Chély-d'Apcher,Saint-Chély-d'Apcher,48,Lozère,76,Occitanie
48141,MAS ST CHELY,48210,MAS ST CHELY,,44.3091282875,3.4143985008,141,,Mas-Saint-Chély,Mas-Saint-Chély,48,Lozère,76,Occitanie
48142,STE COLOMBE DE PEYRE,48130,STE COLOMBE DE PEYRE,,44.6769208097,3.23191868734,142,,Sainte-Colombe-de-Peyre,Sainte-Colombe-de-Peyre,48,Lozère,76,Occitanie
48144,STE CROIX VALLEE FRANCAISE,48110,STE CROIX VALLEE FRANCAISE,,44.1863337479,3.74222985458,144,,Sainte-Croix-Vallée-Française,Sainte-Croix-Vallée-Française,48,Lozère,76,Occitanie
48145,ST DENIS EN MARGERIDE,48700,ST DENIS EN MARGERIDE,,44.7410960032,3.47353884292,145,,Saint-Denis-en-Margeride,Saint-Denis-en-Margeride,48,Lozère,76,Occitanie
48146,STE ENIMIE,48210,STE ENIMIE,,44.3777129567,3.41934570392,146,,Gorges du Tarn Causses,Gorges du Tarn Causses,48,Lozère,76,Occitanie
48146,STE ENIMIE,48210,STE ENIMIE,PRADES,44.3777129567,3.41934570392,146,,Gorges du Tarn Causses,Gorges du Tarn Causses,48,Lozère,76,Occitanie
48147,ST ETIENNE DU VALDONNEZ,48000,ST ETIENNE DU VALDONNEZ,,44.4420560828,3.57373923919,147,,Saint-Étienne-du-Valdonnez,Saint-Étienne-du-Valdonnez,48,Lozère,76,Occitanie
48148,ST ETIENNE VALLEE FRANCAISE,48330,ST ETIENNE VALLEE FRANCAISE,,44.1711188054,3.8568943356,148,,Saint-Étienne-Vallée-Française,Saint-Étienne-Vallée-Française,48,Lozère,76,Occitanie
48149,STE EULALIE,48120,STE EULALIE,,44.7843192803,3.48437305584,149,,Sainte-Eulalie,Sainte-Eulalie,48,Lozère,76,Occitanie
48150,ST FLOUR DE MERCOIRE,48300,ST FLOUR DE MERCOIRE,,44.6814106122,3.82941959224,150,,Saint-Flour-de-Mercoire,Saint-Flour-de-Mercoire,48,Lozère,76,Occitanie
48151,ST FREZAL D ALBUGES,48170,ST FREZAL D ALBUGES,,44.580874652,3.76717918984,151,,Saint-Frézal-d'Albuges,Saint-Frézal-d'Albuges,48,Lozère,76,Occitanie
48152,VENTALON EN CEVENNES,48160,VENTALON EN CEVENNES,ST ANDEOL DE CLERGUEMORT,44.2940872699,3.8666152032,152,,Ventalon en Cévennes,Ventalon en Cévennes,48,Lozère,76,Occitanie
48152,VENTALON EN CEVENNES,48240,VENTALON EN CEVENNES,ST FREZAL DE VENTALON,44.2940872699,3.8666152032,152,,Ventalon en Cévennes,Ventalon en Cévennes,48,Lozère,76,Occitanie
48153,ST GAL,48700,ST GAL,,44.6677397831,3.41592493596,153,,Saint-Gal,Saint-Gal,48,Lozère,76,Occitanie
48154,ST GEORGES DE LEVEJAC,48500,ST GEORGES DE LEVEJAC,,44.3347458529,3.23583489511,154,,Saint-Georges-de-Lévéjac,Saint-Georges-de-Lévéjac,48,Lozère,76,Occitanie
48155,ST GERMAIN DE CALBERTE,48370,ST GERMAIN DE CALBERTE,,44.2216658847,3.81002631474,155,,Saint-Germain-de-Calberte,Saint-Germain-de-Calberte,48,Lozère,76,Occitanie
48156,ST GERMAIN DU TEIL,48340,ST GERMAIN DU TEIL,,44.48168833,3.17237902865,156,,Saint-Germain-du-Teil,Saint-Germain-du-Teil,48,Lozère,76,Occitanie
48157,STE HELENE,48190,STE HELENE,,44.5200305066,3.60341341973,157,,Sainte-Hélène,Sainte-Hélène,48,Lozère,76,Occitanie
48158,ST HILAIRE DE LAVIT,48160,ST HILAIRE DE LAVIT,,44.2534149147,3.85445517188,158,,Saint-Hilaire-de-Lavit,Saint-Hilaire-de-Lavit,48,Lozère,76,Occitanie
48160,ST JEAN LA FOUILLOUSE,48170,ST JEAN LA FOUILLOUSE,,44.7097136887,3.69001544129,160,,Saint-Jean-la-Fouillouse,Saint-Jean-la-Fouillouse,48,Lozère,76,Occitanie
48161,ST JUERY,48310,ST JUERY,,44.8298892181,3.08370934221,161,,Saint-Juéry,Saint-Juéry,48,Lozère,76,Occitanie
48162,CANS ET CEVENNES,48400,CANS ET CEVENNES,ST JULIEN D ARPAON,44.301436191,3.68067181243,162,,Saint-Julien-d'Arpaon,Saint-Julien-d'Arpaon,48,Lozère,76,Occitanie
48162,CANS ET CEVENNES,48400,CANS ET CEVENNES,ST LAURENT DE TREVES,44.301436191,3.68067181243,162,,Saint-Julien-d'Arpaon,Saint-Julien-d'Arpaon,48,Lozère,76,Occitanie
48163,ST JULIEN DES POINTS,48160,ST JULIEN DES POINTS,,44.259634137,3.96080381254,163,,Saint-Julien-des-Points,Saint-Julien-des-Points,48,Lozère,76,Occitanie
48164,ST JULIEN DU TOURNEL,48190,ST JULIEN DU TOURNEL,,44.4951981731,3.67969267896,164,,Saint-Julien-du-Tournel,Saint-Julien-du-Tournel,48,Lozère,76,Occitanie
48165,ST LAURENT DE MURET,48100,ST LAURENT DE MURET,,44.5986955189,3.17537194079,165,,Saint-Laurent-de-Muret,Saint-Laurent-de-Muret,48,Lozère,76,Occitanie
48167,ST LAURENT DE VEYRES,48310,ST LAURENT DE VEYRES,,44.7666501599,3.1233766072,167,,Saint-Laurent-de-Veyrès,Saint-Laurent-de-Veyrès,48,Lozère,76,Occitanie
48168,ST LEGER DE PEYRE,48100,ST LEGER DE PEYRE,,44.604203879,3.30637134661,168,,Saint-Léger-de-Peyre,Saint-Léger-de-Peyre,48,Lozère,76,Occitanie
48169,ST LEGER DU MALZIEU,48140,ST LEGER DU MALZIEU,,44.8986893937,3.31608500848,169,,Saint-Léger-du-Malzieu,Saint-Léger-du-Malzieu,48,Lozère,76,Occitanie
48170,ST MARTIN DE BOUBAUX,48160,ST MARTIN DE BOUBAUX,,44.194298693,3.9183444604,170,,Saint-Martin-de-Boubaux,Saint-Martin-de-Boubaux,48,Lozère,76,Occitanie
48171,ST MARTIN DE LANSUSCLE,48110,ST MARTIN DE LANSUSCLE,,44.2230644532,3.75067066931,171,,Saint-Martin-de-Lansuscle,Saint-Martin-de-Lansuscle,48,Lozère,76,Occitanie
48173,ST MICHEL DE DEZE,48160,ST MICHEL DE DEZE,,44.240223078,3.88529361784,173,,Saint-Michel-de-Dèze,Saint-Michel-de-Dèze,48,Lozère,76,Occitanie
48174,ST PAUL LE FROID,48600,ST PAUL LE FROID,,44.7953874731,3.5412624136,174,,Saint-Paul-le-Froid,Saint-Paul-le-Froid,48,Lozère,76,Occitanie
48175,ST PIERRE DE NOGARET,48340,ST PIERRE DE NOGARET,,44.4874734198,3.1270768416,175,,Saint-Pierre-de-Nogaret,Saint-Pierre-de-Nogaret,48,Lozère,76,Occitanie
48176,ST PIERRE DES TRIPIERS,48150,ST PIERRE DES TRIPIERS,,44.2243200504,3.26998184024,176,,Saint-Pierre-des-Tripiers,Saint-Pierre-des-Tripiers,48,Lozère,76,Occitanie
48177,ST PIERRE LE VIEUX,48200,ST PIERRE LE VIEUX,,44.844671879,3.30623246683,177,,Saint-Pierre-le-Vieux,Saint-Pierre-le-Vieux,48,Lozère,76,Occitanie
48178,ST PRIVAT DE VALLONGUE,48240,ST PRIVAT DE VALLONGUE,,44.2854432826,3.82446827604,178,,Saint-Privat-de-Vallongue,Saint-Privat-de-Vallongue,48,Lozère,76,Occitanie
48179,ST PRIVAT DU FAU,48140,ST PRIVAT DU FAU,,44.9186181805,3.35635253803,179,,Saint-Privat-du-Fau,Saint-Privat-du-Fau,48,Lozère,76,Occitanie
48180,ST ROME DE DOLAN,48500,ST ROME DE DOLAN,,44.2694614271,3.19482557519,180,,Saint-Rome-de-Dolan,Saint-Rome-de-Dolan,48,Lozère,76,Occitanie
48181,ST SATURNIN,48500,ST SATURNIN,,44.405047281,3.1969905802,181,,Saint-Saturnin,Saint-Saturnin,48,Lozère,76,Occitanie
48182,ST SAUVEUR DE GINESTOUX,48170,ST SAUVEUR DE GINESTOUX,,44.7018049753,3.61535812137,182,,Saint-Sauveur-de-Ginestoux,Saint-Sauveur-de-Ginestoux,48,Lozère,76,Occitanie
48183,ST SAUVEUR DE PEYRE,48130,ST SAUVEUR DE PEYRE,,44.652810778,3.2885496609,183,,Saint-Sauveur-de-Peyre,Saint-Sauveur-de-Peyre,48,Lozère,76,Occitanie
48184,ST SYMPHORIEN,48600,ST SYMPHORIEN,,44.8399709197,3.62339715605,184,,Saint-Symphorien,Saint-Symphorien,48,Lozère,76,Occitanie
48185,LES SALELLES,48230,LES SALELLES,,44.4847386708,3.27413095434,185,Les,Salelles,Les Salelles,48,Lozère,76,Occitanie
48187,LES SALCES,48100,LES SALCES,,44.5611463487,3.13595674342,187,Les,Salces,Les Salces,48,Lozère,76,Occitanie
48188,SERVERETTE,48700,SERVERETTE,,44.7000537421,3.39168881156,188,,Serverette,Serverette,48,Lozère,76,Occitanie
48189,SERVIERES,48000,SERVIERES,,44.5862173943,3.41770447473,189,,Servières,Servières,48,Lozère,76,Occitanie
48190,TERMES,48310,TERMES,,44.8258552553,3.16928104788,190,,Termes,Termes,48,Lozère,76,Occitanie
48191,LA TIEULE,48500,LA TIEULE,,44.3826211706,3.16213006652,191,La,Tieule,La Tieule,48,Lozère,76,Occitanie
48192,TRELANS,48340,TRELANS,,44.520157863,3.09375123863,192,,Trélans,Trélans,48,Lozère,76,Occitanie
48193,VEBRON,48400,VEBRON,,44.2551348683,3.54353418299,193,,Vebron,Vebron,48,Lozère,76,Occitanie
48193,VEBRON,48400,VEBRON,LES VANELS,44.2551348683,3.54353418299,193,,Vebron,Vebron,48,Lozère,76,Occitanie
48194,VIALAS,48220,VIALAS,,44.3430125499,3.89043012589,194,,Vialas,Vialas,48,Lozère,76,Occitanie
48195,LES VIGNES,48210,LES VIGNES,,44.268883271,3.25062860598,195,Les,Vignes,Les Vignes,48,Lozère,76,Occitanie
48197,LA VILLEDIEU,48700,LA VILLEDIEU,,44.7170082266,3.53559461062,197,La,Villedieu,La Villedieu,48,Lozère,76,Occitanie
48198,VILLEFORT,48800,VILLEFORT,,44.4441033418,3.93038185016,198,,Villefort,Villefort,48,Lozère,76,Occitanie
48198,VILLEFORT,48800,VILLEFORT,MAS DE LA BARQUE,44.4441033418,3.93038185016,198,,Villefort,Villefort,48,Lozère,76,Occitanie
49001,LES ALLEUDS,49320,LES ALLEUDS,,47.3148893703,-0.416673059864,1,Les,Alleuds,Les Alleuds,49,Maine-et-Loire,52,Pays de la Loire
49002,ALLONNES,49650,ALLONNES,,47.3034746947,0.0111127082363,2,,Allonnes,Allonnes,49,Maine-et-Loire,52,Pays de la Loire
49003,TUFFALUN,49700,TUFFALUN,AMBILLOU CHATEAU,47.2573828474,-0.337427788842,3,,Tuffalun,Tuffalun,49,Maine-et-Loire,52,Pays de la Loire
49003,TUFFALUN,49700,TUFFALUN,LOUERRE,47.2573828474,-0.337427788842,3,,Tuffalun,Tuffalun,49,Maine-et-Loire,52,Pays de la Loire
49003,TUFFALUN,49700,TUFFALUN,NOYANT LA PLAINE,47.2573828474,-0.337427788842,3,,Tuffalun,Tuffalun,49,Maine-et-Loire,52,Pays de la Loire
49007,ANGERS,49000,ANGERS,,47.476837416,-0.556125995444,7,,Angers,Angers,49,Maine-et-Loire,52,Pays de la Loire
49007,ANGERS,49100,ANGERS,,47.476837416,-0.556125995444,7,,Angers,Angers,49,Maine-et-Loire,52,Pays de la Loire
49008,ANGRIE,49440,ANGRIE,,47.5778407482,-0.968142946141,8,,Angrie,Angrie,49,Maine-et-Loire,52,Pays de la Loire
49009,ANTOIGNE,49260,ANTOIGNE,,47.0784768788,-0.131764667589,9,,Antoigné,Antoigné,49,Maine-et-Loire,52,Pays de la Loire
49010,ARMAILLE,49420,ARMAILLE,,47.7134820734,-1.137864787,10,,Armaillé,Armaillé,49,Maine-et-Loire,52,Pays de la Loire
49011,ARTANNES SUR THOUET,49260,ARTANNES SUR THOUET,,47.2019406008,-0.0969244084187,11,,Artannes-sur-Thouet,Artannes-sur-Thouet,49,Maine-et-Loire,52,Pays de la Loire
49012,AUBIGNE SUR LAYON,49540,AUBIGNE SUR LAYON,,47.2124010372,-0.472931660445,12,,Aubigné-sur-Layon,Aubigné-sur-Layon,49,Maine-et-Loire,52,Pays de la Loire
49013,AUVERSE,49490,AUVERSE,,47.5005493445,0.0389865694982,13,,Auverse,Auverse,49,Maine-et-Loire,52,Pays de la Loire
49014,AVIRE,49500,AVIRE,,47.7118822608,-0.791626507041,14,,Aviré,Aviré,49,Maine-et-Loire,52,Pays de la Loire
49015,AVRILLE,49240,AVRILLE,,47.5057106265,-0.600788940942,15,,Avrillé,Avrillé,49,Maine-et-Loire,52,Pays de la Loire
49017,BARACE,49430,BARACE,,47.6427261834,-0.357767481621,17,,Baracé,Baracé,49,Maine-et-Loire,52,Pays de la Loire
49018,BAUGE EN ANJOU,49150,BAUGE EN ANJOU,,47.5466894738,-0.109021794815,18,,Baugé-en-Anjou,Baugé-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49018,BAUGE EN ANJOU,49150,BAUGE EN ANJOU,BOCE,47.5466894738,-0.109021794815,18,,Baugé-en-Anjou,Baugé-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49018,BAUGE EN ANJOU,49150,BAUGE EN ANJOU,CHARTRENE,47.5466894738,-0.109021794815,18,,Baugé-en-Anjou,Baugé-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49018,BAUGE EN ANJOU,49150,BAUGE EN ANJOU,CHEVIRE LE ROUGE,47.5466894738,-0.109021794815,18,,Baugé-en-Anjou,Baugé-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49018,BAUGE EN ANJOU,49150,BAUGE EN ANJOU,CLEFS VAL D ANJOU,47.5466894738,-0.109021794815,18,,Baugé-en-Anjou,Baugé-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49018,BAUGE EN ANJOU,49150,BAUGE EN ANJOU,CUON,47.5466894738,-0.109021794815,18,,Baugé-en-Anjou,Baugé-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49018,BAUGE EN ANJOU,49150,BAUGE EN ANJOU,ECHEMIRE,47.5466894738,-0.109021794815,18,,Baugé-en-Anjou,Baugé-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49018,BAUGE EN ANJOU,49150,BAUGE EN ANJOU,FOUGERE,47.5466894738,-0.109021794815,18,,Baugé-en-Anjou,Baugé-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49018,BAUGE EN ANJOU,49150,BAUGE EN ANJOU,LE GUEDENIAU,47.5466894738,-0.109021794815,18,,Baugé-en-Anjou,Baugé-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49018,BAUGE EN ANJOU,49150,BAUGE EN ANJOU,LE VIEIL BAUGE,47.5466894738,-0.109021794815,18,,Baugé-en-Anjou,Baugé-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49018,BAUGE EN ANJOU,49150,BAUGE EN ANJOU,MONTPOLLIN,47.5466894738,-0.109021794815,18,,Baugé-en-Anjou,Baugé-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49018,BAUGE EN ANJOU,49150,BAUGE EN ANJOU,PONTIGNE,47.5466894738,-0.109021794815,18,,Baugé-en-Anjou,Baugé-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49018,BAUGE EN ANJOU,49150,BAUGE EN ANJOU,ST MARTIN D ARCE,47.5466894738,-0.109021794815,18,,Baugé-en-Anjou,Baugé-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49018,BAUGE EN ANJOU,49150,BAUGE EN ANJOU,ST QUENTIN LES BEAUREPAIRE,47.5466894738,-0.109021794815,18,,Baugé-en-Anjou,Baugé-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49018,BAUGE EN ANJOU,49150,BAUGE EN ANJOU,VAULANDRY,47.5466894738,-0.109021794815,18,,Baugé-en-Anjou,Baugé-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49020,BEAUCOUZE,49070,BEAUCOUZE,,47.4744512295,-0.63405515785,20,,Beaucouzé,Beaucouzé,49,Maine-et-Loire,52,Pays de la Loire
49021,BEAUFORT EN ANJOU,49250,BEAUFORT EN ANJOU,,47.4363863926,-0.206986791966,21,,Beaufort-en-Anjou,Beaufort-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49021,BEAUFORT EN ANJOU,49250,BEAUFORT EN ANJOU,GEE,47.4363863926,-0.206986791966,21,,Beaufort-en-Anjou,Beaufort-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49022,BEAULIEU SUR LAYON,49750,BEAULIEU SUR LAYON,,47.3187109536,-0.600035399851,22,,Beaulieu-sur-Layon,Beaulieu-sur-Layon,49,Maine-et-Loire,52,Pays de la Loire
49023,BEAUPREAU EN MAUGES,49110,BEAUPREAU EN MAUGES,LE PIN EN MAUGES,47.2125984545,-0.983619028639,23,,Beaupréau-en-Mauges,Beaupréau-en-Mauges,49,Maine-et-Loire,52,Pays de la Loire
49023,BEAUPREAU EN MAUGES,49450,BEAUPREAU EN MAUGES,VILLEDIEU LA BLOUERE,47.2125984545,-0.983619028639,23,,Beaupréau-en-Mauges,Beaupréau-en-Mauges,49,Maine-et-Loire,52,Pays de la Loire
49023,BEAUPREAU EN MAUGES,49510,BEAUPREAU EN MAUGES,JALLAIS,47.2125984545,-0.983619028639,23,,Beaupréau-en-Mauges,Beaupréau-en-Mauges,49,Maine-et-Loire,52,Pays de la Loire
49023,BEAUPREAU EN MAUGES,49510,BEAUPREAU EN MAUGES,LA JUBAUDIERE,47.2125984545,-0.983619028639,23,,Beaupréau-en-Mauges,Beaupréau-en-Mauges,49,Maine-et-Loire,52,Pays de la Loire
49023,BEAUPREAU EN MAUGES,49510,BEAUPREAU EN MAUGES,LA POITEVINIERE,47.2125984545,-0.983619028639,23,,Beaupréau-en-Mauges,Beaupréau-en-Mauges,49,Maine-et-Loire,52,Pays de la Loire
49023,BEAUPREAU EN MAUGES,49600,BEAUPREAU EN MAUGES,,47.2125984545,-0.983619028639,23,,Beaupréau-en-Mauges,Beaupréau-en-Mauges,49,Maine-et-Loire,52,Pays de la Loire
49023,BEAUPREAU EN MAUGES,49600,BEAUPREAU EN MAUGES,ANDREZE,47.2125984545,-0.983619028639,23,,Beaupréau-en-Mauges,Beaupréau-en-Mauges,49,Maine-et-Loire,52,Pays de la Loire
49023,BEAUPREAU EN MAUGES,49600,BEAUPREAU EN MAUGES,GESTE,47.2125984545,-0.983619028639,23,,Beaupréau-en-Mauges,Beaupréau-en-Mauges,49,Maine-et-Loire,52,Pays de la Loire
49023,BEAUPREAU EN MAUGES,49600,BEAUPREAU EN MAUGES,LA CHAPELLE DU GENET,47.2125984545,-0.983619028639,23,,Beaupréau-en-Mauges,Beaupréau-en-Mauges,49,Maine-et-Loire,52,Pays de la Loire
49023,BEAUPREAU EN MAUGES,49600,BEAUPREAU EN MAUGES,ST PHILBERT EN MAUGES,47.2125984545,-0.983619028639,23,,Beaupréau-en-Mauges,Beaupréau-en-Mauges,49,Maine-et-Loire,52,Pays de la Loire
49026,BECON LES GRANITS,49370,BECON LES GRANITS,,47.4995106103,-0.799139218655,26,,Bécon-les-Granits,Bécon-les-Granits,49,Maine-et-Loire,52,Pays de la Loire
49027,BEGROLLES EN MAUGES,49122,BEGROLLES EN MAUGES,,47.133273239,-0.931697835787,27,,Bégrolles-en-Mauges,Bégrolles-en-Mauges,49,Maine-et-Loire,52,Pays de la Loire
49028,BEHUARD,49170,BEHUARD,,47.376691016,-0.653915821734,28,,Béhuard,Béhuard,49,Maine-et-Loire,52,Pays de la Loire
49029,BLAISON ST SULPICE,49320,BLAISON ST SULPICE,,47.3908187654,-0.380019189582,29,,Blaison-Saint-Sulpice,Blaison-Saint-Sulpice,49,Maine-et-Loire,52,Pays de la Loire
49029,BLAISON ST SULPICE,49320,BLAISON ST SULPICE,GOHIER,47.3908187654,-0.380019189582,29,,Blaison-Saint-Sulpice,Blaison-Saint-Sulpice,49,Maine-et-Loire,52,Pays de la Loire
49029,BLAISON ST SULPICE,49320,BLAISON ST SULPICE,ST SULPICE,47.3908187654,-0.380019189582,29,,Blaison-Saint-Sulpice,Blaison-Saint-Sulpice,49,Maine-et-Loire,52,Pays de la Loire
49030,BLOU,49160,BLOU,,47.3630441484,-0.0536103057427,30,,Blou,Blou,49,Maine-et-Loire,52,Pays de la Loire
49035,BOUCHEMAINE,49080,BOUCHEMAINE,,47.4307669874,-0.627122133651,35,,Bouchemaine,Bouchemaine,49,Maine-et-Loire,52,Pays de la Loire
49036,BOUILLE MENARD,49520,BOUILLE MENARD,,47.7381032955,-0.969364603994,36,,Bouillé-Ménard,Bouillé-Ménard,49,Maine-et-Loire,52,Pays de la Loire
49037,LE BOURG D IRE,49520,LE BOURG D IRE,,47.6705697053,-0.969128489378,37,Le,Bourg-d'Iré,Le Bourg-d'Iré,49,Maine-et-Loire,52,Pays de la Loire
49038,BOURG L EVEQUE,49520,BOURG L EVEQUE,,47.7329044493,-1.01480048811,38,,Bourg-l'Évêque,Bourg-l'Évêque,49,Maine-et-Loire,52,Pays de la Loire
49041,BRAIN SUR ALLONNES,49650,BRAIN SUR ALLONNES,,47.3056918695,0.0710549100336,41,,Brain-sur-Allonnes,Brain-sur-Allonnes,49,Maine-et-Loire,52,Pays de la Loire
49044,BREIL,49490,BREIL,,47.473768222,0.167566733068,44,,Breil,Breil,49,Maine-et-Loire,52,Pays de la Loire
49045,LA BREILLE LES PINS,49390,LA BREILLE LES PINS,,47.3510849572,0.0835955334414,45,La,Breille-les-Pins,La Breille-les-Pins,49,Maine-et-Loire,52,Pays de la Loire
49046,BREZE,49260,BREZE,,47.1659198942,-0.0543045916422,46,,Brézé,Brézé,49,Maine-et-Loire,52,Pays de la Loire
49047,BRIGNE,49700,BRIGNE,,47.2441365877,-0.378451731955,47,,Brigné,Brigné,49,Maine-et-Loire,52,Pays de la Loire
49048,BRIOLLAY,49125,BRIOLLAY,,47.57639762,-0.497931074064,48,,Briollay,Briollay,49,Maine-et-Loire,52,Pays de la Loire
49050,BRISSAC QUINCE,49320,BRISSAC QUINCE,,47.3416678749,-0.441366325976,50,,Brissac Loire Aubance,Brissac Loire Aubance,49,Maine-et-Loire,52,Pays de la Loire
49051,BRISSARTHE,49330,BRISSARTHE,,47.7115167472,-0.463072395365,51,,Brissarthe,Brissarthe,49,Maine-et-Loire,52,Pays de la Loire
49052,BROC,49490,BROC,,47.5898671292,0.197416645494,52,,Broc,Broc,49,Maine-et-Loire,52,Pays de la Loire
49053,BROSSAY,49700,BROSSAY,,47.1680323936,-0.216255821188,53,,Brossay,Brossay,49,Maine-et-Loire,52,Pays de la Loire
49054,CANDE,49440,CANDE,,47.5585230813,-1.03503351449,54,,Candé,Candé,49,Maine-et-Loire,52,Pays de la Loire
49055,CANTENAY EPINARD,49460,CANTENAY EPINARD,,47.5380581206,-0.566473143041,55,,Cantenay-Épinard,Cantenay-Épinard,49,Maine-et-Loire,52,Pays de la Loire
49056,CARBAY,49420,CARBAY,,47.7315510459,-1.2261277126,56,,Carbay,Carbay,49,Maine-et-Loire,52,Pays de la Loire
49057,CERNUSSON,49310,CERNUSSON,,47.1678543768,-0.482175305378,57,,Cernusson,Cernusson,49,Maine-et-Loire,52,Pays de la Loire
49058,LES CERQUEUX,49360,LES CERQUEUX,,47.0057486644,-0.641214387414,58,Les,Cerqueux,Les Cerqueux,49,Maine-et-Loire,52,Pays de la Loire
49060,CHACE,49400,CHACE,,47.2066359278,-0.0708098589193,60,,Chacé,Chacé,49,Maine-et-Loire,52,Pays de la Loire
49061,CHALLAIN LA POTHERIE,49440,CHALLAIN LA POTHERIE,,47.6319922326,-1.07146744794,61,,Challain-la-Potherie,Challain-la-Potherie,49,Maine-et-Loire,52,Pays de la Loire
49062,CHALONNES SOUS LE LUDE,49490,CHALONNES SOUS LE LUDE,,47.5572762686,0.176064952548,62,,Chalonnes-sous-le-Lude,Chalonnes-sous-le-Lude,49,Maine-et-Loire,52,Pays de la Loire
49063,CHALONNES SUR LOIRE,49290,CHALONNES SUR LOIRE,,47.3494785235,-0.772675582911,63,,Chalonnes-sur-Loire,Chalonnes-sur-Loire,49,Maine-et-Loire,52,Pays de la Loire
49064,CHAMBELLAY,49220,CHAMBELLAY,,47.6887999605,-0.69354489611,64,,Chambellay,Chambellay,49,Maine-et-Loire,52,Pays de la Loire
49065,CHAMPIGNE,49330,CHAMPIGNE,,47.6653059504,-0.570426298247,65,Les,Hauts d'Anjou,Les Hauts d'Anjou,49,Maine-et-Loire,52,Pays de la Loire
49067,CHENILLE CHAMPTEUSSE,49220,CHENILLE CHAMPTEUSSE,,47.6694970175,-0.65425867074,67,,Chenillé-Champteussé,Chenillé-Champteussé,49,Maine-et-Loire,52,Pays de la Loire
49067,CHENILLE CHAMPTEUSSE,49220,CHENILLE CHAMPTEUSSE,CHENILLE CHANGE,47.6694970175,-0.65425867074,67,,Chenillé-Champteussé,Chenillé-Champteussé,49,Maine-et-Loire,52,Pays de la Loire
49068,CHAMPTOCE SUR LOIRE,49123,CHAMPTOCE SUR LOIRE,,47.4299165836,-0.874606992873,68,,Champtocé-sur-Loire,Champtocé-sur-Loire,49,Maine-et-Loire,52,Pays de la Loire
49069,OREE D ANJOU,49270,OREE D ANJOU,CHAMPTOCEAUX,47.3218941947,-1.26053581871,69,,Orée d'Anjou,Orée d'Anjou,49,Maine-et-Loire,52,Pays de la Loire
49069,OREE D ANJOU,49270,OREE D ANJOU,LANDEMONT,47.3218941947,-1.26053581871,69,,Orée d'Anjou,Orée d'Anjou,49,Maine-et-Loire,52,Pays de la Loire
49069,OREE D ANJOU,49270,OREE D ANJOU,LA VARENNE,47.3218941947,-1.26053581871,69,,Orée d'Anjou,Orée d'Anjou,49,Maine-et-Loire,52,Pays de la Loire
49069,OREE D ANJOU,49270,OREE D ANJOU,ST CHRISTOPHE LA COUPERIE,47.3218941947,-1.26053581871,69,,Orée d'Anjou,Orée d'Anjou,49,Maine-et-Loire,52,Pays de la Loire
49069,OREE D ANJOU,49270,OREE D ANJOU,ST LAURENT DES AUTELS,47.3218941947,-1.26053581871,69,,Orée d'Anjou,Orée d'Anjou,49,Maine-et-Loire,52,Pays de la Loire
49069,OREE D ANJOU,49270,OREE D ANJOU,ST SAUVEUR DE LANDEMONT,47.3218941947,-1.26053581871,69,,Orée d'Anjou,Orée d'Anjou,49,Maine-et-Loire,52,Pays de la Loire
49069,OREE D ANJOU,49530,OREE D ANJOU,BOUZILLE,47.3218941947,-1.26053581871,69,,Orée d'Anjou,Orée d'Anjou,49,Maine-et-Loire,52,Pays de la Loire
49069,OREE D ANJOU,49530,OREE D ANJOU,DRAIN,47.3218941947,-1.26053581871,69,,Orée d'Anjou,Orée d'Anjou,49,Maine-et-Loire,52,Pays de la Loire
49069,OREE D ANJOU,49530,OREE D ANJOU,LIRE,47.3218941947,-1.26053581871,69,,Orée d'Anjou,Orée d'Anjou,49,Maine-et-Loire,52,Pays de la Loire
49070,CHANTELOUP LES BOIS,49340,CHANTELOUP LES BOIS,,47.084888395,-0.691577840535,70,,Chanteloup-les-Bois,Chanteloup-les-Bois,49,Maine-et-Loire,52,Pays de la Loire
49073,LA CHAPELLE HULLIN,49420,LA CHAPELLE HULLIN,,47.7577299396,-1.06746334675,73,La,Chapelle-Hullin,La Chapelle-Hullin,49,Maine-et-Loire,52,Pays de la Loire
49076,LA CHAPELLE ST LAUD,49140,LA CHAPELLE ST LAUD,,47.6139328972,-0.298192588523,76,La,Chapelle-Saint-Laud,La Chapelle-Saint-Laud,49,Maine-et-Loire,52,Pays de la Loire
49077,LA CHAPELLE SUR OUDON,49500,LA CHAPELLE SUR OUDON,,47.6665870157,-0.829317480119,77,La,Chapelle-sur-Oudon,La Chapelle-sur-Oudon,49,Maine-et-Loire,52,Pays de la Loire
49078,CHARCE ST ELLIER SUR AUBANCE,49320,CHARCE ST ELLIER SUR AUBANCE,,47.3522114874,-0.403200268067,78,,Charcé-Saint-Ellier-sur-Aubance,Charcé-Saint-Ellier-sur-Aubance,49,Maine-et-Loire,52,Pays de la Loire
49078,CHARCE ST ELLIER SUR AUBANCE,49320,CHARCE ST ELLIER SUR AUBANCE,ST ELLIER,47.3522114874,-0.403200268067,78,,Charcé-Saint-Ellier-sur-Aubance,Charcé-Saint-Ellier-sur-Aubance,49,Maine-et-Loire,52,Pays de la Loire
49080,CHATEAUNEUF SUR SARTHE,49330,CHATEAUNEUF SUR SARTHE,,47.6841712906,-0.495930395639,80,,Châteauneuf-sur-Sarthe,Châteauneuf-sur-Sarthe,49,Maine-et-Loire,52,Pays de la Loire
49081,CHATELAIS,49520,CHATELAIS,,47.7621935629,-0.934797324242,81,,Châtelais,Châtelais,49,Maine-et-Loire,52,Pays de la Loire
49082,CHAUDEFONDS SUR LAYON,49290,CHAUDEFONDS SUR LAYON,,47.3253239782,-0.721782727229,82,,Chaudefonds-sur-Layon,Chaudefonds-sur-Layon,49,Maine-et-Loire,52,Pays de la Loire
49086,CHAVAGNES,49380,CHAVAGNES,,47.274007949,-0.439804383087,86,,Terranjou,Terranjou,49,Maine-et-Loire,52,Pays de la Loire
49087,CHAVAIGNES,49490,CHAVAIGNES,,47.5440518472,0.0423849749559,87,,Chavaignes,Chavaignes,49,Maine-et-Loire,52,Pays de la Loire
49088,CHAZE HENRY,49420,CHAZE HENRY,,47.7595620628,-1.11116254411,88,,Chazé-Henry,Chazé-Henry,49,Maine-et-Loire,52,Pays de la Loire
49089,CHAZE SUR ARGOS,49500,CHAZE SUR ARGOS,,47.6147680187,-0.897982444715,89,,Chazé-sur-Argos,Chazé-sur-Argos,49,Maine-et-Loire,52,Pays de la Loire
49090,CHEFFES,49125,CHEFFES,,47.6253057182,-0.519918963857,90,,Cheffes,Cheffes,49,Maine-et-Loire,52,Pays de la Loire
49091,CHEMELLIER,49320,CHEMELLIER,,47.3369696045,-0.358947664736,91,,Chemellier,Chemellier,49,Maine-et-Loire,52,Pays de la Loire
49092,CHEMILLE EN ANJOU,49120,CHEMILLE EN ANJOU,,47.2272268469,-0.730188160448,92,,Chemillé-en-Anjou,Chemillé-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49092,CHEMILLE EN ANJOU,49120,CHEMILLE EN ANJOU,COSSE D ANJOU,47.2272268469,-0.730188160448,92,,Chemillé-en-Anjou,Chemillé-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49092,CHEMILLE EN ANJOU,49120,CHEMILLE EN ANJOU,LA CHAPELLE ROUSSELIN,47.2272268469,-0.730188160448,92,,Chemillé-en-Anjou,Chemillé-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49092,CHEMILLE EN ANJOU,49120,CHEMILLE EN ANJOU,LA JUMELLIERE,47.2272268469,-0.730188160448,92,,Chemillé-en-Anjou,Chemillé-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49092,CHEMILLE EN ANJOU,49120,CHEMILLE EN ANJOU,LA TOURLANDRY,47.2272268469,-0.730188160448,92,,Chemillé-en-Anjou,Chemillé-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49092,CHEMILLE EN ANJOU,49120,CHEMILLE EN ANJOU,LES GARDES,47.2272268469,-0.730188160448,92,,Chemillé-en-Anjou,Chemillé-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49092,CHEMILLE EN ANJOU,49120,CHEMILLE EN ANJOU,MELAY,47.2272268469,-0.730188160448,92,,Chemillé-en-Anjou,Chemillé-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49092,CHEMILLE EN ANJOU,49120,CHEMILLE EN ANJOU,NEUVY EN MAUGES,47.2272268469,-0.730188160448,92,,Chemillé-en-Anjou,Chemillé-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49092,CHEMILLE EN ANJOU,49120,CHEMILLE EN ANJOU,STE CHRISTINE,47.2272268469,-0.730188160448,92,,Chemillé-en-Anjou,Chemillé-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49092,CHEMILLE EN ANJOU,49120,CHEMILLE EN ANJOU,ST GEORGES DES GARDES,47.2272268469,-0.730188160448,92,,Chemillé-en-Anjou,Chemillé-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49092,CHEMILLE EN ANJOU,49120,CHEMILLE EN ANJOU,ST LEZIN,47.2272268469,-0.730188160448,92,,Chemillé-en-Anjou,Chemillé-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49092,CHEMILLE EN ANJOU,49310,CHEMILLE EN ANJOU,LA SALLE DE VIHIERS,47.2272268469,-0.730188160448,92,,Chemillé-en-Anjou,Chemillé-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49092,CHEMILLE EN ANJOU,49670,CHEMILLE EN ANJOU,JOUE ETIAU,47.2272268469,-0.730188160448,92,,Chemillé-en-Anjou,Chemillé-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49092,CHEMILLE EN ANJOU,49670,CHEMILLE EN ANJOU,VALANJOU,47.2272268469,-0.730188160448,92,,Chemillé-en-Anjou,Chemillé-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49092,CHEMILLE EN ANJOU,49750,CHEMILLE EN ANJOU,CHANZEAUX,47.2272268469,-0.730188160448,92,,Chemillé-en-Anjou,Chemillé-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49096,CHERRE,49330,CHERRE,,47.708594776,-0.554395431177,96,,Cherré,Cherré,49,Maine-et-Loire,52,Pays de la Loire
49098,CHIGNE,49490,CHIGNE,,47.5762354871,0.111148577918,98,,Chigné,Chigné,49,Maine-et-Loire,52,Pays de la Loire
49099,CHOLET,49300,CHOLET,,47.0454402992,-0.877886189605,99,,Cholet,Cholet,49,Maine-et-Loire,52,Pays de la Loire
49099,CHOLET,49300,CHOLET,LE PUY ST BONNET,47.0454402992,-0.877886189605,99,,Cholet,Cholet,49,Maine-et-Loire,52,Pays de la Loire
49100,CIZAY LA MADELEINE,49700,CIZAY LA MADELEINE,,47.1826312611,-0.184989396034,100,,Cizay-la-Madeleine,Cizay-la-Madeleine,49,Maine-et-Loire,52,Pays de la Loire
49102,CLERE SUR LAYON,49560,CLERE SUR LAYON,,47.0857328909,-0.430334621535,102,,Cléré-sur-Layon,Cléré-sur-Layon,49,Maine-et-Loire,52,Pays de la Loire
49103,COMBREE,49520,COMBREE,,47.7071628659,-1.02070109736,103,,Combrée,Combrée,49,Maine-et-Loire,52,Pays de la Loire
49103,COMBREE,49520,COMBREE,BEL AIR,47.7071628659,-1.02070109736,103,,Combrée,Combrée,49,Maine-et-Loire,52,Pays de la Loire
49104,CONCOURSON SUR LAYON,49700,CONCOURSON SUR LAYON,,47.1738115578,-0.350573208793,104,,Concourson-sur-Layon,Concourson-sur-Layon,49,Maine-et-Loire,52,Pays de la Loire
49105,CONTIGNE,49330,CONTIGNE,,47.7267152855,-0.502172077277,105,,Contigné,Contigné,49,Maine-et-Loire,52,Pays de la Loire
49107,CORNILLE LES CAVES,49140,CORNILLE LES CAVES,,47.4923319626,-0.303632794526,107,,Cornillé-les-Caves,Cornillé-les-Caves,49,Maine-et-Loire,52,Pays de la Loire
49108,LA CORNUAILLE,49440,LA CORNUAILLE,,47.5247468295,-0.995630411608,108,La,Cornuaille,La Cornuaille,49,Maine-et-Loire,52,Pays de la Loire
49109,CORON,49690,CORON,,47.1203026416,-0.63636371367,109,,Coron,Coron,49,Maine-et-Loire,52,Pays de la Loire
49110,CORZE,49140,CORZE,,47.5424133416,-0.369410127178,110,,Corzé,Corzé,49,Maine-et-Loire,52,Pays de la Loire
49112,LE COUDRAY MACOUARD,49260,LE COUDRAY MACOUARD,,47.1825767187,-0.129426451927,112,Le,Coudray-Macouard,Le Coudray-Macouard,49,Maine-et-Loire,52,Pays de la Loire
49113,COURCHAMPS,49260,COURCHAMPS,,47.1979795886,-0.157247285018,113,,Courchamps,Courchamps,49,Maine-et-Loire,52,Pays de la Loire
49114,COURLEON,49390,COURLEON,,47.3809189651,0.146996845138,114,,Courléon,Courléon,49,Maine-et-Loire,52,Pays de la Loire
49115,COUTURES,49320,COUTURES,,47.3723458994,-0.352088357358,115,,Coutures,Coutures,49,Maine-et-Loire,52,Pays de la Loire
49119,DAUMERAY,49640,DAUMERAY,,47.6849357521,-0.362457527472,119,,Daumeray,Daumeray,49,Maine-et-Loire,52,Pays de la Loire
49120,DENEE,49190,DENEE,,47.3777256876,-0.609937724094,120,,Denée,Denée,49,Maine-et-Loire,52,Pays de la Loire
49121,DENEZE SOUS DOUE,49700,DENEZE SOUS DOUE,,47.2528537089,-0.259868894277,121,,Dénezé-sous-Doué,Dénezé-sous-Doué,49,Maine-et-Loire,52,Pays de la Loire
49122,DENEZE SOUS LE LUDE,49490,DENEZE SOUS LE LUDE,,47.5381332882,0.123152020519,122,,Dénezé-sous-le-Lude,Dénezé-sous-le-Lude,49,Maine-et-Loire,52,Pays de la Loire
49123,DISTRE,49400,DISTRE,,47.223264756,-0.123769000712,123,,Distré,Distré,49,Maine-et-Loire,52,Pays de la Loire
49125,DOUE LA FONTAINE,49700,DOUE LA FONTAINE,,47.1914715686,-0.278336056087,125,,Doué-en-Anjou,Doué-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49127,DURTAL,49430,DURTAL,,47.6780515421,-0.254608332885,127,,Durtal,Durtal,49,Maine-et-Loire,52,Pays de la Loire
49129,ECOUFLANT,49000,ECOUFLANT,,47.5286938274,-0.518454771034,129,,Écouflant,Écouflant,49,Maine-et-Loire,52,Pays de la Loire
49130,ECUILLE,49460,ECUILLE,,47.6151527391,-0.560819094743,130,,Écuillé,Écuillé,49,Maine-et-Loire,52,Pays de la Loire
49131,EPIEDS,49260,EPIEDS,,47.1348367577,-0.0385758480154,131,,Épieds,Épieds,49,Maine-et-Loire,52,Pays de la Loire
49132,ETRICHE,49330,ETRICHE,,47.6598525566,-0.452234777822,132,,Étriché,Étriché,49,Maine-et-Loire,52,Pays de la Loire
49135,FENEU,49460,FENEU,,47.5840201404,-0.600831621131,135,,Feneu,Feneu,49,Maine-et-Loire,52,Pays de la Loire
49136,LA FERRIERE DE FLEE,49500,LA FERRIERE DE FLEE,,47.7365814493,-0.839728647757,136,La,Ferrière-de-Flée,La Ferrière-de-Flée,49,Maine-et-Loire,52,Pays de la Loire
49138,LES BOIS D ANJOU,49250,LES BOIS D ANJOU,BRION,47.4829167789,-0.174198642479,138,Les,Bois d'Anjou,Les Bois d'Anjou,49,Maine-et-Loire,52,Pays de la Loire
49138,LES BOIS D ANJOU,49250,LES BOIS D ANJOU,FONTAINE GUERIN,47.4829167789,-0.174198642479,138,Les,Bois d'Anjou,Les Bois d'Anjou,49,Maine-et-Loire,52,Pays de la Loire
49138,LES BOIS D ANJOU,49250,LES BOIS D ANJOU,ST GEORGES DU BOIS,47.4829167789,-0.174198642479,138,Les,Bois d'Anjou,Les Bois d'Anjou,49,Maine-et-Loire,52,Pays de la Loire
49140,FONTEVRAUD L ABBAYE,49590,FONTEVRAUD L ABBAYE,,47.1843477154,0.03384269066,140,,Fontevraud-l'Abbaye,Fontevraud-l'Abbaye,49,Maine-et-Loire,52,Pays de la Loire
49141,FORGES,49700,FORGES,,47.2144953715,-0.23712263964,141,,Forges,Forges,49,Maine-et-Loire,52,Pays de la Loire
49144,FREIGNE,49440,FREIGNE,,47.5362195118,-1.10598728403,144,,Freigné,Freigné,49,Maine-et-Loire,52,Pays de la Loire
49149,GENNES VAL DE LOIRE,49320,GENNES VAL DE LOIRE,GREZILLE,47.3145776087,-0.253486023918,149,,Gennes,Gennes,49,Maine-et-Loire,52,Pays de la Loire
49149,GENNES VAL DE LOIRE,49350,GENNES VAL DE LOIRE,,47.3145776087,-0.253486023918,149,,Gennes,Gennes,49,Maine-et-Loire,52,Pays de la Loire
49149,GENNES VAL DE LOIRE,49350,GENNES VAL DE LOIRE,CHENEHUTTE TREVES CUNAULT,47.3145776087,-0.253486023918,149,,Gennes,Gennes,49,Maine-et-Loire,52,Pays de la Loire
49149,GENNES VAL DE LOIRE,49350,GENNES VAL DE LOIRE,LE THOUREIL,47.3145776087,-0.253486023918,149,,Gennes,Gennes,49,Maine-et-Loire,52,Pays de la Loire
49149,GENNES VAL DE LOIRE,49350,GENNES VAL DE LOIRE,ST GEORGES DES SEPT VOIES,47.3145776087,-0.253486023918,149,,Gennes,Gennes,49,Maine-et-Loire,52,Pays de la Loire
49149,GENNES VAL DE LOIRE,49350,GENNES VAL DE LOIRE,TREVES CUNAULT,47.3145776087,-0.253486023918,149,,Gennes,Gennes,49,Maine-et-Loire,52,Pays de la Loire
49150,GENNETEIL,49490,GENNETEIL,,47.5836923333,0.0465784528083,150,,Genneteil,Genneteil,49,Maine-et-Loire,52,Pays de la Loire
49155,GREZ NEUVILLE,49220,GREZ NEUVILLE,,47.5951579943,-0.695965516847,155,,Grez-Neuville,Grez-Neuville,49,Maine-et-Loire,52,Pays de la Loire
49156,GRUGE L HOPITAL,49520,GRUGE L HOPITAL,,47.7529019334,-1.02649508441,156,,Grugé-l'Hôpital,Grugé-l'Hôpital,49,Maine-et-Loire,52,Pays de la Loire
49158,L HOTELLERIE DE FLEE,49500,L HOTELLERIE DE FLEE,,47.7471998488,-0.892010550736,158,L',Hôtellerie-de-Flée,L'Hôtellerie-de-Flée,49,Maine-et-Loire,52,Pays de la Loire
49159,HUILLE,49430,HUILLE,,47.6551436907,-0.309834884442,159,,Huillé,Huillé,49,Maine-et-Loire,52,Pays de la Loire
49160,INGRANDES LE FRESNE SUR LOIRE,49123,INGRANDES LE FRESNE SUR LOIRE,,47.4154942142,-0.920314202965,160,,Ingrandes-Le Fresne sur Loire,Ingrandes-Le Fresne sur Loire,49,Maine-et-Loire,52,Pays de la Loire
49160,INGRANDES LE FRESNE SUR LOIRE,49123,INGRANDES LE FRESNE SUR LOIRE,LE FRESNE SUR LOIRE,47.4154942142,-0.920314202965,160,,Ingrandes-Le Fresne sur Loire,Ingrandes-Le Fresne sur Loire,49,Maine-et-Loire,52,Pays de la Loire
49161,LA JAILLE YVON,49220,LA JAILLE YVON,,47.7267173977,-0.687550991223,161,La,Jaille-Yvon,La Jaille-Yvon,49,Maine-et-Loire,52,Pays de la Loire
49163,JARZE VILLAGES,49140,JARZE VILLAGES,,47.5576434301,-0.24007574412,163,,Jarzé Villages,Jarzé Villages,49,Maine-et-Loire,52,Pays de la Loire
49163,JARZE VILLAGES,49140,JARZE VILLAGES,BEAUVAU,47.5576434301,-0.24007574412,163,,Jarzé Villages,Jarzé Villages,49,Maine-et-Loire,52,Pays de la Loire
49163,JARZE VILLAGES,49140,JARZE VILLAGES,CHAUMONT D ANJOU,47.5576434301,-0.24007574412,163,,Jarzé Villages,Jarzé Villages,49,Maine-et-Loire,52,Pays de la Loire
49163,JARZE VILLAGES,49140,JARZE VILLAGES,LUE EN BAUGEOIS,47.5576434301,-0.24007574412,163,,Jarzé Villages,Jarzé Villages,49,Maine-et-Loire,52,Pays de la Loire
49167,JUIGNE SUR LOIRE,49610,JUIGNE SUR LOIRE,,47.40011602,-0.484584915233,167,Les,Garennes sur Loire,Les Garennes sur Loire,49,Maine-et-Loire,52,Pays de la Loire
49170,JUVARDEIL,49330,JUVARDEIL,,47.6592762477,-0.514251565754,170,,Juvardeil,Juvardeil,49,Maine-et-Loire,52,Pays de la Loire
49171,LA LANDE CHASLES,49150,LA LANDE CHASLES,,47.4614234865,-0.0676198362625,171,La,Lande-Chasles,La Lande-Chasles,49,Maine-et-Loire,52,Pays de la Loire
49173,LASSE,49490,LASSE,,47.5442138366,0.00592216216308,173,,Lasse,Lasse,49,Maine-et-Loire,52,Pays de la Loire
49174,LEZIGNE,49430,LEZIGNE,,47.6367744645,-0.292940093919,174,,Lézigné,Lézigné,49,Maine-et-Loire,52,Pays de la Loire
49175,LINIERES BOUTON,49490,LINIERES BOUTON,,47.4501636334,0.0666355358883,175,,Linières-Bouton,Linières-Bouton,49,Maine-et-Loire,52,Pays de la Loire
49176,LE LION D ANGERS,49220,LE LION D ANGERS,,47.6307737346,-0.749881925184,176,Le,Lion-d'Angers,Le Lion-d'Angers,49,Maine-et-Loire,52,Pays de la Loire
49176,LE LION D ANGERS,49220,LE LION D ANGERS,ANDIGNE,47.6307737346,-0.749881925184,176,Le,Lion-d'Angers,Le Lion-d'Angers,49,Maine-et-Loire,52,Pays de la Loire
49178,LOIRE,49440,LOIRE,,47.6264697538,-0.964687167794,178,,Loiré,Loiré,49,Maine-et-Loire,52,Pays de la Loire
49180,LONGUE JUMELLES,49160,LONGUE JUMELLES,,47.3984723864,-0.107579294015,180,,Longué-Jumelles,Longué-Jumelles,49,Maine-et-Loire,52,Pays de la Loire
49180,LONGUE JUMELLES,49160,LONGUE JUMELLES,JUMELLES,47.3984723864,-0.107579294015,180,,Longué-Jumelles,Longué-Jumelles,49,Maine-et-Loire,52,Pays de la Loire
49182,LOURESSE ROCHEMENIER,49700,LOURESSE ROCHEMENIER,,47.2417694387,-0.307091096953,182,,Louresse-Rochemenier,Louresse-Rochemenier,49,Maine-et-Loire,52,Pays de la Loire
49183,LE LOUROUX BECONNAIS,49370,LE LOUROUX BECONNAIS,,47.519040527,-0.90137845396,183,,Val d'Erdre-Auxence,Val d'Erdre-Auxence,49,Maine-et-Loire,52,Pays de la Loire
49184,LOUVAINES,49500,LOUVAINES,,47.6906077603,-0.802929721052,184,,Louvaines,Louvaines,49,Maine-et-Loire,52,Pays de la Loire
49186,LUIGNE,49320,LUIGNE,,47.2858487854,-0.400877315446,186,,Luigné,Luigné,49,Maine-et-Loire,52,Pays de la Loire
49187,MARANS,49500,MARANS,,47.6416252314,-0.849694724597,187,,Marans,Marans,49,Maine-et-Loire,52,Pays de la Loire
49188,MARCE,49140,MARCE,,47.5822974995,-0.300211738642,188,,Marcé,Marcé,49,Maine-et-Loire,52,Pays de la Loire
49189,MARIGNE,49330,MARIGNE,,47.7139945801,-0.623422312974,189,,Marigné,Marigné,49,Maine-et-Loire,52,Pays de la Loire
49191,MARTIGNE BRIAND,49540,MARTIGNE BRIAND,,47.2399642788,-0.437027427715,191,,Martigné-Briand,Martigné-Briand,49,Maine-et-Loire,52,Pays de la Loire
49192,MAULEVRIER,49360,MAULEVRIER,,47.0185766256,-0.768134737511,192,,Maulévrier,Maulévrier,49,Maine-et-Loire,52,Pays de la Loire
49193,LE MAY SUR EVRE,49122,LE MAY SUR EVRE,,47.1327247986,-0.875212635921,193,Le,May-sur-Èvre,Le May-sur-Èvre,49,Maine-et-Loire,52,Pays de la Loire
49194,MAZE MILON,49140,MAZE MILON,FONTAINE MILON,47.4603950447,-0.284298744303,194,,Mazé-Milon,Mazé-Milon,49,Maine-et-Loire,52,Pays de la Loire
49194,MAZE MILON,49630,MAZE MILON,,47.4603950447,-0.284298744303,194,,Mazé-Milon,Mazé-Milon,49,Maine-et-Loire,52,Pays de la Loire
49195,MAZIERES EN MAUGES,49280,MAZIERES EN MAUGES,,47.05980143,-0.795548412055,195,,Mazières-en-Mauges,Mazières-en-Mauges,49,Maine-et-Loire,52,Pays de la Loire
49197,MEIGNE LE VICOMTE,49490,MEIGNE LE VICOMTE,,47.5125846986,0.188043785469,197,,Meigné-le-Vicomte,Meigné-le-Vicomte,49,Maine-et-Loire,52,Pays de la Loire
49198,MEIGNE,49700,MEIGNE,,47.2424461374,-0.21562378954,198,,Meigné,Meigné,49,Maine-et-Loire,52,Pays de la Loire
49200,LONGUENEE EN ANJOU,49220,LONGUENEE EN ANJOU,PRUILLE,47.5607664036,-0.683468497352,200,,Longuenée-en-Anjou,Longuenée-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49200,LONGUENEE EN ANJOU,49770,LONGUENEE EN ANJOU,LA MEIGNANNE,47.5607664036,-0.683468497352,200,,Longuenée-en-Anjou,Longuenée-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49200,LONGUENEE EN ANJOU,49770,LONGUENEE EN ANJOU,LA MEMBROLLE SUR LONGUENEE,47.5607664036,-0.683468497352,200,,Longuenée-en-Anjou,Longuenée-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49200,LONGUENEE EN ANJOU,49770,LONGUENEE EN ANJOU,LE PLESSIS MACE,47.5607664036,-0.683468497352,200,,Longuenée-en-Anjou,Longuenée-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49201,LA MENITRE,49250,LA MENITRE,,47.4070050575,-0.256671293231,201,La,Ménitré,La Ménitré,49,Maine-et-Loire,52,Pays de la Loire
49202,MEON,49490,MEON,,47.4730078794,0.113522965936,202,,Méon,Méon,49,Maine-et-Loire,52,Pays de la Loire
49205,MIRE,49330,MIRE,,47.7620031933,-0.495000586199,205,,Miré,Miré,49,Maine-et-Loire,52,Pays de la Loire
49207,MONTFORT,49700,MONTFORT,,47.1888469498,-0.215553855338,207,,Montfort,Montfort,49,Maine-et-Loire,52,Pays de la Loire
49208,MONTGUILLON,49500,MONTGUILLON,,47.7346596305,-0.754703527421,208,,Montguillon,Montguillon,49,Maine-et-Loire,52,Pays de la Loire
49209,MONTIGNE LES RAIRIES,49430,MONTIGNE LES RAIRIES,,47.6256194713,-0.214038609082,209,,Montigné-lès-Rairies,Montigné-lès-Rairies,49,Maine-et-Loire,52,Pays de la Loire
49211,MONTILLIERS,49310,MONTILLIERS,,47.1873187077,-0.516413041747,211,,Montilliers,Montilliers,49,Maine-et-Loire,52,Pays de la Loire
49214,MONTREUIL JUIGNE,49460,MONTREUIL JUIGNE,,47.5411858548,-0.616472796467,214,,Montreuil-Juigné,Montreuil-Juigné,49,Maine-et-Loire,52,Pays de la Loire
49214,MONTREUIL JUIGNE,49460,MONTREUIL JUIGNE,JUIGNE BENE,47.5411858548,-0.616472796467,214,,Montreuil-Juigné,Montreuil-Juigné,49,Maine-et-Loire,52,Pays de la Loire
49215,MONTREUIL BELLAY,49260,MONTREUIL BELLAY,,47.1287547622,-0.125278817925,215,,Montreuil-Bellay,Montreuil-Bellay,49,Maine-et-Loire,52,Pays de la Loire
49215,MONTREUIL BELLAY,49260,MONTREUIL BELLAY,MERON,47.1287547622,-0.125278817925,215,,Montreuil-Bellay,Montreuil-Bellay,49,Maine-et-Loire,52,Pays de la Loire
49216,MONTREUIL SUR LOIR,49140,MONTREUIL SUR LOIR,,47.5935379468,-0.391603731287,216,,Montreuil-sur-Loir,Montreuil-sur-Loir,49,Maine-et-Loire,52,Pays de la Loire
49217,MONTREUIL SUR MAINE,49220,MONTREUIL SUR MAINE,,47.6603355661,-0.71140770195,217,,Montreuil-sur-Maine,Montreuil-sur-Maine,49,Maine-et-Loire,52,Pays de la Loire
49218,MONTREVAULT SUR EVRE,49110,MONTREVAULT SUR EVRE,,47.255395404,-1.05160971025,218,,Montrevault-sur-Èvre,Montrevault-sur-Èvre,49,Maine-et-Loire,52,Pays de la Loire
49218,MONTREVAULT SUR EVRE,49110,MONTREVAULT SUR EVRE,CHAUDRON EN MAUGES,47.255395404,-1.05160971025,218,,Montrevault-sur-Èvre,Montrevault-sur-Èvre,49,Maine-et-Loire,52,Pays de la Loire
49218,MONTREVAULT SUR EVRE,49110,MONTREVAULT SUR EVRE,LA BOISSIERE SUR EVRE,47.255395404,-1.05160971025,218,,Montrevault-sur-Èvre,Montrevault-sur-Èvre,49,Maine-et-Loire,52,Pays de la Loire
49218,MONTREVAULT SUR EVRE,49110,MONTREVAULT SUR EVRE,LA SALLE ET CHAPELLE AUBRY,47.255395404,-1.05160971025,218,,Montrevault-sur-Èvre,Montrevault-sur-Èvre,49,Maine-et-Loire,52,Pays de la Loire
49218,MONTREVAULT SUR EVRE,49110,MONTREVAULT SUR EVRE,ST PIERRE MONTLIMART,47.255395404,-1.05160971025,218,,Montrevault-sur-Èvre,Montrevault-sur-Èvre,49,Maine-et-Loire,52,Pays de la Loire
49218,MONTREVAULT SUR EVRE,49110,MONTREVAULT SUR EVRE,ST QUENTIN EN MAUGES,47.255395404,-1.05160971025,218,,Montrevault-sur-Èvre,Montrevault-sur-Èvre,49,Maine-et-Loire,52,Pays de la Loire
49218,MONTREVAULT SUR EVRE,49110,MONTREVAULT SUR EVRE,ST REMY EN MAUGES,47.255395404,-1.05160971025,218,,Montrevault-sur-Èvre,Montrevault-sur-Èvre,49,Maine-et-Loire,52,Pays de la Loire
49218,MONTREVAULT SUR EVRE,49270,MONTREVAULT SUR EVRE,LE FUILET,47.255395404,-1.05160971025,218,,Montrevault-sur-Èvre,Montrevault-sur-Èvre,49,Maine-et-Loire,52,Pays de la Loire
49218,MONTREVAULT SUR EVRE,49600,MONTREVAULT SUR EVRE,LA CHAUSSAIRE,47.255395404,-1.05160971025,218,,Montrevault-sur-Èvre,Montrevault-sur-Èvre,49,Maine-et-Loire,52,Pays de la Loire
49218,MONTREVAULT SUR EVRE,49600,MONTREVAULT SUR EVRE,LE FIEF SAUVIN,47.255395404,-1.05160971025,218,,Montrevault-sur-Èvre,Montrevault-sur-Èvre,49,Maine-et-Loire,52,Pays de la Loire
49218,MONTREVAULT SUR EVRE,49600,MONTREVAULT SUR EVRE,LE PUISET DORE,47.255395404,-1.05160971025,218,,Montrevault-sur-Èvre,Montrevault-sur-Èvre,49,Maine-et-Loire,52,Pays de la Loire
49219,MONTSOREAU,49730,MONTSOREAU,,47.2135013304,0.0519492185178,219,,Montsoreau,Montsoreau,49,Maine-et-Loire,52,Pays de la Loire
49220,MORANNES SUR SARTHE,49640,MORANNES SUR SARTHE,,47.725500783,-0.403190245491,220,,Morannes sur Sarthe-Daumeray,Morannes sur Sarthe-Daumeray,49,Maine-et-Loire,52,Pays de la Loire
49220,MORANNES SUR SARTHE,49640,MORANNES SUR SARTHE,CHEMIRE SUR SARTHE,47.725500783,-0.403190245491,220,,Morannes sur Sarthe-Daumeray,Morannes sur Sarthe-Daumeray,49,Maine-et-Loire,52,Pays de la Loire
49221,MOULIHERNE,49390,MOULIHERNE,,47.4531278968,0.0130885602791,221,,Mouliherne,Mouliherne,49,Maine-et-Loire,52,Pays de la Loire
49222,MOZE SUR LOUET,49610,MOZE SUR LOUET,,47.3588616971,-0.568969169001,222,,Mozé-sur-Louet,Mozé-sur-Louet,49,Maine-et-Loire,52,Pays de la Loire
49223,MURS ERIGNE,49610,MURS ERIGNE,,47.3952383531,-0.553551118702,223,,Mûrs-Erigné,Mûrs-Erigné,49,Maine-et-Loire,52,Pays de la Loire
49224,NEUILLE,49680,NEUILLE,,47.3363897029,-0.0184526935141,224,,Neuillé,Neuillé,49,Maine-et-Loire,52,Pays de la Loire
49226,NOELLET,49520,NOELLET,,47.6939204743,-1.08973286033,226,,Noëllet,Noëllet,49,Maine-et-Loire,52,Pays de la Loire
49227,NOTRE DAME D ALLENCON,49380,NOTRE DAME D ALLENCON,,47.2987148996,-0.466445239668,227,,Notre-Dame-d'Allençon,Notre-Dame-d'Allençon,49,Maine-et-Loire,52,Pays de la Loire
49228,NOYANT,49490,NOYANT,,47.5070164198,0.109706678066,228,,Noyant-Villages,Noyant-Villages,49,Maine-et-Loire,52,Pays de la Loire
49229,NOYANT LA GRAVOYERE,49520,NOYANT LA GRAVOYERE,,47.7066159584,-0.959577298346,229,,Noyant-la-Gravoyère,Noyant-la-Gravoyère,49,Maine-et-Loire,52,Pays de la Loire
49231,NUAILLE,49340,NUAILLE,,47.0863010668,-0.777309742731,231,,Nuaillé,Nuaillé,49,Maine-et-Loire,52,Pays de la Loire
49233,NYOISEAU,49500,NYOISEAU,,47.7107860625,-0.907508451549,233,,Nyoiseau,Nyoiseau,49,Maine-et-Loire,52,Pays de la Loire
49234,PARCAY LES PINS,49390,PARCAY LES PINS,,47.4314188846,0.14445317827,234,,Parçay-les-Pins,Parçay-les-Pins,49,Maine-et-Loire,52,Pays de la Loire
49235,PARNAY,49730,PARNAY,,47.2209572382,0.00402105248715,235,,Parnay,Parnay,49,Maine-et-Loire,52,Pays de la Loire
49236,PASSAVANT SUR LAYON,49560,PASSAVANT SUR LAYON,,47.1007325389,-0.383422642271,236,,Passavant-sur-Layon,Passavant-sur-Layon,49,Maine-et-Loire,52,Pays de la Loire
49237,LA PELLERINE,49490,LA PELLERINE,,47.459103434,0.131469619906,237,La,Pellerine,La Pellerine,49,Maine-et-Loire,52,Pays de la Loire
49240,LA PLAINE,49360,LA PLAINE,,47.0673907245,-0.628869263252,240,La,Plaine,La Plaine,49,Maine-et-Loire,52,Pays de la Loire
49241,LE PLESSIS GRAMMOIRE,49124,LE PLESSIS GRAMMOIRE,,47.4923950219,-0.436285134541,241,Le,Plessis-Grammoire,Le Plessis-Grammoire,49,Maine-et-Loire,52,Pays de la Loire
49244,MAUGES SUR LOIRE,49110,MAUGES SUR LOIRE,BOTZ EN MAUGES,47.3429800481,-0.869186945487,244,,Mauges-sur-Loire,Mauges-sur-Loire,49,Maine-et-Loire,52,Pays de la Loire
49244,MAUGES SUR LOIRE,49290,MAUGES SUR LOIRE,BOURGNEUF EN MAUGES,47.3429800481,-0.869186945487,244,,Mauges-sur-Loire,Mauges-sur-Loire,49,Maine-et-Loire,52,Pays de la Loire
49244,MAUGES SUR LOIRE,49290,MAUGES SUR LOIRE,ST LAURENT DE LA PLAINE,47.3429800481,-0.869186945487,244,,Mauges-sur-Loire,Mauges-sur-Loire,49,Maine-et-Loire,52,Pays de la Loire
49244,MAUGES SUR LOIRE,49410,MAUGES SUR LOIRE,BEAUSSE,47.3429800481,-0.869186945487,244,,Mauges-sur-Loire,Mauges-sur-Loire,49,Maine-et-Loire,52,Pays de la Loire
49244,MAUGES SUR LOIRE,49410,MAUGES SUR LOIRE,LA CHAPELLE ST FLORENT,47.3429800481,-0.869186945487,244,,Mauges-sur-Loire,Mauges-sur-Loire,49,Maine-et-Loire,52,Pays de la Loire
49244,MAUGES SUR LOIRE,49410,MAUGES SUR LOIRE,LE MARILLAIS,47.3429800481,-0.869186945487,244,,Mauges-sur-Loire,Mauges-sur-Loire,49,Maine-et-Loire,52,Pays de la Loire
49244,MAUGES SUR LOIRE,49410,MAUGES SUR LOIRE,LE MESNIL EN VALLEE,47.3429800481,-0.869186945487,244,,Mauges-sur-Loire,Mauges-sur-Loire,49,Maine-et-Loire,52,Pays de la Loire
49244,MAUGES SUR LOIRE,49410,MAUGES SUR LOIRE,ST FLORENT LE VIEIL,47.3429800481,-0.869186945487,244,,Mauges-sur-Loire,Mauges-sur-Loire,49,Maine-et-Loire,52,Pays de la Loire
49244,MAUGES SUR LOIRE,49410,MAUGES SUR LOIRE,ST LAURENT DU MOTTAY,47.3429800481,-0.869186945487,244,,Mauges-sur-Loire,Mauges-sur-Loire,49,Maine-et-Loire,52,Pays de la Loire
49244,MAUGES SUR LOIRE,49570,MAUGES SUR LOIRE,MONTJEAN SUR LOIRE,47.3429800481,-0.869186945487,244,,Mauges-sur-Loire,Mauges-sur-Loire,49,Maine-et-Loire,52,Pays de la Loire
49244,MAUGES SUR LOIRE,49620,MAUGES SUR LOIRE,LA POMMERAYE,47.3429800481,-0.869186945487,244,,Mauges-sur-Loire,Mauges-sur-Loire,49,Maine-et-Loire,52,Pays de la Loire
49246,LES PONTS DE CE,49130,LES PONTS DE CE,,47.4272536106,-0.512677371617,246,Les,Ponts-de-Cé,Les Ponts-de-Cé,49,Maine-et-Loire,52,Pays de la Loire
49246,LES PONTS DE CE,49130,LES PONTS DE CE,SORGES,47.4272536106,-0.512677371617,246,Les,Ponts-de-Cé,Les Ponts-de-Cé,49,Maine-et-Loire,52,Pays de la Loire
49247,LA POSSONNIERE,49170,LA POSSONNIERE,,47.3814407983,-0.708738811093,247,La,Possonnière,La Possonnière,49,Maine-et-Loire,52,Pays de la Loire
49248,POUANCE,49420,POUANCE,,47.7629344972,-1.19300189799,248,,Ombrée d'Anjou,Ombrée d'Anjou,49,Maine-et-Loire,52,Pays de la Loire
49250,LA PREVIERE,49420,LA PREVIERE,,47.7196258054,-1.17847184047,250,La,Prévière,La Prévière,49,Maine-et-Loire,52,Pays de la Loire
49253,LE PUY NOTRE DAME,49260,LE PUY NOTRE DAME,,47.1140698471,-0.229006138131,253,Le,Puy-Notre-Dame,Le Puy-Notre-Dame,49,Maine-et-Loire,52,Pays de la Loire
49254,QUERRE,49330,QUERRE,,47.6744801615,-0.613825669743,254,,Querré,Querré,49,Maine-et-Loire,52,Pays de la Loire
49257,LES RAIRIES,49430,LES RAIRIES,,47.6517641258,-0.216889906219,257,Les,Rairies,Les Rairies,49,Maine-et-Loire,52,Pays de la Loire
49259,ROCHEFORT SUR LOIRE,49190,ROCHEFORT SUR LOIRE,,47.3508366008,-0.65047354418,259,,Rochefort-sur-Loire,Rochefort-sur-Loire,49,Maine-et-Loire,52,Pays de la Loire
49260,LA ROMAGNE,49740,LA ROMAGNE,,47.0543066107,-1.00996778066,260,La,Romagne,La Romagne,49,Maine-et-Loire,52,Pays de la Loire
49261,LES ROSIERS SUR LOIRE,49350,LES ROSIERS SUR LOIRE,,47.374115152,-0.222562814963,261,,Gennes-Val-de-Loire,Gennes-Val-de-Loire,49,Maine-et-Loire,52,Pays de la Loire
49262,ROU MARSON,49400,ROU MARSON,,47.2445767064,-0.151599974844,262,,Rou-Marson,Rou-Marson,49,Maine-et-Loire,52,Pays de la Loire
49266,ST AUGUSTIN DES BOIS,49170,ST AUGUSTIN DES BOIS,,47.4545053471,-0.800399129373,266,,Saint-Augustin-des-Bois,Saint-Augustin-des-Bois,49,Maine-et-Loire,52,Pays de la Loire
49267,ST BARTHELEMY D ANJOU,49124,ST BARTHELEMY D ANJOU,,47.4738114322,-0.485526012217,267,,Saint-Barthélemy-d'Anjou,Saint-Barthélemy-d'Anjou,49,Maine-et-Loire,52,Pays de la Loire
49269,ST CHRISTOPHE DU BOIS,49280,ST CHRISTOPHE DU BOIS,,47.0291789736,-0.965837562307,269,,Saint-Christophe-du-Bois,Saint-Christophe-du-Bois,49,Maine-et-Loire,52,Pays de la Loire
49271,ST CLEMENT DE LA PLACE,49370,ST CLEMENT DE LA PLACE,,47.5263429934,-0.734122843325,271,,Saint-Clément-de-la-Place,Saint-Clément-de-la-Place,49,Maine-et-Loire,52,Pays de la Loire
49272,ST CLEMENT DES LEVEES,49350,ST CLEMENT DES LEVEES,,47.3443059131,-0.182503440633,272,,Saint-Clément-des-Levées,Saint-Clément-des-Levées,49,Maine-et-Loire,52,Pays de la Loire
49274,ST CYR EN BOURG,49260,ST CYR EN BOURG,,47.1946036975,-0.0504196154812,274,,Saint-Cyr-en-Bourg,Saint-Cyr-en-Bourg,49,Maine-et-Loire,52,Pays de la Loire
49277,STE GEMMES D ANDIGNE,49500,STE GEMMES D ANDIGNE,,47.6660928171,-0.895241558075,277,,Sainte-Gemmes-d'Andigné,Sainte-Gemmes-d'Andigné,49,Maine-et-Loire,52,Pays de la Loire
49278,STE GEMMES SUR LOIRE,49130,STE GEMMES SUR LOIRE,,47.4281722672,-0.575792781781,278,,Sainte-Gemmes-sur-Loire,Sainte-Gemmes-sur-Loire,49,Maine-et-Loire,52,Pays de la Loire
49282,ST GEORGES SUR LAYON,49700,ST GEORGES SUR LAYON,,47.195832374,-0.382785659282,282,,Saint-Georges-sur-Layon,Saint-Georges-sur-Layon,49,Maine-et-Loire,52,Pays de la Loire
49283,ST GEORGES SUR LOIRE,49170,ST GEORGES SUR LOIRE,,47.4024678993,-0.756078071904,283,,Saint-Georges-sur-Loire,Saint-Georges-sur-Loire,49,Maine-et-Loire,52,Pays de la Loire
49284,ST GERMAIN DES PRES,49170,ST GERMAIN DES PRES,,47.4104988844,-0.812032158291,284,,Saint-Germain-des-Prés,Saint-Germain-des-Prés,49,Maine-et-Loire,52,Pays de la Loire
49288,ST JEAN DE LA CROIX,49130,ST JEAN DE LA CROIX,,47.4108613803,-0.588351617749,288,,Saint-Jean-de-la-Croix,Saint-Jean-de-la-Croix,49,Maine-et-Loire,52,Pays de la Loire
49289,ST JEAN DE LINIERES,49070,ST JEAN DE LINIERES,,47.4586878354,-0.674607472395,289,,Saint-Jean-de-Linières,Saint-Jean-de-Linières,49,Maine-et-Loire,52,Pays de la Loire
49290,ST JEAN DES MAUVRETS,49320,ST JEAN DES MAUVRETS,,47.3849442681,-0.460307336678,290,,Saint-Jean-des-Mauvrets,Saint-Jean-des-Mauvrets,49,Maine-et-Loire,52,Pays de la Loire
49291,ST JUST SUR DIVE,49260,ST JUST SUR DIVE,,47.1722921725,-0.0995345523201,291,,Saint-Just-sur-Dive,Saint-Just-sur-Dive,49,Maine-et-Loire,52,Pays de la Loire
49292,VAL DU LAYON,49190,VAL DU LAYON,ST AUBIN DE LUIGNE,47.3002199454,-0.640314853216,292,,Val-du-Layon,Val-du-Layon,49,Maine-et-Loire,52,Pays de la Loire
49292,VAL DU LAYON,49750,VAL DU LAYON,ST LAMBERT DU LATTAY,47.3002199454,-0.640314853216,292,,Val-du-Layon,Val-du-Layon,49,Maine-et-Loire,52,Pays de la Loire
49294,ST LAMBERT LA POTHERIE,49070,ST LAMBERT LA POTHERIE,,47.4862725971,-0.690818663562,294,,Saint-Lambert-la-Potherie,Saint-Lambert-la-Potherie,49,Maine-et-Loire,52,Pays de la Loire
49298,ST LEGER DES BOIS,49170,ST LEGER DES BOIS,,47.4625011515,-0.727690848988,298,,Saint-Léger-des-Bois,Saint-Léger-des-Bois,49,Maine-et-Loire,52,Pays de la Loire
49299,ST LEGER SOUS CHOLET,49280,ST LEGER SOUS CHOLET,,47.1022853762,-0.904193715243,299,,Saint-Léger-sous-Cholet,Saint-Léger-sous-Cholet,49,Maine-et-Loire,52,Pays de la Loire
49301,SEVREMOINE,49230,SEVREMOINE,MONTFAUCON MONTIGNE,47.1256560392,-0.998341815021,301,,Sèvremoine,Sèvremoine,49,Maine-et-Loire,52,Pays de la Loire
49301,SEVREMOINE,49230,SEVREMOINE,MONTIGNE SUR MOINE,47.1256560392,-0.998341815021,301,,Sèvremoine,Sèvremoine,49,Maine-et-Loire,52,Pays de la Loire
49301,SEVREMOINE,49230,SEVREMOINE,ST CRESPIN SUR MOINE,47.1256560392,-0.998341815021,301,,Sèvremoine,Sèvremoine,49,Maine-et-Loire,52,Pays de la Loire
49301,SEVREMOINE,49230,SEVREMOINE,ST GERMAIN SUR MOINE,47.1256560392,-0.998341815021,301,,Sèvremoine,Sèvremoine,49,Maine-et-Loire,52,Pays de la Loire
49301,SEVREMOINE,49230,SEVREMOINE,TILLIERES,47.1256560392,-0.998341815021,301,,Sèvremoine,Sèvremoine,49,Maine-et-Loire,52,Pays de la Loire
49301,SEVREMOINE,49450,SEVREMOINE,LA RENAUDIERE,47.1256560392,-0.998341815021,301,,Sèvremoine,Sèvremoine,49,Maine-et-Loire,52,Pays de la Loire
49301,SEVREMOINE,49450,SEVREMOINE,ROUSSAY,47.1256560392,-0.998341815021,301,,Sèvremoine,Sèvremoine,49,Maine-et-Loire,52,Pays de la Loire
49301,SEVREMOINE,49450,SEVREMOINE,ST ANDRE DE LA MARCHE,47.1256560392,-0.998341815021,301,,Sèvremoine,Sèvremoine,49,Maine-et-Loire,52,Pays de la Loire
49301,SEVREMOINE,49450,SEVREMOINE,ST MACAIRE EN MAUGES,47.1256560392,-0.998341815021,301,,Sèvremoine,Sèvremoine,49,Maine-et-Loire,52,Pays de la Loire
49301,SEVREMOINE,49660,SEVREMOINE,TORFOU,47.1256560392,-0.998341815021,301,,Sèvremoine,Sèvremoine,49,Maine-et-Loire,52,Pays de la Loire
49301,SEVREMOINE,49710,SEVREMOINE,LE LONGERON,47.1256560392,-0.998341815021,301,,Sèvremoine,Sèvremoine,49,Maine-et-Loire,52,Pays de la Loire
49302,ST MACAIRE DU BOIS,49260,ST MACAIRE DU BOIS,,47.1133394452,-0.289626991067,302,,Saint-Macaire-du-Bois,Saint-Macaire-du-Bois,49,Maine-et-Loire,52,Pays de la Loire
49304,ST MARTIN DE LA PLACE,49160,ST MARTIN DE LA PLACE,,47.3188388032,-0.137613576826,304,,Saint-Martin-de-la-Place,Saint-Martin-de-la-Place,49,Maine-et-Loire,52,Pays de la Loire
49305,ST MARTIN DU BOIS,49500,ST MARTIN DU BOIS,,47.69876152,-0.73599285508,305,,Saint-Martin-du-Bois,Saint-Martin-du-Bois,49,Maine-et-Loire,52,Pays de la Loire
49306,ST MARTIN DU FOUILLOUX,49170,ST MARTIN DU FOUILLOUX,,47.4370289729,-0.708731973455,306,,Saint-Martin-du-Fouilloux,Saint-Martin-du-Fouilloux,49,Maine-et-Loire,52,Pays de la Loire
49307,LOIRE AUTHION,49140,LOIRE AUTHION,BAUNE,47.4219645317,-0.319673646947,307,,Loire-Authion,Loire-Authion,49,Maine-et-Loire,52,Pays de la Loire
49307,LOIRE AUTHION,49250,LOIRE AUTHION,ST MATHURIN SUR LOIRE,47.4219645317,-0.319673646947,307,,Loire-Authion,Loire-Authion,49,Maine-et-Loire,52,Pays de la Loire
49307,LOIRE AUTHION,49630,LOIRE AUTHION,CORNE,47.4219645317,-0.319673646947,307,,Loire-Authion,Loire-Authion,49,Maine-et-Loire,52,Pays de la Loire
49307,LOIRE AUTHION,49800,LOIRE AUTHION,ANDARD,47.4219645317,-0.319673646947,307,,Loire-Authion,Loire-Authion,49,Maine-et-Loire,52,Pays de la Loire
49307,LOIRE AUTHION,49800,LOIRE AUTHION,BRAIN SUR L AUTHION,47.4219645317,-0.319673646947,307,,Loire-Authion,Loire-Authion,49,Maine-et-Loire,52,Pays de la Loire
49307,LOIRE AUTHION,49800,LOIRE AUTHION,LA BOHALLE,47.4219645317,-0.319673646947,307,,Loire-Authion,Loire-Authion,49,Maine-et-Loire,52,Pays de la Loire
49307,LOIRE AUTHION,49800,LOIRE AUTHION,LA DAGUENIERE,47.4219645317,-0.319673646947,307,,Loire-Authion,Loire-Authion,49,Maine-et-Loire,52,Pays de la Loire
49308,ST MELAINE SUR AUBANCE,49610,ST MELAINE SUR AUBANCE,,47.3818008704,-0.50194349373,308,,Saint-Melaine-sur-Aubance,Saint-Melaine-sur-Aubance,49,Maine-et-Loire,52,Pays de la Loire
49309,ST MICHEL ET CHANVEAUX,49420,ST MICHEL ET CHANVEAUX,,47.6724747817,-1.14243087198,309,,Saint-Michel-et-Chanveaux,Saint-Michel-et-Chanveaux,49,Maine-et-Loire,52,Pays de la Loire
49310,ST PAUL DU BOIS,49310,ST PAUL DU BOIS,,47.0892502591,-0.541920339488,310,,Saint-Paul-du-Bois,Saint-Paul-du-Bois,49,Maine-et-Loire,52,Pays de la Loire
49311,ST PHILBERT DU PEUPLE,49160,ST PHILBERT DU PEUPLE,,47.3908362181,-0.0435917623377,311,,Saint-Philbert-du-Peuple,Saint-Philbert-du-Peuple,49,Maine-et-Loire,52,Pays de la Loire
49317,ST REMY LA VARENNE,49250,ST REMY LA VARENNE,,47.3878545887,-0.31928686518,317,,Saint-Rémy-la-Varenne,Saint-Rémy-la-Varenne,49,Maine-et-Loire,52,Pays de la Loire
49318,ST SATURNIN SUR LOIRE,49320,ST SATURNIN SUR LOIRE,,47.3839004642,-0.429149450456,318,,Saint-Saturnin-sur-Loire,Saint-Saturnin-sur-Loire,49,Maine-et-Loire,52,Pays de la Loire
49319,ST SAUVEUR DE FLEE,49500,ST SAUVEUR DE FLEE,,47.7500623776,-0.807873128349,319,,Saint-Sauveur-de-Flée,Saint-Sauveur-de-Flée,49,Maine-et-Loire,52,Pays de la Loire
49321,ST SIGISMOND,49123,ST SIGISMOND,,47.4512573953,-0.942796446768,321,,Saint-Sigismond,Saint-Sigismond,49,Maine-et-Loire,52,Pays de la Loire
49323,VERRIERES EN ANJOU,49112,VERRIERES EN ANJOU,PELLOUAILLES LES VIGNES,47.5123317726,-0.475837998559,323,,Verrières-en-Anjou,Verrières-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49323,VERRIERES EN ANJOU,49480,VERRIERES EN ANJOU,ST SYLVAIN D ANJOU,47.5123317726,-0.475837998559,323,,Verrières-en-Anjou,Verrières-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49326,SARRIGNE,49800,SARRIGNE,,47.5024740958,-0.385798724443,326,,Sarrigné,Sarrigné,49,Maine-et-Loire,52,Pays de la Loire
49327,SAULGE L HOPITAL,49320,SAULGE L HOPITAL,,47.3040326825,-0.379973297535,327,,Saulgé-l'Hôpital,Saulgé-l'Hôpital,49,Maine-et-Loire,52,Pays de la Loire
49328,SAUMUR,49400,SAUMUR,,47.2673853525,-0.0830410591988,328,,Saumur,Saumur,49,Maine-et-Loire,52,Pays de la Loire
49328,SAUMUR,49400,SAUMUR,BAGNEUX,47.2673853525,-0.0830410591988,328,,Saumur,Saumur,49,Maine-et-Loire,52,Pays de la Loire
49328,SAUMUR,49400,SAUMUR,DAMPIERRE SUR LOIRE,47.2673853525,-0.0830410591988,328,,Saumur,Saumur,49,Maine-et-Loire,52,Pays de la Loire
49328,SAUMUR,49400,SAUMUR,ST HILAIRE ST FLORENT,47.2673853525,-0.0830410591988,328,,Saumur,Saumur,49,Maine-et-Loire,52,Pays de la Loire
49328,SAUMUR,49400,SAUMUR,ST LAMBERT DES LEVEES,47.2673853525,-0.0830410591988,328,,Saumur,Saumur,49,Maine-et-Loire,52,Pays de la Loire
49329,SAVENNIERES,49170,SAVENNIERES,,47.405865718,-0.667155831696,329,,Savennières,Savennières,49,Maine-et-Loire,52,Pays de la Loire
49329,SAVENNIERES,49170,SAVENNIERES,EPIRE,47.405865718,-0.667155831696,329,,Savennières,Savennières,49,Maine-et-Loire,52,Pays de la Loire
49330,SCEAUX D ANJOU,49330,SCEAUX D ANJOU,,47.6313080266,-0.60969581753,330,,Sceaux-d'Anjou,Sceaux-d'Anjou,49,Maine-et-Loire,52,Pays de la Loire
49331,SEGRE,49500,SEGRE,,47.7016654227,-0.863354104119,331,,Segré-en-Anjou Bleu,Segré-en-Anjou Bleu,49,Maine-et-Loire,52,Pays de la Loire
49332,LA SEGUINIERE,49280,LA SEGUINIERE,,47.0803772753,-0.957722131472,332,La,Séguinière,La Séguinière,49,Maine-et-Loire,52,Pays de la Loire
49333,SEICHES SUR LE LOIR,49140,SEICHES SUR LE LOIR,,47.6039171793,-0.358897551801,333,,Seiches-sur-le-Loir,Seiches-sur-le-Loir,49,Maine-et-Loire,52,Pays de la Loire
49333,SEICHES SUR LE LOIR,49140,SEICHES SUR LE LOIR,MATHEFLON,47.6039171793,-0.358897551801,333,,Seiches-sur-le-Loir,Seiches-sur-le-Loir,49,Maine-et-Loire,52,Pays de la Loire
49334,SERMAISE,49140,SERMAISE,,47.522658847,-0.215904161416,334,,Sermaise,Sermaise,49,Maine-et-Loire,52,Pays de la Loire
49335,SOEURDRES,49330,SOEURDRES,,47.7408689927,-0.570923085358,335,,Sœurdres,Sœurdres,49,Maine-et-Loire,52,Pays de la Loire
49336,SOMLOIRE,49360,SOMLOIRE,,47.0353125936,-0.595627270209,336,,Somloire,Somloire,49,Maine-et-Loire,52,Pays de la Loire
49337,SOUCELLES,49140,SOUCELLES,,47.582728286,-0.425395379882,337,,Soucelles,Soucelles,49,Maine-et-Loire,52,Pays de la Loire
49338,SOULAINES SUR AUBANCE,49610,SOULAINES SUR AUBANCE,,47.3590850983,-0.519604593269,338,,Soulaines-sur-Aubance,Soulaines-sur-Aubance,49,Maine-et-Loire,52,Pays de la Loire
49339,SOULAIRE ET BOURG,49460,SOULAIRE ET BOURG,,47.5798701651,-0.542933611394,339,,Soulaire-et-Bourg,Soulaire-et-Bourg,49,Maine-et-Loire,52,Pays de la Loire
49341,SOUZAY CHAMPIGNY,49400,SOUZAY CHAMPIGNY,,47.2170632945,-0.0207542806981,341,,Souzay-Champigny,Souzay-Champigny,49,Maine-et-Loire,52,Pays de la Loire
49343,LA TESSOUALLE,49280,LA TESSOUALLE,,47.0080969032,-0.83728849312,343,La,Tessoualle,La Tessoualle,49,Maine-et-Loire,52,Pays de la Loire
49344,THORIGNE D ANJOU,49220,THORIGNE D ANJOU,,47.6350974159,-0.663556490698,344,,Thorigné-d'Anjou,Thorigné-d'Anjou,49,Maine-et-Loire,52,Pays de la Loire
49345,BELLEVIGNE EN LAYON,49380,BELLEVIGNE EN LAYON,CHAMP SUR LAYON,47.2592682331,-0.517923916545,345,,Bellevigne-en-Layon,Bellevigne-en-Layon,49,Maine-et-Loire,52,Pays de la Loire
49345,BELLEVIGNE EN LAYON,49380,BELLEVIGNE EN LAYON,FAVERAYE MACHELLES,47.2592682331,-0.517923916545,345,,Bellevigne-en-Layon,Bellevigne-en-Layon,49,Maine-et-Loire,52,Pays de la Loire
49345,BELLEVIGNE EN LAYON,49380,BELLEVIGNE EN LAYON,FAYE D ANJOU,47.2592682331,-0.517923916545,345,,Bellevigne-en-Layon,Bellevigne-en-Layon,49,Maine-et-Loire,52,Pays de la Loire
49345,BELLEVIGNE EN LAYON,49380,BELLEVIGNE EN LAYON,THOUARCE,47.2592682331,-0.517923916545,345,,Bellevigne-en-Layon,Bellevigne-en-Layon,49,Maine-et-Loire,52,Pays de la Loire
49345,BELLEVIGNE EN LAYON,49750,BELLEVIGNE EN LAYON,RABLAY SUR LAYON,47.2592682331,-0.517923916545,345,,Bellevigne-en-Layon,Bellevigne-en-Layon,49,Maine-et-Loire,52,Pays de la Loire
49347,TIERCE,49125,TIERCE,,47.6245599519,-0.44344301136,347,,Tiercé,Tiercé,49,Maine-et-Loire,52,Pays de la Loire
49352,TOUTLEMONDE,49360,TOUTLEMONDE,,47.0574145416,-0.75844602531,352,,Toutlemonde,Toutlemonde,49,Maine-et-Loire,52,Pays de la Loire
49353,TRELAZE,49800,TRELAZE,,47.451357179,-0.473458788346,353,,Trélazé,Trélazé,49,Maine-et-Loire,52,Pays de la Loire
49354,LE TREMBLAY,49520,LE TREMBLAY,,47.6704965475,-1.04359451042,354,Le,Tremblay,Le Tremblay,49,Maine-et-Loire,52,Pays de la Loire
49355,TREMENTINES,49340,TREMENTINES,,47.1227107975,-0.801475622875,355,,Trémentines,Trémentines,49,Maine-et-Loire,52,Pays de la Loire
49358,TURQUANT,49730,TURQUANT,,47.2114118968,0.0218791736623,358,,Turquant,Turquant,49,Maine-et-Loire,52,Pays de la Loire
49359,LES ULMES,49700,LES ULMES,,47.2234666455,-0.18231147008,359,Les,Ulmes,Les Ulmes,49,Maine-et-Loire,52,Pays de la Loire
49361,VARENNES SUR LOIRE,49730,VARENNES SUR LOIRE,,47.247861704,0.0416546487257,361,,Varennes-sur-Loire,Varennes-sur-Loire,49,Maine-et-Loire,52,Pays de la Loire
49362,VARRAINS,49400,VARRAINS,,47.2277631144,-0.0662293398772,362,,Varrains,Varrains,49,Maine-et-Loire,52,Pays de la Loire
49363,VAUCHRETIEN,49320,VAUCHRETIEN,,47.3384739558,-0.485141925888,363,,Vauchrétien,Vauchrétien,49,Maine-et-Loire,52,Pays de la Loire
49364,VAUDELNAY,49260,VAUDELNAY,,47.1411198846,-0.212881943742,364,,Vaudelnay,Vaudelnay,49,Maine-et-Loire,52,Pays de la Loire
49365,LES VERCHERS SUR LAYON,49700,LES VERCHERS SUR LAYON,,47.1469610454,-0.302089800221,365,Les,Verchers-sur-Layon,Les Verchers-sur-Layon,49,Maine-et-Loire,52,Pays de la Loire
49366,VERGONNES,49420,VERGONNES,,47.7250972893,-1.07837805923,366,,Vergonnes,Vergonnes,49,Maine-et-Loire,52,Pays de la Loire
49367,ERDRE EN ANJOU,49220,ERDRE EN ANJOU,BRAIN SUR LONGUENEE,47.5882203908,-0.849650865368,367,,Erdre-en-Anjou,Erdre-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49367,ERDRE EN ANJOU,49220,ERDRE EN ANJOU,GENE,47.5882203908,-0.849650865368,367,,Erdre-en-Anjou,Erdre-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49367,ERDRE EN ANJOU,49220,ERDRE EN ANJOU,VERN D ANJOU,47.5882203908,-0.849650865368,367,,Erdre-en-Anjou,Erdre-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49367,ERDRE EN ANJOU,49370,ERDRE EN ANJOU,LA POUEZE,47.5882203908,-0.849650865368,367,,Erdre-en-Anjou,Erdre-en-Anjou,49,Maine-et-Loire,52,Pays de la Loire
49368,VERNANTES,49390,VERNANTES,,47.3942457572,0.0285780026834,368,,Vernantes,Vernantes,49,Maine-et-Loire,52,Pays de la Loire
49369,VERNOIL LE FOURRIER,49390,VERNOIL LE FOURRIER,,47.3961591368,0.097374002532,369,,Vernoil-le-Fourrier,Vernoil-le-Fourrier,49,Maine-et-Loire,52,Pays de la Loire
49370,VERRIE,49400,VERRIE,,47.2685065312,-0.186628431321,370,,Verrie,Verrie,49,Maine-et-Loire,52,Pays de la Loire
49371,VEZINS,49340,VEZINS,,47.1152806742,-0.721207267194,371,,Vezins,Vezins,49,Maine-et-Loire,52,Pays de la Loire
49373,LYS HAUT LAYON,49310,LYS HAUT LAYON,LES CERQUEUX SOUS PASSAVANT,47.1373301524,-0.559450064005,373,,Lys-Haut-Layon,Lys-Haut-Layon,49,Maine-et-Loire,52,Pays de la Loire
49373,LYS HAUT LAYON,49310,LYS HAUT LAYON,LE VOIDE,47.1373301524,-0.559450064005,373,,Lys-Haut-Layon,Lys-Haut-Layon,49,Maine-et-Loire,52,Pays de la Loire
49373,LYS HAUT LAYON,49310,LYS HAUT LAYON,ST HILAIRE DU BOIS,47.1373301524,-0.559450064005,373,,Lys-Haut-Layon,Lys-Haut-Layon,49,Maine-et-Loire,52,Pays de la Loire
49373,LYS HAUT LAYON,49310,LYS HAUT LAYON,TANCOIGNE,47.1373301524,-0.559450064005,373,,Lys-Haut-Layon,Lys-Haut-Layon,49,Maine-et-Loire,52,Pays de la Loire
49373,LYS HAUT LAYON,49310,LYS HAUT LAYON,TREMONT,47.1373301524,-0.559450064005,373,,Lys-Haut-Layon,Lys-Haut-Layon,49,Maine-et-Loire,52,Pays de la Loire
49373,LYS HAUT LAYON,49310,LYS HAUT LAYON,VIHIERS,47.1373301524,-0.559450064005,373,,Lys-Haut-Layon,Lys-Haut-Layon,49,Maine-et-Loire,52,Pays de la Loire
49373,LYS HAUT LAYON,49540,LYS HAUT LAYON,LA FOSSE DE TIGNE,47.1373301524,-0.559450064005,373,,Lys-Haut-Layon,Lys-Haut-Layon,49,Maine-et-Loire,52,Pays de la Loire
49373,LYS HAUT LAYON,49540,LYS HAUT LAYON,TIGNE,47.1373301524,-0.559450064005,373,,Lys-Haut-Layon,Lys-Haut-Layon,49,Maine-et-Loire,52,Pays de la Loire
49373,LYS HAUT LAYON,49560,LYS HAUT LAYON,NUEIL SUR LAYON,47.1373301524,-0.559450064005,373,,Lys-Haut-Layon,Lys-Haut-Layon,49,Maine-et-Loire,52,Pays de la Loire
49374,VILLEBERNIER,49400,VILLEBERNIER,,47.2624126293,-0.0208277166686,374,,Villebernier,Villebernier,49,Maine-et-Loire,52,Pays de la Loire
49376,VILLEMOISAN,49370,VILLEMOISAN,,47.4710319772,-0.890055215604,376,,Villemoisan,Villemoisan,49,Maine-et-Loire,52,Pays de la Loire
49377,VILLEVEQUE,49140,VILLEVEQUE,,47.5486542844,-0.44694039674,377,,Villevêque,Villevêque,49,Maine-et-Loire,52,Pays de la Loire
49378,VIVY,49680,VIVY,,47.3228206835,-0.0669608639815,378,,Vivy,Vivy,49,Maine-et-Loire,52,Pays de la Loire
49381,YZERNAY,49360,YZERNAY,,47.0344970546,-0.696855883888,381,,Yzernay,Yzernay,49,Maine-et-Loire,52,Pays de la Loire
50001,ACQUEVILLE,50440,ACQUEVILLE,,49.6112667941,-1.74961794435,1,,Acqueville,Acqueville,50,Manche,28,Normandie
50002,AGNEAUX,50180,AGNEAUX,,49.1154902114,-1.12991373053,2,,Agneaux,Agneaux,50,Manche,28,Normandie
50003,AGON COUTAINVILLE,50230,AGON COUTAINVILLE,,49.0388327181,-1.58144574808,3,,Agon-Coutainville,Agon-Coutainville,50,Manche,28,Normandie
50004,AIREL,50680,AIREL,,49.2125093524,-1.08007866426,4,,Airel,Airel,50,Manche,28,Normandie
50006,AMIGNY,50620,AMIGNY,,49.1568470769,-1.18525646512,6,,Amigny,Amigny,50,Manche,28,Normandie
50007,ANCTEVILLE,50200,ANCTEVILLE,,49.1030446695,-1.45942313558,7,,Ancteville,Ancteville,50,Manche,28,Normandie
50008,ANCTOVILLE SUR BOSCQ,50400,ANCTOVILLE SUR BOSCQ,,48.8461317815,-1.52840505005,8,,Anctoville-sur-Boscq,Anctoville-sur-Boscq,50,Manche,28,Normandie
50013,ANNEVILLE EN SAIRE,50760,ANNEVILLE EN SAIRE,,49.6362063201,-1.2855537086,13,,Anneville-en-Saire,Anneville-en-Saire,50,Manche,28,Normandie
50014,ANNEVILLE SUR MER,50560,ANNEVILLE SUR MER,,49.1205964204,-1.58682666285,14,,Anneville-sur-Mer,Anneville-sur-Mer,50,Manche,28,Normandie
50015,ANNOVILLE,50660,ANNOVILLE,,48.9613248053,-1.5319902105,15,,Annoville,Annoville,50,Manche,28,Normandie
50016,APPEVILLE,50500,APPEVILLE,,49.3270407818,-1.3359052724,16,,Appeville,Appeville,50,Manche,28,Normandie
50018,ARGOUGES,50240,ARGOUGES,,48.4916284059,-1.37949697287,18,,Argouges,Argouges,50,Manche,28,Normandie
50019,AUCEY LA PLAINE,50170,AUCEY LA PLAINE,,48.5265006323,-1.47519775381,19,,Aucey-la-Plaine,Aucey-la-Plaine,50,Manche,28,Normandie
50020,AUDERVILLE,50440,AUDERVILLE,,49.7072355624,-1.93531188214,20,,Auderville,Auderville,50,Manche,28,Normandie
50021,AUDOUVILLE LA HUBERT,50480,AUDOUVILLE LA HUBERT,,49.4170404243,-1.22666547902,21,,Audouville-la-Hubert,Audouville-la-Hubert,50,Manche,28,Normandie
50022,AUMEVILLE LESTRE,50630,AUMEVILLE LESTRE,,49.5389917084,-1.32249534202,22,,Aumeville-Lestre,Aumeville-Lestre,50,Manche,28,Normandie
50023,AUVERS,50500,AUVERS,,49.3000438595,-1.32395939229,23,,Auvers,Auvers,50,Manche,28,Normandie
50024,AUXAIS,50500,AUXAIS,,49.2154112968,-1.30575313751,24,,Auxais,Auxais,50,Manche,28,Normandie
50025,AVRANCHES,50300,AVRANCHES,,48.6884746717,-1.35732338245,25,,Avranches,Avranches,50,Manche,28,Normandie
50026,AZEVILLE,50310,AZEVILLE,,49.4572780775,-1.31285353585,26,,Azeville,Azeville,50,Manche,28,Normandie
50027,BACILLY,50530,BACILLY,,48.7041130742,-1.43259564211,27,,Bacilly,Bacilly,50,Manche,28,Normandie
50028,LA BALEINE,50450,LA BALEINE,,48.9240616553,-1.30193952808,28,La,Baleine,La Baleine,50,Manche,28,Normandie
50029,BARENTON,50720,BARENTON,,48.6083533868,-0.830642245682,29,,Barenton,Barenton,50,Manche,28,Normandie
50030,BARFLEUR,50760,BARFLEUR,,49.6687321744,-1.26330967066,30,,Barfleur,Barfleur,50,Manche,28,Normandie
50031,BARNEVILLE CARTERET,50270,BARNEVILLE CARTERET,,49.3804461853,-1.77027682978,31,,Barneville-Carteret,Barneville-Carteret,50,Manche,28,Normandie
50032,LA BARRE DE SEMILLY,50810,LA BARRE DE SEMILLY,,49.1063271499,-1.01818230779,32,La,Barre-de-Semilly,La Barre-de-Semilly,50,Manche,28,Normandie
50033,BAUBIGNY,50270,BAUBIGNY,,49.4277910269,-1.80597357475,33,,Baubigny,Baubigny,50,Manche,28,Normandie
50034,BAUDRE,50000,BAUDRE,,49.0874889577,-1.06982745173,34,,Baudre,Baudre,50,Manche,28,Normandie
50036,BAUPTE,50500,BAUPTE,,49.3098408956,-1.36772622791,36,,Baupte,Baupte,50,Manche,28,Normandie
50037,LA BAZOGE,50520,LA BAZOGE,,48.6433576619,-1.0356169875,37,La,Bazoge,La Bazoge,50,Manche,28,Normandie
50038,BEAUCHAMPS,50320,BEAUCHAMPS,,48.8370011641,-1.36645537752,38,,Beauchamps,Beauchamps,50,Manche,28,Normandie
50039,BEAUCOUDRAY,50420,BEAUCOUDRAY,,48.9613880405,-1.14237512444,39,,Beaucoudray,Beaucoudray,50,Manche,28,Normandie
50040,BEAUFICEL,50150,BEAUFICEL,,48.7422652249,-0.962075624861,40,,Beauficel,Beauficel,50,Manche,28,Normandie
50041,BEAUMONT HAGUE,50440,BEAUMONT HAGUE,,49.6631647329,-1.84267363083,41,La,Hague,La Hague,50,Manche,28,Normandie
50042,BEAUVOIR,50170,BEAUVOIR,,48.6036397591,-1.52825115278,42,,Beauvoir,Beauvoir,50,Manche,28,Normandie
50043,BELLEFONTAINE,50520,BELLEFONTAINE,,48.682034341,-0.980875313993,43,,Bellefontaine,Bellefontaine,50,Manche,28,Normandie
50044,BELVAL,50210,BELVAL,,49.0607502082,-1.35521741013,44,,Belval,Belval,50,Manche,28,Normandie
50045,BENOITVILLE,50340,BENOITVILLE,,49.5288698875,-1.77573720806,45,,Benoîtville,Benoîtville,50,Manche,28,Normandie
50046,BERIGNY,50810,BERIGNY,,49.1372472004,-0.947359698893,46,,Bérigny,Bérigny,50,Manche,28,Normandie
50048,BESLON,50800,BESLON,,48.8582276716,-1.15769236289,48,,Beslon,Beslon,50,Manche,28,Normandie
50049,BESNEVILLE,50390,BESNEVILLE,,49.376696854,-1.62681954299,49,,Besneville,Besneville,50,Manche,28,Normandie
50050,BEUVRIGNY,50420,BEUVRIGNY,,48.9657440451,-1.00442708556,50,,Beuvrigny,Beuvrigny,50,Manche,28,Normandie
50052,BEUZEVILLE LA BASTILLE,50360,BEUZEVILLE LA BASTILLE,,49.3525650943,-1.36515881519,52,,Beuzeville-la-Bastille,Beuzeville-la-Bastille,50,Manche,28,Normandie
50054,BIEVILLE,50160,BIEVILLE,,49.0736562042,-0.889084336461,54,,Biéville,Biéville,50,Manche,28,Normandie
50055,BINIVILLE,50390,BINIVILLE,,49.4295879255,-1.48671133047,55,,Biniville,Biniville,50,Manche,28,Normandie
50057,BIVILLE,50440,BIVILLE,,49.6143559098,-1.81083955619,57,,Biville,Biville,50,Manche,28,Normandie
50058,BLAINVILLE SUR MER,50560,BLAINVILLE SUR MER,,49.0728785258,-1.57173551659,58,,Blainville-sur-Mer,Blainville-sur-Mer,50,Manche,28,Normandie
50059,BLOSVILLE,50480,BLOSVILLE,,49.3718453122,-1.28832073685,59,,Blosville,Blosville,50,Manche,28,Normandie
50060,LA BLOUTIERE,50800,LA BLOUTIERE,,48.8700905322,-1.24787411802,60,La,Bloutière,La Bloutière,50,Manche,28,Normandie
50062,BOISYVON,50800,BOISYVON,,48.8066947421,-1.12798080113,62,,Boisyvon,Boisyvon,50,Manche,28,Normandie
50064,LA BONNEVILLE,50360,LA BONNEVILLE,,49.3917994108,-1.45932515061,64,La,Bonneville,La Bonneville,50,Manche,28,Normandie
50066,JULLOUVILLE,50610,JULLOUVILLE,,48.7634529301,-1.52729216893,66,,Jullouville,Jullouville,50,Manche,28,Normandie
50066,JULLOUVILLE,50610,JULLOUVILLE,ST MICHEL DES LOUPS,48.7634529301,-1.52729216893,66,,Jullouville,Jullouville,50,Manche,28,Normandie
50069,BOURGUENOLLES,50800,BOURGUENOLLES,,48.7936682659,-1.28697228564,69,,Bourguenolles,Bourguenolles,50,Manche,28,Normandie
50070,BOUTTEVILLE,50480,BOUTTEVILLE,,49.3907224421,-1.25588804338,70,,Boutteville,Boutteville,50,Manche,28,Normandie
50072,BRAINVILLE,50200,BRAINVILLE,,49.0824569432,-1.50021514683,72,,Brainville,Brainville,50,Manche,28,Normandie
50073,BRANVILLE HAGUE,50440,BRANVILLE HAGUE,,49.6553473762,-1.78554001259,73,,Branville-Hague,Branville-Hague,50,Manche,28,Normandie
50074,BRECEY,50370,BRECEY,,48.7250596734,-1.17310373114,74,,Brécey,Brécey,50,Manche,28,Normandie
50076,BREHAL,50290,BREHAL,,48.8991054391,-1.5329842418,76,,Bréhal,Bréhal,50,Manche,28,Normandie
50076,BREHAL,50290,BREHAL,ST MARTIN DE BREHAL,48.8991054391,-1.5329842418,76,,Bréhal,Bréhal,50,Manche,28,Normandie
50077,BRETTEVILLE,50110,BRETTEVILLE,,49.6500323441,-1.508274502,77,,Bretteville,Bretteville,50,Manche,28,Normandie
50078,BRETTEVILLE SUR AY,50430,BRETTEVILLE SUR AY,,49.257448557,-1.62881599028,78,,Bretteville-sur-Ay,Bretteville-sur-Ay,50,Manche,28,Normandie
50079,BREUVILLE,50260,BREUVILLE,,49.5381940041,-1.66976755388,79,,Breuville,Breuville,50,Manche,28,Normandie
50080,BREVANDS,50500,BREVANDS,,49.3401374818,-1.17110997385,80,,Brévands,Brévands,50,Manche,28,Normandie
50081,BREVILLE SUR MER,50290,BREVILLE SUR MER,,48.8696459716,-1.55887910392,81,,Bréville-sur-Mer,Bréville-sur-Mer,50,Manche,28,Normandie
50082,BRICQUEBEC EN COTENTIN,50260,BRICQUEBEC EN COTENTIN,,49.4699810492,-1.63261585347,82,,Bricquebec-en-Cotentin,Bricquebec-en-Cotentin,50,Manche,28,Normandie
50082,BRICQUEBEC EN COTENTIN,50260,BRICQUEBEC EN COTENTIN,LES PERQUES,49.4699810492,-1.63261585347,82,,Bricquebec-en-Cotentin,Bricquebec-en-Cotentin,50,Manche,28,Normandie
50082,BRICQUEBEC EN COTENTIN,50260,BRICQUEBEC EN COTENTIN,LE VALDECIE,49.4699810492,-1.63261585347,82,,Bricquebec-en-Cotentin,Bricquebec-en-Cotentin,50,Manche,28,Normandie
50082,BRICQUEBEC EN COTENTIN,50260,BRICQUEBEC EN COTENTIN,LE VRETOT,49.4699810492,-1.63261585347,82,,Bricquebec-en-Cotentin,Bricquebec-en-Cotentin,50,Manche,28,Normandie
50082,BRICQUEBEC EN COTENTIN,50260,BRICQUEBEC EN COTENTIN,QUETTETOT,49.4699810492,-1.63261585347,82,,Bricquebec-en-Cotentin,Bricquebec-en-Cotentin,50,Manche,28,Normandie
50082,BRICQUEBEC EN COTENTIN,50260,BRICQUEBEC EN COTENTIN,ST MARTIN LE HEBERT,49.4699810492,-1.63261585347,82,,Bricquebec-en-Cotentin,Bricquebec-en-Cotentin,50,Manche,28,Normandie
50083,BRICQUEBOSQ,50340,BRICQUEBOSQ,,49.5357212716,-1.71763641021,83,,Bricquebosq,Bricquebosq,50,Manche,28,Normandie
50084,BRICQUEVILLE LA BLOUETTE,50200,BRICQUEVILLE LA BLOUETTE,,49.0380343461,-1.47913007848,84,,Bricqueville-la-Blouette,Bricqueville-la-Blouette,50,Manche,28,Normandie
50085,BRICQUEVILLE SUR MER,50290,BRICQUEVILLE SUR MER,,48.9183887139,-1.51381189352,85,,Bricqueville-sur-Mer,Bricqueville-sur-Mer,50,Manche,28,Normandie
50086,BRILLEVAST,50330,BRILLEVAST,,49.6251847771,-1.41274998692,86,,Brillevast,Brillevast,50,Manche,28,Normandie
50087,BRIX,50700,BRIX,,49.5534145945,-1.57357010117,87,,Brix,Brix,50,Manche,28,Normandie
50088,BROUAINS,50150,BROUAINS,,48.720771214,-0.966952494174,88,,Brouains,Brouains,50,Manche,28,Normandie
50089,BRUCHEVILLE,50480,BRUCHEVILLE,,49.3585322836,-1.20653843529,89,,Brucheville,Brucheville,50,Manche,28,Normandie
50090,BUAIS LES MONTS,50640,BUAIS LES MONTS,,48.5185588487,-0.971234303313,90,,Buais-Les-Monts,Buais-Les-Monts,50,Manche,28,Normandie
50090,BUAIS LES MONTS,50640,BUAIS LES MONTS,ST SYMPHORIEN DES MONTS,48.5185588487,-0.971234303313,90,,Buais-Les-Monts,Buais-Les-Monts,50,Manche,28,Normandie
50092,CAMBERNON,50200,CAMBERNON,,49.0871438723,-1.38108310659,92,,Cambernon,Cambernon,50,Manche,28,Normandie
50093,CAMETOURS,50570,CAMETOURS,,49.0708020035,-1.28408388695,93,,Cametours,Cametours,50,Manche,28,Normandie
50094,CAMPROND,50210,CAMPROND,,49.0858024109,-1.33862320377,94,,Camprond,Camprond,50,Manche,28,Normandie
50095,CANISY,50750,CANISY,,49.0797681926,-1.18008424272,95,,Canisy,Canisy,50,Manche,28,Normandie
50096,CANTELOUP,50330,CANTELOUP,,49.6403205635,-1.35985612981,96,,Canteloup,Canteloup,50,Manche,28,Normandie
50097,CANVILLE LA ROCQUE,50580,CANVILLE LA ROCQUE,,49.3468037124,-1.63643708504,97,,Canville-la-Rocque,Canville-la-Rocque,50,Manche,28,Normandie
50098,CARANTILLY,50570,CARANTILLY,,49.0661156491,-1.24968371393,98,,Carantilly,Carantilly,50,Manche,28,Normandie
50099,CARENTAN LES MARAIS,50480,CARENTAN LES MARAIS,ANGOVILLE AU PLAIN,49.2963250601,-1.2559215726,99,,Carentan les Marais,Carentan les Marais,50,Manche,28,Normandie
50099,CARENTAN LES MARAIS,50480,CARENTAN LES MARAIS,HOUESVILLE,49.2963250601,-1.2559215726,99,,Carentan les Marais,Carentan les Marais,50,Manche,28,Normandie
50099,CARENTAN LES MARAIS,50500,CARENTAN LES MARAIS,,49.2963250601,-1.2559215726,99,,Carentan les Marais,Carentan les Marais,50,Manche,28,Normandie
50099,CARENTAN LES MARAIS,50500,CARENTAN LES MARAIS,ST COME DU MONT,49.2963250601,-1.2559215726,99,,Carentan les Marais,Carentan les Marais,50,Manche,28,Normandie
50100,CARNET,50240,CARNET,,48.5049122594,-1.35301469644,100,,Carnet,Carnet,50,Manche,28,Normandie
50101,CARNEVILLE,50330,CARNEVILLE,,49.6594170534,-1.44840446886,101,,Carneville,Carneville,50,Manche,28,Normandie
50102,CAROLLES,50740,CAROLLES,,48.749037599,-1.55955935077,102,,Carolles,Carolles,50,Manche,28,Normandie
50103,CARQUEBUT,50480,CARQUEBUT,,49.3744415862,-1.32327946627,103,,Carquebut,Carquebut,50,Manche,28,Normandie
50105,CATTEVILLE,50390,CATTEVILLE,,49.3549798995,-1.5656295955,105,,Catteville,Catteville,50,Manche,28,Normandie
50106,CAVIGNY,50620,CAVIGNY,,49.1911573529,-1.12208710895,106,,Cavigny,Cavigny,50,Manche,28,Normandie
50107,CATZ,50500,CATZ,,49.3127149417,-1.18665023659,107,,Catz,Catz,50,Manche,28,Normandie
50108,CEAUX,50220,CEAUX,,48.6305274048,-1.38499101591,108,,Céaux,Céaux,50,Manche,28,Normandie
50109,CERENCES,50510,CERENCES,,48.9111679831,-1.43836257164,109,,Cérences,Cérences,50,Manche,28,Normandie
50110,CERISY LA FORET,50680,CERISY LA FORET,,49.1921145601,-0.938538623187,110,,Cerisy-la-Forêt,Cerisy-la-Forêt,50,Manche,28,Normandie
50111,CERISY LA SALLE,50210,CERISY LA SALLE,,49.0364321556,-1.27126890359,111,,Cerisy-la-Salle,Cerisy-la-Salle,50,Manche,28,Normandie
50112,LA CHAISE BAUDOUIN,50370,LA CHAISE BAUDOUIN,,48.7649742891,-1.23764812549,112,La,Chaise-Baudouin,La Chaise-Baudouin,50,Manche,28,Normandie
50115,LE GRIPPON,50320,LE GRIPPON,CHAMPCERVON,48.7720554748,-1.40097666285,115,Le,Grippon,Le Grippon,50,Manche,28,Normandie
50115,LE GRIPPON,50320,LE GRIPPON,LES CHAMBRES,48.7720554748,-1.40097666285,115,Le,Grippon,Le Grippon,50,Manche,28,Normandie
50117,CHAMPEAUX,50530,CHAMPEAUX,,48.7377829694,-1.52927055978,117,,Champeaux,Champeaux,50,Manche,28,Normandie
50118,CHAMPREPUS,50800,CHAMPREPUS,,48.8340009078,-1.31607889446,118,,Champrepus,Champrepus,50,Manche,28,Normandie
50119,LES CHAMPS DE LOSQUE,50620,LES CHAMPS DE LOSQUE,,49.1778691017,-1.22163591323,119,Les,Champs-de-Losque,Les Champs-de-Losque,50,Manche,28,Normandie
50120,CHANTELOUP,50510,CHANTELOUP,,48.8929574359,-1.4825906848,120,,Chanteloup,Chanteloup,50,Manche,28,Normandie
50121,LA CHAPELLE CECELIN,50800,LA CHAPELLE CECELIN,,48.8078496044,-1.16781595111,121,La,Chapelle-Cécelin,La Chapelle-Cécelin,50,Manche,28,Normandie
50124,LA CHAPELLE UREE,50370,LA CHAPELLE UREE,,48.6642257683,-1.14933275487,124,La,Chapelle-Urée,La Chapelle-Urée,50,Manche,28,Normandie
50125,CHASSEGUEY,50520,CHASSEGUEY,,48.6442535798,-1.06949518386,125,,Chasseguey,Chasseguey,50,Manche,28,Normandie
50126,CHAVOY,50870,CHAVOY,,48.7360129868,-1.33360285302,126,,Chavoy,Chavoy,50,Manche,28,Normandie
50129,CHERBOURG EN COTENTIN,50100,CHERBOURG EN COTENTIN,,49.633412156,-1.63390160204,129,,Cherbourg-en-Cotentin,Cherbourg-en-Cotentin,50,Manche,28,Normandie
50129,CHERBOURG EN COTENTIN,50110,CHERBOURG EN COTENTIN,TOURLAVILLE,49.633412156,-1.63390160204,129,,Cherbourg-en-Cotentin,Cherbourg-en-Cotentin,50,Manche,28,Normandie
50129,CHERBOURG EN COTENTIN,50120,CHERBOURG EN COTENTIN,EQUEURDREVILLE HAINNEVILLE,49.633412156,-1.63390160204,129,,Cherbourg-en-Cotentin,Cherbourg-en-Cotentin,50,Manche,28,Normandie
50129,CHERBOURG EN COTENTIN,50130,CHERBOURG EN COTENTIN,,49.633412156,-1.63390160204,129,,Cherbourg-en-Cotentin,Cherbourg-en-Cotentin,50,Manche,28,Normandie
50129,CHERBOURG EN COTENTIN,50130,CHERBOURG EN COTENTIN,OCTEVILLE,49.633412156,-1.63390160204,129,,Cherbourg-en-Cotentin,Cherbourg-en-Cotentin,50,Manche,28,Normandie
50129,CHERBOURG EN COTENTIN,50460,CHERBOURG EN COTENTIN,QUERQUEVILLE,49.633412156,-1.63390160204,129,,Cherbourg-en-Cotentin,Cherbourg-en-Cotentin,50,Manche,28,Normandie
50129,CHERBOURG EN COTENTIN,50470,CHERBOURG EN COTENTIN,LA GLACERIE,49.633412156,-1.63390160204,129,,Cherbourg-en-Cotentin,Cherbourg-en-Cotentin,50,Manche,28,Normandie
50130,CHERENCE LE HERON,50800,CHERENCE LE HERON,,48.7990064942,-1.20150339544,130,,Chérencé-le-Héron,Chérencé-le-Héron,50,Manche,28,Normandie
50131,CHERENCE LE ROUSSEL,50520,CHERENCE LE ROUSSEL,,48.7112243096,-0.999366416758,131,,Chérencé-le-Roussel,Chérencé-le-Roussel,50,Manche,28,Normandie
50135,CLITOURPS,50330,CLITOURPS,,49.6512259574,-1.37120809063,135,,Clitourps,Clitourps,50,Manche,28,Normandie
50137,LA COLOMBE,50800,LA COLOMBE,,48.8755777251,-1.19526749563,137,La,Colombe,La Colombe,50,Manche,28,Normandie
50138,COLOMBY,50700,COLOMBY,,49.4541493168,-1.48894916304,138,,Colomby,Colomby,50,Manche,28,Normandie
50139,CONDE SUR VIRE,50420,CONDE SUR VIRE,LE MESNIL RAOULT,49.0540546845,-1.02453868309,139,,Condé-sur-Vire,Condé-sur-Vire,50,Manche,28,Normandie
50139,CONDE SUR VIRE,50890,CONDE SUR VIRE,,49.0540546845,-1.02453868309,139,,Condé-sur-Vire,Condé-sur-Vire,50,Manche,28,Normandie
50140,CONTRIERES,50660,CONTRIERES,,48.9862200698,-1.42923558899,140,,Contrières,Contrières,50,Manche,28,Normandie
50142,VICQ SUR MER,50330,VICQ SUR MER,ANGOVILLE EN SAIRE,49.6915085353,-1.4000999278,142,,Vicq-sur-Mer,Vicq-sur-Mer,50,Manche,28,Normandie
50142,VICQ SUR MER,50330,VICQ SUR MER,COSQUEVILLE,49.6915085353,-1.4000999278,142,,Vicq-sur-Mer,Vicq-sur-Mer,50,Manche,28,Normandie
50142,VICQ SUR MER,50330,VICQ SUR MER,GOUBERVILLE,49.6915085353,-1.4000999278,142,,Vicq-sur-Mer,Vicq-sur-Mer,50,Manche,28,Normandie
50142,VICQ SUR MER,50330,VICQ SUR MER,NEVILLE SUR MER,49.6915085353,-1.4000999278,142,,Vicq-sur-Mer,Vicq-sur-Mer,50,Manche,28,Normandie
50142,VICQ SUR MER,50330,VICQ SUR MER,RETHOVILLE,49.6915085353,-1.4000999278,142,,Vicq-sur-Mer,Vicq-sur-Mer,50,Manche,28,Normandie
50142,VICQ SUR MER,50330,VICQ SUR MER,VRASVILLE,49.6915085353,-1.4000999278,142,,Vicq-sur-Mer,Vicq-sur-Mer,50,Manche,28,Normandie
50143,COUDEVILLE SUR MER,50290,COUDEVILLE SUR MER,,48.8735234655,-1.52424564985,143,,Coudeville-sur-Mer,Coudeville-sur-Mer,50,Manche,28,Normandie
50144,COULOUVRAY BOISBENATRE,50670,COULOUVRAY BOISBENATRE,,48.7821077073,-1.10464666586,144,,Coulouvray-Boisbenâtre,Coulouvray-Boisbenâtre,50,Manche,28,Normandie
50145,COURCY,50200,COURCY,,49.0509362145,-1.38869491395,145,,Courcy,Courcy,50,Manche,28,Normandie
50146,COURTILS,50220,COURTILS,,48.6281130246,-1.42282016647,146,,Courtils,Courtils,50,Manche,28,Normandie
50147,COUTANCES,50200,COUTANCES,,49.0566189539,-1.44345003609,147,,Coutances,Coutances,50,Manche,28,Normandie
50148,COUVAINS,50680,COUVAINS,,49.1619489869,-1.0082509146,148,,Couvains,Couvains,50,Manche,28,Normandie
50149,COUVILLE,50690,COUVILLE,,49.5583745259,-1.68158460164,149,,Couville,Couville,50,Manche,28,Normandie
50150,CRASVILLE,50630,CRASVILLE,,49.5541955641,-1.33206905184,150,,Crasville,Crasville,50,Manche,28,Normandie
50151,CREANCES,50710,CREANCES,,49.1999968396,-1.56218099471,151,,Créances,Créances,50,Manche,28,Normandie
50152,LES CRESNAYS,50370,LES CRESNAYS,,48.7090600064,-1.11382515634,152,Les,Cresnays,Les Cresnays,50,Manche,28,Normandie
50154,LA CROIX AVRANCHIN,50240,LA CROIX AVRANCHIN,,48.5502684433,-1.38007913992,154,La,Croix-Avranchin,La Croix-Avranchin,50,Manche,28,Normandie
50155,CROLLON,50220,CROLLON,,48.5824076496,-1.38068361185,155,,Crollon,Crollon,50,Manche,28,Normandie
50156,CROSVILLE SUR DOUVE,50360,CROSVILLE SUR DOUVE,,49.3854792404,-1.47607183277,156,,Crosville-sur-Douve,Crosville-sur-Douve,50,Manche,28,Normandie
50158,CUVES,50670,CUVES,,48.7292658425,-1.09806431434,158,,Cuves,Cuves,50,Manche,28,Normandie
50159,DANGY,50750,DANGY,,49.029220854,-1.22193963849,159,,Dangy,Dangy,50,Manche,28,Normandie
50160,DENNEVILLE,50580,DENNEVILLE,,49.3146923833,-1.6626564065,160,,Denneville,Denneville,50,Manche,28,Normandie
50161,LE DEZERT,50620,LE DEZERT,,49.2088367132,-1.16303254743,161,Le,Dézert,Le Dézert,50,Manche,28,Normandie
50162,DIGOSVILLE,50110,DIGOSVILLE,,49.6288821095,-1.52811592716,162,,Digosville,Digosville,50,Manche,28,Normandie
50163,DIGULLEVILLE,50440,DIGULLEVILLE,,49.691054091,-1.85876194612,163,,Digulleville,Digulleville,50,Manche,28,Normandie
50164,DOMJEAN,50420,DOMJEAN,,48.9907905898,-1.03423801965,164,,Domjean,Domjean,50,Manche,28,Normandie
50165,DONVILLE LES BAINS,50350,DONVILLE LES BAINS,,48.8508414617,-1.57315135089,165,,Donville-les-Bains,Donville-les-Bains,50,Manche,28,Normandie
50166,DOVILLE,50250,DOVILLE,,49.3370222882,-1.54822338133,166,,Doville,Doville,50,Manche,28,Normandie
50167,DRAGEY RONTHON,50530,DRAGEY RONTHON,,48.7166999564,-1.48832263825,167,,Dragey-Ronthon,Dragey-Ronthon,50,Manche,28,Normandie
50167,DRAGEY RONTHON,50530,DRAGEY RONTHON,RONTHON,48.7166999564,-1.48832263825,167,,Dragey-Ronthon,Dragey-Ronthon,50,Manche,28,Normandie
50168,DUCEY LES CHERIS,50220,DUCEY LES CHERIS,,48.6129636498,-1.27873609026,168,,Ducey-Les Chéris,Ducey-Les Chéris,50,Manche,28,Normandie
50168,DUCEY LES CHERIS,50220,DUCEY LES CHERIS,LES CHERIS,48.6129636498,-1.27873609026,168,,Ducey-Les Chéris,Ducey-Les Chéris,50,Manche,28,Normandie
50169,ECAUSSEVILLE,50310,ECAUSSEVILLE,,49.4597239721,-1.38260000439,169,,Écausseville,Écausseville,50,Manche,28,Normandie
50171,ECULLEVILLE,50440,ECULLEVILLE,,49.6826069133,-1.82441646748,171,,Éculleville,Éculleville,50,Manche,28,Normandie
50172,EMONDEVILLE,50310,EMONDEVILLE,,49.4603061877,-1.33759005071,172,,Émondeville,Émondeville,50,Manche,28,Normandie
50174,EQUILLY,50320,EQUILLY,,48.8331161622,-1.38244555863,174,,Équilly,Équilly,50,Manche,28,Normandie
50175,EROUDEVILLE,50310,EROUDEVILLE,,49.4742435291,-1.39648414202,175,,Éroudeville,Éroudeville,50,Manche,28,Normandie
50176,L ETANG BERTRAND,50260,L ETANG BERTRAND,,49.4724529021,-1.57060708734,176,L',Étang-Bertrand,L'Étang-Bertrand,50,Manche,28,Normandie
50177,ETIENVILLE,50360,ETIENVILLE,,49.3854228436,-1.4364851189,177,,Étienville,Étienville,50,Manche,28,Normandie
50178,FERMANVILLE,50840,FERMANVILLE,,49.6810861003,-1.45453917857,178,,Fermanville,Fermanville,50,Manche,28,Normandie
50181,FEUGERES,50190,FEUGERES,,49.1487759285,-1.31727331355,181,,Feugères,Feugères,50,Manche,28,Normandie
50182,LA FEUILLIE,50190,LA FEUILLIE,,49.1701790495,-1.49237727633,182,La,Feuillie,La Feuillie,50,Manche,28,Normandie
50183,FIERVILLE LES MINES,50580,FIERVILLE LES MINES,,49.3954757313,-1.66642219384,183,,Fierville-les-Mines,Fierville-les-Mines,50,Manche,28,Normandie
50184,FLAMANVILLE,50340,FLAMANVILLE,,49.530584299,-1.86809875664,184,,Flamanville,Flamanville,50,Manche,28,Normandie
50185,FLEURY,50800,FLEURY,,48.8488921299,-1.26431862113,185,,Fleury,Fleury,50,Manche,28,Normandie
50186,FLOTTEMANVILLE,50700,FLOTTEMANVILLE,,49.476265104,-1.45169361396,186,,Flottemanville,Flottemanville,50,Manche,28,Normandie
50187,FLOTTEMANVILLE HAGUE,50690,FLOTTEMANVILLE HAGUE,,49.6183451491,-1.71707810029,187,,Flottemanville-Hague,Flottemanville-Hague,50,Manche,28,Normandie
50188,FOLLIGNY,50320,FOLLIGNY,,48.8202336889,-1.41781019903,188,,Folligny,Folligny,50,Manche,28,Normandie
50188,FOLLIGNY,50320,FOLLIGNY,LA BESLIERE,48.8202336889,-1.41781019903,188,,Folligny,Folligny,50,Manche,28,Normandie
50188,FOLLIGNY,50320,FOLLIGNY,LE MESNIL DREY,48.8202336889,-1.41781019903,188,,Folligny,Folligny,50,Manche,28,Normandie
50190,FONTENAY SUR MER,50310,FONTENAY SUR MER,,49.4935350272,-1.30873681575,190,,Fontenay-sur-Mer,Fontenay-sur-Mer,50,Manche,28,Normandie
50192,FOURNEAUX,50420,FOURNEAUX,,48.9664383524,-1.04146315344,192,,Fourneaux,Fourneaux,50,Manche,28,Normandie
50193,LE FRESNE PORET,50850,LE FRESNE PORET,,48.7082718798,-0.820867832626,193,Le,Fresne-Poret,Le Fresne-Poret,50,Manche,28,Normandie
50194,FRESVILLE,50310,FRESVILLE,,49.4413987957,-1.35332483937,194,,Fresville,Fresville,50,Manche,28,Normandie
50195,GATHEMO,50150,GATHEMO,,48.7693848928,-0.963982047584,195,,Gathemo,Gathemo,50,Manche,28,Normandie
50196,GATTEVILLE LE PHARE,50760,GATTEVILLE LE PHARE,,49.6796510681,-1.29047452696,196,,Gatteville-le-Phare,Gatteville-le-Phare,50,Manche,28,Normandie
50197,GAVRAY,50450,GAVRAY,,48.901866947,-1.32927187447,197,,Gavray,Gavray,50,Manche,28,Normandie
50197,GAVRAY,50450,GAVRAY,LE MESNIL BONANT,48.901866947,-1.32927187447,197,,Gavray,Gavray,50,Manche,28,Normandie
50197,GAVRAY,50450,GAVRAY,LE MESNIL HUE,48.901866947,-1.32927187447,197,,Gavray,Gavray,50,Manche,28,Normandie
50198,GEFFOSSES,50560,GEFFOSSES,,49.1293350748,-1.55102894919,198,,Geffosses,Geffosses,50,Manche,28,Normandie
50199,GENETS,50530,GENETS,,48.6891667321,-1.47354012671,199,,Genêts,Genêts,50,Manche,28,Normandie
50200,GER,50850,GER,,48.6718461689,-0.802951577458,200,,Ger,Ger,50,Manche,28,Normandie
50205,LA GODEFROY,50300,LA GODEFROY,,48.6905981848,-1.28421561374,205,La,Godefroy,La Godefroy,50,Manche,28,Normandie
50206,LA GOHANNIERE,50300,LA GOHANNIERE,,48.7013309141,-1.26089756914,206,La,Gohannière,La Gohannière,50,Manche,28,Normandie
50207,GOLLEVILLE,50390,GOLLEVILLE,,49.4387094084,-1.51369309832,207,,Golleville,Golleville,50,Manche,28,Normandie
50208,GONFREVILLE,50190,GONFREVILLE,,49.2310325389,-1.40286602257,208,,Gonfreville,Gonfreville,50,Manche,28,Normandie
50209,GONNEVILLE LE THEIL,50330,GONNEVILLE LE THEIL,,49.6319375816,-1.47264089403,209,,Gonneville-Le Theil,Gonneville-Le Theil,50,Manche,28,Normandie
50209,GONNEVILLE LE THEIL,50330,GONNEVILLE LE THEIL,LE THEIL,49.6319375816,-1.47264089403,209,,Gonneville-Le Theil,Gonneville-Le Theil,50,Manche,28,Normandie
50210,GORGES,50190,GORGES,,49.2644285169,-1.38981745517,210,,Gorges,Gorges,50,Manche,28,Normandie
50214,GOUVETS,50420,GOUVETS,,48.928521643,-1.09363899793,214,,Gouvets,Gouvets,50,Manche,28,Normandie
50215,GOUVILLE SUR MER,50200,GOUVILLE SUR MER,BOISROGER,49.0979435491,-1.57923308824,215,,Gouville sur Mer,Gouville sur Mer,50,Manche,28,Normandie
50215,GOUVILLE SUR MER,50560,GOUVILLE SUR MER,,49.0979435491,-1.57923308824,215,,Gouville sur Mer,Gouville sur Mer,50,Manche,28,Normandie
50216,GRAIGNES MESNIL ANGOT,50620,GRAIGNES MESNIL ANGOT,,49.2380106991,-1.20015194304,216,,Graignes-Mesnil-Angot,Graignes-Mesnil-Angot,50,Manche,28,Normandie
50216,GRAIGNES MESNIL ANGOT,50620,GRAIGNES MESNIL ANGOT,LE MESNIL ANGOT,49.2380106991,-1.20015194304,216,,Graignes-Mesnil-Angot,Graignes-Mesnil-Angot,50,Manche,28,Normandie
50217,LE GRAND CELLAND,50370,LE GRAND CELLAND,,48.6882059438,-1.17301300163,217,Le,Grand-Celland,Le Grand-Celland,50,Manche,28,Normandie
50218,GRANVILLE,50400,GRANVILLE,,48.8327078372,-1.56670866413,218,,Granville,Granville,50,Manche,28,Normandie
50218,GRANVILLE,50400,GRANVILLE,ILES CHAUSEY,48.8327078372,-1.56670866413,218,,Granville,Granville,50,Manche,28,Normandie
50219,GRATOT,50200,GRATOT,,49.0683104391,-1.49454774157,219,,Gratot,Gratot,50,Manche,28,Normandie
50220,GREVILLE HAGUE,50440,GREVILLE HAGUE,,49.6726234506,-1.79920661444,220,,Gréville-Hague,Gréville-Hague,50,Manche,28,Normandie
50221,GRIMESNIL,50450,GRIMESNIL,,48.9549115704,-1.35072066889,221,,Grimesnil,Grimesnil,50,Manche,28,Normandie
50222,GROSVILLE,50340,GROSVILLE,,49.5070631991,-1.73298860614,222,,Grosville,Grosville,50,Manche,28,Normandie
50223,GUEHEBERT,50210,GUEHEBERT,,48.9668730716,-1.37072235282,223,,Guéhébert,Guéhébert,50,Manche,28,Normandie
50225,LE GUISLAIN,50410,LE GUISLAIN,,48.971687343,-1.21557798635,225,Le,Guislain,Le Guislain,50,Manche,28,Normandie
50227,LE HAM,50310,LE HAM,,49.449950081,-1.40982861072,227,Le,Ham,Le Ham,50,Manche,28,Normandie
50228,HAMBYE,50450,HAMBYE,,48.947434282,-1.25972475614,228,,Hambye,Hambye,50,Manche,28,Normandie
50229,HAMELIN,50730,HAMELIN,,48.5429270331,-1.22248303099,229,,Hamelin,Hamelin,50,Manche,28,Normandie
50230,HARDINVAST,50690,HARDINVAST,,49.5766905204,-1.64595103605,230,,Hardinvast,Hardinvast,50,Manche,28,Normandie
50231,HAUTEVILLE SUR MER,50590,HAUTEVILLE SUR MER,,48.9741132204,-1.54436628419,231,,Hauteville-sur-Mer,Hauteville-sur-Mer,50,Manche,28,Normandie
50232,HAUTEVILLE LA GUICHARD,50570,HAUTEVILLE LA GUICHARD,,49.1244193511,-1.3030503118,232,,Hauteville-la-Guichard,Hauteville-la-Guichard,50,Manche,28,Normandie
50233,HAUTTEVILLE BOCAGE,50390,HAUTTEVILLE BOCAGE,,49.4282916342,-1.46687598255,233,,Hautteville-Bocage,Hautteville-Bocage,50,Manche,28,Normandie
50234,LA HAYE BELLEFOND,50410,LA HAYE BELLEFOND,,48.9808356321,-1.18892406479,234,La,Haye-Bellefond,La Haye-Bellefond,50,Manche,28,Normandie
50235,LA HAYE D ECTOT,50270,LA HAYE D ECTOT,,49.3936872914,-1.73016316,235,La,Haye-d'Ectot,La Haye-d'Ectot,50,Manche,28,Normandie
50236,LA HAYE,50250,LA HAYE,,49.2835142105,-1.53943989107,236,La,Haye,La Haye,50,Manche,28,Normandie
50236,LA HAYE,50250,LA HAYE,BAUDREVILLE,49.2835142105,-1.53943989107,236,La,Haye,La Haye,50,Manche,28,Normandie
50236,LA HAYE,50250,LA HAYE,BOLLEVILLE,49.2835142105,-1.53943989107,236,La,Haye,La Haye,50,Manche,28,Normandie
50236,LA HAYE,50250,LA HAYE,GLATIGNY,49.2835142105,-1.53943989107,236,La,Haye,La Haye,50,Manche,28,Normandie
50236,LA HAYE,50250,LA HAYE,MOBECQ,49.2835142105,-1.53943989107,236,La,Haye,La Haye,50,Manche,28,Normandie
50236,LA HAYE,50250,LA HAYE,MONTGARDON,49.2835142105,-1.53943989107,236,La,Haye,La Haye,50,Manche,28,Normandie
50236,LA HAYE,50250,LA HAYE,ST SYMPHORIEN LE VALOIS,49.2835142105,-1.53943989107,236,La,Haye,La Haye,50,Manche,28,Normandie
50236,LA HAYE,50250,LA HAYE,SURVILLE,49.2835142105,-1.53943989107,236,La,Haye,La Haye,50,Manche,28,Normandie
50236,LA HAYE,50580,LA HAYE,ST REMY DES LANDES,49.2835142105,-1.53943989107,236,La,Haye,La Haye,50,Manche,28,Normandie
50237,LA HAYE PESNEL,50320,LA HAYE PESNEL,,48.8097220034,-1.374935921,237,La,Haye-Pesnel,La Haye-Pesnel,50,Manche,28,Normandie
50238,HEAUVILLE,50340,HEAUVILLE,,49.5753620349,-1.80249526081,238,,Héauville,Héauville,50,Manche,28,Normandie
50239,THEREVAL,50180,THEREVAL,HEBECREVON,49.1221777065,-1.17047644258,239,,Thèreval,Thèreval,50,Manche,28,Normandie
50239,THEREVAL,50570,THEREVAL,LA CHAPELLE EN JUGER,49.1221777065,-1.17047644258,239,,Thèreval,Thèreval,50,Manche,28,Normandie
50240,HELLEVILLE,50340,HELLEVILLE,,49.558015983,-1.78437613079,240,,Helleville,Helleville,50,Manche,28,Normandie
50241,HEMEVEZ,50700,HEMEVEZ,,49.4623918009,-1.43528849976,241,,Hémevez,Hémevez,50,Manche,28,Normandie
50242,HERQUEVILLE,50440,HERQUEVILLE,,49.6673680277,-1.88085144895,242,,Herqueville,Herqueville,50,Manche,28,Normandie
50243,HEUGUEVILLE SUR SIENNE,50200,HEUGUEVILLE SUR SIENNE,,49.037931867,-1.51183729041,243,,Heugueville-sur-Sienne,Heugueville-sur-Sienne,50,Manche,28,Normandie
50244,HERENGUERVILLE,50660,HERENGUERVILLE,,48.9748474749,-1.50155321395,244,,Hérenguerville,Hérenguerville,50,Manche,28,Normandie
50246,HIESVILLE,50480,HIESVILLE,,49.3711233958,-1.25909624755,246,,Hiesville,Hiesville,50,Manche,28,Normandie
50247,HOCQUIGNY,50320,HOCQUIGNY,,48.8085201444,-1.401972508,247,,Hocquigny,Hocquigny,50,Manche,28,Normandie
50248,LE HOMMET D ARTHENAY,50620,LE HOMMET D ARTHENAY,,49.1901156033,-1.19499269817,248,Le,Hommet-d'Arthenay,Le Hommet-d'Arthenay,50,Manche,28,Normandie
50251,HUBERVILLE,50700,HUBERVILLE,,49.5093443935,-1.43254692074,251,,Huberville,Huberville,50,Manche,28,Normandie
50252,HUDIMESNIL,50510,HUDIMESNIL,,48.870028751,-1.46798288631,252,,Hudimesnil,Hudimesnil,50,Manche,28,Normandie
50253,HUISNES SUR MER,50170,HUISNES SUR MER,,48.6124227402,-1.44918235268,253,,Huisnes-sur-Mer,Huisnes-sur-Mer,50,Manche,28,Normandie
50256,ISIGNY LE BUAT,50540,ISIGNY LE BUAT,,48.6214964353,-1.18298649234,256,,Isigny-le-Buat,Isigny-le-Buat,50,Manche,28,Normandie
50256,ISIGNY LE BUAT,50540,ISIGNY LE BUAT,CHALANDREY,48.6214964353,-1.18298649234,256,,Isigny-le-Buat,Isigny-le-Buat,50,Manche,28,Normandie
50256,ISIGNY LE BUAT,50540,ISIGNY LE BUAT,LA MANCELLIERE,48.6214964353,-1.18298649234,256,,Isigny-le-Buat,Isigny-le-Buat,50,Manche,28,Normandie
50256,ISIGNY LE BUAT,50540,ISIGNY LE BUAT,LE MESNIL BOEUFS,48.6214964353,-1.18298649234,256,,Isigny-le-Buat,Isigny-le-Buat,50,Manche,28,Normandie
50256,ISIGNY LE BUAT,50540,ISIGNY LE BUAT,LE MESNIL THEBAULT,48.6214964353,-1.18298649234,256,,Isigny-le-Buat,Isigny-le-Buat,50,Manche,28,Normandie
50256,ISIGNY LE BUAT,50540,ISIGNY LE BUAT,LES BIARDS,48.6214964353,-1.18298649234,256,,Isigny-le-Buat,Isigny-le-Buat,50,Manche,28,Normandie
50256,ISIGNY LE BUAT,50540,ISIGNY LE BUAT,MONTGOTHIER,48.6214964353,-1.18298649234,256,,Isigny-le-Buat,Isigny-le-Buat,50,Manche,28,Normandie
50256,ISIGNY LE BUAT,50540,ISIGNY LE BUAT,MONTIGNY,48.6214964353,-1.18298649234,256,,Isigny-le-Buat,Isigny-le-Buat,50,Manche,28,Normandie
50256,ISIGNY LE BUAT,50540,ISIGNY LE BUAT,NAFTEL,48.6214964353,-1.18298649234,256,,Isigny-le-Buat,Isigny-le-Buat,50,Manche,28,Normandie
50256,ISIGNY LE BUAT,50540,ISIGNY LE BUAT,VEZINS,48.6214964353,-1.18298649234,256,,Isigny-le-Buat,Isigny-le-Buat,50,Manche,28,Normandie
50257,JOBOURG,50440,JOBOURG,,49.6806962451,-1.91434201556,257,,Jobourg,Jobourg,50,Manche,28,Normandie
50258,JOGANVILLE,50310,JOGANVILLE,,49.4715637056,-1.35154159176,258,,Joganville,Joganville,50,Manche,28,Normandie
50259,JUILLEY,50220,JUILLEY,,48.5909006433,-1.34729095786,259,,Juilley,Juilley,50,Manche,28,Normandie
50260,JUVIGNY LE TERTRE,50520,JUVIGNY LE TERTRE,,48.678576703,-1.04281408802,260,,Juvigny les Vallées,Juvigny les Vallées,50,Manche,28,Normandie
50261,LAMBERVILLE,50160,LAMBERVILLE,,49.079020177,-0.91672130687,261,,Lamberville,Lamberville,50,Manche,28,Normandie
50262,LA LANDE D AIROU,50800,LA LANDE D AIROU,,48.8191087979,-1.28348605862,262,La,Lande-d'Airou,La Lande-d'Airou,50,Manche,28,Normandie
50263,LAPENTY,50600,LAPENTY,,48.5689340564,-1.02322797383,263,,Lapenty,Lapenty,50,Manche,28,Normandie
50265,LAULNE,50430,LAULNE,,49.2506372168,-1.45537341043,265,,Laulne,Laulne,50,Manche,28,Normandie
50266,LENGRONNE,50450,LENGRONNE,,48.9338177836,-1.37830852349,266,,Lengronne,Lengronne,50,Manche,28,Normandie
50267,LESSAY,50430,LESSAY,,49.2167143742,-1.52478862014,267,,Lessay,Lessay,50,Manche,28,Normandie
50267,LESSAY,50430,LESSAY,ANGOVILLE SUR AY,49.2167143742,-1.52478862014,267,,Lessay,Lessay,50,Manche,28,Normandie
50268,LESTRE,50310,LESTRE,,49.5256131651,-1.32237994906,268,,Lestre,Lestre,50,Manche,28,Normandie
50269,LIESVILLE SUR DOUVE,50480,LIESVILLE SUR DOUVE,,49.3526847756,-1.322250003,269,,Liesville-sur-Douve,Liesville-sur-Douve,50,Manche,28,Normandie
50270,LIEUSAINT,50700,LIEUSAINT,,49.4836142567,-1.47249258747,270,,Lieusaint,Lieusaint,50,Manche,28,Normandie
50271,LINGEARD,50670,LINGEARD,,48.7381417691,-1.02635116604,271,,Lingeard,Lingeard,50,Manche,28,Normandie
50272,LINGREVILLE,50660,LINGREVILLE,,48.9432866634,-1.52855287697,272,,Lingreville,Lingreville,50,Manche,28,Normandie
50273,MONTSENELLE,50250,MONTSENELLE,COIGNY,49.2964016031,-1.48893562927,273,,Montsenelle,Montsenelle,50,Manche,28,Normandie
50273,MONTSENELLE,50250,MONTSENELLE,LITHAIRE,49.2964016031,-1.48893562927,273,,Montsenelle,Montsenelle,50,Manche,28,Normandie
50273,MONTSENELLE,50250,MONTSENELLE,PRETOT STE SUZANNE,49.2964016031,-1.48893562927,273,,Montsenelle,Montsenelle,50,Manche,28,Normandie
50273,MONTSENELLE,50250,MONTSENELLE,ST JORES,49.2964016031,-1.48893562927,273,,Montsenelle,Montsenelle,50,Manche,28,Normandie
50273,MONTSENELLE,50250,MONTSENELLE,STE SUZANNE EN BAUPTOIS,49.2964016031,-1.48893562927,273,,Montsenelle,Montsenelle,50,Manche,28,Normandie
50274,LES LOGES MARCHIS,50600,LES LOGES MARCHIS,,48.5342583933,-1.08560477293,274,Les,Loges-Marchis,Les Loges-Marchis,50,Manche,28,Normandie
50275,LES LOGES SUR BRECEY,50370,LES LOGES SUR BRECEY,,48.7566766172,-1.16089600587,275,Les,Loges-sur-Brécey,Les Loges-sur-Brécey,50,Manche,28,Normandie
50276,LOLIF,50530,LOLIF,,48.731700288,-1.39448203488,276,,Lolif,Lolif,50,Manche,28,Normandie
50277,LONGUEVILLE,50290,LONGUEVILLE,,48.8585636393,-1.5352864546,277,,Longueville,Longueville,50,Manche,28,Normandie
50278,LE LOREUR,50510,LE LOREUR,,48.8640911491,-1.42396294106,278,Le,Loreur,Le Loreur,50,Manche,28,Normandie
50279,LE LOREY,50570,LE LOREY,,49.0977430002,-1.30007991644,279,Le,Lorey,Le Lorey,50,Manche,28,Normandie
50281,LA LUCERNE D OUTREMER,50320,LA LUCERNE D OUTREMER,,48.788392012,-1.40995865422,281,La,Lucerne-d'Outremer,La Lucerne-d'Outremer,50,Manche,28,Normandie
50282,LE LUOT,50870,LE LUOT,,48.7661567656,-1.32946620609,282,Le,Luot,Le Luot,50,Manche,28,Normandie
50283,LA LUZERNE,50680,LA LUZERNE,,49.1413998381,-1.04606238276,283,La,Luzerne,La Luzerne,50,Manche,28,Normandie
50285,MAGNEVILLE,50260,MAGNEVILLE,,49.452860666,-1.5484188019,285,,Magneville,Magneville,50,Manche,28,Normandie
50288,MARCEY LES GREVES,50300,MARCEY LES GREVES,,48.7001007007,-1.38897452773,288,,Marcey-les-Grèves,Marcey-les-Grèves,50,Manche,28,Normandie
50289,MARCHESIEUX,50190,MARCHESIEUX,,49.1886083143,-1.30728128303,289,,Marchésieux,Marchésieux,50,Manche,28,Normandie
50290,MARCILLY,50220,MARCILLY,,48.653990075,-1.2442081655,290,,Marcilly,Marcilly,50,Manche,28,Normandie
50291,MARGUERAY,50410,MARGUERAY,,48.9003108376,-1.1263529355,291,,Margueray,Margueray,50,Manche,28,Normandie
50292,MARIGNY LE LOZON,50570,MARIGNY LE LOZON,,49.1042100375,-1.2522099973,292,,Marigny-Le-Lozon,Marigny-Le-Lozon,50,Manche,28,Normandie
50292,MARIGNY LE LOZON,50570,MARIGNY LE LOZON,LOZON,49.1042100375,-1.2522099973,292,,Marigny-Le-Lozon,Marigny-Le-Lozon,50,Manche,28,Normandie
50294,MARTINVAST,50690,MARTINVAST,,49.598183461,-1.64849604825,294,,Martinvast,Martinvast,50,Manche,28,Normandie
50295,MAUPERTUIS,50410,MAUPERTUIS,,48.9551865483,-1.20001198671,295,,Maupertuis,Maupertuis,50,Manche,28,Normandie
50296,MAUPERTUS SUR MER,50330,MAUPERTUS SUR MER,,49.6568745054,-1.48350640946,296,,Maupertus-sur-Mer,Maupertus-sur-Mer,50,Manche,28,Normandie
50297,LA MEAUFFE,50880,LA MEAUFFE,,49.1691596357,-1.09998439521,297,La,Meauffe,La Meauffe,50,Manche,28,Normandie
50298,MEAUTIS,50500,MEAUTIS,,49.280521992,-1.29573057994,298,,Méautis,Méautis,50,Manche,28,Normandie
50299,LE MESNIL,50580,LE MESNIL,,49.3719294322,-1.69334065015,299,Le,Mesnil,Le Mesnil,50,Manche,28,Normandie
50300,LE MESNIL ADELEE,50520,LE MESNIL ADELEE,,48.7002691908,-1.06280357498,300,Le,Mesnil-Adelée,Le Mesnil-Adelée,50,Manche,28,Normandie
50301,LE MESNIL AMAND,50450,LE MESNIL AMAND,,48.8773843427,-1.35994365275,301,Le,Mesnil-Amand,Le Mesnil-Amand,50,Manche,28,Normandie
50302,LE MESNIL AMEY,50570,LE MESNIL AMEY,,49.1056479231,-1.21160314829,302,Le,Mesnil-Amey,Le Mesnil-Amey,50,Manche,28,Normandie
50304,LE MESNIL AUBERT,50510,LE MESNIL AUBERT,,48.9444549365,-1.41515417901,304,Le,Mesnil-Aubert,Le Mesnil-Aubert,50,Manche,28,Normandie
50305,LE MESNIL AU VAL,50110,LE MESNIL AU VAL,,49.5983810722,-1.52768461283,305,Le,Mesnil-au-Val,Le Mesnil-au-Val,50,Manche,28,Normandie
50308,LE MESNILBUS,50490,LE MESNILBUS,,49.1346852772,-1.35267057421,308,Le,Mesnilbus,Le Mesnilbus,50,Manche,28,Normandie
50310,LE MESNIL EURY,50570,LE MESNIL EURY,,49.1618115328,-1.23538945959,310,Le,Mesnil-Eury,Le Mesnil-Eury,50,Manche,28,Normandie
50311,LE MESNIL GARNIER,50450,LE MESNIL GARNIER,,48.8654912425,-1.3045486883,311,Le,Mesnil-Garnier,Le Mesnil-Garnier,50,Manche,28,Normandie
50312,LE MESNIL GILBERT,50670,LE MESNIL GILBERT,,48.7221632657,-1.0569044647,312,Le,Mesnil-Gilbert,Le Mesnil-Gilbert,50,Manche,28,Normandie
50313,LE MESNIL HERMAN,50750,LE MESNIL HERMAN,,49.0206182429,-1.14456951626,313,Le,Mesnil-Herman,Le Mesnil-Herman,50,Manche,28,Normandie
50315,LE MESNILLARD,50600,LE MESNILLARD,,48.6310057924,-1.08070582492,315,Le,Mesnillard,Le Mesnillard,50,Manche,28,Normandie
50317,LE MESNIL OZENNE,50220,LE MESNIL OZENNE,,48.6675006123,-1.21767106141,317,Le,Mesnil-Ozenne,Le Mesnil-Ozenne,50,Manche,28,Normandie
50318,LE MESNIL RAINFRAY,50520,LE MESNIL RAINFRAY,,48.6620724926,-1.04831541531,318,Le,Mesnil-Rainfray,Le Mesnil-Rainfray,50,Manche,28,Normandie
50320,LE MESNIL ROGUES,50450,LE MESNIL ROGUES,,48.8595053152,-1.37785747364,320,Le,Mesnil-Rogues,Le Mesnil-Rogues,50,Manche,28,Normandie
50321,LE MESNIL ROUXELIN,50000,LE MESNIL ROUXELIN,,49.1423322166,-1.07833436341,321,Le,Mesnil-Rouxelin,Le Mesnil-Rouxelin,50,Manche,28,Normandie
50323,LE MESNIL TOVE,50520,LE MESNIL TOVE,,48.6926473211,-1.01584086129,323,Le,Mesnil-Tôve,Le Mesnil-Tôve,50,Manche,28,Normandie
50324,LE MESNIL VENERON,50620,LE MESNIL VENERON,,49.2259215456,-1.16299457412,324,Le,Mesnil-Véneron,Le Mesnil-Véneron,50,Manche,28,Normandie
50325,LE MESNIL VIGOT,50570,LE MESNIL VIGOT,,49.1615348173,-1.27754584093,325,Le,Mesnil-Vigot,Le Mesnil-Vigot,50,Manche,28,Normandie
50326,LE MESNIL VILLEMAN,50450,LE MESNIL VILLEMAN,,48.8563586641,-1.34036490176,326,Le,Mesnil-Villeman,Le Mesnil-Villeman,50,Manche,28,Normandie
50327,LA MEURDRAQUIERE,50510,LA MEURDRAQUIERE,,48.8607789195,-1.40495640543,327,La,Meurdraquière,La Meurdraquière,50,Manche,28,Normandie
50328,MILLIERES,50190,MILLIERES,,49.1906741242,-1.45514260552,328,,Millières,Millières,50,Manche,28,Normandie
50332,LES MOITIERS D ALLONNE,50270,LES MOITIERS D ALLONNE,,49.4032073739,-1.78595352218,332,Les,Moitiers-d'Allonne,Les Moitiers-d'Allonne,50,Manche,28,Normandie
50333,LES MOITIERS EN BAUPTOIS,50360,LES MOITIERS EN BAUPTOIS,,49.3599012108,-1.4321785112,333,Les,Moitiers-en-Bauptois,Les Moitiers-en-Bauptois,50,Manche,28,Normandie
50334,MONTABOT,50410,MONTABOT,,48.936758668,-1.13220070022,334,,Montabot,Montabot,50,Manche,28,Normandie
50335,MONTAIGU LA BRISETTE,50700,MONTAIGU LA BRISETTE,,49.5672343716,-1.42035332441,335,,Montaigu-la-Brisette,Montaigu-la-Brisette,50,Manche,28,Normandie
50336,MONTAIGU LES BOIS,50450,MONTAIGU LES BOIS,,48.8911102203,-1.27021811882,336,,Montaigu-les-Bois,Montaigu-les-Bois,50,Manche,28,Normandie
50337,MONTANEL,50240,MONTANEL,,48.481558318,-1.40890718989,337,,Montanel,Montanel,50,Manche,28,Normandie
50338,MONTBRAY,50410,MONTBRAY,,48.8830810349,-1.10930704314,338,,Montbray,Montbray,50,Manche,28,Normandie
50340,MONTCUIT,50490,MONTCUIT,,49.1158136189,-1.34654440183,340,,Montcuit,Montcuit,50,Manche,28,Normandie
50341,MONTEBOURG,50310,MONTEBOURG,,49.4973576116,-1.38713307717,341,,Montebourg,Montebourg,50,Manche,28,Normandie
50342,MONTFARVILLE,50760,MONTFARVILLE,,49.6556616978,-1.26338019218,342,,Montfarville,Montfarville,50,Manche,28,Normandie
50345,MONTHUCHON,50200,MONTHUCHON,,49.0881773625,-1.42023463837,345,,Monthuchon,Monthuchon,50,Manche,28,Normandie
50347,MONTJOIE ST MARTIN,50240,MONTJOIE ST MARTIN,,48.5257397216,-1.28858197079,347,,Montjoie-Saint-Martin,Montjoie-Saint-Martin,50,Manche,28,Normandie
50348,MONTMARTIN EN GRAIGNES,50620,MONTMARTIN EN GRAIGNES,,49.2674470247,-1.16552700264,348,,Montmartin-en-Graignes,Montmartin-en-Graignes,50,Manche,28,Normandie
50349,MONTMARTIN SUR MER,50590,MONTMARTIN SUR MER,,48.9910212765,-1.52994382531,349,,Montmartin-sur-Mer,Montmartin-sur-Mer,50,Manche,28,Normandie
50350,MONTPINCHON,50210,MONTPINCHON,,49.0263320805,-1.32027175268,350,,Montpinchon,Montpinchon,50,Manche,28,Normandie
50351,MONTRABOT,50810,MONTRABOT,,49.1078812835,-0.896591935347,351,,Montrabot,Montrabot,50,Manche,28,Normandie
50352,MONTREUIL SUR LOZON,50570,MONTREUIL SUR LOZON,,49.1333974057,-1.24331405559,352,,Montreuil-sur-Lozon,Montreuil-sur-Lozon,50,Manche,28,Normandie
50353,LE MONT ST MICHEL,50170,LE MONT ST MICHEL,,48.6222106276,-1.53309065436,353,Le,Mont-Saint-Michel,Le Mont-Saint-Michel,50,Manche,28,Normandie
50354,MONTSURVENT,50200,MONTSURVENT,,49.1082682832,-1.51110962777,354,,Montsurvent,Montsurvent,50,Manche,28,Normandie
50356,MOON SUR ELLE,50680,MOON SUR ELLE,,49.2037429192,-1.05390925446,356,,Moon-sur-Elle,Moon-sur-Elle,50,Manche,28,Normandie
50357,MORIGNY,50410,MORIGNY,,48.8814109111,-1.06958962493,357,,Morigny,Morigny,50,Manche,28,Normandie
50358,MORSALINES,50630,MORSALINES,,49.5700693753,-1.31780369049,358,,Morsalines,Morsalines,50,Manche,28,Normandie
50359,MORTAIN BOCAGE,50140,MORTAIN BOCAGE,,48.6474805702,-0.931721848821,359,,Mortain-Bocage,Mortain-Bocage,50,Manche,28,Normandie
50359,MORTAIN BOCAGE,50140,MORTAIN BOCAGE,BION,48.6474805702,-0.931721848821,359,,Mortain-Bocage,Mortain-Bocage,50,Manche,28,Normandie
50359,MORTAIN BOCAGE,50140,MORTAIN BOCAGE,NOTRE DAME DU TOUCHET,48.6474805702,-0.931721848821,359,,Mortain-Bocage,Mortain-Bocage,50,Manche,28,Normandie
50359,MORTAIN BOCAGE,50140,MORTAIN BOCAGE,ST JEAN DU CORAIL,48.6474805702,-0.931721848821,359,,Mortain-Bocage,Mortain-Bocage,50,Manche,28,Normandie
50359,MORTAIN BOCAGE,50140,MORTAIN BOCAGE,VILLECHIEN,48.6474805702,-0.931721848821,359,,Mortain-Bocage,Mortain-Bocage,50,Manche,28,Normandie
50360,MORVILLE,50700,MORVILLE,,49.4744014366,-1.51700030481,360,,Morville,Morville,50,Manche,28,Normandie
50361,LA MOUCHE,50320,LA MOUCHE,,48.7848809108,-1.3527392237,361,La,Mouche,La Mouche,50,Manche,28,Normandie
50362,MOULINES,50600,MOULINES,,48.5485016323,-1.0455527654,362,,Moulines,Moulines,50,Manche,28,Normandie
50363,MOYON VILLAGES,50420,MOYON VILLAGES,CHEVRY,48.9941243257,-1.13665567668,363,,Moyon Villages,Moyon Villages,50,Manche,28,Normandie
50363,MOYON VILLAGES,50860,MOYON VILLAGES,,48.9941243257,-1.13665567668,363,,Moyon Villages,Moyon Villages,50,Manche,28,Normandie
50363,MOYON VILLAGES,50860,MOYON VILLAGES,LE MESNIL OPAC,48.9941243257,-1.13665567668,363,,Moyon Villages,Moyon Villages,50,Manche,28,Normandie
50364,MUNEVILLE LE BINGARD,50490,MUNEVILLE LE BINGARD,,49.1367650132,-1.4760994419,364,,Muneville-le-Bingard,Muneville-le-Bingard,50,Manche,28,Normandie
50365,MUNEVILLE SUR MER,50290,MUNEVILLE SUR MER,,48.9364819167,-1.48702600548,365,,Muneville-sur-Mer,Muneville-sur-Mer,50,Manche,28,Normandie
50368,NAY,50190,NAY,,49.2418464549,-1.37167813409,368,,Nay,Nay,50,Manche,28,Normandie
50369,NEGREVILLE,50260,NEGREVILLE,,49.5039052057,-1.55157352152,369,,Négreville,Négreville,50,Manche,28,Normandie
50370,NEHOU,50390,NEHOU,,49.4262375768,-1.56321330148,370,,Néhou,Néhou,50,Manche,28,Normandie
50371,LE NEUFBOURG,50140,LE NEUFBOURG,,48.6630193769,-0.947435614235,371,Le,Neufbourg,Le Neufbourg,50,Manche,28,Normandie
50372,NEUFMESNIL,50250,NEUFMESNIL,,49.310933741,-1.52894483942,372,,Neufmesnil,Neufmesnil,50,Manche,28,Normandie
50373,NEUVILLE AU PLAIN,50480,NEUVILLE AU PLAIN,,49.4241715154,-1.33468334659,373,,Neuville-au-Plain,Neuville-au-Plain,50,Manche,28,Normandie
50374,NEUVILLE EN BEAUMONT,50250,NEUVILLE EN BEAUMONT,,49.3502533597,-1.59768959905,374,,Neuville-en-Beaumont,Neuville-en-Beaumont,50,Manche,28,Normandie
50376,NICORPS,50200,NICORPS,,49.0313601381,-1.41066233816,376,,Nicorps,Nicorps,50,Manche,28,Normandie
50378,NOTRE DAME DE CENILLY,50210,NOTRE DAME DE CENILLY,,48.9982461228,-1.25757304935,378,,Notre-Dame-de-Cenilly,Notre-Dame-de-Cenilly,50,Manche,28,Normandie
50379,NOTRE DAME DE LIVOYE,50370,NOTRE DAME DE LIVOYE,,48.7471616775,-1.19991486968,379,,Notre-Dame-de-Livoye,Notre-Dame-de-Livoye,50,Manche,28,Normandie
50382,NOUAINVILLE,50690,NOUAINVILLE,,49.6200133969,-1.68096572433,382,,Nouainville,Nouainville,50,Manche,28,Normandie
50384,OCTEVILLE L AVENEL,50630,OCTEVILLE L AVENEL,,49.5468058643,-1.36608867195,384,,Octeville-l'Avenel,Octeville-l'Avenel,50,Manche,28,Normandie
50385,OMONVILLE LA PETITE,50440,OMONVILLE LA PETITE,,49.6929987074,-1.88472538238,385,,Omonville-la-Petite,Omonville-la-Petite,50,Manche,28,Normandie
50386,OMONVILLE LA ROGUE,50440,OMONVILLE LA ROGUE,,49.6968358341,-1.84105189922,386,,Omonville-la-Rogue,Omonville-la-Rogue,50,Manche,28,Normandie
50387,ORGLANDES,50390,ORGLANDES,,49.4240734293,-1.44023948223,387,,Orglandes,Orglandes,50,Manche,28,Normandie
50388,ORVAL SUR SIENNE,50660,ORVAL SUR SIENNE,,49.0114256149,-1.46437538722,388,,Orval sur Sienne,Orval sur Sienne,50,Manche,28,Normandie
50388,ORVAL SUR SIENNE,50660,ORVAL SUR SIENNE,MONTCHATON,49.0114256149,-1.46437538722,388,,Orval sur Sienne,Orval sur Sienne,50,Manche,28,Normandie
50389,OUVILLE,50210,OUVILLE,,49.020748865,-1.36684952643,389,,Ouville,Ouville,50,Manche,28,Normandie
50390,OZEVILLE,50310,OZEVILLE,,49.5039632158,-1.34095393925,390,,Ozeville,Ozeville,50,Manche,28,Normandie
50391,GRANDPARIGNY,50600,GRANDPARIGNY,CHEVREVILLE,48.5937472635,-1.08234718807,391,,Grandparigny,Grandparigny,50,Manche,28,Normandie
50391,GRANDPARIGNY,50600,GRANDPARIGNY,MARTIGNY,48.5937472635,-1.08234718807,391,,Grandparigny,Grandparigny,50,Manche,28,Normandie
50391,GRANDPARIGNY,50600,GRANDPARIGNY,MILLY,48.5937472635,-1.08234718807,391,,Grandparigny,Grandparigny,50,Manche,28,Normandie
50391,GRANDPARIGNY,50600,GRANDPARIGNY,PARIGNY,48.5937472635,-1.08234718807,391,,Grandparigny,Grandparigny,50,Manche,28,Normandie
50393,PERCY EN NORMANDIE,50410,PERCY EN NORMANDIE,,48.9174601419,-1.2052472339,393,,Percy-en-Normandie,Percy-en-Normandie,50,Manche,28,Normandie
50393,PERCY EN NORMANDIE,50410,PERCY EN NORMANDIE,LE CHEFRESNE,48.9174601419,-1.2052472339,393,,Percy-en-Normandie,Percy-en-Normandie,50,Manche,28,Normandie
50394,PERIERS,50190,PERIERS,,49.1918062542,-1.41249922765,394,,Périers,Périers,50,Manche,28,Normandie
50395,LA PERNELLE,50630,LA PERNELLE,,49.6131883307,-1.30916407089,395,La,Pernelle,La Pernelle,50,Manche,28,Normandie
50397,PERRIERS EN BEAUFICEL,50150,PERRIERS EN BEAUFICEL,,48.7361688634,-0.999617410349,397,,Perriers-en-Beauficel,Perriers-en-Beauficel,50,Manche,28,Normandie
50398,LE PERRON,50160,LE PERRON,,49.0556226265,-0.893077920494,398,Le,Perron,Le Perron,50,Manche,28,Normandie
50399,LE PETIT CELLAND,50370,LE PETIT CELLAND,,48.6976542457,-1.21523678819,399,Le,Petit-Celland,Le Petit-Celland,50,Manche,28,Normandie
50400,PICAUVILLE,50250,PICAUVILLE,CRETTEVILLE,49.3794617832,-1.3877872154,400,,Picauville,Picauville,50,Manche,28,Normandie
50400,PICAUVILLE,50250,PICAUVILLE,HOUTTEVILLE,49.3794617832,-1.3877872154,400,,Picauville,Picauville,50,Manche,28,Normandie
50400,PICAUVILLE,50250,PICAUVILLE,VINDEFONTAINE,49.3794617832,-1.3877872154,400,,Picauville,Picauville,50,Manche,28,Normandie
50400,PICAUVILLE,50360,PICAUVILLE,,49.3794617832,-1.3877872154,400,,Picauville,Picauville,50,Manche,28,Normandie
50400,PICAUVILLE,50480,PICAUVILLE,AMFREVILLE,49.3794617832,-1.3877872154,400,,Picauville,Picauville,50,Manche,28,Normandie
50400,PICAUVILLE,50480,PICAUVILLE,GOURBESVILLE,49.3794617832,-1.3877872154,400,,Picauville,Picauville,50,Manche,28,Normandie
50401,PIERREVILLE,50340,PIERREVILLE,,49.4674285357,-1.77803993341,401,,Pierreville,Pierreville,50,Manche,28,Normandie
50402,LES PIEUX,50340,LES PIEUX,,49.5103059572,-1.82036292827,402,Les,Pieux,Les Pieux,50,Manche,28,Normandie
50403,PIROU,50770,PIROU,,49.1646654807,-1.55903985715,403,,Pirou,Pirou,50,Manche,28,Normandie
50404,PLACY MONTAIGU,50160,PLACY MONTAIGU,,49.030987332,-0.893388943271,404,,Placy-Montaigu,Placy-Montaigu,50,Manche,28,Normandie
50405,LE PLESSIS LASTELLE,50250,LE PLESSIS LASTELLE,,49.2791657048,-1.435102599,405,Le,Plessis-Lastelle,Le Plessis-Lastelle,50,Manche,28,Normandie
50407,POILLEY,50220,POILLEY,,48.617924447,-1.32049882164,407,,Poilley,Poilley,50,Manche,28,Normandie
50408,PONTAUBAULT,50220,PONTAUBAULT,,48.6249468178,-1.35394926962,408,,Pontaubault,Pontaubault,50,Manche,28,Normandie
50409,PONT HEBERT,50880,PONT HEBERT,,49.1634595987,-1.15107589364,409,,Pont-Hébert,Pont-Hébert,50,Manche,28,Normandie
50410,PONTORSON,50170,PONTORSON,,48.5689721753,-1.48653363271,410,,Pontorson,Pontorson,50,Manche,28,Normandie
50410,PONTORSON,50170,PONTORSON,ARDEVON,48.5689721753,-1.48653363271,410,,Pontorson,Pontorson,50,Manche,28,Normandie
50410,PONTORSON,50170,PONTORSON,BOUCEY,48.5689721753,-1.48653363271,410,,Pontorson,Pontorson,50,Manche,28,Normandie
50410,PONTORSON,50170,PONTORSON,CORMERAY,48.5689721753,-1.48653363271,410,,Pontorson,Pontorson,50,Manche,28,Normandie
50410,PONTORSON,50170,PONTORSON,CUREY,48.5689721753,-1.48653363271,410,,Pontorson,Pontorson,50,Manche,28,Normandie
50410,PONTORSON,50170,PONTORSON,LES PAS,48.5689721753,-1.48653363271,410,,Pontorson,Pontorson,50,Manche,28,Normandie
50410,PONTORSON,50170,PONTORSON,MACEY,48.5689721753,-1.48653363271,410,,Pontorson,Pontorson,50,Manche,28,Normandie
50410,PONTORSON,50170,PONTORSON,MOIDREY,48.5689721753,-1.48653363271,410,,Pontorson,Pontorson,50,Manche,28,Normandie
50410,PONTORSON,50170,PONTORSON,VESSEY,48.5689721753,-1.48653363271,410,,Pontorson,Pontorson,50,Manche,28,Normandie
50411,PONTS,50300,PONTS,,48.7112854871,-1.33485986216,411,,Ponts,Ponts,50,Manche,28,Normandie
50412,PORTBAIL,50580,PORTBAIL,,49.3520385149,-1.69541641793,412,,Portbail,Portbail,50,Manche,28,Normandie
50413,PRECEY,50220,PRECEY,,48.6057042474,-1.37641793764,413,,Précey,Précey,50,Manche,28,Normandie
50417,QUETTEHOU,50630,QUETTEHOU,,49.5883938646,-1.32408087794,417,,Quettehou,Quettehou,50,Manche,28,Normandie
50419,QUETTREVILLE SUR SIENNE,50660,QUETTREVILLE SUR SIENNE,,48.9618413224,-1.46914956989,419,,Quettreville-sur-Sienne,Quettreville-sur-Sienne,50,Manche,28,Normandie
50419,QUETTREVILLE SUR SIENNE,50660,QUETTREVILLE SUR SIENNE,HYENVILLE,48.9618413224,-1.46914956989,419,,Quettreville-sur-Sienne,Quettreville-sur-Sienne,50,Manche,28,Normandie
50420,QUIBOU,50750,QUIBOU,,49.0718984354,-1.2132232691,420,,Quibou,Quibou,50,Manche,28,Normandie
50421,QUINEVILLE,50310,QUINEVILLE,,49.5108915382,-1.30534056299,421,,Quinéville,Quinéville,50,Manche,28,Normandie
50422,RAIDS,50500,RAIDS,,49.2079341726,-1.34553076662,422,,Raids,Raids,50,Manche,28,Normandie
50423,RAMPAN,50000,RAMPAN,,49.1464510934,-1.13114695392,423,,Rampan,Rampan,50,Manche,28,Normandie
50425,RAUVILLE LA BIGOT,50260,RAUVILLE LA BIGOT,,49.5174637964,-1.66546170195,425,,Rauville-la-Bigot,Rauville-la-Bigot,50,Manche,28,Normandie
50426,RAUVILLE LA PLACE,50390,RAUVILLE LA PLACE,,49.390519859,-1.5015509856,426,,Rauville-la-Place,Rauville-la-Place,50,Manche,28,Normandie
50427,RAVENOVILLE,50480,RAVENOVILLE,,49.456333218,-1.27348224203,427,,Ravenoville,Ravenoville,50,Manche,28,Normandie
50428,REFFUVEILLE,50520,REFFUVEILLE,,48.6773659485,-1.11165024883,428,,Reffuveille,Reffuveille,50,Manche,28,Normandie
50429,REGNEVILLE SUR MER,50590,REGNEVILLE SUR MER,,49.0157487171,-1.53843430747,429,,Regnéville-sur-Mer,Regnéville-sur-Mer,50,Manche,28,Normandie
50430,REIGNEVILLE BOCAGE,50390,REIGNEVILLE BOCAGE,,49.4109584119,-1.47453769549,430,,Reigneville-Bocage,Reigneville-Bocage,50,Manche,28,Normandie
50431,REMILLY SUR LOZON,50570,REMILLY SUR LOZON,,49.1797139942,-1.26085995101,431,,Remilly Les Marais,Remilly Les Marais,50,Manche,28,Normandie
50433,REVILLE,50760,REVILLE,,49.6261392194,-1.25142117163,433,,Réville,Réville,50,Manche,28,Normandie
50435,ROCHEVILLE,50260,ROCHEVILLE,,49.5001421824,-1.59313956631,435,,Rocheville,Rocheville,50,Manche,28,Normandie
50436,ROMAGNY FONTENAY,50140,ROMAGNY FONTENAY,,48.6374333349,-0.98072243352,436,,Romagny Fontenay,Romagny Fontenay,50,Manche,28,Normandie
50436,ROMAGNY FONTENAY,50140,ROMAGNY FONTENAY,FONTENAY,48.6374333349,-0.98072243352,436,,Romagny Fontenay,Romagny Fontenay,50,Manche,28,Normandie
50437,RONCEY,50210,RONCEY,,48.9835239687,-1.33024151632,437,,Roncey,Roncey,50,Manche,28,Normandie
50438,LA RONDE HAYE,50490,LA RONDE HAYE,,49.1268697579,-1.44258497048,438,La,Ronde-Haye,La Ronde-Haye,50,Manche,28,Normandie
50442,LE ROZEL,50340,LE ROZEL,,49.4814801103,-1.82868622801,442,Le,Rozel,Le Rozel,50,Manche,28,Normandie
50443,SACEY,50170,SACEY,,48.4995771205,-1.4595082329,443,,Sacey,Sacey,50,Manche,28,Normandie
50444,ST AMAND,50160,ST AMAND,,49.0444946813,-0.948478098898,444,,Saint-Amand-Villages,Saint-Amand-Villages,50,Manche,28,Normandie
50444,ST AMAND,50160,ST AMAND,LA CHAPELLE DU FEST,49.0444946813,-0.948478098898,444,,Saint-Amand-Villages,Saint-Amand-Villages,50,Manche,28,Normandie
50444,ST AMAND,50160,ST AMAND,ST SYMPHORIEN LES BUTTES,49.0444946813,-0.948478098898,444,,Saint-Amand-Villages,Saint-Amand-Villages,50,Manche,28,Normandie
50445,ST ANDRE DE BOHON,50500,ST ANDRE DE BOHON,,49.2389765261,-1.24797081821,445,,Saint-André-de-Bohon,Saint-André-de-Bohon,50,Manche,28,Normandie
50446,ST ANDRE DE L EPINE,50680,ST ANDRE DE L EPINE,,49.1337500931,-1.02272343031,446,,Saint-André-de-l'Épine,Saint-André-de-l'Épine,50,Manche,28,Normandie
50447,ST AUBIN DES PREAUX,50380,ST AUBIN DES PREAUX,,48.8064090554,-1.50085003989,447,,Saint-Aubin-des-Préaux,Saint-Aubin-des-Préaux,50,Manche,28,Normandie
50448,ST AUBIN DE TERREGATTE,50240,ST AUBIN DE TERREGATTE,,48.5703247813,-1.28722870629,448,,Saint-Aubin-de-Terregatte,Saint-Aubin-de-Terregatte,50,Manche,28,Normandie
50449,ST AUBIN DU PERRON,50490,ST AUBIN DU PERRON,,49.1531494774,-1.37994678052,449,,Saint-Aubin-du-Perron,Saint-Aubin-du-Perron,50,Manche,28,Normandie
50450,ST BARTHELEMY,50140,ST BARTHELEMY,,48.6842500413,-0.950654669015,450,,Saint-Barthélemy,Saint-Barthélemy,50,Manche,28,Normandie
50451,ST BRICE,50300,ST BRICE,,48.699142426,-1.2977589729,451,,Saint-Brice,Saint-Brice,50,Manche,28,Normandie
50452,ST BRICE DE LANDELLES,50730,ST BRICE DE LANDELLES,,48.5412096046,-1.13243584408,452,,Saint-Brice-de-Landelles,Saint-Brice-de-Landelles,50,Manche,28,Normandie
50453,STE CECILE,50800,STE CECILE,,48.8335140152,-1.18922933897,453,,Sainte-Cécile,Sainte-Cécile,50,Manche,28,Normandie
50454,ST CHRISTOPHE DU FOC,50340,ST CHRISTOPHE DU FOC,,49.5546561276,-1.73523737028,454,,Saint-Christophe-du-Foc,Saint-Christophe-du-Foc,50,Manche,28,Normandie
50455,ST CLAIR SUR L ELLE,50680,ST CLAIR SUR L ELLE,,49.1851844677,-1.04046067538,455,,Saint-Clair-sur-l'Elle,Saint-Clair-sur-l'Elle,50,Manche,28,Normandie
50456,ST CLEMENT RANCOUDRAY,50140,ST CLEMENT RANCOUDRAY,,48.6689629346,-0.890384424865,456,,Saint-Clément-Rancoudray,Saint-Clément-Rancoudray,50,Manche,28,Normandie
50456,ST CLEMENT RANCOUDRAY,50140,ST CLEMENT RANCOUDRAY,RANCOUDRAY,48.6689629346,-0.890384424865,456,,Saint-Clément-Rancoudray,Saint-Clément-Rancoudray,50,Manche,28,Normandie
50457,STE COLOMBE,50390,STE COLOMBE,,49.4151457162,-1.51172159828,457,,Sainte-Colombe,Sainte-Colombe,50,Manche,28,Normandie
50460,STE CROIX HAGUE,50440,STE CROIX HAGUE,,49.642621281,-1.76503469066,460,,Sainte-Croix-Hague,Sainte-Croix-Hague,50,Manche,28,Normandie
50461,ST CYR,50310,ST CYR,,49.4869134023,-1.415719419,461,,Saint-Cyr,Saint-Cyr,50,Manche,28,Normandie
50462,ST CYR DU BAILLEUL,50720,ST CYR DU BAILLEUL,,48.5678803644,-0.826779942958,462,,Saint-Cyr-du-Bailleul,Saint-Cyr-du-Bailleul,50,Manche,28,Normandie
50463,ST DENIS LE GAST,50450,ST DENIS LE GAST,,48.9438664581,-1.32723799683,463,,Saint-Denis-le-Gast,Saint-Denis-le-Gast,50,Manche,28,Normandie
50464,ST DENIS LE VETU,50210,ST DENIS LE VETU,,48.9932776669,-1.37792309863,464,,Saint-Denis-le-Vêtu,Saint-Denis-le-Vêtu,50,Manche,28,Normandie
50465,ST EBREMOND DE BONFOSSE,50750,ST EBREMOND DE BONFOSSE,,49.0726923267,-1.14523794079,465,,Saint-Ébremond-de-Bonfossé,Saint-Ébremond-de-Bonfossé,50,Manche,28,Normandie
50467,ST FLOXEL,50310,ST FLOXEL,,49.4885809022,-1.35724625484,467,,Saint-Floxel,Saint-Floxel,50,Manche,28,Normandie
50468,ST FROMOND,50620,ST FROMOND,,49.2257606006,-1.10862929646,468,,Saint-Fromond,Saint-Fromond,50,Manche,28,Normandie
50469,STE GENEVIEVE,50760,STE GENEVIEVE,,49.6595933268,-1.3069626688,469,,Sainte-Geneviève,Sainte-Geneviève,50,Manche,28,Normandie
50471,ST GEORGES DE LA RIVIERE,50270,ST GEORGES DE LA RIVIERE,,49.3630192969,-1.72971750237,471,,Saint-Georges-de-la-Rivière,Saint-Georges-de-la-Rivière,50,Manche,28,Normandie
50472,ST GEORGES DE LIVOYE,50370,ST GEORGES DE LIVOYE,,48.7372655684,-1.2227039529,472,,Saint-Georges-de-Livoye,Saint-Georges-de-Livoye,50,Manche,28,Normandie
50473,ST GEORGES D ELLE,50680,ST GEORGES D ELLE,,49.1477280514,-0.978093745627,473,,Saint-Georges-d'Elle,Saint-Georges-d'Elle,50,Manche,28,Normandie
50474,ST GEORGES DE ROUELLEY,50720,ST GEORGES DE ROUELLEY,,48.614962159,-0.775364778574,474,,Saint-Georges-de-Rouelley,Saint-Georges-de-Rouelley,50,Manche,28,Normandie
50475,ST GEORGES MONTCOCQ,50000,ST GEORGES MONTCOCQ,,49.1351168575,-1.10954006058,475,,Saint-Georges-Montcocq,Saint-Georges-Montcocq,50,Manche,28,Normandie
50476,ST GERMAIN D ELLE,50810,ST GERMAIN D ELLE,,49.1197755158,-0.920856737433,476,,Saint-Germain-d'Elle,Saint-Germain-d'Elle,50,Manche,28,Normandie
50477,ST GERMAIN DES VAUX,50440,ST GERMAIN DES VAUX,,49.7106836928,-1.91336275749,477,,Saint-Germain-des-Vaux,Saint-Germain-des-Vaux,50,Manche,28,Normandie
50478,ST GERMAIN DE TOURNEBUT,50700,ST GERMAIN DE TOURNEBUT,,49.527450489,-1.40278389673,478,,Saint-Germain-de-Tournebut,Saint-Germain-de-Tournebut,50,Manche,28,Normandie
50479,ST GERMAIN DE VARREVILLE,50480,ST GERMAIN DE VARREVILLE,,49.4391006116,-1.24467615745,479,,Saint-Germain-de-Varreville,Saint-Germain-de-Varreville,50,Manche,28,Normandie
50480,ST GERMAIN LE GAILLARD,50340,ST GERMAIN LE GAILLARD,,49.4890990838,-1.77283879309,480,,Saint-Germain-le-Gaillard,Saint-Germain-le-Gaillard,50,Manche,28,Normandie
50481,ST GERMAIN SUR AY,50430,ST GERMAIN SUR AY,,49.2376498334,-1.60885612868,481,,Saint-Germain-sur-Ay,Saint-Germain-sur-Ay,50,Manche,28,Normandie
50482,ST GERMAIN SUR SEVES,50190,ST GERMAIN SUR SEVES,,49.2200351465,-1.37670798974,482,,Saint-Germain-sur-Sèves,Saint-Germain-sur-Sèves,50,Manche,28,Normandie
50483,ST GILLES,50180,ST GILLES,,49.0969481522,-1.16151864411,483,,Saint-Gilles,Saint-Gilles,50,Manche,28,Normandie
50484,ST HILAIRE DU HARCOUET,50600,ST HILAIRE DU HARCOUET,,48.5720535322,-1.08283897698,484,,Saint-Hilaire-du-Harcouët,Saint-Hilaire-du-Harcouët,50,Manche,28,Normandie
50484,ST HILAIRE DU HARCOUET,50600,ST HILAIRE DU HARCOUET,VIREY,48.5720535322,-1.08283897698,484,,Saint-Hilaire-du-Harcouët,Saint-Hilaire-du-Harcouët,50,Manche,28,Normandie
50484,ST HILAIRE DU HARCOUET,50730,ST HILAIRE DU HARCOUET,ST MARTIN DE LANDELLES,48.5720535322,-1.08283897698,484,,Saint-Hilaire-du-Harcouët,Saint-Hilaire-du-Harcouët,50,Manche,28,Normandie
50485,ST HILAIRE PETITVILLE,50500,ST HILAIRE PETITVILLE,,49.3035990702,-1.21788567702,485,,Saint-Hilaire-Petitville,Saint-Hilaire-Petitville,50,Manche,28,Normandie
50486,ST JACQUES DE NEHOU,50390,ST JACQUES DE NEHOU,,49.4201355471,-1.61663746279,486,,Saint-Jacques-de-Néhou,Saint-Jacques-de-Néhou,50,Manche,28,Normandie
50487,ST JAMES,50240,ST JAMES,,48.5257483373,-1.32634914406,487,,Saint-James,Saint-James,50,Manche,28,Normandie
50488,ST JEAN DE DAYE,50620,ST JEAN DE DAYE,,49.231738279,-1.13630108744,488,,Saint-Jean-de-Daye,Saint-Jean-de-Daye,50,Manche,28,Normandie
50489,ST JEAN DE LA HAIZE,50300,ST JEAN DE LA HAIZE,,48.7198424785,-1.35962175748,489,,Saint-Jean-de-la-Haize,Saint-Jean-de-la-Haize,50,Manche,28,Normandie
50490,ST JEAN DE LA RIVIERE,50270,ST JEAN DE LA RIVIERE,,49.3687683933,-1.73964743222,490,,Saint-Jean-de-la-Rivière,Saint-Jean-de-la-Rivière,50,Manche,28,Normandie
50491,ST JEAN DE SAVIGNY,50680,ST JEAN DE SAVIGNY,,49.1886831817,-1.00370068666,491,,Saint-Jean-de-Savigny,Saint-Jean-de-Savigny,50,Manche,28,Normandie
50492,ST JEAN D ELLE,50810,ST JEAN D ELLE,,49.0936069017,-0.991213654539,492,,Saint-Jean-d'Elle,Saint-Jean-d'Elle,50,Manche,28,Normandie
50492,ST JEAN D ELLE,50810,ST JEAN D ELLE,NOTRE DAME D ELLE,49.0936069017,-0.991213654539,492,,Saint-Jean-d'Elle,Saint-Jean-d'Elle,50,Manche,28,Normandie
50492,ST JEAN D ELLE,50810,ST JEAN D ELLE,PRECORBIN,49.0936069017,-0.991213654539,492,,Saint-Jean-d'Elle,Saint-Jean-d'Elle,50,Manche,28,Normandie
50492,ST JEAN D ELLE,50810,ST JEAN D ELLE,ROUXEVILLE,49.0936069017,-0.991213654539,492,,Saint-Jean-d'Elle,Saint-Jean-d'Elle,50,Manche,28,Normandie
50492,ST JEAN D ELLE,50810,ST JEAN D ELLE,VIDOUVILLE,49.0936069017,-0.991213654539,492,,Saint-Jean-d'Elle,Saint-Jean-d'Elle,50,Manche,28,Normandie
50493,ST JEAN DES CHAMPS,50320,ST JEAN DES CHAMPS,,48.8240464448,-1.46346833183,493,,Saint-Jean-des-Champs,Saint-Jean-des-Champs,50,Manche,28,Normandie
50493,ST JEAN DES CHAMPS,50320,ST JEAN DES CHAMPS,ST LEGER,48.8240464448,-1.46346833183,493,,Saint-Jean-des-Champs,Saint-Jean-des-Champs,50,Manche,28,Normandie
50493,ST JEAN DES CHAMPS,50320,ST JEAN DES CHAMPS,ST URSIN,48.8240464448,-1.46346833183,493,,Saint-Jean-des-Champs,Saint-Jean-des-Champs,50,Manche,28,Normandie
50495,ST JEAN DU CORAIL DES BOIS,50370,ST JEAN DU CORAIL DES BOIS,,48.7721392247,-1.20394577611,495,,Saint-Jean-du-Corail-des-Bois,Saint-Jean-du-Corail-des-Bois,50,Manche,28,Normandie
50496,ST JEAN LE THOMAS,50530,ST JEAN LE THOMAS,,48.7269044295,-1.51493536559,496,,Saint-Jean-le-Thomas,Saint-Jean-le-Thomas,50,Manche,28,Normandie
50498,ST JOSEPH,50700,ST JOSEPH,,49.5317896214,-1.52353706492,498,,Saint-Joseph,Saint-Joseph,50,Manche,28,Normandie
50499,ST LAURENT DE CUVES,50670,ST LAURENT DE CUVES,,48.7494442333,-1.11520234761,499,,Saint-Laurent-de-Cuves,Saint-Laurent-de-Cuves,50,Manche,28,Normandie
50500,ST LAURENT DE TERREGATTE,50240,ST LAURENT DE TERREGATTE,,48.5707591468,-1.24632871909,500,,Saint-Laurent-de-Terregatte,Saint-Laurent-de-Terregatte,50,Manche,28,Normandie
50502,ST LO,50000,ST LO,,49.1099624249,-1.07755642702,502,,Saint-Lô,Saint-Lô,50,Manche,28,Normandie
50503,ST LO D OURVILLE,50580,ST LO D OURVILLE,,49.3317884523,-1.67378144777,503,,Saint-Lô-d'Ourville,Saint-Lô-d'Ourville,50,Manche,28,Normandie
50504,ST LOUET SUR VIRE,50420,ST LOUET SUR VIRE,,48.9940543586,-0.987194724514,504,,Saint-Louet-sur-Vire,Saint-Louet-sur-Vire,50,Manche,28,Normandie
50505,ST LOUP,50300,ST LOUP,,48.6657307643,-1.30380060924,505,,Saint-Loup,Saint-Loup,50,Manche,28,Normandie
50506,ST MALO DE LA LANDE,50200,ST MALO DE LA LANDE,,49.0630539457,-1.54261997084,506,,Saint-Malo-de-la-Lande,Saint-Malo-de-la-Lande,50,Manche,28,Normandie
50507,ST MARCOUF,50310,ST MARCOUF,,49.4783175934,-1.28643297158,507,,Saint-Marcouf,Saint-Marcouf,50,Manche,28,Normandie
50509,STE MARIE DU MONT,50480,STE MARIE DU MONT,,49.395071265,-1.20388843834,509,,Sainte-Marie-du-Mont,Sainte-Marie-du-Mont,50,Manche,28,Normandie
50510,ST MARTIN D AUBIGNY,50190,ST MARTIN D AUBIGNY,,49.1686541161,-1.35248949773,510,,Saint-Martin-d'Aubigny,Saint-Martin-d'Aubigny,50,Manche,28,Normandie
50511,ST MARTIN D AUDOUVILLE,50310,ST MARTIN D AUDOUVILLE,,49.5290089356,-1.36946215018,511,,Saint-Martin-d'Audouville,Saint-Martin-d'Audouville,50,Manche,28,Normandie
50512,ST MARTIN DE BONFOSSE,50750,ST MARTIN DE BONFOSSE,,49.0416813347,-1.17448957334,512,,Saint-Martin-de-Bonfossé,Saint-Martin-de-Bonfossé,50,Manche,28,Normandie
50513,ST MARTIN DE CENILLY,50210,ST MARTIN DE CENILLY,,48.977495164,-1.28613865858,513,,Saint-Martin-de-Cenilly,Saint-Martin-de-Cenilly,50,Manche,28,Normandie
50514,CHAULIEU,50150,CHAULIEU,,48.7475664246,-0.860916100149,514,,Chaulieu,Chaulieu,50,Manche,28,Normandie
50514,CHAULIEU,50150,CHAULIEU,ST SAUVEUR DE CHAULIEU,48.7475664246,-0.860916100149,514,,Chaulieu,Chaulieu,50,Manche,28,Normandie
50516,ST MARTIN DES CHAMPS,50300,ST MARTIN DES CHAMPS,,48.6668233534,-1.3367884355,516,,Saint-Martin-des-Champs,Saint-Martin-des-Champs,50,Manche,28,Normandie
50517,ST MARTIN DE VARREVILLE,50480,ST MARTIN DE VARREVILLE,,49.4289939104,-1.23371020801,517,,Saint-Martin-de-Varreville,Saint-Martin-de-Varreville,50,Manche,28,Normandie
50518,ST MARTIN LE BOUILLANT,50800,ST MARTIN LE BOUILLANT,,48.7800336062,-1.15914723791,518,,Saint-Martin-le-Bouillant,Saint-Martin-le-Bouillant,50,Manche,28,Normandie
50519,ST MARTIN LE GREARD,50690,ST MARTIN LE GREARD,,49.5531033144,-1.64677361322,519,,Saint-Martin-le-Gréard,Saint-Martin-le-Gréard,50,Manche,28,Normandie
50521,ST MAUR DES BOIS,50800,ST MAUR DES BOIS,,48.816824491,-1.15153276787,521,,Saint-Maur-des-Bois,Saint-Maur-des-Bois,50,Manche,28,Normandie
50522,ST MAURICE EN COTENTIN,50270,ST MAURICE EN COTENTIN,,49.3948768703,-1.70004225617,522,,Saint-Maurice-en-Cotentin,Saint-Maurice-en-Cotentin,50,Manche,28,Normandie
50523,STE MERE EGLISE,50480,STE MERE EGLISE,,49.4088606097,-1.32366056657,523,,Sainte-Mère-Église,Sainte-Mère-Église,50,Manche,28,Normandie
50523,STE MERE EGLISE,50480,STE MERE EGLISE,BEUZEVILLE AU PLAIN,49.4088606097,-1.32366056657,523,,Sainte-Mère-Église,Sainte-Mère-Église,50,Manche,28,Normandie
50523,STE MERE EGLISE,50480,STE MERE EGLISE,CHEF DU PONT,49.4088606097,-1.32366056657,523,,Sainte-Mère-Église,Sainte-Mère-Église,50,Manche,28,Normandie
50523,STE MERE EGLISE,50480,STE MERE EGLISE,ECOQUENEAUVILLE,49.4088606097,-1.32366056657,523,,Sainte-Mère-Église,Sainte-Mère-Église,50,Manche,28,Normandie
50523,STE MERE EGLISE,50480,STE MERE EGLISE,FOUCARVILLE,49.4088606097,-1.32366056657,523,,Sainte-Mère-Église,Sainte-Mère-Église,50,Manche,28,Normandie
50524,ST MICHEL DE LA PIERRE,50490,ST MICHEL DE LA PIERRE,,49.1342933605,-1.3823946912,524,,Saint-Michel-de-la-Pierre,Saint-Michel-de-la-Pierre,50,Manche,28,Normandie
50525,ST MICHEL DE MONTJOIE,50670,ST MICHEL DE MONTJOIE,,48.7643844423,-1.02574787224,525,,Saint-Michel-de-Montjoie,Saint-Michel-de-Montjoie,50,Manche,28,Normandie
50528,ST NICOLAS DE PIERREPONT,50250,ST NICOLAS DE PIERREPONT,,49.3204768919,-1.58266376948,528,,Saint-Nicolas-de-Pierrepont,Saint-Nicolas-de-Pierrepont,50,Manche,28,Normandie
50529,ST NICOLAS DES BOIS,50370,ST NICOLAS DES BOIS,,48.7615753481,-1.19249333353,529,,Saint-Nicolas-des-Bois,Saint-Nicolas-des-Bois,50,Manche,28,Normandie
50531,ST OVIN,50220,ST OVIN,LA BOULOUZE,48.6792316921,-1.24401346918,531,,Saint-Ovin,Saint-Ovin,50,Manche,28,Normandie
50531,ST OVIN,50300,ST OVIN,,48.6792316921,-1.24401346918,531,,Saint-Ovin,Saint-Ovin,50,Manche,28,Normandie
50532,ST PAIR SUR MER,50380,ST PAIR SUR MER,,48.8023738223,-1.54696599127,532,,Saint-Pair-sur-Mer,Saint-Pair-sur-Mer,50,Manche,28,Normandie
50533,ST PATRICE DE CLAIDS,50190,ST PATRICE DE CLAIDS,,49.2275614672,-1.44033380334,533,,Saint-Patrice-de-Claids,Saint-Patrice-de-Claids,50,Manche,28,Normandie
50534,ST PELLERIN,50500,ST PELLERIN,,49.2953094902,-1.18923938817,534,,Saint-Pellerin,Saint-Pellerin,50,Manche,28,Normandie
50535,LE PARC,50870,LE PARC,BRAFFAIS,48.7713169182,-1.29851023982,535,Le,Parc,Le Parc,50,Manche,28,Normandie
50535,LE PARC,50870,LE PARC,PLOMB,48.7713169182,-1.29851023982,535,Le,Parc,Le Parc,50,Manche,28,Normandie
50535,LE PARC,50870,LE PARC,STE PIENCE,48.7713169182,-1.29851023982,535,Le,Parc,Le Parc,50,Manche,28,Normandie
50536,ST PIERRE D ARTHEGLISE,50270,ST PIERRE D ARTHEGLISE,,49.4173651602,-1.68504770752,536,,Saint-Pierre-d'Arthéglise,Saint-Pierre-d'Arthéglise,50,Manche,28,Normandie
50537,ST PIERRE DE COUTANCES,50200,ST PIERRE DE COUTANCES,,49.0395774631,-1.43513976797,537,,Saint-Pierre-de-Coutances,Saint-Pierre-de-Coutances,50,Manche,28,Normandie
50538,ST PIERRE DE SEMILLY,50810,ST PIERRE DE SEMILLY,,49.122177193,-0.997134740618,538,,Saint-Pierre-de-Semilly,Saint-Pierre-de-Semilly,50,Manche,28,Normandie
50539,ST PIERRE EGLISE,50330,ST PIERRE EGLISE,,49.669715545,-1.39807682939,539,,Saint-Pierre-Église,Saint-Pierre-Église,50,Manche,28,Normandie
50540,ST PIERRE LANGERS,50530,ST PIERRE LANGERS,,48.7843094283,-1.48878829897,540,,Saint-Pierre-Langers,Saint-Pierre-Langers,50,Manche,28,Normandie
50541,ST PLANCHERS,50400,ST PLANCHERS,,48.8308849826,-1.50561914851,541,,Saint-Planchers,Saint-Planchers,50,Manche,28,Normandie
50542,ST POIS,50670,ST POIS,,48.7476968749,-1.05750848644,542,,Saint-Pois,Saint-Pois,50,Manche,28,Normandie
50543,ST QUENTIN SUR LE HOMME,50220,ST QUENTIN SUR LE HOMME,,48.6452249464,-1.30041468589,543,,Saint-Quentin-sur-le-Homme,Saint-Quentin-sur-le-Homme,50,Manche,28,Normandie
50546,BOURGVALLEES,50750,BOURGVALLEES,GOURFALEUR,49.0498085612,-1.1310126677,546,,Bourgvallées,Bourgvallées,50,Manche,28,Normandie
50546,BOURGVALLEES,50750,BOURGVALLEES,LA MANCELLIERE SUR VIRE,49.0498085612,-1.1310126677,546,,Bourgvallées,Bourgvallées,50,Manche,28,Normandie
50546,BOURGVALLEES,50750,BOURGVALLEES,ST ROMPHAIRE,49.0498085612,-1.1310126677,546,,Bourgvallées,Bourgvallées,50,Manche,28,Normandie
50546,BOURGVALLEES,50750,BOURGVALLEES,ST SAMSON DE BONFOSSE,49.0498085612,-1.1310126677,546,,Bourgvallées,Bourgvallées,50,Manche,28,Normandie
50548,ST SAUVEUR DE PIERREPONT,50250,ST SAUVEUR DE PIERREPONT,,49.3353264923,-1.60977461831,548,,Saint-Sauveur-de-Pierrepont,Saint-Sauveur-de-Pierrepont,50,Manche,28,Normandie
50549,ST SAUVEUR LA POMMERAYE,50510,ST SAUVEUR LA POMMERAYE,,48.8400547201,-1.43412628403,549,,Saint-Sauveur-la-Pommeraye,Saint-Sauveur-la-Pommeraye,50,Manche,28,Normandie
50550,ST SAUVEUR LENDELIN,50490,ST SAUVEUR LENDELIN,,49.1238164508,-1.40639536649,550,,Saint-Sauveur-Lendelin,Saint-Sauveur-Lendelin,50,Manche,28,Normandie
50551,ST SAUVEUR LE VICOMTE,50390,ST SAUVEUR LE VICOMTE,,49.3798967977,-1.54758330129,551,,Saint-Sauveur-le-Vicomte,Saint-Sauveur-le-Vicomte,50,Manche,28,Normandie
50552,ST SEBASTIEN DE RAIDS,50190,ST SEBASTIEN DE RAIDS,,49.1953005849,-1.37598924689,552,,Saint-Sébastien-de-Raids,Saint-Sébastien-de-Raids,50,Manche,28,Normandie
50553,ST SENIER DE BEUVRON,50240,ST SENIER DE BEUVRON,,48.5585224149,-1.32041724737,553,,Saint-Senier-de-Beuvron,Saint-Senier-de-Beuvron,50,Manche,28,Normandie
50554,ST SENIER SOUS AVRANCHES,50300,ST SENIER SOUS AVRANCHES,,48.6870653386,-1.31913118915,554,,Saint-Senier-sous-Avranches,Saint-Senier-sous-Avranches,50,Manche,28,Normandie
50556,STE SUZANNE SUR VIRE,50750,STE SUZANNE SUR VIRE,,49.0733180514,-1.05714630383,556,,Sainte-Suzanne-sur-Vire,Sainte-Suzanne-sur-Vire,50,Manche,28,Normandie
50562,ST VAAST LA HOUGUE,50550,ST VAAST LA HOUGUE,,49.5993508281,-1.27306891683,562,,Saint-Vaast-la-Hougue,Saint-Vaast-la-Hougue,50,Manche,28,Normandie
50562,ST VAAST LA HOUGUE,50550,ST VAAST LA HOUGUE,ILE TATIHOU,49.5993508281,-1.27306891683,562,,Saint-Vaast-la-Hougue,Saint-Vaast-la-Hougue,50,Manche,28,Normandie
50563,ST VIGOR DES MONTS,50420,ST VIGOR DES MONTS,,48.9096722066,-1.05669811205,563,,Saint-Vigor-des-Monts,Saint-Vigor-des-Monts,50,Manche,28,Normandie
50564,TERRE ET MARAIS,50500,TERRE ET MARAIS,SAINTENY,49.2439040053,-1.32743807676,564,,Terre-et-Marais,Terre-et-Marais,50,Manche,28,Normandie
50564,TERRE ET MARAIS,50500,TERRE ET MARAIS,ST GEORGES DE BOHON,49.2439040053,-1.32743807676,564,,Terre-et-Marais,Terre-et-Marais,50,Manche,28,Normandie
50565,SARTILLY BAIE BOCAGE,50530,SARTILLY BAIE BOCAGE,,48.7540217735,-1.46351733597,565,,Sartilly-Baie-Bocage,Sartilly-Baie-Bocage,50,Manche,28,Normandie
50565,SARTILLY BAIE BOCAGE,50530,SARTILLY BAIE BOCAGE,ANGEY,48.7540217735,-1.46351733597,565,,Sartilly-Baie-Bocage,Sartilly-Baie-Bocage,50,Manche,28,Normandie
50565,SARTILLY BAIE BOCAGE,50530,SARTILLY BAIE BOCAGE,CHAMPCEY,48.7540217735,-1.46351733597,565,,Sartilly-Baie-Bocage,Sartilly-Baie-Bocage,50,Manche,28,Normandie
50565,SARTILLY BAIE BOCAGE,50530,SARTILLY BAIE BOCAGE,LA ROCHELLE NORMANDE,48.7540217735,-1.46351733597,565,,Sartilly-Baie-Bocage,Sartilly-Baie-Bocage,50,Manche,28,Normandie
50565,SARTILLY BAIE BOCAGE,50530,SARTILLY BAIE BOCAGE,MONTVIRON,48.7540217735,-1.46351733597,565,,Sartilly-Baie-Bocage,Sartilly-Baie-Bocage,50,Manche,28,Normandie
50567,SAUSSEMESNIL,50700,SAUSSEMESNIL,,49.5728877751,-1.4894487737,567,,Saussemesnil,Saussemesnil,50,Manche,28,Normandie
50568,SAUSSEY,50200,SAUSSEY,,49.0115191307,-1.42526649012,568,,Saussey,Saussey,50,Manche,28,Normandie
50569,SAVIGNY,50210,SAVIGNY,,49.0591765811,-1.32787039859,569,,Savigny,Savigny,50,Manche,28,Normandie
50570,SAVIGNY LE VIEUX,50640,SAVIGNY LE VIEUX,,48.5195591779,-1.02472837695,570,,Savigny-le-Vieux,Savigny-le-Vieux,50,Manche,28,Normandie
50571,SEBEVILLE,50480,SEBEVILLE,,49.3866049343,-1.2842458445,571,,Sébeville,Sébeville,50,Manche,28,Normandie
50572,SENOVILLE,50270,SENOVILLE,,49.4292326453,-1.77035371165,572,,Sénoville,Sénoville,50,Manche,28,Normandie
50573,SERVIGNY,50200,SERVIGNY,,49.0911272336,-1.48469867291,573,,Servigny,Servigny,50,Manche,28,Normandie
50574,SERVON,50170,SERVON,,48.6028234324,-1.41113304944,574,,Servon,Servon,50,Manche,28,Normandie
50575,SIDEVILLE,50690,SIDEVILLE,,49.5931791069,-1.68646853149,575,,Sideville,Sideville,50,Manche,28,Normandie
50576,SIOUVILLE HAGUE,50340,SIOUVILLE HAGUE,,49.559964542,-1.8308686969,576,,Siouville-Hague,Siouville-Hague,50,Manche,28,Normandie
50577,SORTOSVILLE EN BEAUMONT,50270,SORTOSVILLE EN BEAUMONT,,49.4245013285,-1.7097121823,577,,Sortosville-en-Beaumont,Sortosville-en-Beaumont,50,Manche,28,Normandie
50578,SORTOSVILLE,50310,SORTOSVILLE,,49.4817461354,-1.42985751557,578,,Sortosville,Sortosville,50,Manche,28,Normandie
50579,SOTTEVAST,50260,SOTTEVAST,,49.5221585855,-1.60619449046,579,,Sottevast,Sottevast,50,Manche,28,Normandie
50580,SOTTEVILLE,50340,SOTTEVILLE,,49.5393529841,-1.75245754632,580,,Sotteville,Sotteville,50,Manche,28,Normandie
50581,SOULLES,50750,SOULLES,,49.0093446258,-1.18833456705,581,,Soulles,Soulles,50,Manche,28,Normandie
50582,SOURDEVAL,50150,SOURDEVAL,,48.7134467987,-0.891882166303,582,,Sourdeval,Sourdeval,50,Manche,28,Normandie
50582,SOURDEVAL,50150,SOURDEVAL,VENGEONS,48.7134467987,-0.891882166303,582,,Sourdeval,Sourdeval,50,Manche,28,Normandie
50583,SOURDEVAL LES BOIS,50450,SOURDEVAL LES BOIS,,48.9120730678,-1.27685881072,583,,Sourdeval-les-Bois,Sourdeval-les-Bois,50,Manche,28,Normandie
50584,SUBLIGNY,50870,SUBLIGNY,,48.7530084367,-1.35897250285,584,,Subligny,Subligny,50,Manche,28,Normandie
50585,SURTAINVILLE,50270,SURTAINVILLE,,49.4529583495,-1.79283782284,585,,Surtainville,Surtainville,50,Manche,28,Normandie
50587,TAILLEPIED,50390,TAILLEPIED,,49.3622618411,-1.58574528499,587,,Taillepied,Taillepied,50,Manche,28,Normandie
50588,TAMERVILLE,50700,TAMERVILLE,,49.5419972354,-1.46098146273,588,,Tamerville,Tamerville,50,Manche,28,Normandie
50589,TANIS,50170,TANIS,,48.5847136068,-1.44326167489,589,,Tanis,Tanis,50,Manche,28,Normandie
50590,LE TANU,50320,LE TANU,,48.8042090311,-1.33539265426,590,Le,Tanu,Le Tanu,50,Manche,28,Normandie
50590,LE TANU,50320,LE TANU,NOIRPALU,48.8042090311,-1.33539265426,590,Le,Tanu,Le Tanu,50,Manche,28,Normandie
50591,LE TEILLEUL,50640,LE TEILLEUL,,48.5412543067,-0.878410989848,591,Le,Teilleul,Le Teilleul,50,Manche,28,Normandie
50591,LE TEILLEUL,50640,LE TEILLEUL,FERRIERES,48.5412543067,-0.878410989848,591,Le,Teilleul,Le Teilleul,50,Manche,28,Normandie
50591,LE TEILLEUL,50640,LE TEILLEUL,HEUSSE,48.5412543067,-0.878410989848,591,Le,Teilleul,Le Teilleul,50,Manche,28,Normandie
50591,LE TEILLEUL,50640,LE TEILLEUL,HUSSON,48.5412543067,-0.878410989848,591,Le,Teilleul,Le Teilleul,50,Manche,28,Normandie
50591,LE TEILLEUL,50640,LE TEILLEUL,STE MARIE DU BOIS,48.5412543067,-0.878410989848,591,Le,Teilleul,Le Teilleul,50,Manche,28,Normandie
50592,TESSY BOCAGE,50420,TESSY BOCAGE,,48.9633310504,-1.08464396412,592,,Tessy-Bocage,Tessy-Bocage,50,Manche,28,Normandie
50592,TESSY BOCAGE,50420,TESSY BOCAGE,FERVACHES,48.9633310504,-1.08464396412,592,,Tessy-Bocage,Tessy-Bocage,50,Manche,28,Normandie
50593,TEURTHEVILLE BOCAGE,50630,TEURTHEVILLE BOCAGE,,49.592445598,-1.39477640051,593,,Teurthéville-Bocage,Teurthéville-Bocage,50,Manche,28,Normandie
50594,TEURTHEVILLE HAGUE,50690,TEURTHEVILLE HAGUE,,49.5833092246,-1.73831108507,594,,Teurthéville-Hague,Teurthéville-Hague,50,Manche,28,Normandie
50596,THEVILLE,50330,THEVILLE,,49.6482237983,-1.41776532413,596,,Théville,Théville,50,Manche,28,Normandie
50597,TIREPIED,50870,TIREPIED,,48.7239372262,-1.2735313312,597,,Tirepied,Tirepied,50,Manche,28,Normandie
50597,TIREPIED,50870,TIREPIED,STE EUGIENNE,48.7239372262,-1.2735313312,597,,Tirepied,Tirepied,50,Manche,28,Normandie
50598,TOCQUEVILLE,50330,TOCQUEVILLE,,49.6711907146,-1.33613504172,598,,Tocqueville,Tocqueville,50,Manche,28,Normandie
50599,TOLLEVAST,50470,TOLLEVAST,,49.5790245976,-1.60738435371,599,,Tollevast,Tollevast,50,Manche,28,Normandie
50600,TONNEVILLE,50460,TONNEVILLE,,49.6413751892,-1.71941565604,600,,Tonneville,Tonneville,50,Manche,28,Normandie
50601,TORIGNY LES VILLES,50160,TORIGNY LES VILLES,,49.0319879145,-0.982814708617,601,,Torigny-les-Villes,Torigny-les-Villes,50,Manche,28,Normandie
50601,TORIGNY LES VILLES,50160,TORIGNY LES VILLES,BRECTOUVILLE,49.0319879145,-0.982814708617,601,,Torigny-les-Villes,Torigny-les-Villes,50,Manche,28,Normandie
50601,TORIGNY LES VILLES,50160,TORIGNY LES VILLES,GIEVILLE,49.0319879145,-0.982814708617,601,,Torigny-les-Villes,Torigny-les-Villes,50,Manche,28,Normandie
50601,TORIGNY LES VILLES,50160,TORIGNY LES VILLES,GUILBERVILLE,49.0319879145,-0.982814708617,601,,Torigny-les-Villes,Torigny-les-Villes,50,Manche,28,Normandie
50603,TOURVILLE SUR SIENNE,50200,TOURVILLE SUR SIENNE,,49.0508158439,-1.52656595616,603,,Tourville-sur-Sienne,Tourville-sur-Sienne,50,Manche,28,Normandie
50604,TREAUVILLE,50340,TREAUVILLE,,49.5379513416,-1.82387618642,604,,Tréauville,Tréauville,50,Manche,28,Normandie
50605,TRELLY,50660,TRELLY,,48.9606378787,-1.4175703278,605,,Trelly,Trelly,50,Manche,28,Normandie
50606,TRIBEHOU,50620,TRIBEHOU,,49.2091331956,-1.24592963433,606,,Tribehou,Tribehou,50,Manche,28,Normandie
50607,LA TRINITE,50800,LA TRINITE,,48.7882899186,-1.24631476826,607,La,Trinité,La Trinité,50,Manche,28,Normandie
50608,TROISGOTS,50420,TROISGOTS,,49.0180396417,-1.07203804357,608,,Troisgots,Troisgots,50,Manche,28,Normandie
50609,TURQUEVILLE,50480,TURQUEVILLE,,49.4052196116,-1.26997975955,609,,Turqueville,Turqueville,50,Manche,28,Normandie
50610,URVILLE,50700,URVILLE,,49.4492565147,-1.44567160127,610,,Urville,Urville,50,Manche,28,Normandie
50611,URVILLE NACQUEVILLE,50460,URVILLE NACQUEVILLE,,49.6672722291,-1.74465833547,611,,Urville-Nacqueville,Urville-Nacqueville,50,Manche,28,Normandie
50612,VAINS,50300,VAINS,,48.6760046422,-1.42560331067,612,,Vains,Vains,50,Manche,28,Normandie
50613,VALCANVILLE,50760,VALCANVILLE,,49.648017352,-1.32179306363,613,,Valcanville,Valcanville,50,Manche,28,Normandie
50615,VALOGNES,50700,VALOGNES,,49.5128276096,-1.47383499973,615,,Valognes,Valognes,50,Manche,28,Normandie
50616,LE VAL ST PERE,50300,LE VAL ST PERE,,48.661573623,-1.37225712194,616,Le,Val-Saint-Père,Le Val-Saint-Père,50,Manche,28,Normandie
50617,VARENGUEBEC,50250,VARENGUEBEC,,49.3375063908,-1.49183766292,617,,Varenguebec,Varenguebec,50,Manche,28,Normandie
50618,VAROUVILLE,50330,VAROUVILLE,,49.6713044012,-1.37126711857,618,,Varouville,Varouville,50,Manche,28,Normandie
50619,LE VAST,50630,LE VAST,,49.6181289951,-1.35780462163,619,Le,Vast,Le Vast,50,Manche,28,Normandie
50620,VASTEVILLE,50440,VASTEVILLE,,49.5990015094,-1.7918666797,620,,Vasteville,Vasteville,50,Manche,28,Normandie
50621,VAUDREVILLE,50310,VAUDREVILLE,,49.5168980807,-1.35870638129,621,,Vaudreville,Vaudreville,50,Manche,28,Normandie
50622,VAUDRIMESNIL,50490,VAUDRIMESNIL,,49.1610665135,-1.41852676139,622,,Vaudrimesnil,Vaudrimesnil,50,Manche,28,Normandie
50623,VAUVILLE,50440,VAUVILLE,,49.6372240544,-1.82536053456,623,,Vauville,Vauville,50,Manche,28,Normandie
50624,LA VENDELEE,50200,LA VENDELEE,,49.0833532845,-1.45799644929,624,La,Vendelée,La Vendelée,50,Manche,28,Normandie
50626,VER,50450,VER,,48.895581747,-1.39039156201,626,,Ver,Ver,50,Manche,28,Normandie
50627,VERGONCEY,50240,VERGONCEY,,48.568195028,-1.38661335129,627,,Vergoncey,Vergoncey,50,Manche,28,Normandie
50628,VERNIX,50370,VERNIX,,48.7151708198,-1.2283280036,628,,Vernix,Vernix,50,Manche,28,Normandie
50629,VESLY,50430,VESLY,,49.246440274,-1.49007496954,629,,Vesly,Vesly,50,Manche,28,Normandie
50629,VESLY,50430,VESLY,GERVILLE LA FORET,49.246440274,-1.49007496954,629,,Vesly,Vesly,50,Manche,28,Normandie
50631,LES VEYS,50500,LES VEYS,,49.3200894315,-1.14460882334,631,Les,Veys,Les Veys,50,Manche,28,Normandie
50633,LE VICEL,50760,LE VICEL,,49.6325667145,-1.32055469764,633,Le,Vicel,Le Vicel,50,Manche,28,Normandie
50634,VIDECOSVILLE,50630,VIDECOSVILLE,,49.5668795945,-1.37148540577,634,,Videcosville,Videcosville,50,Manche,28,Normandie
50636,VIERVILLE,50480,VIERVILLE,,49.351803168,-1.2339984877,636,,Vierville,Vierville,50,Manche,28,Normandie
50637,VILLEBAUDON,50410,VILLEBAUDON,,48.9649649634,-1.17120753687,637,,Villebaudon,Villebaudon,50,Manche,28,Normandie
50639,VILLEDIEU LES POELES ROUFFIGNY,50800,VILLEDIEU LES POELES ROUFFIGNY,,48.8344159273,-1.22248057231,639,,Villedieu-les-Poêles-Rouffigny,Villedieu-les-Poêles-Rouffigny,50,Manche,28,Normandie
50639,VILLEDIEU LES POELES ROUFFIGNY,50800,VILLEDIEU LES POELES ROUFFIGNY,ROUFFIGNY,48.8344159273,-1.22248057231,639,,Villedieu-les-Poêles-Rouffigny,Villedieu-les-Poêles-Rouffigny,50,Manche,28,Normandie
50640,VILLIERS LE PRE,50240,VILLIERS LE PRE,,48.5328566587,-1.38845596888,640,,Villiers-le-Pré,Villiers-le-Pré,50,Manche,28,Normandie
50641,VILLIERS FOSSARD,50680,VILLIERS FOSSARD,,49.1613885671,-1.06108270385,641,,Villiers-Fossard,Villiers-Fossard,50,Manche,28,Normandie
50643,VIRANDEVILLE,50690,VIRANDEVILLE,,49.5693060058,-1.7150466184,643,,Virandeville,Virandeville,50,Manche,28,Normandie
50647,YQUELON,50400,YQUELON,,48.8444141467,-1.55122744214,647,,Yquelon,Yquelon,50,Manche,28,Normandie
50648,YVETOT BOCAGE,50700,YVETOT BOCAGE,,49.4960572995,-1.51043693291,648,,Yvetot-Bocage,Yvetot-Bocage,50,Manche,28,Normandie
51001,ABLANCOURT,51240,ABLANCOURT,,48.8103417636,4.53084843791,1,,Ablancourt,Ablancourt,51,Marne,44,Grand Est
51002,ST MARTIN D ABLOIS,51530,ST MARTIN D ABLOIS,,49.0118734772,3.83947434155,2,,Saint-Martin-d'Ablois,Saint-Martin-d'Ablois,51,Marne,44,Grand Est
51003,AIGNY,51150,AIGNY,,49.042644399,4.22045582777,3,,Aigny,Aigny,51,Marne,44,Grand Est
51004,ALLEMANCHE LAUNAY ET SOYER,51260,ALLEMANCHE LAUNAY ET SOYER,,48.6066890182,3.79098671125,4,,Allemanche-Launay-et-Soyer,Allemanche-Launay-et-Soyer,51,Marne,44,Grand Est
51005,ALLEMANT,51120,ALLEMANT,,48.7615346017,3.8160207846,5,,Allemant,Allemant,51,Marne,44,Grand Est
51006,ALLIANCELLES,51250,ALLIANCELLES,,48.8033869619,4.87418519758,6,,Alliancelles,Alliancelles,51,Marne,44,Grand Est
51007,AMBONNAY,51150,AMBONNAY,,49.08689493,4.17217235053,7,,Ambonnay,Ambonnay,51,Marne,44,Grand Est
51008,AMBRIERES,51290,AMBRIERES,,48.629003135,4.82923701475,8,,Ambrières,Ambrières,51,Marne,44,Grand Est
51009,ANGLURE,51260,ANGLURE,,48.5873514274,3.8174517586,9,,Anglure,Anglure,51,Marne,44,Grand Est
51010,ANGLUZELLES ET COURCELLES,51230,ANGLUZELLES ET COURCELLES,,48.6663421752,3.90589421225,10,,Angluzelles-et-Courcelles,Angluzelles-et-Courcelles,51,Marne,44,Grand Est
51012,ANTHENAY,51700,ANTHENAY,,49.1391972334,3.73533803847,12,,Anthenay,Anthenay,51,Marne,44,Grand Est
51013,AOUGNY,51170,AOUGNY,,49.1874469545,3.72490243283,13,,Aougny,Aougny,51,Marne,44,Grand Est
51014,ARCIS LE PONSART,51170,ARCIS LE PONSART,,49.2246570472,3.68877941886,14,,Arcis-le-Ponsart,Arcis-le-Ponsart,51,Marne,44,Grand Est
51015,ARGERS,51800,ARGERS,,49.0667880865,4.85773458093,15,,Argers,Argers,51,Marne,44,Grand Est
51016,ARRIGNY,51290,ARRIGNY,,48.6073640595,4.69474482412,16,,Arrigny,Arrigny,51,Marne,44,Grand Est
51017,ARZILLIERES NEUVILLE,51290,ARZILLIERES NEUVILLE,,48.6458789827,4.60054022663,17,,Arzillières-Neuville,Arzillières-Neuville,51,Marne,44,Grand Est
51017,ARZILLIERES NEUVILLE,51290,ARZILLIERES NEUVILLE,NEUVILLE SOUS ARZILLIERES,48.6458789827,4.60054022663,17,,Arzillières-Neuville,Arzillières-Neuville,51,Marne,44,Grand Est
51018,ATHIS,51150,ATHIS,,49.0072417062,4.12566505905,18,,Athis,Athis,51,Marne,44,Grand Est
51019,AUBERIVE,51600,AUBERIVE,,49.1972757573,4.41096827282,19,,Aubérive,Aubérive,51,Marne,44,Grand Est
51020,AUBILLY,51170,AUBILLY,,49.2127739521,3.85555629436,20,,Aubilly,Aubilly,51,Marne,44,Grand Est
51022,AULNAY L AITRE,51240,AULNAY L AITRE,,48.8259358805,4.56822017278,22,,Aulnay-l'Aître,Aulnay-l'Aître,51,Marne,44,Grand Est
51023,AULNAY SUR MARNE,51150,AULNAY SUR MARNE,,48.9973425988,4.20731510255,23,,Aulnay-sur-Marne,Aulnay-sur-Marne,51,Marne,44,Grand Est
51025,AUMENANCOURT,51110,AUMENANCOURT,,49.3855740123,4.05833195184,25,,Auménancourt,Auménancourt,51,Marne,44,Grand Est
51027,AUVE,51800,AUVE,,49.0286926359,4.68669645123,27,,Auve,Auve,51,Marne,44,Grand Est
51028,AVENAY VAL D OR,51160,AVENAY VAL D OR,,49.0758383697,4.04657297912,28,,Avenay-Val-d'Or,Avenay-Val-d'Or,51,Marne,44,Grand Est
51029,AVIZE,51190,AVIZE,,48.9733821282,4.00541672872,29,,Avize,Avize,51,Marne,44,Grand Est
51030,AY CHAMPAGNE,51150,AY CHAMPAGNE,BISSEUIL,49.0625907596,3.9973447815,30,,Aÿ-Champagne,Aÿ-Champagne,51,Marne,44,Grand Est
51030,AY CHAMPAGNE,51160,AY CHAMPAGNE,,49.0625907596,3.9973447815,30,,Aÿ-Champagne,Aÿ-Champagne,51,Marne,44,Grand Est
51030,AY CHAMPAGNE,51160,AY CHAMPAGNE,MAREUIL SUR AY,49.0625907596,3.9973447815,30,,Aÿ-Champagne,Aÿ-Champagne,51,Marne,44,Grand Est
51031,BACONNES,51400,BACONNES,,49.1643635921,4.33841654965,31,,Baconnes,Baconnes,51,Marne,44,Grand Est
51032,BAGNEUX,51260,BAGNEUX,,48.5608751887,3.83152846641,32,,Bagneux,Bagneux,51,Marne,44,Grand Est
51033,LE BAIZIL,51270,LE BAIZIL,,48.9750758673,3.79600981845,33,Le,Baizil,Le Baizil,51,Marne,44,Grand Est
51034,BANNAY,51270,BANNAY,,48.8547181933,3.72167783873,34,,Bannay,Bannay,51,Marne,44,Grand Est
51035,BANNES,51230,BANNES,,48.7922110406,3.92587879621,35,,Bannes,Bannes,51,Marne,44,Grand Est
51036,BARBONNE FAYEL,51120,BARBONNE FAYEL,,48.654016669,3.68784800525,36,,Barbonne-Fayel,Barbonne-Fayel,51,Marne,44,Grand Est
51037,BASLIEUX LES FISMES,51170,BASLIEUX LES FISMES,,49.3247340929,3.7142359608,37,,Baslieux-lès-Fismes,Baslieux-lès-Fismes,51,Marne,44,Grand Est
51038,BASLIEUX SOUS CHATILLON,51700,BASLIEUX SOUS CHATILLON,,49.1218320502,3.79516191833,38,,Baslieux-sous-Châtillon,Baslieux-sous-Châtillon,51,Marne,44,Grand Est
51039,BASSU,51300,BASSU,,48.833946829,4.69258148533,39,,Bassu,Bassu,51,Marne,44,Grand Est
51040,BASSUET,51300,BASSUET,,48.8018029229,4.67029926523,40,,Bassuet,Bassuet,51,Marne,44,Grand Est
51041,BAUDEMENT,51260,BAUDEMENT,,48.5786840356,3.76817010802,41,,Baudement,Baudement,51,Marne,44,Grand Est
51042,BAYE,51270,BAYE,,48.8606173405,3.75936134657,42,,Baye,Baye,51,Marne,44,Grand Est
51043,BAZANCOURT,51110,BAZANCOURT,,49.3701419632,4.17377871921,43,,Bazancourt,Bazancourt,51,Marne,44,Grand Est
51044,BEAUMONT SUR VESLE,51360,BEAUMONT SUR VESLE,,49.1731370665,4.17941674929,44,,Beaumont-sur-Vesle,Beaumont-sur-Vesle,51,Marne,44,Grand Est
51045,BEAUNAY,51270,BEAUNAY,,48.8767550463,3.88535038911,45,,Beaunay,Beaunay,51,Marne,44,Grand Est
51046,BEINE NAUROY,51490,BEINE NAUROY,,49.2458158743,4.23851235844,46,,Beine-Nauroy,Beine-Nauroy,51,Marne,44,Grand Est
51047,BELVAL EN ARGONNE,51330,BELVAL EN ARGONNE,,48.9443279764,4.97862490945,47,,Belval-en-Argonne,Belval-en-Argonne,51,Marne,44,Grand Est
51048,BELVAL SOUS CHATILLON,51480,BELVAL SOUS CHATILLON,,49.125456085,3.85394695704,48,,Belval-sous-Châtillon,Belval-sous-Châtillon,51,Marne,44,Grand Est
51049,BERGERES LES VERTUS,51130,BERGERES LES VERTUS,,48.8730736689,4.00812828777,49,,Bergères-lès-Vertus,Bergères-lès-Vertus,51,Marne,44,Grand Est
51050,BERGERES SOUS MONTMIRAIL,51210,BERGERES SOUS MONTMIRAIL,,48.8453830392,3.59711725011,50,,Bergères-sous-Montmirail,Bergères-sous-Montmirail,51,Marne,44,Grand Est
51051,BERMERICOURT,51220,BERMERICOURT,,49.3653023098,3.98697258925,51,,Berméricourt,Berméricourt,51,Marne,44,Grand Est
51052,BERRU,51420,BERRU,,49.2727412592,4.16300404161,52,,Berru,Berru,51,Marne,44,Grand Est
51053,BERZIEUX,51800,BERZIEUX,,49.1603688335,4.80180873405,53,,Berzieux,Berzieux,51,Marne,44,Grand Est
51054,BETHENIVILLE,51490,BETHENIVILLE,,49.2925253096,4.36369457633,54,,Bétheniville,Bétheniville,51,Marne,44,Grand Est
51055,BETHENY,51450,BETHENY,,49.2956894813,4.06006294917,55,,Bétheny,Bétheny,51,Marne,44,Grand Est
51056,BETHON,51260,BETHON,,48.6143489927,3.61217905941,56,,Bethon,Bethon,51,Marne,44,Grand Est
51057,BETTANCOURT LA LONGUE,51330,BETTANCOURT LA LONGUE,,48.8322058575,4.88394828986,57,,Bettancourt-la-Longue,Bettancourt-la-Longue,51,Marne,44,Grand Est
51058,BEZANNES,51430,BEZANNES,,49.2198739552,3.99051364265,58,,Bezannes,Bezannes,51,Marne,44,Grand Est
51059,BIGNICOURT SUR MARNE,51300,BIGNICOURT SUR MARNE,,48.6813049833,4.60875139762,59,,Bignicourt-sur-Marne,Bignicourt-sur-Marne,51,Marne,44,Grand Est
51060,BIGNICOURT SUR SAULX,51340,BIGNICOURT SUR SAULX,,48.755771866,4.78740225896,60,,Bignicourt-sur-Saulx,Bignicourt-sur-Saulx,51,Marne,44,Grand Est
51061,BILLY LE GRAND,51400,BILLY LE GRAND,,49.1079745411,4.23300588875,61,,Billy-le-Grand,Billy-le-Grand,51,Marne,44,Grand Est
51062,BINARVILLE,51800,BINARVILLE,,49.2442879223,4.90318973025,62,,Binarville,Binarville,51,Marne,44,Grand Est
51063,BINSON ET ORQUIGNY,51700,BINSON ET ORQUIGNY,,49.0964941609,3.78995380367,63,,Binson-et-Orquigny,Binson-et-Orquigny,51,Marne,44,Grand Est
51065,BLACY,51300,BLACY,,48.7216383378,4.5179461795,65,,Blacy,Blacy,51,Marne,44,Grand Est
51066,BLAISE SOUS ARZILLIERES,51300,BLAISE SOUS ARZILLIERES,,48.6712256274,4.58510919023,66,,Blaise-sous-Arzillières,Blaise-sous-Arzillières,51,Marne,44,Grand Est
51068,BLESME,51340,BLESME,,48.7322244042,4.77715801079,68,,Blesme,Blesme,51,Marne,44,Grand Est
51069,BLIGNY,51170,BLIGNY,,49.1943978535,3.85996649455,69,,Bligny,Bligny,51,Marne,44,Grand Est
51070,BOISSY LE REPOS,51210,BOISSY LE REPOS,,48.8468531697,3.64177121632,70,,Boissy-le-Repos,Boissy-le-Repos,51,Marne,44,Grand Est
51071,BOUCHY ST GENEST,51310,BOUCHY ST GENEST,,48.6567156677,3.48974064428,71,,Bouchy-Saint-Genest,Bouchy-Saint-Genest,51,Marne,44,Grand Est
51072,BOUILLY,51390,BOUILLY,,49.1982100596,3.89628678861,72,,Bouilly,Bouilly,51,Marne,44,Grand Est
51073,BOULEUSE,51170,BOULEUSE,,49.2280540072,3.84094917164,73,,Bouleuse,Bouleuse,51,Marne,44,Grand Est
51074,BOULT SUR SUIPPE,51110,BOULT SUR SUIPPE,,49.3817578149,4.14116508461,74,,Boult-sur-Suippe,Boult-sur-Suippe,51,Marne,44,Grand Est
51075,BOURGOGNE,51110,BOURGOGNE,,49.3547870367,4.0718983781,75,,Bourgogne-Fresne,Bourgogne-Fresne,51,Marne,44,Grand Est
51076,BOURSAULT,51480,BOURSAULT,,49.0517564757,3.83850554916,76,,Boursault,Boursault,51,Marne,44,Grand Est
51077,BOUVANCOURT,51140,BOUVANCOURT,,49.3411541811,3.85921451901,77,,Bouvancourt,Bouvancourt,51,Marne,44,Grand Est
51078,BOUY,51400,BOUY,,49.0872445057,4.36349504242,78,,Bouy,Bouy,51,Marne,44,Grand Est
51079,BOUZY,51150,BOUZY,,49.0853543122,4.13887616628,79,,Bouzy,Bouzy,51,Marne,44,Grand Est
51080,BRANDONVILLERS,51290,BRANDONVILLERS,,48.5864193044,4.56804721582,80,,Brandonvillers,Brandonvillers,51,Marne,44,Grand Est
51081,BRANSCOURT,51140,BRANSCOURT,,49.2711872526,3.82154036192,81,,Branscourt,Branscourt,51,Marne,44,Grand Est
51082,BRAUX STE COHIERE,51800,BRAUX STE COHIERE,,49.0940197616,4.82214706786,82,,Braux-Sainte-Cohière,Braux-Sainte-Cohière,51,Marne,44,Grand Est
51083,BRAUX ST REMY,51800,BRAUX ST REMY,,49.024131251,4.86523753879,83,,Braux-Saint-Remy,Braux-Saint-Remy,51,Marne,44,Grand Est
51084,BREBAN,51320,BREBAN,,48.5794229708,4.39201727027,84,,Bréban,Bréban,51,Marne,44,Grand Est
51085,LE BREUIL,51210,LE BREUIL,,48.9828254266,3.65112059442,85,Le,Breuil,Le Breuil,51,Marne,44,Grand Est
51086,BREUIL,51140,BREUIL,,49.3020182621,3.76628666849,86,,Breuil,Breuil,51,Marne,44,Grand Est
51087,BREUVERY SUR COOLE,51240,BREUVERY SUR COOLE,,48.8543161427,4.29872818849,87,,Breuvery-sur-Coole,Breuvery-sur-Coole,51,Marne,44,Grand Est
51088,BRIMONT,51220,BRIMONT,,49.3387814946,4.03685503885,88,,Brimont,Brimont,51,Marne,44,Grand Est
51089,BROUILLET,51170,BROUILLET,,49.2274251852,3.73673973777,89,,Brouillet,Brouillet,51,Marne,44,Grand Est
51090,BROUSSY LE GRAND,51230,BROUSSY LE GRAND,,48.7889797353,3.87056310376,90,,Broussy-le-Grand,Broussy-le-Grand,51,Marne,44,Grand Est
51091,BROUSSY LE PETIT,51230,BROUSSY LE PETIT,,48.7858869706,3.83244940433,91,,Broussy-le-Petit,Broussy-le-Petit,51,Marne,44,Grand Est
51092,BROYES,51120,BROYES,,48.7612770796,3.75727727145,92,,Broyes,Broyes,51,Marne,44,Grand Est
51093,BRUGNY VAUDANCOURT,51530,BRUGNY VAUDANCOURT,,48.9832921495,3.85964192502,93,,Brugny-Vaudancourt,Brugny-Vaudancourt,51,Marne,44,Grand Est
51094,BRUSSON,51300,BRUSSON,,48.7415318428,4.7060593864,94,,Brusson,Brusson,51,Marne,44,Grand Est
51095,LE BUISSON,51300,LE BUISSON,,48.7556229579,4.7516934988,95,Le,Buisson,Le Buisson,51,Marne,44,Grand Est
51097,BUSSY LE CHATEAU,51600,BUSSY LE CHATEAU,,49.0593792031,4.53222848273,97,,Bussy-le-Château,Bussy-le-Château,51,Marne,44,Grand Est
51098,BUSSY LE REPOS,51330,BUSSY LE REPOS,,48.9030368503,4.74003720995,98,,Bussy-le-Repos,Bussy-le-Repos,51,Marne,44,Grand Est
51099,BUSSY LETTREE,51320,BUSSY LETTREE,,48.803079986,4.25136495939,99,,Bussy-Lettrée,Bussy-Lettrée,51,Marne,44,Grand Est
51100,LA CAURE,51270,LA CAURE,,48.9088413041,3.77651890135,100,La,Caure,La Caure,51,Marne,44,Grand Est
51101,CAUREL,51110,CAUREL,,49.3039168337,4.14507952597,101,,Caurel,Caurel,51,Marne,44,Grand Est
51102,CAUROY LES HERMONVILLE,51220,CAUROY LES HERMONVILLE,,49.3562446547,3.92821331358,102,,Cauroy-lès-Hermonville,Cauroy-lès-Hermonville,51,Marne,44,Grand Est
51103,LA CELLE SOUS CHANTEMERLE,51260,LA CELLE SOUS CHANTEMERLE,,48.6055592946,3.68625302626,103,La,Celle-sous-Chantemerle,La Celle-sous-Chantemerle,51,Marne,44,Grand Est
51104,CERNAY EN DORMOIS,51800,CERNAY EN DORMOIS,,49.2241452412,4.76942905433,104,,Cernay-en-Dormois,Cernay-en-Dormois,51,Marne,44,Grand Est
51105,CERNAY LES REIMS,51420,CERNAY LES REIMS,,49.2538223791,4.10981558086,105,,Cernay-lès-Reims,Cernay-lès-Reims,51,Marne,44,Grand Est
51106,CERNON,51240,CERNON,,48.8335946268,4.34181763415,106,,Cernon,Cernon,51,Marne,44,Grand Est
51107,CHAINTRIX BIERGES,51130,CHAINTRIX BIERGES,,48.8951439141,4.10313633097,107,,Chaintrix-Bierges,Chaintrix-Bierges,51,Marne,44,Grand Est
51108,CHALONS EN CHAMPAGNE,51000,CHALONS EN CHAMPAGNE,,48.9640892125,4.37883539725,108,,Châlons-en-Champagne,Châlons-en-Champagne,51,Marne,44,Grand Est
51109,CHALONS SUR VESLE,51140,CHALONS SUR VESLE,,49.2853621034,3.91987643283,109,,Châlons-sur-Vesle,Châlons-sur-Vesle,51,Marne,44,Grand Est
51110,CHALTRAIT,51130,CHALTRAIT,,48.9351662662,3.89300580573,110,,Chaltrait,Chaltrait,51,Marne,44,Grand Est
51111,CHAMBRECY,51170,CHAMBRECY,,49.1821738115,3.82605710701,111,,Chambrecy,Chambrecy,51,Marne,44,Grand Est
51112,CHAMERY,51500,CHAMERY,,49.1704993272,3.95695378996,112,,Chamery,Chamery,51,Marne,44,Grand Est
51113,CHAMPAUBERT,51270,CHAMPAUBERT,,48.8878457413,3.76608740283,113,,Champaubert,Champaubert,51,Marne,44,Grand Est
51115,CHAMPFLEURY,51500,CHAMPFLEURY,,49.195865059,4.01839707256,115,,Champfleury,Champfleury,51,Marne,44,Grand Est
51116,CHAMPGUYON,51310,CHAMPGUYON,,48.7716937806,3.56404200625,116,,Champguyon,Champguyon,51,Marne,44,Grand Est
51117,CHAMPIGNEUL CHAMPAGNE,51150,CHAMPIGNEUL CHAMPAGNE,,48.9651548385,4.18037243471,117,,Champigneul-Champagne,Champigneul-Champagne,51,Marne,44,Grand Est
51118,CHAMPIGNY,51370,CHAMPIGNY,,49.2683371756,3.95843961351,118,,Champigny,Champigny,51,Marne,44,Grand Est
51119,CHAMPILLON,51160,CHAMPILLON,,49.0848248132,3.97539279568,119,,Champillon,Champillon,51,Marne,44,Grand Est
51120,CHAMPLAT ET BOUJACOURT,51480,CHAMPLAT ET BOUJACOURT,,49.1558530772,3.82649080886,120,,Champlat-et-Boujacourt,Champlat-et-Boujacourt,51,Marne,44,Grand Est
51121,CHAMPVOISY,51700,CHAMPVOISY,,49.1261137519,3.62684686856,121,,Champvoisy,Champvoisy,51,Marne,44,Grand Est
51122,CHANGY,51300,CHANGY,,48.77967619,4.67870882012,122,,Changy,Changy,51,Marne,44,Grand Est
51124,CHANTEMERLE,51260,CHANTEMERLE,,48.6185982101,3.64465146733,124,,Chantemerle,Chantemerle,51,Marne,44,Grand Est
51125,CHAPELAINE,51290,CHAPELAINE,,48.5795561262,4.48064081068,125,,Chapelaine,Chapelaine,51,Marne,44,Grand Est
51126,LA CHAPELLE FELCOURT,51800,LA CHAPELLE FELCOURT,,49.0405394481,4.75534021192,126,La,Chapelle-Felcourt,La Chapelle-Felcourt,51,Marne,44,Grand Est
51127,LA CHAPELLE LASSON,51260,LA CHAPELLE LASSON,,48.6337338747,3.81745821088,127,La,Chapelle-Lasson,La Chapelle-Lasson,51,Marne,44,Grand Est
51128,LA CHAPELLE SOUS ORBAIS,51270,LA CHAPELLE SOUS ORBAIS,,48.9151526355,3.71933082114,128,La,Chapelle-sous-Orbais,La Chapelle-sous-Orbais,51,Marne,44,Grand Est
51129,CHARLEVILLE,51120,CHARLEVILLE,,48.8012203524,3.65820336577,129,,Charleville,Charleville,51,Marne,44,Grand Est
51130,CHARMONT,51330,CHARMONT,,48.8691072811,4.87049946102,130,,Charmont,Charmont,51,Marne,44,Grand Est
51132,LES CHARMONTOIS,51330,LES CHARMONTOIS,,48.9652158012,4.99726991782,132,Les,Charmontois,Les Charmontois,51,Marne,44,Grand Est
51133,LE CHATELIER,51330,LE CHATELIER,,48.9369936984,4.92578569834,133,Le,Châtelier,Le Châtelier,51,Marne,44,Grand Est
51134,CHATELRAOULD ST LOUVENT,51300,CHATELRAOULD ST LOUVENT,,48.6623062215,4.51571278495,134,,Châtelraould-Saint-Louvent,Châtelraould-Saint-Louvent,51,Marne,44,Grand Est
51135,CHATILLON SUR BROUE,51290,CHATILLON SUR BROUE,,48.5467940189,4.70070241315,135,,Châtillon-sur-Broué,Châtillon-sur-Broué,51,Marne,44,Grand Est
51136,CHATILLON SUR MARNE,51700,CHATILLON SUR MARNE,,49.1032018844,3.76590199588,136,,Châtillon-sur-Marne,Châtillon-sur-Marne,51,Marne,44,Grand Est
51137,CHATILLON SUR MORIN,51310,CHATILLON SUR MORIN,,48.6976684127,3.58856747174,137,,Châtillon-sur-Morin,Châtillon-sur-Morin,51,Marne,44,Grand Est
51138,CHATRICES,51800,CHATRICES,,49.0394929135,4.94531122105,138,,Châtrices,Châtrices,51,Marne,44,Grand Est
51139,CHAUDEFONTAINE,51800,CHAUDEFONTAINE,,49.1004750135,4.87042311361,139,,Chaudefontaine,Chaudefontaine,51,Marne,44,Grand Est
51140,CHAUMUZY,51170,CHAUMUZY,,49.1596131119,3.86801749375,140,,Chaumuzy,Chaumuzy,51,Marne,44,Grand Est
51141,LA CHAUSSEE SUR MARNE,51240,LA CHAUSSEE SUR MARNE,,48.8433156105,4.54286173009,141,La,Chaussée-sur-Marne,La Chaussée-sur-Marne,51,Marne,44,Grand Est
51142,CHAVOT COURCOURT,51530,CHAVOT COURCOURT,,48.9979835849,3.91605601452,142,,Chavot-Courcourt,Chavot-Courcourt,51,Marne,44,Grand Est
51143,LE CHEMIN,51800,LE CHEMIN,,49.0002264299,4.97827512976,143,Le,Chemin,Le Chemin,51,Marne,44,Grand Est
51144,CHEMINON,51250,CHEMINON,,48.7435589691,4.92012319388,144,,Cheminon,Cheminon,51,Marne,44,Grand Est
51145,CHENAY,51140,CHENAY,,49.3017965632,3.92526660394,145,,Chenay,Chenay,51,Marne,44,Grand Est
51146,CHENIERS,51510,CHENIERS,,48.886031036,4.24425126854,146,,Cheniers,Cheniers,51,Marne,44,Grand Est
51147,LA CHEPPE,51600,LA CHEPPE,,49.0495940104,4.48839724856,147,La,Cheppe,La Cheppe,51,Marne,44,Grand Est
51148,CHEPPES LA PRAIRIE,51240,CHEPPES LA PRAIRIE,,48.8177073997,4.44216733433,148,,Cheppes-la-Prairie,Cheppes-la-Prairie,51,Marne,44,Grand Est
51149,CHEPY,51240,CHEPY,,48.906707488,4.45380528055,149,,Chepy,Chepy,51,Marne,44,Grand Est
51150,CHERVILLE,51150,CHERVILLE,,49.0033010144,4.15123028739,150,,Cherville,Cherville,51,Marne,44,Grand Est
51151,CHICHEY,51120,CHICHEY,,48.6829470295,3.75431338999,151,,Chichey,Chichey,51,Marne,44,Grand Est
51152,CHIGNY LES ROSES,51500,CHIGNY LES ROSES,,49.1510624676,4.06063898517,152,,Chigny-les-Roses,Chigny-les-Roses,51,Marne,44,Grand Est
51153,CHOUILLY,51530,CHOUILLY,,49.0176099992,4.00414015936,153,,Chouilly,Chouilly,51,Marne,44,Grand Est
51154,CLAMANGES,51130,CLAMANGES,,48.8301398095,4.08311012604,154,,Clamanges,Clamanges,51,Marne,44,Grand Est
51155,CLESLES,51260,CLESLES,,48.533182949,3.82710958128,155,,Clesles,Clesles,51,Marne,44,Grand Est
51156,CLOYES SUR MARNE,51300,CLOYES SUR MARNE,,48.6612318612,4.64206905718,156,,Cloyes-sur-Marne,Cloyes-sur-Marne,51,Marne,44,Grand Est
51157,COIZARD JOCHES,51270,COIZARD JOCHES,,48.828738197,3.86398431629,157,,Coizard-Joches,Coizard-Joches,51,Marne,44,Grand Est
51158,VAL DES MARAIS,51130,VAL DES MARAIS,,48.8273139118,3.96840907593,158,,Val-des-Marais,Val-des-Marais,51,Marne,44,Grand Est
51158,VAL DES MARAIS,51130,VAL DES MARAIS,AULNAY AUX PLANCHES,48.8273139118,3.96840907593,158,,Val-des-Marais,Val-des-Marais,51,Marne,44,Grand Est
51158,VAL DES MARAIS,51130,VAL DES MARAIS,AULNIZEUX,48.8273139118,3.96840907593,158,,Val-des-Marais,Val-des-Marais,51,Marne,44,Grand Est
51158,VAL DES MARAIS,51130,VAL DES MARAIS,MORAINS,48.8273139118,3.96840907593,158,,Val-des-Marais,Val-des-Marais,51,Marne,44,Grand Est
51160,COMPERTRIX,51510,COMPERTRIX,,48.9280892911,4.32579427772,160,,Compertrix,Compertrix,51,Marne,44,Grand Est
51161,CONDE SUR MARNE,51150,CONDE SUR MARNE,,49.0465594364,4.18039266936,161,,Condé-sur-Marne,Condé-sur-Marne,51,Marne,44,Grand Est
51162,CONFLANS SUR SEINE,51260,CONFLANS SUR SEINE,,48.5481786951,3.68299893221,162,,Conflans-sur-Seine,Conflans-sur-Seine,51,Marne,44,Grand Est
51163,CONGY,51270,CONGY,,48.8704889111,3.81828668315,163,,Congy,Congy,51,Marne,44,Grand Est
51164,CONNANTRAY VAUREFROY,51230,CONNANTRAY VAUREFROY,,48.7387596621,4.06685851055,164,,Connantray-Vaurefroy,Connantray-Vaurefroy,51,Marne,44,Grand Est
51165,CONNANTRE,51230,CONNANTRE,,48.7383348646,3.91196281487,165,,Connantre,Connantre,51,Marne,44,Grand Est
51166,CONTAULT,51330,CONTAULT,,48.915677101,4.78766587099,166,,Contault,Contault,51,Marne,44,Grand Est
51167,COOLE,51320,COOLE,,48.7392759511,4.39164451957,167,,Coole,Coole,51,Marne,44,Grand Est
51168,COOLUS,51510,COOLUS,,48.9125767711,4.32081484845,168,,Coolus,Coolus,51,Marne,44,Grand Est
51169,CORBEIL,51320,CORBEIL,,48.573704122,4.43585861027,169,,Corbeil,Corbeil,51,Marne,44,Grand Est
51170,CORFELIX,51210,CORFELIX,,48.8313022988,3.69721601306,170,,Corfélix,Corfélix,51,Marne,44,Grand Est
51171,CORMICY,51220,CORMICY,,49.3768003286,3.90513204967,171,,Cormicy,Cormicy,51,Marne,44,Grand Est
51172,CORMONTREUIL,51350,CORMONTREUIL,,49.2171216768,4.05352954118,172,,Cormontreuil,Cormontreuil,51,Marne,44,Grand Est
51173,CORMOYEUX,51480,CORMOYEUX,,49.1091268833,3.9125350258,173,,Cormoyeux,Cormoyeux,51,Marne,44,Grand Est
51174,CORRIBERT,51270,CORRIBERT,,48.9415239593,3.76703691409,174,,Corribert,Corribert,51,Marne,44,Grand Est
51175,CORROBERT,51210,CORROBERT,,48.9233679376,3.60759515783,175,,Corrobert,Corrobert,51,Marne,44,Grand Est
51176,CORROY,51230,CORROY,,48.7025366162,3.95701088583,176,,Corroy,Corroy,51,Marne,44,Grand Est
51177,COULOMMES LA MONTAGNE,51390,COULOMMES LA MONTAGNE,,49.2264862111,3.91132904874,177,,Coulommes-la-Montagne,Coulommes-la-Montagne,51,Marne,44,Grand Est
51178,COUPETZ,51240,COUPETZ,,48.8133160842,4.3502647164,178,,Coupetz,Coupetz,51,Marne,44,Grand Est
51179,COUPEVILLE,51240,COUPEVILLE,,48.9039008235,4.6230291647,179,,Coupéville,Coupéville,51,Marne,44,Grand Est
51181,COURCELLES SAPICOURT,51140,COURCELLES SAPICOURT,,49.2678098004,3.84186227209,181,,Courcelles-Sapicourt,Courcelles-Sapicourt,51,Marne,44,Grand Est
51182,COURCEMAIN,51260,COURCEMAIN,,48.6183477857,3.93310900792,182,,Courcemain,Courcemain,51,Marne,44,Grand Est
51183,COURCY,51220,COURCY,,49.3196081983,4.0061261454,183,,Courcy,Courcy,51,Marne,44,Grand Est
51184,COURDEMANGES,51300,COURDEMANGES,,48.6799310548,4.50944976193,184,,Courdemanges,Courdemanges,51,Marne,44,Grand Est
51185,COURGIVAUX,51310,COURGIVAUX,,48.7130437686,3.49067370796,185,,Courgivaux,Courgivaux,51,Marne,44,Grand Est
51186,COURJEONNET,51270,COURJEONNET,,48.8245748598,3.82986361597,186,,Courjeonnet,Courjeonnet,51,Marne,44,Grand Est
51187,COURLANDON,51170,COURLANDON,,49.3163787015,3.73552622692,187,,Courlandon,Courlandon,51,Marne,44,Grand Est
51188,COURMAS,51390,COURMAS,,49.1889586184,3.9120738875,188,,Courmas,Courmas,51,Marne,44,Grand Est
51190,COURTAGNON,51480,COURTAGNON,,49.1443836828,3.94593690729,190,,Courtagnon,Courtagnon,51,Marne,44,Grand Est
51191,COURTEMONT,51800,COURTEMONT,,49.1404547237,4.78279338239,191,,Courtémont,Courtémont,51,Marne,44,Grand Est
51192,COURTHIEZY,51700,COURTHIEZY,,49.0472061076,3.60014193409,192,,Courthiézy,Courthiézy,51,Marne,44,Grand Est
51193,COURTISOLS,51460,COURTISOLS,,48.9772975479,4.51921227291,193,,Courtisols,Courtisols,51,Marne,44,Grand Est
51194,COURVILLE,51170,COURVILLE,,49.2717748239,3.70869393616,194,,Courville,Courville,51,Marne,44,Grand Est
51195,COUVROT,51300,COUVROT,,48.7661940133,4.57813670134,195,,Couvrot,Couvrot,51,Marne,44,Grand Est
51196,CRAMANT,51530,CRAMANT,,48.9875700234,4.00091885006,196,,Cramant,Cramant,51,Marne,44,Grand Est
51197,LA CROIX EN CHAMPAGNE,51600,LA CROIX EN CHAMPAGNE,,49.068970184,4.6608844245,197,La,Croix-en-Champagne,La Croix-en-Champagne,51,Marne,44,Grand Est
51198,CRUGNY,51170,CRUGNY,,49.2540458252,3.73483729976,198,,Crugny,Crugny,51,Marne,44,Grand Est
51199,CUCHERY,51480,CUCHERY,,49.1271200021,3.8265472233,199,,Cuchery,Cuchery,51,Marne,44,Grand Est
51200,CUIS,51530,CUIS,,48.9995241684,3.9667438583,200,,Cuis,Cuis,51,Marne,44,Grand Est
51201,CUISLES,51700,CUISLES,,49.1303077225,3.77691947684,201,,Cuisles,Cuisles,51,Marne,44,Grand Est
51202,CUMIERES,51480,CUMIERES,,49.0721627289,3.91948376313,202,,Cumières,Cumières,51,Marne,44,Grand Est
51203,CUPERLY,51400,CUPERLY,,49.0645166138,4.44810543218,203,,Cuperly,Cuperly,51,Marne,44,Grand Est
51204,DAMERY,51480,DAMERY,,49.080484604,3.87786708147,204,,Damery,Damery,51,Marne,44,Grand Est
51205,DAMPIERRE AU TEMPLE,51400,DAMPIERRE AU TEMPLE,,49.0387201638,4.39365189938,205,,Dampierre-au-Temple,Dampierre-au-Temple,51,Marne,44,Grand Est
51206,DAMPIERRE LE CHATEAU,51330,DAMPIERRE LE CHATEAU,,49.0075580233,4.80217889942,206,,Dampierre-le-Château,Dampierre-le-Château,51,Marne,44,Grand Est
51208,DAMPIERRE SUR MOIVRE,51240,DAMPIERRE SUR MOIVRE,,48.8796364329,4.57485707749,208,,Dampierre-sur-Moivre,Dampierre-sur-Moivre,51,Marne,44,Grand Est
51210,DIZY,51530,DIZY,,49.0705211181,3.97084205034,210,,Dizy,Dizy,51,Marne,44,Grand Est
51211,DOMMARTIN DAMPIERRE,51800,DOMMARTIN DAMPIERRE,,49.0658685537,4.82440047617,211,,Dommartin-Dampierre,Dommartin-Dampierre,51,Marne,44,Grand Est
51212,DOMMARTIN LETTREE,51320,DOMMARTIN LETTREE,,48.771366093,4.28578540294,212,,Dommartin-Lettrée,Dommartin-Lettrée,51,Marne,44,Grand Est
51213,DOMMARTIN SOUS HANS,51800,DOMMARTIN SOUS HANS,,49.12412458,4.7940542532,213,,Dommartin-sous-Hans,Dommartin-sous-Hans,51,Marne,44,Grand Est
51214,DOMMARTIN VARIMONT,51330,DOMMARTIN VARIMONT,,48.9782968112,4.77366726266,214,,Dommartin-Varimont,Dommartin-Varimont,51,Marne,44,Grand Est
51215,DOMPREMY,51300,DOMPREMY,,48.7333043849,4.73803791435,215,,Dompremy,Dompremy,51,Marne,44,Grand Est
51216,DONTRIEN,51490,DONTRIEN,,49.2408574651,4.41468324878,216,,Dontrien,Dontrien,51,Marne,44,Grand Est
51217,DORMANS,51700,DORMANS,,49.062193047,3.6454130311,217,,Dormans,Dormans,51,Marne,44,Grand Est
51217,DORMANS,51700,DORMANS,SOILLY,49.062193047,3.6454130311,217,,Dormans,Dormans,51,Marne,44,Grand Est
51218,VAL DE VIERE,51340,VAL DE VIERE,,48.8193926004,4.74532612173,218,,Val-de-Vière,Val-de-Vière,51,Marne,44,Grand Est
51219,DROSNAY,51290,DROSNAY,,48.5730970733,4.61843085451,219,,Drosnay,Drosnay,51,Marne,44,Grand Est
51220,DROUILLY,51300,DROUILLY,,48.7699720491,4.51233011677,220,,Drouilly,Drouilly,51,Marne,44,Grand Est
51222,ECLAIRES,51800,ECLAIRES,,48.9981693662,5.00427988031,222,,Éclaires,Éclaires,51,Marne,44,Grand Est
51223,ECOLLEMONT,51290,ECOLLEMONT,,48.6153480087,4.73962649282,223,,Écollemont,Écollemont,51,Marne,44,Grand Est
51224,ECRIENNES,51300,ECRIENNES,,48.6922657647,4.68742317989,224,,Écriennes,Écriennes,51,Marne,44,Grand Est
51225,ECUEIL,51500,ECUEIL,,49.1815231826,3.95026646636,225,,Écueil,Écueil,51,Marne,44,Grand Est
51226,ECURY LE REPOS,51230,ECURY LE REPOS,,48.8085024476,4.02740679239,226,,Écury-le-Repos,Écury-le-Repos,51,Marne,44,Grand Est
51227,ECURY SUR COOLE,51240,ECURY SUR COOLE,,48.8957543213,4.3447919413,227,,Écury-sur-Coole,Écury-sur-Coole,51,Marne,44,Grand Est
51228,ELISE DAUCOURT,51800,ELISE DAUCOURT,,49.0425311549,4.86078672791,228,,Élise-Daucourt,Élise-Daucourt,51,Marne,44,Grand Est
51229,EPENSE,51330,EPENSE,,48.9742892722,4.83231337792,229,,Épense,Épense,51,Marne,44,Grand Est
51230,EPERNAY,51200,EPERNAY,,49.037000689,3.93144626202,230,,Épernay,Épernay,51,Marne,44,Grand Est
51231,L EPINE,51460,L EPINE,,48.9939672818,4.44934691033,231,L',Épine,L'Épine,51,Marne,44,Grand Est
51232,EPOYE,51490,EPOYE,,49.2866248574,4.23317683518,232,,Époye,Époye,51,Marne,44,Grand Est
51233,ESCARDES,51310,ESCARDES,,48.6900375432,3.52743435778,233,,Escardes,Escardes,51,Marne,44,Grand Est
51234,ESCLAVOLLES LUREY,51260,ESCLAVOLLES LUREY,,48.5543933536,3.64977752907,234,,Esclavolles-Lurey,Esclavolles-Lurey,51,Marne,44,Grand Est
51235,LES ESSARTS LES SEZANNE,51120,LES ESSARTS LES SEZANNE,,48.7648493747,3.63896907299,235,Les,Essarts-lès-Sézanne,Les Essarts-lès-Sézanne,51,Marne,44,Grand Est
51236,LES ESSARTS LE VICOMTE,51310,LES ESSARTS LE VICOMTE,,48.6646500716,3.55311169062,236,Les,Essarts-le-Vicomte,Les Essarts-le-Vicomte,51,Marne,44,Grand Est
51237,ESTERNAY,51310,ESTERNAY,,48.7264632721,3.57621013369,237,,Esternay,Esternay,51,Marne,44,Grand Est
51238,ETOGES,51270,ETOGES,,48.8915340584,3.85279805872,238,,Étoges,Étoges,51,Marne,44,Grand Est
51239,ETRECHY,51130,ETRECHY,,48.8826569512,3.95067419112,239,,Étréchy,Étréchy,51,Marne,44,Grand Est
51240,ETREPY,51340,ETREPY,,48.7627041855,4.80935325993,240,,Étrepy,Étrepy,51,Marne,44,Grand Est
51241,EUVY,51230,EUVY,,48.7176600126,4.01995122819,241,,Euvy,Euvy,51,Marne,44,Grand Est
51242,FAGNIERES,51510,FAGNIERES,,48.9465486999,4.30937334592,242,,Fagnières,Fagnières,51,Marne,44,Grand Est
51243,FAUX FRESNAY,51230,FAUX FRESNAY,,48.6524940366,3.9495151761,243,,Faux-Fresnay,Faux-Fresnay,51,Marne,44,Grand Est
51244,FAUX VESIGNEUL,51320,FAUX VESIGNEUL,,48.7824659739,4.37941197112,244,,Faux-Vésigneul,Faux-Vésigneul,51,Marne,44,Grand Est
51244,FAUX VESIGNEUL,51320,FAUX VESIGNEUL,FONTAINE SUR COOLE,48.7824659739,4.37941197112,244,,Faux-Vésigneul,Faux-Vésigneul,51,Marne,44,Grand Est
51245,FAVEROLLES ET COEMY,51170,FAVEROLLES ET COEMY,,49.2326869732,3.7908317551,245,,Faverolles-et-Coëmy,Faverolles-et-Coëmy,51,Marne,44,Grand Est
51246,FAVRESSE,51300,FAVRESSE,,48.719129687,4.71728407757,246,,Favresse,Favresse,51,Marne,44,Grand Est
51247,FEREBRIANGES,51270,FEREBRIANGES,,48.8711384032,3.8404842316,247,,Fèrebrianges,Fèrebrianges,51,Marne,44,Grand Est
51248,FERE CHAMPENOISE,51230,FERE CHAMPENOISE,,48.7706781866,4.01743444039,248,,Fère-Champenoise,Fère-Champenoise,51,Marne,44,Grand Est
51248,FERE CHAMPENOISE,51230,FERE CHAMPENOISE,NORMEE,48.7706781866,4.01743444039,248,,Fère-Champenoise,Fère-Champenoise,51,Marne,44,Grand Est
51249,FESTIGNY,51700,FESTIGNY,,49.0282923032,3.76729652703,249,,Festigny,Festigny,51,Marne,44,Grand Est
51250,FISMES,51170,FISMES,,49.3059837243,3.6791731942,250,,Fismes,Fismes,51,Marne,44,Grand Est
51251,FLAVIGNY,51190,FLAVIGNY,,48.9752908905,4.06189871068,251,,Flavigny,Flavigny,51,Marne,44,Grand Est
51252,FLEURY LA RIVIERE,51480,FLEURY LA RIVIERE,,49.1031077559,3.8861561458,252,,Fleury-la-Rivière,Fleury-la-Rivière,51,Marne,44,Grand Est
51253,FLORENT EN ARGONNE,51800,FLORENT EN ARGONNE,,49.1389592855,4.94934662778,253,,Florent-en-Argonne,Florent-en-Argonne,51,Marne,44,Grand Est
51254,FONTAINE DENIS NUISY,51120,FONTAINE DENIS NUISY,,48.6316590806,3.67679439561,254,,Fontaine-Denis-Nuisy,Fontaine-Denis-Nuisy,51,Marne,44,Grand Est
51255,FONTAINE EN DORMOIS,51800,FONTAINE EN DORMOIS,,49.2404904108,4.72158432493,255,,Fontaine-en-Dormois,Fontaine-en-Dormois,51,Marne,44,Grand Est
51256,FONTAINE SUR AY,51160,FONTAINE SUR AY,,49.0919957027,4.06660970581,256,,Fontaine-sur-Ay,Fontaine-sur-Ay,51,Marne,44,Grand Est
51258,LA FORESTIERE,51120,LA FORESTIERE,,48.6603046786,3.6042984967,258,La,Forestière,La Forestière,51,Marne,44,Grand Est
51259,FRANCHEVILLE,51240,FRANCHEVILLE,,48.8786491229,4.54064901103,259,,Francheville,Francheville,51,Marne,44,Grand Est
51260,LE FRESNE,51240,LE FRESNE,,48.9074828008,4.66619316954,260,Le,Fresne,Le Fresne,51,Marne,44,Grand Est
51261,FRESNE LES REIMS,51110,FRESNE LES REIMS,,49.3387116381,4.10138563501,261,,Fresne-lès-Reims,Fresne-lès-Reims,51,Marne,44,Grand Est
51262,FRIGNICOURT,51300,FRIGNICOURT,,48.7010173112,4.5952745376,262,,Frignicourt,Frignicourt,51,Marne,44,Grand Est
51263,FROMENTIERES,51210,FROMENTIERES,,48.8899366213,3.70451315221,263,,Fromentières,Fromentières,51,Marne,44,Grand Est
51264,LE GAULT SOIGNY,51210,LE GAULT SOIGNY,,48.8104099198,3.60267965584,264,Le,Gault-Soigny,Le Gault-Soigny,51,Marne,44,Grand Est
51265,GAYE,51120,GAYE,,48.6801754701,3.8063084627,265,,Gaye,Gaye,51,Marne,44,Grand Est
51266,GERMAINE,51160,GERMAINE,,49.1188404223,4.03185929464,266,,Germaine,Germaine,51,Marne,44,Grand Est
51267,GERMIGNY,51390,GERMIGNY,,49.2445998408,3.86056138886,267,,Germigny,Germigny,51,Marne,44,Grand Est
51268,GERMINON,51130,GERMINON,,48.8864611799,4.17848404466,268,,Germinon,Germinon,51,Marne,44,Grand Est
51269,GIFFAUMONT CHAMPAUBERT,51290,GIFFAUMONT CHAMPAUBERT,,48.5598653758,4.74815226277,269,,Giffaumont-Champaubert,Giffaumont-Champaubert,51,Marne,44,Grand Est
51270,GIGNY BUSSY,51290,GIGNY BUSSY,,48.6098773154,4.58918617422,270,,Gigny-Bussy,Gigny-Bussy,51,Marne,44,Grand Est
51271,GIONGES,51130,GIONGES,,48.9403574881,3.96461880692,271,,Gionges,Gionges,51,Marne,44,Grand Est
51272,GIVRY EN ARGONNE,51330,GIVRY EN ARGONNE,,48.9489230669,4.90669459258,272,,Givry-en-Argonne,Givry-en-Argonne,51,Marne,44,Grand Est
51273,GIVRY LES LOISY,51130,GIVRY LES LOISY,,48.8924489876,3.91584386017,273,,Givry-lès-Loisy,Givry-lès-Loisy,51,Marne,44,Grand Est
51274,GIZAUCOURT,51800,GIZAUCOURT,,49.0566661968,4.78091155159,274,,Gizaucourt,Gizaucourt,51,Marne,44,Grand Est
51275,GLANNES,51300,GLANNES,,48.7050593969,4.50000704361,275,,Glannes,Glannes,51,Marne,44,Grand Est
51276,GOURGANCON,51230,GOURGANCON,,48.6847255926,4.02672471881,276,,Gourgançon,Gourgançon,51,Marne,44,Grand Est
51277,STE MARIE DU LAC NUISEMENT,51290,STE MARIE DU LAC NUISEMENT,,48.6009118806,4.76140676125,277,,Sainte-Marie-du-Lac-Nuisement,Sainte-Marie-du-Lac-Nuisement,51,Marne,44,Grand Est
51278,LES GRANDES LOGES,51400,LES GRANDES LOGES,,49.064046771,4.28902555718,278,Les,Grandes-Loges,Les Grandes-Loges,51,Marne,44,Grand Est
51279,GRANGES SUR AUBE,51260,GRANGES SUR AUBE,,48.5884688345,3.85896108603,279,,Granges-sur-Aube,Granges-sur-Aube,51,Marne,44,Grand Est
51280,GRATREUIL,51800,GRATREUIL,,49.2460502906,4.69103289221,280,,Gratreuil,Gratreuil,51,Marne,44,Grand Est
51281,GRAUVES,51190,GRAUVES,,48.9686001163,3.95992650372,281,,Grauves,Grauves,51,Marne,44,Grand Est
51282,GUEUX,51390,GUEUX,,49.2540182295,3.90671179707,282,,Gueux,Gueux,51,Marne,44,Grand Est
51283,HANS,51800,HANS,,49.1139160574,4.74397425381,283,,Hans,Hans,51,Marne,44,Grand Est
51284,HAUSSIGNEMONT,51300,HAUSSIGNEMONT,,48.7163058306,4.75359455149,284,,Haussignémont,Haussignémont,51,Marne,44,Grand Est
51285,HAUSSIMONT,51320,HAUSSIMONT,,48.7448119146,4.16707787714,285,,Haussimont,Haussimont,51,Marne,44,Grand Est
51286,HAUTEVILLE,51290,HAUTEVILLE,,48.6354922599,4.77235476532,286,,Hauteville,Hauteville,51,Marne,44,Grand Est
51287,HAUTVILLERS,51160,HAUTVILLERS,,49.085558433,3.94419700261,287,,Hautvillers,Hautvillers,51,Marne,44,Grand Est
51288,HEILTZ LE HUTIER,51300,HEILTZ LE HUTIER,,48.6895232624,4.773330849,288,,Heiltz-le-Hutier,Heiltz-le-Hutier,51,Marne,44,Grand Est
51289,HEILTZ LE MAURUPT,51340,HEILTZ LE MAURUPT,,48.7987019209,4.82920100589,289,,Heiltz-le-Maurupt,Heiltz-le-Maurupt,51,Marne,44,Grand Est
51290,HEILTZ L EVEQUE,51340,HEILTZ L EVEQUE,,48.7791762757,4.73816215254,290,,Heiltz-l'Évêque,Heiltz-l'Évêque,51,Marne,44,Grand Est
51291,HERMONVILLE,51220,HERMONVILLE,,49.3318496346,3.9143354087,291,,Hermonville,Hermonville,51,Marne,44,Grand Est
51292,HERPONT,51460,HERPONT,,48.9901236506,4.71862295964,292,,Herpont,Herpont,51,Marne,44,Grand Est
51293,HEUTREGIVILLE,51110,HEUTREGIVILLE,,49.3281543526,4.26735597834,293,,Heutrégiville,Heutrégiville,51,Marne,44,Grand Est
51294,HOURGES,51140,HOURGES,,49.281019544,3.77065706879,294,,Hourges,Hourges,51,Marne,44,Grand Est
51295,HUIRON,51300,HUIRON,,48.6918417886,4.49329566326,295,,Huiron,Huiron,51,Marne,44,Grand Est
51296,HUMBAUVILLE,51320,HUMBAUVILLE,,48.6578881242,4.39725171693,296,,Humbauville,Humbauville,51,Marne,44,Grand Est
51298,IGNY COMBLIZY,51700,IGNY COMBLIZY,,49.0071354293,3.7152217773,298,,Igny-Comblizy,Igny-Comblizy,51,Marne,44,Grand Est
51299,ISLES SUR SUIPPE,51110,ISLES SUR SUIPPE,,49.3630419181,4.202938765,299,,Isles-sur-Suippe,Isles-sur-Suippe,51,Marne,44,Grand Est
51300,ISLE SUR MARNE,51290,ISLE SUR MARNE,,48.6453011796,4.6872585742,300,,Isle-sur-Marne,Isle-sur-Marne,51,Marne,44,Grand Est
51301,ISSE,51150,ISSE,,49.0697584759,4.21594077406,301,,Isse,Isse,51,Marne,44,Grand Est
51302,LES ISTRES ET BURY,51190,LES ISTRES ET BURY,,48.9874290084,4.08707328889,302,Les,Istres-et-Bury,Les Istres-et-Bury,51,Marne,44,Grand Est
51303,JALONS,51150,JALONS,,49.0064136945,4.17596884263,303,,Jâlons,Jâlons,51,Marne,44,Grand Est
51304,JANVILLIERS,51210,JANVILLIERS,,48.8954151697,3.65818118533,304,,Janvilliers,Janvilliers,51,Marne,44,Grand Est
51305,JANVRY,51390,JANVRY,,49.2443068265,3.87748501301,305,,Janvry,Janvry,51,Marne,44,Grand Est
51306,JOISELLE,51310,JOISELLE,,48.7649096343,3.49706015109,306,,Joiselle,Joiselle,51,Marne,44,Grand Est
51307,JONCHERY SUR SUIPPE,51600,JONCHERY SUR SUIPPE,,49.1397315843,4.47372094597,307,,Jonchery-sur-Suippe,Jonchery-sur-Suippe,51,Marne,44,Grand Est
51308,JONCHERY SUR VESLE,51140,JONCHERY SUR VESLE,,49.2860158121,3.81843506219,308,,Jonchery-sur-Vesle,Jonchery-sur-Vesle,51,Marne,44,Grand Est
51309,JONQUERY,51700,JONQUERY,,49.1453578537,3.79009280539,309,,Jonquery,Jonquery,51,Marne,44,Grand Est
51310,JOUY LES REIMS,51390,JOUY LES REIMS,,49.2123542299,3.92767747156,310,,Jouy-lès-Reims,Jouy-lès-Reims,51,Marne,44,Grand Est
51311,JUSSECOURT MINECOURT,51340,JUSSECOURT MINECOURT,,48.7925796867,4.78085431604,311,,Jussecourt-Minecourt,Jussecourt-Minecourt,51,Marne,44,Grand Est
51312,JUVIGNY,51150,JUVIGNY,,49.0158806281,4.27525107126,312,,Juvigny,Juvigny,51,Marne,44,Grand Est
51313,LACHY,51120,LACHY,,48.769209655,3.7010108358,313,,Lachy,Lachy,51,Marne,44,Grand Est
51314,LAGERY,51170,LAGERY,,49.2071148254,3.732444574,314,,Lagery,Lagery,51,Marne,44,Grand Est
51315,LANDRICOURT,51290,LANDRICOURT,,48.6128944545,4.8069011125,315,,Landricourt,Landricourt,51,Marne,44,Grand Est
51316,LARZICOURT,51290,LARZICOURT,,48.6349897271,4.72626956254,316,,Larzicourt,Larzicourt,51,Marne,44,Grand Est
51317,LAVAL SUR TOURBE,51600,LAVAL SUR TOURBE,,49.1413872858,4.66851737844,317,,Laval-sur-Tourbe,Laval-sur-Tourbe,51,Marne,44,Grand Est
51318,LAVANNES,51110,LAVANNES,,49.3106590076,4.18731846893,318,,Lavannes,Lavannes,51,Marne,44,Grand Est
51319,LENHARREE,51230,LENHARREE,,48.7792639645,4.11685316512,319,,Lenharrée,Lenharrée,51,Marne,44,Grand Est
51320,LEUVRIGNY,51700,LEUVRIGNY,,49.0537619832,3.77685120343,320,,Leuvrigny,Leuvrigny,51,Marne,44,Grand Est
51321,LHERY,51170,LHERY,,49.2084675481,3.76792527831,321,,Lhéry,Lhéry,51,Marne,44,Grand Est
51322,LIGNON,51290,LIGNON,,48.5893337928,4.53111130718,322,,Lignon,Lignon,51,Marne,44,Grand Est
51323,LINTHELLES,51230,LINTHELLES,,48.7138896496,3.81792576197,323,,Linthelles,Linthelles,51,Marne,44,Grand Est
51324,LINTHES,51230,LINTHES,,48.7336444727,3.8560516976,324,,Linthes,Linthes,51,Marne,44,Grand Est
51325,LISSE EN CHAMPAGNE,51300,LISSE EN CHAMPAGNE,,48.8183214275,4.64935373876,325,,Lisse-en-Champagne,Lisse-en-Champagne,51,Marne,44,Grand Est
51326,LIVRY LOUVERCY,51400,LIVRY LOUVERCY,,49.1058291749,4.31269707688,326,,Livry-Louvercy,Livry-Louvercy,51,Marne,44,Grand Est
51327,LOISY EN BRIE,51130,LOISY EN BRIE,,48.8937645721,3.89048343145,327,,Loisy-en-Brie,Loisy-en-Brie,51,Marne,44,Grand Est
51328,LOISY SUR MARNE,51300,LOISY SUR MARNE,,48.7501424119,4.54084010874,328,,Loisy-sur-Marne,Loisy-sur-Marne,51,Marne,44,Grand Est
51329,LOIVRE,51220,LOIVRE,,49.3456807799,3.9750851292,329,,Loivre,Loivre,51,Marne,44,Grand Est
51333,LUDES,51500,LUDES,,49.1597280176,4.08223281179,333,,Ludes,Ludes,51,Marne,44,Grand Est
51334,LUXEMONT ET VILLOTTE,51300,LUXEMONT ET VILLOTTE,,48.6983539754,4.63716318666,334,,Luxémont-et-Villotte,Luxémont-et-Villotte,51,Marne,44,Grand Est
51336,MAFFRECOURT,51800,MAFFRECOURT,,49.1144837191,4.819903628,336,,Maffrécourt,Maffrécourt,51,Marne,44,Grand Est
51337,MAGNEUX,51170,MAGNEUX,,49.3005344986,3.72711655162,337,,Magneux,Magneux,51,Marne,44,Grand Est
51338,MAILLY CHAMPAGNE,51500,MAILLY CHAMPAGNE,,49.1547915207,4.11192393083,338,,Mailly-Champagne,Mailly-Champagne,51,Marne,44,Grand Est
51339,MAIRY SUR MARNE,51240,MAIRY SUR MARNE,,48.8677969763,4.39336523723,339,,Mairy-sur-Marne,Mairy-sur-Marne,51,Marne,44,Grand Est
51340,MAISONS EN CHAMPAGNE,51300,MAISONS EN CHAMPAGNE,,48.7366632829,4.46700160771,340,,Maisons-en-Champagne,Maisons-en-Champagne,51,Marne,44,Grand Est
51341,MALMY,51800,MALMY,,49.1805031948,4.81024077767,341,,Malmy,Malmy,51,Marne,44,Grand Est
51342,MANCY,51530,MANCY,,48.982499338,3.9380543947,342,,Mancy,Mancy,51,Marne,44,Grand Est
51343,MARCILLY SUR SEINE,51260,MARCILLY SUR SEINE,,48.5553652866,3.71047973437,343,,Marcilly-sur-Seine,Marcilly-sur-Seine,51,Marne,44,Grand Est
51344,MARDEUIL,51530,MARDEUIL,,49.0552896714,3.92278542438,344,,Mardeuil,Mardeuil,51,Marne,44,Grand Est
51345,MAREUIL EN BRIE,51270,MAREUIL EN BRIE,,48.9638384493,3.75395021148,345,,Mareuil-en-Brie,Mareuil-en-Brie,51,Marne,44,Grand Est
51346,MAREUIL LE PORT,51700,MAREUIL LE PORT,,49.0737672387,3.74425898431,346,,Mareuil-le-Port,Mareuil-le-Port,51,Marne,44,Grand Est
51346,MAREUIL LE PORT,51700,MAREUIL LE PORT,PORT A BINSON,49.0737672387,3.74425898431,346,,Mareuil-le-Port,Mareuil-le-Port,51,Marne,44,Grand Est
51348,MARFAUX,51170,MARFAUX,,49.1691156658,3.90046162118,348,,Marfaux,Marfaux,51,Marne,44,Grand Est
51349,MARGERIE HANCOURT,51290,MARGERIE HANCOURT,,48.5547827328,4.54428346622,349,,Margerie-Hancourt,Margerie-Hancourt,51,Marne,44,Grand Est
51350,MARGNY,51210,MARGNY,,48.9223639685,3.65775146218,350,,Margny,Margny,51,Marne,44,Grand Est
51351,MARIGNY,51230,MARIGNY,,48.6620436629,3.84731315305,351,,Marigny,Marigny,51,Marne,44,Grand Est
51352,MAROLLES,51300,MAROLLES,,48.7205571657,4.62167240644,352,,Marolles,Marolles,51,Marne,44,Grand Est
51353,MARSANGIS,51260,MARSANGIS,,48.6113473287,3.85182777539,353,,Marsangis,Marsangis,51,Marne,44,Grand Est
51354,MARSON,51240,MARSON,,48.9268661226,4.54293784179,354,,Marson,Marson,51,Marne,44,Grand Est
51355,MASSIGES,51800,MASSIGES,,49.1936279449,4.73839071461,355,,Massiges,Massiges,51,Marne,44,Grand Est
51356,MATIGNICOURT GONCOURT,51300,MATIGNICOURT GONCOURT,,48.6750657203,4.67663431783,356,,Matignicourt-Goncourt,Matignicourt-Goncourt,51,Marne,44,Grand Est
51357,MATOUGUES,51510,MATOUGUES,,48.9835136779,4.24385043922,357,,Matougues,Matougues,51,Marne,44,Grand Est
51358,MAURUPT LE MONTOIS,51340,MAURUPT LE MONTOIS,,48.7312980227,4.856551791,358,,Maurupt-le-Montois,Maurupt-le-Montois,51,Marne,44,Grand Est
51359,MECRINGES,51210,MECRINGES,,48.8497781133,3.52541124754,359,,Mécringes,Mécringes,51,Marne,44,Grand Est
51360,LE MEIX ST EPOING,51120,LE MEIX ST EPOING,,48.69506554,3.65422262759,360,Le,Meix-Saint-Epoing,Le Meix-Saint-Epoing,51,Marne,44,Grand Est
51361,LE MEIX TIERCELIN,51320,LE MEIX TIERCELIN,,48.6401635513,4.44092566457,361,Le,Meix-Tiercelin,Le Meix-Tiercelin,51,Marne,44,Grand Est
51362,MERFY,51220,MERFY,,49.2888116759,3.95305290596,362,,Merfy,Merfy,51,Marne,44,Grand Est
51363,MERLAUT,51300,MERLAUT,,48.7615849122,4.65787310242,363,,Merlaut,Merlaut,51,Marne,44,Grand Est
51364,MERY PREMECY,51390,MERY PREMECY,,49.2293632812,3.87299237056,364,,Méry-Prémecy,Méry-Prémecy,51,Marne,44,Grand Est
51365,LES MESNEUX,51370,LES MESNEUX,,49.2208240652,3.96116226522,365,Les,Mesneux,Les Mesneux,51,Marne,44,Grand Est
51367,LE MESNIL SUR OGER,51190,LE MESNIL SUR OGER,,48.9392514319,4.01968783316,367,Le,Mesnil-sur-Oger,Le Mesnil-sur-Oger,51,Marne,44,Grand Est
51368,MINAUCOURT LE MESNIL LES HURLUS,51800,MINAUCOURT LE MESNIL LES HURLUS,,49.179177044,4.69580247586,368,,Minaucourt-le-Mesnil-lès-Hurlus,Minaucourt-le-Mesnil-lès-Hurlus,51,Marne,44,Grand Est
51369,MOEURS VERDEY,51120,MOEURS VERDEY,,48.7365194964,3.67236344176,369,,Mœurs-Verdey,Mœurs-Verdey,51,Marne,44,Grand Est
51370,MOIREMONT,51800,MOIREMONT,,49.1396346505,4.89805347592,370,,Moiremont,Moiremont,51,Marne,44,Grand Est
51371,MOIVRE,51240,MOIVRE,,48.9411760455,4.67652027531,371,,Moivre,Moivre,51,Marne,44,Grand Est
51372,MONCETZ LONGEVAS,51470,MONCETZ LONGEVAS,,48.9179609776,4.44919991298,372,,Moncetz-Longevas,Moncetz-Longevas,51,Marne,44,Grand Est
51373,MONCETZ L ABBAYE,51290,MONCETZ L ABBAYE,,48.6489508753,4.66277584236,373,,Moncetz-l'Abbaye,Moncetz-l'Abbaye,51,Marne,44,Grand Est
51374,MONDEMENT MONTGIVROUX,51120,MONDEMENT MONTGIVROUX,,48.7904305607,3.76355809317,374,,Mondement-Montgivroux,Mondement-Montgivroux,51,Marne,44,Grand Est
51375,MONTBRE,51500,MONTBRE,,49.1923765653,4.04501591283,375,,Montbré,Montbré,51,Marne,44,Grand Est
51376,MONTGENOST,51260,MONTGENOST,,48.59915533,3.5935408562,376,,Montgenost,Montgenost,51,Marne,44,Grand Est
51377,MONTEPREUX,51320,MONTEPREUX,,48.7083820761,4.12342785794,377,,Montépreux,Montépreux,51,Marne,44,Grand Est
51378,MONTHELON,51530,MONTHELON,,48.9969852992,3.93667064032,378,,Monthelon,Monthelon,51,Marne,44,Grand Est
51379,MONTIGNY SUR VESLE,51140,MONTIGNY SUR VESLE,,49.3136433995,3.80140399878,379,,Montigny-sur-Vesle,Montigny-sur-Vesle,51,Marne,44,Grand Est
51380,MONTMIRAIL,51210,MONTMIRAIL,,48.8802869076,3.56048556258,380,,Montmirail,Montmirail,51,Marne,44,Grand Est
51380,MONTMIRAIL,51210,MONTMIRAIL,MACLAUNAY,48.8802869076,3.56048556258,380,,Montmirail,Montmirail,51,Marne,44,Grand Est
51381,MONTMORT LUCY,51270,MONTMORT LUCY,,48.9358384283,3.82595963448,381,,Montmort-Lucy,Montmort-Lucy,51,Marne,44,Grand Est
51381,MONTMORT LUCY,51270,MONTMORT LUCY,LUCY,48.9358384283,3.82595963448,381,,Montmort-Lucy,Montmort-Lucy,51,Marne,44,Grand Est
51382,MONT SUR COURVILLE,51170,MONT SUR COURVILLE,,49.2560688238,3.67592575502,382,,Mont-sur-Courville,Mont-sur-Courville,51,Marne,44,Grand Est
51384,MORANGIS,51530,MORANGIS,,48.9724763219,3.89450554499,384,,Morangis,Morangis,51,Marne,44,Grand Est
51386,MORSAINS,51210,MORSAINS,,48.8014721077,3.54754115829,386,,Morsains,Morsains,51,Marne,44,Grand Est
51387,MOSLINS,51530,MOSLINS,,48.9566633894,3.90961634751,387,,Moslins,Moslins,51,Marne,44,Grand Est
51388,MOURMELON LE GRAND,51400,MOURMELON LE GRAND,,49.1326106985,4.38710569439,388,,Mourmelon-le-Grand,Mourmelon-le-Grand,51,Marne,44,Grand Est
51389,MOURMELON LE PETIT,51400,MOURMELON LE PETIT,,49.1331102957,4.30596210424,389,,Mourmelon-le-Petit,Mourmelon-le-Petit,51,Marne,44,Grand Est
51390,MOUSSY,51530,MOUSSY,,49.0150808698,3.92356301559,390,,Moussy,Moussy,51,Marne,44,Grand Est
51391,MUIZON,51140,MUIZON,,49.2724383199,3.88749263388,391,,Muizon,Muizon,51,Marne,44,Grand Est
51392,MUTIGNY,51160,MUTIGNY,,49.0757241763,4.0185508573,392,,Mutigny,Mutigny,51,Marne,44,Grand Est
51393,NANTEUIL LA FORET,51480,NANTEUIL LA FORET,,49.1277645525,3.92530056587,393,,Nanteuil-la-Forêt,Nanteuil-la-Forêt,51,Marne,44,Grand Est
51395,NESLE LA REPOSTE,51120,NESLE LA REPOSTE,,48.6321483145,3.55444537036,395,,Nesle-la-Reposte,Nesle-la-Reposte,51,Marne,44,Grand Est
51396,NESLE LE REPONS,51700,NESLE LE REPONS,,49.0444334826,3.71638188696,396,,Nesle-le-Repons,Nesle-le-Repons,51,Marne,44,Grand Est
51397,LA NEUVILLE AUX BOIS,51330,LA NEUVILLE AUX BOIS,,48.9677556904,4.89351823597,397,La,Neuville-aux-Bois,La Neuville-aux-Bois,51,Marne,44,Grand Est
51398,LA NEUVILLE AUX LARRIS,51480,LA NEUVILLE AUX LARRIS,,49.1426526319,3.83061444831,398,La,Neuville-aux-Larris,La Neuville-aux-Larris,51,Marne,44,Grand Est
51399,LA NEUVILLE AU PONT,51800,LA NEUVILLE AU PONT,,49.1302299148,4.85184152327,399,La,Neuville-au-Pont,La Neuville-au-Pont,51,Marne,44,Grand Est
51402,NEUVY,51310,NEUVY,,48.7432206736,3.51030207363,402,,Neuvy,Neuvy,51,Marne,44,Grand Est
51403,NOGENT L ABBESSE,51420,NOGENT L ABBESSE,,49.2464581849,4.16004589535,403,,Nogent-l'Abbesse,Nogent-l'Abbesse,51,Marne,44,Grand Est
51404,NOIRLIEU,51330,NOIRLIEU,,48.9404596488,4.8049133911,404,,Noirlieu,Noirlieu,51,Marne,44,Grand Est
51406,NORROIS,51300,NORROIS,,48.6718913156,4.62316728405,406,,Norrois,Norrois,51,Marne,44,Grand Est
51407,LA NOUE,51310,LA NOUE,,48.7384876549,3.62179405438,407,La,Noue,La Noue,51,Marne,44,Grand Est
51409,NUISEMENT SUR COOLE,51240,NUISEMENT SUR COOLE,,48.8717774517,4.28439545806,409,,Nuisement-sur-Coole,Nuisement-sur-Coole,51,Marne,44,Grand Est
51410,OEUILLY,51480,OEUILLY,,49.0634098763,3.79757014256,410,,Œuilly,Œuilly,51,Marne,44,Grand Est
51411,OGER,51190,OGER,,48.9553832689,4.02687417168,411,,Oger,Oger,51,Marne,44,Grand Est
51412,OGNES,51230,OGNES,,48.6955801098,3.90728218249,412,,Ognes,Ognes,51,Marne,44,Grand Est
51413,OIRY,51530,OIRY,,49.0073773523,4.03973627996,413,,Oiry,Oiry,51,Marne,44,Grand Est
51414,OLIZY,51700,OLIZY,,49.1462489704,3.75894755028,414,,Olizy,Olizy,51,Marne,44,Grand Est
51415,OMEY,51240,OMEY,,48.8508163201,4.5057348075,415,,Omey,Omey,51,Marne,44,Grand Est
51416,ORBAIS L ABBAYE,51270,ORBAIS L ABBAYE,,48.9434307489,3.69982605989,416,,Orbais-l'Abbaye,Orbais-l'Abbaye,51,Marne,44,Grand Est
51417,ORCONTE,51300,ORCONTE,,48.6675882836,4.73795505965,417,,Orconte,Orconte,51,Marne,44,Grand Est
51418,ORMES,51370,ORMES,,49.237845443,3.95766278556,418,,Ormes,Ormes,51,Marne,44,Grand Est
51419,OUTINES,51290,OUTINES,,48.5586240355,4.66870052428,419,,Outines,Outines,51,Marne,44,Grand Est
51420,OUTREPONT,51300,OUTREPONT,,48.7649161607,4.68747550141,420,,Outrepont,Outrepont,51,Marne,44,Grand Est
51421,OYES,51120,OYES,,48.8105165952,3.77122436918,421,,Oyes,Oyes,51,Marne,44,Grand Est
51422,PARGNY LES REIMS,51390,PARGNY LES REIMS,,49.2191370591,3.92180730509,422,,Pargny-lès-Reims,Pargny-lès-Reims,51,Marne,44,Grand Est
51423,PARGNY SUR SAULX,51340,PARGNY SUR SAULX,,48.7670730492,4.8547838716,423,,Pargny-sur-Saulx,Pargny-sur-Saulx,51,Marne,44,Grand Est
51424,PASSAVANT EN ARGONNE,51800,PASSAVANT EN ARGONNE,,49.0220525824,5.00182082791,424,,Passavant-en-Argonne,Passavant-en-Argonne,51,Marne,44,Grand Est
51425,PASSY GRIGNY,51700,PASSY GRIGNY,,49.1283522131,3.68110893863,425,,Passy-Grigny,Passy-Grigny,51,Marne,44,Grand Est
51426,PEAS,51120,PEAS,,48.7364016208,3.77963745983,426,,Péas,Péas,51,Marne,44,Grand Est
51428,LES PETITES LOGES,51400,LES PETITES LOGES,,49.1318286454,4.23205555858,428,Les,Petites-Loges,Les Petites-Loges,51,Marne,44,Grand Est
51429,PEVY,51140,PEVY,,49.3174771815,3.85061054201,429,,Pévy,Pévy,51,Marne,44,Grand Est
51430,PIERRE MORAINS,51130,PIERRE MORAINS,,48.8400739001,4.02053698314,430,,Pierre-Morains,Pierre-Morains,51,Marne,44,Grand Est
51431,PIERRY,51530,PIERRY,,49.0190498467,3.94930532069,431,,Pierry,Pierry,51,Marne,44,Grand Est
51432,PLEURS,51230,PLEURS,,48.6970615395,3.87091196227,432,,Pleurs,Pleurs,51,Marne,44,Grand Est
51433,PLICHANCOURT,51300,PLICHANCOURT,,48.7405847183,4.67233357502,433,,Plichancourt,Plichancourt,51,Marne,44,Grand Est
51434,PLIVOT,51150,PLIVOT,,49.0112410452,4.07664321848,434,,Plivot,Plivot,51,Marne,44,Grand Est
51435,POCANCY,51130,POCANCY,,48.9405655246,4.14658726908,435,,Pocancy,Pocancy,51,Marne,44,Grand Est
51436,POGNY,51240,POGNY,,48.867379244,4.49530006662,436,,Pogny,Pogny,51,Marne,44,Grand Est
51437,POILLY,51170,POILLY,,49.2162019985,3.81320617428,437,,Poilly,Poilly,51,Marne,44,Grand Est
51438,POIX,51460,POIX,,48.9634947037,4.63289016459,438,,Poix,Poix,51,Marne,44,Grand Est
51439,POMACLE,51110,POMACLE,,49.3365316045,4.14756415081,439,,Pomacle,Pomacle,51,Marne,44,Grand Est
51440,PONTFAVERGER MORONVILLIERS,51490,PONTFAVERGER MORONVILLIERS,,49.2817231291,4.31572659574,440,,Pontfaverger-Moronvilliers,Pontfaverger-Moronvilliers,51,Marne,44,Grand Est
51441,PONTHION,51300,PONTHION,,48.7551656451,4.71748764846,441,,Ponthion,Ponthion,51,Marne,44,Grand Est
51442,POSSESSE,51330,POSSESSE,,48.8978158277,4.8561483128,442,,Possesse,Possesse,51,Marne,44,Grand Est
51443,POTANGIS,51260,POTANGIS,,48.5850735929,3.64666442992,443,,Potangis,Potangis,51,Marne,44,Grand Est
51444,POUILLON,51220,POUILLON,,49.3134262923,3.94532083722,444,,Pouillon,Pouillon,51,Marne,44,Grand Est
51445,POURCY,51480,POURCY,,49.1497686357,3.90663695027,445,,Pourcy,Pourcy,51,Marne,44,Grand Est
51446,PRINGY,51300,PRINGY,,48.773140994,4.48202645952,446,,Pringy,Pringy,51,Marne,44,Grand Est
51447,PROSNES,51400,PROSNES,,49.2004306897,4.3048573886,447,,Prosnes,Prosnes,51,Marne,44,Grand Est
51448,PROUILLY,51140,PROUILLY,,49.2967039156,3.84656080405,448,,Prouilly,Prouilly,51,Marne,44,Grand Est
51449,PRUNAY,51360,PRUNAY,,49.2141061333,4.17483063704,449,,Prunay,Prunay,51,Marne,44,Grand Est
51450,PUISIEULX,51500,PUISIEULX,,49.2008681775,4.11546356735,450,,Puisieulx,Puisieulx,51,Marne,44,Grand Est
51451,QUEUDES,51120,QUEUDES,,48.6591522739,3.75635488966,451,,Queudes,Queudes,51,Marne,44,Grand Est
51452,RAPSECOURT,51330,RAPSECOURT,,49.0231964043,4.79125516398,452,,Rapsécourt,Rapsécourt,51,Marne,44,Grand Est
51453,RECY,51520,RECY,,48.9985904011,4.32285338175,453,,Recy,Recy,51,Marne,44,Grand Est
51454,REIMS,51100,REIMS,,49.2514906066,4.0402302322,454,,Reims,Reims,51,Marne,44,Grand Est
51454,REIMS,51100,REIMS,LA NEUVILLETTE,49.2514906066,4.0402302322,454,,Reims,Reims,51,Marne,44,Grand Est
51455,REIMS LA BRULEE,51300,REIMS LA BRULEE,,48.7216063085,4.66793528602,455,,Reims-la-Brûlée,Reims-la-Brûlée,51,Marne,44,Grand Est
51456,REMICOURT,51330,REMICOURT,,48.9479810081,4.85388891659,456,,Remicourt,Remicourt,51,Marne,44,Grand Est
51457,REUIL,51480,REUIL,,49.085169468,3.81305711475,457,,Reuil,Reuil,51,Marne,44,Grand Est
51458,REUVES,51120,REUVES,,48.7982990232,3.80208051062,458,,Reuves,Reuves,51,Marne,44,Grand Est
51459,REVEILLON,51310,REVEILLON,,48.7494077104,3.45568014336,459,,Réveillon,Réveillon,51,Marne,44,Grand Est
51460,RIEUX,51210,RIEUX,,48.8366662002,3.50580892516,460,,Rieux,Rieux,51,Marne,44,Grand Est
51461,RILLY LA MONTAGNE,51500,RILLY LA MONTAGNE,,49.1634301759,4.0458133231,461,,Rilly-la-Montagne,Rilly-la-Montagne,51,Marne,44,Grand Est
51463,LES RIVIERES HENRUEL,51300,LES RIVIERES HENRUEL,,48.6507854512,4.5419723092,463,Les,Rivières-Henruel,Les Rivières-Henruel,51,Marne,44,Grand Est
51464,ROMAIN,51140,ROMAIN,,49.3300427757,3.75689883765,464,,Romain,Romain,51,Marne,44,Grand Est
51465,ROMERY,51480,ROMERY,,49.0965097553,3.91290546283,465,,Romery,Romery,51,Marne,44,Grand Est
51466,ROMIGNY,51170,ROMIGNY,,49.1729740589,3.76865133986,466,,Romigny,Romigny,51,Marne,44,Grand Est
51468,ROSNAY,51390,ROSNAY,,49.2565896564,3.85701228085,468,,Rosnay,Rosnay,51,Marne,44,Grand Est
51469,ROUFFY,51130,ROUFFY,,48.9442844543,4.09038782139,469,,Rouffy,Rouffy,51,Marne,44,Grand Est
51470,ROUVROY RIPONT,51800,ROUVROY RIPONT,,49.2227915324,4.70463752908,470,,Rouvroy-Ripont,Rouvroy-Ripont,51,Marne,44,Grand Est
51471,SACY,51500,SACY,,49.1981336193,3.95292346407,471,,Sacy,Sacy,51,Marne,44,Grand Est
51472,ST AMAND SUR FION,51300,ST AMAND SUR FION,,48.8272157767,4.61145383959,472,,Saint-Amand-sur-Fion,Saint-Amand-sur-Fion,51,Marne,44,Grand Est
51473,ST BON,51310,ST BON,,48.6810933106,3.4758256593,473,,Saint-Bon,Saint-Bon,51,Marne,44,Grand Est
51474,ST BRICE COURCELLES,51370,ST BRICE COURCELLES,,49.2700930638,3.98965296971,474,,Saint-Brice-Courcelles,Saint-Brice-Courcelles,51,Marne,44,Grand Est
51475,ST CHERON,51290,ST CHERON,,48.6302468013,4.54637424501,475,,Saint-Chéron,Saint-Chéron,51,Marne,44,Grand Est
51476,ST ETIENNE AU TEMPLE,51460,ST ETIENNE AU TEMPLE,,49.0199669584,4.419049069,476,,Saint-Étienne-au-Temple,Saint-Étienne-au-Temple,51,Marne,44,Grand Est
51477,ST ETIENNE SUR SUIPPE,51110,ST ETIENNE SUR SUIPPE,,49.3844633848,4.10121019242,477,,Saint-Étienne-sur-Suippe,Saint-Étienne-sur-Suippe,51,Marne,44,Grand Est
51478,ST EULIEN,52100,ST EULIEN,,48.6835488012,4.87961420424,478,,Saint-Eulien,Saint-Eulien,51,Marne,44,Grand Est
51479,ST EUPHRAISE ET CLAIRIZET,51390,ST EUPHRAISE ET CLAIRIZET,,49.2126052966,3.88386348503,479,,Saint-Euphraise-et-Clairizet,Saint-Euphraise-et-Clairizet,51,Marne,44,Grand Est
51480,STE GEMME,51700,STE GEMME,,49.1449983194,3.66273959081,480,,Sainte-Gemme,Sainte-Gemme,51,Marne,44,Grand Est
51482,ST GERMAIN LA VILLE,51240,ST GERMAIN LA VILLE,,48.8918610464,4.46169066024,482,,Saint-Germain-la-Ville,Saint-Germain-la-Ville,51,Marne,44,Grand Est
51483,ST GIBRIEN,51510,ST GIBRIEN,,48.9746985932,4.29499737261,483,,Saint-Gibrien,Saint-Gibrien,51,Marne,44,Grand Est
51484,ST GILLES,51170,ST GILLES,,49.2815444611,3.6769576626,484,,Saint-Gilles,Saint-Gilles,51,Marne,44,Grand Est
51485,ST HILAIRE AU TEMPLE,51400,ST HILAIRE AU TEMPLE,,49.0576981187,4.37473957395,485,,Saint-Hilaire-au-Temple,Saint-Hilaire-au-Temple,51,Marne,44,Grand Est
51486,ST HILAIRE LE GRAND,51600,ST HILAIRE LE GRAND,,49.1716852099,4.44934319127,486,,Saint-Hilaire-le-Grand,Saint-Hilaire-le-Grand,51,Marne,44,Grand Est
51487,ST HILAIRE LE PETIT,51490,ST HILAIRE LE PETIT,,49.2651675739,4.38191112577,487,,Saint-Hilaire-le-Petit,Saint-Hilaire-le-Petit,51,Marne,44,Grand Est
51488,ST IMOGES,51160,ST IMOGES,,49.1069746679,3.98465817198,488,,Saint-Imoges,Saint-Imoges,51,Marne,44,Grand Est
51489,ST JEAN DEVANT POSSESSE,51330,ST JEAN DEVANT POSSESSE,,48.8700176429,4.78622248501,489,,Saint-Jean-devant-Possesse,Saint-Jean-devant-Possesse,51,Marne,44,Grand Est
51490,ST JEAN SUR MOIVRE,51240,ST JEAN SUR MOIVRE,,48.8971001167,4.58461376769,490,,Saint-Jean-sur-Moivre,Saint-Jean-sur-Moivre,51,Marne,44,Grand Est
51491,ST JEAN SUR TOURBE,51600,ST JEAN SUR TOURBE,,49.1233394938,4.67214565601,491,,Saint-Jean-sur-Tourbe,Saint-Jean-sur-Tourbe,51,Marne,44,Grand Est
51492,ST JUST SAUVAGE,51260,ST JUST SAUVAGE,,48.5482578678,3.77201547275,492,,Saint-Just-Sauvage,Saint-Just-Sauvage,51,Marne,44,Grand Est
51493,ST LEONARD,51500,ST LEONARD,,49.2258255377,4.10765248658,493,,Saint-Léonard,Saint-Léonard,51,Marne,44,Grand Est
51495,ST LOUP,51120,ST LOUP,,48.7353584914,3.82110281161,495,,Saint-Loup,Saint-Loup,51,Marne,44,Grand Est
51496,ST LUMIER EN CHAMPAGNE,51300,ST LUMIER EN CHAMPAGNE,,48.7922000417,4.62005564865,496,,Saint-Lumier-en-Champagne,Saint-Lumier-en-Champagne,51,Marne,44,Grand Est
51497,ST LUMIER LA POPULEUSE,51340,ST LUMIER LA POPULEUSE,,48.7285501546,4.80515575755,497,,Saint-Lumier-la-Populeuse,Saint-Lumier-la-Populeuse,51,Marne,44,Grand Est
51498,ST MARD SUR AUVE,51800,ST MARD SUR AUVE,,49.0293500265,4.72778139432,498,,Saint-Mard-sur-Auve,Saint-Mard-sur-Auve,51,Marne,44,Grand Est
51499,ST MARD LES ROUFFY,51130,ST MARD LES ROUFFY,,48.9537045244,4.10363300278,499,,Saint-Mard-lès-Rouffy,Saint-Mard-lès-Rouffy,51,Marne,44,Grand Est
51500,ST MARD SUR LE MONT,51330,ST MARD SUR LE MONT,,48.9236124793,4.85276237974,500,,Saint-Mard-sur-le-Mont,Saint-Mard-sur-le-Mont,51,Marne,44,Grand Est
51501,STE MARIE A PY,51600,STE MARIE A PY,,49.2393806185,4.50756056596,501,,Sainte-Marie-à-Py,Sainte-Marie-à-Py,51,Marne,44,Grand Est
51502,ST MARTIN AUX CHAMPS,51240,ST MARTIN AUX CHAMPS,,48.8146669912,4.47971521555,502,,Saint-Martin-aux-Champs,Saint-Martin-aux-Champs,51,Marne,44,Grand Est
51503,ST MARTIN L HEUREUX,51490,ST MARTIN L HEUREUX,,49.2434551487,4.376331789,503,,Saint-Martin-l'Heureux,Saint-Martin-l'Heureux,51,Marne,44,Grand Est
51504,ST MARTIN SUR LE PRE,51520,ST MARTIN SUR LE PRE,,48.9918819484,4.35747987108,504,,Saint-Martin-sur-le-Pré,Saint-Martin-sur-le-Pré,51,Marne,44,Grand Est
51505,ST MASMES,51490,ST MASMES,,49.3112137886,4.24950510453,505,,Saint-Masmes,Saint-Masmes,51,Marne,44,Grand Est
51506,ST MEMMIE,51470,ST MEMMIE,,48.9545604627,4.41789406119,506,,Saint-Memmie,Saint-Memmie,51,Marne,44,Grand Est
51507,STE MENEHOULD,51800,STE MENEHOULD,,49.0865774658,4.94259439229,507,,Sainte-Menehould,Sainte-Menehould,51,Marne,44,Grand Est
51508,ST OUEN DOMPROT,51320,ST OUEN DOMPROT,,48.6138855668,4.39297037267,508,,Saint-Ouen-Domprot,Saint-Ouen-Domprot,51,Marne,44,Grand Est
51509,ST PIERRE,51510,ST PIERRE,,48.9405334413,4.24555140178,509,,Saint-Pierre,Saint-Pierre,51,Marne,44,Grand Est
51510,ST QUENTIN LES MARAIS,51300,ST QUENTIN LES MARAIS,,48.7768556316,4.63146682582,510,,Saint-Quentin-les-Marais,Saint-Quentin-les-Marais,51,Marne,44,Grand Est
51511,ST QUENTIN LE VERGER,51120,ST QUENTIN LE VERGER,,48.6171468398,3.74389575573,511,,Saint-Quentin-le-Verger,Saint-Quentin-le-Verger,51,Marne,44,Grand Est
51512,ST QUENTIN SUR COOLE,51240,ST QUENTIN SUR COOLE,,48.8504241158,4.32661804133,512,,Saint-Quentin-sur-Coole,Saint-Quentin-sur-Coole,51,Marne,44,Grand Est
51513,ST REMY EN BOUZEMONT ST GENEST ISSON,51290,ST REMY EN BOUZEMONT ST GENEST,,48.6129201202,4.6490004853,513,,Saint-Remy-en-Bouzemont-Saint-Genest-et-Isson,Saint-Remy-en-Bouzemont-Saint-Genest-et-Isson,51,Marne,44,Grand Est
51514,ST REMY SOUS BROYES,51120,ST REMY SOUS BROYES,,48.7070186782,3.77379053294,514,,Saint-Remy-sous-Broyes,Saint-Remy-sous-Broyes,51,Marne,44,Grand Est
51515,ST REMY SUR BUSSY,51600,ST REMY SUR BUSSY,,49.060519815,4.58774935231,515,,Saint-Remy-sur-Bussy,Saint-Remy-sur-Bussy,51,Marne,44,Grand Est
51516,ST SATURNIN,51260,ST SATURNIN,,48.6167902832,3.89132444903,516,,Saint-Saturnin,Saint-Saturnin,51,Marne,44,Grand Est
51517,ST SOUPLET SUR PY,51600,ST SOUPLET SUR PY,,49.2450297704,4.46480167767,517,,Saint-Souplet-sur-Py,Saint-Souplet-sur-Py,51,Marne,44,Grand Est
51518,ST THIERRY,51220,ST THIERRY,,49.297621537,3.97532406163,518,,Saint-Thierry,Saint-Thierry,51,Marne,44,Grand Est
51519,ST THOMAS EN ARGONNE,51800,ST THOMAS EN ARGONNE,,49.1912114565,4.86655912863,519,,Saint-Thomas-en-Argonne,Saint-Thomas-en-Argonne,51,Marne,44,Grand Est
51520,ST UTIN,51290,ST UTIN,,48.5535485189,4.47470257392,520,,Saint-Utin,Saint-Utin,51,Marne,44,Grand Est
51521,ST VRAIN,51340,ST VRAIN,,48.6965592836,4.82187411211,521,,Saint-Vrain,Saint-Vrain,51,Marne,44,Grand Est
51522,SAPIGNICOURT,52100,SAPIGNICOURT,,48.6473946256,4.81229033603,522,,Sapignicourt,Sapignicourt,51,Marne,44,Grand Est
51523,SARCY,51170,SARCY,,49.202533594,3.83019199367,523,,Sarcy,Sarcy,51,Marne,44,Grand Est
51524,SARON SUR AUBE,51260,SARON SUR AUBE,,48.5824010998,3.72970498559,524,,Saron-sur-Aube,Saron-sur-Aube,51,Marne,44,Grand Est
51525,SARRY,51520,SARRY,,48.9268317961,4.41488135448,525,,Sarry,Sarry,51,Marne,44,Grand Est
51526,SAUDOY,51120,SAUDOY,,48.6819330141,3.69807910524,526,,Saudoy,Saudoy,51,Marne,44,Grand Est
51527,SAVIGNY SUR ARDRES,51170,SAVIGNY SUR ARDRES,,49.2533040649,3.79359840137,527,,Savigny-sur-Ardres,Savigny-sur-Ardres,51,Marne,44,Grand Est
51528,SCRUPT,51340,SCRUPT,,48.7158651738,4.80888603123,528,,Scrupt,Scrupt,51,Marne,44,Grand Est
51529,SELLES,51490,SELLES,,49.2969104797,4.2820477749,529,,Selles,Selles,51,Marne,44,Grand Est
51530,SEPT SAULX,51400,SEPT SAULX,,49.1492543394,4.26408117653,530,,Sept-Saulx,Sept-Saulx,51,Marne,44,Grand Est
51531,SERMAIZE LES BAINS,51250,SERMAIZE LES BAINS,,48.7776497443,4.90737969309,531,,Sermaize-les-Bains,Sermaize-les-Bains,51,Marne,44,Grand Est
51532,SERMIERS,51500,SERMIERS,,49.1488670974,3.97937404873,532,,Sermiers,Sermiers,51,Marne,44,Grand Est
51533,SERVON MELZICOURT,51800,SERVON MELZICOURT,,49.2081540683,4.84616301412,533,,Servon-Melzicourt,Servon-Melzicourt,51,Marne,44,Grand Est
51534,SERZY ET PRIN,51170,SERZY ET PRIN,,49.248503127,3.76445343326,534,,Serzy-et-Prin,Serzy-et-Prin,51,Marne,44,Grand Est
51535,SEZANNE,51120,SEZANNE,,48.7238850993,3.72347511643,535,,Sézanne,Sézanne,51,Marne,44,Grand Est
51536,SILLERY,51500,SILLERY,,49.1921396501,4.13941524041,536,,Sillery,Sillery,51,Marne,44,Grand Est
51537,SIVRY ANTE,51800,SIVRY ANTE,,49.0014070263,4.89235907358,537,,Sivry-Ante,Sivry-Ante,51,Marne,44,Grand Est
51538,SOGNY AUX MOULINS,51520,SOGNY AUX MOULINS,,48.8957853925,4.39011051994,538,,Sogny-aux-Moulins,Sogny-aux-Moulins,51,Marne,44,Grand Est
51539,SOGNY EN L ANGLE,51340,SOGNY EN L ANGLE,,48.816895594,4.80749859782,539,,Sogny-en-l'Angle,Sogny-en-l'Angle,51,Marne,44,Grand Est
51542,SOIZY AUX BOIS,51120,SOIZY AUX BOIS,,48.8095986689,3.72934364469,542,,Soizy-aux-Bois,Soizy-aux-Bois,51,Marne,44,Grand Est
51543,SOMME BIONNE,51800,SOMME BIONNE,,49.0883022983,4.71735582412,543,,Somme-Bionne,Somme-Bionne,51,Marne,44,Grand Est
51544,SOMMEPY TAHURE,51600,SOMMEPY TAHURE,,49.2432976782,4.59239324913,544,,Sommepy-Tahure,Sommepy-Tahure,51,Marne,44,Grand Est
51545,SOMMESOUS,51320,SOMMESOUS,,48.7345322993,4.21304590146,545,,Sommesous,Sommesous,51,Marne,44,Grand Est
51546,SOMME SUIPPE,51600,SOMME SUIPPE,,49.1187938082,4.58881261321,546,,Somme-Suippe,Somme-Suippe,51,Marne,44,Grand Est
51547,SOMME TOURBE,51600,SOMME TOURBE,,49.0974715708,4.66026066798,547,,Somme-Tourbe,Somme-Tourbe,51,Marne,44,Grand Est
51548,SOMME VESLE,51460,SOMME VESLE,,48.992390138,4.61434632836,548,,Somme-Vesle,Somme-Vesle,51,Marne,44,Grand Est
51549,SOMME YEVRE,51330,SOMME YEVRE,,48.9445152692,4.74104866802,549,,Somme-Yèvre,Somme-Yèvre,51,Marne,44,Grand Est
51550,SOMPUIS,51320,SOMPUIS,,48.6855555733,4.37611550868,550,,Sompuis,Sompuis,51,Marne,44,Grand Est
51551,SOMSOIS,51290,SOMSOIS,,48.6060501552,4.49698542051,551,,Somsois,Somsois,51,Marne,44,Grand Est
51552,SONGY,51240,SONGY,,48.7928814429,4.4717457251,552,,Songy,Songy,51,Marne,44,Grand Est
51553,SOUAIN PERTHES LES HURLUS,51600,SOUAIN PERTHES LES HURLUS,,49.1870517871,4.57391390732,553,,Souain-Perthes-lès-Hurlus,Souain-Perthes-lès-Hurlus,51,Marne,44,Grand Est
51555,SOUDE,51320,SOUDE,,48.7289755504,4.30528409518,555,,Soudé,Soudé,51,Marne,44,Grand Est
51556,SOUDRON,51320,SOUDRON,,48.8328002637,4.19908676708,556,,Soudron,Soudron,51,Marne,44,Grand Est
51557,SOULANGES,51300,SOULANGES,,48.7883824232,4.55512796769,557,,Soulanges,Soulanges,51,Marne,44,Grand Est
51558,SOULIERES,51130,SOULIERES,,48.9038402772,3.93440126624,558,,Soulières,Soulières,51,Marne,44,Grand Est
51559,SUIPPES,51600,SUIPPES,,49.125439853,4.52591673692,559,,Suippes,Suippes,51,Marne,44,Grand Est
51560,SUIZY LE FRANC,51270,SUIZY LE FRANC,,48.9538152949,3.72996910022,560,,Suizy-le-Franc,Suizy-le-Franc,51,Marne,44,Grand Est
51562,TAISSY,51500,TAISSY,,49.2040224576,4.08149844423,562,,Taissy,Taissy,51,Marne,44,Grand Est
51563,TALUS ST PRIX,51270,TALUS ST PRIX,,48.8322484487,3.74333157283,563,,Talus-Saint-Prix,Talus-Saint-Prix,51,Marne,44,Grand Est
51564,VAL DE LIVRE,51150,VAL DE LIVRE,LOUVOIS,49.0904462327,4.09591940521,564,,Val de Livre,Val de Livre,51,Marne,44,Grand Est
51564,VAL DE LIVRE,51150,VAL DE LIVRE,TAUXIERES MUTRY,49.0904462327,4.09591940521,564,,Val de Livre,Val de Livre,51,Marne,44,Grand Est
51565,THAAS,51230,THAAS,,48.6389793802,3.87152616864,565,,Thaas,Thaas,51,Marne,44,Grand Est
51566,THIBIE,51510,THIBIE,,48.9177321416,4.21025087752,566,,Thibie,Thibie,51,Marne,44,Grand Est
51567,THIEBLEMONT FAREMONT,51300,THIEBLEMONT FAREMONT,,48.6926709039,4.73027706187,567,,Thiéblemont-Farémont,Thiéblemont-Farémont,51,Marne,44,Grand Est
51568,THIL,51220,THIL,,49.3181688565,3.96508523039,568,,Thil,Thil,51,Marne,44,Grand Est
51569,THILLOIS,51370,THILLOIS,,49.2589058142,3.94531513362,569,,Thillois,Thillois,51,Marne,44,Grand Est
51570,LE THOULT TROSNAY,51210,LE THOULT TROSNAY,,48.8625433145,3.68421131138,570,Le,Thoult-Trosnay,Le Thoult-Trosnay,51,Marne,44,Grand Est
51571,VAL DE VESLE,51360,VAL DE VESLE,,49.1844475204,4.22804448988,571,,Val-de-Vesle,Val-de-Vesle,51,Marne,44,Grand Est
51572,TILLOY ET BELLAY,51460,TILLOY ET BELLAY,,49.0293633724,4.63078861218,572,,Tilloy-et-Bellay,Tilloy-et-Bellay,51,Marne,44,Grand Est
51573,TINQUEUX,51430,TINQUEUX,,49.2496666793,3.98795391117,573,,Tinqueux,Tinqueux,51,Marne,44,Grand Est
51574,TOGNY AUX BOEUFS,51240,TOGNY AUX BOEUFS,,48.8465790978,4.42100509871,574,,Togny-aux-Bœufs,Togny-aux-Bœufs,51,Marne,44,Grand Est
51576,TOURS SUR MARNE,51150,TOURS SUR MARNE,,49.0525282676,4.12927337739,576,,Tours-sur-Marne,Tours-sur-Marne,51,Marne,44,Grand Est
51577,TRAMERY,51170,TRAMERY,,49.2226489325,3.80058910738,577,,Tramery,Tramery,51,Marne,44,Grand Est
51578,TRECON,51130,TRECON,,48.8665837423,4.08841746273,578,,Trécon,Trécon,51,Marne,44,Grand Est
51579,TREFOLS,51210,TREFOLS,,48.7954481021,3.49891700621,579,,Tréfols,Tréfols,51,Marne,44,Grand Est
51580,TREPAIL,51380,TREPAIL,,49.1092248705,4.18205770202,580,,Trépail,Trépail,51,Marne,44,Grand Est
51581,TRESLON,51140,TRESLON,,49.2417966857,3.82706094707,581,,Treslon,Treslon,51,Marne,44,Grand Est
51582,TRIGNY,51140,TRIGNY,,49.2975971087,3.89004113473,582,,Trigny,Trigny,51,Marne,44,Grand Est
51583,TROIS FONTAINES L ABBAYE,51340,TROIS FONTAINES L ABBAYE,,48.7106542315,4.9541795358,583,,Trois-Fontaines-l'Abbaye,Trois-Fontaines-l'Abbaye,51,Marne,44,Grand Est
51584,TROIS PUITS,51500,TROIS PUITS,,49.2056733316,4.03927957709,584,,Trois-Puits,Trois-Puits,51,Marne,44,Grand Est
51585,TROISSY,51700,TROISSY,,49.0725724682,3.70218911309,585,,Troissy,Troissy,51,Marne,44,Grand Est
51586,UNCHAIR,51170,UNCHAIR,,49.2870911732,3.74648367671,586,,Unchair,Unchair,51,Marne,44,Grand Est
51587,VADENAY,51400,VADENAY,,49.0836066326,4.4164981077,587,,Vadenay,Vadenay,51,Marne,44,Grand Est
51588,VALMY,51800,VALMY,,49.0777158902,4.76263259887,588,,Valmy,Valmy,51,Marne,44,Grand Est
51589,VANAULT LE CHATEL,51330,VANAULT LE CHATEL,,48.8657919705,4.70484289994,589,,Vanault-le-Châtel,Vanault-le-Châtel,51,Marne,44,Grand Est
51590,VANAULT LES DAMES,51340,VANAULT LES DAMES,,48.8424185091,4.7832872554,590,,Vanault-les-Dames,Vanault-les-Dames,51,Marne,44,Grand Est
51591,VANDEUIL,51140,VANDEUIL,,49.2821394936,3.79415062209,591,,Vandeuil,Vandeuil,51,Marne,44,Grand Est
51592,VANDIERES,51700,VANDIERES,,49.113805512,3.72500319376,592,,Vandières,Vandières,51,Marne,44,Grand Est
51594,VASSIMONT ET CHAPELAINE,51320,VASSIMONT ET CHAPELAINE,,48.7597343436,4.13985568358,594,,Vassimont-et-Chapelaine,Vassimont-et-Chapelaine,51,Marne,44,Grand Est
51595,VATRY,51320,VATRY,,48.8237179226,4.24189842242,595,,Vatry,Vatry,51,Marne,44,Grand Est
51596,VAUCHAMPS,51210,VAUCHAMPS,,48.8809936566,3.62624789917,596,,Vauchamps,Vauchamps,51,Marne,44,Grand Est
51597,VAUCIENNES,51480,VAUCIENNES,,49.0514138331,3.88062809942,597,,Vauciennes,Vauciennes,51,Marne,44,Grand Est
51598,VAUCLERC,51300,VAUCLERC,,48.7072494854,4.67364422061,598,,Vauclerc,Vauclerc,51,Marne,44,Grand Est
51599,VAUDEMANGE,51380,VAUDEMANGE,,49.0878246076,4.24420301293,599,,Vaudemange,Vaudemange,51,Marne,44,Grand Est
51600,VAUDESINCOURT,51600,VAUDESINCOURT,,49.217325695,4.38256185386,600,,Vaudesincourt,Vaudesincourt,51,Marne,44,Grand Est
51601,VAVRAY LE GRAND,51300,VAVRAY LE GRAND,,48.7943355548,4.70542609943,601,,Vavray-le-Grand,Vavray-le-Grand,51,Marne,44,Grand Est
51602,VAVRAY LE PETIT,51300,VAVRAY LE PETIT,,48.8075522088,4.7160526865,602,,Vavray-le-Petit,Vavray-le-Petit,51,Marne,44,Grand Est
51603,VELYE,51130,VELYE,,48.8921054834,4.13831644909,603,,Vélye,Vélye,51,Marne,44,Grand Est
51604,VENTELAY,51140,VENTELAY,,49.3416417063,3.79943740088,604,,Ventelay,Ventelay,51,Marne,44,Grand Est
51605,VENTEUIL,51480,VENTEUIL,,49.08876131,3.84088360221,605,,Venteuil,Venteuil,51,Marne,44,Grand Est
51607,VERDON,51210,VERDON,,48.9498449588,3.62063247963,607,,Verdon,Verdon,51,Marne,44,Grand Est
51608,VERNANCOURT,51330,VERNANCOURT,,48.8516215288,4.83179693678,608,,Vernancourt,Vernancourt,51,Marne,44,Grand Est
51609,VERNEUIL,51700,VERNEUIL,,49.1023191077,3.67124633089,609,,Verneuil,Verneuil,51,Marne,44,Grand Est
51610,VERRIERES,51800,VERRIERES,,49.061277732,4.90108677036,610,,Verrières,Verrières,51,Marne,44,Grand Est
51611,VERT TOULON,51130,VERT TOULON,,48.8477595772,3.90655337825,611,,Vert-Toulon,Vert-Toulon,51,Marne,44,Grand Est
51611,VERT TOULON,51130,VERT TOULON,TOULON LA MONTAGNE,48.8477595772,3.90655337825,611,,Vert-Toulon,Vert-Toulon,51,Marne,44,Grand Est
51612,VERTUS,51130,VERTUS,,48.9081794912,3.97961201086,612,,Blancs-Coteaux,Blancs-Coteaux,51,Marne,44,Grand Est
51613,VERZENAY,51360,VERZENAY,,49.1640933006,4.14305005276,613,,Verzenay,Verzenay,51,Marne,44,Grand Est
51614,VERZY,51380,VERZY,,49.1438617468,4.16066393277,614,,Verzy,Verzy,51,Marne,44,Grand Est
51616,VESIGNEUL SUR MARNE,51240,VESIGNEUL SUR MARNE,,48.8766478014,4.46821129445,616,,Vésigneul-sur-Marne,Vésigneul-sur-Marne,51,Marne,44,Grand Est
51617,LA VEUVE,51520,LA VEUVE,,49.0370630593,4.33427822759,617,La,Veuve,La Veuve,51,Marne,44,Grand Est
51618,LE VEZIER,51210,LE VEZIER,,48.803348161,3.45232948902,618,Le,Vézier,Le Vézier,51,Marne,44,Grand Est
51619,LE VIEIL DAMPIERRE,51330,LE VIEIL DAMPIERRE,,48.9830505219,4.92494474307,619,Le,Vieil-Dampierre,Le Vieil-Dampierre,51,Marne,44,Grand Est
51620,VIENNE LA VILLE,51800,VIENNE LA VILLE,,49.1635761231,4.85405487577,620,,Vienne-la-Ville,Vienne-la-Ville,51,Marne,44,Grand Est
51621,VIENNE LE CHATEAU,51800,VIENNE LE CHATEAU,,49.1962706351,4.92488983667,621,,Vienne-le-Château,Vienne-le-Château,51,Marne,44,Grand Est
51622,VILLE DOMMANGE,51390,VILLE DOMMANGE,,49.2048611738,3.93766033813,622,,Ville-Dommange,Ville-Dommange,51,Marne,44,Grand Est
51623,VILLE EN SELVE,51500,VILLE EN SELVE,,49.1225957296,4.0791891129,623,,Ville-en-Selve,Ville-en-Selve,51,Marne,44,Grand Est
51624,VILLE EN TARDENOIS,51170,VILLE EN TARDENOIS,,49.1783150735,3.79797345455,624,,Ville-en-Tardenois,Ville-en-Tardenois,51,Marne,44,Grand Est
51625,VILLENEUVE LA LIONNE,51310,VILLENEUVE LA LIONNE,,48.7700598396,3.43629160696,625,,Villeneuve-la-Lionne,Villeneuve-la-Lionne,51,Marne,44,Grand Est
51626,LA VILLENEUVE LES CHARLEVILLE,51120,LA VILLENEUVE LES CHARLEVILLE,,48.7972274315,3.70878628564,626,La,Villeneuve-lès-Charleville,La Villeneuve-lès-Charleville,51,Marne,44,Grand Est
51627,VILLENEUVE RENNEVILLE CHEVIGNY,51130,VILLENEUVE RENNEVILLE CHEVIGNY,,48.918550927,4.06212540381,627,,Villeneuve-Renneville-Chevigny,Villeneuve-Renneville-Chevigny,51,Marne,44,Grand Est
51628,VILLENEUVE ST VISTRE ET VILLEVOTTE,51120,VILLENEUVE ST VISTRE VILLEVOTTE,,48.6375394757,3.76140601624,628,,Villeneuve-Saint-Vistre-et-Villevotte,Villeneuve-Saint-Vistre-et-Villevotte,51,Marne,44,Grand Est
51629,VILLERS ALLERAND,51500,VILLERS ALLERAND,,49.1628290117,4.01849032746,629,,Villers-Allerand,Villers-Allerand,51,Marne,44,Grand Est
51630,VILLERS AUX BOIS,51130,VILLERS AUX BOIS,,48.9348611937,3.93035066412,630,,Villers-aux-Bois,Villers-aux-Bois,51,Marne,44,Grand Est
51631,VILLERS AUX NOEUDS,51500,VILLERS AUX NOEUDS,,49.1914885586,3.99330476767,631,,Villers-aux-Nœuds,Villers-aux-Nœuds,51,Marne,44,Grand Est
51632,VILLERS EN ARGONNE,51800,VILLERS EN ARGONNE,,49.0180251843,4.93994624635,632,,Villers-en-Argonne,Villers-en-Argonne,51,Marne,44,Grand Est
51633,VILLERS FRANQUEUX,51220,VILLERS FRANQUEUX,,49.3296687157,3.95052532169,633,,Villers-Franqueux,Villers-Franqueux,51,Marne,44,Grand Est
51634,VILLERS LE CHATEAU,51510,VILLERS LE CHATEAU,,48.9386044105,4.26341111871,634,,Villers-le-Château,Villers-le-Château,51,Marne,44,Grand Est
51635,VILLERS LE SEC,51250,VILLERS LE SEC,,48.8197755055,4.85545727922,635,,Villers-le-Sec,Villers-le-Sec,51,Marne,44,Grand Est
51636,VILLERS MARMERY,51380,VILLERS MARMERY,,49.1302437275,4.19857748314,636,,Villers-Marmery,Villers-Marmery,51,Marne,44,Grand Est
51637,VILLERS SOUS CHATILLON,51700,VILLERS SOUS CHATILLON,,49.1032051362,3.81701238416,637,,Villers-sous-Châtillon,Villers-sous-Châtillon,51,Marne,44,Grand Est
51638,VILLESENEUX,51130,VILLESENEUX,,48.8442040929,4.14375966973,638,,Villeseneux,Villeseneux,51,Marne,44,Grand Est
51639,LA VILLE SOUS ORBAIS,51270,LA VILLE SOUS ORBAIS,,48.9652642255,3.67706951414,639,La,Ville-sous-Orbais,La Ville-sous-Orbais,51,Marne,44,Grand Est
51640,VILLE SUR TOURBE,51800,VILLE SUR TOURBE,,49.1979765872,4.78591314557,640,,Ville-sur-Tourbe,Ville-sur-Tourbe,51,Marne,44,Grand Est
51641,VILLEVENARD,51270,VILLEVENARD,,48.8323336586,3.79579692713,641,,Villevenard,Villevenard,51,Marne,44,Grand Est
51642,VILLIERS AUX CORNEILLES,51260,VILLIERS AUX CORNEILLES,,48.574728909,3.67678401066,642,,Villiers-aux-Corneilles,Villiers-aux-Corneilles,51,Marne,44,Grand Est
51643,VINAY,51530,VINAY,,49.0129643499,3.89876523906,643,,Vinay,Vinay,51,Marne,44,Grand Est
51644,VINCELLES,51700,VINCELLES,,49.095711115,3.63873649841,644,,Vincelles,Vincelles,51,Marne,44,Grand Est
51645,VINDEY,51120,VINDEY,,48.6994506096,3.70913696706,645,,Vindey,Vindey,51,Marne,44,Grand Est
51646,VIRGINY,51800,VIRGINY,,49.1672610952,4.7587997797,646,,Virginy,Virginy,51,Marne,44,Grand Est
51647,VITRY EN PERTHOIS,51300,VITRY EN PERTHOIS,,48.7482473925,4.61966323951,647,,Vitry-en-Perthois,Vitry-en-Perthois,51,Marne,44,Grand Est
51648,VITRY LA VILLE,51240,VITRY LA VILLE,,48.8347533525,4.43279915024,648,,Vitry-la-Ville,Vitry-la-Ville,51,Marne,44,Grand Est
51648,VITRY LA VILLE,51240,VITRY LA VILLE,VOUCIENNES,48.8347533525,4.43279915024,648,,Vitry-la-Ville,Vitry-la-Ville,51,Marne,44,Grand Est
51649,VITRY LE FRANCOIS,51300,VITRY LE FRANCOIS,,48.728201078,4.59222371874,649,,Vitry-le-François,Vitry-le-François,51,Marne,44,Grand Est
51650,VOILEMONT,51800,VOILEMONT,,49.0428848152,4.8010705026,650,,Voilemont,Voilemont,51,Marne,44,Grand Est
51651,VOIPREUX,51130,VOIPREUX,,48.8983197218,4.04742659851,651,,Voipreux,Voipreux,51,Marne,44,Grand Est
51652,VOUARCES,51260,VOUARCES,,48.5930940812,3.89089602611,652,,Vouarces,Vouarces,51,Marne,44,Grand Est
51654,VOUILLERS,51340,VOUILLERS,,48.6857312327,4.8445322469,654,,Vouillers,Vouillers,51,Marne,44,Grand Est
51655,VOUZY,51130,VOUZY,,48.9170302973,4.10778108018,655,,Vouzy,Vouzy,51,Marne,44,Grand Est
51656,VRAUX,51150,VRAUX,,49.0340178671,4.24525136577,656,,Vraux,Vraux,51,Marne,44,Grand Est
51657,VRIGNY,51390,VRIGNY,,49.2382231686,3.91662114924,657,,Vrigny,Vrigny,51,Marne,44,Grand Est
51658,VROIL,51330,VROIL,,48.8502978121,4.91023351451,658,,Vroil,Vroil,51,Marne,44,Grand Est
51659,WARGEMOULIN HURLUS,51800,WARGEMOULIN HURLUS,,49.1602195185,4.66277412786,659,,Wargemoulin-Hurlus,Wargemoulin-Hurlus,51,Marne,44,Grand Est
51660,WARMERIVILLE,51110,WARMERIVILLE,,49.3487467598,4.23822416025,660,,Warmeriville,Warmeriville,51,Marne,44,Grand Est
51662,WITRY LES REIMS,51420,WITRY LES REIMS,,49.2972389215,4.11178756183,662,,Witry-lès-Reims,Witry-lès-Reims,51,Marne,44,Grand Est
51663,MAGENTA,51530,MAGENTA,,49.0556579578,3.96637548356,663,,Magenta,Magenta,51,Marne,44,Grand Est
52001,AGEVILLE,52340,AGEVILLE,,48.1124478437,5.3380981717,1,,Ageville,Ageville,52,Haute-Marne,44,Grand Est
52002,AIGREMONT,52400,AIGREMONT,,48.0125748541,5.72587981805,2,,Aigremont,Aigremont,52,Haute-Marne,44,Grand Est
52003,AILLIANVILLE,52700,AILLIANVILLE,,48.3333027636,5.48294419471,3,,Aillianville,Aillianville,52,Haute-Marne,44,Grand Est
52004,AINGOULAINCOURT,52230,AINGOULAINCOURT,,48.4562339315,5.27698051622,4,,Aingoulaincourt,Aingoulaincourt,52,Haute-Marne,44,Grand Est
52005,AIZANVILLE,52120,AIZANVILLE,,48.1116130217,4.89456102722,5,,Aizanville,Aizanville,52,Haute-Marne,44,Grand Est
52006,ALLICHAMPS,52130,ALLICHAMPS,,48.5636922014,4.88629237794,6,,Allichamps,Allichamps,52,Haute-Marne,44,Grand Est
52007,AMBONVILLE,52110,AMBONVILLE,,48.3139710416,5.01062838373,7,,Ambonville,Ambonville,52,Haute-Marne,44,Grand Est
52008,ANDELOT BLANCHEVILLE,52700,ANDELOT BLANCHEVILLE,,48.2358357276,5.28592728312,8,,Andelot-Blancheville,Andelot-Blancheville,52,Haute-Marne,44,Grand Est
52008,ANDELOT BLANCHEVILLE,52700,ANDELOT BLANCHEVILLE,BLANCHEVILLE,48.2358357276,5.28592728312,8,,Andelot-Blancheville,Andelot-Blancheville,52,Haute-Marne,44,Grand Est
52009,ANDILLY EN BASSIGNY,52360,ANDILLY EN BASSIGNY,,47.9283690025,5.52049253052,9,,Andilly-en-Bassigny,Andilly-en-Bassigny,52,Haute-Marne,44,Grand Est
52011,ANNEVILLE LA PRAIRIE,52310,ANNEVILLE LA PRAIRIE,,48.2007257864,5.08748613699,11,,Annéville-la-Prairie,Annéville-la-Prairie,52,Haute-Marne,44,Grand Est
52012,ANNONVILLE,52230,ANNONVILLE,,48.386421082,5.26980637959,12,,Annonville,Annonville,52,Haute-Marne,44,Grand Est
52013,ANROSEY,52500,ANROSEY,,47.8449228993,5.66083228787,13,,Anrosey,Anrosey,52,Haute-Marne,44,Grand Est
52014,APREY,52250,APREY,,47.7713625885,5.1988766167,14,,Aprey,Aprey,52,Haute-Marne,44,Grand Est
52015,ARBIGNY SOUS VARENNES,52500,ARBIGNY SOUS VARENNES,,47.860574645,5.610752246,15,,Arbigny-sous-Varennes,Arbigny-sous-Varennes,52,Haute-Marne,44,Grand Est
52016,ARBOT,52160,ARBOT,,47.847249934,5.01730732864,16,,Arbot,Arbot,52,Haute-Marne,44,Grand Est
52017,ARC EN BARROIS,52210,ARC EN BARROIS,,47.947611382,5.02233277969,17,,Arc-en-Barrois,Arc-en-Barrois,52,Haute-Marne,44,Grand Est
52019,ARNANCOURT,52110,ARNANCOURT,,48.3492116853,4.91356584892,19,,Arnancourt,Arnancourt,52,Haute-Marne,44,Grand Est
52021,ATTANCOURT,52130,ATTANCOURT,,48.5345882518,4.94426693506,21,,Attancourt,Attancourt,52,Haute-Marne,44,Grand Est
52022,AUBEPIERRE SUR AUBE,52210,AUBEPIERRE SUR AUBE,,47.9097995655,4.95005798566,22,,Aubepierre-sur-Aube,Aubepierre-sur-Aube,52,Haute-Marne,44,Grand Est
52023,AUBERIVE,52160,AUBERIVE,,47.7789330347,5.10722171802,23,,Auberive,Auberive,52,Haute-Marne,44,Grand Est
52025,AUDELONCOURT,52240,AUDELONCOURT,,48.1158167981,5.53260787357,25,,Audeloncourt,Audeloncourt,52,Haute-Marne,44,Grand Est
52027,AUJEURRES,52190,AUJEURRES,,47.7387809421,5.17295673992,27,,Aujeurres,Aujeurres,52,Haute-Marne,44,Grand Est
52028,AULNOY SUR AUBE,52160,AULNOY SUR AUBE,,47.8312576468,5.03398861314,28,,Aulnoy-sur-Aube,Aulnoy-sur-Aube,52,Haute-Marne,44,Grand Est
52029,AUTIGNY LE GRAND,52300,AUTIGNY LE GRAND,,48.4719385669,5.14870838264,29,,Autigny-le-Grand,Autigny-le-Grand,52,Haute-Marne,44,Grand Est
52030,AUTIGNY LE PETIT,52300,AUTIGNY LE PETIT,,48.4816592371,5.15235006309,30,,Autigny-le-Petit,Autigny-le-Petit,52,Haute-Marne,44,Grand Est
52031,AUTREVILLE SUR LA RENNE,52120,AUTREVILLE SUR LA RENNE,,48.1258481479,4.97740367937,31,,Autreville-sur-la-Renne,Autreville-sur-la-Renne,52,Haute-Marne,44,Grand Est
52031,AUTREVILLE SUR LA RENNE,52120,AUTREVILLE SUR LA RENNE,VALDELANCOURT,48.1258481479,4.97740367937,31,,Autreville-sur-la-Renne,Autreville-sur-la-Renne,52,Haute-Marne,44,Grand Est
52031,AUTREVILLE SUR LA RENNE,52330,AUTREVILLE SUR LA RENNE,,48.1258481479,4.97740367937,31,,Autreville-sur-la-Renne,Autreville-sur-la-Renne,52,Haute-Marne,44,Grand Est
52031,AUTREVILLE SUR LA RENNE,52330,AUTREVILLE SUR LA RENNE,ST MARTIN SUR LA RENNE,48.1258481479,4.97740367937,31,,Autreville-sur-la-Renne,Autreville-sur-la-Renne,52,Haute-Marne,44,Grand Est
52033,AVRECOURT,52140,AVRECOURT,,47.9667481066,5.53468073024,33,,Avrecourt,Avrecourt,52,Haute-Marne,44,Grand Est
52034,BAILLY AUX FORGES,52130,BAILLY AUX FORGES,,48.4543823566,4.90549135186,34,,Bailly-aux-Forges,Bailly-aux-Forges,52,Haute-Marne,44,Grand Est
52035,BAISSEY,52250,BAISSEY,,47.7489182688,5.24988056664,35,,Baissey,Baissey,52,Haute-Marne,44,Grand Est
52037,BANNES,52360,BANNES,,47.901328666,5.3916498109,37,,Bannes,Bannes,52,Haute-Marne,44,Grand Est
52038,BASSONCOURT,52240,BASSONCOURT,,48.0726409981,5.55718616762,38,,Bassoncourt,Bassoncourt,52,Haute-Marne,44,Grand Est
52039,BAUDRECOURT,52110,BAUDRECOURT,,48.3950398869,4.96825290253,39,,Baudrecourt,Baudrecourt,52,Haute-Marne,44,Grand Est
52040,BAY SUR AUBE,52160,BAY SUR AUBE,,47.8238443502,5.06047731738,40,,Bay-sur-Aube,Bay-sur-Aube,52,Haute-Marne,44,Grand Est
52042,BEAUCHEMIN,52260,BEAUCHEMIN,,47.9178737226,5.24775917113,42,,Beauchemin,Beauchemin,52,Haute-Marne,44,Grand Est
52043,BELMONT,52500,BELMONT,,47.7200699243,5.55533089037,43,,Belmont,Belmont,52,Haute-Marne,44,Grand Est
52044,ROCHES BETTAINCOURT,52270,ROCHES BETTAINCOURT,,48.2973672533,5.25753260566,44,,Roches-Bettaincourt,Roches-Bettaincourt,52,Haute-Marne,44,Grand Est
52044,ROCHES BETTAINCOURT,52270,ROCHES BETTAINCOURT,BETTAINCOURT SUR ROGNON,48.2973672533,5.25753260566,44,,Roches-Bettaincourt,Roches-Bettaincourt,52,Haute-Marne,44,Grand Est
52044,ROCHES BETTAINCOURT,52270,ROCHES BETTAINCOURT,ROCHES SUR ROGNON,48.2973672533,5.25753260566,44,,Roches-Bettaincourt,Roches-Bettaincourt,52,Haute-Marne,44,Grand Est
52045,BETTANCOURT LA FERREE,52100,BETTANCOURT LA FERREE,,48.6559404041,4.97264106822,45,,Bettancourt-la-Ferrée,Bettancourt-la-Ferrée,52,Haute-Marne,44,Grand Est
52047,BEURVILLE,52110,BEURVILLE,,48.3155770517,4.86121801407,47,,Beurville,Beurville,52,Haute-Marne,44,Grand Est
52050,BIESLES,52340,BIESLES,,48.102555202,5.28581680401,50,,Biesles,Biesles,52,Haute-Marne,44,Grand Est
52050,BIESLES,52340,BIESLES,LE PUITS DES MEZES,48.102555202,5.28581680401,50,,Biesles,Biesles,52,Haute-Marne,44,Grand Est
52051,BIZE,52500,BIZE,,47.8401528768,5.6357935522,51,,Bize,Bize,52,Haute-Marne,44,Grand Est
52053,BLAISY,52330,BLAISY,,48.1733254707,5.01063806969,53,,Blaisy,Blaisy,52,Haute-Marne,44,Grand Est
52055,BLECOURT,52300,BLECOURT,,48.3799023139,5.08188668024,55,,Blécourt,Blécourt,52,Haute-Marne,44,Grand Est
52056,BLESSONVILLE,52120,BLESSONVILLE,,48.0580108633,5.01262588825,56,,Blessonville,Blessonville,52,Haute-Marne,44,Grand Est
52057,BLUMERAY,52110,BLUMERAY,,48.3542889175,4.86349451944,57,,Blumeray,Blumeray,52,Haute-Marne,44,Grand Est
52058,BOLOGNE,52310,BOLOGNE,,48.1992324541,5.14187719083,58,,Bologne,Bologne,52,Haute-Marne,44,Grand Est
52058,BOLOGNE,52310,BOLOGNE,MARAULT,48.1992324541,5.14187719083,58,,Bologne,Bologne,52,Haute-Marne,44,Grand Est
52058,BOLOGNE,52310,BOLOGNE,ROOCOURT LA COTE,48.1992324541,5.14187719083,58,,Bologne,Bologne,52,Haute-Marne,44,Grand Est
52059,BONNECOURT,52360,BONNECOURT,,47.9545453262,5.48095599257,59,,Bonnecourt,Bonnecourt,52,Haute-Marne,44,Grand Est
52060,BOURBONNE LES BAINS,52400,BOURBONNE LES BAINS,,47.9441089031,5.7418695819,60,,Bourbonne-les-Bains,Bourbonne-les-Bains,52,Haute-Marne,44,Grand Est
52060,BOURBONNE LES BAINS,52400,BOURBONNE LES BAINS,GENRUPT,47.9441089031,5.7418695819,60,,Bourbonne-les-Bains,Bourbonne-les-Bains,52,Haute-Marne,44,Grand Est
52060,BOURBONNE LES BAINS,52400,BOURBONNE LES BAINS,VILLARS ST MARCELLIN,47.9441089031,5.7418695819,60,,Bourbonne-les-Bains,Bourbonne-les-Bains,52,Haute-Marne,44,Grand Est
52061,BOURDONS SUR ROGNON,52700,BOURDONS SUR ROGNON,,48.1760934357,5.33895543232,61,,Bourdons-sur-Rognon,Bourdons-sur-Rognon,52,Haute-Marne,44,Grand Est
52062,BOURG,52200,BOURG,,47.7955958128,5.31329818526,62,,Bourg,Bourg,52,Haute-Marne,44,Grand Est
52063,BOURG STE MARIE,52150,BOURG STE MARIE,,48.1854541714,5.55429126567,63,,Bourg-Sainte-Marie,Bourg-Sainte-Marie,52,Haute-Marne,44,Grand Est
52064,BOURMONT,52150,BOURMONT,,48.2050671577,5.60137874866,64,,Bourmont-entre-Meuse-et-Mouzon,Bourmont-entre-Meuse-et-Mouzon,52,Haute-Marne,44,Grand Est
52064,BOURMONT,52150,BOURMONT,GONAINCOURT,48.2050671577,5.60137874866,64,,Bourmont-entre-Meuse-et-Mouzon,Bourmont-entre-Meuse-et-Mouzon,52,Haute-Marne,44,Grand Est
52065,BOUZANCOURT,52110,BOUZANCOURT,,48.3161828914,4.95023725164,65,,Bouzancourt,Bouzancourt,52,Haute-Marne,44,Grand Est
52066,BRACHAY,52110,BRACHAY,,48.3817212409,5.03615837807,66,,Brachay,Brachay,52,Haute-Marne,44,Grand Est
52067,BRAINVILLE SUR MEUSE,52150,BRAINVILLE SUR MEUSE,,48.1751020027,5.59252512247,67,,Brainville-sur-Meuse,Brainville-sur-Meuse,52,Haute-Marne,44,Grand Est
52069,BRAUX LE CHATEL,52120,BRAUX LE CHATEL,,48.1049254714,4.93912395764,69,,Braux-le-Châtel,Braux-le-Châtel,52,Haute-Marne,44,Grand Est
52070,BRENNES,52200,BRENNES,,47.7980886975,5.28308639281,70,,Brennes,Brennes,52,Haute-Marne,44,Grand Est
52072,BRETHENAY,52000,BRETHENAY,,48.1570319253,5.13602745985,72,,Brethenay,Brethenay,52,Haute-Marne,44,Grand Est
52074,BREUVANNES EN BASSIGNY,52240,BREUVANNES EN BASSIGNY,,48.0850729223,5.61021002539,74,,Breuvannes-en-Bassigny,Breuvannes-en-Bassigny,52,Haute-Marne,44,Grand Est
52074,BREUVANNES EN BASSIGNY,52240,BREUVANNES EN BASSIGNY,COLOMBEY LES CHOISEUL,48.0850729223,5.61021002539,74,,Breuvannes-en-Bassigny,Breuvannes-en-Bassigny,52,Haute-Marne,44,Grand Est
52074,BREUVANNES EN BASSIGNY,52240,BREUVANNES EN BASSIGNY,MEUVY,48.0850729223,5.61021002539,74,,Breuvannes-en-Bassigny,Breuvannes-en-Bassigny,52,Haute-Marne,44,Grand Est
52075,BRIAUCOURT,52700,BRIAUCOURT,,48.2080254801,5.19573843371,75,,Briaucourt,Briaucourt,52,Haute-Marne,44,Grand Est
52076,BRICON,52120,BRICON,,48.0857699601,4.98565524852,76,,Bricon,Bricon,52,Haute-Marne,44,Grand Est
52079,BROUSSEVAL,52130,BROUSSEVAL,,48.4911221703,4.97724506546,79,,Brousseval,Brousseval,52,Haute-Marne,44,Grand Est
52082,BUGNIERES,52210,BUGNIERES,,47.9610345188,5.08914316906,82,,Bugnières,Bugnières,52,Haute-Marne,44,Grand Est
52083,CHAMPSEVRAINE,52500,CHAMPSEVRAINE,,47.7640004218,5.52713580414,83,,Champsevraine,Champsevraine,52,Haute-Marne,44,Grand Est
52083,CHAMPSEVRAINE,52500,CHAMPSEVRAINE,BUSSIERES LES BELMONT,47.7640004218,5.52713580414,83,,Champsevraine,Champsevraine,52,Haute-Marne,44,Grand Est
52083,CHAMPSEVRAINE,52500,CHAMPSEVRAINE,CORGIRNON,47.7640004218,5.52713580414,83,,Champsevraine,Champsevraine,52,Haute-Marne,44,Grand Est
52084,BUSSON,52700,BUSSON,,48.33182756,5.34457219599,84,,Busson,Busson,52,Haute-Marne,44,Grand Est
52085,BUXIERES LES CLEFMONT,52240,BUXIERES LES CLEFMONT,,48.0897801284,5.45977208825,85,,Buxières-lès-Clefmont,Buxières-lès-Clefmont,52,Haute-Marne,44,Grand Est
52087,BUXIERES LES VILLIERS,52000,BUXIERES LES VILLIERS,,48.1014585884,5.02708635524,87,,Buxières-lès-Villiers,Buxières-lès-Villiers,52,Haute-Marne,44,Grand Est
52088,CEFFONDS,52220,CEFFONDS,,48.4425385457,4.74768588072,88,,Ceffonds,Ceffonds,52,Haute-Marne,44,Grand Est
52088,CEFFONDS,52220,CEFFONDS,ANGLUS,48.4425385457,4.74768588072,88,,Ceffonds,Ceffonds,52,Haute-Marne,44,Grand Est
52088,CEFFONDS,52220,CEFFONDS,SAUVAGE MAGNY,48.4425385457,4.74768588072,88,,Ceffonds,Ceffonds,52,Haute-Marne,44,Grand Est
52089,CELLES EN BASSIGNY,52360,CELLES EN BASSIGNY,,47.9119135852,5.55156066281,89,,Celles-en-Bassigny,Celles-en-Bassigny,52,Haute-Marne,44,Grand Est
52090,CELSOY,52600,CELSOY,,47.8630499689,5.49202471429,90,,Celsoy,Celsoy,52,Haute-Marne,44,Grand Est
52091,CERISIERES,52320,CERISIERES,,48.3093104196,5.07874546698,91,,Cerisières,Cerisières,52,Haute-Marne,44,Grand Est
52092,CHALANCEY,52160,CHALANCEY,,47.6766614444,5.13613711539,92,,Chalancey,Chalancey,52,Haute-Marne,44,Grand Est
52093,CHALINDREY,52600,CHALINDREY,,47.7928741357,5.44307044618,93,,Chalindrey,Chalindrey,52,Haute-Marne,44,Grand Est
52094,VALS DES TILLES,52160,VALS DES TILLES,,47.6942815153,5.08168973131,94,,Vals-des-Tilles,Vals-des-Tilles,52,Haute-Marne,44,Grand Est
52094,VALS DES TILLES,52160,VALS DES TILLES,CHALMESSIN,47.6942815153,5.08168973131,94,,Vals-des-Tilles,Vals-des-Tilles,52,Haute-Marne,44,Grand Est
52094,VALS DES TILLES,52160,VALS DES TILLES,LAMARGELLE AUX BOIS,47.6942815153,5.08168973131,94,,Vals-des-Tilles,Vals-des-Tilles,52,Haute-Marne,44,Grand Est
52094,VALS DES TILLES,52160,VALS DES TILLES,MUSSEAU,47.6942815153,5.08168973131,94,,Vals-des-Tilles,Vals-des-Tilles,52,Haute-Marne,44,Grand Est
52094,VALS DES TILLES,52160,VALS DES TILLES,VILLEMERVRY,47.6942815153,5.08168973131,94,,Vals-des-Tilles,Vals-des-Tilles,52,Haute-Marne,44,Grand Est
52094,VALS DES TILLES,52160,VALS DES TILLES,VILLEMORON,47.6942815153,5.08168973131,94,,Vals-des-Tilles,Vals-des-Tilles,52,Haute-Marne,44,Grand Est
52095,CHALVRAINES,52700,CHALVRAINES,,48.2303573038,5.50163461294,95,,Chalvraines,Chalvraines,52,Haute-Marne,44,Grand Est
52097,CHAMBRONCOURT,52700,CHAMBRONCOURT,,48.3521665045,5.39965495154,97,,Chambroncourt,Chambroncourt,52,Haute-Marne,44,Grand Est
52099,CHAMOUILLEY,52410,CHAMOUILLEY,,48.6082188964,5.05078741037,99,,Chamouilley,Chamouilley,52,Haute-Marne,44,Grand Est
52101,CHAMPIGNEULLES EN BASSIGNY,52150,CHAMPIGNEULLES EN BASSIGNY,,48.1287336984,5.62935287629,101,,Champigneulles-en-Bassigny,Champigneulles-en-Bassigny,52,Haute-Marne,44,Grand Est
52102,CHAMPIGNY LES LANGRES,52200,CHAMPIGNY LES LANGRES,,47.8953588704,5.34704113965,102,,Champigny-lès-Langres,Champigny-lès-Langres,52,Haute-Marne,44,Grand Est
52103,CHAMPIGNY SOUS VARENNES,52400,CHAMPIGNY SOUS VARENNES,,47.8719082532,5.62939550331,103,,Champigny-sous-Varennes,Champigny-sous-Varennes,52,Haute-Marne,44,Grand Est
52104,CHANCENAY,52100,CHANCENAY,,48.6747534279,4.98001306427,104,,Chancenay,Chancenay,52,Haute-Marne,44,Grand Est
52105,CHANGEY,52360,CHANGEY,,47.9258657605,5.38438279152,105,,Changey,Changey,52,Haute-Marne,44,Grand Est
52106,CHANOY,52260,CHANOY,,47.9206283856,5.28430781755,106,,Chanoy,Chanoy,52,Haute-Marne,44,Grand Est
52107,CHANTRAINES,52700,CHANTRAINES,,48.216107614,5.24193163106,107,,Chantraines,Chantraines,52,Haute-Marne,44,Grand Est
52108,CHARMES,52360,CHARMES,,47.9205445894,5.3544393191,108,,Charmes,Charmes,52,Haute-Marne,44,Grand Est
52109,CHARMES EN L ANGLE,52110,CHARMES EN L ANGLE,,48.3590507638,4.99706046425,109,,Charmes-en-l'Angle,Charmes-en-l'Angle,52,Haute-Marne,44,Grand Est
52110,CHARMES LA GRANDE,52110,CHARMES LA GRANDE,,48.3839955278,4.99607090848,110,,Charmes-la-Grande,Charmes-la-Grande,52,Haute-Marne,44,Grand Est
52113,CHASSIGNY,52190,CHASSIGNY,,47.7183172965,5.38247891273,113,,Chassigny,Chassigny,52,Haute-Marne,44,Grand Est
52114,CHATEAUVILLAIN,52120,CHATEAUVILLAIN,,48.0291535601,4.93239064269,114,,Châteauvillain,Châteauvillain,52,Haute-Marne,44,Grand Est
52114,CHATEAUVILLAIN,52120,CHATEAUVILLAIN,CREANCEY,48.0291535601,4.93239064269,114,,Châteauvillain,Châteauvillain,52,Haute-Marne,44,Grand Est
52114,CHATEAUVILLAIN,52120,CHATEAUVILLAIN,ESSEY LES PONTS,48.0291535601,4.93239064269,114,,Châteauvillain,Châteauvillain,52,Haute-Marne,44,Grand Est
52114,CHATEAUVILLAIN,52120,CHATEAUVILLAIN,MARMESSE,48.0291535601,4.93239064269,114,,Châteauvillain,Châteauvillain,52,Haute-Marne,44,Grand Est
52115,CHATENAY MACHERON,52200,CHATENAY MACHERON,,47.8564939499,5.38906909699,115,,Chatenay-Mâcheron,Chatenay-Mâcheron,52,Haute-Marne,44,Grand Est
52116,CHATENAY VAUDIN,52360,CHATENAY VAUDIN,,47.8510495493,5.44883005859,116,,Chatenay-Vaudin,Chatenay-Vaudin,52,Haute-Marne,44,Grand Est
52118,CHATONRUPT SOMMERMONT,52300,CHATONRUPT SOMMERMONT,,48.4718286513,5.10006137447,118,,Chatonrupt-Sommermont,Chatonrupt-Sommermont,52,Haute-Marne,44,Grand Est
52118,CHATONRUPT SOMMERMONT,52300,CHATONRUPT SOMMERMONT,SOMMERMONT,48.4718286513,5.10006137447,118,,Chatonrupt-Sommermont,Chatonrupt-Sommermont,52,Haute-Marne,44,Grand Est
52119,CHAUDENAY,52600,CHAUDENAY,,47.8217167742,5.50082172465,119,,Chaudenay,Chaudenay,52,Haute-Marne,44,Grand Est
52120,CHAUFFOURT,52140,CHAUFFOURT,,47.9727336414,5.43587115621,120,,Chauffourt,Chauffourt,52,Haute-Marne,44,Grand Est
52121,CHAUMONT,52000,CHAUMONT,,48.0980144211,5.14070044621,121,,Chaumont,Chaumont,52,Haute-Marne,44,Grand Est
52121,CHAUMONT,52000,CHAUMONT,BROTTES,48.0980144211,5.14070044621,121,,Chaumont,Chaumont,52,Haute-Marne,44,Grand Est
52122,CHAUMONT LA VILLE,52150,CHAUMONT LA VILLE,,48.1446272021,5.64945872273,122,,Chaumont-la-Ville,Chaumont-la-Ville,52,Haute-Marne,44,Grand Est
52123,CHEVILLON,52170,CHEVILLON,,48.5306693888,5.14449573214,123,,Chevillon,Chevillon,52,Haute-Marne,44,Grand Est
52123,CHEVILLON,52170,CHEVILLON,BREUIL SUR MARNE,48.5306693888,5.14449573214,123,,Chevillon,Chevillon,52,Haute-Marne,44,Grand Est
52123,CHEVILLON,52170,CHEVILLON,SOMMEVILLE,48.5306693888,5.14449573214,123,,Chevillon,Chevillon,52,Haute-Marne,44,Grand Est
52124,CHEZEAUX,52400,CHEZEAUX,,47.8796059313,5.67169694346,124,,Chézeaux,Chézeaux,52,Haute-Marne,44,Grand Est
52125,CHAMARANDES CHOIGNES,52000,CHAMARANDES CHOIGNES,,48.1022459554,5.18945345266,125,,Chamarandes-Choignes,Chamarandes-Choignes,52,Haute-Marne,44,Grand Est
52125,CHAMARANDES CHOIGNES,52000,CHAMARANDES CHOIGNES,CHAMARANDES,48.1022459554,5.18945345266,125,,Chamarandes-Choignes,Chamarandes-Choignes,52,Haute-Marne,44,Grand Est
52125,CHAMARANDES CHOIGNES,52000,CHAMARANDES CHOIGNES,CHOIGNES,48.1022459554,5.18945345266,125,,Chamarandes-Choignes,Chamarandes-Choignes,52,Haute-Marne,44,Grand Est
52126,CHOILLEY DARDENAY,52190,CHOILLEY DARDENAY,,47.6613154745,5.36577957222,126,,Choilley-Dardenay,Choilley-Dardenay,52,Haute-Marne,44,Grand Est
52126,CHOILLEY DARDENAY,52190,CHOILLEY DARDENAY,DARDENAY,47.6613154745,5.36577957222,126,,Choilley-Dardenay,Choilley-Dardenay,52,Haute-Marne,44,Grand Est
52127,CHOISEUL,52240,CHOISEUL,,48.0528969056,5.57184637406,127,,Choiseul,Choiseul,52,Haute-Marne,44,Grand Est
52128,CIREY LES MAREILLES,52700,CIREY LES MAREILLES,,48.1970178033,5.298201554,128,,Cirey-lès-Mareilles,Cirey-lès-Mareilles,52,Haute-Marne,44,Grand Est
52129,CIREY SUR BLAISE,52110,CIREY SUR BLAISE,,48.3335754386,4.93609411438,129,,Cirey-sur-Blaise,Cirey-sur-Blaise,52,Haute-Marne,44,Grand Est
52130,CIRFONTAINES EN AZOIS,52370,CIRFONTAINES EN AZOIS,,48.1049404341,4.86318194734,130,,Cirfontaines-en-Azois,Cirfontaines-en-Azois,52,Haute-Marne,44,Grand Est
52131,CIRFONTAINES EN ORNOIS,52230,CIRFONTAINES EN ORNOIS,,48.4532797682,5.38951402511,131,,Cirfontaines-en-Ornois,Cirfontaines-en-Ornois,52,Haute-Marne,44,Grand Est
52132,CLEFMONT,52240,CLEFMONT,,48.1137318112,5.49168449746,132,,Clefmont,Clefmont,52,Haute-Marne,44,Grand Est
52133,CLINCHAMP,52700,CLINCHAMP,,48.1950512199,5.45032533735,133,,Clinchamp,Clinchamp,52,Haute-Marne,44,Grand Est
52134,COHONS,52600,COHONS,,47.7872946763,5.34164047789,134,,Cohons,Cohons,52,Haute-Marne,44,Grand Est
52135,COIFFY LE BAS,52400,COIFFY LE BAS,,47.9092931665,5.65983630677,135,,Coiffy-le-Bas,Coiffy-le-Bas,52,Haute-Marne,44,Grand Est
52136,COIFFY LE HAUT,52400,COIFFY LE HAUT,,47.9010106381,5.69160816764,136,,Coiffy-le-Haut,Coiffy-le-Haut,52,Haute-Marne,44,Grand Est
52137,COLMIER LE BAS,52160,COLMIER LE BAS,,47.7721798652,4.9400957102,137,,Colmier-le-Bas,Colmier-le-Bas,52,Haute-Marne,44,Grand Est
52138,COLMIER LE HAUT,52160,COLMIER LE HAUT,,47.7863345069,4.98805196449,138,,Colmier-le-Haut,Colmier-le-Haut,52,Haute-Marne,44,Grand Est
52140,COLOMBEY LES DEUX EGLISES,52330,COLOMBEY LES DEUX EGLISES,,48.2454844081,4.92015393779,140,,Colombey les Deux Églises,Colombey les Deux Églises,52,Haute-Marne,44,Grand Est
52140,COLOMBEY LES DEUX EGLISES,52330,COLOMBEY LES DEUX EGLISES,ARGENTOLLES,48.2454844081,4.92015393779,140,,Colombey les Deux Églises,Colombey les Deux Églises,52,Haute-Marne,44,Grand Est
52140,COLOMBEY LES DEUX EGLISES,52330,COLOMBEY LES DEUX EGLISES,BIERNES,48.2454844081,4.92015393779,140,,Colombey les Deux Églises,Colombey les Deux Églises,52,Haute-Marne,44,Grand Est
52140,COLOMBEY LES DEUX EGLISES,52330,COLOMBEY LES DEUX EGLISES,BLAISE,48.2454844081,4.92015393779,140,,Colombey les Deux Églises,Colombey les Deux Églises,52,Haute-Marne,44,Grand Est
52140,COLOMBEY LES DEUX EGLISES,52330,COLOMBEY LES DEUX EGLISES,CHAMPCOURT,48.2454844081,4.92015393779,140,,Colombey les Deux Églises,Colombey les Deux Églises,52,Haute-Marne,44,Grand Est
52140,COLOMBEY LES DEUX EGLISES,52330,COLOMBEY LES DEUX EGLISES,HARRICOURT,48.2454844081,4.92015393779,140,,Colombey les Deux Églises,Colombey les Deux Églises,52,Haute-Marne,44,Grand Est
52140,COLOMBEY LES DEUX EGLISES,52330,COLOMBEY LES DEUX EGLISES,LAVILLENEUVE AUX FRESNES,48.2454844081,4.92015393779,140,,Colombey les Deux Églises,Colombey les Deux Églises,52,Haute-Marne,44,Grand Est
52140,COLOMBEY LES DEUX EGLISES,52330,COLOMBEY LES DEUX EGLISES,PRATZ,48.2454844081,4.92015393779,140,,Colombey les Deux Églises,Colombey les Deux Églises,52,Haute-Marne,44,Grand Est
52141,CONDES,52000,CONDES,,48.1420892135,5.14324861233,141,,Condes,Condes,52,Haute-Marne,44,Grand Est
52142,CONSIGNY,52700,CONSIGNY,,48.1654075569,5.41135471652,142,,Consigny,Consigny,52,Haute-Marne,44,Grand Est
52145,COUBLANC,52500,COUBLANC,,47.6939540954,5.44943414423,145,,Coublanc,Coublanc,52,Haute-Marne,44,Grand Est
52146,COUPRAY,52210,COUPRAY,,47.9771163258,4.94022617054,146,,Coupray,Coupray,52,Haute-Marne,44,Grand Est
52147,COURCELLES EN MONTAGNE,52200,COURCELLES EN MONTAGNE,,47.8419296659,5.21506539233,147,,Courcelles-en-Montagne,Courcelles-en-Montagne,52,Haute-Marne,44,Grand Est
52149,COURCELLES SUR BLAISE,52110,COURCELLES SUR BLAISE,,48.4128684466,4.94116258258,149,,Courcelles-sur-Blaise,Courcelles-sur-Blaise,52,Haute-Marne,44,Grand Est
52151,COUR L EVEQUE,52210,COUR L EVEQUE,,47.9767999819,4.982031793,151,,Cour-l'Évêque,Cour-l'Évêque,52,Haute-Marne,44,Grand Est
52155,CULMONT,52600,CULMONT,,47.8293464175,5.44281148434,155,,Culmont,Culmont,52,Haute-Marne,44,Grand Est
52156,CUREL,52300,CUREL,,48.4982110144,5.14626238825,156,,Curel,Curel,52,Haute-Marne,44,Grand Est
52157,CURMONT,52330,CURMONT,,48.2556964438,4.95172366017,157,,Curmont,Curmont,52,Haute-Marne,44,Grand Est
52158,CUSEY,52190,CUSEY,,47.6250364992,5.35268671907,158,,Cusey,Cusey,52,Haute-Marne,44,Grand Est
52158,CUSEY,52190,CUSEY,PERCEY SOUS MONTORMENTIER,47.6250364992,5.35268671907,158,,Cusey,Cusey,52,Haute-Marne,44,Grand Est
52159,CUVES,52240,CUVES,,48.0947233015,5.43400619127,159,,Cuves,Cuves,52,Haute-Marne,44,Grand Est
52160,DAILLANCOURT,52110,DAILLANCOURT,,48.2966159781,4.93715295501,160,,Daillancourt,Daillancourt,52,Haute-Marne,44,Grand Est
52161,DAILLECOURT,52240,DAILLECOURT,,48.0727138198,5.50793307633,161,,Daillecourt,Daillecourt,52,Haute-Marne,44,Grand Est
52162,DAMMARTIN SUR MEUSE,52140,DAMMARTIN SUR MEUSE,,47.9802668402,5.58560136029,162,,Dammartin-sur-Meuse,Dammartin-sur-Meuse,52,Haute-Marne,44,Grand Est
52163,DAMPIERRE,52360,DAMPIERRE,,47.9584274789,5.40019439303,163,,Dampierre,Dampierre,52,Haute-Marne,44,Grand Est
52164,DAMREMONT,52400,DAMREMONT,,47.9487557847,5.64105093613,164,,Damrémont,Damrémont,52,Haute-Marne,44,Grand Est
52165,DANCEVOIR,52210,DANCEVOIR,,47.9334948469,4.8797472329,165,,Dancevoir,Dancevoir,52,Haute-Marne,44,Grand Est
52167,DARMANNES,52700,DARMANNES,,48.1680388839,5.22269064305,167,,Darmannes,Darmannes,52,Haute-Marne,44,Grand Est
52168,DINTEVILLE,52120,DINTEVILLE,,48.0375731521,4.80723999451,168,,Dinteville,Dinteville,52,Haute-Marne,44,Grand Est
52169,DOMBLAIN,52130,DOMBLAIN,,48.465754612,5.00175003331,169,,Domblain,Domblain,52,Haute-Marne,44,Grand Est
52170,DOMMARIEN,52190,DOMMARIEN,,47.6892296711,5.35283846982,170,,Dommarien,Dommarien,52,Haute-Marne,44,Grand Est
52171,DOMMARTIN LE FRANC,52110,DOMMARTIN LE FRANC,,48.425380925,4.9518947525,171,,Dommartin-le-Franc,Dommartin-le-Franc,52,Haute-Marne,44,Grand Est
52172,DOMMARTIN LE ST PERE,52110,DOMMARTIN LE ST PERE,,48.4011786255,4.91405367965,172,,Dommartin-le-Saint-Père,Dommartin-le-Saint-Père,52,Haute-Marne,44,Grand Est
52173,DOMREMY LANDEVILLE,52270,DOMREMY LANDEVILLE,,48.359441184,5.25296341706,173,,Domremy-Landéville,Domremy-Landéville,52,Haute-Marne,44,Grand Est
52173,DOMREMY LANDEVILLE,52270,DOMREMY LANDEVILLE,LANDEVILLE,48.359441184,5.25296341706,173,,Domremy-Landéville,Domremy-Landéville,52,Haute-Marne,44,Grand Est
52174,DONCOURT SUR MEUSE,52150,DONCOURT SUR MEUSE,,48.140541872,5.58464519798,174,,Doncourt-sur-Meuse,Doncourt-sur-Meuse,52,Haute-Marne,44,Grand Est
52175,DONJEUX,52300,DONJEUX,,48.3533734076,5.16224732615,175,,Donjeux,Donjeux,52,Haute-Marne,44,Grand Est
52177,DOULAINCOURT SAUCOURT,52270,DOULAINCOURT SAUCOURT,,48.3149359491,5.2044416904,177,,Doulaincourt-Saucourt,Doulaincourt-Saucourt,52,Haute-Marne,44,Grand Est
52177,DOULAINCOURT SAUCOURT,52270,DOULAINCOURT SAUCOURT,SAUCOURT SUR ROGNON,48.3149359491,5.2044416904,177,,Doulaincourt-Saucourt,Doulaincourt-Saucourt,52,Haute-Marne,44,Grand Est
52178,DOULEVANT LE CHATEAU,52110,DOULEVANT LE CHATEAU,,48.3711804366,4.91706385243,178,,Doulevant-le-Château,Doulevant-le-Château,52,Haute-Marne,44,Grand Est
52178,DOULEVANT LE CHATEAU,52110,DOULEVANT LE CHATEAU,VILLIERS AUX CHENES,48.3711804366,4.91706385243,178,,Doulevant-le-Château,Doulevant-le-Château,52,Haute-Marne,44,Grand Est
52179,DOULEVANT LE PETIT,52130,DOULEVANT LE PETIT,,48.45005121,4.95140742292,179,,Doulevant-le-Petit,Doulevant-le-Petit,52,Haute-Marne,44,Grand Est
52181,ECHENAY,52230,ECHENAY,,48.4637165491,5.31232044052,181,,Échenay,Échenay,52,Haute-Marne,44,Grand Est
52182,ECLARON BRAUCOURT STE LIVIERE,52290,ECLARON BRAUCOURT STE LIVIERE,,48.5752024627,4.83180958879,182,,Éclaron-Braucourt-Sainte-Livière,Éclaron-Braucourt-Sainte-Livière,52,Haute-Marne,44,Grand Est
52182,ECLARON BRAUCOURT STE LIVIERE,52290,ECLARON BRAUCOURT STE LIVIERE,BRAUCOURT,48.5752024627,4.83180958879,182,,Éclaron-Braucourt-Sainte-Livière,Éclaron-Braucourt-Sainte-Livière,52,Haute-Marne,44,Grand Est
52182,ECLARON BRAUCOURT STE LIVIERE,52290,ECLARON BRAUCOURT STE LIVIERE,STE LIVIERE,48.5752024627,4.83180958879,182,,Éclaron-Braucourt-Sainte-Livière,Éclaron-Braucourt-Sainte-Livière,52,Haute-Marne,44,Grand Est
52183,ECOT LA COMBE,52700,ECOT LA COMBE,,48.208131496,5.39669220982,183,,Ecot-la-Combe,Ecot-la-Combe,52,Haute-Marne,44,Grand Est
52184,EFFINCOURT,52300,EFFINCOURT,,48.4941454945,5.26283262819,184,,Effincourt,Effincourt,52,Haute-Marne,44,Grand Est
52185,ENFONVELLE,52400,ENFONVELLE,,47.9188396684,5.86558348246,185,,Enfonvelle,Enfonvelle,52,Haute-Marne,44,Grand Est
52187,EPIZON,52230,EPIZON,,48.3781421603,5.33232295089,187,,Épizon,Épizon,52,Haute-Marne,44,Grand Est
52187,EPIZON,52230,EPIZON,BETTONCOURT LE HAUT,48.3781421603,5.33232295089,187,,Épizon,Épizon,52,Haute-Marne,44,Grand Est
52187,EPIZON,52270,EPIZON,AUGEVILLE,48.3781421603,5.33232295089,187,,Épizon,Épizon,52,Haute-Marne,44,Grand Est
52187,EPIZON,52270,EPIZON,PAUTAINES,48.3781421603,5.33232295089,187,,Épizon,Épizon,52,Haute-Marne,44,Grand Est
52189,LE VAL D ESNOMS,52190,LE VAL D ESNOMS,,47.6904929765,5.21656859314,189,Le,Val-d'Esnoms,Le Val-d'Esnoms,52,Haute-Marne,44,Grand Est
52189,LE VAL D ESNOMS,52190,LE VAL D ESNOMS,CHATOILLENOT,47.6904929765,5.21656859314,189,Le,Val-d'Esnoms,Le Val-d'Esnoms,52,Haute-Marne,44,Grand Est
52189,LE VAL D ESNOMS,52190,LE VAL D ESNOMS,COURCELLES VAL D ESNOMS,47.6904929765,5.21656859314,189,Le,Val-d'Esnoms,Le Val-d'Esnoms,52,Haute-Marne,44,Grand Est
52189,LE VAL D ESNOMS,52190,LE VAL D ESNOMS,ESNOMS AU VAL,47.6904929765,5.21656859314,189,Le,Val-d'Esnoms,Le Val-d'Esnoms,52,Haute-Marne,44,Grand Est
52190,ESNOUVEAUX,52340,ESNOUVEAUX,,48.1327800395,5.34926255803,190,,Esnouveaux,Esnouveaux,52,Haute-Marne,44,Grand Est
52193,EUFFIGNEIX,52000,EUFFIGNEIX,,48.1285952384,5.04441346885,193,,Euffigneix,Euffigneix,52,Haute-Marne,44,Grand Est
52194,EURVILLE BIENVILLE,52410,EURVILLE BIENVILLE,,48.5782674919,5.02966764311,194,,Eurville-Bienville,Eurville-Bienville,52,Haute-Marne,44,Grand Est
52194,EURVILLE BIENVILLE,52410,EURVILLE BIENVILLE,BIENVILLE,48.5782674919,5.02966764311,194,,Eurville-Bienville,Eurville-Bienville,52,Haute-Marne,44,Grand Est
52195,FARINCOURT,52500,FARINCOURT,,47.6965919672,5.68100859574,195,,Farincourt,Farincourt,52,Haute-Marne,44,Grand Est
52196,FAVEROLLES,52260,FAVEROLLES,,47.9535166477,5.22561749902,196,,Faverolles,Faverolles,52,Haute-Marne,44,Grand Est
52197,FAYL BILLOT,52500,FAYL BILLOT,,47.7869444974,5.60488795992,197,,Fayl-Billot,Fayl-Billot,52,Haute-Marne,44,Grand Est
52197,FAYL BILLOT,52500,FAYL BILLOT,BRONCOURT,47.7869444974,5.60488795992,197,,Fayl-Billot,Fayl-Billot,52,Haute-Marne,44,Grand Est
52197,FAYL BILLOT,52500,FAYL BILLOT,CHARMOY,47.7869444974,5.60488795992,197,,Fayl-Billot,Fayl-Billot,52,Haute-Marne,44,Grand Est
52198,FAYS,52130,FAYS,,48.4748666467,5.03808215827,198,,Fays,Fays,52,Haute-Marne,44,Grand Est
52199,FERRIERE ET LAFOLIE,52300,FERRIERE ET LAFOLIE,,48.3990664695,5.07542542698,199,,Ferrière-et-Lafolie,Ferrière-et-Lafolie,52,Haute-Marne,44,Grand Est
52200,FLAGEY,52250,FLAGEY,,47.7865880267,5.24538753249,200,,Flagey,Flagey,52,Haute-Marne,44,Grand Est
52201,FLAMMERECOURT,52110,FLAMMERECOURT,,48.3602623319,5.04472179817,201,,Flammerécourt,Flammerécourt,52,Haute-Marne,44,Grand Est
52203,FONTAINES SUR MARNE,52170,FONTAINES SUR MARNE,,48.5585322909,5.11565256465,203,,Fontaines-sur-Marne,Fontaines-sur-Marne,52,Haute-Marne,44,Grand Est
52204,FORCEY,52700,FORCEY,,48.1512048179,5.36896944878,204,,Forcey,Forcey,52,Haute-Marne,44,Grand Est
52205,FOULAIN,52000,FOULAIN,CRENAY,48.0223132698,5.16701559496,205,,Foulain,Foulain,52,Haute-Marne,44,Grand Est
52205,FOULAIN,52800,FOULAIN,,48.0223132698,5.16701559496,205,,Foulain,Foulain,52,Haute-Marne,44,Grand Est
52206,FRAMPAS,52220,FRAMPAS,,48.5195287693,4.8332793403,206,,Frampas,Frampas,52,Haute-Marne,44,Grand Est
52207,FRECOURT,52360,FRECOURT,,47.9494023613,5.45333076545,207,,Frécourt,Frécourt,52,Haute-Marne,44,Grand Est
52208,FRESNES SUR APANCE,52400,FRESNES SUR APANCE,,47.9402612111,5.83368203758,208,,Fresnes-sur-Apance,Fresnes-sur-Apance,52,Haute-Marne,44,Grand Est
52211,FRONCLES,52320,FRONCLES,,48.2997317806,5.13968155091,211,,Froncles,Froncles,52,Haute-Marne,44,Grand Est
52211,FRONCLES,52320,FRONCLES,BUXIERES LES FRONCLES,48.2997317806,5.13968155091,211,,Froncles,Froncles,52,Haute-Marne,44,Grand Est
52211,FRONCLES,52320,FRONCLES,PROVENCHERES SUR MARNE,48.2997317806,5.13968155091,211,,Froncles,Froncles,52,Haute-Marne,44,Grand Est
52212,FRONVILLE,52300,FRONVILLE,,48.4008313761,5.13453844457,212,,Fronville,Fronville,52,Haute-Marne,44,Grand Est
52213,GENEVRIERES,52500,GENEVRIERES,,47.7226560944,5.60301498362,213,,Genevrières,Genevrières,52,Haute-Marne,44,Grand Est
52214,LA GENEVROYE,52320,LA GENEVROYE,,48.2769154826,5.05621670765,214,La,Genevroye,La Genevroye,52,Haute-Marne,44,Grand Est
52216,GERMAINES,52160,GERMAINES,,47.7994372317,5.02777502578,216,,Germaines,Germaines,52,Haute-Marne,44,Grand Est
52217,GERMAINVILLIERS,52150,GERMAINVILLIERS,,48.1147244495,5.64040267442,217,,Germainvilliers,Germainvilliers,52,Haute-Marne,44,Grand Est
52218,GERMAY,52230,GERMAY,,48.4076524839,5.37142403643,218,,Germay,Germay,52,Haute-Marne,44,Grand Est
52219,GERMISAY,52230,GERMISAY,,48.3907421507,5.36918258943,219,,Germisay,Germisay,52,Haute-Marne,44,Grand Est
52220,GIEY SUR AUJON,52210,GIEY SUR AUJON,,47.9059188991,5.05514903281,220,,Giey-sur-Aujon,Giey-sur-Aujon,52,Haute-Marne,44,Grand Est
52221,GILLANCOURT,52330,GILLANCOURT,,48.1542646062,4.99722177508,221,,Gillancourt,Gillancourt,52,Haute-Marne,44,Grand Est
52222,GILLAUME,52230,GILLAUME,,48.4693259695,5.34579090105,222,,Gillaumé,Gillaumé,52,Haute-Marne,44,Grand Est
52223,GILLEY,52500,GILLEY,,47.6894873737,5.63261379823,223,,Gilley,Gilley,52,Haute-Marne,44,Grand Est
52225,GONCOURT,52150,GONCOURT,,48.2419488894,5.59330700815,225,,Goncourt,Goncourt,52,Haute-Marne,44,Grand Est
52227,GRAFFIGNY CHEMIN,52150,GRAFFIGNY CHEMIN,,48.1692808867,5.64603738902,227,,Graffigny-Chemin,Graffigny-Chemin,52,Haute-Marne,44,Grand Est
52228,GRANDCHAMP,52600,GRANDCHAMP,,47.7230166407,5.44612862714,228,,Grandchamp,Grandchamp,52,Haute-Marne,44,Grand Est
52229,GRENANT,52500,GRENANT,,47.7074669217,5.49441820553,229,,Grenant,Grenant,52,Haute-Marne,44,Grand Est
52230,GUDMONT VILLIERS,52320,GUDMONT VILLIERS,,48.3363931415,5.12108418016,230,,Gudmont-Villiers,Gudmont-Villiers,52,Haute-Marne,44,Grand Est
52230,GUDMONT VILLIERS,52320,GUDMONT VILLIERS,VILLIERS SUR MARNE,48.3363931415,5.12108418016,230,,Gudmont-Villiers,Gudmont-Villiers,52,Haute-Marne,44,Grand Est
52231,GUINDRECOURT AUX ORMES,52300,GUINDRECOURT AUX ORMES,,48.4548468693,5.03959560769,231,,Guindrecourt-aux-Ormes,Guindrecourt-aux-Ormes,52,Haute-Marne,44,Grand Est
52232,GUINDRECOURT SUR BLAISE,52330,GUINDRECOURT SUR BLAISE,,48.2979328826,4.98051100173,232,,Guindrecourt-sur-Blaise,Guindrecourt-sur-Blaise,52,Haute-Marne,44,Grand Est
52233,GUYONVELLE,52400,GUYONVELLE,,47.8592079373,5.70929745857,233,,Guyonvelle,Guyonvelle,52,Haute-Marne,44,Grand Est
52234,HACOURT,52150,HACOURT,,48.1581819606,5.58135190906,234,,Hâcourt,Hâcourt,52,Haute-Marne,44,Grand Est
52235,HALLIGNICOURT,52100,HALLIGNICOURT,,48.6478891057,4.87117380954,235,,Hallignicourt,Hallignicourt,52,Haute-Marne,44,Grand Est
52237,HARREVILLE LES CHANTEURS,52150,HARREVILLE LES CHANTEURS,,48.2669962236,5.62077184704,237,,Harréville-les-Chanteurs,Harréville-les-Chanteurs,52,Haute-Marne,44,Grand Est
52240,HEUILLEY LE GRAND,52600,HEUILLEY LE GRAND,,47.7518661762,5.39548262611,240,,Heuilley-le-Grand,Heuilley-le-Grand,52,Haute-Marne,44,Grand Est
52242,HAUTE AMANCE,52600,HAUTE AMANCE,,47.844980324,5.53371841027,242,,Haute-Amance,Haute-Amance,52,Haute-Marne,44,Grand Est
52242,HAUTE AMANCE,52600,HAUTE AMANCE,HORTES,47.844980324,5.53371841027,242,,Haute-Amance,Haute-Amance,52,Haute-Marne,44,Grand Est
52242,HAUTE AMANCE,52600,HAUTE AMANCE,MONTLANDON,47.844980324,5.53371841027,242,,Haute-Amance,Haute-Amance,52,Haute-Marne,44,Grand Est
52242,HAUTE AMANCE,52600,HAUTE AMANCE,ROSOY SUR AMANCE,47.844980324,5.53371841027,242,,Haute-Amance,Haute-Amance,52,Haute-Marne,44,Grand Est
52242,HAUTE AMANCE,52600,HAUTE AMANCE,TROISCHAMPS,47.844980324,5.53371841027,242,,Haute-Amance,Haute-Amance,52,Haute-Marne,44,Grand Est
52243,HUILLIECOURT,52150,HUILLIECOURT,,48.1599537536,5.53274384003,243,,Huilliécourt,Huilliécourt,52,Haute-Marne,44,Grand Est
52244,HUMBECOURT,52290,HUMBECOURT,,48.5797739259,4.92181738125,244,,Humbécourt,Humbécourt,52,Haute-Marne,44,Grand Est
52245,HUMBERVILLE,52700,HUMBERVILLE,,48.2924560516,5.3803496928,245,,Humberville,Humberville,52,Haute-Marne,44,Grand Est
52246,HUMES JORQUENAY,52200,HUMES JORQUENAY,,47.9037279897,5.30437368964,246,,Humes-Jorquenay,Humes-Jorquenay,52,Haute-Marne,44,Grand Est
52246,HUMES JORQUENAY,52200,HUMES JORQUENAY,JORQUENAY,47.9037279897,5.30437368964,246,,Humes-Jorquenay,Humes-Jorquenay,52,Haute-Marne,44,Grand Est
52247,ILLOUD,52150,ILLOUD,,48.2160151188,5.55304320685,247,,Illoud,Illoud,52,Haute-Marne,44,Grand Est
52248,IS EN BASSIGNY,52140,IS EN BASSIGNY,,48.0343615527,5.44661296465,248,,Is-en-Bassigny,Is-en-Bassigny,52,Haute-Marne,44,Grand Est
52249,ISOMES,52190,ISOMES,,47.6383926135,5.30585029521,249,,Isômes,Isômes,52,Haute-Marne,44,Grand Est
52250,JOINVILLE,52300,JOINVILLE,,48.4337759431,5.12296134696,250,,Joinville,Joinville,52,Haute-Marne,44,Grand Est
52251,JONCHERY,52000,JONCHERY,,48.1516102771,5.07446482114,251,,Jonchery,Jonchery,52,Haute-Marne,44,Grand Est
52251,JONCHERY,52000,JONCHERY,LAHARMAND,48.1516102771,5.07446482114,251,,Jonchery,Jonchery,52,Haute-Marne,44,Grand Est
52251,JONCHERY,52000,JONCHERY,SARCICOURT,48.1516102771,5.07446482114,251,,Jonchery,Jonchery,52,Haute-Marne,44,Grand Est
52253,JUZENNECOURT,52330,JUZENNECOURT,,48.1928229786,4.98502788338,253,,Juzennecourt,Juzennecourt,52,Haute-Marne,44,Grand Est
52254,LACHAPELLE EN BLAISY,52330,LACHAPELLE EN BLAISY,,48.20850002,4.96079377818,254,,Lachapelle-en-Blaisy,Lachapelle-en-Blaisy,52,Haute-Marne,44,Grand Est
52256,LAFAUCHE,52700,LAFAUCHE,,48.3053380759,5.49239986278,256,,Lafauche,Lafauche,52,Haute-Marne,44,Grand Est
52257,LAFERTE SUR AMANCE,52500,LAFERTE SUR AMANCE,,47.8342467374,5.69549834249,257,,Laferté-sur-Amance,Laferté-sur-Amance,52,Haute-Marne,44,Grand Est
52258,LAFERTE SUR AUBE,52120,LAFERTE SUR AUBE,,48.0939723153,4.77481337756,258,,Laferté-sur-Aube,Laferté-sur-Aube,52,Haute-Marne,44,Grand Est
52260,LAMANCINE,52310,LAMANCINE,,48.2111993531,5.11560659049,260,,Lamancine,Lamancine,52,Haute-Marne,44,Grand Est
52262,LAMOTHE EN BLAISY,52330,LAMOTHE EN BLAISY,,48.2364266781,4.94527289707,262,,Lamothe-en-Blaisy,Lamothe-en-Blaisy,52,Haute-Marne,44,Grand Est
52264,LANEUVELLE,52400,LANEUVELLE,,47.9338547424,5.66780967773,264,,Laneuvelle,Laneuvelle,52,Haute-Marne,44,Grand Est
52265,BAYARD SUR MARNE,52170,BAYARD SUR MARNE,,48.5499615771,5.06880935835,265,,Bayard-sur-Marne,Bayard-sur-Marne,52,Haute-Marne,44,Grand Est
52265,BAYARD SUR MARNE,52170,BAYARD SUR MARNE,GOURZON,48.5499615771,5.06880935835,265,,Bayard-sur-Marne,Bayard-sur-Marne,52,Haute-Marne,44,Grand Est
52265,BAYARD SUR MARNE,52170,BAYARD SUR MARNE,LANEUVILLE A BAYARD,48.5499615771,5.06880935835,265,,Bayard-sur-Marne,Bayard-sur-Marne,52,Haute-Marne,44,Grand Est
52265,BAYARD SUR MARNE,52170,BAYARD SUR MARNE,PREZ SUR MARNE,48.5499615771,5.06880935835,265,,Bayard-sur-Marne,Bayard-sur-Marne,52,Haute-Marne,44,Grand Est
52266,LANEUVILLE A REMY,52220,LANEUVILLE A REMY,,48.4723601077,4.87755196221,266,,Laneuville-à-Rémy,Laneuville-à-Rémy,52,Haute-Marne,44,Grand Est
52267,LANEUVILLE AU PONT,52100,LANEUVILLE AU PONT,,48.6239555487,4.86367125229,267,,Laneuville-au-Pont,Laneuville-au-Pont,52,Haute-Marne,44,Grand Est
52269,LANGRES,52200,LANGRES,,47.8591544821,5.33880466821,269,,Langres,Langres,52,Haute-Marne,44,Grand Est
52269,LANGRES,52200,LANGRES,CORLEE,47.8591544821,5.33880466821,269,,Langres,Langres,52,Haute-Marne,44,Grand Est
52271,LANQUES SUR ROGNON,52800,LANQUES SUR ROGNON,,48.0878260847,5.36870154442,271,,Lanques-sur-Rognon,Lanques-sur-Rognon,52,Haute-Marne,44,Grand Est
52272,LANTY SUR AUBE,52120,LANTY SUR AUBE,,48.0238372012,4.74848536778,272,,Lanty-sur-Aube,Lanty-sur-Aube,52,Haute-Marne,44,Grand Est
52273,LARIVIERE ARNONCOURT,52400,LARIVIERE ARNONCOURT,,48.0240430841,5.7182829587,273,,Larivière-Arnoncourt,Larivière-Arnoncourt,52,Haute-Marne,44,Grand Est
52273,LARIVIERE ARNONCOURT,52400,LARIVIERE ARNONCOURT,ARNONCOURT SUR APANCE,48.0240430841,5.7182829587,273,,Larivière-Arnoncourt,Larivière-Arnoncourt,52,Haute-Marne,44,Grand Est
52274,LATRECEY ORMOY SUR AUBE,52120,LATRECEY ORMOY SUR AUBE,,47.9862841495,4.84287471621,274,,Latrecey-Ormoy-sur-Aube,Latrecey-Ormoy-sur-Aube,52,Haute-Marne,44,Grand Est
52274,LATRECEY ORMOY SUR AUBE,52120,LATRECEY ORMOY SUR AUBE,ORMOY SUR AUBE,47.9862841495,4.84287471621,274,,Latrecey-Ormoy-sur-Aube,Latrecey-Ormoy-sur-Aube,52,Haute-Marne,44,Grand Est
52275,LAVERNOY,52140,LAVERNOY,,47.9161136655,5.57575568127,275,,Lavernoy,Lavernoy,52,Haute-Marne,44,Grand Est
52276,LAVILLE AUX BOIS,52000,LAVILLE AUX BOIS,,48.0896326089,5.23950555481,276,,Laville-aux-Bois,Laville-aux-Bois,52,Haute-Marne,44,Grand Est
52277,LAVILLENEUVE,52140,LAVILLENEUVE,,48.0373240885,5.51638574625,277,,Lavilleneuve,Lavilleneuve,52,Haute-Marne,44,Grand Est
52278,LAVILLENEUVE AU ROI,52330,LAVILLENEUVE AU ROI,,48.1629805805,4.9339217846,278,,Lavilleneuve-au-Roi,Lavilleneuve-au-Roi,52,Haute-Marne,44,Grand Est
52280,LECEY,52360,LECEY,,47.8635165634,5.43218978673,280,,Lecey,Lecey,52,Haute-Marne,44,Grand Est
52282,LEFFONDS,52210,LEFFONDS,,47.9812762101,5.14225572463,282,,Leffonds,Leffonds,52,Haute-Marne,44,Grand Est
52284,LESCHERES SUR LE BLAISERON,52110,LESCHERES SUR LE BLAISERON,,48.3371313417,5.03163673531,284,,Leschères-sur-le-Blaiseron,Leschères-sur-le-Blaiseron,52,Haute-Marne,44,Grand Est
52285,LEUCHEY,52190,LEUCHEY,,47.7280758728,5.21503608334,285,,Leuchey,Leuchey,52,Haute-Marne,44,Grand Est
52286,LEURVILLE,52700,LEURVILLE,,48.3369321376,5.3802826598,286,,Leurville,Leurville,52,Haute-Marne,44,Grand Est
52287,LEVECOURT,52150,LEVECOURT,,48.1358793289,5.56080225778,287,,Levécourt,Levécourt,52,Haute-Marne,44,Grand Est
52288,LEZEVILLE,52230,LEZEVILLE,,48.4275449909,5.39183251912,288,,Lezéville,Lezéville,52,Haute-Marne,44,Grand Est
52288,LEZEVILLE,52230,LEZEVILLE,HARMEVILLE,48.4275449909,5.39183251912,288,,Lezéville,Lezéville,52,Haute-Marne,44,Grand Est
52288,LEZEVILLE,52230,LEZEVILLE,LANEUVILLE AU BOIS,48.4275449909,5.39183251912,288,,Lezéville,Lezéville,52,Haute-Marne,44,Grand Est
52289,LIFFOL LE PETIT,52700,LIFFOL LE PETIT,,48.2910504229,5.54344687928,289,,Liffol-le-Petit,Liffol-le-Petit,52,Haute-Marne,44,Grand Est
52290,LES LOGES,52500,LES LOGES,,47.7728373639,5.48522225072,290,Les,Loges,Les Loges,52,Haute-Marne,44,Grand Est
52291,LONGCHAMP,52240,LONGCHAMP,,48.1264874008,5.44150373802,291,,Longchamp,Longchamp,52,Haute-Marne,44,Grand Est
52292,LONGEAU PERCEY,52250,LONGEAU PERCEY,,47.7600762777,5.31857922442,292,,Longeau-Percey,Longeau-Percey,52,Haute-Marne,44,Grand Est
52292,LONGEAU PERCEY,52250,LONGEAU PERCEY,LONGEAU,47.7600762777,5.31857922442,292,,Longeau-Percey,Longeau-Percey,52,Haute-Marne,44,Grand Est
52292,LONGEAU PERCEY,52250,LONGEAU PERCEY,PERCEY LE PAUTEL,47.7600762777,5.31857922442,292,,Longeau-Percey,Longeau-Percey,52,Haute-Marne,44,Grand Est
52294,LOUVEMONT,52130,LOUVEMONT,,48.5434019662,4.89152733992,294,,Louvemont,Louvemont,52,Haute-Marne,44,Grand Est
52295,LOUVIERES,52800,LOUVIERES,,48.0280199419,5.28378758426,295,,Louvières,Louvières,52,Haute-Marne,44,Grand Est
52297,LUZY SUR MARNE,52000,LUZY SUR MARNE,,48.0565587654,5.20017320213,297,,Luzy-sur-Marne,Luzy-sur-Marne,52,Haute-Marne,44,Grand Est
52298,MAATZ,52500,MAATZ,,47.7057969225,5.43384462311,298,,Maâtz,Maâtz,52,Haute-Marne,44,Grand Est
52300,MAGNEUX,52130,MAGNEUX,,48.5096354522,5.00776253025,300,,Magneux,Magneux,52,Haute-Marne,44,Grand Est
52301,MAISONCELLES,52240,MAISONCELLES,,48.1325423759,5.53375254528,301,,Maisoncelles,Maisoncelles,52,Haute-Marne,44,Grand Est
52302,MAIZIERES,52300,MAIZIERES,,48.4923765397,5.066636417,302,,Maizières,Maizières,52,Haute-Marne,44,Grand Est
52303,MAIZIERES SUR AMANCE,52500,MAIZIERES SUR AMANCE,,47.8353531495,5.60838290831,303,,Maizières-sur-Amance,Maizières-sur-Amance,52,Haute-Marne,44,Grand Est
52304,MALAINCOURT SUR MEUSE,52150,MALAINCOURT SUR MEUSE,,48.1553460415,5.6050993633,304,,Malaincourt-sur-Meuse,Malaincourt-sur-Meuse,52,Haute-Marne,44,Grand Est
52305,MANDRES LA COTE,52800,MANDRES LA COTE,,48.0688442092,5.33356686947,305,,Mandres-la-Côte,Mandres-la-Côte,52,Haute-Marne,44,Grand Est
52306,MANOIS,52700,MANOIS,,48.2657522932,5.37467760517,306,,Manois,Manois,52,Haute-Marne,44,Grand Est
52307,MARAC,52260,MARAC,,47.9337415191,5.17210164704,307,,Marac,Marac,52,Haute-Marne,44,Grand Est
52308,MARANVILLE,52370,MARANVILLE,,48.134833256,4.86564026031,308,,Maranville,Maranville,52,Haute-Marne,44,Grand Est
52310,MARBEVILLE,52320,MARBEVILLE,,48.2625993866,5.02188488237,310,,Marbéville,Marbéville,52,Haute-Marne,44,Grand Est
52311,MARCILLY EN BASSIGNY,52360,MARCILLY EN BASSIGNY,,47.8872207822,5.54794700045,311,,Marcilly-en-Bassigny,Marcilly-en-Bassigny,52,Haute-Marne,44,Grand Est
52312,MARDOR,52200,MARDOR,,47.8839324283,5.20979926063,312,,Mardor,Mardor,52,Haute-Marne,44,Grand Est
52313,MAREILLES,52700,MAREILLES,,48.1744777134,5.26234657635,313,,Mareilles,Mareilles,52,Haute-Marne,44,Grand Est
52315,MARNAY SUR MARNE,52800,MARNAY SUR MARNE,,48.0110920714,5.23259645475,315,,Marnay-sur-Marne,Marnay-sur-Marne,52,Haute-Marne,44,Grand Est
52316,MATHONS,52300,MATHONS,,48.4154342605,5.03852960319,316,,Mathons,Mathons,52,Haute-Marne,44,Grand Est
52318,MELAY,52400,MELAY,,47.8934170946,5.81934321302,318,,Melay,Melay,52,Haute-Marne,44,Grand Est
52319,MENNOUVEAUX,52240,MENNOUVEAUX,,48.1028804059,5.41153271084,319,,Mennouveaux,Mennouveaux,52,Haute-Marne,44,Grand Est
52320,MERREY,52240,MERREY,,48.052900906,5.59302877424,320,,Merrey,Merrey,52,Haute-Marne,44,Grand Est
52321,MERTRUD,52110,MERTRUD,,48.4253267868,4.8840209417,321,,Mertrud,Mertrud,52,Haute-Marne,44,Grand Est
52322,MEURES,52310,MEURES,,48.1878659479,5.06166389383,322,,Meures,Meures,52,Haute-Marne,44,Grand Est
52325,MILLIERES,52240,MILLIERES,,48.1421522918,5.42371010065,325,,Millières,Millières,52,Haute-Marne,44,Grand Est
52326,MIRBEL,52320,MIRBEL,,48.2896692671,5.04080217069,326,,Mirbel,Mirbel,52,Haute-Marne,44,Grand Est
52327,MOESLAINS,52100,MOESLAINS,,48.6169452822,4.88678829658,327,,Moëslains,Moëslains,52,Haute-Marne,44,Grand Est
52328,MONTCHARVOT,52400,MONTCHARVOT,,47.8999798972,5.72792351103,328,,Montcharvot,Montcharvot,52,Haute-Marne,44,Grand Est
52330,MONTHERIES,52330,MONTHERIES,,48.1797188872,4.90288673895,330,,Montheries,Montheries,52,Haute-Marne,44,Grand Est
52331,LA PORTE DU DER,52220,LA PORTE DU DER,MONTIER EN DER,48.4874732749,4.78668890124,331,La,Porte du Der,La Porte du Der,52,Haute-Marne,44,Grand Est
52331,LA PORTE DU DER,52220,LA PORTE DU DER,ROBERT MAGNY,48.4874732749,4.78668890124,331,La,Porte du Der,La Porte du Der,52,Haute-Marne,44,Grand Est
52332,VAL DE MEUSE,52140,VAL DE MEUSE,,48.007428702,5.53409668952,332,,Val-de-Meuse,Val-de-Meuse,52,Haute-Marne,44,Grand Est
52332,VAL DE MEUSE,52140,VAL DE MEUSE,EPINANT,48.007428702,5.53409668952,332,,Val-de-Meuse,Val-de-Meuse,52,Haute-Marne,44,Grand Est
52332,VAL DE MEUSE,52140,VAL DE MEUSE,LECOURT,48.007428702,5.53409668952,332,,Val-de-Meuse,Val-de-Meuse,52,Haute-Marne,44,Grand Est
52332,VAL DE MEUSE,52140,VAL DE MEUSE,MAULAIN,48.007428702,5.53409668952,332,,Val-de-Meuse,Val-de-Meuse,52,Haute-Marne,44,Grand Est
52332,VAL DE MEUSE,52140,VAL DE MEUSE,MONTIGNY LE ROI,48.007428702,5.53409668952,332,,Val-de-Meuse,Val-de-Meuse,52,Haute-Marne,44,Grand Est
52332,VAL DE MEUSE,52140,VAL DE MEUSE,PROVENCHERES SUR MEUSE,48.007428702,5.53409668952,332,,Val-de-Meuse,Val-de-Meuse,52,Haute-Marne,44,Grand Est
52332,VAL DE MEUSE,52140,VAL DE MEUSE,RAVENNEFONTAINES,48.007428702,5.53409668952,332,,Val-de-Meuse,Val-de-Meuse,52,Haute-Marne,44,Grand Est
52332,VAL DE MEUSE,52140,VAL DE MEUSE,RECOURT,48.007428702,5.53409668952,332,,Val-de-Meuse,Val-de-Meuse,52,Haute-Marne,44,Grand Est
52332,VAL DE MEUSE,52240,VAL DE MEUSE,LENIZEUL,48.007428702,5.53409668952,332,,Val-de-Meuse,Val-de-Meuse,52,Haute-Marne,44,Grand Est
52335,MONTOT SUR ROGNON,52700,MONTOT SUR ROGNON,,48.2846391815,5.29631206011,335,,Montot-sur-Rognon,Montot-sur-Rognon,52,Haute-Marne,44,Grand Est
52336,MONTREUIL SUR BLAISE,52130,MONTREUIL SUR BLAISE,,48.4762156736,4.95570987328,336,,Montreuil-sur-Blaise,Montreuil-sur-Blaise,52,Haute-Marne,44,Grand Est
52337,MONTREUIL SUR THONNANCE,52230,MONTREUIL SUR THONNANCE,,48.4595853523,5.23455952263,337,,Montreuil-sur-Thonnance,Montreuil-sur-Thonnance,52,Haute-Marne,44,Grand Est
52341,MORANCOURT,52110,MORANCOURT,,48.4298864114,5.0031491394,341,,Morancourt,Morancourt,52,Haute-Marne,44,Grand Est
52342,MORIONVILLIERS,52700,MORIONVILLIERS,,48.374977788,5.4107346516,342,,Morionvilliers,Morionvilliers,52,Haute-Marne,44,Grand Est
52344,MOUILLERON,52160,MOUILLERON,,47.6928820808,5.10711887788,344,,Mouilleron,Mouilleron,52,Haute-Marne,44,Grand Est
52346,MUSSEY SUR MARNE,52300,MUSSEY SUR MARNE,,48.3790388875,5.12685701678,346,,Mussey-sur-Marne,Mussey-sur-Marne,52,Haute-Marne,44,Grand Est
52347,NARCY,52170,NARCY,,48.5802972317,5.09906771087,347,,Narcy,Narcy,52,Haute-Marne,44,Grand Est
52348,NEUILLY L EVEQUE,52360,NEUILLY L EVEQUE,,47.9193052066,5.43856285337,348,,Neuilly-l'Évêque,Neuilly-l'Évêque,52,Haute-Marne,44,Grand Est
52349,NEUILLY SUR SUIZE,52000,NEUILLY SUR SUIZE,,48.0378682238,5.12863094565,349,,Neuilly-sur-Suize,Neuilly-sur-Suize,52,Haute-Marne,44,Grand Est
52350,NEUVELLE LES VOISEY,52400,NEUVELLE LES VOISEY,,47.8632118259,5.78592799086,350,,Neuvelle-lès-Voisey,Neuvelle-lès-Voisey,52,Haute-Marne,44,Grand Est
52351,NIJON,52150,NIJON,,48.195711108,5.64311984869,351,,Nijon,Nijon,52,Haute-Marne,44,Grand Est
52352,NINVILLE,52800,NINVILLE,,48.0718513418,5.43848531398,352,,Ninville,Ninville,52,Haute-Marne,44,Grand Est
52353,NOGENT,52800,NOGENT,,48.037521428,5.36502804198,353,,Nogent,Nogent,52,Haute-Marne,44,Grand Est
52353,NOGENT,52800,NOGENT,DONNEMARIE,48.037521428,5.36502804198,353,,Nogent,Nogent,52,Haute-Marne,44,Grand Est
52353,NOGENT,52800,NOGENT,ESSEY LES EAUX,48.037521428,5.36502804198,353,,Nogent,Nogent,52,Haute-Marne,44,Grand Est
52353,NOGENT,52800,NOGENT,ODIVAL,48.037521428,5.36502804198,353,,Nogent,Nogent,52,Haute-Marne,44,Grand Est
52354,NOIDANT CHATENOY,52600,NOIDANT CHATENOY,,47.7919589019,5.37791130968,354,,Noidant-Chatenoy,Noidant-Chatenoy,52,Haute-Marne,44,Grand Est
52355,NOIDANT LE ROCHEUX,52200,NOIDANT LE ROCHEUX,,47.822685302,5.25671203266,355,,Noidant-le-Rocheux,Noidant-le-Rocheux,52,Haute-Marne,44,Grand Est
52356,NOMECOURT,52300,NOMECOURT,,48.4374300268,5.07195448796,356,,Nomécourt,Nomécourt,52,Haute-Marne,44,Grand Est
52357,NONCOURT SUR LE RONGEANT,52230,NONCOURT SUR LE RONGEANT,,48.4173782579,5.25094367311,357,,Noncourt-sur-le-Rongeant,Noncourt-sur-le-Rongeant,52,Haute-Marne,44,Grand Est
52358,NOYERS,52240,NOYERS,,48.0613888303,5.48533764002,358,,Noyers,Noyers,52,Haute-Marne,44,Grand Est
52359,NULLY,52110,NULLY,,48.3704679464,4.81456093137,359,,Nully,Nully,52,Haute-Marne,44,Grand Est
52360,OCCEY,52190,OCCEY,,47.6040975571,5.27196740067,360,,Occey,Occey,52,Haute-Marne,44,Grand Est
52362,ORBIGNY AU MONT,52360,ORBIGNY AU MONT,,47.8851114034,5.46238446053,362,,Orbigny-au-Mont,Orbigny-au-Mont,52,Haute-Marne,44,Grand Est
52363,ORBIGNY AU VAL,52360,ORBIGNY AU VAL,,47.8832429719,5.42232556379,363,,Orbigny-au-Val,Orbigny-au-Val,52,Haute-Marne,44,Grand Est
52364,ORCEVAUX,52250,ORCEVAUX,,47.7743218186,5.27109137653,364,,Orcevaux,Orcevaux,52,Haute-Marne,44,Grand Est
52365,ORGES,52120,ORGES,,48.0782759639,4.93479857914,365,,Orges,Orges,52,Haute-Marne,44,Grand Est
52366,ORMANCEY,52200,ORMANCEY,,47.9057227647,5.17880695197,366,,Ormancey,Ormancey,52,Haute-Marne,44,Grand Est
52367,ORMOY LES SEXFONTAINES,52310,ORMOY LES SEXFONTAINES,,48.2263305102,5.05911873488,367,,Ormoy-lès-Sexfontaines,Ormoy-lès-Sexfontaines,52,Haute-Marne,44,Grand Est
52369,ORQUEVAUX,52700,ORQUEVAUX,,48.311099049,5.41479052752,369,,Orquevaux,Orquevaux,52,Haute-Marne,44,Grand Est
52370,OSNE LE VAL,52300,OSNE LE VAL,,48.4981170566,5.20118415597,370,,Osne-le-Val,Osne-le-Val,52,Haute-Marne,44,Grand Est
52371,OUDINCOURT,52310,OUDINCOURT,,48.2253154213,5.08932327892,371,,Oudincourt,Oudincourt,52,Haute-Marne,44,Grand Est
52372,OUTREMECOURT,52150,OUTREMECOURT,,48.2159872582,5.69127519244,372,,Outremécourt,Outremécourt,52,Haute-Marne,44,Grand Est
52373,OZIERES,52700,OZIERES,,48.1695665258,5.47158712619,373,,Ozières,Ozières,52,Haute-Marne,44,Grand Est
52374,LE PAILLY,52600,LE PAILLY,,47.7864627616,5.41163164594,374,Le,Pailly,Le Pailly,52,Haute-Marne,44,Grand Est
52375,PALAISEUL,52600,PALAISEUL,,47.7669628042,5.41731891936,375,,Palaiseul,Palaiseul,52,Haute-Marne,44,Grand Est
52376,PANSEY,52230,PANSEY,,48.4772456107,5.28155771235,376,,Pansey,Pansey,52,Haute-Marne,44,Grand Est
52377,PARNOY EN BASSIGNY,52400,PARNOY EN BASSIGNY,,48.0252868226,5.6605141849,377,,Parnoy-en-Bassigny,Parnoy-en-Bassigny,52,Haute-Marne,44,Grand Est
52377,PARNOY EN BASSIGNY,52400,PARNOY EN BASSIGNY,FRESNOY EN BASSIGNY,48.0252868226,5.6605141849,377,,Parnoy-en-Bassigny,Parnoy-en-Bassigny,52,Haute-Marne,44,Grand Est
52377,PARNOY EN BASSIGNY,52400,PARNOY EN BASSIGNY,PARNOT,48.0252868226,5.6605141849,377,,Parnoy-en-Bassigny,Parnoy-en-Bassigny,52,Haute-Marne,44,Grand Est
52378,PAROY SUR SAULX,52300,PAROY SUR SAULX,,48.5149155599,5.24765880237,378,,Paroy-sur-Saulx,Paroy-sur-Saulx,52,Haute-Marne,44,Grand Est
52380,PEIGNEY,52200,PEIGNEY,,47.8784687071,5.37568618034,380,,Peigney,Peigney,52,Haute-Marne,44,Grand Est
52383,PERRANCEY LES VIEUX MOULINS,52200,PERRANCEY LES VIEUX MOULINS,,47.8567750757,5.27470200366,383,,Perrancey-les-Vieux-Moulins,Perrancey-les-Vieux-Moulins,52,Haute-Marne,44,Grand Est
52383,PERRANCEY LES VIEUX MOULINS,52200,PERRANCEY LES VIEUX MOULINS,VIEUX MOULINS,47.8567750757,5.27470200366,383,,Perrancey-les-Vieux-Moulins,Perrancey-les-Vieux-Moulins,52,Haute-Marne,44,Grand Est
52384,PERROGNEY LES FONTAINES,52160,PERROGNEY LES FONTAINES,,47.8046627431,5.20244650135,384,,Perrogney-les-Fontaines,Perrogney-les-Fontaines,52,Haute-Marne,44,Grand Est
52384,PERROGNEY LES FONTAINES,52160,PERROGNEY LES FONTAINES,PIERREFONTAINES,47.8046627431,5.20244650135,384,,Perrogney-les-Fontaines,Perrogney-les-Fontaines,52,Haute-Marne,44,Grand Est
52385,PERRUSSE,52240,PERRUSSE,,48.0969131081,5.47804670166,385,,Perrusse,Perrusse,52,Haute-Marne,44,Grand Est
52386,PERTHES,52100,PERTHES,,48.6633775695,4.81457659756,386,,Perthes,Perthes,52,Haute-Marne,44,Grand Est
52388,PIERREMONT SUR AMANCE,52500,PIERREMONT SUR AMANCE,,47.8040669012,5.66061764392,388,,Pierremont-sur-Amance,Pierremont-sur-Amance,52,Haute-Marne,44,Grand Est
52388,PIERREMONT SUR AMANCE,52500,PIERREMONT SUR AMANCE,MONTESSON,47.8040669012,5.66061764392,388,,Pierremont-sur-Amance,Pierremont-sur-Amance,52,Haute-Marne,44,Grand Est
52388,PIERREMONT SUR AMANCE,52500,PIERREMONT SUR AMANCE,PIERREFAITES,47.8040669012,5.66061764392,388,,Pierremont-sur-Amance,Pierremont-sur-Amance,52,Haute-Marne,44,Grand Est
52390,PISSELOUP,52500,PISSELOUP,,47.8328972062,5.73978482099,390,,Pisseloup,Pisseloup,52,Haute-Marne,44,Grand Est
52391,PLANRUPT,52220,PLANRUPT,,48.512744399,4.78886303175,391,,Planrupt,Planrupt,52,Haute-Marne,44,Grand Est
52392,PLESNOY,52360,PLESNOY,,47.892875539,5.49151963209,392,,Plesnoy,Plesnoy,52,Haute-Marne,44,Grand Est
52393,POINSENOT,52160,POINSENOT,,47.7161433717,5.00835250709,393,,Poinsenot,Poinsenot,52,Haute-Marne,44,Grand Est
52394,POINSON LES FAYL,52500,POINSON LES FAYL,,47.7498603171,5.60394451287,394,,Poinson-lès-Fayl,Poinson-lès-Fayl,52,Haute-Marne,44,Grand Est
52395,POINSON LES GRANCEY,52160,POINSON LES GRANCEY,,47.7081445805,4.97920656681,395,,Poinson-lès-Grancey,Poinson-lès-Grancey,52,Haute-Marne,44,Grand Est
52396,POINSON LES NOGENT,52800,POINSON LES NOGENT,,47.9916180616,5.37946091532,396,,Poinson-lès-Nogent,Poinson-lès-Nogent,52,Haute-Marne,44,Grand Est
52397,POISEUL,52360,POISEUL,,47.9229870743,5.49096839108,397,,Poiseul,Poiseul,52,Haute-Marne,44,Grand Est
52398,POISSONS,52230,POISSONS,,48.4239473612,5.21581768919,398,,Poissons,Poissons,52,Haute-Marne,44,Grand Est
52399,PONT LA VILLE,52120,PONT LA VILLE,,48.081638175,4.87120903047,399,,Pont-la-Ville,Pont-la-Ville,52,Haute-Marne,44,Grand Est
52400,LE CHATELET SUR MEUSE,52400,LE CHATELET SUR MEUSE,,47.9735467362,5.63993048506,400,Le,Châtelet-sur-Meuse,Le Châtelet-sur-Meuse,52,Haute-Marne,44,Grand Est
52400,LE CHATELET SUR MEUSE,52400,LE CHATELET SUR MEUSE,BEAUCHARMOY,47.9735467362,5.63993048506,400,Le,Châtelet-sur-Meuse,Le Châtelet-sur-Meuse,52,Haute-Marne,44,Grand Est
52400,LE CHATELET SUR MEUSE,52400,LE CHATELET SUR MEUSE,POUILLY EN BASSIGNY,47.9735467362,5.63993048506,400,Le,Châtelet-sur-Meuse,Le Châtelet-sur-Meuse,52,Haute-Marne,44,Grand Est
52401,POULANGY,52800,POULANGY,,48.0490763466,5.25648006741,401,,Poulangy,Poulangy,52,Haute-Marne,44,Grand Est
52403,PRASLAY,52160,PRASLAY,,47.7358613239,5.10485637494,403,,Praslay,Praslay,52,Haute-Marne,44,Grand Est
52405,LE MONTSAUGEONNAIS,52190,LE MONTSAUGEONNAIS,MONTSAUGEON,47.6875295793,5.2920187848,405,Le,Montsaugeonnais,Le Montsaugeonnais,52,Haute-Marne,44,Grand Est
52405,LE MONTSAUGEONNAIS,52190,LE MONTSAUGEONNAIS,PRAUTHOY,47.6875295793,5.2920187848,405,Le,Montsaugeonnais,Le Montsaugeonnais,52,Haute-Marne,44,Grand Est
52405,LE MONTSAUGEONNAIS,52190,LE MONTSAUGEONNAIS,VAUX SOUS AUBIGNY,47.6875295793,5.2920187848,405,Le,Montsaugeonnais,Le Montsaugeonnais,52,Haute-Marne,44,Grand Est
52406,PRESSIGNY,52500,PRESSIGNY,,47.7470915899,5.67005264983,406,,Pressigny,Pressigny,52,Haute-Marne,44,Grand Est
52407,PREZ SOUS LAFAUCHE,52700,PREZ SOUS LAFAUCHE,,48.2760804704,5.50328753513,407,,Prez-sous-Lafauche,Prez-sous-Lafauche,52,Haute-Marne,44,Grand Est
52411,RIVES DERVOISES,52220,RIVES DERVOISES,DROYES,48.489304653,4.6870081083,411,,Rives Dervoises,Rives Dervoises,52,Haute-Marne,44,Grand Est
52411,RIVES DERVOISES,52220,RIVES DERVOISES,LONGEVILLE SUR LA LAINES,48.489304653,4.6870081083,411,,Rives Dervoises,Rives Dervoises,52,Haute-Marne,44,Grand Est
52411,RIVES DERVOISES,52220,RIVES DERVOISES,LOUZE,48.489304653,4.6870081083,411,,Rives Dervoises,Rives Dervoises,52,Haute-Marne,44,Grand Est
52411,RIVES DERVOISES,52220,RIVES DERVOISES,PUELLEMONTIER,48.489304653,4.6870081083,411,,Rives Dervoises,Rives Dervoises,52,Haute-Marne,44,Grand Est
52413,RACHECOURT SUZEMONT,52130,RACHECOURT SUZEMONT,,48.4553637737,4.97053155021,413,,Rachecourt-Suzémont,Rachecourt-Suzémont,52,Haute-Marne,44,Grand Est
52414,RACHECOURT SUR MARNE,52170,RACHECOURT SUR MARNE,,48.5190528546,5.0924613247,414,,Rachecourt-sur-Marne,Rachecourt-sur-Marne,52,Haute-Marne,44,Grand Est
52415,RANCONNIERES,52140,RANCONNIERES,,47.9411957897,5.55892920828,415,,Rançonnières,Rançonnières,52,Haute-Marne,44,Grand Est
52416,RANGECOURT,52140,RANGECOURT,,48.0432441848,5.48652377409,416,,Rangecourt,Rangecourt,52,Haute-Marne,44,Grand Est
52419,RENNEPONT,52370,RENNEPONT,,48.1641670802,4.85864447421,419,,Rennepont,Rennepont,52,Haute-Marne,44,Grand Est
52420,REYNEL,52700,REYNEL,,48.3005429211,5.33940771996,420,,Reynel,Reynel,52,Haute-Marne,44,Grand Est
52421,RIAUCOURT,52000,RIAUCOURT,,48.1733717496,5.16933990762,421,,Riaucourt,Riaucourt,52,Haute-Marne,44,Grand Est
52422,RICHEBOURG,52120,RICHEBOURG,,48.0095629662,5.06048237651,422,,Richebourg,Richebourg,52,Haute-Marne,44,Grand Est
52423,RIMAUCOURT,52700,RIMAUCOURT,,48.2480091505,5.34969223549,423,,Rimaucourt,Rimaucourt,52,Haute-Marne,44,Grand Est
52424,RIVIERES LE BOIS,52600,RIVIERES LE BOIS,,47.7413650111,5.45056321037,424,,Rivières-le-Bois,Rivières-le-Bois,52,Haute-Marne,44,Grand Est
52425,RIVIERE LES FOSSES,52190,RIVIERE LES FOSSES,,47.6496133771,5.22678234152,425,,Rivière-les-Fosses,Rivière-les-Fosses,52,Haute-Marne,44,Grand Est
52426,RIZAUCOURT BUCHEY,52330,RIZAUCOURT BUCHEY,,48.2744216566,4.86924772223,426,,Rizaucourt-Buchey,Rizaucourt-Buchey,52,Haute-Marne,44,Grand Est
52426,RIZAUCOURT BUCHEY,52330,RIZAUCOURT BUCHEY,BUCHEY,48.2744216566,4.86924772223,426,,Rizaucourt-Buchey,Rizaucourt-Buchey,52,Haute-Marne,44,Grand Est
52428,ROCHEFORT SUR LA COTE,52700,ROCHEFORT SUR LA COTE,,48.226286585,5.21152794505,428,,Rochefort-sur-la-Côte,Rochefort-sur-la-Côte,52,Haute-Marne,44,Grand Est
52429,ROCHES SUR MARNE,52410,ROCHES SUR MARNE,,48.6001078186,5.01242215662,429,,Roches-sur-Marne,Roches-sur-Marne,52,Haute-Marne,44,Grand Est
52431,ROCHETAILLEE,52210,ROCHETAILLEE,,47.848841047,5.11949041982,431,,Rochetaillée,Rochetaillée,52,Haute-Marne,44,Grand Est
52431,ROCHETAILLEE,52210,ROCHETAILLEE,CHAMEROY,47.848841047,5.11949041982,431,,Rochetaillée,Rochetaillée,52,Haute-Marne,44,Grand Est
52432,ROLAMPONT,52260,ROLAMPONT,,47.9506169361,5.30859356776,432,,Rolampont,Rolampont,52,Haute-Marne,44,Grand Est
52432,ROLAMPONT,52260,ROLAMPONT,CHARMOILLES,47.9506169361,5.30859356776,432,,Rolampont,Rolampont,52,Haute-Marne,44,Grand Est
52432,ROLAMPONT,52260,ROLAMPONT,LANNES,47.9506169361,5.30859356776,432,,Rolampont,Rolampont,52,Haute-Marne,44,Grand Est
52432,ROLAMPONT,52260,ROLAMPONT,TRONCHOY,47.9506169361,5.30859356776,432,,Rolampont,Rolampont,52,Haute-Marne,44,Grand Est
52433,ROMAIN SUR MEUSE,52150,ROMAIN SUR MEUSE,,48.1863459188,5.51316926986,433,,Romain-sur-Meuse,Romain-sur-Meuse,52,Haute-Marne,44,Grand Est
52436,ROUECOURT,52320,ROUECOURT,,48.328615744,5.07589658302,436,,Rouécourt,Rouécourt,52,Haute-Marne,44,Grand Est
52437,ROUELLES,52160,ROUELLES,,47.8032782742,5.0879485638,437,,Rouelles,Rouelles,52,Haute-Marne,44,Grand Est
52438,ROUGEUX,52500,ROUGEUX,,47.8099540801,5.57305964854,438,,Rougeux,Rougeux,52,Haute-Marne,44,Grand Est
52439,ROUVRES SUR AUBE,52160,ROUVRES SUR AUBE,,47.8608869669,4.98793387974,439,,Rouvres-sur-Aube,Rouvres-sur-Aube,52,Haute-Marne,44,Grand Est
52440,ROUVROY SUR MARNE,52300,ROUVROY SUR MARNE,,48.3600369184,5.10947647053,440,,Rouvroy-sur-Marne,Rouvroy-sur-Marne,52,Haute-Marne,44,Grand Est
52442,RUPT,52300,RUPT,,48.4215002883,5.13093271018,442,,Rupt,Rupt,52,Haute-Marne,44,Grand Est
52443,SAILLY,52230,SAILLY,,48.4356912773,5.27218061021,443,,Sailly,Sailly,52,Haute-Marne,44,Grand Est
52444,ST BLIN,52700,ST BLIN,,48.2512823872,5.41681321098,444,,Saint-Blin,Saint-Blin,52,Haute-Marne,44,Grand Est
52445,ST BROINGT LE BOIS,52190,ST BROINGT LE BOIS,,47.7284500611,5.41889569327,445,,Saint-Broingt-le-Bois,Saint-Broingt-le-Bois,52,Haute-Marne,44,Grand Est
52446,ST BROINGT LES FOSSES,52190,ST BROINGT LES FOSSES,,47.7136962549,5.26813340342,446,,Saint-Broingt-les-Fosses,Saint-Broingt-les-Fosses,52,Haute-Marne,44,Grand Est
52447,ST CIERGUES,52200,ST CIERGUES,,47.8811134277,5.2558356921,447,,Saint-Ciergues,Saint-Ciergues,52,Haute-Marne,44,Grand Est
52448,ST DIZIER,52100,ST DIZIER,,48.6280100668,4.94923523578,448,,Saint-Dizier,Saint-Dizier,52,Haute-Marne,44,Grand Est
52449,SAINTS GEOSMES,52200,SAINTS GEOSMES,,47.8274545004,5.31702793629,449,,Saints-Geosmes,Saints-Geosmes,52,Haute-Marne,44,Grand Est
52449,SAINTS GEOSMES,52200,SAINTS GEOSMES,BALESMES SUR MARNE,47.8274545004,5.31702793629,449,,Saints-Geosmes,Saints-Geosmes,52,Haute-Marne,44,Grand Est
52450,ST LOUP SUR AUJON,52210,ST LOUP SUR AUJON,,47.8798017799,5.07916418859,450,,Saint-Loup-sur-Aujon,Saint-Loup-sur-Aujon,52,Haute-Marne,44,Grand Est
52450,ST LOUP SUR AUJON,52210,ST LOUP SUR AUJON,COURCELLES SUR AUJON,47.8798017799,5.07916418859,450,,Saint-Loup-sur-Aujon,Saint-Loup-sur-Aujon,52,Haute-Marne,44,Grand Est
52450,ST LOUP SUR AUJON,52210,ST LOUP SUR AUJON,ERISEUL,47.8798017799,5.07916418859,450,,Saint-Loup-sur-Aujon,Saint-Loup-sur-Aujon,52,Haute-Marne,44,Grand Est
52452,ST MARTIN LES LANGRES,52200,ST MARTIN LES LANGRES,,47.896671428,5.26019164896,452,,Saint-Martin-lès-Langres,Saint-Martin-lès-Langres,52,Haute-Marne,44,Grand Est
52453,ST MAURICE,52200,ST MAURICE,,47.8492351289,5.41520627627,453,,Saint-Maurice,Saint-Maurice,52,Haute-Marne,44,Grand Est
52455,ST THIEBAULT,52150,ST THIEBAULT,,48.2003690234,5.57648587847,455,,Saint-Thiébault,Saint-Thiébault,52,Haute-Marne,44,Grand Est
52456,ST URBAIN MACONCOURT,52300,ST URBAIN MACONCOURT,,48.3964570951,5.19639597902,456,,Saint-Urbain-Maconcourt,Saint-Urbain-Maconcourt,52,Haute-Marne,44,Grand Est
52456,ST URBAIN MACONCOURT,52300,ST URBAIN MACONCOURT,MACONCOURT,48.3964570951,5.19639597902,456,,Saint-Urbain-Maconcourt,Saint-Urbain-Maconcourt,52,Haute-Marne,44,Grand Est
52457,ST VALLIER SUR MARNE,52200,ST VALLIER SUR MARNE,,47.8347894371,5.40182533022,457,,Saint-Vallier-sur-Marne,Saint-Vallier-sur-Marne,52,Haute-Marne,44,Grand Est
52459,SARCEY,52800,SARCEY,,48.057274539,5.29800666302,459,,Sarcey,Sarcey,52,Haute-Marne,44,Grand Est
52461,SARREY,52140,SARREY,,48.0018035882,5.42481168818,461,,Sarrey,Sarrey,52,Haute-Marne,44,Grand Est
52463,SAUDRON,52230,SAUDRON,,48.4947761197,5.32100791464,463,,Saudron,Saudron,52,Haute-Marne,44,Grand Est
52464,SAULLES,52500,SAULLES,,47.7012031937,5.52842395456,464,,Saulles,Saulles,52,Haute-Marne,44,Grand Est
52465,SAULXURES,52140,SAULXURES,,47.955255252,5.57978520657,465,,Saulxures,Saulxures,52,Haute-Marne,44,Grand Est
52467,SAVIGNY,52500,SAVIGNY,,47.7178107179,5.64399328349,467,,Savigny,Savigny,52,Haute-Marne,44,Grand Est
52468,SEMILLY,52700,SEMILLY,,48.2505992806,5.46434760219,468,,Semilly,Semilly,52,Haute-Marne,44,Grand Est
52469,SEMOUTIERS MONTSAON,52000,SEMOUTIERS MONTSAON,,48.0638816661,5.05420561217,469,,Semoutiers-Montsaon,Semoutiers-Montsaon,52,Haute-Marne,44,Grand Est
52469,SEMOUTIERS MONTSAON,52000,SEMOUTIERS MONTSAON,MONTSAON,48.0638816661,5.05420561217,469,,Semoutiers-Montsaon,Semoutiers-Montsaon,52,Haute-Marne,44,Grand Est
52470,SERQUEUX,52400,SERQUEUX,,47.9966571508,5.75100246622,470,,Serqueux,Serqueux,52,Haute-Marne,44,Grand Est
52472,SEXFONTAINES,52330,SEXFONTAINES,,48.20974327,5.02878676099,472,,Sexfontaines,Sexfontaines,52,Haute-Marne,44,Grand Est
52473,SIGNEVILLE,52700,SIGNEVILLE,,48.2656773353,5.266565256,473,,Signéville,Signéville,52,Haute-Marne,44,Grand Est
52474,SILVAROUVRES,52120,SILVAROUVRES,,48.0618671385,4.79407821948,474,,Silvarouvres,Silvarouvres,52,Haute-Marne,44,Grand Est
52475,SOMMANCOURT,52130,SOMMANCOURT,,48.5043560875,5.03760918175,475,,Sommancourt,Sommancourt,52,Haute-Marne,44,Grand Est
52476,SOMMERECOURT,52150,SOMMERECOURT,,48.2241761343,5.65159771886,476,,Sommerécourt,Sommerécourt,52,Haute-Marne,44,Grand Est
52479,SOMMEVOIRE,52220,SOMMEVOIRE,,48.4099270669,4.83318653827,479,,Sommevoire,Sommevoire,52,Haute-Marne,44,Grand Est
52479,SOMMEVOIRE,52220,SOMMEVOIRE,ROZIERES,48.4099270669,4.83318653827,479,,Sommevoire,Sommevoire,52,Haute-Marne,44,Grand Est
52480,SONCOURT SUR MARNE,52320,SONCOURT SUR MARNE,,48.2487991823,5.091834409,480,,Soncourt-sur-Marne,Soncourt-sur-Marne,52,Haute-Marne,44,Grand Est
52482,SOULAUCOURT SUR MOUZON,52150,SOULAUCOURT SUR MOUZON,,48.1954396501,5.69449914292,482,,Soulaucourt-sur-Mouzon,Soulaucourt-sur-Mouzon,52,Haute-Marne,44,Grand Est
52483,SOYERS,52400,SOYERS,,47.8695105416,5.69390932987,483,,Soyers,Soyers,52,Haute-Marne,44,Grand Est
52484,SUZANNECOURT,52300,SUZANNECOURT,,48.4423356345,5.18183107876,484,,Suzannecourt,Suzannecourt,52,Haute-Marne,44,Grand Est
52486,TERNAT,52210,TERNAT,,47.91358833,5.12761461513,486,,Ternat,Ternat,52,Haute-Marne,44,Grand Est
52487,THILLEUX,52220,THILLEUX,,48.4342468946,4.79056251456,487,,Thilleux,Thilleux,52,Haute-Marne,44,Grand Est
52488,THIVET,52800,THIVET,,47.9868961501,5.29966490039,488,,Thivet,Thivet,52,Haute-Marne,44,Grand Est
52489,THOL LES MILLIERES,52240,THOL LES MILLIERES,,48.1470755546,5.47884380766,489,,Thol-lès-Millières,Thol-lès-Millières,52,Haute-Marne,44,Grand Est
52490,THONNANCE LES JOINVILLE,52300,THONNANCE LES JOINVILLE,,48.4622070885,5.18737757983,490,,Thonnance-lès-Joinville,Thonnance-lès-Joinville,52,Haute-Marne,44,Grand Est
52491,THONNANCE LES MOULINS,52230,THONNANCE LES MOULINS,,48.4195754486,5.31434602015,491,,Thonnance-les-Moulins,Thonnance-les-Moulins,52,Haute-Marne,44,Grand Est
52491,THONNANCE LES MOULINS,52230,THONNANCE LES MOULINS,BRESSONCOURT,48.4195754486,5.31434602015,491,,Thonnance-les-Moulins,Thonnance-les-Moulins,52,Haute-Marne,44,Grand Est
52491,THONNANCE LES MOULINS,52230,THONNANCE LES MOULINS,BROUTHIERES,48.4195754486,5.31434602015,491,,Thonnance-les-Moulins,Thonnance-les-Moulins,52,Haute-Marne,44,Grand Est
52491,THONNANCE LES MOULINS,52230,THONNANCE LES MOULINS,SOULAINCOURT,48.4195754486,5.31434602015,491,,Thonnance-les-Moulins,Thonnance-les-Moulins,52,Haute-Marne,44,Grand Est
52492,TORCENAY,52600,TORCENAY,,47.8148282196,5.46932272187,492,,Torcenay,Torcenay,52,Haute-Marne,44,Grand Est
52493,TORNAY,52500,TORNAY,,47.6940487968,5.59873122424,493,,Tornay,Tornay,52,Haute-Marne,44,Grand Est
52494,TREIX,52000,TREIX,,48.1414838273,5.21369102942,494,,Treix,Treix,52,Haute-Marne,44,Grand Est
52495,TREMILLY,52110,TREMILLY,,48.3787256289,4.77481889669,495,,Trémilly,Trémilly,52,Haute-Marne,44,Grand Est
52497,TROISFONTAINES LA VILLE,52130,TROISFONTAINES LA VILLE,,48.5429240485,5.00777358148,497,,Troisfontaines-la-Ville,Troisfontaines-la-Ville,52,Haute-Marne,44,Grand Est
52497,TROISFONTAINES LA VILLE,52130,TROISFONTAINES LA VILLE,AVRAINVILLE,48.5429240485,5.00777358148,497,,Troisfontaines-la-Ville,Troisfontaines-la-Ville,52,Haute-Marne,44,Grand Est
52497,TROISFONTAINES LA VILLE,52130,TROISFONTAINES LA VILLE,FLORNOY,48.5429240485,5.00777358148,497,,Troisfontaines-la-Ville,Troisfontaines-la-Ville,52,Haute-Marne,44,Grand Est
52497,TROISFONTAINES LA VILLE,52130,TROISFONTAINES LA VILLE,VILLIERS AUX BOIS,48.5429240485,5.00777358148,497,,Troisfontaines-la-Ville,Troisfontaines-la-Ville,52,Haute-Marne,44,Grand Est
52499,VAILLANT,52160,VAILLANT,,47.7152758836,5.15527857753,499,,Vaillant,Vaillant,52,Haute-Marne,44,Grand Est
52500,VALCOURT,52100,VALCOURT,,48.6111128881,4.9125468368,500,,Valcourt,Valcourt,52,Haute-Marne,44,Grand Est
52502,VALLERET,52130,VALLERET,,48.4875952404,5.01041992627,502,,Valleret,Valleret,52,Haute-Marne,44,Grand Est
52503,VALLEROY,52500,VALLEROY,,47.6979256201,5.66002905454,503,,Valleroy,Valleroy,52,Haute-Marne,44,Grand Est
52504,VARENNES SUR AMANCE,52400,VARENNES SUR AMANCE,,47.8958656756,5.61137460007,504,,Varennes-sur-Amance,Varennes-sur-Amance,52,Haute-Marne,44,Grand Est
52505,VAUDRECOURT,52150,VAUDRECOURT,,48.2083534,5.64678700964,505,,Vaudrecourt,Vaudrecourt,52,Haute-Marne,44,Grand Est
52506,VAUDREMONT,52330,VAUDREMONT,,48.1318724255,4.91034777098,506,,Vaudrémont,Vaudrémont,52,Haute-Marne,44,Grand Est
52507,VAUXBONS,52200,VAUXBONS,,47.8824954846,5.13949772489,507,,Vauxbons,Vauxbons,52,Haute-Marne,44,Grand Est
52510,VAUX SUR BLAISE,52130,VAUX SUR BLAISE,,48.4691630131,4.96829847611,510,,Vaux-sur-Blaise,Vaux-sur-Blaise,52,Haute-Marne,44,Grand Est
52511,VAUX SUR ST URBAIN,52300,VAUX SUR ST URBAIN,,48.3710588558,5.2078654933,511,,Vaux-sur-Saint-Urbain,Vaux-sur-Saint-Urbain,52,Haute-Marne,44,Grand Est
52512,VECQUEVILLE,52300,VECQUEVILLE,,48.4603991907,5.13494669336,512,,Vecqueville,Vecqueville,52,Haute-Marne,44,Grand Est
52513,VELLES,52500,VELLES,,47.8338089389,5.72379027286,513,,Velles,Velles,52,Haute-Marne,44,Grand Est
52514,VERBIESLES,52000,VERBIESLES,,48.0755954132,5.18833921357,514,,Verbiesles,Verbiesles,52,Haute-Marne,44,Grand Est
52515,VERSEILLES LE BAS,52250,VERSEILLES LE BAS,,47.7610576364,5.28486080198,515,,Verseilles-le-Bas,Verseilles-le-Bas,52,Haute-Marne,44,Grand Est
52516,VERSEILLES LE HAUT,52250,VERSEILLES LE HAUT,,47.7704909127,5.29253229775,516,,Verseilles-le-Haut,Verseilles-le-Haut,52,Haute-Marne,44,Grand Est
52517,VESAIGNES SOUS LAFAUCHE,52700,VESAIGNES SOUS LAFAUCHE,,48.284900446,5.44400289784,517,,Vesaignes-sous-Lafauche,Vesaignes-sous-Lafauche,52,Haute-Marne,44,Grand Est
52518,VESAIGNES SUR MARNE,52800,VESAIGNES SUR MARNE,,48.0004981057,5.26348033194,518,,Vesaignes-sur-Marne,Vesaignes-sur-Marne,52,Haute-Marne,44,Grand Est
52519,VESVRES SOUS CHALANCEY,52190,VESVRES SOUS CHALANCEY,,47.6952316016,5.17073363819,519,,Vesvres-sous-Chalancey,Vesvres-sous-Chalancey,52,Haute-Marne,44,Grand Est
52520,VICQ,52400,VICQ,,47.9269296373,5.61019361194,520,,Vicq,Vicq,52,Haute-Marne,44,Grand Est
52522,VIEVILLE,52310,VIEVILLE,,48.2402822332,5.15871985577,522,,Viéville,Viéville,52,Haute-Marne,44,Grand Est
52523,VIGNES LA COTE,52700,VIGNES LA COTE,,48.2716451194,5.31184668552,523,,Vignes-la-Côte,Vignes-la-Côte,52,Haute-Marne,44,Grand Est
52524,VIGNORY,52320,VIGNORY,,48.2756379493,5.08951990521,524,,Vignory,Vignory,52,Haute-Marne,44,Grand Est
52525,VILLARS EN AZOIS,52120,VILLARS EN AZOIS,,48.0697572489,4.71940908337,525,,Villars-en-Azois,Villars-en-Azois,52,Haute-Marne,44,Grand Est
52526,VILLARS SANTENOGE,52160,VILLARS SANTENOGE,,47.744268654,4.99918998244,526,,Villars-Santenoge,Villars-Santenoge,52,Haute-Marne,44,Grand Est
52526,VILLARS SANTENOGE,52160,VILLARS SANTENOGE,SANTENOGE,47.744268654,4.99918998244,526,,Villars-Santenoge,Villars-Santenoge,52,Haute-Marne,44,Grand Est
52526,VILLARS SANTENOGE,52160,VILLARS SANTENOGE,VILLARS MONTROYER,47.744268654,4.99918998244,526,,Villars-Santenoge,Villars-Santenoge,52,Haute-Marne,44,Grand Est
52528,VILLE EN BLAISOIS,52130,VILLE EN BLAISOIS,,48.4412669126,4.95178954559,528,,Ville-en-Blaisois,Ville-en-Blaisois,52,Haute-Marne,44,Grand Est
52529,VILLEGUSIEN LE LAC,52190,VILLEGUSIEN LE LAC,,47.7309169271,5.31298003747,529,,Villegusien-le-Lac,Villegusien-le-Lac,52,Haute-Marne,44,Grand Est
52529,VILLEGUSIEN LE LAC,52190,VILLEGUSIEN LE LAC,PIEPAPE,47.7309169271,5.31298003747,529,,Villegusien-le-Lac,Villegusien-le-Lac,52,Haute-Marne,44,Grand Est
52529,VILLEGUSIEN LE LAC,52190,VILLEGUSIEN LE LAC,PRANGEY,47.7309169271,5.31298003747,529,,Villegusien-le-Lac,Villegusien-le-Lac,52,Haute-Marne,44,Grand Est
52529,VILLEGUSIEN LE LAC,52190,VILLEGUSIEN LE LAC,ST MICHEL,47.7309169271,5.31298003747,529,,Villegusien-le-Lac,Villegusien-le-Lac,52,Haute-Marne,44,Grand Est
52529,VILLEGUSIEN LE LAC,52600,VILLEGUSIEN LE LAC,HEUILLEY COTTON,47.7309169271,5.31298003747,529,,Villegusien-le-Lac,Villegusien-le-Lac,52,Haute-Marne,44,Grand Est
52534,VILLIERS EN LIEU,52100,VILLIERS EN LIEU,,48.6683263529,4.90563674583,534,,Villiers-en-Lieu,Villiers-en-Lieu,52,Haute-Marne,44,Grand Est
52535,VILLIERS LE SEC,52000,VILLIERS LE SEC,,48.1017851333,5.07195698997,535,,Villiers-le-Sec,Villiers-le-Sec,52,Haute-Marne,44,Grand Est
52536,VILLIERS LES APREY,52190,VILLIERS LES APREY,,47.7515735752,5.21966019441,536,,Villiers-lès-Aprey,Villiers-lès-Aprey,52,Haute-Marne,44,Grand Est
52538,VILLIERS SUR SUIZE,52210,VILLIERS SUR SUIZE,,47.9779580006,5.19631550116,538,,Villiers-sur-Suize,Villiers-sur-Suize,52,Haute-Marne,44,Grand Est
52539,VIOLOT,52600,VIOLOT,,47.7591470911,5.44427189873,539,,Violot,Violot,52,Haute-Marne,44,Grand Est
52540,VITRY EN MONTAGNE,52160,VITRY EN MONTAGNE,,47.8259360589,5.0931358206,540,,Vitry-en-Montagne,Vitry-en-Montagne,52,Haute-Marne,44,Grand Est
52541,VITRY LES NOGENT,52800,VITRY LES NOGENT,,47.9866886616,5.3434828824,541,,Vitry-lès-Nogent,Vitry-lès-Nogent,52,Haute-Marne,44,Grand Est
52542,VIVEY,52160,VIVEY,,47.7373432906,5.06446282527,542,,Vivey,Vivey,52,Haute-Marne,44,Grand Est
52543,VOILLECOMTE,52130,VOILLECOMTE,,48.5022510829,4.86259090368,543,,Voillecomte,Voillecomte,52,Haute-Marne,44,Grand Est
52544,VOISEY,52400,VOISEY,,47.8788477448,5.76399198551,544,,Voisey,Voisey,52,Haute-Marne,44,Grand Est
52544,VOISEY,52400,VOISEY,VAUX LA DOUCE,47.8788477448,5.76399198551,544,,Voisey,Voisey,52,Haute-Marne,44,Grand Est
52545,VOISINES,52200,VOISINES,,47.8566535707,5.18061608381,545,,Voisines,Voisines,52,Haute-Marne,44,Grand Est
52546,VONCOURT,52500,VONCOURT,,47.7174045888,5.67295513235,546,,Voncourt,Voncourt,52,Haute-Marne,44,Grand Est
52547,VOUECOURT,52320,VOUECOURT,,48.2625820816,5.1581472867,547,,Vouécourt,Vouécourt,52,Haute-Marne,44,Grand Est
52548,VRAINCOURT,52310,VRAINCOURT,,48.2325399035,5.12038971796,548,,Vraincourt,Vraincourt,52,Haute-Marne,44,Grand Est
52549,VRONCOURT LA COTE,52240,VRONCOURT LA COTE,,48.1465009681,5.51317198706,549,,Vroncourt-la-Côte,Vroncourt-la-Côte,52,Haute-Marne,44,Grand Est
52550,WASSY,52130,WASSY,,48.4994770394,4.93077661376,550,,Wassy,Wassy,52,Haute-Marne,44,Grand Est
53001,AHUILLE,53940,AHUILLE,,48.0242749675,-0.872848003304,1,,Ahuillé,Ahuillé,53,Mayenne,52,Pays de la Loire
53002,ALEXAIN,53240,ALEXAIN,,48.2320714529,-0.735727853569,2,,Alexain,Alexain,53,Mayenne,52,Pays de la Loire
53003,AMBRIERES LES VALLEES,53300,AMBRIERES LES VALLEES,,48.4227200634,-0.624160085775,3,,Ambrières-les-Vallées,Ambrières-les-Vallées,53,Mayenne,52,Pays de la Loire
53003,AMBRIERES LES VALLEES,53300,AMBRIERES LES VALLEES,CIGNE,48.4227200634,-0.624160085775,3,,Ambrières-les-Vallées,Ambrières-les-Vallées,53,Mayenne,52,Pays de la Loire
53004,AMPOIGNE,53200,AMPOIGNE,,47.8051436119,-0.823338302617,4,,Ampoigné,Ampoigné,53,Mayenne,52,Pays de la Loire
53005,ANDOUILLE,53240,ANDOUILLE,,48.1771432995,-0.792693064482,5,,Andouillé,Andouillé,53,Mayenne,52,Pays de la Loire
53006,ARGENTON NOTRE DAME,53290,ARGENTON NOTRE DAME,,47.7818256101,-0.599209713441,6,,Argenton-Notre-Dame,Argenton-Notre-Dame,53,Mayenne,52,Pays de la Loire
53007,ARGENTRE,53210,ARGENTRE,,48.0906663644,-0.632122843282,7,,Argentré,Argentré,53,Mayenne,52,Pays de la Loire
53008,ARON,53440,ARON,,48.3036694505,-0.549695026744,8,,Aron,Aron,53,Mayenne,52,Pays de la Loire
53009,ARQUENAY,53170,ARQUENAY,,47.9860121101,-0.588034521359,9,,Arquenay,Arquenay,53,Mayenne,52,Pays de la Loire
53010,ASSE LE BERENGER,53600,ASSE LE BERENGER,,48.155873836,-0.318587508951,10,,Assé-le-Bérenger,Assé-le-Bérenger,53,Mayenne,52,Pays de la Loire
53011,ASTILLE,53230,ASTILLE,,47.9617911116,-0.846376343167,11,,Astillé,Astillé,53,Mayenne,52,Pays de la Loire
53012,ATHEE,53400,ATHEE,,47.8819468899,-0.93658289517,12,,Athée,Athée,53,Mayenne,52,Pays de la Loire
53013,AVERTON,53700,AVERTON,,48.3545540707,-0.210521518054,13,,Averton,Averton,53,Mayenne,52,Pays de la Loire
53014,AZE,53200,AZE,,47.8288251994,-0.657885317512,14,,Azé,Azé,53,Mayenne,52,Pays de la Loire
53015,LA BACONNIERE,53240,LA BACONNIERE,,48.1750815634,-0.900384999021,15,La,Baconnière,La Baconnière,53,Mayenne,52,Pays de la Loire
53016,BAIS,53160,BAIS,,48.253257123,-0.357282017147,16,,Bais,Bais,53,Mayenne,52,Pays de la Loire
53017,BALLEE,53340,BALLEE,,47.9369072798,-0.415963016327,17,,Val-du-Maine,Val-du-Maine,53,Mayenne,52,Pays de la Loire
53018,BALLOTS,53350,BALLOTS,,47.9040903892,-1.05880243531,18,,Ballots,Ballots,53,Mayenne,52,Pays de la Loire
53019,BANNES,53340,BANNES,,47.9849130159,-0.35128628671,19,,Bannes,Bannes,53,Mayenne,52,Pays de la Loire
53021,LA BAZOGE MONTPINCON,53440,LA BAZOGE MONTPINCON,,48.2714381316,-0.58389369345,21,La,Bazoge-Montpinçon,La Bazoge-Montpinçon,53,Mayenne,52,Pays de la Loire
53022,LA BAZOUGE DE CHEMERE,53170,LA BAZOUGE DE CHEMERE,,48.007363424,-0.504663561789,22,La,Bazouge-de-Chemeré,La Bazouge-de-Chemeré,53,Mayenne,52,Pays de la Loire
53023,LA BAZOUGE DES ALLEUX,53470,LA BAZOUGE DES ALLEUX,,48.1864442528,-0.601360078693,23,La,Bazouge-des-Alleux,La Bazouge-des-Alleux,53,Mayenne,52,Pays de la Loire
53025,BAZOUGERS,53170,BAZOUGERS,,48.0261307912,-0.593302561904,25,,Bazougers,Bazougers,53,Mayenne,52,Pays de la Loire
53026,BEAULIEU SUR OUDON,53320,BEAULIEU SUR OUDON,,47.9922207523,-0.988210034643,26,,Beaulieu-sur-Oudon,Beaulieu-sur-Oudon,53,Mayenne,52,Pays de la Loire
53027,BEAUMONT PIED DE BOEUF,53290,BEAUMONT PIED DE BOEUF,,47.9084771781,-0.440801679102,27,,Beaumont-Pied-de-Bœuf,Beaumont-Pied-de-Bœuf,53,Mayenne,52,Pays de la Loire
53028,BELGEARD,53440,BELGEARD,,48.2517598167,-0.560135475971,28,,Belgeard,Belgeard,53,Mayenne,52,Pays de la Loire
53029,BIERNE,53290,BIERNE,,47.8071088114,-0.532804034432,29,,Bierné,Bierné,53,Mayenne,52,Pays de la Loire
53030,LE BIGNON DU MAINE,53170,LE BIGNON DU MAINE,,47.9514202879,-0.625482366182,30,Le,Bignon-du-Maine,Le Bignon-du-Maine,53,Mayenne,52,Pays de la Loire
53031,LA BIGOTTIERE,53240,LA BIGOTTIERE,,48.2226908203,-0.795648692425,31,La,Bigottière,La Bigottière,53,Mayenne,52,Pays de la Loire
53032,BLANDOUET,53270,BLANDOUET,,48.0614087758,-0.317876875641,32,,Blandouet,Blandouet,53,Mayenne,52,Pays de la Loire
53033,LA BOISSIERE,53800,LA BOISSIERE,,47.7725380187,-0.978568477474,33,La,Boissière,La Boissière,53,Mayenne,52,Pays de la Loire
53034,BONCHAMP LES LAVAL,53960,BONCHAMP LES LAVAL,,48.067608658,-0.698304870899,34,,Bonchamp-lès-Laval,Bonchamp-lès-Laval,53,Mayenne,52,Pays de la Loire
53035,BOUCHAMPS LES CRAON,53800,BOUCHAMPS LES CRAON,,47.8063086126,-0.984995031745,35,,Bouchamps-lès-Craon,Bouchamps-lès-Craon,53,Mayenne,52,Pays de la Loire
53036,BOUERE,53290,BOUERE,,47.8606333035,-0.484622471494,36,,Bouère,Bouère,53,Mayenne,52,Pays de la Loire
53037,BOUESSAY,53290,BOUESSAY,,47.8721398411,-0.393398326513,37,,Bouessay,Bouessay,53,Mayenne,52,Pays de la Loire
53038,BOULAY LES IFS,53370,BOULAY LES IFS,,48.4262764002,-0.138570341957,38,,Boulay-les-Ifs,Boulay-les-Ifs,53,Mayenne,52,Pays de la Loire
53039,LE BOURGNEUF LA FORET,53410,LE BOURGNEUF LA FORET,,48.164229969,-0.977903623792,39,Le,Bourgneuf-la-Forêt,Le Bourgneuf-la-Forêt,53,Mayenne,52,Pays de la Loire
53040,BOURGON,53410,BOURGON,,48.1603649988,-1.04536225795,40,,Bourgon,Bourgon,53,Mayenne,52,Pays de la Loire
53041,BRAINS SUR LES MARCHES,53350,BRAINS SUR LES MARCHES,,47.8834759384,-1.17612675598,41,,Brains-sur-les-Marches,Brains-sur-les-Marches,53,Mayenne,52,Pays de la Loire
53042,BRECE,53120,BRECE,,48.3901761265,-0.771171459793,42,,Brecé,Brecé,53,Mayenne,52,Pays de la Loire
53043,BREE,53150,BREE,,48.1564468678,-0.518044395418,43,,Brée,Brée,53,Mayenne,52,Pays de la Loire
53045,LA BRULATTE,53410,LA BRULATTE,,48.0896249185,-0.973295963828,45,La,Brûlatte,La Brûlatte,53,Mayenne,52,Pays de la Loire
53046,LE BURET,53170,LE BURET,,47.9188428297,-0.500867794601,46,Le,Buret,Le Buret,53,Mayenne,52,Pays de la Loire
53047,CARELLES,53120,CARELLES,,48.3887876245,-0.899702305853,47,,Carelles,Carelles,53,Mayenne,52,Pays de la Loire
53048,CHAILLAND,53420,CHAILLAND,,48.2319686385,-0.868605352742,48,,Chailland,Chailland,53,Mayenne,52,Pays de la Loire
53049,CHALONS DU MAINE,53470,CHALONS DU MAINE,,48.1595881924,-0.645569243902,49,,Châlons-du-Maine,Châlons-du-Maine,53,Mayenne,52,Pays de la Loire
53051,CHAMPEON,53640,CHAMPEON,,48.355940269,-0.513101128528,51,,Champéon,Champéon,53,Mayenne,52,Pays de la Loire
53052,CHAMPFREMONT,53370,CHAMPFREMONT,,48.4329079781,-0.104054621774,52,,Champfrémont,Champfrémont,53,Mayenne,52,Pays de la Loire
53053,CHAMPGENETEUX,53160,CHAMPGENETEUX,,48.2936006558,-0.376248680161,53,,Champgenéteux,Champgenéteux,53,Mayenne,52,Pays de la Loire
53054,CHANGE,53810,CHANGE,,48.1079801148,-0.800104772023,54,,Changé,Changé,53,Mayenne,52,Pays de la Loire
53055,CHANTRIGNE,53300,CHANTRIGNE,,48.4131486304,-0.55989625382,55,,Chantrigné,Chantrigné,53,Mayenne,52,Pays de la Loire
53056,LA CHAPELLE ANTHENAISE,53950,LA CHAPELLE ANTHENAISE,,48.1320145418,-0.660149410464,56,La,Chapelle-Anthenaise,La Chapelle-Anthenaise,53,Mayenne,52,Pays de la Loire
53057,LA CHAPELLE AU RIBOUL,53440,LA CHAPELLE AU RIBOUL,,48.3161643792,-0.432803278756,57,La,Chapelle-au-Riboul,La Chapelle-au-Riboul,53,Mayenne,52,Pays de la Loire
53058,LA CHAPELLE CRAONNAISE,53230,LA CHAPELLE CRAONNAISE,,47.9020169468,-0.908547347571,58,La,Chapelle-Craonnaise,La Chapelle-Craonnaise,53,Mayenne,52,Pays de la Loire
53059,LA CHAPELLE RAINSOUIN,53150,LA CHAPELLE RAINSOUIN,,48.0968923633,-0.527783702085,59,La,Chapelle-Rainsouin,La Chapelle-Rainsouin,53,Mayenne,52,Pays de la Loire
53061,CHARCHIGNE,53250,CHARCHIGNE,,48.4142784855,-0.407005787666,61,,Charchigné,Charchigné,53,Mayenne,52,Pays de la Loire
53062,CHATEAU GONTIER,53200,CHATEAU GONTIER,,47.8290067122,-0.738721767875,62,,Château-Gontier,Château-Gontier,53,Mayenne,52,Pays de la Loire
53062,CHATEAU GONTIER,53200,CHATEAU GONTIER,BAZOUGES,47.8290067122,-0.738721767875,62,,Château-Gontier,Château-Gontier,53,Mayenne,52,Pays de la Loire
53063,CHATELAIN,53200,CHATELAIN,,47.8067934344,-0.596047486278,63,,Châtelain,Châtelain,53,Mayenne,52,Pays de la Loire
53064,CHATILLON SUR COLMONT,53100,CHATILLON SUR COLMONT,,48.3355648035,-0.75825362219,64,,Châtillon-sur-Colmont,Châtillon-sur-Colmont,53,Mayenne,52,Pays de la Loire
53065,CHATRES LA FORET,53600,CHATRES LA FORET,,48.1242760809,-0.417110386076,65,,Châtres-la-Forêt,Châtres-la-Forêt,53,Mayenne,52,Pays de la Loire
53066,CHEMAZE,53200,CHEMAZE,,47.7785171054,-0.766233986792,66,,Chemazé,Chemazé,53,Mayenne,52,Pays de la Loire
53067,CHEMERE LE ROI,53340,CHEMERE LE ROI,,47.9744461326,-0.445587835574,67,,Chémeré-le-Roi,Chémeré-le-Roi,53,Mayenne,52,Pays de la Loire
53068,CHERANCE,53400,CHERANCE,,47.8007098465,-0.926581399952,68,,Chérancé,Chérancé,53,Mayenne,52,Pays de la Loire
53069,CHEVAIGNE DU MAINE,53250,CHEVAIGNE DU MAINE,,48.4381316968,-0.400629850689,69,,Chevaigné-du-Maine,Chevaigné-du-Maine,53,Mayenne,52,Pays de la Loire
53071,COLOMBIERS DU PLESSIS,53120,COLOMBIERS DU PLESSIS,,48.3876502723,-0.847201528921,71,,Colombiers-du-Plessis,Colombiers-du-Plessis,53,Mayenne,52,Pays de la Loire
53072,COMMER,53470,COMMER,,48.2352391072,-0.620245484503,72,,Commer,Commer,53,Mayenne,52,Pays de la Loire
53073,CONGRIER,53800,CONGRIER,,47.802801926,-1.12483688372,73,,Congrier,Congrier,53,Mayenne,52,Pays de la Loire
53074,CONTEST,53100,CONTEST,,48.2595526249,-0.68989469528,74,,Contest,Contest,53,Mayenne,52,Pays de la Loire
53075,COSMES,53230,COSMES,,47.9182026802,-0.870520798099,75,,Cosmes,Cosmes,53,Mayenne,52,Pays de la Loire
53076,COSSE EN CHAMPAGNE,53340,COSSE EN CHAMPAGNE,,47.965084113,-0.323516283471,76,,Cossé-en-Champagne,Cossé-en-Champagne,53,Mayenne,52,Pays de la Loire
53077,COSSE LE VIVIEN,53230,COSSE LE VIVIEN,,47.94585879,-0.934606329089,77,,Cossé-le-Vivien,Cossé-le-Vivien,53,Mayenne,52,Pays de la Loire
53078,COUDRAY,53200,COUDRAY,,47.7910216372,-0.63763514955,78,,Coudray,Coudray,53,Mayenne,52,Pays de la Loire
53079,COUESMES VAUCE,53300,COUESMES VAUCE,,48.4480572726,-0.709938389949,79,,Couesmes-Vaucé,Couesmes-Vaucé,53,Mayenne,52,Pays de la Loire
53079,COUESMES VAUCE,53300,COUESMES VAUCE,VAUCE,48.4480572726,-0.709938389949,79,,Couesmes-Vaucé,Couesmes-Vaucé,53,Mayenne,52,Pays de la Loire
53080,COUPTRAIN,53250,COUPTRAIN,,48.482398086,-0.291375085093,80,,Couptrain,Couptrain,53,Mayenne,52,Pays de la Loire
53082,COURBEVEILLE,53230,COURBEVEILLE,,47.9866601942,-0.888326689852,82,,Courbeveille,Courbeveille,53,Mayenne,52,Pays de la Loire
53083,COURCITE,53700,COURCITE,,48.3045487938,-0.253606869806,83,,Courcité,Courcité,53,Mayenne,52,Pays de la Loire
53084,CRAON,53400,CRAON,,47.8454412251,-0.943066490216,84,,Craon,Craon,53,Mayenne,52,Pays de la Loire
53085,CRENNES SUR FRAUBEE,53700,CRENNES SUR FRAUBEE,,48.3825961037,-0.26618187335,85,,Crennes-sur-Fraubée,Crennes-sur-Fraubée,53,Mayenne,52,Pays de la Loire
53086,LA CROIXILLE,53380,LA CROIXILLE,,48.1972333653,-1.04159689946,86,La,Croixille,La Croixille,53,Mayenne,52,Pays de la Loire
53087,LA CROPTE,53170,LA CROPTE,,47.9540718566,-0.491505968258,87,La,Cropte,La Cropte,53,Mayenne,52,Pays de la Loire
53088,CUILLE,53540,CUILLE,,47.9658362794,-1.11498852858,88,,Cuillé,Cuillé,53,Mayenne,52,Pays de la Loire
53089,DAON,53200,DAON,,47.7578017678,-0.627519055718,89,,Daon,Daon,53,Mayenne,52,Pays de la Loire
53090,DENAZE,53400,DENAZE,,47.8809039886,-0.883191347382,90,,Denazé,Denazé,53,Mayenne,52,Pays de la Loire
53091,DESERTINES,53190,DESERTINES,,48.4739929091,-0.875711222537,91,,Désertines,Désertines,53,Mayenne,52,Pays de la Loire
53092,DEUX EVAILLES,53150,DEUX EVAILLES,,48.1912601242,-0.522772642393,92,,Deux-Évailles,Deux-Évailles,53,Mayenne,52,Pays de la Loire
53093,LA DOREE,53190,LA DOREE,,48.4480675066,-0.96796934022,93,La,Dorée,La Dorée,53,Mayenne,52,Pays de la Loire
53094,ENTRAMMES,53260,ENTRAMMES,,47.9934210518,-0.715102434492,94,,Entrammes,Entrammes,53,Mayenne,52,Pays de la Loire
53095,EPINEUX LE SEGUIN,53340,EPINEUX LE SEGUIN,,47.941800225,-0.350865340153,95,,Épineux-le-Seguin,Épineux-le-Seguin,53,Mayenne,52,Pays de la Loire
53096,ERNEE,53500,ERNEE,,48.3039992806,-0.93628278715,96,,Ernée,Ernée,53,Mayenne,52,Pays de la Loire
53097,EVRON,53600,EVRON,,48.1520198351,-0.389270176708,97,,Évron,Évron,53,Mayenne,52,Pays de la Loire
53098,FONTAINE COUVERTE,53350,FONTAINE COUVERTE,,47.9135795411,-1.13947861137,98,,Fontaine-Couverte,Fontaine-Couverte,53,Mayenne,52,Pays de la Loire
53099,FORCE,53260,FORCE,,48.0292171678,-0.704215110969,99,,Forcé,Forcé,53,Mayenne,52,Pays de la Loire
53100,FOUGEROLLES DU PLESSIS,53190,FOUGEROLLES DU PLESSIS,,48.4830723517,-0.945714822072,100,,Fougerolles-du-Plessis,Fougerolles-du-Plessis,53,Mayenne,52,Pays de la Loire
53101,FROMENTIERES,53200,FROMENTIERES,,47.8718387269,-0.667772720385,101,,Fromentières,Fromentières,53,Mayenne,52,Pays de la Loire
53102,GASTINES,53540,GASTINES,,47.9398573687,-1.11199461377,102,,Gastines,Gastines,53,Mayenne,52,Pays de la Loire
53103,LE GENEST ST ISLE,53940,LE GENEST ST ISLE,,48.104173372,-0.895219707599,103,Le,Genest-Saint-Isle,Le Genest-Saint-Isle,53,Mayenne,52,Pays de la Loire
53103,LE GENEST ST ISLE,53940,LE GENEST ST ISLE,ST ISLE,48.104173372,-0.895219707599,103,Le,Genest-Saint-Isle,Le Genest-Saint-Isle,53,Mayenne,52,Pays de la Loire
53104,GENNES SUR GLAIZE,53200,GENNES SUR GLAIZE,,47.8440286792,-0.586296420609,104,,Gennes-sur-Glaize,Gennes-sur-Glaize,53,Mayenne,52,Pays de la Loire
53105,GESNES,53150,GESNES,,48.1503325044,-0.593903165779,105,,Gesnes,Gesnes,53,Mayenne,52,Pays de la Loire
53106,GESVRES,53370,GESVRES,,48.3761066055,-0.163693410236,106,,Gesvres,Gesvres,53,Mayenne,52,Pays de la Loire
53107,GORRON,53120,GORRON,,48.4219397405,-0.8016203216,107,,Gorron,Gorron,53,Mayenne,52,Pays de la Loire
53108,LA GRAVELLE,53410,LA GRAVELLE,,48.0812094944,-1.0243127895,108,La,Gravelle,La Gravelle,53,Mayenne,52,Pays de la Loire
53109,GRAZAY,53440,GRAZAY,,48.2855176903,-0.47627228769,109,,Grazay,Grazay,53,Mayenne,52,Pays de la Loire
53110,GREZ EN BOUERE,53290,GREZ EN BOUERE,,47.8703920979,-0.537813247538,110,,Grez-en-Bouère,Grez-en-Bouère,53,Mayenne,52,Pays de la Loire
53111,LA HAIE TRAVERSAINE,53300,LA HAIE TRAVERSAINE,,48.3586109255,-0.613698907752,111,La,Haie-Traversaine,La Haie-Traversaine,53,Mayenne,52,Pays de la Loire
53112,LE HAM,53250,LE HAM,,48.3736183022,-0.353133097864,112,Le,Ham,Le Ham,53,Mayenne,52,Pays de la Loire
53113,HAMBERS,53160,HAMBERS,,48.2543518824,-0.425847827344,113,,Hambers,Hambers,53,Mayenne,52,Pays de la Loire
53114,HARDANGES,53640,HARDANGES,,48.34114678,-0.405714149833,114,,Hardanges,Hardanges,53,Mayenne,52,Pays de la Loire
53115,HERCE,53120,HERCE,,48.4229725052,-0.860571595419,115,,Hercé,Hercé,53,Mayenne,52,Pays de la Loire
53116,LE HORPS,53640,LE HORPS,,48.3894551796,-0.46306709899,116,Le,Horps,Le Horps,53,Mayenne,52,Pays de la Loire
53117,HOUSSAY,53360,HOUSSAY,,47.9107835548,-0.744633552583,117,,Houssay,Houssay,53,Mayenne,52,Pays de la Loire
53118,LE HOUSSEAU BRETIGNOLLES,53110,LE HOUSSEAU BRETIGNOLLES,,48.4707455149,-0.537332465722,118,Le,Housseau-Brétignolles,Le Housseau-Brétignolles,53,Mayenne,52,Pays de la Loire
53118,LE HOUSSEAU BRETIGNOLLES,53110,LE HOUSSEAU BRETIGNOLLES,BRETIGNOLLES LE MOULIN,48.4707455149,-0.537332465722,118,Le,Housseau-Brétignolles,Le Housseau-Brétignolles,53,Mayenne,52,Pays de la Loire
53119,L HUISSERIE,53970,L HUISSERIE,,48.0167338344,-0.766387903128,119,L',Huisserie,L'Huisserie,53,Mayenne,52,Pays de la Loire
53120,IZE,53160,IZE,,48.225304458,-0.301409019014,120,,Izé,Izé,53,Mayenne,52,Pays de la Loire
53121,JAVRON LES CHAPELLES,53250,JAVRON LES CHAPELLES,,48.4208361061,-0.330959453036,121,,Javron-les-Chapelles,Javron-les-Chapelles,53,Mayenne,52,Pays de la Loire
53121,JAVRON LES CHAPELLES,53250,JAVRON LES CHAPELLES,LES CHAPELLES,48.4208361061,-0.330959453036,121,,Javron-les-Chapelles,Javron-les-Chapelles,53,Mayenne,52,Pays de la Loire
53122,JUBLAINS,53160,JUBLAINS,,48.2455340373,-0.498318427927,122,,Jublains,Jublains,53,Mayenne,52,Pays de la Loire
53123,JUVIGNE,53380,JUVIGNE,,48.2356053561,-1.02188545282,123,,Juvigné,Juvigné,53,Mayenne,52,Pays de la Loire
53124,LAIGNE,53200,LAIGNE,,47.8446027753,-0.835394769225,124,,Prée-d'Anjou,Prée-d'Anjou,53,Mayenne,52,Pays de la Loire
53125,LANDIVY,53190,LANDIVY,,48.4787671606,-1.03882246254,125,,Landivy,Landivy,53,Mayenne,52,Pays de la Loire
53126,LARCHAMP,53220,LARCHAMP,,48.3492150628,-1.00336742476,126,,Larchamp,Larchamp,53,Mayenne,52,Pays de la Loire
53127,LASSAY LES CHATEAUX,53110,LASSAY LES CHATEAUX,,48.4421089808,-0.498821106653,127,,Lassay-les-Châteaux,Lassay-les-Châteaux,53,Mayenne,52,Pays de la Loire
53127,LASSAY LES CHATEAUX,53110,LASSAY LES CHATEAUX,LA BAROCHE GONDOUIN,48.4421089808,-0.498821106653,127,,Lassay-les-Châteaux,Lassay-les-Châteaux,53,Mayenne,52,Pays de la Loire
53127,LASSAY LES CHATEAUX,53110,LASSAY LES CHATEAUX,MELLERAY LA VALLEE,48.4421089808,-0.498821106653,127,,Lassay-les-Châteaux,Lassay-les-Châteaux,53,Mayenne,52,Pays de la Loire
53127,LASSAY LES CHATEAUX,53110,LASSAY LES CHATEAUX,NIORT LA FONTAINE,48.4421089808,-0.498821106653,127,,Lassay-les-Châteaux,Lassay-les-Châteaux,53,Mayenne,52,Pays de la Loire
53128,LAUBRIERES,53540,LAUBRIERES,,47.9483524885,-1.07070944071,128,,Laubrières,Laubrières,53,Mayenne,52,Pays de la Loire
53129,LAUNAY VILLIERS,53410,LAUNAY VILLIERS,,48.1329122905,-1.0116324097,129,,Launay-Villiers,Launay-Villiers,53,Mayenne,52,Pays de la Loire
53130,LAVAL,53000,LAVAL,,48.0608565468,-0.766005687142,130,,Laval,Laval,53,Mayenne,52,Pays de la Loire
53131,LESBOIS,53120,LESBOIS,,48.4514022952,-0.793461282551,131,,Lesbois,Lesbois,53,Mayenne,52,Pays de la Loire
53132,LEVARE,53120,LEVARE,,48.4188847,-0.907330477374,132,,Levaré,Levaré,53,Mayenne,52,Pays de la Loire
53133,LIGNIERES ORGERES,53140,LIGNIERES ORGERES,,48.5288919372,-0.212232237721,133,,Lignières-Orgères,Lignières-Orgères,53,Mayenne,52,Pays de la Loire
53133,LIGNIERES ORGERES,53140,LIGNIERES ORGERES,ORGERES LA ROCHE,48.5288919372,-0.212232237721,133,,Lignières-Orgères,Lignières-Orgères,53,Mayenne,52,Pays de la Loire
53134,LIVET,53150,LIVET,,48.1116444392,-0.462684976775,134,,Livet,Livet,53,Mayenne,52,Pays de la Loire
53135,LIVRE LA TOUCHE,53400,LIVRE LA TOUCHE,,47.8891404451,-0.992643026604,135,,Livré-la-Touche,Livré-la-Touche,53,Mayenne,52,Pays de la Loire
53136,LOIGNE SUR MAYENNE,53200,LOIGNE SUR MAYENNE,,47.8683528572,-0.737119476827,136,,Loigné-sur-Mayenne,Loigné-sur-Mayenne,53,Mayenne,52,Pays de la Loire
53137,LOIRON RUILLE,53320,LOIRON RUILLE,,48.0630179288,-0.919282481771,137,,Loiron-Ruillé,Loiron-Ruillé,53,Mayenne,52,Pays de la Loire
53137,LOIRON RUILLE,53320,LOIRON RUILLE,RUILLE LE GRAVELAIS,48.0630179288,-0.919282481771,137,,Loiron-Ruillé,Loiron-Ruillé,53,Mayenne,52,Pays de la Loire
53138,LONGUEFUYE,53200,LONGUEFUYE,,47.8810026528,-0.591521816813,138,,Longuefuye,Longuefuye,53,Mayenne,52,Pays de la Loire
53139,LOUPFOUGERES,53700,LOUPFOUGERES,,48.3321259289,-0.352428366281,139,,Loupfougères,Loupfougères,53,Mayenne,52,Pays de la Loire
53140,LOUVERNE,53950,LOUVERNE,,48.1255057038,-0.711543672275,140,,Louverné,Louverné,53,Mayenne,52,Pays de la Loire
53141,LOUVIGNE,53210,LOUVIGNE,,48.0547197919,-0.634640720339,141,,Louvigné,Louvigné,53,Mayenne,52,Pays de la Loire
53142,MADRE,53250,MADRE,,48.4702152337,-0.375712672997,142,,Madré,Madré,53,Mayenne,52,Pays de la Loire
53143,MAISONCELLES DU MAINE,53170,MAISONCELLES DU MAINE,,47.9677800474,-0.660519989345,143,,Maisoncelles-du-Maine,Maisoncelles-du-Maine,53,Mayenne,52,Pays de la Loire
53144,MARCILLE LA VILLE,53440,MARCILLE LA VILLE,,48.3246621329,-0.480574121834,144,,Marcillé-la-Ville,Marcillé-la-Ville,53,Mayenne,52,Pays de la Loire
53145,MARIGNE PEUTON,53200,MARIGNE PEUTON,,47.8580802657,-0.798902117267,145,,Marigné-Peuton,Marigné-Peuton,53,Mayenne,52,Pays de la Loire
53146,MARTIGNE SUR MAYENNE,53470,MARTIGNE SUR MAYENNE,,48.2026950175,-0.661054831138,146,,Martigné-sur-Mayenne,Martigné-sur-Mayenne,53,Mayenne,52,Pays de la Loire
53147,MAYENNE,53100,MAYENNE,,48.3074609449,-0.614799950799,147,,Mayenne,Mayenne,53,Mayenne,52,Pays de la Loire
53148,MEE,53400,MEE,,47.8016835943,-0.865499945684,148,,Mée,Mée,53,Mayenne,52,Pays de la Loire
53150,MENIL,53200,MENIL,,47.7638591117,-0.687864580509,150,,Ménil,Ménil,53,Mayenne,52,Pays de la Loire
53151,MERAL,53230,MERAL,,47.9514671567,-1.0154942248,151,,Méral,Méral,53,Mayenne,52,Pays de la Loire
53152,MESLAY DU MAINE,53170,MESLAY DU MAINE,,47.9469156243,-0.558693863131,152,,Meslay-du-Maine,Meslay-du-Maine,53,Mayenne,52,Pays de la Loire
53153,MEZANGERS,53600,MEZANGERS,,48.2005441476,-0.449609961038,153,,Mézangers,Mézangers,53,Mayenne,52,Pays de la Loire
53154,MONTAUDIN,53220,MONTAUDIN,,48.3809154323,-0.97983082547,154,,Montaudin,Montaudin,53,Mayenne,52,Pays de la Loire
53155,MONTENAY,53500,MONTENAY,,48.2791740131,-0.892252753941,155,,Montenay,Montenay,53,Mayenne,52,Pays de la Loire
53156,MONTFLOURS,53240,MONTFLOURS,,48.1697752785,-0.726176866371,156,,Montflours,Montflours,53,Mayenne,52,Pays de la Loire
53157,MONTIGNE LE BRILLANT,53970,MONTIGNE LE BRILLANT,,48.013438147,-0.816955445143,157,,Montigné-le-Brillant,Montigné-le-Brillant,53,Mayenne,52,Pays de la Loire
53158,MONTJEAN,53320,MONTJEAN,,48.0081888709,-0.941581146475,158,,Montjean,Montjean,53,Mayenne,52,Pays de la Loire
53159,MONTOURTIER,53150,MONTOURTIER,,48.2124575885,-0.554264699743,159,,Montourtier,Montourtier,53,Mayenne,52,Pays de la Loire
53160,MONTREUIL POULAY,53640,MONTREUIL POULAY,,48.385896935,-0.535818668483,160,,Montreuil-Poulay,Montreuil-Poulay,53,Mayenne,52,Pays de la Loire
53160,MONTREUIL POULAY,53640,MONTREUIL POULAY,POULAY,48.385896935,-0.535818668483,160,,Montreuil-Poulay,Montreuil-Poulay,53,Mayenne,52,Pays de la Loire
53161,MONTSURS,53150,MONTSURS,,48.1342883147,-0.552017747624,161,,Montsûrs-Saint-Céneré,Montsûrs-Saint-Céneré,53,Mayenne,52,Pays de la Loire
53162,MOULAY,53100,MOULAY,,48.2651790153,-0.621214314022,162,,Moulay,Moulay,53,Mayenne,52,Pays de la Loire
53163,NEAU,53150,NEAU,,48.1701789884,-0.474651575361,163,,Neau,Neau,53,Mayenne,52,Pays de la Loire
53164,NEUILLY LE VENDIN,53250,NEUILLY LE VENDIN,,48.4904833451,-0.326146757248,164,,Neuilly-le-Vendin,Neuilly-le-Vendin,53,Mayenne,52,Pays de la Loire
53165,NIAFLES,53400,NIAFLES,,47.8465834119,-0.99977658744,165,,Niafles,Niafles,53,Mayenne,52,Pays de la Loire
53168,NUILLE SUR VICOIN,53970,NUILLE SUR VICOIN,,47.9802789281,-0.779212405934,168,,Nuillé-sur-Vicoin,Nuillé-sur-Vicoin,53,Mayenne,52,Pays de la Loire
53169,OLIVET,53410,OLIVET,,48.1207608153,-0.935026988481,169,,Olivet,Olivet,53,Mayenne,52,Pays de la Loire
53170,OISSEAU,53300,OISSEAU,,48.3617009398,-0.667360504455,170,,Oisseau,Oisseau,53,Mayenne,52,Pays de la Loire
53172,ORIGNE,53360,ORIGNE,,47.9525235676,-0.734592990077,172,,Origné,Origné,53,Mayenne,52,Pays de la Loire
53173,LA PALLU,53140,LA PALLU,,48.5047614159,-0.297114491021,173,La,Pallu,La Pallu,53,Mayenne,52,Pays de la Loire
53174,PARIGNE SUR BRAYE,53100,PARIGNE SUR BRAYE,,48.3232727343,-0.647230020006,174,,Parigné-sur-Braye,Parigné-sur-Braye,53,Mayenne,52,Pays de la Loire
53175,PARNE SUR ROC,53260,PARNE SUR ROC,,48.0096895482,-0.66530040528,175,,Parné-sur-Roc,Parné-sur-Roc,53,Mayenne,52,Pays de la Loire
53176,LE PAS,53300,LE PAS,,48.4219636252,-0.694714292765,176,Le,Pas,Le Pas,53,Mayenne,52,Pays de la Loire
53177,LA PELLERINE,53220,LA PELLERINE,,48.3204300872,-1.03408740843,177,La,Pellerine,La Pellerine,53,Mayenne,52,Pays de la Loire
53178,PEUTON,53360,PEUTON,,47.8891706831,-0.813068776889,178,,Peuton,Peuton,53,Mayenne,52,Pays de la Loire
53179,PLACE,53240,PLACE,,48.2644178522,-0.775676155113,179,,Placé,Placé,53,Mayenne,52,Pays de la Loire
53180,POMMERIEUX,53400,POMMERIEUX,,47.8279468794,-0.899677522032,180,,Pommerieux,Pommerieux,53,Mayenne,52,Pays de la Loire
53181,PONTMAIN,53220,PONTMAIN,,48.4336187982,-1.06521474954,181,,Pontmain,Pontmain,53,Mayenne,52,Pays de la Loire
53182,PORT BRILLET,53410,PORT BRILLET,,48.1212899945,-0.963949064008,182,,Port-Brillet,Port-Brillet,53,Mayenne,52,Pays de la Loire
53184,PREAUX,53340,PREAUX,,47.9370817742,-0.464953364883,184,,Préaux,Préaux,53,Mayenne,52,Pays de la Loire
53185,PRE EN PAIL ST SAMSON,53140,PRE EN PAIL ST SAMSON,,48.4470393586,-0.196704985828,185,,Pré-en-Pail-Saint-Samson,Pré-en-Pail-Saint-Samson,53,Mayenne,52,Pays de la Loire
53185,PRE EN PAIL ST SAMSON,53140,PRE EN PAIL ST SAMSON,ST SAMSON,48.4470393586,-0.196704985828,185,,Pré-en-Pail-Saint-Samson,Pré-en-Pail-Saint-Samson,53,Mayenne,52,Pays de la Loire
53186,QUELAINES ST GAULT,53360,QUELAINES ST GAULT,,47.9279241835,-0.79917074746,186,,Quelaines-Saint-Gault,Quelaines-Saint-Gault,53,Mayenne,52,Pays de la Loire
53186,QUELAINES ST GAULT,53360,QUELAINES ST GAULT,ST GAULT,47.9279241835,-0.79917074746,186,,Quelaines-Saint-Gault,Quelaines-Saint-Gault,53,Mayenne,52,Pays de la Loire
53187,RAVIGNY,53370,RAVIGNY,,48.4424919751,-0.0682909455819,187,,Ravigny,Ravigny,53,Mayenne,52,Pays de la Loire
53188,RENAZE,53800,RENAZE,,47.7843103989,-1.040222995,188,,Renazé,Renazé,53,Mayenne,52,Pays de la Loire
53189,RENNES EN GRENOUILLES,53110,RENNES EN GRENOUILLES,,48.4909507271,-0.507002879374,189,,Rennes-en-Grenouilles,Rennes-en-Grenouilles,53,Mayenne,52,Pays de la Loire
53190,LE RIBAY,53640,LE RIBAY,,48.3782564166,-0.415575081434,190,Le,Ribay,Le Ribay,53,Mayenne,52,Pays de la Loire
53191,LA ROE,53350,LA ROE,,47.8910085582,-1.10255581049,191,La,Roë,La Roë,53,Mayenne,52,Pays de la Loire
53192,LA ROUAUDIERE,53390,LA ROUAUDIERE,,47.8380947101,-1.18557298037,192,La,Rouaudière,La Rouaudière,53,Mayenne,52,Pays de la Loire
53193,RUILLE FROID FONDS,53170,RUILLE FROID FONDS,,47.9061787546,-0.625211049544,193,,Ruillé-Froid-Fonds,Ruillé-Froid-Fonds,53,Mayenne,52,Pays de la Loire
53195,SACE,53470,SACE,,48.1800800197,-0.699374640093,195,,Sacé,Sacé,53,Mayenne,52,Pays de la Loire
53196,ST AIGNAN DE COUPTRAIN,53250,ST AIGNAN DE COUPTRAIN,,48.4595906728,-0.301358440713,196,,Saint-Aignan-de-Couptrain,Saint-Aignan-de-Couptrain,53,Mayenne,52,Pays de la Loire
53197,ST AIGNAN SUR ROE,53390,ST AIGNAN SUR ROE,,47.8498132435,-1.14052509792,197,,Saint-Aignan-sur-Roë,Saint-Aignan-sur-Roë,53,Mayenne,52,Pays de la Loire
53198,ST AUBIN DU DESERT,53700,ST AUBIN DU DESERT,,48.3147006248,-0.184927866306,198,,Saint-Aubin-du-Désert,Saint-Aubin-du-Désert,53,Mayenne,52,Pays de la Loire
53199,ST AUBIN FOSSE LOUVAIN,53120,ST AUBIN FOSSE LOUVAIN,,48.4490661735,-0.836056782555,199,,Saint-Aubin-Fosse-Louvain,Saint-Aubin-Fosse-Louvain,53,Mayenne,52,Pays de la Loire
53200,ST BAUDELLE,53100,ST BAUDELLE,,48.2871604216,-0.650878906901,200,,Saint-Baudelle,Saint-Baudelle,53,Mayenne,52,Pays de la Loire
53201,ST BERTHEVIN,53940,ST BERTHEVIN,,48.0702234162,-0.845217269922,201,,Saint-Berthevin,Saint-Berthevin,53,Mayenne,52,Pays de la Loire
53202,ST BERTHEVIN LA TANNIERE,53220,ST BERTHEVIN LA TANNIERE,,48.4068199721,-0.947577369365,202,,Saint-Berthevin-la-Tannière,Saint-Berthevin-la-Tannière,53,Mayenne,52,Pays de la Loire
53203,ST BRICE,53290,ST BRICE,,47.8614989489,-0.439457256097,203,,Saint-Brice,Saint-Brice,53,Mayenne,52,Pays de la Loire
53204,ST CALAIS DU DESERT,53140,ST CALAIS DU DESERT,,48.490532997,-0.250672513735,204,,Saint-Calais-du-Désert,Saint-Calais-du-Désert,53,Mayenne,52,Pays de la Loire
53205,ST CENERE,53150,ST CENERE,,48.1166127949,-0.585561633647,205,,Saint-Céneré,Saint-Céneré,53,Mayenne,52,Pays de la Loire
53206,ST CHARLES LA FORET,53170,ST CHARLES LA FORET,,47.9194763101,-0.56890485725,206,,Saint-Charles-la-Forêt,Saint-Charles-la-Forêt,53,Mayenne,52,Pays de la Loire
53207,ST CHRISTOPHE DU LUAT,53150,ST CHRISTOPHE DU LUAT,,48.1330327005,-0.478741130679,207,,Saint-Christophe-du-Luat,Saint-Christophe-du-Luat,53,Mayenne,52,Pays de la Loire
53208,ST CYR EN PAIL,53140,ST CYR EN PAIL,,48.4338588382,-0.253528252762,208,,Saint-Cyr-en-Pail,Saint-Cyr-en-Pail,53,Mayenne,52,Pays de la Loire
53209,ST CYR LE GRAVELAIS,53320,ST CYR LE GRAVELAIS,,48.038695875,-1.00495049618,209,,Saint-Cyr-le-Gravelais,Saint-Cyr-le-Gravelais,53,Mayenne,52,Pays de la Loire
53210,ST DENIS D ANJOU,53290,ST DENIS D ANJOU,,47.7899907217,-0.443836396748,210,,Saint-Denis-d'Anjou,Saint-Denis-d'Anjou,53,Mayenne,52,Pays de la Loire
53211,ST DENIS DE GASTINES,53500,ST DENIS DE GASTINES,,48.3469457308,-0.87082653269,211,,Saint-Denis-de-Gastines,Saint-Denis-de-Gastines,53,Mayenne,52,Pays de la Loire
53212,ST DENIS DU MAINE,53170,ST DENIS DU MAINE,,47.9806121004,-0.521686670231,212,,Saint-Denis-du-Maine,Saint-Denis-du-Maine,53,Mayenne,52,Pays de la Loire
53213,ST ELLIER DU MAINE,53220,ST ELLIER DU MAINE,,48.404060159,-1.04037083091,213,,Saint-Ellier-du-Maine,Saint-Ellier-du-Maine,53,Mayenne,52,Pays de la Loire
53214,ST ERBLON,53390,ST ERBLON,,47.7867130682,-1.15831425112,214,,Saint-Erblon,Saint-Erblon,53,Mayenne,52,Pays de la Loire
53215,ST FORT,53200,ST FORT,,47.800280383,-0.707857177962,215,,Saint-Fort,Saint-Fort,53,Mayenne,52,Pays de la Loire
53216,ST FRAIMBAULT DE PRIERES,53300,ST FRAIMBAULT DE PRIERES,,48.3488678421,-0.574505181437,216,,Saint-Fraimbault-de-Prières,Saint-Fraimbault-de-Prières,53,Mayenne,52,Pays de la Loire
53218,STE GEMMES LE ROBERT,53600,STE GEMMES LE ROBERT,,48.2045535141,-0.373170787282,218,,Sainte-Gemmes-le-Robert,Sainte-Gemmes-le-Robert,53,Mayenne,52,Pays de la Loire
53219,ST GEORGES BUTTAVENT,53100,ST GEORGES BUTTAVENT,,48.3009765177,-0.709948140576,219,,Saint-Georges-Buttavent,Saint-Georges-Buttavent,53,Mayenne,52,Pays de la Loire
53219,ST GEORGES BUTTAVENT,53100,ST GEORGES BUTTAVENT,FONTAINE DANIEL,48.3009765177,-0.709948140576,219,,Saint-Georges-Buttavent,Saint-Georges-Buttavent,53,Mayenne,52,Pays de la Loire
53220,ST GEORGES LE FLECHARD,53480,ST GEORGES LE FLECHARD,,48.0359442394,-0.514840617722,220,,Saint-Georges-le-Fléchard,Saint-Georges-le-Fléchard,53,Mayenne,52,Pays de la Loire
53221,ST GEORGES SUR ERVE,53600,ST GEORGES SUR ERVE,,48.1772730015,-0.292545458166,221,,Saint-Georges-sur-Erve,Saint-Georges-sur-Erve,53,Mayenne,52,Pays de la Loire
53222,ST GERMAIN D ANXURE,53240,ST GERMAIN D ANXURE,,48.2180775188,-0.734109264327,222,,Saint-Germain-d'Anxure,Saint-Germain-d'Anxure,53,Mayenne,52,Pays de la Loire
53223,ST GERMAIN DE COULAMER,53700,ST GERMAIN DE COULAMER,,48.2677488568,-0.185983201965,223,,Saint-Germain-de-Coulamer,Saint-Germain-de-Coulamer,53,Mayenne,52,Pays de la Loire
53224,ST GERMAIN LE FOUILLOUX,53240,ST GERMAIN LE FOUILLOUX,,48.1404679817,-0.812282389838,224,,Saint-Germain-le-Fouilloux,Saint-Germain-le-Fouilloux,53,Mayenne,52,Pays de la Loire
53225,ST GERMAIN LE GUILLAUME,53240,ST GERMAIN LE GUILLAUME,,48.2074057351,-0.827228115733,225,,Saint-Germain-le-Guillaume,Saint-Germain-le-Guillaume,53,Mayenne,52,Pays de la Loire
53226,ST HILAIRE DU MAINE,53380,ST HILAIRE DU MAINE,,48.2230674956,-0.930972405821,226,,Saint-Hilaire-du-Maine,Saint-Hilaire-du-Maine,53,Mayenne,52,Pays de la Loire
53228,ST JEAN SUR ERVE,53270,ST JEAN SUR ERVE,,48.0403224442,-0.38824131993,228,,Blandouet-Saint Jean,Blandouet-Saint Jean,53,Mayenne,52,Pays de la Loire
53229,ST JEAN SUR MAYENNE,53240,ST JEAN SUR MAYENNE,,48.1413153806,-0.752524079247,229,,Saint-Jean-sur-Mayenne,Saint-Jean-sur-Mayenne,53,Mayenne,52,Pays de la Loire
53230,ST JULIEN DU TERROUX,53110,ST JULIEN DU TERROUX,,48.4835428783,-0.409443392744,230,,Saint-Julien-du-Terroux,Saint-Julien-du-Terroux,53,Mayenne,52,Pays de la Loire
53231,ST LAURENT DES MORTIERS,53290,ST LAURENT DES MORTIERS,,47.7703071745,-0.540190288651,231,,Saint-Laurent-des-Mortiers,Saint-Laurent-des-Mortiers,53,Mayenne,52,Pays de la Loire
53232,ST LEGER,53480,ST LEGER,,48.0862593313,-0.446252425773,232,,Saint-Léger,Saint-Léger,53,Mayenne,52,Pays de la Loire
53233,ST LOUP DU DORAT,53290,ST LOUP DU DORAT,,47.8857485976,-0.423854225277,233,,Saint-Loup-du-Dorat,Saint-Loup-du-Dorat,53,Mayenne,52,Pays de la Loire
53234,ST LOUP DU GAST,53300,ST LOUP DU GAST,,48.3871907627,-0.584446559338,234,,Saint-Loup-du-Gast,Saint-Loup-du-Gast,53,Mayenne,52,Pays de la Loire
53235,STE MARIE DU BOIS,53110,STE MARIE DU BOIS,,48.4689132651,-0.483184155223,235,,Sainte-Marie-du-Bois,Sainte-Marie-du-Bois,53,Mayenne,52,Pays de la Loire
53236,ST MARS DU DESERT,53700,ST MARS DU DESERT,,48.295036747,-0.169490797785,236,,Saint-Mars-du-Désert,Saint-Mars-du-Désert,53,Mayenne,52,Pays de la Loire
53237,ST MARS SUR COLMONT,53300,ST MARS SUR COLMONT,,48.3829153295,-0.708965390881,237,,Saint-Mars-sur-Colmont,Saint-Mars-sur-Colmont,53,Mayenne,52,Pays de la Loire
53238,ST MARS SUR LA FUTAIE,53220,ST MARS SUR LA FUTAIE,,48.4310961702,-1.01165240815,238,,Saint-Mars-sur-la-Futaie,Saint-Mars-sur-la-Futaie,53,Mayenne,52,Pays de la Loire
53239,ST MARTIN DE CONNEE,53160,ST MARTIN DE CONNEE,,48.2263014135,-0.246813322677,239,,Saint-Martin-de-Connée,Saint-Martin-de-Connée,53,Mayenne,52,Pays de la Loire
53240,ST MARTIN DU LIMET,53800,ST MARTIN DU LIMET,,47.8147228072,-1.02874130308,240,,Saint-Martin-du-Limet,Saint-Martin-du-Limet,53,Mayenne,52,Pays de la Loire
53241,ST MICHEL DE FEINS,53290,ST MICHEL DE FEINS,,47.7695502906,-0.573583641744,241,,Saint-Michel-de-Feins,Saint-Michel-de-Feins,53,Mayenne,52,Pays de la Loire
53242,ST MICHEL DE LA ROE,53350,ST MICHEL DE LA ROE,,47.8723222093,-1.12403931554,242,,Saint-Michel-de-la-Roë,Saint-Michel-de-la-Roë,53,Mayenne,52,Pays de la Loire
53243,ST OUEN DES TOITS,53410,ST OUEN DES TOITS,,48.1425474962,-0.892808198068,243,,Saint-Ouën-des-Toits,Saint-Ouën-des-Toits,53,Mayenne,52,Pays de la Loire
53244,ST OUEN DES VALLONS,53150,ST OUEN DES VALLONS,,48.1731026011,-0.559503025324,244,,Saint-Ouën-des-Vallons,Saint-Ouën-des-Vallons,53,Mayenne,52,Pays de la Loire
53245,ST PIERRE DES LANDES,53500,ST PIERRE DES LANDES,,48.2883947917,-1.02805097575,245,,Saint-Pierre-des-Landes,Saint-Pierre-des-Landes,53,Mayenne,52,Pays de la Loire
53246,ST PIERRE DES NIDS,53370,ST PIERRE DES NIDS,,48.3981189139,-0.108578317473,246,,Saint-Pierre-des-Nids,Saint-Pierre-des-Nids,53,Mayenne,52,Pays de la Loire
53247,ST PIERRE LA COUR,53410,ST PIERRE LA COUR,,48.1084439296,-1.02377898526,247,,Saint-Pierre-la-Cour,Saint-Pierre-la-Cour,53,Mayenne,52,Pays de la Loire
53248,ST PIERRE SUR ERVE,53270,ST PIERRE SUR ERVE,,48.0122977006,-0.410540706359,248,,Saint-Pierre-sur-Erve,Saint-Pierre-sur-Erve,53,Mayenne,52,Pays de la Loire
53249,ST PIERRE SUR ORTHE,53160,ST PIERRE SUR ORTHE,,48.2283647918,-0.193084867876,249,,Saint-Pierre-sur-Orthe,Saint-Pierre-sur-Orthe,53,Mayenne,52,Pays de la Loire
53250,ST POIX,53540,ST POIX,,47.9663369335,-1.04358876565,250,,Saint-Poix,Saint-Poix,53,Mayenne,52,Pays de la Loire
53251,ST QUENTIN LES ANGES,53400,ST QUENTIN LES ANGES,,47.777433487,-0.874589070438,251,,Saint-Quentin-les-Anges,Saint-Quentin-les-Anges,53,Mayenne,52,Pays de la Loire
53253,ST SATURNIN DU LIMET,53800,ST SATURNIN DU LIMET,,47.8160669984,-1.08925376057,253,,Saint-Saturnin-du-Limet,Saint-Saturnin-du-Limet,53,Mayenne,52,Pays de la Loire
53254,ST SULPICE,53360,ST SULPICE,,47.89311618,-0.715072813876,254,,Saint-Sulpice,Saint-Sulpice,53,Mayenne,52,Pays de la Loire
53255,STE SUZANNE ET CHAMMES,53270,STE SUZANNE ET CHAMMES,,48.1028813013,-0.354680320285,255,,Sainte-Suzanne-et-Chammes,Sainte-Suzanne-et-Chammes,53,Mayenne,52,Pays de la Loire
53255,STE SUZANNE ET CHAMMES,53270,STE SUZANNE ET CHAMMES,CHAMMES,48.1028813013,-0.354680320285,255,,Sainte-Suzanne-et-Chammes,Sainte-Suzanne-et-Chammes,53,Mayenne,52,Pays de la Loire
53256,ST THOMAS DE COURCERIERS,53160,ST THOMAS DE COURCERIERS,,48.2645467758,-0.265751898423,256,,Saint-Thomas-de-Courceriers,Saint-Thomas-de-Courceriers,53,Mayenne,52,Pays de la Loire
53257,SAULGES,53340,SAULGES,,47.9761126561,-0.405978103131,257,,Saulges,Saulges,53,Mayenne,52,Pays de la Loire
53258,LA SELLE CRAONNAISE,53800,LA SELLE CRAONNAISE,,47.8467586768,-1.05851023151,258,La,Selle-Craonnaise,La Selle-Craonnaise,53,Mayenne,52,Pays de la Loire
53259,SENONNES,53390,SENONNES,,47.8069754528,-1.19638617309,259,,Senonnes,Senonnes,53,Mayenne,52,Pays de la Loire
53260,SIMPLE,53360,SIMPLE,,47.8831198696,-0.854685550737,260,,Simplé,Simplé,53,Mayenne,52,Pays de la Loire
53261,SOUCE,53300,SOUCE,,48.4670641596,-0.67042009651,261,,Soucé,Soucé,53,Mayenne,52,Pays de la Loire
53262,SOULGE SUR OUETTE,53210,SOULGE SUR OUETTE,,48.0623593247,-0.558425301844,262,,Soulgé-sur-Ouette,Soulgé-sur-Ouette,53,Mayenne,52,Pays de la Loire
53262,SOULGE SUR OUETTE,53210,SOULGE SUR OUETTE,NUILLE SUR OUETTE,48.0623593247,-0.558425301844,262,,Soulgé-sur-Ouette,Soulgé-sur-Ouette,53,Mayenne,52,Pays de la Loire
53263,THUBOEUF,53110,THUBOEUF,,48.4958782313,-0.453905832076,263,,Thubœuf,Thubœuf,53,Mayenne,52,Pays de la Loire
53264,THORIGNE EN CHARNIE,53270,THORIGNE EN CHARNIE,,48.005707604,-0.359572558274,264,,Thorigné-en-Charnie,Thorigné-en-Charnie,53,Mayenne,52,Pays de la Loire
53265,TORCE VIVIERS EN CHARNIE,53270,TORCE VIVIERS EN CHARNIE,,48.0968755142,-0.27150595519,265,,Torcé-Viviers-en-Charnie,Torcé-Viviers-en-Charnie,53,Mayenne,52,Pays de la Loire
53265,TORCE VIVIERS EN CHARNIE,53270,TORCE VIVIERS EN CHARNIE,VIVIERS EN CHARNIE,48.0968755142,-0.27150595519,265,,Torcé-Viviers-en-Charnie,Torcé-Viviers-en-Charnie,53,Mayenne,52,Pays de la Loire
53266,TRANS,53160,TRANS,,48.2832532915,-0.308341418341,266,,Trans,Trans,53,Mayenne,52,Pays de la Loire
53267,VAIGES,53480,VAIGES,,48.0506374243,-0.471285801786,267,,Vaiges,Vaiges,53,Mayenne,52,Pays de la Loire
53269,VAUTORTE,53500,VAUTORTE,,48.2983458845,-0.823100560436,269,,Vautorte,Vautorte,53,Mayenne,52,Pays de la Loire
53270,VIEUVY,53120,VIEUVY,,48.446058835,-0.877620263394,270,,Vieuvy,Vieuvy,53,Mayenne,52,Pays de la Loire
53271,VILLAINES LA JUHEL,53700,VILLAINES LA JUHEL,,48.3394105228,-0.292005010106,271,,Villaines-la-Juhel,Villaines-la-Juhel,53,Mayenne,52,Pays de la Loire
53272,VILLEPAIL,53250,VILLEPAIL,,48.4031461586,-0.264153409415,272,,Villepail,Villepail,53,Mayenne,52,Pays de la Loire
53273,VILLIERS CHARLEMAGNE,53170,VILLIERS CHARLEMAGNE,,47.9250890496,-0.682830873383,273,,Villiers-Charlemagne,Villiers-Charlemagne,53,Mayenne,52,Pays de la Loire
53274,VIMARCE,53160,VIMARCE,,48.1878029439,-0.231085796657,274,,Vimarcé,Vimarcé,53,Mayenne,52,Pays de la Loire
53276,VOUTRE,53600,VOUTRE,,48.1374251351,-0.29251688071,276,,Voutré,Voutré,53,Mayenne,52,Pays de la Loire
54001,ABAUCOURT,54610,ABAUCOURT SUR SEILLE,,48.8928445916,6.26559712571,1,,Abaucourt,Abaucourt,54,Meurthe-et-Moselle,44,Grand Est
54002,ABBEVILLE LES CONFLANS,54800,ABBEVILLE LES CONFLANS,,49.2002692912,5.84406780319,2,,Abbéville-lès-Conflans,Abbéville-lès-Conflans,54,Meurthe-et-Moselle,44,Grand Est
54003,ABONCOURT,54115,ABONCOURT,,48.3611044547,5.98255466791,3,,Aboncourt,Aboncourt,54,Meurthe-et-Moselle,44,Grand Est
54004,AFFLEVILLE,54800,AFFLEVILLE,,49.2639666613,5.75288845363,4,,Affléville,Affléville,54,Meurthe-et-Moselle,44,Grand Est
54005,AFFRACOURT,54740,AFFRACOURT,,48.458337046,6.16536195039,5,,Affracourt,Affracourt,54,Meurthe-et-Moselle,44,Grand Est
54006,AGINCOURT,54770,AGINCOURT,,48.7294061965,6.23559240727,6,,Agincourt,Agincourt,54,Meurthe-et-Moselle,44,Grand Est
54007,AINGERAY,54460,AINGERAY,,48.7369624344,6.0192315764,7,,Aingeray,Aingeray,54,Meurthe-et-Moselle,44,Grand Est
54008,ALLAIN,54170,ALLAIN,,48.5496654439,5.92089883259,8,,Allain,Allain,54,Meurthe-et-Moselle,44,Grand Est
54009,ALLAMONT,54800,ALLAMONT,,49.1284916114,5.77659749922,9,,Allamont,Allamont,54,Meurthe-et-Moselle,44,Grand Est
54010,ALLAMPS,54112,ALLAMPS,,48.548374875,5.81464831473,10,,Allamps,Allamps,54,Meurthe-et-Moselle,44,Grand Est
54011,ALLONDRELLE LA MALMAISON,54260,ALLONDRELLE LA MALMAISON,,49.5106931001,5.5687752244,11,,Allondrelle-la-Malmaison,Allondrelle-la-Malmaison,54,Meurthe-et-Moselle,44,Grand Est
54012,AMANCE,54770,AMANCE,,48.754963401,6.30928287834,12,,Amance,Amance,54,Meurthe-et-Moselle,44,Grand Est
54013,AMENONCOURT,54450,AMENONCOURT,,48.6300704003,6.78443520767,13,,Amenoncourt,Amenoncourt,54,Meurthe-et-Moselle,44,Grand Est
54014,ANCERVILLER,54450,ANCERVILLER,,48.5333635859,6.83699257631,14,,Ancerviller,Ancerviller,54,Meurthe-et-Moselle,44,Grand Est
54015,ANDERNY,54560,ANDERNY,,49.3356448662,5.88195094203,15,,Anderny,Anderny,54,Meurthe-et-Moselle,44,Grand Est
54016,ANDILLY,54200,ANDILLY,,48.7670812602,5.88801466262,16,,Andilly,Andilly,54,Meurthe-et-Moselle,44,Grand Est
54017,ANGOMONT,54540,ANGOMONT,,48.504868026,6.97199355795,17,,Angomont,Angomont,54,Meurthe-et-Moselle,44,Grand Est
54018,ANOUX,54150,ANOUX,,49.2727570971,5.8647091829,18,,Anoux,Anoux,54,Meurthe-et-Moselle,44,Grand Est
54019,ANSAUVILLE,54470,ANSAUVILLE,,48.8123329537,5.82286125547,19,,Ansauville,Ansauville,54,Meurthe-et-Moselle,44,Grand Est
54020,ANTHELUPT,54110,ANTHELUPT,,48.6033445755,6.41530002506,20,,Anthelupt,Anthelupt,54,Meurthe-et-Moselle,44,Grand Est
54021,ARMAUCOURT,54760,ARMAUCOURT,,48.8125986306,6.298257299,21,,Armaucourt,Armaucourt,54,Meurthe-et-Moselle,44,Grand Est
54022,ARNAVILLE,54530,ARNAVILLE,,49.0131279391,6.02593659149,22,,Arnaville,Arnaville,54,Meurthe-et-Moselle,44,Grand Est
54023,ARRACOURT,54370,ARRACOURT,,48.7246216504,6.53304766529,23,,Arracourt,Arracourt,54,Meurthe-et-Moselle,44,Grand Est
54024,ARRAYE ET HAN,54760,ARRAYE ET HAN,,48.8354421206,6.28872660857,24,,Arraye-et-Han,Arraye-et-Han,54,Meurthe-et-Moselle,44,Grand Est
54025,ART SUR MEURTHE,54510,ART SUR MEURTHE,,48.6605653469,6.26295482069,25,,Art-sur-Meurthe,Art-sur-Meurthe,54,Meurthe-et-Moselle,44,Grand Est
54025,ART SUR MEURTHE,54510,ART SUR MEURTHE,BOSSERVILLE,48.6605653469,6.26295482069,25,,Art-sur-Meurthe,Art-sur-Meurthe,54,Meurthe-et-Moselle,44,Grand Est
54026,ATHIENVILLE,54370,ATHIENVILLE,,48.7174707236,6.48605238169,26,,Athienville,Athienville,54,Meurthe-et-Moselle,44,Grand Est
54027,ATTON,54700,ATTON,,48.892928495,6.11100019961,27,,Atton,Atton,54,Meurthe-et-Moselle,44,Grand Est
54028,AUBOUE,54580,AUBOUE,,49.2112474141,5.97509767363,28,,Auboué,Auboué,54,Meurthe-et-Moselle,44,Grand Est
54029,AUDUN LE ROMAN,54560,AUDUN LE ROMAN,,49.3679143393,5.89363457214,29,,Audun-le-Roman,Audun-le-Roman,54,Meurthe-et-Moselle,44,Grand Est
54030,AUTREPIERRE,54450,AUTREPIERRE,,48.60801728,6.79797382207,30,,Autrepierre,Autrepierre,54,Meurthe-et-Moselle,44,Grand Est
54031,AUTREVILLE SUR MOSELLE,54380,AUTREVILLE SUR MOSELLE,,48.8287005082,6.10778514599,31,,Autreville-sur-Moselle,Autreville-sur-Moselle,54,Meurthe-et-Moselle,44,Grand Est
54032,AUTREY,54160,AUTREY,,48.5315476318,6.12732307982,32,,Autrey,Autrey,54,Meurthe-et-Moselle,44,Grand Est
54033,AVILLERS,54490,AVILLERS,,49.3225310079,5.73813667687,33,,Avillers,Avillers,54,Meurthe-et-Moselle,44,Grand Est
54034,AVRAINVILLE,54385,AVRAINVILLE,,48.7714993611,5.94649132503,34,,Avrainville,Avrainville,54,Meurthe-et-Moselle,44,Grand Est
54035,AVRICOURT,54450,AVRICOURT,,48.6425808657,6.81321103492,35,,Avricourt,Avricourt,54,Meurthe-et-Moselle,44,Grand Est
54036,AVRIL,54150,AVRIL,,49.2941688536,5.96776937418,36,,Avril,Avril,54,Meurthe-et-Moselle,44,Grand Est
54037,AZELOT,54210,AZELOT,,48.5837575734,6.22938782702,37,,Azelot,Azelot,54,Meurthe-et-Moselle,44,Grand Est
54038,AZERAILLES,54122,AZERAILLES,,48.4972745986,6.6948706717,38,,Azerailles,Azerailles,54,Meurthe-et-Moselle,44,Grand Est
54039,BACCARAT,54120,BACCARAT,,48.4499656424,6.74057641633,39,,Baccarat,Baccarat,54,Meurthe-et-Moselle,44,Grand Est
54039,BACCARAT,54120,BACCARAT,BADMENIL,48.4499656424,6.74057641633,39,,Baccarat,Baccarat,54,Meurthe-et-Moselle,44,Grand Est
54039,BACCARAT,54120,BACCARAT,CRIVILLER,48.4499656424,6.74057641633,39,,Baccarat,Baccarat,54,Meurthe-et-Moselle,44,Grand Est
54040,BADONVILLER,54540,BADONVILLER,,48.4949206323,6.91010597045,40,,Badonviller,Badonviller,54,Meurthe-et-Moselle,44,Grand Est
54041,BAGNEUX,54170,BAGNEUX,,48.5571215852,5.87679654588,41,,Bagneux,Bagneux,54,Meurthe-et-Moselle,44,Grand Est
54042,BAINVILLE AUX MIROIRS,54290,BAINVILLE AUX MIROIRS,,48.4363808241,6.2833736901,42,,Bainville-aux-Miroirs,Bainville-aux-Miroirs,54,Meurthe-et-Moselle,44,Grand Est
54043,BAINVILLE SUR MADON,54550,BAINVILLE SUR MADON,,48.5900970896,6.08780771245,43,,Bainville-sur-Madon,Bainville-sur-Madon,54,Meurthe-et-Moselle,44,Grand Est
54044,BARBAS,54450,BARBAS,,48.5709454434,6.8498123198,44,,Barbas,Barbas,54,Meurthe-et-Moselle,44,Grand Est
54045,BARBONVILLE,54360,BARBONVILLE,,48.5504061988,6.35579001053,45,,Barbonville,Barbonville,54,Meurthe-et-Moselle,44,Grand Est
54046,BARISEY AU PLAIN,54170,BARISEY AU PLAIN,,48.5234474389,5.85202368314,46,,Barisey-au-Plain,Barisey-au-Plain,54,Meurthe-et-Moselle,44,Grand Est
54047,BARISEY LA COTE,54170,BARISEY LA COTE,,48.5457983543,5.84382509461,47,,Barisey-la-Côte,Barisey-la-Côte,54,Meurthe-et-Moselle,44,Grand Est
54048,LES BAROCHES,54150,LES BAROCHES,,49.229921661,5.88812607827,48,Les,Baroches,Les Baroches,54,Meurthe-et-Moselle,44,Grand Est
54048,LES BAROCHES,54150,LES BAROCHES,GENAVILLE,49.229921661,5.88812607827,48,Les,Baroches,Les Baroches,54,Meurthe-et-Moselle,44,Grand Est
54049,BASLIEUX,54620,BASLIEUX,,49.4380044151,5.75072929196,49,,Baslieux,Baslieux,54,Meurthe-et-Moselle,44,Grand Est
54050,BATHELEMONT,54370,BATHELEMONT,,48.6931563796,6.52703036369,50,,Bathelémont,Bathelémont,54,Meurthe-et-Moselle,44,Grand Est
54051,BATILLY,54980,BATILLY,,49.1770727995,5.9696995649,51,,Batilly,Batilly,54,Meurthe-et-Moselle,44,Grand Est
54052,BATTIGNY,54115,BATTIGNY,,48.4439079418,5.98482135856,52,,Battigny,Battigny,54,Meurthe-et-Moselle,44,Grand Est
54053,BAUZEMONT,54370,BAUZEMONT,,48.6709985624,6.53100828935,53,,Bauzemont,Bauzemont,54,Meurthe-et-Moselle,44,Grand Est
54054,BAYON,54290,BAYON,,48.479603201,6.32139080605,54,,Bayon,Bayon,54,Meurthe-et-Moselle,44,Grand Est
54055,BAYONVILLE SUR MAD,54890,BAYONVILLE SUR MAD,,49.0159045252,5.99755026725,55,,Bayonville-sur-Mad,Bayonville-sur-Mad,54,Meurthe-et-Moselle,44,Grand Est
54056,BAZAILLES,54620,BAZAILLES,,49.4100137123,5.76596330618,56,,Bazailles,Bazailles,54,Meurthe-et-Moselle,44,Grand Est
54057,BEAUMONT,54470,BEAUMONT,,48.8517198736,5.78823029157,57,,Beaumont,Beaumont,54,Meurthe-et-Moselle,44,Grand Est
54058,BECHAMPS,54800,BECHAMPS,,49.2140558586,5.74244122383,58,,Béchamps,Béchamps,54,Meurthe-et-Moselle,44,Grand Est
54059,BELLEAU,54610,BELLEAU,,48.8487345093,6.18702961708,59,,Belleau,Belleau,54,Meurthe-et-Moselle,44,Grand Est
54059,BELLEAU,54610,BELLEAU,LIXIERES,48.8487345093,6.18702961708,59,,Belleau,Belleau,54,Meurthe-et-Moselle,44,Grand Est
54059,BELLEAU,54610,BELLEAU,MANONCOURT SUR SEILLE,48.8487345093,6.18702961708,59,,Belleau,Belleau,54,Meurthe-et-Moselle,44,Grand Est
54059,BELLEAU,54610,BELLEAU,MOREY,48.8487345093,6.18702961708,59,,Belleau,Belleau,54,Meurthe-et-Moselle,44,Grand Est
54059,BELLEAU,54610,BELLEAU,SERRIERES,48.8487345093,6.18702961708,59,,Belleau,Belleau,54,Meurthe-et-Moselle,44,Grand Est
54060,BELLEVILLE,54940,BELLEVILLE,,48.8144707448,6.08968796916,60,,Belleville,Belleville,54,Meurthe-et-Moselle,44,Grand Est
54061,BENAMENIL,54450,BENAMENIL,,48.5644531096,6.66195339245,61,,Bénaménil,Bénaménil,54,Meurthe-et-Moselle,44,Grand Est
54062,BENNEY,54740,BENNEY,,48.5246335005,6.2245124594,62,,Benney,Benney,54,Meurthe-et-Moselle,44,Grand Est
54063,BERNECOURT,54470,BERNECOURT,,48.8511844853,5.84154231501,63,,Bernécourt,Bernécourt,54,Meurthe-et-Moselle,44,Grand Est
54064,BERTRAMBOIS,54480,BERTRAMBOIS,,48.576979378,7.01618231059,64,,Bertrambois,Bertrambois,54,Meurthe-et-Moselle,44,Grand Est
54065,BERTRICHAMPS,54120,BERTRICHAMPS,,48.4351443357,6.81228115142,65,,Bertrichamps,Bertrichamps,54,Meurthe-et-Moselle,44,Grand Est
54066,BETTAINVILLERS,54640,BETTAINVILLERS,,49.2970531393,5.90972467758,66,,Bettainvillers,Bettainvillers,54,Meurthe-et-Moselle,44,Grand Est
54067,BEUVEILLE,54620,BEUVEILLE,,49.4363497823,5.67562424969,67,,Beuveille,Beuveille,54,Meurthe-et-Moselle,44,Grand Est
54068,BEUVEZIN,54115,BEUVEZIN,,48.3785491656,5.97284907185,68,,Beuvezin,Beuvezin,54,Meurthe-et-Moselle,44,Grand Est
54069,BEUVILLERS,54560,BEUVILLERS,,49.3870500621,5.91906739563,69,,Beuvillers,Beuvillers,54,Meurthe-et-Moselle,44,Grand Est
54070,BEY SUR SEILLE,54760,BEY SUR SEILLE,,48.7957774029,6.33608176026,70,,Bey-sur-Seille,Bey-sur-Seille,54,Meurthe-et-Moselle,44,Grand Est
54071,BEZANGE LA GRANDE,54370,BEZANGE LA GRANDE,,48.747640463,6.47273971333,71,,Bezange-la-Grande,Bezange-la-Grande,54,Meurthe-et-Moselle,44,Grand Est
54072,BEZAUMONT,54380,BEZAUMONT,,48.8484200682,6.10334870452,72,,Bezaumont,Bezaumont,54,Meurthe-et-Moselle,44,Grand Est
54073,BICQUELEY,54200,BICQUELEY,,48.6236921452,5.92521609903,73,,Bicqueley,Bicqueley,54,Meurthe-et-Moselle,44,Grand Est
54074,BIENVILLE LA PETITE,54300,BIENVILLE LA PETITE,,48.633628939,6.5126118634,74,,Bienville-la-Petite,Bienville-la-Petite,54,Meurthe-et-Moselle,44,Grand Est
54075,BIONVILLE,54540,BIONVILLE,,48.495041795,7.02179760093,75,,Bionville,Bionville,54,Meurthe-et-Moselle,44,Grand Est
54076,BLAINVILLE SUR L EAU,54360,BLAINVILLE SUR L EAU,,48.5501857312,6.41522344884,76,,Blainville-sur-l'Eau,Blainville-sur-l'Eau,54,Meurthe-et-Moselle,44,Grand Est
54077,BLAMONT,54450,BLAMONT,,48.5905345206,6.84253315857,77,,Blâmont,Blâmont,54,Meurthe-et-Moselle,44,Grand Est
54078,BLEMEREY,54450,BLEMEREY,,48.5821365938,6.73701286982,78,,Blémerey,Blémerey,54,Meurthe-et-Moselle,44,Grand Est
54079,BLENOD LES PONT A MOUSSON,54700,BLENOD LES PONT A MOUSSON,,48.8752634053,6.0622081682,79,,Blénod-lès-Pont-à-Mousson,Blénod-lès-Pont-à-Mousson,54,Meurthe-et-Moselle,44,Grand Est
54080,BLENOD LES TOUL,54113,BLENOD LES TOUL,,48.5971874239,5.82709869884,80,,Blénod-lès-Toul,Blénod-lès-Toul,54,Meurthe-et-Moselle,44,Grand Est
54081,BOISMONT,54620,BOISMONT,,49.4110699513,5.7403735088,81,,Boismont,Boismont,54,Meurthe-et-Moselle,44,Grand Est
54082,BONCOURT,54800,BONCOURT,,49.1725135773,5.82265705678,82,,Boncourt,Boncourt,54,Meurthe-et-Moselle,44,Grand Est
54083,BONVILLER,54300,BONVILLER,,48.62740859,6.49547156004,83,,Bonviller,Bonviller,54,Meurthe-et-Moselle,44,Grand Est
54084,MONT BONVILLERS,54111,MONT BONVILLERS,,49.3268467637,5.84287018832,84,,Mont-Bonvillers,Mont-Bonvillers,54,Meurthe-et-Moselle,44,Grand Est
54085,BORVILLE,54290,BORVILLE,,48.4474994656,6.3973303641,85,,Borville,Borville,54,Meurthe-et-Moselle,44,Grand Est
54086,BOUCQ,54200,BOUCQ,,48.7548905415,5.75734618007,86,,Boucq,Boucq,54,Meurthe-et-Moselle,44,Grand Est
54087,BOUILLONVILLE,54470,BOUILLONVILLE,,48.9409400392,5.84719536115,87,,Bouillonville,Bouillonville,54,Meurthe-et-Moselle,44,Grand Est
54088,BOUVRON,54200,BOUVRON,,48.7396311739,5.88530993382,88,,Bouvron,Bouvron,54,Meurthe-et-Moselle,44,Grand Est
54089,BOUXIERES AUX CHENES,54770,BOUXIERES AUX CHENES,,48.7728236838,6.27141981463,89,,Bouxières-aux-Chênes,Bouxières-aux-Chênes,54,Meurthe-et-Moselle,44,Grand Est
54089,BOUXIERES AUX CHENES,54770,BOUXIERES AUX CHENES,MOULINS,48.7728236838,6.27141981463,89,,Bouxières-aux-Chênes,Bouxières-aux-Chênes,54,Meurthe-et-Moselle,44,Grand Est
54090,BOUXIERES AUX DAMES,54136,BOUXIERES AUX DAMES,,48.7585901427,6.16355504539,90,,Bouxières-aux-Dames,Bouxières-aux-Dames,54,Meurthe-et-Moselle,44,Grand Est
54091,BOUXIERES SOUS FROIDMONT,54700,BOUXIERES SOUS FROIDMONT,,48.9553164791,6.10140466054,91,,Bouxières-sous-Froidmont,Bouxières-sous-Froidmont,54,Meurthe-et-Moselle,44,Grand Est
54092,BOUZANVILLE,54930,BOUZANVILLE,,48.3707663422,6.10733218681,92,,Bouzanville,Bouzanville,54,Meurthe-et-Moselle,44,Grand Est
54093,BRAINVILLE,54800,BRAINVILLE,,49.1224087762,5.80859499046,93,,Brainville,Brainville,54,Meurthe-et-Moselle,44,Grand Est
54094,BRALLEVILLE,54740,BRALLEVILLE,,48.4091219312,6.19227832829,94,,Bralleville,Bralleville,54,Meurthe-et-Moselle,44,Grand Est
54095,BRATTE,54610,BRATTE,,48.8164658679,6.21836030059,95,,Bratte,Bratte,54,Meurthe-et-Moselle,44,Grand Est
54096,BREHAIN LA VILLE,54190,BREHAIN LA VILLE,,49.4396153658,5.88537167291,96,,Bréhain-la-Ville,Bréhain-la-Ville,54,Meurthe-et-Moselle,44,Grand Est
54097,BREMENIL,54540,BREMENIL,,48.523168625,6.921302703,97,,Bréménil,Bréménil,54,Meurthe-et-Moselle,44,Grand Est
54098,BREMONCOURT,54290,BREMONCOURT,,48.4918994073,6.35995262318,98,,Brémoncourt,Brémoncourt,54,Meurthe-et-Moselle,44,Grand Est
54099,BRIEY,54150,BRIEY,,49.2557914726,5.97046491466,99,,Val de Briey,Val de Briey,54,Meurthe-et-Moselle,44,Grand Est
54100,BRIN SUR SEILLE,54280,BRIN SUR SEILLE,,48.7740484006,6.35487375911,100,,Brin-sur-Seille,Brin-sur-Seille,54,Meurthe-et-Moselle,44,Grand Est
54101,BROUVILLE,54120,BROUVILLE,,48.4922395199,6.74929356253,101,,Brouville,Brouville,54,Meurthe-et-Moselle,44,Grand Est
54102,BRULEY,54200,BRULEY,,48.7129215737,5.85662158856,102,,Bruley,Bruley,54,Meurthe-et-Moselle,44,Grand Est
54103,BRUVILLE,54800,BRUVILLE,,49.1230005819,5.92560093898,103,,Bruville,Bruville,54,Meurthe-et-Moselle,44,Grand Est
54104,BUISSONCOURT,54110,BUISSONCOURT,,48.6826232641,6.34150058691,104,,Buissoncourt,Buissoncourt,54,Meurthe-et-Moselle,44,Grand Est
54105,BULLIGNY,54113,BULLIGNY,,48.5723193391,5.84368578538,105,,Bulligny,Bulligny,54,Meurthe-et-Moselle,44,Grand Est
54106,BURES,54370,BURES,,48.6982412473,6.57054339464,106,,Bures,Bures,54,Meurthe-et-Moselle,44,Grand Est
54107,BURIVILLE,54450,BURIVILLE,,48.5370380722,6.68839442053,107,,Buriville,Buriville,54,Meurthe-et-Moselle,44,Grand Est
54108,BURTHECOURT AUX CHENES,54210,BURTHECOURT AUX CHENES,,48.5777526054,6.24827376448,108,,Burthecourt-aux-Chênes,Burthecourt-aux-Chênes,54,Meurthe-et-Moselle,44,Grand Est
54109,CEINTREY,54134,CEINTREY,,48.5333833934,6.17792744649,109,,Ceintrey,Ceintrey,54,Meurthe-et-Moselle,44,Grand Est
54110,CERVILLE,54420,CERVILLE,,48.6953044901,6.31206321567,110,,Cerville,Cerville,54,Meurthe-et-Moselle,44,Grand Est
54111,CHALIGNY,54230,CHALIGNY,,48.6369570839,6.09005794818,111,,Chaligny,Chaligny,54,Meurthe-et-Moselle,44,Grand Est
54112,CHAMBLEY BUSSIERES,54890,CHAMBLEY BUSSIERES,,49.051934493,5.91549235178,112,,Chambley-Bussières,Chambley-Bussières,54,Meurthe-et-Moselle,44,Grand Est
54113,CHAMPENOUX,54280,CHAMPENOUX,,48.7344291014,6.35304245931,113,,Champenoux,Champenoux,54,Meurthe-et-Moselle,44,Grand Est
54114,CHAMPEY SUR MOSELLE,54700,CHAMPEY SUR MOSELLE,,48.9572800681,6.06611088952,114,,Champey-sur-Moselle,Champey-sur-Moselle,54,Meurthe-et-Moselle,44,Grand Est
54115,CHAMPIGNEULLES,54250,CHAMPIGNEULLES,,48.7209128078,6.12290248432,115,,Champigneulles,Champigneulles,54,Meurthe-et-Moselle,44,Grand Est
54116,CHANTEHEUX,54300,CHANTEHEUX,,48.5935603101,6.53792470418,116,,Chanteheux,Chanteheux,54,Meurthe-et-Moselle,44,Grand Est
54117,CHAOUILLEY,54330,CHAOUILLEY,,48.4409660806,6.0668878378,117,,Chaouilley,Chaouilley,54,Meurthe-et-Moselle,44,Grand Est
54118,CHARENCY VEZIN,54260,CHARENCY VEZIN,,49.4847390739,5.51467592253,118,,Charency-Vezin,Charency-Vezin,54,Meurthe-et-Moselle,44,Grand Est
54119,CHAREY,54470,CHAREY,,48.9959312747,5.86520504315,119,,Charey,Charey,54,Meurthe-et-Moselle,44,Grand Est
54120,CHARMES LA COTE,54113,CHARMES LA COTE,,48.6249619246,5.82750911815,120,,Charmes-la-Côte,Charmes-la-Côte,54,Meurthe-et-Moselle,44,Grand Est
54121,CHARMOIS,54360,CHARMOIS,,48.5345693782,6.38928827568,121,,Charmois,Charmois,54,Meurthe-et-Moselle,44,Grand Est
54122,CHAUDENEY SUR MOSELLE,54200,CHAUDENEY SUR MOSELLE,,48.6549674771,5.92432019683,122,,Chaudeney-sur-Moselle,Chaudeney-sur-Moselle,54,Meurthe-et-Moselle,44,Grand Est
54123,CHAVIGNY,54230,CHAVIGNY,,48.6318722028,6.13387769869,123,,Chavigny,Chavigny,54,Meurthe-et-Moselle,44,Grand Est
54124,CHAZELLES SUR ALBE,54450,CHAZELLES SUR ALBE,,48.5877870031,6.77089895622,124,,Chazelles-sur-Albe,Chazelles-sur-Albe,54,Meurthe-et-Moselle,44,Grand Est
54125,CHENEVIERES,54122,CHENEVIERES,,48.5218297908,6.63786246234,125,,Chenevières,Chenevières,54,Meurthe-et-Moselle,44,Grand Est
54126,CHENICOURT,54610,CHENICOURT,,48.8581632637,6.28336506418,126,,Chenicourt,Chenicourt,54,Meurthe-et-Moselle,44,Grand Est
54127,CHENIERES,54720,CHENIERES,,49.4742785657,5.76605374538,127,,Chenières,Chenières,54,Meurthe-et-Moselle,44,Grand Est
54128,CHOLOY MENILLOT,54200,CHOLOY MENILLOT,,48.6534236231,5.80737190718,128,,Choloy-Ménillot,Choloy-Ménillot,54,Meurthe-et-Moselle,44,Grand Est
54128,CHOLOY MENILLOT,54200,CHOLOY MENILLOT,MENILLOT,48.6534236231,5.80737190718,128,,Choloy-Ménillot,Choloy-Ménillot,54,Meurthe-et-Moselle,44,Grand Est
54129,CIREY SUR VEZOUZE,54480,CIREY SUR VEZOUZE,,48.5858686466,6.9448900006,129,,Cirey-sur-Vezouze,Cirey-sur-Vezouze,54,Meurthe-et-Moselle,44,Grand Est
54130,CLAYEURES,54290,CLAYEURES,,48.4695576115,6.4094671888,130,,Clayeures,Clayeures,54,Meurthe-et-Moselle,44,Grand Est
54131,CLEMERY,54610,CLEMERY,,48.8830969785,6.18034249181,131,,Clémery,Clémery,54,Meurthe-et-Moselle,44,Grand Est
54132,CLEREY SUR BRENON,54330,CLEREY SUR BRENON,,48.5062432638,6.13755407578,132,,Clérey-sur-Brenon,Clérey-sur-Brenon,54,Meurthe-et-Moselle,44,Grand Est
54133,COINCOURT,54370,COINCOURT,,48.7020703567,6.61728962108,133,,Coincourt,Coincourt,54,Meurthe-et-Moselle,44,Grand Est
54134,COLMEY,54260,COLMEY,,49.4630021306,5.55236900348,134,,Colmey,Colmey,54,Meurthe-et-Moselle,44,Grand Est
54134,COLMEY,54260,COLMEY,FLABEUVILLE,49.4630021306,5.55236900348,134,,Colmey,Colmey,54,Meurthe-et-Moselle,44,Grand Est
54135,COLOMBEY LES BELLES,54170,COLOMBEY LES BELLES,,48.5163026509,5.89559203198,135,,Colombey-les-Belles,Colombey-les-Belles,54,Meurthe-et-Moselle,44,Grand Est
54136,CONFLANS EN JARNISY,54800,CONFLANS EN JARNISY,,49.1684203113,5.8479719726,136,,Conflans-en-Jarnisy,Conflans-en-Jarnisy,54,Meurthe-et-Moselle,44,Grand Est
54137,CONS LA GRANDVILLE,54870,CONS LA GRANDVILLE,,49.4856090372,5.70114135723,137,,Cons-la-Grandville,Cons-la-Grandville,54,Meurthe-et-Moselle,44,Grand Est
54138,COSNES ET ROMAIN,54400,COSNES ET ROMAIN,,49.525596238,5.71659969266,138,,Cosnes-et-Romain,Cosnes-et-Romain,54,Meurthe-et-Moselle,44,Grand Est
54138,COSNES ET ROMAIN,54400,COSNES ET ROMAIN,VAUX WARNIMONT,49.525596238,5.71659969266,138,,Cosnes-et-Romain,Cosnes-et-Romain,54,Meurthe-et-Moselle,44,Grand Est
54139,COURBESSEAUX,54110,COURBESSEAUX,,48.6914376855,6.4022815813,139,,Courbesseaux,Courbesseaux,54,Meurthe-et-Moselle,44,Grand Est
54140,COURCELLES,54930,COURCELLES,,48.3691022452,6.03113443127,140,,Courcelles,Courcelles,54,Meurthe-et-Moselle,44,Grand Est
54141,COYVILLER,54210,COYVILLER,,48.5853465771,6.28322914765,141,,Coyviller,Coyviller,54,Meurthe-et-Moselle,44,Grand Est
54142,CRANTENOY,54740,CRANTENOY,,48.4671335804,6.23200302278,142,,Crantenoy,Crantenoy,54,Meurthe-et-Moselle,44,Grand Est
54143,CREPEY,54170,CREPEY,,48.528093841,5.96194835495,143,,Crépey,Crépey,54,Meurthe-et-Moselle,44,Grand Est
54144,CREVECHAMPS,54290,CREVECHAMPS,,48.5192078306,6.26378884079,144,,Crévéchamps,Crévéchamps,54,Meurthe-et-Moselle,44,Grand Est
54145,CREVIC,54110,CREVIC,,48.6397116744,6.40645781055,145,,Crévic,Crévic,54,Meurthe-et-Moselle,44,Grand Est
54146,CREZILLES,54113,CREZILLES,,48.5822792016,5.88930622746,146,,Crézilles,Crézilles,54,Meurthe-et-Moselle,44,Grand Est
54147,CRION,54300,CRION,,48.6444858777,6.55052825992,147,,Crion,Crion,54,Meurthe-et-Moselle,44,Grand Est
54148,CROISMARE,54300,CROISMARE,,48.6126708787,6.57201025704,148,,Croismare,Croismare,54,Meurthe-et-Moselle,44,Grand Est
54149,CRUSNES,54680,CRUSNES,,49.4338705839,5.9207515095,149,,Crusnes,Crusnes,54,Meurthe-et-Moselle,44,Grand Est
54150,CUSTINES,54670,CUSTINES,,48.7893818613,6.14616923168,150,,Custines,Custines,54,Meurthe-et-Moselle,44,Grand Est
54151,CUTRY,54720,CUTRY,,49.4812435903,5.73774950796,151,,Cutry,Cutry,54,Meurthe-et-Moselle,44,Grand Est
54152,DAMELEVIERES,54360,DAMELEVIERES,,48.5664288239,6.39207304688,152,,Damelevières,Damelevières,54,Meurthe-et-Moselle,44,Grand Est
54153,DAMPVITOUX,54470,DAMPVITOUX,,49.0062419838,5.83175566603,153,,Dampvitoux,Dampvitoux,54,Meurthe-et-Moselle,44,Grand Est
54154,DENEUVRE,54120,DENEUVRE,,48.4268871835,6.72071255897,154,,Deneuvre,Deneuvre,54,Meurthe-et-Moselle,44,Grand Est
54155,DEUXVILLE,54370,DEUXVILLE,,48.6184739539,6.45812112896,155,,Deuxville,Deuxville,54,Meurthe-et-Moselle,44,Grand Est
54156,DIARVILLE,54930,DIARVILLE,,48.3870401502,6.13074893357,156,,Diarville,Diarville,54,Meurthe-et-Moselle,44,Grand Est
54157,DIEULOUARD,54380,DIEULOUARD,,48.8340364411,6.05567802778,157,,Dieulouard,Dieulouard,54,Meurthe-et-Moselle,44,Grand Est
54158,DOLCOURT,54170,DOLCOURT,,48.4903130398,5.97835316985,158,,Dolcourt,Dolcourt,54,Meurthe-et-Moselle,44,Grand Est
54159,DOMBASLE SUR MEURTHE,54110,DOMBASLE SUR MEURTHE,,48.6191265471,6.36071318649,159,,Dombasle-sur-Meurthe,Dombasle-sur-Meurthe,54,Meurthe-et-Moselle,44,Grand Est
54160,DOMEVRE EN HAYE,54385,DOMEVRE EN HAYE,,48.8145983762,5.93513051722,160,,Domèvre-en-Haye,Domèvre-en-Haye,54,Meurthe-et-Moselle,44,Grand Est
54161,DOMEVRE SUR VEZOUZE,54450,DOMEVRE SUR VEZOUZE,,48.5610695418,6.80087230375,161,,Domèvre-sur-Vezouze,Domèvre-sur-Vezouze,54,Meurthe-et-Moselle,44,Grand Est
54162,DOMGERMAIN,54119,DOMGERMAIN,,48.6414563077,5.82353906952,162,,Domgermain,Domgermain,54,Meurthe-et-Moselle,44,Grand Est
54163,DOMJEVIN,54450,DOMJEVIN,,48.5805107093,6.69825075007,163,,Domjevin,Domjevin,54,Meurthe-et-Moselle,44,Grand Est
54164,DOMMARIE EULMONT,54115,DOMMARIE EULMONT,,48.4188444758,6.03214502759,164,,Dommarie-Eulmont,Dommarie-Eulmont,54,Meurthe-et-Moselle,44,Grand Est
54165,DOMMARTEMONT,54130,DOMMARTEMONT,,48.7171280907,6.21562801792,165,,Dommartemont,Dommartemont,54,Meurthe-et-Moselle,44,Grand Est
54166,DOMMARTIN LA CHAUSSEE,54470,DOMMARTIN LA CHAUSSEE,,49.0108189344,5.86625964379,166,,Dommartin-la-Chaussée,Dommartin-la-Chaussée,54,Meurthe-et-Moselle,44,Grand Est
54167,DOMMARTIN LES TOUL,54200,DOMMARTIN LES TOUL,,48.6713430062,5.92526202105,167,,Dommartin-lès-Toul,Dommartin-lès-Toul,54,Meurthe-et-Moselle,44,Grand Est
54168,DOMMARTIN SOUS AMANCE,54770,DOMMARTIN SOUS AMANCE,,48.7347808105,6.25195648696,168,,Dommartin-sous-Amance,Dommartin-sous-Amance,54,Meurthe-et-Moselle,44,Grand Est
54169,DOMPRIX,54490,DOMPRIX,,49.3288239969,5.76432925243,169,,Domprix,Domprix,54,Meurthe-et-Moselle,44,Grand Est
54169,DOMPRIX,54490,DOMPRIX,BERTRAMEIX,49.3288239969,5.76432925243,169,,Domprix,Domprix,54,Meurthe-et-Moselle,44,Grand Est
54170,DOMPTAIL EN L AIR,54290,DOMPTAIL EN L AIR,,48.5112118028,6.33509789172,170,,Domptail-en-l'Air,Domptail-en-l'Air,54,Meurthe-et-Moselle,44,Grand Est
54171,DONCOURT LES CONFLANS,54800,DONCOURT LES CONFLANS,,49.1460686126,5.93149020305,171,,Doncourt-lès-Conflans,Doncourt-lès-Conflans,54,Meurthe-et-Moselle,44,Grand Est
54172,DONCOURT LES LONGUYON,54620,DONCOURT LES LONGUYON,,49.442470433,5.71492699523,172,,Doncourt-lès-Longuyon,Doncourt-lès-Longuyon,54,Meurthe-et-Moselle,44,Grand Est
54173,DROUVILLE,54370,DROUVILLE,,48.670392727,6.41174385859,173,,Drouville,Drouville,54,Meurthe-et-Moselle,44,Grand Est
54174,ECROUVES,54200,ECROUVES,,48.6801928361,5.84268931795,174,,Écrouves,Écrouves,54,Meurthe-et-Moselle,44,Grand Est
54174,ECROUVES,54200,ECROUVES,GRAND MENIL,48.6801928361,5.84268931795,174,,Écrouves,Écrouves,54,Meurthe-et-Moselle,44,Grand Est
54175,EINVAUX,54360,EINVAUX,,48.4930960422,6.39330940695,175,,Einvaux,Einvaux,54,Meurthe-et-Moselle,44,Grand Est
54176,EINVILLE AU JARD,54370,EINVILLE AU JARD,,48.6558058764,6.4779137062,176,,Einville-au-Jard,Einville-au-Jard,54,Meurthe-et-Moselle,44,Grand Est
54177,EMBERMENIL,54370,EMBERMENIL,,48.6376861214,6.68261184638,177,,Emberménil,Emberménil,54,Meurthe-et-Moselle,44,Grand Est
54178,EPIEZ SUR CHIERS,54260,EPIEZ SUR CHIERS,,49.5006972676,5.50053332941,178,,Épiez-sur-Chiers,Épiez-sur-Chiers,54,Meurthe-et-Moselle,44,Grand Est
54179,EPLY,54610,EPLY,,48.9252261568,6.18124047726,179,,Éply,Éply,54,Meurthe-et-Moselle,44,Grand Est
54180,ERBEVILLER SUR AMEZULE,54280,ERBEVILLER SUR AMEZULE,,48.7297219006,6.38825640905,180,,Erbéviller-sur-Amezule,Erbéviller-sur-Amezule,54,Meurthe-et-Moselle,44,Grand Est
54181,ERROUVILLE,54680,ERROUVILLE,,49.417401541,5.89794840942,181,,Errouville,Errouville,54,Meurthe-et-Moselle,44,Grand Est
54182,ESSEY ET MAIZERAIS,54470,ESSEY ET MAIZERAIS,,48.9110254277,5.80601775099,182,,Essey-et-Maizerais,Essey-et-Maizerais,54,Meurthe-et-Moselle,44,Grand Est
54183,ESSEY LA COTE,54830,ESSEY LA COTE,,48.423637788,6.47050708108,183,,Essey-la-Côte,Essey-la-Côte,54,Meurthe-et-Moselle,44,Grand Est
54184,ESSEY LES NANCY,54270,ESSEY LES NANCY,,48.7075649773,6.23284287776,184,,Essey-lès-Nancy,Essey-lès-Nancy,54,Meurthe-et-Moselle,44,Grand Est
54185,ETREVAL,54330,ETREVAL,,48.4565833814,6.05321514252,185,,Étreval,Étreval,54,Meurthe-et-Moselle,44,Grand Est
54186,EULMONT,54690,EULMONT,,48.7501265245,6.22215042658,186,,Eulmont,Eulmont,54,Meurthe-et-Moselle,44,Grand Est
54187,EUVEZIN,54470,EUVEZIN,,48.9198099623,5.8592491591,187,,Euvezin,Euvezin,54,Meurthe-et-Moselle,44,Grand Est
54188,FAULX,54760,FAULX,,48.7924837201,6.19928293851,188,,Faulx,Faulx,54,Meurthe-et-Moselle,44,Grand Est
54189,FAVIERES,54115,FAVIERES,,48.4573518357,5.93381901381,189,,Favières,Favières,54,Meurthe-et-Moselle,44,Grand Est
54190,FECOCOURT,54115,FECOCOURT,,48.4030210268,6.00201019233,190,,Fécocourt,Fécocourt,54,Meurthe-et-Moselle,44,Grand Est
54191,FENNEVILLER,54540,FENNEVILLER,,48.4851073736,6.88313404801,191,,Fenneviller,Fenneviller,54,Meurthe-et-Moselle,44,Grand Est
54192,FERRIERES,54210,FERRIERES,,48.5531631357,6.2948206682,192,,Ferrières,Ferrières,54,Meurthe-et-Moselle,44,Grand Est
54193,FEY EN HAYE,54470,FEY EN HAYE,,48.9053568805,5.96408309137,193,,Fey-en-Haye,Fey-en-Haye,54,Meurthe-et-Moselle,44,Grand Est
54194,FILLIERES,54560,FILLIERES,,49.4117617368,5.84226468429,194,,Fillières,Fillières,54,Meurthe-et-Moselle,44,Grand Est
54195,FLAINVAL,54110,FLAINVAL,,48.6185325751,6.39765353038,195,,Flainval,Flainval,54,Meurthe-et-Moselle,44,Grand Est
54196,FLAVIGNY SUR MOSELLE,54630,FLAVIGNY SUR MOSELLE,,48.5696887891,6.19347520507,196,,Flavigny-sur-Moselle,Flavigny-sur-Moselle,54,Meurthe-et-Moselle,44,Grand Est
54197,FLEVILLE DEVANT NANCY,54710,FLEVILLE DEVANT NANCY,,48.629778591,6.20386362626,197,,Fléville-devant-Nancy,Fléville-devant-Nancy,54,Meurthe-et-Moselle,44,Grand Est
54198,FLEVILLE LIXIERES,54150,FLEVILLE LIXIERES,,49.2429244428,5.81464794353,198,,Fléville-Lixières,Fléville-Lixières,54,Meurthe-et-Moselle,44,Grand Est
54199,FLIN,54122,FLIN,,48.5030439882,6.65785821113,199,,Flin,Flin,54,Meurthe-et-Moselle,44,Grand Est
54200,FLIREY,54470,FLIREY,,48.8835595963,5.85130889526,200,,Flirey,Flirey,54,Meurthe-et-Moselle,44,Grand Est
54201,FONTENOY LA JOUTE,54122,FONTENOY LA JOUTE,,48.4492444301,6.65552222431,201,,Fontenoy-la-Joûte,Fontenoy-la-Joûte,54,Meurthe-et-Moselle,44,Grand Est
54202,FONTENOY SUR MOSELLE,54840,FONTENOY SUR MOSELLE,,48.7076893045,5.99112188418,202,,Fontenoy-sur-Moselle,Fontenoy-sur-Moselle,54,Meurthe-et-Moselle,44,Grand Est
54203,FORCELLES ST GORGON,54330,FORCELLES ST GORGON,,48.45687195,6.10382987514,203,,Forcelles-Saint-Gorgon,Forcelles-Saint-Gorgon,54,Meurthe-et-Moselle,44,Grand Est
54204,FORCELLES SOUS GUGNEY,54930,FORCELLES SOUS GUGNEY,,48.3923639107,6.08482336842,204,,Forcelles-sous-Gugney,Forcelles-sous-Gugney,54,Meurthe-et-Moselle,44,Grand Est
54205,FOUG,54570,FOUG,,48.6734425678,5.78090769084,205,,Foug,Foug,54,Meurthe-et-Moselle,44,Grand Est
54206,FRAIMBOIS,54300,FRAIMBOIS,,48.525553895,6.54242224099,206,,Fraimbois,Fraimbois,54,Meurthe-et-Moselle,44,Grand Est
54207,FRAISNES EN SAINTOIS,54930,FRAISNES EN SAINTOIS,,48.3742055671,6.06091955537,207,,Fraisnes-en-Saintois,Fraisnes-en-Saintois,54,Meurthe-et-Moselle,44,Grand Est
54208,FRANCHEVILLE,54200,FRANCHEVILLE,,48.7410673805,5.92647201254,208,,Francheville,Francheville,54,Meurthe-et-Moselle,44,Grand Est
54209,FRANCONVILLE,54830,FRANCONVILLE,,48.5002554952,6.4513145872,209,,Franconville,Franconville,54,Meurthe-et-Moselle,44,Grand Est
54210,FREMENIL,54450,FREMENIL,,48.5614320905,6.70677381054,210,,Fréménil,Fréménil,54,Meurthe-et-Moselle,44,Grand Est
54211,FREMONVILLE,54450,FREMONVILLE,,48.6052485765,6.90329247833,211,,Frémonville,Frémonville,54,Meurthe-et-Moselle,44,Grand Est
54212,FRESNOIS LA MONTAGNE,54260,FRESNOIS LA MONTAGNE,,49.4988783585,5.65106914796,212,,Fresnois-la-Montagne,Fresnois-la-Montagne,54,Meurthe-et-Moselle,44,Grand Est
54213,FRIAUVILLE,54800,FRIAUVILLE,,49.1432779641,5.83078364972,213,,Friauville,Friauville,54,Meurthe-et-Moselle,44,Grand Est
54214,FROLOIS,54160,FROLOIS,,48.5721064112,6.12655884356,214,,Frolois,Frolois,54,Meurthe-et-Moselle,44,Grand Est
54215,FROUARD,54390,FROUARD,,48.7504210433,6.12310457526,215,,Frouard,Frouard,54,Meurthe-et-Moselle,44,Grand Est
54216,FROVILLE,54290,FROVILLE,,48.4692546468,6.36462739875,216,,Froville,Froville,54,Meurthe-et-Moselle,44,Grand Est
54217,GELACOURT,54120,GELACOURT,,48.4762022357,6.73171604271,217,,Gélacourt,Gélacourt,54,Meurthe-et-Moselle,44,Grand Est
54218,GELAUCOURT,54115,GELAUCOURT,,48.4586790205,5.98828502243,218,,Gélaucourt,Gélaucourt,54,Meurthe-et-Moselle,44,Grand Est
54219,GELLENONCOURT,54110,GELLENONCOURT,,48.6776898663,6.38064814902,219,,Gellenoncourt,Gellenoncourt,54,Meurthe-et-Moselle,44,Grand Est
54220,GEMONVILLE,54115,GEMONVILLE,,48.4196063205,5.88362556401,220,,Gémonville,Gémonville,54,Meurthe-et-Moselle,44,Grand Est
54221,GERBECOURT ET HAPLEMONT,54740,GERBECOURT ET HAPLEMONT,,48.4887341101,6.15264357464,221,,Gerbécourt-et-Haplemont,Gerbécourt-et-Haplemont,54,Meurthe-et-Moselle,44,Grand Est
54222,GERBEVILLER,54830,GERBEVILLER,,48.4934294526,6.51089844698,222,,Gerbéviller,Gerbéviller,54,Meurthe-et-Moselle,44,Grand Est
54223,GERMINY,54170,GERMINY,,48.5465882829,6.00157138294,223,,Germiny,Germiny,54,Meurthe-et-Moselle,44,Grand Est
54224,GERMONVILLE,54740,GERMONVILLE,,48.4096245856,6.21787659153,224,,Germonville,Germonville,54,Meurthe-et-Moselle,44,Grand Est
54225,GEZONCOURT,54380,GEZONCOURT,,48.8427635602,5.98578970414,225,,Gézoncourt,Gézoncourt,54,Meurthe-et-Moselle,44,Grand Est
54226,GIBEAUMEIX,54112,GIBEAUMEIX,,48.5907333488,5.74299833726,226,,Gibeaumeix,Gibeaumeix,54,Meurthe-et-Moselle,44,Grand Est
54227,GIRAUMONT,54780,GIRAUMONT,,49.173261325,5.91605842347,227,,Giraumont,Giraumont,54,Meurthe-et-Moselle,44,Grand Est
54228,GIRIVILLER,54830,GIRIVILLER,,48.4348846294,6.49599417359,228,,Giriviller,Giriviller,54,Meurthe-et-Moselle,44,Grand Est
54229,GLONVILLE,54122,GLONVILLE,,48.4576693426,6.68733022075,229,,Glonville,Glonville,54,Meurthe-et-Moselle,44,Grand Est
54230,GOGNEY,54450,GOGNEY,,48.6147528073,6.86850513389,230,,Gogney,Gogney,54,Meurthe-et-Moselle,44,Grand Est
54231,GONDRECOURT AIX,54800,GONDRECOURT AIX,,49.2444148346,5.76236135673,231,,Gondrecourt-Aix,Gondrecourt-Aix,54,Meurthe-et-Moselle,44,Grand Est
54232,GONDREVILLE,54840,GONDREVILLE,,48.6824829595,5.98127899343,232,,Gondreville,Gondreville,54,Meurthe-et-Moselle,44,Grand Est
54233,GONDREXON,54450,GONDREXON,,48.6046029179,6.766784271,233,,Gondrexon,Gondrexon,54,Meurthe-et-Moselle,44,Grand Est
54234,GORCY,54730,GORCY,,49.536095003,5.68308068922,234,,Gorcy,Gorcy,54,Meurthe-et-Moselle,44,Grand Est
54235,GOVILLER,54330,GOVILLER,,48.5051745323,6.01397377452,235,,Goviller,Goviller,54,Meurthe-et-Moselle,44,Grand Est
54236,GRAND FAILLY,54260,GRAND FAILLY,,49.4211588157,5.52020012648,236,,Grand-Failly,Grand-Failly,54,Meurthe-et-Moselle,44,Grand Est
54236,GRAND FAILLY,54260,GRAND FAILLY,PETIT XIVRY,49.4211588157,5.52020012648,236,,Grand-Failly,Grand-Failly,54,Meurthe-et-Moselle,44,Grand Est
54237,GRIMONVILLER,54115,GRIMONVILLER,,48.380828765,6.00797124692,237,,Grimonviller,Grimonviller,54,Meurthe-et-Moselle,44,Grand Est
54238,GRIPPORT,54290,GRIPPORT,,48.4156194741,6.24799886583,238,,Gripport,Gripport,54,Meurthe-et-Moselle,44,Grand Est
54239,GRISCOURT,54380,GRISCOURT,,48.8410498647,6.01719019687,239,,Griscourt,Griscourt,54,Meurthe-et-Moselle,44,Grand Est
54240,GROSROUVRES,54470,GROSROUVRES,,48.8291215221,5.84616523476,240,,Grosrouvres,Grosrouvres,54,Meurthe-et-Moselle,44,Grand Est
54241,GUGNEY,54930,GUGNEY,,48.3953048359,6.05847657517,241,,Gugney,Gugney,54,Meurthe-et-Moselle,44,Grand Est
54242,GYE,54113,GYE,,48.6248391973,5.87737525004,242,,Gye,Gye,54,Meurthe-et-Moselle,44,Grand Est
54243,HABLAINVILLE,54120,HABLAINVILLE,,48.5156599634,6.7191166301,243,,Hablainville,Hablainville,54,Meurthe-et-Moselle,44,Grand Est
54244,HAGEVILLE,54470,HAGEVILLE,,49.0316653024,5.86433857707,244,,Hagéville,Hagéville,54,Meurthe-et-Moselle,44,Grand Est
54245,HAIGNEVILLE,54290,HAIGNEVILLE,,48.4842703886,6.34106087649,245,,Haigneville,Haigneville,54,Meurthe-et-Moselle,44,Grand Est
54246,HALLOVILLE,54450,HALLOVILLE,,48.5513232103,6.85953452145,246,,Halloville,Halloville,54,Meurthe-et-Moselle,44,Grand Est
54247,HAMMEVILLE,54330,HAMMEVILLE,,48.498931189,6.07133226205,247,,Hammeville,Hammeville,54,Meurthe-et-Moselle,44,Grand Est
54248,HAMONVILLE,54470,HAMONVILLE,,48.8244409233,5.808080587,248,,Hamonville,Hamonville,54,Meurthe-et-Moselle,44,Grand Est
54249,HANNONVILLE SUZEMONT,54800,HANNONVILLE SUZEMONT,,49.098891613,5.83676444356,249,,Hannonville-Suzémont,Hannonville-Suzémont,54,Meurthe-et-Moselle,44,Grand Est
54250,HARAUCOURT,54110,HARAUCOURT,,48.6614357963,6.36110636976,250,,Haraucourt,Haraucourt,54,Meurthe-et-Moselle,44,Grand Est
54251,HARBOUEY,54450,HARBOUEY,,48.5706375328,6.89406102379,251,,Harbouey,Harbouey,54,Meurthe-et-Moselle,44,Grand Est
54252,HAROUE,54740,HAROUE,,48.4739852791,6.18771142507,252,,Haroué,Haroué,54,Meurthe-et-Moselle,44,Grand Est
54253,HATRIZE,54800,HATRIZE,,49.1964808746,5.89788853353,253,,Hatrize,Hatrize,54,Meurthe-et-Moselle,44,Grand Est
54254,HAUCOURT MOULAINE,54860,HAUCOURT MOULAINE,,49.4965171841,5.80666548469,254,,Haucourt-Moulaine,Haucourt-Moulaine,54,Meurthe-et-Moselle,44,Grand Est
54254,HAUCOURT MOULAINE,54860,HAUCOURT MOULAINE,ST CHARLES,49.4965171841,5.80666548469,254,,Haucourt-Moulaine,Haucourt-Moulaine,54,Meurthe-et-Moselle,44,Grand Est
54255,HAUDONVILLE,54830,HAUDONVILLE,,48.501240806,6.47889738033,255,,Haudonville,Haudonville,54,Meurthe-et-Moselle,44,Grand Est
54256,HAUSSONVILLE,54290,HAUSSONVILLE,,48.528593243,6.32257433396,256,,Haussonville,Haussonville,54,Meurthe-et-Moselle,44,Grand Est
54257,HEILLECOURT,54180,HEILLECOURT,,48.6510825042,6.19906043573,257,,Heillecourt,Heillecourt,54,Meurthe-et-Moselle,44,Grand Est
54258,HENAMENIL,54370,HENAMENIL,,48.6645550394,6.56878209568,258,,Hénaménil,Hénaménil,54,Meurthe-et-Moselle,44,Grand Est
54259,HERBEVILLER,54450,HERBEVILLER,,48.5530200075,6.75411067546,259,,Herbéviller,Herbéviller,54,Meurthe-et-Moselle,44,Grand Est
54260,HERIMENIL,54300,HERIMENIL,,48.5507958672,6.50819869804,260,,Hériménil,Hériménil,54,Meurthe-et-Moselle,44,Grand Est
54261,HERSERANGE,54440,HERSERANGE,,49.5184653931,5.79248530225,261,,Herserange,Herserange,54,Meurthe-et-Moselle,44,Grand Est
54262,HOEVILLE,54370,HOEVILLE,,48.7165627893,6.43301011258,262,,Hoéville,Hoéville,54,Meurthe-et-Moselle,44,Grand Est
54263,HOMECOURT,54310,HOMECOURT,,49.222479805,5.99270563767,263,,Homécourt,Homécourt,54,Meurthe-et-Moselle,44,Grand Est
54264,HOUDELMONT,54330,HOUDELMONT,,48.5366637219,6.09333663622,264,,Houdelmont,Houdelmont,54,Meurthe-et-Moselle,44,Grand Est
54265,HOUDEMONT,54180,HOUDEMONT,,48.641290785,6.1735112537,265,,Houdemont,Houdemont,54,Meurthe-et-Moselle,44,Grand Est
54266,HOUDREVILLE,54330,HOUDREVILLE,,48.513558496,6.09894313371,266,,Houdreville,Houdreville,54,Meurthe-et-Moselle,44,Grand Est
54268,HOUSSEVILLE,54930,HOUSSEVILLE,,48.4077357566,6.11574371641,268,,Housséville,Housséville,54,Meurthe-et-Moselle,44,Grand Est
54269,HUDIVILLER,54110,HUDIVILLER,,48.6000312474,6.39073674521,269,,Hudiviller,Hudiviller,54,Meurthe-et-Moselle,44,Grand Est
54270,HUSSIGNY GODBRANGE,54590,HUSSIGNY GODBRANGE,,49.4955216164,5.85614525592,270,,Hussigny-Godbrange,Hussigny-Godbrange,54,Meurthe-et-Moselle,44,Grand Est
54271,IGNEY,54450,IGNEY,,48.6323470746,6.81983012116,271,,Igney,Igney,54,Meurthe-et-Moselle,44,Grand Est
54272,JAILLON,54200,JAILLON,,48.7618646587,5.97477344119,272,,Jaillon,Jaillon,54,Meurthe-et-Moselle,44,Grand Est
54273,JARNY,54800,JARNY,,49.1495784035,5.88121522823,273,,Jarny,Jarny,54,Meurthe-et-Moselle,44,Grand Est
54273,JARNY,54800,JARNY,DROITAUMONT,49.1495784035,5.88121522823,273,,Jarny,Jarny,54,Meurthe-et-Moselle,44,Grand Est
54274,JARVILLE LA MALGRANGE,54140,JARVILLE LA MALGRANGE,,48.6671595811,6.20465347403,274,,Jarville-la-Malgrange,Jarville-la-Malgrange,54,Meurthe-et-Moselle,44,Grand Est
54275,JAULNY,54470,JAULNY,,48.9667717545,5.89278620377,275,,Jaulny,Jaulny,54,Meurthe-et-Moselle,44,Grand Est
54276,JEANDELAINCOURT,54114,JEANDELAINCOURT,,48.846525852,6.24307502417,276,,Jeandelaincourt,Jeandelaincourt,54,Meurthe-et-Moselle,44,Grand Est
54277,JEANDELIZE,54800,JEANDELIZE,,49.1734340947,5.79665152824,277,,Jeandelize,Jeandelize,54,Meurthe-et-Moselle,44,Grand Est
54278,JEVONCOURT,54740,JEVONCOURT,,48.4141859978,6.16235097783,278,,Jevoncourt,Jevoncourt,54,Meurthe-et-Moselle,44,Grand Est
54279,JEZAINVILLE,54700,JEZAINVILLE,,48.8646635968,6.01606284838,279,,Jezainville,Jezainville,54,Meurthe-et-Moselle,44,Grand Est
54280,JOEUF,54240,JOEUF,,49.2320250417,6.01169605322,280,,Jœuf,Jœuf,54,Meurthe-et-Moselle,44,Grand Est
54281,JOLIVET,54300,JOLIVET,,48.6107978242,6.51789715685,281,,Jolivet,Jolivet,54,Meurthe-et-Moselle,44,Grand Est
54282,JOPPECOURT,54620,JOPPECOURT,,49.3897779591,5.80251158691,282,,Joppécourt,Joppécourt,54,Meurthe-et-Moselle,44,Grand Est
54283,JOUAVILLE,54800,JOUAVILLE,,49.158543784,5.96141063139,283,,Jouaville,Jouaville,54,Meurthe-et-Moselle,44,Grand Est
54284,JOUDREVILLE,54490,JOUDREVILLE,,49.288175052,5.77928680152,284,,Joudreville,Joudreville,54,Meurthe-et-Moselle,44,Grand Est
54285,JUVRECOURT,54370,JUVRECOURT,,48.7407750765,6.56048529163,285,,Juvrecourt,Juvrecourt,54,Meurthe-et-Moselle,44,Grand Est
54286,LABRY,54800,LABRY,,49.1827676063,5.87341636269,286,,Labry,Labry,54,Meurthe-et-Moselle,44,Grand Est
54287,LACHAPELLE,54120,LACHAPELLE,,48.4179142504,6.76909847865,287,,Lachapelle,Lachapelle,54,Meurthe-et-Moselle,44,Grand Est
54288,LAGNEY,54200,LAGNEY,,48.7452329012,5.82895253417,288,,Lagney,Lagney,54,Meurthe-et-Moselle,44,Grand Est
54289,LAITRE SOUS AMANCE,54770,LAITRE SOUS AMANCE,,48.7392097523,6.27378806825,289,,Laître-sous-Amance,Laître-sous-Amance,54,Meurthe-et-Moselle,44,Grand Est
54290,LAIX,54720,LAIX,,49.4515411349,5.77943414827,290,,Laix,Laix,54,Meurthe-et-Moselle,44,Grand Est
54291,LALOEUF,54115,LALOEUF,,48.4682147408,6.00803494037,291,,Lalœuf,Lalœuf,54,Meurthe-et-Moselle,44,Grand Est
54292,LAMATH,54300,LAMATH,,48.5201883757,6.44306273905,292,,Lamath,Lamath,54,Meurthe-et-Moselle,44,Grand Est
54293,LANDECOURT,54360,LANDECOURT,,48.5024280452,6.42185405653,293,,Landécourt,Landécourt,54,Meurthe-et-Moselle,44,Grand Est
54294,LANDREMONT,54380,LANDREMONT,,48.8549774896,6.15220892149,294,,Landremont,Landremont,54,Meurthe-et-Moselle,44,Grand Est
54295,LANDRES,54970,LANDRES,,49.3186906023,5.80755122322,295,,Landres,Landres,54,Meurthe-et-Moselle,44,Grand Est
54296,LANEUVELOTTE,54280,LANEUVELOTTE,,48.7248084973,6.30007004295,296,,Laneuvelotte,Laneuvelotte,54,Meurthe-et-Moselle,44,Grand Est
54297,LANEUVEVILLE AUX BOIS,54370,LANEUVEVILLE AUX BOIS,,48.6227293259,6.64543068381,297,,Laneuveville-aux-Bois,Laneuveville-aux-Bois,54,Meurthe-et-Moselle,44,Grand Est
54298,LANEUVEVILLE DERRIERE FOUG,54570,LANEUVEVILLE DERRIERE FOUG,,48.7076170761,5.80562400536,298,,Laneuveville-derrière-Foug,Laneuveville-derrière-Foug,54,Meurthe-et-Moselle,44,Grand Est
54299,LANEUVEVILLE DEVANT BAYON,54740,LANEUVEVILLE DEVANT BAYON,,48.4708279009,6.26388852498,299,,Laneuveville-devant-Bayon,Laneuveville-devant-Bayon,54,Meurthe-et-Moselle,44,Grand Est
54300,LANEUVEVILLE DEVANT NANCY,54410,LANEUVEVILLE DEVANT NANCY,,48.6462056113,6.24120543129,300,,Laneuveville-devant-Nancy,Laneuveville-devant-Nancy,54,Meurthe-et-Moselle,44,Grand Est
54300,LANEUVEVILLE DEVANT NANCY,54410,LANEUVEVILLE DEVANT NANCY,LA MADELEINE,48.6462056113,6.24120543129,300,,Laneuveville-devant-Nancy,Laneuveville-devant-Nancy,54,Meurthe-et-Moselle,44,Grand Est
54301,LANFROICOURT,54760,LANFROICOURT,,48.8062964781,6.31894910517,301,,Lanfroicourt,Lanfroicourt,54,Meurthe-et-Moselle,44,Grand Est
54302,LANTEFONTAINE,54150,LANTEFONTAINE,,49.2533813537,5.88651395465,302,,Lantéfontaine,Lantéfontaine,54,Meurthe-et-Moselle,44,Grand Est
54302,LANTEFONTAINE,54150,LANTEFONTAINE,IMMONVILLE,49.2533813537,5.88651395465,302,,Lantéfontaine,Lantéfontaine,54,Meurthe-et-Moselle,44,Grand Est
54303,LARONXE,54950,LARONXE,,48.5442014247,6.59779172365,303,,Laronxe,Laronxe,54,Meurthe-et-Moselle,44,Grand Est
54304,LAXOU,54520,LAXOU,,48.6822062614,6.11358630152,304,,Laxou,Laxou,54,Meurthe-et-Moselle,44,Grand Est
54304,LAXOU,54520,LAXOU,LAXOU CHAMPLEBOEUF,48.6822062614,6.11358630152,304,,Laxou,Laxou,54,Meurthe-et-Moselle,44,Grand Est
54305,LAY ST CHRISTOPHE,54690,LAY ST CHRISTOPHE,,48.753844505,6.19506078007,305,,Lay-Saint-Christophe,Lay-Saint-Christophe,54,Meurthe-et-Moselle,44,Grand Est
54306,LAY ST REMY,54570,LAY ST REMY,,48.6816250302,5.76144997783,306,,Lay-Saint-Remy,Lay-Saint-Remy,54,Meurthe-et-Moselle,44,Grand Est
54307,LEBEUVILLE,54740,LEBEUVILLE,,48.4342739169,6.241134065,307,,Lebeuville,Lebeuville,54,Meurthe-et-Moselle,44,Grand Est
54308,LEINTREY,54450,LEINTREY,,48.6283526431,6.74348437889,308,,Leintrey,Leintrey,54,Meurthe-et-Moselle,44,Grand Est
54309,LEMAINVILLE,54740,LEMAINVILLE,,48.5057667643,6.18685411561,309,,Lemainville,Lemainville,54,Meurthe-et-Moselle,44,Grand Est
54310,LEMENIL MITRY,54740,LEMENIL MITRY,,48.4531451229,6.25492080273,310,,Leménil-Mitry,Leménil-Mitry,54,Meurthe-et-Moselle,44,Grand Est
54311,LENONCOURT,54110,LENONCOURT,,48.6713494283,6.30019640728,311,,Lenoncourt,Lenoncourt,54,Meurthe-et-Moselle,44,Grand Est
54312,LESMENILS,54700,LESMENILS,,48.9314670902,6.11072851248,312,,Lesménils,Lesménils,54,Meurthe-et-Moselle,44,Grand Est
54313,LETRICOURT,54610,LETRICOURT,,48.8747932305,6.285203486,313,,Létricourt,Létricourt,54,Meurthe-et-Moselle,44,Grand Est
54314,LEXY,54720,LEXY,,49.5032742592,5.72907122522,314,,Lexy,Lexy,54,Meurthe-et-Moselle,44,Grand Est
54315,LEYR,54760,LEYR,,48.8023774234,6.26922360903,315,,Leyr,Leyr,54,Meurthe-et-Moselle,44,Grand Est
54316,LIMEY REMENAUVILLE,54470,LIMEY REMENAUVILLE,,48.8942281864,5.90234476562,316,,Limey-Remenauville,Limey-Remenauville,54,Meurthe-et-Moselle,44,Grand Est
54317,LIRONVILLE,54470,LIRONVILLE,,48.867147586,5.91217253236,317,,Lironville,Lironville,54,Meurthe-et-Moselle,44,Grand Est
54318,LIVERDUN,54460,LIVERDUN,,48.7537166405,6.05235397303,318,,Liverdun,Liverdun,54,Meurthe-et-Moselle,44,Grand Est
54320,LOISY,54700,LOISY,,48.8672070236,6.09844235852,320,,Loisy,Loisy,54,Meurthe-et-Moselle,44,Grand Est
54321,LONGLAVILLE,54810,LONGLAVILLE,,49.533561598,5.80230527944,321,,Longlaville,Longlaville,54,Meurthe-et-Moselle,44,Grand Est
54322,LONGUYON,54260,LONGUYON,,49.4583667355,5.59176459376,322,,Longuyon,Longuyon,54,Meurthe-et-Moselle,44,Grand Est
54322,LONGUYON,54260,LONGUYON,NOERS,49.4583667355,5.59176459376,322,,Longuyon,Longuyon,54,Meurthe-et-Moselle,44,Grand Est
54322,LONGUYON,54260,LONGUYON,VILLANCY,49.4583667355,5.59176459376,322,,Longuyon,Longuyon,54,Meurthe-et-Moselle,44,Grand Est
54323,LONGWY,54400,LONGWY,,49.5214021779,5.76638370702,323,,Longwy,Longwy,54,Meurthe-et-Moselle,44,Grand Est
54324,LOREY,54290,LOREY,,48.4973673544,6.31479678875,324,,Lorey,Lorey,54,Meurthe-et-Moselle,44,Grand Est
54325,LOROMONTZEY,54290,LOROMONTZEY,,48.4301459071,6.36977813562,325,,Loromontzey,Loromontzey,54,Meurthe-et-Moselle,44,Grand Est
54326,LUBEY,54150,LUBEY,,49.2440280069,5.85466048345,326,,Lubey,Lubey,54,Meurthe-et-Moselle,44,Grand Est
54327,LUCEY,54200,LUCEY,,48.7225913687,5.82885971661,327,,Lucey,Lucey,54,Meurthe-et-Moselle,44,Grand Est
54328,LUDRES,54710,LUDRES,,48.6207490821,6.17338110718,328,,Ludres,Ludres,54,Meurthe-et-Moselle,44,Grand Est
54329,LUNEVILLE,54300,LUNEVILLE,,48.5920244663,6.49810324732,329,,Lunéville,Lunéville,54,Meurthe-et-Moselle,44,Grand Est
54330,LUPCOURT,54210,LUPCOURT,,48.6024897309,6.22121574013,330,,Lupcourt,Lupcourt,54,Meurthe-et-Moselle,44,Grand Est
54331,MAGNIERES,54129,MAGNIERES,,48.4441780874,6.56579108633,331,,Magnières,Magnières,54,Meurthe-et-Moselle,44,Grand Est
54332,MAIDIERES,54700,MAIDIERES,,48.8958716119,6.03587896317,332,,Maidières,Maidières,54,Meurthe-et-Moselle,44,Grand Est
54333,MAILLY SUR SEILLE,54610,MAILLY SUR SEILLE,,48.9178459564,6.25112851262,333,,Mailly-sur-Seille,Mailly-sur-Seille,54,Meurthe-et-Moselle,44,Grand Est
54334,MAIRY MAINVILLE,54150,MAIRY MAINVILLE,,49.3047502382,5.85538054793,334,,Mairy-Mainville,Mairy-Mainville,54,Meurthe-et-Moselle,44,Grand Est
54334,MAIRY MAINVILLE,54150,MAIRY MAINVILLE,MAINVILLE,49.3047502382,5.85538054793,334,,Mairy-Mainville,Mairy-Mainville,54,Meurthe-et-Moselle,44,Grand Est
54335,MAIXE,54370,MAIXE,,48.6475415735,6.43790356383,335,,Maixe,Maixe,54,Meurthe-et-Moselle,44,Grand Est
54336,MAIZIERES,54550,MAIZIERES,,48.5908483148,6.0487436467,336,,Maizières,Maizières,54,Meurthe-et-Moselle,44,Grand Est
54337,MALAVILLERS,54560,MALAVILLERS,,49.3574661069,5.86577468306,337,,Malavillers,Malavillers,54,Meurthe-et-Moselle,44,Grand Est
54338,MALLELOY,54670,MALLELOY,,48.7916600767,6.16801224152,338,,Malleloy,Malleloy,54,Meurthe-et-Moselle,44,Grand Est
54339,MALZEVILLE,54220,MALZEVILLE,,48.7218421978,6.18996136017,339,,Malzéville,Malzéville,54,Meurthe-et-Moselle,44,Grand Est
54340,MAMEY,54470,MAMEY,,48.8796302932,5.95590778718,340,,Mamey,Mamey,54,Meurthe-et-Moselle,44,Grand Est
54341,MANCE,54150,MANCE,,49.2743132888,5.91941137591,341,,Mance,Mance,54,Meurthe-et-Moselle,44,Grand Est
54342,MANCIEULLES,54790,MANCIEULLES,,49.2810932813,5.89669694127,342,,Mancieulles,Mancieulles,54,Meurthe-et-Moselle,44,Grand Est
54343,MANDRES AUX QUATRE TOURS,54470,MANDRES AUX QUATRE TOURS,,48.8280148948,5.79045735083,343,,Mandres-aux-Quatre-Tours,Mandres-aux-Quatre-Tours,54,Meurthe-et-Moselle,44,Grand Est
54344,MANGONVILLE,54290,MANGONVILLE,,48.451085439,6.28899041903,344,,Mangonville,Mangonville,54,Meurthe-et-Moselle,44,Grand Est
54345,MANONCOURT EN VERMOIS,54210,MANONCOURT EN VERMOIS,,48.6014894202,6.27024108029,345,,Manoncourt-en-Vermois,Manoncourt-en-Vermois,54,Meurthe-et-Moselle,44,Grand Est
54346,MANONCOURT EN WOEVRE,54385,MANONCOURT EN WOEVRE,,48.78198493,5.91915355955,346,,Manoncourt-en-Woëvre,Manoncourt-en-Woëvre,54,Meurthe-et-Moselle,44,Grand Est
54348,MANONVILLE,54385,MANONVILLE,,48.8313527752,5.91543101221,348,,Manonville,Manonville,54,Meurthe-et-Moselle,44,Grand Est
54349,MANONVILLER,54300,MANONVILLER,,48.5910642375,6.66259343648,349,,Manonviller,Manonviller,54,Meurthe-et-Moselle,44,Grand Est
54350,MARAINVILLER,54300,MARAINVILLER,,48.597994773,6.60823842119,350,,Marainviller,Marainviller,54,Meurthe-et-Moselle,44,Grand Est
54351,MARBACHE,54820,MARBACHE,,48.790237292,6.09039426477,351,,Marbache,Marbache,54,Meurthe-et-Moselle,44,Grand Est
54352,MARON,54230,MARON,,48.6577116838,6.05780726242,352,,Maron,Maron,54,Meurthe-et-Moselle,44,Grand Est
54353,MARS LA TOUR,54800,MARS LA TOUR,,49.0967846153,5.88467840528,353,,Mars-la-Tour,Mars-la-Tour,54,Meurthe-et-Moselle,44,Grand Est
54354,MARTHEMONT,54330,MARTHEMONT,,48.5592614713,6.03669109733,354,,Marthemont,Marthemont,54,Meurthe-et-Moselle,44,Grand Est
54355,MARTINCOURT,54380,MARTINCOURT,,48.8482925248,5.95195681774,355,,Martincourt,Martincourt,54,Meurthe-et-Moselle,44,Grand Est
54356,MATTEXEY,54830,MATTEXEY,,48.4390177422,6.52169861442,356,,Mattexey,Mattexey,54,Meurthe-et-Moselle,44,Grand Est
54357,MAXEVILLE,54320,MAXEVILLE,,48.7091528465,6.14959862185,357,,Maxéville,Maxéville,54,Meurthe-et-Moselle,44,Grand Est
54357,MAXEVILLE,54320,MAXEVILLE,MAXEVILLE CHAMPLEBOEUF,48.7091528465,6.14959862185,357,,Maxéville,Maxéville,54,Meurthe-et-Moselle,44,Grand Est
54358,MAZERULLES,54280,MAZERULLES,,48.7552760519,6.37935633386,358,,Mazerulles,Mazerulles,54,Meurthe-et-Moselle,44,Grand Est
54359,MEHONCOURT,54360,MEHONCOURT,,48.5123289166,6.37994710945,359,,Méhoncourt,Méhoncourt,54,Meurthe-et-Moselle,44,Grand Est
54360,MENIL LA TOUR,54200,MENIL LA TOUR,,48.7741697228,5.84763659664,360,,Ménil-la-Tour,Ménil-la-Tour,54,Meurthe-et-Moselle,44,Grand Est
54362,MERCY LE BAS,54960,MERCY LE BAS,,49.3854191289,5.75848432242,362,,Mercy-le-Bas,Mercy-le-Bas,54,Meurthe-et-Moselle,44,Grand Est
54363,MERCY LE HAUT,54560,MERCY LE HAUT,,49.3697647828,5.82533322849,363,,Mercy-le-Haut,Mercy-le-Haut,54,Meurthe-et-Moselle,44,Grand Est
54363,MERCY LE HAUT,54560,MERCY LE HAUT,BOUDREZY,49.3697647828,5.82533322849,363,,Mercy-le-Haut,Mercy-le-Haut,54,Meurthe-et-Moselle,44,Grand Est
54364,MEREVILLE,54850,MEREVILLE,,48.5911694805,6.1377105599,364,,Méréville,Méréville,54,Meurthe-et-Moselle,44,Grand Est
54365,MERVILLER,54120,MERVILLER,,48.4739799839,6.77926594668,365,,Merviller,Merviller,54,Meurthe-et-Moselle,44,Grand Est
54366,MESSEIN,54850,MESSEIN,,48.6104817604,6.14212766773,366,,Messein,Messein,54,Meurthe-et-Moselle,44,Grand Est
54367,MEXY,54135,MEXY,,49.5017214844,5.78595844743,367,,Mexy,Mexy,54,Meurthe-et-Moselle,44,Grand Est
54368,MIGNEVILLE,54540,MIGNEVILLE,,48.5341591798,6.78313669557,368,,Mignéville,Mignéville,54,Meurthe-et-Moselle,44,Grand Est
54369,MILLERY,54670,MILLERY,,48.8151063619,6.13947444735,369,,Millery,Millery,54,Meurthe-et-Moselle,44,Grand Est
54370,MINORVILLE,54385,MINORVILLE,,48.8097499629,5.8798507442,370,,Minorville,Minorville,54,Meurthe-et-Moselle,44,Grand Est
54371,MOINEVILLE,54580,MOINEVILLE,,49.1970140932,5.94836254251,371,,Moineville,Moineville,54,Meurthe-et-Moselle,44,Grand Est
54372,MOIVRONS,54760,MOIVRONS,,48.8281038737,6.24784004204,372,,Moivrons,Moivrons,54,Meurthe-et-Moselle,44,Grand Est
54373,MONCEL LES LUNEVILLE,54300,MONCEL LES LUNEVILLE,,48.5637047138,6.56149374143,373,,Moncel-lès-Lunéville,Moncel-lès-Lunéville,54,Meurthe-et-Moselle,44,Grand Est
54374,MONCEL SUR SEILLE,54280,MONCEL SUR SEILLE,,48.7689689739,6.42429308045,374,,Moncel-sur-Seille,Moncel-sur-Seille,54,Meurthe-et-Moselle,44,Grand Est
54375,MONTAUVILLE,54700,MONTAUVILLE,,48.8961507252,6.00139029586,375,,Montauville,Montauville,54,Meurthe-et-Moselle,44,Grand Est
54376,MONTENOY,54760,MONTENOY,,48.7926139006,6.23631393016,376,,Montenoy,Montenoy,54,Meurthe-et-Moselle,44,Grand Est
54377,MONTIGNY,54540,MONTIGNY,,48.5137501502,6.80331187595,377,,Montigny,Montigny,54,Meurthe-et-Moselle,44,Grand Est
54378,MONTIGNY SUR CHIERS,54870,MONTIGNY SUR CHIERS,,49.4718809607,5.66811908272,378,,Montigny-sur-Chiers,Montigny-sur-Chiers,54,Meurthe-et-Moselle,44,Grand Est
54378,MONTIGNY SUR CHIERS,54870,MONTIGNY SUR CHIERS,FERMONT,49.4718809607,5.66811908272,378,,Montigny-sur-Chiers,Montigny-sur-Chiers,54,Meurthe-et-Moselle,44,Grand Est
54379,MONT L ETROIT,54170,MONT L ETROIT,,48.4986857514,5.78282069784,379,,Mont-l'Étroit,Mont-l'Étroit,54,Meurthe-et-Moselle,44,Grand Est
54380,MONT LE VIGNOBLE,54113,MONT LE VIGNOBLE,,48.6194473403,5.85052856749,380,,Mont-le-Vignoble,Mont-le-Vignoble,54,Meurthe-et-Moselle,44,Grand Est
54381,MONTREUX,54450,MONTREUX,,48.5354543018,6.88782007418,381,,Montreux,Montreux,54,Meurthe-et-Moselle,44,Grand Est
54382,MONT ST MARTIN,54350,MONT ST MARTIN,,49.545958519,5.77946566153,382,,Mont-Saint-Martin,Mont-Saint-Martin,54,Meurthe-et-Moselle,44,Grand Est
54383,MONT SUR MEURTHE,54360,MONT SUR MEURTHE,,48.5493164912,6.44216065788,383,,Mont-sur-Meurthe,Mont-sur-Meurthe,54,Meurthe-et-Moselle,44,Grand Est
54385,MORFONTAINE,54920,MORFONTAINE,,49.4434732186,5.81770469629,385,,Morfontaine,Morfontaine,54,Meurthe-et-Moselle,44,Grand Est
54386,MORIVILLER,54830,MORIVILLER,,48.4809875123,6.44040977493,386,,Moriviller,Moriviller,54,Meurthe-et-Moselle,44,Grand Est
54387,MORVILLE SUR SEILLE,54700,MORVILLE SUR SEILLE,,48.9179706076,6.14619157987,387,,Morville-sur-Seille,Morville-sur-Seille,54,Meurthe-et-Moselle,44,Grand Est
54388,MOUACOURT,54370,MOUACOURT,,48.6720340555,6.63993630871,388,,Mouacourt,Mouacourt,54,Meurthe-et-Moselle,44,Grand Est
54389,MOUAVILLE,54800,MOUAVILLE,,49.2135294001,5.77867124953,389,,Mouaville,Mouaville,54,Meurthe-et-Moselle,44,Grand Est
54390,MOUSSON,54700,MOUSSON,,48.9108966733,6.09255325215,390,,Mousson,Mousson,54,Meurthe-et-Moselle,44,Grand Est
54391,MOUTIERS,54660,MOUTIERS,,49.2325832585,5.95571904292,391,,Moutiers,Moutiers,54,Meurthe-et-Moselle,44,Grand Est
54392,MOUTROT,54113,MOUTROT,,48.6018097016,5.89684875772,392,,Moutrot,Moutrot,54,Meurthe-et-Moselle,44,Grand Est
54393,MOYEN,54118,MOYEN,,48.4870728454,6.58670428264,393,,Moyen,Moyen,54,Meurthe-et-Moselle,44,Grand Est
54394,MURVILLE,54490,MURVILLE,,49.3429418953,5.83086554021,394,,Murville,Murville,54,Meurthe-et-Moselle,44,Grand Est
54395,NANCY,54000,NANCY,,48.6901995499,6.17588254434,395,,Nancy,Nancy,54,Meurthe-et-Moselle,44,Grand Est
54395,NANCY,54100,NANCY,,48.6901995499,6.17588254434,395,,Nancy,Nancy,54,Meurthe-et-Moselle,44,Grand Est
54396,NEUFMAISONS,54540,NEUFMAISONS,,48.4471437689,6.87001944215,396,,Neufmaisons,Neufmaisons,54,Meurthe-et-Moselle,44,Grand Est
54397,NEUVES MAISONS,54230,NEUVES MAISONS,,48.6147598668,6.10912661259,397,,Neuves-Maisons,Neuves-Maisons,54,Meurthe-et-Moselle,44,Grand Est
54398,NEUVILLER LES BADONVILLER,54540,NEUVILLER LES BADONVILLER,,48.5208088136,6.87681873145,398,,Neuviller-lès-Badonviller,Neuviller-lès-Badonviller,54,Meurthe-et-Moselle,44,Grand Est
54399,NEUVILLER SUR MOSELLE,54290,NEUVILLER SUR MOSELLE,,48.4902431576,6.28177004556,399,,Neuviller-sur-Moselle,Neuviller-sur-Moselle,54,Meurthe-et-Moselle,44,Grand Est
54400,NOMENY,54610,NOMENY,,48.8776522651,6.23309013731,400,,Nomeny,Nomeny,54,Meurthe-et-Moselle,44,Grand Est
54401,NONHIGNY,54450,NONHIGNY,,48.5487523948,6.89074494437,401,,Nonhigny,Nonhigny,54,Meurthe-et-Moselle,44,Grand Est
54402,NORROY LE SEC,54150,NORROY LE SEC,,49.2766316915,5.80939284962,402,,Norroy-le-Sec,Norroy-le-Sec,54,Meurthe-et-Moselle,44,Grand Est
54403,NORROY LES PONT A MOUSSON,54700,NORROY LES PONT A MOUSSON,,48.9321142201,6.01232137434,403,,Norroy-lès-Pont-à-Mousson,Norroy-lès-Pont-à-Mousson,54,Meurthe-et-Moselle,44,Grand Est
54404,NOVIANT AUX PRES,54385,NOVIANT AUX PRES,,48.8457598946,5.88509402021,404,,Noviant-aux-Prés,Noviant-aux-Prés,54,Meurthe-et-Moselle,44,Grand Est
54405,OCHEY,54170,OCHEY,,48.5937124314,5.94862791477,405,,Ochey,Ochey,54,Meurthe-et-Moselle,44,Grand Est
54406,OGEVILLER,54450,OGEVILLER,,48.5513307344,6.71800139721,406,,Ogéviller,Ogéviller,54,Meurthe-et-Moselle,44,Grand Est
54407,OGNEVILLE,54330,OGNEVILLE,,48.4714847798,6.0591026476,407,,Ognéville,Ognéville,54,Meurthe-et-Moselle,44,Grand Est
54408,OLLEY,54800,OLLEY,,49.1731372736,5.76773239103,408,,Olley,Olley,54,Meurthe-et-Moselle,44,Grand Est
54409,OMELMONT,54330,OMELMONT,,48.4933449333,6.11846755085,409,,Omelmont,Omelmont,54,Meurthe-et-Moselle,44,Grand Est
54410,ONVILLE,54890,ONVILLE,,49.0218156654,5.96851536679,410,,Onville,Onville,54,Meurthe-et-Moselle,44,Grand Est
54411,ORMES ET VILLE,54740,ORMES ET VILLE,,48.4878294205,6.20501552992,411,,Ormes-et-Ville,Ormes-et-Ville,54,Meurthe-et-Moselle,44,Grand Est
54412,OTHE,54260,OTHE,,49.4911452642,5.44705837235,412,,Othe,Othe,54,Meurthe-et-Moselle,44,Grand Est
54413,OZERAILLES,54150,OZERAILLES,,49.2259087453,5.84170990352,413,,Ozerailles,Ozerailles,54,Meurthe-et-Moselle,44,Grand Est
54414,PAGNEY DERRIERE BARINE,54200,PAGNEY DERRIERE BARINE,,48.6988404472,5.8475442388,414,,Pagney-derrière-Barine,Pagney-derrière-Barine,54,Meurthe-et-Moselle,44,Grand Est
54415,PAGNY SUR MOSELLE,54530,PAGNY SUR MOSELLE,,48.9884361128,6.01116368886,415,,Pagny-sur-Moselle,Pagny-sur-Moselle,54,Meurthe-et-Moselle,44,Grand Est
54416,PANNES,54470,PANNES,,48.9298072595,5.79605273034,416,,Pannes,Pannes,54,Meurthe-et-Moselle,44,Grand Est
54417,PAREY ST CESAIRE,54330,PAREY ST CESAIRE,,48.5271211226,6.06368968426,417,,Parey-Saint-Césaire,Parey-Saint-Césaire,54,Meurthe-et-Moselle,44,Grand Est
54418,PARROY,54370,PARROY,,48.6685304259,6.60820341709,418,,Parroy,Parroy,54,Meurthe-et-Moselle,44,Grand Est
54419,PARUX,54480,PARUX,,48.5418719546,6.92113497047,419,,Parux,Parux,54,Meurthe-et-Moselle,44,Grand Est
54420,PETIT FAILLY,54260,PETIT FAILLY,,49.439659769,5.50119376994,420,,Petit-Failly,Petit-Failly,54,Meurthe-et-Moselle,44,Grand Est
54421,PETITMONT,54480,PETITMONT,,48.5464573608,6.97419828123,421,,Petitmont,Petitmont,54,Meurthe-et-Moselle,44,Grand Est
54422,PETTONVILLE,54120,PETTONVILLE,,48.5301540618,6.74371187817,422,,Pettonville,Pettonville,54,Meurthe-et-Moselle,44,Grand Est
54423,PEXONNE,54540,PEXONNE,,48.4679125322,6.88380524568,423,,Pexonne,Pexonne,54,Meurthe-et-Moselle,44,Grand Est
54424,PHLIN,54610,PHLIN,,48.9167225344,6.27672257026,424,,Phlin,Phlin,54,Meurthe-et-Moselle,44,Grand Est
54425,PIENNES,54490,PIENNES,,49.3073950571,5.78383056316,425,,Piennes,Piennes,54,Meurthe-et-Moselle,44,Grand Est
54426,PIERRE LA TREICHE,54200,PIERRE LA TREICHE,,48.639755596,5.96389435396,426,,Pierre-la-Treiche,Pierre-la-Treiche,54,Meurthe-et-Moselle,44,Grand Est
54427,PIERRE PERCEE,54540,PIERRE PERCEE,,48.4646115258,6.93843634408,427,,Pierre-Percée,Pierre-Percée,54,Meurthe-et-Moselle,44,Grand Est
54428,PIERREPONT,54620,PIERREPONT,,49.4183305264,5.70916931035,428,,Pierrepont,Pierrepont,54,Meurthe-et-Moselle,44,Grand Est
54429,PIERREVILLE,54160,PIERREVILLE,,48.5485746268,6.11074927639,429,,Pierreville,Pierreville,54,Meurthe-et-Moselle,44,Grand Est
54430,POMPEY,54340,POMPEY,,48.7732074334,6.11082140462,430,,Pompey,Pompey,54,Meurthe-et-Moselle,44,Grand Est
54431,PONT A MOUSSON,54700,PONT A MOUSSON,,48.9219828048,6.05392690748,431,,Pont-à-Mousson,Pont-à-Mousson,54,Meurthe-et-Moselle,44,Grand Est
54432,PONT ST VINCENT,54550,PONT ST VINCENT,,48.6093470317,6.07504458865,432,,Pont-Saint-Vincent,Pont-Saint-Vincent,54,Meurthe-et-Moselle,44,Grand Est
54433,PORT SUR SEILLE,54700,PORT SUR SEILLE,,48.8997308507,6.15976934112,433,,Port-sur-Seille,Port-sur-Seille,54,Meurthe-et-Moselle,44,Grand Est
54434,PRAYE,54116,PRAYE,,48.437631952,6.11147744521,434,,Praye,Praye,54,Meurthe-et-Moselle,44,Grand Est
54435,PRENY,54530,PRENY,,48.9719661706,5.96314993379,435,,Prény,Prény,54,Meurthe-et-Moselle,44,Grand Est
54436,PREUTIN HIGNY,54490,PREUTIN HIGNY,,49.3473076426,5.79949172398,436,,Preutin-Higny,Preutin-Higny,54,Meurthe-et-Moselle,44,Grand Est
54437,PULLIGNY,54160,PULLIGNY,,48.549622462,6.15189007246,437,,Pulligny,Pulligny,54,Meurthe-et-Moselle,44,Grand Est
54438,PULNEY,54115,PULNEY,,48.3909723712,6.03248056928,438,,Pulney,Pulney,54,Meurthe-et-Moselle,44,Grand Est
54439,PULNOY,54425,PULNOY,,48.7016421917,6.26610027665,439,,Pulnoy,Pulnoy,54,Meurthe-et-Moselle,44,Grand Est
54440,PUXE,54800,PUXE,,49.1511730916,5.7867211463,440,,Puxe,Puxe,54,Meurthe-et-Moselle,44,Grand Est
54441,PUXIEUX,54800,PUXIEUX,,49.073592292,5.88512359173,441,,Puxieux,Puxieux,54,Meurthe-et-Moselle,44,Grand Est
54442,QUEVILLONCOURT,54330,QUEVILLONCOURT,,48.4715134849,6.10533295975,442,,Quevilloncourt,Quevilloncourt,54,Meurthe-et-Moselle,44,Grand Est
54443,RAON LES LEAU,54540,RAON LES LEAU,,48.5138486149,7.09738712876,443,,Raon-lès-Leau,Raon-lès-Leau,54,Meurthe-et-Moselle,44,Grand Est
54444,RAUCOURT,54610,RAUCOURT,,48.9241901699,6.21845026045,444,,Raucourt,Raucourt,54,Meurthe-et-Moselle,44,Grand Est
54445,RAVILLE SUR SANON,54370,RAVILLE SUR SANON,,48.6515330352,6.51008948493,445,,Raville-sur-Sânon,Raville-sur-Sânon,54,Meurthe-et-Moselle,44,Grand Est
54446,RECHICOURT LA PETITE,54370,RECHICOURT LA PETITE,,48.717997142,6.58235675457,446,,Réchicourt-la-Petite,Réchicourt-la-Petite,54,Meurthe-et-Moselle,44,Grand Est
54447,RECLONVILLE,54450,RECLONVILLE,,48.537477465,6.72946978651,447,,Réclonville,Réclonville,54,Meurthe-et-Moselle,44,Grand Est
54449,REHAINVILLER,54300,REHAINVILLER,,48.5681612179,6.46940398243,449,,Rehainviller,Rehainviller,54,Meurthe-et-Moselle,44,Grand Est
54450,REHERREY,54120,REHERREY,,48.5084858883,6.77646344447,450,,Reherrey,Reherrey,54,Meurthe-et-Moselle,44,Grand Est
54451,REHON,54430,REHON,,49.4995747303,5.75731520354,451,,Réhon,Réhon,54,Meurthe-et-Moselle,44,Grand Est
54451,REHON,54430,REHON,HEUMONT,49.4995747303,5.75731520354,451,,Réhon,Réhon,54,Meurthe-et-Moselle,44,Grand Est
54452,REILLON,54450,REILLON,,48.5988717503,6.74314976627,452,,Reillon,Reillon,54,Meurthe-et-Moselle,44,Grand Est
54453,REMBERCOURT SUR MAD,54470,REMBERCOURT SUR MAD,,48.9851155798,5.90361841528,453,,Rembercourt-sur-Mad,Rembercourt-sur-Mad,54,Meurthe-et-Moselle,44,Grand Est
54455,REMENOVILLE,54830,REMENOVILLE,,48.4639098729,6.47480886613,455,,Remenoville,Remenoville,54,Meurthe-et-Moselle,44,Grand Est
54456,REMEREVILLE,54110,REMEREVILLE,,48.7083090785,6.38025660676,456,,Réméréville,Réméréville,54,Meurthe-et-Moselle,44,Grand Est
54457,REMONCOURT,54370,REMONCOURT,,48.656075231,6.74132398096,457,,Remoncourt,Remoncourt,54,Meurthe-et-Moselle,44,Grand Est
54458,REPAIX,54450,REPAIX,,48.6128594878,6.83000371109,458,,Repaix,Repaix,54,Meurthe-et-Moselle,44,Grand Est
54459,RICHARDMENIL,54630,RICHARDMENIL,,48.5958649273,6.17614506031,459,,Richardménil,Richardménil,54,Meurthe-et-Moselle,44,Grand Est
54460,ROGEVILLE,54380,ROGEVILLE,,48.8146914454,5.97727344525,460,,Rogéville,Rogéville,54,Meurthe-et-Moselle,44,Grand Est
54461,ROMAIN,54360,ROMAIN,,48.52075217,6.35686026913,461,,Romain,Romain,54,Meurthe-et-Moselle,44,Grand Est
54462,ROSIERES AUX SALINES,54110,ROSIERES AUX SALINES,,48.5924316341,6.33430536376,462,,Rosières-aux-Salines,Rosières-aux-Salines,54,Meurthe-et-Moselle,44,Grand Est
54463,ROSIERES EN HAYE,54385,ROSIERES EN HAYE,,48.7887620436,5.99419781093,463,,Rosières-en-Haye,Rosières-en-Haye,54,Meurthe-et-Moselle,44,Grand Est
54464,ROUVES,54610,ROUVES,,48.9025493534,6.20428002565,464,,Rouves,Rouves,54,Meurthe-et-Moselle,44,Grand Est
54465,ROVILLE DEVANT BAYON,54290,ROVILLE DEVANT BAYON,,48.4673083609,6.29365271914,465,,Roville-devant-Bayon,Roville-devant-Bayon,54,Meurthe-et-Moselle,44,Grand Est
54466,ROYAUMEIX,54200,ROYAUMEIX,,48.7851184202,5.8221072138,466,,Royaumeix,Royaumeix,54,Meurthe-et-Moselle,44,Grand Est
54467,ROZELIEURES,54290,ROZELIEURES,,48.4468595767,6.43045935453,467,,Rozelieures,Rozelieures,54,Meurthe-et-Moselle,44,Grand Est
54468,SAFFAIS,54210,SAFFAIS,,48.5594143103,6.31597389904,468,,Saffais,Saffais,54,Meurthe-et-Moselle,44,Grand Est
54469,ST AIL,54580,ST AIL,,49.1694540532,6.0019618843,469,,Saint-Ail,Saint-Ail,54,Meurthe-et-Moselle,44,Grand Est
54469,ST AIL,54580,ST AIL,HABONVILLE,49.1694540532,6.0019618843,469,,Saint-Ail,Saint-Ail,54,Meurthe-et-Moselle,44,Grand Est
54470,ST BAUSSANT,54470,ST BAUSSANT,,48.8919040892,5.79727902455,470,,Saint-Baussant,Saint-Baussant,54,Meurthe-et-Moselle,44,Grand Est
54471,ST BOINGT,54290,ST BOINGT,,48.4186728188,6.42962191006,471,,Saint-Boingt,Saint-Boingt,54,Meurthe-et-Moselle,44,Grand Est
54472,ST CLEMENT,54950,ST CLEMENT,,48.5424165222,6.6233621308,472,,Saint-Clément,Saint-Clément,54,Meurthe-et-Moselle,44,Grand Est
54473,ST FIRMIN,54930,ST FIRMIN,,48.4251143645,6.13660869738,473,,Saint-Firmin,Saint-Firmin,54,Meurthe-et-Moselle,44,Grand Est
54474,STE GENEVIEVE,54700,STE GENEVIEVE,,48.8702243582,6.13715548513,474,,Sainte-Geneviève,Sainte-Geneviève,54,Meurthe-et-Moselle,44,Grand Est
54475,ST GERMAIN,54290,ST GERMAIN,,48.4232806157,6.34412609341,475,,Saint-Germain,Saint-Germain,54,Meurthe-et-Moselle,44,Grand Est
54476,ST JEAN LES LONGUYON,54260,ST JEAN LES LONGUYON,,49.4555490143,5.48860106808,476,,Saint-Jean-lès-Longuyon,Saint-Jean-lès-Longuyon,54,Meurthe-et-Moselle,44,Grand Est
54476,ST JEAN LES LONGUYON,54260,ST JEAN LES LONGUYON,HAM LES ST JEAN,49.4555490143,5.48860106808,476,,Saint-Jean-lès-Longuyon,Saint-Jean-lès-Longuyon,54,Meurthe-et-Moselle,44,Grand Est
54477,ST JULIEN LES GORZE,54470,ST JULIEN LES GORZE,,49.0182448248,5.90427864143,477,,Saint-Julien-lès-Gorze,Saint-Julien-lès-Gorze,54,Meurthe-et-Moselle,44,Grand Est
54478,ST MARCEL,54800,ST MARCEL,,49.1225093046,5.97329179607,478,,Saint-Marcel,Saint-Marcel,54,Meurthe-et-Moselle,44,Grand Est
54479,ST MARD,54290,ST MARD,,48.5100096795,6.29862310671,479,,Saint-Mard,Saint-Mard,54,Meurthe-et-Moselle,44,Grand Est
54480,ST MARTIN,54450,ST MARTIN,,48.571711252,6.74965214111,480,,Saint-Martin,Saint-Martin,54,Meurthe-et-Moselle,44,Grand Est
54481,ST MAURICE AUX FORGES,54540,ST MAURICE AUX FORGES,,48.5064468487,6.85090733519,481,,Saint-Maurice-aux-Forges,Saint-Maurice-aux-Forges,54,Meurthe-et-Moselle,44,Grand Est
54482,ST MAX,54130,ST MAX,,48.7052276034,6.20645111198,482,,Saint-Max,Saint-Max,54,Meurthe-et-Moselle,44,Grand Est
54483,ST NICOLAS DE PORT,54210,ST NICOLAS DE PORT,,48.6220684472,6.29547921699,483,,Saint-Nicolas-de-Port,Saint-Nicolas-de-Port,54,Meurthe-et-Moselle,44,Grand Est
54484,STE POLE,54540,STE POLE,,48.5055231931,6.83057236429,484,,Sainte-Pôle,Sainte-Pôle,54,Meurthe-et-Moselle,44,Grand Est
54485,ST PANCRE,54730,ST PANCRE,,49.5261729402,5.6387663725,485,,Saint-Pancré,Saint-Pancré,54,Meurthe-et-Moselle,44,Grand Est
54486,ST REMIMONT,54740,ST REMIMONT,,48.4990142296,6.25415061571,486,,Saint-Remimont,Saint-Remimont,54,Meurthe-et-Moselle,44,Grand Est
54487,ST REMY AUX BOIS,54290,ST REMY AUX BOIS,,48.4160503488,6.3962556094,487,,Saint-Rémy-aux-Bois,Saint-Rémy-aux-Bois,54,Meurthe-et-Moselle,44,Grand Est
54488,ST SAUVEUR,54480,ST SAUVEUR,,48.5230682031,7.00308119799,488,,Saint-Sauveur,Saint-Sauveur,54,Meurthe-et-Moselle,44,Grand Est
54489,ST SUPPLET,54620,ST SUPPLET,,49.3758463385,5.72951086724,489,,Saint-Supplet,Saint-Supplet,54,Meurthe-et-Moselle,44,Grand Est
54490,SAIZERAIS,54380,SAIZERAIS,,48.7916785782,6.04064725609,490,,Saizerais,Saizerais,54,Meurthe-et-Moselle,44,Grand Est
54491,SANCY,54560,SANCY,,49.3519271584,5.92745145012,491,,Sancy,Sancy,54,Meurthe-et-Moselle,44,Grand Est
54492,SANZEY,54200,SANZEY,,48.7652405472,5.82604995245,492,,Sanzey,Sanzey,54,Meurthe-et-Moselle,44,Grand Est
54493,SAULNES,54650,SAULNES,,49.5293904249,5.82642469537,493,,Saulnes,Saulnes,54,Meurthe-et-Moselle,44,Grand Est
54494,SAULXEROTTE,54115,SAULXEROTTE,,48.4728000133,5.9334120785,494,,Saulxerotte,Saulxerotte,54,Meurthe-et-Moselle,44,Grand Est
54495,SAULXURES LES NANCY,54420,SAULXURES LES NANCY,,48.685833475,6.25394887342,495,,Saulxures-lès-Nancy,Saulxures-lès-Nancy,54,Meurthe-et-Moselle,44,Grand Est
54496,SAULXURES LES VANNES,54170,SAULXURES LES VANNES,,48.5191491895,5.80050793813,496,,Saulxures-lès-Vannes,Saulxures-lès-Vannes,54,Meurthe-et-Moselle,44,Grand Est
54497,SAXON SION,54330,SAXON SION,,48.4174218363,6.08660462033,497,,Saxon-Sion,Saxon-Sion,54,Meurthe-et-Moselle,44,Grand Est
54498,SEICHAMPS,54280,SEICHAMPS,,48.715250189,6.27143894576,498,,Seichamps,Seichamps,54,Meurthe-et-Moselle,44,Grand Est
54499,SEICHEPREY,54470,SEICHEPREY,,48.8686282676,5.7957979909,499,,Seicheprey,Seicheprey,54,Meurthe-et-Moselle,44,Grand Est
54500,SELAINCOURT,54170,SELAINCOURT,,48.4966094195,5.94435711868,500,,Selaincourt,Selaincourt,54,Meurthe-et-Moselle,44,Grand Est
54501,SERANVILLE,54830,SERANVILLE,,48.459608902,6.51664977219,501,,Seranville,Seranville,54,Meurthe-et-Moselle,44,Grand Est
54502,SERRES,54370,SERRES,,48.6912563682,6.4543425853,502,,Serres,Serres,54,Meurthe-et-Moselle,44,Grand Est
54504,SERROUVILLE,54560,SERROUVILLE,,49.3938837785,5.87764390671,504,,Serrouville,Serrouville,54,Meurthe-et-Moselle,44,Grand Est
54505,SEXEY AUX FORGES,54550,SEXEY AUX FORGES,,48.6346984648,6.02336738736,505,,Sexey-aux-Forges,Sexey-aux-Forges,54,Meurthe-et-Moselle,44,Grand Est
54506,SEXEY LES BOIS,54840,SEXEY LES BOIS,,48.7189207027,6.0309719075,506,,Sexey-les-Bois,Sexey-les-Bois,54,Meurthe-et-Moselle,44,Grand Est
54507,SIONVILLER,54300,SIONVILLER,,48.6319604008,6.54768956139,507,,Sionviller,Sionviller,54,Meurthe-et-Moselle,44,Grand Est
54508,SIVRY,54610,SIVRY,,48.8296711116,6.20716489613,508,,Sivry,Sivry,54,Meurthe-et-Moselle,44,Grand Est
54509,SOMMERVILLER,54110,SOMMERVILLER,,48.6360355809,6.37571272602,509,,Sommerviller,Sommerviller,54,Meurthe-et-Moselle,44,Grand Est
54510,SORNEVILLE,54280,SORNEVILLE,,48.7411266505,6.41623319667,510,,Sornéville,Sornéville,54,Meurthe-et-Moselle,44,Grand Est
54511,SPONVILLE,54800,SPONVILLE,,49.0740783089,5.83194943929,511,,Sponville,Sponville,54,Meurthe-et-Moselle,44,Grand Est
54512,TANCONVILLE,54480,TANCONVILLE,,48.6051799268,6.94048390571,512,,Tanconville,Tanconville,54,Meurthe-et-Moselle,44,Grand Est
54513,TANTONVILLE,54116,TANTONVILLE,,48.4672751108,6.13754525709,513,,Tantonville,Tantonville,54,Meurthe-et-Moselle,44,Grand Est
54514,TELLANCOURT,54260,TELLANCOURT,,49.5089556847,5.6298875124,514,,Tellancourt,Tellancourt,54,Meurthe-et-Moselle,44,Grand Est
54515,THELOD,54330,THELOD,,48.5439845164,6.05076910436,515,,Thélod,Thélod,54,Meurthe-et-Moselle,44,Grand Est
54516,THEY SOUS VAUDEMONT,54930,THEY SOUS VAUDEMONT,,48.4044708625,6.05299530826,516,,They-sous-Vaudemont,They-sous-Vaudemont,54,Meurthe-et-Moselle,44,Grand Est
54517,THEZEY ST MARTIN,54610,THEZEY ST MARTIN,,48.9030330285,6.30273178932,517,,Thézey-Saint-Martin,Thézey-Saint-Martin,54,Meurthe-et-Moselle,44,Grand Est
54518,THIAUCOURT REGNIEVILLE,54470,THIAUCOURT REGNIEVILLE,,48.9331323678,5.90090345105,518,,Thiaucourt-Regniéville,Thiaucourt-Regniéville,54,Meurthe-et-Moselle,44,Grand Est
54519,THIAVILLE SUR MEURTHE,54120,THIAVILLE SUR MEURTHE,,48.4072052471,6.80501372608,519,,Thiaville-sur-Meurthe,Thiaville-sur-Meurthe,54,Meurthe-et-Moselle,44,Grand Est
54520,THIEBAUMENIL,54300,THIEBAUMENIL,,48.5791590332,6.62983158835,520,,Thiébauménil,Thiébauménil,54,Meurthe-et-Moselle,44,Grand Est
54521,THIL,54880,THIL,,49.4735980022,5.90616788349,521,,Thil,Thil,54,Meurthe-et-Moselle,44,Grand Est
54522,THOREY LYAUTEY,54115,THOREY LYAUTEY,,48.4447902824,6.03226525963,522,,Thorey-Lyautey,Thorey-Lyautey,54,Meurthe-et-Moselle,44,Grand Est
54523,THUILLEY AUX GROSEILLES,54170,THUILLEY AUX GROSEILLES,,48.5757760081,5.97219994844,523,,Thuilley-aux-Groseilles,Thuilley-aux-Groseilles,54,Meurthe-et-Moselle,44,Grand Est
54524,THUMEREVILLE,54800,THUMEREVILLE,,49.2035423546,5.80624858651,524,,Thumeréville,Thumeréville,54,Meurthe-et-Moselle,44,Grand Est
54525,TIERCELET,54190,TIERCELET,,49.4603548011,5.88317112799,525,,Tiercelet,Tiercelet,54,Meurthe-et-Moselle,44,Grand Est
54526,TOMBLAINE,54510,TOMBLAINE,,48.6859541528,6.22129283302,526,,Tomblaine,Tomblaine,54,Meurthe-et-Moselle,44,Grand Est
54527,TONNOY,54210,TONNOY,,48.5545026464,6.25931804019,527,,Tonnoy,Tonnoy,54,Meurthe-et-Moselle,44,Grand Est
54528,TOUL,54200,TOUL,,48.6865463937,5.89508449535,528,,Toul,Toul,54,Meurthe-et-Moselle,44,Grand Est
54529,TRAMONT EMY,54115,TRAMONT EMY,,48.4135129696,5.94924680248,529,,Tramont-Émy,Tramont-Émy,54,Meurthe-et-Moselle,44,Grand Est
54530,TRAMONT LASSUS,54115,TRAMONT LASSUS,,48.4007845601,5.9666240782,530,,Tramont-Lassus,Tramont-Lassus,54,Meurthe-et-Moselle,44,Grand Est
54531,TRAMONT ST ANDRE,54115,TRAMONT ST ANDRE,,48.4143611906,5.92748990163,531,,Tramont-Saint-André,Tramont-Saint-André,54,Meurthe-et-Moselle,44,Grand Est
54532,TREMBLECOURT,54385,TREMBLECOURT,,48.8028930871,5.94555829148,532,,Tremblecourt,Tremblecourt,54,Meurthe-et-Moselle,44,Grand Est
54533,TRIEUX,54750,TRIEUX,,49.3225546403,5.94374684568,533,,Trieux,Trieux,54,Meurthe-et-Moselle,44,Grand Est
54534,TRONDES,54570,TRONDES,,48.7234407473,5.77227841983,534,,Trondes,Trondes,54,Meurthe-et-Moselle,44,Grand Est
54535,TRONVILLE,54800,TRONVILLE,,49.0828019053,5.91902477431,535,,Tronville,Tronville,54,Meurthe-et-Moselle,44,Grand Est
54536,TUCQUEGNIEUX,54640,TUCQUEGNIEUX,,49.3071016735,5.89392083172,536,,Tucquegnieux,Tucquegnieux,54,Meurthe-et-Moselle,44,Grand Est
54537,UGNY,54870,UGNY,,49.4635508835,5.708275612,537,,Ugny,Ugny,54,Meurthe-et-Moselle,44,Grand Est
54538,URUFFE,54112,URUFFE,,48.5686957619,5.74648893211,538,,Uruffe,Uruffe,54,Meurthe-et-Moselle,44,Grand Est
54539,VACQUEVILLE,54540,VACQUEVILLE,,48.4812508877,6.81739744262,539,,Vacqueville,Vacqueville,54,Meurthe-et-Moselle,44,Grand Est
54540,VAL ET CHATILLON,54480,VAL ET CHATILLON,,48.5544444959,7.00739045852,540,,Val-et-Châtillon,Val-et-Châtillon,54,Meurthe-et-Moselle,44,Grand Est
54541,VALHEY,54370,VALHEY,,48.68350583,6.49390517861,541,,Valhey,Valhey,54,Meurthe-et-Moselle,44,Grand Est
54542,VALLEROY,54910,VALLEROY,,49.2141375647,5.91990455999,542,,Valleroy,Valleroy,54,Meurthe-et-Moselle,44,Grand Est
54543,VALLOIS,54830,VALLOIS,,48.4603393172,6.54530816916,543,,Vallois,Vallois,54,Meurthe-et-Moselle,44,Grand Est
54544,VANDELAINVILLE,54890,VANDELAINVILLE,,49.0292238077,5.98234979094,544,,Vandelainville,Vandelainville,54,Meurthe-et-Moselle,44,Grand Est
54545,VANDELEVILLE,54115,VANDELEVILLE,,48.4246792766,5.99150166147,545,,Vandeléville,Vandeléville,54,Meurthe-et-Moselle,44,Grand Est
54546,VANDIERES,54121,VANDIERES,,48.9590505792,6.02020912664,546,,Vandières,Vandières,54,Meurthe-et-Moselle,44,Grand Est
54547,VANDOEUVRE LES NANCY,54500,VANDOEUVRE LES NANCY,,48.6579999356,6.16531229307,547,,Vandœuvre-lès-Nancy,Vandœuvre-lès-Nancy,54,Meurthe-et-Moselle,44,Grand Est
54548,VANNES LE CHATEL,54112,VANNES LE CHATEL,,48.5715031888,5.78490409682,548,,Vannes-le-Châtel,Vannes-le-Châtel,54,Meurthe-et-Moselle,44,Grand Est
54549,VARANGEVILLE,54110,VARANGEVILLE,,48.6416869467,6.32137950944,549,,Varangéville,Varangéville,54,Meurthe-et-Moselle,44,Grand Est
54550,VATHIMENIL,54122,VATHIMENIL,,48.5048003013,6.61472207285,550,,Vathiménil,Vathiménil,54,Meurthe-et-Moselle,44,Grand Est
54551,VAUCOURT,54370,VAUCOURT,,48.6653978067,6.68626626794,551,,Vaucourt,Vaucourt,54,Meurthe-et-Moselle,44,Grand Est
54552,VAUDEMONT,54330,VAUDEMONT,,48.4212219796,6.05835186507,552,,Vaudémont,Vaudémont,54,Meurthe-et-Moselle,44,Grand Est
54553,VAUDEVILLE,54740,VAUDEVILLE,,48.4549633063,6.20876642137,553,,Vaudeville,Vaudeville,54,Meurthe-et-Moselle,44,Grand Est
54554,VAUDIGNY,54740,VAUDIGNY,,48.4379021324,6.20530783653,554,,Vaudigny,Vaudigny,54,Meurthe-et-Moselle,44,Grand Est
54555,VAXAINVILLE,54120,VAXAINVILLE,,48.5200456427,6.75581373849,555,,Vaxainville,Vaxainville,54,Meurthe-et-Moselle,44,Grand Est
54556,VEHO,54450,VEHO,,48.6033575468,6.70883858627,556,,Vého,Vého,54,Meurthe-et-Moselle,44,Grand Est
54557,VELAINE EN HAYE,54840,VELAINE EN HAYE,,48.6991910373,6.04715070856,557,,Velaine-en-Haye,Velaine-en-Haye,54,Meurthe-et-Moselle,44,Grand Est
54558,VELAINE SOUS AMANCE,54280,VELAINE SOUS AMANCE,,48.7156089379,6.32528004772,558,,Velaine-sous-Amance,Velaine-sous-Amance,54,Meurthe-et-Moselle,44,Grand Est
54559,VELLE SUR MOSELLE,54290,VELLE SUR MOSELLE,,48.5304708642,6.28555083892,559,,Velle-sur-Moselle,Velle-sur-Moselle,54,Meurthe-et-Moselle,44,Grand Est
54560,VENEY,54540,VENEY,,48.4607558773,6.8136260434,560,,Veney,Veney,54,Meurthe-et-Moselle,44,Grand Est
54561,VENNEZEY,54830,VENNEZEY,,48.4418803145,6.46147065137,561,,Vennezey,Vennezey,54,Meurthe-et-Moselle,44,Grand Est
54562,VERDENAL,54450,VERDENAL,,48.5875978822,6.80463240253,562,,Verdenal,Verdenal,54,Meurthe-et-Moselle,44,Grand Est
54563,VEZELISE,54330,VEZELISE,,48.4830480647,6.09399935525,563,,Vézelise,Vézelise,54,Meurthe-et-Moselle,44,Grand Est
54564,VIEVILLE EN HAYE,54470,VIEVILLE EN HAYE,,48.9392920708,5.91849230434,564,,Viéville-en-Haye,Viéville-en-Haye,54,Meurthe-et-Moselle,44,Grand Est
54565,VIGNEULLES,54360,VIGNEULLES,,48.56937975,6.34432277004,565,,Vigneulles,Vigneulles,54,Meurthe-et-Moselle,44,Grand Est
54566,VILCEY SUR TREY,54700,VILCEY SUR TREY,,48.9396903192,5.95866589461,566,,Vilcey-sur-Trey,Vilcey-sur-Trey,54,Meurthe-et-Moselle,44,Grand Est
54567,VILLACOURT,54290,VILLACOURT,,48.4398337287,6.3364959449,567,,Villacourt,Villacourt,54,Meurthe-et-Moselle,44,Grand Est
54568,VILLE AU MONTOIS,54620,VILLE AU MONTOIS,,49.417435049,5.80118957601,568,,Ville-au-Montois,Ville-au-Montois,54,Meurthe-et-Moselle,44,Grand Est
54569,VILLE AU VAL,54380,VILLE AU VAL,,48.8410567772,6.13005453194,569,,Ville-au-Val,Ville-au-Val,54,Meurthe-et-Moselle,44,Grand Est
54570,VILLECEY SUR MAD,54890,VILLECEY SUR MAD,,48.9935348155,5.9462111664,570,,Villecey-sur-Mad,Villecey-sur-Mad,54,Meurthe-et-Moselle,44,Grand Est
54571,VILLE EN VERMOIS,54210,VILLE EN VERMOIS,,48.6194878128,6.24428854524,571,,Ville-en-Vermois,Ville-en-Vermois,54,Meurthe-et-Moselle,44,Grand Est
54572,VILLE HOUDLEMONT,54730,VILLE HOUDLEMONT,,49.5391091104,5.65720411208,572,,Ville-Houdlémont,Ville-Houdlémont,54,Meurthe-et-Moselle,44,Grand Est
54573,VILLERS EN HAYE,54380,VILLERS EN HAYE,,48.8203320027,6.00699057007,573,,Villers-en-Haye,Villers-en-Haye,54,Meurthe-et-Moselle,44,Grand Est
54574,VILLERS LA CHEVRE,54870,VILLERS LA CHEVRE,,49.5055978223,5.68894758816,574,,Villers-la-Chèvre,Villers-la-Chèvre,54,Meurthe-et-Moselle,44,Grand Est
54575,VILLERS LA MONTAGNE,54920,VILLERS LA MONTAGNE,,49.4699530186,5.83188690951,575,,Villers-la-Montagne,Villers-la-Montagne,54,Meurthe-et-Moselle,44,Grand Est
54576,VILLERS LE ROND,54260,VILLERS LE ROND,,49.4690003224,5.48194955327,576,,Villers-le-Rond,Villers-le-Rond,54,Meurthe-et-Moselle,44,Grand Est
54577,VILLERS LES MOIVRONS,54760,VILLERS LES MOIVRONS,,48.8144465735,6.25119600136,577,,Villers-lès-Moivrons,Villers-lès-Moivrons,54,Meurthe-et-Moselle,44,Grand Est
54578,VILLERS LES NANCY,54600,VILLERS LES NANCY,,48.6616923324,6.1287873364,578,,Villers-lès-Nancy,Villers-lès-Nancy,54,Meurthe-et-Moselle,44,Grand Est
54579,VILLERS SOUS PRENY,54700,VILLERS SOUS PRENY,,48.9430575567,5.99502891051,579,,Villers-sous-Prény,Villers-sous-Prény,54,Meurthe-et-Moselle,44,Grand Est
54580,VILLERUPT,54190,VILLERUPT,,49.4647155933,5.92643741054,580,,Villerupt,Villerupt,54,Meurthe-et-Moselle,44,Grand Est
54580,VILLERUPT,54190,VILLERUPT,CANTEBONNE,49.4647155933,5.92643741054,580,,Villerupt,Villerupt,54,Meurthe-et-Moselle,44,Grand Est
54581,VILLE SUR YRON,54800,VILLE SUR YRON,,49.1209779475,5.87272709272,581,,Ville-sur-Yron,Ville-sur-Yron,54,Meurthe-et-Moselle,44,Grand Est
54582,VILLETTE,54260,VILLETTE,,49.478632384,5.55376018116,582,,Villette,Villette,54,Meurthe-et-Moselle,44,Grand Est
54583,VILLEY LE SEC,54840,VILLEY LE SEC,,48.6576126839,5.97596975592,583,,Villey-le-Sec,Villey-le-Sec,54,Meurthe-et-Moselle,44,Grand Est
54584,VILLEY ST ETIENNE,54200,VILLEY ST ETIENNE,,48.7281187821,5.96351375096,584,,Villey-Saint-Étienne,Villey-Saint-Étienne,54,Meurthe-et-Moselle,44,Grand Est
54585,VIRECOURT,54290,VIRECOURT,,48.4551698224,6.31832028976,585,,Virecourt,Virecourt,54,Meurthe-et-Moselle,44,Grand Est
54586,VITERNE,54123,VITERNE,,48.5872345547,6.01297760561,586,,Viterne,Viterne,54,Meurthe-et-Moselle,44,Grand Est
54587,VITREY,54330,VITREY,,48.4936169662,6.04249866428,587,,Vitrey,Vitrey,54,Meurthe-et-Moselle,44,Grand Est
54588,VITRIMONT,54300,VITRIMONT,,48.5905577157,6.44238362199,588,,Vitrimont,Vitrimont,54,Meurthe-et-Moselle,44,Grand Est
54589,VITTONVILLE,54700,VITTONVILLE,,48.9707772906,6.05981349899,589,,Vittonville,Vittonville,54,Meurthe-et-Moselle,44,Grand Est
54590,VIVIERS SUR CHIERS,54260,VIVIERS SUR CHIERS,,49.465789726,5.63118044912,590,,Viviers-sur-Chiers,Viviers-sur-Chiers,54,Meurthe-et-Moselle,44,Grand Est
54590,VIVIERS SUR CHIERS,54260,VIVIERS SUR CHIERS,BRAUMONT,49.465789726,5.63118044912,590,,Viviers-sur-Chiers,Viviers-sur-Chiers,54,Meurthe-et-Moselle,44,Grand Est
54590,VIVIERS SUR CHIERS,54260,VIVIERS SUR CHIERS,REVEMONT,49.465789726,5.63118044912,590,,Viviers-sur-Chiers,Viviers-sur-Chiers,54,Meurthe-et-Moselle,44,Grand Est
54591,VOINEMONT,54134,VOINEMONT,,48.5170735039,6.17416267478,591,,Voinémont,Voinémont,54,Meurthe-et-Moselle,44,Grand Est
54592,VRONCOURT,54330,VRONCOURT,,48.4591497683,6.07725776885,592,,Vroncourt,Vroncourt,54,Meurthe-et-Moselle,44,Grand Est
54593,WAVILLE,54890,WAVILLE,,49.012026579,5.93620450432,593,,Waville,Waville,54,Meurthe-et-Moselle,44,Grand Est
54594,XAMMES,54470,XAMMES,,48.978144801,5.84898390072,594,,Xammes,Xammes,54,Meurthe-et-Moselle,44,Grand Est
54595,XERMAMENIL,54300,XERMAMENIL,,48.5390973507,6.47693512099,595,,Xermaménil,Xermaménil,54,Meurthe-et-Moselle,44,Grand Est
54596,XEUILLEY,54990,XEUILLEY,,48.5619591921,6.09070968141,596,,Xeuilley,Xeuilley,54,Meurthe-et-Moselle,44,Grand Est
54597,XIROCOURT,54740,XIROCOURT,,48.4327331153,6.17625721621,597,,Xirocourt,Xirocourt,54,Meurthe-et-Moselle,44,Grand Est
54598,XIVRY CIRCOURT,54490,XIVRY CIRCOURT,,49.3568414875,5.76520028273,598,,Xivry-Circourt,Xivry-Circourt,54,Meurthe-et-Moselle,44,Grand Est
54599,XONVILLE,54800,XONVILLE,,49.0569615023,5.85606886475,599,,Xonville,Xonville,54,Meurthe-et-Moselle,44,Grand Est
54600,XOUSSE,54370,XOUSSE,,48.653274183,6.70808860029,600,,Xousse,Xousse,54,Meurthe-et-Moselle,44,Grand Est
54601,XURES,54370,XURES,,48.6903081814,6.65734195369,601,,Xures,Xures,54,Meurthe-et-Moselle,44,Grand Est
54602,HAN DEVANT PIERREPONT,54620,HAN DEVANT PIERREPONT,,49.3965119718,5.71113429533,602,,Han-devant-Pierrepont,Han-devant-Pierrepont,54,Meurthe-et-Moselle,44,Grand Est
55001,ABAINVILLE,55130,ABAINVILLE,,48.5326982449,5.51524727317,1,,Abainville,Abainville,55,Meuse,44,Grand Est
55002,ABAUCOURT HAUTECOURT,55400,ABAUCOURT HAUTECOURT,,49.1969606553,5.54868808043,2,,Abaucourt-Hautecourt,Abaucourt-Hautecourt,55,Meuse,44,Grand Est
55002,ABAUCOURT HAUTECOURT,55400,ABAUCOURT HAUTECOURT,HAUTECOURT LES BROVILLE,49.1969606553,5.54868808043,2,,Abaucourt-Hautecourt,Abaucourt-Hautecourt,55,Meuse,44,Grand Est
55004,AINCREVILLE,55110,AINCREVILLE,,49.3697469375,5.11617164649,4,,Aincreville,Aincreville,55,Meuse,44,Grand Est
55005,AMANTY,55130,AMANTY,,48.5151526576,5.60410565546,5,,Amanty,Amanty,55,Meuse,44,Grand Est
55007,AMBLY SUR MEUSE,55300,AMBLY SUR MEUSE,,49.0225781961,5.45226054579,7,,Ambly-sur-Meuse,Ambly-sur-Meuse,55,Meuse,44,Grand Est
55008,AMEL SUR L ETANG,55230,AMEL SUR L ETANG,,49.2572264224,5.64725124448,8,,Amel-sur-l'Étang,Amel-sur-l'Étang,55,Meuse,44,Grand Est
55009,ANCEMONT,55320,ANCEMONT,,49.0649831287,5.38366026137,9,,Ancemont,Ancemont,55,Meuse,44,Grand Est
55010,ANCERVILLE,55170,ANCERVILLE,,48.6399125992,5.02373888302,10,,Ancerville,Ancerville,55,Meuse,44,Grand Est
55011,ANDERNAY,55800,ANDERNAY,,48.783786876,4.95243588603,11,,Andernay,Andernay,55,Meuse,44,Grand Est
55012,APREMONT LA FORET,55300,APREMONT LA FORET,,48.8438751454,5.62186076093,12,,Apremont-la-Forêt,Apremont-la-Forêt,55,Meuse,44,Grand Est
55012,APREMONT LA FORET,55300,APREMONT LA FORET,LIOUVILLE,48.8438751454,5.62186076093,12,,Apremont-la-Forêt,Apremont-la-Forêt,55,Meuse,44,Grand Est
55012,APREMONT LA FORET,55300,APREMONT LA FORET,MARBOTTE,48.8438751454,5.62186076093,12,,Apremont-la-Forêt,Apremont-la-Forêt,55,Meuse,44,Grand Est
55012,APREMONT LA FORET,55300,APREMONT LA FORET,ST AGNANT SOUS LES COTES,48.8438751454,5.62186076093,12,,Apremont-la-Forêt,Apremont-la-Forêt,55,Meuse,44,Grand Est
55013,ARRANCY SUR CRUSNE,55230,ARRANCY SUR CRUSNE,,49.4118279021,5.65095584183,13,,Arrancy-sur-Crusne,Arrancy-sur-Crusne,55,Meuse,44,Grand Est
55014,AUBREVILLE,55120,AUBREVILLE,,49.1557767788,5.09477198919,14,,Aubréville,Aubréville,55,Meuse,44,Grand Est
55015,AULNOIS EN PERTHOIS,55170,AULNOIS EN PERTHOIS,,48.6347700844,5.12637328459,15,,Aulnois-en-Perthois,Aulnois-en-Perthois,55,Meuse,44,Grand Est
55017,AUTRECOURT SUR AIRE,55120,AUTRECOURT SUR AIRE,,49.031056488,5.13983417646,17,,Autrécourt-sur-Aire,Autrécourt-sur-Aire,55,Meuse,44,Grand Est
55018,AUTREVILLE ST LAMBERT,55700,AUTREVILLE ST LAMBERT,,49.5627667761,5.13433361931,18,,Autréville-Saint-Lambert,Autréville-Saint-Lambert,55,Meuse,44,Grand Est
55021,AVILLERS STE CROIX,55210,AVILLERS STE CROIX,,49.0338833425,5.70380804793,21,,Avillers-Sainte-Croix,Avillers-Sainte-Croix,55,Meuse,44,Grand Est
55022,AVIOTH,55600,AVIOTH,,49.5663158104,5.39049784871,22,,Avioth,Avioth,55,Meuse,44,Grand Est
55023,AVOCOURT,55270,AVOCOURT,,49.2032158089,5.14229694314,23,,Avocourt,Avocourt,55,Meuse,44,Grand Est
55024,AZANNES ET SOUMAZANNES,55150,AZANNES ET SOUMAZANNES,,49.2932309678,5.46797824659,24,,Azannes-et-Soumazannes,Azannes-et-Soumazannes,55,Meuse,44,Grand Est
55025,BAALON,55700,BAALON,,49.4830137826,5.25808058848,25,,Baâlon,Baâlon,55,Meuse,44,Grand Est
55026,BADONVILLIERS GERAUVILLIERS,55130,BADONVILLIERS GERAUVILLIERS,,48.5444733383,5.5777546612,26,,Badonvilliers-Gérauvilliers,Badonvilliers-Gérauvilliers,55,Meuse,44,Grand Est
55026,BADONVILLIERS GERAUVILLIERS,55130,BADONVILLIERS GERAUVILLIERS,GERAUVILLIERS,48.5444733383,5.5777546612,26,,Badonvilliers-Gérauvilliers,Badonvilliers-Gérauvilliers,55,Meuse,44,Grand Est
55027,BANNONCOURT,55300,BANNONCOURT,,48.9532610217,5.48371239858,27,,Bannoncourt,Bannoncourt,55,Meuse,44,Grand Est
55028,BANTHEVILLE,55110,BANTHEVILLE,,49.3529299482,5.07368538821,28,,Bantheville,Bantheville,55,Meuse,44,Grand Est
55029,BAR LE DUC,55000,BAR LE DUC,,48.7642280465,5.16346492169,29,,Bar-le-Duc,Bar-le-Duc,55,Meuse,44,Grand Est
55030,BAUDIGNECOURT,55130,BAUDIGNECOURT,,48.5651974464,5.45858309464,30,,Baudignécourt,Baudignécourt,55,Meuse,44,Grand Est
55031,BAUDONVILLIERS,55170,BAUDONVILLIERS,,48.6886339013,5.00566539287,31,,Baudonvilliers,Baudonvilliers,55,Meuse,44,Grand Est
55032,BAUDREMONT,55260,BAUDREMONT,,48.8371703603,5.39335538286,32,,Baudrémont,Baudrémont,55,Meuse,44,Grand Est
55033,BAULNY,55270,BAULNY,,49.2660859501,5.024077499,33,,Baulny,Baulny,55,Meuse,44,Grand Est
55034,BAZEILLES SUR OTHAIN,55600,BAZEILLES SUR OTHAIN,,49.5004142337,5.4263378414,34,,Bazeilles-sur-Othain,Bazeilles-sur-Othain,55,Meuse,44,Grand Est
55035,BAZINCOURT SUR SAULX,55170,BAZINCOURT SUR SAULX,,48.687294106,5.14014455856,35,,Bazincourt-sur-Saulx,Bazincourt-sur-Saulx,55,Meuse,44,Grand Est
55036,BEAUCLAIR,55700,BEAUCLAIR,,49.451925219,5.11758186646,36,,Beauclair,Beauclair,55,Meuse,44,Grand Est
55037,BEAUFORT EN ARGONNE,55700,BEAUFORT EN ARGONNE,,49.4796313426,5.10765410387,37,,Beaufort-en-Argonne,Beaufort-en-Argonne,55,Meuse,44,Grand Est
55038,BEAULIEU EN ARGONNE,55250,BEAULIEU EN ARGONNE,,49.0432133518,5.0398062849,38,,Beaulieu-en-Argonne,Beaulieu-en-Argonne,55,Meuse,44,Grand Est
55039,BEAUMONT EN VERDUNOIS,55100,BEAUMONT EN VERDUNOIS,,49.262130283,5.40982097485,39,,Beaumont-en-Verdunois,Beaumont-en-Verdunois,55,Meuse,44,Grand Est
55040,BEAUSITE,55250,BEAUSITE,,48.9672383709,5.21266788679,40,,Beausite,Beausite,55,Meuse,44,Grand Est
55040,BEAUSITE,55250,BEAUSITE,AMBLAINCOURT,48.9672383709,5.21266788679,40,,Beausite,Beausite,55,Meuse,44,Grand Est
55040,BEAUSITE,55250,BEAUSITE,DEUXNOUDS DEVANT BEAUZEE,48.9672383709,5.21266788679,40,,Beausite,Beausite,55,Meuse,44,Grand Est
55040,BEAUSITE,55250,BEAUSITE,SERAUCOURT,48.9672383709,5.21266788679,40,,Beausite,Beausite,55,Meuse,44,Grand Est
55041,BEHONNE,55000,BEHONNE,,48.7984377932,5.17542175052,41,,Behonne,Behonne,55,Meuse,44,Grand Est
55042,BELLERAY,55100,BELLERAY,,49.1313430455,5.38090978507,42,,Belleray,Belleray,55,Meuse,44,Grand Est
55043,BELLEVILLE SUR MEUSE,55430,BELLEVILLE SUR MEUSE,,49.179717344,5.39632263211,43,,Belleville-sur-Meuse,Belleville-sur-Meuse,55,Meuse,44,Grand Est
55044,BELRAIN,55260,BELRAIN,,48.8638222263,5.30723615462,44,,Belrain,Belrain,55,Meuse,44,Grand Est
55045,BELRUPT EN VERDUNOIS,55100,BELRUPT EN VERDUNOIS,,49.1477821746,5.45052020242,45,,Belrupt-en-Verdunois,Belrupt-en-Verdunois,55,Meuse,44,Grand Est
55046,BENEY EN WOEVRE,55210,BENEY EN WOEVRE,,48.9616218528,5.80703063987,46,,Beney-en-Woëvre,Beney-en-Woëvre,55,Meuse,44,Grand Est
55047,BETHELAINVILLE,55100,BETHELAINVILLE,,49.1684519662,5.23346533727,47,,Béthelainville,Béthelainville,55,Meuse,44,Grand Est
55048,BETHINCOURT,55270,BETHINCOURT,,49.2503994373,5.22447207758,48,,Béthincourt,Béthincourt,55,Meuse,44,Grand Est
55049,BEUREY SUR SAULX,55000,BEUREY SUR SAULX,,48.7566689617,5.02891767706,49,,Beurey-sur-Saulx,Beurey-sur-Saulx,55,Meuse,44,Grand Est
55050,BEZONVAUX,55100,BEZONVAUX,,49.2314503054,5.47231735633,50,,Bezonvaux,Bezonvaux,55,Meuse,44,Grand Est
55051,BIENCOURT SUR ORGE,55290,BIENCOURT SUR ORGE,,48.5699197095,5.34636472127,51,,Biencourt-sur-Orge,Biencourt-sur-Orge,55,Meuse,44,Grand Est
55053,BILLY SOUS MANGIENNES,55230,BILLY SOUS MANGIENNES,,49.3230881619,5.56172937909,53,,Billy-sous-Mangiennes,Billy-sous-Mangiennes,55,Meuse,44,Grand Est
55054,BISLEE,55300,BISLEE,,48.8731963735,5.51011218092,54,,Bislée,Bislée,55,Meuse,44,Grand Est
55055,BLANZEE,55400,BLANZEE,,49.1603955281,5.53647182641,55,,Blanzée,Blanzée,55,Meuse,44,Grand Est
55057,BOINVILLE EN WOEVRE,55400,BOINVILLE EN WOEVRE,,49.1885895553,5.67783902142,57,,Boinville-en-Woëvre,Boinville-en-Woëvre,55,Meuse,44,Grand Est
55058,BONCOURT SUR MEUSE,55200,BONCOURT SUR MEUSE,,48.8078856377,5.58513925167,58,,Boncourt-sur-Meuse,Boncourt-sur-Meuse,55,Meuse,44,Grand Est
55059,BONNET,55130,BONNET,,48.5253805044,5.42592334072,59,,Bonnet,Bonnet,55,Meuse,44,Grand Est
55060,BONZEE,55160,BONZEE,,49.0904854762,5.56470845413,60,,Bonzée,Bonzée,55,Meuse,44,Grand Est
55060,BONZEE,55160,BONZEE,MESNIL SOUS LES COTES,49.0904854762,5.56470845413,60,,Bonzée,Bonzée,55,Meuse,44,Grand Est
55060,BONZEE,55160,BONZEE,MONT VILLERS,49.0904854762,5.56470845413,60,,Bonzée,Bonzée,55,Meuse,44,Grand Est
55061,LE BOUCHON SUR SAULX,55500,LE BOUCHON SUR SAULX,,48.6090454105,5.22782104309,61,Le,Bouchon-sur-Saulx,Le Bouchon-sur-Saulx,55,Meuse,44,Grand Est
55062,BOUCONVILLE SUR MADT,55300,BOUCONVILLE SUR MADT,,48.8443958398,5.70456773671,62,,Bouconville-sur-Madt,Bouconville-sur-Madt,55,Meuse,44,Grand Est
55063,BOULIGNY,55240,BOULIGNY,,49.2916071166,5.74543375394,63,,Bouligny,Bouligny,55,Meuse,44,Grand Est
55064,BOUQUEMONT,55300,BOUQUEMONT,,48.9814089324,5.43303376805,64,,Bouquemont,Bouquemont,55,Meuse,44,Grand Est
55065,BOUREUILLES,55270,BOUREUILLES,,49.1963115003,5.02208212432,65,,Boureuilles,Boureuilles,55,Meuse,44,Grand Est
55066,BOVEE SUR BARBOURE,55190,BOVEE SUR BARBOURE,,48.6372168576,5.51006564419,66,,Bovée-sur-Barboure,Bovée-sur-Barboure,55,Meuse,44,Grand Est
55067,BOVIOLLES,55500,BOVIOLLES,,48.6452890077,5.41584931677,67,,Boviolles,Boviolles,55,Meuse,44,Grand Est
55068,BRABANT EN ARGONNE,55120,BRABANT EN ARGONNE,,49.1207632255,5.14345539293,68,,Brabant-en-Argonne,Brabant-en-Argonne,55,Meuse,44,Grand Est
55069,BRABANT LE ROI,55800,BRABANT LE ROI,,48.8537761275,4.98106135604,69,,Brabant-le-Roi,Brabant-le-Roi,55,Meuse,44,Grand Est
55070,BRABANT SUR MEUSE,55100,BRABANT SUR MEUSE,,49.2783588274,5.32831788043,70,,Brabant-sur-Meuse,Brabant-sur-Meuse,55,Meuse,44,Grand Est
55071,BRANDEVILLE,55150,BRANDEVILLE,,49.3922832005,5.30458987886,71,,Brandeville,Brandeville,55,Meuse,44,Grand Est
55072,BRAQUIS,55400,BRAQUIS,,49.1556838925,5.62867021892,72,,Braquis,Braquis,55,Meuse,44,Grand Est
55073,BRAS SUR MEUSE,55100,BRAS SUR MEUSE,,49.2078486836,5.38950541121,73,,Bras-sur-Meuse,Bras-sur-Meuse,55,Meuse,44,Grand Est
55075,BRAUVILLIERS,55170,BRAUVILLIERS,,48.5765120738,5.15370623292,75,,Brauvilliers,Brauvilliers,55,Meuse,44,Grand Est
55076,BREHEVILLE,55150,BREHEVILLE,,49.3843029047,5.33372175915,76,,Bréhéville,Bréhéville,55,Meuse,44,Grand Est
55077,BREUX,55600,BREUX,,49.5917827257,5.39399299667,77,,Breux,Breux,55,Meuse,44,Grand Est
55078,BRIEULLES SUR MEUSE,55110,BRIEULLES SUR MEUSE,,49.3273847506,5.16531418341,78,,Brieulles-sur-Meuse,Brieulles-sur-Meuse,55,Meuse,44,Grand Est
55079,BRILLON EN BARROIS,55000,BRILLON EN BARROIS,,48.7153593718,5.1083480967,79,,Brillon-en-Barrois,Brillon-en-Barrois,55,Meuse,44,Grand Est
55080,BRIXEY AUX CHANOINES,55140,BRIXEY AUX CHANOINES,,48.4730081857,5.71605178841,80,,Brixey-aux-Chanoines,Brixey-aux-Chanoines,55,Meuse,44,Grand Est
55081,BRIZEAUX,55250,BRIZEAUX,,49.0062286296,5.05620625699,81,,Brizeaux,Brizeaux,55,Meuse,44,Grand Est
55082,BROCOURT EN ARGONNE,55120,BROCOURT EN ARGONNE,,49.1128653147,5.17130390685,82,,Brocourt-en-Argonne,Brocourt-en-Argonne,55,Meuse,44,Grand Est
55083,BROUENNES,55700,BROUENNES,,49.5214266627,5.25429244028,83,,Brouennes,Brouennes,55,Meuse,44,Grand Est
55084,BROUSSEY EN BLOIS,55190,BROUSSEY EN BLOIS,,48.6345506518,5.55128932739,84,,Broussey-en-Blois,Broussey-en-Blois,55,Meuse,44,Grand Est
55085,BROUSSEY RAULECOURT,55200,BROUSSEY RAULECOURT,,48.81452443,5.71880607657,85,,Broussey-Raulecourt,Broussey-Raulecourt,55,Meuse,44,Grand Est
55085,BROUSSEY RAULECOURT,55200,BROUSSEY RAULECOURT,RAULECOURT,48.81452443,5.71880607657,85,,Broussey-Raulecourt,Broussey-Raulecourt,55,Meuse,44,Grand Est
55087,BURE,55290,BURE,,48.5093297187,5.34677594949,87,,Bure,Bure,55,Meuse,44,Grand Est
55088,BUREY EN VAUX,55140,BUREY EN VAUX,,48.5587875875,5.65225335625,88,,Burey-en-Vaux,Burey-en-Vaux,55,Meuse,44,Grand Est
55089,BUREY LA COTE,55140,BUREY LA COTE,,48.5001500482,5.68520575039,89,,Burey-la-Côte,Burey-la-Côte,55,Meuse,44,Grand Est
55093,BUXIERES SOUS LES COTES,55300,BUXIERES SOUS LES COTES,,48.9063587971,5.6783526742,93,,Buxières-sous-les-Côtes,Buxières-sous-les-Côtes,55,Meuse,44,Grand Est
55093,BUXIERES SOUS LES COTES,55300,BUXIERES SOUS LES COTES,BUXERULLES,48.9063587971,5.6783526742,93,,Buxières-sous-les-Côtes,Buxières-sous-les-Côtes,55,Meuse,44,Grand Est
55093,BUXIERES SOUS LES COTES,55300,BUXIERES SOUS LES COTES,WOINVILLE,48.9063587971,5.6783526742,93,,Buxières-sous-les-Côtes,Buxières-sous-les-Côtes,55,Meuse,44,Grand Est
55094,BUZY DARMONT,55400,BUZY DARMONT,,49.1747296825,5.70639284211,94,,Buzy-Darmont,Buzy-Darmont,55,Meuse,44,Grand Est
55094,BUZY DARMONT,55400,BUZY DARMONT,DARMONT,49.1747296825,5.70639284211,94,,Buzy-Darmont,Buzy-Darmont,55,Meuse,44,Grand Est
55095,CESSE,55700,CESSE,,49.5091057988,5.14445527647,95,,Cesse,Cesse,55,Meuse,44,Grand Est
55096,CHAILLON,55210,CHAILLON,,48.949711014,5.64307884217,96,,Chaillon,Chaillon,55,Meuse,44,Grand Est
55097,CHALAINES,55140,CHALAINES,,48.5935730965,5.69702247068,97,,Chalaines,Chalaines,55,Meuse,44,Grand Est
55099,CHAMPNEUVILLE,55100,CHAMPNEUVILLE,,49.2365297669,5.32952814286,99,,Champneuville,Champneuville,55,Meuse,44,Grand Est
55100,CHAMPOUGNY,55140,CHAMPOUGNY,,48.5461096496,5.70282238531,100,,Champougny,Champougny,55,Meuse,44,Grand Est
55101,CHARDOGNE,55000,CHARDOGNE,,48.827232312,5.13068088608,101,,Chardogne,Chardogne,55,Meuse,44,Grand Est
55102,CHARNY SUR MEUSE,55100,CHARNY SUR MEUSE,,49.203830142,5.3418897321,102,,Charny-sur-Meuse,Charny-sur-Meuse,55,Meuse,44,Grand Est
55103,CHARPENTRY,55270,CHARPENTRY,,49.2572362947,5.04141957375,103,,Charpentry,Charpentry,55,Meuse,44,Grand Est
55104,CHASSEY BEAUPRE,55130,CHASSEY BEAUPRE,,48.4464179633,5.43815444912,104,,Chassey-Beaupré,Chassey-Beaupré,55,Meuse,44,Grand Est
55105,CHATILLON SOUS LES COTES,55400,CHATILLON SOUS LES COTES,,49.1391205448,5.51313292709,105,,Châtillon-sous-les-Côtes,Châtillon-sous-les-Côtes,55,Meuse,44,Grand Est
55106,CHATTANCOURT,55100,CHATTANCOURT,,49.2160851171,5.26496953074,106,,Chattancourt,Chattancourt,55,Meuse,44,Grand Est
55107,CHAUMONT DEVANT DAMVILLERS,55150,CHAUMONT DEVANT DAMVILLERS,,49.309821487,5.43117741433,107,,Chaumont-devant-Damvillers,Chaumont-devant-Damvillers,55,Meuse,44,Grand Est
55108,CHAUMONT SUR AIRE,55260,CHAUMONT SUR AIRE,,48.9255521315,5.26979694314,108,,Chaumont-sur-Aire,Chaumont-sur-Aire,55,Meuse,44,Grand Est
55109,CHAUVENCY LE CHATEAU,55600,CHAUVENCY LE CHATEAU,,49.5231474391,5.30784952619,109,,Chauvency-le-Château,Chauvency-le-Château,55,Meuse,44,Grand Est
55110,CHAUVENCY ST HUBERT,55600,CHAUVENCY ST HUBERT,,49.5399729551,5.29668874531,110,,Chauvency-Saint-Hubert,Chauvency-Saint-Hubert,55,Meuse,44,Grand Est
55111,CHAUVONCOURT,55300,CHAUVONCOURT,,48.8925104825,5.49858002466,111,,Chauvoncourt,Chauvoncourt,55,Meuse,44,Grand Est
55113,CHEPPY,55270,CHEPPY,,49.2242640905,5.07282385534,113,,Cheppy,Cheppy,55,Meuse,44,Grand Est
55114,CHONVILLE MALAUMONT,55200,CHONVILLE MALAUMONT,,48.7606282553,5.49015408334,114,,Chonville-Malaumont,Chonville-Malaumont,55,Meuse,44,Grand Est
55114,CHONVILLE MALAUMONT,55200,CHONVILLE MALAUMONT,MALAUMONT,48.7606282553,5.49015408334,114,,Chonville-Malaumont,Chonville-Malaumont,55,Meuse,44,Grand Est
55115,CIERGES SOUS MONTFAUCON,55270,CIERGES SOUS MONTFAUCON,,49.2994621445,5.0933177343,115,,Cierges-sous-Montfaucon,Cierges-sous-Montfaucon,55,Meuse,44,Grand Est
55116,LE CLAON,55120,LE CLAON,,49.1453145718,4.99015300497,116,Le,Claon,Le Claon,55,Meuse,44,Grand Est
55117,CLERMONT EN ARGONNE,55120,CLERMONT EN ARGONNE,,49.1085070366,5.08060353165,117,,Clermont-en-Argonne,Clermont-en-Argonne,55,Meuse,44,Grand Est
55117,CLERMONT EN ARGONNE,55120,CLERMONT EN ARGONNE,AUZEVILLE EN ARGONNE,49.1085070366,5.08060353165,117,,Clermont-en-Argonne,Clermont-en-Argonne,55,Meuse,44,Grand Est
55117,CLERMONT EN ARGONNE,55120,CLERMONT EN ARGONNE,JUBECOURT,49.1085070366,5.08060353165,117,,Clermont-en-Argonne,Clermont-en-Argonne,55,Meuse,44,Grand Est
55117,CLERMONT EN ARGONNE,55120,CLERMONT EN ARGONNE,PAROIS,49.1085070366,5.08060353165,117,,Clermont-en-Argonne,Clermont-en-Argonne,55,Meuse,44,Grand Est
55118,CLERY LE GRAND,55110,CLERY LE GRAND,,49.3592013064,5.14431610293,118,,Cléry-le-Grand,Cléry-le-Grand,55,Meuse,44,Grand Est
55119,CLERY LE PETIT,55110,CLERY LE PETIT,,49.3658027512,5.17529974514,119,,Cléry-le-Petit,Cléry-le-Petit,55,Meuse,44,Grand Est
55120,COMBLES EN BARROIS,55000,COMBLES EN BARROIS,,48.7439561955,5.11375467167,120,,Combles-en-Barrois,Combles-en-Barrois,55,Meuse,44,Grand Est
55121,COMBRES SOUS LES COTES,55160,COMBRES SOUS LES COTES,,49.060064851,5.62218735388,121,,Combres-sous-les-Côtes,Combres-sous-les-Côtes,55,Meuse,44,Grand Est
55122,COMMERCY,55200,COMMERCY,,48.7462445722,5.55631428699,122,,Commercy,Commercy,55,Meuse,44,Grand Est
55123,LES HAUTS DE CHEE,55000,LES HAUTS DE CHEE,,48.8683371725,5.16520708778,123,Les,Hauts-de-Chée,Les Hauts-de-Chée,55,Meuse,44,Grand Est
55123,LES HAUTS DE CHEE,55000,LES HAUTS DE CHEE,GENICOURT SOUS CONDE,48.8683371725,5.16520708778,123,Les,Hauts-de-Chée,Les Hauts-de-Chée,55,Meuse,44,Grand Est
55123,LES HAUTS DE CHEE,55000,LES HAUTS DE CHEE,HARGEVILLE SUR CHEE,48.8683371725,5.16520708778,123,Les,Hauts-de-Chée,Les Hauts-de-Chée,55,Meuse,44,Grand Est
55123,LES HAUTS DE CHEE,55000,LES HAUTS DE CHEE,LES MARATS,48.8683371725,5.16520708778,123,Les,Hauts-de-Chée,Les Hauts-de-Chée,55,Meuse,44,Grand Est
55123,LES HAUTS DE CHEE,55000,LES HAUTS DE CHEE,LOUPPY SUR CHEE,48.8683371725,5.16520708778,123,Les,Hauts-de-Chée,Les Hauts-de-Chée,55,Meuse,44,Grand Est
55124,CONSENVOYE,55110,CONSENVOYE,,49.2955880804,5.31125963797,124,,Consenvoye,Consenvoye,55,Meuse,44,Grand Est
55125,CONTRISSON,55800,CONTRISSON,,48.8030599838,4.95587124601,125,,Contrisson,Contrisson,55,Meuse,44,Grand Est
55127,COURCELLES EN BARROIS,55260,COURCELLES EN BARROIS,,48.8264091372,5.42728737864,127,,Courcelles-en-Barrois,Courcelles-en-Barrois,55,Meuse,44,Grand Est
55128,COURCELLES SUR AIRE,55260,COURCELLES SUR AIRE,,48.9403925531,5.2333703508,128,,Courcelles-sur-Aire,Courcelles-sur-Aire,55,Meuse,44,Grand Est
55129,COUROUVRE,55260,COUROUVRE,,48.9406652212,5.35286669289,129,,Courouvre,Courouvre,55,Meuse,44,Grand Est
55132,COUSANCES LES FORGES,55170,COUSANCES LES FORGES,,48.6171588991,5.0853615873,132,,Cousances-les-Forges,Cousances-les-Forges,55,Meuse,44,Grand Est
55133,COUVERTPUIS,55290,COUVERTPUIS,,48.5811715624,5.30645025735,133,,Couvertpuis,Couvertpuis,55,Meuse,44,Grand Est
55134,COUVONGES,55800,COUVONGES,,48.7765173821,5.03607292231,134,,Couvonges,Couvonges,55,Meuse,44,Grand Est
55137,CUISY,55270,CUISY,,49.2673180788,5.18849470784,137,,Cuisy,Cuisy,55,Meuse,44,Grand Est
55138,CULEY,55000,CULEY,,,,138,,Culey,Culey,55,Meuse,44,Grand Est
55139,CUMIERES LE MORT HOMME,55100,CUMIERES LE MORT HOMME,,49.234262174,5.2679113417,139,,Cumières-le-Mort-Homme,Cumières-le-Mort-Homme,55,Meuse,44,Grand Est
55140,CUNEL,55110,CUNEL,,49.3320988491,5.11722235919,140,,Cunel,Cunel,55,Meuse,44,Grand Est
55141,DAGONVILLE,55500,DAGONVILLE,,48.7898702371,5.38475597116,141,,Dagonville,Dagonville,55,Meuse,44,Grand Est
55142,DAINVILLE BERTHELEVILLE,55130,DAINVILLE BERTHELEVILLE,,48.4507669673,5.50858709349,142,,Dainville-Bertheléville,Dainville-Bertheléville,55,Meuse,44,Grand Est
55143,DAMLOUP,55400,DAMLOUP,,49.1993965237,5.49261552169,143,,Damloup,Damloup,55,Meuse,44,Grand Est
55144,DAMMARIE SUR SAULX,55500,DAMMARIE SUR SAULX,,48.5968409986,5.2379279396,144,,Dammarie-sur-Saulx,Dammarie-sur-Saulx,55,Meuse,44,Grand Est
55145,DAMVILLERS,55150,DAMVILLERS,,49.34338311,5.41492141505,145,,Damvillers,Damvillers,55,Meuse,44,Grand Est
55146,DANNEVOUX,55110,DANNEVOUX,,49.3046381081,5.22673976949,146,,Dannevoux,Dannevoux,55,Meuse,44,Grand Est
55148,DELOUZE ROSIERES,55130,DELOUZE ROSIERES,,48.5657451674,5.53076706785,148,,Delouze-Rosières,Delouze-Rosières,55,Meuse,44,Grand Est
55148,DELOUZE ROSIERES,55130,DELOUZE ROSIERES,ROSIERES EN BLOIS,48.5657451674,5.53076706785,148,,Delouze-Rosières,Delouze-Rosières,55,Meuse,44,Grand Est
55149,DELUT,55150,DELUT,,49.4112381123,5.42861600912,149,,Delut,Delut,55,Meuse,44,Grand Est
55150,DEMANGE AUX EAUX,55130,DEMANGE AUX EAUX,,48.5915243717,5.47266149936,150,,Demange-aux-Eaux,Demange-aux-Eaux,55,Meuse,44,Grand Est
55153,DIEPPE SOUS DOUAUMONT,55400,DIEPPE SOUS DOUAUMONT,,49.2195718638,5.51909425729,153,,Dieppe-sous-Douaumont,Dieppe-sous-Douaumont,55,Meuse,44,Grand Est
55154,DIEUE SUR MEUSE,55320,DIEUE SUR MEUSE,,49.0797403582,5.42963036302,154,,Dieue-sur-Meuse,Dieue-sur-Meuse,55,Meuse,44,Grand Est
55155,DOMBASLE EN ARGONNE,55120,DOMBASLE EN ARGONNE,,49.1446409597,5.19544889955,155,,Dombasle-en-Argonne,Dombasle-en-Argonne,55,Meuse,44,Grand Est
55156,DOMBRAS,55150,DOMBRAS,,49.3816772358,5.44815989683,156,,Dombras,Dombras,55,Meuse,44,Grand Est
55157,DOMMARTIN LA MONTAGNE,55160,DOMMARTIN LA MONTAGNE,,49.0242520854,5.6017607408,157,,Dommartin-la-Montagne,Dommartin-la-Montagne,55,Meuse,44,Grand Est
55158,DOMMARY BARONCOURT,55240,DOMMARY BARONCOURT,,49.2842744329,5.7145908679,158,,Dommary-Baroncourt,Dommary-Baroncourt,55,Meuse,44,Grand Est
55159,DOMPCEVRIN,55300,DOMPCEVRIN,,48.9279404037,5.46777370123,159,,Dompcevrin,Dompcevrin,55,Meuse,44,Grand Est
55160,DOMPIERRE AUX BOIS,55300,DOMPIERRE AUX BOIS,,48.9997079464,5.59058564532,160,,Dompierre-aux-Bois,Dompierre-aux-Bois,55,Meuse,44,Grand Est
55162,DOMREMY LA CANNE,55240,DOMREMY LA CANNE,,49.2976443641,5.69310622182,162,,Domremy-la-Canne,Domremy-la-Canne,55,Meuse,44,Grand Est
55163,DONCOURT AUX TEMPLIERS,55160,DONCOURT AUX TEMPLIERS,,49.0599218489,5.7217872725,163,,Doncourt-aux-Templiers,Doncourt-aux-Templiers,55,Meuse,44,Grand Est
55164,DOUAUMONT,55100,DOUAUMONT,,49.223145551,5.43177151543,164,,Douaumont,Douaumont,55,Meuse,44,Grand Est
55165,DOULCON,55110,DOULCON,,49.3862415142,5.15481342523,165,,Doulcon,Doulcon,55,Meuse,44,Grand Est
55166,DUGNY SUR MEUSE,55100,DUGNY SUR MEUSE,,49.1014036398,5.37402646428,166,,Dugny-sur-Meuse,Dugny-sur-Meuse,55,Meuse,44,Grand Est
55167,DUN SUR MEUSE,55110,DUN SUR MEUSE,,49.3830660669,5.19574111404,167,,Dun-sur-Meuse,Dun-sur-Meuse,55,Meuse,44,Grand Est
55168,DUZEY,55230,DUZEY,,49.3582966072,5.6169163896,168,,Duzey,Duzey,55,Meuse,44,Grand Est
55169,ECOUVIEZ,55600,ECOUVIEZ,,49.5332207163,5.45169555956,169,,Écouviez,Écouviez,55,Meuse,44,Grand Est
55170,ECUREY EN VERDUNOIS,55150,ECUREY EN VERDUNOIS,,49.3568005016,5.34054044125,170,,Écurey-en-Verdunois,Écurey-en-Verdunois,55,Meuse,44,Grand Est
55171,EIX,55400,EIX,,49.1790990812,5.47995693569,171,,Eix,Eix,55,Meuse,44,Grand Est
55172,LES EPARGES,55160,LES EPARGES,,49.0644524312,5.58324844754,172,Les,Éparges,Les Éparges,55,Meuse,44,Grand Est
55173,EPIEZ SUR MEUSE,55140,EPIEZ SUR MEUSE,,48.5424602605,5.62671048102,173,,Épiez-sur-Meuse,Épiez-sur-Meuse,55,Meuse,44,Grand Est
55174,EPINONVILLE,55270,EPINONVILLE,,49.2732637894,5.08284466165,174,,Épinonville,Épinonville,55,Meuse,44,Grand Est
55175,ERIZE LA BRULEE,55260,ERIZE LA BRULEE,,48.846916203,5.27496200343,175,,Érize-la-Brûlée,Érize-la-Brûlée,55,Meuse,44,Grand Est
55177,ERIZE LA PETITE,55260,ERIZE LA PETITE,,48.9140537118,5.23715361728,177,,Érize-la-Petite,Érize-la-Petite,55,Meuse,44,Grand Est
55178,ERIZE ST DIZIER,55000,ERIZE ST DIZIER,,48.8106985165,5.28403725027,178,,Érize-Saint-Dizier,Érize-Saint-Dizier,55,Meuse,44,Grand Est
55179,ERNEVILLE AUX BOIS,55500,ERNEVILLE AUX BOIS,,48.7418948425,5.41343354401,179,,Erneville-aux-Bois,Erneville-aux-Bois,55,Meuse,44,Grand Est
55179,ERNEVILLE AUX BOIS,55500,ERNEVILLE AUX BOIS,DOMREMY AUX BOIS,48.7418948425,5.41343354401,179,,Erneville-aux-Bois,Erneville-aux-Bois,55,Meuse,44,Grand Est
55179,ERNEVILLE AUX BOIS,55500,ERNEVILLE AUX BOIS,LOXEVILLE,48.7418948425,5.41343354401,179,,Erneville-aux-Bois,Erneville-aux-Bois,55,Meuse,44,Grand Est
55180,ESNES EN ARGONNE,55100,ESNES EN ARGONNE,,49.2094249857,5.20477484518,180,,Esnes-en-Argonne,Esnes-en-Argonne,55,Meuse,44,Grand Est
55181,ETAIN,55400,ETAIN,,49.2168123871,5.6370016095,181,,Étain,Étain,55,Meuse,44,Grand Est
55182,ETON,55240,ETON,,49.2639037682,5.68791098189,182,,Éton,Éton,55,Meuse,44,Grand Est
55183,ETRAYE,55150,ETRAYE,,49.3271973903,5.35601277253,183,,Étraye,Étraye,55,Meuse,44,Grand Est
55184,EUVILLE,55200,EUVILLE,,48.7444024501,5.64512103609,184,,Euville,Euville,55,Meuse,44,Grand Est
55184,EUVILLE,55200,EUVILLE,AULNOIS SOUS VERTUZEY,48.7444024501,5.64512103609,184,,Euville,Euville,55,Meuse,44,Grand Est
55184,EUVILLE,55200,EUVILLE,VERTUZEY,48.7444024501,5.64512103609,184,,Euville,Euville,55,Meuse,44,Grand Est
55184,EUVILLE,55200,EUVILLE,VILLE ISSEY,48.7444024501,5.64512103609,184,,Euville,Euville,55,Meuse,44,Grand Est
55185,EVRES,55250,EVRES,,48.9823527638,5.1255521249,185,,Èvres,Èvres,55,Meuse,44,Grand Est
55186,FAINS VEEL,55000,FAINS VEEL,,48.779817337,5.11255044282,186,,Fains-Véel,Fains-Véel,55,Meuse,44,Grand Est
55186,FAINS VEEL,55000,FAINS VEEL,VEEL,48.779817337,5.11255044282,186,,Fains-Véel,Fains-Véel,55,Meuse,44,Grand Est
55188,FLASSIGNY,55600,FLASSIGNY,,49.4762151252,5.43742607078,188,,Flassigny,Flassigny,55,Meuse,44,Grand Est
55189,FLEURY DEVANT DOUAUMONT,55100,FLEURY DEVANT DOUAUMONT,,49.1943927792,5.433296975,189,,Fleury-devant-Douaumont,Fleury-devant-Douaumont,55,Meuse,44,Grand Est
55191,FOAMEIX ORNEL,55400,FOAMEIX ORNEL,,49.238852622,5.6078648482,191,,Foameix-Ornel,Foameix-Ornel,55,Meuse,44,Grand Est
55191,FOAMEIX ORNEL,55400,FOAMEIX ORNEL,ORNEL,49.238852622,5.6078648482,191,,Foameix-Ornel,Foameix-Ornel,55,Meuse,44,Grand Est
55192,FONTAINES ST CLAIR,55110,FONTAINES ST CLAIR,,49.3664401834,5.24637974366,192,,Fontaines-Saint-Clair,Fontaines-Saint-Clair,55,Meuse,44,Grand Est
55193,FORGES SUR MEUSE,55110,FORGES SUR MEUSE,,49.2601553209,5.27647162444,193,,Forges-sur-Meuse,Forges-sur-Meuse,55,Meuse,44,Grand Est
55194,FOUCAUCOURT SUR THABAS,55250,FOUCAUCOURT SUR THABAS,,49.0042517123,5.09735038261,194,,Foucaucourt-sur-Thabas,Foucaucourt-sur-Thabas,55,Meuse,44,Grand Est
55195,FOUCHERES AUX BOIS,55500,FOUCHERES AUX BOIS,,48.631196437,5.25056095689,195,,Fouchères-aux-Bois,Fouchères-aux-Bois,55,Meuse,44,Grand Est
55196,FREMEREVILLE SOUS LES COTES,55200,FREMEREVILLE SOUS LES COTES,,48.8038365424,5.65594480257,196,,Frémeréville-sous-les-Côtes,Frémeréville-sous-les-Côtes,55,Meuse,44,Grand Est
55197,FRESNES AU MONT,55260,FRESNES AU MONT,,48.8991708733,5.43476068255,197,,Fresnes-au-Mont,Fresnes-au-Mont,55,Meuse,44,Grand Est
55198,FRESNES EN WOEVRE,55160,FRESNES EN WOEVRE,,49.1009729702,5.63355600212,198,,Fresnes-en-Woëvre,Fresnes-en-Woëvre,55,Meuse,44,Grand Est
55199,FROIDOS,55120,FROIDOS,,49.0603189577,5.11809128238,199,,Froidos,Froidos,55,Meuse,44,Grand Est
55200,FROMEREVILLE LES VALLONS,55100,FROMEREVILLE LES VALLONS,,49.1642010056,5.29335139433,200,,Fromeréville-les-Vallons,Fromeréville-les-Vallons,55,Meuse,44,Grand Est
55201,FROMEZEY,55400,FROMEZEY,,49.21113111,5.58213989318,201,,Fromezey,Fromezey,55,Meuse,44,Grand Est
55202,FUTEAU,55120,FUTEAU,,49.0766831895,5.0069025981,202,,Futeau,Futeau,55,Meuse,44,Grand Est
55204,GENICOURT SUR MEUSE,55320,GENICOURT SUR MEUSE,,49.0415387246,5.44302652928,204,,Génicourt-sur-Meuse,Génicourt-sur-Meuse,55,Meuse,44,Grand Est
55206,GERCOURT ET DRILLANCOURT,55110,GERCOURT ET DRILLANCOURT,,49.282283356,5.23384529214,206,,Gercourt-et-Drillancourt,Gercourt-et-Drillancourt,55,Meuse,44,Grand Est
55207,GERY,55000,GERY,,48.7877900495,5.30037608106,207,,Géry,Géry,55,Meuse,44,Grand Est
55208,GESNES EN ARGONNE,55110,GESNES EN ARGONNE,,49.3059315656,5.05548499562,208,,Gesnes-en-Argonne,Gesnes-en-Argonne,55,Meuse,44,Grand Est
55210,GIMECOURT,55260,GIMECOURT,,48.8493284173,5.37522923437,210,,Gimécourt,Gimécourt,55,Meuse,44,Grand Est
55211,GINCREY,55400,GINCREY,,49.2601835738,5.56566816818,211,,Gincrey,Gincrey,55,Meuse,44,Grand Est
55212,GIRAUVOISIN,55200,GIRAUVOISIN,,48.80548383,5.63225535857,212,,Girauvoisin,Girauvoisin,55,Meuse,44,Grand Est
55214,GIVRAUVAL,55500,GIVRAUVAL,,48.6577603039,5.31810746284,214,,Givrauval,Givrauval,55,Meuse,44,Grand Est
55215,GONDRECOURT LE CHATEAU,55130,GONDRECOURT LE CHATEAU,,48.4927414057,5.50567222525,215,,Gondrecourt-le-Château,Gondrecourt-le-Château,55,Meuse,44,Grand Est
55215,GONDRECOURT LE CHATEAU,55130,GONDRECOURT LE CHATEAU,LUMEVILLE EN ORNOIS,48.4927414057,5.50567222525,215,,Gondrecourt-le-Château,Gondrecourt-le-Château,55,Meuse,44,Grand Est
55215,GONDRECOURT LE CHATEAU,55130,GONDRECOURT LE CHATEAU,TOURAILLES SOUS BOIS,48.4927414057,5.50567222525,215,,Gondrecourt-le-Château,Gondrecourt-le-Château,55,Meuse,44,Grand Est
55216,GOURAINCOURT,55230,GOURAINCOURT,,49.298652581,5.66811972316,216,,Gouraincourt,Gouraincourt,55,Meuse,44,Grand Est
55217,GOUSSAINCOURT,55140,GOUSSAINCOURT,,48.4821493618,5.67287004446,217,,Goussaincourt,Goussaincourt,55,Meuse,44,Grand Est
55218,GREMILLY,55150,GREMILLY,,49.2813295252,5.50984611095,218,,Gremilly,Gremilly,55,Meuse,44,Grand Est
55219,GRIMAUCOURT EN WOEVRE,55400,GRIMAUCOURT EN WOEVRE,,49.1630352825,5.56520722889,219,,Grimaucourt-en-Woëvre,Grimaucourt-en-Woëvre,55,Meuse,44,Grand Est
55220,GRIMAUCOURT PRES SAMPIGNY,55500,GRIMAUCOURT PRES SAMPIGNY,,48.7837595557,5.46509066709,220,,Grimaucourt-près-Sampigny,Grimaucourt-près-Sampigny,55,Meuse,44,Grand Est
55221,GUERPONT,55000,GUERPONT,,48.7221791424,5.25619009217,221,,Guerpont,Guerpont,55,Meuse,44,Grand Est
55222,GUSSAINVILLE,55400,GUSSAINVILLE,,49.1670483663,5.6624328239,222,,Gussainville,Gussainville,55,Meuse,44,Grand Est
55224,HAIRONVILLE,55000,HAIRONVILLE,,48.6878011778,5.09026737297,224,,Haironville,Haironville,55,Meuse,44,Grand Est
55225,HALLES SOUS LES COTES,55700,HALLES SOUS LES COTES,,49.4465576437,5.12784461624,225,,Halles-sous-les-Côtes,Halles-sous-les-Côtes,55,Meuse,44,Grand Est
55226,HAN LES JUVIGNY,55600,HAN LES JUVIGNY,,49.4821865073,5.33320912906,226,,Han-lès-Juvigny,Han-lès-Juvigny,55,Meuse,44,Grand Est
55228,HANNONVILLE SOUS LES COTES,55210,HANNONVILLE SOUS LES COTES,,49.0325694517,5.65281948352,228,,Hannonville-sous-les-Côtes,Hannonville-sous-les-Côtes,55,Meuse,44,Grand Est
55229,HAN SUR MEUSE,55300,HAN SUR MEUSE,,48.856313295,5.54572440463,229,,Han-sur-Meuse,Han-sur-Meuse,55,Meuse,44,Grand Est
55229,HAN SUR MEUSE,55300,HAN SUR MEUSE,AILLY SUR MEUSE,48.856313295,5.54572440463,229,,Han-sur-Meuse,Han-sur-Meuse,55,Meuse,44,Grand Est
55229,HAN SUR MEUSE,55300,HAN SUR MEUSE,BRASSEITTE,48.856313295,5.54572440463,229,,Han-sur-Meuse,Han-sur-Meuse,55,Meuse,44,Grand Est
55232,HARVILLE,55160,HARVILLE,,49.0956539028,5.72863323305,232,,Harville,Harville,55,Meuse,44,Grand Est
55236,HAUDAINVILLE,55100,HAUDAINVILLE,,49.1247629986,5.42686617859,236,,Haudainville,Haudainville,55,Meuse,44,Grand Est
55237,HAUDIOMONT,55160,HAUDIOMONT,,49.1156390921,5.54370706013,237,,Haudiomont,Haudiomont,55,Meuse,44,Grand Est
55239,HAUMONT PRES SAMOGNEUX,55100,HAUMONT PRES SAMOGNEUX,,49.2758081538,5.36911759313,239,,Haumont-près-Samogneux,Haumont-près-Samogneux,55,Meuse,44,Grand Est
55241,HEIPPES,55220,HEIPPES,,48.9997698114,5.27567605241,241,,Heippes,Heippes,55,Meuse,44,Grand Est
55242,HENNEMONT,55160,HENNEMONT,,49.1388580246,5.66540287149,242,,Hennemont,Hennemont,55,Meuse,44,Grand Est
55243,HERBEUVILLE,55210,HERBEUVILLE,,49.0473026346,5.63920324426,243,,Herbeuville,Herbeuville,55,Meuse,44,Grand Est
55244,HERMEVILLE EN WOEVRE,55400,HERMEVILLE EN WOEVRE,,49.1775489001,5.6019344626,244,,Herméville-en-Woëvre,Herméville-en-Woëvre,55,Meuse,44,Grand Est
55245,HEUDICOURT SOUS LES COTES,55210,HEUDICOURT SOUS LES COTES,,48.938354511,5.69786732895,245,,Heudicourt-sous-les-Côtes,Heudicourt-sous-les-Côtes,55,Meuse,44,Grand Est
55246,HEVILLIERS,55290,HEVILLIERS,,48.601764443,5.32678677655,246,,Hévilliers,Hévilliers,55,Meuse,44,Grand Est
55247,HORVILLE EN ORNOIS,55130,HORVILLE EN ORNOIS,,48.4878089513,5.46806713006,247,,Horville-en-Ornois,Horville-en-Ornois,55,Meuse,44,Grand Est
55248,HOUDELAINCOURT,55130,HOUDELAINCOURT,,48.5516999115,5.45666519241,248,,Houdelaincourt,Houdelaincourt,55,Meuse,44,Grand Est
55250,INOR,55700,INOR,,49.5549325347,5.16437044612,250,,Inor,Inor,55,Meuse,44,Grand Est
55251,IPPECOURT,55220,IPPECOURT,,49.0379722386,5.2114576271,251,,Ippécourt,Ippécourt,55,Meuse,44,Grand Est
55252,IRE LE SEC,55600,IRE LE SEC,,49.4734793135,5.39668662619,252,,Iré-le-Sec,Iré-le-Sec,55,Meuse,44,Grand Est
55253,LES ISLETTES,55120,LES ISLETTES,,49.1115870926,5.00586320659,253,Les,Islettes,Les Islettes,55,Meuse,44,Grand Est
55254,LES TROIS DOMAINES,55220,LES TROIS DOMAINES,,48.9707289763,5.28179879783,254,Les,Trois-Domaines,Les Trois-Domaines,55,Meuse,44,Grand Est
55254,LES TROIS DOMAINES,55220,LES TROIS DOMAINES,ISSONCOURT,48.9707289763,5.28179879783,254,Les,Trois-Domaines,Les Trois-Domaines,55,Meuse,44,Grand Est
55254,LES TROIS DOMAINES,55220,LES TROIS DOMAINES,MONDRECOURT,48.9707289763,5.28179879783,254,Les,Trois-Domaines,Les Trois-Domaines,55,Meuse,44,Grand Est
55254,LES TROIS DOMAINES,55220,LES TROIS DOMAINES,RIGNAUCOURT,48.9707289763,5.28179879783,254,Les,Trois-Domaines,Les Trois-Domaines,55,Meuse,44,Grand Est
55255,JAMETZ,55600,JAMETZ,,49.4256516039,5.39096385374,255,,Jametz,Jametz,55,Meuse,44,Grand Est
55256,JONVILLE EN WOEVRE,55160,JONVILLE EN WOEVRE,,49.0653727638,5.77605703237,256,,Jonville-en-Woëvre,Jonville-en-Woëvre,55,Meuse,44,Grand Est
55257,JOUY EN ARGONNE,55120,JOUY EN ARGONNE,,49.1347817438,5.22451457835,257,,Jouy-en-Argonne,Jouy-en-Argonne,55,Meuse,44,Grand Est
55258,GEVILLE,55200,GEVILLE,,48.7799328844,5.70340084835,258,,Geville,Geville,55,Meuse,44,Grand Est
55258,GEVILLE,55200,GEVILLE,CORNIEVILLE,48.7799328844,5.70340084835,258,,Geville,Geville,55,Meuse,44,Grand Est
55258,GEVILLE,55200,GEVILLE,GIRONVILLE SOUS LES COTES,48.7799328844,5.70340084835,258,,Geville,Geville,55,Meuse,44,Grand Est
55260,JULVECOURT,55120,JULVECOURT,,49.0596334338,5.19038629276,260,,Julvécourt,Julvécourt,55,Meuse,44,Grand Est
55261,JUVIGNY EN PERTHOIS,55170,JUVIGNY EN PERTHOIS,,48.5973061129,5.16335627452,261,,Juvigny-en-Perthois,Juvigny-en-Perthois,55,Meuse,44,Grand Est
55262,JUVIGNY SUR LOISON,55600,JUVIGNY SUR LOISON,,49.4608506316,5.33129815426,262,,Juvigny-sur-Loison,Juvigny-sur-Loison,55,Meuse,44,Grand Est
55263,KOEUR LA GRANDE,55300,KOEUR LA GRANDE,,48.8668514079,5.4686065027,263,,Kœur-la-Grande,Kœur-la-Grande,55,Meuse,44,Grand Est
55264,KOEUR LA PETITE,55300,KOEUR LA PETITE,,48.8517090729,5.45424623667,264,,Kœur-la-Petite,Kœur-la-Petite,55,Meuse,44,Grand Est
55265,LABEUVILLE,55160,LABEUVILLE,,49.0924273219,5.775136694,265,,Labeuville,Labeuville,55,Meuse,44,Grand Est
55266,LACHALADE,55120,LACHALADE,,49.1756905147,4.99292199348,266,,Lachalade,Lachalade,55,Meuse,44,Grand Est
55267,LACHAUSSEE,55210,LACHAUSSEE,,49.0332521585,5.79999880279,267,,Lachaussée,Lachaussée,55,Meuse,44,Grand Est
55267,LACHAUSSEE,55210,LACHAUSSEE,HADONVILLE LES LACHAUSSEE,49.0332521585,5.79999880279,267,,Lachaussée,Lachaussée,55,Meuse,44,Grand Est
55267,LACHAUSSEE,55210,LACHAUSSEE,HAUMONT LES LACHAUSSEE,49.0332521585,5.79999880279,267,,Lachaussée,Lachaussée,55,Meuse,44,Grand Est
55268,LACROIX SUR MEUSE,55300,LACROIX SUR MEUSE,,48.983746854,5.52830793027,268,,Lacroix-sur-Meuse,Lacroix-sur-Meuse,55,Meuse,44,Grand Est
55269,LAHAYMEIX,55260,LAHAYMEIX,,48.9348988887,5.41756249131,269,,Lahaymeix,Lahaymeix,55,Meuse,44,Grand Est
55270,LAHAYVILLE,55300,LAHAYVILLE,,48.8922156555,5.76968506389,270,,Lahayville,Lahayville,55,Meuse,44,Grand Est
55271,LAHEYCOURT,55800,LAHEYCOURT,,48.9009724444,5.03016026608,271,,Laheycourt,Laheycourt,55,Meuse,44,Grand Est
55272,LAIMONT,55800,LAIMONT,,48.8425240872,5.04206568758,272,,Laimont,Laimont,55,Meuse,44,Grand Est
55274,LAMORVILLE,55300,LAMORVILLE,,48.964535147,5.59226400853,274,,Lamorville,Lamorville,55,Meuse,44,Grand Est
55274,LAMORVILLE,55300,LAMORVILLE,DEUXNOUDS AUX BOIS,48.964535147,5.59226400853,274,,Lamorville,Lamorville,55,Meuse,44,Grand Est
55274,LAMORVILLE,55300,LAMORVILLE,LAVIGNEVILLE,48.964535147,5.59226400853,274,,Lamorville,Lamorville,55,Meuse,44,Grand Est
55274,LAMORVILLE,55300,LAMORVILLE,SPADA,48.964535147,5.59226400853,274,,Lamorville,Lamorville,55,Meuse,44,Grand Est
55275,LAMOUILLY,55700,LAMOUILLY,,49.5481877863,5.24415102151,275,,Lamouilly,Lamouilly,55,Meuse,44,Grand Est
55276,LANDRECOURT LEMPIRE,55100,LANDRECOURT LEMPIRE,,49.0991326551,5.32989005207,276,,Landrecourt-Lempire,Landrecourt-Lempire,55,Meuse,44,Grand Est
55276,LANDRECOURT LEMPIRE,55100,LANDRECOURT LEMPIRE,LEMPIRE AUX BOIS,49.0991326551,5.32989005207,276,,Landrecourt-Lempire,Landrecourt-Lempire,55,Meuse,44,Grand Est
55278,LANEUVILLE AU RUPT,55190,LANEUVILLE AU RUPT,,48.7074064434,5.57588667991,278,,Laneuville-au-Rupt,Laneuville-au-Rupt,55,Meuse,44,Grand Est
55279,LANEUVILLE SUR MEUSE,55700,LANEUVILLE SUR MEUSE,,49.4960126597,5.12109125774,279,,Laneuville-sur-Meuse,Laneuville-sur-Meuse,55,Meuse,44,Grand Est
55280,LANHERES,55400,LANHERES,,49.2082886446,5.71133805157,280,,Lanhères,Lanhères,55,Meuse,44,Grand Est
55281,LATOUR EN WOEVRE,55160,LATOUR EN WOEVRE,,49.0909063934,5.80554436766,281,,Latour-en-Woëvre,Latour-en-Woëvre,55,Meuse,44,Grand Est
55282,LAVALLEE,55260,LAVALLEE,,48.8067455989,5.3330507642,282,,Lavallée,Lavallée,55,Meuse,44,Grand Est
55284,LAVINCOURT,55170,LAVINCOURT,,48.6573246186,5.14765738477,284,,Lavincourt,Lavincourt,55,Meuse,44,Grand Est
55285,LAVOYE,55120,LAVOYE,,49.0478330422,5.13923689971,285,,Lavoye,Lavoye,55,Meuse,44,Grand Est
55286,LEMMES,55220,LEMMES,,49.0685421139,5.29370015131,286,,Lemmes,Lemmes,55,Meuse,44,Grand Est
55288,LEROUVILLE,55200,LEROUVILLE,,48.7788808268,5.53636457415,288,,Lérouville,Lérouville,55,Meuse,44,Grand Est
55289,LEVONCOURT,55260,LEVONCOURT,,48.826608488,5.34664441975,289,,Levoncourt,Levoncourt,55,Meuse,44,Grand Est
55290,LIGNIERES SUR AIRE,55260,LIGNIERES SUR AIRE,,48.8104118654,5.39217105616,290,,Lignières-sur-Aire,Lignières-sur-Aire,55,Meuse,44,Grand Est
55291,LIGNY EN BARROIS,55500,LIGNY EN BARROIS,,48.6621088949,5.2962935372,291,,Ligny-en-Barrois,Ligny-en-Barrois,55,Meuse,44,Grand Est
55292,LINY DEVANT DUN,55110,LINY DEVANT DUN,,49.3535491651,5.20662377454,292,,Liny-devant-Dun,Liny-devant-Dun,55,Meuse,44,Grand Est
55293,LION DEVANT DUN,55110,LION DEVANT DUN,,49.4174380341,5.25307410301,293,,Lion-devant-Dun,Lion-devant-Dun,55,Meuse,44,Grand Est
55295,LISLE EN BARROIS,55250,LISLE EN BARROIS,,48.9202691464,5.08297428907,295,,Lisle-en-Barrois,Lisle-en-Barrois,55,Meuse,44,Grand Est
55296,LISLE EN RIGAULT,55000,LISLE EN RIGAULT,,48.7110294084,5.0337606691,296,L',Isle-en-Rigault,L'Isle-en-Rigault,55,Meuse,44,Grand Est
55297,LISSEY,55150,LISSEY,,49.3766676279,5.36653086946,297,,Lissey,Lissey,55,Meuse,44,Grand Est
55298,LOISEY,55000,LOISEY,,48.7673476973,5.28322526105,298,,Loisey,Loisey,55,Meuse,44,Grand Est
55299,LOISON,55230,LOISON,,49.3040815275,5.59193126702,299,,Loison,Loison,55,Meuse,44,Grand Est
55300,LONGEAUX,55500,LONGEAUX,,48.639459973,5.32835659769,300,,Longeaux,Longeaux,55,Meuse,44,Grand Est
55301,LONGCHAMPS SUR AIRE,55260,LONGCHAMPS SUR AIRE,,48.9126907802,5.30611003141,301,,Longchamps-sur-Aire,Longchamps-sur-Aire,55,Meuse,44,Grand Est
55302,LONGEVILLE EN BARROIS,55000,LONGEVILLE EN BARROIS,,48.7386917643,5.20324681568,302,,Longeville-en-Barrois,Longeville-en-Barrois,55,Meuse,44,Grand Est
55303,LOUPMONT,55300,LOUPMONT,,48.8661005424,5.67917505776,303,,Loupmont,Loupmont,55,Meuse,44,Grand Est
55304,LOUPPY LE CHATEAU,55800,LOUPPY LE CHATEAU,,48.8659998801,5.07830494124,304,,Louppy-le-Château,Louppy-le-Château,55,Meuse,44,Grand Est
55306,LOUPPY SUR LOISON,55600,LOUPPY SUR LOISON,,49.4378207831,5.33742764775,306,,Louppy-sur-Loison,Louppy-sur-Loison,55,Meuse,44,Grand Est
55307,LOUVEMONT COTE DU POIVRE,55100,LOUVEMONT COTE DU POIVRE,,49.2407092317,5.40169210567,307,,Louvemont-Côte-du-Poivre,Louvemont-Côte-du-Poivre,55,Meuse,44,Grand Est
55310,LUZY ST MARTIN,55700,LUZY ST MARTIN,,49.5273912467,5.1453294871,310,,Luzy-Saint-Martin,Luzy-Saint-Martin,55,Meuse,44,Grand Est
55311,MAIZERAY,55160,MAIZERAY,,49.1053498485,5.70142913056,311,,Maizeray,Maizeray,55,Meuse,44,Grand Est
55312,MAIZEY,55300,MAIZEY,,48.9230075702,5.53791038683,312,,Maizey,Maizey,55,Meuse,44,Grand Est
55313,MALANCOURT,55270,MALANCOURT,,49.2355612046,5.17616049731,313,,Malancourt,Malancourt,55,Meuse,44,Grand Est
55315,MANDRES EN BARROIS,55290,MANDRES EN BARROIS,,48.4962208596,5.39267614836,315,,Mandres-en-Barrois,Mandres-en-Barrois,55,Meuse,44,Grand Est
55316,MANGIENNES,55150,MANGIENNES,,49.3459600586,5.52912697423,316,,Mangiennes,Mangiennes,55,Meuse,44,Grand Est
55317,MANHEULLES,55160,MANHEULLES,,49.1211788426,5.59472588936,317,,Manheulles,Manheulles,55,Meuse,44,Grand Est
55320,MARCHEVILLE EN WOEVRE,55160,MARCHEVILLE EN WOEVRE,,49.0877081896,5.68164816489,320,,Marchéville-en-Woëvre,Marchéville-en-Woëvre,55,Meuse,44,Grand Est
55321,MARRE,55100,MARRE,,49.2016524858,5.29839041902,321,,Marre,Marre,55,Meuse,44,Grand Est
55322,MARSON SUR BARBOURE,55190,MARSON SUR BARBOURE,,48.6356525614,5.44358153935,322,,Marson-sur-Barboure,Marson-sur-Barboure,55,Meuse,44,Grand Est
55323,MARTINCOURT SUR MEUSE,55700,MARTINCOURT SUR MEUSE,,49.5346464428,5.17792141785,323,,Martincourt-sur-Meuse,Martincourt-sur-Meuse,55,Meuse,44,Grand Est
55324,MARVILLE,55600,MARVILLE,,49.4465296289,5.44770167137,324,,Marville,Marville,55,Meuse,44,Grand Est
55325,MAUCOURT SUR ORNE,55400,MAUCOURT SUR ORNE,,49.2531133882,5.51686707572,325,,Maucourt-sur-Orne,Maucourt-sur-Orne,55,Meuse,44,Grand Est
55326,MAULAN,55500,MAULAN,,48.666686474,5.25265458532,326,,Maulan,Maulan,55,Meuse,44,Grand Est
55327,MAUVAGES,55190,MAUVAGES,,48.5953356755,5.54882215388,327,,Mauvages,Mauvages,55,Meuse,44,Grand Est
55328,MAXEY SUR VAISE,55140,MAXEY SUR VAISE,,48.5296387207,5.6572928474,328,,Maxey-sur-Vaise,Maxey-sur-Vaise,55,Meuse,44,Grand Est
55329,MECRIN,55300,MECRIN,,48.8274213069,5.54766172848,329,,Mécrin,Mécrin,55,Meuse,44,Grand Est
55330,MELIGNY LE GRAND,55190,MELIGNY LE GRAND,,48.6783327417,5.49388673044,330,,Méligny-le-Grand,Méligny-le-Grand,55,Meuse,44,Grand Est
55331,MELIGNY LE PETIT,55190,MELIGNY LE PETIT,,48.6557409409,5.47216568886,331,,Méligny-le-Petit,Méligny-le-Petit,55,Meuse,44,Grand Est
55332,MENAUCOURT,55500,MENAUCOURT,,48.6555904423,5.36214352258,332,,Menaucourt,Menaucourt,55,Meuse,44,Grand Est
55333,MENIL AUX BOIS,55260,MENIL AUX BOIS,,48.8029208567,5.43679104224,333,,Ménil-aux-Bois,Ménil-aux-Bois,55,Meuse,44,Grand Est
55334,MENIL LA HORGNE,55190,MENIL LA HORGNE,,48.7009354685,5.53155497656,334,,Ménil-la-Horgne,Ménil-la-Horgne,55,Meuse,44,Grand Est
55335,MENIL SUR SAULX,55500,MENIL SUR SAULX,,48.6206443104,5.20630539586,335,,Ménil-sur-Saulx,Ménil-sur-Saulx,55,Meuse,44,Grand Est
55336,MERLES SUR LOISON,55150,MERLES SUR LOISON,,49.3645416905,5.47491286205,336,,Merles-sur-Loison,Merles-sur-Loison,55,Meuse,44,Grand Est
55338,MILLY SUR BRADON,55110,MILLY SUR BRADON,,49.3955801908,5.21589849467,338,,Milly-sur-Bradon,Milly-sur-Bradon,55,Meuse,44,Grand Est
55339,MOGEVILLE,55400,MOGEVILLE,,49.2438999043,5.53947409518,339,,Mogeville,Mogeville,55,Meuse,44,Grand Est
55340,MOGNEVILLE,55800,MOGNEVILLE,,48.7751610805,4.99231252941,340,,Mognéville,Mognéville,55,Meuse,44,Grand Est
55341,MOIREY FLABAS CREPION,55150,MOIREY FLABAS CREPION,,49.298309748,5.39163772407,341,,Moirey-Flabas-Crépion,Moirey-Flabas-Crépion,55,Meuse,44,Grand Est
55341,MOIREY FLABAS CREPION,55150,MOIREY FLABAS CREPION,CREPION,49.298309748,5.39163772407,341,,Moirey-Flabas-Crépion,Moirey-Flabas-Crépion,55,Meuse,44,Grand Est
55341,MOIREY FLABAS CREPION,55150,MOIREY FLABAS CREPION,FLABAS,49.298309748,5.39163772407,341,,Moirey-Flabas-Crépion,Moirey-Flabas-Crépion,55,Meuse,44,Grand Est
55343,MONTBLAINVILLE,55270,MONTBLAINVILLE,,49.2434560123,4.9948188695,343,,Montblainville,Montblainville,55,Meuse,44,Grand Est
55344,MONTBRAS,55140,MONTBRAS,,48.5153868171,5.70082281043,344,,Montbras,Montbras,55,Meuse,44,Grand Est
55345,MONT DEVANT SASSEY,55110,MONT DEVANT SASSEY,,49.411249525,5.15862269798,345,,Mont-devant-Sassey,Mont-devant-Sassey,55,Meuse,44,Grand Est
55346,MONTFAUCON D ARGONNE,55270,MONTFAUCON D ARGONNE,,49.2564181066,5.13457463823,346,,Montfaucon-d'Argonne,Montfaucon-d'Argonne,55,Meuse,44,Grand Est
55347,LES MONTHAIRONS,55320,LES MONTHAIRONS,,49.0425631534,5.3924863289,347,Les,Monthairons,Les Monthairons,55,Meuse,44,Grand Est
55348,MONTIERS SUR SAULX,55290,MONTIERS SUR SAULX,,48.5435992409,5.26905976425,348,,Montiers-sur-Saulx,Montiers-sur-Saulx,55,Meuse,44,Grand Est
55349,MONTIGNY DEVANT SASSEY,55110,MONTIGNY DEVANT SASSEY,,49.4269988159,5.13768878129,349,,Montigny-devant-Sassey,Montigny-devant-Sassey,55,Meuse,44,Grand Est
55350,MONTIGNY LES VAUCOULEURS,55140,MONTIGNY LES VAUCOULEURS,,48.5781146588,5.6048244301,350,,Montigny-lès-Vaucouleurs,Montigny-lès-Vaucouleurs,55,Meuse,44,Grand Est
55351,MONTMEDY,55600,MONTMEDY,,49.5154285158,5.37250308228,351,,Montmédy,Montmédy,55,Meuse,44,Grand Est
55352,MONTPLONNE,55000,MONTPLONNE,,48.6979724913,5.16926714673,352,,Montplonne,Montplonne,55,Meuse,44,Grand Est
55353,MONTSEC,55300,MONTSEC,,48.8910208908,5.72352186307,353,,Montsec,Montsec,55,Meuse,44,Grand Est
55355,MONTZEVILLE,55100,MONTZEVILLE,,49.1881158405,5.21643042708,355,,Montzéville,Montzéville,55,Meuse,44,Grand Est
55356,MORANVILLE,55400,MORANVILLE,,49.1788762926,5.54050637938,356,,Moranville,Moranville,55,Meuse,44,Grand Est
55357,MORGEMOULIN,55400,MORGEMOULIN,,49.2296830093,5.57188739785,357,,Morgemoulin,Morgemoulin,55,Meuse,44,Grand Est
55358,CHANTERAINE,55500,CHANTERAINE,,48.6785463151,5.39088582965,358,,Chanteraine,Chanteraine,55,Meuse,44,Grand Est
55358,CHANTERAINE,55500,CHANTERAINE,CHENNEVIERES,48.6785463151,5.39088582965,358,,Chanteraine,Chanteraine,55,Meuse,44,Grand Est
55358,CHANTERAINE,55500,CHANTERAINE,OEY,48.6785463151,5.39088582965,358,,Chanteraine,Chanteraine,55,Meuse,44,Grand Est
55359,MORLEY,55290,MORLEY,,48.5749796805,5.22473568305,359,,Morley,Morley,55,Meuse,44,Grand Est
55360,MOUILLY,55320,MOUILLY,,49.054067614,5.53932302592,360,,Mouilly,Mouilly,55,Meuse,44,Grand Est
55361,MOULAINVILLE,55400,MOULAINVILLE,,49.1609164543,5.49038770004,361,,Moulainville,Moulainville,55,Meuse,44,Grand Est
55362,MOULINS ST HUBERT,55700,MOULINS ST HUBERT,,49.5783636876,5.12770792958,362,,Moulins-Saint-Hubert,Moulins-Saint-Hubert,55,Meuse,44,Grand Est
55363,MOULOTTE,55160,MOULOTTE,,49.1019942644,5.75171537684,363,,Moulotte,Moulotte,55,Meuse,44,Grand Est
55364,MOUZAY,55700,MOUZAY,,49.4497659783,5.23933878643,364,,Mouzay,Mouzay,55,Meuse,44,Grand Est
55365,MURVAUX,55110,MURVAUX,,49.394776752,5.26819381857,365,,Murvaux,Murvaux,55,Meuse,44,Grand Est
55366,VAL D ORNAIN,55000,VAL D ORNAIN,,48.8072006792,5.08545079895,366,,Val-d'Ornain,Val-d'Ornain,55,Meuse,44,Grand Est
55366,VAL D ORNAIN,55000,VAL D ORNAIN,BUSSY LA COTE,48.8072006792,5.08545079895,366,,Val-d'Ornain,Val-d'Ornain,55,Meuse,44,Grand Est
55366,VAL D ORNAIN,55000,VAL D ORNAIN,VARNEY,48.8072006792,5.08545079895,366,,Val-d'Ornain,Val-d'Ornain,55,Meuse,44,Grand Est
55367,MUZERAY,55230,MUZERAY,,49.3387535839,5.62224266222,367,,Muzeray,Muzeray,55,Meuse,44,Grand Est
55368,NAIVES EN BLOIS,55190,NAIVES EN BLOIS,,48.6669601977,5.54899721589,368,,Naives-en-Blois,Naives-en-Blois,55,Meuse,44,Grand Est
55369,NAIVES ROSIERES,55000,NAIVES ROSIERES,,48.8002647073,5.2290200392,369,,Naives-Rosières,Naives-Rosières,55,Meuse,44,Grand Est
55369,NAIVES ROSIERES,55000,NAIVES ROSIERES,ROSIERES DEVANT BAR,48.8002647073,5.2290200392,369,,Naives-Rosières,Naives-Rosières,55,Meuse,44,Grand Est
55370,NAIX AUX FORGES,55500,NAIX AUX FORGES,,48.6330383742,5.37144035596,370,,Naix-aux-Forges,Naix-aux-Forges,55,Meuse,44,Grand Est
55371,NANCOIS LE GRAND,55500,NANCOIS LE GRAND,,48.7139239344,5.39257246773,371,,Nançois-le-Grand,Nançois-le-Grand,55,Meuse,44,Grand Est
55372,NANCOIS SUR ORNAIN,55500,NANCOIS SUR ORNAIN,,48.7206725009,5.31852190946,372,,Nançois-sur-Ornain,Nançois-sur-Ornain,55,Meuse,44,Grand Est
55373,NANT LE GRAND,55500,NANT LE GRAND,,48.6815794654,5.22965400058,373,,Nant-le-Grand,Nant-le-Grand,55,Meuse,44,Grand Est
55374,NANT LE PETIT,55500,NANT LE PETIT,,48.6517674687,5.22623977721,374,,Nant-le-Petit,Nant-le-Petit,55,Meuse,44,Grand Est
55375,NANTILLOIS,55270,NANTILLOIS,,49.2996425661,5.13157990328,375,,Nantillois,Nantillois,55,Meuse,44,Grand Est
55376,NANTOIS,55500,NANTOIS,,48.6266032381,5.34341376776,376,,Nantois,Nantois,55,Meuse,44,Grand Est
55377,NEPVANT,55700,NEPVANT,,49.5368969307,5.22074045753,377,,Nepvant,Nepvant,55,Meuse,44,Grand Est
55378,NETTANCOURT,55800,NETTANCOURT,,48.8741218376,4.93497890158,378,,Nettancourt,Nettancourt,55,Meuse,44,Grand Est
55379,LE NEUFOUR,55120,LE NEUFOUR,,49.1295802161,4.98375457706,379,Le,Neufour,Le Neufour,55,Meuse,44,Grand Est
55380,NEUVILLE EN VERDUNOIS,55260,NEUVILLE EN VERDUNOIS,,48.9490096616,5.31159146275,380,,Neuville-en-Verdunois,Neuville-en-Verdunois,55,Meuse,44,Grand Est
55381,NEUVILLE LES VAUCOULEURS,55140,NEUVILLE LES VAUCOULEURS,,48.5765469967,5.65580757296,381,,Neuville-lès-Vaucouleurs,Neuville-lès-Vaucouleurs,55,Meuse,44,Grand Est
55382,NEUVILLE SUR ORNAIN,55800,NEUVILLE SUR ORNAIN,,48.8215154399,5.0508300311,382,,Neuville-sur-Ornain,Neuville-sur-Ornain,55,Meuse,44,Grand Est
55383,NEUVILLY EN ARGONNE,55120,NEUVILLY EN ARGONNE,,49.1634575272,5.04657677548,383,,Neuvilly-en-Argonne,Neuvilly-en-Argonne,55,Meuse,44,Grand Est
55384,NICEY SUR AIRE,55260,NICEY SUR AIRE,,48.8922146905,5.35514008596,384,,Nicey-sur-Aire,Nicey-sur-Aire,55,Meuse,44,Grand Est
55385,NIXEVILLE BLERCOURT,55120,NIXEVILLE BLERCOURT,,49.1118913184,5.26188176234,385,,Nixéville-Blercourt,Nixéville-Blercourt,55,Meuse,44,Grand Est
55385,NIXEVILLE BLERCOURT,55120,NIXEVILLE BLERCOURT,BLERCOURT,49.1118913184,5.26188176234,385,,Nixéville-Blercourt,Nixéville-Blercourt,55,Meuse,44,Grand Est
55386,NONSARD LAMARCHE,55210,NONSARD LAMARCHE,,48.9387436314,5.75541848294,386,,Nonsard-Lamarche,Nonsard-Lamarche,55,Meuse,44,Grand Est
55386,NONSARD LAMARCHE,55210,NONSARD LAMARCHE,LAMARCHE EN WOEVRE,48.9387436314,5.75541848294,386,,Nonsard-Lamarche,Nonsard-Lamarche,55,Meuse,44,Grand Est
55387,NOUILLONPONT,55230,NOUILLONPONT,,49.3572202791,5.66034226167,387,,Nouillonpont,Nouillonpont,55,Meuse,44,Grand Est
55388,NOYERS AUZECOURT,55800,NOYERS AUZECOURT,,48.8849963805,4.9795655698,388,,Noyers-Auzécourt,Noyers-Auzécourt,55,Meuse,44,Grand Est
55388,NOYERS AUZECOURT,55800,NOYERS AUZECOURT,AUZECOURT,48.8849963805,4.9795655698,388,,Noyers-Auzécourt,Noyers-Auzécourt,55,Meuse,44,Grand Est
55389,NUBECOURT,55250,NUBECOURT,,49.0052141438,5.17649305461,389,,Nubécourt,Nubécourt,55,Meuse,44,Grand Est
55389,NUBECOURT,55250,NUBECOURT,BULAINVILLE,49.0052141438,5.17649305461,389,,Nubécourt,Nubécourt,55,Meuse,44,Grand Est
55389,NUBECOURT,55250,NUBECOURT,FLEURY SUR AIRE,49.0052141438,5.17649305461,389,,Nubécourt,Nubécourt,55,Meuse,44,Grand Est
55391,OLIZY SUR CHIERS,55700,OLIZY SUR CHIERS,,49.5587566959,5.20819261385,391,,Olizy-sur-Chiers,Olizy-sur-Chiers,55,Meuse,44,Grand Est
55394,ORNES,55150,ORNES,,49.2567451481,5.47371666783,394,,Ornes,Ornes,55,Meuse,44,Grand Est
55395,OSCHES,55220,OSCHES,,49.0460229213,5.24882014612,395,,Osches,Osches,55,Meuse,44,Grand Est
55396,OURCHES SUR MEUSE,55190,OURCHES SUR MEUSE,,48.6653426686,5.6928001458,396,,Ourches-sur-Meuse,Ourches-sur-Meuse,55,Meuse,44,Grand Est
55397,PAGNY LA BLANCHE COTE,55140,PAGNY LA BLANCHE COTE,,48.5374465111,5.73922302223,397,,Pagny-la-Blanche-Côte,Pagny-la-Blanche-Côte,55,Meuse,44,Grand Est
55398,PAGNY SUR MEUSE,55190,PAGNY SUR MEUSE,,48.6892208397,5.73298818244,398,,Pagny-sur-Meuse,Pagny-sur-Meuse,55,Meuse,44,Grand Est
55399,PAREID,55160,PAREID,,49.1238004957,5.70672304736,399,,Pareid,Pareid,55,Meuse,44,Grand Est
55400,PARFONDRUPT,55400,PARFONDRUPT,,49.1473840735,5.72473139951,400,,Parfondrupt,Parfondrupt,55,Meuse,44,Grand Est
55401,LES PAROCHES,55300,LES PAROCHES,,48.9106967738,5.48710777239,401,Les,Paroches,Les Paroches,55,Meuse,44,Grand Est
55403,PEUVILLERS,55150,PEUVILLERS,,49.3670336223,5.39792536022,403,,Peuvillers,Peuvillers,55,Meuse,44,Grand Est
55404,PIERREFITTE SUR AIRE,55260,PIERREFITTE SUR AIRE,,48.9069676213,5.34860611914,404,,Pierrefitte-sur-Aire,Pierrefitte-sur-Aire,55,Meuse,44,Grand Est
55405,PILLON,55230,PILLON,,49.3719058703,5.57959766721,405,,Pillon,Pillon,55,Meuse,44,Grand Est
55406,PINTHEVILLE,55160,PINTHEVILLE,,49.1156179213,5.66923029109,406,,Pintheville,Pintheville,55,Meuse,44,Grand Est
55407,PONT SUR MEUSE,55200,PONT SUR MEUSE,,48.8080941031,5.55195590684,407,,Pont-sur-Meuse,Pont-sur-Meuse,55,Meuse,44,Grand Est
55408,POUILLY SUR MEUSE,55700,POUILLY SUR MEUSE,,49.5411923043,5.11699665787,408,,Pouilly-sur-Meuse,Pouilly-sur-Meuse,55,Meuse,44,Grand Est
55409,PRETZ EN ARGONNE,55250,PRETZ EN ARGONNE,,48.9614732775,5.14788383834,409,,Pretz-en-Argonne,Pretz-en-Argonne,55,Meuse,44,Grand Est
55410,QUINCY LANDZECOURT,55600,QUINCY LANDZECOURT,,49.4911393915,5.2960461996,410,,Quincy-Landzécourt,Quincy-Landzécourt,55,Meuse,44,Grand Est
55411,RAMBLUZIN ET BENOITE VAUX,55220,RAMBLUZIN ET BENOITE VAUX,,48.9907557305,5.33360643775,411,,Rambluzin-et-Benoite-Vaux,Rambluzin-et-Benoite-Vaux,55,Meuse,44,Grand Est
55412,RAMBUCOURT,55300,RAMBUCOURT,,48.8352452657,5.75755366267,412,,Rambucourt,Rambucourt,55,Meuse,44,Grand Est
55414,RANCOURT SUR ORNAIN,55800,RANCOURT SUR ORNAIN,,48.8201062551,4.91562191671,414,,Rancourt-sur-Ornain,Rancourt-sur-Ornain,55,Meuse,44,Grand Est
55415,RANZIERES,55300,RANZIERES,,49.0265798115,5.50221886713,415,,Ranzières,Ranzières,55,Meuse,44,Grand Est
55416,RARECOURT,55120,RARECOURT,,49.0748050041,5.09770601968,416,,Rarécourt,Rarécourt,55,Meuse,44,Grand Est
55419,RECICOURT,55120,RECICOURT,,49.1539592244,5.16115927527,419,,Récicourt,Récicourt,55,Meuse,44,Grand Est
55420,RECOURT LE CREUX,55220,RECOURT LE CREUX,,48.9990933177,5.37408151001,420,,Récourt-le-Creux,Récourt-le-Creux,55,Meuse,44,Grand Est
55421,REFFROY,55190,REFFROY,,48.6261033324,5.47057526455,421,,Reffroy,Reffroy,55,Meuse,44,Grand Est
55422,REGNEVILLE SUR MEUSE,55110,REGNEVILLE SUR MEUSE,,49.2519964472,5.31620365223,422,,Regnéville-sur-Meuse,Regnéville-sur-Meuse,55,Meuse,44,Grand Est
55423,REMBERCOURT SOMMAISNE,55250,REMBERCOURT SOMMAISNE,,48.9200891019,5.17855123412,423,,Rembercourt-Sommaisne,Rembercourt-Sommaisne,55,Meuse,44,Grand Est
55423,REMBERCOURT SOMMAISNE,55250,REMBERCOURT SOMMAISNE,SOMMAISNE,48.9200891019,5.17855123412,423,,Rembercourt-Sommaisne,Rembercourt-Sommaisne,55,Meuse,44,Grand Est
55424,REMENNECOURT,55800,REMENNECOURT,,48.7994502862,4.91706495138,424,,Remennecourt,Remennecourt,55,Meuse,44,Grand Est
55425,REMOIVILLE,55600,REMOIVILLE,,49.4374801225,5.37609892461,425,,Remoiville,Remoiville,55,Meuse,44,Grand Est
55426,RESSON,55000,RESSON,,48.771071403,5.2310401165,426,,Resson,Resson,55,Meuse,44,Grand Est
55427,REVIGNY SUR ORNAIN,55800,REVIGNY SUR ORNAIN,,48.8268165357,4.97675349582,427,,Revigny-sur-Ornain,Revigny-sur-Ornain,55,Meuse,44,Grand Est
55428,REVILLE AUX BOIS,55150,REVILLE AUX BOIS,,49.3410510097,5.34124962311,428,,Réville-aux-Bois,Réville-aux-Bois,55,Meuse,44,Grand Est
55429,RIAVILLE,55160,RIAVILLE,,49.0974087964,5.6657442627,429,,Riaville,Riaville,55,Meuse,44,Grand Est
55430,RIBEAUCOURT,55290,RIBEAUCOURT,,48.5441750908,5.35669405147,430,,Ribeaucourt,Ribeaucourt,55,Meuse,44,Grand Est
55431,RICHECOURT,55300,RICHECOURT,,48.8920724047,5.75183420043,431,,Richecourt,Richecourt,55,Meuse,44,Grand Est
55433,RIGNY LA SALLE,55140,RIGNY LA SALLE,,48.6274903264,5.72062942814,433,,Rigny-la-Salle,Rigny-la-Salle,55,Meuse,44,Grand Est
55434,RIGNY ST MARTIN,55140,RIGNY ST MARTIN,,48.6115007542,5.74768119219,434,,Rigny-Saint-Martin,Rigny-Saint-Martin,55,Meuse,44,Grand Est
55435,ROBERT ESPAGNE,55000,ROBERT ESPAGNE,,48.7325838025,5.02417040964,435,,Robert-Espagne,Robert-Espagne,55,Meuse,44,Grand Est
55436,LES ROISES,55130,LES ROISES,,48.457800837,5.63400298857,436,Les,Roises,Les Roises,55,Meuse,44,Grand Est
55437,ROMAGNE SOUS LES COTES,55150,ROMAGNE SOUS LES COTES,,49.3282356396,5.46600393873,437,,Romagne-sous-les-Côtes,Romagne-sous-les-Côtes,55,Meuse,44,Grand Est
55438,ROMAGNE SOUS MONTFAUCON,55110,ROMAGNE SOUS MONTFAUCON,,49.3272531274,5.07279522494,438,,Romagne-sous-Montfaucon,Romagne-sous-Montfaucon,55,Meuse,44,Grand Est
55439,RONVAUX,55160,RONVAUX,,49.1308317869,5.56071907553,439,,Ronvaux,Ronvaux,55,Meuse,44,Grand Est
55442,RAIVAL,55260,RAIVAL,,48.8854759177,5.25147773833,442,,Raival,Raival,55,Meuse,44,Grand Est
55442,RAIVAL,55260,RAIVAL,ERIZE LA GRANDE,48.8854759177,5.25147773833,442,,Raival,Raival,55,Meuse,44,Grand Est
55443,ROUVRES EN WOEVRE,55400,ROUVRES EN WOEVRE,,49.224367243,5.6960269701,443,,Rouvres-en-Woëvre,Rouvres-en-Woëvre,55,Meuse,44,Grand Est
55444,ROUVROIS SUR MEUSE,55300,ROUVROIS SUR MEUSE,,48.9500686957,5.52570837054,444,,Rouvrois-sur-Meuse,Rouvrois-sur-Meuse,55,Meuse,44,Grand Est
55445,ROUVROIS SUR OTHAIN,55230,ROUVROIS SUR OTHAIN,,49.3871461165,5.62761373552,445,,Rouvrois-sur-Othain,Rouvrois-sur-Othain,55,Meuse,44,Grand Est
55446,RUMONT,55000,RUMONT,,48.8297105505,5.27258056103,446,,Rumont,Rumont,55,Meuse,44,Grand Est
55447,RUPT AUX NONAINS,55170,RUPT AUX NONAINS,,48.6626472968,5.09507160902,447,,Rupt-aux-Nonains,Rupt-aux-Nonains,55,Meuse,44,Grand Est
55448,RUPT DEVANT ST MIHIEL,55260,RUPT DEVANT ST MIHIEL,,48.8850341422,5.40109789376,448,,Rupt-devant-Saint-Mihiel,Rupt-devant-Saint-Mihiel,55,Meuse,44,Grand Est
55449,RUPT EN WOEVRE,55320,RUPT EN WOEVRE,,49.0616267227,5.49481707998,449,,Rupt-en-Woëvre,Rupt-en-Woëvre,55,Meuse,44,Grand Est
55450,RUPT SUR OTHAIN,55150,RUPT SUR OTHAIN,,49.4152733096,5.4797768236,450,,Rupt-sur-Othain,Rupt-sur-Othain,55,Meuse,44,Grand Est
55452,ST AMAND SUR ORNAIN,55500,ST AMAND SUR ORNAIN,,48.6275522043,5.39456538238,452,,Saint-Amand-sur-Ornain,Saint-Amand-sur-Ornain,55,Meuse,44,Grand Est
55453,ST ANDRE EN BARROIS,55220,ST ANDRE EN BARROIS,,49.0097722899,5.23315841367,453,,Saint-André-en-Barrois,Saint-André-en-Barrois,55,Meuse,44,Grand Est
55454,ST AUBIN SUR AIRE,55500,ST AUBIN SUR AIRE,,48.7154934695,5.44781303231,454,,Saint-Aubin-sur-Aire,Saint-Aubin-sur-Aire,55,Meuse,44,Grand Est
55456,ST GERMAIN SUR MEUSE,55140,ST GERMAIN SUR MEUSE,,48.6480131272,5.72350490371,456,,Saint-Germain-sur-Meuse,Saint-Germain-sur-Meuse,55,Meuse,44,Grand Est
55457,ST HILAIRE EN WOEVRE,55160,ST HILAIRE EN WOEVRE,,49.074793582,5.70636197976,457,,Saint-Hilaire-en-Woëvre,Saint-Hilaire-en-Woëvre,55,Meuse,44,Grand Est
55457,ST HILAIRE EN WOEVRE,55160,ST HILAIRE EN WOEVRE,BUTGNEVILLE,49.074793582,5.70636197976,457,,Saint-Hilaire-en-Woëvre,Saint-Hilaire-en-Woëvre,55,Meuse,44,Grand Est
55457,ST HILAIRE EN WOEVRE,55160,ST HILAIRE EN WOEVRE,WADONVILLE EN WOEVRE,49.074793582,5.70636197976,457,,Saint-Hilaire-en-Woëvre,Saint-Hilaire-en-Woëvre,55,Meuse,44,Grand Est
55458,ST JEAN LES BUZY,55400,ST JEAN LES BUZY,,49.1730960084,5.74178569512,458,,Saint-Jean-lès-Buzy,Saint-Jean-lès-Buzy,55,Meuse,44,Grand Est
55459,ST JOIRE,55130,ST JOIRE,,48.5817287073,5.4087600099,459,,Saint-Joire,Saint-Joire,55,Meuse,44,Grand Est
55460,ST JULIEN SOUS LES COTES,55200,ST JULIEN SOUS LES COTES,,48.8193157554,5.61428641475,460,,Saint-Julien-sous-les-Côtes,Saint-Julien-sous-les-Côtes,55,Meuse,44,Grand Est
55461,ST LAURENT SUR OTHAIN,55150,ST LAURENT SUR OTHAIN,,49.3949095365,5.52799758686,461,,Saint-Laurent-sur-Othain,Saint-Laurent-sur-Othain,55,Meuse,44,Grand Est
55462,ST MAURICE SOUS LES COTES,55210,ST MAURICE SOUS LES COTES,,49.0106196654,5.66598601118,462,,Saint-Maurice-sous-les-Côtes,Saint-Maurice-sous-les-Côtes,55,Meuse,44,Grand Est
55463,ST MIHIEL,55300,ST MIHIEL,,48.8912948587,5.58243236203,463,,Saint-Mihiel,Saint-Mihiel,55,Meuse,44,Grand Est
55464,ST PIERREVILLERS,55230,ST PIERREVILLERS,,49.3759234559,5.68731569816,464,,Saint-Pierrevillers,Saint-Pierrevillers,55,Meuse,44,Grand Est
55465,ST REMY LA CALONNE,55160,ST REMY LA CALONNE,,49.0404075198,5.58732606223,465,,Saint-Remy-la-Calonne,Saint-Remy-la-Calonne,55,Meuse,44,Grand Est
55466,SALMAGNE,55000,SALMAGNE,,48.7557307288,5.33344834879,466,,Salmagne,Salmagne,55,Meuse,44,Grand Est
55467,SAMPIGNY,55300,SAMPIGNY,,48.819060545,5.48663507868,467,,Sampigny,Sampigny,55,Meuse,44,Grand Est
55468,SAMOGNEUX,55100,SAMOGNEUX,,49.2582106398,5.34650093972,468,,Samogneux,Samogneux,55,Meuse,44,Grand Est
55469,SASSEY SUR MEUSE,55110,SASSEY SUR MEUSE,,49.4119703128,5.18704970118,469,,Sassey-sur-Meuse,Sassey-sur-Meuse,55,Meuse,44,Grand Est
55470,SAUDRUPT,55000,SAUDRUPT,,48.6952209612,5.05807421719,470,,Saudrupt,Saudrupt,55,Meuse,44,Grand Est
55471,SAULMORY ET VILLEFRANCHE,55110,SAULMORY ET VILLEFRANCHE,,49.4388180734,5.17185912384,471,,Saulmory-Villefranche,Saulmory-Villefranche,55,Meuse,44,Grand Est
55472,SAULVAUX,55500,SAULVAUX,,48.6897973515,5.46044345308,472,,Saulvaux,Saulvaux,55,Meuse,44,Grand Est
55472,SAULVAUX,55500,SAULVAUX,VAUX LA GRANDE,48.6897973515,5.46044345308,472,,Saulvaux,Saulvaux,55,Meuse,44,Grand Est
55472,SAULVAUX,55500,SAULVAUX,VAUX LA PETITE,48.6897973515,5.46044345308,472,,Saulvaux,Saulvaux,55,Meuse,44,Grand Est
55473,SAULX LES CHAMPLON,55160,SAULX LES CHAMPLON,,49.0735769232,5.65335195591,473,,Saulx-lès-Champlon,Saulx-lès-Champlon,55,Meuse,44,Grand Est
55473,SAULX LES CHAMPLON,55160,SAULX LES CHAMPLON,CHAMPLON,49.0735769232,5.65335195591,473,,Saulx-lès-Champlon,Saulx-lès-Champlon,55,Meuse,44,Grand Est
55474,SAUVIGNY,55140,SAUVIGNY,,48.5018634827,5.73596629266,474,,Sauvigny,Sauvigny,55,Meuse,44,Grand Est
55475,SAUVOY,55190,SAUVOY,,48.6394778194,5.59975067222,475,,Sauvoy,Sauvoy,55,Meuse,44,Grand Est
55476,SAVONNIERES DEVANT BAR,55000,SAVONNIERES DEVANT BAR,,48.7489756437,5.17589243702,476,,Savonnières-devant-Bar,Savonnières-devant-Bar,55,Meuse,44,Grand Est
55477,SAVONNIERES EN PERTHOIS,55170,SAVONNIERES EN PERTHOIS,,48.6061624959,5.13483299132,477,,Savonnières-en-Perthois,Savonnières-en-Perthois,55,Meuse,44,Grand Est
55479,SEIGNEULLES,55000,SEIGNEULLES,,48.8524409919,5.23092840664,479,,Seigneulles,Seigneulles,55,Meuse,44,Grand Est
55481,SENON,55230,SENON,,49.2817425376,5.61733509785,481,,Senon,Senon,55,Meuse,44,Grand Est
55482,SENONCOURT LES MAUJOUY,55220,SENONCOURT LES MAUJOUY,,49.0585412777,5.34394200098,482,,Senoncourt-les-Maujouy,Senoncourt-les-Maujouy,55,Meuse,44,Grand Est
55484,SEPTSARGES,55270,SEPTSARGES,,49.2865032784,5.17446943745,484,,Septsarges,Septsarges,55,Meuse,44,Grand Est
55485,SEPVIGNY,55140,SEPVIGNY,,48.5643055469,5.69442972921,485,,Sepvigny,Sepvigny,55,Meuse,44,Grand Est
55487,SEUZEY,55300,SEUZEY,,48.9882446498,5.56084684316,487,,Seuzey,Seuzey,55,Meuse,44,Grand Est
55488,SILMONT,55000,SILMONT,,48.7392069249,5.24642373048,488,,Silmont,Silmont,55,Meuse,44,Grand Est
55489,SIVRY LA PERCHE,55100,SIVRY LA PERCHE,,49.1391427258,5.26052651476,489,,Sivry-la-Perche,Sivry-la-Perche,55,Meuse,44,Grand Est
55490,SIVRY SUR MEUSE,55110,SIVRY SUR MEUSE,,49.3274104355,5.28255466905,490,,Sivry-sur-Meuse,Sivry-sur-Meuse,55,Meuse,44,Grand Est
55492,SOMMEDIEUE,55320,SOMMEDIEUE,,49.1008388282,5.47677902729,492,,Sommedieue,Sommedieue,55,Meuse,44,Grand Est
55493,SOMMEILLES,55800,SOMMEILLES,,48.9157131194,4.96860127249,493,,Sommeilles,Sommeilles,55,Meuse,44,Grand Est
55494,SOMMELONNE,55170,SOMMELONNE,,48.6750698928,5.03693713079,494,,Sommelonne,Sommelonne,55,Meuse,44,Grand Est
55495,SORBEY,55230,SORBEY,,49.4037288217,5.58585055178,495,,Sorbey,Sorbey,55,Meuse,44,Grand Est
55496,SORCY ST MARTIN,55190,SORCY ST MARTIN,,48.719302425,5.6607909695,496,,Sorcy-Saint-Martin,Sorcy-Saint-Martin,55,Meuse,44,Grand Est
55497,LES SOUHESMES RAMPONT,55220,LES SOUHESMES RAMPONT,,49.0880279879,5.2363065868,497,Les,Souhesmes-Rampont,Les Souhesmes-Rampont,55,Meuse,44,Grand Est
55497,LES SOUHESMES RAMPONT,55220,LES SOUHESMES RAMPONT,RAMPONT,49.0880279879,5.2363065868,497,Les,Souhesmes-Rampont,Les Souhesmes-Rampont,55,Meuse,44,Grand Est
55498,SOUILLY,55220,SOUILLY,,49.0289891196,5.2999397258,498,,Souilly,Souilly,55,Meuse,44,Grand Est
55500,SPINCOURT,55230,SPINCOURT,,49.3333694763,5.69690578835,500,,Spincourt,Spincourt,55,Meuse,44,Grand Est
55500,SPINCOURT,55230,SPINCOURT,HAUCOURT LA RIGOLE,49.3333694763,5.69690578835,500,,Spincourt,Spincourt,55,Meuse,44,Grand Est
55500,SPINCOURT,55230,SPINCOURT,HOUDELAUCOURT SUR OTHAIN,49.3333694763,5.69690578835,500,,Spincourt,Spincourt,55,Meuse,44,Grand Est
55500,SPINCOURT,55230,SPINCOURT,OLLIERES,49.3333694763,5.69690578835,500,,Spincourt,Spincourt,55,Meuse,44,Grand Est
55500,SPINCOURT,55230,SPINCOURT,RECHICOURT,49.3333694763,5.69690578835,500,,Spincourt,Spincourt,55,Meuse,44,Grand Est
55501,STAINVILLE,55500,STAINVILLE,,48.6425989975,5.17866321517,501,,Stainville,Stainville,55,Meuse,44,Grand Est
55502,STENAY,55700,STENAY,,49.5005049028,5.19767442624,502,,Stenay,Stenay,55,Meuse,44,Grand Est
55503,TAILLANCOURT,55140,TAILLANCOURT,,48.5108199186,5.66150550751,503,,Taillancourt,Taillancourt,55,Meuse,44,Grand Est
55504,TANNOIS,55000,TANNOIS,,48.7133377728,5.22030723215,504,,Tannois,Tannois,55,Meuse,44,Grand Est
55505,THIERVILLE SUR MEUSE,55840,THIERVILLE SUR MEUSE,,49.1721699733,5.33910478758,505,,Thierville-sur-Meuse,Thierville-sur-Meuse,55,Meuse,44,Grand Est
55506,THILLOMBOIS,55260,THILLOMBOIS,,48.955498451,5.38479221078,506,,Thillombois,Thillombois,55,Meuse,44,Grand Est
55507,THILLOT,55210,THILLOT,,49.0250512589,5.66665859799,507,,Thillot,Thillot,55,Meuse,44,Grand Est
55508,THONNE LA LONG,55600,THONNE LA LONG,,49.5661986662,5.4256255311,508,,Thonne-la-Long,Thonne-la-Long,55,Meuse,44,Grand Est
55509,THONNE LE THIL,55600,THONNE LE THIL,,49.5717892465,5.34089829816,509,,Thonne-le-Thil,Thonne-le-Thil,55,Meuse,44,Grand Est
55510,THONNE LES PRES,55600,THONNE LES PRES,,49.5321707367,5.34285786422,510,,Thonne-les-Près,Thonne-les-Près,55,Meuse,44,Grand Est
55511,THONNELLE,55600,THONNELLE,,49.5522651595,5.35866825052,511,,Thonnelle,Thonnelle,55,Meuse,44,Grand Est
55512,TILLY SUR MEUSE,55220,TILLY SUR MEUSE,,48.9943756095,5.41496893941,512,,Tilly-sur-Meuse,Tilly-sur-Meuse,55,Meuse,44,Grand Est
55514,TREMONT SUR SAULX,55000,TREMONT SUR SAULX,,48.7435666584,5.06993719945,514,,Trémont-sur-Saulx,Trémont-sur-Saulx,55,Meuse,44,Grand Est
55515,TRESAUVAUX,55160,TRESAUVAUX,,49.0794988181,5.61218881526,515,,Trésauvaux,Trésauvaux,55,Meuse,44,Grand Est
55516,TREVERAY,55130,TREVERAY,,48.6053443839,5.3901669837,516,,Tréveray,Tréveray,55,Meuse,44,Grand Est
55517,SEUIL D ARGONNE,55250,SEUIL D ARGONNE,,48.9741184199,5.05640187676,517,,Seuil-d'Argonne,Seuil-d'Argonne,55,Meuse,44,Grand Est
55517,SEUIL D ARGONNE,55250,SEUIL D ARGONNE,SENARD,48.9741184199,5.05640187676,517,,Seuil-d'Argonne,Seuil-d'Argonne,55,Meuse,44,Grand Est
55517,SEUIL D ARGONNE,55250,SEUIL D ARGONNE,TRIAUCOURT EN ARGONNE,48.9741184199,5.05640187676,517,,Seuil-d'Argonne,Seuil-d'Argonne,55,Meuse,44,Grand Est
55518,COUSANCES LES TRICONVILLE,55500,COUSANCES LES TRICONVILLE,,48.769706572,5.40036892505,518,,Cousances-lès-Triconville,Cousances-lès-Triconville,55,Meuse,44,Grand Est
55518,COUSANCES LES TRICONVILLE,55500,COUSANCES LES TRICONVILLE,COUSANCES AU BOIS,48.769706572,5.40036892505,518,,Cousances-lès-Triconville,Cousances-lès-Triconville,55,Meuse,44,Grand Est
55519,TRONVILLE EN BARROIS,55310,TRONVILLE EN BARROIS,,48.7202054641,5.27880486884,519,,Tronville-en-Barrois,Tronville-en-Barrois,55,Meuse,44,Grand Est
55520,TROUSSEY,55190,TROUSSEY,,48.701256647,5.69348408341,520,,Troussey,Troussey,55,Meuse,44,Grand Est
55521,TROYON,55300,TROYON,,49.0009733042,5.48594007947,521,,Troyon,Troyon,55,Meuse,44,Grand Est
55522,UGNY SUR MEUSE,55140,UGNY SUR MEUSE,,48.6380468267,5.68950588575,522,,Ugny-sur-Meuse,Ugny-sur-Meuse,55,Meuse,44,Grand Est
55523,VACHERAUVILLE,55100,VACHERAUVILLE,,49.2298521797,5.36190605148,523,,Vacherauville,Vacherauville,55,Meuse,44,Grand Est
55525,VADELAINCOURT,55220,VADELAINCOURT,,49.0698810912,5.25891845389,525,,Vadelaincourt,Vadelaincourt,55,Meuse,44,Grand Est
55526,VADONVILLE,55200,VADONVILLE,,48.7985029531,5.5144264856,526,,Vadonville,Vadonville,55,Meuse,44,Grand Est
55527,VARENNES EN ARGONNE,55270,VARENNES EN ARGONNE,,49.2271516493,5.01654206132,527,,Varennes-en-Argonne,Varennes-en-Argonne,55,Meuse,44,Grand Est
55528,VARNEVILLE,55300,VARNEVILLE,,48.8771784427,5.65887449876,528,,Varnéville,Varnéville,55,Meuse,44,Grand Est
55530,VALBOIS,55300,VALBOIS,,48.9287959752,5.61607345803,530,,Valbois,Valbois,55,Meuse,44,Grand Est
55530,VALBOIS,55300,VALBOIS,SAVONNIERES EN WOEVRE,48.9287959752,5.61607345803,530,,Valbois,Valbois,55,Meuse,44,Grand Est
55530,VALBOIS,55300,VALBOIS,SENONVILLE,48.9287959752,5.61607345803,530,,Valbois,Valbois,55,Meuse,44,Grand Est
55531,VASSINCOURT,55800,VASSINCOURT,,48.8014203494,5.02678786197,531,,Vassincourt,Vassincourt,55,Meuse,44,Grand Est
55532,VAUBECOURT,55250,VAUBECOURT,,48.9422008662,5.10692416585,532,,Vaubecourt,Vaubecourt,55,Meuse,44,Grand Est
55533,VAUCOULEURS,55140,VAUCOULEURS,,48.6160491817,5.64137050524,533,,Vaucouleurs,Vaucouleurs,55,Meuse,44,Grand Est
55534,VAUDEVILLE LE HAUT,55130,VAUDEVILLE LE HAUT,,48.4489987899,5.58915571674,534,,Vaudeville-le-Haut,Vaudeville-le-Haut,55,Meuse,44,Grand Est
55535,VAUDONCOURT,55230,VAUDONCOURT,,49.3164530998,5.64496115253,535,,Vaudoncourt,Vaudoncourt,55,Meuse,44,Grand Est
55536,VAUQUOIS,55270,VAUQUOIS,,49.199378983,5.08325500348,536,,Vauquois,Vauquois,55,Meuse,44,Grand Est
55537,VAUX DEVANT DAMLOUP,55400,VAUX DEVANT DAMLOUP,,49.2061604941,5.46338028839,537,,Vaux-devant-Damloup,Vaux-devant-Damloup,55,Meuse,44,Grand Est
55540,VAUX LES PALAMEIX,55300,VAUX LES PALAMEIX,,49.0235694173,5.54952475226,540,,Vaux-lès-Palameix,Vaux-lès-Palameix,55,Meuse,44,Grand Est
55541,VAVINCOURT,55000,VAVINCOURT,,48.8232896251,5.19531664577,541,,Vavincourt,Vavincourt,55,Meuse,44,Grand Est
55543,VELAINES,55500,VELAINES,,48.6973807648,5.29679363324,543,,Velaines,Velaines,55,Meuse,44,Grand Est
55544,VELOSNES,55600,VELOSNES,,49.4989737491,5.46049245047,544,,Velosnes,Velosnes,55,Meuse,44,Grand Est
55545,VERDUN,55100,VERDUN,,49.1454831903,5.36141821261,545,,Verdun,Verdun,55,Meuse,44,Grand Est
55546,VERNEUIL GRAND,55600,VERNEUIL GRAND,,49.5312660602,5.42592624358,546,,Verneuil-Grand,Verneuil-Grand,55,Meuse,44,Grand Est
55547,VERNEUIL PETIT,55600,VERNEUIL PETIT,,49.5455943768,5.41822136927,547,,Verneuil-Petit,Verneuil-Petit,55,Meuse,44,Grand Est
55549,VERY,55270,VERY,,49.240211743,5.09287373247,549,,Véry,Véry,55,Meuse,44,Grand Est
55551,VIGNEULLES LES HATTONCHATEL,55210,VIGNEULLES LES HATTONCHATEL,,48.9857791469,5.724051532,551,,Vigneulles-lès-Hattonchâtel,Vigneulles-lès-Hattonchâtel,55,Meuse,44,Grand Est
55551,VIGNEULLES LES HATTONCHATEL,55210,VIGNEULLES LES HATTONCHATEL,BILLY SOUS LES COTES,48.9857791469,5.724051532,551,,Vigneulles-lès-Hattonchâtel,Vigneulles-lès-Hattonchâtel,55,Meuse,44,Grand Est
55551,VIGNEULLES LES HATTONCHATEL,55210,VIGNEULLES LES HATTONCHATEL,CREUE,48.9857791469,5.724051532,551,,Vigneulles-lès-Hattonchâtel,Vigneulles-lès-Hattonchâtel,55,Meuse,44,Grand Est
55551,VIGNEULLES LES HATTONCHATEL,55210,VIGNEULLES LES HATTONCHATEL,HATTONCHATEL,48.9857791469,5.724051532,551,,Vigneulles-lès-Hattonchâtel,Vigneulles-lès-Hattonchâtel,55,Meuse,44,Grand Est
55551,VIGNEULLES LES HATTONCHATEL,55210,VIGNEULLES LES HATTONCHATEL,HATTONVILLE,48.9857791469,5.724051532,551,,Vigneulles-lès-Hattonchâtel,Vigneulles-lès-Hattonchâtel,55,Meuse,44,Grand Est
55551,VIGNEULLES LES HATTONCHATEL,55210,VIGNEULLES LES HATTONCHATEL,ST BENOIT EN WOEVRE,48.9857791469,5.724051532,551,,Vigneulles-lès-Hattonchâtel,Vigneulles-lès-Hattonchâtel,55,Meuse,44,Grand Est
55551,VIGNEULLES LES HATTONCHATEL,55210,VIGNEULLES LES HATTONCHATEL,VIEVILLE SOUS LES COTES,48.9857791469,5.724051532,551,,Vigneulles-lès-Hattonchâtel,Vigneulles-lès-Hattonchâtel,55,Meuse,44,Grand Est
55552,VIGNEUL SOUS MONTMEDY,55600,VIGNEUL SOUS MONTMEDY,,49.5081292429,5.32876869093,552,,Vigneul-sous-Montmédy,Vigneul-sous-Montmédy,55,Meuse,44,Grand Est
55553,VIGNOT,55200,VIGNOT,,48.7806834874,5.61241732944,553,,Vignot,Vignot,55,Meuse,44,Grand Est
55554,VILLECLOYE,55600,VILLECLOYE,,49.5110917403,5.40412322951,554,,Villécloye,Villécloye,55,Meuse,44,Grand Est
55555,VILLE DEVANT BELRAIN,55260,VILLE DEVANT BELRAIN,,48.8765037549,5.3538368109,555,,Ville-devant-Belrain,Ville-devant-Belrain,55,Meuse,44,Grand Est
55556,VILLE DEVANT CHAUMONT,55150,VILLE DEVANT CHAUMONT,,49.2902473771,5.42758806672,556,,Ville-devant-Chaumont,Ville-devant-Chaumont,55,Meuse,44,Grand Est
55557,VILLE EN WOEVRE,55160,VILLE EN WOEVRE,,49.1384249835,5.61392700414,557,,Ville-en-Woëvre,Ville-en-Woëvre,55,Meuse,44,Grand Est
55559,VILLEROY SUR MEHOLLE,55190,VILLEROY SUR MEHOLLE,,48.616315357,5.57205116516,559,,Villeroy-sur-Méholle,Villeroy-sur-Méholle,55,Meuse,44,Grand Est
55560,VILLERS AUX VENTS,55800,VILLERS AUX VENTS,,48.859860916,5.02051057979,560,,Villers-aux-Vents,Villers-aux-Vents,55,Meuse,44,Grand Est
55561,VILLERS DEVANT DUN,55110,VILLERS DEVANT DUN,,49.3993889395,5.11188584059,561,,Villers-devant-Dun,Villers-devant-Dun,55,Meuse,44,Grand Est
55562,VILLERS LE SEC,55500,VILLERS LE SEC,,48.6244341706,5.29962310593,562,,Villers-le-Sec,Villers-le-Sec,55,Meuse,44,Grand Est
55563,VILLERS LES MANGIENNES,55150,VILLERS LES MANGIENNES,,49.3574782476,5.50300806274,563,,Villers-lès-Mangiennes,Villers-lès-Mangiennes,55,Meuse,44,Grand Est
55565,VILLERS SOUS PAREID,55160,VILLERS SOUS PAREID,,49.1271118517,5.7409473841,565,,Villers-sous-Pareid,Villers-sous-Pareid,55,Meuse,44,Grand Est
55566,VILLERS SUR MEUSE,55220,VILLERS SUR MEUSE,,49.0229531601,5.40373990843,566,,Villers-sur-Meuse,Villers-sur-Meuse,55,Meuse,44,Grand Est
55567,VILLE SUR COUSANCES,55120,VILLE SUR COUSANCES,,49.0788332374,5.17822742167,567,,Ville-sur-Cousances,Ville-sur-Cousances,55,Meuse,44,Grand Est
55568,VILLE SUR SAULX,55000,VILLE SUR SAULX,,48.7184994101,5.07040984768,568,,Ville-sur-Saulx,Ville-sur-Saulx,55,Meuse,44,Grand Est
55569,VILLOTTE DEVANT LOUPPY,55250,VILLOTTE DEVANT LOUPPY,,48.8913774589,5.0757136176,569,,Villotte-devant-Louppy,Villotte-devant-Louppy,55,Meuse,44,Grand Est
55570,VILLOTTE SUR AIRE,55260,VILLOTTE SUR AIRE,,48.8575993984,5.35419334822,570,,Villotte-sur-Aire,Villotte-sur-Aire,55,Meuse,44,Grand Est
55571,VILOSNES HARAUMONT,55110,VILOSNES HARAUMONT,,49.3370255498,5.2447539057,571,,Vilosnes-Haraumont,Vilosnes-Haraumont,55,Meuse,44,Grand Est
55571,VILOSNES HARAUMONT,55110,VILOSNES HARAUMONT,HARAUMONT,49.3370255498,5.2447539057,571,,Vilosnes-Haraumont,Vilosnes-Haraumont,55,Meuse,44,Grand Est
55572,VITTARVILLE,55150,VITTARVILLE,,49.3884920923,5.40985151504,572,,Vittarville,Vittarville,55,Meuse,44,Grand Est
55573,VOID VACON,55190,VOID VACON,,48.6743817207,5.62159767195,573,,Void-Vacon,Void-Vacon,55,Meuse,44,Grand Est
55573,VOID VACON,55190,VOID VACON,VACON,48.6743817207,5.62159767195,573,,Void-Vacon,Void-Vacon,55,Meuse,44,Grand Est
55574,VOUTHON BAS,55130,VOUTHON BAS,,48.4892446628,5.61374554666,574,,Vouthon-Bas,Vouthon-Bas,55,Meuse,44,Grand Est
55575,VOUTHON HAUT,55130,VOUTHON HAUT,,48.4742260699,5.60221819722,575,,Vouthon-Haut,Vouthon-Haut,55,Meuse,44,Grand Est
55577,WALY,55250,WALY,,49.0242695757,5.0987439035,577,,Waly,Waly,55,Meuse,44,Grand Est
55578,WARCQ,55400,WARCQ,,49.1910139129,5.64237556781,578,,Warcq,Warcq,55,Meuse,44,Grand Est
55579,WATRONVILLE,55160,WATRONVILLE,,49.1386242762,5.54701396462,579,,Watronville,Watronville,55,Meuse,44,Grand Est
55580,WAVRILLE,55150,WAVRILLE,,49.3187272917,5.37534023156,580,,Wavrille,Wavrille,55,Meuse,44,Grand Est
55581,WILLERONCOURT,55500,WILLERONCOURT,,48.7148687969,5.3568649824,581,,Willeroncourt,Willeroncourt,55,Meuse,44,Grand Est
55582,WISEPPE,55700,WISEPPE,,49.4607454359,5.16493375894,582,,Wiseppe,Wiseppe,55,Meuse,44,Grand Est
55583,WOEL,55210,WOEL,,49.0386550285,5.74002247215,583,,Woël,Woël,55,Meuse,44,Grand Est
55584,WOIMBEY,55300,WOIMBEY,,48.965431246,5.4520114726,584,,Woimbey,Woimbey,55,Meuse,44,Grand Est
55586,XIVRAY ET MARVOISIN,55300,XIVRAY ET MARVOISIN,,48.8664911436,5.73011450191,586,,Xivray-et-Marvoisin,Xivray-et-Marvoisin,55,Meuse,44,Grand Est
56001,ALLAIRE,56350,ALLAIRE,,47.6392193445,-2.17681507496,1,,Allaire,Allaire,56,Morbihan,53,Bretagne
56002,AMBON,56190,AMBON,,47.5570972136,-2.54057380669,2,,Ambon,Ambon,56,Morbihan,53,Bretagne
56003,ARRADON,56610,ARRADON,,47.6335958588,-2.8220328139,3,,Arradon,Arradon,56,Morbihan,53,Bretagne
56004,ARZAL,56190,ARZAL,,47.519901266,-2.40478493652,4,,Arzal,Arzal,56,Morbihan,53,Bretagne
56005,ARZON,56640,ARZON,,47.5470278592,-2.88784873321,5,,Arzon,Arzon,56,Morbihan,53,Bretagne
56005,ARZON,56640,ARZON,PORT NAVALO,47.5470278592,-2.88784873321,5,,Arzon,Arzon,56,Morbihan,53,Bretagne
56006,AUGAN,56800,AUGAN,,47.9122301916,-2.26794243142,6,,Augan,Augan,56,Morbihan,53,Bretagne
56007,AURAY,56400,AURAY,,47.6677057368,-2.99041112225,7,,Auray,Auray,56,Morbihan,53,Bretagne
56008,BADEN,56870,BADEN,,47.614993273,-2.90383771516,8,,Baden,Baden,56,Morbihan,53,Bretagne
56009,BANGOR,56360,BANGOR,,47.313014109,-3.1920046614,9,,Bangor,Bangor,56,Morbihan,53,Bretagne
56010,BAUD,56150,BAUD,,47.8753982698,-3.03131524701,10,,Baud,Baud,56,Morbihan,53,Bretagne
56011,BEGANNE,56350,BEGANNE,,47.6025516588,-2.2440968565,11,,Béganne,Béganne,56,Morbihan,53,Bretagne
56012,BEIGNON,56380,BEIGNON,,47.9655803913,-2.1804139894,12,,Beignon,Beignon,56,Morbihan,53,Bretagne
56013,BELZ,56550,BELZ,,47.6719818192,-3.16245947522,13,,Belz,Belz,56,Morbihan,53,Bretagne
56014,BERNE,56240,BERNE,,47.9850566608,-3.37414883543,14,,Berné,Berné,56,Morbihan,53,Bretagne
56015,BERRIC,56230,BERRIC,,47.6385230566,-2.52752927952,15,,Berric,Berric,56,Morbihan,53,Bretagne
56016,BIEUZY,56310,BIEUZY LES EAUX,,47.9996986994,-3.03463534674,16,,Bieuzy,Bieuzy,56,Morbihan,53,Bretagne
56017,BIGNAN,56500,BIGNAN,,47.8771804702,-2.75915073091,17,,Bignan,Bignan,56,Morbihan,53,Bretagne
56018,BILLIERS,56190,BILLIERS,,47.5234827047,-2.47972989397,18,,Billiers,Billiers,56,Morbihan,53,Bretagne
56019,BILLIO,56420,BILLIO,,47.8676423674,-2.64808099654,19,,Billio,Billio,56,Morbihan,53,Bretagne
56020,BOHAL,56140,BOHAL,,47.7842091553,-2.44701555999,20,,Bohal,Bohal,56,Morbihan,53,Bretagne
56021,BRANDERION,56700,BRANDERION,,47.79176054,-3.19316403763,21,,Brandérion,Brandérion,56,Morbihan,53,Bretagne
56022,BRANDIVY,56390,BRANDIVY,,47.7845009747,-2.92240814832,22,,Brandivy,Brandivy,56,Morbihan,53,Bretagne
56023,BRECH,56400,BRECH,,47.709389623,-3.02425370135,23,,Brech,Brech,56,Morbihan,53,Bretagne
56024,BREHAN,56580,BREHAN,,48.0699465844,-2.68732663005,24,,Bréhan,Bréhan,56,Morbihan,53,Bretagne
56025,BRIGNAC,56430,BRIGNAC,,48.1120379683,-2.39511005338,25,,Brignac,Brignac,56,Morbihan,53,Bretagne
56026,BUBRY,56310,BUBRY,,47.969363201,-3.17594645997,26,,Bubry,Bubry,56,Morbihan,53,Bretagne
56027,BULEON,56420,BULEON,,47.9204907078,-2.675100146,27,,Buléon,Buléon,56,Morbihan,53,Bretagne
56028,CADEN,56220,CADEN,,47.6401091806,-2.29927254459,28,,Caden,Caden,56,Morbihan,53,Bretagne
56029,CALAN,56240,CALAN,,47.8803732969,-3.31244277189,29,,Calan,Calan,56,Morbihan,53,Bretagne
56030,CAMOEL,56130,CAMOEL,,47.4791790965,-2.39860395694,30,,Camoël,Camoël,56,Morbihan,53,Bretagne
56031,CAMORS,56330,CAMORS,,47.8301134196,-3.0000365994,31,,Camors,Camors,56,Morbihan,53,Bretagne
56032,CAMPENEAC,56800,CAMPENEAC,,47.962439437,-2.27757699452,32,,Campénéac,Campénéac,56,Morbihan,53,Bretagne
56033,CARENTOIR,56910,CARENTOIR,,47.8204086772,-2.14646724944,33,,Carentoir,Carentoir,56,Morbihan,53,Bretagne
56034,CARNAC,56340,CARNAC,,47.608433163,-3.06973611145,34,,Carnac,Carnac,56,Morbihan,53,Bretagne
56035,CARO,56140,CARO,,47.8621010187,-2.32764949894,35,,Caro,Caro,56,Morbihan,53,Bretagne
56036,CAUDAN,56850,CAUDAN,,47.8132096296,-3.34070239075,36,,Caudan,Caudan,56,Morbihan,53,Bretagne
56038,LA CHAPELLE GACELINE,56200,LA CHAPELLE GACELINE,,47.790691436,-2.10022603174,38,La,Chapelle-Gaceline,La Chapelle-Gaceline,56,Morbihan,53,Bretagne
56039,LA CHAPELLE NEUVE,56500,LA CHAPELLE NEUVE,,47.855020898,-2.93430872541,39,La,Chapelle-Neuve,La Chapelle-Neuve,56,Morbihan,53,Bretagne
56040,CLEGUER,56620,CLEGUER,,47.8651441358,-3.36505283669,40,,Cléguer,Cléguer,56,Morbihan,53,Bretagne
56041,CLEGUEREC,56480,CLEGUEREC,,48.1278326906,-3.0634223364,41,,Cléguérec,Cléguérec,56,Morbihan,53,Bretagne
56042,COLPO,56390,COLPO,,47.8163021083,-2.80656999612,42,,Colpo,Colpo,56,Morbihan,53,Bretagne
56043,CONCORET,56430,CONCORET,,48.0658877259,-2.2085337227,43,,Concoret,Concoret,56,Morbihan,53,Bretagne
56044,COURNON,56200,COURNON,,47.7490041075,-2.09816065532,44,,Cournon,Cournon,56,Morbihan,53,Bretagne
56045,LE COURS,56230,LE COURS,,47.7495071048,-2.50848106104,45,Le,Cours,Le Cours,56,Morbihan,53,Bretagne
56046,CRACH,56950,CRACH,,47.6301210908,-3.00077657572,46,,Crach,Crach,56,Morbihan,53,Bretagne
56047,CREDIN,56580,CREDIN,,48.0385062482,-2.76505746928,47,,Crédin,Crédin,56,Morbihan,53,Bretagne
56048,LE CROISTY,56540,LE CROISTY,,48.0614013727,-3.37327067635,48,Le,Croisty,Le Croisty,56,Morbihan,53,Bretagne
56049,CROIXANVEC,56920,CROIXANVEC,,48.1350238881,-2.86804560575,49,,Croixanvec,Croixanvec,56,Morbihan,53,Bretagne
56050,LA CROIX HELLEAN,56120,LA CROIX HELLEAN,,47.9636789814,-2.51127913999,50,La,Croix-Helléan,La Croix-Helléan,56,Morbihan,53,Bretagne
56051,CRUGUEL,56420,CRUGUEL,,47.8775619552,-2.59076740576,51,,Cruguel,Cruguel,56,Morbihan,53,Bretagne
56052,DAMGAN,56750,DAMGAN,,47.5225849121,-2.58591349552,52,,Damgan,Damgan,56,Morbihan,53,Bretagne
56053,ELVEN,56250,ELVEN,,47.7326809062,-2.59279070215,53,,Elven,Elven,56,Morbihan,53,Bretagne
56054,ERDEVEN,56410,ERDEVEN,,47.6370207886,-3.15628129656,54,,Erdeven,Erdeven,56,Morbihan,53,Bretagne
56055,ETEL,56410,ETEL,,47.6567860287,-3.1985408357,55,,Étel,Étel,56,Morbihan,53,Bretagne
56056,EVRIGUET,56490,EVRIGUET,,48.0832901567,-2.404814166,56,,Évriguet,Évriguet,56,Morbihan,53,Bretagne
56057,LE FAOUET,56320,LE FAOUET,,48.048735927,-3.50863192945,57,Le,Faouët,Le Faouët,56,Morbihan,53,Bretagne
56058,FEREL,56130,FEREL,,47.4873744391,-2.34002198542,58,,Férel,Férel,56,Morbihan,53,Bretagne
56059,LES FORGES,56120,LES FORGES,,48.0299375029,-2.58479776688,59,Les,Forges,Les Forges,56,Morbihan,53,Bretagne
56060,LES FOUGERETS,56200,LES FOUGERETS,,47.7480373026,-2.19710765653,60,Les,Fougerêts,Les Fougerêts,56,Morbihan,53,Bretagne
56061,LA GACILLY,56200,LA GACILLY,,47.7728317091,-2.15651114837,61,La,Gacilly,La Gacilly,56,Morbihan,53,Bretagne
56062,GAVRES,56680,GAVRES,,47.6951381724,-3.33453004583,62,,Gâvres,Gâvres,56,Morbihan,53,Bretagne
56063,GESTEL,56530,GESTEL,,47.8081867338,-3.43789895944,63,,Gestel,Gestel,56,Morbihan,53,Bretagne
56064,GLENAC,56200,GLENAC,,47.737831269,-2.14280393723,64,,Glénac,Glénac,56,Morbihan,53,Bretagne
56065,GOURHEL,56800,GOURHEL,,47.9391748838,-2.35828875195,65,,Gourhel,Gourhel,56,Morbihan,53,Bretagne
56066,GOURIN,56110,GOURIN,,48.1365207443,-3.60390071405,66,,Gourin,Gourin,56,Morbihan,53,Bretagne
56067,GRAND CHAMP,56390,GRAND CHAMP,,47.760673333,-2.84786424904,67,,Grand-Champ,Grand-Champ,56,Morbihan,53,Bretagne
56068,LA GREE ST LAURENT,56120,LA GREE ST LAURENT,,48.0015062155,-2.5032399429,68,La,Grée-Saint-Laurent,La Grée-Saint-Laurent,56,Morbihan,53,Bretagne
56069,GROIX,56590,GROIX,,47.6372394221,-3.4644268016,69,,Groix,Groix,56,Morbihan,53,Bretagne
56070,GUEGON,56120,GUEGON,,47.9255876007,-2.58638209695,70,,Guégon,Guégon,56,Morbihan,53,Bretagne
56071,GUEHENNO,56420,GUEHENNO,,47.8922430084,-2.65141544204,71,,Guéhenno,Guéhenno,56,Morbihan,53,Bretagne
56072,GUELTAS,56920,GUELTAS,,48.0920646623,-2.80987346419,72,,Gueltas,Gueltas,56,Morbihan,53,Bretagne
56073,GUEMENE SUR SCORFF,56160,GUEMENE SUR SCORFF,,48.0663101307,-3.20585471209,73,,Guémené-sur-Scorff,Guémené-sur-Scorff,56,Morbihan,53,Bretagne
56074,GUENIN,56150,GUENIN,,47.9054538153,-2.96668545756,74,,Guénin,Guénin,56,Morbihan,53,Bretagne
56075,GUER,56380,GUER,,47.9073328689,-2.12921667557,75,,Guer,Guer,56,Morbihan,53,Bretagne
56075,GUER,56380,GUER,COETQUIDAN BELLEVUE,47.9073328689,-2.12921667557,75,,Guer,Guer,56,Morbihan,53,Bretagne
56076,GUERN,56310,GUERN,,48.034812094,-3.10210819961,76,,Guern,Guern,56,Morbihan,53,Bretagne
56077,LE GUERNO,56190,LE GUERNO,,47.5911293554,-2.39975146229,77,Le,Guerno,Le Guerno,56,Morbihan,53,Bretagne
56078,GUIDEL,56520,GUIDEL,,47.7940076851,-3.49161886127,78,,Guidel,Guidel,56,Morbihan,53,Bretagne
56079,GUILLAC,56800,GUILLAC,,47.9238724593,-2.47986722223,79,,Guillac,Guillac,56,Morbihan,53,Bretagne
56080,GUILLIERS,56490,GUILLIERS,,48.0517458182,-2.41446112985,80,,Guilliers,Guilliers,56,Morbihan,53,Bretagne
56081,GUISCRIFF,56560,GUISCRIFF,,48.0427009622,-3.62381122626,81,,Guiscriff,Guiscriff,56,Morbihan,53,Bretagne
56082,HELLEAN,56120,HELLEAN,,47.9684046119,-2.48122455823,82,,Helléan,Helléan,56,Morbihan,53,Bretagne
56083,HENNEBONT,56700,HENNEBONT,,47.807556604,-3.26971559866,83,,Hennebont,Hennebont,56,Morbihan,53,Bretagne
56084,LE HEZO,56450,LE HEZO,,47.5806224027,-2.69210124119,84,Le,Hézo,Le Hézo,56,Morbihan,53,Bretagne
56085,HOEDIC,56170,ILE DE HOEDIC,,47.3396501062,-2.87582897878,85,,Hœdic,Hœdic,56,Morbihan,53,Bretagne
56086,ILE D HOUAT,56170,ILE D HOUAT,,47.3894319946,-2.96687516001,86,,Île-d'Houat,Île-d'Houat,56,Morbihan,53,Bretagne
56087,ILE AUX MOINES,56780,ILE AUX MOINES,,47.5862523195,-2.84876733291,87,,Île-aux-Moines,Île-aux-Moines,56,Morbihan,53,Bretagne
56088,ILE D ARZ,56840,ILE D ARZ,,47.5921615847,-2.80045392153,88,,Île-d'Arz,Île-d'Arz,56,Morbihan,53,Bretagne
56089,INGUINIEL,56240,INGUINIEL,,47.9555939738,-3.26003939704,89,,Inguiniel,Inguiniel,56,Morbihan,53,Bretagne
56090,INZINZAC LOCHRIST,56650,INZINZAC LOCHRIST,,47.8558359743,-3.25178122558,90,,Inzinzac-Lochrist,Inzinzac-Lochrist,56,Morbihan,53,Bretagne
56091,JOSSELIN,56120,JOSSELIN,,47.9544411983,-2.54877600621,91,,Josselin,Josselin,56,Morbihan,53,Bretagne
56092,KERFOURN,56920,KERFOURN,,48.0434067231,-2.83634810154,92,,Kerfourn,Kerfourn,56,Morbihan,53,Bretagne
56093,KERGRIST,56300,KERGRIST,,48.1472818466,-2.92787554336,93,,Kergrist,Kergrist,56,Morbihan,53,Bretagne
56094,KERVIGNAC,56700,KERVIGNAC,,47.7686555667,-3.2479141613,94,,Kervignac,Kervignac,56,Morbihan,53,Bretagne
56096,LANDAUL,56690,LANDAUL,,47.7417597623,-3.09860506584,96,,Landaul,Landaul,56,Morbihan,53,Bretagne
56097,LANDEVANT,56690,LANDEVANT,,47.7714410604,-3.11373409438,97,,Landévant,Landévant,56,Morbihan,53,Bretagne
56098,LANESTER,56600,LANESTER,,47.7691924277,-3.32648317962,98,,Lanester,Lanester,56,Morbihan,53,Bretagne
56099,LANGOELAN,56160,LANGOELAN,,48.1207904206,-3.23318501887,99,,Langoëlan,Langoëlan,56,Morbihan,53,Bretagne
56100,LANGONNET,56630,LANGONNET,,48.1318811919,-3.48128235286,100,,Langonnet,Langonnet,56,Morbihan,53,Bretagne
56101,LANGUIDIC,56440,LANGUIDIC,,47.8365621011,-3.14756396353,101,,Languidic,Languidic,56,Morbihan,53,Bretagne
56102,LANOUEE,56120,LANOUEE,,47.9872211991,-2.57567607546,102,,Lanouée,Lanouée,56,Morbihan,53,Bretagne
56103,LANTILLAC,56120,LANTILLAC,,47.9513185093,-2.66089059866,103,,Lantillac,Lantillac,56,Morbihan,53,Bretagne
56104,LANVAUDAN,56240,LANVAUDAN,,47.8927979736,-3.24455555644,104,,Lanvaudan,Lanvaudan,56,Morbihan,53,Bretagne
56105,LANVENEGEN,56320,LANVENEGEN,,48.004442867,-3.53246846865,105,,Lanvénégen,Lanvénégen,56,Morbihan,53,Bretagne
56106,LARMOR BADEN,56870,LARMOR BADEN,,47.5934456952,-2.89858472133,106,,Larmor-Baden,Larmor-Baden,56,Morbihan,53,Bretagne
56107,LARMOR PLAGE,56260,LARMOR PLAGE,,47.7159229255,-3.39111373247,107,,Larmor-Plage,Larmor-Plage,56,Morbihan,53,Bretagne
56108,LARRE,56230,LARRE,,47.7158136416,-2.49456221297,108,,Larré,Larré,56,Morbihan,53,Bretagne
56109,LAUZACH,56190,LAUZACH,,47.6111902718,-2.55589461102,109,,Lauzach,Lauzach,56,Morbihan,53,Bretagne
56110,LIGNOL,56160,LIGNOL,,48.0272907982,-3.27168989222,110,,Lignol,Lignol,56,Morbihan,53,Bretagne
56111,LIMERZEL,56220,LIMERZEL,,47.6357871831,-2.36132601788,111,,Limerzel,Limerzel,56,Morbihan,53,Bretagne
56112,LIZIO,56460,LIZIO,,47.8643087698,-2.51995591164,112,,Lizio,Lizio,56,Morbihan,53,Bretagne
56113,LOCMALO,56160,LOCMALO,,48.0622114432,-3.18337050069,113,,Locmalo,Locmalo,56,Morbihan,53,Bretagne
56114,LOCMARIA,56360,LOCMARIA,,47.3019658435,-3.10628601643,114,,Locmaria,Locmaria,56,Morbihan,53,Bretagne
56115,LOCMARIA GRAND CHAMP,56390,LOCMARIA GRAND CHAMP,,47.7597989098,-2.79009588918,115,,Locmaria-Grand-Champ,Locmaria-Grand-Champ,56,Morbihan,53,Bretagne
56116,LOCMARIAQUER,56740,LOCMARIAQUER,,47.5780372256,-2.96305094247,116,,Locmariaquer,Locmariaquer,56,Morbihan,53,Bretagne
56117,LOCMINE,56500,LOCMINE,,47.8796108678,-2.83755408244,117,,Locminé,Locminé,56,Morbihan,53,Bretagne
56118,LOCMIQUELIC,56570,LOCMIQUELIC,,47.7303087589,-3.32842997885,118,,Locmiquélic,Locmiquélic,56,Morbihan,53,Bretagne
56119,LOCOAL MENDON,56550,LOCOAL MENDON,,47.70208162,-3.11005717259,119,,Locoal-Mendon,Locoal-Mendon,56,Morbihan,53,Bretagne
56120,LOCQUELTAS,56390,LOCQUELTAS,,47.7645245564,-2.75837514675,120,,Locqueltas,Locqueltas,56,Morbihan,53,Bretagne
56121,LORIENT,56100,LORIENT,,47.7500486947,-3.37823200917,121,,Lorient,Lorient,56,Morbihan,53,Bretagne
56122,LOYAT,56800,LOYAT,,47.9922231376,-2.38391082278,122,,Loyat,Loyat,56,Morbihan,53,Bretagne
56123,MALANSAC,56220,MALANSAC,,47.6861486744,-2.2997401984,123,,Malansac,Malansac,56,Morbihan,53,Bretagne
56124,MALESTROIT,56140,MALESTROIT,,47.8076863788,-2.38701072581,124,,Malestroit,Malestroit,56,Morbihan,53,Bretagne
56125,MALGUENAC,56300,MALGUENAC,,48.0690022602,-3.05620760639,125,,Malguénac,Malguénac,56,Morbihan,53,Bretagne
56126,MARZAN,56130,MARZAN,,47.5415287199,-2.35411657522,126,,Marzan,Marzan,56,Morbihan,53,Bretagne
56127,MAURON,56430,MAURON,,48.0820059121,-2.30432452661,127,,Mauron,Mauron,56,Morbihan,53,Bretagne
56128,MELRAND,56310,MELRAND,,47.9750761347,-3.09950962004,128,,Melrand,Melrand,56,Morbihan,53,Bretagne
56129,MENEAC,56490,MENEAC,,48.1310055293,-2.45348998168,129,,Ménéac,Ménéac,56,Morbihan,53,Bretagne
56130,MERLEVENEZ,56700,MERLEVENEZ,,47.7329410625,-3.24339145538,130,,Merlevenez,Merlevenez,56,Morbihan,53,Bretagne
56131,MESLAN,56320,MESLAN,,47.9879241099,-3.44802769432,131,,Meslan,Meslan,56,Morbihan,53,Bretagne
56132,MEUCON,56890,MEUCON,,47.7239745954,-2.77407412753,132,,Meucon,Meucon,56,Morbihan,53,Bretagne
56133,MISSIRIAC,56140,MISSIRIAC,,47.8239477341,-2.35554306974,133,,Missiriac,Missiriac,56,Morbihan,53,Bretagne
56134,MOHON,56490,MOHON,,48.0633640521,-2.49561629627,134,,Mohon,Mohon,56,Morbihan,53,Bretagne
56135,MOLAC,56230,MOLAC,,47.7362321249,-2.43951991682,135,,Molac,Molac,56,Morbihan,53,Bretagne
56136,MONTENEUF,56380,MONTENEUF,,47.8782281935,-2.1931404895,136,,Monteneuf,Monteneuf,56,Morbihan,53,Bretagne
56137,MONTERBLANC,56250,MONTERBLANC,,47.7315728601,-2.69647257441,137,,Monterblanc,Monterblanc,56,Morbihan,53,Bretagne
56138,MONTERREIN,56800,MONTERREIN,,47.8834163205,-2.35602682087,138,,Monterrein,Monterrein,56,Morbihan,53,Bretagne
56139,MONTERTELOT,56800,MONTERTELOT,,47.8855029773,-2.41232336354,139,,Montertelot,Montertelot,56,Morbihan,53,Bretagne
56140,MOREAC,56500,MOREAC,,47.9328057263,-2.80381737947,140,,Moréac,Moréac,56,Morbihan,53,Bretagne
56141,MOUSTOIR AC,56500,MOUSTOIR AC,,47.8426011958,-2.85850436809,141,,Moustoir-Ac,Moustoir-Ac,56,Morbihan,53,Bretagne
56143,MUZILLAC,56190,MUZILLAC,,47.5547990071,-2.47313130571,143,,Muzillac,Muzillac,56,Morbihan,53,Bretagne
56144,EVELLYS,56500,EVELLYS,MOUSTOIR REMUNGOL,47.9969702073,-2.84450199902,144,,Évellys,Évellys,56,Morbihan,53,Bretagne
56144,EVELLYS,56500,EVELLYS,NAIZIN,47.9969702073,-2.84450199902,144,,Évellys,Évellys,56,Morbihan,53,Bretagne
56144,EVELLYS,56500,EVELLYS,REMUNGOL,47.9969702073,-2.84450199902,144,,Évellys,Évellys,56,Morbihan,53,Bretagne
56145,NEANT SUR YVEL,56430,NEANT SUR YVEL,,48.0221749901,-2.33277424547,145,,Néant-sur-Yvel,Néant-sur-Yvel,56,Morbihan,53,Bretagne
56146,NEULLIAC,56300,NEULLIAC,,48.1221729003,-2.97176123226,146,,Neulliac,Neulliac,56,Morbihan,53,Bretagne
56147,NIVILLAC,56130,NIVILLAC,,47.5405533272,-2.24923467173,147,,Nivillac,Nivillac,56,Morbihan,53,Bretagne
56148,NOSTANG,56690,NOSTANG,,47.7545531097,-3.17469853998,148,,Nostang,Nostang,56,Morbihan,53,Bretagne
56149,NOYAL MUZILLAC,56190,NOYAL MUZILLAC,,47.5996326601,-2.45965724155,149,,Noyal-Muzillac,Noyal-Muzillac,56,Morbihan,53,Bretagne
56151,NOYAL PONTIVY,56920,NOYAL PONTIVY,,48.0614251201,-2.89115096448,151,,Noyal-Pontivy,Noyal-Pontivy,56,Morbihan,53,Bretagne
56152,LE PALAIS,56360,LE PALAIS,,47.3426909682,-3.17102952046,152,Le,Palais,Le Palais,56,Morbihan,53,Bretagne
56153,PEAULE,56130,PEAULE,,47.5826411591,-2.33551260645,153,,Péaule,Péaule,56,Morbihan,53,Bretagne
56154,PEILLAC,56220,PEILLAC,,47.7151673014,-2.21864708228,154,,Peillac,Peillac,56,Morbihan,53,Bretagne
56155,PENESTIN,56760,PENESTIN,,47.4726864791,-2.46473254944,155,,Pénestin,Pénestin,56,Morbihan,53,Bretagne
56156,PERSQUEN,56160,PERSQUEN,,48.0148048063,-3.21876275805,156,,Persquen,Persquen,56,Morbihan,53,Bretagne
56157,PLAUDREN,56420,PLAUDREN,,47.7791727358,-2.69563725639,157,,Plaudren,Plaudren,56,Morbihan,53,Bretagne
56158,PLESCOP,56890,PLESCOP,,47.6973490259,-2.83080852214,158,,Plescop,Plescop,56,Morbihan,53,Bretagne
56159,PLEUCADEUC,56140,PLEUCADEUC,,47.7694959397,-2.38667192104,159,,Pleucadeuc,Pleucadeuc,56,Morbihan,53,Bretagne
56160,PLEUGRIFFET,56120,PLEUGRIFFET,,47.9938347053,-2.69149691604,160,,Pleugriffet,Pleugriffet,56,Morbihan,53,Bretagne
56161,PLOEMEL,56400,PLOEMEL,,47.6594932649,-3.07159403186,161,,Ploemel,Ploemel,56,Morbihan,53,Bretagne
56162,PLOEMEUR,56270,PLOEMEUR,,47.7325584986,-3.44178815745,162,,Ploemeur,Ploemeur,56,Morbihan,53,Bretagne
56163,PLOERDUT,56160,PLOERDUT,,48.092400423,-3.28849567828,163,,Ploërdut,Ploërdut,56,Morbihan,53,Bretagne
56164,PLOEREN,56880,PLOEREN,,47.6618147163,-2.84313700258,164,,Ploeren,Ploeren,56,Morbihan,53,Bretagne
56165,PLOERMEL,56800,PLOERMEL,,47.9221801451,-2.38313563666,165,,Ploërmel,Ploërmel,56,Morbihan,53,Bretagne
56166,PLOUAY,56240,PLOUAY,,47.9270557389,-3.33743884173,166,,Plouay,Plouay,56,Morbihan,53,Bretagne
56167,PLOUGOUMELEN,56400,PLOUGOUMELEN,,47.6608388788,-2.89786302771,167,,Plougoumelen,Plougoumelen,56,Morbihan,53,Bretagne
56168,PLOUHARNEL,56340,PLOUHARNEL,,47.5996860072,-3.12263540651,168,,Plouharnel,Plouharnel,56,Morbihan,53,Bretagne
56169,PLOUHINEC,56680,PLOUHINEC,,47.6929109171,-3.23358959435,169,,Plouhinec,Plouhinec,56,Morbihan,53,Bretagne
56170,PLOURAY,56770,PLOURAY,,48.1343832686,-3.37547839068,170,,Plouray,Plouray,56,Morbihan,53,Bretagne
56171,PLUHERLIN,56220,PLUHERLIN,,47.7150813893,-2.36982500415,171,,Pluherlin,Pluherlin,56,Morbihan,53,Bretagne
56172,PLUMELEC,56420,PLUMELEC,,47.8257230255,-2.62344380142,172,,Plumelec,Plumelec,56,Morbihan,53,Bretagne
56173,PLUMELIAU,56930,PLUMELIAU,,47.9650479618,-2.98095397344,173,,Pluméliau,Pluméliau,56,Morbihan,53,Bretagne
56174,PLUMELIN,56500,PLUMELIN,,47.8825173769,-2.88768093525,174,,Plumelin,Plumelin,56,Morbihan,53,Bretagne
56175,PLUMERGAT,56400,PLUMERGAT,,47.7279059531,-2.92323704418,175,,Plumergat,Plumergat,56,Morbihan,53,Bretagne
56176,PLUNERET,56400,PLUNERET,,47.6782099916,-2.9456811423,176,,Pluneret,Pluneret,56,Morbihan,53,Bretagne
56177,PLUVIGNER,56330,PLUVIGNER,,47.7824013121,-3.01608916771,177,,Pluvigner,Pluvigner,56,Morbihan,53,Bretagne
56177,PLUVIGNER,56330,PLUVIGNER,BIEUZY LANVAUX,47.7824013121,-3.01608916771,177,,Pluvigner,Pluvigner,56,Morbihan,53,Bretagne
56178,PONTIVY,56300,PONTIVY,,48.0729953877,-2.97046592885,178,,Pontivy,Pontivy,56,Morbihan,53,Bretagne
56179,PONT SCORFF,56620,PONT SCORFF,,47.839096749,-3.41967999137,179,,Pont-Scorff,Pont-Scorff,56,Morbihan,53,Bretagne
56180,PORCARO,56380,PORCARO,,47.9198730267,-2.19384701914,180,,Porcaro,Porcaro,56,Morbihan,53,Bretagne
56181,PORT LOUIS,56290,PORT LOUIS,,47.7099205258,-3.34845750769,181,,Port-Louis,Port-Louis,56,Morbihan,53,Bretagne
56182,PRIZIAC,56320,PRIZIAC,,48.055864397,-3.43477253026,182,,Priziac,Priziac,56,Morbihan,53,Bretagne
56183,QUELNEUC,56910,QUELNEUC,,47.8353968876,-2.06653226615,183,,Quelneuc,Quelneuc,56,Morbihan,53,Bretagne
56184,QUESTEMBERT,56230,QUESTEMBERT,,47.6638017747,-2.43671076032,184,,Questembert,Questembert,56,Morbihan,53,Bretagne
56185,QUEVEN,56530,QUEVEN,,47.7841865998,-3.41957717053,185,,Quéven,Quéven,56,Morbihan,53,Bretagne
56186,QUIBERON,56170,QUIBERON,,47.4881657518,-3.12282400639,186,,Quiberon,Quiberon,56,Morbihan,53,Bretagne
56188,QUISTINIC,56310,QUISTINIC,,47.9093287127,-3.12816047592,188,,Quistinic,Quistinic,56,Morbihan,53,Bretagne
56189,RADENAC,56500,RADENAC,,47.9491864881,-2.7130262254,189,,Radenac,Radenac,56,Morbihan,53,Bretagne
56190,REGUINY,56500,REGUINY,,47.9858944462,-2.77189767095,190,,Réguiny,Réguiny,56,Morbihan,53,Bretagne
56191,REMINIAC,56140,REMINIAC,,47.8638253053,-2.25279250004,191,,Réminiac,Réminiac,56,Morbihan,53,Bretagne
56193,RIANTEC,56670,RIANTEC,,47.7184369348,-3.30221298077,193,,Riantec,Riantec,56,Morbihan,53,Bretagne
56194,RIEUX,56350,RIEUX,,47.6054822523,-2.12584604962,194,,Rieux,Rieux,56,Morbihan,53,Bretagne
56195,LA ROCHE BERNARD,56130,LA ROCHE BERNARD,,47.5193208116,-2.30307422213,195,La,Roche-Bernard,La Roche-Bernard,56,Morbihan,53,Bretagne
56196,ROCHEFORT EN TERRE,56220,ROCHEFORT EN TERRE,,47.6983579224,-2.34412952507,196,,Rochefort-en-Terre,Rochefort-en-Terre,56,Morbihan,53,Bretagne
56197,VAL D OUST,56460,VAL D OUST,LA CHAPELLE CARO,47.8685533688,-2.45624648415,197,,Val d'Oust,Val d'Oust,56,Morbihan,53,Bretagne
56197,VAL D OUST,56460,VAL D OUST,LE ROC ST ANDRE,47.8685533688,-2.45624648415,197,,Val d'Oust,Val d'Oust,56,Morbihan,53,Bretagne
56197,VAL D OUST,56800,VAL D OUST,QUILY,47.8685533688,-2.45624648415,197,,Val d'Oust,Val d'Oust,56,Morbihan,53,Bretagne
56198,ROHAN,56580,ROHAN,,48.0894729078,-2.74289349801,198,,Rohan,Rohan,56,Morbihan,53,Bretagne
56198,ROHAN,56580,ROHAN,ST GOUVRY,48.0894729078,-2.74289349801,198,,Rohan,Rohan,56,Morbihan,53,Bretagne
56198,ROHAN,56580,ROHAN,ST SAMSON,48.0894729078,-2.74289349801,198,,Rohan,Rohan,56,Morbihan,53,Bretagne
56199,ROUDOUALLEC,56110,ROUDOUALLEC,,48.1198265822,-3.69385005734,199,,Roudouallec,Roudouallec,56,Morbihan,53,Bretagne
56200,RUFFIAC,56140,RUFFIAC,,47.8135299611,-2.28477717582,200,,Ruffiac,Ruffiac,56,Morbihan,53,Bretagne
56201,LE SAINT,56110,LE SAINT,,48.0965409362,-3.5652314791,201,Le,Saint,Le Saint,56,Morbihan,53,Bretagne
56202,ST ABRAHAM,56140,ST ABRAHAM,,47.8475889201,-2.40640451407,202,,Saint-Abraham,Saint-Abraham,56,Morbihan,53,Bretagne
56203,ST AIGNAN,56480,ST AIGNAN,,48.1820807024,-3.04891714742,203,,Saint-Aignan,Saint-Aignan,56,Morbihan,53,Bretagne
56204,ST ALLOUESTRE,56500,ST ALLOUESTRE,,47.9081978294,-2.73628511193,204,,Saint-Allouestre,Saint-Allouestre,56,Morbihan,53,Bretagne
56205,ST ARMEL,56450,ST ARMEL,,47.5631092443,-2.71087365172,205,,Saint-Armel,Saint-Armel,56,Morbihan,53,Bretagne
56206,ST AVE,56890,ST AVE,,47.6991321739,-2.7415830935,206,,Saint-Avé,Saint-Avé,56,Morbihan,53,Bretagne
56207,ST BARTHELEMY,56150,ST BARTHELEMY,,47.9265481211,-3.04066993826,207,,Saint-Barthélemy,Saint-Barthélemy,56,Morbihan,53,Bretagne
56208,ST BRIEUC DE MAURON,56430,ST BRIEUC DE MAURON,,48.0900360435,-2.36145460306,208,,Saint-Brieuc-de-Mauron,Saint-Brieuc-de-Mauron,56,Morbihan,53,Bretagne
56209,STE BRIGITTE,56480,STE BRIGITTE,,48.1771391346,-3.11020493167,209,,Sainte-Brigitte,Sainte-Brigitte,56,Morbihan,53,Bretagne
56210,ST CARADEC TREGOMEL,56540,ST CARADEC TREGOMEL,,48.0321760477,-3.35137450933,210,,Saint-Caradec-Trégomel,Saint-Caradec-Trégomel,56,Morbihan,53,Bretagne
56211,ST CONGARD,56140,ST CONGARD,,47.7680806028,-2.32893095384,211,,Saint-Congard,Saint-Congard,56,Morbihan,53,Bretagne
56212,ST DOLAY,56130,ST DOLAY,,47.5474738486,-2.17191320646,212,,Saint-Dolay,Saint-Dolay,56,Morbihan,53,Bretagne
56213,ST GERAND,56920,ST GERAND,,48.1085338932,-2.88597650893,213,,Saint-Gérand,Saint-Gérand,56,Morbihan,53,Bretagne
56214,ST GILDAS DE RHUYS,56730,ST GILDAS DE RHUYS,,47.5128140114,-2.83281521373,214,,Saint-Gildas-de-Rhuys,Saint-Gildas-de-Rhuys,56,Morbihan,53,Bretagne
56215,ST GONNERY,56920,ST GONNERY,,48.1259650305,-2.82225083617,215,,Saint-Gonnery,Saint-Gonnery,56,Morbihan,53,Bretagne
56216,ST GORGON,56350,ST GORGON,,47.6418423031,-2.23845877778,216,,Saint-Gorgon,Saint-Gorgon,56,Morbihan,53,Bretagne
56218,ST GRAVE,56220,ST GRAVE,,47.7228923689,-2.28820700863,218,,Saint-Gravé,Saint-Gravé,56,Morbihan,53,Bretagne
56219,ST GUYOMARD,56460,ST GUYOMARD,,47.7793419253,-2.50210374964,219,,Saint-Guyomard,Saint-Guyomard,56,Morbihan,53,Bretagne
56220,STE HELENE,56700,STE HELENE,,47.7172153555,-3.18492247934,220,,Sainte-Hélène,Sainte-Hélène,56,Morbihan,53,Bretagne
56221,ST JACUT LES PINS,56220,ST JACUT LES PINS,,47.6776522748,-2.21229939133,221,,Saint-Jacut-les-Pins,Saint-Jacut-les-Pins,56,Morbihan,53,Bretagne
56222,ST JEAN BREVELAY,56660,ST JEAN BREVELAY,,47.831231207,-2.72758657957,222,,Saint-Jean-Brévelay,Saint-Jean-Brévelay,56,Morbihan,53,Bretagne
56223,ST JEAN LA POTERIE,56350,ST JEAN LA POTERIE,,47.6453867213,-2.12256103473,223,,Saint-Jean-la-Poterie,Saint-Jean-la-Poterie,56,Morbihan,53,Bretagne
56224,ST LAURENT SUR OUST,56140,ST LAURENT SUR OUST,,47.7918218379,-2.31430772802,224,,Saint-Laurent-sur-Oust,Saint-Laurent-sur-Oust,56,Morbihan,53,Bretagne
56225,ST LERY,56430,ST LERY,,48.091355996,-2.2632759244,225,,Saint-Léry,Saint-Léry,56,Morbihan,53,Bretagne
56226,ST MALO DE BEIGNON,56380,ST MALO DE BEIGNON,,47.9565800203,-2.14364557766,226,,Saint-Malo-de-Beignon,Saint-Malo-de-Beignon,56,Morbihan,53,Bretagne
56227,ST MALO DES TROIS FONTAINES,56490,ST MALO DES TROIS FONTAINES,,48.0174200107,-2.46820100361,227,,Saint-Malo-des-Trois-Fontaines,Saint-Malo-des-Trois-Fontaines,56,Morbihan,53,Bretagne
56228,ST MARCEL,56140,ST MARCEL,,47.8114442291,-2.41640908391,228,,Saint-Marcel,Saint-Marcel,56,Morbihan,53,Bretagne
56229,ST MARTIN SUR OUST,56200,ST MARTIN SUR OUST,,47.7625644099,-2.26391469379,229,,Saint-Martin-sur-Oust,Saint-Martin-sur-Oust,56,Morbihan,53,Bretagne
56230,ST NICOLAS DU TERTRE,56910,ST NICOLAS DU TERTRE,,47.7960541424,-2.21778311468,230,,Saint-Nicolas-du-Tertre,Saint-Nicolas-du-Tertre,56,Morbihan,53,Bretagne
56231,ST NOLFF,56250,ST NOLFF,,47.6984675237,-2.66583210925,231,,Saint-Nolff,Saint-Nolff,56,Morbihan,53,Bretagne
56232,ST PERREUX,56350,ST PERREUX,,47.6721206978,-2.12263604298,232,,Saint-Perreux,Saint-Perreux,56,Morbihan,53,Bretagne
56233,ST PHILIBERT,56470,ST PHILIBERT,,47.5878387262,-3.00101980446,233,,Saint-Philibert,Saint-Philibert,56,Morbihan,53,Bretagne
56234,ST PIERRE QUIBERON,56510,ST PIERRE QUIBERON,,47.5229822162,-3.13814252591,234,,Saint-Pierre-Quiberon,Saint-Pierre-Quiberon,56,Morbihan,53,Bretagne
56236,ST SERVANT,56120,ST SERVANT,,47.89876779,-2.5120013112,236,,Saint-Servant,Saint-Servant,56,Morbihan,53,Bretagne
56237,ST THURIAU,56300,ST THURIAU,,48.0165458217,-2.94330344486,237,,Saint-Thuriau,Saint-Thuriau,56,Morbihan,53,Bretagne
56238,ST TUGDUAL,56540,ST TUGDUAL,,48.0961762993,-3.36700769419,238,,Saint-Tugdual,Saint-Tugdual,56,Morbihan,53,Bretagne
56239,ST VINCENT SUR OUST,56350,ST VINCENT SUR OUST,,47.7023139983,-2.14800708531,239,,Saint-Vincent-sur-Oust,Saint-Vincent-sur-Oust,56,Morbihan,53,Bretagne
56240,SARZEAU,56370,SARZEAU,,47.5235379478,-2.75087136906,240,,Sarzeau,Sarzeau,56,Morbihan,53,Bretagne
56241,SAUZON,56360,SAUZON,,47.353750697,-3.23035128949,241,,Sauzon,Sauzon,56,Morbihan,53,Bretagne
56242,SEGLIEN,56160,SEGLIEN,,48.102365362,-3.15150581727,242,,Séglien,Séglien,56,Morbihan,53,Bretagne
56243,SENE,56860,SENE,,47.6228786892,-2.72442609453,243,,Séné,Séné,56,Morbihan,53,Bretagne
56244,SERENT,56460,SERENT,,47.8245310823,-2.49728404193,244,,Sérent,Sérent,56,Morbihan,53,Bretagne
56245,SILFIAC,56480,SILFIAC,,48.1449436665,-3.17252446086,245,,Silfiac,Silfiac,56,Morbihan,53,Bretagne
56246,LE SOURN,56300,LE SOURN,,48.0382214758,-2.99470821094,246,Le,Sourn,Le Sourn,56,Morbihan,53,Bretagne
56247,SULNIAC,56250,SULNIAC,,47.6598704315,-2.56262143424,247,,Sulniac,Sulniac,56,Morbihan,53,Bretagne
56248,SURZUR,56450,SURZUR,,47.5691616224,-2.62727408049,248,,Surzur,Surzur,56,Morbihan,53,Bretagne
56249,TAUPONT,56800,TAUPONT,,47.9622874981,-2.44276588766,249,,Taupont,Taupont,56,Morbihan,53,Bretagne
56250,THEHILLAC,56130,THEHILLAC,,47.552020586,-2.1182992394,250,,Théhillac,Théhillac,56,Morbihan,53,Bretagne
56251,THEIX NOYALO,56450,THEIX NOYALO,,47.6356885267,-2.64682620132,251,,Theix-Noyalo,Theix-Noyalo,56,Morbihan,53,Bretagne
56251,THEIX NOYALO,56450,THEIX NOYALO,NOYALO,47.6356885267,-2.64682620132,251,,Theix-Noyalo,Theix-Noyalo,56,Morbihan,53,Bretagne
56252,LE TOUR DU PARC,56370,LE TOUR DU PARC,,47.5293514837,-2.6538249578,252,Le,Tour-du-Parc,Le Tour-du-Parc,56,Morbihan,53,Bretagne
56253,TREAL,56140,TREAL,,47.832976381,-2.22970819032,253,,Tréal,Tréal,56,Morbihan,53,Bretagne
56254,TREDION,56250,TREDION,,47.7828735251,-2.58443570196,254,,Trédion,Trédion,56,Morbihan,53,Bretagne
56255,TREFFLEAN,56250,TREFFLEAN,,47.6781213314,-2.62203549701,255,,Treffléan,Treffléan,56,Morbihan,53,Bretagne
56256,TREHORENTEUC,56430,TREHORENTEUC,,48.003791815,-2.29527448191,256,,Tréhorenteuc,Tréhorenteuc,56,Morbihan,53,Bretagne
56257,LA TRINITE PORHOET,56490,LA TRINITE PORHOET,,48.0984875737,-2.52457196653,257,La,Trinité-Porhoët,La Trinité-Porhoët,56,Morbihan,53,Bretagne
56258,LA TRINITE SUR MER,56470,LA TRINITE SUR MER,,47.5909007393,-3.03700751097,258,La,Trinité-sur-Mer,La Trinité-sur-Mer,56,Morbihan,53,Bretagne
56259,LA TRINITE SURZUR,56190,LA TRINITE SURZUR,,47.6047054617,-2.59227223671,259,La,Trinité-Surzur,La Trinité-Surzur,56,Morbihan,53,Bretagne
56260,VANNES,56000,VANNES,,47.6597493766,-2.75714329498,260,,Vannes,Vannes,56,Morbihan,53,Bretagne
56261,LA VRAIE CROIX,56250,LA VRAIE CROIX,,47.6897714715,-2.51941411954,261,La,Vraie-Croix,La Vraie-Croix,56,Morbihan,53,Bretagne
56262,BONO,56400,LE BONO,,47.6369017681,-2.93950115097,262,,Bono,Bono,56,Morbihan,53,Bretagne
56263,STE ANNE D AURAY,56400,STE ANNE D AURAY,,47.7009234212,-2.95341644757,263,,Sainte-Anne-d'Auray,Sainte-Anne-d'Auray,56,Morbihan,53,Bretagne
56264,KERNASCLEDEN,56540,KERNASCLEDEN,,48.0077330975,-3.32930106576,264,,Kernascléden,Kernascléden,56,Morbihan,53,Bretagne
57001,ABONCOURT,57920,ABONCOURT,,49.2565158648,6.35416455836,1,,Aboncourt,Aboncourt,57,Moselle,44,Grand Est
57002,ABONCOURT SUR SEILLE,57590,ABONCOURT SUR SEILLE,,48.8186679657,6.35583662298,2,,Aboncourt-sur-Seille,Aboncourt-sur-Seille,57,Moselle,44,Grand Est
57003,ABRESCHVILLER,57560,ABRESCHVILLER,,48.60157203,7.15576686053,3,,Abreschviller,Abreschviller,57,Moselle,44,Grand Est
57004,ACHAIN,57340,ACHAIN,,48.9158245704,6.59030850848,4,,Achain,Achain,57,Moselle,44,Grand Est
57006,ACHEN,57412,ACHEN,,49.046252952,7.18366864797,6,,Achen,Achen,57,Moselle,44,Grand Est
57007,ADAINCOURT,57580,ADAINCOURT,,49.0059162559,6.44419024928,7,,Adaincourt,Adaincourt,57,Moselle,44,Grand Est
57008,ADELANGE,57380,ADELANGE,,49.0079073828,6.60632652354,8,,Adelange,Adelange,57,Moselle,44,Grand Est
57009,AJONCOURT,57590,AJONCOURT,,48.8450639322,6.3036696933,9,,Ajoncourt,Ajoncourt,57,Moselle,44,Grand Est
57010,ALAINCOURT LA COTE,57590,ALAINCOURT LA COTE,,48.8969970894,6.34037404652,10,,Alaincourt-la-Côte,Alaincourt-la-Côte,57,Moselle,44,Grand Est
57011,ALBESTROFF,57670,ALBESTROFF,,48.929810647,6.8693705224,11,,Albestroff,Albestroff,57,Moselle,44,Grand Est
57012,ALGRANGE,57440,ALGRANGE,,49.3644069158,6.04829438392,12,,Algrange,Algrange,57,Moselle,44,Grand Est
57013,ALSTING,57515,ALSTING,,49.180492978,7.00110743227,13,,Alsting,Alsting,57,Moselle,44,Grand Est
57014,ALTRIPPE,57660,ALTRIPPE,,49.0273733411,6.81555740492,14,,Altrippe,Altrippe,57,Moselle,44,Grand Est
57015,ALTVILLER,57730,ALTVILLER,,49.0689443074,6.73140491187,15,,Altviller,Altviller,57,Moselle,44,Grand Est
57016,ALZING,57320,ALZING,,49.2764757176,6.55325896948,16,,Alzing,Alzing,57,Moselle,44,Grand Est
57017,AMANVILLERS,57865,AMANVILLERS,,49.1595048977,6.04632244195,17,,Amanvillers,Amanvillers,57,Moselle,44,Grand Est
57018,AMELECOURT,57170,AMELECOURT,,48.8406895704,6.48798103824,18,,Amelécourt,Amelécourt,57,Moselle,44,Grand Est
57019,AMNEVILLE,57360,AMNEVILLE LES THERMES,,49.2396972255,6.09947450041,19,,Amnéville,Amnéville,57,Moselle,44,Grand Est
57019,AMNEVILLE,57360,AMNEVILLE LES THERMES,MALANCOURT LA MONTAGNE,49.2396972255,6.09947450041,19,,Amnéville,Amnéville,57,Moselle,44,Grand Est
57020,ANCERVILLE,57580,ANCERVILLE,,49.032956145,6.39803240304,20,,Ancerville,Ancerville,57,Moselle,44,Grand Est
57021,ANCY DORNOT,57130,ANCY DORNOT,,49.0653836521,6.04477980667,21,,Ancy-Dornot,Ancy-Dornot,57,Moselle,44,Grand Est
57021,ANCY DORNOT,57130,ANCY DORNOT,DORNOT,49.0653836521,6.04477980667,21,,Ancy-Dornot,Ancy-Dornot,57,Moselle,44,Grand Est
57022,ANGEVILLERS,57440,ANGEVILLERS,,49.3875925274,6.04259987593,22,,Angevillers,Angevillers,57,Moselle,44,Grand Est
57024,ANTILLY,57640,ANTILLY,,49.19731326,6.24824504682,24,,Antilly,Antilly,57,Moselle,44,Grand Est
57025,ANZELING,57320,ANZELING,,49.2683112384,6.46229391409,25,,Anzeling,Anzeling,57,Moselle,44,Grand Est
57026,APACH,57480,APACH,,49.4602733408,6.38601004029,26,,Apach,Apach,57,Moselle,44,Grand Est
57027,ARRAINCOURT,57380,ARRAINCOURT,,48.9760540573,6.53048540164,27,,Arraincourt,Arraincourt,57,Moselle,44,Grand Est
57028,ARGANCY,57640,ARGANCY,,49.1989568995,6.21054384876,28,,Argancy,Argancy,57,Moselle,44,Grand Est
57029,ARRIANCE,57580,ARRIANCE,,49.0186311758,6.50377825217,29,,Arriance,Arriance,57,Moselle,44,Grand Est
57030,ARRY,57680,ARRY,,48.9943639154,6.05696134676,30,,Arry,Arry,57,Moselle,44,Grand Est
57031,ARS LAQUENEXY,57530,ARS LAQUENEXY,,49.0873346287,6.26878430088,31,,Ars-Laquenexy,Ars-Laquenexy,57,Moselle,44,Grand Est
57032,ARS SUR MOSELLE,57130,ARS SUR MOSELLE,,49.0852493143,6.0478493798,32,,Ars-sur-Moselle,Ars-sur-Moselle,57,Moselle,44,Grand Est
57033,ARZVILLER,57405,ARZVILLER,,48.7216847027,7.16338621626,33,,Arzviller,Arzviller,57,Moselle,44,Grand Est
57034,ASPACH,57790,ASPACH,,48.6526727598,6.9670213438,34,,Aspach,Aspach,57,Moselle,44,Grand Est
57035,ASSENONCOURT,57260,ASSENONCOURT,,48.7664604694,6.79955530067,35,,Assenoncourt,Assenoncourt,57,Moselle,44,Grand Est
57036,ATTILLONCOURT,57170,ATTILLONCOURT,,48.7904278549,6.38552204812,36,,Attilloncourt,Attilloncourt,57,Moselle,44,Grand Est
57037,AUBE,57580,AUBE,,49.0251696747,6.3360717944,37,,Aube,Aube,57,Moselle,44,Grand Est
57038,AUDUN LE TICHE,57390,AUDUN LE TICHE,,49.4583101565,5.9573466797,38,,Audun-le-Tiche,Audun-le-Tiche,57,Moselle,44,Grand Est
57039,AUGNY,57685,AUGNY,,49.0552002607,6.11577629638,39,,Augny,Augny,57,Moselle,44,Grand Est
57040,AULNOIS SUR SEILLE,57590,AULNOIS SUR SEILLE,,48.8619380667,6.32824523031,40,,Aulnois-sur-Seille,Aulnois-sur-Seille,57,Moselle,44,Grand Est
57041,AUMETZ,57710,AUMETZ,,49.4185038018,5.94689631512,41,,Aumetz,Aumetz,57,Moselle,44,Grand Est
57042,AVRICOURT,57810,AVRICOURT,,48.6556287459,6.8054734595,42,,Avricourt,Avricourt,57,Moselle,44,Grand Est
57043,AY SUR MOSELLE,57300,AY SUR MOSELLE,,49.2475802944,6.19571589281,43,,Ay-sur-Moselle,Ay-sur-Moselle,57,Moselle,44,Grand Est
57044,AZOUDANGE,57810,AZOUDANGE,,48.7305691867,6.82193103047,44,,Azoudange,Azoudange,57,Moselle,44,Grand Est
57045,BACOURT,57590,BACOURT,,48.92859453,6.40632103762,45,,Bacourt,Bacourt,57,Moselle,44,Grand Est
57046,BAERENTHAL,57230,BAERENTHAL,,48.967824812,7.52245388571,46,,Baerenthal,Baerenthal,57,Moselle,44,Grand Est
57047,BAMBIDERSTROFF,57690,BAMBIDERSTROFF,,49.1010213921,6.59685944774,47,,Bambiderstroff,Bambiderstroff,57,Moselle,44,Grand Est
57048,BANNAY,57220,BANNAY,,49.1219359607,6.46458815093,48,,Bannay,Bannay,57,Moselle,44,Grand Est
57049,LE BAN ST MARTIN,57050,LE BAN ST MARTIN,,49.1241611255,6.14173598917,49,Le,Ban-Saint-Martin,Le Ban-Saint-Martin,57,Moselle,44,Grand Est
57050,BARCHAIN,57830,BARCHAIN,,48.708066115,6.95761919263,50,,Barchain,Barchain,57,Moselle,44,Grand Est
57051,BARONVILLE,57340,BARONVILLE,,48.9385057671,6.61439678254,51,,Baronville,Baronville,57,Moselle,44,Grand Est
57052,BARST,57450,BARST,,49.073506377,6.82303969379,52,,Barst,Barst,57,Moselle,44,Grand Est
57053,BASSING,57260,BASSING,,48.8728450421,6.80064100992,53,,Bassing,Bassing,57,Moselle,44,Grand Est
57054,BAUDRECOURT,57580,BAUDRECOURT,,48.9603224035,6.45869419532,54,,Baudrecourt,Baudrecourt,57,Moselle,44,Grand Est
57055,BAZONCOURT,57530,BAZONCOURT,,49.057272812,6.38508559255,55,,Bazoncourt,Bazoncourt,57,Moselle,44,Grand Est
57056,BEBING,57830,BEBING,,48.7214398635,6.99109821572,56,,Bébing,Bébing,57,Moselle,44,Grand Est
57057,BECHY,57580,BECHY,,48.9829120537,6.38598191377,57,,Béchy,Béchy,57,Moselle,44,Grand Est
57058,BEHREN LES FORBACH,57460,BEHREN LES FORBACH,,49.1675786838,6.94249059471,58,,Behren-lès-Forbach,Behren-lès-Forbach,57,Moselle,44,Grand Est
57059,BELLANGE,57340,BELLANGE,,48.9009974144,6.57445426566,59,,Bellange,Bellange,57,Moselle,44,Grand Est
57060,BENESTROFF,57670,BENESTROFF,,48.9083612745,6.75653495521,60,,Bénestroff,Bénestroff,57,Moselle,44,Grand Est
57061,BENING LES ST AVOLD,57800,BENING LES ST AVOLD,,49.1339677054,6.83742986369,61,,Béning-lès-Saint-Avold,Béning-lès-Saint-Avold,57,Moselle,44,Grand Est
57062,BERG SUR MOSELLE,57570,BERG SUR MOSELLE,,49.4347777081,6.31144547502,62,,Berg-sur-Moselle,Berg-sur-Moselle,57,Moselle,44,Grand Est
57063,BERIG VINTRANGE,57660,BERIG VINTRANGE,,48.9725863451,6.69450028658,63,,Bérig-Vintrange,Bérig-Vintrange,57,Moselle,44,Grand Est
57064,BERLING,57370,BERLING,,48.7999822826,7.24350642684,64,,Berling,Berling,57,Moselle,44,Grand Est
57065,BERMERING,57340,BERMERING,,48.9333505084,6.71263318734,65,,Bermering,Bermering,57,Moselle,44,Grand Est
57066,BERTHELMING,57930,BERTHELMING,,48.8213061613,6.98183479413,66,,Berthelming,Berthelming,57,Moselle,44,Grand Est
57067,BERTRANGE,57310,BERTRANGE,,49.3148066005,6.19339809541,67,,Bertrange,Bertrange,57,Moselle,44,Grand Est
57069,BERVILLER EN MOSELLE,57550,BERVILLER EN MOSELLE,,49.2687554002,6.65166884114,69,,Berviller-en-Moselle,Berviller-en-Moselle,57,Moselle,44,Grand Est
57070,BETTANGE,57220,BETTANGE,,49.2386757924,6.48925028318,70,,Bettange,Bettange,57,Moselle,44,Grand Est
57071,BETTBORN,57930,BETTBORN,,48.8075081926,7.02877201918,71,,Bettborn,Bettborn,57,Moselle,44,Grand Est
57072,BETTELAINVILLE,57640,BETTELAINVILLE,,49.2450266882,6.31370817439,72,,Bettelainville,Bettelainville,57,Moselle,44,Grand Est
57073,BETTING,57800,BETTING,,49.1299695233,6.81296157618,73,,Betting,Betting,57,Moselle,44,Grand Est
57074,BETTVILLER,57410,BETTVILLER,,49.0755681327,7.29043662433,74,,Bettviller,Bettviller,57,Moselle,44,Grand Est
57075,BEUX,57580,BEUX,,49.0006365532,6.31228664139,75,,Beux,Beux,57,Moselle,44,Grand Est
57076,BEYREN LES SIERCK,57570,BEYREN LES SIERCK,,49.4686112541,6.2955500378,76,,Beyren-lès-Sierck,Beyren-lès-Sierck,57,Moselle,44,Grand Est
57077,BEZANGE LA PETITE,57630,BEZANGE LA PETITE,,48.7321125296,6.61187800592,77,,Bezange-la-Petite,Bezange-la-Petite,57,Moselle,44,Grand Est
57079,BIBICHE,57320,BIBICHE,,49.3315599598,6.47689109496,79,,Bibiche,Bibiche,57,Moselle,44,Grand Est
57080,BICKENHOLTZ,57635,BICKENHOLTZ,,48.8068173571,7.17081758315,80,,Bickenholtz,Bickenholtz,57,Moselle,44,Grand Est
57081,BIDESTROFF,57260,BIDESTROFF,,48.8437922106,6.78929446529,81,,Bidestroff,Bidestroff,57,Moselle,44,Grand Est
57082,BIDING,57660,BIDING,,49.0637579139,6.79094608781,82,,Biding,Biding,57,Moselle,44,Grand Est
57083,BINING,57410,BINING,,49.0255954258,7.24756790053,83,,Bining,Bining,57,Moselle,44,Grand Est
57084,BIONCOURT,57170,BIONCOURT,,48.8014463238,6.36953922667,84,,Bioncourt,Bioncourt,57,Moselle,44,Grand Est
57085,BIONVILLE SUR NIED,57220,BIONVILLE SUR NIED,,49.1118093676,6.47964260905,85,,Bionville-sur-Nied,Bionville-sur-Nied,57,Moselle,44,Grand Est
57086,BELLES FORETS,57930,BELLES FORETS,,48.8081241943,6.90014574673,86,,Belles-Forêts,Belles-Forêts,57,Moselle,44,Grand Est
57086,BELLES FORETS,57930,BELLES FORETS,ANGVILLER LES BISPING,48.8081241943,6.90014574673,86,,Belles-Forêts,Belles-Forêts,57,Moselle,44,Grand Est
57086,BELLES FORETS,57930,BELLES FORETS,BISPING,48.8081241943,6.90014574673,86,,Belles-Forêts,Belles-Forêts,57,Moselle,44,Grand Est
57087,BISTEN EN LORRAINE,57220,BISTEN EN LORRAINE,,49.1643646232,6.59958337457,87,,Bisten-en-Lorraine,Bisten-en-Lorraine,57,Moselle,44,Grand Est
57088,BISTROFF,57660,BISTROFF,,49.0038033452,6.70381157124,88,,Bistroff,Bistroff,57,Moselle,44,Grand Est
57089,BITCHE,57230,BITCHE,,49.0473471719,7.459601871,89,,Bitche,Bitche,57,Moselle,44,Grand Est
57090,BLANCHE EGLISE,57260,BLANCHE EGLISE,,48.7896924974,6.67659797693,90,,Blanche-Église,Blanche-Église,57,Moselle,44,Grand Est
57091,BLIESBRUCK,57200,BLIESBRUCK,,49.1109578632,7.17855370951,91,,Bliesbruck,Bliesbruck,57,Moselle,44,Grand Est
57092,BLIES EBERSING,57200,BLIES EBERSING,,49.1137018858,7.14221813754,92,,Blies-Ébersing,Blies-Ébersing,57,Moselle,44,Grand Est
57093,BLIES GUERSVILLER,57200,BLIES GUERSVILLER,,49.1441438381,7.09494961773,93,,Blies-Guersviller,Blies-Guersviller,57,Moselle,44,Grand Est
57095,BOUCHEPORN,57220,BOUCHEPORN,,49.1495824486,6.61912798021,95,,Boucheporn,Boucheporn,57,Moselle,44,Grand Est
57096,BOULANGE,57655,BOULANGE,,49.3827399513,5.95209711737,96,,Boulange,Boulange,57,Moselle,44,Grand Est
57097,BOULAY MOSELLE,57220,BOULAY,,49.1779901346,6.49842566408,97,,Boulay-Moselle,Boulay-Moselle,57,Moselle,44,Grand Est
57097,BOULAY MOSELLE,57220,BOULAY,HALLING LES BOULAY,49.1779901346,6.49842566408,97,,Boulay-Moselle,Boulay-Moselle,57,Moselle,44,Grand Est
57098,BOURGALTROFF,57260,BOURGALTROFF,,48.8741830367,6.76367631386,98,,Bourgaltroff,Bourgaltroff,57,Moselle,44,Grand Est
57099,BOURDONNAY,57810,BOURDONNAY,,48.7246400805,6.73626264674,99,,Bourdonnay,Bourdonnay,57,Moselle,44,Grand Est
57100,BOURSCHEID,57370,BOURSCHEID,,48.7696199164,7.19805131397,100,,Bourscheid,Bourscheid,57,Moselle,44,Grand Est
57101,BOUSBACH,57460,BOUSBACH,,49.1449395157,6.94789546183,101,,Bousbach,Bousbach,57,Moselle,44,Grand Est
57102,BOUSSE,57310,BOUSSE,,49.2758183032,6.20436699384,102,,Bousse,Bousse,57,Moselle,44,Grand Est
57103,BOUSSEVILLER,57230,BOUSSEVILLER,,49.1217722376,7.46170264834,103,,Bousseviller,Bousseviller,57,Moselle,44,Grand Est
57104,BOUST,57570,BOUST,,49.4357529788,6.19331179482,104,,Boust,Boust,57,Moselle,44,Grand Est
57105,BOUSTROFF,57380,BOUSTROFF,,49.0017966177,6.63111070806,105,,Boustroff,Boustroff,57,Moselle,44,Grand Est
57106,BOUZONVILLE,57320,BOUZONVILLE,,49.2988626834,6.54161019887,106,,Bouzonville,Bouzonville,57,Moselle,44,Grand Est
57107,BREHAIN,57340,BREHAIN,,48.9080812018,6.54463207168,107,,Bréhain,Bréhain,57,Moselle,44,Grand Est
57108,BREIDENBACH,57720,BREIDENBACH,,49.1322602403,7.41859558098,108,,Breidenbach,Breidenbach,57,Moselle,44,Grand Est
57109,BREISTROFF LA GRANDE,57570,BREISTROFF LA GRANDE,,49.4517934888,6.22461967843,109,,Breistroff-la-Grande,Breistroff-la-Grande,57,Moselle,44,Grand Est
57110,BRETTNACH,57320,BRETTNACH,,49.2580265241,6.5599273832,110,,Brettnach,Brettnach,57,Moselle,44,Grand Est
57111,BRONVAUX,57535,BRONVAUX,,49.1927767637,6.08381786639,111,,Bronvaux,Bronvaux,57,Moselle,44,Grand Est
57112,BROUCK,57220,BROUCK,,49.1300313642,6.50888245176,112,,Brouck,Brouck,57,Moselle,44,Grand Est
57113,BROUDERDORFF,57565,BROUDERDORFF,,48.6938303554,7.10063688026,113,,Brouderdorff,Brouderdorff,57,Moselle,44,Grand Est
57114,BROUVILLER,57635,BROUVILLER,,48.755809011,7.16580711521,114,,Brouviller,Brouviller,57,Moselle,44,Grand Est
57115,BRULANGE,57340,BRULANGE,,48.9603870574,6.5493906183,115,,Brulange,Brulange,57,Moselle,44,Grand Est
57116,BUCHY,57420,BUCHY,,48.9799646993,6.28457340946,116,,Buchy,Buchy,57,Moselle,44,Grand Est
57117,BUDING,57920,BUDING,,49.3283000639,6.323728807,117,,Buding,Buding,57,Moselle,44,Grand Est
57118,BUDLING,57970,BUDLING,,49.3468728627,6.34426099542,118,,Budling,Budling,57,Moselle,44,Grand Est
57119,BUHL LORRAINE,57400,BUHL LORRAINE,,48.7190027122,7.07430215859,119,,Buhl-Lorraine,Buhl-Lorraine,57,Moselle,44,Grand Est
57120,BURLIONCOURT,57170,BURLIONCOURT,,48.8625578408,6.57951956171,120,,Burlioncourt,Burlioncourt,57,Moselle,44,Grand Est
57121,BURTONCOURT,57220,BURTONCOURT,,49.2239102035,6.40735123793,121,,Burtoncourt,Burtoncourt,57,Moselle,44,Grand Est
57122,CAPPEL,57450,CAPPEL,,49.0759225608,6.84355366565,122,,Cappel,Cappel,57,Moselle,44,Grand Est
57123,CARLING,57490,CARLING,,49.1666391756,6.71515090719,123,,Carling,Carling,57,Moselle,44,Grand Est
57124,CATTENOM,57570,CATTENOM,,49.4154747401,6.2351808823,124,,Cattenom,Cattenom,57,Moselle,44,Grand Est
57125,CHAILLY LES ENNERY,57365,CHAILLY LES ENNERY,,49.2127279951,6.25578322218,125,,Chailly-lès-Ennery,Chailly-lès-Ennery,57,Moselle,44,Grand Est
57126,CHAMBREY,57170,CHAMBREY,,48.7961903159,6.45461384881,126,,Chambrey,Chambrey,57,Moselle,44,Grand Est
57127,CHANVILLE,57580,CHANVILLE,,49.0421866813,6.43895041652,127,,Chanville,Chanville,57,Moselle,44,Grand Est
57128,CHARLEVILLE SOUS BOIS,57220,CHARLEVILLE SOUS BOIS,,49.1959515116,6.4063041025,128,,Charleville-sous-Bois,Charleville-sous-Bois,57,Moselle,44,Grand Est
57129,CHARLY ORADOUR,57640,CHARLY ORADOUR,,49.1760119243,6.24626769306,129,,Charly-Oradour,Charly-Oradour,57,Moselle,44,Grand Est
57130,CHATEAU BREHAIN,57340,CHATEAU BREHAIN,,48.898906621,6.52153311828,130,,Château-Bréhain,Château-Bréhain,57,Moselle,44,Grand Est
57131,CHATEAU ROUGE,57320,CHATEAU ROUGE,,49.283778795,6.59645792981,131,,Château-Rouge,Château-Rouge,57,Moselle,44,Grand Est
57132,CHATEAU SALINS,57170,CHATEAU SALINS,,48.8192996978,6.49319554209,132,,Château-Salins,Château-Salins,57,Moselle,44,Grand Est
57132,CHATEAU SALINS,57170,CHATEAU SALINS,COUTURES,48.8192996978,6.49319554209,132,,Château-Salins,Château-Salins,57,Moselle,44,Grand Est
57133,CHATEAU VOUE,57170,CHATEAU VOUE,,48.8474504744,6.6276342753,133,,Château-Voué,Château-Voué,57,Moselle,44,Grand Est
57133,CHATEAU VOUE,57170,CHATEAU VOUE,DEDELING,48.8474504744,6.6276342753,133,,Château-Voué,Château-Voué,57,Moselle,44,Grand Est
57134,CHATEL ST GERMAIN,57160,CHATEL ST GERMAIN,,49.1317567029,6.0658550675,134,,Châtel-Saint-Germain,Châtel-Saint-Germain,57,Moselle,44,Grand Est
57136,CHEMERY LES DEUX,57320,CHEMERY LES DEUX,,49.3000638576,6.44368755831,136,,Chémery-les-Deux,Chémery-les-Deux,57,Moselle,44,Grand Est
57137,CHEMINOT,57420,CHEMINOT,,48.9560682029,6.13779293585,137,,Cheminot,Cheminot,57,Moselle,44,Grand Est
57138,CHENOIS,57580,CHENOIS,,48.9576168886,6.48910214477,138,,Chenois,Chenois,57,Moselle,44,Grand Est
57139,CHERISEY,57420,CHERISEY,,49.0105765777,6.24566851068,139,,Chérisey,Chérisey,57,Moselle,44,Grand Est
57140,CHESNY,57245,CHESNY,,49.0524150036,6.24363224216,140,,Chesny,Chesny,57,Moselle,44,Grand Est
57141,CHICOURT,57590,CHICOURT,,48.9194951245,6.50508064075,141,,Chicourt,Chicourt,57,Moselle,44,Grand Est
57142,CHIEULLES,57070,CHIEULLES,,49.1575710787,6.22035628304,142,,Chieulles,Chieulles,57,Moselle,44,Grand Est
57143,CLOUANGE,57185,CLOUANGE,,49.2636746222,6.08746043718,143,,Clouange,Clouange,57,Moselle,44,Grand Est
57144,COCHEREN,57800,COCHEREN,,49.1434616014,6.8532637393,144,,Cocheren,Cocheren,57,Moselle,44,Grand Est
57144,COCHEREN,57800,COCHEREN,BELLE ROCHE,49.1434616014,6.8532637393,144,,Cocheren,Cocheren,57,Moselle,44,Grand Est
57145,COINCY,57530,COINCY,,49.1065216017,6.2692481247,145,,Coincy,Coincy,57,Moselle,44,Grand Est
57146,COIN LES CUVRY,57420,COIN LES CUVRY,,49.0248510499,6.13661695349,146,,Coin-lès-Cuvry,Coin-lès-Cuvry,57,Moselle,44,Grand Est
57147,COIN SUR SEILLE,57420,COIN SUR SEILLE,,49.0102275931,6.15977287871,147,,Coin-sur-Seille,Coin-sur-Seille,57,Moselle,44,Grand Est
57148,COLLIGNY,57530,COLLIGNY,,49.0966398426,6.33612392553,148,,Colligny-Maizery,Colligny-Maizery,57,Moselle,44,Grand Est
57149,COLMEN,57320,COLMEN,,49.3503030508,6.53398702334,149,,Colmen,Colmen,57,Moselle,44,Grand Est
57150,CONDE NORTHEN,57220,CONDE NORTHEN,,49.1557780335,6.42749152793,150,,Condé-Northen,Condé-Northen,57,Moselle,44,Grand Est
57150,CONDE NORTHEN,57220,CONDE NORTHEN,LOUTREMANGE,49.1557780335,6.42749152793,150,,Condé-Northen,Condé-Northen,57,Moselle,44,Grand Est
57151,CONTHIL,57340,CONTHIL,,48.8916747657,6.66400638326,151,,Conthil,Conthil,57,Moselle,44,Grand Est
57152,CONTZ LES BAINS,57480,CONTZ LES BAINS,,49.4565196788,6.35072229703,152,,Contz-les-Bains,Contz-les-Bains,57,Moselle,44,Grand Est
57153,CORNY SUR MOSELLE,57680,CORNY SUR MOSELLE,,49.0294224346,6.07254741579,153,,Corny-sur-Moselle,Corny-sur-Moselle,57,Moselle,44,Grand Est
57154,COUME,57220,COUME,,49.1968729859,6.58311875071,154,,Coume,Coume,57,Moselle,44,Grand Est
57155,COURCELLES CHAUSSY,57530,COURCELLES CHAUSSY,,49.1184504729,6.39972759611,155,,Courcelles-Chaussy,Courcelles-Chaussy,57,Moselle,44,Grand Est
57155,COURCELLES CHAUSSY,57530,COURCELLES CHAUSSY,LANDONVILLERS,49.1184504729,6.39972759611,155,,Courcelles-Chaussy,Courcelles-Chaussy,57,Moselle,44,Grand Est
57156,COURCELLES SUR NIED,57530,COURCELLES SUR NIED,,49.0571624727,6.30773670444,156,,Courcelles-sur-Nied,Courcelles-sur-Nied,57,Moselle,44,Grand Est
57158,CRAINCOURT,57590,CRAINCOURT,,48.8765159972,6.33285701026,158,,Craincourt,Craincourt,57,Moselle,44,Grand Est
57159,CREHANGE,57690,CREHANGE,,49.0509994159,6.57512805664,159,,Créhange,Créhange,57,Moselle,44,Grand Est
57160,CREUTZWALD,57150,CREUTZWALD,,49.2091115915,6.68082893611,160,,Creutzwald,Creutzwald,57,Moselle,44,Grand Est
57161,CUTTING,57260,CUTTING,,48.8448468243,6.83520339633,161,,Cutting,Cutting,57,Moselle,44,Grand Est
57162,CUVRY,57420,CUVRY,,49.0397448486,6.16141321465,162,,Cuvry,Cuvry,57,Moselle,44,Grand Est
57163,DABO,57850,DABO,,48.6429413775,7.23926887176,163,,Dabo,Dabo,57,Moselle,44,Grand Est
57163,DABO,57850,DABO,SCHAEFERHOF,48.6429413775,7.23926887176,163,,Dabo,Dabo,57,Moselle,44,Grand Est
57165,DALEM,57550,DALEM,,49.2409715207,6.61298309158,165,,Dalem,Dalem,57,Moselle,44,Grand Est
57166,DALHAIN,57340,DALHAIN,,48.8880803541,6.56448695773,166,,Dalhain,Dalhain,57,Moselle,44,Grand Est
57167,DALSTEIN,57320,DALSTEIN,,49.3062685367,6.40178942241,167,,Dalstein,Dalstein,57,Moselle,44,Grand Est
57168,DANNE ET QUATRE VENTS,57370,DANNE ET QUATRE VENTS,,48.7616932875,7.29067794786,168,,Danne-et-Quatre-Vents,Danne-et-Quatre-Vents,57,Moselle,44,Grand Est
57169,DANNELBOURG,57820,DANNELBOURG,,48.7434148502,7.23830155205,169,,Dannelbourg,Dannelbourg,57,Moselle,44,Grand Est
57171,DELME,57590,DELME,,48.8832883622,6.3852048088,171,,Delme,Delme,57,Moselle,44,Grand Est
57172,DENTING,57220,DENTING,,49.1838468541,6.54223138177,172,,Denting,Denting,57,Moselle,44,Grand Est
57173,DESSELING,57260,DESSELING,,48.7853183275,6.84475115273,173,,Desseling,Desseling,57,Moselle,44,Grand Est
57174,DESTRY,57340,DESTRY,,48.9455841112,6.58479529339,174,,Destry,Destry,57,Moselle,44,Grand Est
57175,DIANE CAPELLE,57830,DIANE CAPELLE,,48.7282548842,6.93474209956,175,,Diane-Capelle,Diane-Capelle,57,Moselle,44,Grand Est
57176,DIEBLING,57980,DIEBLING,,49.1049228082,6.9358597746,176,,Diebling,Diebling,57,Moselle,44,Grand Est
57177,DIEUZE,57260,DIEUZE,,48.8077845102,6.71733647603,177,,Dieuze,Dieuze,57,Moselle,44,Grand Est
57178,DIFFEMBACH LES HELLIMER,57660,DIFFEMBACH LES HELLIMER,,49.0067890191,6.85532210517,178,,Diffembach-lès-Hellimer,Diffembach-lès-Hellimer,57,Moselle,44,Grand Est
57179,DISTROFF,57925,DISTROFF,,49.336051033,6.26255383896,179,,Distroff,Distroff,57,Moselle,44,Grand Est
57180,DOLVING,57400,DOLVING,,48.7704026772,7.02130985886,180,,Dolving,Dolving,57,Moselle,44,Grand Est
57181,DOMNOM LES DIEUZE,57260,DOMNOM LES DIEUZE,,48.8611192156,6.82405240882,181,,Domnom-lès-Dieuze,Domnom-lès-Dieuze,57,Moselle,44,Grand Est
57182,DONJEUX,57590,DONJEUX,,48.8785631549,6.41104605137,182,,Donjeux,Donjeux,57,Moselle,44,Grand Est
57183,DONNELAY,57810,DONNELAY,,48.750185761,6.68883715814,183,,Donnelay,Donnelay,57,Moselle,44,Grand Est
57186,EBERSVILLER,57320,EBERSVILLER,,49.2801569466,6.39992340407,186,,Ébersviller,Ébersviller,57,Moselle,44,Grand Est
57187,EBLANGE,57220,EBLANGE,,49.2217423553,6.4871272432,187,,Éblange,Éblange,57,Moselle,44,Grand Est
57188,EGUELSHARDT,57230,EGUELSHARDT,,49.0242750772,7.52002924724,188,,Éguelshardt,Éguelshardt,57,Moselle,44,Grand Est
57189,EINCHEVILLE,57340,EINCHEVILLE,,48.9851540857,6.60090977779,189,,Eincheville,Eincheville,57,Moselle,44,Grand Est
57190,ELVANGE,57690,ELVANGE,,49.0502783316,6.54406450287,190,,Elvange,Elvange,57,Moselle,44,Grand Est
57191,ELZANGE,57970,ELZANGE,,49.3607500617,6.29245730658,191,,Elzange,Elzange,57,Moselle,44,Grand Est
57192,ENCHENBERG,57415,ENCHENBERG,,49.0201848872,7.32888421679,192,,Enchenberg,Enchenberg,57,Moselle,44,Grand Est
57193,ENNERY,57365,ENNERY,,49.2278102689,6.2187616545,193,,Ennery,Ennery,57,Moselle,44,Grand Est
57194,ENTRANGE,57330,ENTRANGE,,49.4144066567,6.11091763784,194,,Entrange,Entrange,57,Moselle,44,Grand Est
57195,EPPING,57720,EPPING,,49.1056772723,7.31945928878,195,,Epping,Epping,57,Moselle,44,Grand Est
57196,ERCHING,57720,ERCHING,,49.1145150394,7.26539475747,196,,Erching,Erching,57,Moselle,44,Grand Est
57197,ERNESTVILLER,57510,ERNESTVILLER,,49.0636116192,6.97508799239,197,,Ernestviller,Ernestviller,57,Moselle,44,Grand Est
57198,ERSTROFF,57660,ERSTROFF,,48.980103423,6.77594710498,198,,Erstroff,Erstroff,57,Moselle,44,Grand Est
57199,ESCHERANGE,57330,ESCHERANGE,,49.4169735728,6.06735044599,199,,Escherange,Escherange,57,Moselle,44,Grand Est
57200,LES ETANGS,57530,LES ETANGS,,49.1452868081,6.37462761343,200,Les,Étangs,Les Étangs,57,Moselle,44,Grand Est
57201,ETTING,57412,ETTING,,49.0254317079,7.18693147869,201,,Etting,Etting,57,Moselle,44,Grand Est
57202,ETZLING,57460,ETZLING,,49.1801213769,6.95820940486,202,,Etzling,Etzling,57,Moselle,44,Grand Est
57203,EVRANGE,57570,EVRANGE,,49.5018952512,6.19391605054,203,,Évrange,Évrange,57,Moselle,44,Grand Est
57204,FAILLY,57640,FAILLY,,49.1632324658,6.27202810921,204,,Failly,Failly,57,Moselle,44,Grand Est
57204,FAILLY,57640,FAILLY,VREMY,49.1632324658,6.27202810921,204,,Failly,Failly,57,Moselle,44,Grand Est
57205,FALCK,57550,FALCK,,49.2259138571,6.6382811788,205,,Falck,Falck,57,Moselle,44,Grand Est
57206,FAMECK,57290,FAMECK,,49.2987006334,6.10649265804,206,,Fameck,Fameck,57,Moselle,44,Grand Est
57206,FAMECK,57290,FAMECK,OURY,49.2987006334,6.10649265804,206,,Fameck,Fameck,57,Moselle,44,Grand Est
57206,FAMECK,57290,FAMECK,REMELANGE,49.2987006334,6.10649265804,206,,Fameck,Fameck,57,Moselle,44,Grand Est
57207,FAREBERSVILLER,57450,FAREBERSVILLER,,49.1168142079,6.86777677982,207,,Farébersviller,Farébersviller,57,Moselle,44,Grand Est
57208,FARSCHVILLER,57450,FARSCHVILLER,,49.0904845084,6.88423747593,208,,Farschviller,Farschviller,57,Moselle,44,Grand Est
57209,FAULQUEMONT,57380,FAULQUEMONT,,49.0355163236,6.59404999138,209,,Faulquemont,Faulquemont,57,Moselle,44,Grand Est
57209,FAULQUEMONT,57380,FAULQUEMONT,CHEMERY,49.0355163236,6.59404999138,209,,Faulquemont,Faulquemont,57,Moselle,44,Grand Est
57210,FENETRANGE,57930,FENETRANGE,,48.850716262,7.00987117365,210,,Fénétrange,Fénétrange,57,Moselle,44,Grand Est
57211,FEVES,57280,FEVES,,49.1891814065,6.10692735575,211,,Fèves,Fèves,57,Moselle,44,Grand Est
57212,FEY,57420,FEY,,49.0296436308,6.0997498938,212,,Féy,Féy,57,Moselle,44,Grand Est
57213,FILSTROFF,57320,FILSTROFF,,49.3246054218,6.53027792058,213,,Filstroff,Filstroff,57,Moselle,44,Grand Est
57214,FIXEM,57570,FIXEM,,49.4427943752,6.27318688031,214,,Fixem,Fixem,57,Moselle,44,Grand Est
57215,FLASTROFF,57320,FLASTROFF,,49.374940842,6.53622362598,215,,Flastroff,Flastroff,57,Moselle,44,Grand Est
57216,FLEISHEIM,57635,FLEISHEIM,,48.791923368,7.16414660657,216,,Fleisheim,Fleisheim,57,Moselle,44,Grand Est
57217,FLETRANGE,57690,FLETRANGE,,49.0726696242,6.56399437397,217,,Flétrange,Flétrange,57,Moselle,44,Grand Est
57218,FLEURY,57420,FLEURY,,49.0375892357,6.20549246753,218,,Fleury,Fleury,57,Moselle,44,Grand Est
57219,FLEVY,57365,FLEVY,,49.2400453527,6.26395844495,219,,Flévy,Flévy,57,Moselle,44,Grand Est
57220,FLOCOURT,57580,FLOCOURT,,48.9726017584,6.41177578458,220,,Flocourt,Flocourt,57,Moselle,44,Grand Est
57221,FLORANGE,57190,FLORANGE,,49.3281938982,6.12282167584,221,,Florange,Florange,57,Moselle,44,Grand Est
57221,FLORANGE,57190,FLORANGE,EBANGE,49.3281938982,6.12282167584,221,,Florange,Florange,57,Moselle,44,Grand Est
57222,FOLKLING,57600,FOLKLING,,49.1512458848,6.90623591877,222,,Folkling,Folkling,57,Moselle,44,Grand Est
57224,FOLSCHVILLER,57730,FOLSCHVILLER,,49.0692645659,6.68467570512,224,,Folschviller,Folschviller,57,Moselle,44,Grand Est
57225,FONTENY,57590,FONTENY,,48.8761779555,6.47830275914,225,,Fonteny,Fonteny,57,Moselle,44,Grand Est
57226,FONTOY,57650,FONTOY,,49.3577138097,5.99679227022,226,,Fontoy,Fontoy,57,Moselle,44,Grand Est
57227,FORBACH,57600,FORBACH,,49.1912650369,6.89275798526,227,,Forbach,Forbach,57,Moselle,44,Grand Est
57227,FORBACH,57600,FORBACH,KREUTZBERG,49.1912650369,6.89275798526,227,,Forbach,Forbach,57,Moselle,44,Grand Est
57227,FORBACH,57600,FORBACH,MARIENAU,49.1912650369,6.89275798526,227,,Forbach,Forbach,57,Moselle,44,Grand Est
57228,FOSSIEUX,57590,FOSSIEUX,,48.850273801,6.33084247597,228,,Fossieux,Fossieux,57,Moselle,44,Grand Est
57229,FOULCREY,57830,FOULCREY,,48.6402409757,6.86776109913,229,,Foulcrey,Foulcrey,57,Moselle,44,Grand Est
57230,FOULIGNY,57220,FOULIGNY,,49.0960674446,6.51361253656,230,,Fouligny,Fouligny,57,Moselle,44,Grand Est
57231,FOVILLE,57420,FOVILLE,,48.9185016444,6.3250667958,231,,Foville,Foville,57,Moselle,44,Grand Est
57232,FRANCALTROFF,57670,FRANCALTROFF,,48.9621475531,6.79559883909,232,,Francaltroff,Francaltroff,57,Moselle,44,Grand Est
57233,FRAQUELFING,57790,FRAQUELFING,,48.6354147373,6.98945886405,233,,Fraquelfing,Fraquelfing,57,Moselle,44,Grand Est
57234,FRAUENBERG,57200,FRAUENBERG,,49.1339601858,7.11539380178,234,,Frauenberg,Frauenberg,57,Moselle,44,Grand Est
57235,FREISTROFF,57320,FREISTROFF,,49.2899855727,6.4826695714,235,,Freistroff,Freistroff,57,Moselle,44,Grand Est
57236,FREMERY,57590,FREMERY,,48.9240175363,6.47344471901,236,,Frémery,Frémery,57,Moselle,44,Grand Est
57237,FREMESTROFF,57660,FREMESTROFF,,49.0147605301,6.7854856374,237,,Frémestroff,Frémestroff,57,Moselle,44,Grand Est
57238,FRESNES EN SAULNOIS,57170,FRESNES EN SAULNOIS,,48.8388411221,6.4401167651,238,,Fresnes-en-Saulnois,Fresnes-en-Saulnois,57,Moselle,44,Grand Est
57239,FREYBOUSE,57660,FREYBOUSE,,48.9986501647,6.77863114276,239,,Freybouse,Freybouse,57,Moselle,44,Grand Est
57240,FREYMING MERLEBACH,57800,FREYMING MERLEBACH,,49.1525756195,6.79731056431,240,,Freyming-Merlebach,Freyming-Merlebach,57,Moselle,44,Grand Est
57240,FREYMING MERLEBACH,57800,FREYMING MERLEBACH,MERLEBACH,49.1525756195,6.79731056431,240,,Freyming-Merlebach,Freyming-Merlebach,57,Moselle,44,Grand Est
57241,FRIBOURG,57810,FRIBOURG,,48.7700278814,6.87194156304,241,,Fribourg,Fribourg,57,Moselle,44,Grand Est
57242,GANDRANGE,57175,GANDRANGE,,49.2734111238,6.13350555493,242,,Gandrange,Gandrange,57,Moselle,44,Grand Est
57244,GARREBOURG,57820,GARREBOURG,,48.7083515564,7.23712179882,244,,Garrebourg,Garrebourg,57,Moselle,44,Grand Est
57245,GAVISSE,57570,GAVISSE,,49.4341876365,6.28799997345,245,,Gavisse,Gavisse,57,Moselle,44,Grand Est
57246,GELUCOURT,57260,GELUCOURT,,48.7612241027,6.73734826884,246,,Gelucourt,Gelucourt,57,Moselle,44,Grand Est
57247,GERBECOURT,57170,GERBECOURT,,48.8513266231,6.51598424383,247,,Gerbécourt,Gerbécourt,57,Moselle,44,Grand Est
57248,GIVRYCOURT,57670,GIVRYCOURT,,48.9292867874,6.9178467389,248,,Givrycourt,Givrycourt,57,Moselle,44,Grand Est
57249,GLATIGNY,57530,GLATIGNY,,49.1419722137,6.34765231325,249,,Glatigny,Glatigny,57,Moselle,44,Grand Est
57250,GOETZENBRUCK,57620,GOETZENBRUCK,,48.974624573,7.37727669636,250,,Goetzenbruck,Goetzenbruck,57,Moselle,44,Grand Est
57250,GOETZENBRUCK,57620,GOETZENBRUCK,SARREINSBERG,48.974624573,7.37727669636,250,,Goetzenbruck,Goetzenbruck,57,Moselle,44,Grand Est
57251,GOIN,57420,GOIN,,48.9875798423,6.22300683385,251,,Goin,Goin,57,Moselle,44,Grand Est
57252,GOMELANGE,57220,GOMELANGE,,49.2385076599,6.46140268813,252,,Gomelange,Gomelange,57,Moselle,44,Grand Est
57252,GOMELANGE,57220,GOMELANGE,GUIRLANGE,49.2385076599,6.46140268813,252,,Gomelange,Gomelange,57,Moselle,44,Grand Est
57253,GONDREXANGE,57815,GONDREXANGE,,48.6916242352,6.90768275556,253,,Gondrexange,Gondrexange,57,Moselle,44,Grand Est
57254,GORZE,57680,GORZE,,49.058023805,5.99129893078,254,,Gorze,Gorze,57,Moselle,44,Grand Est
57255,GOSSELMING,57930,GOSSELMING,,48.7902289684,6.9850144129,255,,Gosselming,Gosselming,57,Moselle,44,Grand Est
57256,GRAVELOTTE,57130,GRAVELOTTE,,49.1111675803,6.02427505901,256,,Gravelotte,Gravelotte,57,Moselle,44,Grand Est
57257,GREMECEY,57170,GREMECEY,,48.810894347,6.41142526055,257,,Grémecey,Grémecey,57,Moselle,44,Grand Est
57258,GRENING,57660,GRENING,,48.974322105,6.84167758808,258,,Gréning,Gréning,57,Moselle,44,Grand Est
57259,GRINDORFF BIZING,57480,GRINDORFF BIZING,,49.3821541257,6.51356610302,259,,Grindorff-Bizing,Grindorff-Bizing,57,Moselle,44,Grand Est
57260,GROSBLIEDERSTROFF,57520,GROSBLIEDERSTROFF,,49.1552146769,7.02220762018,260,,Grosbliederstroff,Grosbliederstroff,57,Moselle,44,Grand Est
57261,GROS REDERCHING,57410,GROS REDERCHING,,49.068151455,7.2189013758,261,,Gros-Réderching,Gros-Réderching,57,Moselle,44,Grand Est
57262,GROSTENQUIN,57660,GROSTENQUIN,,48.9811031676,6.74027388511,262,,Grostenquin,Grostenquin,57,Moselle,44,Grand Est
57263,GRUNDVILLER,57510,GRUNDVILLER,,49.0488850629,6.98394214677,263,,Grundviller,Grundviller,57,Moselle,44,Grand Est
57264,GUEBENHOUSE,57510,GUEBENHOUSE,,49.0769015436,6.95086192119,264,,Guebenhouse,Guebenhouse,57,Moselle,44,Grand Est
57265,GUEBESTROFF,57260,GUEBESTROFF,,48.8417093595,6.71398837689,265,,Guébestroff,Guébestroff,57,Moselle,44,Grand Est
57266,GUEBLANGE LES DIEUZE,57260,GUEBLANGE LES DIEUZE,,48.7774548457,6.70140022635,266,,Guéblange-lès-Dieuze,Guéblange-lès-Dieuze,57,Moselle,44,Grand Est
57267,LE VAL DE GUEBLANGE,57430,LE VAL DE GUEBLANGE,,48.980310818,6.95647659891,267,Le,Val-de-Guéblange,Le Val-de-Guéblange,57,Moselle,44,Grand Est
57268,GUEBLING,57260,GUEBLING,,48.8674293239,6.73710242043,268,,Guébling,Guébling,57,Moselle,44,Grand Est
57269,GUENANGE,57310,GUENANGE,,49.2984663485,6.20430376162,269,,Guénange,Guénange,57,Moselle,44,Grand Est
57270,VAL DE BRIDE,57260,VAL DE BRIDE,,48.8293499889,6.69041214777,270,,Val-de-Bride,Val-de-Bride,57,Moselle,44,Grand Est
57270,VAL DE BRIDE,57260,VAL DE BRIDE,KERPRICH LES DIEUZE,48.8293499889,6.69041214777,270,,Val-de-Bride,Val-de-Bride,57,Moselle,44,Grand Est
57271,GUENVILLER,57470,GUENVILLER,,49.1048014179,6.80480680693,271,,Guenviller,Guenviller,57,Moselle,44,Grand Est
57272,GUERMANGE,57260,GUERMANGE,,48.8023864837,6.81989842123,272,,Guermange,Guermange,57,Moselle,44,Grand Est
57273,GUERSTLING,57320,GUERSTLING,,49.3242110965,6.57634987075,273,,Guerstling,Guerstling,57,Moselle,44,Grand Est
57274,GUERTING,57880,GUERTING,,49.1937313048,6.61915657628,274,,Guerting,Guerting,57,Moselle,44,Grand Est
57275,GUESSLING HEMERING,57380,GUESSLING HEMERING,,49.0221937323,6.66814664402,275,,Guessling-Hémering,Guessling-Hémering,57,Moselle,44,Grand Est
57276,GUINGLANGE,57690,GUINGLANGE,,49.070677409,6.51711375375,276,,Guinglange,Guinglange,57,Moselle,44,Grand Est
57277,GUINKIRCHEN,57220,GUINKIRCHEN,,49.2071964613,6.44513979515,277,,Guinkirchen,Guinkirchen,57,Moselle,44,Grand Est
57278,GUINZELING,57670,GUINZELING,,48.8800902544,6.84393509997,278,,Guinzeling,Guinzeling,57,Moselle,44,Grand Est
57280,GUNTZVILLER,57405,GUNTZVILLER,,48.7041811617,7.16303879874,280,,Guntzviller,Guntzviller,57,Moselle,44,Grand Est
57281,HABOUDANGE,57340,HABOUDANGE,,48.8851645447,6.60344571848,281,,Haboudange,Haboudange,57,Moselle,44,Grand Est
57282,HAGEN,57570,HAGEN,,49.4968460062,6.16553316474,282,,Hagen,Hagen,57,Moselle,44,Grand Est
57283,HAGONDANGE,57300,HAGONDANGE,,49.2467196243,6.15851425392,283,,Hagondange,Hagondange,57,Moselle,44,Grand Est
57284,HALLERING,57690,HALLERING,,49.1239323268,6.55528654892,284,,Hallering,Hallering,57,Moselle,44,Grand Est
57286,HALSTROFF,57480,HALSTROFF,,49.3875730574,6.47720074368,286,,Halstroff,Halstroff,57,Moselle,44,Grand Est
57287,BASSE HAM,57970,BASSE HAM,,49.3759973873,6.23863474222,287,,Basse-Ham,Basse-Ham,57,Moselle,44,Grand Est
57288,HAM SOUS VARSBERG,57880,HAM SOUS VARSBERG,,49.1829986038,6.64920897655,288,,Ham-sous-Varsberg,Ham-sous-Varsberg,57,Moselle,44,Grand Est
57289,HAMBACH,57910,HAMBACH,,49.0541849142,7.03768665525,289,,Hambach,Hambach,57,Moselle,44,Grand Est
57290,HAMPONT,57170,HAMPONT,,48.8316002275,6.58571987571,290,,Hampont,Hampont,57,Moselle,44,Grand Est
57291,HANGVILLER,57370,HANGVILLER,,48.8155200679,7.23284930083,291,,Hangviller,Hangviller,57,Moselle,44,Grand Est
57292,HANNOCOURT,57590,HANNOCOURT,,48.9143175086,6.45203653587,292,,Hannocourt,Hannocourt,57,Moselle,44,Grand Est
57293,HAN SUR NIED,57580,HAN SUR NIED,,48.9905452209,6.44473244817,293,,Han-sur-Nied,Han-sur-Nied,57,Moselle,44,Grand Est
57294,HANVILLER,57230,HANVILLER,,49.103795438,7.44223800825,294,,Hanviller,Hanviller,57,Moselle,44,Grand Est
57295,HARAUCOURT SUR SEILLE,57630,HARAUCOURT SUR SEILLE,,48.8084972349,6.60564803108,295,,Haraucourt-sur-Seille,Haraucourt-sur-Seille,57,Moselle,44,Grand Est
57296,HARGARTEN AUX MINES,57550,HARGARTEN AUX MINES,,49.2213146736,6.60477300575,296,,Hargarten-aux-Mines,Hargarten-aux-Mines,57,Moselle,44,Grand Est
57297,HARPRICH,57340,HARPRICH,,48.966895194,6.65512911806,297,,Harprich,Harprich,57,Moselle,44,Grand Est
57298,HARREBERG,57870,HARREBERG,,48.6629610218,7.17414816773,298,,Harreberg,Harreberg,57,Moselle,44,Grand Est
57299,HARTZVILLER,57870,HARTZVILLER,,48.6702553561,7.08771571021,299,,Hartzviller,Hartzviller,57,Moselle,44,Grand Est
57300,HASELBOURG,57850,HASELBOURG,,48.6894845276,7.22182351627,300,,Haselbourg,Haselbourg,57,Moselle,44,Grand Est
57301,HASPELSCHIEDT,57230,HASPELSCHIEDT,,49.0765827485,7.49538129616,301,,Haspelschiedt,Haspelschiedt,57,Moselle,44,Grand Est
57302,HATTIGNY,57790,HATTIGNY,,48.6273852983,6.96125390786,302,,Hattigny,Hattigny,57,Moselle,44,Grand Est
57303,HAUCONCOURT,57280,HAUCONCOURT,,49.2113038601,6.18435511357,303,,Hauconcourt,Hauconcourt,57,Moselle,44,Grand Est
57304,HAUT CLOCHER,57400,HAUT CLOCHER,,48.7620782586,6.99144678539,304,,Haut-Clocher,Haut-Clocher,57,Moselle,44,Grand Est
57305,HAVANGE,57650,HAVANGE,,49.3886163858,6.00100680811,305,,Havange,Havange,57,Moselle,44,Grand Est
57306,HAYANGE,57700,HAYANGE,,49.330858891,6.06649233507,306,,Hayange,Hayange,57,Moselle,44,Grand Est
57306,HAYANGE,57700,HAYANGE,MARSPICH,49.330858891,6.06649233507,306,,Hayange,Hayange,57,Moselle,44,Grand Est
57306,HAYANGE,57700,HAYANGE,ST NICOLAS EN FORET,49.330858891,6.06649233507,306,,Hayange,Hayange,57,Moselle,44,Grand Est
57307,HAYES,57530,HAYES,,49.1699634472,6.37852476248,307,,Hayes,Hayes,57,Moselle,44,Grand Est
57308,HAZEMBOURG,57430,HAZEMBOURG,,48.9617561061,6.94250550256,308,,Hazembourg,Hazembourg,57,Moselle,44,Grand Est
57309,HEINING LES BOUZONVILLE,57320,HEINING LES BOUZONVILLE,,49.30601587,6.59755585227,309,,Heining-lès-Bouzonville,Heining-lès-Bouzonville,57,Moselle,44,Grand Est
57310,HELLERING LES FENETRANGE,57930,HELLERING LES FENETRANGE,,48.8081899476,7.06605940733,310,,Hellering-lès-Fénétrange,Hellering-lès-Fénétrange,57,Moselle,44,Grand Est
57311,HELLIMER,57660,HELLIMER,,48.9953314454,6.82557922684,311,,Hellimer,Hellimer,57,Moselle,44,Grand Est
57312,HELSTROFF,57220,HELSTROFF,,49.1523462454,6.48234809379,312,,Helstroff,Helstroff,57,Moselle,44,Grand Est
57313,HEMILLY,57690,HEMILLY,,49.0470706428,6.49114253745,313,,Hémilly,Hémilly,57,Moselle,44,Grand Est
57314,HEMING,57830,HEMING,,48.6990544638,6.97117087348,314,,Héming,Héming,57,Moselle,44,Grand Est
57315,HENRIDORFF,57820,HENRIDORFF,,48.7328010132,7.21130656264,315,,Henridorff,Henridorff,57,Moselle,44,Grand Est
57316,HENRIVILLE,57450,HENRIVILLE,,49.0993733764,6.85657257275,316,,Henriville,Henriville,57,Moselle,44,Grand Est
57317,HERANGE,57635,HERANGE,,48.7775443186,7.17286672818,317,,Hérange,Hérange,57,Moselle,44,Grand Est
57318,HERMELANGE,57790,HERMELANGE,,48.681898181,7.0155466313,318,,Hermelange,Hermelange,57,Moselle,44,Grand Est
57319,HERNY,57580,HERNY,,49.003612296,6.48054105293,319,,Herny,Herny,57,Moselle,44,Grand Est
57320,HERTZING,57830,HERTZING,,48.6915281748,6.95434783679,320,,Hertzing,Hertzing,57,Moselle,44,Grand Est
57321,HESSE,57400,HESSE,,48.6876193264,7.05195872766,321,,Hesse,Hesse,57,Moselle,44,Grand Est
57322,HESTROFF,57320,HESTROFF,,49.2636097823,6.42936599156,322,,Hestroff,Hestroff,57,Moselle,44,Grand Est
57323,HETTANGE GRANDE,57330,HETTANGE GRANDE,,49.4173312149,6.15258748635,323,,Hettange-Grande,Hettange-Grande,57,Moselle,44,Grand Est
57324,HILBESHEIM,57400,HILBESHEIM,,48.7801948561,7.10042717343,324,,Hilbesheim,Hilbesheim,57,Moselle,44,Grand Est
57325,HILSPRICH,57510,HILSPRICH,,49.0059363706,6.91134139053,325,,Hilsprich,Hilsprich,57,Moselle,44,Grand Est
57326,HINCKANGE,57220,HINCKANGE,,49.1876232217,6.44855984948,326,,Hinckange,Hinckange,57,Moselle,44,Grand Est
57328,HOLACOURT,57380,HOLACOURT,,48.977421047,6.50810665423,328,,Holacourt,Holacourt,57,Moselle,44,Grand Est
57329,HOLLING,57220,HOLLING,,49.2572520068,6.49985680786,329,,Holling,Holling,57,Moselle,44,Grand Est
57330,HOLVING,57510,HOLVING,,49.0141852798,6.96843320029,330,,Holving,Holving,57,Moselle,44,Grand Est
57331,HOMBOURG BUDANGE,57920,HOMBOURG BUDANGE,,49.2875134564,6.35579703436,331,,Hombourg-Budange,Hombourg-Budange,57,Moselle,44,Grand Est
57332,HOMBOURG HAUT,57470,HOMBOURG HAUT,,49.1275851228,6.77437338619,332,,Hombourg-Haut,Hombourg-Haut,57,Moselle,44,Grand Est
57333,HOMMARTING,57405,HOMMARTING,,48.7388077485,7.14432398822,333,,Hommarting,Hommarting,57,Moselle,44,Grand Est
57334,HOMMERT,57870,HOMMERT,,48.6771461904,7.17716114263,334,,Hommert,Hommert,57,Moselle,44,Grand Est
57335,HONSKIRCH,57670,HONSKIRCH,,48.9403516855,6.96380472548,335,,Honskirch,Honskirch,57,Moselle,44,Grand Est
57336,L HOPITAL,57490,L HOPITAL,,49.1574029891,6.73265965456,336,L',Hôpital,L'Hôpital,57,Moselle,44,Grand Est
57337,HOSTE,57510,HOSTE,,49.0579657478,6.86138197129,337,,Hoste,Hoste,57,Moselle,44,Grand Est
57338,HOTTVILLER,57720,HOTTVILLER,,49.0782664216,7.35808063345,338,,Hottviller,Hottviller,57,Moselle,44,Grand Est
57339,HULTEHOUSE,57820,HULTEHOUSE,,48.7180055538,7.26302245691,339,,Hultehouse,Hultehouse,57,Moselle,44,Grand Est
57340,HUNDLING,57990,HUNDLING,,49.1019689356,6.98521601159,340,,Hundling,Hundling,57,Moselle,44,Grand Est
57341,HUNTING,57480,HUNTING,,49.4144357167,6.32945827777,341,,Hunting,Hunting,57,Moselle,44,Grand Est
57342,IBIGNY,57830,IBIGNY,,48.6456165478,6.89793311842,342,,Ibigny,Ibigny,57,Moselle,44,Grand Est
57343,ILLANGE,57970,ILLANGE,,49.3287497597,6.17935792858,343,,Illange,Illange,57,Moselle,44,Grand Est
57344,IMLING,57400,IMLING,,48.7109869246,7.0190381257,344,,Imling,Imling,57,Moselle,44,Grand Est
57345,INGLANGE,57970,INGLANGE,,49.3455744089,6.30036423785,345,,Inglange,Inglange,57,Moselle,44,Grand Est
57346,INSMING,57670,INSMING,,48.9549408655,6.88613750335,346,,Insming,Insming,57,Moselle,44,Grand Est
57347,INSVILLER,57670,INSVILLER,,48.8907066316,6.90981688773,347,,Insviller,Insviller,57,Moselle,44,Grand Est
57348,IPPLING,57990,IPPLING,,49.1058301525,7.00408307681,348,,Ippling,Ippling,57,Moselle,44,Grand Est
57349,JALLAUCOURT,57590,JALLAUCOURT,,48.8346271223,6.39461777056,349,,Jallaucourt,Jallaucourt,57,Moselle,44,Grand Est
57350,JOUY AUX ARCHES,57130,JOUY AUX ARCHES,,49.0631848992,6.08507919116,350,,Jouy-aux-Arches,Jouy-aux-Arches,57,Moselle,44,Grand Est
57351,JURY,57245,JURY,,49.0729756124,6.25660660705,351,,Jury,Jury,57,Moselle,44,Grand Est
57352,JUSSY,57130,JUSSY,,49.1008350599,6.08012042749,352,,Jussy,Jussy,57,Moselle,44,Grand Est
57353,JUVELIZE,57630,JUVELIZE,,48.7672447342,6.6566140221,353,,Juvelize,Juvelize,57,Moselle,44,Grand Est
57354,JUVILLE,57590,JUVILLE,,48.9306249062,6.3588020815,354,,Juville,Juville,57,Moselle,44,Grand Est
57355,KALHAUSEN,57412,KALHAUSEN,,49.0234490158,7.14457834236,355,,Kalhausen,Kalhausen,57,Moselle,44,Grand Est
57356,KANFEN,57330,KANFEN,,49.4352156072,6.1146772839,356,,Kanfen,Kanfen,57,Moselle,44,Grand Est
57357,KAPPELKINGER,57430,KAPPELKINGER,,48.97576104,6.90998256348,357,,Kappelkinger,Kappelkinger,57,Moselle,44,Grand Est
57358,KEDANGE SUR CANNER,57920,KEDANGE SUR CANNER,,49.3109453426,6.343428905,358,,Kédange-sur-Canner,Kédange-sur-Canner,57,Moselle,44,Grand Est
57359,KEMPLICH,57920,KEMPLICH,,49.3245509599,6.39523707067,359,,Kemplich,Kemplich,57,Moselle,44,Grand Est
57360,KERBACH,57460,KERBACH,,49.162415506,6.97402349356,360,,Kerbach,Kerbach,57,Moselle,44,Grand Est
57361,KERLING LES SIERCK,57480,KERLING LES SIERCK,,49.3910007055,6.37261264464,361,,Kerling-lès-Sierck,Kerling-lès-Sierck,57,Moselle,44,Grand Est
57362,KERPRICH AUX BOIS,57830,KERPRICH AUX BOIS,,48.7366040636,6.96003815781,362,,Kerprich-aux-Bois,Kerprich-aux-Bois,57,Moselle,44,Grand Est
57364,KIRSCH LES SIERCK,57480,KIRSCH LES SIERCK,,49.437242014,6.41053052228,364,,Kirsch-lès-Sierck,Kirsch-lès-Sierck,57,Moselle,44,Grand Est
57365,KIRSCHNAUMEN,57480,KIRSCHNAUMEN,,49.4036194349,6.43416231385,365,,Kirschnaumen,Kirschnaumen,57,Moselle,44,Grand Est
57366,KIRVILLER,57430,KIRVILLER,,48.9579531488,6.9814083493,366,,Kirviller,Kirviller,57,Moselle,44,Grand Est
57367,KLANG,57920,KLANG,,49.3187329539,6.37045880316,367,,Klang,Klang,57,Moselle,44,Grand Est
57368,KNUTANGE,57240,KNUTANGE,,49.3372900264,6.03313873426,368,,Knutange,Knutange,57,Moselle,44,Grand Est
57370,KOENIGSMACKER,57970,KOENIGSMACKER,,49.3882172495,6.28617286599,370,,Kœnigsmacker,Kœnigsmacker,57,Moselle,44,Grand Est
57371,HAUTE KONTZ,57480,HAUTE KONTZ,,49.4551080766,6.31777701549,371,,Haute-Kontz,Haute-Kontz,57,Moselle,44,Grand Est
57372,KUNTZIG,57970,KUNTZIG,,49.3481386676,6.23675334876,372,,Kuntzig,Kuntzig,57,Moselle,44,Grand Est
57373,LACHAMBRE,57730,LACHAMBRE,,49.0788433987,6.7537514656,373,,Lachambre,Lachambre,57,Moselle,44,Grand Est
57374,LAFRIMBOLLE,57560,LAFRIMBOLLE,,48.6030813996,7.02379408079,374,,Lafrimbolle,Lafrimbolle,57,Moselle,44,Grand Est
57375,LAGARDE,57810,LAGARDE,,48.6872802759,6.71223569046,375,,Lagarde,Lagarde,57,Moselle,44,Grand Est
57376,LAMBACH,57410,LAMBACH,,49.0299766365,7.36427689294,376,,Lambach,Lambach,57,Moselle,44,Grand Est
57377,LANDANGE,57830,LANDANGE,,48.6688126564,6.96056023007,377,,Landange,Landange,57,Moselle,44,Grand Est
57379,LANDROFF,57340,LANDROFF,,48.965218434,6.6165180005,379,,Landroff,Landroff,57,Moselle,44,Grand Est
57380,LANEUVEVILLE LES LORQUIN,57790,LANEUVEVILLE LES LORQUIN,,48.6512962656,7.00539649013,380,,Laneuveville-lès-Lorquin,Laneuveville-lès-Lorquin,57,Moselle,44,Grand Est
57381,LANEUVEVILLE EN SAULNOIS,57590,LANEUVEVILLE EN SAULNOIS,,48.8662241867,6.44460881144,381,,Laneuveville-en-Saulnois,Laneuveville-en-Saulnois,57,Moselle,44,Grand Est
57382,LANGATTE,57400,LANGATTE,,48.7621753199,6.95886930776,382,,Langatte,Langatte,57,Moselle,44,Grand Est
57383,LANGUIMBERG,57810,LANGUIMBERG,,48.7303158689,6.87777720328,383,,Languimberg,Languimberg,57,Moselle,44,Grand Est
57384,LANING,57660,LANING,,49.0289145916,6.77148236689,384,,Laning,Laning,57,Moselle,44,Grand Est
57385,LAQUENEXY,57530,LAQUENEXY,,49.0796082321,6.31414221874,385,,Laquenexy,Laquenexy,57,Moselle,44,Grand Est
57386,LAUDREFANG,57385,LAUDREFANG,,49.0828663736,6.6421743054,386,,Laudrefang,Laudrefang,57,Moselle,44,Grand Est
57387,LAUMESFELD,57480,LAUMESFELD,,49.3683939393,6.44377458501,387,,Laumesfeld,Laumesfeld,57,Moselle,44,Grand Est
57388,LAUNSTROFF,57480,LAUNSTROFF,,49.4349332995,6.50065230203,388,,Launstroff,Launstroff,57,Moselle,44,Grand Est
57389,LELLING,57660,LELLING,,49.0419434651,6.71058338549,389,,Lelling,Lelling,57,Moselle,44,Grand Est
57390,LEMBERG,57620,LEMBERG,,49.0103404671,7.38244495948,390,,Lemberg,Lemberg,57,Moselle,44,Grand Est
57391,LEMONCOURT,57590,LEMONCOURT,,48.8636367151,6.38717271434,391,,Lemoncourt,Lemoncourt,57,Moselle,44,Grand Est
57392,LEMUD,57580,LEMUD,,49.0335930566,6.36360487921,392,,Lemud,Lemud,57,Moselle,44,Grand Est
57393,LENGELSHEIM,57720,LENGELSHEIM,,49.1061017044,7.40784564879,393,,Lengelsheim,Lengelsheim,57,Moselle,44,Grand Est
57394,LENING,57670,LENING,,48.9581758873,6.81929265344,394,,Léning,Léning,57,Moselle,44,Grand Est
57395,LESSE,57580,LESSE,,48.9515094121,6.51596582053,395,,Lesse,Lesse,57,Moselle,44,Grand Est
57396,LESSY,57160,LESSY,,49.1272207543,6.09954585779,396,,Lessy,Lessy,57,Moselle,44,Grand Est
57397,LEY,57810,LEY,,48.736472131,6.65504252874,397,,Ley,Ley,57,Moselle,44,Grand Est
57398,LEYVILLER,57660,LEYVILLER,,49.0291320953,6.84156436895,398,,Leyviller,Leyviller,57,Moselle,44,Grand Est
57399,LEZEY,57630,LEZEY,,48.7576127729,6.62184289204,399,,Lezey,Lezey,57,Moselle,44,Grand Est
57401,LIDREZING,57340,LIDREZING,,48.8816795144,6.71394586116,401,,Lidrezing,Lidrezing,57,Moselle,44,Grand Est
57402,LIEDERSCHIEDT,57230,LIEDERSCHIEDT,,49.1216563582,7.4960610535,402,,Liederschiedt,Liederschiedt,57,Moselle,44,Grand Est
57403,LIEHON,57420,LIEHON,,48.9937891686,6.26047269459,403,,Liéhon,Liéhon,57,Moselle,44,Grand Est
57404,LINDRE BASSE,57260,LINDRE BASSE,,48.7996602877,6.74900604531,404,,Lindre-Basse,Lindre-Basse,57,Moselle,44,Grand Est
57405,LINDRE HAUTE,57260,LINDRE HAUTE,,48.8192716057,6.75638052646,405,,Lindre-Haute,Lindre-Haute,57,Moselle,44,Grand Est
57406,LIOCOURT,57590,LIOCOURT,,48.9116904529,6.34175286688,406,,Liocourt,Liocourt,57,Moselle,44,Grand Est
57407,LIXHEIM,57635,LIXHEIM,,48.7874942792,7.14504787778,407,,Lixheim,Lixheim,57,Moselle,44,Grand Est
57408,LIXING LES ROUHLING,57520,LIXING LES ROUHLING,,49.1510106332,6.99074075556,408,,Lixing-lès-Rouhling,Lixing-lès-Rouhling,57,Moselle,44,Grand Est
57409,LIXING LES ST AVOLD,57660,LIXING LES ST AVOLD,,49.0343991815,6.74031521371,409,,Lixing-lès-Saint-Avold,Lixing-lès-Saint-Avold,57,Moselle,44,Grand Est
57410,LHOR,57670,LHOR,,48.8866796516,6.8716948604,410,,Lhor,Lhor,57,Moselle,44,Grand Est
57411,LOMMERANGE,57650,LOMMERANGE,,49.3284282334,5.97092420783,411,,Lommerange,Lommerange,57,Moselle,44,Grand Est
57412,LONGEVILLE LES METZ,57050,LONGEVILLE LES METZ,,49.1148867103,6.14655128172,412,,Longeville-lès-Metz,Longeville-lès-Metz,57,Moselle,44,Grand Est
57413,LONGEVILLE LES ST AVOLD,57740,LONGEVILLE LES ST AVOLD,,49.1191583369,6.64823861446,413,,Longeville-lès-Saint-Avold,Longeville-lès-Saint-Avold,57,Moselle,44,Grand Est
57414,LORQUIN,57790,LORQUIN,,48.6664066936,7.00099165249,414,,Lorquin,Lorquin,57,Moselle,44,Grand Est
57415,LORRY LES METZ,57050,LORRY LES METZ,,49.1440695242,6.10904481635,415,,Lorry-lès-Metz,Lorry-lès-Metz,57,Moselle,44,Grand Est
57416,LORRY MARDIGNY,57420,LORRY MARDIGNY,,48.9830745569,6.09424023575,416,,Lorry-Mardigny,Lorry-Mardigny,57,Moselle,44,Grand Est
57417,LOSTROFF,57670,LOSTROFF,,48.8651920526,6.8639069452,417,,Lostroff,Lostroff,57,Moselle,44,Grand Est
57418,LOUDREFING,57670,LOUDREFING,,48.8528606416,6.89337964685,418,,Loudrefing,Loudrefing,57,Moselle,44,Grand Est
57419,LOUPERSHOUSE,57510,LOUPERSHOUSE,,49.0806819852,6.91513594257,419,,Loupershouse,Loupershouse,57,Moselle,44,Grand Est
57421,LOUTZVILLER,57720,LOUTZVILLER,,49.144497827,7.38445498027,421,,Loutzviller,Loutzviller,57,Moselle,44,Grand Est
57422,LOUVIGNY,57420,LOUVIGNY,,48.9608362772,6.18259042823,422,,Louvigny,Louvigny,57,Moselle,44,Grand Est
57423,LUBECOURT,57170,LUBECOURT,,48.8436629279,6.51001272581,423,,Lubécourt,Lubécourt,57,Moselle,44,Grand Est
57424,LUCY,57590,LUCY,,48.9407098207,6.47695483965,424,,Lucy,Lucy,57,Moselle,44,Grand Est
57425,LUPPY,57580,LUPPY,,48.9807860499,6.34093701448,425,,Luppy,Luppy,57,Moselle,44,Grand Est
57426,LUTTANGE,57935,LUTTANGE,,49.273930831,6.29636780272,426,,Luttange,Luttange,57,Moselle,44,Grand Est
57427,LUTZELBOURG,57820,LUTZELBOURG,,48.7348913591,7.25953062438,427,,Lutzelbourg,Lutzelbourg,57,Moselle,44,Grand Est
57428,MACHEREN,57730,MACHEREN,,49.0948492367,6.76865484007,428,,Macheren,Macheren,57,Moselle,44,Grand Est
57428,MACHEREN,57730,MACHEREN,PETIT EBERSVILLER,49.0948492367,6.76865484007,428,,Macheren,Macheren,57,Moselle,44,Grand Est
57430,MAINVILLERS,57380,MAINVILLERS,,49.0208000969,6.54258158308,430,,Mainvillers,Mainvillers,57,Moselle,44,Grand Est
57431,MAIZEROY,57530,MAIZEROY,,49.0861603474,6.38860795137,431,,Maizeroy,Maizeroy,57,Moselle,44,Grand Est
57432,MAIZERY,57530,MAIZERY,,49.1101920701,6.34230552342,432,,Maizery,Maizery,57,Moselle,44,Grand Est
57433,MAIZIERES LES METZ,57280,MAIZIERES LES METZ,,49.2085175228,6.15614048077,433,,Maizières-lès-Metz,Maizières-lès-Metz,57,Moselle,44,Grand Est
57434,MAIZIERES LES VIC,57810,MAIZIERES LES VIC,,48.7095753584,6.78401275888,434,,Maizières-lès-Vic,Maizières-lès-Vic,57,Moselle,44,Grand Est
57436,MALAUCOURT SUR SEILLE,57590,MALAUCOURT SUR SEILLE,,48.8432909798,6.35890178072,436,,Malaucourt-sur-Seille,Malaucourt-sur-Seille,57,Moselle,44,Grand Est
57437,MALLING,57480,MALLING,,49.4148882065,6.30672522537,437,,Malling,Malling,57,Moselle,44,Grand Est
57438,MALROY,57640,MALROY,,49.174168393,6.21680938131,438,,Malroy,Malroy,57,Moselle,44,Grand Est
57439,MANDEREN,57480,MANDEREN,,49.4535376683,6.44989622561,439,,Manderen,Manderen,57,Moselle,44,Grand Est
57440,MANHOUE,57590,MANHOUE,,48.8266822397,6.34120837788,440,,Manhoué,Manhoué,57,Moselle,44,Grand Est
57441,MANOM,57100,MANOM,,49.3807868109,6.17472865398,441,,Manom,Manom,57,Moselle,44,Grand Est
57442,MANY,57380,MANY,,49.0008874067,6.52779955702,442,,Many,Many,57,Moselle,44,Grand Est
57443,MARANGE SILVANGE,57535,MARANGE SILVANGE,,49.215755032,6.11238062036,443,,Marange-Silvange,Marange-Silvange,57,Moselle,44,Grand Est
57444,MARANGE ZONDRANGE,57690,MARANGE ZONDRANGE,,49.1161517101,6.52943965948,444,,Marange-Zondrange,Marange-Zondrange,57,Moselle,44,Grand Est
57445,MARIEULLES,57420,MARIEULLES,,49.0056098276,6.11076140538,445,,Marieulles,Marieulles,57,Moselle,44,Grand Est
57446,MARIMONT LES BENESTROFF,57670,MARIMONT LES BENESTROFF,,48.8895699015,6.788940583,446,,Marimont-lès-Bénestroff,Marimont-lès-Bénestroff,57,Moselle,44,Grand Est
57447,MARLY,57155,MARLY,,49.0658509672,6.15231485365,447,,Marly,Marly,57,Moselle,44,Grand Est
57448,MARSAL,57630,MARSAL,,48.7823947316,6.61581078575,448,,Marsal,Marsal,57,Moselle,44,Grand Est
57449,MARSILLY,57530,MARSILLY,,49.0954334009,6.3072836454,449,,Marsilly,Marsilly,57,Moselle,44,Grand Est
57451,MARTHILLE,57340,MARTHILLE,,48.9295898959,6.55903279348,451,,Marthille,Marthille,57,Moselle,44,Grand Est
57452,LA MAXE,57140,LA MAXE,,49.1617041923,6.19206256915,452,La,Maxe,La Maxe,57,Moselle,44,Grand Est
57453,MAXSTADT,57660,MAXSTADT,,49.0463550894,6.80405802458,453,,Maxstadt,Maxstadt,57,Moselle,44,Grand Est
57454,MECLEUVES,57245,MECLEUVES,,49.0513378937,6.2779740963,454,,Mécleuves,Mécleuves,57,Moselle,44,Grand Est
57455,MEGANGE,57220,MEGANGE,,49.2240160777,6.43649768125,455,,Mégange,Mégange,57,Moselle,44,Grand Est
57456,MEISENTHAL,57960,MEISENTHAL,,48.9707983496,7.34611209492,456,,Meisenthal,Meisenthal,57,Moselle,44,Grand Est
57457,MENSKIRCH,57320,MENSKIRCH,,49.3200359913,6.42942950334,457,,Menskirch,Menskirch,57,Moselle,44,Grand Est
57459,MERSCHWEILLER,57480,MERSCHWEILLER,,49.4611026921,6.41621024861,459,,Merschweiller,Merschweiller,57,Moselle,44,Grand Est
57460,MERTEN,57550,MERTEN,,49.2468555754,6.66349016101,460,,Merten,Merten,57,Moselle,44,Grand Est
57461,METAIRIES ST QUIRIN,57560,METAIRIES ST QUIRIN,,48.6404358674,7.03770343075,461,,Métairies-Saint-Quirin,Métairies-Saint-Quirin,57,Moselle,44,Grand Est
57462,METTING,57370,METTING,,48.8117374038,7.20544869004,462,,Metting,Metting,57,Moselle,44,Grand Est
57463,METZ,57000,METZ,,49.1081133279,6.1955245421,463,,Metz,Metz,57,Moselle,44,Grand Est
57463,METZ,57050,METZ,,49.1081133279,6.1955245421,463,,Metz,Metz,57,Moselle,44,Grand Est
57463,METZ,57070,METZ,,49.1081133279,6.1955245421,463,,Metz,Metz,57,Moselle,44,Grand Est
57464,METZERESCHE,57920,METZERESCHE,,49.2949941385,6.30743995125,464,,Metzeresche,Metzeresche,57,Moselle,44,Grand Est
57465,METZERVISSE,57940,METZERVISSE,,49.3168013752,6.28918104694,465,,Metzervisse,Metzervisse,57,Moselle,44,Grand Est
57466,METZING,57980,METZING,,49.0993204165,6.96137313836,466,,Metzing,Metzing,57,Moselle,44,Grand Est
57467,MEY,57070,MEY,,49.1370275752,6.24143707513,467,,Mey,Mey,57,Moselle,44,Grand Est
57468,MITTELBRONN,57370,MITTELBRONN,,48.7652819916,7.22614305425,468,,Mittelbronn,Mittelbronn,57,Moselle,44,Grand Est
57469,MITTERSHEIM,57930,MITTERSHEIM,,48.855832503,6.94423877984,469,,Mittersheim,Mittersheim,57,Moselle,44,Grand Est
57470,MOLRING,57670,MOLRING,,48.8903821461,6.8236381289,470,,Molring,Molring,57,Moselle,44,Grand Est
57471,MOMERSTROFF,57220,MOMERSTROFF,,49.1545873457,6.53247203457,471,,Momerstroff,Momerstroff,57,Moselle,44,Grand Est
57472,MONCHEUX,57420,MONCHEUX,,48.9466807174,6.33617668606,472,,Moncheux,Moncheux,57,Moselle,44,Grand Est
57473,MONCOURT,57810,MONCOURT,,48.7177111982,6.64038481874,473,,Moncourt,Moncourt,57,Moselle,44,Grand Est
57474,MONDELANGE,57300,MONDELANGE,,49.2630401785,6.17244194206,474,,Mondelange,Mondelange,57,Moselle,44,Grand Est
57475,MONDORFF,57570,MONDORFF,,49.5020535838,6.25578622629,475,,Mondorff,Mondorff,57,Moselle,44,Grand Est
57476,MONNEREN,57920,MONNEREN,,49.3493856385,6.41198740634,476,,Monneren,Monneren,57,Moselle,44,Grand Est
57477,MONTBRONN,57415,MONTBRONN,,48.9975807124,7.30621869916,477,,Montbronn,Montbronn,57,Moselle,44,Grand Est
57478,MONTDIDIER,57670,MONTDIDIER,,48.9328455067,6.81546576999,478,,Montdidier,Montdidier,57,Moselle,44,Grand Est
57479,MONTENACH,57480,MONTENACH,,49.4142449665,6.38637260457,479,,Montenach,Montenach,57,Moselle,44,Grand Est
57480,MONTIGNY LES METZ,57950,MONTIGNY LES METZ,,49.0953199106,6.15452599506,480,,Montigny-lès-Metz,Montigny-lès-Metz,57,Moselle,44,Grand Est
57481,MONTOIS LA MONTAGNE,57860,MONTOIS LA MONTAGNE,,49.2211420359,6.02743804311,481,,Montois-la-Montagne,Montois-la-Montagne,57,Moselle,44,Grand Est
57482,MONTOY FLANVILLE,57645,MONTOY FLANVILLE,,49.120451502,6.2888126818,482,,Ogy-Montoy-Flanville,Ogy-Montoy-Flanville,57,Moselle,44,Grand Est
57483,MORHANGE,57340,MORHANGE,,48.9274404434,6.64219551497,483,,Morhange,Morhange,57,Moselle,44,Grand Est
57484,MORSBACH,57600,MORSBACH,,49.167360465,6.86659002983,484,,Morsbach,Morsbach,57,Moselle,44,Grand Est
57485,MORVILLE LES VIC,57170,MORVILLE LES VIC,,48.8153269864,6.54720214247,485,,Morville-lès-Vic,Morville-lès-Vic,57,Moselle,44,Grand Est
57486,MORVILLE SUR NIED,57590,MORVILLE SUR NIED,,48.9493034431,6.42925654166,486,,Morville-sur-Nied,Morville-sur-Nied,57,Moselle,44,Grand Est
57487,MOULINS LES METZ,57160,MOULINS LES METZ,,49.0926450491,6.11852525419,487,,Moulins-lès-Metz,Moulins-lès-Metz,57,Moselle,44,Grand Est
57487,MOULINS LES METZ,57160,MOULINS LES METZ,MOULINS ST PIERRE,49.0926450491,6.11852525419,487,,Moulins-lès-Metz,Moulins-lès-Metz,57,Moselle,44,Grand Est
57488,MOUSSEY,57770,MOUSSEY,,48.6714459538,6.77870838226,488,,Moussey,Moussey,57,Moselle,44,Grand Est
57489,MOUTERHOUSE,57620,MOUTERHOUSE,,48.9903559218,7.43839841263,489,,Mouterhouse,Mouterhouse,57,Moselle,44,Grand Est
57490,MOYENVIC,57630,MOYENVIC,,48.7832829713,6.57005924028,490,,Moyenvic,Moyenvic,57,Moselle,44,Grand Est
57491,MOYEUVRE GRANDE,57250,MOYEUVRE GRANDE,,49.2539723138,6.0371285867,491,,Moyeuvre-Grande,Moyeuvre-Grande,57,Moselle,44,Grand Est
57491,MOYEUVRE GRANDE,57250,MOYEUVRE GRANDE,FROIDCUL,49.2539723138,6.0371285867,491,,Moyeuvre-Grande,Moyeuvre-Grande,57,Moselle,44,Grand Est
57492,MOYEUVRE PETITE,57250,MOYEUVRE PETITE,,49.2792197408,6.02037855349,492,,Moyeuvre-Petite,Moyeuvre-Petite,57,Moselle,44,Grand Est
57493,MULCEY,57260,MULCEY,,48.8077142021,6.66402921027,493,,Mulcey,Mulcey,57,Moselle,44,Grand Est
57494,MUNSTER,57670,MUNSTER,,48.9087027721,6.89308196735,494,,Munster,Munster,57,Moselle,44,Grand Est
57495,NARBEFONTAINE,57220,NARBEFONTAINE,,49.1367860634,6.54682213499,495,,Narbéfontaine,Narbéfontaine,57,Moselle,44,Grand Est
57496,NEBING,57670,NEBING,,48.9088397612,6.81421395642,496,,Nébing,Nébing,57,Moselle,44,Grand Est
57497,NELLING,57670,NELLING,,48.9700836423,6.86518784706,497,,Nelling,Nelling,57,Moselle,44,Grand Est
57498,NEUFCHEF,57700,NEUFCHEF,,49.317754273,6.01399448465,498,,Neufchef,Neufchef,57,Moselle,44,Grand Est
57499,NEUFGRANGE,57910,NEUFGRANGE,,49.0746513432,7.06686266439,499,,Neufgrange,Neufgrange,57,Moselle,44,Grand Est
57500,NEUFMOULINS,57830,NEUFMOULINS,,48.6801953438,6.97253040466,500,,Neufmoulins,Neufmoulins,57,Moselle,44,Grand Est
57501,NEUFVILLAGE,57670,NEUFVILLAGE,,48.9344617713,6.7792192501,501,,Neufvillage,Neufvillage,57,Moselle,44,Grand Est
57502,NEUNKIRCHEN LES BOUZONVILLE,57320,NEUNKIRCHEN LES BOUZONVILLE,,49.3498409296,6.56078600065,502,,Neunkirchen-lès-Bouzonville,Neunkirchen-lès-Bouzonville,57,Moselle,44,Grand Est
57504,NIDERHOFF,57560,NIDERHOFF,,48.6279003557,7.008294528,504,,Niderhoff,Niderhoff,57,Moselle,44,Grand Est
57505,NIDERVILLER,57565,NIDERVILLER,,48.7105520176,7.11797148432,505,,Niderviller,Niderviller,57,Moselle,44,Grand Est
57506,NIEDERSTINZEL,57930,NIEDERSTINZEL,,48.8717353508,7.01381132369,506,,Niederstinzel,Niederstinzel,57,Moselle,44,Grand Est
57507,NIEDERVISSE,57220,NIEDERVISSE,,49.16173296,6.56498885511,507,,Niedervisse,Niedervisse,57,Moselle,44,Grand Est
57508,NILVANGE,57240,NILVANGE,,49.3442452472,6.05058281436,508,,Nilvange,Nilvange,57,Moselle,44,Grand Est
57509,NITTING,57790,NITTING,,48.6617122731,7.0490425095,509,,Nitting,Nitting,57,Moselle,44,Grand Est
57510,NOISSEVILLE,57645,NOISSEVILLE,,49.1337073536,6.27240706225,510,,Noisseville,Noisseville,57,Moselle,44,Grand Est
57511,NORROY LE VENEUR,57140,NORROY LE VENEUR,,49.179562869,6.11099249211,511,,Norroy-le-Veneur,Norroy-le-Veneur,57,Moselle,44,Grand Est
57512,NOUILLY,57645,NOUILLY,,49.1341177397,6.25580095096,512,,Nouilly,Nouilly,57,Moselle,44,Grand Est
57513,NOUSSEVILLER LES BITCHE,57720,NOUSSEVILLER LES BITCHE,,49.1000930479,7.37370577565,513,,Nousseviller-lès-Bitche,Nousseviller-lès-Bitche,57,Moselle,44,Grand Est
57514,NOUSSEVILLER ST NABOR,57990,NOUSSEVILLER ST NABOR,,49.1292618087,6.97126269237,514,,Nousseviller-Saint-Nabor,Nousseviller-Saint-Nabor,57,Moselle,44,Grand Est
57515,NOVEANT SUR MOSELLE,57680,NOVEANT SUR MOSELLE,,49.0322950446,6.03876360118,515,,Novéant-sur-Moselle,Novéant-sur-Moselle,57,Moselle,44,Grand Est
57516,OBERDORFF,57320,OBERDORFF,,49.274217248,6.58132683065,516,,Oberdorff,Oberdorff,57,Moselle,44,Grand Est
57517,OBERGAILBACH,57720,OBERGAILBACH,,49.1140388578,7.22339792872,517,,Obergailbach,Obergailbach,57,Moselle,44,Grand Est
57518,OBERSTINZEL,57930,OBERSTINZEL,,48.7917214165,7.03623655137,518,,Oberstinzel,Oberstinzel,57,Moselle,44,Grand Est
57519,OBERVISSE,57220,OBERVISSE,,49.1506436659,6.57888159109,519,,Obervisse,Obervisse,57,Moselle,44,Grand Est
57520,OBRECK,57170,OBRECK,,48.8514849093,6.59872976547,520,,Obreck,Obreck,57,Moselle,44,Grand Est
57521,OETING,57600,OETING,,49.172008781,6.90938897168,521,,Œting,Œting,57,Moselle,44,Grand Est
57523,OGY,57530,OGY,,49.1105499079,6.31565297053,523,,Ogy,Ogy,57,Moselle,44,Grand Est
57524,OMMERAY,57810,OMMERAY,,48.7181618127,6.68721833616,524,,Ommeray,Ommeray,57,Moselle,44,Grand Est
57525,ORIOCOURT,57590,ORIOCOURT,,48.8582626227,6.41317599769,525,,Oriocourt,Oriocourt,57,Moselle,44,Grand Est
57526,ORMERSVILLER,57720,ORMERSVILLER,,49.133604967,7.33481433142,526,,Ormersviller,Ormersviller,57,Moselle,44,Grand Est
57527,ORNY,57420,ORNY,,49.0303467553,6.24889661293,527,,Orny,Orny,57,Moselle,44,Grand Est
57528,ORON,57590,ORON,,48.901762397,6.48569334216,528,,Oron,Oron,57,Moselle,44,Grand Est
57529,OTTANGE,57840,OTTANGE,,49.437722146,6.00701265216,529,,Ottange,Ottange,57,Moselle,44,Grand Est
57530,OTTONVILLE,57220,OTTONVILLE,,49.2161203101,6.52231246205,530,,Ottonville,Ottonville,57,Moselle,44,Grand Est
57531,OUDRENNE,57970,OUDRENNE,,49.3702144479,6.34830971112,531,,Oudrenne,Oudrenne,57,Moselle,44,Grand Est
57532,PAGNY LES GOIN,57420,PAGNY LES GOIN,,48.9670452807,6.21991401987,532,,Pagny-lès-Goin,Pagny-lès-Goin,57,Moselle,44,Grand Est
57533,PANGE,57530,PANGE,,49.0816476726,6.35523146056,533,,Pange,Pange,57,Moselle,44,Grand Est
57534,PELTRE,57245,PELTRE,,49.072284463,6.22444313381,534,,Peltre,Peltre,57,Moselle,44,Grand Est
57535,PETIT REDERCHING,57410,PETIT REDERCHING,,49.054551428,7.31625533519,535,,Petit-Réderching,Petit-Réderching,57,Moselle,44,Grand Est
57536,PETIT TENQUIN,57660,PETIT TENQUIN,,48.9884543081,6.86904472208,536,,Petit-Tenquin,Petit-Tenquin,57,Moselle,44,Grand Est
57537,PETITE ROSSELLE,57540,PETITE ROSSELLE,,49.2095397191,6.85924244683,537,,Petite-Rosselle,Petite-Rosselle,57,Moselle,44,Grand Est
57538,PETTONCOURT,57170,PETTONCOURT,,48.7881263595,6.41106883774,538,,Pettoncourt,Pettoncourt,57,Moselle,44,Grand Est
57539,PEVANGE,57340,PEVANGE,,48.9080570122,6.62186068756,539,,Pévange,Pévange,57,Moselle,44,Grand Est
57540,PHALSBOURG,57370,PHALSBOURG,,48.7666597988,7.26359977418,540,,Phalsbourg,Phalsbourg,57,Moselle,44,Grand Est
57541,PHILIPPSBOURG,57230,PHILIPPSBOURG,,49.0028642083,7.56619934882,541,,Philippsbourg,Philippsbourg,57,Moselle,44,Grand Est
57542,PIBLANGE,57220,PIBLANGE,,49.2458851209,6.41209982643,542,,Piblange,Piblange,57,Moselle,44,Grand Est
57542,PIBLANGE,57220,PIBLANGE,ST BERNARD,49.2458851209,6.41209982643,542,,Piblange,Piblange,57,Moselle,44,Grand Est
57543,PIERREVILLERS,57120,PIERREVILLERS,,49.2255549058,6.09577655472,543,,Pierrevillers,Pierrevillers,57,Moselle,44,Grand Est
57544,PLAINE DE WALSCH,57870,PLAINE DE WALSCH,,48.6900524567,7.14681874191,544,,Plaine-de-Walsch,Plaine-de-Walsch,57,Moselle,44,Grand Est
57545,PLAPPEVILLE,57050,PLAPPEVILLE,,49.130840924,6.12422757212,545,,Plappeville,Plappeville,57,Moselle,44,Grand Est
57546,PLESNOIS,57140,PLESNOIS,,49.1709600596,6.11233794217,546,,Plesnois,Plesnois,57,Moselle,44,Grand Est
57547,POMMERIEUX,57420,POMMERIEUX,,48.9950226786,6.18256631128,547,,Pommérieux,Pommérieux,57,Moselle,44,Grand Est
57548,PONTOY,57420,PONTOY,,49.0162867909,6.29354972088,548,,Pontoy,Pontoy,57,Moselle,44,Grand Est
57549,PONTPIERRE,57380,PONTPIERRE,,49.0490255784,6.63804894747,549,,Pontpierre,Pontpierre,57,Moselle,44,Grand Est
57550,PORCELETTE,57890,PORCELETTE,,49.1603232212,6.66533625772,550,,Porcelette,Porcelette,57,Moselle,44,Grand Est
57551,POSTROFF,57930,POSTROFF,,48.8550589623,7.07472849238,551,,Postroff,Postroff,57,Moselle,44,Grand Est
57552,POUILLY,57420,POUILLY,,49.0549329608,6.19454629621,552,,Pouilly,Pouilly,57,Moselle,44,Grand Est
57553,POURNOY LA CHETIVE,57420,POURNOY LA CHETIVE,,49.0189061997,6.15388727432,553,,Pournoy-la-Chétive,Pournoy-la-Chétive,57,Moselle,44,Grand Est
57554,POURNOY LA GRASSE,57420,POURNOY LA GRASSE,,49.0149430341,6.20651616481,554,,Pournoy-la-Grasse,Pournoy-la-Grasse,57,Moselle,44,Grand Est
57555,PREVOCOURT,57590,PREVOCOURT,,48.926748986,6.4339887271,555,,Prévocourt,Prévocourt,57,Moselle,44,Grand Est
57556,PUTTELANGE AUX LACS,57510,PUTTELANGE AUX LACS,,49.0535036691,6.918414517,556,,Puttelange-aux-Lacs,Puttelange-aux-Lacs,57,Moselle,44,Grand Est
57557,PUTTELANGE LES THIONVILLE,57570,PUTTELANGE LES THIONVILLE,,49.4859599881,6.25676575434,557,,Puttelange-lès-Thionville,Puttelange-lès-Thionville,57,Moselle,44,Grand Est
57558,PUTTIGNY,57170,PUTTIGNY,,48.8438614892,6.54858910646,558,,Puttigny,Puttigny,57,Moselle,44,Grand Est
57559,PUZIEUX,57590,PUZIEUX,,48.8902553561,6.36255550089,559,,Puzieux,Puzieux,57,Moselle,44,Grand Est
57560,RACRANGE,57340,RACRANGE,,48.9251200829,6.67898076184,560,,Racrange,Racrange,57,Moselle,44,Grand Est
57561,RAHLING,57410,RAHLING,,48.9964768458,7.23869707911,561,,Rahling,Rahling,57,Moselle,44,Grand Est
57562,RANGUEVAUX,57700,RANGUEVAUX,,49.2956996055,6.05513226147,562,,Ranguevaux,Ranguevaux,57,Moselle,44,Grand Est
57563,RAVILLE,57530,RAVILLE,,49.0903409058,6.47750019939,563,,Raville,Raville,57,Moselle,44,Grand Est
57564,RECHICOURT LE CHATEAU,57810,RECHICOURT LE CHATEAU,,48.678407747,6.84276180766,564,,Réchicourt-le-Château,Réchicourt-le-Château,57,Moselle,44,Grand Est
57565,REDANGE,57390,REDANGE,,49.4918573457,5.91621437506,565,,Rédange,Rédange,57,Moselle,44,Grand Est
57566,REDING,57445,REDING,,48.7496589563,7.104580252,566,,Réding,Réding,57,Moselle,44,Grand Est
57567,REMELFANG,57320,REMELFANG,,49.268230209,6.51275682105,567,,Rémelfang,Rémelfang,57,Moselle,44,Grand Est
57568,REMELFING,57200,REMELFING,,49.089310055,7.08571672709,568,,Rémelfing,Rémelfing,57,Moselle,44,Grand Est
57569,REMELING,57480,REMELING,,49.413057321,6.48144808378,569,,Rémeling,Rémeling,57,Moselle,44,Grand Est
57570,REMERING,57550,REMERING,,49.2594876827,6.62884080525,570,,Rémering,Rémering,57,Moselle,44,Grand Est
57571,REMERING LES PUTTELANGE,57510,REMERING LES PUTTELANGE,,49.0326911576,6.93171898464,571,,Rémering-lès-Puttelange,Rémering-lès-Puttelange,57,Moselle,44,Grand Est
57572,REMILLY,57580,REMILLY,,49.0067187571,6.38923213224,572,,Rémilly,Rémilly,57,Moselle,44,Grand Est
57573,RENING,57670,RENING,,48.9496511765,6.8430392125,573,,Réning,Réning,57,Moselle,44,Grand Est
57574,BASSE RENTGEN,57570,BASSE RENTGEN,,49.4899417302,6.2028697524,574,,Basse-Rentgen,Basse-Rentgen,57,Moselle,44,Grand Est
57575,RETONFEY,57645,RETONFEY,,49.1332326497,6.31630111446,575,,Retonfey,Retonfey,57,Moselle,44,Grand Est
57576,RETTEL,57480,RETTEL,,49.4341736247,6.33743036901,576,,Rettel,Rettel,57,Moselle,44,Grand Est
57577,REYERSVILLER,57230,REYERSVILLER,,49.0389385883,7.39557012544,577,,Reyersviller,Reyersviller,57,Moselle,44,Grand Est
57578,REZONVILLE,57130,REZONVILLE,,49.0899933152,5.98385942866,578,,Rezonville,Rezonville,57,Moselle,44,Grand Est
57579,RHODES,57810,RHODES,,48.7558241125,6.90704140563,579,,Rhodes,Rhodes,57,Moselle,44,Grand Est
57580,RICHE,57340,RICHE,,48.8894955539,6.632474492,580,,Riche,Riche,57,Moselle,44,Grand Est
57581,RICHELING,57510,RICHELING,,49.0332778492,6.97379191847,581,,Richeling,Richeling,57,Moselle,44,Grand Est
57582,RICHEMONT,57270,RICHEMONT,,49.2844440368,6.16265276093,582,,Richemont,Richemont,57,Moselle,44,Grand Est
57583,RICHEVAL,57830,RICHEVAL,,48.6336692816,6.91235074827,583,,Richeval,Richeval,57,Moselle,44,Grand Est
57584,RIMLING,57720,RIMLING,,49.0931477366,7.25838170063,584,,Rimling,Rimling,57,Moselle,44,Grand Est
57585,RITZING,57480,RITZING,,49.4370053705,6.46359725844,585,,Ritzing,Ritzing,57,Moselle,44,Grand Est
57586,ROCHONVILLERS,57840,ROCHONVILLERS,,49.4096487557,6.02717195246,586,,Rochonvillers,Rochonvillers,57,Moselle,44,Grand Est
57587,RODALBE,57340,RODALBE,,48.9109394561,6.71447347935,587,,Rodalbe,Rodalbe,57,Moselle,44,Grand Est
57588,RODEMACK,57570,RODEMACK,,49.4637445576,6.24710550815,588,,Rodemack,Rodemack,57,Moselle,44,Grand Est
57589,ROHRBACH LES BITCHE,57410,ROHRBACH LES BITCHE,,49.0408635757,7.27733357043,589,,Rohrbach-lès-Bitche,Rohrbach-lès-Bitche,57,Moselle,44,Grand Est
57590,ROLBING,57720,ROLBING,,49.1723630415,7.42210286068,590,,Rolbing,Rolbing,57,Moselle,44,Grand Est
57591,ROMBAS,57120,ROMBAS,,49.2440664423,6.08820823406,591,,Rombas,Rombas,57,Moselle,44,Grand Est
57592,ROMELFING,57930,ROMELFING,,48.8339571573,7.00575615826,592,,Romelfing,Romelfing,57,Moselle,44,Grand Est
57593,RONCOURT,57860,RONCOURT,,49.2001585372,6.05186000716,593,,Roncourt,Roncourt,57,Moselle,44,Grand Est
57594,ROPPEVILLER,57230,ROPPEVILLER,,49.0895314895,7.5228410898,594,,Roppeviller,Roppeviller,57,Moselle,44,Grand Est
57595,RORBACH LES DIEUZE,57260,RORBACH LES DIEUZE,,48.8290007353,6.84003792776,595,,Rorbach-lès-Dieuze,Rorbach-lès-Dieuze,57,Moselle,44,Grand Est
57596,ROSBRUCK,57800,ROSBRUCK,,49.1593450312,6.85603413121,596,,Rosbruck,Rosbruck,57,Moselle,44,Grand Est
57597,ROSSELANGE,57780,ROSSELANGE,,49.2667090169,6.06105180621,597,,Rosselange,Rosselange,57,Moselle,44,Grand Est
57598,ROUHLING,57520,ROUHLING,,49.130927834,7.00317727676,598,,Rouhling,Rouhling,57,Moselle,44,Grand Est
57599,ROUPELDANGE,57220,ROUPELDANGE,,49.207356539,6.47248674843,599,,Roupeldange,Roupeldange,57,Moselle,44,Grand Est
57600,ROUSSY LE VILLAGE,57330,ROUSSY LE VILLAGE,,49.4605841152,6.17782499217,600,,Roussy-le-Village,Roussy-le-Village,57,Moselle,44,Grand Est
57601,ROZERIEULLES,57160,ROZERIEULLES,,49.1190149736,6.05282881166,601,,Rozérieulles,Rozérieulles,57,Moselle,44,Grand Est
57602,RURANGE LES THIONVILLE,57310,RURANGE LES THIONVILLE,,49.2703005799,6.23459173013,602,,Rurange-lès-Thionville,Rurange-lès-Thionville,57,Moselle,44,Grand Est
57603,RUSSANGE,57390,RUSSANGE,,49.4870922211,5.94585152871,603,,Russange,Russange,57,Moselle,44,Grand Est
57604,RUSTROFF,57480,RUSTROFF,,49.4414794169,6.37625718945,604,,Rustroff,Rustroff,57,Moselle,44,Grand Est
57605,SAILLY ACHATEL,57420,SAILLY ACHATEL,,48.9419543892,6.30208152793,605,,Sailly-Achâtel,Sailly-Achâtel,57,Moselle,44,Grand Est
57606,ST AVOLD,57500,ST AVOLD,,49.12845245,6.71233591282,606,,Saint-Avold,Saint-Avold,57,Moselle,44,Grand Est
57607,STE BARBE,57640,STE BARBE,,49.1628017297,6.31827843912,607,,Sainte-Barbe,Sainte-Barbe,57,Moselle,44,Grand Est
57609,ST EPVRE,57580,ST EPVRE,,48.9709676551,6.43286354316,609,,Saint-Epvre,Saint-Epvre,57,Moselle,44,Grand Est
57610,ST FRANCOIS LACROIX,57320,ST FRANCOIS LACROIX,,49.3461045283,6.44828141175,610,,Saint-François-Lacroix,Saint-François-Lacroix,57,Moselle,44,Grand Est
57611,ST GEORGES,57830,ST GEORGES,,48.6575343955,6.92704793516,611,,Saint-Georges,Saint-Georges,57,Moselle,44,Grand Est
57612,ST HUBERT,57640,ST HUBERT,,49.2309333716,6.36179933256,612,,Saint-Hubert,Saint-Hubert,57,Moselle,44,Grand Est
57613,ST JEAN DE BASSEL,57930,ST JEAN DE BASSEL,,48.8098303761,6.95819464193,613,,Saint-Jean-de-Bassel,Saint-Jean-de-Bassel,57,Moselle,44,Grand Est
57614,ST JEAN KOURTZERODE,57370,ST JEAN KOURTZERODE,,48.7572752471,7.19428583817,614,,Saint-Jean-Kourtzerode,Saint-Jean-Kourtzerode,57,Moselle,44,Grand Est
57615,ST JEAN ROHRBACH,57510,ST JEAN ROHRBACH,,49.026412081,6.87942935113,615,,Saint-Jean-Rohrbach,Saint-Jean-Rohrbach,57,Moselle,44,Grand Est
57616,ST JULIEN LES METZ,57070,ST JULIEN LES METZ,,49.1398497812,6.2087377953,616,,Saint-Julien-lès-Metz,Saint-Julien-lès-Metz,57,Moselle,44,Grand Est
57617,ST JURE,57420,ST JURE,,48.9431905301,6.23274904029,617,,Saint-Jure,Saint-Jure,57,Moselle,44,Grand Est
57618,ST LOUIS,57820,ST LOUIS,,48.710897697,7.1949734352,618,,Saint-Louis,Saint-Louis,57,Moselle,44,Grand Est
57619,ST LOUIS LES BITCHE,57620,ST LOUIS LES BITCHE,,48.9900389284,7.35271084125,619,,Saint-Louis-lès-Bitche,Saint-Louis-lès-Bitche,57,Moselle,44,Grand Est
57620,STE MARIE AUX CHENES,57255,STE MARIE AUX CHENES,,49.1914264698,6.0072170748,620,,Sainte-Marie-aux-Chênes,Sainte-Marie-aux-Chênes,57,Moselle,44,Grand Est
57621,ST MEDARD,57260,ST MEDARD,,48.8140487517,6.63779394361,621,,Saint-Médard,Saint-Médard,57,Moselle,44,Grand Est
57622,ST PRIVAT LA MONTAGNE,57855,ST PRIVAT LA MONTAGNE,,49.1857329152,6.04088915822,622,,Saint-Privat-la-Montagne,Saint-Privat-la-Montagne,57,Moselle,44,Grand Est
57623,ST QUIRIN,57560,ST QUIRIN,,48.5845453315,7.11411356549,623,,Saint-Quirin,Saint-Quirin,57,Moselle,44,Grand Est
57624,STE RUFFINE,57130,STE RUFFINE,,49.1065906287,6.09568579469,624,,Sainte-Ruffine,Sainte-Ruffine,57,Moselle,44,Grand Est
57625,SALONNES,57170,SALONNES,,48.7893695962,6.49332298181,625,,Salonnes,Salonnes,57,Moselle,44,Grand Est
57626,SANRY LES VIGY,57640,SANRY LES VIGY,,49.185187158,6.27924889026,626,,Sanry-lès-Vigy,Sanry-lès-Vigy,57,Moselle,44,Grand Est
57627,SANRY SUR NIED,57530,SANRY SUR NIED,,49.0537244163,6.34213009495,627,,Sanry-sur-Nied,Sanry-sur-Nied,57,Moselle,44,Grand Est
57628,SARRALBE,57430,SARRALBE,,48.9956964636,7.01158536668,628,,Sarralbe,Sarralbe,57,Moselle,44,Grand Est
57629,SARRALTROFF,57400,SARRALTROFF,,48.7748898738,7.06228392608,629,,Sarraltroff,Sarraltroff,57,Moselle,44,Grand Est
57630,SARREBOURG,57400,SARREBOURG,,48.7379661581,7.04683266422,630,,Sarrebourg,Sarrebourg,57,Moselle,44,Grand Est
57631,SARREGUEMINES,57200,SARREGUEMINES,,49.1088144102,7.06943100683,631,,Sarreguemines,Sarreguemines,57,Moselle,44,Grand Est
57631,SARREGUEMINES,57200,SARREGUEMINES,FOLPERSVILLER,49.1088144102,7.06943100683,631,,Sarreguemines,Sarreguemines,57,Moselle,44,Grand Est
57633,SARREINSMING,57905,SARREINSMING,,49.0924332105,7.11956483456,633,,Sarreinsming,Sarreinsming,57,Moselle,44,Grand Est
57634,SAULNY,57140,SAULNY,,49.1602800907,6.09502895041,634,,Saulny,Saulny,57,Moselle,44,Grand Est
57635,SCHALBACH,57370,SCHALBACH,,48.8211055124,7.15542835444,635,,Schalbach,Schalbach,57,Moselle,44,Grand Est
57636,SCHMITTVILLER,57412,SCHMITTVILLER,,49.0071560938,7.18605554531,636,,Schmittviller,Schmittviller,57,Moselle,44,Grand Est
57637,SCHNECKENBUSCH,57400,SCHNECKENBUSCH,,48.6959536942,7.08168211214,637,,Schneckenbusch,Schneckenbusch,57,Moselle,44,Grand Est
57638,SCHOENECK,57350,SCHOENECK,,49.2131743265,6.92043725941,638,,Schœneck,Schœneck,57,Moselle,44,Grand Est
57639,SCHORBACH,57230,SCHORBACH,,49.0806906445,7.40599666671,639,,Schorbach,Schorbach,57,Moselle,44,Grand Est
57640,SCHWERDORFF,57320,SCHWERDORFF,,49.3725901068,6.57210977292,640,,Schwerdorff,Schwerdorff,57,Moselle,44,Grand Est
57641,SCHWEYEN,57720,SCHWEYEN,,49.1601477497,7.39627940351,641,,Schweyen,Schweyen,57,Moselle,44,Grand Est
57642,SCY CHAZELLES,57160,SCY CHAZELLES,,49.1140417721,6.11984882666,642,,Scy-Chazelles,Scy-Chazelles,57,Moselle,44,Grand Est
57643,SECOURT,57420,SECOURT,,48.9485643363,6.27165031944,643,,Secourt,Secourt,57,Moselle,44,Grand Est
57644,SEINGBOUSE,57455,SEINGBOUSE,,49.1079438712,6.83222968228,644,,Seingbouse,Seingbouse,57,Moselle,44,Grand Est
57645,SEMECOURT,57280,SEMECOURT,,49.1970900836,6.13770154726,645,,Semécourt,Semécourt,57,Moselle,44,Grand Est
57647,SEREMANGE ERZANGE,57290,SEREMANGE ERZANGE,,49.322921611,6.09359436832,647,,Serémange-Erzange,Serémange-Erzange,57,Moselle,44,Grand Est
57648,SERVIGNY LES RAVILLE,57530,SERVIGNY LES RAVILLE,,49.0792585927,6.43935938,648,,Servigny-lès-Raville,Servigny-lès-Raville,57,Moselle,44,Grand Est
57649,SERVIGNY LES STE BARBE,57640,SERVIGNY LES STE BARBE,,49.1486719983,6.27473702344,649,,Servigny-lès-Sainte-Barbe,Servigny-lès-Sainte-Barbe,57,Moselle,44,Grand Est
57650,SIERCK LES BAINS,57480,SIERCK LES BAINS,,49.4367246307,6.35954877529,650,,Sierck-les-Bains,Sierck-les-Bains,57,Moselle,44,Grand Est
57651,SIERSTHAL,57410,SIERSTHAL,,49.0510946988,7.35614038462,651,,Siersthal,Siersthal,57,Moselle,44,Grand Est
57652,SILLEGNY,57420,SILLEGNY,,48.9871036218,6.14755818334,652,,Sillegny,Sillegny,57,Moselle,44,Grand Est
57653,SILLY EN SAULNOIS,57420,SILLY EN SAULNOIS,,48.9918643442,6.28019307812,653,,Silly-en-Saulnois,Silly-en-Saulnois,57,Moselle,44,Grand Est
57654,SILLY SUR NIED,57530,SILLY SUR NIED,,49.1225790957,6.36666848574,654,,Silly-sur-Nied,Silly-sur-Nied,57,Moselle,44,Grand Est
57655,SOLGNE,57420,SOLGNE,,48.9686599172,6.29890596956,655,,Solgne,Solgne,57,Moselle,44,Grand Est
57656,SORBEY,57580,SORBEY,,49.0397485789,6.31695459653,656,,Sorbey,Sorbey,57,Moselle,44,Grand Est
57657,SOTZELING,57170,SOTZELING,,48.8684821498,6.63703101651,657,,Sotzeling,Sotzeling,57,Moselle,44,Grand Est
57658,SOUCHT,57960,SOUCHT,,48.9616739785,7.31992410346,658,,Soucht,Soucht,57,Moselle,44,Grand Est
57659,SPICHEREN,57350,SPICHEREN,,49.1948583946,6.97044688686,659,,Spicheren,Spicheren,57,Moselle,44,Grand Est
57659,SPICHEREN,57350,SPICHEREN,LA BREME D OR,49.1948583946,6.97044688686,659,,Spicheren,Spicheren,57,Moselle,44,Grand Est
57660,STIRING WENDEL,57350,STIRING WENDEL,,49.2025325702,6.93271905155,660,,Stiring-Wendel,Stiring-Wendel,57,Moselle,44,Grand Est
57660,STIRING WENDEL,57350,STIRING WENDEL,HABSTERDICK,49.2025325702,6.93271905155,660,,Stiring-Wendel,Stiring-Wendel,57,Moselle,44,Grand Est
57660,STIRING WENDEL,57350,STIRING WENDEL,VERRERIE SOPHIE,49.2025325702,6.93271905155,660,,Stiring-Wendel,Stiring-Wendel,57,Moselle,44,Grand Est
57661,STURZELBRONN,57230,STURZELBRONN,,49.053346733,7.59397660337,661,,Sturzelbronn,Sturzelbronn,57,Moselle,44,Grand Est
57662,SUISSE,57340,SUISSE,,48.9667358817,6.57858533859,662,,Suisse,Suisse,57,Moselle,44,Grand Est
57663,TALANGE,57525,TALANGE,,49.2342630409,6.17206960901,663,,Talange,Talange,57,Moselle,44,Grand Est
57664,TARQUIMPOL,57260,TARQUIMPOL,,48.780910982,6.76220586082,664,,Tarquimpol,Tarquimpol,57,Moselle,44,Grand Est
57665,TENTELING,57980,TENTELING,,49.1250557723,6.92875440455,665,,Tenteling,Tenteling,57,Moselle,44,Grand Est
57666,TERVILLE,57180,TERVILLE,,49.3465483422,6.13220160083,666,,Terville,Terville,57,Moselle,44,Grand Est
57667,TETERCHEN,57220,TETERCHEN,,49.2301105568,6.5674968633,667,,Téterchen,Téterchen,57,Moselle,44,Grand Est
57668,TETING SUR NIED,57385,TETING SUR NIED,,49.0532700783,6.6696096112,668,,Teting-sur-Nied,Teting-sur-Nied,57,Moselle,44,Grand Est
57669,THEDING,57450,THEDING,,49.1284878197,6.89011082531,669,,Théding,Théding,57,Moselle,44,Grand Est
57670,THICOURT,57380,THICOURT,,48.9917121202,6.56000990403,670,,Thicourt,Thicourt,57,Moselle,44,Grand Est
57671,THIMONVILLE,57580,THIMONVILLE,,48.9446896286,6.39367353613,671,,Thimonville,Thimonville,57,Moselle,44,Grand Est
57672,THIONVILLE,57100,THIONVILLE,,49.3759716201,6.12928314217,672,,Thionville,Thionville,57,Moselle,44,Grand Est
57672,THIONVILLE,57100,THIONVILLE,GARCHE,49.3759716201,6.12928314217,672,,Thionville,Thionville,57,Moselle,44,Grand Est
57672,THIONVILLE,57100,THIONVILLE,KOEKING,49.3759716201,6.12928314217,672,,Thionville,Thionville,57,Moselle,44,Grand Est
57672,THIONVILLE,57100,THIONVILLE,OEUTRANGE,49.3759716201,6.12928314217,672,,Thionville,Thionville,57,Moselle,44,Grand Est
57673,THONVILLE,57380,THONVILLE,,48.9813587624,6.56673792931,673,,Thonville,Thonville,57,Moselle,44,Grand Est
57674,TINCRY,57590,TINCRY,,48.9079313189,6.40590872247,674,,Tincry,Tincry,57,Moselle,44,Grand Est
57675,TORCHEVILLE,57670,TORCHEVILLE,,48.90519391,6.84835199777,675,,Torcheville,Torcheville,57,Moselle,44,Grand Est
57676,TRAGNY,57580,TRAGNY,,48.9564904348,6.37332375552,676,,Tragny,Tragny,57,Moselle,44,Grand Est
57677,TREMERY,57300,TREMERY,,49.2538335062,6.24271493427,677,,Trémery,Trémery,57,Moselle,44,Grand Est
57678,TRESSANGE,57710,TRESSANGE,,49.4096807012,5.98237050725,678,,Tressange,Tressange,57,Moselle,44,Grand Est
57678,TRESSANGE,57710,TRESSANGE,BURE,49.4096807012,5.98237050725,678,,Tressange,Tressange,57,Moselle,44,Grand Est
57679,TRITTELING REDLACH,57385,TRITTELING REDLACH,,49.0775355368,6.616775943,679,,Tritteling-Redlach,Tritteling-Redlach,57,Moselle,44,Grand Est
57680,TROISFONTAINES,57870,TROISFONTAINES,,48.6710669896,7.12727960239,680,,Troisfontaines,Troisfontaines,57,Moselle,44,Grand Est
57680,TROISFONTAINES,57870,TROISFONTAINES,VALLERYSTHAL,48.6710669896,7.12727960239,680,,Troisfontaines,Troisfontaines,57,Moselle,44,Grand Est
57681,TROMBORN,57320,TROMBORN,,49.257002543,6.5958597076,681,,Tromborn,Tromborn,57,Moselle,44,Grand Est
57682,TURQUESTEIN BLANCRUPT,57560,TURQUESTEIN BLANCRUPT,,48.5598070165,7.08686988771,682,,Turquestein-Blancrupt,Turquestein-Blancrupt,57,Moselle,44,Grand Est
57683,UCKANGE,57270,UCKANGE,,49.3058767055,6.14931216735,683,,Uckange,Uckange,57,Moselle,44,Grand Est
57684,VAHL EBERSING,57660,VAHL EBERSING,,49.0526763578,6.74843540034,684,,Vahl-Ebersing,Vahl-Ebersing,57,Moselle,44,Grand Est
57685,VAHL LES BENESTROFF,57670,VAHL LES BENESTROFF,,48.9192974745,6.78723850274,685,,Vahl-lès-Bénestroff,Vahl-lès-Bénestroff,57,Moselle,44,Grand Est
57686,VAHL LES FAULQUEMONT,57380,VAHL LES FAULQUEMONT,,49.0220687137,6.63107150458,686,,Vahl-lès-Faulquemont,Vahl-lès-Faulquemont,57,Moselle,44,Grand Est
57687,VALLERANGE,57340,VALLERANGE,,48.9502611982,6.68677832719,687,,Vallerange,Vallerange,57,Moselle,44,Grand Est
57689,VALMESTROFF,57970,VALMESTROFF,,49.3583304457,6.26235164313,689,,Valmestroff,Valmestroff,57,Moselle,44,Grand Est
57690,VALMONT,57730,VALMONT,,49.0843033887,6.70254231837,690,,Valmont,Valmont,57,Moselle,44,Grand Est
57691,VALMUNSTER,57220,VALMUNSTER,,49.2475100945,6.51684193459,691,,Valmunster,Valmunster,57,Moselle,44,Grand Est
57692,VANNECOURT,57340,VANNECOURT,,48.87799465,6.53511277921,692,,Vannecourt,Vannecourt,57,Moselle,44,Grand Est
57693,VANTOUX,57070,VANTOUX,,49.1323231811,6.233426984,693,,Vantoux,Vantoux,57,Moselle,44,Grand Est
57694,VANY,57070,VANY,,49.1526090428,6.24135072121,694,,Vany,Vany,57,Moselle,44,Grand Est
57695,VARIZE,57220,VARIZE,,49.1254107578,6.44934014942,695,,Varize-Vaudoncourt,Varize-Vaudoncourt,57,Moselle,44,Grand Est
57695,VARIZE,57220,VARIZE,VAUDONCOURT,49.1254107578,6.44934014942,695,,Varize-Vaudoncourt,Varize-Vaudoncourt,57,Moselle,44,Grand Est
57696,VARSBERG,57880,VARSBERG,,49.1748380196,6.61893587601,696,,Varsberg,Varsberg,57,Moselle,44,Grand Est
57697,VASPERVILLER,57560,VASPERVILLER,,48.6352577375,7.07124711805,697,,Vasperviller,Vasperviller,57,Moselle,44,Grand Est
57698,VATIMONT,57580,VATIMONT,,48.9782586379,6.4742819411,698,,Vatimont,Vatimont,57,Moselle,44,Grand Est
57700,VAUDRECHING,57320,VAUDRECHING,,49.2762381448,6.52500672927,700,,Vaudreching,Vaudreching,57,Moselle,44,Grand Est
57701,VAUX,57130,VAUX,,49.093212654,6.06911259068,701,,Vaux,Vaux,57,Moselle,44,Grand Est
57702,VAXY,57170,VAXY,,48.8590225328,6.52663158102,702,,Vaxy,Vaxy,57,Moselle,44,Grand Est
57703,VECKERSVILLER,57370,VECKERSVILLER,,48.8297509643,7.18632776564,703,,Veckersviller,Veckersviller,57,Moselle,44,Grand Est
57704,VECKRING,57920,VECKRING,,49.3355810386,6.36306382549,704,,Veckring,Veckring,57,Moselle,44,Grand Est
57705,VELVING,57220,VELVING,,49.2419971851,6.53888039354,705,,Velving,Velving,57,Moselle,44,Grand Est
57706,VERGAVILLE,57260,VERGAVILLE,,48.8408430132,6.74607225475,706,,Vergaville,Vergaville,57,Moselle,44,Grand Est
57707,VERNEVILLE,57130,VERNEVILLE,,49.1377096481,6.00708886544,707,,Vernéville,Vernéville,57,Moselle,44,Grand Est
57708,VERNY,57420,VERNY,,49.0087164059,6.20170510603,708,,Verny,Verny,57,Moselle,44,Grand Est
57709,VESCHEIM,57370,VESCHEIM,,48.7943463225,7.22591032153,709,,Vescheim,Vescheim,57,Moselle,44,Grand Est
57711,VIBERSVILLER,57670,VIBERSVILLER,,48.9054943096,6.94495240127,711,,Vibersviller,Vibersviller,57,Moselle,44,Grand Est
57712,VIC SUR SEILLE,57630,VIC SUR SEILLE,,48.7749870403,6.52757815472,712,,Vic-sur-Seille,Vic-sur-Seille,57,Moselle,44,Grand Est
57713,VIEUX LIXHEIM,57635,VIEUX LIXHEIM,,48.7801701567,7.12597432049,713,,Vieux-Lixheim,Vieux-Lixheim,57,Moselle,44,Grand Est
57714,HAUTE VIGNEULLES,57690,HAUTE VIGNEULLES,,49.0954763193,6.55643389665,714,,Haute-Vigneulles,Haute-Vigneulles,57,Moselle,44,Grand Est
57715,VIGNY,57420,VIGNY,,48.9683757899,6.25329228193,715,,Vigny,Vigny,57,Moselle,44,Grand Est
57716,VIGY,57640,VIGY,,49.2080435062,6.3068125723,716,,Vigy,Vigy,57,Moselle,44,Grand Est
57717,VILLER,57340,VILLER,,48.9884059966,6.64577215725,717,,Viller,Viller,57,Moselle,44,Grand Est
57718,VILLERS STONCOURT,57530,VILLERS STONCOURT,,49.05755169,6.44110870664,718,,Villers-Stoncourt,Villers-Stoncourt,57,Moselle,44,Grand Est
57719,VILLERS SUR NIED,57340,VILLERS SUR NIED,,48.9262387667,6.52816467528,719,,Villers-sur-Nied,Villers-sur-Nied,57,Moselle,44,Grand Est
57720,VILLING,57550,VILLING,,49.2816215974,6.63107602679,720,,Villing,Villing,57,Moselle,44,Grand Est
57721,VILSBERG,57370,VILSBERG,,48.79004649,7.26022732288,721,,Vilsberg,Vilsberg,57,Moselle,44,Grand Est
57722,VIONVILLE,57130,VIONVILLE,,49.0909489678,5.9468673832,722,,Vionville,Vionville,57,Moselle,44,Grand Est
57723,VIRMING,57340,VIRMING,,48.9424365099,6.7521262958,723,,Virming,Virming,57,Moselle,44,Grand Est
57724,VITRY SUR ORNE,57185,VITRY SUR ORNE,,49.2766034862,6.09532955616,724,,Vitry-sur-Orne,Vitry-sur-Orne,57,Moselle,44,Grand Est
57725,VITTERSBOURG,57670,VITTERSBOURG,,48.9483985965,6.93045004386,725,,Vittersbourg,Vittersbourg,57,Moselle,44,Grand Est
57726,VITTONCOURT,57580,VITTONCOURT,,49.0215965813,6.45567929548,726,,Vittoncourt,Vittoncourt,57,Moselle,44,Grand Est
57727,VIVIERS,57590,VIVIERS,,48.8939817383,6.43988945822,727,,Viviers,Viviers,57,Moselle,44,Grand Est
57728,VOIMHAUT,57580,VOIMHAUT,,49.026429553,6.4205093614,728,,Voimhaut,Voimhaut,57,Moselle,44,Grand Est
57730,VOLMERANGE LES BOULAY,57220,VOLMERANGE LES BOULAY,,49.1701692039,6.44955385962,730,,Volmerange-lès-Boulay,Volmerange-lès-Boulay,57,Moselle,44,Grand Est
57731,VOLMERANGE LES MINES,57330,VOLMERANGE LES MINES,,49.4457659624,6.06722602054,731,,Volmerange-les-Mines,Volmerange-les-Mines,57,Moselle,44,Grand Est
57732,VOLMUNSTER,57720,VOLMUNSTER,,49.1202720542,7.36590048312,732,,Volmunster,Volmunster,57,Moselle,44,Grand Est
57733,VOLSTROFF,57940,VOLSTROFF,,49.3014063896,6.25187794488,733,,Volstroff,Volstroff,57,Moselle,44,Grand Est
57734,VOYER,57560,VOYER,,48.6522218701,7.08937064886,734,,Voyer,Voyer,57,Moselle,44,Grand Est
57736,VRY,57640,VRY,,49.1924424841,6.35664506917,736,,Vry,Vry,57,Moselle,44,Grand Est
57737,VULMONT,57420,VULMONT,,48.925709781,6.30508801318,737,,Vulmont,Vulmont,57,Moselle,44,Grand Est
57738,WALDHOUSE,57720,WALDHOUSE,,49.1394116711,7.45605227676,738,,Waldhouse,Waldhouse,57,Moselle,44,Grand Est
57739,WALDWEISTROFF,57320,WALDWEISTROFF,,49.3575723655,6.49576337503,739,,Waldweistroff,Waldweistroff,57,Moselle,44,Grand Est
57740,WALDWISSE,57480,WALDWISSE,,49.4143532699,6.52258139522,740,,Waldwisse,Waldwisse,57,Moselle,44,Grand Est
57741,WALSCHBRONN,57720,WALSCHBRONN,,49.154023233,7.47376788664,741,,Walschbronn,Walschbronn,57,Moselle,44,Grand Est
57742,WALSCHEID,57870,WALSCHEID,,48.6198274157,7.18861685724,742,,Walscheid,Walscheid,57,Moselle,44,Grand Est
57743,WALTEMBOURG,57370,WALTEMBOURG,,48.7492194101,7.19586695343,743,,Waltembourg,Waltembourg,57,Moselle,44,Grand Est
57745,WIESVILLER,57200,WIESVILLER,,49.0772822429,7.16122161095,745,,Wiesviller,Wiesviller,57,Moselle,44,Grand Est
57746,WILLERWALD,57430,WILLERWALD,,49.0244559247,7.03682413781,746,,Willerwald,Willerwald,57,Moselle,44,Grand Est
57747,WINTERSBOURG,57635,WINTERSBOURG,,48.7918074055,7.19082900649,747,,Wintersbourg,Wintersbourg,57,Moselle,44,Grand Est
57748,WITTRING,57905,WITTRING,,49.0532147787,7.13000666752,748,,Wittring,Wittring,57,Moselle,44,Grand Est
57749,VOELFLING LES BOUZONVILLE,57320,VOELFLING LES BOUZONVILLE,,49.2929378044,6.60527377943,749,,Vœlfling-lès-Bouzonville,Vœlfling-lès-Bouzonville,57,Moselle,44,Grand Est
57750,WOELFLING LES SARREGUEMINES,57200,WOELFLING LES SARREGUEMINES,,49.0853812881,7.18464267698,750,,Wœlfling-lès-Sarreguemines,Wœlfling-lès-Sarreguemines,57,Moselle,44,Grand Est
57751,WOIPPY,57140,WOIPPY,,49.1638098271,6.15770213219,751,,Woippy,Woippy,57,Moselle,44,Grand Est
57752,WOUSTVILLER,57915,WOUSTVILLER,,49.0785622468,7.0055640876,752,,Woustviller,Woustviller,57,Moselle,44,Grand Est
57753,WUISSE,57170,WUISSE,,48.8548708568,6.66842054976,753,,Wuisse,Wuisse,57,Moselle,44,Grand Est
57754,XANREY,57630,XANREY,,48.7510168445,6.5874192208,754,,Xanrey,Xanrey,57,Moselle,44,Grand Est
57755,XOCOURT,57590,XOCOURT,,48.9097711268,6.37642709292,755,,Xocourt,Xocourt,57,Moselle,44,Grand Est
57756,XOUAXANGE,57830,XOUAXANGE,,48.6922407747,6.99262688966,756,,Xouaxange,Xouaxange,57,Moselle,44,Grand Est
57757,YUTZ,57970,YUTZ,,49.3517848092,6.20171929319,757,,Yutz,Yutz,57,Moselle,44,Grand Est
57759,ZARBELING,57340,ZARBELING,,48.8901000277,6.68836654162,759,,Zarbeling,Zarbeling,57,Moselle,44,Grand Est
57760,ZETTING,57905,ZETTING,,49.073370472,7.12604029604,760,,Zetting,Zetting,57,Moselle,44,Grand Est
57761,ZILLING,57370,ZILLING,,48.7867266822,7.21030213577,761,,Zilling,Zilling,57,Moselle,44,Grand Est
57762,ZIMMING,57690,ZIMMING,,49.1301980522,6.58678160982,762,,Zimming,Zimming,57,Moselle,44,Grand Est
57763,ZOMMANGE,57260,ZOMMANGE,,48.8223763728,6.8005971224,763,,Zommange,Zommange,57,Moselle,44,Grand Est
57764,ZOUFFTGEN,57330,ZOUFFTGEN,,49.4658864649,6.13504143605,764,,Zoufftgen,Zoufftgen,57,Moselle,44,Grand Est
57765,DIESEN,57890,DIESEN,,49.1755911262,6.69315944055,765,,Diesen,Diesen,57,Moselle,44,Grand Est
57767,STUCKANGE,57970,STUCKANGE,,49.3240150186,6.23226190689,767,,Stuckange,Stuckange,57,Moselle,44,Grand Est
58001,ACHUN,58110,ACHUN,,47.1296847784,3.66418782377,1,,Achun,Achun,58,Nièvre,27,Bourgogne-Franche-Comté
58002,ALLIGNY COSNE,58200,ALLIGNY COSNE,,47.4435756978,3.07325894698,2,,Alligny-Cosne,Alligny-Cosne,58,Nièvre,27,Bourgogne-Franche-Comté
58003,ALLIGNY EN MORVAN,58230,ALLIGNY EN MORVAN,,47.2097168429,4.17338633235,3,,Alligny-en-Morvan,Alligny-en-Morvan,58,Nièvre,27,Bourgogne-Franche-Comté
58004,ALLUY,58110,ALLUY,,47.0338777551,3.63261778008,4,,Alluy,Alluy,58,Nièvre,27,Bourgogne-Franche-Comté
58005,AMAZY,58190,AMAZY,,47.3830839935,3.56104962023,5,,Amazy,Amazy,58,Nièvre,27,Bourgogne-Franche-Comté
58006,ANLEZY,58270,ANLEZY,,46.966601859,3.49853718093,6,,Anlezy,Anlezy,58,Nièvre,27,Bourgogne-Franche-Comté
58007,ANNAY,58450,ANNAY,,47.5308551054,2.94137018332,7,,Annay,Annay,58,Nièvre,27,Bourgogne-Franche-Comté
58008,ANTHIEN,58800,ANTHIEN,,47.3090555536,3.72507845013,8,,Anthien,Anthien,58,Nièvre,27,Bourgogne-Franche-Comté
58009,ARBOURSE,58350,ARBOURSE,,47.2487922642,3.2239933188,9,,Arbourse,Arbourse,58,Nièvre,27,Bourgogne-Franche-Comté
58010,ARLEUF,58430,ARLEUF,,47.0404133279,4.01572442394,10,,Arleuf,Arleuf,58,Nièvre,27,Bourgogne-Franche-Comté
58011,ARMES,58500,ARMES,,47.4629608534,3.562292348,11,,Armes,Armes,58,Nièvre,27,Bourgogne-Franche-Comté
58012,ARQUIAN,58310,ARQUIAN,,47.5349426855,2.99793071135,12,,Arquian,Arquian,58,Nièvre,27,Bourgogne-Franche-Comté
58013,ARTHEL,58700,ARTHEL,,47.2439939183,3.40639275395,13,,Arthel,Arthel,58,Nièvre,27,Bourgogne-Franche-Comté
58014,ARZEMBOUY,58700,ARZEMBOUY,,47.2468634111,3.35980909728,14,,Arzembouy,Arzembouy,58,Nièvre,27,Bourgogne-Franche-Comté
58015,ASNAN,58420,ASNAN,,47.31031619,3.55239408391,15,,Asnan,Asnan,58,Nièvre,27,Bourgogne-Franche-Comté
58016,ASNOIS,58190,ASNOIS,,47.3975694255,3.5877353754,16,,Asnois,Asnois,58,Nièvre,27,Bourgogne-Franche-Comté
58017,AUNAY EN BAZOIS,58110,AUNAY EN BAZOIS,,47.124295853,3.71965717123,17,,Aunay-en-Bazois,Aunay-en-Bazois,58,Nièvre,27,Bourgogne-Franche-Comté
58018,AUTHIOU,58700,AUTHIOU,,47.2653242927,3.41891982538,18,,Authiou,Authiou,58,Nièvre,27,Bourgogne-Franche-Comté
58019,AVREE,58170,AVREE,,46.8229493433,3.87907755172,19,,Avrée,Avrée,58,Nièvre,27,Bourgogne-Franche-Comté
58020,AVRIL SUR LOIRE,58300,AVRIL SUR LOIRE,,46.8084334305,3.36209640132,20,,Avril-sur-Loire,Avril-sur-Loire,58,Nièvre,27,Bourgogne-Franche-Comté
58021,AZY LE VIF,58240,AZY LE VIF,,46.7880140939,3.22978809356,21,,Azy-le-Vif,Azy-le-Vif,58,Nièvre,27,Bourgogne-Franche-Comté
58022,BALLERAY,58130,BALLERAY,,47.0879110029,3.27769579826,22,,Balleray,Balleray,58,Nièvre,27,Bourgogne-Franche-Comté
58023,BAZOCHES,58190,BAZOCHES,,47.368858753,3.79377524452,23,,Bazoches,Bazoches,58,Nièvre,27,Bourgogne-Franche-Comté
58024,BAZOLLES,58110,BAZOLLES,,47.1455832324,3.60825350452,24,,Bazolles,Bazolles,58,Nièvre,27,Bourgogne-Franche-Comté
58024,BAZOLLES,58110,BAZOLLES,BAYE,47.1455832324,3.60825350452,24,,Bazolles,Bazolles,58,Nièvre,27,Bourgogne-Franche-Comté
58025,BEARD,58160,BEARD,,46.8687338106,3.3263533543,25,,Béard,Béard,58,Nièvre,27,Bourgogne-Franche-Comté
58026,BEAULIEU,58420,BEAULIEU,,47.2523686472,3.52425874416,26,,Beaulieu,Beaulieu,58,Nièvre,27,Bourgogne-Franche-Comté
58026,BEAULIEU,58420,BEAULIEU,DOMPIERRE SUR HERY,47.2523686472,3.52425874416,26,,Beaulieu,Beaulieu,58,Nièvre,27,Bourgogne-Franche-Comté
58026,BEAULIEU,58420,BEAULIEU,MICHAUGUES,47.2523686472,3.52425874416,26,,Beaulieu,Beaulieu,58,Nièvre,27,Bourgogne-Franche-Comté
58027,BEAUMONT LA FERRIERE,58700,BEAUMONT LA FERRIERE,,47.1832698667,3.23544438526,27,,Beaumont-la-Ferrière,Beaumont-la-Ferrière,58,Nièvre,27,Bourgogne-Franche-Comté
58028,BEAUMONT SARDOLLES,58270,BEAUMONT SARDOLLES,,46.9351521565,3.39695451595,28,,Beaumont-Sardolles,Beaumont-Sardolles,58,Nièvre,27,Bourgogne-Franche-Comté
58029,BEUVRON,58210,BEUVRON,,47.3507345394,3.49740731618,29,,Beuvron,Beuvron,58,Nièvre,27,Bourgogne-Franche-Comté
58030,BICHES,58110,BICHES,,46.9936912156,3.65454091933,30,,Biches,Biches,58,Nièvre,27,Bourgogne-Franche-Comté
58031,BILLY CHEVANNES,58270,BILLY CHEVANNES,,47.0121989226,3.46179486419,31,,Billy-Chevannes,Billy-Chevannes,58,Nièvre,27,Bourgogne-Franche-Comté
58032,BILLY SUR OISY,58500,BILLY SUR OISY,,47.4843005161,3.40178360674,32,,Billy-sur-Oisy,Billy-sur-Oisy,58,Nièvre,27,Bourgogne-Franche-Comté
58033,BITRY,58310,BITRY,,47.4936543218,3.08467845903,33,,Bitry,Bitry,58,Nièvre,27,Bourgogne-Franche-Comté
58034,BLISMES,58120,BLISMES,,47.1229051277,3.82149809413,34,,Blismes,Blismes,58,Nièvre,27,Bourgogne-Franche-Comté
58035,BONA,58330,BONA,,47.0648311481,3.42596350946,35,,Bona,Bona,58,Nièvre,27,Bourgogne-Franche-Comté
58036,BOUHY,58310,BOUHY,,47.4871286567,3.18343987019,36,,Bouhy,Bouhy,58,Nièvre,27,Bourgogne-Franche-Comté
58037,BRASSY,58140,BRASSY,,47.2637724452,3.93404612826,37,,Brassy,Brassy,58,Nièvre,27,Bourgogne-Franche-Comté
58038,BREUGNON,58460,BREUGNON,,47.4272649445,3.45776279567,38,,Breugnon,Breugnon,58,Nièvre,27,Bourgogne-Franche-Comté
58039,BREVES,58530,BREVES,,47.430617501,3.62503798759,39,,Brèves,Brèves,58,Nièvre,27,Bourgogne-Franche-Comté
58040,BRINAY,58110,BRINAY,,47.0139290387,3.70701792001,40,,Brinay,Brinay,58,Nièvre,27,Bourgogne-Franche-Comté
58041,BRINON SUR BEUVRON,58420,BRINON SUR BEUVRON,,47.2843863072,3.49020583859,41,,Brinon-sur-Beuvron,Brinon-sur-Beuvron,58,Nièvre,27,Bourgogne-Franche-Comté
58042,BULCY,58400,BULCY,,47.2416583489,3.02883533035,42,,Bulcy,Bulcy,58,Nièvre,27,Bourgogne-Franche-Comté
58043,BUSSY LA PESLE,58420,BUSSY LA PESLE,,47.2550240135,3.47467766034,43,,Bussy-la-Pesle,Bussy-la-Pesle,58,Nièvre,27,Bourgogne-Franche-Comté
58044,LA CELLE SUR LOIRE,58440,LA CELLE SUR LOIRE,,47.4852979363,2.93434517824,44,La,Celle-sur-Loire,La Celle-sur-Loire,58,Nièvre,27,Bourgogne-Franche-Comté
58045,LA CELLE SUR NIEVRE,58700,LA CELLE SUR NIEVRE,,47.218624699,3.20226089241,45,La,Celle-sur-Nièvre,La Celle-sur-Nièvre,58,Nièvre,27,Bourgogne-Franche-Comté
58046,CERCY LA TOUR,58340,CERCY LA TOUR,,46.8494214757,3.64592375221,46,,Cercy-la-Tour,Cercy-la-Tour,58,Nièvre,27,Bourgogne-Franche-Comté
58047,CERVON,58800,CERVON,,47.2396751916,3.75219456322,47,,Cervon,Cervon,58,Nièvre,27,Bourgogne-Franche-Comté
58048,CESSY LES BOIS,58220,CESSY LES BOIS,,47.3318627577,3.21619121558,48,,Cessy-les-Bois,Cessy-les-Bois,58,Nièvre,27,Bourgogne-Franche-Comté
58049,CHALAUX,58140,CHALAUX,,47.3330120045,3.91270768034,49,,Chalaux,Chalaux,58,Nièvre,27,Bourgogne-Franche-Comté
58050,CHALLEMENT,58420,CHALLEMENT,,47.3130719582,3.59365043297,50,,Challement,Challement,58,Nièvre,27,Bourgogne-Franche-Comté
58051,CHALLUY,58000,CHALLUY,,46.9425745079,3.14061478033,51,,Challuy,Challuy,58,Nièvre,27,Bourgogne-Franche-Comté
58051,CHALLUY,58000,CHALLUY,PLAGNY,46.9425745079,3.14061478033,51,,Challuy,Challuy,58,Nièvre,27,Bourgogne-Franche-Comté
58052,CHAMPALLEMENT,58420,CHAMPALLEMENT,,47.2284006437,3.48161378948,52,,Champallement,Champallement,58,Nièvre,27,Bourgogne-Franche-Comté
58053,CHAMPLEMY,58210,CHAMPLEMY,,47.2852072744,3.33527169936,53,,Champlemy,Champlemy,58,Nièvre,27,Bourgogne-Franche-Comté
58054,CHAMPLIN,58700,CHAMPLIN,,47.2447063381,3.45044284941,54,,Champlin,Champlin,58,Nièvre,27,Bourgogne-Franche-Comté
58055,CHAMPVERT,58300,CHAMPVERT,,46.8530454461,3.52326215969,55,,Champvert,Champvert,58,Nièvre,27,Bourgogne-Franche-Comté
58056,CHAMPVOUX,58400,CHAMPVOUX,,47.1440153043,3.07361379775,56,,Champvoux,Champvoux,58,Nièvre,27,Bourgogne-Franche-Comté
58057,CHANTENAY ST IMBERT,58240,CHANTENAY ST IMBERT,,46.7394357002,3.17150423035,57,,Chantenay-Saint-Imbert,Chantenay-Saint-Imbert,58,Nièvre,27,Bourgogne-Franche-Comté
58057,CHANTENAY ST IMBERT,58240,CHANTENAY ST IMBERT,ST IMBERT,46.7394357002,3.17150423035,57,,Chantenay-Saint-Imbert,Chantenay-Saint-Imbert,58,Nièvre,27,Bourgogne-Franche-Comté
58058,LA CHAPELLE ST ANDRE,58210,LA CHAPELLE ST ANDRE,,47.4008356839,3.32446330279,58,La,Chapelle-Saint-André,La Chapelle-Saint-André,58,Nièvre,27,Bourgogne-Franche-Comté
58059,LA CHARITE SUR LOIRE,58400,LA CHARITE SUR LOIRE,,47.1835862006,3.02885515594,59,La,Charité-sur-Loire,La Charité-sur-Loire,58,Nièvre,27,Bourgogne-Franche-Comté
58060,CHARRIN,58300,CHARRIN,,46.7983120336,3.5962141687,60,,Charrin,Charrin,58,Nièvre,27,Bourgogne-Franche-Comté
58061,CHASNAY,58350,CHASNAY,,47.2389608861,3.17151179049,61,,Chasnay,Chasnay,58,Nièvre,27,Bourgogne-Franche-Comté
58062,CHATEAU CHINON VILLE,58120,CHATEAU CHINON,,47.0626511564,3.92696760176,62,,Château-Chinon Ville,Château-Chinon Ville,58,Nièvre,27,Bourgogne-Franche-Comté
58063,CHATEAU CHINON CAMPAGNE,58120,CHATEAU CHINON CAMPAGNE,,47.056080506,3.9410750203,63,,Château-Chinon Campagne,Château-Chinon Campagne,58,Nièvre,27,Bourgogne-Franche-Comté
58064,CHATEAUNEUF VAL DE BARGIS,58350,CHATEAUNEUF VAL DE BARGIS,,47.2837806445,3.21656972159,64,,Châteauneuf-Val-de-Bargis,Châteauneuf-Val-de-Bargis,58,Nièvre,27,Bourgogne-Franche-Comté
58065,CHATILLON EN BAZOIS,58110,CHATILLON EN BAZOIS,,47.0557553811,3.65816113346,65,,Châtillon-en-Bazois,Châtillon-en-Bazois,58,Nièvre,27,Bourgogne-Franche-Comté
58066,CHATIN,58120,CHATIN,,47.1029949815,3.88309542741,66,,Châtin,Châtin,58,Nièvre,27,Bourgogne-Franche-Comté
58067,CHAULGNES,58400,CHAULGNES,,47.1290872121,3.10710953083,67,,Chaulgnes,Chaulgnes,58,Nièvre,27,Bourgogne-Franche-Comté
58068,CHAUMARD,58120,CHAUMARD,,47.1456786521,3.91332080752,68,,Chaumard,Chaumard,58,Nièvre,27,Bourgogne-Franche-Comté
58069,CHAUMOT,58800,CHAUMOT,,47.251942409,3.64281891552,69,,Chaumot,Chaumot,58,Nièvre,27,Bourgogne-Franche-Comté
58070,CHAZEUIL,58700,CHAZEUIL,,47.2780823598,3.40392511236,70,,Chazeuil,Chazeuil,58,Nièvre,27,Bourgogne-Franche-Comté
58071,CHEVANNES CHANGY,58420,CHEVANNES CHANGY,,47.2909006199,3.4529391081,71,,Chevannes-Changy,Chevannes-Changy,58,Nièvre,27,Bourgogne-Franche-Comté
58072,CHEVENON,58160,CHEVENON,,46.9145671045,3.23010283481,72,,Chevenon,Chevenon,58,Nièvre,27,Bourgogne-Franche-Comté
58073,CHEVROCHES,58500,CHEVROCHES,,47.4447632015,3.54472363708,73,,Chevroches,Chevroches,58,Nièvre,27,Bourgogne-Franche-Comté
58074,CHIDDES,58170,CHIDDES,,46.8621669432,3.92954270868,74,,Chiddes,Chiddes,58,Nièvre,27,Bourgogne-Franche-Comté
58075,CHITRY LES MINES,58800,CHITRY LES MINES,,47.27098105,3.66227184049,75,,Chitry-les-Mines,Chitry-les-Mines,58,Nièvre,27,Bourgogne-Franche-Comté
58076,CHOUGNY,58110,CHOUGNY,,47.0896123144,3.7576422765,76,,Chougny,Chougny,58,Nièvre,27,Bourgogne-Franche-Comté
58077,CIEZ,58220,CIEZ,,47.440576466,3.16319899612,77,,Ciez,Ciez,58,Nièvre,27,Bourgogne-Franche-Comté
58078,CIZELY,58270,CIZELY,,46.9834200347,3.46889795447,78,,Cizely,Cizely,58,Nièvre,27,Bourgogne-Franche-Comté
58079,CLAMECY,58500,CLAMECY,,47.4610140738,3.51008131187,79,,Clamecy,Clamecy,58,Nièvre,27,Bourgogne-Franche-Comté
58079,CLAMECY,58500,CLAMECY,BEAUGY,47.4610140738,3.51008131187,79,,Clamecy,Clamecy,58,Nièvre,27,Bourgogne-Franche-Comté
58079,CLAMECY,58500,CLAMECY,MOULOT,47.4610140738,3.51008131187,79,,Clamecy,Clamecy,58,Nièvre,27,Bourgogne-Franche-Comté
58080,LA COLLANCELLE,58800,LA COLLANCELLE,,47.1806919215,3.64662117712,80,La,Collancelle,La Collancelle,58,Nièvre,27,Bourgogne-Franche-Comté
58081,COLMERY,58350,COLMERY,,47.348832741,3.24694851198,81,,Colméry,Colméry,58,Nièvre,27,Bourgogne-Franche-Comté
58082,CORANCY,58120,CORANCY,,47.1062561745,3.95987876262,82,,Corancy,Corancy,58,Nièvre,27,Bourgogne-Franche-Comté
58083,CORBIGNY,58800,CORBIGNY,,47.2565578889,3.68948978336,83,,Corbigny,Corbigny,58,Nièvre,27,Bourgogne-Franche-Comté
58084,CORVOL D EMBERNARD,58210,CORVOL D EMBERNARD,,47.2944695727,3.39889165707,84,,Corvol-d'Embernard,Corvol-d'Embernard,58,Nièvre,27,Bourgogne-Franche-Comté
58085,CORVOL L ORGUEILLEUX,58460,CORVOL L ORGUEILLEUX,,47.4328581251,3.36750754848,85,,Corvol-l'Orgueilleux,Corvol-l'Orgueilleux,58,Nièvre,27,Bourgogne-Franche-Comté
58086,COSNE COURS SUR LOIRE,58200,COSNE COURS SUR LOIRE,,47.4029099422,2.94284103771,86,,Cosne-Cours-sur-Loire,Cosne-Cours-sur-Loire,58,Nièvre,27,Bourgogne-Franche-Comté
58086,COSNE COURS SUR LOIRE,58200,COSNE COURS SUR LOIRE,COURS,47.4029099422,2.94284103771,86,,Cosne-Cours-sur-Loire,Cosne-Cours-sur-Loire,58,Nièvre,27,Bourgogne-Franche-Comté
58087,COSSAYE,58300,COSSAYE,,46.7559659836,3.51003237912,87,,Cossaye,Cossaye,58,Nièvre,27,Bourgogne-Franche-Comté
58088,COULANGES LES NEVERS,58660,COULANGES LES NEVERS,,47.013650182,3.19716378992,88,,Coulanges-lès-Nevers,Coulanges-lès-Nevers,58,Nièvre,27,Bourgogne-Franche-Comté
58089,COULOUTRE,58220,COULOUTRE,,47.3950173245,3.22048548212,89,,Couloutre,Couloutre,58,Nièvre,27,Bourgogne-Franche-Comté
58090,COURCELLES,58210,COURCELLES,,47.4026373716,3.3940224595,90,,Courcelles,Courcelles,58,Nièvre,27,Bourgogne-Franche-Comté
58092,CRUX LA VILLE,58330,CRUX LA VILLE,,47.15352301,3.52633710802,92,,Crux-la-Ville,Crux-la-Ville,58,Nièvre,27,Bourgogne-Franche-Comté
58093,CUNCY LES VARZY,58210,CUNCY LES VARZY,,47.3624384077,3.45913021851,93,,Cuncy-lès-Varzy,Cuncy-lès-Varzy,58,Nièvre,27,Bourgogne-Franche-Comté
58094,DAMPIERRE SOUS BOUHY,58310,DAMPIERRE SOUS BOUHY,,47.5071079363,3.12979179712,94,,Dampierre-sous-Bouhy,Dampierre-sous-Bouhy,58,Nièvre,27,Bourgogne-Franche-Comté
58095,DECIZE,58300,DECIZE,,46.8088592771,3.46630747619,95,,Decize,Decize,58,Nièvre,27,Bourgogne-Franche-Comté
58096,DEVAY,58300,DEVAY,,46.8052157107,3.54237937997,96,,Devay,Devay,58,Nièvre,27,Bourgogne-Franche-Comté
58097,DIENNES AUBIGNY,58340,DIENNES AUBIGNY,,46.9132554892,3.57639620469,97,,Diennes-Aubigny,Diennes-Aubigny,58,Nièvre,27,Bourgogne-Franche-Comté
58098,DIROL,58190,DIROL,,47.3184800902,3.64313334318,98,,Dirol,Dirol,58,Nièvre,27,Bourgogne-Franche-Comté
58099,DOMMARTIN,58120,DOMMARTIN,,47.0718096664,3.84542104585,99,,Dommartin,Dommartin,58,Nièvre,27,Bourgogne-Franche-Comté
58101,DOMPIERRE SUR NIEVRE,58350,DOMPIERRE SUR NIEVRE,,47.2317693223,3.25913821713,101,,Dompierre-sur-Nièvre,Dompierre-sur-Nièvre,58,Nièvre,27,Bourgogne-Franche-Comté
58102,DONZY,58220,DONZY,,47.3800225966,3.11365486135,102,,Donzy,Donzy,58,Nièvre,27,Bourgogne-Franche-Comté
58103,DORNECY,58530,DORNECY,,47.4426675206,3.58969747383,103,,Dornecy,Dornecy,58,Nièvre,27,Bourgogne-Franche-Comté
58104,DORNES,58390,DORNES,,46.7193519034,3.33797253161,104,,Dornes,Dornes,58,Nièvre,27,Bourgogne-Franche-Comté
58105,DRUY PARIGNY,58160,DRUY PARIGNY,,46.8810589814,3.37180650361,105,,Druy-Parigny,Druy-Parigny,58,Nièvre,27,Bourgogne-Franche-Comté
58106,DUN LES PLACES,58230,DUN LES PLACES,,47.289797689,4.01928339749,106,,Dun-les-Places,Dun-les-Places,58,Nièvre,27,Bourgogne-Franche-Comté
58107,DUN SUR GRANDRY,58110,DUN SUR GRANDRY,,47.0898700253,3.79985075071,107,,Dun-sur-Grandry,Dun-sur-Grandry,58,Nièvre,27,Bourgogne-Franche-Comté
58108,EMPURY,58140,EMPURY,,47.3475457799,3.82219513783,108,,Empury,Empury,58,Nièvre,27,Bourgogne-Franche-Comté
58109,ENTRAINS SUR NOHAIN,58410,ENTRAINS SUR NOHAIN,,47.4610040367,3.27333257004,109,,Entrains-sur-Nohain,Entrains-sur-Nohain,58,Nièvre,27,Bourgogne-Franche-Comté
58110,EPIRY,58800,EPIRY,,47.170814257,3.73120598102,110,,Epiry,Epiry,58,Nièvre,27,Bourgogne-Franche-Comté
58111,FACHIN,58430,FACHIN,,47.002421282,3.96692225039,111,,Fâchin,Fâchin,58,Nièvre,27,Bourgogne-Franche-Comté
58112,LA FERMETE,58160,LA FERMETE,,46.9561343358,3.32854721893,112,La,Fermeté,La Fermeté,58,Nièvre,27,Bourgogne-Franche-Comté
58113,FERTREVE,58270,FERTREVE,,46.9629893289,3.58909121606,113,,Fertrève,Fertrève,58,Nièvre,27,Bourgogne-Franche-Comté
58114,FLETY,58170,FLETY,,46.798653723,3.9063939213,114,,Fléty,Fléty,58,Nièvre,27,Bourgogne-Franche-Comté
58115,FLEURY SUR LOIRE,58240,FLEURY SUR LOIRE,,46.830039713,3.31570978537,115,,Fleury-sur-Loire,Fleury-sur-Loire,58,Nièvre,27,Bourgogne-Franche-Comté
58116,FLEZ CUZY,58190,FLEZ CUZY,,47.3584468681,3.63385591808,116,,Flez-Cuzy,Flez-Cuzy,58,Nièvre,27,Bourgogne-Franche-Comté
58117,FOURCHAMBAULT,58600,FOURCHAMBAULT,,47.0168666428,3.09059273072,117,,Fourchambault,Fourchambault,58,Nièvre,27,Bourgogne-Franche-Comté
58118,FOURS,58250,FOURS,,46.8089651055,3.72049071948,118,,Fours,Fours,58,Nièvre,27,Bourgogne-Franche-Comté
58119,FRASNAY REUGNY,58270,FRASNAY REUGNY,,46.9922185296,3.52773836273,119,,Frasnay-Reugny,Frasnay-Reugny,58,Nièvre,27,Bourgogne-Franche-Comté
58120,GACOGNE,58140,GACOGNE,,47.2332591371,3.87853362588,120,,Gâcogne,Gâcogne,58,Nièvre,27,Bourgogne-Franche-Comté
58121,GARCHIZY,58600,GARCHIZY,,47.0432163647,3.09375234427,121,,Garchizy,Garchizy,58,Nièvre,27,Bourgogne-Franche-Comté
58122,GARCHY,58150,GARCHY,,47.2771676376,3.05332788878,122,,Garchy,Garchy,58,Nièvre,27,Bourgogne-Franche-Comté
58122,GARCHY,58150,GARCHY,MAIZIERES,47.2771676376,3.05332788878,122,,Garchy,Garchy,58,Nièvre,27,Bourgogne-Franche-Comté
58123,GERMENAY,58800,GERMENAY,,47.2891665195,3.60058784085,123,,Germenay,Germenay,58,Nièvre,27,Bourgogne-Franche-Comté
58124,GERMIGNY SUR LOIRE,58320,GERMIGNY SUR LOIRE,,47.0779068845,3.04792148383,124,,Germigny-sur-Loire,Germigny-sur-Loire,58,Nièvre,27,Bourgogne-Franche-Comté
58125,GIEN SUR CURE,58230,GIEN SUR CURE,,47.1456570657,4.09021461672,125,,Gien-sur-Cure,Gien-sur-Cure,58,Nièvre,27,Bourgogne-Franche-Comté
58126,GIMOUILLE,58470,GIMOUILLE,,46.9412562853,3.09837395371,126,,Gimouille,Gimouille,58,Nièvre,27,Bourgogne-Franche-Comté
58127,GIRY,58700,GIRY,,47.2145145857,3.33712972505,127,,Giry,Giry,58,Nièvre,27,Bourgogne-Franche-Comté
58128,GLUX EN GLENNE,58370,GLUX EN GLENNE,,46.9557860799,4.0213723533,128,,Glux-en-Glenne,Glux-en-Glenne,58,Nièvre,27,Bourgogne-Franche-Comté
58129,GOULOUX,58230,GOULOUX,,47.2305921605,4.0840074367,129,,Gouloux,Gouloux,58,Nièvre,27,Bourgogne-Franche-Comté
58130,GRENOIS,58420,GRENOIS,,47.3214591393,3.52128232179,130,,Grenois,Grenois,58,Nièvre,27,Bourgogne-Franche-Comté
58131,GUERIGNY,58130,GUERIGNY,,47.0928544134,3.21281103733,131,,Guérigny,Guérigny,58,Nièvre,27,Bourgogne-Franche-Comté
58132,GUIPY,58420,GUIPY,,47.2288349928,3.57914407014,132,,Guipy,Guipy,58,Nièvre,27,Bourgogne-Franche-Comté
58133,HERY,58800,HERY,,47.2623486952,3.59205862673,133,,Héry,Héry,58,Nièvre,27,Bourgogne-Franche-Comté
58134,IMPHY,58160,IMPHY,,46.9351930768,3.2810945093,134,,Imphy,Imphy,58,Nièvre,27,Bourgogne-Franche-Comté
58135,ISENAY,58290,ISENAY,,46.9182601112,3.71038298673,135,,Isenay,Isenay,58,Nièvre,27,Bourgogne-Franche-Comté
58136,JAILLY,58330,JAILLY,,47.0855636389,3.46962108333,136,,Jailly,Jailly,58,Nièvre,27,Bourgogne-Franche-Comté
58137,LAMENAY SUR LOIRE,58300,LAMENAY SUR LOIRE,,46.7499702083,3.57600499117,137,,Lamenay-sur-Loire,Lamenay-sur-Loire,58,Nièvre,27,Bourgogne-Franche-Comté
58138,LANGERON,58240,LANGERON,,46.8169584148,3.08314704096,138,,Langeron,Langeron,58,Nièvre,27,Bourgogne-Franche-Comté
58139,LANTY,58250,LANTY,,46.8028139778,3.82941818317,139,,Lanty,Lanty,58,Nièvre,27,Bourgogne-Franche-Comté
58140,LAROCHEMILLAY,58370,LAROCHEMILLAY,,46.8904175771,3.98533892675,140,,Larochemillay,Larochemillay,58,Nièvre,27,Bourgogne-Franche-Comté
58141,LAVAULT DE FRETOY,58230,LAVAULT DE FRETOY,,47.1054408095,4.02164055616,141,,Lavault-de-Frétoy,Lavault-de-Frétoy,58,Nièvre,27,Bourgogne-Franche-Comté
58142,LIMANTON,58290,LIMANTON,,46.9799380877,3.74070604967,142,,Limanton,Limanton,58,Nièvre,27,Bourgogne-Franche-Comté
58143,LIMON,58270,LIMON,,46.9651256644,3.38472580613,143,,Limon,Limon,58,Nièvre,27,Bourgogne-Franche-Comté
58144,LIVRY,58240,LIVRY,,46.7686585382,3.07241225301,144,,Livry,Livry,58,Nièvre,27,Bourgogne-Franche-Comté
58145,LORMES,58140,LORMES,,47.2894645643,3.82491613793,145,,Lormes,Lormes,58,Nièvre,27,Bourgogne-Franche-Comté
58146,LUCENAY LES AIX,58380,LUCENAY LES AIX,,46.6979583473,3.49166232072,146,,Lucenay-lès-Aix,Lucenay-lès-Aix,58,Nièvre,27,Bourgogne-Franche-Comté
58147,LURCY LE BOURG,58700,LURCY LE BOURG,,47.1656078028,3.39665729196,147,,Lurcy-le-Bourg,Lurcy-le-Bourg,58,Nièvre,27,Bourgogne-Franche-Comté
58148,LUTHENAY UXELOUP,58240,LUTHENAY UXELOUP,,46.8534809432,3.26854590349,148,,Luthenay-Uxeloup,Luthenay-Uxeloup,58,Nièvre,27,Bourgogne-Franche-Comté
58149,LUZY,58170,LUZY,,46.7965929965,3.99922728747,149,,Luzy,Luzy,58,Nièvre,27,Bourgogne-Franche-Comté
58150,LYS,58190,LYS,,47.334846257,3.59874573794,150,,Lys,Lys,58,Nièvre,27,Bourgogne-Franche-Comté
58151,LA MACHINE,58260,LA MACHINE,,46.8916874308,3.46212793801,151,La,Machine,La Machine,58,Nièvre,27,Bourgogne-Franche-Comté
58152,MAGNY COURS,58470,MAGNY COURS,,46.8900521667,3.15435874916,152,,Magny-Cours,Magny-Cours,58,Nièvre,27,Bourgogne-Franche-Comté
58153,MAGNY LORMES,58800,MAGNY LORMES,,47.2909908741,3.74767215343,153,,Magny-Lormes,Magny-Lormes,58,Nièvre,27,Bourgogne-Franche-Comté
58154,LA MAISON DIEU,58190,LA MAISON DIEU,,47.4216053148,3.66770508444,154,La,Maison-Dieu,La Maison-Dieu,58,Nièvre,27,Bourgogne-Franche-Comté
58155,LA MARCHE,58400,LA MARCHE,,47.148869332,3.04010611601,155,La,Marche,La Marche,58,Nièvre,27,Bourgogne-Franche-Comté
58156,MARCY,58210,MARCY,,47.3172612368,3.40361734697,156,,Marcy,Marcy,58,Nièvre,27,Bourgogne-Franche-Comté
58157,MARIGNY L EGLISE,58140,MARIGNY L EGLISE,,47.3451872372,3.94113791416,157,,Marigny-l'Église,Marigny-l'Église,58,Nièvre,27,Bourgogne-Franche-Comté
58158,MARS SUR ALLIER,58240,MARS SUR ALLIER,,46.8583738333,3.08787262562,158,,Mars-sur-Allier,Mars-sur-Allier,58,Nièvre,27,Bourgogne-Franche-Comté
58159,MARIGNY SUR YONNE,58800,MARIGNY SUR YONNE,,47.287657405,3.64242914164,159,,Marigny-sur-Yonne,Marigny-sur-Yonne,58,Nièvre,27,Bourgogne-Franche-Comté
58160,MARZY,58180,MARZY,,46.9838677741,3.0926104206,160,,Marzy,Marzy,58,Nièvre,27,Bourgogne-Franche-Comté
58160,MARZY,58180,MARZY,CORCELLES,46.9838677741,3.0926104206,160,,Marzy,Marzy,58,Nièvre,27,Bourgogne-Franche-Comté
58161,MAUX,58290,MAUX,,47.038265018,3.77347419012,161,,Maux,Maux,58,Nièvre,27,Bourgogne-Franche-Comté
58162,MENESTREAU,58410,MENESTREAU,,47.4095765836,3.26147861119,162,,Menestreau,Menestreau,58,Nièvre,27,Bourgogne-Franche-Comté
58163,MENOU,58210,MENOU,,47.3684390837,3.28332694453,163,,Menou,Menou,58,Nièvre,27,Bourgogne-Franche-Comté
58164,MESVES SUR LOIRE,58400,MESVES SUR LOIRE,,47.2305535189,3.00466811976,164,,Mesves-sur-Loire,Mesves-sur-Loire,58,Nièvre,27,Bourgogne-Franche-Comté
58165,METZ LE COMTE,58190,METZ LE COMTE,,47.3930715612,3.63564973338,165,,Metz-le-Comte,Metz-le-Comte,58,Nièvre,27,Bourgogne-Franche-Comté
58166,MHERE,58140,MHERE,,47.1949524122,3.86024509237,166,,Mhère,Mhère,58,Nièvre,27,Bourgogne-Franche-Comté
58168,MILLAY,58170,MILLAY,,46.8347484024,3.99024145825,168,,Millay,Millay,58,Nièvre,27,Bourgogne-Franche-Comté
58169,MOISSY MOULINOT,58190,MOISSY MOULINOT,,47.3274392813,3.70660052795,169,,Moissy-Moulinot,Moissy-Moulinot,58,Nièvre,27,Bourgogne-Franche-Comté
58170,MONCEAUX LE COMTE,58190,MONCEAUX LE COMTE,,47.3317643391,3.66490728626,170,,Monceaux-le-Comte,Monceaux-le-Comte,58,Nièvre,27,Bourgogne-Franche-Comté
58171,MONTAPAS,58110,MONTAPAS,,47.0809802706,3.58450998335,171,,Montapas,Montapas,58,Nièvre,27,Bourgogne-Franche-Comté
58172,MONTAMBERT,58250,MONTAMBERT,,46.770548443,3.68356679841,172,,Montambert,Montambert,58,Nièvre,27,Bourgogne-Franche-Comté
58173,MONTARON,58250,MONTARON,,46.8762307221,3.74836898416,173,,Montaron,Montaron,58,Nièvre,27,Bourgogne-Franche-Comté
58174,MONTENOISON,58700,MONTENOISON,,47.2188062149,3.42097761698,174,,Montenoison,Montenoison,58,Nièvre,27,Bourgogne-Franche-Comté
58175,MONT ET MARRE,58110,MONT ET MARRE,,47.0889728299,3.64523773772,175,,Mont-et-Marré,Mont-et-Marré,58,Nièvre,27,Bourgogne-Franche-Comté
58176,MONTIGNY AUX AMOGNES,58130,MONTIGNY AUX AMOGNES,,47.0255912053,3.27904541582,176,,Montigny-aux-Amognes,Montigny-aux-Amognes,58,Nièvre,27,Bourgogne-Franche-Comté
58177,MONTIGNY EN MORVAN,58120,MONTIGNY EN MORVAN,,47.1446460147,3.86175539037,177,,Montigny-en-Morvan,Montigny-en-Morvan,58,Nièvre,27,Bourgogne-Franche-Comté
58178,MONTIGNY SUR CANNE,58340,MONTIGNY SUR CANNE,,46.9429617618,3.65775331444,178,,Montigny-sur-Canne,Montigny-sur-Canne,58,Nièvre,27,Bourgogne-Franche-Comté
58179,MONTREUILLON,58800,MONTREUILLON,,47.1714740778,3.79148953079,179,,Montreuillon,Montreuillon,58,Nièvre,27,Bourgogne-Franche-Comté
58180,MONTSAUCHE LES SETTONS,58230,MONTSAUCHE LES SETTONS,,47.2140724484,4.03362547677,180,,Montsauche-les-Settons,Montsauche-les-Settons,58,Nièvre,27,Bourgogne-Franche-Comté
58180,MONTSAUCHE LES SETTONS,58230,MONTSAUCHE LES SETTONS,LES SETTONS,47.2140724484,4.03362547677,180,,Montsauche-les-Settons,Montsauche-les-Settons,58,Nièvre,27,Bourgogne-Franche-Comté
58181,MORACHES,58420,MORACHES,,47.2847629548,3.54492476259,181,,Moraches,Moraches,58,Nièvre,27,Bourgogne-Franche-Comté
58182,MOULINS ENGILBERT,58290,MOULINS ENGILBERT,,46.9731661302,3.81182294166,182,,Moulins-Engilbert,Moulins-Engilbert,58,Nièvre,27,Bourgogne-Franche-Comté
58183,MOURON SUR YONNE,58800,MOURON SUR YONNE,,47.1984538221,3.74007897525,183,,Mouron-sur-Yonne,Mouron-sur-Yonne,58,Nièvre,27,Bourgogne-Franche-Comté
58184,MOUSSY,58700,MOUSSY,,47.1965140139,3.4589921021,184,,Moussy,Moussy,58,Nièvre,27,Bourgogne-Franche-Comté
58185,MOUX EN MORVAN,58230,MOUX EN MORVAN,,47.1694217154,4.13049282693,185,,Moux-en-Morvan,Moux-en-Morvan,58,Nièvre,27,Bourgogne-Franche-Comté
58186,MURLIN,58700,MURLIN,,47.1954955945,3.17316291743,186,,Murlin,Murlin,58,Nièvre,27,Bourgogne-Franche-Comté
58187,MYENNES,58440,MYENNES,,47.4543629223,2.94370342447,187,,Myennes,Myennes,58,Nièvre,27,Bourgogne-Franche-Comté
58188,NANNAY,58350,NANNAY,,47.2606508434,3.16417632976,188,,Nannay,Nannay,58,Nièvre,27,Bourgogne-Franche-Comté
58189,NARCY,58400,NARCY,,47.2328455003,3.10662279955,189,,Narcy,Narcy,58,Nièvre,27,Bourgogne-Franche-Comté
58190,NEUFFONTAINES,58190,NEUFFONTAINES,,47.3499035349,3.74055126391,190,,Neuffontaines,Neuffontaines,58,Nièvre,27,Bourgogne-Franche-Comté
58191,NEUILLY,58420,NEUILLY,,47.2406097442,3.51510509101,191,,Neuilly,Neuilly,58,Nièvre,27,Bourgogne-Franche-Comté
58192,NEUVILLE LES DECIZE,58300,NEUVILLE LES DECIZE,,46.7746439982,3.30128534052,192,,Neuville-lès-Decize,Neuville-lès-Decize,58,Nièvre,27,Bourgogne-Franche-Comté
58193,NEUVY SUR LOIRE,58450,NEUVY SUR LOIRE,,47.5317453883,2.89007280988,193,,Neuvy-sur-Loire,Neuvy-sur-Loire,58,Nièvre,27,Bourgogne-Franche-Comté
58194,NEVERS,58000,NEVERS,,46.9881194908,3.15689130958,194,,Nevers,Nevers,58,Nièvre,27,Bourgogne-Franche-Comté
58195,LA NOCLE MAULAIX,58250,LA NOCLE MAULAIX,,46.7633667466,3.76335478199,195,La,Nocle-Maulaix,La Nocle-Maulaix,58,Nièvre,27,Bourgogne-Franche-Comté
58196,NOLAY,58700,NOLAY,,47.1179113926,3.33180523455,196,,Nolay,Nolay,58,Nièvre,27,Bourgogne-Franche-Comté
58197,NUARS,58190,NUARS,,47.388402075,3.70962662782,197,,Nuars,Nuars,58,Nièvre,27,Bourgogne-Franche-Comté
58198,OISY,58500,OISY,,47.4715736996,3.45107563466,198,,Oisy,Oisy,58,Nièvre,27,Bourgogne-Franche-Comté
58199,ONLAY,58370,ONLAY,,46.9672902471,3.89557358975,199,,Onlay,Onlay,58,Nièvre,27,Bourgogne-Franche-Comté
58200,OUAGNE,58500,OUAGNE,,47.4020463013,3.49846601652,200,,Ouagne,Ouagne,58,Nièvre,27,Bourgogne-Franche-Comté
58201,OUDAN,58210,OUDAN,,47.3420705967,3.32896873722,201,,Oudan,Oudan,58,Nièvre,27,Bourgogne-Franche-Comté
58202,OUGNY,58110,OUGNY,,47.0774917586,3.71108202561,202,,Ougny,Ougny,58,Nièvre,27,Bourgogne-Franche-Comté
58203,OULON,58700,OULON,,47.198088576,3.40068802026,203,,Oulon,Oulon,58,Nièvre,27,Bourgogne-Franche-Comté
58204,OUROUER,58130,OUROUER,,47.0613273631,3.30090661535,204,,Vaux d'Amognes,Vaux d'Amognes,58,Nièvre,27,Bourgogne-Franche-Comté
58205,OUROUX EN MORVAN,58230,OUROUX EN MORVAN,,47.1892092482,3.95313922801,205,,Ouroux-en-Morvan,Ouroux-en-Morvan,58,Nièvre,27,Bourgogne-Franche-Comté
58206,PARIGNY LA ROSE,58210,PARIGNY LA ROSE,,47.3307435797,3.45512612247,206,,Parigny-la-Rose,Parigny-la-Rose,58,Nièvre,27,Bourgogne-Franche-Comté
58207,PARIGNY LES VAUX,58320,PARIGNY LES VAUX,,47.1005907771,3.14628705959,207,,Parigny-les-Vaux,Parigny-les-Vaux,58,Nièvre,27,Bourgogne-Franche-Comté
58208,PAZY,58800,PAZY,,47.2261642942,3.63097657563,208,,Pazy,Pazy,58,Nièvre,27,Bourgogne-Franche-Comté
58209,PERROY,58220,PERROY,,47.4048215524,3.16453961922,209,,Perroy,Perroy,58,Nièvre,27,Bourgogne-Franche-Comté
58210,PLANCHEZ,58230,PLANCHEZ,,47.1427917023,4.02625107605,210,,Planchez,Planchez,58,Nièvre,27,Bourgogne-Franche-Comté
58211,POIL,58170,POIL,,46.8732226807,4.05560457368,211,,Poil,Poil,58,Nièvre,27,Bourgogne-Franche-Comté
58212,POISEUX,58130,POISEUX,,47.1203587052,3.23981973484,212,,Poiseux,Poiseux,58,Nièvre,27,Bourgogne-Franche-Comté
58213,POUGNY,58200,POUGNY,,47.3940156932,3.02397404784,213,,Pougny,Pougny,58,Nièvre,27,Bourgogne-Franche-Comté
58214,POUGUES LES EAUX,58320,POUGUES LES EAUX,,47.0759346437,3.09656237194,214,,Pougues-les-Eaux,Pougues-les-Eaux,58,Nièvre,27,Bourgogne-Franche-Comté
58215,POUILLY SUR LOIRE,58150,POUILLY SUR LOIRE,,47.2802139612,2.98240910916,215,,Pouilly-sur-Loire,Pouilly-sur-Loire,58,Nièvre,27,Bourgogne-Franche-Comté
58216,POUQUES LORMES,58140,POUQUES LORMES,,47.3242466095,3.77724647553,216,,Pouques-Lormes,Pouques-Lormes,58,Nièvre,27,Bourgogne-Franche-Comté
58217,POUSSEAUX,58500,POUSSEAUX,,47.4985703451,3.54164743196,217,,Pousseaux,Pousseaux,58,Nièvre,27,Bourgogne-Franche-Comté
58218,PREMERY,58700,PREMERY,,47.1734370453,3.32035271062,218,,Prémery,Prémery,58,Nièvre,27,Bourgogne-Franche-Comté
58219,PREPORCHE,58360,PREPORCHE,,46.9362843762,3.86451394261,219,,Préporché,Préporché,58,Nièvre,27,Bourgogne-Franche-Comté
58220,RAVEAU,58400,RAVEAU,,47.1789620063,3.11552861009,220,,Raveau,Raveau,58,Nièvre,27,Bourgogne-Franche-Comté
58221,REMILLY,58250,REMILLY,,46.8282746226,3.79337792444,221,,Rémilly,Rémilly,58,Nièvre,27,Bourgogne-Franche-Comté
58222,RIX,58500,RIX,,47.4284202802,3.49827889196,222,,Rix,Rix,58,Nièvre,27,Bourgogne-Franche-Comté
58223,ROUY,58110,ROUY,,47.0382901584,3.54022524667,223,,Rouy,Rouy,58,Nièvre,27,Bourgogne-Franche-Comté
58224,RUAGES,58190,RUAGES,,47.3117824259,3.68294903942,224,,Ruages,Ruages,58,Nièvre,27,Bourgogne-Franche-Comté
58225,SAINCAIZE MEAUCE,58470,SAINCAIZE MEAUCE,,46.9074250385,3.08679775967,225,,Saincaize-Meauce,Saincaize-Meauce,58,Nièvre,27,Bourgogne-Franche-Comté
58226,ST AGNAN,58230,ST AGNAN,,47.3229791004,4.09119317284,226,,Saint-Agnan,Saint-Agnan,58,Nièvre,27,Bourgogne-Franche-Comté
58227,ST AMAND EN PUISAYE,58310,ST AMAND EN PUISAYE,,47.548454274,3.07830606651,227,,Saint-Amand-en-Puisaye,Saint-Amand-en-Puisaye,58,Nièvre,27,Bourgogne-Franche-Comté
58228,ST ANDELAIN,58150,ST ANDELAIN,,47.3106333891,2.98007868063,228,,Saint-Andelain,Saint-Andelain,58,Nièvre,27,Bourgogne-Franche-Comté
58229,ST ANDRE EN MORVAN,58140,ST ANDRE EN MORVAN,,47.3985163696,3.85566396441,229,,Saint-André-en-Morvan,Saint-André-en-Morvan,58,Nièvre,27,Bourgogne-Franche-Comté
58230,ST AUBIN DES CHAUMES,58190,ST AUBIN DES CHAUMES,,47.3901624546,3.75721572087,230,,Saint-Aubin-des-Chaumes,Saint-Aubin-des-Chaumes,58,Nièvre,27,Bourgogne-Franche-Comté
58231,ST AUBIN LES FORGES,58130,ST AUBIN LES FORGES,,47.1430333562,3.18841485018,231,,Saint-Aubin-les-Forges,Saint-Aubin-les-Forges,58,Nièvre,27,Bourgogne-Franche-Comté
58232,ST BENIN D AZY,58270,ST BENIN D AZY,,46.9959427892,3.40477178304,232,,Saint-Benin-d'Azy,Saint-Benin-d'Azy,58,Nièvre,27,Bourgogne-Franche-Comté
58233,ST BENIN DES BOIS,58330,ST BENIN DES BOIS,,47.1194281851,3.40476489067,233,,Saint-Benin-des-Bois,Saint-Benin-des-Bois,58,Nièvre,27,Bourgogne-Franche-Comté
58234,ST BONNOT,58700,ST BONNOT,,47.2460224588,3.30695653573,234,,Saint-Bonnot,Saint-Bonnot,58,Nièvre,27,Bourgogne-Franche-Comté
58235,ST BRISSON,58230,ST BRISSON,,47.2676919884,4.09479104131,235,,Saint-Brisson,Saint-Brisson,58,Nièvre,27,Bourgogne-Franche-Comté
58236,STE COLOMBE DES BOIS,58220,STE COLOMBE DES BOIS,,47.3209444056,3.15350066237,236,,Sainte-Colombe-des-Bois,Sainte-Colombe-des-Bois,58,Nièvre,27,Bourgogne-Franche-Comté
58237,ST DIDIER,58190,ST DIDIER,,47.3478980035,3.62016798803,237,,Saint-Didier,Saint-Didier,58,Nièvre,27,Bourgogne-Franche-Comté
58238,ST ELOI,58000,ST ELOI,,46.9901181067,3.22198605099,238,,Saint-Éloi,Saint-Éloi,58,Nièvre,27,Bourgogne-Franche-Comté
58239,ST FIRMIN,58270,ST FIRMIN,,47.0392467272,3.39435546081,239,,Saint-Firmin,Saint-Firmin,58,Nièvre,27,Bourgogne-Franche-Comté
58240,ST FRANCHY,58330,ST FRANCHY,,47.1512468042,3.45504416231,240,,Saint-Franchy,Saint-Franchy,58,Nièvre,27,Bourgogne-Franche-Comté
58241,ST GERMAIN CHASSENAY,58300,ST GERMAIN CHASSENAY,,46.7812644833,3.39785224897,241,,Saint-Germain-Chassenay,Saint-Germain-Chassenay,58,Nièvre,27,Bourgogne-Franche-Comté
58242,ST GERMAIN DES BOIS,58210,ST GERMAIN DES BOIS,,47.3688689171,3.51935768956,242,,Saint-Germain-des-Bois,Saint-Germain-des-Bois,58,Nièvre,27,Bourgogne-Franche-Comté
58243,ST GRATIEN SAVIGNY,58340,ST GRATIEN SAVIGNY,,46.9008929887,3.65901244789,243,,Saint-Gratien-Savigny,Saint-Gratien-Savigny,58,Nièvre,27,Bourgogne-Franche-Comté
58244,ST HILAIRE EN MORVAN,58120,ST HILAIRE EN MORVAN,,47.0682831624,3.88728791714,244,,Saint-Hilaire-en-Morvan,Saint-Hilaire-en-Morvan,58,Nièvre,27,Bourgogne-Franche-Comté
58245,ST HILAIRE FONTAINE,58300,ST HILAIRE FONTAINE,,46.7751245255,3.63613180182,245,,Saint-Hilaire-Fontaine,Saint-Hilaire-Fontaine,58,Nièvre,27,Bourgogne-Franche-Comté
58246,ST HONORE LES BAINS,58360,ST HONORE LES BAINS,,46.899599449,3.85391051025,246,,Saint-Honoré-les-Bains,Saint-Honoré-les-Bains,58,Nièvre,27,Bourgogne-Franche-Comté
58247,ST JEAN AUX AMOGNES,58270,ST JEAN AUX AMOGNES,,47.0088136491,3.34145655138,247,,Saint-Jean-aux-Amognes,Saint-Jean-aux-Amognes,58,Nièvre,27,Bourgogne-Franche-Comté
58248,ST LAURENT L ABBAYE,58150,ST LAURENT L ABBAYE,,47.3370394747,2.99458105958,248,,Saint-Laurent-l'Abbaye,Saint-Laurent-l'Abbaye,58,Nièvre,27,Bourgogne-Franche-Comté
58249,ST LEGER DE FOUGERET,58120,ST LEGER DE FOUGERET,,47.0085324049,3.90770959425,249,,Saint-Léger-de-Fougeret,Saint-Léger-de-Fougeret,58,Nièvre,27,Bourgogne-Franche-Comté
58250,ST LEGER DES VIGNES,58300,ST LEGER DES VIGNES,,46.8489006615,3.45264311674,250,,Saint-Léger-des-Vignes,Saint-Léger-des-Vignes,58,Nièvre,27,Bourgogne-Franche-Comté
58251,ST LOUP,58200,ST LOUP,,47.4524727684,3.00689474871,251,,Saint-Loup,Saint-Loup,58,Nièvre,27,Bourgogne-Franche-Comté
58252,ST MALO EN DONZIOIS,58350,ST MALO EN DONZIOIS,,47.3114598029,3.27714440768,252,,Saint-Malo-en-Donziois,Saint-Malo-en-Donziois,58,Nièvre,27,Bourgogne-Franche-Comté
58253,STE MARIE,58330,STE MARIE,,47.111632983,3.44705005669,253,,Sainte-Marie,Sainte-Marie,58,Nièvre,27,Bourgogne-Franche-Comté
58254,ST MARTIN D HEUILLE,58130,ST MARTIN D HEUILLE,,47.0480526526,3.23272615742,254,,Saint-Martin-d'Heuille,Saint-Martin-d'Heuille,58,Nièvre,27,Bourgogne-Franche-Comté
58255,ST MARTIN DU PUY,58140,ST MARTIN DU PUY,,47.3309047004,3.87554072718,255,,Saint-Martin-du-Puy,Saint-Martin-du-Puy,58,Nièvre,27,Bourgogne-Franche-Comté
58256,ST MARTIN SUR NOHAIN,58150,ST MARTIN SUR NOHAIN,,47.3584010694,2.98825176159,256,,Saint-Martin-sur-Nohain,Saint-Martin-sur-Nohain,58,Nièvre,27,Bourgogne-Franche-Comté
58257,ST MAURICE,58330,ST MAURICE,,47.1123966682,3.56924225444,257,,Saint-Maurice,Saint-Maurice,58,Nièvre,27,Bourgogne-Franche-Comté
58258,ST OUEN SUR LOIRE,58160,ST OUEN SUR LOIRE,,46.8992684036,3.3202343008,258,,Saint-Ouen-sur-Loire,Saint-Ouen-sur-Loire,58,Nièvre,27,Bourgogne-Franche-Comté
58259,ST PARIZE EN VIRY,58300,ST PARIZE EN VIRY,,46.7536799214,3.36701488719,259,,Saint-Parize-en-Viry,Saint-Parize-en-Viry,58,Nièvre,27,Bourgogne-Franche-Comté
58260,ST PARIZE LE CHATEL,58490,ST PARIZE LE CHATEL,,46.8507546576,3.18416300393,260,,Saint-Parize-le-Châtel,Saint-Parize-le-Châtel,58,Nièvre,27,Bourgogne-Franche-Comté
58260,ST PARIZE LE CHATEL,58490,ST PARIZE LE CHATEL,MOIRY,46.8507546576,3.18416300393,260,,Saint-Parize-le-Châtel,Saint-Parize-le-Châtel,58,Nièvre,27,Bourgogne-Franche-Comté
58261,ST PERE,58200,ST PERE,,47.4038457466,2.97705623439,261,,Saint-Père,Saint-Père,58,Nièvre,27,Bourgogne-Franche-Comté
58262,ST PEREUSE,58110,ST PEREUSE,,47.0590546372,3.80877052884,262,,Saint-Péreuse,Saint-Péreuse,58,Nièvre,27,Bourgogne-Franche-Comté
58263,ST PIERRE DU MONT,58210,ST PIERRE DU MONT,,47.3972230217,3.44528921516,263,,Saint-Pierre-du-Mont,Saint-Pierre-du-Mont,58,Nièvre,27,Bourgogne-Franche-Comté
58264,ST PIERRE LE MOUTIER,58240,ST PIERRE LE MOUTIER,,46.7917213568,3.14035365486,264,,Saint-Pierre-le-Moûtier,Saint-Pierre-le-Moûtier,58,Nièvre,27,Bourgogne-Franche-Comté
58265,ST QUENTIN SUR NOHAIN,58150,ST QUENTIN SUR NOHAIN,,47.3245431361,3.02155254017,265,,Saint-Quentin-sur-Nohain,Saint-Quentin-sur-Nohain,58,Nièvre,27,Bourgogne-Franche-Comté
58266,ST REVERIEN,58420,ST REVERIEN,,47.2035107176,3.50777909865,266,,Saint-Révérien,Saint-Révérien,58,Nièvre,27,Bourgogne-Franche-Comté
58267,ST SAULGE,58330,ST SAULGE,,47.1016983299,3.51951300252,267,,Saint-Saulge,Saint-Saulge,58,Nièvre,27,Bourgogne-Franche-Comté
58268,ST SEINE,58250,ST SEINE,,46.7247057323,3.81621435,268,,Saint-Seine,Saint-Seine,58,Nièvre,27,Bourgogne-Franche-Comté
58269,ST SULPICE,58270,ST SULPICE,,47.0624951748,3.36019421637,269,,Saint-Sulpice,Saint-Sulpice,58,Nièvre,27,Bourgogne-Franche-Comté
58270,ST VERAIN,58310,ST VERAIN,,47.4869956669,3.03383994779,270,,Saint-Vérain,Saint-Vérain,58,Nièvre,27,Bourgogne-Franche-Comté
58271,SAIZY,58190,SAIZY,,47.3561828054,3.69933759931,271,,Saizy,Saizy,58,Nièvre,27,Bourgogne-Franche-Comté
58272,SARDY LES EPIRY,58800,SARDY LES EPIRY,,47.1956477659,3.68999413618,272,,Sardy-lès-Épiry,Sardy-lès-Épiry,58,Nièvre,27,Bourgogne-Franche-Comté
58273,SAUVIGNY LES BOIS,58160,SAUVIGNY LES BOIS,,46.977062374,3.2660919409,273,,Sauvigny-les-Bois,Sauvigny-les-Bois,58,Nièvre,27,Bourgogne-Franche-Comté
58273,SAUVIGNY LES BOIS,58160,SAUVIGNY LES BOIS,FORGES,46.977062374,3.2660919409,273,,Sauvigny-les-Bois,Sauvigny-les-Bois,58,Nièvre,27,Bourgogne-Franche-Comté
58274,SAVIGNY POIL FOL,58170,SAVIGNY POIL FOL,,46.7831055479,3.83512689838,274,,Savigny-Poil-Fol,Savigny-Poil-Fol,58,Nièvre,27,Bourgogne-Franche-Comté
58275,SAXI BOURDON,58330,SAXI BOURDON,,47.056209327,3.48742996327,275,,Saxi-Bourdon,Saxi-Bourdon,58,Nièvre,27,Bourgogne-Franche-Comté
58276,SEMELAY,58360,SEMELAY,,46.8611632045,3.86209285887,276,,Sémelay,Sémelay,58,Nièvre,27,Bourgogne-Franche-Comté
58277,SERMAGES,58290,SERMAGES,,47.0153050339,3.84090611605,277,,Sermages,Sermages,58,Nièvre,27,Bourgogne-Franche-Comté
58278,SERMOISE SUR LOIRE,58000,SERMOISE SUR LOIRE,,46.9469146489,3.18310220464,278,,Sermoise-sur-Loire,Sermoise-sur-Loire,58,Nièvre,27,Bourgogne-Franche-Comté
58279,SICHAMPS,58700,SICHAMPS,,47.1558171574,3.27638586284,279,,Sichamps,Sichamps,58,Nièvre,27,Bourgogne-Franche-Comté
58280,SOUGY SUR LOIRE,58300,SOUGY SUR LOIRE,,46.8604832973,3.40636403321,280,,Sougy-sur-Loire,Sougy-sur-Loire,58,Nièvre,27,Bourgogne-Franche-Comté
58281,SUILLY LA TOUR,58150,SUILLY LA TOUR,,47.3335127052,3.07213356568,281,,Suilly-la-Tour,Suilly-la-Tour,58,Nièvre,27,Bourgogne-Franche-Comté
58282,SURGY,58500,SURGY,,47.513493834,3.50606920534,282,,Surgy,Surgy,58,Nièvre,27,Bourgogne-Franche-Comté
58283,TACONNAY,58420,TACONNAY,,47.312512267,3.48949357869,283,,Taconnay,Taconnay,58,Nièvre,27,Bourgogne-Franche-Comté
58284,TALON,58190,TALON,,47.3349431775,3.55610298077,284,,Talon,Talon,58,Nièvre,27,Bourgogne-Franche-Comté
58285,TAMNAY EN BAZOIS,58110,TAMNAY EN BAZOIS,,47.0541382549,3.72330912305,285,,Tamnay-en-Bazois,Tamnay-en-Bazois,58,Nièvre,27,Bourgogne-Franche-Comté
58286,TANNAY,58190,TANNAY,,47.3614608414,3.58125302901,286,,Tannay,Tannay,58,Nièvre,27,Bourgogne-Franche-Comté
58287,TAZILLY,58170,TAZILLY,,46.7589535788,3.91377772769,287,,Tazilly,Tazilly,58,Nièvre,27,Bourgogne-Franche-Comté
58288,TEIGNY,58190,TEIGNY,,47.3880495543,3.66896170005,288,,Teigny,Teigny,58,Nièvre,27,Bourgogne-Franche-Comté
58289,TERNANT,58250,TERNANT,,46.7519793961,3.84842348353,289,,Ternant,Ternant,58,Nièvre,27,Bourgogne-Franche-Comté
58290,THAIX,58250,THAIX,,46.8493225096,3.71331013498,290,,Thaix,Thaix,58,Nièvre,27,Bourgogne-Franche-Comté
58291,THIANGES,58260,THIANGES,,46.9121380764,3.49075278698,291,,Thianges,Thianges,58,Nièvre,27,Bourgogne-Franche-Comté
58292,TINTURY,58110,TINTURY,,47.0011371502,3.58766803098,292,,Tintury,Tintury,58,Nièvre,27,Bourgogne-Franche-Comté
58293,TOURY LURCY,58300,TOURY LURCY,,46.7373495509,3.42432236339,293,,Toury-Lurcy,Toury-Lurcy,58,Nièvre,27,Bourgogne-Franche-Comté
58294,TOURY SUR JOUR,58240,TOURY SUR JOUR,,46.729128696,3.25509046367,294,,Toury-sur-Jour,Toury-sur-Jour,58,Nièvre,27,Bourgogne-Franche-Comté
58295,TRACY SUR LOIRE,58150,TRACY SUR LOIRE,,47.3288567643,2.91378042455,295,,Tracy-sur-Loire,Tracy-sur-Loire,58,Nièvre,27,Bourgogne-Franche-Comté
58295,TRACY SUR LOIRE,58150,TRACY SUR LOIRE,BOISGIBAULT,47.3288567643,2.91378042455,295,,Tracy-sur-Loire,Tracy-sur-Loire,58,Nièvre,27,Bourgogne-Franche-Comté
58296,TRESNAY,58240,TRESNAY,,46.700299942,3.19457698203,296,,Tresnay,Tresnay,58,Nièvre,27,Bourgogne-Franche-Comté
58297,TROIS VEVRES,58260,TROIS VEVRES,,46.9112215122,3.42242349709,297,,Trois-Vèvres,Trois-Vèvres,58,Nièvre,27,Bourgogne-Franche-Comté
58298,TRONSANGES,58400,TRONSANGES,,47.1171713979,3.05070672439,298,,Tronsanges,Tronsanges,58,Nièvre,27,Bourgogne-Franche-Comté
58299,TRUCY L ORGUEILLEUX,58460,TRUCY L ORGUEILLEUX,,47.4516802367,3.40054273995,299,,Trucy-l'Orgueilleux,Trucy-l'Orgueilleux,58,Nièvre,27,Bourgogne-Franche-Comté
58300,URZY,58130,URZY,,47.0592677863,3.20126668844,300,,Urzy,Urzy,58,Nièvre,27,Bourgogne-Franche-Comté
58301,VANDENESSE,58290,VANDENESSE,,46.9128337385,3.77795147316,301,,Vandenesse,Vandenesse,58,Nièvre,27,Bourgogne-Franche-Comté
58302,VARENNES LES NARCY,58400,VARENNES LES NARCY,,47.2120070847,3.06549723601,302,,Varennes-lès-Narcy,Varennes-lès-Narcy,58,Nièvre,27,Bourgogne-Franche-Comté
58303,VARENNES VAUZELLES,58640,VARENNES VAUZELLES,,47.0372879565,3.14354311851,303,,Varennes-Vauzelles,Varennes-Vauzelles,58,Nièvre,27,Bourgogne-Franche-Comté
58304,VARZY,58210,VARZY,,47.356767544,3.38692732286,304,,Varzy,Varzy,58,Nièvre,27,Bourgogne-Franche-Comté
58305,VAUCLAIX,58140,VAUCLAIX,,47.2273609671,3.81810901103,305,,Vauclaix,Vauclaix,58,Nièvre,27,Bourgogne-Franche-Comté
58306,VERNEUIL,58300,VERNEUIL,,46.8603735972,3.58244196837,306,,Verneuil,Verneuil,58,Nièvre,27,Bourgogne-Franche-Comté
58307,VIELMANAY,58150,VIELMANAY,,47.2776012604,3.11071354211,307,,Vielmanay,Vielmanay,58,Nièvre,27,Bourgogne-Franche-Comté
58308,VIGNOL,58190,VIGNOL,,47.3582002671,3.66211871646,308,,Vignol,Vignol,58,Nièvre,27,Bourgogne-Franche-Comté
58309,VILLAPOURCON,58370,VILLAPOURCON,,46.9380595323,3.95652709792,309,,Villapourçon,Villapourçon,58,Nièvre,27,Bourgogne-Franche-Comté
58310,VILLIERS LE SEC,58210,VILLIERS LE SEC,,47.3770117779,3.43752498324,310,,Villiers-le-Sec,Villiers-le-Sec,58,Nièvre,27,Bourgogne-Franche-Comté
58311,VILLE LANGY,58270,VILLE LANGY,,46.9377064471,3.50190643749,311,,Ville-Langy,Ville-Langy,58,Nièvre,27,Bourgogne-Franche-Comté
58312,VILLIERS SUR YONNE,58500,VILLIERS SUR YONNE,,47.4134941256,3.54865482568,312,,Villiers-sur-Yonne,Villiers-sur-Yonne,58,Nièvre,27,Bourgogne-Franche-Comté
58313,VITRY LACHE,58420,VITRY LACHE,,47.1914761708,3.56906711354,313,,Vitry-Laché,Vitry-Laché,58,Nièvre,27,Bourgogne-Franche-Comté
59001,ABANCOURT,59268,ABANCOURT,,50.2368696873,3.20731301738,1,,Abancourt,Abancourt,59,Nord,32,Hauts-de-France
59002,ABSCON,59215,ABSCON,,50.3283410844,3.29787586969,2,,Abscon,Abscon,59,Nord,32,Hauts-de-France
59003,AIBES,59149,AIBES,,50.233709404,4.09622701433,3,,Aibes,Aibes,59,Nord,32,Hauts-de-France
59004,AIX,59310,AIX,,50.498421043,3.30536772065,4,,Aix,Aix,59,Nord,32,Hauts-de-France
59005,ALLENNES LES MARAIS,59251,ALLENNES LES MARAIS,,50.5417063572,2.94865877262,5,,Allennes-les-Marais,Allennes-les-Marais,59,Nord,32,Hauts-de-France
59006,AMFROIPRET,59144,AMFROIPRET,,50.2776390302,3.73565394566,6,,Amfroipret,Amfroipret,59,Nord,32,Hauts-de-France
59007,ANHIERS,59194,ANHIERS,,50.4047718446,3.15371031857,7,,Anhiers,Anhiers,59,Nord,32,Hauts-de-France
59008,ANICHE,59580,ANICHE,,50.330855319,3.25574141604,8,,Aniche,Aniche,59,Nord,32,Hauts-de-France
59009,VILLENEUVE D ASCQ,59491,VILLENEUVE D ASCQ,,50.6324372393,3.1535257221,9,,Villeneuve-d'Ascq,Villeneuve-d'Ascq,59,Nord,32,Hauts-de-France
59009,VILLENEUVE D ASCQ,59493,VILLENEUVE D ASCQ,,50.6324372393,3.1535257221,9,,Villeneuve-d'Ascq,Villeneuve-d'Ascq,59,Nord,32,Hauts-de-France
59009,VILLENEUVE D ASCQ,59650,VILLENEUVE D ASCQ,,50.6324372393,3.1535257221,9,,Villeneuve-d'Ascq,Villeneuve-d'Ascq,59,Nord,32,Hauts-de-France
59010,ANNEUX,59400,ANNEUX,,50.1570164384,3.12505671113,10,,Anneux,Anneux,59,Nord,32,Hauts-de-France
59011,ANNOEULLIN,59112,ANNOEULLIN,,50.5265153703,2.92753629479,11,,Annœullin,Annœullin,59,Nord,32,Hauts-de-France
59012,ANOR,59186,ANOR,,49.9949415311,4.11785622281,12,,Anor,Anor,59,Nord,32,Hauts-de-France
59013,ANSTAING,59152,ANSTAING,,50.603523536,3.18920215192,13,,Anstaing,Anstaing,59,Nord,32,Hauts-de-France
59014,ANZIN,59410,ANZIN,,50.3753643424,3.51113183833,14,,Anzin,Anzin,59,Nord,32,Hauts-de-France
59015,ARLEUX,59151,ARLEUX,,50.2820675827,3.11314479871,15,,Arleux,Arleux,59,Nord,32,Hauts-de-France
59016,ARMBOUTS CAPPEL,59380,ARMBOUTS CAPPEL,,50.981590247,2.34855524915,16,,Armbouts-Cappel,Armbouts-Cappel,59,Nord,32,Hauts-de-France
59017,ARMENTIERES,59280,ARMENTIERES,,50.6913797537,2.8797155328,17,,Armentières,Armentières,59,Nord,32,Hauts-de-France
59017,ARMENTIERES,59280,ARMENTIERES,LE BIZET,50.6913797537,2.8797155328,17,,Armentières,Armentières,59,Nord,32,Hauts-de-France
59018,ARNEKE,59285,ARNEKE,,50.8416889858,2.41843292762,18,,Arnèke,Arnèke,59,Nord,32,Hauts-de-France
59019,ARTRES,59269,ARTRES,,50.2956596119,3.53884943565,19,,Artres,Artres,59,Nord,32,Hauts-de-France
59021,ASSEVENT,59600,ASSEVENT,,50.2884411784,4.01973285077,21,,Assevent,Assevent,59,Nord,32,Hauts-de-France
59022,ATTICHES,59551,ATTICHES,,50.5138938279,3.06223374765,22,,Attiches,Attiches,59,Nord,32,Hauts-de-France
59023,AUBENCHEUL AU BAC,59265,AUBENCHEUL AU BAC,,50.2518526914,3.16168773742,23,,Aubencheul-au-Bac,Aubencheul-au-Bac,59,Nord,32,Hauts-de-France
59024,AUBERCHICOURT,59165,AUBERCHICOURT,,50.3299785774,3.22782402703,24,,Auberchicourt,Auberchicourt,59,Nord,32,Hauts-de-France
59025,AUBERS,59249,AUBERS,,50.5960514415,2.81995366841,25,,Aubers,Aubers,59,Nord,32,Hauts-de-France
59026,AUBIGNY AU BAC,59265,AUBIGNY AU BAC,,50.2659759447,3.16874604317,26,,Aubigny-au-Bac,Aubigny-au-Bac,59,Nord,32,Hauts-de-France
59027,AUBRY DU HAINAUT,59494,AUBRY DU HAINAUT,,50.3759590091,3.4522066329,27,,Aubry-du-Hainaut,Aubry-du-Hainaut,59,Nord,32,Hauts-de-France
59028,AUBY,59950,AUBY,,50.4142959158,3.06115822735,28,,Auby,Auby,59,Nord,32,Hauts-de-France
59029,AUCHY LEZ ORCHIES,59310,AUCHY LEZ ORCHIES,,50.480622996,3.19519541331,29,,Auchy-lez-Orchies,Auchy-lez-Orchies,59,Nord,32,Hauts-de-France
59031,AUDIGNIES,59570,AUDIGNIES,,50.2783465062,3.82456125193,31,,Audignies,Audignies,59,Nord,32,Hauts-de-France
59032,AULNOY LEZ VALENCIENNES,59300,AULNOY LEZ VALENCIENNES,,50.3261545044,3.53764264287,32,,Aulnoy-lez-Valenciennes,Aulnoy-lez-Valenciennes,59,Nord,32,Hauts-de-France
59033,AULNOYE AYMERIES,59620,AULNOYE AYMERIES,,50.2058749072,3.83263118494,33,,Aulnoye-Aymeries,Aulnoye-Aymeries,59,Nord,32,Hauts-de-France
59034,AVELIN,59710,AVELIN,,50.5397882047,3.08608433822,34,,Avelin,Avelin,59,Nord,32,Hauts-de-France
59035,AVESNELLES,59440,AVESNELLES,,50.1056589526,3.95096845588,35,,Avesnelles,Avesnelles,59,Nord,32,Hauts-de-France
59036,AVESNES SUR HELPE,59440,AVESNES SUR HELPE,,50.1242937124,3.92754898552,36,,Avesnes-sur-Helpe,Avesnes-sur-Helpe,59,Nord,32,Hauts-de-France
59037,AVESNES LES AUBERT,59129,AVESNES LES AUBERT,,50.1911782213,3.37676857334,37,,Avesnes-les-Aubert,Avesnes-les-Aubert,59,Nord,32,Hauts-de-France
59038,AVESNES LE SEC,59296,AVESNES LE SEC,,50.2483988689,3.37145460907,38,,Avesnes-le-Sec,Avesnes-le-Sec,59,Nord,32,Hauts-de-France
59039,AWOINGT,59400,AWOINGT,,50.153637436,3.28601914646,39,,Awoingt,Awoingt,59,Nord,32,Hauts-de-France
59041,BACHANT,59138,BACHANT,,50.2081006997,3.8751600192,41,,Bachant,Bachant,59,Nord,32,Hauts-de-France
59042,BACHY,59830,BACHY,,50.5435916608,3.26883958433,42,,Bachy,Bachy,59,Nord,32,Hauts-de-France
59043,BAILLEUL,59270,BAILLEUL,,50.7274252798,2.7379912081,43,,Bailleul,Bailleul,59,Nord,32,Hauts-de-France
59043,BAILLEUL,59270,BAILLEUL,MONT NOIR,50.7274252798,2.7379912081,43,,Bailleul,Bailleul,59,Nord,32,Hauts-de-France
59044,BAISIEUX,59780,BAISIEUX,,50.6111718868,3.24121907398,44,,Baisieux,Baisieux,59,Nord,32,Hauts-de-France
59045,BAIVES,59132,BAIVES,,50.0673923236,4.20393625141,45,,Baives,Baives,59,Nord,32,Hauts-de-France
59046,BAMBECQUE,59470,BAMBECQUE,,50.9087831017,2.56046766711,46,,Bambecque,Bambecque,59,Nord,32,Hauts-de-France
59047,BANTEUX,59266,BANTEUX,,50.0656630113,3.18790046791,47,,Banteux,Banteux,59,Nord,32,Hauts-de-France
59048,BANTIGNY,59554,BANTIGNY,,50.2342835957,3.23612444557,48,,Bantigny,Bantigny,59,Nord,32,Hauts-de-France
59049,BANTOUZELLE,59266,BANTOUZELLE,,50.0579473391,3.22511810175,49,,Bantouzelle,Bantouzelle,59,Nord,32,Hauts-de-France
59050,BAS LIEU,59440,BAS LIEU,,50.1443528184,3.95104519087,50,,Bas-Lieu,Bas-Lieu,59,Nord,32,Hauts-de-France
59051,LA BASSEE,59480,LA BASSEE,,50.5397647046,2.80910290453,51,La,Bassée,La Bassée,59,Nord,32,Hauts-de-France
59052,BAUVIN,59221,BAUVIN,,50.5162714452,2.89367809062,52,,Bauvin,Bauvin,59,Nord,32,Hauts-de-France
59053,BAVAY,59570,BAVAY,,50.2936087618,3.80308794658,53,,Bavay,Bavay,59,Nord,32,Hauts-de-France
59053,BAVAY,59570,BAVAY,LOUVIGNIES BAVAY,50.2936087618,3.80308794658,53,,Bavay,Bavay,59,Nord,32,Hauts-de-France
59054,BAVINCHOVE,59670,BAVINCHOVE,,50.7749510357,2.44160500507,54,,Bavinchove,Bavinchove,59,Nord,32,Hauts-de-France
59055,BAZUEL,59360,BAZUEL,,50.0816021977,3.59590423049,55,,Bazuel,Bazuel,59,Nord,32,Hauts-de-France
59056,BEAUCAMPS LIGNY,59134,BEAUCAMPS LIGNY,,50.6062622747,2.9147903974,56,,Beaucamps-Ligny,Beaucamps-Ligny,59,Nord,32,Hauts-de-France
59057,BEAUDIGNIES,59530,BEAUDIGNIES,,50.2296082323,3.58590888572,57,,Beaudignies,Beaudignies,59,Nord,32,Hauts-de-France
59058,BEAUFORT,59330,BEAUFORT,,50.2183201415,3.96567972154,58,,Beaufort,Beaufort,59,Nord,32,Hauts-de-France
59059,BEAUMONT EN CAMBRESIS,59540,BEAUMONT EN CAMBRESIS,,50.1265847908,3.45162407092,59,,Beaumont-en-Cambrésis,Beaumont-en-Cambrésis,59,Nord,32,Hauts-de-France
59060,BEAURAIN,59730,BEAURAIN,,50.1779797016,3.55059200886,60,,Beaurain,Beaurain,59,Nord,32,Hauts-de-France
59061,BEAUREPAIRE SUR SAMBRE,59550,BEAUREPAIRE SUR SAMBRE,,50.0605483796,3.79605984174,61,,Beaurepaire-sur-Sambre,Beaurepaire-sur-Sambre,59,Nord,32,Hauts-de-France
59062,BEAURIEUX,59740,BEAURIEUX,,50.1779588737,4.13702062275,62,,Beaurieux,Beaurieux,59,Nord,32,Hauts-de-France
59063,BEAUVOIS EN CAMBRESIS,59157,BEAUVOIS EN CAMBRESIS,,50.1403335661,3.38449590227,63,,Beauvois-en-Cambrésis,Beauvois-en-Cambrésis,59,Nord,32,Hauts-de-France
59064,BELLAING,59135,BELLAING,,50.3659312022,3.42553238229,64,,Bellaing,Bellaing,59,Nord,32,Hauts-de-France
59065,BELLIGNIES,59570,BELLIGNIES,,50.3225210245,3.76276954523,65,,Bellignies,Bellignies,59,Nord,32,Hauts-de-France
59066,BERELLES,59740,BERELLES,,50.2136798325,4.10541703727,66,,Bérelles,Bérelles,59,Nord,32,Hauts-de-France
59067,BERGUES,59380,BERGUES,,50.9691920635,2.43268784817,67,,Bergues,Bergues,59,Nord,32,Hauts-de-France
59068,BERLAIMONT,59145,BERLAIMONT,,50.2057229954,3.79391306597,68,,Berlaimont,Berlaimont,59,Nord,32,Hauts-de-France
59069,BERMERAIN,59213,BERMERAIN,,50.2575420594,3.5398919082,69,,Bermerain,Bermerain,59,Nord,32,Hauts-de-France
59070,BERMERIES,59570,BERMERIES,,50.2847571566,3.74905320173,70,,Bermeries,Bermeries,59,Nord,32,Hauts-de-France
59071,BERSEE,59235,BERSEE,,50.4804538835,3.15078397856,71,,Bersée,Bersée,59,Nord,32,Hauts-de-France
59072,BERSILLIES,59600,BERSILLIES,,50.3244968872,3.99559417236,72,,Bersillies,Bersillies,59,Nord,32,Hauts-de-France
59073,BERTHEN,59270,BERTHEN,,50.7779383016,2.6875622719,73,,Berthen,Berthen,59,Nord,32,Hauts-de-France
59073,BERTHEN,59270,BERTHEN,MONT DES CATS,50.7779383016,2.6875622719,73,,Berthen,Berthen,59,Nord,32,Hauts-de-France
59074,BERTRY,59980,BERTRY,,50.0823963526,3.43983505864,74,,Bertry,Bertry,59,Nord,32,Hauts-de-France
59075,BETHENCOURT,59540,BETHENCOURT,,50.1426560397,3.42342136756,75,,Béthencourt,Béthencourt,59,Nord,32,Hauts-de-France
59076,BETTIGNIES,59600,BETTIGNIES,,50.3323628525,3.9686190609,76,,Bettignies,Bettignies,59,Nord,32,Hauts-de-France
59077,BETTRECHIES,59570,BETTRECHIES,,50.3221407699,3.74013412317,77,,Bettrechies,Bettrechies,59,Nord,32,Hauts-de-France
59078,BEUGNIES,59216,BEUGNIES,,50.1628708508,3.99516722908,78,,Beugnies,Beugnies,59,Nord,32,Hauts-de-France
59079,BEUVRAGES,59192,BEUVRAGES,,50.3883826633,3.50595410504,79,,Beuvrages,Beuvrages,59,Nord,32,Hauts-de-France
59080,BEUVRY LA FORET,59310,BEUVRY LA FORET,,50.4499686964,3.28271096457,80,,Beuvry-la-Forêt,Beuvry-la-Forêt,59,Nord,32,Hauts-de-France
59081,BEVILLERS,59217,BEVILLERS,,50.158314463,3.39072994469,81,,Bévillers,Bévillers,59,Nord,32,Hauts-de-France
59082,BIERNE,59380,BIERNE,,50.9713270565,2.40211995661,82,,Bierne,Bierne,59,Nord,32,Hauts-de-France
59083,BISSEZEELE,59380,BISSEZEELE,,50.9131167962,2.40661835313,83,,Bissezeele,Bissezeele,59,Nord,32,Hauts-de-France
59084,BLARINGHEM,59173,BLARINGHEM,,50.6905234393,2.409606447,84,,Blaringhem,Blaringhem,59,Nord,32,Hauts-de-France
59085,BLECOURT,59268,BLECOURT,,50.2175061105,3.21286001901,85,,Blécourt,Blécourt,59,Nord,32,Hauts-de-France
59086,BOESCHEPE,59299,BOESCHEPE,,50.7989980219,2.69937041713,86,,Boeschepe,Boeschepe,59,Nord,32,Hauts-de-France
59087,BOESEGHEM,59189,BOESEGHEM,,50.6691135946,2.44242706344,87,,Boëseghem,Boëseghem,59,Nord,32,Hauts-de-France
59088,BOIS GRENIER,59280,BOIS GRENIER,,50.6487129112,2.87274435291,88,,Bois-Grenier,Bois-Grenier,59,Nord,32,Hauts-de-France
59089,BOLLEZEELE,59470,BOLLEZEELE,,50.8652273621,2.34136933381,89,,Bollezeele,Bollezeele,59,Nord,32,Hauts-de-France
59090,BONDUES,59910,BONDUES,,50.7096656233,3.09529083038,90,,Bondues,Bondues,59,Nord,32,Hauts-de-France
59091,BORRE,59190,BORRE,,50.731528018,2.58086143092,91,,Borre,Borre,59,Nord,32,Hauts-de-France
59092,BOUCHAIN,59111,BOUCHAIN,,50.2808463643,3.3121651121,92,,Bouchain,Bouchain,59,Nord,32,Hauts-de-France
59092,BOUCHAIN,59111,BOUCHAIN,BASSIN ROND,50.2808463643,3.3121651121,92,,Bouchain,Bouchain,59,Nord,32,Hauts-de-France
59093,BOULOGNE SUR HELPE,59440,BOULOGNE SUR HELPE,,50.0790302636,3.89209548407,93,,Boulogne-sur-Helpe,Boulogne-sur-Helpe,59,Nord,32,Hauts-de-France
59094,BOURBOURG,59630,BOURBOURG,,50.9424001702,2.20355448436,94,,Bourbourg,Bourbourg,59,Nord,32,Hauts-de-France
59096,BOURGHELLES,59830,BOURGHELLES,,50.5637205192,3.24739149692,96,,Bourghelles,Bourghelles,59,Nord,32,Hauts-de-France
59097,BOURSIES,59400,BOURSIES,,50.1408057238,3.04441612391,97,,Boursies,Boursies,59,Nord,32,Hauts-de-France
59098,BOUSBECQUE,59166,BOUSBECQUE,,50.7638184317,3.07542848088,98,,Bousbecque,Bousbecque,59,Nord,32,Hauts-de-France
59099,BOUSIES,59222,BOUSIES,,50.151363705,3.61511089589,99,,Bousies,Bousies,59,Nord,32,Hauts-de-France
59100,BOUSIGNIES,59178,BOUSIGNIES,,50.4302087604,3.35488860191,100,,Bousignies,Bousignies,59,Nord,32,Hauts-de-France
59101,BOUSIGNIES SUR ROC,59149,BOUSIGNIES SUR ROC,,50.2638826677,4.18728654005,101,,Bousignies-sur-Roc,Bousignies-sur-Roc,59,Nord,32,Hauts-de-France
59102,BOUSSIERES EN CAMBRESIS,59217,BOUSSIERES EN CAMBRESIS,,50.1638275467,3.37224279409,102,,Boussières-en-Cambrésis,Boussières-en-Cambrésis,59,Nord,32,Hauts-de-France
59103,BOUSSIERES SUR SAMBRE,59330,BOUSSIERES SUR SAMBRE,,50.2410266122,3.8784480036,103,,Boussières-sur-Sambre,Boussières-sur-Sambre,59,Nord,32,Hauts-de-France
59104,BOUSSOIS,59168,BOUSSOIS,,50.2948725712,4.04711513653,104,,Boussois,Boussois,59,Nord,32,Hauts-de-France
59105,BOUVIGNIES,59870,BOUVIGNIES,,50.432750868,3.23662350637,105,,Bouvignies,Bouvignies,59,Nord,32,Hauts-de-France
59106,BOUVINES,59830,BOUVINES,,50.5822303153,3.19348904281,106,,Bouvines,Bouvines,59,Nord,32,Hauts-de-France
59107,BRAY DUNES,59123,BRAY DUNES,,51.0730436867,2.52764870458,107,,Bray-Dunes,Bray-Dunes,59,Nord,32,Hauts-de-France
59108,BRIASTRE,59730,BRIASTRE,,50.154815937,3.48675100947,108,,Briastre,Briastre,59,Nord,32,Hauts-de-France
59109,BRILLON,59178,BRILLON,,50.4339939197,3.33534225599,109,,Brillon,Brillon,59,Nord,32,Hauts-de-France
59110,BROUCKERQUE,59630,BROUCKERQUE,,50.9515630712,2.28707334994,110,,Brouckerque,Brouckerque,59,Nord,32,Hauts-de-France
59111,BROXEELE,59470,BROXEELE,,50.8279362343,2.32136721551,111,,Broxeele,Broxeele,59,Nord,32,Hauts-de-France
59112,BRUAY SUR L ESCAUT,59860,BRUAY SUR L ESCAUT,,50.3973093421,3.53909152804,112,,Bruay-sur-l'Escaut,Bruay-sur-l'Escaut,59,Nord,32,Hauts-de-France
59113,BRUILLE LEZ MARCHIENNES,59490,BRUILLE LEZ MARCHIENNES,,50.3559112318,3.24310396614,113,,Bruille-lez-Marchiennes,Bruille-lez-Marchiennes,59,Nord,32,Hauts-de-France
59114,BRUILLE ST AMAND,59199,BRUILLE ST AMAND,,50.4652689949,3.50624365329,114,,Bruille-Saint-Amand,Bruille-Saint-Amand,59,Nord,32,Hauts-de-France
59115,BRUNEMONT,59151,BRUNEMONT,,50.2745061343,3.14482312096,115,,Brunémont,Brunémont,59,Nord,32,Hauts-de-France
59116,BRY,59144,BRY,,50.3162997425,3.68368839887,116,,Bry,Bry,59,Nord,32,Hauts-de-France
59117,BUGNICOURT,59151,BUGNICOURT,,50.2910659273,3.15468753018,117,,Bugnicourt,Bugnicourt,59,Nord,32,Hauts-de-France
59118,BUSIGNY,59137,BUSIGNY,,50.0375979868,3.46602965443,118,,Busigny,Busigny,59,Nord,32,Hauts-de-France
59119,BUYSSCHEURE,59285,BUYSSCHEURE,,50.806582613,2.33507855755,119,,Buysscheure,Buysscheure,59,Nord,32,Hauts-de-France
59120,CAESTRE,59190,CAESTRE,,50.7566001349,2.60456655751,120,,Caëstre,Caëstre,59,Nord,32,Hauts-de-France
59121,CAGNONCLES,59161,CAGNONCLES,,50.185423787,3.31868765658,121,,Cagnoncles,Cagnoncles,59,Nord,32,Hauts-de-France
59122,CAMBRAI,59400,CAMBRAI,,50.1702775977,3.24221021072,122,,Cambrai,Cambrai,59,Nord,32,Hauts-de-France
59122,CAMBRAI,59400,CAMBRAI,MORENCHIES,50.1702775977,3.24221021072,122,,Cambrai,Cambrai,59,Nord,32,Hauts-de-France
59123,CAMPHIN EN CAREMBAULT,59133,CAMPHIN EN CAREMBAULT,,50.5106436194,2.99165906644,123,,Camphin-en-Carembault,Camphin-en-Carembault,59,Nord,32,Hauts-de-France
59124,CAMPHIN EN PEVELE,59780,CAMPHIN EN PEVELE,,50.5923766053,3.25767476267,124,,Camphin-en-Pévèle,Camphin-en-Pévèle,59,Nord,32,Hauts-de-France
59125,CANTAING SUR ESCAUT,59267,CANTAING SUR ESCAUT,,50.1451657481,3.15708777258,125,,Cantaing-sur-Escaut,Cantaing-sur-Escaut,59,Nord,32,Hauts-de-France
59126,CANTIN,59169,CANTIN,,50.3097701017,3.1238994061,126,,Cantin,Cantin,59,Nord,32,Hauts-de-France
59127,CAPELLE,59213,CAPELLE,,50.2401009671,3.56039393735,127,,Capelle,Capelle,59,Nord,32,Hauts-de-France
59128,CAPINGHEM,59160,CAPINGHEM,,50.6474745374,2.96473852343,128,,Capinghem,Capinghem,59,Nord,32,Hauts-de-France
59129,CAPPELLE EN PEVELE,59242,CAPPELLE EN PEVELE,,50.5045434166,3.17433068381,129,,Cappelle-en-Pévèle,Cappelle-en-Pévèle,59,Nord,32,Hauts-de-France
59130,CAPPELLE BROUCK,59630,CAPPELLE BROUCK,,50.8938368005,2.22204306272,130,,Cappelle-Brouck,Cappelle-Brouck,59,Nord,32,Hauts-de-France
59131,CAPPELLE LA GRANDE,59180,CAPPELLE LA GRANDE,,50.9960740777,2.37003367458,131,,Cappelle-la-Grande,Cappelle-la-Grande,59,Nord,32,Hauts-de-France
59132,CARNIERES,59217,CARNIERES,,50.1649512039,3.34587128459,132,,Carnières,Carnières,59,Nord,32,Hauts-de-France
59133,CARNIN,59112,CARNIN,,50.5196965937,2.96024467267,133,,Carnin,Carnin,59,Nord,32,Hauts-de-France
59134,CARTIGNIES,59244,CARTIGNIES,,50.0779397385,3.84271561148,134,,Cartignies,Cartignies,59,Nord,32,Hauts-de-France
59135,CASSEL,59670,CASSEL,,50.795681277,2.49423243668,135,,Cassel,Cassel,59,Nord,32,Hauts-de-France
59136,LE CATEAU CAMBRESIS,59360,LE CATEAU CAMBRESIS,,50.0920093814,3.53922472873,136,Le,Cateau-Cambrésis,Le Cateau-Cambrésis,59,Nord,32,Hauts-de-France
59137,CATILLON SUR SAMBRE,59360,CATILLON SUR SAMBRE,,50.0704090435,3.64485751091,137,,Catillon-sur-Sambre,Catillon-sur-Sambre,59,Nord,32,Hauts-de-France
59138,CATTENIERES,59217,CATTENIERES,,50.1329900452,3.33660861697,138,,Cattenières,Cattenières,59,Nord,32,Hauts-de-France
59139,CAUDRY,59540,CAUDRY,,50.1187185043,3.4192394153,139,,Caudry,Caudry,59,Nord,32,Hauts-de-France
59139,CAUDRY,59540,CAUDRY,AUDENCOURT,50.1187185043,3.4192394153,139,,Caudry,Caudry,59,Nord,32,Hauts-de-France
59140,CAULLERY,59191,CAULLERY,,50.0824546804,3.37276572782,140,,Caullery,Caullery,59,Nord,32,Hauts-de-France
59141,CAUROIR,59400,CAUROIR,,50.1720373952,3.29914293305,141,,Cauroir,Cauroir,59,Nord,32,Hauts-de-France
59142,CERFONTAINE,59680,CERFONTAINE,,50.2591419146,4.02505740703,142,,Cerfontaine,Cerfontaine,59,Nord,32,Hauts-de-France
59143,LA CHAPELLE D ARMENTIERES,59930,LA CHAPELLE D ARMENTIERES,,50.6659536434,2.89558804665,143,La,Chapelle-d'Armentières,La Chapelle-d'Armentières,59,Nord,32,Hauts-de-France
59144,CHATEAU L ABBAYE,59230,CHATEAU L ABBAYE,,50.4858024683,3.47414949128,144,,Château-l'Abbaye,Château-l'Abbaye,59,Nord,32,Hauts-de-France
59145,CHEMY,59147,CHEMY,,50.5317873736,2.99537239927,145,,Chemy,Chemy,59,Nord,32,Hauts-de-France
59146,CHERENG,59152,CHERENG,,50.6108890768,3.20972154636,146,,Chéreng,Chéreng,59,Nord,32,Hauts-de-France
59147,CHOISIES,59740,CHOISIES,,50.212677337,4.04878255804,147,,Choisies,Choisies,59,Nord,32,Hauts-de-France
59148,CLAIRFAYTS,59740,CLAIRFAYTS,,50.1564472386,4.12464922424,148,,Clairfayts,Clairfayts,59,Nord,32,Hauts-de-France
59149,CLARY,59225,CLARY,,50.0684086956,3.39589708434,149,,Clary,Clary,59,Nord,32,Hauts-de-France
59150,COBRIEUX,59830,COBRIEUX,,50.5391896198,3.24034953595,150,,Cobrieux,Cobrieux,59,Nord,32,Hauts-de-France
59151,COLLERET,59680,COLLERET,,50.2598488269,4.084768244,151,,Colleret,Colleret,59,Nord,32,Hauts-de-France
59152,COMINES,59560,COMINES,,50.7497420422,3.01679477405,152,,Comines,Comines,59,Nord,32,Hauts-de-France
59153,CONDE SUR L ESCAUT,59163,CONDE SUR L ESCAUT,,50.4678612037,3.60589087978,153,,Condé-sur-l'Escaut,Condé-sur-l'Escaut,59,Nord,32,Hauts-de-France
59155,COUDEKERQUE BRANCHE,59210,COUDEKERQUE BRANCHE,,51.0179541343,2.39580832593,155,,Coudekerque-Branche,Coudekerque-Branche,59,Nord,32,Hauts-de-France
59156,COURCHELETTES,59552,COURCHELETTES,,50.3421628278,3.05975008268,156,,Courchelettes,Courchelettes,59,Nord,32,Hauts-de-France
59157,COUSOLRE,59149,COUSOLRE,,50.2372536419,4.15308223751,157,,Cousolre,Cousolre,59,Nord,32,Hauts-de-France
59158,COUTICHES,59310,COUTICHES,,50.4501366141,3.19335565357,158,,Coutiches,Coutiches,59,Nord,32,Hauts-de-France
59159,CRAYWICK,59279,CRAYWICK,,50.9726285741,2.22142508421,159,,Craywick,Craywick,59,Nord,32,Hauts-de-France
59160,CRESPIN,59154,CRESPIN,,50.4217331557,3.65237979993,160,,Crespin,Crespin,59,Nord,32,Hauts-de-France
59160,CRESPIN,59154,CRESPIN,BLANC MISSERON ANF,50.4217331557,3.65237979993,160,,Crespin,Crespin,59,Nord,32,Hauts-de-France
59161,CREVECOEUR SUR L ESCAUT,59258,CREVECOEUR SUR L ESCAUT,,50.0727577904,3.26867770233,161,,Crèvecœur-sur-l'Escaut,Crèvecœur-sur-l'Escaut,59,Nord,32,Hauts-de-France
59162,CROCHTE,59380,CROCHTE,,50.9238758959,2.39595026667,162,,Crochte,Crochte,59,Nord,32,Hauts-de-France
59163,CROIX,59170,CROIX,,50.6740689994,3.15464041723,163,,Croix,Croix,59,Nord,32,Hauts-de-France
59164,CROIX CALUYAU,59222,CROIX CALUYAU,,50.152088377,3.58548579583,164,,Croix-Caluyau,Croix-Caluyau,59,Nord,32,Hauts-de-France
59165,CUINCY,59553,CUINCY,,50.3743818914,3.03412629786,165,,Cuincy,Cuincy,59,Nord,32,Hauts-de-France
59166,CURGIES,59990,CURGIES,,50.3253821605,3.60666630753,166,,Curgies,Curgies,59,Nord,32,Hauts-de-France
59167,CUVILLERS,59268,CUVILLERS,,50.2219798771,3.23832511042,167,,Cuvillers,Cuvillers,59,Nord,32,Hauts-de-France
59168,CYSOING,59830,CYSOING,,50.5639123041,3.21398704215,168,,Cysoing,Cysoing,59,Nord,32,Hauts-de-France
59169,DAMOUSIES,59680,DAMOUSIES,,50.2195042293,4.00850987305,169,,Damousies,Damousies,59,Nord,32,Hauts-de-France
59170,DECHY,59187,DECHY,,50.3495940778,3.1299861719,170,,Dechy,Dechy,59,Nord,32,Hauts-de-France
59171,DEHERIES,59127,DEHERIES,,50.0505594446,3.34352939122,171,,Dehéries,Dehéries,59,Nord,32,Hauts-de-France
59172,DENAIN,59220,DENAIN,,50.3299660733,3.3898623357,172,,Denain,Denain,59,Nord,32,Hauts-de-France
59173,DEULEMONT,59890,DEULEMONT,,50.7280171454,2.96967815507,173,,Deûlémont,Deûlémont,59,Nord,32,Hauts-de-France
59174,DIMECHAUX,59740,DIMECHAUX,,50.1970283505,4.04666180439,174,,Dimechaux,Dimechaux,59,Nord,32,Hauts-de-France
59175,DIMONT,59216,DIMONT,,50.1830406458,4.02228377235,175,,Dimont,Dimont,59,Nord,32,Hauts-de-France
59176,DOIGNIES,59400,DOIGNIES,,50.1321485769,3.01379384494,176,,Doignies,Doignies,59,Nord,32,Hauts-de-France
59177,DOMPIERRE SUR HELPE,59440,DOMPIERRE SUR HELPE,,50.1420178536,3.8595206672,177,,Dompierre-sur-Helpe,Dompierre-sur-Helpe,59,Nord,32,Hauts-de-France
59178,DOUAI,59500,DOUAI,,50.3823195335,3.09145683114,178,,Douai,Douai,59,Nord,32,Hauts-de-France
59178,DOUAI,59500,DOUAI,DORIGNIES,50.3823195335,3.09145683114,178,,Douai,Douai,59,Nord,32,Hauts-de-France
59178,DOUAI,59500,DOUAI,FRAIS MARAIS,50.3823195335,3.09145683114,178,,Douai,Douai,59,Nord,32,Hauts-de-France
59179,DOUCHY LES MINES,59282,DOUCHY LES MINES,,50.2994352925,3.38947295524,179,,Douchy-les-Mines,Douchy-les-Mines,59,Nord,32,Hauts-de-France
59180,LE DOULIEU,59940,LE DOULIEU,,50.6800093864,2.69977444114,180,Le,Doulieu,Le Doulieu,59,Nord,32,Hauts-de-France
59181,DOURLERS,59440,DOURLERS,,50.1745636905,3.94546756925,181,,Dourlers,Dourlers,59,Nord,32,Hauts-de-France
59182,DRINCHAM,59630,DRINCHAM,,50.9104379281,2.31996661732,182,,Drincham,Drincham,59,Nord,32,Hauts-de-France
59183,DUNKERQUE,59140,DUNKERQUE,,51.0307229078,2.33752414095,183,,Dunkerque,Dunkerque,59,Nord,32,Hauts-de-France
59183,DUNKERQUE,59240,DUNKERQUE,,51.0307229078,2.33752414095,183,,Dunkerque,Dunkerque,59,Nord,32,Hauts-de-France
59183,DUNKERQUE,59240,DUNKERQUE,MALO LES BAINS,51.0307229078,2.33752414095,183,,Dunkerque,Dunkerque,59,Nord,32,Hauts-de-France
59183,DUNKERQUE,59240,DUNKERQUE,ROSENDAEL,51.0307229078,2.33752414095,183,,Dunkerque,Dunkerque,59,Nord,32,Hauts-de-France
59183,DUNKERQUE,59279,DUNKERQUE,MARDYCK,51.0307229078,2.33752414095,183,,Dunkerque,Dunkerque,59,Nord,32,Hauts-de-France
59183,DUNKERQUE,59430,DUNKERQUE,FORT MARDYCK,51.0307229078,2.33752414095,183,,Dunkerque,Dunkerque,59,Nord,32,Hauts-de-France
59183,DUNKERQUE,59430,DUNKERQUE,ST POL SUR MER,51.0307229078,2.33752414095,183,,Dunkerque,Dunkerque,59,Nord,32,Hauts-de-France
59183,DUNKERQUE,59640,DUNKERQUE,,51.0307229078,2.33752414095,183,,Dunkerque,Dunkerque,59,Nord,32,Hauts-de-France
59183,DUNKERQUE,59640,DUNKERQUE,PETITE SYNTHE,51.0307229078,2.33752414095,183,,Dunkerque,Dunkerque,59,Nord,32,Hauts-de-France
59184,EBBLINGHEM,59173,EBBLINGHEM,,50.734564266,2.41884672597,184,,Ebblinghem,Ebblinghem,59,Nord,32,Hauts-de-France
59185,ECAILLON,59176,ECAILLON,,50.3482315234,3.21529635453,185,,Écaillon,Écaillon,59,Nord,32,Hauts-de-France
59186,ECCLES,59740,ECCLES,,50.1988413348,4.09813341605,186,,Eccles,Eccles,59,Nord,32,Hauts-de-France
59187,ECLAIBES,59330,ECLAIBES,,50.1999798551,3.93837593672,187,,Éclaibes,Éclaibes,59,Nord,32,Hauts-de-France
59188,ECUELIN,59620,ECUELIN,,50.1924531151,3.89604149257,188,,Écuélin,Écuélin,59,Nord,32,Hauts-de-France
59189,EECKE,59114,EECKE,,50.7800648549,2.59723080781,189,,Eecke,Eecke,59,Nord,32,Hauts-de-France
59190,ELESMES,59600,ELESMES,,50.3048578036,4.01140019092,190,,Élesmes,Élesmes,59,Nord,32,Hauts-de-France
59191,ELINCOURT,59127,ELINCOURT,,50.0457660349,3.37059499043,191,,Élincourt,Élincourt,59,Nord,32,Hauts-de-France
59192,EMERCHICOURT,59580,EMERCHICOURT,,50.3117441641,3.25561088678,192,,Émerchicourt,Émerchicourt,59,Nord,32,Hauts-de-France
59193,EMMERIN,59320,EMMERIN,,50.5908761975,3.00549490357,193,,Emmerin,Emmerin,59,Nord,32,Hauts-de-France
59194,ENGLEFONTAINE,59530,ENGLEFONTAINE,,50.1933240477,3.64157019307,194,,Englefontaine,Englefontaine,59,Nord,32,Hauts-de-France
59195,ENGLOS,59320,ENGLOS,,50.6262888459,2.9579931684,195,,Englos,Englos,59,Nord,32,Hauts-de-France
59196,ENNETIERES EN WEPPES,59320,ENNETIERES EN WEPPES,,50.640484704,2.93312247961,196,,Ennetières-en-Weppes,Ennetières-en-Weppes,59,Nord,32,Hauts-de-France
59197,ENNEVELIN,59710,ENNEVELIN,,50.5387195422,3.12792567754,197,,Ennevelin,Ennevelin,59,Nord,32,Hauts-de-France
59198,EPPE SAUVAGE,59132,EPPE SAUVAGE,,50.1185512931,4.16530475504,198,,Eppe-Sauvage,Eppe-Sauvage,59,Nord,32,Hauts-de-France
59199,ERCHIN,59169,ERCHIN,,50.3168262116,3.17348026694,199,,Erchin,Erchin,59,Nord,32,Hauts-de-France
59200,ERINGHEM,59470,ERINGHEM,,50.889329927,2.31351250902,200,,Eringhem,Eringhem,59,Nord,32,Hauts-de-France
59201,ERQUINGHEM LE SEC,59320,ERQUINGHEM LE SEC,,50.6103293262,2.93381789085,201,,Erquinghem-le-Sec,Erquinghem-le-Sec,59,Nord,32,Hauts-de-France
59202,ERQUINGHEM LYS,59193,ERQUINGHEM LYS,,50.6700847929,2.84140786831,202,,Erquinghem-Lys,Erquinghem-Lys,59,Nord,32,Hauts-de-France
59203,ERRE,59171,ERRE,,50.3693524443,3.31597211762,203,,Erre,Erre,59,Nord,32,Hauts-de-France
59204,ESCARMAIN,59213,ESCARMAIN,,50.2236333668,3.54874778179,204,,Escarmain,Escarmain,59,Nord,32,Hauts-de-France
59205,ESCAUDAIN,59124,ESCAUDAIN,,50.3339160351,3.34129451552,205,,Escaudain,Escaudain,59,Nord,32,Hauts-de-France
59206,ESCAUDOEUVRES,59161,ESCAUDOEUVRES,,50.1924313857,3.27406978358,206,,Escaudœuvres,Escaudœuvres,59,Nord,32,Hauts-de-France
59207,ESCAUTPONT,59278,ESCAUTPONT,,50.4239013457,3.5531225813,207,,Escautpont,Escautpont,59,Nord,32,Hauts-de-France
59208,ESCOBECQUES,59320,ESCOBECQUES,,50.6212631237,2.93273720306,208,,Escobecques,Escobecques,59,Nord,32,Hauts-de-France
59209,ESNES,59127,ESNES,,50.0953832001,3.30848102705,209,,Esnes,Esnes,59,Nord,32,Hauts-de-France
59210,ESQUELBECQ,59470,ESQUELBECQ,,50.8902971553,2.42902144416,210,,Esquelbecq,Esquelbecq,59,Nord,32,Hauts-de-France
59211,ESQUERCHIN,59553,ESQUERCHIN,,50.3859330394,3.00649318201,211,,Esquerchin,Esquerchin,59,Nord,32,Hauts-de-France
59212,ESTAIRES,59940,ESTAIRES,,50.654423304,2.72156583757,212,,Estaires,Estaires,59,Nord,32,Hauts-de-France
59213,ESTOURMEL,59400,ESTOURMEL,,50.1518286613,3.32132515587,213,,Estourmel,Estourmel,59,Nord,32,Hauts-de-France
59214,ESTREES,59151,ESTREES,,50.2977355864,3.07513052042,214,,Estrées,Estrées,59,Nord,32,Hauts-de-France
59215,ESTREUX,59990,ESTREUX,,50.3527239271,3.60184515387,215,,Estreux,Estreux,59,Nord,32,Hauts-de-France
59216,ESWARS,59161,ESWARS,,50.2182439323,3.26546336103,216,,Eswars,Eswars,59,Nord,32,Hauts-de-France
59217,ETH,59144,ETH,,50.3253571369,3.66687653832,217,,Eth,Eth,59,Nord,32,Hauts-de-France
59218,ETROEUNGT,59219,ETROEUNGT,,50.0620601477,3.94319652795,218,,Étrœungt,Étrœungt,59,Nord,32,Hauts-de-France
59219,ESTRUN,59295,ESTRUN,,50.2447242764,3.29498867507,219,,Estrun,Estrun,59,Nord,32,Hauts-de-France
59220,FACHES THUMESNIL,59155,FACHES THUMESNIL,,50.593618421,3.07229537426,220,,Faches-Thumesnil,Faches-Thumesnil,59,Nord,32,Hauts-de-France
59221,FAMARS,59300,FAMARS,,50.3123396289,3.52179790977,221,,Famars,Famars,59,Nord,32,Hauts-de-France
59222,FAUMONT,59310,FAUMONT,,50.4531008477,3.13526855992,222,,Faumont,Faumont,59,Nord,32,Hauts-de-France
59223,LE FAVRIL,59550,LE FAVRIL,,50.0938595271,3.71988086994,223,Le,Favril,Le Favril,59,Nord,32,Hauts-de-France
59224,FECHAIN,59247,FECHAIN,,50.268615561,3.212900112,224,,Féchain,Féchain,59,Nord,32,Hauts-de-France
59225,FEIGNIES,59750,FEIGNIES,,50.2944744456,3.90909278075,225,,Feignies,Feignies,59,Nord,32,Hauts-de-France
59225,FEIGNIES,59750,FEIGNIES,DOUZIES FEIGNIES,50.2944744456,3.90909278075,225,,Feignies,Feignies,59,Nord,32,Hauts-de-France
59226,FELLERIES,59740,FELLERIES,,50.1407847953,4.05118081621,226,,Felleries,Felleries,59,Nord,32,Hauts-de-France
59227,FENAIN,59179,FENAIN,,50.3681590708,3.30010560597,227,,Fenain,Fenain,59,Nord,32,Hauts-de-France
59228,FERIN,59169,FERIN,,50.3309978415,3.08128816904,228,,Férin,Férin,59,Nord,32,Hauts-de-France
59229,FERON,59610,FERON,,50.0482056585,4.01906957461,229,,Féron,Féron,59,Nord,32,Hauts-de-France
59230,FERRIERE LA GRANDE,59680,FERRIERE LA GRANDE,,50.2456386476,3.99089727257,230,,Ferrière-la-Grande,Ferrière-la-Grande,59,Nord,32,Hauts-de-France
59231,FERRIERE LA PETITE,59680,FERRIERE LA PETITE,,50.2403988666,4.02739766598,231,,Ferrière-la-Petite,Ferrière-la-Petite,59,Nord,32,Hauts-de-France
59232,LA FLAMENGRIE,59570,LA FLAMENGRIE,,50.3066443243,3.72273889632,232,La,Flamengrie,La Flamengrie,59,Nord,32,Hauts-de-France
59233,FLAUMONT WAUDRECHIES,59440,FLAUMONT WAUDRECHIES,,50.1311318318,3.96984931969,233,,Flaumont-Waudrechies,Flaumont-Waudrechies,59,Nord,32,Hauts-de-France
59234,FLERS EN ESCREBIEUX,59128,FLERS EN ESCREBIEUX,,50.401307148,3.05136553917,234,,Flers-en-Escrebieux,Flers-en-Escrebieux,59,Nord,32,Hauts-de-France
59236,FLESQUIERES,59267,FLESQUIERES,,50.1238325838,3.11938780816,236,,Flesquières,Flesquières,59,Nord,32,Hauts-de-France
59237,FLETRE,59270,FLETRE,,50.758011537,2.64096480956,237,,Flêtre,Flêtre,59,Nord,32,Hauts-de-France
59238,FLINES LES MORTAGNE,59158,FLINES LES MORTAGNE,,50.5128551387,3.48452339444,238,,Flines-lès-Mortagne,Flines-lès-Mortagne,59,Nord,32,Hauts-de-France
59239,FLINES LEZ RACHES,59148,FLINES LEZ RACHES,,50.4164214514,3.18241517385,239,,Flines-lez-Raches,Flines-lez-Raches,59,Nord,32,Hauts-de-France
59240,FLOURSIES,59440,FLOURSIES,,50.1863221041,3.97166819813,240,,Floursies,Floursies,59,Nord,32,Hauts-de-France
59241,FLOYON,59219,FLOYON,,50.0412036226,3.88418001471,241,,Floyon,Floyon,59,Nord,32,Hauts-de-France
59242,FONTAINE AU BOIS,59550,FONTAINE AU BOIS,,50.1363749485,3.64250579372,242,,Fontaine-au-Bois,Fontaine-au-Bois,59,Nord,32,Hauts-de-France
59243,FONTAINE AU PIRE,59157,FONTAINE AU PIRE,,50.127252452,3.36673958113,243,,Fontaine-au-Pire,Fontaine-au-Pire,59,Nord,32,Hauts-de-France
59244,FONTAINE NOTRE DAME,59400,FONTAINE NOTRE DAME,,50.1686299394,3.16830361827,244,,Fontaine-Notre-Dame,Fontaine-Notre-Dame,59,Nord,32,Hauts-de-France
59246,FOREST EN CAMBRESIS,59222,FOREST EN CAMBRESIS,,50.1406649942,3.57049484282,246,,Forest-en-Cambrésis,Forest-en-Cambrésis,59,Nord,32,Hauts-de-France
59247,FOREST SUR MARQUE,59510,FOREST SUR MARQUE,,50.6330739631,3.18918578659,247,,Forest-sur-Marque,Forest-sur-Marque,59,Nord,32,Hauts-de-France
59249,FOURMIES,59610,FOURMIES,,50.006893732,4.05775002509,249,,Fourmies,Fourmies,59,Nord,32,Hauts-de-France
59250,FOURNES EN WEPPES,59134,FOURNES EN WEPPES,,50.5917277363,2.89362071865,250,,Fournes-en-Weppes,Fournes-en-Weppes,59,Nord,32,Hauts-de-France
59251,FRASNOY,59530,FRASNOY,,50.2750060846,3.66453718802,251,,Frasnoy,Frasnoy,59,Nord,32,Hauts-de-France
59252,FRELINGHIEN,59236,FRELINGHIEN,,50.703109663,2.95178380263,252,,Frelinghien,Frelinghien,59,Nord,32,Hauts-de-France
59253,FRESNES SUR ESCAUT,59970,FRESNES SUR ESCAUT,,50.432489562,3.57931049864,253,,Fresnes-sur-Escaut,Fresnes-sur-Escaut,59,Nord,32,Hauts-de-France
59254,FRESSAIN,59234,FRESSAIN,,50.2829594632,3.19200167251,254,,Fressain,Fressain,59,Nord,32,Hauts-de-France
59255,FRESSIES,59268,FRESSIES,,50.2494910786,3.18888373782,255,,Fressies,Fressies,59,Nord,32,Hauts-de-France
59256,FRETIN,59273,FRETIN,,50.5653657611,3.1322045776,256,,Fretin,Fretin,59,Nord,32,Hauts-de-France
59257,FROMELLES,59249,FROMELLES,,50.6109686203,2.85464946616,257,,Fromelles,Fromelles,59,Nord,32,Hauts-de-France
59258,GENECH,59242,GENECH,,50.5285200565,3.22237664062,258,,Genech,Genech,59,Nord,32,Hauts-de-France
59259,GHISSIGNIES,59530,GHISSIGNIES,,50.2277589853,3.61633962969,259,,Ghissignies,Ghissignies,59,Nord,32,Hauts-de-France
59260,GHYVELDE,59122,GHYVELDE,LES MOERES,51.0486014481,2.51379722419,260,,Ghyvelde,Ghyvelde,59,Nord,32,Hauts-de-France
59260,GHYVELDE,59254,GHYVELDE,,51.0486014481,2.51379722419,260,,Ghyvelde,Ghyvelde,59,Nord,32,Hauts-de-France
59261,GLAGEON,59132,GLAGEON,,50.0506114879,4.06404937092,261,,Glageon,Glageon,59,Nord,32,Hauts-de-France
59262,GODEWAERSVELDE,59270,GODEWAERSVELDE,,50.7950511501,2.6455520339,262,,Godewaersvelde,Godewaersvelde,59,Nord,32,Hauts-de-France
59263,GOEULZIN,59169,GOEULZIN,,50.3185089576,3.09937496212,263,,Gœulzin,Gœulzin,59,Nord,32,Hauts-de-France
59264,GOGNIES CHAUSSEE,59600,GOGNIES CHAUSSEE,,50.3269897728,3.94228730102,264,,Gognies-Chaussée,Gognies-Chaussée,59,Nord,32,Hauts-de-France
59265,GOMMEGNIES,59144,GOMMEGNIES,,50.2650047987,3.70836085584,265,,Gommegnies,Gommegnies,59,Nord,32,Hauts-de-France
59266,GONDECOURT,59147,GONDECOURT,,50.5478874512,2.98009724993,266,,Gondecourt,Gondecourt,59,Nord,32,Hauts-de-France
59267,GONNELIEU,59231,GONNELIEU,,50.059740161,3.15907798363,267,,Gonnelieu,Gonnelieu,59,Nord,32,Hauts-de-France
59268,LA GORGUE,59253,LA GORGUE,,50.6309281138,2.73945543193,268,La,Gorgue,La Gorgue,59,Nord,32,Hauts-de-France
59269,GOUZEAUCOURT,59231,GOUZEAUCOURT,,50.0555182827,3.11588275361,269,,Gouzeaucourt,Gouzeaucourt,59,Nord,32,Hauts-de-France
59270,GRAND FAYT,59244,GRAND FAYT,,50.1123607999,3.79567600764,270,,Grand-Fayt,Grand-Fayt,59,Nord,32,Hauts-de-France
59271,GRANDE SYNTHE,59760,GRANDE SYNTHE,,51.0167874642,2.29071143357,271,,Grande-Synthe,Grande-Synthe,59,Nord,32,Hauts-de-France
59272,GRAND FORT PHILIPPE,59153,GRAND FORT PHILIPPE,,50.9994095872,2.10036684474,272,,Grand-Fort-Philippe,Grand-Fort-Philippe,59,Nord,32,Hauts-de-France
59273,GRAVELINES,59820,GRAVELINES,,50.9975626638,2.14413046485,273,,Gravelines,Gravelines,59,Nord,32,Hauts-de-France
59274,LA GROISE,59360,LA GROISE,,50.0763719216,3.6815589114,274,La,Groise,La Groise,59,Nord,32,Hauts-de-France
59275,GRUSON,59152,GRUSON,,50.5924125411,3.21559274036,275,,Gruson,Gruson,59,Nord,32,Hauts-de-France
59276,GUESNAIN,59287,GUESNAIN,,50.3483461671,3.14816711364,276,,Guesnain,Guesnain,59,Nord,32,Hauts-de-France
59277,GUSSIGNIES,59570,GUSSIGNIES,,50.3405832772,3.74998509899,277,,Gussignies,Gussignies,59,Nord,32,Hauts-de-France
59278,HALLENNES LEZ HAUBOURDIN,59320,HALLENNES LEZ HAUBOURDIN,,50.6120409794,2.95454404454,278,,Hallennes-lez-Haubourdin,Hallennes-lez-Haubourdin,59,Nord,32,Hauts-de-France
59279,HALLUIN,59250,HALLUIN,,50.7747480298,3.12692474358,279,,Halluin,Halluin,59,Nord,32,Hauts-de-France
59280,HAMEL,59151,HAMEL,,50.2819824197,3.06850591061,280,,Hamel,Hamel,59,Nord,32,Hauts-de-France
59281,HANTAY,59496,HANTAY,,50.5374677667,2.86845922313,281,,Hantay,Hantay,59,Nord,32,Hauts-de-France
59282,HARDIFORT,59670,HARDIFORT,,50.8187124794,2.48138038993,282,,Hardifort,Hardifort,59,Nord,32,Hauts-de-France
59283,HARGNIES,59138,HARGNIES,,50.2577740991,3.84173807004,283,,Hargnies,Hargnies,59,Nord,32,Hauts-de-France
59284,HASNON,59178,HASNON,,50.4127809249,3.38752842826,284,,Hasnon,Hasnon,59,Nord,32,Hauts-de-France
59285,HASPRES,59198,HASPRES,,50.2650877152,3.41176296658,285,,Haspres,Haspres,59,Nord,32,Hauts-de-France
59286,HAUBOURDIN,59320,HAUBOURDIN,,50.6044150332,2.98727183184,286,,Haubourdin,Haubourdin,59,Nord,32,Hauts-de-France
59287,HAUCOURT EN CAMBRESIS,59191,HAUCOURT EN CAMBRESIS,,50.1068286942,3.34360726309,287,,Haucourt-en-Cambrésis,Haucourt-en-Cambrésis,59,Nord,32,Hauts-de-France
59288,HAULCHIN,59121,HAULCHIN,,50.3133069663,3.42273486523,288,,Haulchin,Haulchin,59,Nord,32,Hauts-de-France
59289,HAUSSY,59294,HAUSSY,,50.2223988964,3.4797106662,289,,Haussy,Haussy,59,Nord,32,Hauts-de-France
59290,HAUT LIEU,59440,HAUT LIEU,,50.1031693794,3.90753338648,290,,Haut-Lieu,Haut-Lieu,59,Nord,32,Hauts-de-France
59291,HAUTMONT,59330,HAUTMONT,,50.2478507103,3.91948913803,291,,Hautmont,Hautmont,59,Nord,32,Hauts-de-France
59292,HAVELUY,59255,HAVELUY,,50.3537384586,3.40310314362,292,,Haveluy,Haveluy,59,Nord,32,Hauts-de-France
59293,HAVERSKERQUE,59660,HAVERSKERQUE,,50.6403332971,2.54677365157,293,,Haverskerque,Haverskerque,59,Nord,32,Hauts-de-France
59294,HAYNECOURT,59268,HAYNECOURT,,50.2094536408,3.15541640096,294,,Haynecourt,Haynecourt,59,Nord,32,Hauts-de-France
59295,HAZEBROUCK,59190,HAZEBROUCK,,50.7262967113,2.53864301455,295,,Hazebrouck,Hazebrouck,59,Nord,32,Hauts-de-France
59296,HECQ,59530,HECQ,,50.1795272818,3.65140738395,296,,Hecq,Hecq,59,Nord,32,Hauts-de-France
59297,HELESMES,59171,HELESMES,,50.3677526981,3.35840586833,297,,Hélesmes,Hélesmes,59,Nord,32,Hauts-de-France
59299,HEM,59510,HEM,,50.6548049456,3.19305632608,299,,Hem,Hem,59,Nord,32,Hauts-de-France
59300,HEM LENGLET,59247,HEM LENGLET,,50.2528760846,3.22719072906,300,,Hem-Lenglet,Hem-Lenglet,59,Nord,32,Hauts-de-France
59301,HERGNIES,59199,HERGNIES,,50.4809811628,3.53133874107,301,,Hergnies,Hergnies,59,Nord,32,Hauts-de-France
59302,HERIN,59195,HERIN,,50.3588545059,3.44801878538,302,,Hérin,Hérin,59,Nord,32,Hauts-de-France
59303,HERLIES,59134,HERLIES,,50.5816535581,2.8531853808,303,,Herlies,Herlies,59,Nord,32,Hauts-de-France
59304,HERRIN,59147,HERRIN,,50.5495900938,2.96246977042,304,,Herrin,Herrin,59,Nord,32,Hauts-de-France
59305,HERZEELE,59470,HERZEELE,,50.8810801461,2.53001631051,305,,Herzeele,Herzeele,59,Nord,32,Hauts-de-France
59306,HESTRUD,59740,HESTRUD,,50.2030928421,4.13978408262,306,,Hestrud,Hestrud,59,Nord,32,Hauts-de-France
59307,HOLQUE,59143,HOLQUE,,50.856500023,2.2050645429,307,,Holque,Holque,59,Nord,32,Hauts-de-France
59308,HONDEGHEM,59190,HONDEGHEM,,50.7552272301,2.5153551845,308,,Hondeghem,Hondeghem,59,Nord,32,Hauts-de-France
59309,HONDSCHOOTE,59122,HONDSCHOOTE,,50.971669311,2.58828514944,309,,Hondschoote,Hondschoote,59,Nord,32,Hauts-de-France
59310,HON HERGIES,59570,HON HERGIES,,50.3348329757,3.82037377932,310,,Hon-Hergies,Hon-Hergies,59,Nord,32,Hauts-de-France
59311,HONNECHY,59980,HONNECHY,,50.0677939071,3.48120463527,311,,Honnechy,Honnechy,59,Nord,32,Hauts-de-France
59312,HONNECOURT SUR ESCAUT,59266,HONNECOURT SUR ESCAUT,,50.0343460681,3.20211189134,312,,Honnecourt-sur-Escaut,Honnecourt-sur-Escaut,59,Nord,32,Hauts-de-France
59313,HORDAIN,59111,HORDAIN,,50.2597936195,3.32675271349,313,,Hordain,Hordain,59,Nord,32,Hauts-de-France
59314,HORNAING,59171,HORNAING,,50.3696239428,3.33594044996,314,,Hornaing,Hornaing,59,Nord,32,Hauts-de-France
59315,HOUDAIN LEZ BAVAY,59570,HOUDAIN LEZ BAVAY,,50.3294305775,3.78664727295,315,,Houdain-lez-Bavay,Houdain-lez-Bavay,59,Nord,32,Hauts-de-France
59316,HOUPLIN ANCOISNE,59263,HOUPLIN ANCOISNE,,50.5699205029,2.99498019167,316,,Houplin-Ancoisne,Houplin-Ancoisne,59,Nord,32,Hauts-de-France
59317,HOUPLINES,59116,HOUPLINES,,50.6841292085,2.92646496256,317,,Houplines,Houplines,59,Nord,32,Hauts-de-France
59318,HOUTKERQUE,59470,HOUTKERQUE,,50.8825678923,2.58249154938,318,,Houtkerque,Houtkerque,59,Nord,32,Hauts-de-France
59319,HOYMILLE,59492,HOYMILLE,,50.9727799567,2.45547219579,319,,Hoymille,Hoymille,59,Nord,32,Hauts-de-France
59320,ILLIES,59480,ILLIES,,50.5626769272,2.82845132596,320,,Illies,Illies,59,Nord,32,Hauts-de-France
59321,INCHY,59540,INCHY,,50.1219699248,3.47168074868,321,,Inchy,Inchy,59,Nord,32,Hauts-de-France
59322,IWUY,59141,IWUY,,50.233598422,3.33623347454,322,,Iwuy,Iwuy,59,Nord,32,Hauts-de-France
59323,JENLAIN,59144,JENLAIN,,50.3097021009,3.63092596962,323,,Jenlain,Jenlain,59,Nord,32,Hauts-de-France
59324,JEUMONT,59460,JEUMONT,,50.2913767196,4.10405698183,324,,Jeumont,Jeumont,59,Nord,32,Hauts-de-France
59325,JOLIMETZ,59530,JOLIMETZ,,50.2290109948,3.67767939299,325,,Jolimetz,Jolimetz,59,Nord,32,Hauts-de-France
59326,KILLEM,59122,KILLEM,,50.9575597067,2.55978032528,326,,Killem,Killem,59,Nord,32,Hauts-de-France
59327,LALLAING,59167,LALLAING,,50.3864016974,3.16880269409,327,,Lallaing,Lallaing,59,Nord,32,Hauts-de-France
59328,LAMBERSART,59130,LAMBERSART,,50.6522053005,3.02491174276,328,,Lambersart,Lambersart,59,Nord,32,Hauts-de-France
59329,LAMBRES LEZ DOUAI,59552,LAMBRES LEZ DOUAI,,50.3542349649,3.05466709825,329,,Lambres-lez-Douai,Lambres-lez-Douai,59,Nord,32,Hauts-de-France
59330,LANDAS,59310,LANDAS,,50.4726958028,3.29518086014,330,,Landas,Landas,59,Nord,32,Hauts-de-France
59331,LANDRECIES,59550,LANDRECIES,,50.1205093509,3.68851283044,331,,Landrecies,Landrecies,59,Nord,32,Hauts-de-France
59332,LANNOY,59390,LANNOY,,50.666027423,3.21037021622,332,,Lannoy,Lannoy,59,Nord,32,Hauts-de-France
59333,LAROUILLIES,59219,LAROUILLIES,,50.0378159159,3.93206496317,333,,Larouillies,Larouillies,59,Nord,32,Hauts-de-France
59334,LAUWIN PLANQUE,59553,LAUWIN PLANQUE,,50.3929252275,3.02930995162,334,,Lauwin-Planque,Lauwin-Planque,59,Nord,32,Hauts-de-France
59335,LECELLES,59226,LECELLES,,50.474778936,3.39878461837,335,,Lecelles,Lecelles,59,Nord,32,Hauts-de-France
59336,LECLUSE,59259,LECLUSE,,50.2765109082,3.03054584973,336,,Lécluse,Lécluse,59,Nord,32,Hauts-de-France
59337,LEDERZEELE,59143,LEDERZEELE,,50.8134333231,2.29316091241,337,,Lederzeele,Lederzeele,59,Nord,32,Hauts-de-France
59338,LEDRINGHEM,59470,LEDRINGHEM,,50.862026516,2.44478876781,338,,Ledringhem,Ledringhem,59,Nord,32,Hauts-de-France
59339,LEERS,59115,LEERS,,50.6813150077,3.23964411032,339,,Leers,Leers,59,Nord,32,Hauts-de-France
59340,LEFFRINCKOUCKE,59495,LEFFRINCKOUCKE,,51.0479753617,2.45468596825,340,,Leffrinckoucke,Leffrinckoucke,59,Nord,32,Hauts-de-France
59341,LESDAIN,59258,LESDAIN,,50.0926261924,3.27595747456,341,,Lesdain,Lesdain,59,Nord,32,Hauts-de-France
59342,LEZ FONTAINE,59740,LEZ FONTAINE,,50.1733832806,4.06309065769,342,,Lez-Fontaine,Lez-Fontaine,59,Nord,32,Hauts-de-France
59343,LESQUIN,59810,LESQUIN,,50.5892285666,3.11191934713,343,,Lesquin,Lesquin,59,Nord,32,Hauts-de-France
59344,LEVAL,59620,LEVAL,,50.180651266,3.83355819928,344,,Leval,Leval,59,Nord,32,Hauts-de-France
59345,LEWARDE,59287,LEWARDE,,50.3367323265,3.16865198714,345,,Lewarde,Lewarde,59,Nord,32,Hauts-de-France
59346,LEZENNES,59260,LEZENNES,,50.6111691928,3.11824226491,346,,Lezennes,Lezennes,59,Nord,32,Hauts-de-France
59347,LIESSIES,59740,LIESSIES,,50.1049349578,4.07726257108,347,,Liessies,Liessies,59,Nord,32,Hauts-de-France
59348,LIEU ST AMAND,59111,LIEU ST AMAND,,50.2722406252,3.3493708028,348,,Lieu-Saint-Amand,Lieu-Saint-Amand,59,Nord,32,Hauts-de-France
59349,LIGNY EN CAMBRESIS,59191,LIGNY EN CAMBRESIS,,50.1025067499,3.37859262548,349,,Ligny-en-Cambrésis,Ligny-en-Cambrésis,59,Nord,32,Hauts-de-France
59350,LILLE,59000,LILLE,,50.6317183168,3.04783272312,350,,Lille,Lille,59,Nord,32,Hauts-de-France
59350,LILLE,59160,LILLE,LOMME,50.6317183168,3.04783272312,350,,Lille,Lille,59,Nord,32,Hauts-de-France
59350,LILLE,59260,LILLE,HELLEMMES LILLE,50.6317183168,3.04783272312,350,,Lille,Lille,59,Nord,32,Hauts-de-France
59350,LILLE,59777,LILLE,EURALILLE,50.6317183168,3.04783272312,350,,Lille,Lille,59,Nord,32,Hauts-de-France
59350,LILLE,59800,LILLE,,50.6317183168,3.04783272312,350,,Lille,Lille,59,Nord,32,Hauts-de-France
59351,LIMONT FONTAINE,59330,LIMONT FONTAINE,,50.2155213802,3.92291118633,351,,Limont-Fontaine,Limont-Fontaine,59,Nord,32,Hauts-de-France
59352,LINSELLES,59126,LINSELLES,,50.7361350171,3.0744786004,352,,Linselles,Linselles,59,Nord,32,Hauts-de-France
59353,LOCQUIGNOL,59530,LOCQUIGNOL,,50.2033505573,3.7334425782,353,,Locquignol,Locquignol,59,Nord,32,Hauts-de-France
59354,LOFFRE,59182,LOFFRE,,50.3537253099,3.17227962058,354,,Loffre,Loffre,59,Nord,32,Hauts-de-France
59356,LOMPRET,59840,LOMPRET,,50.6631680443,2.99516100327,356,,Lompret,Lompret,59,Nord,32,Hauts-de-France
59357,LA LONGUEVILLE,59570,LA LONGUEVILLE,,50.2961357977,3.87102048316,357,La,Longueville,La Longueville,59,Nord,32,Hauts-de-France
59358,LOOBERGHE,59630,LOOBERGHE,,50.912741374,2.2751678981,358,,Looberghe,Looberghe,59,Nord,32,Hauts-de-France
59359,LOON PLAGE,59279,LOON PLAGE,,51.0012144614,2.22304802432,359,,Loon-Plage,Loon-Plage,59,Nord,32,Hauts-de-France
59360,LOOS,59120,LOOS,,50.608350368,3.02066096506,360,,Loos,Loos,59,Nord,32,Hauts-de-France
59361,LOURCHES,59156,LOURCHES,,50.3111500417,3.35745797918,361,,Lourches,Lourches,59,Nord,32,Hauts-de-France
59363,LOUVIGNIES QUESNOY,59530,LOUVIGNIES QUESNOY,,50.2165903097,3.64008191069,363,,Louvignies-Quesnoy,Louvignies-Quesnoy,59,Nord,32,Hauts-de-France
59364,LOUVIL,59830,LOUVIL,,50.5567255467,3.18952793321,364,,Louvil,Louvil,59,Nord,32,Hauts-de-France
59365,LOUVROIL,59720,LOUVROIL,,50.2577866904,3.95387643411,365,,Louvroil,Louvroil,59,Nord,32,Hauts-de-France
59366,LYNDE,59173,LYNDE,,50.7186012441,2.43465079994,366,,Lynde,Lynde,59,Nord,32,Hauts-de-France
59367,LYS LEZ LANNOY,59390,LYS LEZ LANNOY,,50.6721775091,3.2172382556,367,,Lys-lez-Lannoy,Lys-lez-Lannoy,59,Nord,32,Hauts-de-France
59368,LA MADELEINE,59110,LA MADELEINE,,50.6548883138,3.06963722507,368,La,Madeleine,La Madeleine,59,Nord,32,Hauts-de-France
59369,MAING,59233,MAING,,50.3036687499,3.48802239874,369,,Maing,Maing,59,Nord,32,Hauts-de-France
59370,MAIRIEUX,59600,MAIRIEUX,,50.3136654011,3.97327891565,370,,Mairieux,Mairieux,59,Nord,32,Hauts-de-France
59371,LE MAISNIL,59134,LE MAISNIL,,50.6182693489,2.87754636769,371,Le,Maisnil,Le Maisnil,59,Nord,32,Hauts-de-France
59372,MALINCOURT,59127,MALINCOURT,,50.0351942091,3.32471130413,372,,Malincourt,Malincourt,59,Nord,32,Hauts-de-France
59374,MARBAIX,59440,MARBAIX,,50.1280580168,3.83650655237,374,,Marbaix,Marbaix,59,Nord,32,Hauts-de-France
59375,MARCHIENNES,59870,MARCHIENNES,,50.414280284,3.26362216805,375,,Marchiennes,Marchiennes,59,Nord,32,Hauts-de-France
59377,MARCOING,59159,MARCOING,,50.1144749853,3.17343200113,377,,Marcoing,Marcoing,59,Nord,32,Hauts-de-France
59378,MARCQ EN BAROEUL,59700,MARCQ EN BAROEUL,,50.6753348557,3.10093583816,378,,Marcq-en-Barœul,Marcq-en-Barœul,59,Nord,32,Hauts-de-France
59379,MARCQ EN OSTREVENT,59252,MARCQ EN OSTREVENT,,50.2876828642,3.23484427567,379,,Marcq-en-Ostrevent,Marcq-en-Ostrevent,59,Nord,32,Hauts-de-France
59381,MARESCHES,59990,MARESCHES,,50.2940290785,3.57606019881,381,,Maresches,Maresches,59,Nord,32,Hauts-de-France
59382,MARETZ,59238,MARETZ,,50.0450861132,3.42038871212,382,,Maretz,Maretz,59,Nord,32,Hauts-de-France
59383,MARLY,59770,MARLY,,50.3452442077,3.55000999792,383,,Marly,Marly,59,Nord,32,Hauts-de-France
59384,MAROILLES,59550,MAROILLES,,50.1319269189,3.75814901282,384,,Maroilles,Maroilles,59,Nord,32,Hauts-de-France
59385,MARPENT,59164,MARPENT,,50.2913562157,4.07637705799,385,,Marpent,Marpent,59,Nord,32,Hauts-de-France
59386,MARQUETTE LEZ LILLE,59520,MARQUETTE LEZ LILLE,,50.67409511,3.05886328577,386,,Marquette-lez-Lille,Marquette-lez-Lille,59,Nord,32,Hauts-de-France
59387,MARQUETTE EN OSTREVANT,59252,MARQUETTE EN OSTREVANT,,50.2921413356,3.2707273524,387,,Marquette-en-Ostrevant,Marquette-en-Ostrevant,59,Nord,32,Hauts-de-France
59388,MARQUILLIES,59274,MARQUILLIES,,50.5507925122,2.86815833452,388,,Marquillies,Marquillies,59,Nord,32,Hauts-de-France
59389,MASNIERES,59241,MASNIERES,,50.104659247,3.20208772877,389,,Masnières,Masnières,59,Nord,32,Hauts-de-France
59390,MASNY,59176,MASNY,,50.341241582,3.19548470018,390,,Masny,Masny,59,Nord,32,Hauts-de-France
59391,MASTAING,59172,MASTAING,,50.3064012156,3.29792589761,391,,Mastaing,Mastaing,59,Nord,32,Hauts-de-France
59392,MAUBEUGE,59600,MAUBEUGE,,50.283630719,3.96328053037,392,,Maubeuge,Maubeuge,59,Nord,32,Hauts-de-France
59392,MAUBEUGE,59600,MAUBEUGE,DOUZIES,50.283630719,3.96328053037,392,,Maubeuge,Maubeuge,59,Nord,32,Hauts-de-France
59393,MAULDE,59158,MAULDE,,50.4952908946,3.42538565597,393,,Maulde,Maulde,59,Nord,32,Hauts-de-France
59394,MAUROIS,59980,MAUROIS,,50.0727158621,3.4590777682,394,,Maurois,Maurois,59,Nord,32,Hauts-de-France
59395,MAZINGHIEN,59360,MAZINGHIEN,,50.049448426,3.59700862525,395,,Mazinghien,Mazinghien,59,Nord,32,Hauts-de-France
59396,MECQUIGNIES,59570,MECQUIGNIES,,50.2704309321,3.80248207777,396,,Mecquignies,Mecquignies,59,Nord,32,Hauts-de-France
59397,MERCKEGHEM,59470,MERCKEGHEM,,50.8699396008,2.27783454706,397,,Merckeghem,Merckeghem,59,Nord,32,Hauts-de-France
59398,MERIGNIES,59710,MERIGNIES,,50.5069205161,3.12172446457,398,,Mérignies,Mérignies,59,Nord,32,Hauts-de-France
59399,MERRIS,59270,MERRIS,,50.7178435961,2.6572770613,399,,Merris,Merris,59,Nord,32,Hauts-de-France
59400,MERVILLE,59660,MERVILLE,,50.6449293889,2.63582653422,400,,Merville,Merville,59,Nord,32,Hauts-de-France
59401,METEREN,59270,METEREN,,50.7479005121,2.67634884839,401,,Méteren,Méteren,59,Nord,32,Hauts-de-France
59402,MILLAM,59143,MILLAM,,50.8559210958,2.25120770329,402,,Millam,Millam,59,Nord,32,Hauts-de-France
59403,MILLONFOSSE,59178,MILLONFOSSE,,50.4291924594,3.37701536636,403,,Millonfosse,Millonfosse,59,Nord,32,Hauts-de-France
59405,MOEUVRES,59400,MOEUVRES,,50.1602158423,3.06781891746,405,,Mœuvres,Mœuvres,59,Nord,32,Hauts-de-France
59406,MONCEAU ST WAAST,59620,MONCEAU ST WAAST,,50.1717846537,3.85726023625,406,,Monceau-Saint-Waast,Monceau-Saint-Waast,59,Nord,32,Hauts-de-France
59407,MONCHAUX SUR ECAILLON,59224,MONCHAUX SUR ECAILLON,,50.2838656974,3.4581039292,407,,Monchaux-sur-Écaillon,Monchaux-sur-Écaillon,59,Nord,32,Hauts-de-France
59408,MONCHEAUX,59283,MONCHEAUX,,50.456566302,3.08800670538,408,,Moncheaux,Moncheaux,59,Nord,32,Hauts-de-France
59409,MONCHECOURT,59234,MONCHECOURT,,50.3061963745,3.21073984637,409,,Monchecourt,Monchecourt,59,Nord,32,Hauts-de-France
59410,MONS EN BAROEUL,59370,MONS EN BAROEUL,,50.6432478699,3.1087677878,410,,Mons-en-Barœul,Mons-en-Barœul,59,Nord,32,Hauts-de-France
59411,MONS EN PEVELE,59246,MONS EN PEVELE,,50.4819644352,3.10317921728,411,,Mons-en-Pévèle,Mons-en-Pévèle,59,Nord,32,Hauts-de-France
59412,MONTAY,59360,MONTAY,,50.1222064074,3.53460777852,412,,Montay,Montay,59,Nord,32,Hauts-de-France
59413,MONTIGNY EN CAMBRESIS,59225,MONTIGNY EN CAMBRESIS,,50.0910970595,3.41429656763,413,,Montigny-en-Cambrésis,Montigny-en-Cambrésis,59,Nord,32,Hauts-de-France
59414,MONTIGNY EN OSTREVENT,59182,MONTIGNY EN OSTREVENT,,50.3682405226,3.18011986506,414,,Montigny-en-Ostrevent,Montigny-en-Ostrevent,59,Nord,32,Hauts-de-France
59415,MONTRECOURT,59227,MONTRECOURT,,50.2256590135,3.44600124957,415,,Montrécourt,Montrécourt,59,Nord,32,Hauts-de-France
59416,MORBECQUE,59190,MORBECQUE,,50.6768934286,2.53621614433,416,,Morbecque,Morbecque,59,Nord,32,Hauts-de-France
59418,MORTAGNE DU NORD,59158,MORTAGNE DU NORD,,50.5001515803,3.45327380801,418,,Mortagne-du-Nord,Mortagne-du-Nord,59,Nord,32,Hauts-de-France
59419,MOUCHIN,59310,MOUCHIN,,50.5168601993,3.28629185123,419,,Mouchin,Mouchin,59,Nord,32,Hauts-de-France
59420,MOUSTIER EN FAGNE,59132,MOUSTIER EN FAGNE,,50.0917864543,4.19598971428,420,,Moustier-en-Fagne,Moustier-en-Fagne,59,Nord,32,Hauts-de-France
59421,MOUVAUX,59420,MOUVAUX,,50.7041016348,3.13593215066,421,,Mouvaux,Mouvaux,59,Nord,32,Hauts-de-France
59422,NAVES,59161,NAVES,,50.2033732531,3.31732755418,422,,Naves,Naves,59,Nord,32,Hauts-de-France
59423,NEUF BERQUIN,59940,NEUF BERQUIN,,50.6652328405,2.66524376956,423,,Neuf-Berquin,Neuf-Berquin,59,Nord,32,Hauts-de-France
59424,NEUF MESNIL,59330,NEUF MESNIL,,50.2653663974,3.90941211198,424,,Neuf-Mesnil,Neuf-Mesnil,59,Nord,32,Hauts-de-France
59425,NEUVILLE EN AVESNOIS,59218,NEUVILLE EN AVESNOIS,,50.1995093719,3.57904088445,425,,Neuville-en-Avesnois,Neuville-en-Avesnois,59,Nord,32,Hauts-de-France
59426,NEUVILLE EN FERRAIN,59960,NEUVILLE EN FERRAIN,,50.7533162393,3.15977251104,426,,Neuville-en-Ferrain,Neuville-en-Ferrain,59,Nord,32,Hauts-de-France
59427,LA NEUVILLE,59239,LA NEUVILLE,,50.4968963031,3.05358974895,427,La,Neuville,La Neuville,59,Nord,32,Hauts-de-France
59428,NEUVILLE ST REMY,59554,NEUVILLE ST REMY,,50.1876980939,3.21918185069,428,,Neuville-Saint-Rémy,Neuville-Saint-Rémy,59,Nord,32,Hauts-de-France
59429,NEUVILLE SUR ESCAUT,59293,NEUVILLE SUR ESCAUT,,50.2905014388,3.35160622721,429,,Neuville-sur-Escaut,Neuville-sur-Escaut,59,Nord,32,Hauts-de-France
59430,NEUVILLY,59360,NEUVILLY,,50.1349971919,3.51024982301,430,,Neuvilly,Neuvilly,59,Nord,32,Hauts-de-France
59431,NIEPPE,59850,NIEPPE,,50.6992987531,2.83247310085,431,,Nieppe,Nieppe,59,Nord,32,Hauts-de-France
59432,NIERGNIES,59400,NIERGNIES,,50.1416253963,3.25613708021,432,,Niergnies,Niergnies,59,Nord,32,Hauts-de-France
59433,NIEURLET,59143,NIEURLET,,50.7949144222,2.27990645597,433,,Nieurlet,Nieurlet,59,Nord,32,Hauts-de-France
59434,NIVELLE,59230,NIVELLE,,50.4681563003,3.46013569739,434,,Nivelle,Nivelle,59,Nord,32,Hauts-de-France
59435,NOMAIN,59310,NOMAIN,,50.5012176112,3.23912545125,435,,Nomain,Nomain,59,Nord,32,Hauts-de-France
59436,NOORDPEENE,59670,NOORDPEENE,,50.7945101601,2.36741593689,436,,Noordpeene,Noordpeene,59,Nord,32,Hauts-de-France
59437,NOYELLES LES SECLIN,59139,NOYELLES LES SECLIN,,50.5729280386,3.02238335303,437,,Noyelles-lès-Seclin,Noyelles-lès-Seclin,59,Nord,32,Hauts-de-France
59438,NOYELLES SUR ESCAUT,59159,NOYELLES SUR ESCAUT,,50.1373086019,3.17631469975,438,,Noyelles-sur-Escaut,Noyelles-sur-Escaut,59,Nord,32,Hauts-de-France
59439,NOYELLES SUR SAMBRE,59550,NOYELLES SUR SAMBRE,,50.1616211802,3.79528710452,439,,Noyelles-sur-Sambre,Noyelles-sur-Sambre,59,Nord,32,Hauts-de-France
59440,NOYELLES SUR SELLE,59282,NOYELLES SUR SELLE,,50.2786620955,3.38194992576,440,,Noyelles-sur-Selle,Noyelles-sur-Selle,59,Nord,32,Hauts-de-France
59441,OBIES,59570,OBIES,,50.2692950414,3.7760866867,441,,Obies,Obies,59,Nord,32,Hauts-de-France
59442,OBRECHIES,59680,OBRECHIES,,50.2225324882,4.03683646675,442,,Obrechies,Obrechies,59,Nord,32,Hauts-de-France
59443,OCHTEZEELE,59670,OCHTEZEELE,,50.8191546724,2.40052257312,443,,Ochtezeele,Ochtezeele,59,Nord,32,Hauts-de-France
59444,ODOMEZ,59970,ODOMEZ,,50.4481733778,3.53940322234,444,,Odomez,Odomez,59,Nord,32,Hauts-de-France
59445,OHAIN,59132,OHAIN,,50.0369176402,4.12652703676,445,,Ohain,Ohain,59,Nord,32,Hauts-de-France
59446,OISY,59195,OISY,,50.3522308671,3.43281738814,446,,Oisy,Oisy,59,Nord,32,Hauts-de-France
59447,ONNAING,59264,ONNAING,,50.3867147723,3.59668401083,447,,Onnaing,Onnaing,59,Nord,32,Hauts-de-France
59448,OOST CAPPEL,59122,OOST CAPPEL,,50.9297498837,2.58933450299,448,,Oost-Cappel,Oost-Cappel,59,Nord,32,Hauts-de-France
59449,ORCHIES,59310,ORCHIES,,50.4684491037,3.23890685351,449,,Orchies,Orchies,59,Nord,32,Hauts-de-France
59450,ORS,59360,ORS,,50.1050821078,3.6311053836,450,,Ors,Ors,59,Nord,32,Hauts-de-France
59451,ORSINVAL,59530,ORSINVAL,,50.2718570583,3.63293709102,451,,Orsinval,Orsinval,59,Nord,32,Hauts-de-France
59452,OSTRICOURT,59162,OSTRICOURT,,50.4571255859,3.03269713092,452,,Ostricourt,Ostricourt,59,Nord,32,Hauts-de-France
59453,OUDEZEELE,59670,OUDEZEELE,,50.8399999116,2.51032290122,453,,Oudezeele,Oudezeele,59,Nord,32,Hauts-de-France
59454,OXELAERE,59670,OXELAERE,,50.7807301542,2.4699206565,454,,Oxelaëre,Oxelaëre,59,Nord,32,Hauts-de-France
59455,PAILLENCOURT,59295,PAILLENCOURT,,50.2487376852,3.26355890022,455,,Paillencourt,Paillencourt,59,Nord,32,Hauts-de-France
59456,PECQUENCOURT,59146,PECQUENCOURT,,50.3763502504,3.21867627513,456,,Pecquencourt,Pecquencourt,59,Nord,32,Hauts-de-France
59457,PERENCHIES,59840,PERENCHIES,,50.6700813687,2.96867396183,457,,Pérenchies,Pérenchies,59,Nord,32,Hauts-de-France
59458,PERONNE EN MELANTOIS,59273,PERONNE EN MELANTOIS,,50.56433015,3.17149667698,458,,Péronne-en-Mélantois,Péronne-en-Mélantois,59,Nord,32,Hauts-de-France
59459,PETITE FORET,59494,PETITE FORET,,50.3747760096,3.47594179576,459,,Petite-Forêt,Petite-Forêt,59,Nord,32,Hauts-de-France
59461,PETIT FAYT,59244,PETIT FAYT,,50.1023567532,3.82607936534,461,,Petit-Fayt,Petit-Fayt,59,Nord,32,Hauts-de-France
59462,PHALEMPIN,59133,PHALEMPIN,,50.5128963726,3.02222998505,462,,Phalempin,Phalempin,59,Nord,32,Hauts-de-France
59463,PITGAM,59284,PITGAM,,50.9299079429,2.34010090191,463,,Pitgam,Pitgam,59,Nord,32,Hauts-de-France
59464,POIX DU NORD,59218,POIX DU NORD,,50.185059973,3.61684782607,464,,Poix-du-Nord,Poix-du-Nord,59,Nord,32,Hauts-de-France
59465,POMMEREUIL,59360,POMMEREUIL,,50.1169693319,3.58693750917,465,,Pommereuil,Pommereuil,59,Nord,32,Hauts-de-France
59466,PONT A MARCQ,59710,PONT A MARCQ,,50.5213224802,3.11535964739,466,,Pont-à-Marcq,Pont-à-Marcq,59,Nord,32,Hauts-de-France
59467,PONT SUR SAMBRE,59138,PONT SUR SAMBRE,,50.2317600389,3.84512612151,467,,Pont-sur-Sambre,Pont-sur-Sambre,59,Nord,32,Hauts-de-France
59468,POTELLE,59530,POTELLE,,50.2386756413,3.66509880886,468,,Potelle,Potelle,59,Nord,32,Hauts-de-France
59469,PRADELLES,59190,PRADELLES,,50.7340517429,2.60269224096,469,,Pradelles,Pradelles,59,Nord,32,Hauts-de-France
59470,PREMESQUES,59840,PREMESQUES,,50.6607863693,2.9485483768,470,,Prémesques,Prémesques,59,Nord,32,Hauts-de-France
59470,PREMESQUES,59840,PREMESQUES,MONT DE PREMESQUES,50.6607863693,2.9485483768,470,,Prémesques,Prémesques,59,Nord,32,Hauts-de-France
59471,PRESEAU,59990,PRESEAU,,50.311072595,3.57351530616,471,,Préseau,Préseau,59,Nord,32,Hauts-de-France
59472,PREUX AU BOIS,59288,PREUX AU BOIS,,50.1647138146,3.6553638264,472,,Preux-au-Bois,Preux-au-Bois,59,Nord,32,Hauts-de-France
59473,PREUX AU SART,59144,PREUX AU SART,,50.2886107324,3.69910774088,473,,Preux-au-Sart,Preux-au-Sart,59,Nord,32,Hauts-de-France
59474,PRISCHES,59550,PRISCHES,,50.0779109986,3.75922395806,474,,Prisches,Prisches,59,Nord,32,Hauts-de-France
59475,PROUVY,59121,PROUVY,,50.3241135233,3.45729001727,475,,Prouvy,Prouvy,59,Nord,32,Hauts-de-France
59476,PROVILLE,59267,PROVILLE,,50.15582108,3.20370328154,476,,Proville,Proville,59,Nord,32,Hauts-de-France
59477,PROVIN,59185,PROVIN,,50.5134080019,2.91117965832,477,,Provin,Provin,59,Nord,32,Hauts-de-France
59478,QUAEDYPRE,59380,QUAEDYPRE,,50.9381093158,2.46047387712,478,,Quaëdypre,Quaëdypre,59,Nord,32,Hauts-de-France
59479,QUAROUBLE,59243,QUAROUBLE,,50.4035655109,3.6270603909,479,,Quarouble,Quarouble,59,Nord,32,Hauts-de-France
59480,QUERENAING,59269,QUERENAING,,50.2848542839,3.50823290442,480,,Quérénaing,Quérénaing,59,Nord,32,Hauts-de-France
59481,LE QUESNOY,59530,LE QUESNOY,,50.2506593981,3.62486177207,481,Le,Quesnoy,Le Quesnoy,59,Nord,32,Hauts-de-France
59482,QUESNOY SUR DEULE,59890,QUESNOY SUR DEULE,,50.7129994523,3.00861966929,482,,Quesnoy-sur-Deûle,Quesnoy-sur-Deûle,59,Nord,32,Hauts-de-France
59483,QUIEVELON,59680,QUIEVELON,,50.2350198423,4.06805030291,483,,Quiévelon,Quiévelon,59,Nord,32,Hauts-de-France
59484,QUIEVRECHAIN,59920,QUIEVRECHAIN,,50.3920521291,3.65887590653,484,,Quiévrechain,Quiévrechain,59,Nord,32,Hauts-de-France
59484,QUIEVRECHAIN,59920,QUIEVRECHAIN,BLANC MISSERON,50.3920521291,3.65887590653,484,,Quiévrechain,Quiévrechain,59,Nord,32,Hauts-de-France
59485,QUIEVY,59214,QUIEVY,,50.162969816,3.42160576023,485,,Quiévy,Quiévy,59,Nord,32,Hauts-de-France
59486,RACHES,59194,RACHES,,50.4201328892,3.13698851837,486,,Râches,Râches,59,Nord,32,Hauts-de-France
59487,RADINGHEM EN WEPPES,59320,RADINGHEM EN WEPPES,,50.6281404058,2.89866058213,487,,Radinghem-en-Weppes,Radinghem-en-Weppes,59,Nord,32,Hauts-de-France
59488,RAILLENCOURT STE OLLE,59554,RAILLENCOURT STE OLLE,,50.1884274141,3.17806914502,488,,Raillencourt-Sainte-Olle,Raillencourt-Sainte-Olle,59,Nord,32,Hauts-de-France
59489,RAIMBEAUCOURT,59283,RAIMBEAUCOURT,,50.4363550078,3.10277352628,489,,Raimbeaucourt,Raimbeaucourt,59,Nord,32,Hauts-de-France
59490,RAINSARS,59177,RAINSARS,,50.0704332931,3.99772859383,490,,Rainsars,Rainsars,59,Nord,32,Hauts-de-France
59491,RAISMES,59590,RAISMES,,50.410089317,3.48909318774,491,,Raismes,Raismes,59,Nord,32,Hauts-de-France
59492,RAMILLIES,59161,RAMILLIES,,50.2072582763,3.24940380157,492,,Ramillies,Ramillies,59,Nord,32,Hauts-de-France
59493,RAMOUSIES,59177,RAMOUSIES,,50.1164251073,4.03401363601,493,,Ramousies,Ramousies,59,Nord,32,Hauts-de-France
59494,RAUCOURT AU BOIS,59530,RAUCOURT AU BOIS,,50.2067851455,3.6560114776,494,,Raucourt-au-Bois,Raucourt-au-Bois,59,Nord,32,Hauts-de-France
59495,RECQUIGNIES,59245,RECQUIGNIES,,50.2766724838,4.04841593336,495,,Recquignies,Recquignies,59,Nord,32,Hauts-de-France
59496,REJET DE BEAULIEU,59360,REJET DE BEAULIEU,,50.0452957797,3.63508660272,496,,Rejet-de-Beaulieu,Rejet-de-Beaulieu,59,Nord,32,Hauts-de-France
59497,RENESCURE,59173,RENESCURE,,50.7357377095,2.37835427066,497,,Renescure,Renescure,59,Nord,32,Hauts-de-France
59498,REUMONT,59980,REUMONT,,50.0851954003,3.47837512553,498,,Reumont,Reumont,59,Nord,32,Hauts-de-France
59499,REXPOEDE,59122,REXPOEDE,,50.9353119359,2.54496726178,499,,Rexpoëde,Rexpoëde,59,Nord,32,Hauts-de-France
59500,RIBECOURT LA TOUR,59159,RIBECOURT LA TOUR,,50.1039235945,3.13170162173,500,,Ribécourt-la-Tour,Ribécourt-la-Tour,59,Nord,32,Hauts-de-France
59501,RIEULAY,59870,RIEULAY,,50.3871460601,3.2654482403,501,,Rieulay,Rieulay,59,Nord,32,Hauts-de-France
59502,RIEUX EN CAMBRESIS,59277,RIEUX EN CAMBRESIS,,50.2043374368,3.35238504979,502,,Rieux-en-Cambrésis,Rieux-en-Cambrésis,59,Nord,32,Hauts-de-France
59503,ROBERSART,59550,ROBERSART,,50.1565289177,3.64613951991,503,,Robersart,Robersart,59,Nord,32,Hauts-de-France
59504,ROEULX,59172,ROEULX,,50.3127229733,3.32813430468,504,,Rœulx,Rœulx,59,Nord,32,Hauts-de-France
59505,ROMBIES ET MARCHIPONT,59990,ROMBIES ET MARCHIPONT,,50.3683187976,3.63953903487,505,,Rombies-et-Marchipont,Rombies-et-Marchipont,59,Nord,32,Hauts-de-France
59506,ROMERIES,59730,ROMERIES,,50.1972863068,3.54305484338,506,,Romeries,Romeries,59,Nord,32,Hauts-de-France
59507,RONCHIN,59790,RONCHIN,,50.6044738955,3.09168585537,507,,Ronchin,Ronchin,59,Nord,32,Hauts-de-France
59508,RONCQ,59223,RONCQ,,50.747350697,3.11815606165,508,,Roncq,Roncq,59,Nord,32,Hauts-de-France
59509,ROOST WARENDIN,59286,ROOST WARENDIN,,50.4147869735,3.10349816509,509,,Roost-Warendin,Roost-Warendin,59,Nord,32,Hauts-de-France
59511,ROSULT,59230,ROSULT,,50.4521455969,3.36243705958,511,,Rosult,Rosult,59,Nord,32,Hauts-de-France
59512,ROUBAIX,59100,ROUBAIX,,50.6879774811,3.18258434623,512,,Roubaix,Roubaix,59,Nord,32,Hauts-de-France
59513,ROUCOURT,59169,ROUCOURT,,50.3279013406,3.14688211688,513,,Roucourt,Roucourt,59,Nord,32,Hauts-de-France
59514,ROUSIES,59131,ROUSIES,,50.2712617008,3.99972723492,514,,Rousies,Rousies,59,Nord,32,Hauts-de-France
59515,ROUVIGNIES,59220,ROUVIGNIES,,50.333483649,3.44055794538,515,,Rouvignies,Rouvignies,59,Nord,32,Hauts-de-France
59516,RUBROUCK,59285,RUBROUCK,,50.8331982321,2.36252595858,516,,Rubrouck,Rubrouck,59,Nord,32,Hauts-de-France
59517,LES RUES DES VIGNES,59258,LES RUES DES VIGNES,,50.0758341626,3.23349040866,517,Les,Rues-des-Vignes,Les Rues-des-Vignes,59,Nord,32,Hauts-de-France
59518,RUESNES,59530,RUESNES,,50.2634217469,3.5818116421,518,,Ruesnes,Ruesnes,59,Nord,32,Hauts-de-France
59519,RUMEGIES,59226,RUMEGIES,,50.4924282163,3.35255840321,519,,Rumegies,Rumegies,59,Nord,32,Hauts-de-France
59520,RUMILLY EN CAMBRESIS,59281,RUMILLY EN CAMBRESIS,,50.1304621675,3.22848231666,520,,Rumilly-en-Cambrésis,Rumilly-en-Cambrésis,59,Nord,32,Hauts-de-France
59521,SAILLY LEZ CAMBRAI,59554,SAILLY LEZ CAMBRAI,,50.1992760236,3.17933507614,521,,Sailly-lez-Cambrai,Sailly-lez-Cambrai,59,Nord,32,Hauts-de-France
59522,SAILLY LEZ LANNOY,59390,SAILLY LEZ LANNOY,,50.6460432884,3.22762243791,522,,Sailly-lez-Lannoy,Sailly-lez-Lannoy,59,Nord,32,Hauts-de-France
59523,SAINGHIN EN MELANTOIS,59262,SAINGHIN EN MELANTOIS,,50.5877332144,3.16342165677,523,,Sainghin-en-Mélantois,Sainghin-en-Mélantois,59,Nord,32,Hauts-de-France
59524,SAINGHIN EN WEPPES,59184,SAINGHIN EN WEPPES,,50.5583359872,2.89740255201,524,,Sainghin-en-Weppes,Sainghin-en-Weppes,59,Nord,32,Hauts-de-France
59525,SAINS DU NORD,59177,SAINS DU NORD,,50.0854794146,4.03223070419,525,,Sains-du-Nord,Sains-du-Nord,59,Nord,32,Hauts-de-France
59526,ST AMAND LES EAUX,59230,ST AMAND LES EAUX,,50.4401000981,3.44460708029,526,,Saint-Amand-les-Eaux,Saint-Amand-les-Eaux,59,Nord,32,Hauts-de-France
59527,ST ANDRE LEZ LILLE,59350,ST ANDRE LEZ LILLE,,50.6597692969,3.04609387889,527,,Saint-André-lez-Lille,Saint-André-lez-Lille,59,Nord,32,Hauts-de-France
59528,ST AUBERT,59188,ST AUBERT,,50.2076147877,3.41741294384,528,,Saint-Aubert,Saint-Aubert,59,Nord,32,Hauts-de-France
59529,ST AUBIN,59440,ST AUBIN,,50.1709642642,3.91201093007,529,,Saint-Aubin,Saint-Aubin,59,Nord,32,Hauts-de-France
59530,ST AYBERT,59163,ST AYBERT,,50.4513178883,3.64979329449,530,,Saint-Aybert,Saint-Aybert,59,Nord,32,Hauts-de-France
59531,ST BENIN,59360,ST BENIN,,50.0760031859,3.5318789033,531,,Saint-Benin,Saint-Benin,59,Nord,32,Hauts-de-France
59532,ST GEORGES SUR L AA,59820,ST GEORGES SUR L AA,,50.967052608,2.15668818231,532,,Saint-Georges-sur-l'Aa,Saint-Georges-sur-l'Aa,59,Nord,32,Hauts-de-France
59533,ST HILAIRE LEZ CAMBRAI,59292,ST HILAIRE LEZ CAMBRAI,,50.1812812123,3.4168912736,533,,Saint-Hilaire-lez-Cambrai,Saint-Hilaire-lez-Cambrai,59,Nord,32,Hauts-de-France
59534,ST HILAIRE SUR HELPE,59440,ST HILAIRE SUR HELPE,,50.1366870157,3.90004713488,534,,Saint-Hilaire-sur-Helpe,Saint-Hilaire-sur-Helpe,59,Nord,32,Hauts-de-France
59535,ST JANS CAPPEL,59270,ST JANS CAPPEL,,50.7684241988,2.72184821596,535,,Saint-Jans-Cappel,Saint-Jans-Cappel,59,Nord,32,Hauts-de-France
59536,STE MARIE CAPPEL,59670,STE MARIE CAPPEL,,50.780208006,2.51589636551,536,,Sainte-Marie-Cappel,Sainte-Marie-Cappel,59,Nord,32,Hauts-de-France
59537,ST MARTIN SUR ECAILLON,59213,ST MARTIN SUR ECAILLON,,50.2401464788,3.51737991935,537,,Saint-Martin-sur-Écaillon,Saint-Martin-sur-Écaillon,59,Nord,32,Hauts-de-France
59538,ST MOMELIN,59143,ST MOMELIN,,50.8126413042,2.24135657399,538,,Saint-Momelin,Saint-Momelin,59,Nord,32,Hauts-de-France
59539,ST PIERRE BROUCK,59630,ST PIERRE BROUCK,,50.8950694199,2.19025685455,539,,Saint-Pierre-Brouck,Saint-Pierre-Brouck,59,Nord,32,Hauts-de-France
59541,ST PYTHON,59730,ST PYTHON,,50.1935153197,3.47506674368,541,,Saint-Python,Saint-Python,59,Nord,32,Hauts-de-France
59542,ST REMY CHAUSSEE,59620,ST REMY CHAUSSEE,,50.1799835542,3.87887382168,542,,Saint-Remy-Chaussée,Saint-Remy-Chaussée,59,Nord,32,Hauts-de-France
59543,ST REMY DU NORD,59330,ST REMY DU NORD,,50.2280842932,3.90173812805,543,,Saint-Remy-du-Nord,Saint-Remy-du-Nord,59,Nord,32,Hauts-de-France
59544,ST SAULVE,59880,ST SAULVE,,50.3750172475,3.56642418912,544,,Saint-Saulve,Saint-Saulve,59,Nord,32,Hauts-de-France
59545,ST SOUPLET,59360,ST SOUPLET,,50.0531218076,3.51585525327,545,,Saint-Souplet,Saint-Souplet,59,Nord,32,Hauts-de-France
59545,ST SOUPLET,59360,ST SOUPLET,ESCAUFOURT,50.0531218076,3.51585525327,545,,Saint-Souplet,Saint-Souplet,59,Nord,32,Hauts-de-France
59546,ST SYLVESTRE CAPPEL,59114,ST SYLVESTRE CAPPEL,,50.7757055306,2.55012081181,546,,Saint-Sylvestre-Cappel,Saint-Sylvestre-Cappel,59,Nord,32,Hauts-de-France
59547,ST VAAST EN CAMBRESIS,59188,ST VAAST EN CAMBRESIS,,50.1944271461,3.43509312626,547,,Saint-Vaast-en-Cambrésis,Saint-Vaast-en-Cambrésis,59,Nord,32,Hauts-de-France
59548,ST WAAST,59570,ST WAAST,,50.3009046885,3.74775611906,548,,Saint-Waast,Saint-Waast,59,Nord,32,Hauts-de-France
59549,SALESCHES,59218,SALESCHES,,50.2082539959,3.59623286765,549,,Salesches,Salesches,59,Nord,32,Hauts-de-France
59550,SALOME,59496,SALOME,,50.5358395709,2.83692247297,550,,Salomé,Salomé,59,Nord,32,Hauts-de-France
59551,SAMEON,59310,SAMEON,,50.4772545596,3.33248163784,551,,Saméon,Saméon,59,Nord,32,Hauts-de-France
59552,SANCOURT,59268,SANCOURT,,50.2144498638,3.19130962776,552,,Sancourt,Sancourt,59,Nord,32,Hauts-de-France
59553,SANTES,59211,SANTES,,50.5909879066,2.96114421951,553,,Santes,Santes,59,Nord,32,Hauts-de-France
59554,SARS ET ROSIERES,59230,SARS ET ROSIERES,,50.446729768,3.33012536259,554,,Sars-et-Rosières,Sars-et-Rosières,59,Nord,32,Hauts-de-France
59555,SARS POTERIES,59216,SARS POTERIES,,50.1616514836,4.04379435649,555,,Sars-Poteries,Sars-Poteries,59,Nord,32,Hauts-de-France
59556,SASSEGNIES,59145,SASSEGNIES,,50.1790384849,3.79850687638,556,,Sassegnies,Sassegnies,59,Nord,32,Hauts-de-France
59557,SAULTAIN,59990,SAULTAIN,,50.335080255,3.58004167583,557,,Saultain,Saultain,59,Nord,32,Hauts-de-France
59558,SAULZOIR,59227,SAULZOIR,,50.2425296454,3.43939074526,558,,Saulzoir,Saulzoir,59,Nord,32,Hauts-de-France
59559,SEBOURG,59990,SEBOURG,,50.3441268565,3.64525979429,559,,Sebourg,Sebourg,59,Nord,32,Hauts-de-France
59560,SECLIN,59113,SECLIN,,50.5462914067,3.03440410368,560,,Seclin,Seclin,59,Nord,32,Hauts-de-France
59562,SEMERIES,59440,SEMERIES,,50.104983917,3.98854238684,562,,Sémeries,Sémeries,59,Nord,32,Hauts-de-France
59563,SEMOUSIES,59440,SEMOUSIES,,50.1639637271,3.96792099301,563,,Semousies,Semousies,59,Nord,32,Hauts-de-France
59564,LA SENTINELLE,59174,LA SENTINELLE,,50.3486299909,3.47455507349,564,La,Sentinelle,La Sentinelle,59,Nord,32,Hauts-de-France
59565,SEPMERIES,59269,SEPMERIES,,50.2772551517,3.56080213817,565,,Sepmeries,Sepmeries,59,Nord,32,Hauts-de-France
59566,SEQUEDIN,59320,SEQUEDIN,,50.6248188486,2.98254300781,566,,Sequedin,Sequedin,59,Nord,32,Hauts-de-France
59567,SERANVILLERS FORENVILLE,59400,SERANVILLERS FORENVILLE,,50.1266132129,3.27725549348,567,,Séranvillers-Forenville,Séranvillers-Forenville,59,Nord,32,Hauts-de-France
59568,SERCUS,59173,SERCUS,,50.7083527622,2.45193657383,568,,Sercus,Sercus,59,Nord,32,Hauts-de-France
59569,SIN LE NOBLE,59450,SIN LE NOBLE,,50.3677512577,3.12071297192,569,,Sin-le-Noble,Sin-le-Noble,59,Nord,32,Hauts-de-France
59570,SOCX,59380,SOCX,,50.9358655303,2.42417743396,570,,Socx,Socx,59,Nord,32,Hauts-de-France
59571,SOLESMES,59730,SOLESMES,,50.171784611,3.51976547867,571,,Solesmes,Solesmes,59,Nord,32,Hauts-de-France
59572,SOLRE LE CHATEAU,59740,SOLRE LE CHATEAU,,50.1722246859,4.09630317789,572,,Solre-le-Château,Solre-le-Château,59,Nord,32,Hauts-de-France
59573,SOLRINNES,59740,SOLRINNES,,50.2029200373,4.0732007826,573,,Solrinnes,Solrinnes,59,Nord,32,Hauts-de-France
59574,SOMAIN,59490,SOMAIN,,50.3598009687,3.27519704161,574,,Somain,Somain,59,Nord,32,Hauts-de-France
59575,SOMMAING,59213,SOMMAING,,50.2674478954,3.49584685724,575,,Sommaing,Sommaing,59,Nord,32,Hauts-de-France
59576,SPYCKER,59380,SPYCKER,,50.9751405786,2.31360058844,576,,Spycker,Spycker,59,Nord,32,Hauts-de-France
59577,STAPLE,59190,STAPLE,,50.7522593819,2.44870670166,577,,Staple,Staple,59,Nord,32,Hauts-de-France
59578,STEENBECQUE,59189,STEENBECQUE,,50.6774388783,2.48812239192,578,,Steenbecque,Steenbecque,59,Nord,32,Hauts-de-France
59579,STEENE,59380,STEENE,,50.9531939911,2.37222398651,579,,Steene,Steene,59,Nord,32,Hauts-de-France
59580,STEENVOORDE,59114,STEENVOORDE,,50.8214340622,2.58556642469,580,,Steenvoorde,Steenvoorde,59,Nord,32,Hauts-de-France
59581,STEENWERCK,59181,STEENWERCK,,50.6864176007,2.76933714516,581,,Steenwerck,Steenwerck,59,Nord,32,Hauts-de-France
59581,STEENWERCK,59181,STEENWERCK,CROIX DU BAC,50.6864176007,2.76933714516,581,,Steenwerck,Steenwerck,59,Nord,32,Hauts-de-France
59582,STRAZEELE,59270,STRAZEELE,,50.7237136693,2.61971858959,582,,Strazeele,Strazeele,59,Nord,32,Hauts-de-France
59583,TAISNIERES EN THIERACHE,59550,TAISNIERES EN THIERACHE,,50.1455912411,3.81152119353,583,,Taisnières-en-Thiérache,Taisnières-en-Thiérache,59,Nord,32,Hauts-de-France
59584,TAISNIERES SUR HON,59570,TAISNIERES SUR HON,,50.3215475622,3.85119216193,584,,Taisnières-sur-Hon,Taisnières-sur-Hon,59,Nord,32,Hauts-de-France
59585,TEMPLEMARS,59175,TEMPLEMARS,,50.5706303055,3.0593969462,585,,Templemars,Templemars,59,Nord,32,Hauts-de-France
59586,TEMPLEUVE EN PEVELE,59242,TEMPLEUVE EN PEVELE,,50.5327534602,3.17344214233,586,,Templeuve-en-Pévèle,Templeuve-en-Pévèle,59,Nord,32,Hauts-de-France
59587,TERDEGHEM,59114,TERDEGHEM,,50.7976310827,2.55518666354,587,,Terdeghem,Terdeghem,59,Nord,32,Hauts-de-France
59588,TETEGHEM COUDEKERQUE VILLAGE,59229,TETEGHEM COUDEKERQUE VILLAGE,,51.0158652678,2.45278368133,588,,Téteghem-Coudekerque-Village,Téteghem-Coudekerque-Village,59,Nord,32,Hauts-de-France
59588,TETEGHEM COUDEKERQUE VILLAGE,59380,TETEGHEM COUDEKERQUE VILLAGE,COUDEKERQUE VILLAGE,51.0158652678,2.45278368133,588,,Téteghem-Coudekerque-Village,Téteghem-Coudekerque-Village,59,Nord,32,Hauts-de-France
59589,THIANT,59224,THIANT,,50.2978015553,3.43898383487,589,,Thiant,Thiant,59,Nord,32,Hauts-de-France
59590,THIENNES,59189,THIENNES,,50.6509078196,2.47577776887,590,,Thiennes,Thiennes,59,Nord,32,Hauts-de-France
59591,THIVENCELLE,59163,THIVENCELLE,,50.4459598369,3.62768756311,591,,Thivencelle,Thivencelle,59,Nord,32,Hauts-de-France
59592,THUMERIES,59239,THUMERIES,,50.4729282172,3.06360015777,592,,Thumeries,Thumeries,59,Nord,32,Hauts-de-France
59593,THUN L EVEQUE,59141,THUN L EVEQUE,,50.2257650605,3.28109704734,593,,Thun-l'Évêque,Thun-l'Évêque,59,Nord,32,Hauts-de-France
59594,THUN ST AMAND,59158,THUN ST AMAND,,50.4847098079,3.44835522897,594,,Thun-Saint-Amand,Thun-Saint-Amand,59,Nord,32,Hauts-de-France
59595,THUN ST MARTIN,59141,THUN ST MARTIN,,50.218482215,3.30304020982,595,,Thun-Saint-Martin,Thun-Saint-Martin,59,Nord,32,Hauts-de-France
59596,TILLOY LEZ MARCHIENNES,59870,TILLOY LEZ MARCHIENNES,,50.4251780134,3.32276515057,596,,Tilloy-lez-Marchiennes,Tilloy-lez-Marchiennes,59,Nord,32,Hauts-de-France
59597,TILLOY LEZ CAMBRAI,59554,TILLOY LEZ CAMBRAI,,50.2010948825,3.21429536974,597,,Tilloy-lez-Cambrai,Tilloy-lez-Cambrai,59,Nord,32,Hauts-de-France
59598,TOUFFLERS,59390,TOUFFLERS,,50.6609540641,3.23330964809,598,,Toufflers,Toufflers,59,Nord,32,Hauts-de-France
59599,TOURCOING,59200,TOURCOING,,50.7254418902,3.15882757215,599,,Tourcoing,Tourcoing,59,Nord,32,Hauts-de-France
59600,TOURMIGNIES,59551,TOURMIGNIES,,50.5041629351,3.08506088086,600,,Tourmignies,Tourmignies,59,Nord,32,Hauts-de-France
59601,TRELON,59132,TRELON,,50.0755155252,4.12172301875,601,,Trélon,Trélon,59,Nord,32,Hauts-de-France
59602,TRESSIN,59152,TRESSIN,,50.6172932924,3.18934892273,602,,Tressin,Tressin,59,Nord,32,Hauts-de-France
59603,TRITH ST LEGER,59125,TRITH ST LEGER,,50.33056703,3.48645305133,603,,Trith-Saint-Léger,Trith-Saint-Léger,59,Nord,32,Hauts-de-France
59604,TROISVILLES,59980,TROISVILLES,,50.1023251315,3.47303644624,604,,Troisvilles,Troisvilles,59,Nord,32,Hauts-de-France
59605,UXEM,59229,UXEM,,51.0239750832,2.49246889837,605,,Uxem,Uxem,59,Nord,32,Hauts-de-France
59606,VALENCIENNES,59300,VALENCIENNES,,50.3588666163,3.51567664819,606,,Valenciennes,Valenciennes,59,Nord,32,Hauts-de-France
59607,VENDEGIES AU BOIS,59218,VENDEGIES AU BOIS,,50.1780771295,3.57802377396,607,,Vendegies-au-Bois,Vendegies-au-Bois,59,Nord,32,Hauts-de-France
59608,VENDEGIES SUR ECAILLON,59213,VENDEGIES SUR ECAILLON,,50.2600667641,3.50791948811,608,,Vendegies-sur-Écaillon,Vendegies-sur-Écaillon,59,Nord,32,Hauts-de-France
59609,VENDEVILLE,59175,VENDEVILLE,,50.5731550754,3.08064149278,609,,Vendeville,Vendeville,59,Nord,32,Hauts-de-France
59610,VERCHAIN MAUGRE,59227,VERCHAIN MAUGRE,,50.2661390084,3.46320478499,610,,Verchain-Maugré,Verchain-Maugré,59,Nord,32,Hauts-de-France
59611,VERLINGHEM,59237,VERLINGHEM,,50.6820809996,3.0005883762,611,,Verlinghem,Verlinghem,59,Nord,32,Hauts-de-France
59612,VERTAIN,59730,VERTAIN,,50.2124028613,3.52268754362,612,,Vertain,Vertain,59,Nord,32,Hauts-de-France
59613,VICQ,59970,VICQ,,50.4144657364,3.6078304121,613,,Vicq,Vicq,59,Nord,32,Hauts-de-France
59614,VIESLY,59271,VIESLY,,50.1571125186,3.45621053562,614,,Viesly,Viesly,59,Nord,32,Hauts-de-France
59615,VIEUX BERQUIN,59232,VIEUX BERQUIN,,50.6932504715,2.62068917125,615,,Vieux-Berquin,Vieux-Berquin,59,Nord,32,Hauts-de-France
59616,VIEUX CONDE,59690,VIEUX CONDE,,50.4735638651,3.56606734565,616,,Vieux-Condé,Vieux-Condé,59,Nord,32,Hauts-de-France
59617,VIEUX MESNIL,59138,VIEUX MESNIL,,50.2599763963,3.87105908253,617,,Vieux-Mesnil,Vieux-Mesnil,59,Nord,32,Hauts-de-France
59618,VIEUX RENG,59600,VIEUX RENG,,50.3213611397,4.0463688859,618,,Vieux-Reng,Vieux-Reng,59,Nord,32,Hauts-de-France
59619,VILLEREAU,59530,VILLEREAU,,50.2478551697,3.68060159072,619,,Villereau,Villereau,59,Nord,32,Hauts-de-France
59619,VILLEREAU,59530,VILLEREAU,HERBIGNIES VILLEREAU,50.2478551697,3.68060159072,619,,Villereau,Villereau,59,Nord,32,Hauts-de-France
59620,VILLERS AU TERTRE,59234,VILLERS AU TERTRE,,50.3020721089,3.17587411198,620,,Villers-au-Tertre,Villers-au-Tertre,59,Nord,32,Hauts-de-France
59622,VILLERS EN CAUCHIES,59188,VILLERS EN CAUCHIES,,50.2264212975,3.39435479006,622,,Villers-en-Cauchies,Villers-en-Cauchies,59,Nord,32,Hauts-de-France
59623,VILLERS GUISLAIN,59297,VILLERS GUISLAIN,,50.0359972826,3.15257855128,623,,Villers-Guislain,Villers-Guislain,59,Nord,32,Hauts-de-France
59624,VILLERS OUTREAUX,59142,VILLERS OUTREAUX,,50.0297720966,3.29193521329,624,,Villers-Outréaux,Villers-Outréaux,59,Nord,32,Hauts-de-France
59625,VILLERS PLOUICH,59231,VILLERS PLOUICH,,50.0789345355,3.13659590623,625,,Villers-Plouich,Villers-Plouich,59,Nord,32,Hauts-de-France
59626,VILLERS POL,59530,VILLERS POL,,50.2889516363,3.6151652439,626,,Villers-Pol,Villers-Pol,59,Nord,32,Hauts-de-France
59627,VILLERS SIRE NICOLE,59600,VILLERS SIRE NICOLE,,50.339831062,4.01339230045,627,,Villers-Sire-Nicole,Villers-Sire-Nicole,59,Nord,32,Hauts-de-France
59628,VOLCKERINCKHOVE,59470,VOLCKERINCKHOVE,,50.839933688,2.29875013353,628,,Volckerinckhove,Volckerinckhove,59,Nord,32,Hauts-de-France
59629,VRED,59870,VRED,,50.3939409921,3.23217277359,629,,Vred,Vred,59,Nord,32,Hauts-de-France
59630,WAHAGNIES,59261,WAHAGNIES,,50.4837257649,3.03028758206,630,,Wahagnies,Wahagnies,59,Nord,32,Hauts-de-France
59631,WALINCOURT SELVIGNY,59127,WALINCOURT SELVIGNY,,50.0733447799,3.33900382143,631,,Walincourt-Selvigny,Walincourt-Selvigny,59,Nord,32,Hauts-de-France
59631,WALINCOURT SELVIGNY,59127,WALINCOURT SELVIGNY,SELVIGNY,50.0733447799,3.33900382143,631,,Walincourt-Selvigny,Walincourt-Selvigny,59,Nord,32,Hauts-de-France
59632,WALLERS,59135,WALLERS,,50.3808461182,3.39313610701,632,,Wallers,Wallers,59,Nord,32,Hauts-de-France
59632,WALLERS,59135,WALLERS,ARENBERG,50.3808461182,3.39313610701,632,,Wallers,Wallers,59,Nord,32,Hauts-de-France
59633,WALLERS EN FAGNE,59132,WALLERS EN FAGNE,,50.0613544764,4.16718008292,633,,Wallers-en-Fagne,Wallers-en-Fagne,59,Nord,32,Hauts-de-France
59634,WALLON CAPPEL,59190,WALLON CAPPEL,,50.7278457083,2.48249397523,634,,Wallon-Cappel,Wallon-Cappel,59,Nord,32,Hauts-de-France
59635,WAMBAIX,59400,WAMBAIX,,50.1283412382,3.30897119661,635,,Wambaix,Wambaix,59,Nord,32,Hauts-de-France
59636,WAMBRECHIES,59118,WAMBRECHIES,,50.6957054596,3.0473159475,636,,Wambrechies,Wambrechies,59,Nord,32,Hauts-de-France
59637,WANDIGNIES HAMAGE,59870,WANDIGNIES HAMAGE,,50.3972962992,3.32367136058,637,,Wandignies-Hamage,Wandignies-Hamage,59,Nord,32,Hauts-de-France
59638,WANNEHAIN,59830,WANNEHAIN,,50.5709754745,3.27007498669,638,,Wannehain,Wannehain,59,Nord,32,Hauts-de-France
59639,WARGNIES LE GRAND,59144,WARGNIES LE GRAND,,50.3079420993,3.65866407405,639,,Wargnies-le-Grand,Wargnies-le-Grand,59,Nord,32,Hauts-de-France
59640,WARGNIES LE PETIT,59144,WARGNIES LE PETIT,,50.2986246557,3.68344256708,640,,Wargnies-le-Petit,Wargnies-le-Petit,59,Nord,32,Hauts-de-France
59641,WARHEM,59380,WARHEM,,50.9770774555,2.50331327056,641,,Warhem,Warhem,59,Nord,32,Hauts-de-France
59642,WARLAING,59870,WARLAING,,50.4093268684,3.32433906396,642,,Warlaing,Warlaing,59,Nord,32,Hauts-de-France
59643,WARNETON,59560,WARNETON,,50.7453105306,2.96704012681,643,,Warneton,Warneton,59,Nord,32,Hauts-de-France
59645,WASNES AU BAC,59252,WASNES AU BAC,,50.2699499987,3.25379518679,645,,Wasnes-au-Bac,Wasnes-au-Bac,59,Nord,32,Hauts-de-France
59646,WASQUEHAL,59290,WASQUEHAL,,50.6750984751,3.12858017096,646,,Wasquehal,Wasquehal,59,Nord,32,Hauts-de-France
59647,WATTEN,59143,WATTEN,,50.833804191,2.22588651794,647,,Watten,Watten,59,Nord,32,Hauts-de-France
59648,WATTIGNIES,59139,WATTIGNIES,,50.5864928159,3.04193803597,648,,Wattignies,Wattignies,59,Nord,32,Hauts-de-France
59649,WATTIGNIES LA VICTOIRE,59680,WATTIGNIES LA VICTOIRE,,50.2003544445,4.00235676778,649,,Wattignies-la-Victoire,Wattignies-la-Victoire,59,Nord,32,Hauts-de-France
59650,WATTRELOS,59150,WATTRELOS,,50.7056367285,3.21628221056,650,,Wattrelos,Wattrelos,59,Nord,32,Hauts-de-France
59651,WAVRECHAIN SOUS DENAIN,59220,WAVRECHAIN SOUS DENAIN,,50.3338831581,3.42191162083,651,,Wavrechain-sous-Denain,Wavrechain-sous-Denain,59,Nord,32,Hauts-de-France
59652,WAVRECHAIN SOUS FAULX,59111,WAVRECHAIN SOUS FAULX,,50.270787775,3.28212535821,652,,Wavrechain-sous-Faulx,Wavrechain-sous-Faulx,59,Nord,32,Hauts-de-France
59653,WAVRIN,59136,WAVRIN,,50.5726224029,2.93337226806,653,,Wavrin,Wavrin,59,Nord,32,Hauts-de-France
59654,WAZIERS,59119,WAZIERS,,50.3842186333,3.11188476067,654,,Waziers,Waziers,59,Nord,32,Hauts-de-France
59655,WEMAERS CAPPEL,59670,WEMAERS CAPPEL,,50.8152774849,2.44143393834,655,,Wemaers-Cappel,Wemaers-Cappel,59,Nord,32,Hauts-de-France
59656,WERVICQ SUD,59117,WERVICQ SUD,,50.7620461406,3.05089776037,656,,Wervicq-Sud,Wervicq-Sud,59,Nord,32,Hauts-de-France
59657,WEST CAPPEL,59380,WEST CAPPEL,,50.9254100501,2.50878268816,657,,West-Cappel,West-Cappel,59,Nord,32,Hauts-de-France
59658,WICRES,59134,WICRES,,50.5694195145,2.86858974063,658,,Wicres,Wicres,59,Nord,32,Hauts-de-France
59659,WIGNEHIES,59212,WIGNEHIES,,50.0118044778,4.0065736506,659,,Wignehies,Wignehies,59,Nord,32,Hauts-de-France
59660,WILLEMS,59780,WILLEMS,,50.6306074254,3.22916996988,660,,Willems,Willems,59,Nord,32,Hauts-de-France
59661,WILLIES,59740,WILLIES,,50.1212728931,4.10501451158,661,,Willies,Willies,59,Nord,32,Hauts-de-France
59662,WINNEZEELE,59670,WINNEZEELE,,50.8503260936,2.56373009721,662,,Winnezeele,Winnezeele,59,Nord,32,Hauts-de-France
59663,WORMHOUT,59470,WORMHOUT,,50.8778557286,2.47860517129,663,,Wormhout,Wormhout,59,Nord,32,Hauts-de-France
59664,WULVERDINGHE,59143,WULVERDINGHE,,50.8284324881,2.2531343958,664,,Wulverdinghe,Wulverdinghe,59,Nord,32,Hauts-de-France
59665,WYLDER,59380,WYLDER,,50.9111679301,2.48735893081,665,,Wylder,Wylder,59,Nord,32,Hauts-de-France
59666,ZEGERSCAPPEL,59470,ZEGERSCAPPEL,,50.8833052086,2.384587094,666,,Zegerscappel,Zegerscappel,59,Nord,32,Hauts-de-France
59667,ZERMEZEELE,59670,ZERMEZEELE,,50.8321932353,2.4589529897,667,,Zermezeele,Zermezeele,59,Nord,32,Hauts-de-France
59668,ZUYDCOOTE,59123,ZUYDCOOTE,,51.06377209,2.48763279882,668,,Zuydcoote,Zuydcoote,59,Nord,32,Hauts-de-France
59669,ZUYTPEENE,59670,ZUYTPEENE,,50.7918240712,2.42330162819,669,,Zuytpeene,Zuytpeene,59,Nord,32,Hauts-de-France
59670,DON,59272,DON,,50.5440489811,2.91557809769,670,,Don,Don,59,Nord,32,Hauts-de-France
60001,ABANCOURT,60220,ABANCOURT,,49.6930546611,1.76924374516,1,,Abancourt,Abancourt,60,Oise,32,Hauts-de-France
60002,ABBECOURT,60430,ABBECOURT,,49.361199272,2.15509033815,2,,Abbecourt,Abbecourt,60,Oise,32,Hauts-de-France
60003,ABBEVILLE ST LUCIEN,60480,ABBEVILLE ST LUCIEN,,49.5201068363,2.16636536385,3,,Abbeville-Saint-Lucien,Abbeville-Saint-Lucien,60,Oise,32,Hauts-de-France
60004,ACHY,60690,ACHY,,49.5562211633,1.97185766368,4,,Achy,Achy,60,Oise,32,Hauts-de-France
60005,ACY EN MULTIEN,60620,ACY EN MULTIEN,,49.1087771245,2.95509279724,5,,Acy-en-Multien,Acy-en-Multien,60,Oise,32,Hauts-de-France
60006,LES AGEUX,60700,LES AGEUX,,49.3183042237,2.5890180969,6,Les,Ageux,Les Ageux,60,Oise,32,Hauts-de-France
60007,AGNETZ,60600,AGNETZ,,49.3961836166,2.38254589214,7,,Agnetz,Agnetz,60,Oise,32,Hauts-de-France
60008,AIRION,60600,AIRION,,49.4222514079,2.41814312594,8,,Airion,Airion,60,Oise,32,Hauts-de-France
60009,ALLONNE,60000,ALLONNE,,49.3965343512,2.11883442492,9,,Allonne,Allonne,60,Oise,32,Hauts-de-France
60010,AMBLAINVILLE,60110,AMBLAINVILLE,,49.2071001179,2.12057880418,10,,Amblainville,Amblainville,60,Oise,32,Hauts-de-France
60011,AMY,60310,AMY,,49.6414081201,2.83395091808,11,,Amy,Amy,60,Oise,32,Hauts-de-France
60012,ANDEVILLE,60570,ANDEVILLE,,49.257573184,2.16834997777,12,,Andeville,Andeville,60,Oise,32,Hauts-de-France
60013,ANGICOURT,60940,ANGICOURT,,49.3148919573,2.50251888584,13,,Angicourt,Angicourt,60,Oise,32,Hauts-de-France
60014,ANGIVILLERS,60130,ANGIVILLERS,,49.4889521261,2.49971184199,14,,Angivillers,Angivillers,60,Oise,32,Hauts-de-France
60015,ANGY,60250,ANGY,,49.334468969,2.32846218053,15,,Angy,Angy,60,Oise,32,Hauts-de-France
60016,ANSACQ,60250,ANSACQ,,49.350204752,2.36431728817,16,,Ansacq,Ansacq,60,Oise,32,Hauts-de-France
60017,ANSAUVILLERS,60120,ANSAUVILLERS,,49.5672978302,2.38642388977,17,,Ansauvillers,Ansauvillers,60,Oise,32,Hauts-de-France
60019,ANTHEUIL PORTES,60162,ANTHEUIL PORTES,,49.5004605023,2.73385430881,19,,Antheuil-Portes,Antheuil-Portes,60,Oise,32,Hauts-de-France
60020,ANTILLY,60620,ANTILLY,,49.1550668913,2.98806856134,20,,Antilly,Antilly,60,Oise,32,Hauts-de-France
60021,APPILLY,60400,APPILLY,,49.582424474,3.12116123031,21,,Appilly,Appilly,60,Oise,32,Hauts-de-France
60022,APREMONT,60300,APREMONT,,49.2286452463,2.5135789889,22,,Apremont,Apremont,60,Oise,32,Hauts-de-France
60023,ARMANCOURT,60880,ARMANCOURT,,49.370833099,2.76400949363,23,,Armancourt,Armancourt,60,Oise,32,Hauts-de-France
60024,ARSY,60190,ARSY,,49.4056086887,2.68958438939,24,,Arsy,Arsy,60,Oise,32,Hauts-de-France
60025,ATTICHY,60350,ATTICHY,,49.4257269191,3.04404702722,25,,Attichy,Attichy,60,Oise,32,Hauts-de-France
60026,AUCHY LA MONTAGNE,60360,AUCHY LA MONTAGNE,,49.5739624558,2.11947399318,26,,Auchy-la-Montagne,Auchy-la-Montagne,60,Oise,32,Hauts-de-France
60027,AUGER ST VINCENT,60800,AUGER ST VINCENT,,49.2124952218,2.81319586858,27,,Auger-Saint-Vincent,Auger-Saint-Vincent,60,Oise,32,Hauts-de-France
60028,AUMONT EN HALATTE,60300,AUMONT EN HALATTE,,49.2330047148,2.55262710425,28,,Aumont-en-Halatte,Aumont-en-Halatte,60,Oise,32,Hauts-de-France
60029,AUNEUIL,60390,AUNEUIL,,49.3702714618,1.99554869838,29,,Auneuil,Auneuil,60,Oise,32,Hauts-de-France
60030,AUTEUIL,60390,AUTEUIL,,49.3450250122,2.09238872376,30,,Auteuil,Auteuil,60,Oise,32,Hauts-de-France
60031,AUTHEUIL EN VALOIS,60890,AUTHEUIL EN VALOIS,,49.1792888729,3.06349602702,31,,Autheuil-en-Valois,Autheuil-en-Valois,60,Oise,32,Hauts-de-France
60032,AUTRECHES,60350,AUTRECHES,,49.449194192,3.12722554549,32,,Autrêches,Autrêches,60,Oise,32,Hauts-de-France
60033,AVILLY ST LEONARD,60300,AVILLY ST LEONARD,,49.1839157473,2.52767735366,33,,Avilly-Saint-Léonard,Avilly-Saint-Léonard,60,Oise,32,Hauts-de-France
60034,AVRECHY,60130,AVRECHY,,49.4449120653,2.42380338592,34,,Avrechy,Avrechy,60,Oise,32,Hauts-de-France
60035,AVRICOURT,60310,AVRICOURT,,49.6496055244,2.86403233407,35,,Avricourt,Avricourt,60,Oise,32,Hauts-de-France
60036,AVRIGNY,60190,AVRIGNY,,49.3890770811,2.57702799562,36,,Avrigny,Avrigny,60,Oise,32,Hauts-de-France
60037,BABOEUF,60400,BABOEUF,,49.5820096557,3.09130594062,37,,Babœuf,Babœuf,60,Oise,32,Hauts-de-France
60038,BACHIVILLERS,60240,BACHIVILLERS,,49.2869210002,1.96825665241,38,,Bachivillers,Bachivillers,60,Oise,32,Hauts-de-France
60039,BACOUEL,60120,BACOUEL,,49.6218428027,2.38266948973,39,,Bacouël,Bacouël,60,Oise,32,Hauts-de-France
60040,BAILLEUL LE SOC,60190,BAILLEUL LE SOC,,49.4206214218,2.58376325171,40,,Bailleul-le-Soc,Bailleul-le-Soc,60,Oise,32,Hauts-de-France
60041,BAILLEUL SUR THERAIN,60930,BAILLEUL SUR THERAIN,,49.385157591,2.23033347435,41,,Bailleul-sur-Thérain,Bailleul-sur-Thérain,60,Oise,32,Hauts-de-France
60042,BAILLEVAL,60140,BAILLEVAL,,49.3492689664,2.46620386202,42,,Bailleval,Bailleval,60,Oise,32,Hauts-de-France
60043,BAILLY,60170,BAILLY,,49.4994561216,2.98240258389,43,,Bailly,Bailly,60,Oise,32,Hauts-de-France
60044,BALAGNY SUR THERAIN,60250,BALAGNY SUR THERAIN,,49.2973878556,2.32131973295,44,,Balagny-sur-Thérain,Balagny-sur-Thérain,60,Oise,32,Hauts-de-France
60045,BARBERY,60810,BARBERY,,49.2222684941,2.67330860939,45,,Barbery,Barbery,60,Oise,32,Hauts-de-France
60046,BARGNY,60620,BARGNY,,49.1776038507,2.95817932227,46,,Bargny,Bargny,60,Oise,32,Hauts-de-France
60047,BARON,60300,BARON,,49.1754522891,2.7348143898,47,,Baron,Baron,60,Oise,32,Hauts-de-France
60048,BAUGY,60113,BAUGY,,49.4559271908,2.75517506422,48,,Baugy,Baugy,60,Oise,32,Hauts-de-France
60048,BAUGY,60190,BAUGY,,49.4559271908,2.75517506422,48,,Baugy,Baugy,60,Oise,32,Hauts-de-France
60049,BAZANCOURT,60380,BAZANCOURT,,49.5498849477,1.73943481164,49,,Bazancourt,Bazancourt,60,Oise,32,Hauts-de-France
60050,BAZICOURT,60700,BAZICOURT,,49.3431574033,2.62299591456,50,,Bazicourt,Bazicourt,60,Oise,32,Hauts-de-France
60051,BEAUDEDUIT,60210,BEAUDEDUIT,,49.6788602196,2.05926915948,51,,Beaudéduit,Beaudéduit,60,Oise,32,Hauts-de-France
60052,BEAUGIES SOUS BOIS,60640,BEAUGIES SOUS BOIS,,49.6372067347,3.09986657497,52,,Beaugies-sous-Bois,Beaugies-sous-Bois,60,Oise,32,Hauts-de-France
60053,BEAULIEU LES FONTAINES,60310,BEAULIEU LES FONTAINES,,49.6653459644,2.91164661769,53,,Beaulieu-les-Fontaines,Beaulieu-les-Fontaines,60,Oise,32,Hauts-de-France
60054,BEAUMONT LES NONAINS,60390,BEAUMONT LES NONAINS,,49.3254912942,2.00355965035,54,,Beaumont-les-Nonains,Beaumont-les-Nonains,60,Oise,32,Hauts-de-France
60055,BEAURAINS LES NOYON,60400,BEAURAINS LES NOYON,,49.6065980784,2.9753206205,55,,Beaurains-lès-Noyon,Beaurains-lès-Noyon,60,Oise,32,Hauts-de-France
60056,BEAUREPAIRE,60700,BEAUREPAIRE,,49.2916886469,2.56951230843,56,,Beaurepaire,Beaurepaire,60,Oise,32,Hauts-de-France
60057,BEAUVAIS,60000,BEAUVAIS,,49.4365523321,2.08616123661,57,,Beauvais,Beauvais,60,Oise,32,Hauts-de-France
60058,BEAUVOIR,60120,BEAUVOIR,,49.605809976,2.33019531687,58,,Beauvoir,Beauvoir,60,Oise,32,Hauts-de-France
60059,BEHERICOURT,60400,BEHERICOURT,,49.5980389585,3.06410555853,59,,Béhéricourt,Béhéricourt,60,Oise,32,Hauts-de-France
60060,BELLE EGLISE,60540,BELLE EGLISE,,49.1881561836,2.21521967543,60,,Belle-Église,Belle-Église,60,Oise,32,Hauts-de-France
60061,BELLOY,60490,BELLOY,,49.5352929666,2.65285100616,61,,Belloy,Belloy,60,Oise,32,Hauts-de-France
60062,BERLANCOURT,60640,BERLANCOURT,,49.6785871051,3.08371773899,62,,Berlancourt,Berlancourt,60,Oise,32,Hauts-de-France
60063,BERNEUIL EN BRAY,60390,BERNEUIL EN BRAY,,49.3578687801,2.05826883915,63,,Berneuil-en-Bray,Berneuil-en-Bray,60,Oise,32,Hauts-de-France
60064,BERNEUIL SUR AISNE,60350,BERNEUIL SUR AISNE,,49.4218075738,3.00010078676,64,,Berneuil-sur-Aisne,Berneuil-sur-Aisne,60,Oise,32,Hauts-de-France
60065,BERTHECOURT,60370,BERTHECOURT,,49.3451833083,2.22291035284,65,,Berthecourt,Berthecourt,60,Oise,32,Hauts-de-France
60066,BETHANCOURT EN VALOIS,60129,BETHANCOURT EN VALOIS,,49.2792326515,2.87566048733,66,,Béthancourt-en-Valois,Béthancourt-en-Valois,60,Oise,32,Hauts-de-France
60067,BETHISY ST MARTIN,60320,BETHISY ST MARTIN,,49.2875795546,2.812008154,67,,Béthisy-Saint-Martin,Béthisy-Saint-Martin,60,Oise,32,Hauts-de-France
60068,BETHISY ST PIERRE,60320,BETHISY ST PIERRE,,49.3076185781,2.80360801965,68,,Béthisy-Saint-Pierre,Béthisy-Saint-Pierre,60,Oise,32,Hauts-de-France
60069,BETZ,60620,BETZ,,49.1559241622,2.94290715615,69,,Betz,Betz,60,Oise,32,Hauts-de-France
60070,BIENVILLE,60280,BIENVILLE,,49.4477920759,2.82514033131,70,,Bienville,Bienville,60,Oise,32,Hauts-de-France
60071,BIERMONT,60490,BIERMONT,,49.5770777764,2.73899530755,71,,Biermont,Biermont,60,Oise,32,Hauts-de-France
60072,BITRY,60350,BITRY,,49.4145786012,3.07807803458,72,,Bitry,Bitry,60,Oise,32,Hauts-de-France
60073,BLACOURT,60650,BLACOURT,,49.4557951068,1.86303368192,73,,Blacourt,Blacourt,60,Oise,32,Hauts-de-France
60074,BLAINCOURT LES PRECY,60460,BLAINCOURT LES PRECY,,49.2280944073,2.34451291361,74,,Blaincourt-lès-Précy,Blaincourt-lès-Précy,60,Oise,32,Hauts-de-France
60075,BLANCFOSSE,60120,BLANCFOSSE,,49.662501216,2.18953950322,75,,Blancfossé,Blancfossé,60,Oise,32,Hauts-de-France
60076,BLARGIES,60220,BLARGIES,,49.6713426247,1.76339266657,76,,Blargies,Blargies,60,Oise,32,Hauts-de-France
60077,BLICOURT,60860,BLICOURT,,49.5608239154,2.06049872178,77,,Blicourt,Blicourt,60,Oise,32,Hauts-de-France
60078,BLINCOURT,60190,BLINCOURT,,49.3848096307,2.61936803553,78,,Blincourt,Blincourt,60,Oise,32,Hauts-de-France
60079,BOISSY FRESNOY,60440,BOISSY FRESNOY,,49.1659624514,2.88226234522,79,,Boissy-Fresnoy,Boissy-Fresnoy,60,Oise,32,Hauts-de-France
60080,BOISSY LE BOIS,60240,BOISSY LE BOIS,,49.278858787,1.94117700611,80,,Boissy-le-Bois,Boissy-le-Bois,60,Oise,32,Hauts-de-France
60081,BONLIER,60510,BONLIER,,49.4727781384,2.14990842093,81,,Bonlier,Bonlier,60,Oise,32,Hauts-de-France
60082,BONNEUIL LES EAUX,60120,BONNEUIL LES EAUX,,49.6808426625,2.24243541639,82,,Bonneuil-les-Eaux,Bonneuil-les-Eaux,60,Oise,32,Hauts-de-France
60083,BONNEUIL EN VALOIS,60123,BONNEUIL EN VALOIS,,49.2800248996,2.98130103699,83,,Bonneuil-en-Valois,Bonneuil-en-Valois,60,Oise,32,Hauts-de-France
60084,BONNIERES,60112,BONNIERES,,49.508074732,1.95670880366,84,,Bonnières,Bonnières,60,Oise,32,Hauts-de-France
60085,BONVILLERS,60120,BONVILLERS,,49.5855067939,2.35206121544,85,,Bonvillers,Bonvillers,60,Oise,32,Hauts-de-France
60086,BORAN SUR OISE,60820,BORAN SUR OISE,,49.1749794772,2.35195670267,86,,Boran-sur-Oise,Boran-sur-Oise,60,Oise,32,Hauts-de-France
60087,BOREST,60300,BOREST,,49.1886146842,2.67197292503,87,,Borest,Borest,60,Oise,32,Hauts-de-France
60088,BORNEL,60540,BORNEL,,49.1918532269,2.18485090875,88,,Bornel,Bornel,60,Oise,32,Hauts-de-France
60088,BORNEL,60540,BORNEL,ANSERVILLE,49.1918532269,2.18485090875,88,,Bornel,Bornel,60,Oise,32,Hauts-de-France
60088,BORNEL,60540,BORNEL,FOSSEUSE,49.1918532269,2.18485090875,88,,Bornel,Bornel,60,Oise,32,Hauts-de-France
60089,BOUBIERS,60240,BOUBIERS,,49.2165992699,1.87067010234,89,,Boubiers,Boubiers,60,Oise,32,Hauts-de-France
60090,BOUCONVILLERS,60240,BOUCONVILLERS,,49.1734900557,1.90034078826,90,,Bouconvillers,Bouconvillers,60,Oise,32,Hauts-de-France
60091,BOUILLANCY,60620,BOUILLANCY,,49.1176919226,2.91177538082,91,,Bouillancy,Bouillancy,60,Oise,32,Hauts-de-France
60092,BOULLARRE,60620,BOULLARRE,,49.1304808787,3.00990272849,92,,Boullarre,Boullarre,60,Oise,32,Hauts-de-France
60093,BOULOGNE LA GRASSE,60490,BOULOGNE LA GRASSE,,49.6080302361,2.70097863571,93,,Boulogne-la-Grasse,Boulogne-la-Grasse,60,Oise,32,Hauts-de-France
60094,BOURSONNE,60141,BOURSONNE,,49.1983151268,3.04668303732,94,,Boursonne,Boursonne,60,Oise,32,Hauts-de-France
60095,BOURY EN VEXIN,60240,BOURY EN VEXIN,,49.2424145077,1.7371079349,95,,Boury-en-Vexin,Boury-en-Vexin,60,Oise,32,Hauts-de-France
60096,BOUTAVENT,60220,BOUTAVENT,,49.6384622091,1.75329152429,96,,Boutavent,Boutavent,60,Oise,32,Hauts-de-France
60097,BOUTENCOURT,60590,BOUTENCOURT,,49.3205572988,1.87026132379,97,,Boutencourt,Boutencourt,60,Oise,32,Hauts-de-France
60098,BOUVRESSE,60220,BOUVRESSE,,49.6514305652,1.75717052982,98,,Bouvresse,Bouvresse,60,Oise,32,Hauts-de-France
60099,BRAISNES SUR ARONDE,60113,BRAISNES SUR ARONDE,,49.4761042434,2.77402760825,99,,Braisnes-sur-Aronde,Braisnes-sur-Aronde,60,Oise,32,Hauts-de-France
60100,BRASSEUSE,60810,BRASSEUSE,,49.2518806817,2.67877402724,100,,Brasseuse,Brasseuse,60,Oise,32,Hauts-de-France
60101,BREGY,60440,BREGY,,49.0895785618,2.87413951706,101,,Brégy,Brégy,60,Oise,32,Hauts-de-France
60102,BRENOUILLE,60870,BRENOUILLE,,49.3068934524,2.5499630565,102,,Brenouille,Brenouille,60,Oise,32,Hauts-de-France
60103,BRESLES,60510,BRESLES,,49.4135603419,2.25008112215,103,,Bresles,Bresles,60,Oise,32,Hauts-de-France
60104,BRETEUIL,60120,BRETEUIL,,49.6340425257,2.29183403499,104,,Breteuil,Breteuil,60,Oise,32,Hauts-de-France
60105,BRETIGNY,60400,BRETIGNY,,49.5596891409,3.11657953141,105,,Brétigny,Brétigny,60,Oise,32,Hauts-de-France
60106,BREUIL LE SEC,60840,BREUIL LE SEC,,49.3780023932,2.45643096781,106,,Breuil-le-Sec,Breuil-le-Sec,60,Oise,32,Hauts-de-France
60107,BREUIL LE VERT,60600,BREUIL LE VERT,,49.3619627867,2.42549520398,107,,Breuil-le-Vert,Breuil-le-Vert,60,Oise,32,Hauts-de-France
60108,BRIOT,60210,BRIOT,,49.6413335684,1.91716979734,108,,Briot,Briot,60,Oise,32,Hauts-de-France
60109,BROMBOS,60210,BROMBOS,,49.6455079765,1.88822480879,109,,Brombos,Brombos,60,Oise,32,Hauts-de-France
60110,BROQUIERS,60220,BROQUIERS,,49.6626603129,1.83561315094,110,,Broquiers,Broquiers,60,Oise,32,Hauts-de-France
60111,BROYES,60120,BROYES,,49.6277624015,2.45363892412,111,,Broyes,Broyes,60,Oise,32,Hauts-de-France
60112,BRUNVILLERS LA MOTTE,60130,BRUNVILLERS LA MOTTE,,49.555566184,2.45376962705,112,,Brunvillers-la-Motte,Brunvillers-la-Motte,60,Oise,32,Hauts-de-France
60113,BUCAMPS,60480,BUCAMPS,,49.5247948425,2.3166105981,113,,Bucamps,Bucamps,60,Oise,32,Hauts-de-France
60114,BUICOURT,60380,BUICOURT,,49.5341980923,1.81531179837,114,,Buicourt,Buicourt,60,Oise,32,Hauts-de-France
60115,BULLES,60130,BULLES,,49.4569537729,2.33518875415,115,,Bulles,Bulles,60,Oise,32,Hauts-de-France
60116,BURY,60250,BURY,,49.3128542225,2.35865250561,116,,Bury,Bury,60,Oise,32,Hauts-de-France
60117,BUSSY,60400,BUSSY,,49.6314236015,2.98056681507,117,,Bussy,Bussy,60,Oise,32,Hauts-de-France
60118,CAISNES,60400,CAISNES,,49.5201213587,3.06454486519,118,,Caisnes,Caisnes,60,Oise,32,Hauts-de-France
60119,CAMBRONNE LES RIBECOURT,60170,CAMBRONNE LES RIBECOURT,,49.5062092065,2.89807134548,119,,Cambronne-lès-Ribécourt,Cambronne-lès-Ribécourt,60,Oise,32,Hauts-de-France
60120,CAMBRONNE LES CLERMONT,60290,CAMBRONNE LES CLERMONT,,49.3259611654,2.40115486808,120,,Cambronne-lès-Clermont,Cambronne-lès-Clermont,60,Oise,32,Hauts-de-France
60121,CAMPAGNE,60640,CAMPAGNE,,49.6495792933,2.96070669901,121,,Campagne,Campagne,60,Oise,32,Hauts-de-France
60122,CAMPEAUX,60220,CAMPEAUX,,49.6140351708,1.75497677348,122,,Campeaux,Campeaux,60,Oise,32,Hauts-de-France
60123,CAMPREMY,60480,CAMPREMY,,49.5638031018,2.32696331161,123,,Campremy,Campremy,60,Oise,32,Hauts-de-France
60124,CANDOR,60310,CANDOR,,49.6285566714,2.88780350461,124,,Candor,Candor,60,Oise,32,Hauts-de-France
60125,CANLY,60680,CANLY,,49.389080292,2.69965926929,125,,Canly,Canly,60,Oise,32,Hauts-de-France
60126,CANNECTANCOURT,60310,CANNECTANCOURT,,49.5483651127,2.89622271842,126,,Cannectancourt,Cannectancourt,60,Oise,32,Hauts-de-France
60127,CANNY SUR MATZ,60310,CANNY SUR MATZ,,49.6025511779,2.80098340652,127,,Canny-sur-Matz,Canny-sur-Matz,60,Oise,32,Hauts-de-France
60128,CANNY SUR THERAIN,60220,CANNY SUR THERAIN,,49.6080822276,1.71771760656,128,,Canny-sur-Thérain,Canny-sur-Thérain,60,Oise,32,Hauts-de-France
60129,CARLEPONT,60170,CARLEPONT,,49.5191522759,3.02234053869,129,,Carlepont,Carlepont,60,Oise,32,Hauts-de-France
60130,CATENOY,60840,CATENOY,,49.3791543364,2.51800677203,130,,Catenoy,Catenoy,60,Oise,32,Hauts-de-France
60131,CATHEUX,60360,CATHEUX,,49.6494174689,2.10545550049,131,,Catheux,Catheux,60,Oise,32,Hauts-de-France
60132,CATIGNY,60640,CATIGNY,,49.6348936549,2.9419257001,132,,Catigny,Catigny,60,Oise,32,Hauts-de-France
60133,CATILLON FUMECHON,60130,CATILLON FUMECHON,,49.5268754931,2.37843374733,133,,Catillon-Fumechon,Catillon-Fumechon,60,Oise,32,Hauts-de-France
60134,CAUFFRY,60290,CAUFFRY,,49.3134355162,2.43026281523,134,,Cauffry,Cauffry,60,Oise,32,Hauts-de-France
60135,CAUVIGNY,60730,CAUVIGNY,,49.3026459577,2.24002692157,135,,Cauvigny,Cauvigny,60,Oise,32,Hauts-de-France
60136,CEMPUIS,60210,CEMPUIS,,49.6575445065,1.98508588361,136,,Cempuis,Cempuis,60,Oise,32,Hauts-de-France
60137,CERNOY,60190,CERNOY,,49.4426355659,2.53929202364,137,,Cernoy,Cernoy,60,Oise,32,Hauts-de-France
60138,CHAMANT,60300,CHAMANT,,49.2258210412,2.61918764925,138,,Chamant,Chamant,60,Oise,32,Hauts-de-France
60139,CHAMBLY,60230,CHAMBLY,,49.1718103651,2.24657019692,139,,Chambly,Chambly,60,Oise,32,Hauts-de-France
60140,CHAMBORS,60240,CHAMBORS,,49.2601158315,1.81072865391,140,,Chambors,Chambors,60,Oise,32,Hauts-de-France
60141,CHANTILLY,60500,CHANTILLY,,49.1762641654,2.48103481914,141,,Chantilly,Chantilly,60,Oise,32,Hauts-de-France
60142,LA CHAPELLE EN SERVAL,60520,LA CHAPELLE EN SERVAL,,49.1212917393,2.5265939072,142,La,Chapelle-en-Serval,La Chapelle-en-Serval,60,Oise,32,Hauts-de-France
60143,CHAUMONT EN VEXIN,60240,CHAUMONT EN VEXIN,,49.2729979316,1.88182608902,143,,Chaumont-en-Vexin,Chaumont-en-Vexin,60,Oise,32,Hauts-de-France
60144,CHAVENCON,60240,CHAVENCON,,49.1872473906,1.9918563453,144,,Chavençon,Chavençon,60,Oise,32,Hauts-de-France
60145,CHELLES,60350,CHELLES,,49.3466662796,3.03951779948,145,,Chelles,Chelles,60,Oise,32,Hauts-de-France
60146,CHEPOIX,60120,CHEPOIX,,49.6048407456,2.38064262302,146,,Chepoix,Chepoix,60,Oise,32,Hauts-de-France
60147,CHEVINCOURT,60150,CHEVINCOURT,,49.5167606649,2.85123034306,147,,Chevincourt,Chevincourt,60,Oise,32,Hauts-de-France
60148,CHEVREVILLE,60440,CHEVREVILLE,,49.1188920411,2.85852113558,148,,Chèvreville,Chèvreville,60,Oise,32,Hauts-de-France
60149,CHEVRIERES,60710,CHEVRIERES,,49.3461074721,2.67968198793,149,,Chevrières,Chevrières,60,Oise,32,Hauts-de-France
60150,CHIRY OURSCAMP,60138,CHIRY OURSCAMP,,49.5303142759,2.97100668785,150,,Chiry-Ourscamp,Chiry-Ourscamp,60,Oise,32,Hauts-de-France
60151,CHOISY AU BAC,60750,CHOISY AU BAC,,49.442089833,2.89567270285,151,,Choisy-au-Bac,Choisy-au-Bac,60,Oise,32,Hauts-de-France
60152,CHOISY LA VICTOIRE,60190,CHOISY LA VICTOIRE,,49.3775941705,2.59726878219,152,,Choisy-la-Victoire,Choisy-la-Victoire,60,Oise,32,Hauts-de-France
60153,CHOQUEUSE LES BENARDS,60360,CHOQUEUSE LES BENARDS,,49.649815276,2.07488298131,153,,Choqueuse-les-Bénards,Choqueuse-les-Bénards,60,Oise,32,Hauts-de-France
60154,CINQUEUX,60940,CINQUEUX,,49.3243592276,2.53354291409,154,,Cinqueux,Cinqueux,60,Oise,32,Hauts-de-France
60155,CIRES LES MELLO,60660,CIRES LES MELLO,,49.262678531,2.33736387513,155,,Cires-lès-Mello,Cires-lès-Mello,60,Oise,32,Hauts-de-France
60156,CLAIROIX,60280,CLAIROIX,,49.4412986705,2.8453991397,156,,Clairoix,Clairoix,60,Oise,32,Hauts-de-France
60157,CLERMONT,60600,CLERMONT,,49.3777168975,2.40891709196,157,,Clermont,Clermont,60,Oise,32,Hauts-de-France
60158,COIVREL,60420,COIVREL,,49.5554230001,2.55108811608,158,,Coivrel,Coivrel,60,Oise,32,Hauts-de-France
60159,COMPIEGNE,60200,COMPIEGNE,,49.3990601478,2.85317249363,159,,Compiègne,Compiègne,60,Oise,32,Hauts-de-France
60160,CONCHY LES POTS,60490,CONCHY LES POTS,,49.6073168338,2.73630966072,160,,Conchy-les-Pots,Conchy-les-Pots,60,Oise,32,Hauts-de-France
60161,CONTEVILLE,60360,CONTEVILLE,,49.6534471153,2.05414599318,161,,Conteville,Conteville,60,Oise,32,Hauts-de-France
60162,CORBEIL CERF,60110,CORBEIL CERF,,49.2772532427,2.11061298697,162,,Corbeil-Cerf,Corbeil-Cerf,60,Oise,32,Hauts-de-France
60163,CORMEILLES,60120,CORMEILLES,,49.6424440702,2.19661684856,163,,Cormeilles,Cormeilles,60,Oise,32,Hauts-de-France
60164,LE COUDRAY ST GERMER,60850,LE COUDRAY ST GERMER,,49.4006526373,1.83221910213,164,Le,Coudray-Saint-Germer,Le Coudray-Saint-Germer,60,Oise,32,Hauts-de-France
60165,LE COUDRAY SUR THELLE,60430,LE COUDRAY SUR THELLE,,49.3091487399,2.12428741588,165,Le,Coudray-sur-Thelle,Le Coudray-sur-Thelle,60,Oise,32,Hauts-de-France
60166,COUDUN,60150,COUDUN,,49.4581784318,2.8000113018,166,,Coudun,Coudun,60,Oise,32,Hauts-de-France
60167,COULOISY,60350,COULOISY,,49.4005386894,3.02837690735,167,,Couloisy,Couloisy,60,Oise,32,Hauts-de-France
60168,COURCELLES EPAYELLES,60420,COURCELLES EPAYELLES,,49.5696380397,2.62594591233,168,,Courcelles-Epayelles,Courcelles-Epayelles,60,Oise,32,Hauts-de-France
60169,COURCELLES LES GISORS,60240,COURCELLES LES GISORS,,49.2594249464,1.73615075347,169,,Courcelles-lès-Gisors,Courcelles-lès-Gisors,60,Oise,32,Hauts-de-France
60170,COURTEUIL,60300,COURTEUIL,,49.2065256712,2.54439836724,170,,Courteuil,Courteuil,60,Oise,32,Hauts-de-France
60171,COURTIEUX,60350,COURTIEUX,,49.3852025965,3.08510106889,171,,Courtieux,Courtieux,60,Oise,32,Hauts-de-France
60172,COYE LA FORET,60580,COYE LA FORET,,49.1449874027,2.47045856809,172,,Coye-la-Forêt,Coye-la-Forêt,60,Oise,32,Hauts-de-France
60173,CRAMOISY,60660,CRAMOISY,,49.2489499722,2.39573291818,173,,Cramoisy,Cramoisy,60,Oise,32,Hauts-de-France
60174,CRAPEAUMESNIL,60310,CRAPEAUMESNIL,,49.6404571177,2.80722897477,174,,Crapeaumesnil,Crapeaumesnil,60,Oise,32,Hauts-de-France
60175,CREIL,60100,CREIL,,49.2533487138,2.48472669359,175,,Creil,Creil,60,Oise,32,Hauts-de-France
60176,CREPY EN VALOIS,60800,CREPY EN VALOIS,,49.2360130888,2.8969318206,176,,Crépy-en-Valois,Crépy-en-Valois,60,Oise,32,Hauts-de-France
60177,CRESSONSACQ,60190,CRESSONSACQ,,49.448238217,2.56793368491,177,,Cressonsacq,Cressonsacq,60,Oise,32,Hauts-de-France
60178,CREVECOEUR LE GRAND,60360,CREVECOEUR LE GRAND,,49.6125388783,2.08489020774,178,,Crèvecœur-le-Grand,Crèvecœur-le-Grand,60,Oise,32,Hauts-de-France
60179,CREVECOEUR LE PETIT,60420,CREVECOEUR LE PETIT,,49.5740115762,2.49886203911,179,,Crèvecœur-le-Petit,Crèvecœur-le-Petit,60,Oise,32,Hauts-de-France
60180,CRILLON,60112,CRILLON,,49.5259729634,1.9257668088,180,,Crillon,Crillon,60,Oise,32,Hauts-de-France
60181,CRISOLLES,60400,CRISOLLES,,49.6230708747,3.02420793836,181,,Crisolles,Crisolles,60,Oise,32,Hauts-de-France
60182,LE CROCQ,60120,LE CROCQ,,49.6208925269,2.18869008805,182,Le,Crocq,Le Crocq,60,Oise,32,Hauts-de-France
60183,CROISSY SUR CELLE,60120,CROISSY SUR CELLE,,49.6903535459,2.17212127925,183,,Croissy-sur-Celle,Croissy-sur-Celle,60,Oise,32,Hauts-de-France
60184,CROUTOY,60350,CROUTOY,,49.3772107204,3.03575847593,184,,Croutoy,Croutoy,60,Oise,32,Hauts-de-France
60185,CROUY EN THELLE,60530,CROUY EN THELLE,,49.2099385814,2.32007698271,185,,Crouy-en-Thelle,Crouy-en-Thelle,60,Oise,32,Hauts-de-France
60186,CUIGNIERES,60130,CUIGNIERES,,49.4481411967,2.4717178571,186,,Cuignières,Cuignières,60,Oise,32,Hauts-de-France
60187,CUIGY EN BRAY,60850,CUIGY EN BRAY,,49.4323341573,1.83507266626,187,,Cuigy-en-Bray,Cuigy-en-Bray,60,Oise,32,Hauts-de-France
60188,CUISE LA MOTTE,60350,CUISE LA MOTTE,,49.3859702699,2.99976302541,188,,Cuise-la-Motte,Cuise-la-Motte,60,Oise,32,Hauts-de-France
60189,CUTS,60400,CUTS,,49.5342045787,3.10272038663,189,,Cuts,Cuts,60,Oise,32,Hauts-de-France
60190,CUVERGNON,60620,CUVERGNON,,49.1740067801,2.99807792645,190,,Cuvergnon,Cuvergnon,60,Oise,32,Hauts-de-France
60191,CUVILLY,60490,CUVILLY,,49.5486898805,2.70082321252,191,,Cuvilly,Cuvilly,60,Oise,32,Hauts-de-France
60192,CUY,60310,CUY,,49.5855409296,2.90969070334,192,,Cuy,Cuy,60,Oise,32,Hauts-de-France
60193,DAMERAUCOURT,60210,DAMERAUCOURT,,49.701294681,1.92280713557,193,,Daméraucourt,Daméraucourt,60,Oise,32,Hauts-de-France
60194,DARGIES,60210,DARGIES,,49.6991905153,1.98093616856,194,,Dargies,Dargies,60,Oise,32,Hauts-de-France
60195,DELINCOURT,60240,DELINCOURT,,49.2396598274,1.83241773712,195,,Delincourt,Delincourt,60,Oise,32,Hauts-de-France
60196,LE DELUGE,60790,LE DELUGE,,49.2942535417,2.11111174674,196,La,Drenne,La Drenne,60,Oise,32,Hauts-de-France
60197,DIEUDONNE,60530,DIEUDONNE,,49.241132253,2.24030627221,197,,Dieudonné,Dieudonné,60,Oise,32,Hauts-de-France
60198,DIVES,60310,DIVES,,49.5909351098,2.8785644648,198,,Dives,Dives,60,Oise,32,Hauts-de-France
60199,DOMELIERS,60360,DOMELIERS,,49.6285305666,2.16465510243,199,,Doméliers,Doméliers,60,Oise,32,Hauts-de-France
60200,DOMFRONT,60420,DOMFRONT,,49.5991248237,2.55703431624,200,,Domfront,Domfront,60,Oise,32,Hauts-de-France
60201,DOMPIERRE,60420,DOMPIERRE,,49.5927370104,2.53422054952,201,,Dompierre,Dompierre,60,Oise,32,Hauts-de-France
60203,DUVY,60800,DUVY,,49.2322741704,2.84879453989,203,,Duvy,Duvy,60,Oise,32,Hauts-de-France
60204,ECUVILLY,60310,ECUVILLY,,49.6473710219,2.91673971975,204,,Écuvilly,Écuvilly,60,Oise,32,Hauts-de-France
60205,ELENCOURT,60210,ELENCOURT,,49.6931893721,1.89535227719,205,,Élencourt,Élencourt,60,Oise,32,Hauts-de-France
60206,ELINCOURT STE MARGUERITE,60157,ELINCOURT STE MARGUERITE,,49.5317901702,2.82885830555,206,,Élincourt-Sainte-Marguerite,Élincourt-Sainte-Marguerite,60,Oise,32,Hauts-de-France
60207,EMEVILLE,60123,EMEVILLE,,49.2797702031,3.02463728753,207,,Éméville,Éméville,60,Oise,32,Hauts-de-France
60208,ENENCOURT LEAGE,60590,ENENCOURT LEAGE,,49.3046719364,1.84454960402,208,,Énencourt-Léage,Énencourt-Léage,60,Oise,32,Hauts-de-France
60209,ENENCOURT LE SEC,60240,ENENCOURT LE SEC,,49.295556054,1.91817578256,209,,Énencourt-le-Sec,Énencourt-le-Sec,60,Oise,32,Hauts-de-France
60210,EPINEUSE,60190,EPINEUSE,,49.400292993,2.55014914769,210,,Épineuse,Épineuse,60,Oise,32,Hauts-de-France
60211,ERAGNY SUR EPTE,60590,ERAGNY SUR EPTE,,49.312023845,1.78713220993,211,,Éragny-sur-Epte,Éragny-sur-Epte,60,Oise,32,Hauts-de-France
60212,ERCUIS,60530,ERCUIS,,49.2335196293,2.30851412485,212,,Ercuis,Ercuis,60,Oise,32,Hauts-de-France
60213,ERMENONVILLE,60950,ERMENONVILLE,,49.1256725288,2.69642408679,213,,Ermenonville,Ermenonville,60,Oise,32,Hauts-de-France
60214,ERNEMONT BOUTAVENT,60380,ERNEMONT BOUTAVENT,,49.5930690775,1.79502801856,214,,Ernemont-Boutavent,Ernemont-Boutavent,60,Oise,32,Hauts-de-France
60215,ERQUERY,60600,ERQUERY,,49.4136451151,2.45644712469,215,,Erquery,Erquery,60,Oise,32,Hauts-de-France
60216,ERQUINVILLERS,60130,ERQUINVILLERS,,49.4596126861,2.48947452235,216,,Erquinvillers,Erquinvillers,60,Oise,32,Hauts-de-France
60217,ESCAMES,60380,ESCAMES,,49.5565711127,1.80571620416,217,,Escames,Escames,60,Oise,32,Hauts-de-France
60218,ESCHES,60110,ESCHES,,49.2315594255,2.17454733737,218,,Esches,Esches,60,Oise,32,Hauts-de-France
60219,ESCLES ST PIERRE,60220,ESCLES ST PIERRE,,49.7444351551,1.80313112603,219,,Escles-Saint-Pierre,Escles-Saint-Pierre,60,Oise,32,Hauts-de-France
60220,ESPAUBOURG,60650,ESPAUBOURG,,49.4241291278,1.8655489571,220,,Espaubourg,Espaubourg,60,Oise,32,Hauts-de-France
60221,ESQUENNOY,60120,ESQUENNOY,,49.6550975512,2.26906443197,221,,Esquennoy,Esquennoy,60,Oise,32,Hauts-de-France
60222,ESSUILES,60510,ESSUILES,,49.4802122478,2.28119101511,222,,Essuiles,Essuiles,60,Oise,32,Hauts-de-France
60223,ESTREES ST DENIS,60190,ESTREES ST DENIS,,49.4263442144,2.62953613001,223,,Estrées-Saint-Denis,Estrées-Saint-Denis,60,Oise,32,Hauts-de-France
60224,ETAVIGNY,60620,ETAVIGNY,,49.1276676436,2.98017417243,224,,Étavigny,Étavigny,60,Oise,32,Hauts-de-France
60225,ETOUY,60600,ETOUY,,49.4281067222,2.37019823319,225,,Étouy,Étouy,60,Oise,32,Hauts-de-France
60226,EVE,60330,EVE,,49.087549811,2.70898194051,226,,Ève,Ève,60,Oise,32,Hauts-de-France
60227,EVRICOURT,60310,EVRICOURT,,49.570401673,2.91627648313,227,,Évricourt,Évricourt,60,Oise,32,Hauts-de-France
60228,FAY LES ETANGS,60240,FAY LES ETANGS,,49.2495116378,1.94467191321,228,,Fay-les-Étangs,Fay-les-Étangs,60,Oise,32,Hauts-de-France
60229,LE FAYEL,60680,LE FAYEL,,49.3722915111,2.6969172661,229,Le,Fayel,Le Fayel,60,Oise,32,Hauts-de-France
60230,LE FAY ST QUENTIN,60510,LE FAY ST QUENTIN,,49.4490275764,2.25128332435,230,Le,Fay-Saint-Quentin,Le Fay-Saint-Quentin,60,Oise,32,Hauts-de-France
60231,FEIGNEUX,60800,FEIGNEUX,,49.2609818537,2.91459037295,231,,Feigneux,Feigneux,60,Oise,32,Hauts-de-France
60232,FERRIERES,60420,FERRIERES,,49.5890936598,2.51315398391,232,,Ferrières,Ferrières,60,Oise,32,Hauts-de-France
60233,FEUQUIERES,60960,FEUQUIERES,,49.6498151324,1.84993618914,233,,Feuquières,Feuquières,60,Oise,32,Hauts-de-France
60234,FITZ JAMES,60600,FITZ JAMES,,49.3993972661,2.4291033299,234,,Fitz-James,Fitz-James,60,Oise,32,Hauts-de-France
60235,FLAVACOURT,60590,FLAVACOURT,,49.3460308859,1.82886153194,235,,Flavacourt,Flavacourt,60,Oise,32,Hauts-de-France
60236,FLAVY LE MELDEUX,60640,FLAVY LE MELDEUX,,49.6851555754,3.0475541502,236,,Flavy-le-Meldeux,Flavy-le-Meldeux,60,Oise,32,Hauts-de-France
60237,FLECHY,60120,FLECHY,,49.6589445196,2.23097979586,237,,Fléchy,Fléchy,60,Oise,32,Hauts-de-France
60238,FLEURINES,60700,FLEURINES,,49.2623361218,2.59258349492,238,,Fleurines,Fleurines,60,Oise,32,Hauts-de-France
60239,FLEURY,60240,FLEURY,,49.2456151425,1.96684307339,239,,Fleury,Fleury,60,Oise,32,Hauts-de-France
60240,FONTAINE BONNELEAU,60360,FONTAINE BONNELEAU,,49.6636581121,2.14897434682,240,,Fontaine-Bonneleau,Fontaine-Bonneleau,60,Oise,32,Hauts-de-France
60241,FONTAINE CHAALIS,60300,FONTAINE CHAALIS,,49.1547073818,2.66051125918,241,,Fontaine-Chaalis,Fontaine-Chaalis,60,Oise,32,Hauts-de-France
60242,FONTAINE LAVAGANNE,60690,FONTAINE LAVAGANNE,,49.5986219699,1.9543319916,242,,Fontaine-Lavaganne,Fontaine-Lavaganne,60,Oise,32,Hauts-de-France
60243,FONTAINE ST LUCIEN,60480,FONTAINE ST LUCIEN,,49.5040754183,2.14696786986,243,,Fontaine-Saint-Lucien,Fontaine-Saint-Lucien,60,Oise,32,Hauts-de-France
60244,FONTENAY TORCY,60380,FONTENAY TORCY,,49.5683000437,1.76602231488,244,,Fontenay-Torcy,Fontenay-Torcy,60,Oise,32,Hauts-de-France
60245,FORMERIE,60220,FORMERIE,,49.6492986407,1.72801391707,245,,Formerie,Formerie,60,Oise,32,Hauts-de-France
60247,FOUILLEUSE,60190,FOUILLEUSE,,49.4269878907,2.54053926429,247,,Fouilleuse,Fouilleuse,60,Oise,32,Hauts-de-France
60248,FOUILLOY,60220,FOUILLOY,,49.7312722644,1.81819749576,248,,Fouilloy,Fouilloy,60,Oise,32,Hauts-de-France
60249,FOULANGUES,60250,FOULANGUES,,49.2769230112,2.31380236183,249,,Foulangues,Foulangues,60,Oise,32,Hauts-de-France
60250,FOUQUENIES,60000,FOUQUENIES,,49.4652825822,2.03011183536,250,,Fouquenies,Fouquenies,60,Oise,32,Hauts-de-France
60251,FOUQUEROLLES,60510,FOUQUEROLLES,,49.4590327609,2.21413521512,251,,Fouquerolles,Fouquerolles,60,Oise,32,Hauts-de-France
60252,FOURNIVAL,60130,FOURNIVAL,,49.4639491439,2.38160769117,252,,Fournival,Fournival,60,Oise,32,Hauts-de-France
60253,FRANCASTEL,60480,FRANCASTEL,,49.5898566137,2.14867375497,253,,Francastel,Francastel,60,Oise,32,Hauts-de-France
60254,FRANCIERES,60190,FRANCIERES,,49.4471256066,2.66733923856,254,,Francières,Francières,60,Oise,32,Hauts-de-France
60255,FRENICHES,60640,FRENICHES,,49.6743203422,3.00463917072,255,,Fréniches,Fréniches,60,Oise,32,Hauts-de-France
60256,FRESNEAUX MONTCHEVREUIL,60240,FRESNEAUX MONTCHEVREUIL,,49.2879395667,2.01083675138,256,,Fresneaux-Montchevreuil,Fresneaux-Montchevreuil,60,Oise,32,Hauts-de-France
60257,FRESNE LEGUILLON,60240,FRESNE LEGUILLON,,49.2509113423,1.98912391608,257,,Fresne-Léguillon,Fresne-Léguillon,60,Oise,32,Hauts-de-France
60258,FRESNIERES,60310,FRESNIERES,,49.6228561422,2.80910070167,258,,Fresnières,Fresnières,60,Oise,32,Hauts-de-France
60259,FRESNOY EN THELLE,60530,FRESNOY EN THELLE,,49.1990973717,2.26218558295,259,,Fresnoy-en-Thelle,Fresnoy-en-Thelle,60,Oise,32,Hauts-de-France
60260,FRESNOY LA RIVIERE,60127,FRESNOY LA RIVIERE,,49.2781522381,2.92700227503,260,,Fresnoy-la-Rivière,Fresnoy-la-Rivière,60,Oise,32,Hauts-de-France
60261,FRESNOY LE LUAT,60800,FRESNOY LE LUAT,,49.2127033818,2.76283065148,261,,Fresnoy-le-Luat,Fresnoy-le-Luat,60,Oise,32,Hauts-de-France
60262,LE FRESTOY VAUX,60420,LE FRESTOY VAUX,,49.5962921933,2.60833323074,262,Le,Frestoy-Vaux,Le Frestoy-Vaux,60,Oise,32,Hauts-de-France
60263,FRETOY LE CHATEAU,60640,FRETOY LE CHATEAU,,49.6618149905,2.97314954198,263,,Frétoy-le-Château,Frétoy-le-Château,60,Oise,32,Hauts-de-France
60264,FROCOURT,60000,FROCOURT,,49.3813474009,2.08360584323,264,,Frocourt,Frocourt,60,Oise,32,Hauts-de-France
60265,FROISSY,60480,FROISSY,,49.5657711832,2.21470986262,265,,Froissy,Froissy,60,Oise,32,Hauts-de-France
60267,LE GALLET,60360,LE GALLET,,49.6251188685,2.11231378778,267,Le,Gallet,Le Gallet,60,Oise,32,Hauts-de-France
60268,GANNES,60120,GANNES,,49.5738621459,2.4192438931,268,,Gannes,Gannes,60,Oise,32,Hauts-de-France
60269,GAUDECHART,60210,GAUDECHART,,49.6181645312,1.96029040389,269,,Gaudechart,Gaudechart,60,Oise,32,Hauts-de-France
60270,GENVRY,60400,GENVRY,,49.6116517465,2.99549959374,270,,Genvry,Genvry,60,Oise,32,Hauts-de-France
60271,GERBEROY,60380,GERBEROY,,49.5385653619,1.84224948917,271,,Gerberoy,Gerberoy,60,Oise,32,Hauts-de-France
60272,GILOCOURT,60129,GILOCOURT,,49.3007930979,2.89026457858,272,,Gilocourt,Gilocourt,60,Oise,32,Hauts-de-France
60273,GIRAUMONT,60150,GIRAUMONT,,49.4737037715,2.82476712242,273,,Giraumont,Giraumont,60,Oise,32,Hauts-de-France
60274,GLAIGNES,60129,GLAIGNES,,49.2730688353,2.84005159158,274,,Glaignes,Glaignes,60,Oise,32,Hauts-de-France
60275,GLATIGNY,60650,GLATIGNY,,49.4954037049,1.90088240357,275,,Glatigny,Glatigny,60,Oise,32,Hauts-de-France
60276,GODENVILLERS,60420,GODENVILLERS,,49.5820126531,2.54731751004,276,,Godenvillers,Godenvillers,60,Oise,32,Hauts-de-France
60277,GOINCOURT,60000,GOINCOURT,,49.4249364687,2.03316738421,277,,Goincourt,Goincourt,60,Oise,32,Hauts-de-France
60278,GOLANCOURT,60640,GOLANCOURT,,49.7032997149,3.06874567879,278,,Golancourt,Golancourt,60,Oise,32,Hauts-de-France
60279,GONDREVILLE,60117,GONDREVILLE,,49.2201415565,2.94864804292,279,,Gondreville,Gondreville,60,Oise,32,Hauts-de-France
60280,GOURCHELLES,60220,GOURCHELLES,,49.726483327,1.77900309327,280,,Gourchelles,Gourchelles,60,Oise,32,Hauts-de-France
60281,GOURNAY SUR ARONDE,60190,GOURNAY SUR ARONDE,,49.500679776,2.69144488848,281,,Gournay-sur-Aronde,Gournay-sur-Aronde,60,Oise,32,Hauts-de-France
60282,GOUVIEUX,60270,GOUVIEUX,,49.1895816267,2.42026324989,282,,Gouvieux,Gouvieux,60,Oise,32,Hauts-de-France
60283,GOUY LES GROSEILLERS,60120,GOUY LES GROSEILLERS,,49.6953840868,2.21713981156,283,,Gouy-les-Groseillers,Gouy-les-Groseillers,60,Oise,32,Hauts-de-France
60284,GRANDFRESNOY,60680,GRANDFRESNOY,,49.3762488165,2.65940543568,284,,Grandfresnoy,Grandfresnoy,60,Oise,32,Hauts-de-France
60285,GRANDVILLERS AUX BOIS,60190,GRANDVILLERS AUX BOIS,,49.4641649904,2.60552551813,285,,Grandvillers-aux-Bois,Grandvillers-aux-Bois,60,Oise,32,Hauts-de-France
60286,GRANDVILLIERS,60210,GRANDVILLIERS,,49.666016357,1.9349239261,286,,Grandvilliers,Grandvilliers,60,Oise,32,Hauts-de-France
60287,GRANDRU,60400,GRANDRU,,49.6125799831,3.07876647173,287,,Grandrû,Grandrû,60,Oise,32,Hauts-de-France
60288,GREMEVILLERS,60380,GREMEVILLERS,,49.5591317883,1.90615994772,288,,Grémévillers,Grémévillers,60,Oise,32,Hauts-de-France
60289,GREZ,60210,GREZ,,49.6337227766,1.97882087154,289,,Grez,Grez,60,Oise,32,Hauts-de-France
60290,GUIGNECOURT,60480,GUIGNECOURT,,49.4868854532,2.12865823482,290,,Guignecourt,Guignecourt,60,Oise,32,Hauts-de-France
60291,GUISCARD,60640,GUISCARD,,49.6583847453,3.05502192746,291,,Guiscard,Guiscard,60,Oise,32,Hauts-de-France
60292,GURY,60310,GURY,,49.5703401492,2.80143019037,292,,Gury,Gury,60,Oise,32,Hauts-de-France
60293,HADANCOURT LE HAUT CLOCHER,60240,HADANCOURT LE HAUT CLOCHER,,49.1888359993,1.85320082465,293,,Hadancourt-le-Haut-Clocher,Hadancourt-le-Haut-Clocher,60,Oise,32,Hauts-de-France
60294,HAINVILLERS,60490,HAINVILLERS,,49.5909803966,2.68189967625,294,,Hainvillers,Hainvillers,60,Oise,32,Hauts-de-France
60295,HALLOY,60210,HALLOY,,49.6498614207,1.9366921667,295,,Halloy,Halloy,60,Oise,32,Hauts-de-France
60296,HANNACHES,60650,HANNACHES,,49.5087752838,1.80840356243,296,,Hannaches,Hannaches,60,Oise,32,Hauts-de-France
60297,LE HAMEL,60210,LE HAMEL,,49.6453258761,2.01203536602,297,Le,Hamel,Le Hamel,60,Oise,32,Hauts-de-France
60298,HANVOILE,60650,HANVOILE,,49.5090035202,1.8809988384,298,,Hanvoile,Hanvoile,60,Oise,32,Hauts-de-France
60299,HARDIVILLERS,60120,HARDIVILLERS,,49.6190429969,2.22471392321,299,,Hardivillers,Hardivillers,60,Oise,32,Hauts-de-France
60300,HARDIVILLERS EN VEXIN,60240,HARDIVILLERS EN VEXIN,,49.3108267732,1.93520324012,300,,Hardivillers-en-Vexin,Hardivillers-en-Vexin,60,Oise,32,Hauts-de-France
60301,HAUCOURT,60112,HAUCOURT,,49.5061935577,1.93107451004,301,,Haucourt,Haucourt,60,Oise,32,Hauts-de-France
60302,HAUDIVILLERS,60510,HAUDIVILLERS,,49.4869439837,2.24134168227,302,,Haudivillers,Haudivillers,60,Oise,32,Hauts-de-France
60303,HAUTBOS,60210,HAUTBOS,,49.6277629796,1.86981129568,303,,Hautbos,Hautbos,60,Oise,32,Hauts-de-France
60304,HAUTE EPINE,60690,HAUTE EPINE,,49.5828440784,2.00667497625,304,,Haute-Épine,Haute-Épine,60,Oise,32,Hauts-de-France
60305,HAUTEFONTAINE,60350,HAUTEFONTAINE,,49.3639288185,3.06265363799,305,,Hautefontaine,Hautefontaine,60,Oise,32,Hauts-de-France
60306,HECOURT,60380,HECOURT,,49.5238037939,1.77954561999,306,,Hécourt,Hécourt,60,Oise,32,Hauts-de-France
60307,HEILLES,60250,HEILLES,,49.3330867142,2.26963680088,307,,Heilles,Heilles,60,Oise,32,Hauts-de-France
60308,HEMEVILLERS,60190,HEMEVILLERS,,49.471836742,2.67275797723,308,,Hémévillers,Hémévillers,60,Oise,32,Hauts-de-France
60309,HENONVILLE,60119,HENONVILLE,,49.2100313417,2.05597443118,309,,Hénonville,Hénonville,60,Oise,32,Hauts-de-France
60310,HERCHIES,60112,HERCHIES,,49.4834141754,2.00297914207,310,,Herchies,Herchies,60,Oise,32,Hauts-de-France
60311,LA HERELLE,60120,LA HERELLE,,49.598162118,2.422095572,311,La,Hérelle,La Hérelle,60,Oise,32,Hauts-de-France
60312,HERICOURT SUR THERAIN,60380,HERICOURT SUR THERAIN,,49.586663908,1.76354974469,312,,Héricourt-sur-Thérain,Héricourt-sur-Thérain,60,Oise,32,Hauts-de-France
60313,HERMES,60370,HERMES,,49.3590736605,2.25601407563,313,,Hermes,Hermes,60,Oise,32,Hauts-de-France
60314,HETOMESNIL,60360,HETOMESNIL,,49.631830901,2.04103130989,314,,Hétomesnil,Hétomesnil,60,Oise,32,Hauts-de-France
60315,HODENC EN BRAY,60650,HODENC EN BRAY,,49.4723304928,1.91021767615,315,,Hodenc-en-Bray,Hodenc-en-Bray,60,Oise,32,Hauts-de-France
60316,HODENC L EVEQUE,60430,HODENC L EVEQUE,,49.3361613976,2.14176639228,316,,Hodenc-l'Évêque,Hodenc-l'Évêque,60,Oise,32,Hauts-de-France
60317,HONDAINVILLE,60250,HONDAINVILLE,,49.344933293,2.30785725358,317,,Hondainville,Hondainville,60,Oise,32,Hauts-de-France
60318,HOUDANCOURT,60710,HOUDANCOURT,,49.3375370465,2.64802307732,318,,Houdancourt,Houdancourt,60,Oise,32,Hauts-de-France
60319,LA HOUSSOYE,60390,LA HOUSSOYE,,49.3541163748,1.9482489871,319,La,Houssoye,La Houssoye,60,Oise,32,Hauts-de-France
60320,IVORS,60141,IVORS,,49.2003314845,3.01881111858,320,,Ivors,Ivors,60,Oise,32,Hauts-de-France
60321,IVRY LE TEMPLE,60173,IVRY LE TEMPLE,,49.2320779777,2.02966880034,321,,Ivry-le-Temple,Ivry-le-Temple,60,Oise,32,Hauts-de-France
60322,JAMERICOURT,60240,JAMERICOURT,,49.3021818872,1.87348567207,322,,Jaméricourt,Jaméricourt,60,Oise,32,Hauts-de-France
60323,JANVILLE,60150,JANVILLE,,49.4539462268,2.85882891775,323,,Janville,Janville,60,Oise,32,Hauts-de-France
60324,JAULZY,60350,JAULZY,,49.3881283326,3.0594833004,324,,Jaulzy,Jaulzy,60,Oise,32,Hauts-de-France
60325,JAUX,60880,JAUX,,49.395906847,2.76678762351,325,,Jaux,Jaux,60,Oise,32,Hauts-de-France
60326,JONQUIERES,60680,JONQUIERES,,49.3993843188,2.73435995446,326,,Jonquières,Jonquières,60,Oise,32,Hauts-de-France
60327,JOUY SOUS THELLE,60240,JOUY SOUS THELLE,,49.3223267421,1.96068066064,327,,Jouy-sous-Thelle,Jouy-sous-Thelle,60,Oise,32,Hauts-de-France
60328,JUVIGNIES,60112,JUVIGNIES,,49.5239940947,2.09077115313,328,,Juvignies,Juvignies,60,Oise,32,Hauts-de-France
60329,LABERLIERE,60310,LABERLIERE,,49.57515956,2.76304639278,329,,Laberlière,Laberlière,60,Oise,32,Hauts-de-France
60330,LABOISSIERE EN THELLE,60570,LABOISSIERE EN THELLE,,49.287058167,2.15238634007,330,,Laboissière-en-Thelle,Laboissière-en-Thelle,60,Oise,32,Hauts-de-France
60331,LABOSSE,60590,LABOSSE,,49.3540781627,1.89832023675,331,,Labosse,Labosse,60,Oise,32,Hauts-de-France
60332,LABRUYERE,60140,LABRUYERE,,49.3527272314,2.50769568365,332,,Labruyère,Labruyère,60,Oise,32,Hauts-de-France
60333,LACHAPELLE AUX POTS,60650,LACHAPELLE AUX POTS,,49.4470304298,1.92181255596,333,,Lachapelle-aux-Pots,Lachapelle-aux-Pots,60,Oise,32,Hauts-de-France
60334,LACHAPELLE ST PIERRE,60730,LACHAPELLE ST PIERRE,,49.2687137764,2.23612056531,334,,Lachapelle-Saint-Pierre,Lachapelle-Saint-Pierre,60,Oise,32,Hauts-de-France
60335,LACHAPELLE SOUS GERBEROY,60380,LACHAPELLE SOUS GERBEROY,,49.5415853366,1.87591010386,335,,Lachapelle-sous-Gerberoy,Lachapelle-sous-Gerberoy,60,Oise,32,Hauts-de-France
60336,LACHAUSSEE DU BOIS D ECU,60480,LACHAUSSEE DU BOIS D ECU,,49.562241218,2.17627773976,336,,Lachaussée-du-Bois-d'Écu,Lachaussée-du-Bois-d'Écu,60,Oise,32,Hauts-de-France
60337,LACHELLE,60190,LACHELLE,,49.434368037,2.74685318222,337,,Lachelle,Lachelle,60,Oise,32,Hauts-de-France
60338,LACROIX ST OUEN,60610,LACROIX ST OUEN,,49.3550551807,2.79513678008,338,,Lacroix-Saint-Ouen,Lacroix-Saint-Ouen,60,Oise,32,Hauts-de-France
60339,LAFRAYE,60510,LAFRAYE,,49.4944510627,2.21159932191,339,,Lafraye,Lafraye,60,Oise,32,Hauts-de-France
60340,LAGNY,60310,LAGNY,,49.6104257346,2.91470679251,340,,Lagny,Lagny,60,Oise,32,Hauts-de-France
60341,LAGNY LE SEC,60330,LAGNY LE SEC,,49.0781168522,2.7487989483,341,,Lagny-le-Sec,Lagny-le-Sec,60,Oise,32,Hauts-de-France
60342,LAIGNEVILLE,60290,LAIGNEVILLE,,49.2973604415,2.43493025435,342,,Laigneville,Laigneville,60,Oise,32,Hauts-de-France
60343,LALANDE EN SON,60590,LALANDE EN SON,,49.3890398972,1.78480094952,343,,Lalande-en-Son,Lalande-en-Son,60,Oise,32,Hauts-de-France
60344,LALANDELLE,60850,LALANDELLE,,49.387187443,1.87215742547,344,,Lalandelle,Lalandelle,60,Oise,32,Hauts-de-France
60345,LAMECOURT,60600,LAMECOURT,,49.4300920723,2.46503272341,345,,Lamécourt,Lamécourt,60,Oise,32,Hauts-de-France
60346,LAMORLAYE,60260,LAMORLAYE,,49.1602771773,2.41972419406,346,,Lamorlaye,Lamorlaye,60,Oise,32,Hauts-de-France
60347,LANNOY CUILLERE,60220,LANNOY CUILLERE,,49.7087683015,1.73577281734,347,,Lannoy-Cuillère,Lannoy-Cuillère,60,Oise,32,Hauts-de-France
60348,LARBROYE,60400,LARBROYE,,49.5711545725,2.96064290936,348,,Larbroye,Larbroye,60,Oise,32,Hauts-de-France
60350,LASSIGNY,60310,LASSIGNY,,49.5999941571,2.84362090335,350,,Lassigny,Lassigny,60,Oise,32,Hauts-de-France
60351,LATAULE,60490,LATAULE,,49.5367201607,2.67457954002,351,,Lataule,Lataule,60,Oise,32,Hauts-de-France
60352,LATTAINVILLE,60240,LATTAINVILLE,,49.2410615022,1.80857359539,352,,Lattainville,Lattainville,60,Oise,32,Hauts-de-France
60353,LAVACQUERIE,60120,LAVACQUERIE,,49.6799986377,2.10079870289,353,,Lavacquerie,Lavacquerie,60,Oise,32,Hauts-de-France
60354,LAVERRIERE,60210,LAVERRIERE,,49.6863142025,2.01097764056,354,,Laverrière,Laverrière,60,Oise,32,Hauts-de-France
60355,LAVERSINES,60510,LAVERSINES,,49.4299134204,2.20087165324,355,,Laversines,Laversines,60,Oise,32,Hauts-de-France
60356,LAVILLETERTRE,60240,LAVILLETERTRE,,49.1905049391,1.9341708115,356,,Lavilletertre,Lavilletertre,60,Oise,32,Hauts-de-France
60357,LEGLANTIERS,60420,LEGLANTIERS,,49.4986729429,2.53214211935,357,,Léglantiers,Léglantiers,60,Oise,32,Hauts-de-France
60358,LEVIGNEN,60800,LEVIGNEN,,49.1970697917,2.91542960657,358,,Lévignen,Lévignen,60,Oise,32,Hauts-de-France
60359,LHERAULE,60650,LHERAULE,,49.4882885044,1.93093330345,359,,Lhéraule,Lhéraule,60,Oise,32,Hauts-de-France
60360,LIANCOURT,60140,LIANCOURT,,49.3300766734,2.46523634953,360,,Liancourt,Liancourt,60,Oise,32,Hauts-de-France
60361,LIANCOURT ST PIERRE,60240,LIANCOURT ST PIERRE,,49.2330864401,1.90635269506,361,,Liancourt-Saint-Pierre,Liancourt-Saint-Pierre,60,Oise,32,Hauts-de-France
60362,LIBERMONT,60640,LIBERMONT,,49.6880319126,2.98026221191,362,,Libermont,Libermont,60,Oise,32,Hauts-de-France
60363,LIERVILLE,60240,LIERVILLE,,49.1973720919,1.89089983426,363,,Lierville,Lierville,60,Oise,32,Hauts-de-France
60364,LIEUVILLERS,60130,LIEUVILLERS,,49.4742826717,2.48980639031,364,,Lieuvillers,Lieuvillers,60,Oise,32,Hauts-de-France
60365,LIHUS,60360,LIHUS,,49.6046293138,2.03925521235,365,,Lihus,Lihus,60,Oise,32,Hauts-de-France
60366,LITZ,60510,LITZ,,49.4262725966,2.32354551734,366,,Litz,Litz,60,Oise,32,Hauts-de-France
60367,LOCONVILLE,60240,LOCONVILLE,,49.2574394193,1.91981496985,367,,Loconville,Loconville,60,Oise,32,Hauts-de-France
60368,LONGUEIL ANNEL,60150,LONGUEIL ANNEL,,49.4667196986,2.85423661921,368,,Longueil-Annel,Longueil-Annel,60,Oise,32,Hauts-de-France
60369,LONGUEIL STE MARIE,60126,LONGUEIL STE MARIE,,49.3400219614,2.71142471159,369,,Longueil-Sainte-Marie,Longueil-Sainte-Marie,60,Oise,32,Hauts-de-France
60370,LORMAISON,60110,LORMAISON,,49.2546108551,2.10422012576,370,,Lormaison,Lormaison,60,Oise,32,Hauts-de-France
60371,LOUEUSE,60380,LOUEUSE,,49.593084675,1.8304724622,371,,Loueuse,Loueuse,60,Oise,32,Hauts-de-France
60372,LUCHY,60360,LUCHY,,49.5519072556,2.11494855936,372,,Luchy,Luchy,60,Oise,32,Hauts-de-France
60373,MACHEMONT,60150,MACHEMONT,,49.5099441905,2.875553005,373,,Machemont,Machemont,60,Oise,32,Hauts-de-France
60374,MAIGNELAY MONTIGNY,60420,MAIGNELAY MONTIGNY,,49.548179093,2.51320633248,374,,Maignelay-Montigny,Maignelay-Montigny,60,Oise,32,Hauts-de-France
60375,MAIMBEVILLE,60600,MAIMBEVILLE,,49.4147776174,2.522051381,375,,Maimbeville,Maimbeville,60,Oise,32,Hauts-de-France
60376,MAISONCELLE ST PIERRE,60112,MAISONCELLE ST PIERRE,,49.5154384614,2.12121694853,376,,Maisoncelle-Saint-Pierre,Maisoncelle-Saint-Pierre,60,Oise,32,Hauts-de-France
60377,MAISONCELLE TUILERIE,60480,MAISONCELLE TUILERIE,,49.5897435077,2.21897451499,377,,Maisoncelle-Tuilerie,Maisoncelle-Tuilerie,60,Oise,32,Hauts-de-France
60378,MAREST SUR MATZ,60490,MAREST SUR MATZ,,49.5033268018,2.82457764723,378,,Marest-sur-Matz,Marest-sur-Matz,60,Oise,32,Hauts-de-France
60379,MAREUIL LA MOTTE,60490,MAREUIL LA MOTTE,,49.5492395195,2.79727471102,379,,Mareuil-la-Motte,Mareuil-la-Motte,60,Oise,32,Hauts-de-France
60380,MAREUIL SUR OURCQ,60890,MAREUIL SUR OURCQ,,49.1392151665,3.07327554548,380,,Mareuil-sur-Ourcq,Mareuil-sur-Ourcq,60,Oise,32,Hauts-de-France
60381,MARGNY AUX CERISES,60310,MARGNY AUX CERISES,,49.6724443709,2.86865853956,381,,Margny-aux-Cerises,Margny-aux-Cerises,60,Oise,32,Hauts-de-France
60382,MARGNY LES COMPIEGNE,60280,MARGNY LES COMPIEGNE,,49.4329968709,2.8054861143,382,,Margny-lès-Compiègne,Margny-lès-Compiègne,60,Oise,32,Hauts-de-France
60383,MARGNY SUR MATZ,60490,MARGNY SUR MATZ,,49.5293175273,2.77915218245,383,,Margny-sur-Matz,Margny-sur-Matz,60,Oise,32,Hauts-de-France
60385,MAROLLES,60890,MAROLLES,,49.1636748962,3.10850778789,385,,Marolles,Marolles,60,Oise,32,Hauts-de-France
60386,MARQUEGLISE,60490,MARQUEGLISE,,49.5158815481,2.7484137141,386,,Marquéglise,Marquéglise,60,Oise,32,Hauts-de-France
60387,MARSEILLE EN BEAUVAISIS,60690,MARSEILLE EN BEAUVAISIS,,49.5753195852,1.95765019879,387,,Marseille-en-Beauvaisis,Marseille-en-Beauvaisis,60,Oise,32,Hauts-de-France
60388,MARTINCOURT,60112,MARTINCOURT,,49.5301768343,1.90447241773,388,,Martincourt,Martincourt,60,Oise,32,Hauts-de-France
60389,MAUCOURT,60640,MAUCOURT,,49.6325787646,3.07814267123,389,,Maucourt,Maucourt,60,Oise,32,Hauts-de-France
60390,MAULERS,60480,MAULERS,,49.5440319929,2.16545655463,390,,Maulers,Maulers,60,Oise,32,Hauts-de-France
60391,MAYSEL,60660,MAYSEL,,49.2519110578,2.36711680855,391,,Maysel,Maysel,60,Oise,32,Hauts-de-France
60392,MELICOCQ,60150,MELICOCQ,,49.489344184,2.85004584455,392,,Mélicocq,Mélicocq,60,Oise,32,Hauts-de-France
60393,MELLO,60660,MELLO,,49.2782636292,2.37545666755,393,,Mello,Mello,60,Oise,32,Hauts-de-France
60394,MENEVILLERS,60420,MENEVILLERS,,49.5212028828,2.60416999172,394,,Ménévillers,Ménévillers,60,Oise,32,Hauts-de-France
60395,MERU,60110,MERU,,49.2453722483,2.13541107599,395,,Méru,Méru,60,Oise,32,Hauts-de-France
60396,MERY LA BATAILLE,60420,MERY LA BATAILLE,,49.5442353409,2.6306106736,396,,Méry-la-Bataille,Méry-la-Bataille,60,Oise,32,Hauts-de-France
60397,LE MESNIL CONTEVILLE,60210,LE MESNIL CONTEVILLE,,49.6678346082,2.0642050499,397,Le,Mesnil-Conteville,Le Mesnil-Conteville,60,Oise,32,Hauts-de-France
60398,LE MESNIL EN THELLE,60530,LE MESNIL EN THELLE,,49.1755288467,2.28195991466,398,Le,Mesnil-en-Thelle,Le Mesnil-en-Thelle,60,Oise,32,Hauts-de-France
60399,LE MESNIL ST FIRMIN,60120,LE MESNIL ST FIRMIN,,49.6259590704,2.41044983882,399,Le,Mesnil-Saint-Firmin,Le Mesnil-Saint-Firmin,60,Oise,32,Hauts-de-France
60400,LE MESNIL SUR BULLES,60130,LE MESNIL SUR BULLES,,49.4823695987,2.34912415685,400,Le,Mesnil-sur-Bulles,Le Mesnil-sur-Bulles,60,Oise,32,Hauts-de-France
60401,LE MESNIL THERIBUS,60240,LE MESNIL THERIBUS,,49.3057295939,1.99122891939,401,Le,Mesnil-Théribus,Le Mesnil-Théribus,60,Oise,32,Hauts-de-France
60402,LE MEUX,60880,LE MEUX,,49.3675243058,2.74517943461,402,Le,Meux,Le Meux,60,Oise,32,Hauts-de-France
60403,MILLY SUR THERAIN,60112,MILLY SUR THERAIN,,49.5090452996,2.01406401611,403,,Milly-sur-Thérain,Milly-sur-Thérain,60,Oise,32,Hauts-de-France
60404,MOGNEVILLE,60140,MOGNEVILLE,,49.3126555491,2.47328622083,404,,Mogneville,Mogneville,60,Oise,32,Hauts-de-France
60405,MOLIENS,60220,MOLIENS,,49.6713332232,1.81534943912,405,,Moliens,Moliens,60,Oise,32,Hauts-de-France
60406,MONCEAUX,60940,MONCEAUX,,49.3269208712,2.56630699286,406,,Monceaux,Monceaux,60,Oise,32,Hauts-de-France
60407,MONCEAUX L ABBAYE,60220,MONCEAUX L ABBAYE,,49.6524929678,1.78839949291,407,,Monceaux-l'Abbaye,Monceaux-l'Abbaye,60,Oise,32,Hauts-de-France
60408,MONCHY HUMIERES,60113,MONCHY HUMIERES,,49.4768018948,2.73831899509,408,,Monchy-Humières,Monchy-Humières,60,Oise,32,Hauts-de-France
60409,MONCHY ST ELOI,60290,MONCHY ST ELOI,,49.2968280054,2.46847273697,409,,Monchy-Saint-Éloi,Monchy-Saint-Éloi,60,Oise,32,Hauts-de-France
60410,MONDESCOURT,60400,MONDESCOURT,,49.6007632459,3.10933333116,410,,Mondescourt,Mondescourt,60,Oise,32,Hauts-de-France
60411,MONNEVILLE,60240,MONNEVILLE,,49.2123743231,1.97064890593,411,,Monneville,Monneville,60,Oise,32,Hauts-de-France
60412,MONTAGNY EN VEXIN,60240,MONTAGNY EN VEXIN,,49.1937559871,1.7963074166,412,,Montagny-en-Vexin,Montagny-en-Vexin,60,Oise,32,Hauts-de-France
60413,MONTAGNY STE FELICITE,60950,MONTAGNY STE FELICITE,,49.1299636301,2.74708435457,413,,Montagny-Sainte-Félicité,Montagny-Sainte-Félicité,60,Oise,32,Hauts-de-France
60414,MONTATAIRE,60160,MONTATAIRE,,49.2632645948,2.43135724334,414,,Montataire,Montataire,60,Oise,32,Hauts-de-France
60415,MONTEPILLOY,60810,MONTEPILLOY,,49.2139185636,2.70932676856,415,,Montépilloy,Montépilloy,60,Oise,32,Hauts-de-France
60416,MONTGERAIN,60420,MONTGERAIN,,49.5386638914,2.58385170113,416,,Montgérain,Montgérain,60,Oise,32,Hauts-de-France
60418,MONTIERS,60190,MONTIERS,,49.5038812733,2.56965274789,418,,Montiers,Montiers,60,Oise,32,Hauts-de-France
60420,MONTJAVOULT,60240,MONTJAVOULT,,49.2167501643,1.79301456897,420,,Montjavoult,Montjavoult,60,Oise,32,Hauts-de-France
60421,MONT L EVEQUE,60300,MONT L EVEQUE,,49.1904259616,2.6339267851,421,,Mont-l'Évêque,Mont-l'Évêque,60,Oise,32,Hauts-de-France
60422,MONTLOGNON,60300,MONTLOGNON,,49.1557531066,2.70658027708,422,,Montlognon,Montlognon,60,Oise,32,Hauts-de-France
60423,MONTMACQ,60150,MONTMACQ,,49.4818105198,2.9149211513,423,,Montmacq,Montmacq,60,Oise,32,Hauts-de-France
60424,MONTMARTIN,60190,MONTMARTIN,,49.4662692846,2.69750550928,424,,Montmartin,Montmartin,60,Oise,32,Hauts-de-France
60425,MONTREUIL SUR BRECHE,60480,MONTREUIL SUR BRECHE,,49.5123717764,2.27407884393,425,,Montreuil-sur-Brêche,Montreuil-sur-Brêche,60,Oise,32,Hauts-de-France
60426,MONTREUIL SUR THERAIN,60134,MONTREUIL SUR THERAIN,,49.3813378813,2.19419096108,426,,Montreuil-sur-Thérain,Montreuil-sur-Thérain,60,Oise,32,Hauts-de-France
60427,MONTS,60119,MONTS,,49.2187416558,2.0008608865,427,,Monts,Monts,60,Oise,32,Hauts-de-France
60428,LE MONT ST ADRIEN,60650,LE MONT ST ADRIEN,,49.4492939211,2.01420488112,428,Le,Mont-Saint-Adrien,Le Mont-Saint-Adrien,60,Oise,32,Hauts-de-France
60429,MORANGLES,60530,MORANGLES,,49.1948903601,2.30542739268,429,,Morangles,Morangles,60,Oise,32,Hauts-de-France
60430,MORIENVAL,60127,MORIENVAL,,49.3104709662,2.93003968872,430,,Morienval,Morienval,60,Oise,32,Hauts-de-France
60431,MORLINCOURT,60400,MORLINCOURT,,49.5687824764,3.041877271,431,,Morlincourt,Morlincourt,60,Oise,32,Hauts-de-France
60432,MORTEFONTAINE,60128,MORTEFONTAINE,,49.1222609821,2.60876023484,432,,Mortefontaine,Mortefontaine,60,Oise,32,Hauts-de-France
60433,MORTEFONTAINE EN THELLE,60570,MORTEFONTAINE EN THELLE,,49.2651041736,2.18912997448,433,,Mortefontaine-en-Thelle,Mortefontaine-en-Thelle,60,Oise,32,Hauts-de-France
60434,MORTEMER,60490,MORTEMER,,49.568875217,2.6728130469,434,,Mortemer,Mortemer,60,Oise,32,Hauts-de-France
60435,MORVILLERS,60380,MORVILLERS,,49.5843809513,1.87218404343,435,,Morvillers,Morvillers,60,Oise,32,Hauts-de-France
60436,MORY MONTCRUX,60120,MORY MONTCRUX,,49.5944185573,2.39746582957,436,,Mory-Montcrux,Mory-Montcrux,60,Oise,32,Hauts-de-France
60437,MOUCHY LE CHATEL,60250,MOUCHY LE CHATEL,,49.3245030423,2.24609957487,437,,Mouchy-le-Châtel,Mouchy-le-Châtel,60,Oise,32,Hauts-de-France
60438,MOULIN SOUS TOUVENT,60350,MOULIN SOUS TOUVENT,,49.4655313245,3.0666237868,438,,Moulin-sous-Touvent,Moulin-sous-Touvent,60,Oise,32,Hauts-de-France
60439,MOUY,60250,MOUY,,49.3172920199,2.29984372967,439,,Mouy,Mouy,60,Oise,32,Hauts-de-France
60440,MOYENNEVILLE,60190,MOYENNEVILLE,,49.486855889,2.63341728898,440,,Moyenneville,Moyenneville,60,Oise,32,Hauts-de-France
60441,MOYVILLERS,60190,MOYVILLERS,,49.4071700438,2.64288403755,441,,Moyvillers,Moyvillers,60,Oise,32,Hauts-de-France
60442,MUIDORGE,60480,MUIDORGE,,49.5330704904,2.13567268039,442,,Muidorge,Muidorge,60,Oise,32,Hauts-de-France
60443,MUIRANCOURT,60640,MUIRANCOURT,,49.6485015114,3.00199557071,443,,Muirancourt,Muirancourt,60,Oise,32,Hauts-de-France
60444,MUREAUMONT,60220,MUREAUMONT,,49.62273366,1.7873657958,444,,Mureaumont,Mureaumont,60,Oise,32,Hauts-de-France
60445,NAMPCEL,60400,NAMPCEL,,49.4918764493,3.09066471681,445,,Nampcel,Nampcel,60,Oise,32,Hauts-de-France
60446,NANTEUIL LE HAUDOUIN,60440,NANTEUIL LE HAUDOUIN,,49.1369195797,2.81079341188,446,,Nanteuil-le-Haudouin,Nanteuil-le-Haudouin,60,Oise,32,Hauts-de-France
60447,NERY,60320,NERY,,49.271310066,2.77130714289,447,,Néry,Néry,60,Oise,32,Hauts-de-France
60448,NEUFCHELLES,60890,NEUFCHELLES,,49.1155773029,3.05195614829,448,,Neufchelles,Neufchelles,60,Oise,32,Hauts-de-France
60449,NEUFVY SUR ARONDE,60190,NEUFVY SUR ARONDE,,49.5092235216,2.65370398093,449,,Neufvy-sur-Aronde,Neufvy-sur-Aronde,60,Oise,32,Hauts-de-France
60450,NEUILLY EN THELLE,60530,NEUILLY EN THELLE,,49.2285502167,2.27982147453,450,,Neuilly-en-Thelle,Neuilly-en-Thelle,60,Oise,32,Hauts-de-France
60451,NEUILLY SOUS CLERMONT,60290,NEUILLY SOUS CLERMONT,,49.3496103814,2.40083374421,451,,Neuilly-sous-Clermont,Neuilly-sous-Clermont,60,Oise,32,Hauts-de-France
60452,NEUVILLE BOSC,60119,NEUVILLE BOSC,,49.2027066455,2.01455667374,452,,Neuville-Bosc,Neuville-Bosc,60,Oise,32,Hauts-de-France
60453,LA NEUVILLE D AUMONT,60790,LA NEUVILLE D AUMONT,,49.3177690805,2.10113160319,453,La,Neuville-d'Aumont,La Neuville-d'Aumont,60,Oise,32,Hauts-de-France
60454,LA NEUVILLE EN HEZ,60510,LA NEUVILLE EN HEZ,,49.3865132805,2.32682912406,454,La,Neuville-en-Hez,La Neuville-en-Hez,60,Oise,32,Hauts-de-France
60455,LA NEUVILLE GARNIER,60390,LA NEUVILLE GARNIER,,49.3324514539,2.04442768207,455,La,Neuville-Garnier,La Neuville-Garnier,60,Oise,32,Hauts-de-France
60456,LA NEUVILLE ROY,60190,LA NEUVILLE ROY,,49.4793462594,2.58212809207,456,La,Neuville-Roy,La Neuville-Roy,60,Oise,32,Hauts-de-France
60457,LA NEUVILLE ST PIERRE,60480,LA NEUVILLE ST PIERRE,,49.5318909884,2.19557228619,457,La,Neuville-Saint-Pierre,La Neuville-Saint-Pierre,60,Oise,32,Hauts-de-France
60458,LA NEUVILLE SUR OUDEUIL,60690,LA NEUVILLE SUR OUDEUIL,,49.5689583194,2.0136506967,458,La,Neuville-sur-Oudeuil,La Neuville-sur-Oudeuil,60,Oise,32,Hauts-de-France
60459,LA NEUVILLE SUR RESSONS,60490,LA NEUVILLE SUR RESSONS,,49.5514944714,2.74513879891,459,La,Neuville-sur-Ressons,La Neuville-sur-Ressons,60,Oise,32,Hauts-de-France
60460,LA NEUVILLE VAULT,60112,LA NEUVILLE VAULT,,49.4890118867,1.96688277958,460,La,Neuville-Vault,La Neuville-Vault,60,Oise,32,Hauts-de-France
60461,NIVILLERS,60510,NIVILLERS,,49.4506700371,2.16728626603,461,,Nivillers,Nivillers,60,Oise,32,Hauts-de-France
60462,NOAILLES,60430,NOAILLES,,49.3185654473,2.19363262342,462,,Noailles,Noailles,60,Oise,32,Hauts-de-France
60463,NOGENT SUR OISE,60180,NOGENT SUR OISE,,49.2761508066,2.46417183632,463,,Nogent-sur-Oise,Nogent-sur-Oise,60,Oise,32,Hauts-de-France
60464,NOINTEL,60840,NOINTEL,,49.3828586322,2.48747358426,464,,Nointel,Nointel,60,Oise,32,Hauts-de-France
60465,NOIREMONT,60480,NOIREMONT,,49.5477774289,2.21412764409,465,,Noirémont,Noirémont,60,Oise,32,Hauts-de-France
60466,NOROY,60130,NOROY,,49.4429608366,2.51040723734,466,,Noroy,Noroy,60,Oise,32,Hauts-de-France
60468,NOURARD LE FRANC,60130,NOURARD LE FRANC,,49.5007862004,2.36368956147,468,,Nourard-le-Franc,Nourard-le-Franc,60,Oise,32,Hauts-de-France
60469,NOVILLERS,60730,NOVILLERS,,49.269638659,2.21663678041,469,,Novillers,Novillers,60,Oise,32,Hauts-de-France
60470,NOYERS ST MARTIN,60480,NOYERS ST MARTIN,,49.548098011,2.26637360937,470,,Noyers-Saint-Martin,Noyers-Saint-Martin,60,Oise,32,Hauts-de-France
60471,NOYON,60400,NOYON,,49.5774343318,3.00799806171,471,,Noyon,Noyon,60,Oise,32,Hauts-de-France
60472,OFFOY,60210,OFFOY,,49.6969048837,2.03849887777,472,,Offoy,Offoy,60,Oise,32,Hauts-de-France
60473,OGNES,60440,OGNES,,49.0992468739,2.82917802889,473,,Ognes,Ognes,60,Oise,32,Hauts-de-France
60474,OGNOLLES,60310,OGNOLLES,,49.6882744078,2.9140817077,474,,Ognolles,Ognolles,60,Oise,32,Hauts-de-France
60475,OGNON,60810,OGNON,,49.2392172647,2.64450386461,475,,Ognon,Ognon,60,Oise,32,Hauts-de-France
60476,OMECOURT,60220,OMECOURT,,49.6166743846,1.83402395723,476,,Omécourt,Omécourt,60,Oise,32,Hauts-de-France
60477,ONS EN BRAY,60650,ONS EN BRAY,,49.4170822922,1.91904543496,477,,Ons-en-Bray,Ons-en-Bray,60,Oise,32,Hauts-de-France
60478,ORMOY LE DAVIEN,60620,ORMOY LE DAVIEN,,49.1960845952,2.95791288835,478,,Ormoy-le-Davien,Ormoy-le-Davien,60,Oise,32,Hauts-de-France
60479,ORMOY VILLERS,60800,ORMOY VILLERS,,49.1972207576,2.84690572589,479,,Ormoy-Villers,Ormoy-Villers,60,Oise,32,Hauts-de-France
60480,OROER,60510,OROER,,49.4964951058,2.18031432031,480,,Oroër,Oroër,60,Oise,32,Hauts-de-France
60481,ORROUY,60129,ORROUY,,49.3071238607,2.85809275109,481,,Orrouy,Orrouy,60,Oise,32,Hauts-de-France
60482,ORRY LA VILLE,60560,ORRY LA VILLE,,49.140934755,2.50855732387,482,,Orry-la-Ville,Orry-la-Ville,60,Oise,32,Hauts-de-France
60483,ORVILLERS SOREL,60490,ORVILLERS SOREL,,49.5744800615,2.71031705668,483,,Orvillers-Sorel,Orvillers-Sorel,60,Oise,32,Hauts-de-France
60484,OUDEUIL,60860,OUDEUIL,,49.5491561309,2.02722725517,484,,Oudeuil,Oudeuil,60,Oise,32,Hauts-de-France
60485,OURSEL MAISON,60480,OURSEL MAISON,,49.6022006095,2.17775833249,485,,Oursel-Maison,Oursel-Maison,60,Oise,32,Hauts-de-France
60486,PAILLART,60120,PAILLART,,49.6651337935,2.3206181648,486,,Paillart,Paillart,60,Oise,32,Hauts-de-France
60487,PARNES,60240,PARNES,,49.2000876864,1.74905447896,487,,Parnes,Parnes,60,Oise,32,Hauts-de-France
60488,PASSEL,60400,PASSEL,,49.5562386806,2.96570215096,488,,Passel,Passel,60,Oise,32,Hauts-de-France
60489,PEROY LES GOMBRIES,60440,PEROY LES GOMBRIES,,49.1656749464,2.84320631548,489,,Péroy-les-Gombries,Péroy-les-Gombries,60,Oise,32,Hauts-de-France
60490,PIERREFITTE EN BEAUVAISIS,60112,PIERREFITTE EN BEAUVAISIS,,49.4736179476,1.97936624141,490,,Pierrefitte-en-Beauvaisis,Pierrefitte-en-Beauvaisis,60,Oise,32,Hauts-de-France
60491,PIERREFONDS,60350,PIERREFONDS,,49.348002803,2.96575966362,491,,Pierrefonds,Pierrefonds,60,Oise,32,Hauts-de-France
60492,PIMPREZ,60170,PIMPREZ,,49.5123733256,2.95178055109,492,,Pimprez,Pimprez,60,Oise,32,Hauts-de-France
60493,PISSELEU,60860,PISSELEU,,49.5378600786,2.05641774458,493,,Pisseleu,Pisseleu,60,Oise,32,Hauts-de-France
60494,PLAILLY,60128,PLAILLY,,49.1113621574,2.57767746026,494,,Plailly,Plailly,60,Oise,32,Hauts-de-France
60495,PLAINVAL,60130,PLAINVAL,,49.5340616359,2.45773884627,495,,Plainval,Plainval,60,Oise,32,Hauts-de-France
60496,PLAINVILLE,60120,PLAINVILLE,,49.6129358636,2.44994653858,496,,Plainville,Plainville,60,Oise,32,Hauts-de-France
60497,LE PLESSIER SUR BULLES,60130,LE PLESSIER SUR BULLES,,49.4924154529,2.31813572468,497,Le,Plessier-sur-Bulles,Le Plessier-sur-Bulles,60,Oise,32,Hauts-de-France
60498,LE PLESSIER SUR ST JUST,60130,LE PLESSIER SUR ST JUST,,49.5081515622,2.4596143307,498,Le,Plessier-sur-Saint-Just,Le Plessier-sur-Saint-Just,60,Oise,32,Hauts-de-France
60499,PLESSIS DE ROYE,60310,PLESSIS DE ROYE,,49.5715853896,2.82849127947,499,,Plessis-de-Roye,Plessis-de-Roye,60,Oise,32,Hauts-de-France
60500,LE PLESSIS BELLEVILLE,60330,LE PLESSIS BELLEVILLE,,49.0985806649,2.75896019527,500,Le,Plessis-Belleville,Le Plessis-Belleville,60,Oise,32,Hauts-de-France
60501,LE PLESSIS BRION,60150,LE PLESSIS BRION,,49.4622196223,2.9084704643,501,Le,Plessis-Brion,Le Plessis-Brion,60,Oise,32,Hauts-de-France
60502,LE PLESSIS PATTE D OIE,60640,LE PLESSIS PATTE D OIE,,49.6838619137,3.06584033346,502,Le,Plessis-Patte-d'Oie,Le Plessis-Patte-d'Oie,60,Oise,32,Hauts-de-France
60503,LE PLOYRON,60420,LE PLOYRON,,49.5863727907,2.58183714891,503,Le,Ployron,Le Ployron,60,Oise,32,Hauts-de-France
60504,PONCHON,60430,PONCHON,,49.3480922542,2.18400439052,504,,Ponchon,Ponchon,60,Oise,32,Hauts-de-France
60505,PONTARME,60520,PONTARME,,49.1652249379,2.55752249629,505,,Pontarmé,Pontarmé,60,Oise,32,Hauts-de-France
60506,PONT L EVEQUE,60400,PONT L EVEQUE,,49.5627550625,2.98667327051,506,,Pont-l'Évêque,Pont-l'Évêque,60,Oise,32,Hauts-de-France
60507,PONTOISE LES NOYON,60400,PONTOISE LES NOYON,,49.5434848302,3.04751072551,507,,Pontoise-lès-Noyon,Pontoise-lès-Noyon,60,Oise,32,Hauts-de-France
60508,PONTPOINT,60700,PONTPOINT,,49.3004968016,2.64847829127,508,,Pontpoint,Pontpoint,60,Oise,32,Hauts-de-France
60509,PONT STE MAXENCE,60700,PONT STE MAXENCE,,49.3049711726,2.60798085563,509,,Pont-Sainte-Maxence,Pont-Sainte-Maxence,60,Oise,32,Hauts-de-France
60510,PORCHEUX,60390,PORCHEUX,,49.3365218243,1.92395971009,510,,Porcheux,Porcheux,60,Oise,32,Hauts-de-France
60511,PORQUERICOURT,60400,PORQUERICOURT,,49.5968822317,2.95464137145,511,,Porquéricourt,Porquéricourt,60,Oise,32,Hauts-de-France
60512,POUILLY,60790,POUILLY,,49.2756110754,2.03153859002,512,,Pouilly,Pouilly,60,Oise,32,Hauts-de-France
60513,PRECY SUR OISE,60460,PRECY SUR OISE,,49.2033673942,2.36214384754,513,,Précy-sur-Oise,Précy-sur-Oise,60,Oise,32,Hauts-de-France
60514,PREVILLERS,60360,PREVILLERS,,49.6174288468,1.99964588625,514,,Prévillers,Prévillers,60,Oise,32,Hauts-de-France
60515,PRONLEROY,60190,PRONLEROY,,49.4684053367,2.53983717529,515,,Pronleroy,Pronleroy,60,Oise,32,Hauts-de-France
60516,PUISEUX EN BRAY,60850,PUISEUX EN BRAY,,49.4107616918,1.77846343904,516,,Puiseux-en-Bray,Puiseux-en-Bray,60,Oise,32,Hauts-de-France
60517,PUISEUX LE HAUBERGER,60540,PUISEUX LE HAUBERGER,,49.2180286454,2.23133735339,517,,Puiseux-le-Hauberger,Puiseux-le-Hauberger,60,Oise,32,Hauts-de-France
60518,PUITS LA VALLEE,60480,PUITS LA VALLEE,,49.5836339442,2.19003543051,518,,Puits-la-Vallée,Puits-la-Vallée,60,Oise,32,Hauts-de-France
60519,QUESMY,60640,QUESMY,,49.6320835228,3.05496542232,519,,Quesmy,Quesmy,60,Oise,32,Hauts-de-France
60520,LE QUESNEL AUBRY,60480,LE QUESNEL AUBRY,,49.5099695752,2.31447331085,520,Le,Quesnel-Aubry,Le Quesnel-Aubry,60,Oise,32,Hauts-de-France
60521,QUINCAMPOIX FLEUZY,60220,QUINCAMPOIX FLEUZY,,49.7455416962,1.76896943429,521,,Quincampoix-Fleuzy,Quincampoix-Fleuzy,60,Oise,32,Hauts-de-France
60522,QUINQUEMPOIX,60130,QUINQUEMPOIX,,49.5472914802,2.41866842622,522,,Quinquempoix,Quinquempoix,60,Oise,32,Hauts-de-France
60523,RAINVILLERS,60155,RAINVILLERS,,49.4070517504,1.99835502155,523,,Rainvillers,Rainvillers,60,Oise,32,Hauts-de-France
60524,RANTIGNY,60290,RANTIGNY,,49.3338498991,2.43555395564,524,,Rantigny,Rantigny,60,Oise,32,Hauts-de-France
60525,RARAY,60810,RARAY,,49.263440205,2.71969043321,525,,Raray,Raray,60,Oise,32,Hauts-de-France
60526,RAVENEL,60130,RAVENEL,,49.5143827967,2.49905019788,526,,Ravenel,Ravenel,60,Oise,32,Hauts-de-France
60527,REEZ FOSSE MARTIN,60620,REEZ FOSSE MARTIN,,49.0912758357,2.92362494577,527,,Réez-Fosse-Martin,Réez-Fosse-Martin,60,Oise,32,Hauts-de-France
60528,REILLY,60240,REILLY,,49.240746359,1.85701777759,528,,Reilly,Reilly,60,Oise,32,Hauts-de-France
60529,REMECOURT,60600,REMECOURT,,49.4300556144,2.49241906873,529,,Rémécourt,Rémécourt,60,Oise,32,Hauts-de-France
60530,REMERANGLES,60510,REMERANGLES,,49.4481992219,2.29023906853,530,,Rémérangles,Rémérangles,60,Oise,32,Hauts-de-France
60531,REMY,60190,REMY,,49.4346894863,2.70312726692,531,,Remy,Remy,60,Oise,32,Hauts-de-France
60532,RESSONS L ABBAYE,60790,RESSONS L ABBAYE,,49.2955871011,2.0863202618,532,,Ressons-l'Abbaye,Ressons-l'Abbaye,60,Oise,32,Hauts-de-France
60533,RESSONS SUR MATZ,60490,RESSONS SUR MATZ,,49.5337304815,2.73255438737,533,,Ressons-sur-Matz,Ressons-sur-Matz,60,Oise,32,Hauts-de-France
60534,RETHONDES,60153,RETHONDES,,49.4289952434,2.94460226209,534,,Rethondes,Rethondes,60,Oise,32,Hauts-de-France
60535,REUIL SUR BRECHE,60480,REUIL SUR BRECHE,,49.5218600846,2.226027175,535,,Reuil-sur-Brêche,Reuil-sur-Brêche,60,Oise,32,Hauts-de-France
60536,RHUIS,60410,RHUIS,,49.3044719658,2.69787230375,536,,Rhuis,Rhuis,60,Oise,32,Hauts-de-France
60537,RIBECOURT DRESLINCOURT,60170,RIBECOURT DRESLINCOURT,,49.5224343786,2.91876179627,537,,Ribécourt-Dreslincourt,Ribécourt-Dreslincourt,60,Oise,32,Hauts-de-France
60537,RIBECOURT DRESLINCOURT,60170,RIBECOURT DRESLINCOURT,DRESLINCOURT,49.5224343786,2.91876179627,537,,Ribécourt-Dreslincourt,Ribécourt-Dreslincourt,60,Oise,32,Hauts-de-France
60538,RICQUEBOURG,60490,RICQUEBOURG,,49.5606808344,2.75829251841,538,,Ricquebourg,Ricquebourg,60,Oise,32,Hauts-de-France
60539,RIEUX,60870,RIEUX,,49.3000073439,2.51252938174,539,,Rieux,Rieux,60,Oise,32,Hauts-de-France
60540,RIVECOURT,60126,RIVECOURT,,49.3462815732,2.73842909155,540,,Rivecourt,Rivecourt,60,Oise,32,Hauts-de-France
60541,ROBERVAL,60410,ROBERVAL,,49.2911584185,2.68497553909,541,,Roberval,Roberval,60,Oise,32,Hauts-de-France
60542,ROCHY CONDE,60510,ROCHY CONDE,,49.4041536099,2.1887872346,542,,Rochy-Condé,Rochy-Condé,60,Oise,32,Hauts-de-France
60543,ROCQUEMONT,60800,ROCQUEMONT,,49.2596362011,2.81990784544,543,,Rocquemont,Rocquemont,60,Oise,32,Hauts-de-France
60544,ROCQUENCOURT,60120,ROCQUENCOURT,,49.6482426702,2.410098489,544,,Rocquencourt,Rocquencourt,60,Oise,32,Hauts-de-France
60545,ROMESCAMPS,60220,ROMESCAMPS,,49.712188183,1.80039781774,545,,Romescamps,Romescamps,60,Oise,32,Hauts-de-France
60546,ROSIERES,60440,ROSIERES,,49.186539903,2.781014217,546,,Rosières,Rosières,60,Oise,32,Hauts-de-France
60547,ROSOY,60140,ROSOY,,49.3392916564,2.50928278389,547,,Rosoy,Rosoy,60,Oise,32,Hauts-de-France
60548,ROSOY EN MULTIEN,60620,ROSOY EN MULTIEN,,49.0955522989,2.98960846382,548,,Rosoy-en-Multien,Rosoy-en-Multien,60,Oise,32,Hauts-de-France
60549,ROTANGY,60360,ROTANGY,,49.5850016727,2.08836287061,549,,Rotangy,Rotangy,60,Oise,32,Hauts-de-France
60550,ROTHOIS,60690,ROTHOIS,,49.5981184511,1.98978913272,550,,Rothois,Rothois,60,Oise,32,Hauts-de-France
60551,ROUSSELOY,60660,ROUSSELOY,,49.2984297084,2.39261860558,551,,Rousseloy,Rousseloy,60,Oise,32,Hauts-de-France
60552,ROUVILLE,60800,ROUVILLE,,49.2101065424,2.88059214006,552,,Rouville,Rouville,60,Oise,32,Hauts-de-France
60553,ROUVILLERS,60190,ROUVILLERS,,49.4557611545,2.62988775379,553,,Rouvillers,Rouvillers,60,Oise,32,Hauts-de-France
60554,ROUVRES EN MULTIEN,60620,ROUVRES EN MULTIEN,,49.1065269014,3.02241048345,554,,Rouvres-en-Multien,Rouvres-en-Multien,60,Oise,32,Hauts-de-France
60555,ROUVROY LES MERLES,60120,ROUVROY LES MERLES,,49.6509731175,2.3591696972,555,,Rouvroy-les-Merles,Rouvroy-les-Merles,60,Oise,32,Hauts-de-France
60556,ROYAUCOURT,60420,ROYAUCOURT,,49.6124825658,2.52773304536,556,,Royaucourt,Royaucourt,60,Oise,32,Hauts-de-France
60557,ROY BOISSY,60690,ROY BOISSY,,49.5802763443,1.91999157455,557,,Roy-Boissy,Roy-Boissy,60,Oise,32,Hauts-de-France
60558,ROYE SUR MATZ,60310,ROYE SUR MATZ,,49.5936160984,2.7709194212,558,,Roye-sur-Matz,Roye-sur-Matz,60,Oise,32,Hauts-de-France
60559,LA RUE ST PIERRE,60510,LA RUE ST PIERRE,,49.414002576,2.29121659235,559,La,Rue-Saint-Pierre,La Rue-Saint-Pierre,60,Oise,32,Hauts-de-France
60560,RULLY,60810,RULLY,,49.2390399273,2.7248899775,560,,Rully,Rully,60,Oise,32,Hauts-de-France
60561,RUSSY BEMONT,60117,RUSSY BEMONT,,49.249762849,2.95637817553,561,,Russy-Bémont,Russy-Bémont,60,Oise,32,Hauts-de-France
60562,SACY LE GRAND,60700,SACY LE GRAND,,49.3567536222,2.55499209997,562,,Sacy-le-Grand,Sacy-le-Grand,60,Oise,32,Hauts-de-France
60563,SACY LE PETIT,60190,SACY LE PETIT,,49.3698949265,2.62778300608,563,,Sacy-le-Petit,Sacy-le-Petit,60,Oise,32,Hauts-de-France
60564,SAINS MORAINVILLERS,60420,SAINS MORAINVILLERS,,49.5784892479,2.46396837971,564,,Sains-Morainvillers,Sains-Morainvillers,60,Oise,32,Hauts-de-France
60565,ST ANDRE FARIVILLERS,60480,ST ANDRE FARIVILLERS,,49.5820586693,2.30062161495,565,,Saint-André-Farivillers,Saint-André-Farivillers,60,Oise,32,Hauts-de-France
60566,ST ARNOULT,60220,ST ARNOULT,,49.636114034,1.80591000401,566,,Saint-Arnoult,Saint-Arnoult,60,Oise,32,Hauts-de-France
60567,ST AUBIN EN BRAY,60650,ST AUBIN EN BRAY,,49.4256177121,1.88925202038,567,,Saint-Aubin-en-Bray,Saint-Aubin-en-Bray,60,Oise,32,Hauts-de-France
60567,ST AUBIN EN BRAY,60650,ST AUBIN EN BRAY,LES FONTAINETTES,49.4256177121,1.88925202038,567,,Saint-Aubin-en-Bray,Saint-Aubin-en-Bray,60,Oise,32,Hauts-de-France
60568,ST AUBIN SOUS ERQUERY,60600,ST AUBIN SOUS ERQUERY,,49.4123694145,2.49005189932,568,,Saint-Aubin-sous-Erquery,Saint-Aubin-sous-Erquery,60,Oise,32,Hauts-de-France
60569,ST CREPIN AUX BOIS,60170,ST CREPIN AUX BOIS,,49.4447949689,2.9848806612,569,,Saint-Crépin-aux-Bois,Saint-Crépin-aux-Bois,60,Oise,32,Hauts-de-France
60570,ST CREPIN IBOUVILLERS,60149,ST CREPIN IBOUVILLERS,,49.2592528645,2.06773136457,570,,Saint-Crépin-Ibouvillers,Saint-Crépin-Ibouvillers,60,Oise,32,Hauts-de-France
60570,ST CREPIN IBOUVILLERS,60790,ST CREPIN IBOUVILLERS,MONTHERLANT,49.2592528645,2.06773136457,570,,Saint-Crépin-Ibouvillers,Saint-Crépin-Ibouvillers,60,Oise,32,Hauts-de-France
60571,ST DENISCOURT,60380,ST DENISCOURT,,49.6064297043,1.86417879893,571,,Saint-Deniscourt,Saint-Deniscourt,60,Oise,32,Hauts-de-France
60572,ST ETIENNE ROILAYE,60350,ST ETIENNE ROILAYE,,49.3604965921,3.0112130917,572,,Saint-Étienne-Roilaye,Saint-Étienne-Roilaye,60,Oise,32,Hauts-de-France
60573,STE EUSOYE,60480,STE EUSOYE,,49.5752040683,2.25015619286,573,,Sainte-Eusoye,Sainte-Eusoye,60,Oise,32,Hauts-de-France
60574,ST FELIX,60370,ST FELIX,,49.3548770591,2.28412904405,574,,Saint-Félix,Saint-Félix,60,Oise,32,Hauts-de-France
60575,STE GENEVIEVE,60730,STE GENEVIEVE,,49.2889931714,2.19808421262,575,,Sainte-Geneviève,Sainte-Geneviève,60,Oise,32,Hauts-de-France
60576,ST GERMAIN LA POTERIE,60650,ST GERMAIN LA POTERIE,,49.4423808139,1.971867466,576,,Saint-Germain-la-Poterie,Saint-Germain-la-Poterie,60,Oise,32,Hauts-de-France
60577,ST GERMER DE FLY,60850,ST GERMER DE FLY,,49.441635095,1.7819147282,577,,Saint-Germer-de-Fly,Saint-Germer-de-Fly,60,Oise,32,Hauts-de-France
60578,SAINTINES,60410,SAINTINES,,49.3020445014,2.76780194989,578,,Saintines,Saintines,60,Oise,32,Hauts-de-France
60579,ST JEAN AUX BOIS,60350,ST JEAN AUX BOIS,,49.3501826789,2.88709409437,579,,Saint-Jean-aux-Bois,Saint-Jean-aux-Bois,60,Oise,32,Hauts-de-France
60581,ST JUST EN CHAUSSEE,60130,ST JUST EN CHAUSSEE,,49.5112063236,2.41780624403,581,,Saint-Just-en-Chaussée,Saint-Just-en-Chaussée,60,Oise,32,Hauts-de-France
60582,ST LEGER AUX BOIS,60170,ST LEGER AUX BOIS,,49.4782276386,2.95219747326,582,,Saint-Léger-aux-Bois,Saint-Léger-aux-Bois,60,Oise,32,Hauts-de-France
60583,ST LEGER EN BRAY,60155,ST LEGER EN BRAY,,49.3882002284,2.02635842771,583,,Saint-Léger-en-Bray,Saint-Léger-en-Bray,60,Oise,32,Hauts-de-France
60584,ST LEU D ESSERENT,60340,ST LEU D ESSERENT,,49.2286214126,2.40910625002,584,,Saint-Leu-d'Esserent,Saint-Leu-d'Esserent,60,Oise,32,Hauts-de-France
60585,ST MARTIN AUX BOIS,60420,ST MARTIN AUX BOIS,,49.5250033186,2.56003918662,585,,Saint-Martin-aux-Bois,Saint-Martin-aux-Bois,60,Oise,32,Hauts-de-France
60586,ST MARTIN LE NOEUD,60000,ST MARTIN LE NOEUD,,49.3957799829,2.05937901861,586,,Saint-Martin-le-Nœud,Saint-Martin-le-Nœud,60,Oise,32,Hauts-de-France
60587,ST MARTIN LONGUEAU,60700,ST MARTIN LONGUEAU,,49.3474706445,2.59996403152,587,,Saint-Martin-Longueau,Saint-Martin-Longueau,60,Oise,32,Hauts-de-France
60588,ST MAUR,60210,ST MAUR,,49.6166715837,1.92159084424,588,,Saint-Maur,Saint-Maur,60,Oise,32,Hauts-de-France
60589,ST MAXIMIN,60740,ST MAXIMIN,,49.224445885,2.45930498428,589,,Saint-Maximin,Saint-Maximin,60,Oise,32,Hauts-de-France
60590,ST OMER EN CHAUSSEE,60860,ST OMER EN CHAUSSEE,,49.5333363606,2.00249119554,590,,Saint-Omer-en-Chaussée,Saint-Omer-en-Chaussée,60,Oise,32,Hauts-de-France
60591,ST PAUL,60650,ST PAUL,,49.4277052907,1.98675158982,591,,Saint-Paul,Saint-Paul,60,Oise,32,Hauts-de-France
60592,ST PIERRE ES CHAMPS,60850,ST PIERRE ES CHAMPS,,49.4227812737,1.74067082162,592,,Saint-Pierre-es-Champs,Saint-Pierre-es-Champs,60,Oise,32,Hauts-de-France
60593,ST PIERRE LES BITRY,60350,ST PIERRE LES BITRY,,49.4314595152,3.08507355061,593,,Saint-Pierre-lès-Bitry,Saint-Pierre-lès-Bitry,60,Oise,32,Hauts-de-France
60594,ST QUENTIN DES PRES,60380,ST QUENTIN DES PRES,,49.5201563268,1.7479039158,594,,Saint-Quentin-des-Prés,Saint-Quentin-des-Prés,60,Oise,32,Hauts-de-France
60595,ST REMY EN L EAU,60130,ST REMY EN L EAU,,49.4703008041,2.42414502443,595,,Saint-Remy-en-l'Eau,Saint-Remy-en-l'Eau,60,Oise,32,Hauts-de-France
60596,ST SAMSON LA POTERIE,60220,ST SAMSON LA POTERIE,,49.5932820021,1.73960582864,596,,Saint-Samson-la-Poterie,Saint-Samson-la-Poterie,60,Oise,32,Hauts-de-France
60597,ST SAUVEUR,60320,ST SAUVEUR,,49.3285204781,2.80616373785,597,,Saint-Sauveur,Saint-Sauveur,60,Oise,32,Hauts-de-France
60598,ST SULPICE,60430,ST SULPICE,,49.3492908609,2.12271497289,598,,Saint-Sulpice,Saint-Sulpice,60,Oise,32,Hauts-de-France
60599,ST THIBAULT,60210,ST THIBAULT,,49.6940947082,1.82443104222,599,,Saint-Thibault,Saint-Thibault,60,Oise,32,Hauts-de-France
60600,ST VAAST DE LONGMONT,60410,ST VAAST DE LONGMONT,,49.2964793242,2.74465320657,600,,Saint-Vaast-de-Longmont,Saint-Vaast-de-Longmont,60,Oise,32,Hauts-de-France
60601,ST VAAST LES MELLO,60660,ST VAAST LES MELLO,,49.2767877481,2.39991208976,601,,Saint-Vaast-lès-Mello,Saint-Vaast-lès-Mello,60,Oise,32,Hauts-de-France
60602,ST VALERY,60220,ST VALERY,,49.7249107248,1.72911335468,602,,Saint-Valery,Saint-Valery,60,Oise,32,Hauts-de-France
60603,SALENCY,60400,SALENCY,,49.5876754737,3.04632508396,603,,Salency,Salency,60,Oise,32,Hauts-de-France
60604,SARCUS,60210,SARCUS,,49.6808539591,1.87577061583,604,,Sarcus,Sarcus,60,Oise,32,Hauts-de-France
60605,SARNOIS,60210,SARNOIS,,49.680399425,1.92482666497,605,,Sarnois,Sarnois,60,Oise,32,Hauts-de-France
60608,LE SAULCHOY,60360,LE SAULCHOY,,49.6307458971,2.13635146653,608,Le,Saulchoy,Le Saulchoy,60,Oise,32,Hauts-de-France
60609,SAVIGNIES,60650,SAVIGNIES,,49.4627452726,1.95763828893,609,,Savignies,Savignies,60,Oise,32,Hauts-de-France
60610,SEMPIGNY,60400,SEMPIGNY,,49.5526800369,3.00054707507,610,,Sempigny,Sempigny,60,Oise,32,Hauts-de-France
60611,SENANTES,60650,SENANTES,,49.4824791427,1.8336835055,611,,Senantes,Senantes,60,Oise,32,Hauts-de-France
60612,SENLIS,60300,SENLIS,,49.2118455897,2.58570626014,612,,Senlis,Senlis,60,Oise,32,Hauts-de-France
60613,SENOTS,60240,SENOTS,,49.2603954952,2.01352308101,613,,Senots,Senots,60,Oise,32,Hauts-de-France
60614,SERANS,60240,SERANS,,49.1909346161,1.82734336167,614,,Serans,Serans,60,Oise,32,Hauts-de-France
60615,SEREVILLERS,60120,SEREVILLERS,,49.6352843192,2.43516839817,615,,Sérévillers,Sérévillers,60,Oise,32,Hauts-de-France
60616,SERIFONTAINE,60590,SERIFONTAINE,,49.3615071345,1.79376324721,616,,Sérifontaine,Sérifontaine,60,Oise,32,Hauts-de-France
60617,SERMAIZE,60400,SERMAIZE,,49.6168772777,2.95323666872,617,,Sermaize,Sermaize,60,Oise,32,Hauts-de-France
60618,SERY MAGNEVAL,60800,SERY MAGNEVAL,,49.2584848304,2.86028691199,618,,Séry-Magneval,Séry-Magneval,60,Oise,32,Hauts-de-France
60619,SILLY LE LONG,60330,SILLY LE LONG,,49.1084845139,2.78729008831,619,,Silly-le-Long,Silly-le-Long,60,Oise,32,Hauts-de-France
60620,SILLY TILLARD,60430,SILLY TILLARD,,49.3175765383,2.15326097758,620,,Silly-Tillard,Silly-Tillard,60,Oise,32,Hauts-de-France
60621,SOLENTE,60310,SOLENTE,,49.6978592083,2.88427490672,621,,Solente,Solente,60,Oise,32,Hauts-de-France
60622,SOMMEREUX,60210,SOMMEREUX,,49.6749063836,2.00347221953,622,,Sommereux,Sommereux,60,Oise,32,Hauts-de-France
60623,SONGEONS,60380,SONGEONS,,49.5626808865,1.85253851049,623,,Songeons,Songeons,60,Oise,32,Hauts-de-France
60624,SULLY,60380,SULLY,,49.5582968682,1.77942067528,624,,Sully,Sully,60,Oise,32,Hauts-de-France
60625,SUZOY,60400,SUZOY,,49.582856711,2.93918164919,625,,Suzoy,Suzoy,60,Oise,32,Hauts-de-France
60626,TALMONTIERS,60590,TALMONTIERS,,49.3892320184,1.75051136418,626,,Talmontiers,Talmontiers,60,Oise,32,Hauts-de-France
60627,TARTIGNY,60120,TARTIGNY,,49.6347175976,2.36247535718,627,,Tartigny,Tartigny,60,Oise,32,Hauts-de-France
60628,THERDONNE,60510,THERDONNE,,49.4231387844,2.15300884654,628,,Therdonne,Therdonne,60,Oise,32,Hauts-de-France
60629,THERINES,60380,THERINES,,49.6034358445,1.8937457326,629,,Thérines,Thérines,60,Oise,32,Hauts-de-France
60630,THIBIVILLERS,60240,THIBIVILLERS,,49.3092311857,1.90187779485,630,,Thibivillers,Thibivillers,60,Oise,32,Hauts-de-France
60631,THIERS SUR THEVE,60520,THIERS SUR THEVE,,49.1536648574,2.57819751248,631,,Thiers-sur-Thève,Thiers-sur-Thève,60,Oise,32,Hauts-de-France
60632,THIESCOURT,60310,THIESCOURT,,49.5596026493,2.86659655683,632,,Thiescourt,Thiescourt,60,Oise,32,Hauts-de-France
60633,THIEULOY ST ANTOINE,60210,THIEULOY ST ANTOINE,,49.6347803075,1.94612857854,633,,Thieuloy-Saint-Antoine,Thieuloy-Saint-Antoine,60,Oise,32,Hauts-de-France
60634,THIEUX,60480,THIEUX,,49.5437985165,2.30999977831,634,,Thieux,Thieux,60,Oise,32,Hauts-de-France
60635,THIVERNY,60160,THIVERNY,,49.245997329,2.42786495364,635,,Thiverny,Thiverny,60,Oise,32,Hauts-de-France
60636,THOUROTTE,60150,THOUROTTE,,49.4793368724,2.88124505032,636,,Thourotte,Thourotte,60,Oise,32,Hauts-de-France
60637,THURY EN VALOIS,60890,THURY EN VALOIS,,49.1516839082,3.02876069607,637,,Thury-en-Valois,Thury-en-Valois,60,Oise,32,Hauts-de-France
60638,THURY SOUS CLERMONT,60250,THURY SOUS CLERMONT,,49.3584817285,2.32859309927,638,,Thury-sous-Clermont,Thury-sous-Clermont,60,Oise,32,Hauts-de-France
60639,TILLE,60000,TILLE,,49.4644718487,2.11782914141,639,,Tillé,Tillé,60,Oise,32,Hauts-de-France
60640,TOURLY,60240,TOURLY,,49.2202049953,1.94208573805,640,,Tourly,Tourly,60,Oise,32,Hauts-de-France
60641,TRACY LE MONT,60170,TRACY LE MONT,,49.469047669,3.00367907538,641,,Tracy-le-Mont,Tracy-le-Mont,60,Oise,32,Hauts-de-France
60641,TRACY LE MONT,60170,TRACY LE MONT,OLLENCOURT,49.469047669,3.00367907538,641,,Tracy-le-Mont,Tracy-le-Mont,60,Oise,32,Hauts-de-France
60642,TRACY LE VAL,60170,TRACY LE VAL,,49.4907973534,3.01394376369,642,,Tracy-le-Val,Tracy-le-Val,60,Oise,32,Hauts-de-France
60643,TRICOT,60420,TRICOT,,49.5631454489,2.58460217686,643,,Tricot,Tricot,60,Oise,32,Hauts-de-France
60644,TRIE CHATEAU,60590,TRIE CHATEAU,,49.2868018979,1.81392681407,644,,Trie-Château,Trie-Château,60,Oise,32,Hauts-de-France
60645,TRIE LA VILLE,60590,TRIE LA VILLE,,49.2858970695,1.84203331226,645,,Trie-la-Ville,Trie-la-Ville,60,Oise,32,Hauts-de-France
60646,TROISSEREUX,60112,TROISSEREUX,,49.4839349109,2.05750411907,646,,Troissereux,Troissereux,60,Oise,32,Hauts-de-France
60647,TROSLY BREUIL,60350,TROSLY BREUIL,,49.3995937676,2.96594779621,647,,Trosly-Breuil,Trosly-Breuil,60,Oise,32,Hauts-de-France
60648,TROUSSENCOURT,60120,TROUSSENCOURT,,49.6035801576,2.24854174337,648,,Troussencourt,Troussencourt,60,Oise,32,Hauts-de-France
60649,TROUSSURES,60390,TROUSSURES,,49.3821112605,1.9561782833,649,,Troussures,Troussures,60,Oise,32,Hauts-de-France
60650,TRUMILLY,60800,TRUMILLY,,49.2396223945,2.78712488353,650,,Trumilly,Trumilly,60,Oise,32,Hauts-de-France
60651,ULLY ST GEORGES,60730,ULLY ST GEORGES,,49.2743733295,2.27563757505,651,,Ully-Saint-Georges,Ully-Saint-Georges,60,Oise,32,Hauts-de-France
60652,VALDAMPIERRE,60790,VALDAMPIERRE,,49.3067997145,2.05433922137,652,,Valdampierre,Valdampierre,60,Oise,32,Hauts-de-France
60653,VALESCOURT,60130,VALESCOURT,,49.4852062948,2.43655225362,653,,Valescourt,Valescourt,60,Oise,32,Hauts-de-France
60654,VANDELICOURT,60490,VANDELICOURT,,49.5083038228,2.79666827304,654,,Vandélicourt,Vandélicourt,60,Oise,32,Hauts-de-France
60655,VARESNES,60400,VARESNES,,49.5566902552,3.07817073988,655,,Varesnes,Varesnes,60,Oise,32,Hauts-de-France
60656,VARINFROY,60890,VARINFROY,,49.0940200918,3.0480173997,656,,Varinfroy,Varinfroy,60,Oise,32,Hauts-de-France
60657,VAUCHELLES,60400,VAUCHELLES,,49.5858402656,2.96793055954,657,,Vauchelles,Vauchelles,60,Oise,32,Hauts-de-France
60658,VAUCIENNES,60117,VAUCIENNES,,49.2291351959,3.01623561024,658,,Vauciennes,Vauciennes,60,Oise,32,Hauts-de-France
60659,VAUDANCOURT,60240,VAUDANCOURT,,49.2291987469,1.76107824427,659,,Vaudancourt,Vaudancourt,60,Oise,32,Hauts-de-France
60660,LE VAUMAIN,60590,LE VAUMAIN,,49.3425632763,1.86578114721,660,Le,Vaumain,Le Vaumain,60,Oise,32,Hauts-de-France
60661,VAUMOISE,60117,VAUMOISE,,49.2399086091,2.98230232507,661,,Vaumoise,Vaumoise,60,Oise,32,Hauts-de-France
60662,LE VAUROUX,60390,LE VAUROUX,,49.3812218518,1.91494553081,662,Le,Vauroux,Le Vauroux,60,Oise,32,Hauts-de-France
60663,VELENNES,60510,VELENNES,,49.4733673509,2.18849314931,663,,Velennes,Velennes,60,Oise,32,Hauts-de-France
60664,VENDEUIL CAPLY,60120,VENDEUIL CAPLY,,49.6103570649,2.29253043617,664,,Vendeuil-Caply,Vendeuil-Caply,60,Oise,32,Hauts-de-France
60665,VENETTE,60280,VENETTE,,49.4195417844,2.78373972195,665,,Venette,Venette,60,Oise,32,Hauts-de-France
60666,VER SUR LAUNETTE,60950,VER SUR LAUNETTE,,49.1055721825,2.67260382684,666,,Ver-sur-Launette,Ver-sur-Launette,60,Oise,32,Hauts-de-France
60667,VERBERIE,60410,VERBERIE,,49.3057654461,2.73302583276,667,,Verberie,Verberie,60,Oise,32,Hauts-de-France
60668,VERDEREL LES SAUQUEUSE,60112,VERDEREL LES SAUQUEUSE,,49.5067320831,2.08154719995,668,,Verderel-lès-Sauqueuse,Verderel-lès-Sauqueuse,60,Oise,32,Hauts-de-France
60668,VERDEREL LES SAUQUEUSE,60112,VERDEREL LES SAUQUEUSE,SAUQUEUSE ST LUCIEN,49.5067320831,2.08154719995,668,,Verderel-lès-Sauqueuse,Verderel-lès-Sauqueuse,60,Oise,32,Hauts-de-France
60669,VERDERONNE,60140,VERDERONNE,,49.3288283373,2.49160157922,669,,Verderonne,Verderonne,60,Oise,32,Hauts-de-France
60670,VERNEUIL EN HALATTE,60550,VERNEUIL EN HALATTE,,49.2729920918,2.53462190972,670,,Verneuil-en-Halatte,Verneuil-en-Halatte,60,Oise,32,Hauts-de-France
60671,VERSIGNY,60440,VERSIGNY,,49.1606951564,2.78351758115,671,,Versigny,Versigny,60,Oise,32,Hauts-de-France
60672,VEZ,60117,VEZ,,49.256896611,3.00864189174,672,,Vez,Vez,60,Oise,32,Hauts-de-France
60673,VIEFVILLERS,60360,VIEFVILLERS,,49.6092660128,2.12817479204,673,,Viefvillers,Viefvillers,60,Oise,32,Hauts-de-France
60674,VIEUX MOULIN,60350,VIEUX MOULIN,,49.3844925116,2.92575839247,674,,Vieux-Moulin,Vieux-Moulin,60,Oise,32,Hauts-de-France
60675,VIGNEMONT,60162,VIGNEMONT,,49.496646661,2.77690716811,675,,Vignemont,Vignemont,60,Oise,32,Hauts-de-France
60676,VILLE,60400,VILLE,,49.5568331597,2.93397641263,676,,Ville,Ville,60,Oise,32,Hauts-de-France
60677,VILLEMBRAY,60650,VILLEMBRAY,,49.4826856803,1.87278558201,677,,Villembray,Villembray,60,Oise,32,Hauts-de-France
60678,VILLENEUVE LES SABLONS,60175,VILLENEUVE LES SABLONS,,49.2334293804,2.07366432947,678,,Villeneuve-les-Sablons,Villeneuve-les-Sablons,60,Oise,32,Hauts-de-France
60679,LA VILLENEUVE SOUS THURY,60890,LA VILLENEUVE SOUS THURY,,49.1601844737,3.0611547211,679,La,Villeneuve-sous-Thury,La Villeneuve-sous-Thury,60,Oise,32,Hauts-de-France
60680,VILLENEUVE SUR VERBERIE,60410,VILLENEUVE SUR VERBERIE,,49.2751399266,2.67989844537,680,,Villeneuve-sur-Verberie,Villeneuve-sur-Verberie,60,Oise,32,Hauts-de-France
60681,VILLERS ST BARTHELEMY,60650,VILLERS ST BARTHELEMY,,49.4030125375,1.95463003144,681,,Villers-Saint-Barthélemy,Villers-Saint-Barthélemy,60,Oise,32,Hauts-de-France
60682,VILLERS ST FRAMBOURG,60810,VILLERS ST FRAMBOURG,,49.260726193,2.63465752752,682,,Villers-Saint-Frambourg,Villers-Saint-Frambourg,60,Oise,32,Hauts-de-France
60683,VILLERS ST GENEST,60620,VILLERS ST GENEST,,49.14033599,2.90001405815,683,,Villers-Saint-Genest,Villers-Saint-Genest,60,Oise,32,Hauts-de-France
60684,VILLERS ST PAUL,60870,VILLERS ST PAUL,,49.2881764019,2.49334191961,684,,Villers-Saint-Paul,Villers-Saint-Paul,60,Oise,32,Hauts-de-France
60685,VILLERS ST SEPULCRE,60134,VILLERS ST SEPULCRE,,49.3689508807,2.20925215873,685,,Villers-Saint-Sépulcre,Villers-Saint-Sépulcre,60,Oise,32,Hauts-de-France
60686,VILLERS SOUS ST LEU,60340,VILLERS SOUS ST LEU,,49.2175345324,2.38499254783,686,,Villers-sous-Saint-Leu,Villers-sous-Saint-Leu,60,Oise,32,Hauts-de-France
60687,VILLERS SUR AUCHY,60650,VILLERS SUR AUCHY,,49.4762316159,1.79239140234,687,,Villers-sur-Auchy,Villers-sur-Auchy,60,Oise,32,Hauts-de-France
60688,VILLERS SUR BONNIERES,60860,VILLERS SUR BONNIERES,,49.5340978848,1.96061918501,688,,Villers-sur-Bonnières,Villers-sur-Bonnières,60,Oise,32,Hauts-de-France
60689,VILLERS SUR COUDUN,60150,VILLERS SUR COUDUN,,49.4863336578,2.8047419383,689,,Villers-sur-Coudun,Villers-sur-Coudun,60,Oise,32,Hauts-de-France
60690,VILLERS SUR TRIE,60590,VILLERS SUR TRIE,,49.3139532792,1.81713750407,690,,Villers-sur-Trie,Villers-sur-Trie,60,Oise,32,Hauts-de-France
60691,VILLERS VERMONT,60380,VILLERS VERMONT,,49.5743549353,1.7287833977,691,,Villers-Vermont,Villers-Vermont,60,Oise,32,Hauts-de-France
60692,VILLERS VICOMTE,60120,VILLERS VICOMTE,,49.6423565019,2.22866493678,692,,Villers-Vicomte,Villers-Vicomte,60,Oise,32,Hauts-de-France
60693,VILLESELVE,60640,VILLESELVE,,49.6864956183,3.11192493316,693,,Villeselve,Villeselve,60,Oise,32,Hauts-de-France
60694,VILLOTRAN,60390,VILLOTRAN,,49.3451558969,2.01028324287,694,,Villotran,Villotran,60,Oise,32,Hauts-de-France
60695,VINEUIL ST FIRMIN,60500,VINEUIL ST FIRMIN,,49.2047770407,2.50069915861,695,,Vineuil-Saint-Firmin,Vineuil-Saint-Firmin,60,Oise,32,Hauts-de-France
60697,VROCOURT,60112,VROCOURT,,49.5361368053,1.89040269759,697,,Vrocourt,Vrocourt,60,Oise,32,Hauts-de-France
60698,WACQUEMOULIN,60420,WACQUEMOULIN,,49.5082288701,2.6214465099,698,,Wacquemoulin,Wacquemoulin,60,Oise,32,Hauts-de-France
60699,WAMBEZ,60380,WAMBEZ,,49.5222569394,1.84463132714,699,,Wambez,Wambez,60,Oise,32,Hauts-de-France
60700,WARLUIS,60430,WARLUIS,,49.3861654492,2.15875512452,700,,Warluis,Warluis,60,Oise,32,Hauts-de-France
60701,WAVIGNIES,60130,WAVIGNIES,,49.5467064424,2.35949571781,701,,Wavignies,Wavignies,60,Oise,32,Hauts-de-France
60702,WELLES PERENNES,60420,WELLES PERENNES,,49.6092799764,2.48322981336,702,,Welles-Pérennes,Welles-Pérennes,60,Oise,32,Hauts-de-France
60703,AUX MARAIS,60000,AUX MARAIS,,49.4073687683,2.04083080438,703,Aux,Marais,Aux Marais,60,Oise,32,Hauts-de-France
61001,ALENCON,61000,ALENCON,,48.4318193082,0.0915406916107,1,,Alençon,Alençon,61,Orne,28,Normandie
61002,ALMENECHES,61570,ALMENECHES,,48.6982248016,0.125681370095,2,,Almenêches,Almenêches,61,Orne,28,Normandie
61005,APPENAI SOUS BELLEME,61130,APPENAI SOUS BELLEME,,48.3400960941,0.564708341058,5,,Appenai-sous-Bellême,Appenai-sous-Bellême,61,Orne,28,Normandie
61006,ARGENTAN,61200,ARGENTAN,,48.7321880919,-0.0132322590535,6,,Argentan,Argentan,61,Orne,28,Normandie
61007,ATHIS VAL DE ROUVRE,61100,ATHIS VAL DE ROUVRE,BREEL,48.7951982952,-0.509617196105,7,,Athis-Val de Rouvre,Athis-Val de Rouvre,61,Orne,28,Normandie
61007,ATHIS VAL DE ROUVRE,61100,ATHIS VAL DE ROUVRE,LA CARNEILLE,48.7951982952,-0.509617196105,7,,Athis-Val de Rouvre,Athis-Val de Rouvre,61,Orne,28,Normandie
61007,ATHIS VAL DE ROUVRE,61100,ATHIS VAL DE ROUVRE,LES TOURAILLES,48.7951982952,-0.509617196105,7,,Athis-Val de Rouvre,Athis-Val de Rouvre,61,Orne,28,Normandie
61007,ATHIS VAL DE ROUVRE,61100,ATHIS VAL DE ROUVRE,NOTRE DAME DU ROCHER,48.7951982952,-0.509617196105,7,,Athis-Val de Rouvre,Athis-Val de Rouvre,61,Orne,28,Normandie
61007,ATHIS VAL DE ROUVRE,61100,ATHIS VAL DE ROUVRE,RONFEUGERAI,48.7951982952,-0.509617196105,7,,Athis-Val de Rouvre,Athis-Val de Rouvre,61,Orne,28,Normandie
61007,ATHIS VAL DE ROUVRE,61100,ATHIS VAL DE ROUVRE,SEGRIE FONTAINE,48.7951982952,-0.509617196105,7,,Athis-Val de Rouvre,Athis-Val de Rouvre,61,Orne,28,Normandie
61007,ATHIS VAL DE ROUVRE,61100,ATHIS VAL DE ROUVRE,TAILLEBOIS,48.7951982952,-0.509617196105,7,,Athis-Val de Rouvre,Athis-Val de Rouvre,61,Orne,28,Normandie
61007,ATHIS VAL DE ROUVRE,61430,ATHIS VAL DE ROUVRE,,48.7951982952,-0.509617196105,7,,Athis-Val de Rouvre,Athis-Val de Rouvre,61,Orne,28,Normandie
61008,AUBE,61270,AUBE,,48.7318577878,0.545628090073,8,,Aube,Aube,61,Orne,28,Normandie
61009,AUBRY EN EXMES,61160,AUBRY EN EXMES,,48.7945507541,0.0713641098683,9,,Aubry-en-Exmes,Aubry-en-Exmes,61,Orne,28,Normandie
61010,AUBRY LE PANTHOU,61120,AUBRY LE PANTHOU,,48.8545964209,0.227741225949,10,,Aubry-le-Panthou,Aubry-le-Panthou,61,Orne,28,Normandie
61011,AUBUSSON,61100,AUBUSSON,,48.7798224159,-0.553278644755,11,,Aubusson,Aubusson,61,Orne,28,Normandie
61012,AUGUAISE,61270,AUGUAISE,,48.6981000827,0.551574938976,12,,Auguaise,Auguaise,61,Orne,28,Normandie
61013,AUNAY LES BOIS,61500,AUNAY LES BOIS,,48.5466721031,0.29333748049,13,,Aunay-les-Bois,Aunay-les-Bois,61,Orne,28,Normandie
61014,AUNOU LE FAUCON,61200,AUNOU LE FAUCON,,48.7194759541,0.055348775966,14,,Aunou-le-Faucon,Aunou-le-Faucon,61,Orne,28,Normandie
61015,AUNOU SUR ORNE,61500,AUNOU SUR ORNE,,48.6057819219,0.238232234334,15,,Aunou-sur-Orne,Aunou-sur-Orne,61,Orne,28,Normandie
61017,LES AUTHIEUX DU PUITS,61240,LES AUTHIEUX DU PUITS,,48.7049354762,0.329371823678,17,Les,Authieux-du-Puits,Les Authieux-du-Puits,61,Orne,28,Normandie
61018,AVERNES ST GOURGON,61470,AVERNES ST GOURGON,,48.9370401429,0.31350420868,18,,Avernes-Saint-Gourgon,Avernes-Saint-Gourgon,61,Orne,28,Normandie
61019,AVERNES SOUS EXMES,61310,AVERNES SOUS EXMES,,48.7882977467,0.201060617354,19,,Avernes-sous-Exmes,Avernes-sous-Exmes,61,Orne,28,Normandie
61020,AVOINE,61150,AVOINE,,48.6713596683,-0.0993268987581,20,,Avoine,Avoine,61,Orne,28,Normandie
61021,AVRILLY,61700,AVRILLY,,48.53716556,-0.617581790397,21,,Avrilly,Avrilly,61,Orne,28,Normandie
61023,BAILLEUL,61160,BAILLEUL,,48.8108648094,-0.0236048546803,23,,Bailleul,Bailleul,61,Orne,28,Normandie
61024,BANVOU,61450,BANVOU,,48.6639903117,-0.556894831544,24,,Banvou,Banvou,61,Orne,28,Normandie
61026,BARVILLE,61170,BARVILLE,,48.4725873445,0.346572085133,26,,Barville,Barville,61,Orne,28,Normandie
61028,BAZOCHES AU HOULME,61210,BAZOCHES AU HOULME,,48.8288059137,-0.263374307048,28,,Bazoches-au-Houlme,Bazoches-au-Houlme,61,Orne,28,Normandie
61029,BAZOCHES SUR HOENE,61560,BAZOCHES SUR HOENE,,48.5510739399,0.470361328466,29,,Bazoches-sur-Hoëne,Bazoches-sur-Hoëne,61,Orne,28,Normandie
61030,LA BAZOQUE,61100,LA BAZOQUE,,48.7845443137,-0.598606188518,30,La,Bazoque,La Bazoque,61,Orne,28,Normandie
61032,BEAUFAI,61270,BEAUFAI,,48.755637161,0.511069602511,32,,Beaufai,Beaufai,61,Orne,28,Normandie
61034,BEAULIEU,61190,BEAULIEU,,48.6802207803,0.736178480276,34,,Beaulieu,Beaulieu,61,Orne,28,Normandie
61035,BEAUVAIN,61600,BEAUVAIN,,48.6202503674,-0.302633037156,35,,Beauvain,Beauvain,61,Orne,28,Normandie
61036,BELFONDS,61500,BELFONDS,,48.6109514041,0.107175122984,36,,Belfonds,Belfonds,61,Orne,28,Normandie
61037,BELLAVILLIERS,61360,BELLAVILLIERS,,48.4176974835,0.490691078997,37,,Bellavilliers,Bellavilliers,61,Orne,28,Normandie
61038,BELLEME,61130,BELLEME,,48.3730422807,0.563433048033,38,,Bellême,Bellême,61,Orne,28,Normandie
61039,LA BELLIERE,61570,LA BELLIERE,,48.6212934568,-0.0179184399938,39,La,Bellière,La Bellière,61,Orne,28,Normandie
61040,BELLOU EN HOULME,61220,BELLOU EN HOULME,,48.6910303266,-0.444104849045,40,,Bellou-en-Houlme,Bellou-en-Houlme,61,Orne,28,Normandie
61041,BELLOU LE TRICHARD,61130,BELLOU LE TRICHARD,,48.2640987687,0.55321746429,41,,Bellou-le-Trichard,Bellou-le-Trichard,61,Orne,28,Normandie
61043,BERD HUIS,61340,BERD HUIS,,48.3500105635,0.739532399855,43,,Berd'huis,Berd'huis,61,Orne,28,Normandie
61044,BERJOU,61430,BERJOU,,48.8487660558,-0.478406645322,44,,Berjou,Berjou,61,Orne,28,Normandie
61046,BIZOU,61290,BIZOU,,48.492046967,0.750681055966,46,,Bizou,Bizou,61,Orne,28,Normandie
61048,BOECE,61560,BOECE,,48.5149253316,0.454540443789,48,,Boëcé,Boëcé,61,Orne,28,Normandie
61049,BOISSEI LA LANDE,61570,BOISSEI LA LANDE,,48.6888205216,0.0605654191254,49,,Boissei-la-Lande,Boissei-la-Lande,61,Orne,28,Normandie
61050,COUR MAUGIS SUR HUISNE,61110,COUR MAUGIS SUR HUISNE,BOISSY MAUGIS,48.464144125,0.730036118715,50,,Cour-Maugis sur Huisne,Cour-Maugis sur Huisne,61,Orne,28,Normandie
61050,COUR MAUGIS SUR HUISNE,61110,COUR MAUGIS SUR HUISNE,MAISON MAUGIS,48.464144125,0.730036118715,50,,Cour-Maugis sur Huisne,Cour-Maugis sur Huisne,61,Orne,28,Normandie
61050,COUR MAUGIS SUR HUISNE,61110,COUR MAUGIS SUR HUISNE,ST MAURICE SUR HUISNE,48.464144125,0.730036118715,50,,Cour-Maugis sur Huisne,Cour-Maugis sur Huisne,61,Orne,28,Normandie
61050,COUR MAUGIS SUR HUISNE,61340,COUR MAUGIS SUR HUISNE,COURCERAULT,48.464144125,0.730036118715,50,,Cour-Maugis sur Huisne,Cour-Maugis sur Huisne,61,Orne,28,Normandie
61051,BOITRON,61500,BOITRON,,48.5692964813,0.265425741092,51,,Boitron,Boitron,61,Orne,28,Normandie
61052,BONNEFOI,61270,BONNEFOI,,48.6761468135,0.562246564264,52,,Bonnefoi,Bonnefoi,61,Orne,28,Normandie
61053,BONSMOULINS,61380,BONSMOULINS,,48.6583186822,0.541666162724,53,,Bonsmoulins,Bonsmoulins,61,Orne,28,Normandie
61054,LE BOSC RENOULT,61470,LE BOSC RENOULT,,48.9156193104,0.309566800591,54,Le,Bosc-Renoult,Le Bosc-Renoult,61,Orne,28,Normandie
61055,BOUCE,61570,BOUCE,,48.6324251717,-0.095549889468,55,,Boucé,Boucé,61,Orne,28,Normandie
61056,LE BOUILLON,61500,LE BOUILLON,,48.558226063,0.0985668726868,56,Le,Bouillon,Le Bouillon,61,Orne,28,Normandie
61057,LE BOURG ST LEONARD,61310,LE BOURG ST LEONARD,,48.7715618333,0.105064371264,57,Le,Bourg-Saint-Léonard,Le Bourg-Saint-Léonard,61,Orne,28,Normandie
61060,BRETHEL,61270,BRETHEL,,48.7151055973,0.54286645063,60,,Brethel,Brethel,61,Orne,28,Normandie
61061,BRETONCELLES,61110,BRETONCELLES,,48.4358447093,0.900700483444,61,,Bretoncelles,Bretoncelles,61,Orne,28,Normandie
61062,BRIEUX,61160,BRIEUX,,48.8334251501,-0.0791169679953,62,,Brieux,Brieux,61,Orne,28,Normandie
61063,BRIOUZE,61220,BRIOUZE,,48.7111600408,-0.377752655086,63,,Briouze,Briouze,61,Orne,28,Normandie
61064,BRULLEMAIL,61390,BRULLEMAIL,,48.6595245184,0.324213865898,64,,Brullemail,Brullemail,61,Orne,28,Normandie
61066,BURE,61170,BURE,,48.5057238643,0.404327830049,66,,Buré,Buré,61,Orne,28,Normandie
61067,BURES,61170,BURES,,48.5579280908,0.401372198691,67,,Bures,Bures,61,Orne,28,Normandie
61068,BURSARD,61500,BURSARD,,48.5410404688,0.203747510397,68,,Bursard,Bursard,61,Orne,28,Normandie
61069,CAHAN,61430,CAHAN,,48.8597279145,-0.446109041609,69,,Cahan,Cahan,61,Orne,28,Normandie
61070,CALIGNY,61100,CALIGNY,,48.8071056766,-0.603648177007,70,,Caligny,Caligny,61,Orne,28,Normandie
61071,CAMEMBERT,61120,CAMEMBERT,,48.8916392638,0.17180547604,71,,Camembert,Camembert,61,Orne,28,Normandie
61072,CANAPVILLE,61120,CANAPVILLE,,48.9484108928,0.256239841886,72,,Canapville,Canapville,61,Orne,28,Normandie
61074,CARROUGES,61320,CARROUGES,,48.5691225023,-0.160230464301,74,,Carrouges,Carrouges,61,Orne,28,Normandie
61075,CEAUCE,61330,CEAUCE,,48.4936450518,-0.634587693157,75,,Ceaucé,Ceaucé,61,Orne,28,Normandie
61076,LE CERCUEIL,61500,LE CERCUEIL,,48.5939742322,0.0100728220332,76,Le,Cercueil,Le Cercueil,61,Orne,28,Normandie
61077,CERISE,61000,CERISE,,48.4441981247,0.131476179306,77,,Cerisé,Cerisé,61,Orne,28,Normandie
61078,CERISY BELLE ETOILE,61100,CERISY BELLE ETOILE,,48.7831815078,-0.623679681205,78,,Cerisy-Belle-Étoile,Cerisy-Belle-Étoile,61,Orne,28,Normandie
61079,CETON,61260,CETON,,48.2249965055,0.758513883231,79,,Ceton,Ceton,61,Orne,28,Normandie
61080,CHAHAINS,61320,CHAHAINS,,48.561816363,-0.116398968033,80,,Chahains,Chahains,61,Orne,28,Normandie
61081,CHAILLOUE,61240,CHAILLOUE,MARMOUILLE,48.6568911726,0.196513358702,81,,Chailloué,Chailloué,61,Orne,28,Normandie
61081,CHAILLOUE,61500,CHAILLOUE,,48.6568911726,0.196513358702,81,,Chailloué,Chailloué,61,Orne,28,Normandie
61081,CHAILLOUE,61500,CHAILLOUE,NEUVILLE PRES SEES,48.6568911726,0.196513358702,81,,Chailloué,Chailloué,61,Orne,28,Normandie
61082,LE CHALANGE,61390,LE CHALANGE,,48.5921212927,0.312206757526,82,Le,Chalange,Le Chalange,61,Orne,28,Normandie
61083,CHAMBOIS,61160,CHAMBOIS,,48.8158758305,0.112532847122,83,,Chambois,Chambois,61,Orne,28,Normandie
61084,CHAMPCERIE,61210,CHAMPCERIE,,48.7979697432,-0.228081171597,84,,Champcerie,Champcerie,61,Orne,28,Normandie
61085,LE CHAMP DE LA PIERRE,61320,LE CHAMP DE LA PIERRE,,48.6042220102,-0.205228915716,85,Le,Champ-de-la-Pierre,Le Champ-de-la-Pierre,61,Orne,28,Normandie
61086,LES CHAMPEAUX,61120,LES CHAMPEAUX,,48.8883947737,0.134248218764,86,Les,Champeaux,Les Champeaux,61,Orne,28,Normandie
61087,CHAMPEAUX SUR SARTHE,61560,CHAMPEAUX SUR SARTHE,,48.5770264971,0.439382618657,87,,Champeaux-sur-Sarthe,Champeaux-sur-Sarthe,61,Orne,28,Normandie
61088,CHAMP HAUT,61240,CHAMP HAUT,,48.7261314736,0.325245165597,88,,Champ-Haut,Champ-Haut,61,Orne,28,Normandie
61089,CHAMPOSOULT,61120,CHAMPOSOULT,,48.8633242426,0.16705586887,89,,Champosoult,Champosoult,61,Orne,28,Normandie
61091,CHAMPSECRET,61700,CHAMPSECRET,,48.605210467,-0.525248571661,91,,Champsecret,Champsecret,61,Orne,28,Normandie
61092,CHANDAI,61300,CHANDAI,,48.7419330141,0.744469274566,92,,Chandai,Chandai,61,Orne,28,Normandie
61093,CHANU,61800,CHANU,,48.7224631427,-0.672912176288,93,,Chanu,Chanu,61,Orne,28,Normandie
61094,LA CHAPELLE AU MOINE,61100,LA CHAPELLE AU MOINE,,48.7030083622,-0.592037594285,94,La,Chapelle-au-Moine,La Chapelle-au-Moine,61,Orne,28,Normandie
61095,LA CHAPELLE BICHE,61100,LA CHAPELLE BICHE,,48.7128515719,-0.622127814568,95,La,Chapelle-Biche,La Chapelle-Biche,61,Orne,28,Normandie
61096,RIVES D ANDAINE,61140,RIVES D ANDAINE,GENESLAY,48.5439558211,-0.466370828883,96,,Rives d'Andaine,Rives d'Andaine,61,Orne,28,Normandie
61096,RIVES D ANDAINE,61140,RIVES D ANDAINE,LA CHAPELLE D ANDAINE,48.5439558211,-0.466370828883,96,,Rives d'Andaine,Rives d'Andaine,61,Orne,28,Normandie
61096,RIVES D ANDAINE,61410,RIVES D ANDAINE,COUTERNE,48.5439558211,-0.466370828883,96,,Rives d'Andaine,Rives d'Andaine,61,Orne,28,Normandie
61096,RIVES D ANDAINE,61410,RIVES D ANDAINE,HALEINE,48.5439558211,-0.466370828883,96,,Rives d'Andaine,Rives d'Andaine,61,Orne,28,Normandie
61097,LA CHAPELLE MONTLIGEON,61400,LA CHAPELLE MONTLIGEON,,48.4854312725,0.659562933284,97,La,Chapelle-Montligeon,La Chapelle-Montligeon,61,Orne,28,Normandie
61098,LA CHAPELLE PRES SEES,61500,LA CHAPELLE PRES SEES,,48.5667583152,0.153857564535,98,La,Chapelle-près-Sées,La Chapelle-près-Sées,61,Orne,28,Normandie
61099,LA CHAPELLE SOUEF,61130,LA CHAPELLE SOUEF,,48.3177028134,0.588455492814,99,La,Chapelle-Souëf,La Chapelle-Souëf,61,Orne,28,Normandie
61100,LA CHAPELLE VIEL,61270,LA CHAPELLE VIEL,,48.7099526297,0.612218004614,100,La,Chapelle-Viel,La Chapelle-Viel,61,Orne,28,Normandie
61101,LE CHATEAU D ALMENECHES,61570,LE CHATEAU D ALMENECHES,,48.6727206778,0.130610426529,101,Le,Château-d'Almenêches,Le Château-d'Almenêches,61,Orne,28,Normandie
61102,LE CHATELLIER,61450,LE CHATELLIER,,48.6774891036,-0.583208023113,102,Le,Châtellier,Le Châtellier,61,Orne,28,Normandie
61103,CHAUMONT,61230,CHAUMONT,,48.8445061451,0.338218709549,103,,Chaumont,Chaumont,61,Orne,28,Normandie
61104,LA CHAUX,61600,LA CHAUX,,48.6020436044,-0.267103118047,104,La,Chaux,La Chaux,61,Orne,28,Normandie
61105,CHEMILLI,61360,CHEMILLI,,48.3600549663,0.451929656823,105,,Chemilli,Chemilli,61,Orne,28,Normandie
61107,CIRAL,61320,CIRAL,,48.5071426572,-0.132794455306,107,,Ciral,Ciral,61,Orne,28,Normandie
61108,CISAI ST AUBIN,61230,CISAI ST AUBIN,,48.7765975635,0.357700893574,108,,Cisai-Saint-Aubin,Cisai-Saint-Aubin,61,Orne,28,Normandie
61110,LA COCHERE,61310,LA COCHERE,,48.7221256327,0.155120131817,110,La,Cochère,La Cochère,61,Orne,28,Normandie
61111,COLOMBIERS,61250,COLOMBIERS,,48.4757516967,0.0509684749642,111,,Colombiers,Colombiers,61,Orne,28,Normandie
61113,COMBLOT,61400,COMBLOT,,48.4604945962,0.586141513594,113,,Comblot,Comblot,61,Orne,28,Normandie
61114,COMMEAUX,61200,COMMEAUX,,48.7884537955,-0.0987177513716,114,,Commeaux,Commeaux,61,Orne,28,Normandie
61116,SABLONS SUR HUISNE,61110,SABLONS SUR HUISNE,CONDEAU,48.3793529122,0.864706610339,116,,Sablons sur Huisne,Sablons sur Huisne,61,Orne,28,Normandie
61116,SABLONS SUR HUISNE,61110,SABLONS SUR HUISNE,CONDE SUR HUISNE,48.3793529122,0.864706610339,116,,Sablons sur Huisne,Sablons sur Huisne,61,Orne,28,Normandie
61116,SABLONS SUR HUISNE,61110,SABLONS SUR HUISNE,COULONGES LES SABLONS,48.3793529122,0.864706610339,116,,Sablons sur Huisne,Sablons sur Huisne,61,Orne,28,Normandie
61117,CONDE SUR SARTHE,61250,CONDE SUR SARTHE,,48.4339888036,0.0335059639951,117,,Condé-sur-Sarthe,Condé-sur-Sarthe,61,Orne,28,Normandie
61118,CORBON,61400,CORBON,,48.4600122799,0.642647219775,118,,Corbon,Corbon,61,Orne,28,Normandie
61120,COUDEHARD,61160,COUDEHARD,,48.8492741023,0.137324296391,120,,Coudehard,Coudehard,61,Orne,28,Normandie
61121,COULIMER,61360,COULIMER,,48.4795734598,0.461229892959,121,,Coulimer,Coulimer,61,Orne,28,Normandie
61122,COULMER,61230,COULMER,,48.7666900667,0.303236402861,122,,Coulmer,Coulmer,61,Orne,28,Normandie
61123,COULONCES,61160,COULONCES,,48.8320426038,0.0154675970058,123,,Coulonces,Coulonces,61,Orne,28,Normandie
61124,LA COULONCHE,61220,LA COULONCHE,,48.6375516967,-0.466617045312,124,La,Coulonche,La Coulonche,61,Orne,28,Normandie
61126,COULONGES SUR SARTHE,61170,COULONGES SUR SARTHE,,48.5299458495,0.395044867838,126,,Coulonges-sur-Sarthe,Coulonges-sur-Sarthe,61,Orne,28,Normandie
61129,COURGEON,61400,COURGEON,,48.4792586192,0.610569927364,129,,Courgeon,Courgeon,61,Orne,28,Normandie
61130,COURGEOUT,61560,COURGEOUT,,48.5081772919,0.490279202757,130,,Courgeoût,Courgeoût,61,Orne,28,Normandie
61131,COURMENIL,61310,COURMENIL,,48.7714604847,0.228786798845,131,,Courménil,Courménil,61,Orne,28,Normandie
61133,COURTOMER,61390,COURTOMER,,48.6253139706,0.348458079844,133,,Courtomer,Courtomer,61,Orne,28,Normandie
61137,CRAMENIL,61220,CRAMENIL,,48.7450234977,-0.372031703174,137,,Craménil,Craménil,61,Orne,28,Normandie
61138,CROISILLES,61230,CROISILLES,,48.7626241213,0.267120484409,138,,Croisilles,Croisilles,61,Orne,28,Normandie
61139,CROUTTES,61120,CROUTTES,,48.9223848816,0.136536392714,139,,Crouttes,Crouttes,61,Orne,28,Normandie
61140,CRULAI,61300,CRULAI,,48.6957117873,0.659111574519,140,,Crulai,Crulai,61,Orne,28,Normandie
61141,CUISSAI,61250,CUISSAI,,48.4725248248,0.00249150932899,141,,Cuissai,Cuissai,61,Orne,28,Normandie
61142,DAME MARIE,61130,DAME MARIE,,48.3547234316,0.618155918935,142,,Dame-Marie,Dame-Marie,61,Orne,28,Normandie
61143,DAMIGNY,61250,DAMIGNY,,48.4509364175,0.0721150858454,143,,Damigny,Damigny,61,Orne,28,Normandie
61145,DOMFRONT EN POIRAIE,61700,DOMFRONT EN POIRAIE,,48.5824684934,-0.61398156582,145,,Domfront en Poiraie,Domfront en Poiraie,61,Orne,28,Normandie
61145,DOMFRONT EN POIRAIE,61700,DOMFRONT EN POIRAIE,LA HAUTE CHAPELLE,48.5824684934,-0.61398156582,145,,Domfront en Poiraie,Domfront en Poiraie,61,Orne,28,Normandie
61145,DOMFRONT EN POIRAIE,61700,DOMFRONT EN POIRAIE,ROUELLE,48.5824684934,-0.61398156582,145,,Domfront en Poiraie,Domfront en Poiraie,61,Orne,28,Normandie
61146,DOMPIERRE,61700,DOMPIERRE,,48.6368158106,-0.5529421904,146,,Dompierre,Dompierre,61,Orne,28,Normandie
61148,DURCET,61100,DURCET,,48.7409713095,-0.441750363185,148,,Durcet,Durcet,61,Orne,28,Normandie
61149,ECHALOU,61440,ECHALOU,,48.7222533282,-0.492334551677,149,,Échalou,Échalou,61,Orne,28,Normandie
61150,ECHAUFFOUR,61370,ECHAUFFOUR,,48.7322506455,0.395097737587,150,,Échauffour,Échauffour,61,Orne,28,Normandie
61151,ECORCEI,61270,ECORCEI,,48.7192657885,0.575658478261,151,,Écorcei,Écorcei,61,Orne,28,Normandie
61152,ECORCHES,61160,ECORCHES,,48.8736821576,0.0860912539084,152,,Écorches,Écorches,61,Orne,28,Normandie
61153,ECOUCHE LES VALLEES,61150,ECOUCHE LES VALLEES,,48.715236562,-0.11079364791,153,,Écouché-les-Vallées,Écouché-les-Vallées,61,Orne,28,Normandie
61153,ECOUCHE LES VALLEES,61150,ECOUCHE LES VALLEES,BATILLY,48.715236562,-0.11079364791,153,,Écouché-les-Vallées,Écouché-les-Vallées,61,Orne,28,Normandie
61153,ECOUCHE LES VALLEES,61150,ECOUCHE LES VALLEES,LA COURBE,48.715236562,-0.11079364791,153,,Écouché-les-Vallées,Écouché-les-Vallées,61,Orne,28,Normandie
61153,ECOUCHE LES VALLEES,61150,ECOUCHE LES VALLEES,LOUCE,48.715236562,-0.11079364791,153,,Écouché-les-Vallées,Écouché-les-Vallées,61,Orne,28,Normandie
61153,ECOUCHE LES VALLEES,61150,ECOUCHE LES VALLEES,SERANS,48.715236562,-0.11079364791,153,,Écouché-les-Vallées,Écouché-les-Vallées,61,Orne,28,Normandie
61153,ECOUCHE LES VALLEES,61150,ECOUCHE LES VALLEES,ST OUEN SUR MAIRE,48.715236562,-0.11079364791,153,,Écouché-les-Vallées,Écouché-les-Vallées,61,Orne,28,Normandie
61154,EPERRAIS,61400,EPERRAIS,,48.4156893337,0.54665296801,154,,Eperrais,Eperrais,61,Orne,28,Normandie
61156,ESSAY,61500,ESSAY,,48.5404326393,0.242237703533,156,,Essay,Essay,61,Orne,28,Normandie
61157,EXMES,61310,EXMES,,48.7563407789,0.180543046693,157,,Exmes,Exmes,61,Orne,28,Normandie
61158,FAVEROLLES,61600,FAVEROLLES,,48.6691172577,-0.289200935258,158,,Faverolles,Faverolles,61,Orne,28,Normandie
61159,FAY,61390,FAY,,48.6612913524,0.407509291689,159,,Fay,Fay,61,Orne,28,Normandie
61160,FEINGS,61400,FEINGS,,48.5433586588,0.649080735895,160,,Feings,Feings,61,Orne,28,Normandie
61161,FEL,61160,FEL,,48.7933103841,0.111944335524,161,,Fel,Fel,61,Orne,28,Normandie
61162,LA FERRIERE AU DOYEN,61380,LA FERRIERE AU DOYEN,,48.6795473913,0.506168512231,162,La,Ferrière-au-Doyen,La Ferrière-au-Doyen,61,Orne,28,Normandie
61163,LA FERRIERE AUX ETANGS,61450,LA FERRIERE AUX ETANGS,,48.6515598236,-0.508551110895,163,La,Ferrière-aux-Étangs,La Ferrière-aux-Étangs,61,Orne,28,Normandie
61164,LA FERRIERE BECHET,61500,LA FERRIERE BECHET,,48.5753978113,0.0709025033541,164,La,Ferrière-Béchet,La Ferrière-Béchet,61,Orne,28,Normandie
61165,LA FERRIERE BOCHARD,61420,LA FERRIERE BOCHARD,,48.4160824644,-0.0368422233193,165,La,Ferrière-Bochard,La Ferrière-Bochard,61,Orne,28,Normandie
61166,FERRIERES LA VERRERIE,61390,FERRIERES LA VERRERIE,,48.6526270893,0.376818250873,166,,Ferrières-la-Verrerie,Ferrières-la-Verrerie,61,Orne,28,Normandie
61167,LA FERTE EN OUCHE,61470,LA FERTE EN OUCHE,HEUGON,48.8362459554,0.508544741133,167,La,Ferté-en-Ouche,La Ferté-en-Ouche,61,Orne,28,Normandie
61167,LA FERTE EN OUCHE,61470,LA FERTE EN OUCHE,MONNAI,48.8362459554,0.508544741133,167,La,Ferté-en-Ouche,La Ferté-en-Ouche,61,Orne,28,Normandie
61167,LA FERTE EN OUCHE,61550,LA FERTE EN OUCHE,,48.8362459554,0.508544741133,167,La,Ferté-en-Ouche,La Ferté-en-Ouche,61,Orne,28,Normandie
61167,LA FERTE EN OUCHE,61550,LA FERTE EN OUCHE,ANCEINS,48.8362459554,0.508544741133,167,La,Ferté-en-Ouche,La Ferté-en-Ouche,61,Orne,28,Normandie
61167,LA FERTE EN OUCHE,61550,LA FERTE EN OUCHE,BOCQUENCE,48.8362459554,0.508544741133,167,La,Ferté-en-Ouche,La Ferté-en-Ouche,61,Orne,28,Normandie
61167,LA FERTE EN OUCHE,61550,LA FERTE EN OUCHE,COUVAINS,48.8362459554,0.508544741133,167,La,Ferté-en-Ouche,La Ferté-en-Ouche,61,Orne,28,Normandie
61167,LA FERTE EN OUCHE,61550,LA FERTE EN OUCHE,GAUVILLE,48.8362459554,0.508544741133,167,La,Ferté-en-Ouche,La Ferté-en-Ouche,61,Orne,28,Normandie
61167,LA FERTE EN OUCHE,61550,LA FERTE EN OUCHE,GLOS LA FERRIERE,48.8362459554,0.508544741133,167,La,Ferté-en-Ouche,La Ferté-en-Ouche,61,Orne,28,Normandie
61167,LA FERTE EN OUCHE,61550,LA FERTE EN OUCHE,MARNEFER,48.8362459554,0.508544741133,167,La,Ferté-en-Ouche,La Ferté-en-Ouche,61,Orne,28,Normandie
61167,LA FERTE EN OUCHE,61550,LA FERTE EN OUCHE,ST NICOLAS DES LAITIERS,48.8362459554,0.508544741133,167,La,Ferté-en-Ouche,La Ferté-en-Ouche,61,Orne,28,Normandie
61167,LA FERTE EN OUCHE,61550,LA FERTE EN OUCHE,VILLERS EN OUCHE,48.8362459554,0.508544741133,167,La,Ferté-en-Ouche,La Ferté-en-Ouche,61,Orne,28,Normandie
61168,LA FERTE MACE,61410,LA FERTE MACE,ANTOIGNY,48.581835018,-0.366879794994,168,La,Ferté Macé,La Ferté Macé,61,Orne,28,Normandie
61168,LA FERTE MACE,61600,LA FERTE MACE,,48.581835018,-0.366879794994,168,La,Ferté Macé,La Ferté Macé,61,Orne,28,Normandie
61169,FLERS,61100,FLERS,,48.7399319125,-0.562509564641,169,,Flers,Flers,61,Orne,28,Normandie
61170,FLEURE,61200,FLEURE,,48.6830918716,-0.0507019208151,170,,Fleuré,Fleuré,61,Orne,28,Normandie
61171,FONTAINE LES BASSETS,61160,FONTAINE LES BASSETS,,48.8614123081,0.00290765242172,171,,Fontaine-les-Bassets,Fontaine-les-Bassets,61,Orne,28,Normandie
61172,FONTENAI LES LOUVETS,61420,FONTENAI LES LOUVETS,,48.5327670824,0.00848249030153,172,,Fontenai-les-Louvets,Fontenai-les-Louvets,61,Orne,28,Normandie
61173,FONTENAI SUR ORNE,61200,FONTENAI SUR ORNE,,48.7169406919,-0.0739482661223,173,,Fontenai-sur-Orne,Fontenai-sur-Orne,61,Orne,28,Normandie
61176,FRANCHEVILLE,61570,FRANCHEVILLE,,48.6382859389,-0.0600624308004,176,,Francheville,Francheville,61,Orne,28,Normandie
61178,LA FRESNAIE FAYEL,61230,LA FRESNAIE FAYEL,,48.8333166779,0.227433994696,178,La,Fresnaie-Fayel,La Fresnaie-Fayel,61,Orne,28,Normandie
61180,FRESNAY LE SAMSON,61120,FRESNAY LE SAMSON,,48.872166928,0.207025608696,180,,Fresnay-le-Samson,Fresnay-le-Samson,61,Orne,28,Normandie
61181,GACE,61230,GACE,,48.790215977,0.308446478408,181,,Gacé,Gacé,61,Orne,28,Normandie
61182,GANDELAIN,61420,GANDELAIN,,48.471434104,-0.0863104439985,182,,Gandelain,Gandelain,61,Orne,28,Normandie
61183,GAPREE,61390,GAPREE,,48.6244819747,0.293642849368,183,,Gâprée,Gâprée,61,Orne,28,Normandie
61187,LES GENETTES,61270,LES GENETTES,,48.6613367608,0.582203708406,187,Les,Genettes,Les Genettes,61,Orne,28,Normandie
61188,LA GENEVRAIE,61240,LA GENEVRAIE,,48.6836221489,0.319127105217,188,La,Genevraie,La Genevraie,61,Orne,28,Normandie
61189,GIEL COURTEILLES,61210,GIEL COURTEILLES,,48.7650250908,-0.208008269332,189,,Giel-Courteilles,Giel-Courteilles,61,Orne,28,Normandie
61190,GINAI,61310,GINAI,,48.7444923709,0.209513017562,190,,Ginai,Ginai,61,Orne,28,Normandie
61192,GODISSON,61240,GODISSON,,48.6758477819,0.249741558022,192,,Godisson,Godisson,61,Orne,28,Normandie
61193,LA GONFRIERE,61550,LA GONFRIERE,,48.8156823436,0.490360081148,193,La,Gonfrière,La Gonfrière,61,Orne,28,Normandie
61194,GOULET,61150,GOULET,,48.7360749427,-0.0897289866447,194,,Monts-sur-Orne,Monts-sur-Orne,61,Orne,28,Normandie
61195,LE GRAIS,61600,LE GRAIS,,48.640228163,-0.320480791635,195,Le,Grais,Le Grais,61,Orne,28,Normandie
61196,LE GUE DE LA CHAINE,61130,LE GUE DE LA CHAINE,,48.3725300515,0.509787667614,196,,Belforêt-en-Perche,Belforêt-en-Perche,61,Orne,28,Normandie
61197,GUEPREI,61160,GUEPREI,,48.8356279545,-0.0153255535915,197,,Guêprei,Guêprei,61,Orne,28,Normandie
61198,GUERQUESALLES,61120,GUERQUESALLES,,48.9007314354,0.215841045214,198,,Guerquesalles,Guerquesalles,61,Orne,28,Normandie
61199,HABLOVILLE,61210,HABLOVILLE,,48.7905539735,-0.178098224902,199,,Habloville,Habloville,61,Orne,28,Normandie
61202,HAUTERIVE,61250,HAUTERIVE,,48.4813211383,0.205141190434,202,,Hauterive,Hauterive,61,Orne,28,Normandie
61203,HELOUP,61250,HELOUP,,48.3967092098,0.0351889089974,203,,Héloup,Héloup,61,Orne,28,Normandie
61206,L HOME CHAMONDOT,61290,L HOME CHAMONDOT,,48.6002616594,0.735701468188,206,L',Hôme-Chamondot,L'Hôme-Chamondot,61,Orne,28,Normandie
61207,IGE,61130,IGE,,48.3129531169,0.516923568848,207,,Igé,Igé,61,Orne,28,Normandie
61208,IRAI,61190,IRAI,,48.6742050739,0.689209561635,208,,Irai,Irai,61,Orne,28,Normandie
61209,JOUE DU BOIS,61320,JOUE DU BOIS,,48.5845783211,-0.231209382514,209,,Joué-du-Bois,Joué-du-Bois,61,Orne,28,Normandie
61210,JOUE DU PLAIN,61150,JOUE DU PLAIN,,48.6801916267,-0.140023769708,210,,Joué-du-Plain,Joué-du-Plain,61,Orne,28,Normandie
61211,JUVIGNY VAL D ANDAINE,61140,JUVIGNY VAL D ANDAINE,,48.5575380101,-0.496792071108,211,,Juvigny Val d'Andaine,Juvigny Val d'Andaine,61,Orne,28,Normandie
61211,JUVIGNY VAL D ANDAINE,61140,JUVIGNY VAL D ANDAINE,BEAULANDAIS,48.5575380101,-0.496792071108,211,,Juvigny Val d'Andaine,Juvigny Val d'Andaine,61,Orne,28,Normandie
61211,JUVIGNY VAL D ANDAINE,61330,JUVIGNY VAL D ANDAINE,LA BAROCHE SOUS LUCE,48.5575380101,-0.496792071108,211,,Juvigny Val d'Andaine,Juvigny Val d'Andaine,61,Orne,28,Normandie
61211,JUVIGNY VAL D ANDAINE,61330,JUVIGNY VAL D ANDAINE,LORE,48.5575380101,-0.496792071108,211,,Juvigny Val d'Andaine,Juvigny Val d'Andaine,61,Orne,28,Normandie
61211,JUVIGNY VAL D ANDAINE,61330,JUVIGNY VAL D ANDAINE,LUCE,48.5575380101,-0.496792071108,211,,Juvigny Val d'Andaine,Juvigny Val d'Andaine,61,Orne,28,Normandie
61211,JUVIGNY VAL D ANDAINE,61330,JUVIGNY VAL D ANDAINE,SEPT FORGES,48.5575380101,-0.496792071108,211,,Juvigny Val d'Andaine,Juvigny Val d'Andaine,61,Orne,28,Normandie
61211,JUVIGNY VAL D ANDAINE,61330,JUVIGNY VAL D ANDAINE,ST DENIS DE VILLENETTE,48.5575380101,-0.496792071108,211,,Juvigny Val d'Andaine,Juvigny Val d'Andaine,61,Orne,28,Normandie
61212,JUVIGNY SUR ORNE,61200,JUVIGNY SUR ORNE,,48.7241556418,0.0235822074744,212,,Juvigny-sur-Orne,Juvigny-sur-Orne,61,Orne,28,Normandie
61213,LALACELLE,61320,LALACELLE,,48.4693160672,-0.127634893953,213,,Lalacelle,Lalacelle,61,Orne,28,Normandie
61214,L AIGLE,61300,L AIGLE,,48.7561549553,0.611126420423,214,L',Aigle,L'Aigle,61,Orne,28,Normandie
61215,LALEU,61170,LALEU,,48.5417295562,0.365988546618,215,,Laleu,Laleu,61,Orne,28,Normandie
61216,LA LANDE DE GOULT,61320,LA LANDE DE GOULT,,48.5840302708,-0.0548449345783,216,La,Lande-de-Goult,La Lande-de-Goult,61,Orne,28,Normandie
61217,LA LANDE DE LOUGE,61210,LA LANDE DE LOUGE,,48.7010795205,-0.249914242658,217,La,Lande-de-Lougé,La Lande-de-Lougé,61,Orne,28,Normandie
61218,LA LANDE PATRY,61100,LA LANDE PATRY,,48.7535484464,-0.598148128234,218,La,Lande-Patry,La Lande-Patry,61,Orne,28,Normandie
61219,LA LANDE ST SIMEON,61100,LA LANDE ST SIMEON,,48.8273403664,-0.433811348847,219,La,Lande-Saint-Siméon,La Lande-Saint-Siméon,61,Orne,28,Normandie
61221,LANDIGOU,61100,LANDIGOU,,48.7436800101,-0.47751409105,221,,Landigou,Landigou,61,Orne,28,Normandie
61222,LANDISACQ,61100,LANDISACQ,,48.752851397,-0.658625971927,222,,Landisacq,Landisacq,61,Orne,28,Normandie
61224,LARRE,61250,LARRE,,48.4933130424,0.166961596924,224,,Larré,Larré,61,Orne,28,Normandie
61225,LIGNERES,61240,LIGNERES,,48.7371565601,0.306149827572,225,,Lignères,Lignères,61,Orne,28,Normandie
61227,LIGNOU,61220,LIGNOU,,48.6736724147,-0.342978146704,227,,Lignou,Lignou,61,Orne,28,Normandie
61228,LIVAIE,61420,LIVAIE,,48.5076444763,-0.0385013329846,228,,Livaie,Livaie,61,Orne,28,Normandie
61229,LOISAIL,61400,LOISAIL,,48.5049086488,0.586338732219,229,,Loisail,Loisail,61,Orne,28,Normandie
61230,LONGNY LES VILLAGES,61290,LONGNY LES VILLAGES,,48.5293923919,0.757893185933,230,,Longny les Villages,Longny les Villages,61,Orne,28,Normandie
61230,LONGNY LES VILLAGES,61290,LONGNY LES VILLAGES,LA LANDE SUR EURE,48.5293923919,0.757893185933,230,,Longny les Villages,Longny les Villages,61,Orne,28,Normandie
61230,LONGNY LES VILLAGES,61290,LONGNY LES VILLAGES,MALETABLE,48.5293923919,0.757893185933,230,,Longny les Villages,Longny les Villages,61,Orne,28,Normandie
61230,LONGNY LES VILLAGES,61290,LONGNY LES VILLAGES,MARCHAINVILLE,48.5293923919,0.757893185933,230,,Longny les Villages,Longny les Villages,61,Orne,28,Normandie
61230,LONGNY LES VILLAGES,61290,LONGNY LES VILLAGES,MONCEAUX AU PERCHE,48.5293923919,0.757893185933,230,,Longny les Villages,Longny les Villages,61,Orne,28,Normandie
61230,LONGNY LES VILLAGES,61290,LONGNY LES VILLAGES,MOULICENT,48.5293923919,0.757893185933,230,,Longny les Villages,Longny les Villages,61,Orne,28,Normandie
61230,LONGNY LES VILLAGES,61290,LONGNY LES VILLAGES,NEUILLY SUR EURE,48.5293923919,0.757893185933,230,,Longny les Villages,Longny les Villages,61,Orne,28,Normandie
61230,LONGNY LES VILLAGES,61290,LONGNY LES VILLAGES,ST VICTOR DE RENO,48.5293923919,0.757893185933,230,,Longny les Villages,Longny les Villages,61,Orne,28,Normandie
61231,LONGUENOE,61320,LONGUENOE,,48.5162399583,-0.0655173755231,231,,Longuenoë,Longuenoë,61,Orne,28,Normandie
61232,LONLAY L ABBAYE,61700,LONLAY L ABBAYE,,48.6502297145,-0.704744112776,232,,Lonlay-l'Abbaye,Lonlay-l'Abbaye,61,Orne,28,Normandie
61233,LONLAY LE TESSON,61600,LONLAY LE TESSON,,48.6505142645,-0.34589892324,233,,Lonlay-le-Tesson,Lonlay-le-Tesson,61,Orne,28,Normandie
61234,LONRAI,61250,LONRAI,,48.4532606248,0.0362818528268,234,,Lonrai,Lonrai,61,Orne,28,Normandie
61237,LOUGE SUR MAIRE,61150,LOUGE SUR MAIRE,,48.7010300474,-0.221367554163,237,,Lougé-sur-Maire,Lougé-sur-Maire,61,Orne,28,Normandie
61238,LOUVIERES EN AUGE,61160,LOUVIERES EN AUGE,,48.8691287716,0.0285333540069,238,,Louvières-en-Auge,Louvières-en-Auge,61,Orne,28,Normandie
61240,MACE,61500,MACE,,48.6476898388,0.136870409048,240,,Macé,Macé,61,Orne,28,Normandie
61241,LA MADELEINE BOUVET,61110,LA MADELEINE BOUVET,,48.468815139,0.898781486083,241,La,Madeleine-Bouvet,La Madeleine-Bouvet,61,Orne,28,Normandie
61242,LE MAGE,61290,LE MAGE,,48.5046888094,0.813503394446,242,Le,Mage,Le Mage,61,Orne,28,Normandie
61243,MAGNY LE DESERT,61600,MAGNY LE DESERT,,48.570570213,-0.323140546642,243,,Magny-le-Désert,Magny-le-Désert,61,Orne,28,Normandie
61244,MAHERU,61380,MAHERU,,48.6654929816,0.43898779821,244,,Mahéru,Mahéru,61,Orne,28,Normandie
61248,MANTILLY,61350,MANTILLY,,48.5113113918,-0.813623687288,248,,Mantilly,Mantilly,61,Orne,28,Normandie
61251,MARCHEMAISONS,61170,MARCHEMAISONS,,48.5227109351,0.314713654868,251,,Marchemaisons,Marchemaisons,61,Orne,28,Normandie
61252,MARDILLY,61230,MARDILLY,,48.829136334,0.262656669322,252,,Mardilly,Mardilly,61,Orne,28,Normandie
61255,MAUVES SUR HUISNE,61400,MAUVES SUR HUISNE,,48.4380123635,0.608254382675,255,,Mauves-sur-Huisne,Mauves-sur-Huisne,61,Orne,28,Normandie
61256,MEDAVY,61570,MEDAVY,,48.6730922251,0.0786736803117,256,,Médavy,Médavy,61,Orne,28,Normandie
61257,MEHOUDIN,61410,MEHOUDIN,,48.508806883,-0.381338867818,257,,Méhoudin,Méhoudin,61,Orne,28,Normandie
61258,LE MELE SUR SARTHE,61170,LE MELE SUR SARTHE,,48.5109025611,0.35265245515,258,Le,Mêle-sur-Sarthe,Le Mêle-sur-Sarthe,61,Orne,28,Normandie
61259,LE MENIL BERARD,61270,LE MENIL BERARD,,48.7055430019,0.510723949969,259,Le,Ménil-Bérard,Le Ménil-Bérard,61,Orne,28,Normandie
61260,LE MENIL DE BRIOUZE,61220,LE MENIL DE BRIOUZE,,48.660905496,-0.397537206394,260,Le,Ménil-de-Briouze,Le Ménil-de-Briouze,61,Orne,28,Normandie
61261,LE MENIL BROUT,61250,LE MENIL BROUT,,48.4800081539,0.237910354434,261,Le,Ménil-Broût,Le Ménil-Broût,61,Orne,28,Normandie
61262,LE MENIL CIBOULT,61800,LE MENIL CIBOULT,,48.761641813,-0.791879154627,262,Le,Ménil-Ciboult,Le Ménil-Ciboult,61,Orne,28,Normandie
61263,MENIL ERREUX,61250,MENIL ERREUX,,48.5093937209,0.183751804866,263,,Ménil-Erreux,Ménil-Erreux,61,Orne,28,Normandie
61264,MENIL FROGER,61240,MENIL FROGER,,48.7308500818,0.265343668064,264,,Ménil-Froger,Ménil-Froger,61,Orne,28,Normandie
61265,MENIL GONDOUIN,61210,MENIL GONDOUIN,,48.7541147745,-0.296950238626,265,,Ménil-Gondouin,Ménil-Gondouin,61,Orne,28,Normandie
61266,LE MENIL GUYON,61170,LE MENIL GUYON,,48.5800612899,0.29433804603,266,Le,Ménil-Guyon,Le Ménil-Guyon,61,Orne,28,Normandie
61267,MENIL HERMEI,61210,MENIL HERMEI,,48.8258123332,-0.318533772581,267,,Ménil-Hermei,Ménil-Hermei,61,Orne,28,Normandie
61268,MENIL HUBERT EN EXMES,61230,MENIL HUBERT EN EXMES,,48.8018370315,0.233439468984,268,,Ménil-Hubert-en-Exmes,Ménil-Hubert-en-Exmes,61,Orne,28,Normandie
61269,MENIL HUBERT SUR ORNE,61430,MENIL HUBERT SUR ORNE,,48.8493278134,-0.41582469605,269,,Ménil-Hubert-sur-Orne,Ménil-Hubert-sur-Orne,61,Orne,28,Normandie
61271,LE MENIL SCELLEUR,61320,LE MENIL SCELLEUR,,48.6131652955,-0.124894508969,271,Le,Ménil-Scelleur,Le Ménil-Scelleur,61,Orne,28,Normandie
61272,LE MENIL VICOMTE,61240,LE MENIL VICOMTE,,48.7454400632,0.293574610527,272,Le,Ménil-Vicomte,Le Ménil-Vicomte,61,Orne,28,Normandie
61273,MENIL VIN,61210,MENIL VIN,,48.8495621801,-0.319173633186,273,,Ménil-Vin,Ménil-Vin,61,Orne,28,Normandie
61274,LES MENUS,61290,LES MENUS,,48.5179857136,0.93370273757,274,Les,Menus,Les Menus,61,Orne,28,Normandie
61275,LE MERLERAULT,61240,LE MERLERAULT,,48.7007870105,0.280266472699,275,Le,Merlerault,Le Merlerault,61,Orne,28,Normandie
61276,MERRI,61160,MERRI,,48.8496427055,-0.0480442687765,276,,Merri,Merri,61,Orne,28,Normandie
61277,LA MESNIERE,61560,LA MESNIERE,,48.5217923084,0.434024036779,277,La,Mesnière,La Mesnière,61,Orne,28,Normandie
61278,MESSEI,61440,MESSEI,,48.7140643026,-0.539204262797,278,,Messei,Messei,61,Orne,28,Normandie
61279,MIEUXCE,61250,MIEUXCE,,48.4115548771,-0.00204089099581,279,,Mieuxcé,Mieuxcé,61,Orne,28,Normandie
61281,MONCY,61800,MONCY,,48.8332257317,-0.674553210815,281,,Moncy,Moncy,61,Orne,28,Normandie
61283,MONTABARD,61160,MONTABARD,,48.8111933049,-0.085483255391,283,,Montabard,Montabard,61,Orne,28,Normandie
61284,MONTCHEVREL,61170,MONTCHEVREL,,48.5815747627,0.334452095994,284,,Montchevrel,Montchevrel,61,Orne,28,Normandie
61285,MONTGAROULT,61150,MONTGAROULT,,48.751612807,-0.141264839999,285,,Montgaroult,Montgaroult,61,Orne,28,Normandie
61286,MONTGAUDRY,61360,MONTGAUDRY,,48.409293886,0.397216476746,286,,Montgaudry,Montgaudry,61,Orne,28,Normandie
61287,MONTILLY SUR NOIREAU,61100,MONTILLY SUR NOIREAU,,48.8146701791,-0.5709234596,287,,Montilly-sur-Noireau,Montilly-sur-Noireau,61,Orne,28,Normandie
61288,MONTMERREI,61570,MONTMERREI,,48.6305942263,0.0318720441424,288,,Montmerrei,Montmerrei,61,Orne,28,Normandie
61289,MONT ORMEL,61160,MONT ORMEL,,48.8358294295,0.153055227997,289,,Mont-Ormel,Mont-Ormel,61,Orne,28,Normandie
61290,MONTREUIL AU HOULME,61210,MONTREUIL AU HOULME,,48.6816940367,-0.266477110439,290,,Montreuil-au-Houlme,Montreuil-au-Houlme,61,Orne,28,Normandie
61291,MONTREUIL LA CAMBE,61160,MONTREUIL LA CAMBE,,48.8842457581,0.0335930186845,291,,Montreuil-la-Cambe,Montreuil-la-Cambe,61,Orne,28,Normandie
61292,MONTSECRET CLAIREFOUGERE,61800,MONTSECRET CLAIREFOUGERE,,48.7964115214,-0.677236896006,292,,Montsecret-Clairefougère,Montsecret-Clairefougère,61,Orne,28,Normandie
61292,MONTSECRET CLAIREFOUGERE,61800,MONTSECRET CLAIREFOUGERE,CLAIREFOUGERE,48.7964115214,-0.677236896006,292,,Montsecret-Clairefougère,Montsecret-Clairefougère,61,Orne,28,Normandie
61293,MORTAGNE AU PERCHE,61400,MORTAGNE AU PERCHE,,48.5204467811,0.561388622887,293,,Mortagne-au-Perche,Mortagne-au-Perche,61,Orne,28,Normandie
61294,MORTREE,61570,MORTREE,,48.6384870468,0.0801583053247,294,,Mortrée,Mortrée,61,Orne,28,Normandie
61295,LA MOTTE FOUQUET,61600,LA MOTTE FOUQUET,,48.5665252218,-0.268780930667,295,La,Motte-Fouquet,La Motte-Fouquet,61,Orne,28,Normandie
61297,MOULINS LA MARCHE,61380,MOULINS LA MARCHE,,48.6502807278,0.47464312921,297,,Moulins-la-Marche,Moulins-la-Marche,61,Orne,28,Normandie
61298,MOULINS SUR ORNE,61200,MOULINS SUR ORNE,,48.7533220868,-0.0653769966336,298,,Moulins-sur-Orne,Moulins-sur-Orne,61,Orne,28,Normandie
61299,MOUSSONVILLIERS,61190,MOUSSONVILLIERS,,48.6386660452,0.7994065464,299,,Moussonvilliers,Moussonvilliers,61,Orne,28,Normandie
61300,MOUTIERS AU PERCHE,61110,MOUTIERS AU PERCHE,,48.4831821035,0.856650987166,300,,Moutiers-au-Perche,Moutiers-au-Perche,61,Orne,28,Normandie
61301,NEAUPHE SOUS ESSAI,61500,NEAUPHE SOUS ESSAI,,48.5638348673,0.194252543746,301,,Neauphe-sous-Essai,Neauphe-sous-Essai,61,Orne,28,Normandie
61302,NEAUPHE SUR DIVE,61160,NEAUPHE SUR DIVE,,48.8515075057,0.0956108929382,302,,Neauphe-sur-Dive,Neauphe-sur-Dive,61,Orne,28,Normandie
61303,NECY,61160,NECY,,48.8284800153,-0.117856246285,303,,Nécy,Nécy,61,Orne,28,Normandie
61304,NEUILLY LE BISSON,61250,NEUILLY LE BISSON,,48.5043524254,0.219675874132,304,,Neuilly-le-Bisson,Neuilly-le-Bisson,61,Orne,28,Normandie
61307,NEUVILLE SUR TOUQUES,61120,NEUVILLE SUR TOUQUES,,48.862323733,0.280747613281,307,,Neuville-sur-Touques,Neuville-sur-Touques,61,Orne,28,Normandie
61308,NEUVY AU HOULME,61210,NEUVY AU HOULME,,48.821735051,-0.190259929005,308,,Neuvy-au-Houlme,Neuvy-au-Houlme,61,Orne,28,Normandie
61309,PERCHE EN NOCE,61340,PERCHE EN NOCE,COLONARD CORUBERT,48.3808259058,0.686799351677,309,,Perche en Nocé,Perche en Nocé,61,Orne,28,Normandie
61309,PERCHE EN NOCE,61340,PERCHE EN NOCE,DANCE,48.3808259058,0.686799351677,309,,Perche en Nocé,Perche en Nocé,61,Orne,28,Normandie
61309,PERCHE EN NOCE,61340,PERCHE EN NOCE,NOCE,48.3808259058,0.686799351677,309,,Perche en Nocé,Perche en Nocé,61,Orne,28,Normandie
61309,PERCHE EN NOCE,61340,PERCHE EN NOCE,PREAUX DU PERCHE,48.3808259058,0.686799351677,309,,Perche en Nocé,Perche en Nocé,61,Orne,28,Normandie
61309,PERCHE EN NOCE,61340,PERCHE EN NOCE,ST AUBIN DES GROIS,48.3808259058,0.686799351677,309,,Perche en Nocé,Perche en Nocé,61,Orne,28,Normandie
61309,PERCHE EN NOCE,61340,PERCHE EN NOCE,ST JEAN DE LA FORET,48.3808259058,0.686799351677,309,,Perche en Nocé,Perche en Nocé,61,Orne,28,Normandie
61310,NONANT LE PIN,61240,NONANT LE PIN,,48.7031233421,0.207290973767,310,,Nonant-le-Pin,Nonant-le-Pin,61,Orne,28,Normandie
61311,NORMANDEL,61190,NORMANDEL,,48.6507246602,0.7216861032,311,,Normandel,Normandel,61,Orne,28,Normandie
61314,OCCAGNES,61200,OCCAGNES,,48.7813226676,-0.0658264012764,314,,Occagnes,Occagnes,61,Orne,28,Normandie
61315,OMMEEL,61160,OMMEEL,,48.8044111779,0.15206518925,315,,Omméel,Omméel,61,Orne,28,Normandie
61316,OMMOY,61160,OMMOY,,48.8545702425,-0.0238115124899,316,,Ommoy,Ommoy,61,Orne,28,Normandie
61317,ORGERES,61230,ORGERES,,48.752506479,0.3456805156,317,,Orgères,Orgères,61,Orne,28,Normandie
61318,ORIGNY LE BUTIN,61130,ORIGNY LE BUTIN,,48.3789608192,0.473135967271,318,,Origny-le-Butin,Origny-le-Butin,61,Orne,28,Normandie
61319,ORIGNY LE ROUX,61130,ORIGNY LE ROUX,,48.3345893006,0.417585339398,319,,Origny-le-Roux,Origny-le-Roux,61,Orne,28,Normandie
61321,PACE,61250,PACE,,48.4457253693,-0.00548884652011,321,,Pacé,Pacé,61,Orne,28,Normandie
61322,PARFONDEVAL,61400,PARFONDEVAL,,48.4800875172,0.506050858158,322,,Parfondeval,Parfondeval,61,Orne,28,Normandie
61323,LE PAS ST L HOMER,61290,LE PAS ST L HOMER,,48.4935203875,0.928175992686,323,Le,Pas-Saint-l'Homer,Le Pas-Saint-l'Homer,61,Orne,28,Normandie
61324,PASSAIS VILLAGES,61350,PASSAIS VILLAGES,,48.5142650643,-0.759546926567,324,,Passais Villages,Passais Villages,61,Orne,28,Normandie
61324,PASSAIS VILLAGES,61350,PASSAIS VILLAGES,L EPINAY LE COMTE,48.5142650643,-0.759546926567,324,,Passais Villages,Passais Villages,61,Orne,28,Normandie
61324,PASSAIS VILLAGES,61350,PASSAIS VILLAGES,ST SIMEON,48.5142650643,-0.759546926567,324,,Passais Villages,Passais Villages,61,Orne,28,Normandie
61325,LA PERRIERE,61360,LA PERRIERE,,48.3977372341,0.441431665682,325,La,Perrière,La Perrière,61,Orne,28,Normandie
61326,PERROU,61700,PERROU,,48.5760698377,-0.548505109021,326,,Perrou,Perrou,61,Orne,28,Normandie
61327,PERVENCHERES,61360,PERVENCHERES,,48.4439121887,0.411023261659,327,,Pervenchères,Pervenchères,61,Orne,28,Normandie
61328,LE PIN AU HARAS,61310,LE PIN AU HARAS,,48.7473548359,0.136994750389,328,Le,Pin-au-Haras,Le Pin-au-Haras,61,Orne,28,Normandie
61329,LE PIN LA GARENNE,61400,LE PIN LA GARENNE,,48.4445657379,0.544883378197,329,Le,Pin-la-Garenne,Le Pin-la-Garenne,61,Orne,28,Normandie
61330,PLANCHES,61370,PLANCHES,,48.6898504724,0.377530769995,330,,Planches,Planches,61,Orne,28,Normandie
61331,LE PLANTIS,61170,LE PLANTIS,,48.6037546825,0.397038776409,331,Le,Plantis,Le Plantis,61,Orne,28,Normandie
61332,POINTEL,61220,POINTEL,,48.6945257579,-0.348493610503,332,,Pointel,Pointel,61,Orne,28,Normandie
61333,PONTCHARDON,61120,PONTCHARDON,,48.9301943035,0.262438401104,333,,Pontchardon,Pontchardon,61,Orne,28,Normandie
61336,POUVRAI,61130,POUVRAI,,48.2795939755,0.518958565692,336,,Pouvrai,Pouvrai,61,Orne,28,Normandie
61339,PUTANGES LE LAC,61210,PUTANGES LE LAC,CHENEDOUIT,48.766449668,-0.252215702706,339,,Putanges-le-Lac,Putanges-le-Lac,61,Orne,28,Normandie
61339,PUTANGES LE LAC,61210,PUTANGES LE LAC,LA FORET AUVRAY,48.766449668,-0.252215702706,339,,Putanges-le-Lac,Putanges-le-Lac,61,Orne,28,Normandie
61339,PUTANGES LE LAC,61210,PUTANGES LE LAC,LA FRESNAYE AU SAUVAGE,48.766449668,-0.252215702706,339,,Putanges-le-Lac,Putanges-le-Lac,61,Orne,28,Normandie
61339,PUTANGES LE LAC,61210,PUTANGES LE LAC,LES ROTOURS,48.766449668,-0.252215702706,339,,Putanges-le-Lac,Putanges-le-Lac,61,Orne,28,Normandie
61339,PUTANGES LE LAC,61210,PUTANGES LE LAC,MENIL JEAN,48.766449668,-0.252215702706,339,,Putanges-le-Lac,Putanges-le-Lac,61,Orne,28,Normandie
61339,PUTANGES LE LAC,61210,PUTANGES LE LAC,PUTANGES PONT ECREPIN,48.766449668,-0.252215702706,339,,Putanges-le-Lac,Putanges-le-Lac,61,Orne,28,Normandie
61339,PUTANGES LE LAC,61210,PUTANGES LE LAC,RABODANGES,48.766449668,-0.252215702706,339,,Putanges-le-Lac,Putanges-le-Lac,61,Orne,28,Normandie
61339,PUTANGES LE LAC,61210,PUTANGES LE LAC,ST AUBERT SUR ORNE,48.766449668,-0.252215702706,339,,Putanges-le-Lac,Putanges-le-Lac,61,Orne,28,Normandie
61339,PUTANGES LE LAC,61210,PUTANGES LE LAC,STE CROIX SUR ORNE,48.766449668,-0.252215702706,339,,Putanges-le-Lac,Putanges-le-Lac,61,Orne,28,Normandie
61341,ECOUVES,61250,ECOUVES,FORGES,48.5089267357,0.0863965878338,341,,Écouves,Écouves,61,Orne,28,Normandie
61341,ECOUVES,61250,ECOUVES,RADON,48.5089267357,0.0863965878338,341,,Écouves,Écouves,61,Orne,28,Normandie
61341,ECOUVES,61250,ECOUVES,VINGT HANAPS,48.5089267357,0.0863965878338,341,,Écouves,Écouves,61,Orne,28,Normandie
61342,RAI,61270,RAI,,48.7559861003,0.562809659715,342,,Rai,Rai,61,Orne,28,Normandie
61344,RANES,61150,RANES,,48.6436675698,-0.217658803697,344,,Rânes,Rânes,61,Orne,28,Normandie
61345,REMALARD EN PERCHE,61110,REMALARD EN PERCHE,,48.4498027895,0.77912800072,345,,Rémalard en Perche,Rémalard en Perche,61,Orne,28,Normandie
61345,REMALARD EN PERCHE,61110,REMALARD EN PERCHE,BELLOU SUR HUISNE,48.4498027895,0.77912800072,345,,Rémalard en Perche,Rémalard en Perche,61,Orne,28,Normandie
61345,REMALARD EN PERCHE,61110,REMALARD EN PERCHE,DORCEAU,48.4498027895,0.77912800072,345,,Rémalard en Perche,Rémalard en Perche,61,Orne,28,Normandie
61346,LE RENOUARD,61120,LE RENOUARD,,48.9194110342,0.0988609548607,346,Le,Renouard,Le Renouard,61,Orne,28,Normandie
61347,RESENLIEU,61230,RESENLIEU,,48.7900887821,0.276930426076,347,,Résenlieu,Résenlieu,61,Orne,28,Normandie
61348,REVEILLON,61400,REVEILLON,,48.4806705534,0.56261549181,348,,Réveillon,Réveillon,61,Orne,28,Normandie
61349,RI,61210,RI,,48.788213656,-0.13879185478,349,,Ri,Ri,61,Orne,28,Normandie
61350,LA ROCHE MABILE,61420,LA ROCHE MABILE,,48.489577366,-0.0663745074632,350,La,Roche-Mabile,La Roche-Mabile,61,Orne,28,Normandie
61351,ROIVILLE,61120,ROIVILLE,,48.8801395888,0.236531994875,351,,Roiville,Roiville,61,Orne,28,Normandie
61352,RONAI,61160,RONAI,,48.8104980056,-0.135238062061,352,,Rônai,Rônai,61,Orne,28,Normandie
61357,ROUPERROUX,61320,ROUPERROUX,,48.5486223106,-0.0783070425264,357,,Rouperroux,Rouperroux,61,Orne,28,Normandie
61358,SAI,61200,SAI,,48.7407667641,0.0235996359828,358,,Sai,Sai,61,Orne,28,Normandie
61360,ST AGNAN SUR SARTHE,61170,ST AGNAN SUR SARTHE,,48.6219458943,0.428936207107,360,,Saint-Agnan-sur-Sarthe,Saint-Agnan-sur-Sarthe,61,Orne,28,Normandie
61361,ST ANDRE DE BRIOUZE,61220,ST ANDRE DE BRIOUZE,,48.7338760322,-0.327257845989,361,,Saint-André-de-Briouze,Saint-André-de-Briouze,61,Orne,28,Normandie
61362,ST ANDRE DE MESSEI,61440,ST ANDRE DE MESSEI,,48.6883800574,-0.521932078822,362,,Saint-André-de-Messei,Saint-André-de-Messei,61,Orne,28,Normandie
61363,ST AQUILIN DE CORBION,61380,ST AQUILIN DE CORBION,,48.6410212867,0.527395250129,363,,Saint-Aquilin-de-Corbion,Saint-Aquilin-de-Corbion,61,Orne,28,Normandie
61365,ST AUBIN D APPENAI,61170,ST AUBIN D APPENAI,,48.5420210884,0.33805985698,365,,Saint-Aubin-d'Appenai,Saint-Aubin-d'Appenai,61,Orne,28,Normandie
61366,ST AUBIN DE BONNEVAL,61470,ST AUBIN DE BONNEVAL,,48.9491057689,0.371524010487,366,,Saint-Aubin-de-Bonneval,Saint-Aubin-de-Bonneval,61,Orne,28,Normandie
61367,ST AUBIN DE COURTERAIE,61560,ST AUBIN DE COURTERAIE,,48.6087873617,0.452912112698,367,,Saint-Aubin-de-Courteraie,Saint-Aubin-de-Courteraie,61,Orne,28,Normandie
61369,ST BOMER LES FORGES,61700,ST BOMER LES FORGES,,48.6448585107,-0.622820262542,369,,Saint-Bômer-les-Forges,Saint-Bômer-les-Forges,61,Orne,28,Normandie
61370,ST BRICE,61700,ST BRICE,,48.5498503299,-0.641601711695,370,,Saint-Brice,Saint-Brice,61,Orne,28,Normandie
61371,ST BRICE SOUS RANES,61150,ST BRICE SOUS RANES,,48.6784450821,-0.19335744417,371,,Saint-Brice-sous-Rânes,Saint-Brice-sous-Rânes,61,Orne,28,Normandie
61372,ST CENERI LE GEREI,61250,ST CENERI LE GEREI,,48.389040252,-0.0400378632747,372,,Saint-Céneri-le-Gérei,Saint-Céneri-le-Gérei,61,Orne,28,Normandie
61373,STE CERONNE LES MORTAGNE,61380,STE CERONNE LES MORTAGNE,,48.5864346163,0.525562208998,373,,Sainte-Céronne-lès-Mortagne,Sainte-Céronne-lès-Mortagne,61,Orne,28,Normandie
61374,ST CHRISTOPHE DE CHAULIEU,61800,ST CHRISTOPHE DE CHAULIEU,,48.7424101342,-0.83155426075,374,,Saint-Christophe-de-Chaulieu,Saint-Christophe-de-Chaulieu,61,Orne,28,Normandie
61375,BOISCHAMPRE,61570,BOISCHAMPRE,,48.656618574,0.00188165114275,375,,Boischampré,Boischampré,61,Orne,28,Normandie
61375,BOISCHAMPRE,61570,BOISCHAMPRE,MARCEI,48.656618574,0.00188165114275,375,,Boischampré,Boischampré,61,Orne,28,Normandie
61375,BOISCHAMPRE,61570,BOISCHAMPRE,ST LOYER DES CHAMPS,48.656618574,0.00188165114275,375,,Boischampré,Boischampré,61,Orne,28,Normandie
61375,BOISCHAMPRE,61570,BOISCHAMPRE,VRIGNY,48.656618574,0.00188165114275,375,,Boischampré,Boischampré,61,Orne,28,Normandie
61376,ST CLAIR DE HALOUZE,61490,ST CLAIR DE HALOUZE,,48.6891169765,-0.626025775908,376,,Saint-Clair-de-Halouze,Saint-Clair-de-Halouze,61,Orne,28,Normandie
61379,ST CYR LA ROSIERE,61130,ST CYR LA ROSIERE,,48.3206311258,0.642420415109,379,,Saint-Cyr-la-Rosière,Saint-Cyr-la-Rosière,61,Orne,28,Normandie
61381,ST DENIS SUR HUISNE,61400,ST DENIS SUR HUISNE,,48.4707648584,0.531647869938,381,,Saint-Denis-sur-Huisne,Saint-Denis-sur-Huisne,61,Orne,28,Normandie
61382,ST DENIS SUR SARTHON,61420,ST DENIS SUR SARTHON,,48.4579481796,-0.0372277848804,382,,Saint-Denis-sur-Sarthon,Saint-Denis-sur-Sarthon,61,Orne,28,Normandie
61383,ST DIDIER SOUS ECOUVES,61320,ST DIDIER SOUS ECOUVES,,48.5411496414,-0.0403584271863,383,,Saint-Didier-sous-Écouves,Saint-Didier-sous-Écouves,61,Orne,28,Normandie
61384,ST ELLIER LES BOIS,61320,ST ELLIER LES BOIS,,48.5239551221,-0.0984963605984,384,,Saint-Ellier-les-Bois,Saint-Ellier-les-Bois,61,Orne,28,Normandie
61385,ST EVROULT DE MONTFORT,61230,ST EVROULT DE MONTFORT,,48.8122960586,0.312765377399,385,,Saint-Evroult-de-Montfort,Saint-Evroult-de-Montfort,61,Orne,28,Normandie
61386,ST EVROULT NOTRE DAME DU BOIS,61550,ST EVROULT NOTRE DAME DU BOIS,,48.7791005321,0.452488554274,386,,Saint-Evroult-Notre-Dame-du-Bois,Saint-Evroult-Notre-Dame-du-Bois,61,Orne,28,Normandie
61387,ST FRAIMBAULT,61350,ST FRAIMBAULT,,48.4987485682,-0.711543508094,387,,Saint-Fraimbault,Saint-Fraimbault,61,Orne,28,Normandie
61388,ST FULGENT DES ORMES,61130,ST FULGENT DES ORMES,,48.3204406534,0.453059277552,388,,Saint-Fulgent-des-Ormes,Saint-Fulgent-des-Ormes,61,Orne,28,Normandie
61389,STE GAUBURGE STE COLOMBE,61370,STE GAUBURGE STE COLOMBE,,48.7078395565,0.444533266372,389,,Sainte-Gauburge-Sainte-Colombe,Sainte-Gauburge-Sainte-Colombe,61,Orne,28,Normandie
61390,ST GEORGES D ANNEBECQ,61600,ST GEORGES D ANNEBECQ,,48.6307948842,-0.26545917977,390,,Saint-Georges-d'Annebecq,Saint-Georges-d'Annebecq,61,Orne,28,Normandie
61391,ST GEORGES DES GROSEILLERS,61100,ST GEORGES DES GROSEILLERS,,48.7672046691,-0.568075544032,391,,Saint-Georges-des-Groseillers,Saint-Georges-des-Groseillers,61,Orne,28,Normandie
61392,ST GERMAIN D AUNAY,61470,ST GERMAIN D AUNAY,,48.9290248327,0.381614351113,392,,Saint-Germain-d'Aunay,Saint-Germain-d'Aunay,61,Orne,28,Normandie
61393,ST GERMAIN DE CLAIREFEUILLE,61240,ST GERMAIN DE CLAIREFEUILLE,,48.7256386551,0.24088318968,393,,Saint-Germain-de-Clairefeuille,Saint-Germain-de-Clairefeuille,61,Orne,28,Normandie
61394,ST GERMAIN DE LA COUDRE,61130,ST GERMAIN DE LA COUDRE,,48.2717389911,0.591626706972,394,,Saint-Germain-de-la-Coudre,Saint-Germain-de-la-Coudre,61,Orne,28,Normandie
61395,ST GERMAIN DES GROIS,61110,ST GERMAIN DES GROIS,,48.4049147624,0.815631940601,395,,Saint-Germain-des-Grois,Saint-Germain-des-Grois,61,Orne,28,Normandie
61396,ST GERMAIN DE MARTIGNY,61560,ST GERMAIN DE MARTIGNY,,48.5873936203,0.467132104726,396,,Saint-Germain-de-Martigny,Saint-Germain-de-Martigny,61,Orne,28,Normandie
61397,ST GERMAIN DU CORBEIS,61000,ST GERMAIN DU CORBEIS,,48.4177117997,0.0580112631913,397,,Saint-Germain-du-Corbéis,Saint-Germain-du-Corbéis,61,Orne,28,Normandie
61398,ST GERMAIN LE VIEUX,61390,ST GERMAIN LE VIEUX,,48.6106053248,0.311416198264,398,,Saint-Germain-le-Vieux,Saint-Germain-le-Vieux,61,Orne,28,Normandie
61399,ST GERVAIS DES SABLONS,61160,ST GERVAIS DES SABLONS,,48.9014613359,0.0731396137637,399,,Saint-Gervais-des-Sablons,Saint-Gervais-des-Sablons,61,Orne,28,Normandie
61400,ST GERVAIS DU PERRON,61500,ST GERVAIS DU PERRON,,48.5368344624,0.150158348014,400,,Saint-Gervais-du-Perron,Saint-Gervais-du-Perron,61,Orne,28,Normandie
61401,ST GILLES DES MARAIS,61700,ST GILLES DES MARAIS,,48.5850326416,-0.698007308747,401,,Saint-Gilles-des-Marais,Saint-Gilles-des-Marais,61,Orne,28,Normandie
61402,ST HILAIRE DE BRIOUZE,61220,ST HILAIRE DE BRIOUZE,,48.7072968061,-0.30765966543,402,,Saint-Hilaire-de-Briouze,Saint-Hilaire-de-Briouze,61,Orne,28,Normandie
61403,ST HILAIRE LA GERARD,61500,ST HILAIRE LA GERARD,,48.5945563992,0.0496896989442,403,,Saint-Hilaire-la-Gérard,Saint-Hilaire-la-Gérard,61,Orne,28,Normandie
61404,ST HILAIRE LE CHATEL,61400,ST HILAIRE LE CHATEL,,48.5551273159,0.540291950034,404,,Saint-Hilaire-le-Châtel,Saint-Hilaire-le-Châtel,61,Orne,28,Normandie
61405,ST HILAIRE SUR ERRE,61340,ST HILAIRE SUR ERRE,,48.3121656511,0.745828480936,405,,Saint-Hilaire-sur-Erre,Saint-Hilaire-sur-Erre,61,Orne,28,Normandie
61406,ST HILAIRE SUR RISLE,61270,ST HILAIRE SUR RISLE,,48.7245700882,0.507831900424,406,,Saint-Hilaire-sur-Risle,Saint-Hilaire-sur-Risle,61,Orne,28,Normandie
61407,STE HONORINE LA CHARDONNE,61430,STE HONORINE LA CHARDONNE,,48.8247345941,-0.476136278421,407,,Sainte-Honorine-la-Chardonne,Sainte-Honorine-la-Chardonne,61,Orne,28,Normandie
61408,STE HONORINE LA GUILLAUME,61210,STE HONORINE LA GUILLAUME,,48.7774185998,-0.381537556924,408,,Sainte-Honorine-la-Guillaume,Sainte-Honorine-la-Guillaume,61,Orne,28,Normandie
61411,ST JOUIN DE BLAVOU,61360,ST JOUIN DE BLAVOU,,48.4486820251,0.4823333383,411,,Saint-Jouin-de-Blavou,Saint-Jouin-de-Blavou,61,Orne,28,Normandie
61412,ST JULIEN SUR SARTHE,61170,ST JULIEN SUR SARTHE,,48.4899708762,0.37342953932,412,,Saint-Julien-sur-Sarthe,Saint-Julien-sur-Sarthe,61,Orne,28,Normandie
61413,ST LAMBERT SUR DIVE,61160,ST LAMBERT SUR DIVE,,48.8296369621,0.0905725341208,413,,Saint-Lambert-sur-Dive,Saint-Lambert-sur-Dive,61,Orne,28,Normandie
61414,ST LANGIS LES MORTAGNE,61400,ST LANGIS LES MORTAGNE,,48.5098568869,0.531729896693,414,,Saint-Langis-lès-Mortagne,Saint-Langis-lès-Mortagne,61,Orne,28,Normandie
61415,ST LEGER SUR SARTHE,61170,ST LEGER SUR SARTHE,,48.495044339,0.316782029384,415,,Saint-Léger-sur-Sarthe,Saint-Léger-sur-Sarthe,61,Orne,28,Normandie
61416,ST LEONARD DES PARCS,61390,ST LEONARD DES PARCS,,48.6460340258,0.276826106353,416,,Saint-Léonard-des-Parcs,Saint-Léonard-des-Parcs,61,Orne,28,Normandie
61418,ST MARD DE RENO,61400,ST MARD DE RENO,,48.5102214225,0.634185094113,418,,Saint-Mard-de-Réno,Saint-Mard-de-Réno,61,Orne,28,Normandie
61419,STE MARGUERITE DE CARROUGES,61320,STE MARGUERITE DE CARROUGES,,48.5937651303,-0.151429689315,419,,Sainte-Marguerite-de-Carrouges,Sainte-Marguerite-de-Carrouges,61,Orne,28,Normandie
61420,STE MARIE LA ROBERT,61320,STE MARIE LA ROBERT,,48.6261229757,-0.143899474106,420,,Sainte-Marie-la-Robert,Sainte-Marie-la-Robert,61,Orne,28,Normandie
61421,ST MARS D EGRENNE,61350,ST MARS D EGRENNE,,48.5513540605,-0.733712113871,421,,Saint-Mars-d'Égrenne,Saint-Mars-d'Égrenne,61,Orne,28,Normandie
61422,LES ASPRES,61270,LES ASPRES,,48.6753438359,0.610110315479,422,Les,Aspres,Les Aspres,61,Orne,28,Normandie
61423,ST MARTIN D ECUBLEI,61300,ST MARTIN D ECUBLEI,,48.7949779285,0.669166067882,423,,Saint-Martin-d'Écublei,Saint-Martin-d'Écublei,61,Orne,28,Normandie
61424,ST MARTIN DES LANDES,61320,ST MARTIN DES LANDES,,48.546237837,-0.156413785136,424,,Saint-Martin-des-Landes,Saint-Martin-des-Landes,61,Orne,28,Normandie
61425,ST MARTIN DES PEZERITS,61380,ST MARTIN DES PEZERITS,,48.627183396,0.489313798529,425,,Saint-Martin-des-Pézerits,Saint-Martin-des-Pézerits,61,Orne,28,Normandie
61426,ST MARTIN DU VIEUX BELLEME,61130,ST MARTIN DU VIEUX BELLEME,,48.3722492499,0.54715865547,426,,Saint-Martin-du-Vieux-Bellême,Saint-Martin-du-Vieux-Bellême,61,Orne,28,Normandie
61427,ST MARTIN L AIGUILLON,61320,ST MARTIN L AIGUILLON,,48.6028213138,-0.17990137206,427,,Saint-Martin-l'Aiguillon,Saint-Martin-l'Aiguillon,61,Orne,28,Normandie
61429,ST MAURICE LES CHARENCEY,61190,ST MAURICE LES CHARENCEY,,48.6391173168,0.752904500205,429,,Charencey,Charencey,61,Orne,28,Normandie
61432,ST MICHEL TUBOEUF,61300,ST MICHEL TUBOEUF,,48.7538911269,0.686562165438,432,,Saint-Michel-Tubœuf,Saint-Michel-Tubœuf,61,Orne,28,Normandie
61433,ST NICOLAS DES BOIS,61250,ST NICOLAS DES BOIS,,48.5061778998,0.0266355295781,433,,Saint-Nicolas-des-Bois,Saint-Nicolas-des-Bois,61,Orne,28,Normandie
61435,ST NICOLAS DE SOMMAIRE,61550,ST NICOLAS DE SOMMAIRE,,48.8124271962,0.597978267366,435,,Saint-Nicolas-de-Sommaire,Saint-Nicolas-de-Sommaire,61,Orne,28,Normandie
61436,STE OPPORTUNE,61100,STE OPPORTUNE,,48.7360455773,-0.411243624023,436,,Sainte-Opportune,Sainte-Opportune,61,Orne,28,Normandie
61437,ST OUEN DE LA COUR,61130,ST OUEN DE LA COUR,,48.4117385044,0.587184301404,437,,Saint-Ouen-de-la-Cour,Saint-Ouen-de-la-Cour,61,Orne,28,Normandie
61438,ST OUEN DE SECHEROUVRE,61560,ST OUEN DE SECHEROUVRE,,48.5987945928,0.492195334785,438,,Saint-Ouen-de-Sécherouvre,Saint-Ouen-de-Sécherouvre,61,Orne,28,Normandie
61439,ST OUEN LE BRISOULT,61410,ST OUEN LE BRISOULT,,48.5091783781,-0.350727640894,439,,Saint-Ouen-le-Brisoult,Saint-Ouen-le-Brisoult,61,Orne,28,Normandie
61440,ST OUEN SUR ITON,61300,ST OUEN SUR ITON,,48.7356894467,0.671333439138,440,,Saint-Ouen-sur-Iton,Saint-Ouen-sur-Iton,61,Orne,28,Normandie
61442,ST PATRICE DU DESERT,61600,ST PATRICE DU DESERT,,48.5343425524,-0.285812215846,442,,Saint-Patrice-du-Désert,Saint-Patrice-du-Désert,61,Orne,28,Normandie
61443,ST PAUL,61100,ST PAUL,,48.7391706962,-0.63036026972,443,,Saint-Paul,Saint-Paul,61,Orne,28,Normandie
61444,ST PHILBERT SUR ORNE,61430,ST PHILBERT SUR ORNE,,48.8347611836,-0.375940618055,444,,Saint-Philbert-sur-Orne,Saint-Philbert-sur-Orne,61,Orne,28,Normandie
61445,ST PIERRE D ENTREMONT,61800,ST PIERRE D ENTREMONT,,48.8117202926,-0.649709169308,445,,Saint-Pierre-d'Entremont,Saint-Pierre-d'Entremont,61,Orne,28,Normandie
61446,ST PIERRE DES LOGES,61370,ST PIERRE DES LOGES,,48.7431643088,0.466256113036,446,,Saint-Pierre-des-Loges,Saint-Pierre-des-Loges,61,Orne,28,Normandie
61447,ST PIERRE DU REGARD,61790,ST PIERRE DU REGARD,,48.8338445612,-0.541002383662,447,,Saint-Pierre-du-Regard,Saint-Pierre-du-Regard,61,Orne,28,Normandie
61448,ST PIERRE LA BRUYERE,61340,ST PIERRE LA BRUYERE,,48.35901525,0.799156524403,448,,Saint-Pierre-la-Bruyère,Saint-Pierre-la-Bruyère,61,Orne,28,Normandie
61449,ST PIERRE LA RIVIERE,61310,ST PIERRE LA RIVIERE,,48.815421921,0.186207380362,449,,Saint-Pierre-la-Rivière,Saint-Pierre-la-Rivière,61,Orne,28,Normandie
61450,ST QUENTIN DE BLAVOU,61360,ST QUENTIN DE BLAVOU,,48.4792330263,0.418725497105,450,,Saint-Quentin-de-Blavou,Saint-Quentin-de-Blavou,61,Orne,28,Normandie
61451,ST QUENTIN LES CHARDONNETS,61800,ST QUENTIN LES CHARDONNETS,,48.7827074381,-0.75146556661,451,,Saint-Quentin-les-Chardonnets,Saint-Quentin-les-Chardonnets,61,Orne,28,Normandie
61452,ST ROCH SUR EGRENNE,61350,ST ROCH SUR EGRENNE,,48.5760363897,-0.744740516087,452,,Saint-Roch-sur-Égrenne,Saint-Roch-sur-Égrenne,61,Orne,28,Normandie
61453,ST SAUVEUR DE CARROUGES,61320,ST SAUVEUR DE CARROUGES,,48.589499718,-0.109356192251,453,,Saint-Sauveur-de-Carrouges,Saint-Sauveur-de-Carrouges,61,Orne,28,Normandie
61454,STE SCOLASSE SUR SARTHE,61170,STE SCOLASSE SUR SARTHE,,48.5810388449,0.385128508121,454,,Sainte-Scolasse-sur-Sarthe,Sainte-Scolasse-sur-Sarthe,61,Orne,28,Normandie
61456,ST SULPICE SUR RISLE,61300,ST SULPICE SUR RISLE,,48.776305281,0.671403390128,456,,Saint-Sulpice-sur-Risle,Saint-Sulpice-sur-Risle,61,Orne,28,Normandie
61457,ST SYMPHORIEN DES BRUYERES,61300,ST SYMPHORIEN DES BRUYERES,,48.7864528297,0.557396047758,457,,Saint-Symphorien-des-Bruyères,Saint-Symphorien-des-Bruyères,61,Orne,28,Normandie
61459,SAIRES LA VERRERIE,61220,SAIRES LA VERRERIE,,48.6896183653,-0.4875928451,459,,Saires-la-Verrerie,Saires-la-Verrerie,61,Orne,28,Normandie
61460,SAP EN AUGE,61120,SAP EN AUGE,ORVILLE,48.8881688087,0.338970200397,460,,Sap-en-Auge,Sap-en-Auge,61,Orne,28,Normandie
61460,SAP EN AUGE,61470,SAP EN AUGE,LE SAP,48.8881688087,0.338970200397,460,,Sap-en-Auge,Sap-en-Auge,61,Orne,28,Normandie
61461,LE SAP ANDRE,61230,LE SAP ANDRE,,48.8285699503,0.390076945635,461,Le,Sap-André,Le Sap-André,61,Orne,28,Normandie
61462,SARCEAUX,61200,SARCEAUX,,48.7158736886,-0.0346146728609,462,,Sarceaux,Sarceaux,61,Orne,28,Normandie
61463,LES MONTS D ANDAINE,61600,LES MONTS D ANDAINE,LA SAUVAGERE,48.6127467342,-0.435491688539,463,Les,Monts d'Andaine,Les Monts d'Andaine,61,Orne,28,Normandie
61463,LES MONTS D ANDAINE,61600,LES MONTS D ANDAINE,ST MAURICE DU DESERT,48.6127467342,-0.435491688539,463,Les,Monts d'Andaine,Les Monts d'Andaine,61,Orne,28,Normandie
61464,SEES,61500,SEES,,48.6048296774,0.169026415284,464,,Sées,Sées,61,Orne,28,Normandie
61466,LA SELLE LA FORGE,61100,LA SELLE LA FORGE,,48.7354177799,-0.53278023694,466,La,Selle-la-Forge,La Selle-la-Forge,61,Orne,28,Normandie
61467,SEMALLE,61250,SEMALLE,,48.4735348664,0.15478366717,467,,Semallé,Semallé,61,Orne,28,Normandie
61468,SENTILLY,61150,SENTILLY,,48.7670202732,-0.12073239352,468,,Sentilly,Sentilly,61,Orne,28,Normandie
61471,SERIGNY,61130,SERIGNY,,48.3772241823,0.590152636294,471,,Sérigny,Sérigny,61,Orne,28,Normandie
61472,SEVIGNY,61200,SEVIGNY,,48.7731316921,-0.0198186687078,472,,Sévigny,Sévigny,61,Orne,28,Normandie
61473,SEVRAI,61150,SEVRAI,,48.7048584232,-0.151545774151,473,,Sevrai,Sevrai,61,Orne,28,Normandie
61474,SILLY EN GOUFFERN,61310,SILLY EN GOUFFERN,,48.7516893837,0.0651597392441,474,,Gouffern en Auge,Gouffern en Auge,61,Orne,28,Normandie
61475,SOLIGNY LA TRAPPE,61380,SOLIGNY LA TRAPPE,,48.6272197858,0.549235029616,475,,Soligny-la-Trappe,Soligny-la-Trappe,61,Orne,28,Normandie
61476,SURE,61360,SURE,,48.3689914648,0.406327878358,476,,Suré,Suré,61,Orne,28,Normandie
61477,SURVIE,61310,SURVIE,,48.84043361,0.188966675929,477,,Survie,Survie,61,Orne,28,Normandie
61479,TANQUES,61150,TANQUES,,48.6863581551,-0.0741132458246,479,,Tanques,Tanques,61,Orne,28,Normandie
61480,TANVILLE,61500,TANVILLE,,48.5653374491,0.00940180044497,480,,Tanville,Tanville,61,Orne,28,Normandie
61481,TELLIERES LE PLESSIS,61390,TELLIERES LE PLESSIS,,48.6212310332,0.393995256892,481,,Tellières-le-Plessis,Tellières-le-Plessis,61,Orne,28,Normandie
61482,TESSE FROULAY,61410,TESSE FROULAY,,48.5310517444,-0.428931253176,482,,Tessé-Froulay,Tessé-Froulay,61,Orne,28,Normandie
61483,BAGNOLES DE L ORNE NORMANDIE,61140,BAGNOLES DE L ORNE NORMANDIE,,48.5556585202,-0.419510749596,483,,Bagnoles de l'Orne Normandie,Bagnoles de l'Orne Normandie,61,Orne,28,Normandie
61483,BAGNOLES DE L ORNE NORMANDIE,61600,BAGNOLES DE L ORNE NORMANDIE,ST MICHEL DES ANDAINES,48.5556585202,-0.419510749596,483,,Bagnoles de l'Orne Normandie,Bagnoles de l'Orne Normandie,61,Orne,28,Normandie
61484,VAL AU PERCHE,61130,VAL AU PERCHE,GEMAGES,48.2676940084,0.650909010002,484,,Val-au-Perche,Val-au-Perche,61,Orne,28,Normandie
61484,VAL AU PERCHE,61260,VAL AU PERCHE,LA ROUGE,48.2676940084,0.650909010002,484,,Val-au-Perche,Val-au-Perche,61,Orne,28,Normandie
61484,VAL AU PERCHE,61260,VAL AU PERCHE,LE THEIL,48.2676940084,0.650909010002,484,,Val-au-Perche,Val-au-Perche,61,Orne,28,Normandie
61484,VAL AU PERCHE,61260,VAL AU PERCHE,L HERMITIERE,48.2676940084,0.650909010002,484,,Val-au-Perche,Val-au-Perche,61,Orne,28,Normandie
61484,VAL AU PERCHE,61260,VAL AU PERCHE,MALE,48.2676940084,0.650909010002,484,,Val-au-Perche,Val-au-Perche,61,Orne,28,Normandie
61484,VAL AU PERCHE,61340,VAL AU PERCHE,ST AGNAN SUR ERRE,48.2676940084,0.650909010002,484,,Val-au-Perche,Val-au-Perche,61,Orne,28,Normandie
61485,TICHEVILLE,61120,TICHEVILLE,,48.9078218887,0.258960926416,485,,Ticheville,Ticheville,61,Orne,28,Normandie
61486,TINCHEBRAY BOCAGE,61800,TINCHEBRAY BOCAGE,,48.7558885792,-0.736451200504,486,,Tinchebray-Bocage,Tinchebray-Bocage,61,Orne,28,Normandie
61486,TINCHEBRAY BOCAGE,61800,TINCHEBRAY BOCAGE,BEAUCHENE,48.7558885792,-0.736451200504,486,,Tinchebray-Bocage,Tinchebray-Bocage,61,Orne,28,Normandie
61486,TINCHEBRAY BOCAGE,61800,TINCHEBRAY BOCAGE,FRENES,48.7558885792,-0.736451200504,486,,Tinchebray-Bocage,Tinchebray-Bocage,61,Orne,28,Normandie
61486,TINCHEBRAY BOCAGE,61800,TINCHEBRAY BOCAGE,LARCHAMP,48.7558885792,-0.736451200504,486,,Tinchebray-Bocage,Tinchebray-Bocage,61,Orne,28,Normandie
61486,TINCHEBRAY BOCAGE,61800,TINCHEBRAY BOCAGE,ST CORNIER DES LANDES,48.7558885792,-0.736451200504,486,,Tinchebray-Bocage,Tinchebray-Bocage,61,Orne,28,Normandie
61486,TINCHEBRAY BOCAGE,61800,TINCHEBRAY BOCAGE,ST JEAN DES BOIS,48.7558885792,-0.736451200504,486,,Tinchebray-Bocage,Tinchebray-Bocage,61,Orne,28,Normandie
61486,TINCHEBRAY BOCAGE,61800,TINCHEBRAY BOCAGE,YVRANDES,48.7558885792,-0.736451200504,486,,Tinchebray-Bocage,Tinchebray-Bocage,61,Orne,28,Normandie
61487,TORCHAMP,61330,TORCHAMP,,48.5396080186,-0.674763032313,487,,Torchamp,Torchamp,61,Orne,28,Normandie
61488,TOUQUETTES,61550,TOUQUETTES,,48.7969098748,0.414530174127,488,,Touquettes,Touquettes,61,Orne,28,Normandie
61490,TOURNAI SUR DIVE,61160,TOURNAI SUR DIVE,,48.8106770801,0.0439108281261,490,,Tournai-sur-Dive,Tournai-sur-Dive,61,Orne,28,Normandie
61491,TOUROUVRE AU PERCHE,61190,TOUROUVRE AU PERCHE,,48.5889926319,0.659944291667,491,,Tourouvre au Perche,Tourouvre au Perche,61,Orne,28,Normandie
61491,TOUROUVRE AU PERCHE,61190,TOUROUVRE AU PERCHE,AUTHEUIL,48.5889926319,0.659944291667,491,,Tourouvre au Perche,Tourouvre au Perche,61,Orne,28,Normandie
61491,TOUROUVRE AU PERCHE,61190,TOUROUVRE AU PERCHE,BIVILLIERS,48.5889926319,0.659944291667,491,,Tourouvre au Perche,Tourouvre au Perche,61,Orne,28,Normandie
61491,TOUROUVRE AU PERCHE,61190,TOUROUVRE AU PERCHE,BRESOLETTES,48.5889926319,0.659944291667,491,,Tourouvre au Perche,Tourouvre au Perche,61,Orne,28,Normandie
61491,TOUROUVRE AU PERCHE,61190,TOUROUVRE AU PERCHE,BUBERTRE,48.5889926319,0.659944291667,491,,Tourouvre au Perche,Tourouvre au Perche,61,Orne,28,Normandie
61491,TOUROUVRE AU PERCHE,61190,TOUROUVRE AU PERCHE,CHAMPS,48.5889926319,0.659944291667,491,,Tourouvre au Perche,Tourouvre au Perche,61,Orne,28,Normandie
61491,TOUROUVRE AU PERCHE,61190,TOUROUVRE AU PERCHE,LA POTERIE AU PERCHE,48.5889926319,0.659944291667,491,,Tourouvre au Perche,Tourouvre au Perche,61,Orne,28,Normandie
61491,TOUROUVRE AU PERCHE,61190,TOUROUVRE AU PERCHE,LIGNEROLLES,48.5889926319,0.659944291667,491,,Tourouvre au Perche,Tourouvre au Perche,61,Orne,28,Normandie
61491,TOUROUVRE AU PERCHE,61190,TOUROUVRE AU PERCHE,PREPOTIN,48.5889926319,0.659944291667,491,,Tourouvre au Perche,Tourouvre au Perche,61,Orne,28,Normandie
61491,TOUROUVRE AU PERCHE,61190,TOUROUVRE AU PERCHE,RANDONNAI,48.5889926319,0.659944291667,491,,Tourouvre au Perche,Tourouvre au Perche,61,Orne,28,Normandie
61492,TREMONT,61390,TREMONT,,48.5992594307,0.273573907946,492,,Trémont,Trémont,61,Orne,28,Normandie
61493,LA TRINITE DES LAITIERS,61230,LA TRINITE DES LAITIERS,,48.8014157498,0.379968041831,493,La,Trinité-des-Laitiers,La Trinité-des-Laitiers,61,Orne,28,Normandie
61494,TRUN,61160,TRUN,,48.8509334132,0.0461785983021,494,,Trun,Trun,61,Orne,28,Normandie
61496,UROU ET CRENNES,61200,UROU ET CRENNES,,48.757281999,0.0121282857365,496,,Urou-et-Crennes,Urou-et-Crennes,61,Orne,28,Normandie
61497,VALFRAMBERT,61250,VALFRAMBERT,,48.4648637397,0.110562026701,497,,Valframbert,Valframbert,61,Orne,28,Normandie
61498,VAUNOISE,61130,VAUNOISE,,48.3472339828,0.486737933229,498,,Vaunoise,Vaunoise,61,Orne,28,Normandie
61499,LES VENTES DE BOURSE,61170,LES VENTES DE BOURSE,,48.5065515464,0.266487058191,499,Les,Ventes-de-Bourse,Les Ventes-de-Bourse,61,Orne,28,Normandie
61500,LA VENTROUZE,61190,LA VENTROUZE,,48.6121964562,0.689163035175,500,La,Ventrouze,La Ventrouze,61,Orne,28,Normandie
61501,VERRIERES,61110,VERRIERES,,48.3940369878,0.751793679416,501,,Verrières,Verrières,61,Orne,28,Normandie
61502,VIDAI,61360,VIDAI,,48.4602912292,0.376581458211,502,,Vidai,Vidai,61,Orne,28,Normandie
61503,VIEUX PONT,61150,VIEUX PONT,,48.6497838693,-0.151446966065,503,,Vieux-Pont,Vieux-Pont,61,Orne,28,Normandie
61504,VILLEBADIN,61310,VILLEBADIN,,48.7771847088,0.155000763874,504,,Villebadin,Villebadin,61,Orne,28,Normandie
61505,VILLEDIEU LES BAILLEUL,61160,VILLEDIEU LES BAILLEUL,,48.7995766824,0.0180312174989,505,,Villedieu-lès-Bailleul,Villedieu-lès-Bailleul,61,Orne,28,Normandie
61507,VILLIERS SOUS MORTAGNE,61400,VILLIERS SOUS MORTAGNE,,48.5392056522,0.593084909404,507,,Villiers-sous-Mortagne,Villiers-sous-Mortagne,61,Orne,28,Normandie
61508,VIMOUTIERS,61120,VIMOUTIERS,,48.9244621074,0.200660290146,508,,Vimoutiers,Vimoutiers,61,Orne,28,Normandie
61510,VITRAI SOUS LAIGLE,61300,VITRAI SOUS LAIGLE,,48.716086064,0.709251455452,510,,Vitrai-sous-Laigle,Vitrai-sous-Laigle,61,Orne,28,Normandie
61512,LES YVETEAUX,61210,LES YVETEAUX,,48.7089293788,-0.270231852035,512,Les,Yveteaux,Les Yveteaux,61,Orne,28,Normandie
62001,ABLAIN ST NAZAIRE,62153,ABLAIN ST NAZAIRE,,50.3965609084,2.69725163344,1,,Ablain-Saint-Nazaire,Ablain-Saint-Nazaire,62,Pas-de-Calais,32,Hauts-de-France
62002,ABLAINZEVELLE,62116,ABLAINZEVELLE,,50.153659552,2.74147361096,2,,Ablainzevelle,Ablainzevelle,62,Pas-de-Calais,32,Hauts-de-France
62003,ACHEVILLE,62320,ACHEVILLE,,50.3843803047,2.88203915482,3,,Acheville,Acheville,62,Pas-de-Calais,32,Hauts-de-France
62004,ACHICOURT,62217,ACHICOURT,,50.272283128,2.75614634271,4,,Achicourt,Achicourt,62,Pas-de-Calais,32,Hauts-de-France
62005,ACHIET LE GRAND,62121,ACHIET LE GRAND,,50.1324695483,2.77909963917,5,,Achiet-le-Grand,Achiet-le-Grand,62,Pas-de-Calais,32,Hauts-de-France
62006,ACHIET LE PETIT,62121,ACHIET LE PETIT,,50.1256395888,2.7552096399,6,,Achiet-le-Petit,Achiet-le-Petit,62,Pas-de-Calais,32,Hauts-de-France
62007,ACQ,62144,ACQ,,50.3494049466,2.65503527981,7,,Acq,Acq,62,Pas-de-Calais,32,Hauts-de-France
62008,ACQUIN WESTBECOURT,62380,ACQUIN WESTBECOURT,,50.7293873198,2.07688748986,8,,Acquin-Westbécourt,Acquin-Westbécourt,62,Pas-de-Calais,32,Hauts-de-France
62008,ACQUIN WESTBECOURT,62380,ACQUIN WESTBECOURT,WESTBECOURT,50.7293873198,2.07688748986,8,,Acquin-Westbécourt,Acquin-Westbécourt,62,Pas-de-Calais,32,Hauts-de-France
62009,ADINFER,62116,ADINFER,,50.1937061733,2.70612950432,9,,Adinfer,Adinfer,62,Pas-de-Calais,32,Hauts-de-France
62010,AFFRINGUES,62380,AFFRINGUES,,50.6903350571,2.07492436119,10,,Affringues,Affringues,62,Pas-de-Calais,32,Hauts-de-France
62011,AGNEZ LES DUISANS,62161,AGNEZ LES DUISANS,,50.3102322126,2.65021288373,11,,Agnez-lès-Duisans,Agnez-lès-Duisans,62,Pas-de-Calais,32,Hauts-de-France
62012,AGNIERES,62690,AGNIERES,,50.3535942668,2.60772514318,12,,Agnières,Agnières,62,Pas-de-Calais,32,Hauts-de-France
62013,AGNY,62217,AGNY,,50.2529566088,2.76055250016,13,,Agny,Agny,62,Pas-de-Calais,32,Hauts-de-France
62014,AIRE SUR LA LYS,62120,AIRE SUR LA LYS,,50.6441641115,2.39533267946,14,,Aire-sur-la-Lys,Aire-sur-la-Lys,62,Pas-de-Calais,32,Hauts-de-France
62015,AIRON NOTRE DAME,62180,AIRON NOTRE DAME,,50.4412207446,1.66789087192,15,,Airon-Notre-Dame,Airon-Notre-Dame,62,Pas-de-Calais,32,Hauts-de-France
62016,AIRON ST VAAST,62180,AIRON ST VAAST,,50.4271409917,1.6712119131,16,,Airon-Saint-Vaast,Airon-Saint-Vaast,62,Pas-de-Calais,32,Hauts-de-France
62017,AIX EN ERGNY,62650,AIX EN ERGNY,,50.5872440261,2.00011764709,17,,Aix-en-Ergny,Aix-en-Ergny,62,Pas-de-Calais,32,Hauts-de-France
62018,AIX EN ISSART,62170,AIX EN ISSART,,50.4802572282,1.86105299794,18,,Aix-en-Issart,Aix-en-Issart,62,Pas-de-Calais,32,Hauts-de-France
62019,AIX NOULETTE,62160,AIX NOULETTE,,50.4212424085,2.71263968704,19,,Aix-Noulette,Aix-Noulette,62,Pas-de-Calais,32,Hauts-de-France
62020,ALEMBON,62850,ALEMBON,,50.7805751248,1.87194877839,20,,Alembon,Alembon,62,Pas-de-Calais,32,Hauts-de-France
62021,ALETTE,62650,ALETTE,,50.524381531,1.85083000523,21,,Alette,Alette,62,Pas-de-Calais,32,Hauts-de-France
62022,ALINCTHUN,62142,ALINCTHUN,,50.7213979047,1.81018390432,22,,Alincthun,Alincthun,62,Pas-de-Calais,32,Hauts-de-France
62023,ALLOUAGNE,62157,ALLOUAGNE,,50.5322320704,2.50493799409,23,,Allouagne,Allouagne,62,Pas-de-Calais,32,Hauts-de-France
62024,ALQUINES,62850,ALQUINES,,50.7335685811,1.99279258078,24,,Alquines,Alquines,62,Pas-de-Calais,32,Hauts-de-France
62025,AMBLETEUSE,62164,AMBLETEUSE,,50.8115899636,1.62108848626,25,,Ambleteuse,Ambleteuse,62,Pas-de-Calais,32,Hauts-de-France
62026,AMBRICOURT,62310,AMBRICOURT,,50.4655698983,2.17120117891,26,,Ambricourt,Ambricourt,62,Pas-de-Calais,32,Hauts-de-France
62027,AMBRINES,62127,AMBRINES,,50.3081142295,2.46698289083,27,,Ambrines,Ambrines,62,Pas-de-Calais,32,Hauts-de-France
62028,AMES,62190,AMES,,50.5355497519,2.41535244184,28,,Ames,Ames,62,Pas-de-Calais,32,Hauts-de-France
62029,AMETTES,62260,AMETTES,,50.5270054766,2.39396099687,29,,Amettes,Amettes,62,Pas-de-Calais,32,Hauts-de-France
62030,AMPLIER,62760,AMPLIER,,50.1381261668,2.39740510092,30,,Amplier,Amplier,62,Pas-de-Calais,32,Hauts-de-France
62031,ANDRES,62340,ANDRES,,50.8658312835,1.91824266934,31,,Andres,Andres,62,Pas-de-Calais,32,Hauts-de-France
62032,ANGRES,62143,ANGRES,,50.4095857133,2.75123923187,32,,Angres,Angres,62,Pas-de-Calais,32,Hauts-de-France
62033,ANNAY,62880,ANNAY,,50.4616640055,2.88243603583,33,,Annay,Annay,62,Pas-de-Calais,32,Hauts-de-France
62034,ANNEQUIN,62149,ANNEQUIN,,50.5032887953,2.71958159288,34,,Annequin,Annequin,62,Pas-de-Calais,32,Hauts-de-France
62035,ANNEZIN,62232,ANNEZIN,,50.5396043649,2.61906640484,35,,Annezin,Annezin,62,Pas-de-Calais,32,Hauts-de-France
62036,ANVIN,62134,ANVIN,,50.4503901319,2.25792059446,36,,Anvin,Anvin,62,Pas-de-Calais,32,Hauts-de-France
62037,ANZIN ST AUBIN,62223,ANZIN ST AUBIN,,50.3178758623,2.74105063372,37,,Anzin-Saint-Aubin,Anzin-Saint-Aubin,62,Pas-de-Calais,32,Hauts-de-France
62038,ARDRES,62610,ARDRES,,50.8768753502,1.98398234284,38,,Ardres,Ardres,62,Pas-de-Calais,32,Hauts-de-France
62038,ARDRES,62610,ARDRES,BOIS EN ARDRES,50.8768753502,1.98398234284,38,,Ardres,Ardres,62,Pas-de-Calais,32,Hauts-de-France
62038,ARDRES,62610,ARDRES,PONT D ARDRES,50.8768753502,1.98398234284,38,,Ardres,Ardres,62,Pas-de-Calais,32,Hauts-de-France
62039,ARLEUX EN GOHELLE,62580,ARLEUX EN GOHELLE,,50.3643664042,2.86961289041,39,,Arleux-en-Gohelle,Arleux-en-Gohelle,62,Pas-de-Calais,32,Hauts-de-France
62040,ARQUES,62510,ARQUES,,50.7402216255,2.31902794783,40,,Arques,Arques,62,Pas-de-Calais,32,Hauts-de-France
62041,ARRAS,62000,ARRAS,,50.2898964997,2.76587316711,41,,Arras,Arras,62,Pas-de-Calais,32,Hauts-de-France
62042,ATHIES,62223,ATHIES,,50.3071331522,2.84195049899,42,,Athies,Athies,62,Pas-de-Calais,32,Hauts-de-France
62043,LES ATTAQUES,62730,LES ATTAQUES,,50.8995873254,1.93699545869,43,Les,Attaques,Les Attaques,62,Pas-de-Calais,32,Hauts-de-France
62044,ATTIN,62170,ATTIN,,50.4917667023,1.75510223982,44,,Attin,Attin,62,Pas-de-Calais,32,Hauts-de-France
62045,AUBIGNY EN ARTOIS,62690,AUBIGNY EN ARTOIS,,50.3482767559,2.58650720649,45,,Aubigny-en-Artois,Aubigny-en-Artois,62,Pas-de-Calais,32,Hauts-de-France
62046,AUBIN ST VAAST,62140,AUBIN ST VAAST,,50.3867453505,1.96316204355,46,,Aubin-Saint-Vaast,Aubin-Saint-Vaast,62,Pas-de-Calais,32,Hauts-de-France
62047,AUBROMETZ,62390,AUBROMETZ,,50.3037964831,2.17623474177,47,,Aubrometz,Aubrometz,62,Pas-de-Calais,32,Hauts-de-France
62048,AUCHEL,62260,AUCHEL,,50.5103339678,2.4687078329,48,,Auchel,Auchel,62,Pas-de-Calais,32,Hauts-de-France
62049,AUCHY AU BOIS,62190,AUCHY AU BOIS,,50.5505941226,2.37330907284,49,,Auchy-au-Bois,Auchy-au-Bois,62,Pas-de-Calais,32,Hauts-de-France
62050,AUCHY LES HESDIN,62770,AUCHY LES HESDIN,,50.4085719795,2.09530419017,50,,Auchy-lès-Hesdin,Auchy-lès-Hesdin,62,Pas-de-Calais,32,Hauts-de-France
62051,AUCHY LES MINES,62138,AUCHY LES MINES,,50.505827034,2.78113489948,51,,Auchy-les-Mines,Auchy-les-Mines,62,Pas-de-Calais,32,Hauts-de-France
62052,AUDEMBERT,62250,AUDEMBERT,,50.8589004255,1.69083658829,52,,Audembert,Audembert,62,Pas-de-Calais,32,Hauts-de-France
62053,AUDINCTHUN,62560,AUDINCTHUN,,50.5786952858,2.12673374535,53,,Audincthun,Audincthun,62,Pas-de-Calais,32,Hauts-de-France
62054,AUDINGHEN,62179,AUDINGHEN,,50.8517582874,1.60848720292,54,,Audinghen,Audinghen,62,Pas-de-Calais,32,Hauts-de-France
62055,AUDREHEM,62890,AUDREHEM,,50.7714356577,1.98451606586,55,,Audrehem,Audrehem,62,Pas-de-Calais,32,Hauts-de-France
62056,AUDRESSELLES,62164,AUDRESSELLES,,50.8288589082,1.611270565,56,,Audresselles,Audresselles,62,Pas-de-Calais,32,Hauts-de-France
62057,AUDRUICQ,62370,AUDRUICQ,,50.8846450982,2.08156931946,57,,Audruicq,Audruicq,62,Pas-de-Calais,32,Hauts-de-France
62058,AUMERVAL,62550,AUMERVAL,,50.5056084882,2.40678584816,58,,Aumerval,Aumerval,62,Pas-de-Calais,32,Hauts-de-France
62059,AUTINGUES,62610,AUTINGUES,,50.8422869707,1.98640127698,59,,Autingues,Autingues,62,Pas-de-Calais,32,Hauts-de-France
62060,AUXI LE CHATEAU,62390,AUXI LE CHATEAU,,50.2310426213,2.11720873928,60,,Auxi-le-Château,Auxi-le-Château,62,Pas-de-Calais,32,Hauts-de-France
62061,AVERDOINGT,62127,AVERDOINGT,,50.3453037362,2.44499865437,61,,Averdoingt,Averdoingt,62,Pas-de-Calais,32,Hauts-de-France
62062,AVESNES,62650,AVESNES,,50.5516543223,1.96214874129,62,,Avesnes,Avesnes,62,Pas-de-Calais,32,Hauts-de-France
62063,AVESNES LE COMTE,62810,AVESNES LE COMTE,,50.271678948,2.52858702519,63,,Avesnes-le-Comte,Avesnes-le-Comte,62,Pas-de-Calais,32,Hauts-de-France
62064,AVESNES LES BAPAUME,62450,AVESNES LES BAPAUME,,50.1049859352,2.83305700978,64,,Avesnes-lès-Bapaume,Avesnes-lès-Bapaume,62,Pas-de-Calais,32,Hauts-de-France
62065,AVION,62210,AVION,,50.4039007645,2.82658779245,65,,Avion,Avion,62,Pas-de-Calais,32,Hauts-de-France
62066,AVONDANCE,62310,AVONDANCE,,50.4792838147,2.10125197413,66,,Avondance,Avondance,62,Pas-de-Calais,32,Hauts-de-France
62067,AVROULT,62560,AVROULT,,50.6320023279,2.14547609845,67,,Avroult,Avroult,62,Pas-de-Calais,32,Hauts-de-France
62068,AYETTE,62116,AYETTE,,50.1699222305,2.73263282673,68,,Ayette,Ayette,62,Pas-de-Calais,32,Hauts-de-France
62069,AZINCOURT,62310,AZINCOURT,,50.4588971511,2.11716559112,69,,Azincourt,Azincourt,62,Pas-de-Calais,32,Hauts-de-France
62070,BAILLEUL AUX CORNAILLES,62127,BAILLEUL AUX CORNAILLES,,50.3728404349,2.44878258653,70,,Bailleul-aux-Cornailles,Bailleul-aux-Cornailles,62,Pas-de-Calais,32,Hauts-de-France
62071,BAILLEUL LES PERNES,62550,BAILLEUL LES PERNES,,50.5078665231,2.3803188111,71,,Bailleul-lès-Pernes,Bailleul-lès-Pernes,62,Pas-de-Calais,32,Hauts-de-France
62072,BAILLEULMONT,62123,BAILLEULMONT,,50.2110356075,2.60402081464,72,,Bailleulmont,Bailleulmont,62,Pas-de-Calais,32,Hauts-de-France
62073,BAILLEUL SIR BERTHOULT,62580,BAILLEUL SIR BERTHOULT,,50.3357112804,2.84376737542,73,,Bailleul-Sir-Berthoult,Bailleul-Sir-Berthoult,62,Pas-de-Calais,32,Hauts-de-France
62074,BAILLEULVAL,62123,BAILLEULVAL,,50.2223355543,2.62727030677,74,,Bailleulval,Bailleulval,62,Pas-de-Calais,32,Hauts-de-France
62075,BAINCTHUN,62360,BAINCTHUN,,50.7014421299,1.71289310882,75,,Baincthun,Baincthun,62,Pas-de-Calais,32,Hauts-de-France
62076,BAINGHEN,62850,BAINGHEN,,50.7506938279,1.90219726912,76,,Bainghen,Bainghen,62,Pas-de-Calais,32,Hauts-de-France
62077,BAJUS,62150,BAJUS,,50.4205764325,2.47224510422,77,,Bajus,Bajus,62,Pas-de-Calais,32,Hauts-de-France
62078,BALINGHEM,62610,BALINGHEM,,50.8613731955,1.94035198603,78,,Balinghem,Balinghem,62,Pas-de-Calais,32,Hauts-de-France
62079,BANCOURT,62450,BANCOURT,,50.1014519899,2.88965840912,79,,Bancourt,Bancourt,62,Pas-de-Calais,32,Hauts-de-France
62080,BAPAUME,62450,BAPAUME,,50.1013647974,2.85501640622,80,,Bapaume,Bapaume,62,Pas-de-Calais,32,Hauts-de-France
62081,BARALLE,62860,BARALLE,,50.2138070796,3.05693800103,81,,Baralle,Baralle,62,Pas-de-Calais,32,Hauts-de-France
62082,BARASTRE,62124,BARASTRE,,50.071129509,2.93896011115,82,,Barastre,Barastre,62,Pas-de-Calais,32,Hauts-de-France
62083,BARLIN,62620,BARLIN,,50.4565423999,2.61390140726,83,,Barlin,Barlin,62,Pas-de-Calais,32,Hauts-de-France
62084,BARLY,62810,BARLY,,50.2482462373,2.53846548292,84,,Barly,Barly,62,Pas-de-Calais,32,Hauts-de-France
62085,BASSEUX,62123,BASSEUX,,50.2289727397,2.64740800316,85,,Basseux,Basseux,62,Pas-de-Calais,32,Hauts-de-France
62086,BAVINCOURT,62158,BAVINCOURT,,50.2235500941,2.5641980363,86,,Bavincourt,Bavincourt,62,Pas-de-Calais,32,Hauts-de-France
62086,BAVINCOURT,62158,BAVINCOURT,L ARBRET,50.2235500941,2.5641980363,86,,Bavincourt,Bavincourt,62,Pas-de-Calais,32,Hauts-de-France
62087,BAYENGHEM LES EPERLECQUES,62910,BAYENGHEM LES EPERLECQUES,,50.8060919083,2.11581614167,87,,Bayenghem-lès-Éperlecques,Bayenghem-lès-Éperlecques,62,Pas-de-Calais,32,Hauts-de-France
62088,BAYENGHEM LES SENINGHEM,62380,BAYENGHEM LES SENINGHEM,,50.7048864835,2.07577387326,88,,Bayenghem-lès-Seninghem,Bayenghem-lès-Seninghem,62,Pas-de-Calais,32,Hauts-de-France
62089,BAZINGHEN,62250,BAZINGHEN,,50.831272878,1.65838919631,89,,Bazinghen,Bazinghen,62,Pas-de-Calais,32,Hauts-de-France
62090,BEALENCOURT,62770,BEALENCOURT,,50.4365269815,2.12585170509,90,,Béalencourt,Béalencourt,62,Pas-de-Calais,32,Hauts-de-France
62091,BEAUDRICOURT,62810,BEAUDRICOURT,,50.251321134,2.40781926457,91,,Beaudricourt,Beaudricourt,62,Pas-de-Calais,32,Hauts-de-France
62092,BEAUFORT BLAVINCOURT,62810,BEAUFORT BLAVINCOURT,,50.2755246835,2.48584476187,92,,Beaufort-Blavincourt,Beaufort-Blavincourt,62,Pas-de-Calais,32,Hauts-de-France
62093,BEAULENCOURT,62450,BEAULENCOURT,,50.0732677142,2.86940557722,93,,Beaulencourt,Beaulencourt,62,Pas-de-Calais,32,Hauts-de-France
62094,BEAUMERIE ST MARTIN,62170,BEAUMERIE ST MARTIN,,50.4463613161,1.79723541504,94,,Beaumerie-Saint-Martin,Beaumerie-Saint-Martin,62,Pas-de-Calais,32,Hauts-de-France
62095,BEAUMETZ LES AIRE,62960,BEAUMETZ LES AIRE,,50.5437814581,2.21908661342,95,,Beaumetz-lès-Aire,Beaumetz-lès-Aire,62,Pas-de-Calais,32,Hauts-de-France
62096,BEAUMETZ LES CAMBRAI,62124,BEAUMETZ LES CAMBRAI,,50.1255965767,2.98659622521,96,,Beaumetz-lès-Cambrai,Beaumetz-lès-Cambrai,62,Pas-de-Calais,32,Hauts-de-France
62097,BEAUMETZ LES LOGES,62123,BEAUMETZ LES LOGES,,50.2455653714,2.65887337603,97,,Beaumetz-lès-Loges,Beaumetz-lès-Loges,62,Pas-de-Calais,32,Hauts-de-France
62099,BEAURAINS,62217,BEAURAINS,,50.2580784965,2.78988564135,99,,Beaurains,Beaurains,62,Pas-de-Calais,32,Hauts-de-France
62100,BEAURAINVILLE,62990,BEAURAINVILLE,,50.4202171445,1.90313818619,100,,Beaurainville,Beaurainville,62,Pas-de-Calais,32,Hauts-de-France
62101,BEAUVOIS,62130,BEAUVOIS,,50.3742414369,2.23430985863,101,,Beauvois,Beauvois,62,Pas-de-Calais,32,Hauts-de-France
62102,BECOURT,62240,BECOURT,,50.6398225963,1.90227068236,102,,Bécourt,Bécourt,62,Pas-de-Calais,32,Hauts-de-France
62103,BEHAGNIES,62121,BEHAGNIES,,50.1420610157,2.8275098552,103,,Béhagnies,Béhagnies,62,Pas-de-Calais,32,Hauts-de-France
62104,BELLEBRUNE,62142,BELLEBRUNE,,50.7326742454,1.77771584162,104,,Bellebrune,Bellebrune,62,Pas-de-Calais,32,Hauts-de-France
62105,BELLE ET HOULLEFORT,62142,BELLE ET HOULLEFORT,,50.7473911914,1.76615616876,105,,Belle-et-Houllefort,Belle-et-Houllefort,62,Pas-de-Calais,32,Hauts-de-France
62106,BELLONNE,62490,BELLONNE,,50.3006458949,3.04320256335,106,,Bellonne,Bellonne,62,Pas-de-Calais,32,Hauts-de-France
62107,BENIFONTAINE,62410,BENIFONTAINE,,50.4760416223,2.82697647324,107,,Bénifontaine,Bénifontaine,62,Pas-de-Calais,32,Hauts-de-France
62108,BERCK,62600,BERCK,,50.4141574341,1.58229663833,108,,Berck,Berck,62,Pas-de-Calais,32,Hauts-de-France
62109,BERGUENEUSE,62134,BERGUENEUSE,,50.4662361395,2.24867928273,109,,Bergueneuse,Bergueneuse,62,Pas-de-Calais,32,Hauts-de-France
62111,BERLENCOURT LE CAUROY,62810,BERLENCOURT LE CAUROY,,50.2733690161,2.42347894966,111,,Berlencourt-le-Cauroy,Berlencourt-le-Cauroy,62,Pas-de-Calais,32,Hauts-de-France
62112,BERLES AU BOIS,62123,BERLES AU BOIS,,50.1998422446,2.63005349312,112,,Berles-au-Bois,Berles-au-Bois,62,Pas-de-Calais,32,Hauts-de-France
62113,BERLES MONCHEL,62690,BERLES MONCHEL,,50.3449347791,2.52589986909,113,,Berles-Monchel,Berles-Monchel,62,Pas-de-Calais,32,Hauts-de-France
62114,BERMICOURT,62130,BERMICOURT,,50.4079914817,2.23309928615,114,,Bermicourt,Bermicourt,62,Pas-de-Calais,32,Hauts-de-France
62115,BERNEVILLE,62123,BERNEVILLE,,50.2641580645,2.67337034713,115,,Berneville,Berneville,62,Pas-de-Calais,32,Hauts-de-France
62116,BERNIEULLES,62170,BERNIEULLES,,50.5548320618,1.77250960717,116,,Bernieulles,Bernieulles,62,Pas-de-Calais,32,Hauts-de-France
62117,BERTINCOURT,62124,BERTINCOURT,,50.0875076095,2.98123833644,117,,Bertincourt,Bertincourt,62,Pas-de-Calais,32,Hauts-de-France
62118,BETHONSART,62690,BETHONSART,,50.382567238,2.5436027957,118,,Béthonsart,Béthonsart,62,Pas-de-Calais,32,Hauts-de-France
62119,BETHUNE,62400,BETHUNE,,50.5288922584,2.64242379342,119,,Béthune,Béthune,62,Pas-de-Calais,32,Hauts-de-France
62120,BEUGIN,62150,BEUGIN,,50.4421075569,2.50784218991,120,,Beugin,Beugin,62,Pas-de-Calais,32,Hauts-de-France
62121,BEUGNATRE,62450,BEUGNATRE,,50.1322737737,2.87602272016,121,,Beugnâtre,Beugnâtre,62,Pas-de-Calais,32,Hauts-de-France
62122,BEUGNY,62124,BEUGNY,,50.1183185417,2.93213204111,122,,Beugny,Beugny,62,Pas-de-Calais,32,Hauts-de-France
62123,BEUSSENT,62170,BEUSSENT,,50.5580041151,1.81455798669,123,,Beussent,Beussent,62,Pas-de-Calais,32,Hauts-de-France
62124,BEUTIN,62170,BEUTIN,,50.4967718151,1.72676548576,124,,Beutin,Beutin,62,Pas-de-Calais,32,Hauts-de-France
62125,BEUVREQUEN,62250,BEUVREQUEN,,50.7987535793,1.67420667033,125,,Beuvrequen,Beuvrequen,62,Pas-de-Calais,32,Hauts-de-France
62126,BEUVRY,62660,BEUVRY,,50.5328399462,2.69176985674,126,,Beuvry,Beuvry,62,Pas-de-Calais,32,Hauts-de-France
62127,BEZINGHEM,62650,BEZINGHEM,,50.5986244617,1.85180618484,127,,Bezinghem,Bezinghem,62,Pas-de-Calais,32,Hauts-de-France
62128,BIACHE ST VAAST,62118,BIACHE ST VAAST,,50.3149700801,2.94146922674,128,,Biache-Saint-Vaast,Biache-Saint-Vaast,62,Pas-de-Calais,32,Hauts-de-France
62129,BIEFVILLERS LES BAPAUME,62450,BIEFVILLERS LES BAPAUME,,50.1181182201,2.82212034919,129,,Biefvillers-lès-Bapaume,Biefvillers-lès-Bapaume,62,Pas-de-Calais,32,Hauts-de-France
62130,BIENVILLERS AU BOIS,62111,BIENVILLERS AU BOIS,,50.1663319359,2.60896396672,130,,Bienvillers-au-Bois,Bienvillers-au-Bois,62,Pas-de-Calais,32,Hauts-de-France
62131,BIHUCOURT,62121,BIHUCOURT,,50.1268891083,2.80101394028,131,,Bihucourt,Bihucourt,62,Pas-de-Calais,32,Hauts-de-France
62132,BILLY BERCLAU,62138,BILLY BERCLAU,,50.5196248764,2.86674896884,132,,Billy-Berclau,Billy-Berclau,62,Pas-de-Calais,32,Hauts-de-France
62133,BILLY MONTIGNY,62420,BILLY MONTIGNY,,50.4151791054,2.90828984537,133,,Billy-Montigny,Billy-Montigny,62,Pas-de-Calais,32,Hauts-de-France
62134,BIMONT,62650,BIMONT,,50.540978292,1.90966011141,134,,Bimont,Bimont,62,Pas-de-Calais,32,Hauts-de-France
62135,BLAIRVILLE,62173,BLAIRVILLE,,50.2182216387,2.71384024388,135,,Blairville,Blairville,62,Pas-de-Calais,32,Hauts-de-France
62137,BLANGERVAL BLANGERMONT,62270,BLANGERVAL BLANGERMONT,,50.3244181723,2.22103571725,137,,Blangerval-Blangermont,Blangerval-Blangermont,62,Pas-de-Calais,32,Hauts-de-France
62137,BLANGERVAL BLANGERMONT,62270,BLANGERVAL BLANGERMONT,BLANGERMONT,50.3244181723,2.22103571725,137,,Blangerval-Blangermont,Blangerval-Blangermont,62,Pas-de-Calais,32,Hauts-de-France
62138,BLANGY SUR TERNOISE,62770,BLANGY SUR TERNOISE,,50.423805898,2.17392910662,138,,Blangy-sur-Ternoise,Blangy-sur-Ternoise,62,Pas-de-Calais,32,Hauts-de-France
62139,BLENDECQUES,62575,BLENDECQUES,,50.7159944346,2.27536301448,139,,Blendecques,Blendecques,62,Pas-de-Calais,32,Hauts-de-France
62140,BLEQUIN,62380,BLEQUIN,,50.6645152898,1.97143085721,140,,Bléquin,Bléquin,62,Pas-de-Calais,32,Hauts-de-France
62141,BLESSY,62120,BLESSY,,50.6164683999,2.33114392012,141,,Blessy,Blessy,62,Pas-de-Calais,32,Hauts-de-France
62142,BLINGEL,62770,BLINGEL,,50.4051757793,2.15016843833,142,,Blingel,Blingel,62,Pas-de-Calais,32,Hauts-de-France
62143,BOFFLES,62390,BOFFLES,,50.2567972494,2.19943270366,143,,Boffles,Boffles,62,Pas-de-Calais,32,Hauts-de-France
62144,BOIRY BECQUERELLE,62128,BOIRY BECQUERELLE,,50.2137016789,2.82007955522,144,,Boiry-Becquerelle,Boiry-Becquerelle,62,Pas-de-Calais,32,Hauts-de-France
62145,BOIRY NOTRE DAME,62156,BOIRY NOTRE DAME,,50.2739296431,2.94252395946,145,,Boiry-Notre-Dame,Boiry-Notre-Dame,62,Pas-de-Calais,32,Hauts-de-France
62146,BOIRY ST MARTIN,62175,BOIRY ST MARTIN,,50.1926802634,2.75913608882,146,,Boiry-Saint-Martin,Boiry-Saint-Martin,62,Pas-de-Calais,32,Hauts-de-France
62147,BOIRY STE RICTRUDE,62175,BOIRY STE RICTRUDE,,50.2004598383,2.74639343282,147,,Boiry-Sainte-Rictrude,Boiry-Sainte-Rictrude,62,Pas-de-Calais,32,Hauts-de-France
62148,BOIS BERNARD,62320,BOIS BERNARD,,50.3775317977,2.91332273733,148,,Bois-Bernard,Bois-Bernard,62,Pas-de-Calais,32,Hauts-de-France
62149,BOISDINGHEM,62500,BOISDINGHEM,,50.7490608016,2.09427817413,149,,Boisdinghem,Boisdinghem,62,Pas-de-Calais,32,Hauts-de-France
62150,BOISJEAN,62170,BOISJEAN,,50.4084556449,1.78127372492,150,,Boisjean,Boisjean,62,Pas-de-Calais,32,Hauts-de-France
62151,BOISLEUX AU MONT,62175,BOISLEUX AU MONT,,50.212571363,2.77755045242,151,,Boisleux-au-Mont,Boisleux-au-Mont,62,Pas-de-Calais,32,Hauts-de-France
62152,BOISLEUX ST MARC,62175,BOISLEUX ST MARC,,50.212910679,2.79651597969,152,,Boisleux-Saint-Marc,Boisleux-Saint-Marc,62,Pas-de-Calais,32,Hauts-de-France
62153,BOMY,62960,BOMY,,50.5693776323,2.22407829439,153,,Bomy,Bomy,62,Pas-de-Calais,32,Hauts-de-France
62154,BONNIERES,62270,BONNIERES,,50.2370246537,2.26837260211,154,,Bonnières,Bonnières,62,Pas-de-Calais,32,Hauts-de-France
62155,BONNINGUES LES ARDRES,62890,BONNINGUES LES ARDRES,,50.7756349885,2.0218630949,155,,Bonningues-lès-Ardres,Bonningues-lès-Ardres,62,Pas-de-Calais,32,Hauts-de-France
62156,BONNINGUES LES CALAIS,62340,BONNINGUES LES CALAIS,,50.8931678254,1.77107438801,156,,Bonningues-lès-Calais,Bonningues-lès-Calais,62,Pas-de-Calais,32,Hauts-de-France
62157,BOUBERS LES HESMOND,62990,BOUBERS LES HESMOND,,50.4791206101,1.94657771886,157,,Boubers-lès-Hesmond,Boubers-lès-Hesmond,62,Pas-de-Calais,32,Hauts-de-France
62158,BOUBERS SUR CANCHE,62270,BOUBERS SUR CANCHE,,50.2910196847,2.236634893,158,,Boubers-sur-Canche,Boubers-sur-Canche,62,Pas-de-Calais,32,Hauts-de-France
62160,BOULOGNE SUR MER,62200,BOULOGNE SUR MER,,50.7271332663,1.60756334802,160,,Boulogne-sur-Mer,Boulogne-sur-Mer,62,Pas-de-Calais,32,Hauts-de-France
62161,BOUQUEHAULT,62340,BOUQUEHAULT,,50.8160934944,1.89974480886,161,,Bouquehault,Bouquehault,62,Pas-de-Calais,32,Hauts-de-France
62162,BOURECQ,62190,BOURECQ,,50.5769348477,2.43571250001,162,,Bourecq,Bourecq,62,Pas-de-Calais,32,Hauts-de-France
62163,BOURET SUR CANCHE,62270,BOURET SUR CANCHE,,50.2684109099,2.32331979143,163,,Bouret-sur-Canche,Bouret-sur-Canche,62,Pas-de-Calais,32,Hauts-de-France
62164,BOURLON,62860,BOURLON,,50.180525748,3.12077414192,164,,Bourlon,Bourlon,62,Pas-de-Calais,32,Hauts-de-France
62165,BOURNONVILLE,62240,BOURNONVILLE,,50.698274703,1.84604616577,165,,Bournonville,Bournonville,62,Pas-de-Calais,32,Hauts-de-France
62166,BOURS,62550,BOURS,,50.4489923653,2.40723757367,166,,Bours,Bours,62,Pas-de-Calais,32,Hauts-de-France
62167,BOURSIN,62132,BOURSIN,,50.7705251422,1.82549104115,167,,Boursin,Boursin,62,Pas-de-Calais,32,Hauts-de-France
62168,BOURTHES,62650,BOURTHES,,50.6133691642,1.93499522484,168,,Bourthes,Bourthes,62,Pas-de-Calais,32,Hauts-de-France
62169,BOUVELINGHEM,62380,BOUVELINGHEM,,50.7353867231,2.03289516346,169,,Bouvelinghem,Bouvelinghem,62,Pas-de-Calais,32,Hauts-de-France
62170,BOUVIGNY BOYEFFLES,62172,BOUVIGNY BOYEFFLES,,50.4230685525,2.67309987765,170,,Bouvigny-Boyeffles,Bouvigny-Boyeffles,62,Pas-de-Calais,32,Hauts-de-France
62171,BOYAVAL,62134,BOYAVAL,,50.4727096061,2.31189396331,171,,Boyaval,Boyaval,62,Pas-de-Calais,32,Hauts-de-France
62172,BOYELLES,62128,BOYELLES,,50.1972799281,2.82057343929,172,,Boyelles,Boyelles,62,Pas-de-Calais,32,Hauts-de-France
62173,BREBIERES,62117,BREBIERES,,50.3380926792,3.0195549694,173,,Brebières,Brebières,62,Pas-de-Calais,32,Hauts-de-France
62174,BREMES,62610,BREMES,,50.8476447565,1.955614931,174,,Brêmes,Brêmes,62,Pas-de-Calais,32,Hauts-de-France
62175,BREVILLERS,62140,BREVILLERS,,50.3473733228,2.02124878449,175,,Brévillers,Brévillers,62,Pas-de-Calais,32,Hauts-de-France
62176,BREXENT ENOCQ,62170,BREXENT ENOCQ,,50.5075872573,1.72093733978,176,,Bréxent-Énocq,Bréxent-Énocq,62,Pas-de-Calais,32,Hauts-de-France
62177,BRIMEUX,62170,BRIMEUX,,50.4355050534,1.83617898169,177,,Brimeux,Brimeux,62,Pas-de-Calais,32,Hauts-de-France
62178,BRUAY LA BUISSIERE,62700,BRUAY LA BUISSIERE,,50.4903167839,2.55205338028,178,,Bruay-la-Buissière,Bruay-la-Buissière,62,Pas-de-Calais,32,Hauts-de-France
62178,BRUAY LA BUISSIERE,62700,BRUAY LA BUISSIERE,LABUISSIERE,50.4903167839,2.55205338028,178,,Bruay-la-Buissière,Bruay-la-Buissière,62,Pas-de-Calais,32,Hauts-de-France
62179,BRUNEMBERT,62240,BRUNEMBERT,,50.7149478254,1.88770340365,179,,Brunembert,Brunembert,62,Pas-de-Calais,32,Hauts-de-France
62180,BRIAS,62130,BRIAS,,50.4112336365,2.38520158695,180,,Brias,Brias,62,Pas-de-Calais,32,Hauts-de-France
62181,BUCQUOY,62116,BUCQUOY,,50.1459428141,2.69722641414,181,,Bucquoy,Bucquoy,62,Pas-de-Calais,32,Hauts-de-France
62182,BUIRE AU BOIS,62390,BUIRE AU BOIS,,50.2633672835,2.15308709023,182,,Buire-au-Bois,Buire-au-Bois,62,Pas-de-Calais,32,Hauts-de-France
62183,BUIRE LE SEC,62870,BUIRE LE SEC,,50.3932227275,1.82311982724,183,,Buire-le-Sec,Buire-le-Sec,62,Pas-de-Calais,32,Hauts-de-France
62184,BUISSY,62860,BUISSY,,50.2010903848,3.02979862167,184,,Buissy,Buissy,62,Pas-de-Calais,32,Hauts-de-France
62185,BULLECOURT,62128,BULLECOURT,,50.1927400368,2.92876143561,185,,Bullecourt,Bullecourt,62,Pas-de-Calais,32,Hauts-de-France
62186,BULLY LES MINES,62160,BULLY LES MINES,,50.4451131556,2.71911165536,186,,Bully-les-Mines,Bully-les-Mines,62,Pas-de-Calais,32,Hauts-de-France
62187,BUNEVILLE,62130,BUNEVILLE,,50.3246078076,2.34815051856,187,,Buneville,Buneville,62,Pas-de-Calais,32,Hauts-de-France
62188,BURBURE,62151,BURBURE,,50.5320822618,2.46231135416,188,,Burbure,Burbure,62,Pas-de-Calais,32,Hauts-de-France
62189,BUS,62124,BUS,,50.0637422666,2.96369120352,189,,Bus,Bus,62,Pas-de-Calais,32,Hauts-de-France
62190,BUSNES,62350,BUSNES,,50.588902264,2.51386787052,190,,Busnes,Busnes,62,Pas-de-Calais,32,Hauts-de-France
62191,CAFFIERS,62132,CAFFIERS,,50.843543317,1.81075267365,191,,Caffiers,Caffiers,62,Pas-de-Calais,32,Hauts-de-France
62192,CAGNICOURT,62182,CAGNICOURT,,50.2082287456,2.99387065458,192,,Cagnicourt,Cagnicourt,62,Pas-de-Calais,32,Hauts-de-France
62193,CALAIS,62100,CALAIS,,50.9502072754,1.87575566132,193,,Calais,Calais,62,Pas-de-Calais,32,Hauts-de-France
62194,CALONNE RICOUART,62470,CALONNE RICOUART,,50.4898416084,2.48534476198,194,,Calonne-Ricouart,Calonne-Ricouart,62,Pas-de-Calais,32,Hauts-de-France
62195,CALONNE SUR LA LYS,62350,CALONNE SUR LA LYS,,50.6135521823,2.60895962502,195,,Calonne-sur-la-Lys,Calonne-sur-la-Lys,62,Pas-de-Calais,32,Hauts-de-France
62196,LA CALOTTERIE,62170,LA CALOTTERIE,,50.4789464513,1.71772196973,196,La,Calotterie,La Calotterie,62,Pas-de-Calais,32,Hauts-de-France
62197,CAMBLAIN CHATELAIN,62470,CAMBLAIN CHATELAIN,,50.4770255596,2.45056284457,197,,Camblain-Châtelain,Camblain-Châtelain,62,Pas-de-Calais,32,Hauts-de-France
62198,CAMBLIGNEUL,62690,CAMBLIGNEUL,,50.3796063239,2.61054254507,198,,Cambligneul,Cambligneul,62,Pas-de-Calais,32,Hauts-de-France
62199,CAMBLAIN L ABBE,62690,CAMBLAIN L ABBE,,50.3732152487,2.63610604156,199,,Camblain-l'Abbé,Camblain-l'Abbé,62,Pas-de-Calais,32,Hauts-de-France
62200,CAMBRIN,62149,CAMBRIN,,50.5116047377,2.73659179875,200,,Cambrin,Cambrin,62,Pas-de-Calais,32,Hauts-de-France
62201,CAMIERS,62176,CAMIERS,,50.5641187027,1.61276179612,201,,Camiers,Camiers,62,Pas-de-Calais,32,Hauts-de-France
62201,CAMIERS,62176,CAMIERS,STE CECILE,50.5641187027,1.61276179612,201,,Camiers,Camiers,62,Pas-de-Calais,32,Hauts-de-France
62202,CAMPAGNE LES BOULONNAIS,62650,CAMPAGNE LES BOULONNAIS,,50.6189686189,1.99566804739,202,,Campagne-lès-Boulonnais,Campagne-lès-Boulonnais,62,Pas-de-Calais,32,Hauts-de-France
62203,CAMPAGNE LES GUINES,62340,CAMPAGNE LES GUINES,,50.8407555351,1.89853626716,203,,Campagne-lès-Guines,Campagne-lès-Guines,62,Pas-de-Calais,32,Hauts-de-France
62204,CAMPAGNE LES HESDIN,62870,CAMPAGNE LES HESDIN,,50.401191114,1.86634630857,204,,Campagne-lès-Hesdin,Campagne-lès-Hesdin,62,Pas-de-Calais,32,Hauts-de-France
62205,CAMPAGNE LES WARDRECQUES,62120,CAMPAGNE LES WARDRECQUES,,50.7136140049,2.33349766884,205,,Campagne-lès-Wardrecques,Campagne-lès-Wardrecques,62,Pas-de-Calais,32,Hauts-de-France
62206,CAMPIGNEULLES LES GRANDES,62170,CAMPIGNEULLES LES GRANDES,,50.4317716288,1.70627964186,206,,Campigneulles-les-Grandes,Campigneulles-les-Grandes,62,Pas-de-Calais,32,Hauts-de-France
62207,CAMPIGNEULLES LES PETITES,62170,CAMPIGNEULLES LES PETITES,,50.4406093208,1.73639595025,207,,Campigneulles-les-Petites,Campigneulles-les-Petites,62,Pas-de-Calais,32,Hauts-de-France
62208,CANETTEMONT,62270,CANETTEMONT,,50.2798293137,2.36334823406,208,,Canettemont,Canettemont,62,Pas-de-Calais,32,Hauts-de-France
62209,CANLERS,62310,CANLERS,,50.4863634901,2.14578275774,209,,Canlers,Canlers,62,Pas-de-Calais,32,Hauts-de-France
62210,CANTELEUX,62270,CANTELEUX,,50.2220372067,2.31306541614,210,,Canteleux,Canteleux,62,Pas-de-Calais,32,Hauts-de-France
62211,CAPELLE FERMONT,62690,CAPELLE FERMONT,,50.3456037658,2.61751195169,211,,Capelle-Fermont,Capelle-Fermont,62,Pas-de-Calais,32,Hauts-de-France
62212,CAPELLE LES HESDIN,62140,CAPELLE LES HESDIN,,50.3470946607,1.99114146583,212,,Capelle-lès-Hesdin,Capelle-lès-Hesdin,62,Pas-de-Calais,32,Hauts-de-France
62213,CARENCY,62144,CARENCY,,50.3786422794,2.7000271609,213,,Carency,Carency,62,Pas-de-Calais,32,Hauts-de-France
62214,CARLY,62830,CARLY,,50.6548213012,1.71112187076,214,,Carly,Carly,62,Pas-de-Calais,32,Hauts-de-France
62215,CARVIN,62220,CARVIN,,50.4895185959,2.94858062937,215,,Carvin,Carvin,62,Pas-de-Calais,32,Hauts-de-France
62216,LA CAUCHIE,62158,LA CAUCHIE,,50.2049912603,2.58249828284,216,La,Cauchie,La Cauchie,62,Pas-de-Calais,32,Hauts-de-France
62217,CAUCHY A LA TOUR,62260,CAUCHY A LA TOUR,,50.5047864537,2.44974030755,217,,Cauchy-à-la-Tour,Cauchy-à-la-Tour,62,Pas-de-Calais,32,Hauts-de-France
62218,CAUCOURT,62150,CAUCOURT,,50.3943730066,2.57209168767,218,,Caucourt,Caucourt,62,Pas-de-Calais,32,Hauts-de-France
62219,CAUMONT,62140,CAUMONT,,50.2933192344,2.03127032068,219,,Caumont,Caumont,62,Pas-de-Calais,32,Hauts-de-France
62220,CAVRON ST MARTIN,62140,CAVRON ST MARTIN,,50.4210471112,2.00405279554,220,,Cavron-Saint-Martin,Cavron-Saint-Martin,62,Pas-de-Calais,32,Hauts-de-France
62221,CHELERS,62127,CHELERS,,50.3815791457,2.48784596215,221,,Chelers,Chelers,62,Pas-de-Calais,32,Hauts-de-France
62222,CHERIENNES,62140,CHERIENNES,,50.3116108089,2.03441974871,222,,Chériennes,Chériennes,62,Pas-de-Calais,32,Hauts-de-France
62223,CHERISY,62128,CHERISY,,50.233594509,2.91299322756,223,,Chérisy,Chérisy,62,Pas-de-Calais,32,Hauts-de-France
62224,CHOCQUES,62920,CHOCQUES,,50.5404138209,2.56222999188,224,,Chocques,Chocques,62,Pas-de-Calais,32,Hauts-de-France
62225,CLAIRMARAIS,62500,CLAIRMARAIS,,50.7691557578,2.34273928671,225,,Clairmarais,Clairmarais,62,Pas-de-Calais,32,Hauts-de-France
62227,CLENLEU,62650,CLENLEU,,50.5280730059,1.88459632489,227,,Clenleu,Clenleu,62,Pas-de-Calais,32,Hauts-de-France
62228,CLERQUES,62890,CLERQUES,,50.7939031118,1.98394387825,228,,Clerques,Clerques,62,Pas-de-Calais,32,Hauts-de-France
62229,CLETY,62380,CLETY,,50.6533415783,2.18209813078,229,,Cléty,Cléty,62,Pas-de-Calais,32,Hauts-de-France
62230,COLEMBERT,62142,COLEMBERT,,50.7498495112,1.82728211556,230,,Colembert,Colembert,62,Pas-de-Calais,32,Hauts-de-France
62231,COLLINE BEAUMONT,62180,COLLINE BEAUMONT,,50.3429997005,1.67370320424,231,,Colline-Beaumont,Colline-Beaumont,62,Pas-de-Calais,32,Hauts-de-France
62232,LA COMTE,62150,LA COMTE,,50.4259527407,2.49981751816,232,La,Comté,La Comté,62,Pas-de-Calais,32,Hauts-de-France
62233,CONCHIL LE TEMPLE,62180,CONCHIL LE TEMPLE,,50.3675406641,1.6749678298,233,,Conchil-le-Temple,Conchil-le-Temple,62,Pas-de-Calais,32,Hauts-de-France
62234,CONCHY SUR CANCHE,62270,CONCHY SUR CANCHE,,50.2932943413,2.19176240414,234,,Conchy-sur-Canche,Conchy-sur-Canche,62,Pas-de-Calais,32,Hauts-de-France
62235,CONDETTE,62360,CONDETTE,,50.6499272537,1.63946214432,235,,Condette,Condette,62,Pas-de-Calais,32,Hauts-de-France
62236,CONTES,62990,CONTES,,50.4166808226,1.96163690095,236,,Contes,Contes,62,Pas-de-Calais,32,Hauts-de-France
62237,CONTEVILLE LES BOULOGNE,62126,CONTEVILLE LES BOULOGNE,,50.7441321021,1.72974942382,237,,Conteville-lès-Boulogne,Conteville-lès-Boulogne,62,Pas-de-Calais,32,Hauts-de-France
62238,CONTEVILLE EN TERNOIS,62130,CONTEVILLE EN TERNOIS,,50.4329844864,2.32879432412,238,,Conteville-en-Ternois,Conteville-en-Ternois,62,Pas-de-Calais,32,Hauts-de-France
62239,COQUELLES,62231,COQUELLES,,50.929612545,1.81411630613,239,,Coquelles,Coquelles,62,Pas-de-Calais,32,Hauts-de-France
62240,CORBEHEM,62112,CORBEHEM,,50.3351261232,3.0477618854,240,,Corbehem,Corbehem,62,Pas-de-Calais,32,Hauts-de-France
62241,CORMONT,62630,CORMONT,,50.5639416071,1.74295071169,241,,Cormont,Cormont,62,Pas-de-Calais,32,Hauts-de-France
62242,COUIN,62760,COUIN,,50.133455749,2.53396562717,242,,Couin,Couin,62,Pas-de-Calais,32,Hauts-de-France
62243,COULLEMONT,62158,COULLEMONT,,50.218441414,2.48202998649,243,,Coullemont,Coullemont,62,Pas-de-Calais,32,Hauts-de-France
62244,COULOGNE,62137,COULOGNE,,50.916752799,1.88656499297,244,,Coulogne,Coulogne,62,Pas-de-Calais,32,Hauts-de-France
62245,COULOMBY,62380,COULOMBY,,50.7093724516,2.00012790111,245,,Coulomby,Coulomby,62,Pas-de-Calais,32,Hauts-de-France
62246,COUPELLE NEUVE,62310,COUPELLE NEUVE,,50.4980306908,2.10823141445,246,,Coupelle-Neuve,Coupelle-Neuve,62,Pas-de-Calais,32,Hauts-de-France
62247,COUPELLE VIEILLE,62310,COUPELLE VIEILLE,,50.5329987345,2.07763347202,247,,Coupelle-Vieille,Coupelle-Vieille,62,Pas-de-Calais,32,Hauts-de-France
62248,COURCELLES LE COMTE,62121,COURCELLES LE COMTE,,50.1574659714,2.77662394023,248,,Courcelles-le-Comte,Courcelles-le-Comte,62,Pas-de-Calais,32,Hauts-de-France
62249,COURCELLES LES LENS,62970,COURCELLES LES LENS,,50.4147715319,3.01497181219,249,,Courcelles-lès-Lens,Courcelles-lès-Lens,62,Pas-de-Calais,32,Hauts-de-France
62250,COURRIERES,62710,COURRIERES,,50.4532038748,2.94633053767,250,,Courrières,Courrières,62,Pas-de-Calais,32,Hauts-de-France
62251,COURSET,62240,COURSET,,50.644204376,1.85186054058,251,,Courset,Courset,62,Pas-de-Calais,32,Hauts-de-France
62252,LA COUTURE,62136,LA COUTURE,,50.5786372651,2.69951640297,252,La,Couture,La Couture,62,Pas-de-Calais,32,Hauts-de-France
62253,COUTURELLE,62158,COUTURELLE,,50.2066022769,2.49814698704,253,,Couturelle,Couturelle,62,Pas-de-Calais,32,Hauts-de-France
62254,COYECQUES,62560,COYECQUES,,50.6006814818,2.18485516321,254,,Coyecques,Coyecques,62,Pas-de-Calais,32,Hauts-de-France
62255,CREMAREST,62240,CREMAREST,,50.7007092157,1.78896697788,255,,Crémarest,Crémarest,62,Pas-de-Calais,32,Hauts-de-France
62256,CREPY,62310,CREPY,,50.4725099777,2.20322348829,256,,Crépy,Crépy,62,Pas-de-Calais,32,Hauts-de-France
62257,CREQUY,62310,CREQUY,,50.5028112008,2.03862864649,257,,Créquy,Créquy,62,Pas-de-Calais,32,Hauts-de-France
62258,CROISETTE,62130,CROISETTE,,50.353867612,2.26482730869,258,,Croisette,Croisette,62,Pas-de-Calais,32,Hauts-de-France
62259,CROISILLES,62128,CROISILLES,,50.1978734873,2.88112932349,259,,Croisilles,Croisilles,62,Pas-de-Calais,32,Hauts-de-France
62260,CROIX EN TERNOIS,62130,CROIX EN TERNOIS,,50.3833079457,2.27273086096,260,,Croix-en-Ternois,Croix-en-Ternois,62,Pas-de-Calais,32,Hauts-de-France
62261,CUCQ,62780,CUCQ,,50.4861371651,1.6129406035,261,,Cucq,Cucq,62,Pas-de-Calais,32,Hauts-de-France
62261,CUCQ,62780,CUCQ,STELLA,50.4861371651,1.6129406035,261,,Cucq,Cucq,62,Pas-de-Calais,32,Hauts-de-France
62261,CUCQ,62780,CUCQ,TREPIED,50.4861371651,1.6129406035,261,,Cucq,Cucq,62,Pas-de-Calais,32,Hauts-de-France
62262,CUINCHY,62149,CUINCHY,,50.5192106267,2.74651659216,262,,Cuinchy,Cuinchy,62,Pas-de-Calais,32,Hauts-de-France
62263,DAINVILLE,62000,DAINVILLE,,50.2771637475,2.71345127608,263,,Dainville,Dainville,62,Pas-de-Calais,32,Hauts-de-France
62264,DANNES,62187,DANNES,,50.5927758846,1.60549165729,264,,Dannes,Dannes,62,Pas-de-Calais,32,Hauts-de-France
62265,DELETTES,62129,DELETTES,,50.6223136659,2.21751946622,265,,Delettes,Delettes,62,Pas-de-Calais,32,Hauts-de-France
62266,DENIER,62810,DENIER,,50.2905902827,2.44714550707,266,,Denier,Denier,62,Pas-de-Calais,32,Hauts-de-France
62267,DENNEBROEUCQ,62560,DENNEBROEUCQ,,50.5841623425,2.15571582187,267,,Dennebrœucq,Dennebrœucq,62,Pas-de-Calais,32,Hauts-de-France
62268,DESVRES,62240,DESVRES,,50.6749344627,1.82879354348,268,,Desvres,Desvres,62,Pas-de-Calais,32,Hauts-de-France
62269,DIEVAL,62460,DIEVAL,,50.4365599827,2.44830895254,269,,Diéval,Diéval,62,Pas-de-Calais,32,Hauts-de-France
62270,DIVION,62460,DIVION,,50.4697599295,2.50465277071,270,,Divion,Divion,62,Pas-de-Calais,32,Hauts-de-France
62271,DOHEM,62380,DOHEM,,50.6269973774,2.16928920194,271,,Dohem,Dohem,62,Pas-de-Calais,32,Hauts-de-France
62272,DOUCHY LES AYETTE,62116,DOUCHY LES AYETTE,,50.1758311935,2.71038948013,272,,Douchy-lès-Ayette,Douchy-lès-Ayette,62,Pas-de-Calais,32,Hauts-de-France
62273,DOUDEAUVILLE,62830,DOUDEAUVILLE,,50.6239084374,1.83106911247,273,,Doudeauville,Doudeauville,62,Pas-de-Calais,32,Hauts-de-France
62274,DOURGES,62119,DOURGES,,50.4393889238,2.98726838092,274,,Dourges,Dourges,62,Pas-de-Calais,32,Hauts-de-France
62275,DOURIEZ,62870,DOURIEZ,,50.3384867372,1.88223144909,275,,Douriez,Douriez,62,Pas-de-Calais,32,Hauts-de-France
62276,DOUVRIN,62138,DOUVRIN,,50.5121447638,2.8263130337,276,,Douvrin,Douvrin,62,Pas-de-Calais,32,Hauts-de-France
62277,DROCOURT,62320,DROCOURT,,50.3900419812,2.93220470295,277,,Drocourt,Drocourt,62,Pas-de-Calais,32,Hauts-de-France
62278,DROUVIN LE MARAIS,62131,DROUVIN LE MARAIS,,50.4892270417,2.63277217205,278,,Drouvin-le-Marais,Drouvin-le-Marais,62,Pas-de-Calais,32,Hauts-de-France
62279,DUISANS,62161,DUISANS,,50.3016242398,2.69606455285,279,,Duisans,Duisans,62,Pas-de-Calais,32,Hauts-de-France
62280,DURY,62156,DURY,,50.2481728938,3.00391068828,280,,Dury,Dury,62,Pas-de-Calais,32,Hauts-de-France
62281,ECHINGHEN,62360,ECHINGHEN,,50.7013312992,1.65483173967,281,,Echinghen,Echinghen,62,Pas-de-Calais,32,Hauts-de-France
62282,ECLIMEUX,62770,ECLIMEUX,,50.3960082983,2.18049856502,282,,Éclimeux,Éclimeux,62,Pas-de-Calais,32,Hauts-de-France
62283,ECOIVRES,62270,ECOIVRES,,50.3246377433,2.28557967966,283,,Écoivres,Écoivres,62,Pas-de-Calais,32,Hauts-de-France
62284,ECOURT ST QUENTIN,62860,ECOURT ST QUENTIN,,50.2606003824,3.06198342767,284,,Écourt-Saint-Quentin,Écourt-Saint-Quentin,62,Pas-de-Calais,32,Hauts-de-France
62285,ECOUST ST MEIN,62128,ECOUST ST MEIN,,50.1738217586,2.90196588934,285,,Écoust-Saint-Mein,Écoust-Saint-Mein,62,Pas-de-Calais,32,Hauts-de-France
62286,ECQUEDECQUES,62190,ECQUEDECQUES,,50.5601376893,2.44103713077,286,,Ecquedecques,Ecquedecques,62,Pas-de-Calais,32,Hauts-de-France
62288,ECQUES,62129,ECQUES,,50.6751011115,2.28909761313,288,,Ecques,Ecques,62,Pas-de-Calais,32,Hauts-de-France
62289,ECUIRES,62170,ECUIRES,,50.4375878146,1.76808279831,289,,Écuires,Écuires,62,Pas-de-Calais,32,Hauts-de-France
62290,ECURIE,62223,ECURIE,,50.3328667178,2.76793109769,290,,Écurie,Écurie,62,Pas-de-Calais,32,Hauts-de-France
62291,ELEU DIT LEAUWETTE,62300,ELEU DIT LEAUWETTE,,50.4182625415,2.81150914933,291,,Éleu-dit-Leauwette,Éleu-dit-Leauwette,62,Pas-de-Calais,32,Hauts-de-France
62292,ELNES,62380,ELNES,,50.6860806657,2.11534643648,292,,Elnes,Elnes,62,Pas-de-Calais,32,Hauts-de-France
62293,EMBRY,62990,EMBRY,,50.4961583161,1.95703731112,293,,Embry,Embry,62,Pas-de-Calais,32,Hauts-de-France
62294,ENGUINEGATTE,62145,ENGUINEGATTE,,50.6082639968,2.26812644414,294,,Enguinegatte,Enguinegatte,62,Pas-de-Calais,32,Hauts-de-France
62295,ENQUIN LES MINES,62145,ENQUIN LES MINES,,50.5860830884,2.29343023005,295,,Enquin-lez-Guinegatte,Enquin-lez-Guinegatte,62,Pas-de-Calais,32,Hauts-de-France
62296,ENQUIN SUR BAILLONS,62650,ENQUIN SUR BAILLONS,,50.57162689,1.84772501897,296,,Enquin-sur-Baillons,Enquin-sur-Baillons,62,Pas-de-Calais,32,Hauts-de-France
62297,EPERLECQUES,62910,EPERLECQUES,,50.8183914819,2.16069366951,297,,Éperlecques,Éperlecques,62,Pas-de-Calais,32,Hauts-de-France
62298,EPINOY,62860,EPINOY,,50.2312654081,3.15940386461,298,,Épinoy,Épinoy,62,Pas-de-Calais,32,Hauts-de-France
62299,EPS,62134,EPS,,50.4548816215,2.29608712353,299,,Eps,Eps,62,Pas-de-Calais,32,Hauts-de-France
62300,EQUIHEN PLAGE,62224,EQUIHEN PLAGE,,50.6793131074,1.57627344046,300,,Équihen-Plage,Équihen-Plage,62,Pas-de-Calais,32,Hauts-de-France
62301,EQUIRRE,62134,EQUIRRE,,50.4795228175,2.23471114879,301,,Équirre,Équirre,62,Pas-de-Calais,32,Hauts-de-France
62302,ERGNY,62650,ERGNY,,50.5887514874,1.97473205888,302,,Ergny,Ergny,62,Pas-de-Calais,32,Hauts-de-France
62303,ERIN,62134,ERIN,,50.4304811529,2.21886321071,303,,Érin,Érin,62,Pas-de-Calais,32,Hauts-de-France
62304,ERNY ST JULIEN,62960,ERNY ST JULIEN,,50.5839233636,2.25140626649,304,,Erny-Saint-Julien,Erny-Saint-Julien,62,Pas-de-Calais,32,Hauts-de-France
62306,ERVILLERS,62121,ERVILLERS,,50.1644439777,2.82560253669,306,,Ervillers,Ervillers,62,Pas-de-Calais,32,Hauts-de-France
62307,ESCALLES,62179,ESCALLES,,50.9112503142,1.72061882421,307,,Escalles,Escalles,62,Pas-de-Calais,32,Hauts-de-France
62308,ESCOEUILLES,62850,ESCOEUILLES,,50.7236925633,1.93553476901,308,,Escœuilles,Escœuilles,62,Pas-de-Calais,32,Hauts-de-France
62309,ESQUERDES,62380,ESQUERDES,,50.7009057679,2.17889940044,309,,Esquerdes,Esquerdes,62,Pas-de-Calais,32,Hauts-de-France
62310,ESSARS,62400,ESSARS,,50.5483122969,2.66191048635,310,,Essars,Essars,62,Pas-de-Calais,32,Hauts-de-France
62311,ESTEVELLES,62880,ESTEVELLES,,50.4792333773,2.9072255969,311,,Estevelles,Estevelles,62,Pas-de-Calais,32,Hauts-de-France
62312,ESTREE,62170,ESTREE,,50.4955374947,1.80353730749,312,,Estrée,Estrée,62,Pas-de-Calais,32,Hauts-de-France
62313,ESTREE BLANCHE,62145,ESTREE BLANCHE,,50.5930088193,2.32026164871,313,,Estrée-Blanche,Estrée-Blanche,62,Pas-de-Calais,32,Hauts-de-France
62314,ESTREE CAUCHY,62690,ESTREE CAUCHY,,50.3991885256,2.61140942771,314,,Estrée-Cauchy,Estrée-Cauchy,62,Pas-de-Calais,32,Hauts-de-France
62315,ESTREELLES,62170,ESTREELLES,,50.5013343429,1.77932498079,315,,Estréelles,Estréelles,62,Pas-de-Calais,32,Hauts-de-France
62316,ESTREE WAMIN,62810,ESTREE WAMIN,,50.2689484994,2.39437823865,316,,Estrée-Wamin,Estrée-Wamin,62,Pas-de-Calais,32,Hauts-de-France
62317,ETAING,62156,ETAING,,50.2720246326,2.99815975036,317,,Étaing,Étaing,62,Pas-de-Calais,32,Hauts-de-France
62318,ETAPLES,62630,ETAPLES,,50.5235816785,1.65199985504,318,,Étaples,Étaples,62,Pas-de-Calais,32,Hauts-de-France
62319,ETERPIGNY,62156,ETERPIGNY,,50.2560087957,2.98044212985,319,,Éterpigny,Éterpigny,62,Pas-de-Calais,32,Hauts-de-France
62320,ETRUN,62161,ETRUN,,50.3171481839,2.68183149447,320,,Étrun,Étrun,62,Pas-de-Calais,32,Hauts-de-France
62321,EVIN MALMAISON,62141,EVIN MALMAISON,,50.4353563235,3.03002444738,321,,Évin-Malmaison,Évin-Malmaison,62,Pas-de-Calais,32,Hauts-de-France
62322,FAMECHON,62760,FAMECHON,,50.1439770504,2.45778315136,322,,Famechon,Famechon,62,Pas-de-Calais,32,Hauts-de-France
62323,FAMPOUX,62118,FAMPOUX,,50.2998803664,2.87181354867,323,,Fampoux,Fampoux,62,Pas-de-Calais,32,Hauts-de-France
62324,FARBUS,62580,FARBUS,,50.3602196651,2.82681103336,324,,Farbus,Farbus,62,Pas-de-Calais,32,Hauts-de-France
62325,FAUQUEMBERGUES,62560,FAUQUEMBERGUES,,50.5933148828,2.09984616722,325,,Fauquembergues,Fauquembergues,62,Pas-de-Calais,32,Hauts-de-France
62326,FAVREUIL,62450,FAVREUIL,,50.1256507438,2.85908637048,326,,Favreuil,Favreuil,62,Pas-de-Calais,32,Hauts-de-France
62327,FEBVIN PALFART,62960,FEBVIN PALFART,,50.5333989825,2.30304274848,327,,Febvin-Palfart,Febvin-Palfart,62,Pas-de-Calais,32,Hauts-de-France
62328,FERFAY,62260,FERFAY,,50.521404257,2.43125603761,328,,Ferfay,Ferfay,62,Pas-de-Calais,32,Hauts-de-France
62329,FERQUES,62250,FERQUES,,50.8288565205,1.7680736791,329,,Ferques,Ferques,62,Pas-de-Calais,32,Hauts-de-France
62330,FESTUBERT,62149,FESTUBERT,,50.5446581516,2.7365385521,330,,Festubert,Festubert,62,Pas-de-Calais,32,Hauts-de-France
62331,FEUCHY,62223,FEUCHY,,50.2847198713,2.84925984061,331,,Feuchy,Feuchy,62,Pas-de-Calais,32,Hauts-de-France
62332,FICHEUX,62173,FICHEUX,,50.2258571465,2.74623560668,332,,Ficheux,Ficheux,62,Pas-de-Calais,32,Hauts-de-France
62333,FIEFS,62134,FIEFS,,50.4999837138,2.32108014368,333,,Fiefs,Fiefs,62,Pas-de-Calais,32,Hauts-de-France
62334,FIENNES,62132,FIENNES,,50.8239442286,1.83126229183,334,,Fiennes,Fiennes,62,Pas-de-Calais,32,Hauts-de-France
62335,FILLIEVRES,62770,FILLIEVRES,,50.3171424803,2.16170089705,335,,Fillièvres,Fillièvres,62,Pas-de-Calais,32,Hauts-de-France
62336,FLECHIN,62960,FLECHIN,,50.5587478522,2.28119677571,336,,Fléchin,Fléchin,62,Pas-de-Calais,32,Hauts-de-France
62337,FLERS,62270,FLERS,,50.3212035167,2.25448501079,337,,Flers,Flers,62,Pas-de-Calais,32,Hauts-de-France
62338,FLEURBAIX,62840,FLEURBAIX,,50.6384888387,2.83066487498,338,,Fleurbaix,Fleurbaix,62,Pas-de-Calais,32,Hauts-de-France
62339,FLEURY,62134,FLEURY,,50.4235609474,2.25298412174,339,,Fleury,Fleury,62,Pas-de-Calais,32,Hauts-de-France
62340,FLORINGHEM,62550,FLORINGHEM,,50.497209636,2.42943014623,340,,Floringhem,Floringhem,62,Pas-de-Calais,32,Hauts-de-France
62341,FONCQUEVILLERS,62111,FONCQUEVILLERS,,50.1456346313,2.62269335519,341,,Foncquevillers,Foncquevillers,62,Pas-de-Calais,32,Hauts-de-France
62342,FONTAINE LES BOULANS,62134,FONTAINE LES BOULANS,,50.4986573015,2.28398463259,342,,Fontaine-lès-Boulans,Fontaine-lès-Boulans,62,Pas-de-Calais,32,Hauts-de-France
62343,FONTAINE LES CROISILLES,62128,FONTAINE LES CROISILLES,,50.2157524515,2.90436080494,343,,Fontaine-lès-Croisilles,Fontaine-lès-Croisilles,62,Pas-de-Calais,32,Hauts-de-France
62344,FONTAINE LES HERMANS,62550,FONTAINE LES HERMANS,,50.5275299556,2.33764506206,344,,Fontaine-lès-Hermans,Fontaine-lès-Hermans,62,Pas-de-Calais,32,Hauts-de-France
62345,FONTAINE L ETALON,62390,FONTAINE L ETALON,,50.3021964061,2.06687302766,345,,Fontaine-l'Étalon,Fontaine-l'Étalon,62,Pas-de-Calais,32,Hauts-de-France
62346,FORTEL EN ARTOIS,62270,FORTEL EN ARTOIS,,50.2579701722,2.22982753471,346,,Fortel-en-Artois,Fortel-en-Artois,62,Pas-de-Calais,32,Hauts-de-France
62347,FOSSEUX,62810,FOSSEUX,,50.2541868154,2.56492880738,347,,Fosseux,Fosseux,62,Pas-de-Calais,32,Hauts-de-France
62348,FOUFFLIN RICAMETZ,62130,FOUFFLIN RICAMETZ,,50.3499914405,2.38708909848,348,,Foufflin-Ricametz,Foufflin-Ricametz,62,Pas-de-Calais,32,Hauts-de-France
62349,FOUQUEREUIL,62232,FOUQUEREUIL,,50.5207754925,2.59890688489,349,,Fouquereuil,Fouquereuil,62,Pas-de-Calais,32,Hauts-de-France
62350,FOUQUIERES LES BETHUNE,62232,FOUQUIERES LES BETHUNE,,50.5124125189,2.61269523201,350,,Fouquières-lès-Béthune,Fouquières-lès-Béthune,62,Pas-de-Calais,32,Hauts-de-France
62351,FOUQUIERES LES LENS,62740,FOUQUIERES LES LENS,,50.4292454614,2.90611987264,351,,Fouquières-lès-Lens,Fouquières-lès-Lens,62,Pas-de-Calais,32,Hauts-de-France
62352,FRAMECOURT,62130,FRAMECOURT,,50.3232859684,2.30531032543,352,,Framecourt,Framecourt,62,Pas-de-Calais,32,Hauts-de-France
62353,FREMICOURT,62450,FREMICOURT,,50.112643271,2.90288307097,353,,Frémicourt,Frémicourt,62,Pas-de-Calais,32,Hauts-de-France
62354,FRENCQ,62630,FRENCQ,,50.5617772282,1.68949161438,354,,Frencq,Frencq,62,Pas-de-Calais,32,Hauts-de-France
62355,FRESNES LES MONTAUBAN,62490,FRESNES LES MONTAUBAN,,50.3362535574,2.92878850685,355,,Fresnes-lès-Montauban,Fresnes-lès-Montauban,62,Pas-de-Calais,32,Hauts-de-France
62356,FRESNICOURT LE DOLMEN,62150,FRESNICOURT LE DOLMEN,,50.4248203734,2.60417768475,356,,Fresnicourt-le-Dolmen,Fresnicourt-le-Dolmen,62,Pas-de-Calais,32,Hauts-de-France
62357,FRESNOY,62770,FRESNOY,,50.3739202398,2.13763944148,357,,Fresnoy,Fresnoy,62,Pas-de-Calais,32,Hauts-de-France
62358,FRESNOY EN GOHELLE,62580,FRESNOY EN GOHELLE,,50.3685369312,2.89601093482,358,,Fresnoy-en-Gohelle,Fresnoy-en-Gohelle,62,Pas-de-Calais,32,Hauts-de-France
62359,FRESSIN,62140,FRESSIN,,50.4488605209,2.05259326434,359,,Fressin,Fressin,62,Pas-de-Calais,32,Hauts-de-France
62360,FRETHUN,62185,FRETHUN,,50.9121649744,1.81683480747,360,,Fréthun,Fréthun,62,Pas-de-Calais,32,Hauts-de-France
62361,FREVENT,62270,FREVENT,,50.270911587,2.29293491451,361,,Frévent,Frévent,62,Pas-de-Calais,32,Hauts-de-France
62362,FREVILLERS,62127,FREVILLERS,,50.40086365,2.52420922895,362,,Frévillers,Frévillers,62,Pas-de-Calais,32,Hauts-de-France
62363,FREVIN CAPELLE,62690,FREVIN CAPELLE,,50.3505908973,2.63573550055,363,,Frévin-Capelle,Frévin-Capelle,62,Pas-de-Calais,32,Hauts-de-France
62364,FRUGES,62310,FRUGES,,50.5130111281,2.1230697319,364,,Fruges,Fruges,62,Pas-de-Calais,32,Hauts-de-France
62365,GALAMETZ,62770,GALAMETZ,,50.3258571911,2.13007564683,365,,Galametz,Galametz,62,Pas-de-Calais,32,Hauts-de-France
62366,GAUCHIN LEGAL,62150,GAUCHIN LEGAL,,50.408974638,2.57827135438,366,,Gauchin-Légal,Gauchin-Légal,62,Pas-de-Calais,32,Hauts-de-France
62367,GAUCHIN VERLOINGT,62130,GAUCHIN VERLOINGT,,50.3896471445,2.31551845708,367,,Gauchin-Verloingt,Gauchin-Verloingt,62,Pas-de-Calais,32,Hauts-de-France
62368,GAUDIEMPRE,62760,GAUDIEMPRE,,50.1751742719,2.52871563115,368,,Gaudiempré,Gaudiempré,62,Pas-de-Calais,32,Hauts-de-France
62369,GAVRELLE,62580,GAVRELLE,,50.3261836927,2.88569261923,369,,Gavrelle,Gavrelle,62,Pas-de-Calais,32,Hauts-de-France
62370,GENNES IVERGNY,62390,GENNES IVERGNY,,50.2741036632,2.06316402606,370,,Gennes-Ivergny,Gennes-Ivergny,62,Pas-de-Calais,32,Hauts-de-France
62371,GIVENCHY EN GOHELLE,62580,GIVENCHY EN GOHELLE,,50.3925166262,2.77782748947,371,,Givenchy-en-Gohelle,Givenchy-en-Gohelle,62,Pas-de-Calais,32,Hauts-de-France
62372,GIVENCHY LE NOBLE,62810,GIVENCHY LE NOBLE,,50.2999577291,2.4906551045,372,,Givenchy-le-Noble,Givenchy-le-Noble,62,Pas-de-Calais,32,Hauts-de-France
62373,GIVENCHY LES LA BASSEE,62149,GIVENCHY LES LA BASSEE,,50.5319568907,2.7603145898,373,,Givenchy-lès-la-Bassée,Givenchy-lès-la-Bassée,62,Pas-de-Calais,32,Hauts-de-France
62374,GOMIECOURT,62121,GOMIECOURT,,50.1482444554,2.80012683443,374,,Gomiécourt,Gomiécourt,62,Pas-de-Calais,32,Hauts-de-France
62375,GOMMECOURT,62111,GOMMECOURT,,50.1379205953,2.65284245293,375,,Gommecourt,Gommecourt,62,Pas-de-Calais,32,Hauts-de-France
62376,GONNEHEM,62920,GONNEHEM,,50.5645138274,2.56749470477,376,,Gonnehem,Gonnehem,62,Pas-de-Calais,32,Hauts-de-France
62377,GOSNAY,62199,GOSNAY,,50.5088591145,2.58721404812,377,,Gosnay,Gosnay,62,Pas-de-Calais,32,Hauts-de-France
62378,GOUVES,62123,GOUVES,,50.2964618298,2.64441572265,378,,Gouves,Gouves,62,Pas-de-Calais,32,Hauts-de-France
62379,GOUY EN ARTOIS,62123,GOUY EN ARTOIS,,50.2427202396,2.59069547826,379,,Gouy-en-Artois,Gouy-en-Artois,62,Pas-de-Calais,32,Hauts-de-France
62380,GOUY SERVINS,62530,GOUY SERVINS,,50.4026129183,2.65579808326,380,,Gouy-Servins,Gouy-Servins,62,Pas-de-Calais,32,Hauts-de-France
62381,GOUY EN TERNOIS,62127,GOUY EN TERNOIS,,50.3226610933,2.41085838264,381,,Gouy-en-Ternois,Gouy-en-Ternois,62,Pas-de-Calais,32,Hauts-de-France
62382,GOUY ST ANDRE,62870,GOUY ST ANDRE,,50.3737414142,1.90925846468,382,,Gouy-Saint-André,Gouy-Saint-André,62,Pas-de-Calais,32,Hauts-de-France
62383,GOUY SOUS BELLONNE,62112,GOUY SOUS BELLONNE,,50.3145124236,3.05826904717,383,,Gouy-sous-Bellonne,Gouy-sous-Bellonne,62,Pas-de-Calais,32,Hauts-de-France
62384,GRAINCOURT LES HAVRINCOURT,62147,GRAINCOURT LES HAVRINCOURT,,50.1424794757,3.09706084719,384,,Graincourt-lès-Havrincourt,Graincourt-lès-Havrincourt,62,Pas-de-Calais,32,Hauts-de-France
62385,GRAND RULLECOURT,62810,GRAND RULLECOURT,,50.2543107636,2.47447539793,385,,Grand-Rullecourt,Grand-Rullecourt,62,Pas-de-Calais,32,Hauts-de-France
62386,GRENAY,62160,GRENAY,,50.4496814864,2.74513302656,386,,Grenay,Grenay,62,Pas-de-Calais,32,Hauts-de-France
62387,GREVILLERS,62450,GREVILLERS,,50.1018030363,2.7985377862,387,,Grévillers,Grévillers,62,Pas-de-Calais,32,Hauts-de-France
62388,GRIGNY,62140,GRIGNY,,50.3823543208,2.06358217112,388,,Grigny,Grigny,62,Pas-de-Calais,32,Hauts-de-France
62389,GRINCOURT LES PAS,62760,GRINCOURT LES PAS,,50.1780241408,2.48803738534,389,,Grincourt-lès-Pas,Grincourt-lès-Pas,62,Pas-de-Calais,32,Hauts-de-France
62390,GROFFLIERS,62600,GROFFLIERS,,50.3852621579,1.60399303027,390,,Groffliers,Groffliers,62,Pas-de-Calais,32,Hauts-de-France
62391,GUARBECQUE,62330,GUARBECQUE,,50.6071737755,2.48934824993,391,,Guarbecque,Guarbecque,62,Pas-de-Calais,32,Hauts-de-France
62392,GUEMAPPE,62128,GUEMAPPE,,50.2507335159,2.89301877431,392,,Guémappe,Guémappe,62,Pas-de-Calais,32,Hauts-de-France
62393,GUEMPS,62370,GUEMPS,,50.9177532951,1.99730294047,393,,Guemps,Guemps,62,Pas-de-Calais,32,Hauts-de-France
62395,GUIGNY,62140,GUIGNY,,50.3280392669,1.99522776546,395,,Guigny,Guigny,62,Pas-de-Calais,32,Hauts-de-France
62396,GUINECOURT,62130,GUINECOURT,,50.3477039372,2.22804664838,396,,Guinecourt,Guinecourt,62,Pas-de-Calais,32,Hauts-de-France
62397,GUINES,62340,GUINES,,50.8582256362,1.87057931185,397,,Guînes,Guînes,62,Pas-de-Calais,32,Hauts-de-France
62398,GUISY,62140,GUISY,,50.3877493268,2.00263953159,398,,Guisy,Guisy,62,Pas-de-Calais,32,Hauts-de-France
62399,HABARCQ,62123,HABARCQ,,50.3112006109,2.60710466393,399,,Habarcq,Habarcq,62,Pas-de-Calais,32,Hauts-de-France
62400,HAILLICOURT,62940,HAILLICOURT,,50.4758957511,2.57933383024,400,,Haillicourt,Haillicourt,62,Pas-de-Calais,32,Hauts-de-France
62401,HAISNES,62138,HAISNES,,50.500965786,2.79702254759,401,,Haisnes,Haisnes,62,Pas-de-Calais,32,Hauts-de-France
62402,HALINGHEN,62830,HALINGHEN,,50.6041744819,1.68261824682,402,,Halinghen,Halinghen,62,Pas-de-Calais,32,Hauts-de-France
62403,HALLINES,62570,HALLINES,,50.7007255503,2.20827364581,403,,Hallines,Hallines,62,Pas-de-Calais,32,Hauts-de-France
62404,HALLOY,62760,HALLOY,,50.1614697022,2.42474392928,404,,Halloy,Halloy,62,Pas-de-Calais,32,Hauts-de-France
62405,HAMBLAIN LES PRES,62118,HAMBLAIN LES PRES,,50.2910559245,2.95821572161,405,,Hamblain-les-Prés,Hamblain-les-Prés,62,Pas-de-Calais,32,Hauts-de-France
62406,HAMELINCOURT,62121,HAMELINCOURT,,50.1833996066,2.80226363556,406,,Hamelincourt,Hamelincourt,62,Pas-de-Calais,32,Hauts-de-France
62407,HAM EN ARTOIS,62190,HAM EN ARTOIS,,50.5916560768,2.4526238091,407,,Ham-en-Artois,Ham-en-Artois,62,Pas-de-Calais,32,Hauts-de-France
62408,HAMES BOUCRES,62340,HAMES BOUCRES,,50.8843797191,1.8485231785,408,,Hames-Boucres,Hames-Boucres,62,Pas-de-Calais,32,Hauts-de-France
62409,HANNESCAMPS,62111,HANNESCAMPS,,50.1615495622,2.64192588392,409,,Hannescamps,Hannescamps,62,Pas-de-Calais,32,Hauts-de-France
62410,HAPLINCOURT,62124,HAPLINCOURT,,50.0929484431,2.93693106881,410,,Haplincourt,Haplincourt,62,Pas-de-Calais,32,Hauts-de-France
62411,HARAVESNES,62390,HARAVESNES,,50.2847351527,2.12582011822,411,,Haravesnes,Haravesnes,62,Pas-de-Calais,32,Hauts-de-France
62412,HARDINGHEN,62132,HARDINGHEN,,50.7968528137,1.82812334781,412,,Hardinghen,Hardinghen,62,Pas-de-Calais,32,Hauts-de-France
62413,HARNES,62440,HARNES,,50.4516887565,2.90335898213,413,,Harnes,Harnes,62,Pas-de-Calais,32,Hauts-de-France
62414,HAUCOURT,62156,HAUCOURT,,50.2366546486,2.96966672137,414,,Haucourt,Haucourt,62,Pas-de-Calais,32,Hauts-de-France
62415,HAUTE AVESNES,62144,HAUTE AVESNES,,50.3298562005,2.63592929368,415,,Haute-Avesnes,Haute-Avesnes,62,Pas-de-Calais,32,Hauts-de-France
62416,HAUTECLOQUE,62130,HAUTECLOQUE,,50.3338971726,2.31705625508,416,,Hautecloque,Hautecloque,62,Pas-de-Calais,32,Hauts-de-France
62418,HAUTEVILLE,62810,HAUTEVILLE,,50.2717222163,2.56838764568,418,,Hauteville,Hauteville,62,Pas-de-Calais,32,Hauts-de-France
62419,HAUT LOQUIN,62850,HAUT LOQUIN,,50.7379262703,1.96340572061,419,,Haut-Loquin,Haut-Loquin,62,Pas-de-Calais,32,Hauts-de-France
62421,HAVRINCOURT,62147,HAVRINCOURT,,50.1006868952,3.07267779614,421,,Havrincourt,Havrincourt,62,Pas-de-Calais,32,Hauts-de-France
62422,HEBUTERNE,62111,HEBUTERNE,,50.1180452685,2.64625006269,422,,Hébuterne,Hébuterne,62,Pas-de-Calais,32,Hauts-de-France
62423,HELFAUT,62570,HELFAUT,,50.6917027903,2.25015585426,423,,Helfaut,Helfaut,62,Pas-de-Calais,32,Hauts-de-France
62424,HENDECOURT LES CAGNICOURT,62182,HENDECOURT LES CAGNICOURT,,50.2147999984,2.94877835826,424,,Hendecourt-lès-Cagnicourt,Hendecourt-lès-Cagnicourt,62,Pas-de-Calais,32,Hauts-de-France
62425,HENDECOURT LES RANSART,62175,HENDECOURT LES RANSART,,50.2083901157,2.73142877157,425,,Hendecourt-lès-Ransart,Hendecourt-lès-Ransart,62,Pas-de-Calais,32,Hauts-de-France
62426,HENINEL,62128,HENINEL,,50.2331382255,2.87160951227,426,,Héninel,Héninel,62,Pas-de-Calais,32,Hauts-de-France
62427,HENIN BEAUMONT,62110,HENIN BEAUMONT,,50.4092335517,2.95899735168,427,,Hénin-Beaumont,Hénin-Beaumont,62,Pas-de-Calais,32,Hauts-de-France
62428,HENIN SUR COJEUL,62128,HENIN SUR COJEUL,,50.2187895959,2.838987557,428,,Hénin-sur-Cojeul,Hénin-sur-Cojeul,62,Pas-de-Calais,32,Hauts-de-France
62429,HENNEVEUX,62142,HENNEVEUX,,50.7258740136,1.84583997521,429,,Henneveux,Henneveux,62,Pas-de-Calais,32,Hauts-de-France
62430,HENU,62760,HENU,,50.1563296482,2.5317199579,430,,Hénu,Hénu,62,Pas-de-Calais,32,Hauts-de-France
62431,HERBELLES,62129,HERBELLES,,50.6556656049,2.22364943772,431,,Herbelles,Herbelles,62,Pas-de-Calais,32,Hauts-de-France
62432,HERBINGHEN,62850,HERBINGHEN,,50.7636525295,1.89780581848,432,,Herbinghen,Herbinghen,62,Pas-de-Calais,32,Hauts-de-France
62433,HERICOURT,62130,HERICOURT,,50.3379712527,2.24874588384,433,,Héricourt,Héricourt,62,Pas-de-Calais,32,Hauts-de-France
62434,LA HERLIERE,62158,LA HERLIERE,,50.1989955706,2.55855876678,434,La,Herlière,La Herlière,62,Pas-de-Calais,32,Hauts-de-France
62435,HERLINCOURT,62130,HERLINCOURT,,50.3443144148,2.29198306532,435,,Herlincourt,Herlincourt,62,Pas-de-Calais,32,Hauts-de-France
62436,HERLIN LE SEC,62130,HERLIN LE SEC,,50.3533382181,2.33277640011,436,,Herlin-le-Sec,Herlin-le-Sec,62,Pas-de-Calais,32,Hauts-de-France
62437,HERLY,62650,HERLY,,50.5415450848,1.99010495982,437,,Herly,Herly,62,Pas-de-Calais,32,Hauts-de-France
62438,HERMAVILLE,62690,HERMAVILLE,,50.325082478,2.58386198522,438,,Hermaville,Hermaville,62,Pas-de-Calais,32,Hauts-de-France
62439,HERMELINGHEN,62132,HERMELINGHEN,,50.8043182752,1.86169968318,439,,Hermelinghen,Hermelinghen,62,Pas-de-Calais,32,Hauts-de-France
62440,HERMIES,62147,HERMIES,,50.1094195341,3.03670684063,440,,Hermies,Hermies,62,Pas-de-Calais,32,Hauts-de-France
62441,HERMIN,62150,HERMIN,,50.4135492599,2.55237244713,441,,Hermin,Hermin,62,Pas-de-Calais,32,Hauts-de-France
62442,HERNICOURT,62130,HERNICOURT,,50.4147047187,2.30775344747,442,,Hernicourt,Hernicourt,62,Pas-de-Calais,32,Hauts-de-France
62443,HERSIN COUPIGNY,62530,HERSIN COUPIGNY,,50.4455468429,2.64235771503,443,,Hersin-Coupigny,Hersin-Coupigny,62,Pas-de-Calais,32,Hauts-de-France
62444,HERVELINGHEN,62179,HERVELINGHEN,,50.8844468643,1.7189212303,444,,Hervelinghen,Hervelinghen,62,Pas-de-Calais,32,Hauts-de-France
62445,HESDIGNEUL LES BETHUNE,62196,HESDIGNEUL LES BETHUNE,,50.4965750907,2.5966608131,445,,Hesdigneul-lès-Béthune,Hesdigneul-lès-Béthune,62,Pas-de-Calais,32,Hauts-de-France
62446,HESDIGNEUL LES BOULOGNE,62360,HESDIGNEUL LES BOULOGNE,,50.6523596946,1.67768618773,446,,Hesdigneul-lès-Boulogne,Hesdigneul-lès-Boulogne,62,Pas-de-Calais,32,Hauts-de-France
62447,HESDIN,62140,HESDIN,,50.3737561031,2.03813237444,447,,Hesdin,Hesdin,62,Pas-de-Calais,32,Hauts-de-France
62448,HESDIN L ABBE,62360,HESDIN L ABBE,,50.6687608865,1.68795327602,448,,Hesdin-l'Abbé,Hesdin-l'Abbé,62,Pas-de-Calais,32,Hauts-de-France
62449,HESMOND,62990,HESMOND,,50.4640913287,1.94222442678,449,,Hesmond,Hesmond,62,Pas-de-Calais,32,Hauts-de-France
62450,HESTRUS,62550,HESTRUS,,50.4493020094,2.32930818602,450,,Hestrus,Hestrus,62,Pas-de-Calais,32,Hauts-de-France
62451,HEUCHIN,62134,HEUCHIN,,50.4808803919,2.27062622618,451,,Heuchin,Heuchin,62,Pas-de-Calais,32,Hauts-de-France
62452,HEURINGHEM,62575,HEURINGHEM,,50.6957474078,2.29391988057,452,,Heuringhem,Heuringhem,62,Pas-de-Calais,32,Hauts-de-France
62453,HEZECQUES,62310,HEZECQUES,,50.5341909306,2.19182773822,453,,Hézecques,Hézecques,62,Pas-de-Calais,32,Hauts-de-France
62454,HINGES,62232,HINGES,,50.5707161285,2.62942563762,454,,Hinges,Hinges,62,Pas-de-Calais,32,Hauts-de-France
62455,HOCQUINGHEN,62850,HOCQUINGHEN,,50.7669354784,1.93243678254,455,,Hocquinghen,Hocquinghen,62,Pas-de-Calais,32,Hauts-de-France
62456,HOUCHIN,62620,HOUCHIN,,50.4791705559,2.61907091985,456,,Houchin,Houchin,62,Pas-de-Calais,32,Hauts-de-France
62457,HOUDAIN,62150,HOUDAIN,,50.4548178375,2.53664303679,457,,Houdain,Houdain,62,Pas-de-Calais,32,Hauts-de-France
62458,HOULLE,62910,HOULLE,,50.7969936327,2.16484725691,458,,Houlle,Houlle,62,Pas-de-Calais,32,Hauts-de-France
62459,HOUVIN HOUVIGNEUL,62270,HOUVIN HOUVIGNEUL,,50.2960440098,2.37739765685,459,,Houvin-Houvigneul,Houvin-Houvigneul,62,Pas-de-Calais,32,Hauts-de-France
62460,HUBERSENT,62630,HUBERSENT,,50.5830281628,1.73838024518,460,,Hubersent,Hubersent,62,Pas-de-Calais,32,Hauts-de-France
62461,HUBY ST LEU,62140,HUBY ST LEU,,50.3946394663,2.02454954867,461,,Huby-Saint-Leu,Huby-Saint-Leu,62,Pas-de-Calais,32,Hauts-de-France
62462,HUCLIER,62130,HUCLIER,,50.4369559747,2.35470715306,462,,Huclier,Huclier,62,Pas-de-Calais,32,Hauts-de-France
62463,HUCQUELIERS,62650,HUCQUELIERS,,50.5684694236,1.91564949795,463,,Hucqueliers,Hucqueliers,62,Pas-de-Calais,32,Hauts-de-France
62464,HULLUCH,62410,HULLUCH,,50.4843039018,2.81123592957,464,,Hulluch,Hulluch,62,Pas-de-Calais,32,Hauts-de-France
62465,HUMBERCAMPS,62158,HUMBERCAMPS,,50.1884753334,2.5745331886,465,,Humbercamps,Humbercamps,62,Pas-de-Calais,32,Hauts-de-France
62466,HUMBERT,62650,HUMBERT,,50.5005174956,1.90980020513,466,,Humbert,Humbert,62,Pas-de-Calais,32,Hauts-de-France
62467,HUMEROEUILLE,62130,HUMEROEUILLE,,50.4085299581,2.20983576549,467,,Humerœuille,Humerœuille,62,Pas-de-Calais,32,Hauts-de-France
62468,HUMIERES,62130,HUMIERES,,50.3848586497,2.20826918939,468,,Humières,Humières,62,Pas-de-Calais,32,Hauts-de-France
62469,INCHY EN ARTOIS,62860,INCHY EN ARTOIS,,50.1745599898,3.03944320203,469,,Inchy-en-Artois,Inchy-en-Artois,62,Pas-de-Calais,32,Hauts-de-France
62470,INCOURT,62770,INCOURT,,50.3873539733,2.15038878907,470,,Incourt,Incourt,62,Pas-de-Calais,32,Hauts-de-France
62471,INGHEM,62129,INGHEM,,50.6690392574,2.24271033014,471,,Bellinghem,Bellinghem,62,Pas-de-Calais,32,Hauts-de-France
62472,INXENT,62170,INXENT,,50.5367422786,1.78814127158,472,,Inxent,Inxent,62,Pas-de-Calais,32,Hauts-de-France
62473,ISBERGUES,62330,ISBERGUES,,50.6163010723,2.45566347831,473,,Isbergues,Isbergues,62,Pas-de-Calais,32,Hauts-de-France
62473,ISBERGUES,62330,ISBERGUES,BERGUETTE,50.6163010723,2.45566347831,473,,Isbergues,Isbergues,62,Pas-de-Calais,32,Hauts-de-France
62473,ISBERGUES,62330,ISBERGUES,MOLINGHEM,50.6163010723,2.45566347831,473,,Isbergues,Isbergues,62,Pas-de-Calais,32,Hauts-de-France
62474,ISQUES,62360,ISQUES,,50.6822962991,1.65634356393,474,,Isques,Isques,62,Pas-de-Calais,32,Hauts-de-France
62475,IVERGNY,62810,IVERGNY,,50.2380889989,2.38095249535,475,,Ivergny,Ivergny,62,Pas-de-Calais,32,Hauts-de-France
62476,IZEL LES EQUERCHIN,62490,IZEL LES EQUERCHIN,,50.362470832,2.94589813108,476,,Izel-lès-Équerchin,Izel-lès-Équerchin,62,Pas-de-Calais,32,Hauts-de-France
62477,IZEL LES HAMEAU,62690,IZEL LES HAMEAU,,50.3147014081,2.53156899896,477,,Izel-lès-Hameau,Izel-lès-Hameau,62,Pas-de-Calais,32,Hauts-de-France
62478,JOURNY,62850,JOURNY,,50.7533388277,2.0030860324,478,,Journy,Journy,62,Pas-de-Calais,32,Hauts-de-France
62479,LABEUVRIERE,62122,LABEUVRIERE,,50.5215621853,2.57161381831,479,,Labeuvrière,Labeuvrière,62,Pas-de-Calais,32,Hauts-de-France
62480,LABOURSE,62113,LABOURSE,,50.4933712663,2.68345429185,480,,Labourse,Labourse,62,Pas-de-Calais,32,Hauts-de-France
62481,LABROYE,62140,LABROYE,,50.2935984532,1.98515419117,481,,Labroye,Labroye,62,Pas-de-Calais,32,Hauts-de-France
62483,LACRES,62830,LACRES,,50.6034546008,1.76197213447,483,,Lacres,Lacres,62,Pas-de-Calais,32,Hauts-de-France
62484,LAGNICOURT MARCEL,62159,LAGNICOURT MARCEL,,50.1560504506,2.95664780211,484,,Lagnicourt-Marcel,Lagnicourt-Marcel,62,Pas-de-Calais,32,Hauts-de-France
62485,LAIRES,62960,LAIRES,,50.5378767018,2.25617757034,485,,Laires,Laires,62,Pas-de-Calais,32,Hauts-de-France
62486,LAMBRES,62120,LAMBRES,,50.6180000635,2.40481322183,486,,Lambres,Lambres,62,Pas-de-Calais,32,Hauts-de-France
62487,LANDRETHUN LE NORD,62250,LANDRETHUN LE NORD,,50.8498865079,1.77882908623,487,,Landrethun-le-Nord,Landrethun-le-Nord,62,Pas-de-Calais,32,Hauts-de-France
62488,LANDRETHUN LES ARDRES,62610,LANDRETHUN LES ARDRES,,50.816140117,1.96682595063,488,,Landrethun-lès-Ardres,Landrethun-lès-Ardres,62,Pas-de-Calais,32,Hauts-de-France
62489,LAPUGNOY,62122,LAPUGNOY,,50.5191431274,2.53207580559,489,,Lapugnoy,Lapugnoy,62,Pas-de-Calais,32,Hauts-de-France
62490,LATTRE ST QUENTIN,62810,LATTRE ST QUENTIN,,50.2971861104,2.57210181191,490,,Lattre-Saint-Quentin,Lattre-Saint-Quentin,62,Pas-de-Calais,32,Hauts-de-France
62491,LAVENTIE,62840,LAVENTIE,,50.6122806488,2.78361228614,491,,Laventie,Laventie,62,Pas-de-Calais,32,Hauts-de-France
62492,LEBIEZ,62990,LEBIEZ,,50.4592668371,1.97830860496,492,,Lebiez,Lebiez,62,Pas-de-Calais,32,Hauts-de-France
62493,LEBUCQUIERE,62124,LEBUCQUIERE,,50.1099498863,2.95661100637,493,,Lebucquière,Lebucquière,62,Pas-de-Calais,32,Hauts-de-France
62494,LECHELLE,62124,LECHELLE,,50.0545425148,2.97707265499,494,,Léchelle,Léchelle,62,Pas-de-Calais,32,Hauts-de-France
62495,LEDINGHEM,62380,LEDINGHEM,,50.6449906532,1.97365993925,495,,Ledinghem,Ledinghem,62,Pas-de-Calais,32,Hauts-de-France
62496,LEFAUX,62630,LEFAUX,,50.5462607365,1.65372158326,496,,Lefaux,Lefaux,62,Pas-de-Calais,32,Hauts-de-France
62497,LEFOREST,62790,LEFOREST,,50.4394110642,3.05859008926,497,,Leforest,Leforest,62,Pas-de-Calais,32,Hauts-de-France
62498,LENS,62300,LENS,,50.4374264148,2.82105851259,498,,Lens,Lens,62,Pas-de-Calais,32,Hauts-de-France
62499,LEPINE,62170,LEPINE,,50.3851141972,1.72825357976,499,,Lépine,Lépine,62,Pas-de-Calais,32,Hauts-de-France
62500,LESPESSES,62190,LESPESSES,,50.5552927057,2.42428623219,500,,Lespesses,Lespesses,62,Pas-de-Calais,32,Hauts-de-France
62501,LESPINOY,62990,LESPINOY,,50.4276154192,1.86447871866,501,,Lespinoy,Lespinoy,62,Pas-de-Calais,32,Hauts-de-France
62502,LESTREM,62136,LESTREM,,50.6119032822,2.6781346301,502,,Lestrem,Lestrem,62,Pas-de-Calais,32,Hauts-de-France
62503,LEUBRINGHEN,62250,LEUBRINGHEN,,50.853723675,1.73268337788,503,,Leubringhen,Leubringhen,62,Pas-de-Calais,32,Hauts-de-France
62504,LEULINGHEM,62500,LEULINGHEM,,50.7346790388,2.16911709043,504,,Leulinghem,Leulinghem,62,Pas-de-Calais,32,Hauts-de-France
62505,LEULINGHEN BERNES,62250,LEULINGHEN BERNES,,50.8371171137,1.71313037263,505,,Leulinghen-Bernes,Leulinghen-Bernes,62,Pas-de-Calais,32,Hauts-de-France
62506,LICQUES,62850,LICQUES,,50.7962798733,1.9348768777,506,,Licques,Licques,62,Pas-de-Calais,32,Hauts-de-France
62507,LIENCOURT,62810,LIENCOURT,,50.2739197024,2.44860991608,507,,Liencourt,Liencourt,62,Pas-de-Calais,32,Hauts-de-France
62508,LIERES,62190,LIERES,,50.5490835835,2.40945021621,508,,Lières,Lières,62,Pas-de-Calais,32,Hauts-de-France
62509,LIETTRES,62145,LIETTRES,,50.5965757151,2.33945536026,509,,Liettres,Liettres,62,Pas-de-Calais,32,Hauts-de-France
62510,LIEVIN,62800,LIEVIN,,50.4242782538,2.77291885384,510,,Liévin,Liévin,62,Pas-de-Calais,32,Hauts-de-France
62511,LIGNEREUIL,62810,LIGNEREUIL,,50.2913104341,2.47081083726,511,,Lignereuil,Lignereuil,62,Pas-de-Calais,32,Hauts-de-France
62512,LIGNY LES AIRE,62960,LIGNY LES AIRE,,50.563205487,2.33673596363,512,,Ligny-lès-Aire,Ligny-lès-Aire,62,Pas-de-Calais,32,Hauts-de-France
62513,LIGNY SUR CANCHE,62270,LIGNY SUR CANCHE,,50.2840846482,2.26049042414,513,,Ligny-sur-Canche,Ligny-sur-Canche,62,Pas-de-Calais,32,Hauts-de-France
62514,LIGNY ST FLOCHEL,62127,LIGNY ST FLOCHEL,,50.3584786276,2.42321914967,514,,Ligny-Saint-Flochel,Ligny-Saint-Flochel,62,Pas-de-Calais,32,Hauts-de-France
62515,LIGNY THILLOY,62450,LIGNY THILLOY,,50.0798753739,2.82498264917,515,,Ligny-Thilloy,Ligny-Thilloy,62,Pas-de-Calais,32,Hauts-de-France
62516,LILLERS,62190,LILLERS,,50.5614379612,2.48197499021,516,,Lillers,Lillers,62,Pas-de-Calais,32,Hauts-de-France
62517,LINGHEM,62120,LINGHEM,,50.5897222044,2.37264094171,517,,Linghem,Linghem,62,Pas-de-Calais,32,Hauts-de-France
62518,LINZEUX,62270,LINZEUX,,50.3424338699,2.20289720787,518,,Linzeux,Linzeux,62,Pas-de-Calais,32,Hauts-de-France
62519,LISBOURG,62134,LISBOURG,,50.5109473164,2.22306817225,519,,Lisbourg,Lisbourg,62,Pas-de-Calais,32,Hauts-de-France
62520,LOCON,62400,LOCON,,50.570143515,2.66701720965,520,,Locon,Locon,62,Pas-de-Calais,32,Hauts-de-France
62521,LA LOGE,62140,LA LOGE,,50.4088782853,2.03527757207,521,La,Loge,La Loge,62,Pas-de-Calais,32,Hauts-de-France
62522,LOISON SUR CREQUOISE,62990,LOISON SUR CREQUOISE,,50.4449700996,1.91909184151,522,,Loison-sur-Créquoise,Loison-sur-Créquoise,62,Pas-de-Calais,32,Hauts-de-France
62523,LOISON SOUS LENS,62218,LOISON SOUS LENS,,50.4435308346,2.85832933206,523,,Loison-sous-Lens,Loison-sous-Lens,62,Pas-de-Calais,32,Hauts-de-France
62524,LONGFOSSE,62240,LONGFOSSE,,50.6545422678,1.80794052393,524,,Longfossé,Longfossé,62,Pas-de-Calais,32,Hauts-de-France
62525,LONGUENESSE,62219,LONGUENESSE,,50.7356717764,2.24445927669,525,,Longuenesse,Longuenesse,62,Pas-de-Calais,32,Hauts-de-France
62526,LONGUEVILLE,62142,LONGUEVILLE,,50.7288500512,1.88045163542,526,,Longueville,Longueville,62,Pas-de-Calais,32,Hauts-de-France
62527,LONGVILLIERS,62630,LONGVILLIERS,,50.5350281811,1.74349784296,527,,Longvilliers,Longvilliers,62,Pas-de-Calais,32,Hauts-de-France
62528,LOOS EN GOHELLE,62750,LOOS EN GOHELLE,,50.4567931082,2.78572759998,528,,Loos-en-Gohelle,Loos-en-Gohelle,62,Pas-de-Calais,32,Hauts-de-France
62529,LORGIES,62840,LORGIES,,50.5665642235,2.79775803436,529,,Lorgies,Lorgies,62,Pas-de-Calais,32,Hauts-de-France
62530,LOTTINGHEN,62240,LOTTINGHEN,,50.6772682361,1.93556689371,530,,Lottinghen,Lottinghen,62,Pas-de-Calais,32,Hauts-de-France
62531,LOUCHES,62610,LOUCHES,,50.8268079904,1.99961507698,531,,Louches,Louches,62,Pas-de-Calais,32,Hauts-de-France
62532,LOZINGHEM,62540,LOZINGHEM,,50.516650369,2.49540704274,532,,Lozinghem,Lozinghem,62,Pas-de-Calais,32,Hauts-de-France
62533,LUGY,62310,LUGY,,50.5217114727,2.17428495837,533,,Lugy,Lugy,62,Pas-de-Calais,32,Hauts-de-France
62534,LUMBRES,62380,LUMBRES,,50.705094418,2.11706482499,534,,Lumbres,Lumbres,62,Pas-de-Calais,32,Hauts-de-France
62535,LA MADELAINE SOUS MONTREUIL,62170,LA MADELAINE SOUS MONTREUIL,,50.4657638325,1.74915066351,535,La,Madelaine-sous-Montreuil,La Madelaine-sous-Montreuil,62,Pas-de-Calais,32,Hauts-de-France
62536,MAGNICOURT EN COMTE,62127,MAGNICOURT EN COMTE,,50.4032927894,2.48186461999,536,,Magnicourt-en-Comte,Magnicourt-en-Comte,62,Pas-de-Calais,32,Hauts-de-France
62537,MAGNICOURT SUR CANCHE,62270,MAGNICOURT SUR CANCHE,,50.2997948915,2.41112193284,537,,Magnicourt-sur-Canche,Magnicourt-sur-Canche,62,Pas-de-Calais,32,Hauts-de-France
62538,MAINTENAY,62870,MAINTENAY,,50.369972884,1.81633084989,538,,Maintenay,Maintenay,62,Pas-de-Calais,32,Hauts-de-France
62539,MAISNIL,62130,MAISNIL,,50.346716103,2.3577236191,539,,Maisnil,Maisnil,62,Pas-de-Calais,32,Hauts-de-France
62540,MAISNIL LES RUITZ,62620,MAISNIL LES RUITZ,,50.4477765466,2.57828775042,540,,Maisnil-lès-Ruitz,Maisnil-lès-Ruitz,62,Pas-de-Calais,32,Hauts-de-France
62541,MAISONCELLE,62310,MAISONCELLE,,50.4509090574,2.14815135855,541,,Maisoncelle,Maisoncelle,62,Pas-de-Calais,32,Hauts-de-France
62542,MAIZIERES,62127,MAIZIERES,,50.32212095,2.44126600282,542,,Maizières,Maizières,62,Pas-de-Calais,32,Hauts-de-France
62543,MAMETZ,62120,MAMETZ,,50.6294533649,2.30938013681,543,,Mametz,Mametz,62,Pas-de-Calais,32,Hauts-de-France
62544,MANIN,62810,MANIN,,50.2966374738,2.5072774682,544,,Manin,Manin,62,Pas-de-Calais,32,Hauts-de-France
62545,MANINGHEM,62650,MANINGHEM,,50.5480187009,1.9398392621,545,,Maninghem,Maninghem,62,Pas-de-Calais,32,Hauts-de-France
62546,MANINGHEN HENNE,62250,MANINGHEN HENNE,,50.7698364522,1.6724792037,546,,Maninghen-Henne,Maninghen-Henne,62,Pas-de-Calais,32,Hauts-de-France
62547,MARANT,62170,MARANT,,50.4709192925,1.84034009219,547,,Marant,Marant,62,Pas-de-Calais,32,Hauts-de-France
62548,MARCK,62730,MARCK,,50.9539156079,1.94945765215,548,,Marck,Marck,62,Pas-de-Calais,32,Hauts-de-France
62549,MARCONNE,62140,MARCONNE,,50.3661822096,2.03231054119,549,,Marconne,Marconne,62,Pas-de-Calais,32,Hauts-de-France
62550,MARCONNELLE,62140,MARCONNELLE,,50.369452969,2.00711158449,550,,Marconnelle,Marconnelle,62,Pas-de-Calais,32,Hauts-de-France
62551,MARENLA,62990,MARENLA,,50.4521838936,1.87453885296,551,,Marenla,Marenla,62,Pas-de-Calais,32,Hauts-de-France
62552,MARESQUEL ECQUEMICOURT,62990,MARESQUEL ECQUEMICOURT,,50.3999401675,1.93489288336,552,,Maresquel-Ecquemicourt,Maresquel-Ecquemicourt,62,Pas-de-Calais,32,Hauts-de-France
62553,MAREST,62550,MAREST,,50.4670398544,2.41815861804,553,,Marest,Marest,62,Pas-de-Calais,32,Hauts-de-France
62554,MARESVILLE,62630,MARESVILLE,,50.5232708818,1.73018696482,554,,Maresville,Maresville,62,Pas-de-Calais,32,Hauts-de-France
62555,MARLES LES MINES,62540,MARLES LES MINES,,50.5020046484,2.50636353506,555,,Marles-les-Mines,Marles-les-Mines,62,Pas-de-Calais,32,Hauts-de-France
62556,MARLES SUR CANCHE,62170,MARLES SUR CANCHE,,50.4638642323,1.8200426932,556,,Marles-sur-Canche,Marles-sur-Canche,62,Pas-de-Calais,32,Hauts-de-France
62557,MAROEUIL,62161,MAROEUIL,,50.3306055435,2.71035583524,557,,Marœuil,Marœuil,62,Pas-de-Calais,32,Hauts-de-France
62558,MARQUAY,62127,MARQUAY,,50.380374701,2.42143290213,558,,Marquay,Marquay,62,Pas-de-Calais,32,Hauts-de-France
62559,MARQUION,62860,MARQUION,,50.2041974457,3.09602784111,559,,Marquion,Marquion,62,Pas-de-Calais,32,Hauts-de-France
62560,MARQUISE,62250,MARQUISE,,50.8125786719,1.70113966333,560,,Marquise,Marquise,62,Pas-de-Calais,32,Hauts-de-France
62561,MARTINPUICH,62450,MARTINPUICH,,50.0511586041,2.76881719032,561,,Martinpuich,Martinpuich,62,Pas-de-Calais,32,Hauts-de-France
62562,MATRINGHEM,62310,MATRINGHEM,,50.5456223403,2.16665746182,562,,Matringhem,Matringhem,62,Pas-de-Calais,32,Hauts-de-France
62563,MAZINGARBE,62670,MAZINGARBE,,50.4684201259,2.72581419392,563,,Mazingarbe,Mazingarbe,62,Pas-de-Calais,32,Hauts-de-France
62564,MAZINGHEM,62120,MAZINGHEM,,50.6049243005,2.41314605386,564,,Mazinghem,Mazinghem,62,Pas-de-Calais,32,Hauts-de-France
62565,MENCAS,62310,MENCAS,,50.5575500609,2.14482012062,565,,Mencas,Mencas,62,Pas-de-Calais,32,Hauts-de-France
62566,MENNEVILLE,62240,MENNEVILLE,,50.6741251308,1.86280714354,566,,Menneville,Menneville,62,Pas-de-Calais,32,Hauts-de-France
62567,MENTQUE NORTBECOURT,62890,MENTQUE NORTBECOURT,,50.7729975278,2.09168559465,567,,Mentque-Nortbécourt,Mentque-Nortbécourt,62,Pas-de-Calais,32,Hauts-de-France
62568,MERCATEL,62217,MERCATEL,,50.2345646876,2.78842840897,568,,Mercatel,Mercatel,62,Pas-de-Calais,32,Hauts-de-France
62569,MERCK ST LIEVIN,62560,MERCK ST LIEVIN,,50.6363344754,2.10761618871,569,,Merck-Saint-Liévin,Merck-Saint-Liévin,62,Pas-de-Calais,32,Hauts-de-France
62570,MERICOURT,62680,MERICOURT,,50.3992003873,2.86819314424,570,,Méricourt,Méricourt,62,Pas-de-Calais,32,Hauts-de-France
62571,MERLIMONT,62155,MERLIMONT,,50.4528272137,1.60785781096,571,,Merlimont,Merlimont,62,Pas-de-Calais,32,Hauts-de-France
62572,METZ EN COUTURE,62124,METZ EN COUTURE,,50.0624565501,3.06272750972,572,,Metz-en-Couture,Metz-en-Couture,62,Pas-de-Calais,32,Hauts-de-France
62573,MEURCHIN,62410,MEURCHIN,,50.4942234674,2.89430512766,573,,Meurchin,Meurchin,62,Pas-de-Calais,32,Hauts-de-France
62574,MINGOVAL,62690,MINGOVAL,,50.376078209,2.56887018606,574,,Mingoval,Mingoval,62,Pas-de-Calais,32,Hauts-de-France
62576,MONCHEAUX LES FREVENT,62270,MONCHEAUX LES FREVENT,,50.3143087443,2.3636150292,576,,Moncheaux-lès-Frévent,Moncheaux-lès-Frévent,62,Pas-de-Calais,32,Hauts-de-France
62577,MONCHEL SUR CANCHE,62270,MONCHEL SUR CANCHE,,50.3037937856,2.21852869181,577,,Monchel-sur-Canche,Monchel-sur-Canche,62,Pas-de-Calais,32,Hauts-de-France
62578,MONCHIET,62123,MONCHIET,,50.2381944656,2.62311825865,578,,Monchiet,Monchiet,62,Pas-de-Calais,32,Hauts-de-France
62579,MONCHY AU BOIS,62111,MONCHY AU BOIS,,50.1799080557,2.65841335513,579,,Monchy-au-Bois,Monchy-au-Bois,62,Pas-de-Calais,32,Hauts-de-France
62580,MONCHY BRETON,62127,MONCHY BRETON,,50.3984333716,2.43602889753,580,,Monchy-Breton,Monchy-Breton,62,Pas-de-Calais,32,Hauts-de-France
62581,MONCHY CAYEUX,62134,MONCHY CAYEUX,,50.4344363807,2.28088263768,581,,Monchy-Cayeux,Monchy-Cayeux,62,Pas-de-Calais,32,Hauts-de-France
62582,MONCHY LE PREUX,62118,MONCHY LE PREUX,,50.2680152947,2.89515601798,582,,Monchy-le-Preux,Monchy-le-Preux,62,Pas-de-Calais,32,Hauts-de-France
62583,MONDICOURT,62760,MONDICOURT,,50.1742014399,2.46725438343,583,,Mondicourt,Mondicourt,62,Pas-de-Calais,32,Hauts-de-France
62584,MONT BERNANCHON,62350,MONT BERNANCHON,,50.5892672563,2.59806310499,584,,Mont-Bernanchon,Mont-Bernanchon,62,Pas-de-Calais,32,Hauts-de-France
62585,MONTCAVREL,62170,MONTCAVREL,,50.5200642841,1.81575896733,585,,Montcavrel,Montcavrel,62,Pas-de-Calais,32,Hauts-de-France
62586,MONTENESCOURT,62123,MONTENESCOURT,,50.2927140041,2.62902405219,586,,Montenescourt,Montenescourt,62,Pas-de-Calais,32,Hauts-de-France
62587,MONTIGNY EN GOHELLE,62640,MONTIGNY EN GOHELLE,,50.4280892859,2.93038201383,587,,Montigny-en-Gohelle,Montigny-en-Gohelle,62,Pas-de-Calais,32,Hauts-de-France
62588,MONTREUIL,62170,MONTREUIL,,50.4655272073,1.77098646216,588,,Montreuil,Montreuil,62,Pas-de-Calais,32,Hauts-de-France
62589,MONT ST ELOI,62144,MONT ST ELOI,,50.3502329575,2.69040970286,589,,Mont-Saint-Éloi,Mont-Saint-Éloi,62,Pas-de-Calais,32,Hauts-de-France
62590,MONTS EN TERNOIS,62130,MONTS EN TERNOIS,,50.3211000612,2.38646498424,590,,Monts-en-Ternois,Monts-en-Ternois,62,Pas-de-Calais,32,Hauts-de-France
62591,MORCHIES,62124,MORCHIES,,50.1374335468,2.95474922701,591,,Morchies,Morchies,62,Pas-de-Calais,32,Hauts-de-France
62592,MORINGHEM,62910,MORINGHEM,,50.7633897725,2.12682414351,592,,Moringhem,Moringhem,62,Pas-de-Calais,32,Hauts-de-France
62593,MORVAL,62450,MORVAL,,50.0285941958,2.86791432248,593,,Morval,Morval,62,Pas-de-Calais,32,Hauts-de-France
62594,MORY,62159,MORY,,50.1566834993,2.85758228781,594,,Mory,Mory,62,Pas-de-Calais,32,Hauts-de-France
62595,MOULLE,62910,MOULLE,,50.7883148321,2.1706543981,595,,Moulle,Moulle,62,Pas-de-Calais,32,Hauts-de-France
62596,MOURIEZ,62140,MOURIEZ,,50.3470493042,1.95514283314,596,,Mouriez,Mouriez,62,Pas-de-Calais,32,Hauts-de-France
62597,MOYENNEVILLE,62121,MOYENNEVILLE,,50.1799294808,2.77141568163,597,,Moyenneville,Moyenneville,62,Pas-de-Calais,32,Hauts-de-France
62598,MUNCQ NIEURLET,62890,MUNCQ NIEURLET,,50.8391311985,2.11817509259,598,,Muncq-Nieurlet,Muncq-Nieurlet,62,Pas-de-Calais,32,Hauts-de-France
62599,NABRINGHEN,62142,NABRINGHEN,,50.7417350163,1.86256483299,599,,Nabringhen,Nabringhen,62,Pas-de-Calais,32,Hauts-de-France
62600,NEDON,62550,NEDON,,50.5178234445,2.36307041105,600,,Nédon,Nédon,62,Pas-de-Calais,32,Hauts-de-France
62601,NEDONCHEL,62550,NEDONCHEL,,50.5271339963,2.35474825526,601,,Nédonchel,Nédonchel,62,Pas-de-Calais,32,Hauts-de-France
62602,NEMPONT ST FIRMIN,62180,NEMPONT ST FIRMIN,,50.3613146343,1.7392353846,602,,Nempont-Saint-Firmin,Nempont-Saint-Firmin,62,Pas-de-Calais,32,Hauts-de-France
62603,NESLES,62152,NESLES,,50.6227064448,1.66004463955,603,,Nesles,Nesles,62,Pas-de-Calais,32,Hauts-de-France
62604,NEUFCHATEL HARDELOT,62152,NEUFCHATEL HARDELOT,,50.6171328385,1.61729338887,604,,Neufchâtel-Hardelot,Neufchâtel-Hardelot,62,Pas-de-Calais,32,Hauts-de-France
62604,NEUFCHATEL HARDELOT,62152,NEUFCHATEL HARDELOT,HARDELOT PLAGE,50.6171328385,1.61729338887,604,,Neufchâtel-Hardelot,Neufchâtel-Hardelot,62,Pas-de-Calais,32,Hauts-de-France
62605,NEULETTE,62770,NEULETTE,,50.3813194274,2.16664316221,605,,Neulette,Neulette,62,Pas-de-Calais,32,Hauts-de-France
62606,NEUVE CHAPELLE,62840,NEUVE CHAPELLE,,50.585092327,2.77952080173,606,,Neuve-Chapelle,Neuve-Chapelle,62,Pas-de-Calais,32,Hauts-de-France
62607,NEUVILLE AU CORNET,62130,NEUVILLE AU CORNET,,50.334282171,2.36952468939,607,,Neuville-au-Cornet,Neuville-au-Cornet,62,Pas-de-Calais,32,Hauts-de-France
62608,NEUVILLE BOURJONVAL,62124,NEUVILLE BOURJONVAL,,50.0669791719,3.024953452,608,,Neuville-Bourjonval,Neuville-Bourjonval,62,Pas-de-Calais,32,Hauts-de-France
62609,NEUVILLE ST VAAST,62580,NEUVILLE ST VAAST,,50.3583439881,2.75467888748,609,,Neuville-Saint-Vaast,Neuville-Saint-Vaast,62,Pas-de-Calais,32,Hauts-de-France
62610,NEUVILLE SOUS MONTREUIL,62170,NEUVILLE SOUS MONTREUIL,,50.4788857008,1.79521288108,610,,Neuville-sous-Montreuil,Neuville-sous-Montreuil,62,Pas-de-Calais,32,Hauts-de-France
62611,NEUVILLE VITASSE,62217,NEUVILLE VITASSE,,50.2476254863,2.81958872203,611,,Neuville-Vitasse,Neuville-Vitasse,62,Pas-de-Calais,32,Hauts-de-France
62612,NEUVIREUIL,62580,NEUVIREUIL,,50.3531492484,2.91444913531,612,,Neuvireuil,Neuvireuil,62,Pas-de-Calais,32,Hauts-de-France
62613,NIELLES LES BLEQUIN,62380,NIELLES LES BLEQUIN,,50.675332061,2.03042491518,613,,Nielles-lès-Bléquin,Nielles-lès-Bléquin,62,Pas-de-Calais,32,Hauts-de-France
62614,NIELLES LES ARDRES,62610,NIELLES LES ARDRES,,50.8486968374,2.00661569937,614,,Nielles-lès-Ardres,Nielles-lès-Ardres,62,Pas-de-Calais,32,Hauts-de-France
62615,NIELLES LES CALAIS,62185,NIELLES LES CALAIS,,50.9070271437,1.83253644288,615,,Nielles-lès-Calais,Nielles-lès-Calais,62,Pas-de-Calais,32,Hauts-de-France
62616,NOEUX LES AUXI,62390,NOEUX LES AUXI,,50.2408362056,2.18178131298,616,,Nœux-lès-Auxi,Nœux-lès-Auxi,62,Pas-de-Calais,32,Hauts-de-France
62617,NOEUX LES MINES,62290,NOEUX LES MINES,,50.4751015881,2.66285501357,617,,Nœux-les-Mines,Nœux-les-Mines,62,Pas-de-Calais,32,Hauts-de-France
62618,NORDAUSQUES,62890,NORDAUSQUES,,50.8139376345,2.08471496754,618,,Nordausques,Nordausques,62,Pas-de-Calais,32,Hauts-de-France
62619,NOREUIL,62128,NOREUIL,,50.170479855,2.93868570028,619,,Noreuil,Noreuil,62,Pas-de-Calais,32,Hauts-de-France
62620,NORRENT FONTES,62120,NORRENT FONTES,,50.5874971634,2.40937405829,620,,Norrent-Fontes,Norrent-Fontes,62,Pas-de-Calais,32,Hauts-de-France
62621,NORTKERQUE,62370,NORTKERQUE,,50.8781972257,2.03311083061,621,,Nortkerque,Nortkerque,62,Pas-de-Calais,32,Hauts-de-France
62622,NORT LEULINGHEM,62890,NORT LEULINGHEM,,50.7959979295,2.08880938982,622,,Nort-Leulinghem,Nort-Leulinghem,62,Pas-de-Calais,32,Hauts-de-France
62623,NOUVELLE EGLISE,62370,NOUVELLE EGLISE,,50.9318131132,2.0456999898,623,,Nouvelle-Église,Nouvelle-Église,62,Pas-de-Calais,32,Hauts-de-France
62624,NOYELLES GODAULT,62950,NOYELLES GODAULT,,50.4179672705,2.99358335607,624,,Noyelles-Godault,Noyelles-Godault,62,Pas-de-Calais,32,Hauts-de-France
62625,NOYELLES LES HUMIERES,62770,NOYELLES LES HUMIERES,,50.3754237505,2.17737764186,625,,Noyelles-lès-Humières,Noyelles-lès-Humières,62,Pas-de-Calais,32,Hauts-de-France
62626,NOYELLES LES VERMELLES,62980,NOYELLES LES VERMELLES,,50.4912262518,2.72838985487,626,,Noyelles-lès-Vermelles,Noyelles-lès-Vermelles,62,Pas-de-Calais,32,Hauts-de-France
62627,NOYELLES SOUS BELLONNE,62490,NOYELLES SOUS BELLONNE,,50.3091545259,3.02186108116,627,,Noyelles-sous-Bellonne,Noyelles-sous-Bellonne,62,Pas-de-Calais,32,Hauts-de-France
62628,NOYELLES SOUS LENS,62221,NOYELLES SOUS LENS,,50.4286822706,2.87559653416,628,,Noyelles-sous-Lens,Noyelles-sous-Lens,62,Pas-de-Calais,32,Hauts-de-France
62629,NOYELLETTE,62123,NOYELLETTE,,50.2978570958,2.59183341606,629,,Noyellette,Noyellette,62,Pas-de-Calais,32,Hauts-de-France
62630,NOYELLE VION,62810,NOYELLE VION,,50.2915190134,2.54478161296,630,,Noyelle-Vion,Noyelle-Vion,62,Pas-de-Calais,32,Hauts-de-France
62631,NUNCQ HAUTECOTE,62270,NUNCQ HAUTECOTE,,50.3077191679,2.28678904322,631,,Nuncq-Hautecôte,Nuncq-Hautecôte,62,Pas-de-Calais,32,Hauts-de-France
62631,NUNCQ HAUTECOTE,62270,NUNCQ HAUTECOTE,HAUTECOTE,50.3077191679,2.28678904322,631,,Nuncq-Hautecôte,Nuncq-Hautecôte,62,Pas-de-Calais,32,Hauts-de-France
62632,OBLINGHEM,62920,OBLINGHEM,,50.5512476487,2.59352729136,632,,Oblinghem,Oblinghem,62,Pas-de-Calais,32,Hauts-de-France
62633,OEUF EN TERNOIS,62130,OEUF EN TERNOIS,,50.3630160601,2.21206291553,633,,Œuf-en-Ternois,Œuf-en-Ternois,62,Pas-de-Calais,32,Hauts-de-France
62634,OFFEKERQUE,62370,OFFEKERQUE,,50.9314630388,2.02026099219,634,,Offekerque,Offekerque,62,Pas-de-Calais,32,Hauts-de-France
62635,OFFIN,62990,OFFIN,,50.4419639304,1.95243278829,635,,Offin,Offin,62,Pas-de-Calais,32,Hauts-de-France
62636,OFFRETHUN,62250,OFFRETHUN,,50.7862971343,1.69796833059,636,,Offrethun,Offrethun,62,Pas-de-Calais,32,Hauts-de-France
62637,OIGNIES,62590,OIGNIES,,50.4644483399,2.99306842451,637,,Oignies,Oignies,62,Pas-de-Calais,32,Hauts-de-France
62638,OISY LE VERGER,62860,OISY LE VERGER,,50.2504114011,3.12224592091,638,,Oisy-le-Verger,Oisy-le-Verger,62,Pas-de-Calais,32,Hauts-de-France
62639,OPPY,62580,OPPY,,50.3471676036,2.88887974402,639,,Oppy,Oppy,62,Pas-de-Calais,32,Hauts-de-France
62640,ORVILLE,62760,ORVILLE,,50.1286097362,2.41900637434,640,,Orville,Orville,62,Pas-de-Calais,32,Hauts-de-France
62641,OSTREVILLE,62130,OSTREVILLE,,50.3941779286,2.39782308177,641,,Ostreville,Ostreville,62,Pas-de-Calais,32,Hauts-de-France
62642,OURTON,62460,OURTON,,50.4555544554,2.47132859309,642,,Ourton,Ourton,62,Pas-de-Calais,32,Hauts-de-France
62643,OUTREAU,62230,OUTREAU,,50.6980158519,1.59211375998,643,,Outreau,Outreau,62,Pas-de-Calais,32,Hauts-de-France
62644,OUVE WIRQUIN,62380,OUVE WIRQUIN,,50.6527627631,2.14390429317,644,,Ouve-Wirquin,Ouve-Wirquin,62,Pas-de-Calais,32,Hauts-de-France
62645,OYE PLAGE,62215,OYE PLAGE,,50.9812329903,2.03930688594,645,,Oye-Plage,Oye-Plage,62,Pas-de-Calais,32,Hauts-de-France
62646,PALLUEL,62860,PALLUEL,,50.2653513279,3.09530280931,646,,Palluel,Palluel,62,Pas-de-Calais,32,Hauts-de-France
62647,LE PARCQ,62770,LE PARCQ,,50.382239682,2.08547177983,647,Le,Parcq,Le Parcq,62,Pas-de-Calais,32,Hauts-de-France
62648,PARENTY,62650,PARENTY,,50.5942219579,1.80057178458,648,,Parenty,Parenty,62,Pas-de-Calais,32,Hauts-de-France
62649,PAS EN ARTOIS,62760,PAS EN ARTOIS,,50.1524799226,2.4911616268,649,,Pas-en-Artois,Pas-en-Artois,62,Pas-de-Calais,32,Hauts-de-France
62650,PELVES,62118,PELVES,,50.2872121477,2.91797931604,650,,Pelves,Pelves,62,Pas-de-Calais,32,Hauts-de-France
62651,PENIN,62127,PENIN,,50.3304741478,2.487971772,651,,Penin,Penin,62,Pas-de-Calais,32,Hauts-de-France
62652,PERNES,62550,PERNES,,50.4869916511,2.402530406,652,,Pernes,Pernes,62,Pas-de-Calais,32,Hauts-de-France
62653,PERNES LES BOULOGNE,62126,PERNES LES BOULOGNE,,50.7487764703,1.69726351984,653,,Pernes-lès-Boulogne,Pernes-lès-Boulogne,62,Pas-de-Calais,32,Hauts-de-France
62654,PEUPLINGUES,62231,PEUPLINGUES,,50.9137738583,1.75951398538,654,,Peuplingues,Peuplingues,62,Pas-de-Calais,32,Hauts-de-France
62655,PIERREMONT,62130,PIERREMONT,,50.3983828785,2.2554720629,655,,Pierremont,Pierremont,62,Pas-de-Calais,32,Hauts-de-France
62656,PIHEM,62570,PIHEM,,50.6761891157,2.21194474254,656,,Pihem,Pihem,62,Pas-de-Calais,32,Hauts-de-France
62657,PIHEN LES GUINES,62340,PIHEN LES GUINES,,50.8717360678,1.79132748835,657,,Pihen-lès-Guînes,Pihen-lès-Guînes,62,Pas-de-Calais,32,Hauts-de-France
62658,PITTEFAUX,62126,PITTEFAUX,,50.7620533619,1.68493219669,658,,Pittefaux,Pittefaux,62,Pas-de-Calais,32,Hauts-de-France
62659,PLANQUES,62310,PLANQUES,,50.4663856926,2.08885022364,659,,Planques,Planques,62,Pas-de-Calais,32,Hauts-de-France
62660,PLOUVAIN,62118,PLOUVAIN,,50.3059557939,2.91794786158,660,,Plouvain,Plouvain,62,Pas-de-Calais,32,Hauts-de-France
62661,BOUIN PLUMOISON,62140,BOUIN PLUMOISON,,50.3760980886,1.98171782386,661,,Bouin-Plumoison,Bouin-Plumoison,62,Pas-de-Calais,32,Hauts-de-France
62661,BOUIN PLUMOISON,62140,BOUIN PLUMOISON,BOUIN,50.3760980886,1.98171782386,661,,Bouin-Plumoison,Bouin-Plumoison,62,Pas-de-Calais,32,Hauts-de-France
62662,POLINCOVE,62370,POLINCOVE,,50.8615356416,2.10891305299,662,,Polincove,Polincove,62,Pas-de-Calais,32,Hauts-de-France
62663,POMMERA,62760,POMMERA,,50.1674967,2.44251787314,663,,Pommera,Pommera,62,Pas-de-Calais,32,Hauts-de-France
62664,POMMIER,62111,POMMIER,,50.1813083697,2.59512513056,664,,Pommier,Pommier,62,Pas-de-Calais,32,Hauts-de-France
62665,LE PONCHEL,62390,LE PONCHEL,,50.2568499569,2.08536374004,665,Le,Ponchel,Le Ponchel,62,Pas-de-Calais,32,Hauts-de-France
62666,PONT A VENDIN,62880,PONT A VENDIN,,50.4760049841,2.88811858203,666,,Pont-à-Vendin,Pont-à-Vendin,62,Pas-de-Calais,32,Hauts-de-France
62667,LE PORTEL,62480,LE PORTEL,,50.7126100716,1.57611235445,667,Le,Portel,Le Portel,62,Pas-de-Calais,32,Hauts-de-France
62668,PREDEFIN,62134,PREDEFIN,,50.5049426008,2.25899233284,668,,Prédefin,Prédefin,62,Pas-de-Calais,32,Hauts-de-France
62669,PRESSY,62550,PRESSY,,50.4741367266,2.39005609292,669,,Pressy,Pressy,62,Pas-de-Calais,32,Hauts-de-France
62670,PREURES,62650,PREURES,,50.573370398,1.88208837787,670,,Preures,Preures,62,Pas-de-Calais,32,Hauts-de-France
62671,PRONVILLE,62860,PRONVILLE,,50.1658848731,3.00801874894,671,,Pronville-en-Artois,Pronville-en-Artois,62,Pas-de-Calais,32,Hauts-de-France
62672,PUISIEUX,62116,PUISIEUX,,50.1064415329,2.68870005517,672,,Puisieux,Puisieux,62,Pas-de-Calais,32,Hauts-de-France
62673,QUEANT,62860,QUEANT,,50.1765937301,2.98518027531,673,,Quéant,Quéant,62,Pas-de-Calais,32,Hauts-de-France
62674,QUELMES,62500,QUELMES,,50.7344041405,2.12647655403,674,,Quelmes,Quelmes,62,Pas-de-Calais,32,Hauts-de-France
62675,QUERCAMPS,62380,QUERCAMPS,,50.7521632,2.04535296753,675,,Quercamps,Quercamps,62,Pas-de-Calais,32,Hauts-de-France
62676,QUERNES,62120,QUERNES,,50.602495211,2.36795171734,676,,Quernes,Quernes,62,Pas-de-Calais,32,Hauts-de-France
62677,LE QUESNOY EN ARTOIS,62140,LE QUESNOY EN ARTOIS,,50.3316939023,2.0384112079,677,Le,Quesnoy-en-Artois,Le Quesnoy-en-Artois,62,Pas-de-Calais,32,Hauts-de-France
62678,QUESQUES,62240,QUESQUES,,50.7016785113,1.9419210746,678,,Quesques,Quesques,62,Pas-de-Calais,32,Hauts-de-France
62679,QUESTRECQUES,62830,QUESTRECQUES,,50.6645692579,1.74592527562,679,,Questrecques,Questrecques,62,Pas-de-Calais,32,Hauts-de-France
62680,QUIERY LA MOTTE,62490,QUIERY LA MOTTE,,50.3662007463,2.98347505624,680,,Quiéry-la-Motte,Quiéry-la-Motte,62,Pas-de-Calais,32,Hauts-de-France
62681,QUIESTEDE,62120,QUIESTEDE,,50.6805074273,2.32892065938,681,,Quiestède,Quiestède,62,Pas-de-Calais,32,Hauts-de-France
62682,QUILEN,62650,QUILEN,,50.5294762225,1.9336963946,682,,Quilen,Quilen,62,Pas-de-Calais,32,Hauts-de-France
62683,QUOEUX HAUT MAINIL,62390,QUOEUX HAUT MAINIL,,50.3010617239,2.10429355404,683,,Quœux-Haut-Maînil,Quœux-Haut-Maînil,62,Pas-de-Calais,32,Hauts-de-France
62683,QUOEUX HAUT MAINIL,62390,QUOEUX HAUT MAINIL,HAUT MAINIL,50.3010617239,2.10429355404,683,,Quœux-Haut-Maînil,Quœux-Haut-Maînil,62,Pas-de-Calais,32,Hauts-de-France
62684,RACQUINGHEM,62120,RACQUINGHEM,,50.6951552372,2.36039554568,684,,Racquinghem,Racquinghem,62,Pas-de-Calais,32,Hauts-de-France
62685,RADINGHEM,62310,RADINGHEM,,50.5466948364,2.11786981824,685,,Radinghem,Radinghem,62,Pas-de-Calais,32,Hauts-de-France
62686,RAMECOURT,62130,RAMECOURT,,50.3636637798,2.30659261864,686,,Ramecourt,Ramecourt,62,Pas-de-Calais,32,Hauts-de-France
62688,RANG DU FLIERS,62180,RANG DU FLIERS,,50.4199152362,1.63418600654,688,,Rang-du-Fliers,Rang-du-Fliers,62,Pas-de-Calais,32,Hauts-de-France
62689,RANSART,62173,RANSART,,50.2055666044,2.67670768859,689,,Ransart,Ransart,62,Pas-de-Calais,32,Hauts-de-France
62690,RAYE SUR AUTHIE,62140,RAYE SUR AUTHIE,,50.3025647802,1.9586390074,690,,Raye-sur-Authie,Raye-sur-Authie,62,Pas-de-Calais,32,Hauts-de-France
62691,ST AUGUSTIN,62120,ST AUGUSTIN,REBECQUES,50.6486142358,2.30256500172,691,,Saint-Augustin,Saint-Augustin,62,Pas-de-Calais,32,Hauts-de-France
62691,ST AUGUSTIN,62129,ST AUGUSTIN,CLARQUES,50.6486142358,2.30256500172,691,,Saint-Augustin,Saint-Augustin,62,Pas-de-Calais,32,Hauts-de-France
62692,REBERGUES,62850,REBERGUES,,50.7533359346,1.95267179122,692,,Rebergues,Rebergues,62,Pas-de-Calais,32,Hauts-de-France
62693,REBREUVE RANCHICOURT,62150,REBREUVE RANCHICOURT,,50.4315349497,2.54883254933,693,,Rebreuve-Ranchicourt,Rebreuve-Ranchicourt,62,Pas-de-Calais,32,Hauts-de-France
62693,REBREUVE RANCHICOURT,62150,REBREUVE RANCHICOURT,RANCHICOURT,50.4315349497,2.54883254933,693,,Rebreuve-Ranchicourt,Rebreuve-Ranchicourt,62,Pas-de-Calais,32,Hauts-de-France
62694,REBREUVE SUR CANCHE,62270,REBREUVE SUR CANCHE,,50.2662010324,2.34099398003,694,,Rebreuve-sur-Canche,Rebreuve-sur-Canche,62,Pas-de-Calais,32,Hauts-de-France
62695,REBREUVIETTE,62270,REBREUVIETTE,,50.2575030027,2.3638974015,695,,Rebreuviette,Rebreuviette,62,Pas-de-Calais,32,Hauts-de-France
62696,RECLINGHEM,62560,RECLINGHEM,,50.5726869457,2.17529394025,696,,Reclinghem,Reclinghem,62,Pas-de-Calais,32,Hauts-de-France
62697,RECOURT,62860,RECOURT,,50.2572703806,3.02765469348,697,,Récourt,Récourt,62,Pas-de-Calais,32,Hauts-de-France
62698,RECQUES SUR COURSE,62170,RECQUES SUR COURSE,,50.5183914542,1.77865917585,698,,Recques-sur-Course,Recques-sur-Course,62,Pas-de-Calais,32,Hauts-de-France
62699,RECQUES SUR HEM,62890,RECQUES SUR HEM,,50.8355345504,2.08017520799,699,,Recques-sur-Hem,Recques-sur-Hem,62,Pas-de-Calais,32,Hauts-de-France
62700,REGNAUVILLE,62140,REGNAUVILLE,,50.3165005533,2.00147322794,700,,Regnauville,Regnauville,62,Pas-de-Calais,32,Hauts-de-France
62701,RELY,62120,RELY,,50.5758856261,2.36150772557,701,,Rely,Rely,62,Pas-de-Calais,32,Hauts-de-France
62702,REMILLY WIRQUIN,62380,REMILLY WIRQUIN,,50.6699885591,2.17457011207,702,,Remilly-Wirquin,Remilly-Wirquin,62,Pas-de-Calais,32,Hauts-de-France
62703,REMY,62156,REMY,,50.257089455,2.95881684722,703,,Rémy,Rémy,62,Pas-de-Calais,32,Hauts-de-France
62704,RENTY,62560,RENTY,,50.5795789917,2.06891103096,704,,Renty,Renty,62,Pas-de-Calais,32,Hauts-de-France
62705,RETY,62720,RETY,,50.7933479687,1.78478453099,705,,Rety,Rety,62,Pas-de-Calais,32,Hauts-de-France
62706,RICHEBOURG,62136,RICHEBOURG,,50.5790087378,2.74726959895,706,,Richebourg,Richebourg,62,Pas-de-Calais,32,Hauts-de-France
62708,RIENCOURT LES BAPAUME,62450,RIENCOURT LES BAPAUME,,50.0885322704,2.8804356925,708,,Riencourt-lès-Bapaume,Riencourt-lès-Bapaume,62,Pas-de-Calais,32,Hauts-de-France
62709,RIENCOURT LES CAGNICOURT,62182,RIENCOURT LES CAGNICOURT,,50.1942984379,2.96139723714,709,,Riencourt-lès-Cagnicourt,Riencourt-lès-Cagnicourt,62,Pas-de-Calais,32,Hauts-de-France
62710,RIMBOVAL,62990,RIMBOVAL,,50.5151477103,1.98923496959,710,,Rimboval,Rimboval,62,Pas-de-Calais,32,Hauts-de-France
62711,RINXENT,62720,RINXENT,,50.8071658491,1.74101623086,711,,Rinxent,Rinxent,62,Pas-de-Calais,32,Hauts-de-France
62712,RIVIERE,62173,RIVIERE,,50.232029156,2.68653803853,712,,Rivière,Rivière,62,Pas-de-Calais,32,Hauts-de-France
62713,ROBECQ,62350,ROBECQ,,50.594171583,2.55063682467,713,,Robecq,Robecq,62,Pas-de-Calais,32,Hauts-de-France
62714,ROCLINCOURT,62223,ROCLINCOURT,,50.3298536154,2.78984756836,714,,Roclincourt,Roclincourt,62,Pas-de-Calais,32,Hauts-de-France
62715,ROCQUIGNY,62450,ROCQUIGNY,,50.0557736792,2.92417535709,715,,Rocquigny,Rocquigny,62,Pas-de-Calais,32,Hauts-de-France
62716,RODELINGHEM,62610,RODELINGHEM,,50.8340533685,1.93038024618,716,,Rodelinghem,Rodelinghem,62,Pas-de-Calais,32,Hauts-de-France
62717,ROELLECOURT,62130,ROELLECOURT,,50.3682282573,2.387937901,717,,Roëllecourt,Roëllecourt,62,Pas-de-Calais,32,Hauts-de-France
62718,ROEUX,62118,ROEUX,,50.3016559501,2.89915245765,718,,Rœux,Rœux,62,Pas-de-Calais,32,Hauts-de-France
62719,ROLLANCOURT,62770,ROLLANCOURT,,50.4117012202,2.1259936792,719,,Rollancourt,Rollancourt,62,Pas-de-Calais,32,Hauts-de-France
62720,ROMBLY,62120,ROMBLY,,50.5951805534,2.38984957197,720,,Rombly,Rombly,62,Pas-de-Calais,32,Hauts-de-France
62721,ROQUETOIRE,62120,ROQUETOIRE,,50.6660698696,2.33842480492,721,,Roquetoire,Roquetoire,62,Pas-de-Calais,32,Hauts-de-France
62722,ROUGEFAY,62390,ROUGEFAY,,50.2763576271,2.16980912233,722,,Rougefay,Rougefay,62,Pas-de-Calais,32,Hauts-de-France
62723,ROUSSENT,62870,ROUSSENT,,50.3713244129,1.77455343487,723,,Roussent,Roussent,62,Pas-de-Calais,32,Hauts-de-France
62724,ROUVROY,62320,ROUVROY,,50.3992080459,2.90489431252,724,,Rouvroy,Rouvroy,62,Pas-de-Calais,32,Hauts-de-France
62725,ROYON,62990,ROYON,,50.4726572315,1.99966116605,725,,Royon,Royon,62,Pas-de-Calais,32,Hauts-de-France
62726,RUISSEAUVILLE,62310,RUISSEAUVILLE,,50.4836257391,2.11590995942,726,,Ruisseauville,Ruisseauville,62,Pas-de-Calais,32,Hauts-de-France
62727,RUITZ,62620,RUITZ,,50.4661492425,2.59073135345,727,,Ruitz,Ruitz,62,Pas-de-Calais,32,Hauts-de-France
62728,RUMAUCOURT,62860,RUMAUCOURT,,50.2382420752,3.06500657544,728,,Rumaucourt,Rumaucourt,62,Pas-de-Calais,32,Hauts-de-France
62729,RUMILLY,62650,RUMILLY,,50.5793719163,2.01782227688,729,,Rumilly,Rumilly,62,Pas-de-Calais,32,Hauts-de-France
62730,RUMINGHEM,62370,RUMINGHEM,,50.8561596741,2.16768057488,730,,Ruminghem,Ruminghem,62,Pas-de-Calais,32,Hauts-de-France
62731,RUYAULCOURT,62124,RUYAULCOURT,,50.0828450646,3.01620386019,731,,Ruyaulcourt,Ruyaulcourt,62,Pas-de-Calais,32,Hauts-de-France
62732,SACHIN,62550,SACHIN,,50.4927168142,2.36659910024,732,,Sachin,Sachin,62,Pas-de-Calais,32,Hauts-de-France
62733,SAILLY AU BOIS,62111,SAILLY AU BOIS,,50.1213705437,2.59867218191,733,,Sailly-au-Bois,Sailly-au-Bois,62,Pas-de-Calais,32,Hauts-de-France
62734,SAILLY EN OSTREVENT,62490,SAILLY EN OSTREVENT,,50.2875880806,2.99003702164,734,,Sailly-en-Ostrevent,Sailly-en-Ostrevent,62,Pas-de-Calais,32,Hauts-de-France
62735,SAILLY LABOURSE,62113,SAILLY LABOURSE,,50.501663993,2.70273580801,735,,Sailly-Labourse,Sailly-Labourse,62,Pas-de-Calais,32,Hauts-de-France
62736,SAILLY SUR LA LYS,62840,SAILLY SUR LA LYS,,50.6532332701,2.79249849805,736,,Sailly-sur-la-Lys,Sailly-sur-la-Lys,62,Pas-de-Calais,32,Hauts-de-France
62737,SAINS EN GOHELLE,62114,SAINS EN GOHELLE,,50.4509698572,2.68385518781,737,,Sains-en-Gohelle,Sains-en-Gohelle,62,Pas-de-Calais,32,Hauts-de-France
62738,SAINS LES FRESSIN,62310,SAINS LES FRESSIN,,50.4721168643,2.04894753008,738,,Sains-lès-Fressin,Sains-lès-Fressin,62,Pas-de-Calais,32,Hauts-de-France
62739,SAINS LES MARQUION,62860,SAINS LES MARQUION,,50.1855341097,3.07837118721,739,,Sains-lès-Marquion,Sains-lès-Marquion,62,Pas-de-Calais,32,Hauts-de-France
62740,SAINS LES PERNES,62550,SAINS LES PERNES,,50.4791687953,2.34885594845,740,,Sains-lès-Pernes,Sains-lès-Pernes,62,Pas-de-Calais,32,Hauts-de-France
62741,ST AMAND,62760,ST AMAND,,50.1701921167,2.56122106868,741,,Saint-Amand,Saint-Amand,62,Pas-de-Calais,32,Hauts-de-France
62742,ST AUBIN,62170,ST AUBIN,,50.4542149045,1.66870486737,742,,Saint-Aubin,Saint-Aubin,62,Pas-de-Calais,32,Hauts-de-France
62743,STE AUSTREBERTHE,62140,STE AUSTREBERTHE,,50.3579438229,2.04425580197,743,,Sainte-Austreberthe,Sainte-Austreberthe,62,Pas-de-Calais,32,Hauts-de-France
62744,STE CATHERINE,62223,STE CATHERINE,,50.3146043394,2.7623576742,744,,Sainte-Catherine,Sainte-Catherine,62,Pas-de-Calais,32,Hauts-de-France
62745,ST DENOEUX,62990,ST DENOEUX,,50.4773305558,1.90952236294,745,,Saint-Denœux,Saint-Denœux,62,Pas-de-Calais,32,Hauts-de-France
62746,ST ETIENNE AU MONT,62360,PONT DE BRIQUES ST ETIENNE,,50.6654810277,1.6018617047,746,,Saint-Étienne-au-Mont,Saint-Étienne-au-Mont,62,Pas-de-Calais,32,Hauts-de-France
62746,ST ETIENNE AU MONT,62360,PONT DE BRIQUES ST ETIENNE,PONT DE BRIQUES,50.6654810277,1.6018617047,746,,Saint-Étienne-au-Mont,Saint-Étienne-au-Mont,62,Pas-de-Calais,32,Hauts-de-France
62747,ST FLORIS,62350,ST FLORIS,,50.6245104816,2.57921156269,747,,Saint-Floris,Saint-Floris,62,Pas-de-Calais,32,Hauts-de-France
62748,ST FOLQUIN,62370,ST FOLQUIN,,50.9379112392,2.12543329678,748,,Saint-Folquin,Saint-Folquin,62,Pas-de-Calais,32,Hauts-de-France
62749,ST GEORGES,62770,ST GEORGES,,50.351642191,2.07280932615,749,,Saint-Georges,Saint-Georges,62,Pas-de-Calais,32,Hauts-de-France
62750,ST HILAIRE COTTES,62120,ST HILAIRE COTTES,,50.5673889188,2.39680232031,750,,Saint-Hilaire-Cottes,Saint-Hilaire-Cottes,62,Pas-de-Calais,32,Hauts-de-France
62751,ST INGLEVERT,62250,ST INGLEVERT,,50.8715347562,1.74383112864,751,,Saint-Inglevert,Saint-Inglevert,62,Pas-de-Calais,32,Hauts-de-France
62752,ST JOSSE,62170,ST JOSSE,,50.4816510992,1.66447526938,752,,Saint-Josse,Saint-Josse,62,Pas-de-Calais,32,Hauts-de-France
62753,ST LAURENT BLANGY,62223,ST LAURENT BLANGY,,50.3076122259,2.81123049591,753,,Saint-Laurent-Blangy,Saint-Laurent-Blangy,62,Pas-de-Calais,32,Hauts-de-France
62754,ST LEGER,62128,ST LEGER,,50.1836392129,2.8515731694,754,,Saint-Léger,Saint-Léger,62,Pas-de-Calais,32,Hauts-de-France
62755,ST LEONARD,62360,ST LEONARD,,50.6957085427,1.62300066383,755,,Saint-Léonard,Saint-Léonard,62,Pas-de-Calais,32,Hauts-de-France
62756,STE MARIE KERQUE,62370,STE MARIE KERQUE,,50.8925108938,2.15129692928,756,,Sainte-Marie-Kerque,Sainte-Marie-Kerque,62,Pas-de-Calais,32,Hauts-de-France
62757,ST MARTIN LEZ TATINGHEM,62500,ST MARTIN LEZ TATINGHEM,,50.7568801196,2.2297072767,757,,Saint-Martin-lez-Tatinghem,Saint-Martin-lez-Tatinghem,62,Pas-de-Calais,32,Hauts-de-France
62757,ST MARTIN LEZ TATINGHEM,62500,ST MARTIN LEZ TATINGHEM,TATINGHEM,50.7568801196,2.2297072767,757,,Saint-Martin-lez-Tatinghem,Saint-Martin-lez-Tatinghem,62,Pas-de-Calais,32,Hauts-de-France
62758,ST MARTIN BOULOGNE,62280,ST MARTIN BOULOGNE,,50.7235416991,1.64793693921,758,,Saint-Martin-Boulogne,Saint-Martin-Boulogne,62,Pas-de-Calais,32,Hauts-de-France
62759,ST MARTIN CHOQUEL,62240,ST MARTIN CHOQUEL,,50.6684714182,1.88754948653,759,,Saint-Martin-Choquel,Saint-Martin-Choquel,62,Pas-de-Calais,32,Hauts-de-France
62760,ST MARTIN D HARDINGHEM,62560,ST MARTIN D HARDINGHEM,,50.6112309641,2.10476829244,760,,Saint-Martin-d'Hardinghem,Saint-Martin-d'Hardinghem,62,Pas-de-Calais,32,Hauts-de-France
62761,ST MARTIN SUR COJEUL,62128,ST MARTIN SUR COJEUL,,50.228647822,2.85191979354,761,,Saint-Martin-sur-Cojeul,Saint-Martin-sur-Cojeul,62,Pas-de-Calais,32,Hauts-de-France
62762,ST MICHEL SOUS BOIS,62650,ST MICHEL SOUS BOIS,,50.5174881258,1.93606986972,762,,Saint-Michel-sous-Bois,Saint-Michel-sous-Bois,62,Pas-de-Calais,32,Hauts-de-France
62763,ST MICHEL SUR TERNOISE,62130,ST MICHEL SUR TERNOISE,,50.375190709,2.36052687199,763,,Saint-Michel-sur-Ternoise,Saint-Michel-sur-Ternoise,62,Pas-de-Calais,32,Hauts-de-France
62764,ST NICOLAS,62223,ST NICOLAS,,50.3086770117,2.78508314188,764,,Saint-Nicolas,Saint-Nicolas,62,Pas-de-Calais,32,Hauts-de-France
62765,ST OMER,62500,ST OMER,,50.7679781717,2.26334881482,765,,Saint-Omer,Saint-Omer,62,Pas-de-Calais,32,Hauts-de-France
62766,ST OMER CAPELLE,62162,ST OMER CAPELLE,,50.9450043366,2.1009180306,766,,Saint-Omer-Capelle,Saint-Omer-Capelle,62,Pas-de-Calais,32,Hauts-de-France
62767,ST POL SUR TERNOISE,62130,ST POL SUR TERNOISE,,50.3883542632,2.35010590037,767,,Saint-Pol-sur-Ternoise,Saint-Pol-sur-Ternoise,62,Pas-de-Calais,32,Hauts-de-France
62768,ST REMY AU BOIS,62870,ST REMY AU BOIS,,50.3683009566,1.86914301088,768,,Saint-Rémy-au-Bois,Saint-Rémy-au-Bois,62,Pas-de-Calais,32,Hauts-de-France
62769,ST TRICAT,62185,ST TRICAT,,50.8926601154,1.82664044658,769,,Saint-Tricat,Saint-Tricat,62,Pas-de-Calais,32,Hauts-de-France
62770,ST VENANT,62350,ST VENANT,,50.6225931834,2.52541265716,770,,Saint-Venant,Saint-Venant,62,Pas-de-Calais,32,Hauts-de-France
62771,SALLAUMINES,62430,SALLAUMINES,,50.4204336349,2.85999934529,771,,Sallaumines,Sallaumines,62,Pas-de-Calais,32,Hauts-de-France
62772,SALPERWICK,62500,SALPERWICK,,50.768362521,2.21963774504,772,,Salperwick,Salperwick,62,Pas-de-Calais,32,Hauts-de-France
62773,SAMER,62830,SAMER,,50.6338926429,1.75485002535,773,,Samer,Samer,62,Pas-de-Calais,32,Hauts-de-France
62774,SANGATTE,62231,SANGATTE,,50.9410415453,1.76958356617,774,,Sangatte,Sangatte,62,Pas-de-Calais,32,Hauts-de-France
62774,SANGATTE,62231,SANGATTE,BLERIOT,50.9410415453,1.76958356617,774,,Sangatte,Sangatte,62,Pas-de-Calais,32,Hauts-de-France
62775,SANGHEN,62850,SANGHEN,,50.7731524304,1.89264979524,775,,Sanghen,Sanghen,62,Pas-de-Calais,32,Hauts-de-France
62776,SAPIGNIES,62121,SAPIGNIES,,50.1316392726,2.83675199369,776,,Sapignies,Sapignies,62,Pas-de-Calais,32,Hauts-de-France
62777,LE SARS,62450,LE SARS,,50.0668702821,2.78329520895,777,Le,Sars,Le Sars,62,Pas-de-Calais,32,Hauts-de-France
62778,SARS LE BOIS,62810,SARS LE BOIS,,50.2975025898,2.43161426138,778,,Sars-le-Bois,Sars-le-Bois,62,Pas-de-Calais,32,Hauts-de-France
62779,SARTON,62760,SARTON,,50.1088396237,2.43057048265,779,,Sarton,Sarton,62,Pas-de-Calais,32,Hauts-de-France
62780,SAUCHY CAUCHY,62860,SAUCHY CAUCHY,,50.2357972637,3.09028659809,780,,Sauchy-Cauchy,Sauchy-Cauchy,62,Pas-de-Calais,32,Hauts-de-France
62781,SAUCHY LESTREE,62860,SAUCHY LESTREE,,50.2199513579,3.11647492412,781,,Sauchy-Lestrée,Sauchy-Lestrée,62,Pas-de-Calais,32,Hauts-de-France
62782,SAUDEMONT,62860,SAUDEMONT,,50.238640039,3.03137519569,782,,Saudemont,Saudemont,62,Pas-de-Calais,32,Hauts-de-France
62783,SAULCHOY,62870,SAULCHOY,,50.3538363354,1.85072788355,783,,Saulchoy,Saulchoy,62,Pas-de-Calais,32,Hauts-de-France
62784,SAULTY,62158,SAULTY,,50.2118204122,2.52470460058,784,,Saulty,Saulty,62,Pas-de-Calais,32,Hauts-de-France
62785,SAVY BERLETTE,62690,SAVY BERLETTE,,50.3544160083,2.55845813427,785,,Savy-Berlette,Savy-Berlette,62,Pas-de-Calais,32,Hauts-de-France
62786,SELLES,62240,SELLES,,50.6959428486,1.88964347847,786,,Selles,Selles,62,Pas-de-Calais,32,Hauts-de-France
62787,SEMPY,62170,SEMPY,,50.4962113332,1.87768243694,787,,Sempy,Sempy,62,Pas-de-Calais,32,Hauts-de-France
62788,SENINGHEM,62380,SENINGHEM,,50.6984836133,2.02702639439,788,,Seninghem,Seninghem,62,Pas-de-Calais,32,Hauts-de-France
62789,SENLECQUES,62240,SENLECQUES,,50.6500062435,1.93647679876,789,,Senlecques,Senlecques,62,Pas-de-Calais,32,Hauts-de-France
62790,SENLIS,62310,SENLIS,,50.5345389344,2.14734408463,790,,Senlis,Senlis,62,Pas-de-Calais,32,Hauts-de-France
62791,SERICOURT,62270,SERICOURT,,50.2955629239,2.31306293309,791,,Séricourt,Séricourt,62,Pas-de-Calais,32,Hauts-de-France
62792,SERQUES,62910,SERQUES,,50.7907495973,2.20166097242,792,,Serques,Serques,62,Pas-de-Calais,32,Hauts-de-France
62793,SERVINS,62530,SERVINS,,50.4073388544,2.63620262652,793,,Servins,Servins,62,Pas-de-Calais,32,Hauts-de-France
62794,SETQUES,62380,SETQUES,,50.7145017028,2.15645765074,794,,Setques,Setques,62,Pas-de-Calais,32,Hauts-de-France
62795,SIBIVILLE,62270,SIBIVILLE,,50.3047427649,2.33129283098,795,,Sibiville,Sibiville,62,Pas-de-Calais,32,Hauts-de-France
62796,SIMENCOURT,62123,SIMENCOURT,,50.2544725245,2.63378258972,796,,Simencourt,Simencourt,62,Pas-de-Calais,32,Hauts-de-France
62797,SIRACOURT,62130,SIRACOURT,,50.3707474842,2.26790984038,797,,Siracourt,Siracourt,62,Pas-de-Calais,32,Hauts-de-France
62798,SOMBRIN,62810,SOMBRIN,,50.2376406968,2.50522767518,798,,Sombrin,Sombrin,62,Pas-de-Calais,32,Hauts-de-France
62799,SORRUS,62170,SORRUS,,50.4564208466,1.71453079549,799,,Sorrus,Sorrus,62,Pas-de-Calais,32,Hauts-de-France
62800,SOUASTRE,62111,SOUASTRE,,50.1493830163,2.57000343449,800,,Souastre,Souastre,62,Pas-de-Calais,32,Hauts-de-France
62801,SOUCHEZ,62153,SOUCHEZ,,50.388730428,2.74349744668,801,,Souchez,Souchez,62,Pas-de-Calais,32,Hauts-de-France
62802,LE SOUICH,62810,LE SOUICH,,50.2233905,2.36160198411,802,Le,Souich,Le Souich,62,Pas-de-Calais,32,Hauts-de-France
62803,SURQUES,62850,SURQUES,,50.7434489941,1.92294829855,803,,Surques,Surques,62,Pas-de-Calais,32,Hauts-de-France
62804,SUS ST LEGER,62810,SUS ST LEGER,,50.2394092727,2.43472712358,804,,Sus-Saint-Léger,Sus-Saint-Léger,62,Pas-de-Calais,32,Hauts-de-France
62805,TANGRY,62550,TANGRY,,50.4621592652,2.36013300414,805,,Tangry,Tangry,62,Pas-de-Calais,32,Hauts-de-France
62806,TARDINGHEN,62179,TARDINGHEN,,50.8654992895,1.64282403638,806,,Tardinghen,Tardinghen,62,Pas-de-Calais,32,Hauts-de-France
62808,TENEUR,62134,TENEUR,,50.4521719287,2.22043017933,808,,Teneur,Teneur,62,Pas-de-Calais,32,Hauts-de-France
62809,TERNAS,62127,TERNAS,,50.3394097594,2.39775976719,809,,Ternas,Ternas,62,Pas-de-Calais,32,Hauts-de-France
62810,THELUS,62580,THELUS,,50.350962843,2.79959112856,810,,Thélus,Thélus,62,Pas-de-Calais,32,Hauts-de-France
62811,THEROUANNE,62129,THEROUANNE,,50.6288197176,2.25105673076,811,,Thérouanne,Thérouanne,62,Pas-de-Calais,32,Hauts-de-France
62812,THIEMBRONNE,62560,THIEMBRONNE,,50.6239861501,2.04691534962,812,,Thiembronne,Thiembronne,62,Pas-de-Calais,32,Hauts-de-France
62813,LA THIEULOYE,62130,LA THIEULOYE,,50.4137694617,2.43289315348,813,La,Thieuloye,La Thieuloye,62,Pas-de-Calais,32,Hauts-de-France
62814,THIEVRES,62760,THIEVRES,,50.1320744532,2.44789711987,814,,Thièvres,Thièvres,62,Pas-de-Calais,32,Hauts-de-France
62815,TIGNY NOYELLE,62180,TIGNY NOYELLE,,50.3549622929,1.70803750479,815,,Tigny-Noyelle,Tigny-Noyelle,62,Pas-de-Calais,32,Hauts-de-France
62816,TILLOY LES HERMAVILLE,62690,TILLOY LES HERMAVILLE,,50.3286181773,2.55390925943,816,,Tilloy-lès-Hermaville,Tilloy-lès-Hermaville,62,Pas-de-Calais,32,Hauts-de-France
62817,TILLOY LES MOFFLAINES,62217,TILLOY LES MOFFLAINES,,50.2772825816,2.82150381922,817,,Tilloy-lès-Mofflaines,Tilloy-lès-Mofflaines,62,Pas-de-Calais,32,Hauts-de-France
62818,TILLY CAPELLE,62134,TILLY CAPELLE,,50.4443030623,2.1871596899,818,,Tilly-Capelle,Tilly-Capelle,62,Pas-de-Calais,32,Hauts-de-France
62819,TILQUES,62500,TILQUES,,50.7728122391,2.20152463188,819,,Tilques,Tilques,62,Pas-de-Calais,32,Hauts-de-France
62820,TINCQUES,62127,TINCQUES,,50.3598646909,2.49403198382,820,,Tincques,Tincques,62,Pas-de-Calais,32,Hauts-de-France
62821,TINGRY,62830,TINGRY,,50.6110233696,1.72199394641,821,,Tingry,Tingry,62,Pas-de-Calais,32,Hauts-de-France
62822,TOLLENT,62390,TOLLENT,,50.2775872362,2.01380158526,822,,Tollent,Tollent,62,Pas-de-Calais,32,Hauts-de-France
62823,TORCY,62310,TORCY,,50.483966834,2.02072690969,823,,Torcy,Torcy,62,Pas-de-Calais,32,Hauts-de-France
62824,TORTEFONTAINE,62140,TORTEFONTAINE,,50.3354918555,1.91483333867,824,,Tortefontaine,Tortefontaine,62,Pas-de-Calais,32,Hauts-de-France
62825,TORTEQUESNE,62490,TORTEQUESNE,,50.2909229989,3.03256081417,825,,Tortequesne,Tortequesne,62,Pas-de-Calais,32,Hauts-de-France
62826,LE TOUQUET PARIS PLAGE,62520,LE TOUQUET PARIS PLAGE,,50.5083984501,1.59923820583,826,Le,Touquet-Paris-Plage,Le Touquet-Paris-Plage,62,Pas-de-Calais,32,Hauts-de-France
62827,TOURNEHEM SUR LA HEM,62890,TOURNEHEM SUR LA HEM,,50.7916214471,2.04769169432,827,,Tournehem-sur-la-Hem,Tournehem-sur-la-Hem,62,Pas-de-Calais,32,Hauts-de-France
62828,TRAMECOURT,62310,TRAMECOURT,,50.46970334,2.15213924885,828,,Tramecourt,Tramecourt,62,Pas-de-Calais,32,Hauts-de-France
62829,LE TRANSLOY,62450,LE TRANSLOY,,50.0502514612,2.89232344,829,Le,Transloy,Le Transloy,62,Pas-de-Calais,32,Hauts-de-France
62830,TRESCAULT,62147,TRESCAULT,,50.0904387311,3.09682034258,830,,Trescault,Trescault,62,Pas-de-Calais,32,Hauts-de-France
62831,TROISVAUX,62130,TROISVAUX,,50.4116603363,2.34375575662,831,,Troisvaux,Troisvaux,62,Pas-de-Calais,32,Hauts-de-France
62832,TUBERSENT,62630,TUBERSENT,,50.5216866473,1.69918169379,832,,Tubersent,Tubersent,62,Pas-de-Calais,32,Hauts-de-France
62833,VACQUERIE LE BOUCQ,62270,VACQUERIE LE BOUCQ,,50.2700526673,2.2075282322,833,,Vacquerie-le-Boucq,Vacquerie-le-Boucq,62,Pas-de-Calais,32,Hauts-de-France
62834,VACQUERIETTE ERQUIERES,62140,VACQUERIETTE ERQUIERES,,50.3228879351,2.07435637725,834,,Vacqueriette-Erquières,Vacqueriette-Erquières,62,Pas-de-Calais,32,Hauts-de-France
62834,VACQUERIETTE ERQUIERES,62140,VACQUERIETTE ERQUIERES,ERQUIERES,50.3228879351,2.07435637725,834,,Vacqueriette-Erquières,Vacqueriette-Erquières,62,Pas-de-Calais,32,Hauts-de-France
62835,VALHUON,62550,VALHUON,,50.4342829706,2.38336806426,835,,Valhuon,Valhuon,62,Pas-de-Calais,32,Hauts-de-France
62836,VAUDRICOURT,62131,VAUDRICOURT,,50.4996012728,2.61877656623,836,,Vaudricourt,Vaudricourt,62,Pas-de-Calais,32,Hauts-de-France
62837,VAUDRINGHEM,62380,VAUDRINGHEM,,50.6540835736,2.02409481692,837,,Vaudringhem,Vaudringhem,62,Pas-de-Calais,32,Hauts-de-France
62838,VAULX,62390,VAULX,,50.2710456344,2.1045646963,838,,Vaulx,Vaulx,62,Pas-de-Calais,32,Hauts-de-France
62839,VAULX VRAUCOURT,62159,VAULX VRAUCOURT,,50.1439845718,2.90458316152,839,,Vaulx-Vraucourt,Vaulx-Vraucourt,62,Pas-de-Calais,32,Hauts-de-France
62840,VELU,62124,VELU,,50.1007618527,2.97411822884,840,,Vélu,Vélu,62,Pas-de-Calais,32,Hauts-de-France
62841,VENDIN LES BETHUNE,62232,VENDIN LES BETHUNE,,50.5464095644,2.60682652334,841,,Vendin-lès-Béthune,Vendin-lès-Béthune,62,Pas-de-Calais,32,Hauts-de-France
62842,VENDIN LE VIEIL,62880,VENDIN LE VIEIL,,50.4703783932,2.85384547397,842,,Vendin-le-Vieil,Vendin-le-Vieil,62,Pas-de-Calais,32,Hauts-de-France
62843,VERCHIN,62310,VERCHIN,,50.493379155,2.17923063627,843,,Verchin,Verchin,62,Pas-de-Calais,32,Hauts-de-France
62844,VERCHOCQ,62560,VERCHOCQ,,50.5548367541,2.04003605108,844,,Verchocq,Verchocq,62,Pas-de-Calais,32,Hauts-de-France
62845,VERLINCTHUN,62830,VERLINCTHUN,,50.6285938608,1.69225464674,845,,Verlincthun,Verlincthun,62,Pas-de-Calais,32,Hauts-de-France
62846,VERMELLES,62980,VERMELLES,,50.4869056542,2.75733602392,846,,Vermelles,Vermelles,62,Pas-de-Calais,32,Hauts-de-France
62847,VERQUIGNEUL,62113,VERQUIGNEUL,,50.5030883097,2.66214119709,847,,Verquigneul,Verquigneul,62,Pas-de-Calais,32,Hauts-de-France
62848,VERQUIN,62131,VERQUIN,,50.5014162013,2.64245100087,848,,Verquin,Verquin,62,Pas-de-Calais,32,Hauts-de-France
62849,VERTON,62180,VERTON,,50.4023869418,1.65093274373,849,,Verton,Verton,62,Pas-de-Calais,32,Hauts-de-France
62850,VIEIL HESDIN,62770,VIEIL HESDIN,,50.3632756728,2.1130630782,850,,Vieil-Hesdin,Vieil-Hesdin,62,Pas-de-Calais,32,Hauts-de-France
62851,VIEILLE CHAPELLE,62136,VIEILLE CHAPELLE,,50.5929100116,2.71744022097,851,,Vieille-Chapelle,Vieille-Chapelle,62,Pas-de-Calais,32,Hauts-de-France
62852,VIEILLE EGLISE,62162,VIEILLE EGLISE,,50.9332624726,2.07653957537,852,,Vieille-Église,Vieille-Église,62,Pas-de-Calais,32,Hauts-de-France
62853,VIEIL MOUTIER,62240,VIEIL MOUTIER,,50.6624676779,1.91064000839,853,,Vieil-Moutier,Vieil-Moutier,62,Pas-de-Calais,32,Hauts-de-France
62854,VILLERS AU BOIS,62144,VILLERS AU BOIS,,50.3774575605,2.66415892943,854,,Villers-au-Bois,Villers-au-Bois,62,Pas-de-Calais,32,Hauts-de-France
62855,VILLERS AU FLOS,62450,VILLERS AU FLOS,,50.0787621287,2.90542012766,855,,Villers-au-Flos,Villers-au-Flos,62,Pas-de-Calais,32,Hauts-de-France
62856,VILLERS BRULIN,62690,VILLERS BRULIN,,50.3727264691,2.52941539032,856,,Villers-Brûlin,Villers-Brûlin,62,Pas-de-Calais,32,Hauts-de-France
62857,VILLERS CHATEL,62690,VILLERS CHATEL,,50.3751045916,2.58988048072,857,,Villers-Châtel,Villers-Châtel,62,Pas-de-Calais,32,Hauts-de-France
62858,VILLERS LES CAGNICOURT,62182,VILLERS LES CAGNICOURT,,50.2253150593,3.01039978275,858,,Villers-lès-Cagnicourt,Villers-lès-Cagnicourt,62,Pas-de-Calais,32,Hauts-de-France
62859,VILLERS L HOPITAL,62390,VILLERS L HOPITAL,,50.2291696538,2.21010808284,859,,Villers-l'Hôpital,Villers-l'Hôpital,62,Pas-de-Calais,32,Hauts-de-France
62860,VILLERS SIR SIMON,62127,VILLERS SIR SIMON,,50.3148651908,2.49209480845,860,,Villers-Sir-Simon,Villers-Sir-Simon,62,Pas-de-Calais,32,Hauts-de-France
62861,VIMY,62580,VIMY,,50.3776460123,2.81116855896,861,,Vimy,Vimy,62,Pas-de-Calais,32,Hauts-de-France
62862,VINCLY,62310,VINCLY,,50.5561858278,2.17901178873,862,,Vincly,Vincly,62,Pas-de-Calais,32,Hauts-de-France
62863,VIOLAINES,62138,VIOLAINES,,50.5377604504,2.77970850314,863,,Violaines,Violaines,62,Pas-de-Calais,32,Hauts-de-France
62864,VIS EN ARTOIS,62156,VIS EN ARTOIS,,50.245875467,2.93609831247,864,,Vis-en-Artois,Vis-en-Artois,62,Pas-de-Calais,32,Hauts-de-France
62865,VITRY EN ARTOIS,62490,VITRY EN ARTOIS,,50.3256177847,2.98092673342,865,,Vitry-en-Artois,Vitry-en-Artois,62,Pas-de-Calais,32,Hauts-de-France
62866,WABEN,62180,WABEN,,50.3822929262,1.65041372546,866,,Waben,Waben,62,Pas-de-Calais,32,Hauts-de-France
62867,WACQUINGHEN,62250,WACQUINGHEN,,50.780796244,1.66928695416,867,,Wacquinghen,Wacquinghen,62,Pas-de-Calais,32,Hauts-de-France
62868,WAIL,62770,WAIL,,50.3377712798,2.11915213609,868,,Wail,Wail,62,Pas-de-Calais,32,Hauts-de-France
62869,WAILLY,62217,WAILLY,,50.249116803,2.72419041642,869,,Wailly,Wailly,62,Pas-de-Calais,32,Hauts-de-France
62870,WAILLY BEAUCAMP,62170,WAILLY BEAUCAMP,,50.4082282743,1.73011785449,870,,Wailly-Beaucamp,Wailly-Beaucamp,62,Pas-de-Calais,32,Hauts-de-France
62871,WAMBERCOURT,62140,WAMBERCOURT,,50.4340661657,2.0238876189,871,,Wambercourt,Wambercourt,62,Pas-de-Calais,32,Hauts-de-France
62872,WAMIN,62770,WAMIN,,50.4153161017,2.06205464514,872,,Wamin,Wamin,62,Pas-de-Calais,32,Hauts-de-France
62873,WANCOURT,62128,WANCOURT,,50.256058557,2.85524996324,873,,Wancourt,Wancourt,62,Pas-de-Calais,32,Hauts-de-France
62874,WANQUETIN,62123,WANQUETIN,,50.2723730799,2.61412474296,874,,Wanquetin,Wanquetin,62,Pas-de-Calais,32,Hauts-de-France
62875,WARDRECQUES,62120,WARDRECQUES,,50.7053320588,2.34558119432,875,,Wardrecques,Wardrecques,62,Pas-de-Calais,32,Hauts-de-France
62876,WARLENCOURT EAUCOURT,62450,WARLENCOURT EAUCOURT,,50.0811867179,2.79134969906,876,,Warlencourt-Eaucourt,Warlencourt-Eaucourt,62,Pas-de-Calais,32,Hauts-de-France
62877,WARLINCOURT LES PAS,62760,WARLINCOURT LES PAS,,50.1841807304,2.50628872795,877,,Warlincourt-lès-Pas,Warlincourt-lès-Pas,62,Pas-de-Calais,32,Hauts-de-France
62878,WARLUS,62123,WARLUS,,50.2793115608,2.66486908357,878,,Warlus,Warlus,62,Pas-de-Calais,32,Hauts-de-France
62879,WARLUZEL,62810,WARLUZEL,,50.2311660847,2.46913010596,879,,Warluzel,Warluzel,62,Pas-de-Calais,32,Hauts-de-France
62880,LE WAST,62142,LE WAST,,50.750004796,1.79828909838,880,Le,Wast,Le Wast,62,Pas-de-Calais,32,Hauts-de-France
62881,BEAUVOIR WAVANS,62390,BEAUVOIR WAVANS,,50.2165851287,2.1653582453,881,,Beauvoir-Wavans,Beauvoir-Wavans,62,Pas-de-Calais,32,Hauts-de-France
62882,WAVRANS SUR L AA,62380,WAVRANS SUR L AA,,50.6749530079,2.13817752788,882,,Wavrans-sur-l'Aa,Wavrans-sur-l'Aa,62,Pas-de-Calais,32,Hauts-de-France
62883,WAVRANS SUR TERNOISE,62130,WAVRANS SUR TERNOISE,,50.409386121,2.2840999024,883,,Wavrans-sur-Ternoise,Wavrans-sur-Ternoise,62,Pas-de-Calais,32,Hauts-de-France
62885,WESTREHEM,62960,WESTREHEM,,50.5447817916,2.34356634513,885,,Westrehem,Westrehem,62,Pas-de-Calais,32,Hauts-de-France
62886,WICQUINGHEM,62650,WICQUINGHEM,,50.5725871336,1.95032069551,886,,Wicquinghem,Wicquinghem,62,Pas-de-Calais,32,Hauts-de-France
62887,WIDEHEM,62630,WIDEHEM,,50.5841311365,1.65936419487,887,,Widehem,Widehem,62,Pas-de-Calais,32,Hauts-de-France
62888,WIERRE AU BOIS,62830,WIERRE AU BOIS,,50.651199611,1.76845641503,888,,Wierre-au-Bois,Wierre-au-Bois,62,Pas-de-Calais,32,Hauts-de-France
62889,WIERRE EFFROY,62720,WIERRE EFFROY,,50.7718663253,1.73716281389,889,,Wierre-Effroy,Wierre-Effroy,62,Pas-de-Calais,32,Hauts-de-France
62890,WILLEMAN,62770,WILLEMAN,,50.3594087947,2.16281250365,890,,Willeman,Willeman,62,Pas-de-Calais,32,Hauts-de-France
62891,WILLENCOURT,62390,WILLENCOURT,,50.2394266639,2.07766867117,891,,Willencourt,Willencourt,62,Pas-de-Calais,32,Hauts-de-France
62892,WILLERVAL,62580,WILLERVAL,,50.3625979947,2.84933464088,892,,Willerval,Willerval,62,Pas-de-Calais,32,Hauts-de-France
62893,WIMEREUX,62930,WIMEREUX,,50.7792497892,1.6134323131,893,,Wimereux,Wimereux,62,Pas-de-Calais,32,Hauts-de-France
62894,WIMILLE,62126,WIMILLE,,50.7649885294,1.64203664126,894,,Wimille,Wimille,62,Pas-de-Calais,32,Hauts-de-France
62895,WINGLES,62410,WINGLES,,50.4951923669,2.85777326738,895,,Wingles,Wingles,62,Pas-de-Calais,32,Hauts-de-France
62896,WIRWIGNES,62240,WIRWIGNES,,50.685266842,1.76740602045,896,,Wirwignes,Wirwignes,62,Pas-de-Calais,32,Hauts-de-France
62897,WISMES,62380,WISMES,,50.6613559583,2.07972084711,897,,Wismes,Wismes,62,Pas-de-Calais,32,Hauts-de-France
62898,WISQUES,62219,WISQUES,,50.7269504581,2.1911996601,898,,Wisques,Wisques,62,Pas-de-Calais,32,Hauts-de-France
62899,WISSANT,62179,WISSANT,,50.8883355248,1.68248567418,899,,Wissant,Wissant,62,Pas-de-Calais,32,Hauts-de-France
62900,WITTERNESSE,62120,WITTERNESSE,,50.6139812013,2.36320125058,900,,Witternesse,Witternesse,62,Pas-de-Calais,32,Hauts-de-France
62901,WITTES,62120,WITTES,,50.6716650445,2.38817871374,901,,Wittes,Wittes,62,Pas-de-Calais,32,Hauts-de-France
62902,WIZERNES,62570,WIZERNES,,50.7153325042,2.22913159655,902,,Wizernes,Wizernes,62,Pas-de-Calais,32,Hauts-de-France
62903,ZOTEUX,62650,ZOTEUX,,50.614922981,1.88746444712,903,,Zoteux,Zoteux,62,Pas-de-Calais,32,Hauts-de-France
62904,ZOUAFQUES,62890,ZOUAFQUES,,50.8228097659,2.05320953187,904,,Zouafques,Zouafques,62,Pas-de-Calais,32,Hauts-de-France
62904,ZOUAFQUES,62890,ZOUAFQUES,LA RECOUSSE,50.8228097659,2.05320953187,904,,Zouafques,Zouafques,62,Pas-de-Calais,32,Hauts-de-France
62905,ZUDAUSQUES,62500,ZUDAUSQUES,,50.7526605163,2.16024730203,905,,Zudausques,Zudausques,62,Pas-de-Calais,32,Hauts-de-France
62906,ZUTKERQUE,62370,ZUTKERQUE,,50.8528869572,2.05960239074,906,,Zutkerque,Zutkerque,62,Pas-de-Calais,32,Hauts-de-France
62907,LIBERCOURT,62820,LIBERCOURT,,50.482031915,2.99971382524,907,,Libercourt,Libercourt,62,Pas-de-Calais,32,Hauts-de-France
62908,LA CAPELLE LES BOULOGNE,62360,LA CAPELLE LES BOULOGNE,,50.7313010692,1.71597979438,908,La,Capelle-lès-Boulogne,La Capelle-lès-Boulogne,62,Pas-de-Calais,32,Hauts-de-France
62909,YTRES,62124,YTRES,,50.0647969783,2.99626975733,909,,Ytres,Ytres,62,Pas-de-Calais,32,Hauts-de-France
63001,AIGUEPERSE,63260,AIGUEPERSE,,46.014833908,3.2012859723,1,,Aigueperse,Aigueperse,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63002,AIX LA FAYETTE,63980,AIX LA FAYETTE,,45.5069762836,3.52877162272,2,,Aix-la-Fayette,Aix-la-Fayette,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63003,AMBERT,63600,AMBERT,,45.5557839461,3.75625356567,3,,Ambert,Ambert,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63004,LES ANCIZES COMPS,63770,LES ANCIZES COMPS,,45.9331353459,2.80539332166,4,Les,Ancizes-Comps,Les Ancizes-Comps,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63005,ANTOINGT,63340,ANTOINGT,,45.5004481767,3.18402754188,5,,Antoingt,Antoingt,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63006,ANZAT LE LUGUET,63420,ANZAT LE LUGUET,,45.3306191916,3.01194241608,6,,Anzat-le-Luguet,Anzat-le-Luguet,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63007,APCHAT,63420,APCHAT,,45.3738404423,3.14813755867,7,,Apchat,Apchat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63008,ARCONSAT,63250,ARCONSAT,,45.8981332955,3.71595849831,8,,Arconsat,Arconsat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63009,ARDES,63420,ARDES,,45.3984149619,3.11786009543,9,,Ardes,Ardes,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63010,ARLANC,63220,ARLANC,,45.4207986655,3.72311154214,10,,Arlanc,Arlanc,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63011,ARS LES FAVETS,63700,ARS LES FAVETS,,46.2002650376,2.74850375721,11,,Ars-les-Favets,Ars-les-Favets,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63012,ARTONNE,63460,ARTONNE,,46.0058522543,3.15012499904,12,,Artonne,Artonne,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63013,AUBIAT,63260,AUBIAT,,45.9806227322,3.19256070994,13,,Aubiat,Aubiat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63013,AUBIAT,63260,AUBIAT,CHAZELLES,45.9806227322,3.19256070994,13,,Aubiat,Aubiat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63014,AUBIERE,63170,AUBIERE,,45.7510558385,3.126134119,14,,Aubière,Aubière,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63015,AUBUSSON D AUVERGNE,63120,AUBUSSON D AUVERGNE,,45.7532116404,3.59888416853,15,,Aubusson-d'Auvergne,Aubusson-d'Auvergne,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63016,AUGEROLLES,63930,AUGEROLLES,,45.7367380515,3.63089700637,16,,Augerolles,Augerolles,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63017,AUGNAT,63340,AUGNAT,,45.4140216179,3.17930900469,17,,Augnat,Augnat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63019,AULNAT,63510,AULNAT,,45.7931829001,3.17108169057,19,,Aulnat,Aulnat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63020,AURIERES,63210,AURIERES,,45.6818392854,2.91546780422,20,,Aurières,Aurières,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63021,AUTHEZAT,63114,AUTHEZAT,,45.6385828582,3.19313623822,21,,Authezat,Authezat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63022,AUZAT LA COMBELLE,63570,AUZAT LA COMBELLE,,45.4508101492,3.32589518501,22,,Auzat-la-Combelle,Auzat-la-Combelle,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63022,AUZAT LA COMBELLE,63570,AUZAT LA COMBELLE,LA COMBELLE,45.4508101492,3.32589518501,22,,Auzat-la-Combelle,Auzat-la-Combelle,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63023,AUZELLES,63590,AUZELLES,,45.6068543824,3.51902613907,23,,Auzelles,Auzelles,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63024,AVEZE,63690,AVEZE,,45.5970951985,2.60615054719,24,,Avèze,Avèze,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63025,AYAT SUR SIOULE,63390,AYAT SUR SIOULE,,46.0615532638,2.88700603569,25,,Ayat-sur-Sioule,Ayat-sur-Sioule,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63026,AYDAT,63970,AYDAT,,45.6667878523,2.98447780455,26,,Aydat,Aydat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63027,BAFFIE,63600,BAFFIE,,45.4768637549,3.82808996896,27,,Baffie,Baffie,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63028,BAGNOLS,63810,BAGNOLS,,45.4980479842,2.63866750043,28,,Bagnols,Bagnols,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63029,BANSAT,63570,BANSAT,,45.4834504429,3.3603356252,29,,Bansat,Bansat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63030,BAS ET LEZAT,63310,BAS ET LEZAT,,46.0370937855,3.30812552626,30,,Bas-et-Lezat,Bas-et-Lezat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63031,BEAULIEU,63570,BEAULIEU,,45.4434163862,3.2857086032,31,,Beaulieu,Beaulieu,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63032,BEAUMONT,63110,BEAUMONT,,45.7498745868,3.08667875235,32,,Beaumont,Beaumont,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63033,BEAUMONT LES RANDAN,63310,BEAUMONT LES RANDAN,,45.9963944263,3.38335354791,33,,Beaumont-lès-Randan,Beaumont-lès-Randan,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63034,BEAUREGARD L EVEQUE,63116,BEAUREGARD L EVEQUE,,45.8137742226,3.29884607281,34,,Beauregard-l'Évêque,Beauregard-l'Évêque,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63035,BEAUREGARD VENDON,63460,BEAUREGARD VENDON,,45.9656649406,3.11312148208,35,,Beauregard-Vendon,Beauregard-Vendon,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63036,BERGONNE,63500,BERGONNE,,45.5097308749,3.21674790663,36,,Bergonne,Bergonne,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63037,BERTIGNAT,63480,BERTIGNAT,,45.6174754909,3.69083488933,37,,Bertignat,Bertignat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63038,BESSE ET ST ANASTAISE,63610,BESSE ET ST ANASTAISE,,45.4967792648,2.90703862997,38,,Besse-et-Saint-Anastaise,Besse-et-Saint-Anastaise,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63038,BESSE ET ST ANASTAISE,63610,BESSE ET ST ANASTAISE,ST ANASTAISE,45.4967792648,2.90703862997,38,,Besse-et-Saint-Anastaise,Besse-et-Saint-Anastaise,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63038,BESSE ET ST ANASTAISE,63610,BESSE ET ST ANASTAISE,SUPER BESSE,45.4967792648,2.90703862997,38,,Besse-et-Saint-Anastaise,Besse-et-Saint-Anastaise,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63039,BEURIERES,63220,BEURIERES,,45.4316094965,3.77865697978,39,,Beurières,Beurières,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63040,BILLOM,63160,BILLOM,,45.7251184657,3.32304115809,40,,Billom,Billom,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63041,BIOLLET,63640,BIOLLET,,45.9926597245,2.69344623164,41,,Biollet,Biollet,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63042,BLANZAT,63112,BLANZAT,,45.8274618701,3.07331572461,42,,Blanzat,Blanzat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63043,BLOT L EGLISE,63440,BLOT L EGLISE,,46.0337566478,2.95773350422,43,,Blot-l'Église,Blot-l'Église,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63044,BONGHEAT,63160,BONGHEAT,,45.7244737269,3.42713550452,44,,Bongheat,Bongheat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63045,BORT L ETANG,63190,BORT L ETANG,,45.7812457267,3.43542941153,45,,Bort-l'Étang,Bort-l'Étang,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63046,BOUDES,63340,BOUDES,,45.4522556993,3.18246169871,46,,Boudes,Boudes,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63047,LA BOURBOULE,63150,LA BOURBOULE,,45.5796236201,2.7501805247,47,La,Bourboule,La Bourboule,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63048,BOURG LASTIC,63760,BOURG LASTIC,,45.6572710215,2.56278019812,48,,Bourg-Lastic,Bourg-Lastic,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63049,BOUZEL,63910,BOUZEL,,45.7801556099,3.31866753421,49,,Bouzel,Bouzel,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63050,BRASSAC LES MINES,63570,BRASSAC LES MINES,,45.4199156591,3.32061008295,50,,Brassac-les-Mines,Brassac-les-Mines,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63051,BRENAT,63500,BRENAT,,45.5494693829,3.30961453895,51,,Brenat,Brenat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63052,LE BREUIL SUR COUZE,63340,LE BREUIL SUR COUZE,,45.4671208876,3.26675909597,52,Le,Breuil-sur-Couze,Le Breuil-sur-Couze,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63053,BRIFFONS,63820,BRIFFONS,,45.7007518204,2.65439923771,53,,Briffons,Briffons,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63054,LE BROC,63500,LE BROC,,45.5037915614,3.25150034962,54,Le,Broc,Le Broc,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63055,BROMONT LAMOTHE,63230,BROMONT LAMOTHE,,45.8322474216,2.81021693697,55,,Bromont-Lamothe,Bromont-Lamothe,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63056,BROUSSE,63490,BROUSSE,,45.6003729958,3.46386697811,56,,Brousse,Brousse,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63057,LE BRUGERON,63880,LE BRUGERON,,45.7117515141,3.74237133965,57,Le,Brugeron,Le Brugeron,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63058,BULHON,63350,BULHON,,45.8848012313,3.39133454384,58,,Bulhon,Bulhon,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63059,BUSSEOL,63270,BUSSEOL,,45.6933391482,3.2623134791,59,,Busséol,Busséol,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63060,BUSSIERES,63330,BUSSIERES,,46.0639164771,2.64739056246,60,,Bussières,Bussières,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63061,BUSSIERES ET PRUNS,63260,BUSSIERES ET PRUNS,,46.0068491978,3.23823283226,61,,Bussières-et-Pruns,Bussières-et-Pruns,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63062,BUXIERES SOUS MONTAIGUT,63700,BUXIERES SOUS MONTAIGUT,,46.2017165035,2.8370551426,62,,Buxières-sous-Montaigut,Buxières-sous-Montaigut,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63063,CEBAZAT,63118,CEBAZAT,,45.8318909179,3.10802814876,63,,Cébazat,Cébazat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63064,LA CELLE,63620,LA CELLE,,45.8497369275,2.47438590969,64,La,Celle,La Celle,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63065,CEILLOUX,63520,CEILLOUX,,45.6552162831,3.51622303139,65,,Ceilloux,Ceilloux,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63066,CELLES SUR DUROLLE,63250,CELLES SUR DUROLLE,,45.8720497687,3.65199036079,66,,Celles-sur-Durolle,Celles-sur-Durolle,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63066,CELLES SUR DUROLLE,63250,CELLES SUR DUROLLE,LES SARRAIX,45.8720497687,3.65199036079,66,,Celles-sur-Durolle,Celles-sur-Durolle,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63067,LA CELLETTE,63330,LA CELLETTE,,46.0955904109,2.72649262532,67,La,Cellette,La Cellette,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63069,LE CENDRE,63670,LE CENDRE,,45.7178589681,3.19396372923,69,Le,Cendre,Le Cendre,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63070,CEYRAT,63122,CEYRAT,,45.7385665795,3.06427177861,70,,Ceyrat,Ceyrat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63070,CEYRAT,63122,CEYRAT,BOISSEJOUR,45.7385665795,3.06427177861,70,,Ceyrat,Ceyrat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63071,CEYSSAT,63210,CEYSSAT,,45.7698934261,2.9244283989,71,,Ceyssat,Ceyssat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63072,CHABRELOCHE,63250,CHABRELOCHE,,45.8711155066,3.69927199533,72,,Chabreloche,Chabreloche,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63073,CHADELEUF,63320,CHADELEUF,,45.5811513709,3.18117536948,73,,Chadeleuf,Chadeleuf,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63074,CHALUS,63340,CHALUS,,45.4646474919,3.20803180257,74,,Chalus,Chalus,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63075,CHAMALIERES,63400,CHAMALIERES,,45.7747966551,3.0612681838,75,,Chamalières,Chamalières,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63076,CHAMBON SUR DOLORE,63980,CHAMBON SUR DOLORE,,45.4970592625,3.62526275053,76,,Chambon-sur-Dolore,Chambon-sur-Dolore,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63077,CHAMBON SUR LAC,63790,CHAMBON SUR LAC,,45.5602562882,2.86880475585,77,,Chambon-sur-Lac,Chambon-sur-Lac,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63078,CHAMEANE,63580,CHAMEANE,,45.5198014002,3.43838921924,78,,Chaméane,Chaméane,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63079,CHAMPAGNAT LE JEUNE,63580,CHAMPAGNAT LE JEUNE,,45.4445734596,3.41645424575,79,,Champagnat-le-Jeune,Champagnat-le-Jeune,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63080,CHAMPEIX,63320,CHAMPEIX,,45.5840997399,3.12859021721,80,,Champeix,Champeix,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63081,CHAMPETIERES,63600,CHAMPETIERES,,45.5157534837,3.67868280607,81,,Champétières,Champétières,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63082,CHAMPS,63440,CHAMPS,,46.0616676024,3.09345321573,82,,Champs,Champs,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63083,CHANAT LA MOUTEYRE,63530,CHANAT LA MOUTEYRE,,45.825281343,3.00698253027,83,,Chanat-la-Mouteyre,Chanat-la-Mouteyre,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63084,CHANONAT,63450,CHANONAT,,45.6953181074,3.08324914721,84,,Chanonat,Chanonat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63085,CHAPDES BEAUFORT,63230,CHAPDES BEAUFORT,,45.8923408221,2.85542542398,85,,Chapdes-Beaufort,Chapdes-Beaufort,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63086,LA CHAPELLE AGNON,63590,LA CHAPELLE AGNON,,45.6297458616,3.62896943409,86,La,Chapelle-Agnon,La Chapelle-Agnon,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63087,LA CHAPELLE MARCOUSSE,63420,LA CHAPELLE MARCOUSSE,,45.4421073659,3.08079339129,87,La,Chapelle-Marcousse,La Chapelle-Marcousse,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63088,LA CHAPELLE SUR USSON,63580,LA CHAPELLE SUR USSON,,45.4637778888,3.39230982633,88,La,Chapelle-sur-Usson,La Chapelle-sur-Usson,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63089,CHAPPES,63720,CHAPPES,,45.8653394386,3.22674645901,89,,Chappes,Chappes,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63090,CHAPTUZAT,63260,CHAPTUZAT,,46.0302322013,3.17010831836,90,,Chaptuzat,Chaptuzat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63091,CHARBONNIER LES MINES,63340,CHARBONNIER LES MINES,,45.4181480729,3.28199342923,91,,Charbonnier-les-Mines,Charbonnier-les-Mines,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63092,CHARBONNIERES LES VARENNES,63410,CHARBONNIERES LES VARENNES,,45.8981076915,2.98002956187,92,,Charbonnières-les-Varennes,Charbonnières-les-Varennes,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63092,CHARBONNIERES LES VARENNES,63410,CHARBONNIERES LES VARENNES,PAUGNAT,45.8981076915,2.98002956187,92,,Charbonnières-les-Varennes,Charbonnières-les-Varennes,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63093,CHARBONNIERES LES VIEILLES,63410,CHARBONNIERES LES VIEILLES,,45.9916492196,3.00764883452,93,,Charbonnières-les-Vieilles,Charbonnières-les-Vieilles,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63094,CHARENSAT,63640,CHARENSAT,,45.9834200472,2.64390606976,94,,Charensat,Charensat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63095,CHARNAT,63290,CHARNAT,,45.9449562685,3.43494838607,95,,Charnat,Charnat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63096,CHAS,63160,CHAS,,45.7472569761,3.30727070935,96,,Chas,Chas,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63097,CHASSAGNE,63320,CHASSAGNE,,45.492969211,3.06150388982,97,,Chassagne,Chassagne,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63098,CHASTREIX,63680,CHASTREIX,,45.5125229995,2.7534823407,98,,Chastreix,Chastreix,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63099,CHATEAUGAY,63119,CHATEAUGAY,,45.8537260918,3.09327050267,99,,Châteaugay,Châteaugay,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63100,CHATEAUNEUF LES BAINS,63390,CHATEAUNEUF LES BAINS,,46.0254681643,2.88743233345,100,,Châteauneuf-les-Bains,Châteauneuf-les-Bains,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63101,CHATEAU SUR CHER,63330,CHATEAU SUR CHER,,46.1168680835,2.56878532043,101,,Château-sur-Cher,Château-sur-Cher,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63102,CHATELDON,63290,CHATELDON,,45.9733956022,3.55296275066,102,,Châteldon,Châteldon,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63103,CHATEL GUYON,63140,CHATEL GUYON,,45.9206993185,3.0632143777,103,,Châtel-Guyon,Châtel-Guyon,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63103,CHATEL GUYON,63140,CHATEL GUYON,ST HIPPOLYTE,45.9206993185,3.0632143777,103,,Châtel-Guyon,Châtel-Guyon,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63104,LA CHAULME,63660,LA CHAULME,,45.4716212556,3.95792941378,104,La,Chaulme,La Chaulme,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63105,CHAUMONT LE BOURG,63220,CHAUMONT LE BOURG,,45.4581251093,3.76551615562,105,,Chaumont-le-Bourg,Chaumont-le-Bourg,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63106,CHAURIAT,63117,CHAURIAT,,45.7437351677,3.27755208061,106,,Chauriat,Chauriat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63107,CHAVAROUX,63720,CHAVAROUX,,45.8558212715,3.25835959174,107,,Chavaroux,Chavaroux,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63108,LE CHEIX,63200,LE CHEIX,,45.9573037327,3.18187928237,108,Le,Cheix,Le Cheix,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63109,CHIDRAC,63320,CHIDRAC,,45.5594914487,3.15044914308,109,,Chidrac,Chidrac,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63110,CISTERNES LA FORET,63740,CISTERNES LA FORET,,45.808024859,2.71463037162,110,,Cisternes-la-Forêt,Cisternes-la-Forêt,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63111,CLEMENSAT,63320,CLEMENSAT,,45.5641956437,3.10521274351,111,,Clémensat,Clémensat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63112,CLERLANDE,63720,CLERLANDE,,45.916303528,3.19013272843,112,,Clerlande,Clerlande,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63113,CLERMONT FERRAND,63000,CLERMONT FERRAND,,45.7856492991,3.11554542903,113,,Clermont-Ferrand,Clermont-Ferrand,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63113,CLERMONT FERRAND,63100,CLERMONT FERRAND,,45.7856492991,3.11554542903,113,,Clermont-Ferrand,Clermont-Ferrand,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63114,COLLANGES,63340,COLLANGES,,45.435304448,3.21485301473,114,,Collanges,Collanges,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63115,COMBRAILLES,63380,COMBRAILLES,,45.8408553955,2.64157782279,115,,Combrailles,Combrailles,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63116,COMBRONDE,63460,COMBRONDE,,45.9858171361,3.08063423598,116,,Combronde,Combronde,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63117,COMPAINS,63610,COMPAINS,,45.4364463518,2.92462564486,117,,Compains,Compains,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63118,CONDAT EN COMBRAILLE,63380,CONDAT EN COMBRAILLE,,45.8621808314,2.56600859259,118,,Condat-en-Combraille,Condat-en-Combraille,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63119,CONDAT LES MONTBOISSIER,63490,CONDAT LES MONTBOISSIER,,45.5577736133,3.47788895135,119,,Condat-lès-Montboissier,Condat-lès-Montboissier,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63120,CORENT,63730,CORENT,,45.6605122795,3.19265515129,120,,Corent,Corent,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63121,COUDES,63114,COUDES,,45.6105151994,3.20143305932,121,,Coudes,Coudes,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63122,COURGOUL,63320,COURGOUL,,45.5117810661,3.0464942145,122,,Courgoul,Courgoul,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63123,COURNOLS,63450,COURNOLS,,45.6415170829,3.03680111738,123,,Cournols,Cournols,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63124,COURNON D AUVERGNE,63800,COURNON D AUVERGNE,,45.7429670015,3.18687581638,124,,Cournon-d'Auvergne,Cournon-d'Auvergne,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63125,COURPIERE,63120,COURPIERE,,45.7580183795,3.54121734983,125,,Courpière,Courpière,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63126,LE CREST,63450,LE CREST,,45.6887173351,3.13282364947,126,Le,Crest,Le Crest,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63127,CRESTE,63320,CRESTE,,45.5556818427,3.05131458624,127,,Creste,Creste,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63128,CREVANT LAVEINE,63350,CREVANT LAVEINE,,45.90236787,3.4014570747,128,,Crevant-Laveine,Crevant-Laveine,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63129,CROS,63810,CROS,,45.466906332,2.60796346653,129,,Cros,Cros,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63130,LA CROUZILLE,63700,LA CROUZILLE,,46.1718472306,2.75158999506,130,La,Crouzille,La Crouzille,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63131,CULHAT,63350,CULHAT,,45.8605302277,3.34367377982,131,,Culhat,Culhat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63132,CUNLHAT,63590,CUNLHAT,,45.6303425286,3.56287931079,132,,Cunlhat,Cunlhat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63133,DALLET,63111,DALLET,,45.7717717007,3.2385610139,133,,Dallet,Dallet,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63134,DAUZAT SUR VODABLE,63340,DAUZAT SUR VODABLE,,45.4713470837,3.0825251658,134,,Dauzat-sur-Vodable,Dauzat-sur-Vodable,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63135,DAVAYAT,63200,DAVAYAT,,45.9471124393,3.11541506144,135,,Davayat,Davayat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63136,DOMAIZE,63520,DOMAIZE,,45.686410901,3.53308767944,136,,Domaize,Domaize,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63137,DORANGES,63220,DORANGES,,45.3996897026,3.60566060253,137,,Doranges,Doranges,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63138,DORAT,63300,DORAT,,45.8956338419,3.48091335909,138,,Dorat,Dorat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63139,DORE L EGLISE,63220,DORE L EGLISE,,45.3776442028,3.75676812672,139,,Dore-l'Église,Dore-l'Église,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63140,DURMIGNAT,63700,DURMIGNAT,,46.1968182287,2.89315984455,140,,Durmignat,Durmignat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63141,DURTOL,63830,DURTOL,,45.7965429295,3.05159715299,141,,Durtol,Durtol,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63142,ECHANDELYS,63980,ECHANDELYS,,45.5469529567,3.53131238334,142,,Échandelys,Échandelys,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63143,EFFIAT,63260,EFFIAT,,46.0444270814,3.26882771667,143,,Effiat,Effiat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63144,EGLISENEUVE D ENTRAIGUES,63850,EGLISENEUVE D ENTRAIGUES,,45.4211832681,2.82270295289,144,,Égliseneuve-d'Entraigues,Égliseneuve-d'Entraigues,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63145,EGLISENEUVE DES LIARDS,63490,EGLISENEUVE DES LIARDS,,45.5658772497,3.42053251866,145,,Égliseneuve-des-Liards,Égliseneuve-des-Liards,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63146,EGLISENEUVE PRES BILLOM,63160,EGLISENEUVE PRES BILLOM,,45.7163424749,3.38987095565,146,,Égliseneuve-près-Billom,Égliseneuve-près-Billom,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63147,EGLISOLLES,63840,EGLISOLLES,,45.4686394597,3.87133916451,147,,Églisolles,Églisolles,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63148,ENNEZAT,63720,ENNEZAT,,45.895678159,3.21640094817,148,,Ennezat,Ennezat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63149,ENTRAIGUES,63720,ENTRAIGUES,,45.8842162628,3.26384627263,149,,Entraigues,Entraigues,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63150,ENVAL,63530,ENVAL,,45.8971595331,3.05295746173,150,,Enval,Enval,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63151,ESCOUTOUX,63300,ESCOUTOUX,,45.8157734773,3.5542890895,151,,Escoutoux,Escoutoux,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63152,ESPINASSE,63390,ESPINASSE,,46.0340938449,2.71073825365,152,,Espinasse,Espinasse,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63153,ESPINCHAL,63850,ESPINCHAL,,45.3946444984,2.87909484661,153,,Espinchal,Espinchal,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63154,ESPIRAT,63160,ESPIRAT,,45.7531766214,3.3349537305,154,,Espirat,Espirat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63155,ESTANDEUIL,63520,ESTANDEUIL,,45.6814605393,3.44431665529,155,,Estandeuil,Estandeuil,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63156,ESTEIL,63570,ESTEIL,,45.453054984,3.36737872864,156,,Esteil,Esteil,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63157,FAYET LE CHATEAU,63160,FAYET LE CHATEAU,,45.6792802707,3.40952344824,157,,Fayet-le-Château,Fayet-le-Château,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63158,FAYET RONAYE,63630,FAYET RONAYE,,45.4189763159,3.53569524449,158,,Fayet-Ronaye,Fayet-Ronaye,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63159,FERNOEL,63620,FERNOEL,,45.8175774487,2.42216277388,159,,Fernoël,Fernoël,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63160,AULHAT FLAT,63500,AULHAT FLAT,,45.5717634419,3.294046648,160,,Aulhat-Flat,Aulhat-Flat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63160,AULHAT FLAT,63500,AULHAT FLAT,AULHAT ST PRIVAT,45.5717634419,3.294046648,160,,Aulhat-Flat,Aulhat-Flat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63161,LA FORIE,63600,LA FORIE,,45.5886957413,3.75371206334,161,La,Forie,La Forie,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63162,FOURNOLS,63980,FOURNOLS,,45.5212211477,3.58921545609,162,,Fournols,Fournols,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63163,GELLES,63740,GELLES,,45.7617631556,2.77069441162,163,,Gelles,Gelles,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63164,GERZAT,63360,GERZAT,,45.8279149927,3.15493420222,164,,Gerzat,Gerzat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63165,GIAT,63620,GIAT,,45.7993582147,2.47685317783,165,,Giat,Giat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63166,GIGNAT,63340,GIGNAT,,45.4865085499,3.21841465193,166,,Gignat,Gignat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63167,GIMEAUX,63200,GIMEAUX,,45.9488128227,3.09815848902,167,,Gimeaux,Gimeaux,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63168,GLAINE MONTAIGUT,63160,GLAINE MONTAIGUT,,45.7509787495,3.38638176937,168,,Glaine-Montaigut,Glaine-Montaigut,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63169,LA GODIVELLE,63850,LA GODIVELLE,,45.389984353,2.91731415419,169,La,Godivelle,La Godivelle,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63170,LA GOUTELLE,63230,LA GOUTELLE,,45.844471607,2.74702322197,170,La,Goutelle,La Goutelle,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63171,GOUTTIERES,63390,GOUTTIERES,,46.0642315277,2.77508071716,171,,Gouttières,Gouttières,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63172,GRANDEYROLLES,63320,GRANDEYROLLES,,45.5829881204,3.04671739846,172,,Grandeyrolles,Grandeyrolles,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63173,GRANDRIF,63600,GRANDRIF,,45.5197967423,3.83458423534,173,,Grandrif,Grandrif,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63174,GRANDVAL,63890,GRANDVAL,,45.5959407776,3.65123463129,174,,Grandval,Grandval,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63175,HERMENT,63470,HERMENT,,45.7634315003,2.56960581546,175,,Herment,Herment,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63176,HEUME L EGLISE,63210,HEUME L EGLISE,,45.7212118766,2.72132681759,176,,Heume-l'Église,Heume-l'Église,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63177,ISSERTEAUX,63270,ISSERTEAUX,,45.653140809,3.38111745386,177,,Isserteaux,Isserteaux,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63178,ISSOIRE,63500,ISSOIRE,,45.5455577894,3.24499324643,178,,Issoire,Issoire,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63179,JOB,63990,JOB,,45.6284431431,3.7630429463,179,,Job,Job,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63180,JOZE,63350,JOZE,,45.8682468414,3.30362665352,180,,Joze,Joze,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63181,JOZERAND,63460,JOZERAND,,46.0248498581,3.09894573011,181,,Jozerand,Jozerand,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63182,JUMEAUX,63570,JUMEAUX,,45.43440961,3.34825518447,182,,Jumeaux,Jumeaux,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63183,LABESSETTE,63690,LABESSETTE,,45.4961438957,2.53609023312,183,,Labessette,Labessette,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63184,LACHAUX,63290,LACHAUX,,45.9923135282,3.59392264341,184,,Lachaux,Lachaux,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63185,LAMONTGIE,63570,LAMONTGIE,,45.4745838897,3.32883718084,185,,Lamontgie,Lamontgie,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63186,LANDOGNE,63380,LANDOGNE,,45.8826859815,2.64886685468,186,,Landogne,Landogne,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63187,LAPEYROUSE,63700,LAPEYROUSE,,46.2316265705,2.87835411182,187,,Lapeyrouse,Lapeyrouse,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63188,LAPS,63270,LAPS,,45.6712957114,3.26837509243,188,,Laps,Laps,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63189,LAQUEUILLE,63820,LAQUEUILLE,,45.6509383388,2.74601941375,189,,Laqueuille,Laqueuille,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63190,LARODDE,63690,LARODDE,,45.5243871141,2.55410927855,190,,Larodde,Larodde,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63191,LASTIC,63760,LASTIC,,45.7004342968,2.57059072951,191,,Lastic,Lastic,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63192,LA TOUR D AUVERGNE,63680,LA TOUR D AUVERGNE,,45.544240217,2.70757823914,192,La,Tour-d'Auvergne,La Tour-d'Auvergne,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63193,LEMPDES,63370,LEMPDES,,45.7724761738,3.19295725075,193,,Lempdes,Lempdes,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63194,LEMPTY,63190,LEMPTY,,45.8291540814,3.32957794666,194,,Lempty,Lempty,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63195,LEZOUX,63190,LEZOUX,,45.8287562383,3.38628504923,195,,Lezoux,Lezoux,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63196,LIMONS,63290,LIMONS,,45.9761150757,3.44572241084,196,,Limons,Limons,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63197,LISSEUIL,63440,LISSEUIL,,46.0569589114,2.93429771729,197,,Lisseuil,Lisseuil,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63198,LOUBEYRAT,63410,LOUBEYRAT,,45.9367572324,3.00677386083,198,,Loubeyrat,Loubeyrat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63199,LUDESSE,63320,LUDESSE,,45.6156818862,3.09887261026,199,,Ludesse,Ludesse,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63200,LUSSAT,63360,LUSSAT,,45.8379405832,3.22624771125,200,,Lussat,Lussat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63201,LUZILLAT,63350,LUZILLAT,,45.9588040696,3.38536946431,201,,Luzillat,Luzillat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63202,MADRIAT,63340,MADRIAT,,45.4353691789,3.175781685,202,,Madriat,Madriat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63203,MALAUZAT,63200,MALAUZAT,,45.8602209203,3.06104915998,203,,Malauzat,Malauzat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63204,MALINTRAT,63510,MALINTRAT,,45.8108783699,3.19119989954,204,,Malintrat,Malintrat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63205,MANGLIEU,63270,MANGLIEU,,45.6117755124,3.35655394687,205,,Manglieu,Manglieu,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63206,MANZAT,63410,MANZAT,,45.944894562,2.94042540915,206,,Manzat,Manzat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63207,MARAT,63480,MARAT,,45.6728912976,3.68812548894,207,,Marat,Marat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63208,MARCILLAT,63440,MARCILLAT,,46.0775559364,3.03384594494,208,,Marcillat,Marcillat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63209,MAREUGHEOL,63340,MAREUGHEOL,,45.4840734656,3.16169878471,209,,Mareugheol,Mareugheol,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63210,MARINGUES,63350,MARINGUES,,45.9209843347,3.33844645964,210,,Maringues,Maringues,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63211,MARSAC EN LIVRADOIS,63940,MARSAC EN LIVRADOIS,,45.4804409768,3.72202018487,211,,Marsac-en-Livradois,Marsac-en-Livradois,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63212,MARSAT,63200,MARSAT,,45.872900243,3.08321989305,212,,Marsat,Marsat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63213,LES MARTRES D ARTIERE,63430,LES MARTRES D ARTIERE,,45.8298275148,3.26055281188,213,Les,Martres-d'Artière,Les Martres-d'Artière,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63214,LES MARTRES DE VEYRE,63730,LES MARTRES DE VEYRE,,45.6864206017,3.19978584082,214,Les,Martres-de-Veyre,Les Martres-de-Veyre,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63215,MARTRES SUR MORGE,63720,MARTRES SUR MORGE,,45.9367934098,3.21935374869,215,,Martres-sur-Morge,Martres-sur-Morge,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63216,MAUZUN,63160,MAUZUN,,45.7042286945,3.42616955931,216,,Mauzun,Mauzun,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63218,MAYRES,63220,MAYRES,,45.3868702112,3.69971432703,218,,Mayres,Mayres,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63219,MAZAYE,63230,MAZAYE,,45.7848850923,2.87412730178,219,,Mazaye,Mazaye,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63220,MAZOIRES,63420,MAZOIRES,,45.3952258023,3.04773640705,220,,Mazoires,Mazoires,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63221,MEDEYROLLES,63220,MEDEYROLLES,,45.407858055,3.81792966619,221,,Medeyrolles,Medeyrolles,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63222,MEILHAUD,63320,MEILHAUD,,45.5429995885,3.16590025212,222,,Meilhaud,Meilhaud,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63223,MENAT,63560,MENAT,,46.1110382839,2.89522075999,223,,Menat,Menat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63224,MENETROL,63200,MENETROL,,45.8614463644,3.13296365852,224,,Ménétrol,Ménétrol,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63225,MESSEIX,63750,MESSEIX,,45.6122699559,2.533971784,225,,Messeix,Messeix,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63225,MESSEIX,63750,MESSEIX,BOGROS,45.6122699559,2.533971784,225,,Messeix,Messeix,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63225,MESSEIX,63750,MESSEIX,LES GANNES,45.6122699559,2.533971784,225,,Messeix,Messeix,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63226,MEZEL,63115,MEZEL,,45.7490361428,3.24075330378,226,,Mezel,Mezel,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63227,MIREFLEURS,63730,MIREFLEURS,,45.6909304728,3.22523866728,227,,Mirefleurs,Mirefleurs,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63228,MIREMONT,63380,MIREMONT,,45.9076476666,2.71763320469,228,,Miremont,Miremont,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63229,MOISSAT,63190,MOISSAT,,45.7783462842,3.35256262892,229,,Moissat,Moissat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63230,LE MONESTIER,63890,LE MONESTIER,,45.5577541247,3.65211264367,230,Le,Monestier,Le Monestier,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63231,LA MONNERIE LE MONTEL,63650,LA MONNERIE LE MONTEL,,45.8732657663,3.6116173358,231,La,Monnerie-le-Montel,La Monnerie-le-Montel,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63232,MONS,63310,MONS,,46.0004480585,3.42241354762,232,,Mons,Mons,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63233,MONTAIGUT,63700,MONTAIGUT EN COMBRAILLE,,46.1800902439,2.80149714867,233,,Montaigut,Montaigut,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63234,MONTAIGUT LE BLANC,63320,MONTAIGUT LE BLANC,,45.5863850519,3.07925191624,234,,Montaigut-le-Blanc,Montaigut-le-Blanc,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63235,MONTCEL,63460,MONTCEL,,46.0145706418,3.06413214387,235,,Montcel,Montcel,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63236,MONT DORE,63240,LE MONT DORE,,45.5760999131,2.80995918175,236,,Mont-Dore,Mont-Dore,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63237,MONTEL DE GELAT,63380,MONTEL DE GELAT,,45.9390596742,2.58561989591,237,,Montel-de-Gelat,Montel-de-Gelat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63238,MONTFERMY,63230,MONTFERMY,,45.8710003985,2.79757348907,238,,Montfermy,Montfermy,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63239,MONTMORIN,63160,MONTMORIN,,45.6915806177,3.35819148283,239,,Montmorin,Montmorin,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63240,MONTPENSIER,63260,MONTPENSIER,,46.0378088494,3.22643917176,240,,Montpensier,Montpensier,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63241,MONTPEYROUX,63114,MONTPEYROUX,,45.6253241922,3.20376168016,241,,Montpeyroux,Montpeyroux,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63242,MORIAT,63340,MORIAT,,45.4048477206,3.25943853538,242,,Moriat,Moriat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63243,MOUREUILLE,63700,MOUREUILLE,,46.1626033559,2.89026644721,243,,Moureuille,Moureuille,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63244,CHAMBARON SUR MORGE,63200,CHAMBARON SUR MORGE,CELLULE,45.9678093732,3.15096276782,244,,Chambaron sur Morge,Chambaron sur Morge,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63244,CHAMBARON SUR MORGE,63200,CHAMBARON SUR MORGE,LA MOUTADE,45.9678093732,3.15096276782,244,,Chambaron sur Morge,Chambaron sur Morge,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63245,MOZAC,63200,MOZAC,,45.8922783904,3.08484211748,245,,Mozac,Mozac,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63246,MURAT LE QUAIRE,63150,MURAT LE QUAIRE,,45.6038610372,2.75900504923,246,,Murat-le-Quaire,Murat-le-Quaire,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63247,MUROL,63790,MUROL,,45.5865050744,2.92277959567,247,,Murol,Murol,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63248,NEBOUZAT,63210,NEBOUZAT,,45.7185110259,2.91891004508,248,,Nébouzat,Nébouzat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63249,NERONDE SUR DORE,63120,NERONDE SUR DORE,,45.7989836343,3.5070472328,249,,Néronde-sur-Dore,Néronde-sur-Dore,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63250,NESCHERS,63320,NESCHERS,,45.5918097021,3.16530927442,250,,Neschers,Neschers,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63251,NEUF EGLISE,63560,NEUF EGLISE,,46.0981305257,2.86846423252,251,,Neuf-Église,Neuf-Église,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63252,NEUVILLE,63160,NEUVILLE,,45.75084815,3.44292731359,252,,Neuville,Neuville,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63253,NOALHAT,63290,NOALHAT,,45.918505761,3.46291211176,253,,Noalhat,Noalhat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63254,NOHANENT,63830,NOHANENT,,45.8109222583,3.05794637762,254,,Nohanent,Nohanent,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63255,NONETTE ORSONNETTE,63340,NONETTE ORSONNETTE,,45.4846982805,3.28374300124,255,,Nonette-Orsonnette,Nonette-Orsonnette,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63255,NONETTE ORSONNETTE,63340,NONETTE ORSONNETTE,ORSONNETTE,45.4846982805,3.28374300124,255,,Nonette-Orsonnette,Nonette-Orsonnette,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63256,NOVACELLES,63220,NOVACELLES,,45.4290595333,3.65737870098,256,,Novacelles,Novacelles,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63257,OLBY,63210,OLBY,,45.745518501,2.87011507295,257,,Olby,Olby,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63258,OLLIERGUES,63880,OLLIERGUES,,45.6904370225,3.63491981442,258,,Olliergues,Olliergues,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63259,OLLOIX,63450,OLLOIX,,45.6226524872,3.05077529203,259,,Olloix,Olloix,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63260,OLMET,63880,OLMET,,45.7163915803,3.66395528311,260,,Olmet,Olmet,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63261,ORBEIL,63500,ORBEIL,,45.5711903486,3.26877850823,261,,Orbeil,Orbeil,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63262,ORCET,63670,ORCET,,45.7054352684,3.1740367528,262,,Orcet,Orcet,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63263,ORCINES,63870,ORCINES,,45.7867473066,2.99703586023,263,,Orcines,Orcines,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63264,ORCIVAL,63210,ORCIVAL,,45.6564020975,2.83878096942,264,,Orcival,Orcival,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63265,ORLEAT,63190,ORLEAT,,45.8596417494,3.43869853549,265,,Orléat,Orléat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63267,PALLADUC,63550,PALLADUC,,45.908225301,3.64219943697,267,,Palladuc,Palladuc,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63268,PARDINES,63500,PARDINES,,45.5620015974,3.182451166,268,,Pardines,Pardines,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63269,PARENT,63270,PARENT,,45.6233589577,3.22638862151,269,,Parent,Parent,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63270,PARENTIGNAT,63500,PARENTIGNAT,,45.5313091189,3.28696019987,270,,Parentignat,Parentignat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63271,PASLIERES,63290,PASLIERES,,45.9233858396,3.5081870466,271,,Paslières,Paslières,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63272,PERIGNAT LES SARLIEVE,63170,PERIGNAT LES SARLIEVE,,45.7334187796,3.14276506265,272,,Pérignat-lès-Sarliève,Pérignat-lès-Sarliève,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63273,PERIGNAT SUR ALLIER,63800,PERIGNAT SUR ALLIER,,45.7272486864,3.23450898188,273,,Pérignat-sur-Allier,Pérignat-sur-Allier,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63274,PERPEZAT,63210,PERPEZAT,,45.6721335766,2.77634650959,274,,Perpezat,Perpezat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63275,PERRIER,63500,PERRIER,,45.5452957796,3.19913132431,275,,Perrier,Perrier,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63276,PESCHADOIRES,63920,PESCHADOIRES,,45.8269406268,3.4811849091,276,,Peschadoires,Peschadoires,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63276,PESCHADOIRES,63920,PESCHADOIRES,PONT DE DORE,45.8269406268,3.4811849091,276,,Peschadoires,Peschadoires,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63277,PESLIERES,63580,PESLIERES,,45.4378027341,3.47346758756,277,,Peslières,Peslières,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63278,PESSAT VILLENEUVE,63200,PESSAT VILLENEUVE,,45.9230389016,3.15822094053,278,,Pessat-Villeneuve,Pessat-Villeneuve,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63279,PICHERANDE,63113,PICHERANDE,,45.4694847916,2.79202815154,279,,Picherande,Picherande,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63280,PIGNOLS,63270,PIGNOLS,,45.6495248205,3.28545392206,280,,Pignols,Pignols,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63281,PIONSAT,63330,PIONSAT,,46.1199954597,2.68534957248,281,,Pionsat,Pionsat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63282,PLAUZAT,63730,PLAUZAT,,45.6197285651,3.14814026549,282,,Plauzat,Plauzat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63283,PONTAUMUR,63380,PONTAUMUR,,45.8601283416,2.68239147553,283,,Pontaumur,Pontaumur,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63284,PONT DU CHATEAU,63430,PONT DU CHATEAU,,45.7984084969,3.23957738391,284,,Pont-du-Château,Pont-du-Château,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63285,PONTGIBAUD,63230,PONTGIBAUD,,45.8268408954,2.85360398183,285,,Pontgibaud,Pontgibaud,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63286,POUZOL,63440,POUZOL,,46.0943310427,2.97381798823,286,,Pouzol,Pouzol,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63287,LES PRADEAUX,63500,LES PRADEAUX,,45.5132697587,3.29159884788,287,Les,Pradeaux,Les Pradeaux,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63288,PROMPSAT,63200,PROMPSAT,,45.9450364846,3.07223860162,288,,Prompsat,Prompsat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63289,PRONDINES,63470,PRONDINES,,45.7591745171,2.68428069953,289,,Prondines,Prondines,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63290,PULVERIERES,63230,PULVERIERES,,45.8821936223,2.92228975912,290,,Pulvérières,Pulvérières,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63291,PUY GUILLAUME,63290,PUY GUILLAUME,,45.9610998338,3.49732173113,291,,Puy-Guillaume,Puy-Guillaume,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63292,PUY ST GULMIER,63470,PUY ST GULMIER,,45.7949000316,2.62744439303,292,,Puy-Saint-Gulmier,Puy-Saint-Gulmier,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63293,LE QUARTIER,63330,LE QUARTIER,,46.1220166679,2.76068293896,293,Le,Quartier,Le Quartier,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63294,QUEUILLE,63780,QUEUILLE,,45.9728708366,2.83726186484,294,,Queuille,Queuille,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63295,RANDAN,63310,RANDAN,,46.0116125087,3.36364426205,295,,Randan,Randan,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63296,RAVEL,63190,RAVEL,,45.7870275365,3.39484221864,296,,Ravel,Ravel,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63297,REIGNAT,63160,REIGNAT,,45.7487299142,3.35567413071,297,,Reignat,Reignat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63298,LA RENAUDIE,63930,LA RENAUDIE,,45.7414767056,3.70950857493,298,La,Renaudie,La Renaudie,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63299,RENTIERES,63420,RENTIERES,,45.4251236978,3.11962739468,299,,Rentières,Rentières,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63300,RIOM,63200,RIOM,,45.8942667778,3.13246469719,300,,Riom,Riom,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63301,RIS,63290,RIS,,46.0038743026,3.51270074491,301,,Ris,Ris,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63302,LA ROCHE BLANCHE,63670,LA ROCHE BLANCHE,,45.7137644349,3.13804954458,302,La,Roche-Blanche,La Roche-Blanche,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63303,ROCHE CHARLES LA MAYRAND,63420,ROCHE CHARLES LA MAYRAND,,45.447450625,3.02047016292,303,,Roche-Charles-la-Mayrand,Roche-Charles-la-Mayrand,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63303,ROCHE CHARLES LA MAYRAND,63420,ROCHE CHARLES LA MAYRAND,LA MAYRAND,45.447450625,3.02047016292,303,,Roche-Charles-la-Mayrand,Roche-Charles-la-Mayrand,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63304,ROCHE D AGOUX,63330,ROCHE D AGOUX,,46.0409492985,2.64213874431,304,,Roche-d'Agoux,Roche-d'Agoux,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63305,ROCHEFORT MONTAGNE,63210,ROCHEFORT MONTAGNE,,45.6822657676,2.80889976555,305,,Rochefort-Montagne,Rochefort-Montagne,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63306,LA ROCHE NOIRE,63800,LA ROCHE NOIRE,,45.7136067003,3.22320975881,306,La,Roche-Noire,La Roche-Noire,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63307,ROMAGNAT,63540,ROMAGNAT,,45.7210583634,3.08824671856,307,,Romagnat,Romagnat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63307,ROMAGNAT,63540,ROMAGNAT,OPME,45.7210583634,3.08824671856,307,,Romagnat,Romagnat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63307,ROMAGNAT,63540,ROMAGNAT,SAULZET LE CHAUD,45.7210583634,3.08824671856,307,,Romagnat,Romagnat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63308,ROYAT,63130,ROYAT,,45.7578437859,3.04076552996,308,,Royat,Royat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63309,SAILLANT,63840,SAILLANT,,45.4486696779,3.92581266951,309,,Saillant,Saillant,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63310,STE AGATHE,63120,STE AGATHE,,45.8212374629,3.62274798435,310,,Sainte-Agathe,Sainte-Agathe,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63311,ST AGOULIN,63260,ST AGOULIN,,46.0402856832,3.1292028282,311,,Saint-Agoulin,Saint-Agoulin,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63312,ST ALYRE D ARLANC,63220,ST ALYRE D ARLANC,,45.3674865412,3.62684726981,312,,Saint-Alyre-d'Arlanc,Saint-Alyre-d'Arlanc,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63313,ST ALYRE ES MONTAGNE,63420,ST ALYRE ES MONTAGNE,,45.3796154166,2.97231530365,313,,Saint-Alyre-ès-Montagne,Saint-Alyre-ès-Montagne,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63314,ST AMANT ROCHE SAVINE,63890,ST AMANT ROCHE SAVINE,,45.5783076578,3.60730566057,314,,Saint-Amant-Roche-Savine,Saint-Amant-Roche-Savine,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63315,ST AMANT TALLENDE,63450,ST AMANT TALLENDE,,45.6673371467,3.10961194917,315,,Saint-Amant-Tallende,Saint-Amant-Tallende,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63317,ST ANDRE LE COQ,63310,ST ANDRE LE COQ,,45.9579209834,3.30759631356,317,,Saint-André-le-Coq,Saint-André-le-Coq,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63318,ST ANGEL,63410,ST ANGEL,,46.0018691299,2.93956309171,318,,Saint-Angel,Saint-Angel,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63319,ST ANTHEME,63660,ST ANTHEME,,45.5557096264,3.89624958452,319,,Saint-Anthème,Saint-Anthème,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63320,ST AVIT,63380,ST AVIT,,45.8758919248,2.52568725113,320,,Saint-Avit,Saint-Avit,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63321,ST BABEL,63500,ST BABEL,,45.6026972387,3.30047875727,321,,Saint-Babel,Saint-Babel,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63322,ST BEAUZIRE,63360,ST BEAUZIRE,,45.8545444146,3.184257017,322,,Saint-Beauzire,Saint-Beauzire,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63323,ST BONNET LE BOURG,63630,ST BONNET LE BOURG,,45.4368658908,3.59632655596,323,,Saint-Bonnet-le-Bourg,Saint-Bonnet-le-Bourg,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63324,ST BONNET LE CHASTEL,63630,ST BONNET LE CHASTEL,,45.4613295123,3.6383745969,324,,Saint-Bonnet-le-Chastel,Saint-Bonnet-le-Chastel,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63325,ST BONNET LES ALLIER,63800,ST BONNET LES ALLIER,,45.7393749504,3.25532353986,325,,Saint-Bonnet-lès-Allier,Saint-Bonnet-lès-Allier,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63326,ST BONNET PRES ORCIVAL,63210,ST BONNET PRES ORCIVAL,,45.7078488032,2.86424850869,326,,Saint-Bonnet-près-Orcival,Saint-Bonnet-près-Orcival,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63327,ST BONNET PRES RIOM,63200,ST BONNET PRES RIOM,,45.9285193382,3.11628669554,327,,Saint-Bonnet-près-Riom,Saint-Bonnet-près-Riom,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63328,STE CATHERINE,63580,STE CATHERINE,,45.4508139616,3.47808095492,328,,Sainte-Catherine,Sainte-Catherine,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63329,STE CHRISTINE,63390,STE CHRISTINE,,46.0723087961,2.83140486418,329,,Sainte-Christine,Sainte-Christine,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63330,ST CIRGUES SUR COUZE,63320,ST CIRGUES SUR COUZE,,45.5459770641,3.14419958349,330,,Saint-Cirgues-sur-Couze,Saint-Cirgues-sur-Couze,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63331,ST CLEMENT DE VALORGUE,63660,ST CLEMENT DE VALORGUE,,45.5006965349,3.93607974506,331,,Saint-Clément-de-Valorgue,Saint-Clément-de-Valorgue,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63332,ST CLEMENT DE REGNAT,63310,ST CLEMENT DE REGNAT,,45.9980168992,3.29522971,332,,Saint-Clément-de-Régnat,Saint-Clément-de-Régnat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63333,ST DENIS COMBARNAZAT,63310,ST DENIS COMBARNAZAT,,45.9758266274,3.34809938141,333,,Saint-Denis-Combarnazat,Saint-Denis-Combarnazat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63334,ST DIER D AUVERGNE,63520,ST DIER D AUVERGNE,,45.6701289344,3.48253379649,334,,Saint-Dier-d'Auvergne,Saint-Dier-d'Auvergne,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63335,ST DIERY,63320,ST DIERY,,45.5401220112,3.00891497802,335,,Saint-Diéry,Saint-Diéry,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63336,ST DONAT,63680,ST DONAT,,45.4628524127,2.70421193548,336,,Saint-Donat,Saint-Donat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63337,ST ELOY LA GLACIERE,63890,ST ELOY LA GLACIERE,,45.5636981637,3.56724864306,337,,Saint-Éloy-la-Glacière,Saint-Éloy-la-Glacière,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63338,ST ELOY LES MINES,63700,ST ELOY LES MINES,,46.1638179584,2.84278214683,338,,Saint-Éloy-les-Mines,Saint-Éloy-les-Mines,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63339,ST ETIENNE DES CHAMPS,63380,ST ETIENNE DES CHAMPS,,45.8166087877,2.57954692815,339,,Saint-Étienne-des-Champs,Saint-Étienne-des-Champs,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63340,ST ETIENNE SUR USSON,63580,ST ETIENNE SUR USSON,,45.5076408755,3.40099762607,340,,Saint-Étienne-sur-Usson,Saint-Étienne-sur-Usson,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63341,ST FERREOL DES COTES,63600,ST FERREOL DES COTES,,45.536074649,3.69830266756,341,,Saint-Ferréol-des-Côtes,Saint-Ferréol-des-Côtes,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63342,ST FLORET,63320,ST FLORET,,45.5393613066,3.0936807019,342,,Saint-Floret,Saint-Floret,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63343,ST FLOUR,63520,ST FLOUR,,45.7110854172,3.51007845153,343,,Saint-Flour,Saint-Flour,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63344,ST GAL SUR SIOULE,63440,ST GAL SUR SIOULE,,46.1079395641,2.99416305431,344,,Saint-Gal-sur-Sioule,Saint-Gal-sur-Sioule,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63345,ST GENES CHAMPANELLE,63122,ST GENES CHAMPANELLE,,45.722927873,2.99890145376,345,,Saint-Genès-Champanelle,Saint-Genès-Champanelle,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63345,ST GENES CHAMPANELLE,63122,ST GENES CHAMPANELLE,BERZET,45.722927873,2.99890145376,345,,Saint-Genès-Champanelle,Saint-Genès-Champanelle,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63345,ST GENES CHAMPANELLE,63122,ST GENES CHAMPANELLE,FONT FREYDE,45.722927873,2.99890145376,345,,Saint-Genès-Champanelle,Saint-Genès-Champanelle,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63345,ST GENES CHAMPANELLE,63122,ST GENES CHAMPANELLE,MANSON,45.722927873,2.99890145376,345,,Saint-Genès-Champanelle,Saint-Genès-Champanelle,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63345,ST GENES CHAMPANELLE,63122,ST GENES CHAMPANELLE,THEIX,45.722927873,2.99890145376,345,,Saint-Genès-Champanelle,Saint-Genès-Champanelle,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63346,ST GENES CHAMPESPE,63850,ST GENES CHAMPESPE,,45.4155027111,2.72512948108,346,,Saint-Genès-Champespe,Saint-Genès-Champespe,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63347,ST GENES DU RETZ,63260,ST GENES DU RETZ,,46.0629678805,3.2121024758,347,,Saint-Genès-du-Retz,Saint-Genès-du-Retz,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63348,ST GENES LA TOURETTE,63580,ST GENES LA TOURETTE,,45.5179309213,3.48092703199,348,,Saint-Genès-la-Tourette,Saint-Genès-la-Tourette,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63349,ST GEORGES DE MONS,63780,ST GEORGES DE MONS,,45.9425789981,2.86525806155,349,,Saint-Georges-de-Mons,Saint-Georges-de-Mons,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63350,ST GEORGES SUR ALLIER,63800,ST GEORGES SUR ALLIER,,45.7146185494,3.26081077344,350,,Saint-Georges-sur-Allier,Saint-Georges-sur-Allier,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63351,ST GERMAIN PRES HERMENT,63470,ST GERMAIN PRES HERMENT,,45.7236097881,2.5533478726,351,,Saint-Germain-près-Herment,Saint-Germain-près-Herment,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63352,ST GERMAIN LEMBRON,63340,ST GERMAIN LEMBRON,,45.4533133864,3.24296692377,352,,Saint-Germain-Lembron,Saint-Germain-Lembron,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63353,ST GERMAIN L HERM,63630,ST GERMAIN L HERM,,45.4628350355,3.54468862687,353,,Saint-Germain-l'Herm,Saint-Germain-l'Herm,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63354,ST GERVAIS D AUVERGNE,63390,ST GERVAIS D AUVERGNE,,46.0189715375,2.83089717473,354,,Saint-Gervais-d'Auvergne,Saint-Gervais-d'Auvergne,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63355,ST GERVAIS SOUS MEYMONT,63880,ST GERVAIS SOUS MEYMONT,,45.6698849896,3.61279256951,355,,Saint-Gervais-sous-Meymont,Saint-Gervais-sous-Meymont,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63356,ST GERVAZY,63340,ST GERVAZY,,45.4012733362,3.21294721972,356,,Saint-Gervazy,Saint-Gervazy,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63357,ST HERENT,63340,ST HERENT,,45.4603240811,3.14016672935,357,,Saint-Hérent,Saint-Hérent,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63358,ST HILAIRE LA CROIX,63440,ST HILAIRE LA CROIX,,46.0395388751,3.04481120336,358,,Saint-Hilaire-la-Croix,Saint-Hilaire-la-Croix,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63359,ST HILAIRE LES MONGES,63380,ST HILAIRE LES MONGES,,45.8079835138,2.66518845246,359,,Saint-Hilaire-les-Monges,Saint-Hilaire-les-Monges,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63360,ST HILAIRE,63330,ST HILAIRE PRES PIONSAT,,46.1055031441,2.61988914983,360,,Saint-Hilaire,Saint-Hilaire,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63362,ST IGNAT,63720,ST IGNAT,,45.9198911724,3.273318515,362,,Saint-Ignat,Saint-Ignat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63363,ST JACQUES D AMBUR,63230,ST JACQUES D AMBUR,,45.8966831602,2.76646671593,363,,Saint-Jacques-d'Ambur,Saint-Jacques-d'Ambur,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63364,ST JEAN D HEURS,63190,ST JEAN D HEURS,,45.8149832501,3.44648739262,364,,Saint-Jean-d'Heurs,Saint-Jean-d'Heurs,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63365,ST JEAN DES OLLIERES,63520,ST JEAN DES OLLIERES,,45.6417405578,3.43719352048,365,,Saint-Jean-des-Ollières,Saint-Jean-des-Ollières,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63366,ST JEAN EN VAL,63490,ST JEAN EN VAL,,45.512805827,3.36496437735,366,,Saint-Jean-en-Val,Saint-Jean-en-Val,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63367,ST JEAN ST GERVAIS,63570,ST JEAN ST GERVAIS,,45.4215952823,3.38756418048,367,,Saint-Jean-Saint-Gervais,Saint-Jean-Saint-Gervais,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63368,ST JULIEN DE COPPEL,63160,ST JULIEN DE COPPEL,,45.6901407838,3.31080229183,368,,Saint-Julien-de-Coppel,Saint-Julien-de-Coppel,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63369,ST JULIEN LA GENESTE,63390,ST JULIEN LA GENESTE,,46.0492070067,2.7408717504,369,,Saint-Julien-la-Geneste,Saint-Julien-la-Geneste,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63370,ST JULIEN PUY LAVEZE,63820,ST JULIEN PUY LAVEZE,,45.6620496707,2.67963503526,370,,Saint-Julien-Puy-Lavèze,Saint-Julien-Puy-Lavèze,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63371,ST JUST,63600,ST JUST,,45.4584826405,3.80945921845,371,,Saint-Just,Saint-Just,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63372,ST LAURE,63350,ST LAURE,,45.8981408483,3.29305031526,372,,Saint-Laure,Saint-Laure,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63373,ST MAIGNER,63330,ST MAIGNER,,46.0784044812,2.68604538053,373,,Saint-Maigner,Saint-Maigner,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63374,ST MARTIN DES OLMES,63600,ST MARTIN DES OLMES,,45.5270156748,3.80419164583,374,,Saint-Martin-des-Olmes,Saint-Martin-des-Olmes,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63375,ST MARTIN DES PLAINS,63570,ST MARTIN DES PLAINS,,45.4950315603,3.311046762,375,,Saint-Martin-des-Plains,Saint-Martin-des-Plains,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63376,ST MARTIN D OLLIERES,63580,ST MARTIN D OLLIERES,,45.4170695019,3.44908379911,376,,Saint-Martin-d'Ollières,Saint-Martin-d'Ollières,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63377,ST MAURICE PRES PIONSAT,63330,ST MAURICE PRES PIONSAT,,46.0687545413,2.59304544754,377,,Saint-Maurice-près-Pionsat,Saint-Maurice-près-Pionsat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63378,ST MAURICE,63270,ST MAURICE,,45.6705565807,3.23800296875,378,,Saint-Maurice,Saint-Maurice,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63379,ST MYON,63460,ST MYON,,45.9871537427,3.12955448011,379,,Saint-Myon,Saint-Myon,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63380,ST NECTAIRE,63710,ST NECTAIRE,,45.5919139674,2.99779544156,380,,Saint-Nectaire,Saint-Nectaire,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63381,ST OURS,63230,ST OURS,,45.8391495604,2.91545146686,381,,Saint-Ours,Saint-Ours,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63382,ST PARDOUX,63440,ST PARDOUX,,46.0543225356,3.00502990654,382,,Saint-Pardoux,Saint-Pardoux,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63383,ST PIERRE COLAMINE,63610,ST PIERRE COLAMINE,,45.5143028753,2.97474204258,383,,Saint-Pierre-Colamine,Saint-Pierre-Colamine,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63384,ST PIERRE LA BOURLHONNE,63480,ST PIERRE LA BOURLHONNE,,45.6770583346,3.75497395692,384,,Saint-Pierre-la-Bourlhonne,Saint-Pierre-la-Bourlhonne,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63385,ST PIERRE LE CHASTEL,63230,ST PIERRE LE CHASTEL,,45.7937961523,2.83603887701,385,,Saint-Pierre-le-Chastel,Saint-Pierre-le-Chastel,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63386,ST PIERRE ROCHE,63210,ST PIERRE ROCHE,,45.7293103902,2.814651997,386,,Saint-Pierre-Roche,Saint-Pierre-Roche,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63387,ST PRIEST BRAMEFANT,63310,ST PRIEST BRAMEFANT,,46.0285804944,3.44009346182,387,,Saint-Priest-Bramefant,Saint-Priest-Bramefant,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63388,ST PRIEST DES CHAMPS,63640,ST PRIEST DES CHAMPS,,45.9720196173,2.74903294202,388,,Saint-Priest-des-Champs,Saint-Priest-des-Champs,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63389,ST QUENTIN SUR SAUXILLANGES,63490,ST QUENTIN SUR SAUXILLANGES,,45.5452461177,3.41588566339,389,,Saint-Quentin-sur-Sauxillanges,Saint-Quentin-sur-Sauxillanges,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63390,ST QUINTIN SUR SIOULE,63440,ST QUINTIN SUR SIOULE,,46.0925408247,3.06946747154,390,,Saint-Quintin-sur-Sioule,Saint-Quintin-sur-Sioule,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63391,ST REMY DE BLOT,63440,ST REMY DE BLOT,,46.0773790361,2.9483504199,391,,Saint-Rémy-de-Blot,Saint-Rémy-de-Blot,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63392,ST REMY DE CHARGNAT,63500,ST REMY DE CHARGNAT,,45.5095851539,3.32523243931,392,,Saint-Rémy-de-Chargnat,Saint-Rémy-de-Chargnat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63393,ST REMY SUR DUROLLE,63550,ST REMY SUR DUROLLE,,45.8957828526,3.58458656193,393,,Saint-Rémy-sur-Durolle,Saint-Rémy-sur-Durolle,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63394,ST ROMAIN,63660,ST ROMAIN,,45.4923783806,3.89615838601,394,,Saint-Romain,Saint-Romain,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63395,ST SANDOUX,63450,ST SANDOUX,,45.6383512241,3.1083290687,395,,Saint-Sandoux,Saint-Sandoux,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63396,ST SATURNIN,63450,ST SATURNIN,,45.66385711,3.07392244735,396,,Saint-Saturnin,Saint-Saturnin,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63397,ST SAUVES D AUVERGNE,63950,ST SAUVES D AUVERGNE,,45.6115653546,2.69301587098,397,,Saint-Sauves-d'Auvergne,Saint-Sauves-d'Auvergne,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63398,ST SAUVEUR LA SAGNE,63220,ST SAUVEUR LA SAGNE,,45.3949784675,3.66352383653,398,,Saint-Sauveur-la-Sagne,Saint-Sauveur-la-Sagne,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63399,ST SULPICE,63760,ST SULPICE,,45.6470287269,2.6197535104,399,,Saint-Sulpice,Saint-Sulpice,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63400,ST SYLVESTRE PRAGOULIN,63310,ST SYLVESTRE PRAGOULIN,,46.0489526337,3.39583970589,400,,Saint-Sylvestre-Pragoulin,Saint-Sylvestre-Pragoulin,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63401,ST VICTOR LA RIVIERE,63790,ST VICTOR LA RIVIERE,,45.5489108825,2.93527528124,401,,Saint-Victor-la-Rivière,Saint-Victor-la-Rivière,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63402,ST VICTOR MONTVIANEIX,63550,ST VICTOR MONTVIANEIX,,45.9381159052,3.61132640921,402,,Saint-Victor-Montvianeix,Saint-Victor-Montvianeix,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63403,ST VINCENT,63320,ST VINCENT,,45.5472176594,3.12590149636,403,,Saint-Vincent,Saint-Vincent,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63404,ST YVOINE,63500,ST YVOINE,,45.5750111656,3.22572717039,404,,Saint-Yvoine,Saint-Yvoine,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63405,SALLEDES,63270,SALLEDES,,45.6462597262,3.32585901323,405,,Sallèdes,Sallèdes,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63406,SARDON,63260,SARDON,,45.9633782182,3.22255151665,406,,Sardon,Sardon,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63407,SAULZET LE FROID,63970,SAULZET LE FROID,,45.6309455318,2.89220399205,407,,Saulzet-le-Froid,Saulzet-le-Froid,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63408,SAURET BESSERVE,63390,SAURET BESSERVE,,45.9894794334,2.80323189341,408,,Sauret-Besserve,Sauret-Besserve,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63409,SAURIER,63320,SAURIER,,45.5357694302,3.05522089662,409,,Saurier,Saurier,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63410,SAUVAGNAT,63470,SAUVAGNAT,,45.7557655132,2.60931861408,410,,Sauvagnat,Sauvagnat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63411,SAUVAGNAT STE MARTHE,63500,SAUVAGNAT STE MARTHE,,45.5897061355,3.20853805822,411,,Sauvagnat-Sainte-Marthe,Sauvagnat-Sainte-Marthe,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63412,SAUVESSANGES,63840,SAUVESSANGES,,45.3925967575,3.86535045633,412,,Sauvessanges,Sauvessanges,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63413,LA SAUVETAT,63730,LA SAUVETAT,,45.6424514679,3.16483008201,413,La,Sauvetat,La Sauvetat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63414,SAUVIAT,63120,SAUVIAT,,45.7189041361,3.55697409571,414,,Sauviat,Sauviat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63415,SAUXILLANGES,63490,SAUXILLANGES,,45.562939202,3.37211320059,415,,Sauxillanges,Sauxillanges,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63416,SAVENNES,63750,SAVENNES,,45.5804099944,2.49690892919,416,,Savennes,Savennes,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63417,SAYAT,63530,SAYAT,,45.8388005016,3.03448182973,417,,Sayat,Sayat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63418,SERMENTIZON,63120,SERMENTIZON,,45.7640786806,3.48570267684,418,,Sermentizon,Sermentizon,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63419,SERVANT,63560,SERVANT,,46.1383512026,2.93216785544,419,,Servant,Servant,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63420,SEYCHALLES,63190,SEYCHALLES,,45.8064341619,3.33651456543,420,,Seychalles,Seychalles,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63421,SINGLES,63690,SINGLES,,45.5600798415,2.55206331215,421,,Singles,Singles,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63422,SOLIGNAT,63500,SOLIGNAT,,45.5234450562,3.18541001355,422,,Solignat,Solignat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63423,SUGERES,63490,SUGERES,,45.6003604212,3.41188316397,423,,Sugères,Sugères,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63424,SURAT,63720,SURAT,,45.9402039693,3.25933179692,424,,Surat,Surat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63425,TALLENDE,63450,TALLENDE,,45.661669969,3.13374681277,425,,Tallende,Tallende,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63426,TAUVES,63690,TAUVES,,45.5635422743,2.62732246631,426,,Tauves,Tauves,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63427,TEILHEDE,63460,TEILHEDE,,45.9614924136,3.05247762419,427,,Teilhède,Teilhède,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63428,TEILHET,63560,TEILHET,,46.1023710126,2.81638595115,428,,Teilhet,Teilhet,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63429,TERNANT LES EAUX,63340,TERNANT LES EAUX,,45.4812539874,3.12762758694,429,,Ternant-les-Eaux,Ternant-les-Eaux,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63430,THIERS,63300,THIERS,,45.8620554106,3.53945258847,430,,Thiers,Thiers,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63431,THIOLIERES,63600,THIOLIERES,,45.5821770217,3.69356744497,431,,Thiolières,Thiolières,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63432,THURET,63260,THURET,,45.9738532514,3.26184697421,432,,Thuret,Thuret,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63433,TORTEBESSE,63470,TORTEBESSE,,45.7273657826,2.65962165368,433,,Tortebesse,Tortebesse,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63434,TOURS SUR MEYMONT,63590,TOURS SUR MEYMONT,,45.6740219658,3.57621725614,434,,Tours-sur-Meymont,Tours-sur-Meymont,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63435,TOURZEL RONZIERES,63320,TOURZEL RONZIERES,,45.52076364,3.11820097979,435,,Tourzel-Ronzières,Tourzel-Ronzières,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63436,TRALAIGUES,63380,TRALAIGUES,,45.9013280837,2.59396620413,436,,Tralaigues,Tralaigues,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63437,TREMOUILLE ST LOUP,63810,TREMOUILLE ST LOUP,,45.486076065,2.56362799557,437,,Trémouille-Saint-Loup,Trémouille-Saint-Loup,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63438,TREZIOUX,63520,TREZIOUX,,45.7193601775,3.47259272973,438,,Trézioux,Trézioux,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63439,USSON,63490,USSON,,45.5273145167,3.3428220129,439,,Usson,Usson,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63440,VALBELEIX,63610,VALBELEIX,,45.4741100431,2.99840008974,440,,Valbeleix,Valbeleix,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63441,VALCIVIERES,63600,VALCIVIERES,,45.6015215325,3.82060542258,441,,Valcivières,Valcivières,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63442,VALZ SOUS CHATEAUNEUF,63580,VALZ SOUS CHATEAUNEUF,,45.4308765586,3.42163496764,442,,Valz-sous-Châteauneuf,Valz-sous-Châteauneuf,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63443,VARENNES SUR MORGE,63720,VARENNES SUR MORGE,,45.9398936436,3.18639040806,443,,Varennes-sur-Morge,Varennes-sur-Morge,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63444,VARENNES SUR USSON,63500,VARENNES SUR USSON,,45.535308248,3.32399311749,444,,Varennes-sur-Usson,Varennes-sur-Usson,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63445,VASSEL,63910,VASSEL,,45.7605313754,3.31232153494,445,,Vassel,Vassel,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63446,VENSAT,63260,VENSAT,,46.0542113877,3.166015215,446,,Vensat,Vensat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63447,VERGHEAS,63330,VERGHEAS,,46.0244493253,2.62233135761,447,,Vergheas,Vergheas,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63448,VERNET LA VARENNE,63580,VERNET LA VARENNE,,45.4784207253,3.45441467275,448,,Vernet-la-Varenne,Vernet-la-Varenne,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63449,LE VERNET STE MARGUERITE,63710,LE VERNET STE MARGUERITE,,45.6163801632,2.94006169987,449,Le,Vernet-Sainte-Marguerite,Le Vernet-Sainte-Marguerite,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63450,VERNEUGHEOL,63470,VERNEUGHEOL,,45.7554248044,2.52012470345,450,,Verneugheol,Verneugheol,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63451,VERNINES,63210,VERNINES,,45.6674200897,2.87779660364,451,,Vernines,Vernines,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63452,VERRIERES,63320,VERRIERES,,45.5681032742,3.03553806926,452,,Verrières,Verrières,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63453,VERTAIZON,63910,VERTAIZON,,45.7750797019,3.2843067169,453,,Vertaizon,Vertaizon,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63454,VERTOLAYE,63480,VERTOLAYE,,45.6538886313,3.73749020485,454,,Vertolaye,Vertolaye,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63455,VEYRE MONTON,63960,VEYRE MONTON,,45.6731732382,3.16405079473,455,,Veyre-Monton,Veyre-Monton,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63456,VICHEL,63340,VICHEL,,45.4240567135,3.24088300911,456,,Vichel,Vichel,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63457,VIC LE COMTE,63270,VIC LE COMTE,,45.6440673683,3.24047430326,457,,Vic-le-Comte,Vic-le-Comte,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63457,VIC LE COMTE,63270,VIC LE COMTE,LONGUES,45.6440673683,3.24047430326,457,,Vic-le-Comte,Vic-le-Comte,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63458,VILLENEUVE,63340,VILLENEUVE,,45.4775997565,3.18746036905,458,,Villeneuve,Villeneuve,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63459,VILLENEUVE LES CERFS,63310,VILLENEUVE LES CERFS,,46.0188653048,3.3291939424,459,,Villeneuve-les-Cerfs,Villeneuve-les-Cerfs,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63460,VILLOSANGES,63380,VILLOSANGES,,45.9190356938,2.64520886545,460,,Villosanges,Villosanges,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63461,VINZELLES,63350,VINZELLES,,45.9274973505,3.41564073946,461,,Vinzelles,Vinzelles,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63462,VIRLET,63330,VIRLET,,46.1545658295,2.70225561671,462,,Virlet,Virlet,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63463,VISCOMTAT,63250,VISCOMTAT,,45.8286818103,3.68998040023,463,,Viscomtat,Viscomtat,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63464,VITRAC,63410,VITRAC,,45.9786269052,2.89497191528,464,,Vitrac,Vitrac,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63465,VIVEROLS,63840,VIVEROLS,,45.4323678185,3.88146996654,465,,Viverols,Viverols,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63466,VODABLE,63500,VODABLE,,45.5041326781,3.13460645088,466,,Vodable,Vodable,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63467,VOINGT,63620,VOINGT,,45.8061372767,2.54054574233,467,,Voingt,Voingt,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63468,VOLLORE MONTAGNE,63120,VOLLORE MONTAGNE,,45.7827859194,3.68768043722,468,,Vollore-Montagne,Vollore-Montagne,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63469,VOLLORE VILLE,63120,VOLLORE VILLE,,45.7843683155,3.61088138658,469,,Vollore-Ville,Vollore-Ville,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63470,VOLVIC,63530,VOLVIC,,45.8681922975,3.01900139762,470,,Volvic,Volvic,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63471,YOUX,63700,YOUX,,46.1408722674,2.8044100996,471,,Youx,Youx,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63471,YOUX,63700,YOUX,MONTJOIE,46.1408722674,2.8044100996,471,,Youx,Youx,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63472,YRONDE ET BURON,63270,YRONDE ET BURON,,45.6099212666,3.25296189739,472,,Yronde-et-Buron,Yronde-et-Buron,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
63473,YSSAC LA TOURETTE,63200,YSSAC LA TOURETTE,,45.9344003582,3.09229073701,473,,Yssac-la-Tourette,Yssac-la-Tourette,63,Puy-de-Dôme,84,Auvergne-Rhône-Alpes
64001,AAST,64460,AAST,,43.2908772184,-0.0817726913902,1,,Aast,Aast,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64002,ABERE,64160,ABERE,,43.3870958576,-0.174833109291,2,,Abère,Abère,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64003,ABIDOS,64150,ABIDOS,,43.3951950748,-0.628336499241,3,,Abidos,Abidos,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64004,ABITAIN,64390,ABITAIN,,43.4173386214,-0.99809023231,4,,Abitain,Abitain,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64005,ABOS,64360,ABOS,,43.3538742135,-0.550692004112,5,,Abos,Abos,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64006,ACCOUS,64490,ACCOUS,,42.9398080836,-0.579066514702,6,,Accous,Accous,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64007,AGNOS,64400,AGNOS,,43.1539224601,-0.62932795894,7,,Agnos,Agnos,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64008,AHAXE ALCIETTE BASCASSAN,64220,AHAXE ALCIETTE BASCASSAN,,43.1439687884,-1.16379898273,8,,Ahaxe-Alciette-Bascassan,Ahaxe-Alciette-Bascassan,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64009,AHETZE,64210,AHETZE,,43.4042762685,-1.57067985884,9,,Ahetze,Ahetze,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64010,AICIRITS CAMOU SUHAST,64120,AICIRITS CAMOU SUHAST,,43.3536564293,-1.01649248098,10,,Aïcirits-Camou-Suhast,Aïcirits-Camou-Suhast,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64010,AICIRITS CAMOU SUHAST,64120,AICIRITS CAMOU SUHAST,CAMOU MIXE SUHAST,43.3536564293,-1.01649248098,10,,Aïcirits-Camou-Suhast,Aïcirits-Camou-Suhast,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64011,AINCILLE,64220,AINCILLE,,43.1339521918,-1.19765745268,11,,Aincille,Aincille,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64012,AINHARP,64130,AINHARP,,43.2547388679,-0.935719451958,12,,Ainharp,Ainharp,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64013,AINHICE MONGELOS,64220,AINHICE MONGELOS,,43.2143412216,-1.15953469932,13,,Ainhice-Mongelos,Ainhice-Mongelos,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64014,AINHOA,64250,AINHOA,,43.2973870708,-1.48514354363,14,,Ainhoa,Ainhoa,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64015,ALCAY ALCABEHETY SUNHARETTE,64470,ALCAY ALCABEHETY SUNHARETTE,,43.0865933129,-0.975830876097,15,,Alçay-Alçabéhéty-Sunharette,Alçay-Alçabéhéty-Sunharette,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64016,ALDUDES,64430,ALDUDES,,43.0920329311,-1.43472285971,16,,Aldudes,Aldudes,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64017,ALOS SIBAS ABENSE,64470,ALOS SIBAS ABENSE,,43.1129165102,-0.877966906276,17,,Alos-Sibas-Abense,Alos-Sibas-Abense,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64018,AMENDEUIX ONEIX,64120,AMENDEUIX ONEIX,,43.3445045858,-1.04369537456,18,,Amendeuix-Oneix,Amendeuix-Oneix,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64019,AMOROTS SUCCOS,64120,AMOROTS SUCCOS,,43.3625512592,-1.12435569273,19,,Amorots-Succos,Amorots-Succos,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64020,ANCE,64570,ANCE,,43.1445987386,-0.687615665018,20,,Ance,Ance,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64021,ANDOINS,64420,ANDOINS,,43.3003317348,-0.223135510504,21,,Andoins,Andoins,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64022,ANDREIN,64390,ANDREIN,,43.3997122836,-0.889281632405,22,,Andrein,Andrein,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64023,ANGAIS,64510,ANGAIS,,43.2387006623,-0.247297469008,23,,Angaïs,Angaïs,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64024,ANGLET,64600,ANGLET,,43.4917846595,-1.51623373371,24,,Anglet,Anglet,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64025,ANGOUS,64190,ANGOUS,,43.2928389811,-0.812536653193,25,,Angous,Angous,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64026,ANHAUX,64220,ANHAUX,,43.1459283383,-1.30104249702,26,,Anhaux,Anhaux,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64027,ANOS,64160,ANOS,,43.3974753128,-0.289222563999,27,,Anos,Anos,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64028,ANOYE,64350,ANOYE,,43.3965266852,-0.136280497719,28,,Anoye,Anoye,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64029,ARAMITS,64570,ARAMITS,,43.1318295065,-0.724473601106,29,,Aramits,Aramits,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64031,ARANCOU,64270,ARANCOU,,43.4438787221,-1.05280002539,31,,Arancou,Arancou,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64032,ARAUJUZON,64190,ARAUJUZON,,43.3506639149,-0.830144585337,32,,Araujuzon,Araujuzon,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64033,ARAUX,64190,ARAUX,,43.3409945704,-0.819420157964,33,,Araux,Araux,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64034,ARBERATS SILLEGUE,64120,ARBERATS SILLEGUE,,43.340809615,-0.991760283648,34,,Arbérats-Sillègue,Arbérats-Sillègue,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64035,ARBONNE,64210,ARBONNE,,43.4221190537,-1.54906516427,35,,Arbonne,Arbonne,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64036,ARBOUET SUSSAUTE,64120,ARBOUET SUSSAUTE,,43.3718035763,-0.989499391955,36,,Arbouet-Sussaute,Arbouet-Sussaute,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64037,ARBUS,64230,ARBUS,,43.3244225813,-0.506270542842,37,,Arbus,Arbus,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64038,ARCANGUES,64200,ARCANGUES,,43.4260778242,-1.51681726621,38,,Arcangues,Arcangues,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64039,AREN,64400,AREN,,43.2479579692,-0.725585017259,39,,Aren,Aren,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64040,ARETTE,64570,ARETTE,,43.0398698092,-0.735693683266,40,,Arette,Arette,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64041,ARESSY,64320,ARESSY,,43.2780475519,-0.328128024391,41,,Aressy,Aressy,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64042,ARGAGNON,64300,ARGAGNON,,43.4617440342,-0.676305200735,42,,Argagnon,Argagnon,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64043,ARGELOS,64450,ARGELOS,,43.4489771911,-0.346464744941,43,,Argelos,Argelos,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64044,ARGET,64410,ARGET,,43.5449611911,-0.532086605631,44,,Arget,Arget,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64045,ARHANSUS,64120,ARHANSUS,,43.2548502906,-1.02446609378,45,,Arhansus,Arhansus,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64046,ARMENDARITS,64640,ARMENDARITS,,43.2995874286,-1.16163775322,46,,Armendarits,Armendarits,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64047,ARNEGUY,64220,ARNEGUY,,43.0855581818,-1.27601688686,47,,Arnéguy,Arnéguy,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64048,ARNOS,64370,ARNOS,,43.4587702004,-0.533290212033,48,,Arnos,Arnos,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64049,AROUE ITHOROTS OLHAIBY,64120,AROUE ITHOROTS OLHAIBY,,43.3085352376,-0.928425599929,49,,Aroue-Ithorots-Olhaïby,Aroue-Ithorots-Olhaïby,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64049,AROUE ITHOROTS OLHAIBY,64120,AROUE ITHOROTS OLHAIBY,ITHOROTS OLHAIBY,43.3085352376,-0.928425599929,49,,Aroue-Ithorots-Olhaïby,Aroue-Ithorots-Olhaïby,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64050,ARRAST LARREBIEU,64130,ARRAST LARREBIEU,,43.2856672915,-0.850375454846,50,,Arrast-Larrebieu,Arrast-Larrebieu,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64051,ARRAUTE CHARRITTE,64120,ARRAUTE CHARRITTE,,43.419824465,-1.11344095243,51,,Arraute-Charritte,Arraute-Charritte,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64052,ARRICAU BORDES,64350,ARRICAU BORDES,,43.5019052094,-0.142484551875,52,,Arricau-Bordes,Arricau-Bordes,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64053,ARRIEN,64420,ARRIEN,,43.3128952701,-0.162235527982,53,,Arrien,Arrien,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64054,ARROS DE NAY,64800,ARROS DE NAY,,43.1823198948,-0.294968827477,54,,Arros-de-Nay,Arros-de-Nay,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64056,ARROSES,64350,ARROSES,,43.5470586711,-0.111198183696,56,,Arrosès,Arrosès,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64057,ARTHEZ DE BEARN,64370,ARTHEZ DE BEARN,,43.4663451599,-0.620076434129,57,,Arthez-de-Béarn,Arthez-de-Béarn,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64058,ARTHEZ D ASSON,64800,ARTHEZ D ASSON,,43.0890909736,-0.253157457726,58,,Arthez-d'Asson,Arthez-d'Asson,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64059,ARTIGUELOUTAN,64420,ARTIGUELOUTAN,,43.2722949713,-0.243925717191,59,,Artigueloutan,Artigueloutan,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64060,ARTIGUELOUVE,64230,ARTIGUELOUVE,,43.3137207808,-0.473057611318,60,,Artiguelouve,Artiguelouve,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64061,ARTIX,64170,ARTIX,,43.3977948105,-0.574159913123,61,,Artix,Artix,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64062,ARUDY,64260,ARUDY,,43.1003244271,-0.47407529449,62,,Arudy,Arudy,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64063,ARZACQ ARRAZIGUET,64410,ARZACQ ARRAZIGUET,,43.5410756338,-0.424773783669,63,,Arzacq-Arraziguet,Arzacq-Arraziguet,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64064,ASASP ARROS,64660,ASASP ARROS,,43.1179115359,-0.629962098599,64,,Asasp-Arros,Asasp-Arros,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64064,ASASP ARROS,64660,ASASP ARROS,ARROS D OLORON,43.1179115359,-0.629962098599,64,,Asasp-Arros,Asasp-Arros,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64065,ASCAIN,64310,ASCAIN,,43.3396601016,-1.6307994298,65,,Ascain,Ascain,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64066,ASCARAT,64220,ASCARAT,,43.1831619914,-1.26301579695,66,,Ascarat,Ascarat,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64067,ASSAT,64510,ASSAT,,43.2575933228,-0.288463640719,67,,Assat,Assat,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64068,ASSON,64800,ASSON,,43.0932345158,-0.24942324617,68,,Asson,Asson,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64069,ASTE BEON,64260,ASTE BEON,,43.0305496433,-0.397677517551,69,,Aste-Béon,Aste-Béon,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64070,ASTIS,64450,ASTIS,,43.4371727056,-0.322407779012,70,,Astis,Astis,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64071,ATHOS ASPIS,64390,ATHOS ASPIS,,43.4126325632,-0.964931757196,71,,Athos-Aspis,Athos-Aspis,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64072,AUBERTIN,64290,AUBERTIN,,43.2716852677,-0.480982579779,72,,Aubertin,Aubertin,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64073,AUBIN,64230,AUBIN,,43.4356805812,-0.421465763214,73,,Aubin,Aubin,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64074,AUBOUS,64330,AUBOUS,,43.5761449327,-0.141071694882,74,,Aubous,Aubous,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64075,AUDAUX,64190,AUDAUX,,43.3725983414,-0.779901739497,75,,Audaux,Audaux,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64077,AUGA,64450,AUGA,,43.4773069345,-0.378698295047,77,,Auga,Auga,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64078,AURIAC,64450,AURIAC,,43.4534554702,-0.319474009911,78,,Auriac,Auriac,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64079,AURIONS IDERNES,64350,AURIONS IDERNES,,43.53116782,-0.130770309579,79,,Aurions-Idernes,Aurions-Idernes,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64080,AUSSEVIELLE,64230,AUSSEVIELLE,,43.3584012455,-0.479170118376,80,,Aussevielle,Aussevielle,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64081,AUSSURUCQ,64130,AUSSURUCQ,,43.1289596051,-0.978843273973,81,,Aussurucq,Aussurucq,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64082,AUTERRIVE,64270,AUTERRIVE,,43.4655237651,-1.00925153647,82,,Auterrive,Auterrive,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64083,AUTEVIELLE ST MARTIN BIDEREN,64390,AUTEVIELLE ST MARTIN BIDEREN,,43.3956392905,-0.977749738282,83,,Autevielle-Saint-Martin-Bideren,Autevielle-Saint-Martin-Bideren,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64084,AYDIE,64330,AYDIE,,43.5707613227,-0.113447741678,84,,Aydie,Aydie,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64085,AYDIUS,64490,AYDIUS,,42.9988475331,-0.519267160267,85,,Aydius,Aydius,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64086,AYHERRE,64240,AYHERRE,,43.3787620748,-1.245267479,86,,Ayherre,Ayherre,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64087,BAIGTS DE BEARN,64300,BAIGTS DE BEARN,,43.5246551723,-0.845538853902,87,,Baigts-de-Béarn,Baigts-de-Béarn,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64088,BALANSUN,64300,BALANSUN,,43.4912109435,-0.693259422836,88,,Balansun,Balansun,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64089,BALEIX,64460,BALEIX,,43.3760240874,-0.136041304972,89,,Baleix,Baleix,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64090,BALIRACQ MAUMUSSON,64330,BALIRACQ MAUMUSSON,,43.5412822794,-0.2496409379,90,,Baliracq-Maumusson,Baliracq-Maumusson,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64091,BALIROS,64510,BALIROS,,43.2285177341,-0.308318533909,91,,Baliros,Baliros,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64092,BANCA,64430,BANCA,,43.1001307168,-1.37160784191,92,,Banca,Banca,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64093,BARCUS,64130,BARCUS,,43.1772515324,-0.794679831418,93,,Barcus,Barcus,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64094,BARDOS,64520,BARDOS,,43.4681224323,-1.22301840969,94,,Bardos,Bardos,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64095,BARINQUE,64160,BARINQUE,,43.4040350013,-0.269781784045,95,,Barinque,Barinque,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64096,BARRAUTE CAMU,64390,BARRAUTE CAMU,,43.3831583364,-0.900945944724,96,,Barraute-Camu,Barraute-Camu,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64097,BARZUN,64530,BARZUN,,43.2154362655,-0.134118816539,97,,Barzun,Barzun,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64098,BASSILLON VAUZE,64350,BASSILLON VAUZE,,43.4526790153,-0.07621462231,98,,Bassillon-Vauzé,Bassillon-Vauzé,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64099,BASTANES,64190,BASTANES,,43.3528261826,-0.752484725384,99,,Bastanès,Bastanès,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64100,BASSUSSARRY,64200,BASSUSSARRY,,43.4445312416,-1.49649880002,100,,Bassussarry,Bassussarry,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64101,BAUDREIX,64800,BAUDREIX,,43.2056641864,-0.258861524454,101,,Baudreix,Baudreix,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64102,BAYONNE,64100,BAYONNE,,43.4922254016,-1.46607674358,102,,Bayonne,Bayonne,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64103,BEDEILLE,64460,BEDEILLE,,43.3464176542,-0.0975638445243,103,,Bédeille,Bédeille,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64104,BEDOUS,64490,BEDOUS,,43.0056214728,-0.588165067203,104,,Bedous,Bedous,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64105,BEGUIOS,64120,BEGUIOS,,43.3510821409,-1.09745249473,105,,Béguios,Béguios,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64106,BEHASQUE LAPISTE,64120,BEHASQUE LAPISTE,,43.3188892342,-1.00802745389,106,,Béhasque-Lapiste,Béhasque-Lapiste,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64107,BEHORLEGUY,64220,BEHORLEGUY,,43.1201655995,-1.08535669994,107,,Béhorléguy,Béhorléguy,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64108,BELLOCQ,64270,BELLOCQ,,43.5092272402,-0.921264118611,108,,Bellocq,Bellocq,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64109,BENEJACQ,64800,BENEJACQ,,43.1946371974,-0.190128875721,109,,Bénéjacq,Bénéjacq,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64110,BEOST,64440,BEOST,,42.9800031396,-0.329944029272,110,,Béost,Béost,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64111,BENTAYOU SEREE,64460,BENTAYOU SEREE,,43.3955911569,-0.0624344594456,111,,Bentayou-Sérée,Bentayou-Sérée,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64112,BERENX,64300,BERENX,,43.4932804178,-0.867524783512,112,,Bérenx,Bérenx,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64113,BERGOUEY VIELLENAVE,64270,BERGOUEY VIELLENAVE,,43.4248305248,-1.06247099125,113,,Bergouey-Viellenave,Bergouey-Viellenave,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64113,BERGOUEY VIELLENAVE,64270,BERGOUEY VIELLENAVE,VIELLENAVE SUR BIDOUZE,43.4248305248,-1.06247099125,113,,Bergouey-Viellenave,Bergouey-Viellenave,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64114,BERNADETS,64160,BERNADETS,,43.3767881583,-0.282433844807,114,,Bernadets,Bernadets,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64115,BERROGAIN LARUNS,64130,BERROGAIN LARUNS,,43.2501740565,-0.862735447746,115,,Berrogain-Laruns,Berrogain-Laruns,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64116,BESCAT,64260,BESCAT,,43.129651934,-0.423887128976,116,,Bescat,Bescat,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64117,BESINGRAND,64150,BESINGRAND,,43.3742085086,-0.568868568942,117,,Bésingrand,Bésingrand,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64118,BETRACQ,64350,BETRACQ,,43.5180487775,-0.0544692925119,118,,Bétracq,Bétracq,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64119,BEUSTE,64800,BEUSTE,,43.2218627813,-0.22938631115,119,,Beuste,Beuste,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64120,BEYRIE SUR JOYEUSE,64120,BEYRIE SUR JOYEUSE,,43.3108779849,-1.09656956349,120,,Beyrie-sur-Joyeuse,Beyrie-sur-Joyeuse,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64121,BEYRIE EN BEARN,64230,BEYRIE EN BEARN,,43.3720098717,-0.465298241664,121,,Beyrie-en-Béarn,Beyrie-en-Béarn,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64122,BIARRITZ,64200,BIARRITZ,,43.4695847227,-1.55309857519,122,,Biarritz,Biarritz,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64123,BIDACHE,64520,BIDACHE,,43.4637748051,-1.14534438631,123,,Bidache,Bidache,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64124,BIDARRAY,64780,BIDARRAY,,43.2723557234,-1.35385755497,124,,Bidarray,Bidarray,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64125,BIDART,64210,BIDART,,43.4380521764,-1.58002714725,125,,Bidart,Bidart,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64126,BIDOS,64400,BIDOS,,43.1751507938,-0.604844854778,126,,Bidos,Bidos,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64127,BIELLE,64260,BIELLE,,43.0445386527,-0.467140466364,127,,Bielle,Bielle,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64128,BILHERES,64260,BILHERES,,43.0663758418,-0.482744274023,128,,Bilhères,Bilhères,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64129,BILLERE,64140,BILLERE,,43.3045259937,-0.393492580041,129,,Billère,Billère,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64130,BIRIATOU,64700,BIRIATOU,,43.3236811068,-1.72986887716,130,,Biriatou,Biriatou,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64131,BIRON,64300,BIRON,,43.4626761667,-0.741219830743,131,,Biron,Biron,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64132,BIZANOS,64320,BIZANOS,,43.292458405,-0.337175743313,132,,Bizanos,Bizanos,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64133,BOEIL BEZING,64510,BOEIL BEZING,,43.2275428829,-0.25100390075,133,,Boeil-Bezing,Boeil-Bezing,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64134,BONLOC,64240,BONLOC,,43.3669336353,-1.26789948653,134,,Bonloc,Bonloc,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64135,BONNUT,64300,BONNUT,,43.5504537514,-0.765861941107,135,,Bonnut,Bonnut,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64136,BORCE,64490,BORCE,,42.8583801271,-0.587747470754,136,,Borce,Borce,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64137,BORDERES,64800,BORDERES,,43.2047226344,-0.212865402876,137,,Bordères,Bordères,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64138,BORDES,64510,BORDES,,43.2436324189,-0.272861349005,138,,Bordes,Bordes,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64139,BOSDARROS,64290,BOSDARROS,,43.2013292234,-0.35837984121,139,,Bosdarros,Bosdarros,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64140,BOUCAU,64340,BOUCAU,,43.52540846,-1.48001541381,140,,Boucau,Boucau,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64141,BOUEILH BOUEILHO LASQUE,64330,BOUEILH BOUEILHO LASQUE,,43.5391539288,-0.31483213796,141,,Boueilh-Boueilho-Lasque,Boueilh-Boueilho-Lasque,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64142,BOUGARBER,64230,BOUGARBER,,43.3916441338,-0.469336082949,142,,Bougarber,Bougarber,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64143,BOUILLON,64410,BOUILLON,,43.4946357814,-0.508161824959,143,,Bouillon,Bouillon,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64144,BOUMOURT,64370,BOUMOURT,,43.4423096627,-0.514147087954,144,,Boumourt,Boumourt,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64145,BOURDETTES,64800,BOURDETTES,,43.1941285363,-0.270250092047,145,,Bourdettes,Bourdettes,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64146,BOURNOS,64450,BOURNOS,,43.4388278116,-0.395430501786,146,,Bournos,Bournos,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64147,BRISCOUS,64240,BRISCOUS,,43.4564293033,-1.32475876534,147,,Briscous,Briscous,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64148,BRUGES CAPBIS MIFAGET,64800,BRUGES CAPBIS MIFAGET,,43.1253072219,-0.303486553374,148,,Bruges-Capbis-Mifaget,Bruges-Capbis-Mifaget,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64148,BRUGES CAPBIS MIFAGET,64800,BRUGES CAPBIS MIFAGET,CAPBIS,43.1253072219,-0.303486553374,148,,Bruges-Capbis-Mifaget,Bruges-Capbis-Mifaget,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64148,BRUGES CAPBIS MIFAGET,64800,BRUGES CAPBIS MIFAGET,MIFAGET,43.1253072219,-0.303486553374,148,,Bruges-Capbis-Mifaget,Bruges-Capbis-Mifaget,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64149,BUGNEIN,64190,BUGNEIN,,43.3686766215,-0.754427090491,149,,Bugnein,Bugnein,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64150,BUNUS,64120,BUNUS,,43.2072970793,-1.06452656523,150,,Bunus,Bunus,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64151,BURGARONNE,64390,BURGARONNE,,43.4177713373,-0.906845978602,151,,Burgaronne,Burgaronne,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64152,BUROS,64160,BUROS,,43.3463641258,-0.315857247807,152,,Buros,Buros,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64153,BUROSSE MENDOUSSE,64330,BUROSSE MENDOUSSE,,43.5133919734,-0.209328142304,153,,Burosse-Mendousse,Burosse-Mendousse,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64154,BUSSUNARITS SARRASQUETTE,64220,BUSSUNARITS SARRASQUETTE,,43.1692531272,-1.14700332707,154,,Bussunarits-Sarrasquette,Bussunarits-Sarrasquette,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64155,BUSTINCE IRIBERRY,64220,BUSTINCE IRIBERRY,,43.1873136505,-1.18469156817,155,,Bustince-Iriberry,Bustince-Iriberry,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64156,BUZIET,64680,BUZIET,,43.1442617368,-0.47589383596,156,,Buziet,Buziet,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64157,BUZY,64260,BUZY,,43.1407278397,-0.446234672821,157,,Buzy,Buzy,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64158,CABIDOS,64410,CABIDOS,,43.5396790369,-0.463748067374,158,,Cabidos,Cabidos,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64159,CADILLON,64330,CADILLON,,43.5242735908,-0.154852094311,159,,Cadillon,Cadillon,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64160,CAMBO LES BAINS,64250,CAMBO LES BAINS,,43.3617393087,-1.38541817484,160,,Cambo-les-Bains,Cambo-les-Bains,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64161,CAME,64520,CAME,,43.48131751,-1.09178751513,161,,Came,Came,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64162,CAMOU CIHIGUE,64470,CAMOU CIHIGUE,,43.1187601878,-0.921772960752,162,,Camou-Cihigue,Camou-Cihigue,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64165,CARDESSE,64360,CARDESSE,,43.2634335383,-0.592748076501,165,,Cardesse,Cardesse,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64166,CARO,64220,CARO,,43.1487167236,-1.22057717225,166,,Caro,Caro,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64167,CARRERE,64160,CARRERE,,43.478719085,-0.277971738226,167,,Carrère,Carrère,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64168,CARRESSE CASSABER,64270,CARRESSE CASSABER,,43.4867092033,-0.999430077721,168,,Carresse-Cassaber,Carresse-Cassaber,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64168,CARRESSE CASSABER,64270,CARRESSE CASSABER,CASSABER,43.4867092033,-0.999430077721,168,,Carresse-Cassaber,Carresse-Cassaber,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64170,CASTAGNEDE,64270,CASTAGNEDE,,43.4556389583,-0.974073017031,170,,Castagnède,Castagnède,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64171,CASTEIDE CAMI,64170,CASTEIDE CAMI,,43.4244241199,-0.528274059167,171,,Casteide-Cami,Casteide-Cami,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64172,CASTEIDE CANDAU,64370,CASTEIDE CANDAU,,43.5246218611,-0.557545327219,172,,Casteide-Candau,Casteide-Candau,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64173,CASTEIDE DOAT,64460,CASTEIDE DOAT,,43.3740019876,-0.0110617940773,173,,Casteide-Doat,Casteide-Doat,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64174,CASTERA LOUBIX,64460,CASTERA LOUBIX,,43.4059390869,-0.034605507142,174,,Castéra-Loubix,Castéra-Loubix,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64175,CASTET,64260,CASTET,,43.0548936338,-0.373353196266,175,,Castet,Castet,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64176,CASTETBON,64190,CASTETBON,,43.3972591089,-0.792417128449,176,,Castetbon,Castetbon,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64177,CASTETIS,64300,CASTETIS,,43.4755360423,-0.718730754517,177,,Castétis,Castétis,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64178,CASTETNAU CAMBLONG,64190,CASTETNAU CAMBLONG,,43.3227014971,-0.797193541473,178,,Castetnau-Camblong,Castetnau-Camblong,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64179,CASTETNER,64300,CASTETNER,,43.4401622593,-0.729783827554,179,,Castetner,Castetner,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64180,CASTETPUGON,64330,CASTETPUGON,,43.5630952267,-0.227861123127,180,,Castetpugon,Castetpugon,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64181,CASTILLON D ARTHEZ,64370,CASTILLON D ARTHEZ,,43.4647790726,-0.57522696758,181,,Castillon Canton d'Arthez-de-Béarn,Castillon Canton d'Arthez-de-Béarn,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64182,CASTILLON DE LEMBEYE,64350,CASTILLON DE LEMBEYE,,43.4827393039,-0.12995598336,182,,Castillon Canton de Lembeye,Castillon Canton de Lembeye,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64183,CAUBIOS LOOS,64230,CAUBIOS LOOS,,43.4184546974,-0.403179073959,183,,Caubios-Loos,Caubios-Loos,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64184,CESCAU,64170,CESCAU,,43.4123325253,-0.503690665578,184,,Cescau,Cescau,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64185,CETTE EYGUN,64490,CETTE EYGUN,,42.9364187767,-0.568555169226,185,,Cette-Eygun,Cette-Eygun,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64186,CHARRE,64190,CHARRE,,43.3109705377,-0.854949348438,186,,Charre,Charre,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64187,CHARRITTE DE BAS,64130,CHARRITTE DE BAS,,43.2950340435,-0.897219835697,187,,Charritte-de-Bas,Charritte-de-Bas,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64188,CHERAUTE,64130,CHERAUTE,,43.2304528106,-0.821599852605,188,,Chéraute,Chéraute,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64189,CIBOURE,64500,CIBOURE,,43.3749892489,-1.66133753626,189,,Ciboure,Ciboure,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64190,CLARACQ,64330,CLARACQ,,43.5062422755,-0.293137631962,190,,Claracq,Claracq,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64191,COARRAZE,64800,COARRAZE,,43.16814552,-0.209947352946,191,,Coarraze,Coarraze,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64192,CONCHEZ DE BEARN,64330,CONCHEZ DE BEARN,,43.546390844,-0.168607821774,192,,Conchez-de-Béarn,Conchez-de-Béarn,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64193,CORBERE ABERES,64350,CORBERE ABERES,,43.4757367559,-0.0896960657333,193,,Corbère-Abères,Corbère-Abères,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64194,COSLEDAA LUBE BOAST,64160,COSLEDAA LUBE BOAST,,43.4521878366,-0.22859543445,194,,Coslédaà-Lube-Boast,Coslédaà-Lube-Boast,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64195,COUBLUCQ,64410,COUBLUCQ,,43.5378290325,-0.363582850112,195,,Coublucq,Coublucq,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64196,CROUSEILLES,64350,CROUSEILLES,,43.5309932657,-0.0816458802012,196,,Crouseilles,Crouseilles,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64197,CUQUERON,64360,CUQUERON,,43.3097711576,-0.552973788897,197,,Cuqueron,Cuqueron,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64198,DENGUIN,64230,DENGUIN,,43.3708216546,-0.500350227226,198,,Denguin,Denguin,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64199,DIUSSE,64330,DIUSSE,,43.5668688531,-0.171440879545,199,,Diusse,Diusse,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64200,DOAZON,64370,DOAZON,,43.4553885972,-0.553044979993,200,,Doazon,Doazon,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64201,DOGNEN,64190,DOGNEN,,43.2890538791,-0.732627601607,201,,Dognen,Dognen,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64202,DOMEZAIN BERRAUTE,64120,DOMEZAIN BERRAUTE,,43.3266580674,-0.966401773594,202,,Domezain-Berraute,Domezain-Berraute,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64203,DOUMY,64450,DOUMY,,43.4451222979,-0.377112446292,203,,Doumy,Doumy,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64204,EAUX BONNES,64440,EAUX BONNES,,42.9515491129,-0.358104565434,204,,Eaux-Bonnes,Eaux-Bonnes,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64204,EAUX BONNES,64440,EAUX BONNES,GOURETTE,42.9515491129,-0.358104565434,204,,Eaux-Bonnes,Eaux-Bonnes,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64205,ESCOS,64270,ESCOS,,43.4428467771,-1.00557759768,205,,Escos,Escos,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64206,ESCOT,64490,ESCOT,,43.0782488645,-0.565384866739,206,,Escot,Escot,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64207,ESCOU,64870,ESCOU,,43.1836947756,-0.529049715765,207,,Escou,Escou,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64208,ESCOUBES,64160,ESCOUBES,,43.4184592644,-0.243028871952,208,,Escoubès,Escoubès,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64209,ESCOUT,64870,ESCOUT,,43.1875477654,-0.553494252513,209,,Escout,Escout,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64210,ESCURES,64350,ESCURES,,43.4659000742,-0.123403091358,210,,Escurès,Escurès,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64211,ESLOURENTIES DABAN,64420,ESLOURENTIES DABAN,,43.2999541228,-0.14066779788,211,,Eslourenties-Daban,Eslourenties-Daban,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64212,ESPECHEDE,64160,ESPECHEDE,,43.3162501481,-0.194897192092,212,,Espéchède,Espéchède,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64213,ESPELETTE,64250,ESPELETTE,,43.3235532289,-1.45240885976,213,,Espelette,Espelette,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64214,ESPES UNDUREIN,64130,ESPES UNDUREIN,,43.2745536511,-0.89014755808,214,,Espès-Undurein,Espès-Undurein,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64215,ESPIUTE,64390,ESPIUTE,,43.3544908357,-0.914707993249,215,,Espiute,Espiute,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64216,ESPOEY,64420,ESPOEY,,43.2514996266,-0.155421762891,216,,Espoey,Espoey,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64217,ESQUIULE,64400,ESQUIULE,,43.1855622152,-0.724360816506,217,,Esquiule,Esquiule,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64218,ESTERENCUBY,64220,ESTERENCUBY,,43.0817332803,-1.18186745197,218,,Estérençuby,Estérençuby,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64219,ESTIALESCQ,64290,ESTIALESCQ,,43.220867957,-0.551869962929,219,,Estialescq,Estialescq,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64220,ESTOS,64400,ESTOS,,43.2125962039,-0.60619639047,220,,Estos,Estos,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64221,ETCHARRY,64120,ETCHARRY,,43.3321625989,-0.927792287342,221,,Etcharry,Etcharry,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64222,ETCHEBAR,64470,ETCHEBAR,,43.0669985514,-0.903672606774,222,,Etchebar,Etchebar,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64223,ETSAUT,64490,ETSAUT,,42.8973131851,-0.528220911971,223,,Etsaut,Etsaut,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64224,EYSUS,64400,EYSUS,,43.1367809349,-0.583842190325,224,,Eysus,Eysus,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64225,FEAS,64570,FEAS,,43.1680555361,-0.697334035468,225,,Ance Féas,Ance Féas,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64226,FICHOUS RIUMAYOU,64410,FICHOUS RIUMAYOU,,43.4864503765,-0.438799385567,226,,Fichous-Riumayou,Fichous-Riumayou,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64227,GABASTON,64160,GABASTON,,43.3574296466,-0.215532898213,227,,Gabaston,Gabaston,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64228,GABAT,64120,GABAT,,43.3765147976,-1.03114679811,228,,Gabat,Gabat,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64229,GAMARTHE,64220,GAMARTHE,,43.1951524207,-1.13016306002,229,,Gamarthe,Gamarthe,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64230,GAN,64290,GAN,,43.2086903788,-0.410215112205,230,,Gan,Gan,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64231,GARINDEIN,64130,GARINDEIN,,43.2188188695,-0.924182153678,231,,Garindein,Garindein,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64232,GARLEDE MONDEBAT,64450,GARLEDE MONDEBAT,,43.5101140161,-0.335110781651,232,,Garlède-Mondebat,Garlède-Mondebat,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64233,GARLIN,64330,GARLIN,,43.5599586619,-0.272296457605,233,,Garlin,Garlin,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64234,GAROS,64410,GAROS,,43.5068508348,-0.481505714885,234,,Garos,Garos,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64235,GARRIS,64120,GARRIS,,43.3394050155,-1.05898897013,235,,Garris,Garris,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64236,GAYON,64350,GAYON,,43.4853661293,-0.165938339941,236,,Gayon,Gayon,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64237,GELOS,64110,GELOS,,43.2558653705,-0.36978595434,237,,Gelos,Gelos,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64238,GER,64530,GER,,43.2540907071,-0.0593210180343,238,,Ger,Ger,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64239,GERDEREST,64160,GERDEREST,,43.4091904721,-0.170896858069,239,,Gerderest,Gerderest,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64240,GERE BELESTEN,64260,GERE BELESTEN,,43.0216111241,-0.446673264564,240,,Gère-Bélesten,Gère-Bélesten,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64241,GERONCE,64400,GERONCE,,43.2221479657,-0.710166887554,241,,Géronce,Géronce,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64242,GESTAS,64190,GESTAS,,43.3529290484,-0.881977356619,242,,Gestas,Gestas,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64243,GEUS D ARZACQ,64370,GEUS D ARZACQ,,43.4810420798,-0.522965041639,243,,Géus-d'Arzacq,Géus-d'Arzacq,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64244,GEUS D OLORON,64400,GEUS D OLORON,,43.2382175116,-0.730765582736,244,,Geüs-d'Oloron,Geüs-d'Oloron,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64245,GOES,64400,GOES,,43.2174962375,-0.575780625501,245,,Goès,Goès,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64246,GOMER,64420,GOMER,,43.2475680844,-0.197277203284,246,,Gomer,Gomer,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64247,GOTEIN LIBARRENX,64130,GOTEIN LIBARRENX,,43.1917199949,-0.889247440623,247,,Gotein-Libarrenx,Gotein-Libarrenx,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64249,GUETHARY,64210,GUETHARY,,43.4216284725,-1.61035485945,249,,Guéthary,Guéthary,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64250,GUICHE,64520,GUICHE,,43.5099011843,-1.20771855354,250,,Guiche,Guiche,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64251,GUINARTHE PARENTIES,64390,GUINARTHE PARENTIES,,43.3844299922,-0.946598983055,251,,Guinarthe-Parenties,Guinarthe-Parenties,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64252,GURMENCON,64400,GURMENCON,,43.1583407209,-0.599376287501,252,,Gurmençon,Gurmençon,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64253,GURS,64190,GURS,,43.2765512642,-0.758975468791,253,,Gurs,Gurs,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64254,HAGETAUBIN,64370,HAGETAUBIN,,43.5105619096,-0.612895161323,254,,Hagetaubin,Hagetaubin,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64255,HALSOU,64480,HALSOU,,43.3851722831,-1.41123002191,255,,Halsou,Halsou,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64256,HASPARREN,64240,HASPARREN,,43.3982982364,-1.32563611071,256,,Hasparren,Hasparren,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64257,HAUT DE BOSDARROS,64800,HAUT DE BOSDARROS,,43.1689449769,-0.327961166577,257,,Haut-de-Bosdarros,Haut-de-Bosdarros,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64258,HAUX,64470,HAUX,,43.0614133334,-0.837865590402,258,,Haux,Haux,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64259,HELETTE,64640,HELETTE,,43.306287983,-1.24580704734,259,,Hélette,Hélette,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64260,HENDAYE,64700,HENDAYE,,43.3656373687,-1.76396662,260,,Hendaye,Hendaye,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64261,HERRERE,64680,HERRERE,,43.1595542922,-0.545488112075,261,,Herrère,Herrère,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64262,HIGUERES SOUYE,64160,HIGUERES SOUYE,,43.3790480363,-0.250616143806,262,,Higuères-Souye,Higuères-Souye,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64263,L HOPITAL D ORION,64270,L HOPITAL D ORION,,43.441442284,-0.853283494968,263,L',Hôpital-d'Orion,L'Hôpital-d'Orion,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64264,L HOPITAL ST BLAISE,64130,L HOPITAL ST BLAISE,,43.2555611144,-0.770264601126,264,L',Hôpital-Saint-Blaise,L'Hôpital-Saint-Blaise,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64265,HOSTA,64120,HOSTA,,43.1484603704,-1.07534418075,265,,Hosta,Hosta,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64266,HOURS,64420,HOURS,,43.223209454,-0.172612595527,266,,Hours,Hours,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64267,IBARROLLE,64120,IBARROLLE,,43.1926783014,-1.09837639291,267,,Ibarrolle,Ibarrolle,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64268,IDAUX MENDY,64130,IDAUX MENDY,,43.1737848926,-0.922656463962,268,,Idaux-Mendy,Idaux-Mendy,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64269,IDRON,64320,IDRON,,43.2983178945,-0.306106204673,269,,Idron,Idron,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64270,IGON,64800,IGON,,43.153240594,-0.232545768454,270,,Igon,Igon,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64271,IHOLDY,64640,IHOLDY,,43.2735914336,-1.17738320434,271,,Iholdy,Iholdy,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64272,ILHARRE,64120,ILHARRE,,43.4021965208,-1.03242626315,272,,Ilharre,Ilharre,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64273,IRISSARRY,64780,IRISSARRY,,43.2614719543,-1.23617834401,273,,Irissarry,Irissarry,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64274,IROULEGUY,64220,IROULEGUY,,43.185106673,-1.28878634847,274,,Irouléguy,Irouléguy,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64275,ISPOURE,64220,ISPOURE,,43.1832686174,-1.23994963712,275,,Ispoure,Ispoure,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64276,ISSOR,64570,ISSOR,,43.0901244813,-0.658271988701,276,,Issor,Issor,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64277,ISTURITS,64240,ISTURITS,,43.3700960955,-1.20346966918,277,,Isturits,Isturits,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64279,ITXASSOU,64250,ITXASSOU,,43.3100742796,-1.40531371043,279,,Itxassou,Itxassou,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64280,IZESTE,64260,IZESTE,,43.0842981048,-0.44404003206,280,,Izeste,Izeste,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64281,JASSES,64190,JASSES,,43.3137532193,-0.734253298525,281,,Jasses,Jasses,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64282,JATXOU,64480,JATXOU,,43.4070870725,-1.41179120283,282,,Jatxou,Jatxou,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64283,JAXU,64220,JAXU,,43.2043840286,-1.20784344564,283,,Jaxu,Jaxu,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64284,JURANCON,64110,JURANCON,,43.2695632007,-0.407001172739,284,,Jurançon,Jurançon,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64285,JUXUE,64120,JUXUE,,43.2306045938,-1.04127746067,285,,Juxue,Juxue,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64286,LAA MONDRANS,64300,LAA MONDRANS,,43.4559984058,-0.770649350183,286,,Laà-Mondrans,Laà-Mondrans,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64287,LAAS,64390,LAAS,,43.3863780665,-0.854028010814,287,,Laàs,Laàs,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64288,LABASTIDE CEZERACQ,64170,LABASTIDE CEZERACQ,,43.3764513777,-0.540462234039,288,,Labastide-Cézéracq,Labastide-Cézéracq,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64289,LA BASTIDE CLAIRENCE,64240,LA BASTIDE CLAIRENCE,,43.417654264,-1.23211183617,289,La,Bastide-Clairence,La Bastide-Clairence,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64290,LABASTIDE MONREJEAU,64170,LABASTIDE MONREJEAU,,43.3907261571,-0.527272938618,290,,Labastide-Monréjeau,Labastide-Monréjeau,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64291,LABASTIDE VILLEFRANCHE,64270,LABASTIDE VILLEFRANCHE,,43.4542845438,-1.03617926766,291,,Labastide-Villefranche,Labastide-Villefranche,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64292,LABATMALE,64530,LABATMALE,,43.1829361776,-0.150153111393,292,,Labatmale,Labatmale,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64293,LABATUT,64460,LABATUT,,43.4165864025,-0.0134728274425,293,,Labatut,Labatut,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64294,LABETS BISCAY,64120,LABETS BISCAY,,43.3901052681,-1.06356701774,294,,Labets-Biscay,Labets-Biscay,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64295,LABEYRIE,64300,LABEYRIE,,43.5417553069,-0.631736420575,295,,Labeyrie,Labeyrie,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64296,LACADEE,64300,LACADEE,,43.5262558037,-0.655405212683,296,,Lacadée,Lacadée,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64297,LACARRE,64220,LACARRE,,43.1875203489,-1.16195961403,297,,Lacarre,Lacarre,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64298,LACARRY ARHAN CHARRITTE DE HAUT,64470,LACARRY ARHAN CHARRITTE DE HAUT,,43.0712585016,-0.950546305865,298,,Lacarry-Arhan-Charritte-de-Haut,Lacarry-Arhan-Charritte-de-Haut,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64299,LACOMMANDE,64360,LACOMMANDE,,43.2762663968,-0.50925390327,299,,Lacommande,Lacommande,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64300,LACQ,64170,LACQ,,43.4215600876,-0.612163653339,300,,Lacq,Lacq,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64300,LACQ,64170,LACQ,AUDEJOS,43.4215600876,-0.612163653339,300,,Lacq,Lacq,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64301,LAGOR,64150,LAGOR,,43.382938836,-0.66718204589,301,,Lagor,Lagor,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64302,LAGOS,64800,LAGOS,,43.2122827806,-0.220943768634,302,,Lagos,Lagos,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64303,LAGUINGE RESTOUE,64470,LAGUINGE RESTOUE,,43.095942797,-0.84902832685,303,,Laguinge-Restoue,Laguinge-Restoue,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64304,LAHONCE,64990,LAHONCE,,43.4844685694,-1.39601479811,304,,Lahonce,Lahonce,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64305,LAHONTAN,64270,LAHONTAN,,43.5208233379,-0.968131907041,305,,Lahontan,Lahontan,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64306,LAHOURCADE,64150,LAHOURCADE,,43.351780818,-0.630355750104,306,,Lahourcade,Lahourcade,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64307,LALONGUE,64350,LALONGUE,,43.4812762295,-0.188843126276,307,,Lalongue,Lalongue,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64308,LALONQUETTE,64450,LALONQUETTE,,43.4892855567,-0.316695658071,308,,Lalonquette,Lalonquette,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64309,LAMAYOU,64460,LAMAYOU,,43.3849922326,-0.030140439683,309,,Lamayou,Lamayou,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64310,LANNE EN BARETOUS,64570,LANNE EN BARETOUS,,43.0731187091,-0.784065423581,310,,Lanne-en-Barétous,Lanne-en-Barétous,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64311,LANNECAUBE,64350,LANNECAUBE,,43.4823827586,-0.216562157908,311,,Lannecaube,Lannecaube,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64312,LANNEPLAA,64300,LANNEPLAA,,43.4601794735,-0.827975616705,312,,Lanneplaà,Lanneplaà,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64313,LANTABAT,64640,LANTABAT,,43.2575328584,-1.12843928405,313,,Lantabat,Lantabat,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64314,LARCEVEAU ARROS CIBITS,64120,LARCEVEAU ARROS CIBITS,,43.2252198933,-1.09885079256,314,,Larceveau-Arros-Cibits,Larceveau-Arros-Cibits,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64315,LAROIN,64110,LAROIN,,43.2974492378,-0.436584051431,315,,Laroin,Laroin,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64316,LARRAU,64560,LARRAU,,43.0098151548,-0.97252443116,316,,Larrau,Larrau,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64317,LARRESSORE,64480,LARRESSORE,,43.3695046276,-1.44321557321,317,,Larressore,Larressore,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64318,LARREULE,64410,LARREULE,,43.4734148077,-0.470945656972,318,,Larreule,Larreule,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64319,LARRIBAR SORHAPURU,64120,LARRIBAR SORHAPURU,,43.290356098,-1.00299694901,319,,Larribar-Sorhapuru,Larribar-Sorhapuru,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64320,LARUNS,64440,LARUNS,,42.8923840962,-0.419704380499,320,,Laruns,Laruns,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64320,LARUNS,64440,LARUNS,EAUX CHAUDES,42.8923840962,-0.419704380499,320,,Laruns,Laruns,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64320,LARUNS,64440,LARUNS,GABAS,42.8923840962,-0.419704380499,320,,Laruns,Laruns,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64321,LASCLAVERIES,64450,LASCLAVERIES,,43.4339584385,-0.291563063654,321,,Lasclaveries,Lasclaveries,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64322,LASSE,64220,LASSE,,43.1357291149,-1.28300542031,322,,Lasse,Lasse,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64323,LASSERRE,64350,LASSERRE,,43.5124997921,-0.0798087284423,323,,Lasserre,Lasserre,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64324,LASSEUBE,64290,LASSEUBE,,43.2135578773,-0.483373200293,324,,Lasseube,Lasseube,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64325,LASSEUBETAT,64290,LASSEUBETAT,,43.1830063532,-0.448422921821,325,,Lasseubetat,Lasseubetat,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64326,LAY LAMIDOU,64190,LAY LAMIDOU,,43.2943257752,-0.708646235265,326,,Lay-Lamidou,Lay-Lamidou,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64327,LECUMBERRY,64220,LECUMBERRY,,43.0676995332,-1.1247200726,327,,Lecumberry,Lecumberry,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64328,LEDEUIX,64400,LEDEUIX,,43.2320564193,-0.611443821671,328,,Ledeuix,Ledeuix,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64329,LEE,64320,LEE,,43.2913998523,-0.286759766855,329,,Lée,Lée,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64330,LEES ATHAS,64490,LEES ATHAS,,42.9710540613,-0.668398814857,330,,Lées-Athas,Lées-Athas,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64331,LEMBEYE,64350,LEMBEYE,,43.4517373745,-0.104621641637,331,,Lembeye,Lembeye,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64332,LEME,64450,LEME,,43.4960890658,-0.360478701676,332,,Lème,Lème,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64334,LEREN,64270,LEREN,,43.503711716,-1.0502356112,334,,Léren,Léren,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64335,LESCAR,64230,LESCAR,,43.3404556993,-0.428066847423,335,,Lescar,Lescar,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64336,LESCUN,64490,LESCUN,,42.9178479197,-0.677437388946,336,,Lescun,Lescun,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64337,LESPIELLE,64350,LESPIELLE,,43.4688456772,-0.154777907241,337,,Lespielle,Lespielle,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64338,LESPOURCY,64160,LESPOURCY,,43.3596787562,-0.154631315094,338,,Lespourcy,Lespourcy,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64339,LESTELLE BETHARRAM,64800,LESTELLE BETHARRAM,,43.1218161618,-0.213848358539,339,,Lestelle-Bétharram,Lestelle-Bétharram,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64340,LICHANS SUNHAR,64470,LICHANS SUNHAR,,43.0928633026,-0.881047174356,340,,Lichans-Sunhar,Lichans-Sunhar,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64341,LICHOS,64130,LICHOS,,43.3089314168,-0.881172413258,341,,Lichos,Lichos,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64342,LICQ ATHEREY,64560,LICQ ATHEREY,,43.0554346058,-0.87881299213,342,,Licq-Athérey,Licq-Athérey,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64343,LIMENDOUS,64420,LIMENDOUS,,43.2780903853,-0.182787733754,343,,Limendous,Limendous,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64344,LIVRON,64530,LIVRON,,43.2327692583,-0.127683079228,344,,Livron,Livron,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64345,LOHITZUN OYHERCQ,64120,LOHITZUN OYHERCQ,,43.271181894,-0.966424793959,345,,Lohitzun-Oyhercq,Lohitzun-Oyhercq,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64346,LOMBIA,64160,LOMBIA,,43.3292789984,-0.132750238483,346,,Lombia,Lombia,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64347,LONCON,64410,LONCON,,43.4709402635,-0.419531707158,347,,Lonçon,Lonçon,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64348,LONS,64140,LONS,,43.3212113158,-0.401734390624,348,,Lons,Lons,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64349,LOUBIENG,64300,LOUBIENG,,43.4208530318,-0.756562747566,349,,Loubieng,Loubieng,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64350,LOUHOSSOA,64250,LOUHOSSOA,,43.3122720751,-1.34791351208,350,,Louhossoa,Louhossoa,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64351,LOURDIOS ICHERE,64570,LOURDIOS ICHERE,,43.0419831344,-0.655955491716,351,,Lourdios-Ichère,Lourdios-Ichère,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64352,LOURENTIES,64420,LOURENTIES,,43.2864004571,-0.159610916229,352,,Lourenties,Lourenties,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64353,LOUVIE JUZON,64260,LOUVIE JUZON,,43.0727682758,-0.337194181284,353,,Louvie-Juzon,Louvie-Juzon,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64354,LOUVIE SOUBIRON,64440,LOUVIE SOUBIRON,,43.0139182282,-0.33273247908,354,,Louvie-Soubiron,Louvie-Soubiron,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64355,LOUVIGNY,64410,LOUVIGNY,,43.5050422971,-0.445140263297,355,,Louvigny,Louvigny,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64356,LUC ARMAU,64350,LUC ARMAU,,43.4265649374,-0.0728344787144,356,,Luc-Armau,Luc-Armau,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64357,LUCARRE,64350,LUCARRE,,43.4036827051,-0.0849144725176,357,,Lucarré,Lucarré,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64358,LUCGARIER,64420,LUCGARIER,,43.2320475565,-0.194294488173,358,,Lucgarier,Lucgarier,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64359,LUCQ DE BEARN,64360,LUCQ DE BEARN,,43.2979508598,-0.647527637305,359,,Lucq-de-Béarn,Lucq-de-Béarn,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64360,LURBE ST CHRISTAU,64660,LURBE ST CHRISTAU,,43.1077815258,-0.60256135415,360,,Lurbe-Saint-Christau,Lurbe-Saint-Christau,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64361,LUSSAGNET LUSSON,64160,LUSSAGNET LUSSON,,43.4423563274,-0.199025557867,361,,Lussagnet-Lusson,Lussagnet-Lusson,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64362,LUXE SUMBERRAUTE,64120,LUXE SUMBERRAUTE,,43.3492777324,-1.07290522087,362,,Luxe-Sumberraute,Luxe-Sumberraute,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64363,LYS,64260,LYS,,43.1338458627,-0.346755700017,363,,Lys,Lys,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64364,MACAYE,64240,MACAYE,,43.3223278424,-1.32403472047,364,,Macaye,Macaye,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64365,MALAUSSANNE,64410,MALAUSSANNE,,43.5702193545,-0.469701824259,365,,Malaussanne,Malaussanne,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64366,MASCARAAS HARON,64330,MASCARAAS HARON,,43.5399650569,-0.217995397037,366,,Mascaraàs-Haron,Mascaraàs-Haron,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64367,MASLACQ,64300,MASLACQ,,43.42655357,-0.698940013436,367,,Maslacq,Maslacq,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64368,MASPARRAUTE,64120,MASPARRAUTE,,43.3919071747,-1.08723243847,368,,Masparraute,Masparraute,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64369,MASPIE LALONQUERE JUILLACQ,64350,MASPIE LALONQUERE JUILLACQ,,43.4247831466,-0.14776537036,369,,Maspie-Lalonquère-Juillacq,Maspie-Lalonquère-Juillacq,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64370,MAUCOR,64160,MAUCOR,,43.3565898582,-0.284966466044,370,,Maucor,Maucor,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64371,MAULEON LICHARRE,64130,MAULEON SOULE,,43.2166409006,-0.883099093035,371,,Mauléon-Licharre,Mauléon-Licharre,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64372,MAURE,64460,MAURE,,43.3777021697,-0.078138626285,372,,Maure,Maure,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64373,MAZERES LEZONS,64110,MAZERES LEZONS,,43.2732972829,-0.35479385766,373,,Mazères-Lezons,Mazères-Lezons,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64374,MAZEROLLES,64230,MAZEROLLES,,43.443213137,-0.478568880464,374,,Mazerolles,Mazerolles,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64375,MEHARIN,64120,MEHARIN,,43.3311255336,-1.15068722067,375,,Méharin,Méharin,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64376,MEILLON,64510,MEILLON,,43.2702297083,-0.307769641145,376,,Meillon,Meillon,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64377,MENDIONDE,64240,MENDIONDE,,43.3375302797,-1.28633130956,377,,Mendionde,Mendionde,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64378,MENDITTE,64130,MENDITTE,,43.1686396764,-0.886409522024,378,,Menditte,Menditte,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64379,MENDIVE,64220,MENDIVE,,43.0830689585,-1.08233702672,379,,Mendive,Mendive,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64380,MERACQ,64410,MERACQ,,43.5116461311,-0.383432750249,380,,Méracq,Méracq,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64381,MERITEIN,64190,MERITEIN,,43.3386651984,-0.750086761557,381,,Méritein,Méritein,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64382,MESPLEDE,64370,MESPLEDE,,43.494020254,-0.652404668429,382,,Mesplède,Mesplède,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64383,MIALOS,64410,MIALOS,,43.4995726122,-0.409362300931,383,,Mialos,Mialos,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64385,MIOSSENS LANUSSE,64450,MIOSSENS LANUSSE,,43.4678287713,-0.302302705005,385,,Miossens-Lanusse,Miossens-Lanusse,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64386,MIREPEIX,64800,MIREPEIX,,43.1933700705,-0.250922042302,386,,Mirepeix,Mirepeix,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64387,MOMAS,64230,MOMAS,,43.4440655322,-0.442604552796,387,,Momas,Momas,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64388,MOMY,64350,MOMY,,43.387860585,-0.103108977353,388,,Momy,Momy,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64389,MONASSUT AUDIRACQ,64160,MONASSUT AUDIRACQ,,43.4131575035,-0.203083854292,389,,Monassut-Audiracq,Monassut-Audiracq,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64390,MONCAUP,64350,MONCAUP,,43.4798319005,-0.0536135830947,390,,Moncaup,Moncaup,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64391,MONCAYOLLE LARRORY MENDIBIEU,64130,MONCAYOLLE LARRORY MENDIBIEU,,43.2662281317,-0.828462255248,391,,Moncayolle-Larrory-Mendibieu,Moncayolle-Larrory-Mendibieu,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64392,MONCLA,64330,MONCLA,,43.5813150725,-0.222857074215,392,,Moncla,Moncla,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64393,MONEIN,64360,MONEIN,,43.2911768338,-0.562421409058,393,,Monein,Monein,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64394,MONPEZAT,64350,MONPEZAT,,43.5011506043,-0.0640641372412,394,,Monpezat,Monpezat,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64395,MONSEGUR,64460,MONSEGUR,,43.4355097332,-0.0128037847262,395,,Monségur,Monségur,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64396,MONT,64300,MONT,,43.4270216184,-0.659420644515,396,,Mont,Mont,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64396,MONT,64300,MONT,ARANCE,43.4270216184,-0.659420644515,396,,Mont,Mont,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64396,MONT,64300,MONT,GOUZE,43.4270216184,-0.659420644515,396,,Mont,Mont,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64396,MONT,64300,MONT,LENDRESSE,43.4270216184,-0.659420644515,396,,Mont,Mont,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64397,MONTAGUT,64410,MONTAGUT,,43.5488973424,-0.500913141999,397,,Montagut,Montagut,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64398,MONTANER,64460,MONTANER,,43.3465278822,-0.0133879488811,398,,Montaner,Montaner,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64399,MONTARDON,64121,MONTARDON,,43.3651274671,-0.35046748696,399,,Montardon,Montardon,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64400,MONTAUT,64800,MONTAUT,,43.1325527294,-0.181058529507,400,,Montaut,Montaut,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64401,MONT DISSE,64330,MONT DISSE,,43.5540384731,-0.148656162377,401,,Mont-Disse,Mont-Disse,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64403,MONTFORT,64190,MONTFORT,,43.368781372,-0.864438809018,403,,Montfort,Montfort,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64404,MONTORY,64470,MONTORY,,43.0994626037,-0.810517017656,404,,Montory,Montory,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64405,MORLAAS,64160,MORLAAS,,43.3345842802,-0.268321408256,405,,Morlaàs,Morlaàs,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64406,MORLANNE,64370,MORLANNE,,43.5087142383,-0.54195487058,406,,Morlanne,Morlanne,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64407,MOUGUERRE,64990,MOUGUERRE,,43.4574606767,-1.40476602538,407,,Mouguerre,Mouguerre,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64408,MOUHOUS,64330,MOUHOUS,,43.4918785951,-0.25442895349,408,,Mouhous,Mouhous,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64409,MOUMOUR,64400,MOUMOUR,,43.2105912463,-0.661946566242,409,,Moumour,Moumour,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64410,MOURENX,64150,MOURENX,,43.3740220656,-0.617464224458,410,,Mourenx,Mourenx,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64411,MUSCULDY,64130,MUSCULDY,,43.1868106258,-0.995319456738,411,,Musculdy,Musculdy,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64412,NABAS,64190,NABAS,,43.3317101736,-0.888663049804,412,,Nabas,Nabas,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64413,NARCASTET,64510,NARCASTET,,43.2417207618,-0.32056723288,413,,Narcastet,Narcastet,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64414,NARP,64190,NARP,,43.3839548451,-0.825430886901,414,,Narp,Narp,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64415,NAVAILLES ANGOS,64450,NAVAILLES ANGOS,,43.4150286053,-0.342429253025,415,,Navailles-Angos,Navailles-Angos,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64416,NAVARRENX,64190,NAVARRENX,,43.3288673351,-0.736425017112,416,,Navarrenx,Navarrenx,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64417,NAY,64800,NAY,,43.1761249342,-0.263143755533,417,,Nay,Nay,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64418,NOGUERES,64150,NOGUERES,,43.3707949043,-0.600380518459,418,,Noguères,Noguères,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64419,NOUSTY,64420,NOUSTY,,43.2651949251,-0.218820025106,419,,Nousty,Nousty,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64420,OGENNE CAMPTORT,64190,OGENNE CAMPTORT,,43.3175789585,-0.697599330645,420,,Ogenne-Camptort,Ogenne-Camptort,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64421,OGEU LES BAINS,64680,OGEU LES BAINS,,43.1558515448,-0.504099362447,421,,Ogeu-les-Bains,Ogeu-les-Bains,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64422,OLORON STE MARIE,64400,OLORON STE MARIE,,43.1560871189,-0.587546244432,422,,Oloron-Sainte-Marie,Oloron-Sainte-Marie,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64423,ORAAS,64390,ORAAS,,43.4353751917,-0.965928475713,423,,Oraàs,Oraàs,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64424,ORDIARP,64130,ORDIARP,,43.1898726884,-0.963886517377,424,,Ordiarp,Ordiarp,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64425,OREGUE,64120,OREGUE,,43.4048811444,-1.16532813582,425,,Orègue,Orègue,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64426,ORIN,64400,ORIN,,43.2264680608,-0.675557185765,426,,Orin,Orin,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64427,ORION,64390,ORION,,43.4207738426,-0.863152653996,427,,Orion,Orion,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64428,ORRIULE,64390,ORRIULE,,43.4054035607,-0.850232826718,428,,Orriule,Orriule,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64429,ORSANCO,64120,ORSANCO,,43.2901022225,-1.06406979337,429,,Orsanco,Orsanco,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64430,ORTHEZ,64300,ORTHEZ,,43.4943173399,-0.779874853399,430,,Orthez,Orthez,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64430,ORTHEZ,64300,ORTHEZ,STE SUZANNE,43.4943173399,-0.779874853399,430,,Orthez,Orthez,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64431,OS MARSILLON,64150,OS MARSILLON,,43.3890752479,-0.613461851871,431,,Os-Marsillon,Os-Marsillon,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64432,OSSAS SUHARE,64470,OSSAS SUHARE,,43.138521728,-0.907399706648,432,,Ossas-Suhare,Ossas-Suhare,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64433,OSSE EN ASPE,64490,OSSE EN ASPE,,43.0104152948,-0.669602106566,433,,Osse-en-Aspe,Osse-en-Aspe,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64434,OSSENX,64190,OSSENX,,43.3765462273,-0.80836547403,434,,Ossenx,Ossenx,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64435,OSSERAIN RIVAREYTE,64390,OSSERAIN RIVAREYTE,,43.3723824956,-0.953308055466,435,,Osserain-Rivareyte,Osserain-Rivareyte,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64436,OSSES,64780,OSSES,,43.242011308,-1.27505154323,436,,Ossès,Ossès,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64437,OSTABAT ASME,64120,OSTABAT ASME,,43.2605319233,-1.06809217449,437,,Ostabat-Asme,Ostabat-Asme,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64438,OUILLON,64160,OUILLON,,43.326993012,-0.227554610182,438,,Ouillon,Ouillon,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64439,OUSSE,64320,OUSSE,,43.2791521961,-0.267307850969,439,,Ousse,Ousse,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64440,OZENX MONTESTRUCQ,64300,OZENX MONTESTRUCQ,,43.434296626,-0.807501200009,440,,Ozenx-Montestrucq,Ozenx-Montestrucq,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64440,OZENX MONTESTRUCQ,64300,OZENX MONTESTRUCQ,MONTESTRUCQ,43.434296626,-0.807501200009,440,,Ozenx-Montestrucq,Ozenx-Montestrucq,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64441,PAGOLLE,64120,PAGOLLE,,43.2250651513,-1.00007203267,441,,Pagolle,Pagolle,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64442,PARBAYSE,64360,PARBAYSE,,43.3329548199,-0.549856093173,442,,Parbayse,Parbayse,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64443,PARDIES,64150,PARDIES,,43.3669144852,-0.586119410196,443,,Pardies,Pardies,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64444,PARDIES PIETAT,64800,PARDIES PIETAT,,43.2137224883,-0.311095758037,444,,Pardies-Piétat,Pardies-Piétat,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64445,PAU,64000,PAU,,43.3200189773,-0.350337918181,445,,Pau,Pau,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64446,PEYRELONGUE ABOS,64350,PEYRELONGUE ABOS,,43.4185756604,-0.101559098685,446,,Peyrelongue-Abos,Peyrelongue-Abos,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64447,PIETS PLASENCE MOUSTROU,64410,PIETS PLASENCE MOUSTROU,,43.527482932,-0.504651341722,447,,Piets-Plasence-Moustrou,Piets-Plasence-Moustrou,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64448,POEY DE LESCAR,64230,POEY DE LESCAR,,43.3557464475,-0.457046076502,448,,Poey-de-Lescar,Poey-de-Lescar,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64449,POEY D OLORON,64400,POEY D OLORON,,43.2467550092,-0.663213521181,449,,Poey-d'Oloron,Poey-d'Oloron,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64450,POMPS,64370,POMPS,,43.4857880693,-0.553202333963,450,,Pomps,Pomps,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64451,PONSON DEBAT POUTS,64460,PONSON DEBAT POUTS,,43.3304533156,-0.0494811878674,451,,Ponson-Debat-Pouts,Ponson-Debat-Pouts,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64452,PONSON DESSUS,64460,PONSON DESSUS,,43.3055844611,-0.0546726184157,452,,Ponson-Dessus,Ponson-Dessus,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64453,PONTACQ,64530,PONTACQ,,43.1983211463,-0.103500734217,453,,Pontacq,Pontacq,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64454,PONTIACQ VIELLEPINTE,64460,PONTIACQ VIELLEPINTE,,43.3632011271,-0.0568079986553,454,,Pontiacq-Viellepinte,Pontiacq-Viellepinte,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64455,PORTET,64330,PORTET,,43.5781162021,-0.186908454571,455,,Portet,Portet,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64456,POULIACQ,64410,POULIACQ,,43.5244222238,-0.355722297241,456,,Pouliacq,Pouliacq,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64457,POURSIUGUES BOUCOUE,64410,POURSIUGUES BOUCOUE,,43.5541576599,-0.378415416336,457,,Poursiugues-Boucoue,Poursiugues-Boucoue,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64458,PRECHACQ JOSBAIG,64190,PRECHACQ JOSBAIG,,43.2585423682,-0.732506093638,458,,Préchacq-Josbaig,Préchacq-Josbaig,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64459,PRECHACQ NAVARRENX,64190,PRECHACQ NAVARRENX,,43.2799895089,-0.705497036287,459,,Préchacq-Navarrenx,Préchacq-Navarrenx,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64460,PRECILHON,64400,PRECILHON,,43.1957778723,-0.57297399961,460,,Précilhon,Précilhon,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64461,PUYOO,64270,PUYOO,,43.5360452479,-0.904449154165,461,,Puyoô,Puyoô,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64462,RAMOUS,64270,RAMOUS,,43.5249836797,-0.880069037681,462,,Ramous,Ramous,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64463,REBENACQ,64260,REBENACQ,,43.1559802063,-0.394552598132,463,,Rébénacq,Rébénacq,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64464,RIBARROUY,64330,RIBARROUY,,43.5217745514,-0.276171114152,464,,Ribarrouy,Ribarrouy,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64465,RIUPEYROUS,64160,RIUPEYROUS,,43.4007685011,-0.231595867903,465,,Riupeyrous,Riupeyrous,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64466,RIVEHAUTE,64190,RIVEHAUTE,,43.3432256931,-0.85985108204,466,,Rivehaute,Rivehaute,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64467,RONTIGNON,64110,RONTIGNON,,43.2476646594,-0.339294477978,467,,Rontignon,Rontignon,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64468,ROQUIAGUE,64130,ROQUIAGUE,,43.1855341651,-0.84137929952,468,,Roquiague,Roquiague,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64469,ST ABIT,64800,ST ABIT,,43.2039364358,-0.300077200774,469,,Saint-Abit,Saint-Abit,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64470,ST ARMOU,64160,ST ARMOU,,43.4075976721,-0.306568669389,470,,Saint-Armou,Saint-Armou,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64471,ST BOES,64300,ST BOES,,43.5290119415,-0.806166229833,471,,Saint-Boès,Saint-Boès,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64472,ST CASTIN,64160,ST CASTIN,,43.3743065004,-0.309504991301,472,,Saint-Castin,Saint-Castin,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64473,STE COLOME,64260,STE COLOME,,43.1097407715,-0.381940819142,473,,Sainte-Colome,Sainte-Colome,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64474,ST DOS,64270,ST DOS,,43.4732115273,-1.02350466604,474,,Saint-Dos,Saint-Dos,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64475,STE ENGRACE,64560,STE ENGRACE,,42.9943132999,-0.839892143116,475,,Sainte-Engrâce,Sainte-Engrâce,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64476,ST ESTEBEN,64640,ST ESTEBEN,,43.3288407635,-1.21620474075,476,,Saint-Esteben,Saint-Esteben,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64477,ST ETIENNE DE BAIGORRY,64430,ST ETIENNE DE BAIGORRY,,43.179022936,-1.35105102148,477,,Saint-Étienne-de-Baïgorry,Saint-Étienne-de-Baïgorry,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64478,ST FAUST,64110,ST FAUST,,43.270186835,-0.44796457911,478,,Saint-Faust,Saint-Faust,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64479,ST GIRONS EN BEARN,64300,ST GIRONS EN BEARN,,43.5505790546,-0.823806052996,479,,Saint-Girons-en-Béarn,Saint-Girons-en-Béarn,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64480,ST GLADIE ARRIVE MUNEIN,64390,ST GLADIE ARRIVE MUNEIN,,43.3714422062,-0.92287013321,480,,Saint-Gladie-Arrive-Munein,Saint-Gladie-Arrive-Munein,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64481,ST GOIN,64400,ST GOIN,,43.2312012156,-0.725099267035,481,,Saint-Goin,Saint-Goin,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64482,ST JAMMES,64160,ST JAMMES,,43.3614930531,-0.253048695967,482,,Saint-Jammes,Saint-Jammes,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64483,ST JEAN DE LUZ,64500,ST JEAN DE LUZ,,43.3947886873,-1.63099021573,483,,Saint-Jean-de-Luz,Saint-Jean-de-Luz,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64484,ST JEAN LE VIEUX,64220,ST JEAN LE VIEUX,,43.1714110881,-1.20732334378,484,,Saint-Jean-le-Vieux,Saint-Jean-le-Vieux,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64485,ST JEAN PIED DE PORT,64220,ST JEAN PIED DE PORT,,43.1590812269,-1.23540759617,485,,Saint-Jean-Pied-de-Port,Saint-Jean-Pied-de-Port,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64486,ST JEAN POUDGE,64330,ST JEAN POUDGE,,43.5282173941,-0.185319400051,486,,Saint-Jean-Poudge,Saint-Jean-Poudge,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64487,ST JUST IBARRE,64120,ST JUST IBARRE,,43.1685351428,-1.04795747058,487,,Saint-Just-Ibarre,Saint-Just-Ibarre,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64488,ST LAURENT BRETAGNE,64160,ST LAURENT BRETAGNE,,43.382550943,-0.205102257997,488,,Saint-Laurent-Bretagne,Saint-Laurent-Bretagne,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64489,ST MARTIN D ARBEROUE,64640,ST MARTIN D ARBEROUE,,43.3433653939,-1.18372407608,489,,Saint-Martin-d'Arberoue,Saint-Martin-d'Arberoue,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64490,ST MARTIN D ARROSSA,64780,ST MARTIN D ARROSSA,,43.2283517771,-1.31623061822,490,,Saint-Martin-d'Arrossa,Saint-Martin-d'Arrossa,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64490,ST MARTIN D ARROSSA,64780,ST MARTIN D ARROSSA,EYHARCE,43.2283517771,-1.31623061822,490,,Saint-Martin-d'Arrossa,Saint-Martin-d'Arrossa,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64491,ST MEDARD,64370,ST MEDARD,,43.5349414863,-0.587158607192,491,,Saint-Médard,Saint-Médard,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64492,ST MICHEL,64220,ST MICHEL,,43.0893274033,-1.23447352127,492,,Saint-Michel,Saint-Michel,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64493,ST PALAIS,64120,ST PALAIS,,43.3153764046,-1.0377471932,493,,Saint-Palais,Saint-Palais,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64494,ST PE DE LEREN,64270,ST PE DE LEREN,,43.4909560582,-1.03877508063,494,,Saint-Pé-de-Léren,Saint-Pé-de-Léren,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64495,ST PEE SUR NIVELLE,64310,ST PEE SUR NIVELLE,,43.3564883514,-1.55534617636,495,,Saint-Pée-sur-Nivelle,Saint-Pée-sur-Nivelle,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64496,ST PIERRE D IRUBE,64990,ST PIERRE D IRUBE,,43.462632374,-1.44207882507,496,,Saint-Pierre-d'Irube,Saint-Pierre-d'Irube,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64498,ST VINCENT,64800,ST VINCENT,,43.1610140979,-0.159752736727,498,,Saint-Vincent,Saint-Vincent,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64499,SALIES DE BEARN,64270,SALIES DE BEARN,,43.4683239252,-0.917789447505,499,,Salies-de-Béarn,Salies-de-Béarn,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64500,SALLES MONGISCARD,64300,SALLES MONGISCARD,,43.4855052626,-0.839260147941,500,,Salles-Mongiscard,Salles-Mongiscard,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64501,SALLESPISSE,64300,SALLESPISSE,,43.5211031134,-0.718617217756,501,,Sallespisse,Sallespisse,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64502,SAMES,64520,SAMES,,43.527482395,-1.16790395141,502,,Sames,Sames,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64503,SAMSONS LION,64350,SAMSONS LION,,43.4340604062,-0.119778196465,503,,Samsons-Lion,Samsons-Lion,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64504,SARE,64310,SARE,,43.296231493,-1.58652920554,504,,Sare,Sare,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64505,SARPOURENX,64300,SARPOURENX,,43.4547704018,-0.715454998778,505,,Sarpourenx,Sarpourenx,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64506,SARRANCE,64490,SARRANCE,,43.0446957599,-0.575631142614,506,,Sarrance,Sarrance,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64507,SAUBOLE,64420,SAUBOLE,,43.3105788075,-0.112194249958,507,,Saubole,Saubole,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64508,SAUCEDE,64400,SAUCEDE,,43.2656744342,-0.669441853203,508,,Saucède,Saucède,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64509,SAUGUIS ST ETIENNE,64470,SAUGUIS ST ETIENNE,,43.1581269508,-0.873283147655,509,,Sauguis-Saint-Étienne,Sauguis-Saint-Étienne,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64510,SAULT DE NAVAILLES,64300,SAULT DE NAVAILLES,,43.5406415198,-0.676491082021,510,,Sault-de-Navailles,Sault-de-Navailles,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64511,SAUVAGNON,64230,SAUVAGNON,,43.3933131319,-0.389091623871,511,,Sauvagnon,Sauvagnon,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64512,SAUVELADE,64150,SAUVELADE,,43.3913713418,-0.716491969468,512,,Sauvelade,Sauvelade,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64513,SAUVETERRE DE BEARN,64390,SAUVETERRE DE BEARN,,43.4115819423,-0.931810105485,513,,Sauveterre-de-Béarn,Sauveterre-de-Béarn,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64514,SEBY,64410,SEBY,,43.479615245,-0.397462262821,514,,Séby,Séby,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64515,SEDZE MAUBECQ,64160,SEDZE MAUBECQ,,43.3560996127,-0.120159115391,515,,Sedze-Maubecq,Sedze-Maubecq,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64516,SEDZERE,64160,SEDZERE,,43.3402761138,-0.180181129359,516,,Sedzère,Sedzère,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64517,SEMEACQ BLACHON,64350,SEMEACQ BLACHON,,43.5034837711,-0.107040592287,517,,Séméacq-Blachon,Séméacq-Blachon,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64518,SENDETS,64320,SENDETS,,43.3023058183,-0.265755496231,518,,Sendets,Sendets,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64519,SERRES CASTET,64121,SERRES CASTET,,43.3796823116,-0.366642676872,519,,Serres-Castet,Serres-Castet,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64520,SERRES MORLAAS,64160,SERRES MORLAAS,,43.32289558,-0.261497500044,520,,Serres-Morlaàs,Serres-Morlaàs,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64521,SERRES STE MARIE,64170,SERRES STE MARIE,,43.4169790488,-0.558512355562,521,,Serres-Sainte-Marie,Serres-Sainte-Marie,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64522,SEVIGNACQ MEYRACQ,64260,SEVIGNACQ MEYRACQ,,43.1376731477,-0.385672446851,522,,Sévignacq-Meyracq,Sévignacq-Meyracq,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64523,SEVIGNACQ,64160,SEVIGNACQ,,43.4525350572,-0.260345256329,523,,Sévignacq,Sévignacq,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64524,SIMACOURBE,64350,SIMACOURBE,,43.4470512005,-0.167424687354,524,,Simacourbe,Simacourbe,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64525,SIROS,64230,SIROS,,43.3431539318,-0.489701963125,525,,Siros,Siros,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64526,SOUMOULOU,64420,SOUMOULOU,,43.2616113493,-0.192918125557,526,,Soumoulou,Soumoulou,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64527,SOURAIDE,64250,SOURAIDE,,43.3438919475,-1.48818031049,527,,Souraïde,Souraïde,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64528,SUHESCUN,64780,SUHESCUN,,43.2343253939,-1.19438205249,528,,Suhescun,Suhescun,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64529,SUS,64190,SUS,,43.2913255988,-0.781603331422,529,,Sus,Sus,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64530,SUSMIOU,64190,SUSMIOU,,43.3117294131,-0.780889188259,530,,Susmiou,Susmiou,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64531,TABAILLE USQUAIN,64190,TABAILLE USQUAIN,,43.3612997539,-0.890276104739,531,,Tabaille-Usquain,Tabaille-Usquain,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64532,TADOUSSE USSAU,64330,TADOUSSE USSAU,,43.5468245822,-0.192686671692,532,,Tadousse-Ussau,Tadousse-Ussau,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64533,TARDETS SORHOLUS,64470,TARDETS SORHOLUS,,43.1284037403,-0.839978372656,533,,Tardets-Sorholus,Tardets-Sorholus,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64534,TARON SADIRAC VIELLENAVE,64330,TARON SADIRAC VIELLENAVE,,43.5121931243,-0.244633052278,534,,Taron-Sadirac-Viellenave,Taron-Sadirac-Viellenave,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64535,TARSACQ,64360,TARSACQ,,43.3557575881,-0.531704585094,535,,Tarsacq,Tarsacq,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64536,THEZE,64450,THEZE,,43.4749565308,-0.344667086414,536,,Thèze,Thèze,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64537,TROIS VILLES,64470,TROIS VILLES,,43.1398804842,-0.865595669268,537,,Trois-Villes,Trois-Villes,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64538,UHART CIZE,64220,UHART CIZE,,43.1285879857,-1.2505576735,538,,Uhart-Cize,Uhart-Cize,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64539,UHART MIXE,64120,UHART MIXE,,43.273744664,-1.0182798978,539,,Uhart-Mixe,Uhart-Mixe,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64540,URCUIT,64990,URCUIT,,43.4851399002,-1.35045650645,540,,Urcuit,Urcuit,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64541,URDES,64370,URDES,,43.4397491635,-0.578016104788,541,,Urdès,Urdès,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64542,URDOS,64490,URDOS,,42.8354449559,-0.535288457262,542,,Urdos,Urdos,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64543,UREPEL,64430,UREPEL,,43.0588257518,-1.40540454935,543,,Urepel,Urepel,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64544,UROST,64160,UROST,,43.3400983469,-0.150921233415,544,,Urost,Urost,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64545,URRUGNE,64122,URRUGNE,,43.3497136026,-1.69722838309,545,,Urrugne,Urrugne,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64545,URRUGNE,64700,URRUGNE,BEHOBIE,43.3497136026,-1.69722838309,545,,Urrugne,Urrugne,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64546,URT,64240,URT,,43.4830789659,-1.28285405213,546,,Urt,Urt,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64547,USTARITZ,64480,USTARITZ,,43.401807303,-1.4751898518,547,,Ustaritz,Ustaritz,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64548,UZAN,64370,UZAN,,43.4725068198,-0.502354827029,548,,Uzan,Uzan,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64549,UZEIN,64230,UZEIN,,43.3960752682,-0.432656657054,549,,Uzein,Uzein,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64550,UZOS,64110,UZOS,,43.2607603254,-0.349153042882,550,,Uzos,Uzos,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64551,VERDETS,64400,VERDETS,,43.232186115,-0.642308183699,551,,Verdets,Verdets,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64552,VIALER,64330,VIALER,,43.508736032,-0.178980532106,552,,Vialer,Vialer,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64554,VIELLENAVE D ARTHEZ,64170,VIELLENAVE D ARTHEZ,,43.412202813,-0.47673612803,554,,Viellenave-d'Arthez,Viellenave-d'Arthez,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64555,VIELLENAVE DE NAVARRENX,64190,VIELLENAVE DE NAVARRENX,,43.3365594073,-0.807320933148,555,,Viellenave-de-Navarrenx,Viellenave-de-Navarrenx,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64556,VIELLESEGURE,64150,VIELLESEGURE,,43.3559621275,-0.702432499401,556,,Vielleségure,Vielleségure,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64557,VIGNES,64410,VIGNES,,43.5230729966,-0.410534963551,557,,Vignes,Vignes,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64558,VILLEFRANQUE,64990,VILLEFRANQUE,,43.4421237778,-1.44766830875,558,,Villefranque,Villefranque,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64559,VIODOS ABENSE DE BAS,64130,VIODOS ABENSE DE BAS,,43.2476033104,-0.896806531762,559,,Viodos-Abense-de-Bas,Viodos-Abense-de-Bas,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
64560,VIVEN,64450,VIVEN,,43.4623560405,-0.375213701374,560,,Viven,Viven,64,Pyrénées-Atlantiques,75,Nouvelle-Aquitaine
65001,ADAST,65260,ADAST,,42.9753854058,-0.0764342583386,1,,Adast,Adast,65,Hautes-Pyrénées,76,Occitanie
65002,ADE,65100,ADE,,43.1415839472,-0.0260255659904,2,,Adé,Adé,65,Hautes-Pyrénées,76,Occitanie
65003,ADERVIELLE POUCHERGUES,65240,ADERVIELLE POUCHERGUES,,42.8017280148,0.389922766373,3,,Adervielle-Pouchergues,Adervielle-Pouchergues,65,Hautes-Pyrénées,76,Occitanie
65003,ADERVIELLE POUCHERGUES,65240,ADERVIELLE POUCHERGUES,POUCHERGUES,42.8017280148,0.389922766373,3,,Adervielle-Pouchergues,Adervielle-Pouchergues,65,Hautes-Pyrénées,76,Occitanie
65004,AGOS VIDALOS,65400,AGOS VIDALOS,,43.0397405633,-0.0754111444316,4,,Agos-Vidalos,Agos-Vidalos,65,Hautes-Pyrénées,76,Occitanie
65005,ALLIER,65360,ALLIER,,43.1800492737,0.126791065309,5,,Allier,Allier,65,Hautes-Pyrénées,76,Occitanie
65006,ANCIZAN,65440,ANCIZAN,,42.8993345679,0.283796847933,6,,Ancizan,Ancizan,65,Hautes-Pyrénées,76,Occitanie
65007,ANDREST,65390,ANDREST,,43.3162323845,0.0661292310469,7,,Andrest,Andrest,65,Hautes-Pyrénées,76,Occitanie
65009,ANERES,65150,ANERES,,43.0760669312,0.464444450073,9,,Anères,Anères,65,Hautes-Pyrénées,76,Occitanie
65010,ANGOS,65690,ANGOS,,43.1986038545,0.161492581105,10,,Angos,Angos,65,Hautes-Pyrénées,76,Occitanie
65011,LES ANGLES,65100,LES ANGLES,,43.0840991852,0.00392016448307,11,Les,Angles,Les Angles,65,Hautes-Pyrénées,76,Occitanie
65012,ANLA,65370,ANLA,,43.0053698793,0.591471549126,12,,Anla,Anla,65,Hautes-Pyrénées,76,Occitanie
65013,ANSOST,65140,ANSOST,,43.4324735802,0.109776461821,13,,Ansost,Ansost,65,Hautes-Pyrénées,76,Occitanie
65014,ANTICHAN,65370,ANTICHAN,,42.9924688915,0.583832200787,14,,Antichan,Antichan,65,Hautes-Pyrénées,76,Occitanie
65015,ANTIN,65220,ANTIN,,43.3324246014,0.290105540606,15,,Antin,Antin,65,Hautes-Pyrénées,76,Occitanie
65016,ANTIST,65200,ANTIST,,43.1198806357,0.135249572657,16,,Antist,Antist,65,Hautes-Pyrénées,76,Occitanie
65017,ARAGNOUET,65170,ARAGNOUET,,42.784224156,0.178165691036,17,,Aragnouet,Aragnouet,65,Hautes-Pyrénées,76,Occitanie
65018,ARBEOST,65560,ARBEOST,,42.9800099474,-0.274398509458,18,,Arbéost,Arbéost,65,Hautes-Pyrénées,76,Occitanie
65019,ARCIZAC ADOUR,65360,ARCIZAC ADOUR,,43.1496570834,0.0906664946254,19,,Arcizac-Adour,Arcizac-Adour,65,Hautes-Pyrénées,76,Occitanie
65020,ARCIZAC EZ ANGLES,65100,ARCIZAC EZ ANGLES,,43.0961049437,0.0150289813237,20,,Arcizac-ez-Angles,Arcizac-ez-Angles,65,Hautes-Pyrénées,76,Occitanie
65021,ARCIZANS AVANT,65400,ARCIZANS AVANT,,42.9591847371,-0.119079504665,21,,Arcizans-Avant,Arcizans-Avant,65,Hautes-Pyrénées,76,Occitanie
65022,ARCIZANS DESSUS,65400,ARCIZANS DESSUS,,42.9960153605,-0.160782379853,22,,Arcizans-Dessus,Arcizans-Dessus,65,Hautes-Pyrénées,76,Occitanie
65023,ARDENGOST,65240,ARDENGOST,,42.9275389079,0.414150481685,23,,Ardengost,Ardengost,65,Hautes-Pyrénées,76,Occitanie
65024,ARGELES BAGNERES,65200,ARGELES BAGNERES,,43.0917474714,0.196734819269,24,,Argelès-Bagnères,Argelès-Bagnères,65,Hautes-Pyrénées,76,Occitanie
65025,ARGELES GAZOST,65400,ARGELES GAZOST,,43.006001672,-0.0963626224791,25,,Argelès-Gazost,Argelès-Gazost,65,Hautes-Pyrénées,76,Occitanie
65026,ARIES ESPENAN,65230,ARIES ESPENAN,,43.2757729573,0.53011685665,26,,Aries-Espénan,Aries-Espénan,65,Hautes-Pyrénées,76,Occitanie
65028,ARNE,65670,ARNE,,43.1810099467,0.492692454567,28,,Arné,Arné,65,Hautes-Pyrénées,76,Occitanie
65029,ARRAS EN LAVEDAN,65400,ARRAS EN LAVEDAN,,42.9675038855,-0.143830716437,29,,Arras-en-Lavedan,Arras-en-Lavedan,65,Hautes-Pyrénées,76,Occitanie
65031,ARREAU,65240,ARREAU,,42.9142857296,0.338038988881,31,,Arreau,Arreau,65,Hautes-Pyrénées,76,Occitanie
65032,ARRENS MARSOUS,65400,ARRENS MARSOUS,,42.9104049352,-0.260439991296,32,,Arrens-Marsous,Arrens-Marsous,65,Hautes-Pyrénées,76,Occitanie
65032,ARRENS MARSOUS,65400,ARRENS MARSOUS,MARSOUS,42.9104049352,-0.260439991296,32,,Arrens-Marsous,Arrens-Marsous,65,Hautes-Pyrénées,76,Occitanie
65033,ARRODETS EZ ANGLES,65100,ARRODETS EZ ANGLES,,43.0705428308,0.0409733731908,33,,Arrodets-ez-Angles,Arrodets-ez-Angles,65,Hautes-Pyrénées,76,Occitanie
65034,ARRODETS,65130,ARRODETS,,43.0305965563,0.281033647397,34,,Arrodets,Arrodets,65,Hautes-Pyrénées,76,Occitanie
65035,ARTAGNAN,65500,ARTAGNAN,,43.4068464374,0.0826210970042,35,,Artagnan,Artagnan,65,Hautes-Pyrénées,76,Occitanie
65036,ARTALENS SOUIN,65400,ARTALENS SOUIN,,42.9782578727,-0.0414043062586,36,,Artalens-Souin,Artalens-Souin,65,Hautes-Pyrénées,76,Occitanie
65037,ARTIGUEMY,65130,ARTIGUEMY,,43.1262809012,0.246205576137,37,,Artiguemy,Artiguemy,65,Hautes-Pyrénées,76,Occitanie
65038,ARTIGUES,65100,ARTIGUES,,43.0732762022,-0.00153356995654,38,,Artigues,Artigues,65,Hautes-Pyrénées,76,Occitanie
65039,ASPIN AURE,65240,ASPIN AURE,,42.936099418,0.327349916156,39,,Aspin-Aure,Aspin-Aure,65,Hautes-Pyrénées,76,Occitanie
65040,ASPIN EN LAVEDAN,65100,ASPIN EN LAVEDAN,,43.0749440828,-0.0492041066704,40,,Aspin-en-Lavedan,Aspin-en-Lavedan,65,Hautes-Pyrénées,76,Occitanie
65041,ASQUE,65130,ASQUE,,43.0249620557,0.252854845671,41,,Asque,Asque,65,Hautes-Pyrénées,76,Occitanie
65042,ASTE,65200,ASTE,,43.0169757045,0.216169122937,42,,Asté,Asté,65,Hautes-Pyrénées,76,Occitanie
65043,ASTUGUE,65200,ASTUGUE,,43.0973955816,0.072326458171,43,,Astugue,Astugue,65,Hautes-Pyrénées,76,Occitanie
65044,AUBAREDE,65350,AUBAREDE,,43.2705496988,0.249527710509,44,,Aubarède,Aubarède,65,Hautes-Pyrénées,76,Occitanie
65045,AUCUN,65400,AUCUN,,42.9857652582,-0.201428304348,45,,Aucun,Aucun,65,Hautes-Pyrénées,76,Occitanie
65046,AULON,65240,AULON,,42.8566915829,0.263308131503,46,,Aulon,Aulon,65,Hautes-Pyrénées,76,Occitanie
65047,AUREILHAN,65800,AUREILHAN,,43.2497030503,0.103640168515,47,,Aureilhan,Aureilhan,65,Hautes-Pyrénées,76,Occitanie
65048,AURENSAN,65390,AURENSAN,,43.3062200799,0.0955012093615,48,,Aurensan,Aurensan,65,Hautes-Pyrénées,76,Occitanie
65049,AURIEBAT,65700,AURIEBAT,,43.4992440945,0.0882821803633,49,,Auriébat,Auriébat,65,Hautes-Pyrénées,76,Occitanie
65050,AVAJAN,65240,AVAJAN,,42.8474630152,0.402289905692,50,,Avajan,Avajan,65,Hautes-Pyrénées,76,Occitanie
65051,AVENTIGNAN,65660,AVENTIGNAN,,43.061616187,0.523748074875,51,,Aventignan,Aventignan,65,Hautes-Pyrénées,76,Occitanie
65052,AVERAN,65380,AVERAN,,43.135917391,-0.00268338345986,52,,Averan,Averan,65,Hautes-Pyrénées,76,Occitanie
65053,AVEUX,65370,AVEUX,,43.0085622623,0.563703514189,53,,Aveux,Aveux,65,Hautes-Pyrénées,76,Occitanie
65054,AVEZAC PRAT LAHITTE,65130,AVEZAC PRAT LAHITTE,,43.0649077619,0.3352681161,54,,Avezac-Prat-Lahitte,Avezac-Prat-Lahitte,65,Hautes-Pyrénées,76,Occitanie
65054,AVEZAC PRAT LAHITTE,65130,AVEZAC PRAT LAHITTE,LAHITTE,43.0649077619,0.3352681161,54,,Avezac-Prat-Lahitte,Avezac-Prat-Lahitte,65,Hautes-Pyrénées,76,Occitanie
65055,AYROS ARBOUIX,65400,AYROS ARBOUIX,,43.0013594606,-0.0676362867002,55,,Ayros-Arbouix,Ayros-Arbouix,65,Hautes-Pyrénées,76,Occitanie
65056,AYZAC OST,65400,AYZAC OST,,43.0227077194,-0.0922337556025,56,,Ayzac-Ost,Ayzac-Ost,65,Hautes-Pyrénées,76,Occitanie
65057,AZEREIX,65380,AZEREIX,,43.208173501,-0.02380994852,57,,Azereix,Azereix,65,Hautes-Pyrénées,76,Occitanie
65058,AZET,65170,AZET,,42.7786994405,0.359541705229,58,,Azet,Azet,65,Hautes-Pyrénées,76,Occitanie
65059,BAGNERES DE BIGORRE,65200,BAGNERES DE BIGORRE,,42.9758668859,0.132640694318,59,,Bagnères-de-Bigorre,Bagnères-de-Bigorre,65,Hautes-Pyrénées,76,Occitanie
65059,BAGNERES DE BIGORRE,65200,BAGNERES DE BIGORRE,LA MONGIE,42.9758668859,0.132640694318,59,,Bagnères-de-Bigorre,Bagnères-de-Bigorre,65,Hautes-Pyrénées,76,Occitanie
65059,BAGNERES DE BIGORRE,65710,BAGNERES DE BIGORRE,LESPONNE,42.9758668859,0.132640694318,59,,Bagnères-de-Bigorre,Bagnères-de-Bigorre,65,Hautes-Pyrénées,76,Occitanie
65060,BANIOS,65200,BANIOS,,43.0387732941,0.22854718648,60,,Banios,Banios,65,Hautes-Pyrénées,76,Occitanie
65061,BARBACHEN,65140,BARBACHEN,,43.4291256681,0.12612843943,61,,Barbachen,Barbachen,65,Hautes-Pyrénées,76,Occitanie
65062,BARBAZAN DEBAT,65690,BARBAZAN DEBAT,,43.201851966,0.129955186604,62,,Barbazan-Debat,Barbazan-Debat,65,Hautes-Pyrénées,76,Occitanie
65063,BARBAZAN DESSUS,65360,BARBAZAN DESSUS,,43.1640011198,0.143871058767,63,,Barbazan-Dessus,Barbazan-Dessus,65,Hautes-Pyrénées,76,Occitanie
65064,BAREILLES,65240,BAREILLES,,42.8909172311,0.436736458562,64,,Bareilles,Bareilles,65,Hautes-Pyrénées,76,Occitanie
65065,BARLEST,65100,BARLEST,,43.1500535146,-0.0958058355214,65,,Barlest,Barlest,65,Hautes-Pyrénées,76,Occitanie
65066,BARRANCOUEU,65240,BARRANCOUEU,,42.9099558733,0.325530555509,66,,Barrancoueu,Barrancoueu,65,Hautes-Pyrénées,76,Occitanie
65067,BARRY,65380,BARRY,,43.1386668818,0.0200105013053,67,,Barry,Barry,65,Hautes-Pyrénées,76,Occitanie
65068,BARTHE,65230,BARTHE,,43.287241787,0.477330743407,68,,Barthe,Barthe,65,Hautes-Pyrénées,76,Occitanie
65069,LA BARTHE DE NESTE,65250,LA BARTHE DE NESTE,,43.0794759843,0.382949945071,69,La,Barthe-de-Neste,La Barthe-de-Neste,65,Hautes-Pyrénées,76,Occitanie
65070,BARTRES,65100,BARTRES,,43.1330236195,-0.0546585429956,70,,Bartrès,Bartrès,65,Hautes-Pyrénées,76,Occitanie
65071,BATSERE,65130,BATSERE,,43.059770593,0.284319513747,71,,Batsère,Batsère,65,Hautes-Pyrénées,76,Occitanie
65072,BAZET,65460,BAZET,,43.2907000893,0.0755201253689,72,,Bazet,Bazet,65,Hautes-Pyrénées,76,Occitanie
65073,BAZILLAC,65140,BAZILLAC,,43.3599640684,0.110946825712,73,,Bazillac,Bazillac,65,Hautes-Pyrénées,76,Occitanie
65074,BAZORDAN,65670,BAZORDAN,,43.2217370669,0.547780218437,74,,Bazordan,Bazordan,65,Hautes-Pyrénées,76,Occitanie
65075,BAZUS AURE,65170,BAZUS AURE,,42.8564526243,0.345785536911,75,,Bazus-Aure,Bazus-Aure,65,Hautes-Pyrénées,76,Occitanie
65076,BAZUS NESTE,65250,BAZUS NESTE,,43.0313000488,0.387437561885,76,,Bazus-Neste,Bazus-Neste,65,Hautes-Pyrénées,76,Occitanie
65077,BEAUCENS,65400,BEAUCENS,,42.9521147799,0.0226628621288,77,,Beaucens,Beaucens,65,Hautes-Pyrénées,76,Occitanie
65078,BEAUDEAN,65710,BEAUDEAN,,42.9933681218,0.137569111909,78,,Beaudéan,Beaudéan,65,Hautes-Pyrénées,76,Occitanie
65079,BEGOLE,65190,BEGOLE,,43.1596154692,0.319511225672,79,,Bégole,Bégole,65,Hautes-Pyrénées,76,Occitanie
65080,BENAC,65380,BENAC,,43.1528922473,0.0296198137787,80,,Bénac,Bénac,65,Hautes-Pyrénées,76,Occitanie
65081,BENQUE,65130,BENQUE,,43.0967455334,0.279787256256,81,,Benqué-Molère,Benqué-Molère,65,Hautes-Pyrénées,76,Occitanie
65082,BERBERUST LIAS,65100,BERBERUST LIAS,,43.0347901584,-0.0328719658346,82,,Berbérust-Lias,Berbérust-Lias,65,Hautes-Pyrénées,76,Occitanie
65083,BERNAC DEBAT,65360,BERNAC DEBAT,,43.1695672176,0.110496542104,83,,Bernac-Debat,Bernac-Debat,65,Hautes-Pyrénées,76,Occitanie
65084,BERNAC DESSUS,65360,BERNAC DESSUS,,43.1546695111,0.12835220741,84,,Bernac-Dessus,Bernac-Dessus,65,Hautes-Pyrénées,76,Occitanie
65085,BERNADETS DEBAT,65220,BERNADETS DEBAT,,43.3513205406,0.318165574549,85,,Bernadets-Debat,Bernadets-Debat,65,Hautes-Pyrénées,76,Occitanie
65086,BERNADETS DESSUS,65190,BERNADETS DESSUS,,43.2125027639,0.295205829649,86,,Bernadets-Dessus,Bernadets-Dessus,65,Hautes-Pyrénées,76,Occitanie
65087,BERTREN,65370,BERTREN,,42.9991094379,0.617157070005,87,,Bertren,Bertren,65,Hautes-Pyrénées,76,Occitanie
65088,BETBEZE,65230,BETBEZE,,43.2898534376,0.572647104239,88,,Betbèze,Betbèze,65,Hautes-Pyrénées,76,Occitanie
65089,BETPOUEY,65120,BETPOUEY,,42.8602756979,0.0652448602604,89,,Betpouey,Betpouey,65,Hautes-Pyrénées,76,Occitanie
65090,BETPOUY,65230,BETPOUY,,43.2719488626,0.45729417903,90,,Betpouy,Betpouy,65,Hautes-Pyrénées,76,Occitanie
65091,BETTES,65130,BETTES,,43.0802858213,0.219226356205,91,,Bettes,Bettes,65,Hautes-Pyrénées,76,Occitanie
65092,BEYREDE JUMET,65410,BEYREDE JUMET,,42.9554911892,0.338191810154,92,,Beyrède-Jumet,Beyrède-Jumet,65,Hautes-Pyrénées,76,Occitanie
65093,BIZE,65150,BIZE,,43.0352737792,0.462210784813,93,,Bize,Bize,65,Hautes-Pyrénées,76,Occitanie
65094,BIZOUS,65150,BIZOUS,,43.0645413689,0.439986702814,94,,Bizous,Bizous,65,Hautes-Pyrénées,76,Occitanie
65095,BONNEFONT,65220,BONNEFONT,,43.2439914702,0.344903520312,95,,Bonnefont,Bonnefont,65,Hautes-Pyrénées,76,Occitanie
65096,BONNEMAZON,65130,BONNEMAZON,,43.1084728845,0.250672951665,96,,Bonnemazon,Bonnemazon,65,Hautes-Pyrénées,76,Occitanie
65097,BONREPOS,65330,BONREPOS,,43.1931725535,0.381397370482,97,,Bonrepos,Bonrepos,65,Hautes-Pyrénées,76,Occitanie
65098,BOO SILHEN,65400,BOO SILHEN,,43.019031119,-0.0730896930851,98,,Boô-Silhen,Boô-Silhen,65,Hautes-Pyrénées,76,Occitanie
65099,BORDERES LOURON,65590,BORDERES LOURON,,42.8676970923,0.416690191608,99,,Bordères-Louron,Bordères-Louron,65,Hautes-Pyrénées,76,Occitanie
65099,BORDERES LOURON,65590,BORDERES LOURON,ILHAN,42.8676970923,0.416690191608,99,,Bordères-Louron,Bordères-Louron,65,Hautes-Pyrénées,76,Occitanie
65100,BORDERES SUR L ECHEZ,65320,BORDERES SUR L ECHEZ,,43.2654849206,0.0424725726856,100,,Bordères-sur-l'Échez,Bordères-sur-l'Échez,65,Hautes-Pyrénées,76,Occitanie
65101,BORDES,65190,BORDES,,43.1982212521,0.214667029062,101,,Bordes,Bordes,65,Hautes-Pyrénées,76,Occitanie
65102,BOUILH DEVANT,65140,BOUILH DEVANT,,43.3365484114,0.261492915975,102,,Bouilh-Devant,Bouilh-Devant,65,Hautes-Pyrénées,76,Occitanie
65103,BOUILH PEREUILH,65350,BOUILH PEREUILH,,43.3091440414,0.191684455073,103,,Bouilh-Péreuilh,Bouilh-Péreuilh,65,Hautes-Pyrénées,76,Occitanie
65104,BOULIN,65350,BOULIN,,43.2540490794,0.135324700722,104,,Boulin,Boulin,65,Hautes-Pyrénées,76,Occitanie
65105,BOURG DE BIGORRE,65130,BOURG DE BIGORRE,,43.0860330702,0.257177493123,105,,Bourg-de-Bigorre,Bourg-de-Bigorre,65,Hautes-Pyrénées,76,Occitanie
65106,BOURISP,65170,BOURISP,,42.8316716481,0.341936826343,106,,Bourisp,Bourisp,65,Hautes-Pyrénées,76,Occitanie
65107,BOURREAC,65100,BOURREAC,,43.1053670319,0.00269825078889,107,,Bourréac,Bourréac,65,Hautes-Pyrénées,76,Occitanie
65108,BOURS,65460,BOURS,,43.2761096704,0.0907724442987,108,,Bours,Bours,65,Hautes-Pyrénées,76,Occitanie
65109,BRAMEVAQUE,65370,BRAMEVAQUE,,42.9775013643,0.559652236891,109,,Bramevaque,Bramevaque,65,Hautes-Pyrénées,76,Occitanie
65110,BUGARD,65220,BUGARD,,43.2552558483,0.311328173985,110,,Bugard,Bugard,65,Hautes-Pyrénées,76,Occitanie
65111,BULAN,65130,BULAN,,43.043232784,0.279721330275,111,,Bulan,Bulan,65,Hautes-Pyrénées,76,Occitanie
65112,BUN,65400,BUN,,42.9697185212,-0.16335034646,112,,Bun,Bun,65,Hautes-Pyrénées,76,Occitanie
65113,BURG,65190,BURG,,43.1876222355,0.317969712946,113,,Burg,Burg,65,Hautes-Pyrénées,76,Occitanie
65114,BUZON,65140,BUZON,,43.4467290166,0.148400419047,114,,Buzon,Buzon,65,Hautes-Pyrénées,76,Occitanie
65115,CABANAC,65350,CABANAC,,43.2733956234,0.225559355522,115,,Cabanac,Cabanac,65,Hautes-Pyrénées,76,Occitanie
65116,CADEAC,65240,CADEAC,,42.894013886,0.335111025922,116,,Cadéac,Cadéac,65,Hautes-Pyrénées,76,Occitanie
65117,CADEILHAN TRACHERE,65170,CADEILHAN TRACHERE,,42.8105603633,0.295350991831,117,,Cadeilhan-Trachère,Cadeilhan-Trachère,65,Hautes-Pyrénées,76,Occitanie
65118,CAHARET,65190,CAHARET,,43.1450399682,0.313300480102,118,,Caharet,Caharet,65,Hautes-Pyrénées,76,Occitanie
65119,CAIXON,65500,CAIXON,,43.408563662,0.0204351091089,119,,Caixon,Caixon,65,Hautes-Pyrénées,76,Occitanie
65120,CALAVANTE,65190,CALAVANTE,,43.2044624838,0.163749395277,120,,Calavanté,Calavanté,65,Hautes-Pyrénées,76,Occitanie
65121,CAMALES,65500,CAMALES,,43.3587114668,0.0800191422189,121,,Camalès,Camalès,65,Hautes-Pyrénées,76,Occitanie
65122,CAMOUS,65410,CAMOUS,,42.9402787603,0.389064470055,122,,Camous,Camous,65,Hautes-Pyrénées,76,Occitanie
65123,CAMPAN,65710,CAMPAN,,42.9614883707,0.217031289795,123,,Campan,Campan,65,Hautes-Pyrénées,76,Occitanie
65123,CAMPAN,65710,CAMPAN,ARTIGUES CAMPAN,42.9614883707,0.217031289795,123,,Campan,Campan,65,Hautes-Pyrénées,76,Occitanie
65123,CAMPAN,65710,CAMPAN,GRIPP,42.9614883707,0.217031289795,123,,Campan,Campan,65,Hautes-Pyrénées,76,Occitanie
65123,CAMPAN,65710,CAMPAN,LA SEOUBE,42.9614883707,0.217031289795,123,,Campan,Campan,65,Hautes-Pyrénées,76,Occitanie
65123,CAMPAN,65710,CAMPAN,STE MARIE DE CAMPAN,42.9614883707,0.217031289795,123,,Campan,Campan,65,Hautes-Pyrénées,76,Occitanie
65124,CAMPARAN,65170,CAMPARAN,,42.8322238965,0.357816058989,124,,Camparan,Camparan,65,Hautes-Pyrénées,76,Occitanie
65125,CAMPISTROUS,65300,CAMPISTROUS,,43.1446272812,0.374575615028,125,,Campistrous,Campistrous,65,Hautes-Pyrénées,76,Occitanie
65126,CAMPUZAN,65230,CAMPUZAN,,43.2789909949,0.434076263991,126,,Campuzan,Campuzan,65,Hautes-Pyrénées,76,Occitanie
65127,CAPVERN,65130,CAPVERN LES BAINS,,43.1070380886,0.331958446057,127,,Capvern,Capvern,65,Hautes-Pyrénées,76,Occitanie
65128,CASTELBAJAC,65330,CASTELBAJAC,,43.1819809472,0.356269583215,128,,Castelbajac,Castelbajac,65,Hautes-Pyrénées,76,Occitanie
65129,CASTELNAU MAGNOAC,65230,CASTELNAU MAGNOAC,,43.2950835364,0.515143167541,129,,Castelnau-Magnoac,Castelnau-Magnoac,65,Hautes-Pyrénées,76,Occitanie
65130,CASTELNAU RIVIERE BASSE,65700,CASTELNAU RIVIERE BASSE,,43.5804567818,-0.0282406541212,130,,Castelnau-Rivière-Basse,Castelnau-Rivière-Basse,65,Hautes-Pyrénées,76,Occitanie
65131,CASTELVIEILH,65350,CASTELVIEILH,,43.281097408,0.201372876533,131,,Castelvieilh,Castelvieilh,65,Hautes-Pyrénées,76,Occitanie
65132,CASTERA LANUSSE,65190,CASTERA LANUSSE,,43.1721431119,0.291846368196,132,,Castéra-Lanusse,Castéra-Lanusse,65,Hautes-Pyrénées,76,Occitanie
65133,CASTERA LOU,65350,CASTERA LOU,,43.3222665444,0.155031547361,133,,Castéra-Lou,Castéra-Lou,65,Hautes-Pyrénées,76,Occitanie
65134,CASTERETS,65230,CASTERETS,,43.3154419047,0.582927112072,134,,Casterets,Casterets,65,Hautes-Pyrénées,76,Occitanie
65135,CASTILLON,65130,CASTILLON,,43.094301823,0.221465619218,135,,Castillon,Castillon,65,Hautes-Pyrénées,76,Occitanie
65136,CAUBOUS,65230,CAUBOUS,,43.2431070641,0.468805987642,136,,Caubous,Caubous,65,Hautes-Pyrénées,76,Occitanie
65137,CAUSSADE RIVIERE,65700,CAUSSADE RIVIERE,,43.5165153859,0.00978618829501,137,,Caussade-Rivière,Caussade-Rivière,65,Hautes-Pyrénées,76,Occitanie
65138,CAUTERETS,65110,CAUTERETS,,42.8522083035,-0.126528407421,138,,Cauterets,Cauterets,65,Hautes-Pyrénées,76,Occitanie
65139,CAZARILH,65370,CAZARILH,,42.950946353,0.589319300613,139,,Cazarilh,Cazarilh,65,Hautes-Pyrénées,76,Occitanie
65140,CAZAUX DEBAT,65590,CAZAUX DEBAT,,42.887816689,0.382896680318,140,,Cazaux-Debat,Cazaux-Debat,65,Hautes-Pyrénées,76,Occitanie
65141,CAZAUX FRECHET ANERAN CAMORS,65240,CAZAUX FRECHET ANERAN CAMORS,,42.8433198052,0.433537773802,141,,Cazaux-Fréchet-Anéran-Camors,Cazaux-Fréchet-Anéran-Camors,65,Hautes-Pyrénées,76,Occitanie
65141,CAZAUX FRECHET ANERAN CAMORS,65240,CAZAUX FRECHET ANERAN CAMORS,ANERAN CAMORS,42.8433198052,0.433537773802,141,,Cazaux-Fréchet-Anéran-Camors,Cazaux-Fréchet-Anéran-Camors,65,Hautes-Pyrénées,76,Occitanie
65141,CAZAUX FRECHET ANERAN CAMORS,65240,CAZAUX FRECHET ANERAN CAMORS,CAZAUX FRECHET,42.8433198052,0.433537773802,141,,Cazaux-Fréchet-Anéran-Camors,Cazaux-Fréchet-Anéran-Camors,65,Hautes-Pyrénées,76,Occitanie
65142,CHELLE DEBAT,65350,CHELLE DEBAT,,43.2976885835,0.239089634968,142,,Chelle-Debat,Chelle-Debat,65,Hautes-Pyrénées,76,Occitanie
65143,CHELLE SPOU,65130,CHELLE SPOU,,43.1426153635,0.248633985629,143,,Chelle-Spou,Chelle-Spou,65,Hautes-Pyrénées,76,Occitanie
65144,CHEUST,65100,CHEUST,,43.0559325042,0.029784427126,144,,Cheust,Cheust,65,Hautes-Pyrénées,76,Occitanie
65145,CHEZE,65120,CHEZE,,42.920721977,-0.0219668101648,145,,Chèze,Chèze,65,Hautes-Pyrénées,76,Occitanie
65146,CHIS,65800,CHIS,,43.298262816,0.114509982823,146,,Chis,Chis,65,Hautes-Pyrénées,76,Occitanie
65147,CIEUTAT,65200,CIEUTAT,,43.1222598008,0.213094773599,147,,Cieutat,Cieutat,65,Hautes-Pyrénées,76,Occitanie
65148,CIZOS,65230,CIZOS,,43.262678463,0.488561935084,148,,Cizos,Cizos,65,Hautes-Pyrénées,76,Occitanie
65149,CLARAC,65190,CLARAC,,43.2189623801,0.251872148873,149,,Clarac,Clarac,65,Hautes-Pyrénées,76,Occitanie
65150,CLARENS,65300,CLARENS,,43.1538418103,0.41213626371,150,,Clarens,Clarens,65,Hautes-Pyrénées,76,Occitanie
65151,COLLONGUES,65350,COLLONGUES,,43.2895209015,0.168216810258,151,,Collongues,Collongues,65,Hautes-Pyrénées,76,Occitanie
65153,COUSSAN,65350,COUSSAN,,43.2444618183,0.204407038553,153,,Coussan,Coussan,65,Hautes-Pyrénées,76,Occitanie
65154,CRECHETS,65370,CRECHETS,,43.0010267056,0.570074903594,154,,Créchets,Créchets,65,Hautes-Pyrénées,76,Occitanie
65155,DEVEZE,65230,DEVEZE,,43.2757053528,0.549509061122,155,,Devèze,Devèze,65,Hautes-Pyrénées,76,Occitanie
65156,DOURS,65350,DOURS,,43.306657768,0.133314265723,156,,Dours,Dours,65,Hautes-Pyrénées,76,Occitanie
65157,ENS,65170,ENS,,42.7969837017,0.334770180996,157,,Ens,Ens,65,Hautes-Pyrénées,76,Occitanie
65158,ESBAREICH,65370,ESBAREICH,,42.931210078,0.574720507714,158,,Esbareich,Esbareich,65,Hautes-Pyrénées,76,Occitanie
65159,ESCALA,65250,ESCALA,,43.0877050147,0.411249757071,159,,Escala,Escala,65,Hautes-Pyrénées,76,Occitanie
65160,ESCAUNETS,65500,ESCAUNETS,,43.346344736,-0.0768293180694,160,,Escaunets,Escaunets,65,Hautes-Pyrénées,76,Occitanie
65161,ESCONDEAUX,65140,ESCONDEAUX,,43.3459510945,0.13296588942,161,,Escondeaux,Escondeaux,65,Hautes-Pyrénées,76,Occitanie
65162,ESCONNETS,65130,ESCONNETS,,43.0712735338,0.231228181694,162,,Esconnets,Esconnets,65,Hautes-Pyrénées,76,Occitanie
65163,ESCOTS,65130,ESCOTS,,43.0602628922,0.262268698187,163,,Escots,Escots,65,Hautes-Pyrénées,76,Occitanie
65164,ESCOUBES POUTS,65100,ESCOUBES POUTS,,43.1066062315,0.0291146800461,164,,Escoubès-Pouts,Escoubès-Pouts,65,Hautes-Pyrénées,76,Occitanie
65165,ESPARROS,65130,ESPARROS,,43.0106422066,0.306373964745,165,,Esparros,Esparros,65,Hautes-Pyrénées,76,Occitanie
65166,ESPECHE,65130,ESPECHE,,43.0616013882,0.301518033021,166,,Espèche,Espèche,65,Hautes-Pyrénées,76,Occitanie
65167,ESPIEILH,65130,ESPIEILH,,43.0788602953,0.240991510549,167,,Espieilh,Espieilh,65,Hautes-Pyrénées,76,Occitanie
65168,ESQUIEZE SERE,65120,ESQUIEZE SERE,,42.8807733222,-0.00717784345508,168,,Esquièze-Sère,Esquièze-Sère,65,Hautes-Pyrénées,76,Occitanie
65169,ESTAING,65400,ESTAING,,42.8811154225,-0.205984086638,169,,Estaing,Estaing,65,Hautes-Pyrénées,76,Occitanie
65170,ESTAMPURES,65220,ESTAMPURES,,43.3759426738,0.285547480034,170,,Estampures,Estampures,65,Hautes-Pyrénées,76,Occitanie
65171,ESTARVIELLE,65240,ESTARVIELLE,,42.8207156581,0.415397234999,171,,Estarvielle,Estarvielle,65,Hautes-Pyrénées,76,Occitanie
65172,ESTENSAN,65170,ESTENSAN,,42.8164167593,0.344902286039,172,,Estensan,Estensan,65,Hautes-Pyrénées,76,Occitanie
65173,ESTERRE,65120,ESTERRE,,42.8715920542,0.0105585516206,173,,Esterre,Esterre,65,Hautes-Pyrénées,76,Occitanie
65174,ESTIRAC,65700,ESTIRAC,,43.4990637725,0.0289743472032,174,,Estirac,Estirac,65,Hautes-Pyrénées,76,Occitanie
65175,FERRERE,65370,FERRERE,,42.9209922116,0.499588049287,175,,Ferrère,Ferrère,65,Hautes-Pyrénées,76,Occitanie
65176,FERRIERES,65560,FERRIERES,,43.0134440776,-0.240122106852,176,,Ferrières,Ferrières,65,Hautes-Pyrénées,76,Occitanie
65177,FONTRAILLES,65220,FONTRAILLES,,43.3424585482,0.368909254988,177,,Fontrailles,Fontrailles,65,Hautes-Pyrénées,76,Occitanie
65178,FRECHEDE,65220,FRECHEDE,,43.3651632338,0.261264447769,178,,Fréchède,Fréchède,65,Hautes-Pyrénées,76,Occitanie
65179,FRECHENDETS,65130,FRECHENDETS,,43.0613932621,0.234135249528,179,,Fréchendets,Fréchendets,65,Hautes-Pyrénées,76,Occitanie
65180,FRECHET AURE,65240,FRECHET AURE,,42.9266185238,0.379649905195,180,,Fréchet-Aure,Fréchet-Aure,65,Hautes-Pyrénées,76,Occitanie
65181,FRECHOU FRECHET,65190,FRECHOU FRECHET,,43.1763752493,0.162182597764,181,,Fréchou-Fréchet,Fréchou-Fréchet,65,Hautes-Pyrénées,76,Occitanie
65182,GAILLAGOS,65400,GAILLAGOS,,42.9935161811,-0.181340859661,182,,Gaillagos,Gaillagos,65,Hautes-Pyrénées,76,Occitanie
65183,GALAN,65330,GALAN,,43.2229884965,0.403470787681,183,,Galan,Galan,65,Hautes-Pyrénées,76,Occitanie
65184,GALEZ,65330,GALEZ,,43.1867712791,0.406830649029,184,,Galez,Galez,65,Hautes-Pyrénées,76,Occitanie
65185,GARDERES,65320,GARDERES,,43.2829450177,-0.106324179852,185,,Gardères,Gardères,65,Hautes-Pyrénées,76,Occitanie
65186,GAUDENT,65370,GAUDENT,,42.9975262511,0.559482919265,186,,Gaudent,Gaudent,65,Hautes-Pyrénées,76,Occitanie
65187,GAUSSAN,65670,GAUSSAN,,43.2227037647,0.478344428126,187,,Gaussan,Gaussan,65,Hautes-Pyrénées,76,Occitanie
65189,GAYAN,65320,GAYAN,,43.3046479242,0.0486919047842,189,,Gayan,Gayan,65,Hautes-Pyrénées,76,Occitanie
65190,GAZAVE,65250,GAZAVE,,43.0294569149,0.4215522116,190,,Gazave,Gazave,65,Hautes-Pyrénées,76,Occitanie
65191,GAZOST,65100,GAZOST,,43.0007012116,0.0235191133565,191,,Gazost,Gazost,65,Hautes-Pyrénées,76,Occitanie
65192,GAVARNIE GEDRE,65120,GAVARNIE GEDRE,,42.766573981,0.041488959159,192,,Gavarnie-Gèdre,Gavarnie-Gèdre,65,Hautes-Pyrénées,76,Occitanie
65192,GAVARNIE GEDRE,65120,GAVARNIE GEDRE,GAVARNIE,42.766573981,0.041488959159,192,,Gavarnie-Gèdre,Gavarnie-Gèdre,65,Hautes-Pyrénées,76,Occitanie
65193,GEMBRIE,65370,GEMBRIE,,42.9886064144,0.57539500217,193,,Gembrie,Gembrie,65,Hautes-Pyrénées,76,Occitanie
65194,GENEREST,65150,GENEREST,,43.025453129,0.516625299091,194,,Générest,Générest,65,Hautes-Pyrénées,76,Occitanie
65195,GENOS,65240,GENOS,,42.7519110844,0.391498032881,195,,Génos,Génos,65,Hautes-Pyrénées,76,Occitanie
65196,GENSAC,65140,GENSAC,,43.4335645933,0.0908531453962,196,,Gensac,Gensac,65,Hautes-Pyrénées,76,Occitanie
65197,GER,65100,GER,,43.0533440987,-0.041359283067,197,,Ger,Ger,65,Hautes-Pyrénées,76,Occitanie
65198,GERDE,65200,GERDE,,43.0586379729,0.177947425719,198,,Gerde,Gerde,65,Hautes-Pyrénées,76,Occitanie
65199,GERM,65240,GERM,,42.7763912568,0.441090349269,199,,Germ,Germ,65,Hautes-Pyrénées,76,Occitanie
65200,GERMS SUR L OUSSOUET,65200,GERMS SUR L OUSSOUET,,43.0391620559,0.0597615644896,200,,Germs-sur-l'Oussouet,Germs-sur-l'Oussouet,65,Hautes-Pyrénées,76,Occitanie
65201,GEU,65100,GEU,,43.0400523893,-0.0525256694523,201,,Geu,Geu,65,Hautes-Pyrénées,76,Occitanie
65202,GEZ,65400,GEZ,,43.0126351765,-0.121360765935,202,,Gez,Gez,65,Hautes-Pyrénées,76,Occitanie
65203,GEZ EZ ANGLES,65100,GEZ EZ ANGLES,,43.0881354542,0.0247150767346,203,,Gez-ez-Angles,Gez-ez-Angles,65,Hautes-Pyrénées,76,Occitanie
65204,GONEZ,65350,GONEZ,,43.2336842296,0.20961075838,204,,Gonez,Gonez,65,Hautes-Pyrénées,76,Occitanie
65205,GOUAUX,65240,GOUAUX,,42.8566434469,0.373155272179,205,,Gouaux,Gouaux,65,Hautes-Pyrénées,76,Occitanie
65206,GOUDON,65190,GOUDON,,43.2453593051,0.236810689449,206,,Goudon,Goudon,65,Hautes-Pyrénées,76,Occitanie
65207,GOURGUE,65130,GOURGUE,,43.1376860127,0.268172885398,207,,Gourgue,Gourgue,65,Hautes-Pyrénées,76,Occitanie
65208,GRAILHEN,65170,GRAILHEN,,42.83138911,0.372241097838,208,,Grailhen,Grailhen,65,Hautes-Pyrénées,76,Occitanie
65209,GREZIAN,65240,GREZIAN,,42.8723925904,0.352853993495,209,,Grézian,Grézian,65,Hautes-Pyrénées,76,Occitanie
65210,GRUST,65120,GRUST,,42.8904876239,-0.0590135769638,210,,Grust,Grust,65,Hautes-Pyrénées,76,Occitanie
65211,GUCHAN,65170,GUCHAN,,42.8466893699,0.347945425842,211,,Guchan,Guchan,65,Hautes-Pyrénées,76,Occitanie
65212,GUCHEN,65240,GUCHEN,,42.8574363144,0.322355278083,212,,Guchen,Guchen,65,Hautes-Pyrénées,76,Occitanie
65213,GUIZERIX,65230,GUIZERIX,,43.3229464044,0.453311797259,213,,Guizerix,Guizerix,65,Hautes-Pyrénées,76,Occitanie
65214,HACHAN,65230,HACHAN,,43.2848182043,0.455050397796,214,,Hachan,Hachan,65,Hautes-Pyrénées,76,Occitanie
65215,HAGEDET,65700,HAGEDET,,43.5185343099,-0.0280247100999,215,,Hagedet,Hagedet,65,Hautes-Pyrénées,76,Occitanie
65216,HAUBAN,65200,HAUBAN,,43.0993533014,0.162842331191,216,,Hauban,Hauban,65,Hautes-Pyrénées,76,Occitanie
65217,HAUTAGET,65150,HAUTAGET,,43.0583239771,0.456670276219,217,,Hautaget,Hautaget,65,Hautes-Pyrénées,76,Occitanie
65218,HECHES,65250,HECHES,,42.9998996047,0.373888635101,218,,Hèches,Hèches,65,Hautes-Pyrénées,76,Occitanie
65218,HECHES,65250,HECHES,REBOUC,42.9998996047,0.373888635101,218,,Hèches,Hèches,65,Hautes-Pyrénées,76,Occitanie
65219,HERES,65700,HERES,,43.5504327216,-0.00223724418856,219,,Hères,Hères,65,Hautes-Pyrénées,76,Occitanie
65220,HIBARETTE,65380,HIBARETTE,,43.1651076047,0.0381107147768,220,,Hibarette,Hibarette,65,Hautes-Pyrénées,76,Occitanie
65221,HIIS,65200,HIIS,,43.1356432139,0.096431409035,221,,Hiis,Hiis,65,Hautes-Pyrénées,76,Occitanie
65222,HITTE,65190,HITTE,,43.1520020024,0.163133431927,222,,Hitte,Hitte,65,Hautes-Pyrénées,76,Occitanie
65223,HORGUES,65310,HORGUES,,43.1893086126,0.0801941893697,223,,Horgues,Horgues,65,Hautes-Pyrénées,76,Occitanie
65224,HOUEYDETS,65330,HOUEYDETS,,43.1547737607,0.353267560435,224,,Houeydets,Houeydets,65,Hautes-Pyrénées,76,Occitanie
65225,HOURC,65350,HOURC,,43.2501242697,0.17547709876,225,,Hourc,Hourc,65,Hautes-Pyrénées,76,Occitanie
65226,IBOS,65420,IBOS,,43.2411378996,0.000239711403462,226,,Ibos,Ibos,65,Hautes-Pyrénées,76,Occitanie
65228,ILHET,65410,ILHET,,42.963629591,0.396133786225,228,,Ilhet,Ilhet,65,Hautes-Pyrénées,76,Occitanie
65229,ILHEU,65370,ILHEU,,42.9961638122,0.601486255429,229,,Ilheu,Ilheu,65,Hautes-Pyrénées,76,Occitanie
65230,IZAOURT,65370,IZAOURT,,43.0158308851,0.602750524708,230,,Izaourt,Izaourt,65,Hautes-Pyrénées,76,Occitanie
65231,IZAUX,65250,IZAUX,,43.0578535178,0.377272900491,231,,Izaux,Izaux,65,Hautes-Pyrénées,76,Occitanie
65232,JACQUE,65350,JACQUE,,43.3206788577,0.215472944826,232,,Jacque,Jacque,65,Hautes-Pyrénées,76,Occitanie
65233,JARRET,65100,JARRET,,43.0746279828,-0.0165977313964,233,,Jarret,Jarret,65,Hautes-Pyrénées,76,Occitanie
65234,JEZEAU,65240,JEZEAU,,42.9114645299,0.407364858674,234,,Jézeau,Jézeau,65,Hautes-Pyrénées,76,Occitanie
65235,JUILLAN,65290,JUILLAN,,43.199677324,0.0228064029796,235,,Juillan,Juillan,65,Hautes-Pyrénées,76,Occitanie
65236,JULOS,65100,JULOS,,43.1190514683,-0.00206249429953,236,,Julos,Julos,65,Hautes-Pyrénées,76,Occitanie
65237,JUNCALAS,65100,JUNCALAS,,43.0527699826,0.00117393417508,237,,Juncalas,Juncalas,65,Hautes-Pyrénées,76,Occitanie
65238,LABASSERE,65200,LABASSERE,,43.0613748253,0.0905355146224,238,,Labassère,Labassère,65,Hautes-Pyrénées,76,Occitanie
65239,LABASTIDE,65130,LABASTIDE,,43.0357928885,0.351671885257,239,,Labastide,Labastide,65,Hautes-Pyrénées,76,Occitanie
65240,LABATUT RIVIERE,65700,LABATUT RIVIERE,,43.5257802018,0.0335259810087,240,,Labatut-Rivière,Labatut-Rivière,65,Hautes-Pyrénées,76,Occitanie
65241,LABORDE,65130,LABORDE,,43.0340643997,0.294781061742,241,,Laborde,Laborde,65,Hautes-Pyrénées,76,Occitanie
65242,LACASSAGNE,65140,LACASSAGNE,,43.3558671594,0.154037265829,242,,Lacassagne,Lacassagne,65,Hautes-Pyrénées,76,Occitanie
65243,LAFITOLE,65700,LAFITOLE,,43.4493703401,0.0766668449898,243,,Lafitole,Lafitole,65,Hautes-Pyrénées,76,Occitanie
65244,LAGARDE,65320,LAGARDE,,43.3040330291,0.0211696262511,244,,Lagarde,Lagarde,65,Hautes-Pyrénées,76,Occitanie
65245,LAGRANGE,65300,LAGRANGE,,43.1291634355,0.348603751912,245,,Lagrange,Lagrange,65,Hautes-Pyrénées,76,Occitanie
65247,ARRAYOU LAHITTE,65100,ARRAYOU LAHITTE,,43.0934147227,0.0446631806438,247,,Arrayou-Lahitte,Arrayou-Lahitte,65,Hautes-Pyrénées,76,Occitanie
65248,LAHITTE TOUPIERE,65700,LAHITTE TOUPIERE,,43.4563239551,-0.013870937842,248,,Lahitte-Toupière,Lahitte-Toupière,65,Hautes-Pyrénées,76,Occitanie
65249,LALANNE,65230,LALANNE,,43.2735240227,0.581021273893,249,,Lalanne,Lalanne,65,Hautes-Pyrénées,76,Occitanie
65250,LALANNE TRIE,65220,LALANNE TRIE,,43.3116930661,0.336259044414,250,,Lalanne-Trie,Lalanne-Trie,65,Hautes-Pyrénées,76,Occitanie
65251,LALOUBERE,65310,LALOUBERE,,43.2090831887,0.0761770721287,251,,Laloubère,Laloubère,65,Hautes-Pyrénées,76,Occitanie
65252,LAMARQUE PONTACQ,65380,LAMARQUE PONTACQ,,43.1638872939,-0.101045534959,252,,Lamarque-Pontacq,Lamarque-Pontacq,65,Hautes-Pyrénées,76,Occitanie
65253,LAMARQUE RUSTAING,65220,LAMARQUE RUSTAING,,43.2770067649,0.294662608938,253,,Lamarque-Rustaing,Lamarque-Rustaing,65,Hautes-Pyrénées,76,Occitanie
65254,LAMEAC,65140,LAMEAC,,43.3322074148,0.231252614188,254,,Laméac,Laméac,65,Hautes-Pyrénées,76,Occitanie
65255,LANCON,65240,LANCON,,42.8820682705,0.36788060148,255,,Lançon,Lançon,65,Hautes-Pyrénées,76,Occitanie
65256,LANESPEDE,65190,LANESPEDE,,43.1658354923,0.272925172208,256,,Lanespède,Lanespède,65,Hautes-Pyrénées,76,Occitanie
65257,LANNE,65380,LANNE,,43.162653619,0.00193180240761,257,,Lanne,Lanne,65,Hautes-Pyrénées,76,Occitanie
65258,LANNEMEZAN,65300,LANNEMEZAN,,43.117063337,0.39353033034,258,,Lannemezan,Lannemezan,65,Hautes-Pyrénées,76,Occitanie
65259,LANSAC,65350,LANSAC,,43.221317061,0.17215713947,259,,Lansac,Lansac,65,Hautes-Pyrénées,76,Occitanie
65260,LAPEYRE,65220,LAPEYRE,,43.3245708913,0.331017237637,260,,Lapeyre,Lapeyre,65,Hautes-Pyrénées,76,Occitanie
65261,LARAN,65670,LARAN,,43.2344827655,0.473331822869,261,,Laran,Laran,65,Hautes-Pyrénées,76,Occitanie
65262,LARREULE,65700,LARREULE,,43.4410472948,0.0162059547746,262,,Larreule,Larreule,65,Hautes-Pyrénées,76,Occitanie
65263,LARROQUE,65230,LARROQUE,,43.3134878152,0.487635169542,263,,Larroque,Larroque,65,Hautes-Pyrénées,76,Occitanie
65264,LASCAZERES,65700,LASCAZERES,,43.4981293192,-0.0285264493096,264,,Lascazères,Lascazères,65,Hautes-Pyrénées,76,Occitanie
65265,LASLADES,65350,LASLADES,,43.2304728913,0.165662705335,265,,Laslades,Laslades,65,Hautes-Pyrénées,76,Occitanie
65266,LASSALES,65670,LASSALES,,43.2097258268,0.486113113941,266,,Lassales,Lassales,65,Hautes-Pyrénées,76,Occitanie
65267,LAU BALAGNAS,65400,LAU BALAGNAS,,42.9932113326,-0.0889285600847,267,,Lau-Balagnas,Lau-Balagnas,65,Hautes-Pyrénées,76,Occitanie
65268,LAYRISSE,65380,LAYRISSE,,43.1369406664,0.0500385637109,268,,Layrisse,Layrisse,65,Hautes-Pyrénées,76,Occitanie
65269,LESCURRY,65140,LESCURRY,,43.3365858689,0.156615119219,269,,Lescurry,Lescurry,65,Hautes-Pyrénées,76,Occitanie
65270,LESPOUEY,65190,LESPOUEY,,43.2123292378,0.172154745019,270,,Lespouey,Lespouey,65,Hautes-Pyrénées,76,Occitanie
65271,LEZIGNAN,65100,LEZIGNAN,,43.098874846,-0.00717400670291,271,,Lézignan,Lézignan,65,Hautes-Pyrénées,76,Occitanie
65272,LHEZ,65190,LHEZ,,43.2024093618,0.19484559668,272,,Lhez,Lhez,65,Hautes-Pyrénées,76,Occitanie
65273,LIAC,65140,LIAC,,43.4145828325,0.100583360751,273,,Liac,Liac,65,Hautes-Pyrénées,76,Occitanie
65274,LIBAROS,65330,LIBAROS,,43.2485668256,0.391971117997,274,,Libaros,Libaros,65,Hautes-Pyrénées,76,Occitanie
65275,LIES,65200,LIES,,43.0617961938,0.204542612711,275,,Lies,Lies,65,Hautes-Pyrénées,76,Occitanie
65276,LIZOS,65350,LIZOS,,43.2589588685,0.146081263735,276,,Lizos,Lizos,65,Hautes-Pyrénées,76,Occitanie
65277,LOMBRES,65150,LOMBRES,,43.0518686754,0.510353716304,277,,Lombrès,Lombrès,65,Hautes-Pyrénées,76,Occitanie
65278,LOMNE,65130,LOMNE,,43.0469492307,0.302892887123,278,,Lomné,Lomné,65,Hautes-Pyrénées,76,Occitanie
65279,LORTET,65250,LORTET,,43.0398593753,0.374062084533,279,,Lortet,Lortet,65,Hautes-Pyrénées,76,Occitanie
65280,LOUBAJAC,65100,LOUBAJAC,,43.1385999855,-0.0829246154989,280,,Loubajac,Loubajac,65,Hautes-Pyrénées,76,Occitanie
65281,LOUCRUP,65200,LOUCRUP,,43.1180537077,0.0703348331566,281,,Loucrup,Loucrup,65,Hautes-Pyrénées,76,Occitanie
65282,LOUDENVIELLE,65240,LOUDENVIELLE,ARMENTEULE,42.7300216017,0.432583717897,282,,Loudenvielle,Loudenvielle,65,Hautes-Pyrénées,76,Occitanie
65282,LOUDENVIELLE,65510,LOUDENVIELLE,,42.7300216017,0.432583717897,282,,Loudenvielle,Loudenvielle,65,Hautes-Pyrénées,76,Occitanie
65283,LOUDERVIELLE,65240,LOUDERVIELLE,,42.8019156527,0.439491770073,283,,Loudervielle,Loudervielle,65,Hautes-Pyrénées,76,Occitanie
65284,LOUEY,65290,LOUEY,,43.1791075195,0.0196023682592,284,,Louey,Louey,65,Hautes-Pyrénées,76,Occitanie
65285,LOUIT,65350,LOUIT,,43.3006855002,0.16086842567,285,,Louit,Louit,65,Hautes-Pyrénées,76,Occitanie
65286,LOURDES,65100,LOURDES,,43.1074297344,-0.0768069387932,286,,Lourdes,Lourdes,65,Hautes-Pyrénées,76,Occitanie
65287,LOURES BAROUSSE,65370,LOURES BAROUSSE,,43.0272522696,0.603543320181,287,,Loures-Barousse,Loures-Barousse,65,Hautes-Pyrénées,76,Occitanie
65288,LUBRET ST LUC,65220,LUBRET ST LUC,,43.3155941399,0.298164374881,288,,Lubret-Saint-Luc,Lubret-Saint-Luc,65,Hautes-Pyrénées,76,Occitanie
65289,LUBY BETMONT,65220,LUBY BETMONT,,43.2961360859,0.294464255946,289,,Luby-Betmont,Luby-Betmont,65,Hautes-Pyrénées,76,Occitanie
65290,LUC,65190,LUC,,43.1490471894,0.1822289415,290,,Luc,Luc,65,Hautes-Pyrénées,76,Occitanie
65291,LUGAGNAN,65100,LUGAGNAN,,43.0634793076,-0.0388443963174,291,,Lugagnan,Lugagnan,65,Hautes-Pyrénées,76,Occitanie
65292,LUQUET,65320,LUQUET,,43.2596416561,-0.11842797217,292,,Luquet,Luquet,65,Hautes-Pyrénées,76,Occitanie
65293,LUSTAR,65220,LUSTAR,,43.2694349953,0.340455146819,293,,Lustar,Lustar,65,Hautes-Pyrénées,76,Occitanie
65294,LUTILHOUS,65300,LUTILHOUS,,43.1342737779,0.326800783392,294,,Lutilhous,Lutilhous,65,Hautes-Pyrénées,76,Occitanie
65295,LUZ ST SAUVEUR,65120,LUZ ST SAUVEUR,,42.8320053922,0.0163906780322,295,,Luz-Saint-Sauveur,Luz-Saint-Sauveur,65,Hautes-Pyrénées,76,Occitanie
65295,LUZ ST SAUVEUR,65120,LUZ ST SAUVEUR,ST SAUVEUR LES BAINS,42.8320053922,0.0163906780322,295,,Luz-Saint-Sauveur,Luz-Saint-Sauveur,65,Hautes-Pyrénées,76,Occitanie
65296,MADIRAN,65700,MADIRAN,,43.5517824555,-0.057201539613,296,,Madiran,Madiran,65,Hautes-Pyrénées,76,Occitanie
65297,MANSAN,65140,MANSAN,,43.3413274684,0.192744647375,297,,Mansan,Mansan,65,Hautes-Pyrénées,76,Occitanie
65298,MARQUERIE,65350,MARQUERIE,,43.2592951961,0.208002985993,298,,Marquerie,Marquerie,65,Hautes-Pyrénées,76,Occitanie
65299,MARSAC,65500,MARSAC,,43.3299316069,0.0847711611787,299,,Marsac,Marsac,65,Hautes-Pyrénées,76,Occitanie
65300,MARSAS,65200,MARSAS,,43.0532691959,0.220898066775,300,,Marsas,Marsas,65,Hautes-Pyrénées,76,Occitanie
65301,MARSEILLAN,65350,MARSEILLAN,,43.3014129983,0.213052556681,301,,Marseillan,Marseillan,65,Hautes-Pyrénées,76,Occitanie
65303,MASCARAS,65190,MASCARAS,,43.1923766337,0.174125977844,303,,Mascaras,Mascaras,65,Hautes-Pyrénées,76,Occitanie
65304,MAUBOURGUET,65700,MAUBOURGUET,,43.4688561646,0.044250933589,304,,Maubourguet,Maubourguet,65,Hautes-Pyrénées,76,Occitanie
65305,MAULEON BAROUSSE,65370,MAULEON BAROUSSE,,42.9502723572,0.577280685975,305,,Mauléon-Barousse,Mauléon-Barousse,65,Hautes-Pyrénées,76,Occitanie
65306,MAUVEZIN,65130,MAUVEZIN,,43.1208235208,0.28103379635,306,,Mauvezin,Mauvezin,65,Hautes-Pyrénées,76,Occitanie
65307,MAZERES DE NESTE,65150,MAZERES DE NESTE,,43.0760786715,0.540392902484,307,,Mazères-de-Neste,Mazères-de-Neste,65,Hautes-Pyrénées,76,Occitanie
65308,MAZEROLLES,65220,MAZEROLLES,,43.3546128947,0.286551798965,308,,Mazerolles,Mazerolles,65,Hautes-Pyrénées,76,Occitanie
65309,MAZOUAU,65250,MAZOUAU,,43.0279073309,0.40177065112,309,,Mazouau,Mazouau,65,Hautes-Pyrénées,76,Occitanie
65310,MERILHEU,65200,MERILHEU,,43.0996506102,0.179357214328,310,,Mérilheu,Mérilheu,65,Hautes-Pyrénées,76,Occitanie
65311,MINGOT,65140,MINGOT,,43.3742203643,0.171415164704,311,,Mingot,Mingot,65,Hautes-Pyrénées,76,Occitanie
65312,MOLERE,65130,MOLERE,,43.0973035068,0.300278702333,312,,Molère,Molère,65,Hautes-Pyrénées,76,Occitanie
65313,MOMERES,65360,MOMERES,,43.174371736,0.0813794112594,313,,Momères,Momères,65,Hautes-Pyrénées,76,Occitanie
65314,MONFAUCON,65140,MONFAUCON,,43.4543600444,0.120919395519,314,,Monfaucon,Monfaucon,65,Hautes-Pyrénées,76,Occitanie
65315,MONLEON MAGNOAC,65670,MONLEON MAGNOAC,,43.2361837361,0.517312861957,315,,Monléon-Magnoac,Monléon-Magnoac,65,Hautes-Pyrénées,76,Occitanie
65316,MONLONG,65670,MONLONG,,43.1979666498,0.468864803299,316,,Monlong,Monlong,65,Hautes-Pyrénées,76,Occitanie
65317,MONT,65240,MONT,,42.8210570316,0.443991719394,317,,Mont,Mont,65,Hautes-Pyrénées,76,Occitanie
65318,MONTASTRUC,65330,MONTASTRUC,,43.2144777606,0.347325885358,318,,Montastruc,Montastruc,65,Hautes-Pyrénées,76,Occitanie
65319,MONTEGUT,65150,MONTEGUT,,43.0494913717,0.499092510463,319,,Montégut,Montégut,65,Hautes-Pyrénées,76,Occitanie
65320,MONTGAILLARD,65200,MONTGAILLARD,,43.1232297746,0.111048955498,320,,Montgaillard,Montgaillard,65,Hautes-Pyrénées,76,Occitanie
65321,MONTIGNAC,65690,MONTIGNAC,,43.1821183858,0.149533254079,321,,Montignac,Montignac,65,Hautes-Pyrénées,76,Occitanie
65322,MONTOUSSE,65250,MONTOUSSE,,43.062664756,0.41311893726,322,,Montoussé,Montoussé,65,Hautes-Pyrénées,76,Occitanie
65323,MONTSERIE,65150,MONTSERIE,,43.0493730572,0.437520292,323,,Montsérié,Montsérié,65,Hautes-Pyrénées,76,Occitanie
65324,MOULEDOUS,65190,MOULEDOUS,,43.2259408269,0.250550021139,324,,Moulédous,Moulédous,65,Hautes-Pyrénées,76,Occitanie
65325,MOUMOULOUS,65140,MOUMOULOUS,,43.3591394974,0.244384113594,325,,Moumoulous,Moumoulous,65,Hautes-Pyrénées,76,Occitanie
65326,MUN,65350,MUN,,43.2803464506,0.270777277359,326,,Mun,Mun,65,Hautes-Pyrénées,76,Occitanie
65327,NESTIER,65150,NESTIER,,43.0596221545,0.478343318065,327,,Nestier,Nestier,65,Hautes-Pyrénées,76,Occitanie
65328,NEUILH,65200,NEUILH,,43.0748778356,0.0705571748574,328,,Neuilh,Neuilh,65,Hautes-Pyrénées,76,Occitanie
65329,NISTOS,65150,NISTOS,,42.9901954674,0.462879990115,329,,Nistos,Nistos,65,Hautes-Pyrénées,76,Occitanie
65330,NOUILHAN,65500,NOUILHAN,,43.4272288534,0.0420506166777,330,,Nouilhan,Nouilhan,65,Hautes-Pyrénées,76,Occitanie
65331,ODOS,65310,ODOS,,43.1944738978,0.0557112263144,331,,Odos,Odos,65,Hautes-Pyrénées,76,Occitanie
65332,OLEAC DEBAT,65350,OLEAC DEBAT,,43.2720706813,0.140882447163,332,,Oléac-Debat,Oléac-Debat,65,Hautes-Pyrénées,76,Occitanie
65333,OLEAC DESSUS,65190,OLEAC DESSUS,,43.1610283433,0.19689417299,333,,Oléac-Dessus,Oléac-Dessus,65,Hautes-Pyrénées,76,Occitanie
65334,OMEX,65100,OMEX,,43.0803152897,-0.0935460346356,334,,Omex,Omex,65,Hautes-Pyrénées,76,Occitanie
65335,ORDIZAN,65200,ORDIZAN,,43.1071734446,0.141440645856,335,,Ordizan,Ordizan,65,Hautes-Pyrénées,76,Occitanie
65336,ORGAN,65230,ORGAN,,43.2762051089,0.480265014249,336,,Organ,Organ,65,Hautes-Pyrénées,76,Occitanie
65337,ORIEUX,65190,ORIEUX,,43.2345017899,0.294377366184,337,,Orieux,Orieux,65,Hautes-Pyrénées,76,Occitanie
65338,ORIGNAC,65200,ORIGNAC,,43.1260618827,0.168829805939,338,,Orignac,Orignac,65,Hautes-Pyrénées,76,Occitanie
65339,ORINCLES,65380,ORINCLES,,43.1235842747,0.0409425529196,339,,Orincles,Orincles,65,Hautes-Pyrénées,76,Occitanie
65340,ORLEIX,65800,ORLEIX,,43.2762828841,0.117396047373,340,,Orleix,Orleix,65,Hautes-Pyrénées,76,Occitanie
65341,OROIX,65320,OROIX,,43.2948050605,-0.0231123059147,341,,Oroix,Oroix,65,Hautes-Pyrénées,76,Occitanie
65342,OSMETS,65350,OSMETS,,43.3080163694,0.264427382148,342,,Osmets,Osmets,65,Hautes-Pyrénées,76,Occitanie
65343,OSSEN,65100,OSSEN,,43.0638671027,-0.0763064224879,343,,Ossen,Ossen,65,Hautes-Pyrénées,76,Occitanie
65344,OSSUN,65380,OSSUN,,43.1786184221,-0.0428005714329,344,,Ossun,Ossun,65,Hautes-Pyrénées,76,Occitanie
65345,OSSUN EZ ANGLES,65100,OSSUN EZ ANGLES,,43.0795108469,0.0566869335969,345,,Ossun-ez-Angles,Ossun-ez-Angles,65,Hautes-Pyrénées,76,Occitanie
65346,OUEILLOUX,65190,OUEILLOUX,,43.1751943141,0.181896926196,346,,Oueilloux,Oueilloux,65,Hautes-Pyrénées,76,Occitanie
65347,OURDE,65370,OURDE,,42.9660003493,0.544379532923,347,,Ourde,Ourde,65,Hautes-Pyrénées,76,Occitanie
65348,OURDIS COTDOUSSAN,65100,OURDIS COTDOUSSAN,,43.0400793386,0.0282367657448,348,,Ourdis-Cotdoussan,Ourdis-Cotdoussan,65,Hautes-Pyrénées,76,Occitanie
65349,OURDON,65100,OURDON,,43.0322561805,-0.0135097211174,349,,Ourdon,Ourdon,65,Hautes-Pyrénées,76,Occitanie
65350,OURSBELILLE,65490,OURSBELILLE,,43.2863457184,0.0364938056024,350,,Oursbelille,Oursbelille,65,Hautes-Pyrénées,76,Occitanie
65351,OUSTE,65100,OUSTE,,43.0464855651,-0.0149801465463,351,,Ousté,Ousté,65,Hautes-Pyrénées,76,Occitanie
65352,OUZOUS,65400,OUZOUS,,43.0370929502,-0.103757903937,352,,Ouzous,Ouzous,65,Hautes-Pyrénées,76,Occitanie
65353,OZON,65190,OZON,,43.1694074655,0.2540513635,353,,Ozon,Ozon,65,Hautes-Pyrénées,76,Occitanie
65354,PAILHAC,65240,PAILHAC,,42.911922384,0.367540499398,354,,Pailhac,Pailhac,65,Hautes-Pyrénées,76,Occitanie
65355,PAREAC,65100,PAREAC,,43.1149928821,0.0195991686393,355,,Paréac,Paréac,65,Hautes-Pyrénées,76,Occitanie
65356,PERE,65130,PERE,,43.1456315546,0.295918034098,356,,Péré,Péré,65,Hautes-Pyrénées,76,Occitanie
65357,PEYRAUBE,65190,PEYRAUBE,,43.2027242549,0.255934590964,357,,Peyraube,Peyraube,65,Hautes-Pyrénées,76,Occitanie
65358,PEYRET ST ANDRE,65230,PEYRET ST ANDRE,,43.3207713084,0.512445880132,358,,Peyret-Saint-André,Peyret-Saint-André,65,Hautes-Pyrénées,76,Occitanie
65359,PEYRIGUERE,65350,PEYRIGUERE,,43.2524090816,0.261598345068,359,,Peyriguère,Peyriguère,65,Hautes-Pyrénées,76,Occitanie
65360,PEYROUSE,65270,PEYROUSE,,43.1090326974,-0.115779330923,360,,Peyrouse,Peyrouse,65,Hautes-Pyrénées,76,Occitanie
65361,PEYRUN,65140,PEYRUN,,43.3304439427,0.191795849008,361,,Peyrun,Peyrun,65,Hautes-Pyrénées,76,Occitanie
65362,PIERREFITTE NESTALAS,65260,PIERREFITTE NESTALAS,,42.9622556048,-0.0749478244802,362,,Pierrefitte-Nestalas,Pierrefitte-Nestalas,65,Hautes-Pyrénées,76,Occitanie
65363,PINAS,65300,PINAS,,43.1171174899,0.440832164226,363,,Pinas,Pinas,65,Hautes-Pyrénées,76,Occitanie
65364,PINTAC,65320,PINTAC,,43.2852132015,-0.00516132877618,364,,Pintac,Pintac,65,Hautes-Pyrénées,76,Occitanie
65366,POUEYFERRE,65100,POUEYFERRE,,43.1194383468,-0.0813313513521,366,,Poueyferré,Poueyferré,65,Hautes-Pyrénées,76,Occitanie
65367,POUMAROUS,65190,POUMAROUS,,43.1556409444,0.215770472747,367,,Poumarous,Poumarous,65,Hautes-Pyrénées,76,Occitanie
65368,POUY,65230,POUY,,43.262645754,0.557997332005,368,,Pouy,Pouy,65,Hautes-Pyrénées,76,Occitanie
65369,POUYASTRUC,65350,POUYASTRUC,,43.2680458723,0.171366694638,369,,Pouyastruc,Pouyastruc,65,Hautes-Pyrénées,76,Occitanie
65370,POUZAC,65200,POUZAC,,43.0809868459,0.129487370632,370,,Pouzac,Pouzac,65,Hautes-Pyrénées,76,Occitanie
65371,PRECHAC,65400,PRECHAC,,42.9921924128,-0.0714255631764,371,,Préchac,Préchac,65,Hautes-Pyrénées,76,Occitanie
65372,PUJO,65500,PUJO,,43.3417978125,0.0643943971076,372,,Pujo,Pujo,65,Hautes-Pyrénées,76,Occitanie
65373,PUNTOUS,65230,PUNTOUS,,43.3027199473,0.460801407548,373,,Puntous,Puntous,65,Hautes-Pyrénées,76,Occitanie
65374,PUYDARRIEUX,65220,PUYDARRIEUX,,43.289930505,0.40087886965,374,,Puydarrieux,Puydarrieux,65,Hautes-Pyrénées,76,Occitanie
65375,RABASTENS DE BIGORRE,65140,RABASTENS DE BIGORRE,,43.3859669642,0.156153624575,375,,Rabastens-de-Bigorre,Rabastens-de-Bigorre,65,Hautes-Pyrénées,76,Occitanie
65376,RECURT,65330,RECURT,,43.2006170869,0.434943895633,376,,Recurt,Recurt,65,Hautes-Pyrénées,76,Occitanie
65377,REJAUMONT,65300,REJAUMONT,,43.164905946,0.452687259013,377,,Réjaumont,Réjaumont,65,Hautes-Pyrénées,76,Occitanie
65378,RICAUD,65190,RICAUD,,43.147168202,0.273180802588,378,,Ricaud,Ricaud,65,Hautes-Pyrénées,76,Occitanie
65379,RIS,65590,RIS,,42.8855674038,0.402762810462,379,,Ris,Ris,65,Hautes-Pyrénées,76,Occitanie
65380,SABALOS,65350,SABALOS,,43.2845871037,0.145076212959,380,,Sabalos,Sabalos,65,Hautes-Pyrénées,76,Occitanie
65381,SABARROS,65330,SABARROS,,43.2384256329,0.438784416094,381,,Sabarros,Sabarros,65,Hautes-Pyrénées,76,Occitanie
65382,SACOUE,65370,SACOUE,,42.9860898554,0.529831805774,382,,Sacoué,Sacoué,65,Hautes-Pyrénées,76,Occitanie
65383,SADOURNIN,65220,SADOURNIN,,43.3176909375,0.410703923997,383,,Sadournin,Sadournin,65,Hautes-Pyrénées,76,Occitanie
65384,SAILHAN,65170,SAILHAN,,42.8109106702,0.330131676021,384,,Sailhan,Sailhan,65,Hautes-Pyrénées,76,Occitanie
65385,ST ARROMAN,65250,ST ARROMAN,,43.0447990556,0.403215538636,385,,Saint-Arroman,Saint-Arroman,65,Hautes-Pyrénées,76,Occitanie
65386,ST CREAC,65100,ST CREAC,,43.0606630739,-0.023210441963,386,,Saint-Créac,Saint-Créac,65,Hautes-Pyrénées,76,Occitanie
65387,ST LANNE,65700,ST LANNE,,43.586077387,-0.0699024162423,387,,Saint-Lanne,Saint-Lanne,65,Hautes-Pyrénées,76,Occitanie
65388,ST LARY SOULAN,65170,ST LARY SOULAN,,42.7591645461,0.28622709133,388,,Saint-Lary-Soulan,Saint-Lary-Soulan,65,Hautes-Pyrénées,76,Occitanie
65389,ST LAURENT DE NESTE,65150,ST LAURENT DE NESTE,,43.0920150867,0.480853074724,389,,Saint-Laurent-de-Neste,Saint-Laurent-de-Neste,65,Hautes-Pyrénées,76,Occitanie
65390,ST LEZER,65500,ST LEZER,,43.3591040686,0.0308771458332,390,,Saint-Lézer,Saint-Lézer,65,Hautes-Pyrénées,76,Occitanie
65391,STE MARIE,65370,STE MARIE,,42.9691035062,0.628958999643,391,,Sainte-Marie,Sainte-Marie,65,Hautes-Pyrénées,76,Occitanie
65392,ST MARTIN,65360,ST MARTIN,,43.1649978865,0.0648650720223,392,,Saint-Martin,Saint-Martin,65,Hautes-Pyrénées,76,Occitanie
65393,ST PASTOUS,65400,ST PASTOUS,,43.0155024767,-0.0429811806834,393,,Saint-Pastous,Saint-Pastous,65,Hautes-Pyrénées,76,Occitanie
65394,ST PAUL,65150,ST PAUL,,43.0860017819,0.510432995882,394,,Saint-Paul,Saint-Paul,65,Hautes-Pyrénées,76,Occitanie
65395,ST PE DE BIGORRE,65270,ST PE DE BIGORRE,,43.0842866025,-0.157552870418,395,,Saint-Pé-de-Bigorre,Saint-Pé-de-Bigorre,65,Hautes-Pyrénées,76,Occitanie
65396,ST SAVIN,65400,ST SAVIN,,42.9753213682,-0.0917828178997,396,,Saint-Savin,Saint-Savin,65,Hautes-Pyrénées,76,Occitanie
65397,ST SEVER DE RUSTAN,65140,ST SEVER DE RUSTAN,,43.3517947858,0.226201455092,397,,Saint-Sever-de-Rustan,Saint-Sever-de-Rustan,65,Hautes-Pyrénées,76,Occitanie
65398,SALECHAN,65370,SALECHAN,,42.9551950512,0.629639120312,398,,Saléchan,Saléchan,65,Hautes-Pyrénées,76,Occitanie
65399,SALIGOS,65120,SALIGOS,,42.9015701967,-0.0144385336677,399,,Saligos,Saligos,65,Hautes-Pyrénées,76,Occitanie
65400,SALLES,65400,SALLES,,43.0304838604,-0.170939051228,400,,Salles,Salles,65,Hautes-Pyrénées,76,Occitanie
65401,SALLES ADOUR,65360,SALLES ADOUR,,43.1867597741,0.101493602864,401,,Salles-Adour,Salles-Adour,65,Hautes-Pyrénées,76,Occitanie
65402,SAMURAN,65370,SAMURAN,,42.9851402717,0.600350618237,402,,Samuran,Samuran,65,Hautes-Pyrénées,76,Occitanie
65403,SANOUS,65500,SANOUS,,43.3783036013,0.00355512460944,403,,Sanous,Sanous,65,Hautes-Pyrénées,76,Occitanie
65404,SARIAC MAGNOAC,65230,SARIAC MAGNOAC,,43.3094204607,0.553619587154,404,,Sariac-Magnoac,Sariac-Magnoac,65,Hautes-Pyrénées,76,Occitanie
65405,SARLABOUS,65130,SARLABOUS,,43.0753620802,0.279815388021,405,,Sarlabous,Sarlabous,65,Hautes-Pyrénées,76,Occitanie
65406,SARNIGUET,65390,SARNIGUET,,43.3191202591,0.0882106064948,406,,Sarniguet,Sarniguet,65,Hautes-Pyrénées,76,Occitanie
65407,SARP,65370,SARP,,43.0173035605,0.579910090616,407,,Sarp,Sarp,65,Hautes-Pyrénées,76,Occitanie
65408,SARRANCOLIN,65410,SARRANCOLIN,,42.9604187161,0.411895325406,408,,Sarrancolin,Sarrancolin,65,Hautes-Pyrénées,76,Occitanie
65409,SARRIAC BIGORRE,65140,SARRIAC BIGORRE,,43.3885175976,0.117612400735,409,,Sarriac-Bigorre,Sarriac-Bigorre,65,Hautes-Pyrénées,76,Occitanie
65410,SARROUILLES,65600,SARROUILLES,,43.2315195581,0.130295390654,410,,Sarrouilles,Sarrouilles,65,Hautes-Pyrénées,76,Occitanie
65411,SASSIS,65120,SASSIS,,42.8770355921,-0.0167783465633,411,,Sassis,Sassis,65,Hautes-Pyrénées,76,Occitanie
65412,SAUVETERRE,65700,SAUVETERRE,,43.4768502106,0.105564157307,412,,Sauveterre,Sauveterre,65,Hautes-Pyrénées,76,Occitanie
65413,SAZOS,65120,SAZOS,,42.8560542087,-0.0430560934464,413,,Sazos,Sazos,65,Hautes-Pyrénées,76,Occitanie
65414,SEGALAS,65140,SEGALAS,,43.4098505398,0.130234005775,414,,Ségalas,Ségalas,65,Hautes-Pyrénées,76,Occitanie
65415,SEGUS,65100,SEGUS,,43.0601957152,-0.109294599709,415,,Ségus,Ségus,65,Hautes-Pyrénées,76,Occitanie
65416,SEICH,65150,SEICH,,42.9964233845,0.500255289157,416,,Seich,Seich,65,Hautes-Pyrénées,76,Occitanie
65417,SEMEAC,65600,SEMEAC,,43.2246169766,0.109046031378,417,,Séméac,Séméac,65,Hautes-Pyrénées,76,Occitanie
65418,SENAC,65140,SENAC,,43.3580745238,0.188368232081,418,,Sénac,Sénac,65,Hautes-Pyrénées,76,Occitanie
65419,SENTOUS,65330,SENTOUS,,43.2654727852,0.377625495732,419,,Sentous,Sentous,65,Hautes-Pyrénées,76,Occitanie
65420,SERE EN LAVEDAN,65400,SERE EN LAVEDAN,,43.0198406053,-0.128367115293,420,,Sère-en-Lavedan,Sère-en-Lavedan,65,Hautes-Pyrénées,76,Occitanie
65421,SERE LANSO,65100,SERE LANSO,,43.0673096557,0.0151038377966,421,,Sère-Lanso,Sère-Lanso,65,Hautes-Pyrénées,76,Occitanie
65422,SERON,65320,SERON,,43.3227244461,-0.0918151618276,422,,Séron,Séron,65,Hautes-Pyrénées,76,Occitanie
65423,SERE RUSTAING,65220,SERE RUSTAING,,43.2585633467,0.287398416163,423,,Sère-Rustaing,Sère-Rustaing,65,Hautes-Pyrénées,76,Occitanie
65424,SERS,65120,SERS,,42.9129140504,0.0773911881732,424,,Sers,Sers,65,Hautes-Pyrénées,76,Occitanie
65425,SIARROUY,65500,SIARROUY,,43.3224858434,0.0355258903188,425,,Siarrouy,Siarrouy,65,Hautes-Pyrénées,76,Occitanie
65426,SINZOS,65190,SINZOS,,43.2200222005,0.211795927228,426,,Sinzos,Sinzos,65,Hautes-Pyrénées,76,Occitanie
65427,SIRADAN,65370,SIRADAN,,42.9698474653,0.614553890336,427,,Siradan,Siradan,65,Hautes-Pyrénées,76,Occitanie
65428,SIREIX,65400,SIREIX,,42.9660849304,-0.15464025939,428,,Sireix,Sireix,65,Hautes-Pyrénées,76,Occitanie
65429,SOMBRUN,65700,SOMBRUN,,43.4809482428,0.000102966481311,429,,Sombrun,Sombrun,65,Hautes-Pyrénées,76,Occitanie
65430,SOREAC,65350,SOREAC,,43.3124691316,0.159501219686,430,,Soréac,Soréac,65,Hautes-Pyrénées,76,Occitanie
65431,SOST,65370,SOST,,42.8971814705,0.554911945456,431,,Sost,Sost,65,Hautes-Pyrénées,76,Occitanie
65432,SOUBLECAUSE,65700,SOUBLECAUSE,,43.5328266693,-0.0255224883768,432,,Soublecause,Soublecause,65,Hautes-Pyrénées,76,Occitanie
65433,SOUES,65430,SOUES,,43.2056090738,0.0994897363458,433,,Soues,Soues,65,Hautes-Pyrénées,76,Occitanie
65435,SOULOM,65260,SOULOM,,42.9498367638,-0.0729862244782,435,,Soulom,Soulom,65,Hautes-Pyrénées,76,Occitanie
65436,SOUYEAUX,65350,SOUYEAUX,,43.2412109122,0.1663976851,436,,Souyeaux,Souyeaux,65,Hautes-Pyrénées,76,Occitanie
65437,TAJAN,65300,TAJAN,,43.1815736536,0.451940506125,437,,Tajan,Tajan,65,Hautes-Pyrénées,76,Occitanie
65438,TALAZAC,65500,TALAZAC,,43.3342840796,0.0373399435018,438,,Talazac,Talazac,65,Hautes-Pyrénées,76,Occitanie
65439,TARASTEIX,65320,TARASTEIX,,43.3172131776,-0.00747302687854,439,,Tarasteix,Tarasteix,65,Hautes-Pyrénées,76,Occitanie
65440,TARBES,65000,TARBES,,43.2347859635,0.0660093937851,440,,Tarbes,Tarbes,65,Hautes-Pyrénées,76,Occitanie
65441,THEBE,65370,THEBE,,42.9569919341,0.60265452454,441,,Thèbe,Thèbe,65,Hautes-Pyrénées,76,Occitanie
65442,THERMES MAGNOAC,65230,THERMES MAGNOAC,,43.2974962072,0.603217695988,442,,Thermes-Magnoac,Thermes-Magnoac,65,Hautes-Pyrénées,76,Occitanie
65443,THUY,65350,THUY,,43.2572755846,0.240856974444,443,,Thuy,Thuy,65,Hautes-Pyrénées,76,Occitanie
65444,TIBIRAN JAUNAC,65150,TIBIRAN JAUNAC,,43.0523720905,0.549033475405,444,,Tibiran-Jaunac,Tibiran-Jaunac,65,Hautes-Pyrénées,76,Occitanie
65445,TILHOUSE,65130,TILHOUSE,,43.0848937897,0.309988769457,445,,Tilhouse,Tilhouse,65,Hautes-Pyrénées,76,Occitanie
65446,TOSTAT,65140,TOSTAT,,43.3297026101,0.111155594441,446,,Tostat,Tostat,65,Hautes-Pyrénées,76,Occitanie
65447,TOURNAY,65190,TOURNAY,,43.1857191109,0.247816207827,447,,Tournay,Tournay,65,Hautes-Pyrénées,76,Occitanie
65448,TOURNOUS DARRE,65220,TOURNOUS DARRE,,43.284903024,0.357933854328,448,,Tournous-Darré,Tournous-Darré,65,Hautes-Pyrénées,76,Occitanie
65449,TOURNOUS DEVANT,65330,TOURNOUS DEVANT,,43.2502363801,0.420327450195,449,,Tournous-Devant,Tournous-Devant,65,Hautes-Pyrénées,76,Occitanie
65450,TRAMEZAIGUES,65170,TRAMEZAIGUES,,42.7537706313,0.250807124153,450,,Tramezaïgues,Tramezaïgues,65,Hautes-Pyrénées,76,Occitanie
65451,TREBONS,65200,TREBONS,,43.0950124343,0.107093938563,451,,Trébons,Trébons,65,Hautes-Pyrénées,76,Occitanie
65452,TRIE SUR BAISE,65220,TRIE SUR BAISE,,43.3175400046,0.365986553654,452,,Trie-sur-Baïse,Trie-sur-Baïse,65,Hautes-Pyrénées,76,Occitanie
65453,TROUBAT,65370,TROUBAT,,42.9751262869,0.582135854208,453,,Troubat,Troubat,65,Hautes-Pyrénées,76,Occitanie
65454,TROULEY LABARTHE,65140,TROULEY LABARTHE,,43.3198102586,0.248116496445,454,,Trouley-Labarthe,Trouley-Labarthe,65,Hautes-Pyrénées,76,Occitanie
65455,TUZAGUET,65150,TUZAGUET,,43.0839092418,0.441803377516,455,,Tuzaguet,Tuzaguet,65,Hautes-Pyrénées,76,Occitanie
65456,UGLAS,65300,UGLAS,,43.1402100651,0.4386284458,456,,Uglas,Uglas,65,Hautes-Pyrénées,76,Occitanie
65457,UGNOUAS,65140,UGNOUAS,,43.3421854183,0.103951257526,457,,Ugnouas,Ugnouas,65,Hautes-Pyrénées,76,Occitanie
65458,UZ,65400,UZ,,42.957459306,-0.0905524703645,458,,Uz,Uz,65,Hautes-Pyrénées,76,Occitanie
65459,UZER,65200,UZER,,43.0773706772,0.192776728078,459,,Uzer,Uzer,65,Hautes-Pyrénées,76,Occitanie
65460,VIC EN BIGORRE,65500,VIC EN BIGORRE,,43.3910708794,0.0554734768116,460,,Vic-en-Bigorre,Vic-en-Bigorre,65,Hautes-Pyrénées,76,Occitanie
65461,VIDOU,65220,VIDOU,,43.2984065613,0.33215461226,461,,Vidou,Vidou,65,Hautes-Pyrénées,76,Occitanie
65462,VIDOUZE,65700,VIDOUZE,,43.4402150519,-0.0459193409795,462,,Vidouze,Vidouze,65,Hautes-Pyrénées,76,Occitanie
65463,VIELLA,65120,VIELLA,,42.8710887537,0.0248813179111,463,,Viella,Viella,65,Hautes-Pyrénées,76,Occitanie
65464,VIELLE ADOUR,65360,VIELLE ADOUR,,43.1428451906,0.128121780681,464,,Vielle-Adour,Vielle-Adour,65,Hautes-Pyrénées,76,Occitanie
65465,VIELLE AURE,65170,VIELLE AURE,,42.8492746267,0.208155528446,465,,Vielle-Aure,Vielle-Aure,65,Hautes-Pyrénées,76,Occitanie
65466,VIELLE LOURON,65240,VIELLE LOURON,,42.8340764173,0.396120729041,466,,Vielle-Louron,Vielle-Louron,65,Hautes-Pyrénées,76,Occitanie
65467,VIER BORDES,65400,VIER BORDES,,42.9934213357,-0.0313550215399,467,,Vier-Bordes,Vier-Bordes,65,Hautes-Pyrénées,76,Occitanie
65468,VIEUZOS,65230,VIEUZOS,,43.2546308261,0.447929093195,468,,Vieuzos,Vieuzos,65,Hautes-Pyrénées,76,Occitanie
65469,VIEY,65120,VIEY,,42.892540013,0.017273292807,469,,Viey,Viey,65,Hautes-Pyrénées,76,Occitanie
65470,VIGER,65100,VIGER,,43.0589366609,-0.0568001764267,470,,Viger,Viger,65,Hautes-Pyrénées,76,Occitanie
65471,VIGNEC,65170,VIGNEC,,42.8187367503,0.285369087805,471,,Vignec,Vignec,65,Hautes-Pyrénées,76,Occitanie
65472,VILLEFRANQUE,65700,VILLEFRANQUE,,43.5018976612,-0.00421391977878,472,,Villefranque,Villefranque,65,Hautes-Pyrénées,76,Occitanie
65473,VILLELONGUE,65260,VILLELONGUE,,42.94230298,-0.0171051666121,473,,Villelongue,Villelongue,65,Hautes-Pyrénées,76,Occitanie
65474,VILLEMBITS,65220,VILLEMBITS,,43.2801778426,0.319096050681,474,,Villembits,Villembits,65,Hautes-Pyrénées,76,Occitanie
65475,VILLEMUR,65230,VILLEMUR,,43.2528387965,0.55548588731,475,,Villemur,Villemur,65,Hautes-Pyrénées,76,Occitanie
65476,VILLENAVE PRES BEARN,65500,VILLENAVE PRES BEARN,,43.3658932913,-0.0935111328163,476,,Villenave-près-Béarn,Villenave-près-Béarn,65,Hautes-Pyrénées,76,Occitanie
65477,VILLENAVE PRES MARSAC,65500,VILLENAVE PRES MARSAC,,43.3388929432,0.0880398223015,477,,Villenave-près-Marsac,Villenave-près-Marsac,65,Hautes-Pyrénées,76,Occitanie
65478,VISCOS,65120,VISCOS,,42.916683424,-0.0532950234889,478,,Viscos,Viscos,65,Hautes-Pyrénées,76,Occitanie
65479,VISKER,65200,VISKER,,43.1385924723,0.0683532878312,479,,Visker,Visker,65,Hautes-Pyrénées,76,Occitanie
65480,VIZOS,65120,VIZOS,,42.8925591748,-0.00333686959239,480,,Vizos,Vizos,65,Hautes-Pyrénées,76,Occitanie
65481,BAREGES,65120,BAREGES,,42.877602229,0.113710879628,481,,Barèges,Barèges,65,Hautes-Pyrénées,76,Occitanie
65482,CANTAOUS,65150,CANTAOUS,,43.101919219,0.445956061466,482,,Cantaous,Cantaous,65,Hautes-Pyrénées,76,Occitanie
66001,L ALBERE,66480,L ALBERE,,42.4760146426,2.89621584171,1,L',Albère,L'Albère,66,Pyrénées-Orientales,76,Occitanie
66002,ALENYA,66200,ALENYA,,42.6407019491,2.98145485065,2,,Alénya,Alénya,66,Pyrénées-Orientales,76,Occitanie
66003,AMELIE LES BAINS PALALDA,66110,AMELIE LES BAINS PALALDA,,42.4436859079,2.68338432052,3,,Amélie-les-Bains-Palalda,Amélie-les-Bains-Palalda,66,Pyrénées-Orientales,76,Occitanie
66004,LES ANGLES,66210,LES ANGLES,,42.5797574108,2.05268528008,4,Les,Angles,Les Angles,66,Pyrénées-Orientales,76,Occitanie
66005,ANGOUSTRINE VILLENEUVE DES ESCALDES,66760,ANGOUSTRINE VILLENEUVE ESCALDES,,42.5644340706,1.95068130074,5,,Angoustrine-Villeneuve-des-Escaldes,Angoustrine-Villeneuve-des-Escaldes,66,Pyrénées-Orientales,76,Occitanie
66005,ANGOUSTRINE VILLENEUVE DES ESCALDES,66760,ANGOUSTRINE VILLENEUVE ESCALDES,VILLENEUVE DES ESCALDES,42.5644340706,1.95068130074,5,,Angoustrine-Villeneuve-des-Escaldes,Angoustrine-Villeneuve-des-Escaldes,66,Pyrénées-Orientales,76,Occitanie
66006,ANSIGNAN,66220,ANSIGNAN,,42.7627016726,2.52092387991,6,,Ansignan,Ansignan,66,Pyrénées-Orientales,76,Occitanie
66007,ARBOUSSOLS,66320,ARBOUSSOLS,,42.6660398231,2.49328385773,7,,Arboussols,Arboussols,66,Pyrénées-Orientales,76,Occitanie
66008,ARGELES SUR MER,66700,ARGELES SUR MER,,42.5352193463,3.02429862885,8,,Argelès-sur-Mer,Argelès-sur-Mer,66,Pyrénées-Orientales,76,Occitanie
66008,ARGELES SUR MER,66700,ARGELES SUR MER,ARGELES PLAGE,42.5352193463,3.02429862885,8,,Argelès-sur-Mer,Argelès-sur-Mer,66,Pyrénées-Orientales,76,Occitanie
66009,ARLES SUR TECH,66150,ARLES SUR TECH,,42.4498186611,2.62632454224,9,,Arles-sur-Tech,Arles-sur-Tech,66,Pyrénées-Orientales,76,Occitanie
66010,AYGUATEBIA TALAU,66360,AYGUATEBIA TALAU,,42.5529817606,2.18299990224,10,,Ayguatébia-Talau,Ayguatébia-Talau,66,Pyrénées-Orientales,76,Occitanie
66010,AYGUATEBIA TALAU,66360,AYGUATEBIA TALAU,TALAU,42.5529817606,2.18299990224,10,,Ayguatébia-Talau,Ayguatébia-Talau,66,Pyrénées-Orientales,76,Occitanie
66011,BAGES,66670,BAGES,,42.6073851516,2.88856969665,11,,Bages,Bages,66,Pyrénées-Orientales,76,Occitanie
66012,BAHO,66540,BAHO,,42.705784148,2.82066588737,12,,Baho,Baho,66,Pyrénées-Orientales,76,Occitanie
66013,BAILLESTAVY,66320,BAILLESTAVY,,42.5644827066,2.52207120058,13,,Baillestavy,Baillestavy,66,Pyrénées-Orientales,76,Occitanie
66014,BAIXAS,66390,BAIXAS,,42.7454275346,2.80569787207,14,,Baixas,Baixas,66,Pyrénées-Orientales,76,Occitanie
66015,BANYULS DELS ASPRES,66300,BANYULS DELS ASPRES,,42.5651533767,2.86636549196,15,,Banyuls-dels-Aspres,Banyuls-dels-Aspres,66,Pyrénées-Orientales,76,Occitanie
66016,BANYULS SUR MER,66650,BANYULS SUR MER,,42.4614750255,3.09989653778,16,,Banyuls-sur-Mer,Banyuls-sur-Mer,66,Pyrénées-Orientales,76,Occitanie
66017,LE BARCARES,66420,LE BARCARES,,42.8127710984,3.02834125149,17,Le,Barcarès,Le Barcarès,66,Pyrénées-Orientales,76,Occitanie
66017,LE BARCARES,66420,LE BARCARES,PORT BARCARES,42.8127710984,3.02834125149,17,Le,Barcarès,Le Barcarès,66,Pyrénées-Orientales,76,Occitanie
66018,LA BASTIDE,66110,LA BASTIDE,,42.5373299609,2.57199667182,18,La,Bastide,La Bastide,66,Pyrénées-Orientales,76,Occitanie
66019,BELESTA,66720,BELESTA,,42.7208095071,2.62374902434,19,,Bélesta,Bélesta,66,Pyrénées-Orientales,76,Occitanie
66020,BOLQUERE,66210,BOLQUERE,,42.5221194704,2.06518824241,20,,Bolquère,Bolquère,66,Pyrénées-Orientales,76,Occitanie
66020,BOLQUERE,66210,BOLQUERE,SUPERBOLQUERE,42.5221194704,2.06518824241,20,,Bolquère,Bolquère,66,Pyrénées-Orientales,76,Occitanie
66021,BOMPAS,66430,BOMPAS,,42.7286935999,2.94228068459,21,,Bompas,Bompas,66,Pyrénées-Orientales,76,Occitanie
66022,BOULE D AMONT,66130,BOULE D AMONT,,42.5879849764,2.59192090962,22,,Boule-d'Amont,Boule-d'Amont,66,Pyrénées-Orientales,76,Occitanie
66023,BOULETERNERE,66130,BOULETERNERE,,42.6445403346,2.58787927866,23,,Bouleternère,Bouleternère,66,Pyrénées-Orientales,76,Occitanie
66024,LE BOULOU,66160,LE BOULOU,,42.526358117,2.83157706562,24,Le,Boulou,Le Boulou,66,Pyrénées-Orientales,76,Occitanie
66025,BOURG MADAME,66760,BOURG MADAME,,42.4379971277,1.96090815348,25,,Bourg-Madame,Bourg-Madame,66,Pyrénées-Orientales,76,Occitanie
66025,BOURG MADAME,66760,BOURG MADAME,CALDEGAS,42.4379971277,1.96090815348,25,,Bourg-Madame,Bourg-Madame,66,Pyrénées-Orientales,76,Occitanie
66026,BROUILLA,66620,BROUILLA,,42.5745922327,2.89542921407,26,,Brouilla,Brouilla,66,Pyrénées-Orientales,76,Occitanie
66027,LA CABANASSE,66210,LA CABANASSE,,42.5024173109,2.10678145362,27,La,Cabanasse,La Cabanasse,66,Pyrénées-Orientales,76,Occitanie
66028,CABESTANY,66330,CABESTANY,,42.6790392044,2.94628633406,28,,Cabestany,Cabestany,66,Pyrénées-Orientales,76,Occitanie
66029,CAIXAS,66300,CAIXAS,,42.6003698334,2.66171249085,29,,Caixas,Caixas,66,Pyrénées-Orientales,76,Occitanie
66030,CALCE,66600,CALCE,,42.7479343111,2.75646495256,30,,Calce,Calce,66,Pyrénées-Orientales,76,Occitanie
66032,CALMEILLES,66400,CALMEILLES,,42.5519868705,2.67154077315,32,,Calmeilles,Calmeilles,66,Pyrénées-Orientales,76,Occitanie
66033,CAMELAS,66300,CAMELAS,,42.6394607157,2.68891489054,33,,Camélas,Camélas,66,Pyrénées-Orientales,76,Occitanie
66034,CAMPOME,66500,CAMPOME,,42.6431601092,2.38417455008,34,,Campôme,Campôme,66,Pyrénées-Orientales,76,Occitanie
66035,CAMPOUSSY,66730,CAMPOUSSY,,42.6951710472,2.45222227409,35,,Campoussy,Campoussy,66,Pyrénées-Orientales,76,Occitanie
66036,CANAVEILLES,66360,CANAVEILLES,,42.5344206968,2.2238422292,36,,Canaveilles,Canaveilles,66,Pyrénées-Orientales,76,Occitanie
66037,CANET EN ROUSSILLON,66140,CANET EN ROUSSILLON,,42.6841288047,3.01161744752,37,,Canet-en-Roussillon,Canet-en-Roussillon,66,Pyrénées-Orientales,76,Occitanie
66037,CANET EN ROUSSILLON,66140,CANET EN ROUSSILLON,CANET PLAGE,42.6841288047,3.01161744752,37,,Canet-en-Roussillon,Canet-en-Roussillon,66,Pyrénées-Orientales,76,Occitanie
66038,CANOHES,66680,CANOHES,,42.6518506927,2.83475138204,38,,Canohès,Canohès,66,Pyrénées-Orientales,76,Occitanie
66039,CARAMANY,66720,CARAMANY,,42.7373348032,2.559879696,39,,Caramany,Caramany,66,Pyrénées-Orientales,76,Occitanie
66040,CASEFABRE,66130,CASEFABRE,,42.6184473874,2.61434229279,40,,Casefabre,Casefabre,66,Pyrénées-Orientales,76,Occitanie
66041,CASES DE PENE,66600,CASES DE PENE,,42.7867407426,2.77853627321,41,,Cases-de-Pène,Cases-de-Pène,66,Pyrénées-Orientales,76,Occitanie
66042,CASSAGNES,66720,CASSAGNES,,42.7439142997,2.62441382617,42,,Cassagnes,Cassagnes,66,Pyrénées-Orientales,76,Occitanie
66043,CASTEIL,66820,CASTEIL,,42.5057155606,2.4200306299,43,,Casteil,Casteil,66,Pyrénées-Orientales,76,Occitanie
66044,CASTELNOU,66300,CASTELNOU,,42.6193831125,2.70904889838,44,,Castelnou,Castelnou,66,Pyrénées-Orientales,76,Occitanie
66045,CATLLAR,66500,CATLLAR,,42.6404379908,2.42258231457,45,,Catllar,Catllar,66,Pyrénées-Orientales,76,Occitanie
66046,CAUDIES DE FENOUILLEDES,66220,CAUDIES DE FENOUILLEDES,,42.8195033862,2.37751985492,46,,Caudiès-de-Fenouillèdes,Caudiès-de-Fenouillèdes,66,Pyrénées-Orientales,76,Occitanie
66047,CAUDIES DE CONFLENT,66360,CAUDIES DE CONFLENT,,42.563639266,2.1426420364,47,,Caudiès-de-Conflent,Caudiès-de-Conflent,66,Pyrénées-Orientales,76,Occitanie
66048,CERBERE,66290,CERBERE,,42.4439076617,3.14835500726,48,,Cerbère,Cerbère,66,Pyrénées-Orientales,76,Occitanie
66049,CERET,66400,CERET,,42.4739622057,2.75268021455,49,,Céret,Céret,66,Pyrénées-Orientales,76,Occitanie
66050,CLAIRA,66530,CLAIRA,,42.7641982638,2.94476145253,50,,Claira,Claira,66,Pyrénées-Orientales,76,Occitanie
66051,CLARA,66500,CLARA,,42.5844703036,2.4511008593,51,,Clara-Villerach,Clara-Villerach,66,Pyrénées-Orientales,76,Occitanie
66052,CODALET,66500,CODALET,,42.5997201968,2.41580874288,52,,Codalet,Codalet,66,Pyrénées-Orientales,76,Occitanie
66053,COLLIOURE,66190,COLLIOURE,,42.5130107325,3.07254121467,53,,Collioure,Collioure,66,Pyrénées-Orientales,76,Occitanie
66054,CONAT,66500,CONAT,,42.6169400651,2.33899950618,54,,Conat,Conat,66,Pyrénées-Orientales,76,Occitanie
66055,CORBERE,66130,CORBERE,,42.6509601236,2.66156013725,55,,Corbère,Corbère,66,Pyrénées-Orientales,76,Occitanie
66056,CORBERE LES CABANES,66130,CORBERE LES CABANES,,42.659851515,2.6824784207,56,,Corbère-les-Cabanes,Corbère-les-Cabanes,66,Pyrénées-Orientales,76,Occitanie
66057,CORNEILLA DE CONFLENT,66820,CORNEILLA DE CONFLENT,,42.5760974122,2.38267562909,57,,Corneilla-de-Conflent,Corneilla-de-Conflent,66,Pyrénées-Orientales,76,Occitanie
66058,CORNEILLA LA RIVIERE,66550,CORNEILLA LA RIVIERE,,42.709567145,2.72548561716,58,,Corneilla-la-Rivière,Corneilla-la-Rivière,66,Pyrénées-Orientales,76,Occitanie
66059,CORNEILLA DEL VERCOL,66200,CORNEILLA DEL VERCOL,,42.6232156993,2.95070116658,59,,Corneilla-del-Vercol,Corneilla-del-Vercol,66,Pyrénées-Orientales,76,Occitanie
66060,CORSAVY,66150,CORSAVY,,42.4826022431,2.5407943882,60,,Corsavy,Corsavy,66,Pyrénées-Orientales,76,Occitanie
66061,COUSTOUGES,66260,COUSTOUGES,,42.3559782284,2.63785307332,61,,Coustouges,Coustouges,66,Pyrénées-Orientales,76,Occitanie
66062,DORRES,66760,DORRES,,42.513691127,1.92704781601,62,,Dorres,Dorres,66,Pyrénées-Orientales,76,Occitanie
66063,LES CLUSES,66480,LES CLUSES,,42.4851649751,2.84398358463,63,Les,Cluses,Les Cluses,66,Pyrénées-Orientales,76,Occitanie
66064,EGAT,66120,EGAT,,42.5012125582,2.01713210956,64,,Égat,Égat,66,Pyrénées-Orientales,76,Occitanie
66065,ELNE,66200,ELNE,,42.6008592066,2.97119695146,65,,Elne,Elne,66,Pyrénées-Orientales,76,Occitanie
66066,ENVEITG,66760,ENVEITG,,42.4961765884,1.89318419154,66,,Enveitg,Enveitg,66,Pyrénées-Orientales,76,Occitanie
66067,ERR,66800,ERR,,42.4101855099,2.06608273686,67,,Err,Err,66,Pyrénées-Orientales,76,Occitanie
66068,ESCARO,66360,ESCARO,,42.5345574095,2.31898297559,68,,Escaro,Escaro,66,Pyrénées-Orientales,76,Occitanie
66069,ESPIRA DE L AGLY,66600,ESPIRA DE L AGLY,,42.7964740198,2.82234963668,69,,Espira-de-l'Agly,Espira-de-l'Agly,66,Pyrénées-Orientales,76,Occitanie
66070,ESPIRA DE CONFLENT,66320,ESPIRA DE CONFLENT,,42.6190777893,2.49374976371,70,,Espira-de-Conflent,Espira-de-Conflent,66,Pyrénées-Orientales,76,Occitanie
66071,ESTAGEL,66310,ESTAGEL,,42.7677306754,2.70754179553,71,,Estagel,Estagel,66,Pyrénées-Orientales,76,Occitanie
66072,ESTAVAR,66800,ESTAVAR,,42.4747536018,2.00935678563,72,,Estavar,Estavar,66,Pyrénées-Orientales,76,Occitanie
66073,ESTOHER,66320,ESTOHER,,42.5679910483,2.48208662183,73,,Estoher,Estoher,66,Pyrénées-Orientales,76,Occitanie
66074,EUS,66500,EUS,,42.657654474,2.44661138862,74,,Eus,Eus,66,Pyrénées-Orientales,76,Occitanie
66075,EYNE,66800,EYNE,,42.4551578677,2.10885426854,75,,Eyne,Eyne,66,Pyrénées-Orientales,76,Occitanie
66076,FELLUNS,66730,FELLUNS,,42.7608211376,2.48853840388,76,,Felluns,Felluns,66,Pyrénées-Orientales,76,Occitanie
66077,FENOUILLET,66220,FENOUILLET,,42.7823278894,2.37368151493,77,,Fenouillet,Fenouillet,66,Pyrénées-Orientales,76,Occitanie
66078,FILLOLS,66820,FILLOLS,,42.5582297774,2.42208610221,78,,Fillols,Fillols,66,Pyrénées-Orientales,76,Occitanie
66079,FINESTRET,66320,FINESTRET,,42.6040161713,2.51579255516,79,,Finestret,Finestret,66,Pyrénées-Orientales,76,Occitanie
66080,FONTPEDROUSE,66360,FONTPEDROUSE,,42.4628889701,2.19485745621,80,,Fontpédrouse,Fontpédrouse,66,Pyrénées-Orientales,76,Occitanie
66081,FONTRABIOUSE,66210,FONTRABIOUSE,,42.6426527575,2.05312603045,81,,Fontrabiouse,Fontrabiouse,66,Pyrénées-Orientales,76,Occitanie
66082,FORMIGUERES,66210,FORMIGUERES,,42.6209968401,2.05402456557,82,,Formiguères,Formiguères,66,Pyrénées-Orientales,76,Occitanie
66083,FOSSE,66220,FOSSE,,42.7858632622,2.43269558007,83,,Fosse,Fosse,66,Pyrénées-Orientales,76,Occitanie
66084,FOURQUES,66300,FOURQUES,,42.5851203665,2.77662110647,84,,Fourques,Fourques,66,Pyrénées-Orientales,76,Occitanie
66085,FUILLA,66820,FUILLA,,42.5642517,2.35588100076,85,,Fuilla,Fuilla,66,Pyrénées-Orientales,76,Occitanie
66086,GLORIANES,66320,GLORIANES,,42.5942469638,2.55462590969,86,,Glorianes,Glorianes,66,Pyrénées-Orientales,76,Occitanie
66088,ILLE SUR TET,66130,ILLE SUR TET,,42.6774223178,2.61639804475,88,,Ille-sur-Têt,Ille-sur-Têt,66,Pyrénées-Orientales,76,Occitanie
66089,JOCH,66320,JOCH,,42.6155239513,2.52614634923,89,,Joch,Joch,66,Pyrénées-Orientales,76,Occitanie
66090,JUJOLS,66360,JUJOLS,,42.5835759835,2.28439855065,90,,Jujols,Jujols,66,Pyrénées-Orientales,76,Occitanie
66091,LAMANERE,66230,LAMANERE,,42.3581335381,2.51960965655,91,,Lamanère,Lamanère,66,Pyrénées-Orientales,76,Occitanie
66092,LANSAC,66720,LANSAC,,42.768061594,2.56512635708,92,,Lansac,Lansac,66,Pyrénées-Orientales,76,Occitanie
66093,LAROQUE DES ALBERES,66740,LAROQUE DES ALBERES,,42.5116805013,2.93303085148,93,,Laroque-des-Albères,Laroque-des-Albères,66,Pyrénées-Orientales,76,Occitanie
66094,LATOUR BAS ELNE,66200,LATOUR BAS ELNE,,42.6043043945,3.0052419925,94,,Latour-Bas-Elne,Latour-Bas-Elne,66,Pyrénées-Orientales,76,Occitanie
66095,LATOUR DE CAROL,66760,LATOUR DE CAROL,,42.4737507924,1.87250178908,95,,Latour-de-Carol,Latour-de-Carol,66,Pyrénées-Orientales,76,Occitanie
66096,LATOUR DE FRANCE,66720,LATOUR DE FRANCE,,42.7704659309,2.65156604374,96,,Latour-de-France,Latour-de-France,66,Pyrénées-Orientales,76,Occitanie
66097,LESQUERDE,66220,LESQUERDE,,42.7937247188,2.544623285,97,,Lesquerde,Lesquerde,66,Pyrénées-Orientales,76,Occitanie
66098,LA LLAGONNE,66210,LA LLAGONNE,,42.5372670169,2.09507395014,98,La,Llagonne,La Llagonne,66,Pyrénées-Orientales,76,Occitanie
66099,LLAURO,66300,LLAURO,,42.5460172155,2.74437234146,99,,Llauro,Llauro,66,Pyrénées-Orientales,76,Occitanie
66100,LLO,66800,LLO,,42.4335821703,2.09024996022,100,,Llo,Llo,66,Pyrénées-Orientales,76,Occitanie
66101,LLUPIA,66300,LLUPIA,,42.62594544,2.78302556037,101,,Llupia,Llupia,66,Pyrénées-Orientales,76,Occitanie
66102,MANTET,66360,MANTET,,42.4532803899,2.29477774171,102,,Mantet,Mantet,66,Pyrénées-Orientales,76,Occitanie
66103,MARQUIXANES,66320,MARQUIXANES,,42.6377902043,2.48426796216,103,,Marquixanes,Marquixanes,66,Pyrénées-Orientales,76,Occitanie
66104,LOS MASOS,66500,LOS MASOS,,42.6155047259,2.46056294865,104,Los,Masos,Los Masos,66,Pyrénées-Orientales,76,Occitanie
66105,MATEMALE,66210,MATEMALE,,42.5770065721,2.11612519256,105,,Matemale,Matemale,66,Pyrénées-Orientales,76,Occitanie
66106,MAUREILLAS LAS ILLAS,66480,MAUREILLAS LAS ILLAS,,42.460076792,2.8011796582,106,,Maureillas-las-Illas,Maureillas-las-Illas,66,Pyrénées-Orientales,76,Occitanie
66106,MAUREILLAS LAS ILLAS,66480,MAUREILLAS LAS ILLAS,LAS ILLAS,42.460076792,2.8011796582,106,,Maureillas-las-Illas,Maureillas-las-Illas,66,Pyrénées-Orientales,76,Occitanie
66106,MAUREILLAS LAS ILLAS,66480,MAUREILLAS LAS ILLAS,RIUNOGUES,42.460076792,2.8011796582,106,,Maureillas-las-Illas,Maureillas-las-Illas,66,Pyrénées-Orientales,76,Occitanie
66107,MAURY,66460,MAURY,,42.8152827939,2.61292653306,107,,Maury,Maury,66,Pyrénées-Orientales,76,Occitanie
66108,MILLAS,66170,MILLAS,,42.6989825431,2.68991104341,108,,Millas,Millas,66,Pyrénées-Orientales,76,Occitanie
66109,MOLITG LES BAINS,66500,MOLITG LES BAINS,,42.6637917961,2.39585353258,109,,Molitg-les-Bains,Molitg-les-Bains,66,Pyrénées-Orientales,76,Occitanie
66111,MONTALBA LE CHATEAU,66130,MONTALBA LE CHATEAU,,42.6999973124,2.56550996657,111,,Montalba-le-Château,Montalba-le-Château,66,Pyrénées-Orientales,76,Occitanie
66112,MONTAURIOL,66300,MONTAURIOL,,42.5766990784,2.72388992762,112,,Montauriol,Montauriol,66,Pyrénées-Orientales,76,Occitanie
66113,MONTBOLO,66110,MONTBOLO,,42.4911841677,2.63242543008,113,,Montbolo,Montbolo,66,Pyrénées-Orientales,76,Occitanie
66114,MONTESCOT,66200,MONTESCOT,,42.6154571229,2.92213721952,114,,Montescot,Montescot,66,Pyrénées-Orientales,76,Occitanie
66115,MONTESQUIEU DES ALBERES,66740,MONTESQUIEU DES ALBERES,,42.5181506161,2.8737459407,115,,Montesquieu-des-Albères,Montesquieu-des-Albères,66,Pyrénées-Orientales,76,Occitanie
66116,MONTFERRER,66150,MONTFERRER,,42.4434669097,2.5643017322,116,,Montferrer,Montferrer,66,Pyrénées-Orientales,76,Occitanie
66117,MONT LOUIS,66210,MONT LOUIS,,42.5104146008,2.1202342386,117,,Mont-Louis,Mont-Louis,66,Pyrénées-Orientales,76,Occitanie
66118,MONTNER,66720,MONTNER,,42.7408986624,2.68850770885,118,,Montner,Montner,66,Pyrénées-Orientales,76,Occitanie
66119,MOSSET,66500,MOSSET,,42.6739429511,2.30444228005,119,,Mosset,Mosset,66,Pyrénées-Orientales,76,Occitanie
66120,NAHUJA,66340,NAHUJA,,42.4234772569,1.99829049597,120,,Nahuja,Nahuja,66,Pyrénées-Orientales,76,Occitanie
66121,NEFIACH,66170,NEFIACH,,42.6961258361,2.65843590263,121,,Néfiach,Néfiach,66,Pyrénées-Orientales,76,Occitanie
66122,NOHEDES,66500,NOHEDES,,42.6287938288,2.26078366597,122,,Nohèdes,Nohèdes,66,Pyrénées-Orientales,76,Occitanie
66123,NYER,66360,NYER,,42.5010890315,2.27403031665,123,,Nyer,Nyer,66,Pyrénées-Orientales,76,Occitanie
66124,FONT ROMEU ODEILLO VIA,66120,FONT ROMEU ODEILLO VIA,,42.513242501,2.02697700093,124,,Font-Romeu-Odeillo-Via,Font-Romeu-Odeillo-Via,66,Pyrénées-Orientales,76,Occitanie
66124,FONT ROMEU ODEILLO VIA,66120,FONT ROMEU ODEILLO VIA,ODEILLO VIA,42.513242501,2.02697700093,124,,Font-Romeu-Odeillo-Via,Font-Romeu-Odeillo-Via,66,Pyrénées-Orientales,76,Occitanie
66125,OLETTE,66360,OLETTE,,42.5955754383,2.24262771832,125,,Olette,Olette,66,Pyrénées-Orientales,76,Occitanie
66126,OMS,66400,OMS,,42.5336508458,2.709631236,126,,Oms,Oms,66,Pyrénées-Orientales,76,Occitanie
66127,OPOUL PERILLOS,66600,OPOUL PERILLOS,,42.8800628012,2.85805113344,127,,Opoul-Périllos,Opoul-Périllos,66,Pyrénées-Orientales,76,Occitanie
66127,OPOUL PERILLOS,66600,OPOUL PERILLOS,PERILLOS,42.8800628012,2.85805113344,127,,Opoul-Périllos,Opoul-Périllos,66,Pyrénées-Orientales,76,Occitanie
66128,OREILLA,66360,OREILLA,,42.5763756825,2.2219872927,128,,Oreilla,Oreilla,66,Pyrénées-Orientales,76,Occitanie
66129,ORTAFFA,66560,ORTAFFA,,42.5843466688,2.92083398591,129,,Ortaffa,Ortaffa,66,Pyrénées-Orientales,76,Occitanie
66130,OSSEJA,66340,OSSEJA,,42.3951873215,1.99778156297,130,,Osséja,Osséja,66,Pyrénées-Orientales,76,Occitanie
66132,PALAU DE CERDAGNE,66340,PALAU DE CERDAGNE,,42.3857763378,1.97991972852,132,,Palau-de-Cerdagne,Palau-de-Cerdagne,66,Pyrénées-Orientales,76,Occitanie
66133,PALAU DEL VIDRE,66690,PALAU DEL VIDRE,,42.5687032771,2.95809890817,133,,Palau-del-Vidre,Palau-del-Vidre,66,Pyrénées-Orientales,76,Occitanie
66134,PASSA,66300,PASSA,,42.5650078478,2.80463901382,134,,Passa,Passa,66,Pyrénées-Orientales,76,Occitanie
66136,PERPIGNAN,66000,PERPIGNAN,,42.6965954131,2.89936953979,136,,Perpignan,Perpignan,66,Pyrénées-Orientales,76,Occitanie
66136,PERPIGNAN,66100,PERPIGNAN,,42.6965954131,2.89936953979,136,,Perpignan,Perpignan,66,Pyrénées-Orientales,76,Occitanie
66137,LE PERTHUS,66480,LE PERTHUS,,42.4661561009,2.85850748207,137,Le,Perthus,Le Perthus,66,Pyrénées-Orientales,76,Occitanie
66138,PEYRESTORTES,66600,PEYRESTORTES,,42.7483532712,2.8456847121,138,,Peyrestortes,Peyrestortes,66,Pyrénées-Orientales,76,Occitanie
66139,PEZILLA DE CONFLENT,66730,PEZILLA DE CONFLENT,,42.7356387796,2.48928199955,139,,Pézilla-de-Conflent,Pézilla-de-Conflent,66,Pyrénées-Orientales,76,Occitanie
66140,PEZILLA LA RIVIERE,66370,PEZILLA LA RIVIERE,,42.7052766962,2.76420559832,140,,Pézilla-la-Rivière,Pézilla-la-Rivière,66,Pyrénées-Orientales,76,Occitanie
66141,PIA,66380,PIA,,42.7487012331,2.91497381059,141,,Pia,Pia,66,Pyrénées-Orientales,76,Occitanie
66142,PLANES,66210,PLANES,,42.4728951595,2.14556980825,142,,Planès,Planès,66,Pyrénées-Orientales,76,Occitanie
66143,PLANEZES,66720,PLANEZES,,42.7742310967,2.62167277524,143,,Planèzes,Planèzes,66,Pyrénées-Orientales,76,Occitanie
66144,POLLESTRES,66450,POLLESTRES,,42.6374827748,2.872863153,144,,Pollestres,Pollestres,66,Pyrénées-Orientales,76,Occitanie
66145,PONTEILLA,66300,PONTEILLA,,42.632806748,2.82639975565,145,,Ponteilla,Ponteilla,66,Pyrénées-Orientales,76,Occitanie
66146,PORTA,66760,PORTA,,42.515624655,1.79216910759,146,,Porta,Porta,66,Pyrénées-Orientales,76,Occitanie
66147,PORTE PUYMORENS,66760,PORTE PUYMORENS,,42.555415554,1.83547052209,147,,Porté-Puymorens,Porté-Puymorens,66,Pyrénées-Orientales,76,Occitanie
66148,PORT VENDRES,66660,PORT VENDRES,,42.5025817829,3.10455891207,148,,Port-Vendres,Port-Vendres,66,Pyrénées-Orientales,76,Occitanie
66149,PRADES,66500,PRADES,,42.6145021996,2.43074849066,149,,Prades,Prades,66,Pyrénées-Orientales,76,Occitanie
66150,PRATS DE MOLLO LA PRESTE,66230,PRATS DE MOLLO LA PRESTE,,42.4201535171,2.44098964514,150,,Prats-de-Mollo-la-Preste,Prats-de-Mollo-la-Preste,66,Pyrénées-Orientales,76,Occitanie
66150,PRATS DE MOLLO LA PRESTE,66230,PRATS DE MOLLO LA PRESTE,LA PRESTE,42.4201535171,2.44098964514,150,,Prats-de-Mollo-la-Preste,Prats-de-Mollo-la-Preste,66,Pyrénées-Orientales,76,Occitanie
66151,PRATS DE SOURNIA,66730,PRATS DE SOURNIA,,42.7426820307,2.45927988592,151,,Prats-de-Sournia,Prats-de-Sournia,66,Pyrénées-Orientales,76,Occitanie
66152,PRUGNANES,66220,PRUGNANES,,42.8268896774,2.43647245139,152,,Prugnanes,Prugnanes,66,Pyrénées-Orientales,76,Occitanie
66153,PRUNET ET BELPUIG,66130,PRUNET ET BELPUIG,,42.5661186047,2.63306302211,153,,Prunet-et-Belpuig,Prunet-et-Belpuig,66,Pyrénées-Orientales,76,Occitanie
66154,PUYVALADOR,66210,PUYVALADOR,,42.6554096645,2.10499616332,154,,Puyvalador,Puyvalador,66,Pyrénées-Orientales,76,Occitanie
66155,PY,66360,PY,,42.4733047636,2.36001969105,155,,Py,Py,66,Pyrénées-Orientales,76,Occitanie
66156,RABOUILLET,66730,RABOUILLET,,42.7273037833,2.37109677548,156,,Rabouillet,Rabouillet,66,Pyrénées-Orientales,76,Occitanie
66157,RAILLEU,66360,RAILLEU,,42.5900683086,2.15898183392,157,,Railleu,Railleu,66,Pyrénées-Orientales,76,Occitanie
66158,RASIGUERES,66720,RASIGUERES,,42.7745406859,2.59533841168,158,,Rasiguères,Rasiguères,66,Pyrénées-Orientales,76,Occitanie
66159,REAL,66210,REAL,,42.6407525568,2.14619018938,159,,Réal,Réal,66,Pyrénées-Orientales,76,Occitanie
66160,REYNES,66400,REYNES,,42.475553684,2.70559051736,160,,Reynès,Reynès,66,Pyrénées-Orientales,76,Occitanie
66161,RIA SIRACH,66500,RIA SIRACH,,42.6128760298,2.38920846568,161,,Ria-Sirach,Ria-Sirach,66,Pyrénées-Orientales,76,Occitanie
66162,RIGARDA,66320,RIGARDA,,42.6279224154,2.5378645197,162,,Rigarda,Rigarda,66,Pyrénées-Orientales,76,Occitanie
66164,RIVESALTES,66600,RIVESALTES,,42.7785910442,2.87687296538,164,,Rivesaltes,Rivesaltes,66,Pyrénées-Orientales,76,Occitanie
66165,RODES,66320,RODES,,42.6564198443,2.55013246871,165,,Rodès,Rodès,66,Pyrénées-Orientales,76,Occitanie
66166,SAHORRE,66360,SAHORRE,,42.5235898496,2.35864365675,166,,Sahorre,Sahorre,66,Pyrénées-Orientales,76,Occitanie
66167,SAILLAGOUSE,66800,SAILLAGOUSE,,42.459329456,2.03972104811,167,,Saillagouse,Saillagouse,66,Pyrénées-Orientales,76,Occitanie
66168,ST ANDRE,66690,ST ANDRE,,42.5524573215,2.98000507261,168,,Saint-André,Saint-André,66,Pyrénées-Orientales,76,Occitanie
66169,ST ARNAC,66220,ST ARNAC,,42.7767967015,2.53565116152,169,,Saint-Arnac,Saint-Arnac,66,Pyrénées-Orientales,76,Occitanie
66170,STE COLOMBE DE LA COMMANDERIE,66300,STE COLOMBE DE LA COMMANDERIE,,42.6154060137,2.73987613858,170,,Sainte-Colombe-de-la-Commanderie,Sainte-Colombe-de-la-Commanderie,66,Pyrénées-Orientales,76,Occitanie
66171,ST CYPRIEN,66750,ST CYPRIEN,,42.6220280294,3.01622014324,171,,Saint-Cyprien,Saint-Cyprien,66,Pyrénées-Orientales,76,Occitanie
66171,ST CYPRIEN,66750,ST CYPRIEN,ST CYPRIEN PLAGE,42.6220280294,3.01622014324,171,,Saint-Cyprien,Saint-Cyprien,66,Pyrénées-Orientales,76,Occitanie
66172,ST ESTEVE,66240,ST ESTEVE,,42.7151122221,2.84611914185,172,,Saint-Estève,Saint-Estève,66,Pyrénées-Orientales,76,Occitanie
66173,ST FELIU D AMONT,66170,ST FELIU D AMONT,,42.6762988692,2.71785569395,173,,Saint-Féliu-d'Amont,Saint-Féliu-d'Amont,66,Pyrénées-Orientales,76,Occitanie
66174,ST FELIU D AVALL,66170,ST FELIU D AVALL,,42.6726973076,2.74775654622,174,,Saint-Féliu-d'Avall,Saint-Féliu-d'Avall,66,Pyrénées-Orientales,76,Occitanie
66175,ST GENIS DES FONTAINES,66740,ST GENIS DES FONTAINES,,42.5501690702,2.92022187545,175,,Saint-Génis-des-Fontaines,Saint-Génis-des-Fontaines,66,Pyrénées-Orientales,76,Occitanie
66176,ST HIPPOLYTE,66510,ST HIPPOLYTE,,42.806945541,2.97367162246,176,,Saint-Hippolyte,Saint-Hippolyte,66,Pyrénées-Orientales,76,Occitanie
66177,ST JEAN LASSEILLE,66300,ST JEAN LASSEILLE,,42.5862325168,2.86488169804,177,,Saint-Jean-Lasseille,Saint-Jean-Lasseille,66,Pyrénées-Orientales,76,Occitanie
66178,ST JEAN PLA DE CORTS,66490,ST JEAN PLA DE CORTS,,42.515467363,2.79428656792,178,,Saint-Jean-Pla-de-Corts,Saint-Jean-Pla-de-Corts,66,Pyrénées-Orientales,76,Occitanie
66179,ST LAURENT DE CERDANS,66260,ST LAURENT DE CERDANS,,42.3968213012,2.62129986859,179,,Saint-Laurent-de-Cerdans,Saint-Laurent-de-Cerdans,66,Pyrénées-Orientales,76,Occitanie
66180,ST LAURENT DE LA SALANQUE,66250,ST LAURENT DE LA SALANQUE,,42.7935650332,3.00207879592,180,,Saint-Laurent-de-la-Salanque,Saint-Laurent-de-la-Salanque,66,Pyrénées-Orientales,76,Occitanie
66181,STE LEOCADIE,66800,STE LEOCADIE,,42.434356099,2.00830705641,181,,Sainte-Léocadie,Sainte-Léocadie,66,Pyrénées-Orientales,76,Occitanie
66182,STE MARIE,66470,STE MARIE LA MER,,42.7262501426,3.017081634,182,,Sainte-Marie-la-Mer,Sainte-Marie-la-Mer,66,Pyrénées-Orientales,76,Occitanie
66183,ST MARSAL,66110,ST MARSAL,,42.5276130849,2.61404559524,183,,Saint-Marsal,Saint-Marsal,66,Pyrénées-Orientales,76,Occitanie
66184,ST MARTIN DE FENOUILLET,66220,ST MARTIN DE FENOUILLET,,42.7853593201,2.47859695441,184,,Saint-Martin-de-Fenouillet,Saint-Martin-de-Fenouillet,66,Pyrénées-Orientales,76,Occitanie
66185,ST MICHEL DE LLOTES,66130,ST MICHEL DE LLOTES,,42.6445028805,2.62483449266,185,,Saint-Michel-de-Llotes,Saint-Michel-de-Llotes,66,Pyrénées-Orientales,76,Occitanie
66186,ST NAZAIRE,66570,ST NAZAIRE,,42.6621428836,2.98670142131,186,,Saint-Nazaire,Saint-Nazaire,66,Pyrénées-Orientales,76,Occitanie
66187,ST PAUL DE FENOUILLET,66220,ST PAUL DE FENOUILLET,,42.8206748492,2.50723870282,187,,Saint-Paul-de-Fenouillet,Saint-Paul-de-Fenouillet,66,Pyrénées-Orientales,76,Occitanie
66188,ST PIERRE DELS FORCATS,66210,ST PIERRE DELS FORCATS,,42.4802464548,2.11815640127,188,,Saint-Pierre-dels-Forcats,Saint-Pierre-dels-Forcats,66,Pyrénées-Orientales,76,Occitanie
66189,SALEILLES,66280,SALEILLES,,42.6568410057,2.94920487175,189,,Saleilles,Saleilles,66,Pyrénées-Orientales,76,Occitanie
66190,SALSES LE CHATEAU,66600,SALSES LE CHATEAU,,42.8371985526,2.91593580268,190,,Salses-le-Château,Salses-le-Château,66,Pyrénées-Orientales,76,Occitanie
66191,SANSA,66360,SANSA,,42.6192728881,2.18058547197,191,,Sansa,Sansa,66,Pyrénées-Orientales,76,Occitanie
66192,SAUTO,66210,SAUTO,,42.5155748521,2.14916955893,192,,Sauto,Sauto,66,Pyrénées-Orientales,76,Occitanie
66193,SERDINYA,66360,SERDINYA,,42.5762876689,2.32227751946,193,,Serdinya,Serdinya,66,Pyrénées-Orientales,76,Occitanie
66194,SERRALONGUE,66230,SERRALONGUE,,42.3823857102,2.55698391663,194,,Serralongue,Serralongue,66,Pyrénées-Orientales,76,Occitanie
66195,LE SOLER,66270,LE SOLER,,42.6776853173,2.79565359118,195,Le,Soler,Le Soler,66,Pyrénées-Orientales,76,Occitanie
66196,SOREDE,66690,SOREDE,,42.4998734367,2.98047193551,196,,Sorède,Sorède,66,Pyrénées-Orientales,76,Occitanie
66197,SOUANYAS,66360,SOUANYAS,,42.5480499348,2.28418328185,197,,Souanyas,Souanyas,66,Pyrénées-Orientales,76,Occitanie
66198,SOURNIA,66730,SOURNIA,,42.7145850247,2.41454778007,198,,Sournia,Sournia,66,Pyrénées-Orientales,76,Occitanie
66199,TAILLET,66400,TAILLET,,42.5171359573,2.67866498314,199,,Taillet,Taillet,66,Pyrénées-Orientales,76,Occitanie
66201,TARERACH,66320,TARERACH,,42.6850004554,2.51090245856,201,,Tarerach,Tarerach,66,Pyrénées-Orientales,76,Occitanie
66202,TARGASSONNE,66120,TARGASSONNE,,42.5032914509,1.98870542863,202,,Targassonne,Targassonne,66,Pyrénées-Orientales,76,Occitanie
66203,TAULIS,66110,TAULIS,,42.5134541453,2.61842052411,203,,Taulis,Taulis,66,Pyrénées-Orientales,76,Occitanie
66204,TAURINYA,66500,TAURINYA,,42.5592864302,2.44208129657,204,,Taurinya,Taurinya,66,Pyrénées-Orientales,76,Occitanie
66205,TAUTAVEL,66720,TAUTAVEL,,42.8136035868,2.72010210415,205,,Tautavel,Tautavel,66,Pyrénées-Orientales,76,Occitanie
66206,LE TECH,66230,LE TECH,,42.4414680314,2.51364104722,206,Le,Tech,Le Tech,66,Pyrénées-Orientales,76,Occitanie
66207,TERRATS,66300,TERRATS,,42.6033088129,2.75967553668,207,,Terrats,Terrats,66,Pyrénées-Orientales,76,Occitanie
66208,THEZA,66200,THEZA,,42.6401080331,2.94564275118,208,,Théza,Théza,66,Pyrénées-Orientales,76,Occitanie
66209,THUES ENTRE VALLS,66360,THUES ENTRE VALLS,,42.5040638697,2.22986633977,209,,Thuès-Entre-Valls,Thuès-Entre-Valls,66,Pyrénées-Orientales,76,Occitanie
66210,THUIR,66300,THUIR,,42.6434195538,2.75897243961,210,,Thuir,Thuir,66,Pyrénées-Orientales,76,Occitanie
66211,TORDERES,66300,TORDERES,,42.5655208272,2.75949456771,211,,Tordères,Tordères,66,Pyrénées-Orientales,76,Occitanie
66212,TORREILLES,66440,TORREILLES,,42.7533924686,3.00860614432,212,,Torreilles,Torreilles,66,Pyrénées-Orientales,76,Occitanie
66213,TOULOUGES,66350,TOULOUGES,,42.6696131691,2.82406854776,213,,Toulouges,Toulouges,66,Pyrénées-Orientales,76,Occitanie
66214,TRESSERRE,66300,TRESSERRE,,42.5556320578,2.83755303653,214,,Tresserre,Tresserre,66,Pyrénées-Orientales,76,Occitanie
66215,TREVILLACH,66130,TREVILLACH,,42.7095753054,2.50886341936,215,,Trévillach,Trévillach,66,Pyrénées-Orientales,76,Occitanie
66216,TRILLA,66220,TRILLA,,42.7387475959,2.52142584284,216,,Trilla,Trilla,66,Pyrénées-Orientales,76,Occitanie
66217,TROUILLAS,66300,TROUILLAS,,42.6064392439,2.82021173802,217,,Trouillas,Trouillas,66,Pyrénées-Orientales,76,Occitanie
66218,UR,66760,UR,,42.4618525308,1.941668494,218,,Ur,Ur,66,Pyrénées-Orientales,76,Occitanie
66219,URBANYA,66500,URBANYA,,42.6433739876,2.29947678761,219,,Urbanya,Urbanya,66,Pyrénées-Orientales,76,Occitanie
66220,VALCEBOLLERE,66340,VALCEBOLLERE,,42.3803353633,2.04302555825,220,,Valcebollère,Valcebollère,66,Pyrénées-Orientales,76,Occitanie
66221,VALMANYA,66320,VALMANYA,,42.5215439119,2.5139596876,221,,Valmanya,Valmanya,66,Pyrénées-Orientales,76,Occitanie
66222,VERNET LES BAINS,66820,VERNET LES BAINS,,42.5385252811,2.41579513309,222,,Vernet-les-Bains,Vernet-les-Bains,66,Pyrénées-Orientales,76,Occitanie
66223,VILLEFRANCHE DE CONFLENT,66500,VILLEFRANCHE DE CONFLENT,,42.5964793448,2.3599348086,223,,Villefranche-de-Conflent,Villefranche-de-Conflent,66,Pyrénées-Orientales,76,Occitanie
66224,VILLELONGUE DE LA SALANQUE,66410,VILLELONGUE DE LA SALANQUE,,42.7256098922,2.97746160668,224,,Villelongue-de-la-Salanque,Villelongue-de-la-Salanque,66,Pyrénées-Orientales,76,Occitanie
66225,VILLELONGUE DELS MONTS,66740,VILLELONGUE DELS MONTS,,42.5236011382,2.89994789711,225,,Villelongue-dels-Monts,Villelongue-dels-Monts,66,Pyrénées-Orientales,76,Occitanie
66226,VILLEMOLAQUE,66300,VILLEMOLAQUE,,42.5931339433,2.84776970877,226,,Villemolaque,Villemolaque,66,Pyrénées-Orientales,76,Occitanie
66227,VILLENEUVE DE LA RAHO,66180,VILLENEUVE DE LA RAHO,,42.6399917541,2.91045028538,227,,Villeneuve-de-la-Raho,Villeneuve-de-la-Raho,66,Pyrénées-Orientales,76,Occitanie
66228,VILLENEUVE LA RIVIERE,66610,VILLENEUVE LA RIVIERE,,42.7052906272,2.79873686308,228,,Villeneuve-la-Rivière,Villeneuve-la-Rivière,66,Pyrénées-Orientales,76,Occitanie
66230,VINCA,66320,VINCA,,42.64326224,2.52272432664,230,,Vinça,Vinça,66,Pyrénées-Orientales,76,Occitanie
66231,VINGRAU,66600,VINGRAU,,42.8577422264,2.7906568133,231,,Vingrau,Vingrau,66,Pyrénées-Orientales,76,Occitanie
66232,VIRA,66220,VIRA,,42.7632437549,2.39447328597,232,,Vira,Vira,66,Pyrénées-Orientales,76,Occitanie
66233,VIVES,66490,VIVES,,42.5292840825,2.767358635,233,,Vivès,Vivès,66,Pyrénées-Orientales,76,Occitanie
66234,LE VIVIER,66730,LE VIVIER,,42.7641929196,2.44351748783,234,Le,Vivier,Le Vivier,66,Pyrénées-Orientales,76,Occitanie
67001,ACHENHEIM,67204,ACHENHEIM,,48.58046231,7.62540605297,1,,Achenheim,Achenheim,67,Bas-Rhin,44,Grand Est
67002,ADAMSWILLER,67320,ADAMSWILLER,,48.9062078499,7.20176753115,2,,Adamswiller,Adamswiller,67,Bas-Rhin,44,Grand Est
67003,ALBE,67220,ALBE,,48.3614659809,7.33650221165,3,,Albé,Albé,67,Bas-Rhin,44,Grand Est
67004,SOMMERAU,67310,SOMMERAU,ALLENWILLER,48.6536185967,7.36691370722,4,,Sommerau,Sommerau,67,Bas-Rhin,44,Grand Est
67004,SOMMERAU,67440,SOMMERAU,BIRKENWALD,48.6536185967,7.36691370722,4,,Sommerau,Sommerau,67,Bas-Rhin,44,Grand Est
67004,SOMMERAU,67440,SOMMERAU,SALENTHAL,48.6536185967,7.36691370722,4,,Sommerau,Sommerau,67,Bas-Rhin,44,Grand Est
67004,SOMMERAU,67440,SOMMERAU,SINGRIST,48.6536185967,7.36691370722,4,,Sommerau,Sommerau,67,Bas-Rhin,44,Grand Est
67005,ALTECKENDORF,67270,ALTECKENDORF,,48.7944549979,7.59641028848,5,,Alteckendorf,Alteckendorf,67,Bas-Rhin,44,Grand Est
67006,ALTENHEIM,67490,ALTENHEIM,,48.7200663423,7.46240989793,6,,Altenheim,Altenheim,67,Bas-Rhin,44,Grand Est
67008,ALTORF,67120,ALTORF,,48.5247239067,7.53898226259,8,,Altorf,Altorf,67,Bas-Rhin,44,Grand Est
67009,ALTWILLER,67260,ALTWILLER,,48.9155179716,6.97971818024,9,,Altwiller,Altwiller,67,Bas-Rhin,44,Grand Est
67010,ANDLAU,67140,ANDLAU,,48.3907003871,7.38488102933,10,,Andlau,Andlau,67,Bas-Rhin,44,Grand Est
67011,ARTOLSHEIM,67390,ARTOLSHEIM,,48.2079373474,7.60004702604,11,,Artolsheim,Artolsheim,67,Bas-Rhin,44,Grand Est
67012,ASCHBACH,67250,ASCHBACH,,48.9421865958,7.98028356812,12,,Aschbach,Aschbach,67,Bas-Rhin,44,Grand Est
67013,ASSWILLER,67320,ASSWILLER,,48.8805887917,7.224373364,13,,Asswiller,Asswiller,67,Bas-Rhin,44,Grand Est
67014,AUENHEIM,67480,AUENHEIM,,48.8090909724,8.01736090911,14,,Auenheim,Auenheim,67,Bas-Rhin,44,Grand Est
67016,AVOLSHEIM,67120,AVOLSHEIM,,48.5605883589,7.49416534055,16,,Avolsheim,Avolsheim,67,Bas-Rhin,44,Grand Est
67017,BAERENDORF,67320,BAERENDORF,,48.8306192171,7.08989424787,17,,Baerendorf,Baerendorf,67,Bas-Rhin,44,Grand Est
67018,BALBRONN,67310,BALBRONN,,48.5825386976,7.40408500338,18,,Balbronn,Balbronn,67,Bas-Rhin,44,Grand Est
67019,BALDENHEIM,67600,BALDENHEIM,,48.2444211,7.53586844634,19,,Baldenheim,Baldenheim,67,Bas-Rhin,44,Grand Est
67020,BAREMBACH,67130,BAREMBACH,,48.4670562489,7.24316580425,20,,Barembach,Barembach,67,Bas-Rhin,44,Grand Est
67021,BARR,67140,BARR,,48.4149512356,7.39930438782,21,,Barr,Barr,67,Bas-Rhin,44,Grand Est
67022,BASSEMBERG,67220,BASSEMBERG,,48.3359466326,7.27796558319,22,,Bassemberg,Bassemberg,67,Bas-Rhin,44,Grand Est
67023,BATZENDORF,67500,BATZENDORF,,48.7820553601,7.70964609521,23,,Batzendorf,Batzendorf,67,Bas-Rhin,44,Grand Est
67025,BEINHEIM,67930,BEINHEIM,,48.8543766678,8.09164601373,25,,Beinheim,Beinheim,67,Bas-Rhin,44,Grand Est
67026,BELLEFOSSE,67130,BELLEFOSSE,,48.39467045,7.23463416375,26,,Bellefosse,Bellefosse,67,Bas-Rhin,44,Grand Est
67027,BELMONT,67130,BELMONT,,48.4104343878,7.25374115024,27,,Belmont,Belmont,67,Bas-Rhin,44,Grand Est
67028,BENFELD,67230,BENFELD,,48.3659229092,7.60197338557,28,,Benfeld,Benfeld,67,Bas-Rhin,44,Grand Est
67029,BERG,67320,BERG,,48.8928474217,7.14475787887,29,,Berg,Berg,67,Bas-Rhin,44,Grand Est
67030,BERGBIETEN,67310,BERGBIETEN,,48.5771244719,7.4507261973,30,,Bergbieten,Bergbieten,67,Bas-Rhin,44,Grand Est
67031,BERNARDSWILLER,67210,BERNARDSWILLER,,48.4473498624,7.45350882919,31,,Bernardswiller,Bernardswiller,67,Bas-Rhin,44,Grand Est
67032,BERNARDVILLE,67140,BERNARDVILLE,,48.3696169477,7.40684055557,32,,Bernardvillé,Bernardvillé,67,Bas-Rhin,44,Grand Est
67033,BERNOLSHEIM,67170,BERNOLSHEIM,,48.7529512586,7.68495908894,33,,Bernolsheim,Bernolsheim,67,Bas-Rhin,44,Grand Est
67034,BERSTETT,67370,BERSTETT,,48.6822332576,7.63237996044,34,,Berstett,Berstett,67,Bas-Rhin,44,Grand Est
67034,BERSTETT,67370,BERSTETT,GIMBRETT,48.6822332576,7.63237996044,34,,Berstett,Berstett,67,Bas-Rhin,44,Grand Est
67034,BERSTETT,67370,BERSTETT,REITWILLER,48.6822332576,7.63237996044,34,,Berstett,Berstett,67,Bas-Rhin,44,Grand Est
67034,BERSTETT,67370,BERSTETT,RUMERSHEIM,48.6822332576,7.63237996044,34,,Berstett,Berstett,67,Bas-Rhin,44,Grand Est
67035,BERSTHEIM,67170,BERSTHEIM,,48.7926179333,7.67977428284,35,,Berstheim,Berstheim,67,Bas-Rhin,44,Grand Est
67036,BETTWILLER,67320,BETTWILLER,,48.8879941966,7.17495885923,36,,Bettwiller,Bettwiller,67,Bas-Rhin,44,Grand Est
67037,BIBLISHEIM,67360,BIBLISHEIM,,48.8996840866,7.79109308708,37,,Biblisheim,Biblisheim,67,Bas-Rhin,44,Grand Est
67038,BIETLENHEIM,67720,BIETLENHEIM,,48.7204274461,7.7833019212,38,,Bietlenheim,Bietlenheim,67,Bas-Rhin,44,Grand Est
67039,BILWISHEIM,67170,BILWISHEIM,,48.7100680278,7.66330058564,39,,Bilwisheim,Bilwisheim,67,Bas-Rhin,44,Grand Est
67040,BINDERNHEIM,67600,BINDERNHEIM,,48.2870316499,7.61448549663,40,,Bindernheim,Bindernheim,67,Bas-Rhin,44,Grand Est
67043,BISCHHEIM,67800,BISCHHEIM,,48.6215316062,7.75864282518,43,,Bischheim,Bischheim,67,Bas-Rhin,44,Grand Est
67044,BISCHHOLTZ,67340,BISCHHOLTZ,,48.8907710974,7.53068422833,44,,Bischholtz,Bischholtz,67,Bas-Rhin,44,Grand Est
67045,BISCHOFFSHEIM,67870,BISCHOFFSHEIM,,48.4855267183,7.52599035848,45,,Bischoffsheim,Bischoffsheim,67,Bas-Rhin,44,Grand Est
67046,BISCHWILLER,67240,BISCHWILLER,,48.7581317312,7.8599836461,46,,Bischwiller,Bischwiller,67,Bas-Rhin,44,Grand Est
67047,BISSERT,67260,BISSERT,,48.9461819262,7.01770386276,47,,Bissert,Bissert,67,Bas-Rhin,44,Grand Est
67048,BITSCHHOFFEN,67350,BITSCHHOFFEN,,48.8628351945,7.62438682496,48,,Bitschhoffen,Bitschhoffen,67,Bas-Rhin,44,Grand Est
67049,BLAESHEIM,67113,BLAESHEIM,,48.5022035714,7.60873399472,49,,Blaesheim,Blaesheim,67,Bas-Rhin,44,Grand Est
67050,BLANCHERUPT,67130,BLANCHERUPT,,48.4071095997,7.19552856029,50,,Blancherupt,Blancherupt,67,Bas-Rhin,44,Grand Est
67051,BLIENSCHWILLER,67650,BLIENSCHWILLER,,48.3454000894,7.41533948904,51,,Blienschwiller,Blienschwiller,67,Bas-Rhin,44,Grand Est
67052,BOERSCH,67530,BOERSCH,,48.4664860323,7.37505113728,52,,Bœrsch,Bœrsch,67,Bas-Rhin,44,Grand Est
67052,BOERSCH,67530,BOERSCH,KLINGENTHAL,48.4664860323,7.37505113728,52,,Bœrsch,Bœrsch,67,Bas-Rhin,44,Grand Est
67053,BOESENBIESEN,67390,BOESENBIESEN,,48.2261928699,7.56229858754,53,,Bœsenbiesen,Bœsenbiesen,67,Bas-Rhin,44,Grand Est
67054,BOLSENHEIM,67150,BOLSENHEIM,,48.425239497,7.6039565984,54,,Bolsenheim,Bolsenheim,67,Bas-Rhin,44,Grand Est
67055,BOOFZHEIM,67860,BOOFZHEIM,,48.3343650586,7.6782140788,55,,Boofzheim,Boofzheim,67,Bas-Rhin,44,Grand Est
67056,BOOTZHEIM,67390,BOOTZHEIM,,48.1932692623,7.58948440794,56,,Bootzheim,Bootzheim,67,Bas-Rhin,44,Grand Est
67057,BOSSELSHAUSEN,67330,BOSSELSHAUSEN,,48.8061717193,7.50878423117,57,,Bosselshausen,Bosselshausen,67,Bas-Rhin,44,Grand Est
67058,BOSSENDORF,67270,BOSSENDORF,,48.781544071,7.56733864822,58,,Bossendorf,Bossendorf,67,Bas-Rhin,44,Grand Est
67059,BOURG BRUCHE,67420,BOURG BRUCHE,,48.358768312,7.143716963,59,,Bourg-Bruche,Bourg-Bruche,67,Bas-Rhin,44,Grand Est
67059,BOURG BRUCHE,67420,BOURG BRUCHE,BOURG,48.358768312,7.143716963,59,,Bourg-Bruche,Bourg-Bruche,67,Bas-Rhin,44,Grand Est
67060,BOURGHEIM,67140,BOURGHEIM,,48.4192706314,7.48915564819,60,,Bourgheim,Bourgheim,67,Bas-Rhin,44,Grand Est
67061,BOUXWILLER,67330,BOUXWILLER,,48.8172998086,7.46488021539,61,,Bouxwiller,Bouxwiller,67,Bas-Rhin,44,Grand Est
67061,BOUXWILLER,67330,BOUXWILLER,GRIESBACH LE BASTBERG,48.8172998086,7.46488021539,61,,Bouxwiller,Bouxwiller,67,Bas-Rhin,44,Grand Est
67061,BOUXWILLER,67330,BOUXWILLER,IMBSHEIM,48.8172998086,7.46488021539,61,,Bouxwiller,Bouxwiller,67,Bas-Rhin,44,Grand Est
67061,BOUXWILLER,67330,BOUXWILLER,RIEDHEIM,48.8172998086,7.46488021539,61,,Bouxwiller,Bouxwiller,67,Bas-Rhin,44,Grand Est
67062,BREITENAU,67220,BREITENAU,,48.316132686,7.27931381506,62,,Breitenau,Breitenau,67,Bas-Rhin,44,Grand Est
67063,BREITENBACH,67220,BREITENBACH,,48.374509707,7.28708160963,63,,Breitenbach,Breitenbach,67,Bas-Rhin,44,Grand Est
67065,BREUSCHWICKERSHEIM,67112,BREUSCHWICKERSHEIM,,48.578440458,7.59885897998,65,,Breuschwickersheim,Breuschwickersheim,67,Bas-Rhin,44,Grand Est
67066,LA BROQUE,67130,LA BROQUE,,48.4601655449,7.17283025504,66,La,Broque,La Broque,67,Bas-Rhin,44,Grand Est
67066,LA BROQUE,67130,LA BROQUE,FRECONRUPT,48.4601655449,7.17283025504,66,La,Broque,La Broque,67,Bas-Rhin,44,Grand Est
67066,LA BROQUE,67130,LA BROQUE,WACQUENOUX,48.4601655449,7.17283025504,66,La,Broque,La Broque,67,Bas-Rhin,44,Grand Est
67066,LA BROQUE,67570,LA BROQUE,,48.4601655449,7.17283025504,66,La,Broque,La Broque,67,Bas-Rhin,44,Grand Est
67066,LA BROQUE,67570,LA BROQUE,LA CLAQUETTE,48.4601655449,7.17283025504,66,La,Broque,La Broque,67,Bas-Rhin,44,Grand Est
67067,BRUMATH,67170,BRUMATH,,48.7204656106,7.71740193625,67,,Brumath,Brumath,67,Bas-Rhin,44,Grand Est
67068,BUSWILLER,67350,BUSWILLER,,48.8219680597,7.56228814034,68,,Buswiller,Buswiller,67,Bas-Rhin,44,Grand Est
67069,BUHL,67470,BUHL,,48.9172713817,8.01562633252,69,,Buhl,Buhl,67,Bas-Rhin,44,Grand Est
67070,BURBACH,67260,BURBACH,,48.8975113337,7.11153585144,70,,Burbach,Burbach,67,Bas-Rhin,44,Grand Est
67071,BUST,67320,BUST,,48.8338084584,7.23455655316,71,,Bust,Bust,67,Bas-Rhin,44,Grand Est
67072,BUTTEN,67430,BUTTEN,,48.9713955937,7.24356966874,72,,Butten,Butten,67,Bas-Rhin,44,Grand Est
67073,CHATENOIS,67730,CHATENOIS,,48.2803314907,7.38582937668,73,,Châtenois,Châtenois,67,Bas-Rhin,44,Grand Est
67074,CLEEBOURG,67160,CLEEBOURG,,48.9953521897,7.88602873126,74,,Cleebourg,Cleebourg,67,Bas-Rhin,44,Grand Est
67074,CLEEBOURG,67160,CLEEBOURG,BREMMELBACH,48.9953521897,7.88602873126,74,,Cleebourg,Cleebourg,67,Bas-Rhin,44,Grand Est
67075,CLIMBACH,67510,CLIMBACH,,49.0157843115,7.85030833791,75,,Climbach,Climbach,67,Bas-Rhin,44,Grand Est
67076,COLROY LA ROCHE,67420,COLROY LA ROCHE,,48.39149749,7.17386084704,76,,Colroy-la-Roche,Colroy-la-Roche,67,Bas-Rhin,44,Grand Est
67077,COSSWILLER,67310,COSSWILLER,,48.6086737459,7.36224872367,77,,Cosswiller,Cosswiller,67,Bas-Rhin,44,Grand Est
67078,CRASTATT,67310,CRASTATT,,48.6596453708,7.42269000127,78,,Crastatt,Crastatt,67,Bas-Rhin,44,Grand Est
67079,CROETTWILLER,67470,CROETTWILLER,,48.9315378409,8.03790255661,79,,Crœttwiller,Crœttwiller,67,Bas-Rhin,44,Grand Est
67080,DACHSTEIN,67120,DACHSTEIN,,48.553555897,7.54050377618,80,,Dachstein,Dachstein,67,Bas-Rhin,44,Grand Est
67081,DAHLENHEIM,67310,DAHLENHEIM,,48.5882941085,7.51624848746,81,,Dahlenheim,Dahlenheim,67,Bas-Rhin,44,Grand Est
67082,DALHUNDEN,67770,DALHUNDEN,,48.7711698119,7.99159083509,82,,Dalhunden,Dalhunden,67,Bas-Rhin,44,Grand Est
67083,DAMBACH,67110,DAMBACH,,49.0093806397,7.64091899107,83,,Dambach,Dambach,67,Bas-Rhin,44,Grand Est
67083,DAMBACH,67110,DAMBACH,NEUNHOFFEN,49.0093806397,7.64091899107,83,,Dambach,Dambach,67,Bas-Rhin,44,Grand Est
67084,DAMBACH LA VILLE,67650,DAMBACH LA VILLE,,48.3231725111,7.4269277176,84,,Dambach-la-Ville,Dambach-la-Ville,67,Bas-Rhin,44,Grand Est
67085,DANGOLSHEIM,67310,DANGOLSHEIM,,48.5647236628,7.45735566784,85,,Dangolsheim,Dangolsheim,67,Bas-Rhin,44,Grand Est
67086,DAUBENSAND,67150,DAUBENSAND,,48.3518354847,7.72863456174,86,,Daubensand,Daubensand,67,Bas-Rhin,44,Grand Est
67087,DAUENDORF,67350,DAUENDORF,,48.833965856,7.66613309742,87,,Dauendorf,Dauendorf,67,Bas-Rhin,44,Grand Est
67087,DAUENDORF,67350,DAUENDORF,NEUBOURG,48.833965856,7.66613309742,87,,Dauendorf,Dauendorf,67,Bas-Rhin,44,Grand Est
67088,DEHLINGEN,67430,DEHLINGEN,,48.9852170965,7.18127401976,88,,Dehlingen,Dehlingen,67,Bas-Rhin,44,Grand Est
67089,DETTWILLER,67490,DETTWILLER,,48.7608351027,7.45405808317,89,,Dettwiller,Dettwiller,67,Bas-Rhin,44,Grand Est
67090,DIEBOLSHEIM,67230,DIEBOLSHEIM,,48.2855918549,7.6576330222,90,,Diebolsheim,Diebolsheim,67,Bas-Rhin,44,Grand Est
67091,DIEDENDORF,67260,DIEDENDORF,,48.887700346,7.02814105959,91,,Diedendorf,Diedendorf,67,Bas-Rhin,44,Grand Est
67092,DIEFFENBACH AU VAL,67220,DIEFFENBACH AU VAL,,48.3144024513,7.32599641876,92,,Dieffenbach-au-Val,Dieffenbach-au-Val,67,Bas-Rhin,44,Grand Est
67093,DIEFFENBACH LES WOERTH,67360,DIEFFENBACH LES WOERTH,,48.9302435287,7.7944805731,93,,Dieffenbach-lès-Wœrth,Dieffenbach-lès-Wœrth,67,Bas-Rhin,44,Grand Est
67094,DIEFFENTHAL,67650,DIEFFENTHAL,,48.3073185385,7.41146997738,94,,Dieffenthal,Dieffenthal,67,Bas-Rhin,44,Grand Est
67095,DIEMERINGEN,67430,DIEMERINGEN,,48.9440361875,7.2036576979,95,,Diemeringen,Diemeringen,67,Bas-Rhin,44,Grand Est
67096,DIMBSTHAL,67440,DIMBSTHAL,,48.6729223187,7.35414815294,96,,Dimbsthal,Dimbsthal,67,Bas-Rhin,44,Grand Est
67097,DINGSHEIM,67370,DINGSHEIM,,48.622536679,7.6607451279,97,,Dingsheim,Dingsheim,67,Bas-Rhin,44,Grand Est
67098,DINSHEIM SUR BRUCHE,67190,DINSHEIM SUR BRUCHE,,48.5475535578,7.42402678958,98,,Dinsheim-sur-Bruche,Dinsheim-sur-Bruche,67,Bas-Rhin,44,Grand Est
67099,DOMFESSEL,67430,DOMFESSEL,,48.9480129452,7.14588876509,99,,Domfessel,Domfessel,67,Bas-Rhin,44,Grand Est
67100,DONNENHEIM,67170,DONNENHEIM,,48.7219425024,7.66278189146,100,,Donnenheim,Donnenheim,67,Bas-Rhin,44,Grand Est
67101,DORLISHEIM,67120,DORLISHEIM,,48.5199427115,7.48035735807,101,,Dorlisheim,Dorlisheim,67,Bas-Rhin,44,Grand Est
67102,DOSSENHEIM KOCHERSBERG,67117,DOSSENHEIM KOCHERSBERG,,48.6410930657,7.57816691199,102,,Dossenheim-Kochersberg,Dossenheim-Kochersberg,67,Bas-Rhin,44,Grand Est
67103,DOSSENHEIM SUR ZINSEL,67330,DOSSENHEIM SUR ZINSEL,,48.814142481,7.37763215457,103,,Dossenheim-sur-Zinsel,Dossenheim-sur-Zinsel,67,Bas-Rhin,44,Grand Est
67104,DRACHENBRONN BIRLENBACH,67160,DRACHENBRONN BIRLENBACH,,48.9809878964,7.87807854084,104,,Drachenbronn-Birlenbach,Drachenbronn-Birlenbach,67,Bas-Rhin,44,Grand Est
67104,DRACHENBRONN BIRLENBACH,67160,DRACHENBRONN BIRLENBACH,BIRLENBACH,48.9809878964,7.87807854084,104,,Drachenbronn-Birlenbach,Drachenbronn-Birlenbach,67,Bas-Rhin,44,Grand Est
67105,DRULINGEN,67320,DRULINGEN,,48.8678721776,7.1949547515,105,,Drulingen,Drulingen,67,Bas-Rhin,44,Grand Est
67106,DRUSENHEIM,67410,DRUSENHEIM,,48.7609264205,7.94563844601,106,,Drusenheim,Drusenheim,67,Bas-Rhin,44,Grand Est
67107,DUNTZENHEIM,67270,DUNTZENHEIM,,48.7134280666,7.54291307036,107,,Duntzenheim,Duntzenheim,67,Bas-Rhin,44,Grand Est
67108,DUPPIGHEIM,67120,DUPPIGHEIM,,48.5331312304,7.59599375258,108,,Duppigheim,Duppigheim,67,Bas-Rhin,44,Grand Est
67109,DURNINGEN,67270,DURNINGEN,,48.6849651267,7.5618284818,109,,Durningen,Durningen,67,Bas-Rhin,44,Grand Est
67110,DURRENBACH,67360,DURRENBACH,,48.8992737853,7.76279300097,110,,Durrenbach,Durrenbach,67,Bas-Rhin,44,Grand Est
67111,DURSTEL,67320,DURSTEL,,48.8910133304,7.20487622199,111,,Durstel,Durstel,67,Bas-Rhin,44,Grand Est
67112,DUTTLENHEIM,67120,DUTTLENHEIM,,48.5240740255,7.57056635367,112,,Duttlenheim,Duttlenheim,67,Bas-Rhin,44,Grand Est
67113,EBERBACH SELTZ,67470,EBERBACH SELTZ,,48.928540065,8.0666092873,113,,Eberbach-Seltz,Eberbach-Seltz,67,Bas-Rhin,44,Grand Est
67115,EBERSHEIM,67600,EBERSHEIM,,48.3024348713,7.49845782701,115,,Ebersheim,Ebersheim,67,Bas-Rhin,44,Grand Est
67116,EBERSMUNSTER,67600,EBERSMUNSTER,,48.3033027968,7.53361664193,116,,Ebersmunster,Ebersmunster,67,Bas-Rhin,44,Grand Est
67117,ECKARTSWILLER,67700,ECKARTSWILLER,,48.7796438355,7.32419357331,117,,Eckartswiller,Eckartswiller,67,Bas-Rhin,44,Grand Est
67118,ECKBOLSHEIM,67201,ECKBOLSHEIM,,48.5790053064,7.68216295597,118,,Eckbolsheim,Eckbolsheim,67,Bas-Rhin,44,Grand Est
67119,ECKWERSHEIM,67550,ECKWERSHEIM,,48.6852852896,7.69634796304,119,,Eckwersheim,Eckwersheim,67,Bas-Rhin,44,Grand Est
67120,EICHHOFFEN,67140,EICHHOFFEN,,48.3784061586,7.4410649341,120,,Eichhoffen,Eichhoffen,67,Bas-Rhin,44,Grand Est
67121,ELSENHEIM,67390,ELSENHEIM,,48.1655800689,7.49719724328,121,,Elsenheim,Elsenheim,67,Bas-Rhin,44,Grand Est
67122,WANGENBOURG ENGENTHAL,67710,WANGENBOURG ENGENTHAL,,48.6212665024,7.30505408726,122,,Wangenbourg-Engenthal,Wangenbourg-Engenthal,67,Bas-Rhin,44,Grand Est
67122,WANGENBOURG ENGENTHAL,67710,WANGENBOURG ENGENTHAL,ENGENTHAL,48.6212665024,7.30505408726,122,,Wangenbourg-Engenthal,Wangenbourg-Engenthal,67,Bas-Rhin,44,Grand Est
67122,WANGENBOURG ENGENTHAL,67710,WANGENBOURG ENGENTHAL,SCHNEETHAL,48.6212665024,7.30505408726,122,,Wangenbourg-Engenthal,Wangenbourg-Engenthal,67,Bas-Rhin,44,Grand Est
67122,WANGENBOURG ENGENTHAL,67710,WANGENBOURG ENGENTHAL,WANGENBOURG,48.6212665024,7.30505408726,122,,Wangenbourg-Engenthal,Wangenbourg-Engenthal,67,Bas-Rhin,44,Grand Est
67122,WANGENBOURG ENGENTHAL,67710,WANGENBOURG ENGENTHAL,WOLFSTHAL,48.6212665024,7.30505408726,122,,Wangenbourg-Engenthal,Wangenbourg-Engenthal,67,Bas-Rhin,44,Grand Est
67123,ENGWILLER,67350,ENGWILLER,,48.8817152403,7.61563175479,123,,Engwiller,Engwiller,67,Bas-Rhin,44,Grand Est
67124,ENTZHEIM,67960,ENTZHEIM,,48.5358846915,7.63324537487,124,,Entzheim,Entzheim,67,Bas-Rhin,44,Grand Est
67125,EPFIG,67680,EPFIG,,48.3513757902,7.47140926627,125,,Epfig,Epfig,67,Bas-Rhin,44,Grand Est
67126,ERCKARTSWILLER,67290,ERCKARTSWILLER,,48.8829392855,7.35746524699,126,,Erckartswiller,Erckartswiller,67,Bas-Rhin,44,Grand Est
67127,ERGERSHEIM,67120,ERGERSHEIM,,48.5755353252,7.53497410967,127,,Ergersheim,Ergersheim,67,Bas-Rhin,44,Grand Est
67128,ERNOLSHEIM BRUCHE,67120,ERNOLSHEIM BRUCHE,,48.5637542939,7.56903301628,128,,Ernolsheim-Bruche,Ernolsheim-Bruche,67,Bas-Rhin,44,Grand Est
67129,ERNOLSHEIM LES SAVERNE,67330,ERNOLSHEIM LES SAVERNE,,48.7888603168,7.37728426045,129,,Ernolsheim-lès-Saverne,Ernolsheim-lès-Saverne,67,Bas-Rhin,44,Grand Est
67130,ERSTEIN,67150,ERSTEIN,,48.4213687934,7.68523629922,130,,Erstein,Erstein,67,Bas-Rhin,44,Grand Est
67130,ERSTEIN,67150,ERSTEIN,KRAFFT,48.4213687934,7.68523629922,130,,Erstein,Erstein,67,Bas-Rhin,44,Grand Est
67131,ESCHAU,67114,ESCHAU,,48.4832295386,7.72955536654,131,,Eschau,Eschau,67,Bas-Rhin,44,Grand Est
67132,ESCHBACH,67360,ESCHBACH,,48.8758347944,7.73863882524,132,,Eschbach,Eschbach,67,Bas-Rhin,44,Grand Est
67133,ESCHBOURG,67320,ESCHBOURG,,48.8226852369,7.29080380579,133,,Eschbourg,Eschbourg,67,Bas-Rhin,44,Grand Est
67133,ESCHBOURG,67320,ESCHBOURG,GRAUFTHAL,48.8226852369,7.29080380579,133,,Eschbourg,Eschbourg,67,Bas-Rhin,44,Grand Est
67134,ESCHWILLER,67320,ESCHWILLER,,48.8576773931,7.11098433701,134,,Eschwiller,Eschwiller,67,Bas-Rhin,44,Grand Est
67135,ETTENDORF,67350,ETTENDORF,,48.8151610201,7.58546708674,135,,Ettendorf,Ettendorf,67,Bas-Rhin,44,Grand Est
67136,EYWILLER,67320,EYWILLER,,48.8716690735,7.12428089902,136,,Eywiller,Eywiller,67,Bas-Rhin,44,Grand Est
67137,FEGERSHEIM,67640,FEGERSHEIM,,48.4974401666,7.68863317258,137,,Fegersheim,Fegersheim,67,Bas-Rhin,44,Grand Est
67137,FEGERSHEIM,67640,FEGERSHEIM,OHNHEIM,48.4974401666,7.68863317258,137,,Fegersheim,Fegersheim,67,Bas-Rhin,44,Grand Est
67138,FESSENHEIM LE BAS,67117,FESSENHEIM LE BAS,,48.6322069438,7.54708136376,138,,Fessenheim-le-Bas,Fessenheim-le-Bas,67,Bas-Rhin,44,Grand Est
67139,FLEXBOURG,67310,FLEXBOURG,,48.5682741101,7.424259657,139,,Flexbourg,Flexbourg,67,Bas-Rhin,44,Grand Est
67140,FORSTFELD,67480,FORSTFELD,,48.8631078852,8.04746930377,140,,Forstfeld,Forstfeld,67,Bas-Rhin,44,Grand Est
67141,FORSTHEIM,67580,FORSTHEIM,,48.8959541759,7.71097392643,141,,Forstheim,Forstheim,67,Bas-Rhin,44,Grand Est
67142,FORT LOUIS,67480,FORT LOUIS,,48.7987310184,8.05261384918,142,,Fort-Louis,Fort-Louis,67,Bas-Rhin,44,Grand Est
67143,FOUCHY,67220,FOUCHY,,48.316102651,7.24660740823,143,,Fouchy,Fouchy,67,Bas-Rhin,44,Grand Est
67144,FOUDAY,67130,FOUDAY,,48.420716241,7.19048475836,144,,Fouday,Fouday,67,Bas-Rhin,44,Grand Est
67145,FRIEDOLSHEIM,67490,FRIEDOLSHEIM,,48.7070325284,7.48727340354,145,,Friedolsheim,Friedolsheim,67,Bas-Rhin,44,Grand Est
67146,FRIESENHEIM,67860,FRIESENHEIM,,48.3090804747,7.65396070706,146,,Friesenheim,Friesenheim,67,Bas-Rhin,44,Grand Est
67147,FROESCHWILLER,67360,FROESCHWILLER,,48.93840535,7.71886241494,147,,Frœschwiller,Frœschwiller,67,Bas-Rhin,44,Grand Est
67148,FROHMUHL,67290,FROHMUHL,,48.9113095227,7.27923351719,148,,Frohmuhl,Frohmuhl,67,Bas-Rhin,44,Grand Est
67149,FURCHHAUSEN,67700,FURCHHAUSEN,,48.7204949919,7.43041510079,149,,Furchhausen,Furchhausen,67,Bas-Rhin,44,Grand Est
67150,FURDENHEIM,67117,FURDENHEIM,,48.6105637831,7.55789773047,150,,Furdenheim,Furdenheim,67,Bas-Rhin,44,Grand Est
67151,GAMBSHEIM,67760,GAMBSHEIM,,48.6923110768,7.8840878201,151,,Gambsheim,Gambsheim,67,Bas-Rhin,44,Grand Est
67152,GEISPOLSHEIM,67118,GEISPOLSHEIM,,48.5143463092,7.65806561244,152,,Geispolsheim,Geispolsheim,67,Bas-Rhin,44,Grand Est
67153,GEISWILLER,67270,GEISWILLER,,48.7904561114,7.50050784514,153,,Geiswiller-Zœbersdorf,Geiswiller-Zœbersdorf,67,Bas-Rhin,44,Grand Est
67154,GERSTHEIM,67150,GERSTHEIM,,48.3807788366,7.70453174528,154,,Gerstheim,Gerstheim,67,Bas-Rhin,44,Grand Est
67155,GERTWILLER,67140,GERTWILLER,,48.4115755061,7.47499866604,155,,Gertwiller,Gertwiller,67,Bas-Rhin,44,Grand Est
67156,GEUDERTHEIM,67170,GEUDERTHEIM,,48.7154836732,7.76052070487,156,,Geudertheim,Geudertheim,67,Bas-Rhin,44,Grand Est
67159,GOERLINGEN,67320,GOERLINGEN,,48.7987678214,7.08515641917,159,,Gœrlingen,Gœrlingen,67,Bas-Rhin,44,Grand Est
67160,GOERSDORF,67360,GOERSDORF,,48.9590667268,7.77360094456,160,,Gœrsdorf,Gœrsdorf,67,Bas-Rhin,44,Grand Est
67160,GOERSDORF,67360,GOERSDORF,MITSCHDORF,48.9590667268,7.77360094456,160,,Gœrsdorf,Gœrsdorf,67,Bas-Rhin,44,Grand Est
67161,GOTTENHOUSE,67700,GOTTENHOUSE,,48.7180135851,7.35834197528,161,,Gottenhouse,Gottenhouse,67,Bas-Rhin,44,Grand Est
67162,GOTTESHEIM,67490,GOTTESHEIM,,48.7757972428,7.48107091135,162,,Gottesheim,Gottesheim,67,Bas-Rhin,44,Grand Est
67163,GOUGENHEIM,67270,GOUGENHEIM,,48.704527352,7.57535025275,163,,Gougenheim,Gougenheim,67,Bas-Rhin,44,Grand Est
67164,GOXWILLER,67210,GOXWILLER,,48.4309239256,7.48344951435,164,,Goxwiller,Goxwiller,67,Bas-Rhin,44,Grand Est
67165,GRANDFONTAINE,67130,GRANDFONTAINE,,48.4986116178,7.12869750968,165,,Grandfontaine,Grandfontaine,67,Bas-Rhin,44,Grand Est
67166,GRASSENDORF,67350,GRASSENDORF,,48.8148050236,7.61013928029,166,,Grassendorf,Grassendorf,67,Bas-Rhin,44,Grand Est
67167,GRENDELBRUCH,67190,GRENDELBRUCH,,48.4812263837,7.30476049546,167,,Grendelbruch,Grendelbruch,67,Bas-Rhin,44,Grand Est
67168,GRESSWILLER,67190,GRESSWILLER,,48.5249899072,7.40991098161,168,,Gresswiller,Gresswiller,67,Bas-Rhin,44,Grand Est
67169,GRIES,67240,GRIES,,48.7513621625,7.82473437694,169,,Gries,Gries,67,Bas-Rhin,44,Grand Est
67172,GRIESHEIM PRES MOLSHEIM,67870,GRIESHEIM PRES MOLSHEIM,,48.5020356636,7.53113191824,172,,Griesheim-près-Molsheim,Griesheim-près-Molsheim,67,Bas-Rhin,44,Grand Est
67173,GRIESHEIM SUR SOUFFEL,67370,GRIESHEIM SUR SOUFFEL,,48.6334125597,7.6733957235,173,,Griesheim-sur-Souffel,Griesheim-sur-Souffel,67,Bas-Rhin,44,Grand Est
67174,GUMBRECHTSHOFFEN,67110,GUMBRECHTSHOFFEN,,48.9027833028,7.62074781132,174,,Gumbrechtshoffen,Gumbrechtshoffen,67,Bas-Rhin,44,Grand Est
67176,GUNDERSHOFFEN,67110,GUNDERSHOFFEN,,48.9073472641,7.68204357189,176,,Gundershoffen,Gundershoffen,67,Bas-Rhin,44,Grand Est
67176,GUNDERSHOFFEN,67110,GUNDERSHOFFEN,EBERBACH,48.9073472641,7.68204357189,176,,Gundershoffen,Gundershoffen,67,Bas-Rhin,44,Grand Est
67176,GUNDERSHOFFEN,67110,GUNDERSHOFFEN,GRIESBACH,48.9073472641,7.68204357189,176,,Gundershoffen,Gundershoffen,67,Bas-Rhin,44,Grand Est
67176,GUNDERSHOFFEN,67110,GUNDERSHOFFEN,SCHIRLENHOF,48.9073472641,7.68204357189,176,,Gundershoffen,Gundershoffen,67,Bas-Rhin,44,Grand Est
67177,GUNSTETT,67360,GUNSTETT,,48.9150746894,7.77903805347,177,,Gunstett,Gunstett,67,Bas-Rhin,44,Grand Est
67178,GUNGWILLER,67320,GUNGWILLER,,48.8780997481,7.15256188284,178,,Gungwiller,Gungwiller,67,Bas-Rhin,44,Grand Est
67179,HAEGEN,67700,HAEGEN,,48.7056354976,7.29766137398,179,,Haegen,Haegen,67,Bas-Rhin,44,Grand Est
67180,HAGUENAU,67500,HAGUENAU,,48.8417047695,7.83010404968,180,,Haguenau,Haguenau,67,Bas-Rhin,44,Grand Est
67180,HAGUENAU,67500,HAGUENAU,HARTHOUSE,48.8417047695,7.83010404968,180,,Haguenau,Haguenau,67,Bas-Rhin,44,Grand Est
67180,HAGUENAU,67500,HAGUENAU,MARIENTHAL,48.8417047695,7.83010404968,180,,Haguenau,Haguenau,67,Bas-Rhin,44,Grand Est
67181,HANDSCHUHEIM,67117,HANDSCHUHEIM,,48.5993989361,7.57721727962,181,,Handschuheim,Handschuheim,67,Bas-Rhin,44,Grand Est
67182,HANGENBIETEN,67980,HANGENBIETEN,,48.55700945,7.61194013862,182,,Hangenbieten,Hangenbieten,67,Bas-Rhin,44,Grand Est
67183,HARSKIRCHEN,67260,HARSKIRCHEN,,48.9237270109,7.02630342331,183,,Harskirchen,Harskirchen,67,Bas-Rhin,44,Grand Est
67184,HATTEN,67690,HATTEN,,48.8939645842,8.00687514269,184,,Hatten,Hatten,67,Bas-Rhin,44,Grand Est
67185,HATTMATT,67330,HATTMATT,,48.7854804933,7.4364304189,185,,Hattmatt,Hattmatt,67,Bas-Rhin,44,Grand Est
67186,HEGENEY,67360,HEGENEY,,48.8880984199,7.73980226806,186,,Hegeney,Hegeney,67,Bas-Rhin,44,Grand Est
67187,HEIDOLSHEIM,67390,HEIDOLSHEIM,,48.2041501521,7.51093457658,187,,Heidolsheim,Heidolsheim,67,Bas-Rhin,44,Grand Est
67188,HEILIGENBERG,67190,HEILIGENBERG,,48.5371724214,7.37789459868,188,,Heiligenberg,Heiligenberg,67,Bas-Rhin,44,Grand Est
67189,HEILIGENSTEIN,67140,HEILIGENSTEIN,,48.4220772866,7.44087809076,189,,Heiligenstein,Heiligenstein,67,Bas-Rhin,44,Grand Est
67190,HENGWILLER,67440,HENGWILLER,,48.6676339453,7.328558621,190,,Hengwiller,Hengwiller,67,Bas-Rhin,44,Grand Est
67191,HERBITZHEIM,67260,HERBITZHEIM,,49.0221359155,7.08178967712,191,,Herbitzheim,Herbitzheim,67,Bas-Rhin,44,Grand Est
67192,HERBSHEIM,67230,HERBSHEIM,,48.3494389579,7.63675054527,192,,Herbsheim,Herbsheim,67,Bas-Rhin,44,Grand Est
67194,HERRLISHEIM,67850,HERRLISHEIM,,48.7325109113,7.91012947405,194,,Herrlisheim,Herrlisheim,67,Bas-Rhin,44,Grand Est
67195,HESSENHEIM,67390,HESSENHEIM,,48.2054418574,7.55188432622,195,,Hessenheim,Hessenheim,67,Bas-Rhin,44,Grand Est
67196,HILSENHEIM,67600,HILSENHEIM,,48.2969573251,7.57311937757,196,,Hilsenheim,Hilsenheim,67,Bas-Rhin,44,Grand Est
67197,HINDISHEIM,67150,HINDISHEIM,,48.465896683,7.62196229958,197,,Hindisheim,Hindisheim,67,Bas-Rhin,44,Grand Est
67198,HINSBOURG,67290,HINSBOURG,,48.902016302,7.29622114102,198,,Hinsbourg,Hinsbourg,67,Bas-Rhin,44,Grand Est
67199,HINSINGEN,67260,HINSINGEN,,48.9511717259,6.99896336486,199,,Hinsingen,Hinsingen,67,Bas-Rhin,44,Grand Est
67200,HIPSHEIM,67150,HIPSHEIM,,48.4655967531,7.68015819425,200,,Hipsheim,Hipsheim,67,Bas-Rhin,44,Grand Est
67201,HIRSCHLAND,67320,HIRSCHLAND,,48.8360079859,7.12394256542,201,,Hirschland,Hirschland,67,Bas-Rhin,44,Grand Est
67202,HOCHFELDEN,67270,HOCHFELDEN,,48.7558373026,7.56312887309,202,,Hochfelden,Hochfelden,67,Bas-Rhin,44,Grand Est
67203,HOCHSTETT,67170,HOCHSTETT,,48.7757655741,7.67684396609,203,,Hochstett,Hochstett,67,Bas-Rhin,44,Grand Est
67204,HOENHEIM,67800,HOENHEIM,,48.6242068412,7.75365666565,204,,Hœnheim,Hœnheim,67,Bas-Rhin,44,Grand Est
67205,HOERDT,67720,HOERDT,,48.6905674036,7.78483478217,205,,Hœrdt,Hœrdt,67,Bas-Rhin,44,Grand Est
67206,HOFFEN,67250,HOFFEN,,48.9330135449,7.93276702942,206,,Hoffen,Hoffen,67,Bas-Rhin,44,Grand Est
67206,HOFFEN,67250,HOFFEN,HERMERSWILLER,48.9330135449,7.93276702942,206,,Hoffen,Hoffen,67,Bas-Rhin,44,Grand Est
67206,HOFFEN,67250,HOFFEN,LEITERSWILLER,48.9330135449,7.93276702942,206,,Hoffen,Hoffen,67,Bas-Rhin,44,Grand Est
67208,HOHENGOEFT,67310,HOHENGOEFT,,48.6607527966,7.46742297306,208,,Hohengœft,Hohengœft,67,Bas-Rhin,44,Grand Est
67209,HOHFRANKENHEIM,67270,HOHFRANKENHEIM,,48.7286774647,7.58079540705,209,,Hohfrankenheim,Hohfrankenheim,67,Bas-Rhin,44,Grand Est
67210,LE HOHWALD,67140,LE HOHWALD,,48.4006621261,7.31029962192,210,Le,Hohwald,Le Hohwald,67,Bas-Rhin,44,Grand Est
67212,HOLTZHEIM,67810,HOLTZHEIM,,48.5567634132,7.64425286816,212,,Holtzheim,Holtzheim,67,Bas-Rhin,44,Grand Est
67213,HUNSPACH,67250,HUNSPACH,,48.9578637227,7.93587412104,213,,Hunspach,Hunspach,67,Bas-Rhin,44,Grand Est
67214,HURTIGHEIM,67117,HURTIGHEIM,,48.6171752515,7.6034009548,214,,Hurtigheim,Hurtigheim,67,Bas-Rhin,44,Grand Est
67215,HUTTENDORF,67270,HUTTENDORF,,48.801101142,7.63868407812,215,,Huttendorf,Huttendorf,67,Bas-Rhin,44,Grand Est
67216,HUTTENHEIM,67230,HUTTENHEIM,,48.3537686447,7.57534532611,216,,Huttenheim,Huttenheim,67,Bas-Rhin,44,Grand Est
67217,ICHTRATZHEIM,67640,ICHTRATZHEIM,,48.47895733,7.67769353417,217,,Ichtratzheim,Ichtratzheim,67,Bas-Rhin,44,Grand Est
67218,ILLKIRCH GRAFFENSTADEN,67400,ILLKIRCH GRAFFENSTADEN,,48.5200498962,7.73129588098,218,,Illkirch-Graffenstaden,Illkirch-Graffenstaden,67,Bas-Rhin,44,Grand Est
67220,INGENHEIM,67270,INGENHEIM,,48.7336224941,7.52591364727,220,,Ingenheim,Ingenheim,67,Bas-Rhin,44,Grand Est
67221,INGOLSHEIM,67250,INGOLSHEIM,,48.9708439784,7.92975610012,221,,Ingolsheim,Ingolsheim,67,Bas-Rhin,44,Grand Est
67222,INGWILLER,67340,INGWILLER,,48.8859131079,7.46918832697,222,,Ingwiller,Ingwiller,67,Bas-Rhin,44,Grand Est
67223,INNENHEIM,67880,INNENHEIM,,48.4975444021,7.57352976158,223,,Innenheim,Innenheim,67,Bas-Rhin,44,Grand Est
67225,ISSENHAUSEN,67330,ISSENHAUSEN,,48.8042772362,7.53366478397,225,,Issenhausen,Issenhausen,67,Bas-Rhin,44,Grand Est
67226,ITTENHEIM,67117,ITTENHEIM,,48.6017514577,7.60509580501,226,,Ittenheim,Ittenheim,67,Bas-Rhin,44,Grand Est
67227,ITTERSWILLER,67140,ITTERSWILLER,,48.3665982092,7.42936455525,227,,Itterswiller,Itterswiller,67,Bas-Rhin,44,Grand Est
67228,NEUGARTHEIM ITTLENHEIM,67370,NEUGARTHEIM ITTLENHEIM,,48.6565369611,7.5414687691,228,,Neugartheim-Ittlenheim,Neugartheim-Ittlenheim,67,Bas-Rhin,44,Grand Est
67228,NEUGARTHEIM ITTLENHEIM,67370,NEUGARTHEIM ITTLENHEIM,ITTLENHEIM,48.6565369611,7.5414687691,228,,Neugartheim-Ittlenheim,Neugartheim-Ittlenheim,67,Bas-Rhin,44,Grand Est
67228,NEUGARTHEIM ITTLENHEIM,67370,NEUGARTHEIM ITTLENHEIM,NEUGARTHEIM,48.6565369611,7.5414687691,228,,Neugartheim-Ittlenheim,Neugartheim-Ittlenheim,67,Bas-Rhin,44,Grand Est
67229,JETTERSWILLER,67440,JETTERSWILLER,,48.6697366716,7.41745177343,229,,Jetterswiller,Jetterswiller,67,Bas-Rhin,44,Grand Est
67230,KALTENHOUSE,67240,KALTENHOUSE,,48.7902364903,7.83303517468,230,,Kaltenhouse,Kaltenhouse,67,Bas-Rhin,44,Grand Est
67231,KAUFFENHEIM,67480,KAUFFENHEIM,,48.854005833,8.03199767746,231,,Kauffenheim,Kauffenheim,67,Bas-Rhin,44,Grand Est
67232,KEFFENACH,67250,KEFFENACH,,48.9666217259,7.88352123291,232,,Keffenach,Keffenach,67,Bas-Rhin,44,Grand Est
67233,KERTZFELD,67230,KERTZFELD,,48.3845479788,7.55526368096,233,,Kertzfeld,Kertzfeld,67,Bas-Rhin,44,Grand Est
67234,KESKASTEL,67260,KESKASTEL,,48.9764095589,7.062926056,234,,Keskastel,Keskastel,67,Bas-Rhin,44,Grand Est
67235,KESSELDORF,67930,KESSELDORF,,48.8843920264,8.05901750428,235,,Kesseldorf,Kesseldorf,67,Bas-Rhin,44,Grand Est
67236,KIENHEIM,67270,KIENHEIM,,48.6881585203,7.58568269941,236,,Kienheim,Kienheim,67,Bas-Rhin,44,Grand Est
67237,KILSTETT,67840,KILSTETT,,48.6835701227,7.84577121429,237,,Kilstett,Kilstett,67,Bas-Rhin,44,Grand Est
67238,KINDWILLER,67350,KINDWILLER,,48.8612754447,7.59545058526,238,,Kindwiller,Kindwiller,67,Bas-Rhin,44,Grand Est
67239,KINTZHEIM,67600,KINTZHEIM,,48.2585880501,7.37432236249,239,,Kintzheim,Kintzheim,67,Bas-Rhin,44,Grand Est
67240,KIRCHHEIM,67520,KIRCHHEIM,,48.6059106052,7.48884201445,240,,Kirchheim,Kirchheim,67,Bas-Rhin,44,Grand Est
67241,KIRRBERG,67320,KIRRBERG,,48.8301802352,7.06430672642,241,,Kirrberg,Kirrberg,67,Bas-Rhin,44,Grand Est
67242,KIRRWILLER,67330,KIRRWILLER,,48.8202222605,7.52643377824,242,,Kirrwiller,Kirrwiller,67,Bas-Rhin,44,Grand Est
67244,KLEINGOEFT,67440,KLEINGOEFT,,48.6939893775,7.44201331166,244,,Kleingœft,Kleingœft,67,Bas-Rhin,44,Grand Est
67245,KNOERSHEIM,67310,KNOERSHEIM,,48.6796467865,7.45825135776,245,,Knœrsheim,Knœrsheim,67,Bas-Rhin,44,Grand Est
67246,KOGENHEIM,67230,KOGENHEIM,,48.331885522,7.53140383573,246,,Kogenheim,Kogenheim,67,Bas-Rhin,44,Grand Est
67247,KOLBSHEIM,67120,KOLBSHEIM,,48.5621490705,7.58621736954,247,,Kolbsheim,Kolbsheim,67,Bas-Rhin,44,Grand Est
67248,KRAUTERGERSHEIM,67880,KRAUTERGERSHEIM,,48.475170055,7.56554119438,248,,Krautergersheim,Krautergersheim,67,Bas-Rhin,44,Grand Est
67249,KRAUTWILLER,67170,KRAUTWILLER,,48.739166546,7.67482992477,249,,Krautwiller,Krautwiller,67,Bas-Rhin,44,Grand Est
67250,KRIEGSHEIM,67170,KRIEGSHEIM,,48.7567867136,7.73549910611,250,,Kriegsheim,Kriegsheim,67,Bas-Rhin,44,Grand Est
67252,KURTZENHOUSE,67240,KURTZENHOUSE,,48.7372858241,7.80514291468,252,,Kurtzenhouse,Kurtzenhouse,67,Bas-Rhin,44,Grand Est
67253,KUTTOLSHEIM,67520,KUTTOLSHEIM,,48.6478359715,7.51923399394,253,,Kuttolsheim,Kuttolsheim,67,Bas-Rhin,44,Grand Est
67254,KUTZENHAUSEN,67250,KUTZENHAUSEN,,48.9388573117,7.8491381943,254,,Kutzenhausen,Kutzenhausen,67,Bas-Rhin,44,Grand Est
67254,KUTZENHAUSEN,67250,KUTZENHAUSEN,OBERKUTZENHAUSEN,48.9388573117,7.8491381943,254,,Kutzenhausen,Kutzenhausen,67,Bas-Rhin,44,Grand Est
67255,LALAYE,67220,LALAYE,,48.3386901777,7.23525154111,255,,Lalaye,Lalaye,67,Bas-Rhin,44,Grand Est
67255,LALAYE,67220,LALAYE,CHARBES,48.3386901777,7.23525154111,255,,Lalaye,Lalaye,67,Bas-Rhin,44,Grand Est
67256,LAMPERTHEIM,67450,LAMPERTHEIM,,48.6551039655,7.69405052062,256,,Lampertheim,Lampertheim,67,Bas-Rhin,44,Grand Est
67257,LAMPERTSLOCH,67250,LAMPERTSLOCH,,48.9704665444,7.82012664257,257,,Lampertsloch,Lampertsloch,67,Bas-Rhin,44,Grand Est
67258,LANDERSHEIM,67700,LANDERSHEIM,,48.6902190893,7.50257928239,258,,Landersheim,Landersheim,67,Bas-Rhin,44,Grand Est
67259,LANGENSOULTZBACH,67360,LANGENSOULTZBACH,,48.9742908393,7.73045198292,259,,Langensoultzbach,Langensoultzbach,67,Bas-Rhin,44,Grand Est
67260,LAUBACH,67580,LAUBACH,,48.8796848057,7.71816160797,260,,Laubach,Laubach,67,Bas-Rhin,44,Grand Est
67261,LAUTERBOURG,67630,LAUTERBOURG,,48.9637116345,8.18637725776,261,,Lauterbourg,Lauterbourg,67,Bas-Rhin,44,Grand Est
67263,LEMBACH,67510,LEMBACH,,49.0124690471,7.768432419,263,,Lembach,Lembach,67,Bas-Rhin,44,Grand Est
67263,LEMBACH,67510,LEMBACH,MATTSTALL,49.0124690471,7.768432419,263,,Lembach,Lembach,67,Bas-Rhin,44,Grand Est
67264,LEUTENHEIM,67480,LEUTENHEIM,,48.851706744,8.00007828567,264,,Leutenheim,Leutenheim,67,Bas-Rhin,44,Grand Est
67265,LICHTENBERG,67340,LICHTENBERG,,48.9218797672,7.48346810796,265,,Lichtenberg,Lichtenberg,67,Bas-Rhin,44,Grand Est
67266,LIMERSHEIM,67150,LIMERSHEIM,,48.4538179541,7.63089115629,266,,Limersheim,Limersheim,67,Bas-Rhin,44,Grand Est
67267,LINGOLSHEIM,67380,LINGOLSHEIM,,48.5544875852,7.68093840708,267,,Lingolsheim,Lingolsheim,67,Bas-Rhin,44,Grand Est
67268,LIPSHEIM,67640,LIPSHEIM,,48.4893768866,7.65665864812,268,,Lipsheim,Lipsheim,67,Bas-Rhin,44,Grand Est
67269,LITTENHEIM,67490,LITTENHEIM,,48.7252115785,7.49295380466,269,,Littenheim,Littenheim,67,Bas-Rhin,44,Grand Est
67270,LIXHAUSEN,67270,LIXHAUSEN,,48.7942598149,7.55588673552,270,,Lixhausen,Lixhausen,67,Bas-Rhin,44,Grand Est
67271,LOBSANN,67250,LOBSANN,,48.9632390551,7.84358548339,271,,Lobsann,Lobsann,67,Bas-Rhin,44,Grand Est
67272,LOCHWILLER,67440,LOCHWILLER,,48.6974249676,7.414828958,272,,Lochwiller,Lochwiller,67,Bas-Rhin,44,Grand Est
67273,LOHR,67290,LOHR,,48.854674328,7.26417699771,273,,Lohr,Lohr,67,Bas-Rhin,44,Grand Est
67274,LORENTZEN,67430,LORENTZEN,,48.9570762629,7.18176816001,274,,Lorentzen,Lorentzen,67,Bas-Rhin,44,Grand Est
67275,LUPSTEIN,67490,LUPSTEIN,,48.7379650718,7.47592816068,275,,Lupstein,Lupstein,67,Bas-Rhin,44,Grand Est
67276,LUTZELHOUSE,67130,LUTZELHOUSE,,48.5376842701,7.24174255302,276,,Lutzelhouse,Lutzelhouse,67,Bas-Rhin,44,Grand Est
67277,MACKENHEIM,67390,MACKENHEIM,,48.1826180117,7.58253183432,277,,Mackenheim,Mackenheim,67,Bas-Rhin,44,Grand Est
67278,MACKWILLER,67430,MACKWILLER,,48.9249078232,7.16638709797,278,,Mackwiller,Mackwiller,67,Bas-Rhin,44,Grand Est
67279,MAENNOLSHEIM,67700,MAENNOLSHEIM,,48.6975871317,7.47193118415,279,,Maennolsheim,Maennolsheim,67,Bas-Rhin,44,Grand Est
67280,MAISONSGOUTTE,67220,MAISONSGOUTTE,,48.3535462593,7.25920729014,280,,Maisonsgoutte,Maisonsgoutte,67,Bas-Rhin,44,Grand Est
67280,MAISONSGOUTTE,67220,MAISONSGOUTTE,WAGENBACH,48.3535462593,7.25920729014,280,,Maisonsgoutte,Maisonsgoutte,67,Bas-Rhin,44,Grand Est
67281,MARCKOLSHEIM,67390,MARCKOLSHEIM,,48.1496222393,7.56101805973,281,,Marckolsheim,Marckolsheim,67,Bas-Rhin,44,Grand Est
67282,MARLENHEIM,67520,MARLENHEIM,,48.6190542828,7.50589211094,282,,Marlenheim,Marlenheim,67,Bas-Rhin,44,Grand Est
67283,MARMOUTIER,67440,MARMOUTIER,,48.694247484,7.37099677128,283,,Marmoutier,Marmoutier,67,Bas-Rhin,44,Grand Est
67285,MATZENHEIM,67150,MATZENHEIM,,48.3845019495,7.63534088675,285,,Matzenheim,Matzenheim,67,Bas-Rhin,44,Grand Est
67285,MATZENHEIM,67150,MATZENHEIM,HAEUSERN,48.3845019495,7.63534088675,285,,Matzenheim,Matzenheim,67,Bas-Rhin,44,Grand Est
67286,MEISTRATZHEIM,67210,MEISTRATZHEIM,,48.4525286856,7.56113902632,286,,Meistratzheim,Meistratzheim,67,Bas-Rhin,44,Grand Est
67287,MELSHEIM,67270,MELSHEIM,,48.763931845,7.51498324586,287,,Melsheim,Melsheim,67,Bas-Rhin,44,Grand Est
67288,MEMMELSHOFFEN,67250,MEMMELSHOFFEN,,48.9589782972,7.87192314443,288,,Memmelshoffen,Memmelshoffen,67,Bas-Rhin,44,Grand Est
67289,MENCHHOFFEN,67340,MENCHHOFFEN,,48.8636588921,7.49803716008,289,,Menchhoffen,Menchhoffen,67,Bas-Rhin,44,Grand Est
67290,MERKWILLER PECHELBRONN,67250,MERKWILLER PECHELBRONN,,48.9286722006,7.8291529606,290,,Merkwiller-Pechelbronn,Merkwiller-Pechelbronn,67,Bas-Rhin,44,Grand Est
67290,MERKWILLER PECHELBRONN,67250,MERKWILLER PECHELBRONN,HOELSCHLOCH,48.9286722006,7.8291529606,290,,Merkwiller-Pechelbronn,Merkwiller-Pechelbronn,67,Bas-Rhin,44,Grand Est
67291,MERTZWILLER,67580,MERTZWILLER,,48.8703439948,7.68546930616,291,,Mertzwiller,Mertzwiller,67,Bas-Rhin,44,Grand Est
67292,MIETESHEIM,67580,MIETESHEIM,,48.8761605476,7.64658563349,292,,Mietesheim,Mietesheim,67,Bas-Rhin,44,Grand Est
67293,MINVERSHEIM,67270,MINVERSHEIM,,48.7842778125,7.62414913788,293,,Minversheim,Minversheim,67,Bas-Rhin,44,Grand Est
67295,MITTELBERGHEIM,67140,MITTELBERGHEIM,,48.3941967219,7.44334636249,295,,Mittelbergheim,Mittelbergheim,67,Bas-Rhin,44,Grand Est
67296,MITTELHAUSBERGEN,67206,MITTELHAUSBERGEN,,48.6144088918,7.69388360837,296,,Mittelhausbergen,Mittelhausbergen,67,Bas-Rhin,44,Grand Est
67298,MITTELSCHAEFFOLSHEIM,67170,MITTELSCHAEFFOLSHEIM,,48.6973385181,7.6517496049,298,,Mittelschaeffolsheim,Mittelschaeffolsheim,67,Bas-Rhin,44,Grand Est
67299,MOLLKIRCH,67190,MOLLKIRCH,,48.5058373844,7.36568374858,299,,Mollkirch,Mollkirch,67,Bas-Rhin,44,Grand Est
67300,MOLSHEIM,67120,MOLSHEIM,,48.5447196161,7.4995001297,300,,Molsheim,Molsheim,67,Bas-Rhin,44,Grand Est
67301,MOMMENHEIM,67670,MOMMENHEIM,,48.7558742576,7.64983524171,301,,Mommenheim,Mommenheim,67,Bas-Rhin,44,Grand Est
67302,MONSWILLER,67700,MONSWILLER,,48.7521963056,7.38626447438,302,,Monswiller,Monswiller,67,Bas-Rhin,44,Grand Est
67303,MORSBRONN LES BAINS,67360,MORSBRONN LES BAINS,,48.9099424,7.73456137399,303,,Morsbronn-les-Bains,Morsbronn-les-Bains,67,Bas-Rhin,44,Grand Est
67304,MORSCHWILLER,67350,MORSCHWILLER,,48.8194129618,7.62944925979,304,,Morschwiller,Morschwiller,67,Bas-Rhin,44,Grand Est
67305,MOTHERN,67470,MOTHERN,,48.9397726908,8.15250691524,305,,Mothern,Mothern,67,Bas-Rhin,44,Grand Est
67306,MUHLBACH SUR BRUCHE,67130,MUHLBACH SUR BRUCHE,,48.5125214509,7.31212371742,306,,Muhlbach-sur-Bruche,Muhlbach-sur-Bruche,67,Bas-Rhin,44,Grand Est
67307,MULHAUSEN,67350,MULHAUSEN,,48.8864927658,7.55485958914,307,,Mulhausen,Mulhausen,67,Bas-Rhin,44,Grand Est
67308,MUNCHHAUSEN,67470,MUNCHHAUSEN,,48.9149378138,8.14501372175,308,,Munchhausen,Munchhausen,67,Bas-Rhin,44,Grand Est
67309,MUNDOLSHEIM,67450,MUNDOLSHEIM,,48.6395028241,7.71495900977,309,,Mundolsheim,Mundolsheim,67,Bas-Rhin,44,Grand Est
67310,MUSSIG,67600,MUSSIG,,48.2266111578,7.51848492236,310,,Mussig,Mussig,67,Bas-Rhin,44,Grand Est
67311,MUTTERSHOLTZ,67600,MUTTERSHOLTZ,,48.2687190251,7.53306592763,311,,Muttersholtz,Muttersholtz,67,Bas-Rhin,44,Grand Est
67311,MUTTERSHOLTZ,67600,MUTTERSHOLTZ,EHNWIHR,48.2687190251,7.53306592763,311,,Muttersholtz,Muttersholtz,67,Bas-Rhin,44,Grand Est
67312,MUTZENHOUSE,67270,MUTZENHOUSE,,48.7409950821,7.5889236459,312,,Mutzenhouse,Mutzenhouse,67,Bas-Rhin,44,Grand Est
67313,MUTZIG,67190,MUTZIG,,48.538054845,7.45530315563,313,,Mutzig,Mutzig,67,Bas-Rhin,44,Grand Est
67314,NATZWILLER,67130,NATZWILLER,,48.4477521389,7.25122799667,314,,Natzwiller,Natzwiller,67,Bas-Rhin,44,Grand Est
67315,NEEWILLER PRES LAUTERBOURG,67630,NEEWILLER PRES LAUTERBOURG,,48.9516989257,8.11931601006,315,,Neewiller-près-Lauterbourg,Neewiller-près-Lauterbourg,67,Bas-Rhin,44,Grand Est
67317,NEUBOIS,67220,NEUBOIS,,48.3028817341,7.3176285572,317,,Neubois,Neubois,67,Bas-Rhin,44,Grand Est
67319,NEUHAEUSEL,67480,NEUHAEUSEL,,48.8185397229,8.09241128897,319,,Neuhaeusel,Neuhaeusel,67,Bas-Rhin,44,Grand Est
67320,NEUVE EGLISE,67220,NEUVE EGLISE,,48.3261151153,7.31059686746,320,,Neuve-Église,Neuve-Église,67,Bas-Rhin,44,Grand Est
67321,NEUVILLER LA ROCHE,67130,NEUVILLER LA ROCHE,,48.4309499766,7.26437049524,321,,Neuviller-la-Roche,Neuviller-la-Roche,67,Bas-Rhin,44,Grand Est
67321,NEUVILLER LA ROCHE,67130,NEUVILLER LA ROCHE,HAUTE GOUTTE,48.4309499766,7.26437049524,321,,Neuviller-la-Roche,Neuviller-la-Roche,67,Bas-Rhin,44,Grand Est
67322,NEUWILLER LES SAVERNE,67330,NEUWILLER LES SAVERNE,,48.8271341455,7.36606572186,322,,Neuwiller-lès-Saverne,Neuwiller-lès-Saverne,67,Bas-Rhin,44,Grand Est
67324,NIEDERBRONN LES BAINS,67110,NIEDERBRONN LES BAINS,,48.9638157112,7.63986093383,324,,Niederbronn-les-Bains,Niederbronn-les-Bains,67,Bas-Rhin,44,Grand Est
67325,NIEDERHASLACH,67280,NIEDERHASLACH,,48.5356531709,7.35017404112,325,,Niederhaslach,Niederhaslach,67,Bas-Rhin,44,Grand Est
67326,NIEDERHAUSBERGEN,67207,NIEDERHAUSBERGEN,,48.6233090076,7.70515748809,326,,Niederhausbergen,Niederhausbergen,67,Bas-Rhin,44,Grand Est
67327,NIEDERLAUTERBACH,67630,NIEDERLAUTERBACH,,48.971274266,8.10526672897,327,,Niederlauterbach,Niederlauterbach,67,Bas-Rhin,44,Grand Est
67328,NIEDERMODERN,67350,NIEDERMODERN,,48.8407663139,7.63538850073,328,,Niedermodern,Niedermodern,67,Bas-Rhin,44,Grand Est
67329,NIEDERNAI,67210,NIEDERNAI,,48.4456110275,7.53064650952,329,,Niedernai,Niedernai,67,Bas-Rhin,44,Grand Est
67330,NIEDERROEDERN,67470,NIEDERROEDERN,,48.912896258,8.05241114765,330,,Niederrœdern,Niederrœdern,67,Bas-Rhin,44,Grand Est
67331,NIEDERSCHAEFFOLSHEIM,67500,NIEDERSCHAEFFOLSHEIM,,48.7725031726,7.74386109493,331,,Niederschaeffolsheim,Niederschaeffolsheim,67,Bas-Rhin,44,Grand Est
67333,NIEDERSOULTZBACH,67330,NIEDERSOULTZBACH,,48.8506235374,7.4715582868,333,,Niedersoultzbach,Niedersoultzbach,67,Bas-Rhin,44,Grand Est
67334,NIEDERSTEINBACH,67510,NIEDERSTEINBACH,,49.0382020834,7.71349059998,334,,Niedersteinbach,Niedersteinbach,67,Bas-Rhin,44,Grand Est
67335,NORDHEIM,67520,NORDHEIM,,48.6396017162,7.50080829789,335,,Nordheim,Nordheim,67,Bas-Rhin,44,Grand Est
67336,NORDHOUSE,67150,NORDHOUSE,,48.4472243918,7.68558012653,336,,Nordhouse,Nordhouse,67,Bas-Rhin,44,Grand Est
67337,NOTHALTEN,67680,NOTHALTEN,,48.3556520235,7.41160208554,337,,Nothalten,Nothalten,67,Bas-Rhin,44,Grand Est
67338,OBENHEIM,67230,OBENHEIM,,48.3562350534,7.68497044431,338,,Obenheim,Obenheim,67,Bas-Rhin,44,Grand Est
67339,BETSCHDORF,67660,BETSCHDORF,,48.893487303,7.92255309446,339,,Betschdorf,Betschdorf,67,Bas-Rhin,44,Grand Est
67339,BETSCHDORF,67660,BETSCHDORF,KUHLENDORF,48.893487303,7.92255309446,339,,Betschdorf,Betschdorf,67,Bas-Rhin,44,Grand Est
67339,BETSCHDORF,67660,BETSCHDORF,REIMERSWILLER,48.893487303,7.92255309446,339,,Betschdorf,Betschdorf,67,Bas-Rhin,44,Grand Est
67339,BETSCHDORF,67660,BETSCHDORF,SCHWABWILLER,48.893487303,7.92255309446,339,,Betschdorf,Betschdorf,67,Bas-Rhin,44,Grand Est
67340,OBERBRONN,67110,OBERBRONN,,48.9380836461,7.59665709278,340,,Oberbronn,Oberbronn,67,Bas-Rhin,44,Grand Est
67341,OBERDORF SPACHBACH,67360,OBERDORF SPACHBACH,,48.9274480651,7.76852769166,341,,Oberdorf-Spachbach,Oberdorf-Spachbach,67,Bas-Rhin,44,Grand Est
67342,OBERHASLACH,67280,OBERHASLACH,,48.5685057118,7.29548789735,342,,Oberhaslach,Oberhaslach,67,Bas-Rhin,44,Grand Est
67343,OBERHAUSBERGEN,67205,OBERHAUSBERGEN,,48.6073002945,7.68309913156,343,,Oberhausbergen,Oberhausbergen,67,Bas-Rhin,44,Grand Est
67344,OBERHOFFEN LES WISSEMBOURG,67160,OBERHOFFEN LES WISSEMBOURG,,49.0107628213,7.91451933272,344,,Oberhoffen-lès-Wissembourg,Oberhoffen-lès-Wissembourg,67,Bas-Rhin,44,Grand Est
67345,OBERHOFFEN SUR MODER,67240,OBERHOFFEN SUR MODER,,48.7801040272,7.88738513222,345,,Oberhoffen-sur-Moder,Oberhoffen-sur-Moder,67,Bas-Rhin,44,Grand Est
67345,OBERHOFFEN SUR MODER,67240,OBERHOFFEN SUR MODER,CAMP D OBERHOFFEN,48.7801040272,7.88738513222,345,,Oberhoffen-sur-Moder,Oberhoffen-sur-Moder,67,Bas-Rhin,44,Grand Est
67346,OBERLAUTERBACH,67160,OBERLAUTERBACH,,48.9448114402,8.06578726125,346,,Oberlauterbach,Oberlauterbach,67,Bas-Rhin,44,Grand Est
67347,OBERMODERN ZUTZENDORF,67330,OBERMODERN ZUTZENDORF,,48.8479068198,7.54015464948,347,,Obermodern-Zutzendorf,Obermodern-Zutzendorf,67,Bas-Rhin,44,Grand Est
67347,OBERMODERN ZUTZENDORF,67330,OBERMODERN ZUTZENDORF,OBERMODERN,48.8479068198,7.54015464948,347,,Obermodern-Zutzendorf,Obermodern-Zutzendorf,67,Bas-Rhin,44,Grand Est
67347,OBERMODERN ZUTZENDORF,67330,OBERMODERN ZUTZENDORF,ZUTZENDORF,48.8479068198,7.54015464948,347,,Obermodern-Zutzendorf,Obermodern-Zutzendorf,67,Bas-Rhin,44,Grand Est
67348,OBERNAI,67210,OBERNAI,,48.4593467321,7.48006434701,348,,Obernai,Obernai,67,Bas-Rhin,44,Grand Est
67349,OBERROEDERN,67250,OBERROEDERN,,48.9271368909,7.97102967191,349,,Oberrœdern,Oberrœdern,67,Bas-Rhin,44,Grand Est
67350,OBERSCHAEFFOLSHEIM,67203,OBERSCHAEFFOLSHEIM,,48.5919169259,7.64608858988,350,,Oberschaeffolsheim,Oberschaeffolsheim,67,Bas-Rhin,44,Grand Est
67351,SEEBACH,67160,SEEBACH,,48.9644209228,7.99028108589,351,,Seebach,Seebach,67,Bas-Rhin,44,Grand Est
67351,SEEBACH,67160,SEEBACH,NIEDERSEEBACH,48.9644209228,7.99028108589,351,,Seebach,Seebach,67,Bas-Rhin,44,Grand Est
67351,SEEBACH,67160,SEEBACH,OBERSEEBACH,48.9644209228,7.99028108589,351,,Seebach,Seebach,67,Bas-Rhin,44,Grand Est
67352,OBERSOULTZBACH,67330,OBERSOULTZBACH,,48.851369071,7.44768959028,352,,Obersoultzbach,Obersoultzbach,67,Bas-Rhin,44,Grand Est
67353,OBERSTEINBACH,67510,OBERSTEINBACH,,49.0379940706,7.67309796819,353,,Obersteinbach,Obersteinbach,67,Bas-Rhin,44,Grand Est
67354,ODRATZHEIM,67520,ODRATZHEIM,,48.6001350338,7.48985443833,354,,Odratzheim,Odratzheim,67,Bas-Rhin,44,Grand Est
67355,OERMINGEN,67970,OERMINGEN,,48.9984271369,7.11950944283,355,,Oermingen,Oermingen,67,Bas-Rhin,44,Grand Est
67356,OFFENDORF,67850,OFFENDORF,,48.7148337726,7.92739646265,356,,Offendorf,Offendorf,67,Bas-Rhin,44,Grand Est
67358,OFFWILLER,67340,OFFWILLER,,48.917321847,7.5423120492,358,,Offwiller,Offwiller,67,Bas-Rhin,44,Grand Est
67359,OHLUNGEN,67170,OHLUNGEN,KEFFENDORF,48.8121908123,7.69421854068,359,,Ohlungen,Ohlungen,67,Bas-Rhin,44,Grand Est
67359,OHLUNGEN,67590,OHLUNGEN,,48.8121908123,7.69421854068,359,,Ohlungen,Ohlungen,67,Bas-Rhin,44,Grand Est
67360,OHNENHEIM,67390,OHNENHEIM,,48.1873821568,7.50382215032,360,,Ohnenheim,Ohnenheim,67,Bas-Rhin,44,Grand Est
67361,OLWISHEIM,67170,OLWISHEIM,,48.6977790108,7.67388730631,361,,Olwisheim,Olwisheim,67,Bas-Rhin,44,Grand Est
67362,ORSCHWILLER,67600,ORSCHWILLER,,48.2472141611,7.35466655979,362,,Orschwiller,Orschwiller,67,Bas-Rhin,44,Grand Est
67363,OSTHOFFEN,67990,OSTHOFFEN,,48.590139334,7.56183969821,363,,Osthoffen,Osthoffen,67,Bas-Rhin,44,Grand Est
67364,OSTHOUSE,67150,OSTHOUSE,,48.394273648,7.65078439355,364,,Osthouse,Osthouse,67,Bas-Rhin,44,Grand Est
67365,OSTWALD,67540,OSTWALD,,48.5471323955,7.70760430953,365,,Ostwald,Ostwald,67,Bas-Rhin,44,Grand Est
67366,OTTERSTHAL,67700,OTTERSTHAL,,48.7589616687,7.34210158035,366,,Ottersthal,Ottersthal,67,Bas-Rhin,44,Grand Est
67367,OTTERSWILLER,67700,OTTERSWILLER,,48.7226898525,7.37894754782,367,,Otterswiller,Otterswiller,67,Bas-Rhin,44,Grand Est
67368,OTTROTT,67530,OTTROTT,,48.4434374839,7.36707935429,368,,Ottrott,Ottrott,67,Bas-Rhin,44,Grand Est
67369,OTTWILLER,67320,OTTWILLER,,48.8634372221,7.22875830656,369,,Ottwiller,Ottwiller,67,Bas-Rhin,44,Grand Est
67370,PETERSBACH,67290,PETERSBACH,,48.8771297882,7.27203976424,370,,Petersbach,Petersbach,67,Bas-Rhin,44,Grand Est
67371,LA PETITE PIERRE,67290,LA PETITE PIERRE,,48.8657597248,7.32734776032,371,La,Petite-Pierre,La Petite-Pierre,67,Bas-Rhin,44,Grand Est
67372,VAL DE MODER,67350,VAL DE MODER,LA WALCK,48.8432047964,7.59893121728,372,,Val de Moder,Val de Moder,67,Bas-Rhin,44,Grand Est
67372,VAL DE MODER,67350,VAL DE MODER,PFAFFENHOFFEN,48.8432047964,7.59893121728,372,,Val de Moder,Val de Moder,67,Bas-Rhin,44,Grand Est
67372,VAL DE MODER,67350,VAL DE MODER,UBERACH,48.8432047964,7.59893121728,372,,Val de Moder,Val de Moder,67,Bas-Rhin,44,Grand Est
67373,PFALZWEYER,67320,PFALZWEYER,,48.8087234106,7.25966087365,373,,Pfalzweyer,Pfalzweyer,67,Bas-Rhin,44,Grand Est
67375,PFULGRIESHEIM,67370,PFULGRIESHEIM,,48.6443318607,7.6650628557,375,,Pfulgriesheim,Pfulgriesheim,67,Bas-Rhin,44,Grand Est
67377,PLAINE,67420,PLAINE,,48.4242524376,7.136828953,377,,Plaine,Plaine,67,Bas-Rhin,44,Grand Est
67377,PLAINE,67420,PLAINE,CHAMPENAY,48.4242524376,7.136828953,377,,Plaine,Plaine,67,Bas-Rhin,44,Grand Est
67377,PLAINE,67420,PLAINE,POUTAY,48.4242524376,7.136828953,377,,Plaine,Plaine,67,Bas-Rhin,44,Grand Est
67378,PLOBSHEIM,67115,PLOBSHEIM,,48.4612697761,7.73493123238,378,,Plobsheim,Plobsheim,67,Bas-Rhin,44,Grand Est
67379,PREUSCHDORF,67250,PREUSCHDORF,,48.9468559983,7.80368021275,379,,Preuschdorf,Preuschdorf,67,Bas-Rhin,44,Grand Est
67380,PRINTZHEIM,67490,PRINTZHEIM,,48.7920916691,7.47697747581,380,,Printzheim,Printzheim,67,Bas-Rhin,44,Grand Est
67381,PUBERG,67290,PUBERG,,48.9155823913,7.30719707129,381,,Puberg,Puberg,67,Bas-Rhin,44,Grand Est
67382,QUATZENHEIM,67117,QUATZENHEIM,,48.6274123136,7.57602311143,382,,Quatzenheim,Quatzenheim,67,Bas-Rhin,44,Grand Est
67383,RANGEN,67310,RANGEN,,48.6722666371,7.4769068152,383,,Rangen,Rangen,67,Bas-Rhin,44,Grand Est
67384,RANRUPT,67420,RANRUPT,,48.3735594187,7.19531631799,384,,Ranrupt,Ranrupt,67,Bas-Rhin,44,Grand Est
67385,RATZWILLER,67430,RATZWILLER,,48.9511834886,7.25526644462,385,,Ratzwiller,Ratzwiller,67,Bas-Rhin,44,Grand Est
67386,RAUWILLER,67320,RAUWILLER,,48.8098418508,7.11166295825,386,,Rauwiller,Rauwiller,67,Bas-Rhin,44,Grand Est
67387,REICHSFELD,67140,REICHSFELD,,48.3632176114,7.38013240259,387,,Reichsfeld,Reichsfeld,67,Bas-Rhin,44,Grand Est
67388,REICHSHOFFEN,67110,REICHSHOFFEN,,48.9374738141,7.67524392533,388,,Reichshoffen,Reichshoffen,67,Bas-Rhin,44,Grand Est
67388,REICHSHOFFEN,67110,REICHSHOFFEN,NEHWILLER,48.9374738141,7.67524392533,388,,Reichshoffen,Reichshoffen,67,Bas-Rhin,44,Grand Est
67389,REICHSTETT,67116,REICHSTETT,,48.6474873068,7.75703020685,389,,Reichstett,Reichstett,67,Bas-Rhin,44,Grand Est
67391,REINHARDSMUNSTER,67440,REINHARDSMUNSTER,,48.6824887091,7.29960945429,391,,Reinhardsmunster,Reinhardsmunster,67,Bas-Rhin,44,Grand Est
67392,REIPERTSWILLER,67340,REIPERTSWILLER,,48.9475831775,7.44744692113,392,,Reipertswiller,Reipertswiller,67,Bas-Rhin,44,Grand Est
67394,RETSCHWILLER,67250,RETSCHWILLER,,48.9509410927,7.8859650468,394,,Retschwiller,Retschwiller,67,Bas-Rhin,44,Grand Est
67395,REUTENBOURG,67440,REUTENBOURG,,48.6821850405,7.40817008946,395,,Reutenbourg,Reutenbourg,67,Bas-Rhin,44,Grand Est
67396,REXINGEN,67320,REXINGEN,,48.9055663204,7.17766752759,396,,Rexingen,Rexingen,67,Bas-Rhin,44,Grand Est
67397,RHINAU,67860,RHINAU,,48.3122226913,7.70444455631,397,,Rhinau,Rhinau,67,Bas-Rhin,44,Grand Est
67398,RICHTOLSHEIM,67390,RICHTOLSHEIM,,48.2238657703,7.60386287362,398,,Richtolsheim,Richtolsheim,67,Bas-Rhin,44,Grand Est
67400,RIEDSELTZ,67160,RIEDSELTZ,,48.9891485983,7.94873374892,400,,Riedseltz,Riedseltz,67,Bas-Rhin,44,Grand Est
67401,RIMSDORF,67260,RIMSDORF,,48.9288350756,7.11724868007,401,,Rimsdorf,Rimsdorf,67,Bas-Rhin,44,Grand Est
67402,RINGELDORF,67350,RINGELDORF,,48.8291827005,7.60614758961,402,,Ringeldorf,Ringeldorf,67,Bas-Rhin,44,Grand Est
67403,RINGENDORF,67350,RINGENDORF,,48.8101089087,7.55595222549,403,,Ringendorf,Ringendorf,67,Bas-Rhin,44,Grand Est
67404,RITTERSHOFFEN,67690,RITTERSHOFFEN,,48.892173968,7.97014656337,404,,Rittershoffen,Rittershoffen,67,Bas-Rhin,44,Grand Est
67405,ROESCHWOOG,67480,ROESCHWOOG,,48.8228673826,8.04052812469,405,,Rœschwoog,Rœschwoog,67,Bas-Rhin,44,Grand Est
67406,ROHR,67270,ROHR,,48.6932332205,7.53580119117,406,,Rohr,Rohr,67,Bas-Rhin,44,Grand Est
67407,ROHRWILLER,67410,ROHRWILLER,,48.7499197158,7.90265116944,407,,Rohrwiller,Rohrwiller,67,Bas-Rhin,44,Grand Est
67408,ROMANSWILLER,67310,ROMANSWILLER,,48.6407597718,7.38032558505,408,,Romanswiller,Romanswiller,67,Bas-Rhin,44,Grand Est
67409,ROPPENHEIM,67480,ROPPENHEIM,,48.837297524,8.06719387329,409,,Roppenheim,Roppenheim,67,Bas-Rhin,44,Grand Est
67410,ROSENWILLER,67560,ROSENWILLER,,48.5087284504,7.42577148597,410,,Rosenwiller,Rosenwiller,67,Bas-Rhin,44,Grand Est
67411,ROSHEIM,67560,ROSHEIM,,48.490413735,7.41207007733,411,,Rosheim,Rosheim,67,Bas-Rhin,44,Grand Est
67412,ROSSFELD,67230,ROSSFELD,,48.3321360807,7.61305399839,412,,Rossfeld,Rossfeld,67,Bas-Rhin,44,Grand Est
67413,ROSTEIG,67290,ROSTEIG,,48.9319268767,7.33608226075,413,,Rosteig,Rosteig,67,Bas-Rhin,44,Grand Est
67414,ROTHAU,67570,ROTHAU,,48.447644633,7.2070541855,414,,Rothau,Rothau,67,Bas-Rhin,44,Grand Est
67415,ROTHBACH,67340,ROTHBACH,,48.9053776455,7.51101928675,415,,Rothbach,Rothbach,67,Bas-Rhin,44,Grand Est
67416,ROTT,67160,ROTT,,49.0192487801,7.90612997261,416,,Rott,Rott,67,Bas-Rhin,44,Grand Est
67417,ROTTELSHEIM,67170,ROTTELSHEIM,,48.759113126,7.71180608722,417,,Rottelsheim,Rottelsheim,67,Bas-Rhin,44,Grand Est
67418,ROUNTZENHEIM,67480,ROUNTZENHEIM,,48.8214167109,7.99834154891,418,,Rountzenheim,Rountzenheim,67,Bas-Rhin,44,Grand Est
67420,RUSS,67130,RUSS,,48.485644468,7.26907262122,420,,Russ,Russ,67,Bas-Rhin,44,Grand Est
67420,RUSS,67130,RUSS,SCHWARZBACH,48.485644468,7.26907262122,420,,Russ,Russ,67,Bas-Rhin,44,Grand Est
67420,RUSS,67130,RUSS,STEINBACH,48.485644468,7.26907262122,420,,Russ,Russ,67,Bas-Rhin,44,Grand Est
67421,SAALES,67420,SAALES,,48.3516658281,7.11035100405,421,,Saales,Saales,67,Bas-Rhin,44,Grand Est
67422,SAASENHEIM,67390,SAASENHEIM,,48.239955028,7.62929125209,422,,Saasenheim,Saasenheim,67,Bas-Rhin,44,Grand Est
67423,SAESSOLSHEIM,67270,SAESSOLSHEIM,,48.7083552317,7.51301777212,423,,Saessolsheim,Saessolsheim,67,Bas-Rhin,44,Grand Est
67424,ST BLAISE LA ROCHE,67420,ST BLAISE LA ROCHE,,48.4073616064,7.17386676722,424,,Saint-Blaise-la-Roche,Saint-Blaise-la-Roche,67,Bas-Rhin,44,Grand Est
67425,ST JEAN SAVERNE,67700,ST JEAN SAVERNE,,48.7782167422,7.3556231734,425,,Saint-Jean-Saverne,Saint-Jean-Saverne,67,Bas-Rhin,44,Grand Est
67426,ST MARTIN,67220,ST MARTIN,,48.3498144162,7.28574653524,426,,Saint-Martin,Saint-Martin,67,Bas-Rhin,44,Grand Est
67427,ST MAURICE,67220,ST MAURICE,,48.3329201616,7.33527285146,427,,Saint-Maurice,Saint-Maurice,67,Bas-Rhin,44,Grand Est
67428,ST NABOR,67530,ST NABOR,,48.4414538675,7.41761781742,428,,Saint-Nabor,Saint-Nabor,67,Bas-Rhin,44,Grand Est
67429,ST PIERRE,67140,ST PIERRE,,48.3845643843,7.46512483546,429,,Saint-Pierre,Saint-Pierre,67,Bas-Rhin,44,Grand Est
67430,ST PIERRE BOIS,67220,ST PIERRE BOIS,,48.3357426244,7.36372911449,430,,Saint-Pierre-Bois,Saint-Pierre-Bois,67,Bas-Rhin,44,Grand Est
67430,ST PIERRE BOIS,67220,ST PIERRE BOIS,HOHWARTH,48.3357426244,7.36372911449,430,,Saint-Pierre-Bois,Saint-Pierre-Bois,67,Bas-Rhin,44,Grand Est
67432,SALMBACH,67160,SALMBACH,,48.9761241768,8.07012964746,432,,Salmbach,Salmbach,67,Bas-Rhin,44,Grand Est
67433,SAND,67230,SAND,,48.3775481773,7.61937437402,433,,Sand,Sand,67,Bas-Rhin,44,Grand Est
67434,SARRE UNION,67260,SARRE UNION,,48.9451866571,7.08863161456,434,,Sarre-Union,Sarre-Union,67,Bas-Rhin,44,Grand Est
67435,SARREWERDEN,67260,SARREWERDEN,,48.9076740897,7.07306614237,435,,Sarrewerden,Sarrewerden,67,Bas-Rhin,44,Grand Est
67435,SARREWERDEN,67260,SARREWERDEN,BISCHTROFF SUR SARRE,48.9076740897,7.07306614237,435,,Sarrewerden,Sarrewerden,67,Bas-Rhin,44,Grand Est
67435,SARREWERDEN,67260,SARREWERDEN,ZOLLINGEN,48.9076740897,7.07306614237,435,,Sarrewerden,Sarrewerden,67,Bas-Rhin,44,Grand Est
67436,SAULXURES,67420,SAULXURES,,48.3924491684,7.1235892563,436,,Saulxures,Saulxures,67,Bas-Rhin,44,Grand Est
67437,SAVERNE,67700,SAVERNE,,48.7402250066,7.34206238199,437,,Saverne,Saverne,67,Bas-Rhin,44,Grand Est
67437,SAVERNE,67700,SAVERNE,ZORNHOF,48.7402250066,7.34206238199,437,,Saverne,Saverne,67,Bas-Rhin,44,Grand Est
67437,SAVERNE,67700,SAVERNE,ZORNTHAL,48.7402250066,7.34206238199,437,,Saverne,Saverne,67,Bas-Rhin,44,Grand Est
67438,SCHAEFFERSHEIM,67150,SCHAEFFERSHEIM,,48.4340220417,7.61316826135,438,,Schaeffersheim,Schaeffersheim,67,Bas-Rhin,44,Grand Est
67439,SCHAFFHOUSE SUR ZORN,67270,SCHAFFHOUSE SUR ZORN,,48.7338365703,7.55662870926,439,,Schaffhouse-sur-Zorn,Schaffhouse-sur-Zorn,67,Bas-Rhin,44,Grand Est
67440,SCHAFFHOUSE PRES SELTZ,67470,SCHAFFHOUSE PRES SELTZ,,48.9121321004,8.08967677055,440,,Schaffhouse-près-Seltz,Schaffhouse-près-Seltz,67,Bas-Rhin,44,Grand Est
67441,SCHALKENDORF,67350,SCHALKENDORF,,48.8369254712,7.56834375318,441,,Schalkendorf,Schalkendorf,67,Bas-Rhin,44,Grand Est
67442,SCHARRACHBERGHEIM IRMSTETT,67310,SCHARRACHBERGHEIM IRMSTETT,,48.5896337884,7.4902817682,442,,Scharrachbergheim-Irmstett,Scharrachbergheim-Irmstett,67,Bas-Rhin,44,Grand Est
67442,SCHARRACHBERGHEIM IRMSTETT,67310,SCHARRACHBERGHEIM IRMSTETT,IRMSTETT,48.5896337884,7.4902817682,442,,Scharrachbergheim-Irmstett,Scharrachbergheim-Irmstett,67,Bas-Rhin,44,Grand Est
67443,SCHEIBENHARD,67630,SCHEIBENHARD,,48.9694902772,8.14441884715,443,,Scheibenhard,Scheibenhard,67,Bas-Rhin,44,Grand Est
67444,SCHERLENHEIM,67270,SCHERLENHEIM,,48.7660880313,7.53344245942,444,,Scherlenheim,Scherlenheim,67,Bas-Rhin,44,Grand Est
67445,SCHERWILLER,67750,SCHERWILLER,,48.2952491317,7.41512520333,445,,Scherwiller,Scherwiller,67,Bas-Rhin,44,Grand Est
67445,SCHERWILLER,67750,SCHERWILLER,KIENTZVILLE,48.2952491317,7.41512520333,445,,Scherwiller,Scherwiller,67,Bas-Rhin,44,Grand Est
67446,SCHILLERSDORF,67340,SCHILLERSDORF,,48.8709974146,7.52392906348,446,,Schillersdorf,Schillersdorf,67,Bas-Rhin,44,Grand Est
67447,SCHILTIGHEIM,67300,SCHILTIGHEIM,,48.6118660258,7.74408199334,447,,Schiltigheim,Schiltigheim,67,Bas-Rhin,44,Grand Est
67448,SCHIRMECK,67130,SCHIRMECK,,48.4922866758,7.19802185316,448,,Schirmeck,Schirmeck,67,Bas-Rhin,44,Grand Est
67448,SCHIRMECK,67130,SCHIRMECK,WACKENBACH,48.4922866758,7.19802185316,448,,Schirmeck,Schirmeck,67,Bas-Rhin,44,Grand Est
67449,SCHIRRHEIN,67240,SCHIRRHEIN,,48.7957460013,7.91694869062,449,,Schirrhein,Schirrhein,67,Bas-Rhin,44,Grand Est
67450,SCHIRRHOFFEN,67240,SCHIRRHOFFEN,,48.8069986345,7.92537675508,450,,Schirrhoffen,Schirrhoffen,67,Bas-Rhin,44,Grand Est
67451,SCHLEITHAL,67160,SCHLEITHAL,,48.9866877882,8.03245786047,451,,Schleithal,Schleithal,67,Bas-Rhin,44,Grand Est
67452,SCHNERSHEIM,67370,SCHNERSHEIM,,48.6601653977,7.56876364377,452,,Schnersheim,Schnersheim,67,Bas-Rhin,44,Grand Est
67452,SCHNERSHEIM,67370,SCHNERSHEIM,AVENHEIM,48.6601653977,7.56876364377,452,,Schnersheim,Schnersheim,67,Bas-Rhin,44,Grand Est
67452,SCHNERSHEIM,67370,SCHNERSHEIM,KLEINFRANKENHEIM,48.6601653977,7.56876364377,452,,Schnersheim,Schnersheim,67,Bas-Rhin,44,Grand Est
67453,SCHOENAU,67390,SCHOENAU,,48.2260065064,7.65066673603,453,,Schœnau,Schœnau,67,Bas-Rhin,44,Grand Est
67454,SCHOENBOURG,67320,SCHOENBOURG,,48.8335988956,7.26601140557,454,,Schœnbourg,Schœnbourg,67,Bas-Rhin,44,Grand Est
67455,SCHOENENBOURG,67250,SCHOENENBOURG,,48.9503956142,7.91692156596,455,,Schœnenbourg,Schœnenbourg,67,Bas-Rhin,44,Grand Est
67456,SCHOPPERTEN,67260,SCHOPPERTEN,,48.9475398723,7.05381254412,456,,Schopperten,Schopperten,67,Bas-Rhin,44,Grand Est
67458,SCHWEIGHOUSE SUR MODER,67590,SCHWEIGHOUSE SUR MODER,,48.8172159239,7.7377298253,458,,Schweighouse-sur-Moder,Schweighouse-sur-Moder,67,Bas-Rhin,44,Grand Est
67459,SCHWENHEIM,67440,SCHWENHEIM,,48.7149255569,7.41004083904,459,,Schwenheim,Schwenheim,67,Bas-Rhin,44,Grand Est
67460,SCHWINDRATZHEIM,67270,SCHWINDRATZHEIM,,48.765401982,7.60282147839,460,,Schwindratzheim,Schwindratzheim,67,Bas-Rhin,44,Grand Est
67461,SCHWOBSHEIM,67390,SCHWOBSHEIM,,48.2365028477,7.57432904061,461,,Schwobsheim,Schwobsheim,67,Bas-Rhin,44,Grand Est
67462,SELESTAT,67600,SELESTAT,,48.2481136279,7.46224896454,462,,Sélestat,Sélestat,67,Bas-Rhin,44,Grand Est
67463,SELTZ,67470,SELTZ,,48.8938553051,8.10498682501,463,,Seltz,Seltz,67,Bas-Rhin,44,Grand Est
67464,SERMERSHEIM,67230,SERMERSHEIM,,48.3443357629,7.55277785563,464,,Sermersheim,Sermersheim,67,Bas-Rhin,44,Grand Est
67465,SESSENHEIM,67770,SESSENHEIM,,48.7952349211,7.98061145834,465,,Sessenheim,Sessenheim,67,Bas-Rhin,44,Grand Est
67466,SIEGEN,67160,SIEGEN,,48.957145226,8.03962378793,466,,Siegen,Siegen,67,Bas-Rhin,44,Grand Est
67467,SIEWILLER,67320,SIEWILLER,,48.8414802623,7.20492965057,467,,Siewiller,Siewiller,67,Bas-Rhin,44,Grand Est
67468,SILTZHEIM,67260,SILTZHEIM,,49.061608516,7.09081368964,468,,Siltzheim,Siltzheim,67,Bas-Rhin,44,Grand Est
67470,SOLBACH,67130,SOLBACH,,48.4311691709,7.19975808177,470,,Solbach,Solbach,67,Bas-Rhin,44,Grand Est
67471,SOUFFELWEYERSHEIM,67460,SOUFFELWEYERSHEIM,,48.6319311688,7.74194727065,471,,Souffelweyersheim,Souffelweyersheim,67,Bas-Rhin,44,Grand Est
67472,SOUFFLENHEIM,67620,SOUFFLENHEIM,,48.8085682655,7.95676945015,472,,Soufflenheim,Soufflenheim,67,Bas-Rhin,44,Grand Est
67473,SOULTZ LES BAINS,67120,SOULTZ LES BAINS,,48.5676100551,7.47820560302,473,,Soultz-les-Bains,Soultz-les-Bains,67,Bas-Rhin,44,Grand Est
67474,SOULTZ SOUS FORETS,67250,SOULTZ SOUS FORETS,,48.9461642075,7.87359864642,474,,Soultz-sous-Forêts,Soultz-sous-Forêts,67,Bas-Rhin,44,Grand Est
67474,SOULTZ SOUS FORETS,67250,SOULTZ SOUS FORETS,HOHWILLER,48.9461642075,7.87359864642,474,,Soultz-sous-Forêts,Soultz-sous-Forêts,67,Bas-Rhin,44,Grand Est
67475,SPARSBACH,67340,SPARSBACH,,48.8795880756,7.40029606816,475,,Sparsbach,Sparsbach,67,Bas-Rhin,44,Grand Est
67476,STATTMATTEN,67770,STATTMATTEN,,48.794852744,8.01771717593,476,,Stattmatten,Stattmatten,67,Bas-Rhin,44,Grand Est
67477,STEIGE,67220,STEIGE,,48.3628206219,7.22974408388,477,,Steige,Steige,67,Bas-Rhin,44,Grand Est
67478,STEINBOURG,67790,STEINBOURG,,48.7639649052,7.41351196572,478,,Steinbourg,Steinbourg,67,Bas-Rhin,44,Grand Est
67479,STEINSELTZ,67160,STEINSELTZ,,49.0050564823,7.92824415815,479,,Steinseltz,Steinseltz,67,Bas-Rhin,44,Grand Est
67480,STILL,67190,STILL,,48.5666223105,7.36640588547,480,,Still,Still,67,Bas-Rhin,44,Grand Est
67481,STOTZHEIM,67140,STOTZHEIM,,48.3757768768,7.50674742372,481,,Stotzheim,Stotzheim,67,Bas-Rhin,44,Grand Est
67482,STRASBOURG,67000,STRASBOURG,,48.5712679849,7.76752679517,482,,Strasbourg,Strasbourg,67,Bas-Rhin,44,Grand Est
67482,STRASBOURG,67100,STRASBOURG,,48.5712679849,7.76752679517,482,,Strasbourg,Strasbourg,67,Bas-Rhin,44,Grand Est
67482,STRASBOURG,67200,STRASBOURG,,48.5712679849,7.76752679517,482,,Strasbourg,Strasbourg,67,Bas-Rhin,44,Grand Est
67483,STRUTH,67290,STRUTH,,48.8951838329,7.26857056944,483,,Struth,Struth,67,Bas-Rhin,44,Grand Est
67484,STUNDWILLER,67250,STUNDWILLER,,48.9244859775,7.99641194102,484,,Stundwiller,Stundwiller,67,Bas-Rhin,44,Grand Est
67485,STUTZHEIM OFFENHEIM,67370,STUTZHEIM OFFENHEIM,,48.6266854677,7.62711611103,485,,Stutzheim-Offenheim,Stutzheim-Offenheim,67,Bas-Rhin,44,Grand Est
67485,STUTZHEIM OFFENHEIM,67370,STUTZHEIM OFFENHEIM,OFFENHEIM,48.6266854677,7.62711611103,485,,Stutzheim-Offenheim,Stutzheim-Offenheim,67,Bas-Rhin,44,Grand Est
67486,SUNDHOUSE,67920,SUNDHOUSE,,48.2550723499,7.63031906582,486,,Sundhouse,Sundhouse,67,Bas-Rhin,44,Grand Est
67487,SURBOURG,67250,SURBOURG,,48.9102568228,7.83566149848,487,,Surbourg,Surbourg,67,Bas-Rhin,44,Grand Est
67488,THAL DRULINGEN,67320,THAL DRULINGEN,,48.9123489426,7.13788332344,488,,Thal-Drulingen,Thal-Drulingen,67,Bas-Rhin,44,Grand Est
67489,THAL MARMOUTIER,67440,THAL MARMOUTIER,,48.6960773859,7.34056735881,489,,Thal-Marmoutier,Thal-Marmoutier,67,Bas-Rhin,44,Grand Est
67489,THAL MARMOUTIER,67440,THAL MARMOUTIER,SCHWEBWILLER,48.6960773859,7.34056735881,489,,Thal-Marmoutier,Thal-Marmoutier,67,Bas-Rhin,44,Grand Est
67489,THAL MARMOUTIER,67440,THAL MARMOUTIER,ST GALL,48.6960773859,7.34056735881,489,,Thal-Marmoutier,Thal-Marmoutier,67,Bas-Rhin,44,Grand Est
67490,THANVILLE,67220,THANVILLE,,48.3253039558,7.34853223079,490,,Thanvillé,Thanvillé,67,Bas-Rhin,44,Grand Est
67491,TIEFFENBACH,67290,TIEFFENBACH,,48.9024013107,7.24423915317,491,,Tieffenbach,Tieffenbach,67,Bas-Rhin,44,Grand Est
67492,TRAENHEIM,67310,TRAENHEIM,,48.5940735838,7.46515518656,492,,Traenheim,Traenheim,67,Bas-Rhin,44,Grand Est
67493,TRIEMBACH AU VAL,67220,TRIEMBACH AU VAL,,48.3434265529,7.33416002911,493,,Triembach-au-Val,Triembach-au-Val,67,Bas-Rhin,44,Grand Est
67494,TRIMBACH,67470,TRIMBACH,,48.9403737526,8.01823156218,494,,Trimbach,Trimbach,67,Bas-Rhin,44,Grand Est
67495,TRUCHTERSHEIM,67370,TRUCHTERSHEIM,,48.6564842295,7.61650465885,495,,Truchtersheim,Truchtersheim,67,Bas-Rhin,44,Grand Est
67495,TRUCHTERSHEIM,67370,TRUCHTERSHEIM,BEHLENHEIM,48.6564842295,7.61650465885,495,,Truchtersheim,Truchtersheim,67,Bas-Rhin,44,Grand Est
67495,TRUCHTERSHEIM,67370,TRUCHTERSHEIM,PFETTISHEIM,48.6564842295,7.61650465885,495,,Truchtersheim,Truchtersheim,67,Bas-Rhin,44,Grand Est
67497,UHLWILLER,67350,UHLWILLER,,48.8202578689,7.67470803749,497,,Uhlwiller,Uhlwiller,67,Bas-Rhin,44,Grand Est
67497,UHLWILLER,67350,UHLWILLER,NIEDERALTDORF,48.8202578689,7.67470803749,497,,Uhlwiller,Uhlwiller,67,Bas-Rhin,44,Grand Est
67498,UHRWILLER,67350,UHRWILLER,,48.8809856779,7.57978326718,498,,Uhrwiller,Uhrwiller,67,Bas-Rhin,44,Grand Est
67499,URBEIS,67220,URBEIS,,48.3323782294,7.20301847743,499,,Urbeis,Urbeis,67,Bas-Rhin,44,Grand Est
67500,URMATT,67280,URMATT,,48.5390014537,7.29527991151,500,,Urmatt,Urmatt,67,Bas-Rhin,44,Grand Est
67501,UTTENHEIM,67150,UTTENHEIM,,48.4140516502,7.59572846761,501,,Uttenheim,Uttenheim,67,Bas-Rhin,44,Grand Est
67502,UTTENHOFFEN,67110,UTTENHOFFEN,,48.8931983162,7.64472103816,502,,Uttenhoffen,Uttenhoffen,67,Bas-Rhin,44,Grand Est
67503,UTTWILLER,67330,UTTWILLER,,48.8464668751,7.49650290601,503,,Uttwiller,Uttwiller,67,Bas-Rhin,44,Grand Est
67504,VALFF,67210,VALFF,,48.4221033053,7.52644718944,504,,Valff,Valff,67,Bas-Rhin,44,Grand Est
67505,LA VANCELLE,67730,LA VANCELLE,,48.2814104124,7.31939719314,505,La,Vancelle,La Vancelle,67,Bas-Rhin,44,Grand Est
67506,VENDENHEIM,67550,VENDENHEIM,,48.6674524477,7.73934378443,506,,Vendenheim,Vendenheim,67,Bas-Rhin,44,Grand Est
67507,VILLE,67220,VILLE,,48.3429794644,7.30342869358,507,,Villé,Villé,67,Bas-Rhin,44,Grand Est
67508,VOELLERDINGEN,67430,VOELLERDINGEN,,48.9716431163,7.13658791217,508,,Vœllerdingen,Vœllerdingen,67,Bas-Rhin,44,Grand Est
67509,VOLKSBERG,67290,VOLKSBERG,,48.940471202,7.29797593831,509,,Volksberg,Volksberg,67,Bas-Rhin,44,Grand Est
67510,WAHLENHEIM,67170,WAHLENHEIM,,48.7659087091,7.68693403664,510,,Wahlenheim,Wahlenheim,67,Bas-Rhin,44,Grand Est
67511,WALBOURG,67360,WALBOURG,,48.8857093075,7.78279699588,511,,Walbourg,Walbourg,67,Bas-Rhin,44,Grand Est
67513,WALDERSBACH,67130,WALDERSBACH,,48.4178454688,7.21830400487,513,,Waldersbach,Waldersbach,67,Bas-Rhin,44,Grand Est
67514,WALDHAMBACH,67430,WALDHAMBACH,,48.9271512817,7.2306707984,514,,Waldhambach,Waldhambach,67,Bas-Rhin,44,Grand Est
67515,WALDOLWISHEIM,67700,WALDOLWISHEIM,,48.734237181,7.4372618571,515,,Waldolwisheim,Waldolwisheim,67,Bas-Rhin,44,Grand Est
67516,WALTENHEIM SUR ZORN,67670,WALTENHEIM SUR ZORN,,48.7430310189,7.62201950135,516,,Waltenheim-sur-Zorn,Waltenheim-sur-Zorn,67,Bas-Rhin,44,Grand Est
67517,WANGEN,67520,WANGEN,,48.6188684237,7.46340283895,517,,Wangen,Wangen,67,Bas-Rhin,44,Grand Est
67519,LA WANTZENAU,67610,LA WANTZENAU,,48.6593283518,7.83143435964,519,La,Wantzenau,La Wantzenau,67,Bas-Rhin,44,Grand Est
67520,WASSELONNE,67310,WASSELONNE,,48.6388786162,7.44230441985,520,,Wasselonne,Wasselonne,67,Bas-Rhin,44,Grand Est
67520,WASSELONNE,67310,WASSELONNE,BRECHLINGEN,48.6388786162,7.44230441985,520,,Wasselonne,Wasselonne,67,Bas-Rhin,44,Grand Est
67521,WEINBOURG,67340,WEINBOURG,,48.8716635138,7.44076815738,521,,Weinbourg,Weinbourg,67,Bas-Rhin,44,Grand Est
67522,WEISLINGEN,67290,WEISLINGEN,,48.9192784207,7.25784429593,522,,Weislingen,Weislingen,67,Bas-Rhin,44,Grand Est
67523,WEITBRUCH,67500,WEITBRUCH,,48.7621242565,7.78027504315,523,,Weitbruch,Weitbruch,67,Bas-Rhin,44,Grand Est
67524,WEITERSWILLER,67340,WEITERSWILLER,,48.8549695809,7.40901430205,524,,Weiterswiller,Weiterswiller,67,Bas-Rhin,44,Grand Est
67525,WESTHOFFEN,67310,WESTHOFFEN,,48.6050551631,7.41483819754,525,,Westhoffen,Westhoffen,67,Bas-Rhin,44,Grand Est
67526,WESTHOUSE,67230,WESTHOUSE,,48.4068133604,7.57239597333,526,,Westhouse,Westhouse,67,Bas-Rhin,44,Grand Est
67527,WESTHOUSE MARMOUTIER,67440,WESTHOUSE MARMOUTIER,,48.6858047247,7.45416585837,527,,Westhouse-Marmoutier,Westhouse-Marmoutier,67,Bas-Rhin,44,Grand Est
67528,WEYER,67320,WEYER,,48.858764489,7.16208154442,528,,Weyer,Weyer,67,Bas-Rhin,44,Grand Est
67529,WEYERSHEIM,67720,WEYERSHEIM,,48.7165328229,7.83088991844,529,,Weyersheim,Weyersheim,67,Bas-Rhin,44,Grand Est
67530,WICKERSHEIM WILSHAUSEN,67270,WICKERSHEIM WILSHAUSEN,,48.7815411023,7.53331362139,530,,Wickersheim-Wilshausen,Wickersheim-Wilshausen,67,Bas-Rhin,44,Grand Est
67530,WICKERSHEIM WILSHAUSEN,67270,WICKERSHEIM WILSHAUSEN,WILSHAUSEN,48.7815411023,7.53331362139,530,,Wickersheim-Wilshausen,Wickersheim-Wilshausen,67,Bas-Rhin,44,Grand Est
67531,WILDERSBACH,67130,WILDERSBACH,,48.4358512521,7.22390399237,531,,Wildersbach,Wildersbach,67,Bas-Rhin,44,Grand Est
67532,WILLGOTTHEIM,67370,WILLGOTTHEIM,,48.6735628136,7.51567430993,532,,Willgottheim,Willgottheim,67,Bas-Rhin,44,Grand Est
67532,WILLGOTTHEIM,67370,WILLGOTTHEIM,WOELLENHEIM,48.6735628136,7.51567430993,532,,Willgottheim,Willgottheim,67,Bas-Rhin,44,Grand Est
67534,WILWISHEIM,67270,WILWISHEIM,,48.753708765,7.49990047042,534,,Wilwisheim,Wilwisheim,67,Bas-Rhin,44,Grand Est
67535,WIMMENAU,67290,WIMMENAU,,48.9213776191,7.42020495369,535,,Wimmenau,Wimmenau,67,Bas-Rhin,44,Grand Est
67536,WINDSTEIN,67110,WINDSTEIN,,48.9912433394,7.69559951845,536,,Windstein,Windstein,67,Bas-Rhin,44,Grand Est
67536,WINDSTEIN,67110,WINDSTEIN,JAEGERTHAL,48.9912433394,7.69559951845,536,,Windstein,Windstein,67,Bas-Rhin,44,Grand Est
67537,WINGEN,67510,WINGEN,,49.0391731666,7.81446231798,537,,Wingen,Wingen,67,Bas-Rhin,44,Grand Est
67537,WINGEN,67510,WINGEN,PETIT WINGEN,49.0391731666,7.81446231798,537,,Wingen,Wingen,67,Bas-Rhin,44,Grand Est
67538,WINGEN SUR MODER,67290,WINGEN SUR MODER,,48.9254902407,7.37022391783,538,,Wingen-sur-Moder,Wingen-sur-Moder,67,Bas-Rhin,44,Grand Est
67539,WINGERSHEIM LES QUATRE BANS,67170,WINGERSHEIM LES QUATRE BANS,,48.7299632936,7.63820636503,539,,Wingersheim les Quatre Bans,Wingersheim les Quatre Bans,67,Bas-Rhin,44,Grand Est
67539,WINGERSHEIM LES QUATRE BANS,67170,WINGERSHEIM LES QUATRE BANS,HOHATZENHEIM,48.7299632936,7.63820636503,539,,Wingersheim les Quatre Bans,Wingersheim les Quatre Bans,67,Bas-Rhin,44,Grand Est
67539,WINGERSHEIM LES QUATRE BANS,67170,WINGERSHEIM LES QUATRE BANS,MITTELHAUSEN,48.7299632936,7.63820636503,539,,Wingersheim les Quatre Bans,Wingersheim les Quatre Bans,67,Bas-Rhin,44,Grand Est
67539,WINGERSHEIM LES QUATRE BANS,67270,WINGERSHEIM LES QUATRE BANS,GINGSHEIM,48.7299632936,7.63820636503,539,,Wingersheim les Quatre Bans,Wingersheim les Quatre Bans,67,Bas-Rhin,44,Grand Est
67540,WINTERSHOUSE,67590,WINTERSHOUSE,,48.796262357,7.70641296219,540,,Wintershouse,Wintershouse,67,Bas-Rhin,44,Grand Est
67541,WINTZENBACH,67470,WINTZENBACH,,48.9320980019,8.10430684085,541,,Wintzenbach,Wintzenbach,67,Bas-Rhin,44,Grand Est
67542,WINTZENHEIM KOCHERSBERG,67370,WINTZENHEIM KOCHERSBERG,,48.6578223701,7.50786446733,542,,Wintzenheim-Kochersberg,Wintzenheim-Kochersberg,67,Bas-Rhin,44,Grand Est
67543,WISCHES,67130,WISCHES,,48.5121595612,7.21702183927,543,,Wisches,Wisches,67,Bas-Rhin,44,Grand Est
67543,WISCHES,67130,WISCHES,HERSBACH,48.5121595612,7.21702183927,543,,Wisches,Wisches,67,Bas-Rhin,44,Grand Est
67543,WISCHES,67130,WISCHES,NETZENBACH,48.5121595612,7.21702183927,543,,Wisches,Wisches,67,Bas-Rhin,44,Grand Est
67544,WISSEMBOURG,67160,WISSEMBOURG,,49.0184562539,7.96174575912,544,,Wissembourg,Wissembourg,67,Bas-Rhin,44,Grand Est
67544,WISSEMBOURG,67160,WISSEMBOURG,ALTENSTADT,49.0184562539,7.96174575912,544,,Wissembourg,Wissembourg,67,Bas-Rhin,44,Grand Est
67544,WISSEMBOURG,67160,WISSEMBOURG,WEILER,49.0184562539,7.96174575912,544,,Wissembourg,Wissembourg,67,Bas-Rhin,44,Grand Est
67545,WITTERNHEIM,67230,WITTERNHEIM,,48.3134249965,7.6177163093,545,,Witternheim,Witternheim,67,Bas-Rhin,44,Grand Est
67546,WITTERSHEIM,67670,WITTERSHEIM,,48.7801634182,7.65461502349,546,,Wittersheim,Wittersheim,67,Bas-Rhin,44,Grand Est
67547,WITTISHEIM,67820,WITTISHEIM,,48.2630616091,7.59069438607,547,,Wittisheim,Wittisheim,67,Bas-Rhin,44,Grand Est
67548,WIWERSHEIM,67370,WIWERSHEIM,,48.6379420012,7.59735898171,548,,Wiwersheim,Wiwersheim,67,Bas-Rhin,44,Grand Est
67550,WOERTH,67360,WOERTH,,48.9412737008,7.74416394777,550,,Wœrth,Wœrth,67,Bas-Rhin,44,Grand Est
67551,WOLFISHEIM,67202,WOLFISHEIM,,48.5869426788,7.66313638698,551,,Wolfisheim,Wolfisheim,67,Bas-Rhin,44,Grand Est
67552,WOLFSKIRCHEN,67260,WOLFSKIRCHEN,,48.8758422712,7.08071824299,552,,Wolfskirchen,Wolfskirchen,67,Bas-Rhin,44,Grand Est
67553,WOLSCHHEIM,67700,WOLSCHHEIM,,48.706871713,7.45128183005,553,,Wolschheim,Wolschheim,67,Bas-Rhin,44,Grand Est
67554,WOLXHEIM,67120,WOLXHEIM,,48.5703058902,7.5074599373,554,,Wolxheim,Wolxheim,67,Bas-Rhin,44,Grand Est
67554,WOLXHEIM,67120,WOLXHEIM,CANAL,48.5703058902,7.5074599373,554,,Wolxheim,Wolxheim,67,Bas-Rhin,44,Grand Est
67555,ZEHNACKER,67310,ZEHNACKER,,48.670402343,7.45043435206,555,,Zehnacker,Zehnacker,67,Bas-Rhin,44,Grand Est
67556,ZEINHEIM,67310,ZEINHEIM,,48.6785745127,7.48842740498,556,,Zeinheim,Zeinheim,67,Bas-Rhin,44,Grand Est
67557,ZELLWILLER,67140,ZELLWILLER,,48.3992469094,7.51120403542,557,,Zellwiller,Zellwiller,67,Bas-Rhin,44,Grand Est
67558,ZINSWILLER,67110,ZINSWILLER,,48.9161140099,7.58112323048,558,,Zinswiller,Zinswiller,67,Bas-Rhin,44,Grand Est
67559,ZITTERSHEIM,67290,ZITTERSHEIM,,48.9007208521,7.33743606824,559,,Zittersheim,Zittersheim,67,Bas-Rhin,44,Grand Est
67560,ZOEBERSDORF,67270,ZOEBERSDORF,,48.7949132154,7.52344592585,560,,Zœbersdorf,Zœbersdorf,67,Bas-Rhin,44,Grand Est
68001,ALGOLSHEIM,68600,ALGOLSHEIM,,48.0034942953,7.54980840525,1,,Algolsheim,Algolsheim,68,Haut-Rhin,44,Grand Est
68002,ALTENACH,68210,ALTENACH,,47.6017527449,7.10896561686,2,,Altenach,Altenach,68,Haut-Rhin,44,Grand Est
68004,ALTKIRCH,68130,ALTKIRCH,,47.6210336538,7.24484329622,4,,Altkirch,Altkirch,68,Haut-Rhin,44,Grand Est
68005,AMMERSCHWIHR,68410,AMMERSCHWIHR,LES TROIS EPIS,48.119478992,7.25998332176,5,,Ammerschwihr,Ammerschwihr,68,Haut-Rhin,44,Grand Est
68005,AMMERSCHWIHR,68770,AMMERSCHWIHR,,48.119478992,7.25998332176,5,,Ammerschwihr,Ammerschwihr,68,Haut-Rhin,44,Grand Est
68006,AMMERTZWILLER,68210,AMMERTZWILLER,,47.6926147527,7.16944522071,6,,Bernwiller,Bernwiller,68,Haut-Rhin,44,Grand Est
68007,ANDOLSHEIM,68280,ANDOLSHEIM,,48.0652206217,7.43654259206,7,,Andolsheim,Andolsheim,68,Haut-Rhin,44,Grand Est
68008,APPENWIHR,68280,APPENWIHR,,48.0252709837,7.45257417009,8,,Appenwihr,Appenwihr,68,Haut-Rhin,44,Grand Est
68009,ARTZENHEIM,68320,ARTZENHEIM,,48.1150139228,7.54242627283,9,,Artzenheim,Artzenheim,68,Haut-Rhin,44,Grand Est
68010,ASPACH,68130,ASPACH,,47.6424583561,7.23328777965,10,,Aspach,Aspach,68,Haut-Rhin,44,Grand Est
68011,ASPACH LE BAS,68700,ASPACH LE BAS,,47.7664940616,7.15106009452,11,,Aspach-le-Bas,Aspach-le-Bas,68,Haut-Rhin,44,Grand Est
68012,ASPACH MICHELBACH,68700,ASPACH MICHELBACH,,47.7791495008,7.12888464942,12,,Aspach-Michelbach,Aspach-Michelbach,68,Haut-Rhin,44,Grand Est
68012,ASPACH MICHELBACH,68700,ASPACH MICHELBACH,MICHELBACH,47.7791495008,7.12888464942,12,,Aspach-Michelbach,Aspach-Michelbach,68,Haut-Rhin,44,Grand Est
68013,ATTENSCHWILLER,68220,ATTENSCHWILLER,,47.5678328744,7.46912969155,13,,Attenschwiller,Attenschwiller,68,Haut-Rhin,44,Grand Est
68014,AUBURE,68150,AUBURE,,48.204266087,7.21127661313,14,,Aubure,Aubure,68,Haut-Rhin,44,Grand Est
68015,BALDERSHEIM,68390,BALDERSHEIM,,47.7990452233,7.41601212469,15,,Baldersheim,Baldersheim,68,Haut-Rhin,44,Grand Est
68016,BALGAU,68740,BALGAU,,47.9272368478,7.53211500774,16,,Balgau,Balgau,68,Haut-Rhin,44,Grand Est
68017,BALLERSDORF,68210,BALLERSDORF,,47.6201402497,7.15603615606,17,,Ballersdorf,Ballersdorf,68,Haut-Rhin,44,Grand Est
68018,BALSCHWILLER,68210,BALSCHWILLER,,47.6733286773,7.16339567268,18,,Balschwiller,Balschwiller,68,Haut-Rhin,44,Grand Est
68018,BALSCHWILLER,68210,BALSCHWILLER,UEBERKUMEN,47.6733286773,7.16339567268,18,,Balschwiller,Balschwiller,68,Haut-Rhin,44,Grand Est
68019,BALTZENHEIM,68320,BALTZENHEIM,,48.0987704391,7.55258087773,19,,Baltzenheim,Baltzenheim,68,Haut-Rhin,44,Grand Est
68020,BANTZENHEIM,68490,BANTZENHEIM,,47.8222082924,7.50289765703,20,,Bantzenheim,Bantzenheim,68,Haut-Rhin,44,Grand Est
68021,BARTENHEIM,68870,BARTENHEIM,,47.6362849044,7.4885344409,21,,Bartenheim,Bartenheim,68,Haut-Rhin,44,Grand Est
68021,BARTENHEIM,68870,BARTENHEIM,BARTENHEIM LA CHAUSSEE,47.6362849044,7.4885344409,21,,Bartenheim,Bartenheim,68,Haut-Rhin,44,Grand Est
68022,BATTENHEIM,68390,BATTENHEIM,,47.818845544,7.41535259558,22,,Battenheim,Battenheim,68,Haut-Rhin,44,Grand Est
68023,BEBLENHEIM,68980,BEBLENHEIM,,48.1591484173,7.34155964145,23,,Beblenheim,Beblenheim,68,Haut-Rhin,44,Grand Est
68024,BELLEMAGNY,68210,BELLEMAGNY,,47.6903963579,7.05935022603,24,,Bellemagny,Bellemagny,68,Haut-Rhin,44,Grand Est
68025,BENDORF,68480,BENDORF,,47.4845658206,7.2774077888,25,,Bendorf,Bendorf,68,Haut-Rhin,44,Grand Est
68026,BENNWIHR,68126,BENNWIHR MITTELWIHR,BENNWIHR GARE,48.1394469818,7.33981544547,26,,Bennwihr,Bennwihr,68,Haut-Rhin,44,Grand Est
68026,BENNWIHR,68630,BENNWIHR MITTELWIHR,,48.1394469818,7.33981544547,26,,Bennwihr,Bennwihr,68,Haut-Rhin,44,Grand Est
68027,BERENTZWILLER,68130,BERENTZWILLER,,47.5810125043,7.38725131168,27,,Berentzwiller,Berentzwiller,68,Haut-Rhin,44,Grand Est
68028,BERGHEIM,68750,BERGHEIM,,48.2125964008,7.35786517874,28,,Bergheim,Bergheim,68,Haut-Rhin,44,Grand Est
68029,BERGHOLTZ,68500,BERGHOLTZ,,47.9204515287,7.2473083241,29,,Bergholtz,Bergholtz,68,Haut-Rhin,44,Grand Est
68030,BERGHOLTZZELL,68500,BERGHOLTZZELL,,47.9270613981,7.22577933017,30,,Bergholtzzell,Bergholtzzell,68,Haut-Rhin,44,Grand Est
68031,BERNWILLER,68210,BERNWILLER,,47.7001534046,7.1910425266,31,,Bernwiller,Bernwiller,68,Haut-Rhin,44,Grand Est
68032,BERRWILLER,68500,BERRWILLER,,47.8459225933,7.21845369299,32,,Berrwiller,Berrwiller,68,Haut-Rhin,44,Grand Est
68033,BETTENDORF,68560,BETTENDORF,,47.5833339186,7.28390346765,33,,Bettendorf,Bettendorf,68,Haut-Rhin,44,Grand Est
68034,BETTLACH,68480,BETTLACH,,47.511555294,7.42254471079,34,,Bettlach,Bettlach,68,Haut-Rhin,44,Grand Est
68035,BIEDERTHAL,68480,BIEDERTHAL,,47.4709691234,7.43560956506,35,,Biederthal,Biederthal,68,Haut-Rhin,44,Grand Est
68036,BIESHEIM,68600,BIESHEIM,,48.0504323482,7.53680970912,36,,Biesheim,Biesheim,68,Haut-Rhin,44,Grand Est
68037,BILTZHEIM,68127,BILTZHEIM,,47.9624598517,7.36950245681,37,,Biltzheim,Biltzheim,68,Haut-Rhin,44,Grand Est
68038,BISCHWIHR,68320,BISCHWIHR,,48.0933356673,7.42793671425,38,,Bischwihr,Bischwihr,68,Haut-Rhin,44,Grand Est
68039,BISEL,68580,BISEL,,47.53847364,7.22083949332,39,,Bisel,Bisel,68,Haut-Rhin,44,Grand Est
68040,BITSCHWILLER LES THANN,68620,BITSCHWILLER LES THANN,,47.8268493029,7.06896787479,40,,Bitschwiller-lès-Thann,Bitschwiller-lès-Thann,68,Haut-Rhin,44,Grand Est
68041,BLODELSHEIM,68740,BLODELSHEIM,,47.8835902429,7.52612797828,41,,Blodelsheim,Blodelsheim,68,Haut-Rhin,44,Grand Est
68042,BLOTZHEIM,68730,BLOTZHEIM,,47.6041661694,7.49467481081,42,,Blotzheim,Blotzheim,68,Haut-Rhin,44,Grand Est
68043,BOLLWILLER,68540,BOLLWILLER,,47.854030178,7.25159464138,43,,Bollwiller,Bollwiller,68,Haut-Rhin,44,Grand Est
68044,LE BONHOMME,68650,LE BONHOMME,,48.1634421082,7.09862606883,44,Le,Bonhomme,Le Bonhomme,68,Haut-Rhin,44,Grand Est
68045,BOURBACH LE BAS,68290,BOURBACH LE BAS,,47.7727515199,7.05308714247,45,,Bourbach-le-Bas,Bourbach-le-Bas,68,Haut-Rhin,44,Grand Est
68046,BOURBACH LE HAUT,68290,BOURBACH LE HAUT,,47.7983331125,7.03017652946,46,,Bourbach-le-Haut,Bourbach-le-Haut,68,Haut-Rhin,44,Grand Est
68049,BOUXWILLER,68480,BOUXWILLER,,47.5041559244,7.34056015721,49,,Bouxwiller,Bouxwiller,68,Haut-Rhin,44,Grand Est
68050,BRECHAUMONT,68210,BRECHAUMONT,,47.6646087858,7.05590134955,50,,Bréchaumont,Bréchaumont,68,Haut-Rhin,44,Grand Est
68051,BREITENBACH HAUT RHIN,68380,BREITENBACH,,48.0110195796,7.10491597545,51,,Breitenbach-Haut-Rhin,Breitenbach-Haut-Rhin,68,Haut-Rhin,44,Grand Est
68052,BRETTEN,68780,BRETTEN,,47.7056292411,7.0643418397,52,,Bretten,Bretten,68,Haut-Rhin,44,Grand Est
68054,BRINCKHEIM,68870,BRINCKHEIM,,47.6210927169,7.45833574797,54,,Brinckheim,Brinckheim,68,Haut-Rhin,44,Grand Est
68055,BRUEBACH,68440,BRUEBACH,,47.700432424,7.35849105232,55,,Bruebach,Bruebach,68,Haut-Rhin,44,Grand Est
68056,BRUNSTATT DIDENHEIM,68350,BRUNSTATT DIDENHEIM,,47.7151300366,7.32654596124,56,,Brunstatt-Didenheim,Brunstatt-Didenheim,68,Haut-Rhin,44,Grand Est
68056,BRUNSTATT DIDENHEIM,68350,BRUNSTATT DIDENHEIM,DIDENHEIM,47.7151300366,7.32654596124,56,,Brunstatt-Didenheim,Brunstatt-Didenheim,68,Haut-Rhin,44,Grand Est
68057,BUETHWILLER,68210,BUETHWILLER,,47.6601180217,7.13595818077,57,,Buethwiller,Buethwiller,68,Haut-Rhin,44,Grand Est
68058,BUHL,68530,BUHL,,47.9243546086,7.17962229088,58,,Buhl,Buhl,68,Haut-Rhin,44,Grand Est
68059,BURNHAUPT LE BAS,68520,BURNHAUPT LE BAS,,47.7164544837,7.15440584349,59,,Burnhaupt-le-Bas,Burnhaupt-le-Bas,68,Haut-Rhin,44,Grand Est
68060,BURNHAUPT LE HAUT,68520,BURNHAUPT LE HAUT,,47.7343429793,7.12813648171,60,,Burnhaupt-le-Haut,Burnhaupt-le-Haut,68,Haut-Rhin,44,Grand Est
68061,BUSCHWILLER,68220,BUSCHWILLER,,47.5567551191,7.49825587796,61,,Buschwiller,Buschwiller,68,Haut-Rhin,44,Grand Est
68062,CARSPACH,68130,CARSPACH,,47.6224935398,7.19502984795,62,,Carspach,Carspach,68,Haut-Rhin,44,Grand Est
68063,CERNAY,68700,CERNAY,,47.7945078153,7.17932820409,63,,Cernay,Cernay,68,Haut-Rhin,44,Grand Est
68064,CHALAMPE,68490,CHALAMPE,,47.8321259921,7.54929893347,64,,Chalampé,Chalampé,68,Haut-Rhin,44,Grand Est
68065,CHAVANNES SUR L ETANG,68210,CHAVANNES SUR L ETANG,,47.6396468352,7.03103737458,65,,Chavannes-sur-l'Étang,Chavannes-sur-l'Étang,68,Haut-Rhin,44,Grand Est
68066,COLMAR,68000,COLMAR,,48.1099405789,7.38468690323,66,,Colmar,Colmar,68,Haut-Rhin,44,Grand Est
68067,COURTAVON,68480,COURTAVON,,47.4694045712,7.20007884538,67,,Courtavon,Courtavon,68,Haut-Rhin,44,Grand Est
68068,DANNEMARIE,68210,DANNEMARIE,,47.6251404599,7.12181938903,68,,Dannemarie,Dannemarie,68,Haut-Rhin,44,Grand Est
68069,DESSENHEIM,68600,DESSENHEIM,,47.9735592224,7.48193458989,69,,Dessenheim,Dessenheim,68,Haut-Rhin,44,Grand Est
68071,DIEFMATTEN,68780,DIEFMATTEN,,47.7060797698,7.10092606116,71,,Diefmatten,Diefmatten,68,Haut-Rhin,44,Grand Est
68072,DIETWILLER,68440,DIETWILLER,,47.7010678326,7.42599778593,72,,Dietwiller,Dietwiller,68,Haut-Rhin,44,Grand Est
68073,DOLLEREN,68290,DOLLEREN,,47.7961632915,6.91934613041,73,,Dolleren,Dolleren,68,Haut-Rhin,44,Grand Est
68074,DURLINSDORF,68480,DURLINSDORF,,47.4919501348,7.23379893851,74,,Durlinsdorf,Durlinsdorf,68,Haut-Rhin,44,Grand Est
68075,DURMENACH,68480,DURMENACH,,47.5262001607,7.33850450335,75,,Durmenach,Durmenach,68,Haut-Rhin,44,Grand Est
68076,DURRENENTZEN,68320,DURRENENTZEN,,48.0949092632,7.50887366008,76,,Durrenentzen,Durrenentzen,68,Haut-Rhin,44,Grand Est
68077,EGLINGEN,68720,EGLINGEN,,47.6565496554,7.18346024257,77,,Eglingen,Eglingen,68,Haut-Rhin,44,Grand Est
68078,EGUISHEIM,68420,EGUISHEIM,,48.0371183049,7.30052876111,78,,Eguisheim,Eguisheim,68,Haut-Rhin,44,Grand Est
68079,ELBACH,68210,ELBACH,,47.6424317944,7.06940948194,79,,Elbach,Elbach,68,Haut-Rhin,44,Grand Est
68080,EMLINGEN,68130,EMLINGEN,,47.627841475,7.29119379488,80,,Emlingen,Emlingen,68,Haut-Rhin,44,Grand Est
68081,ST BERNARD,68720,ST BERNARD,,47.665884637,7.20435846737,81,,Saint-Bernard,Saint-Bernard,68,Haut-Rhin,44,Grand Est
68081,ST BERNARD,68720,ST BERNARD,BRINIGHOFFEN,47.665884637,7.20435846737,81,,Saint-Bernard,Saint-Bernard,68,Haut-Rhin,44,Grand Est
68082,ENSISHEIM,68190,ENSISHEIM,,47.8530628657,7.37246514573,82,,Ensisheim,Ensisheim,68,Haut-Rhin,44,Grand Est
68083,ESCHBACH AU VAL,68140,ESCHBACH AU VAL,,48.0218694285,7.1465502338,83,,Eschbach-au-Val,Eschbach-au-Val,68,Haut-Rhin,44,Grand Est
68084,ESCHENTZWILLER,68440,ESCHENTZWILLER,,47.7097157075,7.39173125323,84,,Eschentzwiller,Eschentzwiller,68,Haut-Rhin,44,Grand Est
68085,ETEIMBES,68210,ETEIMBES,,47.7059397365,7.04282420421,85,,Eteimbes,Eteimbes,68,Haut-Rhin,44,Grand Est
68086,FALKWILLER,68210,FALKWILLER,,47.6808376844,7.1296631306,86,,Falkwiller,Falkwiller,68,Haut-Rhin,44,Grand Est
68087,FELDBACH,68640,FELDBACH,,47.5339340394,7.25619803671,87,,Feldbach,Feldbach,68,Haut-Rhin,44,Grand Est
68088,FELDKIRCH,68540,FELDKIRCH,,47.8564873154,7.27311359047,88,,Feldkirch,Feldkirch,68,Haut-Rhin,44,Grand Est
68089,FELLERING,68470,FELLERING,,47.9157234129,6.95863361019,89,,Fellering,Fellering,68,Haut-Rhin,44,Grand Est
68089,FELLERING,68470,FELLERING,WESSERLING,47.9157234129,6.95863361019,89,,Fellering,Fellering,68,Haut-Rhin,44,Grand Est
68090,FERRETTE,68480,FERRETTE,,47.4935597154,7.3158338091,90,,Ferrette,Ferrette,68,Haut-Rhin,44,Grand Est
68091,FESSENHEIM,68740,FESSENHEIM,,47.9099263761,7.53198557441,91,,Fessenheim,Fessenheim,68,Haut-Rhin,44,Grand Est
68092,FISLIS,68480,FISLIS,,47.5137490196,7.38210815031,92,,Fislis,Fislis,68,Haut-Rhin,44,Grand Est
68093,FLAXLANDEN,68720,FLAXLANDEN,,47.6920814143,7.32559027655,93,,Flaxlanden,Flaxlanden,68,Haut-Rhin,44,Grand Est
68094,FOLGENSBOURG,68220,FOLGENSBOURG,,47.5385121748,7.44187031126,94,,Folgensbourg,Folgensbourg,68,Haut-Rhin,44,Grand Est
68095,FORTSCHWIHR,68320,FORTSCHWIHR,,48.0854227132,7.4520020595,95,,Fortschwihr,Fortschwihr,68,Haut-Rhin,44,Grand Est
68096,FRANKEN,68130,FRANKEN,,47.5945824627,7.35367569573,96,,Franken,Franken,68,Haut-Rhin,44,Grand Est
68097,FRELAND,68240,FRELAND,,48.1836015307,7.18808543159,97,,Fréland,Fréland,68,Haut-Rhin,44,Grand Est
68098,FRIESEN,68580,FRIESEN,,47.5615367356,7.15172348131,98,,Friesen,Friesen,68,Haut-Rhin,44,Grand Est
68099,FROENINGEN,68720,FROENINGEN,,47.6951501354,7.25691494942,99,,Frœningen,Frœningen,68,Haut-Rhin,44,Grand Est
68100,FULLEREN,68210,FULLEREN,,47.5915166873,7.15765946964,100,,Fulleren,Fulleren,68,Haut-Rhin,44,Grand Est
68101,GALFINGUE,68990,GALFINGUE,,47.7038424637,7.22128198778,101,,Galfingue,Galfingue,68,Haut-Rhin,44,Grand Est
68102,GEISHOUSE,68690,GEISHOUSE,,47.8886591699,7.07169057683,102,,Geishouse,Geishouse,68,Haut-Rhin,44,Grand Est
68103,GEISPITZEN,68510,GEISPITZEN,,47.6738405843,7.43812251633,103,,Geispitzen,Geispitzen,68,Haut-Rhin,44,Grand Est
68104,GEISWASSER,68600,GEISWASSER,,47.9780603798,7.60554789815,104,,Geiswasser,Geiswasser,68,Haut-Rhin,44,Grand Est
68105,GILDWILLER,68210,GILDWILLER,,47.6967983198,7.14022952947,105,,Gildwiller,Gildwiller,68,Haut-Rhin,44,Grand Est
68106,GOLDBACH ALTENBACH,68760,GOLDBACH ALTENBACH,,47.8792538738,7.10493674734,106,,Goldbach-Altenbach,Goldbach-Altenbach,68,Haut-Rhin,44,Grand Est
68106,GOLDBACH ALTENBACH,68760,GOLDBACH ALTENBACH,ALTENBACH,47.8792538738,7.10493674734,106,,Goldbach-Altenbach,Goldbach-Altenbach,68,Haut-Rhin,44,Grand Est
68107,GOMMERSDORF,68210,GOMMERSDORF,,47.6413930168,7.13299368757,107,,Gommersdorf,Gommersdorf,68,Haut-Rhin,44,Grand Est
68109,GRIESBACH AU VAL,68140,GRIESBACH AU VAL,,48.0293037675,7.1719566301,109,,Griesbach-au-Val,Griesbach-au-Val,68,Haut-Rhin,44,Grand Est
68110,GRUSSENHEIM,68320,GRUSSENHEIM,,48.1464465778,7.49149736481,110,,Grussenheim,Grussenheim,68,Haut-Rhin,44,Grand Est
68111,GUEBERSCHWIHR,68420,GUEBERSCHWIHR,,48.0068225604,7.25975546236,111,,Gueberschwihr,Gueberschwihr,68,Haut-Rhin,44,Grand Est
68112,GUEBWILLER,68500,GUEBWILLER,,47.9096692207,7.21013500007,112,,Guebwiller,Guebwiller,68,Haut-Rhin,44,Grand Est
68113,GUEMAR,68970,GUEMAR,,48.1941685271,7.41475007829,113,,Guémar,Guémar,68,Haut-Rhin,44,Grand Est
68114,GUEVENATTEN,68210,GUEVENATTEN,,47.6827031934,7.08344025468,114,,Guevenatten,Guevenatten,68,Haut-Rhin,44,Grand Est
68115,GUEWENHEIM,68116,GUEWENHEIM,,47.7499350784,7.09127523458,115,,Guewenheim,Guewenheim,68,Haut-Rhin,44,Grand Est
68116,GUNDOLSHEIM,68250,GUNDOLSHEIM,,47.9283813908,7.29831896796,116,,Gundolsheim,Gundolsheim,68,Haut-Rhin,44,Grand Est
68117,GUNSBACH,68140,GUNSBACH,,48.0614208106,7.16506568008,117,,Gunsbach,Gunsbach,68,Haut-Rhin,44,Grand Est
68118,HABSHEIM,68440,HABSHEIM,,47.726261392,7.43344571206,118,,Habsheim,Habsheim,68,Haut-Rhin,44,Grand Est
68119,HAGENBACH,68210,HAGENBACH,,47.6457240743,7.16160529294,119,,Hagenbach,Hagenbach,68,Haut-Rhin,44,Grand Est
68120,HAGENTHAL LE BAS,68220,HAGENTHAL LE BAS,,47.5308980609,7.48185417828,120,,Hagenthal-le-Bas,Hagenthal-le-Bas,68,Haut-Rhin,44,Grand Est
68121,HAGENTHAL LE HAUT,68220,HAGENTHAL LE HAUT,,47.5192935701,7.45588624848,121,,Hagenthal-le-Haut,Hagenthal-le-Haut,68,Haut-Rhin,44,Grand Est
68122,HARTMANNSWILLER,68500,HARTMANNSWILLER,,47.8595424228,7.1992146478,122,,Hartmannswiller,Hartmannswiller,68,Haut-Rhin,44,Grand Est
68123,HATTSTATT,68420,HATTSTATT,,48.0147694216,7.27362142833,123,,Hattstatt,Hattstatt,68,Haut-Rhin,44,Grand Est
68124,HAUSGAUEN,68130,HAUSGAUEN,,47.6037839946,7.31760111595,124,,Hausgauen,Hausgauen,68,Haut-Rhin,44,Grand Est
68125,HECKEN,68210,HECKEN,,47.6935000401,7.11527633282,125,,Hecken,Hecken,68,Haut-Rhin,44,Grand Est
68126,HEGENHEIM,68220,HEGENHEIM,,47.5611004781,7.52631404841,126,,Hégenheim,Hégenheim,68,Haut-Rhin,44,Grand Est
68127,HEIDWILLER,68720,HEIDWILLER,,47.6568601238,7.23377297694,127,,Heidwiller,Heidwiller,68,Haut-Rhin,44,Grand Est
68128,HEIMERSDORF,68560,HEIMERSDORF,,47.563206505,7.23667862006,128,,Heimersdorf,Heimersdorf,68,Haut-Rhin,44,Grand Est
68129,HEIMSBRUNN,68990,HEIMSBRUNN,,47.7248268389,7.21972228432,129,,Heimsbrunn,Heimsbrunn,68,Haut-Rhin,44,Grand Est
68130,HEITEREN,68600,HEITEREN,,47.9616184488,7.54628533058,130,,Heiteren,Heiteren,68,Haut-Rhin,44,Grand Est
68131,HEIWILLER,68130,HEIWILLER,,47.6233403616,7.32325966254,131,,Heiwiller,Heiwiller,68,Haut-Rhin,44,Grand Est
68132,HELFRANTZKIRCH,68510,HELFRANTZKIRCH,,47.6042771391,7.41446012187,132,,Helfrantzkirch,Helfrantzkirch,68,Haut-Rhin,44,Grand Est
68134,HERRLISHEIM PRES COLMAR,68420,HERRLISHEIM PRES COLMAR,,48.0190918794,7.32531342644,134,,Herrlisheim-près-Colmar,Herrlisheim-près-Colmar,68,Haut-Rhin,44,Grand Est
68135,HESINGUE,68220,HESINGUE,,47.579283771,7.51544273738,135,,Hésingue,Hésingue,68,Haut-Rhin,44,Grand Est
68136,HETTENSCHLAG,68600,HETTENSCHLAG,,48.0076759842,7.456998109,136,,Hettenschlag,Hettenschlag,68,Haut-Rhin,44,Grand Est
68137,HINDLINGEN,68580,HINDLINGEN,,47.5703946445,7.14196837188,137,,Hindlingen,Hindlingen,68,Haut-Rhin,44,Grand Est
68138,HIRSINGUE,68560,HIRSINGUE,,47.5891425411,7.25204087522,138,,Hirsingue,Hirsingue,68,Haut-Rhin,44,Grand Est
68139,HIRTZBACH,68118,HIRTZBACH,,47.5906206548,7.20843110407,139,,Hirtzbach,Hirtzbach,68,Haut-Rhin,44,Grand Est
68140,HIRTZFELDEN,68740,HIRTZFELDEN,,47.9099477204,7.44697509823,140,,Hirtzfelden,Hirtzfelden,68,Haut-Rhin,44,Grand Est
68141,HOCHSTATT,68720,HOCHSTATT,,47.7093051072,7.26674678515,141,,Hochstatt,Hochstatt,68,Haut-Rhin,44,Grand Est
68142,HOHROD,68140,HOHROD,,48.063242328,7.13261807916,142,,Hohrod,Hohrod,68,Haut-Rhin,44,Grand Est
68143,PORTE DU RIED,68320,PORTE DU RIED,HOLTZWIHR,48.1164812033,7.41859914847,143,,Porte du Ried,Porte du Ried,68,Haut-Rhin,44,Grand Est
68143,PORTE DU RIED,68320,PORTE DU RIED,RIEDWIHR,48.1164812033,7.41859914847,143,,Porte du Ried,Porte du Ried,68,Haut-Rhin,44,Grand Est
68144,HOMBOURG,68490,HOMBOURG,,47.7566036089,7.49041917296,144,,Hombourg,Hombourg,68,Haut-Rhin,44,Grand Est
68145,HORBOURG WIHR,68180,HORBOURG WIHR,,48.08274524,7.40392986791,145,,Horbourg-Wihr,Horbourg-Wihr,68,Haut-Rhin,44,Grand Est
68145,HORBOURG WIHR,68180,HORBOURG WIHR,WIHR EN PLAINE,48.08274524,7.40392986791,145,,Horbourg-Wihr,Horbourg-Wihr,68,Haut-Rhin,44,Grand Est
68146,HOUSSEN,68125,HOUSSEN,,48.1315690762,7.37459864544,146,,Houssen,Houssen,68,Haut-Rhin,44,Grand Est
68147,HUNAWIHR,68150,HUNAWIHR,,48.1824285926,7.30403809307,147,,Hunawihr,Hunawihr,68,Haut-Rhin,44,Grand Est
68148,HUNDSBACH,68130,HUNDSBACH,,47.5983071682,7.3366232805,148,,Hundsbach,Hundsbach,68,Haut-Rhin,44,Grand Est
68149,HUNINGUE,68330,HUNINGUE,,47.5888753858,7.58124251727,149,,Huningue,Huningue,68,Haut-Rhin,44,Grand Est
68150,HUSSEREN LES CHATEAUX,68420,HUSSEREN LES CHATEAUX,,48.0364791705,7.27810251549,150,,Husseren-les-Châteaux,Husseren-les-Châteaux,68,Haut-Rhin,44,Grand Est
68151,HUSSEREN WESSERLING,68470,HUSSEREN WESSERLING,,47.8799580401,6.98624722904,151,,Husseren-Wesserling,Husseren-Wesserling,68,Haut-Rhin,44,Grand Est
68152,ILLFURTH,68720,ILLFURTH,,47.6759898554,7.26634202969,152,,Illfurth,Illfurth,68,Haut-Rhin,44,Grand Est
68153,ILLHAEUSERN,68970,ILLHAEUSERN,,48.1803388942,7.45176839753,153,,Illhaeusern,Illhaeusern,68,Haut-Rhin,44,Grand Est
68154,ILLZACH,68110,ILLZACH,,47.7734992,7.36011136903,154,,Illzach,Illzach,68,Haut-Rhin,44,Grand Est
68154,ILLZACH,68110,ILLZACH,MODENHEIM,47.7734992,7.36011136903,154,,Illzach,Illzach,68,Haut-Rhin,44,Grand Est
68155,INGERSHEIM,68040,INGERSHEIM,,48.1014572023,7.31053680908,155,,Ingersheim,Ingersheim,68,Haut-Rhin,44,Grand Est
68156,ISSENHEIM,68500,ISSENHEIM,,47.9036358846,7.25786972805,156,,Issenheim,Issenheim,68,Haut-Rhin,44,Grand Est
68157,JEBSHEIM,68320,JEBSHEIM,,48.1233748841,7.48080925093,157,,Jebsheim,Jebsheim,68,Haut-Rhin,44,Grand Est
68158,JETTINGEN,68130,JETTINGEN,,47.5961368894,7.37922389442,158,,Jettingen,Jettingen,68,Haut-Rhin,44,Grand Est
68159,JUNGHOLTZ,68500,JUNGHOLTZ,,47.8885904335,7.18875486257,159,,Jungholtz,Jungholtz,68,Haut-Rhin,44,Grand Est
68160,KAPPELEN,68510,KAPPELEN,,47.6138112548,7.44104846641,160,,Kappelen,Kappelen,68,Haut-Rhin,44,Grand Est
68161,KATZENTHAL,68230,KATZENTHAL,,48.1067132118,7.28187124195,161,,Katzenthal,Katzenthal,68,Haut-Rhin,44,Grand Est
68162,KAYSERSBERG VIGNOBLE,68240,KAYSERSBERG VIGNOBLE,,48.1550192582,7.23808768656,162,,Kaysersberg Vignoble,Kaysersberg Vignoble,68,Haut-Rhin,44,Grand Est
68162,KAYSERSBERG VIGNOBLE,68240,KAYSERSBERG VIGNOBLE,KIENTZHEIM,48.1550192582,7.23808768656,162,,Kaysersberg Vignoble,Kaysersberg Vignoble,68,Haut-Rhin,44,Grand Est
68162,KAYSERSBERG VIGNOBLE,68240,KAYSERSBERG VIGNOBLE,SIGOLSHEIM,48.1550192582,7.23808768656,162,,Kaysersberg Vignoble,Kaysersberg Vignoble,68,Haut-Rhin,44,Grand Est
68163,KEMBS,68680,KEMBS,,47.6793674876,7.50063130465,163,,Kembs,Kembs,68,Haut-Rhin,44,Grand Est
68163,KEMBS,68680,KEMBS,KEMBS LOECHLE,47.6793674876,7.50063130465,163,,Kembs,Kembs,68,Haut-Rhin,44,Grand Est
68165,KIFFIS,68480,KIFFIS,,47.4413533077,7.35699873313,165,,Kiffis,Kiffis,68,Haut-Rhin,44,Grand Est
68166,KINGERSHEIM,68260,KINGERSHEIM,,47.7889765168,7.32299335222,166,,Kingersheim,Kingersheim,68,Haut-Rhin,44,Grand Est
68167,KIRCHBERG,68290,KIRCHBERG,,47.7864018539,6.93996072996,167,,Kirchberg,Kirchberg,68,Haut-Rhin,44,Grand Est
68168,KNOERINGUE,68220,KNOERINGUE,,47.5631849343,7.40195763137,168,,Knœringue,Knœringue,68,Haut-Rhin,44,Grand Est
68169,KOESTLACH,68480,KOESTLACH,,47.5117644426,7.2711596241,169,,Kœstlach,Kœstlach,68,Haut-Rhin,44,Grand Est
68170,KOETZINGUE,68510,KOETZINGUE,,47.6485508091,7.39253593441,170,,Kœtzingue,Kœtzingue,68,Haut-Rhin,44,Grand Est
68171,KRUTH,68820,KRUTH,,47.9508833751,6.96310163389,171,,Kruth,Kruth,68,Haut-Rhin,44,Grand Est
68172,KUNHEIM,68320,KUNHEIM,,48.0770763292,7.54694950755,172,,Kunheim,Kunheim,68,Haut-Rhin,44,Grand Est
68173,LABAROCHE,68910,LABAROCHE,,48.1102898888,7.19554860246,173,,Labaroche,Labaroche,68,Haut-Rhin,44,Grand Est
68174,LANDSER,68440,LANDSER,,47.6869832437,7.3847569882,174,,Landser,Landser,68,Haut-Rhin,44,Grand Est
68175,LAPOUTROIE,68650,LAPOUTROIE,,48.1597717593,7.15907651893,175,,Lapoutroie,Lapoutroie,68,Haut-Rhin,44,Grand Est
68176,LARGITZEN,68580,LARGITZEN,,47.561066335,7.19235370252,176,,Largitzen,Largitzen,68,Haut-Rhin,44,Grand Est
68177,LAUTENBACH,68610,LAUTENBACH,,47.9527349295,7.16433010313,177,,Lautenbach,Lautenbach,68,Haut-Rhin,44,Grand Est
68177,LAUTENBACH,68610,LAUTENBACH,MARKSTEIN,47.9527349295,7.16433010313,177,,Lautenbach,Lautenbach,68,Haut-Rhin,44,Grand Est
68177,LAUTENBACH,68610,LAUTENBACH,SCHWEIGHOUSE,47.9527349295,7.16433010313,177,,Lautenbach,Lautenbach,68,Haut-Rhin,44,Grand Est
68178,LAUTENBACHZELL,68610,LAUTENBACHZELL,,47.9254894792,7.09657685658,178,,Lautenbachzell,Lautenbachzell,68,Haut-Rhin,44,Grand Est
68178,LAUTENBACHZELL,68610,LAUTENBACHZELL,SENGERN,47.9254894792,7.09657685658,178,,Lautenbachzell,Lautenbachzell,68,Haut-Rhin,44,Grand Est
68179,LAUW,68290,LAUW,,47.7620734612,7.02156776589,179,,Lauw,Lauw,68,Haut-Rhin,44,Grand Est
68180,LEIMBACH,68800,LEIMBACH,,47.792882847,7.09615660086,180,,Leimbach,Leimbach,68,Haut-Rhin,44,Grand Est
68181,LEVONCOURT,68480,LEVONCOURT,,47.4471429458,7.1934953379,181,,Levoncourt,Levoncourt,68,Haut-Rhin,44,Grand Est
68182,LEYMEN,68220,LEYMEN,,47.5003880526,7.48444885931,182,,Leymen,Leymen,68,Haut-Rhin,44,Grand Est
68183,LIEBENSWILLER,68220,LIEBENSWILLER,,47.5010138727,7.4504371497,183,,Liebenswiller,Liebenswiller,68,Haut-Rhin,44,Grand Est
68184,LIEBSDORF,68480,LIEBSDORF,,47.4789687972,7.2231472766,184,,Liebsdorf,Liebsdorf,68,Haut-Rhin,44,Grand Est
68185,LIEPVRE,68660,LIEPVRE,,48.2678392398,7.27599094644,185,,Lièpvre,Lièpvre,68,Haut-Rhin,44,Grand Est
68186,LIGSDORF,68480,LIGSDORF,,47.4667364645,7.30073437395,186,,Ligsdorf,Ligsdorf,68,Haut-Rhin,44,Grand Est
68187,LINSDORF,68480,LINSDORF,,47.5150509459,7.40642698338,187,,Linsdorf,Linsdorf,68,Haut-Rhin,44,Grand Est
68188,LINTHAL,68610,LINTHAL,,47.951973745,7.09406827161,188,,Linthal,Linthal,68,Haut-Rhin,44,Grand Est
68189,LOGELHEIM,68280,LOGELHEIM,,48.021228018,7.41870993146,189,,Logelheim,Logelheim,68,Haut-Rhin,44,Grand Est
68190,LUCELLE,68480,LUCELLE,,47.4379498341,7.27615283484,190,,Lucelle,Lucelle,68,Haut-Rhin,44,Grand Est
68191,LUEMSCHWILLER,68720,LUEMSCHWILLER,,47.6576144506,7.29703045483,191,,Luemschwiller,Luemschwiller,68,Haut-Rhin,44,Grand Est
68192,VALDIEU LUTRAN,68210,VALDIEU LUTRAN,,47.6265524313,7.05577643274,192,,Valdieu-Lutran,Valdieu-Lutran,68,Haut-Rhin,44,Grand Est
68192,VALDIEU LUTRAN,68210,VALDIEU LUTRAN,VALDIEU,47.6265524313,7.05577643274,192,,Valdieu-Lutran,Valdieu-Lutran,68,Haut-Rhin,44,Grand Est
68193,LUTTENBACH PRES MUNSTER,68140,LUTTENBACH,,48.0152724586,7.12389564467,193,,Luttenbach-près-Munster,Luttenbach-près-Munster,68,Haut-Rhin,44,Grand Est
68194,LUTTER,68480,LUTTER,,47.4578548286,7.37180651285,194,,Lutter,Lutter,68,Haut-Rhin,44,Grand Est
68195,LUTTERBACH,68460,LUTTERBACH,,47.7610968864,7.27261262553,195,,Lutterbach,Lutterbach,68,Haut-Rhin,44,Grand Est
68196,MAGNY,68210,MAGNY,,47.605758042,7.04676110392,196,,Magny,Magny,68,Haut-Rhin,44,Grand Est
68197,MAGSTATT LE BAS,68510,MAGSTATT LE BAS,,47.6388902604,7.41296468612,197,,Magstatt-le-Bas,Magstatt-le-Bas,68,Haut-Rhin,44,Grand Est
68198,MAGSTATT LE HAUT,68510,MAGSTATT LE HAUT,,47.6271826488,7.39456060175,198,,Magstatt-le-Haut,Magstatt-le-Haut,68,Haut-Rhin,44,Grand Est
68199,MALMERSPACH,68550,MALMERSPACH,,47.8597012807,7.0228099709,199,,Malmerspach,Malmerspach,68,Haut-Rhin,44,Grand Est
68200,MANSPACH,68210,MANSPACH,,47.6116259818,7.0925498257,200,,Manspach,Manspach,68,Haut-Rhin,44,Grand Est
68201,MASEVAUX NIEDERBRUCK,68290,MASEVAUX NIEDERBRUCK,,47.7778607514,6.99469023455,201,,Masevaux-Niederbruck,Masevaux-Niederbruck,68,Haut-Rhin,44,Grand Est
68201,MASEVAUX NIEDERBRUCK,68290,MASEVAUX NIEDERBRUCK,NIEDERBRUCK,47.7778607514,6.99469023455,201,,Masevaux-Niederbruck,Masevaux-Niederbruck,68,Haut-Rhin,44,Grand Est
68202,MERTZEN,68210,MERTZEN,,47.586907289,7.12919776559,202,,Mertzen,Mertzen,68,Haut-Rhin,44,Grand Est
68203,MERXHEIM,68500,MERXHEIM,,47.9093933635,7.30167546188,203,,Merxheim,Merxheim,68,Haut-Rhin,44,Grand Est
68204,METZERAL,68380,METZERAL,,47.9978920505,7.02528063902,204,,Metzeral,Metzeral,68,Haut-Rhin,44,Grand Est
68205,MEYENHEIM,68890,MEYENHEIM,,47.915595397,7.37703268493,205,,Meyenheim,Meyenheim,68,Haut-Rhin,44,Grand Est
68207,MICHELBACH LE BAS,68730,MICHELBACH LE BAS,,47.5936254129,7.46476536443,207,,Michelbach-le-Bas,Michelbach-le-Bas,68,Haut-Rhin,44,Grand Est
68208,MICHELBACH LE HAUT,68220,MICHELBACH LE HAUT,,47.5627694258,7.43780573745,208,,Michelbach-le-Haut,Michelbach-le-Haut,68,Haut-Rhin,44,Grand Est
68209,MITTELWIHR,68630,MITTELWIHR,,48.1506188794,7.31071233803,209,,Mittelwihr,Mittelwihr,68,Haut-Rhin,44,Grand Est
68210,MITTLACH,68380,MITTLACH,,47.9945265472,7.02013274266,210,,Mittlach,Mittlach,68,Haut-Rhin,44,Grand Est
68211,MITZACH,68470,MITZACH,,47.8611986942,6.99775039385,211,,Mitzach,Mitzach,68,Haut-Rhin,44,Grand Est
68212,MOERNACH,68480,MOERNACH,,47.5098929884,7.24557465368,212,,Mœrnach,Mœrnach,68,Haut-Rhin,44,Grand Est
68213,MOLLAU,68470,MOLLAU,,47.8586174298,6.96580213544,213,,Mollau,Mollau,68,Haut-Rhin,44,Grand Est
68214,MONTREUX JEUNE,68210,MONTREUX JEUNE,,47.6042120757,7.02432974787,214,,Montreux-Jeune,Montreux-Jeune,68,Haut-Rhin,44,Grand Est
68215,MONTREUX VIEUX,68210,MONTREUX VIEUX,,47.6201798388,7.02170889357,215,,Montreux-Vieux,Montreux-Vieux,68,Haut-Rhin,44,Grand Est
68216,MOOSLARGUE,68580,MOOSLARGUE,,47.5112217629,7.21307892037,216,,Mooslargue,Mooslargue,68,Haut-Rhin,44,Grand Est
68216,MOOSLARGUE,68580,MOOSLARGUE,NIEDERLARG,47.5112217629,7.21307892037,216,,Mooslargue,Mooslargue,68,Haut-Rhin,44,Grand Est
68217,MOOSCH,68690,MOOSCH,,47.8478706578,7.03007710689,217,,Moosch,Moosch,68,Haut-Rhin,44,Grand Est
68218,MORSCHWILLER LE BAS,68790,MORSCHWILLER LE BAS,,47.7341232071,7.26513847748,218,,Morschwiller-le-Bas,Morschwiller-le-Bas,68,Haut-Rhin,44,Grand Est
68219,LE HAUT SOULTZBACH,68780,LE HAUT SOULTZBACH,MORTZWILLER,47.7386585258,7.03380849733,219,Le,Haut Soultzbach,Le Haut Soultzbach,68,Haut-Rhin,44,Grand Est
68219,LE HAUT SOULTZBACH,68780,LE HAUT SOULTZBACH,SOPPE LE HAUT,47.7386585258,7.03380849733,219,Le,Haut Soultzbach,Le Haut Soultzbach,68,Haut-Rhin,44,Grand Est
68221,MUESPACH,68640,MUESPACH,,47.5496649857,7.37287618717,221,,Muespach,Muespach,68,Haut-Rhin,44,Grand Est
68221,MUESPACH,68640,MUESPACH,MOYEN MUESPACH,47.5496649857,7.37287618717,221,,Muespach,Muespach,68,Haut-Rhin,44,Grand Est
68222,MUESPACH LE HAUT,68640,MUESPACH LE HAUT,,47.5402727782,7.4094886662,222,,Muespach-le-Haut,Muespach-le-Haut,68,Haut-Rhin,44,Grand Est
68223,MUHLBACH SUR MUNSTER,68380,MUHLBACH SUR MUNSTER,,48.028840895,7.07080984321,223,,Muhlbach-sur-Munster,Muhlbach-sur-Munster,68,Haut-Rhin,44,Grand Est
68224,MULHOUSE,68100,MULHOUSE,,47.749163303,7.32570047509,224,,Mulhouse,Mulhouse,68,Haut-Rhin,44,Grand Est
68224,MULHOUSE,68200,MULHOUSE,,47.749163303,7.32570047509,224,,Mulhouse,Mulhouse,68,Haut-Rhin,44,Grand Est
68224,MULHOUSE,68200,MULHOUSE,BOURTZWILLER,47.749163303,7.32570047509,224,,Mulhouse,Mulhouse,68,Haut-Rhin,44,Grand Est
68224,MULHOUSE,68200,MULHOUSE,DORNACH,47.749163303,7.32570047509,224,,Mulhouse,Mulhouse,68,Haut-Rhin,44,Grand Est
68225,MUNCHHOUSE,68740,MUNCHHOUSE,,47.8570621759,7.4479017375,225,,Munchhouse,Munchhouse,68,Haut-Rhin,44,Grand Est
68226,MUNSTER,68140,MUNSTER,,48.0448514207,7.14149744282,226,,Munster,Munster,68,Haut-Rhin,44,Grand Est
68227,MUNTZENHEIM,68320,MUNTZENHEIM,,48.1008840089,7.46904401123,227,,Muntzenheim,Muntzenheim,68,Haut-Rhin,44,Grand Est
68228,MUNWILLER,68250,MUNWILLER,,47.9342627737,7.34739203519,228,,Munwiller,Munwiller,68,Haut-Rhin,44,Grand Est
68229,MURBACH,68530,MURBACH,,47.9168443337,7.13399961465,229,,Murbach,Murbach,68,Haut-Rhin,44,Grand Est
68230,NAMBSHEIM,68740,NAMBSHEIM,,47.9414821541,7.5652223292,230,,Nambsheim,Nambsheim,68,Haut-Rhin,44,Grand Est
68231,NEUF BRISACH,68600,NEUF BRISACH,,48.0183924755,7.52784741678,231,,Neuf-Brisach,Neuf-Brisach,68,Haut-Rhin,44,Grand Est
68232,NEUWILLER,68220,NEUWILLER,,47.5243158787,7.5127533777,232,,Neuwiller,Neuwiller,68,Haut-Rhin,44,Grand Est
68234,NIEDERENTZEN,68127,NIEDERENTZEN,,47.9454596765,7.39795037001,234,,Niederentzen,Niederentzen,68,Haut-Rhin,44,Grand Est
68235,NIEDERHERGHEIM,68127,NIEDERHERGHEIM,,47.9897040567,7.38918456659,235,,Niederhergheim,Niederhergheim,68,Haut-Rhin,44,Grand Est
68237,NIEDERMORSCHWIHR,68230,NIEDERMORSCHWIHR,,48.1007803247,7.25909420396,237,,Niedermorschwihr,Niedermorschwihr,68,Haut-Rhin,44,Grand Est
68238,NIFFER,68680,NIFFER,,47.7142446878,7.49844781169,238,,Niffer,Niffer,68,Haut-Rhin,44,Grand Est
68239,OBERBRUCK,68290,OBERBRUCK,,47.8191429574,6.92431221811,239,,Oberbruck,Oberbruck,68,Haut-Rhin,44,Grand Est
68240,ILLTAL,68960,ILLTAL,GRENTZINGEN,47.5598733177,7.31658096209,240,,Illtal,Illtal,68,Haut-Rhin,44,Grand Est
68240,ILLTAL,68960,ILLTAL,HENFLINGEN,47.5598733177,7.31658096209,240,,Illtal,Illtal,68,Haut-Rhin,44,Grand Est
68240,ILLTAL,68960,ILLTAL,OBERDORF,47.5598733177,7.31658096209,240,,Illtal,Illtal,68,Haut-Rhin,44,Grand Est
68241,OBERENTZEN,68127,OBERENTZEN,,47.9380454434,7.38091455268,241,,Oberentzen,Oberentzen,68,Haut-Rhin,44,Grand Est
68242,OBERHERGHEIM,68127,OBERHERGHEIM,,47.9692322968,7.40002707235,242,,Oberhergheim,Oberhergheim,68,Haut-Rhin,44,Grand Est
68243,OBERLARG,68480,OBERLARG,,47.4511026465,7.23130338788,243,,Oberlarg,Oberlarg,68,Haut-Rhin,44,Grand Est
68244,OBERMORSCHWIHR,68420,OBERMORSCHWIHR,,48.0229740954,7.29540852921,244,,Obermorschwihr,Obermorschwihr,68,Haut-Rhin,44,Grand Est
68245,OBERMORSCHWILLER,68130,OBERMORSCHWILLER,,47.6414713368,7.31641739319,245,,Obermorschwiller,Obermorschwiller,68,Haut-Rhin,44,Grand Est
68246,OBERSAASHEIM,68600,OBERSAASHEIM,,47.9861233303,7.55675214375,246,,Obersaasheim,Obersaasheim,68,Haut-Rhin,44,Grand Est
68247,ODEREN,68830,ODEREN,,47.9289257101,6.99426460048,247,,Oderen,Oderen,68,Haut-Rhin,44,Grand Est
68248,OLTINGUE,68480,OLTINGUE,,47.4891564438,7.40057781922,248,,Oltingue,Oltingue,68,Haut-Rhin,44,Grand Est
68249,ORBEY,68370,ORBEY,,48.1149528963,7.13503444718,249,,Orbey,Orbey,68,Haut-Rhin,44,Grand Est
68250,ORSCHWIHR,68500,ORSCHWIHR,,47.9378114718,7.22902845686,250,,Orschwihr,Orschwihr,68,Haut-Rhin,44,Grand Est
68251,OSENBACH,68570,OSENBACH,,47.9929199138,7.21660441722,251,,Osenbach,Osenbach,68,Haut-Rhin,44,Grand Est
68252,OSTHEIM,68150,OSTHEIM,,48.157155989,7.37273761525,252,,Ostheim,Ostheim,68,Haut-Rhin,44,Grand Est
68253,OTTMARSHEIM,68490,OTTMARSHEIM,,47.7858970322,7.49270791037,253,,Ottmarsheim,Ottmarsheim,68,Haut-Rhin,44,Grand Est
68254,PETIT LANDAU,68490,PETIT LANDAU,,47.7349039137,7.50750240777,254,,Petit-Landau,Petit-Landau,68,Haut-Rhin,44,Grand Est
68255,PFAFFENHEIM,68250,PFAFFENHEIM,,47.9957150402,7.25987167223,255,,Pfaffenheim,Pfaffenheim,68,Haut-Rhin,44,Grand Est
68256,PFASTATT,68120,PFASTATT,,47.7696767881,7.29485630306,256,,Pfastatt,Pfastatt,68,Haut-Rhin,44,Grand Est
68257,PFETTERHOUSE,68480,PFETTERHOUSE,,47.506686131,7.16823330248,257,,Pfetterhouse,Pfetterhouse,68,Haut-Rhin,44,Grand Est
68258,PULVERSHEIM,68840,PULVERSHEIM,,47.8417545163,7.29938600329,258,,Pulversheim,Pulversheim,68,Haut-Rhin,44,Grand Est
68259,RAEDERSDORF,68480,RAEDERSDORF,,47.471221085,7.35426920399,259,,Raedersdorf,Raedersdorf,68,Haut-Rhin,44,Grand Est
68260,RAEDERSHEIM,68190,RAEDERSHEIM,,47.8850080179,7.27686829519,260,,Raedersheim,Raedersheim,68,Haut-Rhin,44,Grand Est
68261,RAMMERSMATT,68800,RAMMERSMATT,,47.7943948271,7.05982977679,261,,Rammersmatt,Rammersmatt,68,Haut-Rhin,44,Grand Est
68262,RANSPACH,68470,RANSPACH,,47.9046781432,7.02202271046,262,,Ranspach,Ranspach,68,Haut-Rhin,44,Grand Est
68263,RANSPACH LE BAS,68730,RANSPACH LE BAS,,47.5873905973,7.44587984681,263,,Ranspach-le-Bas,Ranspach-le-Bas,68,Haut-Rhin,44,Grand Est
68264,RANSPACH LE HAUT,68220,RANSPACH LE HAUT,,47.5802864143,7.42000987982,264,,Ranspach-le-Haut,Ranspach-le-Haut,68,Haut-Rhin,44,Grand Est
68265,RANTZWILLER,68510,RANTZWILLER,,47.6488310607,7.37119412138,265,,Rantzwiller,Rantzwiller,68,Haut-Rhin,44,Grand Est
68266,REGUISHEIM,68890,REGUISHEIM,,47.891760126,7.37115549029,266,,Réguisheim,Réguisheim,68,Haut-Rhin,44,Grand Est
68267,REININGUE,68950,REININGUE,,47.7554724889,7.22032709078,267,,Reiningue,Reiningue,68,Haut-Rhin,44,Grand Est
68268,RETZWILLER,68210,RETZWILLER,,47.6292733626,7.08760761112,268,,Retzwiller,Retzwiller,68,Haut-Rhin,44,Grand Est
68269,RIBEAUVILLE,68150,RIBEAUVILLE,,48.2065139236,7.28672468283,269,,Ribeauvillé,Ribeauvillé,68,Haut-Rhin,44,Grand Est
68270,RICHWILLER,68120,RICHWILLER,,47.7845885966,7.28219452046,270,,Richwiller,Richwiller,68,Haut-Rhin,44,Grand Est
68271,RIEDISHEIM,68400,RIEDISHEIM,,47.7396931501,7.36582056128,271,,Riedisheim,Riedisheim,68,Haut-Rhin,44,Grand Est
68273,RIESPACH,68640,RIESPACH,,47.5364456332,7.28849736143,273,,Riespach,Riespach,68,Haut-Rhin,44,Grand Est
68274,RIMBACH PRES GUEBWILLER,68500,RIMBACH PRES GUEBWILLER,,47.9044113934,7.15327700335,274,,Rimbach-près-Guebwiller,Rimbach-près-Guebwiller,68,Haut-Rhin,44,Grand Est
68275,RIMBACH PRES MASEVAUX,68290,RIMBACH PRES MASEVAUX,,47.8345267818,6.94187078441,275,,Rimbach-près-Masevaux,Rimbach-près-Masevaux,68,Haut-Rhin,44,Grand Est
68276,RIMBACHZELL,68500,RIMBACHZELL,,47.8979516563,7.17356848558,276,,Rimbachzell,Rimbachzell,68,Haut-Rhin,44,Grand Est
68277,RIQUEWIHR,68340,RIQUEWIHR,,48.1787804995,7.27259187393,277,,Riquewihr,Riquewihr,68,Haut-Rhin,44,Grand Est
68278,RIXHEIM,68170,RIXHEIM,,47.7495819243,7.4072406005,278,,Rixheim,Rixheim,68,Haut-Rhin,44,Grand Est
68279,RODEREN,68800,RODEREN,,47.7778631956,7.08771577439,279,,Roderen,Roderen,68,Haut-Rhin,44,Grand Est
68280,RODERN,68590,RODERN,,48.237574913,7.31340839704,280,,Rodern,Rodern,68,Haut-Rhin,44,Grand Est
68281,ROGGENHOUSE,68740,ROGGENHOUSE,,47.8849153208,7.47508030097,281,,Roggenhouse,Roggenhouse,68,Haut-Rhin,44,Grand Est
68282,ROMAGNY,68210,ROMAGNY,,47.6112014535,7.06778027681,282,,Romagny,Romagny,68,Haut-Rhin,44,Grand Est
68283,ROMBACH LE FRANC,68660,ROMBACH LE FRANC,,48.2933550805,7.24348639747,283,,Rombach-le-Franc,Rombach-le-Franc,68,Haut-Rhin,44,Grand Est
68284,ROPPENTZWILLER,68480,ROPPENTZWILLER,,47.5373715057,7.32986002204,284,,Roppentzwiller,Roppentzwiller,68,Haut-Rhin,44,Grand Est
68285,RORSCHWIHR,68590,RORSCHWIHR,,48.219003777,7.36549943321,285,,Rorschwihr,Rorschwihr,68,Haut-Rhin,44,Grand Est
68286,ROSENAU,68128,ROSENAU,,47.6388184393,7.54215519701,286,,Rosenau,Rosenau,68,Haut-Rhin,44,Grand Est
68287,ROUFFACH,68250,ROUFFACH,,47.9688422434,7.27338874141,287,,Rouffach,Rouffach,68,Haut-Rhin,44,Grand Est
68288,RUEDERBACH,68560,RUEDERBACH,,47.5556783925,7.25778215672,288,,Ruederbach,Ruederbach,68,Haut-Rhin,44,Grand Est
68289,RUELISHEIM,68270,RUELISHEIM,,47.8223744559,7.35273202444,289,,Ruelisheim,Ruelisheim,68,Haut-Rhin,44,Grand Est
68290,RUSTENHART,68740,RUSTENHART,,47.9412962955,7.47468006803,290,,Rustenhart,Rustenhart,68,Haut-Rhin,44,Grand Est
68291,RUMERSHEIM LE HAUT,68740,RUMERSHEIM LE HAUT,,47.8531681217,7.51760806653,291,,Rumersheim-le-Haut,Rumersheim-le-Haut,68,Haut-Rhin,44,Grand Est
68292,ST AMARIN,68550,ST AMARIN,,47.887678929,7.04124424443,292,,Saint-Amarin,Saint-Amarin,68,Haut-Rhin,44,Grand Est
68293,ST COSME,68210,ST COSME,,47.680928319,7.0534744668,293,,Saint-Cosme,Saint-Cosme,68,Haut-Rhin,44,Grand Est
68294,STE CROIX AUX MINES,68160,STE CROIX AUX MINES,,48.2683101517,7.20845022023,294,,Sainte-Croix-aux-Mines,Sainte-Croix-aux-Mines,68,Haut-Rhin,44,Grand Est
68295,STE CROIX EN PLAINE,68127,STE CROIX EN PLAINE,,48.0108373364,7.39448245044,295,,Sainte-Croix-en-Plaine,Sainte-Croix-en-Plaine,68,Haut-Rhin,44,Grand Est
68296,ST HIPPOLYTE,68590,ST HIPPOLYTE,,48.2386537498,7.34591217,296,,Saint-Hippolyte,Saint-Hippolyte,68,Haut-Rhin,44,Grand Est
68297,ST LOUIS,68300,ST LOUIS,,47.6016553367,7.54061872745,297,,Saint-Louis,Saint-Louis,68,Haut-Rhin,44,Grand Est
68297,ST LOUIS,68300,ST LOUIS,BOURGFELDEN,47.6016553367,7.54061872745,297,,Saint-Louis,Saint-Louis,68,Haut-Rhin,44,Grand Est
68297,ST LOUIS,68300,ST LOUIS,ST LOUIS LA CHAUSSEE,47.6016553367,7.54061872745,297,,Saint-Louis,Saint-Louis,68,Haut-Rhin,44,Grand Est
68298,STE MARIE AUX MINES,68160,STE MARIE AUX MINES,,48.2285762223,7.16926143657,298,,Sainte-Marie-aux-Mines,Sainte-Marie-aux-Mines,68,Haut-Rhin,44,Grand Est
68298,STE MARIE AUX MINES,68160,STE MARIE AUX MINES,ECHERY,48.2285762223,7.16926143657,298,,Sainte-Marie-aux-Mines,Sainte-Marie-aux-Mines,68,Haut-Rhin,44,Grand Est
68299,ST ULRICH,68210,ST ULRICH,,47.5906419624,7.11252849143,299,,Saint-Ulrich,Saint-Ulrich,68,Haut-Rhin,44,Grand Est
68300,SAUSHEIM,68390,SAUSHEIM,,47.7824980107,7.40065794746,300,,Sausheim,Sausheim,68,Haut-Rhin,44,Grand Est
68301,SCHLIERBACH,68440,SCHLIERBACH,,47.6843290932,7.43002796283,301,,Schlierbach,Schlierbach,68,Haut-Rhin,44,Grand Est
68302,SCHWEIGHOUSE THANN,68520,SCHWEIGHOUSE THANN,,47.7488667079,7.17549476358,302,,Schweighouse-Thann,Schweighouse-Thann,68,Haut-Rhin,44,Grand Est
68303,SCHWOBEN,68130,SCHWOBEN,,47.6075218956,7.29884312664,303,,Schwoben,Schwoben,68,Haut-Rhin,44,Grand Est
68304,SENTHEIM,68780,SENTHEIM,,47.7544037886,7.05013965143,304,,Sentheim,Sentheim,68,Haut-Rhin,44,Grand Est
68305,SEPPOIS LE BAS,68580,SEPPOIS LE BAS,,47.5376133947,7.16814388588,305,,Seppois-le-Bas,Seppois-le-Bas,68,Haut-Rhin,44,Grand Est
68306,SEPPOIS LE HAUT,68580,SEPPOIS LE HAUT,,47.5306368424,7.189321425,306,,Seppois-le-Haut,Seppois-le-Haut,68,Haut-Rhin,44,Grand Est
68307,SEWEN,68290,SEWEN,,47.8075577891,6.88027043152,307,,Sewen,Sewen,68,Haut-Rhin,44,Grand Est
68308,SICKERT,68290,SICKERT,,47.7919119213,6.98580589371,308,,Sickert,Sickert,68,Haut-Rhin,44,Grand Est
68309,SIERENTZ,68510,SIERENTZ,,47.6620198901,7.46670295254,309,,Sierentz,Sierentz,68,Haut-Rhin,44,Grand Est
68311,SONDERNACH,68380,SONDERNACH,,47.9778345263,7.07407441215,311,,Sondernach,Sondernach,68,Haut-Rhin,44,Grand Est
68312,SONDERSDORF,68480,SONDERSDORF,,47.4773648942,7.33375593304,312,,Sondersdorf,Sondersdorf,68,Haut-Rhin,44,Grand Est
68313,SOPPE LE BAS,68780,SOPPE LE BAS,,47.7187563607,7.09111895183,313,,Soppe-le-Bas,Soppe-le-Bas,68,Haut-Rhin,44,Grand Est
68315,SOULTZ HAUT RHIN,68360,SOULTZ HAUT RHIN,,47.8827772059,7.17897878271,315,,Soultz-Haut-Rhin,Soultz-Haut-Rhin,68,Haut-Rhin,44,Grand Est
68316,SOULTZBACH LES BAINS,68230,SOULTZBACH LES BAINS,,48.0284078615,7.20442898654,316,,Soultzbach-les-Bains,Soultzbach-les-Bains,68,Haut-Rhin,44,Grand Est
68317,SOULTZEREN,68140,SOULTZEREN,,48.0828918874,7.09256896947,317,,Soultzeren,Soultzeren,68,Haut-Rhin,44,Grand Est
68318,SOULTZMATT,68570,SOULTZMATT,,47.9701032152,7.21175482373,318,,Soultzmatt,Soultzmatt,68,Haut-Rhin,44,Grand Est
68318,SOULTZMATT,68570,SOULTZMATT,WINTZFELDEN,47.9701032152,7.21175482373,318,,Soultzmatt,Soultzmatt,68,Haut-Rhin,44,Grand Est
68320,SPECHBACH,68720,SPECHBACH,,47.6858659395,7.21898279499,320,,Spechbach,Spechbach,68,Haut-Rhin,44,Grand Est
68320,SPECHBACH,68720,SPECHBACH,SPECHBACH LE BAS,47.6858659395,7.21898279499,320,,Spechbach,Spechbach,68,Haut-Rhin,44,Grand Est
68321,STAFFELFELDEN,68850,STAFFELFELDEN,,47.82743014,7.25320289915,321,,Staffelfelden,Staffelfelden,68,Haut-Rhin,44,Grand Est
68322,STEINBACH,68700,STEINBACH,,47.8218078225,7.13920916637,322,,Steinbach,Steinbach,68,Haut-Rhin,44,Grand Est
68323,STEINBRUNN LE BAS,68440,STEINBRUNN LE BAS,,47.6760725505,7.36407535682,323,,Steinbrunn-le-Bas,Steinbrunn-le-Bas,68,Haut-Rhin,44,Grand Est
68324,STEINBRUNN LE HAUT,68440,STEINBRUNN LE HAUT,,47.6599109756,7.34109225666,324,,Steinbrunn-le-Haut,Steinbrunn-le-Haut,68,Haut-Rhin,44,Grand Est
68325,STEINSOULTZ,68640,STEINSOULTZ,,47.5583266561,7.34658107092,325,,Steinsoultz,Steinsoultz,68,Haut-Rhin,44,Grand Est
68326,STERNENBERG,68780,STERNENBERG,,47.6949071937,7.09088157788,326,,Sternenberg,Sternenberg,68,Haut-Rhin,44,Grand Est
68327,STETTEN,68510,STETTEN,,47.6236636155,7.41905540349,327,,Stetten,Stetten,68,Haut-Rhin,44,Grand Est
68328,STORCKENSOHN,68470,STORCKENSOHN,,47.8640611767,6.94243466248,328,,Storckensohn,Storckensohn,68,Haut-Rhin,44,Grand Est
68329,STOSSWIHR,68140,STOSSWIHR,,48.0552314981,7.06088975747,329,,Stosswihr,Stosswihr,68,Haut-Rhin,44,Grand Est
68330,STRUETH,68580,STRUETH,,47.5748861351,7.11500184209,330,,Strueth,Strueth,68,Haut-Rhin,44,Grand Est
68331,SUNDHOFFEN,68280,SUNDHOFFEN,,48.0428411663,7.42194556181,331,,Sundhoffen,Sundhoffen,68,Haut-Rhin,44,Grand Est
68332,TAGOLSHEIM,68720,TAGOLSHEIM,,47.6548634844,7.26381080113,332,,Tagolsheim,Tagolsheim,68,Haut-Rhin,44,Grand Est
68333,TAGSDORF,68130,TAGSDORF,,47.6202385096,7.30048484717,333,,Tagsdorf,Tagsdorf,68,Haut-Rhin,44,Grand Est
68334,THANN,68800,THANN,,47.8139111281,7.09502457233,334,,Thann,Thann,68,Haut-Rhin,44,Grand Est
68335,THANNENKIRCH,68590,THANNENKIRCH,,48.2332519272,7.29366733491,335,,Thannenkirch,Thannenkirch,68,Haut-Rhin,44,Grand Est
68336,TRAUBACH LE BAS,68210,TRAUBACH LE BAS,,47.655039001,7.0935498603,336,,Traubach-le-Bas,Traubach-le-Bas,68,Haut-Rhin,44,Grand Est
68337,TRAUBACH LE HAUT,68210,TRAUBACH LE HAUT,,47.6706097792,7.09565668471,337,,Traubach-le-Haut,Traubach-le-Haut,68,Haut-Rhin,44,Grand Est
68338,TURCKHEIM,68230,TURCKHEIM,,48.0865424063,7.25076356699,338,,Turckheim,Turckheim,68,Haut-Rhin,44,Grand Est
68340,UEBERSTRASS,68580,UEBERSTRASS,,47.5461126358,7.15301367289,340,,Ueberstrass,Ueberstrass,68,Haut-Rhin,44,Grand Est
68341,UFFHEIM,68510,UFFHEIM,,47.6442489721,7.43802014569,341,,Uffheim,Uffheim,68,Haut-Rhin,44,Grand Est
68342,UFFHOLTZ,68700,UFFHOLTZ,,47.829286972,7.16505941718,342,,Uffholtz,Uffholtz,68,Haut-Rhin,44,Grand Est
68343,UNGERSHEIM,68190,UNGERSHEIM,,47.8739838916,7.30532668717,343,,Ungersheim,Ungersheim,68,Haut-Rhin,44,Grand Est
68344,URBES,68121,URBES,,47.8802074294,6.92848396998,344,,Urbès,Urbès,68,Haut-Rhin,44,Grand Est
68345,URSCHENHEIM,68320,URSCHENHEIM,,48.0805859963,7.49120640493,345,,Urschenheim,Urschenheim,68,Haut-Rhin,44,Grand Est
68347,VIEUX FERRETTE,68480,VIEUX FERRETTE,,47.5090177967,7.30400844003,347,,Vieux-Ferrette,Vieux-Ferrette,68,Haut-Rhin,44,Grand Est
68348,VIEUX THANN,68800,VIEUX THANN,,47.8045241371,7.12730997069,348,,Vieux-Thann,Vieux-Thann,68,Haut-Rhin,44,Grand Est
68349,VILLAGE NEUF,68128,VILLAGE NEUF,,47.6099200713,7.56836021504,349,,Village-Neuf,Village-Neuf,68,Haut-Rhin,44,Grand Est
68350,VOEGTLINSHOFFEN,68420,VOEGTLINSHOFFEN,,48.0241428176,7.26224403842,350,,Vœgtlinshoffen,Vœgtlinshoffen,68,Haut-Rhin,44,Grand Est
68351,VOGELGRUN,68600,VOGELGRUN,,48.0108661539,7.58453738137,351,,Vogelgrun,Vogelgrun,68,Haut-Rhin,44,Grand Est
68352,VOLGELSHEIM,68600,VOLGELSHEIM,,48.0238611459,7.54413370142,352,,Volgelsheim,Volgelsheim,68,Haut-Rhin,44,Grand Est
68353,WAHLBACH,68130,WAHLBACH,,47.6297810488,7.34645616735,353,,Wahlbach,Wahlbach,68,Haut-Rhin,44,Grand Est
68354,WALBACH,68230,WALBACH,,48.0704719047,7.20730643508,354,,Walbach,Walbach,68,Haut-Rhin,44,Grand Est
68355,WALDIGHOFEN,68640,WALDIGHOFEN,,47.5469824081,7.3170216886,355,,Waldighofen,Waldighofen,68,Haut-Rhin,44,Grand Est
68356,WALHEIM,68130,WALHEIM,,47.6407985178,7.27039450439,356,,Walheim,Walheim,68,Haut-Rhin,44,Grand Est
68357,WALTENHEIM,68510,WALTENHEIM,,47.6556165524,7.42289070727,357,,Waltenheim,Waltenheim,68,Haut-Rhin,44,Grand Est
68358,WASSERBOURG,68230,WASSERBOURG,,47.9974106564,7.1537562966,358,,Wasserbourg,Wasserbourg,68,Haut-Rhin,44,Grand Est
68359,WATTWILLER,68700,WATTWILLER,,47.8421539165,7.17527188812,359,,Wattwiller,Wattwiller,68,Haut-Rhin,44,Grand Est
68360,WECKOLSHEIM,68600,WECKOLSHEIM,,48.0035606232,7.49800748783,360,,Weckolsheim,Weckolsheim,68,Haut-Rhin,44,Grand Est
68361,WEGSCHEID,68290,WEGSCHEID,,47.8152049638,6.97607709071,361,,Wegscheid,Wegscheid,68,Haut-Rhin,44,Grand Est
68362,WENTZWILLER,68220,WENTZWILLER,,47.5482319467,7.4737014861,362,,Wentzwiller,Wentzwiller,68,Haut-Rhin,44,Grand Est
68363,WERENTZHOUSE,68480,WERENTZHOUSE,,47.5212216996,7.36266032447,363,,Werentzhouse,Werentzhouse,68,Haut-Rhin,44,Grand Est
68364,WESTHALTEN,68250,WESTHALTEN,,47.9673816891,7.25470437901,364,,Westhalten,Westhalten,68,Haut-Rhin,44,Grand Est
68365,WETTOLSHEIM,68920,WETTOLSHEIM,,48.0520194892,7.30680140883,365,,Wettolsheim,Wettolsheim,68,Haut-Rhin,44,Grand Est
68366,WICKERSCHWIHR,68320,WICKERSCHWIHR,,48.1109299734,7.4394034579,366,,Wickerschwihr,Wickerschwihr,68,Haut-Rhin,44,Grand Est
68367,WIDENSOLEN,68320,WIDENSOLEN,,48.0579869802,7.48257799635,367,,Widensolen,Widensolen,68,Haut-Rhin,44,Grand Est
68368,WIHR AU VAL,68230,WIHR AU VAL,,48.0553731067,7.19720465805,368,,Wihr-au-Val,Wihr-au-Val,68,Haut-Rhin,44,Grand Est
68370,WILDENSTEIN,68820,WILDENSTEIN,,47.9878413195,6.96483383712,370,,Wildenstein,Wildenstein,68,Haut-Rhin,44,Grand Est
68371,WILLER,68960,WILLER,,47.5795307202,7.32499387503,371,,Willer,Willer,68,Haut-Rhin,44,Grand Est
68372,WILLER SUR THUR,68760,WILLER SUR THUR,,47.8518062106,7.0803395975,372,,Willer-sur-Thur,Willer-sur-Thur,68,Haut-Rhin,44,Grand Est
68373,WINKEL,68480,WINKEL,,47.4588691493,7.26354708265,373,,Winkel,Winkel,68,Haut-Rhin,44,Grand Est
68374,WINTZENHEIM,68124,WINTZENHEIM,LOGELBACH,48.0635575339,7.27168140769,374,,Wintzenheim,Wintzenheim,68,Haut-Rhin,44,Grand Est
68374,WINTZENHEIM,68920,WINTZENHEIM,,48.0635575339,7.27168140769,374,,Wintzenheim,Wintzenheim,68,Haut-Rhin,44,Grand Est
68375,WITTELSHEIM,68310,WITTELSHEIM,,47.7987366521,7.24011099826,375,,Wittelsheim,Wittelsheim,68,Haut-Rhin,44,Grand Est
68376,WITTENHEIM,68270,WITTENHEIM,,47.8102988155,7.31251407267,376,,Wittenheim,Wittenheim,68,Haut-Rhin,44,Grand Est
68377,WITTERSDORF,68130,WITTERSDORF,,47.6161807189,7.27312864168,377,,Wittersdorf,Wittersdorf,68,Haut-Rhin,44,Grand Est
68378,WOLFERSDORF,68210,WOLFERSDORF,,47.6430195312,7.10646234685,378,,Wolfersdorf,Wolfersdorf,68,Haut-Rhin,44,Grand Est
68379,WOLFGANTZEN,68600,WOLFGANTZEN,,48.0282556276,7.49703519039,379,,Wolfgantzen,Wolfgantzen,68,Haut-Rhin,44,Grand Est
68380,WOLSCHWILLER,68480,WOLSCHWILLER,,47.4562913245,7.40696039971,380,,Wolschwiller,Wolschwiller,68,Haut-Rhin,44,Grand Est
68381,WUENHEIM,68500,WUENHEIM,,47.8689114622,7.18554578054,381,,Wuenheim,Wuenheim,68,Haut-Rhin,44,Grand Est
68382,ZAESSINGUE,68130,ZAESSINGUE,,47.6197053459,7.37256538622,382,,Zaessingue,Zaessingue,68,Haut-Rhin,44,Grand Est
68383,ZELLENBERG,68340,ZELLENBERG,,48.1735139596,7.34011573543,383,,Zellenberg,Zellenberg,68,Haut-Rhin,44,Grand Est
68384,ZILLISHEIM,68720,ZILLISHEIM,,47.6858083083,7.30319308149,384,,Zillisheim,Zillisheim,68,Haut-Rhin,44,Grand Est
68385,ZIMMERBACH,68230,ZIMMERBACH,,48.0745599556,7.23005041952,385,,Zimmerbach,Zimmerbach,68,Haut-Rhin,44,Grand Est
68386,ZIMMERSHEIM,68440,ZIMMERSHEIM,,47.7187838073,7.38181973945,386,,Zimmersheim,Zimmersheim,68,Haut-Rhin,44,Grand Est
69001,AFFOUX,69170,AFFOUX,,45.8440645304,4.41392129755,1,,Affoux,Affoux,69,Rhône,84,Auvergne-Rhône-Alpes
69002,AIGUEPERSE,69790,AIGUEPERSE,,46.2801268832,4.42330722719,2,,Aigueperse,Aigueperse,69,Rhône,84,Auvergne-Rhône-Alpes
69003,ALBIGNY SUR SAONE,69250,ALBIGNY SUR SAONE,,45.8642146878,4.83076882864,3,,Albigny-sur-Saône,Albigny-sur-Saône,69,Rhône,84,Auvergne-Rhône-Alpes
69004,ALIX,69380,ALIX,,45.9168253291,4.65500393132,4,,Alix,Alix,69,Rhône,84,Auvergne-Rhône-Alpes
69005,AMBERIEUX,69480,AMBERIEUX D AZERGUES,,45.9288848222,4.74109023714,5,,Ambérieux,Ambérieux,69,Rhône,84,Auvergne-Rhône-Alpes
69006,AMPLEPUIS,69550,AMPLEPUIS,,45.9651984924,4.32676291669,6,,Amplepuis,Amplepuis,69,Rhône,84,Auvergne-Rhône-Alpes
69006,AMPLEPUIS,69550,AMPLEPUIS,ROCHEFORT,45.9651984924,4.32676291669,6,,Amplepuis,Amplepuis,69,Rhône,84,Auvergne-Rhône-Alpes
69007,AMPUIS,69420,AMPUIS,,45.503261775,4.8031868522,7,,Ampuis,Ampuis,69,Rhône,84,Auvergne-Rhône-Alpes
69008,ANCY,69490,ANCY,,45.8321615079,4.50376874726,8,,Ancy,Ancy,69,Rhône,84,Auvergne-Rhône-Alpes
69009,ANSE,69480,ANSE,,45.9422570443,4.70796670026,9,,Anse,Anse,69,Rhône,84,Auvergne-Rhône-Alpes
69010,L ARBRESLE,69210,L ARBRESLE,,45.8356663158,4.60937267063,10,L',Arbresle,L'Arbresle,69,Rhône,84,Auvergne-Rhône-Alpes
69012,LES ARDILLATS,69430,LES ARDILLATS,,46.1887475632,4.53644652924,12,Les,Ardillats,Les Ardillats,69,Rhône,84,Auvergne-Rhône-Alpes
69013,ARNAS,69400,ARNAS,,46.018642208,4.71055115549,13,,Arnas,Arnas,69,Rhône,84,Auvergne-Rhône-Alpes
69014,AVEIZE,69610,AVEIZE,,45.6794036414,4.47551947043,14,,Aveize,Aveize,69,Rhône,84,Auvergne-Rhône-Alpes
69015,AVENAS,69430,AVENAS,,46.1958440631,4.60024025351,15,,Avenas,Avenas,69,Rhône,84,Auvergne-Rhône-Alpes
69016,AZOLETTE,69790,AZOLETTE,,46.1918623777,4.41321565621,16,,Azolette,Azolette,69,Rhône,84,Auvergne-Rhône-Alpes
69017,BAGNOLS,69620,BAGNOLS,,45.9087285024,4.62016773133,17,,Bagnols,Bagnols,69,Rhône,84,Auvergne-Rhône-Alpes
69018,BEAUJEU,69430,BEAUJEU,,46.1564039833,4.58394359167,18,,Beaujeu,Beaujeu,69,Rhône,84,Auvergne-Rhône-Alpes
69018,BEAUJEU,69430,BEAUJEU,LE MOLARD,46.1564039833,4.58394359167,18,,Beaujeu,Beaujeu,69,Rhône,84,Auvergne-Rhône-Alpes
69019,BELLEVILLE,69220,BELLEVILLE,,46.1021561474,4.73105689691,19,,Belleville,Belleville,69,Rhône,84,Auvergne-Rhône-Alpes
69020,BELMONT D AZERGUES,69380,BELMONT D AZERGUES,,45.8674607353,4.66814290336,20,,Belmont-d'Azergues,Belmont-d'Azergues,69,Rhône,84,Auvergne-Rhône-Alpes
69021,BESSENAY,69690,BESSENAY,,45.774361074,4.54738228306,21,,Bessenay,Bessenay,69,Rhône,84,Auvergne-Rhône-Alpes
69021,BESSENAY,69690,BESSENAY,LA BREVENNE,45.774361074,4.54738228306,21,,Bessenay,Bessenay,69,Rhône,84,Auvergne-Rhône-Alpes
69021,BESSENAY,69690,BESSENAY,LA GIRAUDIERE,45.774361074,4.54738228306,21,,Bessenay,Bessenay,69,Rhône,84,Auvergne-Rhône-Alpes
69021,BESSENAY,69690,BESSENAY,LES ROCHES,45.774361074,4.54738228306,21,,Bessenay,Bessenay,69,Rhône,84,Auvergne-Rhône-Alpes
69021,BESSENAY,69690,BESSENAY,LURCIEUX,45.774361074,4.54738228306,21,,Bessenay,Bessenay,69,Rhône,84,Auvergne-Rhône-Alpes
69021,BESSENAY,69690,BESSENAY,POMERIEUX,45.774361074,4.54738228306,21,,Bessenay,Bessenay,69,Rhône,84,Auvergne-Rhône-Alpes
69022,BIBOST,69690,BIBOST,,45.7969461911,4.54895137965,22,,Bibost,Bibost,69,Rhône,84,Auvergne-Rhône-Alpes
69022,BIBOST,69690,BIBOST,LA CALONNIERE,45.7969461911,4.54895137965,22,,Bibost,Bibost,69,Rhône,84,Auvergne-Rhône-Alpes
69023,BLACE,69460,BLACE,,46.0346971393,4.63561700748,23,,Blacé,Blacé,69,Rhône,84,Auvergne-Rhône-Alpes
69024,LE BOIS D OINGT,69620,LE BOIS D OINGT,,45.9188737019,4.5787353944,24,,Val d'Oingt,Val d'Oingt,69,Rhône,84,Auvergne-Rhône-Alpes
69026,LE BREUIL,69620,LE BREUIL,,45.8908813689,4.59105351174,26,Le,Breuil,Le Breuil,69,Rhône,84,Auvergne-Rhône-Alpes
69027,BRIGNAIS,69530,BRIGNAIS,,45.678117475,4.75268787981,27,,Brignais,Brignais,69,Rhône,84,Auvergne-Rhône-Alpes
69027,BRIGNAIS,69530,BRIGNAIS,SEPT CHEMINS,45.678117475,4.75268787981,27,,Brignais,Brignais,69,Rhône,84,Auvergne-Rhône-Alpes
69028,BRINDAS,69126,BRINDAS,,45.7203432265,4.70291458625,28,,Brindas,Brindas,69,Rhône,84,Auvergne-Rhône-Alpes
69029,BRON,69500,BRON,,45.7344856902,4.91168159471,29,,Bron,Bron,69,Rhône,84,Auvergne-Rhône-Alpes
69030,BRULLIOLES,69690,BRULLIOLES,,45.7635299511,4.492682945,30,,Brullioles,Brullioles,69,Rhône,84,Auvergne-Rhône-Alpes
69031,BRUSSIEU,69690,BRUSSIEU,,45.7472888817,4.51714598169,31,,Brussieu,Brussieu,69,Rhône,84,Auvergne-Rhône-Alpes
69032,BULLY,69210,BULLY,,45.8591183924,4.5810550945,32,,Bully,Bully,69,Rhône,84,Auvergne-Rhône-Alpes
69033,CAILLOUX SUR FONTAINES,69270,CAILLOUX SUR FONTAINES,,45.8603404776,4.88370094201,33,,Cailloux-sur-Fontaines,Cailloux-sur-Fontaines,69,Rhône,84,Auvergne-Rhône-Alpes
69034,CALUIRE ET CUIRE,69300,CALUIRE ET CUIRE,,45.7974053648,4.85124857101,34,,Caluire-et-Cuire,Caluire-et-Cuire,69,Rhône,84,Auvergne-Rhône-Alpes
69035,CENVES,69840,CENVES,,46.2792109447,4.66098375274,35,,Cenves,Cenves,69,Rhône,84,Auvergne-Rhône-Alpes
69036,CERCIE,69220,CERCIE,,46.1259448157,4.66717778294,36,,Cercié,Cercié,69,Rhône,84,Auvergne-Rhône-Alpes
69037,CHAMBOST ALLIERES,69870,CHAMBOST ALLIERES,,46.0150786956,4.51007188882,37,,Chambost-Allières,Chambost-Allières,69,Rhône,84,Auvergne-Rhône-Alpes
69038,CHAMBOST LONGESSAIGNE,69770,CHAMBOST LONGESSAIGNE,,45.7739225343,4.37950363405,38,,Chambost-Longessaigne,Chambost-Longessaigne,69,Rhône,84,Auvergne-Rhône-Alpes
69039,CHAMELET,69620,CHAMELET,,45.9847097998,4.4986606687,39,,Chamelet,Chamelet,69,Rhône,84,Auvergne-Rhône-Alpes
69040,CHAMPAGNE AU MONT D OR,69410,CHAMPAGNE AU MONT D OR,,45.7983650854,4.78668207794,40,,Champagne-au-Mont-d'Or,Champagne-au-Mont-d'Or,69,Rhône,84,Auvergne-Rhône-Alpes
69042,LA CHAPELLE SUR COISE,69590,LA CHAPELLE SUR COISE,,45.6501998891,4.49605895981,42,La,Chapelle-sur-Coise,La Chapelle-sur-Coise,69,Rhône,84,Auvergne-Rhône-Alpes
69043,CHAPONOST,69630,CHAPONOST,,45.7088623324,4.74547903701,43,,Chaponost,Chaponost,69,Rhône,84,Auvergne-Rhône-Alpes
69044,CHARBONNIERES LES BAINS,69260,CHARBONNIERES LES BAINS,,45.7797061972,4.74328937073,44,,Charbonnières-les-Bains,Charbonnières-les-Bains,69,Rhône,84,Auvergne-Rhône-Alpes
69045,CHARENTAY,69220,CHARENTAY,,46.0840044201,4.68939365571,45,,Charentay,Charentay,69,Rhône,84,Auvergne-Rhône-Alpes
69046,CHARLY,69390,CHARLY,,45.6508030162,4.78979572338,46,,Charly,Charly,69,Rhône,84,Auvergne-Rhône-Alpes
69047,CHARNAY,69380,CHARNAY,,45.891187551,4.66063186811,47,,Charnay,Charnay,69,Rhône,84,Auvergne-Rhône-Alpes
69048,CHASSAGNY,69700,CHASSAGNY,,45.6052853309,4.73091163688,48,,Chassagny,Chassagny,69,Rhône,84,Auvergne-Rhône-Alpes
69049,CHASSELAY,69380,CHASSELAY,,45.8749558525,4.7708529699,49,,Chasselay,Chasselay,69,Rhône,84,Auvergne-Rhône-Alpes
69050,CHATILLON,69380,CHATILLON,,45.8705575392,4.64214055868,50,,Châtillon,Châtillon,69,Rhône,84,Auvergne-Rhône-Alpes
69051,CHAUSSAN,69440,CHAUSSAN,,45.6374563479,4.63446933593,51,,Chaussan,Chaussan,69,Rhône,84,Auvergne-Rhône-Alpes
69051,CHAUSSAN,69440,CHAUSSAN,LE RICHOUD,45.6374563479,4.63446933593,51,,Chaussan,Chaussan,69,Rhône,84,Auvergne-Rhône-Alpes
69052,CHAZAY D AZERGUES,69380,CHAZAY D AZERGUES,,45.8763096867,4.70920946786,52,,Chazay-d'Azergues,Chazay-d'Azergues,69,Rhône,84,Auvergne-Rhône-Alpes
69053,CHENAS,69840,CHENAS,,46.2160888331,4.70845921605,53,,Chénas,Chénas,69,Rhône,84,Auvergne-Rhône-Alpes
69054,CHENELETTE,69430,CHENELETTE,,46.1691527375,4.49124804928,54,,Chénelette,Chénelette,69,Rhône,84,Auvergne-Rhône-Alpes
69055,LES CHERES,69380,LES CHERES,,45.893124529,4.74321692649,55,Les,Chères,Les Chères,69,Rhône,84,Auvergne-Rhône-Alpes
69056,CHESSY,69380,CHESSY,,45.8906849455,4.61639612817,56,,Chessy,Chessy,69,Rhône,84,Auvergne-Rhône-Alpes
69057,CHEVINAY,69210,CHEVINAY,,45.773502658,4.59662114692,57,,Chevinay,Chevinay,69,Rhône,84,Auvergne-Rhône-Alpes
69058,CHIROUBLES,69115,CHIROUBLES,,46.1855528278,4.65508587528,58,,Chiroubles,Chiroubles,69,Rhône,84,Auvergne-Rhône-Alpes
69059,CIVRIEUX D AZERGUES,69380,CIVRIEUX D AZERGUES,,45.854953276,4.71130995652,59,,Civrieux-d'Azergues,Civrieux-d'Azergues,69,Rhône,84,Auvergne-Rhône-Alpes
69060,CLAVEISOLLES,69870,CLAVEISOLLES,,46.0976713102,4.51103752015,60,,Claveisolles,Claveisolles,69,Rhône,84,Auvergne-Rhône-Alpes
69061,COGNY,69640,COGNY,,45.987704614,4.61029755022,61,,Cogny,Cogny,69,Rhône,84,Auvergne-Rhône-Alpes
69062,COISE,69590,COISE,,45.6138333188,4.46902321077,62,,Coise,Coise,69,Rhône,84,Auvergne-Rhône-Alpes
69063,COLLONGES AU MONT D OR,69660,COLLONGES AU MONT D OR,,45.8220411914,4.8419319446,63,,Collonges-au-Mont-d'Or,Collonges-au-Mont-d'Or,69,Rhône,84,Auvergne-Rhône-Alpes
69064,CONDRIEU,69420,CONDRIEU,,45.4743083818,4.75671075164,64,,Condrieu,Condrieu,69,Rhône,84,Auvergne-Rhône-Alpes
69065,CORCELLES EN BEAUJOLAIS,69220,CORCELLES EN BEAUJOLAIS,,46.153800482,4.72504633206,65,,Corcelles-en-Beaujolais,Corcelles-en-Beaujolais,69,Rhône,84,Auvergne-Rhône-Alpes
69066,COURS,69240,COURS,PONT TRAMBOUZE,46.1087135793,4.33699431774,66,,Cours,Cours,69,Rhône,84,Auvergne-Rhône-Alpes
69066,COURS,69470,COURS,,46.1087135793,4.33699431774,66,,Cours,Cours,69,Rhône,84,Auvergne-Rhône-Alpes
69066,COURS,69470,COURS,LA VILLE,46.1087135793,4.33699431774,66,,Cours,Cours,69,Rhône,84,Auvergne-Rhône-Alpes
69066,COURS,69470,COURS,THEL,46.1087135793,4.33699431774,66,,Cours,Cours,69,Rhône,84,Auvergne-Rhône-Alpes
69067,COURZIEU,69690,COURZIEU,,45.7410710306,4.5702660914,67,,Courzieu,Courzieu,69,Rhône,84,Auvergne-Rhône-Alpes
69068,COUZON AU MONT D OR,69270,COUZON AU MONT D OR,,45.8482374264,4.82235886103,68,,Couzon-au-Mont-d'Or,Couzon-au-Mont-d'Or,69,Rhône,84,Auvergne-Rhône-Alpes
69069,CRAPONNE,69290,CRAPONNE,,45.7462853462,4.72672357999,69,,Craponne,Craponne,69,Rhône,84,Auvergne-Rhône-Alpes
69070,CUBLIZE,69550,CUBLIZE,,46.0245451147,4.3873320653,70,,Cublize,Cublize,69,Rhône,84,Auvergne-Rhône-Alpes
69071,CURIS AU MONT D OR,69250,CURIS AU MONT D OR,,45.8694161345,4.81844287868,71,,Curis-au-Mont-d'Or,Curis-au-Mont-d'Or,69,Rhône,84,Auvergne-Rhône-Alpes
69072,DARDILLY,69570,DARDILLY,,45.8120018662,4.74663974001,72,,Dardilly,Dardilly,69,Rhône,84,Auvergne-Rhône-Alpes
69073,DAREIZE,69490,DAREIZE,,45.9054684843,4.48837332917,73,,Dareizé,Dareizé,69,Rhône,84,Auvergne-Rhône-Alpes
69074,DENICE,69640,DENICE,,46.0045607655,4.65149773309,74,,Denicé,Denicé,69,Rhône,84,Auvergne-Rhône-Alpes
69075,DIEME,69170,DIEME,,45.9618729575,4.46094173485,75,,Dième,Dième,69,Rhône,84,Auvergne-Rhône-Alpes
69076,DOMMARTIN,69380,DOMMARTIN,,45.8357243941,4.7156731311,76,,Dommartin,Dommartin,69,Rhône,84,Auvergne-Rhône-Alpes
69077,DRACE,69220,DRACE,,46.1537638462,4.77319704565,77,,Dracé,Dracé,69,Rhône,84,Auvergne-Rhône-Alpes
69078,DUERNE,69850,DUERNE,,45.6813849409,4.52319550828,78,,Duerne,Duerne,69,Rhône,84,Auvergne-Rhône-Alpes
69080,ECHALAS,69700,ECHALAS,,45.5506779948,4.72996324702,80,,Échalas,Échalas,69,Rhône,84,Auvergne-Rhône-Alpes
69080,ECHALAS,69700,ECHALAS,LA RODIERE,45.5506779948,4.72996324702,80,,Échalas,Échalas,69,Rhône,84,Auvergne-Rhône-Alpes
69081,ECULLY,69130,ECULLY,,45.7821499466,4.7721681177,81,,Écully,Écully,69,Rhône,84,Auvergne-Rhône-Alpes
69082,EMERINGES,69840,EMERINGES,,46.225502947,4.67336302557,82,,Émeringes,Émeringes,69,Rhône,84,Auvergne-Rhône-Alpes
69083,EVEUX,69210,EVEUX,,45.8241099915,4.62228473387,83,,Éveux,Éveux,69,Rhône,84,Auvergne-Rhône-Alpes
69084,FLEURIE,69820,FLEURIE,,46.195108023,4.69220963015,84,,Fleurie,Fleurie,69,Rhône,84,Auvergne-Rhône-Alpes
69085,FLEURIEU SUR SAONE,69250,FLEURIEU SUR SAONE,,45.8589780699,4.85032398358,85,,Fleurieu-sur-Saône,Fleurieu-sur-Saône,69,Rhône,84,Auvergne-Rhône-Alpes
69086,FLEURIEUX SUR L ARBRESLE,69210,FLEURIEUX SUR L ARBRESLE,,45.8393590284,4.65229651357,86,,Fleurieux-sur-l'Arbresle,Fleurieux-sur-l'Arbresle,69,Rhône,84,Auvergne-Rhône-Alpes
69087,FONTAINES ST MARTIN,69270,FONTAINES ST MARTIN,,45.8433300655,4.85410848076,87,,Fontaines-Saint-Martin,Fontaines-Saint-Martin,69,Rhône,84,Auvergne-Rhône-Alpes
69088,FONTAINES SUR SAONE,69270,FONTAINES SUR SAONE,,45.8282811054,4.85664595594,88,,Fontaines-sur-Saône,Fontaines-sur-Saône,69,Rhône,84,Auvergne-Rhône-Alpes
69089,FRANCHEVILLE,69340,FRANCHEVILLE,,45.7378794318,4.75508314041,89,,Francheville,Francheville,69,Rhône,84,Auvergne-Rhône-Alpes
69090,FRONTENAS,69620,FRONTENAS,,45.9219427499,4.63235973295,90,,Frontenas,Frontenas,69,Rhône,84,Auvergne-Rhône-Alpes
69091,GIVORS,69700,GIVORS,,45.5816626973,4.7572616296,91,,Givors,Givors,69,Rhône,84,Auvergne-Rhône-Alpes
69091,GIVORS,69700,GIVORS,ST MARTIN DE CORNAS,45.5816626973,4.7572616296,91,,Givors,Givors,69,Rhône,84,Auvergne-Rhône-Alpes
69092,GLEIZE,69400,GLEIZE,,45.9910359834,4.68792227737,92,,Gleizé,Gleizé,69,Rhône,84,Auvergne-Rhône-Alpes
69093,GRANDRIS,69870,GRANDRIS,,46.0333930542,4.45932733477,93,,Grandris,Grandris,69,Rhône,84,Auvergne-Rhône-Alpes
69094,GREZIEU LA VARENNE,69290,GREZIEU LA VARENNE,,45.7477283973,4.6895073804,94,,Grézieu-la-Varenne,Grézieu-la-Varenne,69,Rhône,84,Auvergne-Rhône-Alpes
69095,GREZIEU LE MARCHE,69610,GREZIEU LE MARCHE,,45.6625017112,4.42125665222,95,,Grézieu-le-Marché,Grézieu-le-Marché,69,Rhône,84,Auvergne-Rhône-Alpes
69096,GRIGNY,69520,GRIGNY,,45.6080535265,4.78645083138,96,,Grigny,Grigny,69,Rhône,84,Auvergne-Rhône-Alpes
69097,LES HAIES,69420,LES HAIES,,45.512760817,4.73868418136,97,Les,Haies,Les Haies,69,Rhône,84,Auvergne-Rhône-Alpes
69098,LES HALLES,69610,LES HALLES,,45.7161225651,4.43485013871,98,Les,Halles,Les Halles,69,Rhône,84,Auvergne-Rhône-Alpes
69099,HAUTE RIVOIRE,69610,HAUTE RIVOIRE,,45.7143308832,4.40563224471,99,,Haute-Rivoire,Haute-Rivoire,69,Rhône,84,Auvergne-Rhône-Alpes
69100,IRIGNY,69540,IRIGNY,,45.6748390924,4.81819594216,100,,Irigny,Irigny,69,Rhône,84,Auvergne-Rhône-Alpes
69101,JARNIOUX,69640,JARNIOUX,,45.9694234086,4.63220899785,101,,Jarnioux,Jarnioux,69,Rhône,84,Auvergne-Rhône-Alpes
69102,JOUX,69170,JOUX,,45.890505206,4.36735802084,102,,Joux,Joux,69,Rhône,84,Auvergne-Rhône-Alpes
69103,JULIENAS,69840,JULIENAS,,46.2415176527,4.70402695558,103,,Juliénas,Juliénas,69,Rhône,84,Auvergne-Rhône-Alpes
69104,JULLIE,69840,JULLIE,,46.2435807553,4.66447828334,104,,Jullié,Jullié,69,Rhône,84,Auvergne-Rhône-Alpes
69105,LACENAS,69640,LACENAS,,45.986314587,4.64749345552,105,,Lacenas,Lacenas,69,Rhône,84,Auvergne-Rhône-Alpes
69106,LACHASSAGNE,69480,LACHASSAGNE,,45.9270174233,4.67906566253,106,,Lachassagne,Lachassagne,69,Rhône,84,Auvergne-Rhône-Alpes
69107,LAMURE SUR AZERGUES,69870,LAMURE SUR AZERGUES,,46.0528953737,4.50887540791,107,,Lamure-sur-Azergues,Lamure-sur-Azergues,69,Rhône,84,Auvergne-Rhône-Alpes
69108,LANCIE,69220,LANCIE,,46.1730373902,4.72575548606,108,,Lancié,Lancié,69,Rhône,84,Auvergne-Rhône-Alpes
69109,LANTIGNIE,69430,LANTIGNIE,,46.1557078,4.61861693858,109,,Lantignié,Lantignié,69,Rhône,84,Auvergne-Rhône-Alpes
69110,LARAJASSE,69590,LARAJASSE,,45.6079976924,4.51457589582,110,,Larajasse,Larajasse,69,Rhône,84,Auvergne-Rhône-Alpes
69110,LARAJASSE,69590,LARAJASSE,LA MURE,45.6079976924,4.51457589582,110,,Larajasse,Larajasse,69,Rhône,84,Auvergne-Rhône-Alpes
69111,LEGNY,69620,LEGNY,,45.9039971217,4.57416632232,111,,Légny,Légny,69,Rhône,84,Auvergne-Rhône-Alpes
69112,LENTILLY,69210,LENTILLY,,45.812542419,4.67106123334,112,,Lentilly,Lentilly,69,Rhône,84,Auvergne-Rhône-Alpes
69113,LETRA,69620,LETRA,,45.9718911586,4.52615651956,113,,Létra,Létra,69,Rhône,84,Auvergne-Rhône-Alpes
69113,LETRA,69620,LETRA,LA ROCHE,45.9718911586,4.52615651956,113,,Létra,Létra,69,Rhône,84,Auvergne-Rhône-Alpes
69114,LIERGUES,69400,LIERGUES,,45.9680217297,4.6642564682,114,,Liergues,Liergues,69,Rhône,84,Auvergne-Rhône-Alpes
69114,LIERGUES,69400,LIERGUES,LA COMBE,45.9680217297,4.6642564682,114,,Liergues,Liergues,69,Rhône,84,Auvergne-Rhône-Alpes
69115,LIMAS,69400,LIMAS,,45.9720377921,4.71919087739,115,,Limas,Limas,69,Rhône,84,Auvergne-Rhône-Alpes
69116,LIMONEST,69760,LIMONEST,,45.8303274483,4.7716823325,116,,Limonest,Limonest,69,Rhône,84,Auvergne-Rhône-Alpes
69117,LISSIEU,69380,LISSIEU,,45.8549753291,4.74619581314,117,,Lissieu,Lissieu,69,Rhône,84,Auvergne-Rhône-Alpes
69118,LOIRE SUR RHONE,69700,LOIRE SUR RHONE,,45.5468466677,4.78290098079,118,,Loire-sur-Rhône,Loire-sur-Rhône,69,Rhône,84,Auvergne-Rhône-Alpes
69119,LONGES,69420,LONGES,,45.5046386524,4.68010031794,119,,Longes,Longes,69,Rhône,84,Auvergne-Rhône-Alpes
69120,LONGESSAIGNE,69770,LONGESSAIGNE,,45.7855981526,4.42642945537,120,,Longessaigne,Longessaigne,69,Rhône,84,Auvergne-Rhône-Alpes
69121,LOZANNE,69380,LOZANNE,,45.8530206071,4.68542193423,121,,Lozanne,Lozanne,69,Rhône,84,Auvergne-Rhône-Alpes
69122,LUCENAY,69480,LUCENAY,,45.9134609573,4.70939043822,122,,Lucenay,Lucenay,69,Rhône,84,Auvergne-Rhône-Alpes
69124,MARCHAMPT,69430,MARCHAMPT,,46.1084857601,4.56101357322,124,,Marchampt,Marchampt,69,Rhône,84,Auvergne-Rhône-Alpes
69125,MARCILLY D AZERGUES,69380,MARCILLY D AZERGUES,,45.8679882645,4.73091611018,125,,Marcilly-d'Azergues,Marcilly-d'Azergues,69,Rhône,84,Auvergne-Rhône-Alpes
69126,MARCY,69480,MARCY,,45.9139105552,4.67605163558,126,,Marcy,Marcy,69,Rhône,84,Auvergne-Rhône-Alpes
69127,MARCY L ETOILE,69280,MARCY L ETOILE,,45.7852649435,4.71250416429,127,,Marcy-l'Étoile,Marcy-l'Étoile,69,Rhône,84,Auvergne-Rhône-Alpes
69130,MEAUX LA MONTAGNE,69550,MEAUX LA MONTAGNE,,46.0459851256,4.42169818269,130,,Meaux-la-Montagne,Meaux-la-Montagne,69,Rhône,84,Auvergne-Rhône-Alpes
69131,MESSIMY,69510,MESSIMY,,45.6988993834,4.67145765319,131,,Messimy,Messimy,69,Rhône,84,Auvergne-Rhône-Alpes
69132,MEYS,69610,MEYS,,45.681836669,4.40076611695,132,,Meys,Meys,69,Rhône,84,Auvergne-Rhône-Alpes
69133,MILLERY,69390,MILLERY,,45.6304862126,4.77973349891,133,,Millery,Millery,69,Rhône,84,Auvergne-Rhône-Alpes
69134,MOIRE,69620,MOIRE,,45.9262357159,4.60176770404,134,,Moiré,Moiré,69,Rhône,84,Auvergne-Rhône-Alpes
69135,MONSOLS,69860,MONSOLS,,46.2227835629,4.51394181008,135,,Monsols,Monsols,69,Rhône,84,Auvergne-Rhône-Alpes
69136,MONTAGNY,69700,MONTAGNY,,45.6247829418,4.75036810523,136,,Montagny,Montagny,69,Rhône,84,Auvergne-Rhône-Alpes
69136,MONTAGNY,69700,MONTAGNY,SOURZY,45.6247829418,4.75036810523,136,,Montagny,Montagny,69,Rhône,84,Auvergne-Rhône-Alpes
69137,MONTMELAS ST SORLIN,69640,MONTMELAS ST SORLIN,,46.0177480558,4.60181116926,137,,Montmelas-Saint-Sorlin,Montmelas-Saint-Sorlin,69,Rhône,84,Auvergne-Rhône-Alpes
69138,MONTROMANT,69610,MONTROMANT,,45.7109243581,4.53559681383,138,,Montromant,Montromant,69,Rhône,84,Auvergne-Rhône-Alpes
69139,MONTROTTIER,69770,MONTROTTIER,,45.8005219603,4.46362962057,139,,Montrottier,Montrottier,69,Rhône,84,Auvergne-Rhône-Alpes
69140,MORANCE,69480,MORANCE,,45.8941529725,4.69976319866,140,,Morancé,Morancé,69,Rhône,84,Auvergne-Rhône-Alpes
69140,MORANCE,69480,MORANCE,ST PIERRE,45.8941529725,4.69976319866,140,,Morancé,Morancé,69,Rhône,84,Auvergne-Rhône-Alpes
69141,MORNANT,69440,MORNANT,,45.6159773607,4.67379130422,141,,Mornant,Mornant,69,Rhône,84,Auvergne-Rhône-Alpes
69141,MORNANT,69440,MORNANT,LE ROSSEON,45.6159773607,4.67379130422,141,,Mornant,Mornant,69,Rhône,84,Auvergne-Rhône-Alpes
69142,LA MULATIERE,69350,LA MULATIERE,,45.7303532192,4.8122084071,142,La,Mulatière,La Mulatière,69,Rhône,84,Auvergne-Rhône-Alpes
69143,NEUVILLE SUR SAONE,69250,NEUVILLE SUR SAONE,,45.8829276204,4.84373616973,143,,Neuville-sur-Saône,Neuville-sur-Saône,69,Rhône,84,Auvergne-Rhône-Alpes
69145,ODENAS,69460,ODENAS,,46.0937833535,4.64506094432,145,,Odenas,Odenas,69,Rhône,84,Auvergne-Rhône-Alpes
69146,OINGT,69620,OINGT,,45.9483312216,4.58594811188,146,,Oingt,Oingt,69,Rhône,84,Auvergne-Rhône-Alpes
69147,LES OLMES,69490,LES OLMES,,45.8839722332,4.52396923794,147,Les,Olmes,Les Olmes,69,Rhône,84,Auvergne-Rhône-Alpes
69148,ORLIENAS,69530,ORLIENAS,,45.6609705564,4.72076320026,148,,Orliénas,Orliénas,69,Rhône,84,Auvergne-Rhône-Alpes
69148,ORLIENAS,69530,ORLIENAS,LE BOULARD,45.6609705564,4.72076320026,148,,Orliénas,Orliénas,69,Rhône,84,Auvergne-Rhône-Alpes
69149,OULLINS,69600,OULLINS,,45.7149665543,4.80299453846,149,,Oullins,Oullins,69,Rhône,84,Auvergne-Rhône-Alpes
69150,OUROUX,69860,OUROUX,,46.2252587391,4.58186961104,150,,Ouroux,Ouroux,69,Rhône,84,Auvergne-Rhône-Alpes
69151,LE PERREON,69460,LE PERREON,,46.0696133822,4.58011470441,151,Le,Perréon,Le Perréon,69,Rhône,84,Auvergne-Rhône-Alpes
69152,PIERRE BENITE,69310,PIERRE BENITE,,45.702202274,4.82705049921,152,,Pierre-Bénite,Pierre-Bénite,69,Rhône,84,Auvergne-Rhône-Alpes
69153,POLEYMIEUX AU MONT D OR,69250,POLEYMIEUX AU MONT D OR,,45.8552588854,4.79477387074,153,,Poleymieux-au-Mont-d'Or,Poleymieux-au-Mont-d'Or,69,Rhône,84,Auvergne-Rhône-Alpes
69154,POLLIONNAY,69290,POLLIONNAY,,45.7685281587,4.65869632922,154,,Pollionnay,Pollionnay,69,Rhône,84,Auvergne-Rhône-Alpes
69155,POMEYS,69590,POMEYS,,45.6462662502,4.44570095396,155,,Pomeys,Pomeys,69,Rhône,84,Auvergne-Rhône-Alpes
69156,POMMIERS,69480,POMMIERS,,45.9582307321,4.6884680442,156,,Pommiers,Pommiers,69,Rhône,84,Auvergne-Rhône-Alpes
69157,PONTCHARRA SUR TURDINE,69490,PONTCHARRA SUR TURDINE,,45.8770735618,4.49571511005,157,,Pontcharra-sur-Turdine,Pontcharra-sur-Turdine,69,Rhône,84,Auvergne-Rhône-Alpes
69159,POUILLY LE MONIAL,69400,POUILLY LE MONIAL,,45.9554021029,4.64557594844,159,,Porte des Pierres Dorées,Porte des Pierres Dorées,69,Rhône,84,Auvergne-Rhône-Alpes
69160,POULE LES ECHARMEAUX,69870,POULE LES ECHARMEAUX,,46.1426190331,4.46070930029,160,,Poule-les-Écharmeaux,Poule-les-Écharmeaux,69,Rhône,84,Auvergne-Rhône-Alpes
69160,POULE LES ECHARMEAUX,69870,POULE LES ECHARMEAUX,LES ECHARMEAUX,46.1426190331,4.46070930029,160,,Poule-les-Écharmeaux,Poule-les-Écharmeaux,69,Rhône,84,Auvergne-Rhône-Alpes
69161,PROPIERES,69790,PROPIERES,,46.1927452094,4.45130908694,161,,Propières,Propières,69,Rhône,84,Auvergne-Rhône-Alpes
69162,QUINCIE EN BEAUJOLAIS,69430,QUINCIE EN BEAUJOLAIS,,46.1132737898,4.60801374116,162,,Quincié-en-Beaujolais,Quincié-en-Beaujolais,69,Rhône,84,Auvergne-Rhône-Alpes
69163,QUINCIEUX,69650,QUINCIEUX,,45.9136198331,4.77375123298,163,,Quincieux,Quincieux,69,Rhône,84,Auvergne-Rhône-Alpes
69163,QUINCIEUX,69650,QUINCIEUX,VEISSIEUX,45.9136198331,4.77375123298,163,,Quincieux,Quincieux,69,Rhône,84,Auvergne-Rhône-Alpes
69164,RANCHAL,69470,RANCHAL,,46.1234461797,4.4024348545,164,,Ranchal,Ranchal,69,Rhône,84,Auvergne-Rhône-Alpes
69165,REGNIE DURETTE,69430,REGNIE DURETTE,,46.1466108781,4.64172714554,165,,Régnié-Durette,Régnié-Durette,69,Rhône,84,Auvergne-Rhône-Alpes
69165,REGNIE DURETTE,69430,REGNIE DURETTE,DURETTE,46.1466108781,4.64172714554,165,,Régnié-Durette,Régnié-Durette,69,Rhône,84,Auvergne-Rhône-Alpes
69166,RIVERIE,69440,RIVERIE,,45.599294924,4.58907052971,166,,Riverie,Riverie,69,Rhône,84,Auvergne-Rhône-Alpes
69167,RIVOLET,69640,RIVOLET,,46.0065781442,4.58017136765,167,,Rivolet,Rivolet,69,Rhône,84,Auvergne-Rhône-Alpes
69167,RIVOLET,69640,RIVOLET,LA FOUILLOUSE,46.0065781442,4.58017136765,167,,Rivolet,Rivolet,69,Rhône,84,Auvergne-Rhône-Alpes
69168,ROCHETAILLEE SUR SAONE,69270,ROCHETAILLEE SUR SAONE,,45.8431049292,4.83850182261,168,,Rochetaillée-sur-Saône,Rochetaillée-sur-Saône,69,Rhône,84,Auvergne-Rhône-Alpes
69169,RONNO,69550,RONNO,,45.9873139302,4.38989521253,169,,Ronno,Ronno,69,Rhône,84,Auvergne-Rhône-Alpes
69170,RONTALON,69510,RONTALON,,45.6566700054,4.61833164833,170,,Rontalon,Rontalon,69,Rhône,84,Auvergne-Rhône-Alpes
69171,SAIN BEL,69210,SAIN BEL,,45.8121205501,4.59925971252,171,,Sain-Bel,Sain-Bel,69,Rhône,84,Auvergne-Rhône-Alpes
69172,SALLES ARBUISSONNAS EN BEAUJOLAIS,69460,SALLES ARBUISSONNAS BEAUJOLAIS,,46.0453025139,4.63089316572,172,,Salles-Arbuissonnas-en-Beaujolais,Salles-Arbuissonnas-en-Beaujolais,69,Rhône,84,Auvergne-Rhône-Alpes
69172,SALLES ARBUISSONNAS EN BEAUJOLAIS,69460,SALLES ARBUISSONNAS BEAUJOLAIS,ARBUISSONNAS,46.0453025139,4.63089316572,172,,Salles-Arbuissonnas-en-Beaujolais,Salles-Arbuissonnas-en-Beaujolais,69,Rhône,84,Auvergne-Rhône-Alpes
69173,SARCEY,69490,SARCEY,,45.88508474,4.55500376864,173,,Sarcey,Sarcey,69,Rhône,84,Auvergne-Rhône-Alpes
69174,LES SAUVAGES,69170,LES SAUVAGES,,45.9238716412,4.37393979073,174,Les,Sauvages,Les Sauvages,69,Rhône,84,Auvergne-Rhône-Alpes
69175,SAVIGNY,69210,SAVIGNY,,45.8200944219,4.56587314617,175,,Savigny,Savigny,69,Rhône,84,Auvergne-Rhône-Alpes
69176,SOUCIEU EN JARREST,69510,SOUCIEU EN JARREST,,45.6769698938,4.69227069505,176,,Soucieu-en-Jarrest,Soucieu-en-Jarrest,69,Rhône,84,Auvergne-Rhône-Alpes
69177,SOURCIEUX LES MINES,69210,SOURCIEUX LES MINES,,45.7982263531,4.63130091512,177,,Sourcieux-les-Mines,Sourcieux-les-Mines,69,Rhône,84,Auvergne-Rhône-Alpes
69178,SOUZY,69610,SOUZY,,45.7040409775,4.45107532306,178,,Souzy,Souzy,69,Rhône,84,Auvergne-Rhône-Alpes
69179,ST ANDEOL LE CHATEAU,69700,ST ANDEOL LE CHATEAU,,45.5895638016,4.69551467475,179,,Beauvallon,Beauvallon,69,Rhône,84,Auvergne-Rhône-Alpes
69180,ST ANDRE LA COTE,69440,ST ANDRE LA COTE,,45.6322671317,4.59660370042,180,,Saint-André-la-Côte,Saint-André-la-Côte,69,Rhône,84,Auvergne-Rhône-Alpes
69181,ST APPOLINAIRE,69170,ST APPOLINAIRE,,45.9769778308,4.42664863829,181,,Saint-Appolinaire,Saint-Appolinaire,69,Rhône,84,Auvergne-Rhône-Alpes
69182,ST BONNET DES BRUYERES,69790,ST BONNET DES BRUYERES,,46.2663774591,4.47201345248,182,,Saint-Bonnet-des-Bruyères,Saint-Bonnet-des-Bruyères,69,Rhône,84,Auvergne-Rhône-Alpes
69183,ST BONNET LE TRONCY,69870,ST BONNET LE TRONCY,,46.0764312104,4.41977426167,183,,Saint-Bonnet-le-Troncy,Saint-Bonnet-le-Troncy,69,Rhône,84,Auvergne-Rhône-Alpes
69184,STE CATHERINE,69440,STE CATHERINE,,45.6030568648,4.57279453276,184,,Sainte-Catherine,Sainte-Catherine,69,Rhône,84,Auvergne-Rhône-Alpes
69184,STE CATHERINE,69440,STE CATHERINE,BARROT,45.6030568648,4.57279453276,184,,Sainte-Catherine,Sainte-Catherine,69,Rhône,84,Auvergne-Rhône-Alpes
69184,STE CATHERINE,69440,STE CATHERINE,LA FLACHERE,45.6030568648,4.57279453276,184,,Sainte-Catherine,Sainte-Catherine,69,Rhône,84,Auvergne-Rhône-Alpes
69185,ST CHRISTOPHE,69860,ST CHRISTOPHE,,46.2545993789,4.53354933905,185,,Saint-Christophe,Saint-Christophe,69,Rhône,84,Auvergne-Rhône-Alpes
69186,ST CLEMENT DE VERS,69790,ST CLEMENT DE VERS,,46.2265960037,4.40852585704,186,,Saint-Clément-de-Vers,Saint-Clément-de-Vers,69,Rhône,84,Auvergne-Rhône-Alpes
69187,ST CLEMENT LES PLACES,69930,ST CLEMENT LES PLACES,,45.756189691,4.42060424905,187,,Saint-Clément-les-Places,Saint-Clément-les-Places,69,Rhône,84,Auvergne-Rhône-Alpes
69188,ST CLEMENT SUR VALSONNE,69170,ST CLEMENT SUR VALSONNE,,45.9245957818,4.45989401825,188,,Saint-Clément-sur-Valsonne,Saint-Clément-sur-Valsonne,69,Rhône,84,Auvergne-Rhône-Alpes
69189,STE COLOMBE,69560,STE COLOMBE,,45.5250030617,4.85931457938,189,,Sainte-Colombe,Sainte-Colombe,69,Rhône,84,Auvergne-Rhône-Alpes
69190,STE CONSORCE,69280,STE CONSORCE,,45.7744360761,4.69352659139,190,,Sainte-Consorce,Sainte-Consorce,69,Rhône,84,Auvergne-Rhône-Alpes
69191,ST CYR AU MONT D OR,69450,ST CYR AU MONT D OR,,45.819421673,4.81813452511,191,,Saint-Cyr-au-Mont-d'Or,Saint-Cyr-au-Mont-d'Or,69,Rhône,84,Auvergne-Rhône-Alpes
69192,ST CYR LE CHATOUX,69870,ST CYR LE CHATOUX,,46.0314296952,4.53865267532,192,,Saint-Cyr-le-Chatoux,Saint-Cyr-le-Chatoux,69,Rhône,84,Auvergne-Rhône-Alpes
69193,ST CYR SUR LE RHONE,69560,ST CYR SUR LE RHONE,,45.5166530821,4.83257725316,193,,Saint-Cyr-sur-le-Rhône,Saint-Cyr-sur-le-Rhône,69,Rhône,84,Auvergne-Rhône-Alpes
69194,ST DIDIER AU MONT D OR,69370,ST DIDIER AU MONT D OR,,45.8147288136,4.79844330211,194,,Saint-Didier-au-Mont-d'Or,Saint-Didier-au-Mont-d'Or,69,Rhône,84,Auvergne-Rhône-Alpes
69195,ST DIDIER SOUS RIVERIE,69440,ST DIDIER SOUS RIVERIE,,45.5926758452,4.60545378337,195,,Saint-Didier-sous-Riverie,Saint-Didier-sous-Riverie,69,Rhône,84,Auvergne-Rhône-Alpes
69195,ST DIDIER SOUS RIVERIE,69440,ST DIDIER SOUS RIVERIE,FILLONNIERE,45.5926758452,4.60545378337,195,,Saint-Didier-sous-Riverie,Saint-Didier-sous-Riverie,69,Rhône,84,Auvergne-Rhône-Alpes
69196,ST DIDIER SUR BEAUJEU,69430,ST DIDIER SUR BEAUJEU,,46.1460297825,4.53494871719,196,,Saint-Didier-sur-Beaujeu,Saint-Didier-sur-Beaujeu,69,Rhône,84,Auvergne-Rhône-Alpes
69197,ST ETIENNE DES OULLIERES,69460,ST ETIENNE DES OULLIERES,,46.0632060552,4.65601926022,197,,Saint-Étienne-des-Oullières,Saint-Étienne-des-Oullières,69,Rhône,84,Auvergne-Rhône-Alpes
69198,ST ETIENNE LA VARENNE,69460,ST ETIENNE LA VARENNE,,46.0771106167,4.62211062992,198,,Saint-Étienne-la-Varenne,Saint-Étienne-la-Varenne,69,Rhône,84,Auvergne-Rhône-Alpes
69199,ST FONS,69190,ST FONS,,45.7011050588,4.85046367008,199,,Saint-Fons,Saint-Fons,69,Rhône,84,Auvergne-Rhône-Alpes
69200,ST FORGEUX,69490,ST FORGEUX,,45.8538939544,4.46335108066,200,,Saint-Forgeux,Saint-Forgeux,69,Rhône,84,Auvergne-Rhône-Alpes
69201,STE FOY L ARGENTIERE,69610,STE FOY L ARGENTIERE,,45.7046028711,4.4705436953,201,,Sainte-Foy-l'Argentière,Sainte-Foy-l'Argentière,69,Rhône,84,Auvergne-Rhône-Alpes
69202,STE FOY LES LYON,69110,STE FOY LES LYON,,45.7359781395,4.79343173908,202,,Sainte-Foy-lès-Lyon,Sainte-Foy-lès-Lyon,69,Rhône,84,Auvergne-Rhône-Alpes
69203,ST GENIS L ARGENTIERE,69610,ST GENIS L ARGENTIERE,,45.7133172246,4.49538138887,203,,Saint-Genis-l'Argentière,Saint-Genis-l'Argentière,69,Rhône,84,Auvergne-Rhône-Alpes
69204,ST GENIS LAVAL,69230,ST GENIS LAVAL,,45.6936777293,4.78919028453,204,,Saint-Genis-Laval,Saint-Genis-Laval,69,Rhône,84,Auvergne-Rhône-Alpes
69205,ST GENIS LES OLLIERES,69290,ST GENIS LES OLLIERES,,45.7610551042,4.72602177608,205,,Saint-Genis-les-Ollières,Saint-Genis-les-Ollières,69,Rhône,84,Auvergne-Rhône-Alpes
69206,ST GEORGES DE RENEINS,69830,ST GEORGES DE RENEINS,,46.0576323656,4.72069943417,206,,Saint-Georges-de-Reneins,Saint-Georges-de-Reneins,69,Rhône,84,Auvergne-Rhône-Alpes
69207,ST GERMAIN AU MONT D OR,69650,ST GERMAIN AU MONT D OR,,45.8822105872,4.80144071839,207,,Saint-Germain-au-Mont-d'Or,Saint-Germain-au-Mont-d'Or,69,Rhône,84,Auvergne-Rhône-Alpes
69208,ST GERMAIN NUELLES,69210,ST GERMAIN NUELLES,,45.8643153659,4.6129807389,208,,Saint-Germain-Nuelles,Saint-Germain-Nuelles,69,Rhône,84,Auvergne-Rhône-Alpes
69208,ST GERMAIN NUELLES,69210,ST GERMAIN NUELLES,NUELLES,45.8643153659,4.6129807389,208,,Saint-Germain-Nuelles,Saint-Germain-Nuelles,69,Rhône,84,Auvergne-Rhône-Alpes
69209,ST IGNY DE VERS,69790,ST IGNY DE VERS,,46.2368070195,4.44156117895,209,,Saint-Igny-de-Vers,Saint-Igny-de-Vers,69,Rhône,84,Auvergne-Rhône-Alpes
69210,ST JACQUES DES ARRETS,69860,ST JACQUES DES ARRETS,,46.2560467377,4.60713684439,210,,Saint-Jacques-des-Arrêts,Saint-Jacques-des-Arrêts,69,Rhône,84,Auvergne-Rhône-Alpes
69211,ST JEAN D ARDIERES,69220,ST JEAN D ARDIERES,,46.1270163797,4.72219308287,211,,Saint-Jean-d'Ardières,Saint-Jean-d'Ardières,69,Rhône,84,Auvergne-Rhône-Alpes
69212,ST JEAN DES VIGNES,69380,ST JEAN DES VIGNES,,45.8746263893,4.68243695399,212,,Saint-Jean-des-Vignes,Saint-Jean-des-Vignes,69,Rhône,84,Auvergne-Rhône-Alpes
69213,ST JEAN DE TOUSLAS,69700,ST JEAN DE TOUSLAS,,45.5728153353,4.67206065309,213,,Saint-Jean-de-Touslas,Saint-Jean-de-Touslas,69,Rhône,84,Auvergne-Rhône-Alpes
69214,ST JEAN LA BUSSIERE,69550,ST JEAN LA BUSSIERE,,46.0024923501,4.33306626203,214,,Saint-Jean-la-Bussière,Saint-Jean-la-Bussière,69,Rhône,84,Auvergne-Rhône-Alpes
69215,ST JULIEN,69640,ST JULIEN,,46.022493145,4.65158431759,215,,Saint-Julien,Saint-Julien,69,Rhône,84,Auvergne-Rhône-Alpes
69215,ST JULIEN,69640,ST JULIEN,LA ROCHE,46.022493145,4.65158431759,215,,Saint-Julien,Saint-Julien,69,Rhône,84,Auvergne-Rhône-Alpes
69216,ST JULIEN SUR BIBOST,69690,ST JULIEN SUR BIBOST,,45.800286879,4.50990648734,216,,Saint-Julien-sur-Bibost,Saint-Julien-sur-Bibost,69,Rhône,84,Auvergne-Rhône-Alpes
69217,ST JUST D AVRAY,69870,ST JUST D AVRAY,,45.9996999746,4.4526558128,217,,Saint-Just-d'Avray,Saint-Just-d'Avray,69,Rhône,84,Auvergne-Rhône-Alpes
69218,ST LAGER,69220,ST LAGER,,46.1119203914,4.67603777792,218,,Saint-Lager,Saint-Lager,69,Rhône,84,Auvergne-Rhône-Alpes
69219,ST LAURENT D AGNY,69440,ST LAURENT D AGNY,,45.6427267769,4.68437785922,219,,Saint-Laurent-d'Agny,Saint-Laurent-d'Agny,69,Rhône,84,Auvergne-Rhône-Alpes
69219,ST LAURENT D AGNY,69440,ST LAURENT D AGNY,ST VINCENT,45.6427267769,4.68437785922,219,,Saint-Laurent-d'Agny,Saint-Laurent-d'Agny,69,Rhône,84,Auvergne-Rhône-Alpes
69220,ST LAURENT DE CHAMOUSSET,69930,ST LAURENT DE CHAMOUSSET,,45.7378229671,4.46479069472,220,,Saint-Laurent-de-Chamousset,Saint-Laurent-de-Chamousset,69,Rhône,84,Auvergne-Rhône-Alpes
69222,ST LAURENT D OINGT,69620,ST LAURENT D OINGT,,45.9392674789,4.56373619593,222,,Saint-Laurent-d'Oingt,Saint-Laurent-d'Oingt,69,Rhône,84,Auvergne-Rhône-Alpes
69223,ST LOUP,69490,ST LOUP,,45.8936220046,4.48940688686,223,,Saint-Loup,Saint-Loup,69,Rhône,84,Auvergne-Rhône-Alpes
69224,ST MAMERT,69860,ST MAMERT,,46.2470018204,4.57103908302,224,,Saint-Mamert,Saint-Mamert,69,Rhône,84,Auvergne-Rhône-Alpes
69225,ST MARCEL L ECLAIRE,69170,ST MARCEL L ECLAIRE,,45.8745331983,4.42248122756,225,,Saint-Marcel-l'Éclairé,Saint-Marcel-l'Éclairé,69,Rhône,84,Auvergne-Rhône-Alpes
69227,ST MARTIN EN HAUT,69850,ST MARTIN EN HAUT,,45.658238601,4.55827258271,227,,Saint-Martin-en-Haut,Saint-Martin-en-Haut,69,Rhône,84,Auvergne-Rhône-Alpes
69227,ST MARTIN EN HAUT,69850,ST MARTIN EN HAUT,ROCHEFORT,45.658238601,4.55827258271,227,,Saint-Martin-en-Haut,Saint-Martin-en-Haut,69,Rhône,84,Auvergne-Rhône-Alpes
69228,ST MAURICE SUR DARGOIRE,69440,ST MAURICE SUR DARGOIRE,,45.5781277955,4.64115905425,228,,Chabanière,Chabanière,69,Rhône,84,Auvergne-Rhône-Alpes
69228,ST MAURICE SUR DARGOIRE,69440,ST MAURICE SUR DARGOIRE,ROUSSILLIERE,45.5781277955,4.64115905425,228,,Chabanière,Chabanière,69,Rhône,84,Auvergne-Rhône-Alpes
69229,ST NIZIER D AZERGUES,69870,ST NIZIER D AZERGUES,,46.0832387842,4.4623284164,229,,Saint-Nizier-d'Azergues,Saint-Nizier-d'Azergues,69,Rhône,84,Auvergne-Rhône-Alpes
69230,STE PAULE,69620,STE PAULE,,45.9721337297,4.56220399315,230,,Sainte-Paule,Sainte-Paule,69,Rhône,84,Auvergne-Rhône-Alpes
69231,ST PIERRE LA PALUD,69210,ST PIERRE LA PALUD,,45.7818245427,4.61622468853,231,,Saint-Pierre-la-Palud,Saint-Pierre-la-Palud,69,Rhône,84,Auvergne-Rhône-Alpes
69233,ST ROMAIN AU MONT D OR,69270,ST ROMAIN AU MONT D OR,,45.8355530258,4.82084858556,233,,Saint-Romain-au-Mont-d'Or,Saint-Romain-au-Mont-d'Or,69,Rhône,84,Auvergne-Rhône-Alpes
69234,ST ROMAIN DE POPEY,69490,ST ROMAIN DE POPEY,,45.85576965,4.52946309389,234,,Saint-Romain-de-Popey,Saint-Romain-de-Popey,69,Rhône,84,Auvergne-Rhône-Alpes
69235,ST ROMAIN EN GAL,69560,ST ROMAIN EN GAL,,45.5357749158,4.8261922699,235,,Saint-Romain-en-Gal,Saint-Romain-en-Gal,69,Rhône,84,Auvergne-Rhône-Alpes
69235,ST ROMAIN EN GAL,69560,ST ROMAIN EN GAL,POMERIEUX,45.5357749158,4.8261922699,235,,Saint-Romain-en-Gal,Saint-Romain-en-Gal,69,Rhône,84,Auvergne-Rhône-Alpes
69236,ST ROMAIN EN GIER,69700,ST ROMAIN EN GIER,,45.5657533865,4.70401885929,236,,Saint-Romain-en-Gier,Saint-Romain-en-Gier,69,Rhône,84,Auvergne-Rhône-Alpes
69237,ST SORLIN,69440,ST SORLIN,,45.6194899762,4.62990906622,237,,Saint-Sorlin,Saint-Sorlin,69,Rhône,84,Auvergne-Rhône-Alpes
69238,ST SYMPHORIEN SUR COISE,69590,ST SYMPHORIEN SUR COISE,,45.6308546357,4.45042661825,238,,Saint-Symphorien-sur-Coise,Saint-Symphorien-sur-Coise,69,Rhône,84,Auvergne-Rhône-Alpes
69239,ST VERAND,69620,ST VERAND,,45.9219304015,4.51963645902,239,,Saint-Vérand,Saint-Vérand,69,Rhône,84,Auvergne-Rhône-Alpes
69240,ST VINCENT DE REINS,69240,ST VINCENT DE REINS,,46.0739597884,4.38622082272,240,,Saint-Vincent-de-Reins,Saint-Vincent-de-Reins,69,Rhône,84,Auvergne-Rhône-Alpes
69241,TALUYERS,69440,TALUYERS,,45.6362754046,4.72434330862,241,,Taluyers,Taluyers,69,Rhône,84,Auvergne-Rhône-Alpes
69241,TALUYERS,69440,TALUYERS,LE BATARD,45.6362754046,4.72434330862,241,,Taluyers,Taluyers,69,Rhône,84,Auvergne-Rhône-Alpes
69242,TAPONAS,69220,TAPONAS,,46.1258360429,4.76268144564,242,,Taponas,Taponas,69,Rhône,84,Auvergne-Rhône-Alpes
69243,TARARE,69170,TARARE,,45.9036535552,4.42375959345,243,,Tarare,Tarare,69,Rhône,84,Auvergne-Rhône-Alpes
69244,TASSIN LA DEMI LUNE,69160,TASSIN LA DEMI LUNE,,45.7628681769,4.75540595543,244,,Tassin-la-Demi-Lune,Tassin-la-Demi-Lune,69,Rhône,84,Auvergne-Rhône-Alpes
69245,TERNAND,69620,TERNAND,,45.9482185979,4.52178604805,245,,Ternand,Ternand,69,Rhône,84,Auvergne-Rhône-Alpes
69246,THEIZE,69620,THEIZE,,45.9407758878,4.62735896797,246,,Theizé,Theizé,69,Rhône,84,Auvergne-Rhône-Alpes
69248,THIZY LES BOURGS,69240,THIZY LES BOURGS,,46.0302777714,4.31390305209,248,,Thizy-les-Bourgs,Thizy-les-Bourgs,69,Rhône,84,Auvergne-Rhône-Alpes
69248,THIZY LES BOURGS,69240,THIZY LES BOURGS,BOURG DE THIZY,46.0302777714,4.31390305209,248,,Thizy-les-Bourgs,Thizy-les-Bourgs,69,Rhône,84,Auvergne-Rhône-Alpes
69248,THIZY LES BOURGS,69240,THIZY LES BOURGS,LA CHAPELLE DE MARDORE,46.0302777714,4.31390305209,248,,Thizy-les-Bourgs,Thizy-les-Bourgs,69,Rhône,84,Auvergne-Rhône-Alpes
69248,THIZY LES BOURGS,69240,THIZY LES BOURGS,MARDORE,46.0302777714,4.31390305209,248,,Thizy-les-Bourgs,Thizy-les-Bourgs,69,Rhône,84,Auvergne-Rhône-Alpes
69248,THIZY LES BOURGS,69240,THIZY LES BOURGS,MARNAND,46.0302777714,4.31390305209,248,,Thizy-les-Bourgs,Thizy-les-Bourgs,69,Rhône,84,Auvergne-Rhône-Alpes
69249,THURINS,69510,THURINS,,45.6838356004,4.6261890947,249,,Thurins,Thurins,69,Rhône,84,Auvergne-Rhône-Alpes
69250,LA TOUR DE SALVAGNY,69890,LA TOUR DE SALVAGNY,,45.8104377668,4.7123892929,250,La,Tour-de-Salvagny,La Tour-de-Salvagny,69,Rhône,84,Auvergne-Rhône-Alpes
69251,TRADES,69860,TRADES,,46.2738901496,4.56614765059,251,,Trades,Trades,69,Rhône,84,Auvergne-Rhône-Alpes
69252,TREVES,69420,TREVES,,45.5348002841,4.68968539242,252,,Trèves,Trèves,69,Rhône,84,Auvergne-Rhône-Alpes
69253,TUPIN ET SEMONS,69420,TUPIN ET SEMONS,,45.4902621109,4.77454141311,253,,Tupin-et-Semons,Tupin-et-Semons,69,Rhône,84,Auvergne-Rhône-Alpes
69254,VALSONNE,69170,VALSONNE,,45.946942199,4.42166698424,254,,Valsonne,Valsonne,69,Rhône,84,Auvergne-Rhône-Alpes
69255,VAUGNERAY,69670,VAUGNERAY,,45.7318792519,4.64378667979,255,,Vaugneray,Vaugneray,69,Rhône,84,Auvergne-Rhône-Alpes
69255,VAUGNERAY,69670,VAUGNERAY,ST LAURENT DE VAUX,45.7318792519,4.64378667979,255,,Vaugneray,Vaugneray,69,Rhône,84,Auvergne-Rhône-Alpes
69256,VAULX EN VELIN,69120,VAULX EN VELIN,,45.7858821061,4.92637767698,256,,Vaulx-en-Velin,Vaulx-en-Velin,69,Rhône,84,Auvergne-Rhône-Alpes
69257,VAUX EN BEAUJOLAIS,69460,VAUX EN BEAUJOLAIS,,46.0460801697,4.57697750068,257,,Vaux-en-Beaujolais,Vaux-en-Beaujolais,69,Rhône,84,Auvergne-Rhône-Alpes
69258,VAUXRENARD,69820,VAUXRENARD,,46.2172909716,4.63812595224,258,,Vauxrenard,Vauxrenard,69,Rhône,84,Auvergne-Rhône-Alpes
69259,VENISSIEUX,69200,VENISSIEUX,,45.7037728826,4.88137668221,259,,Vénissieux,Vénissieux,69,Rhône,84,Auvergne-Rhône-Alpes
69260,VERNAISON,69390,VERNAISON,,45.6493899066,4.80930520738,260,,Vernaison,Vernaison,69,Rhône,84,Auvergne-Rhône-Alpes
69261,VERNAY,69430,VERNAY,,46.1645022966,4.52661139979,261,,Vernay,Vernay,69,Rhône,84,Auvergne-Rhône-Alpes
69263,VILLECHENEVE,69770,VILLECHENEVE,,45.8168065047,4.40737314323,263,,Villechenève,Villechenève,69,Rhône,84,Auvergne-Rhône-Alpes
69264,VILLEFRANCHE SUR SAONE,69400,VILLEFRANCHE SUR SAONE,,45.9874596667,4.73174007305,264,,Villefranche-sur-Saône,Villefranche-sur-Saône,69,Rhône,84,Auvergne-Rhône-Alpes
69265,VILLE SUR JARNIOUX,69640,VILLE SUR JARNIOUX,,45.9699127894,4.59789508297,265,,Ville-sur-Jarnioux,Ville-sur-Jarnioux,69,Rhône,84,Auvergne-Rhône-Alpes
69266,VILLEURBANNE,69100,VILLEURBANNE,,45.7707704179,4.88845817426,266,,Villeurbanne,Villeurbanne,69,Rhône,84,Auvergne-Rhône-Alpes
69267,VILLIE MORGON,69910,VILLIE MORGON,,46.1597734434,4.67110459009,267,,Villié-Morgon,Villié-Morgon,69,Rhône,84,Auvergne-Rhône-Alpes
69268,VOURLES,69390,VOURLES,,45.6578547027,4.76815797121,268,,Vourles,Vourles,69,Rhône,84,Auvergne-Rhône-Alpes
69268,VOURLES,69390,VOURLES,SEPT CHEMINS,45.6578547027,4.76815797121,268,,Vourles,Vourles,69,Rhône,84,Auvergne-Rhône-Alpes
69269,YZERON,69510,YZERON,,45.7095752496,4.59542617149,269,,Yzeron,Yzeron,69,Rhône,84,Auvergne-Rhône-Alpes
69270,CHAPONNAY,69970,CHAPONNAY,,45.6282287453,4.95028014638,270,,Chaponnay,Chaponnay,69,Rhône,84,Auvergne-Rhône-Alpes
69271,CHASSIEU,69680,CHASSIEU,,45.7376148968,4.96172914286,271,,Chassieu,Chassieu,69,Rhône,84,Auvergne-Rhône-Alpes
69272,COMMUNAY,69360,COMMUNAY,,45.5981602115,4.83863440374,272,,Communay,Communay,69,Rhône,84,Auvergne-Rhône-Alpes
69273,CORBAS,69960,CORBAS,,45.6680498711,4.90843949215,273,,Corbas,Corbas,69,Rhône,84,Auvergne-Rhône-Alpes
69275,DECINES CHARPIEU,69150,DECINES CHARPIEU,,45.7717277447,4.96142704635,275,,Décines-Charpieu,Décines-Charpieu,69,Rhône,84,Auvergne-Rhône-Alpes
69275,DECINES CHARPIEU,69150,DECINES CHARPIEU,LE MOLARD,45.7717277447,4.96142704635,275,,Décines-Charpieu,Décines-Charpieu,69,Rhône,84,Auvergne-Rhône-Alpes
69276,FEYZIN,69320,FEYZIN,,45.6730630785,4.85725794932,276,,Feyzin,Feyzin,69,Rhône,84,Auvergne-Rhône-Alpes
69277,GENAS,69740,GENAS,,45.7297209932,5.01590344241,277,,Genas,Genas,69,Rhône,84,Auvergne-Rhône-Alpes
69278,GENAY,69730,GENAY,,45.8980767438,4.83863435794,278,,Genay,Genay,69,Rhône,84,Auvergne-Rhône-Alpes
69279,JONAGE,69330,JONAGE,,45.7911073541,5.0415336287,279,,Jonage,Jonage,69,Rhône,84,Auvergne-Rhône-Alpes
69280,JONS,69330,JONS,,45.8001909817,5.07842030906,280,,Jons,Jons,69,Rhône,84,Auvergne-Rhône-Alpes
69281,MARENNES,69970,MARENNES,,45.6230631164,4.90753751863,281,,Marennes,Marennes,69,Rhône,84,Auvergne-Rhône-Alpes
69282,MEYZIEU,69330,MEYZIEU,,45.7770899686,5.00632679347,282,,Meyzieu,Meyzieu,69,Rhône,84,Auvergne-Rhône-Alpes
69283,MIONS,69780,MIONS,,45.6647373498,4.94915210897,283,,Mions,Mions,69,Rhône,84,Auvergne-Rhône-Alpes
69284,MONTANAY,69250,MONTANAY,,45.8771716527,4.86771013158,284,,Montanay,Montanay,69,Rhône,84,Auvergne-Rhône-Alpes
69285,PUSIGNAN,69330,PUSIGNAN,,45.7538862339,5.06707976453,285,,Pusignan,Pusignan,69,Rhône,84,Auvergne-Rhône-Alpes
69286,RILLIEUX LA PAPE,69140,RILLIEUX LA PAPE,,45.8205988109,4.89822639815,286,,Rillieux-la-Pape,Rillieux-la-Pape,69,Rhône,84,Auvergne-Rhône-Alpes
69286,RILLIEUX LA PAPE,69140,RILLIEUX LA PAPE,CREPIEUX LA PAPE,45.8205988109,4.89822639815,286,,Rillieux-la-Pape,Rillieux-la-Pape,69,Rhône,84,Auvergne-Rhône-Alpes
69287,ST BONNET DE MURE,69720,ST BONNET DE MURE,,45.6961833142,5.02220384173,287,,Saint-Bonnet-de-Mure,Saint-Bonnet-de-Mure,69,Rhône,84,Auvergne-Rhône-Alpes
69288,ST LAURENT DE MURE,69720,ST LAURENT DE MURE,,45.6834072587,5.06089287169,288,,Saint-Laurent-de-Mure,Saint-Laurent-de-Mure,69,Rhône,84,Auvergne-Rhône-Alpes
69289,ST PIERRE DE CHANDIEU,69780,ST PIERRE DE CHANDIEU,,45.6448417915,5.00876036664,289,,Saint-Pierre-de-Chandieu,Saint-Pierre-de-Chandieu,69,Rhône,84,Auvergne-Rhône-Alpes
69290,ST PRIEST,69800,ST PRIEST,,45.701466556,4.94882071665,290,,Saint-Priest,Saint-Priest,69,Rhône,84,Auvergne-Rhône-Alpes
69291,ST SYMPHORIEN D OZON,69360,ST SYMPHORIEN D OZON,,45.6421326765,4.86880371042,291,,Saint-Symphorien-d'Ozon,Saint-Symphorien-d'Ozon,69,Rhône,84,Auvergne-Rhône-Alpes
69292,SATHONAY CAMP,69580,SATHONAY CAMP,,45.8257146125,4.87363022248,292,,Sathonay-Camp,Sathonay-Camp,69,Rhône,84,Auvergne-Rhône-Alpes
69293,SATHONAY VILLAGE,69580,SATHONAY VILLAGE,,45.8402514448,4.88563860144,293,,Sathonay-Village,Sathonay-Village,69,Rhône,84,Auvergne-Rhône-Alpes
69294,SEREZIN DU RHONE,69360,SEREZIN DU RHONE,,45.6275993813,4.82483713363,294,,Sérézin-du-Rhône,Sérézin-du-Rhône,69,Rhône,84,Auvergne-Rhône-Alpes
69295,SIMANDRES,69360,SIMANDRES,,45.6115676651,4.87107567478,295,,Simandres,Simandres,69,Rhône,84,Auvergne-Rhône-Alpes
69296,SOLAIZE,69360,SOLAIZE,,45.6460641774,4.83749103665,296,,Solaize,Solaize,69,Rhône,84,Auvergne-Rhône-Alpes
69297,TERNAY,69360,TERNAY,,45.6055276798,4.80759320832,297,,Ternay,Ternay,69,Rhône,84,Auvergne-Rhône-Alpes
69298,TOUSSIEU,69780,TOUSSIEU,,45.6577177504,4.98186963908,298,,Toussieu,Toussieu,69,Rhône,84,Auvergne-Rhône-Alpes
69299,COLOMBIER SAUGNIEU,69124,COLOMBIER SAUGNIEU,,45.7178013267,5.10337456661,299,,Colombier-Saugnieu,Colombier-Saugnieu,69,Rhône,84,Auvergne-Rhône-Alpes
69299,COLOMBIER SAUGNIEU,69124,COLOMBIER SAUGNIEU,SAUGNIEU,45.7178013267,5.10337456661,299,,Colombier-Saugnieu,Colombier-Saugnieu,69,Rhône,84,Auvergne-Rhône-Alpes
69299,COLOMBIER SAUGNIEU,69125,COLOMBIER SAUGNIEU,LYON ST EXUPERY AEROPORT,45.7178013267,5.10337456661,299,,Colombier-Saugnieu,Colombier-Saugnieu,69,Rhône,84,Auvergne-Rhône-Alpes
69299,COLOMBIER SAUGNIEU,69125,COLOMBIER SAUGNIEU,SAINT EXUPERY AEROPORT,45.7178013267,5.10337456661,299,,Colombier-Saugnieu,Colombier-Saugnieu,69,Rhône,84,Auvergne-Rhône-Alpes
69381,LYON 01,69001,LYON,,45.7699284397,4.82922464978,381,,Lyon 01,Lyon 01,69,Rhône,84,Auvergne-Rhône-Alpes
69382,LYON 02,69002,LYON,,45.7492560394,4.82617032549,382,,Lyon 02,Lyon 02,69,Rhône,84,Auvergne-Rhône-Alpes
69383,LYON 03,69003,LYON,,45.7533552486,4.86918522013,383,,Lyon 03,Lyon 03,69,Rhône,84,Auvergne-Rhône-Alpes
69384,LYON 04,69004,LYON,,45.7786867581,4.82396112689,384,,Lyon 04,Lyon 04,69,Rhône,84,Auvergne-Rhône-Alpes
69385,LYON 05,69005,LYON,,45.7558317849,4.8022536949,385,,Lyon 05,Lyon 05,69,Rhône,84,Auvergne-Rhône-Alpes
69386,LYON 06,69006,LYON,,45.7728507239,4.85207911437,386,,Lyon 06,Lyon 06,69,Rhône,84,Auvergne-Rhône-Alpes
69387,LYON 07,69007,LYON,,45.7334098415,4.83758495703,387,,Lyon 07,Lyon 07,69,Rhône,84,Auvergne-Rhône-Alpes
69388,LYON 08,69008,LYON,,45.7342034691,4.86935722305,388,,Lyon 08,Lyon 08,69,Rhône,84,Auvergne-Rhône-Alpes
69389,LYON 09,69009,LYON,,45.7817805887,4.80818327822,389,,Lyon 09,Lyon 09,69,Rhône,84,Auvergne-Rhône-Alpes
70001,ABELCOURT,70300,ABELCOURT,,47.7934014233,6.27774541889,1,,Abelcourt,Abelcourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70002,ABONCOURT GESINCOURT,70500,ABONCOURT GESINCOURT,,47.7683562569,5.97257332225,2,,Aboncourt-Gesincourt,Aboncourt-Gesincourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70003,ACHEY,70180,ACHEY,,47.5770070289,5.60745476371,3,,Achey,Achey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70004,ADELANS ET LE VAL DE BITHAINE,70200,ADELANS ET LE VAL DE BITHAINE,,47.7061573284,6.39427632304,4,,Adelans-et-le-Val-de-Bithaine,Adelans-et-le-Val-de-Bithaine,70,Haute-Saône,27,Bourgogne-Franche-Comté
70005,AILLEVANS,70110,AILLEVANS,,47.5901064587,6.41781812874,5,,Aillevans,Aillevans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70006,AILLEVILLERS ET LYAUMONT,70320,AILLEVILLERS ET LYAUMONT,,47.9382332806,6.33247986136,6,,Aillevillers-et-Lyaumont,Aillevillers-et-Lyaumont,70,Haute-Saône,27,Bourgogne-Franche-Comté
70007,AILLONCOURT,70300,AILLONCOURT,,47.7571786627,6.38881331969,7,,Ailloncourt,Ailloncourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70008,AINVELLE,70800,AINVELLE,,47.8479400704,6.24391333161,8,,Ainvelle,Ainvelle,70,Haute-Saône,27,Bourgogne-Franche-Comté
70009,AISEY ET RICHECOURT,70500,AISEY ET RICHECOURT,,47.8881139494,5.95536698113,9,,Aisey-et-Richecourt,Aisey-et-Richecourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70010,ALAINCOURT,70210,ALAINCOURT,,47.9462772767,6.09996609571,10,,Alaincourt,Alaincourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70011,AMAGE,70280,AMAGE,,47.8456233844,6.49802124714,11,,Amage,Amage,70,Haute-Saône,27,Bourgogne-Franche-Comté
70012,AMANCE,70160,AMANCE,,47.7905088546,6.05331685795,12,,Amance,Amance,70,Haute-Saône,27,Bourgogne-Franche-Comté
70013,AMBIEVILLERS,70210,AMBIEVILLERS,,47.9958871896,6.14035780584,13,,Ambiévillers,Ambiévillers,70,Haute-Saône,27,Bourgogne-Franche-Comté
70014,AMBLANS ET VELOTTE,70200,AMBLANS ET VELOTTE,,47.6809699822,6.41769484638,14,,Amblans-et-Velotte,Amblans-et-Velotte,70,Haute-Saône,27,Bourgogne-Franche-Comté
70015,AMONCOURT,70170,AMONCOURT,,47.7347259725,6.06263685163,15,,Amoncourt,Amoncourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70016,AMONT ET EFFRENEY,70310,AMONT ET EFFRENEY,,47.8672344964,6.56250054881,16,,Amont-et-Effreney,Amont-et-Effreney,70,Haute-Saône,27,Bourgogne-Franche-Comté
70017,ANCHENONCOURT ET CHAZEL,70210,ANCHENONCOURT ET CHAZEL,,47.861727458,6.11528633916,17,,Anchenoncourt-et-Chazel,Anchenoncourt-et-Chazel,70,Haute-Saône,27,Bourgogne-Franche-Comté
70018,ANCIER,70100,ANCIER,,47.4440990959,5.63641501518,18,,Ancier,Ancier,70,Haute-Saône,27,Bourgogne-Franche-Comté
70019,ANDELARRE,70000,ANDELARRE,,47.5902572051,6.09074936306,19,,Andelarre,Andelarre,70,Haute-Saône,27,Bourgogne-Franche-Comté
70020,ANDELARROT,70000,ANDELARROT,,47.5782409483,6.09869200788,20,,Andelarrot,Andelarrot,70,Haute-Saône,27,Bourgogne-Franche-Comté
70021,ANDORNAY,70200,ANDORNAY,,47.6586648001,6.59842406878,21,,Andornay,Andornay,70,Haute-Saône,27,Bourgogne-Franche-Comté
70022,ANGIREY,70700,ANGIREY,,47.4548843773,5.77329895236,22,,Angirey,Angirey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70023,ANJEUX,70800,ANJEUX,,47.8815041686,6.20949435555,23,,Anjeux,Anjeux,70,Haute-Saône,27,Bourgogne-Franche-Comté
70024,APREMONT,70100,APREMONT,,47.3925481381,5.52972879286,24,,Apremont,Apremont,70,Haute-Saône,27,Bourgogne-Franche-Comté
70025,ARBECEY,70120,ARBECEY,,47.7352817883,5.93210492724,25,,Arbecey,Arbecey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70026,ARC LES GRAY,70100,ARC LES GRAY,,47.4628603881,5.58172003346,26,,Arc-lès-Gray,Arc-lès-Gray,70,Haute-Saône,27,Bourgogne-Franche-Comté
70027,ARGILLIERES,70600,ARGILLIERES,,47.6646891892,5.62545925232,27,,Argillières,Argillières,70,Haute-Saône,27,Bourgogne-Franche-Comté
70028,AROZ,70360,AROZ,,47.6138842426,6.00554228377,28,,Aroz,Aroz,70,Haute-Saône,27,Bourgogne-Franche-Comté
70029,ARPENANS,70200,ARPENANS,,47.6208422399,6.41263553144,29,,Arpenans,Arpenans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70030,ARSANS,70100,ARSANS,,47.3672852842,5.63154249443,30,,Arsans,Arsans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70031,ATHESANS ETROITEFONTAINE,70110,ATHESANS ETROITEFONTAINE,,47.5942889134,6.51510882516,31,,Athesans-Étroitefontaine,Athesans-Étroitefontaine,70,Haute-Saône,27,Bourgogne-Franche-Comté
70031,ATHESANS ETROITEFONTAINE,70110,ATHESANS ETROITEFONTAINE,ETROITEFONTAINE,47.5942889134,6.51510882516,31,,Athesans-Étroitefontaine,Athesans-Étroitefontaine,70,Haute-Saône,27,Bourgogne-Franche-Comté
70032,ATTRICOURT,70100,ATTRICOURT,,47.487861891,5.41120963466,32,,Attricourt,Attricourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70035,AUGICOURT,70500,AUGICOURT,,47.7699166638,5.89160778933,35,,Augicourt,Augicourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70036,AULX LES CROMARY,70190,AULX LES CROMARY,,47.3755613472,6.09713595885,36,,Aulx-lès-Cromary,Aulx-lès-Cromary,70,Haute-Saône,27,Bourgogne-Franche-Comté
70037,AUTET,70180,AUTET,,47.545285848,5.70067460474,37,,Autet,Autet,70,Haute-Saône,27,Bourgogne-Franche-Comté
70038,AUTHOISON,70190,AUTHOISON,,47.491649936,6.14448125556,38,,Authoison,Authoison,70,Haute-Saône,27,Bourgogne-Franche-Comté
70039,AUTOREILLE,70700,AUTOREILLE,,47.370625269,5.82479172929,39,,Autoreille,Autoreille,70,Haute-Saône,27,Bourgogne-Franche-Comté
70040,AUTREY LES CERRE,70110,AUTREY LES CERRE,,47.6127923276,6.34600936361,40,,Autrey-lès-Cerre,Autrey-lès-Cerre,70,Haute-Saône,27,Bourgogne-Franche-Comté
70041,AUTREY LES GRAY,70100,AUTREY LES GRAY,,47.464744731,5.46526242316,41,,Autrey-lès-Gray,Autrey-lès-Gray,70,Haute-Saône,27,Bourgogne-Franche-Comté
70042,AUTREY LE VAY,70110,AUTREY LE VAY,,47.5364102911,6.39638774962,42,,Autrey-le-Vay,Autrey-le-Vay,70,Haute-Saône,27,Bourgogne-Franche-Comté
70043,AUVET ET LA CHAPELOTTE,70100,AUVET ET LA CHAPELOTTE,,47.5070865924,5.52591428297,43,,Auvet-et-la-Chapelotte,Auvet-et-la-Chapelotte,70,Haute-Saône,27,Bourgogne-Franche-Comté
70044,AUXON,70000,AUXON,,47.6949909987,6.1544576881,44,,Auxon,Auxon,70,Haute-Saône,27,Bourgogne-Franche-Comté
70045,AVRIGNEY VIREY,70150,AVRIGNEY VIREY,,47.3393039112,5.77571250318,45,,Avrigney-Virey,Avrigney-Virey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70045,AVRIGNEY VIREY,70150,AVRIGNEY VIREY,VIREY,47.3393039112,5.77571250318,45,,Avrigney-Virey,Avrigney-Virey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70046,LES AYNANS,70200,LES AYNANS,,47.6183175602,6.45040746751,46,Les,Aynans,Les Aynans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70047,BAIGNES,70000,BAIGNES,,47.5799666192,6.04782245584,47,,Baignes,Baignes,70,Haute-Saône,27,Bourgogne-Franche-Comté
70048,BARD LES PESMES,70140,BARD LES PESMES,,47.2781983751,5.62760056211,48,,Bard-lès-Pesmes,Bard-lès-Pesmes,70,Haute-Saône,27,Bourgogne-Franche-Comté
70049,BARGES,70500,BARGES,,47.8703689759,5.84022865236,49,,Barges,Barges,70,Haute-Saône,27,Bourgogne-Franche-Comté
70050,LA BARRE,70190,LA BARRE,,47.4076616681,6.17035977753,50,La,Barre,La Barre,70,Haute-Saône,27,Bourgogne-Franche-Comté
70051,LA BASSE VAIVRE,70210,LA BASSE VAIVRE,,47.9478481115,6.05472696195,51,La,Basse-Vaivre,La Basse-Vaivre,70,Haute-Saône,27,Bourgogne-Franche-Comté
70052,BASSIGNEY,70800,BASSIGNEY,,47.8246954089,6.18322387163,52,,Bassigney,Bassigney,70,Haute-Saône,27,Bourgogne-Franche-Comté
70053,LES BATIES,70130,LES BATIES,,47.502432547,5.8956755541,53,Les,Bâties,Les Bâties,70,Haute-Saône,27,Bourgogne-Franche-Comté
70054,BATTRANS,70100,BATTRANS,,47.427310451,5.64228705481,54,,Battrans,Battrans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70055,BAUDONCOURT,70300,BAUDONCOURT,,47.7753645972,6.34425326102,55,,Baudoncourt,Baudoncourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70056,BAULAY,70160,BAULAY,,47.7875044497,6.01577984949,56,,Baulay,Baulay,70,Haute-Saône,27,Bourgogne-Franche-Comté
70057,BAY,70150,BAY,,47.2949169837,5.71604636677,57,,Bay,Bay,70,Haute-Saône,27,Bourgogne-Franche-Comté
70058,BEAUJEU ET QUITTEUR,70100,BEAUJEU ET QUITTEUR,,47.4986137613,5.69348817129,58,,Beaujeu-Saint-Vallier-Pierrejux-et-Quitteur,Beaujeu-Saint-Vallier-Pierrejux-et-Quitteur,70,Haute-Saône,27,Bourgogne-Franche-Comté
70058,BEAUJEU ET QUITTEUR,70100,BEAUJEU ET QUITTEUR,QUITTEUR,47.4986137613,5.69348817129,58,,Beaujeu-Saint-Vallier-Pierrejux-et-Quitteur,Beaujeu-Saint-Vallier-Pierrejux-et-Quitteur,70,Haute-Saône,27,Bourgogne-Franche-Comté
70059,BEAUMOTTE AUBERTANS,70190,BEAUMOTTE AUBERTANS,,47.433936502,6.16129269067,59,,Beaumotte-Aubertans,Beaumotte-Aubertans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70059,BEAUMOTTE AUBERTANS,70190,BEAUMOTTE AUBERTANS,AUBERTANS,47.433936502,6.16129269067,59,,Beaumotte-Aubertans,Beaumotte-Aubertans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70060,BEAUMOTTE LES PIN,70150,BEAUMOTTE LES PIN,,47.3184487342,5.83208927383,60,,Beaumotte-lès-Pin,Beaumotte-lès-Pin,70,Haute-Saône,27,Bourgogne-Franche-Comté
70061,BELFAHY,70290,BELFAHY,,47.7864934066,6.73204100977,61,,Belfahy,Belfahy,70,Haute-Saône,27,Bourgogne-Franche-Comté
70062,BELMONT,70270,BELMONT,,47.7810349094,6.49795396276,62,,Belmont,Belmont,70,Haute-Saône,27,Bourgogne-Franche-Comté
70063,BELONCHAMP,70270,BELONCHAMP,,47.7735452568,6.60867151106,63,,Belonchamp,Belonchamp,70,Haute-Saône,27,Bourgogne-Franche-Comté
70064,BELVERNE,70400,BELVERNE,,47.6271373135,6.6554261048,64,,Belverne,Belverne,70,Haute-Saône,27,Bourgogne-Franche-Comté
70065,BESNANS,70230,BESNANS,,47.4498117838,6.25184787392,65,,Besnans,Besnans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70066,BETAUCOURT,70500,BETAUCOURT,,47.8654216499,5.91855303569,66,,Betaucourt,Betaucourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70067,BETONCOURT LES BROTTE,70300,BETONCOURT LES BROTTE,,47.7376750192,6.34326141895,67,,Betoncourt-lès-Brotte,Betoncourt-lès-Brotte,70,Haute-Saône,27,Bourgogne-Franche-Comté
70069,BETONCOURT ST PANCRAS,70210,BETONCOURT ST PANCRAS,,47.9177842329,6.18204569408,69,,Betoncourt-Saint-Pancras,Betoncourt-Saint-Pancras,70,Haute-Saône,27,Bourgogne-Franche-Comté
70070,BETONCOURT SUR MANCE,70500,BETONCOURT SUR MANCE,,47.8378409607,5.75511684653,70,,Betoncourt-sur-Mance,Betoncourt-sur-Mance,70,Haute-Saône,27,Bourgogne-Franche-Comté
70071,BEULOTTE ST LAURENT,70310,BEULOTTE ST LAURENT,,47.8688160594,6.67305784472,71,,Beulotte-Saint-Laurent,Beulotte-Saint-Laurent,70,Haute-Saône,27,Bourgogne-Franche-Comté
70072,BEVEUGE,70110,BEVEUGE,,47.5598685555,6.49132432795,72,,Beveuge,Beveuge,70,Haute-Saône,27,Bourgogne-Franche-Comté
70074,BLONDEFONTAINE,70500,BLONDEFONTAINE,,47.8855876532,5.87130104074,74,,Blondefontaine,Blondefontaine,70,Haute-Saône,27,Bourgogne-Franche-Comté
70075,BONBOILLON,70150,BONBOILLON,,47.3414290252,5.69622608772,75,,Bonboillon,Bonboillon,70,Haute-Saône,27,Bourgogne-Franche-Comté
70076,BONNEVENT VELLOREILLE,70700,BONNEVENT VELLOREILLE,,47.3937756161,5.9310298649,76,,Bonnevent-Velloreille,Bonnevent-Velloreille,70,Haute-Saône,27,Bourgogne-Franche-Comté
70077,BOREY,70110,BOREY,,47.5806974157,6.35176301516,77,,Borey,Borey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70078,BOUGEY,70500,BOUGEY,,47.783385036,5.86240543155,78,,Bougey,Bougey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70079,BOUGNON,70170,BOUGNON,,47.6948892117,6.10776977153,79,,Bougnon,Bougnon,70,Haute-Saône,27,Bourgogne-Franche-Comté
70080,BOUHANS ET FEURG,70100,BOUHANS ET FEURG,,47.4729092139,5.51046991531,80,,Bouhans-et-Feurg,Bouhans-et-Feurg,70,Haute-Saône,27,Bourgogne-Franche-Comté
70081,BOUHANS LES LURE,70200,BOUHANS LES LURE,,47.6962715281,6.43809662035,81,,Bouhans-lès-Lure,Bouhans-lès-Lure,70,Haute-Saône,27,Bourgogne-Franche-Comté
70082,BOUHANS LES MONTBOZON,70230,BOUHANS LES MONTBOZON,,47.4858934785,6.28045727501,82,,Bouhans-lès-Montbozon,Bouhans-lès-Montbozon,70,Haute-Saône,27,Bourgogne-Franche-Comté
70083,BOULIGNEY,70800,BOULIGNEY,,47.911701027,6.2482203935,83,,Bouligney,Bouligney,70,Haute-Saône,27,Bourgogne-Franche-Comté
70084,BOULOT,70190,BOULOT,,47.3549256777,5.9599297677,84,,Boulot,Boulot,70,Haute-Saône,27,Bourgogne-Franche-Comté
70085,BOULT,70190,BOULT,,47.3845989346,6.00252108883,85,,Boult,Boult,70,Haute-Saône,27,Bourgogne-Franche-Comté
70086,BOURBEVELLE,70500,BOURBEVELLE,,47.9147406842,5.94531974119,86,,Bourbévelle,Bourbévelle,70,Haute-Saône,27,Bourgogne-Franche-Comté
70087,BOURGUIGNON LES CONFLANS,70800,BOURGUIGNON LES CONFLANS,,47.8155894506,6.15743320592,87,,Bourguignon-lès-Conflans,Bourguignon-lès-Conflans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70088,BOURGUIGNON LES LA CHARITE,70190,BOURGUIGNON LES LA CHARITE,,47.5075942378,5.96493598181,88,,Bourguignon-lès-la-Charité,Bourguignon-lès-la-Charité,70,Haute-Saône,27,Bourgogne-Franche-Comté
70089,BOURGUIGNON LES MOREY,70120,BOURGUIGNON LES MOREY,,47.7073928525,5.70735229173,89,,Bourguignon-lès-Morey,Bourguignon-lès-Morey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70090,BOURSIERES,70000,BOURSIERES,,47.6143000555,6.03724175593,90,,Boursières,Boursières,70,Haute-Saône,27,Bourgogne-Franche-Comté
70091,BOUSSERAUCOURT,70500,BOUSSERAUCOURT,,47.9631646944,5.93606571661,91,,Bousseraucourt,Bousseraucourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70092,BRESILLEY,70140,BRESILLEY,,47.261991269,5.63291616251,92,,Bresilley,Bresilley,70,Haute-Saône,27,Bourgogne-Franche-Comté
70093,BREUCHES,70300,BREUCHES,,47.7994742775,6.32850280633,93,,Breuches,Breuches,70,Haute-Saône,27,Bourgogne-Franche-Comté
70094,BREUCHOTTE,70280,BREUCHOTTE,,47.8306439459,6.46183176358,94,,Breuchotte,Breuchotte,70,Haute-Saône,27,Bourgogne-Franche-Comté
70095,BREUREY LES FAVERNEY,70160,BREUREY LES FAVERNEY,,47.7448155296,6.14540506475,95,,Breurey-lès-Faverney,Breurey-lès-Faverney,70,Haute-Saône,27,Bourgogne-Franche-Comté
70096,BREVILLIERS,70400,BREVILLIERS,,47.5786423606,6.79644212349,96,,Brevilliers,Brevilliers,70,Haute-Saône,27,Bourgogne-Franche-Comté
70097,BRIAUCOURT,70800,BRIAUCOURT,,47.8205372453,6.24665609109,97,,Briaucourt,Briaucourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70098,BROTTE LES LUXEUIL,70300,BROTTE LES LUXEUIL,,47.7503614149,6.34512671998,98,,Brotte-lès-Luxeuil,Brotte-lès-Luxeuil,70,Haute-Saône,27,Bourgogne-Franche-Comté
70099,BROTTE LES RAY,70180,BROTTE LES RAY,,47.6070557473,5.73659108691,99,,Brotte-lès-Ray,Brotte-lès-Ray,70,Haute-Saône,27,Bourgogne-Franche-Comté
70100,BROYE LES LOUPS ET VERFONTAINE,70100,BROYE LES LOUPS ET VERFONTAINE,,47.4591753719,5.43034570007,100,,Broye-les-Loups-et-Verfontaine,Broye-les-Loups-et-Verfontaine,70,Haute-Saône,27,Bourgogne-Franche-Comté
70101,BROYE AUBIGNEY MONTSEUGNY,70140,BROYE AUBIGNEY MONTSEUGNY,,47.3305692734,5.5187191763,101,,Broye-Aubigney-Montseugny,Broye-Aubigney-Montseugny,70,Haute-Saône,27,Bourgogne-Franche-Comté
70101,BROYE AUBIGNEY MONTSEUGNY,70140,BROYE AUBIGNEY MONTSEUGNY,AUBIGNEY,47.3305692734,5.5187191763,101,,Broye-Aubigney-Montseugny,Broye-Aubigney-Montseugny,70,Haute-Saône,27,Bourgogne-Franche-Comté
70101,BROYE AUBIGNEY MONTSEUGNY,70140,BROYE AUBIGNEY MONTSEUGNY,MONTSEUGNY,47.3305692734,5.5187191763,101,,Broye-Aubigney-Montseugny,Broye-Aubigney-Montseugny,70,Haute-Saône,27,Bourgogne-Franche-Comté
70102,BRUSSEY,70150,BRUSSEY,,47.3070872023,5.80873884228,102,,Brussey,Brussey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70103,LA BRUYERE,70280,LA BRUYERE,,47.8126908026,6.46989789296,103,La,Bruyère,La Bruyère,70,Haute-Saône,27,Bourgogne-Franche-Comté
70104,BUCEY LES GY,70700,BUCEY LES GY,,47.4054642999,5.86034681333,104,,Bucey-lès-Gy,Bucey-lès-Gy,70,Haute-Saône,27,Bourgogne-Franche-Comté
70105,BUCEY LES TRAVES,70360,BUCEY LES TRAVES,,47.6291367702,5.98525127967,105,,Bucey-lès-Traves,Bucey-lès-Traves,70,Haute-Saône,27,Bourgogne-Franche-Comté
70106,BUFFIGNECOURT,70500,BUFFIGNECOURT,,47.8133675755,6.02218860068,106,,Buffignécourt,Buffignécourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70107,BUSSIERES,70190,BUSSIERES,,47.3440809004,5.98460824617,107,,Bussières,Bussières,70,Haute-Saône,27,Bourgogne-Franche-Comté
70109,BUTHIERS,70190,BUTHIERS,,47.3568392049,6.03220137434,109,,Buthiers,Buthiers,70,Haute-Saône,27,Bourgogne-Franche-Comté
70111,CALMOUTIER,70240,CALMOUTIER,,47.6443877301,6.28291281829,111,,Calmoutier,Calmoutier,70,Haute-Saône,27,Bourgogne-Franche-Comté
70112,CEMBOING,70500,CEMBOING,,47.8432815911,5.85414917354,112,,Cemboing,Cemboing,70,Haute-Saône,27,Bourgogne-Franche-Comté
70113,CENANS,70230,CENANS,,47.4328780684,6.18879786969,113,,Cenans,Cenans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70114,CENDRECOURT,70500,CENDRECOURT,,47.8396085675,5.93861883331,114,,Cendrecourt,Cendrecourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70115,CERRE LES NOROY,70000,CERRE LES NOROY,,47.5876572112,6.31727829478,115,,Cerre-lès-Noroy,Cerre-lès-Noroy,70,Haute-Saône,27,Bourgogne-Franche-Comté
70116,CHAGEY,70400,CHAGEY,,47.6209267815,6.7351701305,116,,Chagey,Chagey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70117,CHALONVILLARS,70400,CHALONVILLARS,,47.6376239575,6.77677655233,117,,Châlonvillars,Châlonvillars,70,Haute-Saône,27,Bourgogne-Franche-Comté
70118,CHAMBORNAY LES BELLEVAUX,70190,CHAMBORNAY LES BELLEVAUX,,47.3958807638,6.10214510229,118,,Chambornay-lès-Bellevaux,Chambornay-lès-Bellevaux,70,Haute-Saône,27,Bourgogne-Franche-Comté
70119,CHAMBORNAY LES PIN,70150,CHAMBORNAY LES PIN,,47.3392690795,5.91122430181,119,,Chambornay-lès-Pin,Chambornay-lès-Pin,70,Haute-Saône,27,Bourgogne-Franche-Comté
70120,CHAMPAGNEY,70290,CHAMPAGNEY,,47.6950851655,6.68822303825,120,,Champagney,Champagney,70,Haute-Saône,27,Bourgogne-Franche-Comté
70121,CHAMPEY,70400,CHAMPEY,,47.5906494715,6.67908440422,121,,Champey,Champey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70122,CHAMPLITTE,70600,CHAMPLITTE,,47.6322292317,5.51329036822,122,,Champlitte,Champlitte,70,Haute-Saône,27,Bourgogne-Franche-Comté
70122,CHAMPLITTE,70600,CHAMPLITTE,CHAMPLITTE LA VILLE,47.6322292317,5.51329036822,122,,Champlitte,Champlitte,70,Haute-Saône,27,Bourgogne-Franche-Comté
70122,CHAMPLITTE,70600,CHAMPLITTE,LEFFOND,47.6322292317,5.51329036822,122,,Champlitte,Champlitte,70,Haute-Saône,27,Bourgogne-Franche-Comté
70122,CHAMPLITTE,70600,CHAMPLITTE,MARGILLEY,47.6322292317,5.51329036822,122,,Champlitte,Champlitte,70,Haute-Saône,27,Bourgogne-Franche-Comté
70122,CHAMPLITTE,70600,CHAMPLITTE,MONTARLOT LES CHAMPLITTE,47.6322292317,5.51329036822,122,,Champlitte,Champlitte,70,Haute-Saône,27,Bourgogne-Franche-Comté
70122,CHAMPLITTE,70600,CHAMPLITTE,NEUVELLE LES CHAMPLITTE,47.6322292317,5.51329036822,122,,Champlitte,Champlitte,70,Haute-Saône,27,Bourgogne-Franche-Comté
70124,CHAMPTONNAY,70100,CHAMPTONNAY,,47.3758996781,5.6613436663,124,,Champtonnay,Champtonnay,70,Haute-Saône,27,Bourgogne-Franche-Comté
70125,CHAMPVANS,70100,CHAMPVANS,,47.3966029072,5.5921235219,125,,Champvans,Champvans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70126,CHANCEY,70140,CHANCEY,,47.3241175879,5.67813657788,126,,Chancey,Chancey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70127,CHANTES,70360,CHANTES,,47.6237829598,5.92693390957,127,,Chantes,Chantes,70,Haute-Saône,27,Bourgogne-Franche-Comté
70128,LA CHAPELLE LES LUXEUIL,70300,LA CHAPELLE LES LUXEUIL,,47.7738963154,6.38097231925,128,La,Chapelle-lès-Luxeuil,La Chapelle-lès-Luxeuil,70,Haute-Saône,27,Bourgogne-Franche-Comté
70129,LA CHAPELLE ST QUILLAIN,70700,LA CHAPELLE ST QUILLAIN,,47.4849082383,5.81725428776,129,La,Chapelle-Saint-Quillain,La Chapelle-Saint-Quillain,70,Haute-Saône,27,Bourgogne-Franche-Comté
70130,CHARCENNE,70700,CHARCENNE,,47.368580858,5.777516428,130,,Charcenne,Charcenne,70,Haute-Saône,27,Bourgogne-Franche-Comté
70132,CHARGEY LES GRAY,70100,CHARGEY LES GRAY,,47.4928980658,5.57884831818,132,,Chargey-lès-Gray,Chargey-lès-Gray,70,Haute-Saône,27,Bourgogne-Franche-Comté
70133,CHARGEY LES PORT,70170,CHARGEY LES PORT,,47.7285168073,5.99727162515,133,,Chargey-lès-Port,Chargey-lès-Port,70,Haute-Saône,27,Bourgogne-Franche-Comté
70134,CHARIEZ,70000,CHARIEZ,,47.6196823197,6.07911059403,134,,Chariez,Chariez,70,Haute-Saône,27,Bourgogne-Franche-Comté
70135,CHARMES ST VALBERT,70120,CHARMES ST VALBERT,,47.7316041443,5.70856900371,135,,Charmes-Saint-Valbert,Charmes-Saint-Valbert,70,Haute-Saône,27,Bourgogne-Franche-Comté
70136,CHARMOILLE,70000,CHARMOILLE,,47.6719755465,6.11198681007,136,,Charmoille,Charmoille,70,Haute-Saône,27,Bourgogne-Franche-Comté
70137,CHASSEY LES MONTBOZON,70230,CHASSEY LES MONTBOZON,,47.5237818332,6.32649306031,137,,Chassey-lès-Montbozon,Chassey-lès-Montbozon,70,Haute-Saône,27,Bourgogne-Franche-Comté
70138,CHASSEY LES SCEY,70360,CHASSEY LES SCEY,,47.643695982,5.98609358914,138,,Chassey-lès-Scey,Chassey-lès-Scey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70140,CHATENEY,70240,CHATENEY,,47.6974623495,6.31908566249,140,,Châteney,Châteney,70,Haute-Saône,27,Bourgogne-Franche-Comté
70141,CHATENOIS,70240,CHATENOIS,,47.6842959157,6.31922142817,141,,Châtenois,Châtenois,70,Haute-Saône,27,Bourgogne-Franche-Comté
70142,CHAUMERCENNE,70140,CHAUMERCENNE,,47.3018644976,5.6263734239,142,,Chaumercenne,Chaumercenne,70,Haute-Saône,27,Bourgogne-Franche-Comté
70143,CHAUVIREY LE CHATEL,70500,CHAUVIREY LE CHATEL,,47.7868240584,5.74359892112,143,,Chauvirey-le-Châtel,Chauvirey-le-Châtel,70,Haute-Saône,27,Bourgogne-Franche-Comté
70144,CHAUVIREY LE VIEIL,70500,CHAUVIREY LE VIEIL,,47.7811083349,5.75924398102,144,,Chauvirey-le-Vieil,Chauvirey-le-Vieil,70,Haute-Saône,27,Bourgogne-Franche-Comté
70145,CHAUX LA LOTIERE,70190,CHAUX LA LOTIERE,,47.3822996917,5.96780533315,145,,Chaux-la-Lotière,Chaux-la-Lotière,70,Haute-Saône,27,Bourgogne-Franche-Comté
70146,CHAUX LES PORT,70170,CHAUX LES PORT,,47.7164049737,6.0457583117,146,,Chaux-lès-Port,Chaux-lès-Port,70,Haute-Saône,27,Bourgogne-Franche-Comté
70147,CHAVANNE,70400,CHAVANNE,,47.5588510857,6.65836633701,147,,Chavanne,Chavanne,70,Haute-Saône,27,Bourgogne-Franche-Comté
70148,CHEMILLY,70360,CHEMILLY,,47.6416852951,6.0177515817,148,,Chemilly,Chemilly,70,Haute-Saône,27,Bourgogne-Franche-Comté
70149,CHENEBIER,70400,CHENEBIER,,47.6435305659,6.70723411638,149,,Chenebier,Chenebier,70,Haute-Saône,27,Bourgogne-Franche-Comté
70150,CHENEVREY ET MOROGNE,70150,CHENEVREY ET MOROGNE,,47.2806388545,5.73662541628,150,,Chenevrey-et-Morogne,Chenevrey-et-Morogne,70,Haute-Saône,27,Bourgogne-Franche-Comté
70151,CHEVIGNEY,70140,CHEVIGNEY,,47.3300952571,5.58746755741,151,,Chevigney,Chevigney,70,Haute-Saône,27,Bourgogne-Franche-Comté
70152,CHOYE,70700,CHOYE,,47.3924629744,5.75753300176,152,,Choye,Choye,70,Haute-Saône,27,Bourgogne-Franche-Comté
70153,CINTREY,70120,CINTREY,,47.7535156501,5.75205214351,153,,Cintrey,Cintrey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70154,CIREY,70190,CIREY,,47.4130463619,6.13282949108,154,,Cirey,Cirey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70155,CITERS,70300,CITERS,,47.7549976504,6.41938709538,155,,Citers,Citers,70,Haute-Saône,27,Bourgogne-Franche-Comté
70156,CITEY,70700,CITEY,,47.4397388319,5.78910667479,156,,Citey,Citey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70157,CLAIREGOUTTE,70200,CLAIREGOUTTE,,47.6628053162,6.63860014681,157,,Clairegoutte,Clairegoutte,70,Haute-Saône,27,Bourgogne-Franche-Comté
70158,CLANS,70000,CLANS,,47.5990510677,6.0303617469,158,,Clans,Clans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70159,COGNIERES,70230,COGNIERES,,47.4990115094,6.28635310536,159,,Cognières,Cognières,70,Haute-Saône,27,Bourgogne-Franche-Comté
70160,COISEVAUX,70400,COISEVAUX,,47.5880541275,6.70710563724,160,,Coisevaux,Coisevaux,70,Haute-Saône,27,Bourgogne-Franche-Comté
70162,COLOMBE LES VESOUL,70000,COLOMBE LES VESOUL,,47.6121777534,6.22089003773,162,,Colombe-lès-Vesoul,Colombe-lès-Vesoul,70,Haute-Saône,27,Bourgogne-Franche-Comté
70163,COLOMBIER,70000,COLOMBIER,,47.6697895456,6.21883495528,163,,Colombier,Colombier,70,Haute-Saône,27,Bourgogne-Franche-Comté
70164,COLOMBOTTE,70240,COLOMBOTTE,,47.66356733,6.28784995728,164,,Colombotte,Colombotte,70,Haute-Saône,27,Bourgogne-Franche-Comté
70165,COMBEAUFONTAINE,70120,COMBEAUFONTAINE,,47.7097115215,5.89708044374,165,,Combeaufontaine,Combeaufontaine,70,Haute-Saône,27,Bourgogne-Franche-Comté
70166,COMBERJON,70000,COMBERJON,,47.6446228268,6.20332293485,166,,Comberjon,Comberjon,70,Haute-Saône,27,Bourgogne-Franche-Comté
70167,CONFLANDEY,70170,CONFLANDEY,,47.732302519,6.03515771131,167,,Conflandey,Conflandey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70168,CONFLANS SUR LANTERNE,70800,CONFLANS SUR LANTERNE,,47.8103436932,6.2138139991,168,,Conflans-sur-Lanterne,Conflans-sur-Lanterne,70,Haute-Saône,27,Bourgogne-Franche-Comté
70169,CONFRACOURT,70120,CONFRACOURT,,47.6738636873,5.88867029246,169,,Confracourt,Confracourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70170,CONTREGLISE,70160,CONTREGLISE,,47.8413661613,6.02951411465,170,,Contréglise,Contréglise,70,Haute-Saône,27,Bourgogne-Franche-Comté
70171,CORBENAY,70320,CORBENAY,,47.8958414477,6.33319148205,171,,Corbenay,Corbenay,70,Haute-Saône,27,Bourgogne-Franche-Comté
70172,LA CORBIERE,70300,LA CORBIERE,,47.7930040565,6.48605188097,172,La,Corbière,La Corbière,70,Haute-Saône,27,Bourgogne-Franche-Comté
70174,CORDONNET,70190,CORDONNET,,47.4186854695,5.96327126972,174,,Cordonnet,Cordonnet,70,Haute-Saône,27,Bourgogne-Franche-Comté
70175,CORNOT,70120,CORNOT,,47.6902160288,5.83898818583,175,,Cornot,Cornot,70,Haute-Saône,27,Bourgogne-Franche-Comté
70176,CORRAVILLERS,70310,CORRAVILLERS,,47.8871053896,6.6423540417,176,,Corravillers,Corravillers,70,Haute-Saône,27,Bourgogne-Franche-Comté
70177,CORRE,70500,CORRE,,47.9178171926,5.99346216699,177,,Corre,Corre,70,Haute-Saône,27,Bourgogne-Franche-Comté
70178,LA COTE,70200,LA COTE,,47.6890199051,6.57292150437,178,La,Côte,La Côte,70,Haute-Saône,27,Bourgogne-Franche-Comté
70179,COULEVON,70000,COULEVON,,47.6487139792,6.18039874314,179,,Coulevon,Coulevon,70,Haute-Saône,27,Bourgogne-Franche-Comté
70180,COURCHATON,70110,COURCHATON,,47.5135728271,6.5542014435,180,,Courchaton,Courchaton,70,Haute-Saône,27,Bourgogne-Franche-Comté
70181,COURCUIRE,70150,COURCUIRE,,47.3474181199,5.82794656469,181,,Courcuire,Courcuire,70,Haute-Saône,27,Bourgogne-Franche-Comté
70182,COURMONT,70400,COURMONT,,47.6119425531,6.64355841755,182,,Courmont,Courmont,70,Haute-Saône,27,Bourgogne-Franche-Comté
70183,COURTESOULT ET GATEY,70600,COURTESOULT ET GATEY,,47.6090292846,5.61236110183,183,,Courtesoult-et-Gatey,Courtesoult-et-Gatey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70184,COUTHENANS,70400,COUTHENANS,,47.5901202306,6.72594262625,184,,Couthenans,Couthenans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70185,CRESANCEY,70100,CRESANCEY,,47.3995870288,5.64879435364,185,,Cresancey,Cresancey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70186,LA CREUSE,70240,LA CREUSE,,47.6824306767,6.3491717627,186,La,Creuse,La Creuse,70,Haute-Saône,27,Bourgogne-Franche-Comté
70187,CREVANS ET LA CHAPELLE LES GRANGES,70400,CREVANS LA CHAPELLE LES GRANGES,,47.5491003203,6.593684558,187,,Crevans-et-la-Chapelle-lès-Granges,Crevans-et-la-Chapelle-lès-Granges,70,Haute-Saône,27,Bourgogne-Franche-Comté
70188,CREVENEY,70240,CREVENEY,,47.6806121755,6.29286368281,188,,Creveney,Creveney,70,Haute-Saône,27,Bourgogne-Franche-Comté
70189,CROMARY,70190,CROMARY,,47.3651266059,6.06864570113,189,,Cromary,Cromary,70,Haute-Saône,27,Bourgogne-Franche-Comté
70190,CUBRY LES FAVERNEY,70160,CUBRY LES FAVERNEY,,47.8157221284,6.13346195549,190,,Cubry-lès-Faverney,Cubry-lès-Faverney,70,Haute-Saône,27,Bourgogne-Franche-Comté
70192,CUGNEY,70700,CUGNEY,,47.3643480364,5.71977368998,192,,Cugney,Cugney,70,Haute-Saône,27,Bourgogne-Franche-Comté
70193,CULT,70150,CULT,,47.3144210178,5.74365387087,193,,Cult,Cult,70,Haute-Saône,27,Bourgogne-Franche-Comté
70194,CUVE,70800,CUVE,,47.9108609255,6.22091242298,194,,Cuve,Cuve,70,Haute-Saône,27,Bourgogne-Franche-Comté
70195,DAMBENOIT LES COLOMBE,70200,DAMBENOIT LES COLOMBE,,47.7225685602,6.3779909866,195,,Dambenoît-lès-Colombe,Dambenoît-lès-Colombe,70,Haute-Saône,27,Bourgogne-Franche-Comté
70195,DAMBENOIT LES COLOMBE,70200,DAMBENOIT LES COLOMBE,COLOMBE LES BITHAINE,47.7225685602,6.3779909866,195,,Dambenoît-lès-Colombe,Dambenoît-lès-Colombe,70,Haute-Saône,27,Bourgogne-Franche-Comté
70196,DAMPIERRE LES CONFLANS,70800,DAMPIERRE LES CONFLANS,,47.8446617435,6.17930368282,196,,Dampierre-lès-Conflans,Dampierre-lès-Conflans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70197,DAMPIERRE SUR LINOTTE,70230,DAMPIERRE SUR LINOTTE,,47.5328760062,6.24208585386,197,,Dampierre-sur-Linotte,Dampierre-sur-Linotte,70,Haute-Saône,27,Bourgogne-Franche-Comté
70197,DAMPIERRE SUR LINOTTE,70230,DAMPIERRE SUR LINOTTE,PRESLE,47.5328760062,6.24208585386,197,,Dampierre-sur-Linotte,Dampierre-sur-Linotte,70,Haute-Saône,27,Bourgogne-Franche-Comté
70197,DAMPIERRE SUR LINOTTE,70230,DAMPIERRE SUR LINOTTE,TREVEY,47.5328760062,6.24208585386,197,,Dampierre-sur-Linotte,Dampierre-sur-Linotte,70,Haute-Saône,27,Bourgogne-Franche-Comté
70198,DAMPIERRE SUR SALON,70180,DAMPIERRE SUR SALON,,47.5720448624,5.67645740995,198,,Dampierre-sur-Salon,Dampierre-sur-Salon,70,Haute-Saône,27,Bourgogne-Franche-Comté
70199,DAMPVALLEY LES COLOMBE,70000,DAMPVALLEY LES COLOMBE,,47.6250131042,6.24487061258,199,,Dampvalley-lès-Colombe,Dampvalley-lès-Colombe,70,Haute-Saône,27,Bourgogne-Franche-Comté
70200,DAMPVALLEY ST PANCRAS,70210,DAMPVALLEY ST PANCRAS,,47.9127848555,6.20068326227,200,,Dampvalley-Saint-Pancras,Dampvalley-Saint-Pancras,70,Haute-Saône,27,Bourgogne-Franche-Comté
70201,DELAIN,70180,DELAIN,,47.592217463,5.64526188682,201,,Delain,Delain,70,Haute-Saône,27,Bourgogne-Franche-Comté
70202,DEMANGEVELLE,70210,DEMANGEVELLE,,47.9305643607,6.03941576973,202,,Demangevelle,Demangevelle,70,Haute-Saône,27,Bourgogne-Franche-Comté
70203,LA DEMIE,70000,LA DEMIE,,47.5825372147,6.17113589941,203,La,Demie,La Demie,70,Haute-Saône,27,Bourgogne-Franche-Comté
70204,DENEVRE,70180,DENEVRE,,47.5557164652,5.64493853194,204,,Denèvre,Denèvre,70,Haute-Saône,27,Bourgogne-Franche-Comté
70205,ECHAVANNE,70400,ECHAVANNE,,47.6577226817,6.72413298347,205,,Échavanne,Échavanne,70,Haute-Saône,27,Bourgogne-Franche-Comté
70206,ECHENANS SOUS MONT VAUDOIS,70400,ECHENANS SOUS MONT VAUDOIS,,47.6045802669,6.76783910472,206,,Échenans-sous-Mont-Vaudois,Échenans-sous-Mont-Vaudois,70,Haute-Saône,27,Bourgogne-Franche-Comté
70207,ECHENOZ LA MELINE,70000,ECHENOZ LA MELINE,,47.593506841,6.13717389546,207,,Échenoz-la-Méline,Échenoz-la-Méline,70,Haute-Saône,27,Bourgogne-Franche-Comté
70208,ECHENOZ LE SEC,70000,ECHENOZ LE SEC,,47.5278583109,6.12143583046,208,,Échenoz-le-Sec,Échenoz-le-Sec,70,Haute-Saône,27,Bourgogne-Franche-Comté
70210,ECROMAGNY,70270,ECROMAGNY,,47.7931932241,6.56449460002,210,,Écromagny,Écromagny,70,Haute-Saône,27,Bourgogne-Franche-Comté
70211,ECUELLE,70600,ECUELLE,,47.5524124907,5.54810358865,211,,Écuelle,Écuelle,70,Haute-Saône,27,Bourgogne-Franche-Comté
70213,EHUNS,70300,EHUNS,,47.7656859341,6.30499154261,213,,Éhuns,Éhuns,70,Haute-Saône,27,Bourgogne-Franche-Comté
70214,EQUEVILLEY,70160,EQUEVILLEY,,47.7814086958,6.18035892728,214,,Équevilley,Équevilley,70,Haute-Saône,27,Bourgogne-Franche-Comté
70215,ERREVET,70400,ERREVET,,47.6872396262,6.77009884237,215,,Errevet,Errevet,70,Haute-Saône,27,Bourgogne-Franche-Comté
70216,ESBOZ BREST,70300,ESBOZ BREST,,47.8034880472,6.44017701337,216,,Esboz-Brest,Esboz-Brest,70,Haute-Saône,27,Bourgogne-Franche-Comté
70217,ESMOULIERES,70310,ESMOULIERES,,47.8525224657,6.616387681,217,,Esmoulières,Esmoulières,70,Haute-Saône,27,Bourgogne-Franche-Comté
70218,ESMOULINS,70100,ESMOULINS,,47.4115991244,5.55921608276,218,,Esmoulins,Esmoulins,70,Haute-Saône,27,Bourgogne-Franche-Comté
70219,ESPRELS,70110,ESPRELS,,47.5427842228,6.35948828395,219,,Esprels,Esprels,70,Haute-Saône,27,Bourgogne-Franche-Comté
70220,ESSERTENNE ET CECEY,70100,ESSERTENNE ET CECEY,,47.4004565715,5.46661383685,220,,Essertenne-et-Cecey,Essertenne-et-Cecey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70221,ETOBON,70400,ETOBON,,47.6435035631,6.67741473141,221,,Étobon,Étobon,70,Haute-Saône,27,Bourgogne-Franche-Comté
70222,ETRELLES ET LA MONTBLEUSE,70700,ETRELLES ET LA MONTBLEUSE,,47.4743685356,5.85585221186,222,,Étrelles-et-la-Montbleuse,Étrelles-et-la-Montbleuse,70,Haute-Saône,27,Bourgogne-Franche-Comté
70224,ETUZ,70150,ETUZ,,47.3581234109,5.93622736859,224,,Étuz,Étuz,70,Haute-Saône,27,Bourgogne-Franche-Comté
70225,FAHY LES AUTREY,70100,FAHY LES AUTREY,,47.5121531962,5.47718136994,225,,Fahy-lès-Autrey,Fahy-lès-Autrey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70226,FALLON,70110,FALLON,,47.5077912933,6.47671768345,226,,Fallon,Fallon,70,Haute-Saône,27,Bourgogne-Franche-Comté
70227,FAUCOGNEY ET LA MER,70310,FAUCOGNEY ET LA MER,,47.8319641353,6.5834328372,227,,Faucogney-et-la-Mer,Faucogney-et-la-Mer,70,Haute-Saône,27,Bourgogne-Franche-Comté
70228,FAVERNEY,70160,FAVERNEY,,47.7661040283,6.07998850446,228,,Faverney,Faverney,70,Haute-Saône,27,Bourgogne-Franche-Comté
70228,FAVERNEY,70160,FAVERNEY,PORT D ATELIER,47.7661040283,6.07998850446,228,,Faverney,Faverney,70,Haute-Saône,27,Bourgogne-Franche-Comté
70229,FAYMONT,70200,FAYMONT,,47.6057038041,6.5983082828,229,,Faymont,Faymont,70,Haute-Saône,27,Bourgogne-Franche-Comté
70230,FEDRY,70120,FEDRY,,47.6150426161,5.87835228178,230,,Fédry,Fédry,70,Haute-Saône,27,Bourgogne-Franche-Comté
70231,FERRIERES LES RAY,70130,FERRIERES LES RAY,,47.5794056888,5.79410220252,231,,Ferrières-lès-Ray,Ferrières-lès-Ray,70,Haute-Saône,27,Bourgogne-Franche-Comté
70232,FERRIERES LES SCEY,70360,FERRIERES LES SCEY,,47.6601846416,6.00409146195,232,,Ferrières-lès-Scey,Ferrières-lès-Scey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70233,LES FESSEY,70310,LES FESSEY,,47.8108548307,6.51712506542,233,Les,Fessey,Les Fessey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70234,FILAIN,70230,FILAIN,,47.5253906128,6.17761888679,234,,Filain,Filain,70,Haute-Saône,27,Bourgogne-Franche-Comté
70235,FLAGY,70000,FLAGY,,47.7001482374,6.18628045346,235,,Flagy,Flagy,70,Haute-Saône,27,Bourgogne-Franche-Comté
70236,FLEUREY LES FAVERNEY,70160,FLEUREY LES FAVERNEY,,47.7411367393,6.09066624014,236,,Fleurey-lès-Faverney,Fleurey-lès-Faverney,70,Haute-Saône,27,Bourgogne-Franche-Comté
70237,FLEUREY LES LAVONCOURT,70120,FLEUREY LES LAVONCOURT,,47.6622890389,5.78508054344,237,,Fleurey-lès-Lavoncourt,Fleurey-lès-Lavoncourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70238,FLEUREY LES ST LOUP,70800,FLEUREY LES ST LOUP,,47.9236983038,6.29330075407,238,,Fleurey-lès-Saint-Loup,Fleurey-lès-Saint-Loup,70,Haute-Saône,27,Bourgogne-Franche-Comté
70239,FONDREMAND,70190,FONDREMAND,,47.4719327079,6.02806238503,239,,Fondremand,Fondremand,70,Haute-Saône,27,Bourgogne-Franche-Comté
70240,FONTAINE LES LUXEUIL,70800,FONTAINE LES LUXEUIL,,47.8601010548,6.33468118355,240,,Fontaine-lès-Luxeuil,Fontaine-lès-Luxeuil,70,Haute-Saône,27,Bourgogne-Franche-Comté
70242,FONTENOIS LA VILLE,70210,FONTENOIS LA VILLE,,47.9384558945,6.17553487571,242,,Fontenois-la-Ville,Fontenois-la-Ville,70,Haute-Saône,27,Bourgogne-Franche-Comté
70243,FONTENOIS LES MONTBOZON,70230,FONTENOIS LES MONTBOZON,,47.4882877067,6.23457965284,243,,Fontenois-lès-Montbozon,Fontenois-lès-Montbozon,70,Haute-Saône,27,Bourgogne-Franche-Comté
70244,FOUCHECOURT,70160,FOUCHECOURT,,47.7897792955,5.9864123843,244,,Fouchécourt,Fouchécourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70245,FOUGEROLLES,70220,FOUGEROLLES,,47.9016025414,6.4130351203,245,,Fougerolles,Fougerolles,70,Haute-Saône,27,Bourgogne-Franche-Comté
70247,FOUVENT ST ANDOCHE,70600,FOUVENT ST ANDOCHE,,47.6505541505,5.6722095809,247,,Fouvent-Saint-Andoche,Fouvent-Saint-Andoche,70,Haute-Saône,27,Bourgogne-Franche-Comté
70247,FOUVENT ST ANDOCHE,70600,FOUVENT ST ANDOCHE,FOUVENT LE BAS,47.6505541505,5.6722095809,247,,Fouvent-Saint-Andoche,Fouvent-Saint-Andoche,70,Haute-Saône,27,Bourgogne-Franche-Comté
70247,FOUVENT ST ANDOCHE,70600,FOUVENT ST ANDOCHE,ST ANDOCHE,47.6505541505,5.6722095809,247,,Fouvent-Saint-Andoche,Fouvent-Saint-Andoche,70,Haute-Saône,27,Bourgogne-Franche-Comté
70248,FRAHIER ET CHATEBIER,70400,FRAHIER ET CHATEBIER,,47.6588239223,6.75066978549,248,,Frahier-et-Chatebier,Frahier-et-Chatebier,70,Haute-Saône,27,Bourgogne-Franche-Comté
70249,FRANCALMONT,70800,FRANCALMONT,,47.8328090874,6.27152718954,249,,Francalmont,Francalmont,70,Haute-Saône,27,Bourgogne-Franche-Comté
70250,FRANCHEVELLE,70200,FRANCHEVELLE,,47.7429315817,6.46131870322,250,,Franchevelle,Franchevelle,70,Haute-Saône,27,Bourgogne-Franche-Comté
70251,FRANCOURT,70180,FRANCOURT,,47.6545449376,5.73384333602,251,,Francourt,Francourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70252,FRAMONT,70600,FRAMONT,,47.5716187406,5.5798394253,252,,Framont,Framont,70,Haute-Saône,27,Bourgogne-Franche-Comté
70252,FRAMONT,70600,FRAMONT,MONT LE FRANOIS,47.5716187406,5.5798394253,252,,Framont,Framont,70,Haute-Saône,27,Bourgogne-Franche-Comté
70253,FRASNE LE CHATEAU,70700,FRASNE LE CHATEAU,,47.464530464,5.89157749434,253,,Frasne-le-Château,Frasne-le-Château,70,Haute-Saône,27,Bourgogne-Franche-Comté
70254,FREDERIC FONTAINE,70200,FREDERIC FONTAINE,,47.6511337429,6.63272946066,254,,Frédéric-Fontaine,Frédéric-Fontaine,70,Haute-Saône,27,Bourgogne-Franche-Comté
70255,FRESNE ST MAMES,70130,FRESNE ST MAMES,,47.5529496769,5.85669124269,255,,Fresne-Saint-Mamès,Fresne-Saint-Mamès,70,Haute-Saône,27,Bourgogne-Franche-Comté
70256,FRESSE,70270,FRESSE,,47.76130687,6.6753666512,256,,Fresse,Fresse,70,Haute-Saône,27,Bourgogne-Franche-Comté
70257,FRETIGNEY ET VELLOREILLE,70130,FRETIGNEY ET VELLOREILLE,,47.4895779062,5.93793813227,257,,Fretigney-et-Velloreille,Fretigney-et-Velloreille,70,Haute-Saône,27,Bourgogne-Franche-Comté
70258,FROIDECONCHE,70300,FROIDECONCHE,,47.8340163849,6.41922862688,258,,Froideconche,Froideconche,70,Haute-Saône,27,Bourgogne-Franche-Comté
70259,FROIDETERRE,70200,FROIDETERRE,,47.6999427158,6.52495307939,259,,Froideterre,Froideterre,70,Haute-Saône,27,Bourgogne-Franche-Comté
70260,FROTEY LES LURE,70200,FROTEY LES LURE,,47.6564257006,6.54861043943,260,,Frotey-lès-Lure,Frotey-lès-Lure,70,Haute-Saône,27,Bourgogne-Franche-Comté
70261,FROTEY LES VESOUL,70000,FROTEY LES VESOUL,,47.6293186194,6.19415622549,261,,Frotey-lès-Vesoul,Frotey-lès-Vesoul,70,Haute-Saône,27,Bourgogne-Franche-Comté
70262,GENEVREUILLE,70240,GENEVREUILLE,,47.6747365323,6.37822435602,262,,Genevreuille,Genevreuille,70,Haute-Saône,27,Bourgogne-Franche-Comté
70263,GENEVREY,70240,GENEVREY,,47.7215124098,6.32711971667,263,,Genevrey,Genevrey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70264,GEORFANS,70110,GEORFANS,,47.5338346388,6.51269421338,264,,Georfans,Georfans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70265,GERMIGNEY,70100,GERMIGNEY,,47.3703184562,5.53460543886,265,,Germigney,Germigney,70,Haute-Saône,27,Bourgogne-Franche-Comté
70267,GEVIGNEY ET MERCEY,70500,GEVIGNEY ET MERCEY,,47.794509649,5.94474334051,267,,Gevigney-et-Mercey,Gevigney-et-Mercey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70268,GEZIER ET FONTENELAY,70700,GEZIER ET FONTENELAY,,47.3622765884,5.89137487583,268,,Gézier-et-Fontenelay,Gézier-et-Fontenelay,70,Haute-Saône,27,Bourgogne-Franche-Comté
70269,GIREFONTAINE,70210,GIREFONTAINE,,47.8953750469,6.17205658715,269,,Girefontaine,Girefontaine,70,Haute-Saône,27,Bourgogne-Franche-Comté
70271,GOUHENANS,70110,GOUHENANS,,47.6119029467,6.48560060505,271,,Gouhenans,Gouhenans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70272,GOURGEON,70120,GOURGEON,,47.71794344,5.84745299803,272,,Gourgeon,Gourgeon,70,Haute-Saône,27,Bourgogne-Franche-Comté
70273,GRAMMONT,70110,GRAMMONT,,47.5135309996,6.51393062339,273,,Grammont,Grammont,70,Haute-Saône,27,Bourgogne-Franche-Comté
70274,GRANDECOURT,70120,GRANDECOURT,,47.6362713068,5.85990201791,274,,Grandecourt,Grandecourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70275,GRANDVELLE ET LE PERRENOT,70190,GRANDVELLE ET LE PERRENOT,,47.5185656017,6.0023921367,275,,Grandvelle-et-le-Perrenot,Grandvelle-et-le-Perrenot,70,Haute-Saône,27,Bourgogne-Franche-Comté
70276,GRANGES LA VILLE,70400,GRANGES LA VILLE,,47.5673790557,6.56598899284,276,,Granges-la-Ville,Granges-la-Ville,70,Haute-Saône,27,Bourgogne-Franche-Comté
70277,GRANGES LE BOURG,70400,GRANGES LE BOURG,,47.5773603894,6.58761184048,277,,Granges-le-Bourg,Granges-le-Bourg,70,Haute-Saône,27,Bourgogne-Franche-Comté
70278,GRATTERY,70170,GRATTERY,,47.6725586831,6.08230172573,278,,Grattery,Grattery,70,Haute-Saône,27,Bourgogne-Franche-Comté
70279,GRAY,70100,GRAY,,47.4320889937,5.61070658545,279,,Gray,Gray,70,Haute-Saône,27,Bourgogne-Franche-Comté
70280,GRAY LA VILLE,70100,GRAY LA VILLE,,47.4400313052,5.57140499871,280,,Gray-la-Ville,Gray-la-Ville,70,Haute-Saône,27,Bourgogne-Franche-Comté
70282,GY,70700,GY,,47.402856646,5.80618920641,282,,Gy,Gy,70,Haute-Saône,27,Bourgogne-Franche-Comté
70283,HAUT DU THEM CHATEAU LAMBERT,70440,HAUT DU THEM CHATEAU LAMBERT,,47.8372151662,6.74620306195,283,,Haut-du-Them-Château-Lambert,Haut-du-Them-Château-Lambert,70,Haute-Saône,27,Bourgogne-Franche-Comté
70283,HAUT DU THEM CHATEAU LAMBERT,70440,HAUT DU THEM CHATEAU LAMBERT,CHATEAU LAMBERT,47.8372151662,6.74620306195,283,,Haut-du-Them-Château-Lambert,Haut-du-Them-Château-Lambert,70,Haute-Saône,27,Bourgogne-Franche-Comté
70284,HAUTEVELLE,70800,HAUTEVELLE,,47.8415266844,6.29533875186,284,,Hautevelle,Hautevelle,70,Haute-Saône,27,Bourgogne-Franche-Comté
70285,HERICOURT,70400,HERICOURT,,47.5698613671,6.76517349246,285,,Héricourt,Héricourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70285,HERICOURT,70400,HERICOURT,BUSSUREL,47.5698613671,6.76517349246,285,,Héricourt,Héricourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70285,HERICOURT,70400,HERICOURT,BYANS,47.5698613671,6.76517349246,285,,Héricourt,Héricourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70286,HUGIER,70150,HUGIER,,47.3119515767,5.70658705993,286,,Hugier,Hugier,70,Haute-Saône,27,Bourgogne-Franche-Comté
70287,HURECOURT,70210,HURECOURT,,47.9044830745,6.05726153484,287,,Hurecourt,Hurecourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70288,HYET,70190,HYET,,47.4764474836,6.07653448218,288,,Hyet,Hyet,70,Haute-Saône,27,Bourgogne-Franche-Comté
70289,IGNY,70700,IGNY,,47.4821498978,5.75563674329,289,,Igny,Igny,70,Haute-Saône,27,Bourgogne-Franche-Comté
70290,JASNEY,70800,JASNEY,,47.8699899355,6.17639151599,290,,Jasney,Jasney,70,Haute-Saône,27,Bourgogne-Franche-Comté
70291,JONVELLE,70500,JONVELLE,,47.9286064746,5.91384412427,291,,Jonvelle,Jonvelle,70,Haute-Saône,27,Bourgogne-Franche-Comté
70292,JUSSEY,70500,JUSSEY,,47.8171454049,5.88977076116,292,,Jussey,Jussey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70292,JUSSEY,70500,JUSSEY,NOROY LES JUSSEY,47.8171454049,5.88977076116,292,,Jussey,Jussey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70293,LAMBREY,70500,LAMBREY,,47.7643209211,5.92933904198,293,,Lambrey,Lambrey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70294,LANTENOT,70200,LANTENOT,,47.7550142375,6.50401420408,294,,Lantenot,Lantenot,70,Haute-Saône,27,Bourgogne-Franche-Comté
70295,LA LANTERNE ET LES ARMONTS,70270,LA LANTERNE ET LES ARMONTS,,47.7866393321,6.52929225583,295,La,Lanterne-et-les-Armonts,La Lanterne-et-les-Armonts,70,Haute-Saône,27,Bourgogne-Franche-Comté
70296,LARIANS ET MUNANS,70230,LARIANS ET MUNANS,,47.4233188148,6.22972623642,296,,Larians-et-Munans,Larians-et-Munans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70297,LARRET,70600,LARRET,,47.6274401476,5.63279804414,297,,Larret,Larret,70,Haute-Saône,27,Bourgogne-Franche-Comté
70298,LAVIGNEY,70120,LAVIGNEY,,47.7118854069,5.80400315712,298,,Lavigney,Lavigney,70,Haute-Saône,27,Bourgogne-Franche-Comté
70299,LAVONCOURT,70120,LAVONCOURT,,47.6226588338,5.77603132497,299,,Lavoncourt,Lavoncourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70301,LIEFFRANS,70190,LIEFFRANS,,47.5224824778,5.96809536182,301,,Lieffrans,Lieffrans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70302,LIEUCOURT,70140,LIEUCOURT,,47.3596424116,5.61660904057,302,,Lieucourt,Lieucourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70303,LIEVANS,70240,LIEVANS,,47.6356797971,6.34783020976,303,,Liévans,Liévans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70304,LINEXERT,70200,LINEXERT,,47.743058957,6.4853079749,304,,Linexert,Linexert,70,Haute-Saône,27,Bourgogne-Franche-Comté
70305,LOEUILLEY,70100,LOEUILLEY,,47.4659733002,5.40017583758,305,,Lœuilley,Lœuilley,70,Haute-Saône,27,Bourgogne-Franche-Comté
70306,LOMONT,70200,LOMONT,,47.6249613349,6.59883932235,306,,Lomont,Lomont,70,Haute-Saône,27,Bourgogne-Franche-Comté
70307,LONGEVELLE,70110,LONGEVELLE,,47.5919835332,6.44894142613,307,,Longevelle,Longevelle,70,Haute-Saône,27,Bourgogne-Franche-Comté
70308,LA LONGINE,70310,LA LONGINE,,47.8992130151,6.57732666046,308,La,Longine,La Longine,70,Haute-Saône,27,Bourgogne-Franche-Comté
70309,LOULANS VERCHAMP,70230,LOULANS VERCHAMP,,47.4450028639,6.20512225954,309,,Loulans-Verchamp,Loulans-Verchamp,70,Haute-Saône,27,Bourgogne-Franche-Comté
70309,LOULANS VERCHAMP,70230,LOULANS VERCHAMP,VERCHAMP,47.4450028639,6.20512225954,309,,Loulans-Verchamp,Loulans-Verchamp,70,Haute-Saône,27,Bourgogne-Franche-Comté
70310,LURE,70200,LURE,,47.6851664568,6.49651910322,310,,Lure,Lure,70,Haute-Saône,27,Bourgogne-Franche-Comté
70311,LUXEUIL LES BAINS,70300,LUXEUIL LES BAINS,,47.823919724,6.36363973342,311,,Luxeuil-les-Bains,Luxeuil-les-Bains,70,Haute-Saône,27,Bourgogne-Franche-Comté
70312,LUZE,70400,LUZE,,47.606620843,6.7184633564,312,,Luze,Luze,70,Haute-Saône,27,Bourgogne-Franche-Comté
70313,LYOFFANS,70200,LYOFFANS,,47.6439821096,6.5885519049,313,,Lyoffans,Lyoffans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70314,MAGNIVRAY,70300,MAGNIVRAY,,47.7814660016,6.4639195197,314,,Magnivray,Magnivray,70,Haute-Saône,27,Bourgogne-Franche-Comté
70315,MAGNONCOURT,70800,MAGNONCOURT,,47.9040102383,6.29020789289,315,,Magnoncourt,Magnoncourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70316,LE MAGNORAY,70000,LE MAGNORAY,,47.5247427467,6.09145764934,316,Le,Magnoray,Le Magnoray,70,Haute-Saône,27,Bourgogne-Franche-Comté
70317,LES MAGNY,70110,LES MAGNY,,47.5280243494,6.4414267438,317,Les,Magny,Les Magny,70,Haute-Saône,27,Bourgogne-Franche-Comté
70318,MAGNY DANIGON,70200,MAGNY DANIGON,,47.6813542817,6.61123865136,318,,Magny-Danigon,Magny-Danigon,70,Haute-Saône,27,Bourgogne-Franche-Comté
70319,MAGNY JOBERT,70200,MAGNY JOBERT,,47.6470935306,6.61228516531,319,,Magny-Jobert,Magny-Jobert,70,Haute-Saône,27,Bourgogne-Franche-Comté
70320,MAGNY LES JUSSEY,70500,MAGNY LES JUSSEY,,47.8564985248,5.97815362476,320,,Magny-lès-Jussey,Magny-lès-Jussey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70321,MAGNY VERNOIS,70200,MAGNY VERNOIS,,47.6685268881,6.47015380217,321,,Magny-Vernois,Magny-Vernois,70,Haute-Saône,27,Bourgogne-Franche-Comté
70322,MAILLERONCOURT CHARETTE,70240,MAILLERONCOURT CHARETTE,,47.7303877198,6.26557184333,322,,Mailleroncourt-Charette,Mailleroncourt-Charette,70,Haute-Saône,27,Bourgogne-Franche-Comté
70323,MAILLERONCOURT ST PANCRAS,70210,MAILLERONCOURT ST PANCRAS,,47.9284392755,6.13912860698,323,,Mailleroncourt-Saint-Pancras,Mailleroncourt-Saint-Pancras,70,Haute-Saône,27,Bourgogne-Franche-Comté
70324,MAILLEY ET CHAZELOT,70000,MAILLEY ET CHAZELOT,,47.5301073689,6.05096098023,324,,Mailley-et-Chazelot,Mailley-et-Chazelot,70,Haute-Saône,27,Bourgogne-Franche-Comté
70325,MAIZIERES,70190,MAIZIERES,,47.4958191986,6.00891758003,325,,Maizières,Maizières,70,Haute-Saône,27,Bourgogne-Franche-Comté
70326,LA MALACHERE,70190,LA MALACHERE,,47.455249731,6.07203936643,326,La,Malachère,La Malachère,70,Haute-Saône,27,Bourgogne-Franche-Comté
70327,MALANS,70140,MALANS,,47.2677473126,5.59612785813,327,,Malans,Malans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70328,MALBOUHANS,70200,MALBOUHANS,,47.7110423982,6.58099580275,328,,Malbouhans,Malbouhans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70329,MALVILLERS,70120,MALVILLERS,,47.7360575456,5.78893531092,329,,Malvillers,Malvillers,70,Haute-Saône,27,Bourgogne-Franche-Comté
70330,MANDREVILLARS,70400,MANDREVILLARS,,47.6176830876,6.77141230982,330,,Mandrevillars,Mandrevillars,70,Haute-Saône,27,Bourgogne-Franche-Comté
70331,MANTOCHE,70100,MANTOCHE,,47.4262852629,5.52118852315,331,,Mantoche,Mantoche,70,Haute-Saône,27,Bourgogne-Franche-Comté
70332,MARAST,70110,MARAST,,47.5574914951,6.38057064129,332,,Marast,Marast,70,Haute-Saône,27,Bourgogne-Franche-Comté
70334,MARNAY,70150,MARNAY,,47.2975763661,5.77201053595,334,,Marnay,Marnay,70,Haute-Saône,27,Bourgogne-Franche-Comté
70335,MAUSSANS,70230,MAUSSANS,,47.4361781142,6.24655907971,335,,Maussans,Maussans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70336,MELECEY,70110,MELECEY,,47.5236383873,6.49028438138,336,,Mélecey,Mélecey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70337,MELIN,70120,MELIN,,47.7422930527,5.82229333828,337,,Melin,Melin,70,Haute-Saône,27,Bourgogne-Franche-Comté
70338,MELINCOURT,70210,MELINCOURT,,47.8875285493,6.12924766787,338,,Melincourt,Melincourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70339,MELISEY,70270,MELISEY,,47.7605175901,6.55981734246,339,,Mélisey,Mélisey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70340,MEMBREY,70180,MEMBREY,,47.585270996,5.75013289833,340,,Membrey,Membrey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70341,MENOUX,70160,MENOUX,,47.8101680875,6.10172191211,341,,Menoux,Menoux,70,Haute-Saône,27,Bourgogne-Franche-Comté
70342,MERCEY SUR SAONE,70130,MERCEY SUR SAONE,,47.5239276742,5.72810930864,342,,Mercey-sur-Saône,Mercey-sur-Saône,70,Haute-Saône,27,Bourgogne-Franche-Comté
70343,MERSUAY,70160,MERSUAY,,47.78520747,6.14234476405,343,,Mersuay,Mersuay,70,Haute-Saône,27,Bourgogne-Franche-Comté
70344,MEURCOURT,70300,MEURCOURT,,47.7660974032,6.23487689466,344,,Meurcourt,Meurcourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70345,MIELLIN,70440,MIELLIN,,47.8077418861,6.74988909945,345,,Miellin,Miellin,70,Haute-Saône,27,Bourgogne-Franche-Comté
70347,MIGNAVILLERS,70400,MIGNAVILLERS,,47.587556022,6.55398478114,347,,Mignavillers,Mignavillers,70,Haute-Saône,27,Bourgogne-Franche-Comté
70348,MOFFANS ET VACHERESSE,70200,MOFFANS ET VACHERESSE,,47.6277773291,6.54883327411,348,,Moffans-et-Vacheresse,Moffans-et-Vacheresse,70,Haute-Saône,27,Bourgogne-Franche-Comté
70349,MOIMAY,70110,MOIMAY,,47.5597602693,6.39935154409,349,,Moimay,Moimay,70,Haute-Saône,27,Bourgogne-Franche-Comté
70350,MOLAY,70120,MOLAY,,47.7343168819,5.74176015728,350,,Molay,Molay,70,Haute-Saône,27,Bourgogne-Franche-Comté
70351,MOLLANS,70240,MOLLANS,,47.6509274907,6.3893154252,351,,Mollans,Mollans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70352,LA MONTAGNE,70310,LA MONTAGNE,,47.9241751204,6.58996774592,352,La,Montagne,La Montagne,70,Haute-Saône,27,Bourgogne-Franche-Comté
70353,MONTAGNEY,70140,MONTAGNEY,,47.285544975,5.6562805312,353,,Montagney,Montagney,70,Haute-Saône,27,Bourgogne-Franche-Comté
70355,MONTARLOT LES RIOZ,70190,MONTARLOT LES RIOZ,,47.4176155928,5.99917917055,355,,Montarlot-lès-Rioz,Montarlot-lès-Rioz,70,Haute-Saône,27,Bourgogne-Franche-Comté
70356,MONTBOILLON,70700,MONTBOILLON,,47.379681472,5.91385310613,356,,Montboillon,Montboillon,70,Haute-Saône,27,Bourgogne-Franche-Comté
70357,MONTBOZON,70230,MONTBOZON,,47.4596587023,6.25631932955,357,,Montbozon,Montbozon,70,Haute-Saône,27,Bourgogne-Franche-Comté
70358,MONTCEY,70000,MONTCEY,,47.6500350881,6.23995648406,358,,Montcey,Montcey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70359,MONTCOURT,70500,MONTCOURT,,47.9307650179,5.96259842933,359,,Montcourt,Montcourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70360,MONTDORE,70210,MONTDORE,,47.9227624293,6.07900554463,360,,Montdoré,Montdoré,70,Haute-Saône,27,Bourgogne-Franche-Comté
70361,MONTESSAUX,70270,MONTESSAUX,,47.7375144324,6.5647568741,361,,Montessaux,Montessaux,70,Haute-Saône,27,Bourgogne-Franche-Comté
70362,MONTIGNY LES CHERLIEU,70500,MONTIGNY LES CHERLIEU,,47.7872395277,5.81643260789,362,,Montigny-lès-Cherlieu,Montigny-lès-Cherlieu,70,Haute-Saône,27,Bourgogne-Franche-Comté
70363,MONTIGNY LES VESOUL,70000,MONTIGNY LES VESOUL,,47.6428660146,6.07108033539,363,,Montigny-lès-Vesoul,Montigny-lès-Vesoul,70,Haute-Saône,27,Bourgogne-Franche-Comté
70364,MONTJUSTIN ET VELOTTE,70110,MONTJUSTIN ET VELOTTE,,47.6187013351,6.3745985909,364,,Montjustin-et-Velotte,Montjustin-et-Velotte,70,Haute-Saône,27,Bourgogne-Franche-Comté
70366,VILLERS CHEMIN ET MONT LES ETRELLES,70700,VILLERS CHEMIN MONT LES ETRELLES,,47.4429990999,5.87604712509,366,,Villers-Chemin-et-Mont-lès-Étrelles,Villers-Chemin-et-Mont-lès-Étrelles,70,Haute-Saône,27,Bourgogne-Franche-Comté
70367,MONT LE VERNOIS,70000,MONT LE VERNOIS,,47.6019923568,6.06463022587,367,,Mont-le-Vernois,Mont-le-Vernois,70,Haute-Saône,27,Bourgogne-Franche-Comté
70368,MONTOT,70180,MONTOT,,47.5521723024,5.61575354895,368,,Montot,Montot,70,Haute-Saône,27,Bourgogne-Franche-Comté
70369,MONT ST LEGER,70120,MONT ST LEGER,,47.6374393402,5.80017411668,369,,Mont-Saint-Léger,Mont-Saint-Léger,70,Haute-Saône,27,Bourgogne-Franche-Comté
70371,MONTUREUX ET PRANTIGNY,70100,MONTUREUX ET PRANTIGNY,,47.5050359046,5.63005559923,371,,Montureux-et-Prantigny,Montureux-et-Prantigny,70,Haute-Saône,27,Bourgogne-Franche-Comté
70372,MONTUREUX LES BAULAY,70500,MONTUREUX LES BAULAY,,47.8202221375,5.9796933701,372,,Montureux-lès-Baulay,Montureux-lès-Baulay,70,Haute-Saône,27,Bourgogne-Franche-Comté
70373,LA ROCHE MOREY,70120,LA ROCHE MOREY,,47.6947168026,5.75337268526,373,La,Roche-Morey,La Roche-Morey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70373,LA ROCHE MOREY,70120,LA ROCHE MOREY,BETONCOURT LES MENETRIERS,47.6947168026,5.75337268526,373,La,Roche-Morey,La Roche-Morey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70373,LA ROCHE MOREY,70120,LA ROCHE MOREY,ST JULIEN,47.6947168026,5.75337268526,373,La,Roche-Morey,La Roche-Morey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70373,LA ROCHE MOREY,70120,LA ROCHE MOREY,SUAUCOURT ET PISSELOUP,47.6947168026,5.75337268526,373,La,Roche-Morey,La Roche-Morey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70374,MOTEY BESUCHE,70140,MOTEY BESUCHE,,47.3053559676,5.66529862225,374,,Motey-Besuche,Motey-Besuche,70,Haute-Saône,27,Bourgogne-Franche-Comté
70375,MOTEY SUR SAONE,70130,MOTEY SUR SAONE,,47.5226262078,5.74626631123,375,,Motey-sur-Saône,Motey-sur-Saône,70,Haute-Saône,27,Bourgogne-Franche-Comté
70376,NANTILLY,70100,NANTILLY,,47.4579304083,5.52873315252,376,,Nantilly,Nantilly,70,Haute-Saône,27,Bourgogne-Franche-Comté
70378,NAVENNE,70000,NAVENNE,,47.6030208472,6.1618556725,378,,Navenne,Navenne,70,Haute-Saône,27,Bourgogne-Franche-Comté
70380,NEUREY EN VAUX,70160,NEUREY EN VAUX,,47.7425950552,6.20223873056,380,,Neurey-en-Vaux,Neurey-en-Vaux,70,Haute-Saône,27,Bourgogne-Franche-Comté
70381,NEUREY LES LA DEMIE,70000,NEUREY LES LA DEMIE,,47.5638711868,6.19561119742,381,,Neurey-lès-la-Demie,Neurey-lès-la-Demie,70,Haute-Saône,27,Bourgogne-Franche-Comté
70383,NEUVELLE LES CROMARY,70190,NEUVELLE LES CROMARY,,47.4079331287,6.06801697703,383,,Neuvelle-lès-Cromary,Neuvelle-lès-Cromary,70,Haute-Saône,27,Bourgogne-Franche-Comté
70384,NEUVELLE LES LA CHARITE,70130,NEUVELLE LES LA CHARITE,,47.5393826084,5.96042081802,384,,Neuvelle-lès-la-Charité,Neuvelle-lès-la-Charité,70,Haute-Saône,27,Bourgogne-Franche-Comté
70385,LA NEUVELLE LES LURE,70200,LA NEUVELLE LES LURE,,47.7156316858,6.55341236985,385,La,Neuvelle-lès-Lure,La Neuvelle-lès-Lure,70,Haute-Saône,27,Bourgogne-Franche-Comté
70386,LA NEUVELLE LES SCEY,70360,LA NEUVELLE LES SCEY,,47.7015222908,5.94028495484,386,La,Neuvelle-lès-Scey,La Neuvelle-lès-Scey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70387,NOIDANS LE FERROUX,70130,NOIDANS LE FERROUX,,47.5676980089,5.94846873016,387,,Noidans-le-Ferroux,Noidans-le-Ferroux,70,Haute-Saône,27,Bourgogne-Franche-Comté
70388,NOIDANS LES VESOUL,70000,NOIDANS LES VESOUL,,47.6097514089,6.11842989879,388,,Noidans-lès-Vesoul,Noidans-lès-Vesoul,70,Haute-Saône,27,Bourgogne-Franche-Comté
70389,NOIRON,70100,NOIRON,,47.3828510502,5.62597938451,389,,Noiron,Noiron,70,Haute-Saône,27,Bourgogne-Franche-Comté
70390,NOROY LE BOURG,70000,NOROY LE BOURG,,47.6038475858,6.28890431672,390,,Noroy-le-Bourg,Noroy-le-Bourg,70,Haute-Saône,27,Bourgogne-Franche-Comté
70392,OIGNEY,70120,OIGNEY,,47.7559418232,5.8503313138,392,,Oigney,Oigney,70,Haute-Saône,27,Bourgogne-Franche-Comté
70393,OISELAY ET GRACHAUX,70700,OISELAY ET GRACHAUX,,47.4265249519,5.92562496608,393,,Oiselay-et-Grachaux,Oiselay-et-Grachaux,70,Haute-Saône,27,Bourgogne-Franche-Comté
70394,ONAY,70100,ONAY,,47.3909626091,5.68585881387,394,,Onay,Onay,70,Haute-Saône,27,Bourgogne-Franche-Comté
70395,OPPENANS,70110,OPPENANS,,47.5826629253,6.38446372576,395,,Oppenans,Oppenans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70396,ORICOURT,70110,ORICOURT,,47.597173392,6.3945348037,396,,Oricourt,Oricourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70397,ORMENANS,70230,ORMENANS,,47.4583413569,6.21351762142,397,,Ormenans,Ormenans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70398,ORMOICHE,70300,ORMOICHE,,47.8144662323,6.30160249808,398,,Ormoiche,Ormoiche,70,Haute-Saône,27,Bourgogne-Franche-Comté
70399,ORMOY,70500,ORMOY,,47.8850612988,5.99402771513,399,,Ormoy,Ormoy,70,Haute-Saône,27,Bourgogne-Franche-Comté
70400,OUGE,70500,OUGE,,47.7986582876,5.70114635642,400,,Ouge,Ouge,70,Haute-Saône,27,Bourgogne-Franche-Comté
70401,OVANCHES,70360,OVANCHES,,47.6291922295,5.95640814876,401,,Ovanches,Ovanches,70,Haute-Saône,27,Bourgogne-Franche-Comté
70402,OYRIERES,70600,OYRIERES,,47.5290658651,5.57358052333,402,,Oyrières,Oyrières,70,Haute-Saône,27,Bourgogne-Franche-Comté
70403,PALANTE,70200,PALANTE,,47.6649408648,6.58267716976,403,,Palante,Palante,70,Haute-Saône,27,Bourgogne-Franche-Comté
70404,PASSAVANT LA ROCHERE,70210,PASSAVANT LA ROCHERE,,47.9842695238,6.05556415808,404,,Passavant-la-Rochère,Passavant-la-Rochère,70,Haute-Saône,27,Bourgogne-Franche-Comté
70405,PENNESIERES,70190,PENNESIERES,,47.4957736463,6.09574918717,405,,Pennesières,Pennesières,70,Haute-Saône,27,Bourgogne-Franche-Comté
70406,PERCEY LE GRAND,70600,PERCEY LE GRAND,,47.6221937066,5.39857467862,406,,Percey-le-Grand,Percey-le-Grand,70,Haute-Saône,27,Bourgogne-Franche-Comté
70407,PERROUSE,70190,PERROUSE,,47.3669269306,6.04533540615,407,,Perrouse,Perrouse,70,Haute-Saône,27,Bourgogne-Franche-Comté
70408,PESMES,70140,PESMES,,47.2915778298,5.57274698135,408,,Pesmes,Pesmes,70,Haute-Saône,27,Bourgogne-Franche-Comté
70409,PIERRECOURT,70600,PIERRECOURT,,47.6432505836,5.58620580677,409,,Pierrecourt,Pierrecourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70410,PIN,70150,PIN,,47.3392778996,5.85895219188,410,,Pin,Pin,70,Haute-Saône,27,Bourgogne-Franche-Comté
70411,LA PISSEURE,70800,LA PISSEURE,,47.8628951813,6.22816351051,411,La,Pisseure,La Pisseure,70,Haute-Saône,27,Bourgogne-Franche-Comté
70412,PLAINEMONT,70800,PLAINEMONT,,47.8476581979,6.21792069409,412,,Plainemont,Plainemont,70,Haute-Saône,27,Bourgogne-Franche-Comté
70413,PLANCHER BAS,70290,PLANCHER BAS,,47.7231669962,6.73487477028,413,,Plancher-Bas,Plancher-Bas,70,Haute-Saône,27,Bourgogne-Franche-Comté
70414,PLANCHER LES MINES,70290,PLANCHER LES MINES,,47.7857364865,6.77321577931,414,,Plancher-les-Mines,Plancher-les-Mines,70,Haute-Saône,27,Bourgogne-Franche-Comté
70415,POLAINCOURT ET CLAIREFONTAINE,70210,POLAINCOURT ET CLAIREFONTAINE,,47.875259179,6.06625530496,415,,Polaincourt-et-Clairefontaine,Polaincourt-et-Clairefontaine,70,Haute-Saône,27,Bourgogne-Franche-Comté
70416,POMOY,70240,POMOY,,47.6592691879,6.34654369308,416,,Pomoy,Pomoy,70,Haute-Saône,27,Bourgogne-Franche-Comté
70417,PONTCEY,70360,PONTCEY,,47.6283826292,6.03204644635,417,,Pontcey,Pontcey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70418,LA ROMAINE,70130,LA ROMAINE,GREUCOURT,47.5374721676,5.92263244824,418,La,Romaine,La Romaine,70,Haute-Saône,27,Bourgogne-Franche-Comté
70418,LA ROMAINE,70130,LA ROMAINE,LE PONT DE PLANCHES,47.5374721676,5.92263244824,418,La,Romaine,La Romaine,70,Haute-Saône,27,Bourgogne-Franche-Comté
70418,LA ROMAINE,70130,LA ROMAINE,VEZET,47.5374721676,5.92263244824,418,La,Romaine,La Romaine,70,Haute-Saône,27,Bourgogne-Franche-Comté
70419,PONT DU BOIS,70210,PONT DU BOIS,,47.9799459833,6.12513402916,419,,Pont-du-Bois,Pont-du-Bois,70,Haute-Saône,27,Bourgogne-Franche-Comté
70420,PONT SUR L OGNON,70110,PONT SUR L OGNON,,47.5189006507,6.38822321386,420,,Pont-sur-l'Ognon,Pont-sur-l'Ognon,70,Haute-Saône,27,Bourgogne-Franche-Comté
70421,PORT SUR SAONE,70170,PORT SUR SAONE,,47.692500452,6.03302774909,421,,Port-sur-Saône,Port-sur-Saône,70,Haute-Saône,27,Bourgogne-Franche-Comté
70422,POYANS,70100,POYANS,,47.4443035685,5.47650052222,422,,Poyans,Poyans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70423,PREIGNEY,70120,PREIGNEY,,47.7656148991,5.78234601722,423,,Preigney,Preigney,70,Haute-Saône,27,Bourgogne-Franche-Comté
70425,LA PROISELIERE ET LANGLE,70310,LA PROISELIERE ET LANGLE,,47.8134343067,6.49157329461,425,La,Proiselière-et-Langle,La Proiselière-et-Langle,70,Haute-Saône,27,Bourgogne-Franche-Comté
70426,PROVENCHERE,70170,PROVENCHERE,,47.7220120266,6.12391376689,426,,Provenchère,Provenchère,70,Haute-Saône,27,Bourgogne-Franche-Comté
70427,PURGEROT,70160,PURGEROT,,47.7492388888,5.99111141327,427,,Purgerot,Purgerot,70,Haute-Saône,27,Bourgogne-Franche-Comté
70428,PUSEY,70000,PUSEY,,47.6516903952,6.12098049033,428,,Pusey,Pusey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70429,PUSY ET EPENOUX,70000,PUSY ET EPENOUX,,47.6675589968,6.15028596157,429,,Pusy-et-Épenoux,Pusy-et-Épenoux,70,Haute-Saône,27,Bourgogne-Franche-Comté
70430,LA QUARTE,70120,LA QUARTE,,47.7724536532,5.70159719481,430,La,Quarte,La Quarte,70,Haute-Saône,27,Bourgogne-Franche-Comté
70431,QUENOCHE,70190,QUENOCHE,,47.4644966479,6.10803014349,431,,Quenoche,Quenoche,70,Haute-Saône,27,Bourgogne-Franche-Comté
70432,QUERS,70200,QUERS,,47.7265725421,6.44190113641,432,,Quers,Quers,70,Haute-Saône,27,Bourgogne-Franche-Comté
70433,QUINCEY,70000,QUINCEY,,47.5918067623,6.20082918188,433,,Quincey,Quincey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70435,RADDON ET CHAPENDU,70280,RADDON ET CHAPENDU,,47.8553521671,6.4533442174,435,,Raddon-et-Chapendu,Raddon-et-Chapendu,70,Haute-Saône,27,Bourgogne-Franche-Comté
70436,RAINCOURT,70500,RAINCOURT,,47.8597701949,5.88767726096,436,,Raincourt,Raincourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70437,RANZEVELLE,70500,RANZEVELLE,,47.9064629009,5.98377333342,437,,Ranzevelle,Ranzevelle,70,Haute-Saône,27,Bourgogne-Franche-Comté
70438,RAY SUR SAONE,70130,RAY SUR SAONE,,47.5911466767,5.81525402096,438,,Ray-sur-Saône,Ray-sur-Saône,70,Haute-Saône,27,Bourgogne-Franche-Comté
70439,RAZE,70000,RAZE,,47.5810464367,6.00410324695,439,,Raze,Raze,70,Haute-Saône,27,Bourgogne-Franche-Comté
70440,RECOLOGNE,70130,RECOLOGNE LES RAY,,47.5821627991,5.77361697832,440,,Recologne,Recologne,70,Haute-Saône,27,Bourgogne-Franche-Comté
70441,RECOLOGNE LES RIOZ,70190,RECOLOGNE LES RIOZ,,47.4687979671,5.98297924025,441,,Recologne-lès-Rioz,Recologne-lès-Rioz,70,Haute-Saône,27,Bourgogne-Franche-Comté
70442,RENAUCOURT,70120,RENAUCOURT,,47.6413769838,5.76341402671,442,,Renaucourt,Renaucourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70443,LA GRANDE RESIE,70140,LA GRANDE RESIE,,47.3367024965,5.56326509023,443,La,Grande-Résie,La Grande-Résie,70,Haute-Saône,27,Bourgogne-Franche-Comté
70444,LA RESIE ST MARTIN,70140,LA RESIE ST MARTIN,,47.3107496034,5.60845314626,444,La,Résie-Saint-Martin,La Résie-Saint-Martin,70,Haute-Saône,27,Bourgogne-Franche-Comté
70445,RIGNOVELLE,70200,RIGNOVELLE,,47.7652483587,6.47533743202,445,,Rignovelle,Rignovelle,70,Haute-Saône,27,Bourgogne-Franche-Comté
70446,RIGNY,70100,RIGNY,,47.477791361,5.63330799933,446,,Rigny,Rigny,70,Haute-Saône,27,Bourgogne-Franche-Comté
70447,RIOZ,70190,RIOZ,,47.4373192173,6.07568807889,447,,Rioz,Rioz,70,Haute-Saône,27,Bourgogne-Franche-Comté
70447,RIOZ,70190,RIOZ,LES FONTENIS,47.4373192173,6.07568807889,447,,Rioz,Rioz,70,Haute-Saône,27,Bourgogne-Franche-Comté
70448,ROCHE ET RAUCOURT,70180,ROCHE ET RAUCOURT,,47.6209655043,5.70623198676,448,,Roche-et-Raucourt,Roche-et-Raucourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70449,ROCHE LINOTTE ET SORANS CORDIERS,70230,ROCHE LINOTTE ET SORANS CORDIERS,,47.4753768003,6.19694981804,449,,Roche-sur-Linotte-et-Sorans-les-Cordiers,Roche-sur-Linotte-et-Sorans-les-Cordiers,70,Haute-Saône,27,Bourgogne-Franche-Comté
70450,LA ROCHELLE,70120,LA ROCHELLE,,47.7523236949,5.72464823314,450,La,Rochelle,La Rochelle,70,Haute-Saône,27,Bourgogne-Franche-Comté
70451,RONCHAMP,70250,RONCHAMP,,47.7159736796,6.63465984032,451,,Ronchamp,Ronchamp,70,Haute-Saône,27,Bourgogne-Franche-Comté
70452,ROSEY,70000,ROSEY,,47.5587435232,6.02112272894,452,,Rosey,Rosey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70453,LA ROSIERE,70310,LA ROSIERE,,47.9104394997,6.62199304981,453,La,Rosière,La Rosière,70,Haute-Saône,27,Bourgogne-Franche-Comté
70454,ROSIERES SUR MANCE,70500,ROSIERES SUR MANCE,,47.8376565053,5.80467738771,454,,Rosières-sur-Mance,Rosières-sur-Mance,70,Haute-Saône,27,Bourgogne-Franche-Comté
70455,ROYE,70200,ROYE,,47.6771506136,6.5417073316,455,,Roye,Roye,70,Haute-Saône,27,Bourgogne-Franche-Comté
70456,RUHANS,70190,RUHANS,,47.4586029452,6.13611036394,456,,Ruhans,Ruhans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70457,RUPT SUR SAONE,70360,RUPT SUR SAONE,,47.6507919517,5.92865307597,457,,Rupt-sur-Saône,Rupt-sur-Saône,70,Haute-Saône,27,Bourgogne-Franche-Comté
70459,ST BARTHELEMY,70270,ST BARTHELEMY,,47.7410393174,6.60409347666,459,,Saint-Barthélemy,Saint-Barthélemy,70,Haute-Saône,27,Bourgogne-Franche-Comté
70460,ST BRESSON,70280,ST BRESSON,,47.8772733622,6.50674206786,460,,Saint-Bresson,Saint-Bresson,70,Haute-Saône,27,Bourgogne-Franche-Comté
70461,ST BROING,70100,ST BROING,,47.4552363284,5.67975286287,461,,Saint-Broing,Saint-Broing,70,Haute-Saône,27,Bourgogne-Franche-Comté
70462,ST FERJEUX,70110,ST FERJEUX,,47.5457392836,6.50713066482,462,,Saint-Ferjeux,Saint-Ferjeux,70,Haute-Saône,27,Bourgogne-Franche-Comté
70463,ST GAND,70130,ST GAND,,47.508338203,5.83393822546,463,,Saint-Gand,Saint-Gand,70,Haute-Saône,27,Bourgogne-Franche-Comté
70464,ST GERMAIN,70200,ST GERMAIN,,47.7246175208,6.51957770989,464,,Saint-Germain,Saint-Germain,70,Haute-Saône,27,Bourgogne-Franche-Comté
70466,ST LOUP NANTOUARD,70100,ST LOUP NANTOUARD,,47.4305059621,5.72854485774,466,,Saint-Loup-Nantouard,Saint-Loup-Nantouard,70,Haute-Saône,27,Bourgogne-Franche-Comté
70466,ST LOUP NANTOUARD,70100,ST LOUP NANTOUARD,NANTOUARD,47.4305059621,5.72854485774,466,,Saint-Loup-Nantouard,Saint-Loup-Nantouard,70,Haute-Saône,27,Bourgogne-Franche-Comté
70467,ST LOUP SUR SEMOUSE,70800,ST LOUP SUR SEMOUSE,,47.8785688968,6.26623762115,467,,Saint-Loup-sur-Semouse,Saint-Loup-sur-Semouse,70,Haute-Saône,27,Bourgogne-Franche-Comté
70468,ST MARCEL,70500,ST MARCEL,,47.8253832848,5.8194773188,468,,Saint-Marcel,Saint-Marcel,70,Haute-Saône,27,Bourgogne-Franche-Comté
70469,STE MARIE EN CHANOIS,70310,STE MARIE EN CHANOIS,,47.8381487494,6.52006378078,469,,Sainte-Marie-en-Chanois,Sainte-Marie-en-Chanois,70,Haute-Saône,27,Bourgogne-Franche-Comté
70470,STE MARIE EN CHAUX,70300,STE MARIE EN CHAUX,,47.7964768334,6.29978833276,470,,Sainte-Marie-en-Chaux,Sainte-Marie-en-Chaux,70,Haute-Saône,27,Bourgogne-Franche-Comté
70471,STE REINE,70700,STE REINE,,47.5000576886,5.77812589576,471,,Sainte-Reine,Sainte-Reine,70,Haute-Saône,27,Bourgogne-Franche-Comté
70472,ST REMY,70160,ST REMY,,47.8375769959,6.10898257377,472,,Saint-Remy,Saint-Remy,70,Haute-Saône,27,Bourgogne-Franche-Comté
70473,ST SAUVEUR,70300,ST SAUVEUR,,47.7954572386,6.39510352305,473,,Saint-Sauveur,Saint-Sauveur,70,Haute-Saône,27,Bourgogne-Franche-Comté
70474,ST SULPICE,70110,ST SULPICE,,47.5725334014,6.45017356755,474,,Saint-Sulpice,Saint-Sulpice,70,Haute-Saône,27,Bourgogne-Franche-Comté
70475,ST VALBERT,70300,ST VALBERT,,47.855243792,6.39385591913,475,,Saint-Valbert,Saint-Valbert,70,Haute-Saône,27,Bourgogne-Franche-Comté
70476,SAPONCOURT,70210,SAPONCOURT,,47.8694358214,6.01884561838,476,,Saponcourt,Saponcourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70477,SAULNOT,70400,SAULNOT,,47.5703099756,6.62953525499,477,,Saulnot,Saulnot,70,Haute-Saône,27,Bourgogne-Franche-Comté
70477,SAULNOT,70400,SAULNOT,CORCELLES,47.5703099756,6.62953525499,477,,Saulnot,Saulnot,70,Haute-Saône,27,Bourgogne-Franche-Comté
70477,SAULNOT,70400,SAULNOT,GONVILLARS,47.5703099756,6.62953525499,477,,Saulnot,Saulnot,70,Haute-Saône,27,Bourgogne-Franche-Comté
70478,SAULX,70240,SAULX,,47.6946947931,6.27713089237,478,,Saulx,Saulx,70,Haute-Saône,27,Bourgogne-Franche-Comté
70479,SAUVIGNEY LES GRAY,70100,SAUVIGNEY LES GRAY,,47.4579344262,5.73262620609,479,,Sauvigney-lès-Gray,Sauvigney-lès-Gray,70,Haute-Saône,27,Bourgogne-Franche-Comté
70480,SAUVIGNEY LES PESMES,70140,SAUVIGNEY LES PESMES,,47.3068166664,5.56895996428,480,,Sauvigney-lès-Pesmes,Sauvigney-lès-Pesmes,70,Haute-Saône,27,Bourgogne-Franche-Comté
70481,SAVOYEUX,70130,SAVOYEUX,,47.5470516036,5.73627689065,481,,Savoyeux,Savoyeux,70,Haute-Saône,27,Bourgogne-Franche-Comté
70482,SCEY SUR SAONE ET ST ALBIN,70360,SCEY SUR SAONE ET ST ALBIN,,47.6798278841,5.96387998278,482,,Scey-sur-Saône-et-Saint-Albin,Scey-sur-Saône-et-Saint-Albin,70,Haute-Saône,27,Bourgogne-Franche-Comté
70483,SCYE,70170,SCYE,,47.6584667551,6.0572353758,483,,Scye,Scye,70,Haute-Saône,27,Bourgogne-Franche-Comté
70484,SECENANS,70400,SECENANS,,47.548679436,6.56746247212,484,,Secenans,Secenans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70485,SELLES,70210,SELLES,,47.9720841546,6.09157358569,485,,Selles,Selles,70,Haute-Saône,27,Bourgogne-Franche-Comté
70486,SEMMADON,70120,SEMMADON,,47.7396556417,5.8813057187,486,,Semmadon,Semmadon,70,Haute-Saône,27,Bourgogne-Franche-Comté
70487,SENARGENT MIGNAFANS,70110,SENARGENT MIGNAFANS,,47.5662742905,6.52981736725,487,,Senargent-Mignafans,Senargent-Mignafans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70487,SENARGENT MIGNAFANS,70110,SENARGENT MIGNAFANS,MIGNAFANS,47.5662742905,6.52981736725,487,,Senargent-Mignafans,Senargent-Mignafans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70488,SENONCOURT,70160,SENONCOURT,,47.8367830368,6.05857627249,488,,Senoncourt,Senoncourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70489,SERVANCE,70440,SERVANCE,,47.8257288068,6.68597884501,489,,Servance-Miellin,Servance-Miellin,70,Haute-Saône,27,Bourgogne-Franche-Comté
70490,SERVIGNEY,70240,SERVIGNEY,,47.7303296374,6.29784435036,490,,Servigney,Servigney,70,Haute-Saône,27,Bourgogne-Franche-Comté
70491,SEVEUX,70130,SEVEUX,,47.5359774659,5.76901424234,491,,Seveux,Seveux,70,Haute-Saône,27,Bourgogne-Franche-Comté
70492,SOING CUBRY CHARENTENAY,70130,SOING CUBRY CHARENTENAY,,47.5851238509,5.88891675749,492,,Soing-Cubry-Charentenay,Soing-Cubry-Charentenay,70,Haute-Saône,27,Bourgogne-Franche-Comté
70492,SOING CUBRY CHARENTENAY,70130,SOING CUBRY CHARENTENAY,CHARENTENAY,47.5851238509,5.88891675749,492,,Soing-Cubry-Charentenay,Soing-Cubry-Charentenay,70,Haute-Saône,27,Bourgogne-Franche-Comté
70492,SOING CUBRY CHARENTENAY,70130,SOING CUBRY CHARENTENAY,CUBRY LES SOING,47.5851238509,5.88891675749,492,,Soing-Cubry-Charentenay,Soing-Cubry-Charentenay,70,Haute-Saône,27,Bourgogne-Franche-Comté
70493,SORANS LES BREUREY,70190,SORANS LES BREUREY,,47.3963302607,6.04703992891,493,,Sorans-lès-Breurey,Sorans-lès-Breurey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70494,SORNAY,70150,SORNAY,,47.2812350721,5.6962342284,494,,Sornay,Sornay,70,Haute-Saône,27,Bourgogne-Franche-Comté
70496,TARTECOURT,70500,TARTECOURT,,47.8399254984,5.97669885968,496,,Tartécourt,Tartécourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70497,TAVEY,70400,TAVEY,,47.5620843234,6.74072275837,497,,Tavey,Tavey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70498,TERNUAY MELAY ET ST HILAIRE,70270,TERNUAY MELAY ET ST HILAIRE,,47.7961623597,6.6326445002,498,,Ternuay-Melay-et-Saint-Hilaire,Ternuay-Melay-et-Saint-Hilaire,70,Haute-Saône,27,Bourgogne-Franche-Comté
70499,THEULEY,70120,THEULEY,,47.6226401654,5.82022410565,499,,Theuley,Theuley,70,Haute-Saône,27,Bourgogne-Franche-Comté
70500,THIEFFRANS,70230,THIEFFRANS,,47.5068100716,6.30413332639,500,,Thieffrans,Thieffrans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70501,THIENANS,70230,THIENANS,,47.4697221913,6.27863279834,501,,Thiénans,Thiénans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70502,TINCEY ET PONTREBEAU,70120,TINCEY ET PONTREBEAU,,47.6032317362,5.78494948833,502,,Tincey-et-Pontrebeau,Tincey-et-Pontrebeau,70,Haute-Saône,27,Bourgogne-Franche-Comté
70503,TRAITIEFONTAINE,70190,TRAITIEFONTAINE,,47.4214104613,6.09590296552,503,,Traitiéfontaine,Traitiéfontaine,70,Haute-Saône,27,Bourgogne-Franche-Comté
70504,TRAVES,70360,TRAVES,,47.6060894924,5.96272642453,504,,Traves,Traves,70,Haute-Saône,27,Bourgogne-Franche-Comté
70505,LE TREMBLOIS,70100,LE TREMBLOIS,,47.3776500721,5.58714893059,505,Le,Tremblois,Le Tremblois,70,Haute-Saône,27,Bourgogne-Franche-Comté
70506,TREMOINS,70400,TREMOINS,,47.5648137282,6.70462228201,506,,Trémoins,Trémoins,70,Haute-Saône,27,Bourgogne-Franche-Comté
70507,TRESILLEY,70190,TRESILLEY,,47.44510679,6.0276765683,507,,Trésilley,Trésilley,70,Haute-Saône,27,Bourgogne-Franche-Comté
70509,TROMAREY,70150,TROMAREY,,47.3358125625,5.72291943926,509,,Tromarey,Tromarey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70510,VADANS,70140,VADANS,,47.3519943594,5.57942602132,510,,Vadans,Vadans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70511,VAITE,70180,VAITE,,47.5846720639,5.71496489496,511,,Vaite,Vaite,70,Haute-Saône,27,Bourgogne-Franche-Comté
70512,LA VAIVRE,70320,LA VAIVRE,,47.9132600992,6.36701482318,512,La,Vaivre,La Vaivre,70,Haute-Saône,27,Bourgogne-Franche-Comté
70513,VAIVRE ET MONTOILLE,70000,VAIVRE ET MONTOILLE,,47.633860629,6.10644479493,513,,Vaivre-et-Montoille,Vaivre-et-Montoille,70,Haute-Saône,27,Bourgogne-Franche-Comté
70514,VALAY,70140,VALAY,,47.3357551317,5.63755798352,514,,Valay,Valay,70,Haute-Saône,27,Bourgogne-Franche-Comté
70515,LE VAL DE GOUHENANS,70200,LE VAL DE GOUHENANS,,47.6238998324,6.48703960291,515,Le,Val-de-Gouhenans,Le Val-de-Gouhenans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70516,VALLEROIS LE BOIS,70000,VALLEROIS LE BOIS,,47.5524024642,6.2989589565,516,,Vallerois-le-Bois,Vallerois-le-Bois,70,Haute-Saône,27,Bourgogne-Franche-Comté
70517,VALLEROIS LORIOZ,70000,VALLEROIS LORIOZ,,47.565277669,6.15445387283,517,,Vallerois-Lorioz,Vallerois-Lorioz,70,Haute-Saône,27,Bourgogne-Franche-Comté
70518,LE VAL ST ELOI,70160,LE VAL ST ELOI,,47.7444513672,6.17780909313,518,Le,Val-Saint-Éloi,Le Val-Saint-Éloi,70,Haute-Saône,27,Bourgogne-Franche-Comté
70519,VANDELANS,70190,VANDELANS,,47.3997867498,6.16016748513,519,,Vandelans,Vandelans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70520,VANNE,70130,VANNE,,47.6047607958,5.84690959522,520,,Vanne,Vanne,70,Haute-Saône,27,Bourgogne-Franche-Comté
70521,VANTOUX ET LONGEVELLE,70700,VANTOUX ET LONGEVELLE,,47.4485121912,5.83803356504,521,,Vantoux-et-Longevelle,Vantoux-et-Longevelle,70,Haute-Saône,27,Bourgogne-Franche-Comté
70522,VAROGNE,70240,VAROGNE,,47.7190126678,6.20728397771,522,,Varogne,Varogne,70,Haute-Saône,27,Bourgogne-Franche-Comté
70523,VARS,70600,VARS,,47.5377653039,5.52005449376,523,,Vars,Vars,70,Haute-Saône,27,Bourgogne-Franche-Comté
70524,VAUCHOUX,70170,VAUCHOUX,,47.6579709662,6.03308496328,524,,Vauchoux,Vauchoux,70,Haute-Saône,27,Bourgogne-Franche-Comté
70525,VAUCONCOURT NERVEZAIN,70120,VAUCONCOURT NERVEZAIN,,47.657168298,5.83264558833,525,,Vauconcourt-Nervezain,Vauconcourt-Nervezain,70,Haute-Saône,27,Bourgogne-Franche-Comté
70526,VAUVILLERS,70210,VAUVILLERS,,47.927696482,6.10999352598,526,,Vauvillers,Vauvillers,70,Haute-Saône,27,Bourgogne-Franche-Comté
70527,VAUX LE MONCELOT,70700,VAUX LE MONCELOT,,47.4769782546,5.90408520859,527,,Vaux-le-Moncelot,Vaux-le-Moncelot,70,Haute-Saône,27,Bourgogne-Franche-Comté
70528,VELESMES ECHEVANNE,70100,VELESMES ECHEVANNE,,47.421541856,5.69067658656,528,,Velesmes-Échevanne,Velesmes-Échevanne,70,Haute-Saône,27,Bourgogne-Franche-Comté
70528,VELESMES ECHEVANNE,70100,VELESMES ECHEVANNE,ECHEVANNE,47.421541856,5.69067658656,528,,Velesmes-Échevanne,Velesmes-Échevanne,70,Haute-Saône,27,Bourgogne-Franche-Comté
70529,VELET,70100,VELET,,47.4258350219,5.56489492159,529,,Velet,Velet,70,Haute-Saône,27,Bourgogne-Franche-Comté
70530,VELLECHEVREUX ET COURBENANS,70110,VELLECHEVREUX ET COURBENANS,,47.5413124373,6.54443417858,530,,Vellechevreux-et-Courbenans,Vellechevreux-et-Courbenans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70531,VELLECLAIRE,70700,VELLECLAIRE,,47.4257773503,5.87383892919,531,,Velleclaire,Velleclaire,70,Haute-Saône,27,Bourgogne-Franche-Comté
70532,VELLEFAUX,70000,VELLEFAUX,,47.5529035792,6.13885306049,532,,Vellefaux,Vellefaux,70,Haute-Saône,27,Bourgogne-Franche-Comté
70533,VELLEFREY ET VELLEFRANGE,70700,VELLEFREY ET VELLEFRANGE,,47.4434504028,5.81383880863,533,,Vellefrey-et-Vellefrange,Vellefrey-et-Vellefrange,70,Haute-Saône,27,Bourgogne-Franche-Comté
70534,VELLEFRIE,70240,VELLEFRIE,,47.7022975155,6.22137740616,534,,Vellefrie,Vellefrie,70,Haute-Saône,27,Bourgogne-Franche-Comté
70535,VELLEGUINDRY ET LEVRECEY,70000,VELLEGUINDRY ET LEVRECEY,,47.5563995344,6.08993283091,535,,Velleguindry-et-Levrecey,Velleguindry-et-Levrecey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70536,VELLE LE CHATEL,70000,VELLE LE CHATEL,,47.5867750201,6.05578313726,536,,Velle-le-Châtel,Velle-le-Châtel,70,Haute-Saône,27,Bourgogne-Franche-Comté
70537,VELLEMINFROY,70240,VELLEMINFROY,,47.6600380526,6.31768021672,537,,Velleminfroy,Velleminfroy,70,Haute-Saône,27,Bourgogne-Franche-Comté
70538,VELLEMOZ,70700,VELLEMOZ,,47.4766541807,5.79152233813,538,,Vellemoz,Vellemoz,70,Haute-Saône,27,Bourgogne-Franche-Comté
70539,VELLEXON QUEUTREY ET VAUDEY,70130,VELLEXON QUEUTREY ET VAUDEY,,47.5502350367,5.80894856908,539,,Vellexon-Queutrey-et-Vaudey,Vellexon-Queutrey-et-Vaudey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70540,VELLOREILLE LES CHOYE,70700,VELLOREILLE LES CHOYE,,47.390189566,5.72389415506,540,,Velloreille-lès-Choye,Velloreille-lès-Choye,70,Haute-Saône,27,Bourgogne-Franche-Comté
70541,VELORCEY,70300,VELORCEY,,47.7857581021,6.25028247897,541,,Velorcey,Velorcey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70542,VENERE,70100,VENERE,,47.3587412355,5.6767085991,542,,Venère,Venère,70,Haute-Saône,27,Bourgogne-Franche-Comté
70544,LA VERGENNE,70200,LA VERGENNE,,47.6149187489,6.52058432739,544,La,Vergenne,La Vergenne,70,Haute-Saône,27,Bourgogne-Franche-Comté
70545,VENISEY,70500,VENISEY,,47.8354222731,6.00091771151,545,,Venisey,Venisey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70546,VEREUX,70180,VEREUX,,47.5272015799,5.63819879272,546,,Vereux,Vereux,70,Haute-Saône,27,Bourgogne-Franche-Comté
70547,VERLANS,70400,VERLANS,,47.5703887849,6.71887157746,547,,Verlans,Verlans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70548,VERNOIS SUR MANCE,70500,VERNOIS SUR MANCE,,47.8426818751,5.77808690918,548,,Vernois-sur-Mance,Vernois-sur-Mance,70,Haute-Saône,27,Bourgogne-Franche-Comté
70549,LA VERNOTTE,70130,LA VERNOTTE,,47.5093017415,5.8714246202,549,La,Vernotte,La Vernotte,70,Haute-Saône,27,Bourgogne-Franche-Comté
70550,VESOUL,70000,VESOUL,,47.6320408648,6.1548458149,550,,Vesoul,Vesoul,70,Haute-Saône,27,Bourgogne-Franche-Comté
70552,VILLAFANS,70110,VILLAFANS,,47.5819931692,6.47563792817,552,,Villafans,Villafans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70553,VILLARGENT,70110,VILLARGENT,,47.5420470778,6.48586205018,553,,Villargent,Villargent,70,Haute-Saône,27,Bourgogne-Franche-Comté
70554,VILLARS LE PAUTEL,70500,VILLARS LE PAUTEL,,47.8959698741,5.92002657844,554,,Villars-le-Pautel,Villars-le-Pautel,70,Haute-Saône,27,Bourgogne-Franche-Comté
70555,LA VILLEDIEU EN FONTENETTE,70160,LA VILLEDIEU EN FONTENETTE,,47.7712074637,6.20381518294,555,La,Villedieu-en-Fontenette,La Villedieu-en-Fontenette,70,Haute-Saône,27,Bourgogne-Franche-Comté
70557,VILLEFRANCON,70700,VILLEFRANCON,,47.4129035904,5.74249429132,557,,Villefrancon,Villefrancon,70,Haute-Saône,27,Bourgogne-Franche-Comté
70558,LA VILLENEUVE BELLENOYE LA MAIZE,70240,LA VILLENEUVE BELLENOYE LA MAIZE,,47.7099462261,6.24130408921,558,La,Villeneuve-Bellenoye-et-la-Maize,La Villeneuve-Bellenoye-et-la-Maize,70,Haute-Saône,27,Bourgogne-Franche-Comté
70559,VILLEPAROIS,70000,VILLEPAROIS,,47.6635986725,6.18151955342,559,,Villeparois,Villeparois,70,Haute-Saône,27,Bourgogne-Franche-Comté
70560,VILLERS BOUTON,70190,VILLERS BOUTON,,47.4496113899,5.97526749135,560,,Villers-Bouton,Villers-Bouton,70,Haute-Saône,27,Bourgogne-Franche-Comté
70561,VILLERSEXEL,70110,VILLERSEXEL,,47.559065822,6.42909523129,561,,Villersexel,Villersexel,70,Haute-Saône,27,Bourgogne-Franche-Comté
70562,VILLERS LA VILLE,70110,VILLERS LA VILLE,,47.5502733142,6.4666688568,562,,Villers-la-Ville,Villers-la-Ville,70,Haute-Saône,27,Bourgogne-Franche-Comté
70563,VILLERS LE SEC,70000,VILLERS LE SEC,,47.5827042725,6.23899893135,563,,Villers-le-Sec,Villers-le-Sec,70,Haute-Saône,27,Bourgogne-Franche-Comté
70564,VILLERS LES LUXEUIL,70300,VILLERS LES LUXEUIL,,47.7687966014,6.27995216443,564,,Villers-lès-Luxeuil,Villers-lès-Luxeuil,70,Haute-Saône,27,Bourgogne-Franche-Comté
70565,VILLERS PATER,70190,VILLERS PATER,,47.4737371816,6.16934140108,565,,Villers-Pater,Villers-Pater,70,Haute-Saône,27,Bourgogne-Franche-Comté
70566,VILLERS SUR PORT,70170,VILLERS SUR PORT,,47.713815334,6.08321182678,566,,Villers-sur-Port,Villers-sur-Port,70,Haute-Saône,27,Bourgogne-Franche-Comté
70567,VILLERS SUR SAULNOT,70400,VILLERS SUR SAULNOT,,47.5481669716,6.64791604275,567,,Villers-sur-Saulnot,Villers-sur-Saulnot,70,Haute-Saône,27,Bourgogne-Franche-Comté
70568,VILLERS VAUDEY,70120,VILLERS VAUDEY,,47.6735158749,5.75631215452,568,,Villers-Vaudey,Villers-Vaudey,70,Haute-Saône,27,Bourgogne-Franche-Comté
70569,VILORY,70240,VILORY,,47.7301466997,6.22337661531,569,,Vilory,Vilory,70,Haute-Saône,27,Bourgogne-Franche-Comté
70571,VISONCOURT,70300,VISONCOURT,,47.7522729682,6.29965399455,571,,Visoncourt,Visoncourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70572,VITREY SUR MANCE,70500,VITREY SUR MANCE,,47.812015486,5.75797516664,572,,Vitrey-sur-Mance,Vitrey-sur-Mance,70,Haute-Saône,27,Bourgogne-Franche-Comté
70573,LA VOIVRE,70310,LA VOIVRE,,47.8185555868,6.55480377432,573,La,Voivre,La Voivre,70,Haute-Saône,27,Bourgogne-Franche-Comté
70574,VOLON,70180,VOLON,,47.6261770622,5.7378809498,574,,Volon,Volon,70,Haute-Saône,27,Bourgogne-Franche-Comté
70575,VORAY SUR L OGNON,70190,VORAY SUR L OGNON,,47.3499834337,6.01396560488,575,,Voray-sur-l'Ognon,Voray-sur-l'Ognon,70,Haute-Saône,27,Bourgogne-Franche-Comté
70576,VOUGECOURT,70500,VOUGECOURT,,47.9447349937,5.9940682612,576,,Vougécourt,Vougécourt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70577,VOUHENANS,70200,VOUHENANS,,47.6424367134,6.49403579676,577,,Vouhenans,Vouhenans,70,Haute-Saône,27,Bourgogne-Franche-Comté
70578,VREGILLE,70150,VREGILLE,,47.3308670504,5.8868762035,578,,Vregille,Vregille,70,Haute-Saône,27,Bourgogne-Franche-Comté
70579,VYANS LE VAL,70400,VYANS LE VAL,,47.5502849273,6.76152440954,579,,Vyans-le-Val,Vyans-le-Val,70,Haute-Saône,27,Bourgogne-Franche-Comté
70580,VY LE FERROUX,70130,VY LE FERROUX,,47.5876286452,5.96724404567,580,,Vy-le-Ferroux,Vy-le-Ferroux,70,Haute-Saône,27,Bourgogne-Franche-Comté
70581,VY LES LURE,70200,VY LES LURE,,47.650001251,6.44237601386,581,,Vy-lès-Lure,Vy-lès-Lure,70,Haute-Saône,27,Bourgogne-Franche-Comté
70582,VY LES RUPT,70120,VY LES RUPT,,47.6393561962,5.89227575956,582,,Vy-lès-Rupt,Vy-lès-Rupt,70,Haute-Saône,27,Bourgogne-Franche-Comté
70583,VY LES FILAIN,70230,VY LES FILAIN,,47.5064123545,6.1953048468,583,,Vy-lès-Filain,Vy-lès-Filain,70,Haute-Saône,27,Bourgogne-Franche-Comté
71001,L ABERGEMENT DE CUISERY,71290,L ABERGEMENT DE CUISERY,,46.5775754985,4.96115611529,1,L',Abergement-de-Cuisery,L'Abergement-de-Cuisery,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71002,L ABERGEMENT STE COLOMBE,71370,L ABERGEMENT STE COLOMBE,,46.754037733,5.0286449583,2,L',Abergement-Sainte-Colombe,L'Abergement-Sainte-Colombe,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71003,ALLEREY SUR SAONE,71350,ALLEREY SUR SAONE,,46.9138309803,4.97484420794,3,,Allerey-sur-Saône,Allerey-sur-Saône,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71004,ALLERIOT,71380,ALLERIOT,,46.7988419834,4.95551290173,4,,Allériot,Allériot,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71005,ALUZE,71510,ALUZE,,46.8518515249,4.6901351827,5,,Aluze,Aluze,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71006,AMANZE,71800,AMANZE,,46.3264716768,4.23626914802,6,,Amanzé,Amanzé,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71007,AMEUGNY,71460,AMEUGNY,,46.5238475796,4.66808403552,7,,Ameugny,Ameugny,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71008,ANGLURE SOUS DUN,71170,ANGLURE SOUS DUN,,46.235903333,4.37061936256,8,,Anglure-sous-Dun,Anglure-sous-Dun,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71009,ANOST,71550,ANOST,,47.0770713545,4.09241779804,9,,Anost,Anost,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71010,ANTULLY,71400,ANTULLY,,46.8926537731,4.40284807189,10,,Antully,Antully,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71011,ANZY LE DUC,71110,ANZY LE DUC,,46.3239156269,4.07162173983,11,,Anzy-le-Duc,Anzy-le-Duc,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71012,ARTAIX,71110,ARTAIX,,46.2492375573,3.99353976024,12,,Artaix,Artaix,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71013,AUTHUMES,71270,AUTHUMES,,46.8899153529,5.30758337993,13,,Authumes,Authumes,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71014,AUTUN,71400,AUTUN,,46.945536773,4.31060069532,14,,Autun,Autun,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71014,AUTUN,71400,AUTUN,ST PANTALEON,46.945536773,4.31060069532,14,,Autun,Autun,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71015,AUXY,71400,AUXY,,46.9410622998,4.41734656437,15,,Auxy,Auxy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71016,AZE,71260,AZE,,46.4318867242,4.74963746686,16,,Azé,Azé,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71017,BALLORE,71220,BALLORE,,46.5341038702,4.37558759241,17,,Ballore,Ballore,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71018,BANTANGES,71500,BANTANGES,,46.605124682,5.12155053335,18,,Bantanges,Bantanges,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71019,BARIZEY,71640,BARIZEY,,46.7917541418,4.6720477978,19,,Barizey,Barizey,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71020,BARNAY,71540,BARNAY,,47.0852204967,4.32108474276,20,,Barnay,Barnay,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71021,BARON,71120,BARON,,46.4934149552,4.28185595803,21,,Baron,Baron,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71022,BAUDEMONT,71800,BAUDEMONT,,46.2935955045,4.28502745064,22,,Baudemont,Baudemont,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71023,BAUDRIERES,71370,BAUDRIERES,,46.6659094039,5.00248487776,23,,Baudrières,Baudrières,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71024,BAUGY,71110,BAUGY,,46.3075519171,4.03236080451,24,,Baugy,Baugy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71025,BEAUBERY,71220,BEAUBERY,,46.4104723077,4.39542233391,25,,Beaubery,Beaubery,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71026,BEAUMONT SUR GROSNE,71240,BEAUMONT SUR GROSNE,,46.6692532687,4.86174711968,26,,Beaumont-sur-Grosne,Beaumont-sur-Grosne,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71027,BEAUREPAIRE EN BRESSE,71580,BEAUREPAIRE EN BRESSE,,46.6672623715,5.39464840853,27,,Beaurepaire-en-Bresse,Beaurepaire-en-Bresse,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71028,BEAUVERNOIS,71270,BEAUVERNOIS,,46.8413806403,5.4403170068,28,,Beauvernois,Beauvernois,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71029,BELLEVESVRE,71270,BELLEVESVRE,,46.8484271655,5.36394090745,29,,Bellevesvre,Bellevesvre,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71030,BERGESSERIN,71250,BERGESSERIN,,46.3990910615,4.56240663444,30,,Bergesserin,Bergesserin,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71030,BERGESSERIN,71250,BERGESSERIN,BERGESSERIN LA CHATELAINE,46.3990910615,4.56240663444,30,,Bergesserin,Bergesserin,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71031,BERZE LE CHATEL,71960,BERZE LE CHATEL,,46.3957782254,4.68241463331,31,,Berzé-le-Châtel,Berzé-le-Châtel,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71032,BERZE LA VILLE,71960,BERZE LA VILLE,,46.3683212977,4.70227131172,32,,Berzé-la-Ville,Berzé-la-Ville,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71033,BEY,71620,BEY,,46.8212987385,4.96979880981,33,,Bey,Bey,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71034,BISSEY SOUS CRUCHAUD,71390,BISSEY SOUS CRUCHAUD,,46.7292045246,4.69324571138,34,,Bissey-sous-Cruchaud,Bissey-sous-Cruchaud,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71035,BISSY LA MACONNAISE,71260,BISSY LA MACONNAISE,,46.4854125388,4.77572068002,35,,Bissy-la-Mâconnaise,Bissy-la-Mâconnaise,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71036,BISSY SOUS UXELLES,71460,BISSY SOUS UXELLES,,46.5685167956,4.72714301912,36,,Bissy-sous-Uxelles,Bissy-sous-Uxelles,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71037,BISSY SUR FLEY,71460,BISSY SUR FLEY,,46.6633352989,4.6213986546,37,,Bissy-sur-Fley,Bissy-sur-Fley,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71038,LES BIZOTS,71710,LES BIZOTS,,46.7515452356,4.38270809741,38,Les,Bizots,Les Bizots,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71039,BLANOT,71250,BLANOT,,46.4810503908,4.74030734552,39,,Blanot,Blanot,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71040,BLANZY,71450,BLANZY,,46.7042568403,4.39315980341,40,,Blanzy,Blanzy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71041,BOIS STE MARIE,71800,BOIS STE MARIE,,46.3321423377,4.35330322456,41,,Bois-Sainte-Marie,Bois-Sainte-Marie,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71042,BONNAY,71460,BONNAY,,46.5495831057,4.62537710045,42,,Bonnay,Bonnay,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71043,LES BORDES,71350,LES BORDES,,46.8986962713,5.04813103141,43,Les,Bordes,Les Bordes,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71044,BOSJEAN,71330,BOSJEAN,,46.7696786287,5.34727924927,44,,Bosjean,Bosjean,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71045,BOUHANS,71330,BOUHANS,,46.7772891552,5.29235408701,45,,Bouhans,Bouhans,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71046,LA BOULAYE,71320,LA BOULAYE,,46.7373325553,4.1515013438,46,La,Boulaye,La Boulaye,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71047,BOURBON LANCY,71140,BOURBON LANCY,,46.6204280383,3.76862021355,47,,Bourbon-Lancy,Bourbon-Lancy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71048,BOURG LE COMTE,71110,BOURG LE COMTE,,46.3102835322,3.9837558428,48,,Bourg-le-Comte,Bourg-le-Comte,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71050,BOURGVILAIN,71520,BOURGVILAIN,,46.3647474088,4.63745643185,50,,Bourgvilain,Bourgvilain,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71051,BOUZERON,71150,BOUZERON,,46.8915695256,4.72745431658,51,,Bouzeron,Bouzeron,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71052,BOYER,71700,BOYER,,46.5997643138,4.90335376574,52,,Boyer,Boyer,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71054,BRAGNY SUR SAONE,71350,BRAGNY SUR SAONE,,46.9213828915,5.03177576974,54,,Bragny-sur-Saône,Bragny-sur-Saône,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71055,BRANDON,71520,BRANDON,,46.3514341446,4.57280957023,55,,Brandon,Brandon,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71056,BRANGES,71500,BRANGES,,46.652956978,5.16400928644,56,,Branges,Branges,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71057,BRAY,71250,BRAY,,46.5037410885,4.70644057084,57,,Bray,Bray,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71058,BRESSE SUR GROSNE,71460,BRESSE SUR GROSNE,,46.5946563977,4.72811970624,58,,Bresse-sur-Grosne,Bresse-sur-Grosne,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71059,LE BREUIL,71670,LE BREUIL,,46.7952744773,4.49374098192,59,Le,Breuil,Le Breuil,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71060,BRIANT,71110,BRIANT,,46.3008717665,4.15112417263,60,,Briant,Briant,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71061,BRIENNE,71290,BRIENNE,,46.5560939784,5.02368261906,61,,Brienne,Brienne,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71062,BRION,71190,BRION,,46.9163069203,4.24167330561,62,,Brion,Brion,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71063,BROYE,71190,BROYE,,46.888541214,4.30809477889,63,,Broye,Broye,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71064,BRUAILLES,71500,BRUAILLES,,46.5971442892,5.25884270547,64,,Bruailles,Bruailles,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71065,BUFFIERES,71250,BUFFIERES,,46.4294911714,4.53776264069,65,,Buffières,Buffières,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71066,BURGY,71260,BURGY,,46.4641123942,4.83082154067,66,,Burgy,Burgy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71067,BURNAND,71460,BURNAND,,46.6019325058,4.63373013891,67,,Burnand,Burnand,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71068,BURZY,71460,BURZY,,46.5949751474,4.58580654162,68,,Burzy,Burzy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71069,BUSSIERES,71960,BUSSIERES,,46.333719714,4.71047131646,69,,Bussières,Bussières,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71070,BUXY,71390,BUXY,,46.7130408432,4.71342619185,70,,Buxy,Buxy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71071,CERON,71110,CERON,,46.2805440909,3.93730889705,71,,Céron,Céron,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71072,CERSOT,71390,CERSOT,,46.7053648589,4.63239889903,72,,Cersot,Cersot,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71073,CHAGNY,71150,CHAGNY,,46.8985800984,4.77433585145,73,,Chagny,Chagny,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71074,CHAINTRE,71570,CHAINTRE,,46.2582470143,4.77030455349,74,,Chaintré,Chaintré,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71075,CHALMOUX,71140,CHALMOUX,,46.5936710904,3.85215646789,75,,Chalmoux,Chalmoux,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71076,CHALON SUR SAONE,71100,CHALON SUR SAONE,,46.7900288793,4.85191555008,76,,Chalon-sur-Saône,Chalon-sur-Saône,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71077,CHAMBILLY,71110,CHAMBILLY,,46.2825163052,3.99815426784,77,,Chambilly,Chambilly,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71078,CHAMILLY,71510,CHAMILLY,,46.8667282501,4.68139596476,78,,Chamilly,Chamilly,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71079,CHAMPAGNAT,71480,CHAMPAGNAT,,46.4834634223,5.36915009557,79,,Champagnat,Champagnat,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71080,CHAMPAGNY SOUS UXELLES,71460,CHAMPAGNY SOUS UXELLES,,46.5792196401,4.74765167584,80,,Champagny-sous-Uxelles,Champagny-sous-Uxelles,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71081,CHAMPFORGEUIL,71530,CHAMPFORGEUIL,,46.8156309692,4.82545441016,81,,Champforgeuil,Champforgeuil,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71082,CHAMPLECY,71120,CHAMPLECY,,46.4628044788,4.22805088541,82,,Champlecy,Champlecy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71084,CHANES,71570,CHANES,,46.2454748177,4.75829079989,84,,Chânes,Chânes,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71085,CHANGE,21340,CHANGE,,46.9307644453,4.62818331108,85,,Change,Change,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71086,CHANGY,71120,CHANGY,,46.4121876754,4.2519227256,86,,Changy,Changy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71087,CHAPAIZE,71460,CHAPAIZE,,46.5529585412,4.73602422687,87,,Chapaize,Chapaize,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71088,LA CHAPELLE AU MANS,71130,LA CHAPELLE AU MANS,,46.6290281598,3.97752672203,88,La,Chapelle-au-Mans,La Chapelle-au-Mans,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71089,LA CHAPELLE DE BRAGNY,71240,LA CHAPELLE DE BRAGNY,,46.6195852003,4.75996353083,89,La,Chapelle-de-Bragny,La Chapelle-de-Bragny,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71090,LA CHAPELLE DE GUINCHAY,71570,LA CHAPELLE DE GUINCHAY,,46.2183395706,4.76075538674,90,La,Chapelle-de-Guinchay,La Chapelle-de-Guinchay,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71090,LA CHAPELLE DE GUINCHAY,71570,LA CHAPELLE DE GUINCHAY,LA CHAPELLE PONTANEVAUX,46.2183395706,4.76075538674,90,La,Chapelle-de-Guinchay,La Chapelle-de-Guinchay,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71090,LA CHAPELLE DE GUINCHAY,71570,LA CHAPELLE DE GUINCHAY,PONTANEVAUX,46.2183395706,4.76075538674,90,La,Chapelle-de-Guinchay,La Chapelle-de-Guinchay,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71091,LA CHAPELLE DU MONT DE FRANCE,71520,LA CHAPELLE DU MONT DE FRANCE,,46.3814725305,4.54090737962,91,La,Chapelle-du-Mont-de-France,La Chapelle-du-Mont-de-France,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71092,LA CHAPELLE NAUDE,71500,LA CHAPELLE NAUDE,,46.5871900268,5.19349664362,92,La,Chapelle-Naude,La Chapelle-Naude,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71093,LA CHAPELLE ST SAUVEUR,71310,LA CHAPELLE ST SAUVEUR,,46.8468026106,5.26448829043,93,La,Chapelle-Saint-Sauveur,La Chapelle-Saint-Sauveur,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71094,LA CHAPELLE SOUS BRANCION,71700,LA CHAPELLE SOUS BRANCION,,46.5559348952,4.78706778132,94,La,Chapelle-sous-Brancion,La Chapelle-sous-Brancion,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71095,LA CHAPELLE SOUS DUN,71800,LA CHAPELLE SOUS DUN,,46.2638539851,4.29882173643,95,La,Chapelle-sous-Dun,La Chapelle-sous-Dun,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71096,LA CHAPELLE SOUS UCHON,71190,LA CHAPELLE SOUS UCHON,,46.8348259179,4.24313052371,96,La,Chapelle-sous-Uchon,La Chapelle-sous-Uchon,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71097,LA CHAPELLE THECLE,71470,LA CHAPELLE THECLE,,46.5566698457,5.10211951342,97,La,Chapelle-Thècle,La Chapelle-Thècle,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71098,CHARBONNAT,71320,CHARBONNAT,,46.7791482329,4.11380902203,98,,Charbonnat,Charbonnat,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71099,CHARBONNIERES,71260,CHARBONNIERES,,46.3887744081,4.83162031903,99,,Charbonnières,Charbonnières,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71100,CHARDONNAY,71700,CHARDONNAY,,46.5087214301,4.84818799475,100,,Chardonnay,Chardonnay,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71101,CHARETTE VARENNES,71270,CHARETTE VARENNES,,46.9006196221,5.19727816775,101,,Charette-Varennes,Charette-Varennes,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71101,CHARETTE VARENNES,71270,CHARETTE VARENNES,VARENNE SUR LE DOUBS,46.9006196221,5.19727816775,101,,Charette-Varennes,Charette-Varennes,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71102,LA CHARMEE,71100,LA CHARMEE,,46.7214775415,4.79585753439,102,La,Charmée,La Charmée,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71103,CHARMOY,71710,CHARMOY,,46.7692538445,4.31625377039,103,,Charmoy,Charmoy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71104,CHARNAY LES CHALON,71350,CHARNAY LES CHALON,,46.9343265104,5.08431654646,104,,Charnay-lès-Chalon,Charnay-lès-Chalon,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71105,CHARNAY LES MACON,71850,CHARNAY LES MACON,,46.3041852737,4.78693813476,105,,Charnay-lès-Mâcon,Charnay-lès-Mâcon,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71106,CHAROLLES,71120,CHAROLLES,,46.4476067658,4.28009429694,106,,Charolles,Charolles,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71107,CHARRECEY,71510,CHARRECEY,,46.8363564868,4.66839372197,107,,Charrecey,Charrecey,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71108,CHASSELAS,71570,CHASSELAS,,46.2782503008,4.71871730618,108,,Chasselas,Chasselas,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71109,CHASSEY LE CAMP,71150,CHASSEY LE CAMP,,46.8874072539,4.70297479178,109,,Chassey-le-Camp,Chassey-le-Camp,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71110,CHASSIGNY SOUS DUN,71170,CHASSIGNY SOUS DUN,,46.2375878745,4.29248216952,110,,Chassigny-sous-Dun,Chassigny-sous-Dun,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71111,CHASSY,71130,CHASSY,,46.5832260551,4.11167998063,111,,Chassy,Chassy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71112,CHATEAU,71250,CHATEAU,,46.4222263876,4.59356497363,112,,Château,Château,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71113,CHATEAUNEUF,71740,CHATEAUNEUF,,46.2104681552,4.26056533405,113,,Châteauneuf,Châteauneuf,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71115,CHATEL MORON,71510,CHATEL MORON,,46.7908182989,4.637184003,115,,Châtel-Moron,Châtel-Moron,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71116,CHATENAY,71800,CHATENAY,,46.2913546739,4.38266694341,116,,Châtenay,Châtenay,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71117,CHATENOY EN BRESSE,71380,CHATENOY EN BRESSE,,46.7936385088,4.91410813762,117,,Châtenoy-en-Bresse,Châtenoy-en-Bresse,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71118,CHATENOY LE ROYAL,71880,CHATENOY LE ROYAL,,46.788588171,4.8058563943,118,,Châtenoy-le-Royal,Châtenoy-le-Royal,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71119,CHAUDENAY,71150,CHAUDENAY,,46.9154246356,4.79538440748,119,,Chaudenay,Chaudenay,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71120,CHAUFFAILLES,71170,CHAUFFAILLES,,46.2065828065,4.34173989083,120,,Chauffailles,Chauffailles,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71121,LA CHAUX,71310,LA CHAUX,,46.8226144674,5.27235831208,121,La,Chaux,La Chaux,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71122,CHEILLY LES MARANGES,71150,CHEILLY LES MARANGES,,46.8926365567,4.66641980553,122,,Cheilly-lès-Maranges,Cheilly-lès-Maranges,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71123,CHENAY LE CHATEL,71340,CHENAY LE CHATEL,,46.228920415,3.93811744372,123,,Chenay-le-Châtel,Chenay-le-Châtel,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71124,CHENOVES,71390,CHENOVES,,46.6662558007,4.70092580314,124,,Chenôves,Chenôves,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71125,CHERIZET,71250,CHERIZET,,46.5171593187,4.56760616223,125,,Chérizet,Chérizet,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71126,CHEVAGNY LES CHEVRIERES,71960,CHEVAGNY LES CHEVRIERES,,46.3358003869,4.77205661745,126,,Chevagny-les-Chevrières,Chevagny-les-Chevrières,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71127,CHEVAGNY SUR GUYE,71220,CHEVAGNY SUR GUYE,,46.5403336038,4.50069717167,127,,Chevagny-sur-Guye,Chevagny-sur-Guye,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71128,CHIDDES,71220,CHIDDES,,46.4572326637,4.51390755775,128,,Chiddes,Chiddes,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71129,CHISSEY EN MORVAN,71540,CHISSEY EN MORVAN,,47.1217509821,4.21531030353,129,,Chissey-en-Morvan,Chissey-en-Morvan,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71130,CHISSEY LES MACON,71460,CHISSEY LES MACON,,46.5214699897,4.74430277103,130,,Chissey-lès-Mâcon,Chissey-lès-Mâcon,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71131,CIEL,71350,CIEL,,46.8716291957,5.05115500472,131,,Ciel,Ciel,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71132,CIRY LE NOBLE,71420,CIRY LE NOBLE,,46.5966175845,4.2978619831,132,,Ciry-le-Noble,Ciry-le-Noble,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71133,LA CLAYETTE,71800,LA CLAYETTE,,46.2892944317,4.31079967145,133,La,Clayette,La Clayette,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71134,CLERMAIN,71520,CLERMAIN,,46.3661832627,4.58968637273,134,,Clermain,Clermain,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71135,CLESSE,71260,CLESSE,,46.418154831,4.82185640271,135,,Clessé,Clessé,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71136,CLESSY,71130,CLESSY,,46.5558912899,4.08579941536,136,,Clessy,Clessy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71137,CLUNY,71250,CLUNY,,46.4303628582,4.67033276327,137,,Cluny,Cluny,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71139,COLLONGE EN CHAROLLAIS,71460,COLLONGE EN CHAROLLAIS,,46.6422857602,4.53104899924,139,,Collonge-en-Charollais,Collonge-en-Charollais,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71140,COLLONGE LA MADELEINE,71360,COLLONGE LA MADELEINE,,46.9407183573,4.52621280337,140,,Collonge-la-Madeleine,Collonge-la-Madeleine,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71141,COLOMBIER EN BRIONNAIS,71800,COLOMBIER EN BRIONNAIS,,46.3479871035,4.33327603076,141,,Colombier-en-Brionnais,Colombier-en-Brionnais,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71142,LA COMELLE,71990,LA COMELLE,,46.8809529598,4.11919829871,142,La,Comelle,La Comelle,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71143,CONDAL,71480,CONDAL,,46.4647126508,5.28216575914,143,,Condal,Condal,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71144,CORDESSE,71540,CORDESSE,,47.043408177,4.33366013416,144,,Cordesse,Cordesse,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71145,CORMATIN,71460,CORMATIN,,46.535532183,4.69561266719,145,,Cormatin,Cormatin,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71146,CORTAMBERT,71250,CORTAMBERT,,46.4704137076,4.69741359705,146,,Cortambert,Cortambert,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71147,CORTEVAIX,71460,CORTEVAIX,,46.5281488272,4.64137291939,147,,Cortevaix,Cortevaix,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71148,COUBLANC,71170,COUBLANC,,46.1713810264,4.27380729464,148,,Coublanc,Coublanc,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71149,COUCHES,71490,COUCHES,,46.869155076,4.56431598435,149,,Couches,Couches,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71150,CRECHES SUR SAONE,71680,CRECHES SUR SAONE,,46.2410718628,4.78718291781,150,,Crêches-sur-Saône,Crêches-sur-Saône,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71151,CREOT,71490,CREOT,,46.9169195884,4.61189688029,151,,Créot,Créot,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71152,CRESSY SUR SOMME,71760,CRESSY SUR SOMME,,46.7008961258,3.86729542324,152,,Cressy-sur-Somme,Cressy-sur-Somme,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71153,LE CREUSOT,71200,LE CREUSOT,,46.8068539189,4.42642301163,153,Le,Creusot,Le Creusot,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71154,CRISSEY,71530,CRISSEY,,46.8169773833,4.88423907508,154,,Crissey,Crissey,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71155,CRONAT,71140,CRONAT,,46.7204911418,3.7115602827,155,,Cronat,Cronat,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71156,CRUZILLE,71260,CRUZILLE,,46.5070246965,4.78675266634,156,,Cruzille,Cruzille,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71157,CUISEAUX,71480,CUISEAUX,,46.5047162667,5.36399526057,157,,Cuiseaux,Cuiseaux,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71158,CUISERY,71290,CUISERY,,46.5569799009,4.99361378862,158,,Cuisery,Cuisery,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71159,CULLES LES ROCHES,71460,CULLES LES ROCHES,,46.6451171565,4.65261786019,159,,Culles-les-Roches,Culles-les-Roches,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71160,CURBIGNY,71800,CURBIGNY,,46.3116932174,4.31698207413,160,,Curbigny,Curbigny,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71161,CURDIN,71130,CURDIN,,46.5930182243,3.99722029696,161,,Curdin,Curdin,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71162,CURGY,71400,CURGY,,46.9886049961,4.38277730487,162,,Curgy,Curgy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71163,CURTIL SOUS BUFFIERES,71520,CURTIL SOUS BUFFIERES,,46.4027317458,4.52645305897,163,,Curtil-sous-Buffières,Curtil-sous-Buffières,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71164,CURTIL SOUS BURNAND,71460,CURTIL SOUS BURNAND,,46.5832448679,4.6312448943,164,,Curtil-sous-Burnand,Curtil-sous-Burnand,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71165,CUSSY EN MORVAN,71550,CUSSY EN MORVAN,,47.0930977975,4.1636345374,165,,Cussy-en-Morvan,Cussy-en-Morvan,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71166,CUZY,71320,CUZY,,46.7609852867,4.03171098199,166,,Cuzy,Cuzy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71167,DAMEREY,71620,DAMEREY,,46.8344484396,4.99429761869,167,,Damerey,Damerey,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71168,DAMPIERRE EN BRESSE,71310,DAMPIERRE EN BRESSE,,46.8274117737,5.20316156952,168,,Dampierre-en-Bresse,Dampierre-en-Bresse,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71169,DAVAYE,71960,DAVAYE,,46.3037816172,4.74869737883,169,,Davayé,Davayé,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71170,DEMIGNY,71150,DEMIGNY,,46.9145180669,4.84793098636,170,,Demigny,Demigny,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71171,DENNEVY,71510,DENNEVY,,46.8656403196,4.64491140945,171,,Dennevy,Dennevy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71172,DETTEY,71190,DETTEY,,46.7671010152,4.1860690251,172,,Dettey,Dettey,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71173,DEVROUZE,71330,DEVROUZE,,46.7614578185,5.16719023343,173,,Devrouze,Devrouze,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71174,DEZIZE LES MARANGES,71150,DEZIZE LES MARANGES,,46.9232421366,4.6596290959,174,,Dezize-lès-Maranges,Dezize-lès-Maranges,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71175,DICONNE,71330,DICONNE,,46.7779978405,5.11904852026,175,,Diconne,Diconne,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71176,DIGOIN,71160,DIGOIN,,46.4891651235,4.02511604849,176,,Digoin,Digoin,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71176,DIGOIN,71160,DIGOIN,VIGNY LES PARAY,46.4891651235,4.02511604849,176,,Digoin,Digoin,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71177,DOMMARTIN LES CUISEAUX,71480,DOMMARTIN LES CUISEAUX,,46.4998326659,5.28977666204,177,,Dommartin-lès-Cuiseaux,Dommartin-lès-Cuiseaux,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71178,DOMPIERRE LES ORMES,71520,DOMPIERRE LES ORMES,,46.354950302,4.48081055025,178,,Dompierre-les-Ormes,Dompierre-les-Ormes,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71178,DOMPIERRE LES ORMES,71520,DOMPIERRE LES ORMES,MEULIN,46.354950302,4.48081055025,178,,Dompierre-les-Ormes,Dompierre-les-Ormes,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71179,DOMPIERRE SOUS SANVIGNES,71420,DOMPIERRE SOUS SANVIGNES,,46.6640724574,4.22501088032,179,,Dompierre-sous-Sanvignes,Dompierre-sous-Sanvignes,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71180,DONZY LE NATIONAL,71250,DONZY LE NATIONAL,,46.4494789467,4.56487665856,180,,Donzy-le-National,Donzy-le-National,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71181,DONZY LE PERTUIS,71250,DONZY LE PERTUIS,,46.4479607517,4.71724886753,181,,Donzy-le-Pertuis,Donzy-le-Pertuis,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71182,DRACY LE FORT,71640,DRACY LE FORT,,46.7967008104,4.77049585592,182,,Dracy-le-Fort,Dracy-le-Fort,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71183,DRACY LES COUCHES,71490,DRACY LES COUCHES,,46.8931291226,4.56630546473,183,,Dracy-lès-Couches,Dracy-lès-Couches,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71184,DRACY ST LOUP,71400,DRACY ST LOUP,,47.0145624747,4.34030409282,184,,Dracy-Saint-Loup,Dracy-Saint-Loup,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71185,DYO,71800,DYO,,46.3638960777,4.28153745354,185,,Dyo,Dyo,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71186,ECUELLES,71350,ECUELLES,,46.951371973,5.0701693,186,,Écuelles,Écuelles,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71187,ECUISSES,71210,ECUISSES,,46.7558604047,4.52493211411,187,,Écuisses,Écuisses,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71188,EPERTULLY,71360,EPERTULLY,,46.9449937024,4.60074542788,188,,Épertully,Épertully,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71189,EPERVANS,71380,EPERVANS,,46.7457712756,4.90239745895,189,,Épervans,Épervans,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71190,EPINAC,71360,EPINAC,,46.9908631777,4.51976953086,190,,Épinac,Épinac,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71191,ESSERTENNE,71510,ESSERTENNE,,46.8109722514,4.54150474121,191,,Essertenne,Essertenne,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71192,ETANG SUR ARROUX,71190,ETANG SUR ARROUX,,46.859401459,4.17913091336,192,,Étang-sur-Arroux,Étang-sur-Arroux,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71193,ETRIGNY,71240,ETRIGNY,,46.5892768003,4.79160201098,193,,Étrigny,Étrigny,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71193,ETRIGNY,71240,ETRIGNY,CHAMPLIEU,46.5892768003,4.79160201098,193,,Étrigny,Étrigny,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71194,FARGES LES CHALON,71150,FARGES LES CHALON,,46.8336764432,4.80644535224,194,,Farges-lès-Chalon,Farges-lès-Chalon,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71195,FARGES LES MACON,71700,FARGES LES MACON,,46.5117669109,4.9115948195,195,,Farges-lès-Mâcon,Farges-lès-Mâcon,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71196,LE FAY,71580,LE FAY,,46.6644310853,5.33674534745,196,Le,Fay,Le Fay,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71198,FLACEY EN BRESSE,71580,FLACEY EN BRESSE,,46.5949707867,5.38150826754,198,,Flacey-en-Bresse,Flacey-en-Bresse,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71199,FLAGY,71250,FLAGY,,46.4925794198,4.63893731321,199,,Flagy,Flagy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71200,FLEURY LA MONTAGNE,71340,FLEURY LA MONTAGNE,,46.1997654702,4.1256489354,200,,Fleury-la-Montagne,Fleury-la-Montagne,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71201,FLEY,71390,FLEY,,46.6759560566,4.63908082855,201,,Fley,Fley,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71202,FONTAINES,71150,FONTAINES,,46.8516219974,4.78077093032,202,,Fontaines,Fontaines,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71203,FONTENAY,71120,FONTENAY,,46.4766084028,4.29956693128,203,,Fontenay,Fontenay,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71204,FRAGNES LA LOYERE,71530,FRAGNES LA LOYERE,,46.8312797426,4.84940221006,204,,Fragnes-La Loyère,Fragnes-La Loyère,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71204,FRAGNES LA LOYERE,71530,FRAGNES LA LOYERE,LA LOYERE,46.8312797426,4.84940221006,204,,Fragnes-La Loyère,Fragnes-La Loyère,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71205,FRANGY EN BRESSE,71330,FRANGY EN BRESSE,,46.7194233644,5.33221685118,205,,Frangy-en-Bresse,Frangy-en-Bresse,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71206,LA FRETTE,71440,LA FRETTE,,46.6412331111,5.04960758377,206,La,Frette,La Frette,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71207,FRETTERANS,71270,FRETTERANS,,46.923240269,5.28789884271,207,,Fretterans,Fretterans,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71208,FRONTENARD,71270,FRONTENARD,,46.8991001814,5.16314384306,208,,Frontenard,Frontenard,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71209,FRONTENAUD,71580,FRONTENAUD,,46.5482784948,5.29083572414,209,,Frontenaud,Frontenaud,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71210,FUISSE,71960,FUISSE,,46.2791382816,4.74647735926,210,,Fuissé,Fuissé,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71212,GENELARD,71420,GENELARD,,46.5798385493,4.25003029458,212,,Génelard,Génelard,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71213,LA GENETE,71290,LA GENETE,,46.549436103,5.04979591392,213,La,Genête,La Genête,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71214,GENOUILLY,71460,GENOUILLY,,46.6527283424,4.5689153242,214,,Genouilly,Genouilly,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71215,GERGY,71590,GERGY,,46.8791801218,4.92129441098,215,,Gergy,Gergy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71216,GERMAGNY,71460,GERMAGNY,,46.6733437634,4.59764029825,216,,Germagny,Germagny,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71217,GERMOLLES SUR GROSNE,71520,GERMOLLES SUR GROSNE,,46.2775172772,4.59556800746,217,,Germolles-sur-Grosne,Germolles-sur-Grosne,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71218,GIBLES,71800,GIBLES,,46.3179773987,4.38022998111,218,,Gibles,Gibles,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71219,GIGNY SUR SAONE,71240,GIGNY SUR SAONE,,46.6538485126,4.93846686758,219,,Gigny-sur-Saône,Gigny-sur-Saône,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71220,GILLY SUR LOIRE,71160,GILLY SUR LOIRE,,46.5480062086,3.79284988791,220,,Gilly-sur-Loire,Gilly-sur-Loire,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71221,GIVRY,71640,GIVRY,,46.7702122725,4.75441496992,221,,Givry,Givry,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71222,GOURDON,71300,GOURDON,,46.6403109594,4.4421869118,222,,Gourdon,Gourdon,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71223,LA GRANDE VERRIERE,71990,LA GRANDE VERRIERE,,46.9688875676,4.14088119017,223,La,Grande-Verrière,La Grande-Verrière,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71224,GRANDVAUX,71430,GRANDVAUX,,46.5059558537,4.26498354082,224,,Grandvaux,Grandvaux,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71225,GRANGES,71390,GRANGES,,46.7369347397,4.75217241831,225,,Granges,Granges,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71226,GREVILLY,71700,GREVILLY,,46.5129383908,4.81939958218,226,,Grevilly,Grevilly,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71227,GRURY,71760,GRURY,,46.6645308499,3.91335098661,227,,Grury,Grury,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71228,GUERFAND,71620,GUERFAND,,46.782165395,5.02905971383,228,,Guerfand,Guerfand,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71229,LES GUERREAUX,71160,LES GUERREAUX,,46.5400960877,3.92347814201,229,Les,Guerreaux,Les Guerreaux,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71230,GUEUGNON,71130,GUEUGNON,,46.5939761041,4.0493689292,230,,Gueugnon,Gueugnon,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71231,LA GUICHE,71220,LA GUICHE,,46.5288680917,4.44086956543,231,La,Guiche,La Guiche,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71232,HAUTEFOND,71600,HAUTEFOND,,46.4353617469,4.17206207978,232,,Hautefond,Hautefond,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71233,L HOPITAL LE MERCIER,71600,L HOPITAL LE MERCIER,,46.3944535195,4.00899233183,233,L',Hôpital-le-Mercier,L'Hôpital-le-Mercier,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71234,HUILLY SUR SEILLE,71290,HUILLY SUR SEILLE,,46.6051659343,5.053133023,234,,Huilly-sur-Seille,Huilly-sur-Seille,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71235,HURIGNY,71870,HURIGNY,,46.3429969068,4.79636186257,235,,Hurigny,Hurigny,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71236,IGE,71960,IGE,,46.4053982627,4.73487976051,236,,Igé,Igé,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71237,IGORNAY,71540,IGORNAY,,47.0569683621,4.37872566745,237,,Igornay,Igornay,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71238,IGUERANDE,71340,IGUERANDE,,46.2165272056,4.07699692762,238,,Iguerande,Iguerande,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71239,ISSY L EVEQUE,71760,ISSY L EVEQUE,,46.7135521882,4.0005402064,239,,Issy-l'Évêque,Issy-l'Évêque,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71240,JALOGNY,71250,JALOGNY,,46.4126361231,4.6349159927,240,,Jalogny,Jalogny,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71241,JAMBLES,71640,JAMBLES,,46.7734363658,4.68027991657,241,,Jambles,Jambles,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71242,JONCY,71460,JONCY,,46.6145870121,4.55010993204,242,,Joncy,Joncy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71243,JOUDES,71480,JOUDES,,46.4732075841,5.33948956282,243,,Joudes,Joudes,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71244,JOUVENCON,71290,JOUVENCON,,46.5761997548,5.05204538096,244,,Jouvençon,Jouvençon,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71245,JUGY,71240,JUGY,,46.6044707808,4.86148307035,245,,Jugy,Jugy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71246,JUIF,71440,JUIF,,46.6827335674,5.16245141859,246,,Juif,Juif,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71247,JULLY LES BUXY,71390,JULLY LES BUXY,,46.6874141273,4.72317009125,247,,Jully-lès-Buxy,Jully-lès-Buxy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71248,LACROST,71700,LACROST,,46.5693107193,4.93607004395,248,,Lacrost,Lacrost,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71249,LAIVES,71240,LAIVES,,46.6527155264,4.83486721421,249,,Laives,Laives,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71250,LAIZE,71870,LAIZE,,46.3824360235,4.80406518536,250,,Laizé,Laizé,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71251,LAIZY,71190,LAIZY,,46.9108484487,4.18328808367,251,,Laizy,Laizy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71252,LALHEUE,71240,LALHEUE,,46.650155549,4.79257635293,252,,Lalheue,Lalheue,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71253,LANS,71380,LANS,,46.7644863686,4.93208606266,253,,Lans,Lans,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71254,LAYS SUR LE DOUBS,71270,LAYS SUR LE DOUBS,,46.9201256677,5.24330208466,254,,Lays-sur-le-Doubs,Lays-sur-le-Doubs,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71255,LESME,71140,LESME,,46.6481584864,3.72120388217,255,,Lesme,Lesme,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71256,LESSARD EN BRESSE,71440,LESSARD EN BRESSE,,46.7410862327,5.08364517208,256,,Lessard-en-Bresse,Lessard-en-Bresse,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71257,LESSARD LE NATIONAL,71530,LESSARD LE NATIONAL,,46.8732289132,4.84609901293,257,,Lessard-le-National,Lessard-le-National,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71258,LEYNES,71570,LEYNES,,46.2647305147,4.72937186217,258,,Leynes,Leynes,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71259,LIGNY EN BRIONNAIS,71110,LIGNY EN BRIONNAIS,,46.2402667832,4.20177769774,259,,Ligny-en-Brionnais,Ligny-en-Brionnais,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71261,LOISY,71290,LOISY,,46.5904043725,5.01289765681,261,,Loisy,Loisy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71262,LONGEPIERRE,71270,LONGEPIERRE,,46.9339285323,5.20327520847,262,,Longepierre,Longepierre,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71263,LOUHANS,71500,LOUHANS,,46.6351883548,5.23839292789,263,,Louhans,Louhans,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71263,LOUHANS,71500,LOUHANS,CHATEAURENAUD,46.6351883548,5.23839292789,263,,Louhans,Louhans,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71264,LOURNAND,71250,LOURNAND,,46.4645640534,4.64914404204,264,,Lournand,Lournand,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71266,LUCENAY L EVEQUE,71540,LUCENAY L EVEQUE,,47.0840697933,4.26102089354,266,,Lucenay-l'Évêque,Lucenay-l'Évêque,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71267,LUGNY,71260,LUGNY,,46.4805805718,4.81912339905,267,,Lugny,Lugny,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71268,LUGNY LES CHAROLLES,71120,LUGNY LES CHAROLLES,,46.4112364438,4.20394785541,268,,Lugny-lès-Charolles,Lugny-lès-Charolles,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71269,LUX,71100,LUX,,46.7490720688,4.86040694261,269,,Lux,Lux,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71270,MACON,71000,MACON,,46.3205511756,4.81842529639,270,,Mâcon,Mâcon,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71270,MACON,71000,MACON,FLACE LES MACON,46.3205511756,4.81842529639,270,,Mâcon,Mâcon,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71270,MACON,71000,MACON,LOCHE,46.3205511756,4.81842529639,270,,Mâcon,Mâcon,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71270,MACON,71000,MACON,SENNECE LES MACON,46.3205511756,4.81842529639,270,,Mâcon,Mâcon,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71270,MACON,71000,MACON,ST JEAN LE PRICHE,46.3205511756,4.81842529639,270,,Mâcon,Mâcon,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71271,MAILLY,71340,MAILLY,,46.2279316064,4.11194727703,271,,Mailly,Mailly,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71272,MALAY,71460,MALAY,,46.56639793,4.68622845385,272,,Malay,Malay,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71273,MALTAT,71140,MALTAT,,46.6751423253,3.80940604841,273,,Maltat,Maltat,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71274,MANCEY,71240,MANCEY,,46.5696717803,4.84535325128,274,,Mancey,Mancey,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71275,MARCIGNY,71110,MARCIGNY,,46.2756272512,4.039116013,275,,Marcigny,Marcigny,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71276,MARCILLY LA GUEURCE,71120,MARCILLY LA GUEURCE,,46.395826222,4.30447472199,276,,Marcilly-la-Gueurce,Marcilly-la-Gueurce,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71277,MARCILLY LES BUXY,71390,MARCILLY LES BUXY,,46.7267163499,4.59181890283,277,,Marcilly-lès-Buxy,Marcilly-lès-Buxy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71278,MARIGNY,71300,MARIGNY,,46.6807912321,4.46979546252,278,,Marigny,Marigny,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71279,LE ROUSSET MARIZY,71220,LE ROUSSET MARIZY,LE ROUSSET,46.5716241899,4.39993345947,279,Le,Rousset-Marizy,Le Rousset-Marizy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71279,LE ROUSSET MARIZY,71220,LE ROUSSET MARIZY,MARIZY,46.5716241899,4.39993345947,279,Le,Rousset-Marizy,Le Rousset-Marizy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71280,MARLY SOUS ISSY,71760,MARLY SOUS ISSY,,46.7292090902,3.93392160044,280,,Marly-sous-Issy,Marly-sous-Issy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71281,MARLY SUR ARROUX,71420,MARLY SUR ARROUX,,46.6196801412,4.13156192602,281,,Marly-sur-Arroux,Marly-sur-Arroux,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71282,MARMAGNE,71710,MARMAGNE,,46.8429753005,4.37123447631,282,,Marmagne,Marmagne,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71283,MARNAY,71240,MARNAY,,46.6987582427,4.92604799837,283,,Marnay,Marnay,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71284,MARTAILLY LES BRANCION,71700,MARTAILLY LES BRANCION,,46.531885064,4.79714660531,284,,Martailly-lès-Brancion,Martailly-lès-Brancion,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71285,MARTIGNY LE COMTE,71220,MARTIGNY LE COMTE,,46.5367776065,4.33366674854,285,,Martigny-le-Comte,Martigny-le-Comte,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71286,MARY,71300,MARY,,46.6104508131,4.50041386834,286,,Mary,Mary,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71287,MASSILLY,71250,MASSILLY,,46.4908350512,4.66945256607,287,,Massilly,Massilly,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71288,MASSY,71250,MASSY,,46.4898679694,4.60948900655,288,,Massy,Massy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71289,MATOUR,71520,MATOUR,,46.3110636207,4.47716868181,289,,Matour,Matour,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71290,MAZILLE,71250,MAZILLE,,46.391550747,4.59913263178,290,,Mazille,Mazille,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71291,MELAY,71340,MELAY,,46.1993430373,4.01334555514,291,,Melay,Melay,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71292,MELLECEY,71640,MELLECEY,,46.8104311793,4.7565417637,292,,Mellecey,Mellecey,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71292,MELLECEY,71640,MELLECEY,GERMOLLES,46.8104311793,4.7565417637,292,,Mellecey,Mellecey,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71293,MENETREUIL,71470,MENETREUIL,,46.5832082014,5.12524084824,293,,Ménetreuil,Ménetreuil,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71294,MERCUREY,71640,MERCUREY,,46.834318433,4.72413233505,294,,Mercurey,Mercurey,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71294,MERCUREY,71640,MERCUREY,BOURGNEUF VAL D OR,46.834318433,4.72413233505,294,,Mercurey,Mercurey,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71295,MERVANS,71310,MERVANS,,46.7945134241,5.18412548455,295,,Mervans,Mervans,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71296,MESSEY SUR GROSNE,71390,MESSEY SUR GROSNE,,46.6508093785,4.75084615409,296,,Messey-sur-Grosne,Messey-sur-Grosne,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71297,MESVRES,71190,MESVRES,,46.8702793384,4.25320711622,297,,Mesvres,Mesvres,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71299,MILLY LAMARTINE,71960,MILLY LAMARTINE,,46.3485783556,4.69624606174,299,,Milly-Lamartine,Milly-Lamartine,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71300,LE MIROIR,71480,LE MIROIR,,46.5431506465,5.33864320841,300,Le,Miroir,Le Miroir,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71301,MONT,71140,MONT,,46.6315974398,3.82797477122,301,,Mont,Mont,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71302,MONTAGNY LES BUXY,71390,MONTAGNY LES BUXY,,46.7050483061,4.66958139328,302,,Montagny-lès-Buxy,Montagny-lès-Buxy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71303,MONTAGNY PRES LOUHANS,71500,MONTAGNY PRES LOUHANS,,46.6581608546,5.28366044756,303,,Montagny-près-Louhans,Montagny-près-Louhans,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71304,MONTAGNY SUR GROSNE,71520,MONTAGNY SUR GROSNE,,46.353742323,4.54441079024,304,,Montagny-sur-Grosne,Montagny-sur-Grosne,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71305,MONTBELLET,71260,MONTBELLET,,46.4713429723,4.8783736486,305,,Montbellet,Montbellet,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71306,MONTCEAU LES MINES,71300,MONTCEAU LES MINES,,46.6762539128,4.3540960404,306,,Montceau-les-Mines,Montceau-les-Mines,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71307,MONTCEAUX L ETOILE,71110,MONTCEAUX L ETOILE,,46.3564508429,4.04500323823,307,,Montceaux-l'Étoile,Montceaux-l'Étoile,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71308,MONTCEAUX RAGNY,71240,MONTCEAUX RAGNY,,46.6203875932,4.84178033117,308,,Montceaux-Ragny,Montceaux-Ragny,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71309,MONTCENIS,71710,MONTCENIS,,46.786245174,4.39093818161,309,,Montcenis,Montcenis,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71310,MONTCHANIN,71210,MONTCHANIN,,46.7473169925,4.47424011768,310,,Montchanin,Montchanin,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71311,MONTCONY,71500,MONTCONY,,46.6943670716,5.29800608262,311,,Montcony,Montcony,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71312,MONTCOY,71620,MONTCOY,,46.7961241606,4.9981535244,312,,Montcoy,Montcoy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71313,MONTHELON,71400,MONTHELON,,46.9594714287,4.22119599878,313,,Monthelon,Monthelon,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71314,MONTJAY,71310,MONTJAY,,46.8055434402,5.29985900019,314,,Montjay,Montjay,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71315,MONT LES SEURRE,71270,MONT LES SEURRE,,46.9529037641,5.12901997473,315,,Mont-lès-Seurre,Mont-lès-Seurre,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71316,MONTMELARD,71520,MONTMELARD,,46.3421169485,4.41407367209,316,,Montmelard,Montmelard,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71317,MONTMORT,71320,MONTMORT,,46.7418911079,4.09607288589,317,,Montmort,Montmort,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71318,MONTPONT EN BRESSE,71470,MONTPONT EN BRESSE,,46.541562777,5.17433501769,318,,Montpont-en-Bresse,Montpont-en-Bresse,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71319,MONTRET,71440,MONTRET,,46.6835248214,5.11326580543,319,,Montret,Montret,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71320,MONT ST VINCENT,71300,MONT ST VINCENT,,46.6461358907,4.49195438858,320,,Mont-Saint-Vincent,Mont-Saint-Vincent,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71321,MOREY,71510,MOREY,,46.7915983107,4.58680108045,321,,Morey,Morey,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71322,MORLET,71360,MORLET,,46.9536543261,4.50525767382,322,,Morlet,Morlet,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71323,MORNAY,71220,MORNAY,,46.4992237373,4.38103180018,323,,Mornay,Mornay,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71324,MOROGES,71390,MOROGES,,46.7507453415,4.67430662316,324,,Moroges,Moroges,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71325,LA MOTTE ST JEAN,71160,LA MOTTE ST JEAN,,46.5148078067,3.96007296995,325,La,Motte-Saint-Jean,La Motte-Saint-Jean,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71326,MOUTHIER EN BRESSE,71270,MOUTHIER EN BRESSE,,46.8638075135,5.38060673295,326,,Mouthier-en-Bresse,Mouthier-en-Bresse,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71327,MUSSY SOUS DUN,71170,MUSSY SOUS DUN,,46.2370094184,4.33413361908,327,,Mussy-sous-Dun,Mussy-sous-Dun,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71328,NANTON,71240,NANTON,,46.6196318731,4.81580507035,328,,Nanton,Nanton,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71329,NAVILLY,71270,NAVILLY,,46.9284745707,5.14455605951,329,,Navilly,Navilly,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71330,NEUVY GRANDCHAMP,71130,NEUVY GRANDCHAMP,,46.5932713521,3.92778768794,330,,Neuvy-Grandchamp,Neuvy-Grandchamp,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71331,NOCHIZE,71600,NOCHIZE,,46.4041760827,4.16485920346,331,,Nochize,Nochize,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71332,ORMES,71290,ORMES,,46.6333104747,4.96657248032,332,,Ormes,Ormes,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71333,OSLON,71380,OSLON,,46.7774536923,4.94292036797,333,,Oslon,Oslon,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71334,OUDRY,71420,OUDRY,,46.5829807312,4.1661581036,334,,Oudry,Oudry,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71335,OUROUX SOUS LE BOIS STE MARIE,71800,OUROUX SOUS LE BOIS STE MARIE,,46.3611048312,4.31018617706,335,,Ouroux-sous-le-Bois-Sainte-Marie,Ouroux-sous-le-Bois-Sainte-Marie,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71336,OUROUX SUR SAONE,71370,OUROUX SUR SAONE,,46.7242227229,4.95068555425,336,,Ouroux-sur-Saône,Ouroux-sur-Saône,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71337,OYE,71800,OYE,,46.3247162501,4.19347320106,337,,Oyé,Oyé,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71338,OZENAY,71700,OZENAY,,46.5352832941,4.84332783222,338,,Ozenay,Ozenay,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71339,OZOLLES,71120,OZOLLES,,46.3798633225,4.3603551512,339,,Ozolles,Ozolles,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71340,PALINGES,71430,PALINGES,,46.547452342,4.20782928989,340,,Palinges,Palinges,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71341,PALLEAU,71350,PALLEAU,,46.96578142,5.03202326419,341,,Palleau,Palleau,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71342,PARAY LE MONIAL,71600,PARAY LE MONIAL,,46.4431191464,4.11289928796,342,,Paray-le-Monial,Paray-le-Monial,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71343,PARIS L HOPITAL,71150,PARIS L HOPITAL,,46.9127885575,4.63958573146,343,,Paris-l'Hôpital,Paris-l'Hôpital,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71344,PASSY,71220,PASSY,,46.5408746813,4.53540370263,344,,Passy,Passy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71345,PERONNE,71260,PERONNE,,46.4412933211,4.8012887987,345,,Péronne,Péronne,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71346,PERRECY LES FORGES,71420,PERRECY LES FORGES,,46.621915608,4.22620349316,346,,Perrecy-les-Forges,Perrecy-les-Forges,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71347,PERREUIL,71510,PERREUIL,,46.8221260373,4.56745960102,347,,Perreuil,Perreuil,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71348,PERRIGNY SUR LOIRE,71160,PERRIGNY SUR LOIRE,,46.5434949213,3.85137583197,348,,Perrigny-sur-Loire,Perrigny-sur-Loire,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71349,LA PETITE VERRIERE,71400,LA PETITE VERRIERE,,47.044929839,4.16692654458,349,La,Petite-Verrière,La Petite-Verrière,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71350,PIERRECLOS,71960,PIERRECLOS,,46.3324202545,4.67135536115,350,,Pierreclos,Pierreclos,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71351,PIERRE DE BRESSE,71270,PIERRE DE BRESSE,,46.8824879885,5.25265750532,351,,Pierre-de-Bresse,Pierre-de-Bresse,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71351,PIERRE DE BRESSE,71270,PIERRE DE BRESSE,TERRANS,46.8824879885,5.25265750532,351,,Pierre-de-Bresse,Pierre-de-Bresse,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71352,LE PLANOIS,71330,LE PLANOIS,,46.7895553787,5.32263068043,352,Le,Planois,Le Planois,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71353,PLOTTES,71700,PLOTTES,,46.5333799704,4.88549659587,353,,Plottes,Plottes,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71354,POISSON,71600,POISSON,,46.3808508262,4.13353930479,354,,Poisson,Poisson,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71355,PONTOUX,71270,PONTOUX,,46.9088293038,5.12123849533,355,,Pontoux,Pontoux,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71356,POUILLOUX,71230,POUILLOUX,,46.6048282453,4.35349281443,356,,Pouilloux,Pouilloux,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71357,POURLANS,71270,POURLANS,,46.9630221086,5.23663173279,357,,Pourlans,Pourlans,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71358,PRESSY SOUS DONDIN,71220,PRESSY SOUS DONDIN,,46.4761059444,4.50397889767,358,,Pressy-sous-Dondin,Pressy-sous-Dondin,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71359,PRETY,71290,PRETY,,46.5394977837,4.95553905201,359,,Préty,Préty,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71360,PRISSE,71960,PRISSE,,46.3243137138,4.74816143404,360,,Prissé,Prissé,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71361,PRIZY,71800,PRIZY,,46.3515033821,4.21820421458,361,,Prizy,Prizy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71362,PRUZILLY,71570,PRUZILLY,,46.259412904,4.69749749783,362,,Pruzilly,Pruzilly,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71363,LE PULEY,71460,LE PULEY,,46.6760071385,4.56588748503,363,Le,Puley,Le Puley,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71364,LA RACINEUSE,71310,LA RACINEUSE,,46.831031386,5.16095151897,364,La,Racineuse,La Racineuse,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71365,RANCY,71290,RANCY,,46.6005131165,5.08307494584,365,,Rancy,Rancy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71366,RATENELLE,71290,RATENELLE,,46.5245517671,5.00102497581,366,,Ratenelle,Ratenelle,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71367,RATTE,71500,RATTE,,46.6395819633,5.30552532869,367,,Ratte,Ratte,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71368,RECLESNE,71540,RECLESNE,,47.0470566219,4.2846413148,368,,Reclesne,Reclesne,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71369,REMIGNY,71150,REMIGNY,,46.9096727588,4.7242069921,369,,Remigny,Remigny,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71370,RIGNY SUR ARROUX,71160,RIGNY SUR ARROUX,,46.5421616023,4.01816532874,370,,Rigny-sur-Arroux,Rigny-sur-Arroux,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71371,LA ROCHE VINEUSE,71960,LA ROCHE VINEUSE,,46.3510235915,4.74854768422,371,La,Roche-Vineuse,La Roche-Vineuse,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71372,ROMANECHE THORINS,71570,ROMANECHE THORINS,,46.1902603868,4.74347572682,372,,Romanèche-Thorins,Romanèche-Thorins,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71373,ROMENAY,71470,ROMENAY,,46.5142649874,5.08147006476,373,,Romenay,Romenay,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71374,ROSEY,71390,ROSEY,,46.7400858369,4.71424499336,374,,Rosey,Rosey,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71376,ROUSSILLON EN MORVAN,71550,ROUSSILLON EN MORVAN,,47.0214021445,4.10448328724,376,,Roussillon-en-Morvan,Roussillon-en-Morvan,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71377,ROYER,71700,ROYER,,46.5553154353,4.82322281115,377,,Royer,Royer,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71378,RULLY,71150,RULLY,,46.8728849421,4.75062759298,378,,Rully,Rully,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71379,SAGY,71580,SAGY,,46.5937236673,5.3241808449,379,,Sagy,Sagy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71380,SAILLENARD,71580,SAILLENARD,,46.6974756794,5.37229340001,380,,Saillenard,Saillenard,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71381,SAILLY,71250,SAILLY,,46.5326888362,4.56213978811,381,,Sailly,Sailly,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71382,ST AGNAN,71160,ST AGNAN,,46.5133587412,3.88738815956,382,,Saint-Agnan,Saint-Agnan,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71383,ST ALBAIN,71260,ST ALBAIN,,46.422400276,4.86818774017,383,,Saint-Albain,Saint-Albain,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71384,ST AMBREUIL,71240,ST AMBREUIL,,46.6810830382,4.81914623845,384,,Saint-Ambreuil,Saint-Ambreuil,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71385,ST AMOUR BELLEVUE,71570,ST AMOUR BELLEVUE,,46.2414353911,4.74041810825,385,,Saint-Amour-Bellevue,Saint-Amour-Bellevue,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71386,ST ANDRE EN BRESSE,71440,ST ANDRE EN BRESSE,,46.6509686442,5.08602391583,386,,Saint-André-en-Bresse,Saint-André-en-Bresse,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71387,ST ANDRE LE DESERT,71220,ST ANDRE LE DESERT,,46.4997623512,4.53459884587,387,,Saint-André-le-Désert,Saint-André-le-Désert,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71388,ST AUBIN EN CHAROLLAIS,71430,ST AUBIN EN CHAROLLAIS,,46.5008361838,4.2089560127,388,,Saint-Aubin-en-Charollais,Saint-Aubin-en-Charollais,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71389,ST AUBIN SUR LOIRE,71140,ST AUBIN SUR LOIRE,,46.5678204278,3.76149865404,389,,Saint-Aubin-sur-Loire,Saint-Aubin-sur-Loire,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71390,ST BERAIN SOUS SANVIGNES,71300,ST BERAIN SOUS SANVIGNES,,46.7158828922,4.29130777114,390,,Saint-Berain-sous-Sanvignes,Saint-Berain-sous-Sanvignes,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71391,ST BERAIN SUR DHEUNE,71510,ST BERAIN SUR DHEUNE,,46.8165810334,4.61430858653,391,,Saint-Bérain-sur-Dheune,Saint-Bérain-sur-Dheune,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71392,ST BOIL,71390,ST BOIL,,46.6416818485,4.69957680942,392,,Saint-Boil,Saint-Boil,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71393,ST BONNET DE CRAY,71340,ST BONNET DE CRAY,,46.202568549,4.16340067325,393,,Saint-Bonnet-de-Cray,Saint-Bonnet-de-Cray,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71394,ST BONNET DE JOUX,71220,ST BONNET DE JOUX,,46.4782854677,4.43348327243,394,,Saint-Bonnet-de-Joux,Saint-Bonnet-de-Joux,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71395,ST BONNET DE VIEILLE VIGNE,71430,ST BONNET DE VIEILLE VIGNE,,46.5304875791,4.2714393389,395,,Saint-Bonnet-de-Vieille-Vigne,Saint-Bonnet-de-Vieille-Vigne,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71396,ST BONNET EN BRESSE,71310,ST BONNET EN BRESSE,,46.8588763872,5.17827286067,396,,Saint-Bonnet-en-Bresse,Saint-Bonnet-en-Bresse,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71397,STE CECILE,71250,STE CECILE,,46.3853014921,4.6346635997,397,,Sainte-Cécile,Sainte-Cécile,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71398,ST CHRISTOPHE EN BRESSE,71370,ST CHRISTOPHE EN BRESSE,,46.7454161376,4.99090268478,398,,Saint-Christophe-en-Bresse,Saint-Christophe-en-Bresse,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71399,ST CHRISTOPHE EN BRIONNAIS,71800,ST CHRISTOPHE EN BRIONNAIS,,46.2848533492,4.19005721567,399,,Saint-Christophe-en-Brionnais,Saint-Christophe-en-Brionnais,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71400,ST CLEMENT SUR GUYE,71460,ST CLEMENT SUR GUYE,,46.6135549466,4.5866103902,400,,Saint-Clément-sur-Guye,Saint-Clément-sur-Guye,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71401,STE CROIX,71470,STE CROIX,,46.5587984571,5.23823090911,401,,Sainte-Croix,Sainte-Croix,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71402,ST CYR,71240,ST CYR,,46.6791536677,4.90050025353,402,,Saint-Cyr,Saint-Cyr,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71403,ST DENIS DE VAUX,71640,ST DENIS DE VAUX,,46.7925579715,4.70152860992,403,,Saint-Denis-de-Vaux,Saint-Denis-de-Vaux,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71404,ST DESERT,71390,ST DESERT,,46.7571405125,4.71340832367,404,,Saint-Désert,Saint-Désert,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71405,ST DIDIER EN BRESSE,71620,ST DIDIER EN BRESSE,,46.8507201385,5.10326207017,405,,Saint-Didier-en-Bresse,Saint-Didier-en-Bresse,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71406,ST DIDIER EN BRIONNAIS,71110,ST DIDIER EN BRIONNAIS,,46.3389618566,4.12398116722,406,,Saint-Didier-en-Brionnais,Saint-Didier-en-Brionnais,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71407,ST DIDIER SUR ARROUX,71190,ST DIDIER SUR ARROUX,,46.832913623,4.10029062388,407,,Saint-Didier-sur-Arroux,Saint-Didier-sur-Arroux,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71408,ST EDMOND,71740,ST EDMOND,,46.2038071906,4.2106213442,408,,Saint-Edmond,Saint-Edmond,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71409,ST EMILAND,71490,ST EMILAND,,46.8946339034,4.47301777155,409,,Saint-Émiland,Saint-Émiland,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71410,ST ETIENNE EN BRESSE,71370,ST ETIENNE EN BRESSE,,46.7060490635,5.06223001474,410,,Saint-Étienne-en-Bresse,Saint-Étienne-en-Bresse,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71411,ST EUGENE,71320,ST EUGENE,,46.7303096317,4.22025491336,411,,Saint-Eugène,Saint-Eugène,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71412,ST EUSEBE,71210,ST EUSEBE,,46.7211446083,4.45443642854,412,,Saint-Eusèbe,Saint-Eusèbe,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71413,ST FIRMIN,71670,ST FIRMIN,,46.8475771598,4.46315164519,413,,Saint-Firmin,Saint-Firmin,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71414,ST FORGEOT,71400,ST FORGEOT,,47.0016193102,4.28632347567,414,,Saint-Forgeot,Saint-Forgeot,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71415,STE FOY,71110,STE FOY,,46.2773831538,4.13207987925,415,,Sainte-Foy,Sainte-Foy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71416,ST GENGOUX DE SCISSE,71260,ST GENGOUX DE SCISSE,,46.4618494936,4.77147556185,416,,Saint-Gengoux-de-Scissé,Saint-Gengoux-de-Scissé,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71417,ST GENGOUX LE NATIONAL,71460,ST GENGOUX LE NATIONAL,,46.6134097037,4.66348657274,417,,Saint-Gengoux-le-National,Saint-Gengoux-le-National,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71419,ST GERMAIN DU BOIS,71330,ST GERMAIN DU BOIS,,46.7400325151,5.244824313,419,,Saint-Germain-du-Bois,Saint-Germain-du-Bois,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71420,ST GERMAIN DU PLAIN,71370,ST GERMAIN DU PLAIN,,46.6944314945,4.98395008765,420,,Saint-Germain-du-Plain,Saint-Germain-du-Plain,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71421,ST GERMAIN EN BRIONNAIS,71800,ST GERMAIN EN BRIONNAIS,,46.3465365136,4.25107234474,421,,Saint-Germain-en-Brionnais,Saint-Germain-en-Brionnais,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71422,ST GERMAIN LES BUXY,71390,ST GERMAIN LES BUXY,,46.6961802928,4.7732570096,422,,Saint-Germain-lès-Buxy,Saint-Germain-lès-Buxy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71423,ST GERVAIS EN VALLIERE,71350,ST GERVAIS EN VALLIERE,,46.945273256,4.96240902154,423,,Saint-Gervais-en-Vallière,Saint-Gervais-en-Vallière,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71424,ST GERVAIS SUR COUCHES,71490,ST GERVAIS SUR COUCHES,,46.9241932116,4.56778879306,424,,Saint-Gervais-sur-Couches,Saint-Gervais-sur-Couches,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71425,ST GILLES,71510,ST GILLES,,46.8776228817,4.66403200389,425,,Saint-Gilles,Saint-Gilles,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71426,STE HELENE,71390,STE HELENE,,46.7577025616,4.63460964959,426,,Sainte-Hélène,Sainte-Hélène,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71427,ST HURUGE,71460,ST HURUGE,,46.5787209539,4.57476760271,427,,Saint-Huruge,Saint-Huruge,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71428,ST IGNY DE ROCHE,71170,ST IGNY DE ROCHE,,46.187952521,4.30371238024,428,,Saint-Igny-de-Roche,Saint-Igny-de-Roche,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71430,ST JEAN DE VAUX,71640,ST JEAN DE VAUX,,46.8066073647,4.69575049861,430,,Saint-Jean-de-Vaux,Saint-Jean-de-Vaux,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71431,ST JEAN DE TREZY,71490,ST JEAN DE TREZY,,46.84298682,4.58633968997,431,,Saint-Jean-de-Trézy,Saint-Jean-de-Trézy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71433,ST JULIEN DE CIVRY,71800,ST JULIEN DE CIVRY,,46.3729257558,4.22118387615,433,,Saint-Julien-de-Civry,Saint-Julien-de-Civry,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71434,ST JULIEN DE JONZY,71110,ST JULIEN DE JONZY,,46.2465829931,4.14251158557,434,,Saint-Julien-de-Jonzy,Saint-Julien-de-Jonzy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71435,ST JULIEN SUR DHEUNE,71210,ST JULIEN SUR DHEUNE,,46.7729463186,4.54776412393,435,,Saint-Julien-sur-Dheune,Saint-Julien-sur-Dheune,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71436,ST LAURENT D ANDENAY,71210,ST LAURENT D ANDENAY,,46.7305082565,4.51202776715,436,,Saint-Laurent-d'Andenay,Saint-Laurent-d'Andenay,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71437,ST LAURENT EN BRIONNAIS,71800,ST LAURENT EN BRIONNAIS,,46.2699837548,4.25673453521,437,,Saint-Laurent-en-Brionnais,Saint-Laurent-en-Brionnais,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71438,ST LEGER DU BOIS,71360,ST LEGER DU BOIS,,47.0229494919,4.43237550476,438,,Saint-Léger-du-Bois,Saint-Léger-du-Bois,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71439,ST LEGER LES PARAY,71600,ST LEGER LES PARAY,,46.4848826059,4.1163312741,439,,Saint-Léger-lès-Paray,Saint-Léger-lès-Paray,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71440,ST LEGER SOUS BEUVRAY,71990,ST LEGER SOUS BEUVRAY,,46.9191539488,4.09209757526,440,,Saint-Léger-sous-Beuvray,Saint-Léger-sous-Beuvray,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71441,ST LEGER SOUS LA BUSSIERE,71520,ST LEGER SOUS LA BUSSIERE,,46.3114540312,4.56276492134,441,,Saint-Léger-sous-la-Bussière,Saint-Léger-sous-la-Bussière,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71442,ST LEGER SUR DHEUNE,71510,ST LEGER SUR DHEUNE,,46.8382241352,4.64131133343,442,,Saint-Léger-sur-Dheune,Saint-Léger-sur-Dheune,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71443,ST LOUP GEANGES,71350,ST LOUP GEANGES,,46.9387218362,4.90922243848,443,,Saint-Loup-Géanges,Saint-Loup-Géanges,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71443,ST LOUP GEANGES,71350,ST LOUP GEANGES,GEANGES,46.9387218362,4.90922243848,443,,Saint-Loup-Géanges,Saint-Loup-Géanges,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71444,ST LOUP DE VARENNES,71240,ST LOUP DE VARENNES,,46.7239907998,4.8521089943,444,,Saint-Loup-de-Varennes,Saint-Loup-de-Varennes,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71445,ST MARCEL,71380,ST MARCEL,,46.77487928,4.88727108461,445,,Saint-Marcel,Saint-Marcel,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71446,ST MARCELIN DE CRAY,71460,ST MARCELIN DE CRAY,,46.5652558244,4.52248882344,446,,Saint-Marcelin-de-Cray,Saint-Marcelin-de-Cray,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71447,ST MARD DE VAUX,71640,ST MARD DE VAUX,,46.8135328184,4.67179721319,447,,Saint-Mard-de-Vaux,Saint-Mard-de-Vaux,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71448,ST MARTIN BELLE ROCHE,71118,ST MARTIN BELLE ROCHE,,46.3803240277,4.85450707097,448,,Saint-Martin-Belle-Roche,Saint-Martin-Belle-Roche,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71449,ST MARTIN D AUXY,71390,ST MARTIN D AUXY,,46.7213262279,4.55075657116,449,,Saint-Martin-d'Auxy,Saint-Martin-d'Auxy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71450,ST MARTIN DE COMMUNE,71490,ST MARTIN DE COMMUNE,,46.9025390266,4.51751488877,450,,Saint-Martin-de-Commune,Saint-Martin-de-Commune,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71451,ST MARTIN DE LIXY,71740,ST MARTIN DE LIXY,,46.1966932958,4.23849403953,451,,Saint-Martin-de-Lixy,Saint-Martin-de-Lixy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71452,ST MARTIN DE SALENCEY,71220,ST MARTIN DE SALENCEY,,46.5186534954,4.49326886947,452,,Saint-Martin-de-Salencey,Saint-Martin-de-Salencey,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71453,ST MARTIN DU LAC,71110,ST MARTIN DU LAC,,46.2492100309,4.05528642547,453,,Saint-Martin-du-Lac,Saint-Martin-du-Lac,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71454,ST MARTIN DU MONT,71580,ST MARTIN DU MONT,,46.6200413286,5.29420271275,454,,Saint-Martin-du-Mont,Saint-Martin-du-Mont,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71455,ST MARTIN DU TARTRE,71460,ST MARTIN DU TARTRE,,46.6484823293,4.60684665036,455,,Saint-Martin-du-Tartre,Saint-Martin-du-Tartre,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71456,ST MARTIN EN BRESSE,71620,ST MARTIN EN BRESSE,,46.8045036563,5.06009460958,456,,Saint-Martin-en-Bresse,Saint-Martin-en-Bresse,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71457,ST MARTIN EN GATINOIS,71350,ST MARTIN EN GATINOIS,,46.9431147927,5.00729435499,457,,Saint-Martin-en-Gâtinois,Saint-Martin-en-Gâtinois,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71458,ST MARTIN LA PATROUILLE,71460,ST MARTIN LA PATROUILLE,,46.5830730651,4.54536544004,458,,Saint-Martin-la-Patrouille,Saint-Martin-la-Patrouille,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71459,ST MARTIN SOUS MONTAIGU,71640,ST MARTIN SOUS MONTAIGU,,46.8134978292,4.7100275215,459,,Saint-Martin-sous-Montaigu,Saint-Martin-sous-Montaigu,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71460,ST MAURICE DE SATONNAY,71260,ST MAURICE DE SATONNAY,,46.4057525452,4.78239224424,460,,Saint-Maurice-de-Satonnay,Saint-Maurice-de-Satonnay,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71461,ST MAURICE DES CHAMPS,71460,ST MAURICE DES CHAMPS,,46.6238661451,4.62609375909,461,,Saint-Maurice-des-Champs,Saint-Maurice-des-Champs,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71462,ST MAURICE EN RIVIERE,71620,ST MAURICE EN RIVIERE,,46.8493063168,5.02211527314,462,,Saint-Maurice-en-Rivière,Saint-Maurice-en-Rivière,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71463,ST MAURICE LES CHATEAUNEUF,71740,ST MAURICE LES CHATEAUNEUF,,46.2267056236,4.25094648512,463,,Saint-Maurice-lès-Châteauneuf,Saint-Maurice-lès-Châteauneuf,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71464,ST MAURICE LES COUCHES,71490,ST MAURICE LES COUCHES,,46.8775473373,4.60138308306,464,,Saint-Maurice-lès-Couches,Saint-Maurice-lès-Couches,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71465,ST MICAUD,71460,ST MICAUD,,46.6923867909,4.52704929723,465,,Saint-Micaud,Saint-Micaud,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71466,ST NIZIER SUR ARROUX,71190,ST NIZIER SUR ARROUX,,46.8143219502,4.15821443089,466,,Saint-Nizier-sur-Arroux,Saint-Nizier-sur-Arroux,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71468,ST PIERRE DE VARENNES,71670,ST PIERRE DE VARENNES,,46.84711079,4.5090273934,468,,Saint-Pierre-de-Varennes,Saint-Pierre-de-Varennes,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71469,ST PIERRE LE VIEUX,71520,ST PIERRE LE VIEUX,,46.2871912572,4.52517734192,469,,Saint-Pierre-le-Vieux,Saint-Pierre-le-Vieux,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71470,ST POINT,71520,ST POINT,,46.3371050724,4.62126526251,470,,Saint-Point,Saint-Point,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71471,ST PRIVE,71390,ST PRIVE,,46.6996790734,4.57526174797,471,,Saint-Privé,Saint-Privé,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71472,ST PRIX,71990,ST PRIX,,46.972172044,4.06111114828,472,,Saint-Prix,Saint-Prix,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71473,ST RACHO,71800,ST RACHO,,46.2627274208,4.37370876485,473,,Saint-Racho,Saint-Racho,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71474,STE RADEGONDE,71320,STE RADEGONDE,,46.6939905917,4.07946252508,474,,Sainte-Radegonde,Sainte-Radegonde,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71475,ST REMY,71100,ST REMY,,46.7602643306,4.82122632524,475,,Saint-Rémy,Saint-Rémy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71477,ST ROMAIN SOUS GOURDON,71230,ST ROMAIN SOUS GOURDON,,46.6170899923,4.41147294378,477,,Saint-Romain-sous-Gourdon,Saint-Romain-sous-Gourdon,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71478,ST ROMAIN SOUS VERSIGNY,71420,ST ROMAIN SOUS VERSIGNY,,46.6523046046,4.18495340535,478,,Saint-Romain-sous-Versigny,Saint-Romain-sous-Versigny,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71479,ST SERNIN DU BOIS,71200,ST SERNIN DU BOIS,,46.8386475435,4.42378430368,479,,Saint-Sernin-du-Bois,Saint-Sernin-du-Bois,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71480,ST SERNIN DU PLAIN,71510,ST SERNIN DU PLAIN,,46.892384215,4.61739889874,480,,Saint-Sernin-du-Plain,Saint-Sernin-du-Plain,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71481,ST SYMPHORIEN D ANCELLES,71570,ST SYMPHORIEN D ANCELLES,,46.1956267392,4.77196064471,481,,Saint-Symphorien-d'Ancelles,Saint-Symphorien-d'Ancelles,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71481,ST SYMPHORIEN D ANCELLES,71570,ST SYMPHORIEN D ANCELLES,ST ROMAIN DES ILES,46.1956267392,4.77196064471,481,,Saint-Symphorien-d'Ancelles,Saint-Symphorien-d'Ancelles,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71482,ST SYMPHORIEN DE MARMAGNE,71710,ST SYMPHORIEN DE MARMAGNE,,46.8287800978,4.31825474649,482,,Saint-Symphorien-de-Marmagne,Saint-Symphorien-de-Marmagne,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71483,ST SYMPHORIEN DES BOIS,71800,ST SYMPHORIEN DES BOIS,,46.3269509169,4.2853376663,483,,Saint-Symphorien-des-Bois,Saint-Symphorien-des-Bois,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71484,ST USUGE,71500,ST USUGE,,46.690095883,5.24143476661,484,,Saint-Usuge,Saint-Usuge,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71485,ST VALLERIN,71390,ST VALLERIN,,46.6847032271,4.66933789686,485,,Saint-Vallerin,Saint-Vallerin,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71486,ST VALLIER,71230,ST VALLIER,,46.6430898449,4.35996411381,486,,Saint-Vallier,Saint-Vallier,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71486,ST VALLIER,71230,ST VALLIER,LES GAUTHERETS,46.6430898449,4.35996411381,486,,Saint-Vallier,Saint-Vallier,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71487,ST VERAND,71570,ST VERAND,,46.2531843675,4.72769715807,487,,Saint-Vérand,Saint-Vérand,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71488,ST VINCENT DES PRES,71250,ST VINCENT DES PRES,,46.4748542216,4.55521519773,488,,Saint-Vincent-des-Prés,Saint-Vincent-des-Prés,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71489,ST VINCENT EN BRESSE,71440,ST VINCENT EN BRESSE,,46.6714691563,5.06091030866,489,,Saint-Vincent-en-Bresse,Saint-Vincent-en-Bresse,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71490,ST VINCENT BRAGNY,71430,ST VINCENT BRAGNY,,46.5253910915,4.12604476033,490,,Saint-Vincent-Bragny,Saint-Vincent-Bragny,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71490,ST VINCENT BRAGNY,71430,ST VINCENT BRAGNY,BRAGNY EN CHAROLLAIS,46.5253910915,4.12604476033,490,,Saint-Vincent-Bragny,Saint-Vincent-Bragny,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71491,ST YAN,71600,ST YAN,,46.4071746209,4.05766988687,491,,Saint-Yan,Saint-Yan,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71492,ST YTHAIRE,71460,ST YTHAIRE,,46.5681757144,4.60307233126,492,,Saint-Ythaire,Saint-Ythaire,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71493,SAISY,71360,SAISY,,46.9611534554,4.55629886469,493,,Saisy,Saisy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71494,LA SALLE,71260,LA SALLE,,46.4089574233,4.86086523972,494,La,Salle,La Salle,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71495,SALORNAY SUR GUYE,71250,SALORNAY SUR GUYE,,46.5174514836,4.6006444858,495,,Salornay-sur-Guye,Salornay-sur-Guye,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71496,SAMPIGNY LES MARANGES,71150,SAMPIGNY LES MARANGES,,46.9020668199,4.65271904267,496,,Sampigny-lès-Maranges,Sampigny-lès-Maranges,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71497,SANCE,71000,SANCE,,46.339587047,4.83540535119,497,,Sancé,Sancé,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71498,SANTILLY,71460,SANTILLY,,46.6204214776,4.70493603274,498,,Santilly,Santilly,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71499,SANVIGNES LES MINES,71410,SANVIGNES LES MINES,,46.6680278999,4.27985342384,499,,Sanvignes-les-Mines,Sanvignes-les-Mines,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71500,SARRY,71110,SARRY,,46.3094373531,4.10708282247,500,,Sarry,Sarry,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71501,SASSANGY,71390,SASSANGY,,46.7235527398,4.64308684871,501,,Sassangy,Sassangy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71502,SASSENAY,71530,SASSENAY,,46.8332708433,4.91806482813,502,,Sassenay,Sassenay,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71503,SAULES,71390,SAULES,,46.6559200534,4.67477291847,503,,Saules,Saules,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71504,SAUNIERES,71350,SAUNIERES,,46.9119642453,5.07167860056,504,,Saunières,Saunières,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71505,SAVIANGES,71460,SAVIANGES,,46.6899032737,4.60174880086,505,,Savianges,Savianges,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71506,SAVIGNY EN REVERMONT,71580,SAVIGNY EN REVERMONT,,46.6336562271,5.39078988387,506,,Savigny-en-Revermont,Savigny-en-Revermont,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71507,SAVIGNY SUR GROSNE,71460,SAVIGNY SUR GROSNE,,46.5810724468,4.66399210359,507,,Savigny-sur-Grosne,Savigny-sur-Grosne,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71508,SAVIGNY SUR SEILLE,71440,SAVIGNY SUR SEILLE,,46.6331048496,5.10253971611,508,,Savigny-sur-Seille,Savigny-sur-Seille,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71509,LA CELLE EN MORVAN,71400,LA CELLE EN MORVAN,,47.0074409579,4.17854772292,509,La,Celle-en-Morvan,La Celle-en-Morvan,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71510,SEMUR EN BRIONNAIS,71110,SEMUR EN BRIONNAIS,,46.2756396728,4.09014154762,510,,Semur-en-Brionnais,Semur-en-Brionnais,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71512,SENNECEY LE GRAND,71240,SENNECEY LE GRAND,,46.6369174742,4.8906553054,512,,Sennecey-le-Grand,Sennecey-le-Grand,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71513,SENOZAN,71260,SENOZAN,,46.3967416331,4.86457842244,513,,Senozan,Senozan,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71514,SENS SUR SEILLE,71330,SENS SUR SEILLE,,46.7462083335,5.30794162297,514,,Sens-sur-Seille,Sens-sur-Seille,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71515,SERCY,71460,SERCY,,46.6008137667,4.69328088717,515,,Sercy,Sercy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71516,SERLEY,71310,SERLEY,,46.7884184366,5.24237687165,516,,Serley,Serley,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71517,SERMESSE,71350,SERMESSE,,46.8944431554,5.09330087989,517,,Sermesse,Sermesse,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71518,SERRIERES,71960,SERRIERES,,46.3106483119,4.66614748504,518,,Serrières,Serrières,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71519,SERRIGNY EN BRESSE,71310,SERRIGNY EN BRESSE,,46.8249036808,5.12195396385,519,,Serrigny-en-Bresse,Serrigny-en-Bresse,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71520,SEVREY,71100,SEVREY,,46.7358656662,4.82878798473,520,,Sevrey,Sevrey,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71521,SIGY LE CHATEL,71250,SIGY LE CHATEL,,46.5550063126,4.57085100441,521,,Sigy-le-Châtel,Sigy-le-Châtel,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71522,SIMANDRE,71290,SIMANDRE,,46.6122739185,4.98702141498,522,,Simandre,Simandre,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71523,SIMARD,71330,SIMARD,,46.7167185165,5.18255120169,523,,Simard,Simard,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71524,SIVIGNON,71220,SIVIGNON,,46.4267420251,4.49917646039,524,,Sivignon,Sivignon,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71525,SOLOGNY,71960,SOLOGNY,,46.3708080231,4.67295100196,525,,Sologny,Sologny,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71526,SOLUTRE POUILLY,71960,SOLUTRE POUILLY,,46.2935765949,4.72347694555,526,,Solutré-Pouilly,Solutré-Pouilly,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71527,SOMMANT,71540,SOMMANT,,47.0533646393,4.20964551396,527,,Sommant,Sommant,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71528,SORNAY,71500,SORNAY,,46.6213260954,5.16979510673,528,,Sornay,Sornay,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71529,SUIN,71220,SUIN,,46.4406556558,4.45677092453,529,,Suin,Suin,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71530,SULLY,71360,SULLY,,46.9880333381,4.45705111324,530,,Sully,Sully,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71531,LA TAGNIERE,71190,LA TAGNIERE,,46.7963713022,4.21515248799,531,La,Tagnière,La Tagnière,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71532,TAIZE,71250,TAIZE,,46.5105515739,4.67305548074,532,,Taizé,Taizé,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71532,TAIZE,71250,TAIZE,TAIZE COMMUNAUTE,46.5105515739,4.67305548074,532,,Taizé,Taizé,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71533,TANCON,71740,TANCON,,46.2007159715,4.27045776133,533,,Tancon,Tancon,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71534,LE TARTRE,71330,LE TARTRE,,46.7429466505,5.35271245031,534,Le,Tartre,Le Tartre,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71535,TAVERNAY,71400,TAVERNAY,,47.0078509175,4.23537137971,535,,Tavernay,Tavernay,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71537,THIL SUR ARROUX,71190,THIL SUR ARROUX,,46.8041058716,4.09212501715,537,,Thil-sur-Arroux,Thil-sur-Arroux,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71538,THUREY,71440,THUREY,,46.7431263464,5.12562918719,538,,Thurey,Thurey,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71539,TINTRY,71490,TINTRY,,46.9318626391,4.48929085952,539,,Tintry,Tintry,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71540,TORCY,71210,TORCY,,46.7663790814,4.44629007139,540,,Torcy,Torcy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71541,TORPES,71270,TORPES,,46.8390198293,5.33546436307,541,,Torpes,Torpes,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71542,TOULON SUR ARROUX,71320,TOULON SUR ARROUX,,46.6826511645,4.14773994061,542,,Toulon-sur-Arroux,Toulon-sur-Arroux,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71543,TOURNUS,71700,TOURNUS,,46.5651726015,4.90012429206,543,,Tournus,Tournus,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71544,TOUTENANT,71350,TOUTENANT,,46.8707263805,5.11407040024,544,,Toutenant,Toutenant,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71545,TRAMAYES,71520,TRAMAYES,,46.3056528048,4.60368110161,545,,Tramayes,Tramayes,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71546,TRAMBLY,71520,TRAMBLY,,46.3310184112,4.53251400851,546,,Trambly,Trambly,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71547,TRIVY,71520,TRIVY,,46.3907231408,4.48743569605,547,,Trivy,Trivy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71548,TRONCHY,71440,TRONCHY,,46.7273787924,5.07374136167,548,,Tronchy,Tronchy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71549,LA TRUCHERE,71290,LA TRUCHERE,,46.5167546433,4.95800393539,549,La,Truchère,La Truchère,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71550,UCHIZY,71700,UCHIZY,,46.4985733672,4.88689760055,550,,Uchizy,Uchizy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71551,UCHON,71190,UCHON,,46.809110092,4.26277763328,551,,Uchon,Uchon,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71552,UXEAU,71130,UXEAU,,46.6565301063,4.04556319316,552,,Uxeau,Uxeau,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71553,VAREILLES,71800,VAREILLES,,46.3003476512,4.2482812157,553,,Vareilles,Vareilles,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71554,VARENNE L ARCONCE,71110,VARENNE L ARCONCE,,46.3412494667,4.15697497877,554,,Varenne-l'Arconce,Varenne-l'Arconce,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71555,VARENNES LE GRAND,71240,VARENNES LE GRAND,,46.7100754692,4.87819266492,555,,Varennes-le-Grand,Varennes-le-Grand,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71556,VARENNES LES MACON,71000,VARENNES LES MACON,,46.2683636158,4.8066970954,556,,Varennes-lès-Mâcon,Varennes-lès-Mâcon,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71557,VARENNE ST GERMAIN,71600,VARENNE ST GERMAIN,,46.4373098017,4.02659181571,557,,Varenne-Saint-Germain,Varenne-Saint-Germain,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71557,VARENNE ST GERMAIN,71600,VARENNE ST GERMAIN,ST GERMAIN DES RIVES,46.4373098017,4.02659181571,557,,Varenne-Saint-Germain,Varenne-Saint-Germain,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71558,VARENNES ST SAUVEUR,71480,VARENNES ST SAUVEUR,,46.5013604889,5.23845010898,558,,Varennes-Saint-Sauveur,Varennes-Saint-Sauveur,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71559,VARENNES SOUS DUN,71800,VARENNES SOUS DUN,,46.2783435067,4.33879272705,559,,Varennes-sous-Dun,Varennes-sous-Dun,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71561,VAUBAN,71800,VAUBAN,,46.2639696284,4.22242971083,561,,Vauban,Vauban,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71562,VAUDEBARRIER,71120,VAUDEBARRIER,,46.4165847786,4.31200605837,562,,Vaudebarrier,Vaudebarrier,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71563,VAUX EN PRE,71460,VAUX EN PRE,,46.6226496963,4.60188954425,563,,Vaux-en-Pré,Vaux-en-Pré,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71564,VENDENESSE LES CHAROLLES,71120,VENDENESSE LES CHAROLLES,,46.4407294835,4.35671809302,564,,Vendenesse-lès-Charolles,Vendenesse-lès-Charolles,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71565,VENDENESSE SUR ARROUX,71130,VENDENESSE SUR ARROUX,,46.6312878987,4.06699365685,565,,Vendenesse-sur-Arroux,Vendenesse-sur-Arroux,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71566,VERDUN SUR LE DOUBS,71350,VERDUN SUR LE DOUBS,,46.8905762188,5.01251623413,566,,Verdun-sur-le-Doubs,Verdun-sur-le-Doubs,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71567,VERGISSON,71960,VERGISSON,,46.3095589166,4.71007119393,567,,Vergisson,Vergisson,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71568,VERISSEY,71440,VERISSEY,,46.7117151775,5.12822838879,568,,Vérissey,Vérissey,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71570,VERJUX,71590,VERJUX,,46.877805287,4.97685538795,570,,Verjux,Verjux,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71571,VEROSVRES,71220,VEROSVRES,,46.3956596645,4.44032061495,571,,Verosvres,Verosvres,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71572,VERS,71240,VERS,,46.5829437707,4.85548745852,572,,Vers,Vers,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71573,VERSAUGUES,71110,VERSAUGUES,,46.3674828886,4.07047999469,573,,Versaugues,Versaugues,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71574,VERZE,71960,VERZE,,46.3779055015,4.73671320238,574,,Verzé,Verzé,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71576,LE VILLARS,71700,LE VILLARS,,46.5289774327,4.92186717271,576,Le,Villars,Le Villars,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71577,VILLEGAUDIN,71620,VILLEGAUDIN,,46.8001182461,5.10315061501,577,,Villegaudin,Villegaudin,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71578,CLUX VILLENEUVE,71270,CLUX VILLENEUVE,,46.9572279729,5.16839319569,578,,Clux-Villeneuve,Clux-Villeneuve,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71578,CLUX VILLENEUVE,71270,CLUX VILLENEUVE,CLUX,46.9572279729,5.16839319569,578,,Clux-Villeneuve,Clux-Villeneuve,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71579,VILLENEUVE EN MONTAGNE,71390,VILLENEUVE EN MONTAGNE,,46.7620859327,4.58923358635,579,,Villeneuve-en-Montagne,Villeneuve-en-Montagne,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71580,VINCELLES,71500,VINCELLES,,46.6645202679,5.22488404549,580,,Vincelles,Vincelles,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71581,VINDECY,71110,VINDECY,,46.3534713337,4.00762169293,581,,Vindecy,Vindecy,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71582,LA VINEUSE,71250,LA VINEUSE,,46.4606978689,4.60125621922,582,La,Vineuse sur Fregande,La Vineuse sur Fregande,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71583,VINZELLES,71680,VINZELLES,,46.2679286558,4.77751474902,583,,Vinzelles,Vinzelles,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71584,VIRE,71260,VIRE,,46.4445923499,4.84966677485,584,,Viré,Viré,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71584,VIRE,71260,VIRE,VERIZET,46.4445923499,4.84966677485,584,,Viré,Viré,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71585,VIREY LE GRAND,71530,VIREY LE GRAND,,46.8524193794,4.86945646379,585,,Virey-le-Grand,Virey-le-Grand,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71586,VIRY,71120,VIRY,,46.4724060725,4.33771715478,586,,Viry,Viry,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71587,VITRY LES CLUNY,71250,VITRY LES CLUNY,,46.4923491806,4.58507876006,587,,Vitry-lès-Cluny,Vitry-lès-Cluny,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71588,VITRY EN CHAROLLAIS,71600,VITRY EN CHAROLLAIS,,46.4610227833,4.0599685169,588,,Vitry-en-Charollais,Vitry-en-Charollais,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71589,VITRY SUR LOIRE,71140,VITRY SUR LOIRE,,46.6796114688,3.71763389584,589,,Vitry-sur-Loire,Vitry-sur-Loire,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71590,VOLESVRES,71600,VOLESVRES,,46.4730942856,4.16777937135,590,,Volesvres,Volesvres,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
71591,FLEURVILLE,71260,FLEURVILLE,,46.4420568988,4.88017715275,591,,Fleurville,Fleurville,71,Saône-et-Loire,27,Bourgogne-Franche-Comté
72001,AIGNE,72650,AIGNE,,48.0674422633,0.105520640211,1,,Aigné,Aigné,72,Sarthe,52,Pays de la Loire
72002,AILLIERES BEAUVOIR,72600,AILLIERES BEAUVOIR,,48.3996493639,0.321252383862,2,,Aillières-Beauvoir,Aillières-Beauvoir,72,Sarthe,52,Pays de la Loire
72003,ALLONNES,72700,ALLONNES,,47.9589683966,0.143770981533,3,,Allonnes,Allonnes,72,Sarthe,52,Pays de la Loire
72004,AMNE,72540,AMNE,,48.0403287811,-0.0436682297545,4,,Amné,Amné,72,Sarthe,52,Pays de la Loire
72005,ANCINNES,72610,ANCINNES,,48.3603390007,0.173826931122,5,,Ancinnes,Ancinnes,72,Sarthe,52,Pays de la Loire
72006,ARCONNAY,72610,ARCONNAY,,48.3954879492,0.0811301798508,6,,Arçonnay,Arçonnay,72,Sarthe,52,Pays de la Loire
72007,ARDENAY SUR MERIZE,72370,ARDENAY SUR MERIZE,,47.9936344647,0.428021491681,7,,Ardenay-sur-Mérize,Ardenay-sur-Mérize,72,Sarthe,52,Pays de la Loire
72008,ARNAGE,72230,ARNAGE,,47.9292220095,0.191344248929,8,,Arnage,Arnage,72,Sarthe,52,Pays de la Loire
72009,ARTHEZE,72270,ARTHEZE,,47.7894345539,-0.110707859557,9,,Arthezé,Arthezé,72,Sarthe,52,Pays de la Loire
72010,ASNIERES SUR VEGRE,72430,ASNIERES SUR VEGRE,,47.886353314,-0.226358103893,10,,Asnières-sur-Vègre,Asnières-sur-Vègre,72,Sarthe,52,Pays de la Loire
72011,ASSE LE BOISNE,72130,ASSE LE BOISNE,,48.3216528559,-0.00324343366865,11,,Assé-le-Boisne,Assé-le-Boisne,72,Sarthe,52,Pays de la Loire
72012,ASSE LE RIBOUL,72170,ASSE LE RIBOUL,,48.2030195812,0.078627862443,12,,Assé-le-Riboul,Assé-le-Riboul,72,Sarthe,52,Pays de la Loire
72013,AUBIGNE RACAN,72800,AUBIGNE RACAN,,47.6839620406,0.250420617503,13,,Aubigné-Racan,Aubigné-Racan,72,Sarthe,52,Pays de la Loire
72015,LES AULNEAUX,72600,LES AULNEAUX,,48.4382484523,0.343168192936,15,Les,Aulneaux,Les Aulneaux,72,Sarthe,52,Pays de la Loire
72016,AUVERS LE HAMON,72300,AUVERS LE HAMON,,47.903917058,-0.345078374369,16,,Auvers-le-Hamon,Auvers-le-Hamon,72,Sarthe,52,Pays de la Loire
72017,AUVERS SOUS MONTFAUCON,72540,AUVERS SOUS MONTFAUCON,,48.0011977503,-0.0746551164135,17,,Auvers-sous-Montfaucon,Auvers-sous-Montfaucon,72,Sarthe,52,Pays de la Loire
72018,AVESNES EN SAOSNOIS,72260,AVESNES EN SAOSNOIS,,48.2660508765,0.358662177926,18,,Avesnes-en-Saosnois,Avesnes-en-Saosnois,72,Sarthe,52,Pays de la Loire
72019,AVESSE,72350,AVESSE,,47.9573422992,-0.267295988986,19,,Avessé,Avessé,72,Sarthe,52,Pays de la Loire
72020,AVEZE,72400,AVEZE,,48.2343214233,0.672495766191,20,,Avezé,Avezé,72,Sarthe,52,Pays de la Loire
72021,AVOISE,72430,AVOISE,,47.8599093742,-0.193085392687,21,,Avoise,Avoise,72,Sarthe,52,Pays de la Loire
72022,LE BAILLEUL,72200,LE BAILLEUL,,47.7724310115,-0.179561724396,22,Le,Bailleul,Le Bailleul,72,Sarthe,52,Pays de la Loire
72023,BALLON ST MARS,72290,BALLON ST MARS,,48.1716510339,0.226519968503,23,,Ballon-Saint Mars,Ballon-Saint Mars,72,Sarthe,52,Pays de la Loire
72023,BALLON ST MARS,72290,BALLON ST MARS,ST MARS SOUS BALLON,48.1716510339,0.226519968503,23,,Ballon-Saint Mars,Ballon-Saint Mars,72,Sarthe,52,Pays de la Loire
72024,LA BAZOGE,72650,LA BAZOGE,,48.1027362098,0.14422733228,24,La,Bazoge,La Bazoge,72,Sarthe,52,Pays de la Loire
72025,BAZOUGES SUR LE LOIR,72200,BAZOUGES SUR LE LOIR,,47.6856990287,-0.172011886332,25,,Bazouges Cré sur Loir,Bazouges Cré sur Loir,72,Sarthe,52,Pays de la Loire
72026,BEAUFAY,72110,BEAUFAY,,48.1423466194,0.342290713803,26,,Beaufay,Beaufay,72,Sarthe,52,Pays de la Loire
72027,BEAUMONT SUR DEME,72340,BEAUMONT SUR DEME,,47.6873211629,0.579539611616,27,,Beaumont-sur-Dême,Beaumont-sur-Dême,72,Sarthe,52,Pays de la Loire
72028,BEAUMONT PIED DE BOEUF,72500,BEAUMONT PIED DE BOEUF,,47.7574486606,0.38353114802,28,,Beaumont-Pied-de-Bœuf,Beaumont-Pied-de-Bœuf,72,Sarthe,52,Pays de la Loire
72029,BEAUMONT SUR SARTHE,72170,BEAUMONT SUR SARTHE,,48.2246887958,0.112408293978,29,,Beaumont-sur-Sarthe,Beaumont-sur-Sarthe,72,Sarthe,52,Pays de la Loire
72031,BEILLE,72160,BEILLE,,48.0830099686,0.509137455093,31,,Beillé,Beillé,72,Sarthe,52,Pays de la Loire
72032,BERFAY,72320,BERFAY,,48.0023104165,0.750071551186,32,,Berfay,Berfay,72,Sarthe,52,Pays de la Loire
72033,BERNAY EN CHAMPAGNE,72240,BERNAY EN CHAMPAGNE,,48.0717941945,-0.0566010405247,33,,Bernay-en-Champagne,Bernay-en-Champagne,72,Sarthe,52,Pays de la Loire
72034,BERUS,72610,BERUS,,48.374837011,0.0675732851409,34,,Bérus,Bérus,72,Sarthe,52,Pays de la Loire
72035,BESSE SUR BRAYE,72310,BESSE SUR BRAYE,,47.824274366,0.722121663384,35,,Bessé-sur-Braye,Bessé-sur-Braye,72,Sarthe,52,Pays de la Loire
72036,BETHON,72610,BETHON,,48.3652173718,0.0923040851255,36,,Béthon,Béthon,72,Sarthe,52,Pays de la Loire
72037,BLEVES,72600,BLEVES,,48.4494580566,0.335584256031,37,,Blèves,Blèves,72,Sarthe,52,Pays de la Loire
72038,BOESSE LE SEC,72400,BOESSE LE SEC,,48.1352696727,0.558033201406,38,,Boëssé-le-Sec,Boëssé-le-Sec,72,Sarthe,52,Pays de la Loire
72039,BONNETABLE,72110,BONNETABLE,,48.18474164,0.452449305895,39,,Bonnétable,Bonnétable,72,Sarthe,52,Pays de la Loire
72040,LA BOSSE,72400,LA BOSSE,,48.1635194155,0.546593401051,40,La,Bosse,La Bosse,72,Sarthe,52,Pays de la Loire
72041,BOUER,72390,BOUER,,48.0763794687,0.633804754102,41,,Bouër,Bouër,72,Sarthe,52,Pays de la Loire
72042,BOULOIRE,72440,BOULOIRE,,47.9677979192,0.550965416522,42,,Bouloire,Bouloire,72,Sarthe,52,Pays de la Loire
72043,BOURG LE ROI,72610,BOURG LE ROI,,48.3460739531,0.132093521683,43,,Bourg-le-Roi,Bourg-le-Roi,72,Sarthe,52,Pays de la Loire
72044,BOUSSE,72270,BOUSSE,,47.7609072838,-0.0629491331709,44,,Bousse,Bousse,72,Sarthe,52,Pays de la Loire
72045,BRAINS SUR GEE,72550,BRAINS SUR GEE,,48.0097032221,-0.0269752604251,45,,Brains-sur-Gée,Brains-sur-Gée,72,Sarthe,52,Pays de la Loire
72046,LE BREIL SUR MERIZE,72370,LE BREIL SUR MERIZE,,47.9957779709,0.499488585442,46,Le,Breil-sur-Mérize,Le Breil-sur-Mérize,72,Sarthe,52,Pays de la Loire
72047,BRETTE LES PINS,72250,BRETTE LES PINS,,47.9107877747,0.326771387948,47,,Brette-les-Pins,Brette-les-Pins,72,Sarthe,52,Pays de la Loire
72048,BRIOSNE LES SABLES,72110,BRIOSNE LES SABLES,,48.1746234769,0.392861782624,48,,Briosne-lès-Sables,Briosne-lès-Sables,72,Sarthe,52,Pays de la Loire
72049,LA BRUERE SUR LOIR,72500,LA BRUERE SUR LOIR,,47.6511451225,0.356792900521,49,La,Bruère-sur-Loir,La Bruère-sur-Loir,72,Sarthe,52,Pays de la Loire
72050,BRULON,72350,BRULON,,47.9808104506,-0.230196324503,50,,Brûlon,Brûlon,72,Sarthe,52,Pays de la Loire
72051,CERANS FOULLETOURTE,72330,CERANS FOULLETOURTE,,47.8339032596,0.0696644872215,51,,Cérans-Foulletourte,Cérans-Foulletourte,72,Sarthe,52,Pays de la Loire
72052,CHAHAIGNES,72340,CHAHAIGNES,,47.752016178,0.513758960916,52,,Chahaignes,Chahaignes,72,Sarthe,52,Pays de la Loire
72053,CHALLES,72250,CHALLES,,47.9342773547,0.428982036509,53,,Challes,Challes,72,Sarthe,52,Pays de la Loire
72054,CHAMPAGNE,72470,CHAMPAGNE,,48.0162619913,0.333986746648,54,,Champagné,Champagné,72,Sarthe,52,Pays de la Loire
72056,CHAMPFLEUR,72610,CHAMPFLEUR,,48.3844669298,0.125303372626,56,,Champfleur,Champfleur,72,Sarthe,52,Pays de la Loire
72057,CHAMPROND,72320,CHAMPROND,,48.0829601424,0.747203594283,57,,Champrond,Champrond,72,Sarthe,52,Pays de la Loire
72058,CHANGE,72560,CHANGE,,47.9784374731,0.297080685865,58,,Changé,Changé,72,Sarthe,52,Pays de la Loire
72059,CHANTENAY VILLEDIEU,72430,CHANTENAY VILLEDIEU,,47.9198250308,-0.154140183084,59,,Chantenay-Villedieu,Chantenay-Villedieu,72,Sarthe,52,Pays de la Loire
72060,LA CHAPELLE AUX CHOUX,72800,LA CHAPELLE AUX CHOUX,,47.6277588052,0.229975214122,60,La,Chapelle-aux-Choux,La Chapelle-aux-Choux,72,Sarthe,52,Pays de la Loire
72061,LA CHAPELLE D ALIGNE,72300,LA CHAPELLE D ALIGNE,,47.7357624962,-0.239313102989,61,La,Chapelle-d'Aligné,La Chapelle-d'Aligné,72,Sarthe,52,Pays de la Loire
72062,LA CHAPELLE DU BOIS,72400,LA CHAPELLE DU BOIS,,48.2270021313,0.583491271516,62,La,Chapelle-du-Bois,La Chapelle-du-Bois,72,Sarthe,52,Pays de la Loire
72063,LA CHAPELLE GAUGAIN,72310,LA CHAPELLE GAUGAIN,,47.8096345414,0.67424154434,63,La,Chapelle-Gaugain,La Chapelle-Gaugain,72,Sarthe,52,Pays de la Loire
72064,LA CHAPELLE HUON,72310,LA CHAPELLE HUON,,47.8558757031,0.718983213265,64,La,Chapelle-Huon,La Chapelle-Huon,72,Sarthe,52,Pays de la Loire
72065,LA CHAPELLE ST AUBIN,72650,LA CHAPELLE ST AUBIN,,48.0357837611,0.156697987071,65,La,Chapelle-Saint-Aubin,La Chapelle-Saint-Aubin,72,Sarthe,52,Pays de la Loire
72066,LA CHAPELLE ST FRAY,72240,LA CHAPELLE ST FRAY,,48.1087340469,0.0912246143378,66,La,Chapelle-Saint-Fray,La Chapelle-Saint-Fray,72,Sarthe,52,Pays de la Loire
72067,LA CHAPELLE ST REMY,72160,LA CHAPELLE ST REMY,,48.1038981246,0.465068388198,67,La,Chapelle-Saint-Rémy,La Chapelle-Saint-Rémy,72,Sarthe,52,Pays de la Loire
72068,LA CHARTRE SUR LE LOIR,72340,LA CHARTRE SUR LE LOIR,,47.7303686417,0.582042206339,68,La,Chartre-sur-le-Loir,La Chartre-sur-le-Loir,72,Sarthe,52,Pays de la Loire
72070,CHASSILLE,72540,CHASSILLE,,48.0213480631,-0.127209826209,70,,Chassillé,Chassillé,72,Sarthe,52,Pays de la Loire
72071,CHATEAU DU LOIR,72500,CHATEAU DU LOIR,,47.702207042,0.430642762781,71,,Montval-sur-Loir,Montval-sur-Loir,72,Sarthe,52,Pays de la Loire
72072,CHATEAU L HERMITAGE,72510,CHATEAU L HERMITAGE,,47.8022785256,0.199179125064,72,,Château-l'Hermitage,Château-l'Hermitage,72,Sarthe,52,Pays de la Loire
72073,CHAUFOUR NOTRE DAME,72550,CHAUFOUR NOTRE DAME,,48.0183975387,0.0629705204295,73,,Chaufour-Notre-Dame,Chaufour-Notre-Dame,72,Sarthe,52,Pays de la Loire
72074,CHEMIRE EN CHARNIE,72540,CHEMIRE EN CHARNIE,,48.0532260742,-0.202932424744,74,,Chemiré-en-Charnie,Chemiré-en-Charnie,72,Sarthe,52,Pays de la Loire
72075,CHEMIRE LE GAUDIN,72210,CHEMIRE LE GAUDIN,,47.9287039115,-0.0124343418856,75,,Chemiré-le-Gaudin,Chemiré-le-Gaudin,72,Sarthe,52,Pays de la Loire
72076,CHENAY,72610,CHENAY,,48.4480039351,0.160206730944,76,,Chenay,Chenay,72,Sarthe,52,Pays de la Loire
72077,CHENU,72500,CHENU,,47.6037065733,0.355437020509,77,,Chenu,Chenu,72,Sarthe,52,Pays de la Loire
72078,CHERANCE,72170,CHERANCE,,48.2814181284,0.175668329779,78,,Chérancé,Chérancé,72,Sarthe,52,Pays de la Loire
72079,CHERISAY,72610,CHERISAY,,48.3503181778,0.114525077823,79,,Chérisay,Chérisay,72,Sarthe,52,Pays de la Loire
72080,CHERRE,72400,CHERRE,,48.1549219183,0.657321436354,80,,Cherré,Cherré,72,Sarthe,52,Pays de la Loire
72081,CHERREAU,72400,CHERREAU,,48.1969993033,0.687448512053,81,,Cherreau,Cherreau,72,Sarthe,52,Pays de la Loire
72082,LE CHEVAIN,72610,LE CHEVAIN,,48.4251542768,0.140511148481,82,Le,Chevain,Le Chevain,72,Sarthe,52,Pays de la Loire
72083,CHEVILLE,72350,CHEVILLE,,47.9421921001,-0.218013743743,83,,Chevillé,Chevillé,72,Sarthe,52,Pays de la Loire
72084,CLERMONT CREANS,72200,CLERMONT CREANS,,47.732640164,-0.0240763113793,84,,Clermont-Créans,Clermont-Créans,72,Sarthe,52,Pays de la Loire
72085,COGNERS,72310,COGNERS,,47.8617807064,0.654044888377,85,,Cogners,Cogners,72,Sarthe,52,Pays de la Loire
72086,COMMERVEIL,72600,COMMERVEIL,,48.3141987198,0.35431330998,86,,Commerveil,Commerveil,72,Sarthe,52,Pays de la Loire
72087,CONFLANS SUR ANILLE,72120,CONFLANS SUR ANILLE,,47.9722293803,0.726834150431,87,,Conflans-sur-Anille,Conflans-sur-Anille,72,Sarthe,52,Pays de la Loire
72088,CONGE SUR ORNE,72290,CONGE SUR ORNE,,48.2095776009,0.254793307438,88,,Congé-sur-Orne,Congé-sur-Orne,72,Sarthe,52,Pays de la Loire
72089,CONLIE,72240,CONLIE,,48.1222946721,-0.0126744450375,89,,Conlie,Conlie,72,Sarthe,52,Pays de la Loire
72090,CONNERRE,72160,CONNERRE,,48.0507447506,0.467977281249,90,,Connerré,Connerré,72,Sarthe,52,Pays de la Loire
72091,CONTILLY,72600,CONTILLY,,48.413032549,0.35872391286,91,,Contilly,Contilly,72,Sarthe,52,Pays de la Loire
72093,CORMES,72400,CORMES,,48.1679248052,0.708991713095,93,,Cormes,Cormes,72,Sarthe,52,Pays de la Loire
72094,COUDRECIEUX,72440,COUDRECIEUX,,47.9853818366,0.625962425017,94,,Coudrecieux,Coudrecieux,72,Sarthe,52,Pays de la Loire
72095,COULAINES,72190,COULAINES,,48.0332041252,0.211301706062,95,,Coulaines,Coulaines,72,Sarthe,52,Pays de la Loire
72096,COULANS SUR GEE,72550,COULANS SUR GEE,,48.0220764526,0.0172606870942,96,,Coulans-sur-Gée,Coulans-sur-Gée,72,Sarthe,52,Pays de la Loire
72097,COULOMBIERS,72130,COULOMBIERS,,48.2947714312,0.12688051263,97,,Coulombiers,Coulombiers,72,Sarthe,52,Pays de la Loire
72098,COULONGE,72800,COULONGE,,47.6923999815,0.194526929998,98,,Coulongé,Coulongé,72,Sarthe,52,Pays de la Loire
72099,COURCEBOEUFS,72290,COURCEBOEUFS,,48.1265537944,0.282062390388,99,,Courcebœufs,Courcebœufs,72,Sarthe,52,Pays de la Loire
72100,COURCELLES LA FORET,72270,COURCELLES LA FORET,,47.7944370903,-0.0240623662465,100,,Courcelles-la-Forêt,Courcelles-la-Forêt,72,Sarthe,52,Pays de la Loire
72101,COURCEMONT,72110,COURCEMONT,,48.1772980099,0.343516519755,101,,Courcemont,Courcemont,72,Sarthe,52,Pays de la Loire
72102,COURCIVAL,72110,COURCIVAL,,48.2356656614,0.391626010989,102,,Courcival,Courcival,72,Sarthe,52,Pays de la Loire
72103,COURDEMANCHE,72150,COURDEMANCHE,,47.8164349745,0.555120405736,103,,Courdemanche,Courdemanche,72,Sarthe,52,Pays de la Loire
72103,COURDEMANCHE,72150,COURDEMANCHE,BRIVES,47.8164349745,0.555120405736,103,,Courdemanche,Courdemanche,72,Sarthe,52,Pays de la Loire
72104,COURGAINS,72260,COURGAINS,,48.2907641209,0.2766633169,104,,Courgains,Courgains,72,Sarthe,52,Pays de la Loire
72105,COURGENARD,72320,COURGENARD,,48.1573993444,0.739824903678,105,,Courgenard,Courgenard,72,Sarthe,52,Pays de la Loire
72106,COURTILLERS,72300,COURTILLERS,,47.8056753795,-0.315433885295,106,,Courtillers,Courtillers,72,Sarthe,52,Pays de la Loire
72107,CRANNES EN CHAMPAGNE,72540,CRANNES EN CHAMPAGNE,,47.9793340977,-0.0366151411596,107,,Crannes-en-Champagne,Crannes-en-Champagne,72,Sarthe,52,Pays de la Loire
72108,CRE SUR LOIR,72200,CRE SUR LOIR,,47.6579707942,-0.139863382378,108,,Cré-sur-Loir,Cré-sur-Loir,72,Sarthe,52,Pays de la Loire
72109,CRISSE,72140,CRISSE,,48.1802737725,-0.0645153355055,109,,Crissé,Crissé,72,Sarthe,52,Pays de la Loire
72110,CROSMIERES,72200,CROSMIERES,,47.7361996092,-0.154731681464,110,,Crosmières,Crosmières,72,Sarthe,52,Pays de la Loire
72111,CURES,72240,CURES,,48.0846394961,0.0166005701727,111,,Cures,Cures,72,Sarthe,52,Pays de la Loire
72112,DANGEUL,72260,DANGEUL,,48.2490170643,0.260997204151,112,,Dangeul,Dangeul,72,Sarthe,52,Pays de la Loire
72113,DEGRE,72550,DEGRE,,48.0528331198,0.0735665144485,113,,Degré,Degré,72,Sarthe,52,Pays de la Loire
72114,DEHAULT,72400,DEHAULT,,48.208236955,0.576083536745,114,,Dehault,Dehault,72,Sarthe,52,Pays de la Loire
72115,DISSAY SOUS COURCILLON,72500,DISSAY SOUS COURCILLON,,47.6664102233,0.484524622495,115,,Dissay-sous-Courcillon,Dissay-sous-Courcillon,72,Sarthe,52,Pays de la Loire
72116,DISSE SOUS BALLON,72260,DISSE SOUS BALLON,,48.2334463534,0.298017567939,116,,Dissé-sous-Ballon,Dissé-sous-Ballon,72,Sarthe,52,Pays de la Loire
72117,DISSE SOUS LE LUDE,72800,DISSE SOUS LE LUDE,,47.6109444771,0.150995629351,117,,Dissé-sous-le-Lude,Dissé-sous-le-Lude,72,Sarthe,52,Pays de la Loire
72118,DOLLON,72390,DOLLON,,48.0360309875,0.59353315613,118,,Dollon,Dollon,72,Sarthe,52,Pays de la Loire
72119,DOMFRONT EN CHAMPAGNE,72240,DOMFRONT EN CHAMPAGNE,,48.1091722798,0.0450026971482,119,,Domfront-en-Champagne,Domfront-en-Champagne,72,Sarthe,52,Pays de la Loire
72120,DOUCELLES,72170,DOUCELLES,,48.2560536745,0.183617363347,120,,Doucelles,Doucelles,72,Sarthe,52,Pays de la Loire
72121,DOUILLET,72130,DOUILLET LE JOLY,,48.2762672206,-0.0504235211752,121,,Douillet,Douillet,72,Sarthe,52,Pays de la Loire
72122,DUNEAU,72160,DUNEAU,,48.0674633869,0.534877628952,122,,Duneau,Duneau,72,Sarthe,52,Pays de la Loire
72123,DUREIL,72270,DUREIL,,47.8298601879,-0.134234804932,123,,Dureil,Dureil,72,Sarthe,52,Pays de la Loire
72124,ECOMMOY,72220,ECOMMOY,,47.8249669123,0.284900847455,124,,Écommoy,Écommoy,72,Sarthe,52,Pays de la Loire
72125,ECORPAIN,72120,ECORPAIN,,47.9378390819,0.63730489093,125,,Écorpain,Écorpain,72,Sarthe,52,Pays de la Loire
72126,EPINEU LE CHEVREUIL,72540,EPINEU LE CHEVREUIL,,48.0405286412,-0.132212278832,126,,Épineu-le-Chevreuil,Épineu-le-Chevreuil,72,Sarthe,52,Pays de la Loire
72127,ETIVAL LES LE MANS,72700,ETIVAL LES LE MANS,,47.9634415619,0.077249958248,127,,Étival-lès-le-Mans,Étival-lès-le-Mans,72,Sarthe,52,Pays de la Loire
72128,EVAILLE,72120,EVAILLE,,47.9002296748,0.626909662566,128,,Évaillé,Évaillé,72,Sarthe,52,Pays de la Loire
72129,FATINES,72470,FATINES,,48.0463106923,0.334777230729,129,,Fatines,Fatines,72,Sarthe,52,Pays de la Loire
72130,FAY,72550,FAY,,48.0006116609,0.0719177436046,130,,Fay,Fay,72,Sarthe,52,Pays de la Loire
72131,FERCE SUR SARTHE,72430,FERCE SUR SARTHE,,47.8973212837,-0.0356446886658,131,,Fercé-sur-Sarthe,Fercé-sur-Sarthe,72,Sarthe,52,Pays de la Loire
72132,LA FERTE BERNARD,72400,LA FERTE BERNARD,,48.1842560568,0.634694806211,132,La,Ferté-Bernard,La Ferté-Bernard,72,Sarthe,52,Pays de la Loire
72133,FILLE,72210,FILLE SUR SARTHE,,47.9023789335,0.115228035183,133,,Fillé,Fillé,72,Sarthe,52,Pays de la Loire
72134,FLEE,72500,FLEE,,47.7316026242,0.457355528125,134,,Flée,Flée,72,Sarthe,52,Pays de la Loire
72135,LA FONTAINE ST MARTIN,72330,LA FONTAINE ST MARTIN,,47.7939242622,0.0473281736821,135,La,Fontaine-Saint-Martin,La Fontaine-Saint-Martin,72,Sarthe,52,Pays de la Loire
72136,FONTENAY SUR VEGRE,72350,FONTENAY SUR VEGRE,,47.9074369831,-0.214830997282,136,,Fontenay-sur-Vègre,Fontenay-sur-Vègre,72,Sarthe,52,Pays de la Loire
72137,VILLENEUVE EN PERSEIGNE,72600,VILLENEUVE EN PERSEIGNE,,48.4380499533,0.236852667784,137,,Villeneuve-en-Perseigne,Villeneuve-en-Perseigne,72,Sarthe,52,Pays de la Loire
72137,VILLENEUVE EN PERSEIGNE,72600,VILLENEUVE EN PERSEIGNE,CHASSE,48.4380499533,0.236852667784,137,,Villeneuve-en-Perseigne,Villeneuve-en-Perseigne,72,Sarthe,52,Pays de la Loire
72137,VILLENEUVE EN PERSEIGNE,72600,VILLENEUVE EN PERSEIGNE,MONTIGNY,48.4380499533,0.236852667784,137,,Villeneuve-en-Perseigne,Villeneuve-en-Perseigne,72,Sarthe,52,Pays de la Loire
72137,VILLENEUVE EN PERSEIGNE,72600,VILLENEUVE EN PERSEIGNE,ROULLEE,48.4380499533,0.236852667784,137,,Villeneuve-en-Perseigne,Villeneuve-en-Perseigne,72,Sarthe,52,Pays de la Loire
72137,VILLENEUVE EN PERSEIGNE,72610,VILLENEUVE EN PERSEIGNE,LE BUISSON,48.4380499533,0.236852667784,137,,Villeneuve-en-Perseigne,Villeneuve-en-Perseigne,72,Sarthe,52,Pays de la Loire
72137,VILLENEUVE EN PERSEIGNE,72610,VILLENEUVE EN PERSEIGNE,LIGNIERES LA CARELLE,48.4380499533,0.236852667784,137,,Villeneuve-en-Perseigne,Villeneuve-en-Perseigne,72,Sarthe,52,Pays de la Loire
72137,VILLENEUVE EN PERSEIGNE,72610,VILLENEUVE EN PERSEIGNE,ST RIGOMER DES BOIS,48.4380499533,0.236852667784,137,,Villeneuve-en-Perseigne,Villeneuve-en-Perseigne,72,Sarthe,52,Pays de la Loire
72138,FRESNAY SUR SARTHE,72130,FRESNAY SUR SARTHE,,48.2817396551,0.0228368822752,138,,Fresnay-sur-Sarthe,Fresnay-sur-Sarthe,72,Sarthe,52,Pays de la Loire
72139,FYE,72610,FYE,,48.3232043088,0.081600822719,139,,Fyé,Fyé,72,Sarthe,52,Pays de la Loire
72141,GESNES LE GANDELIN,72130,GESNES LE GANDELIN,,48.3576711707,0.0296457032236,141,,Gesnes-le-Gandelin,Gesnes-le-Gandelin,72,Sarthe,52,Pays de la Loire
72142,GRANDCHAMP,72610,GRANDCHAMP,,48.3069428443,0.181392823725,142,,Grandchamp,Grandchamp,72,Sarthe,52,Pays de la Loire
72143,LE GRAND LUCE,72150,LE GRAND LUCE,,47.8672204186,0.463121636019,143,Le,Grand-Lucé,Le Grand-Lucé,72,Sarthe,52,Pays de la Loire
72144,GREEZ SUR ROC,72320,GREEZ SUR ROC,,48.136863975,0.823813790133,144,,Gréez-sur-Roc,Gréez-sur-Roc,72,Sarthe,52,Pays de la Loire
72145,LE GREZ,72140,LE GREZ,,48.189214849,-0.15566536803,145,Le,Grez,Le Grez,72,Sarthe,52,Pays de la Loire
72146,GUECELARD,72230,GUECELARD,,47.8842699845,0.142030828594,146,,Guécélard,Guécélard,72,Sarthe,52,Pays de la Loire
72147,LA GUIERCHE,72380,LA GUIERCHE,,48.1185093654,0.20108783128,147,La,Guierche,La Guierche,72,Sarthe,52,Pays de la Loire
72148,JAUZE,72110,JAUZE,,48.2111786499,0.382481451808,148,,Jauzé,Jauzé,72,Sarthe,52,Pays de la Loire
72149,JOUE EN CHARNIE,72540,JOUE EN CHARNIE,,48.0154941417,-0.194716478104,149,,Joué-en-Charnie,Joué-en-Charnie,72,Sarthe,52,Pays de la Loire
72150,JOUE L ABBE,72380,JOUE L ABBE,,48.1075710093,0.229853801099,150,,Joué-l'Abbé,Joué-l'Abbé,72,Sarthe,52,Pays de la Loire
72151,JUIGNE SUR SARTHE,72300,JUIGNE SUR SARTHE,,47.8682753202,-0.291741289618,151,,Juigné-sur-Sarthe,Juigné-sur-Sarthe,72,Sarthe,52,Pays de la Loire
72152,JUILLE,72170,JUILLE,,48.2452632445,0.12580313136,152,,Juillé,Juillé,72,Sarthe,52,Pays de la Loire
72153,JUPILLES,72500,JUPILLES,,47.7918893529,0.410315352572,153,,Jupilles,Jupilles,72,Sarthe,52,Pays de la Loire
72154,LA FLECHE,72200,LA FLECHE,,47.6893893794,-0.0646954849714,154,La,Flèche,La Flèche,72,Sarthe,52,Pays de la Loire
72155,LAIGNE EN BELIN,72220,LAIGNE EN BELIN,,47.8682405593,0.233744073595,155,,Laigné-en-Belin,Laigné-en-Belin,72,Sarthe,52,Pays de la Loire
72156,LAMNAY,72320,LAMNAY,,48.1056365774,0.70953933987,156,,Lamnay,Lamnay,72,Sarthe,52,Pays de la Loire
72157,LAVARDIN,72240,LAVARDIN,,48.0837900789,0.0628095880232,157,,Lavardin,Lavardin,72,Sarthe,52,Pays de la Loire
72158,LAVARE,72390,LAVARE,,48.0582643991,0.657147028816,158,,Lavaré,Lavaré,72,Sarthe,52,Pays de la Loire
72159,LAVENAY,72310,LAVENAY,,47.7834554406,0.687915046136,159,,Lavenay,Lavenay,72,Sarthe,52,Pays de la Loire
72159,LAVENAY,72310,LAVENAY,PONT DE BRAYE,47.7834554406,0.687915046136,159,,Lavenay,Lavenay,72,Sarthe,52,Pays de la Loire
72160,LAVERNAT,72500,LAVERNAT,,47.7185957169,0.346298771657,160,,Lavernat,Lavernat,72,Sarthe,52,Pays de la Loire
72161,LHOMME,72340,LHOMME,,47.7605490341,0.563670508119,161,,Lhomme,Lhomme,72,Sarthe,52,Pays de la Loire
72163,LIGRON,72270,LIGRON,,47.7695105998,-0.0134011784189,163,,Ligron,Ligron,72,Sarthe,52,Pays de la Loire
72164,LIVET EN SAOSNOIS,72610,LIVET EN SAOSNOIS,,48.3561318237,0.211071803966,164,,Livet-en-Saosnois,Livet-en-Saosnois,72,Sarthe,52,Pays de la Loire
72165,LOMBRON,72450,LOMBRON,,48.0861652592,0.412614616268,165,,Lombron,Lombron,72,Sarthe,52,Pays de la Loire
72166,LONGNES,72540,LONGNES,,48.0211923594,-0.0846933792818,166,,Longnes,Longnes,72,Sarthe,52,Pays de la Loire
72167,LOUAILLES,72300,LOUAILLES,,47.78323872,-0.239390755386,167,,Louailles,Louailles,72,Sarthe,52,Pays de la Loire
72168,LOUE,72540,LOUE,,47.9976308821,-0.138237768355,168,,Loué,Loué,72,Sarthe,52,Pays de la Loire
72169,LOUPLANDE,72210,LOUPLANDE,,47.9412506296,0.0485514721049,169,,Louplande,Louplande,72,Sarthe,52,Pays de la Loire
72170,LOUVIGNY,72600,LOUVIGNY,,48.3361679656,0.200765770002,170,,Louvigny,Louvigny,72,Sarthe,52,Pays de la Loire
72171,LOUZES,72600,LOUZES,,48.4277651874,0.29346290726,171,,Louzes,Louzes,72,Sarthe,52,Pays de la Loire
72172,LE LUART,72390,LE LUART,,48.0800184955,0.596928704808,172,Le,Luart,Le Luart,72,Sarthe,52,Pays de la Loire
72173,LUCEAU,72500,LUCEAU,,47.7177033077,0.391455867565,173,,Luceau,Luceau,72,Sarthe,52,Pays de la Loire
72174,LUCE SOUS BALLON,72290,LUCE SOUS BALLON,,48.2111457047,0.212575045337,174,,Lucé-sous-Ballon,Lucé-sous-Ballon,72,Sarthe,52,Pays de la Loire
72175,LUCHE PRINGE,72800,LUCHE PRINGE,,47.7038880653,0.0953882403778,175,,Luché-Pringé,Luché-Pringé,72,Sarthe,52,Pays de la Loire
72176,LE LUDE,72800,LE LUDE,,47.6542548485,0.150109969187,176,Le,Lude,Le Lude,72,Sarthe,52,Pays de la Loire
72177,MAIGNE,72210,MAIGNE,,47.9388716442,-0.0547288929729,177,,Maigné,Maigné,72,Sarthe,52,Pays de la Loire
72178,MAISONCELLES,72440,MAISONCELLES,,47.9379252388,0.577135485156,178,,Maisoncelles,Maisoncelles,72,Sarthe,52,Pays de la Loire
72179,MALICORNE SUR SARTHE,72270,MALICORNE SUR SARTHE,,47.8125544281,-0.0874543104248,179,,Malicorne-sur-Sarthe,Malicorne-sur-Sarthe,72,Sarthe,52,Pays de la Loire
72180,MAMERS,72600,MAMERS,,48.3545313197,0.370783494811,180,,Mamers,Mamers,72,Sarthe,52,Pays de la Loire
72181,LE MANS,72000,LE MANS,,47.9885256718,0.200030493539,181,Le,Mans,Le Mans,72,Sarthe,52,Pays de la Loire
72181,LE MANS,72100,LE MANS,,47.9885256718,0.200030493539,181,Le,Mans,Le Mans,72,Sarthe,52,Pays de la Loire
72182,MANSIGNE,72510,MANSIGNE,,47.7413528401,0.133663213026,182,,Mansigné,Mansigné,72,Sarthe,52,Pays de la Loire
72183,MARCON,72340,MARCON,,47.7050367685,0.526673141254,183,,Marçon,Marçon,72,Sarthe,52,Pays de la Loire
72184,MAREIL EN CHAMPAGNE,72540,MAREIL EN CHAMPAGNE,,47.980608006,-0.17895750019,184,,Mareil-en-Champagne,Mareil-en-Champagne,72,Sarthe,52,Pays de la Loire
72185,MAREIL SUR LOIR,72200,MAREIL SUR LOIR,,47.7201675683,0.00780177902929,185,,Mareil-sur-Loir,Mareil-sur-Loir,72,Sarthe,52,Pays de la Loire
72186,MARESCHE,72170,MARESCHE,,48.2055808859,0.149447281517,186,,Maresché,Maresché,72,Sarthe,52,Pays de la Loire
72187,MARIGNE LAILLE,72220,MARIGNE LAILLE,,47.8180439053,0.352157158687,187,,Marigné-Laillé,Marigné-Laillé,72,Sarthe,52,Pays de la Loire
72188,MAROLLETTE,72600,MAROLLETTE,,48.376493023,0.363054801374,188,,Marollette,Marollette,72,Sarthe,52,Pays de la Loire
72189,MAROLLES LES BRAULTS,72260,MAROLLES LES BRAULTS,,48.2539587745,0.320191786224,189,,Marolles-les-Braults,Marolles-les-Braults,72,Sarthe,52,Pays de la Loire
72190,MAROLLES LES ST CALAIS,72120,MAROLLES LES ST CALAIS,,47.9174702503,0.793391671769,190,,Marolles-lès-Saint-Calais,Marolles-lès-Saint-Calais,72,Sarthe,52,Pays de la Loire
72191,MAYET,72360,MAYET,,47.7642409399,0.279819645077,191,,Mayet,Mayet,72,Sarthe,52,Pays de la Loire
72192,LES MEES,72260,LES MEES,,48.3136638147,0.234399536229,192,Les,Mées,Les Mées,72,Sarthe,52,Pays de la Loire
72193,MELLERAY,72320,MELLERAY,,48.0949259298,0.813535451914,193,,Melleray,Melleray,72,Sarthe,52,Pays de la Loire
72194,MEURCE,72170,MEURCE,,48.237234129,0.201724875518,194,,Meurcé,Meurcé,72,Sarthe,52,Pays de la Loire
72195,MEZERAY,72270,MEZERAY,,47.8295768885,-0.0158831127316,195,,Mézeray,Mézeray,72,Sarthe,52,Pays de la Loire
72196,MEZIERES SUR PONTHOUIN,72290,MEZIERES SUR PONTHOUIN,,48.2022359732,0.297930869305,196,,Mézières-sur-Ponthouin,Mézières-sur-Ponthouin,72,Sarthe,52,Pays de la Loire
72197,MEZIERES SOUS LAVARDIN,72240,MEZIERES SOUS LAVARDIN,,48.1562672241,0.0477555800744,197,,Mézières-sous-Lavardin,Mézières-sous-Lavardin,72,Sarthe,52,Pays de la Loire
72198,LA MILESSE,72650,LA MILESSE,,48.0667169973,0.135919277684,198,La,Milesse,La Milesse,72,Sarthe,52,Pays de la Loire
72199,MOITRON SUR SARTHE,72170,MOITRON SUR SARTHE,,48.247330734,0.0603406887736,199,,Moitron-sur-Sarthe,Moitron-sur-Sarthe,72,Sarthe,52,Pays de la Loire
72199,MOITRON SUR SARTHE,72170,MOITRON SUR SARTHE,LE GUE LIAN,48.247330734,0.0603406887736,199,,Moitron-sur-Sarthe,Moitron-sur-Sarthe,72,Sarthe,52,Pays de la Loire
72200,MONCE EN BELIN,72230,MONCE EN BELIN,,47.8982351133,0.196356138911,200,,Moncé-en-Belin,Moncé-en-Belin,72,Sarthe,52,Pays de la Loire
72201,MONCE EN SAOSNOIS,72260,MONCE EN SAOSNOIS,,48.2731683792,0.388672784026,201,,Moncé-en-Saosnois,Moncé-en-Saosnois,72,Sarthe,52,Pays de la Loire
72202,MONHOUDOU,72260,MONHOUDOU,,48.2873963087,0.326162830698,202,,Monhoudou,Monhoudou,72,Sarthe,52,Pays de la Loire
72203,MONTABON,72500,MONTABON,,47.679959995,0.393423951615,203,,Montabon,Montabon,72,Sarthe,52,Pays de la Loire
72204,MONTAILLE,72120,MONTAILLE,,47.954960355,0.685903280988,204,,Montaillé,Montaillé,72,Sarthe,52,Pays de la Loire
72205,MONTBIZOT,72380,MONTBIZOT,,48.1530640298,0.182398817667,205,,Montbizot,Montbizot,72,Sarthe,52,Pays de la Loire
72208,MONTMIRAIL,72320,MONTMIRAIL,,48.1072506737,0.771306974355,208,,Montmirail,Montmirail,72,Sarthe,52,Pays de la Loire
72209,MONTREUIL LE CHETIF,72130,MONTREUIL LE CHETIF,,48.2411169593,-0.0384115382659,209,,Montreuil-le-Chétif,Montreuil-le-Chétif,72,Sarthe,52,Pays de la Loire
72210,MONTREUIL LE HENRI,72150,MONTREUIL LE HENRI,,47.8619039789,0.571664578097,210,,Montreuil-le-Henri,Montreuil-le-Henri,72,Sarthe,52,Pays de la Loire
72211,MONT ST JEAN,72140,MONT ST JEAN,,48.2435955785,-0.113254973959,211,,Mont-Saint-Jean,Mont-Saint-Jean,72,Sarthe,52,Pays de la Loire
72212,MOULINS LE CARBONNEL,72130,MOULINS LE CARBONNEL,,48.3735152031,-0.0131921167118,212,,Moulins-le-Carbonnel,Moulins-le-Carbonnel,72,Sarthe,52,Pays de la Loire
72213,MULSANNE,72230,MULSANNE,,47.9186376268,0.244158355853,213,,Mulsanne,Mulsanne,72,Sarthe,52,Pays de la Loire
72214,NAUVAY,72260,NAUVAY,,48.2541808432,0.393397353266,214,,Nauvay,Nauvay,72,Sarthe,52,Pays de la Loire
72215,NEUFCHATEL EN SAOSNOIS,72600,NEUFCHATEL EN SAOSNOIS,,48.3875624138,0.245183264636,215,,Neufchâtel-en-Saosnois,Neufchâtel-en-Saosnois,72,Sarthe,52,Pays de la Loire
72216,NEUVILLALAIS,72240,NEUVILLALAIS,,48.1540701215,-0.00726100705973,216,,Neuvillalais,Neuvillalais,72,Sarthe,52,Pays de la Loire
72217,NEUVILLE SUR SARTHE,72190,NEUVILLE SUR SARTHE,,48.0756439927,0.214405547448,217,,Neuville-sur-Sarthe,Neuville-sur-Sarthe,72,Sarthe,52,Pays de la Loire
72218,NEUVILLETTE EN CHARNIE,72140,NEUVILLETTE EN CHARNIE,,48.0874982634,-0.207010580824,218,,Neuvillette-en-Charnie,Neuvillette-en-Charnie,72,Sarthe,52,Pays de la Loire
72219,NEUVY EN CHAMPAGNE,72240,NEUVY EN CHAMPAGNE,,48.0792849605,-0.0218826401128,219,,Neuvy-en-Champagne,Neuvy-en-Champagne,72,Sarthe,52,Pays de la Loire
72220,NOGENT LE BERNARD,72110,NOGENT LE BERNARD,,48.2395023122,0.503482769952,220,,Nogent-le-Bernard,Nogent-le-Bernard,72,Sarthe,52,Pays de la Loire
72221,NOGENT SUR LOIR,72500,NOGENT SUR LOIR,,47.6564225079,0.398090984407,221,,Nogent-sur-Loir,Nogent-sur-Loir,72,Sarthe,52,Pays de la Loire
72222,NOUANS,72260,NOUANS,,48.2378930532,0.22830907332,222,,Nouans,Nouans,72,Sarthe,52,Pays de la Loire
72223,NOYEN SUR SARTHE,72430,NOYEN SUR SARTHE,,47.8589974473,-0.100335586338,223,,Noyen-sur-Sarthe,Noyen-sur-Sarthe,72,Sarthe,52,Pays de la Loire
72224,NUILLE LE JALAIS,72370,NUILLE LE JALAIS,,48.0204559278,0.485891467634,224,,Nuillé-le-Jalais,Nuillé-le-Jalais,72,Sarthe,52,Pays de la Loire
72225,OISSEAU LE PETIT,72610,OISSEAU LE PETIT,,48.3488422257,0.0741365485752,225,,Oisseau-le-Petit,Oisseau-le-Petit,72,Sarthe,52,Pays de la Loire
72226,OIZE,72330,OIZE,,47.8021261548,0.109630746883,226,,Oizé,Oizé,72,Sarthe,52,Pays de la Loire
72227,PANON,72600,PANON,,48.3391172905,0.300272585869,227,,Panon,Panon,72,Sarthe,52,Pays de la Loire
72228,PARCE SUR SARTHE,72300,PARCE SUR SARTHE,,47.8213588323,-0.193405753909,228,,Parcé-sur-Sarthe,Parcé-sur-Sarthe,72,Sarthe,52,Pays de la Loire
72229,PARENNES,72140,PARENNES,,48.1171850426,-0.185567402147,229,,Parennes,Parennes,72,Sarthe,52,Pays de la Loire
72230,PARIGNE LE POLIN,72330,PARIGNE LE POLIN,,47.8560377991,0.124440147574,230,,Parigné-le-Pôlin,Parigné-le-Pôlin,72,Sarthe,52,Pays de la Loire
72231,PARIGNE L EVEQUE,72250,PARIGNE L EVEQUE,,47.9315338021,0.371756176736,231,,Parigné-l'Évêque,Parigné-l'Évêque,72,Sarthe,52,Pays de la Loire
72232,NOTRE DAME DU PE,72300,NOTRE DAME DU PE,,47.7258292037,-0.337170709008,232,,Notre-Dame-du-Pé,Notre-Dame-du-Pé,72,Sarthe,52,Pays de la Loire
72233,PERAY,72260,PERAY,,48.2500157703,0.370719916731,233,,Peray,Peray,72,Sarthe,52,Pays de la Loire
72234,PEZE LE ROBERT,72140,PEZE LE ROBERT,,48.202501305,-0.0424195278143,234,,Pezé-le-Robert,Pezé-le-Robert,72,Sarthe,52,Pays de la Loire
72235,PIACE,72170,PIACE,,48.2662297106,0.114166808373,235,,Piacé,Piacé,72,Sarthe,52,Pays de la Loire
72236,PINCE,72300,PINCE,,47.7974854625,-0.367271386435,236,,Pincé,Pincé,72,Sarthe,52,Pays de la Loire
72237,PIRMIL,72430,PIRMIL,,47.9103473581,-0.0897163271202,237,,Pirmil,Pirmil,72,Sarthe,52,Pays de la Loire
72238,PIZIEUX,72600,PIZIEUX,,48.3230783466,0.333658227533,238,,Pizieux,Pizieux,72,Sarthe,52,Pays de la Loire
72239,POILLE SUR VEGRE,72350,POILLE SUR VEGRE,,47.9162913627,-0.271498760066,239,,Poillé-sur-Vègre,Poillé-sur-Vègre,72,Sarthe,52,Pays de la Loire
72240,PONCE SUR LE LOIR,72340,PONCE SUR LE LOIR,,47.7736203084,0.657052556948,240,,Poncé-sur-le-Loir,Poncé-sur-le-Loir,72,Sarthe,52,Pays de la Loire
72241,MONTFORT LE GESNOIS,72450,MONTFORT LE GESNOIS,,48.0577881474,0.402183285649,241,,Montfort-le-Gesnois,Montfort-le-Gesnois,72,Sarthe,52,Pays de la Loire
72243,PONTVALLAIN,72510,PONTVALLAIN,,47.7590113873,0.199384022474,243,,Pontvallain,Pontvallain,72,Sarthe,52,Pays de la Loire
72244,PRECIGNE,72300,PRECIGNE,,47.7644010028,-0.318024755467,244,,Précigné,Précigné,72,Sarthe,52,Pays de la Loire
72245,PREVAL,72400,PREVAL,,48.2266918238,0.619955132757,245,,Préval,Préval,72,Sarthe,52,Pays de la Loire
72246,PREVELLES,72110,PREVELLES,,48.1540240549,0.474693426494,246,,Prévelles,Prévelles,72,Sarthe,52,Pays de la Loire
72247,PRUILLE LE CHETIF,72700,PRUILLE LE CHETIF,,47.9926394002,0.10552280267,247,,Pruillé-le-Chétif,Pruillé-le-Chétif,72,Sarthe,52,Pays de la Loire
72248,PRUILLE L EGUILLE,72150,PRUILLE L EGUILLE,,47.8385530465,0.421797610193,248,,Pruillé-l'Éguillé,Pruillé-l'Éguillé,72,Sarthe,52,Pays de la Loire
72249,LA QUINTE,72550,LA QUINTE,,48.0511343365,0.0420391198634,249,La,Quinte,La Quinte,72,Sarthe,52,Pays de la Loire
72250,RAHAY,72120,RAHAY,,47.957486899,0.811406701903,250,,Rahay,Rahay,72,Sarthe,52,Pays de la Loire
72251,RENE,72260,RENE,,48.2766544769,0.217202160114,251,,René,René,72,Sarthe,52,Pays de la Loire
72252,REQUEIL,72510,REQUEIL,,47.7866756494,0.157684341786,252,,Requeil,Requeil,72,Sarthe,52,Pays de la Loire
72253,ROEZE SUR SARTHE,72210,ROEZE SUR SARTHE,,47.8935298702,0.0716892654727,253,,Roézé-sur-Sarthe,Roézé-sur-Sarthe,72,Sarthe,52,Pays de la Loire
72254,ROUESSE FONTAINE,72610,ROUESSE FONTAINE,,48.3186762666,0.147946893872,254,,Rouessé-Fontaine,Rouessé-Fontaine,72,Sarthe,52,Pays de la Loire
72255,ROUESSE VASSE,72140,ROUESSE VASSE,,48.1551147556,-0.199832416726,255,,Rouessé-Vassé,Rouessé-Vassé,72,Sarthe,52,Pays de la Loire
72256,ROUEZ,72140,ROUEZ,,48.1376844826,-0.119954104642,256,,Rouez,Rouez,72,Sarthe,52,Pays de la Loire
72257,ROUILLON,72700,ROUILLON,,48.0005070104,0.142249684917,257,,Rouillon,Rouillon,72,Sarthe,52,Pays de la Loire
72259,ROUPERROUX LE COQUET,72110,ROUPERROUX LE COQUET,,48.2293569796,0.438592972489,259,,Rouperroux-le-Coquet,Rouperroux-le-Coquet,72,Sarthe,52,Pays de la Loire
72260,RUAUDIN,72230,RUAUDIN,,47.9430727058,0.266049763098,260,,Ruaudin,Ruaudin,72,Sarthe,52,Pays de la Loire
72261,RUILLE EN CHAMPAGNE,72240,RUILLE EN CHAMPAGNE,,48.0569761583,-0.11518703174,261,,Ruillé-en-Champagne,Ruillé-en-Champagne,72,Sarthe,52,Pays de la Loire
72262,RUILLE SUR LOIR,72340,RUILLE SUR LOIR,,47.7854221078,0.618393311988,262,,Loir en Vallée,Loir en Vallée,72,Sarthe,52,Pays de la Loire
72264,SABLE SUR SARTHE,72300,SABLE SUR SARTHE,,47.8378265007,-0.354705885665,264,,Sablé-sur-Sarthe,Sablé-sur-Sarthe,72,Sarthe,52,Pays de la Loire
72265,ST AIGNAN,72110,ST AIGNAN,,48.2178811072,0.34647150751,265,,Saint-Aignan,Saint-Aignan,72,Sarthe,52,Pays de la Loire
72266,ST AUBIN DE LOCQUENAY,72130,ST AUBIN DE LOCQUENAY,,48.2582927988,0.00918330836218,266,,Saint-Aubin-de-Locquenay,Saint-Aubin-de-Locquenay,72,Sarthe,52,Pays de la Loire
72267,ST AUBIN DES COUDRAIS,72400,ST AUBIN DES COUDRAIS,,48.1788442585,0.579073483742,267,,Saint-Aubin-des-Coudrais,Saint-Aubin-des-Coudrais,72,Sarthe,52,Pays de la Loire
72268,ST BIEZ EN BELIN,72220,ST BIEZ EN BELIN,,47.8185029824,0.23744354895,268,,Saint-Biez-en-Belin,Saint-Biez-en-Belin,72,Sarthe,52,Pays de la Loire
72269,ST CALAIS,72120,ST CALAIS,,47.9250578354,0.747660967628,269,,Saint-Calais,Saint-Calais,72,Sarthe,52,Pays de la Loire
72270,ST CALEZ EN SAOSNOIS,72600,ST CALEZ EN SAOSNOIS,,48.3079476641,0.311052842461,270,,Saint-Calez-en-Saosnois,Saint-Calez-en-Saosnois,72,Sarthe,52,Pays de la Loire
72271,ST CELERIN,72110,ST CELERIN,,48.1258397672,0.433533893718,271,,Saint-Célerin,Saint-Célerin,72,Sarthe,52,Pays de la Loire
72272,STE CEROTTE,72120,STE CEROTTE,,47.9011298028,0.682806358799,272,,Sainte-Cérotte,Sainte-Cérotte,72,Sarthe,52,Pays de la Loire
72273,ST CHRISTOPHE DU JAMBET,72170,ST CHRISTOPHE DU JAMBET,,48.2343278617,0.0606617233123,273,,Saint-Christophe-du-Jambet,Saint-Christophe-du-Jambet,72,Sarthe,52,Pays de la Loire
72274,ST CHRISTOPHE EN CHAMPAGNE,72540,ST CHRISTOPHE EN CHAMPAGNE,,47.9638649026,-0.137657502688,274,,Saint-Christophe-en-Champagne,Saint-Christophe-en-Champagne,72,Sarthe,52,Pays de la Loire
72275,ST CORNEILLE,72460,ST CORNEILLE,,48.0774289474,0.342197463189,275,,Saint-Corneille,Saint-Corneille,72,Sarthe,52,Pays de la Loire
72276,ST COSME EN VAIRAIS,72110,ST COSME EN VAIRAIS,,48.2744377482,0.457973879241,276,,Saint-Cosme-en-Vairais,Saint-Cosme-en-Vairais,72,Sarthe,52,Pays de la Loire
72277,ST DENIS DES COUDRAIS,72110,ST DENIS DES COUDRAIS,,48.1620373195,0.506879313638,277,,Saint-Denis-des-Coudrais,Saint-Denis-des-Coudrais,72,Sarthe,52,Pays de la Loire
72278,ST DENIS D ORQUES,72350,ST DENIS D ORQUES,,48.0311233627,-0.275648536634,278,,Saint-Denis-d'Orques,Saint-Denis-d'Orques,72,Sarthe,52,Pays de la Loire
72279,ST GEORGES DE LA COUEE,72150,ST GEORGES DE LA COUEE,,47.8259245181,0.59121847011,279,,Saint-Georges-de-la-Couée,Saint-Georges-de-la-Couée,72,Sarthe,52,Pays de la Loire
72280,ST GEORGES DU BOIS,72700,ST GEORGES DU BOIS,,47.9720668662,0.101201029384,280,,Saint-Georges-du-Bois,Saint-Georges-du-Bois,72,Sarthe,52,Pays de la Loire
72281,ST GEORGES DU ROSAY,72110,ST GEORGES DU ROSAY,,48.1996205131,0.523209464167,281,,Saint-Georges-du-Rosay,Saint-Georges-du-Rosay,72,Sarthe,52,Pays de la Loire
72282,ST GEORGES LE GAULTIER,72130,ST GEORGES LE GAULTIER,,48.2951885176,-0.110267923649,282,,Saint-Georges-le-Gaultier,Saint-Georges-le-Gaultier,72,Sarthe,52,Pays de la Loire
72283,ST GERMAIN D ARCE,72800,ST GERMAIN D ARCE,,47.6279897995,0.286031290683,283,,Saint-Germain-d'Arcé,Saint-Germain-d'Arcé,72,Sarthe,52,Pays de la Loire
72284,ST GERMAIN SUR SARTHE,72130,ST GERMAIN SUR SARTHE,,48.2796531655,0.0781088012686,284,,Saint-Germain-sur-Sarthe,Saint-Germain-sur-Sarthe,72,Sarthe,52,Pays de la Loire
72284,ST GERMAIN SUR SARTHE,72130,ST GERMAIN SUR SARTHE,LA HUTTE,48.2796531655,0.0781088012686,284,,Saint-Germain-sur-Sarthe,Saint-Germain-sur-Sarthe,72,Sarthe,52,Pays de la Loire
72286,ST GERVAIS DE VIC,72120,ST GERVAIS DE VIC,,47.882312592,0.733164783668,286,,Saint-Gervais-de-Vic,Saint-Gervais-de-Vic,72,Sarthe,52,Pays de la Loire
72287,ST GERVAIS EN BELIN,72220,ST GERVAIS EN BELIN,,47.8700687155,0.200309682523,287,,Saint-Gervais-en-Belin,Saint-Gervais-en-Belin,72,Sarthe,52,Pays de la Loire
72289,STE JAMME SUR SARTHE,72380,STE JAMME SUR SARTHE,,48.138410724,0.158484582373,289,,Sainte-Jamme-sur-Sarthe,Sainte-Jamme-sur-Sarthe,72,Sarthe,52,Pays de la Loire
72289,STE JAMME SUR SARTHE,72380,STE JAMME SUR SARTHE,ANTOIGNE,48.138410724,0.158484582373,289,,Sainte-Jamme-sur-Sarthe,Sainte-Jamme-sur-Sarthe,72,Sarthe,52,Pays de la Loire
72290,ST JEAN D ASSE,72380,ST JEAN D ASSE,,48.158356786,0.121471172036,290,,Saint-Jean-d'Assé,Saint-Jean-d'Assé,72,Sarthe,52,Pays de la Loire
72291,ST JEAN DE LA MOTTE,72510,ST JEAN DE LA MOTTE,,47.7580584214,0.0554184806948,291,,Saint-Jean-de-la-Motte,Saint-Jean-de-la-Motte,72,Sarthe,52,Pays de la Loire
72292,ST JEAN DES ECHELLES,72320,ST JEAN DES ECHELLES,,48.1309646826,0.733209725788,292,,Saint-Jean-des-Échelles,Saint-Jean-des-Échelles,72,Sarthe,52,Pays de la Loire
72293,ST JEAN DU BOIS,72430,ST JEAN DU BOIS,,47.8649273014,-0.0337210880136,293,,Saint-Jean-du-Bois,Saint-Jean-du-Bois,72,Sarthe,52,Pays de la Loire
72294,ST LEONARD DES BOIS,72130,ST LEONARD DES BOIS,,48.3541977725,-0.0869413519394,294,,Saint-Léonard-des-Bois,Saint-Léonard-des-Bois,72,Sarthe,52,Pays de la Loire
72295,ST LONGIS,72600,ST LONGIS,,48.3534054858,0.336993327744,295,,Saint-Longis,Saint-Longis,72,Sarthe,52,Pays de la Loire
72296,ST MAIXENT,72320,ST MAIXENT,,48.1092450135,0.652363595226,296,,Saint-Maixent,Saint-Maixent,72,Sarthe,52,Pays de la Loire
72297,ST MARCEAU,72170,ST MARCEAU,,48.1828234191,0.122196005127,297,,Saint-Marceau,Saint-Marceau,72,Sarthe,52,Pays de la Loire
72298,ST MARS DE LOCQUENAY,72440,ST MARS DE LOCQUENAY,,47.9317580131,0.506038620378,298,,Saint-Mars-de-Locquenay,Saint-Mars-de-Locquenay,72,Sarthe,52,Pays de la Loire
72299,ST MARS D OUTILLE,72220,ST MARS D OUTILLE,,47.8696754844,0.342695139915,299,,Saint-Mars-d'Outillé,Saint-Mars-d'Outillé,72,Sarthe,52,Pays de la Loire
72300,ST MARS LA BRIERE,72470,ST MARS LA BRIERE,,48.0174931387,0.378790037048,300,,Saint-Mars-la-Brière,Saint-Mars-la-Brière,72,Sarthe,52,Pays de la Loire
72302,ST MARTIN DES MONTS,72400,ST MARTIN DES MONTS,,48.1512082085,0.597953696239,302,,Saint-Martin-des-Monts,Saint-Martin-des-Monts,72,Sarthe,52,Pays de la Loire
72303,ST MICHEL DE CHAVAIGNES,72440,ST MICHEL DE CHAVAIGNES,,48.0092301302,0.57342688237,303,,Saint-Michel-de-Chavaignes,Saint-Michel-de-Chavaignes,72,Sarthe,52,Pays de la Loire
72304,STE OSMANE,72120,STE OSMANE,,47.8723192705,0.610274340418,304,,Sainte-Osmane,Sainte-Osmane,72,Sarthe,52,Pays de la Loire
72305,ST OUEN DE MIMBRE,72130,ST OUEN DE MIMBRE,,48.2913700885,0.051229134462,305,,Saint-Ouen-de-Mimbré,Saint-Ouen-de-Mimbré,72,Sarthe,52,Pays de la Loire
72306,ST OUEN EN BELIN,72220,ST OUEN EN BELIN,,47.8355363425,0.212260698777,306,,Saint-Ouen-en-Belin,Saint-Ouen-en-Belin,72,Sarthe,52,Pays de la Loire
72307,ST OUEN EN CHAMPAGNE,72350,ST OUEN EN CHAMPAGNE,,47.9578477128,-0.178608875377,307,,Saint-Ouen-en-Champagne,Saint-Ouen-en-Champagne,72,Sarthe,52,Pays de la Loire
72308,ST PATERNE,72610,ST PATERNE,,48.4136370812,0.114907308448,308,,Saint-Paterne - Le Chevain,Saint-Paterne - Le Chevain,72,Sarthe,52,Pays de la Loire
72309,ST PAUL LE GAULTIER,72130,ST PAUL LE GAULTIER,,48.3259671658,-0.117341949056,309,,Saint-Paul-le-Gaultier,Saint-Paul-le-Gaultier,72,Sarthe,52,Pays de la Loire
72310,ST PAVACE,72190,ST PAVACE,,48.0410090578,0.194891549504,310,,Saint-Pavace,Saint-Pavace,72,Sarthe,52,Pays de la Loire
72311,ST PIERRE DE CHEVILLE,72500,ST PIERRE DE CHEVILLE,,47.6357786169,0.434044707817,311,,Saint-Pierre-de-Chevillé,Saint-Pierre-de-Chevillé,72,Sarthe,52,Pays de la Loire
72312,ST PIERRE DES BOIS,72430,ST PIERRE DES BOIS,,47.9414864633,-0.135650706918,312,,Saint-Pierre-des-Bois,Saint-Pierre-des-Bois,72,Sarthe,52,Pays de la Loire
72313,ST PIERRE DES ORMES,72600,ST PIERRE DES ORMES,,48.2934012161,0.420423717805,313,,Saint-Pierre-des-Ormes,Saint-Pierre-des-Ormes,72,Sarthe,52,Pays de la Loire
72314,ST PIERRE DU LOROUER,72150,ST PIERRE DU LOROUER,,47.7963803238,0.50839859976,314,,Saint-Pierre-du-Lorouër,Saint-Pierre-du-Lorouër,72,Sarthe,52,Pays de la Loire
72315,ST REMY DE SILLE,72140,ST REMY DE SILLE,,48.1814893411,-0.102928256564,315,,Saint-Rémy-de-Sillé,Saint-Rémy-de-Sillé,72,Sarthe,52,Pays de la Loire
72316,ST REMY DES MONTS,72600,ST REMY DES MONTS,,48.321786609,0.382928882455,316,,Saint-Rémy-des-Monts,Saint-Rémy-des-Monts,72,Sarthe,52,Pays de la Loire
72317,ST REMY DU VAL,72600,ST REMY DU VAL,,48.3488699009,0.251095298312,317,,Saint-Rémy-du-Val,Saint-Rémy-du-Val,72,Sarthe,52,Pays de la Loire
72319,STE SABINE SUR LONGEVE,72380,STE SABINE SUR LONGEVE,,48.1357559817,0.0899900112141,319,,Sainte-Sabine-sur-Longève,Sainte-Sabine-sur-Longève,72,Sarthe,52,Pays de la Loire
72320,ST SATURNIN,72650,ST SATURNIN,,48.0593049019,0.172849807382,320,,Saint-Saturnin,Saint-Saturnin,72,Sarthe,52,Pays de la Loire
72321,ST SYMPHORIEN,72240,ST SYMPHORIEN,,48.0785627545,-0.140972858304,321,,Saint-Symphorien,Saint-Symphorien,72,Sarthe,52,Pays de la Loire
72322,ST ULPHACE,72320,ST ULPHACE,,48.1566973317,0.840132351008,322,,Saint-Ulphace,Saint-Ulphace,72,Sarthe,52,Pays de la Loire
72323,ST VICTEUR,72130,ST VICTEUR,,48.3169882456,0.0374842816825,323,,Saint-Victeur,Saint-Victeur,72,Sarthe,52,Pays de la Loire
72324,ST VINCENT DES PRES,72600,ST VINCENT DES PRES,,48.2955356606,0.373225492644,324,,Saint-Vincent-des-Prés,Saint-Vincent-des-Prés,72,Sarthe,52,Pays de la Loire
72325,ST VINCENT DU LOROUER,72150,ST VINCENT DU LOROUER,,47.8210184227,0.480742705963,325,,Saint-Vincent-du-Lorouër,Saint-Vincent-du-Lorouër,72,Sarthe,52,Pays de la Loire
72326,SAOSNES,72600,SAOSNES,,48.3259873984,0.288443058218,326,,Saosnes,Saosnes,72,Sarthe,52,Pays de la Loire
72327,SARCE,72360,SARCE,,47.7223246034,0.228700458643,327,,Sarcé,Sarcé,72,Sarthe,52,Pays de la Loire
72328,SARGE LES LE MANS,72190,SARGE LES LE MANS,,48.0418982247,0.247286439875,328,,Sargé-lès-le-Mans,Sargé-lès-le-Mans,72,Sarthe,52,Pays de la Loire
72329,SAVIGNE L EVEQUE,72460,SAVIGNE L EVEQUE,,48.0834488074,0.287812838107,329,,Savigné-l'Évêque,Savigné-l'Évêque,72,Sarthe,52,Pays de la Loire
72330,SAVIGNE SOUS LE LUDE,72800,SAVIGNE SOUS LE LUDE,,47.629906916,0.0724159454527,330,,Savigné-sous-le-Lude,Savigné-sous-le-Lude,72,Sarthe,52,Pays de la Loire
72331,SCEAUX SUR HUISNE,72160,SCEAUX SUR HUISNE,,48.1011260781,0.580759685853,331,,Sceaux-sur-Huisne,Sceaux-sur-Huisne,72,Sarthe,52,Pays de la Loire
72332,SEGRIE,72170,SEGRIE,,48.2118641806,0.0166401413218,332,,Ségrie,Ségrie,72,Sarthe,52,Pays de la Loire
72333,SEMUR EN VALLON,72390,SEMUR EN VALLON,,48.0214150215,0.672345988795,333,,Semur-en-Vallon,Semur-en-Vallon,72,Sarthe,52,Pays de la Loire
72334,SILLE LE GUILLAUME,72140,SILLE LE GUILLAUME,,48.1864769196,-0.132591918679,334,,Sillé-le-Guillaume,Sillé-le-Guillaume,72,Sarthe,52,Pays de la Loire
72335,SILLE LE PHILIPPE,72460,SILLE LE PHILIPPE,,48.1062934505,0.34906675752,335,,Sillé-le-Philippe,Sillé-le-Philippe,72,Sarthe,52,Pays de la Loire
72336,SOLESMES,72300,SOLESMES,,47.8443845125,-0.280679742039,336,,Solesmes,Solesmes,72,Sarthe,52,Pays de la Loire
72337,SOUGE LE GANELON,72130,SOUGE LE GANELON,,48.3147256299,-0.0444490879879,337,,Sougé-le-Ganelon,Sougé-le-Ganelon,72,Sarthe,52,Pays de la Loire
72338,SOUILLE,72380,SOUILLE,,48.1201531494,0.17339248534,338,,Souillé,Souillé,72,Sarthe,52,Pays de la Loire
72339,SOULIGNE FLACE,72210,SOULIGNE FLACE,,47.9670341132,0.0141635131783,339,,Souligné-Flacé,Souligné-Flacé,72,Sarthe,52,Pays de la Loire
72340,SOULIGNE SOUS BALLON,72290,SOULIGNE SOUS BALLON,,48.1366698283,0.238046849882,340,,Souligné-sous-Ballon,Souligné-sous-Ballon,72,Sarthe,52,Pays de la Loire
72341,SOULITRE,72370,SOULITRE,,48.0223482346,0.441300492215,341,,Soulitré,Soulitré,72,Sarthe,52,Pays de la Loire
72342,SOUVIGNE SUR MEME,72400,SOUVIGNE SUR MEME,,48.2172120607,0.648847952112,342,,Souvigné-sur-Même,Souvigné-sur-Même,72,Sarthe,52,Pays de la Loire
72343,SOUVIGNE SUR SARTHE,72300,SOUVIGNE SUR SARTHE,,47.8249718183,-0.411523601476,343,,Souvigné-sur-Sarthe,Souvigné-sur-Sarthe,72,Sarthe,52,Pays de la Loire
72344,SPAY,72700,SPAY,,47.9251136777,0.151021420961,344,,Spay,Spay,72,Sarthe,52,Pays de la Loire
72345,SURFONDS,72370,SURFONDS,,47.979285909,0.462514595008,345,,Surfonds,Surfonds,72,Sarthe,52,Pays de la Loire
72346,LA SUZE SUR SARTHE,72210,LA SUZE SUR SARTHE,,47.8772923207,0.0251444644642,346,La,Suze-sur-Sarthe,La Suze-sur-Sarthe,72,Sarthe,52,Pays de la Loire
72347,TASSE,72430,TASSE,,47.8880451469,-0.149262839514,347,,Tassé,Tassé,72,Sarthe,52,Pays de la Loire
72348,TASSILLE,72540,TASSILLE,,47.9881765912,-0.0946992624258,348,,Tassillé,Tassillé,72,Sarthe,52,Pays de la Loire
72349,TEILLE,72290,TEILLE,,48.1849465917,0.188449766884,349,,Teillé,Teillé,72,Sarthe,52,Pays de la Loire
72350,TELOCHE,72220,TELOCHE,,47.8904177884,0.278475546841,350,,Teloché,Teloché,72,Sarthe,52,Pays de la Loire
72351,TENNIE,72240,TENNIE,,48.1097279005,-0.0864881845663,351,,Tennie,Tennie,72,Sarthe,52,Pays de la Loire
72352,TERREHAULT,72110,TERREHAULT,,48.20419337,0.406392146471,352,,Terrehault,Terrehault,72,Sarthe,52,Pays de la Loire
72353,THELIGNY,72320,THELIGNY,,48.1717914833,0.785600114205,353,,Théligny,Théligny,72,Sarthe,52,Pays de la Loire
72354,THOIGNE,72260,THOIGNE,,48.2851988813,0.250186351944,354,,Thoigné,Thoigné,72,Sarthe,52,Pays de la Loire
72355,THOIRE SOUS CONTENSOR,72610,THOIRE SOUS CONTENSOR,,48.3123639724,0.205432244367,355,,Thoiré-sous-Contensor,Thoiré-sous-Contensor,72,Sarthe,52,Pays de la Loire
72356,THOIRE SUR DINAN,72500,THOIRE SUR DINAN,,47.7646608356,0.462066578914,356,,Thoiré-sur-Dinan,Thoiré-sur-Dinan,72,Sarthe,52,Pays de la Loire
72357,THOREE LES PINS,72800,THOREE LES PINS,,47.6661683505,0.0339317804074,357,,Thorée-les-Pins,Thorée-les-Pins,72,Sarthe,52,Pays de la Loire
72358,THORIGNE SUR DUE,72160,THORIGNE SUR DUE,,48.0297154005,0.519874230361,358,,Thorigné-sur-Dué,Thorigné-sur-Dué,72,Sarthe,52,Pays de la Loire
72359,TORCE EN VALLEE,72110,TORCE EN VALLEE,,48.1322879299,0.39195616258,359,,Torcé-en-Vallée,Torcé-en-Vallée,72,Sarthe,52,Pays de la Loire
72360,TRANGE,72650,TRANGE,,48.0291229026,0.115747157927,360,,Trangé,Trangé,72,Sarthe,52,Pays de la Loire
72361,TRESSON,72440,TRESSON,,47.9030368156,0.556315449485,361,,Tresson,Tresson,72,Sarthe,52,Pays de la Loire
72362,LE TRONCHET,72170,LE TRONCHET,,48.1800998557,0.0814798946478,362,Le,Tronchet,Le Tronchet,72,Sarthe,52,Pays de la Loire
72363,TUFFE VAL DE LA CHERONNE,72160,TUFFE VAL DE LA CHERONNE,,48.120886565,0.50838049197,363,,Tuffé Val de la Chéronne,Tuffé Val de la Chéronne,72,Sarthe,52,Pays de la Loire
72363,TUFFE VAL DE LA CHERONNE,72160,TUFFE VAL DE LA CHERONNE,ST HILAIRE LE LIERRU,48.120886565,0.50838049197,363,,Tuffé Val de la Chéronne,Tuffé Val de la Chéronne,72,Sarthe,52,Pays de la Loire
72364,VAAS,72500,VAAS,,47.6723014522,0.315373744758,364,,Vaas,Vaas,72,Sarthe,52,Pays de la Loire
72366,VALENNES,72320,VALENNES,,48.0059667214,0.804886650467,366,,Valennes,Valennes,72,Sarthe,52,Pays de la Loire
72367,VALLON SUR GEE,72540,VALLON SUR GEE,,47.9595693292,-0.0842449217262,367,,Vallon-sur-Gée,Vallon-sur-Gée,72,Sarthe,52,Pays de la Loire
72368,VANCE,72310,VANCE,,47.8347996701,0.659547605602,368,,Vancé,Vancé,72,Sarthe,52,Pays de la Loire
72369,VERNEIL LE CHETIF,72360,VERNEIL LE CHETIF,,47.7196089183,0.298597882427,369,,Verneil-le-Chétif,Verneil-le-Chétif,72,Sarthe,52,Pays de la Loire
72370,VERNIE,72170,VERNIE,,48.1820114335,0.0183634956199,370,,Vernie,Vernie,72,Sarthe,52,Pays de la Loire
72372,VEZOT,72600,VEZOT,,48.3534640428,0.294816923539,372,,Vezot,Vezot,72,Sarthe,52,Pays de la Loire
72373,VIBRAYE,72320,VIBRAYE,,48.0480142116,0.739798488483,373,,Vibraye,Vibraye,72,Sarthe,52,Pays de la Loire
72374,VILLAINES LA CARELLE,72600,VILLAINES LA CARELLE,,48.3873561577,0.294814588667,374,,Villaines-la-Carelle,Villaines-la-Carelle,72,Sarthe,52,Pays de la Loire
72375,VILLAINES LA GONAIS,72400,VILLAINES LA GONAIS,,48.13356803,0.608403023138,375,,Villaines-la-Gonais,Villaines-la-Gonais,72,Sarthe,52,Pays de la Loire
72376,VILLAINES SOUS LUCE,72150,VILLAINES SOUS LUCE,,47.8805456446,0.502085922886,376,,Villaines-sous-Lucé,Villaines-sous-Lucé,72,Sarthe,52,Pays de la Loire
72377,VILLAINES SOUS MALICORNE,72270,VILLAINES SOUS MALICORNE,,47.7570761042,-0.104784379095,377,,Villaines-sous-Malicorne,Villaines-sous-Malicorne,72,Sarthe,52,Pays de la Loire
72378,VION,72300,VION,,47.8146942645,-0.264230208564,378,,Vion,Vion,72,Sarthe,52,Pays de la Loire
72379,VIRE EN CHAMPAGNE,72350,VIRE EN CHAMPAGNE,,47.9879997602,-0.291022041763,379,,Viré-en-Champagne,Viré-en-Champagne,72,Sarthe,52,Pays de la Loire
72380,VIVOIN,72170,VIVOIN,,48.2401913817,0.162292381772,380,,Vivoin,Vivoin,72,Sarthe,52,Pays de la Loire
72381,VOIVRES LES LE MANS,72210,VOIVRES LES LE MANS,,47.9278286492,0.0845168943294,381,,Voivres-lès-le-Mans,Voivres-lès-le-Mans,72,Sarthe,52,Pays de la Loire
72382,VOLNAY,72440,VOLNAY,,47.9518684826,0.470224737802,382,,Volnay,Volnay,72,Sarthe,52,Pays de la Loire
72383,VOUVRAY SUR HUISNE,72160,VOUVRAY SUR HUISNE,,48.0857990108,0.553521929431,383,,Vouvray-sur-Huisne,Vouvray-sur-Huisne,72,Sarthe,52,Pays de la Loire
72384,VOUVRAY SUR LOIR,72500,VOUVRAY SUR LOIR,,47.6934510328,0.458489994876,384,,Vouvray-sur-Loir,Vouvray-sur-Loir,72,Sarthe,52,Pays de la Loire
72384,VOUVRAY SUR LOIR,72500,VOUVRAY SUR LOIR,COEMONT,47.6934510328,0.458489994876,384,,Vouvray-sur-Loir,Vouvray-sur-Loir,72,Sarthe,52,Pays de la Loire
72385,YVRE LE POLIN,72330,YVRE LE POLIN,,47.8344620716,0.159510178703,385,,Yvré-le-Pôlin,Yvré-le-Pôlin,72,Sarthe,52,Pays de la Loire
72386,YVRE L EVEQUE,72530,YVRE L EVEQUE,,48.0234127838,0.282137902604,386,,Yvré-l'Évêque,Yvré-l'Évêque,72,Sarthe,52,Pays de la Loire
73001,AIGUEBELETTE LE LAC,73610,AIGUEBELETTE LE LAC,,45.5476875903,5.81387589748,1,,Aiguebelette-le-Lac,Aiguebelette-le-Lac,73,Savoie,84,Auvergne-Rhône-Alpes
73002,AIGUEBELLE,73220,AIGUEBELLE,,45.5328984806,6.30449738468,2,,Aiguebelle,Aiguebelle,73,Savoie,84,Auvergne-Rhône-Alpes
73003,AIGUEBLANCHE,73260,AIGUEBLANCHE,,45.5198646844,6.51470725301,3,,Aigueblanche,Aigueblanche,73,Savoie,84,Auvergne-Rhône-Alpes
73004,AILLON LE JEUNE,73340,AILLON LE JEUNE,,45.6088639529,6.08868598171,4,,Aillon-le-Jeune,Aillon-le-Jeune,73,Savoie,84,Auvergne-Rhône-Alpes
73005,AILLON LE VIEUX,73340,AILLON LE VIEUX,,45.6506523863,6.08810624188,5,,Aillon-le-Vieux,Aillon-le-Vieux,73,Savoie,84,Auvergne-Rhône-Alpes
73006,AIME LA PLAGNE,73210,AIME LA PLAGNE,,45.5525768932,6.61836974596,6,,Aime-la-Plagne,Aime-la-Plagne,73,Savoie,84,Auvergne-Rhône-Alpes
73006,AIME LA PLAGNE,73210,AIME LA PLAGNE,GRANIER,45.5525768932,6.61836974596,6,,Aime-la-Plagne,Aime-la-Plagne,73,Savoie,84,Auvergne-Rhône-Alpes
73006,AIME LA PLAGNE,73210,AIME LA PLAGNE,LONGEFOY,45.5525768932,6.61836974596,6,,Aime-la-Plagne,Aime-la-Plagne,73,Savoie,84,Auvergne-Rhône-Alpes
73006,AIME LA PLAGNE,73210,AIME LA PLAGNE,MONTGIROD,45.5525768932,6.61836974596,6,,Aime-la-Plagne,Aime-la-Plagne,73,Savoie,84,Auvergne-Rhône-Alpes
73006,AIME LA PLAGNE,73210,AIME LA PLAGNE,TESSENS,45.5525768932,6.61836974596,6,,Aime-la-Plagne,Aime-la-Plagne,73,Savoie,84,Auvergne-Rhône-Alpes
73006,AIME LA PLAGNE,73210,AIME LA PLAGNE,VILLETTE,45.5525768932,6.61836974596,6,,Aime-la-Plagne,Aime-la-Plagne,73,Savoie,84,Auvergne-Rhône-Alpes
73007,AITON,73220,AITON,,45.5733792643,6.257350927,7,,Aiton,Aiton,73,Savoie,84,Auvergne-Rhône-Alpes
73008,AIX LES BAINS,73100,AIX LES BAINS,,45.6978541675,5.90388626955,8,,Aix-les-Bains,Aix-les-Bains,73,Savoie,84,Auvergne-Rhône-Alpes
73010,ENTRELACS,73410,ENTRELACS,ALBENS,45.789988746,5.93731067789,10,,Entrelacs,Entrelacs,73,Savoie,84,Auvergne-Rhône-Alpes
73010,ENTRELACS,73410,ENTRELACS,ANSIGNY,45.789988746,5.93731067789,10,,Entrelacs,Entrelacs,73,Savoie,84,Auvergne-Rhône-Alpes
73010,ENTRELACS,73410,ENTRELACS,CESSENS,45.789988746,5.93731067789,10,,Entrelacs,Entrelacs,73,Savoie,84,Auvergne-Rhône-Alpes
73010,ENTRELACS,73410,ENTRELACS,EPERSY,45.789988746,5.93731067789,10,,Entrelacs,Entrelacs,73,Savoie,84,Auvergne-Rhône-Alpes
73010,ENTRELACS,73410,ENTRELACS,MOGNARD,45.789988746,5.93731067789,10,,Entrelacs,Entrelacs,73,Savoie,84,Auvergne-Rhône-Alpes
73010,ENTRELACS,73410,ENTRELACS,ST GERMAIN LA CHAMBOTTE,45.789988746,5.93731067789,10,,Entrelacs,Entrelacs,73,Savoie,84,Auvergne-Rhône-Alpes
73010,ENTRELACS,73410,ENTRELACS,ST GIROD,45.789988746,5.93731067789,10,,Entrelacs,Entrelacs,73,Savoie,84,Auvergne-Rhône-Alpes
73011,ALBERTVILLE,73200,ALBERTVILLE,,45.6683987277,6.40460338643,11,,Albertville,Albertville,73,Savoie,84,Auvergne-Rhône-Alpes
73012,ALBIEZ LE JEUNE,73300,ALBIEZ LE JEUNE,,45.2391859578,6.35812756812,12,,Albiez-le-Jeune,Albiez-le-Jeune,73,Savoie,84,Auvergne-Rhône-Alpes
73013,ALBIEZ MONTROND,73300,ALBIEZ MONTROND,,45.1885296943,6.33989973257,13,,Albiez-Montrond,Albiez-Montrond,73,Savoie,84,Auvergne-Rhône-Alpes
73014,ALLONDAZ,73200,ALLONDAZ,,45.7116530326,6.37223835121,14,,Allondaz,Allondaz,73,Savoie,84,Auvergne-Rhône-Alpes
73015,LES ALLUES,73550,LES ALLUES,,45.3697146214,6.58767852898,15,Les,Allues,Les Allues,73,Savoie,84,Auvergne-Rhône-Alpes
73015,LES ALLUES,73550,LES ALLUES,MERIBEL LES ALLUES,45.3697146214,6.58767852898,15,Les,Allues,Les Allues,73,Savoie,84,Auvergne-Rhône-Alpes
73017,APREMONT,73190,APREMONT,,45.5012177421,5.93652738114,17,,Apremont,Apremont,73,Savoie,84,Auvergne-Rhône-Alpes
73018,ARBIN,73800,ARBIN,,45.510931197,6.07455521712,18,,Arbin,Arbin,73,Savoie,84,Auvergne-Rhône-Alpes
73019,ARGENTINE,73220,ARGENTINE,,45.4893893107,6.33336554074,19,,Argentine,Argentine,73,Savoie,84,Auvergne-Rhône-Alpes
73020,ARITH,73340,ARITH,,45.7213212651,6.06342859728,20,,Arith,Arith,73,Savoie,84,Auvergne-Rhône-Alpes
73021,ARVILLARD,73110,ARVILLARD,,45.4097930929,6.16998092383,21,,Arvillard,Arvillard,73,Savoie,84,Auvergne-Rhône-Alpes
73022,ATTIGNAT ONCIN,73610,ATTIGNAT ONCIN,,45.5141356972,5.77930411133,22,,Attignat-Oncin,Attignat-Oncin,73,Savoie,84,Auvergne-Rhône-Alpes
73023,AUSSOIS,73500,AUSSOIS,,45.2550275097,6.72903201851,23,,Aussois,Aussois,73,Savoie,84,Auvergne-Rhône-Alpes
73024,LES AVANCHERS VALMOREL,73260,LES AVANCHERS VALMOREL,,45.4607199042,6.44362178612,24,Les,Avanchers-Valmorel,Les Avanchers-Valmorel,73,Savoie,84,Auvergne-Rhône-Alpes
73025,AVRESSIEUX,73240,AVRESSIEUX,,45.5713044928,5.69389635857,25,,Avressieux,Avressieux,73,Savoie,84,Auvergne-Rhône-Alpes
73026,AVRIEUX,73500,AVRIEUX,,45.1781016318,6.74676532189,26,,Avrieux,Avrieux,73,Savoie,84,Auvergne-Rhône-Alpes
73027,AYN,73470,AYN,,45.5715140758,5.74945889695,27,,Ayn,Ayn,73,Savoie,84,Auvergne-Rhône-Alpes
73028,LA BALME,73170,LA BALME,,45.6779560454,5.70516932541,28,La,Balme,La Balme,73,Savoie,84,Auvergne-Rhône-Alpes
73029,BARBERAZ,73000,BARBERAZ,,45.5520596872,5.93925556329,29,,Barberaz,Barberaz,73,Savoie,84,Auvergne-Rhône-Alpes
73030,BARBY,73230,BARBY,,45.5699721228,5.98303026413,30,,Barby,Barby,73,Savoie,84,Auvergne-Rhône-Alpes
73031,BASSENS,73000,BASSENS,,45.5810128911,5.93865967351,31,,Bassens,Bassens,73,Savoie,84,Auvergne-Rhône-Alpes
73032,LA BATHIE,73540,LA BATHIE,,45.6383854539,6.4735036135,32,La,Bâthie,La Bâthie,73,Savoie,84,Auvergne-Rhône-Alpes
73033,LA BAUCHE,73360,LA BAUCHE,,45.4819870888,5.7703646358,33,La,Bauche,La Bauche,73,Savoie,84,Auvergne-Rhône-Alpes
73034,BEAUFORT,73270,BEAUFORT SUR DORON,,45.6857270362,6.6034275098,34,,Beaufort,Beaufort,73,Savoie,84,Auvergne-Rhône-Alpes
73034,BEAUFORT,73270,BEAUFORT SUR DORON,ARECHES,45.6857270362,6.6034275098,34,,Beaufort,Beaufort,73,Savoie,84,Auvergne-Rhône-Alpes
73036,BELLECOMBE EN BAUGES,73340,BELLECOMBE EN BAUGES,,45.7380105012,6.14458814234,36,,Bellecombe-en-Bauges,Bellecombe-en-Bauges,73,Savoie,84,Auvergne-Rhône-Alpes
73039,BELMONT TRAMONET,73330,BELMONT TRAMONET,,45.5651185204,5.6763421885,39,,Belmont-Tramonet,Belmont-Tramonet,73,Savoie,84,Auvergne-Rhône-Alpes
73040,BESSANS,73480,BESSANS,,45.292032537,7.04532734274,40,,Bessans,Bessans,73,Savoie,84,Auvergne-Rhône-Alpes
73041,BETTON BETTONET,73390,BETTON BETTONET,,45.5270872916,6.18182093379,41,,Betton-Bettonet,Betton-Bettonet,73,Savoie,84,Auvergne-Rhône-Alpes
73042,BILLIEME,73170,BILLIEME,,45.7192241812,5.81460064999,42,,Billième,Billième,73,Savoie,84,Auvergne-Rhône-Alpes
73043,LA BIOLLE,73410,LA BIOLLE,,45.759099279,5.91593169517,43,La,Biolle,La Biolle,73,Savoie,84,Auvergne-Rhône-Alpes
73045,LE BOIS,73260,LE BOIS,,45.4840008846,6.49947186126,45,Le,Bois,Le Bois,73,Savoie,84,Auvergne-Rhône-Alpes
73046,BONNEVAL,73260,BONNEVAL,,45.5181659738,6.42775770653,46,,Bonneval,Bonneval,73,Savoie,84,Auvergne-Rhône-Alpes
73047,BONNEVAL SUR ARC,73480,BONNEVAL SUR ARC,,45.3858269371,7.09178979521,47,,Bonneval-sur-Arc,Bonneval-sur-Arc,73,Savoie,84,Auvergne-Rhône-Alpes
73048,BONVILLARD,73460,BONVILLARD,,45.5825214492,6.33021122482,48,,Bonvillard,Bonvillard,73,Savoie,84,Auvergne-Rhône-Alpes
73049,BONVILLARET,73220,BONVILLARET,,45.5632077114,6.3154289465,49,,Bonvillaret,Bonvillaret,73,Savoie,84,Auvergne-Rhône-Alpes
73050,BOURDEAU,73370,BOURDEAU,,45.6771264766,5.85370895157,50,,Bourdeau,Bourdeau,73,Savoie,84,Auvergne-Rhône-Alpes
73051,LE BOURGET DU LAC,73370,LE BOURGET DU LAC,,45.6441486088,5.84622475132,51,Le,Bourget-du-Lac,Le Bourget-du-Lac,73,Savoie,84,Auvergne-Rhône-Alpes
73052,BOURGET EN HUILE,73110,BOURGET EN HUILE,,45.4820997208,6.21172718237,52,,Bourget-en-Huile,Bourget-en-Huile,73,Savoie,84,Auvergne-Rhône-Alpes
73053,BOURGNEUF,73390,BOURGNEUF,,45.552915929,6.24666170942,53,,Bourgneuf,Bourgneuf,73,Savoie,84,Auvergne-Rhône-Alpes
73054,BOURG ST MAURICE,73700,BOURG ST MAURICE,,45.6647980545,6.76637903907,54,,Bourg-Saint-Maurice,Bourg-Saint-Maurice,73,Savoie,84,Auvergne-Rhône-Alpes
73054,BOURG ST MAURICE,73700,BOURG ST MAURICE,HAUTEVILLE GONDON,45.6647980545,6.76637903907,54,,Bourg-Saint-Maurice,Bourg-Saint-Maurice,73,Savoie,84,Auvergne-Rhône-Alpes
73054,BOURG ST MAURICE,73700,BOURG ST MAURICE,LES ARCS,45.6647980545,6.76637903907,54,,Bourg-Saint-Maurice,Bourg-Saint-Maurice,73,Savoie,84,Auvergne-Rhône-Alpes
73055,BOZEL,73350,BOZEL,,45.4621455547,6.65092707324,55,,Bozel,Bozel,73,Savoie,84,Auvergne-Rhône-Alpes
73056,BRAMANS,73500,BRAMANS,,45.18737013,6.84225367965,56,,Bramans,Bramans,73,Savoie,84,Auvergne-Rhône-Alpes
73057,BRIDES LES BAINS,73570,BRIDES LES BAINS,,45.4538077442,6.56840188785,57,,Brides-les-Bains,Brides-les-Bains,73,Savoie,84,Auvergne-Rhône-Alpes
73058,LA BRIDOIRE,73520,LA BRIDOIRE,,45.5299612439,5.73849540904,58,La,Bridoire,La Bridoire,73,Savoie,84,Auvergne-Rhône-Alpes
73059,BRISON ST INNOCENT,73100,BRISON ST INNOCENT,,45.7395213661,5.88235600157,59,,Brison-Saint-Innocent,Brison-Saint-Innocent,73,Savoie,84,Auvergne-Rhône-Alpes
73061,CESARCHES,73200,CESARCHES,,45.7027845836,6.41379630554,61,,Césarches,Césarches,73,Savoie,84,Auvergne-Rhône-Alpes
73063,CEVINS,73730,CEVINS,,45.6094285678,6.4969871671,63,,Cevins,Cevins,73,Savoie,84,Auvergne-Rhône-Alpes
73064,CHALLES LES EAUX,73190,CHALLES LES EAUX,,45.5493464648,5.98357559058,64,,Challes-les-Eaux,Challes-les-Eaux,73,Savoie,84,Auvergne-Rhône-Alpes
73065,CHAMBERY,73000,CHAMBERY,,45.583182552,5.90903392417,65,,Chambéry,Chambéry,73,Savoie,84,Auvergne-Rhône-Alpes
73067,LA CHAMBRE,73130,LA CHAMBRE,,45.3669668762,6.29360957589,67,La,Chambre,La Chambre,73,Savoie,84,Auvergne-Rhône-Alpes
73068,CHAMOUSSET,73390,CHAMOUSSET,,45.5652468191,6.20689994252,68,,Chamousset,Chamousset,73,Savoie,84,Auvergne-Rhône-Alpes
73069,CHAMOUX SUR GELON,73390,CHAMOUX SUR GELON,,45.5341655971,6.21788663815,69,,Chamoux-sur-Gelon,Chamoux-sur-Gelon,73,Savoie,84,Auvergne-Rhône-Alpes
73070,CHAMPAGNEUX,73240,CHAMPAGNEUX,,45.6266318375,5.66751566229,70,,Champagneux,Champagneux,73,Savoie,84,Auvergne-Rhône-Alpes
73071,CHAMPAGNY EN VANOISE,73350,CHAMPAGNY EN VANOISE,,45.450515364,6.77846464114,71,,Champagny-en-Vanoise,Champagny-en-Vanoise,73,Savoie,84,Auvergne-Rhône-Alpes
73072,CHAMP LAURENT,73390,CHAMP LAURENT,,45.5074933777,6.20853295293,72,,Champ-Laurent,Champ-Laurent,73,Savoie,84,Auvergne-Rhône-Alpes
73073,CHANAZ,73310,CHANAZ,,45.8034782998,5.79654546056,73,,Chanaz,Chanaz,73,Savoie,84,Auvergne-Rhône-Alpes
73074,LA CHAPELLE,73660,LA CHAPELLE,,45.4217303227,6.29384300807,74,La,Chapelle,La Chapelle,73,Savoie,84,Auvergne-Rhône-Alpes
73075,LA CHAPELLE BLANCHE,73110,LA CHAPELLE BLANCHE,,45.4497737754,6.07384698897,75,La,Chapelle-Blanche,La Chapelle-Blanche,73,Savoie,84,Auvergne-Rhône-Alpes
73076,LA CHAPELLE DU MONT DU CHAT,73370,LA CHAPELLE DU MONT DU CHAT,,45.7138835544,5.85471743894,76,La,Chapelle-du-Mont-du-Chat,La Chapelle-du-Mont-du-Chat,73,Savoie,84,Auvergne-Rhône-Alpes
73077,LES CHAPELLES,73700,LES CHAPELLES,,45.6072931842,6.71151467254,77,Les,Chapelles,Les Chapelles,73,Savoie,84,Auvergne-Rhône-Alpes
73078,LA CHAPELLE ST MARTIN,73170,LA CHAPELLE ST MARTIN,,45.6499016869,5.75392460611,78,La,Chapelle-Saint-Martin,La Chapelle-Saint-Martin,73,Savoie,84,Auvergne-Rhône-Alpes
73079,CHATEAUNEUF,73390,CHATEAUNEUF,,45.5473788658,6.18096637147,79,,Châteauneuf,Châteauneuf,73,Savoie,84,Auvergne-Rhône-Alpes
73080,LE CHATEL,73300,LE CHATEL,,45.3239149489,6.37973948477,80,Le,Châtel,Le Châtel,73,Savoie,84,Auvergne-Rhône-Alpes
73081,LE CHATELARD,73630,LE CHATELARD,,45.6813038277,6.12957262483,81,Le,Châtelard,Le Châtelard,73,Savoie,84,Auvergne-Rhône-Alpes
73082,LA CHAVANNE,73800,LA CHAVANNE,,45.4931434452,6.06652812144,82,La,Chavanne,La Chavanne,73,Savoie,84,Auvergne-Rhône-Alpes
73083,LES CHAVANNES EN MAURIENNE,73660,LES CHAVANNES EN MAURIENNE,,45.389687369,6.2888113422,83,Les,Chavannes-en-Maurienne,Les Chavannes-en-Maurienne,73,Savoie,84,Auvergne-Rhône-Alpes
73084,CHIGNIN,73800,CHIGNIN,,45.5235808519,6.0136049327,84,,Chignin,Chignin,73,Savoie,84,Auvergne-Rhône-Alpes
73085,CHINDRIEUX,73310,CHINDRIEUX,,45.8127166804,5.8446052441,85,,Chindrieux,Chindrieux,73,Savoie,84,Auvergne-Rhône-Alpes
73086,CLERY,73460,CLERY,,45.6476389714,6.27314604473,86,,Cléry,Cléry,73,Savoie,84,Auvergne-Rhône-Alpes
73087,COGNIN,73160,COGNIN,,45.5594810193,5.89180815798,87,,Cognin,Cognin,73,Savoie,84,Auvergne-Rhône-Alpes
73088,COHENNOZ,73400,COHENNOZ,,45.7632895459,6.49611998394,88,,Cohennoz,Cohennoz,73,Savoie,84,Auvergne-Rhône-Alpes
73088,COHENNOZ,73590,COHENNOZ,,45.7632895459,6.49611998394,88,,Cohennoz,Cohennoz,73,Savoie,84,Auvergne-Rhône-Alpes
73089,COISE ST JEAN PIED GAUTHIER,73800,COISE ST JEAN PIED GAUTHIER,,45.5259224168,6.13092024983,89,,Coise-Saint-Jean-Pied-Gauthier,Coise-Saint-Jean-Pied-Gauthier,73,Savoie,84,Auvergne-Rhône-Alpes
73090,LA COMPOTE,73630,LA COMPOTE,,45.6597136497,6.14819976084,90,La,Compôte,La Compôte,73,Savoie,84,Auvergne-Rhône-Alpes
73091,CONJUX,73310,CONJUX,,45.7934929325,5.82300314526,91,,Conjux,Conjux,73,Savoie,84,Auvergne-Rhône-Alpes
73092,CORBEL,73160,CORBEL,,45.43925526,5.82865097845,92,,Corbel,Corbel,73,Savoie,84,Auvergne-Rhône-Alpes
73094,CREST VOLAND,73590,CREST VOLAND,,45.7896752359,6.51608069874,94,,Crest-Voland,Crest-Voland,73,Savoie,84,Auvergne-Rhône-Alpes
73095,LA CROIX DE LA ROCHETTE,73110,LA CROIX DE LA ROCHETTE,,45.4746019888,6.11799818286,95,La,Croix-de-la-Rochette,La Croix-de-la-Rochette,73,Savoie,84,Auvergne-Rhône-Alpes
73096,CRUET,73800,CRUET,,45.5313034202,6.09302638614,96,,Cruet,Cruet,73,Savoie,84,Auvergne-Rhône-Alpes
73097,CURIENNE,73190,CURIENNE,,45.5594547086,6.01073450691,97,,Curienne,Curienne,73,Savoie,84,Auvergne-Rhône-Alpes
73098,LES DESERTS,73230,LES DESERTS,,45.6408440768,5.99604230711,98,Les,Déserts,Les Déserts,73,Savoie,84,Auvergne-Rhône-Alpes
73098,LES DESERTS,73230,LES DESERTS,LA FECLAZ,45.6408440768,5.99604230711,98,Les,Déserts,Les Déserts,73,Savoie,84,Auvergne-Rhône-Alpes
73099,DETRIER,73110,DETRIER,,45.4463545043,6.09897767775,99,,Détrier,Détrier,73,Savoie,84,Auvergne-Rhône-Alpes
73100,DOMESSIN,73330,DOMESSIN,,45.5371179998,5.70221062208,100,,Domessin,Domessin,73,Savoie,84,Auvergne-Rhône-Alpes
73101,DOUCY EN BAUGES,73630,DOUCY EN BAUGES,,45.6961895971,6.17641277073,101,,Doucy-en-Bauges,Doucy-en-Bauges,73,Savoie,84,Auvergne-Rhône-Alpes
73103,DRUMETTAZ CLARAFOND,73420,DRUMETTAZ CLARAFOND,,45.6612110951,5.93781427031,103,,Drumettaz-Clarafond,Drumettaz-Clarafond,73,Savoie,84,Auvergne-Rhône-Alpes
73104,DULLIN,73610,DULLIN,,45.5511197543,5.74939054489,104,,Dullin,Dullin,73,Savoie,84,Auvergne-Rhône-Alpes
73105,LES ECHELLES,73360,LES ECHELLES,,45.4514870099,5.74305650824,105,Les,Échelles,Les Échelles,73,Savoie,84,Auvergne-Rhône-Alpes
73106,ECOLE,73630,ECOLE,,45.6287158464,6.1879831519,106,,École,École,73,Savoie,84,Auvergne-Rhône-Alpes
73107,ENTREMONT LE VIEUX,73670,ENTREMONT LE VIEUX,,45.4619276502,5.88514501859,107,,Entremont-le-Vieux,Entremont-le-Vieux,73,Savoie,84,Auvergne-Rhône-Alpes
73109,EPIERRE,73220,EPIERRE,,45.4524759546,6.32466741894,109,,Épierre,Épierre,73,Savoie,84,Auvergne-Rhône-Alpes
73110,ESSERTS BLAY,73540,ESSERTS BLAY,,45.6265663308,6.41875161664,110,,Esserts-Blay,Esserts-Blay,73,Savoie,84,Auvergne-Rhône-Alpes
73111,ETABLE,73110,ETABLE,,45.4653754545,6.14395478368,111,,Étable,Étable,73,Savoie,84,Auvergne-Rhône-Alpes
73112,FEISSONS SUR ISERE,73260,FEISSONS SUR ISERE,,45.5746002243,6.49003430382,112,,Feissons-sur-Isère,Feissons-sur-Isère,73,Savoie,84,Auvergne-Rhône-Alpes
73113,FEISSONS SUR SALINS,73350,FEISSONS SUR SALINS,,45.4725612545,6.56127729089,113,,Feissons-sur-Salins,Feissons-sur-Salins,73,Savoie,84,Auvergne-Rhône-Alpes
73114,FLUMET,73590,FLUMET,,45.8347717729,6.52972144353,114,,Flumet,Flumet,73,Savoie,84,Auvergne-Rhône-Alpes
73116,FONTCOUVERTE LA TOUSSUIRE,73300,FONTCOUVERTE LA TOUSSUIRE,,45.2460484635,6.28249903846,116,,Fontcouverte-la-Toussuire,Fontcouverte-la-Toussuire,73,Savoie,84,Auvergne-Rhône-Alpes
73116,FONTCOUVERTE LA TOUSSUIRE,73300,FONTCOUVERTE LA TOUSSUIRE,LA TOUSSUIRE,45.2460484635,6.28249903846,116,,Fontcouverte-la-Toussuire,Fontcouverte-la-Toussuire,73,Savoie,84,Auvergne-Rhône-Alpes
73117,FOURNEAUX,73500,FOURNEAUX,,45.1790051314,6.63768399812,117,,Fourneaux,Fourneaux,73,Savoie,84,Auvergne-Rhône-Alpes
73118,FRANCIN,73800,FRANCIN,,45.4921246224,6.03037399148,118,,Francin,Francin,73,Savoie,84,Auvergne-Rhône-Alpes
73119,FRENEY,73500,FRENEY,,45.1839626924,6.61246852795,119,,Freney,Freney,73,Savoie,84,Auvergne-Rhône-Alpes
73120,FRETERIVE,73250,FRETERIVE,,45.5925212338,6.20927183971,120,,Fréterive,Fréterive,73,Savoie,84,Auvergne-Rhône-Alpes
73121,FRONTENEX,73460,FRONTENEX,,45.6328915156,6.31143524397,121,,Frontenex,Frontenex,73,Savoie,84,Auvergne-Rhône-Alpes
73122,GERBAIX,73470,GERBAIX,,45.6072180611,5.73727466441,122,,Gerbaix,Gerbaix,73,Savoie,84,Auvergne-Rhône-Alpes
73123,LA GIETTAZ,73590,LA GIETTAZ,,45.8738355415,6.51022116567,123,La,Giettaz,La Giettaz,73,Savoie,84,Auvergne-Rhône-Alpes
73124,GILLY SUR ISERE,73200,GILLY SUR ISERE,,45.653102038,6.3498533574,124,,Gilly-sur-Isère,Gilly-sur-Isère,73,Savoie,84,Auvergne-Rhône-Alpes
73127,GRESIN,73240,GRESIN,,45.6068898415,5.68384330888,127,,Gresin,Gresin,73,Savoie,84,Auvergne-Rhône-Alpes
73128,GRESY SUR AIX,73100,GRESY SUR AIX,,45.7239088376,5.9343072349,128,,Grésy-sur-Aix,Grésy-sur-Aix,73,Savoie,84,Auvergne-Rhône-Alpes
73129,GRESY SUR ISERE,73460,GRESY SUR ISERE,,45.6009706105,6.24611530229,129,,Grésy-sur-Isère,Grésy-sur-Isère,73,Savoie,84,Auvergne-Rhône-Alpes
73130,GRIGNON,73200,GRIGNON,,45.6421717118,6.38372169869,130,,Grignon,Grignon,73,Savoie,84,Auvergne-Rhône-Alpes
73131,HAUTECOUR,73600,HAUTECOUR,,45.5170974575,6.54362483947,131,,Hautecour,Hautecour,73,Savoie,84,Auvergne-Rhône-Alpes
73132,HAUTELUCE,73620,HAUTELUCE,,45.7639265553,6.61481598841,132,,Hauteluce,Hauteluce,73,Savoie,84,Auvergne-Rhône-Alpes
73133,HAUTEVILLE,73390,HAUTEVILLE,,45.5277372502,6.16582880732,133,,Hauteville,Hauteville,73,Savoie,84,Auvergne-Rhône-Alpes
73135,HERMILLON,73300,HERMILLON,,45.3048823312,6.38960278843,135,,Hermillon,Hermillon,73,Savoie,84,Auvergne-Rhône-Alpes
73137,JACOB BELLECOMBETTE,73000,JACOB BELLECOMBETTE,,45.5515613333,5.9173220983,137,,Jacob-Bellecombette,Jacob-Bellecombette,73,Savoie,84,Auvergne-Rhône-Alpes
73138,JARRIER,73300,JARRIER,,45.287185628,6.3075655794,138,,Jarrier,Jarrier,73,Savoie,84,Auvergne-Rhône-Alpes
73139,JARSY,73630,JARSY,,45.669075325,6.21321879581,139,,Jarsy,Jarsy,73,Savoie,84,Auvergne-Rhône-Alpes
73140,JONGIEUX,73170,JONGIEUX,,45.7366415454,5.80069130287,140,,Jongieux,Jongieux,73,Savoie,84,Auvergne-Rhône-Alpes
73141,LAISSAUD,73800,LAISSAUD,,45.4540504149,6.03226580276,141,,Laissaud,Laissaud,73,Savoie,84,Auvergne-Rhône-Alpes
73142,LANDRY,73210,LANDRY,,45.5639247765,6.76248697474,142,,Landry,Landry,73,Savoie,84,Auvergne-Rhône-Alpes
73143,LANSLEBOURG MONT CENIS,73480,LANSLEBOURG MONT CENIS,,45.2441911207,6.92592607947,143,,Lanslebourg-Mont-Cenis,Lanslebourg-Mont-Cenis,73,Savoie,84,Auvergne-Rhône-Alpes
73144,LANSLEVILLARD,73480,LANSLEVILLARD,,45.2983250137,6.93326635567,144,,Lanslevillard,Lanslevillard,73,Savoie,84,Auvergne-Rhône-Alpes
73145,LEPIN LE LAC,73610,LEPIN LE LAC,,45.5358336406,5.79231986465,145,,Lépin-le-Lac,Lépin-le-Lac,73,Savoie,84,Auvergne-Rhône-Alpes
73146,LESCHERAINES,73340,LESCHERAINES,,45.7071042341,6.10303709808,146,,Lescheraines,Lescheraines,73,Savoie,84,Auvergne-Rhône-Alpes
73147,LOISIEUX,73170,LOISIEUX,,45.6484157655,5.72648384584,147,,Loisieux,Loisieux,73,Savoie,84,Auvergne-Rhône-Alpes
73149,LUCEY,73170,LUCEY,,45.7610525561,5.79415214079,149,,Lucey,Lucey,73,Savoie,84,Auvergne-Rhône-Alpes
73150,LA PLAGNE TARENTAISE,73210,LA PLAGNE TARENTAISE,BELLENTRE,45.5224407762,6.69359362189,150,La,Plagne Tarentaise,La Plagne Tarentaise,73,Savoie,84,Auvergne-Rhône-Alpes
73150,LA PLAGNE TARENTAISE,73210,LA PLAGNE TARENTAISE,LA COTE D AIME,45.5224407762,6.69359362189,150,La,Plagne Tarentaise,La Plagne Tarentaise,73,Savoie,84,Auvergne-Rhône-Alpes
73150,LA PLAGNE TARENTAISE,73210,LA PLAGNE TARENTAISE,LA PLAGNE,45.5224407762,6.69359362189,150,La,Plagne Tarentaise,La Plagne Tarentaise,73,Savoie,84,Auvergne-Rhône-Alpes
73150,LA PLAGNE TARENTAISE,73210,LA PLAGNE TARENTAISE,MACOT LA PLAGNE,45.5224407762,6.69359362189,150,La,Plagne Tarentaise,La Plagne Tarentaise,73,Savoie,84,Auvergne-Rhône-Alpes
73150,LA PLAGNE TARENTAISE,73210,LA PLAGNE TARENTAISE,VALEZAN,45.5224407762,6.69359362189,150,La,Plagne Tarentaise,La Plagne Tarentaise,73,Savoie,84,Auvergne-Rhône-Alpes
73151,LES MARCHES,73800,LES MARCHES,,45.4923770493,5.9922950202,151,Les,Marches,Les Marches,73,Savoie,84,Auvergne-Rhône-Alpes
73152,MARCIEUX,73470,MARCIEUX,,45.6148555738,5.77861801494,152,,Marcieux,Marcieux,73,Savoie,84,Auvergne-Rhône-Alpes
73153,MARTHOD,73400,MARTHOD,,45.7320735425,6.39451957192,153,,Marthod,Marthod,73,Savoie,84,Auvergne-Rhône-Alpes
73154,MERCURY,73200,MERCURY,,45.688171193,6.34436944153,154,,Mercury,Mercury,73,Savoie,84,Auvergne-Rhône-Alpes
73155,MERY,73420,MERY,,45.6368213368,5.93473047597,155,,Méry,Méry,73,Savoie,84,Auvergne-Rhône-Alpes
73156,MEYRIEUX TROUET,73170,MEYRIEUX TROUET,,45.6471525763,5.78654601113,156,,Meyrieux-Trouet,Meyrieux-Trouet,73,Savoie,84,Auvergne-Rhône-Alpes
73157,MODANE,73500,MODANE,,45.1746302833,6.64849495084,157,,Modane,Modane,73,Savoie,84,Auvergne-Rhône-Alpes
73159,LES MOLLETTES,73800,LES MOLLETTES,,45.4665131852,6.05865523761,159,Les,Mollettes,Les Mollettes,73,Savoie,84,Auvergne-Rhône-Alpes
73160,MONTAGNOLE,73000,MONTAGNOLE,,45.5238690773,5.91074621005,160,,Montagnole,Montagnole,73,Savoie,84,Auvergne-Rhône-Alpes
73161,MONTAGNY,73350,MONTAGNY,,45.466297654,6.59859826998,161,,Montagny,Montagny,73,Savoie,84,Auvergne-Rhône-Alpes
73162,MONTAILLEUR,73460,MONTAILLEUR,,45.6193039756,6.26617322454,162,,Montailleur,Montailleur,73,Savoie,84,Auvergne-Rhône-Alpes
73163,MONTAIMONT,73130,MONTAIMONT,,45.3743025025,6.36455124986,163,,Montaimont,Montaimont,73,Savoie,84,Auvergne-Rhône-Alpes
73164,MONTCEL,73100,MONTCEL,,45.7093447772,5.9904850832,164,,Montcel,Montcel,73,Savoie,84,Auvergne-Rhône-Alpes
73166,MONTENDRY,73390,MONTENDRY,,45.5226367593,6.23966266966,166,,Montendry,Montendry,73,Savoie,84,Auvergne-Rhône-Alpes
73167,MONTGELLAFREY,73130,MONTGELLAFREY,,45.4147472793,6.33439467637,167,,Montgellafrey,Montgellafrey,73,Savoie,84,Auvergne-Rhône-Alpes
73168,MONTGILBERT,73220,MONTGILBERT,,45.5358137906,6.27545999399,168,,Montgilbert,Montgilbert,73,Savoie,84,Auvergne-Rhône-Alpes
73170,MONTHION,73200,MONTHION,,45.6225222086,6.37284425509,170,,Monthion,Monthion,73,Savoie,84,Auvergne-Rhône-Alpes
73171,MONTMELIAN,73800,MONTMELIAN,,45.5084437218,6.05560369254,171,,Montmélian,Montmélian,73,Savoie,84,Auvergne-Rhône-Alpes
73173,MONTRICHER ALBANNE,73870,MONTRICHER ALBANNE,,45.2148500671,6.40795019067,173,,Montricher-Albanne,Montricher-Albanne,73,Savoie,84,Auvergne-Rhône-Alpes
73173,MONTRICHER ALBANNE,73870,MONTRICHER ALBANNE,LES KARELLIS,45.2148500671,6.40795019067,173,,Montricher-Albanne,Montricher-Albanne,73,Savoie,84,Auvergne-Rhône-Alpes
73175,MONTSAPEY,73220,MONTSAPEY,,45.5314889919,6.36669427224,175,,Montsapey,Montsapey,73,Savoie,84,Auvergne-Rhône-Alpes
73176,MONTVALEZAN,73700,MONTVALEZAN,,45.633431524,6.87462366552,176,,Montvalezan,Montvalezan,73,Savoie,84,Auvergne-Rhône-Alpes
73177,MONTVERNIER,73300,MONTVERNIER,,45.3327756268,6.33500792788,177,,Montvernier,Montvernier,73,Savoie,84,Auvergne-Rhône-Alpes
73178,LA MOTTE EN BAUGES,73340,LA MOTTE EN BAUGES,,45.7116475514,6.1315548442,178,La,Motte-en-Bauges,La Motte-en-Bauges,73,Savoie,84,Auvergne-Rhône-Alpes
73179,LA MOTTE SERVOLEX,73290,LA MOTTE SERVOLEX,,45.6011711771,5.85409653786,179,La,Motte-Servolex,La Motte-Servolex,73,Savoie,84,Auvergne-Rhône-Alpes
73180,MOTZ,73310,MOTZ,,45.9187981352,5.84467711779,180,,Motz,Motz,73,Savoie,84,Auvergne-Rhône-Alpes
73181,MOUTIERS,73600,MOUTIERS TARENTAISE,,45.4857466628,6.53657755978,181,,Moûtiers,Moûtiers,73,Savoie,84,Auvergne-Rhône-Alpes
73182,MOUXY,73100,MOUXY,,45.6795438416,5.94799104168,182,,Mouxy,Mouxy,73,Savoie,84,Auvergne-Rhône-Alpes
73183,MYANS,73800,MYANS,,45.5187852164,5.97843784098,183,,Myans,Myans,73,Savoie,84,Auvergne-Rhône-Alpes
73184,NANCES,73470,NANCES,,45.5854575397,5.80295380926,184,,Nances,Nances,73,Savoie,84,Auvergne-Rhône-Alpes
73186,NOTRE DAME DE BELLECOMBE,73590,NOTRE DAME DE BELLECOMBE,,45.7951588236,6.55684529691,186,,Notre-Dame-de-Bellecombe,Notre-Dame-de-Bellecombe,73,Savoie,84,Auvergne-Rhône-Alpes
73187,LA LECHERE,73260,LA LECHERE,,45.5274218608,6.46078348062,187,La,Léchère,La Léchère,73,Savoie,84,Auvergne-Rhône-Alpes
73187,LA LECHERE,73260,LA LECHERE,CELLIERS,45.5274218608,6.46078348062,187,La,Léchère,La Léchère,73,Savoie,84,Auvergne-Rhône-Alpes
73187,LA LECHERE,73260,LA LECHERE,DOUCY,45.5274218608,6.46078348062,187,La,Léchère,La Léchère,73,Savoie,84,Auvergne-Rhône-Alpes
73187,LA LECHERE,73260,LA LECHERE,NAVES,45.5274218608,6.46078348062,187,La,Léchère,La Léchère,73,Savoie,84,Auvergne-Rhône-Alpes
73187,LA LECHERE,73260,LA LECHERE,PETIT COEUR,45.5274218608,6.46078348062,187,La,Léchère,La Léchère,73,Savoie,84,Auvergne-Rhône-Alpes
73187,LA LECHERE,73260,LA LECHERE,PUSSY,45.5274218608,6.46078348062,187,La,Léchère,La Léchère,73,Savoie,84,Auvergne-Rhône-Alpes
73188,NOTRE DAME DES MILLIERES,73460,NOTRE DAME DES MILLIERES,,45.6133297459,6.35632585882,188,,Notre-Dame-des-Millières,Notre-Dame-des-Millières,73,Savoie,84,Auvergne-Rhône-Alpes
73189,NOTRE DAME DU CRUET,73130,NOTRE DAME DU CRUET,,45.3752065363,6.3094948961,189,,Notre-Dame-du-Cruet,Notre-Dame-du-Cruet,73,Savoie,84,Auvergne-Rhône-Alpes
73190,NOTRE DAME DU PRE,73600,NOTRE DAME DU PRE,,45.5031164801,6.60523246896,190,,Notre-Dame-du-Pré,Notre-Dame-du-Pré,73,Savoie,84,Auvergne-Rhône-Alpes
73191,NOVALAISE,73470,NOVALAISE,,45.5883066651,5.77193430721,191,,Novalaise,Novalaise,73,Savoie,84,Auvergne-Rhône-Alpes
73192,LE NOYER,73340,LE NOYER,,45.6672501537,6.05542044441,192,Le,Noyer,Le Noyer,73,Savoie,84,Auvergne-Rhône-Alpes
73193,ONTEX,73310,ONTEX,,45.7490416401,5.82274502837,193,,Ontex,Ontex,73,Savoie,84,Auvergne-Rhône-Alpes
73194,ORELLE,73140,ORELLE,,45.1983416758,6.56055750586,194,,Orelle,Orelle,73,Savoie,84,Auvergne-Rhône-Alpes
73196,PALLUD,73200,PALLUD,,45.6932959601,6.38998049719,196,,Pallud,Pallud,73,Savoie,84,Auvergne-Rhône-Alpes
73197,PEISEY NANCROIX,73210,PEISEY NANCROIX,,45.5103194255,6.81520574462,197,,Peisey-Nancroix,Peisey-Nancroix,73,Savoie,84,Auvergne-Rhône-Alpes
73198,LA PERRIERE,73120,LA PERRIERE,,45.4297146113,6.59322071579,198,La,Perrière,La Perrière,73,Savoie,84,Auvergne-Rhône-Alpes
73198,LA PERRIERE,73600,LA PERRIERE,,45.4297146113,6.59322071579,198,La,Perrière,La Perrière,73,Savoie,84,Auvergne-Rhône-Alpes
73200,PLANAISE,73800,PLANAISE,,45.5071148069,6.09359995038,200,,Planaise,Planaise,73,Savoie,84,Auvergne-Rhône-Alpes
73201,PLANAY,73350,PLANAY,,45.4227466378,6.71458583234,201,,Planay,Planay,73,Savoie,84,Auvergne-Rhône-Alpes
73202,PLANCHERINE,73200,PLANCHERINE,,45.6784892181,6.2941466119,202,,Plancherine,Plancherine,73,Savoie,84,Auvergne-Rhône-Alpes
73203,PONTAMAFREY MONTPASCAL,73300,PONTAMAFREY MONTPASCAL,,45.3275110948,6.34849709837,203,,Pontamafrey-Montpascal,Pontamafrey-Montpascal,73,Savoie,84,Auvergne-Rhône-Alpes
73203,PONTAMAFREY MONTPASCAL,73300,PONTAMAFREY MONTPASCAL,MONTPASCAL,45.3275110948,6.34849709837,203,,Pontamafrey-Montpascal,Pontamafrey-Montpascal,73,Savoie,84,Auvergne-Rhône-Alpes
73204,LE PONT DE BEAUVOISIN,73330,LE PONT DE BEAUVOISIN,,45.5356435604,5.68142349925,204,Le,Pont-de-Beauvoisin,Le Pont-de-Beauvoisin,73,Savoie,84,Auvergne-Rhône-Alpes
73205,LE PONTET,73110,LE PONTET,,45.497081703,6.23653163392,205,Le,Pontet,Le Pontet,73,Savoie,84,Auvergne-Rhône-Alpes
73206,PRALOGNAN LA VANOISE,73710,PRALOGNAN LA VANOISE,,45.3498188353,6.72039060964,206,,Pralognan-la-Vanoise,Pralognan-la-Vanoise,73,Savoie,84,Auvergne-Rhône-Alpes
73207,PRESLE,73110,PRESLE,,45.4415501506,6.16915940279,207,,Presle,Presle,73,Savoie,84,Auvergne-Rhône-Alpes
73208,PUGNY CHATENOD,73100,PUGNY CHATENOD,,45.6923319762,5.95516143969,208,,Pugny-Chatenod,Pugny-Chatenod,73,Savoie,84,Auvergne-Rhône-Alpes
73210,PUYGROS,73190,PUYGROS,,45.5690168338,6.04062707195,210,,Puygros,Puygros,73,Savoie,84,Auvergne-Rhône-Alpes
73211,QUEIGE,73720,QUEIGE,,45.7071867017,6.46543980324,211,,Queige,Queige,73,Savoie,84,Auvergne-Rhône-Alpes
73212,RANDENS,73220,RANDENS,,45.5440801099,6.32788266521,212,,Randens,Randens,73,Savoie,84,Auvergne-Rhône-Alpes
73213,LA RAVOIRE,73490,LA RAVOIRE,,45.5561328197,5.9602608047,213,La,Ravoire,La Ravoire,73,Savoie,84,Auvergne-Rhône-Alpes
73214,ROCHEFORT,73240,ROCHEFORT,,45.5807306821,5.71351336836,214,,Rochefort,Rochefort,73,Savoie,84,Auvergne-Rhône-Alpes
73215,LA ROCHETTE,73110,LA ROCHETTE,,45.4592334902,6.11216246274,215,La,Rochette,La Rochette,73,Savoie,84,Auvergne-Rhône-Alpes
73216,ROGNAIX,73730,ROGNAIX,,45.5705817374,6.42314451894,216,,Rognaix,Rognaix,73,Savoie,84,Auvergne-Rhône-Alpes
73217,ROTHERENS,73110,ROTHERENS,,45.472199256,6.13731657303,217,,Rotherens,Rotherens,73,Savoie,84,Auvergne-Rhône-Alpes
73218,RUFFIEUX,73310,RUFFIEUX,,45.8514912091,5.84328531384,218,,Ruffieux,Ruffieux,73,Savoie,84,Auvergne-Rhône-Alpes
73219,ST ALBAN DE MONTBEL,73610,ST ALBAN DE MONTBEL,,45.5515817863,5.77939233212,219,,Saint-Alban-de-Montbel,Saint-Alban-de-Montbel,73,Savoie,84,Auvergne-Rhône-Alpes
73220,ST ALBAN D HURTIERES,73220,ST ALBAN D HURTIERES,,45.4684794014,6.24910265774,220,,Saint-Alban-d'Hurtières,Saint-Alban-d'Hurtières,73,Savoie,84,Auvergne-Rhône-Alpes
73221,ST ALBAN DES VILLARDS,73130,ST ALBAN DES VILLARDS,,45.314656637,6.23573224312,221,,Saint-Alban-des-Villards,Saint-Alban-des-Villards,73,Savoie,84,Auvergne-Rhône-Alpes
73222,ST ALBAN LEYSSE,73230,ST ALBAN LEYSSE,,45.5881109422,5.95790977715,222,,Saint-Alban-Leysse,Saint-Alban-Leysse,73,Savoie,84,Auvergne-Rhône-Alpes
73223,ST ANDRE,73500,ST ANDRE,,45.2241143172,6.61633706922,223,,Saint-André,Saint-André,73,Savoie,84,Auvergne-Rhône-Alpes
73224,ST AVRE,73130,ST AVRE,,45.3494291274,6.3154898666,224,,Saint-Avre,Saint-Avre,73,Savoie,84,Auvergne-Rhône-Alpes
73225,ST BALDOPH,73190,ST BALDOPH,,45.529906626,5.94584830837,225,,Saint-Baldoph,Saint-Baldoph,73,Savoie,84,Auvergne-Rhône-Alpes
73226,ST BERON,73520,ST BERON,,45.5067164494,5.72513692798,226,,Saint-Béron,Saint-Béron,73,Savoie,84,Auvergne-Rhône-Alpes
73227,ST BON TARENTAISE,73120,ST BON TARENTAISE,,45.389507146,6.64742719024,227,,Courchevel,Courchevel,73,Savoie,84,Auvergne-Rhône-Alpes
73227,ST BON TARENTAISE,73120,ST BON TARENTAISE,COURCHEVEL,45.389507146,6.64742719024,227,,Courchevel,Courchevel,73,Savoie,84,Auvergne-Rhône-Alpes
73228,ST CASSIN,73160,ST CASSIN,,45.5211376185,5.87930172549,228,,Saint-Cassin,Saint-Cassin,73,Savoie,84,Auvergne-Rhône-Alpes
73229,ST CHRISTOPHE,73360,ST CHRISTOPHE,,45.4517904868,5.78599778512,229,,Saint-Christophe,Saint-Christophe,73,Savoie,84,Auvergne-Rhône-Alpes
73230,ST COLOMBAN DES VILLARDS,73130,ST COLOMBAN DES VILLARDS,,45.2665810428,6.18303706085,230,,Saint-Colomban-des-Villards,Saint-Colomban-des-Villards,73,Savoie,84,Auvergne-Rhône-Alpes
73231,ST ETIENNE DE CUINES,73130,ST ETIENNE DE CUINES,,45.3456004794,6.25994599551,231,,Saint-Étienne-de-Cuines,Saint-Étienne-de-Cuines,73,Savoie,84,Auvergne-Rhône-Alpes
73232,STE FOY TARENTAISE,73640,STE FOY TARENTAISE,,45.5842289817,6.94134524499,232,,Sainte-Foy-Tarentaise,Sainte-Foy-Tarentaise,73,Savoie,84,Auvergne-Rhône-Alpes
73233,ST FRANC,73360,ST FRANC,,45.4897936377,5.74256959664,233,,Saint-Franc,Saint-Franc,73,Savoie,84,Auvergne-Rhône-Alpes
73234,ST FRANCOIS DE SALES,73340,ST FRANCOIS DE SALES,,45.6827462636,6.03682788265,234,,Saint-François-de-Sales,Saint-François-de-Sales,73,Savoie,84,Auvergne-Rhône-Alpes
73235,ST FRANCOIS LONGCHAMP,73130,ST FRANCOIS LONGCHAMP,,45.4121018734,6.37380621288,235,,Saint François Longchamp,Saint François Longchamp,73,Savoie,84,Auvergne-Rhône-Alpes
73236,ST GENIX SUR GUIERS,73240,ST GENIX SUR GUIERS,,45.5958143287,5.65587988646,236,,Saint-Genix-sur-Guiers,Saint-Genix-sur-Guiers,73,Savoie,84,Auvergne-Rhône-Alpes
73237,ST GEORGES D HURTIERES,73220,ST GEORGES D HURTIERES,,45.5076481725,6.28509753984,237,,Saint-Georges-d'Hurtières,Saint-Georges-d'Hurtières,73,Savoie,84,Auvergne-Rhône-Alpes
73240,STE HELENE DU LAC,73800,STE HELENE DU LAC,,45.4796430592,6.05492059443,240,,Sainte-Hélène-du-Lac,Sainte-Hélène-du-Lac,73,Savoie,84,Auvergne-Rhône-Alpes
73241,STE HELENE SUR ISERE,73460,STE HELENE SUR ISERE,,45.6022927013,6.32652357893,241,,Sainte-Hélène-sur-Isère,Sainte-Hélène-sur-Isère,73,Savoie,84,Auvergne-Rhône-Alpes
73242,ST JEAN D ARVES,73530,ST JEAN D ARVES,,45.1659803652,6.27215759113,242,,Saint-Jean-d'Arves,Saint-Jean-d'Arves,73,Savoie,84,Auvergne-Rhône-Alpes
73243,ST JEAN D ARVEY,73230,ST JEAN D ARVEY,,45.5971514646,5.99104015513,243,,Saint-Jean-d'Arvey,Saint-Jean-d'Arvey,73,Savoie,84,Auvergne-Rhône-Alpes
73244,ST JEAN DE BELLEVILLE,73440,ST JEAN DE BELLEVILLE,,45.4054371324,6.44373760983,244,,Saint-Jean-de-Belleville,Saint-Jean-de-Belleville,73,Savoie,84,Auvergne-Rhône-Alpes
73245,ST JEAN DE CHEVELU,73170,ST JEAN DE CHEVELU,,45.6954877529,5.82649490929,245,,Saint-Jean-de-Chevelu,Saint-Jean-de-Chevelu,73,Savoie,84,Auvergne-Rhône-Alpes
73246,ST JEAN DE COUZ,73160,ST JEAN DE COUZ,,45.4651808602,5.81381473016,246,,Saint-Jean-de-Couz,Saint-Jean-de-Couz,73,Savoie,84,Auvergne-Rhône-Alpes
73247,ST JEAN DE LA PORTE,73250,ST JEAN DE LA PORTE,,45.5593907603,6.11977721306,247,,Saint-Jean-de-la-Porte,Saint-Jean-de-la-Porte,73,Savoie,84,Auvergne-Rhône-Alpes
73248,ST JEAN DE MAURIENNE,73300,ST JEAN DE MAURIENNE,,45.2713738871,6.34553663978,248,,Saint-Jean-de-Maurienne,Saint-Jean-de-Maurienne,73,Savoie,84,Auvergne-Rhône-Alpes
73249,ST JEOIRE PRIEURE,73190,ST JEOIRE PRIEURE,,45.5330568499,5.9893051936,249,,Saint-Jeoire-Prieuré,Saint-Jeoire-Prieuré,73,Savoie,84,Auvergne-Rhône-Alpes
73250,ST JULIEN MONT DENIS,73870,ST JULIEN MONT DENIS,,45.2803881732,6.41769086937,250,,Saint-Julien-Mont-Denis,Saint-Julien-Mont-Denis,73,Savoie,84,Auvergne-Rhône-Alpes
73252,ST LEGER,73220,ST LEGER,,45.4399186872,6.26351735583,252,,Saint-Léger,Saint-Léger,73,Savoie,84,Auvergne-Rhône-Alpes
73253,ST MARCEL,73600,ST MARCEL,,45.4979534787,6.56606269839,253,,Saint-Marcel,Saint-Marcel,73,Savoie,84,Auvergne-Rhône-Alpes
73254,STE MARIE D ALVEY,73240,STE MARIE D ALVEY,,45.5945348192,5.71473062116,254,,Sainte-Marie-d'Alvey,Sainte-Marie-d'Alvey,73,Savoie,84,Auvergne-Rhône-Alpes
73255,STE MARIE DE CUINES,73130,STE MARIE DE CUINES,,45.3192133197,6.29393151953,255,,Sainte-Marie-de-Cuines,Sainte-Marie-de-Cuines,73,Savoie,84,Auvergne-Rhône-Alpes
73256,ST MARTIN D ARC,73140,ST MARTIN D ARC,,45.206861554,6.45941152477,256,,Saint-Martin-d'Arc,Saint-Martin-d'Arc,73,Savoie,84,Auvergne-Rhône-Alpes
73257,LES BELLEVILLE,73440,LES BELLEVILLE,LES MENUIRES,45.334431769,6.51686943075,257,Les,Belleville,Les Belleville,73,Savoie,84,Auvergne-Rhône-Alpes
73257,LES BELLEVILLE,73440,LES BELLEVILLE,ST MARTIN DE BELLEVILLE,45.334431769,6.51686943075,257,Les,Belleville,Les Belleville,73,Savoie,84,Auvergne-Rhône-Alpes
73257,LES BELLEVILLE,73440,LES BELLEVILLE,VAL THORENS,45.334431769,6.51686943075,257,Les,Belleville,Les Belleville,73,Savoie,84,Auvergne-Rhône-Alpes
73257,LES BELLEVILLE,73600,LES BELLEVILLE,VILLARLURIN,45.334431769,6.51686943075,257,Les,Belleville,Les Belleville,73,Savoie,84,Auvergne-Rhône-Alpes
73258,ST MARTIN DE LA PORTE,73140,ST MARTIN DE LA PORTE,,45.2637926068,6.45954136522,258,,Saint-Martin-de-la-Porte,Saint-Martin-de-la-Porte,73,Savoie,84,Auvergne-Rhône-Alpes
73259,ST MARTIN SUR LA CHAMBRE,73130,ST MARTIN SUR LA CHAMBRE,,45.3722651224,6.32262500896,259,,Saint-Martin-sur-la-Chambre,Saint-Martin-sur-la-Chambre,73,Savoie,84,Auvergne-Rhône-Alpes
73260,ST MAURICE DE ROTHERENS,73240,ST MAURICE DE ROTHERENS,,45.6217369154,5.7020587596,260,,Saint-Maurice-de-Rotherens,Saint-Maurice-de-Rotherens,73,Savoie,84,Auvergne-Rhône-Alpes
73261,ST MICHEL DE MAURIENNE,73140,ST MICHEL DE MAURIENNE,,45.2382472621,6.49342053727,261,,Saint-Michel-de-Maurienne,Saint-Michel-de-Maurienne,73,Savoie,84,Auvergne-Rhône-Alpes
73261,ST MICHEL DE MAURIENNE,73140,ST MICHEL DE MAURIENNE,BEAUNE,45.2382472621,6.49342053727,261,,Saint-Michel-de-Maurienne,Saint-Michel-de-Maurienne,73,Savoie,84,Auvergne-Rhône-Alpes
73261,ST MICHEL DE MAURIENNE,73140,ST MICHEL DE MAURIENNE,THYL,45.2382472621,6.49342053727,261,,Saint-Michel-de-Maurienne,Saint-Michel-de-Maurienne,73,Savoie,84,Auvergne-Rhône-Alpes
73262,ST NICOLAS LA CHAPELLE,73590,ST NICOLAS LA CHAPELLE,,45.8244727797,6.47927364341,262,,Saint-Nicolas-la-Chapelle,Saint-Nicolas-la-Chapelle,73,Savoie,84,Auvergne-Rhône-Alpes
73263,ST OFFENGE,73100,ST OFFENGE,,45.7387543881,6.01018266121,263,,Saint-Offenge,Saint-Offenge,73,Savoie,84,Auvergne-Rhône-Alpes
73263,ST OFFENGE,73100,ST OFFENGE,ST OFFENGE DESSUS,45.7387543881,6.01018266121,263,,Saint-Offenge,Saint-Offenge,73,Savoie,84,Auvergne-Rhône-Alpes
73265,ST OURS,73410,ST OURS,,45.7555682291,5.98099167623,265,,Saint-Ours,Saint-Ours,73,Savoie,84,Auvergne-Rhône-Alpes
73266,ST OYEN,73260,ST OYEN,,45.5085525876,6.47859475576,266,,Saint-Oyen,Saint-Oyen,73,Savoie,84,Auvergne-Rhône-Alpes
73267,ST PANCRACE,73300,ST PANCRACE,,45.2661323144,6.30078779644,267,,Saint-Pancrace,Saint-Pancrace,73,Savoie,84,Auvergne-Rhône-Alpes
73268,ST PAUL SUR ISERE,73730,ST PAUL SUR ISERE,,45.5869203358,6.40696654722,268,,Saint-Paul-sur-Isère,Saint-Paul-sur-Isère,73,Savoie,84,Auvergne-Rhône-Alpes
73269,ST PAUL,73170,ST PAUL,,45.6725948958,5.79201634508,269,,Saint-Paul,Saint-Paul,73,Savoie,84,Auvergne-Rhône-Alpes
73270,ST PIERRE D ALBIGNY,73250,ST PIERRE D ALBIGNY,,45.5763259398,6.1598571367,270,,Saint-Pierre-d'Albigny,Saint-Pierre-d'Albigny,73,Savoie,84,Auvergne-Rhône-Alpes
73271,ST PIERRE D ALVEY,73170,ST PIERRE D ALVEY,,45.6259001138,5.73939754416,271,,Saint-Pierre-d'Alvey,Saint-Pierre-d'Alvey,73,Savoie,84,Auvergne-Rhône-Alpes
73272,ST PIERRE DE BELLEVILLE,73220,ST PIERRE DE BELLEVILLE,,45.4537564041,6.25884593711,272,,Saint-Pierre-de-Belleville,Saint-Pierre-de-Belleville,73,Savoie,84,Auvergne-Rhône-Alpes
73273,ST PIERRE DE CURTILLE,73310,ST PIERRE DE CURTILLE,,45.7654977445,5.82887775721,273,,Saint-Pierre-de-Curtille,Saint-Pierre-de-Curtille,73,Savoie,84,Auvergne-Rhône-Alpes
73274,ST PIERRE D ENTREMONT,73670,ST PIERRE D ENTREMONT,,45.4183046433,5.87969833573,274,,Saint-Pierre-d'Entremont,Saint-Pierre-d'Entremont,73,Savoie,84,Auvergne-Rhône-Alpes
73275,ST PIERRE DE GENEBROZ,73360,ST PIERRE DE GENEBROZ,,45.461398048,5.75910376857,275,,Saint-Pierre-de-Genebroz,Saint-Pierre-de-Genebroz,73,Savoie,84,Auvergne-Rhône-Alpes
73276,ST PIERRE DE SOUCY,73800,ST PIERRE DE SOUCY,,45.4878241421,6.10513680462,276,,Saint-Pierre-de-Soucy,Saint-Pierre-de-Soucy,73,Savoie,84,Auvergne-Rhône-Alpes
73277,STE REINE,73630,STE REINE,,45.6071367127,6.14343350447,277,,Sainte-Reine,Sainte-Reine,73,Savoie,84,Auvergne-Rhône-Alpes
73278,ST REMY DE MAURIENNE,73660,ST REMY DE MAURIENNE,,45.3879084612,6.23781210409,278,,Saint-Rémy-de-Maurienne,Saint-Rémy-de-Maurienne,73,Savoie,84,Auvergne-Rhône-Alpes
73280,ST SORLIN D ARVES,73530,ST SORLIN D ARVES,,45.2010658466,6.20188332139,280,,Saint-Sorlin-d'Arves,Saint-Sorlin-d'Arves,73,Savoie,84,Auvergne-Rhône-Alpes
73281,ST SULPICE,73160,ST SULPICE,,45.5644614056,5.84464359287,281,,Saint-Sulpice,Saint-Sulpice,73,Savoie,84,Auvergne-Rhône-Alpes
73282,ST THIBAUD DE COUZ,73160,ST THIBAUD DE COUZ,,45.4944443083,5.8322579118,282,,Saint-Thibaud-de-Couz,Saint-Thibaud-de-Couz,73,Savoie,84,Auvergne-Rhône-Alpes
73283,ST VITAL,73460,ST VITAL,,45.6265051509,6.30021225205,283,,Saint-Vital,Saint-Vital,73,Savoie,84,Auvergne-Rhône-Alpes
73284,SALINS FONTAINE,73600,SALINS FONTAINE,,45.4717964236,6.5301880554,284,,Salins-Fontaine,Salins-Fontaine,73,Savoie,84,Auvergne-Rhône-Alpes
73284,SALINS FONTAINE,73600,SALINS FONTAINE,FONTAINE LE PUITS,45.4717964236,6.5301880554,284,,Salins-Fontaine,Salins-Fontaine,73,Savoie,84,Auvergne-Rhône-Alpes
73285,SEEZ,73700,SEEZ,,45.6478054905,6.83575624707,285,,Séez,Séez,73,Savoie,84,Auvergne-Rhône-Alpes
73286,SERRIERES EN CHAUTAGNE,73310,SERRIERES EN CHAUTAGNE,,45.8813588205,5.84299329456,286,,Serrières-en-Chautagne,Serrières-en-Chautagne,73,Savoie,84,Auvergne-Rhône-Alpes
73287,SOLLIERES SARDIERES,73500,SOLLIERES SARDIERES,,45.24940562,6.81522776187,287,,Sollières-Sardières,Sollières-Sardières,73,Savoie,84,Auvergne-Rhône-Alpes
73288,SONNAZ,73000,SONNAZ,,45.6194864843,5.9172882002,288,,Sonnaz,Sonnaz,73,Savoie,84,Auvergne-Rhône-Alpes
73289,LA TABLE,73110,LA TABLE,,45.4723580933,6.1858039721,289,La,Table,La Table,73,Savoie,84,Auvergne-Rhône-Alpes
73290,TERMIGNON,73500,TERMIGNON,,45.3441528771,6.84789108583,290,,Val-Cenis,Val-Cenis,73,Savoie,84,Auvergne-Rhône-Alpes
73292,THENESOL,73200,THENESOL,,45.7176908375,6.38685541448,292,,Thénésol,Thénésol,73,Savoie,84,Auvergne-Rhône-Alpes
73293,THOIRY,73230,THOIRY,,45.598757543,6.04432776786,293,,Thoiry,Thoiry,73,Savoie,84,Auvergne-Rhône-Alpes
73294,LA THUILE,73190,LA THUILE,,45.5411277872,6.05633197484,294,La,Thuile,La Thuile,73,Savoie,84,Auvergne-Rhône-Alpes
73296,TIGNES,73320,TIGNES,,45.4816828491,6.93635513554,296,,Tignes,Tignes,73,Savoie,84,Auvergne-Rhône-Alpes
73297,TOURNON,73460,TOURNON,,45.637794827,6.32739261169,297,,Tournon,Tournon,73,Savoie,84,Auvergne-Rhône-Alpes
73298,TOURS EN SAVOIE,73790,TOURS EN SAVOIE,,45.6663871792,6.46157169693,298,,Tours-en-Savoie,Tours-en-Savoie,73,Savoie,84,Auvergne-Rhône-Alpes
73299,TRAIZE,73170,TRAIZE,,45.6716573286,5.73696272182,299,,Traize,Traize,73,Savoie,84,Auvergne-Rhône-Alpes
73300,TRESSERVE,73100,TRESSERVE,,45.6743249574,5.88968226158,300,,Tresserve,Tresserve,73,Savoie,84,Auvergne-Rhône-Alpes
73301,TREVIGNIN,73100,TREVIGNIN,,45.7047065922,5.96859856205,301,,Trévignin,Trévignin,73,Savoie,84,Auvergne-Rhône-Alpes
73302,LA TRINITE,73110,LA TRINITE,,45.5038702551,6.15205404986,302,La,Trinité,La Trinité,73,Savoie,84,Auvergne-Rhône-Alpes
73303,UGINE,73400,UGINE,,45.7718876928,6.43266345275,303,,Ugine,Ugine,73,Savoie,84,Auvergne-Rhône-Alpes
73304,VAL D ISERE,73150,VAL D ISERE,,45.4310113935,6.99852444032,304,,Val-d'Isère,Val-d'Isère,73,Savoie,84,Auvergne-Rhône-Alpes
73306,VALLOIRE,73450,VALLOIRE,,45.1233680911,6.42400790634,306,,Valloire,Valloire,73,Savoie,84,Auvergne-Rhône-Alpes
73307,VALMEINIER,73450,VALMEINIER,,45.152061815,6.50820802644,307,,Valmeinier,Valmeinier,73,Savoie,84,Auvergne-Rhône-Alpes
73308,VENTHON,73200,VENTHON,,45.6870981041,6.41810661947,308,,Venthon,Venthon,73,Savoie,84,Auvergne-Rhône-Alpes
73309,VEREL DE MONTBEL,73330,VEREL DE MONTBEL,,45.5537409191,5.72394607182,309,,Verel-de-Montbel,Verel-de-Montbel,73,Savoie,84,Auvergne-Rhône-Alpes
73310,VEREL PRAGONDRAN,73230,VEREL PRAGONDRAN,,45.6213269536,5.95297759397,310,,Verel-Pragondran,Verel-Pragondran,73,Savoie,84,Auvergne-Rhône-Alpes
73311,LE VERNEIL,73110,LE VERNEIL,,45.450825812,6.18533015026,311,Le,Verneil,Le Verneil,73,Savoie,84,Auvergne-Rhône-Alpes
73312,VERRENS ARVEY,73460,VERRENS ARVEY,,45.6626779644,6.29022565576,312,,Verrens-Arvey,Verrens-Arvey,73,Savoie,84,Auvergne-Rhône-Alpes
73313,VERTHEMEX,73170,VERTHEMEX,,45.6285977197,5.79833212691,313,,Verthemex,Verthemex,73,Savoie,84,Auvergne-Rhône-Alpes
73314,VILLARD D HERY,73800,VILLARD D HERY,,45.5142759171,6.1391908576,314,,Villard-d'Héry,Villard-d'Héry,73,Savoie,84,Auvergne-Rhône-Alpes
73315,VILLARD LEGER,73390,VILLARD LEGER,,45.501239323,6.17384945736,315,,Villard-Léger,Villard-Léger,73,Savoie,84,Auvergne-Rhône-Alpes
73316,VILLARD SALLET,73110,VILLARD SALLET,,45.4865020097,6.13474545432,316,,Villard-Sallet,Villard-Sallet,73,Savoie,84,Auvergne-Rhône-Alpes
73317,VILLARD SUR DORON,73270,VILLARD SUR DORON,,45.727682272,6.51866371768,317,,Villard-sur-Doron,Villard-sur-Doron,73,Savoie,84,Auvergne-Rhône-Alpes
73318,VILLAREMBERT,73300,VILLAREMBERT,,45.2399528685,6.2643037706,318,,Villarembert,Villarembert,73,Savoie,84,Auvergne-Rhône-Alpes
73318,VILLAREMBERT,73300,VILLAREMBERT,LE CORBIER,45.2399528685,6.2643037706,318,,Villarembert,Villarembert,73,Savoie,84,Auvergne-Rhône-Alpes
73320,VILLARGONDRAN,73300,VILLARGONDRAN,,45.2527360599,6.37903424342,320,,Villargondran,Villargondran,73,Savoie,84,Auvergne-Rhône-Alpes
73322,VILLARODIN BOURGET,73500,VILLARODIN BOURGET,,45.2189045152,6.69172081742,322,,Villarodin-Bourget,Villarodin-Bourget,73,Savoie,84,Auvergne-Rhône-Alpes
73323,VILLAROGER,73640,VILLAROGER,,45.5575226371,6.86910255537,323,,Villaroger,Villaroger,73,Savoie,84,Auvergne-Rhône-Alpes
73324,VILLAROUX,73110,VILLAROUX,,45.4620342064,6.0793012764,324,,Villaroux,Villaroux,73,Savoie,84,Auvergne-Rhône-Alpes
73326,VIMINES,73160,VIMINES,,45.5387653179,5.85041519179,326,,Vimines,Vimines,73,Savoie,84,Auvergne-Rhône-Alpes
73327,VIONS,73310,VIONS,,45.8310499663,5.80820031597,327,,Vions,Vions,73,Savoie,84,Auvergne-Rhône-Alpes
73328,VIVIERS DU LAC,73420,VIVIERS DU LAC,,45.6502423808,5.89790868543,328,,Viviers-du-Lac,Viviers-du-Lac,73,Savoie,84,Auvergne-Rhône-Alpes
73329,VOGLANS,73420,VOGLANS,,45.6271741926,5.89111432564,329,,Voglans,Voglans,73,Savoie,84,Auvergne-Rhône-Alpes
73330,YENNE,73170,YENNE,,45.7020470491,5.7646557504,330,,Yenne,Yenne,73,Savoie,84,Auvergne-Rhône-Alpes
74001,ABONDANCE,74360,ABONDANCE,,46.2661080816,6.7321620538,1,,Abondance,Abondance,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74002,ALBY SUR CHERAN,74540,ALBY SUR CHERAN,,45.8156435779,6.01581486858,2,,Alby-sur-Chéran,Alby-sur-Chéran,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74003,ALEX,74290,ALEX,,45.8806920223,6.23633769093,3,,Alex,Alex,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74004,ALLEVES,74540,ALLEVES,,45.7588655872,6.08634740225,4,,Allèves,Allèves,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74005,ALLINGES,74200,ALLINGES,,46.333709028,6.46512142751,5,,Allinges,Allinges,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74006,ALLONZIER LA CAILLE,74350,ALLONZIER LA CAILLE,,46.000527699,6.10482048689,6,,Allonzier-la-Caille,Allonzier-la-Caille,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74006,ALLONZIER LA CAILLE,74350,ALLONZIER LA CAILLE,AVREGNY,46.000527699,6.10482048689,6,,Allonzier-la-Caille,Allonzier-la-Caille,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74007,AMANCY,74800,AMANCY,,46.0728257803,6.33057376854,7,,Amancy,Amancy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74008,AMBILLY,74100,AMBILLY,,46.1961567042,6.22429975401,8,,Ambilly,Ambilly,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74009,ANDILLY,74350,ANDILLY,,46.0702115695,6.0710017836,9,,Andilly,Andilly,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74009,ANDILLY,74350,ANDILLY,JUSSY,46.0702115695,6.0710017836,9,,Andilly,Andilly,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74010,ANNECY,74000,ANNECY,,45.8906432566,6.12551773598,10,,Annecy,Annecy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74011,ANNECY LE VIEUX,74940,ANNECY LE VIEUX,,45.9193395896,6.16076240491,11,,Annecy-le-Vieux,Annecy-le-Vieux,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74012,ANNEMASSE,74100,ANNEMASSE,,46.1909730986,6.24250704322,12,,Annemasse,Annemasse,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74013,ANTHY SUR LEMAN,74200,ANTHY SUR LEMAN,,46.3530564511,6.42748586139,13,,Anthy-sur-Léman,Anthy-sur-Léman,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74014,ARACHES LA FRASSE,74300,ARACHES LA FRASSE,,46.0302694516,6.66062068121,14,,Arâches-la-Frasse,Arâches-la-Frasse,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74014,ARACHES LA FRASSE,74300,ARACHES LA FRASSE,LA FRASSE,46.0302694516,6.66062068121,14,,Arâches-la-Frasse,Arâches-la-Frasse,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74014,ARACHES LA FRASSE,74300,ARACHES LA FRASSE,LES CARROZ D ARACHES,46.0302694516,6.66062068121,14,,Arâches-la-Frasse,Arâches-la-Frasse,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74015,ARBUSIGNY,74930,ARBUSIGNY,,46.086240469,6.20734603818,15,,Arbusigny,Arbusigny,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74016,ARCHAMPS,74160,ARCHAMPS,,46.1222282115,6.13971386615,16,,Archamps,Archamps,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74018,ARENTHON,74800,ARENTHON,,46.0981343308,6.3386699894,18,,Arenthon,Arenthon,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74019,ARGONAY,74370,ARGONAY,,45.9472669174,6.14837081563,19,,Argonay,Argonay,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74020,ARMOY,74200,ARMOY,,46.3523619359,6.51159949942,20,,Armoy,Armoy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74021,ARTHAZ PONT NOTRE DAME,74380,ARTHAZ PONT NOTRE DAME,,46.1606681382,6.26878505458,21,,Arthaz-Pont-Notre-Dame,Arthaz-Pont-Notre-Dame,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74022,AVIERNOZ,74570,AVIERNOZ,,45.9764432094,6.2368970464,22,,Aviernoz,Aviernoz,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74024,AYSE,74130,AYSE,,46.0869924496,6.44601645014,24,,Ayse,Ayse,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74025,BALLAISON,74140,BALLAISON,,46.2932587435,6.34400093687,25,,Ballaison,Ballaison,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74026,LA BALME DE SILLINGY,74330,LA BALME DE SILLINGY,,45.9698450968,6.04498278395,26,La,Balme-de-Sillingy,La Balme-de-Sillingy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74027,LA BALME DE THUY,74230,LA BALME DE THUY,,45.910029772,6.28546229311,27,La,Balme-de-Thuy,La Balme-de-Thuy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74029,BASSY,74910,BASSY,,45.9914416036,5.82815626577,29,,Bassy,Bassy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74030,LA BAUME,74430,LA BAUME,,46.2836072136,6.60860693452,30,La,Baume,La Baume,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74031,BEAUMONT,74160,BEAUMONT,,46.0976635697,6.12074518078,31,,Beaumont,Beaumont,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74032,BELLEVAUX,74470,BELLEVAUX,,46.2276019394,6.55285416418,32,,Bellevaux,Bellevaux,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74033,BERNEX,74500,BERNEX,,46.3566554818,6.69898825662,33,,Bernex,Bernex,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74034,LE BIOT,74430,LE BIOT,,46.2728593658,6.64024071432,34,Le,Biot,Le Biot,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74035,BLOYE,74150,BLOYE,,45.8204275493,5.94977872948,35,,Bloye,Bloye,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74036,BLUFFY,74290,BLUFFY,,45.8655021029,6.22227398882,36,,Bluffy,Bluffy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74037,BOEGE,74420,BOEGE,,46.2188768633,6.38530552261,37,,Boëge,Boëge,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74038,BOGEVE,74250,BOGEVE,,46.194241107,6.43929186038,38,,Bogève,Bogève,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74040,BONNE,74380,BONNE,,46.1718364348,6.31931310923,40,,Bonne,Bonne,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74040,BONNE,74380,BONNE,LOEX,46.1718364348,6.31931310923,40,,Bonne,Bonne,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74041,BONNEVAUX,74360,BONNEVAUX,,46.2948424143,6.66552754458,41,,Bonnevaux,Bonnevaux,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74042,BONNEVILLE,74130,BONNEVILLE,,46.0739003968,6.40868532191,42,,Bonneville,Bonneville,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74043,BONS EN CHABLAIS,74890,BONS EN CHABLAIS,,46.2631816283,6.36089776128,43,,Bons-en-Chablais,Bons-en-Chablais,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74044,BOSSEY,74160,BOSSEY,,46.1500584591,6.17275446607,44,,Bossey,Bossey,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74045,LE BOUCHET,74230,LE BOUCHET MONT CHARVIN,,45.8045733703,6.38410878026,45,Le,Bouchet-Mont-Charvin,Le Bouchet-Mont-Charvin,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74046,BOUSSY,74150,BOUSSY,,45.8509031709,5.98346432153,46,,Boussy,Boussy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74048,BRENTHONNE,74890,BRENTHONNE,,46.2692786912,6.39437723207,48,,Brenthonne,Brenthonne,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74049,BRIZON,74130,BRIZON,,46.0352614624,6.43415213,49,,Brizon,Brizon,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74050,BURDIGNIN,74420,BURDIGNIN,,46.2324473826,6.42604409853,50,,Burdignin,Burdignin,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74051,CERCIER,74350,CERCIER,,46.021660163,6.04815697534,51,,Cercier,Cercier,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74052,CERNEX,74350,CERNEX,,46.0623077421,6.03872026675,52,,Cernex,Cernex,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74053,CERVENS,74550,CERVENS,,46.28114425,6.44750930568,53,,Cervens,Cervens,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74054,CHAINAZ LES FRASSES,74540,CHAINAZ LES FRASSES,,45.7784395221,5.98960566934,54,,Chainaz-les-Frasses,Chainaz-les-Frasses,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74055,CHALLONGES,74910,CHALLONGES,,46.0223204278,5.82687440424,55,,Challonges,Challonges,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74056,CHAMONIX MONT BLANC,74400,CHAMONIX MONT BLANC,,45.9309819111,6.92360096711,56,,Chamonix-Mont-Blanc,Chamonix-Mont-Blanc,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74056,CHAMONIX MONT BLANC,74400,CHAMONIX MONT BLANC,ARGENTIERE,45.9309819111,6.92360096711,56,,Chamonix-Mont-Blanc,Chamonix-Mont-Blanc,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74056,CHAMONIX MONT BLANC,74400,CHAMONIX MONT BLANC,LES BOSSONS,45.9309819111,6.92360096711,56,,Chamonix-Mont-Blanc,Chamonix-Mont-Blanc,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74056,CHAMONIX MONT BLANC,74400,CHAMONIX MONT BLANC,LES PRAZ DE CHAMONIX,45.9309819111,6.92360096711,56,,Chamonix-Mont-Blanc,Chamonix-Mont-Blanc,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74057,CHAMPANGES,74500,CHAMPANGES,,46.3715803084,6.55369978172,57,,Champanges,Champanges,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74058,LA CHAPELLE D ABONDANCE,74360,LA CHAPELLE D ABONDANCE,,46.3009535709,6.79280453698,58,La,Chapelle-d'Abondance,La Chapelle-d'Abondance,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74059,LA CHAPELLE RAMBAUD,74800,LA CHAPELLE RAMBAUD,,46.0732040123,6.23904981825,59,La,Chapelle-Rambaud,La Chapelle-Rambaud,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74060,LA CHAPELLE ST MAURICE,74410,LA CHAPELLE ST MAURICE,,45.7714927901,6.15405726067,60,La,Chapelle-Saint-Maurice,La Chapelle-Saint-Maurice,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74061,CHAPEIRY,74540,CHAPEIRY,,45.8392480283,6.03922674801,61,,Chapeiry,Chapeiry,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74062,CHARVONNEX,74370,CHARVONNEX,,45.9825276436,6.15767160259,62,,Charvonnex,Charvonnex,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74063,CHATEL,74390,CHATEL,,46.2490325973,6.81712790779,63,,Châtel,Châtel,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74064,CHATILLON SUR CLUSES,74300,CHATILLON SUR CLUSES,,46.0893520244,6.57817291187,64,,Châtillon-sur-Cluses,Châtillon-sur-Cluses,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74065,CHAUMONT,74270,CHAUMONT,,46.044949243,5.94515955142,65,,Chaumont,Chaumont,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74066,CHAVANNAZ,74270,CHAVANNAZ,,46.0500384099,6.01652644319,66,,Chavannaz,Chavannaz,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74067,CHAVANOD,74650,CHAVANOD,,45.8844744691,6.05040184575,67,,Chavanod,Chavanod,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74068,CHENE EN SEMINE,74270,CHENE EN SEMINE,,46.0499479891,5.858890983,68,,Chêne-en-Semine,Chêne-en-Semine,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74069,CHENEX,74520,CHENEX,,46.1028404412,5.9906719812,69,,Chênex,Chênex,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74070,CHENS SUR LEMAN,74140,CHENS SUR LEMAN,,46.3104075076,6.26773142669,70,,Chens-sur-Léman,Chens-sur-Léman,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74071,CHESSENAZ,74270,CHESSENAZ,,46.0407218951,5.90635701971,71,,Chessenaz,Chessenaz,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74072,CHEVALINE,74210,CHEVALINE,,45.7302662541,6.20797432213,72,,Chevaline,Chevaline,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74073,CHEVENOZ,74500,CHEVENOZ,,46.3330385867,6.64071625327,73,,Chevenoz,Chevenoz,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74074,CHEVRIER,74520,CHEVRIER,,46.1120536918,5.91128750728,74,,Chevrier,Chevrier,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74075,CHILLY,74270,CHILLY,,45.9892543857,5.95663892885,75,,Chilly,Chilly,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74076,CHOISY,74330,CHOISY,,45.9970301608,6.05417234636,76,,Choisy,Choisy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74077,CLARAFOND ARCINE,74270,CLARAFOND ARCINE,,46.074723886,5.89580535006,77,,Clarafond-Arcine,Clarafond-Arcine,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74077,CLARAFOND ARCINE,74270,CLARAFOND ARCINE,ARCINE,46.074723886,5.89580535006,77,,Clarafond-Arcine,Clarafond-Arcine,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74078,CLERMONT,74270,CLERMONT,,45.9734444894,5.90937510902,78,,Clermont,Clermont,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74079,LES CLEFS,74230,LES CLEFS,,45.8448702892,6.32686437969,79,Les,Clefs,Les Clefs,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74080,LA CLUSAZ,74220,LA CLUSAZ,,45.9001389505,6.46031714364,80,La,Clusaz,La Clusaz,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74081,CLUSES,74300,CLUSES,,46.0631088613,6.57892080059,81,,Cluses,Cluses,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74082,COLLONGES SOUS SALEVE,74160,COLLONGES SOUS SALEVE,,46.1362755824,6.16071125763,82,,Collonges-sous-Salève,Collonges-sous-Salève,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74083,COMBLOUX,74920,COMBLOUX,,45.8910313929,6.63687678944,83,,Combloux,Combloux,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74085,LES CONTAMINES MONTJOIE,74170,LES CONTAMINES MONTJOIE,,45.7888971555,6.73152876295,85,Les,Contamines-Montjoie,Les Contamines-Montjoie,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74086,CONTAMINE SARZIN,74270,CONTAMINE SARZIN,,46.0216239121,5.98148486224,86,,Contamine-Sarzin,Contamine-Sarzin,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74087,CONTAMINE SUR ARVE,74130,CONTAMINE SUR ARVE,,46.1334593706,6.33869457098,87,,Contamine-sur-Arve,Contamine-sur-Arve,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74088,COPPONEX,74350,COPPONEX,,46.0442257211,6.0703860483,88,,Copponex,Copponex,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74089,CORDON,74700,CORDON,,45.909121538,6.56940992155,89,,Cordon,Cordon,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74090,CORNIER,74800,CORNIER,,46.0897408945,6.29193924963,90,,Cornier,Cornier,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74091,LA COTE D ARBROZ,74110,LA COTE D ARBROZ,,46.1811051133,6.63111937923,91,La,Côte-d'Arbroz,La Côte-d'Arbroz,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74093,CRAN GEVRIER,74960,CRAN GEVRIER,,45.9017933456,6.09977672068,93,,Cran-Gevrier,Cran-Gevrier,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74094,CRANVES SALES,74380,CRANVES SALES,,46.1974383697,6.30389958032,94,,Cranves-Sales,Cranves-Sales,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74095,CREMPIGNY BONNEGUETE,74150,CREMPIGNY BONNEGUETE,,45.9467841367,5.90312519314,95,,Crempigny-Bonneguête,Crempigny-Bonneguête,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74095,CREMPIGNY BONNEGUETE,74150,CREMPIGNY BONNEGUETE,BONNEGUETE,45.9467841367,5.90312519314,95,,Crempigny-Bonneguête,Crempigny-Bonneguête,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74096,CRUSEILLES,74350,CRUSEILLES,,46.0362105004,6.11527700259,96,,Cruseilles,Cruseilles,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74097,CUSY,74540,CUSY,,45.7584929074,6.0333103006,97,,Cusy,Cusy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74098,CUVAT,74350,CUVAT,,45.9769889587,6.10863397469,98,,Cuvat,Cuvat,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74099,DEMI QUARTIER,74120,DEMI QUARTIER,,45.8739226707,6.6331062739,99,,Demi-Quartier,Demi-Quartier,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74100,DESINGY,74270,DESINGY,,45.9994978895,5.88777092004,100,,Desingy,Desingy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74101,DINGY EN VUACHE,74520,DINGY EN VUACHE,,46.0886545538,5.94974416216,101,,Dingy-en-Vuache,Dingy-en-Vuache,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74102,DINGY ST CLAIR,74230,DINGY ST CLAIR,,45.9289752964,6.25044685082,102,,Dingy-Saint-Clair,Dingy-Saint-Clair,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74103,DOMANCY,74700,DOMANCY,,45.9071921608,6.66391720268,103,,Domancy,Domancy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74104,DOUSSARD,74210,DOUSSARD,,45.7709274033,6.22183215069,104,,Doussard,Doussard,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74105,DOUVAINE,74140,DOUVAINE,,46.3063459721,6.29894207485,105,,Douvaine,Douvaine,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74106,DRAILLANT,74550,DRAILLANT,,46.2957245555,6.4729982985,106,,Draillant,Draillant,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74107,DROISY,74270,DROISY,,45.961959396,5.8811939602,107,,Droisy,Droisy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74108,DUINGT,74410,DUINGT,,45.8204516691,6.19524357933,108,,Duingt,Duingt,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74109,ELOISE,1200,ELOISE,,46.0837640303,5.84669856092,109,,Éloise,Éloise,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74110,ENTREMONT,74130,ENTREMONT,,45.9504525133,6.3755250744,110,,Entremont,Entremont,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74111,ENTREVERNES,74410,ENTREVERNES,,45.7764785792,6.18131365622,111,,Entrevernes,Entrevernes,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74112,EPAGNY METZ TESSY,74330,EPAGNY METZ TESSY,,45.9440370403,6.08226977374,112,,Epagny Metz-Tessy,Epagny Metz-Tessy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74112,EPAGNY METZ TESSY,74370,EPAGNY METZ TESSY,METZ TESSY,45.9440370403,6.08226977374,112,,Epagny Metz-Tessy,Epagny Metz-Tessy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74114,ESSERT ROMAND,74110,ESSERT ROMAND,,46.2023206067,6.660151187,114,,Essert-Romand,Essert-Romand,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74116,ETAUX,74800,ETAUX,,46.060819717,6.26988707785,116,,Etaux,Etaux,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74117,ETERCY,74150,ETERCY,,45.8924317262,6.0079458329,117,,Étercy,Étercy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74118,ETREMBIERES,74100,ETREMBIERES,,46.1698202877,6.20839249952,118,,Étrembières,Étrembières,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74118,ETREMBIERES,74100,ETREMBIERES,PAS DE L ECHELLE,46.1698202877,6.20839249952,118,,Étrembières,Étrembières,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74119,EVIAN LES BAINS,74500,EVIAN LES BAINS,,46.3924102379,6.58612507528,119,,Évian-les-Bains,Évian-les-Bains,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74120,EVIRES,74570,EVIRES,,46.0421036287,6.22183912174,120,,Évires,Évires,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74121,EXCENEVEX,74140,EXCENEVEX,,46.3467214365,6.34122172541,121,,Excenevex,Excenevex,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74122,FAUCIGNY,74130,FAUCIGNY,,46.1164159018,6.36907611887,122,,Faucigny,Faucigny,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74123,FAVERGES SEYTHENEX,74210,FAVERGES SEYTHENEX,,45.749197611,6.2852126691,123,,Faverges-Seythenex,Faverges-Seythenex,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74123,FAVERGES SEYTHENEX,74210,FAVERGES SEYTHENEX,SEYTHENEX,45.749197611,6.2852126691,123,,Faverges-Seythenex,Faverges-Seythenex,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74124,FEIGERES,74160,FEIGERES,,46.1104409048,6.07310572582,124,,Feigères,Feigères,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74126,FESSY,74890,FESSY,,46.2708634498,6.42354470529,126,,Fessy,Fessy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74127,FETERNES,74500,FETERNES,,46.3490789194,6.5652955969,127,,Féternes,Féternes,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74128,FILLINGES,74250,FILLINGES,,46.1661884948,6.35020094115,128,,Fillinges,Fillinges,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74129,LA FORCLAZ,74200,LA FORCLAZ,,46.3179930768,6.61587770566,129,La,Forclaz,La Forclaz,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74130,FRANCLENS,74910,FRANCLENS,,46.0440349953,5.82444988041,130,,Franclens,Franclens,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74131,FRANGY,74270,FRANGY,,46.0231025542,5.92267414864,131,,Frangy,Frangy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74133,GAILLARD,74240,GAILLARD,,46.1818085953,6.20707666644,133,,Gaillard,Gaillard,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74134,LES GETS,74260,LES GETS,,46.1542288253,6.66014566051,134,Les,Gets,Les Gets,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74135,GIEZ,74210,GIEZ,,45.7384038811,6.24575065655,135,,Giez,Giez,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74136,LE GRAND BORNAND,74450,LE GRAND BORNAND,,45.9562174482,6.47641784295,136,Le,Grand-Bornand,Le Grand-Bornand,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74137,GROISY,74570,GROISY,,46.0176333128,6.17496504174,137,,Groisy,Groisy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74138,GRUFFY,74540,GRUFFY,,45.7831959946,6.06312063481,138,,Gruffy,Gruffy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74139,HABERE LULLIN,74420,HABERE LULLIN,,46.2330939582,6.462221779,139,,Habère-Lullin,Habère-Lullin,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74140,HABERE POCHE,74420,HABERE POCHE,,46.2555235551,6.47324374853,140,,Habère-Poche,Habère-Poche,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74141,HAUTEVILLE SUR FIER,74150,HAUTEVILLE SUR FIER,,45.9053969154,5.97847450903,141,,Hauteville-sur-Fier,Hauteville-sur-Fier,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74142,HERY SUR ALBY,74540,HERY SUR ALBY,,45.791943752,6.01056501234,142,,Héry-sur-Alby,Héry-sur-Alby,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74143,LES HOUCHES,74310,LES HOUCHES,,45.8968732063,6.8000932715,143,Les,Houches,Les Houches,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74144,JONZIER EPAGNY,74520,JONZIER EPAGNY,,46.0765856703,5.98885636261,144,,Jonzier-Épagny,Jonzier-Épagny,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74145,JUVIGNY,74100,JUVIGNY,,46.2159934836,6.2901767957,145,,Juvigny,Juvigny,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74146,LARRINGES,74500,LARRINGES,,46.369808059,6.58783064485,146,,Larringes,Larringes,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74147,LATHUILE,74210,LATHUILE,,45.7732607321,6.19555768621,147,,Lathuile,Lathuile,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74148,LESCHAUX,74320,LESCHAUX,,45.7750459129,6.11849070484,148,,Leschaux,Leschaux,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74150,LOISIN,74140,LOISIN,,46.2802184824,6.30399000794,150,,Loisin,Loisin,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74151,LORNAY,74150,LORNAY,,45.9107999793,5.88954497297,151,,Lornay,Lornay,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74152,LOVAGNY,74330,LOVAGNY,,45.9038439081,6.03434039234,152,,Lovagny,Lovagny,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74153,LUCINGES,74380,LUCINGES,,46.1940296525,6.32983573974,153,,Lucinges,Lucinges,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74154,LUGRIN,74500,LUGRIN,,46.3933090904,6.67397267401,154,,Lugrin,Lugrin,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74155,LULLIN,74470,LULLIN,,46.2792006557,6.51041633979,155,,Lullin,Lullin,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74156,LULLY,74890,LULLY,,46.2927550589,6.40876638382,156,,Lully,Lully,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74157,LYAUD,74200,LYAUD,,46.3337086128,6.52037884691,157,,Lyaud,Lyaud,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74158,MACHILLY,74140,MACHILLY,,46.2555120151,6.32281411543,158,,Machilly,Machilly,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74159,MAGLAND,74300,MAGLAND,,46.0010482843,6.63346275001,159,,Magland,Magland,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74159,MAGLAND,74300,MAGLAND,FLAINE,46.0010482843,6.63346275001,159,,Magland,Magland,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74160,MANIGOD,74230,MANIGOD,,45.850295364,6.40113588124,160,,Manigod,Manigod,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74161,MARCELLAZ ALBANAIS,74150,MARCELLAZ ALBANAIS,,45.8707666438,5.99966381928,161,,Marcellaz-Albanais,Marcellaz-Albanais,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74162,MARCELLAZ,74250,MARCELLAZ,,46.1452555386,6.35771780068,162,,Marcellaz,Marcellaz,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74163,MARGENCEL,74200,MARGENCEL,,46.3315818048,6.42253424763,163,,Margencel,Margencel,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74164,MARIGNIER,74970,MARIGNIER,,46.0950289167,6.4944693169,164,,Marignier,Marignier,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74165,MARIGNY ST MARCEL,74150,MARIGNY ST MARCEL,,45.8298777839,5.98423746783,165,,Marigny-Saint-Marcel,Marigny-Saint-Marcel,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74166,MARIN,74200,MARIN,,46.374432028,6.52367394025,166,,Marin,Marin,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74167,VAL DE CHAISE,74210,VAL DE CHAISE,CONS STE COLOMBE,45.7653898846,6.35142628779,167,,Val de Chaise,Val de Chaise,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74167,VAL DE CHAISE,74210,VAL DE CHAISE,MARLENS,45.7653898846,6.35142628779,167,,Val de Chaise,Val de Chaise,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74168,MARLIOZ,74270,MARLIOZ,,46.0270380688,6.00829884912,168,,Marlioz,Marlioz,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74169,MARNAZ,74460,MARNAZ,,46.0518903759,6.5224156191,169,,Marnaz,Marnaz,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74170,MASSINGY,74150,MASSINGY,,45.8267941245,5.91585359049,170,,Massingy,Massingy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74171,MASSONGY,74140,MASSONGY,,46.3230559823,6.32549950668,171,,Massongy,Massongy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74172,MAXILLY SUR LEMAN,74500,MAXILLY SUR LEMAN,,46.393993609,6.6339170176,172,,Maxilly-sur-Léman,Maxilly-sur-Léman,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74173,MEGEVE,74120,MEGEVE,,45.8401573581,6.62348394015,173,,Megève,Megève,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74174,MEGEVETTE,74490,MEGEVETTE,,46.208458258,6.51327068897,174,,Mégevette,Mégevette,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74175,MEILLERIE,74500,MEILLERIE,,46.4006869695,6.73172142623,175,,Meillerie,Meillerie,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74176,MENTHON ST BERNARD,74290,MENTHON ST BERNARD,,45.8653434236,6.19371720419,176,,Menthon-Saint-Bernard,Menthon-Saint-Bernard,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74177,MENTHONNEX EN BORNES,74350,MENTHONNEX EN BORNES,,46.0594628782,6.17994135372,177,,Menthonnex-en-Bornes,Menthonnex-en-Bornes,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74178,MENTHONNEX SOUS CLERMONT,74270,MENTHONNEX SOUS CLERMONT,,45.9618992174,5.93569269941,178,,Menthonnex-sous-Clermont,Menthonnex-sous-Clermont,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74179,MESIGNY,74330,MESIGNY,,45.9864538672,6.00616944554,179,,Mésigny,Mésigny,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74180,MESSERY,74140,MESSERY,,46.3456509574,6.29932822194,180,,Messery,Messery,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74182,MEYTHET,74960,MEYTHET,,45.9184414725,6.09688746113,182,,Meythet,Meythet,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74183,MIEUSSY,74440,MIEUSSY,,46.1453438048,6.53345961052,183,,Mieussy,Mieussy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74183,MIEUSSY,74440,MIEUSSY,SOMMAND,46.1453438048,6.53345961052,183,,Mieussy,Mieussy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74184,MINZIER,74270,MINZIER,,46.0551645606,5.99089024403,184,,Minzier,Minzier,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74185,MONNETIER MORNEX,74560,MONNETIER MORNEX,,46.1525800446,6.21241233878,185,,Monnetier-Mornex,Monnetier-Mornex,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74185,MONNETIER MORNEX,74560,MONNETIER MORNEX,ESSERTS SALEVE,46.1525800446,6.21241233878,185,,Monnetier-Mornex,Monnetier-Mornex,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74186,MONTAGNY LES LANCHES,74600,MONTAGNY LES LANCHES,,45.8589669749,6.04681935916,186,,Montagny-les-Lanches,Montagny-les-Lanches,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74188,MONTRIOND,74110,MONTRIOND,,46.2066444762,6.74359316824,188,,Montriond,Montriond,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74189,MONT SAXONNEX,74130,MONT SAXONNEX,,46.0351333935,6.47990420928,189,,Mont-Saxonnex,Mont-Saxonnex,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74190,MORILLON,74440,MORILLON,,46.0632390772,6.67318237741,190,,Morillon,Morillon,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74191,MORZINE,74110,MORZINE,,46.1732135057,6.74252144427,191,,Morzine,Morzine,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74191,MORZINE,74110,MORZINE,AVORIAZ,46.1732135057,6.74252144427,191,,Morzine,Morzine,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74192,MOYE,74150,MOYE,,45.8711582586,5.89506512149,192,,Moye,Moye,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74193,LA MURAZ,74560,LA MURAZ,,46.1193345153,6.19613166236,193,La,Muraz,La Muraz,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74194,MURES,74540,MURES,,45.8062692849,6.03921790944,194,,Mûres,Mûres,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74195,MUSIEGES,74270,MUSIEGES,,46.014191585,5.95578712285,195,,Musièges,Musièges,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74196,NANCY SUR CLUSES,74300,NANCY SUR CLUSES,,46.0239034674,6.57403286211,196,,Nancy-sur-Cluses,Nancy-sur-Cluses,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74197,NANGY,74380,NANGY,,46.1500146903,6.30645788987,197,,Nangy,Nangy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74198,NAVES PARMELAN,74370,NAVES PARMELAN,,45.9320249832,6.19716046431,198,,Nâves-Parmelan,Nâves-Parmelan,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74199,NERNIER,74140,NERNIER,,46.3615754637,6.30527066431,199,,Nernier,Nernier,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74200,NEUVECELLE,74500,NEUVECELLE,,46.3921186949,6.60933233238,200,,Neuvecelle,Neuvecelle,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74201,NEYDENS,74160,NEYDENS,,46.118085274,6.10360630627,201,,Neydens,Neydens,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74202,NONGLARD,74330,NONGLARD,,45.9228803405,6.02730833828,202,,Nonglard,Nonglard,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74203,NOVEL,74500,NOVEL,,46.3613258341,6.76382065877,203,,Novel,Novel,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74204,LES OLLIERES,74370,LES OLLIERES,,45.9816487094,6.18789182586,204,Les,Ollières,Les Ollières,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74205,ONNION,74490,ONNION,,46.1816924284,6.49869806571,205,,Onnion,Onnion,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74206,ORCIER,74550,ORCIER,,46.3132216712,6.50123814187,206,,Orcier,Orcier,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74208,PASSY,74190,PASSY,,45.9545838758,6.74056895873,208,,Passy,Passy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74208,PASSY,74190,PASSY,CHEDDE,45.9545838758,6.74056895873,208,,Passy,Passy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74208,PASSY,74480,PASSY,,45.9545838758,6.74056895873,208,,Passy,Passy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74208,PASSY,74480,PASSY,GUEBRIANT,45.9545838758,6.74056895873,208,,Passy,Passy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74208,PASSY,74480,PASSY,MARTEL DE JANVILLE,45.9545838758,6.74056895873,208,,Passy,Passy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74208,PASSY,74480,PASSY,MONT BLANC D ASSY,45.9545838758,6.74056895873,208,,Passy,Passy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74208,PASSY,74480,PASSY,PLATEAU D ASSY,45.9545838758,6.74056895873,208,,Passy,Passy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74208,PASSY,74480,PASSY,PRAZ COUTANT,45.9545838758,6.74056895873,208,,Passy,Passy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74208,PASSY,74480,PASSY,SANCELLEMOZ,45.9545838758,6.74056895873,208,,Passy,Passy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74209,PEILLONNEX,74250,PEILLONNEX,,46.1323903269,6.38235648259,209,,Peillonnex,Peillonnex,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74210,PERRIGNIER,74550,PERRIGNIER,,46.3062097696,6.43566112571,210,,Perrignier,Perrignier,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74211,PERS JUSSY,74930,PERS JUSSY,,46.1018355447,6.25852776575,211,,Pers-Jussy,Pers-Jussy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74212,LE PETIT BORNAND LES GLIERES,74130,LE PETIT BORNAND LES GLIERES,,45.9971884762,6.3914422283,212,Le,Petit-Bornand-les-Glières,Le Petit-Bornand-les-Glières,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74213,POISY,74330,POISY,,45.9189291023,6.06312526002,213,,Poisy,Poisy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74215,PRAZ SUR ARLY,74120,PRAZ SUR ARLY,,45.8273830963,6.58013212731,215,,Praz-sur-Arly,Praz-sur-Arly,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74216,PRESILLY,74160,PRESILLY,,46.0850070124,6.09107599342,216,,Présilly,Présilly,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74217,PRINGY,74370,PRINGY,,45.9566469348,6.10912303815,217,,Pringy,Pringy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74217,PRINGY,74370,PRINGY,FERRIERES,45.9566469348,6.10912303815,217,,Pringy,Pringy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74218,PUBLIER,74500,PUBLIER,,46.3896985259,6.54164965422,218,,Publier,Publier,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74218,PUBLIER,74500,PUBLIER,AMPHION LES BAINS,46.3896985259,6.54164965422,218,,Publier,Publier,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74219,QUINTAL,74600,QUINTAL,,45.8329607054,6.09119171062,219,,Quintal,Quintal,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74220,REIGNIER ESERY,74930,REIGNIER ESERY,,46.1353479879,6.26126122824,220,,Reignier-Ésery,Reignier-Ésery,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74220,REIGNIER ESERY,74930,REIGNIER ESERY,ESERY,46.1353479879,6.26126122824,220,,Reignier-Ésery,Reignier-Ésery,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74221,LE REPOSOIR,74950,LE REPOSOIR,,45.9970981338,6.52628371874,221,Le,Reposoir,Le Reposoir,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74222,REYVROZ,74200,REYVROZ,,46.324542262,6.56224816436,222,,Reyvroz,Reyvroz,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74223,LA RIVIERE ENVERSE,74440,LA RIVIERE ENVERSE,,46.0896743627,6.63334619957,223,La,Rivière-Enverse,La Rivière-Enverse,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74224,LA ROCHE SUR FORON,74800,LA ROCHE SUR FORON,,46.0431484138,6.30107653595,224,La,Roche-sur-Foron,La Roche-sur-Foron,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74225,RUMILLY,74150,RUMILLY,,45.8555126651,5.94075287432,225,,Rumilly,Rumilly,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74226,ST ANDRE DE BOEGE,74420,ST ANDRE DE BOEGE,,46.1932380604,6.3815476772,226,,Saint-André-de-Boëge,Saint-André-de-Boëge,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74228,ST BLAISE,74350,ST BLAISE,,46.0663582053,6.09154099437,228,,Saint-Blaise,Saint-Blaise,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74229,ST CERGUES,74140,ST CERGUES,,46.2308494715,6.32552147286,229,,Saint-Cergues,Saint-Cergues,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74231,ST EUSEBE,74150,ST EUSEBE,,45.9294639513,5.96296409626,231,,Saint-Eusèbe,Saint-Eusèbe,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74232,ST EUSTACHE,74410,ST EUSTACHE,,45.7953730789,6.15214933541,232,,Saint-Eustache,Saint-Eustache,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74233,ST FELIX,74540,ST FELIX,,45.8078414616,5.97720329575,233,,Saint-Félix,Saint-Félix,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74234,ST FERREOL,74210,ST FERREOL,,45.7833203062,6.30718276137,234,,Saint-Ferréol,Saint-Ferréol,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74235,ST GERMAIN SUR RHONE,74910,ST GERMAIN SUR RHONE,,46.0700297768,5.82592326437,235,,Saint-Germain-sur-Rhône,Saint-Germain-sur-Rhône,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74236,ST GERVAIS LES BAINS,74170,ST GERVAIS LES BAINS,,45.8571864862,6.74016257718,236,,Saint-Gervais-les-Bains,Saint-Gervais-les-Bains,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74236,ST GERVAIS LES BAINS,74170,ST GERVAIS LES BAINS,LE FAYET,45.8571864862,6.74016257718,236,,Saint-Gervais-les-Bains,Saint-Gervais-les-Bains,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74236,ST GERVAIS LES BAINS,74170,ST GERVAIS LES BAINS,ST NICOLAS DE VEROCE,45.8571864862,6.74016257718,236,,Saint-Gervais-les-Bains,Saint-Gervais-les-Bains,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74237,ST GINGOLPH,74500,ST GINGOLPH,,46.3882088835,6.77810230849,237,,Saint-Gingolph,Saint-Gingolph,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74238,ST JEAN D AULPS,74430,ST JEAN D AULPS,,46.2278739142,6.66076731793,238,,Saint-Jean-d'Aulps,Saint-Jean-d'Aulps,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74239,ST JEAN DE SIXT,74450,ST JEAN DE SIXT,,45.9242802891,6.40961538858,239,,Saint-Jean-de-Sixt,Saint-Jean-de-Sixt,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74240,ST JEAN DE THOLOME,74250,ST JEAN DE THOLOME,,46.1107199959,6.41494686229,240,,Saint-Jean-de-Tholome,Saint-Jean-de-Tholome,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74241,ST JEOIRE,74490,ST JEOIRE EN FAUCIGNY,,46.1392092512,6.46500057209,241,,Saint-Jeoire,Saint-Jeoire,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74242,ST JORIOZ,74410,ST JORIOZ,,45.822898265,6.14963663908,242,,Saint-Jorioz,Saint-Jorioz,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74243,ST JULIEN EN GENEVOIS,74160,ST JULIEN EN GENEVOIS,,46.139365545,6.07881171095,243,,Saint-Julien-en-Genevois,Saint-Julien-en-Genevois,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74244,ST LAURENT,74800,ST LAURENT,,46.0373461357,6.35335860666,244,,Saint-Laurent,Saint-Laurent,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74245,ST MARTIN BELLEVUE,74370,ST MARTIN BELLEVUE,,45.9714139291,6.13897086918,245,,Saint-Martin-Bellevue,Saint-Martin-Bellevue,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74249,ST PAUL EN CHABLAIS,74500,ST PAUL EN CHABLAIS,,46.3693047939,6.63664025494,249,,Saint-Paul-en-Chablais,Saint-Paul-en-Chablais,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74250,ST PIERRE EN FAUCIGNY,74800,ST PIERRE EN FAUCIGNY,,46.063686335,6.37244945345,250,,Saint-Pierre-en-Faucigny,Saint-Pierre-en-Faucigny,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74252,ST SIGISMOND,74300,ST SIGISMOND,,46.0753265855,6.61651398785,252,,Saint-Sigismond,Saint-Sigismond,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74253,ST SIXT,74800,ST SIXT,,46.0419013961,6.32297351763,253,,Saint-Sixt,Saint-Sixt,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74254,ST SYLVESTRE,74540,ST SYLVESTRE,,45.8403822562,6.01327128168,254,,Saint-Sylvestre,Saint-Sylvestre,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74255,SALES,74150,SALES,,45.8821225736,5.96006746807,255,,Sales,Sales,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74256,SALLANCHES,74700,SALLANCHES,,45.9468155788,6.60711053078,256,,Sallanches,Sallanches,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74256,SALLANCHES,74700,SALLANCHES,ST MARTIN SUR ARVE,45.9468155788,6.60711053078,256,,Sallanches,Sallanches,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74256,SALLANCHES,74700,SALLANCHES,ST ROCH,45.9468155788,6.60711053078,256,,Sallanches,Sallanches,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74257,SALLENOVES,74270,SALLENOVES,,46.0029977511,5.99114541965,257,,Sallenôves,Sallenôves,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74258,SAMOENS,74340,SAMOENS,,46.0878467821,6.74329361493,258,,Samoëns,Samoëns,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74259,LE SAPPEY,74350,LE SAPPEY,,46.0883274931,6.16118965479,259,Le,Sappey,Le Sappey,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74260,SAVIGNY,74520,SAVIGNY,,46.0694979288,5.95170767195,260,,Savigny,Savigny,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74261,SAXEL,74420,SAXEL,,46.2447795601,6.40325461428,261,,Saxel,Saxel,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74262,SCIENTRIER,74930,SCIENTRIER,,46.1202601258,6.31761155339,262,,Scientrier,Scientrier,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74263,SCIEZ,74140,SCIEZ,,46.3247332955,6.38071834154,263,,Sciez,Sciez,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74264,SCIONZIER,74950,SCIONZIER,,46.0486254419,6.54669166581,264,,Scionzier,Scionzier,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74265,SERRAVAL,74230,SERRAVAL,,45.8118131571,6.33496579827,265,,Serraval,Serraval,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74266,SERVOZ,74310,SERVOZ,,45.9454311308,6.78543826857,266,,Servoz,Servoz,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74267,SEVRIER,74320,SEVRIER,,45.8555012829,6.13806338899,267,,Sevrier,Sevrier,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74268,SEYNOD,74600,SEYNOD,,45.8651082916,6.08376465474,268,,Seynod,Seynod,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74268,SEYNOD,74600,SEYNOD,BALMONT,45.8651082916,6.08376465474,268,,Seynod,Seynod,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74268,SEYNOD,74600,SEYNOD,VIEUGY,45.8651082916,6.08376465474,268,,Seynod,Seynod,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74269,SEYSSEL,74910,SEYSSEL,,45.959506245,5.8529871085,269,,Seyssel,Seyssel,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74271,SEYTROUX,74430,SEYTROUX,,46.2397817737,6.60295956835,271,,Seytroux,Seytroux,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74272,SILLINGY,74330,SILLINGY,,45.9489088866,6.02753426428,272,,Sillingy,Sillingy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74273,SIXT FER A CHEVAL,74740,SIXT FER A CHEVAL,,46.0542010133,6.81232553172,273,,Sixt-Fer-à-Cheval,Sixt-Fer-à-Cheval,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74274,VAL DE FIER,74150,VAL DE FIER,,45.9279912564,5.89294651172,274,,Val-de-Fier,Val-de-Fier,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74274,VAL DE FIER,74150,VAL DE FIER,SION,45.9279912564,5.89294651172,274,,Val-de-Fier,Val-de-Fier,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74274,VAL DE FIER,74150,VAL DE FIER,ST ANDRE VAL DE FIER,45.9279912564,5.89294651172,274,,Val-de-Fier,Val-de-Fier,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74275,TALLOIRES MONTMIN,74210,TALLOIRES MONTMIN,MONTMIN,45.8357923857,6.23052089871,275,,Talloires-Montmin,Talloires-Montmin,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74275,TALLOIRES MONTMIN,74290,TALLOIRES MONTMIN,,45.8357923857,6.23052089871,275,,Talloires-Montmin,Talloires-Montmin,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74276,TANINGES,74440,TANINGES,,46.1265071577,6.60770716363,276,,Taninges,Taninges,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74276,TANINGES,74440,TANINGES,LE PRAZ DE LYS,46.1265071577,6.60770716363,276,,Taninges,Taninges,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74278,THYEZ,74300,THYEZ,,46.0881937629,6.53665081936,278,,Thyez,Thyez,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74279,THOLLON LES MEMISES,74500,THOLLON LES MEMISES,,46.3828568354,6.7291180359,279,,Thollon-les-Mémises,Thollon-les-Mémises,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74280,THONES,74230,THONES,,45.8852613668,6.31953220709,280,,Thônes,Thônes,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74281,THONON LES BAINS,74200,THONON LES BAINS,,46.3704258049,6.48194336071,281,,Thonon-les-Bains,Thonon-les-Bains,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74281,THONON LES BAINS,74200,THONON LES BAINS,VONGY,46.3704258049,6.48194336071,281,,Thonon-les-Bains,Thonon-les-Bains,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74282,THORENS GLIERES,74570,THORENS GLIERES,,45.9923733891,6.28837579239,282,,Fillière,Fillière,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74283,THUSY,74150,THUSY,,45.949872762,5.96377064209,283,,Thusy,Thusy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74284,LA TOUR,74250,LA TOUR,,46.1336564558,6.43183287509,284,La,Tour,La Tour,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74285,USINENS,74910,USINENS,,46.0141101531,5.85064952145,285,,Usinens,Usinens,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74286,VACHERESSE,74360,VACHERESSE,,46.3228440674,6.7065933809,286,,Vacheresse,Vacheresse,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74287,VAILLY,74470,VAILLY,,46.2936006568,6.55263148753,287,,Vailly,Vailly,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74288,VALLEIRY,74520,VALLEIRY,,46.1152145208,5.97022697234,288,,Valleiry,Valleiry,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74289,VALLIERES,74150,VALLIERES,,45.9052695634,5.94190689609,289,,Vallières,Vallières,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74290,VALLORCINE,74660,VALLORCINE,,46.025877178,6.90055556449,290,,Vallorcine,Vallorcine,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74291,VANZY,74270,VANZY,,46.0345033012,5.87850321489,291,,Vanzy,Vanzy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74292,VAULX,74150,VAULX,,45.9255788911,5.99894217943,292,,Vaulx,Vaulx,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74293,VEIGY FONCENEX,74140,VEIGY FONCENEX,,46.2703256602,6.26781250084,293,,Veigy-Foncenex,Veigy-Foncenex,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74294,VERCHAIX,74440,VERCHAIX,,46.1211763071,6.68814243155,294,,Verchaix,Verchaix,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74295,LA VERNAZ,74200,LA VERNAZ,,46.3073602687,6.59539098914,295,La,Vernaz,La Vernaz,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74296,VERS,74160,VERS,,46.088423154,6.02493398653,296,,Vers,Vers,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74297,VERSONNEX,74150,VERSONNEX,,45.9261649892,5.92765676795,297,,Versonnex,Versonnex,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74298,VETRAZ MONTHOUX,74100,VETRAZ MONTHOUX,,46.1810228514,6.26266311995,298,,Vétraz-Monthoux,Vétraz-Monthoux,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74299,VEYRIER DU LAC,74290,VEYRIER DU LAC,,45.8857432709,6.17657908258,299,,Veyrier-du-Lac,Veyrier-du-Lac,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74301,VILLARD,74420,VILLARD,,46.2123462222,6.450248937,301,,Villard,Villard,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74302,LES VILLARDS SUR THONES,74230,LES VILLARDS SUR THONES,,45.9132702752,6.37458702668,302,Les,Villards-sur-Thônes,Les Villards-sur-Thônes,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74303,VILLAZ,74370,VILLAZ,,45.9532681771,6.20133049908,303,,Villaz,Villaz,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74304,VILLE EN SALLAZ,74250,VILLE EN SALLAZ,,46.1451320833,6.42263248025,304,,Ville-en-Sallaz,Ville-en-Sallaz,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74305,VILLE LA GRAND,74100,VILLE LA GRAND,,46.2044481273,6.26056985125,305,,Ville-la-Grand,Ville-la-Grand,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74306,VILLY LE BOUVERET,74350,VILLY LE BOUVERET,,46.0442515744,6.15921118422,306,,Villy-le-Bouveret,Villy-le-Bouveret,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74307,VILLY LE PELLOUX,74350,VILLY LE PELLOUX,,46.0004341516,6.13625958374,307,,Villy-le-Pelloux,Villy-le-Pelloux,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74308,VINZIER,74500,VINZIER,,46.3449767077,6.61043449085,308,,Vinzier,Vinzier,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74309,VIRY,74580,VIRY,,46.1193528783,6.02496587452,309,,Viry,Viry,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74310,VIUZ LA CHIESAZ,74540,VIUZ LA CHIESAZ,,45.8113073621,6.07554631236,310,,Viuz-la-Chiésaz,Viuz-la-Chiésaz,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74311,VIUZ EN SALLAZ,74250,VIUZ EN SALLAZ,,46.1640902035,6.4072679008,311,,Viuz-en-Sallaz,Viuz-en-Sallaz,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74312,VOUGY,74130,VOUGY,,46.066690083,6.48527967486,312,,Vougy,Vougy,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74313,VOVRAY EN BORNES,74350,VOVRAY EN BORNES,,46.0630306503,6.14060275314,313,,Vovray-en-Bornes,Vovray-en-Bornes,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74314,VULBENS,74520,VULBENS,,46.1094500847,5.9361888099,314,,Vulbens,Vulbens,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
74315,YVOIRE,74140,YVOIRE,,46.3635082871,6.33214094024,315,,Yvoire,Yvoire,74,Haute-Savoie,84,Auvergne-Rhône-Alpes
75101,PARIS 01,75001,PARIS,,48.8626304852,2.33629344655,101,,Paris 01,Paris 01,75,Paris,11,Île-de-France
75102,PARIS 02,75002,PARIS,,48.8679033789,2.34410716666,102,,Paris 02,Paris 02,75,Paris,11,Île-de-France
75103,PARIS 03,75003,PARIS,,48.8630541318,2.35936105897,103,,Paris 03,Paris 03,75,Paris,11,Île-de-France
75104,PARIS 04,75004,PARIS,,48.854228282,2.35736193814,104,,Paris 04,Paris 04,75,Paris,11,Île-de-France
75105,PARIS 05,75005,PARIS,,48.8445086596,2.34985938556,105,,Paris 05,Paris 05,75,Paris,11,Île-de-France
75106,PARIS 06,75006,PARIS,,48.8489680919,2.33267089859,106,,Paris 06,Paris 06,75,Paris,11,Île-de-France
75107,PARIS 07,75007,PARIS,,48.8560825982,2.31243868773,107,,Paris 07,Paris 07,75,Paris,11,Île-de-France
75108,PARIS 08,75008,PARIS,,48.8725272666,2.31258256042,108,,Paris 08,Paris 08,75,Paris,11,Île-de-France
75109,PARIS 09,75009,PARIS,,48.8768961624,2.33746024139,109,,Paris 09,Paris 09,75,Paris,11,Île-de-France
75110,PARIS 10,75010,PARIS,,48.8760285569,2.36111290456,110,,Paris 10,Paris 10,75,Paris,11,Île-de-France
75111,PARIS 11,75011,PARIS,,48.8594154976,2.37874106024,111,,Paris 11,Paris 11,75,Paris,11,Île-de-France
75112,PARIS 12,75012,PARIS,,48.8351562307,2.41980703497,112,,Paris 12,Paris 12,75,Paris,11,Île-de-France
75113,PARIS 13,75013,PARIS,,48.8287176845,2.36246822852,113,,Paris 13,Paris 13,75,Paris,11,Île-de-France
75114,PARIS 14,75014,PARIS,,48.8289932116,2.32710088326,114,,Paris 14,Paris 14,75,Paris,11,Île-de-France
75115,PARIS 15,75015,PARIS,,48.8401554186,2.29355937244,115,,Paris 15,Paris 15,75,Paris,11,Île-de-France
75116,PARIS 16,75016,PARIS,,48.8603987604,2.2620995594,116,,Paris 16,Paris 16,75,Paris,11,Île-de-France
75116,PARIS 16,75116,PARIS,,48.8603987604,2.2620995594,116,,Paris 16,Paris 16,75,Paris,11,Île-de-France
75117,PARIS 17,75017,PARIS,,48.8873371665,2.30748555949,117,,Paris 17,Paris 17,75,Paris,11,Île-de-France
75118,PARIS 18,75018,PARIS,,48.8927350746,2.34871193387,118,,Paris 18,Paris 18,75,Paris,11,Île-de-France
75119,PARIS 19,75019,PARIS,,48.886868623,2.38469432787,119,,Paris 19,Paris 19,75,Paris,11,Île-de-France
75120,PARIS 20,75020,PARIS,,48.8631867774,2.40081982673,120,,Paris 20,Paris 20,75,Paris,11,Île-de-France
76001,ALLOUVILLE BELLEFOSSE,76190,ALLOUVILLE BELLEFOSSE,,49.5946409573,0.665436022637,1,,Allouville-Bellefosse,Allouville-Bellefosse,76,Seine-Maritime,28,Normandie
76002,ALVIMARE,76640,ALVIMARE,,49.6071633233,0.63205346079,2,,Alvimare,Alvimare,76,Seine-Maritime,28,Normandie
76004,AMBRUMESNIL,76550,AMBRUMESNIL,,49.8584810142,0.983755211163,4,,Ambrumesnil,Ambrumesnil,76,Seine-Maritime,28,Normandie
76005,AMFREVILLE LA MI VOIE,76920,AMFREVILLE LA MI VOIE,,49.4044285564,1.12471250441,5,,Amfreville-la-Mi-Voie,Amfreville-la-Mi-Voie,76,Seine-Maritime,28,Normandie
76006,AMFREVILLE LES CHAMPS,76560,AMFREVILLE LES CHAMPS,,49.6985248041,0.826235482496,6,,Amfreville-les-Champs,Amfreville-les-Champs,76,Seine-Maritime,28,Normandie
76007,ANCEAUMEVILLE,76710,ANCEAUMEVILLE,,49.5695149287,1.06535432644,7,,Anceaumeville,Anceaumeville,76,Seine-Maritime,28,Normandie
76008,ANCOURT,76370,ANCOURT,,49.9085547953,1.17898885458,8,,Ancourt,Ancourt,76,Seine-Maritime,28,Normandie
76009,ANCOURTEVILLE SUR HERICOURT,76560,ANCOURTEVILLE SUR HERICOURT,,49.6907478864,0.660651271131,9,,Ancourteville-sur-Héricourt,Ancourteville-sur-Héricourt,76,Seine-Maritime,28,Normandie
76010,ANCRETIEVILLE ST VICTOR,76760,ANCRETIEVILLE ST VICTOR,,49.6545166114,0.967454665493,10,,Ancretiéville-Saint-Victor,Ancretiéville-Saint-Victor,76,Seine-Maritime,28,Normandie
76011,ANCRETTEVILLE SUR MER,76540,ANCRETTEVILLE SUR MER,,49.7892649687,0.504203868088,11,,Ancretteville-sur-Mer,Ancretteville-sur-Mer,76,Seine-Maritime,28,Normandie
76012,ANGERVILLE BAILLEUL,76110,ANGERVILLE BAILLEUL,,49.667866856,0.45434007056,12,,Angerville-Bailleul,Angerville-Bailleul,76,Seine-Maritime,28,Normandie
76013,ANGERVILLE LA MARTEL,76540,ANGERVILLE LA MARTEL,,49.7681060505,0.501685271986,13,,Angerville-la-Martel,Angerville-la-Martel,76,Seine-Maritime,28,Normandie
76014,ANGERVILLE L ORCHER,76280,ANGERVILLE L ORCHER,,49.5867004346,0.292626408663,14,,Angerville-l'Orcher,Angerville-l'Orcher,76,Seine-Maritime,28,Normandie
76015,ANGIENS,76740,ANGIENS,,49.8336959193,0.798984210018,15,,Angiens,Angiens,76,Seine-Maritime,28,Normandie
76016,ANGLESQUEVILLE LA BRAS LONG,76740,ANGLESQUEVILLE LA BRAS LONG,,49.7781234194,0.782574427799,16,,Anglesqueville-la-Bras-Long,Anglesqueville-la-Bras-Long,76,Seine-Maritime,28,Normandie
76017,ANGLESQUEVILLE L ESNEVAL,76280,ANGLESQUEVILLE L ESNEVAL,,49.6304546019,0.235444303485,17,,Anglesqueville-l'Esneval,Anglesqueville-l'Esneval,76,Seine-Maritime,28,Normandie
76018,VAL DE SAANE,76890,VAL DE SAANE,,49.7084832857,0.966266484682,18,,Val-de-Saâne,Val-de-Saâne,76,Seine-Maritime,28,Normandie
76019,ANNEVILLE SUR SCIE,76590,ANNEVILLE SUR SCIE,,49.8386907699,1.09303231826,19,,Anneville-sur-Scie,Anneville-sur-Scie,76,Seine-Maritime,28,Normandie
76020,ANNEVILLE AMBOURVILLE,76480,ANNEVILLE AMBOURVILLE,,49.450044528,0.891983658071,20,,Anneville-Ambourville,Anneville-Ambourville,76,Seine-Maritime,28,Normandie
76020,ANNEVILLE AMBOURVILLE,76480,ANNEVILLE AMBOURVILLE,AMBOURVILLE,49.450044528,0.891983658071,20,,Anneville-Ambourville,Anneville-Ambourville,76,Seine-Maritime,28,Normandie
76021,ANNOUVILLE VILMESNIL,76110,ANNOUVILLE VILMESNIL,,49.6794638348,0.431675175085,21,,Annouville-Vilmesnil,Annouville-Vilmesnil,76,Seine-Maritime,28,Normandie
76022,ANQUETIERVILLE,76490,ANQUETIERVILLE,,49.5363169279,0.632948005781,22,,Anquetierville,Anquetierville,76,Seine-Maritime,28,Normandie
76023,ANVEVILLE,76560,ANVEVILLE,,49.6996514081,0.748889414499,23,,Anvéville,Anvéville,76,Seine-Maritime,28,Normandie
76024,ARDOUVAL,76680,ARDOUVAL,,49.7409130486,1.27312376104,24,,Ardouval,Ardouval,76,Seine-Maritime,28,Normandie
76025,ARGUEIL,76780,ARGUEIL,,49.5459280867,1.52572338915,25,,Argueil,Argueil,76,Seine-Maritime,28,Normandie
76026,ARQUES LA BATAILLE,76880,ARQUES LA BATAILLE,,49.8821767195,1.1367858461,26,,Arques-la-Bataille,Arques-la-Bataille,76,Seine-Maritime,28,Normandie
76028,AUBEGUIMONT,76390,AUBEGUIMONT,,49.8094901556,1.67974843453,28,,Aubéguimont,Aubéguimont,76,Seine-Maritime,28,Normandie
76029,AUBERMESNIL AUX ERABLES,76340,AUBERMESNIL AUX ERABLES,,49.807630976,1.56837335546,29,,Aubermesnil-aux-Érables,Aubermesnil-aux-Érables,76,Seine-Maritime,28,Normandie
76030,AUBERMESNIL BEAUMAIS,76550,AUBERMESNIL BEAUMAIS,,49.847513728,1.12124254365,30,,Aubermesnil-Beaumais,Aubermesnil-Beaumais,76,Seine-Maritime,28,Normandie
76032,AUBERVILLE LA MANUEL,76450,AUBERVILLE LA MANUEL,,49.8291736749,0.584161454904,32,,Auberville-la-Manuel,Auberville-la-Manuel,76,Seine-Maritime,28,Normandie
76033,AUBERVILLE LA RENAULT,76110,AUBERVILLE LA RENAULT,,49.6859567281,0.37918269349,33,,Auberville-la-Renault,Auberville-la-Renault,76,Seine-Maritime,28,Normandie
76034,AUFFAY,76720,AUFFAY,,49.7124656757,1.11303892505,34,,Auffay,Auffay,76,Seine-Maritime,28,Normandie
76035,AUMALE,76390,AUMALE,,49.7693741515,1.7549467172,35,,Aumale,Aumale,76,Seine-Maritime,28,Normandie
76036,AUPPEGARD,76730,AUPPEGARD,,49.8306826854,1.03085104326,36,,Auppegard,Auppegard,76,Seine-Maritime,28,Normandie
76038,AUTHIEUX RATIEVILLE,76690,AUTHIEUX RATIEVILLE,,49.5979881913,1.14626265264,38,,Authieux-Ratiéville,Authieux-Ratiéville,76,Seine-Maritime,28,Normandie
76039,LES AUTHIEUX SUR LE PORT ST OUEN,76520,LES AUTHIEUX SUR LE PORT ST OUEN,,49.338554973,1.13287875281,39,Les,Authieux-sur-le-Port-Saint-Ouen,Les Authieux-sur-le-Port-Saint-Ouen,76,Seine-Maritime,28,Normandie
76040,AUTIGNY,76740,AUTIGNY,,49.7958346368,0.854290024951,40,,Autigny,Autigny,76,Seine-Maritime,28,Normandie
76041,AUTRETOT,76190,AUTRETOT,,49.6553610045,0.7278467274,41,,Autretot,Autretot,76,Seine-Maritime,28,Normandie
76042,AUVILLIERS,76270,AUVILLIERS,,49.7577790499,1.57468218084,42,,Auvilliers,Auvilliers,76,Seine-Maritime,28,Normandie
76043,AUZEBOSC,76190,AUZEBOSC,,49.6013719702,0.733647175328,43,,Auzebosc,Auzebosc,76,Seine-Maritime,28,Normandie
76044,AUZOUVILLE AUBERBOSC,76640,AUZOUVILLE AUBERBOSC,,49.6305934596,0.573467844069,44,,Auzouville-Auberbosc,Auzouville-Auberbosc,76,Seine-Maritime,28,Normandie
76045,AUZOUVILLE L ESNEVAL,76760,AUZOUVILLE L ESNEVAL,,49.6332091755,0.883721965362,45,,Auzouville-l'Esneval,Auzouville-l'Esneval,76,Seine-Maritime,28,Normandie
76046,AUZOUVILLE SUR RY,76116,AUZOUVILLE SUR RY,,49.4374386214,1.30966530446,46,,Auzouville-sur-Ry,Auzouville-sur-Ry,76,Seine-Maritime,28,Normandie
76047,AUZOUVILLE SUR SAANE,76730,AUZOUVILLE SUR SAANE,,49.741963291,0.931837478003,47,,Auzouville-sur-Saâne,Auzouville-sur-Saâne,76,Seine-Maritime,28,Normandie
76048,AVESNES EN BRAY,76220,AVESNES EN BRAY,,49.4729560759,1.67584280631,48,,Avesnes-en-Bray,Avesnes-en-Bray,76,Seine-Maritime,28,Normandie
76049,AVESNES EN VAL,76630,AVESNES EN VAL,,49.9178206413,1.40811370828,49,,Avesnes-en-Val,Avesnes-en-Val,76,Seine-Maritime,28,Normandie
76050,AVREMESNIL,76730,AVREMESNIL,,49.8501863679,0.932942833236,50,,Avremesnil,Avremesnil,76,Seine-Maritime,28,Normandie
76051,BACQUEVILLE EN CAUX,76730,BACQUEVILLE EN CAUX,,49.7964859103,1.0104212782,51,,Bacqueville-en-Caux,Bacqueville-en-Caux,76,Seine-Maritime,28,Normandie
76052,BAILLEUL NEUVILLE,76660,BAILLEUL NEUVILLE,,49.8096848103,1.42191745617,52,,Bailleul-Neuville,Bailleul-Neuville,76,Seine-Maritime,28,Normandie
76053,BAILLOLET,76660,BAILLOLET,,49.7896330742,1.43139966694,53,,Baillolet,Baillolet,76,Seine-Maritime,28,Normandie
76054,BAILLY EN RIVIERE,76630,BAILLY EN RIVIERE,,49.9079295518,1.35035424549,54,,Bailly-en-Rivière,Bailly-en-Rivière,76,Seine-Maritime,28,Normandie
76055,BAONS LE COMTE,76190,BAONS LE COMTE,,49.639081738,0.760973949866,55,,Baons-le-Comte,Baons-le-Comte,76,Seine-Maritime,28,Normandie
76056,BARDOUVILLE,76480,BARDOUVILLE,,49.4336284881,0.923414298372,56,,Bardouville,Bardouville,76,Seine-Maritime,28,Normandie
76057,BARENTIN,76360,BARENTIN,,49.5454370326,0.957011967644,57,,Barentin,Barentin,76,Seine-Maritime,28,Normandie
76058,BAROMESNIL,76260,BAROMESNIL,,49.9852128551,1.41261701359,58,,Baromesnil,Baromesnil,76,Seine-Maritime,28,Normandie
76059,BAZINVAL,76340,BAZINVAL,,49.9483550946,1.54755894168,59,,Bazinval,Bazinval,76,Seine-Maritime,28,Normandie
76060,BEAUBEC LA ROSIERE,76440,BEAUBEC LA ROSIERE,,49.6469045876,1.51422263037,60,,Beaubec-la-Rosière,Beaubec-la-Rosière,76,Seine-Maritime,28,Normandie
76062,BEAUMONT LE HARENG,76850,BEAUMONT LE HARENG,,49.6634230992,1.22007480848,62,,Beaumont-le-Hareng,Beaumont-le-Hareng,76,Seine-Maritime,28,Normandie
76063,BEAUVAL EN CAUX,76890,BEAUVAL EN CAUX,,49.7392898442,1.02536395494,63,,Beauval-en-Caux,Beauval-en-Caux,76,Seine-Maritime,28,Normandie
76064,BEAUREPAIRE,76280,BEAUREPAIRE,,49.6578498142,0.218162169201,64,,Beaurepaire,Beaurepaire,76,Seine-Maritime,28,Normandie
76065,BEAUSSAULT,76870,BEAUSSAULT,,49.6894037709,1.57537767873,65,,Beaussault,Beaussault,76,Seine-Maritime,28,Normandie
76066,BEAUTOT,76890,BEAUTOT,,49.63626441,1.04779451017,66,,Beautot,Beautot,76,Seine-Maritime,28,Normandie
76067,BEAUVOIR EN LYONS,76220,BEAUVOIR EN LYONS,,49.484261541,1.58305968488,67,,Beauvoir-en-Lyons,Beauvoir-en-Lyons,76,Seine-Maritime,28,Normandie
76068,BEC DE MORTAGNE,76110,BEC DE MORTAGNE,,49.7046190137,0.449001538554,68,,Bec-de-Mortagne,Bec-de-Mortagne,76,Seine-Maritime,28,Normandie
76069,BELBEUF,76240,BELBEUF,,49.3848813848,1.13840658151,69,,Belbeuf,Belbeuf,76,Seine-Maritime,28,Normandie
76070,BELLENCOMBRE,76680,BELLENCOMBRE,,49.7178549713,1.23609035774,70,,Bellencombre,Bellencombre,76,Seine-Maritime,28,Normandie
76071,BELLENGREVILLE,76630,BELLENGREVILLE,,49.9107866159,1.22780682049,71,,Bellengreville,Bellengreville,76,Seine-Maritime,28,Normandie
76072,BELLEVILLE EN CAUX,76890,BELLEVILLE EN CAUX,,49.7043949749,0.988901362095,72,,Belleville-en-Caux,Belleville-en-Caux,76,Seine-Maritime,28,Normandie
76074,LA BELLIERE,76440,LA BELLIERE,,49.6051086109,1.60262012686,74,La,Bellière,La Bellière,76,Seine-Maritime,28,Normandie
76075,BELMESNIL,76590,BELMESNIL,,49.7683532311,1.05014790136,75,,Belmesnil,Belmesnil,76,Seine-Maritime,28,Normandie
76076,BENARVILLE,76110,BENARVILLE,,49.6724716321,0.484474262834,76,,Bénarville,Bénarville,76,Seine-Maritime,28,Normandie
76077,BENESVILLE,76560,BENESVILLE,,49.7517164964,0.828144576549,77,,Bénesville,Bénesville,76,Seine-Maritime,28,Normandie
76078,BENNETOT,76640,BENNETOT,,49.6780245064,0.556920859714,78,,Bennetot,Bennetot,76,Seine-Maritime,28,Normandie
76079,BENOUVILLE,76790,BENOUVILLE,,49.7173340214,0.246125602871,79,,Bénouville,Bénouville,76,Seine-Maritime,28,Normandie
76080,BERMONVILLE,76640,BERMONVILLE,,49.6414676577,0.639221457048,80,,Bermonville,Bermonville,76,Seine-Maritime,28,Normandie
76082,BERNIERES,76210,BERNIERES,,49.6223112672,0.4774389585,82,,Bernières,Bernières,76,Seine-Maritime,28,Normandie
76083,BERTHEAUVILLE,76450,BERTHEAUVILLE,,49.7550523424,0.591607213489,83,,Bertheauville,Bertheauville,76,Seine-Maritime,28,Normandie
76084,BERTREVILLE,76450,BERTREVILLE,,49.7666175851,0.580078332797,84,,Bertreville,Bertreville,76,Seine-Maritime,28,Normandie
76085,BERTREVILLE ST OUEN,76590,BERTREVILLE ST OUEN,,49.8123112354,1.04321401128,85,,Bertreville-Saint-Ouen,Bertreville-Saint-Ouen,76,Seine-Maritime,28,Normandie
76086,BERTRIMONT,76890,BERTRIMONT,,49.6700988872,0.997521273823,86,,Bertrimont,Bertrimont,76,Seine-Maritime,28,Normandie
76087,BERVILLE,76560,BERVILLE,,49.7125106385,0.837054229311,87,,Berville-en-Caux,Berville-en-Caux,76,Seine-Maritime,28,Normandie
76088,BERVILLE SUR SEINE,76480,BERVILLE SUR SEINE,,49.4743258716,0.894809841877,88,,Berville-sur-Seine,Berville-sur-Seine,76,Seine-Maritime,28,Normandie
76090,BEUZEVILLE LA GRENIER,76210,BEUZEVILLE LA GRENIER,,49.5921780115,0.421311300104,90,,Beuzeville-la-Grenier,Beuzeville-la-Grenier,76,Seine-Maritime,28,Normandie
76091,BEUZEVILLE LA GUERARD,76450,BEUZEVILLE LA GUERARD,,49.7099645426,0.612884934109,91,,Beuzeville-la-Guérard,Beuzeville-la-Guérard,76,Seine-Maritime,28,Normandie
76092,BEUZEVILLETTE,76210,BEUZEVILLETTE,,49.5754066385,0.54775977113,92,,Beuzevillette,Beuzevillette,76,Seine-Maritime,28,Normandie
76093,BEZANCOURT,76220,BEZANCOURT,,49.4353415465,1.61372174979,93,,Bézancourt,Bézancourt,76,Seine-Maritime,28,Normandie
76094,BIERVILLE,76750,BIERVILLE,,49.5360436591,1.27429073498,94,,Bierville,Bierville,76,Seine-Maritime,28,Normandie
76095,BIHOREL,76420,BIHOREL,,,,95,,Bihorel,Bihorel,76,Seine-Maritime,28,Normandie
76096,BIVILLE LA BAIGNARDE,76890,BIVILLE LA BAIGNARDE,,49.7215955868,1.04761911483,96,,Biville-la-Baignarde,Biville-la-Baignarde,76,Seine-Maritime,28,Normandie
76097,BIVILLE LA RIVIERE,76730,BIVILLE LA RIVIERE,,49.775723221,0.920460711671,97,,Biville-la-Rivière,Biville-la-Rivière,76,Seine-Maritime,28,Normandie
76099,BLACQUEVILLE,76190,BLACQUEVILLE,,49.5573634495,0.848501057817,99,,Blacqueville,Blacqueville,76,Seine-Maritime,28,Normandie
76100,BLAINVILLE CREVON,76116,BLAINVILLE CREVON,,49.504416715,1.29186032794,100,,Blainville-Crevon,Blainville-Crevon,76,Seine-Maritime,28,Normandie
76101,BLANGY SUR BRESLE,76340,BLANGY SUR BRESLE,,49.9167725576,1.62061411438,101,,Blangy-sur-Bresle,Blangy-sur-Bresle,76,Seine-Maritime,28,Normandie
76103,BONSECOURS,76240,BONSECOURS,,49.4260269307,1.12943386715,103,,Bonsecours,Bonsecours,76,Seine-Maritime,28,Normandie
76104,BLOSSEVILLE,76460,BLOSSEVILLE,,49.8517136322,0.801917356304,104,,Blosseville,Blosseville,76,Seine-Maritime,28,Normandie
76105,LE BOCASSE,76690,LE BOCASSE,,49.6088838198,1.0698762203,105,Le,Bocasse,Le Bocasse,76,Seine-Maritime,28,Normandie
76106,BOIS D ENNEBOURG,76160,BOIS D ENNEBOURG,,49.4276209664,1.25861486789,106,,Bois-d'Ennebourg,Bois-d'Ennebourg,76,Seine-Maritime,28,Normandie
76107,BOIS GUILBERT,76750,BOIS GUILBERT,,49.5402657888,1.41428149817,107,,Bois-Guilbert,Bois-Guilbert,76,Seine-Maritime,28,Normandie
76108,BOIS GUILLAUME,76230,BOIS GUILLAUME,,49.4717615714,1.12022637351,108,,Bois-Guillaume,Bois-Guillaume,76,Seine-Maritime,28,Normandie
76109,BOIS HEROULT,76750,BOIS HEROULT,,49.5643900372,1.4075026333,109,,Bois-Héroult,Bois-Héroult,76,Seine-Maritime,28,Normandie
76110,BOIS HIMONT,76190,BOIS HIMONT,,49.5806871028,0.691431007204,110,,Bois-Himont,Bois-Himont,76,Seine-Maritime,28,Normandie
76111,BOIS L EVEQUE,76160,BOIS L EVEQUE,,49.4524985039,1.25560190393,111,,Bois-l'Évêque,Bois-l'Évêque,76,Seine-Maritime,28,Normandie
76112,LE BOIS ROBERT,76590,LE BOIS ROBERT,,49.8380335572,1.14914842733,112,Le,Bois-Robert,Le Bois-Robert,76,Seine-Maritime,28,Normandie
76113,BOISSAY,76750,BOISSAY,,49.5184469464,1.3599768113,113,,Boissay,Boissay,76,Seine-Maritime,28,Normandie
76114,BOLBEC,76210,BOLBEC,,49.5754807674,0.484404802201,114,,Bolbec,Bolbec,76,Seine-Maritime,28,Normandie
76115,BOLLEVILLE,76210,BOLLEVILLE,,49.6028335205,0.563968438854,115,,Bolleville,Bolleville,76,Seine-Maritime,28,Normandie
76116,BOOS,76520,BOOS,,49.3807198893,1.20294822782,116,,Boos,Boos,76,Seine-Maritime,28,Normandie
76117,BORDEAUX ST CLAIR,76790,BORDEAUX ST CLAIR,,49.697601016,0.242239200327,117,,Bordeaux-Saint-Clair,Bordeaux-Saint-Clair,76,Seine-Maritime,28,Normandie
76118,BORNAMBUSC,76110,BORNAMBUSC,,49.625546295,0.353284639397,118,,Bornambusc,Bornambusc,76,Seine-Maritime,28,Normandie
76119,BOSC BERENGER,76680,BOSC BERENGER,,49.6345939152,1.253580255,119,,Bosc-Bérenger,Bosc-Bérenger,76,Seine-Maritime,28,Normandie
76120,BOSC BORDEL,76750,BOSC BORDEL,,49.6049218358,1.40929431923,120,,Bosc-Bordel,Bosc-Bordel,76,Seine-Maritime,28,Normandie
76121,BOSC EDELINE,76750,BOSC EDELINE,,49.5743992317,1.42966771181,121,,Bosc-Édeline,Bosc-Édeline,76,Seine-Maritime,28,Normandie
76122,CALLENGEVILLE,76270,CALLENGEVILLE,,49.8240856066,1.52206018014,122,,Callengeville,Callengeville,76,Seine-Maritime,28,Normandie
76122,CALLENGEVILLE,76270,CALLENGEVILLE,LES ESSARTS VARIMPRE,49.8240856066,1.52206018014,122,,Callengeville,Callengeville,76,Seine-Maritime,28,Normandie
76123,BOSC GUERARD ST ADRIEN,76710,BOSC GUERARD ST ADRIEN,,49.5411821523,1.12523035635,123,,Bosc-Guérard-Saint-Adrien,Bosc-Guérard-Saint-Adrien,76,Seine-Maritime,28,Normandie
76124,BOSC HYONS,76220,BOSC HYONS,,49.452101199,1.64984225358,124,,Bosc-Hyons,Bosc-Hyons,76,Seine-Maritime,28,Normandie
76125,BOSC LE HARD,76850,BOSC LE HARD,,49.6217325927,1.18584148284,125,,Bosc-le-Hard,Bosc-le-Hard,76,Seine-Maritime,28,Normandie
76126,BOSC MESNIL,76680,BOSC MESNIL,,49.6687076974,1.3483005444,126,,Bosc-Mesnil,Bosc-Mesnil,76,Seine-Maritime,28,Normandie
76127,BOSC ROGER SUR BUCHY,76750,BOSC ROGER SUR BUCHY,,49.5874873321,1.37611375286,127,,Bosc-Roger-sur-Buchy,Bosc-Roger-sur-Buchy,76,Seine-Maritime,28,Normandie
76128,BOSVILLE,76450,BOSVILLE,,49.7629078501,0.684228513933,128,,Bosville,Bosville,76,Seine-Maritime,28,Normandie
76129,BOUDEVILLE,76560,BOUDEVILLE,,49.7200445061,0.874466208058,129,,Boudeville,Boudeville,76,Seine-Maritime,28,Normandie
76130,BOUELLES,76270,BOUELLES,,49.7223152226,1.49218753372,130,,Bouelles,Bouelles,76,Seine-Maritime,28,Normandie
76131,LA BOUILLE,76530,LA BOUILLE,,49.3502404856,0.933749684958,131,La,Bouille,La Bouille,76,Seine-Maritime,28,Normandie
76132,BOURDAINVILLE,76760,BOURDAINVILLE,,49.6729057578,0.940094590995,132,,Bourdainville,Bourdainville,76,Seine-Maritime,28,Normandie
76133,LE BOURG DUN,76740,LE BOURG DUN,,49.8702714225,0.892619905742,133,Le,Bourg-Dun,Le Bourg-Dun,76,Seine-Maritime,28,Normandie
76134,BOURVILLE,76740,BOURVILLE,,49.795809684,0.82372418387,134,,Bourville,Bourville,76,Seine-Maritime,28,Normandie
76135,BOUVILLE,76360,BOUVILLE,,49.5571157424,0.894771210458,135,,Bouville,Bouville,76,Seine-Maritime,28,Normandie
76136,BRACHY,76730,BRACHY,,49.8165894771,0.948902356331,136,,Brachy,Brachy,76,Seine-Maritime,28,Normandie
76138,BRACQUETUIT,76850,BRACQUETUIT,,49.6646732436,1.16974205815,138,,Bracquetuit,Bracquetuit,76,Seine-Maritime,28,Normandie
76139,BRADIANCOURT,76680,BRADIANCOURT,,49.6602057155,1.38522083662,139,,Bradiancourt,Bradiancourt,76,Seine-Maritime,28,Normandie
76140,BRAMETOT,76740,BRAMETOT,,49.7820847663,0.869852926704,140,,Brametot,Brametot,76,Seine-Maritime,28,Normandie
76141,BREAUTE,76110,BREAUTE,,49.6258979789,0.403765077716,141,,Bréauté,Bréauté,76,Seine-Maritime,28,Normandie
76142,BREMONTIER MERVAL,76220,BREMONTIER MERVAL,,49.5143282668,1.6171922011,142,,Brémontier-Merval,Brémontier-Merval,76,Seine-Maritime,28,Normandie
76143,BRETTEVILLE DU GRAND CAUX,76110,BRETTEVILLE DU GRAND CAUX,,49.6653391098,0.385967145097,143,,Bretteville-du-Grand-Caux,Bretteville-du-Grand-Caux,76,Seine-Maritime,28,Normandie
76144,BRETTEVILLE ST LAURENT,76560,BRETTEVILLE ST LAURENT,,49.7662190026,0.869259954301,144,,Bretteville-Saint-Laurent,Bretteville-Saint-Laurent,76,Seine-Maritime,28,Normandie
76146,BUCHY,76750,BUCHY,,49.5894942474,1.35094714044,146,,Buchy,Buchy,76,Seine-Maritime,28,Normandie
76147,BULLY,76270,BULLY,,49.7208864104,1.35895678494,147,,Bully,Bully,76,Seine-Maritime,28,Normandie
76148,BURES EN BRAY,76660,BURES EN BRAY,,49.7792273444,1.33526447896,148,,Bures-en-Bray,Bures-en-Bray,76,Seine-Maritime,28,Normandie
76149,BUTOT,76890,BUTOT,,49.6132808919,1.0198441688,149,,Butot,Butot,76,Seine-Maritime,28,Normandie
76151,CAILLEVILLE,76460,CAILLEVILLE,,49.8364501971,0.736017979248,151,,Cailleville,Cailleville,76,Seine-Maritime,28,Normandie
76152,CAILLY,76690,CAILLY,,49.5861650281,1.22979848856,152,,Cailly,Cailly,76,Seine-Maritime,28,Normandie
76153,CALLEVILLE LES DEUX EGLISES,76890,CALLEVILLE LES DEUX EGLISES,,49.7036168494,1.02150696093,153,,Calleville-les-Deux-Églises,Calleville-les-Deux-Églises,76,Seine-Maritime,28,Normandie
76154,CAMPNEUSEVILLE,76340,CAMPNEUSEVILLE,,49.8621259676,1.66261477222,154,,Campneuseville,Campneuseville,76,Seine-Maritime,28,Normandie
76155,CANEHAN,76260,CANEHAN,,49.9795081711,1.34311716652,155,,Canehan,Canehan,76,Seine-Maritime,28,Normandie
76156,CANOUVILLE,76450,CANOUVILLE,,49.8064199555,0.597683771595,156,,Canouville,Canouville,76,Seine-Maritime,28,Normandie
76157,CANTELEU,76380,CANTELEU,,49.4330992435,1.01355208279,157,,Canteleu,Canteleu,76,Seine-Maritime,28,Normandie
76157,CANTELEU,76380,CANTELEU,BAPEAUME LES ROUEN,49.4330992435,1.01355208279,157,,Canteleu,Canteleu,76,Seine-Maritime,28,Normandie
76157,CANTELEU,76380,CANTELEU,DIEPPEDALLE CROISSET,49.4330992435,1.01355208279,157,,Canteleu,Canteleu,76,Seine-Maritime,28,Normandie
76158,CANVILLE LES DEUX EGLISES,76560,CANVILLE LES DEUX EGLISES,,49.7745209331,0.836317584091,158,,Canville-les-Deux-Églises,Canville-les-Deux-Églises,76,Seine-Maritime,28,Normandie
76159,CANY BARVILLE,76450,CANY BARVILLE,,49.7754431249,0.633291896241,159,,Cany-Barville,Cany-Barville,76,Seine-Maritime,28,Normandie
76160,CARVILLE LA FOLLETIERE,76190,CARVILLE LA FOLLETIERE,,49.5564929609,0.816709773757,160,,Carville-la-Folletière,Carville-la-Folletière,76,Seine-Maritime,28,Normandie
76161,CARVILLE POT DE FER,76560,CARVILLE POT DE FER,,49.7186680697,0.723155038419,161,,Carville-Pot-de-Fer,Carville-Pot-de-Fer,76,Seine-Maritime,28,Normandie
76162,LE CATELIER,76590,LE CATELIER,,49.757012277,1.15667476755,162,Le,Catelier,Le Catelier,76,Seine-Maritime,28,Normandie
76163,CATENAY,76116,CATENAY,,49.5106807185,1.32664847368,163,,Catenay,Catenay,76,Seine-Maritime,28,Normandie
76164,RIVES EN SEINE,76490,RIVES EN SEINE,CAUDEBEC EN CAUX,49.5305126235,0.734830682082,164,,Rives-en-Seine,Rives-en-Seine,76,Seine-Maritime,28,Normandie
76164,RIVES EN SEINE,76490,RIVES EN SEINE,ST WANDRILLE RANCON,49.5305126235,0.734830682082,164,,Rives-en-Seine,Rives-en-Seine,76,Seine-Maritime,28,Normandie
76164,RIVES EN SEINE,76490,RIVES EN SEINE,VILLEQUIER,49.5305126235,0.734830682082,164,,Rives-en-Seine,Rives-en-Seine,76,Seine-Maritime,28,Normandie
76165,CAUDEBEC LES ELBEUF,76320,CAUDEBEC LES ELBEUF,,49.2820542128,1.02549551616,165,,Caudebec-lès-Elbeuf,Caudebec-lès-Elbeuf,76,Seine-Maritime,28,Normandie
76166,LE CAULE STE BEUVE,76390,LE CAULE STE BEUVE,,49.7755550599,1.59259694221,166,Le,Caule-Sainte-Beuve,Le Caule-Sainte-Beuve,76,Seine-Maritime,28,Normandie
76167,CAUVILLE SUR MER,76930,CAUVILLE SUR MER,,49.593844191,0.144981167051,167,,Cauville-sur-Mer,Cauville-sur-Mer,76,Seine-Maritime,28,Normandie
76168,LES CENT ACRES,76590,LES CENT ACRES,,49.7712128212,1.14121021088,168,Les,Cent-Acres,Les Cent-Acres,76,Seine-Maritime,28,Normandie
76169,LA CERLANGUE,76430,LA CERLANGUE,,49.4885084321,0.415722968132,169,La,Cerlangue,La Cerlangue,76,Seine-Maritime,28,Normandie
76170,LA CHAPELLE DU BOURGAY,76590,LA CHAPELLE DU BOURGAY,,49.8222579766,1.14610175636,170,La,Chapelle-du-Bourgay,La Chapelle-du-Bourgay,76,Seine-Maritime,28,Normandie
76171,LA CHAPELLE ST OUEN,76780,LA CHAPELLE ST OUEN,,49.521909857,1.42852895013,171,La,Chapelle-Saint-Ouen,La Chapelle-Saint-Ouen,76,Seine-Maritime,28,Normandie
76172,LA CHAPELLE SUR DUN,76740,LA CHAPELLE SUR DUN,,49.8577948391,0.845890538309,172,La,Chapelle-sur-Dun,La Chapelle-sur-Dun,76,Seine-Maritime,28,Normandie
76173,LA CHAUSSEE,76590,LA CHAUSSEE,,49.818475141,1.11848642712,173,La,Chaussée,La Chaussée,76,Seine-Maritime,28,Normandie
76174,CIDEVILLE,76570,CIDEVILLE,,49.6117818169,0.888359643121,174,,Cideville,Cideville,76,Seine-Maritime,28,Normandie
76175,CLAIS,76660,CLAIS,,49.8139194785,1.47363845661,175,,Clais,Clais,76,Seine-Maritime,28,Normandie
76176,CLASVILLE,76450,CLASVILLE,,49.7932822426,0.620981187192,176,,Clasville,Clasville,76,Seine-Maritime,28,Normandie
76177,CLAVILLE MOTTEVILLE,76690,CLAVILLE MOTTEVILLE,,49.5889638723,1.18573450148,177,,Claville-Motteville,Claville-Motteville,76,Seine-Maritime,28,Normandie
76178,CLEON,76410,CLEON,,49.316254089,1.03978678909,178,,Cléon,Cléon,76,Seine-Maritime,28,Normandie
76179,CLERES,76690,CLERES,,49.5912266795,1.10310854217,179,,Clères,Clères,76,Seine-Maritime,28,Normandie
76180,CLEUVILLE,76450,CLEUVILLE,,49.7035324199,0.647945239097,180,,Cleuville,Cleuville,76,Seine-Maritime,28,Normandie
76181,CLEVILLE,76640,CLEVILLE,,49.6224516672,0.615947433679,181,,Cléville,Cléville,76,Seine-Maritime,28,Normandie
76182,CLIPONVILLE,76640,CLIPONVILLE,,49.6734734351,0.658116759425,182,,Cliponville,Cliponville,76,Seine-Maritime,28,Normandie
76183,COLLEVILLE,76400,COLLEVILLE,,49.7521780135,0.456999133125,183,,Colleville,Colleville,76,Seine-Maritime,28,Normandie
76184,COLMESNIL MANNEVILLE,76550,COLMESNIL MANNEVILLE,,49.8474747525,1.02876696183,184,,Colmesnil-Manneville,Colmesnil-Manneville,76,Seine-Maritime,28,Normandie
76185,COMPAINVILLE,76440,COMPAINVILLE,,49.6584559253,1.55975228232,185,,Compainville,Compainville,76,Seine-Maritime,28,Normandie
76186,CONTEVILLE,76390,CONTEVILLE,,49.6966662324,1.63795618701,186,,Conteville,Conteville,76,Seine-Maritime,28,Normandie
76187,CONTREMOULINS,76400,CONTREMOULINS,,49.7245988757,0.437780709173,187,,Contremoulins,Contremoulins,76,Seine-Maritime,28,Normandie
76188,COTTEVRARD,76850,COTTEVRARD,,49.639544,1.22434473478,188,,Cottévrard,Cottévrard,76,Seine-Maritime,28,Normandie
76189,CRASVILLE LA MALLET,76450,CRASVILLE LA MALLET,,49.8050247312,0.716586925747,189,,Crasville-la-Mallet,Crasville-la-Mallet,76,Seine-Maritime,28,Normandie
76190,CRASVILLE LA ROCQUEFORT,76740,CRASVILLE LA ROCQUEFORT,,49.8041521575,0.877451277489,190,,Crasville-la-Rocquefort,Crasville-la-Rocquefort,76,Seine-Maritime,28,Normandie
76191,CRESSY,76720,CRESSY,,49.7248563124,1.16111679423,191,,Cressy,Cressy,76,Seine-Maritime,28,Normandie
76192,CRIEL SUR MER,76910,CRIEL SUR MER,,50.0191932623,1.32368263071,192,,Criel-sur-Mer,Criel-sur-Mer,76,Seine-Maritime,28,Normandie
76192,CRIEL SUR MER,76910,CRIEL SUR MER,MESNIL VAL,50.0191932623,1.32368263071,192,,Criel-sur-Mer,Criel-sur-Mer,76,Seine-Maritime,28,Normandie
76193,LA CRIQUE,76850,LA CRIQUE,,49.6927272245,1.20350778809,193,La,Crique,La Crique,76,Seine-Maritime,28,Normandie
76194,CRIQUEBEUF EN CAUX,76111,CRIQUEBEUF EN CAUX,,49.7396135117,0.333053861202,194,,Criquebeuf-en-Caux,Criquebeuf-en-Caux,76,Seine-Maritime,28,Normandie
76195,CRIQUETOT LE MAUCONDUIT,76540,CRIQUETOT LE MAUCONDUIT,,49.7901620657,0.562175801108,195,,Criquetot-le-Mauconduit,Criquetot-le-Mauconduit,76,Seine-Maritime,28,Normandie
76196,CRIQUETOT L ESNEVAL,76280,CRIQUETOT L ESNEVAL,,49.6368976314,0.267256823933,196,,Criquetot-l'Esneval,Criquetot-l'Esneval,76,Seine-Maritime,28,Normandie
76197,CRIQUETOT SUR LONGUEVILLE,76590,CRIQUETOT SUR LONGUEVILLE,,49.7816615167,1.07572750232,197,,Criquetot-sur-Longueville,Criquetot-sur-Longueville,76,Seine-Maritime,28,Normandie
76198,CRIQUETOT SUR OUVILLE,76760,CRIQUETOT SUR OUVILLE,,49.6716824681,0.849708916197,198,,Criquetot-sur-Ouville,Criquetot-sur-Ouville,76,Seine-Maritime,28,Normandie
76199,CRIQUIERS,76390,CRIQUIERS,,49.6760539694,1.70166139305,199,,Criquiers,Criquiers,76,Seine-Maritime,28,Normandie
76200,CRITOT,76680,CRITOT,,49.6148645766,1.24444787117,200,,Critot,Critot,76,Seine-Maritime,28,Normandie
76201,CROISY SUR ANDELLE,76780,CROISY SUR ANDELLE,,49.4594753468,1.40681724244,201,,Croisy-sur-Andelle,Croisy-sur-Andelle,76,Seine-Maritime,28,Normandie
76202,CROIXDALLE,76660,CROIXDALLE,,49.8027328398,1.36923442163,202,,Croixdalle,Croixdalle,76,Seine-Maritime,28,Normandie
76203,CROIX MARE,76190,CROIX MARE,,49.5978176144,0.837058838818,203,,Croix-Mare,Croix-Mare,76,Seine-Maritime,28,Normandie
76204,CROPUS,76720,CROPUS,,49.7369961062,1.14693756665,204,,Cropus,Cropus,76,Seine-Maritime,28,Normandie
76205,CROSVILLE SUR SCIE,76590,CROSVILLE SUR SCIE,,49.8193863387,1.08185669481,205,,Crosville-sur-Scie,Crosville-sur-Scie,76,Seine-Maritime,28,Normandie
76206,CUVERVILLE,76280,CUVERVILLE,,49.6655519661,0.264080881787,206,,Cuverville,Cuverville,76,Seine-Maritime,28,Normandie
76207,CUVERVILLE SUR YERES,76260,CUVERVILLE SUR YERES,,49.9515780673,1.38873648961,207,,Cuverville-sur-Yères,Cuverville-sur-Yères,76,Seine-Maritime,28,Normandie
76208,CUY ST FIACRE,76220,CUY ST FIACRE,,49.5106522893,1.69159638174,208,,Cuy-Saint-Fiacre,Cuy-Saint-Fiacre,76,Seine-Maritime,28,Normandie
76209,DAMPIERRE EN BRAY,76220,DAMPIERRE EN BRAY,,49.5345937963,1.66314096949,209,,Dampierre-en-Bray,Dampierre-en-Bray,76,Seine-Maritime,28,Normandie
76210,DAMPIERRE ST NICOLAS,76510,DAMPIERRE ST NICOLAS,,49.857280446,1.2036925117,210,,Dampierre-Saint-Nicolas,Dampierre-Saint-Nicolas,76,Seine-Maritime,28,Normandie
76211,DANCOURT,76340,DANCOURT,,49.900965616,1.53871070384,211,,Dancourt,Dancourt,76,Seine-Maritime,28,Normandie
76212,DARNETAL,76160,DARNETAL,,49.4445058359,1.15546994024,212,,Darnétal,Darnétal,76,Seine-Maritime,28,Normandie
76213,DAUBEUF SERVILLE,76110,DAUBEUF SERVILLE,,49.6960147715,0.481684076028,213,,Daubeuf-Serville,Daubeuf-Serville,76,Seine-Maritime,28,Normandie
76214,DENESTANVILLE,76590,DENESTANVILLE,,49.8076990948,1.0908205692,214,,Dénestanville,Dénestanville,76,Seine-Maritime,28,Normandie
76216,DEVILLE LES ROUEN,76250,DEVILLE LES ROUEN,,49.4661147987,1.0514305734,216,,Déville-lès-Rouen,Déville-lès-Rouen,76,Seine-Maritime,28,Normandie
76217,DIEPPE,76200,DIEPPE,,49.9222503951,1.08681168449,217,,Dieppe,Dieppe,76,Seine-Maritime,28,Normandie
76217,DIEPPE,76370,DIEPPE,,49.9222503951,1.08681168449,217,,Dieppe,Dieppe,76,Seine-Maritime,28,Normandie
76217,DIEPPE,76370,DIEPPE,NEUVILLE LES DIEPPE,49.9222503951,1.08681168449,217,,Dieppe,Dieppe,76,Seine-Maritime,28,Normandie
76218,DOUDEAUVILLE,76220,DOUDEAUVILLE,,49.5662160766,1.69896452487,218,,Doudeauville,Doudeauville,76,Seine-Maritime,28,Normandie
76219,DOUDEVILLE,76560,DOUDEVILLE,,49.7304839462,0.786724721323,219,,Doudeville,Doudeville,76,Seine-Maritime,28,Normandie
76220,DOUVREND,76630,DOUVREND,,49.8737591208,1.31959584474,220,,Douvrend,Douvrend,76,Seine-Maritime,28,Normandie
76221,DROSAY,76460,DROSAY,,49.7921683779,0.737326886541,221,,Drosay,Drosay,76,Seine-Maritime,28,Normandie
76222,DUCLAIR,76480,DUCLAIR,,49.4889814343,0.866671652498,222,,Duclair,Duclair,76,Seine-Maritime,28,Normandie
76223,ECALLES ALIX,76190,ECALLES ALIX,,49.6173418426,0.809761271567,223,,Écalles-Alix,Écalles-Alix,76,Seine-Maritime,28,Normandie
76224,ECRAINVILLE,76110,ECRAINVILLE,,49.646391607,0.313116494786,224,,Écrainville,Écrainville,76,Seine-Maritime,28,Normandie
76225,ECRETTEVILLE LES BAONS,76190,ECRETTEVILLE LES BAONS,,49.6297955951,0.67254078576,225,,Écretteville-lès-Baons,Écretteville-lès-Baons,76,Seine-Maritime,28,Normandie
76226,ECRETTEVILLE SUR MER,76540,ECRETTEVILLE SUR MER,,49.7907895888,0.48599605683,226,,Écretteville-sur-Mer,Écretteville-sur-Mer,76,Seine-Maritime,28,Normandie
76227,ECTOT L AUBER,76760,ECTOT L AUBER,,49.6547186988,0.929975857879,227,,Ectot-l'Auber,Ectot-l'Auber,76,Seine-Maritime,28,Normandie
76228,ECTOT LES BAONS,76970,ECTOT LES BAONS,,49.6439867808,0.808763505864,228,,Ectot-lès-Baons,Ectot-lès-Baons,76,Seine-Maritime,28,Normandie
76229,ELBEUF EN BRAY,76220,ELBEUF EN BRAY,,49.4938337874,1.65158974668,229,,Elbeuf-en-Bray,Elbeuf-en-Bray,76,Seine-Maritime,28,Normandie
76230,ELBEUF SUR ANDELLE,76780,ELBEUF SUR ANDELLE,,49.4777269045,1.38078318171,230,,Elbeuf-sur-Andelle,Elbeuf-sur-Andelle,76,Seine-Maritime,28,Normandie
76231,ELBEUF,76500,ELBEUF,,49.2767979948,0.997154590875,231,,Elbeuf,Elbeuf,76,Seine-Maritime,28,Normandie
76232,ELETOT,76540,ELETOT,,49.7897436329,0.457954579116,232,,Életot,Életot,76,Seine-Maritime,28,Normandie
76233,ELLECOURT,76390,ELLECOURT,,49.8012270823,1.72745453084,233,,Ellecourt,Ellecourt,76,Seine-Maritime,28,Normandie
76234,EMANVILLE,76570,EMANVILLE,,49.6220862325,0.961800445083,234,,Émanville,Émanville,76,Seine-Maritime,28,Normandie
76235,ENVERMEU,76630,ENVERMEU,,49.8982838543,1.26348988955,235,,Envermeu,Envermeu,76,Seine-Maritime,28,Normandie
76236,ENVRONVILLE,76640,ENVRONVILLE,,49.6585527629,0.670389261558,236,,Envronville,Envronville,76,Seine-Maritime,28,Normandie
76237,EPINAY SUR DUCLAIR,76480,EPINAY SUR DUCLAIR,,49.5253630622,0.835855173599,237,,Épinay-sur-Duclair,Épinay-sur-Duclair,76,Seine-Maritime,28,Normandie
76238,EPOUVILLE,76133,EPOUVILLE,,49.5632255927,0.23002991813,238,,Épouville,Épouville,76,Seine-Maritime,28,Normandie
76239,EPRETOT,76430,EPRETOT,,49.5381322435,0.313524869493,239,,Épretot,Épretot,76,Seine-Maritime,28,Normandie
76240,EPREVILLE,76400,EPREVILLE,,49.7051644136,0.367872929537,240,,Épreville,Épreville,76,Seine-Maritime,28,Normandie
76241,ERMENOUVILLE,76740,ERMENOUVILLE,,49.8040097414,0.783829373731,241,,Ermenouville,Ermenouville,76,Seine-Maritime,28,Normandie
76242,ERNEMONT LA VILLETTE,76220,ERNEMONT LA VILLETTE,,49.4548358379,1.71169097872,242,,Ernemont-la-Villette,Ernemont-la-Villette,76,Seine-Maritime,28,Normandie
76243,ERNEMONT SUR BUCHY,76750,ERNEMONT SUR BUCHY,,49.5412192034,1.36035800361,243,,Ernemont-sur-Buchy,Ernemont-sur-Buchy,76,Seine-Maritime,28,Normandie
76244,ESCLAVELLES,76270,ESCLAVELLES,,49.7050934258,1.38563696513,244,,Esclavelles,Esclavelles,76,Seine-Maritime,28,Normandie
76245,ESLETTES,76710,ESLETTES,,49.5475542482,1.04647568612,245,,Eslettes,Eslettes,76,Seine-Maritime,28,Normandie
76247,ESTEVILLE,76690,ESTEVILLE,,49.6091932343,1.21578651465,247,,Esteville,Esteville,76,Seine-Maritime,28,Normandie
76248,ESTOUTEVILLE ECALLES,76750,ESTOUTEVILLE ECALLES,,49.5868467071,1.30885834934,248,,Estouteville-Écalles,Estouteville-Écalles,76,Seine-Maritime,28,Normandie
76249,ETAIMPUIS,76850,ETAIMPUIS,,49.6398704816,1.14395186181,249,,Étaimpuis,Étaimpuis,76,Seine-Maritime,28,Normandie
76250,ETAINHUS,76430,ETAINHUS,,49.5667402554,0.309275010904,250,,Étainhus,Étainhus,76,Seine-Maritime,28,Normandie
76251,ETALLEVILLE,76560,ETALLEVILLE,,49.7326211245,0.825031387746,251,,Étalleville,Étalleville,76,Seine-Maritime,28,Normandie
76252,ETALONDES,76260,ETALONDES,,50.0327111921,1.389654721,252,,Étalondes,Étalondes,76,Seine-Maritime,28,Normandie
76253,ETOUTTEVILLE,76190,ETOUTTEVILLE,,49.6720581542,0.783187037988,253,,Étoutteville,Étoutteville,76,Seine-Maritime,28,Normandie
76254,ETRETAT,76790,ETRETAT,,49.704733151,0.205304793744,254,,Étretat,Étretat,76,Seine-Maritime,28,Normandie
76255,EU,76260,EU,,50.0413268784,1.42861757801,255,,Eu,Eu,76,Seine-Maritime,28,Normandie
76257,FALLENCOURT,76340,FALLENCOURT,,49.8641030613,1.56702701699,257,,Fallencourt,Fallencourt,76,Seine-Maritime,28,Normandie
76258,FAUVILLE EN CAUX,76640,FAUVILLE EN CAUX,,49.6570851688,0.584829133473,258,,Terres-de-Caux,Terres-de-Caux,76,Seine-Maritime,28,Normandie
76259,FECAMP,76400,FECAMP,,49.7523245306,0.395297587433,259,,Fécamp,Fécamp,76,Seine-Maritime,28,Normandie
76260,FERRIERES EN BRAY,76220,FERRIERES EN BRAY,,49.4862193398,1.75819614475,260,,Ferrières-en-Bray,Ferrières-en-Bray,76,Seine-Maritime,28,Normandie
76261,LA FERTE ST SAMSON,76440,LA FERTE ST SAMSON,,49.5772618796,1.5368911792,261,La,Ferté-Saint-Samson,La Ferté-Saint-Samson,76,Seine-Maritime,28,Normandie
76262,FESQUES,76270,FESQUES,,49.7904664266,1.48841081098,262,,Fesques,Fesques,76,Seine-Maritime,28,Normandie
76263,LA FEUILLIE,76220,LA FEUILLIE,,49.4651776839,1.52122263632,263,La,Feuillie,La Feuillie,76,Seine-Maritime,28,Normandie
76264,FLAMANVILLE,76970,FLAMANVILLE,,49.6312697717,0.832008310079,264,,Flamanville,Flamanville,76,Seine-Maritime,28,Normandie
76265,FLAMETS FRETILS,76270,FLAMETS FRETILS,,49.7256186457,1.58280844201,265,,Flamets-Frétils,Flamets-Frétils,76,Seine-Maritime,28,Normandie
76266,FLOCQUES,76260,FLOCQUES,,50.0348687665,1.36482281726,266,,Flocques,Flocques,76,Seine-Maritime,28,Normandie
76268,FONGUEUSEMARE,76280,FONGUEUSEMARE,,49.674874895,0.309350071633,268,,Fongueusemare,Fongueusemare,76,Seine-Maritime,28,Normandie
76269,FONTAINE EN BRAY,76440,FONTAINE EN BRAY,,49.6781243733,1.42208848927,269,,Fontaine-en-Bray,Fontaine-en-Bray,76,Seine-Maritime,28,Normandie
76270,FONTAINE LA MALLET,76290,FONTAINE LA MALLET,,49.5385360743,0.14056810162,270,,Fontaine-la-Mallet,Fontaine-la-Mallet,76,Seine-Maritime,28,Normandie
76271,FONTAINE LE BOURG,76690,FONTAINE LE BOURG,,49.5707934376,1.15722862543,271,,Fontaine-le-Bourg,Fontaine-le-Bourg,76,Seine-Maritime,28,Normandie
76272,FONTAINE LE DUN,76740,FONTAINE LE DUN,,49.8141727455,0.845673532596,272,,Fontaine-le-Dun,Fontaine-le-Dun,76,Seine-Maritime,28,Normandie
76273,FONTAINE SOUS PREAUX,76160,FONTAINE SOUS PREAUX,,49.485549107,1.16858781468,273,,Fontaine-sous-Préaux,Fontaine-sous-Préaux,76,Seine-Maritime,28,Normandie
76274,LA FONTELAYE,76890,LA FONTELAYE,,49.6940551078,0.94377185816,274,La,Fontelaye,La Fontelaye,76,Seine-Maritime,28,Normandie
76275,FONTENAY,76290,FONTENAY,,49.5698530615,0.178104990731,275,,Fontenay,Fontenay,76,Seine-Maritime,28,Normandie
76276,FORGES LES EAUX,76440,FORGES LES EAUX,,49.6117853364,1.53278752911,276,,Forges-les-Eaux,Forges-les-Eaux,76,Seine-Maritime,28,Normandie
76276,FORGES LES EAUX,76440,FORGES LES EAUX,LE FOSSE,49.6117853364,1.53278752911,276,,Forges-les-Eaux,Forges-les-Eaux,76,Seine-Maritime,28,Normandie
76278,FOUCARMONT,76340,FOUCARMONT,,49.8482534794,1.56380958774,278,,Foucarmont,Foucarmont,76,Seine-Maritime,28,Normandie
76279,FOUCART,76640,FOUCART,,49.6157923483,0.591746171294,279,,Foucart,Foucart,76,Seine-Maritime,28,Normandie
76280,FREAUVILLE,76660,FREAUVILLE,,49.8249551138,1.41696913742,280,,Fréauville,Fréauville,76,Seine-Maritime,28,Normandie
76281,LA FRENAYE,76170,LA FRENAYE,,49.5208360056,0.573461077843,281,La,Frénaye,La Frénaye,76,Seine-Maritime,28,Normandie
76282,FRENEUSE,76410,FRENEUSE,,49.3080967515,1.06803487468,282,,Freneuse,Freneuse,76,Seine-Maritime,28,Normandie
76283,FRESLES,76270,FRESLES,,49.7460490098,1.3435624982,283,,Fresles,Fresles,76,Seine-Maritime,28,Normandie
76284,FRESNAY LE LONG,76850,FRESNAY LE LONG,,49.6507422703,1.09305102633,284,,Fresnay-le-Long,Fresnay-le-Long,76,Seine-Maritime,28,Normandie
76285,FRESNE LE PLAN,76520,FRESNE LE PLAN,,49.4137813639,1.29661509425,285,,Fresne-le-Plan,Fresne-le-Plan,76,Seine-Maritime,28,Normandie
76286,FRESNOY FOLNY,76660,FRESNOY FOLNY,,49.887273332,1.43491902452,286,,Fresnoy-Folny,Fresnoy-Folny,76,Seine-Maritime,28,Normandie
76287,FRESQUIENNES,76570,FRESQUIENNES,,49.5624935908,1.01177567927,287,,Fresquiennes,Fresquiennes,76,Seine-Maritime,28,Normandie
76288,FREULLEVILLE,76510,FREULLEVILLE,,49.8188117486,1.21669243102,288,,Freulleville,Freulleville,76,Seine-Maritime,28,Normandie
76289,ST MARTIN DE L IF,76190,ST MARTIN DE L IF,BETTEVILLE,49.5651631452,0.833069408864,289,,Saint Martin de l'If,Saint Martin de l'If,76,Seine-Maritime,28,Normandie
76289,ST MARTIN DE L IF,76190,ST MARTIN DE L IF,FREVILLE,49.5651631452,0.833069408864,289,,Saint Martin de l'If,Saint Martin de l'If,76,Seine-Maritime,28,Normandie
76289,ST MARTIN DE L IF,76190,ST MARTIN DE L IF,LA FOLLETIERE,49.5651631452,0.833069408864,289,,Saint Martin de l'If,Saint Martin de l'If,76,Seine-Maritime,28,Normandie
76289,ST MARTIN DE L IF,76190,ST MARTIN DE L IF,MONT DE L IF,49.5651631452,0.833069408864,289,,Saint Martin de l'If,Saint Martin de l'If,76,Seine-Maritime,28,Normandie
76290,FRICHEMESNIL,76690,FRICHEMESNIL,,49.6203720713,1.13252706678,290,,Frichemesnil,Frichemesnil,76,Seine-Maritime,28,Normandie
76291,FROBERVILLE,76400,FROBERVILLE,,49.7150765853,0.335597363106,291,,Froberville,Froberville,76,Seine-Maritime,28,Normandie
76292,FRY,76780,FRY,,49.5265522704,1.53744439837,292,,Fry,Fry,76,Seine-Maritime,28,Normandie
76293,FULTOT,76560,FULTOT,,49.758857187,0.781793900129,293,,Fultot,Fultot,76,Seine-Maritime,28,Normandie
76294,LA GAILLARDE,76740,LA GAILLARDE,,49.8380148051,0.864208798219,294,La,Gaillarde,La Gaillarde,76,Seine-Maritime,28,Normandie
76295,GAILLEFONTAINE,76870,GAILLEFONTAINE,,49.6566343122,1.62674922737,295,,Gaillefontaine,Gaillefontaine,76,Seine-Maritime,28,Normandie
76296,GAINNEVILLE,76700,GAINNEVILLE,,49.5177433404,0.255469072963,296,,Gainneville,Gainneville,76,Seine-Maritime,28,Normandie
76297,GANCOURT ST ETIENNE,76220,GANCOURT ST ETIENNE,,49.5451067998,1.71017890285,297,,Gancourt-Saint-Étienne,Gancourt-Saint-Étienne,76,Seine-Maritime,28,Normandie
76298,GANZEVILLE,76400,GANZEVILLE,,49.7314996982,0.403637131189,298,,Ganzeville,Ganzeville,76,Seine-Maritime,28,Normandie
76299,GERPONVILLE,76540,GERPONVILLE,,49.7478029078,0.563807005364,299,,Gerponville,Gerponville,76,Seine-Maritime,28,Normandie
76300,GERVILLE,76790,GERVILLE,,49.697965438,0.328351503279,300,,Gerville,Gerville,76,Seine-Maritime,28,Normandie
76302,GODERVILLE,76110,GODERVILLE,,49.6443271273,0.355221850128,302,,Goderville,Goderville,76,Seine-Maritime,28,Normandie
76303,GOMMERVILLE,76430,GOMMERVILLE,,49.5524260707,0.359596092306,303,,Gommerville,Gommerville,76,Seine-Maritime,28,Normandie
76304,GONFREVILLE CAILLOT,76110,GONFREVILLE CAILLOT,,49.6520308002,0.440253516483,304,,Gonfreville-Caillot,Gonfreville-Caillot,76,Seine-Maritime,28,Normandie
76305,GONFREVILLE L ORCHER,76700,GONFREVILLE L ORCHER,,49.4860837457,0.221985693724,305,,Gonfreville-l'Orcher,Gonfreville-l'Orcher,76,Seine-Maritime,28,Normandie
76305,GONFREVILLE L ORCHER,76700,GONFREVILLE L ORCHER,GOURNAY EN CAUX,49.4860837457,0.221985693724,305,,Gonfreville-l'Orcher,Gonfreville-l'Orcher,76,Seine-Maritime,28,Normandie
76305,GONFREVILLE L ORCHER,76700,GONFREVILLE L ORCHER,MAYVILLE,49.4860837457,0.221985693724,305,,Gonfreville-l'Orcher,Gonfreville-l'Orcher,76,Seine-Maritime,28,Normandie
76306,GONNETOT,76730,GONNETOT,,49.7619135567,0.896605947173,306,,Gonnetot,Gonnetot,76,Seine-Maritime,28,Normandie
76307,GONNEVILLE LA MALLET,76280,GONNEVILLE LA MALLET,,49.6315125266,0.213546027187,307,,Gonneville-la-Mallet,Gonneville-la-Mallet,76,Seine-Maritime,28,Normandie
76308,GONNEVILLE SUR SCIE,76590,GONNEVILLE SUR SCIE,,49.7569130904,1.0867142722,308,,Gonneville-sur-Scie,Gonneville-sur-Scie,76,Seine-Maritime,28,Normandie
76309,GONZEVILLE,76560,GONZEVILLE,,49.7600318144,0.807790454575,309,,Gonzeville,Gonzeville,76,Seine-Maritime,28,Normandie
76311,GOUPILLIERES,76570,GOUPILLIERES,,49.5835705579,0.983266769608,311,,Goupillières,Goupillières,76,Seine-Maritime,28,Normandie
76312,GOURNAY EN BRAY,76220,GOURNAY EN BRAY,,49.4798020531,1.71814645212,312,,Gournay-en-Bray,Gournay-en-Bray,76,Seine-Maritime,28,Normandie
76313,GOUY,76520,GOUY,,49.3576284402,1.14309716518,313,,Gouy,Gouy,76,Seine-Maritime,28,Normandie
76314,GRAIMBOUVILLE,76430,GRAIMBOUVILLE,,49.5816594783,0.333991745798,314,,Graimbouville,Graimbouville,76,Seine-Maritime,28,Normandie
76315,GRAINVILLE LA TEINTURIERE,76450,GRAINVILLE LA TEINTURIERE,,49.7452374787,0.643304678405,315,,Grainville-la-Teinturière,Grainville-la-Teinturière,76,Seine-Maritime,28,Normandie
76316,GRAINVILLE SUR RY,76116,GRAINVILLE SUR RY,,49.4748647459,1.30031593466,316,,Grainville-sur-Ry,Grainville-sur-Ry,76,Seine-Maritime,28,Normandie
76317,GRAINVILLE YMAUVILLE,76110,GRAINVILLE YMAUVILLE,,49.6540235507,0.413049424917,317,,Grainville-Ymauville,Grainville-Ymauville,76,Seine-Maritime,28,Normandie
76318,GRAND CAMP,76170,GRAND CAMP,,49.5516102056,0.600579425382,318,,Grand-Camp,Grand-Camp,76,Seine-Maritime,28,Normandie
76319,GRAND COURONNE,76530,GRAND COURONNE,,49.3483816966,1.00477486737,319,,Grand-Couronne,Grand-Couronne,76,Seine-Maritime,28,Normandie
76319,GRAND COURONNE,76530,GRAND COURONNE,LES ESSARTS,49.3483816966,1.00477486737,319,,Grand-Couronne,Grand-Couronne,76,Seine-Maritime,28,Normandie
76320,GRANDCOURT,76660,GRANDCOURT,,49.916822702,1.48528084939,320,,Grandcourt,Grandcourt,76,Seine-Maritime,28,Normandie
76321,LES GRANDES VENTES,76950,LES GRANDES VENTES,,49.7714742554,1.2346289662,321,Les,Grandes-Ventes,Les Grandes-Ventes,76,Seine-Maritime,28,Normandie
76322,LE GRAND QUEVILLY,76120,LE GRAND QUEVILLY,,49.4093798045,1.03999262153,322,Le,Grand-Quevilly,Le Grand-Quevilly,76,Seine-Maritime,28,Normandie
76323,GRAVAL,76270,GRAVAL,,49.7280747492,1.55070267514,323,,Graval,Graval,76,Seine-Maritime,28,Normandie
76324,GREGES,76370,GREGES,,49.9239147475,1.14871215445,324,,Grèges,Grèges,76,Seine-Maritime,28,Normandie
76325,GREMONVILLE,76970,GREMONVILLE,,49.6614794296,0.828880907854,325,,Grémonville,Grémonville,76,Seine-Maritime,28,Normandie
76327,GREUVILLE,76810,GREUVILLE,,49.8068524505,0.910034177313,327,,Greuville,Greuville,76,Seine-Maritime,28,Normandie
76328,GRIGNEUSEVILLE,76850,GRIGNEUSEVILLE,,49.654891659,1.19239609151,328,,Grigneuseville,Grigneuseville,76,Seine-Maritime,28,Normandie
76329,GRUCHET LE VALASSE,76210,GRUCHET LE VALASSE,,49.5497174771,0.513926540518,329,,Gruchet-le-Valasse,Gruchet-le-Valasse,76,Seine-Maritime,28,Normandie
76330,GRUCHET ST SIMEON,76810,GRUCHET ST SIMEON,,49.8224015028,0.891150990801,330,,Gruchet-Saint-Siméon,Gruchet-Saint-Siméon,76,Seine-Maritime,28,Normandie
76331,GRUGNY,76690,GRUGNY,,49.6181524638,1.10616826798,331,,Grugny,Grugny,76,Seine-Maritime,28,Normandie
76332,GRUMESNIL,76440,GRUMESNIL,,49.6204750653,1.69327955955,332,,Grumesnil,Grumesnil,76,Seine-Maritime,28,Normandie
76333,GUERVILLE,76340,GUERVILLE,,49.9522879492,1.52560536135,333,,Guerville,Guerville,76,Seine-Maritime,28,Normandie
76334,GUEURES,76730,GUEURES,,49.8422590641,0.95206028846,334,,Gueures,Gueures,76,Seine-Maritime,28,Normandie
76335,GUEUTTEVILLE,76890,GUEUTTEVILLE,,49.6499502123,1.00473068067,335,,Gueutteville,Gueutteville,76,Seine-Maritime,28,Normandie
76336,GUEUTTEVILLE LES GRES,76460,GUEUTTEVILLE LES GRES,,49.8388715423,0.764828836575,336,,Gueutteville-les-Grès,Gueutteville-les-Grès,76,Seine-Maritime,28,Normandie
76338,LA HALLOTIERE,76780,LA HALLOTIERE,,49.5217455492,1.47595225552,338,La,Hallotière,La Hallotière,76,Seine-Maritime,28,Normandie
76339,LE HANOUARD,76450,LE HANOUARD,,49.7227537763,0.65491146964,339,Le,Hanouard,Le Hanouard,76,Seine-Maritime,28,Normandie
76340,HARCANVILLE,76560,HARCANVILLE,,49.7083689762,0.7685061515,340,,Harcanville,Harcanville,76,Seine-Maritime,28,Normandie
76341,HARFLEUR,76700,HARFLEUR,,49.5138279275,0.197050317901,341,,Harfleur,Harfleur,76,Seine-Maritime,28,Normandie
76342,HATTENVILLE,76640,HATTENVILLE,,49.6542985352,0.539027769372,342,,Hattenville,Hattenville,76,Seine-Maritime,28,Normandie
76343,HAUCOURT,76440,HAUCOURT,,49.64421337,1.67253134593,343,,Haucourt,Haucourt,76,Seine-Maritime,28,Normandie
76344,HAUDRICOURT,76390,HAUDRICOURT,,49.7326671243,1.69688165531,344,,Haudricourt,Haudricourt,76,Seine-Maritime,28,Normandie
76345,HAUSSEZ,76440,HAUSSEZ,,49.5875455354,1.67925587307,345,,Haussez,Haussez,76,Seine-Maritime,28,Normandie
76346,HAUTOT L AUVRAY,76450,HAUTOT L AUVRAY,,49.765302786,0.755290722175,346,,Hautot-l'Auvray,Hautot-l'Auvray,76,Seine-Maritime,28,Normandie
76347,HAUTOT LE VATOIS,76190,HAUTOT LE VATOIS,,49.6464064549,0.702728853885,347,,Hautot-le-Vatois,Hautot-le-Vatois,76,Seine-Maritime,28,Normandie
76348,HAUTOT ST SULPICE,76190,HAUTOT ST SULPICE,,49.6794269269,0.737684374658,348,,Hautot-Saint-Sulpice,Hautot-Saint-Sulpice,76,Seine-Maritime,28,Normandie
76349,HAUTOT SUR MER,76550,HAUTOT SUR MER,,49.902417401,1.04064541491,349,,Hautot-sur-Mer,Hautot-sur-Mer,76,Seine-Maritime,28,Normandie
76349,HAUTOT SUR MER,76550,HAUTOT SUR MER,LE PETIT APPEVILLE,49.902417401,1.04064541491,349,,Hautot-sur-Mer,Hautot-sur-Mer,76,Seine-Maritime,28,Normandie
76350,HAUTOT SUR SEINE,76113,HAUTOT SUR SEINE,,49.3628946532,0.977680878823,350,,Hautot-sur-Seine,Hautot-sur-Seine,76,Seine-Maritime,28,Normandie
76351,LE HAVRE,76600,LE HAVRE,,49.498452502,0.140153719153,351,Le,Havre,Le Havre,76,Seine-Maritime,28,Normandie
76351,LE HAVRE,76610,LE HAVRE,,49.498452502,0.140153719153,351,Le,Havre,Le Havre,76,Seine-Maritime,28,Normandie
76351,LE HAVRE,76610,LE HAVRE,ROUELLES,49.498452502,0.140153719153,351,Le,Havre,Le Havre,76,Seine-Maritime,28,Normandie
76351,LE HAVRE,76620,LE HAVRE,,49.498452502,0.140153719153,351,Le,Havre,Le Havre,76,Seine-Maritime,28,Normandie
76352,LA HAYE,76780,LA HAYE,,49.461049199,1.44697821711,352,La,Haye,La Haye,76,Seine-Maritime,28,Normandie
76353,HEBERVILLE,76740,HEBERVILLE,,49.7831426992,0.802685220315,353,,Héberville,Héberville,76,Seine-Maritime,28,Normandie
76354,HENOUVILLE,76840,HENOUVILLE,,49.4750504907,0.951322211324,354,,Hénouville,Hénouville,76,Seine-Maritime,28,Normandie
76355,HERICOURT EN CAUX,76560,HERICOURT EN CAUX,,49.6945647132,0.703298734705,355,,Héricourt-en-Caux,Héricourt-en-Caux,76,Seine-Maritime,28,Normandie
76356,HERMANVILLE,76730,HERMANVILLE,,49.820591572,0.987318293515,356,,Hermanville,Hermanville,76,Seine-Maritime,28,Normandie
76357,HERMEVILLE,76280,HERMEVILLE,,49.5971803275,0.259748976024,357,,Hermeville,Hermeville,76,Seine-Maritime,28,Normandie
76358,LE HERON,76780,LE HERON,,49.4921511515,1.40468099067,358,Le,Héron,Le Héron,76,Seine-Maritime,28,Normandie
76359,HERONCHELLES,76750,HERONCHELLES,,49.5380632023,1.38150021966,359,,Héronchelles,Héronchelles,76,Seine-Maritime,28,Normandie
76360,HEUGLEVILLE SUR SCIE,76720,HEUGLEVILLE SUR SCIE,,49.7347886916,1.09989900857,360,,Heugleville-sur-Scie,Heugleville-sur-Scie,76,Seine-Maritime,28,Normandie
76361,HEUQUEVILLE,76280,HEUQUEVILLE,,49.6176869296,0.152224099853,361,,Heuqueville,Heuqueville,76,Seine-Maritime,28,Normandie
76362,HEURTEAUVILLE,76940,HEURTEAUVILLE,,49.4532160211,0.801910726458,362,,Heurteauville,Heurteauville,76,Seine-Maritime,28,Normandie
76363,HODENG AU BOSC,76340,HODENG AU BOSC,,49.8719141783,1.69787362739,363,,Hodeng-au-Bosc,Hodeng-au-Bosc,76,Seine-Maritime,28,Normandie
76364,HODENG HODENGER,76780,HODENG HODENGER,,49.5365103437,1.58849427798,364,,Hodeng-Hodenger,Hodeng-Hodenger,76,Seine-Maritime,28,Normandie
76365,HOUDETOT,76740,HOUDETOT,,49.8169400407,0.806245052937,365,,Houdetot,Houdetot,76,Seine-Maritime,28,Normandie
76366,LE HOULME,76770,LE HOULME,,49.5109862944,1.03670151831,366,Le,Houlme,Le Houlme,76,Seine-Maritime,28,Normandie
76367,HOUPPEVILLE,76770,HOUPPEVILLE,,49.5041147644,1.08923677261,367,,Houppeville,Houppeville,76,Seine-Maritime,28,Normandie
76368,HOUQUETOT,76110,HOUQUETOT,,49.6046419806,0.387795488349,368,,Houquetot,Houquetot,76,Seine-Maritime,28,Normandie
76369,LA HOUSSAYE BERANGER,76690,LA HOUSSAYE BERANGER,,49.629530026,1.08374590785,369,La,Houssaye-Béranger,La Houssaye-Béranger,76,Seine-Maritime,28,Normandie
76370,HUGLEVILLE EN CAUX,76570,HUGLEVILLE EN CAUX,,49.6236633392,0.990120224832,370,,Hugleville-en-Caux,Hugleville-en-Caux,76,Seine-Maritime,28,Normandie
76371,LES IFS,76630,LES IFS,,49.8886770741,1.3968082009,371,Les,Ifs,Les Ifs,76,Seine-Maritime,28,Normandie
76372,ILLOIS,76390,ILLOIS,,49.7454018898,1.64586609284,372,,Illois,Illois,76,Seine-Maritime,28,Normandie
76373,IMBLEVILLE,76890,IMBLEVILLE,,49.7139469343,0.943139932504,373,,Imbleville,Imbleville,76,Seine-Maritime,28,Normandie
76374,INCHEVILLE,76117,INCHEVILLE,,50.0051595609,1.49981533136,374,,Incheville,Incheville,76,Seine-Maritime,28,Normandie
76375,INGOUVILLE,76460,INGOUVILLE,,49.8445024233,0.681514584492,375,,Ingouville,Ingouville,76,Seine-Maritime,28,Normandie
76377,ISNEAUVILLE,76230,ISNEAUVILLE,,49.5022552878,1.14531230443,377,,Isneauville,Isneauville,76,Seine-Maritime,28,Normandie
76378,JUMIEGES,76480,JUMIEGES,,49.427073553,0.831659847193,378,,Jumièges,Jumièges,76,Seine-Maritime,28,Normandie
76379,LAMBERVILLE,76730,LAMBERVILLE,,49.77385559,1.008841901,379,,Lamberville,Lamberville,76,Seine-Maritime,28,Normandie
76380,LAMMERVILLE,76730,LAMMERVILLE,,49.7943437879,0.970888263334,380,,Lammerville,Lammerville,76,Seine-Maritime,28,Normandie
76381,LANDES VIEILLES ET NEUVES,76390,LANDES VIEILLES ET NEUVES,,49.786578533,1.6323805287,381,,Landes-Vieilles-et-Neuves,Landes-Vieilles-et-Neuves,76,Seine-Maritime,28,Normandie
76382,LANQUETOT,76210,LANQUETOT,,49.5834156641,0.530353339323,382,,Lanquetot,Lanquetot,76,Seine-Maritime,28,Normandie
76383,LESTANVILLE,76730,LESTANVILLE,,49.7481576979,0.959648498602,383,,Lestanville,Lestanville,76,Seine-Maritime,28,Normandie
76384,LILLEBONNE,76170,LILLEBONNE,,49.5087332809,0.534041811072,384,,Lillebonne,Lillebonne,76,Seine-Maritime,28,Normandie
76385,LIMESY,76570,LIMESY,,49.6085021543,0.923810554768,385,,Limésy,Limésy,76,Seine-Maritime,28,Normandie
76386,LIMPIVILLE,76540,LIMPIVILLE,,49.6872515704,0.50230406905,386,,Limpiville,Limpiville,76,Seine-Maritime,28,Normandie
76387,LINDEBEUF,76760,LINDEBEUF,,49.7098655097,0.907175798563,387,,Lindebeuf,Lindebeuf,76,Seine-Maritime,28,Normandie
76388,LINTOT,76210,LINTOT,,49.5593733793,0.567071303383,388,,Lintot,Lintot,76,Seine-Maritime,28,Normandie
76389,LINTOT LES BOIS,76590,LINTOT LES BOIS,,49.8010740121,1.07001099196,389,,Lintot-les-Bois,Lintot-les-Bois,76,Seine-Maritime,28,Normandie
76390,LES LOGES,76790,LES LOGES,,49.6976413785,0.284739023894,390,Les,Loges,Les Loges,76,Seine-Maritime,28,Normandie
76391,LA LONDE,76500,LA LONDE,,49.323828876,0.920341905232,391,La,Londe,La Londe,76,Seine-Maritime,28,Normandie
76392,LONDINIERES,76660,LONDINIERES,,49.8411738829,1.39635078372,392,,Londinières,Londinières,76,Seine-Maritime,28,Normandie
76393,LONGMESNIL,76440,LONGMESNIL,,49.6229643465,1.6127583113,393,,Longmesnil,Longmesnil,76,Seine-Maritime,28,Normandie
76394,LONGROY,76260,LONGROY,,49.9811987866,1.53462109091,394,,Longroy,Longroy,76,Seine-Maritime,28,Normandie
76395,LONGUEIL,76860,LONGUEIL,,49.8832005078,0.947742535975,395,,Longueil,Longueil,76,Seine-Maritime,28,Normandie
76396,LONGUERUE,76750,LONGUERUE,,49.5473482458,1.28948826612,396,,Longuerue,Longuerue,76,Seine-Maritime,28,Normandie
76397,LONGUEVILLE SUR SCIE,76590,LONGUEVILLE SUR SCIE,,49.7957426692,1.10718815906,397,,Longueville-sur-Scie,Longueville-sur-Scie,76,Seine-Maritime,28,Normandie
76398,LOUVETOT,76490,LOUVETOT,,49.5727111011,0.72336777386,398,,Louvetot,Louvetot,76,Seine-Maritime,28,Normandie
76399,LUCY,76270,LUCY,,49.7722779599,1.44769701931,399,,Lucy,Lucy,76,Seine-Maritime,28,Normandie
76400,LUNERAY,76810,LUNERAY,,49.8227333447,0.917982419678,400,,Luneray,Luneray,76,Seine-Maritime,28,Normandie
76401,ARELAUNE EN SEINE,76940,ARELAUNE EN SEINE,LA MAILLERAYE SUR SEINE,49.4463462348,0.753460847099,401,,Arelaune-en-Seine,Arelaune-en-Seine,76,Seine-Maritime,28,Normandie
76401,ARELAUNE EN SEINE,76940,ARELAUNE EN SEINE,ST NICOLAS DE BLIQUETUIT,49.4463462348,0.753460847099,401,,Arelaune-en-Seine,Arelaune-en-Seine,76,Seine-Maritime,28,Normandie
76402,MALAUNAY,76770,MALAUNAY,,49.5254991004,1.04877574012,402,,Malaunay,Malaunay,76,Seine-Maritime,28,Normandie
76403,MALLEVILLE LES GRES,76450,MALLEVILLE LES GRES,,49.8304035234,0.606063626778,403,,Malleville-les-Grès,Malleville-les-Grès,76,Seine-Maritime,28,Normandie
76404,MANEGLISE,76133,MANEGLISE,,49.5667618934,0.256787917963,404,,Manéglise,Manéglise,76,Seine-Maritime,28,Normandie
76405,MANEHOUVILLE,76590,MANEHOUVILLE,,49.832169289,1.06742872639,405,,Manéhouville,Manéhouville,76,Seine-Maritime,28,Normandie
76406,MANIQUERVILLE,76400,MANIQUERVILLE,,49.6944811187,0.346572121697,406,,Maniquerville,Maniquerville,76,Seine-Maritime,28,Normandie
76407,MANNEVILLE ES PLAINS,76460,MANNEVILLE ES PLAINS,,49.8582585545,0.751682408073,407,,Manneville-ès-Plains,Manneville-ès-Plains,76,Seine-Maritime,28,Normandie
76408,MANNEVILLE LA GOUPIL,76110,MANNEVILLE LA GOUPIL,,49.6116014086,0.347552661099,408,,Manneville-la-Goupil,Manneville-la-Goupil,76,Seine-Maritime,28,Normandie
76409,MANNEVILLETTE,76290,MANNEVILLETTE,,49.5956720647,0.175390225182,409,,Mannevillette,Mannevillette,76,Seine-Maritime,28,Normandie
76410,MAROMME,76150,MAROMME,,49.4769434634,1.03230514083,410,,Maromme,Maromme,76,Seine-Maritime,28,Normandie
76410,MAROMME,76150,MAROMME,LA MAINE,49.4769434634,1.03230514083,410,,Maromme,Maromme,76,Seine-Maritime,28,Normandie
76411,MARQUES,76390,MARQUES,,49.7798349093,1.68296751108,411,,Marques,Marques,76,Seine-Maritime,28,Normandie
76412,MARTAINVILLE EPREVILLE,76116,MARTAINVILLE EPREVILLE,,49.451874767,1.29465075499,412,,Martainville-Épreville,Martainville-Épreville,76,Seine-Maritime,28,Normandie
76413,MARTIGNY,76880,MARTIGNY,,49.8602278248,1.14443608077,413,,Martigny,Martigny,76,Seine-Maritime,28,Normandie
76414,MARTIN EGLISE,76370,MARTIN EGLISE,,49.9099813153,1.13201319892,414,,Martin-Église,Martin-Église,76,Seine-Maritime,28,Normandie
76415,MASSY,76270,MASSY,,49.6878599018,1.39541182981,415,,Massy,Massy,76,Seine-Maritime,28,Normandie
76416,MATHONVILLE,76680,MATHONVILLE,,49.6233003429,1.38237435878,416,,Mathonville,Mathonville,76,Seine-Maritime,28,Normandie
76417,MAUCOMBLE,76680,MAUCOMBLE,,49.6874845722,1.3335829013,417,,Maucomble,Maucomble,76,Seine-Maritime,28,Normandie
76418,MAULEVRIER STE GERTRUDE,76490,MAULEVRIER STE GERTRUDE,,49.5547380946,0.708077197955,418,,Maulévrier-Sainte-Gertrude,Maulévrier-Sainte-Gertrude,76,Seine-Maritime,28,Normandie
76419,MAUNY,76530,MAUNY,,49.3964593103,0.902531176422,419,,Mauny,Mauny,76,Seine-Maritime,28,Normandie
76420,MAUQUENCHY,76440,MAUQUENCHY,,49.5990574855,1.46080592913,420,,Mauquenchy,Mauquenchy,76,Seine-Maritime,28,Normandie
76421,MELAMARE,76170,MELAMARE,,49.539757869,0.436022183281,421,,Mélamare,Mélamare,76,Seine-Maritime,28,Normandie
76422,MELLEVILLE,76260,MELLEVILLE,,49.9605697459,1.47593522643,422,,Melleville,Melleville,76,Seine-Maritime,28,Normandie
76423,MENERVAL,76220,MENERVAL,,49.5596263854,1.64743128876,423,,Ménerval,Ménerval,76,Seine-Maritime,28,Normandie
76424,MENONVAL,76270,MENONVAL,,49.7609797714,1.48093299574,424,,Ménonval,Ménonval,76,Seine-Maritime,28,Normandie
76425,MENTHEVILLE,76110,MENTHEVILLE,,49.6915078139,0.40951682007,425,,Mentheville,Mentheville,76,Seine-Maritime,28,Normandie
76426,MESANGUEVILLE,76780,MESANGUEVILLE,,49.5574830484,1.56955244985,426,,Mésangueville,Mésangueville,76,Seine-Maritime,28,Normandie
76427,MESNIERES EN BRAY,76270,MESNIERES EN BRAY,,49.7656701654,1.3868369268,427,,Mesnières-en-Bray,Mesnières-en-Bray,76,Seine-Maritime,28,Normandie
76428,LE MESNIL DURDENT,76460,LE MESNIL DURDENT,,49.81771542,0.771137757772,428,Le,Mesnil-Durdent,Le Mesnil-Durdent,76,Seine-Maritime,28,Normandie
76429,LE MESNIL ESNARD,76240,LE MESNIL ESNARD,,49.4135236522,1.14513058262,429,Le,Mesnil-Esnard,Le Mesnil-Esnard,76,Seine-Maritime,28,Normandie
76430,MESNIL FOLLEMPRISE,76660,MESNIL FOLLEMPRISE,,49.7631399804,1.30108791284,430,,Mesnil-Follemprise,Mesnil-Follemprise,76,Seine-Maritime,28,Normandie
76431,LE MESNIL LIEUBRAY,76780,LE MESNIL LIEUBRAY,,49.5155150214,1.50750794414,431,Le,Mesnil-Lieubray,Le Mesnil-Lieubray,76,Seine-Maritime,28,Normandie
76432,MESNIL MAUGER,76440,MESNIL MAUGER,,49.6699097849,1.52151036865,432,,Mesnil-Mauger,Mesnil-Mauger,76,Seine-Maritime,28,Normandie
76433,MESNIL PANNEVILLE,76570,MESNIL PANNEVILLE,,49.5885832407,0.882653568735,433,,Mesnil-Panneville,Mesnil-Panneville,76,Seine-Maritime,28,Normandie
76434,MESNIL RAOUL,76520,MESNIL RAOUL,,49.3972282851,1.27517747043,434,,Mesnil-Raoul,Mesnil-Raoul,76,Seine-Maritime,28,Normandie
76435,LE MESNIL REAUME,76260,LE MESNIL REAUME,,49.9716669882,1.44085369152,435,Le,Mesnil-Réaume,Le Mesnil-Réaume,76,Seine-Maritime,28,Normandie
76436,LE MESNIL SOUS JUMIEGES,76480,LE MESNIL SOUS JUMIEGES,,49.414321158,0.855001409991,436,Le,Mesnil-sous-Jumièges,Le Mesnil-sous-Jumièges,76,Seine-Maritime,28,Normandie
76437,MEULERS,76510,MEULERS,,49.8412109019,1.22180412827,437,,Meulers,Meulers,76,Seine-Maritime,28,Normandie
76438,MILLEBOSC,76260,MILLEBOSC,,49.9810567179,1.49762439806,438,,Millebosc,Millebosc,76,Seine-Maritime,28,Normandie
76439,MIRVILLE,76210,MIRVILLE,,49.6055328939,0.440522106499,439,,Mirville,Mirville,76,Seine-Maritime,28,Normandie
76440,MOLAGNIES,76220,MOLAGNIES,,49.522352135,1.72459213347,440,,Molagnies,Molagnies,76,Seine-Maritime,28,Normandie
76441,MONCHAUX SORENG,76340,MONCHAUX SORENG,,49.9466134625,1.57307821463,441,,Monchaux-Soreng,Monchaux-Soreng,76,Seine-Maritime,28,Normandie
76442,MONCHY SUR EU,76260,MONCHY SUR EU,,49.9926780546,1.45920994382,442,,Monchy-sur-Eu,Monchy-sur-Eu,76,Seine-Maritime,28,Normandie
76443,MONT CAUVAIRE,76690,MONT CAUVAIRE,,49.5711050563,1.11398307831,443,,Mont-Cauvaire,Mont-Cauvaire,76,Seine-Maritime,28,Normandie
76445,MONTEROLIER,76680,MONTEROLIER,,49.6223619301,1.34614816017,445,,Montérolier,Montérolier,76,Seine-Maritime,28,Normandie
76446,MONTIGNY,76380,MONTIGNY,,49.4586828943,0.997604647989,446,,Montigny,Montigny,76,Seine-Maritime,28,Normandie
76447,MONTIVILLIERS,76290,MONTIVILLIERS,,49.5442665298,0.190325264974,447,,Montivilliers,Montivilliers,76,Seine-Maritime,28,Normandie
76448,MONTMAIN,76520,MONTMAIN,,49.4093538356,1.24612086223,448,,Montmain,Montmain,76,Seine-Maritime,28,Normandie
76449,MONTREUIL EN CAUX,76850,MONTREUIL EN CAUX,,49.6862090326,1.15552636526,449,,Montreuil-en-Caux,Montreuil-en-Caux,76,Seine-Maritime,28,Normandie
76450,MONTROTY,76220,MONTROTY,,49.4316501805,1.66162368177,450,,Montroty,Montroty,76,Seine-Maritime,28,Normandie
76451,MONT ST AIGNAN,76130,MONT ST AIGNAN,,49.4676577166,1.08169720921,451,,Mont-Saint-Aignan,Mont-Saint-Aignan,76,Seine-Maritime,28,Normandie
76452,MONTVILLE,76710,MONTVILLE,,49.5460164645,1.0819460987,452,,Montville,Montville,76,Seine-Maritime,28,Normandie
76453,MORGNY LA POMMERAYE,76750,MORGNY LA POMMERAYE,,49.5199542797,1.25089069783,453,,Morgny-la-Pommeraye,Morgny-la-Pommeraye,76,Seine-Maritime,28,Normandie
76454,MORTEMER,76270,MORTEMER,,49.7444162467,1.54646531807,454,,Mortemer,Mortemer,76,Seine-Maritime,28,Normandie
76455,MORVILLE SUR ANDELLE,76780,MORVILLE SUR ANDELLE,,49.481401838,1.43203726716,455,,Morville-sur-Andelle,Morville-sur-Andelle,76,Seine-Maritime,28,Normandie
76456,MOTTEVILLE,76970,MOTTEVILLE,,49.6307861651,0.854390433199,456,,Motteville,Motteville,76,Seine-Maritime,28,Normandie
76457,MOULINEAUX,76530,MOULINEAUX,,49.3430951193,0.957923849124,457,,Moulineaux,Moulineaux,76,Seine-Maritime,28,Normandie
76458,MUCHEDENT,76590,MUCHEDENT,,49.766373525,1.18741006071,458,,Muchedent,Muchedent,76,Seine-Maritime,28,Normandie
76459,NESLE HODENG,76270,NESLE HODENG,,49.7039732474,1.52184808735,459,,Nesle-Hodeng,Nesle-Hodeng,76,Seine-Maritime,28,Normandie
76460,NESLE NORMANDEUSE,76340,NESLE NORMANDEUSE,,49.9016162412,1.6686722972,460,,Nesle-Normandeuse,Nesle-Normandeuse,76,Seine-Maritime,28,Normandie
76461,NEUFBOSC,76680,NEUFBOSC,,49.6462799246,1.36132710657,461,,Neufbosc,Neufbosc,76,Seine-Maritime,28,Normandie
76462,NEUFCHATEL EN BRAY,76270,NEUFCHATEL EN BRAY,,49.7402537882,1.44657170543,462,,Neufchâtel-en-Bray,Neufchâtel-en-Bray,76,Seine-Maritime,28,Normandie
76463,NEUF MARCHE,76220,NEUF MARCHE,,49.4223226363,1.69282497893,463,,Neuf-Marché,Neuf-Marché,76,Seine-Maritime,28,Normandie
76464,LA NEUVILLE CHANT D OISEL,76520,LA NEUVILLE CHANT D OISEL,,49.3692279184,1.2498939606,464,La,Neuville-Chant-d'Oisel,La Neuville-Chant-d'Oisel,76,Seine-Maritime,28,Normandie
76465,NEUVILLE FERRIERES,76270,NEUVILLE FERRIERES,,49.7113993186,1.45364357181,465,,Neuville-Ferrières,Neuville-Ferrières,76,Seine-Maritime,28,Normandie
76467,NEVILLE,76460,NEVILLE,,49.8235682474,0.71056171755,467,,Néville,Néville,76,Seine-Maritime,28,Normandie
76468,NOINTOT,76210,NOINTOT,,49.5978442384,0.472716239885,468,,Nointot,Nointot,76,Seine-Maritime,28,Normandie
76469,NOLLEVAL,76780,NOLLEVAL,,49.4939955432,1.48168594393,469,,Nolléval,Nolléval,76,Seine-Maritime,28,Normandie
76470,NORMANVILLE,76640,NORMANVILLE,,49.6911788807,0.587022757237,470,,Normanville,Normanville,76,Seine-Maritime,28,Normandie
76471,NORVILLE,76330,NORVILLE,,49.4816595994,0.643538536644,471,,Norville,Norville,76,Seine-Maritime,28,Normandie
76472,NOTRE DAME D ALIERMONT,76510,NOTRE DAME D ALIERMONT,,49.8452221098,1.2979386234,472,,Notre-Dame-d'Aliermont,Notre-Dame-d'Aliermont,76,Seine-Maritime,28,Normandie
76473,NOTRE DAME DE BLIQUETUIT,76940,NOTRE DAME DE BLIQUETUIT,,49.4959539726,0.753080811666,473,,Notre-Dame-de-Bliquetuit,Notre-Dame-de-Bliquetuit,76,Seine-Maritime,28,Normandie
76474,NOTRE DAME DE BONDEVILLE,76960,NOTRE DAME DE BONDEVILLE,,49.4876258547,1.05409073617,474,,Notre-Dame-de-Bondeville,Notre-Dame-de-Bondeville,76,Seine-Maritime,28,Normandie
76475,FRANQUEVILLE ST PIERRE,76520,FRANQUEVILLE ST PIERRE,,49.4038906151,1.18265367249,475,,Franqueville-Saint-Pierre,Franqueville-Saint-Pierre,76,Seine-Maritime,28,Normandie
76476,PORT JEROME SUR SEINE,76170,PORT JEROME SUR SEINE,AUBERVILLE LA CAMPAGNE,49.4895859593,0.572888177014,476,,Port-Jérôme-sur-Seine,Port-Jérôme-sur-Seine,76,Seine-Maritime,28,Normandie
76476,PORT JEROME SUR SEINE,76170,PORT JEROME SUR SEINE,TOUFFREVILLE LA CABLE,49.4895859593,0.572888177014,476,,Port-Jérôme-sur-Seine,Port-Jérôme-sur-Seine,76,Seine-Maritime,28,Normandie
76476,PORT JEROME SUR SEINE,76170,PORT JEROME SUR SEINE,TRIQUERVILLE,49.4895859593,0.572888177014,476,,Port-Jérôme-sur-Seine,Port-Jérôme-sur-Seine,76,Seine-Maritime,28,Normandie
76476,PORT JEROME SUR SEINE,76330,PORT JEROME SUR SEINE,NOTRE DAME DE GRAVENCHON,49.4895859593,0.572888177014,476,,Port-Jérôme-sur-Seine,Port-Jérôme-sur-Seine,76,Seine-Maritime,28,Normandie
76477,NOTRE DAME DU BEC,76133,NOTRE DAME DU BEC,,49.5940970105,0.220671050793,477,,Notre-Dame-du-Bec,Notre-Dame-du-Bec,76,Seine-Maritime,28,Normandie
76478,NOTRE DAME DU PARC,76590,NOTRE DAME DU PARC,,49.7497846013,1.12889565067,478,,Notre-Dame-du-Parc,Notre-Dame-du-Parc,76,Seine-Maritime,28,Normandie
76479,NULLEMONT,76390,NULLEMONT,,49.7707112808,1.64690403795,479,,Nullemont,Nullemont,76,Seine-Maritime,28,Normandie
76480,OCQUEVILLE,76450,OCQUEVILLE,,49.797976245,0.685755731382,480,,Ocqueville,Ocqueville,76,Seine-Maritime,28,Normandie
76481,OCTEVILLE SUR MER,76930,OCTEVILLE SUR MER,,49.558902232,0.11790804118,481,,Octeville-sur-Mer,Octeville-sur-Mer,76,Seine-Maritime,28,Normandie
76482,OFFRANVILLE,76550,OFFRANVILLE,,49.8711707003,1.03537309213,482,,Offranville,Offranville,76,Seine-Maritime,28,Normandie
76483,OHERVILLE,76560,OHERVILLE,,49.7271197121,0.689847919572,483,,Oherville,Oherville,76,Seine-Maritime,28,Normandie
76484,OISSEL,76350,OISSEL,,49.3477840873,1.07400763141,484,,Oissel,Oissel,76,Seine-Maritime,28,Normandie
76485,OMONVILLE,76730,OMONVILLE,,49.7907329666,1.04880121771,485,,Omonville,Omonville,76,Seine-Maritime,28,Normandie
76486,ORIVAL,76500,ORIVAL,,49.3187791595,0.996413960189,486,,Orival,Orival,76,Seine-Maritime,28,Normandie
76487,OSMOY ST VALERY,76660,OSMOY ST VALERY,,49.7970849665,1.30356200138,487,,Osmoy-Saint-Valery,Osmoy-Saint-Valery,76,Seine-Maritime,28,Normandie
76488,OUAINVILLE,76450,OUAINVILLE,,49.7854251971,0.588453866269,488,,Ouainville,Ouainville,76,Seine-Maritime,28,Normandie
76489,OUDALLE,76430,OUDALLE,,49.4851409545,0.28118891923,489,,Oudalle,Oudalle,76,Seine-Maritime,28,Normandie
76490,OURVILLE EN CAUX,76450,OURVILLE EN CAUX,,49.7327387861,0.598993581488,490,,Ourville-en-Caux,Ourville-en-Caux,76,Seine-Maritime,28,Normandie
76491,OUVILLE L ABBAYE,76760,OUVILLE L ABBAYE,,49.6944501885,0.866542166561,491,,Ouville-l'Abbaye,Ouville-l'Abbaye,76,Seine-Maritime,28,Normandie
76492,OUVILLE LA RIVIERE,76860,OUVILLE LA RIVIERE,,49.8737511428,0.982537676244,492,,Ouville-la-Rivière,Ouville-la-Rivière,76,Seine-Maritime,28,Normandie
76493,PALUEL,76450,PALUEL,,49.8443584396,0.630759269257,493,,Paluel,Paluel,76,Seine-Maritime,28,Normandie
76494,PARC D ANXTOT,76210,PARC D ANXTOT,,49.5859076954,0.388286306471,494,,Parc-d'Anxtot,Parc-d'Anxtot,76,Seine-Maritime,28,Normandie
76495,PAVILLY,76570,PAVILLY,,49.5758967466,0.947253796709,495,,Pavilly,Pavilly,76,Seine-Maritime,28,Normandie
76497,PETIT COURONNE,76650,PETIT COURONNE,,49.3807622557,1.03422612047,497,,Petit-Couronne,Petit-Couronne,76,Seine-Maritime,28,Normandie
76498,LE PETIT QUEVILLY,76140,LE PETIT QUEVILLY,,49.4238134718,1.06033289032,498,Le,Petit-Quevilly,Le Petit-Quevilly,76,Seine-Maritime,28,Normandie
76499,PETIVILLE,76330,PETIVILLE,,49.4542024159,0.583953309592,499,,Petiville,Petiville,76,Seine-Maritime,28,Normandie
76500,PIERRECOURT,76340,PIERRECOURT,,49.8882992444,1.6385300202,500,,Pierrecourt,Pierrecourt,76,Seine-Maritime,28,Normandie
76501,PIERREFIQUES,76280,PIERREFIQUES,,49.6746159893,0.230254872539,501,,Pierrefiques,Pierrefiques,76,Seine-Maritime,28,Normandie
76502,PIERREVAL,76750,PIERREVAL,,49.5403359016,1.2537667625,502,,Pierreval,Pierreval,76,Seine-Maritime,28,Normandie
76503,PISSY POVILLE,76360,PISSY POVILLE,,49.5338000532,1.00170847725,503,,Pissy-Pôville,Pissy-Pôville,76,Seine-Maritime,28,Normandie
76504,PLEINE SEVE,76460,PLEINE SEVE,,49.8192764912,0.752574707374,504,,Pleine-Sève,Pleine-Sève,76,Seine-Maritime,28,Normandie
76505,POMMEREUX,76440,POMMEREUX,,49.6098738401,1.63085932731,505,,Pommereux,Pommereux,76,Seine-Maritime,28,Normandie
76506,POMMEREVAL,76680,POMMEREVAL,,49.7348985636,1.30505361857,506,,Pommeréval,Pommeréval,76,Seine-Maritime,28,Normandie
76507,PONTS ET MARAIS,76260,PONTS ET MARAIS,,50.0387974172,1.45638141872,507,,Ponts-et-Marais,Ponts-et-Marais,76,Seine-Maritime,28,Normandie
76508,LA POTERIE CAP D ANTIFER,76280,LA POTERIE CAP D ANTIFER,,49.6783628089,0.178454970928,508,La,Poterie-Cap-d'Antifer,La Poterie-Cap-d'Antifer,76,Seine-Maritime,28,Normandie
76509,PREAUX,76160,PREAUX,,49.4871501461,1.2158124042,509,,Préaux,Préaux,76,Seine-Maritime,28,Normandie
76510,PRETOT VICQUEMARE,76560,PRETOT VICQUEMARE,,49.7336231822,0.847220131387,510,,Prétot-Vicquemare,Prétot-Vicquemare,76,Seine-Maritime,28,Normandie
76511,PREUSEVILLE,76660,PREUSEVILLE,,49.8804684402,1.5053136067,511,,Preuseville,Preuseville,76,Seine-Maritime,28,Normandie
76512,PUISENVAL,76660,PUISENVAL,,49.8787471608,1.46198143965,512,,Puisenval,Puisenval,76,Seine-Maritime,28,Normandie
76513,QUEVILLON,76840,QUEVILLON,,49.4150162102,0.960559393697,513,,Quevillon,Quevillon,76,Seine-Maritime,28,Normandie
76514,QUEVREVILLE LA POTERIE,76520,QUEVREVILLE LA POTERIE,,49.3544551622,1.18963435666,514,,Quévreville-la-Poterie,Quévreville-la-Poterie,76,Seine-Maritime,28,Normandie
76515,QUIBERVILLE,76860,QUIBERVILLE,,49.8974337377,0.919878729271,515,,Quiberville,Quiberville,76,Seine-Maritime,28,Normandie
76516,QUIEVRECOURT,76270,QUIEVRECOURT,,49.725385425,1.41857602963,516,,Quièvrecourt,Quièvrecourt,76,Seine-Maritime,28,Normandie
76517,QUINCAMPOIX,76230,QUINCAMPOIX,,49.5206144276,1.17779386986,517,,Quincampoix,Quincampoix,76,Seine-Maritime,28,Normandie
76518,RAFFETOT,76210,RAFFETOT,,49.6016087127,0.522532598893,518,,Raffetot,Raffetot,76,Seine-Maritime,28,Normandie
76519,RAINFREVILLE,76730,RAINFREVILLE,,49.7931612623,0.923802196696,519,,Rainfreville,Rainfreville,76,Seine-Maritime,28,Normandie
76520,REALCAMP,76340,REALCAMP,,49.8604223248,1.62066205643,520,,Réalcamp,Réalcamp,76,Seine-Maritime,28,Normandie
76521,REBETS,76750,REBETS,,49.5130690586,1.39322276599,521,,Rebets,Rebets,76,Seine-Maritime,28,Normandie
76522,LA REMUEE,76430,LA REMUEE,,49.5259669302,0.409930940139,522,La,Remuée,La Remuée,76,Seine-Maritime,28,Normandie
76523,RETONVAL,76340,RETONVAL,,49.8123722016,1.59427014376,523,,Rétonval,Rétonval,76,Seine-Maritime,28,Normandie
76524,REUVILLE,76560,REUVILLE,,49.747178396,0.862712359723,524,,Reuville,Reuville,76,Seine-Maritime,28,Normandie
76525,RICARVILLE,76640,RICARVILLE,,49.6419932524,0.607251030369,525,,Ricarville,Ricarville,76,Seine-Maritime,28,Normandie
76526,RICARVILLE DU VAL,76510,RICARVILLE DU VAL,,49.7978934042,1.26880317772,526,,Ricarville-du-Val,Ricarville-du-Val,76,Seine-Maritime,28,Normandie
76527,RICHEMONT,76390,RICHEMONT,,49.8102781691,1.64418812478,527,,Richemont,Richemont,76,Seine-Maritime,28,Normandie
76528,RIEUX,76340,RIEUX,,49.9238830095,1.58206484475,528,,Rieux,Rieux,76,Seine-Maritime,28,Normandie
76529,RIVILLE,76540,RIVILLE,,49.7229328156,0.563690281915,529,,Riville,Riville,76,Seine-Maritime,28,Normandie
76530,ROBERTOT,76560,ROBERTOT,,49.7148325676,0.695990388034,530,,Robertot,Robertot,76,Seine-Maritime,28,Normandie
76531,ROCQUEFORT,76640,ROCQUEFORT,,49.6678070405,0.703178317701,531,,Rocquefort,Rocquefort,76,Seine-Maritime,28,Normandie
76532,ROCQUEMONT,76680,ROCQUEMONT,,49.6078202374,1.29752436783,532,,Rocquemont,Rocquemont,76,Seine-Maritime,28,Normandie
76533,ROGERVILLE,76700,ROGERVILLE,,49.4857634739,0.25945469871,533,,Rogerville,Rogerville,76,Seine-Maritime,28,Normandie
76534,ROLLEVILLE,76133,ROLLEVILLE,,49.5787455388,0.211169186328,534,,Rolleville,Rolleville,76,Seine-Maritime,28,Normandie
76535,RONCHEROLLES EN BRAY,76440,RONCHEROLLES EN BRAY,,49.6149739631,1.48812249463,535,,Roncherolles-en-Bray,Roncherolles-en-Bray,76,Seine-Maritime,28,Normandie
76536,RONCHEROLLES SUR LE VIVIER,76160,RONCHEROLLES SUR LE VIVIER,,49.4643888008,1.18206780001,536,,Roncherolles-sur-le-Vivier,Roncherolles-sur-le-Vivier,76,Seine-Maritime,28,Normandie
76537,RONCHOIS,76390,RONCHOIS,,49.7241000601,1.62317523533,537,,Ronchois,Ronchois,76,Seine-Maritime,28,Normandie
76538,ROSAY,76680,ROSAY,,49.6967765846,1.25569705203,538,,Rosay,Rosay,76,Seine-Maritime,28,Normandie
76540,ROUEN,76000,ROUEN,,49.4413460103,1.09256784278,540,,Rouen,Rouen,76,Seine-Maritime,28,Normandie
76540,ROUEN,76100,ROUEN,,49.4413460103,1.09256784278,540,,Rouen,Rouen,76,Seine-Maritime,28,Normandie
76541,ROUMARE,76480,ROUMARE,,49.507884098,0.968583908754,541,,Roumare,Roumare,76,Seine-Maritime,28,Normandie
76542,ROUTES,76560,ROUTES,,49.7300250553,0.747871983525,542,,Routes,Routes,76,Seine-Maritime,28,Normandie
76543,ROUVILLE,76210,ROUVILLE,,49.6266913662,0.498324037116,543,,Rouville,Rouville,76,Seine-Maritime,28,Normandie
76544,ROUVRAY CATILLON,76440,ROUVRAY CATILLON,,49.5765632923,1.47751784058,544,,Rouvray-Catillon,Rouvray-Catillon,76,Seine-Maritime,28,Normandie
76545,ROUXMESNIL BOUTEILLES,76370,ROUXMESNIL BOUTEILLES,,49.8998914455,1.09967062889,545,,Rouxmesnil-Bouteilles,Rouxmesnil-Bouteilles,76,Seine-Maritime,28,Normandie
76546,ROYVILLE,76730,ROYVILLE,,49.7792247048,0.953001666034,546,,Royville,Royville,76,Seine-Maritime,28,Normandie
76547,LA RUE ST PIERRE,76690,LA RUE ST PIERRE,,49.5669983752,1.25404333908,547,La,Rue-Saint-Pierre,La Rue-Saint-Pierre,76,Seine-Maritime,28,Normandie
76548,RY,76116,RY,,49.4733163015,1.33609831586,548,,Ry,Ry,76,Seine-Maritime,28,Normandie
76549,SAANE ST JUST,76730,SAANE ST JUST,,49.7568971698,0.923549334119,549,,Saâne-Saint-Just,Saâne-Saint-Just,76,Seine-Maritime,28,Normandie
76550,SAHURS,76113,SAHURS,,49.3668117102,0.948721197736,550,,Sahurs,Sahurs,76,Seine-Maritime,28,Normandie
76551,SAINNEVILLE,76430,SAINNEVILLE,,49.5524608595,0.292951344544,551,,Sainneville,Sainneville,76,Seine-Maritime,28,Normandie
76552,STE ADRESSE,76310,STE ADRESSE,,49.5101291317,0.0779770948409,552,,Sainte-Adresse,Sainte-Adresse,76,Seine-Maritime,28,Normandie
76553,STE AGATHE D ALIERMONT,76660,STE AGATHE D ALIERMONT,,49.8251228166,1.33849691279,553,,Sainte-Agathe-d'Aliermont,Sainte-Agathe-d'Aliermont,76,Seine-Maritime,28,Normandie
76554,ST AIGNAN SUR RY,76116,ST AIGNAN SUR RY,,49.4965544546,1.34755871373,554,,Saint-Aignan-sur-Ry,Saint-Aignan-sur-Ry,76,Seine-Maritime,28,Normandie
76555,ST ANDRE SUR CAILLY,76690,ST ANDRE SUR CAILLY,,49.5489369036,1.22162496884,555,,Saint-André-sur-Cailly,Saint-André-sur-Cailly,76,Seine-Maritime,28,Normandie
76556,ST ANTOINE LA FORET,76170,ST ANTOINE LA FORET,,49.5385601912,0.480932207517,556,,Saint-Antoine-la-Forêt,Saint-Antoine-la-Forêt,76,Seine-Maritime,28,Normandie
76557,ST ARNOULT,76490,ST ARNOULT,,49.5366520246,0.677043533438,557,,Saint-Arnoult,Saint-Arnoult,76,Seine-Maritime,28,Normandie
76558,ST AUBIN CELLOVILLE,76520,ST AUBIN CELLOVILLE,,49.3755064036,1.16040863034,558,,Saint-Aubin-Celloville,Saint-Aubin-Celloville,76,Seine-Maritime,28,Normandie
76559,ST AUBIN DE CRETOT,76190,ST AUBIN DE CRETOT,,49.5726933909,0.630520111377,559,,Saint-Aubin-de-Crétot,Saint-Aubin-de-Crétot,76,Seine-Maritime,28,Normandie
76560,ST AUBIN EPINAY,76160,ST AUBIN EPINAY,,49.4189460634,1.20455713453,560,,Saint-Aubin-Épinay,Saint-Aubin-Épinay,76,Seine-Maritime,28,Normandie
76561,ST AUBIN LES ELBEUF,76410,ST AUBIN LES ELBEUF,,49.3031255022,1.01820601482,561,,Saint-Aubin-lès-Elbeuf,Saint-Aubin-lès-Elbeuf,76,Seine-Maritime,28,Normandie
76562,ST AUBIN LE CAUF,76510,ST AUBIN LE CAUF,,49.8678815711,1.17642488577,562,,Saint-Aubin-le-Cauf,Saint-Aubin-le-Cauf,76,Seine-Maritime,28,Normandie
76563,ST AUBIN ROUTOT,76430,ST AUBIN ROUTOT,,49.5224149868,0.318821441812,563,,Saint-Aubin-Routot,Saint-Aubin-Routot,76,Seine-Maritime,28,Normandie
76564,ST AUBIN SUR MER,76740,ST AUBIN SUR MER,,49.8888204893,0.875914099002,564,,Saint-Aubin-sur-Mer,Saint-Aubin-sur-Mer,76,Seine-Maritime,28,Normandie
76565,ST AUBIN SUR SCIE,76550,ST AUBIN SUR SCIE,,49.8824960547,1.07805397506,565,,Saint-Aubin-sur-Scie,Saint-Aubin-sur-Scie,76,Seine-Maritime,28,Normandie
76566,STE AUSTREBERTHE,76570,STE AUSTREBERTHE,,49.6017062639,0.972327447526,566,,Sainte-Austreberthe,Sainte-Austreberthe,76,Seine-Maritime,28,Normandie
76567,STE BEUVE EN RIVIERE,76270,STE BEUVE EN RIVIERE,,49.7583870059,1.52758714381,567,,Sainte-Beuve-en-Rivière,Sainte-Beuve-en-Rivière,76,Seine-Maritime,28,Normandie
76568,ST CLAIR SUR LES MONTS,76190,ST CLAIR SUR LES MONTS,,49.6020863264,0.784520444283,568,,Saint-Clair-sur-les-Monts,Saint-Clair-sur-les-Monts,76,Seine-Maritime,28,Normandie
76569,STE COLOMBE,76460,STE COLOMBE,,49.7963787198,0.761815502519,569,,Sainte-Colombe,Sainte-Colombe,76,Seine-Maritime,28,Normandie
76570,ST CRESPIN,76590,ST CRESPIN,,49.7741307345,1.11128726278,570,,Saint-Crespin,Saint-Crespin,76,Seine-Maritime,28,Normandie
76571,STE CROIX SUR BUCHY,76750,STE CROIX SUR BUCHY,,49.5629394597,1.34092504963,571,,Sainte-Croix-sur-Buchy,Sainte-Croix-sur-Buchy,76,Seine-Maritime,28,Normandie
76572,ST DENIS D ACLON,76860,ST DENIS D ACLON,,49.8632761964,0.943153726353,572,,Saint-Denis-d'Aclon,Saint-Denis-d'Aclon,76,Seine-Maritime,28,Normandie
76573,ST DENIS LE THIBOULT,76116,ST DENIS LE THIBOULT,,49.4593462802,1.35296066774,573,,Saint-Denis-le-Thiboult,Saint-Denis-le-Thiboult,76,Seine-Maritime,28,Normandie
76574,ST DENIS SUR SCIE,76890,ST DENIS SUR SCIE,,49.7031340848,1.08565728763,574,,Saint-Denis-sur-Scie,Saint-Denis-sur-Scie,76,Seine-Maritime,28,Normandie
76575,ST ETIENNE DU ROUVRAY,76800,ST ETIENNE DU ROUVRAY,,49.3814944369,1.08976892912,575,,Saint-Étienne-du-Rouvray,Saint-Étienne-du-Rouvray,76,Seine-Maritime,28,Normandie
76576,ST EUSTACHE LA FORET,76210,ST EUSTACHE LA FORET,,49.5542884203,0.451368836604,576,,Saint-Eustache-la-Forêt,Saint-Eustache-la-Forêt,76,Seine-Maritime,28,Normandie
76577,STE FOY,76590,STE FOY,,49.7989403084,1.1394452282,577,,Sainte-Foy,Sainte-Foy,76,Seine-Maritime,28,Normandie
76578,STE GENEVIEVE,76440,STE GENEVIEVE,,49.6585101081,1.42293352559,578,,Sainte-Geneviève,Sainte-Geneviève,76,Seine-Maritime,28,Normandie
76580,ST GEORGES SUR FONTAINE,76690,ST GEORGES SUR FONTAINE,,49.5471041519,1.17538219534,580,,Saint-Georges-sur-Fontaine,Saint-Georges-sur-Fontaine,76,Seine-Maritime,28,Normandie
76581,ST GERMAIN DES ESSOURTS,76750,ST GERMAIN DES ESSOURTS,,49.5337799591,1.315784761,581,,Saint-Germain-des-Essourts,Saint-Germain-des-Essourts,76,Seine-Maritime,28,Normandie
76582,ST GERMAIN D ETABLES,76590,ST GERMAIN D ETABLES,,49.8372754134,1.18137897384,582,,Saint-Germain-d'Étables,Saint-Germain-d'Étables,76,Seine-Maritime,28,Normandie
76583,ST GERMAIN SOUS CAILLY,76690,ST GERMAIN SOUS CAILLY,,49.578635059,1.20932162589,583,,Saint-Germain-sous-Cailly,Saint-Germain-sous-Cailly,76,Seine-Maritime,28,Normandie
76584,ST GERMAIN SUR EAULNE,76270,ST GERMAIN SUR EAULNE,,49.7659609183,1.51056712676,584,,Saint-Germain-sur-Eaulne,Saint-Germain-sur-Eaulne,76,Seine-Maritime,28,Normandie
76585,ST GILLES DE CRETOT,76490,ST GILLES DE CRETOT,,49.5581716513,0.652697455698,585,,Saint-Gilles-de-Crétot,Saint-Gilles-de-Crétot,76,Seine-Maritime,28,Normandie
76586,ST GILLES DE LA NEUVILLE,76430,ST GILLES DE LA NEUVILLE,,49.5728995947,0.370560315612,586,,Saint-Gilles-de-la-Neuville,Saint-Gilles-de-la-Neuville,76,Seine-Maritime,28,Normandie
76587,STE HELENE BONDEVILLE,76400,STE HELENE BONDEVILLE,,49.7715222464,0.460707972342,587,,Sainte-Hélène-Bondeville,Sainte-Hélène-Bondeville,76,Seine-Maritime,28,Normandie
76588,ST HELLIER,76680,ST HELLIER,,49.739289577,1.19753481134,588,,Saint-Hellier,Saint-Hellier,76,Seine-Maritime,28,Normandie
76589,ST HONORE,76590,ST HONORE,,49.7837336028,1.15649576765,589,,Saint-Honoré,Saint-Honoré,76,Seine-Maritime,28,Normandie
76590,ST JACQUES D ALIERMONT,76510,ST JACQUES D ALIERMONT,,49.8478920039,1.25191662151,590,,Saint-Jacques-d'Aliermont,Saint-Jacques-d'Aliermont,76,Seine-Maritime,28,Normandie
76591,ST JACQUES SUR DARNETAL,76160,ST JACQUES SUR DARNETAL,,49.4457877857,1.20822620847,591,,Saint-Jacques-sur-Darnétal,Saint-Jacques-sur-Darnétal,76,Seine-Maritime,28,Normandie
76592,ST JEAN DE FOLLEVILLE,76170,ST JEAN DE FOLLEVILLE,,49.505056753,0.499483150435,592,,Saint-Jean-de-Folleville,Saint-Jean-de-Folleville,76,Seine-Maritime,28,Normandie
76593,ST JEAN DE LA NEUVILLE,76210,ST JEAN DE LA NEUVILLE,,49.5749733786,0.428297913723,593,,Saint-Jean-de-la-Neuville,Saint-Jean-de-la-Neuville,76,Seine-Maritime,28,Normandie
76594,ST JEAN DU CARDONNAY,76150,ST JEAN DU CARDONNAY,,49.4997223949,1.01068314715,594,,Saint-Jean-du-Cardonnay,Saint-Jean-du-Cardonnay,76,Seine-Maritime,28,Normandie
76595,ST JOUIN BRUNEVAL,76280,ST JOUIN BRUNEVAL,,49.6388529108,0.17807226777,595,,Saint-Jouin-Bruneval,Saint-Jouin-Bruneval,76,Seine-Maritime,28,Normandie
76596,ST LAURENT DE BREVEDENT,76700,ST LAURENT DE BREVEDENT,,49.5325966653,0.269281714398,596,,Saint-Laurent-de-Brèvedent,Saint-Laurent-de-Brèvedent,76,Seine-Maritime,28,Normandie
76597,ST LAURENT EN CAUX,76560,ST LAURENT EN CAUX,,49.74310061,0.887020117235,597,,Saint-Laurent-en-Caux,Saint-Laurent-en-Caux,76,Seine-Maritime,28,Normandie
76598,ST LEGER AUX BOIS,76340,ST LEGER AUX BOIS,,49.8298026254,1.61197494699,598,,Saint-Léger-aux-Bois,Saint-Léger-aux-Bois,76,Seine-Maritime,28,Normandie
76599,ST LEGER DU BOURG DENIS,76160,ST LEGER DU BOURG DENIS,,49.4299525675,1.16347791437,599,,Saint-Léger-du-Bourg-Denis,Saint-Léger-du-Bourg-Denis,76,Seine-Maritime,28,Normandie
76600,ST LEONARD,76400,ST LEONARD,,49.7321818322,0.341907614977,600,,Saint-Léonard,Saint-Léonard,76,Seine-Maritime,28,Normandie
76602,ST MACLOU DE FOLLEVILLE,76890,ST MACLOU DE FOLLEVILLE,,49.6759343416,1.09161187212,602,,Saint-Maclou-de-Folleville,Saint-Maclou-de-Folleville,76,Seine-Maritime,28,Normandie
76603,ST MACLOU LA BRIERE,76110,ST MACLOU LA BRIERE,,49.6489375092,0.469956750588,603,,Saint-Maclou-la-Brière,Saint-Maclou-la-Brière,76,Seine-Maritime,28,Normandie
76604,ST MARDS,76730,ST MARDS,,49.7605945476,1.01478157397,604,,Saint-Mards,Saint-Mards,76,Seine-Maritime,28,Normandie
76605,STE MARGUERITE SUR MER,76119,STE MARGUERITE SUR MER,,49.9065952849,0.955245965887,605,,Sainte-Marguerite-sur-Mer,Sainte-Marguerite-sur-Mer,76,Seine-Maritime,28,Normandie
76606,MORIENNE,76390,MORIENNE,,49.7817068976,1.72151986696,606,,Morienne,Morienne,76,Seine-Maritime,28,Normandie
76607,STE MARGUERITE SUR FAUVILLE,76640,STE MARGUERITE SUR FAUVILLE,,49.6722952773,0.595727361653,607,,Sainte-Marguerite-sur-Fauville,Sainte-Marguerite-sur-Fauville,76,Seine-Maritime,28,Normandie
76608,STE MARGUERITE SUR DUCLAIR,76480,STE MARGUERITE SUR DUCLAIR,,49.5122889935,0.821700775963,608,,Sainte-Marguerite-sur-Duclair,Sainte-Marguerite-sur-Duclair,76,Seine-Maritime,28,Normandie
76609,STE MARIE AU BOSC,76280,STE MARIE AU BOSC,,49.6652973868,0.204761684966,609,,Sainte-Marie-au-Bosc,Sainte-Marie-au-Bosc,76,Seine-Maritime,28,Normandie
76610,STE MARIE DES CHAMPS,76190,STE MARIE DES CHAMPS,,49.6259386708,0.781814222098,610,,Sainte-Marie-des-Champs,Sainte-Marie-des-Champs,76,Seine-Maritime,28,Normandie
76611,ST MARTIN AUX ARBRES,76760,ST MARTIN AUX ARBRES,,49.6507295015,0.890163763501,611,,Saint-Martin-aux-Arbres,Saint-Martin-aux-Arbres,76,Seine-Maritime,28,Normandie
76612,ST MARTIN AU BOSC,76340,ST MARTIN AU BOSC,,49.8316316628,1.66866654191,612,,Saint-Martin-au-Bosc,Saint-Martin-au-Bosc,76,Seine-Maritime,28,Normandie
76613,ST MARTIN AUX BUNEAUX,76450,ST MARTIN AUX BUNEAUX,,49.8270470687,0.550567234942,613,,Saint-Martin-aux-Buneaux,Saint-Martin-aux-Buneaux,76,Seine-Maritime,28,Normandie
76613,ST MARTIN AUX BUNEAUX,76540,ST MARTIN AUX BUNEAUX,,49.8270470687,0.550567234942,613,,Saint-Martin-aux-Buneaux,Saint-Martin-aux-Buneaux,76,Seine-Maritime,28,Normandie
76614,ST MARTIN DE BOSCHERVILLE,76840,ST MARTIN DE BOSCHERVILLE,,49.4445921802,0.962799831004,614,,Saint-Martin-de-Boscherville,Saint-Martin-de-Boscherville,76,Seine-Maritime,28,Normandie
76615,ST MARTIN DU BEC,76133,ST MARTIN DU BEC,,49.6006687122,0.202278495789,615,,Saint-Martin-du-Bec,Saint-Martin-du-Bec,76,Seine-Maritime,28,Normandie
76616,ST MARTIN DU MANOIR,76290,ST MARTIN DU MANOIR,,49.5371452361,0.234830159946,616,,Saint-Martin-du-Manoir,Saint-Martin-du-Manoir,76,Seine-Maritime,28,Normandie
76617,ST MARTIN DU VIVIER,76160,ST MARTIN DU VIVIER,,49.470396647,1.15533430797,617,,Saint-Martin-du-Vivier,Saint-Martin-du-Vivier,76,Seine-Maritime,28,Normandie
76618,PETIT CAUX,76370,PETIT CAUX,BELLEVILLE SUR MER,49.9614365259,1.21910748031,618,,Petit-Caux,Petit-Caux,76,Seine-Maritime,28,Normandie
76618,PETIT CAUX,76370,PETIT CAUX,BERNEVAL LE GRAND,49.9614365259,1.21910748031,618,,Petit-Caux,Petit-Caux,76,Seine-Maritime,28,Normandie
76618,PETIT CAUX,76370,PETIT CAUX,BRACQUEMONT,49.9614365259,1.21910748031,618,,Petit-Caux,Petit-Caux,76,Seine-Maritime,28,Normandie
76618,PETIT CAUX,76370,PETIT CAUX,DERCHIGNY,49.9614365259,1.21910748031,618,,Petit-Caux,Petit-Caux,76,Seine-Maritime,28,Normandie
76618,PETIT CAUX,76370,PETIT CAUX,ST MARTIN EN CAMPAGNE,49.9614365259,1.21910748031,618,,Petit-Caux,Petit-Caux,76,Seine-Maritime,28,Normandie
76618,PETIT CAUX,76630,PETIT CAUX,ASSIGNY,49.9614365259,1.21910748031,618,,Petit-Caux,Petit-Caux,76,Seine-Maritime,28,Normandie
76618,PETIT CAUX,76630,PETIT CAUX,AUQUEMESNIL,49.9614365259,1.21910748031,618,,Petit-Caux,Petit-Caux,76,Seine-Maritime,28,Normandie
76618,PETIT CAUX,76630,PETIT CAUX,BIVILLE SUR MER,49.9614365259,1.21910748031,618,,Petit-Caux,Petit-Caux,76,Seine-Maritime,28,Normandie
76618,PETIT CAUX,76630,PETIT CAUX,BRUNVILLE,49.9614365259,1.21910748031,618,,Petit-Caux,Petit-Caux,76,Seine-Maritime,28,Normandie
76618,PETIT CAUX,76630,PETIT CAUX,GLICOURT,49.9614365259,1.21910748031,618,,Petit-Caux,Petit-Caux,76,Seine-Maritime,28,Normandie
76618,PETIT CAUX,76630,PETIT CAUX,GOUCHAUPRE,49.9614365259,1.21910748031,618,,Petit-Caux,Petit-Caux,76,Seine-Maritime,28,Normandie
76618,PETIT CAUX,76630,PETIT CAUX,GRENY,49.9614365259,1.21910748031,618,,Petit-Caux,Petit-Caux,76,Seine-Maritime,28,Normandie
76618,PETIT CAUX,76630,PETIT CAUX,GUILMECOURT,49.9614365259,1.21910748031,618,,Petit-Caux,Petit-Caux,76,Seine-Maritime,28,Normandie
76618,PETIT CAUX,76630,PETIT CAUX,INTRAVILLE,49.9614365259,1.21910748031,618,,Petit-Caux,Petit-Caux,76,Seine-Maritime,28,Normandie
76618,PETIT CAUX,76630,PETIT CAUX,PENLY,49.9614365259,1.21910748031,618,,Petit-Caux,Petit-Caux,76,Seine-Maritime,28,Normandie
76618,PETIT CAUX,76630,PETIT CAUX,ST QUENTIN AU BOSC,49.9614365259,1.21910748031,618,,Petit-Caux,Petit-Caux,76,Seine-Maritime,28,Normandie
76618,PETIT CAUX,76630,PETIT CAUX,TOURVILLE LA CHAPELLE,49.9614365259,1.21910748031,618,,Petit-Caux,Petit-Caux,76,Seine-Maritime,28,Normandie
76618,PETIT CAUX,76910,PETIT CAUX,TOCQUEVILLE SUR EU,49.9614365259,1.21910748031,618,,Petit-Caux,Petit-Caux,76,Seine-Maritime,28,Normandie
76619,ST MARTIN LE GAILLARD,76260,ST MARTIN LE GAILLARD,,49.9747722356,1.36740451033,619,,Saint-Martin-le-Gaillard,Saint-Martin-le-Gaillard,76,Seine-Maritime,28,Normandie
76620,ST MARTIN L HORTIER,76270,ST MARTIN L HORTIER,,49.7458247482,1.40341149871,620,,Saint-Martin-l'Hortier,Saint-Martin-l'Hortier,76,Seine-Maritime,28,Normandie
76621,ST MARTIN OSMONVILLE,76680,ST MARTIN OSMONVILLE,,49.6419438323,1.30112980694,621,,Saint-Martin-Osmonville,Saint-Martin-Osmonville,76,Seine-Maritime,28,Normandie
76622,ST MAURICE D ETELAN,76330,ST MAURICE D ETELAN,,49.4585668505,0.625051868596,622,,Saint-Maurice-d'Ételan,Saint-Maurice-d'Ételan,76,Seine-Maritime,28,Normandie
76623,ST MICHEL D HALESCOURT,76440,ST MICHEL D HALESCOURT,,49.6092134017,1.65667640584,623,,Saint-Michel-d'Halescourt,Saint-Michel-d'Halescourt,76,Seine-Maritime,28,Normandie
76624,ST NICOLAS D ALIERMONT,76510,ST NICOLAS D ALIERMONT,,49.874204912,1.23753225509,624,,Saint-Nicolas-d'Aliermont,Saint-Nicolas-d'Aliermont,76,Seine-Maritime,28,Normandie
76626,ST NICOLAS DE LA HAIE,76490,ST NICOLAS DE LA HAIE,,49.5601616388,0.617932004482,626,,Saint-Nicolas-de-la-Haie,Saint-Nicolas-de-la-Haie,76,Seine-Maritime,28,Normandie
76627,ST NICOLAS DE LA TAILLE,76170,ST NICOLAS DE LA TAILLE,,49.5142461624,0.469748142388,627,,Saint-Nicolas-de-la-Taille,Saint-Nicolas-de-la-Taille,76,Seine-Maritime,28,Normandie
76628,ST OUEN DU BREUIL,76890,ST OUEN DU BREUIL,,49.6321260535,1.02390924063,628,,Saint-Ouen-du-Breuil,Saint-Ouen-du-Breuil,76,Seine-Maritime,28,Normandie
76629,ST OUEN LE MAUGER,76730,ST OUEN LE MAUGER,,49.7600761839,0.959021389482,629,,Saint-Ouen-le-Mauger,Saint-Ouen-le-Mauger,76,Seine-Maritime,28,Normandie
76630,ST OUEN SOUS BAILLY,76630,ST OUEN SOUS BAILLY,,49.9029517365,1.30095112624,630,,Saint-Ouen-sous-Bailly,Saint-Ouen-sous-Bailly,76,Seine-Maritime,28,Normandie
76631,ST PAER,76480,ST PAER,,49.5236063691,0.882805542616,631,,Saint-Paër,Saint-Paër,76,Seine-Maritime,28,Normandie
76632,ST PIERRE BENOUVILLE,76890,ST PIERRE BENOUVILLE,,49.7384423102,0.97405131386,632,,Saint-Pierre-Bénouville,Saint-Pierre-Bénouville,76,Seine-Maritime,28,Normandie
76634,ST PIERRE DE MANNEVILLE,76113,ST PIERRE DE MANNEVILLE,,49.3925053636,0.941378967074,634,,Saint-Pierre-de-Manneville,Saint-Pierre-de-Manneville,76,Seine-Maritime,28,Normandie
76635,ST PIERRE DES JONQUIERES,76660,ST PIERRE DES JONQUIERES,,49.8485799003,1.45100007489,635,,Saint-Pierre-des-Jonquières,Saint-Pierre-des-Jonquières,76,Seine-Maritime,28,Normandie
76636,ST PIERRE DE VARENGEVILLE,76480,ST PIERRE DE VARENGEVILLE,,49.5016042328,0.923668432121,636,,Saint-Pierre-de-Varengeville,Saint-Pierre-de-Varengeville,76,Seine-Maritime,28,Normandie
76637,ST PIERRE EN PORT,76540,ST PIERRE EN PORT,,49.8072086102,0.500314301045,637,,Saint-Pierre-en-Port,Saint-Pierre-en-Port,76,Seine-Maritime,28,Normandie
76638,ST PIERRE EN VAL,76260,ST PIERRE EN VAL,,50.0107632796,1.43978370706,638,,Saint-Pierre-en-Val,Saint-Pierre-en-Val,76,Seine-Maritime,28,Normandie
76639,ST PIERRE LAVIS,76640,ST PIERRE LAVIS,,49.6643345859,0.623128334063,639,,Saint-Pierre-Lavis,Saint-Pierre-Lavis,76,Seine-Maritime,28,Normandie
76640,ST PIERRE LES ELBEUF,76320,ST PIERRE LES ELBEUF,,49.2778967618,1.04793816865,640,,Saint-Pierre-lès-Elbeuf,Saint-Pierre-lès-Elbeuf,76,Seine-Maritime,28,Normandie
76641,ST PIERRE LE VIEUX,76740,ST PIERRE LE VIEUX,,49.8488151047,0.885165037605,641,,Saint-Pierre-le-Vieux,Saint-Pierre-le-Vieux,76,Seine-Maritime,28,Normandie
76642,ST PIERRE LE VIGER,76740,ST PIERRE LE VIGER,,49.8268946102,0.849728214011,642,,Saint-Pierre-le-Viger,Saint-Pierre-le-Viger,76,Seine-Maritime,28,Normandie
76644,ST REMY BOSCROCOURT,76260,ST REMY BOSCROCOURT,,50.009950243,1.39185007909,644,,Saint-Rémy-Boscrocourt,Saint-Rémy-Boscrocourt,76,Seine-Maritime,28,Normandie
76645,ST RIQUIER EN RIVIERE,76340,ST RIQUIER EN RIVIERE,,49.8845471571,1.57255279378,645,,Saint-Riquier-en-Rivière,Saint-Riquier-en-Rivière,76,Seine-Maritime,28,Normandie
76646,ST RIQUIER ES PLAINS,76460,ST RIQUIER ES PLAINS,,49.8203788901,0.666311281625,646,,Saint-Riquier-ès-Plains,Saint-Riquier-ès-Plains,76,Seine-Maritime,28,Normandie
76647,ST ROMAIN DE COLBOSC,76430,ST ROMAIN DE COLBOSC,,49.5286231796,0.363829451738,647,,Saint-Romain-de-Colbosc,Saint-Romain-de-Colbosc,76,Seine-Maritime,28,Normandie
76648,ST SAENS,76680,ST SAENS,,49.6745670639,1.27720644287,648,,Saint-Saëns,Saint-Saëns,76,Seine-Maritime,28,Normandie
76649,ST SAIRE,76270,ST SAIRE,,49.6816711457,1.48096971774,649,,Saint-Saire,Saint-Saire,76,Seine-Maritime,28,Normandie
76650,ST SAUVEUR D EMALLEVILLE,76110,ST SAUVEUR D EMALLEVILLE,,49.6113638216,0.306191805081,650,,Saint-Sauveur-d'Émalleville,Saint-Sauveur-d'Émalleville,76,Seine-Maritime,28,Normandie
76651,ST SYLVAIN,76460,ST SYLVAIN,,49.8484962406,0.659644350897,651,,Saint-Sylvain,Saint-Sylvain,76,Seine-Maritime,28,Normandie
76652,ST VAAST D EQUIQUEVILLE,76510,ST VAAST D EQUIQUEVILLE,,49.8151815963,1.26095923266,652,,Saint-Vaast-d'Équiqueville,Saint-Vaast-d'Équiqueville,76,Seine-Maritime,28,Normandie
76653,ST VAAST DIEPPEDALLE,76450,ST VAAST DIEPPEDALLE,,49.7575768119,0.722349348427,653,,Saint-Vaast-Dieppedalle,Saint-Vaast-Dieppedalle,76,Seine-Maritime,28,Normandie
76654,ST VAAST DU VAL,76890,ST VAAST DU VAL,,49.6860662177,1.00949278238,654,,Saint-Vaast-du-Val,Saint-Vaast-du-Val,76,Seine-Maritime,28,Normandie
76655,ST VALERY EN CAUX,76460,ST VALERY EN CAUX,,49.85975403,0.70707488248,655,,Saint-Valery-en-Caux,Saint-Valery-en-Caux,76,Seine-Maritime,28,Normandie
76656,ST VICTOR L ABBAYE,76890,ST VICTOR L ABBAYE,,49.664925198,1.1242184406,656,,Saint-Victor-l'Abbaye,Saint-Victor-l'Abbaye,76,Seine-Maritime,28,Normandie
76657,ST VIGOR D YMONVILLE,76430,ST VIGOR D YMONVILLE,,49.4688378023,0.347654675669,657,,Saint-Vigor-d'Ymonville,Saint-Vigor-d'Ymonville,76,Seine-Maritime,28,Normandie
76658,ST VINCENT CRAMESNIL,76430,ST VINCENT CRAMESNIL,,49.5089339336,0.359848659438,658,,Saint-Vincent-Cramesnil,Saint-Vincent-Cramesnil,76,Seine-Maritime,28,Normandie
76660,SANDOUVILLE,76430,SANDOUVILLE,,49.4777178239,0.301065445929,660,,Sandouville,Sandouville,76,Seine-Maritime,28,Normandie
76662,SASSETOT LE MALGARDE,76730,SASSETOT LE MALGARDE,,49.7720010811,0.895836596753,662,,Sassetot-le-Malgardé,Sassetot-le-Malgardé,76,Seine-Maritime,28,Normandie
76663,SASSETOT LE MAUCONDUIT,76540,SASSETOT LE MAUCONDUIT,,49.7993640965,0.528771172239,663,,Sassetot-le-Mauconduit,Sassetot-le-Mauconduit,76,Seine-Maritime,28,Normandie
76663,SASSETOT LE MAUCONDUIT,76540,SASSETOT LE MAUCONDUIT,LES GRANDES DALLES,49.7993640965,0.528771172239,663,,Sassetot-le-Mauconduit,Sassetot-le-Mauconduit,76,Seine-Maritime,28,Normandie
76663,SASSETOT LE MAUCONDUIT,76540,SASSETOT LE MAUCONDUIT,LES PETITES DALLES,49.7993640965,0.528771172239,663,,Sassetot-le-Mauconduit,Sassetot-le-Mauconduit,76,Seine-Maritime,28,Normandie
76664,SASSEVILLE,76450,SASSEVILLE,,49.7829827981,0.690793795865,664,,Sasseville,Sasseville,76,Seine-Maritime,28,Normandie
76665,SAUCHAY,76630,SAUCHAY,,49.9194809701,1.20513608482,665,,Sauchay,Sauchay,76,Seine-Maritime,28,Normandie
76666,SAUMONT LA POTERIE,76440,SAUMONT LA POTERIE,,49.5773305007,1.61490948438,666,,Saumont-la-Poterie,Saumont-la-Poterie,76,Seine-Maritime,28,Normandie
76667,SAUQUEVILLE,76550,SAUQUEVILLE,,49.8482791922,1.05950261168,667,,Sauqueville,Sauqueville,76,Seine-Maritime,28,Normandie
76668,SAUSSAY,76760,SAUSSAY,,49.6369146276,0.925129997782,668,,Saussay,Saussay,76,Seine-Maritime,28,Normandie
76669,SAUSSEUZEMARE EN CAUX,76110,SAUSSEUZEMARE EN CAUX,,49.6710119926,0.350018167711,669,,Sausseuzemare-en-Caux,Sausseuzemare-en-Caux,76,Seine-Maritime,28,Normandie
76670,SENNEVILLE SUR FECAMP,76400,SENNEVILLE SUR FECAMP,,49.7721134849,0.41525001597,670,,Senneville-sur-Fécamp,Senneville-sur-Fécamp,76,Seine-Maritime,28,Normandie
76671,SEPT MEULES,76260,SEPT MEULES,,49.9467142291,1.41986298545,671,,Sept-Meules,Sept-Meules,76,Seine-Maritime,28,Normandie
76672,SERQUEUX,76440,SERQUEUX,,49.627931643,1.54524472331,672,,Serqueux,Serqueux,76,Seine-Maritime,28,Normandie
76673,SERVAVILLE SALMONVILLE,76116,SERVAVILLE SALMONVILLE,,49.4774090441,1.27092811678,673,,Servaville-Salmonville,Servaville-Salmonville,76,Seine-Maritime,28,Normandie
76674,SEVIS,76850,SEVIS,,49.7118820005,1.17834673118,674,,Sévis,Sévis,76,Seine-Maritime,28,Normandie
76675,SIERVILLE,76690,SIERVILLE,,49.5915709945,1.03742775738,675,,Sierville,Sierville,76,Seine-Maritime,28,Normandie
76676,SIGY EN BRAY,76780,SIGY EN BRAY,,49.5335628852,1.46692134698,676,,Sigy-en-Bray,Sigy-en-Bray,76,Seine-Maritime,28,Normandie
76676,SIGY EN BRAY,76780,SIGY EN BRAY,ST LUCIEN,49.5335628852,1.46692134698,676,,Sigy-en-Bray,Sigy-en-Bray,76,Seine-Maritime,28,Normandie
76677,SMERMESNIL,76660,SMERMESNIL,,49.854797,1.48691463898,677,,Smermesnil,Smermesnil,76,Seine-Maritime,28,Normandie
76678,SOMMERY,76440,SOMMERY,,49.6386546895,1.4466058642,678,,Sommery,Sommery,76,Seine-Maritime,28,Normandie
76679,SOMMESNIL,76560,SOMMESNIL,,49.7106673423,0.671152911759,679,,Sommesnil,Sommesnil,76,Seine-Maritime,28,Normandie
76680,SORQUAINVILLE,76540,SORQUAINVILLE,,49.7033186673,0.55523073972,680,,Sorquainville,Sorquainville,76,Seine-Maritime,28,Normandie
76681,SOTTEVILLE LES ROUEN,76300,SOTTEVILLE LES ROUEN,,49.4104887444,1.09509946531,681,,Sotteville-lès-Rouen,Sotteville-lès-Rouen,76,Seine-Maritime,28,Normandie
76682,SOTTEVILLE SOUS LE VAL,76410,SOTTEVILLE SOUS LE VAL,,49.3172603768,1.11637971624,682,,Sotteville-sous-le-Val,Sotteville-sous-le-Val,76,Seine-Maritime,28,Normandie
76683,SOTTEVILLE SUR MER,76740,SOTTEVILLE SUR MER,,49.8731818649,0.827643384885,683,,Sotteville-sur-Mer,Sotteville-sur-Mer,76,Seine-Maritime,28,Normandie
76684,TANCARVILLE,76430,TANCARVILLE,,49.4813646581,0.451857547923,684,,Tancarville,Tancarville,76,Seine-Maritime,28,Normandie
76685,THEROULDEVILLE,76540,THEROULDEVILLE,,49.7564638049,0.523235594162,685,,Thérouldeville,Thérouldeville,76,Seine-Maritime,28,Normandie
76686,THEUVILLE AUX MAILLOTS,76540,THEUVILLE AUX MAILLOTS,,49.7617261157,0.546067666828,686,,Theuville-aux-Maillots,Theuville-aux-Maillots,76,Seine-Maritime,28,Normandie
76688,THIERGEVILLE,76540,THIERGEVILLE,,49.7282499178,0.4881028317,688,,Thiergeville,Thiergeville,76,Seine-Maritime,28,Normandie
76689,THIETREVILLE,76540,THIETREVILLE,,49.7197053682,0.518166306169,689,,Thiétreville,Thiétreville,76,Seine-Maritime,28,Normandie
76690,THIL MANNEVILLE,76730,THIL MANNEVILLE,,49.8398223371,0.995926540193,690,,Thil-Manneville,Thil-Manneville,76,Seine-Maritime,28,Normandie
76691,LE THIL RIBERPRE,76440,LE THIL RIBERPRE,,49.6378126411,1.57999987138,691,Le,Thil-Riberpré,Le Thil-Riberpré,76,Seine-Maritime,28,Normandie
76692,THIOUVILLE,76450,THIOUVILLE,,49.6878940847,0.627618388941,692,,Thiouville,Thiouville,76,Seine-Maritime,28,Normandie
76693,LE TILLEUL,76790,LE TILLEUL,,49.6856822888,0.204465863098,693,Le,Tilleul,Le Tilleul,76,Seine-Maritime,28,Normandie
76694,TOCQUEVILLE EN CAUX,76730,TOCQUEVILLE EN CAUX,,49.7833597392,0.909677051286,694,,Tocqueville-en-Caux,Tocqueville-en-Caux,76,Seine-Maritime,28,Normandie
76695,TOCQUEVILLE LES MURS,76110,TOCQUEVILLE LES MURS,,49.6630245731,0.500760305403,695,,Tocqueville-les-Murs,Tocqueville-les-Murs,76,Seine-Maritime,28,Normandie
76697,TORCY LE GRAND,76590,TORCY LE GRAND,,49.7943785758,1.17719285853,697,,Torcy-le-Grand,Torcy-le-Grand,76,Seine-Maritime,28,Normandie
76698,TORCY LE PETIT,76590,TORCY LE PETIT,,49.8151632536,1.18043117883,698,,Torcy-le-Petit,Torcy-le-Petit,76,Seine-Maritime,28,Normandie
76699,LE TORP MESNIL,76560,LE TORP MESNIL,,49.7257071059,0.905083939964,699,Le,Torp-Mesnil,Le Torp-Mesnil,76,Seine-Maritime,28,Normandie
76700,TOTES,76890,TOTES,,49.6835299658,1.04261362963,700,,Tôtes,Tôtes,76,Seine-Maritime,28,Normandie
76702,TOUFFREVILLE LA CORBELINE,76190,TOUFFREVILLE LA CORBELINE,,49.5845925049,0.764046318102,702,,Touffreville-la-Corbeline,Touffreville-la-Corbeline,76,Seine-Maritime,28,Normandie
76703,TOUFFREVILLE SUR EU,76910,TOUFFREVILLE SUR EU,,49.9919570086,1.32074809461,703,,Touffreville-sur-Eu,Touffreville-sur-Eu,76,Seine-Maritime,28,Normandie
76705,TOURVILLE LA RIVIERE,76410,TOURVILLE LA RIVIERE,,49.3274627821,1.09185339831,705,,Tourville-la-Rivière,Tourville-la-Rivière,76,Seine-Maritime,28,Normandie
76706,TOURVILLE LES IFS,76400,TOURVILLE LES IFS,,49.7120375764,0.399997077372,706,,Tourville-les-Ifs,Tourville-les-Ifs,76,Seine-Maritime,28,Normandie
76707,TOURVILLE SUR ARQUES,76550,TOURVILLE SUR ARQUES,,49.8597127895,1.09318958112,707,,Tourville-sur-Arques,Tourville-sur-Arques,76,Seine-Maritime,28,Normandie
76708,TOUSSAINT,76400,TOUSSAINT,,49.7368198759,0.43974783787,708,,Toussaint,Toussaint,76,Seine-Maritime,28,Normandie
76709,LE TRAIT,76580,LE TRAIT,,49.4842765452,0.81921057836,709,Le,Trait,Le Trait,76,Seine-Maritime,28,Normandie
76710,TREMAUVILLE,76640,TREMAUVILLE,,49.6649596837,0.521421339648,710,,Trémauville,Trémauville,76,Seine-Maritime,28,Normandie
76711,LE TREPORT,76470,LE TREPORT,,50.053376282,1.372617602,711,Le,Tréport,Le Tréport,76,Seine-Maritime,28,Normandie
76712,LA TRINITE DU MONT,76170,LA TRINITE DU MONT,,49.5455789044,0.557898876882,712,La,Trinité-du-Mont,La Trinité-du-Mont,76,Seine-Maritime,28,Normandie
76714,LES TROIS PIERRES,76430,LES TROIS PIERRES,,49.5508324956,0.402557046939,714,Les,Trois-Pierres,Les Trois-Pierres,76,Seine-Maritime,28,Normandie
76715,TROUVILLE,76210,TROUVILLE,,49.584302439,0.60043974665,715,,Trouville,Trouville,76,Seine-Maritime,28,Normandie
76716,TURRETOT,76280,TURRETOT,,49.6100649782,0.240595892185,716,,Turretot,Turretot,76,Seine-Maritime,28,Normandie
76717,VAL DE LA HAYE,76380,VAL DE LA HAYE,,49.386140855,0.990005122021,717,,Val-de-la-Haye,Val-de-la-Haye,76,Seine-Maritime,28,Normandie
76718,VALLIQUERVILLE,76190,VALLIQUERVILLE,,49.6169921785,0.710791599011,718,,Valliquerville,Valliquerville,76,Seine-Maritime,28,Normandie
76719,VALMONT,76540,VALMONT,,49.7399725862,0.512635671839,719,,Valmont,Valmont,76,Seine-Maritime,28,Normandie
76720,VARENGEVILLE SUR MER,76119,VARENGEVILLE SUR MER,,49.9033508402,0.996096920995,720,,Varengeville-sur-Mer,Varengeville-sur-Mer,76,Seine-Maritime,28,Normandie
76721,VARNEVILLE BRETTEVILLE,76890,VARNEVILLE BRETTEVILLE,,49.6545398173,1.04295071603,721,,Varneville-Bretteville,Varneville-Bretteville,76,Seine-Maritime,28,Normandie
76723,VASSONVILLE,76890,VASSONVILLE,,49.6886808742,1.0764967043,723,,Vassonville,Vassonville,76,Seine-Maritime,28,Normandie
76724,VATIERVILLE,76270,VATIERVILLE,,49.7878030186,1.51841416883,724,,Vatierville,Vatierville,76,Seine-Maritime,28,Normandie
76725,VATTETOT SOUS BEAUMONT,76110,VATTETOT SOUS BEAUMONT,,49.6285330749,0.44917171315,725,,Vattetot-sous-Beaumont,Vattetot-sous-Beaumont,76,Seine-Maritime,28,Normandie
76726,VATTETOT SUR MER,76111,VATTETOT SUR MER,,49.7227434222,0.286309367017,726,,Vattetot-sur-Mer,Vattetot-sur-Mer,76,Seine-Maritime,28,Normandie
76727,VATTEVILLE LA RUE,76940,VATTEVILLE LA RUE,,49.4536083557,0.684145070402,727,,Vatteville-la-Rue,Vatteville-la-Rue,76,Seine-Maritime,28,Normandie
76728,LA VAUPALIERE,76150,LA VAUPALIERE,,49.4817281,0.994239505882,728,La,Vaupalière,La Vaupalière,76,Seine-Maritime,28,Normandie
76729,VEAUVILLE LES BAONS,76190,VEAUVILLE LES BAONS,,49.6543611356,0.759245923284,729,,Veauville-lès-Baons,Veauville-lès-Baons,76,Seine-Maritime,28,Normandie
76730,VEAUVILLE LES QUELLES,76560,VEAUVILLE LES QUELLES,,49.7379926109,0.711257382258,730,,Veauville-lès-Quelles,Veauville-lès-Quelles,76,Seine-Maritime,28,Normandie
76731,VENESTANVILLE,76730,VENESTANVILLE,,49.793944609,0.898683720517,731,,Vénestanville,Vénestanville,76,Seine-Maritime,28,Normandie
76732,BUTOT VENESVILLE,76450,BUTOT VENESVILLE,,49.8159183889,0.58596239014,732,,Butot-Vénesville,Butot-Vénesville,76,Seine-Maritime,28,Normandie
76732,BUTOT VENESVILLE,76450,BUTOT VENESVILLE,BUTOT EN CAUX,49.8159183889,0.58596239014,732,,Butot-Vénesville,Butot-Vénesville,76,Seine-Maritime,28,Normandie
76733,VENTES ST REMY,76680,VENTES ST REMY,,49.7116173858,1.300245377,733,,Ventes-Saint-Rémy,Ventes-Saint-Rémy,76,Seine-Maritime,28,Normandie
76734,VERGETOT,76280,VERGETOT,,49.6115922738,0.28186227301,734,,Vergetot,Vergetot,76,Seine-Maritime,28,Normandie
76735,VEULES LES ROSES,76980,VEULES LES ROSES,,49.8667629374,0.78608035194,735,,Veules-les-Roses,Veules-les-Roses,76,Seine-Maritime,28,Normandie
76736,VEULETTES SUR MER,76450,VEULETTES SUR MER,,49.8449825714,0.587479052642,736,,Veulettes-sur-Mer,Veulettes-sur-Mer,76,Seine-Maritime,28,Normandie
76737,VIBEUF,76760,VIBEUF,,49.6896445459,0.912928114033,737,,Vibeuf,Vibeuf,76,Seine-Maritime,28,Normandie
76738,VIEUX MANOIR,76750,VIEUX MANOIR,,49.5655818528,1.29487199478,738,,Vieux-Manoir,Vieux-Manoir,76,Seine-Maritime,28,Normandie
76739,VIEUX ROUEN SUR BRESLE,76390,VIEUX ROUEN SUR BRESLE,,49.8321187176,1.70738128107,739,,Vieux-Rouen-sur-Bresle,Vieux-Rouen-sur-Bresle,76,Seine-Maritime,28,Normandie
76740,LA VIEUX RUE,76160,LA VIEUX RUE,,49.4963584129,1.24607289446,740,La,Vieux-Rue,La Vieux-Rue,76,Seine-Maritime,28,Normandie
76741,VILLAINVILLE,76280,VILLAINVILLE,,49.6596668934,0.239594399689,741,,Villainville,Villainville,76,Seine-Maritime,28,Normandie
76743,VILLERS ECALLES,76360,VILLERS ECALLES,,49.5351967025,0.926845120481,743,,Villers-Écalles,Villers-Écalles,76,Seine-Maritime,28,Normandie
76744,VILLERS SOUS FOUCARMONT,76340,VILLERS SOUS FOUCARMONT,,49.8254730877,1.55839902863,744,,Villers-sous-Foucarmont,Villers-sous-Foucarmont,76,Seine-Maritime,28,Normandie
76745,VILLY SUR YERES,76260,VILLY SUR YERES,,49.9413427521,1.45724552961,745,,Villy-sur-Yères,Villy-sur-Yères,76,Seine-Maritime,28,Normandie
76746,VINNEMERVILLE,76540,VINNEMERVILLE,,49.8075939956,0.555993402773,746,,Vinnemerville,Vinnemerville,76,Seine-Maritime,28,Normandie
76747,VIRVILLE,76110,VIRVILLE,,49.5920777641,0.356256177723,747,,Virville,Virville,76,Seine-Maritime,28,Normandie
76748,VITTEFLEUR,76450,VITTEFLEUR,,49.8138689184,0.637304764748,748,,Vittefleur,Vittefleur,76,Seine-Maritime,28,Normandie
76749,WANCHY CAPVAL,76660,WANCHY CAPVAL,,49.8640702539,1.37097161732,749,,Wanchy-Capval,Wanchy-Capval,76,Seine-Maritime,28,Normandie
76750,YAINVILLE,76480,YAINVILLE,,49.4569629192,0.827427548467,750,,Yainville,Yainville,76,Seine-Maritime,28,Normandie
76751,YEBLERON,76640,YEBLERON,,49.630973351,0.53381785323,751,,Yébleron,Yébleron,76,Seine-Maritime,28,Normandie
76752,YERVILLE,76760,YERVILLE,,49.6715515779,0.887001008089,752,,Yerville,Yerville,76,Seine-Maritime,28,Normandie
76753,YMARE,76520,YMARE,,49.3472419057,1.16708645894,753,,Ymare,Ymare,76,Seine-Maritime,28,Normandie
76754,YPORT,76111,YPORT,,49.7346149721,0.314361829499,754,,Yport,Yport,76,Seine-Maritime,28,Normandie
76755,YPREVILLE BIVILLE,76540,YPREVILLE BIVILLE,,49.6944640581,0.530994381028,755,,Ypreville-Biville,Ypreville-Biville,76,Seine-Maritime,28,Normandie
76755,YPREVILLE BIVILLE,76540,YPREVILLE BIVILLE,YPREVILLE,49.6944640581,0.530994381028,755,,Ypreville-Biville,Ypreville-Biville,76,Seine-Maritime,28,Normandie
76756,YQUEBEUF,76690,YQUEBEUF,,49.5932680679,1.2590988695,756,,Yquebeuf,Yquebeuf,76,Seine-Maritime,28,Normandie
76757,YVECRIQUE,76560,YVECRIQUE,,49.6892609326,0.807122350958,757,,Yvecrique,Yvecrique,76,Seine-Maritime,28,Normandie
76758,YVETOT,76190,YVETOT,,49.6175135557,0.761699633531,758,,Yvetot,Yvetot,76,Seine-Maritime,28,Normandie
76759,YVILLE SUR SEINE,76530,YVILLE SUR SEINE,,49.4126349869,0.882826992973,759,,Yville-sur-Seine,Yville-sur-Seine,76,Seine-Maritime,28,Normandie
77001,ACHERES LA FORET,77760,ACHERES LA FORET,,48.348528561,2.56456686676,1,,Achères-la-Forêt,Achères-la-Forêt,77,Seine-et-Marne,11,Île-de-France
77002,AMILLIS,77120,AMILLIS,,48.7355504117,3.13534283623,2,,Amillis,Amillis,77,Seine-et-Marne,11,Île-de-France
77003,AMPONVILLE,77760,AMPONVILLE,,48.2874371683,2.53069073747,3,,Amponville,Amponville,77,Seine-et-Marne,11,Île-de-France
77004,ANDREZEL,77390,ANDREZEL,,48.6145967279,2.8066323497,4,,Andrezel,Andrezel,77,Seine-et-Marne,11,Île-de-France
77005,ANNET SUR MARNE,77410,ANNET SUR MARNE,,48.9223335323,2.70854622393,5,,Annet-sur-Marne,Annet-sur-Marne,77,Seine-et-Marne,11,Île-de-France
77006,ARBONNE LA FORET,77630,ARBONNE LA FORET,,48.4103383904,2.5610975271,6,,Arbonne-la-Forêt,Arbonne-la-Forêt,77,Seine-et-Marne,11,Île-de-France
77007,ARGENTIERES,77390,ARGENTIERES,,48.6520584665,2.87089488924,7,,Argentières,Argentières,77,Seine-et-Marne,11,Île-de-France
77008,ARMENTIERES EN BRIE,77440,ARMENTIERES EN BRIE,,48.9805568845,3.01556654649,8,,Armentières-en-Brie,Armentières-en-Brie,77,Seine-et-Marne,11,Île-de-France
77009,ARVILLE,77890,ARVILLE,,48.1812755865,2.55657978,9,,Arville,Arville,77,Seine-et-Marne,11,Île-de-France
77010,AUBEPIERRE OZOUER LE REPOS,77720,AUBEPIERRE OZOUER LE REPOS,,48.620275445,2.90287523402,10,,Aubepierre-Ozouer-le-Repos,Aubepierre-Ozouer-le-Repos,77,Seine-et-Marne,11,Île-de-France
77010,AUBEPIERRE OZOUER LE REPOS,77720,AUBEPIERRE OZOUER LE REPOS,OZOUER LE REPOS,48.620275445,2.90287523402,10,,Aubepierre-Ozouer-le-Repos,Aubepierre-Ozouer-le-Repos,77,Seine-et-Marne,11,Île-de-France
77011,AUFFERVILLE,77570,AUFFERVILLE,,48.2049342797,2.60096783271,11,,Aufferville,Aufferville,77,Seine-et-Marne,11,Île-de-France
77012,AUGERS EN BRIE,77560,AUGERS EN BRIE,,48.6668346631,3.35240178386,12,,Augers-en-Brie,Augers-en-Brie,77,Seine-et-Marne,11,Île-de-France
77013,AULNOY,77120,AULNOY,,48.8488670481,3.08940930217,13,,Aulnoy,Aulnoy,77,Seine-et-Marne,11,Île-de-France
77014,AVON,77210,AVON,,48.4129699101,2.73351873997,14,,Avon,Avon,77,Seine-et-Marne,11,Île-de-France
77015,BABY,77480,BABY,,48.3894152157,3.3448043159,15,,Baby,Baby,77,Seine-et-Marne,11,Île-de-France
77016,BAGNEAUX SUR LOING,77167,BAGNEAUX SUR LOING,,48.2233131664,2.70750970158,16,,Bagneaux-sur-Loing,Bagneaux-sur-Loing,77,Seine-et-Marne,11,Île-de-France
77018,BAILLY ROMAINVILLIERS,77700,BAILLY ROMAINVILLIERS,,48.8434284926,2.81665828126,18,,Bailly-Romainvilliers,Bailly-Romainvilliers,77,Seine-et-Marne,11,Île-de-France
77019,BALLOY,77118,BALLOY,,48.3968757624,3.15136324376,19,,Balloy,Balloy,77,Seine-et-Marne,11,Île-de-France
77020,BANNOST VILLEGAGNON,77970,BANNOST VILLEGAGNON,,48.6753809899,3.19190655288,20,,Bannost-Villegagnon,Bannost-Villegagnon,77,Seine-et-Marne,11,Île-de-France
77020,BANNOST VILLEGAGNON,77970,BANNOST VILLEGAGNON,VILLEGAGNON,48.6753809899,3.19190655288,20,,Bannost-Villegagnon,Bannost-Villegagnon,77,Seine-et-Marne,11,Île-de-France
77021,BARBEY,77130,BARBEY,,48.3660335449,3.05001183138,21,,Barbey,Barbey,77,Seine-et-Marne,11,Île-de-France
77022,BARBIZON,77630,BARBIZON,,48.4485166396,2.60051335634,22,,Barbizon,Barbizon,77,Seine-et-Marne,11,Île-de-France
77023,BARCY,77910,BARCY,,49.0168774828,2.87655979952,23,,Barcy,Barcy,77,Seine-et-Marne,11,Île-de-France
77024,BASSEVELLE,77750,BASSEVELLE,,48.9228496694,3.28206618815,24,,Bassevelle,Bassevelle,77,Seine-et-Marne,11,Île-de-France
77025,BAZOCHES LES BRAY,77118,BAZOCHES LES BRAY,,48.3983722929,3.19261811911,25,,Bazoches-lès-Bray,Bazoches-lès-Bray,77,Seine-et-Marne,11,Île-de-France
77026,BEAUCHERY ST MARTIN,77560,BEAUCHERY ST MARTIN,,48.6022245541,3.42081185296,26,,Beauchery-Saint-Martin,Beauchery-Saint-Martin,77,Seine-et-Marne,11,Île-de-France
77026,BEAUCHERY ST MARTIN,77560,BEAUCHERY ST MARTIN,ST MARTIN CHENNETRON,48.6022245541,3.42081185296,26,,Beauchery-Saint-Martin,Beauchery-Saint-Martin,77,Seine-et-Marne,11,Île-de-France
77027,BEAUMONT DU GATINAIS,77890,BEAUMONT DU GATINAIS,,48.1407704243,2.4920346913,27,,Beaumont-du-Gâtinais,Beaumont-du-Gâtinais,77,Seine-et-Marne,11,Île-de-France
77028,BEAUTHEIL,77120,BEAUTHEIL,,48.761172382,3.09611614733,28,,Beautheil,Beautheil,77,Seine-et-Marne,11,Île-de-France
77029,BEAUVOIR,77390,BEAUVOIR,,48.6420172822,2.86359124408,29,,Beauvoir,Beauvoir,77,Seine-et-Marne,11,Île-de-France
77030,BELLOT,77510,BELLOT,,48.8416858718,3.3240177722,30,,Bellot,Bellot,77,Seine-et-Marne,11,Île-de-France
77031,BERNAY VILBERT,77540,BERNAY VILBERT,,48.675289546,2.92952115165,31,,Bernay-Vilbert,Bernay-Vilbert,77,Seine-et-Marne,11,Île-de-France
77031,BERNAY VILBERT,77540,BERNAY VILBERT,VILBERT,48.675289546,2.92952115165,31,,Bernay-Vilbert,Bernay-Vilbert,77,Seine-et-Marne,11,Île-de-France
77032,BETON BAZOCHES,77320,BETON BAZOCHES,,48.7072104391,3.24596468954,32,,Beton-Bazoches,Beton-Bazoches,77,Seine-et-Marne,11,Île-de-France
77033,BEZALLES,77970,BEZALLES,,48.6668121483,3.24024781081,33,,Bezalles,Bezalles,77,Seine-et-Marne,11,Île-de-France
77034,BLANDY,77115,BLANDY,,48.5601991238,2.79501080828,34,,Blandy,Blandy,77,Seine-et-Marne,11,Île-de-France
77035,BLENNES,77940,BLENNES,,48.2449362017,3.01197543789,35,,Blennes,Blennes,77,Seine-et-Marne,11,Île-de-France
77036,BOISDON,77970,BOISDON,,48.6755736092,3.2262301193,36,,Boisdon,Boisdon,77,Seine-et-Marne,11,Île-de-France
77037,BOIS LE ROI,77590,BOIS LE ROI,,48.476036197,2.70159529081,37,,Bois-le-Roi,Bois-le-Roi,77,Seine-et-Marne,11,Île-de-France
77038,BOISSETTES,77350,BOISSETTES,,48.523493031,2.6146358615,38,,Boissettes,Boissettes,77,Seine-et-Marne,11,Île-de-France
77039,BOISSISE LA BERTRAND,77350,BOISSISE LA BERTRAND,,48.5339084489,2.58950755763,39,,Boissise-la-Bertrand,Boissise-la-Bertrand,77,Seine-et-Marne,11,Île-de-France
77040,BOISSISE LE ROI,77310,BOISSISE LE ROI,,48.5119779126,2.56991909533,40,,Boissise-le-Roi,Boissise-le-Roi,77,Seine-et-Marne,11,Île-de-France
77041,BOISSY AUX CAILLES,77760,BOISSY AUX CAILLES,,48.3118839635,2.49277036234,41,,Boissy-aux-Cailles,Boissy-aux-Cailles,77,Seine-et-Marne,11,Île-de-France
77042,BOISSY LE CHATEL,77169,BOISSY LE CHATEL,,48.8253832401,3.13705190167,42,,Boissy-le-Châtel,Boissy-le-Châtel,77,Seine-et-Marne,11,Île-de-France
77043,BOITRON,77750,BOITRON,,48.8965868598,3.26188355205,43,,Boitron,Boitron,77,Seine-et-Marne,11,Île-de-France
77044,BOMBON,77720,BOMBON,,48.5678399041,2.86764154257,44,,Bombon,Bombon,77,Seine-et-Marne,11,Île-de-France
77045,BOUGLIGNY,77570,BOUGLIGNY,,48.1990049427,2.66712074887,45,,Bougligny,Bougligny,77,Seine-et-Marne,11,Île-de-France
77046,BOULANCOURT,77760,BOULANCOURT,,48.2614508893,2.45112404829,46,,Boulancourt,Boulancourt,77,Seine-et-Marne,11,Île-de-France
77047,BOULEURS,77580,BOULEURS,,48.879071424,2.90787920973,47,,Bouleurs,Bouleurs,77,Seine-et-Marne,11,Île-de-France
77048,BOURRON MARLOTTE,77780,BOURRON MARLOTTE,,48.3352610776,2.70169993506,48,,Bourron-Marlotte,Bourron-Marlotte,77,Seine-et-Marne,11,Île-de-France
77049,BOUTIGNY,77470,BOUTIGNY,,48.9127981727,2.9292515848,49,,Boutigny,Boutigny,77,Seine-et-Marne,11,Île-de-France
77050,BRANSLES,77620,BRANSLES,,48.1490796752,2.82814445347,50,,Bransles,Bransles,77,Seine-et-Marne,11,Île-de-France
77051,BRAY SUR SEINE,77480,BRAY SUR SEINE,,48.4145972795,3.24389965363,51,,Bray-sur-Seine,Bray-sur-Seine,77,Seine-et-Marne,11,Île-de-France
77052,BREAU,77720,BREAU,,48.5575023659,2.87972860182,52,,Bréau,Bréau,77,Seine-et-Marne,11,Île-de-France
77053,BRIE COMTE ROBERT,77170,BRIE COMTE ROBERT,,48.6920490316,2.6140606052,53,,Brie-Comte-Robert,Brie-Comte-Robert,77,Seine-et-Marne,11,Île-de-France
77054,LA BROSSE MONTCEAUX,77940,LA BROSSE MONTCEAUX,,48.3389431289,3.02362728915,54,La,Brosse-Montceaux,La Brosse-Montceaux,77,Seine-et-Marne,11,Île-de-France
77055,BROU SUR CHANTEREINE,77177,BROU SUR CHANTEREINE,,48.8901616457,2.63989859744,55,,Brou-sur-Chantereine,Brou-sur-Chantereine,77,Seine-et-Marne,11,Île-de-France
77056,BURCY,77760,BURCY,,48.2330689473,2.52555422379,56,,Burcy,Burcy,77,Seine-et-Marne,11,Île-de-France
77057,BUSSIERES,77750,BUSSIERES,,48.9266351593,3.23747220268,57,,Bussières,Bussières,77,Seine-et-Marne,11,Île-de-France
77058,BUSSY ST GEORGES,77600,BUSSY ST GEORGES,,48.8308269318,2.7225014237,58,,Bussy-Saint-Georges,Bussy-Saint-Georges,77,Seine-et-Marne,11,Île-de-France
77059,BUSSY ST MARTIN,77600,BUSSY ST MARTIN,,48.8462454012,2.67900406868,59,,Bussy-Saint-Martin,Bussy-Saint-Martin,77,Seine-et-Marne,11,Île-de-France
77059,BUSSY ST MARTIN,77600,BUSSY ST MARTIN,RENTILLY,48.8462454012,2.67900406868,59,,Bussy-Saint-Martin,Bussy-Saint-Martin,77,Seine-et-Marne,11,Île-de-France
77060,BUTHIERS,77760,BUTHIERS,,48.2869147685,2.45366687237,60,,Buthiers,Buthiers,77,Seine-et-Marne,11,Île-de-France
77061,CANNES ECLUSE,77130,CANNES ECLUSE,,48.3640376731,2.99078667983,61,,Cannes-Écluse,Cannes-Écluse,77,Seine-et-Marne,11,Île-de-France
77062,CARNETIN,77400,CARNETIN,,48.9042186439,2.69939855845,62,,Carnetin,Carnetin,77,Seine-et-Marne,11,Île-de-France
77063,LA CELLE SUR MORIN,77515,LA CELLE SUR MORIN,,48.7964965898,2.97175354301,63,La,Celle-sur-Morin,La Celle-sur-Morin,77,Seine-et-Marne,11,Île-de-France
77065,CELY,77930,CELY EN BIERE,,48.4599875595,2.52531326233,65,,Cély,Cély,77,Seine-et-Marne,11,Île-de-France
77066,CERNEUX,77320,CERNEUX,,48.7047767033,3.34596512996,66,,Cerneux,Cerneux,77,Seine-et-Marne,11,Île-de-France
77067,CESSON,77240,CESSON,,48.5647346588,2.59524476663,67,,Cesson,Cesson,77,Seine-et-Marne,11,Île-de-France
77068,CESSOY EN MONTOIS,77520,CESSOY EN MONTOIS,,48.5073073046,3.13884419418,68,,Cessoy-en-Montois,Cessoy-en-Montois,77,Seine-et-Marne,11,Île-de-France
77069,CHAILLY EN BIERE,77930,CHAILLY EN BIERE,,48.4697661737,2.61164875379,69,,Chailly-en-Bière,Chailly-en-Bière,77,Seine-et-Marne,11,Île-de-France
77070,CHAILLY EN BRIE,77120,CHAILLY EN BRIE,,48.7924119914,3.13372765274,70,,Chailly-en-Brie,Chailly-en-Brie,77,Seine-et-Marne,11,Île-de-France
77071,CHAINTREAUX,77460,CHAINTREAUX,,48.1911011555,2.81419944811,71,,Chaintreaux,Chaintreaux,77,Seine-et-Marne,11,Île-de-France
77072,CHALAUTRE LA GRANDE,77171,CHALAUTRE LA GRANDE,,48.5497596111,3.44637852602,72,,Chalautre-la-Grande,Chalautre-la-Grande,77,Seine-et-Marne,11,Île-de-France
77073,CHALAUTRE LA PETITE,77160,CHALAUTRE LA PETITE,,48.5273173873,3.31527496472,73,,Chalautre-la-Petite,Chalautre-la-Petite,77,Seine-et-Marne,11,Île-de-France
77075,CHALIFERT,77144,CHALIFERT,,48.8952645645,2.77324024955,75,,Chalifert,Chalifert,77,Seine-et-Marne,11,Île-de-France
77076,CHALMAISON,77650,CHALMAISON,,48.4901641224,3.25643675614,76,,Chalmaison,Chalmaison,77,Seine-et-Marne,11,Île-de-France
77077,CHAMBRY,77910,CHAMBRY,,48.9985355569,2.89422161914,77,,Chambry,Chambry,77,Seine-et-Marne,11,Île-de-France
77078,CHAMIGNY,77260,CHAMIGNY,,48.9851781012,3.14218296932,78,,Chamigny,Chamigny,77,Seine-et-Marne,11,Île-de-France
77079,CHAMPAGNE SUR SEINE,77430,CHAMPAGNE SUR SEINE,,48.4093568314,2.8079339272,79,,Champagne-sur-Seine,Champagne-sur-Seine,77,Seine-et-Marne,11,Île-de-France
77080,CHAMPCENEST,77560,CHAMPCENEST,,48.6709207707,3.27677023622,80,,Champcenest,Champcenest,77,Seine-et-Marne,11,Île-de-France
77081,CHAMPDEUIL,77390,CHAMPDEUIL,,48.6222116922,2.73123167134,81,,Champdeuil,Champdeuil,77,Seine-et-Marne,11,Île-de-France
77082,CHAMPEAUX,77720,CHAMPEAUX,,48.5919791102,2.81118299659,82,,Champeaux,Champeaux,77,Seine-et-Marne,11,Île-de-France
77083,CHAMPS SUR MARNE,77420,CHAMPS SUR MARNE,,48.8483311726,2.59610325483,83,,Champs-sur-Marne,Champs-sur-Marne,77,Seine-et-Marne,11,Île-de-France
77084,CHANGIS SUR MARNE,77660,CHANGIS SUR MARNE,,48.9663532581,3.02513385587,84,,Changis-sur-Marne,Changis-sur-Marne,77,Seine-et-Marne,11,Île-de-France
77085,CHANTELOUP EN BRIE,77600,CHANTELOUP EN BRIE,,48.8577766033,2.74039391563,85,,Chanteloup-en-Brie,Chanteloup-en-Brie,77,Seine-et-Marne,11,Île-de-France
77086,LA CHAPELLE GAUTHIER,77720,LA CHAPELLE GAUTHIER,,48.5306744733,2.86286787769,86,La,Chapelle-Gauthier,La Chapelle-Gauthier,77,Seine-et-Marne,11,Île-de-France
77087,LA CHAPELLE IGER,77540,LA CHAPELLE IGER,,48.6514529209,2.99242283241,87,La,Chapelle-Iger,La Chapelle-Iger,77,Seine-et-Marne,11,Île-de-France
77088,LA CHAPELLE LA REINE,77760,LA CHAPELLE LA REINE,,48.3159462288,2.56669707188,88,La,Chapelle-la-Reine,La Chapelle-la-Reine,77,Seine-et-Marne,11,Île-de-France
77089,LA CHAPELLE RABLAIS,77370,LA CHAPELLE RABLAIS,,48.5059176558,2.96075561449,89,La,Chapelle-Rablais,La Chapelle-Rablais,77,Seine-et-Marne,11,Île-de-France
77090,LA CHAPELLE ST SULPICE,77160,LA CHAPELLE ST SULPICE,,48.5577472984,3.17990030417,90,La,Chapelle-Saint-Sulpice,La Chapelle-Saint-Sulpice,77,Seine-et-Marne,11,Île-de-France
77091,LES CHAPELLES BOURBON,77610,LES CHAPELLES BOURBON,,48.7435938293,2.83654734794,91,Les,Chapelles-Bourbon,Les Chapelles-Bourbon,77,Seine-et-Marne,11,Île-de-France
77093,LA CHAPELLE MOUTILS,77320,LA CHAPELLE MOUTILS,,48.7774367045,3.38271618157,93,La,Chapelle-Moutils,La Chapelle-Moutils,77,Seine-et-Marne,11,Île-de-France
77093,LA CHAPELLE MOUTILS,77320,LA CHAPELLE MOUTILS,MOUTILS,48.7774367045,3.38271618157,93,La,Chapelle-Moutils,La Chapelle-Moutils,77,Seine-et-Marne,11,Île-de-France
77094,CHARMENTRAY,77410,CHARMENTRAY,,48.9509081872,2.77901265334,94,,Charmentray,Charmentray,77,Seine-et-Marne,11,Île-de-France
77095,CHARNY,77410,CHARNY,,48.9697818712,2.7560033506,95,,Charny,Charny,77,Seine-et-Marne,11,Île-de-France
77096,CHARTRETTES,77590,CHARTRETTES,,48.4942666127,2.71362181584,96,,Chartrettes,Chartrettes,77,Seine-et-Marne,11,Île-de-France
77097,CHARTRONGES,77320,CHARTRONGES,,48.7513681905,3.26256489697,97,,Chartronges,Chartronges,77,Seine-et-Marne,11,Île-de-France
77098,CHATEAUBLEAU,77370,CHATEAUBLEAU,,48.5830851034,3.10857327047,98,,Châteaubleau,Châteaubleau,77,Seine-et-Marne,11,Île-de-France
77099,CHATEAU LANDON,77570,CHATEAU LANDON,,48.1483667558,2.69970869665,99,,Château-Landon,Château-Landon,77,Seine-et-Marne,11,Île-de-France
77100,LE CHATELET EN BRIE,77820,LE CHATELET EN BRIE,,48.5016068676,2.80339101971,100,Le,Châtelet-en-Brie,Le Châtelet-en-Brie,77,Seine-et-Marne,11,Île-de-France
77101,CHATENAY SUR SEINE,77126,CHATENAY SUR SEINE,,48.4199405158,3.09453028466,101,,Châtenay-sur-Seine,Châtenay-sur-Seine,77,Seine-et-Marne,11,Île-de-France
77102,CHATENOY,77167,CHATENOY,,48.2308781488,2.62712492954,102,,Châtenoy,Châtenoy,77,Seine-et-Marne,11,Île-de-France
77103,CHATILLON LA BORDE,77820,CHATILLON LA BORDE,,48.5377423569,2.82685181318,103,,Châtillon-la-Borde,Châtillon-la-Borde,77,Seine-et-Marne,11,Île-de-France
77104,CHATRES,77610,CHATRES,,48.7127586287,2.8158417118,104,,Châtres,Châtres,77,Seine-et-Marne,11,Île-de-France
77106,CHAUFFRY,77169,CHAUFFRY,,48.8218818253,3.18099765766,106,,Chauffry,Chauffry,77,Seine-et-Marne,11,Île-de-France
77107,CHAUMES EN BRIE,77390,CHAUMES EN BRIE,,48.6671544898,2.84491273965,107,,Chaumes-en-Brie,Chaumes-en-Brie,77,Seine-et-Marne,11,Île-de-France
77108,CHELLES,77500,CHELLES,,48.883962967,2.59686448583,108,,Chelles,Chelles,77,Seine-et-Marne,11,Île-de-France
77108,CHELLES,77500,CHELLES,CHANTEREINE,48.883962967,2.59686448583,108,,Chelles,Chelles,77,Seine-et-Marne,11,Île-de-France
77109,CHENOISE,77160,CHENOISE,,48.6221074853,3.19218767904,109,,Chenoise,Chenoise,77,Seine-et-Marne,11,Île-de-France
77110,CHENOU,77570,CHENOU,,48.1650303616,2.65274445287,110,,Chenou,Chenou,77,Seine-et-Marne,11,Île-de-France
77111,CHESSY,77700,CHESSY,,48.8723310998,2.77039964715,111,,Chessy,Chessy,77,Seine-et-Marne,11,Île-de-France
77112,CHEVRAINVILLIERS,77760,CHEVRAINVILLIERS,,48.2459789311,2.60246070694,112,,Chevrainvilliers,Chevrainvilliers,77,Seine-et-Marne,11,Île-de-France
77113,CHEVRU,77320,CHEVRU,,48.7336804794,3.19577502632,113,,Chevru,Chevru,77,Seine-et-Marne,11,Île-de-France
77114,CHEVRY COSSIGNY,77173,CHEVRY COSSIGNY,,48.7228150234,2.67680477981,114,,Chevry-Cossigny,Chevry-Cossigny,77,Seine-et-Marne,11,Île-de-France
77114,CHEVRY COSSIGNY,77173,CHEVRY COSSIGNY,COSSIGNY,48.7228150234,2.67680477981,114,,Chevry-Cossigny,Chevry-Cossigny,77,Seine-et-Marne,11,Île-de-France
77115,CHEVRY EN SEREINE,77710,CHEVRY EN SEREINE,,48.2486077465,2.96038387712,115,,Chevry-en-Sereine,Chevry-en-Sereine,77,Seine-et-Marne,11,Île-de-France
77116,CHOISY EN BRIE,77320,CHOISY EN BRIE,,48.764988826,3.21324104844,116,,Choisy-en-Brie,Choisy-en-Brie,77,Seine-et-Marne,11,Île-de-France
77117,CITRY,77730,CITRY,,48.9608892793,3.24583955938,117,,Citry,Citry,77,Seine-et-Marne,11,Île-de-France
77118,CLAYE SOUILLY,77410,CLAYE SOUILLY,,48.9429697515,2.67523211808,118,,Claye-Souilly,Claye-Souilly,77,Seine-et-Marne,11,Île-de-France
77118,CLAYE SOUILLY,77410,CLAYE SOUILLY,SOUILLY,48.9429697515,2.67523211808,118,,Claye-Souilly,Claye-Souilly,77,Seine-et-Marne,11,Île-de-France
77119,CLOS FONTAINE,77370,CLOS FONTAINE,,48.6035279611,3.01423593373,119,,Clos-Fontaine,Clos-Fontaine,77,Seine-et-Marne,11,Île-de-France
77120,COCHEREL,77440,COCHEREL,,49.018408712,3.10665091541,120,,Cocherel,Cocherel,77,Seine-et-Marne,11,Île-de-France
77121,COLLEGIEN,77090,COLLEGIEN,,48.827339231,2.67744722497,121,,Collégien,Collégien,77,Seine-et-Marne,11,Île-de-France
77122,COMBS LA VILLE,77380,COMBS LA VILLE,,48.657908631,2.57516916269,122,,Combs-la-Ville,Combs-la-Ville,77,Seine-et-Marne,11,Île-de-France
77123,COMPANS,77290,COMPANS,,48.9892298685,2.6561103822,123,,Compans,Compans,77,Seine-et-Marne,11,Île-de-France
77124,CONCHES SUR GONDOIRE,77600,CONCHES SUR GONDOIRE,,48.8575681036,2.71923246791,124,,Conches-sur-Gondoire,Conches-sur-Gondoire,77,Seine-et-Marne,11,Île-de-France
77125,CONDE STE LIBIAIRE,77450,CONDE STE LIBIAIRE,,48.8985882759,2.83639912671,125,,Condé-Sainte-Libiaire,Condé-Sainte-Libiaire,77,Seine-et-Marne,11,Île-de-France
77126,CONGIS SUR THEROUANNE,77440,CONGIS SUR THEROUANNE,,49.0125341698,2.97847858444,126,,Congis-sur-Thérouanne,Congis-sur-Thérouanne,77,Seine-et-Marne,11,Île-de-France
77127,COUBERT,77170,COUBERT,,48.6782857887,2.70838590882,127,,Coubert,Coubert,77,Seine-et-Marne,11,Île-de-France
77128,COUILLY PONT AUX DAMES,77860,COUILLY PONT AUX DAMES,,48.8816698375,2.86534000286,128,,Couilly-Pont-aux-Dames,Couilly-Pont-aux-Dames,77,Seine-et-Marne,11,Île-de-France
77129,COULOMBS EN VALOIS,77840,COULOMBS EN VALOIS,,49.0777461945,3.12814777064,129,,Coulombs-en-Valois,Coulombs-en-Valois,77,Seine-et-Marne,11,Île-de-France
77130,COULOMMES,77580,COULOMMES,,48.8919104938,2.92942534432,130,,Coulommes,Coulommes,77,Seine-et-Marne,11,Île-de-France
77131,COULOMMIERS,77120,COULOMMIERS,,48.8122991656,3.09124381847,131,,Coulommiers,Coulommiers,77,Seine-et-Marne,11,Île-de-France
77132,COUPVRAY,77700,COUPVRAY,,48.8855498316,2.79349218664,132,,Coupvray,Coupvray,77,Seine-et-Marne,11,Île-de-France
77133,COURCELLES EN BASSEE,77126,COURCELLES EN BASSEE,,48.4125606521,3.05294050556,133,,Courcelles-en-Bassée,Courcelles-en-Bassée,77,Seine-et-Marne,11,Île-de-France
77134,COURCHAMP,77560,COURCHAMP,,48.6425226208,3.28982875028,134,,Courchamp,Courchamp,77,Seine-et-Marne,11,Île-de-France
77135,COURPALAY,77540,COURPALAY,,48.6431647905,2.95363659912,135,,Courpalay,Courpalay,77,Seine-et-Marne,11,Île-de-France
77136,COURQUETAINE,77390,COURQUETAINE,,48.6799468611,2.74754781347,136,,Courquetaine,Courquetaine,77,Seine-et-Marne,11,Île-de-France
77137,COURTACON,77560,COURTACON,,48.7030582245,3.29070185635,137,,Courtacon,Courtacon,77,Seine-et-Marne,11,Île-de-France
77138,COURTOMER,77390,COURTOMER,,48.6580781598,2.89624103762,138,,Courtomer,Courtomer,77,Seine-et-Marne,11,Île-de-France
77139,COURTRY,77181,COURTRY,,48.9167295268,2.60299399479,139,,Courtry,Courtry,77,Seine-et-Marne,11,Île-de-France
77140,COUTENCON,77154,COUTENCON,,48.4729414867,2.9972836252,140,,Coutençon,Coutençon,77,Seine-et-Marne,11,Île-de-France
77141,COUTEVROULT,77580,COUTEVROULT,,48.8533091691,2.8480997097,141,,Coutevroult,Coutevroult,77,Seine-et-Marne,11,Île-de-France
77142,CRECY LA CHAPELLE,77580,CRECY LA CHAPELLE,,48.8567446134,2.93331153745,142,,Crécy-la-Chapelle,Crécy-la-Chapelle,77,Seine-et-Marne,11,Île-de-France
77142,CRECY LA CHAPELLE,77580,CRECY LA CHAPELLE,LA CHAPELLE SUR CRECY,48.8567446134,2.93331153745,142,,Crécy-la-Chapelle,Crécy-la-Chapelle,77,Seine-et-Marne,11,Île-de-France
77143,CREGY LES MEAUX,77124,CREGY LES MEAUX,,48.9776563538,2.87369096046,143,,Crégy-lès-Meaux,Crégy-lès-Meaux,77,Seine-et-Marne,11,Île-de-France
77144,CREVECOEUR EN BRIE,77610,CREVECOEUR EN BRIE,,48.7560531013,2.90716174383,144,,Crèvecœur-en-Brie,Crèvecœur-en-Brie,77,Seine-et-Marne,11,Île-de-France
77145,CRISENOY,77390,CRISENOY,,48.6032562685,2.74469618837,145,,Crisenoy,Crisenoy,77,Seine-et-Marne,11,Île-de-France
77146,CROISSY BEAUBOURG,77183,CROISSY BEAUBOURG,,48.8167092612,2.65547869791,146,,Croissy-Beaubourg,Croissy-Beaubourg,77,Seine-et-Marne,11,Île-de-France
77146,CROISSY BEAUBOURG,77183,CROISSY BEAUBOURG,BEAUBOURG,48.8167092612,2.65547869791,146,,Croissy-Beaubourg,Croissy-Beaubourg,77,Seine-et-Marne,11,Île-de-France
77147,LA CROIX EN BRIE,77370,LA CROIX EN BRIE,,48.5944942615,3.07093990033,147,La,Croix-en-Brie,La Croix-en-Brie,77,Seine-et-Marne,11,Île-de-France
77148,CROUY SUR OURCQ,77840,CROUY SUR OURCQ,,49.0867480473,3.07964633492,148,,Crouy-sur-Ourcq,Crouy-sur-Ourcq,77,Seine-et-Marne,11,Île-de-France
77149,CUCHARMOY,77160,CUCHARMOY,,48.5893108567,3.17911860065,149,,Cucharmoy,Cucharmoy,77,Seine-et-Marne,11,Île-de-France
77150,CUISY,77165,CUISY,,49.0197878433,2.77789349097,150,,Cuisy,Cuisy,77,Seine-et-Marne,11,Île-de-France
77151,DAGNY,77320,DAGNY,,48.7137379393,3.16528278931,151,,Dagny,Dagny,77,Seine-et-Marne,11,Île-de-France
77152,DAMMARIE LES LYS,77190,DAMMARIE LES LYS,,48.5138338655,2.62854374259,152,,Dammarie-les-Lys,Dammarie-les-Lys,77,Seine-et-Marne,11,Île-de-France
77153,DAMMARTIN EN GOELE,77230,DAMMARTIN EN GOELE,,49.0541690491,2.67926104225,153,,Dammartin-en-Goële,Dammartin-en-Goële,77,Seine-et-Marne,11,Île-de-France
77154,DAMMARTIN SUR TIGEAUX,77163,DAMMARTIN SUR TIGEAUX,,48.8083782663,2.90339672322,154,,Dammartin-sur-Tigeaux,Dammartin-sur-Tigeaux,77,Seine-et-Marne,11,Île-de-France
77155,DAMPMART,77400,DAMPMART,,48.8943626322,2.74598280906,155,,Dampmart,Dampmart,77,Seine-et-Marne,11,Île-de-France
77156,DARVAULT,77140,DARVAULT,,48.277038883,2.72843016047,156,,Darvault,Darvault,77,Seine-et-Marne,11,Île-de-France
77157,DHUISY,77440,DHUISY,,49.0357759838,3.16433496105,157,,Dhuisy,Dhuisy,77,Seine-et-Marne,11,Île-de-France
77158,DIANT,77940,DIANT,,48.2914084366,3.00508294667,158,,Diant,Diant,77,Seine-et-Marne,11,Île-de-France
77159,DONNEMARIE DONTILLY,77520,DONNEMARIE DONTILLY,,48.4778429138,3.11825735705,159,,Donnemarie-Dontilly,Donnemarie-Dontilly,77,Seine-et-Marne,11,Île-de-France
77161,DORMELLES,77130,DORMELLES,,48.3134232598,2.88922235899,161,,Dormelles,Dormelles,77,Seine-et-Marne,11,Île-de-France
77162,DOUE,77510,DOUE,,48.8741885092,3.16866538341,162,,Doue,Doue,77,Seine-et-Marne,11,Île-de-France
77163,DOUY LA RAMEE,77139,DOUY LA RAMEE,,49.0618013972,2.88351463449,163,,Douy-la-Ramée,Douy-la-Ramée,77,Seine-et-Marne,11,Île-de-France
77164,ECHOUBOULAINS,77830,ECHOUBOULAINS,,48.4637199643,2.9295993076,164,,Échouboulains,Échouboulains,77,Seine-et-Marne,11,Île-de-France
77165,LES ECRENNES,77820,LES ECRENNES,,48.4956746939,2.8755286018,165,Les,Écrennes,Les Écrennes,77,Seine-et-Marne,11,Île-de-France
77167,EGLIGNY,77126,EGLIGNY,,48.4397062203,3.12418051446,167,,Égligny,Égligny,77,Seine-et-Marne,11,Île-de-France
77168,EGREVILLE,77620,EGREVILLE,,48.1821285238,2.88437118271,168,,Égreville,Égreville,77,Seine-et-Marne,11,Île-de-France
77169,EMERAINVILLE,77184,EMERAINVILLE,,48.8197762376,2.60897006725,169,,Émerainville,Émerainville,77,Seine-et-Marne,11,Île-de-France
77169,EMERAINVILLE,77184,EMERAINVILLE,MALNOUE,48.8197762376,2.60897006725,169,,Émerainville,Émerainville,77,Seine-et-Marne,11,Île-de-France
77171,ESBLY,77450,ESBLY,,48.9005636441,2.81238845063,171,,Esbly,Esbly,77,Seine-et-Marne,11,Île-de-France
77172,ESMANS,77940,ESMANS,,48.3429519807,2.96915653301,172,,Esmans,Esmans,77,Seine-et-Marne,11,Île-de-France
77173,ETREPILLY,77139,ETREPILLY,,49.0420868725,2.93051906568,173,,Étrépilly,Étrépilly,77,Seine-et-Marne,11,Île-de-France
77174,EVERLY,77157,EVERLY,,48.4602932041,3.2638422634,174,,Everly,Everly,77,Seine-et-Marne,11,Île-de-France
77175,EVRY GREGY SUR YERRE,77166,EVRY GREGY SUR YERRE,,48.6536042911,2.63969411828,175,,Évry-Grégy-sur-Yerre,Évry-Grégy-sur-Yerre,77,Seine-et-Marne,11,Île-de-France
77175,EVRY GREGY SUR YERRE,77166,EVRY GREGY SUR YERRE,GREGY SUR YERRE,48.6536042911,2.63969411828,175,,Évry-Grégy-sur-Yerre,Évry-Grégy-sur-Yerre,77,Seine-et-Marne,11,Île-de-France
77176,FAREMOUTIERS,77515,FAREMOUTIERS,,48.7783722685,3.00021735955,176,,Faremoutiers,Faremoutiers,77,Seine-et-Marne,11,Île-de-France
77177,FAVIERES,77220,FAVIERES,,48.7803198731,2.76799621912,177,,Favières,Favières,77,Seine-et-Marne,11,Île-de-France
77178,FAY LES NEMOURS,77167,FAY LES NEMOURS,,48.2276874711,2.66412171118,178,,Faÿ-lès-Nemours,Faÿ-lès-Nemours,77,Seine-et-Marne,11,Île-de-France
77179,FERICY,77133,FERICY,,48.4651858687,2.80348330714,179,,Féricy,Féricy,77,Seine-et-Marne,11,Île-de-France
77180,FEROLLES ATTILLY,77150,FEROLLES ATTILLY,,48.7335952478,2.63893813128,180,,Férolles-Attilly,Férolles-Attilly,77,Seine-et-Marne,11,Île-de-France
77181,FERRIERES EN BRIE,77164,FERRIERES EN BRIE,,48.8200397561,2.70761245584,181,,Ferrières-en-Brie,Ferrières-en-Brie,77,Seine-et-Marne,11,Île-de-France
77182,LA FERTE GAUCHER,77320,LA FERTE GAUCHER,,48.7881786696,3.30423031275,182,La,Ferté-Gaucher,La Ferté-Gaucher,77,Seine-et-Marne,11,Île-de-France
77183,LA FERTE SOUS JOUARRE,77260,LA FERTE SOUS JOUARRE,,48.9540164057,3.12762174384,183,La,Ferté-sous-Jouarre,La Ferté-sous-Jouarre,77,Seine-et-Marne,11,Île-de-France
77184,FLAGY,77940,FLAGY,,48.3095715742,2.91700896132,184,,Flagy,Flagy,77,Seine-et-Marne,11,Île-de-France
77185,FLEURY EN BIERE,77930,FLEURY EN BIERE,,48.4452091427,2.54053694793,185,,Fleury-en-Bière,Fleury-en-Bière,77,Seine-et-Marne,11,Île-de-France
77186,FONTAINEBLEAU,77300,FONTAINEBLEAU,,48.4066856508,2.68031396389,186,,Fontainebleau,Fontainebleau,77,Seine-et-Marne,11,Île-de-France
77187,FONTAINE FOURCHES,77480,FONTAINE FOURCHES,,48.4120304455,3.39431258442,187,,Fontaine-Fourches,Fontaine-Fourches,77,Seine-et-Marne,11,Île-de-France
77188,FONTAINE LE PORT,77590,FONTAINE LE PORT,,48.4842448988,2.76706354211,188,,Fontaine-le-Port,Fontaine-le-Port,77,Seine-et-Marne,11,Île-de-France
77190,FONTAINS,77370,FONTAINS,,48.521763357,3.00687060558,190,,Fontains,Fontains,77,Seine-et-Marne,11,Île-de-France
77191,FONTENAILLES,77370,FONTENAILLES,,48.5324483364,2.92730889492,191,,Fontenailles,Fontenailles,77,Seine-et-Marne,11,Île-de-France
77192,FONTENAY TRESIGNY,77610,FONTENAY TRESIGNY,,48.697973344,2.86231363712,192,,Fontenay-Trésigny,Fontenay-Trésigny,77,Seine-et-Marne,11,Île-de-France
77193,FORFRY,77165,FORFRY,,49.0535919932,2.84961058916,193,,Forfry,Forfry,77,Seine-et-Marne,11,Île-de-France
77194,FORGES,77130,FORGES,,48.4219059797,2.94877394582,194,,Forges,Forges,77,Seine-et-Marne,11,Île-de-France
77195,FOUJU,77390,FOUJU,,48.5874212672,2.76525600537,195,,Fouju,Fouju,77,Seine-et-Marne,11,Île-de-France
77196,FRESNES SUR MARNE,77410,FRESNES SUR MARNE,,48.9418117332,2.73978982468,196,,Fresnes-sur-Marne,Fresnes-sur-Marne,77,Seine-et-Marne,11,Île-de-France
77197,FRETOY,77320,FRETOY,,48.7063577233,3.20260750472,197,,Frétoy,Frétoy,77,Seine-et-Marne,11,Île-de-France
77198,FROMONT,77760,FROMONT,,48.2535112843,2.50835017332,198,,Fromont,Fromont,77,Seine-et-Marne,11,Île-de-France
77199,FUBLAINES,77470,FUBLAINES,,48.939144787,2.94066585893,199,,Fublaines,Fublaines,77,Seine-et-Marne,11,Île-de-France
77200,GARENTREVILLE,77890,GARENTREVILLE,,48.2339884396,2.55980121131,200,,Garentreville,Garentreville,77,Seine-et-Marne,11,Île-de-France
77201,GASTINS,77370,GASTINS,,48.6267877328,3.02021144057,201,,Gastins,Gastins,77,Seine-et-Marne,11,Île-de-France
77202,LA GENEVRAYE,77690,LA GENEVRAYE,,48.3150277838,2.75296542795,202,La,Genevraye,La Genevraye,77,Seine-et-Marne,11,Île-de-France
77203,GERMIGNY L EVEQUE,77910,GERMIGNY L EVEQUE,,48.9859507813,2.95833193991,203,,Germigny-l'Évêque,Germigny-l'Évêque,77,Seine-et-Marne,11,Île-de-France
77204,GERMIGNY SOUS COULOMBS,77840,GERMIGNY SOUS COULOMBS,,49.0643954929,3.16141463916,204,,Germigny-sous-Coulombs,Germigny-sous-Coulombs,77,Seine-et-Marne,11,Île-de-France
77205,GESVRES LE CHAPITRE,77165,GESVRES LE CHAPITRE,,49.0358954042,2.84316991013,205,,Gesvres-le-Chapitre,Gesvres-le-Chapitre,77,Seine-et-Marne,11,Île-de-France
77206,GIREMOUTIERS,77120,GIREMOUTIERS,,48.853040564,3.03326151777,206,,Giremoutiers,Giremoutiers,77,Seine-et-Marne,11,Île-de-France
77207,GIRONVILLE,77890,GIRONVILLE,,48.1626911918,2.53341548743,207,,Gironville,Gironville,77,Seine-et-Marne,11,Île-de-France
77208,GOUAIX,77114,GOUAIX,,48.4760442904,3.2968135463,208,,Gouaix,Gouaix,77,Seine-et-Marne,11,Île-de-France
77209,GOUVERNES,77400,GOUVERNES,,48.8584691866,2.69464360484,209,,Gouvernes,Gouvernes,77,Seine-et-Marne,11,Île-de-France
77210,LA GRANDE PAROISSE,77130,LA GRANDE PAROISSE,,48.3917544483,2.89831742058,210,La,Grande-Paroisse,La Grande-Paroisse,77,Seine-et-Marne,11,Île-de-France
77211,GRANDPUITS BAILLY CARROIS,77720,GRANDPUITS BAILLY CARROIS,,48.5823205697,2.97582475865,211,,Grandpuits-Bailly-Carrois,Grandpuits-Bailly-Carrois,77,Seine-et-Marne,11,Île-de-France
77211,GRANDPUITS BAILLY CARROIS,77720,GRANDPUITS BAILLY CARROIS,BAILLY CARROIS,48.5823205697,2.97582475865,211,,Grandpuits-Bailly-Carrois,Grandpuits-Bailly-Carrois,77,Seine-et-Marne,11,Île-de-France
77212,GRAVON,77118,GRAVON,,48.3882980468,3.12492603624,212,,Gravon,Gravon,77,Seine-et-Marne,11,Île-de-France
77214,GRESSY,77410,GRESSY,,48.9650099729,2.66440376724,214,,Gressy,Gressy,77,Seine-et-Marne,11,Île-de-France
77215,GRETZ ARMAINVILLIERS,77220,GRETZ ARMAINVILLIERS,,48.7495328893,2.72623578521,215,,Gretz-Armainvilliers,Gretz-Armainvilliers,77,Seine-et-Marne,11,Île-de-France
77216,GREZ SUR LOING,77880,GREZ SUR LOING,,48.3071221634,2.67742341915,216,,Grez-sur-Loing,Grez-sur-Loing,77,Seine-et-Marne,11,Île-de-France
77217,GRISY SUISNES,77166,GRISY SUISNES,,48.6844503585,2.67647246485,217,,Grisy-Suisnes,Grisy-Suisnes,77,Seine-et-Marne,11,Île-de-France
77217,GRISY SUISNES,77166,GRISY SUISNES,SUISNES,48.6844503585,2.67647246485,217,,Grisy-Suisnes,Grisy-Suisnes,77,Seine-et-Marne,11,Île-de-France
77218,GRISY SUR SEINE,77480,GRISY SUR SEINE,,48.4392463445,3.31754499195,218,,Grisy-sur-Seine,Grisy-sur-Seine,77,Seine-et-Marne,11,Île-de-France
77219,GUERARD,77580,GUERARD,,48.8187519127,2.95160900564,219,,Guérard,Guérard,77,Seine-et-Marne,11,Île-de-France
77220,GUERCHEVILLE,77760,GUERCHEVILLE,,48.2597174707,2.55771818167,220,,Guercheville,Guercheville,77,Seine-et-Marne,11,Île-de-France
77221,GUERMANTES,77600,GUERMANTES,,48.8514207654,2.71246565476,221,,Guermantes,Guermantes,77,Seine-et-Marne,11,Île-de-France
77222,GUIGNES,77390,GUIGNES,,48.6358224167,2.80042109731,222,,Guignes,Guignes,77,Seine-et-Marne,11,Île-de-France
77223,GURCY LE CHATEL,77520,GURCY LE CHATEL,,48.4844716006,3.0809062943,223,,Gurcy-le-Châtel,Gurcy-le-Châtel,77,Seine-et-Marne,11,Île-de-France
77223,GURCY LE CHATEL,77520,GURCY LE CHATEL,CHALAUTRE LA REPOSTE,48.4844716006,3.0809062943,223,,Gurcy-le-Châtel,Gurcy-le-Châtel,77,Seine-et-Marne,11,Île-de-France
77224,HAUTEFEUILLE,77515,HAUTEFEUILLE,,48.7665435966,2.96358737497,224,,Hautefeuille,Hautefeuille,77,Seine-et-Marne,11,Île-de-France
77225,LA HAUTE MAISON,77580,LA HAUTE MAISON,,48.8841264989,3.00569184171,225,La,Haute-Maison,La Haute-Maison,77,Seine-et-Marne,11,Île-de-France
77226,HERICY,77850,HERICY,,48.4418336444,2.7894248816,226,,Héricy,Héricy,77,Seine-et-Marne,11,Île-de-France
77227,HERME,77114,HERME,,48.4860963164,3.34599463077,227,,Hermé,Hermé,77,Seine-et-Marne,11,Île-de-France
77228,HONDEVILLIERS,77510,HONDEVILLIERS,,48.9036037972,3.31190232857,228,,Hondevilliers,Hondevilliers,77,Seine-et-Marne,11,Île-de-France
77229,LA HOUSSAYE EN BRIE,77610,LA HOUSSAYE EN BRIE,,48.756188625,2.86795216078,229,La,Houssaye-en-Brie,La Houssaye-en-Brie,77,Seine-et-Marne,11,Île-de-France
77230,ICHY,77890,ICHY,,48.2062867777,2.54177134265,230,,Ichy,Ichy,77,Seine-et-Marne,11,Île-de-France
77231,ISLES LES MELDEUSES,77440,ISLES LES MELDEUSES,,48.9977413131,3.01254542771,231,,Isles-les-Meldeuses,Isles-les-Meldeuses,77,Seine-et-Marne,11,Île-de-France
77232,ISLES LES VILLENOY,77450,ISLES LES VILLENOY,,48.9170644276,2.83057358355,232,,Isles-lès-Villenoy,Isles-lès-Villenoy,77,Seine-et-Marne,11,Île-de-France
77233,IVERNY,77165,IVERNY,,48.9962439672,2.79088918596,233,,Iverny,Iverny,77,Seine-et-Marne,11,Île-de-France
77234,JABLINES,77450,JABLINES,,48.9144697442,2.7566018167,234,,Jablines,Jablines,77,Seine-et-Marne,11,Île-de-France
77235,JAIGNES,77440,JAIGNES,,48.9870552422,3.07455824871,235,,Jaignes,Jaignes,77,Seine-et-Marne,11,Île-de-France
77236,JAULNES,77480,JAULNES,,48.4144765757,3.27634735642,236,,Jaulnes,Jaulnes,77,Seine-et-Marne,11,Île-de-France
77237,JOSSIGNY,77600,JOSSIGNY,,48.8311007596,2.76323402723,237,,Jossigny,Jossigny,77,Seine-et-Marne,11,Île-de-France
77238,JOUARRE,77640,JOUARRE,,48.9025410128,3.11280532504,238,,Jouarre,Jouarre,77,Seine-et-Marne,11,Île-de-France
77239,JOUY LE CHATEL,77970,JOUY LE CHATEL,,48.6642848062,3.13507230422,239,,Jouy-le-Châtel,Jouy-le-Châtel,77,Seine-et-Marne,11,Île-de-France
77240,JOUY SUR MORIN,77320,JOUY SUR MORIN,,48.7955034518,3.27252320756,240,,Jouy-sur-Morin,Jouy-sur-Morin,77,Seine-et-Marne,11,Île-de-France
77241,JUILLY,77230,JUILLY,,49.0216143315,2.70583283653,241,,Juilly,Juilly,77,Seine-et-Marne,11,Île-de-France
77242,JUTIGNY,77650,JUTIGNY,,48.4940082553,3.22666999554,242,,Jutigny,Jutigny,77,Seine-et-Marne,11,Île-de-France
77243,LAGNY SUR MARNE,77400,LAGNY SUR MARNE,,48.8730701858,2.70978081313,243,,Lagny-sur-Marne,Lagny-sur-Marne,77,Seine-et-Marne,11,Île-de-France
77244,LARCHANT,77760,LARCHANT,,48.2829275322,2.59886669587,244,,Larchant,Larchant,77,Seine-et-Marne,11,Île-de-France
77245,LAVAL EN BRIE,77148,LAVAL EN BRIE,,48.4552750724,2.97557988604,245,,Laval-en-Brie,Laval-en-Brie,77,Seine-et-Marne,11,Île-de-France
77246,LECHELLE,77171,LECHELLE,,48.5720242437,3.38931768623,246,,Léchelle,Léchelle,77,Seine-et-Marne,11,Île-de-France
77247,LESCHEROLLES,77320,LESCHEROLLES,,48.7608681529,3.35044757724,247,,Lescherolles,Lescherolles,77,Seine-et-Marne,11,Île-de-France
77248,LESCHES,77450,LESCHES,,48.9152646344,2.79280740269,248,,Lesches,Lesches,77,Seine-et-Marne,11,Île-de-France
77249,LESIGNY,77150,LESIGNY,,48.7497182303,2.61583775274,249,,Lésigny,Lésigny,77,Seine-et-Marne,11,Île-de-France
77250,LEUDON EN BRIE,77320,LEUDON EN BRIE,,48.7304236391,3.2680908114,250,,Leudon-en-Brie,Leudon-en-Brie,77,Seine-et-Marne,11,Île-de-France
77251,LIEUSAINT,77127,LIEUSAINT,,48.6259910244,2.54994732668,251,,Lieusaint,Lieusaint,77,Seine-et-Marne,11,Île-de-France
77252,LIMOGES FOURCHES,77550,LIMOGES FOURCHES,,48.6265916189,2.66952295413,252,,Limoges-Fourches,Limoges-Fourches,77,Seine-et-Marne,11,Île-de-France
77253,LISSY,77550,LISSY,,48.6181092789,2.70150853218,253,,Lissy,Lissy,77,Seine-et-Marne,11,Île-de-France
77254,LIVERDY EN BRIE,77220,LIVERDY EN BRIE,,48.6956850464,2.78032213187,254,,Liverdy-en-Brie,Liverdy-en-Brie,77,Seine-et-Marne,11,Île-de-France
77255,LIVRY SUR SEINE,77000,LIVRY SUR SEINE,,48.5051016281,2.68647821336,255,,Livry-sur-Seine,Livry-sur-Seine,77,Seine-et-Marne,11,Île-de-France
77256,LIZINES,77650,LIZINES,,48.5280718067,3.16532874131,256,,Lizines,Lizines,77,Seine-et-Marne,11,Île-de-France
77257,LIZY SUR OURCQ,77440,LIZY SUR OURCQ,,49.0352062852,3.01893213028,257,,Lizy-sur-Ourcq,Lizy-sur-Ourcq,77,Seine-et-Marne,11,Île-de-France
77258,LOGNES,77185,LOGNES,,48.8332806021,2.63346363172,258,,Lognes,Lognes,77,Seine-et-Marne,11,Île-de-France
77259,LONGPERRIER,77230,LONGPERRIER,,49.0509695359,2.65542471026,259,,Longperrier,Longperrier,77,Seine-et-Marne,11,Île-de-France
77260,LONGUEVILLE,77650,LONGUEVILLE,,48.5144915649,3.23853264046,260,,Longueville,Longueville,77,Seine-et-Marne,11,Île-de-France
77261,LORREZ LE BOCAGE PREAUX,77710,LORREZ LE BOCAGE PREAUX,,48.2397310097,2.89648812918,261,,Lorrez-le-Bocage-Préaux,Lorrez-le-Bocage-Préaux,77,Seine-et-Marne,11,Île-de-France
77261,LORREZ LE BOCAGE PREAUX,77710,LORREZ LE BOCAGE PREAUX,PREAUX,48.2397310097,2.89648812918,261,,Lorrez-le-Bocage-Préaux,Lorrez-le-Bocage-Préaux,77,Seine-et-Marne,11,Île-de-France
77262,LOUAN VILLEGRUIS FONTAINE,77560,LOUAN VILLEGRUIS FONTAINE,,48.6145636708,3.48915109409,262,,Louan-Villegruis-Fontaine,Louan-Villegruis-Fontaine,77,Seine-et-Marne,11,Île-de-France
77262,LOUAN VILLEGRUIS FONTAINE,77560,LOUAN VILLEGRUIS FONTAINE,FONTAINE SOUS MONTAIGUILLON,48.6145636708,3.48915109409,262,,Louan-Villegruis-Fontaine,Louan-Villegruis-Fontaine,77,Seine-et-Marne,11,Île-de-France
77262,LOUAN VILLEGRUIS FONTAINE,77560,LOUAN VILLEGRUIS FONTAINE,VILLEGRUIS,48.6145636708,3.48915109409,262,,Louan-Villegruis-Fontaine,Louan-Villegruis-Fontaine,77,Seine-et-Marne,11,Île-de-France
77263,LUISETAINES,77520,LUISETAINES,,48.4610681499,3.18563257802,263,,Luisetaines,Luisetaines,77,Seine-et-Marne,11,Île-de-France
77264,LUMIGNY NESLES ORMEAUX,77540,LUMIGNY NESLES ORMEAUX,,48.719582466,2.95371291045,264,,Lumigny-Nesles-Ormeaux,Lumigny-Nesles-Ormeaux,77,Seine-et-Marne,11,Île-de-France
77264,LUMIGNY NESLES ORMEAUX,77540,LUMIGNY NESLES ORMEAUX,NESLES LA GILBERDE,48.719582466,2.95371291045,264,,Lumigny-Nesles-Ormeaux,Lumigny-Nesles-Ormeaux,77,Seine-et-Marne,11,Île-de-France
77264,LUMIGNY NESLES ORMEAUX,77540,LUMIGNY NESLES ORMEAUX,ORMEAUX,48.719582466,2.95371291045,264,,Lumigny-Nesles-Ormeaux,Lumigny-Nesles-Ormeaux,77,Seine-et-Marne,11,Île-de-France
77265,LUZANCY,77138,LUZANCY,,48.9776259019,3.18079774595,265,,Luzancy,Luzancy,77,Seine-et-Marne,11,Île-de-France
77266,MACHAULT,77133,MACHAULT,,48.445996968,2.84112295871,266,,Machault,Machault,77,Seine-et-Marne,11,Île-de-France
77267,LA MADELEINE SUR LOING,77570,LA MADELEINE SUR LOING,,48.2051069149,2.70184813969,267,La,Madeleine-sur-Loing,La Madeleine-sur-Loing,77,Seine-et-Marne,11,Île-de-France
77268,MAGNY LE HONGRE,77700,MAGNY LE HONGRE,,48.866827281,2.81442274783,268,,Magny-le-Hongre,Magny-le-Hongre,77,Seine-et-Marne,11,Île-de-France
77269,MAINCY,77950,MAINCY,,48.554015687,2.70614781441,269,,Maincy,Maincy,77,Seine-et-Marne,11,Île-de-France
77270,MAISONCELLES EN BRIE,77580,MAISONCELLES EN BRIE,,48.858937632,2.99380875199,270,,Maisoncelles-en-Brie,Maisoncelles-en-Brie,77,Seine-et-Marne,11,Île-de-France
77271,MAISONCELLES EN GATINAIS,77570,MAISONCELLES EN GATINAIS,,48.1827929678,2.6212297089,271,,Maisoncelles-en-Gâtinais,Maisoncelles-en-Gâtinais,77,Seine-et-Marne,11,Île-de-France
77272,MAISON ROUGE,77370,MAISON ROUGE,,48.5549428355,3.15134604553,272,,Maison-Rouge,Maison-Rouge,77,Seine-et-Marne,11,Île-de-France
77273,MARCHEMORET,77230,MARCHEMORET,,49.0528552524,2.75169281381,273,,Marchémoret,Marchémoret,77,Seine-et-Marne,11,Île-de-France
77274,MARCILLY,77139,MARCILLY,,49.0370437307,2.88048254724,274,,Marcilly,Marcilly,77,Seine-et-Marne,11,Île-de-France
77275,LES MARETS,77560,LES MARETS,,48.6725110307,3.31659971579,275,Les,Marêts,Les Marêts,77,Seine-et-Marne,11,Île-de-France
77276,MAREUIL LES MEAUX,77100,MAREUIL LES MEAUX,,48.9236226268,2.86975697306,276,,Mareuil-lès-Meaux,Mareuil-lès-Meaux,77,Seine-et-Marne,11,Île-de-France
77277,MARLES EN BRIE,77610,MARLES EN BRIE,,48.7249445304,2.88847597866,277,,Marles-en-Brie,Marles-en-Brie,77,Seine-et-Marne,11,Île-de-France
77278,MAROLLES EN BRIE,77120,MAROLLES EN BRIE,,48.7741847386,3.16145494015,278,,Marolles-en-Brie,Marolles-en-Brie,77,Seine-et-Marne,11,Île-de-France
77279,MAROLLES SUR SEINE,77130,MAROLLES SUR SEINE,,48.3838863955,3.03202165862,279,,Marolles-sur-Seine,Marolles-sur-Seine,77,Seine-et-Marne,11,Île-de-France
77280,MARY SUR MARNE,77440,MARY SUR MARNE,,49.0154576269,3.0331378952,280,,Mary-sur-Marne,Mary-sur-Marne,77,Seine-et-Marne,11,Île-de-France
77281,MAUPERTHUIS,77120,MAUPERTHUIS,,48.7700152942,3.03854436652,281,,Mauperthuis,Mauperthuis,77,Seine-et-Marne,11,Île-de-France
77282,MAUREGARD,77990,MAUREGARD,,49.0288930356,2.57185471414,282,,Mauregard,Mauregard,77,Seine-et-Marne,11,Île-de-France
77283,MAY EN MULTIEN,77145,MAY EN MULTIEN,,49.0699868201,3.02518523427,283,,May-en-Multien,May-en-Multien,77,Seine-et-Marne,11,Île-de-France
77284,MEAUX,77100,MEAUX,,48.9570625667,2.90003097245,284,,Meaux,Meaux,77,Seine-et-Marne,11,Île-de-France
77285,LE MEE SUR SEINE,77350,LE MEE SUR SEINE,,48.5394588477,2.62902482677,285,Le,Mée-sur-Seine,Le Mée-sur-Seine,77,Seine-et-Marne,11,Île-de-France
77286,MEIGNEUX,77520,MEIGNEUX,,48.5137853479,3.09690502787,286,,Meigneux,Meigneux,77,Seine-et-Marne,11,Île-de-France
77287,MEILLERAY,77320,MEILLERAY,,48.7981344824,3.41720461603,287,,Meilleray,Meilleray,77,Seine-et-Marne,11,Île-de-France
77288,MELUN,77000,MELUN,,48.5444723553,2.65795821917,288,,Melun,Melun,77,Seine-et-Marne,11,Île-de-France
77289,MELZ SUR SEINE,77171,MELZ SUR SEINE,,48.5040892203,3.39694181186,289,,Melz-sur-Seine,Melz-sur-Seine,77,Seine-et-Marne,11,Île-de-France
77290,MERY SUR MARNE,77730,MERY SUR MARNE,,48.9758033075,3.20315053013,290,,Méry-sur-Marne,Méry-sur-Marne,77,Seine-et-Marne,11,Île-de-France
77291,LE MESNIL AMELOT,77990,LE MESNIL AMELOT,,49.0161420137,2.59606893654,291,Le,Mesnil-Amelot,Le Mesnil-Amelot,77,Seine-et-Marne,11,Île-de-France
77292,MESSY,77410,MESSY,,48.9671202326,2.70115983516,292,,Messy,Messy,77,Seine-et-Marne,11,Île-de-France
77293,MISY SUR YONNE,77130,MISY SUR YONNE,,48.3656144275,3.0952436577,293,,Misy-sur-Yonne,Misy-sur-Yonne,77,Seine-et-Marne,11,Île-de-France
77294,MITRY MORY,77290,MITRY MORY,,48.9779450158,2.61244465751,294,,Mitry-Mory,Mitry-Mory,77,Seine-et-Marne,11,Île-de-France
77295,MOISENAY,77950,MOISENAY,,48.5645621495,2.74111082771,295,,Moisenay,Moisenay,77,Seine-et-Marne,11,Île-de-France
77296,MOISSY CRAMAYEL,77550,MOISSY CRAMAYEL,,48.6285851533,2.5960231045,296,,Moissy-Cramayel,Moissy-Cramayel,77,Seine-et-Marne,11,Île-de-France
77297,MONDREVILLE,77570,MONDREVILLE,,48.1510497294,2.5976435537,297,,Mondreville,Mondreville,77,Seine-et-Marne,11,Île-de-France
77298,MONS EN MONTOIS,77520,MONS EN MONTOIS,,48.4912312558,3.13957041829,298,,Mons-en-Montois,Mons-en-Montois,77,Seine-et-Marne,11,Île-de-France
77300,MONTCEAUX LES MEAUX,77470,MONTCEAUX LES MEAUX,,48.9434088877,2.99444163212,300,,Montceaux-lès-Meaux,Montceaux-lès-Meaux,77,Seine-et-Marne,11,Île-de-France
77301,MONTCEAUX LES PROVINS,77151,MONTCEAUX LES PROVINS,,48.6965479073,3.444463614,301,,Montceaux-lès-Provins,Montceaux-lès-Provins,77,Seine-et-Marne,11,Île-de-France
77302,MONTCOURT FROMONVILLE,77140,MONTCOURT FROMONVILLE,,48.2991784842,2.71237772012,302,,Montcourt-Fromonville,Montcourt-Fromonville,77,Seine-et-Marne,11,Île-de-France
77303,MONTDAUPHIN,77320,MONTDAUPHIN,,48.8526076022,3.41948549224,303,,Montdauphin,Montdauphin,77,Seine-et-Marne,11,Île-de-France
77304,MONTENILS,77320,MONTENILS,,48.835294187,3.47842344404,304,,Montenils,Montenils,77,Seine-et-Marne,11,Île-de-France
77305,MONTEREAU FAULT YONNE,77130,MONTEREAU FAULT YONNE,,48.3898830911,2.96155479657,305,,Montereau-Fault-Yonne,Montereau-Fault-Yonne,77,Seine-et-Marne,11,Île-de-France
77306,MONTEREAU SUR LE JARD,77950,MONTEREAU SUR LE JARD,,48.5963121616,2.67243506612,306,,Montereau-sur-le-Jard,Montereau-sur-le-Jard,77,Seine-et-Marne,11,Île-de-France
77307,MONTEVRAIN,77144,MONTEVRAIN,,48.8682918937,2.74959681841,307,,Montévrain,Montévrain,77,Seine-et-Marne,11,Île-de-France
77308,MONTGE EN GOELE,77230,MONTGE EN GOELE,,49.0293871876,2.74391030915,308,,Montgé-en-Goële,Montgé-en-Goële,77,Seine-et-Marne,11,Île-de-France
77309,MONTHYON,77122,MONTHYON,,49.0101085628,2.83291898468,309,,Monthyon,Monthyon,77,Seine-et-Marne,11,Île-de-France
77310,MONTIGNY LE GUESDIER,77480,MONTIGNY LE GUESDIER,,48.3812410409,3.24951033168,310,,Montigny-le-Guesdier,Montigny-le-Guesdier,77,Seine-et-Marne,11,Île-de-France
77311,MONTIGNY LENCOUP,77520,MONTIGNY LENCOUP,,48.4543482078,3.05281640962,311,,Montigny-Lencoup,Montigny-Lencoup,77,Seine-et-Marne,11,Île-de-France
77312,MONTIGNY SUR LOING,77690,MONTIGNY SUR LOING,,48.3413324688,2.75094860085,312,,Montigny-sur-Loing,Montigny-sur-Loing,77,Seine-et-Marne,11,Île-de-France
77313,MONTMACHOUX,77940,MONTMACHOUX,,48.3199642252,2.99238308461,313,,Montmachoux,Montmachoux,77,Seine-et-Marne,11,Île-de-France
77314,MONTOLIVET,77320,MONTOLIVET,,48.8279632534,3.43159250475,314,,Montolivet,Montolivet,77,Seine-et-Marne,11,Île-de-France
77315,MONTRY,77450,MONTRY,,48.8859315574,2.82319777839,315,,Montry,Montry,77,Seine-et-Marne,11,Île-de-France
77316,MORET LOING ET ORVANNE,77250,MORET LOING ET ORVANNE,,48.365092921,2.81172585849,316,,Moret-Loing-et-Orvanne,Moret-Loing-et-Orvanne,77,Seine-et-Marne,11,Île-de-France
77316,MORET LOING ET ORVANNE,77250,MORET LOING ET ORVANNE,ECUELLES,48.365092921,2.81172585849,316,,Moret-Loing-et-Orvanne,Moret-Loing-et-Orvanne,77,Seine-et-Marne,11,Île-de-France
77316,MORET LOING ET ORVANNE,77250,MORET LOING ET ORVANNE,EPISY,48.365092921,2.81172585849,316,,Moret-Loing-et-Orvanne,Moret-Loing-et-Orvanne,77,Seine-et-Marne,11,Île-de-France
77316,MORET LOING ET ORVANNE,77250,MORET LOING ET ORVANNE,MONTARLOT,48.365092921,2.81172585849,316,,Moret-Loing-et-Orvanne,Moret-Loing-et-Orvanne,77,Seine-et-Marne,11,Île-de-France
77317,MORMANT,77720,MORMANT,,48.5977701825,2.88204553613,317,,Mormant,Mormant,77,Seine-et-Marne,11,Île-de-France
77318,MORTCERF,77163,MORTCERF,,48.7828938577,2.90546822956,318,,Mortcerf,Mortcerf,77,Seine-et-Marne,11,Île-de-France
77319,MORTERY,77160,MORTERY,,48.5914990543,3.24833157158,319,,Mortery,Mortery,77,Seine-et-Marne,11,Île-de-France
77320,MOUROUX,77120,MOUROUX,,48.8270896205,3.0508921508,320,,Mouroux,Mouroux,77,Seine-et-Marne,11,Île-de-France
77321,MOUSSEAUX LES BRAY,77480,MOUSSEAUX LES BRAY,,48.3959388077,3.22954318005,321,,Mousseaux-lès-Bray,Mousseaux-lès-Bray,77,Seine-et-Marne,11,Île-de-France
77322,MOUSSY LE NEUF,77230,MOUSSY LE NEUF,,49.0702568046,2.60754182712,322,,Moussy-le-Neuf,Moussy-le-Neuf,77,Seine-et-Marne,11,Île-de-France
77323,MOUSSY LE VIEUX,77230,MOUSSY LE VIEUX,,49.047876566,2.62361968974,323,,Moussy-le-Vieux,Moussy-le-Vieux,77,Seine-et-Marne,11,Île-de-France
77325,MOUY SUR SEINE,77480,MOUY SUR SEINE,,48.4321477503,3.24604389645,325,,Mouy-sur-Seine,Mouy-sur-Seine,77,Seine-et-Marne,11,Île-de-France
77326,NANDY,77176,NANDY,,48.5821287279,2.54782852721,326,,Nandy,Nandy,77,Seine-et-Marne,11,Île-de-France
77327,NANGIS,77370,NANGIS,,48.554637282,3.00883892893,327,,Nangis,Nangis,77,Seine-et-Marne,11,Île-de-France
77328,NANTEAU SUR ESSONNE,77760,NANTEAU SUR ESSONNE,,48.3201309037,2.42677043892,328,,Nanteau-sur-Essonne,Nanteau-sur-Essonne,77,Seine-et-Marne,11,Île-de-France
77329,NANTEAU SUR LUNAIN,77710,NANTEAU SUR LUNAIN,,48.2542947605,2.81304078481,329,,Nanteau-sur-Lunain,Nanteau-sur-Lunain,77,Seine-et-Marne,11,Île-de-France
77330,NANTEUIL LES MEAUX,77100,NANTEUIL LES MEAUX,,48.9285877665,2.8991405174,330,,Nanteuil-lès-Meaux,Nanteuil-lès-Meaux,77,Seine-et-Marne,11,Île-de-France
77331,NANTEUIL SUR MARNE,77730,NANTEUIL SUR MARNE,,48.9805109737,3.22232839885,331,,Nanteuil-sur-Marne,Nanteuil-sur-Marne,77,Seine-et-Marne,11,Île-de-France
77332,NANTOUILLET,77230,NANTOUILLET,,49.0009752296,2.70169532567,332,,Nantouillet,Nantouillet,77,Seine-et-Marne,11,Île-de-France
77333,NEMOURS,77140,NEMOURS,,48.2596067909,2.71107993087,333,,Nemours,Nemours,77,Seine-et-Marne,11,Île-de-France
77335,CHAUCONIN NEUFMONTIERS,77124,CHAUCONIN NEUFMONTIERS,,48.9719332783,2.83158634864,335,,Chauconin-Neufmontiers,Chauconin-Neufmontiers,77,Seine-et-Marne,11,Île-de-France
77335,CHAUCONIN NEUFMONTIERS,77124,CHAUCONIN NEUFMONTIERS,CHAUCONIN,48.9719332783,2.83158634864,335,,Chauconin-Neufmontiers,Chauconin-Neufmontiers,77,Seine-et-Marne,11,Île-de-France
77335,CHAUCONIN NEUFMONTIERS,77124,CHAUCONIN NEUFMONTIERS,NEUFMONTIERS LES MEAUX,48.9719332783,2.83158634864,335,,Chauconin-Neufmontiers,Chauconin-Neufmontiers,77,Seine-et-Marne,11,Île-de-France
77336,NEUFMOUTIERS EN BRIE,77610,NEUFMOUTIERS EN BRIE,,48.7742365844,2.82602108151,336,,Neufmoutiers-en-Brie,Neufmoutiers-en-Brie,77,Seine-et-Marne,11,Île-de-France
77337,NOISIEL,77186,NOISIEL,,48.8460926469,2.61997100426,337,,Noisiel,Noisiel,77,Seine-et-Marne,11,Île-de-France
77338,NOISY RUDIGNON,77940,NOISY RUDIGNON,,48.3364793453,2.93408964722,338,,Noisy-Rudignon,Noisy-Rudignon,77,Seine-et-Marne,11,Île-de-France
77339,NOISY SUR ECOLE,77123,NOISY SUR ECOLE,,48.3776889866,2.50874542385,339,,Noisy-sur-École,Noisy-sur-École,77,Seine-et-Marne,11,Île-de-France
77340,NONVILLE,77140,NONVILLE,,48.28866643,2.7739294965,340,,Nonville,Nonville,77,Seine-et-Marne,11,Île-de-France
77341,NOYEN SUR SEINE,77114,NOYEN SUR SEINE,,48.4518691045,3.34226623535,341,,Noyen-sur-Seine,Noyen-sur-Seine,77,Seine-et-Marne,11,Île-de-France
77342,OBSONVILLE,77890,OBSONVILLE,,48.2165551692,2.56864950435,342,,Obsonville,Obsonville,77,Seine-et-Marne,11,Île-de-France
77343,OCQUERRE,77440,OCQUERRE,,49.0335795596,3.06016213237,343,,Ocquerre,Ocquerre,77,Seine-et-Marne,11,Île-de-France
77344,OISSERY,77178,OISSERY,,49.0705440359,2.8176701421,344,,Oissery,Oissery,77,Seine-et-Marne,11,Île-de-France
77345,ORLY SUR MORIN,77750,ORLY SUR MORIN,,48.9011406989,3.22658496593,345,,Orly-sur-Morin,Orly-sur-Morin,77,Seine-et-Marne,11,Île-de-France
77347,LES ORMES SUR VOULZIE,77134,LES ORMES SUR VOULZIE,,48.4595788684,3.22503214094,347,Les,Ormes-sur-Voulzie,Les Ormes-sur-Voulzie,77,Seine-et-Marne,11,Île-de-France
77348,ORMESSON,77167,ORMESSON,,48.243630146,2.65160228061,348,,Ormesson,Ormesson,77,Seine-et-Marne,11,Île-de-France
77349,OTHIS,77280,OTHIS,,49.082725931,2.65422672381,349,,Othis,Othis,77,Seine-et-Marne,11,Île-de-France
77350,OZOIR LA FERRIERE,77330,OZOIR LA FERRIERE,,48.7635612396,2.67962508199,350,,Ozoir-la-Ferrière,Ozoir-la-Ferrière,77,Seine-et-Marne,11,Île-de-France
77352,OZOUER LE VOULGIS,77390,OZOUER LE VOULGIS,,48.6647166241,2.77681122892,352,,Ozouer-le-Voulgis,Ozouer-le-Voulgis,77,Seine-et-Marne,11,Île-de-France
77353,PALEY,77710,PALEY,,48.2409902344,2.85393444453,353,,Paley,Paley,77,Seine-et-Marne,11,Île-de-France
77354,PAMFOU,77830,PAMFOU,,48.4686137503,2.86415645196,354,,Pamfou,Pamfou,77,Seine-et-Marne,11,Île-de-France
77355,PAROY,77520,PAROY,,48.4812861292,3.20164565449,355,,Paroy,Paroy,77,Seine-et-Marne,11,Île-de-France
77356,PASSY SUR SEINE,77480,PASSY SUR SEINE,,48.4286806122,3.34584614582,356,,Passy-sur-Seine,Passy-sur-Seine,77,Seine-et-Marne,11,Île-de-France
77357,PECY,77970,PECY,,48.6467079831,3.07507507912,357,,Pécy,Pécy,77,Seine-et-Marne,11,Île-de-France
77358,PENCHARD,77124,PENCHARD,,48.9907877063,2.85773717283,358,,Penchard,Penchard,77,Seine-et-Marne,11,Île-de-France
77359,PERTHES,77930,PERTHES,,48.479730606,2.55038052003,359,,Perthes,Perthes,77,Seine-et-Marne,11,Île-de-France
77360,PEZARCHES,77131,PEZARCHES,,48.7441065539,2.99013605672,360,,Pézarches,Pézarches,77,Seine-et-Marne,11,Île-de-France
77361,PIERRE LEVEE,77580,PIERRE LEVEE,,48.8973865093,3.03672367575,361,,Pierre-Levée,Pierre-Levée,77,Seine-et-Marne,11,Île-de-France
77363,LE PIN,77181,LE PIN,,48.9144329004,2.62838554691,363,Le,Pin,Le Pin,77,Seine-et-Marne,11,Île-de-France
77364,LE PLESSIS AUX BOIS,77165,LE PLESSIS AUX BOIS,,49.0037760261,2.76764045922,364,Le,Plessis-aux-Bois,Le Plessis-aux-Bois,77,Seine-et-Marne,11,Île-de-France
77365,LE PLESSIS FEU AUSSOUX,77540,LE PLESSIS FEU AUSSOUX,,48.7130840275,3.03898703443,365,Le,Plessis-Feu-Aussoux,Le Plessis-Feu-Aussoux,77,Seine-et-Marne,11,Île-de-France
77366,LE PLESSIS L EVEQUE,77165,LE PLESSIS L EVEQUE,,49.0072248611,2.79558517777,366,Le,Plessis-l'Évêque,Le Plessis-l'Évêque,77,Seine-et-Marne,11,Île-de-France
77367,LE PLESSIS PLACY,77440,LE PLESSIS PLACY,,49.0533468836,2.98533936874,367,Le,Plessis-Placy,Le Plessis-Placy,77,Seine-et-Marne,11,Île-de-France
77368,POIGNY,77160,POIGNY,,48.5449283297,3.27716740959,368,,Poigny,Poigny,77,Seine-et-Marne,11,Île-de-France
77369,POINCY,77470,POINCY,,48.9734167231,2.92373383139,369,,Poincy,Poincy,77,Seine-et-Marne,11,Île-de-France
77370,POLIGNY,77167,POLIGNY,,48.2336785458,2.7563432153,370,,Poligny,Poligny,77,Seine-et-Marne,11,Île-de-France
77371,POMMEUSE,77515,POMMEUSE,,48.8178723511,3.00613268677,371,,Pommeuse,Pommeuse,77,Seine-et-Marne,11,Île-de-France
77372,POMPONNE,77400,POMPONNE,,48.8867221487,2.67487662086,372,,Pomponne,Pomponne,77,Seine-et-Marne,11,Île-de-France
77372,POMPONNE,77400,POMPONNE,LA POMPONNETTE,48.8867221487,2.67487662086,372,,Pomponne,Pomponne,77,Seine-et-Marne,11,Île-de-France
77373,PONTAULT COMBAULT,77340,PONTAULT COMBAULT,,48.7871411658,2.61407436985,373,,Pontault-Combault,Pontault-Combault,77,Seine-et-Marne,11,Île-de-France
77373,PONTAULT COMBAULT,77340,PONTAULT COMBAULT,LE PAVE DE PONTAULT,48.7871411658,2.61407436985,373,,Pontault-Combault,Pontault-Combault,77,Seine-et-Marne,11,Île-de-France
77374,PONTCARRE,77135,PONTCARRE,,48.796292771,2.70744366503,374,,Pontcarré,Pontcarré,77,Seine-et-Marne,11,Île-de-France
77376,PRECY SUR MARNE,77410,PRECY SUR MARNE,,48.932278559,2.76825616233,376,,Précy-sur-Marne,Précy-sur-Marne,77,Seine-et-Marne,11,Île-de-France
77377,PRESLES EN BRIE,77220,PRESLES EN BRIE,,48.7132293584,2.73724881309,377,,Presles-en-Brie,Presles-en-Brie,77,Seine-et-Marne,11,Île-de-France
77378,PRINGY,77310,PRINGY,,48.5176600254,2.55002787016,378,,Pringy,Pringy,77,Seine-et-Marne,11,Île-de-France
77379,PROVINS,77160,PROVINS,,48.5633093312,3.28756780085,379,,Provins,Provins,77,Seine-et-Marne,11,Île-de-France
77380,PUISIEUX,77139,PUISIEUX,,49.0654141426,2.91537283877,380,,Puisieux,Puisieux,77,Seine-et-Marne,11,Île-de-France
77381,QUIERS,77720,QUIERS,,48.6142332217,2.96159846336,381,,Quiers,Quiers,77,Seine-et-Marne,11,Île-de-France
77382,QUINCY VOISINS,77860,QUINCY VOISINS,,48.8993902334,2.87746538082,382,,Quincy-Voisins,Quincy-Voisins,77,Seine-et-Marne,11,Île-de-France
77383,RAMPILLON,77370,RAMPILLON,,48.5410434258,3.07288099272,383,,Rampillon,Rampillon,77,Seine-et-Marne,11,Île-de-France
77384,REAU,77550,REAU,,48.6073931366,2.62398597049,384,,Réau,Réau,77,Seine-et-Marne,11,Île-de-France
77385,REBAIS,77510,REBAIS,,48.8579690039,3.23286797694,385,,Rebais,Rebais,77,Seine-et-Marne,11,Île-de-France
77386,RECLOSES,77760,RECLOSES,,48.3397926462,2.64268589454,386,,Recloses,Recloses,77,Seine-et-Marne,11,Île-de-France
77387,REMAUVILLE,77710,REMAUVILLE,,48.2205966192,2.82537192194,387,,Remauville,Remauville,77,Seine-et-Marne,11,Île-de-France
77388,REUIL EN BRIE,77260,REUIL EN BRIE,,48.9565680166,3.16343930327,388,,Reuil-en-Brie,Reuil-en-Brie,77,Seine-et-Marne,11,Île-de-France
77389,LA ROCHETTE,77000,LA ROCHETTE,,48.5068460173,2.65955941883,389,La,Rochette,La Rochette,77,Seine-et-Marne,11,Île-de-France
77390,ROISSY EN BRIE,77680,ROISSY EN BRIE,,48.7900855424,2.66122415764,390,,Roissy-en-Brie,Roissy-en-Brie,77,Seine-et-Marne,11,Île-de-France
77391,ROUILLY,77160,ROUILLY,,48.5914740007,3.28881979874,391,,Rouilly,Rouilly,77,Seine-et-Marne,11,Île-de-France
77392,ROUVRES,77230,ROUVRES,,49.0637945293,2.71838722882,392,,Rouvres,Rouvres,77,Seine-et-Marne,11,Île-de-France
77393,ROZAY EN BRIE,77540,ROZAY EN BRIE,,48.6835473801,2.96386164436,393,,Rozay-en-Brie,Rozay-en-Brie,77,Seine-et-Marne,11,Île-de-France
77394,RUBELLES,77950,RUBELLES,,48.5646424027,2.68024008996,394,,Rubelles,Rubelles,77,Seine-et-Marne,11,Île-de-France
77395,RUMONT,77760,RUMONT,,48.2703586481,2.4951176944,395,,Rumont,Rumont,77,Seine-et-Marne,11,Île-de-France
77396,RUPEREUX,77560,RUPEREUX,,48.6426005299,3.32681043883,396,,Rupéreux,Rupéreux,77,Seine-et-Marne,11,Île-de-France
77397,SAACY SUR MARNE,77730,SAACY SUR MARNE,,48.9510388059,3.21708919555,397,,Saâcy-sur-Marne,Saâcy-sur-Marne,77,Seine-et-Marne,11,Île-de-France
77398,SABLONNIERES,77510,SABLONNIERES,,48.8758526374,3.29342847866,398,,Sablonnières,Sablonnières,77,Seine-et-Marne,11,Île-de-France
77399,ST ANGE LE VIEL,77710,ST ANGE LE VIEL,,48.2723686746,2.90471445471,399,,Saint-Ange-le-Viel,Saint-Ange-le-Viel,77,Seine-et-Marne,11,Île-de-France
77400,ST AUGUSTIN,77515,ST AUGUSTIN,,48.789144962,3.02887733096,400,,Saint-Augustin,Saint-Augustin,77,Seine-et-Marne,11,Île-de-France
77401,STE AULDE,77260,STE AULDE,,49.0030713082,3.1746031904,401,,Sainte-Aulde,Sainte-Aulde,77,Seine-et-Marne,11,Île-de-France
77402,ST BARTHELEMY,77320,ST BARTHELEMY,,48.8198934361,3.364501575,402,,Saint-Barthélemy,Saint-Barthélemy,77,Seine-et-Marne,11,Île-de-France
77403,ST BRICE,77160,ST BRICE,,48.5723421342,3.33441347649,403,,Saint-Brice,Saint-Brice,77,Seine-et-Marne,11,Île-de-France
77404,STE COLOMBE,77650,STE COLOMBE,,48.5343555436,3.25919071204,404,,Sainte-Colombe,Sainte-Colombe,77,Seine-et-Marne,11,Île-de-France
77405,ST CYR SUR MORIN,77750,ST CYR SUR MORIN,,48.9119288243,3.17472722013,405,,Saint-Cyr-sur-Morin,Saint-Cyr-sur-Morin,77,Seine-et-Marne,11,Île-de-France
77406,ST DENIS LES REBAIS,77510,ST DENIS LES REBAIS,,48.845087486,3.19932466379,406,,Saint-Denis-lès-Rebais,Saint-Denis-lès-Rebais,77,Seine-et-Marne,11,Île-de-France
77407,ST FARGEAU PONTHIERRY,77310,ST FARGEAU PONTHIERRY,,48.5390914714,2.52499710746,407,,Saint-Fargeau-Ponthierry,Saint-Fargeau-Ponthierry,77,Seine-et-Marne,11,Île-de-France
77407,ST FARGEAU PONTHIERRY,77310,ST FARGEAU PONTHIERRY,PONTHIERRY,48.5390914714,2.52499710746,407,,Saint-Fargeau-Ponthierry,Saint-Fargeau-Ponthierry,77,Seine-et-Marne,11,Île-de-France
77408,ST FIACRE,77470,ST FIACRE,,48.9247152564,2.95384065653,408,,Saint-Fiacre,Saint-Fiacre,77,Seine-et-Marne,11,Île-de-France
77409,ST GERMAIN LAVAL,77130,ST GERMAIN LAVAL,,48.4089652816,2.99481722359,409,,Saint-Germain-Laval,Saint-Germain-Laval,77,Seine-et-Marne,11,Île-de-France
77410,ST GERMAIN LAXIS,77950,ST GERMAIN LAXIS,,48.5862771914,2.70637166512,410,,Saint-Germain-Laxis,Saint-Germain-Laxis,77,Seine-et-Marne,11,Île-de-France
77411,ST GERMAIN SOUS DOUE,77169,ST GERMAIN SOUS DOUE,,48.8511027251,3.13894203561,411,,Saint-Germain-sous-Doue,Saint-Germain-sous-Doue,77,Seine-et-Marne,11,Île-de-France
77412,ST GERMAIN SUR ECOLE,77930,ST GERMAIN SUR ECOLE,,48.4743072486,2.51078189836,412,,Saint-Germain-sur-École,Saint-Germain-sur-École,77,Seine-et-Marne,11,Île-de-France
77413,ST GERMAIN SUR MORIN,77860,ST GERMAIN SUR MORIN,,48.8752580652,2.84028985019,413,,Saint-Germain-sur-Morin,Saint-Germain-sur-Morin,77,Seine-et-Marne,11,Île-de-France
77414,ST HILLIERS,77160,ST HILLIERS,,48.6289146411,3.25823552684,414,,Saint-Hilliers,Saint-Hilliers,77,Seine-et-Marne,11,Île-de-France
77415,ST JEAN LES DEUX JUMEAUX,77660,ST JEAN LES DEUX JUMEAUX,,48.9387834694,3.02695540228,415,,Saint-Jean-les-Deux-Jumeaux,Saint-Jean-les-Deux-Jumeaux,77,Seine-et-Marne,11,Île-de-France
77416,ST JUST EN BRIE,77370,ST JUST EN BRIE,,48.6110276431,3.12128070176,416,,Saint-Just-en-Brie,Saint-Just-en-Brie,77,Seine-et-Marne,11,Île-de-France
77417,ST LEGER,77510,ST LEGER,,48.8435888825,3.27123408518,417,,Saint-Léger,Saint-Léger,77,Seine-et-Marne,11,Île-de-France
77418,ST LOUP DE NAUD,77650,ST LOUP DE NAUD,,48.5369790408,3.21790168625,418,,Saint-Loup-de-Naud,Saint-Loup-de-Naud,77,Seine-et-Marne,11,Île-de-France
77419,ST MAMMES,77670,ST MAMMES,,48.3867865582,2.81899706389,419,,Saint-Mammès,Saint-Mammès,77,Seine-et-Marne,11,Île-de-France
77420,ST MARD,77230,ST MARD,,49.0387495499,2.69702650217,420,,Saint-Mard,Saint-Mard,77,Seine-et-Marne,11,Île-de-France
77421,ST MARS VIEUX MAISONS,77320,ST MARS VIEUX MAISONS,,48.7382088381,3.3197504668,421,,Saint-Mars-Vieux-Maisons,Saint-Mars-Vieux-Maisons,77,Seine-et-Marne,11,Île-de-France
77421,ST MARS VIEUX MAISONS,77320,ST MARS VIEUX MAISONS,VIEUX MAISONS,48.7382088381,3.3197504668,421,,Saint-Mars-Vieux-Maisons,Saint-Mars-Vieux-Maisons,77,Seine-et-Marne,11,Île-de-France
77423,ST MARTIN DES CHAMPS,77320,ST MARTIN DES CHAMPS,,48.7883444526,3.33427084633,423,,Saint-Martin-des-Champs,Saint-Martin-des-Champs,77,Seine-et-Marne,11,Île-de-France
77424,ST MARTIN DU BOSCHET,77320,ST MARTIN DU BOSCHET,,48.7323588994,3.42880146544,424,,Saint-Martin-du-Boschet,Saint-Martin-du-Boschet,77,Seine-et-Marne,11,Île-de-France
77425,ST MARTIN EN BIERE,77630,ST MARTIN EN BIERE,,48.4342403607,2.57483458282,425,,Saint-Martin-en-Bière,Saint-Martin-en-Bière,77,Seine-et-Marne,11,Île-de-France
77426,ST MERY,77720,ST MERY,,48.570308165,2.83791524459,426,,Saint-Méry,Saint-Méry,77,Seine-et-Marne,11,Île-de-France
77427,ST MESMES,77410,ST MESMES,,48.9856128069,2.71476494573,427,,Saint-Mesmes,Saint-Mesmes,77,Seine-et-Marne,11,Île-de-France
77428,ST OUEN EN BRIE,77720,ST OUEN EN BRIE,,48.56560984,2.9198513048,428,,Saint-Ouen-en-Brie,Saint-Ouen-en-Brie,77,Seine-et-Marne,11,Île-de-France
77429,ST OUEN SUR MORIN,77750,ST OUEN SUR MORIN,,48.9068261671,3.20190542412,429,,Saint-Ouen-sur-Morin,Saint-Ouen-sur-Morin,77,Seine-et-Marne,11,Île-de-France
77430,ST PATHUS,77178,ST PATHUS,,49.0737570585,2.78794221143,430,,Saint-Pathus,Saint-Pathus,77,Seine-et-Marne,11,Île-de-France
77431,ST PIERRE LES NEMOURS,77140,ST PIERRE LES NEMOURS,,48.2646880491,2.66647381403,431,,Saint-Pierre-lès-Nemours,Saint-Pierre-lès-Nemours,77,Seine-et-Marne,11,Île-de-France
77432,ST REMY LA VANNE,77320,ST REMY LA VANNE,,48.8125459558,3.24204081614,432,,Saint-Rémy-la-Vanne,Saint-Rémy-la-Vanne,77,Seine-et-Marne,11,Île-de-France
77433,SAINTS,77120,SAINTS,,48.7643724711,3.05922570921,433,,Saints,Saints,77,Seine-et-Marne,11,Île-de-France
77434,ST SAUVEUR LES BRAY,77480,ST SAUVEUR LES BRAY,,48.4379889484,3.20463281198,434,,Saint-Sauveur-lès-Bray,Saint-Sauveur-lès-Bray,77,Seine-et-Marne,11,Île-de-France
77435,ST SAUVEUR SUR ECOLE,77930,ST SAUVEUR SUR ECOLE,,48.4997072373,2.53411876201,435,,Saint-Sauveur-sur-École,Saint-Sauveur-sur-École,77,Seine-et-Marne,11,Île-de-France
77436,ST SIMEON,77169,ST SIMEON,,48.8045983956,3.19433416433,436,,Saint-Siméon,Saint-Siméon,77,Seine-et-Marne,11,Île-de-France
77437,ST SOUPPLETS,77165,ST SOUPPLETS,,49.0367930626,2.80251354691,437,,Saint-Soupplets,Saint-Soupplets,77,Seine-et-Marne,11,Île-de-France
77438,ST THIBAULT DES VIGNES,77400,ST THIBAULT DES VIGNES,,48.8637030584,2.67576810808,438,,Saint-Thibault-des-Vignes,Saint-Thibault-des-Vignes,77,Seine-et-Marne,11,Île-de-France
77439,SALINS,77148,SALINS,,48.4316639187,3.0194177211,439,,Salins,Salins,77,Seine-et-Marne,11,Île-de-France
77440,SAMMERON,77260,SAMMERON,,48.9415743758,3.07701664102,440,,Sammeron,Sammeron,77,Seine-et-Marne,11,Île-de-France
77441,SAMOIS SUR SEINE,77920,SAMOIS SUR SEINE,,48.4550515717,2.75291155262,441,,Samois-sur-Seine,Samois-sur-Seine,77,Seine-et-Marne,11,Île-de-France
77442,SAMOREAU,77210,SAMOREAU,,48.4200104372,2.76978734105,442,,Samoreau,Samoreau,77,Seine-et-Marne,11,Île-de-France
77443,SANCY,77580,SANCY,,48.8791674631,2.96108511605,443,,Sancy,Sancy,77,Seine-et-Marne,11,Île-de-France
77444,SANCY LES PROVINS,77320,SANCY LES PROVINS,,48.7095987707,3.39288392593,444,,Sancy-lès-Provins,Sancy-lès-Provins,77,Seine-et-Marne,11,Île-de-France
77445,SAVIGNY LE TEMPLE,77176,SAVIGNY LE TEMPLE,,48.5884785769,2.57373861268,445,,Savigny-le-Temple,Savigny-le-Temple,77,Seine-et-Marne,11,Île-de-France
77446,SAVINS,77650,SAVINS,,48.5115912505,3.20518344066,446,,Savins,Savins,77,Seine-et-Marne,11,Île-de-France
77447,SEINE PORT,77240,SEINE PORT,,48.5514696661,2.55439654626,447,,Seine-Port,Seine-Port,77,Seine-et-Marne,11,Île-de-France
77448,SEPT SORTS,77260,SEPT SORTS,,48.940158615,3.10627084315,448,,Sept-Sorts,Sept-Sorts,77,Seine-et-Marne,11,Île-de-France
77449,SERRIS,77700,SERRIS,,48.844790279,2.78754866071,449,,Serris,Serris,77,Seine-et-Marne,11,Île-de-France
77450,SERVON,77170,SERVON,,48.7150535859,2.59164264543,450,,Servon,Servon,77,Seine-et-Marne,11,Île-de-France
77451,SIGNY SIGNETS,77640,SIGNY SIGNETS,,48.9173760452,3.06397097105,451,,Signy-Signets,Signy-Signets,77,Seine-et-Marne,11,Île-de-France
77452,SIGY,77520,SIGY,,48.4730612622,3.16363962665,452,,Sigy,Sigy,77,Seine-et-Marne,11,Île-de-France
77453,SIVRY COURTRY,77115,SIVRY COURTRY,,48.530887524,2.75615007071,453,,Sivry-Courtry,Sivry-Courtry,77,Seine-et-Marne,11,Île-de-France
77454,SOGNOLLES EN MONTOIS,77520,SOGNOLLES EN MONTOIS,,48.5188148305,3.15220326867,454,,Sognolles-en-Montois,Sognolles-en-Montois,77,Seine-et-Marne,11,Île-de-France
77455,SOIGNOLLES EN BRIE,77111,SOIGNOLLES EN BRIE,,48.645281445,2.70806809246,455,,Soignolles-en-Brie,Soignolles-en-Brie,77,Seine-et-Marne,11,Île-de-France
77456,SOISY BOUY,77650,SOISY BOUY,,48.5084866501,3.29987604926,456,,Soisy-Bouy,Soisy-Bouy,77,Seine-et-Marne,11,Île-de-France
77457,SOLERS,77111,SOLERS,,48.6593859188,2.72669568972,457,,Solers,Solers,77,Seine-et-Marne,11,Île-de-France
77458,SOUPPES SUR LOING,77460,SOUPPES SUR LOING,,48.186873445,2.74753245184,458,,Souppes-sur-Loing,Souppes-sur-Loing,77,Seine-et-Marne,11,Île-de-France
77459,SOURDUN,77171,SOURDUN,,48.5369434021,3.3628787539,459,,Sourdun,Sourdun,77,Seine-et-Marne,11,Île-de-France
77460,TANCROU,77440,TANCROU,,49.0038979102,3.07986260706,460,,Tancrou,Tancrou,77,Seine-et-Marne,11,Île-de-France
77461,THENISY,77520,THENISY,,48.4916945564,3.17799318925,461,,Thénisy,Thénisy,77,Seine-et-Marne,11,Île-de-France
77462,THIEUX,77230,THIEUX,,49.0091475315,2.66309885608,462,,Thieux,Thieux,77,Seine-et-Marne,11,Île-de-France
77463,THOMERY,77810,THOMERY,,48.4042073432,2.77997290314,463,,Thomery,Thomery,77,Seine-et-Marne,11,Île-de-France
77464,THORIGNY SUR MARNE,77400,THORIGNY SUR MARNE,,48.8918979999,2.7123703432,464,,Thorigny-sur-Marne,Thorigny-sur-Marne,77,Seine-et-Marne,11,Île-de-France
77465,THOURY FEROTTES,77940,THOURY FEROTTES,,48.2974586378,2.93972064778,465,,Thoury-Férottes,Thoury-Férottes,77,Seine-et-Marne,11,Île-de-France
77466,TIGEAUX,77163,TIGEAUX,,48.8251475937,2.89871358774,466,,Tigeaux,Tigeaux,77,Seine-et-Marne,11,Île-de-France
77467,LA TOMBE,77130,LA TOMBE,,48.3867483242,3.09357430759,467,La,Tombe,La Tombe,77,Seine-et-Marne,11,Île-de-France
77468,TORCY,77200,TORCY,,48.8532553496,2.65123227423,468,,Torcy,Torcy,77,Seine-et-Marne,11,Île-de-France
77469,TOUQUIN,77131,TOUQUIN,,48.7330599565,3.02878980311,469,,Touquin,Touquin,77,Seine-et-Marne,11,Île-de-France
77470,TOURNAN EN BRIE,77220,TOURNAN EN BRIE,,48.739865817,2.77820566994,470,,Tournan-en-Brie,Tournan-en-Brie,77,Seine-et-Marne,11,Île-de-France
77471,TOUSSON,77123,TOUSSON,,48.3433486346,2.45792608191,471,,Tousson,Tousson,77,Seine-et-Marne,11,Île-de-France
77472,LA TRETOIRE,77510,LA TRETOIRE,,48.8788972003,3.24649489754,472,La,Trétoire,La Trétoire,77,Seine-et-Marne,11,Île-de-France
77473,TREUZY LEVELAY,77710,TREUZY LEVELAY,,48.2730119814,2.79481734896,473,,Treuzy-Levelay,Treuzy-Levelay,77,Seine-et-Marne,11,Île-de-France
77474,TRILBARDOU,77450,TRILBARDOU,,48.9487712002,2.80417897378,474,,Trilbardou,Trilbardou,77,Seine-et-Marne,11,Île-de-France
77475,TRILPORT,77470,TRILPORT,,48.958724925,2.9662410397,475,,Trilport,Trilport,77,Seine-et-Marne,11,Île-de-France
77476,TROCY EN MULTIEN,77440,TROCY EN MULTIEN,,49.04241911,2.96205647548,476,,Trocy-en-Multien,Trocy-en-Multien,77,Seine-et-Marne,11,Île-de-France
77477,URY,77760,URY,,48.3408766133,2.60035520757,477,,Ury,Ury,77,Seine-et-Marne,11,Île-de-France
77478,USSY SUR MARNE,77260,USSY SUR MARNE,,48.9665838811,3.08198151842,478,,Ussy-sur-Marne,Ussy-sur-Marne,77,Seine-et-Marne,11,Île-de-France
77479,VAIRES SUR MARNE,77360,VAIRES SUR MARNE,,48.8697812117,2.63565952774,479,,Vaires-sur-Marne,Vaires-sur-Marne,77,Seine-et-Marne,11,Île-de-France
77480,VALENCE EN BRIE,77830,VALENCE EN BRIE,,48.4462252242,2.89307139548,480,,Valence-en-Brie,Valence-en-Brie,77,Seine-et-Marne,11,Île-de-France
77481,VANVILLE,77370,VANVILLE,,48.5547348508,3.11550566194,481,,Vanvillé,Vanvillé,77,Seine-et-Marne,11,Île-de-France
77482,VARENNES SUR SEINE,77130,VARENNES SUR SEINE,,48.3685393925,2.93052193046,482,,Varennes-sur-Seine,Varennes-sur-Seine,77,Seine-et-Marne,11,Île-de-France
77483,VARREDDES,77910,VARREDDES,,49.0058198768,2.92807790082,483,,Varreddes,Varreddes,77,Seine-et-Marne,11,Île-de-France
77484,VAUCOURTOIS,77580,VAUCOURTOIS,,48.8988382748,2.95694205944,484,,Vaucourtois,Vaucourtois,77,Seine-et-Marne,11,Île-de-France
77485,LE VAUDOUE,77123,LE VAUDOUE,,48.3471336431,2.52187638095,485,Le,Vaudoué,Le Vaudoué,77,Seine-et-Marne,11,Île-de-France
77486,VAUDOY EN BRIE,77141,VAUDOY EN BRIE,,48.6975609206,3.08246340296,486,,Vaudoy-en-Brie,Vaudoy-en-Brie,77,Seine-et-Marne,11,Île-de-France
77487,VAUX LE PENIL,77000,VAUX LE PENIL,,48.5245871061,2.69727154378,487,,Vaux-le-Pénil,Vaux-le-Pénil,77,Seine-et-Marne,11,Île-de-France
77489,VAUX SUR LUNAIN,77710,VAUX SUR LUNAIN,,48.2172127701,2.95608687154,489,,Vaux-sur-Lunain,Vaux-sur-Lunain,77,Seine-et-Marne,11,Île-de-France
77490,VENDREST,77440,VENDREST,,49.0415084133,3.10843248735,490,,Vendrest,Vendrest,77,Seine-et-Marne,11,Île-de-France
77491,VENEUX LES SABLONS,77250,VENEUX LES SABLONS,,48.3811719817,2.79535158801,491,,Veneux-les-Sablons,Veneux-les-Sablons,77,Seine-et-Marne,11,Île-de-France
77492,VERDELOT,77510,VERDELOT,,48.8785255196,3.36521259688,492,,Verdelot,Verdelot,77,Seine-et-Marne,11,Île-de-France
77493,VERNEUIL L ETANG,77390,VERNEUIL L ETANG,,48.6370893938,2.8323243324,493,,Verneuil-l'Étang,Verneuil-l'Étang,77,Seine-et-Marne,11,Île-de-France
77494,VERNOU LA CELLE SUR SEINE,77670,VERNOU LA CELLE SUR SEINE,,48.4062374172,2.85382135685,494,,Vernou-la-Celle-sur-Seine,Vernou-la-Celle-sur-Seine,77,Seine-et-Marne,11,Île-de-France
77494,VERNOU LA CELLE SUR SEINE,77670,VERNOU LA CELLE SUR SEINE,LA CELLE SUR SEINE,48.4062374172,2.85382135685,494,,Vernou-la-Celle-sur-Seine,Vernou-la-Celle-sur-Seine,77,Seine-et-Marne,11,Île-de-France
77495,VERT ST DENIS,77240,VERT ST DENIS,,48.5711806547,2.62717330981,495,,Vert-Saint-Denis,Vert-Saint-Denis,77,Seine-et-Marne,11,Île-de-France
77496,VIEUX CHAMPAGNE,77370,VIEUX CHAMPAGNE,,48.5871399765,3.1382187303,496,,Vieux-Champagne,Vieux-Champagne,77,Seine-et-Marne,11,Île-de-France
77498,VIGNELY,77450,VIGNELY,,48.9326287098,2.81578532754,498,,Vignely,Vignely,77,Seine-et-Marne,11,Île-de-France
77500,VILLEBEON,77710,VILLEBEON,,48.2049138912,2.92837301126,500,,Villebéon,Villebéon,77,Seine-et-Marne,11,Île-de-France
77501,VILLECERF,77250,VILLECERF,,48.32726351,2.84591675983,501,,Villecerf,Villecerf,77,Seine-et-Marne,11,Île-de-France
77504,VILLEMARECHAL,77710,VILLEMARECHAL,,48.2716975707,2.86854814458,504,,Villemaréchal,Villemaréchal,77,Seine-et-Marne,11,Île-de-France
77505,VILLEMAREUIL,77470,VILLEMAREUIL,,48.9202287763,2.98474504505,505,,Villemareuil,Villemareuil,77,Seine-et-Marne,11,Île-de-France
77506,VILLEMER,77250,VILLEMER,,48.303657213,2.82605774417,506,,Villemer,Villemer,77,Seine-et-Marne,11,Île-de-France
77507,VILLENAUXE LA PETITE,77480,VILLENAUXE LA PETITE,,48.4004012721,3.31327470557,507,,Villenauxe-la-Petite,Villenauxe-la-Petite,77,Seine-et-Marne,11,Île-de-France
77508,VILLENEUVE LE COMTE,77174,VILLENEUVE LE COMTE,,48.8115357459,2.83836128857,508,,Villeneuve-le-Comte,Villeneuve-le-Comte,77,Seine-et-Marne,11,Île-de-France
77509,VILLENEUVE LES BORDES,77154,VILLENEUVE LES BORDES,,48.4947457361,3.03085746067,509,,Villeneuve-les-Bordes,Villeneuve-les-Bordes,77,Seine-et-Marne,11,Île-de-France
77510,VILLENEUVE ST DENIS,77174,VILLENEUVE ST DENIS,,48.8124542945,2.79328879273,510,,Villeneuve-Saint-Denis,Villeneuve-Saint-Denis,77,Seine-et-Marne,11,Île-de-France
77511,VILLENEUVE SOUS DAMMARTIN,77230,VILLENEUVE SOUS DAMMARTIN,,49.0287050139,2.6367073967,511,,Villeneuve-sous-Dammartin,Villeneuve-sous-Dammartin,77,Seine-et-Marne,11,Île-de-France
77512,VILLENEUVE SUR BELLOT,77510,VILLENEUVE SUR BELLOT,,48.8731734921,3.33324327712,512,,Villeneuve-sur-Bellot,Villeneuve-sur-Bellot,77,Seine-et-Marne,11,Île-de-France
77513,VILLENOY,77124,VILLENOY,,48.9414484528,2.85149852192,513,,Villenoy,Villenoy,77,Seine-et-Marne,11,Île-de-France
77514,VILLEPARISIS,77270,VILLEPARISIS,,48.9419981883,2.61700200964,514,,Villeparisis,Villeparisis,77,Seine-et-Marne,11,Île-de-France
77515,VILLEROY,77410,VILLEROY,,48.9824706589,2.78307266843,515,,Villeroy,Villeroy,77,Seine-et-Marne,11,Île-de-France
77516,VILLE ST JACQUES,77130,VILLE ST JACQUES,,48.3445385787,2.89832891808,516,,Ville-Saint-Jacques,Ville-Saint-Jacques,77,Seine-et-Marne,11,Île-de-France
77517,VILLEVAUDE,77410,VILLEVAUDE,,48.9117801079,2.66224807334,517,,Villevaudé,Villevaudé,77,Seine-et-Marne,11,Île-de-France
77517,VILLEVAUDE,77410,VILLEVAUDE,BORDEAUX,48.9117801079,2.66224807334,517,,Villevaudé,Villevaudé,77,Seine-et-Marne,11,Île-de-France
77517,VILLEVAUDE,77410,VILLEVAUDE,MONTJAY LA TOUR,48.9117801079,2.66224807334,517,,Villevaudé,Villevaudé,77,Seine-et-Marne,11,Île-de-France
77518,VILLIERS EN BIERE,77190,VILLIERS EN BIERE,,48.495422077,2.60034178878,518,,Villiers-en-Bière,Villiers-en-Bière,77,Seine-et-Marne,11,Île-de-France
77519,VILLIERS ST GEORGES,77560,VILLIERS ST GEORGES,,48.6516910276,3.40775745476,519,,Villiers-Saint-Georges,Villiers-Saint-Georges,77,Seine-et-Marne,11,Île-de-France
77520,VILLIERS SOUS GREZ,77760,VILLIERS SOUS GREZ,,48.3144333448,2.6391835166,520,,Villiers-sous-Grez,Villiers-sous-Grez,77,Seine-et-Marne,11,Île-de-France
77521,VILLIERS SUR MORIN,77580,VILLIERS SUR MORIN,,48.8490884714,2.86879135984,521,,Villiers-sur-Morin,Villiers-sur-Morin,77,Seine-et-Marne,11,Île-de-France
77522,VILLIERS SUR SEINE,77114,VILLIERS SUR SEINE,,48.4563743528,3.38301719826,522,,Villiers-sur-Seine,Villiers-sur-Seine,77,Seine-et-Marne,11,Île-de-France
77523,VILLUIS,77480,VILLUIS,,48.4053424747,3.35903644752,523,,Villuis,Villuis,77,Seine-et-Marne,11,Île-de-France
77524,VIMPELLES,77520,VIMPELLES,,48.4430647512,3.16654310181,524,,Vimpelles,Vimpelles,77,Seine-et-Marne,11,Île-de-France
77525,VINANTES,77230,VINANTES,,49.0033668902,2.73993236891,525,,Vinantes,Vinantes,77,Seine-et-Marne,11,Île-de-France
77526,VINCY MANOEUVRE,77139,VINCY MANOEUVRE,,49.0760096587,2.95813750524,526,,Vincy-Manœuvre,Vincy-Manœuvre,77,Seine-et-Marne,11,Île-de-France
77527,VOINSLES,77540,VOINSLES,,48.6812171201,3.01742252986,527,,Voinsles,Voinsles,77,Seine-et-Marne,11,Île-de-France
77528,VOISENON,77950,VOISENON,,48.5701795365,2.66375881473,528,,Voisenon,Voisenon,77,Seine-et-Marne,11,Île-de-France
77529,VOULANGIS,77580,VOULANGIS,,48.8403121801,2.88971628574,529,,Voulangis,Voulangis,77,Seine-et-Marne,11,Île-de-France
77530,VOULTON,77560,VOULTON,,48.6116398027,3.3397616891,530,,Voulton,Voulton,77,Seine-et-Marne,11,Île-de-France
77531,VOULX,77940,VOULX,,48.2852061062,2.96998252338,531,,Voulx,Voulx,77,Seine-et-Marne,11,Île-de-France
77532,VULAINES LES PROVINS,77160,VULAINES LES PROVINS,,48.5636143707,3.21951808619,532,,Vulaines-lès-Provins,Vulaines-lès-Provins,77,Seine-et-Marne,11,Île-de-France
77533,VULAINES SUR SEINE,77870,VULAINES SUR SEINE,,48.4316360223,2.7693476397,533,,Vulaines-sur-Seine,Vulaines-sur-Seine,77,Seine-et-Marne,11,Île-de-France
77534,YEBLES,77390,YEBLES,,48.6376060545,2.7689913079,534,,Yèbles,Yèbles,77,Seine-et-Marne,11,Île-de-France
78003,ABLIS,78660,ABLIS,,48.5283257707,1.85096499421,3,,Ablis,Ablis,78,Yvelines,11,Île-de-France
78005,ACHERES,78260,ACHERES,,48.9717990892,2.09003871824,5,,Achères,Achères,78,Yvelines,11,Île-de-France
78006,ADAINVILLE,78113,ADAINVILLE,,48.7201535833,1.66232511762,6,,Adainville,Adainville,78,Yvelines,11,Île-de-France
78007,AIGREMONT,78240,AIGREMONT,,48.9008380509,2.0153630611,7,,Aigremont,Aigremont,78,Yvelines,11,Île-de-France
78009,ALLAINVILLE,78660,ALLAINVILLE AUX BOIS,,48.4678049422,1.90248656235,9,,Allainville,Allainville,78,Yvelines,11,Île-de-France
78010,LES ALLUETS LE ROI,78580,LES ALLUETS LE ROI,,48.9143168349,1.91476236453,10,Les,Alluets-le-Roi,Les Alluets-le-Roi,78,Yvelines,11,Île-de-France
78013,ANDELU,78770,ANDELU,,48.8882282833,1.81107773324,13,,Andelu,Andelu,78,Yvelines,11,Île-de-France
78015,ANDRESY,78570,ANDRESY,,48.9815454378,2.05025746939,15,,Andrésy,Andrésy,78,Yvelines,11,Île-de-France
78015,ANDRESY,78570,ANDRESY,DENOUVAL,48.9815454378,2.05025746939,15,,Andrésy,Andrésy,78,Yvelines,11,Île-de-France
78020,ARNOUVILLE LES MANTES,78790,ARNOUVILLE LES MANTES,,48.911006894,1.72043999321,20,,Arnouville-lès-Mantes,Arnouville-lès-Mantes,78,Yvelines,11,Île-de-France
78029,AUBERGENVILLE,78410,AUBERGENVILLE,,48.9626916321,1.84870251915,29,,Aubergenville,Aubergenville,78,Yvelines,11,Île-de-France
78029,AUBERGENVILLE,78410,AUBERGENVILLE,ELISABETHVILLE,48.9626916321,1.84870251915,29,,Aubergenville,Aubergenville,78,Yvelines,11,Île-de-France
78030,AUFFARGIS,78610,AUFFARGIS,,48.6842952632,1.90243823035,30,,Auffargis,Auffargis,78,Yvelines,11,Île-de-France
78031,AUFFREVILLE BRASSEUIL,78930,AUFFREVILLE BRASSEUIL,,48.954418567,1.70514751215,31,,Auffreville-Brasseuil,Auffreville-Brasseuil,78,Yvelines,11,Île-de-France
78033,AULNAY SUR MAULDRE,78126,AULNAY SUR MAULDRE,,48.9291229778,1.84081525646,33,,Aulnay-sur-Mauldre,Aulnay-sur-Mauldre,78,Yvelines,11,Île-de-France
78034,AUTEUIL,78770,AUTEUIL,,48.8394911873,1.81898836973,34,,Auteuil,Auteuil,78,Yvelines,11,Île-de-France
78036,AUTOUILLET,78770,AUTOUILLET,,48.8439307646,1.79700207496,36,,Autouillet,Autouillet,78,Yvelines,11,Île-de-France
78043,BAILLY,78870,BAILLY,,48.8366896896,2.08067809572,43,,Bailly,Bailly,78,Yvelines,11,Île-de-France
78048,BAZAINVILLE,78550,BAZAINVILLE,,48.8073241204,1.66749054563,48,,Bazainville,Bazainville,78,Yvelines,11,Île-de-France
78049,BAZEMONT,78580,BAZEMONT,,48.9318976597,1.87410045764,49,,Bazemont,Bazemont,78,Yvelines,11,Île-de-France
78050,BAZOCHES SUR GUYONNE,78490,BAZOCHES SUR GUYONNE,,48.7738475764,1.85242453244,50,,Bazoches-sur-Guyonne,Bazoches-sur-Guyonne,78,Yvelines,11,Île-de-France
78053,BEHOUST,78910,BEHOUST,,48.8324001775,1.72423748749,53,,Béhoust,Béhoust,78,Yvelines,11,Île-de-France
78057,BENNECOURT,78270,BENNECOURT,,49.0479974948,1.57232432462,57,,Bennecourt,Bennecourt,78,Yvelines,11,Île-de-France
78062,BEYNES,78650,BEYNES,,48.854444414,1.86989017513,62,,Beynes,Beynes,78,Yvelines,11,Île-de-France
78062,BEYNES,78650,BEYNES,LA MALADRERIE,48.854444414,1.86989017513,62,,Beynes,Beynes,78,Yvelines,11,Île-de-France
78062,BEYNES,78650,BEYNES,VAL DES QUATRE PIGNONS,48.854444414,1.86989017513,62,,Beynes,Beynes,78,Yvelines,11,Île-de-France
78068,BLARU,78270,BLARU,,49.0407915695,1.48063662849,68,,Blaru,Blaru,78,Yvelines,11,Île-de-France
78070,BOINVILLE EN MANTOIS,78930,BOINVILLE EN MANTOIS,,48.9284835934,1.74928034755,70,,Boinville-en-Mantois,Boinville-en-Mantois,78,Yvelines,11,Île-de-France
78071,BOINVILLE LE GAILLARD,78660,BOINVILLE LE GAILLARD,,48.4958030642,1.87115102424,71,,Boinville-le-Gaillard,Boinville-le-Gaillard,78,Yvelines,11,Île-de-France
78072,BOINVILLIERS,78200,BOINVILLIERS,,48.9204900199,1.66289347528,72,,Boinvilliers,Boinvilliers,78,Yvelines,11,Île-de-France
78073,BOIS D ARCY,78390,BOIS D ARCY,,48.8044185716,2.01911294303,73,,Bois-d'Arcy,Bois-d'Arcy,78,Yvelines,11,Île-de-France
78076,BOISSETS,78910,BOISSETS,,48.8587317942,1.58768309542,76,,Boissets,Boissets,78,Yvelines,11,Île-de-France
78077,LA BOISSIERE ECOLE,78125,LA BOISSIERE ECOLE,,48.6898883767,1.66775518226,77,La,Boissière-École,La Boissière-École,78,Yvelines,11,Île-de-France
78082,BOISSY MAUVOISIN,78200,BOISSY MAUVOISIN,,48.9658595506,1.58159815753,82,,Boissy-Mauvoisin,Boissy-Mauvoisin,78,Yvelines,11,Île-de-France
78084,BOISSY SANS AVOIR,78490,BOISSY SANS AVOIR,,48.8210401327,1.79753784845,84,,Boissy-sans-Avoir,Boissy-sans-Avoir,78,Yvelines,11,Île-de-France
78087,BONNELLES,78830,BONNELLES,,48.6181895067,2.0296290825,87,,Bonnelles,Bonnelles,78,Yvelines,11,Île-de-France
78089,BONNIERES SUR SEINE,78270,BONNIERES SUR SEINE,,49.0200145553,1.57058771832,89,,Bonnières-sur-Seine,Bonnières-sur-Seine,78,Yvelines,11,Île-de-France
78090,BOUAFLE,78410,BOUAFLE,,48.9605828143,1.8971510926,90,,Bouafle,Bouafle,78,Yvelines,11,Île-de-France
78092,BOUGIVAL,78380,BOUGIVAL,,48.8635986439,2.13760707765,92,,Bougival,Bougival,78,Yvelines,11,Île-de-France
78096,BOURDONNE,78113,BOURDONNE,,48.7543239729,1.65394025504,96,,Bourdonné,Bourdonné,78,Yvelines,11,Île-de-France
78104,BREUIL BOIS ROBERT,78930,BREUIL BOIS ROBERT,,48.9436009662,1.71471708447,104,,Breuil-Bois-Robert,Breuil-Bois-Robert,78,Yvelines,11,Île-de-France
78107,BREVAL,78980,BREVAL,,48.9549709174,1.54155385963,107,,Bréval,Bréval,78,Yvelines,11,Île-de-France
78108,LES BREVIAIRES,78610,LES BREVIAIRES,,48.7212410686,1.81639481084,108,Les,Bréviaires,Les Bréviaires,78,Yvelines,11,Île-de-France
78113,BRUEIL EN VEXIN,78440,BRUEIL EN VEXIN,,49.0264206637,1.81316986042,113,,Brueil-en-Vexin,Brueil-en-Vexin,78,Yvelines,11,Île-de-France
78117,BUC,78530,BUC,,48.7718338876,2.12394286717,117,,Buc,Buc,78,Yvelines,11,Île-de-France
78118,BUCHELAY,78200,BUCHELAY,,48.9837019552,1.67436909003,118,,Buchelay,Buchelay,78,Yvelines,11,Île-de-France
78120,BULLION,78830,BULLION,,48.6245810321,1.98307952001,120,,Bullion,Bullion,78,Yvelines,11,Île-de-France
78123,CARRIERES SOUS POISSY,78955,CARRIERES SOUS POISSY,,48.9455638989,2.02913706544,123,,Carrières-sous-Poissy,Carrières-sous-Poissy,78,Yvelines,11,Île-de-France
78123,CARRIERES SOUS POISSY,78955,CARRIERES SOUS POISSY,LES GRESILLONS,48.9455638989,2.02913706544,123,,Carrières-sous-Poissy,Carrières-sous-Poissy,78,Yvelines,11,Île-de-France
78124,CARRIERES SUR SEINE,78420,CARRIERES SUR SEINE,,48.9119335064,2.17838235323,124,,Carrières-sur-Seine,Carrières-sur-Seine,78,Yvelines,11,Île-de-France
78125,LA CELLE LES BORDES,78720,LA CELLE LES BORDES,,48.6416446846,1.94488436228,125,La,Celle-les-Bordes,La Celle-les-Bordes,78,Yvelines,11,Île-de-France
78126,LA CELLE ST CLOUD,78170,LA CELLE ST CLOUD,,48.8469487124,2.13519126228,126,La,Celle-Saint-Cloud,La Celle-Saint-Cloud,78,Yvelines,11,Île-de-France
78128,CERNAY LA VILLE,78720,CERNAY LA VILLE,,48.6696344922,1.95970981041,128,,Cernay-la-Ville,Cernay-la-Ville,78,Yvelines,11,Île-de-France
78133,CHAMBOURCY,78240,CHAMBOURCY,,48.8960592796,2.03321565205,133,,Chambourcy,Chambourcy,78,Yvelines,11,Île-de-France
78138,CHANTELOUP LES VIGNES,78570,CHANTELOUP LES VIGNES,,48.9759961298,2.03199829577,138,,Chanteloup-les-Vignes,Chanteloup-les-Vignes,78,Yvelines,11,Île-de-France
78140,CHAPET,78130,CHAPET,,48.9681465305,1.9401229913,140,,Chapet,Chapet,78,Yvelines,11,Île-de-France
78143,CHATEAUFORT,78117,CHATEAUFORT,,48.7390044414,2.09509831044,143,,Châteaufort,Châteaufort,78,Yvelines,11,Île-de-France
78146,CHATOU,78400,CHATOU,,48.8965574645,2.15393458329,146,,Chatou,Chatou,78,Yvelines,11,Île-de-France
78147,CHAUFOUR LES BONNIERES,78270,CHAUFOUR LES BONNIERES,,49.0158393703,1.48536401441,147,,Chaufour-lès-Bonnières,Chaufour-lès-Bonnières,78,Yvelines,11,Île-de-France
78152,CHAVENAY,78450,CHAVENAY,,48.8483573502,1.98288004519,152,,Chavenay,Chavenay,78,Yvelines,11,Île-de-France
78158,LE CHESNAY,78150,LE CHESNAY,,48.8272397742,2.13014908209,158,Le,Chesnay,Le Chesnay,78,Yvelines,11,Île-de-France
78158,LE CHESNAY,78150,LE CHESNAY,PARLY,48.8272397742,2.13014908209,158,Le,Chesnay,Le Chesnay,78,Yvelines,11,Île-de-France
78160,CHEVREUSE,78460,CHEVREUSE,,48.707150143,2.03714431459,160,,Chevreuse,Chevreuse,78,Yvelines,11,Île-de-France
78162,CHOISEL,78460,CHOISEL,,48.6739368242,2.01450641994,162,,Choisel,Choisel,78,Yvelines,11,Île-de-France
78163,CIVRY LA FORET,78910,CIVRY LA FORET,,48.8642006092,1.61682938801,163,,Civry-la-Forêt,Civry-la-Forêt,78,Yvelines,11,Île-de-France
78164,CLAIREFONTAINE EN YVELINES,78120,CLAIREFONTAINE EN YVELINES,,48.6167741818,1.90638035997,164,,Clairefontaine-en-Yvelines,Clairefontaine-en-Yvelines,78,Yvelines,11,Île-de-France
78165,LES CLAYES SOUS BOIS,78340,LES CLAYES SOUS BOIS,,48.8186406601,1.9840595554,165,Les,Clayes-sous-Bois,Les Clayes-sous-Bois,78,Yvelines,11,Île-de-France
78168,COIGNIERES,78310,COIGNIERES,,48.7497670046,1.91381870273,168,,Coignières,Coignières,78,Yvelines,11,Île-de-France
78171,CONDE SUR VESGRE,78113,CONDE SUR VESGRE,,48.7324954071,1.67372721841,171,,Condé-sur-Vesgre,Condé-sur-Vesgre,78,Yvelines,11,Île-de-France
78172,CONFLANS STE HONORINE,78700,CONFLANS STE HONORINE,,49.0001007823,2.0990719052,172,,Conflans-Sainte-Honorine,Conflans-Sainte-Honorine,78,Yvelines,11,Île-de-France
78185,COURGENT,78790,COURGENT,,48.8957168468,1.65941323514,185,,Courgent,Courgent,78,Yvelines,11,Île-de-France
78188,CRAVENT,78270,CRAVENT,,48.9895744703,1.48459959094,188,,Cravent,Cravent,78,Yvelines,11,Île-de-France
78189,CRESPIERES,78121,CRESPIERES,,48.8822021619,1.921122695,189,,Crespières,Crespières,78,Yvelines,11,Île-de-France
78190,CROISSY SUR SEINE,78290,CROISSY SUR SEINE,,48.8787825592,2.13541164589,190,,Croissy-sur-Seine,Croissy-sur-Seine,78,Yvelines,11,Île-de-France
78192,DAMMARTIN EN SERVE,78111,DAMMARTIN EN SERVE,,48.9114065992,1.61781290103,192,,Dammartin-en-Serve,Dammartin-en-Serve,78,Yvelines,11,Île-de-France
78193,DAMPIERRE EN YVELINES,78720,DAMPIERRE EN YVELINES,,48.7037164037,1.97307941128,193,,Dampierre-en-Yvelines,Dampierre-en-Yvelines,78,Yvelines,11,Île-de-France
78193,DAMPIERRE EN YVELINES,78720,DAMPIERRE EN YVELINES,MAINCOURT SUR YVETTE,48.7037164037,1.97307941128,193,,Dampierre-en-Yvelines,Dampierre-en-Yvelines,78,Yvelines,11,Île-de-France
78194,DANNEMARIE,78550,DANNEMARIE,,48.7639707435,1.60896965722,194,,Dannemarie,Dannemarie,78,Yvelines,11,Île-de-France
78196,DAVRON,78810,DAVRON,,48.8636419011,1.94749093682,196,,Davron,Davron,78,Yvelines,11,Île-de-France
78202,DROCOURT,78440,DROCOURT,,49.0519623345,1.76822079992,202,,Drocourt,Drocourt,78,Yvelines,11,Île-de-France
78206,ECQUEVILLY,78920,ECQUEVILLY,,48.9459356042,1.92004051912,206,,Ecquevilly,Ecquevilly,78,Yvelines,11,Île-de-France
78208,ELANCOURT,78990,ELANCOURT,,48.7781596518,1.96049898519,208,,Élancourt,Élancourt,78,Yvelines,11,Île-de-France
78209,EMANCE,78125,EMANCE,,48.5912443616,1.73390174623,209,,Émancé,Émancé,78,Yvelines,11,Île-de-France
78217,EPONE,78680,EPONE,,48.9471759683,1.81269584925,217,,Épône,Épône,78,Yvelines,11,Île-de-France
78220,LES ESSARTS LE ROI,78690,LES ESSARTS LE ROI,,48.716019265,1.8952517006,220,Les,Essarts-le-Roi,Les Essarts-le-Roi,78,Yvelines,11,Île-de-France
78220,LES ESSARTS LE ROI,78690,LES ESSARTS LE ROI,ST HUBERT LE ROI,48.716019265,1.8952517006,220,Les,Essarts-le-Roi,Les Essarts-le-Roi,78,Yvelines,11,Île-de-France
78224,L ETANG LA VILLE,78620,L ETANG LA VILLE,,48.8674116059,2.06048754387,224,L',Étang-la-Ville,L'Étang-la-Ville,78,Yvelines,11,Île-de-France
78227,EVECQUEMONT,78740,EVECQUEMONT,,49.0172176913,1.94219313244,227,,Évecquemont,Évecquemont,78,Yvelines,11,Île-de-France
78230,LA FALAISE,78410,LA FALAISE,,48.9369496827,1.82537874212,230,La,Falaise,La Falaise,78,Yvelines,11,Île-de-France
78231,FAVRIEUX,78200,FAVRIEUX,,48.9448446994,1.64166996008,231,,Favrieux,Favrieux,78,Yvelines,11,Île-de-France
78233,FEUCHEROLLES,78810,FEUCHEROLLES,,48.8800880696,1.97983777956,233,,Feucherolles,Feucherolles,78,Yvelines,11,Île-de-France
78234,FLACOURT,78200,FLACOURT,,48.9323338229,1.64260422917,234,,Flacourt,Flacourt,78,Yvelines,11,Île-de-France
78236,FLEXANVILLE,78910,FLEXANVILLE,,48.8551084372,1.74312032703,236,,Flexanville,Flexanville,78,Yvelines,11,Île-de-France
78237,FLINS NEUVE EGLISE,78790,FLINS NEUVE EGLISE,,48.8927473667,1.57663299283,237,,Flins-Neuve-Église,Flins-Neuve-Église,78,Yvelines,11,Île-de-France
78238,FLINS SUR SEINE,78410,FLINS SUR SEINE,,48.9673692016,1.87272187331,238,,Flins-sur-Seine,Flins-sur-Seine,78,Yvelines,11,Île-de-France
78239,FOLLAINVILLE DENNEMONT,78520,FOLLAINVILLE DENNEMONT,,49.0229954846,1.70861078904,239,,Follainville-Dennemont,Follainville-Dennemont,78,Yvelines,11,Île-de-France
78242,FONTENAY LE FLEURY,78330,FONTENAY LE FLEURY,,48.8177984988,2.04662335413,242,,Fontenay-le-Fleury,Fontenay-le-Fleury,78,Yvelines,11,Île-de-France
78245,FONTENAY MAUVOISIN,78200,FONTENAY MAUVOISIN,,48.9610924482,1.64777778517,245,,Fontenay-Mauvoisin,Fontenay-Mauvoisin,78,Yvelines,11,Île-de-France
78246,FONTENAY ST PERE,78440,FONTENAY ST PERE,,49.0279919911,1.75448471704,246,,Fontenay-Saint-Père,Fontenay-Saint-Père,78,Yvelines,11,Île-de-France
78251,FOURQUEUX,78112,FOURQUEUX,,48.8853336012,2.05562938511,251,,Fourqueux,Fourqueux,78,Yvelines,11,Île-de-France
78255,FRENEUSE,78840,FRENEUSE,,49.0495054013,1.61035317023,255,,Freneuse,Freneuse,78,Yvelines,11,Île-de-France
78261,GAILLON SUR MONTCIENT,78250,GAILLON SUR MONTCIENT,,49.0298577394,1.89797446964,261,,Gaillon-sur-Montcient,Gaillon-sur-Montcient,78,Yvelines,11,Île-de-France
78262,GALLUIS,78490,GALLUIS,,48.7975623096,1.79110153574,262,,Galluis,Galluis,78,Yvelines,11,Île-de-France
78263,GAMBAIS,78950,GAMBAIS,,48.7796089951,1.67651742335,263,,Gambais,Gambais,78,Yvelines,11,Île-de-France
78264,GAMBAISEUIL,78490,GAMBAISEUIL,,48.7565484282,1.72527322588,264,,Gambaiseuil,Gambaiseuil,78,Yvelines,11,Île-de-France
78265,GARANCIERES,78890,GARANCIERES,,48.8236041214,1.75831799114,265,,Garancières,Garancières,78,Yvelines,11,Île-de-France
78267,GARGENVILLE,78440,GARGENVILLE,,48.9931450718,1.81069376214,267,,Gargenville,Gargenville,78,Yvelines,11,Île-de-France
78269,GAZERAN,78125,GAZERAN,,48.6334275463,1.77758974594,269,,Gazeran,Gazeran,78,Yvelines,11,Île-de-France
78276,GOMMECOURT,78270,GOMMECOURT,,49.0711557318,1.5943070354,276,,Gommecourt,Gommecourt,78,Yvelines,11,Île-de-France
78278,GOUPILLIERES,78770,GOUPILLIERES,,48.8811163086,1.76679868988,278,,Goupillières,Goupillières,78,Yvelines,11,Île-de-France
78281,GOUSSONVILLE,78930,GOUSSONVILLE,,48.9235015144,1.76677229339,281,,Goussonville,Goussonville,78,Yvelines,11,Île-de-France
78283,GRANDCHAMP,78113,GRANDCHAMP,,48.7197572172,1.61429077254,283,,Grandchamp,Grandchamp,78,Yvelines,11,Île-de-France
78285,GRESSEY,78550,GRESSEY,,48.8328470856,1.60339390976,285,,Gressey,Gressey,78,Yvelines,11,Île-de-France
78289,GROSROUVRE,78490,GROSROUVRE,,48.7803262238,1.75722546892,289,,Grosrouvre,Grosrouvre,78,Yvelines,11,Île-de-France
78290,GUERNES,78520,GUERNES,,49.0153671856,1.64238528866,290,,Guernes,Guernes,78,Yvelines,11,Île-de-France
78291,GUERVILLE,78930,GUERVILLE,,48.9523717718,1.74080558769,291,,Guerville,Guerville,78,Yvelines,11,Île-de-France
78296,GUITRANCOURT,78440,GUITRANCOURT,,49.0075668772,1.77906463847,296,,Guitrancourt,Guitrancourt,78,Yvelines,11,Île-de-France
78297,GUYANCOURT,78280,GUYANCOURT,,48.7730783892,2.07605207737,297,,Guyancourt,Guyancourt,78,Yvelines,11,Île-de-France
78297,GUYANCOURT,78280,GUYANCOURT,BOUVIERS,48.7730783892,2.07605207737,297,,Guyancourt,Guyancourt,78,Yvelines,11,Île-de-France
78297,GUYANCOURT,78280,GUYANCOURT,VILLAROY,48.7730783892,2.07605207737,297,,Guyancourt,Guyancourt,78,Yvelines,11,Île-de-France
78299,HARDRICOURT,78250,HARDRICOURT,,49.0109340921,1.88729984064,299,,Hardricourt,Hardricourt,78,Yvelines,11,Île-de-France
78300,HARGEVILLE,78790,HARGEVILLE,,48.8952160962,1.74614665372,300,,Hargeville,Hargeville,78,Yvelines,11,Île-de-France
78302,LA HAUTEVILLE,78113,LA HAUTEVILLE,,48.7048932149,1.62739461782,302,La,Hauteville,La Hauteville,78,Yvelines,11,Île-de-France
78305,HERBEVILLE,78580,HERBEVILLE,,48.9026044293,1.89020414118,305,,Herbeville,Herbeville,78,Yvelines,11,Île-de-France
78307,HERMERAY,78125,HERMERAY,,48.653842919,1.68849471188,307,,Hermeray,Hermeray,78,Yvelines,11,Île-de-France
78310,HOUDAN,78550,HOUDAN,,48.7988973157,1.59677099646,310,,Houdan,Houdan,78,Yvelines,11,Île-de-France
78311,HOUILLES,78800,HOUILLES,,48.9265781359,2.1874640255,311,,Houilles,Houilles,78,Yvelines,11,Île-de-France
78314,ISSOU,78440,ISSOU,,48.9869955946,1.7891449839,314,,Issou,Issou,78,Yvelines,11,Île-de-France
78317,JAMBVILLE,78440,JAMBVILLE,,49.0484754419,1.84836541189,317,,Jambville,Jambville,78,Yvelines,11,Île-de-France
78320,JEUFOSSE,78270,JEUFOSSE,,49.0353541387,1.53724970145,320,,Jeufosse,Jeufosse,78,Yvelines,11,Île-de-France
78321,JOUARS PONTCHARTRAIN,78760,JOUARS PONTCHARTRAIN,,48.7904544648,1.90784227893,321,,Jouars-Pontchartrain,Jouars-Pontchartrain,78,Yvelines,11,Île-de-France
78322,JOUY EN JOSAS,78350,JOUY EN JOSAS,,48.7661635434,2.16266396041,322,,Jouy-en-Josas,Jouy-en-Josas,78,Yvelines,11,Île-de-France
78324,JOUY MAUVOISIN,78200,JOUY MAUVOISIN,,48.9744681009,1.64659377662,324,,Jouy-Mauvoisin,Jouy-Mauvoisin,78,Yvelines,11,Île-de-France
78325,JUMEAUVILLE,78580,JUMEAUVILLE,,48.9066083606,1.78398775719,325,,Jumeauville,Jumeauville,78,Yvelines,11,Île-de-France
78327,JUZIERS,78820,JUZIERS,,48.9999173467,1.84036602544,327,,Juziers,Juziers,78,Yvelines,11,Île-de-France
78329,LAINVILLE EN VEXIN,78440,LAINVILLE EN VEXIN,,49.0624169047,1.81689951894,329,,Lainville-en-Vexin,Lainville-en-Vexin,78,Yvelines,11,Île-de-France
78334,LEVIS ST NOM,78320,LEVIS ST NOM,,48.7266074685,1.93544246617,334,,Lévis-Saint-Nom,Lévis-Saint-Nom,78,Yvelines,11,Île-de-France
78335,LIMAY,78520,LIMAY,,48.9946438183,1.7398704848,335,,Limay,Limay,78,Yvelines,11,Île-de-France
78337,LIMETZ VILLEZ,78270,LIMETZ VILLEZ,,49.0598121683,1.55067599473,337,,Limetz-Villez,Limetz-Villez,78,Yvelines,11,Île-de-France
78343,LES LOGES EN JOSAS,78350,LES LOGES EN JOSAS,,48.7624385138,2.13911723694,343,Les,Loges-en-Josas,Les Loges-en-Josas,78,Yvelines,11,Île-de-France
78344,LOMMOYE,78270,LOMMOYE,,48.9973952578,1.51644406225,344,,Lommoye,Lommoye,78,Yvelines,11,Île-de-France
78346,LONGNES,78980,LONGNES,,48.9228573814,1.57947128882,346,,Longnes,Longnes,78,Yvelines,11,Île-de-France
78349,LONGVILLIERS,78730,LONGVILLIERS,,48.5741940135,1.99920343837,349,,Longvilliers,Longvilliers,78,Yvelines,11,Île-de-France
78350,LOUVECIENNES,78430,LOUVECIENNES,,48.8590188206,2.11364039022,350,,Louveciennes,Louveciennes,78,Yvelines,11,Île-de-France
78354,MAGNANVILLE,78200,MAGNANVILLE,,48.9681925896,1.68567158514,354,,Magnanville,Magnanville,78,Yvelines,11,Île-de-France
78356,MAGNY LES HAMEAUX,78114,MAGNY LES HAMEAUX,,48.7410207273,2.0519793397,356,,Magny-les-Hameaux,Magny-les-Hameaux,78,Yvelines,11,Île-de-France
78356,MAGNY LES HAMEAUX,78114,MAGNY LES HAMEAUX,CRESSELY,48.7410207273,2.0519793397,356,,Magny-les-Hameaux,Magny-les-Hameaux,78,Yvelines,11,Île-de-France
78358,MAISONS LAFFITTE,78600,MAISONS LAFFITTE,,48.9523369002,2.15161594901,358,,Maisons-Laffitte,Maisons-Laffitte,78,Yvelines,11,Île-de-France
78361,MANTES LA JOLIE,78200,MANTES LA JOLIE,,48.9981665392,1.69337806821,361,,Mantes-la-Jolie,Mantes-la-Jolie,78,Yvelines,11,Île-de-France
78362,MANTES LA VILLE,78711,MANTES LA VILLE,,48.9747939435,1.71205571778,362,,Mantes-la-Ville,Mantes-la-Ville,78,Yvelines,11,Île-de-France
78364,MARCQ,78770,MARCQ,,48.8642310591,1.82382085659,364,,Marcq,Marcq,78,Yvelines,11,Île-de-France
78366,MAREIL LE GUYON,78490,MAREIL LE GUYON,,48.7910883719,1.85148876174,366,,Mareil-le-Guyon,Mareil-le-Guyon,78,Yvelines,11,Île-de-France
78367,MAREIL MARLY,78750,MAREIL MARLY,,48.8807836526,2.07668355901,367,,Mareil-Marly,Mareil-Marly,78,Yvelines,11,Île-de-France
78368,MAREIL SUR MAULDRE,78124,MAREIL SUR MAULDRE,,48.8886067884,1.87528129432,368,,Mareil-sur-Mauldre,Mareil-sur-Mauldre,78,Yvelines,11,Île-de-France
78372,MARLY LE ROI,78160,MARLY LE ROI,,48.865007453,2.09125004298,372,,Marly-le-Roi,Marly-le-Roi,78,Yvelines,11,Île-de-France
78372,MARLY LE ROI,78160,MARLY LE ROI,MONTVAL,48.865007453,2.09125004298,372,,Marly-le-Roi,Marly-le-Roi,78,Yvelines,11,Île-de-France
78380,MAULE,78580,MAULE,,48.9078548612,1.83959534526,380,,Maule,Maule,78,Yvelines,11,Île-de-France
78381,MAULETTE,78550,MAULETTE,,48.7839119459,1.61644019132,381,,Maulette,Maulette,78,Yvelines,11,Île-de-France
78381,MAULETTE,78550,MAULETTE,THIONVILLE SUR OPTON,48.7839119459,1.61644019132,381,,Maulette,Maulette,78,Yvelines,11,Île-de-France
78382,MAURECOURT,78780,MAURECOURT,,48.998753885,2.05297771853,382,,Maurecourt,Maurecourt,78,Yvelines,11,Île-de-France
78383,MAUREPAS,78310,MAUREPAS,,48.7698393561,1.92404037065,383,,Maurepas,Maurepas,78,Yvelines,11,Île-de-France
78384,MEDAN,78670,MEDAN,,48.9548235398,1.98757804173,384,,Médan,Médan,78,Yvelines,11,Île-de-France
78385,MENERVILLE,78200,MENERVILLE,,48.9566835717,1.59610818145,385,,Ménerville,Ménerville,78,Yvelines,11,Île-de-France
78389,MERE,78490,MERE,,48.7955470885,1.81994051826,389,,Méré,Méré,78,Yvelines,11,Île-de-France
78391,MERICOURT,78270,MERICOURT,,49.0370419554,1.62393586388,391,,Méricourt,Méricourt,78,Yvelines,11,Île-de-France
78396,LE MESNIL LE ROI,78600,LE MESNIL LE ROI,,48.9257999518,2.1221732386,396,Le,Mesnil-le-Roi,Le Mesnil-le-Roi,78,Yvelines,11,Île-de-France
78396,LE MESNIL LE ROI,78600,LE MESNIL LE ROI,CARRIERES SOUS BOIS,48.9257999518,2.1221732386,396,Le,Mesnil-le-Roi,Le Mesnil-le-Roi,78,Yvelines,11,Île-de-France
78397,LE MESNIL ST DENIS,78320,LE MESNIL ST DENIS,,48.7402250614,1.96613835861,397,Le,Mesnil-Saint-Denis,Le Mesnil-Saint-Denis,78,Yvelines,11,Île-de-France
78398,LES MESNULS,78490,LES MESNULS,,48.754542649,1.83436184333,398,Les,Mesnuls,Les Mesnuls,78,Yvelines,11,Île-de-France
78401,MEULAN EN YVELINES,78250,MEULAN EN YVELINES,,49.0064171984,1.91317556338,401,,Meulan-en-Yvelines,Meulan-en-Yvelines,78,Yvelines,11,Île-de-France
78402,MEZIERES SUR SEINE,78970,MEZIERES SUR SEINE,,48.953428636,1.78287413415,402,,Mézières-sur-Seine,Mézières-sur-Seine,78,Yvelines,11,Île-de-France
78403,MEZY SUR SEINE,78250,MEZY SUR SEINE,,49.0032908553,1.86919580679,403,,Mézy-sur-Seine,Mézy-sur-Seine,78,Yvelines,11,Île-de-France
78404,MILLEMONT,78940,MILLEMONT,,48.8080583549,1.72531897998,404,,Millemont,Millemont,78,Yvelines,11,Île-de-France
78406,MILON LA CHAPELLE,78470,MILON LA CHAPELLE,,48.7284642837,2.04661853944,406,,Milon-la-Chapelle,Milon-la-Chapelle,78,Yvelines,11,Île-de-France
78407,MITTAINVILLE,78125,MITTAINVILLE,,48.6632271873,1.63564999259,407,,Mittainville,Mittainville,78,Yvelines,11,Île-de-France
78410,MOISSON,78840,MOISSON,,49.0682573401,1.66415011183,410,,Moisson,Moisson,78,Yvelines,11,Île-de-France
78413,MONDREVILLE,78980,MONDREVILLE,,48.9042583015,1.55787016876,413,,Mondreville,Mondreville,78,Yvelines,11,Île-de-France
78415,MONTAINVILLE,78124,MONTAINVILLE,,48.8802024049,1.85246452448,415,,Montainville,Montainville,78,Yvelines,11,Île-de-France
78416,MONTALET LE BOIS,78440,MONTALET LE BOIS,,49.0489897377,1.82996135013,416,,Montalet-le-Bois,Montalet-le-Bois,78,Yvelines,11,Île-de-France
78417,MONTCHAUVET,78790,MONTCHAUVET,,48.8916704709,1.62681497392,417,,Montchauvet,Montchauvet,78,Yvelines,11,Île-de-France
78418,MONTESSON,78360,MONTESSON,,48.9172263937,2.1399267913,418,,Montesson,Montesson,78,Yvelines,11,Île-de-France
78420,MONTFORT L AMAURY,78490,MONTFORT L AMAURY,,48.771347152,1.80894544858,420,,Montfort-l'Amaury,Montfort-l'Amaury,78,Yvelines,11,Île-de-France
78423,MONTIGNY LE BRETONNEUX,78180,MONTIGNY LE BRETONNEUX,,48.779658781,2.02952194549,423,,Montigny-le-Bretonneux,Montigny-le-Bretonneux,78,Yvelines,11,Île-de-France
78431,MORAINVILLIERS,78630,MORAINVILLIERS,,48.9343458176,1.94550312886,431,,Morainvilliers,Morainvilliers,78,Yvelines,11,Île-de-France
78437,MOUSSEAUX SUR SEINE,78270,MOUSSEAUX SUR SEINE,,49.0512755444,1.65045587517,437,,Mousseaux-sur-Seine,Mousseaux-sur-Seine,78,Yvelines,11,Île-de-France
78439,MULCENT,78790,MULCENT,,48.8784123632,1.65209205778,439,,Mulcent,Mulcent,78,Yvelines,11,Île-de-France
78440,LES MUREAUX,78130,LES MUREAUX,,48.9888041078,1.91363448925,440,Les,Mureaux,Les Mureaux,78,Yvelines,11,Île-de-France
78442,NEAUPHLE LE CHATEAU,78640,NEAUPHLE LE CHATEAU,,48.8130997421,1.90470412718,442,,Neauphle-le-Château,Neauphle-le-Château,78,Yvelines,11,Île-de-France
78443,NEAUPHLE LE VIEUX,78640,NEAUPHLE LE VIEUX,,48.8151801039,1.85898101008,443,,Neauphle-le-Vieux,Neauphle-le-Vieux,78,Yvelines,11,Île-de-France
78444,NEAUPHLETTE,78980,NEAUPHLETTE,,48.935347635,1.54227113276,444,,Neauphlette,Neauphlette,78,Yvelines,11,Île-de-France
78451,NEZEL,78410,NEZEL,,48.9422397324,1.83927892943,451,,Nézel,Nézel,78,Yvelines,11,Île-de-France
78455,NOISY LE ROI,78590,NOISY LE ROI,,48.8465154476,2.05479110499,455,,Noisy-le-Roi,Noisy-le-Roi,78,Yvelines,11,Île-de-France
78460,OINVILLE SUR MONTCIENT,78250,OINVILLE SUR MONTCIENT,,49.0255099055,1.84612045415,460,,Oinville-sur-Montcient,Oinville-sur-Montcient,78,Yvelines,11,Île-de-France
78464,ORCEMONT,78125,ORCEMONT,,48.5968578246,1.80955275599,464,,Orcemont,Orcemont,78,Yvelines,11,Île-de-France
78465,ORGERUS,78910,ORGERUS,,48.8379711706,1.69620236301,465,,Orgerus,Orgerus,78,Yvelines,11,Île-de-France
78466,ORGEVAL,78630,ORGEVAL,,48.9164887331,1.96928773573,466,,Orgeval,Orgeval,78,Yvelines,11,Île-de-France
78470,ORPHIN,78125,ORPHIN,,48.5760592256,1.78299740529,470,,Orphin,Orphin,78,Yvelines,11,Île-de-France
78472,ORSONVILLE,78660,ORSONVILLE,,48.4779086815,1.82683665622,472,,Orsonville,Orsonville,78,Yvelines,11,Île-de-France
78474,ORVILLIERS,78910,ORVILLIERS,,48.8548104012,1.64252100195,474,,Orvilliers,Orvilliers,78,Yvelines,11,Île-de-France
78475,OSMOY,78910,OSMOY,,48.8640021137,1.71710528225,475,,Osmoy,Osmoy,78,Yvelines,11,Île-de-France
78478,PARAY DOUAVILLE,78660,PARAY DOUAVILLE,,48.4599663329,1.86291670006,478,,Paray-Douaville,Paray-Douaville,78,Yvelines,11,Île-de-France
78481,LE PECQ,78230,LE PECQ,,48.8936170481,2.10503635898,481,Le,Pecq,Le Pecq,78,Yvelines,11,Île-de-France
78484,PERDREAUVILLE,78200,PERDREAUVILLE,,48.9704575821,1.61325162938,484,,Perdreauville,Perdreauville,78,Yvelines,11,Île-de-France
78486,LE PERRAY EN YVELINES,78610,LE PERRAY EN YVELINES,,48.6968018136,1.84356131142,486,Le,Perray-en-Yvelines,Le Perray-en-Yvelines,78,Yvelines,11,Île-de-France
78490,PLAISIR,78370,PLAISIR,,48.8126082296,1.94698515715,490,,Plaisir,Plaisir,78,Yvelines,11,Île-de-France
78497,POIGNY LA FORET,78125,POIGNY LA FORET,,48.6829262881,1.75440035013,497,,Poigny-la-Forêt,Poigny-la-Forêt,78,Yvelines,11,Île-de-France
78498,POISSY,78300,POISSY,,48.9235359371,2.02715552772,498,,Poissy,Poissy,78,Yvelines,11,Île-de-France
78498,POISSY,78300,POISSY,LA MALADRERIE,48.9235359371,2.02715552772,498,,Poissy,Poissy,78,Yvelines,11,Île-de-France
78499,PONTHEVRARD,78730,PONTHEVRARD,,48.5499350037,1.90806414752,499,,Ponthévrard,Ponthévrard,78,Yvelines,11,Île-de-France
78501,PORCHEVILLE,78440,PORCHEVILLE,,48.9761864885,1.76831015347,501,,Porcheville,Porcheville,78,Yvelines,11,Île-de-France
78502,LE PORT MARLY,78560,LE PORT MARLY,,48.8804759755,2.10903947427,502,Le,Port-Marly,Le Port-Marly,78,Yvelines,11,Île-de-France
78503,PORT VILLEZ,78270,PORT VILLEZ,,49.0579600659,1.51633686428,503,,Port-Villez,Port-Villez,78,Yvelines,11,Île-de-France
78505,PRUNAY LE TEMPLE,78910,PRUNAY LE TEMPLE,,48.8592898143,1.67130114666,505,,Prunay-le-Temple,Prunay-le-Temple,78,Yvelines,11,Île-de-France
78506,PRUNAY EN YVELINES,78660,PRUNAY EN YVELINES,,48.5267627187,1.80513972814,506,,Prunay-en-Yvelines,Prunay-en-Yvelines,78,Yvelines,11,Île-de-France
78506,PRUNAY EN YVELINES,78660,PRUNAY EN YVELINES,CRACHES,48.5267627187,1.80513972814,506,,Prunay-en-Yvelines,Prunay-en-Yvelines,78,Yvelines,11,Île-de-France
78513,LA QUEUE LES YVELINES,78940,LA QUEUE LES YVELINES,,48.8027893482,1.76744000641,513,La,Queue-les-Yvelines,La Queue-les-Yvelines,78,Yvelines,11,Île-de-France
78516,RAIZEUX,78125,RAIZEUX,,48.6356143673,1.68906925205,516,,Raizeux,Raizeux,78,Yvelines,11,Île-de-France
78517,RAMBOUILLET,78120,RAMBOUILLET,,48.6539051954,1.82652871851,517,,Rambouillet,Rambouillet,78,Yvelines,11,Île-de-France
78518,RENNEMOULIN,78590,RENNEMOULIN,,48.8332483875,2.04229462765,518,,Rennemoulin,Rennemoulin,78,Yvelines,11,Île-de-France
78520,RICHEBOURG,78550,RICHEBOURG,,48.8248023552,1.63460154303,520,,Richebourg,Richebourg,78,Yvelines,11,Île-de-France
78522,ROCHEFORT EN YVELINES,78730,ROCHEFORT EN YVELINES,,48.5924280148,1.97977823093,522,,Rochefort-en-Yvelines,Rochefort-en-Yvelines,78,Yvelines,11,Île-de-France
78524,ROCQUENCOURT,78150,ROCQUENCOURT,,48.8327186556,2.10442227131,524,,Rocquencourt,Rocquencourt,78,Yvelines,11,Île-de-France
78528,ROLLEBOISE,78270,ROLLEBOISE,,49.0191886627,1.60183464658,528,,Rolleboise,Rolleboise,78,Yvelines,11,Île-de-France
78530,ROSAY,78790,ROSAY,,48.9113105941,1.68395914645,530,,Rosay,Rosay,78,Yvelines,11,Île-de-France
78531,ROSNY SUR SEINE,78710,ROSNY SUR SEINE,,48.9964226633,1.60820077392,531,,Rosny-sur-Seine,Rosny-sur-Seine,78,Yvelines,11,Île-de-France
78536,SAILLY,78440,SAILLY,,49.0430008047,1.79368235837,536,,Sailly,Sailly,78,Yvelines,11,Île-de-France
78537,ST ARNOULT EN YVELINES,78730,ST ARNOULT EN YVELINES,,48.5725220079,1.93463847942,537,,Saint-Arnoult-en-Yvelines,Saint-Arnoult-en-Yvelines,78,Yvelines,11,Île-de-France
78545,ST CYR L ECOLE,78210,ST CYR L ECOLE,,48.8065759294,2.0659250703,545,,Saint-Cyr-l'École,Saint-Cyr-l'École,78,Yvelines,11,Île-de-France
78548,ST FORGET,78720,ST FORGET,,48.7127668824,1.99703639595,548,,Saint-Forget,Saint-Forget,78,Yvelines,11,Île-de-France
78550,ST GERMAIN DE LA GRANGE,78640,ST GERMAIN DE LA GRANGE,,48.8330731847,1.90235458205,550,,Saint-Germain-de-la-Grange,Saint-Germain-de-la-Grange,78,Yvelines,11,Île-de-France
78551,ST GERMAIN EN LAYE,78100,ST GERMAIN EN LAYE,,48.9407041394,2.09870929375,551,,Saint-Germain-en-Laye,Saint-Germain-en-Laye,78,Yvelines,11,Île-de-France
78557,ST HILARION,78125,ST HILARION,,48.6224983516,1.72861527853,557,,Saint-Hilarion,Saint-Hilarion,78,Yvelines,11,Île-de-France
78558,ST ILLIERS LA VILLE,78980,ST ILLIERS LA VILLE,,48.9776264452,1.5442103419,558,,Saint-Illiers-la-Ville,Saint-Illiers-la-Ville,78,Yvelines,11,Île-de-France
78559,ST ILLIERS LE BOIS,78980,ST ILLIERS LE BOIS,,48.9650939758,1.5118698164,559,,Saint-Illiers-le-Bois,Saint-Illiers-le-Bois,78,Yvelines,11,Île-de-France
78561,ST LAMBERT,78470,ST LAMBERT DES BOIS,,48.7339427592,2.00622958047,561,,Saint-Lambert,Saint-Lambert,78,Yvelines,11,Île-de-France
78562,ST LEGER EN YVELINES,78610,ST LEGER EN YVELINES,,48.7300275731,1.75992099738,562,,Saint-Léger-en-Yvelines,Saint-Léger-en-Yvelines,78,Yvelines,11,Île-de-France
78564,ST MARTIN DE BRETHENCOURT,78660,ST MARTIN DE BRETHENCOURT,,48.5148592192,1.91568171795,564,,Saint-Martin-de-Bréthencourt,Saint-Martin-de-Bréthencourt,78,Yvelines,11,Île-de-France
78565,ST MARTIN DES CHAMPS,78790,ST MARTIN DES CHAMPS,,48.8778929255,1.7191676109,565,,Saint-Martin-des-Champs,Saint-Martin-des-Champs,78,Yvelines,11,Île-de-France
78567,ST MARTIN LA GARENNE,78520,ST MARTIN LA GARENNE,,49.0359613307,1.6773072296,567,,Saint-Martin-la-Garenne,Saint-Martin-la-Garenne,78,Yvelines,11,Île-de-France
78569,STE MESME,78730,STE MESME,,48.5356862351,1.94057719252,569,,Sainte-Mesme,Sainte-Mesme,78,Yvelines,11,Île-de-France
78571,ST NOM LA BRETECHE,78860,ST NOM LA BRETECHE,,48.8634050098,2.01915428049,571,,Saint-Nom-la-Bretèche,Saint-Nom-la-Bretèche,78,Yvelines,11,Île-de-France
78571,ST NOM LA BRETECHE,78860,ST NOM LA BRETECHE,LA BRETECHE,48.8634050098,2.01915428049,571,,Saint-Nom-la-Bretèche,Saint-Nom-la-Bretèche,78,Yvelines,11,Île-de-France
78575,ST REMY LES CHEVREUSE,78470,ST REMY LES CHEVREUSE,,48.703873758,2.07898924956,575,,Saint-Rémy-lès-Chevreuse,Saint-Rémy-lès-Chevreuse,78,Yvelines,11,Île-de-France
78575,ST REMY LES CHEVREUSE,78470,ST REMY LES CHEVREUSE,RHODON,48.703873758,2.07898924956,575,,Saint-Rémy-lès-Chevreuse,Saint-Rémy-lès-Chevreuse,78,Yvelines,11,Île-de-France
78576,ST REMY L HONORE,78690,ST REMY L HONORE,,48.7487507773,1.87573805831,576,,Saint-Rémy-l'Honoré,Saint-Rémy-l'Honoré,78,Yvelines,11,Île-de-France
78586,SARTROUVILLE,78500,SARTROUVILLE,,48.9396085498,2.17458686513,586,,Sartrouville,Sartrouville,78,Yvelines,11,Île-de-France
78588,SAULX MARCHAIS,78650,SAULX MARCHAIS,,48.8461753929,1.83624882514,588,,Saulx-Marchais,Saulx-Marchais,78,Yvelines,11,Île-de-France
78590,SENLISSE,78720,SENLISSE,,48.6854188568,1.97174310378,590,,Senlisse,Senlisse,78,Yvelines,11,Île-de-France
78591,SEPTEUIL,78790,SEPTEUIL,,48.8882003986,1.68459218607,591,,Septeuil,Septeuil,78,Yvelines,11,Île-de-France
78597,SOINDRES,78200,SOINDRES,,48.9535424225,1.67168350661,597,,Soindres,Soindres,78,Yvelines,11,Île-de-France
78601,SONCHAMP,78120,SONCHAMP,,48.5880930813,1.86888636176,601,,Sonchamp,Sonchamp,78,Yvelines,11,Île-de-France
78605,TACOIGNIERES,78910,TACOIGNIERES,,48.8331353956,1.66523067684,605,,Tacoignières,Tacoignières,78,Yvelines,11,Île-de-France
78606,LE TARTRE GAUDRAN,78113,LE TARTRE GAUDRAN,,48.6971387233,1.60366113051,606,Le,Tartre-Gaudran,Le Tartre-Gaudran,78,Yvelines,11,Île-de-France
78608,LE TERTRE ST DENIS,78980,LE TERTRE ST DENIS,,48.9425366688,1.60840679116,608,Le,Tertre-Saint-Denis,Le Tertre-Saint-Denis,78,Yvelines,11,Île-de-France
78609,TESSANCOURT SUR AUBETTE,78250,TESSANCOURT SUR AUBETTE,,49.0250430993,1.91835280056,609,,Tessancourt-sur-Aubette,Tessancourt-sur-Aubette,78,Yvelines,11,Île-de-France
78615,THIVERVAL GRIGNON,78850,THIVERVAL GRIGNON,,48.8442697487,1.93387158899,615,,Thiverval-Grignon,Thiverval-Grignon,78,Yvelines,11,Île-de-France
78616,THOIRY,78770,THOIRY,,48.8724624004,1.79780062062,616,,Thoiry,Thoiry,78,Yvelines,11,Île-de-France
78618,TILLY,78790,TILLY,,48.8779765086,1.57500969279,618,,Tilly,Tilly,78,Yvelines,11,Île-de-France
78620,TOUSSUS LE NOBLE,78117,TOUSSUS LE NOBLE,,48.7466272252,2.1179056339,620,,Toussus-le-Noble,Toussus-le-Noble,78,Yvelines,11,Île-de-France
78621,TRAPPES,78190,TRAPPES,,48.7750019561,1.99344014312,621,,Trappes,Trappes,78,Yvelines,11,Île-de-France
78623,LE TREMBLAY SUR MAULDRE,78490,LE TREMBLAY SUR MAULDRE,,48.7834999157,1.878022372,623,Le,Tremblay-sur-Mauldre,Le Tremblay-sur-Mauldre,78,Yvelines,11,Île-de-France
78624,TRIEL SUR SEINE,78510,TRIEL SUR SEINE,,48.9780282513,2.00877051663,624,,Triel-sur-Seine,Triel-sur-Seine,78,Yvelines,11,Île-de-France
78624,TRIEL SUR SEINE,78510,TRIEL SUR SEINE,CHEVERCHEMONT,48.9780282513,2.00877051663,624,,Triel-sur-Seine,Triel-sur-Seine,78,Yvelines,11,Île-de-France
78624,TRIEL SUR SEINE,78510,TRIEL SUR SEINE,L HAUTIL,48.9780282513,2.00877051663,624,,Triel-sur-Seine,Triel-sur-Seine,78,Yvelines,11,Île-de-France
78638,VAUX SUR SEINE,78740,VAUX SUR SEINE,,49.0095815158,1.97449626486,638,,Vaux-sur-Seine,Vaux-sur-Seine,78,Yvelines,11,Île-de-France
78640,VELIZY VILLACOUBLAY,78140,VELIZY VILLACOUBLAY,,48.783985823,2.19707485523,640,,Vélizy-Villacoublay,Vélizy-Villacoublay,78,Yvelines,11,Île-de-France
78642,VERNEUIL SUR SEINE,78480,VERNEUIL SUR SEINE,,48.9872093367,1.96276532174,642,,Verneuil-sur-Seine,Verneuil-sur-Seine,78,Yvelines,11,Île-de-France
78643,VERNOUILLET,78540,VERNOUILLET,,48.9655697167,1.97530302109,643,,Vernouillet,Vernouillet,78,Yvelines,11,Île-de-France
78643,VERNOUILLET,78540,VERNOUILLET,MARSINVAL,48.9655697167,1.97530302109,643,,Vernouillet,Vernouillet,78,Yvelines,11,Île-de-France
78644,LA VERRIERE,78320,LA VERRIERE,,48.7545862133,1.95133109322,644,La,Verrière,La Verrière,78,Yvelines,11,Île-de-France
78646,VERSAILLES,78000,VERSAILLES,,48.8025669671,2.11789297191,646,,Versailles,Versailles,78,Yvelines,11,Île-de-France
78647,VERT,78930,VERT,,48.9403014358,1.68320274306,647,,Vert,Vert,78,Yvelines,11,Île-de-France
78650,LE VESINET,78110,LE VESINET,,48.8938640591,2.13039312004,650,Le,Vésinet,Le Vésinet,78,Yvelines,11,Île-de-France
78653,VICQ,78490,VICQ,,48.821484306,1.82843694709,653,,Vicq,Vicq,78,Yvelines,11,Île-de-France
78655,VIEILLE EGLISE EN YVELINES,78125,VIEILLE EGLISE EN YVELINES,,48.669743712,1.87479828191,655,,Vieille-Église-en-Yvelines,Vieille-Église-en-Yvelines,78,Yvelines,11,Île-de-France
78668,LA VILLENEUVE EN CHEVRIE,78270,LA VILLENEUVE EN CHEVRIE,,49.0171283911,1.53111618568,668,La,Villeneuve-en-Chevrie,La Villeneuve-en-Chevrie,78,Yvelines,11,Île-de-France
78672,VILLENNES SUR SEINE,78670,VILLENNES SUR SEINE,,48.9368195223,1.99586527491,672,,Villennes-sur-Seine,Villennes-sur-Seine,78,Yvelines,11,Île-de-France
78674,VILLEPREUX,78450,VILLEPREUX,,48.8311450096,2.0130908572,674,,Villepreux,Villepreux,78,Yvelines,11,Île-de-France
78677,VILLETTE,78930,VILLETTE,,48.9262788706,1.69374172457,677,,Villette,Villette,78,Yvelines,11,Île-de-France
78681,VILLIERS LE MAHIEU,78770,VILLIERS LE MAHIEU,,48.8533372475,1.77379972597,681,,Villiers-le-Mahieu,Villiers-le-Mahieu,78,Yvelines,11,Île-de-France
78683,VILLIERS ST FREDERIC,78640,VILLIERS ST FREDERIC,,48.8201784594,1.88250820601,683,,Villiers-Saint-Frédéric,Villiers-Saint-Frédéric,78,Yvelines,11,Île-de-France
78686,VIROFLAY,78220,VIROFLAY,,48.802404069,2.17151291149,686,,Viroflay,Viroflay,78,Yvelines,11,Île-de-France
78688,VOISINS LE BRETONNEUX,78960,VOISINS LE BRETONNEUX,,48.758677648,2.04850691768,688,,Voisins-le-Bretonneux,Voisins-le-Bretonneux,78,Yvelines,11,Île-de-France
79001,L ABSIE,79240,L ABSIE,,46.6475867778,-0.559273846456,1,L',Absie,L'Absie,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79002,ADILLY,79200,ADILLY,,46.7014595722,-0.315563835199,2,,Adilly,Adilly,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79003,AIFFRES,79230,AIFFRES,,46.2820504466,-0.41429097467,3,,Aiffres,Aiffres,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79004,AIGONNAY,79370,AIGONNAY,,46.3265792945,-0.248620838347,4,,Aigonnay,Aigonnay,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79005,AIRVAULT,79600,AIRVAULT,,46.8329725103,-0.135880248296,5,,Airvault,Airvault,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79005,AIRVAULT,79600,AIRVAULT,BORCQ SUR AIRVAULT,46.8329725103,-0.135880248296,5,,Airvault,Airvault,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79005,AIRVAULT,79600,AIRVAULT,SOULIEVRES,46.8329725103,-0.135880248296,5,,Airvault,Airvault,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79006,LES ALLEUDS,79190,LES ALLEUDS,,46.1594577371,-0.013910113453,6,Les,Alleuds,Les Alleuds,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79007,ALLONNE,79130,ALLONNE,,46.5804441397,-0.38171869398,7,,Allonne,Allonne,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79008,AMAILLOUX,79350,AMAILLOUX,,46.7494537565,-0.313467938365,8,,Amailloux,Amailloux,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79009,AMURE,79210,AMURE,,46.2569032806,-0.608681235963,9,,Amuré,Amuré,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79010,ARCAIS,79210,ARCAIS,,46.3012932064,-0.695370994677,10,,Arçais,Arçais,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79011,ARDILLEUX,79110,ARDILLEUX,,46.0930299539,-0.0437628037734,11,,Ardilleux,Ardilleux,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79012,ARDIN,79160,ARDIN,,46.4816783917,-0.55505132274,12,,Ardin,Ardin,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79013,ARGENTONAY,79150,ARGENTONAY,ARGENTON LES VALLEES,46.9784261272,-0.468875242934,13,,Argentonnay,Argentonnay,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79013,ARGENTONAY,79150,ARGENTONAY,BOESSE,46.9784261272,-0.468875242934,13,,Argentonnay,Argentonnay,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79013,ARGENTONAY,79150,ARGENTONAY,LA COUDRE,46.9784261272,-0.468875242934,13,,Argentonnay,Argentonnay,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79013,ARGENTONAY,79150,ARGENTONAY,LE BREUIL SOUS ARGENTON,46.9784261272,-0.468875242934,13,,Argentonnay,Argentonnay,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79013,ARGENTONAY,79150,ARGENTONAY,MOUTIERS SOUS ARGENTON,46.9784261272,-0.468875242934,13,,Argentonnay,Argentonnay,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79013,ARGENTONAY,79150,ARGENTONAY,SANZAY,46.9784261272,-0.468875242934,13,,Argentonnay,Argentonnay,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79013,ARGENTONAY,79150,ARGENTONAY,ULCOT,46.9784261272,-0.468875242934,13,,Argentonnay,Argentonnay,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79013,ARGENTONAY,79300,ARGENTONAY,LA CHAPELLE GAUDIN,46.9784261272,-0.468875242934,13,,Argentonnay,Argentonnay,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79014,ARGENTON L EGLISE,79290,ARGENTON L EGLISE,,47.0529717735,-0.251510808352,14,,Argenton-l'Église,Argenton-l'Église,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79014,ARGENTON L EGLISE,79290,ARGENTON L EGLISE,BAGNEUX,47.0529717735,-0.251510808352,14,,Argenton-l'Église,Argenton-l'Église,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79015,ASNIERES EN POITOU,79170,ASNIERES EN POITOU,,46.0894554355,-0.211290133628,15,,Asnières-en-Poitou,Asnières-en-Poitou,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79016,ASSAIS LES JUMEAUX,79600,ASSAIS LES JUMEAUX,,46.7918233643,-0.0681160058394,16,,Assais-les-Jumeaux,Assais-les-Jumeaux,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79016,ASSAIS LES JUMEAUX,79600,ASSAIS LES JUMEAUX,LES JUMEAUX,46.7918233643,-0.0681160058394,16,,Assais-les-Jumeaux,Assais-les-Jumeaux,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79018,AUBIGNE,79110,AUBIGNE,,46.0427853144,-0.146119185079,18,,Aubigné,Aubigné,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79019,AUBIGNY,79390,AUBIGNY,,46.7272853814,-0.104552795327,19,,Aubigny,Aubigny,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79020,AUGE,79400,AUGE,,46.4497619282,-0.288817537299,20,,Augé,Augé,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79022,AVAILLES THOUARSAIS,79600,AVAILLES THOUARSAIS,,46.8552025032,-0.136835890651,22,,Availles-Thouarsais,Availles-Thouarsais,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79023,AVON,79800,AVON,,46.3723048467,0.00235793629943,23,,Avon,Avon,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79024,AZAY LE BRULE,79400,AZAY LE BRULE,,46.4045197367,-0.267456920636,24,,Azay-le-Brûlé,Azay-le-Brûlé,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79025,AZAY SUR THOUET,79130,AZAY SUR THOUET,,46.6190923959,-0.356635119347,25,,Azay-sur-Thouet,Azay-sur-Thouet,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79027,LA BATAILLE,79110,LA BATAILLE,,46.0857477459,-0.109623150182,27,La,Bataille,La Bataille,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79029,BEAULIEU SOUS PARTHENAY,79420,BEAULIEU SOUS PARTHENAY,,46.5772588868,-0.208446737008,29,,Beaulieu-sous-Parthenay,Beaulieu-sous-Parthenay,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79030,BEAUSSAIS VITRE,79370,BEAUSSAIS VITRE,,46.2826295796,-0.154592535008,30,,Beaussais-Vitré,Beaussais-Vitré,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79030,BEAUSSAIS VITRE,79370,BEAUSSAIS VITRE,VITRE,46.2826295796,-0.154592535008,30,,Beaussais-Vitré,Beaussais-Vitré,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79031,BEAUVOIR SUR NIORT,79360,BEAUVOIR SUR NIORT,,46.180941644,-0.473314553431,31,,Beauvoir-sur-Niort,Beauvoir-sur-Niort,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79031,BEAUVOIR SUR NIORT,79360,BEAUVOIR SUR NIORT,LA REVETIZON,46.180941644,-0.473314553431,31,,Beauvoir-sur-Niort,Beauvoir-sur-Niort,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79031,BEAUVOIR SUR NIORT,79360,BEAUVOIR SUR NIORT,LE CORMENIER,46.180941644,-0.473314553431,31,,Beauvoir-sur-Niort,Beauvoir-sur-Niort,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79032,BECELEUF,79160,BECELEUF,,46.4795118674,-0.509542625672,32,,Béceleuf,Béceleuf,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79033,BELLEVILLE,79360,BELLEVILLE,,46.1312646734,-0.485169889086,33,,Belleville,Belleville,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79034,BESSINES,79000,BESSINES,,46.2924246809,-0.51369379312,34,,Bessines,Bessines,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79035,LE BEUGNON,79130,LE BEUGNON,,46.5842111668,-0.498009521622,35,Le,Beugnon,Le Beugnon,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79038,BOISME,79300,BOISME,,46.7824800962,-0.433256356633,38,,Boismé,Boismé,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79039,BOISSEROLLES,79360,BOISSEROLLES,,46.11264704,-0.449919647334,39,,Boisserolles,Boisserolles,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79040,LA BOISSIERE EN GATINE,79310,LA BOISSIERE EN GATINE,,46.5484127409,-0.381035769407,40,La,Boissière-en-Gâtine,La Boissière-en-Gâtine,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79042,BOUGON,79800,BOUGON,,46.3681223609,-0.058422640934,42,,Bougon,Bougon,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79043,BOUILLE LORETZ,79290,BOUILLE LORETZ,,47.0837533753,-0.281260023968,43,,Bouillé-Loretz,Bouillé-Loretz,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79044,BOUILLE ST PAUL,79290,BOUILLE ST PAUL,,47.0350297953,-0.314425439362,44,,Bouillé-Saint-Paul,Bouillé-Saint-Paul,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79045,BOUIN,79110,BOUIN,,46.0911898215,-0.013229553499,45,,Bouin,Bouin,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79046,LE BOURDET,79210,LE BOURDET,,46.238410731,-0.635204990837,46,Le,Bourdet,Le Bourdet,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79047,BOUSSAIS,79600,BOUSSAIS,,46.8185496547,-0.263669046618,47,,Boussais,Boussais,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79048,LA CRECHE,79260,LA CRECHE,,46.3656964606,-0.309032604795,48,La,Crèche,La Crèche,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79049,BRESSUIRE,79300,BRESSUIRE,,46.8545755683,-0.479375922286,49,,Bressuire,Bressuire,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79049,BRESSUIRE,79300,BRESSUIRE,BEAULIEU SOUS BRESSUIRE,46.8545755683,-0.479375922286,49,,Bressuire,Bressuire,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79049,BRESSUIRE,79300,BRESSUIRE,BREUIL CHAUSSEE,46.8545755683,-0.479375922286,49,,Bressuire,Bressuire,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79049,BRESSUIRE,79300,BRESSUIRE,CHAMBROUTET,46.8545755683,-0.479375922286,49,,Bressuire,Bressuire,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79049,BRESSUIRE,79300,BRESSUIRE,CLAZAY,46.8545755683,-0.479375922286,49,,Bressuire,Bressuire,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79049,BRESSUIRE,79300,BRESSUIRE,NOIRLIEU,46.8545755683,-0.479375922286,49,,Bressuire,Bressuire,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79049,BRESSUIRE,79300,BRESSUIRE,NOIRTERRE,46.8545755683,-0.479375922286,49,,Bressuire,Bressuire,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79049,BRESSUIRE,79300,BRESSUIRE,ST SAUVEUR,46.8545755683,-0.479375922286,49,,Bressuire,Bressuire,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79049,BRESSUIRE,79300,BRESSUIRE,TERVES,46.8545755683,-0.479375922286,49,,Bressuire,Bressuire,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79050,BRETIGNOLLES,79140,BRETIGNOLLES,,46.8727956003,-0.594560629836,50,,Bretignolles,Bretignolles,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79051,LE BREUIL BERNARD,79320,LE BREUIL BERNARD,,46.7138106631,-0.556791561272,51,Le,Breuil-Bernard,Le Breuil-Bernard,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79054,BRIE,79100,BRIE,,46.922690796,-0.0374783064468,54,,Brie,Brie,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79055,BRIEUIL SUR CHIZE,79170,BRIEUIL SUR CHIZE,,46.1263946231,-0.326929907334,55,,Brieuil-sur-Chizé,Brieuil-sur-Chizé,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79056,BRION PRES THOUET,79290,BRION PRES THOUET,,47.0500287565,-0.188112073906,56,,Brion-près-Thouet,Brion-près-Thouet,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79057,BRIOUX SUR BOUTONNE,79170,BRIOUX SUR BOUTONNE,,46.1418337503,-0.218953610699,57,,Brioux-sur-Boutonne,Brioux-sur-Boutonne,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79058,BRULAIN,79230,BRULAIN,,46.2053327297,-0.314279915986,58,,Brûlain,Brûlain,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79059,LE BUSSEAU,79240,LE BUSSEAU,,46.5831261391,-0.594466016248,59,Le,Busseau,Le Busseau,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79060,CAUNAY,79190,CAUNAY,,46.2016740241,0.088702939979,60,,Caunay,Caunay,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79061,CELLES SUR BELLE,79370,CELLES SUR BELLE,,46.2405369288,-0.218181064771,61,,Celles-sur-Belle,Celles-sur-Belle,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79061,CELLES SUR BELLE,79370,CELLES SUR BELLE,MONTIGNE,46.2405369288,-0.218181064771,61,,Celles-sur-Belle,Celles-sur-Belle,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79061,CELLES SUR BELLE,79370,CELLES SUR BELLE,VERRINES SOUS CELLES,46.2405369288,-0.218181064771,61,,Celles-sur-Belle,Celles-sur-Belle,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79062,CERIZAY,79140,CERIZAY,,46.8217311036,-0.672469385521,62,,Cerizay,Cerizay,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79063,CERSAY,79290,CERSAY,,47.0601100117,-0.36392979915,63,,Val en Vignes,Val en Vignes,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79063,CERSAY,79290,CERSAY,ST PIERRE A CHAMP,47.0601100117,-0.36392979915,63,,Val en Vignes,Val en Vignes,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79064,CHAIL,79500,CHAIL,,46.2032625224,-0.0778790591165,64,,Chail,Chail,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79066,CHAMPDENIERS ST DENIS,79220,CHAMPDENIERS ST DENIS,,46.48017177,-0.38233277532,66,,Champdeniers-Saint-Denis,Champdeniers-Saint-Denis,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79066,CHAMPDENIERS ST DENIS,79220,CHAMPDENIERS ST DENIS,CHAMPEAUX,46.48017177,-0.38233277532,66,,Champdeniers-Saint-Denis,Champdeniers-Saint-Denis,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79066,CHAMPDENIERS ST DENIS,79220,CHAMPDENIERS ST DENIS,ST DENIS,46.48017177,-0.38233277532,66,,Champdeniers-Saint-Denis,Champdeniers-Saint-Denis,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79068,CHANTECORPS,79340,CHANTECORPS,,46.5027168303,-0.14677650863,68,,Chantecorps,Chantecorps,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79069,CHANTELOUP,79320,CHANTELOUP,,46.7625455282,-0.505290757688,69,,Chanteloup,Chanteloup,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79070,LA CHAPELLE BATON,79220,LA CHAPELLE BATON,,46.4685249074,-0.324601053258,70,La,Chapelle-Bâton,La Chapelle-Bâton,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79071,LA CHAPELLE BERTRAND,79200,LA CHAPELLE BERTRAND,,46.6181522688,-0.191159883501,71,La,Chapelle-Bertrand,La Chapelle-Bertrand,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79074,LA CHAPELLE POUILLOUX,79190,LA CHAPELLE POUILLOUX,,46.1374212762,0.0481520171675,74,La,Chapelle-Pouilloux,La Chapelle-Pouilloux,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79075,LA CHAPELLE ST ETIENNE,79240,LA CHAPELLE ST ETIENNE,,46.6712926373,-0.580412885716,75,La,Chapelle-Saint-Étienne,La Chapelle-Saint-Étienne,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79076,LA CHAPELLE ST LAURENT,79430,LA CHAPELLE ST LAURENT,,46.7322726991,-0.466366098553,76,La,Chapelle-Saint-Laurent,La Chapelle-Saint-Laurent,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79077,LA CHAPELLE THIREUIL,79160,LA CHAPELLE THIREUIL,,46.5528411704,-0.552963332142,77,La,Chapelle-Thireuil,La Chapelle-Thireuil,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79078,PRISSE LA CHARRIERE,79360,PRISSE LA CHARRIERE,,46.1506834964,-0.48413782937,78,,Plaine-d'Argenson,Plaine-d'Argenson,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79078,PRISSE LA CHARRIERE,79360,PRISSE LA CHARRIERE,LA CHARRIERE,46.1506834964,-0.48413782937,78,,Plaine-d'Argenson,Plaine-d'Argenson,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79078,PRISSE LA CHARRIERE,79360,PRISSE LA CHARRIERE,PRISSE,46.1506834964,-0.48413782937,78,,Plaine-d'Argenson,Plaine-d'Argenson,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79079,MAULEON,79700,MAULEON,,46.946804716,-0.747142973233,79,,Mauléon,Mauléon,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79079,MAULEON,79700,MAULEON,LA CHAPELLE LARGEAU,46.946804716,-0.747142973233,79,,Mauléon,Mauléon,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79079,MAULEON,79700,MAULEON,LE TEMPLE,46.946804716,-0.747142973233,79,,Mauléon,Mauléon,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79079,MAULEON,79700,MAULEON,LOUBLANDE,46.946804716,-0.747142973233,79,,Mauléon,Mauléon,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79079,MAULEON,79700,MAULEON,MOULINS,46.946804716,-0.747142973233,79,,Mauléon,Mauléon,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79079,MAULEON,79700,MAULEON,RORTHAIS,46.946804716,-0.747142973233,79,,Mauléon,Mauléon,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79079,MAULEON,79700,MAULEON,ST AUBIN DE BAUBIGNE,46.946804716,-0.747142973233,79,,Mauléon,Mauléon,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79080,CHATILLON SUR THOUET,79200,CHATILLON SUR THOUET,,46.6725261071,-0.260421320802,80,,Châtillon-sur-Thouet,Châtillon-sur-Thouet,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79081,CHAURAY,79180,CHAURAY,,46.3517313978,-0.380481716309,81,,Chauray,Chauray,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79083,CHEF BOUTONNE,79110,CHEF BOUTONNE,,46.1151550061,-0.081086313145,83,,Chef-Boutonne,Chef-Boutonne,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79084,CHENAY,79120,CHENAY,,46.3306772083,-0.0114425772663,84,,Chenay,Chenay,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79085,CHERIGNE,79170,CHERIGNE,,46.115178559,-0.172695425322,85,,Chérigné,Chérigné,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79086,CHERVEUX,79410,CHERVEUX,,46.4212062389,-0.353210570059,86,,Cherveux,Cherveux,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79087,CHEY,79120,CHEY,,46.2935127706,-0.049821520326,87,,Chey,Chey,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79088,CHICHE,79350,CHICHE,,46.7954609677,-0.351768054404,88,,Chiché,Chiché,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79089,LE CHILLOU,79600,LE CHILLOU,,46.7695842244,-0.12153287828,89,Le,Chillou,Le Chillou,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79090,CHIZE,79170,CHIZE,,46.1111767305,-0.356732574606,90,,Chizé,Chizé,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79090,CHIZE,79170,CHIZE,AVAILLES SUR CHIZE,46.1111767305,-0.356732574606,90,,Chizé,Chizé,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79091,CIRIERES,79140,CIRIERES,,46.8409779696,-0.621904692049,91,,Cirières,Cirières,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79092,CLAVE,79420,CLAVE,,46.5034289857,-0.201213420477,92,,Clavé,Clavé,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79094,CLESSE,79350,CLESSE,,46.7247725873,-0.401162719088,94,,Clessé,Clessé,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79095,CLUSSAIS LA POMMERAIE,79190,CLUSSAIS LA POMMERAIE,,46.1862514257,0.0320581904981,95,,Clussais-la-Pommeraie,Clussais-la-Pommeraie,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79096,COMBRAND,79140,COMBRAND,,46.8639718192,-0.695632067582,96,,Combrand,Combrand,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79098,LA COUARDE,79800,LA COUARDE,,46.311279395,-0.143860718108,98,La,Couarde,La Couarde,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79100,COULON,79510,COULON,,46.3372530309,-0.58411752533,100,,Coulon,Coulon,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79101,COULONGES SUR L AUTIZE,79160,COULONGES SUR L AUTIZE,,46.4828315658,-0.599450293173,101,,Coulonges-sur-l'Autize,Coulonges-sur-l'Autize,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79102,COULONGES THOUARSAIS,79330,COULONGES THOUARSAIS,,46.9231412518,-0.332982472624,102,,Coulonges-Thouarsais,Coulonges-Thouarsais,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79103,COURLAY,79440,COURLAY,,46.7822684522,-0.577979844225,103,,Courlay,Courlay,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79104,COURS,79220,COURS,,46.5023506712,-0.415189139601,104,,Cours,Cours,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79105,COUTIERES,79340,COUTIERES,,46.4989423539,-0.107332373635,105,,Coutières,Coutières,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79106,COUTURE D ARGENSON,79110,COUTURE D ARGENSON,,45.9956854391,-0.0844691973496,106,,Couture-d'Argenson,Couture-d'Argenson,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79107,CREZIERES,79110,CREZIERES,,46.0796072709,-0.13532231207,107,,Crézières,Crézières,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79108,DOUX,79390,DOUX,,46.7391098577,0.00671432055399,108,,Doux,Doux,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79109,ECHIRE,79410,ECHIRE,,46.3946292281,-0.426727106349,109,,Échiré,Échiré,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79111,ENSIGNE,79170,ENSIGNE,,46.0810957498,-0.257742300306,111,,Ensigné,Ensigné,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79112,EPANNES,79270,EPANNES,,46.2241677035,-0.588710493017,112,,Épannes,Épannes,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79114,EXIREUIL,79400,EXIREUIL,,46.4537143363,-0.177315480712,114,,Exireuil,Exireuil,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79115,EXOUDUN,79800,EXOUDUN,,46.3385004673,-0.0727970199541,115,,Exoudun,Exoudun,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79116,FAYE L ABBESSE,79350,FAYE L ABBESSE,,46.8344255301,-0.343663613449,116,,Faye-l'Abbesse,Faye-l'Abbesse,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79117,FAYE SUR ARDIN,79160,FAYE SUR ARDIN,,46.4422797046,-0.50477624908,117,,Faye-sur-Ardin,Faye-sur-Ardin,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79118,FENERY,79450,FENERY,,46.6849155866,-0.364879721586,118,,Fénery,Fénery,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79119,FENIOUX,79160,FENIOUX,,46.540724799,-0.494170726123,119,,Fenioux,Fenioux,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79120,LA FERRIERE EN PARTHENAY,79390,LA FERRIERE EN PARTHENAY,,46.6478583733,-0.0787535449945,120,La,Ferrière-en-Parthenay,La Ferrière-en-Parthenay,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79121,FOMPERRON,79340,FOMPERRON,,46.4695567913,-0.118091718958,121,,Fomperron,Fomperron,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79122,FONTENILLE ST MARTIN D ENTRAIGUES,79110,FONTENILLE ST MARTIN ENTRAIGUES,,46.1121201701,-0.132411224833,122,,Fontenille-Saint-Martin-d'Entraigues,Fontenille-Saint-Martin-d'Entraigues,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79122,FONTENILLE ST MARTIN D ENTRAIGUES,79110,FONTENILLE ST MARTIN ENTRAIGUES,ST MARTIN D ENTRAIGUES,46.1121201701,-0.132411224833,122,,Fontenille-Saint-Martin-d'Entraigues,Fontenille-Saint-Martin-d'Entraigues,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79123,LA FORET SUR SEVRE,79380,LA FORET SUR SEVRE,,46.7587446345,-0.648000879918,123,La,Forêt-sur-Sèvre,La Forêt-sur-Sèvre,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79123,LA FORET SUR SEVRE,79380,LA FORET SUR SEVRE,LA RONDE,46.7587446345,-0.648000879918,123,La,Forêt-sur-Sèvre,La Forêt-sur-Sèvre,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79123,LA FORET SUR SEVRE,79380,LA FORET SUR SEVRE,MONTIGNY,46.7587446345,-0.648000879918,123,La,Forêt-sur-Sèvre,La Forêt-sur-Sèvre,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79123,LA FORET SUR SEVRE,79380,LA FORET SUR SEVRE,ST MARSAULT,46.7587446345,-0.648000879918,123,La,Forêt-sur-Sèvre,La Forêt-sur-Sèvre,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79124,LES FORGES,79340,LES FORGES,,46.5424201679,-0.0197804413151,124,Les,Forges,Les Forges,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79125,FORS,79230,FORS,,46.2413057135,-0.414327333899,125,,Fors,Fors,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79126,LES FOSSES,79360,LES FOSSES,,46.1685885952,-0.368590910512,126,Les,Fosses,Les Fosses,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79127,LA FOYE MONJAULT,79360,LA FOYE MONJAULT,,46.1870040122,-0.533179423966,127,La,Foye-Monjault,La Foye-Monjault,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79128,FRANCOIS,79260,FRANCOIS,,46.3822106449,-0.344073409629,128,,François,François,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79129,FRESSINES,79370,FRESSINES,,46.3248806803,-0.299910960177,129,,Fressines,Fressines,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79130,FRONTENAY ROHAN ROHAN,79270,FRONTENAY ROHAN ROHAN,,46.2557084922,-0.540123397468,130,,Frontenay-Rohan-Rohan,Frontenay-Rohan-Rohan,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79131,GEAY,79330,GEAY,,46.8691727621,-0.348538487123,131,,Geay,Geay,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79132,GENNETON,79150,GENNETON,,47.0447855312,-0.437548690394,132,,Genneton,Genneton,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79133,GERMOND ROUVRE,79220,GERMOND ROUVRE,,46.450571365,-0.418072751644,133,,Germond-Rouvre,Germond-Rouvre,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79133,GERMOND ROUVRE,79220,GERMOND ROUVRE,ROUVRE,46.450571365,-0.418072751644,133,,Germond-Rouvre,Germond-Rouvre,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79134,GLENAY,79330,GLENAY,,46.856442767,-0.241719789951,134,,Glénay,Glénay,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79135,GOURGE,79200,GOURGE,,46.7252168311,-0.184292127866,135,,Gourgé,Gourgé,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79136,GOURNAY LOIZE,79110,GOURNAY LOIZE,,46.1501272552,-0.0484413702112,136,,Alloinay,Alloinay,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79136,GOURNAY LOIZE,79110,GOURNAY LOIZE,LOIZE,46.1501272552,-0.0484413702112,136,,Alloinay,Alloinay,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79137,GRANZAY GRIPT,79360,GRANZAY GRIPT,,46.2259924524,-0.480205972926,137,,Granzay-Gript,Granzay-Gript,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79137,GRANZAY GRIPT,79360,GRANZAY GRIPT,GRIPT,46.2259924524,-0.480205972926,137,,Granzay-Gript,Granzay-Gript,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79139,LES GROSEILLERS,79220,LES GROSEILLERS,,46.5316195525,-0.406780672579,139,Les,Groseillers,Les Groseillers,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79140,HANC,79110,HANC,,46.0832090401,-0.000696246203395,140,,Hanc,Hanc,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79141,IRAIS,79600,IRAIS,,46.8755153904,-0.0957786406163,141,,Irais,Irais,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79142,JUILLE,79170,JUILLE,,46.1120285773,-0.223925439976,142,,Juillé,Juillé,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79144,JUSCORPS,79230,JUSCORPS,,46.2107424942,-0.377400693805,144,,Juscorps,Juscorps,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79145,LAGEON,79200,LAGEON,,46.7303622992,-0.249500520917,145,,Lageon,Lageon,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79147,LARGEASSE,79240,LARGEASSE,,46.6817186542,-0.514612797017,147,,Largeasse,Largeasse,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79148,LEZAY,79120,LEZAY,,46.2658473904,-0.0196873311167,148,,Lezay,Lezay,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79149,LHOUMOIS,79390,LHOUMOIS,,46.7064468734,-0.126840647275,149,,Lhoumois,Lhoumois,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79150,LIMALONGES,79190,LIMALONGES,,46.1367085273,0.173652821566,150,,Limalonges,Limalonges,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79152,LORIGNE,79190,LORIGNE,,46.1065410411,0.069110436943,152,,Lorigné,Lorigné,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79153,LOUBIGNE,79110,LOUBIGNE,,46.0754688078,-0.0821508831682,153,,Loubigné,Loubigné,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79154,LOUBILLE,79110,LOUBILLE,,46.0438559656,-0.064302714136,154,,Loubillé,Loubillé,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79156,LOUIN,79600,LOUIN,,46.7830388919,-0.207527070527,156,,Louin,Louin,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79157,LOUZY,79100,LOUZY,,47.0176127367,-0.180557135196,157,,Louzy,Louzy,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79158,LUCHE SUR BRIOUX,79170,LUCHE SUR BRIOUX,,46.1344762201,-0.15027346601,158,,Luché-sur-Brioux,Luché-sur-Brioux,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79159,LUCHE THOUARSAIS,79330,LUCHE THOUARSAIS,,46.9128068822,-0.304585380598,159,,Luché-Thouarsais,Luché-Thouarsais,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79160,LUSSERAY,79170,LUSSERAY,,46.1453507007,-0.168954795047,160,,Lusseray,Lusseray,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79161,LUZAY,79100,LUZAY,,46.9273390043,-0.2074214162,161,,Luzay,Luzay,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79162,MAGNE,79460,MAGNE,,46.3095905952,-0.558467673528,162,,Magné,Magné,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79163,MAIRE LEVESCAULT,79190,MAIRE LEVESCAULT,,46.1564138724,0.0765658334292,163,,Mairé-Levescault,Mairé-Levescault,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79164,MAISONNAY,79500,MAISONNAY,,46.1853348729,-0.0509301728856,164,,Maisonnay,Maisonnay,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79165,MAISONTIERS,79600,MAISONTIERS,,46.7784936839,-0.263054037852,165,,Maisontiers,Maisontiers,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79166,MARIGNY,79360,MARIGNY,,46.1947992339,-0.416618786711,166,,Marigny,Marigny,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79167,MARNES,79600,MARNES,,46.8531541226,-0.0177888678649,167,,Marnes,Marnes,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79168,MASSAIS,79150,MASSAIS,,47.0036730756,-0.358495301454,168,,Massais,Massais,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79170,MAUZE SUR LE MIGNON,79210,MAUZE SUR LE MIGNON,,46.1920937817,-0.644355409155,170,,Mauzé-sur-le-Mignon,Mauzé-sur-le-Mignon,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79171,MAUZE THOUARSAIS,79100,MAUZE THOUARSAIS,,46.9730884096,-0.286634323187,171,,Mauzé-Thouarsais,Mauzé-Thouarsais,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79171,MAUZE THOUARSAIS,79100,MAUZE THOUARSAIS,RIGNE,46.9730884096,-0.286634323187,171,,Mauzé-Thouarsais,Mauzé-Thouarsais,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79172,MAZIERES EN GATINE,79310,MAZIERES EN GATINE,,46.5207504336,-0.323592605264,172,,Mazières-en-Gâtine,Mazières-en-Gâtine,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79173,MAZIERES SUR BERONNE,79500,MAZIERES SUR BERONNE,,46.1827256837,-0.19031518074,173,,Mazières-sur-Béronne,Mazières-sur-Béronne,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79174,MELLE,79500,MELLE,,46.2309583731,-0.147888712984,174,,Melle,Melle,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79175,MELLERAN,79190,MELLERAN,,46.1300825848,0.00534971717862,175,,Melleran,Melleran,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79176,MENIGOUTE,79340,MENIGOUTE,,46.4927432547,-0.0657078750275,176,,Ménigoute,Ménigoute,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79177,MESSE,79120,MESSE,,46.2575229665,0.110039438392,177,,Messé,Messé,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79178,MISSE,79100,MISSE,,46.9558568224,-0.175760650961,178,,Missé,Missé,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79179,MONCOUTANT,79320,MONCOUTANT,,46.7361693715,-0.578776799054,179,,Moncoutant,Moncoutant,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79180,MONTALEMBERT,79190,MONTALEMBERT,,46.1021234288,0.170162348938,180,,Montalembert,Montalembert,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79183,MONTRAVERS,79140,MONTRAVERS,,46.8367681009,-0.736426265237,183,,Montravers,Montravers,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79184,LA MOTHE ST HERAY,79800,LA MOTHE ST HERAY,,46.3520802327,-0.119337575815,184,La,Mothe-Saint-Héray,La Mothe-Saint-Héray,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79185,MOUGON,79370,MOUGON,,46.2907364441,-0.296041998938,185,,Mougon-Thorigné,Mougon-Thorigné,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79188,MOUTIERS SOUS CHANTEMERLE,79320,MOUTIERS SOUS CHANTEMERLE,,46.6878996227,-0.624436667699,188,,Moutiers-sous-Chantemerle,Moutiers-sous-Chantemerle,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79189,NANTEUIL,79400,NANTEUIL,,46.4228145621,-0.154504788508,189,,Nanteuil,Nanteuil,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79190,NEUVY BOUIN,79130,NEUVY BOUIN,,46.6807933407,-0.446564097269,190,,Neuvy-Bouin,Neuvy-Bouin,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79191,NIORT,79000,NIORT,,46.328260242,-0.465353019369,191,,Niort,Niort,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79191,NIORT,79000,NIORT,SOUCHE,46.328260242,-0.465353019369,191,,Niort,Niort,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79191,NIORT,79000,NIORT,ST FLORENT,46.328260242,-0.465353019369,191,,Niort,Niort,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79191,NIORT,79000,NIORT,ST LIGUAIRE,46.328260242,-0.465353019369,191,,Niort,Niort,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79191,NIORT,79000,NIORT,STE PEZENNE,46.328260242,-0.465353019369,191,,Niort,Niort,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79195,NUEIL LES AUBIERS,79250,NUEIL LES AUBIERS,,46.9403172404,-0.596385417876,195,,Nueil-les-Aubiers,Nueil-les-Aubiers,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79195,NUEIL LES AUBIERS,79250,NUEIL LES AUBIERS,LES AUBIERS,46.9403172404,-0.596385417876,195,,Nueil-les-Aubiers,Nueil-les-Aubiers,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79196,OIRON,79100,OIRON,,46.9369329202,-0.0842351282064,196,,Oiron,Oiron,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79196,OIRON,79100,OIRON,BILAZAIS,46.9369329202,-0.0842351282064,196,,Oiron,Oiron,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79196,OIRON,79100,OIRON,NOIZE,46.9369329202,-0.0842351282064,196,,Oiron,Oiron,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79197,OROUX,79390,OROUX,,46.6902823818,-0.0938579683212,197,,Oroux,Oroux,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79198,PAIZAY LE CHAPT,79170,PAIZAY LE CHAPT,,46.0710589037,-0.184007366262,198,,Paizay-le-Chapt,Paizay-le-Chapt,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79199,PAIZAY LE TORT,79500,PAIZAY LE TORT,,46.1679427207,-0.17085390228,199,,Paizay-le-Tort,Paizay-le-Tort,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79200,PAMPLIE,79220,PAMPLIE,,46.5267037965,-0.439316862491,200,,Pamplie,Pamplie,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79201,PAMPROUX,79800,PAMPROUX,,46.4116775,-0.0432957775624,201,,Pamproux,Pamproux,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79202,PARTHENAY,79200,PARTHENAY,,46.6453493842,-0.233028439198,202,,Parthenay,Parthenay,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79203,PAS DE JEU,79100,PAS DE JEU,,46.9791242431,-0.062634350018,203,,Pas-de-Jeu,Pas-de-Jeu,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79204,PERIGNE,79170,PERIGNE,,46.1857674388,-0.245561860776,204,,Périgné,Périgné,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79205,PERS,79190,PERS,,46.2272985258,0.0665477663208,205,,Pers,Pers,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79207,LA PETITE BOISSIERE,79700,LA PETITE BOISSIERE,,46.8842322145,-0.735999564213,207,La,Petite-Boissière,La Petite-Boissière,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79208,LA PEYRATTE,79200,LA PEYRATTE,,46.664567255,-0.158712807616,208,La,Peyratte,La Peyratte,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79209,PIERREFITTE,79330,PIERREFITTE,,46.8649655632,-0.303291245227,209,,Pierrefitte,Pierrefitte,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79210,LE PIN,79140,LE PIN,,46.8785875846,-0.651643489827,210,Le,Pin,Le Pin,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79211,PIOUSSAY,79110,PIOUSSAY,,46.0804577028,0.0349880081083,211,,Pioussay,Pioussay,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79212,PLIBOUX,79190,PLIBOUX,,46.1647195331,0.12277120326,212,,Pliboux,Pliboux,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79213,POMPAIRE,79200,POMPAIRE,,46.6162346991,-0.239456636326,213,,Pompaire,Pompaire,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79214,POUFFONDS,79500,POUFFONDS,,46.2011824615,-0.116352885549,214,,Pouffonds,Pouffonds,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79215,POUGNE HERISSON,79130,POUGNE HERISSON,,46.660301587,-0.413918277159,215,,Pougne-Hérisson,Pougne-Hérisson,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79216,PRAHECQ,79230,PRAHECQ,,46.268539716,-0.344623723709,216,,Prahecq,Prahecq,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79217,PRAILLES,79370,PRAILLES,,46.3204184079,-0.202251534086,217,,Prailles,Prailles,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79218,PRESSIGNY,79390,PRESSIGNY,,46.7517058478,-0.0845157139309,218,,Pressigny,Pressigny,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79219,PRIAIRES,79210,PRIAIRES,,46.1501040293,-0.602163064799,219,,Priaires,Priaires,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79220,PRIN DEYRANCON,79210,PRIN DEYRANCON,,46.2187958758,-0.646970168525,220,,Prin-Deyrançon,Prin-Deyrançon,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79222,PUGNY,79320,PUGNY,,46.7216923229,-0.523180063868,222,,Pugny,Pugny,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79223,PUIHARDY,79160,PUIHARDY,,46.5255351053,-0.544548446285,223,,Puihardy,Puihardy,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79225,REFFANNES,79420,REFFANNES,,46.5384833617,-0.186609763053,225,,Reffannes,Reffannes,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79226,LE RETAIL,79130,LE RETAIL,,46.5632946327,-0.434627531061,226,Le,Retail,Le Retail,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79229,LA ROCHENARD,79270,LA ROCHENARD,,46.2012204653,-0.574638181441,229,La,Rochénard,La Rochénard,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79230,ROM,79120,ROM,,46.3034951181,0.1238295004,230,,Rom,Rom,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79231,ROMANS,79260,ROMANS,,46.3564424718,-0.228510254531,231,,Romans,Romans,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79235,ST AMAND SUR SEVRE,79700,ST AMAND SUR SEVRE,,46.8794926355,-0.782455017663,235,,Saint-Amand-sur-Sèvre,Saint-Amand-sur-Sèvre,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79236,ST ANDRE SUR SEVRE,79380,ST ANDRE SUR SEVRE,,46.7779589756,-0.699311994263,236,,Saint-André-sur-Sèvre,Saint-André-sur-Sèvre,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79238,ST AUBIN DU PLAIN,79300,ST AUBIN DU PLAIN,,46.9236179795,-0.470709364194,238,,Saint-Aubin-du-Plain,Saint-Aubin-du-Plain,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79239,ST AUBIN LE CLOUD,79450,ST AUBIN LE CLOUD,,46.6572663301,-0.342791988653,239,,Saint-Aubin-le-Cloud,Saint-Aubin-le-Cloud,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79240,STE BLANDINE,79370,STE BLANDINE,,46.2464579587,-0.277039232299,240,,Sainte-Blandine,Sainte-Blandine,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79241,ST CHRISTOPHE SUR ROC,79220,ST CHRISTOPHE SUR ROC,,46.4536311451,-0.35987590684,241,,Saint-Christophe-sur-Roc,Saint-Christophe-sur-Roc,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79242,VOULMENTIN,79150,VOULMENTIN,,46.960771587,-0.51496460613,242,,Voulmentin,Voulmentin,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79242,VOULMENTIN,79150,VOULMENTIN,VOULTEGON,46.960771587,-0.51496460613,242,,Voulmentin,Voulmentin,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79243,ST COUTANT,79120,ST COUTANT,,46.2229123339,0.00585913479042,243,,Saint-Coutant,Saint-Coutant,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79244,ST CYR LA LANDE,79100,ST CYR LA LANDE,,47.0506321675,-0.150283399563,244,,Saint-Cyr-la-Lande,Saint-Cyr-la-Lande,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79246,STE EANNE,79800,STE EANNE,,46.3915503941,-0.134321812271,246,,Sainte-Eanne,Sainte-Eanne,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79247,ST ETIENNE LA CIGOGNE,79360,ST ETIENNE LA CIGOGNE,,46.1147582638,-0.495376532777,247,,Saint-Étienne-la-Cigogne,Saint-Étienne-la-Cigogne,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79249,ST GELAIS,79410,ST GELAIS,,46.3783464917,-0.388823361913,249,,Saint-Gelais,Saint-Gelais,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79250,STE GEMME,79330,STE GEMME,,46.8997139729,-0.282960726169,250,,Sainte-Gemme,Sainte-Gemme,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79251,ST GENARD,79500,ST GENARD,,46.1863394074,-0.134327171027,251,,Saint-Génard,Saint-Génard,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79252,ST GENEROUX,79600,ST GENEROUX,,46.8857147211,-0.146137280401,252,,Saint-Généroux,Saint-Généroux,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79253,ST GEORGES DE NOISNE,79400,ST GEORGES DE NOISNE,,46.4804515489,-0.242191937779,253,,Saint-Georges-de-Noisné,Saint-Georges-de-Noisné,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79254,ST GEORGES DE REX,79210,ST GEORGES DE REX,,46.2672848492,-0.659095506677,254,,Saint-Georges-de-Rex,Saint-Georges-de-Rex,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79255,ST GERMAIN DE LONGUE CHAUME,79200,ST GERMAIN DE LONGUE CHAUME,,46.7190274409,-0.345105343999,255,,Saint-Germain-de-Longue-Chaume,Saint-Germain-de-Longue-Chaume,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79256,ST GERMIER,79340,ST GERMIER,,46.4595030375,-0.0455788333534,256,,Saint-Germier,Saint-Germier,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79257,ST HILAIRE LA PALUD,79210,ST HILAIRE LA PALUD,,46.2614944894,-0.715171830149,257,,Saint-Hilaire-la-Palud,Saint-Hilaire-la-Palud,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79258,ST JACQUES DE THOUARS,79100,ST JACQUES DE THOUARS,,46.9694218287,-0.233924746738,258,,Saint-Jacques-de-Thouars,Saint-Jacques-de-Thouars,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79259,ST JEAN DE THOUARS,79100,ST JEAN DE THOUARS,,46.9546725978,-0.218962911821,259,,Saint-Jean-de-Thouars,Saint-Jean-de-Thouars,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79260,ST JOUIN DE MARNES,79600,ST JOUIN DE MARNES,,46.8814213211,-0.0550217260198,260,,Saint-Jouin-de-Marnes,Saint-Jouin-de-Marnes,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79261,ST JOUIN DE MILLY,79380,ST JOUIN DE MILLY,,46.761580843,-0.610782054204,261,,Saint-Jouin-de-Milly,Saint-Jouin-de-Milly,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79263,ST LAURS,79160,ST LAURS,,46.5276013407,-0.580296745458,263,,Saint-Laurs,Saint-Laurs,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79264,ST LEGER DE LA MARTINIERE,79500,ST LEGER DE LA MARTINIERE,,46.2428765448,-0.112350670797,264,,Saint-Léger-de-la-Martinière,Saint-Léger-de-la-Martinière,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79264,ST LEGER DE LA MARTINIERE,79500,ST LEGER DE LA MARTINIERE,L ENCLAVE DE LA MARTINIERE,46.2428765448,-0.112350670797,264,,Saint-Léger-de-la-Martinière,Saint-Léger-de-la-Martinière,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79265,ST LEGER DE MONTBRUN,79100,ST LEGER DE MONTBRUN,,46.989216133,-0.130757727063,265,,Saint-Léger-de-Montbrun,Saint-Léger-de-Montbrun,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79267,ST LIN,79420,ST LIN,,46.5326512584,-0.228519741295,267,,Saint-Lin,Saint-Lin,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79268,ST LOUP LAMAIRE,79600,ST LOUP LAMAIRE,,46.77039082,-0.157079150205,268,,Saint-Loup-Lamairé,Saint-Loup-Lamairé,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79268,ST LOUP LAMAIRE,79600,ST LOUP LAMAIRE,LAMAIRE,46.77039082,-0.157079150205,268,,Saint-Loup-Lamairé,Saint-Loup-Lamairé,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79269,ST MAIXENT DE BEUGNE,79160,ST MAIXENT DE BEUGNE,,46.5147236196,-0.613320337812,269,,Saint-Maixent-de-Beugné,Saint-Maixent-de-Beugné,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79270,ST MAIXENT L ECOLE,79400,ST MAIXENT L ECOLE,,46.4128319027,-0.210238265156,270,,Saint-Maixent-l'École,Saint-Maixent-l'École,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79271,ST MARC LA LANDE,79310,ST MARC LA LANDE,,46.5253805702,-0.359877373961,271,,Saint-Marc-la-Lande,Saint-Marc-la-Lande,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79273,ST MARTIN DE BERNEGOUE,79230,ST MARTIN DE BERNEGOUE,,46.2362508702,-0.357119218416,273,,Saint-Martin-de-Bernegoue,Saint-Martin-de-Bernegoue,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79274,ST MARTIN DE MACON,79100,ST MARTIN DE MACON,,47.0226217222,-0.11381871589,274,,Saint-Martin-de-Mâcon,Saint-Martin-de-Mâcon,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79276,ST MARTIN DE ST MAIXENT,79400,ST MARTIN DE ST MAIXENT,,46.3892461256,-0.203596354099,276,,Saint-Martin-de-Saint-Maixent,Saint-Martin-de-Saint-Maixent,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79277,ST MARTIN DE SANZAY,79290,ST MARTIN DE SANZAY,,47.0747309996,-0.194759847944,277,,Saint-Martin-de-Sanzay,Saint-Martin-de-Sanzay,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79278,ST MARTIN DU FOUILLOUX,79420,ST MARTIN DU FOUILLOUX,,46.5925831338,-0.12664543987,278,,Saint-Martin-du-Fouilloux,Saint-Martin-du-Fouilloux,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79279,ST MARTIN LES MELLE,79500,ST MARTIN LES MELLE,,46.2262502573,-0.16852854296,279,,Saint-Martin-lès-Melle,Saint-Martin-lès-Melle,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79280,ST MAURICE ETUSSON,79150,ST MAURICE ETUSSON,,47.0496404823,-0.508816707634,280,,Saint Maurice Étusson,Saint Maurice Étusson,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79280,ST MAURICE ETUSSON,79150,ST MAURICE ETUSSON,ETUSSON,47.0496404823,-0.508816707634,280,,Saint Maurice Étusson,Saint Maurice Étusson,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79281,ST MAXIRE,79410,ST MAXIRE,,46.3993679989,-0.479989759702,281,,Saint-Maxire,Saint-Maxire,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79282,ST MEDARD,79370,ST MEDARD,,46.2112176621,-0.273782246591,282,,Saint-Médard,Saint-Médard,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79283,STE NEOMAYE,79260,STE NEOMAYE,,46.3617084983,-0.263692629127,283,,Sainte-Néomaye,Sainte-Néomaye,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79284,STE OUENNE,79220,STE OUENNE,,46.4384557215,-0.45255458079,284,,Sainte-Ouenne,Sainte-Ouenne,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79285,ST PARDOUX,79310,ST PARDOUX,,46.5770616007,-0.31674359278,285,,Saint-Pardoux,Saint-Pardoux,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79286,ST PAUL EN GATINE,79240,ST PAUL EN GATINE,,46.6396545543,-0.61720118582,286,,Saint-Paul-en-Gâtine,Saint-Paul-en-Gâtine,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79289,ST PIERRE DES ECHAUBROGNES,79700,ST PIERRE DES ECHAUBROGNES,,46.9797414702,-0.751899172602,289,,Saint-Pierre-des-Échaubrognes,Saint-Pierre-des-Échaubrognes,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79290,ST POMPAIN,79160,ST POMPAIN,,46.4246945473,-0.602460764264,290,,Saint-Pompain,Saint-Pompain,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79292,STE RADEGONDE,79100,STE RADEGONDE,,46.9906438525,-0.25058352877,292,,Sainte-Radegonde,Sainte-Radegonde,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79293,ST REMY,79410,ST REMY,,46.3713101301,-0.523390967902,293,,Saint-Rémy,Saint-Rémy,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79294,ST ROMANS DES CHAMPS,79230,ST ROMANS DES CHAMPS,,46.2026203924,-0.348997865901,294,,Saint-Romans-des-Champs,Saint-Romans-des-Champs,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79295,ST ROMANS LES MELLE,79500,ST ROMANS LES MELLE,,46.2066461108,-0.196334503286,295,,Saint-Romans-lès-Melle,Saint-Romans-lès-Melle,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79297,STE SOLINE,79120,STE SOLINE,,46.2515989193,0.0479672855851,297,,Sainte-Soline,Sainte-Soline,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79298,ST SYMPHORIEN,79270,ST SYMPHORIEN,,46.2674034004,-0.479082156946,298,,Saint-Symphorien,Saint-Symphorien,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79299,ST VARENT,79330,ST VARENT,,46.8927639643,-0.223311639048,299,,Saint-Varent,Saint-Varent,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79300,STE VERGE,79100,STE VERGE,,47.0128810811,-0.227694435042,300,,Sainte-Verge,Sainte-Verge,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79301,ST VINCENT LA CHATRE,79500,ST VINCENT LA CHATRE,,46.2128347937,-0.0364291684702,301,,Saint-Vincent-la-Châtre,Saint-Vincent-la-Châtre,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79302,SAIVRES,79400,SAIVRES,,46.4370891679,-0.233108202857,302,,Saivres,Saivres,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79303,SALLES,79800,SALLES,,46.3878556621,-0.0976374643512,303,,Salles,Salles,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79304,SANSAIS,79270,SANSAIS,,46.290244918,-0.594225936069,304,,Sansais,Sansais,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79306,SAURAIS,79200,SAURAIS,,46.6235561265,-0.135209285166,306,,Saurais,Saurais,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79307,SAUZE VAUSSAIS,79190,SAUZE VAUSSAIS,,46.1223913244,0.111211623906,307,,Sauzé-Vaussais,Sauzé-Vaussais,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79308,SCIECQ,79000,SCIECQ,,46.3703981885,-0.474635693538,308,,Sciecq,Sciecq,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79309,SCILLE,79240,SCILLE,,46.5996427928,-0.556961185783,309,,Scillé,Scillé,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79310,SECONDIGNE SUR BELLE,79170,SECONDIGNE SUR BELLE,,46.1625149847,-0.314175852363,310,,Secondigné-sur-Belle,Secondigné-sur-Belle,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79311,SECONDIGNY,79130,SECONDIGNY,,46.6116427776,-0.441850125966,311,,Secondigny,Secondigny,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79312,SELIGNE,79170,SELIGNE,,46.1398320782,-0.270834484675,312,,Séligné,Séligné,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79313,SEPVRET,79120,SEPVRET,,46.2902433415,-0.0945812252792,313,,Sepvret,Sepvret,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79314,SOMPT,79110,SOMPT,,46.1673238576,-0.0927668821819,314,,Sompt,Sompt,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79316,SOUDAN,79800,SOUDAN,,46.4323169213,-0.0959887989813,316,,Soudan,Soudan,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79318,SOUTIERS,79310,SOUTIERS,,46.5893025848,-0.267063023629,318,,Soutiers,Soutiers,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79319,SOUVIGNE,79800,SOUVIGNE,,46.358962328,-0.170685104329,319,,Souvigné,Souvigné,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79320,SURIN,79220,SURIN,,46.4686584041,-0.46087902331,320,,Surin,Surin,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79321,TAIZE,79100,TAIZE,,46.929663684,-0.137558548242,321,,Taizé-Maulais,Taizé-Maulais,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79321,TAIZE,79100,TAIZE,MAULAIS,46.929663684,-0.137558548242,321,,Taizé-Maulais,Taizé-Maulais,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79322,LE TALLUD,79200,LE TALLUD,,46.627315144,-0.291049695732,322,Le,Tallud,Le Tallud,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79325,TESSONNIERE,79600,TESSONNIERE,,46.8126773596,-0.21444629373,325,,Tessonnière,Tessonnière,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79326,THENEZAY,79390,THENEZAY,,46.7132141775,-0.0410463377578,326,,Thénezay,Thénezay,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79327,THORIGNE,79370,THORIGNE,,46.2838461214,-0.252472597117,327,,Thorigné,Thorigné,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79328,THORIGNY SUR LE MIGNON,79360,THORIGNY SUR LE MIGNON,,46.150104348,-0.556777323972,328,,Thorigny-sur-le-Mignon,Thorigny-sur-le-Mignon,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79329,THOUARS,79100,THOUARS,,46.9828628407,-0.199581467021,329,,Thouars,Thouars,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79330,TILLOU,79110,TILLOU,,46.1507015837,-0.121021590694,330,,Tillou,Tillou,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79331,TOURTENAY,79100,TOURTENAY,,47.0467950162,-0.111970256485,331,,Tourtenay,Tourtenay,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79332,TRAYES,79240,TRAYES,,46.6690393835,-0.48214510069,332,,Trayes,Trayes,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79334,USSEAU,79210,USSEAU,,46.1701185882,-0.587199474671,334,,Usseau,Usseau,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79335,VALLANS,79270,VALLANS,,46.2190224234,-0.545587322341,335,,Vallans,Vallans,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79336,VANCAIS,79120,VANCAIS,,46.3039075076,0.0433281606115,336,,Vançais,Vançais,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79337,LE VANNEAU IRLEAU,79270,LE VANNEAU IRLEAU,,46.3008415129,-0.643099715141,337,Le,Vanneau-Irleau,Le Vanneau-Irleau,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79338,VANZAY,79120,VANZAY,,46.2310672583,0.110650290356,338,,Vanzay,Vanzay,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79339,VASLES,79340,VASLES,,46.5737648617,-0.0494104571753,339,,Vasles,Vasles,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79340,VAUSSEROUX,79420,VAUSSEROUX,,46.5553603723,-0.138086745707,340,,Vausseroux,Vausseroux,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79341,VAUTEBIS,79420,VAUTEBIS,,46.5325405622,-0.153027126045,341,,Vautebis,Vautebis,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79342,VERNOUX EN GATINE,79240,VERNOUX EN GATINE,,46.6226441897,-0.517127798933,342,,Vernoux-en-Gâtine,Vernoux-en-Gâtine,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79343,VERNOUX SUR BOUTONNE,79170,VERNOUX SUR BOUTONNE,,46.1579500022,-0.262109778783,343,,Vernoux-sur-Boutonne,Vernoux-sur-Boutonne,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79345,VERRUYES,79310,VERRUYES,,46.5188026675,-0.282182786375,345,,Verruyes,Verruyes,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79346,LE VERT,79170,LE VERT,,46.1044348487,-0.398782793634,346,Le,Vert,Le Vert,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79347,VIENNAY,79200,VIENNAY,,46.6959251659,-0.247961764946,347,,Viennay,Viennay,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79348,VILLEFOLLET,79170,VILLEFOLLET,,46.1201543813,-0.273287814164,348,,Villefollet,Villefollet,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79349,VILLEMAIN,79110,VILLEMAIN,,46.0168427404,-0.111271447669,349,,Villemain,Villemain,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79350,VILLIERS EN BOIS,79360,VILLIERS EN BOIS,,46.1418949381,-0.408815332834,350,,Villiers-en-Bois,Villiers-en-Bois,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79351,VILLIERS EN PLAINE,79160,VILLIERS EN PLAINE,,46.4150506685,-0.536890888981,351,,Villiers-en-Plaine,Villiers-en-Plaine,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79352,VILLIERS SUR CHIZE,79170,VILLIERS SUR CHIZE,,46.0980469832,-0.30373228125,352,,Villiers-sur-Chizé,Villiers-sur-Chizé,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79354,VOUHE,79310,VOUHE,,46.5598449488,-0.25593190505,354,,Vouhé,Vouhé,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79355,VOUILLE,79230,VOUILLE,,46.3162898992,-0.356117687455,355,,Vouillé,Vouillé,79,Deux-Sèvres,75,Nouvelle-Aquitaine
79357,XAINTRAY,79220,XAINTRAY,,46.4958576969,-0.48044229191,357,,Xaintray,Xaintray,79,Deux-Sèvres,75,Nouvelle-Aquitaine
80001,ABBEVILLE,80100,ABBEVILLE,,50.1083582944,1.83209170207,1,,Abbeville,Abbeville,80,Somme,32,Hauts-de-France
80002,ABLAINCOURT PRESSOIR,80320,ABLAINCOURT PRESSOIR,,49.8413195623,2.82481058921,2,,Ablaincourt-Pressoir,Ablaincourt-Pressoir,80,Somme,32,Hauts-de-France
80003,ACHEUX EN AMIENOIS,80560,ACHEUX EN AMIENOIS,,50.0715561395,2.53117777106,3,,Acheux-en-Amiénois,Acheux-en-Amiénois,80,Somme,32,Hauts-de-France
80004,ACHEUX EN VIMEU,80210,ACHEUX EN VIMEU,,50.0660366423,1.67027205864,4,,Acheux-en-Vimeu,Acheux-en-Vimeu,80,Somme,32,Hauts-de-France
80005,AGENVILLE,80370,AGENVILLE,,50.1718032322,2.10777026695,5,,Agenville,Agenville,80,Somme,32,Hauts-de-France
80006,AGENVILLERS,80150,AGENVILLERS,,50.179281145,1.92367045048,6,,Agenvillers,Agenvillers,80,Somme,32,Hauts-de-France
80008,AIGNEVILLE,80210,AIGNEVILLE,,50.0367632999,1.60998293635,8,,Aigneville,Aigneville,80,Somme,32,Hauts-de-France
80009,AILLY LE HAUT CLOCHER,80690,AILLY LE HAUT CLOCHER,,50.0813661279,1.996040675,9,,Ailly-le-Haut-Clocher,Ailly-le-Haut-Clocher,80,Somme,32,Hauts-de-France
80010,AILLY SUR NOYE,80250,AILLY SUR NOYE,,49.7466608044,2.34699446197,10,,Ailly-sur-Noye,Ailly-sur-Noye,80,Somme,32,Hauts-de-France
80011,AILLY SUR SOMME,80470,AILLY SUR SOMME,,49.9134876579,2.18609222746,11,,Ailly-sur-Somme,Ailly-sur-Somme,80,Somme,32,Hauts-de-France
80013,AIRAINES,80270,AIRAINES,,49.9720712318,1.94513035737,13,,Airaines,Airaines,80,Somme,32,Hauts-de-France
80013,AIRAINES,80270,AIRAINES,DREUIL HAMEL,49.9720712318,1.94513035737,13,,Airaines,Airaines,80,Somme,32,Hauts-de-France
80014,AIZECOURT LE BAS,80240,AIZECOURT LE BAS,,49.9867768192,3.02909399369,14,,Aizecourt-le-Bas,Aizecourt-le-Bas,80,Somme,32,Hauts-de-France
80015,AIZECOURT LE HAUT,80200,AIZECOURT LE HAUT,,49.9669534703,2.98250284115,15,,Aizecourt-le-Haut,Aizecourt-le-Haut,80,Somme,32,Hauts-de-France
80016,ALBERT,80300,ALBERT,,50.0040829344,2.6483138296,16,,Albert,Albert,80,Somme,32,Hauts-de-France
80017,ALLAINES,80200,ALLAINES,,49.9632577527,2.9374491434,17,,Allaines,Allaines,80,Somme,32,Hauts-de-France
80018,ALLENAY,80130,ALLENAY,,50.0864703319,1.49294201709,18,,Allenay,Allenay,80,Somme,32,Hauts-de-France
80019,ALLERY,80270,ALLERY,,49.9594164117,1.88551094402,19,,Allery,Allery,80,Somme,32,Hauts-de-France
80020,ALLONVILLE,80260,ALLONVILLE,,49.9379400536,2.3677741824,20,,Allonville,Allonville,80,Somme,32,Hauts-de-France
80021,AMIENS,80000,AMIENS,,49.9009532186,2.29007445539,21,,Amiens,Amiens,80,Somme,32,Hauts-de-France
80021,AMIENS,80080,AMIENS,,49.9009532186,2.29007445539,21,,Amiens,Amiens,80,Somme,32,Hauts-de-France
80021,AMIENS,80090,AMIENS,,49.9009532186,2.29007445539,21,,Amiens,Amiens,80,Somme,32,Hauts-de-France
80022,ANDAINVILLE,80140,ANDAINVILLE,,49.8911987998,1.78468035475,22,,Andainville,Andainville,80,Somme,32,Hauts-de-France
80023,ANDECHY,80700,ANDECHY,,49.7147246952,2.70662674377,23,,Andechy,Andechy,80,Somme,32,Hauts-de-France
80024,ARGOEUVES,80470,ARGOEUVES,,49.9413936849,2.24676238077,24,,Argœuves,Argœuves,80,Somme,32,Hauts-de-France
80025,ARGOULES,80120,ARGOULES,,50.3376452337,1.81790740998,25,,Argoules,Argoules,80,Somme,32,Hauts-de-France
80026,ARGUEL,80140,ARGUEL,,49.8765831654,1.80743667237,26,,Arguel,Arguel,80,Somme,32,Hauts-de-France
80027,ARMANCOURT,80700,ARMANCOURT,,49.6767593182,2.71293599073,27,,Armancourt,Armancourt,80,Somme,32,Hauts-de-France
80028,ARQUEVES,80560,ARQUEVES,,50.0728473755,2.47755633754,28,,Arquèves,Arquèves,80,Somme,32,Hauts-de-France
80029,ARREST,80820,ARREST,,50.1288264007,1.61923803705,29,,Arrest,Arrest,80,Somme,32,Hauts-de-France
80030,ARRY,80120,ARRY,,50.2821494192,1.72270579703,30,,Arry,Arry,80,Somme,32,Hauts-de-France
80031,ARVILLERS,80910,ARVILLERS,,49.7318756883,2.63586531946,31,,Arvillers,Arvillers,80,Somme,32,Hauts-de-France
80032,ASSAINVILLERS,80500,ASSAINVILLERS,,49.6231275288,2.59930231823,32,,Assainvillers,Assainvillers,80,Somme,32,Hauts-de-France
80033,ASSEVILLERS,80200,ASSEVILLERS,,49.898435303,2.84165969588,33,,Assevillers,Assevillers,80,Somme,32,Hauts-de-France
80034,ATHIES,80200,ATHIES,,49.856991186,2.97748073745,34,,Athies,Athies,80,Somme,32,Hauts-de-France
80035,AUBERCOURT,80110,AUBERCOURT,,49.8378941809,2.54731400317,35,,Aubercourt,Aubercourt,80,Somme,32,Hauts-de-France
80036,AUBIGNY,80800,AUBIGNY,,49.887570915,2.47173096758,36,,Aubigny,Aubigny,80,Somme,32,Hauts-de-France
80037,AUBVILLERS,80110,AUBVILLERS,,49.7085691386,2.47932123,37,,Aubvillers,Aubvillers,80,Somme,32,Hauts-de-France
80038,AUCHONVILLERS,80560,AUCHONVILLERS,,50.0820136591,2.63330936836,38,,Auchonvillers,Auchonvillers,80,Somme,32,Hauts-de-France
80039,AULT,80460,AULT,,50.0925488309,1.44717519345,39,,Ault,Ault,80,Somme,32,Hauts-de-France
80040,AUMATRE,80140,AUMATRE,,49.921572463,1.77316426694,40,,Aumâtre,Aumâtre,80,Somme,32,Hauts-de-France
80041,AUMONT,80640,AUMONT,,49.8787928902,1.92410862168,41,,Aumont,Aumont,80,Somme,32,Hauts-de-France
80042,AUTHEUX,80600,AUTHEUX,,50.1395423257,2.2331435159,42,,Autheux,Autheux,80,Somme,32,Hauts-de-France
80043,AUTHIE,80560,AUTHIE,,50.1184979132,2.4915242737,43,,Authie,Authie,80,Somme,32,Hauts-de-France
80044,AUTHIEULE,80600,AUTHIEULE,,50.1359051678,2.37778968129,44,,Authieule,Authieule,80,Somme,32,Hauts-de-France
80045,AUTHUILLE,80300,AUTHUILLE,,50.046768796,2.67678190814,45,,Authuille,Authuille,80,Somme,32,Hauts-de-France
80046,AVELESGES,80270,AVELESGES,,49.9032524695,1.93185720865,46,,Avelesges,Avelesges,80,Somme,32,Hauts-de-France
80047,AVELUY,80300,AVELUY,,50.0311586735,2.66043117023,47,,Aveluy,Aveluy,80,Somme,32,Hauts-de-France
80048,AVESNES CHAUSSOY,80140,AVESNES CHAUSSOY,,49.8930647455,1.86378410575,48,,Avesnes-Chaussoy,Avesnes-Chaussoy,80,Somme,32,Hauts-de-France
80049,AYENCOURT,80500,AYENCOURT LE MONCHEL,,49.6264865136,2.55113103063,49,,Ayencourt,Ayencourt,80,Somme,32,Hauts-de-France
80050,BACOUEL SUR SELLE,80480,BACOUEL SUR SELLE,,49.8303379321,2.19407560384,50,,Bacouel-sur-Selle,Bacouel-sur-Selle,80,Somme,32,Hauts-de-France
80051,BAILLEUL,80490,BAILLEUL,,50.0258469553,1.85504998137,51,,Bailleul,Bailleul,80,Somme,32,Hauts-de-France
80052,BAIZIEUX,80300,BAIZIEUX,,49.990475441,2.51255850083,52,,Baizieux,Baizieux,80,Somme,32,Hauts-de-France
80053,BALATRE,80700,BALATRE,,49.7112977032,2.86504759397,53,,Balâtre,Balâtre,80,Somme,32,Hauts-de-France
80054,BARLEUX,80200,BARLEUX,,49.8976551923,2.89316410406,54,,Barleux,Barleux,80,Somme,32,Hauts-de-France
80055,BARLY,80600,BARLY,,50.2057087511,2.27424425535,55,,Barly,Barly,80,Somme,32,Hauts-de-France
80056,BAVELINCOURT,80260,BAVELINCOURT,,50.00064508,2.44958045984,56,,Bavelincourt,Bavelincourt,80,Somme,32,Hauts-de-France
80057,BAYENCOURT,80560,BAYENCOURT,,50.1316566885,2.57224230294,57,,Bayencourt,Bayencourt,80,Somme,32,Hauts-de-France
80058,BAYONVILLERS,80170,BAYONVILLERS,,49.8647263676,2.62854322017,58,,Bayonvillers,Bayonvillers,80,Somme,32,Hauts-de-France
80059,BAZENTIN,80300,BAZENTIN,,50.0297774468,2.76647860056,59,,Bazentin,Bazentin,80,Somme,32,Hauts-de-France
80060,BEALCOURT,80370,BEALCOURT,,50.1965893183,2.18248694285,60,,Béalcourt,Béalcourt,80,Somme,32,Hauts-de-France
80061,BEAUCAMPS LE JEUNE,80430,BEAUCAMPS LE JEUNE,,49.8168077628,1.77087038319,61,,Beaucamps-le-Jeune,Beaucamps-le-Jeune,80,Somme,32,Hauts-de-France
80062,BEAUCAMPS LE VIEUX,80430,BEAUCAMPS LE VIEUX,,49.8435746385,1.77757240569,62,,Beaucamps-le-Vieux,Beaucamps-le-Vieux,80,Somme,32,Hauts-de-France
80063,BEAUCHAMPS,80770,BEAUCHAMPS,,50.0192246753,1.52332437274,63,,Beauchamps,Beauchamps,80,Somme,32,Hauts-de-France
80064,BEAUCOURT EN SANTERRE,80110,BEAUCOURT EN SANTERRE,,49.7964929568,2.58623353641,64,,Beaucourt-en-Santerre,Beaucourt-en-Santerre,80,Somme,32,Hauts-de-France
80065,BEAUCOURT SUR L ANCRE,80300,BEAUCOURT SUR L ANCRE,,50.0848116723,2.68398023051,65,,Beaucourt-sur-l'Ancre,Beaucourt-sur-l'Ancre,80,Somme,32,Hauts-de-France
80066,BEAUCOURT SUR L HALLUE,80260,BEAUCOURT SUR L HALLUE,,49.9982031782,2.42756312859,66,,Beaucourt-sur-l'Hallue,Beaucourt-sur-l'Hallue,80,Somme,32,Hauts-de-France
80067,BEAUFORT EN SANTERRE,80170,BEAUFORT EN SANTERRE,,49.7816500284,2.66079679081,67,,Beaufort-en-Santerre,Beaufort-en-Santerre,80,Somme,32,Hauts-de-France
80068,BEAUMETZ,80370,BEAUMETZ,,50.1349354477,2.11024314398,68,,Beaumetz,Beaumetz,80,Somme,32,Hauts-de-France
80069,BEAUMONT HAMEL,80300,BEAUMONT HAMEL,,50.0765625531,2.65959457808,69,,Beaumont-Hamel,Beaumont-Hamel,80,Somme,32,Hauts-de-France
80070,BEAUQUESNE,80600,BEAUQUESNE,,50.0811863069,2.37614485033,70,,Beauquesne,Beauquesne,80,Somme,32,Hauts-de-France
80071,BEAUVAL,80630,BEAUVAL,,50.1079177763,2.32862837789,71,,Beauval,Beauval,80,Somme,32,Hauts-de-France
80073,BECORDEL BECOURT,80300,BECORDEL BECOURT,,49.9966789706,2.68706400892,73,,Bécordel-Bécourt,Bécordel-Bécourt,80,Somme,32,Hauts-de-France
80074,BECQUIGNY,80500,BECQUIGNY,,49.6889391045,2.62140108119,74,,Becquigny,Becquigny,80,Somme,32,Hauts-de-France
80076,BEHEN,80870,BEHEN,,50.0512628383,1.76199084272,76,,Béhen,Béhen,80,Somme,32,Hauts-de-France
80077,BEHENCOURT,80260,BEHENCOURT,,49.9738173198,2.469734228,77,,Béhencourt,Béhencourt,80,Somme,32,Hauts-de-France
80078,BELLANCOURT,80132,BELLANCOURT,,50.092743931,1.91271361466,78,,Bellancourt,Bellancourt,80,Somme,32,Hauts-de-France
80079,BELLEUSE,80160,BELLEUSE,,49.7029480012,2.11374292271,79,,Belleuse,Belleuse,80,Somme,32,Hauts-de-France
80080,BELLOY EN SANTERRE,80200,BELLOY EN SANTERRE,,49.8846472208,2.85995670033,80,,Belloy-en-Santerre,Belloy-en-Santerre,80,Somme,32,Hauts-de-France
80081,BELLOY ST LEONARD,80270,BELLOY ST LEONARD,,49.9048731038,1.91019659316,81,,Belloy-Saint-Léonard,Belloy-Saint-Léonard,80,Somme,32,Hauts-de-France
80082,BELLOY SUR SOMME,80310,BELLOY SUR SOMME,,49.9807813124,2.13868145771,82,,Belloy-sur-Somme,Belloy-sur-Somme,80,Somme,32,Hauts-de-France
80083,BERGICOURT,80290,BERGICOURT,,49.7444595181,2.0280499183,83,,Bergicourt,Bergicourt,80,Somme,32,Hauts-de-France
80084,BERMESNIL,80140,BERMESNIL,,49.897842281,1.74398450934,84,,Bermesnil,Bermesnil,80,Somme,32,Hauts-de-France
80084,BERMESNIL,80140,BERMESNIL,MESNIL EUDIN,49.897842281,1.74398450934,84,,Bermesnil,Bermesnil,80,Somme,32,Hauts-de-France
80085,BERNATRE,80370,BERNATRE,,50.1942289185,2.09783309423,85,,Bernâtre,Bernâtre,80,Somme,32,Hauts-de-France
80086,BERNAVILLE,80370,BERNAVILLE,,50.1349359626,2.17571907159,86,,Bernaville,Bernaville,80,Somme,32,Hauts-de-France
80086,BERNAVILLE,80370,BERNAVILLE,VACQUERIE,50.1349359626,2.17571907159,86,,Bernaville,Bernaville,80,Somme,32,Hauts-de-France
80087,BERNAY EN PONTHIEU,80120,BERNAY EN PONTHIEU,,50.2637068045,1.75043603953,87,,Bernay-en-Ponthieu,Bernay-en-Ponthieu,80,Somme,32,Hauts-de-France
80088,BERNES,80240,BERNES,,49.9087174553,3.10186976194,88,,Bernes,Bernes,80,Somme,32,Hauts-de-France
80089,BERNEUIL,80620,BERNEUIL,,50.088214456,2.17360293131,89,,Berneuil,Berneuil,80,Somme,32,Hauts-de-France
80090,BERNY EN SANTERRE,80200,BERNY EN SANTERRE,,49.8665475178,2.85058126397,90,,Berny-en-Santerre,Berny-en-Santerre,80,Somme,32,Hauts-de-France
80092,BERTANGLES,80260,BERTANGLES,,49.9733253681,2.29150476832,92,,Bertangles,Bertangles,80,Somme,32,Hauts-de-France
80093,BERTEAUCOURT LES DAMES,80850,BERTEAUCOURT LES DAMES,,50.0417860599,2.15714009582,93,,Berteaucourt-les-Dames,Berteaucourt-les-Dames,80,Somme,32,Hauts-de-France
80094,BERTEAUCOURT LES THENNES,80110,BERTEAUCOURT LES THENNES,,49.8198024582,2.46222741859,94,,Berteaucourt-lès-Thennes,Berteaucourt-lès-Thennes,80,Somme,32,Hauts-de-France
80095,BERTRANCOURT,80560,BERTRANCOURT,,50.1008193458,2.56424991532,95,,Bertrancourt,Bertrancourt,80,Somme,32,Hauts-de-France
80096,BETHENCOURT SUR MER,80130,BETHENCOURT SUR MER,,50.0753883023,1.49950861954,96,,Béthencourt-sur-Mer,Béthencourt-sur-Mer,80,Somme,32,Hauts-de-France
80097,BETHENCOURT SUR SOMME,80190,BETHENCOURT SUR SOMME,,49.7926456622,2.95729064952,97,,Béthencourt-sur-Somme,Béthencourt-sur-Somme,80,Somme,32,Hauts-de-France
80098,BETTEMBOS,80290,BETTEMBOS,,49.8039989409,1.88150562545,98,,Bettembos,Bettembos,80,Somme,32,Hauts-de-France
80099,BETTENCOURT RIVIERE,80270,BETTENCOURT RIVIERE,,49.9927948123,1.97764245774,99,,Bettencourt-Rivière,Bettencourt-Rivière,80,Somme,32,Hauts-de-France
80100,BETTENCOURT ST OUEN,80610,BETTENCOURT ST OUEN,,50.0175313479,2.1222553636,100,,Bettencourt-Saint-Ouen,Bettencourt-Saint-Ouen,80,Somme,32,Hauts-de-France
80101,BEUVRAIGNES,80700,BEUVRAIGNES,,49.6381423232,2.77599755384,101,,Beuvraignes,Beuvraignes,80,Somme,32,Hauts-de-France
80102,BIACHES,80200,BIACHES,,49.9232199949,2.90026502306,102,,Biaches,Biaches,80,Somme,32,Hauts-de-France
80103,BIARRE,80190,BIARRE,,49.7219365982,2.88347659753,103,,Biarre,Biarre,80,Somme,32,Hauts-de-France
80104,BIENCOURT,80140,BIENCOURT,,49.9765483464,1.69314277635,104,,Biencourt,Biencourt,80,Somme,32,Hauts-de-France
80105,BILLANCOURT,80190,BILLANCOURT,,49.7384473061,2.8992050536,105,,Billancourt,Billancourt,80,Somme,32,Hauts-de-France
80106,BLANGY SOUS POIX,80290,BLANGY SOUS POIX,,49.7660915332,2.00447254772,106,,Blangy-sous-Poix,Blangy-sous-Poix,80,Somme,32,Hauts-de-France
80107,BLANGY TRONVILLE,80440,BLANGY TRONVILLE,,49.8695433545,2.43039869559,107,,Blangy-Tronville,Blangy-Tronville,80,Somme,32,Hauts-de-France
80108,BOISBERGUES,80600,BOISBERGUES,,50.153116145,2.22236323766,108,,Boisbergues,Boisbergues,80,Somme,32,Hauts-de-France
80109,LE BOISLE,80150,LE BOISLE,,50.2683509282,1.97366129203,109,Le,Boisle,Le Boisle,80,Somme,32,Hauts-de-France
80110,BOISMONT,80230,BOISMONT,,50.1579086414,1.6734332027,110,,Boismont,Boismont,80,Somme,32,Hauts-de-France
80112,BONNAY,80800,BONNAY,,49.9429089907,2.50691249555,112,,Bonnay,Bonnay,80,Somme,32,Hauts-de-France
80113,BONNEVILLE,80670,BONNEVILLE,,50.0778725403,2.26234835981,113,,Bonneville,Bonneville,80,Somme,32,Hauts-de-France
80114,BOSQUEL,80160,LE BOSQUEL,,49.737214267,2.21306482831,114,,Bosquel,Bosquel,80,Somme,32,Hauts-de-France
80115,BOUCHAVESNES BERGEN,80200,BOUCHAVESNES BERGEN,,49.9924551007,2.92163834053,115,,Bouchavesnes-Bergen,Bouchavesnes-Bergen,80,Somme,32,Hauts-de-France
80116,BOUCHOIR,80910,BOUCHOIR,,49.7455243243,2.67870575145,116,,Bouchoir,Bouchoir,80,Somme,32,Hauts-de-France
80117,BOUCHON,80830,BOUCHON,,50.0402317999,2.0267416793,117,,Bouchon,Bouchon,80,Somme,32,Hauts-de-France
80118,BOUFFLERS,80150,BOUFFLERS,,50.2589447905,2.02128865897,118,,Boufflers,Boufflers,80,Somme,32,Hauts-de-France
80119,BOUGAINVILLE,80540,BOUGAINVILLE,,49.8646198676,2.04693888376,119,,Bougainville,Bougainville,80,Somme,32,Hauts-de-France
80120,BOUILLANCOURT EN SERY,80220,BOUILLANCOURT EN SERY,,49.9600031644,1.63026565334,120,,Bouillancourt-en-Séry,Bouillancourt-en-Séry,80,Somme,32,Hauts-de-France
80121,BOUILLANCOURT LA BATAILLE,80500,BOUILLANCOURT LA BATAILLE,,49.6969888721,2.52667991931,121,,Bouillancourt-la-Bataille,Bouillancourt-la-Bataille,80,Somme,32,Hauts-de-France
80122,BOUQUEMAISON,80600,BOUQUEMAISON,,50.215619756,2.3391533708,122,,Bouquemaison,Bouquemaison,80,Somme,32,Hauts-de-France
80123,BOURDON,80310,BOURDON,,49.9902026288,2.08654699872,123,,Bourdon,Bourdon,80,Somme,32,Hauts-de-France
80124,BOURSEVILLE,80130,BOURSEVILLE,,50.1053525119,1.52189150446,124,,Bourseville,Bourseville,80,Somme,32,Hauts-de-France
80125,BOUSSICOURT,80500,BOUSSICOURT,,49.6993761451,2.57204034284,125,,Boussicourt,Boussicourt,80,Somme,32,Hauts-de-France
80126,BOUTTENCOURT,80220,BOUTTENCOURT,,49.9505843914,1.61002732845,126,,Bouttencourt,Bouttencourt,80,Somme,32,Hauts-de-France
80127,BOUVAINCOURT SUR BRESLE,80220,BOUVAINCOURT SUR BRESLE,,50.0359241083,1.49611906465,127,,Bouvaincourt-sur-Bresle,Bouvaincourt-sur-Bresle,80,Somme,32,Hauts-de-France
80128,BOUVINCOURT EN VERMANDOIS,80200,BOUVINCOURT EN VERMANDOIS,,49.8908963311,3.04415647131,128,,Bouvincourt-en-Vermandois,Bouvincourt-en-Vermandois,80,Somme,32,Hauts-de-France
80129,BOUZINCOURT,80300,BOUZINCOURT,,50.0233961176,2.61186283447,129,,Bouzincourt,Bouzincourt,80,Somme,32,Hauts-de-France
80130,BOVELLES,80540,BOVELLES,,49.8897442971,2.14077801303,130,,Bovelles,Bovelles,80,Somme,32,Hauts-de-France
80131,BOVES,80440,BOVES,,49.8416983547,2.38329938431,131,,Boves,Boves,80,Somme,32,Hauts-de-France
80132,BRACHES,80110,BRACHES,,49.7302364752,2.49897574736,132,,Braches,Braches,80,Somme,32,Hauts-de-France
80133,BRAILLY CORNEHOTTE,80150,BRAILLY CORNEHOTTE,,50.2243173522,1.95816617039,133,,Brailly-Cornehotte,Brailly-Cornehotte,80,Somme,32,Hauts-de-France
80134,BRASSY,80160,BRASSY,,49.731490735,2.05041493122,134,,Brassy,Brassy,80,Somme,32,Hauts-de-France
80135,BRAY LES MAREUIL,80580,BRAY LES MAREUIL,,50.0514877131,1.84854806423,135,,Bray-lès-Mareuil,Bray-lès-Mareuil,80,Somme,32,Hauts-de-France
80136,BRAY SUR SOMME,80340,BRAY SUR SOMME,,49.9549092786,2.72156545459,136,,Bray-sur-Somme,Bray-sur-Somme,80,Somme,32,Hauts-de-France
80137,BREILLY,80470,BREILLY,,49.9293462442,2.16426343051,137,,Breilly,Breilly,80,Somme,32,Hauts-de-France
80138,BRESLE,80300,BRESLE,,49.9903903024,2.54921483681,138,,Bresle,Bresle,80,Somme,32,Hauts-de-France
80139,BREUIL,80400,BREUIL,,49.7369102712,2.94902548563,139,,Breuil,Breuil,80,Somme,32,Hauts-de-France
80140,BREVILLERS,80600,BREVILLERS,,50.2154961787,2.38099376093,140,,Brévillers,Brévillers,80,Somme,32,Hauts-de-France
80141,BRIE,80200,BRIE,,49.8792146401,2.94219919027,141,,Brie,Brie,80,Somme,32,Hauts-de-France
80142,BRIQUEMESNIL FLOXICOURT,80540,BRIQUEMESNIL FLOXICOURT,,49.8878430503,2.08048930837,142,,Briquemesnil-Floxicourt,Briquemesnil-Floxicourt,80,Somme,32,Hauts-de-France
80143,BROCOURT,80430,BROCOURT,,49.8511224516,1.81975446597,143,,Brocourt,Brocourt,80,Somme,32,Hauts-de-France
80144,BROUCHY,80400,BROUCHY,,49.7163704547,3.0972242101,144,,Brouchy,Brouchy,80,Somme,32,Hauts-de-France
80145,BRUCAMPS,80690,BRUCAMPS,,50.071318862,2.05279274058,145,,Brucamps,Brucamps,80,Somme,32,Hauts-de-France
80146,BRUTELLES,80230,BRUTELLES,,50.1369120037,1.52031200615,146,,Brutelles,Brutelles,80,Somme,32,Hauts-de-France
80147,BUIGNY L ABBE,80132,BUIGNY L ABBE,,50.0970703455,1.94454979441,147,,Buigny-l'Abbé,Buigny-l'Abbé,80,Somme,32,Hauts-de-France
80148,BUIGNY LES GAMACHES,80220,BUIGNY LES GAMACHES,,50.0191959545,1.56883948114,148,,Buigny-lès-Gamaches,Buigny-lès-Gamaches,80,Somme,32,Hauts-de-France
80149,BUIGNY ST MACLOU,80132,BUIGNY ST MACLOU,,50.1528695248,1.81999601533,149,,Buigny-Saint-Maclou,Buigny-Saint-Maclou,80,Somme,32,Hauts-de-France
80150,BUIRE COURCELLES,80200,BUIRE COURCELLES,,49.9332857649,3.00805695151,150,,Buire-Courcelles,Buire-Courcelles,80,Somme,32,Hauts-de-France
80151,BUIRE SUR L ANCRE,80300,BUIRE SUR L ANCRE,,49.9748538247,2.59295688367,151,,Buire-sur-l'Ancre,Buire-sur-l'Ancre,80,Somme,32,Hauts-de-France
80152,BUS LA MESIERE,80700,BUS LA MESIERE,,49.6380331546,2.71416048413,152,,Bus-la-Mésière,Bus-la-Mésière,80,Somme,32,Hauts-de-France
80153,BUS LES ARTOIS,80560,BUS LES ARTOIS,,50.1041450008,2.54068551705,153,,Bus-lès-Artois,Bus-lès-Artois,80,Somme,32,Hauts-de-France
80154,BUSSU,80200,BUSSU,,49.946447754,2.97567757191,154,,Bussu,Bussu,80,Somme,32,Hauts-de-France
80155,BUSSUS BUSSUEL,80135,BUSSUS BUSSUEL,,50.1099366485,2.00778988725,155,,Bussus-Bussuel,Bussus-Bussuel,80,Somme,32,Hauts-de-France
80156,BUSSY LES DAOURS,80800,BUSSY LES DAOURS,,49.9124884023,2.41234649505,156,,Bussy-lès-Daours,Bussy-lès-Daours,80,Somme,32,Hauts-de-France
80157,BUSSY LES POIX,80290,BUSSY LES POIX,,49.8174206612,2.01371818079,157,,Bussy-lès-Poix,Bussy-lès-Poix,80,Somme,32,Hauts-de-France
80158,BUVERCHY,80400,BUVERCHY,,49.7274888762,2.97016764633,158,,Buverchy,Buverchy,80,Somme,32,Hauts-de-France
80159,CACHY,80800,CACHY,,49.8574900096,2.47680531465,159,,Cachy,Cachy,80,Somme,32,Hauts-de-France
80160,CAGNY,80330,CAGNY,,49.8505763421,2.33749287161,160,,Cagny,Cagny,80,Somme,32,Hauts-de-France
80161,CAHON,80132,CAHON GOUY,,50.1196980622,1.7290169639,161,,Cahon,Cahon,80,Somme,32,Hauts-de-France
80162,CAIX,80170,CAIX,,49.8107478766,2.64567730523,162,,Caix,Caix,80,Somme,32,Hauts-de-France
80163,CAMBRON,80132,CAMBRON,,50.1092631325,1.7656573387,163,,Cambron,Cambron,80,Somme,32,Hauts-de-France
80164,CAMON,80450,CAMON,,49.9013220583,2.36320742783,164,,Camon,Camon,80,Somme,32,Hauts-de-France
80165,CAMPS EN AMIENOIS,80540,CAMPS EN AMIENOIS,,49.8837326113,1.9644871607,165,,Camps-en-Amiénois,Camps-en-Amiénois,80,Somme,32,Hauts-de-France
80166,CANAPLES,80670,CANAPLES,,50.0613505846,2.2308855769,166,,Canaples,Canaples,80,Somme,32,Hauts-de-France
80167,CANCHY,80150,CANCHY,,50.1871744967,1.87350289906,167,,Canchy,Canchy,80,Somme,32,Hauts-de-France
80168,CANDAS,80750,CANDAS,,50.0996928866,2.28044782688,168,,Candas,Candas,80,Somme,32,Hauts-de-France
80169,CANNESSIERES,80140,CANNESSIERES,,49.9357156714,1.76566722724,169,,Cannessières,Cannessières,80,Somme,32,Hauts-de-France
80170,CANTIGNY,80500,CANTIGNY,,49.6651675487,2.4899456786,170,,Cantigny,Cantigny,80,Somme,32,Hauts-de-France
80171,CAOURS,80132,CAOURS,,50.1280550287,1.88114339899,171,,Caours,Caours,80,Somme,32,Hauts-de-France
80172,CAPPY,80340,CAPPY,,49.9256314774,2.76317841426,172,,Cappy,Cappy,80,Somme,32,Hauts-de-France
80173,CARDONNETTE,80260,CARDONNETTE,,49.9521802648,2.35601706384,173,,Cardonnette,Cardonnette,80,Somme,32,Hauts-de-France
80174,LE CARDONNOIS,80500,LE CARDONNOIS,,49.6311653117,2.48308861146,174,Le,Cardonnois,Le Cardonnois,80,Somme,32,Hauts-de-France
80175,CARNOY,80300,CARNOY,,49.9863585561,2.75529540341,175,,Carnoy,Carnoy,80,Somme,32,Hauts-de-France
80176,CARREPUIS,80700,CARREPUIS,,49.7045602877,2.81927389833,176,,Carrépuis,Carrépuis,80,Somme,32,Hauts-de-France
80177,CARTIGNY,80200,CARTIGNY,,49.9086884167,3.0172741613,177,,Cartigny,Cartigny,80,Somme,32,Hauts-de-France
80179,CAULIERES,80290,CAULIERES,,49.7867766855,1.89908479868,179,,Caulières,Caulières,80,Somme,32,Hauts-de-France
80180,CAVILLON,80310,CAVILLON,,49.9182245477,2.07548412693,180,,Cavillon,Cavillon,80,Somme,32,Hauts-de-France
80181,CAYEUX EN SANTERRE,80800,CAYEUX EN SANTERRE,,49.8125645731,2.60583040653,181,,Cayeux-en-Santerre,Cayeux-en-Santerre,80,Somme,32,Hauts-de-France
80182,CAYEUX SUR MER,80410,CAYEUX SUR MER,,50.1760397062,1.51571510783,182,,Cayeux-sur-Mer,Cayeux-sur-Mer,80,Somme,32,Hauts-de-France
80183,CERISY BULEUX,80140,CERISY BULEUX,,49.9731167582,1.73718329436,183,,Cerisy-Buleux,Cerisy-Buleux,80,Somme,32,Hauts-de-France
80184,CERISY,80800,CERISY,,49.8960525019,2.6224408332,184,,Cerisy,Cerisy,80,Somme,32,Hauts-de-France
80185,CHAMPIEN,80700,CHAMPIEN,,49.6958546221,2.8583583655,185,,Champien,Champien,80,Somme,32,Hauts-de-France
80186,CHAULNES,80320,CHAULNES,,49.8169359721,2.80153906029,186,,Chaulnes,Chaulnes,80,Somme,32,Hauts-de-France
80187,LA CHAUSSEE TIRANCOURT,80310,LA CHAUSSEE TIRANCOURT,,49.9611459637,2.16746798026,187,La,Chaussée-Tirancourt,La Chaussée-Tirancourt,80,Somme,32,Hauts-de-France
80188,CHAUSSOY EPAGNY,80250,CHAUSSOY EPAGNY,,49.7215007115,2.32882055299,188,,Chaussoy-Epagny,Chaussoy-Epagny,80,Somme,32,Hauts-de-France
80189,LA CHAVATTE,80700,LA CHAVATTE,,49.7556683271,2.76426883905,189,La,Chavatte,La Chavatte,80,Somme,32,Hauts-de-France
80190,CHEPY,80210,CHEPY,,50.0644802332,1.64325361744,190,,Chépy,Chépy,80,Somme,32,Hauts-de-France
80191,CHILLY,80170,CHILLY,,49.7964728556,2.76983345843,191,,Chilly,Chilly,80,Somme,32,Hauts-de-France
80192,CHIPILLY,80800,CHIPILLY,,49.9179353408,2.65156030414,192,,Chipilly,Chipilly,80,Somme,32,Hauts-de-France
80193,CHIRMONT,80250,CHIRMONT,,49.7148777519,2.37183697001,193,,Chirmont,Chirmont,80,Somme,32,Hauts-de-France
80194,CHUIGNES,80340,CHUIGNES,,49.8980530703,2.75163038239,194,,Chuignes,Chuignes,80,Somme,32,Hauts-de-France
80195,CHUIGNOLLES,80340,CHUIGNOLLES,,49.9016436586,2.72842552423,195,,Chuignolles,Chuignolles,80,Somme,32,Hauts-de-France
80196,CITERNE,80490,CITERNE,,49.9790065102,1.81690705354,196,,Citerne,Citerne,80,Somme,32,Hauts-de-France
80197,CIZANCOURT,80200,CIZANCOURT,,49.8401313107,2.91812526703,197,,Cizancourt,Cizancourt,80,Somme,32,Hauts-de-France
80198,CLAIRY SAULCHOIX,80540,CLAIRY SAULCHOIX,,49.8546987793,2.1678833231,198,,Clairy-Saulchoix,Clairy-Saulchoix,80,Somme,32,Hauts-de-France
80199,CLERY SUR SOMME,80200,CLERY SUR SOMME,,49.9622243429,2.88668323143,199,,Cléry-sur-Somme,Cléry-sur-Somme,80,Somme,32,Hauts-de-France
80200,COCQUEREL,80510,COCQUEREL,,50.0556210694,1.96022632545,200,,Cocquerel,Cocquerel,80,Somme,32,Hauts-de-France
80201,COIGNEUX,80560,COIGNEUX,,50.1301013343,2.55612696272,201,,Coigneux,Coigneux,80,Somme,32,Hauts-de-France
80202,COISY,80260,COISY,,49.9654599063,2.33299519676,202,,Coisy,Coisy,80,Somme,32,Hauts-de-France
80203,COLINCAMPS,80560,COLINCAMPS,,50.1015546044,2.60960193283,203,,Colincamps,Colincamps,80,Somme,32,Hauts-de-France
80204,COMBLES,80360,COMBLES,,50.0100855412,2.87396008715,204,,Combles,Combles,80,Somme,32,Hauts-de-France
80205,CONDE FOLIE,80890,CONDE FOLIE,,50.0036692139,2.02115271491,205,,Condé-Folie,Condé-Folie,80,Somme,32,Hauts-de-France
80206,CONTALMAISON,80300,CONTALMAISON,,50.0293857303,2.73562069227,206,,Contalmaison,Contalmaison,80,Somme,32,Hauts-de-France
80207,CONTAY,80560,CONTAY,,50.0098213909,2.46810358617,207,,Contay,Contay,80,Somme,32,Hauts-de-France
80208,CONTEVILLE,80370,CONTEVILLE,,50.1794083006,2.06745109886,208,,Conteville,Conteville,80,Somme,32,Hauts-de-France
80209,CONTOIRE,80500,CONTOIRE HAMEL,,49.7265282008,2.5554571775,209,,Contoire,Contoire,80,Somme,32,Hauts-de-France
80210,CONTRE,80160,CONTRE,,49.7457278213,2.08802522274,210,,Contre,Contre,80,Somme,32,Hauts-de-France
80211,CONTY,80160,CONTY,,49.7503380472,2.14458684266,211,,Conty,Conty,80,Somme,32,Hauts-de-France
80211,CONTY,80160,CONTY,WAILLY,49.7503380472,2.14458684266,211,,Conty,Conty,80,Somme,32,Hauts-de-France
80212,CORBIE,80800,CORBIE,,49.9202042489,2.49498506906,212,,Corbie,Corbie,80,Somme,32,Hauts-de-France
80213,COTTENCHY,80440,COTTENCHY,,49.8071878667,2.36831335799,213,,Cottenchy,Cottenchy,80,Somme,32,Hauts-de-France
80214,COULLEMELLE,80250,COULLEMELLE,,49.6700657392,2.42527413369,214,,Coullemelle,Coullemelle,80,Somme,32,Hauts-de-France
80215,COULONVILLERS,80135,COULONVILLERS,,50.1533593307,2.01779110605,215,,Coulonvillers,Coulonvillers,80,Somme,32,Hauts-de-France
80216,COURCELETTE,80300,COURCELETTE,,50.0596663352,2.74385000997,216,,Courcelette,Courcelette,80,Somme,32,Hauts-de-France
80217,COURCELLES AU BOIS,80560,COURCELLES AU BOIS,,50.1053551298,2.58253392704,217,,Courcelles-au-Bois,Courcelles-au-Bois,80,Somme,32,Hauts-de-France
80218,COURCELLES SOUS MOYENCOURT,80290,COURCELLES SOUS MOYENCOURT,,49.8097148289,2.04154408468,218,,Courcelles-sous-Moyencourt,Courcelles-sous-Moyencourt,80,Somme,32,Hauts-de-France
80219,COURCELLES SOUS THOIX,80160,COURCELLES SOUS THOIX,,49.7211108706,2.07600122174,219,,Courcelles-sous-Thoix,Courcelles-sous-Thoix,80,Somme,32,Hauts-de-France
80220,COURTEMANCHE,80500,COURTEMANCHE,,49.6644829732,2.54537456166,220,,Courtemanche,Courtemanche,80,Somme,32,Hauts-de-France
80221,CRAMONT,80370,CRAMONT,,50.1529953582,2.04893639433,221,,Cramont,Cramont,80,Somme,32,Hauts-de-France
80222,CRECY EN PONTHIEU,80150,CRECY EN PONTHIEU,,50.2402128609,1.84519481744,222,,Crécy-en-Ponthieu,Crécy-en-Ponthieu,80,Somme,32,Hauts-de-France
80222,CRECY EN PONTHIEU,80150,CRECY EN PONTHIEU,MARCHEVILLE,50.2402128609,1.84519481744,222,,Crécy-en-Ponthieu,Crécy-en-Ponthieu,80,Somme,32,Hauts-de-France
80223,CREMERY,80700,CREMERY,,49.7432112752,2.82399385307,223,,Crémery,Crémery,80,Somme,32,Hauts-de-France
80224,CRESSY OMENCOURT,80190,CRESSY OMENCOURT,,49.7175340388,2.90771256914,224,,Cressy-Omencourt,Cressy-Omencourt,80,Somme,32,Hauts-de-France
80225,CREUSE,80480,CREUSE,,49.8354296126,2.15949387069,225,,Creuse,Creuse,80,Somme,32,Hauts-de-France
80226,CROIX MOLIGNEAUX,80400,CROIX MOLIGNEAUX,,49.8196677644,3.00036517518,226,,Croix-Moligneaux,Croix-Moligneaux,80,Somme,32,Hauts-de-France
80227,CROIXRAULT,80290,CROIXRAULT,,49.7945025614,1.98646033146,227,,Croixrault,Croixrault,80,Somme,32,Hauts-de-France
80228,LE CROTOY,80550,LE CROTOY,,50.2439123347,1.62316246487,228,Le,Crotoy,Le Crotoy,80,Somme,32,Hauts-de-France
80229,CROUY ST PIERRE,80310,CROUY ST PIERRE,,49.9600035643,2.09312731925,229,,Crouy-Saint-Pierre,Crouy-Saint-Pierre,80,Somme,32,Hauts-de-France
80229,CROUY ST PIERRE,80310,CROUY ST PIERRE,ST PIERRE A GOUY,49.9600035643,2.09312731925,229,,Crouy-Saint-Pierre,Crouy-Saint-Pierre,80,Somme,32,Hauts-de-France
80230,CURCHY,80190,CURCHY,,49.7790671802,2.86870993814,230,,Curchy,Curchy,80,Somme,32,Hauts-de-France
80230,CURCHY,80190,CURCHY,MANICOURT,49.7790671802,2.86870993814,230,,Curchy,Curchy,80,Somme,32,Hauts-de-France
80231,CURLU,80360,CURLU,,49.968391929,2.81850573633,231,,Curlu,Curlu,80,Somme,32,Hauts-de-France
80232,DAMERY,80700,DAMERY,,49.7307619763,2.73676908988,232,,Damery,Damery,80,Somme,32,Hauts-de-France
80233,DANCOURT POPINCOURT,80700,DANCOURT POPINCOURT,,49.6656361269,2.73132114771,233,,Dancourt-Popincourt,Dancourt-Popincourt,80,Somme,32,Hauts-de-France
80234,DAOURS,80800,DAOURS,,49.9037514357,2.43946725937,234,,Daours,Daours,80,Somme,32,Hauts-de-France
80235,DARGNIES,80570,DARGNIES,,50.0427980747,1.52971446889,235,,Dargnies,Dargnies,80,Somme,32,Hauts-de-France
80236,DAVENESCOURT,80500,DAVENESCOURT,,49.7141857195,2.5965399646,236,,Davenescourt,Davenescourt,80,Somme,32,Hauts-de-France
80237,DEMUIN,80110,DEMUIN,,49.8163771889,2.53950066337,237,,Démuin,Démuin,80,Somme,32,Hauts-de-France
80238,DERNANCOURT,80300,DERNANCOURT,,49.9790238036,2.6233494587,238,,Dernancourt,Dernancourt,80,Somme,32,Hauts-de-France
80239,DEVISE,80200,DEVISE,,49.8511316053,3.00863370114,239,,Devise,Devise,80,Somme,32,Hauts-de-France
80240,DOINGT,80200,DOINGT FLAMICOURT,,49.9231785362,2.96256193239,240,,Doingt,Doingt,80,Somme,32,Hauts-de-France
80241,DOMART EN PONTHIEU,80620,DOMART EN PONTHIEU,,50.0757557319,2.12318895093,241,,Domart-en-Ponthieu,Domart-en-Ponthieu,80,Somme,32,Hauts-de-France
80242,DOMART SUR LA LUCE,80110,DOMART SUR LA LUCE,,49.8261059285,2.48829178135,242,,Domart-sur-la-Luce,Domart-sur-la-Luce,80,Somme,32,Hauts-de-France
80243,DOMESMONT,80370,DOMESMONT,,50.1201839274,2.14393813299,243,,Domesmont,Domesmont,80,Somme,32,Hauts-de-France
80244,DOMINOIS,80120,DOMINOIS,,50.32820704,1.84903188251,244,,Dominois,Dominois,80,Somme,32,Hauts-de-France
80245,DOMLEGER LONGVILLERS,80370,DOMLEGER LONGVILLERS,,50.155006107,2.08731337577,245,,Domléger-Longvillers,Domléger-Longvillers,80,Somme,32,Hauts-de-France
80245,DOMLEGER LONGVILLERS,80370,DOMLEGER LONGVILLERS,LONGVILLERS,50.155006107,2.08731337577,245,,Domléger-Longvillers,Domléger-Longvillers,80,Somme,32,Hauts-de-France
80246,DOMMARTIN,80440,DOMMARTIN,,49.7953023134,2.40134941683,246,,Dommartin,Dommartin,80,Somme,32,Hauts-de-France
80247,DOMPIERRE BECQUINCOURT,80980,DOMPIERRE BECQUINCOURT,,49.9109218562,2.80675580666,247,,Dompierre-Becquincourt,Dompierre-Becquincourt,80,Somme,32,Hauts-de-France
80248,DOMPIERRE SUR AUTHIE,80150,DOMPIERRE SUR AUTHIE,,50.2951382167,1.92084016717,248,,Dompierre-sur-Authie,Dompierre-sur-Authie,80,Somme,32,Hauts-de-France
80249,DOMQUEUR,80620,DOMQUEUR,,50.1135608608,2.05150566393,249,,Domqueur,Domqueur,80,Somme,32,Hauts-de-France
80250,DOMVAST,80150,DOMVAST,,50.2018721228,1.90447781648,250,,Domvast,Domvast,80,Somme,32,Hauts-de-France
80251,DOUDELAINVILLE,80140,DOUDELAINVILLE,,50.000881757,1.76404331717,251,,Doudelainville,Doudelainville,80,Somme,32,Hauts-de-France
80252,DOUILLY,80400,DOUILLY,,49.7969167916,3.05834813816,252,,Douilly,Douilly,80,Somme,32,Hauts-de-France
80253,DOULLENS,80600,DOULLENS,,50.1631890421,2.347176744,253,,Doullens,Doullens,80,Somme,32,Hauts-de-France
80256,DREUIL LES AMIENS,80470,DREUIL LES AMIENS,,49.915981632,2.22637033069,256,,Dreuil-lès-Amiens,Dreuil-lès-Amiens,80,Somme,32,Hauts-de-France
80258,DRIENCOURT,80240,DRIENCOURT,,49.9575231361,3.00524852659,258,,Driencourt,Driencourt,80,Somme,32,Hauts-de-France
80259,DROMESNIL,80640,DROMESNIL,,49.8773914061,1.86918109607,259,,Dromesnil,Dromesnil,80,Somme,32,Hauts-de-France
80260,DRUCAT,80132,DRUCAT,,50.1459729311,1.86130040867,260,,Drucat,Drucat,80,Somme,32,Hauts-de-France
80261,DURY,80480,DURY,,49.8524314645,2.27669568816,261,,Dury,Dury,80,Somme,32,Hauts-de-France
80262,EAUCOURT SUR SOMME,80580,EAUCOURT SUR SOMME,,50.0682542409,1.88826877108,262,,Eaucourt-sur-Somme,Eaucourt-sur-Somme,80,Somme,32,Hauts-de-France
80263,L ECHELLE ST AURIN,80700,L ECHELLE ST AURIN,,49.6903778233,2.71710736022,263,L',Échelle-Saint-Aurin,L'Échelle-Saint-Aurin,80,Somme,32,Hauts-de-France
80264,ECLUSIER VAUX,80340,ECLUSIER VAUX,,49.9511135171,2.79148931118,264,,Éclusier-Vaux,Éclusier-Vaux,80,Somme,32,Hauts-de-France
80265,EMBREVILLE,80570,EMBREVILLE,,50.0329608641,1.55242775238,265,,Embreville,Embreville,80,Somme,32,Hauts-de-France
80266,ENGLEBELMER,80300,ENGLEBELMER,,50.0547930425,2.60796458137,266,,Englebelmer,Englebelmer,80,Somme,32,Hauts-de-France
80267,ENNEMAIN,80200,ENNEMAIN,,49.8401372627,2.95682894761,267,,Ennemain,Ennemain,80,Somme,32,Hauts-de-France
80268,EPAGNE EPAGNETTE,80580,EPAGNE EPAGNETTE,,50.077303233,1.86764272017,268,,Épagne-Épagnette,Épagne-Épagnette,80,Somme,32,Hauts-de-France
80269,EPAUMESNIL,80140,EPAUMESNIL,,49.9140770962,1.85499988847,269,,Épaumesnil,Épaumesnil,80,Somme,32,Hauts-de-France
80270,EPECAMPS,80370,EPECAMPS,,50.1109041034,2.15406528883,270,,Épécamps,Épécamps,80,Somme,32,Hauts-de-France
80271,EPEHY,80740,EPEHY,,50.0062828894,3.13298664943,271,,Épehy,Épehy,80,Somme,32,Hauts-de-France
80272,EPENANCOURT,80190,EPENANCOURT,,49.8202345007,2.92950744804,272,,Épénancourt,Épénancourt,80,Somme,32,Hauts-de-France
80273,EPLESSIER,80290,EPLESSIER,,49.7843306354,1.94046877526,273,,Éplessier,Éplessier,80,Somme,32,Hauts-de-France
80274,EPPEVILLE,80400,EPPEVILLE,,49.737763833,3.04903557364,274,,Eppeville,Eppeville,80,Somme,32,Hauts-de-France
80275,EQUANCOURT,80360,EQUANCOURT,,50.0353500187,3.01428549261,275,,Équancourt,Équancourt,80,Somme,32,Hauts-de-France
80276,EQUENNES ERAMECOURT,80290,EQUENNES ERAMECOURT,,49.7278828186,1.95586225984,276,,Équennes-Éramecourt,Équennes-Éramecourt,80,Somme,32,Hauts-de-France
80276,EQUENNES ERAMECOURT,80290,EQUENNES ERAMECOURT,ERAMECOURT,49.7278828186,1.95586225984,276,,Équennes-Éramecourt,Équennes-Éramecourt,80,Somme,32,Hauts-de-France
80278,ERCHES,80500,ERCHES,,49.7243449482,2.67246538039,278,,Erches,Erches,80,Somme,32,Hauts-de-France
80279,ERCHEU,80400,ERCHEU,,49.7010057712,2.94654183178,279,,Ercheu,Ercheu,80,Somme,32,Hauts-de-France
80280,ERCOURT,80210,ERCOURT,,50.0405703516,1.72408461837,280,,Ercourt,Ercourt,80,Somme,32,Hauts-de-France
80281,ERGNIES,80690,ERGNIES,,50.088353406,2.03216218477,281,,Ergnies,Ergnies,80,Somme,32,Hauts-de-France
80282,ERONDELLE,80580,ERONDELLE,,50.0466779829,1.87770627984,282,,Érondelle,Érondelle,80,Somme,32,Hauts-de-France
80283,ESCLAINVILLERS,80250,ESCLAINVILLERS,,49.6903222746,2.39899082941,283,,Esclainvillers,Esclainvillers,80,Somme,32,Hauts-de-France
80284,ESMERY HALLON,80400,ESMERY HALLON,,49.711331314,3.02468850154,284,,Esmery-Hallon,Esmery-Hallon,80,Somme,32,Hauts-de-France
80285,ESSERTAUX,80160,ESSERTAUX,,49.7465663966,2.25808683879,285,,Essertaux,Essertaux,80,Somme,32,Hauts-de-France
80287,ESTREBOEUF,80230,ESTREBOEUF,,50.153768945,1.62848401612,287,,Estrébœuf,Estrébœuf,80,Somme,32,Hauts-de-France
80288,ESTREES DENIECOURT,80200,ESTREES DENIECOURT,,49.8718517674,2.82257224293,288,,Estrées-Deniécourt,Estrées-Deniécourt,80,Somme,32,Hauts-de-France
80290,ESTREES LES CRECY,80150,ESTREES LES CRECY,,50.2583279205,1.93391857505,290,,Estrées-lès-Crécy,Estrées-lès-Crécy,80,Somme,32,Hauts-de-France
80291,ESTREES SUR NOYE,80250,ESTREES SUR NOYE,,49.7842642884,2.33898041831,291,,Estrées-sur-Noye,Estrées-sur-Noye,80,Somme,32,Hauts-de-France
80292,ETALON,80190,ETALON,,49.760781553,2.84879580561,292,,Étalon,Étalon,80,Somme,32,Hauts-de-France
80293,ETELFAY,80500,ETELFAY,,49.6643512814,2.61085791876,293,,Ételfay,Ételfay,80,Somme,32,Hauts-de-France
80294,ETERPIGNY,80200,ETERPIGNY,,49.8856524019,2.91602886525,294,,Éterpigny,Éterpigny,80,Somme,32,Hauts-de-France
80295,ETINEHEM,80340,ETINEHEM,,49.9355483408,2.68802553545,295,,Étinehem-Méricourt,Étinehem-Méricourt,80,Somme,32,Hauts-de-France
80296,L ETOILE,80830,L ETOILE,,50.0257741586,2.03789491434,296,L',Étoile,L'Étoile,80,Somme,32,Hauts-de-France
80297,ETREJUST,80140,ETREJUST,,49.9117893247,1.88724692625,297,,Étréjust,Étréjust,80,Somme,32,Hauts-de-France
80298,ETRICOURT MANANCOURT,80360,ETRICOURT MANANCOURT,,50.0296469138,2.98220820221,298,,Étricourt-Manancourt,Étricourt-Manancourt,80,Somme,32,Hauts-de-France
80299,LA FALOISE,80250,LA FALOISE,,49.6967150248,2.34026519713,299,La,Faloise,La Faloise,80,Somme,32,Hauts-de-France
80300,FALVY,80190,FALVY,,49.8226904819,2.96264850064,300,,Falvy,Falvy,80,Somme,32,Hauts-de-France
80301,FAMECHON,80290,FAMECHON,,49.7670124378,2.03228812807,301,,Famechon,Famechon,80,Somme,32,Hauts-de-France
80302,FAVEROLLES,80500,FAVEROLLES,,49.6455544304,2.61943483377,302,,Faverolles,Faverolles,80,Somme,32,Hauts-de-France
80303,FAVIERES,80120,FAVIERES,,50.2347245529,1.6686251564,303,,Favières,Favières,80,Somme,32,Hauts-de-France
80304,FAY,80200,FAY,,49.8873496451,2.80237864431,304,,Fay,Fay,80,Somme,32,Hauts-de-France
80305,FERRIERES,80470,FERRIERES,,49.8848976333,2.17848518817,305,,Ferrières,Ferrières,80,Somme,32,Hauts-de-France
80306,FESCAMPS,80500,FESCAMPS,,49.6415538079,2.6866324021,306,,Fescamps,Fescamps,80,Somme,32,Hauts-de-France
80307,FEUILLERES,80200,FEUILLERES,,49.9413358686,2.85151703169,307,,Feuillères,Feuillères,80,Somme,32,Hauts-de-France
80308,FEUQUIERES EN VIMEU,80210,FEUQUIERES EN VIMEU,,50.0586890132,1.60286717803,308,,Feuquières-en-Vimeu,Feuquières-en-Vimeu,80,Somme,32,Hauts-de-France
80310,FIENVILLERS,80750,FIENVILLERS,,50.1178805706,2.23440676149,310,,Fienvillers,Fienvillers,80,Somme,32,Hauts-de-France
80311,FIGNIERES,80500,FIGNIERES,,49.6803491005,2.58545736222,311,,Fignières,Fignières,80,Somme,32,Hauts-de-France
80312,FINS,80360,FINS,,50.0420364674,3.04221057518,312,,Fins,Fins,80,Somme,32,Hauts-de-France
80313,FLAUCOURT,80200,FLAUCOURT,,49.9174280416,2.86789374494,313,,Flaucourt,Flaucourt,80,Somme,32,Hauts-de-France
80314,FLERS,80360,FLERS,,50.0506500725,2.81773522664,314,,Flers,Flers,80,Somme,32,Hauts-de-France
80315,FLERS SUR NOYE,80160,FLERS SUR NOYE,,49.7293333092,2.25757976888,315,,Flers-sur-Noye,Flers-sur-Noye,80,Somme,32,Hauts-de-France
80316,FLESSELLES,80260,FLESSELLES,,50.0032539438,2.25940625975,316,,Flesselles,Flesselles,80,Somme,32,Hauts-de-France
80317,FLEURY,80160,FLEURY,,49.7357232045,2.11453536529,317,,Fleury,Fleury,80,Somme,32,Hauts-de-France
80318,FLIXECOURT,80420,FLIXECOURT,,50.0103212084,2.07964672237,318,,Flixecourt,Flixecourt,80,Somme,32,Hauts-de-France
80319,FLUY,80540,FLUY,,49.8596757003,2.0859623346,319,,Fluy,Fluy,80,Somme,32,Hauts-de-France
80320,FOLIES,80170,FOLIES,,49.7629825224,2.66649413989,320,,Folies,Folies,80,Somme,32,Hauts-de-France
80321,FOLLEVILLE,80250,FOLLEVILLE,,49.6776909768,2.3550476043,321,,Folleville,Folleville,80,Somme,32,Hauts-de-France
80322,FONCHES FONCHETTE,80700,FONCHES FONCHETTE,,49.7761107379,2.82157926223,322,,Fonches-Fonchette,Fonches-Fonchette,80,Somme,32,Hauts-de-France
80324,FONTAINE LE SEC,80140,FONTAINE LE SEC,,49.9436201433,1.80412989344,324,,Fontaine-le-Sec,Fontaine-le-Sec,80,Somme,32,Hauts-de-France
80325,FONTAINE LES CAPPY,80340,FONTAINE LES CAPPY,,49.8992002068,2.77659071025,325,,Fontaine-lès-Cappy,Fontaine-lès-Cappy,80,Somme,32,Hauts-de-France
80326,FONTAINE SOUS MONTDIDIER,80500,FONTAINE SOUS MONTDIDIER,,49.6550724443,2.50539599355,326,,Fontaine-sous-Montdidier,Fontaine-sous-Montdidier,80,Somme,32,Hauts-de-France
80327,FONTAINE SUR MAYE,80150,FONTAINE SUR MAYE,,50.2366154293,1.93030936101,327,,Fontaine-sur-Maye,Fontaine-sur-Maye,80,Somme,32,Hauts-de-France
80328,FONTAINE SUR SOMME,80510,FONTAINE SUR SOMME,,50.0246616547,1.94061931752,328,,Fontaine-sur-Somme,Fontaine-sur-Somme,80,Somme,32,Hauts-de-France
80329,FORCEVILLE,80560,FORCEVILLE EN AMIENOIS,,50.0660087651,2.5554412385,329,,Forceville,Forceville,80,Somme,32,Hauts-de-France
80330,FORCEVILLE EN VIMEU,80140,FORCEVILLE EN VIMEU,,49.9618487041,1.7994141824,330,,Forceville-en-Vimeu,Forceville-en-Vimeu,80,Somme,32,Hauts-de-France
80331,FOREST L ABBAYE,80150,FOREST L ABBAYE,,50.2032978868,1.83242600147,331,,Forest-l'Abbaye,Forest-l'Abbaye,80,Somme,32,Hauts-de-France
80332,FOREST MONTIERS,80120,FOREST MONTIERS,,50.2442234148,1.73665378586,332,,Forest-Montiers,Forest-Montiers,80,Somme,32,Hauts-de-France
80333,FORT MAHON PLAGE,80120,FORT MAHON PLAGE,,50.3450588351,1.5770676415,333,,Fort-Mahon-Plage,Fort-Mahon-Plage,80,Somme,32,Hauts-de-France
80334,FOSSEMANANT,80160,FOSSEMANANT,,49.8021156407,2.1900941827,334,,Fossemanant,Fossemanant,80,Somme,32,Hauts-de-France
80335,FOUCAUCOURT EN SANTERRE,80340,FOUCAUCOURT EN SANTERRE,,49.8766837829,2.76975494054,335,,Foucaucourt-en-Santerre,Foucaucourt-en-Santerre,80,Somme,32,Hauts-de-France
80336,FOUCAUCOURT HORS NESLE,80140,FOUCAUCOURT HORS NESLE,,49.9175841672,1.71782480401,336,,Foucaucourt-Hors-Nesle,Foucaucourt-Hors-Nesle,80,Somme,32,Hauts-de-France
80337,FOUENCAMPS,80440,FOUENCAMPS,,49.8191300337,2.40787983027,337,,Fouencamps,Fouencamps,80,Somme,32,Hauts-de-France
80338,FOUILLOY,80800,FOUILLOY,,49.8905768048,2.50445171042,338,,Fouilloy,Fouilloy,80,Somme,32,Hauts-de-France
80339,FOUQUESCOURT,80170,FOUQUESCOURT,,49.7707802133,2.74741302134,339,,Fouquescourt,Fouquescourt,80,Somme,32,Hauts-de-France
80340,FOURCIGNY,80290,FOURCIGNY,,49.7521286153,1.82506913189,340,,Fourcigny,Fourcigny,80,Somme,32,Hauts-de-France
80341,FOURDRINOY,80310,FOURDRINOY,,49.9240125168,2.10528066329,341,,Fourdrinoy,Fourdrinoy,80,Somme,32,Hauts-de-France
80342,FRAMERVILLE RAINECOURT,80131,FRAMERVILLE RAINECOURT,,49.8633129954,2.72000159396,342,,Framerville-Rainecourt,Framerville-Rainecourt,80,Somme,32,Hauts-de-France
80343,FRAMICOURT,80140,FRAMICOURT,,49.9560141048,1.67720723941,343,,Framicourt,Framicourt,80,Somme,32,Hauts-de-France
80344,FRANCIERES,80690,FRANCIERES,,50.0743738616,1.94581106612,344,,Francières,Francières,80,Somme,32,Hauts-de-France
80345,FRANLEU,80210,FRANLEU,,50.0996882726,1.64455046591,345,,Franleu,Franleu,80,Somme,32,Hauts-de-France
80346,FRANQUEVILLE,80620,FRANQUEVILLE,,50.0953559703,2.0966149018,346,,Franqueville,Franqueville,80,Somme,32,Hauts-de-France
80347,FRANSART,80700,FRANSART,,49.7686241864,2.77384831691,347,,Fransart,Fransart,80,Somme,32,Hauts-de-France
80348,FRANSU,80620,FRANSU,,50.1129742666,2.0902535968,348,,Fransu,Fransu,80,Somme,32,Hauts-de-France
80349,FRANSURES,80160,FRANSURES,,49.7136759827,2.23410813996,349,,Fransures,Fransures,80,Somme,32,Hauts-de-France
80350,FRANVILLERS,80800,FRANVILLERS,,49.9701448943,2.4998796685,350,,Franvillers,Franvillers,80,Somme,32,Hauts-de-France
80351,FRECHENCOURT,80260,FRECHENCOURT,,49.9640407626,2.43689907824,351,,Fréchencourt,Fréchencourt,80,Somme,32,Hauts-de-France
80352,FREMONTIERS,80160,FREMONTIERS,,49.7684546166,2.07402438428,352,,Frémontiers,Frémontiers,80,Somme,32,Hauts-de-France
80353,FRESNES MAZANCOURT,80320,FRESNES MAZANCOURT,,49.8513622783,2.86281953727,353,,Fresnes-Mazancourt,Fresnes-Mazancourt,80,Somme,32,Hauts-de-France
80354,FRESNES TILLOLOY,80140,FRESNES TILLOLOY,,49.9824182223,1.76035909217,354,,Fresnes-Tilloloy,Fresnes-Tilloloy,80,Somme,32,Hauts-de-France
80355,FRESNEVILLE,80140,FRESNEVILLE,,49.887535881,1.83139582116,355,,Fresneville,Fresneville,80,Somme,32,Hauts-de-France
80356,FRESNOY ANDAINVILLE,80140,FRESNOY ANDAINVILLE,,49.9086024009,1.79178711806,356,,Fresnoy-Andainville,Fresnoy-Andainville,80,Somme,32,Hauts-de-France
80357,FRESNOY AU VAL,80290,FRESNOY AU VAL,,49.8374899033,2.05143739704,357,,Fresnoy-au-Val,Fresnoy-au-Val,80,Somme,32,Hauts-de-France
80358,FRESNOY EN CHAUSSEE,80110,FRESNOY EN CHAUSSEE,,49.7722587702,2.58381477214,358,,Fresnoy-en-Chaussée,Fresnoy-en-Chaussée,80,Somme,32,Hauts-de-France
80359,FRESNOY LES ROYE,80700,FRESNOY LES ROYE,,49.7408277684,2.77942583155,359,,Fresnoy-lès-Roye,Fresnoy-lès-Roye,80,Somme,32,Hauts-de-France
80360,FRESSENNEVILLE,80390,FRESSENNEVILLE,,50.0612665592,1.56652824235,360,,Fressenneville,Fressenneville,80,Somme,32,Hauts-de-France
80361,FRETTECUISSE,80140,FRETTECUISSE,,49.9234423718,1.81014711359,361,,Frettecuisse,Frettecuisse,80,Somme,32,Hauts-de-France
80362,FRETTEMEULE,80220,FRETTEMEULE,,49.9995897273,1.63939407213,362,,Frettemeule,Frettemeule,80,Somme,32,Hauts-de-France
80364,FRIAUCOURT,80460,FRIAUCOURT,,50.0873184654,1.47414674764,364,,Friaucourt,Friaucourt,80,Somme,32,Hauts-de-France
80365,FRICAMPS,80290,FRICAMPS,,49.8182138664,1.99055503958,365,,Fricamps,Fricamps,80,Somme,32,Hauts-de-France
80366,FRICOURT,80300,FRICOURT,,49.9963797801,2.71518439467,366,,Fricourt,Fricourt,80,Somme,32,Hauts-de-France
80367,FRISE,80340,FRISE,,49.940092522,2.81429548717,367,,Frise,Frise,80,Somme,32,Hauts-de-France
80368,FRIVILLE ESCARBOTIN,80130,FRIVILLE ESCARBOTIN,,50.0860775469,1.54350789133,368,,Friville-Escarbotin,Friville-Escarbotin,80,Somme,32,Hauts-de-France
80369,FROHEN SUR AUTHIE,80370,FROHEN SUR AUTHIE,,50.2020618045,2.20851468779,369,,Frohen-sur-Authie,Frohen-sur-Authie,80,Somme,32,Hauts-de-France
80369,FROHEN SUR AUTHIE,80370,FROHEN SUR AUTHIE,FROHEN LE PETIT,50.2020618045,2.20851468779,369,,Frohen-sur-Authie,Frohen-sur-Authie,80,Somme,32,Hauts-de-France
80371,FROYELLES,80150,FROYELLES,,50.2231594958,1.9254990977,371,,Froyelles,Froyelles,80,Somme,32,Hauts-de-France
80372,FRUCOURT,80490,FRUCOURT,,49.9984233646,1.81098988886,372,,Frucourt,Frucourt,80,Somme,32,Hauts-de-France
80373,GAMACHES,80220,GAMACHES,,49.9947189836,1.55641684264,373,,Gamaches,Gamaches,80,Somme,32,Hauts-de-France
80374,GAPENNES,80150,GAPENNES,,50.1783068722,1.95226014182,374,,Gapennes,Gapennes,80,Somme,32,Hauts-de-France
80375,GAUVILLE,80290,GAUVILLE,,49.7808911184,1.78753302292,375,,Gauville,Gauville,80,Somme,32,Hauts-de-France
80376,GENTELLES,80800,GENTELLES,,49.8451742087,2.45055227862,376,,Gentelles,Gentelles,80,Somme,32,Hauts-de-France
80377,GEZAINCOURT,80600,GEZAINCOURT,,50.1393411723,2.31166746071,377,,Gézaincourt,Gézaincourt,80,Somme,32,Hauts-de-France
80378,GINCHY,80360,GINCHY,,50.0228499931,2.8362020646,378,,Ginchy,Ginchy,80,Somme,32,Hauts-de-France
80379,GLISY,80440,GLISY,,49.8742994501,2.38728459314,379,,Glisy,Glisy,80,Somme,32,Hauts-de-France
80380,GORENFLOS,80690,GORENFLOS,,50.0915640184,2.0567048062,380,,Gorenflos,Gorenflos,80,Somme,32,Hauts-de-France
80381,GORGES,80370,GORGES,,50.1074433713,2.18310847965,381,,Gorges,Gorges,80,Somme,32,Hauts-de-France
80383,GOYENCOURT,80700,GOYENCOURT,,49.7213872531,2.76641798574,383,,Goyencourt,Goyencourt,80,Somme,32,Hauts-de-France
80384,GRANDCOURT,80300,GRANDCOURT,,50.0695593996,2.71123987118,384,,Grandcourt,Grandcourt,80,Somme,32,Hauts-de-France
80385,GRAND LAVIERS,80132,GRAND LAVIERS,,50.1345136521,1.7882348767,385,,Grand-Laviers,Grand-Laviers,80,Somme,32,Hauts-de-France
80386,GRATIBUS,80500,GRATIBUS,,49.6830172808,2.54937370818,386,,Gratibus,Gratibus,80,Somme,32,Hauts-de-France
80387,GRATTEPANCHE,80680,GRATTEPANCHE,,49.7872512681,2.30610022638,387,,Grattepanche,Grattepanche,80,Somme,32,Hauts-de-France
80388,GREBAULT MESNIL,80140,GREBAULT MESNIL,,50.0243091367,1.72232467954,388,,Grébault-Mesnil,Grébault-Mesnil,80,Somme,32,Hauts-de-France
80389,GRECOURT,80400,GRECOURT,,49.7170432402,2.9868482904,389,,Grécourt,Grécourt,80,Somme,32,Hauts-de-France
80390,GRIVESNES,80250,GRIVESNES,,49.6868458366,2.45819167099,390,,Grivesnes,Grivesnes,80,Somme,32,Hauts-de-France
80391,GRIVILLERS,80700,GRIVILLERS,,49.6592730832,2.70398733576,391,,Grivillers,Grivillers,80,Somme,32,Hauts-de-France
80392,GROUCHES LUCHUEL,80600,GROUCHES LUCHUEL,,50.1821528022,2.38075340423,392,,Grouches-Luchuel,Grouches-Luchuel,80,Somme,32,Hauts-de-France
80393,GRUNY,80700,GRUNY,,49.726399981,2.81653068817,393,,Gruny,Gruny,80,Somme,32,Hauts-de-France
80395,GUERBIGNY,80500,GUERBIGNY,,49.6978131736,2.66525578046,395,,Guerbigny,Guerbigny,80,Somme,32,Hauts-de-France
80396,GUESCHART,80150,GUESCHART,,50.2375835901,2.01124506809,396,,Gueschart,Gueschart,80,Somme,32,Hauts-de-France
80397,GUEUDECOURT,80360,GUEUDECOURT,,50.0600984326,2.84338835573,397,,Gueudecourt,Gueudecourt,80,Somme,32,Hauts-de-France
80399,GUIGNEMICOURT,80540,GUIGNEMICOURT,,49.8712250283,2.16839769767,399,,Guignemicourt,Guignemicourt,80,Somme,32,Hauts-de-France
80400,GUILLAUCOURT,80170,GUILLAUCOURT,,49.838727103,2.63430386505,400,,Guillaucourt,Guillaucourt,80,Somme,32,Hauts-de-France
80401,GUILLEMONT,80360,GUILLEMONT,,50.0110490982,2.82381757133,401,,Guillemont,Guillemont,80,Somme,32,Hauts-de-France
80402,GUIZANCOURT,80290,GUIZANCOURT,,49.7362773037,1.99793273671,402,,Guizancourt,Guizancourt,80,Somme,32,Hauts-de-France
80403,GUYENCOURT SUR NOYE,80250,GUYENCOURT SUR NOYE,,49.776486887,2.36059657656,403,,Guyencourt-sur-Noye,Guyencourt-sur-Noye,80,Somme,32,Hauts-de-France
80404,GUYENCOURT SAULCOURT,80240,GUYENCOURT SAULCOURT,,49.9951657772,3.08079217149,404,,Guyencourt-Saulcourt,Guyencourt-Saulcourt,80,Somme,32,Hauts-de-France
80405,HAILLES,80440,HAILLES,,49.7990959999,2.42882019712,405,,Hailles,Hailles,80,Somme,32,Hauts-de-France
80406,HALLENCOURT,80490,HALLENCOURT,,49.9955608353,1.87489334789,406,,Hallencourt,Hallencourt,80,Somme,32,Hauts-de-France
80406,HALLENCOURT,80490,HALLENCOURT,HOCQUINCOURT,49.9955608353,1.87489334789,406,,Hallencourt,Hallencourt,80,Somme,32,Hauts-de-France
80406,HALLENCOURT,80490,HALLENCOURT,WANEL,49.9955608353,1.87489334789,406,,Hallencourt,Hallencourt,80,Somme,32,Hauts-de-France
80407,HALLIVILLERS,80250,HALLIVILLERS,,49.6961662022,2.2956542546,407,,Hallivillers,Hallivillers,80,Somme,32,Hauts-de-France
80408,HALLOY LES PERNOIS,80670,HALLOY LES PERNOIS,,50.0427875806,2.20337293934,408,,Halloy-lès-Pernois,Halloy-lès-Pernois,80,Somme,32,Hauts-de-France
80409,HALLU,80320,HALLU,,49.7921191949,2.78813620404,409,,Hallu,Hallu,80,Somme,32,Hauts-de-France
80410,HAM,80400,HAM,,49.7534712622,3.07400676417,410,,Ham,Ham,80,Somme,32,Hauts-de-France
80411,LE HAMEL,80800,LE HAMEL,,49.8958798781,2.57277507788,411,Le,Hamel,Le Hamel,80,Somme,32,Hauts-de-France
80412,HAMELET,80800,HAMELET,,49.8961736271,2.53311345885,412,,Hamelet,Hamelet,80,Somme,32,Hauts-de-France
80413,HANCOURT,80240,HANCOURT,,49.9049407174,3.07066226495,413,,Hancourt,Hancourt,80,Somme,32,Hauts-de-France
80414,HANGARD,80110,HANGARD,,49.8336550417,2.50900070848,414,,Hangard,Hangard,80,Somme,32,Hauts-de-France
80415,HANGEST EN SANTERRE,80134,HANGEST EN SANTERRE,,49.7509609311,2.60213270744,415,,Hangest-en-Santerre,Hangest-en-Santerre,80,Somme,32,Hauts-de-France
80416,HANGEST SUR SOMME,80310,HANGEST SUR SOMME,,49.9792304864,2.03810831861,416,,Hangest-sur-Somme,Hangest-sur-Somme,80,Somme,32,Hauts-de-France
80417,HARBONNIERES,80131,HARBONNIERES,,49.8504605449,2.66989455928,417,,Harbonnières,Harbonnières,80,Somme,32,Hauts-de-France
80418,HARDECOURT AUX BOIS,80360,HARDECOURT AUX BOIS,,49.9931432498,2.81396139723,418,,Hardecourt-aux-Bois,Hardecourt-aux-Bois,80,Somme,32,Hauts-de-France
80419,HARGICOURT,80500,HARGICOURT,,49.7135084785,2.52045231855,419,,Hargicourt,Hargicourt,80,Somme,32,Hauts-de-France
80420,HARPONVILLE,80560,HARPONVILLE,,50.0363058361,2.50065859953,420,,Harponville,Harponville,80,Somme,32,Hauts-de-France
80421,HATTENCOURT,80700,HATTENCOURT,,49.7691401362,2.79281334603,421,,Hattencourt,Hattencourt,80,Somme,32,Hauts-de-France
80422,HAUTVILLERS OUVILLE,80132,HAUTVILLERS OUVILLE,,50.1697369629,1.82014998118,422,,Hautvillers-Ouville,Hautvillers-Ouville,80,Somme,32,Hauts-de-France
80423,HAVERNAS,80670,HAVERNAS,,50.0358722384,2.23112667072,423,,Havernas,Havernas,80,Somme,32,Hauts-de-France
80424,HEBECOURT,80680,HEBECOURT,,49.8182332629,2.25969752541,424,,Hébécourt,Hébécourt,80,Somme,32,Hauts-de-France
80425,HEDAUVILLE,80560,HEDAUVILLE,,50.0483166875,2.56989757012,425,,Hédauville,Hédauville,80,Somme,32,Hauts-de-France
80426,HEILLY,80800,HEILLY,,49.9611960311,2.52981039614,426,,Heilly,Heilly,80,Somme,32,Hauts-de-France
80427,HEM HARDINVAL,80600,HEM HARDINVAL,,50.1530964553,2.28736362894,427,,Hem-Hardinval,Hem-Hardinval,80,Somme,32,Hauts-de-France
80428,HEM MONACU,80360,HEM MONACU,,49.9573789855,2.84096165991,428,,Hem-Monacu,Hem-Monacu,80,Somme,32,Hauts-de-France
80429,HENENCOURT,80300,HENENCOURT,,50.0036646587,2.56232754708,429,,Hénencourt,Hénencourt,80,Somme,32,Hauts-de-France
80430,HERBECOURT,80200,HERBECOURT,,49.9225053134,2.83976171493,430,,Herbécourt,Herbécourt,80,Somme,32,Hauts-de-France
80431,HERISSART,80260,HERISSART,,50.0286046388,2.41696809789,431,,Hérissart,Hérissart,80,Somme,32,Hauts-de-France
80432,HERLEVILLE,80340,HERLEVILLE,,49.8611385955,2.75111950285,432,,Herleville,Herleville,80,Somme,32,Hauts-de-France
80433,HERLY,80190,HERLY,,49.7525142116,2.87304441748,433,,Herly,Herly,80,Somme,32,Hauts-de-France
80434,HERVILLY,80240,HERVILLY,,49.9312998449,3.11712136373,434,,Hervilly,Hervilly,80,Somme,32,Hauts-de-France
80435,HESBECOURT,80240,HESBECOURT,,49.9465103458,3.1425154932,435,,Hesbécourt,Hesbécourt,80,Somme,32,Hauts-de-France
80436,HESCAMPS,80290,HESCAMPS,,49.7288200657,1.87751507035,436,,Hescamps,Hescamps,80,Somme,32,Hauts-de-France
80436,HESCAMPS,80290,HESCAMPS,AGNIERES,49.7288200657,1.87751507035,436,,Hescamps,Hescamps,80,Somme,32,Hauts-de-France
80436,HESCAMPS,80290,HESCAMPS,FRETTEMOLLE,49.7288200657,1.87751507035,436,,Hescamps,Hescamps,80,Somme,32,Hauts-de-France
80436,HESCAMPS,80290,HESCAMPS,SOUPLICOURT,49.7288200657,1.87751507035,436,,Hescamps,Hescamps,80,Somme,32,Hauts-de-France
80437,HEUCOURT CROQUOISON,80270,HEUCOURT CROQUOISON,,49.9301375227,1.88243400291,437,,Heucourt-Croquoison,Heucourt-Croquoison,80,Somme,32,Hauts-de-France
80438,HEUDICOURT,80122,HEUDICOURT,,50.0270418566,3.09022844642,438,,Heudicourt,Heudicourt,80,Somme,32,Hauts-de-France
80439,HEUZECOURT,80370,HEUZECOURT,,50.1654636247,2.16872936598,439,,Heuzecourt,Heuzecourt,80,Somme,32,Hauts-de-France
80440,HIERMONT,80370,HIERMONT,,50.1998137547,2.07191019606,440,,Hiermont,Hiermont,80,Somme,32,Hauts-de-France
80442,HOMBLEUX,80400,HOMBLEUX,,49.743120483,2.99136474689,442,,Hombleux,Hombleux,80,Somme,32,Hauts-de-France
80443,HORNOY LE BOURG,80640,HORNOY LE BOURG,,49.8474299808,1.90289897698,443,,Hornoy-le-Bourg,Hornoy-le-Bourg,80,Somme,32,Hauts-de-France
80443,HORNOY LE BOURG,80640,HORNOY LE BOURG,BOISRAULT,49.8474299808,1.90289897698,443,,Hornoy-le-Bourg,Hornoy-le-Bourg,80,Somme,32,Hauts-de-France
80443,HORNOY LE BOURG,80640,HORNOY LE BOURG,GOUY L HOPITAL,49.8474299808,1.90289897698,443,,Hornoy-le-Bourg,Hornoy-le-Bourg,80,Somme,32,Hauts-de-France
80443,HORNOY LE BOURG,80640,HORNOY LE BOURG,LINCHEUX HALLIVILLERS,49.8474299808,1.90289897698,443,,Hornoy-le-Bourg,Hornoy-le-Bourg,80,Somme,32,Hauts-de-France
80443,HORNOY LE BOURG,80640,HORNOY LE BOURG,ORIVAL,49.8474299808,1.90289897698,443,,Hornoy-le-Bourg,Hornoy-le-Bourg,80,Somme,32,Hauts-de-France
80443,HORNOY LE BOURG,80640,HORNOY LE BOURG,SELINCOURT,49.8474299808,1.90289897698,443,,Hornoy-le-Bourg,Hornoy-le-Bourg,80,Somme,32,Hauts-de-France
80443,HORNOY LE BOURG,80640,HORNOY LE BOURG,TRONCHOY,49.8474299808,1.90289897698,443,,Hornoy-le-Bourg,Hornoy-le-Bourg,80,Somme,32,Hauts-de-France
80444,HUCHENNEVILLE,80132,HUCHENNEVILLE,,50.0482513691,1.80273072107,444,,Huchenneville,Huchenneville,80,Somme,32,Hauts-de-France
80445,HUMBERCOURT,80600,HUMBERCOURT,,50.2095577293,2.45741568724,445,,Humbercourt,Humbercourt,80,Somme,32,Hauts-de-France
80446,HUPPY,80140,HUPPY,,50.0240574056,1.76011940003,446,,Huppy,Huppy,80,Somme,32,Hauts-de-France
80447,HYENCOURT LE GRAND,80320,HYENCOURT LE GRAND,,49.8223658764,2.83681004455,447,,Hyencourt-le-Grand,Hyencourt-le-Grand,80,Somme,32,Hauts-de-France
80449,IGNAUCOURT,80800,IGNAUCOURT,,49.817976943,2.57616977207,449,,Ignaucourt,Ignaucourt,80,Somme,32,Hauts-de-France
80450,INVAL BOIRON,80430,INVAL BOIRON,,49.8812998293,1.74688104209,450,,Inval-Boiron,Inval-Boiron,80,Somme,32,Hauts-de-France
80451,IRLES,80300,IRLES,,50.1027187266,2.76036273759,451,,Irles,Irles,80,Somme,32,Hauts-de-France
80452,JUMEL,80250,JUMEL,,49.7623882045,2.32285049366,452,,Jumel,Jumel,80,Somme,32,Hauts-de-France
80453,LABOISSIERE EN SANTERRE,80500,LABOISSIERE EN SANTERRE,,49.6588650675,2.66756575772,453,,Laboissière-en-Santerre,Laboissière-en-Santerre,80,Somme,32,Hauts-de-France
80455,LACHAPELLE,80290,LA CHAPELLE SOUS POIX,,49.7617926645,1.95721881153,455,,Lachapelle,Lachapelle,80,Somme,32,Hauts-de-France
80456,LAFRESGUIMONT ST MARTIN,80430,LAFRESGUIMONT ST MARTIN,,49.8233759898,1.81063672969,456,,Lafresguimont-Saint-Martin,Lafresguimont-Saint-Martin,80,Somme,32,Hauts-de-France
80456,LAFRESGUIMONT ST MARTIN,80430,LAFRESGUIMONT ST MARTIN,GUIBERMESNIL,49.8233759898,1.81063672969,456,,Lafresguimont-Saint-Martin,Lafresguimont-Saint-Martin,80,Somme,32,Hauts-de-France
80456,LAFRESGUIMONT ST MARTIN,80430,LAFRESGUIMONT ST MARTIN,LABOISSIERE ST MARTIN,49.8233759898,1.81063672969,456,,Lafresguimont-Saint-Martin,Lafresguimont-Saint-Martin,80,Somme,32,Hauts-de-France
80456,LAFRESGUIMONT ST MARTIN,80430,LAFRESGUIMONT ST MARTIN,MONTMARQUET,49.8233759898,1.81063672969,456,,Lafresguimont-Saint-Martin,Lafresguimont-Saint-Martin,80,Somme,32,Hauts-de-France
80458,LAHOUSSOYE,80800,LAHOUSSOYE,,49.9488537069,2.48167422297,458,,Lahoussoye,Lahoussoye,80,Somme,32,Hauts-de-France
80459,LALEU,80270,LALEU,,49.9424056241,1.9357914781,459,,Laleu,Laleu,80,Somme,32,Hauts-de-France
80460,LAMARONDE,80290,LAMARONDE,,49.8038232454,1.90392205707,460,,Lamaronde,Lamaronde,80,Somme,32,Hauts-de-France
80461,LAMOTTE BREBIERE,80450,LAMOTTE BREBIERE,,49.8946355893,2.39437471937,461,,Lamotte-Brebière,Lamotte-Brebière,80,Somme,32,Hauts-de-France
80462,LAMOTTE BULEUX,80150,LAMOTTE BULEUX,,50.1865666179,1.83564836965,462,,Lamotte-Buleux,Lamotte-Buleux,80,Somme,32,Hauts-de-France
80463,LAMOTTE WARFUSEE,80800,LAMOTTE WARFUSEE,,49.8753301575,2.59140340435,463,,Lamotte-Warfusée,Lamotte-Warfusée,80,Somme,32,Hauts-de-France
80463,LAMOTTE WARFUSEE,80800,LAMOTTE WARFUSEE,WARFUSEE ABANCOURT,49.8753301575,2.59140340435,463,,Lamotte-Warfusée,Lamotte-Warfusée,80,Somme,32,Hauts-de-France
80464,LANCHERES,80230,LANCHERES,,50.163006057,1.55355178497,464,,Lanchères,Lanchères,80,Somme,32,Hauts-de-France
80465,LANGUEVOISIN QUIQUERY,80190,LANGUEVOISIN QUIQUERY,,49.745698348,2.93309479139,465,,Languevoisin-Quiquery,Languevoisin-Quiquery,80,Somme,32,Hauts-de-France
80466,LANCHES ST HILAIRE,80620,LANCHES ST HILAIRE,,50.10029759,2.1457130224,466,,Lanches-Saint-Hilaire,Lanches-Saint-Hilaire,80,Somme,32,Hauts-de-France
80467,LAUCOURT,80700,LAUCOURT,,49.6715986879,2.76264234534,467,,Laucourt,Laucourt,80,Somme,32,Hauts-de-France
80468,LAVIEVILLE,80300,LAVIEVILLE,,49.9913087444,2.57657970743,468,,Laviéville,Laviéville,80,Somme,32,Hauts-de-France
80469,LAWARDE MAUGER L HORTOY,80250,LAWARDE MAUGER L HORTOY,,49.712060298,2.27144968439,469,,Lawarde-Mauger-l'Hortoy,Lawarde-Mauger-l'Hortoy,80,Somme,32,Hauts-de-France
80470,LEALVILLERS,80560,LEALVILLERS,,50.0684974222,2.50798343756,470,,Léalvillers,Léalvillers,80,Somme,32,Hauts-de-France
80472,LESBOEUFS,80360,LESBOEUFS,,50.0441508716,2.85744124696,472,,Lesbœufs,Lesbœufs,80,Somme,32,Hauts-de-France
80473,LIANCOURT FOSSE,80700,LIANCOURT FOSSE,,49.7556819927,2.81451091711,473,,Liancourt-Fosse,Liancourt-Fosse,80,Somme,32,Hauts-de-France
80474,LICOURT,80320,LICOURT,,49.8252640948,2.90244225748,474,,Licourt,Licourt,80,Somme,32,Hauts-de-France
80475,LIERAMONT,80240,LIERAMONT,,49.9958484333,3.05178625154,475,,Liéramont,Liéramont,80,Somme,32,Hauts-de-France
80476,LIERCOURT,80580,LIERCOURT,,50.0328501095,1.89702785337,476,,Liercourt,Liercourt,80,Somme,32,Hauts-de-France
80477,LIGESCOURT,80150,LIGESCOURT,,50.2903292163,1.87136971032,477,,Ligescourt,Ligescourt,80,Somme,32,Hauts-de-France
80478,LIGNIERES,80500,LIGNIERES LES ROYE,,49.671563988,2.64871635653,478,,Lignières,Lignières,80,Somme,32,Hauts-de-France
80479,LIGNIERES CHATELAIN,80290,LIGNIERES CHATELAIN,,49.7760927135,1.86331037003,479,,Lignières-Châtelain,Lignières-Châtelain,80,Somme,32,Hauts-de-France
80480,LIGNIERES EN VIMEU,80140,LIGNIERES EN VIMEU,,49.9101863161,1.74567554549,480,,Lignières-en-Vimeu,Lignières-en-Vimeu,80,Somme,32,Hauts-de-France
80481,LIHONS,80320,LIHONS,,49.8281719483,2.75595093445,481,,Lihons,Lihons,80,Somme,32,Hauts-de-France
80482,LIMEUX,80490,LIMEUX,,50.0167557888,1.80810963751,482,,Limeux,Limeux,80,Somme,32,Hauts-de-France
80484,LIOMER,80430,LIOMER,,49.859116583,1.808682674,484,,Liomer,Liomer,80,Somme,32,Hauts-de-France
80485,LOEUILLY,80160,LOEUILLY,,49.7718632447,2.19570627328,485,,Lœuilly,Lœuilly,80,Somme,32,Hauts-de-France
80486,LONG,80510,LONG,,50.0414163868,1.9882949224,486,,Long,Long,80,Somme,32,Hauts-de-France
80487,LONGAVESNES,80240,LONGAVESNES,,49.971796955,3.0617264877,487,,Longavesnes,Longavesnes,80,Somme,32,Hauts-de-France
80488,LONGPRE LES CORPS SAINTS,80510,LONGPRE LES CORPS SAINTS,,50.0122641772,1.98145875228,488,,Longpré-les-Corps-Saints,Longpré-les-Corps-Saints,80,Somme,32,Hauts-de-France
80489,LONGUEAU,80330,LONGUEAU,,49.8725071478,2.3569860752,489,,Longueau,Longueau,80,Somme,32,Hauts-de-France
80490,LONGUEVAL,80360,LONGUEVAL,,50.0304280859,2.7989971298,490,,Longueval,Longueval,80,Somme,32,Hauts-de-France
80491,LONGUEVILLETTE,80600,LONGUEVILLETTE,,50.1302872079,2.27525304662,491,,Longuevillette,Longuevillette,80,Somme,32,Hauts-de-France
80493,LOUVENCOURT,80560,LOUVENCOURT,,50.0920247184,2.50525123119,493,,Louvencourt,Louvencourt,80,Somme,32,Hauts-de-France
80494,LOUVRECHY,80250,LOUVRECHY,,49.7318985145,2.39721661859,494,,Louvrechy,Louvrechy,80,Somme,32,Hauts-de-France
80495,LUCHEUX,80600,LUCHEUX,,50.2007727466,2.41954614841,495,,Lucheux,Lucheux,80,Somme,32,Hauts-de-France
80496,MACHIEL,80150,MACHIEL,,50.2721837623,1.83743610924,496,,Machiel,Machiel,80,Somme,32,Hauts-de-France
80497,MACHY,80150,MACHY,,50.2756243749,1.80219554847,497,,Machy,Machy,80,Somme,32,Hauts-de-France
80498,MAILLY MAILLET,80560,MAILLY MAILLET,,50.080228584,2.59367423129,498,,Mailly-Maillet,Mailly-Maillet,80,Somme,32,Hauts-de-France
80499,MAILLY RAINEVAL,80110,MAILLY RAINEVAL,,49.7424922683,2.44960339901,499,,Mailly-Raineval,Mailly-Raineval,80,Somme,32,Hauts-de-France
80500,MAISNIERES,80220,MAISNIERES,,50.0146470358,1.6091770651,500,,Maisnières,Maisnières,80,Somme,32,Hauts-de-France
80501,MAISON PONTHIEU,80150,MAISON PONTHIEU,,50.2071660042,2.04267098305,501,,Maison-Ponthieu,Maison-Ponthieu,80,Somme,32,Hauts-de-France
80502,MAISON ROLAND,80135,MAISON ROLAND,,50.1283581508,2.02616048057,502,,Maison-Roland,Maison-Roland,80,Somme,32,Hauts-de-France
80503,MAIZICOURT,80370,MAIZICOURT,,50.1958444054,2.12689223316,503,,Maizicourt,Maizicourt,80,Somme,32,Hauts-de-France
80504,MALPART,80250,MALPART,,49.6935283012,2.49821322864,504,,Malpart,Malpart,80,Somme,32,Hauts-de-France
80505,MAMETZ,80300,MAMETZ,,50.0005695431,2.74579694332,505,,Mametz,Mametz,80,Somme,32,Hauts-de-France
80507,MARCELCAVE,80800,MARCELCAVE,,49.8483821329,2.57064959188,507,,Marcelcave,Marcelcave,80,Somme,32,Hauts-de-France
80508,MARCHE ALLOUARDE,80700,MARCHE ALLOUARDE,,49.72387927,2.86471134703,508,,Marché-Allouarde,Marché-Allouarde,80,Somme,32,Hauts-de-France
80509,MARCHELEPOT,80200,MARCHELEPOT,,49.8310377933,2.86663557413,509,,Marchélepot,Marchélepot,80,Somme,32,Hauts-de-France
80511,MARESTMONTIERS,80500,MARESTMONTIERS,,49.6824759775,2.52108959814,511,,Marestmontiers,Marestmontiers,80,Somme,32,Hauts-de-France
80512,MAREUIL CAUBERT,80132,MAREUIL CAUBERT,,50.0709955291,1.82600353459,512,,Mareuil-Caubert,Mareuil-Caubert,80,Somme,32,Hauts-de-France
80513,MARICOURT,80360,MARICOURT,,49.9808314215,2.78477314486,513,,Maricourt,Maricourt,80,Somme,32,Hauts-de-France
80514,MARIEUX,80560,MARIEUX,,50.1008204397,2.44635415913,514,,Marieux,Marieux,80,Somme,32,Hauts-de-France
80515,MARLERS,80290,MARLERS,,49.7590692522,1.85023209402,515,,Marlers,Marlers,80,Somme,32,Hauts-de-France
80516,MARQUAIX,80240,MARQUAIX,,49.9474555429,3.07462772499,516,,Marquaix,Marquaix,80,Somme,32,Hauts-de-France
80517,MARQUIVILLERS,80700,MARQUIVILLERS,,49.6799122523,2.6897278702,517,,Marquivillers,Marquivillers,80,Somme,32,Hauts-de-France
80518,MARTAINNEVILLE,80140,MARTAINNEVILLE,,49.9987691783,1.70652941696,518,,Martainneville,Martainneville,80,Somme,32,Hauts-de-France
80519,MATIGNY,80400,MATIGNY,,49.7962902112,3.01279614004,519,,Matigny,Matigny,80,Somme,32,Hauts-de-France
80520,MAUCOURT,80170,MAUCOURT,,49.7932635969,2.75234072333,520,,Maucourt,Maucourt,80,Somme,32,Hauts-de-France
80521,MAUREPAS,80360,MAUREPAS,,49.9853157676,2.85285166012,521,,Maurepas,Maurepas,80,Somme,32,Hauts-de-France
80522,LE MAZIS,80430,LE MAZIS,,49.8721428808,1.76333185731,522,Le,Mazis,Le Mazis,80,Somme,32,Hauts-de-France
80523,MEAULTE,80300,MEAULTE,,49.9748428064,2.66806300084,523,,Méaulte,Méaulte,80,Somme,32,Hauts-de-France
80524,MEHARICOURT,80170,MEHARICOURT,,49.7967919202,2.73103944446,524,,Méharicourt,Méharicourt,80,Somme,32,Hauts-de-France
80525,MEIGNEUX,80290,MEIGNEUX,,49.7668933468,1.89057664308,525,,Meigneux,Meigneux,80,Somme,32,Hauts-de-France
80526,LE MEILLARD,80370,LE MEILLARD,,50.1708867861,2.19859474382,526,Le,Meillard,Le Meillard,80,Somme,32,Hauts-de-France
80527,MENESLIES,80520,MENESLIES,,50.0580071022,1.49366891391,527,,Méneslies,Méneslies,80,Somme,32,Hauts-de-France
80528,MEREAUCOURT,80290,MEREAUCOURT,,49.7302551536,1.93106874547,528,,Méréaucourt,Méréaucourt,80,Somme,32,Hauts-de-France
80529,MERELESSART,80490,MERELESSART,,49.9721965697,1.84857479614,529,,Mérélessart,Mérélessart,80,Somme,32,Hauts-de-France
80530,MERICOURT L ABBE,80800,MERICOURT L ABBE,,49.945066215,2.56298035307,530,,Méricourt-l'Abbé,Méricourt-l'Abbé,80,Somme,32,Hauts-de-France
80531,MERICOURT EN VIMEU,80640,MERICOURT EN VIMEU,,49.8943599561,1.94624178875,531,,Méricourt-en-Vimeu,Méricourt-en-Vimeu,80,Somme,32,Hauts-de-France
80532,MERICOURT SUR SOMME,80340,MERICOURT SUR SOMME,,49.9059138234,2.68162218059,532,,Méricourt-sur-Somme,Méricourt-sur-Somme,80,Somme,32,Hauts-de-France
80533,MERS LES BAINS,80350,MERS LES BAINS,,50.0705181348,1.40473307328,533,,Mers-les-Bains,Mers-les-Bains,80,Somme,32,Hauts-de-France
80535,LE MESGE,80310,LE MESGE,,49.9405207986,2.04375733629,535,Le,Mesge,Le Mesge,80,Somme,32,Hauts-de-France
80536,MESNIL BRUNTEL,80200,MESNIL BRUNTEL,,49.8979290525,2.96283579416,536,,Mesnil-Bruntel,Mesnil-Bruntel,80,Somme,32,Hauts-de-France
80537,MESNIL DOMQUEUR,80620,MESNIL DOMQUEUR,,50.1336917622,2.06307688394,537,,Mesnil-Domqueur,Mesnil-Domqueur,80,Somme,32,Hauts-de-France
80538,MESNIL EN ARROUAISE,80360,MESNIL EN ARROUAISE,,50.0364327239,2.95019239186,538,,Mesnil-en-Arrouaise,Mesnil-en-Arrouaise,80,Somme,32,Hauts-de-France
80540,MESNIL MARTINSART,80300,MESNIL MARTINSART,,50.0476233415,2.6395201945,540,,Mesnil-Martinsart,Mesnil-Martinsart,80,Somme,32,Hauts-de-France
80541,MESNIL ST GEORGES,80500,MESNIL ST GEORGES,,49.6408491861,2.52473977489,541,,Mesnil-Saint-Georges,Mesnil-Saint-Georges,80,Somme,32,Hauts-de-France
80542,MESNIL ST NICAISE,80190,MESNIL ST NICAISE,,49.7784450103,2.91323187372,542,,Mesnil-Saint-Nicaise,Mesnil-Saint-Nicaise,80,Somme,32,Hauts-de-France
80543,METIGNY,80270,METIGNY,,49.9367802972,1.91143330166,543,,Métigny,Métigny,80,Somme,32,Hauts-de-France
80544,MEZEROLLES,80600,MEZEROLLES,,50.1854908448,2.22657090414,544,,Mézerolles,Mézerolles,80,Somme,32,Hauts-de-France
80545,MEZIERES EN SANTERRE,80110,MEZIERES EN SANTERRE,,49.7807995412,2.55149531899,545,,Mézières-en-Santerre,Mézières-en-Santerre,80,Somme,32,Hauts-de-France
80546,MIANNAY,80132,MIANNAY,,50.0958685201,1.71810274117,546,,Miannay,Miannay,80,Somme,32,Hauts-de-France
80547,MILLENCOURT,80300,MILLENCOURT,,50.0012076489,2.59744918705,547,,Millencourt,Millencourt,80,Somme,32,Hauts-de-France
80548,MILLENCOURT EN PONTHIEU,80135,MILLENCOURT EN PONTHIEU,,50.1499478257,1.91216864663,548,,Millencourt-en-Ponthieu,Millencourt-en-Ponthieu,80,Somme,32,Hauts-de-France
80549,MIRAUMONT,80300,MIRAUMONT,,50.1005697017,2.7276651357,549,,Miraumont,Miraumont,80,Somme,32,Hauts-de-France
80550,MIRVAUX,80260,MIRVAUX,,50.0030619644,2.3976230968,550,,Mirvaux,Mirvaux,80,Somme,32,Hauts-de-France
80551,MISERY,80320,MISERY,,49.8474292433,2.89005064119,551,,Misery,Misery,80,Somme,32,Hauts-de-France
80552,MOISLAINS,80200,MOISLAINS,,49.9944569614,2.96706365321,552,,Moislains,Moislains,80,Somme,32,Hauts-de-France
80553,MOLLIENS AU BOIS,80260,MOLLIENS AU BOIS,,49.9891382891,2.38068499565,553,,Molliens-au-Bois,Molliens-au-Bois,80,Somme,32,Hauts-de-France
80554,MOLLIENS DREUIL,80540,MOLLIENS DREUIL,,49.8819204869,2.01098635745,554,,Molliens-Dreuil,Molliens-Dreuil,80,Somme,32,Hauts-de-France
80554,MOLLIENS DREUIL,80540,MOLLIENS DREUIL,DREUIL LES MOLLIENS,49.8819204869,2.01098635745,554,,Molliens-Dreuil,Molliens-Dreuil,80,Somme,32,Hauts-de-France
80555,MONCHY LAGACHE,80200,MONCHY LAGACHE,,49.850644033,3.04313545321,555,,Monchy-Lagache,Monchy-Lagache,80,Somme,32,Hauts-de-France
80555,MONCHY LAGACHE,80200,MONCHY LAGACHE,MEREAUCOURT,49.850644033,3.04313545321,555,,Monchy-Lagache,Monchy-Lagache,80,Somme,32,Hauts-de-France
80556,MONS BOUBERT,80210,MONS BOUBERT,,50.1261955466,1.66415808821,556,,Mons-Boubert,Mons-Boubert,80,Somme,32,Hauts-de-France
80557,ESTREES MONS,80200,ESTREES MONS,,49.8792650638,3.00854833649,557,,Estrées-Mons,Estrées-Mons,80,Somme,32,Hauts-de-France
80557,ESTREES MONS,80200,ESTREES MONS,ESTREES EN CHAUSSEE,49.8792650638,3.00854833649,557,,Estrées-Mons,Estrées-Mons,80,Somme,32,Hauts-de-France
80558,MONSURES,80160,MONSURES,,49.7111155,2.16144716835,558,,Monsures,Monsures,80,Somme,32,Hauts-de-France
80559,MONTAGNE FAYEL,80540,MONTAGNE FAYEL,,49.9111182672,1.98699952073,559,,Montagne-Fayel,Montagne-Fayel,80,Somme,32,Hauts-de-France
80560,MONTAUBAN DE PICARDIE,80300,MONTAUBAN DE PICARDIE,,50.007203585,2.78122270827,560,,Montauban-de-Picardie,Montauban-de-Picardie,80,Somme,32,Hauts-de-France
80561,MONTDIDIER,80500,MONTDIDIER,,49.6484417752,2.56889051669,561,,Montdidier,Montdidier,80,Somme,32,Hauts-de-France
80562,MONTIGNY SUR L HALLUE,80260,MONTIGNY SUR L HALLUE,,49.9854644167,2.42235764909,562,,Montigny-sur-l'Hallue,Montigny-sur-l'Hallue,80,Somme,32,Hauts-de-France
80563,MONTIGNY LES JONGLEURS,80370,MONTIGNY LES JONGLEURS,,50.17823069,2.13581151088,563,,Montigny-les-Jongleurs,Montigny-les-Jongleurs,80,Somme,32,Hauts-de-France
80565,MONTONVILLERS,80260,MONTONVILLERS,,49.9964398506,2.29571982025,565,,Montonvillers,Montonvillers,80,Somme,32,Hauts-de-France
80566,FIEFFES MONTRELET,80670,FIEFFES MONTRELET,,50.0905993078,2.21690355063,566,,Fieffes-Montrelet,Fieffes-Montrelet,80,Somme,32,Hauts-de-France
80566,FIEFFES MONTRELET,80670,FIEFFES MONTRELET,FIEFFES,50.0905993078,2.21690355063,566,,Fieffes-Montrelet,Fieffes-Montrelet,80,Somme,32,Hauts-de-France
80568,MORCHAIN,80190,MORCHAIN,,49.8003145856,2.92316556793,568,,Morchain,Morchain,80,Somme,32,Hauts-de-France
80569,MORCOURT,80340,MORCOURT,,49.8856864737,2.65779356181,569,,Morcourt,Morcourt,80,Somme,32,Hauts-de-France
80570,MOREUIL,80110,MOREUIL,,49.7762271653,2.48422182173,570,,Moreuil,Moreuil,80,Somme,32,Hauts-de-France
80571,MORISEL,80110,MORISEL,,49.7650422662,2.45934575457,571,,Morisel,Morisel,80,Somme,32,Hauts-de-France
80572,MORLANCOURT,80300,MORLANCOURT,,49.948084747,2.6473273905,572,,Morlancourt,Morlancourt,80,Somme,32,Hauts-de-France
80573,MORVILLERS ST SATURNIN,80290,MORVILLERS ST SATURNIN,,49.7787461187,1.81527896317,573,,Morvillers-Saint-Saturnin,Morvillers-Saint-Saturnin,80,Somme,32,Hauts-de-France
80573,MORVILLERS ST SATURNIN,80290,MORVILLERS ST SATURNIN,DIGEON,49.7787461187,1.81527896317,573,,Morvillers-Saint-Saturnin,Morvillers-Saint-Saturnin,80,Somme,32,Hauts-de-France
80574,MOUFLERS,80690,MOUFLERS,,50.0444725636,2.05639720949,574,,Mouflers,Mouflers,80,Somme,32,Hauts-de-France
80575,MOUFLIERES,80140,MOUFLIERES,,49.9258646541,1.74240882463,575,,Mouflières,Mouflières,80,Somme,32,Hauts-de-France
80576,MOYENCOURT,80400,MOYENCOURT,,49.7241561473,2.94715623094,576,,Moyencourt,Moyencourt,80,Somme,32,Hauts-de-France
80577,MOYENCOURT LES POIX,80290,MOYENCOURT LES POIX,,49.7906225014,2.04096511821,577,,Moyencourt-lès-Poix,Moyencourt-lès-Poix,80,Somme,32,Hauts-de-France
80578,MOYENNEVILLE,80870,MOYENNEVILLE,,50.0763860952,1.75417829151,578,,Moyenneville,Moyenneville,80,Somme,32,Hauts-de-France
80579,MUILLE VILLETTE,80400,MUILLE VILLETTE,,49.7250440198,3.06872712121,579,,Muille-Villette,Muille-Villette,80,Somme,32,Hauts-de-France
80580,NAMPONT,80120,NAMPONT ST MARTIN,,50.3421619095,1.75242683099,580,,Nampont,Nampont,80,Somme,32,Hauts-de-France
80582,NAMPS MAISNIL,80290,NAMPS MAISNIL,,49.8061321479,2.1229976802,582,,Namps-Maisnil,Namps-Maisnil,80,Somme,32,Hauts-de-France
80582,NAMPS MAISNIL,80290,NAMPS MAISNIL,NAMPS AU MONT,49.8061321479,2.1229976802,582,,Namps-Maisnil,Namps-Maisnil,80,Somme,32,Hauts-de-France
80582,NAMPS MAISNIL,80290,NAMPS MAISNIL,RUMAISNIL,49.8061321479,2.1229976802,582,,Namps-Maisnil,Namps-Maisnil,80,Somme,32,Hauts-de-France
80582,NAMPS MAISNIL,80290,NAMPS MAISNIL,TAISNIL,49.8061321479,2.1229976802,582,,Namps-Maisnil,Namps-Maisnil,80,Somme,32,Hauts-de-France
80583,NAMPTY,80160,NAMPTY,,49.7893011328,2.21654250749,583,,Nampty,Nampty,80,Somme,32,Hauts-de-France
80584,NAOURS,80260,NAOURS,,50.0451911173,2.28427416723,584,,Naours,Naours,80,Somme,32,Hauts-de-France
80585,NESLE,80190,NESLE,,49.7599586535,2.90989646519,585,,Nesle,Nesle,80,Somme,32,Hauts-de-France
80586,NESLE L HOPITAL,80140,NESLE L HOPITAL,,49.9141969237,1.69287988387,586,,Nesle-l'Hôpital,Nesle-l'Hôpital,80,Somme,32,Hauts-de-France
80587,NESLETTE,80140,NESLETTE,,49.9273386228,1.66463557857,587,,Neslette,Neslette,80,Somme,32,Hauts-de-France
80588,NEUFMOULIN,80132,NEUFMOULIN,,50.1259221155,1.90411559501,588,,Neufmoulin,Neufmoulin,80,Somme,32,Hauts-de-France
80589,NEUILLY LE DIEN,80150,NEUILLY LE DIEN,,50.2260892349,2.04754518311,589,,Neuilly-le-Dien,Neuilly-le-Dien,80,Somme,32,Hauts-de-France
80590,NEUILLY L HOPITAL,80132,NEUILLY L HOPITAL,,50.1693770105,1.88017182256,590,,Neuilly-l'Hôpital,Neuilly-l'Hôpital,80,Somme,32,Hauts-de-France
80591,NEUVILLE AU BOIS,80140,NEUVILLE AU BOIS,,49.9698929378,1.78327911421,591,,Neuville-au-Bois,Neuville-au-Bois,80,Somme,32,Hauts-de-France
80592,NEUVILLE COPPEGUEULE,80430,NEUVILLE COPPEGUEULE,,49.856003312,1.73981391668,592,,Neuville-Coppegueule,Neuville-Coppegueule,80,Somme,32,Hauts-de-France
80593,LA NEUVILLE LES BRAY,80340,LA NEUVILLE LES BRAY,,49.9221021673,2.72201276953,593,La,Neuville-lès-Bray,La Neuville-lès-Bray,80,Somme,32,Hauts-de-France
80594,NEUVILLE LES LOEUILLY,80160,NEUVILLE LES LOEUILLY,,49.7933026111,2.17536379277,594,,Neuville-lès-Lœuilly,Neuville-lès-Lœuilly,80,Somme,32,Hauts-de-France
80595,LA NEUVILLE SIRE BERNARD,80110,LA NEUVILLE SIRE BERNARD,,49.7428027758,2.52313331423,595,La,Neuville-Sire-Bernard,La Neuville-Sire-Bernard,80,Somme,32,Hauts-de-France
80596,NEUVILLETTE,80600,NEUVILLETTE,,50.2084339297,2.3146303419,596,,Neuvillette,Neuvillette,80,Somme,32,Hauts-de-France
80597,NIBAS,80390,NIBAS,,50.0944816863,1.58862291088,597,,Nibas,Nibas,80,Somme,32,Hauts-de-France
80598,NOUVION,80860,NOUVION,,50.2148554834,1.78036919239,598,,Nouvion,Nouvion,80,Somme,32,Hauts-de-France
80599,NOYELLES EN CHAUSSEE,80150,NOYELLES EN CHAUSSEE,,50.2112725157,1.98679883041,599,,Noyelles-en-Chaussée,Noyelles-en-Chaussée,80,Somme,32,Hauts-de-France
80600,NOYELLES SUR MER,80860,NOYELLES SUR MER,,50.1869678717,1.72060860177,600,,Noyelles-sur-Mer,Noyelles-sur-Mer,80,Somme,32,Hauts-de-France
80601,NURLU,80240,NURLU,,50.0039174559,3.01506134636,601,,Nurlu,Nurlu,80,Somme,32,Hauts-de-France
80602,OCCOCHES,80600,OCCOCHES,,50.1823597887,2.28746052815,602,,Occoches,Occoches,80,Somme,32,Hauts-de-France
80603,OCHANCOURT,80210,OCHANCOURT,,50.1010216248,1.61201531961,603,,Ochancourt,Ochancourt,80,Somme,32,Hauts-de-France
80604,OFFIGNIES,80290,OFFIGNIES,,49.7944081036,1.85641454479,604,,Offignies,Offignies,80,Somme,32,Hauts-de-France
80605,OFFOY,80400,OFFOY,,49.7712497571,3.01663312935,605,,Offoy,Offoy,80,Somme,32,Hauts-de-France
80606,OISEMONT,80140,OISEMONT,,49.9551305817,1.76021998485,606,,Oisemont,Oisemont,80,Somme,32,Hauts-de-France
80607,OISSY,80540,OISSY,,49.9026966706,2.06025866834,607,,Oissy,Oissy,80,Somme,32,Hauts-de-France
80608,OMIECOURT,80320,OMIECOURT,,49.8027253689,2.84320989887,608,,Omiécourt,Omiécourt,80,Somme,32,Hauts-de-France
80609,ONEUX,80135,ONEUX,,50.1482964725,1.98062619732,609,,Oneux,Oneux,80,Somme,32,Hauts-de-France
80611,ORESMAUX,80160,ORESMAUX,,49.7665369193,2.26891551501,611,,Oresmaux,Oresmaux,80,Somme,32,Hauts-de-France
80613,OUST MAREST,80460,OUST MAREST,,50.0485837272,1.4684909272,613,,Oust-Marest,Oust-Marest,80,Somme,32,Hauts-de-France
80614,OUTREBOIS,80600,OUTREBOIS,,50.169788556,2.25158068165,614,,Outrebois,Outrebois,80,Somme,32,Hauts-de-France
80615,OVILLERS LA BOISSELLE,80300,OVILLERS LA BOISSELLE,,50.0291342676,2.69870692069,615,,Ovillers-la-Boisselle,Ovillers-la-Boisselle,80,Somme,32,Hauts-de-France
80616,PARGNY,80190,PARGNY,,49.8086448801,2.94770982571,616,,Pargny,Pargny,80,Somme,32,Hauts-de-France
80617,PARVILLERS LE QUESNOY,80700,PARVILLERS LE QUESNOY,,49.7466464905,2.72555344352,617,,Parvillers-le-Quesnoy,Parvillers-le-Quesnoy,80,Somme,32,Hauts-de-France
80617,PARVILLERS LE QUESNOY,80700,PARVILLERS LE QUESNOY,LE QUESNOY,49.7466464905,2.72555344352,617,,Parvillers-le-Quesnoy,Parvillers-le-Quesnoy,80,Somme,32,Hauts-de-France
80618,PENDE,80230,PENDE,,50.1611960507,1.58412882815,618,,Pendé,Pendé,80,Somme,32,Hauts-de-France
80619,PERNOIS,80670,PERNOIS,,50.0612738693,2.18597099318,619,,Pernois,Pernois,80,Somme,32,Hauts-de-France
80620,PERONNE,80200,PERONNE,,49.928328669,2.93001266218,620,,Péronne,Péronne,80,Somme,32,Hauts-de-France
80621,PERTAIN,80320,PERTAIN,,49.8059761599,2.86900836707,621,,Hypercourt,Hypercourt,80,Somme,32,Hauts-de-France
80622,PICQUIGNY,80310,PICQUIGNY,,49.9329385183,2.13909509969,622,,Picquigny,Picquigny,80,Somme,32,Hauts-de-France
80623,PIENNES ONVILLERS,80500,PIENNES ONVILLERS,,49.6253121248,2.64673689542,623,,Piennes-Onvillers,Piennes-Onvillers,80,Somme,32,Hauts-de-France
80623,PIENNES ONVILLERS,80500,PIENNES ONVILLERS,ONVILLERS,49.6253121248,2.64673689542,623,,Piennes-Onvillers,Piennes-Onvillers,80,Somme,32,Hauts-de-France
80624,PIERREGOT,80260,PIERREGOT,,50.0077895959,2.38046356254,624,,Pierregot,Pierregot,80,Somme,32,Hauts-de-France
80625,PIERREPONT SUR AVRE,80500,PIERREPONT SUR AVRE,,49.7068924487,2.5511767234,625,,Pierrepont-sur-Avre,Pierrepont-sur-Avre,80,Somme,32,Hauts-de-France
80626,PISSY,80540,PISSY,,49.8666021286,2.12931431789,626,,Pissy,Pissy,80,Somme,32,Hauts-de-France
80627,PLACHY BUYON,80160,PLACHY BUYON,,49.8120593535,2.22769892861,627,,Plachy-Buyon,Plachy-Buyon,80,Somme,32,Hauts-de-France
80628,LE PLESSIER ROZAINVILLERS,80110,LE PLESSIER ROZAINVILLERS,,49.7515607752,2.55260260825,628,Le,Plessier-Rozainvillers,Le Plessier-Rozainvillers,80,Somme,32,Hauts-de-France
80629,POEUILLY,80240,POEUILLY,,49.8841196332,3.09642051056,629,,Pœuilly,Pœuilly,80,Somme,32,Hauts-de-France
80630,POIX DE PICARDIE,80290,POIX DE PICARDIE,,49.7485829077,1.97952156568,630,,Poix-de-Picardie,Poix-de-Picardie,80,Somme,32,Hauts-de-France
80630,POIX DE PICARDIE,80290,POIX DE PICARDIE,LAHAYE ST ROMAIN,49.7485829077,1.97952156568,630,,Poix-de-Picardie,Poix-de-Picardie,80,Somme,32,Hauts-de-France
80631,PONCHES ESTRUVAL,80150,PONCHES ESTRUVAL,,50.3101137793,1.87589229818,631,,Ponches-Estruval,Ponches-Estruval,80,Somme,32,Hauts-de-France
80632,PONT DE METZ,80480,PONT DE METZ,,49.8829992379,2.22861660834,632,,Pont-de-Metz,Pont-de-Metz,80,Somme,32,Hauts-de-France
80633,PONTHOILE,80860,PONTHOILE,,50.2184715129,1.70629194406,633,,Ponthoile,Ponthoile,80,Somme,32,Hauts-de-France
80634,PONT NOYELLES,80115,PONT NOYELLES,,49.9405653949,2.45269277473,634,,Pont-Noyelles,Pont-Noyelles,80,Somme,32,Hauts-de-France
80635,PONT REMY,80580,PONT REMY,,50.0614985686,1.91476030645,635,,Pont-Remy,Pont-Remy,80,Somme,32,Hauts-de-France
80637,PORT LE GRAND,80132,PORT LE GRAND,,50.1550933696,1.75978321125,637,,Port-le-Grand,Port-le-Grand,80,Somme,32,Hauts-de-France
80638,POTTE,80190,POTTE,,49.7983865709,2.8997143458,638,,Potte,Potte,80,Somme,32,Hauts-de-France
80639,POULAINVILLE,80260,POULAINVILLE,,49.9534990851,2.30852527468,639,,Poulainville,Poulainville,80,Somme,32,Hauts-de-France
80640,POZIERES,80300,POZIERES,,50.0416240596,2.72336179516,640,,Pozières,Pozières,80,Somme,32,Hauts-de-France
80642,PROUVILLE,80370,PROUVILLE,,50.1557396605,2.12945547037,642,,Prouville,Prouville,80,Somme,32,Hauts-de-France
80643,PROUZEL,80160,PROUZEL,,49.814090508,2.18958769704,643,,Prouzel,Prouzel,80,Somme,32,Hauts-de-France
80644,PROYART,80340,PROYART,,49.8887503855,2.70543811348,644,,Proyart,Proyart,80,Somme,32,Hauts-de-France
80645,PUCHEVILLERS,80560,PUCHEVILLERS,,50.0550288029,2.41064200823,645,,Puchevillers,Puchevillers,80,Somme,32,Hauts-de-France
80646,PUNCHY,80320,PUNCHY,,49.7919371127,2.80784010263,646,,Punchy,Punchy,80,Somme,32,Hauts-de-France
80647,PUZEAUX,80320,PUZEAUX,,49.7985997046,2.82666272798,647,,Puzeaux,Puzeaux,80,Somme,32,Hauts-de-France
80648,PYS,80300,PYS,,50.0828866857,2.76242466587,648,,Pys,Pys,80,Somme,32,Hauts-de-France
80649,QUEND,80120,QUEND,,50.3216390021,1.61114189711,649,,Quend,Quend,80,Somme,32,Hauts-de-France
80650,QUERRIEU,80115,QUERRIEU,,49.9387328157,2.41366429954,650,,Querrieu,Querrieu,80,Somme,32,Hauts-de-France
80651,LE QUESNE,80430,LE QUESNE,,49.8602001575,1.79202557749,651,Le,Quesne,Le Quesne,80,Somme,32,Hauts-de-France
80652,LE QUESNEL,80118,LE QUESNEL,,49.7820002151,2.62386030353,652,Le,Quesnel,Le Quesnel,80,Somme,32,Hauts-de-France
80654,QUESNOY LE MONTANT,80132,QUESNOY LE MONTANT,,50.1063029095,1.68766890084,654,,Quesnoy-le-Montant,Quesnoy-le-Montant,80,Somme,32,Hauts-de-France
80655,QUESNOY SUR AIRAINES,80270,QUESNOY SUR AIRAINES,,49.9493617833,1.99218828189,655,,Quesnoy-sur-Airaines,Quesnoy-sur-Airaines,80,Somme,32,Hauts-de-France
80656,QUEVAUVILLERS,80710,QUEVAUVILLERS,,49.8160241079,2.07414071451,656,,Quevauvillers,Quevauvillers,80,Somme,32,Hauts-de-France
80657,QUIRY LE SEC,80250,QUIRY LE SEC,,49.6700274695,2.3838470915,657,,Quiry-le-Sec,Quiry-le-Sec,80,Somme,32,Hauts-de-France
80658,QUIVIERES,80400,QUIVIERES,,49.8238384226,3.0324181792,658,,Quivières,Quivières,80,Somme,32,Hauts-de-France
80659,RAINCHEVAL,80600,RAINCHEVAL,,50.0756706246,2.43909935825,659,,Raincheval,Raincheval,80,Somme,32,Hauts-de-France
80661,RAINNEVILLE,80260,RAINNEVILLE,,49.9739949496,2.36189417533,661,,Rainneville,Rainneville,80,Somme,32,Hauts-de-France
80662,RAMBURELLES,80140,RAMBURELLES,,49.9672661423,1.70929896004,662,,Ramburelles,Ramburelles,80,Somme,32,Hauts-de-France
80663,RAMBURES,80140,RAMBURES,,49.9406734794,1.69572636925,663,,Rambures,Rambures,80,Somme,32,Hauts-de-France
80664,RANCOURT,80360,RANCOURT,,50.0030301832,2.90339507593,664,,Rancourt,Rancourt,80,Somme,32,Hauts-de-France
80665,REGNIERE ECLUSE,80120,REGNIERE ECLUSE,,50.2869055108,1.76835866685,665,,Regnière-Écluse,Regnière-Écluse,80,Somme,32,Hauts-de-France
80666,REMAISNIL,80600,REMAISNIL,,50.2057113096,2.24244191691,666,,Remaisnil,Remaisnil,80,Somme,32,Hauts-de-France
80667,REMAUGIES,80500,REMAUGIES,,49.6270583774,2.67048609499,667,,Remaugies,Remaugies,80,Somme,32,Hauts-de-France
80668,REMIENCOURT,80250,REMIENCOURT,,49.7784769361,2.38496357187,668,,Remiencourt,Remiencourt,80,Somme,32,Hauts-de-France
80669,RETHONVILLERS,80700,RETHONVILLERS,,49.7363046729,2.85528725127,669,,Rethonvillers,Rethonvillers,80,Somme,32,Hauts-de-France
80670,REVELLES,80540,REVELLES,,49.8400277548,2.1143709231,670,,Revelles,Revelles,80,Somme,32,Hauts-de-France
80671,RIBEAUCOURT,80620,RIBEAUCOURT,,50.119279536,2.11909340696,671,,Ribeaucourt,Ribeaucourt,80,Somme,32,Hauts-de-France
80672,RIBEMONT SUR ANCRE,80800,RIBEMONT SUR ANCRE,,49.9716473048,2.55901674513,672,,Ribemont-sur-Ancre,Ribemont-sur-Ancre,80,Somme,32,Hauts-de-France
80673,RIENCOURT,80310,RIENCOURT,,49.9204765041,2.02957741355,673,,Riencourt,Riencourt,80,Somme,32,Hauts-de-France
80674,RIVERY,80136,RIVERY,,49.9106434149,2.33962596148,674,,Rivery,Rivery,80,Somme,32,Hauts-de-France
80675,ROGY,80160,ROGY,,49.7129601282,2.20475435684,675,,Rogy,Rogy,80,Somme,32,Hauts-de-France
80676,ROIGLISE,80700,ROIGLISE,,49.6828051752,2.83469335612,676,,Roiglise,Roiglise,80,Somme,32,Hauts-de-France
80677,ROISEL,80240,ROISEL,,49.9450495593,3.09614374784,677,,Roisel,Roisel,80,Somme,32,Hauts-de-France
80678,ROLLOT,80500,ROLLOT,,49.5939801333,2.64865776071,678,,Rollot,Rollot,80,Somme,32,Hauts-de-France
80679,RONSSOY,80740,LE RONSSOY,,49.9815157292,3.16739555115,679,,Ronssoy,Ronssoy,80,Somme,32,Hauts-de-France
80680,ROSIERES EN SANTERRE,80170,ROSIERES EN SANTERRE,,49.8180169007,2.70364687243,680,,Rosières-en-Santerre,Rosières-en-Santerre,80,Somme,32,Hauts-de-France
80681,ROUVREL,80250,ROUVREL,,49.7678519085,2.41703713925,681,,Rouvrel,Rouvrel,80,Somme,32,Hauts-de-France
80682,ROUVROY EN SANTERRE,80170,ROUVROY EN SANTERRE,,49.7680945713,2.71199955174,682,,Rouvroy-en-Santerre,Rouvroy-en-Santerre,80,Somme,32,Hauts-de-France
80683,ROUY LE GRAND,80190,ROUY LE GRAND,,49.7797743059,2.94948921504,683,,Rouy-le-Grand,Rouy-le-Grand,80,Somme,32,Hauts-de-France
80684,ROUY LE PETIT,80190,ROUY LE PETIT,,49.7653735196,2.95478924129,684,,Rouy-le-Petit,Rouy-le-Petit,80,Somme,32,Hauts-de-France
80685,ROYE,80700,ROYE,,49.6936899931,2.78868757676,685,,Roye,Roye,80,Somme,32,Hauts-de-France
80686,RUBEMPRE,80260,RUBEMPRE,,50.018748722,2.37181069016,686,,Rubempré,Rubempré,80,Somme,32,Hauts-de-France
80687,RUBESCOURT,80500,RUBESCOURT,,49.6100172663,2.57571803855,687,,Rubescourt,Rubescourt,80,Somme,32,Hauts-de-France
80688,RUE,80120,RUE,,50.2781968108,1.6650080675,688,,Rue,Rue,80,Somme,32,Hauts-de-France
80690,RUMIGNY,80680,RUMIGNY,,49.8049587982,2.28324796815,690,,Rumigny,Rumigny,80,Somme,32,Hauts-de-France
80691,SAIGNEVILLE,80230,SAIGNEVILLE,,50.1393465326,1.71615502696,691,,Saigneville,Saigneville,80,Somme,32,Hauts-de-France
80692,SAILLY FLIBEAUCOURT,80970,SAILLY FLIBEAUCOURT,,50.1796300653,1.7706771654,692,,Sailly-Flibeaucourt,Sailly-Flibeaucourt,80,Somme,32,Hauts-de-France
80693,SAILLY LAURETTE,80800,SAILLY LAURETTE,,49.9206257095,2.61569223377,693,,Sailly-Laurette,Sailly-Laurette,80,Somme,32,Hauts-de-France
80694,SAILLY LE SEC,80800,SAILLY LE SEC,,49.927194423,2.58905061259,694,,Sailly-le-Sec,Sailly-le-Sec,80,Somme,32,Hauts-de-France
80695,SAILLY SAILLISEL,80360,SAILLY SAILLISEL,,50.0287843068,2.91254407561,695,,Sailly-Saillisel,Sailly-Saillisel,80,Somme,32,Hauts-de-France
80696,SAINS EN AMIENOIS,80680,SAINS EN AMIENOIS,,49.8147147037,2.32407977472,696,,Sains-en-Amiénois,Sains-en-Amiénois,80,Somme,32,Hauts-de-France
80697,ST ACHEUL,80370,ST ACHEUL,,50.1906465454,2.16266082179,697,,Saint-Acheul,Saint-Acheul,80,Somme,32,Hauts-de-France
80698,ST AUBIN MONTENOY,80540,ST AUBIN MONTENOY,,49.8419962428,2.00219471552,698,,Saint-Aubin-Montenoy,Saint-Aubin-Montenoy,80,Somme,32,Hauts-de-France
80699,ST AUBIN RIVIERE,80430,ST AUBIN RIVIERE,,49.8661495239,1.77940255587,699,,Saint-Aubin-Rivière,Saint-Aubin-Rivière,80,Somme,32,Hauts-de-France
80700,ST BLIMONT,80960,ST BLIMONT,,50.1206557505,1.56512599712,700,,Saint-Blimont,Saint-Blimont,80,Somme,32,Hauts-de-France
80701,ST CHRIST BRIOST,80200,ST CHRIST BRIOST,,49.8544622576,2.92524408101,701,,Saint-Christ-Briost,Saint-Christ-Briost,80,Somme,32,Hauts-de-France
80702,ST FUSCIEN,80680,ST FUSCIEN,,49.8367821176,2.30201932317,702,,Saint-Fuscien,Saint-Fuscien,80,Somme,32,Hauts-de-France
80703,ST GERMAIN SUR BRESLE,80430,ST GERMAIN SUR BRESLE,,49.8268454979,1.74609362795,703,,Saint-Germain-sur-Bresle,Saint-Germain-sur-Bresle,80,Somme,32,Hauts-de-France
80703,ST GERMAIN SUR BRESLE,80430,ST GERMAIN SUR BRESLE,GUEMICOURT,49.8268454979,1.74609362795,703,,Saint-Germain-sur-Bresle,Saint-Germain-sur-Bresle,80,Somme,32,Hauts-de-France
80704,ST GRATIEN,80260,ST GRATIEN,,49.9651618881,2.4032049879,704,,Saint-Gratien,Saint-Gratien,80,Somme,32,Hauts-de-France
80705,ST LEGER LES AUTHIE,80560,ST LEGER LES AUTHIE,,50.1272430532,2.51346133932,705,,Saint-Léger-lès-Authie,Saint-Léger-lès-Authie,80,Somme,32,Hauts-de-France
80706,ST LEGER LES DOMART,80780,ST LEGER LES DOMART,,50.0559093538,2.14029922949,706,,Saint-Léger-lès-Domart,Saint-Léger-lès-Domart,80,Somme,32,Hauts-de-France
80707,ST LEGER SUR BRESLE,80140,ST LEGER SUR BRESLE,,49.8713733215,1.7212418664,707,,Saint-Léger-sur-Bresle,Saint-Léger-sur-Bresle,80,Somme,32,Hauts-de-France
80708,ST MARD,80700,ST MARD,,49.6891007736,2.7565588401,708,,Saint-Mard,Saint-Mard,80,Somme,32,Hauts-de-France
80709,ST MAULVIS,80140,ST MAULVIS,,49.9023922509,1.82631005356,709,,Saint-Maulvis,Saint-Maulvis,80,Somme,32,Hauts-de-France
80710,ST MAXENT,80140,ST MAXENT,,49.998092452,1.73127217091,710,,Saint-Maxent,Saint-Maxent,80,Somme,32,Hauts-de-France
80711,ST OUEN,80610,ST OUEN,,50.0342986699,2.1311595738,711,,Saint-Ouen,Saint-Ouen,80,Somme,32,Hauts-de-France
80713,ST QUENTIN EN TOURMONT,80120,ST QUENTIN EN TOURMONT,,50.2799548442,1.57689944614,713,,Saint-Quentin-en-Tourmont,Saint-Quentin-en-Tourmont,80,Somme,32,Hauts-de-France
80714,ST QUENTIN LA MOTTE CROIX AU BAILLY,80880,ST QUENTIN LA MOTTE CROIX BAILLY,,50.0736550841,1.45100118473,714,,Saint-Quentin-la-Motte-Croix-au-Bailly,Saint-Quentin-la-Motte-Croix-au-Bailly,80,Somme,32,Hauts-de-France
80716,ST RIQUIER,80135,ST RIQUIER,,50.1279792191,1.94290239471,716,,Saint-Riquier,Saint-Riquier,80,Somme,32,Hauts-de-France
80717,ST SAUFLIEU,80160,ST SAUFLIEU,,49.7853940061,2.2529940743,717,,Saint-Sauflieu,Saint-Sauflieu,80,Somme,32,Hauts-de-France
80718,ST SAUVEUR,80470,ST SAUVEUR,,49.9475408658,2.21117481051,718,,Saint-Sauveur,Saint-Sauveur,80,Somme,32,Hauts-de-France
80719,STE SEGREE,80290,STE SEGREE,,49.7602729981,1.91464272826,719,,Sainte-Segrée,Sainte-Segrée,80,Somme,32,Hauts-de-France
80721,ST VALERY SUR SOMME,80230,ST VALERY SUR SOMME,,50.1761767829,1.62262280183,721,,Saint-Valery-sur-Somme,Saint-Valery-sur-Somme,80,Somme,32,Hauts-de-France
80722,ST VAAST EN CHAUSSEE,80310,ST VAAST EN CHAUSSEE,,49.9709493809,2.20409804378,722,,Saint-Vaast-en-Chaussée,Saint-Vaast-en-Chaussée,80,Somme,32,Hauts-de-France
80723,SAISSEVAL,80540,SAISSEVAL,,49.9015320979,2.11887719645,723,,Saisseval,Saisseval,80,Somme,32,Hauts-de-France
80724,SALEUX,80480,SALEUX,,49.8620778346,2.21887634143,724,,Saleux,Saleux,80,Somme,32,Hauts-de-France
80725,SALOUEL,80480,SALOUEL,,49.8699278387,2.24181586447,725,,Salouël,Salouël,80,Somme,32,Hauts-de-France
80726,SANCOURT,80400,SANCOURT,,49.770246414,3.0440886833,726,,Sancourt,Sancourt,80,Somme,32,Hauts-de-France
80728,SAULCHOY SOUS POIX,80290,SAULCHOY SOUS POIX,,49.756979909,1.93890741162,728,,Saulchoy-sous-Poix,Saulchoy-sous-Poix,80,Somme,32,Hauts-de-France
80729,SAUVILLERS MONGIVAL,80110,SAUVILLERS MONGIVAL,,49.7197869951,2.46620070859,729,,Sauvillers-Mongival,Sauvillers-Mongival,80,Somme,32,Hauts-de-France
80730,SAVEUSE,80470,SAVEUSE,,49.8961064636,2.20932519977,730,,Saveuse,Saveuse,80,Somme,32,Hauts-de-France
80732,SENARPONT,80140,SENARPONT,,49.8914351461,1.71834605369,732,,Senarpont,Senarpont,80,Somme,32,Hauts-de-France
80733,SENLIS LE SEC,80300,SENLIS LE SEC,,50.0274617519,2.57771803132,733,,Senlis-le-Sec,Senlis-le-Sec,80,Somme,32,Hauts-de-France
80734,SENTELIE,80160,SENTELIE,,49.7209616189,2.02322646104,734,,Sentelie,Sentelie,80,Somme,32,Hauts-de-France
80735,SEUX,80540,SEUX,,49.8768298923,2.1056017445,735,,Seux,Seux,80,Somme,32,Hauts-de-France
80736,SOREL EN VIMEU,80490,SOREL EN VIMEU,,50.0125363922,1.91305863458,736,,Sorel-en-Vimeu,Sorel-en-Vimeu,80,Somme,32,Hauts-de-France
80737,SOREL,80240,SOREL,,50.0252192747,3.05248043426,737,,Sorel,Sorel,80,Somme,32,Hauts-de-France
80738,SOUES,80310,SOUES,,49.9531535495,2.05747243579,738,,Soues,Soues,80,Somme,32,Hauts-de-France
80740,SOURDON,80250,SOURDON,,49.7078498657,2.40164840104,740,,Sourdon,Sourdon,80,Somme,32,Hauts-de-France
80741,SOYECOURT,80200,SOYECOURT,,49.8638103438,2.79512790188,741,,Soyécourt,Soyécourt,80,Somme,32,Hauts-de-France
80742,SURCAMPS,80620,SURCAMPS,,50.0670190943,2.08374738527,742,,Surcamps,Surcamps,80,Somme,32,Hauts-de-France
80743,SUZANNE,80340,SUZANNE,,49.9592734284,2.76074272727,743,,Suzanne,Suzanne,80,Somme,32,Hauts-de-France
80744,TAILLY,80270,TAILLY L ARBRE A MOUCHES,,49.9346024966,1.94785661524,744,,Tailly,Tailly,80,Somme,32,Hauts-de-France
80746,TALMAS,80260,TALMAS,,50.0411880308,2.34426626176,746,,Talmas,Talmas,80,Somme,32,Hauts-de-France
80747,TEMPLEUX LA FOSSE,80240,TEMPLEUX LA FOSSE,,49.9713168478,3.02444783874,747,,Templeux-la-Fosse,Templeux-la-Fosse,80,Somme,32,Hauts-de-France
80748,TEMPLEUX LE GUERARD,80240,TEMPLEUX LE GUERARD,,49.9633304017,3.14471423364,748,,Templeux-le-Guérard,Templeux-le-Guérard,80,Somme,32,Hauts-de-France
80749,TERRAMESNIL,80600,TERRAMESNIL,,50.1013778468,2.38475513979,749,,Terramesnil,Terramesnil,80,Somme,32,Hauts-de-France
80750,TERTRY,80200,TERTRY,,49.8596732024,3.07111098486,750,,Tertry,Tertry,80,Somme,32,Hauts-de-France
80751,THENNES,80110,THENNES,,49.803442369,2.47935954884,751,,Thennes,Thennes,80,Somme,32,Hauts-de-France
80752,THEZY GLIMONT,80440,THEZY GLIMONT,,49.8215763774,2.43770954257,752,,Thézy-Glimont,Thézy-Glimont,80,Somme,32,Hauts-de-France
80753,THIEPVAL,80300,THIEPVAL,,50.0597825128,2.68781215568,753,,Thiepval,Thiepval,80,Somme,32,Hauts-de-France
80754,THIEULLOY L ABBAYE,80640,THIEULLOY L ABBAYE,,49.8202594794,1.94419558034,754,,Thieulloy-l'Abbaye,Thieulloy-l'Abbaye,80,Somme,32,Hauts-de-France
80755,THIEULLOY LA VILLE,80290,THIEULLOY LA VILLE,,49.7433577525,1.9296286879,755,,Thieulloy-la-Ville,Thieulloy-la-Ville,80,Somme,32,Hauts-de-France
80756,THIEVRES,62760,THIEVRES,,50.1183859138,2.46138718753,756,,Thièvres,Thièvres,80,Somme,32,Hauts-de-France
80757,THOIX,80160,THOIX,,49.7039687787,2.06442641818,757,,Thoix,Thoix,80,Somme,32,Hauts-de-France
80758,THORY,80250,THORY,,49.7199911827,2.43112270739,758,,Thory,Thory,80,Somme,32,Hauts-de-France
80759,TILLOLOY,80700,TILLOLOY,,49.6416492977,2.74418919931,759,,Tilloloy,Tilloloy,80,Somme,32,Hauts-de-France
80760,TILLOY FLORIVILLE,80220,TILLOY FLORIVILLE,,49.9915414775,1.59869864027,760,,Tilloy-Floriville,Tilloy-Floriville,80,Somme,32,Hauts-de-France
80761,TILLOY LES CONTY,80160,TILLOY LES CONTY,,49.7511352808,2.17791931023,761,,Tilloy-lès-Conty,Tilloy-lès-Conty,80,Somme,32,Hauts-de-France
80762,TINCOURT BOUCLY,80240,TINCOURT BOUCLY,,49.93851401,3.04921316346,762,,Tincourt-Boucly,Tincourt-Boucly,80,Somme,32,Hauts-de-France
80763,LE TITRE,80132,LE TITRE,,50.18824872,1.80178576821,763,Le,Titre,Le Titre,80,Somme,32,Hauts-de-France
80764,TOEUFLES,80870,TOEUFLES,,50.0624444273,1.71192475287,764,,Tœufles,Tœufles,80,Somme,32,Hauts-de-France
80765,TOURS EN VIMEU,80210,TOURS EN VIMEU,,50.0353174379,1.68426454279,765,,Tours-en-Vimeu,Tours-en-Vimeu,80,Somme,32,Hauts-de-France
80766,TOUTENCOURT,80560,TOUTENCOURT,,50.0454543871,2.46506066203,766,,Toutencourt,Toutencourt,80,Somme,32,Hauts-de-France
80767,LE TRANSLAY,80140,LE TRANSLAY,,49.9738240696,1.66432935832,767,Le,Translay,Le Translay,80,Somme,32,Hauts-de-France
80769,TREUX,80300,TREUX,,49.9513815906,2.59162319356,769,,Treux,Treux,80,Somme,32,Hauts-de-France
80770,TULLY,80130,TULLY,,50.0820037306,1.51459597406,770,,Tully,Tully,80,Somme,32,Hauts-de-France
80771,UGNY L EQUIPEE,80400,UGNY L EQUIPEE,,49.8148197837,3.06721029435,771,,Ugny-l'Équipée,Ugny-l'Équipée,80,Somme,32,Hauts-de-France
80773,VADENCOURT,80560,VADENCOURT,,50.0174902496,2.49046113345,773,,Vadencourt,Vadencourt,80,Somme,32,Hauts-de-France
80774,VAIRE SOUS CORBIE,80800,VAIRE SOUS CORBIE,,49.9016450158,2.5492857992,774,,Vaire-sous-Corbie,Vaire-sous-Corbie,80,Somme,32,Hauts-de-France
80775,VALINES,80210,VALINES,,50.0793776169,1.62717052139,775,,Valines,Valines,80,Somme,32,Hauts-de-France
80776,VARENNES,80560,VARENNES,,50.0455149826,2.51879170317,776,,Varennes,Varennes,80,Somme,32,Hauts-de-France
80777,VAUCHELLES LES AUTHIE,80560,VAUCHELLES LES AUTHIE,,50.0942754973,2.46950083799,777,,Vauchelles-lès-Authie,Vauchelles-lès-Authie,80,Somme,32,Hauts-de-France
80778,VAUCHELLES LES DOMART,80620,VAUCHELLES LES DOMART,,50.0565678591,2.05807152774,778,,Vauchelles-lès-Domart,Vauchelles-lès-Domart,80,Somme,32,Hauts-de-France
80779,VAUCHELLES LES QUESNOY,80132,VAUCHELLES LES QUESNOY,,50.103603975,1.88589423231,779,,Vauchelles-les-Quesnoy,Vauchelles-les-Quesnoy,80,Somme,32,Hauts-de-France
80780,VAUDRICOURT,80230,VAUDRICOURT,,50.1147458279,1.54855361274,780,,Vaudricourt,Vaudricourt,80,Somme,32,Hauts-de-France
80781,VAUVILLERS,80131,VAUVILLERS,,49.8430127523,2.7058576874,781,,Vauvillers,Vauvillers,80,Somme,32,Hauts-de-France
80782,VAUX EN AMIENOIS,80260,VAUX EN AMIENOIS,,49.9726260936,2.24284070578,782,,Vaux-en-Amiénois,Vaux-en-Amiénois,80,Somme,32,Hauts-de-France
80783,VAUX MARQUENNEVILLE,80140,VAUX MARQUENNEVILLE,,49.9885220384,1.78442284949,783,,Vaux-Marquenneville,Vaux-Marquenneville,80,Somme,32,Hauts-de-France
80784,VAUX SUR SOMME,80800,VAUX SUR SOMME,,49.9273645021,2.55257487917,784,,Vaux-sur-Somme,Vaux-sur-Somme,80,Somme,32,Hauts-de-France
80785,VECQUEMONT,80800,VECQUEMONT,,49.8906655541,2.44061102336,785,,Vecquemont,Vecquemont,80,Somme,32,Hauts-de-France
80786,VELENNES,80160,VELENNES,,49.7672246185,2.09877654821,786,,Velennes,Velennes,80,Somme,32,Hauts-de-France
80787,VERCOURT,80120,VERCOURT,,50.296430457,1.70416946449,787,,Vercourt,Vercourt,80,Somme,32,Hauts-de-France
80788,VERGIES,80270,VERGIES,,49.9342747834,1.84569726721,788,,Vergies,Vergies,80,Somme,32,Hauts-de-France
80789,VERMANDOVILLERS,80320,VERMANDOVILLERS,,49.8450788348,2.78314998144,789,,Vermandovillers,Vermandovillers,80,Somme,32,Hauts-de-France
80790,VERPILLIERES,80700,VERPILLIERES,,49.6670347072,2.82381303765,790,,Verpillières,Verpillières,80,Somme,32,Hauts-de-France
80791,VERS SUR SELLES,80480,VERS SUR SELLES,,49.8423395184,2.22500540619,791,,Vers-sur-Selle,Vers-sur-Selle,80,Somme,32,Hauts-de-France
80792,LA VICOGNE,80260,LA VICOGNE,,50.0639160287,2.32503477883,792,La,Vicogne,La Vicogne,80,Somme,32,Hauts-de-France
80793,VIGNACOURT,80650,VIGNACOURT,,50.0085076196,2.1867884288,793,,Vignacourt,Vignacourt,80,Somme,32,Hauts-de-France
80794,VILLECOURT,80190,VILLECOURT,,49.7984263719,2.97270989609,794,,Villecourt,Villecourt,80,Somme,32,Hauts-de-France
80795,VILLE LE MARCLET,80420,VILLE LE MARCLET,,50.0398514949,2.09051285594,795,,Ville-le-Marclet,Ville-le-Marclet,80,Somme,32,Hauts-de-France
80796,VILLEROY,80140,VILLEROY,,49.9393238443,1.72733691616,796,,Villeroy,Villeroy,80,Somme,32,Hauts-de-France
80797,VILLERS AUX ERABLES,80110,VILLERS AUX ERABLES,,49.7918542395,2.5288735418,797,,Villers-aux-Érables,Villers-aux-Érables,80,Somme,32,Hauts-de-France
80798,VILLERS BOCAGE,80260,VILLERS BOCAGE,,50.0046385115,2.32263311872,798,,Villers-Bocage,Villers-Bocage,80,Somme,32,Hauts-de-France
80799,VILLERS BRETONNEUX,80800,VILLERS BRETONNEUX,,49.8649909699,2.51946641308,799,,Villers-Bretonneux,Villers-Bretonneux,80,Somme,32,Hauts-de-France
80800,VILLERS CAMPSART,80140,VILLERS CAMPSART,,49.8698144741,1.83537806495,800,,Villers-Campsart,Villers-Campsart,80,Somme,32,Hauts-de-France
80801,VILLERS CARBONNEL,80200,VILLERS CARBONNEL,,49.8705708799,2.89376668433,801,,Villers-Carbonnel,Villers-Carbonnel,80,Somme,32,Hauts-de-France
80802,VILLERS FAUCON,80240,VILLERS FAUCON,,49.9770164855,3.10650017982,802,,Villers-Faucon,Villers-Faucon,80,Somme,32,Hauts-de-France
80803,VILLERS LES ROYE,80700,VILLERS LES ROYE,,49.7063943407,2.73634847272,803,,Villers-lès-Roye,Villers-lès-Roye,80,Somme,32,Hauts-de-France
80804,VILLERS SOUS AILLY,80690,VILLERS SOUS AILLY,,50.0624746155,2.01779325981,804,,Villers-sous-Ailly,Villers-sous-Ailly,80,Somme,32,Hauts-de-France
80805,VILLERS TOURNELLE,80500,VILLERS TOURNELLE,,49.6512167089,2.46394056647,805,,Villers-Tournelle,Villers-Tournelle,80,Somme,32,Hauts-de-France
80806,VILLERS SUR AUTHIE,80120,VILLERS SUR AUTHIE,,50.3184395938,1.69211423716,806,,Villers-sur-Authie,Villers-sur-Authie,80,Somme,32,Hauts-de-France
80807,VILLE SUR ANCRE,80300,VILLE SUR ANCRE,,49.9543257069,2.61358253822,807,,Ville-sur-Ancre,Ville-sur-Ancre,80,Somme,32,Hauts-de-France
80808,VIRONCHAUX,80150,VIRONCHAUX,,50.2981889645,1.82569820077,808,,Vironchaux,Vironchaux,80,Somme,32,Hauts-de-France
80809,VISMES,80140,VISMES AU VAL,,50.0029271112,1.6708655212,809,,Vismes,Vismes,80,Somme,32,Hauts-de-France
80810,VITZ SUR AUTHIE,80150,VITZ SUR AUTHIE,,50.2467291978,2.05531402827,810,,Vitz-sur-Authie,Vitz-sur-Authie,80,Somme,32,Hauts-de-France
80811,VOYENNES,80400,VOYENNES,,49.7690921443,2.9844476988,811,,Voyennes,Voyennes,80,Somme,32,Hauts-de-France
80812,VRAIGNES EN VERMANDOIS,80240,VRAIGNES EN VERMANDOIS,,49.8862393392,3.06728625058,812,,Vraignes-en-Vermandois,Vraignes-en-Vermandois,80,Somme,32,Hauts-de-France
80813,VRAIGNES LES HORNOY,80640,VRAIGNES LES HORNOY,,49.8241090128,1.90869386757,813,,Vraignes-lès-Hornoy,Vraignes-lès-Hornoy,80,Somme,32,Hauts-de-France
80814,VRELY,80170,VRELY,,49.7966377319,2.68672946995,814,,Vrély,Vrély,80,Somme,32,Hauts-de-France
80815,VRON,80120,VRON,,50.316036457,1.76410075845,815,,Vron,Vron,80,Somme,32,Hauts-de-France
80819,WARGNIES,80670,WARGNIES,,50.0364524864,2.25088888285,819,,Wargnies,Wargnies,80,Somme,32,Hauts-de-France
80820,WARLOY BAILLON,80300,WARLOY BAILLON,,50.0171155798,2.53081565399,820,,Warloy-Baillon,Warloy-Baillon,80,Somme,32,Hauts-de-France
80821,WARLUS,80270,WARLUS,,49.9175121218,1.95654713132,821,,Warlus,Warlus,80,Somme,32,Hauts-de-France
80822,WARSY,80500,WARSY,,49.7057838726,2.64397079237,822,,Warsy,Warsy,80,Somme,32,Hauts-de-France
80823,WARVILLERS,80170,WARVILLERS,,49.7798160362,2.69111887108,823,,Warvillers,Warvillers,80,Somme,32,Hauts-de-France
80824,WIENCOURT L EQUIPEE,80170,WIENCOURT L EQUIPEE,,49.840059378,2.60476090583,824,,Wiencourt-l'Équipée,Wiencourt-l'Équipée,80,Somme,32,Hauts-de-France
80825,WIRY AU MONT,80270,WIRY AU MONT,,49.9557938172,1.84218246146,825,,Wiry-au-Mont,Wiry-au-Mont,80,Somme,32,Hauts-de-France
80826,WOIGNARUE,80460,WOIGNARUE,,50.1195519125,1.48625273375,826,,Woignarue,Woignarue,80,Somme,32,Hauts-de-France
80827,WOINCOURT,80520,WOINCOURT,,50.0628349006,1.5410106177,827,,Woincourt,Woincourt,80,Somme,32,Hauts-de-France
80828,WOIREL,80140,WOIREL,,49.9589067876,1.81988569934,828,,Woirel,Woirel,80,Somme,32,Hauts-de-France
80829,Y,80190,Y,,49.8021266521,2.98611736598,829,,Y,Y,80,Somme,32,Hauts-de-France
80830,YAUCOURT BUSSUS,80135,YAUCOURT BUSSUS,,50.106026331,1.97674033898,830,,Yaucourt-Bussus,Yaucourt-Bussus,80,Somme,32,Hauts-de-France
80832,YVRENCH,80150,YVRENCH,,50.1846847389,2.01563427911,832,,Yvrench,Yvrench,80,Somme,32,Hauts-de-France
80833,YVRENCHEUX,80150,YVRENCHEUX,,50.177369499,1.98620389971,833,,Yvrencheux,Yvrencheux,80,Somme,32,Hauts-de-France
80834,YZENGREMER,80520,YZENGREMER,,50.0601121166,1.51829137537,834,,Yzengremer,Yzengremer,80,Somme,32,Hauts-de-France
80835,YZEUX,80310,YZEUX,,49.9793186736,2.11002578255,835,,Yzeux,Yzeux,80,Somme,32,Hauts-de-France
80836,YONVAL,80132,YONVAL,,50.0899110126,1.78671680483,836,,Yonval,Yonval,80,Somme,32,Hauts-de-France
81001,AGUTS,81470,AGUTS,,43.5319239166,1.92450141388,1,,Aguts,Aguts,81,Tarn,76,Occitanie
81002,AIGUEFONDE,81200,AIGUEFONDE,,43.4928550174,2.32315581418,2,,Aiguefonde,Aiguefonde,81,Tarn,76,Occitanie
81003,ALBAN,81250,ALBAN,,43.8843961225,2.45700986389,3,,Alban,Alban,81,Tarn,76,Occitanie
81004,ALBI,81000,ALBI,,43.9258213622,2.14686328555,4,,Albi,Albi,81,Tarn,76,Occitanie
81005,ALBINE,81240,ALBINE,,43.4439474577,2.52811969517,5,,Albine,Albine,81,Tarn,76,Occitanie
81006,ALGANS,81470,ALGANS,,43.6001082724,1.89151438957,6,,Algans,Algans,81,Tarn,76,Occitanie
81007,ALOS,81140,ALOS,,44.0205135175,1.87448422002,7,,Alos,Alos,81,Tarn,76,Occitanie
81008,ALMAYRAC,81190,ALMAYRAC,,44.0989296815,2.155985909,8,,Almayrac,Almayrac,81,Tarn,76,Occitanie
81009,AMARENS,81170,AMARENS,,44.0438938289,1.92108502728,9,,Amarens,Amarens,81,Tarn,76,Occitanie
81010,AMBIALET,81430,AMBIALET,,43.9263758841,2.37579999159,10,,Ambialet,Ambialet,81,Tarn,76,Occitanie
81011,AMBRES,81500,AMBRES,,43.7287344791,1.83182396743,11,,Ambres,Ambres,81,Tarn,76,Occitanie
81012,ANDILLAC,81140,ANDILLAC,,44.0051181728,1.89353888985,12,,Andillac,Andillac,81,Tarn,76,Occitanie
81013,ANDOUQUE,81350,ANDOUQUE,,44.0085365227,2.30256637897,13,,Andouque,Andouque,81,Tarn,76,Occitanie
81014,ANGLES,81260,ANGLES,,43.550474503,2.58241545036,14,,Anglès,Anglès,81,Tarn,76,Occitanie
81015,APPELLE,81700,APPELLE,,43.5806834944,1.95329824844,15,,Appelle,Appelle,81,Tarn,76,Occitanie
81016,ARFONS,81110,ARFONS,,43.4246280081,2.18966306697,16,,Arfons,Arfons,81,Tarn,76,Occitanie
81017,ARIFAT,81360,ARIFAT,,43.7758760162,2.3529229423,17,,Arifat,Arifat,81,Tarn,76,Occitanie
81018,ARTHES,81160,ARTHES,,43.9638815505,2.22587013595,18,,Arthès,Arthès,81,Tarn,76,Occitanie
81019,ASSAC,81340,ASSAC,,43.9784300195,2.43430907568,19,,Assac,Assac,81,Tarn,76,Occitanie
81020,AUSSAC,81600,AUSSAC,,43.8628657084,2.03857774636,20,,Aussac,Aussac,81,Tarn,76,Occitanie
81021,AUSSILLON,81200,AUSSILLON,,43.4946823944,2.35580492552,21,,Aussillon,Aussillon,81,Tarn,76,Occitanie
81022,BANNIERES,81500,BANNIERES,,43.6190407005,1.75804967667,22,,Bannières,Bannières,81,Tarn,76,Occitanie
81023,BARRE,81320,BARRE,,43.7432621915,2.81599308412,23,,Barre,Barre,81,Tarn,76,Occitanie
81024,BEAUVAIS SUR TESCOU,81630,BEAUVAIS SUR TESCOU,,43.8981519823,1.57160347509,24,,Beauvais-sur-Tescou,Beauvais-sur-Tescou,81,Tarn,76,Occitanie
81025,BELCASTEL,81500,BELCASTEL,,43.6490033004,1.7499203412,25,,Belcastel,Belcastel,81,Tarn,76,Occitanie
81026,BELLEGARDE MARSAL,81430,BELLEGARDE MARSAL,,43.9045721736,2.27727320222,26,,Bellegarde-Marsal,Bellegarde-Marsal,81,Tarn,76,Occitanie
81026,BELLEGARDE MARSAL,81430,BELLEGARDE MARSAL,MARSAL,43.9045721736,2.27727320222,26,,Bellegarde-Marsal,Bellegarde-Marsal,81,Tarn,76,Occitanie
81027,BELLESERRE,81540,BELLESERRE,,43.4898977584,2.05991842969,27,,Belleserre,Belleserre,81,Tarn,76,Occitanie
81028,BERLATS,81260,BERLATS,,43.6990584222,2.56292415401,28,,Berlats,Berlats,81,Tarn,76,Occitanie
81029,BERNAC,81150,BERNAC,,43.9532150821,2.02995456926,29,,Bernac,Bernac,81,Tarn,76,Occitanie
81030,BERTRE,81700,BERTRE,,43.6053640705,1.94349435829,30,,Bertre,Bertre,81,Tarn,76,Occitanie
81031,LE BEZ,81260,LE BEZ,,43.6222146708,2.45271515235,31,Le,Bez,Le Bez,81,Tarn,76,Occitanie
81032,BLAN,81700,BLAN,,43.5195201544,2.02369927552,32,,Blan,Blan,81,Tarn,76,Occitanie
81033,BLAYE LES MINES,81400,BLAYE LES MINES,,44.033065795,2.14278920127,33,,Blaye-les-Mines,Blaye-les-Mines,81,Tarn,76,Occitanie
81034,BOISSEZON,81490,BOISSEZON,,43.572194321,2.40561286973,34,,Boissezon,Boissezon,81,Tarn,76,Occitanie
81035,BOURNAZEL,81170,BOURNAZEL,,44.1008171987,1.96845675967,35,,Bournazel,Bournazel,81,Tarn,76,Occitanie
81036,BOUT DU PONT DE LARN,81660,BOUT DU PONT DE LARN,,43.5015583238,2.42981143239,36,,Bout-du-Pont-de-Larn,Bout-du-Pont-de-Larn,81,Tarn,76,Occitanie
81037,BRASSAC,81260,BRASSAC,,43.6117373541,2.51133931505,37,,Brassac,Brassac,81,Tarn,76,Occitanie
81038,BRENS,81600,BRENS,,43.8878107316,1.92967120122,38,,Brens,Brens,81,Tarn,76,Occitanie
81039,BRIATEXTE,81390,BRIATEXTE,,43.747193404,1.92377964856,39,,Briatexte,Briatexte,81,Tarn,76,Occitanie
81040,BROUSSE,81440,BROUSSE,,43.720141927,2.06153730693,40,,Brousse,Brousse,81,Tarn,76,Occitanie
81041,BROZE,81600,BROZE,,43.9545871483,1.89131601329,41,,Broze,Broze,81,Tarn,76,Occitanie
81042,BURLATS,81100,BURLATS,,43.634494226,2.32985791816,42,,Burlats,Burlats,81,Tarn,76,Occitanie
81043,BUSQUE,81300,BUSQUE,,43.7853547999,1.94837679226,43,,Busque,Busque,81,Tarn,76,Occitanie
81044,CABANES,81500,CABANES,,43.7158848933,1.94997109086,44,,Cabanès,Cabanès,81,Tarn,76,Occitanie
81045,LES CABANNES,81170,LES CABANNES,,44.0758753553,1.928670835,45,Les,Cabannes,Les Cabannes,81,Tarn,76,Occitanie
81046,CADALEN,81600,CADALEN,,43.8430473493,1.9835948491,46,,Cadalen,Cadalen,81,Tarn,76,Occitanie
81047,CADIX,81340,CADIX,,43.9764011774,2.4705615004,47,,Cadix,Cadix,81,Tarn,76,Occitanie
81048,CAGNAC LES MINES,81130,CAGNAC LES MINES,,43.9810206314,2.11921214,48,,Cagnac-les-Mines,Cagnac-les-Mines,81,Tarn,76,Occitanie
81049,CAHUZAC,81540,CAHUZAC,,43.4764567582,2.08126437181,49,,Cahuzac,Cahuzac,81,Tarn,76,Occitanie
81050,CAMBON LES LAVAUR,81470,CAMBON LES LAVAUR,,43.5919658336,1.84810953199,50,,Cambon-lès-Lavaur,Cambon-lès-Lavaur,81,Tarn,76,Occitanie
81051,CAHUZAC SUR VERE,81140,CAHUZAC SUR VERE,,43.9863939929,1.91547444957,51,,Cahuzac-sur-Vère,Cahuzac-sur-Vère,81,Tarn,76,Occitanie
81052,CAMBON,81990,CAMBON D ALBI,,43.9118287405,2.2237324057,52,,Cambon,Cambon,81,Tarn,76,Occitanie
81053,CAMBOUNES,81260,CAMBOUNES,,43.5907432712,2.43974230887,53,,Cambounès,Cambounès,81,Tarn,76,Occitanie
81054,CAMBOUNET SUR LE SOR,81580,CAMBOUNET SUR LE SOR,,43.5776046558,2.12002411847,54,,Cambounet-sur-le-Sor,Cambounet-sur-le-Sor,81,Tarn,76,Occitanie
81055,LES CAMMAZES,81540,LES CAMMAZES,,43.4110858173,2.07834620707,55,Les,Cammazes,Les Cammazes,81,Tarn,76,Occitanie
81056,CAMPAGNAC,81140,CAMPAGNAC,,44.0329274572,1.83921027036,56,,Campagnac,Campagnac,81,Tarn,76,Occitanie
81058,CARBES,81570,CARBES,,43.6353726667,2.15170395201,58,,Carbes,Carbes,81,Tarn,76,Occitanie
81059,CARLUS,81990,CARLUS,,43.8879968816,2.10436309581,59,,Carlus,Carlus,81,Tarn,76,Occitanie
81060,CARMAUX,81400,CARMAUX,,44.0566667256,2.16641418483,60,,Carmaux,Carmaux,81,Tarn,76,Occitanie
81061,CASTANET,81150,CASTANET,,43.9734777518,2.03636220381,61,,Castanet,Castanet,81,Tarn,76,Occitanie
81062,FONTRIEU,81260,FONTRIEU,CASTELNAU DE BRASSAC,43.6578748652,2.52758390349,62,,Fontrieu,Fontrieu,81,Tarn,76,Occitanie
81062,FONTRIEU,81260,FONTRIEU,FERRIERES,43.6578748652,2.52758390349,62,,Fontrieu,Fontrieu,81,Tarn,76,Occitanie
81062,FONTRIEU,81260,FONTRIEU,LE MARGNES,43.6578748652,2.52758390349,62,,Fontrieu,Fontrieu,81,Tarn,76,Occitanie
81063,CASTELNAU DE LEVIS,81150,CASTELNAU DE LEVIS,,43.9437169399,2.07110041598,63,,Castelnau-de-Lévis,Castelnau-de-Lévis,81,Tarn,76,Occitanie
81064,CASTELNAU DE MONTMIRAL,81140,CASTELNAU DE MONTMIRAL,,43.9867805665,1.78310029174,64,,Castelnau-de-Montmiral,Castelnau-de-Montmiral,81,Tarn,76,Occitanie
81065,CASTRES,81100,CASTRES,,43.6156511237,2.23787231587,65,,Castres,Castres,81,Tarn,76,Occitanie
81066,CAUCALIERES,81200,CAUCALIERES,,43.5399609489,2.31043142734,66,,Caucalières,Caucalières,81,Tarn,76,Occitanie
81067,CESTAYROLS,81150,CESTAYROLS,,43.9825675453,1.98780845646,67,,Cestayrols,Cestayrols,81,Tarn,76,Occitanie
81068,COMBEFA,81640,COMBEFA,,44.0498006457,2.09498362646,68,,Combefa,Combefa,81,Tarn,76,Occitanie
81069,CORDES SUR CIEL,81170,CORDES SUR CIEL,,44.0681683828,1.95360871103,69,,Cordes-sur-Ciel,Cordes-sur-Ciel,81,Tarn,76,Occitanie
81070,COUFOULEUX,81800,COUFOULEUX,,43.7978489409,1.73158426557,70,,Coufouleux,Coufouleux,81,Tarn,76,Occitanie
81071,COURRIS,81340,COURRIS,,43.9567412395,2.40226789495,71,,Courris,Courris,81,Tarn,76,Occitanie
81072,CRESPIN,81350,CRESPIN,,44.0469014925,2.30614613299,72,,Crespin,Crespin,81,Tarn,76,Occitanie
81073,CRESPINET,81350,CRESPINET,,43.9551249562,2.29516613633,73,,Crespinet,Crespinet,81,Tarn,76,Occitanie
81074,CUNAC,81990,CUNAC,,43.9274396398,2.22514974494,74,,Cunac,Cunac,81,Tarn,76,Occitanie
81075,CUQ,81570,CUQ LES VIELMUR,,43.6534622232,2.08686344957,75,,Cuq,Cuq,81,Tarn,76,Occitanie
81076,CUQ TOULZA,81470,CUQ TOULZA,,43.5623792755,1.88686468644,76,,Cuq-Toulza,Cuq-Toulza,81,Tarn,76,Occitanie
81077,CURVALLE,81250,CURVALLE,,43.911329791,2.50746154724,77,,Curvalle,Curvalle,81,Tarn,76,Occitanie
81078,DAMIATTE,81220,DAMIATTE,,43.6801644905,1.98487101085,78,,Damiatte,Damiatte,81,Tarn,76,Occitanie
81079,DENAT,81120,DENAT,,43.8395342344,2.20292263193,79,,Dénat,Dénat,81,Tarn,76,Occitanie
81080,DONNAZAC,81170,DONNAZAC,,44.0124743506,1.94438171897,80,,Donnazac,Donnazac,81,Tarn,76,Occitanie
81081,DOURGNE,81110,DOURGNE,,43.4753070746,2.14244605538,81,,Dourgne,Dourgne,81,Tarn,76,Occitanie
81082,LE DOURN,81340,LE DOURN,,44.0155897925,2.47073380785,82,Le,Dourn,Le Dourn,81,Tarn,76,Occitanie
81083,DURFORT,81540,DURFORT,,43.4316297615,2.07369430679,83,,Durfort,Durfort,81,Tarn,76,Occitanie
81084,ESCOUSSENS,81290,ESCOUSSENS,,43.4822756264,2.22873305615,84,,Escoussens,Escoussens,81,Tarn,76,Occitanie
81085,ESCROUX,81530,ESCROUX,,43.7575872342,2.64191518496,85,,Escroux,Escroux,81,Tarn,76,Occitanie
81086,ESPERAUSSES,81260,ESPERAUSSES,,43.6998410233,2.52506371565,86,,Espérausses,Espérausses,81,Tarn,76,Occitanie
81087,FAYSSAC,81150,FAYSSAC,,43.9601258045,1.98037928954,87,,Fayssac,Fayssac,81,Tarn,76,Occitanie
81088,FAUCH,81120,FAUCH,,43.8391719667,2.25836350572,88,,Fauch,Fauch,81,Tarn,76,Occitanie
81089,FAUSSERGUES,81340,FAUSSERGUES,,44.0406000553,2.43023975408,89,,Faussergues,Faussergues,81,Tarn,76,Occitanie
81090,FENOLS,81600,FENOLS,,43.8495035215,2.0559946114,90,,Fénols,Fénols,81,Tarn,76,Occitanie
81092,FIAC,81500,FIAC,,43.699007933,1.90988416246,92,,Fiac,Fiac,81,Tarn,76,Occitanie
81093,FLORENTIN,81150,FLORENTIN,,43.8854183688,2.02985845557,93,,Florentin,Florentin,81,Tarn,76,Occitanie
81094,FRAISSINES,81340,FRAISSINES,,43.9732107109,2.50810158198,94,,Fraissines,Fraissines,81,Tarn,76,Occitanie
81095,FRAUSSEILLES,81170,FRAUSSEILLES,,44.0296677126,1.92883903661,95,,Frausseilles,Frausseilles,81,Tarn,76,Occitanie
81096,LE FRAYSSE,81430,LE FRAYSSE,,43.9068214611,2.41660067701,96,Le,Fraysse,Le Fraysse,81,Tarn,76,Occitanie
81097,FREJAIROLLES,81990,FREJAIROLLES,,43.8786600972,2.23043162457,97,,Fréjairolles,Fréjairolles,81,Tarn,76,Occitanie
81098,FREJEVILLE,81570,FREJEVILLE,,43.6088880274,2.14739300043,98,,Fréjeville,Fréjeville,81,Tarn,76,Occitanie
81099,GAILLAC,81600,GAILLAC,,43.9170968776,1.88647213801,99,,Gaillac,Gaillac,81,Tarn,76,Occitanie
81100,GARREVAQUES,81700,GARREVAQUES,,43.4927448733,1.98351501119,100,,Garrevaques,Garrevaques,81,Tarn,76,Occitanie
81101,LE GARRIC,81450,LE GARRIC,,44.0037171687,2.17389452679,101,Le,Garric,Le Garric,81,Tarn,76,Occitanie
81102,GARRIGUES,81500,GARRIGUES,,43.7023216398,1.70252072015,102,,Garrigues,Garrigues,81,Tarn,76,Occitanie
81103,GIJOUNET,81530,GIJOUNET,,43.7086383837,2.6070098158,103,,Gijounet,Gijounet,81,Tarn,76,Occitanie
81104,GIROUSSENS,81500,GIROUSSENS,,43.769779769,1.7967421125,104,,Giroussens,Giroussens,81,Tarn,76,Occitanie
81105,GRAULHET,81300,GRAULHET,,43.7576562195,2.00122512851,105,,Graulhet,Graulhet,81,Tarn,76,Occitanie
81106,GRAZAC,81800,GRAZAC,,43.8499819976,1.64948464034,106,,Grazac,Grazac,81,Tarn,76,Occitanie
81108,ITZAC,81170,ITZAC,,44.0506305724,1.84654889285,108,,Itzac,Itzac,81,Tarn,76,Occitanie
81109,JONQUIERES,81440,JONQUIERES,,43.6585990989,2.14341169546,109,,Jonquières,Jonquières,81,Tarn,76,Occitanie
81110,JOUQUEVIEL,81190,JOUQUEVIEL,,44.1816769141,2.13340021405,110,,Jouqueviel,Jouqueviel,81,Tarn,76,Occitanie
81111,LABARTHE BLEYS,81170,LABARTHE BLEYS,,44.0838134122,1.89558851535,111,,Labarthe-Bleys,Labarthe-Bleys,81,Tarn,76,Occitanie
81112,LABASTIDE DE LEVIS,81150,LABASTIDE DE LEVIS,,43.9310810991,2.00269049249,112,,Labastide-de-Lévis,Labastide-de-Lévis,81,Tarn,76,Occitanie
81113,LABASTIDE DENAT,81120,LABASTIDE DENAT,,43.867545441,2.1996143025,113,,Labastide-Dénat,Labastide-Dénat,81,Tarn,76,Occitanie
81114,LABASTIDE GABAUSSE,81400,LABASTIDE GABAUSSE,,44.0310798873,2.09978207665,114,,Labastide-Gabausse,Labastide-Gabausse,81,Tarn,76,Occitanie
81115,LABASTIDE ROUAIROUX,81270,LABASTIDE ROUAIROUX,,43.4826602247,2.63557921234,115,,Labastide-Rouairoux,Labastide-Rouairoux,81,Tarn,76,Occitanie
81116,LABASTIDE ST GEORGES,81500,LABASTIDE ST GEORGES,,43.6998934044,1.84536170011,116,,Labastide-Saint-Georges,Labastide-Saint-Georges,81,Tarn,76,Occitanie
81117,LABESSIERE CANDEIL,81300,LABESSIERE CANDEIL,,43.8006328321,2.02071432029,117,,Labessière-Candeil,Labessière-Candeil,81,Tarn,76,Occitanie
81118,LABOULBENE,81100,LABOULBENE,,43.6660569606,2.20401840916,118,,Laboulbène,Laboulbène,81,Tarn,76,Occitanie
81119,LABOUTARIE,81120,LABOUTARIE,,43.7886845517,2.11353900719,119,,Laboutarie,Laboutarie,81,Tarn,76,Occitanie
81120,LABRUGUIERE,81290,LABRUGUIERE,,43.5090050313,2.26662007488,120,,Labruguière,Labruguière,81,Tarn,76,Occitanie
81121,LACABAREDE,81240,LACABAREDE,,43.4490307181,2.593058648,121,,Lacabarède,Lacabarède,81,Tarn,76,Occitanie
81122,LACAPELLE PINET,81340,LACAPELLE PINET,,44.0677076414,2.35434693156,122,,Lacapelle-Pinet,Lacapelle-Pinet,81,Tarn,76,Occitanie
81123,LACAPELLE SEGALAR,81170,LACAPELLE SEGALAR,,44.1174207039,1.99686305545,123,,Lacapelle-Ségalar,Lacapelle-Ségalar,81,Tarn,76,Occitanie
81124,LACAUNE,81230,LACAUNE,,43.6972753085,2.68833285874,124,,Lacaune,Lacaune,81,Tarn,76,Occitanie
81125,LACAZE,81330,LACAZE,,43.7430264431,2.50920247729,125,,Lacaze,Lacaze,81,Tarn,76,Occitanie
81126,LACOUGOTTE CADOUL,81500,LACOUGOTTE CADOUL,,43.6418245193,1.82103741651,126,,Lacougotte-Cadoul,Lacougotte-Cadoul,81,Tarn,76,Occitanie
81127,LACROISILLE,81470,LACROISILLE,,43.58053089,1.92863316637,127,,Lacroisille,Lacroisille,81,Tarn,76,Occitanie
81128,LACROUZETTE,81210,LACROUZETTE,,43.6622260196,2.36167467964,128,,Lacrouzette,Lacrouzette,81,Tarn,76,Occitanie
81129,LAGARDIOLLE,81110,LAGARDIOLLE,,43.4996169804,2.0926664241,129,,Lagardiolle,Lagardiolle,81,Tarn,76,Occitanie
81130,LAGARRIGUE,81090,LAGARRIGUE,,43.5759645911,2.28583565829,130,,Lagarrigue,Lagarrigue,81,Tarn,76,Occitanie
81131,LAGRAVE,81150,LAGRAVE,,43.8931087297,1.99033789406,131,,Lagrave,Lagrave,81,Tarn,76,Occitanie
81132,GUITALENS L ALBAREDE,81220,GUITALENS L ALBAREDE,,43.6378592707,2.04358289042,132,,Guitalens-L'Albarède,Guitalens-L'Albarède,81,Tarn,76,Occitanie
81132,GUITALENS L ALBAREDE,81220,GUITALENS L ALBAREDE,GUITALENS,43.6378592707,2.04358289042,132,,Guitalens-L'Albarède,Guitalens-L'Albarède,81,Tarn,76,Occitanie
81133,LAMILLARIE,81120,LAMILLARIE,,43.8550180871,2.15786661498,133,,Lamillarié,Lamillarié,81,Tarn,76,Occitanie
81134,LAMONTELARIE,81260,LAMONTELARIE,,43.6201473366,2.6080895123,134,,Lamontélarié,Lamontélarié,81,Tarn,76,Occitanie
81135,LAPARROUQUIAL,81640,LAPARROUQUIAL,,44.1216197815,2.02846018136,135,,Laparrouquial,Laparrouquial,81,Tarn,76,Occitanie
81136,LARROQUE,81140,LARROQUE,,44.0083844325,1.68734477168,136,,Larroque,Larroque,81,Tarn,76,Occitanie
81137,LASFAILLADES,81260,LASFAILLADES,,43.5636947097,2.50028981055,137,,Lasfaillades,Lasfaillades,81,Tarn,76,Occitanie
81138,LASGRAISSES,81300,LASGRAISSES,,43.8230864344,2.03526038178,138,,Lasgraisses,Lasgraisses,81,Tarn,76,Occitanie
81139,LAUTREC,81440,LAUTREC,,43.7052468491,2.12929403349,139,,Lautrec,Lautrec,81,Tarn,76,Occitanie
81140,LAVAUR,81500,LAVAUR,,43.6895628711,1.79437213708,140,,Lavaur,Lavaur,81,Tarn,76,Occitanie
81141,LEDAS ET PENTHIES,81340,LEDAS ET PENTHIES,,44.0788993791,2.38301676967,141,,Lédas-et-Penthiès,Lédas-et-Penthiès,81,Tarn,76,Occitanie
81142,LEMPAUT,81700,LEMPAUT,,43.5250362218,2.06781659289,142,,Lempaut,Lempaut,81,Tarn,76,Occitanie
81143,LESCOUT,81110,LESCOUT,,43.5334899853,2.10628478889,143,,Lescout,Lescout,81,Tarn,76,Occitanie
81144,LESCURE D ALBIGEOIS,81380,LESCURE D ALBIGEOIS,,43.9656164383,2.18230929953,144,,Lescure-d'Albigeois,Lescure-d'Albigeois,81,Tarn,76,Occitanie
81145,LISLE SUR TARN,81310,LISLE SUR TARN,,43.8974980421,1.78200143861,145,,Lisle-sur-Tarn,Lisle-sur-Tarn,81,Tarn,76,Occitanie
81146,LIVERS CAZELLES,81170,LIVERS CAZELLES,,44.0494424357,2.00041907308,146,,Livers-Cazelles,Livers-Cazelles,81,Tarn,76,Occitanie
81147,LOMBERS,81120,LOMBERS,,43.8159438532,2.1438851027,147,,Lombers,Lombers,81,Tarn,76,Occitanie
81148,LOUBERS,81170,LOUBERS,,44.0377793598,1.8934533217,148,,Loubers,Loubers,81,Tarn,76,Occitanie
81149,LOUPIAC,81800,LOUPIAC,,43.8241806897,1.78265206515,149,,Loupiac,Loupiac,81,Tarn,76,Occitanie
81150,LUGAN,81500,LUGAN,,43.7330225833,1.71750529961,150,,Lugan,Lugan,81,Tarn,76,Occitanie
81151,MAGRIN,81220,MAGRIN,,43.61527201,1.9211779967,151,,Magrin,Magrin,81,Tarn,76,Occitanie
81152,MAILHOC,81130,MAILHOC,,44.00390722,2.07332780966,152,,Mailhoc,Mailhoc,81,Tarn,76,Occitanie
81154,MARNAVES,81170,MARNAVES,,44.0959603431,1.87162676035,154,,Marnaves,Marnaves,81,Tarn,76,Occitanie
81156,MARSSAC SUR TARN,81150,MARSSAC SUR TARN,,43.9102726323,2.03641141101,156,,Marssac-sur-Tarn,Marssac-sur-Tarn,81,Tarn,76,Occitanie
81157,MARZENS,81500,MARZENS,,43.6542488768,1.84294854921,157,,Marzens,Marzens,81,Tarn,76,Occitanie
81158,LE MASNAU MASSUGUIES,81530,LE MASNAU MASSUGUIES,,43.8093121675,2.53889440751,158,Le,Masnau-Massuguiès,Le Masnau-Massuguiès,81,Tarn,76,Occitanie
81159,MASSAC SERAN,81500,MASSAC SERAN,,43.6683120665,1.86258553389,159,,Massac-Séran,Massac-Séran,81,Tarn,76,Occitanie
81160,MASSAGUEL,81110,MASSAGUEL,,43.4756759795,2.17631948786,160,,Massaguel,Massaguel,81,Tarn,76,Occitanie
81161,MASSALS,81250,MASSALS,,43.8582915484,2.52110996893,161,,Massals,Massals,81,Tarn,76,Occitanie
81162,MAURENS SCOPONT,81470,MAURENS SCOPONT,,43.5965308671,1.81343450611,162,,Maurens-Scopont,Maurens-Scopont,81,Tarn,76,Occitanie
81163,MAZAMET,81200,MAZAMET,,43.4578780341,2.3885346913,163,,Mazamet,Mazamet,81,Tarn,76,Occitanie
81164,MEZENS,81800,MEZENS,,43.7911506401,1.65853736504,164,,Mézens,Mézens,81,Tarn,76,Occitanie
81165,MILHARS,81170,MILHARS,,44.1227182576,1.87476688582,165,,Milhars,Milhars,81,Tarn,76,Occitanie
81166,MILHAVET,81130,MILHAVET,,44.0326429455,2.02741008603,166,,Milhavet,Milhavet,81,Tarn,76,Occitanie
81167,MIOLLES,81250,MIOLLES,,43.8782226811,2.55075875276,167,,Miolles,Miolles,81,Tarn,76,Occitanie
81168,MIRANDOL BOURGNOUNAC,81190,MIRANDOL BOURGNOUNAC,,44.1468118913,2.17074535227,168,,Mirandol-Bourgnounac,Mirandol-Bourgnounac,81,Tarn,76,Occitanie
81169,MISSECLE,81300,MISSECLE,,43.7204559447,1.98643784492,169,,Missècle,Missècle,81,Tarn,76,Occitanie
81170,MONESTIES,81640,MONESTIES,,44.0787915889,2.09845237087,170,,Monestiés,Monestiés,81,Tarn,76,Occitanie
81171,MONTANS,81600,MONTANS,,43.850225684,1.86631554262,171,,Montans,Montans,81,Tarn,76,Occitanie
81172,MONTAURIOL,81190,MONTAURIOL,,44.0815510672,2.32726239581,172,,Montauriol,Montauriol,81,Tarn,76,Occitanie
81173,MONTCABRIER,81500,MONTCABRIER,,43.6216995124,1.73631591494,173,,Montcabrier,Montcabrier,81,Tarn,76,Occitanie
81174,MONTDRAGON,81440,MONTDRAGON,,43.7753099488,2.09549518318,174,,Montdragon,Montdragon,81,Tarn,76,Occitanie
81175,MONTDURAUSSE,81630,MONTDURAUSSE,,43.9525325063,1.59348701072,175,,Montdurausse,Montdurausse,81,Tarn,76,Occitanie
81176,MONTELS,81140,MONTELS,,43.9645484948,1.88698205993,176,,Montels,Montels,81,Tarn,76,Occitanie
81177,MONTFA,81210,MONTFA,,43.7002321561,2.22942914563,177,,Montfa,Montfa,81,Tarn,76,Occitanie
81178,MONTGAILLARD,81630,MONTGAILLARD,,43.9080851557,1.60943104266,178,,Montgaillard,Montgaillard,81,Tarn,76,Occitanie
81179,MONTGEY,81470,MONTGEY,,43.5093604,1.9451968725,179,,Montgey,Montgey,81,Tarn,76,Occitanie
81180,MONTIRAT,81190,MONTIRAT,,44.1584171912,2.092684783,180,,Montirat,Montirat,81,Tarn,76,Occitanie
81181,MONTPINIER,81440,MONTPINIER,,43.6789611247,2.18996517245,181,,Montpinier,Montpinier,81,Tarn,76,Occitanie
81182,MONTREDON LABESSONNIE,81360,MONTREDON LABESSONNIE,,43.7243034141,2.32124891411,182,,Montredon-Labessonnié,Montredon-Labessonnié,81,Tarn,76,Occitanie
81183,MONT ROC,81120,MONT ROC,,43.8056390894,2.37632529774,183,,Mont-Roc,Mont-Roc,81,Tarn,76,Occitanie
81184,MONTROSIER,81170,MONTROSIER,,44.1302381155,1.84091338428,184,,Montrosier,Montrosier,81,Tarn,76,Occitanie
81185,MONTVALEN,81630,MONTVALEN,,43.8568846629,1.59279252787,185,,Montvalen,Montvalen,81,Tarn,76,Occitanie
81186,MOULARES,81190,MOULARES,,44.0770712635,2.28567831942,186,,Moularès,Moularès,81,Tarn,76,Occitanie
81187,MOULAYRES,81300,MOULAYRES,,43.7043614884,2.02400479753,187,,Moulayrès,Moulayrès,81,Tarn,76,Occitanie
81188,MOULIN MAGE,81320,MOULIN MAGE,,43.7198358431,2.77600372304,188,,Moulin-Mage,Moulin-Mage,81,Tarn,76,Occitanie
81189,MOUZENS,81470,MOUZENS,,43.5319509322,1.88969318814,189,,Mouzens,Mouzens,81,Tarn,76,Occitanie
81190,MOUZIEYS TEULET,81430,MOUZIEYS TEULET,,43.8784071137,2.27301087499,190,,Mouzieys-Teulet,Mouzieys-Teulet,81,Tarn,76,Occitanie
81191,MOUZIEYS PANENS,81170,MOUZIEYS PANENS,,44.1054127277,1.93295083712,191,,Mouzieys-Panens,Mouzieys-Panens,81,Tarn,76,Occitanie
81192,MURAT SUR VEBRE,81320,MURAT SUR VEBRE,,43.6933887734,2.86020880416,192,,Murat-sur-Vèbre,Murat-sur-Vèbre,81,Tarn,76,Occitanie
81193,NAGES,81320,NAGES,,43.6552500553,2.77047071687,193,,Nages,Nages,81,Tarn,76,Occitanie
81195,NAVES,81710,NAVES,,43.5644376165,2.21838218726,195,,Navès,Navès,81,Tarn,76,Occitanie
81196,NOAILHAC,81490,NOAILHAC,,43.5760317808,2.35409410035,196,,Noailhac,Noailhac,81,Tarn,76,Occitanie
81197,NOAILLES,81170,NOAILLES,,44.0122054489,1.99020662698,197,,Noailles,Noailles,81,Tarn,76,Occitanie
81198,ORBAN,81120,ORBAN,,43.8372858734,2.0783975338,198,,Orban,Orban,81,Tarn,76,Occitanie
81199,PADIES,81340,PADIES,,44.0448721385,2.36414366845,199,,Padiès,Padiès,81,Tarn,76,Occitanie
81200,PALLEVILLE,81700,PALLEVILLE,,43.5037421049,1.9961127405,200,,Palleville,Palleville,81,Tarn,76,Occitanie
81201,PAMPELONNE,81190,PAMPELONNE,,44.1158346297,2.24230073337,201,,Pampelonne,Pampelonne,81,Tarn,76,Occitanie
81202,PARISOT,81310,PARISOT,,43.8046304256,1.83642655485,202,,Parisot,Parisot,81,Tarn,76,Occitanie
81203,PAULINET,81250,PAULINET,,43.8471083699,2.43945006878,203,,Paulinet,Paulinet,81,Tarn,76,Occitanie
81204,PAYRIN AUGMONTEL,81660,PAYRIN AUGMONTEL,,43.538512955,2.34476965371,204,,Payrin-Augmontel,Payrin-Augmontel,81,Tarn,76,Occitanie
81205,PECHAUDIER,81470,PECHAUDIER,,43.5445327743,1.94651910659,205,,Péchaudier,Péchaudier,81,Tarn,76,Occitanie
81206,PENNE,81140,PENNE,,44.083378684,1.72222569874,206,,Penne,Penne,81,Tarn,76,Occitanie
81207,PEYREGOUX,81440,PEYREGOUX,,43.7005423286,2.19477882918,207,,Peyregoux,Peyregoux,81,Tarn,76,Occitanie
81208,PEYROLE,81310,PEYROLE,,43.8140246495,1.89716126791,208,,Peyrole,Peyrole,81,Tarn,76,Occitanie
81209,PONT DE LARN,81660,PONT DE LARN,,43.5308469607,2.40980470346,209,,Pont-de-Larn,Pont-de-Larn,81,Tarn,76,Occitanie
81210,POUDIS,81700,POUDIS,,43.5242225907,1.98138103273,210,,Poudis,Poudis,81,Tarn,76,Occitanie
81211,POULAN POUZOLS,81120,POULAN POUZOLS,,43.8582952155,2.10793696053,211,,Poulan-Pouzols,Poulan-Pouzols,81,Tarn,76,Occitanie
81212,PRADES,81220,PRADES,,43.6119091506,1.9714190668,212,,Prades,Prades,81,Tarn,76,Occitanie
81213,PRATVIEL,81500,PRATVIEL,,43.638704181,1.88490760875,213,,Pratviel,Pratviel,81,Tarn,76,Occitanie
81214,PUECHOURSI,81470,PUECHOURSI,,43.5139823585,1.90748676323,214,,Puéchoursi,Puéchoursi,81,Tarn,76,Occitanie
81215,PUYBEGON,81390,PUYBEGON,,43.7801538016,1.89684517296,215,,Puybegon,Puybegon,81,Tarn,76,Occitanie
81216,PUYCALVEL,81440,PUYCALVEL,,43.6838355288,2.07099624199,216,,Puycalvel,Puycalvel,81,Tarn,76,Occitanie
81217,PUYCELSI,81140,PUYCELSI,,43.9765161784,1.70709054548,217,,Puycelsi,Puycelsi,81,Tarn,76,Occitanie
81218,PUYGOUZON,81990,PUYGOUZON,,43.8884573228,2.16955643928,218,,Puygouzon,Puygouzon,81,Tarn,76,Occitanie
81219,PUYLAURENS,81700,PUYLAURENS,,43.5824524921,2.01685800368,219,,Puylaurens,Puylaurens,81,Tarn,76,Occitanie
81220,RABASTENS,81800,RABASTENS,,43.8484040939,1.71495143196,220,,Rabastens,Rabastens,81,Tarn,76,Occitanie
81221,RAYSSAC,81330,RAYSSAC,,43.8037266652,2.42461409466,221,,Rayssac,Rayssac,81,Tarn,76,Occitanie
81222,REALMONT,81120,REALMONT,,43.7799765873,2.19017910647,222,,Réalmont,Réalmont,81,Tarn,76,Occitanie
81223,LE RIALET,81240,LE RIALET,,43.5590728174,2.47081958622,223,Le,Rialet,Le Rialet,81,Tarn,76,Occitanie
81224,LE RIOLS,81170,LE RIOLS,,44.1532720289,1.91550185961,224,Le,Riols,Le Riols,81,Tarn,76,Occitanie
81225,RIVIERES,81600,RIVIERES,,43.9157732969,1.96947284122,225,,Rivières,Rivières,81,Tarn,76,Occitanie
81226,RONEL,81120,RONEL,,43.80989951,2.21337484282,226,,Ronel,Ronel,81,Tarn,76,Occitanie
81227,ROQUECOURBE,81210,ROQUECOURBE,,43.6726544083,2.27758521528,227,,Roquecourbe,Roquecourbe,81,Tarn,76,Occitanie
81228,ROQUEMAURE,81800,ROQUEMAURE,,43.8230103992,1.61753468648,228,,Roquemaure,Roquemaure,81,Tarn,76,Occitanie
81229,ROQUEVIDAL,81470,ROQUEVIDAL,,43.6227131725,1.86053264834,229,,Roquevidal,Roquevidal,81,Tarn,76,Occitanie
81230,ROSIERES,81400,ROSIERES,,44.0411555772,2.19724002021,230,,Rosières,Rosières,81,Tarn,76,Occitanie
81231,ROUAIROUX,81240,ROUAIROUX,,43.4951010568,2.56621532287,231,,Rouairoux,Rouairoux,81,Tarn,76,Occitanie
81232,ROUFFIAC,81150,ROUFFIAC,,43.8826639291,2.07172030466,232,,Rouffiac,Rouffiac,81,Tarn,76,Occitanie
81233,ROUMEGOUX,81120,ROUMEGOUX,,43.8091924031,2.26353154386,233,,Roumégoux,Roumégoux,81,Tarn,76,Occitanie
81234,ROUSSAYROLLES,81140,ROUSSAYROLLES,,44.0939494388,1.8260511519,234,,Roussayrolles,Roussayrolles,81,Tarn,76,Occitanie
81235,ST AFFRIQUE LES MONTAGNES,81290,ST AFFRIQUE LES MONTAGNES,,43.5281225419,2.20135060333,235,,Saint-Affrique-les-Montagnes,Saint-Affrique-les-Montagnes,81,Tarn,76,Occitanie
81236,ST AGNAN,81500,ST AGNAN,,43.6956511222,1.74062164664,236,,Saint-Agnan,Saint-Agnan,81,Tarn,76,Occitanie
81237,ST AMANCET,81110,ST AMANCET,,43.4634376908,2.10916260669,237,,Saint-Amancet,Saint-Amancet,81,Tarn,76,Occitanie
81238,ST AMANS SOULT,81240,ST AMANS SOULT,,43.4644785772,2.47706341258,238,,Saint-Amans-Soult,Saint-Amans-Soult,81,Tarn,76,Occitanie
81239,ST AMANS VALTORET,81240,ST AMANS VALTORET,,43.506155075,2.50072448307,239,,Saint-Amans-Valtoret,Saint-Amans-Valtoret,81,Tarn,76,Occitanie
81240,ST ANDRE,81250,ST ANDRE,,43.9326309582,2.45817547307,240,,Saint-André,Saint-André,81,Tarn,76,Occitanie
81241,ST ANTONIN DE LACALM,81120,ST ANTONIN DE LACALM,,43.7817652694,2.29527252552,241,,Saint-Antonin-de-Lacalm,Saint-Antonin-de-Lacalm,81,Tarn,76,Occitanie
81242,ST AVIT,81110,ST AVIT,,43.5156735271,2.11791103076,242,,Saint-Avit,Saint-Avit,81,Tarn,76,Occitanie
81243,ST BEAUZILE,81140,ST BEAUZILE,,44.0274349161,1.81723438906,243,,Saint-Beauzile,Saint-Beauzile,81,Tarn,76,Occitanie
81244,ST BENOIT DE CARMAUX,81400,ST BENOIT DE CARMAUX,,44.0544138951,2.12958421961,244,,Saint-Benoît-de-Carmaux,Saint-Benoît-de-Carmaux,81,Tarn,76,Occitanie
81245,ST CHRISTOPHE,81190,ST CHRISTOPHE,,44.1526839554,2.0435980246,245,,Saint-Christophe,Saint-Christophe,81,Tarn,76,Occitanie
81245,ST CHRISTOPHE,81190,ST CHRISTOPHE,NARTHOUX,44.1526839554,2.0435980246,245,,Saint-Christophe,Saint-Christophe,81,Tarn,76,Occitanie
81246,STE CECILE DU CAYROU,81140,STE CECILE DU CAYROU,,44.0018082654,1.80559449088,246,,Sainte-Cécile-du-Cayrou,Sainte-Cécile-du-Cayrou,81,Tarn,76,Occitanie
81247,ST CIRGUE,81340,ST CIRGUE,,43.9705143368,2.37166365439,247,,Saint-Cirgue,Saint-Cirgue,81,Tarn,76,Occitanie
81248,ST GAUZENS,81390,ST GAUZENS,,43.7421086865,1.88132892487,248,,Saint-Gauzens,Saint-Gauzens,81,Tarn,76,Occitanie
81249,STE GEMME,81190,STE GEMME,,44.0888853221,2.19609081106,249,,Sainte-Gemme,Sainte-Gemme,81,Tarn,76,Occitanie
81250,ST GENEST DE CONTEST,81440,ST GENEST DE CONTEST,,43.7619862953,2.14681126259,250,,Saint-Genest-de-Contest,Saint-Genest-de-Contest,81,Tarn,76,Occitanie
81251,ST GERMAIN DES PRES,81700,ST GERMAIN DES PRES,,43.5628494713,2.07801085173,251,,Saint-Germain-des-Prés,Saint-Germain-des-Prés,81,Tarn,76,Occitanie
81252,ST GERMIER,81210,ST GERMIER,,43.6768633717,2.23011176634,252,,Saint-Germier,Saint-Germier,81,Tarn,76,Occitanie
81253,ST GREGOIRE,81350,ST GREGOIRE,,43.9551674192,2.26106441809,253,,Saint-Grégoire,Saint-Grégoire,81,Tarn,76,Occitanie
81254,ST JEAN DE MARCEL,81350,ST JEAN DE MARCEL,,44.0562910218,2.24878070436,254,,Saint-Jean-de-Marcel,Saint-Jean-de-Marcel,81,Tarn,76,Occitanie
81255,ST JEAN DE RIVES,81500,ST JEAN DE RIVES,,43.7346247843,1.76633924309,255,,Saint-Jean-de-Rives,Saint-Jean-de-Rives,81,Tarn,76,Occitanie
81256,ST JEAN DE VALS,81210,ST JEAN DE VALS,,43.6871787236,2.25694923614,256,,Saint-Jean-de-Vals,Saint-Jean-de-Vals,81,Tarn,76,Occitanie
81257,ST JUERY,81160,ST JUERY,,43.9385571465,2.22614415314,257,,Saint-Juéry,Saint-Juéry,81,Tarn,76,Occitanie
81258,ST JULIEN DU PUY,81440,ST JULIEN DU PUY,,43.751631087,2.08059603326,258,,Saint-Julien-du-Puy,Saint-Julien-du-Puy,81,Tarn,76,Occitanie
81259,ST JULIEN GAULENE,81340,ST JULIEN GAULENE,,43.9961595825,2.34196813906,259,,Saint-Julien-Gaulène,Saint-Julien-Gaulène,81,Tarn,76,Occitanie
81260,ST LIEUX LAFENASSE,81120,ST LIEUX LAFENASSE,,43.7827334909,2.23936235966,260,,Saint-Lieux-Lafenasse,Saint-Lieux-Lafenasse,81,Tarn,76,Occitanie
81261,ST LIEUX LES LAVAUR,81500,ST LIEUX LES LAVAUR,,43.7554264288,1.74752680249,261,,Saint-Lieux-lès-Lavaur,Saint-Lieux-lès-Lavaur,81,Tarn,76,Occitanie
81262,ST MARCEL CAMPES,81170,ST MARCEL CAMPES,,44.083875553,2.00175106626,262,,Saint-Marcel-Campes,Saint-Marcel-Campes,81,Tarn,76,Occitanie
81262,ST MARCEL CAMPES,81170,ST MARCEL CAMPES,CAMPES,44.083875553,2.00175106626,262,,Saint-Marcel-Campes,Saint-Marcel-Campes,81,Tarn,76,Occitanie
81263,ST MARTIN LAGUEPIE,81170,ST MARTIN LAGUEPIE,,44.1349838436,1.96022083037,263,,Saint-Martin-Laguépie,Saint-Martin-Laguépie,81,Tarn,76,Occitanie
81264,ST MICHEL LABADIE,81340,ST MICHEL LABADIE,,44.0067895923,2.42805040105,264,,Saint-Michel-Labadié,Saint-Michel-Labadié,81,Tarn,76,Occitanie
81265,ST MICHEL DE VAX,81140,ST MICHEL DE VAX,,44.1085463735,1.80659770648,265,,Saint-Michel-de-Vax,Saint-Michel-de-Vax,81,Tarn,76,Occitanie
81266,ST PAUL CAP DE JOUX,81220,ST PAUL CAP DE JOUX,,43.635057866,1.98923840323,266,,Saint-Paul-Cap-de-Joux,Saint-Paul-Cap-de-Joux,81,Tarn,76,Occitanie
81267,ST PIERRE DE TRIVISY,81330,ST PIERRE DE TRIVISY,,43.754059506,2.44562540702,267,,Saint-Pierre-de-Trivisy,Saint-Pierre-de-Trivisy,81,Tarn,76,Occitanie
81268,ST SALVI DE CARCAVES,81530,ST SALVI DE CARCAVES,,43.7975079038,2.59502148525,268,,Saint-Salvi-de-Carcavès,Saint-Salvi-de-Carcavès,81,Tarn,76,Occitanie
81269,ST SALVY DE LA BALME,81490,ST SALVY DE LA BALME,,43.6117421353,2.37969793328,269,,Saint-Salvy-de-la-Balme,Saint-Salvy-de-la-Balme,81,Tarn,76,Occitanie
81270,ST SERNIN LES LAVAUR,81700,ST SERNIN LES LAVAUR,,43.543395503,1.97224589323,270,,Saint-Sernin-lès-Lavaur,Saint-Sernin-lès-Lavaur,81,Tarn,76,Occitanie
81271,ST SULPICE LA POINTE,81370,ST SULPICE LA POINTE,,43.7589885163,1.68659997767,271,,Saint-Sulpice-la-Pointe,Saint-Sulpice-la-Pointe,81,Tarn,76,Occitanie
81272,ST URCISSE,81630,ST URCISSE,,43.9335030171,1.60695307526,272,,Saint-Urcisse,Saint-Urcisse,81,Tarn,76,Occitanie
81273,SAIX,81710,SAIX,,43.5842195914,2.17044847109,273,,Saïx,Saïx,81,Tarn,76,Occitanie
81274,SALIES,81990,SALIES,,43.8859141044,2.13293354556,274,,Saliès,Saliès,81,Tarn,76,Occitanie
81275,SALLES,81640,SALLES,,44.0752776233,2.04296724146,275,,Salles,Salles,81,Tarn,76,Occitanie
81276,SALVAGNAC,81630,SALVAGNAC,,43.9201151478,1.67981306162,276,,Salvagnac,Salvagnac,81,Tarn,76,Occitanie
81277,SAUSSENAC,81350,SAUSSENAC,,43.9932298671,2.2548403386,277,,Saussenac,Saussenac,81,Tarn,76,Occitanie
81278,SAUVETERRE,81240,SAUVETERRE,,43.4536167726,2.55991346733,278,,Sauveterre,Sauveterre,81,Tarn,76,Occitanie
81279,LA SAUZIERE ST JEAN,81630,LA SAUZIERE ST JEAN,,43.9540521322,1.64862996342,279,La,Sauzière-Saint-Jean,La Sauzière-Saint-Jean,81,Tarn,76,Occitanie
81280,LE SEGUR,81640,LE SEGUR,,44.1078756116,2.06217652452,280,Le,Ségur,Le Ségur,81,Tarn,76,Occitanie
81281,SEMALENS,81570,SEMALENS,,43.598998518,2.10929167449,281,,Sémalens,Sémalens,81,Tarn,76,Occitanie
81282,SENAUX,81530,SENAUX,,43.7659564788,2.61566505316,282,,Senaux,Senaux,81,Tarn,76,Occitanie
81283,SENOUILLAC,81600,SENOUILLAC,,43.947412308,1.95119521002,283,,Senouillac,Senouillac,81,Tarn,76,Occitanie
81284,LE SEQUESTRE,81990,LE SEQUESTRE,,43.9105402335,2.10655664211,284,Le,Sequestre,Le Sequestre,81,Tarn,76,Occitanie
81285,SERENAC,81350,SERENAC,,43.9589302642,2.32732633415,285,,Sérénac,Sérénac,81,Tarn,76,Occitanie
81286,SERVIES,81220,SERVIES,,43.6653039824,2.03248661491,286,,Serviès,Serviès,81,Tarn,76,Occitanie
81287,SIEURAC,81120,SIEURAC,,43.8097363863,2.08715323325,287,,Sieurac,Sieurac,81,Tarn,76,Occitanie
81288,SOREZE,81540,SOREZE,,43.4412492469,2.08031337654,288,,Sorèze,Sorèze,81,Tarn,76,Occitanie
81289,SOUAL,81580,SOUAL,,43.5511250266,2.12888668782,289,,Soual,Soual,81,Tarn,76,Occitanie
81290,SOUEL,81170,SOUEL,,44.0347194017,1.96267269811,290,,Souel,Souel,81,Tarn,76,Occitanie
81291,TAIX,81130,TAIX,,44.009592574,2.11973456275,291,,Taïx,Taïx,81,Tarn,76,Occitanie
81292,TANUS,81190,TANUS,,44.1080524235,2.30592694814,292,,Tanus,Tanus,81,Tarn,76,Occitanie
81293,TAURIAC,81630,TAURIAC,,43.8795465115,1.59365631219,293,,Tauriac,Tauriac,81,Tarn,76,Occitanie
81294,TECOU,81600,TECOU,,43.8433118928,1.93529190709,294,,Técou,Técou,81,Tarn,76,Occitanie
81295,TEILLET,81120,TEILLET,,43.8441490915,2.34648455097,295,,Teillet,Teillet,81,Tarn,76,Occitanie
81296,TERRE CLAPIER,81120,TERRE CLAPIER,,43.8488063014,2.3011276294,296,,Terre-Clapier,Terre-Clapier,81,Tarn,76,Occitanie
81297,TERSSAC,81150,TERSSAC,,43.92257156,2.06910358338,297,,Terssac,Terssac,81,Tarn,76,Occitanie
81298,TEULAT,81500,TEULAT,,43.636472694,1.71125634888,298,,Teulat,Teulat,81,Tarn,76,Occitanie
81299,TEYSSODE,81220,TEYSSODE,,43.646080345,1.92563367726,299,,Teyssode,Teyssode,81,Tarn,76,Occitanie
81300,TONNAC,81170,TONNAC,,44.0717430834,1.85301094203,300,,Tonnac,Tonnac,81,Tarn,76,Occitanie
81301,LE TRAVET,81120,LE TRAVET,,43.8099296462,2.32737489648,301,Le,Travet,Le Travet,81,Tarn,76,Occitanie
81302,TREBAN,81190,TREBAN,,44.0915936004,2.3448678628,302,,Tréban,Tréban,81,Tarn,76,Occitanie
81303,TREBAS,81340,TREBAS,,43.9548189483,2.48899274991,303,,Trébas,Trébas,81,Tarn,76,Occitanie
81304,TREVIEN,81190,TREVIEN,,44.1078808594,2.1204609055,304,,Trévien,Trévien,81,Tarn,76,Occitanie
81305,VABRE,81330,VABRE,,43.7055461062,2.42328565025,305,,Vabre,Vabre,81,Tarn,76,Occitanie
81306,VALDERIES,81350,VALDERIES,,44.0186880847,2.23180106024,306,,Valderiès,Valderiès,81,Tarn,76,Occitanie
81307,VALDURENQUE,81090,VALDURENQUE,,43.5713585994,2.3109213574,307,,Valdurenque,Valdurenque,81,Tarn,76,Occitanie
81308,VALENCE D ALBIGEOIS,81340,VALENCE D ALBIGEOIS,,44.0155454064,2.3857453557,308,,Valence-d'Albigeois,Valence-d'Albigeois,81,Tarn,76,Occitanie
81309,VAOUR,81140,VAOUR,,44.070490584,1.80207559018,309,,Vaour,Vaour,81,Tarn,76,Occitanie
81310,VEILHES,81500,VEILHES,,43.6178149408,1.81786230309,310,,Veilhes,Veilhes,81,Tarn,76,Occitanie
81311,VENES,81440,VENES,,43.7373706752,2.19476004846,311,,Vénès,Vénès,81,Tarn,76,Occitanie
81312,VERDALLE,81110,VERDALLE,,43.4967627255,2.17944315605,312,,Verdalle,Verdalle,81,Tarn,76,Occitanie
81313,LE VERDIER,81140,LE VERDIER,,43.9942529468,1.83850790664,313,Le,Verdier,Le Verdier,81,Tarn,76,Occitanie
81314,VIANE,81530,VIANE,,43.7463791863,2.58683700675,314,,Viane,Viane,81,Tarn,76,Occitanie
81315,VIELMUR SUR AGOUT,81570,VIELMUR SUR AGOUT,,43.6274174186,2.10074049718,315,,Vielmur-sur-Agout,Vielmur-sur-Agout,81,Tarn,76,Occitanie
81316,VIEUX,81140,VIEUX,,43.9964922909,1.86610930745,316,,Vieux,Vieux,81,Tarn,76,Occitanie
81317,VILLEFRANCHE D ALBIGEOIS,81430,VILLEFRANCHE D ALBIGEOIS,,43.8923990872,2.32246933503,317,,Villefranche-d'Albigeois,Villefranche-d'Albigeois,81,Tarn,76,Occitanie
81318,VILLENEUVE LES LAVAUR,81500,VILLENEUVE LES LAVAUR,,43.6007072849,1.78331121274,318,,Villeneuve-lès-Lavaur,Villeneuve-lès-Lavaur,81,Tarn,76,Occitanie
81319,VILLENEUVE SUR VERE,81130,VILLENEUVE SUR VERE,,44.0063451197,2.03043286247,319,,Villeneuve-sur-Vère,Villeneuve-sur-Vère,81,Tarn,76,Occitanie
81320,VINDRAC ALAYRAC,81170,VINDRAC ALAYRAC,,44.0596168532,1.90210640065,320,,Vindrac-Alayrac,Vindrac-Alayrac,81,Tarn,76,Occitanie
81321,LE VINTROU,81240,LE VINTROU,,43.531445482,2.47366636477,321,Le,Vintrou,Le Vintrou,81,Tarn,76,Occitanie
81322,VIRAC,81640,VIRAC,,44.0426130975,2.05366411688,322,,Virac,Virac,81,Tarn,76,Occitanie
81323,VITERBE,81220,VITERBE,,43.677590198,1.91623680192,323,,Viterbe,Viterbe,81,Tarn,76,Occitanie
81324,VIVIERS LES LAVAUR,81500,VIVIERS LES LAVAUR,,43.6309479905,1.79117670514,324,,Viviers-lès-Lavaur,Viviers-lès-Lavaur,81,Tarn,76,Occitanie
81325,VIVIERS LES MONTAGNES,81290,VIVIERS LES MONTAGNES,,43.5498302673,2.17556071709,325,,Viviers-lès-Montagnes,Viviers-lès-Montagnes,81,Tarn,76,Occitanie
81326,STE CROIX,81150,STE CROIX,,43.9703149708,2.07210937838,326,,Sainte-Croix,Sainte-Croix,81,Tarn,76,Occitanie
82001,ALBEFEUILLE LAGARDE,82290,ALBEFEUILLE LAGARDE,,44.0482971636,1.27398142654,1,,Albefeuille-Lagarde,Albefeuille-Lagarde,82,Tarn-et-Garonne,76,Occitanie
82002,ALBIAS,82350,ALBIAS,,44.0815971851,1.44856431622,2,,Albias,Albias,82,Tarn-et-Garonne,76,Occitanie
82003,ANGEVILLE,82210,ANGEVILLE,,43.9938181839,1.03112257966,3,,Angeville,Angeville,82,Tarn-et-Garonne,76,Occitanie
82004,ASQUES,82120,ASQUES,,44.0092369862,0.955085490691,4,,Asques,Asques,82,Tarn-et-Garonne,76,Occitanie
82005,AUCAMVILLE,82600,AUCAMVILLE,,43.7960241357,1.22532777135,5,,Aucamville,Aucamville,82,Tarn-et-Garonne,76,Occitanie
82006,AUTERIVE,82500,AUTERIVE,,43.8551653218,0.970551762062,6,,Auterive,Auterive,82,Tarn-et-Garonne,76,Occitanie
82007,AUTY,82220,AUTY,,44.1889226535,1.4694372153,7,,Auty,Auty,82,Tarn-et-Garonne,76,Occitanie
82008,AUVILLAR,82340,AUVILLAR,,44.0585023466,0.887408574561,8,,Auvillar,Auvillar,82,Tarn-et-Garonne,76,Occitanie
82009,BALIGNAC,82120,BALIGNAC,,43.9557351784,0.874892919974,9,,Balignac,Balignac,82,Tarn-et-Garonne,76,Occitanie
82010,BARDIGUES,82340,BARDIGUES,,44.0297663201,0.891549889681,10,,Bardigues,Bardigues,82,Tarn-et-Garonne,76,Occitanie
82011,BARRY D ISLEMADE,82290,BARRY D ISLEMADE,,44.0727114625,1.25300673075,11,,Barry-d'Islemade,Barry-d'Islemade,82,Tarn-et-Garonne,76,Occitanie
82012,LES BARTHES,82100,LES BARTHES,,44.0911919961,1.17175938538,12,Les,Barthes,Les Barthes,82,Tarn-et-Garonne,76,Occitanie
82013,BEAUMONT DE LOMAGNE,82500,BEAUMONT DE LOMAGNE,,43.876728355,1.01059588806,13,,Beaumont-de-Lomagne,Beaumont-de-Lomagne,82,Tarn-et-Garonne,76,Occitanie
82014,BEAUPUY,82600,BEAUPUY,,43.8187281696,1.11607060391,14,,Beaupuy,Beaupuy,82,Tarn-et-Garonne,76,Occitanie
82015,BELBEZE EN LOMAGNE,82500,BELBEZE EN LOMAGNE,,43.9057808814,1.07014719349,15,,Belbèze-en-Lomagne,Belbèze-en-Lomagne,82,Tarn-et-Garonne,76,Occitanie
82016,BELVEZE,82150,BELVEZE,,44.3272081162,1.09030748279,16,,Belvèze,Belvèze,82,Tarn-et-Garonne,76,Occitanie
82017,BESSENS,82170,BESSENS,,43.8878683292,1.27054717739,17,,Bessens,Bessens,82,Tarn-et-Garonne,76,Occitanie
82018,BIOULE,82800,BIOULE,,44.0992261106,1.54336811906,18,,Bioule,Bioule,82,Tarn-et-Garonne,76,Occitanie
82019,BOUDOU,82200,BOUDOU,,44.1038305827,1.01575160815,19,,Boudou,Boudou,82,Tarn-et-Garonne,76,Occitanie
82020,BOUILLAC,82600,BOUILLAC,,43.8471889462,1.1154526635,20,,Bouillac,Bouillac,82,Tarn-et-Garonne,76,Occitanie
82021,BOULOC,82110,BOULOC,,44.3002675818,1.12464954976,21,,Bouloc-en-Quercy,Bouloc-en-Quercy,82,Tarn-et-Garonne,76,Occitanie
82022,BOURG DE VISA,82190,BOURG DE VISA,,44.250966127,0.949785780231,22,,Bourg-de-Visa,Bourg-de-Visa,82,Tarn-et-Garonne,76,Occitanie
82023,BOURRET,82700,BOURRET,,43.9436352242,1.15609472542,23,,Bourret,Bourret,82,Tarn-et-Garonne,76,Occitanie
82024,BRASSAC,82190,BRASSAC,,44.2221101343,0.977222408503,24,,Brassac,Brassac,82,Tarn-et-Garonne,76,Occitanie
82025,BRESSOLS,82710,BRESSOLS,,43.9544945655,1.31611180901,25,,Bressols,Bressols,82,Tarn-et-Garonne,76,Occitanie
82026,BRUNIQUEL,82800,BRUNIQUEL,,44.0521980341,1.66009719751,26,,Bruniquel,Bruniquel,82,Tarn-et-Garonne,76,Occitanie
82027,CAMPSAS,82370,CAMPSAS,,43.8873028891,1.32880119974,27,,Campsas,Campsas,82,Tarn-et-Garonne,76,Occitanie
82028,CANALS,82170,CANALS,,43.8610543748,1.29934658197,28,,Canals,Canals,82,Tarn-et-Garonne,76,Occitanie
82029,CASTANET,82160,CASTANET,,44.2603354224,1.92951732347,29,,Castanet,Castanet,82,Tarn-et-Garonne,76,Occitanie
82030,CASTELFERRUS,82100,CASTELFERRUS,,44.0050177844,1.09114738889,30,,Castelferrus,Castelferrus,82,Tarn-et-Garonne,76,Occitanie
82031,CASTELMAYRAN,82210,CASTELMAYRAN,,44.0227123316,1.04707174068,31,,Castelmayran,Castelmayran,82,Tarn-et-Garonne,76,Occitanie
82032,CASTELSAGRAT,82400,CASTELSAGRAT,,44.1767582704,0.960294296377,32,,Castelsagrat,Castelsagrat,82,Tarn-et-Garonne,76,Occitanie
82033,CASTELSARRASIN,82100,CASTELSARRASIN,,44.0490326525,1.12348123121,33,,Castelsarrasin,Castelsarrasin,82,Tarn-et-Garonne,76,Occitanie
82034,CASTERA BOUZET,82120,CASTERA BOUZET,,44.0029877877,0.917878782904,34,,Castéra-Bouzet,Castéra-Bouzet,82,Tarn-et-Garonne,76,Occitanie
82035,CAUMONT,82210,CAUMONT,,44.0211223113,0.9973632278,35,,Caumont,Caumont,82,Tarn-et-Garonne,76,Occitanie
82036,LE CAUSE,82500,LE CAUSE,,43.8022262259,0.972224568142,36,Le,Causé,Le Causé,82,Tarn-et-Garonne,76,Occitanie
82037,CAUSSADE,82300,CAUSSADE,,44.1565421874,1.54351161487,37,,Caussade,Caussade,82,Tarn-et-Garonne,76,Occitanie
82038,CAYLUS,82160,CAYLUS,,44.2420637295,1.75360869362,38,,Caylus,Caylus,82,Tarn-et-Garonne,76,Occitanie
82039,CAYRAC,82440,CAYRAC,,44.1039406465,1.47852315966,39,,Cayrac,Cayrac,82,Tarn-et-Garonne,76,Occitanie
82040,CAYRIECH,82240,CAYRIECH,,44.2153908954,1.60546514624,40,,Cayriech,Cayriech,82,Tarn-et-Garonne,76,Occitanie
82041,CAZALS,82140,CAZALS,,44.1249834446,1.6848635445,41,,Cazals,Cazals,82,Tarn-et-Garonne,76,Occitanie
82042,CAZES MONDENARD,82110,CAZES MONDENARD,,44.2128308252,1.21516583038,42,,Cazes-Mondenard,Cazes-Mondenard,82,Tarn-et-Garonne,76,Occitanie
82043,COMBEROUGER,82600,COMBEROUGER,,43.8719884875,1.09282788935,43,,Comberouger,Comberouger,82,Tarn-et-Garonne,76,Occitanie
82044,CORBARIEU,82370,CORBARIEU,,43.9412424129,1.38125963307,44,,Corbarieu,Corbarieu,82,Tarn-et-Garonne,76,Occitanie
82045,CORDES TOLOSANNES,82700,CORDES TOLOSANNES,,43.9758898256,1.13586987845,45,,Cordes-Tolosannes,Cordes-Tolosannes,82,Tarn-et-Garonne,76,Occitanie
82046,COUTURES,82210,COUTURES,,43.960075822,0.987814449104,46,,Coutures,Coutures,82,Tarn-et-Garonne,76,Occitanie
82047,CUMONT,82500,CUMONT,,43.8703403158,0.903369775759,47,,Cumont,Cumont,82,Tarn-et-Garonne,76,Occitanie
82048,DIEUPENTALE,82170,DIEUPENTALE,,43.8686375923,1.27914799068,48,,Dieupentale,Dieupentale,82,Tarn-et-Garonne,76,Occitanie
82049,DONZAC,82340,DONZAC,,44.1025353418,0.814008819185,49,,Donzac,Donzac,82,Tarn-et-Garonne,76,Occitanie
82050,DUNES,82340,DUNES,,44.0864807376,0.773515942435,50,,Dunes,Dunes,82,Tarn-et-Garonne,76,Occitanie
82051,DURFORT LACAPELETTE,82390,DURFORT LACAPELETTE,,44.1832337484,1.14143724607,51,,Durfort-Lacapelette,Durfort-Lacapelette,82,Tarn-et-Garonne,76,Occitanie
82052,ESCATALENS,82700,ESCATALENS,,43.9817694616,1.19368002494,52,,Escatalens,Escatalens,82,Tarn-et-Garonne,76,Occitanie
82053,ESCAZEAUX,82500,ESCAZEAUX,,43.836046459,1.02957097257,53,,Escazeaux,Escazeaux,82,Tarn-et-Garonne,76,Occitanie
82054,ESPALAIS,82400,ESPALAIS,,44.0763853292,0.914122599798,54,,Espalais,Espalais,82,Tarn-et-Garonne,76,Occitanie
82055,ESPARSAC,82500,ESPARSAC,,43.9115019162,0.947377490224,55,,Esparsac,Esparsac,82,Tarn-et-Garonne,76,Occitanie
82056,ESPINAS,82160,ESPINAS,,44.1967176562,1.7923386672,56,,Espinas,Espinas,82,Tarn-et-Garonne,76,Occitanie
82057,FABAS,82170,FABAS,,43.861792636,1.3391246531,57,,Fabas,Fabas,82,Tarn-et-Garonne,76,Occitanie
82058,FAJOLLES,82210,FAJOLLES,,43.9666339943,1.01247908889,58,,Fajolles,Fajolles,82,Tarn-et-Garonne,76,Occitanie
82059,FAUDOAS,82500,FAUDOAS,,43.8335413145,0.967332331087,59,,Faudoas,Faudoas,82,Tarn-et-Garonne,76,Occitanie
82060,FAUROUX,82190,FAUROUX,,44.2527084346,1.00589119507,60,,Fauroux,Fauroux,82,Tarn-et-Garonne,76,Occitanie
82061,FENEYROLS,82140,FENEYROLS,,44.1415263063,1.82521779243,61,,Féneyrols,Féneyrols,82,Tarn-et-Garonne,76,Occitanie
82062,FINHAN,82700,FINHAN,,43.9126951654,1.21926326685,62,,Finhan,Finhan,82,Tarn-et-Garonne,76,Occitanie
82063,GARGANVILLAR,82100,GARGANVILLAR,,43.9752270104,1.06428038626,63,,Garganvillar,Garganvillar,82,Tarn-et-Garonne,76,Occitanie
82064,GARIES,82500,GARIES,,43.8107671631,1.03366935308,64,,Gariès,Gariès,82,Tarn-et-Garonne,76,Occitanie
82065,GASQUES,82400,GASQUES,,44.1489786487,0.910582677512,65,,Gasques,Gasques,82,Tarn-et-Garonne,76,Occitanie
82066,GENEBRIERES,82230,GENEBRIERES,,44.0012241111,1.49043175988,66,,Génébrières,Génébrières,82,Tarn-et-Garonne,76,Occitanie
82067,GENSAC,82120,GENSAC,,43.9483571152,0.959337749058,67,,Gensac,Gensac,82,Tarn-et-Garonne,76,Occitanie
82068,GIMAT,82500,GIMAT,,43.8656341647,0.940199875887,68,,Gimat,Gimat,82,Tarn-et-Garonne,76,Occitanie
82069,GINALS,82330,GINALS,,44.2198870277,1.87837880794,69,,Ginals,Ginals,82,Tarn-et-Garonne,76,Occitanie
82070,GLATENS,82500,GLATENS,,43.8944756908,0.919896857009,70,,Glatens,Glatens,82,Tarn-et-Garonne,76,Occitanie
82071,GOAS,82500,GOAS,,43.8150768113,0.960854244996,71,,Goas,Goas,82,Tarn-et-Garonne,76,Occitanie
82072,GOLFECH,82400,GOLFECH,,44.1100274334,0.856994008348,72,,Golfech,Golfech,82,Tarn-et-Garonne,76,Occitanie
82073,GOUDOURVILLE,82400,GOUDOURVILLE,,44.1130087288,0.925431531851,73,,Goudourville,Goudourville,82,Tarn-et-Garonne,76,Occitanie
82074,GRAMONT,82120,GRAMONT,,43.9426554834,0.787981814153,74,,Gramont,Gramont,82,Tarn-et-Garonne,76,Occitanie
82075,GRISOLLES,82170,GRISOLLES,,43.8224483737,1.28670830479,75,,Grisolles,Grisolles,82,Tarn-et-Garonne,76,Occitanie
82076,L HONOR DE COS,82130,L HONOR DE COS,,44.1168766255,1.3569697203,76,L',Honor-de-Cos,L'Honor-de-Cos,82,Tarn-et-Garonne,76,Occitanie
82077,LABARTHE,82220,LABARTHE,,44.2091624963,1.31689415927,77,,Labarthe,Labarthe,82,Tarn-et-Garonne,76,Occitanie
82078,LABASTIDE DE PENNE,82240,LABASTIDE DE PENNE,,44.2817437019,1.59192694474,78,,Labastide-de-Penne,Labastide-de-Penne,82,Tarn-et-Garonne,76,Occitanie
82079,LABASTIDE ST PIERRE,82370,LABASTIDE ST PIERRE,,43.9190655964,1.34640200769,79,,Labastide-Saint-Pierre,Labastide-Saint-Pierre,82,Tarn-et-Garonne,76,Occitanie
82080,LABASTIDE DU TEMPLE,82100,LABASTIDE DU TEMPLE,,44.0810961823,1.20235818385,80,,Labastide-du-Temple,Labastide-du-Temple,82,Tarn-et-Garonne,76,Occitanie
82081,LABOURGADE,82100,LABOURGADE,,43.9566434568,1.09237967165,81,,Labourgade,Labourgade,82,Tarn-et-Garonne,76,Occitanie
82082,LACAPELLE LIVRON,82160,LACAPELLE LIVRON,,44.2719783963,1.79012954535,82,,Lacapelle-Livron,Lacapelle-Livron,82,Tarn-et-Garonne,76,Occitanie
82083,LACHAPELLE,82120,LACHAPELLE,,43.9851541381,0.841507103238,83,,Lachapelle,Lachapelle,82,Tarn-et-Garonne,76,Occitanie
82084,LACOUR,82190,LACOUR,,44.2848641284,0.965045084358,84,,Lacour,Lacour,82,Tarn-et-Garonne,76,Occitanie
82085,LACOURT ST PIERRE,82290,LACOURT ST PIERRE,,43.9885803671,1.27169521858,85,,Lacourt-Saint-Pierre,Lacourt-Saint-Pierre,82,Tarn-et-Garonne,76,Occitanie
82086,LAFITTE,82100,LAFITTE,,43.9737693786,1.10663393727,86,,Lafitte,Lafitte,82,Tarn-et-Garonne,76,Occitanie
82087,LAFRANCAISE,82130,LAFRANCAISE,,44.13545336,1.24021964059,87,,Lafrançaise,Lafrançaise,82,Tarn-et-Garonne,76,Occitanie
82088,LAGUEPIE,82250,LAGUEPIE,,44.163304209,1.95842635186,88,,Laguépie,Laguépie,82,Tarn-et-Garonne,76,Occitanie
82089,LAMAGISTERE,82360,LAMAGISTERE,,44.1320571715,0.821877164854,89,,Lamagistère,Lamagistère,82,Tarn-et-Garonne,76,Occitanie
82090,LAMOTHE CAPDEVILLE,82130,LAMOTHE CAPDEVILLE,,44.0932619829,1.38976564928,90,,Lamothe-Capdeville,Lamothe-Capdeville,82,Tarn-et-Garonne,76,Occitanie
82091,LAMOTHE CUMONT,82500,LAMOTHE CUMONT,,43.8829270496,0.913928018748,91,,Lamothe-Cumont,Lamothe-Cumont,82,Tarn-et-Garonne,76,Occitanie
82092,LAPENCHE,82240,LAPENCHE,,44.223694472,1.5730652063,92,,Lapenche,Lapenche,82,Tarn-et-Garonne,76,Occitanie
82093,LARRAZET,82500,LARRAZET,,43.9324522931,1.08287865971,93,,Larrazet,Larrazet,82,Tarn-et-Garonne,76,Occitanie
82094,LAUZERTE,82110,LAUZERTE,,44.2642995725,1.12713270139,94,,Lauzerte,Lauzerte,82,Tarn-et-Garonne,76,Occitanie
82095,LAVAURETTE,82240,LAVAURETTE,,44.2073975213,1.66999511722,95,,Lavaurette,Lavaurette,82,Tarn-et-Garonne,76,Occitanie
82096,LA VILLE DIEU DU TEMPLE,82290,LA VILLE DIEU DU TEMPLE,,44.0429816924,1.22097362362,96,La,Ville-Dieu-du-Temple,La Ville-Dieu-du-Temple,82,Tarn-et-Garonne,76,Occitanie
82097,LAVIT,82120,LAVIT,,43.9560822139,0.925072043975,97,,Lavit,Lavit,82,Tarn-et-Garonne,76,Occitanie
82098,LEOJAC,82230,LEOJAC,,44.0029268043,1.45114680413,98,,Léojac,Léojac,82,Tarn-et-Garonne,76,Occitanie
82099,LIZAC,82200,LIZAC,,44.1127415173,1.18684429621,99,,Lizac,Lizac,82,Tarn-et-Garonne,76,Occitanie
82100,LOZE,82160,LOZE,,44.2922818263,1.763436611,100,,Loze,Loze,82,Tarn-et-Garonne,76,Occitanie
82101,MALAUSE,82200,MALAUSE,,44.0899884213,0.971913032435,101,,Malause,Malause,82,Tarn-et-Garonne,76,Occitanie
82102,MANSONVILLE,82120,MANSONVILLE,,44.0154220784,0.851115135446,102,,Mansonville,Mansonville,82,Tarn-et-Garonne,76,Occitanie
82103,MARIGNAC,82500,MARIGNAC,,43.8418091084,0.916264916273,103,,Marignac,Marignac,82,Tarn-et-Garonne,76,Occitanie
82104,MARSAC,82120,MARSAC,,43.9414664825,0.829474499399,104,,Marsac,Marsac,82,Tarn-et-Garonne,76,Occitanie
82105,MAS GRENIER,82600,MAS GRENIER,,43.895387967,1.18134234627,105,,Mas-Grenier,Mas-Grenier,82,Tarn-et-Garonne,76,Occitanie
82106,MAUBEC,82500,MAUBEC,,43.8036755051,0.924111218572,106,,Maubec,Maubec,82,Tarn-et-Garonne,76,Occitanie
82107,MAUMUSSON,82120,MAUMUSSON,,43.9122841203,0.906121220446,107,,Maumusson,Maumusson,82,Tarn-et-Garonne,76,Occitanie
82108,MEAUZAC,82290,MEAUZAC,,44.0954617752,1.23041071919,108,,Meauzac,Meauzac,82,Tarn-et-Garonne,76,Occitanie
82109,MERLES,82210,MERLES,,44.0647889391,0.966661565341,109,,Merles,Merles,82,Tarn-et-Garonne,76,Occitanie
82110,MIRABEL,82440,MIRABEL,,44.1424577987,1.4113688594,110,,Mirabel,Mirabel,82,Tarn-et-Garonne,76,Occitanie
82111,MIRAMONT DE QUERCY,82190,MIRAMONT DE QUERCY,,44.2285744993,1.05102472854,111,,Miramont-de-Quercy,Miramont-de-Quercy,82,Tarn-et-Garonne,76,Occitanie
82112,MOISSAC,82200,MOISSAC,,44.1262518079,1.09834758014,112,,Moissac,Moissac,82,Tarn-et-Garonne,76,Occitanie
82113,MOLIERES,82220,MOLIERES,,44.1933793632,1.39317951886,113,,Molières,Molières,82,Tarn-et-Garonne,76,Occitanie
82114,MONBEQUI,82170,MONBEQUI,,43.8913749856,1.23224903007,114,,Monbéqui,Monbéqui,82,Tarn-et-Garonne,76,Occitanie
82115,MONCLAR DE QUERCY,82230,MONCLAR DE QUERCY,,43.9880590722,1.58236305942,115,,Monclar-de-Quercy,Monclar-de-Quercy,82,Tarn-et-Garonne,76,Occitanie
82116,MONTAGUDET,82110,MONTAGUDET,,44.2476837201,1.08705282102,116,,Montagudet,Montagudet,82,Tarn-et-Garonne,76,Occitanie
82117,MONTAIGU DE QUERCY,82150,MONTAIGU DE QUERCY,,44.3406420111,1.03252683687,117,,Montaigu-de-Quercy,Montaigu-de-Quercy,82,Tarn-et-Garonne,76,Occitanie
82118,MONTAIN,82100,MONTAIN,,43.9416567435,1.11390121929,118,,Montaïn,Montaïn,82,Tarn-et-Garonne,76,Occitanie
82119,MONTALZAT,82270,MONTALZAT,,44.2084619848,1.51721472042,119,,Montalzat,Montalzat,82,Tarn-et-Garonne,76,Occitanie
82120,MONTASTRUC,82130,MONTASTRUC,,44.1037069376,1.29437012886,120,,Montastruc,Montastruc,82,Tarn-et-Garonne,76,Occitanie
82121,MONTAUBAN,82000,MONTAUBAN,,44.0222594578,1.36408636501,121,,Montauban,Montauban,82,Tarn-et-Garonne,76,Occitanie
82122,MONTBARLA,82110,MONTBARLA,,44.2136604431,1.08193556134,122,,Montbarla,Montbarla,82,Tarn-et-Garonne,76,Occitanie
82123,MONTBARTIER,82700,MONTBARTIER,,43.9148777604,1.28662456416,123,,Montbartier,Montbartier,82,Tarn-et-Garonne,76,Occitanie
82124,MONTBETON,82290,MONTBETON,,44.0135610101,1.2703654947,124,,Montbeton,Montbeton,82,Tarn-et-Garonne,76,Occitanie
82125,MONTECH,82700,MONTECH,,43.9524124123,1.23764675579,125,,Montech,Montech,82,Tarn-et-Garonne,76,Occitanie
82126,MONTEILS,82300,MONTEILS,,44.1717870381,1.56537726368,126,,Monteils,Monteils,82,Tarn-et-Garonne,76,Occitanie
82127,MONTESQUIEU,82200,MONTESQUIEU,,44.1792539863,1.06193133749,127,,Montesquieu,Montesquieu,82,Tarn-et-Garonne,76,Occitanie
82128,MONTFERMIER,82270,MONTFERMIER,,44.2279188448,1.41564723711,128,,Montfermier,Montfermier,82,Tarn-et-Garonne,76,Occitanie
82129,MONTGAILLARD,82120,MONTGAILLARD,,43.9301148287,0.878320223678,129,,Montgaillard,Montgaillard,82,Tarn-et-Garonne,76,Occitanie
82130,MONTJOI,82400,MONTJOI,,44.2043392824,0.930124213553,130,,Montjoi,Montjoi,82,Tarn-et-Garonne,76,Occitanie
82131,MONTPEZAT DE QUERCY,82270,MONTPEZAT DE QUERCY,,44.2399240236,1.47367523661,131,,Montpezat-de-Quercy,Montpezat-de-Quercy,82,Tarn-et-Garonne,76,Occitanie
82132,MONTRICOUX,82800,MONTRICOUX,,44.1035057733,1.62418942804,132,,Montricoux,Montricoux,82,Tarn-et-Garonne,76,Occitanie
82133,MOUILLAC,82160,MOUILLAC,,44.2755002172,1.67305723666,133,,Mouillac,Mouillac,82,Tarn-et-Garonne,76,Occitanie
82134,NEGREPELISSE,82800,NEGREPELISSE,,44.0631437696,1.52813602608,134,,Nègrepelisse,Nègrepelisse,82,Tarn-et-Garonne,76,Occitanie
82135,NOHIC,82370,NOHIC,,43.8880946294,1.43765103909,135,,Nohic,Nohic,82,Tarn-et-Garonne,76,Occitanie
82136,ORGUEIL,82370,ORGUEIL,,43.8991686927,1.39705978645,136,,Orgueil,Orgueil,82,Tarn-et-Garonne,76,Occitanie
82137,PARISOT,82160,PARISOT,,44.2617881809,1.86571509225,137,,Parisot,Parisot,82,Tarn-et-Garonne,76,Occitanie
82138,PERVILLE,82400,PERVILLE,,44.1808489107,0.880636817107,138,,Perville,Perville,82,Tarn-et-Garonne,76,Occitanie
82139,LE PIN,82340,LE PIN,,44.0376612636,0.975279136698,139,Le,Pin,Le Pin,82,Tarn-et-Garonne,76,Occitanie
82140,PIQUECOS,82130,PIQUECOS,,44.1044667905,1.31637586388,140,,Piquecos,Piquecos,82,Tarn-et-Garonne,76,Occitanie
82141,POMMEVIC,82400,POMMEVIC,,44.0940422544,0.934521561123,141,,Pommevic,Pommevic,82,Tarn-et-Garonne,76,Occitanie
82142,POMPIGNAN,82170,POMPIGNAN,,43.8196347492,1.32921957456,142,,Pompignan,Pompignan,82,Tarn-et-Garonne,76,Occitanie
82143,POUPAS,82120,POUPAS,,43.9626081803,0.835987079725,143,,Poupas,Poupas,82,Tarn-et-Garonne,76,Occitanie
82144,PUYCORNET,82220,PUYCORNET,,44.1546581959,1.32199868953,144,,Puycornet,Puycornet,82,Tarn-et-Garonne,76,Occitanie
82145,PUYGAILLARD DE QUERCY,82800,PUYGAILLARD DE QUERCY,,44.0245113626,1.63164922322,145,,Puygaillard-de-Quercy,Puygaillard-de-Quercy,82,Tarn-et-Garonne,76,Occitanie
82146,PUYGAILLARD DE LOMAGNE,82120,PUYGAILLARD DE LOMAGNE,,43.9687272643,0.893447284352,146,,Puygaillard-de-Lomagne,Puygaillard-de-Lomagne,82,Tarn-et-Garonne,76,Occitanie
82147,PUYLAGARDE,82160,PUYLAGARDE,,44.3066142311,1.84631007866,147,,Puylagarde,Puylagarde,82,Tarn-et-Garonne,76,Occitanie
82148,PUYLAROQUE,82240,PUYLAROQUE,,44.2515860138,1.63529811514,148,,Puylaroque,Puylaroque,82,Tarn-et-Garonne,76,Occitanie
82149,REALVILLE,82440,REALVILLE,,44.1281472573,1.48081390272,149,,Réalville,Réalville,82,Tarn-et-Garonne,76,Occitanie
82150,REYNIES,82370,REYNIES,,43.9261825292,1.41453449796,150,,Reyniès,Reyniès,82,Tarn-et-Garonne,76,Occitanie
82151,ROQUECOR,82150,ROQUECOR,,44.3164931425,0.946500411768,151,,Roquecor,Roquecor,82,Tarn-et-Garonne,76,Occitanie
82152,ST AIGNAN,82100,ST AIGNAN,,44.0231974542,1.07335605665,152,,Saint-Aignan,Saint-Aignan,82,Tarn-et-Garonne,76,Occitanie
82153,ST AMANS DU PECH,82150,ST AMANS DU PECH,,44.3114103481,0.893358658989,153,,Saint-Amans-du-Pech,Saint-Amans-du-Pech,82,Tarn-et-Garonne,76,Occitanie
82154,ST AMANS DE PELLAGAL,82110,ST AMANS DE PELLAGAL,,44.2216170382,1.12813639744,154,,Saint-Amans-de-Pellagal,Saint-Amans-de-Pellagal,82,Tarn-et-Garonne,76,Occitanie
82155,ST ANTONIN NOBLE VAL,82140,ST ANTONIN NOBLE VAL,,44.156093011,1.73640541242,155,,Saint-Antonin-Noble-Val,Saint-Antonin-Noble-Val,82,Tarn-et-Garonne,76,Occitanie
82156,ST ARROUMEX,82210,ST ARROUMEX,,43.9917173293,0.989292206916,156,,Saint-Arroumex,Saint-Arroumex,82,Tarn-et-Garonne,76,Occitanie
82157,ST BEAUZEIL,82150,ST BEAUZEIL,,44.3338919485,0.908627657621,157,,Saint-Beauzeil,Saint-Beauzeil,82,Tarn-et-Garonne,76,Occitanie
82158,ST CIRICE,82340,ST CIRICE,,44.0616740438,0.838811755985,158,,Saint-Cirice,Saint-Cirice,82,Tarn-et-Garonne,76,Occitanie
82159,ST CIRQ,82300,ST CIRQ,,44.1349448025,1.60503531727,159,,Saint-Cirq,Saint-Cirq,82,Tarn-et-Garonne,76,Occitanie
82160,ST CLAIR,82400,ST CLAIR,,44.1524310269,0.942322871268,160,,Saint-Clair,Saint-Clair,82,Tarn-et-Garonne,76,Occitanie
82161,ST ETIENNE DE TULMONT,82410,ST ETIENNE DE TULMONT,,44.044673154,1.45703284983,161,,Saint-Étienne-de-Tulmont,Saint-Étienne-de-Tulmont,82,Tarn-et-Garonne,76,Occitanie
82162,ST GEORGES,82240,ST GEORGES,,44.2182015153,1.63984516896,162,,Saint-Georges,Saint-Georges,82,Tarn-et-Garonne,76,Occitanie
82163,ST JEAN DU BOUZET,82120,ST JEAN DU BOUZET,,43.9866371182,0.875793745086,163,,Saint-Jean-du-Bouzet,Saint-Jean-du-Bouzet,82,Tarn-et-Garonne,76,Occitanie
82164,STE JULIETTE,82110,STE JULIETTE,,44.2920682024,1.16527808537,164,,Sainte-Juliette,Sainte-Juliette,82,Tarn-et-Garonne,76,Occitanie
82165,ST LOUP,82340,ST LOUP,,44.0833269005,0.852281972741,165,,Saint-Loup,Saint-Loup,82,Tarn-et-Garonne,76,Occitanie
82166,ST MICHEL,82340,ST MICHEL,,44.0453516361,0.937520452505,166,,Saint-Michel,Saint-Michel,82,Tarn-et-Garonne,76,Occitanie
82167,ST NAUPHARY,82370,ST NAUPHARY,,43.95896731,1.44860158065,167,,Saint-Nauphary,Saint-Nauphary,82,Tarn-et-Garonne,76,Occitanie
82168,ST NAZAIRE DE VALENTANE,82190,ST NAZAIRE DE VALENTANE,,44.2056807143,1.01728344459,168,,Saint-Nazaire-de-Valentane,Saint-Nazaire-de-Valentane,82,Tarn-et-Garonne,76,Occitanie
82169,ST NICOLAS DE LA GRAVE,82210,ST NICOLAS DE LA GRAVE,,44.0658425315,1.02023020877,169,,Saint-Nicolas-de-la-Grave,Saint-Nicolas-de-la-Grave,82,Tarn-et-Garonne,76,Occitanie
82170,ST PAUL D ESPIS,82400,ST PAUL D ESPIS,,44.1401354751,1.00203700461,170,,Saint-Paul-d'Espis,Saint-Paul-d'Espis,82,Tarn-et-Garonne,76,Occitanie
82171,ST PORQUIER,82700,ST PORQUIER,,44.0077030324,1.18227115286,171,,Saint-Porquier,Saint-Porquier,82,Tarn-et-Garonne,76,Occitanie
82172,ST PROJET,82160,ST PROJET,,44.3109210238,1.77087641196,172,,Saint-Projet,Saint-Projet,82,Tarn-et-Garonne,76,Occitanie
82173,ST SARDOS,82600,ST SARDOS,,43.9017054092,1.12413910173,173,,Saint-Sardos,Saint-Sardos,82,Tarn-et-Garonne,76,Occitanie
82174,ST VINCENT D AUTEJAC,82300,ST VINCENT D AUTEJAC,,44.1643134123,1.46636504185,174,,Saint-Vincent-d'Autéjac,Saint-Vincent-d'Autéjac,82,Tarn-et-Garonne,76,Occitanie
82175,ST VINCENT LESPINASSE,82400,ST VINCENT LESPINASSE,,44.120334414,0.963699672322,175,,Saint-Vincent-Lespinasse,Saint-Vincent-Lespinasse,82,Tarn-et-Garonne,76,Occitanie
82176,LA SALVETAT BELMONTET,82230,LA SALVETAT BELMONTET,,43.966657971,1.51277201419,176,La,Salvetat-Belmontet,La Salvetat-Belmontet,82,Tarn-et-Garonne,76,Occitanie
82177,SAUVETERRE,82110,SAUVETERRE,,44.2678274881,1.2726165468,177,,Sauveterre,Sauveterre,82,Tarn-et-Garonne,76,Occitanie
82178,SAVENES,82600,SAVENES,,43.830592054,1.18506891098,178,,Savenès,Savenès,82,Tarn-et-Garonne,76,Occitanie
82179,SEPTFONDS,82240,SEPTFONDS,,44.1765759652,1.61739308656,179,,Septfonds,Septfonds,82,Tarn-et-Garonne,76,Occitanie
82180,SERIGNAC,82500,SERIGNAC,,43.9244393445,1.02486200605,180,,Sérignac,Sérignac,82,Tarn-et-Garonne,76,Occitanie
82181,SISTELS,82340,SISTELS,,44.0529870158,0.78331430404,181,,Sistels,Sistels,82,Tarn-et-Garonne,76,Occitanie
82182,TOUFFAILLES,82190,TOUFFAILLES,,44.2755478135,1.03805964935,182,,Touffailles,Touffailles,82,Tarn-et-Garonne,76,Occitanie
82183,TREJOULS,82110,TREJOULS,,44.2668414309,1.20229550462,183,,Tréjouls,Tréjouls,82,Tarn-et-Garonne,76,Occitanie
82184,VAISSAC,82800,VAISSAC,,44.031662491,1.57065929853,184,,Vaïssac,Vaïssac,82,Tarn-et-Garonne,76,Occitanie
82185,VALEILLES,82150,VALEILLES,,44.3624095008,0.915058269132,185,,Valeilles,Valeilles,82,Tarn-et-Garonne,76,Occitanie
82186,VALENCE,82400,VALENCE D AGEN,,44.1068332095,0.897921093494,186,,Valence,Valence,82,Tarn-et-Garonne,76,Occitanie
82187,VAREN,82330,VAREN,,44.1580435232,1.88633565997,187,,Varen,Varen,82,Tarn-et-Garonne,76,Occitanie
82188,VARENNES,82370,VARENNES,,43.9165718677,1.49011749068,188,,Varennes,Varennes,82,Tarn-et-Garonne,76,Occitanie
82189,VAZERAC,82220,VAZERAC,,44.195295259,1.27659596104,189,,Vazerac,Vazerac,82,Tarn-et-Garonne,76,Occitanie
82190,VERDUN SUR GARONNE,82600,VERDUN SUR GARONNE,,43.849145335,1.22845642714,190,,Verdun-sur-Garonne,Verdun-sur-Garonne,82,Tarn-et-Garonne,76,Occitanie
82191,VERFEIL,82330,VERFEIL SUR SEYE,,44.1847529793,1.87464680477,191,,Verfeil,Verfeil,82,Tarn-et-Garonne,76,Occitanie
82192,VERLHAC TESCOU,82230,VERLHAC TESCOU,,43.9330870043,1.53273779471,192,,Verlhac-Tescou,Verlhac-Tescou,82,Tarn-et-Garonne,76,Occitanie
82193,VIGUERON,82500,VIGUERON,,43.8937685951,1.05623953646,193,,Vigueron,Vigueron,82,Tarn-et-Garonne,76,Occitanie
82194,VILLEBRUMIER,82370,VILLEBRUMIER,,43.9202948869,1.4512543274,194,,Villebrumier,Villebrumier,82,Tarn-et-Garonne,76,Occitanie
82195,VILLEMADE,82130,VILLEMADE,,44.0801227297,1.2899145835,195,,Villemade,Villemade,82,Tarn-et-Garonne,76,Occitanie
83001,LES ADRETS DE L ESTEREL,83600,LES ADRETS DE L ESTEREL,,43.5356229284,6.8110879666,1,Les,Adrets-de-l'Estérel,Les Adrets-de-l'Estérel,83,Var,93,Provence-Alpes-Côte d'Azur
83002,AIGUINES,83630,AIGUINES,,43.7284369506,6.30439429944,2,,Aiguines,Aiguines,83,Var,93,Provence-Alpes-Côte d'Azur
83003,AMPUS,83111,AMPUS,,43.628363684,6.3718765021,3,,Ampus,Ampus,83,Var,93,Provence-Alpes-Côte d'Azur
83004,LES ARCS,83460,LES ARCS,,43.4509882105,6.48970221222,4,Les,Arcs,Les Arcs,83,Var,93,Provence-Alpes-Côte d'Azur
83005,ARTIGNOSC SUR VERDON,83630,ARTIGNOSC SUR VERDON,,43.6940767712,6.10762159358,5,,Artignosc-sur-Verdon,Artignosc-sur-Verdon,83,Var,93,Provence-Alpes-Côte d'Azur
83006,ARTIGUES,83560,ARTIGUES,,43.576501883,5.80239993371,6,,Artigues,Artigues,83,Var,93,Provence-Alpes-Côte d'Azur
83007,AUPS,83630,AUPS,,43.6246326968,6.22132785585,7,,Aups,Aups,83,Var,93,Provence-Alpes-Côte d'Azur
83008,BAGNOLS EN FORET,83600,BAGNOLS EN FORET,,43.5330955002,6.70850106064,8,,Bagnols-en-Forêt,Bagnols-en-Forêt,83,Var,93,Provence-Alpes-Côte d'Azur
83009,BANDOL,83150,BANDOL,,43.1473544522,5.74789358398,9,,Bandol,Bandol,83,Var,93,Provence-Alpes-Côte d'Azur
83010,BARGEME,83840,BARGEME,,43.7301362684,6.5708673229,10,,Bargème,Bargème,83,Var,93,Provence-Alpes-Côte d'Azur
83011,BARGEMON,83830,BARGEMON,,43.6333104555,6.54244589208,11,,Bargemon,Bargemon,83,Var,93,Provence-Alpes-Côte d'Azur
83012,BARJOLS,83670,BARJOLS,,43.5523879362,5.98777049612,12,,Barjols,Barjols,83,Var,93,Provence-Alpes-Côte d'Azur
83013,LA BASTIDE,83840,LA BASTIDE,,43.7479175346,6.63264027806,13,La,Bastide,La Bastide,83,Var,93,Provence-Alpes-Côte d'Azur
83014,BAUDINARD SUR VERDON,83630,BAUDINARD SUR VERDON,,43.7138038426,6.13352757623,14,,Baudinard-sur-Verdon,Baudinard-sur-Verdon,83,Var,93,Provence-Alpes-Côte d'Azur
83015,BAUDUEN,83630,BAUDUEN,,43.7157423488,6.20204455595,15,,Bauduen,Bauduen,83,Var,93,Provence-Alpes-Côte d'Azur
83016,LE BEAUSSET,83330,LE BEAUSSET,,43.2069315557,5.82020086096,16,Le,Beausset,Le Beausset,83,Var,93,Provence-Alpes-Côte d'Azur
83017,BELGENTIER,83210,BELGENTIER,,43.2399564384,5.99665900105,17,,Belgentier,Belgentier,83,Var,93,Provence-Alpes-Côte d'Azur
83018,BESSE SUR ISSOLE,83890,BESSE SUR ISSOLE,,43.3399479171,6.17774949705,18,,Besse-sur-Issole,Besse-sur-Issole,83,Var,93,Provence-Alpes-Côte d'Azur
83019,BORMES LES MIMOSAS,83230,BORMES LES MIMOSAS,,43.1622722101,6.34956987347,19,,Bormes-les-Mimosas,Bormes-les-Mimosas,83,Var,93,Provence-Alpes-Côte d'Azur
83020,LE BOURGUET,83840,LE BOURGUET,,43.7800937383,6.50345035592,20,Le,Bourguet,Le Bourguet,83,Var,93,Provence-Alpes-Côte d'Azur
83021,BRAS,83149,BRAS,,43.4704081648,5.95853254417,21,,Bras,Bras,83,Var,93,Provence-Alpes-Côte d'Azur
83022,BRENON,83840,BRENON,,43.7658451995,6.53777644844,22,,Brenon,Brenon,83,Var,93,Provence-Alpes-Côte d'Azur
83023,BRIGNOLES,83170,BRIGNOLES,,43.3992941902,6.07740244296,23,,Brignoles,Brignoles,83,Var,93,Provence-Alpes-Côte d'Azur
83025,BRUE AURIAC,83119,BRUE AURIAC,,43.5279511721,5.93664541294,25,,Brue-Auriac,Brue-Auriac,83,Var,93,Provence-Alpes-Côte d'Azur
83026,CABASSE,83340,CABASSE,,43.4270972655,6.23202349485,26,,Cabasse,Cabasse,83,Var,93,Provence-Alpes-Côte d'Azur
83027,LA CADIERE D AZUR,83740,LA CADIERE D AZUR,,43.2032263059,5.72471001592,27,La,Cadière-d'Azur,La Cadière-d'Azur,83,Var,93,Provence-Alpes-Côte d'Azur
83027,LA CADIERE D AZUR,83740,LA CADIERE D AZUR,GRAND MOULIN,43.2032263059,5.72471001592,27,La,Cadière-d'Azur,La Cadière-d'Azur,83,Var,93,Provence-Alpes-Côte d'Azur
83028,CALLAS,83830,CALLAS,,43.5655288351,6.57328931995,28,,Callas,Callas,83,Var,93,Provence-Alpes-Côte d'Azur
83029,CALLIAN,83440,CALLIAN,,43.6125180228,6.75708895004,29,,Callian,Callian,83,Var,93,Provence-Alpes-Côte d'Azur
83030,CAMPS LA SOURCE,83170,CAMPS LA SOURCE,,43.372512011,6.12128418794,30,,Camps-la-Source,Camps-la-Source,83,Var,93,Provence-Alpes-Côte d'Azur
83031,LE CANNET DES MAURES,83340,LE CANNET DES MAURES,,43.3737632121,6.3755010333,31,Le,Cannet-des-Maures,Le Cannet-des-Maures,83,Var,93,Provence-Alpes-Côte d'Azur
83032,CARCES,83570,CARCES,,43.4690059217,6.17637273437,32,,Carcès,Carcès,83,Var,93,Provence-Alpes-Côte d'Azur
83033,CARNOULES,83660,CARNOULES,,43.2945985061,6.19667974945,33,,Carnoules,Carnoules,83,Var,93,Provence-Alpes-Côte d'Azur
83034,CARQUEIRANNE,83320,CARQUEIRANNE,,43.0971940145,6.0717379342,34,,Carqueiranne,Carqueiranne,83,Var,93,Provence-Alpes-Côte d'Azur
83035,LE CASTELLET,83330,LE CASTELLET,,43.227667489,5.76560108367,35,Le,Castellet,Le Castellet,83,Var,93,Provence-Alpes-Côte d'Azur
83035,LE CASTELLET,83330,LE CASTELLET,LA BEGUDE,43.227667489,5.76560108367,35,Le,Castellet,Le Castellet,83,Var,93,Provence-Alpes-Côte d'Azur
83035,LE CASTELLET,83330,LE CASTELLET,LAOUQUE,43.227667489,5.76560108367,35,Le,Castellet,Le Castellet,83,Var,93,Provence-Alpes-Côte d'Azur
83035,LE CASTELLET,83330,LE CASTELLET,LE CAMP,43.227667489,5.76560108367,35,Le,Castellet,Le Castellet,83,Var,93,Provence-Alpes-Côte d'Azur
83035,LE CASTELLET,83330,LE CASTELLET,STE ANNE DU CASTELLET,43.227667489,5.76560108367,35,Le,Castellet,Le Castellet,83,Var,93,Provence-Alpes-Côte d'Azur
83036,CAVALAIRE SUR MER,83240,CAVALAIRE SUR MER,,43.1819055812,6.52121721395,36,,Cavalaire-sur-Mer,Cavalaire-sur-Mer,83,Var,93,Provence-Alpes-Côte d'Azur
83037,LA CELLE,83170,LA CELLE,,43.384370351,5.99861411611,37,La,Celle,La Celle,83,Var,93,Provence-Alpes-Côte d'Azur
83038,CHATEAUDOUBLE,83300,CHATEAUDOUBLE,,43.6234657127,6.43567132454,38,,Châteaudouble,Châteaudouble,83,Var,93,Provence-Alpes-Côte d'Azur
83038,CHATEAUDOUBLE,83300,CHATEAUDOUBLE,REBOUILLON,43.6234657127,6.43567132454,38,,Châteaudouble,Châteaudouble,83,Var,93,Provence-Alpes-Côte d'Azur
83039,CHATEAUVERT,83670,CHATEAUVERT,,43.5012629533,6.01120516318,39,,Châteauvert,Châteauvert,83,Var,93,Provence-Alpes-Côte d'Azur
83040,CHATEAUVIEUX,83840,CHATEAUVIEUX,,43.7886170767,6.59569074716,40,,Châteauvieux,Châteauvieux,83,Var,93,Provence-Alpes-Côte d'Azur
83041,CLAVIERS,83830,CLAVIERS,,43.5988361842,6.58569908601,41,,Claviers,Claviers,83,Var,93,Provence-Alpes-Côte d'Azur
83042,COGOLIN,83310,COGOLIN,,43.2464550515,6.52192234084,42,,Cogolin,Cogolin,83,Var,93,Provence-Alpes-Côte d'Azur
83043,COLLOBRIERES,83610,COLLOBRIERES,,43.2414148175,6.34032184648,43,,Collobrières,Collobrières,83,Var,93,Provence-Alpes-Côte d'Azur
83044,COMPS SUR ARTUBY,83840,COMPS SUR ARTUBY,,43.7035520107,6.50293185659,44,,Comps-sur-Artuby,Comps-sur-Artuby,83,Var,93,Provence-Alpes-Côte d'Azur
83045,CORRENS,83570,CORRENS,,43.4864185627,6.06826217623,45,,Correns,Correns,83,Var,93,Provence-Alpes-Côte d'Azur
83046,COTIGNAC,83570,COTIGNAC,,43.5233757149,6.14490029042,46,,Cotignac,Cotignac,83,Var,93,Provence-Alpes-Côte d'Azur
83047,LA CRAU,83260,LA CRAU,,43.1635207421,6.09268351583,47,La,Crau,La Crau,83,Var,93,Provence-Alpes-Côte d'Azur
83047,LA CRAU,83260,LA CRAU,LA MOUTONNE,43.1635207421,6.09268351583,47,La,Crau,La Crau,83,Var,93,Provence-Alpes-Côte d'Azur
83048,LA CROIX VALMER,83420,LA CROIX VALMER,,43.1951890718,6.5862813598,48,La,Croix-Valmer,La Croix-Valmer,83,Var,93,Provence-Alpes-Côte d'Azur
83049,CUERS,83390,CUERS,,43.2411645502,6.06856350453,49,,Cuers,Cuers,83,Var,93,Provence-Alpes-Côte d'Azur
83050,DRAGUIGNAN,83300,DRAGUIGNAN,,43.5357513285,6.45436569191,50,,Draguignan,Draguignan,83,Var,93,Provence-Alpes-Côte d'Azur
83051,ENTRECASTEAUX,83570,ENTRECASTEAUX,,43.5119622438,6.23475108853,51,,Entrecasteaux,Entrecasteaux,83,Var,93,Provence-Alpes-Côte d'Azur
83052,ESPARRON,83560,ESPARRON,,43.5874697743,5.84872066218,52,,Esparron,Esparron,83,Var,93,Provence-Alpes-Côte d'Azur
83053,EVENOS,83330,EVENOS,,43.1862421767,5.8730401354,53,,Évenos,Évenos,83,Var,93,Provence-Alpes-Côte d'Azur
83054,LA FARLEDE,83210,LA FARLEDE,,43.1615363005,6.04275325508,54,La,Farlède,La Farlède,83,Var,93,Provence-Alpes-Côte d'Azur
83055,FAYENCE,83440,FAYENCE,,43.6103345822,6.67843279926,55,,Fayence,Fayence,83,Var,93,Provence-Alpes-Côte d'Azur
83056,FIGANIERES,83830,FIGANIERES,,43.5672152549,6.49870478854,56,,Figanières,Figanières,83,Var,93,Provence-Alpes-Côte d'Azur
83057,FLASSANS SUR ISSOLE,83340,FLASSANS SUR ISSOLE,,43.3760987922,6.22162416541,57,,Flassans-sur-Issole,Flassans-sur-Issole,83,Var,93,Provence-Alpes-Côte d'Azur
83058,FLAYOSC,83780,FLAYOSC,,43.542212098,6.36275720984,58,,Flayosc,Flayosc,83,Var,93,Provence-Alpes-Côte d'Azur
83059,FORCALQUEIRET,83136,FORCALQUEIRET,,43.3366392546,6.09141410603,59,,Forcalqueiret,Forcalqueiret,83,Var,93,Provence-Alpes-Côte d'Azur
83060,FOX AMPHOUX,83670,FOX AMPHOUX,,43.5955417496,6.10909327573,60,,Fox-Amphoux,Fox-Amphoux,83,Var,93,Provence-Alpes-Côte d'Azur
83061,FREJUS,83370,FREJUS,ST AYGULF,43.4719558114,6.76361597424,61,,Fréjus,Fréjus,83,Var,93,Provence-Alpes-Côte d'Azur
83061,FREJUS,83600,FREJUS,,43.4719558114,6.76361597424,61,,Fréjus,Fréjus,83,Var,93,Provence-Alpes-Côte d'Azur
83062,LA GARDE,83130,LA GARDE,,43.1263279384,6.01898164719,62,La,Garde,La Garde,83,Var,93,Provence-Alpes-Côte d'Azur
83063,LA GARDE FREINET,83680,LA GARDE FREINET,,43.3234160321,6.46693786821,63,La,Garde-Freinet,La Garde-Freinet,83,Var,93,Provence-Alpes-Côte d'Azur
83064,GAREOULT,83136,GAREOULT,,43.3403271328,6.04087625954,64,,Garéoult,Garéoult,83,Var,93,Provence-Alpes-Côte d'Azur
83065,GASSIN,83580,GASSIN,,43.2392065224,6.58617518642,65,,Gassin,Gassin,83,Var,93,Provence-Alpes-Côte d'Azur
83066,GINASSERVIS,83560,GINASSERVIS,,43.6592918147,5.83752000648,66,,Ginasservis,Ginasservis,83,Var,93,Provence-Alpes-Côte d'Azur
83067,GONFARON,83590,GONFARON,,43.3206494882,6.30036862428,67,,Gonfaron,Gonfaron,83,Var,93,Provence-Alpes-Côte d'Azur
83068,GRIMAUD,83310,GRIMAUD,,43.2820282501,6.53303224114,68,,Grimaud,Grimaud,83,Var,93,Provence-Alpes-Côte d'Azur
83068,GRIMAUD,83310,GRIMAUD,PORT GRIMAUD,43.2820282501,6.53303224114,68,,Grimaud,Grimaud,83,Var,93,Provence-Alpes-Côte d'Azur
83069,HYERES,83400,HYERES,,43.1018713534,6.18898508469,69,,Hyères,Hyères,83,Var,93,Provence-Alpes-Côte d'Azur
83069,HYERES,83400,HYERES,AYGUADE CEINTURON,43.1018713534,6.18898508469,69,,Hyères,Hyères,83,Var,93,Provence-Alpes-Côte d'Azur
83069,HYERES,83400,HYERES,GIENS,43.1018713534,6.18898508469,69,,Hyères,Hyères,83,Var,93,Provence-Alpes-Côte d'Azur
83069,HYERES,83400,HYERES,HYERES PLAGE,43.1018713534,6.18898508469,69,,Hyères,Hyères,83,Var,93,Provence-Alpes-Côte d'Azur
83069,HYERES,83400,HYERES,ILE DE PORT CROS,43.1018713534,6.18898508469,69,,Hyères,Hyères,83,Var,93,Provence-Alpes-Côte d'Azur
83069,HYERES,83400,HYERES,ILE DU LEVANT,43.1018713534,6.18898508469,69,,Hyères,Hyères,83,Var,93,Provence-Alpes-Côte d'Azur
83069,HYERES,83400,HYERES,LA CAPTE,43.1018713534,6.18898508469,69,,Hyères,Hyères,83,Var,93,Provence-Alpes-Côte d'Azur
83069,HYERES,83400,HYERES,LES SALINS D HYERES,43.1018713534,6.18898508469,69,,Hyères,Hyères,83,Var,93,Provence-Alpes-Côte d'Azur
83069,HYERES,83400,HYERES,PORQUEROLLES,43.1018713534,6.18898508469,69,,Hyères,Hyères,83,Var,93,Provence-Alpes-Côte d'Azur
83069,HYERES,83400,HYERES,PORT CROS,43.1018713534,6.18898508469,69,,Hyères,Hyères,83,Var,93,Provence-Alpes-Côte d'Azur
83070,LE LAVANDOU,83980,LE LAVANDOU,,43.1656102964,6.41231277863,70,Le,Lavandou,Le Lavandou,83,Var,93,Provence-Alpes-Côte d'Azur
83070,LE LAVANDOU,83980,LE LAVANDOU,CAVALIERE,43.1656102964,6.41231277863,70,Le,Lavandou,Le Lavandou,83,Var,93,Provence-Alpes-Côte d'Azur
83071,LA LONDE LES MAURES,83250,LA LONDE LES MAURES,,43.1689213414,6.24285151023,71,La,Londe-les-Maures,La Londe-les-Maures,83,Var,93,Provence-Alpes-Côte d'Azur
83072,LORGUES,83510,LORGUES,,43.4814630298,6.35892744946,72,,Lorgues,Lorgues,83,Var,93,Provence-Alpes-Côte d'Azur
83073,LE LUC,83340,LE LUC,,43.3804648949,6.30599950133,73,Le,Luc,Le Luc,83,Var,93,Provence-Alpes-Côte d'Azur
83074,LA MARTRE,83840,LA MARTRE,,43.76358635,6.59269594698,74,La,Martre,La Martre,83,Var,93,Provence-Alpes-Côte d'Azur
83075,LES MAYONS,83340,LES MAYONS,,43.3069101873,6.35996172662,75,Les,Mayons,Les Mayons,83,Var,93,Provence-Alpes-Côte d'Azur
83076,MAZAUGUES,83136,MAZAUGUES,,43.3448761166,5.89589582308,76,,Mazaugues,Mazaugues,83,Var,93,Provence-Alpes-Côte d'Azur
83077,MEOUNES LES MONTRIEUX,83136,MEOUNES LES MONTRIEUX,,43.273942519,5.96671080974,77,,Méounes-lès-Montrieux,Méounes-lès-Montrieux,83,Var,93,Provence-Alpes-Côte d'Azur
83078,MOISSAC BELLEVUE,83630,MOISSAC BELLEVUE,,43.6471902756,6.16702503297,78,,Moissac-Bellevue,Moissac-Bellevue,83,Var,93,Provence-Alpes-Côte d'Azur
83079,LA MOLE,83310,LA MOLE,,43.2159206327,6.47656643864,79,La,Môle,La Môle,83,Var,93,Provence-Alpes-Côte d'Azur
83080,MONS,83440,MONS,,43.6989466461,6.70893258048,80,,Mons,Mons,83,Var,93,Provence-Alpes-Côte d'Azur
83081,MONTAUROUX,83440,MONTAUROUX,,43.5990101065,6.78985995347,81,,Montauroux,Montauroux,83,Var,93,Provence-Alpes-Côte d'Azur
83082,MONTFERRAT,83131,MONTFERRAT,,43.6363885978,6.47846723467,82,,Montferrat,Montferrat,83,Var,93,Provence-Alpes-Côte d'Azur
83083,MONTFORT SUR ARGENS,83570,MONTFORT SUR ARGENS,,43.4812536631,6.12560767207,83,,Montfort-sur-Argens,Montfort-sur-Argens,83,Var,93,Provence-Alpes-Côte d'Azur
83084,MONTMEYAN,83670,MONTMEYAN,,43.6482569436,6.06124619134,84,,Montmeyan,Montmeyan,83,Var,93,Provence-Alpes-Côte d'Azur
83085,LA MOTTE,83920,LA MOTTE,,43.5078511168,6.55055399937,85,La,Motte,La Motte,83,Var,93,Provence-Alpes-Côte d'Azur
83086,LE MUY,83490,LE MUY,,43.4687307949,6.576949368,86,Le,Muy,Le Muy,83,Var,93,Provence-Alpes-Côte d'Azur
83087,NANS LES PINS,83860,NANS LES PINS,,43.3790514316,5.78486448585,87,,Nans-les-Pins,Nans-les-Pins,83,Var,93,Provence-Alpes-Côte d'Azur
83088,NEOULES,83136,NEOULES,,43.2945310996,6.02592739077,88,,Néoules,Néoules,83,Var,93,Provence-Alpes-Côte d'Azur
83089,OLLIERES,83470,OLLIERES,,43.5101955138,5.81646293354,89,,Ollières,Ollières,83,Var,93,Provence-Alpes-Côte d'Azur
83090,OLLIOULES,83190,OLLIOULES,,43.1386133658,5.85369204547,90,,Ollioules,Ollioules,83,Var,93,Provence-Alpes-Côte d'Azur
83091,PIERREFEU DU VAR,83390,PIERREFEU DU VAR,,43.2345472455,6.17955823473,91,,Pierrefeu-du-Var,Pierrefeu-du-Var,83,Var,93,Provence-Alpes-Côte d'Azur
83092,PIGNANS,83790,PIGNANS,,43.2968837854,6.25442934778,92,,Pignans,Pignans,83,Var,93,Provence-Alpes-Côte d'Azur
83093,PLAN D AUPS STE BAUME,83640,PLAN D AUPS STE BAUME,,43.3337130548,5.73856018349,93,,Plan-d'Aups-Sainte-Baume,Plan-d'Aups-Sainte-Baume,83,Var,93,Provence-Alpes-Côte d'Azur
83094,LE PLAN DE LA TOUR,83120,LE PLAN DE LA TOUR,,43.3384388996,6.54745751076,94,Le,Plan-de-la-Tour,Le Plan-de-la-Tour,83,Var,93,Provence-Alpes-Côte d'Azur
83095,PONTEVES,83670,PONTEVES,,43.5507745472,6.0629204725,95,,Pontevès,Pontevès,83,Var,93,Provence-Alpes-Côte d'Azur
83096,POURCIEUX,83470,POURCIEUX,,43.4646440633,5.78860890398,96,,Pourcieux,Pourcieux,83,Var,93,Provence-Alpes-Côte d'Azur
83097,POURRIERES,83910,POURRIERES,,43.4938863602,5.74717711665,97,,Pourrières,Pourrières,83,Var,93,Provence-Alpes-Côte d'Azur
83098,LE PRADET,83220,LE PRADET,,43.099405984,6.02844015499,98,Le,Pradet,Le Pradet,83,Var,93,Provence-Alpes-Côte d'Azur
83099,PUGET SUR ARGENS,83480,PUGET SUR ARGENS,,43.4717671252,6.68628195857,99,,Puget-sur-Argens,Puget-sur-Argens,83,Var,93,Provence-Alpes-Côte d'Azur
83100,PUGET VILLE,83390,PUGET VILLE,,43.2763475939,6.14893054109,100,,Puget-Ville,Puget-Ville,83,Var,93,Provence-Alpes-Côte d'Azur
83101,RAMATUELLE,83350,RAMATUELLE,,43.2186126186,6.63754734174,101,,Ramatuelle,Ramatuelle,83,Var,93,Provence-Alpes-Côte d'Azur
83102,REGUSSE,83630,REGUSSE,,43.6676978951,6.11814572566,102,,Régusse,Régusse,83,Var,93,Provence-Alpes-Côte d'Azur
83103,LE REVEST LES EAUX,83200,LE REVEST LES EAUX,,43.1824918424,5.94565392204,103,Le,Revest-les-Eaux,Le Revest-les-Eaux,83,Var,93,Provence-Alpes-Côte d'Azur
83104,RIANS,83560,RIANS,,43.6055023788,5.74132523759,104,,Rians,Rians,83,Var,93,Provence-Alpes-Côte d'Azur
83105,RIBOUX,13780,RIBOUX,,43.3081877669,5.75722408785,105,,Riboux,Riboux,83,Var,93,Provence-Alpes-Côte d'Azur
83106,ROCBARON,83136,ROCBARON,,43.3066686926,6.08361658334,106,,Rocbaron,Rocbaron,83,Var,93,Provence-Alpes-Côte d'Azur
83107,ROQUEBRUNE SUR ARGENS,83380,ROQUEBRUNE SUR ARGENS,LES ISSAMBRES,43.4285090958,6.65212881719,107,,Roquebrune-sur-Argens,Roquebrune-sur-Argens,83,Var,93,Provence-Alpes-Côte d'Azur
83107,ROQUEBRUNE SUR ARGENS,83520,ROQUEBRUNE SUR ARGENS,,43.4285090958,6.65212881719,107,,Roquebrune-sur-Argens,Roquebrune-sur-Argens,83,Var,93,Provence-Alpes-Côte d'Azur
83108,LA ROQUEBRUSSANNE,83136,LA ROQUEBRUSSANNE,,43.3371906607,5.9839377705,108,La,Roquebrussanne,La Roquebrussanne,83,Var,93,Provence-Alpes-Côte d'Azur
83109,LA ROQUE ESCLAPON,83840,LA ROQUE ESCLAPON,,43.7213872883,6.64818087429,109,La,Roque-Esclapon,La Roque-Esclapon,83,Var,93,Provence-Alpes-Côte d'Azur
83110,ROUGIERS,83170,ROUGIERS,,43.3818574426,5.8514412718,110,,Rougiers,Rougiers,83,Var,93,Provence-Alpes-Côte d'Azur
83111,STE ANASTASIE SUR ISSOLE,83136,STE ANASTASIE SUR ISSOLE,,43.3334103841,6.12496118501,111,,Sainte-Anastasie-sur-Issole,Sainte-Anastasie-sur-Issole,83,Var,93,Provence-Alpes-Côte d'Azur
83112,ST CYR SUR MER,83270,ST CYR SUR MER,,43.1720355248,5.70832603708,112,,Saint-Cyr-sur-Mer,Saint-Cyr-sur-Mer,83,Var,93,Provence-Alpes-Côte d'Azur
83112,ST CYR SUR MER,83270,ST CYR SUR MER,LA MADRAGUE,43.1720355248,5.70832603708,112,,Saint-Cyr-sur-Mer,Saint-Cyr-sur-Mer,83,Var,93,Provence-Alpes-Côte d'Azur
83112,ST CYR SUR MER,83270,ST CYR SUR MER,LES BAUMELLES,43.1720355248,5.70832603708,112,,Saint-Cyr-sur-Mer,Saint-Cyr-sur-Mer,83,Var,93,Provence-Alpes-Côte d'Azur
83112,ST CYR SUR MER,83270,ST CYR SUR MER,LES LECQUES,43.1720355248,5.70832603708,112,,Saint-Cyr-sur-Mer,Saint-Cyr-sur-Mer,83,Var,93,Provence-Alpes-Côte d'Azur
83113,ST JULIEN,83560,ST JULIEN,,43.6999613716,5.91499316614,113,,Saint-Julien,Saint-Julien,83,Var,93,Provence-Alpes-Côte d'Azur
83114,ST MARTIN DE PALLIERES,83560,ST MARTIN DE PALLIERES,,43.5741845385,5.88790860086,114,,Saint-Martin-de-Pallières,Saint-Martin-de-Pallières,83,Var,93,Provence-Alpes-Côte d'Azur
83115,STE MAXIME,83120,STE MAXIME,,43.3564730986,6.61172790208,115,,Sainte-Maxime,Sainte-Maxime,83,Var,93,Provence-Alpes-Côte d'Azur
83116,ST MAXIMIN LA STE BAUME,83470,ST MAXIMIN LA STE BAUME,,43.4509676414,5.85962873535,116,,Saint-Maximin-la-Sainte-Baume,Saint-Maximin-la-Sainte-Baume,83,Var,93,Provence-Alpes-Côte d'Azur
83117,ST PAUL EN FORET,83440,ST PAUL EN FORET,,43.564768209,6.68269608083,117,,Saint-Paul-en-Forêt,Saint-Paul-en-Forêt,83,Var,93,Provence-Alpes-Côte d'Azur
83118,ST RAPHAEL,83530,ST RAPHAEL,AGAY,43.4574625431,6.84734210398,118,,Saint-Raphaël,Saint-Raphaël,83,Var,93,Provence-Alpes-Côte d'Azur
83118,ST RAPHAEL,83530,ST RAPHAEL,ANTHEOR,43.4574625431,6.84734210398,118,,Saint-Raphaël,Saint-Raphaël,83,Var,93,Provence-Alpes-Côte d'Azur
83118,ST RAPHAEL,83530,ST RAPHAEL,LE DRAMONT,43.4574625431,6.84734210398,118,,Saint-Raphaël,Saint-Raphaël,83,Var,93,Provence-Alpes-Côte d'Azur
83118,ST RAPHAEL,83530,ST RAPHAEL,LE TRAYAS,43.4574625431,6.84734210398,118,,Saint-Raphaël,Saint-Raphaël,83,Var,93,Provence-Alpes-Côte d'Azur
83118,ST RAPHAEL,83700,ST RAPHAEL,,43.4574625431,6.84734210398,118,,Saint-Raphaël,Saint-Raphaël,83,Var,93,Provence-Alpes-Côte d'Azur
83118,ST RAPHAEL,83700,ST RAPHAEL,BOULOURIS,43.4574625431,6.84734210398,118,,Saint-Raphaël,Saint-Raphaël,83,Var,93,Provence-Alpes-Côte d'Azur
83119,ST TROPEZ,83990,ST TROPEZ,,43.262314383,6.66343039908,119,,Saint-Tropez,Saint-Tropez,83,Var,93,Provence-Alpes-Côte d'Azur
83120,ST ZACHARIE,83640,ST ZACHARIE,,43.3775300587,5.71820851044,120,,Saint-Zacharie,Saint-Zacharie,83,Var,93,Provence-Alpes-Côte d'Azur
83121,SALERNES,83690,SALERNES,,43.5621099339,6.22873067235,121,,Salernes,Salernes,83,Var,93,Provence-Alpes-Côte d'Azur
83122,LES SALLES SUR VERDON,83630,LES SALLES SUR VERDON,,43.7670142156,6.20652542725,122,Les,Salles-sur-Verdon,Les Salles-sur-Verdon,83,Var,93,Provence-Alpes-Côte d'Azur
83123,SANARY SUR MER,83110,SANARY SUR MER,,43.138282853,5.79588814177,123,,Sanary-sur-Mer,Sanary-sur-Mer,83,Var,93,Provence-Alpes-Côte d'Azur
83124,SEILLANS,83440,SEILLANS,,43.6466457482,6.61301408536,124,,Seillans,Seillans,83,Var,93,Provence-Alpes-Côte d'Azur
83124,SEILLANS,83440,SEILLANS,BROVES,43.6466457482,6.61301408536,124,,Seillans,Seillans,83,Var,93,Provence-Alpes-Côte d'Azur
83125,SEILLONS SOURCE D ARGENS,83470,SEILLONS SOURCE D ARGENS,,43.5150970565,5.87693570046,125,,Seillons-Source-d'Argens,Seillons-Source-d'Argens,83,Var,93,Provence-Alpes-Côte d'Azur
83126,LA SEYNE SUR MER,83500,LA SEYNE SUR MER,,43.0880294967,5.87089841754,126,La,Seyne-sur-Mer,La Seyne-sur-Mer,83,Var,93,Provence-Alpes-Côte d'Azur
83126,LA SEYNE SUR MER,83500,LA SEYNE SUR MER,LES SABLETTES,43.0880294967,5.87089841754,126,La,Seyne-sur-Mer,La Seyne-sur-Mer,83,Var,93,Provence-Alpes-Côte d'Azur
83126,LA SEYNE SUR MER,83500,LA SEYNE SUR MER,TAMARIS SUR MER,43.0880294967,5.87089841754,126,La,Seyne-sur-Mer,La Seyne-sur-Mer,83,Var,93,Provence-Alpes-Côte d'Azur
83127,SIGNES,83870,SIGNES,,43.2776407451,5.8571396222,127,,Signes,Signes,83,Var,93,Provence-Alpes-Côte d'Azur
83128,SILLANS LA CASCADE,83690,SILLANS LA CASCADE,,43.5658824056,6.16515240575,128,,Sillans-la-Cascade,Sillans-la-Cascade,83,Var,93,Provence-Alpes-Côte d'Azur
83129,SIX FOURS LES PLAGES,83140,SIX FOURS LES PLAGES,,43.086818602,5.82924464931,129,,Six-Fours-les-Plages,Six-Fours-les-Plages,83,Var,93,Provence-Alpes-Côte d'Azur
83129,SIX FOURS LES PLAGES,83140,SIX FOURS LES PLAGES,LE BRUSC,43.086818602,5.82924464931,129,,Six-Fours-les-Plages,Six-Fours-les-Plages,83,Var,93,Provence-Alpes-Côte d'Azur
83129,SIX FOURS LES PLAGES,83140,SIX FOURS LES PLAGES,LES LONES,43.086818602,5.82924464931,129,,Six-Fours-les-Plages,Six-Fours-les-Plages,83,Var,93,Provence-Alpes-Côte d'Azur
83130,SOLLIES PONT,83210,SOLLIES PONT,,43.1907611248,6.06327697157,130,,Solliès-Pont,Solliès-Pont,83,Var,93,Provence-Alpes-Côte d'Azur
83131,SOLLIES TOUCAS,83210,SOLLIES TOUCAS,,43.21282693,5.98693908648,131,,Solliès-Toucas,Solliès-Toucas,83,Var,93,Provence-Alpes-Côte d'Azur
83132,SOLLIES VILLE,83210,SOLLIES VILLE,,43.1784166306,6.01726722545,132,,Solliès-Ville,Solliès-Ville,83,Var,93,Provence-Alpes-Côte d'Azur
83133,TANNERON,83440,TANNERON,,43.5748995232,6.85671079494,133,,Tanneron,Tanneron,83,Var,93,Provence-Alpes-Côte d'Azur
83134,TARADEAU,83460,TARADEAU,,43.4612575373,6.43066360323,134,,Taradeau,Taradeau,83,Var,93,Provence-Alpes-Côte d'Azur
83135,TAVERNES,83670,TAVERNES,,43.6002244031,6.02099356795,135,,Tavernes,Tavernes,83,Var,93,Provence-Alpes-Côte d'Azur
83136,LE THORONET,83340,LE THORONET,,43.458933028,6.28265623456,136,Le,Thoronet,Le Thoronet,83,Var,93,Provence-Alpes-Côte d'Azur
83137,TOULON,83000,TOULON,,43.1361589728,5.93239634249,137,,Toulon,Toulon,83,Var,93,Provence-Alpes-Côte d'Azur
83137,TOULON,83100,TOULON,,43.1361589728,5.93239634249,137,,Toulon,Toulon,83,Var,93,Provence-Alpes-Côte d'Azur
83137,TOULON,83200,TOULON,,43.1361589728,5.93239634249,137,,Toulon,Toulon,83,Var,93,Provence-Alpes-Côte d'Azur
83138,TOURRETTES,83440,TOURRETTES,,43.6140539601,6.72378364475,138,,Tourrettes,Tourrettes,83,Var,93,Provence-Alpes-Côte d'Azur
83139,TOURTOUR,83690,TOURTOUR,,43.5935800875,6.310682972,139,,Tourtour,Tourtour,83,Var,93,Provence-Alpes-Côte d'Azur
83140,TOURVES,83170,TOURVES,,43.4088015642,5.92715707141,140,,Tourves,Tourves,83,Var,93,Provence-Alpes-Côte d'Azur
83141,TRANS EN PROVENCE,83720,TRANS EN PROVENCE,,43.5024684242,6.48943252904,141,,Trans-en-Provence,Trans-en-Provence,83,Var,93,Provence-Alpes-Côte d'Azur
83142,TRIGANCE,83840,TRIGANCE,,43.7414008338,6.43648594376,142,,Trigance,Trigance,83,Var,93,Provence-Alpes-Côte d'Azur
83143,LE VAL,83143,LE VAL,,43.4452384829,6.05179903767,143,Le,Val,Le Val,83,Var,93,Provence-Alpes-Côte d'Azur
83144,LA VALETTE DU VAR,83160,LA VALETTE DU VAR,,43.1499152044,5.99222538877,144,La,Valette-du-Var,La Valette-du-Var,83,Var,93,Provence-Alpes-Côte d'Azur
83145,VARAGES,83670,VARAGES,,43.5957332501,5.94441099712,145,,Varages,Varages,83,Var,93,Provence-Alpes-Côte d'Azur
83146,LA VERDIERE,83560,LA VERDIERE,,43.6461099213,5.95232275881,146,La,Verdière,La Verdière,83,Var,93,Provence-Alpes-Côte d'Azur
83147,VERIGNON,83630,VERIGNON,,43.6625284051,6.27650359244,147,,Vérignon,Vérignon,83,Var,93,Provence-Alpes-Côte d'Azur
83148,VIDAUBAN,83550,VIDAUBAN,,43.4015046271,6.44908485499,148,,Vidauban,Vidauban,83,Var,93,Provence-Alpes-Côte d'Azur
83149,VILLECROZE,83690,VILLECROZE,,43.564445349,6.28543419016,149,,Villecroze,Villecroze,83,Var,93,Provence-Alpes-Côte d'Azur
83150,VINON SUR VERDON,83560,VINON SUR VERDON,,43.7224277972,5.80747605851,150,,Vinon-sur-Verdon,Vinon-sur-Verdon,83,Var,93,Provence-Alpes-Côte d'Azur
83151,VINS SUR CARAMY,83170,VINS SUR CARAMY,,43.4330128814,6.15001836504,151,,Vins-sur-Caramy,Vins-sur-Caramy,83,Var,93,Provence-Alpes-Côte d'Azur
83152,RAYOL CANADEL SUR MER,83820,RAYOL CANADEL SUR MER,,43.1642973413,6.47477478292,152,,Rayol-Canadel-sur-Mer,Rayol-Canadel-sur-Mer,83,Var,93,Provence-Alpes-Côte d'Azur
83152,RAYOL CANADEL SUR MER,83820,RAYOL CANADEL SUR MER,CANADEL,43.1642973413,6.47477478292,152,,Rayol-Canadel-sur-Mer,Rayol-Canadel-sur-Mer,83,Var,93,Provence-Alpes-Côte d'Azur
83153,ST MANDRIER SUR MER,83430,ST MANDRIER SUR MER,,43.0754322948,5.92642315155,153,,Saint-Mandrier-sur-Mer,Saint-Mandrier-sur-Mer,83,Var,93,Provence-Alpes-Côte d'Azur
83154,ST ANTONIN DU VAR,83510,ST ANTONIN DU VAR,,43.5112335006,6.29002187703,154,,Saint-Antonin-du-Var,Saint-Antonin-du-Var,83,Var,93,Provence-Alpes-Côte d'Azur
84001,ALTHEN DES PALUDS,84210,ALTHEN DES PALUDS,,44.0048087082,4.95793804186,1,,Althen-des-Paluds,Althen-des-Paluds,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84002,ANSOUIS,84240,ANSOUIS,,43.7365172868,5.47136689464,2,,Ansouis,Ansouis,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84003,APT,84400,APT,,43.879393265,5.38921757843,3,,Apt,Apt,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84004,AUBIGNAN,84810,AUBIGNAN,,44.0977380589,5.03165676735,4,,Aubignan,Aubignan,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84005,AUREL,84390,AUREL,,44.1381264678,5.39633326209,5,,Aurel,Aurel,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84006,AURIBEAU,84400,AURIBEAU,,43.8299771658,5.44809359823,6,,Auribeau,Auribeau,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84007,AVIGNON,84000,AVIGNON,,43.9352448339,4.84071572505,7,,Avignon,Avignon,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84007,AVIGNON,84140,AVIGNON,MONTFAVET,43.9352448339,4.84071572505,7,,Avignon,Avignon,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84008,LE BARROUX,84330,LE BARROUX,,44.1547724058,5.09918948911,8,Le,Barroux,Le Barroux,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84009,LA BASTIDE DES JOURDANS,84240,LA BASTIDE DES JOURDANS,,43.7945397599,5.64677782095,9,La,Bastide-des-Jourdans,La Bastide-des-Jourdans,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84010,LA BASTIDONNE,84120,LA BASTIDONNE,,43.6928383261,5.57769351768,10,La,Bastidonne,La Bastidonne,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84011,LE BEAUCET,84210,LE BEAUCET,,43.9740267941,5.13129776172,11,Le,Beaucet,Le Beaucet,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84012,BEAUMES DE VENISE,84190,BEAUMES DE VENISE,,44.1233856856,5.02875589827,12,,Beaumes-de-Venise,Beaumes-de-Venise,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84013,BEAUMETTES,84220,BEAUMETTES,,43.8631590289,5.2036579356,13,,Beaumettes,Beaumettes,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84014,BEAUMONT DE PERTUIS,84120,BEAUMONT DE PERTUIS,,43.7379108971,5.69599127224,14,,Beaumont-de-Pertuis,Beaumont-de-Pertuis,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84015,BEAUMONT DU VENTOUX,84340,BEAUMONT DU VENTOUX,,44.1826393714,5.21385670497,15,,Beaumont-du-Ventoux,Beaumont-du-Ventoux,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84016,BEDARRIDES,84370,BEDARRIDES,,44.0477457533,4.90804109098,16,,Bédarrides,Bédarrides,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84017,BEDOIN,84410,BEDOIN,,44.1374624096,5.23446005284,17,,Bédoin,Bédoin,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84018,BLAUVAC,84570,BLAUVAC,,44.0369893716,5.24329379673,18,,Blauvac,Blauvac,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84019,BOLLENE,84500,BOLLENE,,44.2882016409,4.75235956257,19,,Bollène,Bollène,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84020,BONNIEUX,84480,BONNIEUX,,43.8270930656,5.31457906958,20,,Bonnieux,Bonnieux,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84021,BRANTES,84390,BRANTES,,44.1853662846,5.3324742513,21,,Brantes,Brantes,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84022,BUISSON,84110,BUISSON,,44.2761846134,5.00180862169,22,,Buisson,Buisson,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84023,BUOUX,84480,BUOUX,,43.8218210603,5.37501768295,23,,Buoux,Buoux,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84024,CABRIERES D AIGUES,84240,CABRIERES D AIGUES,,43.7913015359,5.49177311487,24,,Cabrières-d'Aigues,Cabrières-d'Aigues,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84025,CABRIERES D AVIGNON,84220,CABRIERES D AVIGNON,,43.8965322686,5.15279801626,25,,Cabrières-d'Avignon,Cabrières-d'Avignon,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84026,CADENET,84160,CADENET,,43.7304056282,5.38354319666,26,,Cadenet,Cadenet,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84027,CADEROUSSE,84860,CADEROUSSE,,44.1099358763,4.74345046999,27,,Caderousse,Caderousse,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84028,CAIRANNE,84290,CAIRANNE,,44.2302575549,4.93339996862,28,,Cairanne,Cairanne,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84029,CAMARET SUR AIGUES,84850,CAMARET SUR AIGUES,,44.1596814027,4.88410397503,29,,Camaret-sur-Aigues,Camaret-sur-Aigues,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84030,CAROMB,84330,CAROMB,,44.1124328753,5.10687040517,30,,Caromb,Caromb,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84031,CARPENTRAS,84200,CARPENTRAS,,44.0593802565,5.06134844776,31,,Carpentras,Carpentras,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84032,CASENEUVE,84750,CASENEUVE,,43.8888688666,5.47985826048,32,,Caseneuve,Caseneuve,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84033,CASTELLET,84400,CASTELLET,,43.834452269,5.48116657241,33,,Castellet,Castellet,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84034,CAUMONT SUR DURANCE,84510,CAUMONT SUR DURANCE,,43.8913580487,4.95633077332,34,,Caumont-sur-Durance,Caumont-sur-Durance,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84035,CAVAILLON,84300,CAVAILLON,,43.8508361826,5.03606300916,35,,Cavaillon,Cavaillon,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84035,CAVAILLON,84300,CAVAILLON,LES VIGNERES,43.8508361826,5.03606300916,35,,Cavaillon,Cavaillon,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84036,CHATEAUNEUF DE GADAGNE,84470,CHATEAUNEUF DE GADAGNE,,43.9276331683,4.94259114535,36,,Châteauneuf-de-Gadagne,Châteauneuf-de-Gadagne,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84037,CHATEAUNEUF DU PAPE,84230,CHATEAUNEUF DU PAPE,,44.0595884031,4.82728435607,37,,Châteauneuf-du-Pape,Châteauneuf-du-Pape,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84038,CHEVAL BLANC,84460,CHEVAL BLANC,,43.7886782282,5.12410732071,38,,Cheval-Blanc,Cheval-Blanc,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84039,COURTHEZON,84350,COURTHEZON,,44.0865790966,4.88163342078,39,,Courthézon,Courthézon,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84040,CRESTET,84110,CRESTET,,44.2097519132,5.08597487017,40,,Crestet,Crestet,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84041,CRILLON LE BRAVE,84410,CRILLON LE BRAVE,,44.1180400609,5.15095331396,41,,Crillon-le-Brave,Crillon-le-Brave,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84042,CUCURON,84160,CUCURON,,43.778224355,5.44361580359,42,,Cucuron,Cucuron,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84043,ENTRAIGUES SUR LA SORGUE,84320,ENTRAIGUES SUR LA SORGUE,,43.9950173356,4.93295387179,43,,Entraigues-sur-la-Sorgue,Entraigues-sur-la-Sorgue,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84044,ENTRECHAUX,84340,ENTRECHAUX,,44.2224730765,5.13332490403,44,,Entrechaux,Entrechaux,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84045,FAUCON,84110,FAUCON,,44.2524220745,5.14218487887,45,,Faucon,Faucon,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84046,FLASSAN,84410,FLASSAN,,44.0985422058,5.26372029474,46,,Flassan,Flassan,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84047,GARGAS,84400,GARGAS,,43.8960653944,5.34362330109,47,,Gargas,Gargas,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84048,GIGNAC,84400,GIGNAC,,43.9285109892,5.52308716414,48,,Gignac,Gignac,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84049,GIGONDAS,84190,GIGONDAS,,44.1660983331,5.01231871802,49,,Gigondas,Gigondas,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84050,GORDES,84220,GORDES,,43.909019241,5.19801978618,50,,Gordes,Gordes,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84051,GOULT,84220,GOULT,,43.8672520591,5.24782759369,51,,Goult,Goult,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84052,GRAMBOIS,84240,GRAMBOIS,,43.7605598424,5.6040344355,52,,Grambois,Grambois,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84053,GRILLON,84600,GRILLON,,44.3945766896,4.93414256152,53,,Grillon,Grillon,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84054,L ISLE SUR LA SORGUE,84800,L ISLE SUR LA SORGUE,,43.913397592,5.05664277545,54,L',Isle-sur-la-Sorgue,L'Isle-sur-la-Sorgue,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84055,JONQUERETTES,84450,JONQUERETTES,,43.9491839695,4.94413967569,55,,Jonquerettes,Jonquerettes,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84056,JONQUIERES,84150,JONQUIERES,,44.1318029558,4.91341542999,56,,Jonquières,Jonquières,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84057,JOUCAS,84220,JOUCAS,,43.9263664747,5.25260939996,57,,Joucas,Joucas,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84058,LACOSTE,84480,LACOSTE,,43.8257421042,5.26592271907,58,,Lacoste,Lacoste,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84059,LAFARE,84190,LAFARE,,44.1546199628,5.04972814303,59,,Lafare,Lafare,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84060,LAGARDE D APT,84400,LAGARDE D APT,,43.9838729786,5.48164417071,60,,Lagarde-d'Apt,Lagarde-d'Apt,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84061,LAGARDE PAREOL,84290,LAGARDE PAREOL,,44.2272154512,4.85109427827,61,,Lagarde-Paréol,Lagarde-Paréol,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84062,LAGNES,84800,LAGNES,,43.8924876869,5.11458669428,62,,Lagnes,Lagnes,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84063,LAMOTTE DU RHONE,84840,LAMOTTE DU RHONE,,44.2761614284,4.67510511716,63,,Lamotte-du-Rhône,Lamotte-du-Rhône,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84064,LAPALUD,84840,LAPALUD,,44.3065428944,4.67951575962,64,,Lapalud,Lapalud,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84065,LAURIS,84360,LAURIS,,43.7630365491,5.30333693211,65,,Lauris,Lauris,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84066,LIOUX,84220,LIOUX,,43.9793335094,5.30939885306,66,,Lioux,Lioux,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84067,LORIOL DU COMTAT,84870,LORIOL DU COMTAT,,44.0753500101,5.00514799731,67,,Loriol-du-Comtat,Loriol-du-Comtat,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84068,LOURMARIN,84160,LOURMARIN,,43.7780333839,5.36616174066,68,,Lourmarin,Lourmarin,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84069,MALAUCENE,84340,MALAUCENE,,44.1884482159,5.14985080872,69,,Malaucène,Malaucène,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84070,MALEMORT DU COMTAT,84570,MALEMORT DU COMTAT,,44.0188189905,5.16741843804,70,,Malemort-du-Comtat,Malemort-du-Comtat,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84071,MAUBEC,84660,MAUBEC,,43.8417800592,5.13767903423,71,,Maubec,Maubec,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84072,MAZAN,84380,MAZAN,,44.0545320098,5.13048600927,72,,Mazan,Mazan,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84073,MENERBES,84560,MENERBES,,43.8243710342,5.22087170856,73,,Ménerbes,Ménerbes,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84074,MERINDOL,84360,MERINDOL,,43.7619243813,5.20153482914,74,,Mérindol,Mérindol,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84075,METHAMIS,84570,METHAMIS,,44.0126367285,5.25299930239,75,,Méthamis,Méthamis,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84076,MIRABEAU,84120,MIRABEAU,,43.7048655954,5.64268969136,76,,Mirabeau,Mirabeau,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84077,MODENE,84330,MODENE,,44.1057811873,5.12787069214,77,,Modène,Modène,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84078,MONDRAGON,84430,MONDRAGON,,44.2429843935,4.72457377095,78,,Mondragon,Mondragon,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84079,MONIEUX,84390,MONIEUX,,44.0568668171,5.34149223081,79,,Monieux,Monieux,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84080,MONTEUX,84170,MONTEUX,,44.0335256003,4.9847476645,80,,Monteux,Monteux,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84081,MORIERES LES AVIGNON,84310,MORIERES LES AVIGNON,,43.9337788848,4.90875878315,81,,Morières-lès-Avignon,Morières-lès-Avignon,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84082,MORMOIRON,84570,MORMOIRON,,44.0700058452,5.19092701239,82,,Mormoiron,Mormoiron,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84083,MORNAS,84550,MORNAS,,44.2037476388,4.74014549297,83,,Mornas,Mornas,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84084,LA MOTTE D AIGUES,84240,LA MOTTE D AIGUES,,43.7840840338,5.52149188026,84,La,Motte-d'Aigues,La Motte-d'Aigues,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84085,MURS,84220,MURS,,43.9647645787,5.24369462064,85,,Murs,Murs,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84086,OPPEDE,84580,OPPEDE,,43.8313175338,5.16982150363,86,,Oppède,Oppède,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84087,ORANGE,84100,ORANGE,,44.128913537,4.8098792403,87,,Orange,Orange,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84088,PERNES LES FONTAINES,84210,PERNES LES FONTAINES,,43.9960550539,5.03959100234,88,,Pernes-les-Fontaines,Pernes-les-Fontaines,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84089,PERTUIS,84120,PERTUIS,,43.6872357464,5.52052480281,89,,Pertuis,Pertuis,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84090,PEYPIN D AIGUES,84240,PEYPIN D AIGUES,,43.7964408305,5.56003326924,90,,Peypin-d'Aigues,Peypin-d'Aigues,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84091,PIOLENC,84420,PIOLENC,,44.1774697051,4.76585289056,91,,Piolenc,Piolenc,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84092,LE PONTET,84130,LE PONTET,,43.9683903531,4.86549590773,92,Le,Pontet,Le Pontet,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84093,PUGET,84360,PUGET,,43.7695336639,5.2569973443,93,,Puget,Puget,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84094,PUYMERAS,84110,PUYMERAS,,44.2849517081,5.13590933781,94,,Puyméras,Puyméras,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84095,PUYVERT,84160,PUYVERT,,43.7501902846,5.33808223228,95,,Puyvert,Puyvert,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84096,RASTEAU,84110,RASTEAU,,44.2322859605,4.98047268222,96,,Rasteau,Rasteau,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84097,RICHERENCHES,84600,RICHERENCHES,,44.3558133128,4.90386489809,97,,Richerenches,Richerenches,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84098,ROAIX,84110,ROAIX,,44.2551304462,5.01515147311,98,,Roaix,Roaix,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84099,ROBION,84440,ROBION,,43.8519678718,5.11008684319,99,,Robion,Robion,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84100,LA ROQUE ALRIC,84190,LA ROQUE ALRIC,,44.1448441393,5.06883398959,100,La,Roque-Alric,La Roque-Alric,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84101,LA ROQUE SUR PERNES,84210,LA ROQUE SUR PERNES,,43.9741947109,5.10175518845,101,La,Roque-sur-Pernes,La Roque-sur-Pernes,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84102,ROUSSILLON,84220,ROUSSILLON,,43.9012816878,5.29201885718,102,,Roussillon,Roussillon,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84103,RUSTREL,84400,RUSTREL,,43.9299576961,5.47580805616,103,,Rustrel,Rustrel,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84104,SABLET,84110,SABLET,,44.1947680688,5.00118216056,104,,Sablet,Sablet,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84105,SAIGNON,84400,SAIGNON,,43.8604088316,5.43831613647,105,,Saignon,Saignon,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84106,STE CECILE LES VIGNES,84290,STE CECILE LES VIGNES,,44.2358558507,4.88928275558,106,,Sainte-Cécile-les-Vignes,Sainte-Cécile-les-Vignes,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84107,ST CHRISTOL,84390,ST CHRISTOL,,44.0312802167,5.50324881102,107,,Saint-Christol,Saint-Christol,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84108,ST DIDIER,84210,ST DIDIER,,44.0092549793,5.10865247521,108,,Saint-Didier,Saint-Didier,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84109,ST HIPPOLYTE LE GRAVEYRON,84330,ST HIPPOLYTE LE GRAVEYRON,,44.1203739984,5.07610929797,109,,Saint-Hippolyte-le-Graveyron,Saint-Hippolyte-le-Graveyron,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84110,ST LEGER DU VENTOUX,84390,ST LEGER DU VENTOUX,,44.2059476763,5.27072625011,110,,Saint-Léger-du-Ventoux,Saint-Léger-du-Ventoux,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84111,ST MARCELLIN LES VAISON,84110,ST MARCELLIN LES VAISON,,44.2371484247,5.10190359879,111,,Saint-Marcellin-lès-Vaison,Saint-Marcellin-lès-Vaison,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84112,ST MARTIN DE CASTILLON,84750,ST MARTIN DE CASTILLON,,43.8551072159,5.51919312356,112,,Saint-Martin-de-Castillon,Saint-Martin-de-Castillon,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84113,ST MARTIN DE LA BRASQUE,84760,ST MARTIN DE LA BRASQUE,,43.7640135964,5.53975821596,113,,Saint-Martin-de-la-Brasque,Saint-Martin-de-la-Brasque,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84114,ST PANTALEON,84220,ST PANTALEON,,43.8778804064,5.21653289666,114,,Saint-Pantaléon,Saint-Pantaléon,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84115,ST PIERRE DE VASSOLS,84330,ST PIERRE DE VASSOLS,,44.0949073027,5.14436401724,115,,Saint-Pierre-de-Vassols,Saint-Pierre-de-Vassols,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84116,ST ROMAIN EN VIENNOIS,84110,ST ROMAIN EN VIENNOIS,,44.2618277208,5.10165030898,116,,Saint-Romain-en-Viennois,Saint-Romain-en-Viennois,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84117,ST ROMAN DE MALEGARDE,84290,ST ROMAN DE MALEGARDE,,44.2632239809,4.95674983862,117,,Saint-Roman-de-Malegarde,Saint-Roman-de-Malegarde,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84118,ST SATURNIN LES APT,84490,ST SATURNIN LES APT,,43.9587368913,5.37325716104,118,,Saint-Saturnin-lès-Apt,Saint-Saturnin-lès-Apt,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84119,ST SATURNIN LES AVIGNON,84450,ST SATURNIN LES AVIGNON,,43.9621529369,4.94109967241,119,,Saint-Saturnin-lès-Avignon,Saint-Saturnin-lès-Avignon,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84120,ST TRINIT,84390,ST TRINIT,,44.0960361919,5.4788587888,120,,Saint-Trinit,Saint-Trinit,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84121,SANNES,84240,SANNES,,43.7566978031,5.48858992739,121,,Sannes,Sannes,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84122,SARRIANS,84260,SARRIANS,,44.0993149114,4.95726948743,122,,Sarrians,Sarrians,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84123,SAULT,84390,SAULT,,44.0729876027,5.39889956617,123,,Sault,Sault,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84124,SAUMANE DE VAUCLUSE,84800,SAUMANE DE VAUCLUSE,,43.9380357519,5.11208499768,124,,Saumane-de-Vaucluse,Saumane-de-Vaucluse,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84125,SAVOILLAN,84390,SAVOILLAN,,44.1744446408,5.37283802798,125,,Savoillan,Savoillan,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84126,SEGURET,84110,SEGURET,,44.2159640237,5.02881720149,126,,Séguret,Séguret,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84127,SERIGNAN DU COMTAT,84830,SERIGNAN DU COMTAT,,44.1939012207,4.84785727207,127,,Sérignan-du-Comtat,Sérignan-du-Comtat,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84128,SIVERGUES,84400,SIVERGUES,,43.8229667306,5.41627509912,128,,Sivergues,Sivergues,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84129,SORGUES,84700,SORGUES,,44.0145763951,4.86740515419,129,,Sorgues,Sorgues,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84130,SUZETTE,84190,SUZETTE,,44.1717196495,5.06194089513,130,,Suzette,Suzette,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84131,TAILLADES,84300,TAILLADES,,43.8315474513,5.09480690548,131,,Taillades,Taillades,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84132,LE THOR,84250,LE THOR,,43.928239832,4.99324123379,132,Le,Thor,Le Thor,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84133,LA TOUR D AIGUES,84240,LA TOUR D AIGUES,,43.7214835187,5.56734664063,133,La,Tour-d'Aigues,La Tour-d'Aigues,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84134,TRAVAILLAN,84850,TRAVAILLAN,,44.1894500119,4.90856149407,134,,Travaillan,Travaillan,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84135,UCHAUX,84100,UCHAUX,,44.2160888468,4.80180924895,135,,Uchaux,Uchaux,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84136,VACQUEYRAS,84190,VACQUEYRAS,,44.1452203037,4.9790786368,136,,Vacqueyras,Vacqueyras,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84137,VAISON LA ROMAINE,84110,VAISON LA ROMAINE,,44.247701867,5.06040782309,137,,Vaison-la-Romaine,Vaison-la-Romaine,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84138,VALREAS,84600,VALREAS,,44.3771588623,4.99354936197,138,,Valréas,Valréas,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84139,FONTAINE DE VAUCLUSE,84800,FONTAINE DE VAUCLUSE,,43.9220820962,5.13125424344,139,,Fontaine-de-Vaucluse,Fontaine-de-Vaucluse,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84140,VAUGINES,84160,VAUGINES,,43.7786185321,5.40667854286,140,,Vaugines,Vaugines,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84141,VEDENE,84270,VEDENE,,43.9708612885,4.90327834789,141,,Vedène,Vedène,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84142,VELLERON,84740,VELLERON,,43.9615700853,5.02837053925,142,,Velleron,Velleron,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84143,VENASQUE,84210,VENASQUE,,43.9851237661,5.16506865588,143,,Venasque,Venasque,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84144,VIENS,84750,VIENS,,43.9031226397,5.5602158314,144,,Viens,Viens,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84145,VILLARS,84400,VILLARS,,43.9519813835,5.43334418436,145,,Villars,Villars,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84146,VILLEDIEU,84110,VILLEDIEU,,44.2885345568,5.04784441518,146,,Villedieu,Villedieu,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84147,VILLELAURE,84530,VILLELAURE,,43.7075157136,5.43085449725,147,,Villelaure,Villelaure,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84148,VILLES SUR AUZON,84570,VILLES SUR AUZON,,44.0660605145,5.25707803023,148,,Villes-sur-Auzon,Villes-sur-Auzon,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84149,VIOLES,84150,VIOLES,,44.1767100202,4.95138229619,149,,Violès,Violès,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84150,VISAN,84820,VISAN,,44.3268006533,4.94430178075,150,,Visan,Visan,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
84151,VITROLLES EN LUBERON,84240,VITROLLES EN LUBERON,,43.8137920989,5.59899946378,151,,Vitrolles-en-Lubéron,Vitrolles-en-Lubéron,84,Vaucluse,93,Provence-Alpes-Côte d'Azur
85001,L AIGUILLON SUR MER,85460,L AIGUILLON SUR MER,,46.304138479,-1.2623239198,1,L',Aiguillon-sur-Mer,L'Aiguillon-sur-Mer,85,Vendée,52,Pays de la Loire
85002,L AIGUILLON SUR VIE,85220,L AIGUILLON SUR VIE,,46.6706426618,-1.82599992318,2,L',Aiguillon-sur-Vie,L'Aiguillon-sur-Vie,85,Vendée,52,Pays de la Loire
85003,AIZENAY,85190,AIZENAY,,46.7384516809,-1.62702889721,3,,Aizenay,Aizenay,85,Vendée,52,Pays de la Loire
85004,ANGLES,85750,ANGLES,,46.3870511077,-1.40049386944,4,,Angles,Angles,85,Vendée,52,Pays de la Loire
85005,ANTIGNY,85120,ANTIGNY,,46.6191770822,-0.767030058653,5,,Antigny,Antigny,85,Vendée,52,Pays de la Loire
85006,APREMONT,85220,APREMONT,,46.7572682339,-1.74841313647,6,,Apremont,Apremont,85,Vendée,52,Pays de la Loire
85008,AUBIGNY LES CLOUZEAUX,85430,AUBIGNY LES CLOUZEAUX,,46.6028241769,-1.46743549114,8,,Aubigny-Les Clouzeaux,Aubigny-Les Clouzeaux,85,Vendée,52,Pays de la Loire
85008,AUBIGNY LES CLOUZEAUX,85430,AUBIGNY LES CLOUZEAUX,LES CLOUZEAUX,46.6028241769,-1.46743549114,8,,Aubigny-Les Clouzeaux,Aubigny-Les Clouzeaux,85,Vendée,52,Pays de la Loire
85009,AUZAY,85200,AUZAY,,46.4474386161,-0.876574265149,9,,Auchay-sur-Vendée,Auchay-sur-Vendée,85,Vendée,52,Pays de la Loire
85010,AVRILLE,85440,AVRILLE,,46.4744272125,-1.49524360118,10,,Avrillé,Avrillé,85,Vendée,52,Pays de la Loire
85011,BARBATRE,85630,BARBATRE,,46.9335754783,-2.16743559847,11,,Barbâtre,Barbâtre,85,Vendée,52,Pays de la Loire
85012,LA BARRE DE MONTS,85550,LA BARRE DE MONTS,,46.8722784154,-2.09984018879,12,La,Barre-de-Monts,La Barre-de-Monts,85,Vendée,52,Pays de la Loire
85012,LA BARRE DE MONTS,85550,LA BARRE DE MONTS,FROMENTINE,46.8722784154,-2.09984018879,12,La,Barre-de-Monts,La Barre-de-Monts,85,Vendée,52,Pays de la Loire
85013,BAZOGES EN PAILLERS,85130,BAZOGES EN PAILLERS,,46.9213757643,-1.14415666313,13,,Bazoges-en-Paillers,Bazoges-en-Paillers,85,Vendée,52,Pays de la Loire
85014,BAZOGES EN PAREDS,85390,BAZOGES EN PAREDS,,46.6602005512,-0.914053446792,14,,Bazoges-en-Pareds,Bazoges-en-Pareds,85,Vendée,52,Pays de la Loire
85015,BEAUFOU,85170,BEAUFOU,,46.8191122027,-1.52479250801,15,,Beaufou,Beaufou,85,Vendée,52,Pays de la Loire
85016,BEAULIEU SOUS LA ROCHE,85190,BEAULIEU SOUS LA ROCHE,,46.6872087211,-1.62355064963,16,,Beaulieu-sous-la-Roche,Beaulieu-sous-la-Roche,85,Vendée,52,Pays de la Loire
85017,BEAUREPAIRE,85500,BEAUREPAIRE,,46.9050210355,-1.10144867013,17,,Beaurepaire,Beaurepaire,85,Vendée,52,Pays de la Loire
85018,BEAUVOIR SUR MER,85230,BEAUVOIR SUR MER,,46.9086155426,-2.06349351302,18,,Beauvoir-sur-Mer,Beauvoir-sur-Mer,85,Vendée,52,Pays de la Loire
85019,BELLEVIGNY,85170,BELLEVIGNY,BELLEVILLE SUR VIE,46.7756383534,-1.43313700054,19,,Bellevigny,Bellevigny,85,Vendée,52,Pays de la Loire
85019,BELLEVIGNY,85170,BELLEVIGNY,SALIGNY,46.7756383534,-1.43313700054,19,,Bellevigny,Bellevigny,85,Vendée,52,Pays de la Loire
85020,BENET,85490,BENET,,46.368873213,-0.613959918706,20,,Benet,Benet,85,Vendée,52,Pays de la Loire
85020,BENET,85490,BENET,LESSON,46.368873213,-0.613959918706,20,,Benet,Benet,85,Vendée,52,Pays de la Loire
85020,BENET,85490,BENET,STE CHRISTINE,46.368873213,-0.613959918706,20,,Benet,Benet,85,Vendée,52,Pays de la Loire
85021,LA BERNARDIERE,85610,LA BERNARDIERE,,47.0361828072,-1.27390355206,21,La,Bernardière,La Bernardière,85,Vendée,52,Pays de la Loire
85022,LE BERNARD,85560,LE BERNARD,,46.44832528,-1.43979865314,22,Le,Bernard,Le Bernard,85,Vendée,52,Pays de la Loire
85023,BESSAY,85320,BESSAY,,46.5397253861,-1.17028433093,23,,Bessay,Bessay,85,Vendée,52,Pays de la Loire
85024,BOIS DE CENE,85710,BOIS DE CENE,,46.9479643351,-1.89668693466,24,,Bois-de-Céné,Bois-de-Céné,85,Vendée,52,Pays de la Loire
85025,LA BOISSIERE DE MONTAIGU,85600,LA BOISSIERE DE MONTAIGU,,46.9451858636,-1.1916392484,25,La,Boissière-de-Montaigu,La Boissière-de-Montaigu,85,Vendée,52,Pays de la Loire
85026,LA BOISSIERE DES LANDES,85430,LA BOISSIERE DES LANDES,,46.5581861734,-1.44371985689,26,La,Boissière-des-Landes,La Boissière-des-Landes,85,Vendée,52,Pays de la Loire
85027,BOUFFERE,85600,BOUFFERE,,46.9581306722,-1.34274800322,27,,Boufféré,Boufféré,85,Vendée,52,Pays de la Loire
85028,BOUILLE COURDAULT,85420,BOUILLE COURDAULT,,46.3847932448,-0.684917815779,28,,Bouillé-Courdault,Bouillé-Courdault,85,Vendée,52,Pays de la Loire
85029,BOUIN,85230,BOUIN,,46.9815930867,-2.00423808381,29,,Bouin,Bouin,85,Vendée,52,Pays de la Loire
85031,LE BOUPERE,85510,LE BOUPERE,,46.7877960262,-0.930897406714,31,Le,Boupère,Le Boupère,85,Vendée,52,Pays de la Loire
85033,BOURNEAU,85200,BOURNEAU,,46.5476882922,-0.813838020265,33,,Bourneau,Bourneau,85,Vendée,52,Pays de la Loire
85034,BOURNEZEAU,85480,BOURNEZEAU,,46.6296975315,-1.14101742229,34,,Bournezeau,Bournezeau,85,Vendée,52,Pays de la Loire
85034,BOURNEZEAU,85480,BOURNEZEAU,ST VINCENT PUYMAUFRAIS,46.6296975315,-1.14101742229,34,,Bournezeau,Bournezeau,85,Vendée,52,Pays de la Loire
85035,BRETIGNOLLES SUR MER,85470,BRETIGNOLLES SUR MER,,46.6374826705,-1.86324200464,35,,Bretignolles-sur-Mer,Bretignolles-sur-Mer,85,Vendée,52,Pays de la Loire
85036,LA BRETONNIERE LA CLAYE,85320,LA BRETONNIERE LA CLAYE,,46.4879459421,-1.26773426545,36,La,Bretonnière-la-Claye,La Bretonnière-la-Claye,85,Vendée,52,Pays de la Loire
85036,LA BRETONNIERE LA CLAYE,85320,LA BRETONNIERE LA CLAYE,LA CLAYE,46.4879459421,-1.26773426545,36,La,Bretonnière-la-Claye,La Bretonnière-la-Claye,85,Vendée,52,Pays de la Loire
85037,BREUIL BARRET,85120,BREUIL BARRET,,46.6503266235,-0.671948654426,37,,Breuil-Barret,Breuil-Barret,85,Vendée,52,Pays de la Loire
85038,LES BROUZILS,85260,LES BROUZILS,,46.8854235235,-1.33186892233,38,Les,Brouzils,Les Brouzils,85,Vendée,52,Pays de la Loire
85039,LA BRUFFIERE,85530,LA BRUFFIERE,,47.0148006973,-1.18329758318,39,La,Bruffière,La Bruffière,85,Vendée,52,Pays de la Loire
85040,LA CAILLERE ST HILAIRE,85410,LA CAILLERE ST HILAIRE,,46.6293907412,-0.933153931505,40,La,Caillère-Saint-Hilaire,La Caillère-Saint-Hilaire,85,Vendée,52,Pays de la Loire
85040,LA CAILLERE ST HILAIRE,85410,LA CAILLERE ST HILAIRE,ST HILAIRE DU BOIS,46.6293907412,-0.933153931505,40,La,Caillère-Saint-Hilaire,La Caillère-Saint-Hilaire,85,Vendée,52,Pays de la Loire
85041,CEZAIS,85410,CEZAIS,,46.5917363748,-0.802618133558,41,,Cezais,Cezais,85,Vendée,52,Pays de la Loire
85042,CHAILLE LES MARAIS,85450,CHAILLE LES MARAIS,,46.3853555319,-1.01044079362,42,,Chaillé-les-Marais,Chaillé-les-Marais,85,Vendée,52,Pays de la Loire
85042,CHAILLE LES MARAIS,85450,CHAILLE LES MARAIS,AISNE,46.3853555319,-1.01044079362,42,,Chaillé-les-Marais,Chaillé-les-Marais,85,Vendée,52,Pays de la Loire
85042,CHAILLE LES MARAIS,85450,CHAILLE LES MARAIS,SABLEAU,46.3853555319,-1.01044079362,42,,Chaillé-les-Marais,Chaillé-les-Marais,85,Vendée,52,Pays de la Loire
85044,CHAIX,85200,CHAIX,,46.42318752,-0.856783062758,44,,Chaix,Chaix,85,Vendée,52,Pays de la Loire
85045,LA CHAIZE GIRAUD,85220,LA CHAIZE GIRAUD,,46.6476375058,-1.81865076161,45,La,Chaize-Giraud,La Chaize-Giraud,85,Vendée,52,Pays de la Loire
85046,LA CHAIZE LE VICOMTE,85310,LA CHAIZE LE VICOMTE,,46.6729533879,-1.29188591019,46,La,Chaize-le-Vicomte,La Chaize-le-Vicomte,85,Vendée,52,Pays de la Loire
85046,LA CHAIZE LE VICOMTE,85310,LA CHAIZE LE VICOMTE,LA LIMOUZINIERE,46.6729533879,-1.29188591019,46,La,Chaize-le-Vicomte,La Chaize-le-Vicomte,85,Vendée,52,Pays de la Loire
85047,CHALLANS,85300,CHALLANS,,46.8354416653,-1.84036683139,47,,Challans,Challans,85,Vendée,52,Pays de la Loire
85048,CHAMBRETAUD,85500,CHAMBRETAUD,,46.9169852414,-0.97455517343,48,,Chambretaud,Chambretaud,85,Vendée,52,Pays de la Loire
85049,CHAMPAGNE LES MARAIS,85450,CHAMPAGNE LES MARAIS,,46.3735020647,-1.13380723653,49,,Champagné-les-Marais,Champagné-les-Marais,85,Vendée,52,Pays de la Loire
85050,LE CHAMP ST PERE,85540,LE CHAMP ST PERE,,46.5157210212,-1.33946630196,50,Le,Champ-Saint-Père,Le Champ-Saint-Père,85,Vendée,52,Pays de la Loire
85051,CHANTONNAY,85110,CHANTONNAY,,46.6690167793,-1.04372588019,51,,Chantonnay,Chantonnay,85,Vendée,52,Pays de la Loire
85051,CHANTONNAY,85110,CHANTONNAY,ST PHILBERT DU PONT CHARRAULT,46.6690167793,-1.04372588019,51,,Chantonnay,Chantonnay,85,Vendée,52,Pays de la Loire
85052,LA CHAPELLE ACHARD,85150,LA CHAPELLE ACHARD,,46.5869841761,-1.66144398646,52,La,Chapelle-Achard,La Chapelle-Achard,85,Vendée,52,Pays de la Loire
85053,LA CHAPELLE AUX LYS,85120,LA CHAPELLE AUX LYS,,46.6221916887,-0.642706103195,53,La,Chapelle-aux-Lys,La Chapelle-aux-Lys,85,Vendée,52,Pays de la Loire
85054,LA CHAPELLE HERMIER,85220,LA CHAPELLE HERMIER,,46.6826679204,-1.72083372442,54,La,Chapelle-Hermier,La Chapelle-Hermier,85,Vendée,52,Pays de la Loire
85055,LA CHAPELLE PALLUAU,85670,LA CHAPELLE PALLUAU,,46.7873638997,-1.62492863273,55,La,Chapelle-Palluau,La Chapelle-Palluau,85,Vendée,52,Pays de la Loire
85056,LA CHAPELLE THEMER,85210,LA CHAPELLE THEMER,,46.5639307793,-0.960376685588,56,La,Chapelle-Thémer,La Chapelle-Thémer,85,Vendée,52,Pays de la Loire
85058,CHASNAIS,85400,CHASNAIS,,46.4459908481,-1.2385924923,58,,Chasnais,Chasnais,85,Vendée,52,Pays de la Loire
85059,LA CHATAIGNERAIE,85120,LA CHATAIGNERAIE,,46.6416143401,-0.739561966419,59,La,Châtaigneraie,La Châtaigneraie,85,Vendée,52,Pays de la Loire
85060,CHATEAU D OLONNE,85180,CHATEAU D OLONNE,,46.4925968813,-1.72127222048,60,,Château-d'Olonne,Château-d'Olonne,85,Vendée,52,Pays de la Loire
85061,CHATEAU GUIBERT,85320,CHATEAU GUIBERT,,46.5741109302,-1.25524886228,61,,Château-Guibert,Château-Guibert,85,Vendée,52,Pays de la Loire
85062,CHATEAUNEUF,85710,CHATEAUNEUF,,46.916944435,-1.9261131832,62,,Châteauneuf,Châteauneuf,85,Vendée,52,Pays de la Loire
85064,CHAUCHE,85140,CHAUCHE,,46.8282791899,-1.27090860656,64,,Chauché,Chauché,85,Vendée,52,Pays de la Loire
85065,CHAVAGNES EN PAILLERS,85250,CHAVAGNES EN PAILLERS,,46.8951394423,-1.24054768713,65,,Chavagnes-en-Paillers,Chavagnes-en-Paillers,85,Vendée,52,Pays de la Loire
85066,CHAVAGNES LES REDOUX,85390,CHAVAGNES LES REDOUX,,46.7101499475,-0.915900131393,66,,Chavagnes-les-Redoux,Chavagnes-les-Redoux,85,Vendée,52,Pays de la Loire
85067,CHEFFOIS,85390,CHEFFOIS,,46.6786935635,-0.782949851125,67,,Cheffois,Cheffois,85,Vendée,52,Pays de la Loire
85070,COEX,85220,COEX,,46.7078707764,-1.75788339462,70,,Coëx,Coëx,85,Vendée,52,Pays de la Loire
85071,COMMEQUIERS,85220,COMMEQUIERS,,46.7674752232,-1.82534079642,71,,Commequiers,Commequiers,85,Vendée,52,Pays de la Loire
85072,LA COPECHAGNIERE,85260,LA COPECHAGNIERE,,46.8523980181,-1.34349746898,72,La,Copechagnière,La Copechagnière,85,Vendée,52,Pays de la Loire
85073,CORPE,85320,CORPE,,46.5050234767,-1.17034425311,73,,Corpe,Corpe,85,Vendée,52,Pays de la Loire
85074,LA COUTURE,85320,LA COUTURE,,46.523938732,-1.26493227292,74,La,Couture,La Couture,85,Vendée,52,Pays de la Loire
85076,CUGAND,85610,CUGAND,,47.0602388146,-1.25289811103,76,,Cugand,Cugand,85,Vendée,52,Pays de la Loire
85077,CURZON,85540,CURZON,,46.4512376923,-1.30138059216,77,,Curzon,Curzon,85,Vendée,52,Pays de la Loire
85078,DAMVIX,85420,DAMVIX,,46.32063079,-0.743504259797,78,,Damvix,Damvix,85,Vendée,52,Pays de la Loire
85080,DOIX LES FONTAINES,85200,DOIX LES FONTAINES,,46.3849492327,-0.806840287485,80,,Doix lès Fontaines,Doix lès Fontaines,85,Vendée,52,Pays de la Loire
85080,DOIX LES FONTAINES,85200,DOIX LES FONTAINES,FONTAINES,46.3849492327,-0.806840287485,80,,Doix lès Fontaines,Doix lès Fontaines,85,Vendée,52,Pays de la Loire
85081,DOMPIERRE SUR YON,85170,DOMPIERRE SUR YON,,46.7599858068,-1.37275519417,81,,Dompierre-sur-Yon,Dompierre-sur-Yon,85,Vendée,52,Pays de la Loire
85082,LES EPESSES,85590,LES EPESSES,,46.8917166066,-0.903422756546,82,Les,Epesses,Les Epesses,85,Vendée,52,Pays de la Loire
85083,L EPINE,85740,L EPINE,,46.9843405667,-2.26449527608,83,L',Épine,L'Épine,85,Vendée,52,Pays de la Loire
85084,ESSARTS EN BOCAGE,85140,ESSARTS EN BOCAGE,BOULOGNE,46.7806739038,-1.22925967851,84,,Essarts en Bocage,Essarts en Bocage,85,Vendée,52,Pays de la Loire
85084,ESSARTS EN BOCAGE,85140,ESSARTS EN BOCAGE,LES ESSARTS,46.7806739038,-1.22925967851,84,,Essarts en Bocage,Essarts en Bocage,85,Vendée,52,Pays de la Loire
85084,ESSARTS EN BOCAGE,85140,ESSARTS EN BOCAGE,L OIE,46.7806739038,-1.22925967851,84,,Essarts en Bocage,Essarts en Bocage,85,Vendée,52,Pays de la Loire
85084,ESSARTS EN BOCAGE,85140,ESSARTS EN BOCAGE,STE FLORENCE,46.7806739038,-1.22925967851,84,,Essarts en Bocage,Essarts en Bocage,85,Vendée,52,Pays de la Loire
85086,FALLERON,85670,FALLERON,,46.8623928354,-1.70108938038,86,,Falleron,Falleron,85,Vendée,52,Pays de la Loire
85087,FAYMOREAU,85240,FAYMOREAU,,46.5427361252,-0.624271378946,87,,Faymoreau,Faymoreau,85,Vendée,52,Pays de la Loire
85088,LE FENOUILLER,85800,LE FENOUILLER,,46.7161264566,-1.89206667498,88,Le,Fenouiller,Le Fenouiller,85,Vendée,52,Pays de la Loire
85089,LA FERRIERE,85280,LA FERRIERE,,46.7215872927,-1.33469332327,89,La,Ferrière,La Ferrière,85,Vendée,52,Pays de la Loire
85090,SEVREMONT,85700,SEVREMONT,LA FLOCELLIERE,46.8211105864,-0.854584153953,90,,Sèvremont,Sèvremont,85,Vendée,52,Pays de la Loire
85090,SEVREMONT,85700,SEVREMONT,LA POMMERAIE SUR SEVRE,46.8211105864,-0.854584153953,90,,Sèvremont,Sèvremont,85,Vendée,52,Pays de la Loire
85090,SEVREMONT,85700,SEVREMONT,LES CHATELLIERS CHATEAUMUR,46.8211105864,-0.854584153953,90,,Sèvremont,Sèvremont,85,Vendée,52,Pays de la Loire
85090,SEVREMONT,85700,SEVREMONT,ST MICHEL MONT MERCURE,46.8211105864,-0.854584153953,90,,Sèvremont,Sèvremont,85,Vendée,52,Pays de la Loire
85092,FONTENAY LE COMTE,85200,FONTENAY LE COMTE,,46.4563186117,-0.793449510859,92,,Fontenay-le-Comte,Fontenay-le-Comte,85,Vendée,52,Pays de la Loire
85092,FONTENAY LE COMTE,85200,FONTENAY LE COMTE,ST MEDARD DES PRES,46.4563186117,-0.793449510859,92,,Fontenay-le-Comte,Fontenay-le-Comte,85,Vendée,52,Pays de la Loire
85093,FOUGERE,85480,FOUGERE,,46.6617881911,-1.23612691916,93,,Fougeré,Fougeré,85,Vendée,52,Pays de la Loire
85094,FOUSSAIS PAYRE,85240,FOUSSAIS PAYRE,,46.5230750581,-0.687135962627,94,,Foussais-Payré,Foussais-Payré,85,Vendée,52,Pays de la Loire
85095,FROIDFOND,85300,FROIDFOND,,46.8789464367,-1.75511438567,95,,Froidfond,Froidfond,85,Vendée,52,Pays de la Loire
85096,LA GARNACHE,85710,LA GARNACHE,,46.8977541296,-1.82443040539,96,La,Garnache,La Garnache,85,Vendée,52,Pays de la Loire
85097,LA GAUBRETIERE,85130,LA GAUBRETIERE,,46.9345007303,-1.05578200702,97,La,Gaubretière,La Gaubretière,85,Vendée,52,Pays de la Loire
85098,LA GENETOUZE,85190,LA GENETOUZE,,46.7244524541,-1.50410719693,98,La,Genétouze,La Genétouze,85,Vendée,52,Pays de la Loire
85099,LE GIROUARD,85150,LE GIROUARD,,46.5726064909,-1.58872487716,99,Le,Girouard,Le Girouard,85,Vendée,52,Pays de la Loire
85100,GIVRAND,85800,GIVRAND,,46.6822701061,-1.8787272243,100,,Givrand,Givrand,85,Vendée,52,Pays de la Loire
85101,LE GIVRE,85540,LE GIVRE,,46.4729146043,-1.40256149118,101,Le,Givre,Le Givre,85,Vendée,52,Pays de la Loire
85102,GRAND LANDES,85670,GRAND LANDES,,46.8483283063,-1.64453002578,102,,Grand'Landes,Grand'Landes,85,Vendée,52,Pays de la Loire
85103,GROSBREUIL,85440,GROSBREUIL,,46.5390090882,-1.6072005484,103,,Grosbreuil,Grosbreuil,85,Vendée,52,Pays de la Loire
85104,GRUES,85580,GRUES,,46.3813091348,-1.32364519268,104,,Grues,Grues,85,Vendée,52,Pays de la Loire
85105,LE GUE DE VELLUIRE,85770,LE GUE DE VELLUIRE,,46.3675950645,-0.905432724485,105,Le,Gué-de-Velluire,Le Gué-de-Velluire,85,Vendée,52,Pays de la Loire
85106,LA GUERINIERE,85680,LA GUERINIERE,,46.9669962053,-2.2302799245,106,La,Guérinière,La Guérinière,85,Vendée,52,Pays de la Loire
85107,LA GUYONNIERE,85600,LA GUYONNIERE,,46.9691458827,-1.25498183944,107,La,Guyonnière,La Guyonnière,85,Vendée,52,Pays de la Loire
85108,L HERBERGEMENT,85260,L HERBERGEMENT,,46.9166207979,-1.37033557148,108,L',Herbergement,L'Herbergement,85,Vendée,52,Pays de la Loire
85109,LES HERBIERS,85500,LES HERBIERS,,46.8666125813,-1.02216086186,109,Les,Herbiers,Les Herbiers,85,Vendée,52,Pays de la Loire
85110,L HERMENAULT,85570,L HERMENAULT,,46.5156262822,-0.898430664265,110,L',Hermenault,L'Hermenault,85,Vendée,52,Pays de la Loire
85111,L ILE D ELLE,85770,L ILE D ELLE,,46.3334258655,-0.919100677098,111,L',Île-d'Elle,L'Île-d'Elle,85,Vendée,52,Pays de la Loire
85112,L ILE D OLONNE,85340,L ILE D OLONNE,,46.570163703,-1.7737502368,112,L',Île-d'Olonne,L'Île-d'Olonne,85,Vendée,52,Pays de la Loire
85113,L ILE D YEU,85350,L ILE D YEU,,46.7093514816,-2.34712702345,113,L',Île-d'Yeu,L'Île-d'Yeu,85,Vendée,52,Pays de la Loire
85113,L ILE D YEU,85350,L ILE D YEU,PORT JOINVILLE,46.7093514816,-2.34712702345,113,L',Île-d'Yeu,L'Île-d'Yeu,85,Vendée,52,Pays de la Loire
85114,JARD SUR MER,85520,JARD SUR MER,,46.4246376808,-1.60014921643,114,,Jard-sur-Mer,Jard-sur-Mer,85,Vendée,52,Pays de la Loire
85115,LA JAUDONNIERE,85110,LA JAUDONNIERE,,46.6488761831,-0.962477574588,115,La,Jaudonnière,La Jaudonnière,85,Vendée,52,Pays de la Loire
85116,LA JONCHERE,85540,LA JONCHERE,,46.4358647401,-1.38517843312,116,La,Jonchère,La Jonchère,85,Vendée,52,Pays de la Loire
85117,LAIROUX,85400,LAIROUX,,46.4496842668,-1.27114022202,117,,Lairoux,Lairoux,85,Vendée,52,Pays de la Loire
85118,LANDERONDE,85150,LANDERONDE,,46.6549237031,-1.57351777893,118,,Landeronde,Landeronde,85,Vendée,52,Pays de la Loire
85119,LES LANDES GENUSSON,85130,LES LANDES GENUSSON,,46.9663828627,-1.12900644447,119,Les,Landes-Genusson,Les Landes-Genusson,85,Vendée,52,Pays de la Loire
85120,LANDEVIEILLE,85220,LANDEVIEILLE,,46.6444349925,-1.7854367847,120,,Landevieille,Landevieille,85,Vendée,52,Pays de la Loire
85121,LE LANGON,85370,LE LANGON,,46.4393119039,-0.947017086151,121,Le,Langon,Le Langon,85,Vendée,52,Pays de la Loire
85123,LIEZ,85420,LIEZ,,46.3698532376,-0.70502476758,123,,Liez,Liez,85,Vendée,52,Pays de la Loire
85125,LOGE FOUGEREUSE,85120,LOGE FOUGEREUSE,,46.6180854641,-0.6899276733,125,,Loge-Fougereuse,Loge-Fougereuse,85,Vendée,52,Pays de la Loire
85126,LONGEVES,85200,LONGEVES,,46.4722105292,-0.858917690239,126,,Longèves,Longèves,85,Vendée,52,Pays de la Loire
85127,LONGEVILLE SUR MER,85560,LONGEVILLE SUR MER,,46.4091029013,-1.47711855345,127,,Longeville-sur-Mer,Longeville-sur-Mer,85,Vendée,52,Pays de la Loire
85128,LUCON,85400,LUCON,,46.4510564854,-1.16449285012,128,,Luçon,Luçon,85,Vendée,52,Pays de la Loire
85129,LES LUCS SUR BOULOGNE,85170,LES LUCS SUR BOULOGNE,,46.8527299002,-1.48398928084,129,Les,Lucs-sur-Boulogne,Les Lucs-sur-Boulogne,85,Vendée,52,Pays de la Loire
85130,MACHE,85190,MACHE,,46.771364944,-1.69526445062,130,,Maché,Maché,85,Vendée,52,Pays de la Loire
85131,LES MAGNILS REIGNIERS,85400,LES MAGNILS REIGNIERS,,46.4635045649,-1.210635375,131,Les,Magnils-Reigniers,Les Magnils-Reigniers,85,Vendée,52,Pays de la Loire
85132,MAILLE,85420,MAILLE,,46.3417503082,-0.787487297301,132,,Maillé,Maillé,85,Vendée,52,Pays de la Loire
85133,MAILLEZAIS,85420,MAILLEZAIS,,46.3642178261,-0.750260780443,133,,Maillezais,Maillezais,85,Vendée,52,Pays de la Loire
85134,MALLIEVRE,85590,MALLIEVRE,,46.9112847287,-0.864977836096,134,,Mallièvre,Mallièvre,85,Vendée,52,Pays de la Loire
85135,MAREUIL SUR LAY DISSAIS,85320,MAREUIL SUR LAY DISSAIS,,46.5335825488,-1.22688907859,135,,Mareuil-sur-Lay-Dissais,Mareuil-sur-Lay-Dissais,85,Vendée,52,Pays de la Loire
85135,MAREUIL SUR LAY DISSAIS,85320,MAREUIL SUR LAY DISSAIS,DISSAIS,46.5335825488,-1.22688907859,135,,Mareuil-sur-Lay-Dissais,Mareuil-sur-Lay-Dissais,85,Vendée,52,Pays de la Loire
85136,MARILLET,85240,MARILLET,,46.5667525381,-0.634287713939,136,,Marillet,Marillet,85,Vendée,52,Pays de la Loire
85137,MARSAIS STE RADEGONDE,85570,MARSAIS STE RADEGONDE,,46.5380790745,-0.868868309437,137,,Marsais-Sainte-Radégonde,Marsais-Sainte-Radégonde,85,Vendée,52,Pays de la Loire
85138,MARTINET,85150,MARTINET,,46.6620680463,-1.6772013304,138,,Martinet,Martinet,85,Vendée,52,Pays de la Loire
85139,LE MAZEAU,85420,LE MAZEAU,,46.3298580373,-0.672957405035,139,Le,Mazeau,Le Mazeau,85,Vendée,52,Pays de la Loire
85140,LA MEILLERAIE TILLAY,85700,LA MEILLERAIE TILLAY,,46.742582825,-0.85478763606,140,La,Meilleraie-Tillay,La Meilleraie-Tillay,85,Vendée,52,Pays de la Loire
85141,MENOMBLET,85700,MENOMBLET,,46.7301338667,-0.728654955878,141,,Menomblet,Menomblet,85,Vendée,52,Pays de la Loire
85142,LA MERLATIERE,85140,LA MERLATIERE,,46.7557703112,-1.29794577,142,La,Merlatière,La Merlatière,85,Vendée,52,Pays de la Loire
85143,MERVENT,85200,MERVENT,,46.5325327351,-0.748519927998,143,,Mervent,Mervent,85,Vendée,52,Pays de la Loire
85144,MESNARD LA BAROTIERE,85500,MESNARD LA BAROTIERE,,46.851716793,-1.10954466033,144,,Mesnard-la-Barotière,Mesnard-la-Barotière,85,Vendée,52,Pays de la Loire
85145,MONSIREIGNE,85110,MONSIREIGNE,,46.7383480028,-0.931953130855,145,,Monsireigne,Monsireigne,85,Vendée,52,Pays de la Loire
85146,MONTAIGU,85600,MONTAIGU,,46.9759800852,-1.31364530268,146,,Montaigu,Montaigu,85,Vendée,52,Pays de la Loire
85147,MONTOURNAIS,85700,MONTOURNAIS,,46.7502937556,-0.770013941158,147,,Montournais,Montournais,85,Vendée,52,Pays de la Loire
85148,MONTREUIL,85200,MONTREUIL,,46.3973419593,-0.840846860992,148,,Montreuil,Montreuil,85,Vendée,52,Pays de la Loire
85149,MOREILLES,85450,MOREILLES,,46.4218721314,-1.09404530407,149,,Moreilles,Moreilles,85,Vendée,52,Pays de la Loire
85151,MORTAGNE SUR SEVRE,85290,MORTAGNE SUR SEVRE,,46.9910941319,-0.946500033344,151,,Mortagne-sur-Sèvre,Mortagne-sur-Sèvre,85,Vendée,52,Pays de la Loire
85152,LA MOTHE ACHARD,85150,LA MOTHE ACHARD,,46.6163645636,-1.65038156849,152,Les,Achards,Les Achards,85,Vendée,52,Pays de la Loire
85153,MOUCHAMPS,85640,MOUCHAMPS,,46.7870550926,-1.05454102867,153,,Mouchamps,Mouchamps,85,Vendée,52,Pays de la Loire
85154,MOUILLERON ST GERMAIN,85390,MOUILLERON ST GERMAIN,,46.6612700667,-0.846784201071,154,,Mouilleron-Saint-Germain,Mouilleron-Saint-Germain,85,Vendée,52,Pays de la Loire
85154,MOUILLERON ST GERMAIN,85390,MOUILLERON ST GERMAIN,ST GERMAIN L AIGUILLER,46.6612700667,-0.846784201071,154,,Mouilleron-Saint-Germain,Mouilleron-Saint-Germain,85,Vendée,52,Pays de la Loire
85155,MOUILLERON LE CAPTIF,85000,MOUILLERON LE CAPTIF,,46.7104764993,-1.46129661418,155,,Mouilleron-le-Captif,Mouilleron-le-Captif,85,Vendée,52,Pays de la Loire
85156,MOUTIERS LES MAUXFAITS,85540,MOUTIERS LES MAUXFAITS,,46.489279204,-1.43622387207,156,,Moutiers-les-Mauxfaits,Moutiers-les-Mauxfaits,85,Vendée,52,Pays de la Loire
85157,MOUTIERS SUR LE LAY,85320,MOUTIERS SUR LE LAY,,46.5651677306,-1.16826489836,157,,Moutiers-sur-le-Lay,Moutiers-sur-le-Lay,85,Vendée,52,Pays de la Loire
85158,MOUZEUIL ST MARTIN,85370,MOUZEUIL ST MARTIN,,46.4591118412,-0.984449849889,158,,Mouzeuil-Saint-Martin,Mouzeuil-Saint-Martin,85,Vendée,52,Pays de la Loire
85159,NALLIERS,85370,NALLIERS,,46.4658962703,-1.03958611312,159,,Nalliers,Nalliers,85,Vendée,52,Pays de la Loire
85160,NESMY,85310,NESMY,,46.5921936479,-1.40947698594,160,,Nesmy,Nesmy,85,Vendée,52,Pays de la Loire
85161,NIEUL LE DOLENT,85430,NIEUL LE DOLENT,,46.5676509922,-1.51560194548,161,,Nieul-le-Dolent,Nieul-le-Dolent,85,Vendée,52,Pays de la Loire
85162,NIEUL SUR L AUTISE,85240,NIEUL SUR L AUTISE,,46.424726987,-0.665995249042,162,,Nieul-sur-l'Autise,Nieul-sur-l'Autise,85,Vendée,52,Pays de la Loire
85163,NOIRMOUTIER EN L ILE,85330,NOIRMOUTIER EN L ILE,,47.0086655085,-2.26243620205,163,,Noirmoutier-en-l'Île,Noirmoutier-en-l'Île,85,Vendée,52,Pays de la Loire
85164,NOTRE DAME DE MONTS,85690,NOTRE DAME DE MONTS,,46.8424284611,-2.10928732775,164,,Notre-Dame-de-Monts,Notre-Dame-de-Monts,85,Vendée,52,Pays de la Loire
85166,OLONNE SUR MER,85340,OLONNE SUR MER,,46.5413300559,-1.78813984621,166,,Olonne-sur-Mer,Olonne-sur-Mer,85,Vendée,52,Pays de la Loire
85167,L ORBRIE,85200,L ORBRIE,,46.4997145725,-0.77427886573,167,L',Orbrie,L'Orbrie,85,Vendée,52,Pays de la Loire
85168,OULMES,85420,OULMES,,46.3941370181,-0.661346008062,168,,Oulmes,Oulmes,85,Vendée,52,Pays de la Loire
85169,PALLUAU,85670,PALLUAU,,46.8063002019,-1.60225256402,169,,Palluau,Palluau,85,Vendée,52,Pays de la Loire
85171,PEAULT,85320,PEAULT,,46.502029199,-1.22708559855,171,,Péault,Péault,85,Vendée,52,Pays de la Loire
85172,LE PERRIER,85300,LE PERRIER,,46.8196487652,-1.97926629071,172,Le,Perrier,Le Perrier,85,Vendée,52,Pays de la Loire
85174,PETOSSE,85570,PETOSSE,,46.4796848965,-0.911734176662,174,,Petosse,Petosse,85,Vendée,52,Pays de la Loire
85175,LES PINEAUX,85320,LES PINEAUX,,46.599225902,-1.17865799724,175,Les,Pineaux,Les Pineaux,85,Vendée,52,Pays de la Loire
85176,PISSOTTE,85200,PISSOTTE,,46.5010870694,-0.808352236192,176,,Pissotte,Pissotte,85,Vendée,52,Pays de la Loire
85177,LE POIRE SUR VELLUIRE,85770,LE POIRE SUR VELLUIRE,,46.4190919441,-0.910475769222,177,Le,Poiré-sur-Velluire,Le Poiré-sur-Velluire,85,Vendée,52,Pays de la Loire
85178,LE POIRE SUR VIE,85170,LE POIRE SUR VIE,,46.769919754,-1.50488626452,178,Le,Poiré-sur-Vie,Le Poiré-sur-Vie,85,Vendée,52,Pays de la Loire
85179,POIROUX,85440,POIROUX,,46.5107890457,-1.53929317556,179,,Poiroux,Poiroux,85,Vendée,52,Pays de la Loire
85181,POUILLE,85570,POUILLE,,46.5022256779,-0.955223380119,181,,Pouillé,Pouillé,85,Vendée,52,Pays de la Loire
85182,POUZAUGES,85700,POUZAUGES,,46.7812581702,-0.828778359084,182,,Pouzauges,Pouzauges,85,Vendée,52,Pays de la Loire
85184,PUY DE SERRE,85240,PUY DE SERRE,,46.5650384637,-0.680144631346,184,,Puy-de-Serre,Puy-de-Serre,85,Vendée,52,Pays de la Loire
85185,PUYRAVAULT,85450,PUYRAVAULT,,46.3653834101,-1.09115660367,185,,Puyravault,Puyravault,85,Vendée,52,Pays de la Loire
85186,LA RABATELIERE,85250,LA RABATELIERE,,46.8584147661,-1.2569733759,186,La,Rabatelière,La Rabatelière,85,Vendée,52,Pays de la Loire
85187,REAUMUR,85700,REAUMUR,,46.7145137269,-0.816742537248,187,,Réaumur,Réaumur,85,Vendée,52,Pays de la Loire
85188,LA REORTHE,85210,LA REORTHE,,46.6113748938,-1.04881036553,188,La,Réorthe,La Réorthe,85,Vendée,52,Pays de la Loire
85189,NOTRE DAME DE RIEZ,85270,NOTRE DAME DE RIEZ,,46.7532179022,-1.8935292542,189,,Notre-Dame-de-Riez,Notre-Dame-de-Riez,85,Vendée,52,Pays de la Loire
85190,ROCHESERVIERE,85620,ROCHESERVIERE,,46.9274273036,-1.50132208111,190,,Rocheservière,Rocheservière,85,Vendée,52,Pays de la Loire
85191,LA ROCHE SUR YON,85000,LA ROCHE SUR YON,,46.6675261644,-1.4077954093,191,La,Roche-sur-Yon,La Roche-sur-Yon,85,Vendée,52,Pays de la Loire
85192,ROCHETREJOUX,85510,ROCHETREJOUX,,46.7852546732,-0.996743019108,192,,Rochetrejoux,Rochetrejoux,85,Vendée,52,Pays de la Loire
85193,ROSNAY,85320,ROSNAY,,46.5324344973,-1.3007139449,193,,Rosnay,Rosnay,85,Vendée,52,Pays de la Loire
85194,LES SABLES D OLONNE,85100,LES SABLES D OLONNE,,46.5007612799,-1.79255128677,194,Les,Sables-d'Olonne,Les Sables-d'Olonne,85,Vendée,52,Pays de la Loire
85196,ST ANDRE GOULE D OIE,85250,ST ANDRE GOULE D OIE,,46.8410224478,-1.19644211396,196,,Saint-André-Goule-d'Oie,Saint-André-Goule-d'Oie,85,Vendée,52,Pays de la Loire
85197,MONTREVERD,85260,MONTREVERD,MORMAISON,46.9277672307,-1.4126154924,197,,Montréverd,Montréverd,85,Vendée,52,Pays de la Loire
85197,MONTREVERD,85260,MONTREVERD,ST ANDRE TREIZE VOIES,46.9277672307,-1.4126154924,197,,Montréverd,Montréverd,85,Vendée,52,Pays de la Loire
85197,MONTREVERD,85260,MONTREVERD,ST SULPICE LE VERDON,46.9277672307,-1.4126154924,197,,Montréverd,Montréverd,85,Vendée,52,Pays de la Loire
85198,ST AUBIN DES ORMEAUX,85130,ST AUBIN DES ORMEAUX,,46.9958175597,-1.04216568722,198,,Saint-Aubin-des-Ormeaux,Saint-Aubin-des-Ormeaux,85,Vendée,52,Pays de la Loire
85199,ST AUBIN LA PLAINE,85210,ST AUBIN LA PLAINE,,46.5040293195,-1.06506577005,199,,Saint-Aubin-la-Plaine,Saint-Aubin-la-Plaine,85,Vendée,52,Pays de la Loire
85200,ST AVAUGOURD DES LANDES,85540,ST AVAUGOURD DES LANDES,,46.5136722903,-1.47528120789,200,,Saint-Avaugourd-des-Landes,Saint-Avaugourd-des-Landes,85,Vendée,52,Pays de la Loire
85201,ST BENOIST SUR MER,85540,ST BENOIST SUR MER,,46.4266286403,-1.3399129604,201,,Saint-Benoist-sur-Mer,Saint-Benoist-sur-Mer,85,Vendée,52,Pays de la Loire
85202,STE CECILE,85110,STE CECILE,,46.7309688326,-1.12107316048,202,,Sainte-Cécile,Sainte-Cécile,85,Vendée,52,Pays de la Loire
85204,ST CHRISTOPHE DU LIGNERON,85670,ST CHRISTOPHE DU LIGNERON,,46.8151386519,-1.74035413493,204,,Saint-Christophe-du-Ligneron,Saint-Christophe-du-Ligneron,85,Vendée,52,Pays de la Loire
85205,ST CYR DES GATS,85410,ST CYR DES GATS,,46.572397925,-0.86344873853,205,,Saint-Cyr-des-Gâts,Saint-Cyr-des-Gâts,85,Vendée,52,Pays de la Loire
85206,ST CYR EN TALMONDAIS,85540,ST CYR EN TALMONDAIS,,46.4597334032,-1.33722144355,206,,Saint-Cyr-en-Talmondais,Saint-Cyr-en-Talmondais,85,Vendée,52,Pays de la Loire
85207,ST DENIS DU PAYRE,85580,ST DENIS DU PAYRE,,46.4118936776,-1.27222282402,207,,Saint-Denis-du-Payré,Saint-Denis-du-Payré,85,Vendée,52,Pays de la Loire
85208,ST DENIS LA CHEVASSE,85170,ST DENIS LA CHEVASSE,,46.8325959261,-1.3830312677,208,,Saint-Denis-la-Chevasse,Saint-Denis-la-Chevasse,85,Vendée,52,Pays de la Loire
85209,ST ETIENNE DE BRILLOUET,85210,ST ETIENNE DE BRILLOUET,,46.5138850327,-1.01157660374,209,,Saint-Étienne-de-Brillouet,Saint-Étienne-de-Brillouet,85,Vendée,52,Pays de la Loire
85210,ST ETIENNE DU BOIS,85670,ST ETIENNE DU BOIS,,46.8418481774,-1.59617737479,210,,Saint-Étienne-du-Bois,Saint-Étienne-du-Bois,85,Vendée,52,Pays de la Loire
85211,STE FLAIVE DES LOUPS,85150,STE FLAIVE DES LOUPS,,46.611019489,-1.58031627863,211,,Sainte-Flaive-des-Loups,Sainte-Flaive-des-Loups,85,Vendée,52,Pays de la Loire
85213,RIVES DE L YON,85310,RIVES DE L YON,CHAILLE SOUS LES ORMEAUX,46.605637391,-1.3354497172,213,,Rives de l'Yon,Rives de l'Yon,85,Vendée,52,Pays de la Loire
85213,RIVES DE L YON,85310,RIVES DE L YON,ST FLORENT DES BOIS,46.605637391,-1.3354497172,213,,Rives de l'Yon,Rives de l'Yon,85,Vendée,52,Pays de la Loire
85214,STE FOY,85150,STE FOY,,46.5327600427,-1.69243074733,214,,Sainte-Foy,Sainte-Foy,85,Vendée,52,Pays de la Loire
85215,ST FULGENT,85250,ST FULGENT,,46.8705618525,-1.16246465678,215,,Saint-Fulgent,Saint-Fulgent,85,Vendée,52,Pays de la Loire
85216,STE GEMME LA PLAINE,85400,STE GEMME LA PLAINE,,46.4732196212,-1.11103084694,216,,Sainte-Gemme-la-Plaine,Sainte-Gemme-la-Plaine,85,Vendée,52,Pays de la Loire
85217,ST GEORGES DE MONTAIGU,85600,ST GEORGES DE MONTAIGU,,46.935243741,-1.28155507305,217,,Saint-Georges-de-Montaigu,Saint-Georges-de-Montaigu,85,Vendée,52,Pays de la Loire
85218,ST GEORGES DE POINTINDOUX,85150,ST GEORGES DE POINTINDOUX,,46.6423470977,-1.62881823574,218,,Saint-Georges-de-Pointindoux,Saint-Georges-de-Pointindoux,85,Vendée,52,Pays de la Loire
85220,ST GERMAIN DE PRINCAY,85110,ST GERMAIN DE PRINCAY,,46.7356314659,-1.04299885081,220,,Saint-Germain-de-Prinçay,Saint-Germain-de-Prinçay,85,Vendée,52,Pays de la Loire
85221,ST GERVAIS,85230,ST GERVAIS,,46.9285711589,-1.98059327519,221,,Saint-Gervais,Saint-Gervais,85,Vendée,52,Pays de la Loire
85222,ST GILLES CROIX DE VIE,85800,ST GILLES CROIX DE VIE,,46.6904708814,-1.91946363327,222,,Saint-Gilles-Croix-de-Vie,Saint-Gilles-Croix-de-Vie,85,Vendée,52,Pays de la Loire
85223,STE HERMINE,85210,STE HERMINE,,46.5572659953,-1.07210861039,223,,Sainte-Hermine,Sainte-Hermine,85,Vendée,52,Pays de la Loire
85224,ST HILAIRE DE LOULAY,85600,ST HILAIRE DE LOULAY,,47.008536823,-1.32766740632,224,,Saint-Hilaire-de-Loulay,Saint-Hilaire-de-Loulay,85,Vendée,52,Pays de la Loire
85226,ST HILAIRE DE RIEZ,85270,ST HILAIRE DE RIEZ,,46.7432732188,-1.96439228965,226,,Saint-Hilaire-de-Riez,Saint-Hilaire-de-Riez,85,Vendée,52,Pays de la Loire
85226,ST HILAIRE DE RIEZ,85270,ST HILAIRE DE RIEZ,SION SUR L OCEAN,46.7432732188,-1.96439228965,226,,Saint-Hilaire-de-Riez,Saint-Hilaire-de-Riez,85,Vendée,52,Pays de la Loire
85227,ST HILAIRE DES LOGES,85240,ST HILAIRE DES LOGES,,46.4747117878,-0.650611151998,227,,Saint-Hilaire-des-Loges,Saint-Hilaire-des-Loges,85,Vendée,52,Pays de la Loire
85229,ST HILAIRE DE VOUST,85120,ST HILAIRE DE VOUST,,46.5914988312,-0.651486245674,229,,Saint-Hilaire-de-Voust,Saint-Hilaire-de-Voust,85,Vendée,52,Pays de la Loire
85231,ST HILAIRE LA FORET,85440,ST HILAIRE LA FORET,,46.4551155186,-1.53048160541,231,,Saint-Hilaire-la-Forêt,Saint-Hilaire-la-Forêt,85,Vendée,52,Pays de la Loire
85232,ST HILAIRE LE VOUHIS,85480,ST HILAIRE LE VOUHIS,,46.6859198669,-1.15222590884,232,,Saint-Hilaire-le-Vouhis,Saint-Hilaire-le-Vouhis,85,Vendée,52,Pays de la Loire
85233,ST JEAN DE BEUGNE,85210,ST JEAN DE BEUGNE,,46.5196817523,-1.10826075013,233,,Saint-Jean-de-Beugné,Saint-Jean-de-Beugné,85,Vendée,52,Pays de la Loire
85234,ST JEAN DE MONTS,85160,ST JEAN DE MONTS,,46.8021968737,-2.04839789308,234,,Saint-Jean-de-Monts,Saint-Jean-de-Monts,85,Vendée,52,Pays de la Loire
85235,ST JUIRE CHAMPGILLON,85210,ST JUIRE CHAMPGILLON,,46.5882648491,-1.00959676911,235,,Saint-Juire-Champgillon,Saint-Juire-Champgillon,85,Vendée,52,Pays de la Loire
85236,ST JULIEN DES LANDES,85150,ST JULIEN DES LANDES,,46.6395925444,-1.7159724914,236,,Saint-Julien-des-Landes,Saint-Julien-des-Landes,85,Vendée,52,Pays de la Loire
85237,ST LAURENT DE LA SALLE,85410,ST LAURENT DE LA SALLE,,46.5854041653,-0.922177315485,237,,Saint-Laurent-de-la-Salle,Saint-Laurent-de-la-Salle,85,Vendée,52,Pays de la Loire
85238,ST LAURENT SUR SEVRE,85290,ST LAURENT SUR SEVRE,,46.9506837971,-0.901123752328,238,,Saint-Laurent-sur-Sèvre,Saint-Laurent-sur-Sèvre,85,Vendée,52,Pays de la Loire
85239,ST MAIXENT SUR VIE,85220,ST MAIXENT SUR VIE,,46.7329496925,-1.82595152879,239,,Saint-Maixent-sur-Vie,Saint-Maixent-sur-Vie,85,Vendée,52,Pays de la Loire
85240,ST MALO DU BOIS,85590,ST MALO DU BOIS,,46.9248120291,-0.914182961099,240,,Saint-Malô-du-Bois,Saint-Malô-du-Bois,85,Vendée,52,Pays de la Loire
85242,ST MARS LA REORTHE,85590,ST MARS LA REORTHE,,46.8597253005,-0.925777900202,242,,Saint-Mars-la-Réorthe,Saint-Mars-la-Réorthe,85,Vendée,52,Pays de la Loire
85243,BREM SUR MER,85470,BREM SUR MER,,46.6118566989,-1.81003917923,243,,Brem-sur-Mer,Brem-sur-Mer,85,Vendée,52,Pays de la Loire
85243,BREM SUR MER,85470,BREM SUR MER,ST NICOLAS DE BREM,46.6118566989,-1.81003917923,243,,Brem-sur-Mer,Brem-sur-Mer,85,Vendée,52,Pays de la Loire
85244,ST MARTIN DE FRAIGNEAU,85200,ST MARTIN DE FRAIGNEAU,,46.4289052087,-0.758948963227,244,,Saint-Martin-de-Fraigneau,Saint-Martin-de-Fraigneau,85,Vendée,52,Pays de la Loire
85245,ST MARTIN DES FONTAINES,85570,ST MARTIN DES FONTAINES,,46.5464637508,-0.907394581139,245,,Saint-Martin-des-Fontaines,Saint-Martin-des-Fontaines,85,Vendée,52,Pays de la Loire
85246,ST MARTIN DES NOYERS,85140,ST MARTIN DES NOYERS,,46.7239461187,-1.20379080965,246,,Saint-Martin-des-Noyers,Saint-Martin-des-Noyers,85,Vendée,52,Pays de la Loire
85247,ST MARTIN DES TILLEULS,85130,ST MARTIN DES TILLEULS,,46.9711539531,-1.06282621567,247,,Saint-Martin-des-Tilleuls,Saint-Martin-des-Tilleuls,85,Vendée,52,Pays de la Loire
85248,ST MARTIN LARS EN STE HERMINE,85210,ST MARTIN LARS EN STE HERMINE,,46.5970244335,-0.976384286709,248,,Saint-Martin-Lars-en-Sainte-Hermine,Saint-Martin-Lars-en-Sainte-Hermine,85,Vendée,52,Pays de la Loire
85250,ST MATHURIN,85150,ST MATHURIN,,46.5686332748,-1.70787622288,250,,Saint-Mathurin,Saint-Mathurin,85,Vendée,52,Pays de la Loire
85251,ST MAURICE DES NOUES,85120,ST MAURICE DES NOUES,,46.5955876221,-0.724327725888,251,,Saint-Maurice-des-Noues,Saint-Maurice-des-Noues,85,Vendée,52,Pays de la Loire
85252,ST MAURICE LE GIRARD,85390,ST MAURICE LE GIRARD,,46.6398624578,-0.810875649028,252,,Saint-Maurice-le-Girard,Saint-Maurice-le-Girard,85,Vendée,52,Pays de la Loire
85254,ST MESMIN,85700,ST MESMIN,,46.8005779435,-0.748892533741,254,,Saint-Mesmin,Saint-Mesmin,85,Vendée,52,Pays de la Loire
85255,ST MICHEL EN L HERM,85580,ST MICHEL EN L HERM,,46.3366903175,-1.2483968538,255,,Saint-Michel-en-l'Herm,Saint-Michel-en-l'Herm,85,Vendée,52,Pays de la Loire
85256,ST MICHEL LE CLOUCQ,85200,ST MICHEL LE CLOUCQ,,46.4861591475,-0.743056336646,256,,Saint-Michel-le-Cloucq,Saint-Michel-le-Cloucq,85,Vendée,52,Pays de la Loire
85259,ST PAUL EN PAREDS,85500,ST PAUL EN PAREDS,,46.8303789022,-0.964515191283,259,,Saint-Paul-en-Pareds,Saint-Paul-en-Pareds,85,Vendée,52,Pays de la Loire
85260,ST PAUL MONT PENIT,85670,ST PAUL MONT PENIT,,46.8070059547,-1.66964833149,260,,Saint-Paul-Mont-Penit,Saint-Paul-Mont-Penit,85,Vendée,52,Pays de la Loire
85261,STE PEXINE,85320,STE PEXINE,,46.5596018797,-1.12406235901,261,,Sainte-Pexine,Sainte-Pexine,85,Vendée,52,Pays de la Loire
85262,ST PHILBERT DE BOUAINE,85660,ST PHILBERT DE BOUAINE,,46.9927907526,-1.5073882242,262,,Saint-Philbert-de-Bouaine,Saint-Philbert-de-Bouaine,85,Vendée,52,Pays de la Loire
85264,ST PIERRE DU CHEMIN,85120,ST PIERRE DU CHEMIN,,46.6957771744,-0.701777715154,264,,Saint-Pierre-du-Chemin,Saint-Pierre-du-Chemin,85,Vendée,52,Pays de la Loire
85265,ST PIERRE LE VIEUX,85420,ST PIERRE LE VIEUX,,46.4009643491,-0.742816267425,265,,Saint-Pierre-le-Vieux,Saint-Pierre-le-Vieux,85,Vendée,52,Pays de la Loire
85266,ST PROUANT,85110,ST PROUANT,,46.7502017421,-0.974504061491,266,,Saint-Prouant,Saint-Prouant,85,Vendée,52,Pays de la Loire
85267,STE RADEGONDE DES NOYERS,85450,STE RADEGONDE DES NOYERS,,46.3694246909,-1.06671995264,267,,Sainte-Radégonde-des-Noyers,Sainte-Radégonde-des-Noyers,85,Vendée,52,Pays de la Loire
85268,ST REVEREND,85220,ST REVEREND,,46.7057741864,-1.83155480996,268,,Saint-Révérend,Saint-Révérend,85,Vendée,52,Pays de la Loire
85269,ST SIGISMOND,85420,ST SIGISMOND,,46.3368577973,-0.707293731101,269,,Saint-Sigismond,Saint-Sigismond,85,Vendée,52,Pays de la Loire
85271,ST SULPICE EN PAREDS,85410,ST SULPICE EN PAREDS,,46.6130038733,-0.8310839288,271,,Saint-Sulpice-en-Pareds,Saint-Sulpice-en-Pareds,85,Vendée,52,Pays de la Loire
85273,ST URBAIN,85230,ST URBAIN,,46.8818371328,-2.01607828912,273,,Saint-Urbain,Saint-Urbain,85,Vendée,52,Pays de la Loire
85274,ST VALERIEN,85570,ST VALERIEN,,46.5348508899,-0.944470507825,274,,Saint-Valérien,Saint-Valérien,85,Vendée,52,Pays de la Loire
85276,ST VINCENT STERLANGES,85110,ST VINCENT STERLANGES,,46.7397220689,-1.08371759277,276,,Saint-Vincent-Sterlanges,Saint-Vincent-Sterlanges,85,Vendée,52,Pays de la Loire
85277,ST VINCENT SUR GRAON,85540,ST VINCENT SUR GRAON,,46.5034756656,-1.382954206,277,,Saint-Vincent-sur-Graon,Saint-Vincent-sur-Graon,85,Vendée,52,Pays de la Loire
85277,ST VINCENT SUR GRAON,85540,ST VINCENT SUR GRAON,ST SORNIN,46.5034756656,-1.382954206,277,,Saint-Vincent-sur-Graon,Saint-Vincent-sur-Graon,85,Vendée,52,Pays de la Loire
85278,ST VINCENT SUR JARD,85520,ST VINCENT SUR JARD,,46.4297504489,-1.54956205778,278,,Saint-Vincent-sur-Jard,Saint-Vincent-sur-Jard,85,Vendée,52,Pays de la Loire
85280,SALLERTAINE,85300,SALLERTAINE,,46.8659054157,-1.94894081389,280,,Sallertaine,Sallertaine,85,Vendée,52,Pays de la Loire
85281,SERIGNE,85200,SERIGNE,,46.5054321828,-0.848819460581,281,,Sérigné,Sérigné,85,Vendée,52,Pays de la Loire
85282,SIGOURNAIS,85110,SIGOURNAIS,,46.7140097406,-0.98747730882,282,,Sigournais,Sigournais,85,Vendée,52,Pays de la Loire
85284,SOULLANS,85300,SOULLANS,,46.7951288466,-1.91392699457,284,,Soullans,Soullans,85,Vendée,52,Pays de la Loire
85285,LE TABLIER,85310,LE TABLIER,,46.5596307281,-1.32788759657,285,Le,Tablier,Le Tablier,85,Vendée,52,Pays de la Loire
85286,LA TAILLEE,85450,LA TAILLEE,,46.3852513569,-0.941017792066,286,La,Taillée,La Taillée,85,Vendée,52,Pays de la Loire
85287,TALLUD STE GEMME,85390,TALLUD STE GEMME,,46.6949386862,-0.886169517112,287,,Tallud-Sainte-Gemme,Tallud-Sainte-Gemme,85,Vendée,52,Pays de la Loire
85288,TALMONT ST HILAIRE,85440,TALMONT ST HILAIRE,,46.475786445,-1.62751498166,288,,Talmont-Saint-Hilaire,Talmont-Saint-Hilaire,85,Vendée,52,Pays de la Loire
85288,TALMONT ST HILAIRE,85440,TALMONT ST HILAIRE,ST HILAIRE DE TALMONT,46.475786445,-1.62751498166,288,,Talmont-Saint-Hilaire,Talmont-Saint-Hilaire,85,Vendée,52,Pays de la Loire
85289,LA TARDIERE,85120,LA TARDIERE,,46.663737434,-0.727562430214,289,La,Tardière,La Tardière,85,Vendée,52,Pays de la Loire
85290,THIRE,85210,THIRE,,46.5433098199,-1.00699777534,290,,Thiré,Thiré,85,Vendée,52,Pays de la Loire
85291,THORIGNY,85480,THORIGNY,,46.6179795025,-1.24888057642,291,,Thorigny,Thorigny,85,Vendée,52,Pays de la Loire
85292,THOUARSAIS BOUILDROUX,85410,THOUARSAIS BOUILDROUX,,46.6062740621,-0.873461023865,292,,Thouarsais-Bouildroux,Thouarsais-Bouildroux,85,Vendée,52,Pays de la Loire
85293,TIFFAUGES,85130,TIFFAUGES,,47.0020573556,-1.09858009203,293,,Tiffauges,Tiffauges,85,Vendée,52,Pays de la Loire
85294,LA TRANCHE SUR MER,85360,LA TRANCHE SUR MER,,46.3564601605,-1.43136322126,294,La,Tranche-sur-Mer,La Tranche-sur-Mer,85,Vendée,52,Pays de la Loire
85295,TREIZE SEPTIERS,85600,TREIZE SEPTIERS,,46.9975586143,-1.23193361154,295,,Treize-Septiers,Treize-Septiers,85,Vendée,52,Pays de la Loire
85296,TREIZE VENTS,85590,TREIZE VENTS,,46.9168130123,-0.845959158017,296,,Treize-Vents,Treize-Vents,85,Vendée,52,Pays de la Loire
85297,TRIAIZE,85580,TRIAIZE,,46.3792685111,-1.19928351422,297,,Triaize,Triaize,85,Vendée,52,Pays de la Loire
85298,VAIRE,85150,VAIRE,,46.6055340621,-1.74863672042,298,,Vairé,Vairé,85,Vendée,52,Pays de la Loire
85299,VELLUIRE,85770,VELLUIRE,,46.3973858732,-0.885083606793,299,,Velluire,Velluire,85,Vendée,52,Pays de la Loire
85300,VENANSAULT,85190,VENANSAULT,,46.685677363,-1.54112129191,300,,Venansault,Venansault,85,Vendée,52,Pays de la Loire
85301,VENDRENNES,85250,VENDRENNES,,46.8226741756,-1.11650982164,301,,Vendrennes,Vendrennes,85,Vendée,52,Pays de la Loire
85302,LA VERRIE,85130,LA VERRIE,,46.9634774521,-0.985340006089,302,La,Verrie,La Verrie,85,Vendée,52,Pays de la Loire
85303,VIX,85770,VIX,,46.3543018169,-0.856628326667,303,,Vix,Vix,85,Vendée,52,Pays de la Loire
85304,VOUILLE LES MARAIS,85450,VOUILLE LES MARAIS,,46.3891941167,-0.968106001439,304,,Vouillé-les-Marais,Vouillé-les-Marais,85,Vendée,52,Pays de la Loire
85305,VOUVANT,85120,VOUVANT,,46.5626835135,-0.764380170382,305,,Vouvant,Vouvant,85,Vendée,52,Pays de la Loire
85306,XANTON CHASSENON,85240,XANTON CHASSENON,,46.4519408659,-0.706316598666,306,,Xanton-Chassenon,Xanton-Chassenon,85,Vendée,52,Pays de la Loire
85307,LA FAUTE SUR MER,85460,LA FAUTE SUR MER,,46.3199919131,-1.31487049579,307,La,Faute-sur-Mer,La Faute-sur-Mer,85,Vendée,52,Pays de la Loire
86001,ADRIERS,86430,ADRIERS,,46.2646103374,0.789659194144,1,,Adriers,Adriers,86,Vienne,75,Nouvelle-Aquitaine
86002,AMBERRE,86110,AMBERRE,,46.7578511349,0.153603662903,2,,Amberre,Amberre,86,Vienne,75,Nouvelle-Aquitaine
86003,ANCHE,86700,ANCHE,,46.3496375961,0.270285912605,3,,Anché,Anché,86,Vienne,75,Nouvelle-Aquitaine
86004,ANGLES SUR L ANGLIN,86260,ANGLES SUR L ANGLIN,,46.6986976,0.896253717569,4,,Angles-sur-l'Anglin,Angles-sur-l'Anglin,86,Vienne,75,Nouvelle-Aquitaine
86005,ANGLIERS,86330,ANGLIERS,,46.9385872919,0.102241708869,5,,Angliers,Angliers,86,Vienne,75,Nouvelle-Aquitaine
86006,ANTIGNY,86310,ANTIGNY,,46.5360016424,0.839149696505,6,,Antigny,Antigny,86,Vienne,75,Nouvelle-Aquitaine
86007,ANTRAN,86100,ANTRAN,,46.877083076,0.530703043552,7,,Antran,Antran,86,Vienne,75,Nouvelle-Aquitaine
86008,ARCAY,86200,ARCAY,,46.9520341162,0.00333589547426,8,,Arçay,Arçay,86,Vienne,75,Nouvelle-Aquitaine
86009,ARCHIGNY,86210,ARCHIGNY,,46.6715864043,0.681233798313,9,,Archigny,Archigny,86,Vienne,75,Nouvelle-Aquitaine
86010,ASLONNES,86340,ASLONNES,,46.4452071575,0.33420632298,10,,Aslonnes,Aslonnes,86,Vienne,75,Nouvelle-Aquitaine
86011,ASNIERES SUR BLOUR,86430,ASNIERES SUR BLOUR,,46.1500987488,0.78461771822,11,,Asnières-sur-Blour,Asnières-sur-Blour,86,Vienne,75,Nouvelle-Aquitaine
86012,ASNOIS,86250,ASNOIS,,46.1033058392,0.408300889947,12,,Asnois,Asnois,86,Vienne,75,Nouvelle-Aquitaine
86013,AULNAY,86330,AULNAY,,46.9060273599,0.0912871261314,13,,Aulnay,Aulnay,86,Vienne,75,Nouvelle-Aquitaine
86014,AVAILLES EN CHATELLERAULT,86530,AVAILLES EN CHATELLERAULT,,46.7561922634,0.569776256918,14,,Availles-en-Châtellerault,Availles-en-Châtellerault,86,Vienne,75,Nouvelle-Aquitaine
86015,AVAILLES LIMOUZINE,86460,AVAILLES LIMOUZINE,,46.1339246514,0.647417301116,15,,Availles-Limouzine,Availles-Limouzine,86,Vienne,75,Nouvelle-Aquitaine
86016,AVANTON,86170,AVANTON,,46.6636113656,0.304857474533,16,,Avanton,Avanton,86,Vienne,75,Nouvelle-Aquitaine
86017,AYRON,86190,AYRON,,46.6440797341,0.0454968379213,17,,Ayron,Ayron,86,Vienne,75,Nouvelle-Aquitaine
86018,BASSES,86200,BASSES,,47.044883115,0.124036539439,18,,Basses,Basses,86,Vienne,75,Nouvelle-Aquitaine
86019,BEAUMONT,86490,BEAUMONT,,46.7432070332,0.436999990753,19,,Beaumont Saint-Cyr,Beaumont Saint-Cyr,86,Vienne,75,Nouvelle-Aquitaine
86019,BEAUMONT,86490,BEAUMONT,LA TRICHERIE,46.7432070332,0.436999990753,19,,Beaumont Saint-Cyr,Beaumont Saint-Cyr,86,Vienne,75,Nouvelle-Aquitaine
86020,BELLEFONDS,86210,BELLEFONDS,,46.6504921965,0.607845231344,20,,Bellefonds,Bellefonds,86,Vienne,75,Nouvelle-Aquitaine
86021,BENASSAY,86470,BENASSAY,,46.5510149682,0.0352705236052,21,,Benassay,Benassay,86,Vienne,75,Nouvelle-Aquitaine
86022,BERRIE,86120,BERRIE,,47.0594379159,-0.0722827827808,22,,Berrie,Berrie,86,Vienne,75,Nouvelle-Aquitaine
86023,BERTHEGON,86420,BERTHEGON,,46.9016657704,0.268623771232,23,,Berthegon,Berthegon,86,Vienne,75,Nouvelle-Aquitaine
86024,BERUGES,86190,BERUGES,,46.5555538326,0.202564421374,24,,Béruges,Béruges,86,Vienne,75,Nouvelle-Aquitaine
86025,BETHINES,86310,BETHINES,,46.5469550432,0.976587336165,25,,Béthines,Béthines,86,Vienne,75,Nouvelle-Aquitaine
86026,BEUXES,86120,BEUXES,,47.0870340103,0.173619362771,26,,Beuxes,Beuxes,86,Vienne,75,Nouvelle-Aquitaine
86027,BIARD,86580,BIARD,,46.5859914233,0.297504952601,27,,Biard,Biard,86,Vienne,75,Nouvelle-Aquitaine
86028,BIGNOUX,86800,BIGNOUX,,46.6032559543,0.465724619773,28,,Bignoux,Bignoux,86,Vienne,75,Nouvelle-Aquitaine
86029,BLANZAY,86400,BLANZAY,,46.2071446631,0.255573056586,29,,Blanzay,Blanzay,86,Vienne,75,Nouvelle-Aquitaine
86030,BLASLAY,86170,BLASLAY,,46.7296195469,0.230518218918,30,,Blaslay,Blaslay,86,Vienne,75,Nouvelle-Aquitaine
86031,BONNES,86300,BONNES,,46.613909053,0.599381155939,31,,Bonnes,Bonnes,86,Vienne,75,Nouvelle-Aquitaine
86032,BONNEUIL MATOURS,86210,BONNEUIL MATOURS,,46.6795805197,0.566087021513,32,,Bonneuil-Matours,Bonneuil-Matours,86,Vienne,75,Nouvelle-Aquitaine
86034,BOURESSE,86410,BOURESSE,,46.3555804441,0.581776252494,34,,Bouresse,Bouresse,86,Vienne,75,Nouvelle-Aquitaine
86035,BOURG ARCHAMBAULT,86390,BOURG ARCHAMBAULT,,46.3726210899,1.00188704322,35,,Bourg-Archambault,Bourg-Archambault,86,Vienne,75,Nouvelle-Aquitaine
86036,BOURNAND,86120,BOURNAND,,47.0744670977,0.075217763683,36,,Bournand,Bournand,86,Vienne,75,Nouvelle-Aquitaine
86037,BRIGUEIL LE CHANTRE,86290,BRIGUEIL LE CHANTRE,,46.3870241748,1.09004082103,37,,Brigueil-le-Chantre,Brigueil-le-Chantre,86,Vienne,75,Nouvelle-Aquitaine
86038,BRION,86160,BRION,,46.357045926,0.472294724687,38,,Brion,Brion,86,Vienne,75,Nouvelle-Aquitaine
86039,BRUX,86510,BRUX,,46.2504690736,0.191737009106,39,,Brux,Brux,86,Vienne,75,Nouvelle-Aquitaine
86040,LA BUSSIERE,86310,LA BUSSIERE,,46.6315763741,0.82155717047,40,La,Bussière,La Bussière,86,Vienne,75,Nouvelle-Aquitaine
86041,BUXEROLLES,86180,BUXEROLLES,,46.6054095506,0.369205479606,41,,Buxerolles,Buxerolles,86,Vienne,75,Nouvelle-Aquitaine
86042,BUXEUIL,37160,BUXEUIL,,46.9691626791,0.663280851924,42,,Buxeuil,Buxeuil,86,Vienne,75,Nouvelle-Aquitaine
86043,CEAUX EN COUHE,86700,CEAUX EN COUHE,,46.316093485,0.238288018655,43,,Ceaux-en-Couhé,Ceaux-en-Couhé,86,Vienne,75,Nouvelle-Aquitaine
86044,CEAUX EN LOUDUN,86200,CEAUX EN LOUDUN,,47.0334327292,0.229028665856,44,,Ceaux-en-Loudun,Ceaux-en-Loudun,86,Vienne,75,Nouvelle-Aquitaine
86045,CELLE LEVESCAULT,86600,CELLE LEVESCAULT,,46.3990380098,0.169884448212,45,,Celle-Lévescault,Celle-Lévescault,86,Vienne,75,Nouvelle-Aquitaine
86046,CENON SUR VIENNE,86530,CENON SUR VIENNE,,46.7589403896,0.529064773205,46,,Cenon-sur-Vienne,Cenon-sur-Vienne,86,Vienne,75,Nouvelle-Aquitaine
86047,CERNAY,86140,CERNAY,,46.8366061305,0.301673883746,47,,Cernay,Cernay,86,Vienne,75,Nouvelle-Aquitaine
86048,CHABOURNAY,86380,CHABOURNAY,,46.7196688259,0.269258187544,48,,Chabournay,Chabournay,86,Vienne,75,Nouvelle-Aquitaine
86049,CHALAIS,86200,CHALAIS,,46.9719782198,0.0979602730764,49,,Chalais,Chalais,86,Vienne,75,Nouvelle-Aquitaine
86050,CHALANDRAY,86190,CHALANDRAY,,46.6544747841,-0.00687604114589,50,,Chalandray,Chalandray,86,Vienne,75,Nouvelle-Aquitaine
86051,CHAMPAGNE LE SEC,86510,CHAMPAGNE LE SEC,,46.1888814469,0.190864426806,51,,Champagné-le-Sec,Champagné-le-Sec,86,Vienne,75,Nouvelle-Aquitaine
86052,CHAMPAGNE ST HILAIRE,86160,CHAMPAGNE ST HILAIRE,,46.3229827238,0.320405816347,52,,Champagné-Saint-Hilaire,Champagné-Saint-Hilaire,86,Vienne,75,Nouvelle-Aquitaine
86053,CHAMPIGNY LE SEC,86170,CHAMPIGNY LE SEC,,46.7235017775,0.154205857563,53,,Champigny en Rochereau,Champigny en Rochereau,86,Vienne,75,Nouvelle-Aquitaine
86054,CHAMPNIERS,86400,CHAMPNIERS,,46.2148195994,0.327805032659,54,,Champniers,Champniers,86,Vienne,75,Nouvelle-Aquitaine
86055,LA CHAPELLE BATON,86250,LA CHAPELLE BATON,,46.1890627915,0.409088341368,55,La,Chapelle-Bâton,La Chapelle-Bâton,86,Vienne,75,Nouvelle-Aquitaine
86056,LA CHAPELLE MONTREUIL,86470,LA CHAPELLE MONTREUIL,,46.5222746199,0.135612163982,56,La,Chapelle-Montreuil,La Chapelle-Montreuil,86,Vienne,75,Nouvelle-Aquitaine
86058,LA CHAPELLE MOULIERE,86210,LA CHAPELLE MOULIERE,,46.6458608784,0.536909480756,58,La,Chapelle-Moulière,La Chapelle-Moulière,86,Vienne,75,Nouvelle-Aquitaine
86059,CHAPELLE VIVIERS,86300,CHAPELLE VIVIERS,,46.4694872562,0.730976969783,59,,Chapelle-Viviers,Chapelle-Viviers,86,Vienne,75,Nouvelle-Aquitaine
86060,CHARRAIS,86170,CHARRAIS,,46.6975600146,0.200482288592,60,,Charrais,Charrais,86,Vienne,75,Nouvelle-Aquitaine
86061,CHARROUX,86250,CHARROUX,,46.1403226222,0.415210425845,61,,Charroux,Charroux,86,Vienne,75,Nouvelle-Aquitaine
86062,CHASSENEUIL DU POITOU,86360,CHASSENEUIL DU POITOU,,46.6518600158,0.361722698793,62,,Chasseneuil-du-Poitou,Chasseneuil-du-Poitou,86,Vienne,75,Nouvelle-Aquitaine
86063,CHATAIN,86250,CHATAIN,,46.0723336149,0.437740992282,63,,Chatain,Chatain,86,Vienne,75,Nouvelle-Aquitaine
86064,CHATEAU GARNIER,86350,CHATEAU GARNIER,,46.2596541711,0.440513917071,64,,Château-Garnier,Château-Garnier,86,Vienne,75,Nouvelle-Aquitaine
86065,CHATEAU LARCHER,86370,CHATEAU LARCHER,,46.4163092918,0.300807728817,65,,Château-Larcher,Château-Larcher,86,Vienne,75,Nouvelle-Aquitaine
86066,CHATELLERAULT,86100,CHATELLERAULT,,46.8156700185,0.552598976936,66,,Châtellerault,Châtellerault,86,Vienne,75,Nouvelle-Aquitaine
86066,CHATELLERAULT,86100,CHATELLERAULT,TARGE,46.8156700185,0.552598976936,66,,Châtellerault,Châtellerault,86,Vienne,75,Nouvelle-Aquitaine
86067,CHATILLON,86700,CHATILLON,,46.3197910426,0.189593635346,67,,Châtillon,Châtillon,86,Vienne,75,Nouvelle-Aquitaine
86068,CHAUNAY,86510,CHAUNAY,,46.2051935313,0.158137798049,68,,Chaunay,Chaunay,86,Vienne,75,Nouvelle-Aquitaine
86069,LA CHAUSSEE,86330,LA CHAUSSEE,,46.8863544923,0.110583366084,69,La,Chaussée,La Chaussée,86,Vienne,75,Nouvelle-Aquitaine
86070,CHAUVIGNY,86300,CHAUVIGNY,,46.5545564256,0.678622247419,70,,Chauvigny,Chauvigny,86,Vienne,75,Nouvelle-Aquitaine
86070,CHAUVIGNY,86300,CHAUVIGNY,POUZIOUX,46.5545564256,0.678622247419,70,,Chauvigny,Chauvigny,86,Vienne,75,Nouvelle-Aquitaine
86071,CHENECHE,86380,CHENECHE,,46.7444232538,0.271165450849,71,,Cheneché,Cheneché,86,Vienne,75,Nouvelle-Aquitaine
86072,CHENEVELLES,86450,CHENEVELLES,,46.7240115678,0.674147109983,72,,Chenevelles,Chenevelles,86,Vienne,75,Nouvelle-Aquitaine
86073,CHERVES,86170,CHERVES,,46.6943513948,0.0270041425401,73,,Cherves,Cherves,86,Vienne,75,Nouvelle-Aquitaine
86074,CHIRE EN MONTREUIL,86190,CHIRE EN MONTREUIL,,46.6259667314,0.112053368688,74,,Chiré-en-Montreuil,Chiré-en-Montreuil,86,Vienne,75,Nouvelle-Aquitaine
86075,CHOUPPES,86110,CHOUPPES,,46.8076034833,0.168441439384,75,,Chouppes,Chouppes,86,Vienne,75,Nouvelle-Aquitaine
86076,CISSE,86170,CISSE,,46.6502861591,0.241446585284,76,,Cissé,Cissé,86,Vienne,75,Nouvelle-Aquitaine
86077,CIVAUX,86320,CIVAUX,,46.4423086476,0.66823243898,77,,Civaux,Civaux,86,Vienne,75,Nouvelle-Aquitaine
86078,CIVRAY,86400,CIVRAY,,46.1412232275,0.300987364675,78,,Civray,Civray,86,Vienne,75,Nouvelle-Aquitaine
86079,LA ROCHE RIGAULT,86200,LA ROCHE RIGAULT,,46.9698026391,0.170597990866,79,La,Roche-Rigault,La Roche-Rigault,86,Vienne,75,Nouvelle-Aquitaine
86079,LA ROCHE RIGAULT,86200,LA ROCHE RIGAULT,CLAUNAY EN LOUDUN,46.9698026391,0.170597990866,79,La,Roche-Rigault,La Roche-Rigault,86,Vienne,75,Nouvelle-Aquitaine
86079,LA ROCHE RIGAULT,86200,LA ROCHE RIGAULT,LE BOUCHET,46.9698026391,0.170597990866,79,La,Roche-Rigault,La Roche-Rigault,86,Vienne,75,Nouvelle-Aquitaine
86080,CLOUE,86600,CLOUE,,46.4464190572,0.16406841425,80,,Cloué,Cloué,86,Vienne,75,Nouvelle-Aquitaine
86081,COLOMBIERS,86490,COLOMBIERS,,46.7799541393,0.437112922808,81,,Colombiers,Colombiers,86,Vienne,75,Nouvelle-Aquitaine
86082,COUHE,86700,COUHE,,46.2960515844,0.179497066899,82,,Couhé,Couhé,86,Vienne,75,Nouvelle-Aquitaine
86083,COULOMBIERS,86600,COULOMBIERS,,46.4939416192,0.173468544553,83,,Coulombiers,Coulombiers,86,Vienne,75,Nouvelle-Aquitaine
86084,COULONGES,86290,COULONGES,,46.4126626275,1.1750235947,84,,Coulonges,Coulonges,86,Vienne,75,Nouvelle-Aquitaine
86085,COUSSAY,86110,COUSSAY,,46.8386789415,0.201706399643,85,,Coussay,Coussay,86,Vienne,75,Nouvelle-Aquitaine
86086,COUSSAY LES BOIS,86270,COUSSAY LES BOIS,,46.8131222702,0.725467078625,86,,Coussay-les-Bois,Coussay-les-Bois,86,Vienne,75,Nouvelle-Aquitaine
86087,CRAON,86110,CRAON,,46.7719644602,0.0146641408168,87,,Craon,Craon,86,Vienne,75,Nouvelle-Aquitaine
86088,CROUTELLE,86240,CROUTELLE,,46.5425586218,0.2929490098,88,,Croutelle,Croutelle,86,Vienne,75,Nouvelle-Aquitaine
86089,CUHON,86110,CUHON,,46.7619647727,0.105709107422,89,,Cuhon,Cuhon,86,Vienne,75,Nouvelle-Aquitaine
86090,CURCAY SUR DIVE,86120,CURCAY SUR DIVE,,47.012286666,-0.0577125676726,90,,Curçay-sur-Dive,Curçay-sur-Dive,86,Vienne,75,Nouvelle-Aquitaine
86091,CURZAY SUR VONNE,86600,CURZAY SUR VONNE,,46.4906519992,0.0413718312202,91,,Curzay-sur-Vonne,Curzay-sur-Vonne,86,Vienne,75,Nouvelle-Aquitaine
86092,DANGE ST ROMAIN,86220,DANGE ST ROMAIN,,46.9270829508,0.608217192883,92,,Dangé-Saint-Romain,Dangé-Saint-Romain,86,Vienne,75,Nouvelle-Aquitaine
86093,DERCE,86420,DERCE,,46.9393324968,0.208681874617,93,,Dercé,Dercé,86,Vienne,75,Nouvelle-Aquitaine
86094,DIENNE,86410,DIENNE,,46.4414393381,0.537436523079,94,,Dienné,Dienné,86,Vienne,75,Nouvelle-Aquitaine
86095,DISSAY,86130,DISSAY,,46.6962926457,0.443383636833,95,,Dissay,Dissay,86,Vienne,75,Nouvelle-Aquitaine
86096,DOUSSAY,86140,DOUSSAY,,46.8397507343,0.272341235915,96,,Doussay,Doussay,86,Vienne,75,Nouvelle-Aquitaine
86097,LA FERRIERE AIROUX,86160,LA FERRIERE AIROUX,,46.314702219,0.410169403007,97,La,Ferrière-Airoux,La Ferrière-Airoux,86,Vienne,75,Nouvelle-Aquitaine
86098,FLEIX,86300,FLEIX,,46.5580414765,0.747824458548,98,,Fleix,Fleix,86,Vienne,75,Nouvelle-Aquitaine
86099,FLEURE,86340,FLEURE,,46.4832773182,0.522738191744,99,,Fleuré,Fleuré,86,Vienne,75,Nouvelle-Aquitaine
86100,FONTAINE LE COMTE,86240,FONTAINE LE COMTE,,46.5312436615,0.246086949225,100,,Fontaine-le-Comte,Fontaine-le-Comte,86,Vienne,75,Nouvelle-Aquitaine
86102,FROZES,86190,FROZES,,46.6645340062,0.134127229856,102,,Frozes,Frozes,86,Vienne,75,Nouvelle-Aquitaine
86103,GENCAY,86160,GENCAY,,46.3705267835,0.413253072588,103,,Gençay,Gençay,86,Vienne,75,Nouvelle-Aquitaine
86104,GENOUILLE,86250,GENOUILLE,,46.101002656,0.338161906694,104,,Genouillé,Genouillé,86,Vienne,75,Nouvelle-Aquitaine
86105,GIZAY,86340,GIZAY,,46.4395803569,0.423353423469,105,,Gizay,Gizay,86,Vienne,75,Nouvelle-Aquitaine
86106,GLENOUZE,86200,GLENOUZE,,47.007793926,-0.00436588789691,106,,Glénouze,Glénouze,86,Vienne,75,Nouvelle-Aquitaine
86107,GOUEX,86320,GOUEX,,46.368868035,0.664823680576,107,,Gouex,Gouex,86,Vienne,75,Nouvelle-Aquitaine
86108,LA GRIMAUDIERE,86110,LA GRIMAUDIERE,VERGER SUR DIVE,46.8026516286,0.0301338119599,108,La,Grimaudière,La Grimaudière,86,Vienne,75,Nouvelle-Aquitaine
86108,LA GRIMAUDIERE,86330,LA GRIMAUDIERE,,46.8026516286,0.0301338119599,108,La,Grimaudière,La Grimaudière,86,Vienne,75,Nouvelle-Aquitaine
86108,LA GRIMAUDIERE,86330,LA GRIMAUDIERE,NOTRE DAME D OR,46.8026516286,0.0301338119599,108,La,Grimaudière,La Grimaudière,86,Vienne,75,Nouvelle-Aquitaine
86109,GUESNES,86420,GUESNES,,46.9083772846,0.149597143414,109,,Guesnes,Guesnes,86,Vienne,75,Nouvelle-Aquitaine
86110,HAIMS,86310,HAIMS,,46.513121388,0.927029519838,110,,Haims,Haims,86,Vienne,75,Nouvelle-Aquitaine
86111,INGRANDES,86220,INGRANDES,,46.8739924084,0.59309229474,111,,Ingrandes,Ingrandes,86,Vienne,75,Nouvelle-Aquitaine
86112,L ISLE JOURDAIN,86150,L ISLE JOURDAIN,,46.2384696934,0.698756501243,112,L',Isle-Jourdain,L'Isle-Jourdain,86,Vienne,75,Nouvelle-Aquitaine
86113,ITEUIL,86240,ITEUIL,,46.484768544,0.291606821215,113,,Iteuil,Iteuil,86,Vienne,75,Nouvelle-Aquitaine
86114,JARDRES,86800,JARDRES,,46.5676854108,0.569115337508,114,,Jardres,Jardres,86,Vienne,75,Nouvelle-Aquitaine
86115,JAUNAY CLAN,86130,JAUNAY CLAN,,46.6935919998,0.356614754201,115,,Jaunay-Marigny,Jaunay-Marigny,86,Vienne,75,Nouvelle-Aquitaine
86116,JAZENEUIL,86600,JAZENEUIL,,46.4676363109,0.0747596893664,116,,Jazeneuil,Jazeneuil,86,Vienne,75,Nouvelle-Aquitaine
86117,JOUHET,86500,JOUHET,,46.4893266692,0.873416912338,117,,Jouhet,Jouhet,86,Vienne,75,Nouvelle-Aquitaine
86118,JOURNET,86290,JOURNET,,46.469251343,0.974156008064,118,,Journet,Journet,86,Vienne,75,Nouvelle-Aquitaine
86119,JOUSSE,86350,JOUSSE,,46.2241305163,0.457298281723,119,,Joussé,Joussé,86,Vienne,75,Nouvelle-Aquitaine
86120,LATHUS ST REMY,86390,LATHUS ST REMY,,46.3208334605,0.944330571873,120,,Lathus-Saint-Rémy,Lathus-Saint-Rémy,86,Vienne,75,Nouvelle-Aquitaine
86120,LATHUS ST REMY,86390,LATHUS ST REMY,ST REMY EN MONTMORILLON,46.3208334605,0.944330571873,120,,Lathus-Saint-Rémy,Lathus-Saint-Rémy,86,Vienne,75,Nouvelle-Aquitaine
86121,LATILLE,86190,LATILLE,,46.6099211196,0.0623433314507,121,,Latillé,Latillé,86,Vienne,75,Nouvelle-Aquitaine
86122,LAUTHIERS,86300,LAUTHIERS,,46.6040213362,0.726653339198,122,,Lauthiers,Lauthiers,86,Vienne,75,Nouvelle-Aquitaine
86123,LAVAUSSEAU,86470,LAVAUSSEAU,,46.5433549804,0.0821497150944,123,,Lavausseau,Lavausseau,86,Vienne,75,Nouvelle-Aquitaine
86124,LAVOUX,86800,LAVOUX,,46.594305535,0.52295072833,124,,Lavoux,Lavoux,86,Vienne,75,Nouvelle-Aquitaine
86125,LEIGNE LES BOIS,86450,LEIGNE LES BOIS,,46.7640626474,0.705788693742,125,,Leigné-les-Bois,Leigné-les-Bois,86,Vienne,75,Nouvelle-Aquitaine
86126,LEIGNES SUR FONTAINE,86300,LEIGNES SUR FONTAINE,,46.509990215,0.763267537538,126,,Leignes-sur-Fontaine,Leignes-sur-Fontaine,86,Vienne,75,Nouvelle-Aquitaine
86127,LEIGNE SUR USSEAU,86230,LEIGNE SUR USSEAU,,46.9162063157,0.475922952908,127,,Leigné-sur-Usseau,Leigné-sur-Usseau,86,Vienne,75,Nouvelle-Aquitaine
86128,LENCLOITRE,86140,LENCLOITRE,,46.8046486109,0.303557695444,128,,Lencloître,Lencloître,86,Vienne,75,Nouvelle-Aquitaine
86129,LESIGNY,86270,LESIGNY,,46.8368423097,0.772346287299,129,,Lésigny,Lésigny,86,Vienne,75,Nouvelle-Aquitaine
86130,LEUGNY,86220,LEUGNY,,46.8980444886,0.69376366371,130,,Leugny,Leugny,86,Vienne,75,Nouvelle-Aquitaine
86131,LHOMMAIZE,86410,LHOMMAIZE,,46.4434246955,0.588807648526,131,,Lhommaizé,Lhommaizé,86,Vienne,75,Nouvelle-Aquitaine
86132,LIGLET,86290,LIGLET,,46.5066719767,1.08346651537,132,,Liglet,Liglet,86,Vienne,75,Nouvelle-Aquitaine
86133,LIGUGE,86240,LIGUGE,,46.5205840634,0.298086285921,133,,Ligugé,Ligugé,86,Vienne,75,Nouvelle-Aquitaine
86134,LINAZAY,86400,LINAZAY,,46.1672922184,0.192422386232,134,,Linazay,Linazay,86,Vienne,75,Nouvelle-Aquitaine
86135,LINIERS,86800,LINIERS,,46.6179212567,0.530368469247,135,,Liniers,Liniers,86,Vienne,75,Nouvelle-Aquitaine
86136,LIZANT,86400,LIZANT,,46.0800915192,0.283290344941,136,,Lizant,Lizant,86,Vienne,75,Nouvelle-Aquitaine
86137,LOUDUN,86200,LOUDUN,,47.0098072856,0.0990443124673,137,,Loudun,Loudun,86,Vienne,75,Nouvelle-Aquitaine
86137,LOUDUN,86200,LOUDUN,ROSSAY,47.0098072856,0.0990443124673,137,,Loudun,Loudun,86,Vienne,75,Nouvelle-Aquitaine
86138,LUCHAPT,86430,LUCHAPT,,46.1824696586,0.770449311399,138,,Luchapt,Luchapt,86,Vienne,75,Nouvelle-Aquitaine
86139,LUSIGNAN,86600,LUSIGNAN,,46.4377572057,0.114217790925,139,,Lusignan,Lusignan,86,Vienne,75,Nouvelle-Aquitaine
86140,LUSSAC LES CHATEAUX,86320,LUSSAC LES CHATEAUX,,46.4057829015,0.730122613714,140,,Lussac-les-Châteaux,Lussac-les-Châteaux,86,Vienne,75,Nouvelle-Aquitaine
86141,MAGNE,86160,MAGNE,,46.3496002634,0.391444598255,141,,Magné,Magné,86,Vienne,75,Nouvelle-Aquitaine
86142,MAILLE,86190,MAILLE,,46.6780011133,0.0958034977141,142,,Maillé,Maillé,86,Vienne,75,Nouvelle-Aquitaine
86143,MAIRE,86270,MAIRE,,46.8576358517,0.717189204675,143,,Mairé,Mairé,86,Vienne,75,Nouvelle-Aquitaine
86144,MAISONNEUVE,86170,MAISONNEUVE,,46.7204355503,0.0541721725551,144,,Maisonneuve,Maisonneuve,86,Vienne,75,Nouvelle-Aquitaine
86145,MARCAY,86370,MARCAY,,46.4706088589,0.225624915429,145,,Marçay,Marçay,86,Vienne,75,Nouvelle-Aquitaine
86146,MARIGNY BRIZAY,86380,MARIGNY BRIZAY,,46.743415638,0.377111610428,146,,Marigny-Brizay,Marigny-Brizay,86,Vienne,75,Nouvelle-Aquitaine
86147,MARIGNY CHEMEREAU,86370,MARIGNY CHEMEREAU,,46.4257668827,0.216813691346,147,,Marigny-Chemereau,Marigny-Chemereau,86,Vienne,75,Nouvelle-Aquitaine
86148,MARNAY,86160,MARNAY,,46.3930188501,0.347317602516,148,,Marnay,Marnay,86,Vienne,75,Nouvelle-Aquitaine
86149,MARTAIZE,86330,MARTAIZE,,46.9199511468,0.0501103603933,149,,Martaizé,Martaizé,86,Vienne,75,Nouvelle-Aquitaine
86150,MASSOGNES,86170,MASSOGNES,,46.7475089271,0.0578073811289,150,,Massognes,Massognes,86,Vienne,75,Nouvelle-Aquitaine
86151,MAULAY,86200,MAULAY,,46.9685118414,0.216139412097,151,,Maulay,Maulay,86,Vienne,75,Nouvelle-Aquitaine
86152,MAUPREVOIR,86460,MAUPREVOIR,,46.1644495651,0.516322833549,152,,Mauprévoir,Mauprévoir,86,Vienne,75,Nouvelle-Aquitaine
86153,MAZEROLLES,86320,MAZEROLLES,,46.3999686416,0.663328917773,153,,Mazerolles,Mazerolles,86,Vienne,75,Nouvelle-Aquitaine
86154,MAZEUIL,86110,MAZEUIL,,46.7923270913,0.0843953214823,154,,Mazeuil,Mazeuil,86,Vienne,75,Nouvelle-Aquitaine
86156,MESSEME,86200,MESSEME,,47.0106163687,0.16882423682,156,,Messemé,Messemé,86,Vienne,75,Nouvelle-Aquitaine
86157,MIGNALOUX BEAUVOIR,86550,MIGNALOUX BEAUVOIR,,46.5483807431,0.415961339413,157,,Mignaloux-Beauvoir,Mignaloux-Beauvoir,86,Vienne,75,Nouvelle-Aquitaine
86158,MIGNE AUXANCES,86440,MIGNE AUXANCES,,46.6320253577,0.304897711078,158,,Migné-Auxances,Migné-Auxances,86,Vienne,75,Nouvelle-Aquitaine
86159,MILLAC,86150,MILLAC,,46.1948553644,0.705514594963,159,,Millac,Millac,86,Vienne,75,Nouvelle-Aquitaine
86160,MIREBEAU,86110,MIREBEAU,,46.7891930308,0.198457182701,160,,Mirebeau,Mirebeau,86,Vienne,75,Nouvelle-Aquitaine
86161,MONCONTOUR,86330,MONCONTOUR,,46.8774293684,0.00356289624888,161,,Moncontour,Moncontour,86,Vienne,75,Nouvelle-Aquitaine
86161,MONCONTOUR,86330,MONCONTOUR,MESSAIS,46.8774293684,0.00356289624888,161,,Moncontour,Moncontour,86,Vienne,75,Nouvelle-Aquitaine
86161,MONCONTOUR,86330,MONCONTOUR,OUZILLY VIGNOLLES,46.8774293684,0.00356289624888,161,,Moncontour,Moncontour,86,Vienne,75,Nouvelle-Aquitaine
86161,MONCONTOUR,86330,MONCONTOUR,ST CHARTRES,46.8774293684,0.00356289624888,161,,Moncontour,Moncontour,86,Vienne,75,Nouvelle-Aquitaine
86162,MONDION,86230,MONDION,,46.9382399083,0.47233536935,162,,Mondion,Mondion,86,Vienne,75,Nouvelle-Aquitaine
86163,MONTAMISE,86360,MONTAMISE,,46.6285498081,0.434064496857,163,,Montamisé,Montamisé,86,Vienne,75,Nouvelle-Aquitaine
86164,MONTHOIRON,86210,MONTHOIRON,,46.7316777375,0.617815304816,164,,Monthoiron,Monthoiron,86,Vienne,75,Nouvelle-Aquitaine
86165,MONTMORILLON,86500,MONTMORILLON,,46.4263978846,0.895458347632,165,,Montmorillon,Montmorillon,86,Vienne,75,Nouvelle-Aquitaine
86166,MONTREUIL BONNIN,86470,MONTREUIL BONNIN,,46.5621327652,0.134568095376,166,,Montreuil-Bonnin,Montreuil-Bonnin,86,Vienne,75,Nouvelle-Aquitaine
86167,MONTS SUR GUESNES,86420,MONTS SUR GUESNES,,46.9128254124,0.193721339579,167,,Monts-sur-Guesnes,Monts-sur-Guesnes,86,Vienne,75,Nouvelle-Aquitaine
86169,MORTON,86120,MORTON,,47.1033528602,-0.0128216662358,169,,Morton,Morton,86,Vienne,75,Nouvelle-Aquitaine
86170,MOULISMES,86500,MOULISMES,,46.3242835115,0.807504561259,170,,Moulismes,Moulismes,86,Vienne,75,Nouvelle-Aquitaine
86171,MOUSSAC,86150,MOUSSAC,,46.276159957,0.691938825774,171,,Moussac,Moussac,86,Vienne,75,Nouvelle-Aquitaine
86172,MOUTERRE SUR BLOURDE,86430,MOUTERRE SUR BLOURDE,,46.216506603,0.768458601228,172,,Mouterre-sur-Blourde,Mouterre-sur-Blourde,86,Vienne,75,Nouvelle-Aquitaine
86173,MOUTERRE SILLY,86200,MOUTERRE SILLY,,46.9862436522,0.0369324238359,173,,Mouterre-Silly,Mouterre-Silly,86,Vienne,75,Nouvelle-Aquitaine
86174,NAINTRE,86530,NAINTRE,,46.7725487578,0.494183225778,174,,Naintré,Naintré,86,Vienne,75,Nouvelle-Aquitaine
86175,NALLIERS,86310,NALLIERS,,46.6098862089,0.879990310678,175,,Nalliers,Nalliers,86,Vienne,75,Nouvelle-Aquitaine
86176,NERIGNAC,86150,NERIGNAC,,46.2968665882,0.730725173881,176,,Nérignac,Nérignac,86,Vienne,75,Nouvelle-Aquitaine
86177,NEUVILLE DE POITOU,86170,NEUVILLE DE POITOU,,46.6823102624,0.254645073675,177,,Neuville-de-Poitou,Neuville-de-Poitou,86,Vienne,75,Nouvelle-Aquitaine
86178,NIEUIL L ESPOIR,86340,NIEUIL L ESPOIR,,46.4856610471,0.464374544179,178,,Nieuil-l'Espoir,Nieuil-l'Espoir,86,Vienne,75,Nouvelle-Aquitaine
86180,NOUAILLE MAUPERTUIS,86340,NOUAILLE MAUPERTUIS,,46.5052879632,0.418002332762,180,,Nouaillé-Maupertuis,Nouaillé-Maupertuis,86,Vienne,75,Nouvelle-Aquitaine
86181,NUEIL SOUS FAYE,86200,NUEIL SOUS FAYE,,46.9746472974,0.272538169916,181,,Nueil-sous-Faye,Nueil-sous-Faye,86,Vienne,75,Nouvelle-Aquitaine
86182,ORCHES,86230,ORCHES,,46.8823632651,0.327354894566,182,,Orches,Orches,86,Vienne,75,Nouvelle-Aquitaine
86183,LES ORMES,86220,LES ORMES,,46.9649927352,0.622269662308,183,Les,Ormes,Les Ormes,86,Vienne,75,Nouvelle-Aquitaine
86184,OUZILLY,86380,OUZILLY,,46.7754878528,0.357821774595,184,,Ouzilly,Ouzilly,86,Vienne,75,Nouvelle-Aquitaine
86186,OYRE,86220,OYRE,,46.8626653271,0.646321039276,186,,Oyré,Oyré,86,Vienne,75,Nouvelle-Aquitaine
86187,PAIZAY LE SEC,86300,PAIZAY LE SEC,,46.5865795842,0.78017018726,187,,Paizay-le-Sec,Paizay-le-Sec,86,Vienne,75,Nouvelle-Aquitaine
86188,PAYRE,86700,PAYRE,,46.35015698,0.189782511549,188,,Payré,Payré,86,Vienne,75,Nouvelle-Aquitaine
86189,PAYROUX,86350,PAYROUX,,46.2097919073,0.479434214777,189,,Payroux,Payroux,86,Vienne,75,Nouvelle-Aquitaine
86190,PERSAC,86320,PERSAC,,46.3410990682,0.731125498359,190,,Persac,Persac,86,Vienne,75,Nouvelle-Aquitaine
86191,PINDRAY,86500,PINDRAY,,46.4740182126,0.815962349411,191,,Pindray,Pindray,86,Vienne,75,Nouvelle-Aquitaine
86192,PLAISANCE,86500,PLAISANCE,,46.3144693498,0.854061453997,192,,Plaisance,Plaisance,86,Vienne,75,Nouvelle-Aquitaine
86193,PLEUMARTIN,86450,PLEUMARTIN,,46.7414633324,0.759420018765,193,,Pleumartin,Pleumartin,86,Vienne,75,Nouvelle-Aquitaine
86194,POITIERS,86000,POITIERS,,46.5839207726,0.359947653003,194,,Poitiers,Poitiers,86,Vienne,75,Nouvelle-Aquitaine
86195,PORT DE PILES,86220,PORT DE PILES,,46.9999667755,0.59772473181,195,,Port-de-Piles,Port-de-Piles,86,Vienne,75,Nouvelle-Aquitaine
86196,POUANCAY,86120,POUANCAY,,47.08788601,-0.0810916620199,196,,Pouançay,Pouançay,86,Vienne,75,Nouvelle-Aquitaine
86197,POUANT,86200,POUANT,,47.0188564261,0.278071701384,197,,Pouant,Pouant,86,Vienne,75,Nouvelle-Aquitaine
86198,POUILLE,86800,POUILLE,,46.5420045023,0.578924687595,198,,Pouillé,Pouillé,86,Vienne,75,Nouvelle-Aquitaine
86200,PRESSAC,86460,PRESSAC,,46.1182546564,0.567316828463,200,,Pressac,Pressac,86,Vienne,75,Nouvelle-Aquitaine
86201,PRINCAY,86420,PRINCAY,,46.9289774841,0.253952307477,201,,Prinçay,Prinçay,86,Vienne,75,Nouvelle-Aquitaine
86202,LA PUYE,86260,LA PUYE,,46.6471428238,0.745621548258,202,La,Puye,La Puye,86,Vienne,75,Nouvelle-Aquitaine
86203,QUEAUX,86150,QUEAUX,,46.3123958137,0.636100980987,203,,Queaux,Queaux,86,Vienne,75,Nouvelle-Aquitaine
86204,QUINCAY,86190,QUINCAY,,46.6055316956,0.218304769968,204,,Quinçay,Quinçay,86,Vienne,75,Nouvelle-Aquitaine
86205,RANTON,86200,RANTON,,46.9989197523,-0.0327224982621,205,,Ranton,Ranton,86,Vienne,75,Nouvelle-Aquitaine
86206,RASLAY,86120,RASLAY,,47.1122395658,0.00510983218856,206,,Raslay,Raslay,86,Vienne,75,Nouvelle-Aquitaine
86207,LA ROCHE POSAY,86270,LA ROCHE POSAY,,46.7754623113,0.80240732874,207,La,Roche-Posay,La Roche-Posay,86,Vienne,75,Nouvelle-Aquitaine
86208,LE ROCHEREAU,86170,LE ROCHEREAU,,46.6948066486,0.140781690742,208,Le,Rochereau,Le Rochereau,86,Vienne,75,Nouvelle-Aquitaine
86209,ROCHES PREMARIE ANDILLE,86340,ROCHES PREMARIE ANDILLE,,46.4752381283,0.36777654204,209,,Roches-Prémarie-Andillé,Roches-Prémarie-Andillé,86,Vienne,75,Nouvelle-Aquitaine
86210,ROIFFE,86120,ROIFFE,,47.1252100778,0.0517426061907,210,,Roiffé,Roiffé,86,Vienne,75,Nouvelle-Aquitaine
86211,ROMAGNE,86700,ROMAGNE,,46.2580654377,0.303101746455,211,,Romagne,Romagne,86,Vienne,75,Nouvelle-Aquitaine
86213,ROUILLE,86480,ROUILLE,,46.4200390624,0.0268226076765,213,,Rouillé,Rouillé,86,Vienne,75,Nouvelle-Aquitaine
86214,ST BENOIT,86280,ST BENOIT,,46.5486469496,0.352443691442,214,,Saint-Benoît,Saint-Benoît,86,Vienne,75,Nouvelle-Aquitaine
86217,ST CHRISTOPHE,86230,ST CHRISTOPHE,,46.9252807285,0.374730280796,217,,Saint-Christophe,Saint-Christophe,86,Vienne,75,Nouvelle-Aquitaine
86218,ST CLAIR,86330,ST CLAIR,,46.881517057,0.0572109516962,218,,Saint-Clair,Saint-Clair,86,Vienne,75,Nouvelle-Aquitaine
86219,ST CYR,86130,ST CYR,,46.7134974842,0.476705992673,219,,Saint-Cyr,Saint-Cyr,86,Vienne,75,Nouvelle-Aquitaine
86220,ST GAUDENT,86400,ST GAUDENT,,46.1135869026,0.285066310291,220,,Saint-Gaudent,Saint-Gaudent,86,Vienne,75,Nouvelle-Aquitaine
86221,ST GENEST D AMBIERE,86140,ST GENEST D AMBIERE,,46.8280251017,0.360852837882,221,,Saint-Genest-d'Ambière,Saint-Genest-d'Ambière,86,Vienne,75,Nouvelle-Aquitaine
86222,ST GEORGES LES BAILLARGEAUX,86130,ST GEORGES LES BAILLARGEAUX,,46.6640015374,0.435326068865,222,,Saint-Georges-lès-Baillargeaux,Saint-Georges-lès-Baillargeaux,86,Vienne,75,Nouvelle-Aquitaine
86223,ST GERMAIN,86310,ST GERMAIN,,46.5761645622,0.900631696992,223,,Saint-Germain,Saint-Germain,86,Vienne,75,Nouvelle-Aquitaine
86224,ST GERVAIS LES TROIS CLOCHERS,86230,ST GERVAIS LES TROIS CLOCHERS,,46.8978345049,0.419446005432,224,,Saint-Gervais-les-Trois-Clochers,Saint-Gervais-les-Trois-Clochers,86,Vienne,75,Nouvelle-Aquitaine
86225,ST JEAN DE SAUVES,86330,ST JEAN DE SAUVES,,46.8393708515,0.0880509960566,225,,Saint-Jean-de-Sauves,Saint-Jean-de-Sauves,86,Vienne,75,Nouvelle-Aquitaine
86225,ST JEAN DE SAUVES,86330,ST JEAN DE SAUVES,FRONTENAY SUR DIVE,46.8393708515,0.0880509960566,225,,Saint-Jean-de-Sauves,Saint-Jean-de-Sauves,86,Vienne,75,Nouvelle-Aquitaine
86226,ST JULIEN L ARS,86800,ST JULIEN L ARS,,46.5546778015,0.506618351155,226,,Saint-Julien-l'Ars,Saint-Julien-l'Ars,86,Vienne,75,Nouvelle-Aquitaine
86227,ST LAON,86200,ST LAON,,46.968403494,-0.0199916630055,227,,Saint-Laon,Saint-Laon,86,Vienne,75,Nouvelle-Aquitaine
86228,ST LAURENT DE JOURDES,86410,ST LAURENT DE JOURDES,,46.390727985,0.539195755416,228,,Saint-Laurent-de-Jourdes,Saint-Laurent-de-Jourdes,86,Vienne,75,Nouvelle-Aquitaine
86229,ST LEGER DE MONTBRILLAIS,86120,ST LEGER DE MONTBRILLAIS,,47.0801410403,-0.038701548718,229,,Saint-Léger-de-Montbrillais,Saint-Léger-de-Montbrillais,86,Vienne,75,Nouvelle-Aquitaine
86230,ST LEOMER,86290,ST LEOMER,,46.4160960603,0.997218918187,230,,Saint-Léomer,Saint-Léomer,86,Vienne,75,Nouvelle-Aquitaine
86231,ST MACOUX,86400,ST MACOUX,,46.118249319,0.225258448871,231,,Saint-Macoux,Saint-Macoux,86,Vienne,75,Nouvelle-Aquitaine
86233,VALDIVIENNE,86300,VALDIVIENNE,,46.4919287811,0.630612249319,233,,Valdivienne,Valdivienne,86,Vienne,75,Nouvelle-Aquitaine
86233,VALDIVIENNE,86300,VALDIVIENNE,CHAPELLE MORTHEMER,46.4919287811,0.630612249319,233,,Valdivienne,Valdivienne,86,Vienne,75,Nouvelle-Aquitaine
86233,VALDIVIENNE,86300,VALDIVIENNE,MORTHEMER,46.4919287811,0.630612249319,233,,Valdivienne,Valdivienne,86,Vienne,75,Nouvelle-Aquitaine
86233,VALDIVIENNE,86300,VALDIVIENNE,SALLES EN TOULON,46.4919287811,0.630612249319,233,,Valdivienne,Valdivienne,86,Vienne,75,Nouvelle-Aquitaine
86233,VALDIVIENNE,86300,VALDIVIENNE,ST MARTIN LA RIVIERE,46.4919287811,0.630612249319,233,,Valdivienne,Valdivienne,86,Vienne,75,Nouvelle-Aquitaine
86234,ST MARTIN L ARS,86350,ST MARTIN L ARS,,46.2123889925,0.556851383529,234,,Saint-Martin-l'Ars,Saint-Martin-l'Ars,86,Vienne,75,Nouvelle-Aquitaine
86235,ST MAURICE LA CLOUERE,86160,ST MAURICE LA CLOUERE,,46.3880713973,0.448947749507,235,,Saint-Maurice-la-Clouère,Saint-Maurice-la-Clouère,86,Vienne,75,Nouvelle-Aquitaine
86236,ST PIERRE DE MAILLE,86260,ST PIERRE DE MAILLE,,46.6777213024,0.825185186332,236,,Saint-Pierre-de-Maillé,Saint-Pierre-de-Maillé,86,Vienne,75,Nouvelle-Aquitaine
86237,ST PIERRE D EXIDEUIL,86400,ST PIERRE D EXIDEUIL,,46.1597471009,0.258206658722,237,,Saint-Pierre-d'Exideuil,Saint-Pierre-d'Exideuil,86,Vienne,75,Nouvelle-Aquitaine
86239,STE RADEGONDE,86300,STE RADEGONDE,,46.6183112808,0.700038534304,239,,Sainte-Radégonde,Sainte-Radégonde,86,Vienne,75,Nouvelle-Aquitaine
86241,ST REMY SUR CREUSE,86220,ST REMY SUR CREUSE,,46.934761065,0.68067530374,241,,Saint-Rémy-sur-Creuse,Saint-Rémy-sur-Creuse,86,Vienne,75,Nouvelle-Aquitaine
86242,ST ROMAIN,86250,ST ROMAIN,,46.2278846566,0.380138967799,242,,Saint-Romain,Saint-Romain,86,Vienne,75,Nouvelle-Aquitaine
86244,ST SAUVANT,86600,ST SAUVANT,,46.3558019823,0.0781018772297,244,,Saint-Sauvant,Saint-Sauvant,86,Vienne,75,Nouvelle-Aquitaine
86245,SENILLE ST SAUVEUR,86100,SENILLE ST SAUVEUR,,46.8023769572,0.645222775662,245,,Senillé-Saint-Sauveur,Senillé-Saint-Sauveur,86,Vienne,75,Nouvelle-Aquitaine
86245,SENILLE ST SAUVEUR,86100,SENILLE ST SAUVEUR,SENILLE,46.8023769572,0.645222775662,245,,Senillé-Saint-Sauveur,Senillé-Saint-Sauveur,86,Vienne,75,Nouvelle-Aquitaine
86246,ST SAVIN,86310,ST SAVIN,,46.5832866024,0.840222181095,246,,Saint-Savin,Saint-Savin,86,Vienne,75,Nouvelle-Aquitaine
86247,ST SAVIOL,86400,ST SAVIOL,,46.1437005991,0.231045225732,247,,Saint-Saviol,Saint-Saviol,86,Vienne,75,Nouvelle-Aquitaine
86248,ST SECONDIN,86350,ST SECONDIN,,46.3255332953,0.490583315025,248,,Saint-Secondin,Saint-Secondin,86,Vienne,75,Nouvelle-Aquitaine
86249,SAIRES,86420,SAIRES,,46.8901347476,0.231765971356,249,,Saires,Saires,86,Vienne,75,Nouvelle-Aquitaine
86250,SAIX,86120,SAIX,,47.1417376447,0.00673655616709,250,,Saix,Saix,86,Vienne,75,Nouvelle-Aquitaine
86252,SAMMARCOLLES,86200,SAMMARCOLLES,,47.0399718378,0.170367422545,252,,Sammarçolles,Sammarçolles,86,Vienne,75,Nouvelle-Aquitaine
86253,SANXAY,86600,SANXAY,,46.4960318018,-0.00840229241339,253,,Sanxay,Sanxay,86,Vienne,75,Nouvelle-Aquitaine
86254,SAULGE,86500,SAULGE,,46.3667330907,0.865861579177,254,,Saulgé,Saulgé,86,Vienne,75,Nouvelle-Aquitaine
86255,SAVIGNE,86400,SAVIGNE,,46.1709124872,0.330245588195,255,,Savigné,Savigné,86,Vienne,75,Nouvelle-Aquitaine
86256,SAVIGNY LEVESCAULT,86800,SAVIGNY LEVESCAULT,,46.5289656905,0.477909234429,256,,Savigny-Lévescault,Savigny-Lévescault,86,Vienne,75,Nouvelle-Aquitaine
86257,SAVIGNY SOUS FAYE,86140,SAVIGNY SOUS FAYE,,46.8700526474,0.290887370241,257,,Savigny-sous-Faye,Savigny-sous-Faye,86,Vienne,75,Nouvelle-Aquitaine
86258,SCORBE CLAIRVAUX,86140,SCORBE CLAIRVAUX,,46.8070996218,0.410520780394,258,,Scorbé-Clairvaux,Scorbé-Clairvaux,86,Vienne,75,Nouvelle-Aquitaine
86260,SERIGNY,86230,SERIGNY,,46.9268926391,0.316255199259,260,,Sérigny,Sérigny,86,Vienne,75,Nouvelle-Aquitaine
86261,SEVRES ANXAUMONT,86800,SEVRES ANXAUMONT,,46.5739320338,0.46197050544,261,,Sèvres-Anxaumont,Sèvres-Anxaumont,86,Vienne,75,Nouvelle-Aquitaine
86262,SILLARS,86320,SILLARS,,46.4209799569,0.786727577235,262,,Sillars,Sillars,86,Vienne,75,Nouvelle-Aquitaine
86263,SMARVES,86240,SMARVES,,46.5117860933,0.35738527373,263,,Smarves,Smarves,86,Vienne,75,Nouvelle-Aquitaine
86264,SOMMIERES DU CLAIN,86160,SOMMIERES DU CLAIN,,46.2767515848,0.374035555116,264,,Sommières-du-Clain,Sommières-du-Clain,86,Vienne,75,Nouvelle-Aquitaine
86265,SOSSAIS,86230,SOSSAIS,,46.8680162271,0.37661262737,265,,Sossais,Sossais,86,Vienne,75,Nouvelle-Aquitaine
86266,SURIN,86250,SURIN,,46.078667272,0.375623854305,266,,Surin,Surin,86,Vienne,75,Nouvelle-Aquitaine
86268,TERCE,86800,TERCE,,46.514441058,0.554671027183,268,,Tercé,Tercé,86,Vienne,75,Nouvelle-Aquitaine
86269,TERNAY,86120,TERNAY,,47.0351786527,-0.0534363312967,269,,Ternay,Ternay,86,Vienne,75,Nouvelle-Aquitaine
86270,THOLLET,86290,THOLLET,,46.4381388377,1.12576757467,270,,Thollet,Thollet,86,Vienne,75,Nouvelle-Aquitaine
86271,THURAGEAU,86110,THURAGEAU,,46.7777377985,0.268042481696,271,,Thurageau,Thurageau,86,Vienne,75,Nouvelle-Aquitaine
86272,THURE,86540,THURE,,46.8408988705,0.455456315657,272,,Thuré,Thuré,86,Vienne,75,Nouvelle-Aquitaine
86273,LA TRIMOUILLE,86290,LA TRIMOUILLE,,46.4540599175,1.05894947542,273,La,Trimouille,La Trimouille,86,Vienne,75,Nouvelle-Aquitaine
86274,LES TROIS MOUTIERS,86120,LES TROIS MOUTIERS,,47.0614723678,0.0102809057425,274,Les,Trois-Moutiers,Les Trois-Moutiers,86,Vienne,75,Nouvelle-Aquitaine
86275,USSEAU,86230,USSEAU,,46.8867943192,0.494483523463,275,,Usseau,Usseau,86,Vienne,75,Nouvelle-Aquitaine
86276,USSON DU POITOU,86350,USSON DU POITOU,,46.2806798185,0.544510234533,276,,Usson-du-Poitou,Usson-du-Poitou,86,Vienne,75,Nouvelle-Aquitaine
86277,VARENNES,86110,VARENNES,,46.7625392753,0.198803191873,277,,Varennes,Varennes,86,Vienne,75,Nouvelle-Aquitaine
86278,VAUX,86700,VAUX,,46.283148679,0.236630023591,278,,Vaux,Vaux,86,Vienne,75,Nouvelle-Aquitaine
86279,VAUX SUR VIENNE,86220,VAUX SUR VIENNE,,46.9093051898,0.560295416492,279,,Vaux-sur-Vienne,Vaux-sur-Vienne,86,Vienne,75,Nouvelle-Aquitaine
86280,VELLECHES,86230,VELLECHES,,46.9391643097,0.526863341682,280,,Vellèches,Vellèches,86,Vienne,75,Nouvelle-Aquitaine
86281,VENDEUVRE DU POITOU,86380,VENDEUVRE DU POITOU,,46.7257294261,0.312684454169,281,,Saint Martin la Pallu,Saint Martin la Pallu,86,Vienne,75,Nouvelle-Aquitaine
86284,VERNON,86340,VERNON,,46.4308038321,0.481683351308,284,,Vernon,Vernon,86,Vienne,75,Nouvelle-Aquitaine
86285,VERRIERES,86410,VERRIERES,,46.4029509232,0.597094727763,285,,Verrières,Verrières,86,Vienne,75,Nouvelle-Aquitaine
86286,VERRUE,86420,VERRUE,,46.8695202148,0.178625363655,286,,Verrue,Verrue,86,Vienne,75,Nouvelle-Aquitaine
86287,VEZIERES,86120,VEZIERES,,47.0909836743,0.127562035571,287,,Vézières,Vézières,86,Vienne,75,Nouvelle-Aquitaine
86288,VICQ SUR GARTEMPE,86260,VICQ SUR GARTEMPE,,46.726218827,0.843611918005,288,,Vicq-sur-Gartempe,Vicq-sur-Gartempe,86,Vienne,75,Nouvelle-Aquitaine
86289,LE VIGEANT,86150,LE VIGEANT,,46.2207295456,0.637791389497,289,Le,Vigeant,Le Vigeant,86,Vienne,75,Nouvelle-Aquitaine
86290,LA VILLEDIEU DU CLAIN,86340,LA VILLEDIEU DU CLAIN,,46.4559900008,0.390374880157,290,La,Villedieu-du-Clain,La Villedieu-du-Clain,86,Vienne,75,Nouvelle-Aquitaine
86291,VILLEMORT,86310,VILLEMORT,,46.5503223328,0.926975822174,291,,Villemort,Villemort,86,Vienne,75,Nouvelle-Aquitaine
86292,VILLIERS,86190,VILLIERS,,46.6701971212,0.17214970467,292,,Villiers,Villiers,86,Vienne,75,Nouvelle-Aquitaine
86293,VIVONNE,86370,VIVONNE,,46.412040698,0.257005369348,293,,Vivonne,Vivonne,86,Vienne,75,Nouvelle-Aquitaine
86294,VOUILLE,86190,VOUILLE,,46.62150106,0.163409416409,294,,Vouillé,Vouillé,86,Vienne,75,Nouvelle-Aquitaine
86295,VOULEME,86400,VOULEME,,46.1014679335,0.235987087448,295,,Voulême,Voulême,86,Vienne,75,Nouvelle-Aquitaine
86296,VOULON,86700,VOULON,,46.3650497334,0.24044017577,296,,Voulon,Voulon,86,Vienne,75,Nouvelle-Aquitaine
86297,VOUNEUIL SOUS BIARD,86580,VOUNEUIL SOUS BIARD,,46.5792555716,0.270706459664,297,,Vouneuil-sous-Biard,Vouneuil-sous-Biard,86,Vienne,75,Nouvelle-Aquitaine
86297,VOUNEUIL SOUS BIARD,86580,VOUNEUIL SOUS BIARD,POUZIOUX LA JARRIE,46.5792555716,0.270706459664,297,,Vouneuil-sous-Biard,Vouneuil-sous-Biard,86,Vienne,75,Nouvelle-Aquitaine
86298,VOUNEUIL SUR VIENNE,86210,VOUNEUIL SUR VIENNE,,46.7194600079,0.541343502237,298,,Vouneuil-sur-Vienne,Vouneuil-sur-Vienne,86,Vienne,75,Nouvelle-Aquitaine
86299,VOUZAILLES,86170,VOUZAILLES,,46.7134181526,0.0956426803777,299,,Vouzailles,Vouzailles,86,Vienne,75,Nouvelle-Aquitaine
86300,YVERSAY,86170,YVERSAY,,46.6723327836,0.205575527157,300,,Yversay,Yversay,86,Vienne,75,Nouvelle-Aquitaine
87001,AIXE SUR VIENNE,87700,AIXE SUR VIENNE,,45.798115839,1.13256119886,1,,Aixe-sur-Vienne,Aixe-sur-Vienne,87,Haute-Vienne,75,Nouvelle-Aquitaine
87002,AMBAZAC,87240,AMBAZAC,,45.9534226621,1.39930873296,2,,Ambazac,Ambazac,87,Haute-Vienne,75,Nouvelle-Aquitaine
87003,ARNAC LA POSTE,87160,ARNAC LA POSTE,,46.2639834442,1.37338277352,3,,Arnac-la-Poste,Arnac-la-Poste,87,Haute-Vienne,75,Nouvelle-Aquitaine
87004,AUGNE,87120,AUGNE,,45.7719200606,1.70058013967,4,,Augne,Augne,87,Haute-Vienne,75,Nouvelle-Aquitaine
87005,AUREIL,87220,AUREIL,,45.8095050976,1.40129162849,5,,Aureil,Aureil,87,Haute-Vienne,75,Nouvelle-Aquitaine
87006,AZAT LE RIS,87360,AZAT LE RIS,,46.3077799359,1.05737198508,6,,Azat-le-Ris,Azat-le-Ris,87,Haute-Vienne,75,Nouvelle-Aquitaine
87007,BALLEDENT,87290,BALLEDENT,,46.1086784953,1.21629867254,7,,Balledent,Balledent,87,Haute-Vienne,75,Nouvelle-Aquitaine
87008,LA BAZEUGE,87210,LA BAZEUGE,,46.2552096242,1.08391760958,8,La,Bazeuge,La Bazeuge,87,Haute-Vienne,75,Nouvelle-Aquitaine
87009,BEAUMONT DU LAC,87120,BEAUMONT DU LAC,,45.7716133323,1.85480438671,9,,Beaumont-du-Lac,Beaumont-du-Lac,87,Haute-Vienne,75,Nouvelle-Aquitaine
87011,BELLAC,87300,BELLAC,,46.1049122554,1.04116467219,11,,Bellac,Bellac,87,Haute-Vienne,75,Nouvelle-Aquitaine
87012,BERNEUIL,87300,BERNEUIL,,46.0655121435,1.10952207011,12,,Berneuil,Berneuil,87,Haute-Vienne,75,Nouvelle-Aquitaine
87013,BERSAC SUR RIVALIER,87370,BERSAC SUR RIVALIER,,46.077935885,1.41763260464,13,,Bersac-sur-Rivalier,Bersac-sur-Rivalier,87,Haute-Vienne,75,Nouvelle-Aquitaine
87014,BESSINES SUR GARTEMPE,87250,BESSINES SUR GARTEMPE,,46.1088644019,1.3601193629,14,,Bessines-sur-Gartempe,Bessines-sur-Gartempe,87,Haute-Vienne,75,Nouvelle-Aquitaine
87014,BESSINES SUR GARTEMPE,87250,BESSINES SUR GARTEMPE,MORTEROLLES SUR SEMME,46.1088644019,1.3601193629,14,,Bessines-sur-Gartempe,Bessines-sur-Gartempe,87,Haute-Vienne,75,Nouvelle-Aquitaine
87015,BEYNAC,87700,BEYNAC,,45.7677930043,1.1655637429,15,,Beynac,Beynac,87,Haute-Vienne,75,Nouvelle-Aquitaine
87016,LES BILLANGES,87340,LES BILLANGES,,45.977873784,1.53625205794,16,Les,Billanges,Les Billanges,87,Haute-Vienne,75,Nouvelle-Aquitaine
87017,BLANZAC,87300,BLANZAC,,46.1275472261,1.1079991587,17,,Blanzac,Blanzac,87,Haute-Vienne,75,Nouvelle-Aquitaine
87018,BLOND,87300,BLOND,,46.0535299547,1.01712916686,18,,Blond,Blond,87,Haute-Vienne,75,Nouvelle-Aquitaine
87019,BOISSEUIL,87220,BOISSEUIL,,45.7619114827,1.32848172081,19,,Boisseuil,Boisseuil,87,Haute-Vienne,75,Nouvelle-Aquitaine
87020,BONNAC LA COTE,87270,BONNAC LA COTE,,45.9470701507,1.29289430243,20,,Bonnac-la-Côte,Bonnac-la-Côte,87,Haute-Vienne,75,Nouvelle-Aquitaine
87021,BOSMIE L AIGUILLE,87110,BOSMIE L AIGUILLE,,45.7655936715,1.20427660043,21,,Bosmie-l'Aiguille,Bosmie-l'Aiguille,87,Haute-Vienne,75,Nouvelle-Aquitaine
87022,BREUILAUFA,87300,BREUILAUFA,,46.0427652258,1.10929278431,22,,Breuilaufa,Breuilaufa,87,Haute-Vienne,75,Nouvelle-Aquitaine
87023,LE BUIS,87140,LE BUIS,,46.0341686702,1.20438019379,23,Le,Buis,Le Buis,87,Haute-Vienne,75,Nouvelle-Aquitaine
87024,BUJALEUF,87460,BUJALEUF,,45.792339121,1.6311809769,24,,Bujaleuf,Bujaleuf,87,Haute-Vienne,75,Nouvelle-Aquitaine
87025,BURGNAC,87800,BURGNAC,,45.7402548508,1.16724967571,25,,Burgnac,Burgnac,87,Haute-Vienne,75,Nouvelle-Aquitaine
87027,BUSSIERE GALANT,87230,BUSSIERE GALANT,,45.6176010167,1.04599414637,27,,Bussière-Galant,Bussière-Galant,87,Haute-Vienne,75,Nouvelle-Aquitaine
87027,BUSSIERE GALANT,87230,BUSSIERE GALANT,ST NICOLAS COURBEFY,45.6176010167,1.04599414637,27,,Bussière-Galant,Bussière-Galant,87,Haute-Vienne,75,Nouvelle-Aquitaine
87028,BUSSIERE POITEVINE,87320,BUSSIERE POITEVINE,,46.2418910166,0.90395962145,28,,Bussière-Poitevine,Bussière-Poitevine,87,Haute-Vienne,75,Nouvelle-Aquitaine
87029,LES CARS,87230,LES CARS,,45.6748528482,1.07139196993,29,Les,Cars,Les Cars,87,Haute-Vienne,75,Nouvelle-Aquitaine
87030,CHAILLAC SUR VIENNE,87200,CHAILLAC SUR VIENNE,,45.8634763342,0.875471020076,30,,Chaillac-sur-Vienne,Chaillac-sur-Vienne,87,Haute-Vienne,75,Nouvelle-Aquitaine
87031,LE CHALARD,87500,LE CHALARD,,45.5540861274,1.12489435549,31,Le,Chalard,Le Chalard,87,Haute-Vienne,75,Nouvelle-Aquitaine
87032,CHALUS,87230,CHALUS,,45.6572392985,0.964687507808,32,,Châlus,Châlus,87,Haute-Vienne,75,Nouvelle-Aquitaine
87033,CHAMBORET,87140,CHAMBORET,,46.0056617581,1.12492081757,33,,Chamboret,Chamboret,87,Haute-Vienne,75,Nouvelle-Aquitaine
87034,CHAMPAGNAC LA RIVIERE,87150,CHAMPAGNAC LA RIVIERE,,45.6948820019,0.913114020173,34,,Champagnac-la-Rivière,Champagnac-la-Rivière,87,Haute-Vienne,75,Nouvelle-Aquitaine
87035,CHAMPNETERY,87400,CHAMPNETERY,,45.8270852921,1.57880125346,35,,Champnétery,Champnétery,87,Haute-Vienne,75,Nouvelle-Aquitaine
87036,CHAMPSAC,87230,CHAMPSAC,,45.7039235898,0.962300272807,36,,Champsac,Champsac,87,Haute-Vienne,75,Nouvelle-Aquitaine
87037,LA CHAPELLE MONTBRANDEIX,87440,LA CHAPELLE MONTBRANDEIX,,45.6447907702,0.853394327923,37,La,Chapelle-Montbrandeix,La Chapelle-Montbrandeix,87,Haute-Vienne,75,Nouvelle-Aquitaine
87038,CHAPTELAT,87270,CHAPTELAT,,45.9176663551,1.24668895205,38,,Chaptelat,Chaptelat,87,Haute-Vienne,75,Nouvelle-Aquitaine
87039,CHATEAU CHERVIX,87380,CHATEAU CHERVIX,,45.5905089648,1.35649971998,39,,Château-Chervix,Château-Chervix,87,Haute-Vienne,75,Nouvelle-Aquitaine
87040,CHATEAUNEUF LA FORET,87130,CHATEAUNEUF LA FORET,,45.6947594412,1.61002083984,40,,Châteauneuf-la-Forêt,Châteauneuf-la-Forêt,87,Haute-Vienne,75,Nouvelle-Aquitaine
87041,CHATEAUPONSAC,87290,CHATEAUPONSAC,,46.1311583678,1.28041965042,41,,Châteauponsac,Châteauponsac,87,Haute-Vienne,75,Nouvelle-Aquitaine
87042,LE CHATENET EN DOGNON,87400,LE CHATENET EN DOGNON,,45.9046069243,1.50535263166,42,Le,Châtenet-en-Dognon,Le Châtenet-en-Dognon,87,Haute-Vienne,75,Nouvelle-Aquitaine
87043,CHEISSOUX,87460,CHEISSOUX,,45.831716182,1.63811418482,43,,Cheissoux,Cheissoux,87,Haute-Vienne,75,Nouvelle-Aquitaine
87044,CHERONNAC,87600,CHERONNAC,,45.7566840064,0.753584510197,44,,Chéronnac,Chéronnac,87,Haute-Vienne,75,Nouvelle-Aquitaine
87045,CIEUX,87520,CIEUX,,45.9859461693,1.02906583716,45,,Cieux,Cieux,87,Haute-Vienne,75,Nouvelle-Aquitaine
87046,COGNAC LA FORET,87310,COGNAC LA FORET,,45.8367288342,1.00502296001,46,,Cognac-la-Forêt,Cognac-la-Forêt,87,Haute-Vienne,75,Nouvelle-Aquitaine
87047,COMPREIGNAC,87140,COMPREIGNAC,,45.9974067585,1.28161840012,47,,Compreignac,Compreignac,87,Haute-Vienne,75,Nouvelle-Aquitaine
87048,CONDAT SUR VIENNE,87920,CONDAT SUR VIENNE,,45.7833260801,1.2400833862,48,,Condat-sur-Vienne,Condat-sur-Vienne,87,Haute-Vienne,75,Nouvelle-Aquitaine
87049,COUSSAC BONNEVAL,87500,COUSSAC BONNEVAL,,45.51678588,1.32396460585,49,,Coussac-Bonneval,Coussac-Bonneval,87,Haute-Vienne,75,Nouvelle-Aquitaine
87050,COUZEIX,87270,COUZEIX,,45.8830768403,1.22078165066,50,,Couzeix,Couzeix,87,Haute-Vienne,75,Nouvelle-Aquitaine
87051,LA CROISILLE SUR BRIANCE,87130,LA CROISILLE SUR BRIANCE,,45.6190453687,1.60371585953,51,La,Croisille-sur-Briance,La Croisille-sur-Briance,87,Haute-Vienne,75,Nouvelle-Aquitaine
87052,LA CROIX SUR GARTEMPE,87210,LA CROIX SUR GARTEMPE,,46.1604229513,1.00238441381,52,La,Croix-sur-Gartempe,La Croix-sur-Gartempe,87,Haute-Vienne,75,Nouvelle-Aquitaine
87053,CROMAC,87160,CROMAC,,46.3601964498,1.31102488604,53,,Cromac,Cromac,87,Haute-Vienne,75,Nouvelle-Aquitaine
87054,CUSSAC,87150,CUSSAC,,45.6906747183,0.849177803475,54,,Cussac,Cussac,87,Haute-Vienne,75,Nouvelle-Aquitaine
87055,DARNAC,87320,DARNAC,,46.2386108892,0.956698173585,55,,Darnac,Darnac,87,Haute-Vienne,75,Nouvelle-Aquitaine
87056,DINSAC,87210,DINSAC,,46.240726366,1.11014815937,56,,Dinsac,Dinsac,87,Haute-Vienne,75,Nouvelle-Aquitaine
87057,DOMPIERRE LES EGLISES,87190,DOMPIERRE LES EGLISES,,46.2151282851,1.24961324009,57,,Dompierre-les-Églises,Dompierre-les-Églises,87,Haute-Vienne,75,Nouvelle-Aquitaine
87058,DOMPS,87120,DOMPS,,45.6554872653,1.71232934582,58,,Domps,Domps,87,Haute-Vienne,75,Nouvelle-Aquitaine
87059,LE DORAT,87210,LE DORAT,,46.2050923809,1.06774671416,59,Le,Dorat,Le Dorat,87,Haute-Vienne,75,Nouvelle-Aquitaine
87060,DOURNAZAC,87230,DOURNAZAC,,45.629584088,0.918572363305,60,,Dournazac,Dournazac,87,Haute-Vienne,75,Nouvelle-Aquitaine
87061,DROUX,87190,DROUX,,46.1652051939,1.14857191289,61,,Droux,Droux,87,Haute-Vienne,75,Nouvelle-Aquitaine
87062,EYBOULEUF,87400,EYBOULEUF,,45.8001093389,1.48517359116,62,,Eybouleuf,Eybouleuf,87,Haute-Vienne,75,Nouvelle-Aquitaine
87063,EYJEAUX,87220,EYJEAUX,,45.7748713426,1.37728404596,63,,Eyjeaux,Eyjeaux,87,Haute-Vienne,75,Nouvelle-Aquitaine
87064,EYMOUTIERS,87120,EYMOUTIERS,,45.710854305,1.74020017189,64,,Eymoutiers,Eymoutiers,87,Haute-Vienne,75,Nouvelle-Aquitaine
87065,FEYTIAT,87220,FEYTIAT,,45.8054368217,1.32945260238,65,,Feytiat,Feytiat,87,Haute-Vienne,75,Nouvelle-Aquitaine
87066,FLAVIGNAC,87230,FLAVIGNAC,,45.7128781715,1.0737357287,66,,Flavignac,Flavignac,87,Haute-Vienne,75,Nouvelle-Aquitaine
87067,FOLLES,87250,FOLLES,,46.1248930681,1.45625971523,67,,Folles,Folles,87,Haute-Vienne,75,Nouvelle-Aquitaine
87068,FROMENTAL,87250,FROMENTAL,,46.1583680673,1.41900576351,68,,Fromental,Fromental,87,Haute-Vienne,75,Nouvelle-Aquitaine
87069,GAJOUBERT,87330,GAJOUBERT,,46.1097751696,0.844058767446,69,,Gajoubert,Gajoubert,87,Haute-Vienne,75,Nouvelle-Aquitaine
87070,LA GENEYTOUSE,87400,LA GENEYTOUSE,,45.7872366834,1.45422781567,70,La,Geneytouse,La Geneytouse,87,Haute-Vienne,75,Nouvelle-Aquitaine
87071,GLANDON,87500,GLANDON,,45.4720566316,1.22851251134,71,,Glandon,Glandon,87,Haute-Vienne,75,Nouvelle-Aquitaine
87072,GLANGES,87380,GLANGES,,45.659742237,1.46090261076,72,,Glanges,Glanges,87,Haute-Vienne,75,Nouvelle-Aquitaine
87073,GORRE,87310,GORRE,,45.7376552254,0.995309472743,73,,Gorre,Gorre,87,Haute-Vienne,75,Nouvelle-Aquitaine
87074,LES GRANDS CHEZEAUX,87160,LES GRANDS CHEZEAUX,,46.3560575334,1.38436759782,74,Les,Grands-Chézeaux,Les Grands-Chézeaux,87,Haute-Vienne,75,Nouvelle-Aquitaine
87075,ISLE,87170,ISLE,,45.8035870921,1.19524326117,75,,Isle,Isle,87,Haute-Vienne,75,Nouvelle-Aquitaine
87076,JABREILLES LES BORDES,87370,JABREILLES LES BORDES,,46.0240636032,1.51487992944,76,,Jabreilles-les-Bordes,Jabreilles-les-Bordes,87,Haute-Vienne,75,Nouvelle-Aquitaine
87077,JANAILHAC,87800,JANAILHAC,,45.653878347,1.24126604964,77,,Janailhac,Janailhac,87,Haute-Vienne,75,Nouvelle-Aquitaine
87078,JAVERDAT,87520,JAVERDAT,,45.9568008908,0.965240651874,78,,Javerdat,Javerdat,87,Haute-Vienne,75,Nouvelle-Aquitaine
87079,LA JONCHERE ST MAURICE,87340,LA JONCHERE ST MAURICE,,46.0045426024,1.478589047,79,La,Jonchère-Saint-Maurice,La Jonchère-Saint-Maurice,87,Haute-Vienne,75,Nouvelle-Aquitaine
87080,JOUAC,87890,JOUAC,,46.3495733978,1.25939888968,80,,Jouac,Jouac,87,Haute-Vienne,75,Nouvelle-Aquitaine
87081,JOURGNAC,87800,JOURGNAC,,45.7317643416,1.21288397777,81,,Jourgnac,Jourgnac,87,Haute-Vienne,75,Nouvelle-Aquitaine
87082,LADIGNAC LE LONG,87500,LADIGNAC LE LONG,,45.5883075573,1.10742594312,82,,Ladignac-le-Long,Ladignac-le-Long,87,Haute-Vienne,75,Nouvelle-Aquitaine
87083,LAURIERE,87370,LAURIERE,,46.0760741278,1.50106082876,83,,Laurière,Laurière,87,Haute-Vienne,75,Nouvelle-Aquitaine
87084,LAVIGNAC,87230,LAVIGNAC,,45.7194893338,1.12198041488,84,,Lavignac,Lavignac,87,Haute-Vienne,75,Nouvelle-Aquitaine
87085,LIMOGES,87000,LIMOGES,,45.8542549589,1.2487579024,85,,Limoges,Limoges,87,Haute-Vienne,75,Nouvelle-Aquitaine
87085,LIMOGES,87100,LIMOGES,,45.8542549589,1.2487579024,85,,Limoges,Limoges,87,Haute-Vienne,75,Nouvelle-Aquitaine
87085,LIMOGES,87100,LIMOGES,LANDOUGE,45.8542549589,1.2487579024,85,,Limoges,Limoges,87,Haute-Vienne,75,Nouvelle-Aquitaine
87085,LIMOGES,87280,LIMOGES,,45.8542549589,1.2487579024,85,,Limoges,Limoges,87,Haute-Vienne,75,Nouvelle-Aquitaine
87085,LIMOGES,87280,LIMOGES,BEAUNE LES MINES,45.8542549589,1.2487579024,85,,Limoges,Limoges,87,Haute-Vienne,75,Nouvelle-Aquitaine
87086,LINARDS,87130,LINARDS,,45.7038935854,1.5375026125,86,,Linards,Linards,87,Haute-Vienne,75,Nouvelle-Aquitaine
87087,LUSSAC LES EGLISES,87360,LUSSAC LES EGLISES,,46.341752819,1.17591592466,87,,Lussac-les-Églises,Lussac-les-Églises,87,Haute-Vienne,75,Nouvelle-Aquitaine
87088,MAGNAC BOURG,87380,MAGNAC BOURG,,45.6008431539,1.43562609799,88,,Magnac-Bourg,Magnac-Bourg,87,Haute-Vienne,75,Nouvelle-Aquitaine
87089,MAGNAC LAVAL,87190,MAGNAC LAVAL,,46.2285207281,1.17281064947,89,,Magnac-Laval,Magnac-Laval,87,Haute-Vienne,75,Nouvelle-Aquitaine
87090,MAILHAC SUR BENAIZE,87160,MAILHAC SUR BENAIZE,,46.3105169411,1.31571344485,90,,Mailhac-sur-Benaize,Mailhac-sur-Benaize,87,Haute-Vienne,75,Nouvelle-Aquitaine
87091,MAISONNAIS SUR TARDOIRE,87440,MAISONNAIS SUR TARDOIRE,,45.7119603941,0.683803927182,91,,Maisonnais-sur-Tardoire,Maisonnais-sur-Tardoire,87,Haute-Vienne,75,Nouvelle-Aquitaine
87092,MARVAL,87440,MARVAL,,45.6348755661,0.7960449348,92,,Marval,Marval,87,Haute-Vienne,75,Nouvelle-Aquitaine
87092,MARVAL,87440,MARVAL,MILHAGUET,45.6348755661,0.7960449348,92,,Marval,Marval,87,Haute-Vienne,75,Nouvelle-Aquitaine
87093,MASLEON,87130,MASLEON,,45.7667545003,1.57463204916,93,,Masléon,Masléon,87,Haute-Vienne,75,Nouvelle-Aquitaine
87094,MEILHAC,87800,MEILHAC,,45.7117307166,1.15756175442,94,,Meilhac,Meilhac,87,Haute-Vienne,75,Nouvelle-Aquitaine
87095,MEUZAC,87380,MEUZAC,,45.553088918,1.4167411148,95,,Meuzac,Meuzac,87,Haute-Vienne,75,Nouvelle-Aquitaine
87096,LA MEYZE,87800,LA MEYZE,,45.6078683969,1.19432359478,96,La,Meyze,La Meyze,87,Haute-Vienne,75,Nouvelle-Aquitaine
87097,VAL D ISSOIRE,87330,VAL D ISSOIRE,BUSSIERE BOFFY,46.1111458501,0.914010969665,97,,Val d'Issoire,Val d'Issoire,87,Haute-Vienne,75,Nouvelle-Aquitaine
87097,VAL D ISSOIRE,87330,VAL D ISSOIRE,MEZIERES SUR ISSOIRE,46.1111458501,0.914010969665,97,,Val d'Issoire,Val d'Issoire,87,Haute-Vienne,75,Nouvelle-Aquitaine
87099,MOISSANNES,87400,MOISSANNES,,45.8745025564,1.55616505149,99,,Moissannes,Moissannes,87,Haute-Vienne,75,Nouvelle-Aquitaine
87100,MONTROL SENARD,87330,MONTROL SENARD,,46.0172432009,0.952770711759,100,,Montrol-Sénard,Montrol-Sénard,87,Haute-Vienne,75,Nouvelle-Aquitaine
87101,MORTEMART,87330,MORTEMART,,46.0484465758,0.95503235222,101,,Mortemart,Mortemart,87,Haute-Vienne,75,Nouvelle-Aquitaine
87103,NANTIAT,87140,NANTIAT,,46.0110925335,1.17367841667,103,,Nantiat,Nantiat,87,Haute-Vienne,75,Nouvelle-Aquitaine
87104,NEDDE,87120,NEDDE,,45.7207057772,1.82658375084,104,,Nedde,Nedde,87,Haute-Vienne,75,Nouvelle-Aquitaine
87105,NEUVIC ENTIER,87130,NEUVIC ENTIER,,45.7370504986,1.63875195833,105,,Neuvic-Entier,Neuvic-Entier,87,Haute-Vienne,75,Nouvelle-Aquitaine
87106,NEXON,87800,NEXON,,45.6749732411,1.18567878559,106,,Nexon,Nexon,87,Haute-Vienne,75,Nouvelle-Aquitaine
87107,NIEUL,87510,NIEUL,,45.9214559183,1.18018067279,107,,Nieul,Nieul,87,Haute-Vienne,75,Nouvelle-Aquitaine
87108,NOUIC,87330,NOUIC,,46.0620120225,0.915164140935,108,,Nouic,Nouic,87,Haute-Vienne,75,Nouvelle-Aquitaine
87109,ORADOUR ST GENEST,87210,ORADOUR ST GENEST,,46.2424604535,1.01968874073,109,,Oradour-Saint-Genest,Oradour-Saint-Genest,87,Haute-Vienne,75,Nouvelle-Aquitaine
87110,ORADOUR SUR GLANE,87520,ORADOUR SUR GLANE,,45.9310728054,1.02303381376,110,,Oradour-sur-Glane,Oradour-sur-Glane,87,Haute-Vienne,75,Nouvelle-Aquitaine
87111,ORADOUR SUR VAYRES,87150,ORADOUR SUR VAYRES,,45.74094203,0.879569821515,111,,Oradour-sur-Vayres,Oradour-sur-Vayres,87,Haute-Vienne,75,Nouvelle-Aquitaine
87112,PAGEAS,87230,PAGEAS,,45.6827360451,1.01676119087,112,,Pageas,Pageas,87,Haute-Vienne,75,Nouvelle-Aquitaine
87113,LE PALAIS SUR VIENNE,87410,LE PALAIS SUR VIENNE,,45.8734595202,1.32526799595,113,Le,Palais-sur-Vienne,Le Palais-sur-Vienne,87,Haute-Vienne,75,Nouvelle-Aquitaine
87114,PANAZOL,87350,PANAZOL,,45.8407332178,1.32357791715,114,,Panazol,Panazol,87,Haute-Vienne,75,Nouvelle-Aquitaine
87115,PENSOL,87440,PENSOL,,45.5997290212,0.828238655778,115,,Pensol,Pensol,87,Haute-Vienne,75,Nouvelle-Aquitaine
87116,PEYRAT DE BELLAC,87300,PEYRAT DE BELLAC,,46.1315030493,1.02033947545,116,,Peyrat-de-Bellac,Peyrat-de-Bellac,87,Haute-Vienne,75,Nouvelle-Aquitaine
87117,PEYRAT LE CHATEAU,87470,PEYRAT LE CHATEAU,,45.8082560026,1.7824868831,117,,Peyrat-le-Château,Peyrat-le-Château,87,Haute-Vienne,75,Nouvelle-Aquitaine
87118,PEYRILHAC,87510,PEYRILHAC,,45.9617192828,1.12081829671,118,,Peyrilhac,Peyrilhac,87,Haute-Vienne,75,Nouvelle-Aquitaine
87119,PIERRE BUFFIERE,87260,PIERRE BUFFIERE,,45.6911141635,1.36024166965,119,,Pierre-Buffière,Pierre-Buffière,87,Haute-Vienne,75,Nouvelle-Aquitaine
87120,LA PORCHERIE,87380,LA PORCHERIE,,45.5761961108,1.54671471269,120,La,Porcherie,La Porcherie,87,Haute-Vienne,75,Nouvelle-Aquitaine
87121,RANCON,87290,RANCON,,46.1289799327,1.18027706382,121,,Rancon,Rancon,87,Haute-Vienne,75,Nouvelle-Aquitaine
87122,RAZES,87640,RAZES,,46.0371218575,1.34072006273,122,,Razès,Razès,87,Haute-Vienne,75,Nouvelle-Aquitaine
87123,REMPNAT,87120,REMPNAT,,45.6849518305,1.86463083552,123,,Rempnat,Rempnat,87,Haute-Vienne,75,Nouvelle-Aquitaine
87124,RILHAC LASTOURS,87800,RILHAC LASTOURS,,45.6589406753,1.11379948125,124,,Rilhac-Lastours,Rilhac-Lastours,87,Haute-Vienne,75,Nouvelle-Aquitaine
87125,RILHAC RANCON,87570,RILHAC RANCON,,45.9077850023,1.33257526084,125,,Rilhac-Rancon,Rilhac-Rancon,87,Haute-Vienne,75,Nouvelle-Aquitaine
87126,ROCHECHOUART,87600,ROCHECHOUART,,45.8236032815,0.84280512319,126,,Rochechouart,Rochechouart,87,Haute-Vienne,75,Nouvelle-Aquitaine
87127,LA ROCHE L ABEILLE,87800,LA ROCHE L ABEILLE,,45.5937573874,1.25816254853,127,La,Roche-l'Abeille,La Roche-l'Abeille,87,Haute-Vienne,75,Nouvelle-Aquitaine
87128,ROUSSAC,87140,ROUSSAC,,46.0716643434,1.19803600698,128,,Roussac,Roussac,87,Haute-Vienne,75,Nouvelle-Aquitaine
87129,ROYERES,87400,ROYERES,,45.8513527828,1.43458779423,129,,Royères,Royères,87,Haute-Vienne,75,Nouvelle-Aquitaine
87130,ROZIERS ST GEORGES,87130,ROZIERS ST GEORGES,,45.7432695076,1.56065453243,130,,Roziers-Saint-Georges,Roziers-Saint-Georges,87,Haute-Vienne,75,Nouvelle-Aquitaine
87131,SAILLAT SUR VIENNE,87720,SAILLAT SUR VIENNE,,45.8704899446,0.834066842263,131,,Saillat-sur-Vienne,Saillat-sur-Vienne,87,Haute-Vienne,75,Nouvelle-Aquitaine
87132,ST AMAND LE PETIT,87120,ST AMAND LE PETIT,,45.7692929778,1.76751945612,132,,Saint-Amand-le-Petit,Saint-Amand-le-Petit,87,Haute-Vienne,75,Nouvelle-Aquitaine
87133,ST AMAND MAGNAZEIX,87290,ST AMAND MAGNAZEIX,,46.1842804028,1.35791469518,133,,Saint-Amand-Magnazeix,Saint-Amand-Magnazeix,87,Haute-Vienne,75,Nouvelle-Aquitaine
87134,STE ANNE ST PRIEST,87120,STE ANNE ST PRIEST,,45.6978352865,1.68283333119,134,,Sainte-Anne-Saint-Priest,Sainte-Anne-Saint-Priest,87,Haute-Vienne,75,Nouvelle-Aquitaine
87135,ST AUVENT,87310,ST AUVENT,,45.8104090888,0.921077426918,135,,Saint-Auvent,Saint-Auvent,87,Haute-Vienne,75,Nouvelle-Aquitaine
87136,ST BARBANT,87330,ST BARBANT,,46.2020674785,0.853464651387,136,,Saint-Barbant,Saint-Barbant,87,Haute-Vienne,75,Nouvelle-Aquitaine
87137,ST BAZILE,87150,ST BAZILE,,45.7346220066,0.807218203369,137,,Saint-Bazile,Saint-Bazile,87,Haute-Vienne,75,Nouvelle-Aquitaine
87138,ST BONNET BRIANCE,87260,ST BONNET BRIANCE,,45.7152554102,1.48470710425,138,,Saint-Bonnet-Briance,Saint-Bonnet-Briance,87,Haute-Vienne,75,Nouvelle-Aquitaine
87139,ST BONNET DE BELLAC,87300,ST BONNET DE BELLAC,,46.1636002439,0.93811461782,139,,Saint-Bonnet-de-Bellac,Saint-Bonnet-de-Bellac,87,Haute-Vienne,75,Nouvelle-Aquitaine
87140,ST BRICE SUR VIENNE,87200,ST BRICE SUR VIENNE,,45.9023304866,0.963574081829,140,,Saint-Brice-sur-Vienne,Saint-Brice-sur-Vienne,87,Haute-Vienne,75,Nouvelle-Aquitaine
87141,ST CYR,87310,ST CYR,,45.8021449265,0.982111467463,141,,Saint-Cyr,Saint-Cyr,87,Haute-Vienne,75,Nouvelle-Aquitaine
87142,ST DENIS DES MURS,87400,ST DENIS DES MURS,,45.7797308115,1.53011084949,142,,Saint-Denis-des-Murs,Saint-Denis-des-Murs,87,Haute-Vienne,75,Nouvelle-Aquitaine
87143,ST GENCE,87510,ST GENCE,,45.9094679833,1.13938363995,143,,Saint-Gence,Saint-Gence,87,Haute-Vienne,75,Nouvelle-Aquitaine
87144,ST GENEST SUR ROSELLE,87260,ST GENEST SUR ROSELLE,,45.700683139,1.42511195393,144,,Saint-Genest-sur-Roselle,Saint-Genest-sur-Roselle,87,Haute-Vienne,75,Nouvelle-Aquitaine
87145,ST GEORGES LES LANDES,87160,ST GEORGES LES LANDES,,46.3614067916,1.34841876713,145,,Saint-Georges-les-Landes,Saint-Georges-les-Landes,87,Haute-Vienne,75,Nouvelle-Aquitaine
87146,ST GERMAIN LES BELLES,87380,ST GERMAIN LES BELLES,,45.6049909453,1.48606057397,146,,Saint-Germain-les-Belles,Saint-Germain-les-Belles,87,Haute-Vienne,75,Nouvelle-Aquitaine
87147,ST GILLES LES FORETS,87130,ST GILLES LES FORETS,,45.6290760183,1.66694771198,147,,Saint-Gilles-les-Forêts,Saint-Gilles-les-Forêts,87,Haute-Vienne,75,Nouvelle-Aquitaine
87148,ST HILAIRE BONNEVAL,87260,ST HILAIRE BONNEVAL,,45.7218835884,1.3678917188,148,,Saint-Hilaire-Bonneval,Saint-Hilaire-Bonneval,87,Haute-Vienne,75,Nouvelle-Aquitaine
87149,ST HILAIRE LA TREILLE,87190,ST HILAIRE LA TREILLE,,46.2488590739,1.31048173483,149,,Saint-Hilaire-la-Treille,Saint-Hilaire-la-Treille,87,Haute-Vienne,75,Nouvelle-Aquitaine
87150,ST HILAIRE LES PLACES,87800,ST HILAIRE LES PLACES,,45.6362784516,1.15231983928,150,,Saint-Hilaire-les-Places,Saint-Hilaire-les-Places,87,Haute-Vienne,75,Nouvelle-Aquitaine
87151,ST JEAN LIGOURE,87260,ST JEAN LIGOURE,,45.6906508638,1.30783401393,151,,Saint-Jean-Ligoure,Saint-Jean-Ligoure,87,Haute-Vienne,75,Nouvelle-Aquitaine
87152,ST JOUVENT,87510,ST JOUVENT,,45.9566184003,1.20981236101,152,,Saint-Jouvent,Saint-Jouvent,87,Haute-Vienne,75,Nouvelle-Aquitaine
87153,ST JULIEN LE PETIT,87460,ST JULIEN LE PETIT,,45.8226789716,1.70562230623,153,,Saint-Julien-le-Petit,Saint-Julien-le-Petit,87,Haute-Vienne,75,Nouvelle-Aquitaine
87154,ST JUNIEN,87200,ST JUNIEN,,45.9024520048,0.887855844778,154,,Saint-Junien,Saint-Junien,87,Haute-Vienne,75,Nouvelle-Aquitaine
87155,ST JUNIEN LES COMBES,87300,ST JUNIEN LES COMBES,,46.0855473212,1.13424813789,155,,Saint-Junien-les-Combes,Saint-Junien-les-Combes,87,Haute-Vienne,75,Nouvelle-Aquitaine
87156,ST JUST LE MARTEL,87590,ST JUST LE MARTEL,,45.8525030992,1.38689307646,156,,Saint-Just-le-Martel,Saint-Just-le-Martel,87,Haute-Vienne,75,Nouvelle-Aquitaine
87157,ST LAURENT LES EGLISES,87240,ST LAURENT LES EGLISES,,45.9635910312,1.47506553972,157,,Saint-Laurent-les-Églises,Saint-Laurent-les-Églises,87,Haute-Vienne,75,Nouvelle-Aquitaine
87158,ST LAURENT SUR GORRE,87310,ST LAURENT SUR GORRE,,45.7680643586,0.973319205658,158,,Saint-Laurent-sur-Gorre,Saint-Laurent-sur-Gorre,87,Haute-Vienne,75,Nouvelle-Aquitaine
87159,ST LEGER LA MONTAGNE,87340,ST LEGER LA MONTAGNE,,46.0305429413,1.42544791948,159,,Saint-Léger-la-Montagne,Saint-Léger-la-Montagne,87,Haute-Vienne,75,Nouvelle-Aquitaine
87160,ST LEGER MAGNAZEIX,87190,ST LEGER MAGNAZEIX,,46.2880379698,1.23803752439,160,,Saint-Léger-Magnazeix,Saint-Léger-Magnazeix,87,Haute-Vienne,75,Nouvelle-Aquitaine
87161,ST LEONARD DE NOBLAT,87400,ST LEONARD DE NOBLAT,,45.8409102505,1.4960643503,161,,Saint-Léonard-de-Noblat,Saint-Léonard-de-Noblat,87,Haute-Vienne,75,Nouvelle-Aquitaine
87162,STE MARIE DE VAUX,87420,STE MARIE DE VAUX,,45.8624690425,1.04112218143,162,,Sainte-Marie-de-Vaux,Sainte-Marie-de-Vaux,87,Haute-Vienne,75,Nouvelle-Aquitaine
87163,ST MARTIAL SUR ISOP,87330,ST MARTIAL SUR ISOP,,46.1532476453,0.873311624285,163,,Saint-Martial-sur-Isop,Saint-Martial-sur-Isop,87,Haute-Vienne,75,Nouvelle-Aquitaine
87164,ST MARTIN DE JUSSAC,87200,ST MARTIN DE JUSSAC,,45.8608042276,0.943622213531,164,,Saint-Martin-de-Jussac,Saint-Martin-de-Jussac,87,Haute-Vienne,75,Nouvelle-Aquitaine
87165,ST MARTIN LE MAULT,87360,ST MARTIN LE MAULT,,46.3605510633,1.21596116795,165,,Saint-Martin-le-Mault,Saint-Martin-le-Mault,87,Haute-Vienne,75,Nouvelle-Aquitaine
87166,ST MARTIN LE VIEUX,87700,ST MARTIN LE VIEUX,,45.750523916,1.11900095506,166,,Saint-Martin-le-Vieux,Saint-Martin-le-Vieux,87,Haute-Vienne,75,Nouvelle-Aquitaine
87167,ST MARTIN TERRESSUS,87400,ST MARTIN TERRESSUS,,45.914804187,1.4602338555,167,,Saint-Martin-Terressus,Saint-Martin-Terressus,87,Haute-Vienne,75,Nouvelle-Aquitaine
87168,ST MATHIEU,87440,ST MATHIEU,,45.7060063438,0.771764710017,168,,Saint-Mathieu,Saint-Mathieu,87,Haute-Vienne,75,Nouvelle-Aquitaine
87169,ST MAURICE LES BROUSSES,87800,ST MAURICE LES BROUSSES,,45.6997312165,1.24152185921,169,,Saint-Maurice-les-Brousses,Saint-Maurice-les-Brousses,87,Haute-Vienne,75,Nouvelle-Aquitaine
87170,ST MEARD,87130,ST MEARD,,45.6640777038,1.54260179229,170,,Saint-Méard,Saint-Méard,87,Haute-Vienne,75,Nouvelle-Aquitaine
87172,ST OUEN SUR GARTEMPE,87300,ST OUEN SUR GARTEMPE,,46.1733248247,1.0789231125,172,,Saint-Ouen-sur-Gartempe,Saint-Ouen-sur-Gartempe,87,Haute-Vienne,75,Nouvelle-Aquitaine
87173,ST PARDOUX,87250,ST PARDOUX,,46.0638264597,1.29118107836,173,,Saint-Pardoux,Saint-Pardoux,87,Haute-Vienne,75,Nouvelle-Aquitaine
87174,ST PAUL,87260,ST PAUL,,45.7524342928,1.44281385346,174,,Saint-Paul,Saint-Paul,87,Haute-Vienne,75,Nouvelle-Aquitaine
87176,ST PRIEST LIGOURE,87800,ST PRIEST LIGOURE,,45.6262243469,1.300363986,176,,Saint-Priest-Ligoure,Saint-Priest-Ligoure,87,Haute-Vienne,75,Nouvelle-Aquitaine
87177,ST PRIEST SOUS AIXE,87700,ST PRIEST SOUS AIXE,,45.8204298381,1.08391374375,177,,Saint-Priest-sous-Aixe,Saint-Priest-sous-Aixe,87,Haute-Vienne,75,Nouvelle-Aquitaine
87178,ST PRIEST TAURION,87480,ST PRIEST TAURION,,45.8976553193,1.3966265023,178,,Saint-Priest-Taurion,Saint-Priest-Taurion,87,Haute-Vienne,75,Nouvelle-Aquitaine
87179,ST SORNIN LA MARCHE,87210,ST SORNIN LA MARCHE,,46.1904205516,0.992263313197,179,,Saint-Sornin-la-Marche,Saint-Sornin-la-Marche,87,Haute-Vienne,75,Nouvelle-Aquitaine
87180,ST SORNIN LEULAC,87290,ST SORNIN LEULAC,,46.1961790714,1.29766583914,180,,Saint-Sornin-Leulac,Saint-Sornin-Leulac,87,Haute-Vienne,75,Nouvelle-Aquitaine
87180,ST SORNIN LEULAC,87290,ST SORNIN LEULAC,ST PRIEST LE BETOUX,46.1961790714,1.29766583914,180,,Saint-Sornin-Leulac,Saint-Sornin-Leulac,87,Haute-Vienne,75,Nouvelle-Aquitaine
87181,ST SULPICE LAURIERE,87370,ST SULPICE LAURIERE,,46.0553034205,1.4770215698,181,,Saint-Sulpice-Laurière,Saint-Sulpice-Laurière,87,Haute-Vienne,75,Nouvelle-Aquitaine
87182,ST SULPICE LES FEUILLES,87160,ST SULPICE LES FEUILLES,,46.315956063,1.39206207611,182,,Saint-Sulpice-les-Feuilles,Saint-Sulpice-les-Feuilles,87,Haute-Vienne,75,Nouvelle-Aquitaine
87183,ST SYLVESTRE,87240,ST SYLVESTRE,,45.9944455486,1.36513414834,183,,Saint-Sylvestre,Saint-Sylvestre,87,Haute-Vienne,75,Nouvelle-Aquitaine
87184,ST SYMPHORIEN SUR COUZE,87140,ST SYMPHORIEN SUR COUZE,,46.0499456746,1.2387984351,184,,Saint-Symphorien-sur-Couze,Saint-Symphorien-sur-Couze,87,Haute-Vienne,75,Nouvelle-Aquitaine
87185,ST VICTURNIEN,87420,ST VICTURNIEN,,45.8807233781,1.024946383,185,,Saint-Victurnien,Saint-Victurnien,87,Haute-Vienne,75,Nouvelle-Aquitaine
87186,ST VITTE SUR BRIANCE,87380,ST VITTE SUR BRIANCE,,45.6250408506,1.53874001949,186,,Saint-Vitte-sur-Briance,Saint-Vitte-sur-Briance,87,Haute-Vienne,75,Nouvelle-Aquitaine
87187,ST YRIEIX LA PERCHE,87500,ST YRIEIX LA PERCHE,,45.5251111514,1.20386934296,187,,Saint-Yrieix-la-Perche,Saint-Yrieix-la-Perche,87,Haute-Vienne,75,Nouvelle-Aquitaine
87188,ST YRIEIX SOUS AIXE,87700,ST YRIEIX SOUS AIXE,,45.8502841383,1.06391775752,188,,Saint-Yrieix-sous-Aixe,Saint-Yrieix-sous-Aixe,87,Haute-Vienne,75,Nouvelle-Aquitaine
87189,LES SALLES LAVAUGUYON,87440,LES SALLES LAVAUGUYON,,45.7440613913,0.693496428456,189,Les,Salles-Lavauguyon,Les Salles-Lavauguyon,87,Haute-Vienne,75,Nouvelle-Aquitaine
87190,SAUVIAT SUR VIGE,87400,SAUVIAT SUR VIGE,,45.9066917646,1.58854846904,190,,Sauviat-sur-Vige,Sauviat-sur-Vige,87,Haute-Vienne,75,Nouvelle-Aquitaine
87191,SEREILHAC,87620,SEREILHAC,,45.7658422041,1.06004975072,191,,Séreilhac,Séreilhac,87,Haute-Vienne,75,Nouvelle-Aquitaine
87192,SOLIGNAC,87110,SOLIGNAC,,45.7548854905,1.25753601348,192,,Solignac,Solignac,87,Haute-Vienne,75,Nouvelle-Aquitaine
87193,SURDOUX,87130,SURDOUX,,45.605245887,1.65032140968,193,,Surdoux,Surdoux,87,Haute-Vienne,75,Nouvelle-Aquitaine
87194,SUSSAC,87130,SUSSAC,,45.6580687959,1.65195994088,194,,Sussac,Sussac,87,Haute-Vienne,75,Nouvelle-Aquitaine
87195,TERSANNES,87360,TERSANNES,,46.2907796223,1.12756743403,195,,Tersannes,Tersannes,87,Haute-Vienne,75,Nouvelle-Aquitaine
87196,THIAT,87320,THIAT,,46.2745459798,0.969728310358,196,,Thiat,Thiat,87,Haute-Vienne,75,Nouvelle-Aquitaine
87197,THOURON,87140,THOURON,,45.9983550083,1.21994998762,197,,Thouron,Thouron,87,Haute-Vienne,75,Nouvelle-Aquitaine
87198,VAULRY,87140,VAULRY,,46.027608122,1.0838246874,198,,Vaulry,Vaulry,87,Haute-Vienne,75,Nouvelle-Aquitaine
87199,VAYRES,87600,VAYRES,,45.7731821328,0.823738491047,199,,Vayres,Vayres,87,Haute-Vienne,75,Nouvelle-Aquitaine
87200,VERNEUIL MOUSTIERS,87360,VERNEUIL MOUSTIERS,,46.3372063314,1.11643667308,200,,Verneuil-Moustiers,Verneuil-Moustiers,87,Haute-Vienne,75,Nouvelle-Aquitaine
87201,VERNEUIL SUR VIENNE,87430,VERNEUIL SUR VIENNE,,45.8504654455,1.12947772602,201,,Verneuil-sur-Vienne,Verneuil-sur-Vienne,87,Haute-Vienne,75,Nouvelle-Aquitaine
87202,VEYRAC,87520,VEYRAC,,45.9034537819,1.09001899064,202,,Veyrac,Veyrac,87,Haute-Vienne,75,Nouvelle-Aquitaine
87202,VEYRAC,87520,VEYRAC,LA BARRE DE VEYRAC,45.9034537819,1.09001899064,202,,Veyrac,Veyrac,87,Haute-Vienne,75,Nouvelle-Aquitaine
87203,VICQ SUR BREUILH,87260,VICQ SUR BREUILH,,45.6495648123,1.39073762507,203,,Vicq-sur-Breuilh,Vicq-sur-Breuilh,87,Haute-Vienne,75,Nouvelle-Aquitaine
87204,VIDEIX,87600,VIDEIX,,45.7827606968,0.731901083599,204,,Videix,Videix,87,Haute-Vienne,75,Nouvelle-Aquitaine
87205,LE VIGEN,87110,LE VIGEN,,45.7366862945,1.28148315318,205,Le,Vigen,Le Vigen,87,Haute-Vienne,75,Nouvelle-Aquitaine
87206,VILLEFAVARD,87190,VILLEFAVARD,,46.1696846397,1.2141520992,206,,Villefavard,Villefavard,87,Haute-Vienne,75,Nouvelle-Aquitaine
88001,LES ABLEUVENETTES,88270,LES ABLEUVENETTES,,48.1916639061,6.19244312947,1,Les,Ableuvenettes,Les Ableuvenettes,88,Vosges,44,Grand Est
88002,AHEVILLE,88500,AHEVILLE,,48.2846289398,6.19935683316,2,,Ahéville,Ahéville,88,Vosges,44,Grand Est
88003,AINGEVILLE,88140,AINGEVILLE,,48.2051593697,5.76821691233,3,,Aingeville,Aingeville,88,Vosges,44,Grand Est
88004,AINVELLE,88320,AINVELLE,,47.9873795965,5.82891715063,4,,Ainvelle,Ainvelle,88,Vosges,44,Grand Est
88005,ALLARMONT,88110,ALLARMONT,,48.4687180609,7.02039270494,5,,Allarmont,Allarmont,88,Vosges,44,Grand Est
88006,AMBACOURT,88500,AMBACOURT,,48.3519571223,6.13963930684,6,,Ambacourt,Ambacourt,88,Vosges,44,Grand Est
88007,AMEUVELLE,88410,AMEUVELLE,,47.9496470575,5.95257004647,7,,Ameuvelle,Ameuvelle,88,Vosges,44,Grand Est
88008,ANGLEMONT,88700,ANGLEMONT,,48.375772608,6.66844785634,8,,Anglemont,Anglemont,88,Vosges,44,Grand Est
88009,ANOULD,88650,ANOULD,,48.1847716703,6.94070062942,9,,Anould,Anould,88,Vosges,44,Grand Est
88010,AOUZE,88170,AOUZE,,48.3842823677,5.86742713416,10,,Aouze,Aouze,88,Vosges,44,Grand Est
88011,ARCHES,88380,ARCHES,,48.1133336245,6.51405793958,11,,Arches,Arches,88,Vosges,44,Grand Est
88012,ARCHETTES,88380,ARCHETTES,,48.1321521406,6.54858727341,12,,Archettes,Archettes,88,Vosges,44,Grand Est
88013,AROFFE,88170,AROFFE,,48.4090826549,5.89970965405,13,,Aroffe,Aroffe,88,Vosges,44,Grand Est
88014,ARRENTES DE CORCIEUX,88430,ARRENTES DE CORCIEUX,,48.1325805401,6.87565871681,14,,Arrentès-de-Corcieux,Arrentès-de-Corcieux,88,Vosges,44,Grand Est
88015,ATTIGNEVILLE,88300,ATTIGNEVILLE,,48.39777973,5.8275121847,15,,Attignéville,Attignéville,88,Vosges,44,Grand Est
88016,ATTIGNY,88260,ATTIGNY,,48.0615234718,6.02500764856,16,,Attigny,Attigny,88,Vosges,44,Grand Est
88017,AULNOIS,88300,AULNOIS,,48.2618188421,5.77706254235,17,,Aulnois,Aulnois,88,Vosges,44,Grand Est
88019,AUTIGNY LA TOUR,88300,AUTIGNY LA TOUR,,48.4150811595,5.78035222598,19,,Autigny-la-Tour,Autigny-la-Tour,88,Vosges,44,Grand Est
88020,AUTREVILLE,88300,AUTREVILLE,,48.4900920447,5.85837068371,20,,Autreville,Autreville,88,Vosges,44,Grand Est
88021,AUTREY,88700,AUTREY,,48.2892300642,6.7239757963,21,,Autrey,Autrey,88,Vosges,44,Grand Est
88022,AUZAINVILLIERS,88140,AUZAINVILLIERS,,48.2367790067,5.8528751887,22,,Auzainvilliers,Auzainvilliers,88,Vosges,44,Grand Est
88023,AVILLERS,88500,AVILLERS,,48.3135159705,6.20579594744,23,,Avillers,Avillers,88,Vosges,44,Grand Est
88024,AVRAINVILLE,88130,AVRAINVILLE,,48.3781993079,6.22115791444,24,,Avrainville,Avrainville,88,Vosges,44,Grand Est
88025,AVRANVILLE,88630,AVRANVILLE,,48.4179129108,5.53556252619,25,,Avranville,Avranville,88,Vosges,44,Grand Est
88026,AYDOILLES,88600,AYDOILLES,,48.2106398796,6.5713006214,26,,Aydoilles,Aydoilles,88,Vosges,44,Grand Est
88027,BADMENIL AUX BOIS,88330,BADMENIL AUX BOIS,,48.3112223131,6.52392431222,27,,Badménil-aux-Bois,Badménil-aux-Bois,88,Vosges,44,Grand Est
88028,LA BAFFE,88460,LA BAFFE,,48.1590314003,6.56190492977,28,La,Baffe,La Baffe,88,Vosges,44,Grand Est
88029,BAINS LES BAINS,88240,BAINS LES BAINS,,47.9968747353,6.26194585854,29,La,Vôge-les-Bains,La Vôge-les-Bains,88,Vosges,44,Grand Est
88030,BAINVILLE AUX SAULES,88270,BAINVILLE AUX SAULES,,48.2073616447,6.13345381549,30,,Bainville-aux-Saules,Bainville-aux-Saules,88,Vosges,44,Grand Est
88031,BALLEVILLE,88170,BALLEVILLE,,48.3345074129,5.84687687585,31,,Balléville,Balléville,88,Vosges,44,Grand Est
88032,BAN DE LAVELINE,88520,BAN DE LAVELINE,,48.2280944054,7.08440511978,32,,Ban-de-Laveline,Ban-de-Laveline,88,Vosges,44,Grand Est
88033,BAN DE SAPT,88210,BAN DE SAPT,,48.3430851035,7.01566542926,33,,Ban-de-Sapt,Ban-de-Sapt,88,Vosges,44,Grand Est
88035,BARBEY SEROUX,88640,BARBEY SEROUX,,48.1316873956,6.84739914597,35,,Barbey-Seroux,Barbey-Seroux,88,Vosges,44,Grand Est
88036,BARVILLE,88300,BARVILLE,,48.3782063829,5.76910116308,36,,Barville,Barville,88,Vosges,44,Grand Est
88037,BASSE SUR LE RUPT,88120,BASSE SUR LE RUPT,,47.9864781279,6.76859733903,37,,Basse-sur-le-Rupt,Basse-sur-le-Rupt,88,Vosges,44,Grand Est
88038,BATTEXEY,88130,BATTEXEY,,48.3894996662,6.1814174155,38,,Battexey,Battexey,88,Vosges,44,Grand Est
88039,BAUDRICOURT,88500,BAUDRICOURT,,48.3097281212,6.05717256739,39,,Baudricourt,Baudricourt,88,Vosges,44,Grand Est
88040,BAYECOURT,88150,BAYECOURT,,48.2705788508,6.49201473003,40,,Bayecourt,Bayecourt,88,Vosges,44,Grand Est
88041,BAZEGNEY,88270,BAZEGNEY,,48.2657609673,6.23085668637,41,,Bazegney,Bazegney,88,Vosges,44,Grand Est
88042,BAZIEN,88700,BAZIEN,,48.4149166,6.67611556388,42,,Bazien,Bazien,88,Vosges,44,Grand Est
88043,BAZOILLES ET MENIL,88500,BAZOILLES ET MENIL,,48.2588753865,6.10357834209,43,,Bazoilles-et-Ménil,Bazoilles-et-Ménil,88,Vosges,44,Grand Est
88044,BAZOILLES SUR MEUSE,88300,BAZOILLES SUR MEUSE,,48.3011735776,5.65035260146,44,,Bazoilles-sur-Meuse,Bazoilles-sur-Meuse,88,Vosges,44,Grand Est
88045,BEAUFREMONT,88300,BEAUFREMONT,,48.2538106689,5.74644583545,45,,Beaufremont,Beaufremont,88,Vosges,44,Grand Est
88046,BEAUMENIL,88600,BEAUMENIL,,48.1718555594,6.72212832026,46,,Beauménil,Beauménil,88,Vosges,44,Grand Est
88047,BEGNECOURT,88270,BEGNECOURT,,48.209918075,6.16460965145,47,,Begnécourt,Begnécourt,88,Vosges,44,Grand Est
88048,BELLEFONTAINE,88370,BELLEFONTAINE,,48.0103753572,6.46413016754,48,,Bellefontaine,Bellefontaine,88,Vosges,44,Grand Est
88049,BELMONT LES DARNEY,88260,BELMONT LES DARNEY,,48.0858034217,6.01460062165,49,,Belmont-lès-Darney,Belmont-lès-Darney,88,Vosges,44,Grand Est
88050,BELMONT SUR BUTTANT,88600,BELMONT SUR BUTTANT,,48.2215096552,6.76849283806,50,,Belmont-sur-Buttant,Belmont-sur-Buttant,88,Vosges,44,Grand Est
88051,BELMONT SUR VAIR,88800,BELMONT SUR VAIR,,48.2590063678,5.9158647649,51,,Belmont-sur-Vair,Belmont-sur-Vair,88,Vosges,44,Grand Est
88052,BELRUPT,88260,BELRUPT,,48.0912515874,6.08965093071,52,,Belrupt,Belrupt,88,Vosges,44,Grand Est
88053,BELVAL,88210,BELVAL,,48.4086416787,7.07490269655,53,,Belval,Belval,88,Vosges,44,Grand Est
88054,BERTRIMOUTIER,88520,BERTRIMOUTIER,,48.2646393514,7.06227289704,54,,Bertrimoutier,Bertrimoutier,88,Vosges,44,Grand Est
88055,BETTEGNEY ST BRICE,88450,BETTEGNEY ST BRICE,,48.3034515964,6.31437877151,55,,Bettegney-Saint-Brice,Bettegney-Saint-Brice,88,Vosges,44,Grand Est
88056,BETTONCOURT,88500,BETTONCOURT,,48.3416039082,6.16622647862,56,,Bettoncourt,Bettoncourt,88,Vosges,44,Grand Est
88057,LE BEULAY,88490,LE BEULAY,,48.3044013418,7.06332114337,57,Le,Beulay,Le Beulay,88,Vosges,44,Grand Est
88058,BIECOURT,88170,BIECOURT,,48.3248866415,5.95360086649,58,,Biécourt,Biécourt,88,Vosges,44,Grand Est
88059,BIFFONTAINE,88430,BIFFONTAINE,,48.2134810702,6.81492622288,59,,Biffontaine,Biffontaine,88,Vosges,44,Grand Est
88060,BLEMEREY,88500,BLEMEREY,,48.3578801926,6.04237392778,60,,Blémerey,Blémerey,88,Vosges,44,Grand Est
88061,BLEURVILLE,88410,BLEURVILLE,,48.0671041641,5.95653169115,61,,Bleurville,Bleurville,88,Vosges,44,Grand Est
88062,BLEVAINCOURT,88320,BLEVAINCOURT,,48.122704627,5.69226626633,62,,Blevaincourt,Blevaincourt,88,Vosges,44,Grand Est
88063,BOCQUEGNEY,88270,BOCQUEGNEY,,48.220268825,6.3097147916,63,,Bocquegney,Bocquegney,88,Vosges,44,Grand Est
88064,BOIS DE CHAMP,88600,BOIS DE CHAMP,,48.2415730449,6.80358451732,64,,Bois-de-Champ,Bois-de-Champ,88,Vosges,44,Grand Est
88065,BONVILLET,88260,BONVILLET,,48.1023210347,6.06320564561,65,,Bonvillet,Bonvillet,88,Vosges,44,Grand Est
88066,BOULAINCOURT,88500,BOULAINCOURT,,48.3722153205,6.08621386332,66,,Boulaincourt,Boulaincourt,88,Vosges,44,Grand Est
88068,LA BOURGONCE,88470,LA BOURGONCE,,48.3025993945,6.81104954686,68,La,Bourgonce,La Bourgonce,88,Vosges,44,Grand Est
88069,BOUXIERES AUX BOIS,88270,BOUXIERES AUX BOIS,,48.2603726677,6.32068900232,69,,Bouxières-aux-Bois,Bouxières-aux-Bois,88,Vosges,44,Grand Est
88070,BOUXURULLES,88130,BOUXURULLES,,48.331186787,6.23165699645,70,,Bouxurulles,Bouxurulles,88,Vosges,44,Grand Est
88071,BOUZEMONT,88270,BOUZEMONT,,48.2506946208,6.2399049237,71,,Bouzemont,Bouzemont,88,Vosges,44,Grand Est
88073,BRANTIGNY,88130,BRANTIGNY,,48.3463519489,6.27689245872,73,,Brantigny,Brantigny,88,Vosges,44,Grand Est
88074,BRECHAINVILLE,88350,BRECHAINVILLE,,48.3631682748,5.50625429901,74,,Brechainville,Brechainville,88,Vosges,44,Grand Est
88075,LA BRESSE,88250,LA BRESSE,,48.0185002514,6.92615548288,75,La,Bresse,La Bresse,88,Vosges,44,Grand Est
88076,BROUVELIEURES,88600,BROUVELIEURES,,48.2431644831,6.73071847115,76,,Brouvelieures,Brouvelieures,88,Vosges,44,Grand Est
88077,BRU,88700,BRU,,48.3537732863,6.6891785388,77,,Brû,Brû,88,Vosges,44,Grand Est
88078,BRUYERES,88600,BRUYERES,,48.2129348466,6.71603066311,78,,Bruyères,Bruyères,88,Vosges,44,Grand Est
88079,BULGNEVILLE,88140,BULGNEVILLE,,48.2103923658,5.84105562447,79,,Bulgnéville,Bulgnéville,88,Vosges,44,Grand Est
88080,BULT,88700,BULT,,48.2997463865,6.59680830924,80,,Bult,Bult,88,Vosges,44,Grand Est
88081,BUSSANG,88540,BUSSANG,,47.8913024927,6.87663647751,81,,Bussang,Bussang,88,Vosges,44,Grand Est
88082,CELLES SUR PLAINE,88110,CELLES SUR PLAINE,,48.4499228234,6.96838114646,82,,Celles-sur-Plaine,Celles-sur-Plaine,88,Vosges,44,Grand Est
88083,CERTILLEUX,88300,CERTILLEUX,,48.3176635605,5.73569532999,83,,Certilleux,Certilleux,88,Vosges,44,Grand Est
88084,CHAMAGNE,88130,CHAMAGNE,,48.4085056939,6.29230922358,84,,Chamagne,Chamagne,88,Vosges,44,Grand Est
88085,CHAMPDRAY,88640,CHAMPDRAY,,48.1281659319,6.75998154739,85,,Champdray,Champdray,88,Vosges,44,Grand Est
88086,CHAMP LE DUC,88600,CHAMP LE DUC,,48.1923697643,6.72962889202,86,,Champ-le-Duc,Champ-le-Duc,88,Vosges,44,Grand Est
88087,CHANTRAINE,88000,CHANTRAINE,,48.1667597776,6.41955760454,87,,Chantraine,Chantraine,88,Vosges,44,Grand Est
88088,LA CHAPELLE AUX BOIS,88240,LA CHAPELLE AUX BOIS,,48.0282401085,6.32319521959,88,La,Chapelle-aux-Bois,La Chapelle-aux-Bois,88,Vosges,44,Grand Est
88088,LA CHAPELLE AUX BOIS,88240,LA CHAPELLE AUX BOIS,GREMIFONTAINE,48.0282401085,6.32319521959,88,La,Chapelle-aux-Bois,La Chapelle-aux-Bois,88,Vosges,44,Grand Est
88089,LA CHAPELLE DEVANT BRUYERES,88600,LA CHAPELLE DEVANT BRUYERES,,48.178743233,6.80624772393,89,La,Chapelle-devant-Bruyères,La Chapelle-devant-Bruyères,88,Vosges,44,Grand Est
88090,CHARMES,88130,CHARMES,,48.3848071988,6.31788990054,90,,Charmes,Charmes,88,Vosges,44,Grand Est
88091,CHARMOIS DEVANT BRUYERES,88460,CHARMOIS DEVANT BRUYERES,,48.1715459649,6.59203655593,91,,Charmois-devant-Bruyères,Charmois-devant-Bruyères,88,Vosges,44,Grand Est
88092,CHARMOIS L ORGUEILLEUX,88270,CHARMOIS L ORGUEILLEUX,,48.0912574478,6.26183235463,92,,Charmois-l'Orgueilleux,Charmois-l'Orgueilleux,88,Vosges,44,Grand Est
88093,CHATAS,88210,CHATAS,,48.3652872769,7.03771282338,93,,Châtas,Châtas,88,Vosges,44,Grand Est
88094,CHATEL SUR MOSELLE,88330,CHATEL SUR MOSELLE,,48.3198523928,6.40067968782,94,,Châtel-sur-Moselle,Châtel-sur-Moselle,88,Vosges,44,Grand Est
88095,CHATENOIS,88170,CHATENOIS,,48.309318088,5.82646017052,95,,Châtenois,Châtenois,88,Vosges,44,Grand Est
88095,CHATENOIS,88170,CHATENOIS,VALAINCOURT,48.309318088,5.82646017052,95,,Châtenois,Châtenois,88,Vosges,44,Grand Est
88096,CHATILLON SUR SAONE,88410,CHATILLON SUR SAONE,,47.9487239173,5.87799177631,96,,Châtillon-sur-Saône,Châtillon-sur-Saône,88,Vosges,44,Grand Est
88097,CHAUFFECOURT,88500,CHAUFFECOURT,,48.3335628199,6.15552797932,97,,Chauffecourt,Chauffecourt,88,Vosges,44,Grand Est
88098,CHAUMOUSEY,88390,CHAUMOUSEY,,48.1734708065,6.33193477567,98,,Chaumousey,Chaumousey,88,Vosges,44,Grand Est
88099,CHAVELOT,88150,CHAVELOT,,48.2275024139,6.43045423076,99,,Chavelot,Chavelot,88,Vosges,44,Grand Est
88100,CHEF HAUT,88500,CHEF HAUT,,48.3537714974,6.0096060971,100,,Chef-Haut,Chef-Haut,88,Vosges,44,Grand Est
88101,CHENIMENIL,88460,CHENIMENIL,,48.1372346109,6.59779908953,101,,Cheniménil,Cheniménil,88,Vosges,44,Grand Est
88102,CHERMISEY,88630,CHERMISEY,,48.4148753305,5.56909625806,102,,Chermisey,Chermisey,88,Vosges,44,Grand Est
88103,CIRCOURT,88270,CIRCOURT,,48.249056578,6.28568512782,103,,Circourt,Circourt,88,Vosges,44,Grand Est
88104,CIRCOURT SUR MOUZON,88300,CIRCOURT SUR MOUZON,,48.3053639157,5.70211881967,104,,Circourt-sur-Mouzon,Circourt-sur-Mouzon,88,Vosges,44,Grand Est
88105,CLAUDON,88410,CLAUDON,,48.0323668803,6.04405426553,105,,Claudon,Claudon,88,Vosges,44,Grand Est
88106,BAN SUR MEURTHE CLEFCY,88230,BAN SUR MEURTHE CLEFCY,,48.1266851489,6.97520285406,106,,Ban-sur-Meurthe-Clefcy,Ban-sur-Meurthe-Clefcy,88,Vosges,44,Grand Est
88106,BAN SUR MEURTHE CLEFCY,88230,BAN SUR MEURTHE CLEFCY,BAN SUR MEURTHE,48.1266851489,6.97520285406,106,,Ban-sur-Meurthe-Clefcy,Ban-sur-Meurthe-Clefcy,88,Vosges,44,Grand Est
88107,CLEREY LA COTE,88630,CLEREY LA COTE,,48.4844162831,5.76197885517,107,,Clérey-la-Côte,Clérey-la-Côte,88,Vosges,44,Grand Est
88108,LE CLERJUS,88240,LE CLERJUS,,47.9756723942,6.33126246271,108,Le,Clerjus,Le Clerjus,88,Vosges,44,Grand Est
88109,CLEURIE,88120,CLEURIE,,48.0561974173,6.6745100442,109,,Cleurie,Cleurie,88,Vosges,44,Grand Est
88110,CLEZENTAINE,88700,CLEZENTAINE,,48.4061376641,6.52855942576,110,,Clézentaine,Clézentaine,88,Vosges,44,Grand Est
88111,COINCHES,88100,COINCHES,,48.244948169,7.02470695131,111,,Coinches,Coinches,88,Vosges,44,Grand Est
88113,COMBRIMONT,88490,COMBRIMONT,,48.2743557331,7.0756682175,113,,Combrimont,Combrimont,88,Vosges,44,Grand Est
88114,CONTREXEVILLE,88140,CONTREXEVILLE,,48.1854408113,5.89357999879,114,,Contrexéville,Contrexéville,88,Vosges,44,Grand Est
88115,CORCIEUX,88430,CORCIEUX,,48.1672306852,6.88469743225,115,,Corcieux,Corcieux,88,Vosges,44,Grand Est
88116,CORNIMONT,88310,CORNIMONT,,47.9685491752,6.8653046202,116,,Cornimont,Cornimont,88,Vosges,44,Grand Est
88117,COURCELLES SOUS CHATENOIS,88170,COURCELLES SOUS CHATENOIS,,48.3280700524,5.81600954433,117,,Courcelles-sous-Châtenois,Courcelles-sous-Châtenois,88,Vosges,44,Grand Est
88118,COUSSEY,88630,COUSSEY,,48.4131072973,5.66796471132,118,,Coussey,Coussey,88,Vosges,44,Grand Est
88119,CRAINVILLIERS,88140,CRAINVILLIERS,,48.1478456269,5.83524764728,119,,Crainvilliers,Crainvilliers,88,Vosges,44,Grand Est
88120,LA CROIX AUX MINES,88520,LA CROIX AUX MINES,,48.2017053519,7.06097682719,120,La,Croix-aux-Mines,La Croix-aux-Mines,88,Vosges,44,Grand Est
88121,DAMAS AUX BOIS,88330,DAMAS AUX BOIS,,48.3846738845,6.41607186665,121,,Damas-aux-Bois,Damas-aux-Bois,88,Vosges,44,Grand Est
88122,DAMAS ET BETTEGNEY,88270,DAMAS ET BETTEGNEY,,48.2131764478,6.26183705745,122,,Damas-et-Bettegney,Damas-et-Bettegney,88,Vosges,44,Grand Est
88123,DAMBLAIN,88320,DAMBLAIN,,48.091128614,5.66870358714,123,,Damblain,Damblain,88,Vosges,44,Grand Est
88124,DARNEY,88260,DARNEY,,48.0781497355,6.05418895113,124,,Darney,Darney,88,Vosges,44,Grand Est
88125,DARNEY AUX CHENES,88170,DARNEY AUX CHENES,,48.283062153,5.81082852024,125,,Darney-aux-Chênes,Darney-aux-Chênes,88,Vosges,44,Grand Est
88126,DARNIEULLES,88390,DARNIEULLES,,48.1974773569,6.33832716875,126,,Darnieulles,Darnieulles,88,Vosges,44,Grand Est
88127,DEINVILLERS,88700,DEINVILLERS,,48.4125439347,6.56093261631,127,,Deinvillers,Deinvillers,88,Vosges,44,Grand Est
88128,DENIPAIRE,88210,DENIPAIRE,,48.3398168543,6.96189290978,128,,Denipaire,Denipaire,88,Vosges,44,Grand Est
88129,DERBAMONT,88270,DERBAMONT,,48.26687317,6.27162606216,129,,Derbamont,Derbamont,88,Vosges,44,Grand Est
88130,DESTORD,88600,DESTORD,,48.2735636446,6.6180368502,130,,Destord,Destord,88,Vosges,44,Grand Est
88131,DEYCIMONT,88600,DEYCIMONT,,48.1738809469,6.64106383013,131,,Deycimont,Deycimont,88,Vosges,44,Grand Est
88132,DEYVILLERS,88000,DEYVILLERS,,48.1965304597,6.52329047209,132,,Deyvillers,Deyvillers,88,Vosges,44,Grand Est
88133,DIGNONVILLE,88000,DIGNONVILLE,,48.2431130672,6.49905182011,133,,Dignonville,Dignonville,88,Vosges,44,Grand Est
88134,DINOZE,88000,DINOZE,,48.1386457778,6.48137889563,134,,Dinozé,Dinozé,88,Vosges,44,Grand Est
88135,DOCELLES,88460,DOCELLES,,48.1419104784,6.62527743986,135,,Docelles,Docelles,88,Vosges,44,Grand Est
88136,DOGNEVILLE,88000,DOGNEVILLE,,48.2215743919,6.4644885948,136,,Dogneville,Dogneville,88,Vosges,44,Grand Est
88137,DOLAINCOURT,88170,DOLAINCOURT,,48.3394348739,5.80990134614,137,,Dolaincourt,Dolaincourt,88,Vosges,44,Grand Est
88138,DOMBASLE DEVANT DARNEY,88260,DOMBASLE DEVANT DARNEY,,48.127049377,6.07713965721,138,,Dombasle-devant-Darney,Dombasle-devant-Darney,88,Vosges,44,Grand Est
88139,DOMBASLE EN XAINTOIS,88500,DOMBASLE EN XAINTOIS,,48.3057351148,5.99353360159,139,,Dombasle-en-Xaintois,Dombasle-en-Xaintois,88,Vosges,44,Grand Est
88140,DOMBROT LE SEC,88140,DOMBROT LE SEC,,48.1427266138,5.89822258779,140,,Dombrot-le-Sec,Dombrot-le-Sec,88,Vosges,44,Grand Est
88141,DOMBROT SUR VAIR,88170,DOMBROT SUR VAIR,,48.2650411994,5.89084030317,141,,Dombrot-sur-Vair,Dombrot-sur-Vair,88,Vosges,44,Grand Est
88142,DOMEVRE SUR AVIERE,88390,DOMEVRE SUR AVIERE,,48.2221272112,6.38817916406,142,,Domèvre-sur-Avière,Domèvre-sur-Avière,88,Vosges,44,Grand Est
88143,DOMEVRE SUR DURBION,88330,DOMEVRE SUR DURBION,,48.290163391,6.4826501202,143,,Domèvre-sur-Durbion,Domèvre-sur-Durbion,88,Vosges,44,Grand Est
88144,DOMEVRE SOUS MONTFORT,88500,DOMEVRE SOUS MONTFORT,,48.2560865469,6.0742479826,144,,Domèvre-sous-Montfort,Domèvre-sous-Montfort,88,Vosges,44,Grand Est
88145,DOMFAING,88600,DOMFAING,,48.2408241172,6.75106532043,145,,Domfaing,Domfaing,88,Vosges,44,Grand Est
88146,DOMJULIEN,88800,DOMJULIEN,,48.2552201786,5.99477709505,146,,Domjulien,Domjulien,88,Vosges,44,Grand Est
88146,DOMJULIEN,88800,DOMJULIEN,GIROVILLERS SOUS MONTFORT,48.2552201786,5.99477709505,146,,Domjulien,Domjulien,88,Vosges,44,Grand Est
88147,DOMMARTIN AUX BOIS,88390,DOMMARTIN AUX BOIS,,48.1502931822,6.28962914731,147,,Dommartin-aux-Bois,Dommartin-aux-Bois,88,Vosges,44,Grand Est
88148,DOMMARTIN LES REMIREMONT,88200,DOMMARTIN LES REMIREMONT,,47.99107923,6.6482307377,148,,Dommartin-lès-Remiremont,Dommartin-lès-Remiremont,88,Vosges,44,Grand Est
88149,DOMMARTIN LES VALLOIS,88260,DOMMARTIN LES VALLOIS,,48.1537141172,6.08556703588,149,,Dommartin-lès-Vallois,Dommartin-lès-Vallois,88,Vosges,44,Grand Est
88150,DOMMARTIN SUR VRAINE,88170,DOMMARTIN SUR VRAINE,,48.3415797506,5.91405593469,150,,Dommartin-sur-Vraine,Dommartin-sur-Vraine,88,Vosges,44,Grand Est
88151,DOMPAIRE,88270,DOMPAIRE,,48.2260970826,6.20804680456,151,,Dompaire,Dompaire,88,Vosges,44,Grand Est
88152,DOMPIERRE,88600,DOMPIERRE,,48.2497287991,6.56736673982,152,,Dompierre,Dompierre,88,Vosges,44,Grand Est
88153,DOMPTAIL,88700,DOMPTAIL,,48.4448145819,6.61620921538,153,,Domptail,Domptail,88,Vosges,44,Grand Est
88154,DOMREMY LA PUCELLE,88630,DOMREMY LA PUCELLE,,48.4404883955,5.65618746343,154,,Domrémy-la-Pucelle,Domrémy-la-Pucelle,88,Vosges,44,Grand Est
88155,DOMVALLIER,88500,DOMVALLIER,,48.3063425384,6.08048880292,155,,Domvallier,Domvallier,88,Vosges,44,Grand Est
88156,DONCIERES,88700,DONCIERES,,48.391632256,6.63746992341,156,,Doncières,Doncières,88,Vosges,44,Grand Est
88157,DOUNOUX,88220,DOUNOUX,,48.1074042749,6.43956295343,157,,Dounoux,Dounoux,88,Vosges,44,Grand Est
88158,ELOYES,88510,ELOYES,,48.0931276197,6.62003407466,158,,Éloyes,Éloyes,88,Vosges,44,Grand Est
88159,ENTRE DEUX EAUX,88650,ENTRE DEUX EAUX,,48.2376801335,6.99965862371,159,,Entre-deux-Eaux,Entre-deux-Eaux,88,Vosges,44,Grand Est
88160,EPINAL,88000,EPINAL,,48.1631202656,6.47989286928,160,,Épinal,Épinal,88,Vosges,44,Grand Est
88161,ESCLES,88260,ESCLES,,48.1179192154,6.17588466953,161,,Escles,Escles,88,Vosges,44,Grand Est
88162,ESLEY,88260,ESLEY,,48.1752203553,6.05828858575,162,,Esley,Esley,88,Vosges,44,Grand Est
88163,ESSEGNEY,88130,ESSEGNEY,,48.3760416841,6.33310938156,163,,Essegney,Essegney,88,Vosges,44,Grand Est
88164,ESTRENNES,88500,ESTRENNES,,48.2597029699,6.05563322175,164,,Estrennes,Estrennes,88,Vosges,44,Grand Est
88165,ETIVAL CLAIREFONTAINE,88480,ETIVAL CLAIREFONTAINE,,48.3643386923,6.84394360336,165,,Étival-Clairefontaine,Étival-Clairefontaine,88,Vosges,44,Grand Est
88166,EVAUX ET MENIL,88450,EVAUX ET MENIL,,48.3237100807,6.30111306789,166,,Évaux-et-Ménil,Évaux-et-Ménil,88,Vosges,44,Grand Est
88167,FAUCOMPIERRE,88460,FAUCOMPIERRE,,48.1402706744,6.66392055704,167,,Faucompierre,Faucompierre,88,Vosges,44,Grand Est
88168,FAUCONCOURT,88700,FAUCONCOURT,,48.3734058012,6.53727120074,168,,Fauconcourt,Fauconcourt,88,Vosges,44,Grand Est
88169,FAYS,88600,FAYS,,48.196859172,6.66860089142,169,,Fays,Fays,88,Vosges,44,Grand Est
88170,FERDRUPT,88360,FERDRUPT,,47.9111858178,6.71995195157,170,,Ferdrupt,Ferdrupt,88,Vosges,44,Grand Est
88171,FIGNEVELLE,88410,FIGNEVELLE,,47.9832319597,5.91115278832,171,,Fignévelle,Fignévelle,88,Vosges,44,Grand Est
88172,FIMENIL,88600,FIMENIL,,48.1749157278,6.70786220196,172,,Fiménil,Fiménil,88,Vosges,44,Grand Est
88173,FLOREMONT,88130,FLOREMONT,,48.3711842511,6.25282761176,173,,Florémont,Florémont,88,Vosges,44,Grand Est
88174,FOMEREY,88390,FOMEREY,,48.2182731886,6.34379031383,174,,Fomerey,Fomerey,88,Vosges,44,Grand Est
88175,FONTENAY,88600,FONTENAY,,48.2177866,6.59104686001,175,,Fontenay,Fontenay,88,Vosges,44,Grand Est
88176,FONTENOY LE CHATEAU,88240,FONTENOY LE CHATEAU,,47.9788627789,6.20625008119,176,,Fontenoy-le-Château,Fontenoy-le-Château,88,Vosges,44,Grand Est
88176,FONTENOY LE CHATEAU,88240,FONTENOY LE CHATEAU,LE MAGNY,47.9788627789,6.20625008119,176,,Fontenoy-le-Château,Fontenoy-le-Château,88,Vosges,44,Grand Est
88177,LA FORGE,88530,LA FORGE,,48.0704873516,6.71938444545,177,La,Forge,La Forge,88,Vosges,44,Grand Est
88178,LES FORGES,88390,LES FORGES,,48.1741009387,6.39516848095,178,Les,Forges,Les Forges,88,Vosges,44,Grand Est
88179,FOUCHECOURT,88320,FOUCHECOURT,,48.0052492177,5.85929939461,179,,Fouchécourt,Fouchécourt,88,Vosges,44,Grand Est
88180,FRAIN,88320,FRAIN,,48.0826848096,5.88107000168,180,,Frain,Frain,88,Vosges,44,Grand Est
88181,FRAIZE,88230,FRAIZE,,48.1863177255,7.0180107121,181,,Fraize,Fraize,88,Vosges,44,Grand Est
88182,FRAPELLE,88490,FRAPELLE,,48.2948095294,7.05288854277,182,,Frapelle,Frapelle,88,Vosges,44,Grand Est
88183,FREBECOURT,88630,FREBECOURT,,48.3841915094,5.67002519019,183,,Frebécourt,Frebécourt,88,Vosges,44,Grand Est
88184,FREMIFONTAINE,88600,FREMIFONTAINE,,48.261203018,6.70068031913,184,,Fremifontaine,Fremifontaine,88,Vosges,44,Grand Est
88185,FRENELLE LA GRANDE,88500,FRENELLE LA GRANDE,,48.352787817,6.09166022129,185,,Frenelle-la-Grande,Frenelle-la-Grande,88,Vosges,44,Grand Est
88186,FRENELLE LA PETITE,88500,FRENELLE LA PETITE,,48.3497634431,6.06246053491,186,,Frenelle-la-Petite,Frenelle-la-Petite,88,Vosges,44,Grand Est
88187,FRENOIS,88270,FRENOIS,,48.1852875824,6.12323328495,187,,Frénois,Frénois,88,Vosges,44,Grand Est
88188,FRESSE SUR MOSELLE,88160,FRESSE SUR MOSELLE,,47.8718454361,6.8055791741,188,,Fresse-sur-Moselle,Fresse-sur-Moselle,88,Vosges,44,Grand Est
88189,FREVILLE,88350,FREVILLE,,48.3260427587,5.62319879925,189,,Fréville,Fréville,88,Vosges,44,Grand Est
88190,FRIZON,88440,FRIZON,,48.2855591767,6.35372827024,190,,Frizon,Frizon,88,Vosges,44,Grand Est
88192,GELVECOURT ET ADOMPT,88270,GELVECOURT ET ADOMPT,,48.2008667754,6.17865808407,192,,Gelvécourt-et-Adompt,Gelvécourt-et-Adompt,88,Vosges,44,Grand Est
88192,GELVECOURT ET ADOMPT,88270,GELVECOURT ET ADOMPT,ADOMPT,48.2008667754,6.17865808407,192,,Gelvécourt-et-Adompt,Gelvécourt-et-Adompt,88,Vosges,44,Grand Est
88193,GEMAINGOUTTE,88520,GEMAINGOUTTE,,48.2455359961,7.1099105349,193,,Gemaingoutte,Gemaingoutte,88,Vosges,44,Grand Est
88194,GEMMELAINCOURT,88170,GEMMELAINCOURT,,48.2742122102,5.96709778619,194,,Gemmelaincourt,Gemmelaincourt,88,Vosges,44,Grand Est
88195,GENDREVILLE,88140,GENDREVILLE,,48.2354061751,5.71584808429,195,,Gendreville,Gendreville,88,Vosges,44,Grand Est
88196,GERARDMER,88400,GERARDMER,,48.0653057669,6.85912240068,196,,Gérardmer,Gérardmer,88,Vosges,44,Grand Est
88197,GERBAMONT,88120,GERBAMONT,,48.0034015569,6.76897818447,197,,Gerbamont,Gerbamont,88,Vosges,44,Grand Est
88198,GERBEPAL,88430,GERBEPAL,,48.1306977983,6.9210025294,198,,Gerbépal,Gerbépal,88,Vosges,44,Grand Est
88199,GIGNEVILLE,88320,GIGNEVILLE,,48.1123719506,5.91797417258,199,,Gignéville,Gignéville,88,Vosges,44,Grand Est
88200,GIGNEY,88390,GIGNEY,,48.2302000003,6.33437729864,200,,Gigney,Gigney,88,Vosges,44,Grand Est
88201,GIRANCOURT,88390,GIRANCOURT,,48.1469425491,6.32699648339,201,,Girancourt,Girancourt,88,Vosges,44,Grand Est
88202,GIRCOURT LES VIEVILLE,88500,GIRCOURT LES VIEVILLE,,48.3405324074,6.19209092898,202,,Gircourt-lès-Viéville,Gircourt-lès-Viéville,88,Vosges,44,Grand Est
88203,GIRECOURT SUR DURBION,88600,GIRECOURT SUR DURBION,,48.2483163554,6.59863899407,203,,Girecourt-sur-Durbion,Girecourt-sur-Durbion,88,Vosges,44,Grand Est
88205,GIRMONT VAL D AJOL,88340,GIRMONT VAL D AJOL,,47.9501646541,6.56784449876,205,,Girmont-Val-d'Ajol,Girmont-Val-d'Ajol,88,Vosges,44,Grand Est
88206,GIRONCOURT SUR VRAINE,88170,GIRONCOURT SUR VRAINE,,48.3033912981,5.93124486006,206,,Gironcourt-sur-Vraine,Gironcourt-sur-Vraine,88,Vosges,44,Grand Est
88208,GODONCOURT,88410,GODONCOURT,,47.9972274946,5.93385769544,208,,Godoncourt,Godoncourt,88,Vosges,44,Grand Est
88209,GOLBEY,88190,GOLBEY,,48.2002428602,6.42545128144,209,,Golbey,Golbey,88,Vosges,44,Grand Est
88210,GORHEY,88270,GORHEY,,48.1895575108,6.28833364652,210,,Gorhey,Gorhey,88,Vosges,44,Grand Est
88212,GRAND,88350,GRAND,,48.3930669369,5.47818498958,212,,Grand,Grand,88,Vosges,44,Grand Est
88213,LA GRANDE FOSSE,88490,LA GRANDE FOSSE,,48.3411124311,7.07741300394,213,La,Grande-Fosse,La Grande-Fosse,88,Vosges,44,Grand Est
88214,GRANDRUPT DE BAINS,88240,GRANDRUPT DE BAINS,,48.0636070991,6.17968670773,214,,Grandrupt-de-Bains,Grandrupt-de-Bains,88,Vosges,44,Grand Est
88215,GRANDRUPT,88210,GRANDRUPT,,48.3669519771,7.06206760711,215,,Grandrupt,Grandrupt,88,Vosges,44,Grand Est
88216,GRANDVILLERS,88600,GRANDVILLERS,,48.23660603,6.6688268877,216,,Grandvillers,Grandvillers,88,Vosges,44,Grand Est
88218,GRANGES AUMONTZEY,88640,GRANGES AUMONTZEY,,48.127602766,6.81014946226,218,,Granges-Aumontzey,Granges-Aumontzey,88,Vosges,44,Grand Est
88218,GRANGES AUMONTZEY,88640,GRANGES AUMONTZEY,AUMONTZEY,48.127602766,6.81014946226,218,,Granges-Aumontzey,Granges-Aumontzey,88,Vosges,44,Grand Est
88219,GREUX,88630,GREUX,,48.4598585275,5.6715322297,219,,Greux,Greux,88,Vosges,44,Grand Est
88220,GRIGNONCOURT,88410,GRIGNONCOURT,,47.9603456501,5.90603737866,220,,Grignoncourt,Grignoncourt,88,Vosges,44,Grand Est
88221,GRUEY LES SURANCE,88240,GRUEY LES SURANCE,,48.033984929,6.16990695895,221,,Gruey-lès-Surance,Gruey-lès-Surance,88,Vosges,44,Grand Est
88222,GUGNECOURT,88600,GUGNECOURT,,48.2477055814,6.62283113618,222,,Gugnécourt,Gugnécourt,88,Vosges,44,Grand Est
88223,GUGNEY AUX AULX,88450,GUGNEY AUX AULX,,48.2996214429,6.27117773923,223,,Gugney-aux-Aulx,Gugney-aux-Aulx,88,Vosges,44,Grand Est
88224,HADIGNY LES VERRIERES,88330,HADIGNY LES VERRIERES,,48.3219668732,6.4778869001,224,,Hadigny-les-Verrières,Hadigny-les-Verrières,88,Vosges,44,Grand Est
88225,HADOL,88220,HADOL,,48.0819790243,6.45579415214,225,,Hadol,Hadol,88,Vosges,44,Grand Est
88226,HAGECOURT,88270,HAGECOURT,,48.2291705541,6.15725916323,226,,Hagécourt,Hagécourt,88,Vosges,44,Grand Est
88227,HAGNEVILLE ET RONCOURT,88300,HAGNEVILLE ET RONCOURT,,48.2462613874,5.79111153508,227,,Hagnéville-et-Roncourt,Hagnéville-et-Roncourt,88,Vosges,44,Grand Est
88227,HAGNEVILLE ET RONCOURT,88300,HAGNEVILLE ET RONCOURT,RONCOURT,48.2462613874,5.79111153508,227,,Hagnéville-et-Roncourt,Hagnéville-et-Roncourt,88,Vosges,44,Grand Est
88228,HAILLAINVILLE,88330,HAILLAINVILLE,,48.3939828831,6.49311137995,228,,Haillainville,Haillainville,88,Vosges,44,Grand Est
88229,HARCHECHAMP,88300,HARCHECHAMP,,48.4051693044,5.80121835307,229,,Harchéchamp,Harchéchamp,88,Vosges,44,Grand Est
88230,HARDANCOURT,88700,HARDANCOURT,,48.3793075457,6.56231179534,230,,Hardancourt,Hardancourt,88,Vosges,44,Grand Est
88231,HAREVILLE,88800,HAREVILLE,,48.2037286203,6.01663834023,231,,Haréville,Haréville,88,Vosges,44,Grand Est
88232,HARMONVILLE,88300,HARMONVILLE,,48.464571091,5.87162601143,232,,Harmonville,Harmonville,88,Vosges,44,Grand Est
88233,HAROL,88270,HAROL,,48.1441724953,6.24781667111,233,,Harol,Harol,88,Vosges,44,Grand Est
88234,HARSAULT,88240,HARSAULT,,48.0493875654,6.24386739803,234,,Harsault,Harsault,88,Vosges,44,Grand Est
88234,HARSAULT,88240,HARSAULT,LA FORGE DE THUNIMONT,48.0493875654,6.24386739803,234,,Harsault,Harsault,88,Vosges,44,Grand Est
88234,HARSAULT,88240,HARSAULT,THUNIMONT,48.0493875654,6.24386739803,234,,Harsault,Harsault,88,Vosges,44,Grand Est
88235,HAUTMOUGEY,88240,HAUTMOUGEY,,48.0263697175,6.23594144044,235,,Hautmougey,Hautmougey,88,Vosges,44,Grand Est
88236,LA HAYE,88240,LA HAYE,,48.0622716294,6.20516802139,236,La,Haye,La Haye,88,Vosges,44,Grand Est
88237,HENNECOURT,88270,HENNECOURT,,48.2107215683,6.28992434859,237,,Hennecourt,Hennecourt,88,Vosges,44,Grand Est
88238,HENNEZEL,88260,HENNEZEL,,48.051849427,6.11266975905,238,,Hennezel,Hennezel,88,Vosges,44,Grand Est
88239,HERGUGNEY,88130,HERGUGNEY,,48.3902080411,6.2093192268,239,,Hergugney,Hergugney,88,Vosges,44,Grand Est
88240,HERPELMONT,88600,HERPELMONT,,48.1645481826,6.73252039852,240,,Herpelmont,Herpelmont,88,Vosges,44,Grand Est
88241,HOUECOURT,88170,HOUECOURT,,48.2930928111,5.90276215713,241,,Houécourt,Houécourt,88,Vosges,44,Grand Est
88242,HOUEVILLE,88300,HOUEVILLE,,48.3700796834,5.80595939885,242,,Houéville,Houéville,88,Vosges,44,Grand Est
88243,HOUSSERAS,88700,HOUSSERAS,,48.308084237,6.73540332376,243,,Housseras,Housseras,88,Vosges,44,Grand Est
88244,LA HOUSSIERE,88430,LA HOUSSIERE,,48.2051817248,6.86143996065,244,La,Houssière,La Houssière,88,Vosges,44,Grand Est
88245,HURBACHE,88210,HURBACHE,,48.3466578384,6.93090895956,245,,Hurbache,Hurbache,88,Vosges,44,Grand Est
88246,HYMONT,88500,HYMONT,,48.2640115227,6.13751849578,246,,Hymont,Hymont,88,Vosges,44,Grand Est
88247,IGNEY,88150,IGNEY,,48.276319758,6.39000589897,247,,Igney,Igney,88,Vosges,44,Grand Est
88248,ISCHES,88320,ISCHES,,48.0173921353,5.82091215963,248,,Isches,Isches,88,Vosges,44,Grand Est
88249,JAINVILLOTTE,88300,JAINVILLOTTE,,48.2594304053,5.70223863871,249,,Jainvillotte,Jainvillotte,88,Vosges,44,Grand Est
88250,JARMENIL,88550,JARMENIL,,48.1143669208,6.59331561157,250,,Jarménil,Jarménil,88,Vosges,44,Grand Est
88251,JEANMENIL,88700,JEANMENIL,,48.3307687041,6.71818019629,251,,Jeanménil,Jeanménil,88,Vosges,44,Grand Est
88252,JESONVILLE,88260,JESONVILLE,,48.126377839,6.11393136453,252,,Jésonville,Jésonville,88,Vosges,44,Grand Est
88253,JEUXEY,88000,JEUXEY,,48.200587727,6.49007507177,253,,Jeuxey,Jeuxey,88,Vosges,44,Grand Est
88254,JORXEY,88500,JORXEY,,48.303577564,6.23596280086,254,,Jorxey,Jorxey,88,Vosges,44,Grand Est
88255,JUBAINVILLE,88630,JUBAINVILLE,,48.4578190755,5.74949655029,255,,Jubainville,Jubainville,88,Vosges,44,Grand Est
88256,JUSSARUPT,88640,JUSSARUPT,,48.1559833004,6.74946475132,256,,Jussarupt,Jussarupt,88,Vosges,44,Grand Est
88257,JUVAINCOURT,88500,JUVAINCOURT,,48.3316459631,6.06321133757,257,,Juvaincourt,Juvaincourt,88,Vosges,44,Grand Est
88258,LAMARCHE,88320,LAMARCHE,,48.0624124786,5.77771959869,258,,Lamarche,Lamarche,88,Vosges,44,Grand Est
88259,LANDAVILLE,88300,LANDAVILLE,,48.2828814687,5.74689524585,259,,Landaville,Landaville,88,Vosges,44,Grand Est
88260,LANGLEY,88130,LANGLEY,,48.3599654321,6.33168687853,260,,Langley,Langley,88,Vosges,44,Grand Est
88261,LAVAL SUR VOLOGNE,88600,LAVAL SUR VOLOGNE,,48.1965616078,6.69279704432,261,,Laval-sur-Vologne,Laval-sur-Vologne,88,Vosges,44,Grand Est
88262,LAVELINE DEVANT BRUYERES,88600,LAVELINE DEVANT BRUYERES,,48.1852298177,6.75828843679,262,,Laveline-devant-Bruyères,Laveline-devant-Bruyères,88,Vosges,44,Grand Est
88263,LAVELINE DU HOUX,88640,LAVELINE DU HOUX,,48.1394463319,6.70838299375,263,,Laveline-du-Houx,Laveline-du-Houx,88,Vosges,44,Grand Est
88264,LEGEVILLE ET BONFAYS,88270,LEGEVILLE ET BONFAYS,,48.1866029628,6.15336139475,264,,Légéville-et-Bonfays,Légéville-et-Bonfays,88,Vosges,44,Grand Est
88265,LEMMECOURT,88300,LEMMECOURT,,48.2636413902,5.73237626549,265,,Lemmecourt,Lemmecourt,88,Vosges,44,Grand Est
88266,LEPANGES SUR VOLOGNE,88600,LEPANGES SUR VOLOGNE,,48.1792216625,6.66672756752,266,,Lépanges-sur-Vologne,Lépanges-sur-Vologne,88,Vosges,44,Grand Est
88267,LERRAIN,88260,LERRAIN,,48.1334408812,6.1445531008,267,,Lerrain,Lerrain,88,Vosges,44,Grand Est
88268,LESSEUX,88490,LESSEUX,,48.2821061489,7.08613438713,268,,Lesseux,Lesseux,88,Vosges,44,Grand Est
88269,LIEZEY,88400,LIEZEY,,48.0953821973,6.80125032702,269,,Liézey,Liézey,88,Vosges,44,Grand Est
88270,LIFFOL LE GRAND,88350,LIFFOL LE GRAND,,48.3192155275,5.57551328055,270,,Liffol-le-Grand,Liffol-le-Grand,88,Vosges,44,Grand Est
88271,LIGNEVILLE,88800,LIGNEVILLE,,48.1635558918,5.95344174382,271,,Lignéville,Lignéville,88,Vosges,44,Grand Est
88272,LIRONCOURT,88410,LIRONCOURT,,47.9710608513,5.87763408674,272,,Lironcourt,Lironcourt,88,Vosges,44,Grand Est
88273,LONGCHAMP,88000,LONGCHAMP,,48.2179595482,6.52465872608,273,,Longchamp,Longchamp,88,Vosges,44,Grand Est
88274,LONGCHAMP SOUS CHATENOIS,88170,LONGCHAMP SOUS CHATENOIS,,48.2866022286,5.83553689321,274,,Longchamp-sous-Châtenois,Longchamp-sous-Châtenois,88,Vosges,44,Grand Est
88275,LUBINE,88490,LUBINE,,48.3176823306,7.16688865915,275,,Lubine,Lubine,88,Vosges,44,Grand Est
88276,LUSSE,88490,LUSSE,,48.28922256,7.12769332056,276,,Lusse,Lusse,88,Vosges,44,Grand Est
88277,LUVIGNY,88110,LUVIGNY,,48.4980673357,7.07712772937,277,,Luvigny,Luvigny,88,Vosges,44,Grand Est
88278,MACONCOURT,88170,MACONCOURT,,48.3621746967,5.93892201857,278,,Maconcourt,Maconcourt,88,Vosges,44,Grand Est
88279,MADECOURT,88270,MADECOURT,,48.239342474,6.11505012899,279,,Madecourt,Madecourt,88,Vosges,44,Grand Est
88280,MADEGNEY,88450,MADEGNEY,,48.2854598939,6.28777865457,280,,Madegney,Madegney,88,Vosges,44,Grand Est
88281,MADONNE ET LAMEREY,88270,MADONNE ET LAMEREY,,48.2260937024,6.24298966238,281,,Madonne-et-Lamerey,Madonne-et-Lamerey,88,Vosges,44,Grand Est
88283,MALAINCOURT,88140,MALAINCOURT,,48.2246104086,5.76671107877,283,,Malaincourt,Malaincourt,88,Vosges,44,Grand Est
88284,MANDRAY,88650,MANDRAY,,48.2136820328,7.00286781313,284,,Mandray,Mandray,88,Vosges,44,Grand Est
88285,MANDRES SUR VAIR,88800,MANDRES SUR VAIR,,48.221407533,5.88533867624,285,,Mandres-sur-Vair,Mandres-sur-Vair,88,Vosges,44,Grand Est
88286,MARAINVILLE SUR MADON,88130,MARAINVILLE SUR MADON,,48.3969659004,6.16268175048,286,,Marainville-sur-Madon,Marainville-sur-Madon,88,Vosges,44,Grand Est
88287,MAREY,88320,MAREY,,48.1032737559,5.90705245577,287,,Marey,Marey,88,Vosges,44,Grand Est
88288,MARONCOURT,88270,MARONCOURT,,48.2463375996,6.16356949078,288,,Maroncourt,Maroncourt,88,Vosges,44,Grand Est
88289,MARTIGNY LES BAINS,88320,MARTIGNY LES BAINS,,48.1103965406,5.81812933203,289,,Martigny-les-Bains,Martigny-les-Bains,88,Vosges,44,Grand Est
88290,MARTIGNY LES GERBONVAUX,88300,MARTIGNY LES GERBONVAUX,,48.4468151496,5.79362450628,290,,Martigny-les-Gerbonvaux,Martigny-les-Gerbonvaux,88,Vosges,44,Grand Est
88291,MARTINVELLE,88410,MARTINVELLE,,47.9903283135,6.00645786429,291,,Martinvelle,Martinvelle,88,Vosges,44,Grand Est
88292,MATTAINCOURT,88500,MATTAINCOURT,,48.2777683116,6.1280387799,292,,Mattaincourt,Mattaincourt,88,Vosges,44,Grand Est
88293,MAXEY SUR MEUSE,88630,MAXEY SUR MEUSE,,48.4490229482,5.71682995945,293,,Maxey-sur-Meuse,Maxey-sur-Meuse,88,Vosges,44,Grand Est
88294,MAZELEY,88150,MAZELEY,,48.2468678999,6.34350665623,294,,Mazeley,Mazeley,88,Vosges,44,Grand Est
88295,MAZIROT,88500,MAZIROT,,48.3210257674,6.15641355317,295,,Mazirot,Mazirot,88,Vosges,44,Grand Est
88296,MEDONVILLE,88140,MEDONVILLE,,48.2150854972,5.73452530461,296,,Médonville,Médonville,88,Vosges,44,Grand Est
88297,MEMENIL,88600,MEMENIL,,48.209159964,6.61588245016,297,,Méménil,Méménil,88,Vosges,44,Grand Est
88298,MENARMONT,88700,MENARMONT,,48.4181626654,6.64903014535,298,,Ménarmont,Ménarmont,88,Vosges,44,Grand Est
88299,MENIL EN XAINTOIS,88500,MENIL EN XAINTOIS,,48.3027401506,5.97315674215,299,,Ménil-en-Xaintois,Ménil-en-Xaintois,88,Vosges,44,Grand Est
88300,MENIL DE SENONES,88210,MENIL DE SENONES,,48.3750069219,6.99707479761,300,,Ménil-de-Senones,Ménil-de-Senones,88,Vosges,44,Grand Est
88301,MENIL SUR BELVITTE,88700,MENIL SUR BELVITTE,,48.3838870816,6.69616124594,301,,Ménil-sur-Belvitte,Ménil-sur-Belvitte,88,Vosges,44,Grand Est
88302,LE MENIL,88160,LE MENIL,,47.9140118322,6.81585715866,302,Le,Ménil,Le Ménil,88,Vosges,44,Grand Est
88303,MIDREVAUX,88630,MIDREVAUX,,48.3882114046,5.58565024732,303,,Midrevaux,Midrevaux,88,Vosges,44,Grand Est
88304,MIRECOURT,88500,MIRECOURT,,48.2935312144,6.12032421472,304,,Mirecourt,Mirecourt,88,Vosges,44,Grand Est
88305,MONCEL SUR VAIR,88630,MONCEL SUR VAIR,,48.4246422681,5.70427543116,305,,Moncel-sur-Vair,Moncel-sur-Vair,88,Vosges,44,Grand Est
88306,LE MONT,88210,LE MONT,,48.4096696255,7.02450086631,306,Le,Mont,Le Mont,88,Vosges,44,Grand Est
88307,MONT LES LAMARCHE,88320,MONT LES LAMARCHE,,48.0239239914,5.79475524038,307,,Mont-lès-Lamarche,Mont-lès-Lamarche,88,Vosges,44,Grand Est
88308,MONT LES NEUFCHATEAU,88300,MONT LES NEUFCHATEAU,,48.3527617859,5.64259267987,308,,Mont-lès-Neufchâteau,Mont-lès-Neufchâteau,88,Vosges,44,Grand Est
88309,MONTHUREUX LE SEC,88800,MONTHUREUX LE SEC,,48.1678786007,6.02913581296,309,,Monthureux-le-Sec,Monthureux-le-Sec,88,Vosges,44,Grand Est
88310,MONTHUREUX SUR SAONE,88410,MONTHUREUX SUR SAONE,,48.0275487639,5.96711970702,310,,Monthureux-sur-Saône,Monthureux-sur-Saône,88,Vosges,44,Grand Est
88311,MONTMOTIER,88240,MONTMOTIER,,47.980174233,6.17363350663,311,,Montmotier,Montmotier,88,Vosges,44,Grand Est
88312,MORELMAISON,88170,MORELMAISON,,48.3211524377,5.91591541641,312,,Morelmaison,Morelmaison,88,Vosges,44,Grand Est
88313,MORIVILLE,88330,MORIVILLE,,48.3473450165,6.41821065495,313,,Moriville,Moriville,88,Vosges,44,Grand Est
88314,MORIZECOURT,88320,MORIZECOURT,,48.0718872896,5.85844834425,314,,Morizécourt,Morizécourt,88,Vosges,44,Grand Est
88315,MORTAGNE,88600,MORTAGNE,,48.2741321601,6.79654370715,315,,Mortagne,Mortagne,88,Vosges,44,Grand Est
88316,MORVILLE,88140,MORVILLE,,48.2347546949,5.79472191903,316,,Morville,Morville,88,Vosges,44,Grand Est
88317,MOUSSEY,88210,MOUSSEY,,48.4484687555,7.06149660665,317,,Moussey,Moussey,88,Vosges,44,Grand Est
88318,MOYEMONT,88700,MOYEMONT,,48.3446679064,6.54438076888,318,,Moyemont,Moyemont,88,Vosges,44,Grand Est
88319,MOYENMOUTIER,88420,MOYENMOUTIER,,48.3849583082,6.91756486753,319,,Moyenmoutier,Moyenmoutier,88,Vosges,44,Grand Est
88320,NAYEMONT LES FOSSES,88100,NAYEMONT LES FOSSES,,48.2978194425,7.00799607343,320,,Nayemont-les-Fosses,Nayemont-les-Fosses,88,Vosges,44,Grand Est
88321,NEUFCHATEAU,88300,NEUFCHATEAU,,48.3565023559,5.69131913979,321,,Neufchâteau,Neufchâteau,88,Vosges,44,Grand Est
88322,LA NEUVEVILLE DEVANT LEPANGES,88600,LA NEUVEVILLE DEVANT LEPANGES,,48.1537248949,6.66634796718,322,La,Neuveville-devant-Lépanges,La Neuveville-devant-Lépanges,88,Vosges,44,Grand Est
88322,LA NEUVEVILLE DEVANT LEPANGES,88600,LA NEUVEVILLE DEVANT LEPANGES,LE BOULAY,48.1537248949,6.66634796718,322,La,Neuveville-devant-Lépanges,La Neuveville-devant-Lépanges,88,Vosges,44,Grand Est
88322,LA NEUVEVILLE DEVANT LEPANGES,88600,LA NEUVEVILLE DEVANT LEPANGES,ST JEAN DU MARCHE,48.1537248949,6.66634796718,322,La,Neuveville-devant-Lépanges,La Neuveville-devant-Lépanges,88,Vosges,44,Grand Est
88324,LA NEUVEVILLE SOUS CHATENOIS,88170,LA NEUVEVILLE SOUS CHATENOIS,,48.2864760642,5.87204084332,324,La,Neuveville-sous-Châtenois,La Neuveville-sous-Châtenois,88,Vosges,44,Grand Est
88325,LA NEUVEVILLE SOUS MONTFORT,88800,LA NEUVEVILLE SOUS MONTFORT,,48.2291302196,6.01738942152,325,La,Neuveville-sous-Montfort,La Neuveville-sous-Montfort,88,Vosges,44,Grand Est
88326,NEUVILLERS SUR FAVE,88100,NEUVILLERS SUR FAVE,,48.2849702231,7.03413627809,326,,Neuvillers-sur-Fave,Neuvillers-sur-Fave,88,Vosges,44,Grand Est
88327,NOMEXY,88440,NOMEXY,,48.307019833,6.372196304,327,,Nomexy,Nomexy,88,Vosges,44,Grand Est
88328,NOMPATELIZE,88470,NOMPATELIZE,,48.3293457502,6.85556075648,328,,Nompatelize,Nompatelize,88,Vosges,44,Grand Est
88330,NONVILLE,88260,NONVILLE,,48.0899626206,5.98313729511,330,,Nonville,Nonville,88,Vosges,44,Grand Est
88331,NONZEVILLE,88600,NONZEVILLE,,48.2654993347,6.63356846673,331,,Nonzeville,Nonzeville,88,Vosges,44,Grand Est
88332,NORROY,88800,NORROY,,48.2140535521,5.91840801512,332,,Norroy,Norroy,88,Vosges,44,Grand Est
88333,NOSSONCOURT,88700,NOSSONCOURT,,48.3997714824,6.67182363407,333,,Nossoncourt,Nossoncourt,88,Vosges,44,Grand Est
88334,OELLEVILLE,88500,OELLEVILLE,,48.3352451365,6.02078424546,334,,Oëlleville,Oëlleville,88,Vosges,44,Grand Est
88335,OFFROICOURT,88500,OFFROICOURT,,48.2813144174,6.03054762728,335,,Offroicourt,Offroicourt,88,Vosges,44,Grand Est
88336,OLLAINVILLE,88170,OLLAINVILLE,,48.2696863799,5.80439655202,336,,Ollainville,Ollainville,88,Vosges,44,Grand Est
88338,ORTONCOURT,88700,ORTONCOURT,,48.3681665513,6.50942324126,338,,Ortoncourt,Ortoncourt,88,Vosges,44,Grand Est
88340,PADOUX,88700,PADOUX,,48.2874903327,6.56577166427,340,,Padoux,Padoux,88,Vosges,44,Grand Est
88341,PAIR ET GRANDRUPT,88100,PAIR ET GRANDRUPT,,48.2794735198,7.01113193322,341,,Pair-et-Grandrupt,Pair-et-Grandrupt,88,Vosges,44,Grand Est
88342,PALLEGNEY,88330,PALLEGNEY,,48.2905022008,6.4466547327,342,,Pallegney,Pallegney,88,Vosges,44,Grand Est
88343,PAREY SOUS MONTFORT,88800,PAREY SOUS MONTFORT,,48.2508324084,5.94558967092,343,,Parey-sous-Montfort,Parey-sous-Montfort,88,Vosges,44,Grand Est
88344,PARGNY SOUS MUREAU,88350,PARGNY SOUS MUREAU,,48.3646298199,5.58928467692,344,,Pargny-sous-Mureau,Pargny-sous-Mureau,88,Vosges,44,Grand Est
88345,LA PETITE FOSSE,88490,LA PETITE FOSSE,,48.3217854847,7.05050994917,345,La,Petite-Fosse,La Petite-Fosse,88,Vosges,44,Grand Est
88346,LA PETITE RAON,88210,LA PETITE RAON,,48.4194366522,6.99291726168,346,La,Petite-Raon,La Petite-Raon,88,Vosges,44,Grand Est
88347,PIERREFITTE,88270,PIERREFITTE,,48.1641794283,6.17433471969,347,,Pierrefitte,Pierrefitte,88,Vosges,44,Grand Est
88348,PIERREPONT SUR L ARENTELE,88600,PIERREPONT SUR L ARENTELE,,48.2640041205,6.660725062,348,,Pierrepont-sur-l'Arentèle,Pierrepont-sur-l'Arentèle,88,Vosges,44,Grand Est
88349,PLAINFAING,88230,PLAINFAING,,48.1425830967,7.03749465114,349,,Plainfaing,Plainfaing,88,Vosges,44,Grand Est
88350,PLEUVEZAIN,88170,PLEUVEZAIN,,48.3765895082,5.91518537556,350,,Pleuvezain,Pleuvezain,88,Vosges,44,Grand Est
88351,PLOMBIERES LES BAINS,88370,PLOMBIERES LES BAINS,,47.9697475958,6.42879961974,351,,Plombières-les-Bains,Plombières-les-Bains,88,Vosges,44,Grand Est
88351,PLOMBIERES LES BAINS,88370,PLOMBIERES LES BAINS,GRANGES DE PLOMBIERES,47.9697475958,6.42879961974,351,,Plombières-les-Bains,Plombières-les-Bains,88,Vosges,44,Grand Est
88351,PLOMBIERES LES BAINS,88370,PLOMBIERES LES BAINS,RUAUX,47.9697475958,6.42879961974,351,,Plombières-les-Bains,Plombières-les-Bains,88,Vosges,44,Grand Est
88352,POMPIERRE,88300,POMPIERRE,,48.2746496392,5.67619878107,352,,Pompierre,Pompierre,88,Vosges,44,Grand Est
88353,PONT LES BONFAYS,88260,PONT LES BONFAYS,,48.1672898081,6.14266150561,353,,Pont-lès-Bonfays,Pont-lès-Bonfays,88,Vosges,44,Grand Est
88354,PONT SUR MADON,88500,PONT SUR MADON,,48.3708641633,6.15132896221,354,,Pont-sur-Madon,Pont-sur-Madon,88,Vosges,44,Grand Est
88355,PORTIEUX,88330,PORTIEUX,,48.3478769237,6.35610035139,355,,Portieux,Portieux,88,Vosges,44,Grand Est
88356,LES POULIERES,88600,LES POULIERES,,48.2035855433,6.78630426171,356,Les,Poulières,Les Poulières,88,Vosges,44,Grand Est
88357,POUSSAY,88500,POUSSAY,,48.3226145275,6.12077562905,357,,Poussay,Poussay,88,Vosges,44,Grand Est
88358,POUXEUX,88550,POUXEUX,,48.0951237995,6.56483709152,358,,Pouxeux,Pouxeux,88,Vosges,44,Grand Est
88359,PREY,88600,PREY,,48.176574396,6.69007777136,359,,Prey,Prey,88,Vosges,44,Grand Est
88360,PROVENCHERES LES DARNEY,88260,PROVENCHERES LES DARNEY,,48.1233719759,5.96308105544,360,,Provenchères-lès-Darney,Provenchères-lès-Darney,88,Vosges,44,Grand Est
88361,PROVENCHERES ET COLROY,88490,PROVENCHERES ET COLROY,,48.3153511081,7.07950479056,361,,Provenchères-et-Colroy,Provenchères-et-Colroy,88,Vosges,44,Grand Est
88361,PROVENCHERES ET COLROY,88490,PROVENCHERES ET COLROY,COLROY LA GRANDE,48.3153511081,7.07950479056,361,,Provenchères-et-Colroy,Provenchères-et-Colroy,88,Vosges,44,Grand Est
88362,LE PUID,88210,LE PUID,,48.3927228092,7.03919512713,362,Le,Puid,Le Puid,88,Vosges,44,Grand Est
88363,PUNEROT,88630,PUNEROT,,48.4769780957,5.81474360261,363,,Punerot,Punerot,88,Vosges,44,Grand Est
88364,PUZIEUX,88500,PUZIEUX,,48.3347554492,6.0998994342,364,,Puzieux,Puzieux,88,Vosges,44,Grand Est
88365,RACECOURT,88270,RACECOURT,,48.2590688539,6.19750172811,365,,Racécourt,Racécourt,88,Vosges,44,Grand Est
88366,RAINVILLE,88170,RAINVILLE,,48.3564862933,5.88860670134,366,,Rainville,Rainville,88,Vosges,44,Grand Est
88367,RAMBERVILLERS,88700,RAMBERVILLERS,,48.3458722142,6.63121002783,367,,Rambervillers,Rambervillers,88,Vosges,44,Grand Est
88368,RAMECOURT,88500,RAMECOURT,,48.3088372975,6.09746519349,368,,Ramecourt,Ramecourt,88,Vosges,44,Grand Est
88369,RAMONCHAMP,88160,RAMONCHAMP,,47.8957257819,6.74118658301,369,,Ramonchamp,Ramonchamp,88,Vosges,44,Grand Est
88370,RANCOURT,88270,RANCOURT,,48.2200072778,6.10969836772,370,,Rancourt,Rancourt,88,Vosges,44,Grand Est
88371,RAON AUX BOIS,88220,RAON AUX BOIS,,48.0563851529,6.51474644165,371,,Raon-aux-Bois,Raon-aux-Bois,88,Vosges,44,Grand Est
88372,RAON L ETAPE,88110,RAON L ETAPE,,48.4079337645,6.86032429623,372,,Raon-l'Étape,Raon-l'Étape,88,Vosges,44,Grand Est
88372,RAON L ETAPE,88110,RAON L ETAPE,LA TROUCHE,48.4079337645,6.86032429623,372,,Raon-l'Étape,Raon-l'Étape,88,Vosges,44,Grand Est
88373,RAON SUR PLAINE,88110,RAON SUR PLAINE,,48.5066764358,7.10986005227,373,,Raon-sur-Plaine,Raon-sur-Plaine,88,Vosges,44,Grand Est
88374,RAPEY,88130,RAPEY,,48.3170560275,6.25241175461,374,,Rapey,Rapey,88,Vosges,44,Grand Est
88375,RAVES,88520,RAVES,,48.2607082807,7.04375216044,375,,Raves,Raves,88,Vosges,44,Grand Est
88376,REBEUVILLE,88300,REBEUVILLE,,48.3370016504,5.71877822287,376,,Rebeuville,Rebeuville,88,Vosges,44,Grand Est
88377,REGNEVELLE,88410,REGNEVELLE,,47.9878028534,5.9684410687,377,,Regnévelle,Regnévelle,88,Vosges,44,Grand Est
88378,REGNEY,88450,REGNEY,,48.28657305,6.30785592223,378,,Regney,Regney,88,Vosges,44,Grand Est
88379,REHAINCOURT,88330,REHAINCOURT,,48.3615254656,6.4703734495,379,,Rehaincourt,Rehaincourt,88,Vosges,44,Grand Est
88380,REHAUPAL,88640,REHAUPAL,,48.1189218406,6.74191754292,380,,Rehaupal,Rehaupal,88,Vosges,44,Grand Est
88381,RELANGES,88260,RELANGES,,48.1117050113,6.0162884596,381,,Relanges,Relanges,88,Vosges,44,Grand Est
88382,REMICOURT,88500,REMICOURT,,48.2837744091,6.0633435435,382,,Remicourt,Remicourt,88,Vosges,44,Grand Est
88383,REMIREMONT,88200,REMIREMONT,,48.0013090816,6.58128956332,383,,Remiremont,Remiremont,88,Vosges,44,Grand Est
88385,REMONCOURT,88800,REMONCOURT,,48.2238896694,6.05370778613,385,,Remoncourt,Remoncourt,88,Vosges,44,Grand Est
88386,REMOMEIX,88100,REMOMEIX,,48.2636489839,7.00958545987,386,,Remomeix,Remomeix,88,Vosges,44,Grand Est
88387,REMOVILLE,88170,REMOVILLE,,48.3667922699,5.84601094575,387,,Removille,Removille,88,Vosges,44,Grand Est
88388,RENAUVOID,88390,RENAUVOID,,48.1427648527,6.38176151044,388,,Renauvoid,Renauvoid,88,Vosges,44,Grand Est
88389,REPEL,88500,REPEL,,48.3445774553,5.98023037308,389,,Repel,Repel,88,Vosges,44,Grand Est
88390,ROBECOURT,88320,ROBECOURT,,48.1374692528,5.7093887067,390,,Robécourt,Robécourt,88,Vosges,44,Grand Est
88391,ROCHESSON,88120,ROCHESSON,,48.0197429303,6.82438573999,391,,Rochesson,Rochesson,88,Vosges,44,Grand Est
88392,ROCOURT,88320,ROCOURT,,48.1076973635,5.74509629224,392,,Rocourt,Rocourt,88,Vosges,44,Grand Est
88393,ROLLAINVILLE,88300,ROLLAINVILLE,,48.3554764576,5.74054741265,393,,Rollainville,Rollainville,88,Vosges,44,Grand Est
88394,ROMAIN AUX BOIS,88320,ROMAIN AUX BOIS,,48.0766280164,5.71451432126,394,,Romain-aux-Bois,Romain-aux-Bois,88,Vosges,44,Grand Est
88395,ROMONT,88700,ROMONT,,48.3466157075,6.58079592867,395,,Romont,Romont,88,Vosges,44,Grand Est
88398,LES ROUGES EAUX,88600,LES ROUGES EAUX,,48.2654557112,6.82103671225,398,Les,Rouges-Eaux,Les Rouges-Eaux,88,Vosges,44,Grand Est
88399,LE ROULIER,88460,LE ROULIER,,48.1735701591,6.61592028644,399,Le,Roulier,Le Roulier,88,Vosges,44,Grand Est
88400,ROUVRES EN XAINTOIS,88500,ROUVRES EN XAINTOIS,,48.3043260772,6.02285815223,400,,Rouvres-en-Xaintois,Rouvres-en-Xaintois,88,Vosges,44,Grand Est
88401,ROUVRES LA CHETIVE,88170,ROUVRES LA CHETIVE,,48.3048594616,5.77652870122,401,,Rouvres-la-Chétive,Rouvres-la-Chétive,88,Vosges,44,Grand Est
88402,ROVILLE AUX CHENES,88700,ROVILLE AUX CHENES,,48.3820234749,6.61151563001,402,,Roville-aux-Chênes,Roville-aux-Chênes,88,Vosges,44,Grand Est
88403,ROZEROTTE,88500,ROZEROTTE,,48.2385664029,6.08531096455,403,,Rozerotte,Rozerotte,88,Vosges,44,Grand Est
88404,ROZIERES SUR MOUZON,88320,ROZIERES SUR MOUZON,,48.1150950617,5.72132142301,404,,Rozières-sur-Mouzon,Rozières-sur-Mouzon,88,Vosges,44,Grand Est
88406,RUGNEY,88130,RUGNEY,,48.3482485017,6.25242889528,406,,Rugney,Rugney,88,Vosges,44,Grand Est
88407,RUPPES,88630,RUPPES,,48.4665956128,5.77125636552,407,,Ruppes,Ruppes,88,Vosges,44,Grand Est
88408,RUPT SUR MOSELLE,88360,RUPT SUR MOSELLE,,47.933079987,6.6637417919,408,,Rupt-sur-Moselle,Rupt-sur-Moselle,88,Vosges,44,Grand Est
88409,ST AME,88120,ST AME,,48.0241085703,6.65165040514,409,,Saint-Amé,Saint-Amé,88,Vosges,44,Grand Est
88410,STE BARBE,88700,STE BARBE,,48.3926409595,6.75215024324,410,,Sainte-Barbe,Sainte-Barbe,88,Vosges,44,Grand Est
88411,ST BASLEMONT,88260,ST BASLEMONT,,48.1399184604,5.99361275531,411,,Saint-Baslemont,Saint-Baslemont,88,Vosges,44,Grand Est
88412,ST BENOIT LA CHIPOTTE,88700,ST BENOIT LA CHIPOTTE,,48.3554186756,6.74913973695,412,,Saint-Benoît-la-Chipotte,Saint-Benoît-la-Chipotte,88,Vosges,44,Grand Est
88413,ST DIE DES VOSGES,88100,ST DIE DES VOSGES,,48.2967093514,6.93809367547,413,,Saint-Dié-des-Vosges,Saint-Dié-des-Vosges,88,Vosges,44,Grand Est
88415,ST ETIENNE LES REMIREMONT,88200,ST ETIENNE LES REMIREMONT,,48.0463713685,6.62527761065,415,,Saint-Étienne-lès-Remiremont,Saint-Étienne-lès-Remiremont,88,Vosges,44,Grand Est
88416,ST GENEST,88700,ST GENEST,,48.3455201567,6.51142918366,416,,Saint-Genest,Saint-Genest,88,Vosges,44,Grand Est
88417,ST GORGON,88700,ST GORGON,,48.3206900505,6.65349211672,417,,Saint-Gorgon,Saint-Gorgon,88,Vosges,44,Grand Est
88418,STE HELENE,88700,STE HELENE,,48.2911473664,6.65955609502,418,,Sainte-Hélène,Sainte-Hélène,88,Vosges,44,Grand Est
88419,ST JEAN D ORMONT,88210,ST JEAN D ORMONT,,48.3294969817,6.98858773472,419,,Saint-Jean-d'Ormont,Saint-Jean-d'Ormont,88,Vosges,44,Grand Est
88421,ST JULIEN,88410,ST JULIEN,,48.021137571,5.90624995878,421,,Saint-Julien,Saint-Julien,88,Vosges,44,Grand Est
88423,ST LEONARD,88650,ST LEONARD,,48.2151642613,6.91831630408,423,,Saint-Léonard,Saint-Léonard,88,Vosges,44,Grand Est
88424,STE MARGUERITE,88100,STE MARGUERITE,,48.2675581117,6.97340042686,424,,Sainte-Marguerite,Sainte-Marguerite,88,Vosges,44,Grand Est
88425,ST MAURICE SUR MORTAGNE,88700,ST MAURICE SUR MORTAGNE,,48.3917125307,6.57397194545,425,,Saint-Maurice-sur-Mortagne,Saint-Maurice-sur-Mortagne,88,Vosges,44,Grand Est
88426,ST MAURICE SUR MOSELLE,88560,ST MAURICE SUR MOSELLE,,47.8462080674,6.85474549269,426,,Saint-Maurice-sur-Moselle,Saint-Maurice-sur-Moselle,88,Vosges,44,Grand Est
88427,ST MENGE,88170,ST MENGE,,48.288921727,5.95105661444,427,,Saint-Menge,Saint-Menge,88,Vosges,44,Grand Est
88428,ST MICHEL SUR MEURTHE,88470,ST MICHEL SUR MEURTHE,,48.3116592875,6.87608459111,428,,Saint-Michel-sur-Meurthe,Saint-Michel-sur-Meurthe,88,Vosges,44,Grand Est
88429,ST NABORD,88200,ST NABORD,,48.0360750198,6.55639744064,429,,Saint-Nabord,Saint-Nabord,88,Vosges,44,Grand Est
88430,ST OUEN LES PAREY,88140,ST OUEN LES PAREY,,48.1737856169,5.77907312316,430,,Saint-Ouen-lès-Parey,Saint-Ouen-lès-Parey,88,Vosges,44,Grand Est
88431,ST PAUL,88170,ST PAUL,,48.3309340459,5.8864017008,431,,Saint-Paul,Saint-Paul,88,Vosges,44,Grand Est
88432,ST PIERREMONT,88700,ST PIERREMONT,,48.4394574604,6.58332461812,432,,Saint-Pierremont,Saint-Pierremont,88,Vosges,44,Grand Est
88433,ST PRANCHER,88500,ST PRANCHER,,48.3455799739,5.94859855562,433,,Saint-Prancher,Saint-Prancher,88,Vosges,44,Grand Est
88434,ST REMIMONT,88800,ST REMIMONT,,48.2415839774,5.91269544307,434,,Saint-Remimont,Saint-Remimont,88,Vosges,44,Grand Est
88435,ST REMY,88480,ST REMY,,48.3429205688,6.81438794829,435,,Saint-Remy,Saint-Remy,88,Vosges,44,Grand Est
88436,ST STAIL,88210,ST STAIL,,48.3781845866,7.08693862118,436,,Saint-Stail,Saint-Stail,88,Vosges,44,Grand Est
88437,ST VALLIER,88270,ST VALLIER,,48.2777545951,6.3206668903,437,,Saint-Vallier,Saint-Vallier,88,Vosges,44,Grand Est
88438,LA SALLE,88470,LA SALLE,,48.3240158454,6.81120161943,438,La,Salle,La Salle,88,Vosges,44,Grand Est
88439,SANCHEY,88390,SANCHEY,,48.1669458665,6.36802585662,439,,Sanchey,Sanchey,88,Vosges,44,Grand Est
88440,SANDAUCOURT,88170,SANDAUCOURT,,48.2612303664,5.84458431334,440,,Sandaucourt,Sandaucourt,88,Vosges,44,Grand Est
88441,SANS VALLOIS,88260,SANS VALLOIS,,48.1617644637,6.1030177625,441,,Sans-Vallois,Sans-Vallois,88,Vosges,44,Grand Est
88442,SAPOIS,88120,SAPOIS,,48.0357712575,6.78229543437,442,,Sapois,Sapois,88,Vosges,44,Grand Est
88443,SARTES,88300,SARTES,,48.2431645689,5.66789266323,443,,Sartes,Sartes,88,Vosges,44,Grand Est
88444,LE SAULCY,88210,LE SAULCY,,48.4235362189,7.06550788795,444,Le,Saulcy,Le Saulcy,88,Vosges,44,Grand Est
88445,SAULCY SUR MEURTHE,88580,SAULCY SUR MEURTHE,,48.2424754243,6.94912538655,445,,Saulcy-sur-Meurthe,Saulcy-sur-Meurthe,88,Vosges,44,Grand Est
88446,SAULXURES LES BULGNEVILLE,88140,SAULXURES LES BULGNEVILLE,,48.1919857407,5.81576543656,446,,Saulxures-lès-Bulgnéville,Saulxures-lès-Bulgnéville,88,Vosges,44,Grand Est
88447,SAULXURES SUR MOSELOTTE,88290,SAULXURES SUR MOSELOTTE,,47.9512129215,6.77493861259,447,,Saulxures-sur-Moselotte,Saulxures-sur-Moselotte,88,Vosges,44,Grand Est
88448,SAUVILLE,88140,SAUVILLE,,48.1485506254,5.74281944188,448,,Sauville,Sauville,88,Vosges,44,Grand Est
88449,SAVIGNY,88130,SAVIGNY,,48.3555922592,6.21861150216,449,,Savigny,Savigny,88,Vosges,44,Grand Est
88450,SENAIDE,88320,SENAIDE,,47.9694031418,5.80408230955,450,,Senaide,Senaide,88,Vosges,44,Grand Est
88451,SENONES,88210,SENONES,,48.4067567692,6.9636413109,451,,Senones,Senones,88,Vosges,44,Grand Est
88452,SENONGES,88260,SENONGES,,48.1425352488,6.05690466716,452,,Senonges,Senonges,88,Vosges,44,Grand Est
88453,SERAUMONT,88630,SERAUMONT,,48.4306580268,5.60835079451,453,,Seraumont,Seraumont,88,Vosges,44,Grand Est
88454,SERCOEUR,88600,SERCOEUR,,48.2559068435,6.53631302887,454,,Sercœur,Sercœur,88,Vosges,44,Grand Est
88455,SERECOURT,88320,SERECOURT,,48.0500055011,5.84436217974,455,,Serécourt,Serécourt,88,Vosges,44,Grand Est
88456,SEROCOURT,88320,SEROCOURT,,48.0981009125,5.89081859551,456,,Serocourt,Serocourt,88,Vosges,44,Grand Est
88457,SIONNE,88630,SIONNE,,48.3981193359,5.62852785877,457,,Sionne,Sionne,88,Vosges,44,Grand Est
88458,SOCOURT,88130,SOCOURT,,48.3963161508,6.24974565871,458,,Socourt,Socourt,88,Vosges,44,Grand Est
88459,SONCOURT,88170,SONCOURT,,48.3879629966,5.90800491038,459,,Soncourt,Soncourt,88,Vosges,44,Grand Est
88460,SOULOSSE SOUS ST ELOPHE,88630,SOULOSSE SOUS ST ELOPHE,,48.4139044726,5.73896632817,460,,Soulosse-sous-Saint-Élophe,Soulosse-sous-Saint-Élophe,88,Vosges,44,Grand Est
88461,SURIAUVILLE,88140,SURIAUVILLE,,48.1666133431,5.86398613974,461,,Suriauville,Suriauville,88,Vosges,44,Grand Est
88462,LE SYNDICAT,88120,LE SYNDICAT,,48.0311250324,6.69827202469,462,Le,Syndicat,Le Syndicat,88,Vosges,44,Grand Est
88462,LE SYNDICAT,88120,LE SYNDICAT,JULIENRUPT,48.0311250324,6.69827202469,462,Le,Syndicat,Le Syndicat,88,Vosges,44,Grand Est
88463,TAINTRUX,88100,TAINTRUX,,48.2503150047,6.88300452991,463,,Taintrux,Taintrux,88,Vosges,44,Grand Est
88464,TENDON,88460,TENDON,,48.1081118464,6.67533830066,464,,Tendon,Tendon,88,Vosges,44,Grand Est
88465,CAPAVENIR VOSGES,88150,CAPAVENIR VOSGES,GIRMONT,48.2495514114,6.40863836497,465,,Capavenir Vosges,Capavenir Vosges,88,Vosges,44,Grand Est
88465,CAPAVENIR VOSGES,88150,CAPAVENIR VOSGES,ONCOURT,48.2495514114,6.40863836497,465,,Capavenir Vosges,Capavenir Vosges,88,Vosges,44,Grand Est
88465,CAPAVENIR VOSGES,88150,CAPAVENIR VOSGES,THAON LES VOSGES,48.2495514114,6.40863836497,465,,Capavenir Vosges,Capavenir Vosges,88,Vosges,44,Grand Est
88466,THEY SOUS MONTFORT,88800,THEY SOUS MONTFORT,,48.2295778524,5.97261217234,466,,They-sous-Montfort,They-sous-Montfort,88,Vosges,44,Grand Est
88467,THIEFOSSE,88290,THIEFOSSE,,47.9659189559,6.72991911835,467,,Thiéfosse,Thiéfosse,88,Vosges,44,Grand Est
88468,LE THILLOT,88160,LE THILLOT,,47.8795029889,6.76927057164,468,Le,Thillot,Le Thillot,88,Vosges,44,Grand Est
88469,THIRAUCOURT,88500,THIRAUCOURT,,48.292756795,6.07454050117,469,,Thiraucourt,Thiraucourt,88,Vosges,44,Grand Est
88470,LE THOLY,88530,LE THOLY,,48.0875038996,6.73898025546,470,Le,Tholy,Le Tholy,88,Vosges,44,Grand Est
88470,LE THOLY,88530,LE THOLY,BOUVACOTE,48.0875038996,6.73898025546,470,Le,Tholy,Le Tholy,88,Vosges,44,Grand Est
88471,LES THONS,88410,LES THONS,,47.987988516,5.8737744025,471,Les,Thons,Les Thons,88,Vosges,44,Grand Est
88472,THUILLIERES,88260,THUILLIERES,,48.1533673161,6.01138810297,472,,Thuillières,Thuillières,88,Vosges,44,Grand Est
88473,TIGNECOURT,88320,TIGNECOURT,,48.0453029268,5.89454897704,473,,Tignécourt,Tignécourt,88,Vosges,44,Grand Est
88474,TILLEUX,88300,TILLEUX,,48.300687852,5.73148018927,474,,Tilleux,Tilleux,88,Vosges,44,Grand Est
88475,TOLLAINCOURT,88320,TOLLAINCOURT,,48.0944938013,5.72324899946,475,,Tollaincourt,Tollaincourt,88,Vosges,44,Grand Est
88476,TOTAINVILLE,88500,TOTAINVILLE,,48.3263637306,5.98420590394,476,,Totainville,Totainville,88,Vosges,44,Grand Est
88477,TRAMPOT,88350,TRAMPOT,,48.3555814524,5.44245742995,477,,Trampot,Trampot,88,Vosges,44,Grand Est
88478,TRANQUEVILLE GRAUX,88300,TRANQUEVILLE GRAUX,,48.4392316325,5.8449609523,478,,Tranqueville-Graux,Tranqueville-Graux,88,Vosges,44,Grand Est
88479,TREMONZEY,88240,TREMONZEY,,47.9620006058,6.2472825127,479,,Trémonzey,Trémonzey,88,Vosges,44,Grand Est
88480,UBEXY,88130,UBEXY,,48.3372090624,6.28045582806,480,,Ubexy,Ubexy,88,Vosges,44,Grand Est
88481,URIMENIL,88220,URIMENIL,,48.1038996334,6.40140571468,481,,Uriménil,Uriménil,88,Vosges,44,Grand Est
88482,URVILLE,88140,URVILLE,,48.1905034513,5.74420469938,482,,Urville,Urville,88,Vosges,44,Grand Est
88483,UXEGNEY,88390,UXEGNEY,,48.1963376541,6.38201358618,483,,Uxegney,Uxegney,88,Vosges,44,Grand Est
88484,UZEMAIN,88220,UZEMAIN,,48.1015554236,6.34403529547,484,,Uzemain,Uzemain,88,Vosges,44,Grand Est
88485,LA VACHERESSE ET LA ROUILLIE,88140,LA VACHERESSE ET LA ROUILLIE,,48.1460226049,5.79618923036,485,La,Vacheresse-et-la-Rouillie,La Vacheresse-et-la-Rouillie,88,Vosges,44,Grand Est
88486,VAGNEY,88120,VAGNEY,,48.0180970849,6.72440624988,486,,Vagney,Vagney,88,Vosges,44,Grand Est
88487,LE VAL D AJOL,88340,LE VAL D AJOL,,47.9421994334,6.50504695363,487,Le,Val-d'Ajol,Le Val-d'Ajol,88,Vosges,44,Grand Est
88488,VALFROICOURT,88270,VALFROICOURT,,48.1957789984,6.08889066839,488,,Valfroicourt,Valfroicourt,88,Vosges,44,Grand Est
88489,VALLEROY AUX SAULES,88270,VALLEROY AUX SAULES,,48.2439694822,6.13809039258,489,,Valleroy-aux-Saules,Valleroy-aux-Saules,88,Vosges,44,Grand Est
88490,VALLEROY LE SEC,88800,VALLEROY LE SEC,,48.184872138,6.0007659937,490,,Valleroy-le-Sec,Valleroy-le-Sec,88,Vosges,44,Grand Est
88491,LES VALLOIS,88260,LES VALLOIS,,48.1560227971,6.12111135495,491,Les,Vallois,Les Vallois,88,Vosges,44,Grand Est
88492,LE VALTIN,88230,LE VALTIN,,48.0883852137,7.02878596323,492,Le,Valtin,Le Valtin,88,Vosges,44,Grand Est
88493,VARMONZEY,88450,VARMONZEY,,48.3232458898,6.28031877761,493,,Varmonzey,Varmonzey,88,Vosges,44,Grand Est
88494,VAUBEXY,88500,VAUBEXY,,48.283956514,6.23614210512,494,,Vaubexy,Vaubexy,88,Vosges,44,Grand Est
88495,VAUDEVILLE,88000,VAUDEVILLE,,48.2298063128,6.53994247926,495,,Vaudéville,Vaudéville,88,Vosges,44,Grand Est
88496,VAUDONCOURT,88140,VAUDONCOURT,,48.2205661672,5.80563694701,496,,Vaudoncourt,Vaudoncourt,88,Vosges,44,Grand Est
88497,VAXONCOURT,88330,VAXONCOURT,,48.2872881144,6.4200843281,497,,Vaxoncourt,Vaxoncourt,88,Vosges,44,Grand Est
88498,VECOUX,88200,VECOUX,,47.9697988372,6.66320804363,498,,Vecoux,Vecoux,88,Vosges,44,Grand Est
88499,VELOTTE ET TATIGNECOURT,88270,VELOTTE ET TATIGNECOURT,,48.259556916,6.17166162711,499,,Velotte-et-Tatignécourt,Velotte-et-Tatignécourt,88,Vosges,44,Grand Est
88500,VENTRON,88310,VENTRON,,47.9369910932,6.88525672777,500,,Ventron,Ventron,88,Vosges,44,Grand Est
88501,LE VERMONT,88210,LE VERMONT,,48.388919763,7.06729393945,501,Le,Vermont,Le Vermont,88,Vosges,44,Grand Est
88502,VERVEZELLE,88600,VERVEZELLE,,48.2208582004,6.74098838874,502,,Vervezelle,Vervezelle,88,Vosges,44,Grand Est
88503,VEXAINCOURT,88110,VEXAINCOURT,,48.4815540361,7.06463312135,503,,Vexaincourt,Vexaincourt,88,Vosges,44,Grand Est
88504,VICHEREY,88170,VICHEREY,,48.382979978,5.93580495171,504,,Vicherey,Vicherey,88,Vosges,44,Grand Est
88505,VIENVILLE,88430,VIENVILLE,,48.1775009345,6.84473927135,505,,Vienville,Vienville,88,Vosges,44,Grand Est
88506,VIEUX MOULIN,88210,VIEUX MOULIN,,48.39320709,7.00559918116,506,,Vieux-Moulin,Vieux-Moulin,88,Vosges,44,Grand Est
88507,VILLERS,88500,VILLERS,,48.3051053835,6.1735092442,507,,Villers,Villers,88,Vosges,44,Grand Est
88508,VILLE SUR ILLON,88270,VILLE SUR ILLON,,48.1703233891,6.21202219636,508,,Ville-sur-Illon,Ville-sur-Illon,88,Vosges,44,Grand Est
88509,VILLONCOURT,88150,VILLONCOURT,,48.2696270418,6.51634563394,509,,Villoncourt,Villoncourt,88,Vosges,44,Grand Est
88510,VILLOTTE,88320,VILLOTTE,,48.1042926923,5.7690094936,510,,Villotte,Villotte,88,Vosges,44,Grand Est
88511,VILLOUXEL,88350,VILLOUXEL,,48.3475437712,5.58000245649,511,,Villouxel,Villouxel,88,Vosges,44,Grand Est
88512,VIMENIL,88600,VIMENIL,,48.2167970893,6.63877220998,512,,Viménil,Viménil,88,Vosges,44,Grand Est
88513,VINCEY,88450,VINCEY,,48.3305229808,6.32859572261,513,,Vincey,Vincey,88,Vosges,44,Grand Est
88514,VIOCOURT,88170,VIOCOURT,,48.3215627677,5.87101635049,514,,Viocourt,Viocourt,88,Vosges,44,Grand Est
88515,VIOMENIL,88260,VIOMENIL,,48.0901280753,6.1705515055,515,,Vioménil,Vioménil,88,Vosges,44,Grand Est
88516,VITTEL,88800,VITTEL,,48.2012893937,5.95449403029,516,,Vittel,Vittel,88,Vosges,44,Grand Est
88517,VIVIERS LE GRAS,88260,VIVIERS LE GRAS,,48.1207853858,5.93801422421,517,,Viviers-le-Gras,Viviers-le-Gras,88,Vosges,44,Grand Est
88518,VIVIERS LES OFFROICOURT,88500,VIVIERS LES OFFROICOURT,,48.2687079823,6.01804838798,518,,Viviers-lès-Offroicourt,Viviers-lès-Offroicourt,88,Vosges,44,Grand Est
88519,LA VOIVRE,88470,LA VOIVRE,,48.3414006349,6.89978411056,519,La,Voivre,La Voivre,88,Vosges,44,Grand Est
88520,LES VOIVRES,88240,LES VOIVRES,,48.0348359159,6.29572861072,520,Les,Voivres,Les Voivres,88,Vosges,44,Grand Est
88521,VOMECOURT,88700,VOMECOURT,,48.3135963536,6.61490143482,521,,Vomécourt,Vomécourt,88,Vosges,44,Grand Est
88522,VOMECOURT SUR MADON,88500,VOMECOURT SUR MADON,,48.3618465274,6.17001912809,522,,Vomécourt-sur-Madon,Vomécourt-sur-Madon,88,Vosges,44,Grand Est
88523,VOUXEY,88170,VOUXEY,,48.3477921653,5.79087280043,523,,Vouxey,Vouxey,88,Vosges,44,Grand Est
88523,VOUXEY,88170,VOUXEY,IMBRECOURT,48.3477921653,5.79087280043,523,,Vouxey,Vouxey,88,Vosges,44,Grand Est
88524,VRECOURT,88140,VRECOURT,,48.1702434521,5.70465763455,524,,Vrécourt,Vrécourt,88,Vosges,44,Grand Est
88525,VROVILLE,88500,VROVILLE,,48.2872761929,6.17216170005,525,,Vroville,Vroville,88,Vosges,44,Grand Est
88526,WISEMBACH,88520,WISEMBACH,,48.2614197049,7.11887100206,526,,Wisembach,Wisembach,88,Vosges,44,Grand Est
88527,XAFFEVILLERS,88700,XAFFEVILLERS,,48.4106397675,6.60870252101,527,,Xaffévillers,Xaffévillers,88,Vosges,44,Grand Est
88528,XAMONTARUPT,88460,XAMONTARUPT,,48.1237337157,6.64357894957,528,,Xamontarupt,Xamontarupt,88,Vosges,44,Grand Est
88529,XARONVAL,88130,XARONVAL,,48.3720629452,6.18667551387,529,,Xaronval,Xaronval,88,Vosges,44,Grand Est
88530,XERTIGNY,88220,XERTIGNY,,48.0387121214,6.38237551394,530,,Xertigny,Xertigny,88,Vosges,44,Grand Est
88530,XERTIGNY,88220,XERTIGNY,AMEREY,48.0387121214,6.38237551394,530,,Xertigny,Xertigny,88,Vosges,44,Grand Est
88531,XONRUPT LONGEMER,88400,XONRUPT LONGEMER,,48.0716092272,6.95374586425,531,,Xonrupt-Longemer,Xonrupt-Longemer,88,Vosges,44,Grand Est
88532,ZINCOURT,88330,ZINCOURT,,48.3089875469,6.44246020697,532,,Zincourt,Zincourt,88,Vosges,44,Grand Est
89001,ACCOLAY,89460,ACCOLAY,,47.6562162048,3.70516767452,1,,Accolay,Accolay,89,Yonne,27,Bourgogne-Franche-Comté
89002,AIGREMONT,89800,AIGREMONT,,47.7181013588,3.89038343982,2,,Aigremont,Aigremont,89,Yonne,27,Bourgogne-Franche-Comté
89003,AILLANT SUR THOLON,89110,AILLANT SUR THOLON,,47.8710829792,3.32834802576,3,,Montholon,Montholon,89,Yonne,27,Bourgogne-Franche-Comté
89004,AISY SUR ARMANCON,89390,AISY SUR ARMANCON,,47.6569574082,4.2090837181,4,,Aisy-sur-Armançon,Aisy-sur-Armançon,89,Yonne,27,Bourgogne-Franche-Comté
89005,ANCY LE FRANC,89160,ANCY LE FRANC,,47.7772837074,4.16461279806,5,,Ancy-le-Franc,Ancy-le-Franc,89,Yonne,27,Bourgogne-Franche-Comté
89005,ANCY LE FRANC,89160,ANCY LE FRANC,CUSY,47.7772837074,4.16461279806,5,,Ancy-le-Franc,Ancy-le-Franc,89,Yonne,27,Bourgogne-Franche-Comté
89006,ANCY LE LIBRE,89160,ANCY LE LIBRE,,47.8056331303,4.14511090809,6,,Ancy-le-Libre,Ancy-le-Libre,89,Yonne,27,Bourgogne-Franche-Comté
89007,ANDRYES,89480,ANDRYES,,47.5302279519,3.46612181132,7,,Andryes,Andryes,89,Yonne,27,Bourgogne-Franche-Comté
89008,ANGELY,89440,ANGELY,,47.560677405,4.01177532833,8,,Angely,Angely,89,Yonne,27,Bourgogne-Franche-Comté
89009,ANNAY LA COTE,89200,ANNAY LA COTE,,47.5529211916,3.87020615166,9,,Annay-la-Côte,Annay-la-Côte,89,Yonne,27,Bourgogne-Franche-Comté
89010,ANNAY SUR SEREIN,89310,ANNAY SUR SEREIN,,47.728708318,3.96334270407,10,,Annay-sur-Serein,Annay-sur-Serein,89,Yonne,27,Bourgogne-Franche-Comté
89011,ANNEOT,89200,ANNEOT,,47.5167662768,3.87931663597,11,,Annéot,Annéot,89,Yonne,27,Bourgogne-Franche-Comté
89012,ANNOUX,89440,ANNOUX,,47.6235485388,4.0510085082,12,,Annoux,Annoux,89,Yonne,27,Bourgogne-Franche-Comté
89013,APPOIGNY,89380,APPOIGNY,,47.8663621082,3.51999342457,13,,Appoigny,Appoigny,89,Yonne,27,Bourgogne-Franche-Comté
89014,ARCES DILO,89320,ARCES DILO,,48.0983124946,3.59434299703,14,,Arces-Dilo,Arces-Dilo,89,Yonne,27,Bourgogne-Franche-Comté
89014,ARCES DILO,89320,ARCES DILO,DILO,48.0983124946,3.59434299703,14,,Arces-Dilo,Arces-Dilo,89,Yonne,27,Bourgogne-Franche-Comté
89015,ARCY SUR CURE,89270,ARCY SUR CURE,,47.5964840331,3.76078082022,15,,Arcy-sur-Cure,Arcy-sur-Cure,89,Yonne,27,Bourgogne-Franche-Comté
89016,ARGENTENAY,89160,ARGENTENAY,,47.822524019,4.11478245867,16,,Argentenay,Argentenay,89,Yonne,27,Bourgogne-Franche-Comté
89017,ARGENTEUIL SUR ARMANCON,89160,ARGENTEUIL SUR ARMANCON,,47.7437885259,4.10154534909,17,,Argenteuil-sur-Armançon,Argenteuil-sur-Armançon,89,Yonne,27,Bourgogne-Franche-Comté
89018,ARMEAU,89500,ARMEAU,,48.0478451698,3.3370553938,18,,Armeau,Armeau,89,Yonne,27,Bourgogne-Franche-Comté
89019,ARTHONNAY,89740,ARTHONNAY,,47.9316900287,4.22973303932,19,,Arthonnay,Arthonnay,89,Yonne,27,Bourgogne-Franche-Comté
89020,ASNIERES SOUS BOIS,89660,ASNIERES SOUS BOIS,,47.4789567606,3.63907394341,20,,Asnières-sous-Bois,Asnières-sous-Bois,89,Yonne,27,Bourgogne-Franche-Comté
89021,ASQUINS,89450,ASQUINS,,47.4878050152,3.74721603984,21,,Asquins,Asquins,89,Yonne,27,Bourgogne-Franche-Comté
89022,ATHIE,89440,ATHIE,,47.5394434653,3.99227435299,22,,Athie,Athie,89,Yonne,27,Bourgogne-Franche-Comté
89023,AUGY,89290,AUGY,,47.7699223048,3.62188517818,23,,Augy,Augy,89,Yonne,27,Bourgogne-Franche-Comté
89024,AUXERRE,89000,AUXERRE,,47.793488225,3.58168281761,24,,Auxerre,Auxerre,89,Yonne,27,Bourgogne-Franche-Comté
89024,AUXERRE,89290,AUXERRE,VAUX,47.793488225,3.58168281761,24,,Auxerre,Auxerre,89,Yonne,27,Bourgogne-Franche-Comté
89025,AVALLON,89200,AVALLON,,47.4824789933,3.91585953972,25,,Avallon,Avallon,89,Yonne,27,Bourgogne-Franche-Comté
89027,BAGNEAUX,89190,BAGNEAUX,,48.2544532478,3.59222346559,27,,Bagneaux,Bagneaux,89,Yonne,27,Bourgogne-Franche-Comté
89028,BAON,89430,BAON,,47.8620600541,4.13805899228,28,,Baon,Baon,89,Yonne,27,Bourgogne-Franche-Comté
89029,BASSOU,89400,BASSOU,,47.9228104601,3.50819199068,29,,Bassou,Bassou,89,Yonne,27,Bourgogne-Franche-Comté
89030,BAZARNES,89460,BAZARNES,,47.6601599363,3.64377288965,30,,Bazarnes,Bazarnes,89,Yonne,27,Bourgogne-Franche-Comté
89031,BEAUMONT,89250,BEAUMONT,,47.9149271297,3.55727569805,31,,Beaumont,Beaumont,89,Yonne,27,Bourgogne-Franche-Comté
89032,BEAUVILLIERS,89630,BEAUVILLIERS,,47.4132193226,4.03577832193,32,,Beauvilliers,Beauvilliers,89,Yonne,27,Bourgogne-Franche-Comté
89033,BEAUVOIR,89240,BEAUVOIR,,47.7920347312,3.38017414614,33,,Beauvoir,Beauvoir,89,Yonne,27,Bourgogne-Franche-Comté
89034,BEINE,89800,BEINE,,47.816668822,3.72338396242,34,,Beine,Beine,89,Yonne,27,Bourgogne-Franche-Comté
89035,BELLECHAUME,89210,BELLECHAUME,,48.053614787,3.58244232303,35,,Bellechaume,Bellechaume,89,Yonne,27,Bourgogne-Franche-Comté
89036,LA BELLIOLE,89150,LA BELLIOLE,,48.1448835973,3.08579739093,36,La,Belliole,La Belliole,89,Yonne,27,Bourgogne-Franche-Comté
89037,BEON,89410,BEON,,47.9490435845,3.31322327005,37,,Béon,Béon,89,Yonne,27,Bourgogne-Franche-Comté
89038,BERNOUIL,89360,BERNOUIL,,47.9024258939,3.89713447047,38,,Bernouil,Bernouil,89,Yonne,27,Bourgogne-Franche-Comté
89039,BERU,89700,BERU,,47.8035904296,3.88582058467,39,,Béru,Béru,89,Yonne,27,Bourgogne-Franche-Comté
89040,BESSY SUR CURE,89270,BESSY SUR CURE,,47.6194313018,3.72664965671,40,,Bessy-sur-Cure,Bessy-sur-Cure,89,Yonne,27,Bourgogne-Franche-Comté
89041,BEUGNON,89570,BEUGNON,,48.0194485737,3.79563603666,41,,Beugnon,Beugnon,89,Yonne,27,Bourgogne-Franche-Comté
89042,BIERRY LES BELLES FONTAINES,89420,BIERRY LES BELLES FONTAINES,,47.6159994404,4.17905397705,42,,Bierry-les-Belles-Fontaines,Bierry-les-Belles-Fontaines,89,Yonne,27,Bourgogne-Franche-Comté
89043,BLACY,89440,BLACY,,47.5817239595,4.0408057451,43,,Blacy,Blacy,89,Yonne,27,Bourgogne-Franche-Comté
89044,BLANNAY,89200,BLANNAY,,47.533737182,3.76206481409,44,,Blannay,Blannay,89,Yonne,27,Bourgogne-Franche-Comté
89045,BLEIGNY LE CARREAU,89230,BLEIGNY LE CARREAU,,47.8317186329,3.67738872198,45,,Bleigny-le-Carreau,Bleigny-le-Carreau,89,Yonne,27,Bourgogne-Franche-Comté
89046,BLENEAU,89220,BLENEAU,,47.7030312917,2.94847810373,46,,Bléneau,Bléneau,89,Yonne,27,Bourgogne-Franche-Comté
89048,BOEURS EN OTHE,89770,BOEURS EN OTHE,,48.1398712988,3.70632804509,48,,Bœurs-en-Othe,Bœurs-en-Othe,89,Yonne,27,Bourgogne-Franche-Comté
89049,BOIS D ARCY,89660,BOIS D ARCY,,47.5510372024,3.72064912624,49,,Bois-d'Arcy,Bois-d'Arcy,89,Yonne,27,Bourgogne-Franche-Comté
89050,BONNARD,89400,BONNARD,,47.9295214402,3.53452583503,50,,Bonnard,Bonnard,89,Yonne,27,Bourgogne-Franche-Comté
89051,LES BORDES,89500,LES BORDES,,48.1095935384,3.38967473325,51,Les,Bordes,Les Bordes,89,Yonne,27,Bourgogne-Franche-Comté
89053,BRANCHES,89113,BRANCHES,,47.876336004,3.48199156683,53,,Branches,Branches,89,Yonne,27,Bourgogne-Franche-Comté
89054,BRANNAY,89150,BRANNAY,,48.2273397765,3.12512459737,54,,Brannay,Brannay,89,Yonne,27,Bourgogne-Franche-Comté
89055,BRIENON SUR ARMANCON,89210,BRIENON SUR ARMANCON,,48.0009924544,3.61784777108,55,,Brienon-sur-Armançon,Brienon-sur-Armançon,89,Yonne,27,Bourgogne-Franche-Comté
89055,BRIENON SUR ARMANCON,89210,BRIENON SUR ARMANCON,BLIGNY EN OTHE,48.0009924544,3.61784777108,55,,Brienon-sur-Armançon,Brienon-sur-Armançon,89,Yonne,27,Bourgogne-Franche-Comté
89056,BRION,89400,BRION,,47.9995517691,3.47900609014,56,,Brion,Brion,89,Yonne,27,Bourgogne-Franche-Comté
89057,BROSSES,89660,BROSSES,,47.5311094024,3.68947320566,57,,Brosses,Brosses,89,Yonne,27,Bourgogne-Franche-Comté
89058,BUSSIERES,89630,BUSSIERES,,47.4274169611,4.05359315765,58,,Bussières,Bussières,89,Yonne,27,Bourgogne-Franche-Comté
89059,BUSSY EN OTHE,89400,BUSSY EN OTHE,,48.0413773601,3.50603239143,59,,Bussy-en-Othe,Bussy-en-Othe,89,Yonne,27,Bourgogne-Franche-Comté
89060,BUSSY LE REPOS,89500,BUSSY LE REPOS,,48.0546456721,3.21957440415,60,,Bussy-le-Repos,Bussy-le-Repos,89,Yonne,27,Bourgogne-Franche-Comté
89061,BUTTEAUX,89360,BUTTEAUX,,47.9838665188,3.82197374039,61,,Butteaux,Butteaux,89,Yonne,27,Bourgogne-Franche-Comté
89062,CARISEY,89360,CARISEY,,47.9227804565,3.84516630553,62,,Carisey,Carisey,89,Yonne,27,Bourgogne-Franche-Comté
89063,LA CELLE ST CYR,89116,LA CELLE ST CYR,,47.9792470887,3.28365784022,63,La,Celle-Saint-Cyr,La Celle-Saint-Cyr,89,Yonne,27,Bourgogne-Franche-Comté
89064,CENSY,89310,CENSY,,47.6996207906,4.04463682832,64,,Censy,Censy,89,Yonne,27,Bourgogne-Franche-Comté
89065,CERILLY,89320,CERILLY,,48.1772376965,3.62550195723,65,,Cérilly,Cérilly,89,Yonne,27,Bourgogne-Franche-Comté
89066,CERISIERS,89320,CERISIERS,,48.1190156789,3.49841417451,66,,Cerisiers,Cerisiers,89,Yonne,27,Bourgogne-Franche-Comté
89067,CEZY,89410,CEZY,,47.9958317123,3.31537354037,67,,Cézy,Cézy,89,Yonne,27,Bourgogne-Franche-Comté
89068,CHABLIS,89800,CHABLIS,,47.8075995199,3.79091424228,68,,Chablis,Chablis,89,Yonne,27,Bourgogne-Franche-Comté
89068,CHABLIS,89800,CHABLIS,FYE,47.8075995199,3.79091424228,68,,Chablis,Chablis,89,Yonne,27,Bourgogne-Franche-Comté
89068,CHABLIS,89800,CHABLIS,MILLY,47.8075995199,3.79091424228,68,,Chablis,Chablis,89,Yonne,27,Bourgogne-Franche-Comté
89068,CHABLIS,89800,CHABLIS,POINCHY,47.8075995199,3.79091424228,68,,Chablis,Chablis,89,Yonne,27,Bourgogne-Franche-Comté
89069,CHAILLEY,89770,CHAILLEY,,48.0904019735,3.68619871667,69,,Chailley,Chailley,89,Yonne,27,Bourgogne-Franche-Comté
89071,CHAMOUX,89660,CHAMOUX,,47.4594591063,3.66121368079,71,,Chamoux,Chamoux,89,Yonne,27,Bourgogne-Franche-Comté
89072,CHAMPCEVRAIS,89220,CHAMPCEVRAIS,,47.756125263,2.97843674082,72,,Champcevrais,Champcevrais,89,Yonne,27,Bourgogne-Franche-Comté
89073,CHAMPIGNELLES,89350,CHAMPIGNELLES,,47.7800283506,3.07775894136,73,,Champignelles,Champignelles,89,Yonne,27,Bourgogne-Franche-Comté
89073,CHAMPIGNELLES,89350,CHAMPIGNELLES,LOUESME,47.7800283506,3.07775894136,73,,Champignelles,Champignelles,89,Yonne,27,Bourgogne-Franche-Comté
89074,CHAMPIGNY,89340,CHAMPIGNY,,48.3042190014,3.13177066366,74,,Champigny,Champigny,89,Yonne,27,Bourgogne-Franche-Comté
89075,CHAMPLAY,89300,CHAMPLAY,,47.9466983822,3.42023668586,75,,Champlay,Champlay,89,Yonne,27,Bourgogne-Franche-Comté
89076,CHAMPLOST,89210,CHAMPLOST,,48.04194277,3.65112821116,76,,Champlost,Champlost,89,Yonne,27,Bourgogne-Franche-Comté
89077,CHAMPS SUR YONNE,89290,CHAMPS SUR YONNE,,47.7506618668,3.60173279363,77,,Champs-sur-Yonne,Champs-sur-Yonne,89,Yonne,27,Bourgogne-Franche-Comté
89078,CHAMPVALLON,89710,CHAMPVALLON,,47.9359055825,3.34413778316,78,,Champvallon,Champvallon,89,Yonne,27,Bourgogne-Franche-Comté
89079,CHAMVRES,89300,CHAMVRES,,47.958898024,3.35363160513,79,,Chamvres,Chamvres,89,Yonne,27,Bourgogne-Franche-Comté
89080,LA CHAPELLE SUR OREUSE,89260,LA CHAPELLE SUR OREUSE,,48.2918213801,3.29933199653,80,La,Chapelle-sur-Oreuse,La Chapelle-sur-Oreuse,89,Yonne,27,Bourgogne-Franche-Comté
89081,LA CHAPELLE VAUPELTEIGNE,89800,LA CHAPELLE VAUPELTEIGNE,,47.8433599438,3.76931059079,81,La,Chapelle-Vaupelteigne,La Chapelle-Vaupelteigne,89,Yonne,27,Bourgogne-Franche-Comté
89083,CHARBUY,89113,CHARBUY,,47.8282503939,3.46650072753,83,,Charbuy,Charbuy,89,Yonne,27,Bourgogne-Franche-Comté
89084,CHARENTENAY,89580,CHARENTENAY,,47.6441683642,3.55593528035,84,,Charentenay,Charentenay,89,Yonne,27,Bourgogne-Franche-Comté
89085,CHARMOY,89400,CHARMOY,,47.9415525641,3.49409153134,85,,Charmoy,Charmoy,89,Yonne,27,Bourgogne-Franche-Comté
89086,CHARNY OREE DE PUISAYE,89120,CHARNY OREE DE PUISAYE,,47.8862119429,3.09764224265,86,,Charny Orée de Puisaye,Charny Orée de Puisaye,89,Yonne,27,Bourgogne-Franche-Comté
89086,CHARNY OREE DE PUISAYE,89120,CHARNY OREE DE PUISAYE,CHAMBEUGLE,47.8862119429,3.09764224265,86,,Charny Orée de Puisaye,Charny Orée de Puisaye,89,Yonne,27,Bourgogne-Franche-Comté
89086,CHARNY OREE DE PUISAYE,89120,CHARNY OREE DE PUISAYE,CHENE ARNOULT,47.8862119429,3.09764224265,86,,Charny Orée de Puisaye,Charny Orée de Puisaye,89,Yonne,27,Bourgogne-Franche-Comté
89086,CHARNY OREE DE PUISAYE,89120,CHARNY OREE DE PUISAYE,CHEVILLON,47.8862119429,3.09764224265,86,,Charny Orée de Puisaye,Charny Orée de Puisaye,89,Yonne,27,Bourgogne-Franche-Comté
89086,CHARNY OREE DE PUISAYE,89120,CHARNY OREE DE PUISAYE,DICY,47.8862119429,3.09764224265,86,,Charny Orée de Puisaye,Charny Orée de Puisaye,89,Yonne,27,Bourgogne-Franche-Comté
89086,CHARNY OREE DE PUISAYE,89120,CHARNY OREE DE PUISAYE,FONTENOUILLES,47.8862119429,3.09764224265,86,,Charny Orée de Puisaye,Charny Orée de Puisaye,89,Yonne,27,Bourgogne-Franche-Comté
89086,CHARNY OREE DE PUISAYE,89120,CHARNY OREE DE PUISAYE,MALICORNE,47.8862119429,3.09764224265,86,,Charny Orée de Puisaye,Charny Orée de Puisaye,89,Yonne,27,Bourgogne-Franche-Comté
89086,CHARNY OREE DE PUISAYE,89120,CHARNY OREE DE PUISAYE,MARCHAIS BETON,47.8862119429,3.09764224265,86,,Charny Orée de Puisaye,Charny Orée de Puisaye,89,Yonne,27,Bourgogne-Franche-Comté
89086,CHARNY OREE DE PUISAYE,89120,CHARNY OREE DE PUISAYE,PERREUX,47.8862119429,3.09764224265,86,,Charny Orée de Puisaye,Charny Orée de Puisaye,89,Yonne,27,Bourgogne-Franche-Comté
89086,CHARNY OREE DE PUISAYE,89120,CHARNY OREE DE PUISAYE,PRUNOY,47.8862119429,3.09764224265,86,,Charny Orée de Puisaye,Charny Orée de Puisaye,89,Yonne,27,Bourgogne-Franche-Comté
89086,CHARNY OREE DE PUISAYE,89120,CHARNY OREE DE PUISAYE,ST DENIS SUR OUANNE,47.8862119429,3.09764224265,86,,Charny Orée de Puisaye,Charny Orée de Puisaye,89,Yonne,27,Bourgogne-Franche-Comté
89086,CHARNY OREE DE PUISAYE,89120,CHARNY OREE DE PUISAYE,ST MARTIN SUR OUANNE,47.8862119429,3.09764224265,86,,Charny Orée de Puisaye,Charny Orée de Puisaye,89,Yonne,27,Bourgogne-Franche-Comté
89086,CHARNY OREE DE PUISAYE,89120,CHARNY OREE DE PUISAYE,VILLEFRANCHE,47.8862119429,3.09764224265,86,,Charny Orée de Puisaye,Charny Orée de Puisaye,89,Yonne,27,Bourgogne-Franche-Comté
89086,CHARNY OREE DE PUISAYE,89350,CHARNY OREE DE PUISAYE,GRANDCHAMP,47.8862119429,3.09764224265,86,,Charny Orée de Puisaye,Charny Orée de Puisaye,89,Yonne,27,Bourgogne-Franche-Comté
89087,CHASSIGNELLES,89160,CHASSIGNELLES,,47.7671856039,4.19625614819,87,,Chassignelles,Chassignelles,89,Yonne,27,Bourgogne-Franche-Comté
89088,CHASSY,89110,CHASSY,,47.8484306457,3.33067916238,88,,Chassy,Chassy,89,Yonne,27,Bourgogne-Franche-Comté
89089,CHASTELLUX SUR CURE,89630,CHASTELLUX SUR CURE,,47.3853669414,3.88478712981,89,,Chastellux-sur-Cure,Chastellux-sur-Cure,89,Yonne,27,Bourgogne-Franche-Comté
89091,CHATEL CENSOIR,89660,CHATEL CENSOIR,,47.5231274585,3.63789696158,91,,Châtel-Censoir,Châtel-Censoir,89,Yonne,27,Bourgogne-Franche-Comté
89092,CHATEL GERARD,89310,CHATEL GERARD,,47.6281248856,4.10542157906,92,,Châtel-Gérard,Châtel-Gérard,89,Yonne,27,Bourgogne-Franche-Comté
89093,CHAUMONT,89340,CHAUMONT,,48.3098919789,3.10062087722,93,,Chaumont,Chaumont,89,Yonne,27,Bourgogne-Franche-Comté
89094,CHAUMOT,89500,CHAUMOT,,48.0831019146,3.202617181,94,,Chaumot,Chaumot,89,Yonne,27,Bourgogne-Franche-Comté
89095,CHEMILLY SUR SEREIN,89800,CHEMILLY SUR SEREIN,,47.7679851847,3.85758757534,95,,Chemilly-sur-Serein,Chemilly-sur-Serein,89,Yonne,27,Bourgogne-Franche-Comté
89096,CHEMILLY SUR YONNE,89250,CHEMILLY SUR YONNE,,47.8967076201,3.55752169454,96,,Chemilly-sur-Yonne,Chemilly-sur-Yonne,89,Yonne,27,Bourgogne-Franche-Comté
89098,CHENEY,89700,CHENEY,,47.9131115913,3.96166576209,98,,Cheney,Cheney,89,Yonne,27,Bourgogne-Franche-Comté
89099,CHENY,89400,CHENY,,47.944917825,3.53719621335,99,,Cheny,Cheny,89,Yonne,27,Bourgogne-Franche-Comté
89100,CHEROY,89690,CHEROY,,48.2016247737,3.00697319562,100,,Chéroy,Chéroy,89,Yonne,27,Bourgogne-Franche-Comté
89101,CHEU,89600,CHEU,,47.9741309455,3.7564449566,101,,Chéu,Chéu,89,Yonne,27,Bourgogne-Franche-Comté
89102,CHEVANNES,89240,CHEVANNES,,47.7544217076,3.49168845995,102,,Chevannes,Chevannes,89,Yonne,27,Bourgogne-Franche-Comté
89104,CHICHEE,89800,CHICHEE,,47.7843995199,3.83340325953,104,,Chichée,Chichée,89,Yonne,27,Bourgogne-Franche-Comté
89105,CHICHERY,89400,CHICHERY,,47.9052375305,3.50840646597,105,,Chichery,Chichery,89,Yonne,27,Bourgogne-Franche-Comté
89108,CHITRY,89530,CHITRY,,47.7680417771,3.70036125317,108,,Chitry,Chitry,89,Yonne,27,Bourgogne-Franche-Comté
89109,CISERY,89420,CISERY,,47.514748364,4.07194538359,109,,Cisery,Cisery,89,Yonne,27,Bourgogne-Franche-Comté
89111,LES CLERIMOIS,89190,LES CLERIMOIS,,48.2289249998,3.45767593908,111,Les,Clérimois,Les Clérimois,89,Yonne,27,Bourgogne-Franche-Comté
89112,COLLAN,89700,COLLAN,,47.840673115,3.87103885933,112,,Collan,Collan,89,Yonne,27,Bourgogne-Franche-Comté
89113,COLLEMIERS,89100,COLLEMIERS,,48.153962733,3.22037217648,113,,Collemiers,Collemiers,89,Yonne,27,Bourgogne-Franche-Comté
89115,COMPIGNY,89140,COMPIGNY,,48.367152724,3.27965951388,115,,Compigny,Compigny,89,Yonne,27,Bourgogne-Franche-Comté
89116,CORNANT,89500,CORNANT,,48.1352293859,3.1876647879,116,,Cornant,Cornant,89,Yonne,27,Bourgogne-Franche-Comté
89117,COULANGERON,89580,COULANGERON,,47.6835328856,3.46990544997,117,,Coulangeron,Coulangeron,89,Yonne,27,Bourgogne-Franche-Comté
89118,COULANGES LA VINEUSE,89580,COULANGES LA VINEUSE,,47.6988804074,3.58612171567,118,,Coulanges-la-Vineuse,Coulanges-la-Vineuse,89,Yonne,27,Bourgogne-Franche-Comté
89119,COULANGES SUR YONNE,89480,COULANGES SUR YONNE,,47.541473076,3.5245175695,119,,Coulanges-sur-Yonne,Coulanges-sur-Yonne,89,Yonne,27,Bourgogne-Franche-Comté
89120,COULOURS,89320,COULOURS,,48.162216856,3.59391352446,120,,Coulours,Coulours,89,Yonne,27,Bourgogne-Franche-Comté
89122,COURGENAY,89190,COURGENAY,,48.2899389178,3.54620843356,122,,Courgenay,Courgenay,89,Yonne,27,Bourgogne-Franche-Comté
89123,COURGIS,89800,COURGIS,,47.7766945271,3.74350328185,123,,Courgis,Courgis,89,Yonne,27,Bourgogne-Franche-Comté
89124,COURLON SUR YONNE,89140,COURLON SUR YONNE,,48.3449808142,3.17786966598,124,,Courlon-sur-Yonne,Courlon-sur-Yonne,89,Yonne,27,Bourgogne-Franche-Comté
89125,COURSON LES CARRIERES,89560,COURSON LES CARRIERES,,47.6001252342,3.50088380074,125,,Courson-les-Carrières,Courson-les-Carrières,89,Yonne,27,Bourgogne-Franche-Comté
89126,COURTOIN,89150,COURTOIN,,48.1216195741,3.10995909647,126,,Courtoin,Courtoin,89,Yonne,27,Bourgogne-Franche-Comté
89127,COURTOIS SUR YONNE,89100,COURTOIS SUR YONNE,,48.2291773603,3.24850168406,127,,Courtois-sur-Yonne,Courtois-sur-Yonne,89,Yonne,27,Bourgogne-Franche-Comté
89128,COUTARNOUX,89440,COUTARNOUX,,47.6024812263,3.94209074467,128,,Coutarnoux,Coutarnoux,89,Yonne,27,Bourgogne-Franche-Comté
89129,CRAIN,89480,CRAIN,,47.5378170554,3.5683240293,129,,Crain,Crain,89,Yonne,27,Bourgogne-Franche-Comté
89130,CRAVANT,89460,CRAVANT,,47.6934813801,3.71458684617,130,,Deux Rivières,Deux Rivières,89,Yonne,27,Bourgogne-Franche-Comté
89131,CRUZY LE CHATEL,89740,CRUZY LE CHATEL,,47.8725277385,4.22845479455,131,,Cruzy-le-Châtel,Cruzy-le-Châtel,89,Yonne,27,Bourgogne-Franche-Comté
89132,CRY,89390,CRY,,47.7033445336,4.22891521589,132,,Cry,Cry,89,Yonne,27,Bourgogne-Franche-Comté
89133,CUDOT,89116,CUDOT,,47.9826568926,3.17485666428,133,,Cudot,Cudot,89,Yonne,27,Bourgogne-Franche-Comté
89134,CUSSY LES FORGES,89420,CUSSY LES FORGES,,47.4633590356,4.02245492798,134,,Cussy-les-Forges,Cussy-les-Forges,89,Yonne,27,Bourgogne-Franche-Comté
89136,CUY,89140,CUY,,48.2564967217,3.27293872125,136,,Cuy,Cuy,89,Yonne,27,Bourgogne-Franche-Comté
89137,DANNEMOINE,89700,DANNEMOINE,,47.8990371208,3.98088333105,137,,Dannemoine,Dannemoine,89,Yonne,27,Bourgogne-Franche-Comté
89139,DIGES,89240,DIGES,,47.719989631,3.40380600353,139,,Diges,Diges,89,Yonne,27,Bourgogne-Franche-Comté
89141,DISSANGIS,89440,DISSANGIS,,47.5932127691,3.98208557179,141,,Dissangis,Dissangis,89,Yonne,27,Bourgogne-Franche-Comté
89142,DIXMONT,89500,DIXMONT,,48.0776099615,3.42365889592,142,,Dixmont,Dixmont,89,Yonne,27,Bourgogne-Franche-Comté
89143,DOLLOT,89150,DOLLOT,,48.2099848495,3.06409212851,143,,Dollot,Dollot,89,Yonne,27,Bourgogne-Franche-Comté
89144,DOMATS,89150,DOMATS,,48.1175257061,3.05983267835,144,,Domats,Domats,89,Yonne,27,Bourgogne-Franche-Comté
89145,DOMECY SUR CURE,89450,DOMECY SUR CURE,,47.4101947733,3.81331129972,145,,Domecy-sur-Cure,Domecy-sur-Cure,89,Yonne,27,Bourgogne-Franche-Comté
89146,DOMECY SUR LE VAULT,89200,DOMECY SUR LE VAULT,,47.489569621,3.81025505067,146,,Domecy-sur-le-Vault,Domecy-sur-le-Vault,89,Yonne,27,Bourgogne-Franche-Comté
89147,DRACY,89130,DRACY,,47.7486734408,3.23090426169,147,,Dracy,Dracy,89,Yonne,27,Bourgogne-Franche-Comté
89148,DRUYES LES BELLES FONTAINES,89560,DRUYES LES BELLES FONTAINES,,47.5588734709,3.41791514779,148,,Druyes-les-Belles-Fontaines,Druyes-les-Belles-Fontaines,89,Yonne,27,Bourgogne-Franche-Comté
89149,DYE,89360,DYE,,47.8921704825,3.86284219994,149,,Dyé,Dyé,89,Yonne,27,Bourgogne-Franche-Comté
89150,EGLENY,89240,EGLENY,,47.8092239346,3.37455573912,150,,Égleny,Égleny,89,Yonne,27,Bourgogne-Franche-Comté
89151,EGRISELLES LE BOCAGE,89500,EGRISELLES LE BOCAGE,,48.1124449966,3.17977417748,151,,Égriselles-le-Bocage,Égriselles-le-Bocage,89,Yonne,27,Bourgogne-Franche-Comté
89152,EPINEAU LES VOVES,89400,EPINEAU LES VOVES,,47.9468458779,3.46998413292,152,,Épineau-les-Voves,Épineau-les-Voves,89,Yonne,27,Bourgogne-Franche-Comté
89153,EPINEUIL,89700,EPINEUIL,,47.8812855119,3.98935069483,153,,Épineuil,Épineuil,89,Yonne,27,Bourgogne-Franche-Comté
89154,ESCAMPS,89240,ESCAMPS,,47.7177692748,3.47307676969,154,,Escamps,Escamps,89,Yonne,27,Bourgogne-Franche-Comté
89155,ESCOLIVES STE CAMILLE,89290,ESCOLIVES STE CAMILLE,,47.7225909756,3.60249190307,155,,Escolives-Sainte-Camille,Escolives-Sainte-Camille,89,Yonne,27,Bourgogne-Franche-Comté
89156,ESNON,89210,ESNON,,48.0079365464,3.5680062853,156,,Esnon,Esnon,89,Yonne,27,Bourgogne-Franche-Comté
89158,ETAIS LA SAUVIN,89480,ETAIS LA SAUVIN,,47.5119567987,3.34972153514,158,,Étais-la-Sauvin,Étais-la-Sauvin,89,Yonne,27,Bourgogne-Franche-Comté
89159,ETAULE,89200,ETAULE,,47.5287117259,3.91412540974,159,,Étaule,Étaule,89,Yonne,27,Bourgogne-Franche-Comté
89160,ETIGNY,89510,ETIGNY,,48.1368850036,3.27571313001,160,,Étigny,Étigny,89,Yonne,27,Bourgogne-Franche-Comté
89161,ETIVEY,89310,ETIVEY,,47.6726045815,4.14690426955,161,,Étivey,Étivey,89,Yonne,27,Bourgogne-Franche-Comté
89162,EVRY,89140,EVRY,,48.2685630682,3.25982109377,162,,Évry,Évry,89,Yonne,27,Bourgogne-Franche-Comté
89163,LA FERTE LOUPIERE,89110,LA FERTE LOUPIERE,,47.8869741845,3.23991180374,163,La,Ferté-Loupière,La Ferté-Loupière,89,Yonne,27,Bourgogne-Franche-Comté
89164,FESTIGNY,89480,FESTIGNY,,47.5587659772,3.53435245124,164,,Festigny,Festigny,89,Yonne,27,Bourgogne-Franche-Comté
89165,FLACY,89190,FLACY,,48.2122713686,3.58723474642,165,,Flacy,Flacy,89,Yonne,27,Bourgogne-Franche-Comté
89167,FLEURY LA VALLEE,89113,FLEURY LA VALLEE,,47.8647388504,3.44469712443,167,,Fleury-la-Vallée,Fleury-la-Vallée,89,Yonne,27,Bourgogne-Franche-Comté
89168,FLEYS,89800,FLEYS,,47.8164766516,3.85937202058,168,,Fleys,Fleys,89,Yonne,27,Bourgogne-Franche-Comté
89169,FLOGNY LA CHAPELLE,89360,FLOGNY LA CHAPELLE,,47.9610543696,3.88394679625,169,,Flogny-la-Chapelle,Flogny-la-Chapelle,89,Yonne,27,Bourgogne-Franche-Comté
89170,FOISSY LES VEZELAY,89450,FOISSY LES VEZELAY,,47.4373067665,3.75900618123,170,,Foissy-lès-Vézelay,Foissy-lès-Vézelay,89,Yonne,27,Bourgogne-Franche-Comté
89171,FOISSY SUR VANNE,89190,FOISSY SUR VANNE,,48.2225776694,3.50568245446,171,,Foissy-sur-Vanne,Foissy-sur-Vanne,89,Yonne,27,Bourgogne-Franche-Comté
89172,FONTAINE LA GAILLARDE,89100,FONTAINE LA GAILLARDE,,48.2246784203,3.4008490535,172,,Fontaine-la-Gaillarde,Fontaine-la-Gaillarde,89,Yonne,27,Bourgogne-Franche-Comté
89173,FONTAINES,89130,FONTAINES,,47.6926210411,3.25560632931,173,,Fontaines,Fontaines,89,Yonne,27,Bourgogne-Franche-Comté
89174,FONTENAILLES,89560,FONTENAILLES,,47.6309292904,3.47197357688,174,,Fontenailles,Fontenailles,89,Yonne,27,Bourgogne-Franche-Comté
89175,FONTENAY PRES CHABLIS,89800,FONTENAY PRES CHABLIS,,47.8431550159,3.81463603577,175,,Fontenay-près-Chablis,Fontenay-près-Chablis,89,Yonne,27,Bourgogne-Franche-Comté
89176,FONTENAY PRES VEZELAY,89450,FONTENAY PRES VEZELAY,,47.4155434556,3.73951403796,176,,Fontenay-près-Vézelay,Fontenay-près-Vézelay,89,Yonne,27,Bourgogne-Franche-Comté
89177,FONTENAY SOUS FOURONNES,89660,FONTENAY SOUS FOURONNES,,47.6214347574,3.60212816173,177,,Fontenay-sous-Fouronnes,Fontenay-sous-Fouronnes,89,Yonne,27,Bourgogne-Franche-Comté
89179,FONTENOY,89520,FONTENOY,,47.6527290115,3.29197116291,179,,Fontenoy,Fontenoy,89,Yonne,27,Bourgogne-Franche-Comté
89180,FOUCHERES,89150,FOUCHERES,,48.1744609196,3.14702856425,180,,Fouchères,Fouchères,89,Yonne,27,Bourgogne-Franche-Comté
89181,FOURNAUDIN,89320,FOURNAUDIN,,48.1486089647,3.64528232884,181,,Fournaudin,Fournaudin,89,Yonne,27,Bourgogne-Franche-Comté
89182,FOURONNES,89560,FOURONNES,,47.6074365854,3.55751684148,182,,Fouronnes,Fouronnes,89,Yonne,27,Bourgogne-Franche-Comté
89183,FRESNES,89310,FRESNES,,47.7625609673,3.99351919937,183,,Fresnes,Fresnes,89,Yonne,27,Bourgogne-Franche-Comté
89184,FULVY,89160,FULVY,,47.7404530701,4.16212844857,184,,Fulvy,Fulvy,89,Yonne,27,Bourgogne-Franche-Comté
89186,GERMIGNY,89600,GERMIGNY,,47.9971165696,3.79141682909,186,,Germigny,Germigny,89,Yonne,27,Bourgogne-Franche-Comté
89187,GIGNY,89160,GIGNY,,47.8287677848,4.30551561383,187,,Gigny,Gigny,89,Yonne,27,Bourgogne-Franche-Comté
89188,GIROLLES,89200,GIROLLES,,47.5460602358,3.83633441975,188,,Girolles,Girolles,89,Yonne,27,Bourgogne-Franche-Comté
89189,GISY LES NOBLES,89140,GISY LES NOBLES,,48.2868565127,3.24944929424,189,,Gisy-les-Nobles,Gisy-les-Nobles,89,Yonne,27,Bourgogne-Franche-Comté
89190,GIVRY,89200,GIVRY,,47.5137032775,3.79475947222,190,,Givry,Givry,89,Yonne,27,Bourgogne-Franche-Comté
89191,GLAND,89740,GLAND,,47.8196707151,4.22248259581,191,,Gland,Gland,89,Yonne,27,Bourgogne-Franche-Comté
89194,GRIMAULT,89310,GRIMAULT,,47.6605721672,3.97653630246,194,,Grimault,Grimault,89,Yonne,27,Bourgogne-Franche-Comté
89195,GRON,89100,GRON,,48.1527042067,3.25754411225,195,,Gron,Gron,89,Yonne,27,Bourgogne-Franche-Comté
89196,VALRAVILLON,89110,VALRAVILLON,LADUZ,47.8968225975,3.42698733417,196,,Valravillon,Valravillon,89,Yonne,27,Bourgogne-Franche-Comté
89196,VALRAVILLON,89113,VALRAVILLON,GUERCHY,47.8968225975,3.42698733417,196,,Valravillon,Valravillon,89,Yonne,27,Bourgogne-Franche-Comté
89196,VALRAVILLON,89113,VALRAVILLON,NEUILLY,47.8968225975,3.42698733417,196,,Valravillon,Valravillon,89,Yonne,27,Bourgogne-Franche-Comté
89196,VALRAVILLON,89113,VALRAVILLON,VILLEMER,47.8968225975,3.42698733417,196,,Valravillon,Valravillon,89,Yonne,27,Bourgogne-Franche-Comté
89197,GUILLON,89420,GUILLON,,47.5289305152,4.09122331538,197,,Guillon,Guillon,89,Yonne,27,Bourgogne-Franche-Comté
89198,GURGY,89250,GURGY,,47.8782473912,3.55956207309,198,,Gurgy,Gurgy,89,Yonne,27,Bourgogne-Franche-Comté
89199,GY L EVEQUE,89580,GY L EVEQUE,,47.7133845487,3.53370265252,199,,Gy-l'Évêque,Gy-l'Évêque,89,Yonne,27,Bourgogne-Franche-Comté
89200,HAUTERIVE,89250,HAUTERIVE,,47.9314503852,3.60699286989,200,,Hauterive,Hauterive,89,Yonne,27,Bourgogne-Franche-Comté
89201,HERY,89550,HERY,,47.8971703868,3.63734153028,201,,Héry,Héry,89,Yonne,27,Bourgogne-Franche-Comté
89202,IRANCY,89290,IRANCY,,47.7118538877,3.68956867667,202,,Irancy,Irancy,89,Yonne,27,Bourgogne-Franche-Comté
89203,ISLAND,89200,ISLAND,,47.460108291,3.85626941235,203,,Island,Island,89,Yonne,27,Bourgogne-Franche-Comté
89204,L ISLE SUR SEREIN,89440,L ISLE SUR SEREIN,,47.5876821889,4.01838047728,204,L',Isle-sur-Serein,L'Isle-sur-Serein,89,Yonne,27,Bourgogne-Franche-Comté
89205,JAULGES,89360,JAULGES,,47.9592889403,3.78678091557,205,,Jaulges,Jaulges,89,Yonne,27,Bourgogne-Franche-Comté
89206,JOIGNY,89300,JOIGNY,,48.0088550804,3.40980180815,206,,Joigny,Joigny,89,Yonne,27,Bourgogne-Franche-Comté
89207,JOUANCY,89310,JOUANCY,,47.6732459964,4.036930891,207,,Jouancy,Jouancy,89,Yonne,27,Bourgogne-Franche-Comté
89208,JOUX LA VILLE,89440,JOUX LA VILLE,,47.6195970225,3.87008209889,208,,Joux-la-Ville,Joux-la-Ville,89,Yonne,27,Bourgogne-Franche-Comté
89209,JOUY,89150,JOUY,,48.1704821186,2.97094498359,209,,Jouy,Jouy,89,Yonne,27,Bourgogne-Franche-Comté
89210,JULLY,89160,JULLY,,47.7745117396,4.29841355038,210,,Jully,Jully,89,Yonne,27,Bourgogne-Franche-Comté
89211,JUNAY,89700,JUNAY,,47.8785014633,3.94207319674,211,,Junay,Junay,89,Yonne,27,Bourgogne-Franche-Comté
89212,JUSSY,89290,JUSSY,,47.7166785125,3.5697968905,212,,Jussy,Jussy,89,Yonne,27,Bourgogne-Franche-Comté
89214,LAILLY,89190,LAILLY,,48.2616892907,3.50441517022,214,,Lailly,Lailly,89,Yonne,27,Bourgogne-Franche-Comté
89215,LAIN,89560,LAIN,,47.611857386,3.34372648975,215,,Lain,Lain,89,Yonne,27,Bourgogne-Franche-Comté
89216,LAINSECQ,89520,LAINSECQ,,47.5486374956,3.27963840045,216,,Lainsecq,Lainsecq,89,Yonne,27,Bourgogne-Franche-Comté
89217,LALANDE,89130,LALANDE,,47.6806210505,3.31236258541,217,,Lalande,Lalande,89,Yonne,27,Bourgogne-Franche-Comté
89218,LAROCHE ST CYDROINE,89400,LAROCHE ST CYDROINE,,47.973029644,3.46898272005,218,,Laroche-Saint-Cydroine,Laroche-Saint-Cydroine,89,Yonne,27,Bourgogne-Franche-Comté
89219,LASSON,89570,LASSON,,48.0641584411,3.81006151723,219,,Lasson,Lasson,89,Yonne,27,Bourgogne-Franche-Comté
89220,LAVAU,89170,LAVAU,,47.5985003916,2.99655139016,220,,Lavau,Lavau,89,Yonne,27,Bourgogne-Franche-Comté
89221,LEUGNY,89130,LEUGNY,,47.6861199694,3.37458168586,221,,Leugny,Leugny,89,Yonne,27,Bourgogne-Franche-Comté
89222,LEVIS,89520,LEVIS,,47.6601955788,3.3333881952,222,,Levis,Levis,89,Yonne,27,Bourgogne-Franche-Comté
89223,LEZINNES,89160,LEZINNES,,47.8039171738,4.07106558888,223,,Lézinnes,Lézinnes,89,Yonne,27,Bourgogne-Franche-Comté
89224,LICHERES PRES AIGREMONT,89800,LICHERES PRES AIGREMONT,,47.7159130853,3.84254969498,224,,Lichères-près-Aigremont,Lichères-près-Aigremont,89,Yonne,27,Bourgogne-Franche-Comté
89225,LICHERES SUR YONNE,89660,LICHERES SUR YONNE,,47.4918181077,3.59463823295,225,,Lichères-sur-Yonne,Lichères-sur-Yonne,89,Yonne,27,Bourgogne-Franche-Comté
89226,LIGNORELLES,89800,LIGNORELLES,,47.8591784948,3.72515040743,226,,Lignorelles,Lignorelles,89,Yonne,27,Bourgogne-Franche-Comté
89227,LIGNY LE CHATEL,89144,LIGNY LE CHATEL,,47.9199212394,3.75312409599,227,,Ligny-le-Châtel,Ligny-le-Châtel,89,Yonne,27,Bourgogne-Franche-Comté
89228,LINDRY,89240,LINDRY,,47.8006537092,3.4288280044,228,,Lindry,Lindry,89,Yonne,27,Bourgogne-Franche-Comté
89229,LIXY,89140,LIXY,,48.2485392063,3.11023826563,229,,Lixy,Lixy,89,Yonne,27,Bourgogne-Franche-Comté
89230,LOOZE,89300,LOOZE,,47.9974732281,3.44023544311,230,,Looze,Looze,89,Yonne,27,Bourgogne-Franche-Comté
89232,LUCY LE BOIS,89200,LUCY LE BOIS,,47.5655695186,3.88870610331,232,,Lucy-le-Bois,Lucy-le-Bois,89,Yonne,27,Bourgogne-Franche-Comté
89233,LUCY SUR CURE,89270,LUCY SUR CURE,,47.637007629,3.76485740571,233,,Lucy-sur-Cure,Lucy-sur-Cure,89,Yonne,27,Bourgogne-Franche-Comté
89233,LUCY SUR CURE,89270,LUCY SUR CURE,ESSERT,47.637007629,3.76485740571,233,,Lucy-sur-Cure,Lucy-sur-Cure,89,Yonne,27,Bourgogne-Franche-Comté
89234,LUCY SUR YONNE,89480,LUCY SUR YONNE,,47.5149808001,3.5673481793,234,,Lucy-sur-Yonne,Lucy-sur-Yonne,89,Yonne,27,Bourgogne-Franche-Comté
89235,MAGNY,89200,MAGNY,,47.4703684401,3.97088291518,235,,Magny,Magny,89,Yonne,27,Bourgogne-Franche-Comté
89236,MAILLOT,89100,MAILLOT,,48.1655005137,3.3148393225,236,,Maillot,Maillot,89,Yonne,27,Bourgogne-Franche-Comté
89237,MAILLY LA VILLE,89270,MAILLY LA VILLE,,47.5882858652,3.69391994411,237,,Mailly-la-Ville,Mailly-la-Ville,89,Yonne,27,Bourgogne-Franche-Comté
89238,MAILLY LE CHATEAU,89660,MAILLY LE CHATEAU,,47.5825000596,3.59908687076,238,,Mailly-le-Château,Mailly-le-Château,89,Yonne,27,Bourgogne-Franche-Comté
89239,MALAY LE GRAND,89100,MALAY LE GRAND,,48.1633768115,3.35131160799,239,,Malay-le-Grand,Malay-le-Grand,89,Yonne,27,Bourgogne-Franche-Comté
89240,MALAY LE PETIT,89100,MALAY LE PETIT,,48.1846386886,3.37642040846,240,,Malay-le-Petit,Malay-le-Petit,89,Yonne,27,Bourgogne-Franche-Comté
89242,MALIGNY,89800,MALIGNY,,47.8664420498,3.79721123196,242,,Maligny,Maligny,89,Yonne,27,Bourgogne-Franche-Comté
89244,MARMEAUX,89420,MARMEAUX,,47.5909987533,4.10578750687,244,,Marmeaux,Marmeaux,89,Yonne,27,Bourgogne-Franche-Comté
89245,MARSANGY,89500,MARSANGY,,48.1141834081,3.24562152715,245,,Marsangy,Marsangy,89,Yonne,27,Bourgogne-Franche-Comté
89246,MASSANGIS,89440,MASSANGIS,,47.6250901149,3.98513574014,246,,Massangis,Massangis,89,Yonne,27,Bourgogne-Franche-Comté
89246,MASSANGIS,89440,MASSANGIS,CIVRY SUR SEREIN,47.6250901149,3.98513574014,246,,Massangis,Massangis,89,Yonne,27,Bourgogne-Franche-Comté
89247,MELISEY,89430,MELISEY,,47.9202574303,4.07551450684,247,,Mélisey,Mélisey,89,Yonne,27,Bourgogne-Franche-Comté
89248,MENADES,89450,MENADES,,47.4427355062,3.83077013253,248,,Menades,Menades,89,Yonne,27,Bourgogne-Franche-Comté
89249,MERCY,89210,MERCY,,48.0300188827,3.62631173753,249,,Mercy,Mercy,89,Yonne,27,Bourgogne-Franche-Comté
89250,MERE,89144,MERE,,47.899133974,3.82008443141,250,,Méré,Méré,89,Yonne,27,Bourgogne-Franche-Comté
89251,MERRY LA VALLEE,89110,MERRY LA VALLEE,,47.7877688234,3.31454038152,251,,Merry-la-Vallée,Merry-la-Vallée,89,Yonne,27,Bourgogne-Franche-Comté
89252,MERRY SEC,89560,MERRY SEC,,47.6581928432,3.48553734083,252,,Merry-Sec,Merry-Sec,89,Yonne,27,Bourgogne-Franche-Comté
89253,MERRY SUR YONNE,89660,MERRY SUR YONNE,,47.5617133971,3.63656103822,253,,Merry-sur-Yonne,Merry-sur-Yonne,89,Yonne,27,Bourgogne-Franche-Comté
89254,MEZILLES,89130,MEZILLES,,47.6924002085,3.181097405,254,,Mézilles,Mézilles,89,Yonne,27,Bourgogne-Franche-Comté
89255,MICHERY,89140,MICHERY,,48.3122378031,3.24557426327,255,,Michery,Michery,89,Yonne,27,Bourgogne-Franche-Comté
89256,MIGE,89580,MIGE,,47.676526095,3.53286271854,256,,Migé,Migé,89,Yonne,27,Bourgogne-Franche-Comté
89257,MIGENNES,89400,MIGENNES,,47.9751844211,3.53087221846,257,,Migennes,Migennes,89,Yonne,27,Bourgogne-Franche-Comté
89259,MOLAY,89310,MOLAY,,47.7385584128,3.93845047834,259,,Môlay,Môlay,89,Yonne,27,Bourgogne-Franche-Comté
89260,MOLESMES,89560,MOLESMES,,47.6074096657,3.45720095368,260,,Molesmes,Molesmes,89,Yonne,27,Bourgogne-Franche-Comté
89261,MOLINONS,89190,MOLINONS,,48.2218670687,3.54417297472,261,,Molinons,Molinons,89,Yonne,27,Bourgogne-Franche-Comté
89262,MOLOSMES,89700,MOLOSMES,,47.9022725609,4.0235561506,262,,Molosmes,Molosmes,89,Yonne,27,Bourgogne-Franche-Comté
89263,MONETEAU,89470,MONETEAU,,47.8490198002,3.5878018416,263,,Monéteau,Monéteau,89,Yonne,27,Bourgogne-Franche-Comté
89263,MONETEAU,89470,MONETEAU,SOUGERES SUR SINOTTE,47.8490198002,3.5878018416,263,,Monéteau,Monéteau,89,Yonne,27,Bourgogne-Franche-Comté
89264,MONTACHER VILLEGARDIN,89150,MONTACHER VILLEGARDIN,,48.1679038657,3.03252223592,264,,Montacher-Villegardin,Montacher-Villegardin,89,Yonne,27,Bourgogne-Franche-Comté
89265,MONTIGNY LA RESLE,89230,MONTIGNY LA RESLE,,47.8623795697,3.68202012303,265,,Montigny-la-Resle,Montigny-la-Resle,89,Yonne,27,Bourgogne-Franche-Comté
89266,MONTILLOT,89660,MONTILLOT,,47.5078896106,3.71695452719,266,,Montillot,Montillot,89,Yonne,27,Bourgogne-Franche-Comté
89267,MONTREAL,89420,MONTREAL,,47.5493086429,4.05005175601,267,,Montréal,Montréal,89,Yonne,27,Bourgogne-Franche-Comté
89268,MONT ST SULPICE,89250,MONT ST SULPICE,,47.9525719548,3.63056586616,268,,Mont-Saint-Sulpice,Mont-Saint-Sulpice,89,Yonne,27,Bourgogne-Franche-Comté
89270,MOUFFY,89560,MOUFFY,,47.6488434572,3.51431901899,270,,Mouffy,Mouffy,89,Yonne,27,Bourgogne-Franche-Comté
89271,MOULINS EN TONNERROIS,89310,MOULINS EN TONNERROIS,,47.729274263,4.04335339652,271,,Moulins-en-Tonnerrois,Moulins-en-Tonnerrois,89,Yonne,27,Bourgogne-Franche-Comté
89272,MOULINS SUR OUANNE,89130,MOULINS SUR OUANNE,,47.7036850443,3.34308179335,272,,Moulins-sur-Ouanne,Moulins-sur-Ouanne,89,Yonne,27,Bourgogne-Franche-Comté
89273,MOUTIERS EN PUISAYE,89520,MOUTIERS EN PUISAYE,,47.608895157,3.16491505392,273,,Moutiers-en-Puisaye,Moutiers-en-Puisaye,89,Yonne,27,Bourgogne-Franche-Comté
89274,NAILLY,89100,NAILLY,,48.2207451981,3.20592737072,274,,Nailly,Nailly,89,Yonne,27,Bourgogne-Franche-Comté
89276,NEUVY SAUTOUR,89570,NEUVY SAUTOUR,,48.0446413017,3.78542965706,276,,Neuvy-Sautour,Neuvy-Sautour,89,Yonne,27,Bourgogne-Franche-Comté
89277,NITRY,89310,NITRY,,47.6765715644,3.87691630334,277,,Nitry,Nitry,89,Yonne,27,Bourgogne-Franche-Comté
89278,NOE,89320,NOE,,48.1523825868,3.39690293506,278,,Noé,Noé,89,Yonne,27,Bourgogne-Franche-Comté
89279,NOYERS,89310,NOYERS,,47.6952215995,3.98085741528,279,,Noyers,Noyers,89,Yonne,27,Bourgogne-Franche-Comté
89280,NUITS,89390,NUITS,,47.7192463173,4.19503154053,280,,Nuits,Nuits,89,Yonne,27,Bourgogne-Franche-Comté
89281,LES ORMES,89110,LES ORMES,,47.8457217659,3.25863457163,281,Les,Ormes,Les Ormes,89,Yonne,27,Bourgogne-Franche-Comté
89282,ORMOY,89400,ORMOY,,47.9553960343,3.57454289298,282,,Ormoy,Ormoy,89,Yonne,27,Bourgogne-Franche-Comté
89283,OUANNE,89560,OUANNE,,47.6575722012,3.4192034023,283,,Ouanne,Ouanne,89,Yonne,27,Bourgogne-Franche-Comté
89283,OUANNE,89560,OUANNE,CHASTENAY,47.6575722012,3.4192034023,283,,Ouanne,Ouanne,89,Yonne,27,Bourgogne-Franche-Comté
89284,PACY SUR ARMANCON,89160,PACY SUR ARMANCON,,47.7701973278,4.07902140785,284,,Pacy-sur-Armançon,Pacy-sur-Armançon,89,Yonne,27,Bourgogne-Franche-Comté
89285,PAILLY,89140,PAILLY,,48.3417730606,3.33362251435,285,,Pailly,Pailly,89,Yonne,27,Bourgogne-Franche-Comté
89286,PARLY,89240,PARLY,,47.7584885598,3.34962637067,286,,Parly,Parly,89,Yonne,27,Bourgogne-Franche-Comté
89287,PARON,89100,PARON,,48.1852396311,3.23953037436,287,,Paron,Paron,89,Yonne,27,Bourgogne-Franche-Comté
89288,PAROY EN OTHE,89210,PAROY EN OTHE,,48.0304051607,3.57534494998,288,,Paroy-en-Othe,Paroy-en-Othe,89,Yonne,27,Bourgogne-Franche-Comté
89289,PAROY SUR THOLON,89300,PAROY SUR THOLON,,47.952420098,3.37491219132,289,,Paroy-sur-Tholon,Paroy-sur-Tholon,89,Yonne,27,Bourgogne-Franche-Comté
89290,PASILLY,89310,PASILLY,,47.7025345935,4.08341992562,290,,Pasilly,Pasilly,89,Yonne,27,Bourgogne-Franche-Comté
89291,PASSY,89510,PASSY,,48.113257139,3.29533793656,291,,Passy,Passy,89,Yonne,27,Bourgogne-Franche-Comté
89292,PERCEY,89360,PERCEY,,47.9687913844,3.8374991594,292,,Percey,Percey,89,Yonne,27,Bourgogne-Franche-Comté
89295,PERRIGNY,89000,PERRIGNY,,47.8264618403,3.52927414133,295,,Perrigny,Perrigny,89,Yonne,27,Bourgogne-Franche-Comté
89296,PERRIGNY SUR ARMANCON,89390,PERRIGNY SUR ARMANCON,,47.6866686908,4.21866444518,296,,Perrigny-sur-Armançon,Perrigny-sur-Armançon,89,Yonne,27,Bourgogne-Franche-Comté
89297,PIERRE PERTHUIS,89450,PIERRE PERTHUIS,,47.4326784841,3.79104636774,297,,Pierre-Perthuis,Pierre-Perthuis,89,Yonne,27,Bourgogne-Franche-Comté
89298,PIFFONDS,89330,PIFFONDS,,48.0571001875,3.14072115221,298,,Piffonds,Piffonds,89,Yonne,27,Bourgogne-Franche-Comté
89299,PIMELLES,89740,PIMELLES,,47.8394757022,4.17196657767,299,,Pimelles,Pimelles,89,Yonne,27,Bourgogne-Franche-Comté
89300,PISY,89420,PISY,,47.5639786066,4.14212075944,300,,Pisy,Pisy,89,Yonne,27,Bourgogne-Franche-Comté
89302,PLESSIS ST JEAN,89140,PLESSIS ST JEAN,,48.3429566104,3.30281910968,302,,Plessis-Saint-Jean,Plessis-Saint-Jean,89,Yonne,27,Bourgogne-Franche-Comté
89303,POILLY SUR SEREIN,89310,POILLY SUR SEREIN,,47.764269044,3.89097240309,303,,Poilly-sur-Serein,Poilly-sur-Serein,89,Yonne,27,Bourgogne-Franche-Comté
89304,POILLY SUR THOLON,89110,POILLY SUR THOLON,,47.8494315541,3.39947947458,304,,Poilly-sur-Tholon,Poilly-sur-Tholon,89,Yonne,27,Bourgogne-Franche-Comté
89306,PONTAUBERT,89200,PONTAUBERT,,47.4929203838,3.87144768521,306,,Pontaubert,Pontaubert,89,Yonne,27,Bourgogne-Franche-Comté
89307,PONTIGNY,89230,PONTIGNY,,47.9076877953,3.71694380417,307,,Pontigny,Pontigny,89,Yonne,27,Bourgogne-Franche-Comté
89308,PONT SUR VANNE,89190,PONT SUR VANNE,,48.1958502799,3.43713511625,308,,Pont-sur-Vanne,Pont-sur-Vanne,89,Yonne,27,Bourgogne-Franche-Comté
89309,PONT SUR YONNE,89140,PONT SUR YONNE,,48.2787429774,3.1934739546,309,,Pont-sur-Yonne,Pont-sur-Yonne,89,Yonne,27,Bourgogne-Franche-Comté
89310,LA POSTOLLE,89260,LA POSTOLLE,,48.2837034338,3.45398701323,310,La,Postolle,La Postolle,89,Yonne,27,Bourgogne-Franche-Comté
89311,POURRAIN,89240,POURRAIN,,47.7657446745,3.41155845183,311,,Pourrain,Pourrain,89,Yonne,27,Bourgogne-Franche-Comté
89312,PRECY LE SEC,89440,PRECY LE SEC,,47.5932229481,3.82709155548,312,,Précy-le-Sec,Précy-le-Sec,89,Yonne,27,Bourgogne-Franche-Comté
89313,PRECY SUR VRIN,89116,PRECY SUR VRIN,,47.9748965965,3.23292031908,313,,Précy-sur-Vrin,Précy-sur-Vrin,89,Yonne,27,Bourgogne-Franche-Comté
89314,PREGILBERT,89460,PREGILBERT,,47.6364183019,3.68092082062,314,,Prégilbert,Prégilbert,89,Yonne,27,Bourgogne-Franche-Comté
89315,PREHY,89800,PREHY,,47.7526421328,3.78750634442,315,,Préhy,Préhy,89,Yonne,27,Bourgogne-Franche-Comté
89316,PROVENCY,89200,PROVENCY,,47.5412749752,3.95391774558,316,,Provency,Provency,89,Yonne,27,Bourgogne-Franche-Comté
89318,QUARRE LES TOMBES,89630,QUARRE LES TOMBES,,47.3577712365,4.00357546154,318,,Quarré-les-Tombes,Quarré-les-Tombes,89,Yonne,27,Bourgogne-Franche-Comté
89319,QUENNE,89290,QUENNE,,47.779604419,3.65137450935,319,,Quenne,Quenne,89,Yonne,27,Bourgogne-Franche-Comté
89320,QUINCEROT,89740,QUINCEROT,,47.9410004718,4.16662692839,320,,Quincerot,Quincerot,89,Yonne,27,Bourgogne-Franche-Comté
89321,RAVIERES,89390,RAVIERES,,47.743102178,4.25236559824,321,,Ravières,Ravières,89,Yonne,27,Bourgogne-Franche-Comté
89323,ROFFEY,89700,ROFFEY,,47.9122945683,3.91182885101,323,,Roffey,Roffey,89,Yonne,27,Bourgogne-Franche-Comté
89324,ROGNY LES SEPT ECLUSES,89220,ROGNY LES SEPT ECLUSES,,47.7378445116,2.89070027765,324,,Rogny-les-Sept-Écluses,Rogny-les-Sept-Écluses,89,Yonne,27,Bourgogne-Franche-Comté
89325,RONCHERES,89170,RONCHERES,,47.6589905273,3.12087826286,325,,Ronchères,Ronchères,89,Yonne,27,Bourgogne-Franche-Comté
89326,ROSOY,89100,ROSOY,,48.1486871821,3.31220740481,326,,Rosoy,Rosoy,89,Yonne,27,Bourgogne-Franche-Comté
89327,ROUSSON,89500,ROUSSON,,48.0909896672,3.26123308338,327,,Rousson,Rousson,89,Yonne,27,Bourgogne-Franche-Comté
89328,ROUVRAY,89230,ROUVRAY,,47.898249879,3.66932280709,328,,Rouvray,Rouvray,89,Yonne,27,Bourgogne-Franche-Comté
89329,RUGNY,89430,RUGNY,,47.8967756496,4.14997842918,329,,Rugny,Rugny,89,Yonne,27,Bourgogne-Franche-Comté
89331,SAINPUITS,89520,SAINPUITS,,47.5126864446,3.25038757238,331,,Sainpuits,Sainpuits,89,Yonne,27,Bourgogne-Franche-Comté
89332,ST AGNAN,89340,ST AGNAN,,48.3007152806,3.05891454173,332,,Saint-Agnan,Saint-Agnan,89,Yonne,27,Bourgogne-Franche-Comté
89333,ST ANDRE EN TERRE PLAINE,89420,ST ANDRE EN TERRE PLAINE,,47.4822498163,4.05335131054,333,,Saint-André-en-Terre-Plaine,Saint-André-en-Terre-Plaine,89,Yonne,27,Bourgogne-Franche-Comté
89334,LE VAL D OCRE,89110,LE VAL D OCRE,ST AUBIN CHATEAU NEUF,47.8214473263,3.29048335743,334,Le,Val d'Ocre,Le Val d'Ocre,89,Yonne,27,Bourgogne-Franche-Comté
89334,LE VAL D OCRE,89110,LE VAL D OCRE,ST MARTIN SUR OCRE,47.8214473263,3.29048335743,334,Le,Val d'Ocre,Le Val d'Ocre,89,Yonne,27,Bourgogne-Franche-Comté
89335,ST AUBIN SUR YONNE,89300,ST AUBIN SUR YONNE,,47.999800087,3.36292416185,335,,Saint-Aubin-sur-Yonne,Saint-Aubin-sur-Yonne,89,Yonne,27,Bourgogne-Franche-Comté
89336,ST BRANCHER,89630,ST BRANCHER,,47.4294751821,3.99056021345,336,,Saint-Brancher,Saint-Brancher,89,Yonne,27,Bourgogne-Franche-Comté
89337,ST BRIS LE VINEUX,89530,ST BRIS LE VINEUX,,47.7397337759,3.65690105499,337,,Saint-Bris-le-Vineux,Saint-Bris-le-Vineux,89,Yonne,27,Bourgogne-Franche-Comté
89338,ST CLEMENT,89100,ST CLEMENT,,48.2247837392,3.30677970985,338,,Saint-Clément,Saint-Clément,89,Yonne,27,Bourgogne-Franche-Comté
89339,STE COLOMBE,89440,STE COLOMBE,,47.5733326756,3.95597782049,339,,Sainte-Colombe,Sainte-Colombe,89,Yonne,27,Bourgogne-Franche-Comté
89340,STE COLOMBE SUR LOING,89520,STE COLOMBE SUR LOING,,47.578392113,3.23144861008,340,,Sainte-Colombe-sur-Loing,Sainte-Colombe-sur-Loing,89,Yonne,27,Bourgogne-Franche-Comté
89341,ST CYR LES COLONS,89800,ST CYR LES COLONS,,47.7305335651,3.7628533948,341,,Saint-Cyr-les-Colons,Saint-Cyr-les-Colons,89,Yonne,27,Bourgogne-Franche-Comté
89342,ST DENIS LES SENS,89100,ST DENIS LES SENS,,48.2347117121,3.26672512395,342,,Saint-Denis-lès-Sens,Saint-Denis-lès-Sens,89,Yonne,27,Bourgogne-Franche-Comté
89344,ST FARGEAU,89170,ST FARGEAU,,47.6424423236,3.08488500512,344,,Saint-Fargeau,Saint-Fargeau,89,Yonne,27,Bourgogne-Franche-Comté
89344,ST FARGEAU,89170,ST FARGEAU,SEPTFONDS,47.6424423236,3.08488500512,344,,Saint-Fargeau,Saint-Fargeau,89,Yonne,27,Bourgogne-Franche-Comté
89345,ST FLORENTIN,89600,ST FLORENTIN,,47.996991653,3.70472818773,345,,Saint-Florentin,Saint-Florentin,89,Yonne,27,Bourgogne-Franche-Comté
89345,ST FLORENTIN,89600,ST FLORENTIN,AVROLLES,47.996991653,3.70472818773,345,,Saint-Florentin,Saint-Florentin,89,Yonne,27,Bourgogne-Franche-Comté
89346,ST GEORGES SUR BAULCHE,89000,ST GEORGES SUR BAULCHE,,47.8021809257,3.51709241008,346,,Saint-Georges-sur-Baulche,Saint-Georges-sur-Baulche,89,Yonne,27,Bourgogne-Franche-Comté
89347,ST GERMAIN DES CHAMPS,89630,ST GERMAIN DES CHAMPS,,47.4173168736,3.92129827487,347,,Saint-Germain-des-Champs,Saint-Germain-des-Champs,89,Yonne,27,Bourgogne-Franche-Comté
89348,ST JULIEN DU SAULT,89330,ST JULIEN DU SAULT,,48.0341276746,3.27521431804,348,,Saint-Julien-du-Sault,Saint-Julien-du-Sault,89,Yonne,27,Bourgogne-Franche-Comté
89349,ST LEGER VAUBAN,89630,ST LEGER VAUBAN,,47.3729259001,4.06034341517,349,,Saint-Léger-Vauban,Saint-Léger-Vauban,89,Yonne,27,Bourgogne-Franche-Comté
89350,ST LOUP D ORDON,89330,ST LOUP D ORDON,,48.0113645758,3.14208002154,350,,Saint-Loup-d'Ordon,Saint-Loup-d'Ordon,89,Yonne,27,Bourgogne-Franche-Comté
89351,STE MAGNANCE,89420,STE MAGNANCE,,47.4523409147,4.08447153654,351,,Sainte-Magnance,Sainte-Magnance,89,Yonne,27,Bourgogne-Franche-Comté
89352,ST MARTIN DES CHAMPS,89170,ST MARTIN DES CHAMPS,,47.6588823611,3.02834068016,352,,Saint-Martin-des-Champs,Saint-Martin-des-Champs,89,Yonne,27,Bourgogne-Franche-Comté
89353,ST MARTIN D ORDON,89330,ST MARTIN D ORDON,,48.0258467044,3.17245991649,353,,Saint-Martin-d'Ordon,Saint-Martin-d'Ordon,89,Yonne,27,Bourgogne-Franche-Comté
89354,ST MARTIN DU TERTRE,89100,ST MARTIN DU TERTRE,,48.2054577095,3.24082644551,354,,Saint-Martin-du-Tertre,Saint-Martin-du-Tertre,89,Yonne,27,Bourgogne-Franche-Comté
89355,ST MARTIN SUR ARMANCON,89700,ST MARTIN SUR ARMANCON,,47.8773671393,4.06758883362,355,,Saint-Martin-sur-Armançon,Saint-Martin-sur-Armançon,89,Yonne,27,Bourgogne-Franche-Comté
89359,ST MAURICE AUX RICHES HOMMES,89190,ST MAURICE AUX RICHES HOMMES,,48.3293750857,3.49859430256,359,,Saint-Maurice-aux-Riches-Hommes,Saint-Maurice-aux-Riches-Hommes,89,Yonne,27,Bourgogne-Franche-Comté
89360,ST MAURICE LE VIEIL,89110,ST MAURICE LE VIEIL,,47.823467193,3.3678945863,360,,Saint-Maurice-le-Vieil,Saint-Maurice-le-Vieil,89,Yonne,27,Bourgogne-Franche-Comté
89361,ST MAURICE THIZOUAILLE,89110,ST MAURICE THIZOUAILLE,,47.8332646182,3.36323456172,361,,Saint-Maurice-Thizouaille,Saint-Maurice-Thizouaille,89,Yonne,27,Bourgogne-Franche-Comté
89362,ST MORE,89270,ST MORE,,47.5765381901,3.77326623089,362,,Saint-Moré,Saint-Moré,89,Yonne,27,Bourgogne-Franche-Comté
89363,STE PALLAYE,89460,STE PALLAYE,,47.6537787512,3.68064417073,363,,Sainte-Pallaye,Sainte-Pallaye,89,Yonne,27,Bourgogne-Franche-Comté
89364,ST PERE,89450,ST PERE,,47.4609189917,3.77642616957,364,,Saint-Père,Saint-Père,89,Yonne,27,Bourgogne-Franche-Comté
89365,ST PRIVE,89220,ST PRIVE,,47.6895502074,3.0016873765,365,,Saint-Privé,Saint-Privé,89,Yonne,27,Bourgogne-Franche-Comté
89367,SAINTS EN PUISAYE,89520,SAINTS EN PUISAYE,,47.6231345556,3.27095693205,367,,Saints-en-Puisaye,Saints-en-Puisaye,89,Yonne,27,Bourgogne-Franche-Comté
89368,ST SAUVEUR EN PUISAYE,89520,ST SAUVEUR EN PUISAYE,,47.6390792978,3.20437842755,368,,Saint-Sauveur-en-Puisaye,Saint-Sauveur-en-Puisaye,89,Yonne,27,Bourgogne-Franche-Comté
89369,ST SEROTIN,89140,ST SEROTIN,,48.2467879374,3.1604994544,369,,Saint-Sérotin,Saint-Sérotin,89,Yonne,27,Bourgogne-Franche-Comté
89370,ST VALERIEN,89150,ST VALERIEN,,48.1818943604,3.09959245761,370,,Saint-Valérien,Saint-Valérien,89,Yonne,27,Bourgogne-Franche-Comté
89371,STE VERTU,89310,STE VERTU,,47.7398700628,3.91053461734,371,,Sainte-Vertu,Sainte-Vertu,89,Yonne,27,Bourgogne-Franche-Comté
89373,SALIGNY,89100,SALIGNY,,48.2154264712,3.35240862773,373,,Saligny,Saligny,89,Yonne,27,Bourgogne-Franche-Comté
89374,SAMBOURG,89160,SAMBOURG,,47.773945917,4.01023648072,374,,Sambourg,Sambourg,89,Yonne,27,Bourgogne-Franche-Comté
89375,SANTIGNY,89420,SANTIGNY,,47.5763950286,4.12196091631,375,,Santigny,Santigny,89,Yonne,27,Bourgogne-Franche-Comté
89376,SARRY,89310,SARRY,,47.6655179259,4.07573184934,376,,Sarry,Sarry,89,Yonne,27,Bourgogne-Franche-Comté
89377,SAUVIGNY LE BEUREAL,89420,SAUVIGNY LE BEUREAL,,47.4807022931,4.10703113936,377,,Sauvigny-le-Beuréal,Sauvigny-le-Beuréal,89,Yonne,27,Bourgogne-Franche-Comté
89378,SAUVIGNY LE BOIS,89200,SAUVIGNY LE BOIS,,47.5132454769,3.95639274581,378,,Sauvigny-le-Bois,Sauvigny-le-Bois,89,Yonne,27,Bourgogne-Franche-Comté
89379,SAVIGNY EN TERRE PLAINE,89420,SAVIGNY EN TERRE PLAINE,,47.4935874843,4.08358510886,379,,Savigny-en-Terre-Plaine,Savigny-en-Terre-Plaine,89,Yonne,27,Bourgogne-Franche-Comté
89380,SAVIGNY SUR CLAIRIS,89150,SAVIGNY SUR CLAIRIS,,48.0762765897,3.08345438352,380,,Savigny-sur-Clairis,Savigny-sur-Clairis,89,Yonne,27,Bourgogne-Franche-Comté
89381,SCEAUX,89420,SCEAUX,,47.5189022968,4.01877053906,381,,Sceaux,Sceaux,89,Yonne,27,Bourgogne-Franche-Comté
89382,SEIGNELAY,89250,SEIGNELAY,,47.9021528321,3.59422336095,382,,Seignelay,Seignelay,89,Yonne,27,Bourgogne-Franche-Comté
89383,SEMENTRON,89560,SEMENTRON,,47.6312784173,3.36183428197,383,,Sementron,Sementron,89,Yonne,27,Bourgogne-Franche-Comté
89384,SENAN,89710,SENAN,,47.9136523438,3.35802325243,384,,Senan,Senan,89,Yonne,27,Bourgogne-Franche-Comté
89385,SENNEVOY LE BAS,89160,SENNEVOY LE BAS,,47.8008894092,4.30964285017,385,,Sennevoy-le-Bas,Sennevoy-le-Bas,89,Yonne,27,Bourgogne-Franche-Comté
89386,SENNEVOY LE HAUT,89160,SENNEVOY LE HAUT,,47.8116022646,4.26474148707,386,,Sennevoy-le-Haut,Sennevoy-le-Haut,89,Yonne,27,Bourgogne-Franche-Comté
89387,SENS,89100,SENS,,48.1956727015,3.29793332552,387,,Sens,Sens,89,Yonne,27,Bourgogne-Franche-Comté
89388,SEPEAUX ST ROMAIN,89116,SEPEAUX ST ROMAIN,,47.943114412,3.23185765848,388,,Sépeaux-Saint Romain,Sépeaux-Saint Romain,89,Yonne,27,Bourgogne-Franche-Comté
89388,SEPEAUX ST ROMAIN,89116,SEPEAUX ST ROMAIN,ST ROMAIN LE PREUX,47.943114412,3.23185765848,388,,Sépeaux-Saint Romain,Sépeaux-Saint Romain,89,Yonne,27,Bourgogne-Franche-Comté
89390,SERBONNES,89140,SERBONNES,,48.3301048545,3.20867774959,390,,Serbonnes,Serbonnes,89,Yonne,27,Bourgogne-Franche-Comté
89391,SERGINES,89140,SERGINES,,48.3455899887,3.2506084741,391,,Sergines,Sergines,89,Yonne,27,Bourgogne-Franche-Comté
89392,SERMIZELLES,89200,SERMIZELLES,,47.5406742954,3.80001863719,392,,Sermizelles,Sermizelles,89,Yonne,27,Bourgogne-Franche-Comté
89393,SERRIGNY,89700,SERRIGNY,,47.8338277666,3.91219434333,393,,Serrigny,Serrigny,89,Yonne,27,Bourgogne-Franche-Comté
89394,SERY,89270,SERY,,47.6188310437,3.69145226197,394,,Sery,Sery,89,Yonne,27,Bourgogne-Franche-Comté
89395,LES SIEGES,89190,LES SIEGES,,48.1724461499,3.53441319941,395,Les,Sièges,Les Sièges,89,Yonne,27,Bourgogne-Franche-Comté
89397,SOMMECAISE,89110,SOMMECAISE,,47.8381404726,3.22296633404,397,,Sommecaise,Sommecaise,89,Yonne,27,Bourgogne-Franche-Comté
89398,SORMERY,89570,SORMERY,,48.104865517,3.75966718846,398,,Sormery,Sormery,89,Yonne,27,Bourgogne-Franche-Comté
89399,SOUCY,89100,SOUCY,,48.2508138819,3.32683528652,399,,Soucy,Soucy,89,Yonne,27,Bourgogne-Franche-Comté
89400,SOUGERES EN PUISAYE,89520,SOUGERES EN PUISAYE,,47.5644811457,3.34935308016,400,,Sougères-en-Puisaye,Sougères-en-Puisaye,89,Yonne,27,Bourgogne-Franche-Comté
89402,SOUMAINTRAIN,89570,SOUMAINTRAIN,,48.0175798115,3.84030626845,402,,Soumaintrain,Soumaintrain,89,Yonne,27,Bourgogne-Franche-Comté
89403,STIGNY,89160,STIGNY,,47.7795307025,4.2389047068,403,,Stigny,Stigny,89,Yonne,27,Bourgogne-Franche-Comté
89404,SUBLIGNY,89100,SUBLIGNY,,48.1652984137,3.19780626537,404,,Subligny,Subligny,89,Yonne,27,Bourgogne-Franche-Comté
89405,TAINGY,89560,TAINGY,,47.6123914189,3.40803434271,405,Les,Hauts de Forterre,Les Hauts de Forterre,89,Yonne,27,Bourgogne-Franche-Comté
89406,TALCY,89420,TALCY,,47.5761707824,4.07521714273,406,,Talcy,Talcy,89,Yonne,27,Bourgogne-Franche-Comté
89407,TANLAY,89430,TANLAY,,47.844633852,4.08770006589,407,,Tanlay,Tanlay,89,Yonne,27,Bourgogne-Franche-Comté
89407,TANLAY,89430,TANLAY,COMMISSEY,47.844633852,4.08770006589,407,,Tanlay,Tanlay,89,Yonne,27,Bourgogne-Franche-Comté
89407,TANLAY,89430,TANLAY,ST VINNEMER,47.844633852,4.08770006589,407,,Tanlay,Tanlay,89,Yonne,27,Bourgogne-Franche-Comté
89408,TANNERRE EN PUISAYE,89350,TANNERRE EN PUISAYE,,47.7353917535,3.13906959587,408,,Tannerre-en-Puisaye,Tannerre-en-Puisaye,89,Yonne,27,Bourgogne-Franche-Comté
89409,THAROISEAU,89450,THAROISEAU,,47.4635077803,3.8130360009,409,,Tharoiseau,Tharoiseau,89,Yonne,27,Bourgogne-Franche-Comté
89410,THAROT,89200,THAROT,,47.5350072163,3.86316278793,410,,Tharot,Tharot,89,Yonne,27,Bourgogne-Franche-Comté
89411,LES VALLEES DE LA VANNE,89190,LES VALLEES DE LA VANNE,CHIGY,48.163480288,3.43929036088,411,Les,Vallées de la Vanne,Les Vallées de la Vanne,89,Yonne,27,Bourgogne-Franche-Comté
89411,LES VALLEES DE LA VANNE,89320,LES VALLEES DE LA VANNE,THEIL SUR VANNE,48.163480288,3.43929036088,411,Les,Vallées de la Vanne,Les Vallées de la Vanne,89,Yonne,27,Bourgogne-Franche-Comté
89411,LES VALLEES DE LA VANNE,89320,LES VALLEES DE LA VANNE,VAREILLES,48.163480288,3.43929036088,411,Les,Vallées de la Vanne,Les Vallées de la Vanne,89,Yonne,27,Bourgogne-Franche-Comté
89412,THIZY,89420,THIZY,,47.58183768,4.05915393328,412,,Thizy,Thizy,89,Yonne,27,Bourgogne-Franche-Comté
89413,THOREY,89430,THOREY,,47.8996209486,4.11461177122,413,,Thorey,Thorey,89,Yonne,27,Bourgogne-Franche-Comté
89414,THORIGNY SUR OREUSE,89260,THORIGNY SUR OREUSE,,48.2981521482,3.3801934011,414,,Thorigny-sur-Oreuse,Thorigny-sur-Oreuse,89,Yonne,27,Bourgogne-Franche-Comté
89414,THORIGNY SUR OREUSE,89260,THORIGNY SUR OREUSE,FLEURIGNY,48.2981521482,3.3801934011,414,,Thorigny-sur-Oreuse,Thorigny-sur-Oreuse,89,Yonne,27,Bourgogne-Franche-Comté
89414,THORIGNY SUR OREUSE,89260,THORIGNY SUR OREUSE,ST MARTIN SUR OREUSE,48.2981521482,3.3801934011,414,,Thorigny-sur-Oreuse,Thorigny-sur-Oreuse,89,Yonne,27,Bourgogne-Franche-Comté
89415,THORY,89200,THORY,,47.5710240128,3.91643466826,415,,Thory,Thory,89,Yonne,27,Bourgogne-Franche-Comté
89416,THURY,89520,THURY,,47.5889340898,3.30089549955,416,,Thury,Thury,89,Yonne,27,Bourgogne-Franche-Comté
89417,TISSEY,89700,TISSEY,,47.8593082514,3.89981203629,417,,Tissey,Tissey,89,Yonne,27,Bourgogne-Franche-Comté
89418,TONNERRE,89700,TONNERRE,,47.8411044504,3.97744216729,418,,Tonnerre,Tonnerre,89,Yonne,27,Bourgogne-Franche-Comté
89419,TOUCY,89130,TOUCY,,47.7335607719,3.29372849002,419,,Toucy,Toucy,89,Yonne,27,Bourgogne-Franche-Comté
89420,TREIGNY,89520,TREIGNY,,47.5555889247,3.17487086988,420,,Treigny,Treigny,89,Yonne,27,Bourgogne-Franche-Comté
89420,TREIGNY,89520,TREIGNY,PERREUSE,47.5555889247,3.17487086988,420,,Treigny,Treigny,89,Yonne,27,Bourgogne-Franche-Comté
89421,TREVILLY,89420,TREVILLY,,47.5265248869,4.05319063182,421,,Trévilly,Trévilly,89,Yonne,27,Bourgogne-Franche-Comté
89422,TRICHEY,89430,TRICHEY,,47.9247277892,4.14013153912,422,,Trichey,Trichey,89,Yonne,27,Bourgogne-Franche-Comté
89423,TRONCHOY,89700,TRONCHOY,,47.9253601685,3.94507629451,423,,Tronchoy,Tronchoy,89,Yonne,27,Bourgogne-Franche-Comté
89424,TRUCY SUR YONNE,89460,TRUCY SUR YONNE,,47.6277404821,3.64573699559,424,,Trucy-sur-Yonne,Trucy-sur-Yonne,89,Yonne,27,Bourgogne-Franche-Comté
89425,TURNY,89570,TURNY,,48.0654471891,3.73424677868,425,,Turny,Turny,89,Yonne,27,Bourgogne-Franche-Comté
89426,VAL DE MERCY,89580,VAL DE MERCY,,47.6704553293,3.58979488236,426,,Val-de-Mercy,Val-de-Mercy,89,Yonne,27,Bourgogne-Franche-Comté
89427,VALLAN,89580,VALLAN,,47.7452558085,3.54140818593,427,,Vallan,Vallan,89,Yonne,27,Bourgogne-Franche-Comté
89428,VALLERY,89150,VALLERY,,48.2338341776,3.04642439879,428,,Vallery,Vallery,89,Yonne,27,Bourgogne-Franche-Comté
89430,VARENNES,89144,VARENNES,,47.9137837272,3.78515786219,430,,Varennes,Varennes,89,Yonne,27,Bourgogne-Franche-Comté
89431,VASSY SOUS PISY,89420,VASSY SOUS PISY,,47.5727535051,4.16888081291,431,,Vassy-sous-Pisy,Vassy-sous-Pisy,89,Yonne,27,Bourgogne-Franche-Comté
89432,VAUDEURS,89320,VAUDEURS,,48.1307279337,3.55662521835,432,,Vaudeurs,Vaudeurs,89,Yonne,27,Bourgogne-Franche-Comté
89433,VAULT DE LUGNY,89200,VAULT DE LUGNY,,47.5025414674,3.84177784703,433,,Vault-de-Lugny,Vault-de-Lugny,89,Yonne,27,Bourgogne-Franche-Comté
89434,VAUMORT,89320,VAUMORT,,48.1366578358,3.44102523277,434,,Vaumort,Vaumort,89,Yonne,27,Bourgogne-Franche-Comté
89436,VENIZY,89210,VENIZY,,48.0660266782,3.67776590247,436,,Venizy,Venizy,89,Yonne,27,Bourgogne-Franche-Comté
89437,VENOUSE,89230,VENOUSE,,47.8979028984,3.69249058859,437,,Venouse,Venouse,89,Yonne,27,Bourgogne-Franche-Comté
89438,VENOY,89290,VENOY,,47.8055369258,3.65434489608,438,,Venoy,Venoy,89,Yonne,27,Bourgogne-Franche-Comté
89439,VERGIGNY,89600,VERGIGNY,,47.9549518047,3.69703784472,439,,Vergigny,Vergigny,89,Yonne,27,Bourgogne-Franche-Comté
89439,VERGIGNY,89600,VERGIGNY,BOUILLY,47.9549518047,3.69703784472,439,,Vergigny,Vergigny,89,Yonne,27,Bourgogne-Franche-Comté
89439,VERGIGNY,89600,VERGIGNY,REBOURSEAUX,47.9549518047,3.69703784472,439,,Vergigny,Vergigny,89,Yonne,27,Bourgogne-Franche-Comté
89440,VERLIN,89330,VERLIN,,48.0189121069,3.22311343074,440,,Verlin,Verlin,89,Yonne,27,Bourgogne-Franche-Comté
89441,VERMENTON,89270,VERMENTON,,47.6729908911,3.7577164851,441,,Vermenton,Vermenton,89,Yonne,27,Bourgogne-Franche-Comté
89441,VERMENTON,89270,VERMENTON,SACY,47.6729908911,3.7577164851,441,,Vermenton,Vermenton,89,Yonne,27,Bourgogne-Franche-Comté
89442,VERNOY,89150,VERNOY,,48.0940567499,3.12610301658,442,,Vernoy,Vernoy,89,Yonne,27,Bourgogne-Franche-Comté
89443,VERON,89510,VERON,,48.126395695,3.33112142097,443,,Véron,Véron,89,Yonne,27,Bourgogne-Franche-Comté
89445,VEZANNES,89700,VEZANNES,,47.8729874293,3.88180171745,445,,Vézannes,Vézannes,89,Yonne,27,Bourgogne-Franche-Comté
89446,VEZELAY,89450,VEZELAY,,47.4524539513,3.71058845441,446,,Vézelay,Vézelay,89,Yonne,27,Bourgogne-Franche-Comté
89447,VEZINNES,89700,VEZINNES,,47.8922320806,3.93235236779,447,,Vézinnes,Vézinnes,89,Yonne,27,Bourgogne-Franche-Comté
89448,VIGNES,89420,VIGNES,,47.5367256008,4.11835771378,448,,Vignes,Vignes,89,Yonne,27,Bourgogne-Franche-Comté
89449,VILLEBLEVIN,89340,VILLEBLEVIN,,48.3305033124,3.08563926027,449,,Villeblevin,Villeblevin,89,Yonne,27,Bourgogne-Franche-Comté
89450,VILLEBOUGIS,89150,VILLEBOUGIS,,48.2086439691,3.15617870614,450,,Villebougis,Villebougis,89,Yonne,27,Bourgogne-Franche-Comté
89451,VILLECHETIVE,89320,VILLECHETIVE,,48.0957287781,3.53016603926,451,,Villechétive,Villechétive,89,Yonne,27,Bourgogne-Franche-Comté
89452,VILLECIEN,89300,VILLECIEN,,48.0143094754,3.33899968576,452,,Villecien,Villecien,89,Yonne,27,Bourgogne-Franche-Comté
89453,VILLEFARGEAU,89240,VILLEFARGEAU,,47.7882906541,3.48637705982,453,,Villefargeau,Villefargeau,89,Yonne,27,Bourgogne-Franche-Comté
89456,VILLEMANOCHE,89140,VILLEMANOCHE,,48.2957047033,3.16898800512,456,,Villemanoche,Villemanoche,89,Yonne,27,Bourgogne-Franche-Comté
89458,VILLENAVOTTE,89140,VILLENAVOTTE,,48.2436607589,3.23257648668,458,,Villenavotte,Villenavotte,89,Yonne,27,Bourgogne-Franche-Comté
89459,VILLENEUVE LA DONDAGRE,89150,VILLENEUVE LA DONDAGRE,,48.1413913646,3.13840843831,459,,Villeneuve-la-Dondagre,Villeneuve-la-Dondagre,89,Yonne,27,Bourgogne-Franche-Comté
89460,VILLENEUVE LA GUYARD,89340,VILLENEUVE LA GUYARD,,48.3359583803,3.05649125172,460,,Villeneuve-la-Guyard,Villeneuve-la-Guyard,89,Yonne,27,Bourgogne-Franche-Comté
89461,VILLENEUVE L ARCHEVEQUE,89190,VILLENEUVE L ARCHEVEQUE,,48.2355837478,3.56315804862,461,,Villeneuve-l'Archevêque,Villeneuve-l'Archevêque,89,Yonne,27,Bourgogne-Franche-Comté
89462,VILLENEUVE LES GENETS,89350,VILLENEUVE LES GENETS,,47.7375292074,3.07590152841,462,,Villeneuve-les-Genêts,Villeneuve-les-Genêts,89,Yonne,27,Bourgogne-Franche-Comté
89463,VILLENEUVE ST SALVES,89230,VILLENEUVE ST SALVES,,47.8501834559,3.64214223918,463,,Villeneuve-Saint-Salves,Villeneuve-Saint-Salves,89,Yonne,27,Bourgogne-Franche-Comté
89464,VILLENEUVE SUR YONNE,89500,VILLENEUVE SUR YONNE,,48.0817729554,3.3198884982,464,,Villeneuve-sur-Yonne,Villeneuve-sur-Yonne,89,Yonne,27,Bourgogne-Franche-Comté
89465,VILLEPERROT,89140,VILLEPERROT,,48.2579952047,3.21403097378,465,,Villeperrot,Villeperrot,89,Yonne,27,Bourgogne-Franche-Comté
89466,VILLEROY,89100,VILLEROY,,48.1815398633,3.1810299431,466,,Villeroy,Villeroy,89,Yonne,27,Bourgogne-Franche-Comté
89467,VILLETHIERRY,89140,VILLETHIERRY,,48.2677766627,3.07197353642,467,,Villethierry,Villethierry,89,Yonne,27,Bourgogne-Franche-Comté
89468,VILLEVALLIER,89330,VILLEVALLIER,,48.0283864485,3.3421152408,468,,Villevallier,Villevallier,89,Yonne,27,Bourgogne-Franche-Comté
89469,PERCENEIGE,89260,PERCENEIGE,,48.3524100222,3.410424212,469,,Perceneige,Perceneige,89,Yonne,27,Bourgogne-Franche-Comté
89469,PERCENEIGE,89260,PERCENEIGE,COURCEAUX,48.3524100222,3.410424212,469,,Perceneige,Perceneige,89,Yonne,27,Bourgogne-Franche-Comté
89469,PERCENEIGE,89260,PERCENEIGE,GRANGE LE BOCAGE,48.3524100222,3.410424212,469,,Perceneige,Perceneige,89,Yonne,27,Bourgogne-Franche-Comté
89469,PERCENEIGE,89260,PERCENEIGE,PLESSIS DU MEE,48.3524100222,3.410424212,469,,Perceneige,Perceneige,89,Yonne,27,Bourgogne-Franche-Comté
89469,PERCENEIGE,89260,PERCENEIGE,SOGNES,48.3524100222,3.410424212,469,,Perceneige,Perceneige,89,Yonne,27,Bourgogne-Franche-Comté
89469,PERCENEIGE,89260,PERCENEIGE,VERTILLY,48.3524100222,3.410424212,469,,Perceneige,Perceneige,89,Yonne,27,Bourgogne-Franche-Comté
89470,VILLIERS LES HAUTS,89160,VILLIERS LES HAUTS,,47.718668015,4.14977186887,470,,Villiers-les-Hauts,Villiers-les-Hauts,89,Yonne,27,Bourgogne-Franche-Comté
89471,VILLIERS LOUIS,89320,VILLIERS LOUIS,,48.1976433719,3.40283565458,471,,Villiers-Louis,Villiers-Louis,89,Yonne,27,Bourgogne-Franche-Comté
89472,VILLIERS ST BENOIT,89130,VILLIERS ST BENOIT,,47.7820741656,3.22439167522,472,,Villiers-Saint-Benoît,Villiers-Saint-Benoît,89,Yonne,27,Bourgogne-Franche-Comté
89472,VILLIERS ST BENOIT,89130,VILLIERS ST BENOIT,LA VILLOTTE,47.7820741656,3.22439167522,472,,Villiers-Saint-Benoît,Villiers-Saint-Benoît,89,Yonne,27,Bourgogne-Franche-Comté
89473,VILLIERS SUR THOLON,89110,VILLIERS SUR THOLON,,47.892712243,3.32228138316,473,,Villiers-sur-Tholon,Villiers-sur-Tholon,89,Yonne,27,Bourgogne-Franche-Comté
89474,VILLIERS VINEUX,89360,VILLIERS VINEUX,,47.9423289612,3.82464741849,474,,Villiers-Vineux,Villiers-Vineux,89,Yonne,27,Bourgogne-Franche-Comté
89475,VILLON,89740,VILLON,,47.8999233331,4.18645605887,475,,Villon,Villon,89,Yonne,27,Bourgogne-Franche-Comté
89477,VILLY,89800,VILLY,,47.8668272951,3.74734170454,477,,Villy,Villy,89,Yonne,27,Bourgogne-Franche-Comté
89478,VINCELLES,89290,VINCELLES,,47.6886915621,3.63296032864,478,,Vincelles,Vincelles,89,Yonne,27,Bourgogne-Franche-Comté
89479,VINCELOTTES,89290,VINCELOTTES,,47.7096174282,3.64165113268,479,,Vincelottes,Vincelottes,89,Yonne,27,Bourgogne-Franche-Comté
89480,VINNEUF,89140,VINNEUF,,48.3545827578,3.1386704897,480,,Vinneuf,Vinneuf,89,Yonne,27,Bourgogne-Franche-Comté
89481,VIREAUX,89160,VIREAUX,,47.7883991862,4.03759334001,481,,Vireaux,Vireaux,89,Yonne,27,Bourgogne-Franche-Comté
89482,VIVIERS,89700,VIVIERS,,47.8059930935,3.92242744061,482,,Viviers,Viviers,89,Yonne,27,Bourgogne-Franche-Comté
89483,VOISINES,89260,VOISINES,,48.2541143686,3.41070498223,483,,Voisines,Voisines,89,Yonne,27,Bourgogne-Franche-Comté
89484,VOLGRE,89710,VOLGRE,,47.9167423065,3.30997507491,484,,Volgré,Volgré,89,Yonne,27,Bourgogne-Franche-Comté
89485,VOUTENAY SUR CURE,89270,VOUTENAY SUR CURE,,47.5577664247,3.78586900239,485,,Voutenay-sur-Cure,Voutenay-sur-Cure,89,Yonne,27,Bourgogne-Franche-Comté
89486,YROUERRE,89700,YROUERRE,,47.7870283663,3.95763937516,486,,Yrouerre,Yrouerre,89,Yonne,27,Bourgogne-Franche-Comté
90001,ANDELNANS,90400,ANDELNANS,,47.6031655316,6.86569831996,1,,Andelnans,Andelnans,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90001,ANDELNANS,90400,ANDELNANS,FROIDEVAL,47.6031655316,6.86569831996,1,,Andelnans,Andelnans,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90002,ANGEOT,90150,ANGEOT,,47.6955037352,7.00609340402,2,,Angeot,Angeot,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90003,ANJOUTEY,90170,ANJOUTEY,,47.7023396715,6.92749570916,3,,Anjoutey,Anjoutey,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90004,ARGIESANS,90800,ARGIESANS,,47.6049743564,6.82328122613,4,,Argiésans,Argiésans,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90005,AUXELLES BAS,90200,AUXELLES BAS,,47.7286757608,6.78389507054,5,,Auxelles-Bas,Auxelles-Bas,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90006,AUXELLES HAUT,90200,AUXELLES HAUT,,47.7497691632,6.77833985609,6,,Auxelles-Haut,Auxelles-Haut,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90007,BANVILLARS,90800,BANVILLARS,,47.590801025,6.81387082116,7,,Banvillars,Banvillars,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90008,BAVILLIERS,90800,BAVILLIERS,,47.6199695086,6.83065542168,8,,Bavilliers,Bavilliers,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90009,BEAUCOURT,90500,BEAUCOURT,,47.4866458822,6.92526796011,9,,Beaucourt,Beaucourt,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90010,BELFORT,90000,BELFORT,,47.6471539414,6.84541206237,10,,Belfort,Belfort,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90011,BERMONT,90400,BERMONT,,47.5754640823,6.84557401926,11,,Bermont,Bermont,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90012,BESSONCOURT,90160,BESSONCOURT,,47.6448119375,6.9429284708,12,,Bessoncourt,Bessoncourt,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90013,BETHONVILLIERS,90150,BETHONVILLIERS,,47.6808468613,6.96678328233,13,,Bethonvilliers,Bethonvilliers,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90014,BORON,90100,BORON,,47.5539430181,7.01511285165,14,,Boron,Boron,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90015,BOTANS,90400,BOTANS,,47.5956035781,6.84875928018,15,,Botans,Botans,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90016,BOURG SOUS CHATELET,90110,BOURG SOUS CHATELET,,47.7051448214,6.94870528663,16,,Bourg-sous-Châtelet,Bourg-sous-Châtelet,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90017,BOUROGNE,90140,BOUROGNE,,47.5644650637,6.90576475767,17,,Bourogne,Bourogne,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90018,BREBOTTE,90140,BREBOTTE,,47.5785857282,6.97299379772,18,,Brebotte,Brebotte,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90019,BRETAGNE,90130,BRETAGNE,,47.5906442362,7.00045774264,19,,Bretagne,Bretagne,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90020,BUC,90800,BUC,,47.6177478789,6.78886377781,20,,Buc,Buc,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90021,CHARMOIS,90140,CHARMOIS,,47.5792626446,6.94147757264,21,,Charmois,Charmois,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90022,CHATENOIS LES FORGES,90700,CHATENOIS LES FORGES,,47.5590968943,6.83373185003,22,,Châtenois-les-Forges,Châtenois-les-Forges,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90023,CHAUX,90330,CHAUX,,47.7125374753,6.83833170347,23,,Chaux,Chaux,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90024,CHAVANATTE,90100,CHAVANATTE,,47.5826458846,7.07154687722,24,,Chavanatte,Chavanatte,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90025,CHAVANNES LES GRANDS,90100,CHAVANNES LES GRANDS,,47.5898992645,7.04916944322,25,,Chavannes-les-Grands,Chavannes-les-Grands,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90026,CHEVREMONT,90340,CHEVREMONT,,47.6278946489,6.92965534221,26,,Chèvremont,Chèvremont,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90027,COURCELLES,90100,COURCELLES,,47.4989296387,7.07296297288,27,,Courcelles,Courcelles,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90028,COURTELEVANT,90100,COURTELEVANT,,47.5252208202,7.08505338248,28,,Courtelevant,Courtelevant,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90029,CRAVANCHE,90300,CRAVANCHE,,47.6518069558,6.82383810256,29,,Cravanche,Cravanche,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90030,CROIX,90100,CROIX,,47.4447830481,6.95044642125,30,,Croix,Croix,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90031,CUNELIERES,90150,CUNELIERES,,47.6258233078,6.99387177143,31,,Cunelières,Cunelières,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90032,DANJOUTIN,90400,DANJOUTIN,,47.6179263286,6.86632266419,32,,Danjoutin,Danjoutin,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90033,DELLE,90100,DELLE,,47.5085668053,6.99653850498,33,,Delle,Delle,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90034,DENNEY,90160,DENNEY,,47.6552442246,6.90807737911,34,,Denney,Denney,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90035,DORANS,90400,DORANS,,47.584744627,6.83724758711,35,,Dorans,Dorans,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90036,EGUENIGUE,90150,EGUENIGUE,,47.6793258182,6.9331272882,36,,Eguenigue,Eguenigue,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90037,ELOIE,90300,ELOIE,,47.6875264608,6.87288702572,37,,Éloie,Éloie,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90039,ESSERT,90850,ESSERT,,47.6367014962,6.81198236249,39,,Essert,Essert,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90041,ETUEFFONT,90170,ETUEFFONT,,47.7254670433,6.91517824444,41,,Étueffont,Étueffont,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90042,EVETTE SALBERT,90350,EVETTE SALBERT,,47.6726681014,6.79638657468,42,,Évette-Salbert,Évette-Salbert,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90043,FAVEROIS,90100,FAVEROIS,,47.5283156243,7.03649746611,43,,Faverois,Faverois,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90044,FELON,90110,FELON,,47.7062610634,6.98051528131,44,,Felon,Felon,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90045,FECHE L EGLISE,90100,FECHE L EGLISE,,47.5021767416,6.95588031238,45,,Fêche-l'Église,Fêche-l'Église,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90046,FLORIMONT,90100,FLORIMONT,,47.5370834795,7.05075565634,46,,Florimont,Florimont,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90047,FONTAINE,90150,FONTAINE,,47.6588814395,6.99769315688,47,,Fontaine,Fontaine,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90048,FONTENELLE,90340,FONTENELLE,,47.6208281317,6.9563673377,48,,Fontenelle,Fontenelle,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90049,FOUSSEMAGNE,90150,FOUSSEMAGNE,,47.6377553885,6.99718798904,49,,Foussemagne,Foussemagne,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90050,FRAIS,90150,FRAIS,,47.6463987999,6.9814493981,50,,Frais,Frais,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90051,FROIDEFONTAINE,90140,FROIDEFONTAINE,,47.5634149229,6.95605250107,51,,Froidefontaine,Froidefontaine,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90052,GIROMAGNY,90200,GIROMAGNY,,47.741048439,6.82264329531,52,,Giromagny,Giromagny,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90053,GRANDVILLARS,90600,GRANDVILLARS,,47.5377831374,6.97050338546,53,,Grandvillars,Grandvillars,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90054,GROSMAGNY,90200,GROSMAGNY,,47.7184568018,6.88090479563,54,,Grosmagny,Grosmagny,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90055,GROSNE,90100,GROSNE,,47.571197367,6.99460817647,55,,Grosne,Grosne,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90056,JONCHEREY,90100,JONCHEREY,,47.5329509014,7.01087011883,56,,Joncherey,Joncherey,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90057,LACHAPELLE SOUS CHAUX,90300,LACHAPELLE SOUS CHAUX,,47.7049696546,6.79856033468,57,,Lachapelle-sous-Chaux,Lachapelle-sous-Chaux,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90058,LACHAPELLE SOUS ROUGEMONT,90360,LACHAPELLE SOUS ROUGEMONT,,47.7137681909,7.01591974575,58,,Lachapelle-sous-Rougemont,Lachapelle-sous-Rougemont,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90059,LACOLLONGE,90150,LACOLLONGE,,47.6639071503,6.96032555343,59,,Lacollonge,Lacollonge,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90060,LAGRANGE,90150,LAGRANGE,,47.6840587684,6.97706962401,60,,Lagrange,Lagrange,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90061,LAMADELEINE VAL DES ANGES,90170,LAMADELEINE VAL DES ANGES,,47.7601153142,6.90910336251,61,,Lamadeleine-Val-des-Anges,Lamadeleine-Val-des-Anges,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90062,LARIVIERE,90150,LARIVIERE,,47.6769376385,6.99381327055,62,,Larivière,Larivière,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90063,LEBETAIN,90100,LEBETAIN,,47.4864077201,6.97353458294,63,,Lebetain,Lebetain,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90064,LEPUIX NEUF,90100,LEPUIX NEUF,,47.5415228689,7.1047242748,64,,Lepuix-Neuf,Lepuix-Neuf,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90065,LEPUIX,90200,LEPUIX,,47.7806013604,6.82197185625,65,,Lepuix,Lepuix,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90066,LEVAL,90110,LEVAL,,47.7325561254,6.99485433225,66,,Leval,Leval,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90067,MENONCOURT,90150,MENONCOURT,,47.6788573234,6.95039773409,67,,Menoncourt,Menoncourt,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90068,MEROUX,90400,MEROUX,,47.5925461032,6.90957261498,68,,Meroux,Meroux,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90069,MEZIRE,90120,MEZIRE,,47.5314114127,6.92258227383,69,,Méziré,Méziré,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90070,MONTBOUTON,90500,MONTBOUTON,,47.4687777002,6.92638107495,70,,Montbouton,Montbouton,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90071,MONTREUX CHATEAU,90130,MONTREUX CHATEAU,,47.6079194544,6.99446090768,71,,Montreux-Château,Montreux-Château,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90072,MORVILLARS,90120,MORVILLARS,,47.5429192415,6.93785518461,72,,Morvillars,Morvillars,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90073,MOVAL,90400,MOVAL,,47.5856192963,6.88545785765,73,,Moval,Moval,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90074,NOVILLARD,90340,NOVILLARD,,47.6050025795,6.96134299185,74,,Novillard,Novillard,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90075,OFFEMONT,90300,OFFEMONT,,47.6629165729,6.87500834426,75,,Offemont,Offemont,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90076,PEROUSE,90160,PEROUSE,,47.6363380208,6.89414319751,76,,Pérouse,Pérouse,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90077,PETIT CROIX,90130,PETIT CROIX,,47.6212088279,6.97354282285,77,,Petit-Croix,Petit-Croix,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90078,PETITEFONTAINE,90360,PETITEFONTAINE,,47.7237763721,7.00757336099,78,,Petitefontaine,Petitefontaine,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90079,PETITMAGNY,90170,PETITMAGNY,,47.7236606703,6.89890832072,79,,Petitmagny,Petitmagny,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90080,PHAFFANS,90150,PHAFFANS,,47.6585204866,6.94418106146,80,,Phaffans,Phaffans,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90081,RECHESY,90370,RECHESY,,47.5158861847,7.1166640348,81,,Réchésy,Réchésy,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90082,AUTRECHENE,90140,AUTRECHENE,,47.5919647033,6.96347182318,82,,Autrechêne,Autrechêne,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90083,RECOUVRANCE,90140,RECOUVRANCE,,47.5703574644,6.97895072336,83,,Recouvrance,Recouvrance,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90084,REPPE,90150,REPPE,,47.6629663825,7.02937822362,84,,Reppe,Reppe,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90085,RIERVESCEMONT,90200,RIERVESCEMONT,,47.7686202907,6.87873144599,85,,Riervescemont,Riervescemont,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90086,ROMAGNY SOUS ROUGEMONT,90110,ROMAGNY SOUS ROUGEMONT,,47.7186718403,6.96636692216,86,,Romagny-sous-Rougemont,Romagny-sous-Rougemont,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90087,ROPPE,90380,ROPPE,,47.6791103202,6.91008918124,87,,Roppe,Roppe,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90088,ROUGEGOUTTE,90200,ROUGEGOUTTE,,47.7339603674,6.86245086381,88,,Rougegoutte,Rougegoutte,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90089,ROUGEMONT LE CHATEAU,90110,ROUGEMONT LE CHATEAU,,47.7460113941,6.95212889734,89,,Rougemont-le-Château,Rougemont-le-Château,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90090,ST DIZIER L EVEQUE,90100,ST DIZIER L EVEQUE,,47.4674300962,6.96107878975,90,,Saint-Dizier-l'Évêque,Saint-Dizier-l'Évêque,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90091,ST GERMAIN LE CHATELET,90110,ST GERMAIN LE CHATELET,,47.7002701756,6.96114583666,91,,Saint-Germain-le-Châtelet,Saint-Germain-le-Châtelet,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90093,SERMAMAGNY,90300,SERMAMAGNY,,47.687801557,6.8309146345,93,,Sermamagny,Sermamagny,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90094,SEVENANS,90400,SEVENANS,,47.5887550061,6.87009154705,94,,Sevenans,Sevenans,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90095,SUARCE,90100,SUARCE,,47.5612956517,7.08005568664,95,,Suarce,Suarce,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90096,THIANCOURT,90100,THIANCOURT,,47.5200971035,6.98091530541,96,,Thiancourt,Thiancourt,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90097,TREVENANS,90400,TREVENANS,,47.5667104538,6.86855180089,97,,Trévenans,Trévenans,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90098,URCEREY,90800,URCEREY,,47.6107025364,6.80334349857,98,,Urcerey,Urcerey,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90099,VALDOIE,90300,VALDOIE,,47.6712389192,6.84860821584,99,,Valdoie,Valdoie,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90100,VAUTHIERMONT,90150,VAUTHIERMONT,,47.6811834758,7.0246637056,100,,Vauthiermont,Vauthiermont,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90101,VELLESCOT,90100,VELLESCOT,,47.5752045188,7.01784519688,101,,Vellescot,Vellescot,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90102,VESCEMONT,90200,VESCEMONT,,47.7533939016,6.85248119382,102,,Vescemont,Vescemont,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90103,VETRIGNE,90300,VETRIGNE,,47.6690986224,6.89453808576,103,,Vétrigne,Vétrigne,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90104,VEZELOIS,90400,VEZELOIS,,47.6094018086,6.91908506936,104,,Vézelois,Vézelois,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
90105,VILLARS LE SEC,90100,VILLARS LE SEC,,47.4554088507,6.98803547043,105,,Villars-le-Sec,Villars-le-Sec,90,Territoire de Belfort,27,Bourgogne-Franche-Comté
91001,ABBEVILLE LA RIVIERE,91150,ABBEVILLE LA RIVIERE,,48.3437461384,2.19127679346,1,,Abbéville-la-Rivière,Abbéville-la-Rivière,91,Essonne,11,Île-de-France
91016,ANGERVILLE,91670,ANGERVILLE,,48.3095627706,2.00619112268,16,,Angerville,Angerville,91,Essonne,11,Île-de-France
91016,ANGERVILLE,91670,ANGERVILLE,DOMMERVILLE,48.3095627706,2.00619112268,16,,Angerville,Angerville,91,Essonne,11,Île-de-France
91017,ANGERVILLIERS,91470,ANGERVILLIERS,,48.5911202876,2.05864904136,17,,Angervilliers,Angervilliers,91,Essonne,11,Île-de-France
91021,ARPAJON,91290,ARPAJON,,48.5908921647,2.24349117607,21,,Arpajon,Arpajon,91,Essonne,11,Île-de-France
91022,ARRANCOURT,91690,ARRANCOURT,,48.3267041104,2.15155534916,22,,Arrancourt,Arrancourt,91,Essonne,11,Île-de-France
91027,ATHIS MONS,91200,ATHIS MONS,,48.7092445775,2.38660492325,27,,Athis-Mons,Athis-Mons,91,Essonne,11,Île-de-France
91035,AUTHON LA PLAINE,91410,AUTHON LA PLAINE,,48.4534137641,1.95677183334,35,,Authon-la-Plaine,Authon-la-Plaine,91,Essonne,11,Île-de-France
91037,AUVERNAUX,91830,AUVERNAUX,,48.5291181496,2.4879155112,37,,Auvernaux,Auvernaux,91,Essonne,11,Île-de-France
91038,AUVERS ST GEORGES,91580,AUVERS ST GEORGES,,48.4773721699,2.22600147466,38,,Auvers-Saint-Georges,Auvers-Saint-Georges,91,Essonne,11,Île-de-France
91041,AVRAINVILLE,91630,AVRAINVILLE,,48.5576520151,2.24428910412,41,,Avrainville,Avrainville,91,Essonne,11,Île-de-France
91044,BALLAINVILLIERS,91160,BALLAINVILLIERS,,48.6713240802,2.28913874502,44,,Ballainvilliers,Ballainvilliers,91,Essonne,11,Île-de-France
91045,BALLANCOURT SUR ESSONNE,91610,BALLANCOURT SUR ESSONNE,,48.521899752,2.38788025142,45,,Ballancourt-sur-Essonne,Ballancourt-sur-Essonne,91,Essonne,11,Île-de-France
91047,BAULNE,91590,BAULNE,,48.4949687726,2.38140884118,47,,Baulne,Baulne,91,Essonne,11,Île-de-France
91064,BIEVRES,91570,BIEVRES,,48.7567351109,2.20665207098,64,,Bièvres,Bièvres,91,Essonne,11,Île-de-France
91067,BLANDY,91150,BLANDY,,48.3237539135,2.25785911069,67,,Blandy,Blandy,91,Essonne,11,Île-de-France
91069,BOIGNEVILLE,91720,BOIGNEVILLE,,48.3281689535,2.36609167603,69,,Boigneville,Boigneville,91,Essonne,11,Île-de-France
91075,BOIS HERPIN,91150,BOIS HERPIN,,48.3717921411,2.24398863454,75,,Bois-Herpin,Bois-Herpin,91,Essonne,11,Île-de-France
91079,BOISSY LA RIVIERE,91690,BOISSY LA RIVIERE,,48.3824146625,2.17005086721,79,,Boissy-la-Rivière,Boissy-la-Rivière,91,Essonne,11,Île-de-France
91080,BOISSY LE CUTTE,91590,BOISSY LE CUTTE,,48.4702885961,2.28518160664,80,,Boissy-le-Cutté,Boissy-le-Cutté,91,Essonne,11,Île-de-France
91081,BOISSY LE SEC,91870,BOISSY LE SEC,,48.4846711917,2.09143210906,81,,Boissy-le-Sec,Boissy-le-Sec,91,Essonne,11,Île-de-France
91085,BOISSY SOUS ST YON,91790,BOISSY SOUS ST YON,,48.5500395894,2.21286982392,85,,Boissy-sous-Saint-Yon,Boissy-sous-Saint-Yon,91,Essonne,11,Île-de-France
91086,BONDOUFLE,91070,BONDOUFLE,,48.6140476567,2.38002541357,86,,Bondoufle,Bondoufle,91,Essonne,11,Île-de-France
91093,BOULLAY LES TROUX,91470,BOULLAY LES TROUX,,48.6753515056,2.04828313772,93,,Boullay-les-Troux,Boullay-les-Troux,91,Essonne,11,Île-de-France
91095,BOURAY SUR JUINE,91850,BOURAY SUR JUINE,,48.5123020136,2.29610342228,95,,Bouray-sur-Juine,Bouray-sur-Juine,91,Essonne,11,Île-de-France
91097,BOUSSY ST ANTOINE,91800,BOUSSY ST ANTOINE,,48.6895309069,2.53381915314,97,,Boussy-Saint-Antoine,Boussy-Saint-Antoine,91,Essonne,11,Île-de-France
91098,BOUTERVILLIERS,91150,BOUTERVILLIERS,,48.4555830213,2.05114682408,98,,Boutervilliers,Boutervilliers,91,Essonne,11,Île-de-France
91099,BOUTIGNY SUR ESSONNE,91820,BOUTIGNY SUR ESSONNE,,48.4361818403,2.3954223757,99,,Boutigny-sur-Essonne,Boutigny-sur-Essonne,91,Essonne,11,Île-de-France
91100,BOUVILLE,91880,BOUVILLE,,48.4326483441,2.2783856422,100,,Bouville,Bouville,91,Essonne,11,Île-de-France
91103,BRETIGNY SUR ORGE,91220,BRETIGNY SUR ORGE,,48.6025113399,2.3021623232,103,,Brétigny-sur-Orge,Brétigny-sur-Orge,91,Essonne,11,Île-de-France
91105,BREUILLET,91650,BREUILLET,,48.5718215115,2.16598389799,105,,Breuillet,Breuillet,91,Essonne,11,Île-de-France
91106,BREUX JOUY,91650,BREUX JOUY,,48.5549501512,2.16049670709,106,,Breux-Jouy,Breux-Jouy,91,Essonne,11,Île-de-France
91109,BRIERES LES SCELLES,91150,BRIERES LES SCELLES,,48.4636769085,2.14290097216,109,,Brières-les-Scellés,Brières-les-Scellés,91,Essonne,11,Île-de-France
91111,BRIIS SOUS FORGES,91640,BRIIS SOUS FORGES,,48.6271447741,2.1309080467,111,,Briis-sous-Forges,Briis-sous-Forges,91,Essonne,11,Île-de-France
91112,BROUY,91150,BROUY,,48.3264481207,2.28927890389,112,,Brouy,Brouy,91,Essonne,11,Île-de-France
91114,BRUNOY,91800,BRUNOY,,48.6964396294,2.50394753271,114,,Brunoy,Brunoy,91,Essonne,11,Île-de-France
91115,BRUYERES LE CHATEL,91680,BRUYERES LE CHATEL,,48.600315457,2.18687041469,115,,Bruyères-le-Châtel,Bruyères-le-Châtel,91,Essonne,11,Île-de-France
91121,BUNO BONNEVAUX,91720,BUNO BONNEVAUX,,48.3569089853,2.40696147299,121,,Buno-Bonnevaux,Buno-Bonnevaux,91,Essonne,11,Île-de-France
91122,BURES SUR YVETTE,91440,BURES SUR YVETTE,,48.6929979919,2.15838686116,122,,Bures-sur-Yvette,Bures-sur-Yvette,91,Essonne,11,Île-de-France
91122,BURES SUR YVETTE,91440,BURES SUR YVETTE,MONTJAY,48.6929979919,2.15838686116,122,,Bures-sur-Yvette,Bures-sur-Yvette,91,Essonne,11,Île-de-France
91129,CERNY,91590,CERNY,,48.4859798517,2.31068283872,129,,Cerny,Cerny,91,Essonne,11,Île-de-France
91130,CHALO ST MARS,91780,CHALO ST MARS,,48.4167324924,2.05158821673,130,,Chalo-Saint-Mars,Chalo-Saint-Mars,91,Essonne,11,Île-de-France
91131,CHALOU MOULINEUX,91740,CHALOU MOULINEUX,,48.3803154982,2.02616711579,131,,Chalou-Moulineux,Chalou-Moulineux,91,Essonne,11,Île-de-France
91132,CHAMARANDE,91730,CHAMARANDE,,48.5167739657,2.21800082924,132,,Chamarande,Chamarande,91,Essonne,11,Île-de-France
91135,CHAMPCUEIL,91750,CHAMPCUEIL,,48.5067693664,2.44815529587,135,,Champcueil,Champcueil,91,Essonne,11,Île-de-France
91136,CHAMPLAN,91160,CHAMPLAN,,48.7083599746,2.27815398132,136,,Champlan,Champlan,91,Essonne,11,Île-de-France
91137,CHAMPMOTTEUX,91150,CHAMPMOTTEUX,,48.3439397579,2.31485115366,137,,Champmotteux,Champmotteux,91,Essonne,11,Île-de-France
91145,CHATIGNONVILLE,91410,CHATIGNONVILLE,,48.4687599817,1.93252874595,145,,Chatignonville,Chatignonville,91,Essonne,11,Île-de-France
91148,CHAUFFOUR LES ETRECHY,91580,CHAUFFOUR LES ETRECHY,,48.5078619555,2.16843822281,148,,Chauffour-lès-Étréchy,Chauffour-lès-Étréchy,91,Essonne,11,Île-de-France
91156,CHEPTAINVILLE,91630,CHEPTAINVILLE,,48.5465841683,2.27416218366,156,,Cheptainville,Cheptainville,91,Essonne,11,Île-de-France
91159,CHEVANNES,91750,CHEVANNES,,48.5335355797,2.44062628529,159,,Chevannes,Chevannes,91,Essonne,11,Île-de-France
91161,CHILLY MAZARIN,91380,CHILLY MAZARIN,,48.7077663127,2.3111122334,161,,Chilly-Mazarin,Chilly-Mazarin,91,Essonne,11,Île-de-France
91174,CORBEIL ESSONNES,91100,CORBEIL ESSONNES,,48.6034809251,2.46934079002,174,,Corbeil-Essonnes,Corbeil-Essonnes,91,Essonne,11,Île-de-France
91175,CORBREUSE,91410,CORBREUSE,,48.494765705,1.96031071197,175,,Corbreuse,Corbreuse,91,Essonne,11,Île-de-France
91179,LE COUDRAY MONTCEAUX,91830,LE COUDRAY MONTCEAUX,,48.5587791447,2.48742424481,179,Le,Coudray-Montceaux,Le Coudray-Montceaux,91,Essonne,11,Île-de-France
91179,LE COUDRAY MONTCEAUX,91830,LE COUDRAY MONTCEAUX,LE PLESSIS CHENET,48.5587791447,2.48742424481,179,Le,Coudray-Montceaux,Le Coudray-Montceaux,91,Essonne,11,Île-de-France
91180,COURANCES,91490,COURANCES,,48.4385478349,2.48480049204,180,,Courances,Courances,91,Essonne,11,Île-de-France
91182,COURCOURONNES,91080,COURCOURONNES,,48.6216349774,2.41288009058,182,,Courcouronnes,Courcouronnes,91,Essonne,11,Île-de-France
91184,COURDIMANCHE SUR ESSONNE,91720,COURDIMANCHE SUR ESSONNE,,48.418031424,2.36344067611,184,,Courdimanche-sur-Essonne,Courdimanche-sur-Essonne,91,Essonne,11,Île-de-France
91186,COURSON MONTELOUP,91680,COURSON MONTELOUP,,48.5982232022,2.14130817077,186,,Courson-Monteloup,Courson-Monteloup,91,Essonne,11,Île-de-France
91191,CROSNE,91560,CROSNE,,48.7200869901,2.46331727859,191,,Crosne,Crosne,91,Essonne,11,Île-de-France
91195,DANNEMOIS,91490,DANNEMOIS,,48.4597288126,2.46450828384,195,,Dannemois,Dannemois,91,Essonne,11,Île-de-France
91198,D HUISON LONGUEVILLE,91590,D HUISON LONGUEVILLE,,48.4557191202,2.32754055181,198,,D'Huison-Longueville,D'Huison-Longueville,91,Essonne,11,Île-de-France
91200,DOURDAN,91410,DOURDAN,,48.5359351532,1.99430156785,200,,Dourdan,Dourdan,91,Essonne,11,Île-de-France
91201,DRAVEIL,91210,DRAVEIL,,48.6778413772,2.42197801862,201,,Draveil,Draveil,91,Essonne,11,Île-de-France
91201,DRAVEIL,91210,DRAVEIL,MAINVILLE,48.6778413772,2.42197801862,201,,Draveil,Draveil,91,Essonne,11,Île-de-France
91204,ECHARCON,91540,ECHARCON,,48.5750589359,2.40080185445,204,,Écharcon,Écharcon,91,Essonne,11,Île-de-France
91207,EGLY,91520,EGLY,,48.5778313904,2.22141176606,207,,Égly,Égly,91,Essonne,11,Île-de-France
91215,EPINAY SOUS SENART,91860,EPINAY SOUS SENART,,48.6871527113,2.51577225487,215,,Épinay-sous-Sénart,Épinay-sous-Sénart,91,Essonne,11,Île-de-France
91216,EPINAY SUR ORGE,91360,EPINAY SUR ORGE,,48.672618112,2.31834586071,216,,Épinay-sur-Orge,Épinay-sur-Orge,91,Essonne,11,Île-de-France
91222,ESTOUCHES,91660,ESTOUCHES,,48.3069780775,2.13261118075,222,,Estouches,Estouches,91,Essonne,11,Île-de-France
91223,ETAMPES,91150,ETAMPES,,48.4211282308,2.13838881712,223,,Étampes,Étampes,91,Essonne,11,Île-de-France
91225,ETIOLLES,91450,ETIOLLES,,48.6501283496,2.48331098589,225,,Étiolles,Étiolles,91,Essonne,11,Île-de-France
91226,ETRECHY,91580,ETRECHY,,48.4924832389,2.17928848122,226,,Étréchy,Étréchy,91,Essonne,11,Île-de-France
91228,EVRY,91000,EVRY,,48.6294831659,2.44008244492,228,,Évry,Évry,91,Essonne,11,Île-de-France
91232,LA FERTE ALAIS,91590,LA FERTE ALAIS,,48.481221954,2.36734978463,232,La,Ferté-Alais,La Ferté-Alais,91,Essonne,11,Île-de-France
91235,FLEURY MEROGIS,91700,FLEURY MEROGIS,,48.6333048682,2.36825308788,235,,Fleury-Mérogis,Fleury-Mérogis,91,Essonne,11,Île-de-France
91240,FONTAINE LA RIVIERE,91690,FONTAINE LA RIVIERE,,48.3602367763,2.16482780211,240,,Fontaine-la-Rivière,Fontaine-la-Rivière,91,Essonne,11,Île-de-France
91243,FONTENAY LES BRIIS,91640,FONTENAY LES BRIIS,,48.6107631779,2.16460955805,243,,Fontenay-lès-Briis,Fontenay-lès-Briis,91,Essonne,11,Île-de-France
91244,FONTENAY LE VICOMTE,91540,FONTENAY LE VICOMTE,,48.5468386132,2.40068041051,244,,Fontenay-le-Vicomte,Fontenay-le-Vicomte,91,Essonne,11,Île-de-France
91247,LA FORET LE ROI,91410,LA FORET LE ROI,,48.4755183328,2.0468515284,247,La,Forêt-le-Roi,La Forêt-le-Roi,91,Essonne,11,Île-de-France
91248,LA FORET STE CROIX,91150,LA FORET STE CROIX,,48.3840802182,2.23053467052,248,La,Forêt-Sainte-Croix,La Forêt-Sainte-Croix,91,Essonne,11,Île-de-France
91249,FORGES LES BAINS,91470,FORGES LES BAINS,,48.619866924,2.08295423107,249,,Forges-les-Bains,Forges-les-Bains,91,Essonne,11,Île-de-France
91272,GIF SUR YVETTE,91190,GIF SUR YVETTE,,48.6988273634,2.12788365005,272,,Gif-sur-Yvette,Gif-sur-Yvette,91,Essonne,11,Île-de-France
91273,GIRONVILLE SUR ESSONNE,91720,GIRONVILLE SUR ESSONNE,,48.3663326191,2.34574347653,273,,Gironville-sur-Essonne,Gironville-sur-Essonne,91,Essonne,11,Île-de-France
91274,GOMETZ LA VILLE,91400,GOMETZ LA VILLE,,48.672349947,2.10961593577,274,,Gometz-la-Ville,Gometz-la-Ville,91,Essonne,11,Île-de-France
91275,GOMETZ LE CHATEL,91940,GOMETZ LE CHATEL,,48.6745834658,2.142891374,275,,Gometz-le-Châtel,Gometz-le-Châtel,91,Essonne,11,Île-de-France
91284,LES GRANGES LE ROI,91410,LES GRANGES LE ROI,,48.4981066436,2.01059778399,284,Les,Granges-le-Roi,Les Granges-le-Roi,91,Essonne,11,Île-de-France
91286,GRIGNY,91350,GRIGNY,,48.6568896116,2.38789647858,286,,Grigny,Grigny,91,Essonne,11,Île-de-France
91292,GUIBEVILLE,91630,GUIBEVILLE,,48.5671878264,2.27170202676,292,,Guibeville,Guibeville,91,Essonne,11,Île-de-France
91293,GUIGNEVILLE SUR ESSONNE,91590,GUIGNEVILLE SUR ESSONNE,,48.4638589713,2.37103830296,293,,Guigneville-sur-Essonne,Guigneville-sur-Essonne,91,Essonne,11,Île-de-France
91294,GUILLERVAL,91690,GUILLERVAL,,48.368305642,2.07601234971,294,,Guillerval,Guillerval,91,Essonne,11,Île-de-France
91312,IGNY,91430,IGNY,,48.7362250613,2.22442021749,312,,Igny,Igny,91,Essonne,11,Île-de-France
91312,IGNY,91430,IGNY,GOMMONVILLIERS,48.7362250613,2.22442021749,312,,Igny,Igny,91,Essonne,11,Île-de-France
91315,ITTEVILLE,91760,ITTEVILLE,,48.5145241389,2.34431579636,315,,Itteville,Itteville,91,Essonne,11,Île-de-France
91318,JANVILLE SUR JUINE,91510,JANVILLE SUR JUINE,,48.5011134841,2.25385756053,318,,Janville-sur-Juine,Janville-sur-Juine,91,Essonne,11,Île-de-France
91319,JANVRY,91640,JANVRY,,48.6461448761,2.15536092787,319,,Janvry,Janvry,91,Essonne,11,Île-de-France
91326,JUVISY SUR ORGE,91260,JUVISY SUR ORGE,,48.6918789321,2.37393682594,326,,Juvisy-sur-Orge,Juvisy-sur-Orge,91,Essonne,11,Île-de-France
91330,LARDY,91510,LARDY,,48.5270354108,2.27017712838,330,,Lardy,Lardy,91,Essonne,11,Île-de-France
91332,LEUDEVILLE,91630,LEUDEVILLE,,48.5707254105,2.32774257442,332,,Leudeville,Leudeville,91,Essonne,11,Île-de-France
91333,LEUVILLE SUR ORGE,91310,LEUVILLE SUR ORGE,,48.614191732,2.26786916606,333,,Leuville-sur-Orge,Leuville-sur-Orge,91,Essonne,11,Île-de-France
91338,LIMOURS,91470,LIMOURS,,48.6465432324,2.08125663662,338,,Limours,Limours,91,Essonne,11,Île-de-France
91339,LINAS,91310,LINAS,,48.6255061941,2.25135348658,339,,Linas,Linas,91,Essonne,11,Île-de-France
91340,LISSES,91090,LISSES,,48.5966688856,2.42469094606,340,,Lisses,Lisses,91,Essonne,11,Île-de-France
91345,LONGJUMEAU,91160,LONGJUMEAU,,48.6900488045,2.30247056719,345,,Longjumeau,Longjumeau,91,Essonne,11,Île-de-France
91345,LONGJUMEAU,91160,LONGJUMEAU,BALIZY,48.6900488045,2.30247056719,345,,Longjumeau,Longjumeau,91,Essonne,11,Île-de-France
91347,LONGPONT SUR ORGE,91310,LONGPONT SUR ORGE,,48.6404556934,2.28647061043,347,,Longpont-sur-Orge,Longpont-sur-Orge,91,Essonne,11,Île-de-France
91359,MAISSE,91720,MAISSE,,48.3958941333,2.37009405843,359,,Maisse,Maisse,91,Essonne,11,Île-de-France
91363,MARCOUSSIS,91460,MARCOUSSIS,,48.6463321888,2.20621393661,363,,Marcoussis,Marcoussis,91,Essonne,11,Île-de-France
91374,MAROLLES EN BEAUCE,91150,MAROLLES EN BEAUCE,,48.3692255214,2.20423612616,374,,Marolles-en-Beauce,Marolles-en-Beauce,91,Essonne,11,Île-de-France
91376,MAROLLES EN HUREPOIX,91630,MAROLLES EN HUREPOIX,,48.5649798618,2.29920657171,376,,Marolles-en-Hurepoix,Marolles-en-Hurepoix,91,Essonne,11,Île-de-France
91377,MASSY,91300,MASSY,,48.7277896426,2.27513131903,377,,Massy,Massy,91,Essonne,11,Île-de-France
91378,MAUCHAMPS,91730,MAUCHAMPS,,48.5272680908,2.19718165044,378,,Mauchamps,Mauchamps,91,Essonne,11,Île-de-France
91386,MENNECY,91540,MENNECY,,48.5586242184,2.43753236038,386,,Mennecy,Mennecy,91,Essonne,11,Île-de-France
91390,MEREVILLE,91660,MEREVILLE,,48.3183780776,2.07369929257,390,,Méréville,Méréville,91,Essonne,11,Île-de-France
91393,MEROBERT,91780,MEROBERT,,48.4157801741,1.99629785985,393,,Mérobert,Mérobert,91,Essonne,11,Île-de-France
91399,MESPUITS,91150,MESPUITS,,48.3583012409,2.27660044339,399,,Mespuits,Mespuits,91,Essonne,11,Île-de-France
91405,MILLY LA FORET,91490,MILLY LA FORET,,48.4032948231,2.46495403521,405,,Milly-la-Forêt,Milly-la-Forêt,91,Essonne,11,Île-de-France
91408,MOIGNY SUR ECOLE,91490,MOIGNY SUR ECOLE,,48.4371290941,2.43964124963,408,,Moigny-sur-École,Moigny-sur-École,91,Essonne,11,Île-de-France
91411,LES MOLIERES,91470,LES MOLIERES,,48.671525,2.07284691795,411,Les,Molières,Les Molières,91,Essonne,11,Île-de-France
91412,MONDEVILLE,91590,MONDEVILLE,,48.4912035622,2.4204540658,412,,Mondeville,Mondeville,91,Essonne,11,Île-de-France
91414,MONNERVILLE,91930,MONNERVILLE,,48.3475778316,2.03756140392,414,,Monnerville,Monnerville,91,Essonne,11,Île-de-France
91421,MONTGERON,91230,MONTGERON,,48.6951531801,2.46249881778,421,,Montgeron,Montgeron,91,Essonne,11,Île-de-France
91425,MONTLHERY,91310,MONTLHERY,,48.6422562554,2.26812085232,425,,Montlhéry,Montlhéry,91,Essonne,11,Île-de-France
91432,MORANGIS,91420,MORANGIS,,48.702703885,2.33614296581,432,,Morangis,Morangis,91,Essonne,11,Île-de-France
91433,MORIGNY CHAMPIGNY,91150,MORIGNY CHAMPIGNY,,48.4409475427,2.20748797746,433,,Morigny-Champigny,Morigny-Champigny,91,Essonne,11,Île-de-France
91434,MORSANG SUR ORGE,91390,MORSANG SUR ORGE,,48.6562855194,2.35088739655,434,,Morsang-sur-Orge,Morsang-sur-Orge,91,Essonne,11,Île-de-France
91435,MORSANG SUR SEINE,91250,MORSANG SUR SEINE,,48.5768153629,2.50087368249,435,,Morsang-sur-Seine,Morsang-sur-Seine,91,Essonne,11,Île-de-France
91441,NAINVILLE LES ROCHES,91750,NAINVILLE LES ROCHES,,48.5047296879,2.49555231597,441,,Nainville-les-Roches,Nainville-les-Roches,91,Essonne,11,Île-de-France
91457,LA NORVILLE,91290,LA NORVILLE,,48.5814225294,2.26569127873,457,La,Norville,La Norville,91,Essonne,11,Île-de-France
91458,NOZAY,91620,NOZAY,,48.6606560132,2.23814387316,458,,Nozay,Nozay,91,Essonne,11,Île-de-France
91461,OLLAINVILLE,91340,OLLAINVILLE,,48.6063091787,2.21888103138,461,,Ollainville,Ollainville,91,Essonne,11,Île-de-France
91463,ONCY SUR ECOLE,91490,ONCY SUR ECOLE,,48.3819349973,2.45686876187,463,,Oncy-sur-École,Oncy-sur-École,91,Essonne,11,Île-de-France
91468,ORMOY,91540,ORMOY,,48.5732357153,2.45777798707,468,,Ormoy,Ormoy,91,Essonne,11,Île-de-France
91469,ORMOY LA RIVIERE,91150,ORMOY LA RIVIERE,,48.4004789028,2.16430044754,469,,Ormoy-la-Rivière,Ormoy-la-Rivière,91,Essonne,11,Île-de-France
91471,ORSAY,91400,ORSAY,,48.7004093953,2.18737051177,471,,Orsay,Orsay,91,Essonne,11,Île-de-France
91471,ORSAY,91400,ORSAY,ORSIGNY,48.7004093953,2.18737051177,471,,Orsay,Orsay,91,Essonne,11,Île-de-France
91473,ORVEAU,91590,ORVEAU,,48.4491758886,2.29666744574,473,,Orveau,Orveau,91,Essonne,11,Île-de-France
91477,PALAISEAU,91120,PALAISEAU,,48.7146765876,2.22881488083,477,,Palaiseau,Palaiseau,91,Essonne,11,Île-de-France
91479,PARAY VIEILLE POSTE,91550,PARAY VIEILLE POSTE,,48.7241848219,2.3598349385,479,,Paray-Vieille-Poste,Paray-Vieille-Poste,91,Essonne,11,Île-de-France
91479,PARAY VIEILLE POSTE,94390,PARAY VIEILLE POSTE,ORLY AEROGARE,48.7241848219,2.3598349385,479,,Paray-Vieille-Poste,Paray-Vieille-Poste,91,Essonne,11,Île-de-France
91482,PECQUEUSE,91470,PECQUEUSE,,48.6482785839,2.03658537249,482,,Pecqueuse,Pecqueuse,91,Essonne,11,Île-de-France
91494,LE PLESSIS PATE,91220,LE PLESSIS PATE,,48.6071065715,2.3415354355,494,Le,Plessis-Pâté,Le Plessis-Pâté,91,Essonne,11,Île-de-France
91495,PLESSIS ST BENOIST,91410,PLESSIS ST BENOIST,,48.4451994125,2.0030499524,495,,Plessis-Saint-Benoist,Plessis-Saint-Benoist,91,Essonne,11,Île-de-France
91507,PRUNAY SUR ESSONNE,91720,PRUNAY SUR ESSONNE,,48.3520662513,2.36130827572,507,,Prunay-sur-Essonne,Prunay-sur-Essonne,91,Essonne,11,Île-de-France
91508,PUISELET LE MARAIS,91150,PUISELET LE MARAIS,,48.3993237404,2.26344473532,508,,Puiselet-le-Marais,Puiselet-le-Marais,91,Essonne,11,Île-de-France
91511,PUSSAY,91740,PUSSAY,,48.3501390368,1.99758393379,511,,Pussay,Pussay,91,Essonne,11,Île-de-France
91514,QUINCY SOUS SENART,91480,QUINCY SOUS SENART,,48.6703327314,2.52837404941,514,,Quincy-sous-Sénart,Quincy-sous-Sénart,91,Essonne,11,Île-de-France
91519,RICHARVILLE,91410,RICHARVILLE,,48.4685502881,1.99842610136,519,,Richarville,Richarville,91,Essonne,11,Île-de-France
91521,RIS ORANGIS,91130,RIS ORANGIS,,48.6451939168,2.40793631766,521,,Ris-Orangis,Ris-Orangis,91,Essonne,11,Île-de-France
91525,ROINVILLE,91410,ROINVILLE,,48.5170698274,2.05333144451,525,,Roinville,Roinville,91,Essonne,11,Île-de-France
91526,ROINVILLIERS,91150,ROINVILLIERS,,48.3446966636,2.23697563097,526,,Roinvilliers,Roinvilliers,91,Essonne,11,Île-de-France
91533,SACLAS,91690,SACLAS,,48.3583856207,2.11333214124,533,,Saclas,Saclas,91,Essonne,11,Île-de-France
91534,SACLAY,91400,SACLAY,,48.7354339366,2.1663021309,534,,Saclay,Saclay,91,Essonne,11,Île-de-France
91534,SACLAY,91400,SACLAY,LE VAL D ALBIAN,48.7354339366,2.1663021309,534,,Saclay,Saclay,91,Essonne,11,Île-de-France
91538,ST AUBIN,91190,ST AUBIN,,48.7149626666,2.1395315453,538,,Saint-Aubin,Saint-Aubin,91,Essonne,11,Île-de-France
91538,ST AUBIN,91190,ST AUBIN,VILLERAS,48.7149626666,2.1395315453,538,,Saint-Aubin,Saint-Aubin,91,Essonne,11,Île-de-France
91540,ST CHERON,91530,ST CHERON,,48.5491975829,2.12662820406,540,,Saint-Chéron,Saint-Chéron,91,Essonne,11,Île-de-France
91544,ST CYR LA RIVIERE,91690,ST CYR LA RIVIERE,,48.3501033292,2.13743376012,544,,Saint-Cyr-la-Rivière,Saint-Cyr-la-Rivière,91,Essonne,11,Île-de-France
91546,ST CYR SOUS DOURDAN,91410,ST CYR SOUS DOURDAN,,48.5655061412,2.03782109729,546,,Saint-Cyr-sous-Dourdan,Saint-Cyr-sous-Dourdan,91,Essonne,11,Île-de-France
91547,ST ESCOBILLE,91410,ST ESCOBILLE,,48.4224153678,1.95796421561,547,,Saint-Escobille,Saint-Escobille,91,Essonne,11,Île-de-France
91549,STE GENEVIEVE DES BOIS,91700,STE GENEVIEVE DES BOIS,,48.6412523804,2.33019417891,549,,Sainte-Geneviève-des-Bois,Sainte-Geneviève-des-Bois,91,Essonne,11,Île-de-France
91552,ST GERMAIN LES ARPAJON,91180,ST GERMAIN LES ARPAJON,,48.5982444605,2.2646136849,552,,Saint-Germain-lès-Arpajon,Saint-Germain-lès-Arpajon,91,Essonne,11,Île-de-France
91553,ST GERMAIN LES CORBEIL,91250,ST GERMAIN LES CORBEIL,,48.6257066899,2.49140468072,553,,Saint-Germain-lès-Corbeil,Saint-Germain-lès-Corbeil,91,Essonne,11,Île-de-France
91556,ST HILAIRE,91780,ST HILAIRE,,48.4416195593,2.08707092552,556,,Saint-Hilaire,Saint-Hilaire,91,Essonne,11,Île-de-France
91560,ST JEAN DE BEAUREGARD,91940,ST JEAN DE BEAUREGARD,,48.6657065613,2.17124577371,560,,Saint-Jean-de-Beauregard,Saint-Jean-de-Beauregard,91,Essonne,11,Île-de-France
91560,ST JEAN DE BEAUREGARD,91940,ST JEAN DE BEAUREGARD,VILLEZIERS,48.6657065613,2.17124577371,560,,Saint-Jean-de-Beauregard,Saint-Jean-de-Beauregard,91,Essonne,11,Île-de-France
91568,ST MAURICE MONTCOURONNE,91530,ST MAURICE MONTCOURONNE,,48.5789512305,2.12082584197,568,,Saint-Maurice-Montcouronne,Saint-Maurice-Montcouronne,91,Essonne,11,Île-de-France
91570,ST MICHEL SUR ORGE,91240,ST MICHEL SUR ORGE,,48.6320496004,2.31170277845,570,,Saint-Michel-sur-Orge,Saint-Michel-sur-Orge,91,Essonne,11,Île-de-France
91573,ST PIERRE DU PERRAY,91280,ST PIERRE DU PERRAY,,48.6058282604,2.51614926696,573,,Saint-Pierre-du-Perray,Saint-Pierre-du-Perray,91,Essonne,11,Île-de-France
91577,SAINTRY SUR SEINE,91250,SAINTRY SUR SEINE,,48.5930107359,2.4941919266,577,,Saintry-sur-Seine,Saintry-sur-Seine,91,Essonne,11,Île-de-France
91578,ST SULPICE DE FAVIERES,91910,ST SULPICE DE FAVIERES,,48.5363608889,2.17969276336,578,,Saint-Sulpice-de-Favières,Saint-Sulpice-de-Favières,91,Essonne,11,Île-de-France
91579,ST VRAIN,91770,ST VRAIN,,48.5373210479,2.32289078443,579,,Saint-Vrain,Saint-Vrain,91,Essonne,11,Île-de-France
91581,ST YON,91650,ST YON,,48.5610752043,2.19243577473,581,,Saint-Yon,Saint-Yon,91,Essonne,11,Île-de-France
91587,SAULX LES CHARTREUX,91160,SAULX LES CHARTREUX,,48.6850264714,2.26634749221,587,,Saulx-les-Chartreux,Saulx-les-Chartreux,91,Essonne,11,Île-de-France
91589,SAVIGNY SUR ORGE,91600,SAVIGNY SUR ORGE,,48.684228392,2.34873862215,589,,Savigny-sur-Orge,Savigny-sur-Orge,91,Essonne,11,Île-de-France
91593,SERMAISE,91530,SERMAISE,,48.5310930707,2.08893971049,593,,Sermaise,Sermaise,91,Essonne,11,Île-de-France
91599,SOISY SUR ECOLE,91840,SOISY SUR ECOLE,,48.4786667096,2.48222153936,599,,Soisy-sur-École,Soisy-sur-École,91,Essonne,11,Île-de-France
91600,SOISY SUR SEINE,91450,SOISY SUR SEINE,,48.6601163856,2.46296011606,600,,Soisy-sur-Seine,Soisy-sur-Seine,91,Essonne,11,Île-de-France
91602,SOUZY LA BRICHE,91580,SOUZY LA BRICHE,,48.5269161626,2.15432082253,602,,Souzy-la-Briche,Souzy-la-Briche,91,Essonne,11,Île-de-France
91613,CONGERVILLE THIONVILLE,91740,CONGERVILLE THIONVILLE,,48.3827643404,1.99087904506,613,,Congerville-Thionville,Congerville-Thionville,91,Essonne,11,Île-de-France
91613,CONGERVILLE THIONVILLE,91740,CONGERVILLE THIONVILLE,CONGERVILLE,48.3827643404,1.99087904506,613,,Congerville-Thionville,Congerville-Thionville,91,Essonne,11,Île-de-France
91617,TIGERY,91250,TIGERY,,48.6444988439,2.51906038006,617,,Tigery,Tigery,91,Essonne,11,Île-de-France
91619,TORFOU,91730,TORFOU,,48.5320494268,2.23447204587,619,,Torfou,Torfou,91,Essonne,11,Île-de-France
91629,VALPUISEAUX,91720,VALPUISEAUX,,48.3922279636,2.30328757315,629,,Valpuiseaux,Valpuiseaux,91,Essonne,11,Île-de-France
91630,LE VAL ST GERMAIN,91530,LE VAL ST GERMAIN,,48.5678789376,2.08128870384,630,Le,Val-Saint-Germain,Le Val-Saint-Germain,91,Essonne,11,Île-de-France
91631,VARENNES JARCY,91480,VARENNES JARCY,,48.6815226941,2.56238031249,631,,Varennes-Jarcy,Varennes-Jarcy,91,Essonne,11,Île-de-France
91634,VAUGRIGNEUSE,91640,VAUGRIGNEUSE,,48.6013342807,2.10541488357,634,,Vaugrigneuse,Vaugrigneuse,91,Essonne,11,Île-de-France
91635,VAUHALLAN,91430,VAUHALLAN,,48.7307999031,2.19905265979,635,,Vauhallan,Vauhallan,91,Essonne,11,Île-de-France
91639,VAYRES SUR ESSONNE,91820,VAYRES SUR ESSONNE,,48.431521942,2.34569131487,639,,Vayres-sur-Essonne,Vayres-sur-Essonne,91,Essonne,11,Île-de-France
91645,VERRIERES LE BUISSON,91370,VERRIERES LE BUISSON,,48.75044312,2.25171297214,645,,Verrières-le-Buisson,Verrières-le-Buisson,91,Essonne,11,Île-de-France
91648,VERT LE GRAND,91810,VERT LE GRAND,,48.583559319,2.36719516135,648,,Vert-le-Grand,Vert-le-Grand,91,Essonne,11,Île-de-France
91649,VERT LE PETIT,91710,VERT LE PETIT,,48.552025472,2.36598547883,649,,Vert-le-Petit,Vert-le-Petit,91,Essonne,11,Île-de-France
91654,VIDELLES,91890,VIDELLES,,48.4683664138,2.42068572026,654,,Videlles,Videlles,91,Essonne,11,Île-de-France
91657,VIGNEUX SUR SEINE,91270,VIGNEUX SUR SEINE,,48.7037445355,2.42793203784,657,,Vigneux-sur-Seine,Vigneux-sur-Seine,91,Essonne,11,Île-de-France
91659,VILLABE,91100,VILLABE,,48.5857767602,2.45200288236,659,,Villabé,Villabé,91,Essonne,11,Île-de-France
91661,VILLEBON SUR YVETTE,91140,VILLEBON SUR YVETTE,,48.6956774396,2.23505155118,661,,Villebon-sur-Yvette,Villebon-sur-Yvette,91,Essonne,11,Île-de-France
91662,VILLECONIN,91580,VILLECONIN,,48.5019182614,2.12813043077,662,,Villeconin,Villeconin,91,Essonne,11,Île-de-France
91665,LA VILLE DU BOIS,91620,LA VILLE DU BOIS,,48.6610194975,2.26503430984,665,La,Ville-du-Bois,La Ville-du-Bois,91,Essonne,11,Île-de-France
91666,VILLEJUST,91140,VILLEJUST,,48.6777382383,2.22294283132,666,,Villejust,Villejust,91,Essonne,11,Île-de-France
91667,VILLEMOISSON SUR ORGE,91360,VILLEMOISSON SUR ORGE,,48.6613107676,2.33108879096,667,,Villemoisson-sur-Orge,Villemoisson-sur-Orge,91,Essonne,11,Île-de-France
91671,VILLENEUVE SUR AUVERS,91580,VILLENEUVE SUR AUVERS,,48.4671191613,2.25415010197,671,,Villeneuve-sur-Auvers,Villeneuve-sur-Auvers,91,Essonne,11,Île-de-France
91679,VILLIERS LE BACLE,91190,VILLIERS LE BACLE,,48.7288080861,2.1221470247,679,,Villiers-le-Bâcle,Villiers-le-Bâcle,91,Essonne,11,Île-de-France
91685,VILLIERS SUR ORGE,91700,VILLIERS SUR ORGE,,48.6584199819,2.29950120792,685,,Villiers-sur-Orge,Villiers-sur-Orge,91,Essonne,11,Île-de-France
91687,VIRY CHATILLON,91170,VIRY CHATILLON,,48.6696168935,2.37374357641,687,,Viry-Châtillon,Viry-Châtillon,91,Essonne,11,Île-de-France
91689,WISSOUS,91320,WISSOUS,,48.7294509959,2.32903292215,689,,Wissous,Wissous,91,Essonne,11,Île-de-France
91691,YERRES,91330,YERRES,,48.7157210712,2.49273020271,691,,Yerres,Yerres,91,Essonne,11,Île-de-France
91692,LES ULIS,91940,LES ULIS,,48.680321482,2.18518955,692,Les,Ulis,Les Ulis,91,Essonne,11,Île-de-France
92002,ANTONY,92160,ANTONY,,48.7503412602,2.2993268102,2,,Antony,Antony,92,Hauts-de-Seine,11,Île-de-France
92004,ASNIERES SUR SEINE,92600,ASNIERES SUR SEINE,,48.9153530123,2.2880384663,4,,Asnières-sur-Seine,Asnières-sur-Seine,92,Hauts-de-Seine,11,Île-de-France
92007,BAGNEUX,92220,BAGNEUX,,48.7983229866,2.30989995212,7,,Bagneux,Bagneux,92,Hauts-de-Seine,11,Île-de-France
92009,BOIS COLOMBES,92270,BOIS COLOMBES,,48.9153368426,2.26738552597,9,,Bois-Colombes,Bois-Colombes,92,Hauts-de-Seine,11,Île-de-France
92012,BOULOGNE BILLANCOURT,92100,BOULOGNE BILLANCOURT,,48.8365843138,2.23913599058,12,,Boulogne-Billancourt,Boulogne-Billancourt,92,Hauts-de-Seine,11,Île-de-France
92014,BOURG LA REINE,92340,BOURG LA REINE,,48.7799073627,2.31643010763,14,,Bourg-la-Reine,Bourg-la-Reine,92,Hauts-de-Seine,11,Île-de-France
92019,CHATENAY MALABRY,92290,CHATENAY MALABRY,,48.7681690197,2.26282598525,19,,Châtenay-Malabry,Châtenay-Malabry,92,Hauts-de-Seine,11,Île-de-France
92019,CHATENAY MALABRY,92290,CHATENAY MALABRY,LA BUTTE ROUGE,48.7681690197,2.26282598525,19,,Châtenay-Malabry,Châtenay-Malabry,92,Hauts-de-Seine,11,Île-de-France
92020,CHATILLON,92320,CHATILLON,,48.8034091756,2.28799131883,20,,Châtillon,Châtillon,92,Hauts-de-Seine,11,Île-de-France
92022,CHAVILLE,92370,CHAVILLE,,48.8076083562,2.19234061062,22,,Chaville,Chaville,92,Hauts-de-Seine,11,Île-de-France
92023,CLAMART,92140,CLAMART,,48.7964306792,2.25474652922,23,,Clamart,Clamart,92,Hauts-de-Seine,11,Île-de-France
92023,CLAMART,92140,CLAMART,LE PETIT CLAMART,48.7964306792,2.25474652922,23,,Clamart,Clamart,92,Hauts-de-Seine,11,Île-de-France
92024,CLICHY,92110,CLICHY,,48.9035359139,2.30575230452,24,,Clichy,Clichy,92,Hauts-de-Seine,11,Île-de-France
92025,COLOMBES,92700,COLOMBES,,48.9225179147,2.24675160629,25,,Colombes,Colombes,92,Hauts-de-Seine,11,Île-de-France
92026,COURBEVOIE,92400,COURBEVOIE,,48.8984504771,2.25570587289,26,,Courbevoie,Courbevoie,92,Hauts-de-Seine,11,Île-de-France
92032,FONTENAY AUX ROSES,92260,FONTENAY AUX ROSES,,48.7897301312,2.28723493557,32,,Fontenay-aux-Roses,Fontenay-aux-Roses,92,Hauts-de-Seine,11,Île-de-France
92033,GARCHES,92380,GARCHES,,48.8450113548,2.18665007789,33,,Garches,Garches,92,Hauts-de-Seine,11,Île-de-France
92035,LA GARENNE COLOMBES,92250,LA GARENNE COLOMBES,,48.9067577111,2.24464604084,35,La,Garenne-Colombes,La Garenne-Colombes,92,Hauts-de-Seine,11,Île-de-France
92036,GENNEVILLIERS,92230,GENNEVILLIERS,,48.9345190548,2.29384987973,36,,Gennevilliers,Gennevilliers,92,Hauts-de-Seine,11,Île-de-France
92040,ISSY LES MOULINEAUX,92130,ISSY LES MOULINEAUX,,48.82347434,2.26449823277,40,,Issy-les-Moulineaux,Issy-les-Moulineaux,92,Hauts-de-Seine,11,Île-de-France
92044,LEVALLOIS PERRET,92300,LEVALLOIS PERRET,,48.895071672,2.28688268127,44,,Levallois-Perret,Levallois-Perret,92,Hauts-de-Seine,11,Île-de-France
92046,MALAKOFF,92240,MALAKOFF,,48.8169695977,2.29693599517,46,,Malakoff,Malakoff,92,Hauts-de-Seine,11,Île-de-France
92047,MARNES LA COQUETTE,92430,MARNES LA COQUETTE,,48.830271264,2.16647261735,47,,Marnes-la-Coquette,Marnes-la-Coquette,92,Hauts-de-Seine,11,Île-de-France
92048,MEUDON,92190,MEUDON,,48.8037275796,2.22696287829,48,,Meudon,Meudon,92,Hauts-de-Seine,11,Île-de-France
92048,MEUDON,92360,MEUDON,MEUDON LA FORET,48.8037275796,2.22696287829,48,,Meudon,Meudon,92,Hauts-de-Seine,11,Île-de-France
92049,MONTROUGE,92120,MONTROUGE,,48.8153032159,2.31648921432,49,,Montrouge,Montrouge,92,Hauts-de-Seine,11,Île-de-France
92050,NANTERRE,92000,NANTERRE,,48.8960701282,2.20671346353,50,,Nanterre,Nanterre,92,Hauts-de-Seine,11,Île-de-France
92051,NEUILLY SUR SEINE,92200,NEUILLY SUR SEINE,,48.8853719688,2.26642584746,51,,Neuilly-sur-Seine,Neuilly-sur-Seine,92,Hauts-de-Seine,11,Île-de-France
92060,LE PLESSIS ROBINSON,92350,LE PLESSIS ROBINSON,,48.7804383642,2.26064286011,60,Le,Plessis-Robinson,Le Plessis-Robinson,92,Hauts-de-Seine,11,Île-de-France
92060,LE PLESSIS ROBINSON,92350,LE PLESSIS ROBINSON,ROBINSON,48.7804383642,2.26064286011,60,Le,Plessis-Robinson,Le Plessis-Robinson,92,Hauts-de-Seine,11,Île-de-France
92062,PUTEAUX,92800,PUTEAUX,,48.8837094969,2.23834178314,62,,Puteaux,Puteaux,92,Hauts-de-Seine,11,Île-de-France
92063,RUEIL MALMAISON,92500,RUEIL MALMAISON,,48.8691897371,2.1773406315,63,,Rueil-Malmaison,Rueil-Malmaison,92,Hauts-de-Seine,11,Île-de-France
92063,RUEIL MALMAISON,92500,RUEIL MALMAISON,BUZENVAL,48.8691897371,2.1773406315,63,,Rueil-Malmaison,Rueil-Malmaison,92,Hauts-de-Seine,11,Île-de-France
92064,ST CLOUD,92210,ST CLOUD,,48.8428741034,2.20864570316,64,,Saint-Cloud,Saint-Cloud,92,Hauts-de-Seine,11,Île-de-France
92071,SCEAUX,92330,SCEAUX,,48.776816369,2.29529414514,71,,Sceaux,Sceaux,92,Hauts-de-Seine,11,Île-de-France
92072,SEVRES,92310,SEVRES,,48.821476509,2.21052297438,72,,Sèvres,Sèvres,92,Hauts-de-Seine,11,Île-de-France
92073,SURESNES,92150,SURESNES,,48.8698479132,2.21965517625,73,,Suresnes,Suresnes,92,Hauts-de-Seine,11,Île-de-France
92075,VANVES,92170,VANVES,,48.8215227089,2.28739490768,75,,Vanves,Vanves,92,Hauts-de-Seine,11,Île-de-France
92076,VAUCRESSON,92420,VAUCRESSON,,48.8410515899,2.16026455161,76,,Vaucresson,Vaucresson,92,Hauts-de-Seine,11,Île-de-France
92077,VILLE D AVRAY,92410,VILLE D AVRAY,,48.8215895592,2.17761311514,77,,Ville-d'Avray,Ville-d'Avray,92,Hauts-de-Seine,11,Île-de-France
92078,VILLENEUVE LA GARENNE,92390,VILLENEUVE LA GARENNE,,48.9364061917,2.32448995943,78,,Villeneuve-la-Garenne,Villeneuve-la-Garenne,92,Hauts-de-Seine,11,Île-de-France
93001,AUBERVILLIERS,93300,AUBERVILLIERS,,48.9121722626,2.38445513768,1,,Aubervilliers,Aubervilliers,93,Seine-Saint-Denis,11,Île-de-France
93005,AULNAY SOUS BOIS,93600,AULNAY SOUS BOIS,,48.9458384367,2.49329041687,5,,Aulnay-sous-Bois,Aulnay-sous-Bois,93,Seine-Saint-Denis,11,Île-de-France
93006,BAGNOLET,93170,BAGNOLET,,48.8690836308,2.42274096688,6,,Bagnolet,Bagnolet,93,Seine-Saint-Denis,11,Île-de-France
93007,LE BLANC MESNIL,93150,LE BLANC MESNIL,,48.9395475536,2.46112148528,7,Le,Blanc-Mesnil,Le Blanc-Mesnil,93,Seine-Saint-Denis,11,Île-de-France
93008,BOBIGNY,93000,BOBIGNY,,48.907688244,2.43863982727,8,,Bobigny,Bobigny,93,Seine-Saint-Denis,11,Île-de-France
93010,BONDY,93140,BONDY,,48.9023234526,2.4837276939,10,,Bondy,Bondy,93,Seine-Saint-Denis,11,Île-de-France
93013,LE BOURGET,93350,LE BOURGET,,48.9361847458,2.42827855593,13,Le,Bourget,Le Bourget,93,Seine-Saint-Denis,11,Île-de-France
93014,CLICHY SOUS BOIS,93390,CLICHY SOUS BOIS,,48.9076285106,2.5462022266,14,,Clichy-sous-Bois,Clichy-sous-Bois,93,Seine-Saint-Denis,11,Île-de-France
93015,COUBRON,93470,COUBRON,,48.9176519555,2.57631231652,15,,Coubron,Coubron,93,Seine-Saint-Denis,11,Île-de-France
93027,LA COURNEUVE,93120,LA COURNEUVE,,48.9322569546,2.39978064801,27,La,Courneuve,La Courneuve,93,Seine-Saint-Denis,11,Île-de-France
93029,DRANCY,93700,DRANCY,,48.9234246259,2.44492688692,29,,Drancy,Drancy,93,Seine-Saint-Denis,11,Île-de-France
93030,DUGNY,93440,DUGNY,,48.9503408732,2.4238637503,30,,Dugny,Dugny,93,Seine-Saint-Denis,11,Île-de-France
93031,EPINAY SUR SEINE,93800,EPINAY SUR SEINE,,48.9550132062,2.31453043231,31,,Épinay-sur-Seine,Épinay-sur-Seine,93,Seine-Saint-Denis,11,Île-de-France
93032,GAGNY,93220,GAGNY,,48.8820763119,2.5449484757,32,,Gagny,Gagny,93,Seine-Saint-Denis,11,Île-de-France
93033,GOURNAY SUR MARNE,93460,GOURNAY SUR MARNE,,48.8605817902,2.57543313796,33,,Gournay-sur-Marne,Gournay-sur-Marne,93,Seine-Saint-Denis,11,Île-de-France
93039,L ILE ST DENIS,93450,L ILE ST DENIS,,48.9395693769,2.32545252764,39,L',Île-Saint-Denis,L'Île-Saint-Denis,93,Seine-Saint-Denis,11,Île-de-France
93045,LES LILAS,93260,LES LILAS,,48.8819208939,2.419667821,45,Les,Lilas,Les Lilas,93,Seine-Saint-Denis,11,Île-de-France
93046,LIVRY GARGAN,93190,LIVRY GARGAN,,48.9197633213,2.53486592332,46,,Livry-Gargan,Livry-Gargan,93,Seine-Saint-Denis,11,Île-de-France
93047,MONTFERMEIL,93370,MONTFERMEIL,,48.8982616679,2.56714354796,47,,Montfermeil,Montfermeil,93,Seine-Saint-Denis,11,Île-de-France
93048,MONTREUIL,93100,MONTREUIL,,48.8633175054,2.44816211857,48,,Montreuil,Montreuil,93,Seine-Saint-Denis,11,Île-de-France
93049,NEUILLY PLAISANCE,93360,NEUILLY PLAISANCE,,48.8643287852,2.51040249898,49,,Neuilly-Plaisance,Neuilly-Plaisance,93,Seine-Saint-Denis,11,Île-de-France
93050,NEUILLY SUR MARNE,93330,NEUILLY SUR MARNE,,48.8624578581,2.54000288785,50,,Neuilly-sur-Marne,Neuilly-sur-Marne,93,Seine-Saint-Denis,11,Île-de-France
93051,NOISY LE GRAND,93160,NOISY LE GRAND,,48.8361825401,2.56443736814,51,,Noisy-le-Grand,Noisy-le-Grand,93,Seine-Saint-Denis,11,Île-de-France
93053,NOISY LE SEC,93130,NOISY LE SEC,,48.891322463,2.45913647372,53,,Noisy-le-Sec,Noisy-le-Sec,93,Seine-Saint-Denis,11,Île-de-France
93055,PANTIN,93500,PANTIN,,48.8983093876,2.40872147475,55,,Pantin,Pantin,93,Seine-Saint-Denis,11,Île-de-France
93057,LES PAVILLONS SOUS BOIS,93320,LES PAVILLONS SOUS BOIS,,48.9082060253,2.50297448267,57,Les,Pavillons-sous-Bois,Les Pavillons-sous-Bois,93,Seine-Saint-Denis,11,Île-de-France
93059,PIERREFITTE SUR SEINE,93380,PIERREFITTE SUR SEINE,,48.9609833355,2.36328125445,59,,Pierrefitte-sur-Seine,Pierrefitte-sur-Seine,93,Seine-Saint-Denis,11,Île-de-France
93061,LE PRE ST GERVAIS,93310,LE PRE ST GERVAIS,,48.8846734877,2.40542232961,61,Le,Pré-Saint-Gervais,Le Pré-Saint-Gervais,93,Seine-Saint-Denis,11,Île-de-France
93062,LE RAINCY,93340,LE RAINCY,,48.8967447585,2.51973664021,62,Le,Raincy,Le Raincy,93,Seine-Saint-Denis,11,Île-de-France
93063,ROMAINVILLE,93230,ROMAINVILLE,,48.8852118968,2.43767884231,63,,Romainville,Romainville,93,Seine-Saint-Denis,11,Île-de-France
93064,ROSNY SOUS BOIS,93110,ROSNY SOUS BOIS,,48.8745763768,2.4863404591,64,,Rosny-sous-Bois,Rosny-sous-Bois,93,Seine-Saint-Denis,11,Île-de-France
93066,ST DENIS,93200,ST DENIS,,48.9295650455,2.3592429975,66,,Saint-Denis,Saint-Denis,93,Seine-Saint-Denis,11,Île-de-France
93066,ST DENIS,93210,ST DENIS,LA PLAINE ST DENIS,48.9295650455,2.3592429975,66,,Saint-Denis,Saint-Denis,93,Seine-Saint-Denis,11,Île-de-France
93070,ST OUEN,93400,ST OUEN,,48.909806575,2.33257042205,70,,Saint-Ouen,Saint-Ouen,93,Seine-Saint-Denis,11,Île-de-France
93071,SEVRAN,93270,SEVRAN,,48.9386070153,2.53124057567,71,,Sevran,Sevran,93,Seine-Saint-Denis,11,Île-de-France
93072,STAINS,93240,STAINS,,48.9567775699,2.3854495629,72,,Stains,Stains,93,Seine-Saint-Denis,11,Île-de-France
93073,TREMBLAY EN FRANCE,93290,TREMBLAY EN FRANCE,,48.9784304121,2.55468501543,73,,Tremblay-en-France,Tremblay-en-France,93,Seine-Saint-Denis,11,Île-de-France
93074,VAUJOURS,93410,VAUJOURS,,48.9324772605,2.5810025704,74,,Vaujours,Vaujours,93,Seine-Saint-Denis,11,Île-de-France
93077,VILLEMOMBLE,93250,VILLEMOMBLE,,48.8848370021,2.50893406035,77,,Villemomble,Villemomble,93,Seine-Saint-Denis,11,Île-de-France
93078,VILLEPINTE,93420,VILLEPINTE,,48.9590202538,2.53630634206,78,,Villepinte,Villepinte,93,Seine-Saint-Denis,11,Île-de-France
93079,VILLETANEUSE,93430,VILLETANEUSE,,48.9572976501,2.34506633651,79,,Villetaneuse,Villetaneuse,93,Seine-Saint-Denis,11,Île-de-France
94001,ABLON SUR SEINE,94480,ABLON SUR SEINE,,48.7238409464,2.42117629348,1,,Ablon-sur-Seine,Ablon-sur-Seine,94,Val-de-Marne,11,Île-de-France
94002,ALFORTVILLE,94140,ALFORTVILLE,,48.7960843864,2.42124593241,2,,Alfortville,Alfortville,94,Val-de-Marne,11,Île-de-France
94003,ARCUEIL,94110,ARCUEIL,,48.8058803597,2.33351024984,3,,Arcueil,Arcueil,94,Val-de-Marne,11,Île-de-France
94004,BOISSY ST LEGER,94470,BOISSY ST LEGER,,48.7470359922,2.52528049019,4,,Boissy-Saint-Léger,Boissy-Saint-Léger,94,Val-de-Marne,11,Île-de-France
94011,BONNEUIL SUR MARNE,94380,BONNEUIL SUR MARNE,,48.7733620104,2.48799310463,11,,Bonneuil-sur-Marne,Bonneuil-sur-Marne,94,Val-de-Marne,11,Île-de-France
94015,BRY SUR MARNE,94360,BRY SUR MARNE,,48.8384095661,2.52319657626,15,,Bry-sur-Marne,Bry-sur-Marne,94,Val-de-Marne,11,Île-de-France
94016,CACHAN,94230,CACHAN,,48.7916121646,2.33153313164,16,,Cachan,Cachan,94,Val-de-Marne,11,Île-de-France
94017,CHAMPIGNY SUR MARNE,94500,CHAMPIGNY SUR MARNE,,48.817254364,2.51709815257,17,,Champigny-sur-Marne,Champigny-sur-Marne,94,Val-de-Marne,11,Île-de-France
94017,CHAMPIGNY SUR MARNE,94500,CHAMPIGNY SUR MARNE,COEUILLY,48.817254364,2.51709815257,17,,Champigny-sur-Marne,Champigny-sur-Marne,94,Val-de-Marne,11,Île-de-France
94018,CHARENTON LE PONT,94220,CHARENTON LE PONT,,48.8228084978,2.40760915477,18,,Charenton-le-Pont,Charenton-le-Pont,94,Val-de-Marne,11,Île-de-France
94019,CHENNEVIERES SUR MARNE,94430,CHENNEVIERES SUR MARNE,,48.7976502878,2.54157630073,19,,Chennevières-sur-Marne,Chennevières-sur-Marne,94,Val-de-Marne,11,Île-de-France
94021,CHEVILLY LARUE,94550,CHEVILLY LARUE,,48.766848001,2.35306611234,21,,Chevilly-Larue,Chevilly-Larue,94,Val-de-Marne,11,Île-de-France
94022,CHOISY LE ROI,94600,CHOISY LE ROI,,48.764315118,2.41742518222,22,,Choisy-le-Roi,Choisy-le-Roi,94,Val-de-Marne,11,Île-de-France
94028,CRETEIL,94000,CRETEIL,,48.7837401836,2.45463530415,28,,Créteil,Créteil,94,Val-de-Marne,11,Île-de-France
94033,FONTENAY SOUS BOIS,94120,FONTENAY SOUS BOIS,,48.8511046382,2.47395409774,33,,Fontenay-sous-Bois,Fontenay-sous-Bois,94,Val-de-Marne,11,Île-de-France
94034,FRESNES,94260,FRESNES,,48.7571343016,2.32612839743,34,,Fresnes,Fresnes,94,Val-de-Marne,11,Île-de-France
94037,GENTILLY,94250,GENTILLY,,48.8132044389,2.34420659702,37,,Gentilly,Gentilly,94,Val-de-Marne,11,Île-de-France
94038,L HAY LES ROSES,94240,L HAY LES ROSES,,48.7763207843,2.33772915609,38,L',Haÿ-les-Roses,L'Haÿ-les-Roses,94,Val-de-Marne,11,Île-de-France
94041,IVRY SUR SEINE,94200,IVRY SUR SEINE,,48.8128063812,2.38778023612,41,,Ivry-sur-Seine,Ivry-sur-Seine,94,Val-de-Marne,11,Île-de-France
94042,JOINVILLE LE PONT,94340,JOINVILLE LE PONT,,48.8193683216,2.47044977809,42,,Joinville-le-Pont,Joinville-le-Pont,94,Val-de-Marne,11,Île-de-France
94043,LE KREMLIN BICETRE,94270,LE KREMLIN BICETRE,,48.809220102,2.35657965243,43,Le,Kremlin-Bicêtre,Le Kremlin-Bicêtre,94,Val-de-Marne,11,Île-de-France
94044,LIMEIL BREVANNES,94450,LIMEIL BREVANNES,,48.7450357782,2.48936184357,44,,Limeil-Brévannes,Limeil-Brévannes,94,Val-de-Marne,11,Île-de-France
94046,MAISONS ALFORT,94700,MAISONS ALFORT,,48.8063923267,2.43818248906,46,,Maisons-Alfort,Maisons-Alfort,94,Val-de-Marne,11,Île-de-France
94047,MANDRES LES ROSES,94520,MANDRES LES ROSES,,48.7058880174,2.54830741614,47,,Mandres-les-Roses,Mandres-les-Roses,94,Val-de-Marne,11,Île-de-France
94048,MAROLLES EN BRIE,94440,MAROLLES EN BRIE,,48.7394780616,2.55491338455,48,,Marolles-en-Brie,Marolles-en-Brie,94,Val-de-Marne,11,Île-de-France
94052,NOGENT SUR MARNE,94130,NOGENT SUR MARNE,,48.8366634713,2.48166741533,52,,Nogent-sur-Marne,Nogent-sur-Marne,94,Val-de-Marne,11,Île-de-France
94053,NOISEAU,94880,NOISEAU,,48.774315467,2.55542375351,53,,Noiseau,Noiseau,94,Val-de-Marne,11,Île-de-France
94054,ORLY,94310,ORLY,,48.7429320073,2.39468296999,54,,Orly,Orly,94,Val-de-Marne,11,Île-de-France
94055,ORMESSON SUR MARNE,94490,ORMESSON SUR MARNE,,48.7854562744,2.53924214317,55,,Ormesson-sur-Marne,Ormesson-sur-Marne,94,Val-de-Marne,11,Île-de-France
94056,PERIGNY,94520,PERIGNY SUR YERRES,,48.6968359635,2.56167940426,56,,Périgny,Périgny,94,Val-de-Marne,11,Île-de-France
94058,LE PERREUX SUR MARNE,94170,LE PERREUX SUR MARNE,,48.8423952383,2.50406848573,58,Le,Perreux-sur-Marne,Le Perreux-sur-Marne,94,Val-de-Marne,11,Île-de-France
94059,LE PLESSIS TREVISE,94420,LE PLESSIS TREVISE,,48.8060154583,2.57619846918,59,Le,Plessis-Trévise,Le Plessis-Trévise,94,Val-de-Marne,11,Île-de-France
94060,LA QUEUE EN BRIE,94510,LA QUEUE EN BRIE,,48.7775866104,2.58385577608,60,La,Queue-en-Brie,La Queue-en-Brie,94,Val-de-Marne,11,Île-de-France
94065,RUNGIS,94150,RUNGIS,,48.7494821504,2.35281832827,65,,Rungis,Rungis,94,Val-de-Marne,11,Île-de-France
94067,ST MANDE,94160,ST MANDE,,48.842440243,2.4192191088,67,,Saint-Mandé,Saint-Mandé,94,Val-de-Marne,11,Île-de-France
94068,ST MAUR DES FOSSES,94100,ST MAUR DES FOSSES,,48.7990677797,2.49386450452,68,,Saint-Maur-des-Fossés,Saint-Maur-des-Fossés,94,Val-de-Marne,11,Île-de-France
94068,ST MAUR DES FOSSES,94210,ST MAUR DES FOSSES,LA VARENNE ST HILAIRE,48.7990677797,2.49386450452,68,,Saint-Maur-des-Fossés,Saint-Maur-des-Fossés,94,Val-de-Marne,11,Île-de-France
94069,ST MAURICE,94410,ST MAURICE,,48.8184921372,2.4374501342,69,,Saint-Maurice,Saint-Maurice,94,Val-de-Marne,11,Île-de-France
94070,SANTENY,94440,SANTENY,,48.7356177142,2.57637480242,70,,Santeny,Santeny,94,Val-de-Marne,11,Île-de-France
94071,SUCY EN BRIE,94370,SUCY EN BRIE,,48.7656456593,2.5330560803,71,,Sucy-en-Brie,Sucy-en-Brie,94,Val-de-Marne,11,Île-de-France
94071,SUCY EN BRIE,94370,SUCY EN BRIE,LES BRUYERES,48.7656456593,2.5330560803,71,,Sucy-en-Brie,Sucy-en-Brie,94,Val-de-Marne,11,Île-de-France
94073,THIAIS,94320,THIAIS,,48.7607841928,2.38537729524,73,,Thiais,Thiais,94,Val-de-Marne,11,Île-de-France
94074,VALENTON,94460,VALENTON,,48.752872402,2.46129479573,74,,Valenton,Valenton,94,Val-de-Marne,11,Île-de-France
94074,VALENTON,94460,VALENTON,VAL POMPADOUR,48.752872402,2.46129479573,74,,Valenton,Valenton,94,Val-de-Marne,11,Île-de-France
94075,VILLECRESNES,94440,VILLECRESNES,,48.7208692486,2.53157741439,75,,Villecresnes,Villecresnes,94,Val-de-Marne,11,Île-de-France
94076,VILLEJUIF,94800,VILLEJUIF,,48.7928547268,2.36006103249,76,,Villejuif,Villejuif,94,Val-de-Marne,11,Île-de-France
94077,VILLENEUVE LE ROI,94290,VILLENEUVE LE ROI,,48.7320914576,2.41037420069,77,,Villeneuve-le-Roi,Villeneuve-le-Roi,94,Val-de-Marne,11,Île-de-France
94078,VILLENEUVE ST GEORGES,94190,VILLENEUVE ST GEORGES,,48.7413809637,2.448998846,78,,Villeneuve-Saint-Georges,Villeneuve-Saint-Georges,94,Val-de-Marne,11,Île-de-France
94079,VILLIERS SUR MARNE,94350,VILLIERS SUR MARNE,,48.8259586165,2.54520483293,79,,Villiers-sur-Marne,Villiers-sur-Marne,94,Val-de-Marne,11,Île-de-France
94080,VINCENNES,94300,VINCENNES,,48.8473732045,2.43798650741,80,,Vincennes,Vincennes,94,Val-de-Marne,11,Île-de-France
94081,VITRY SUR SEINE,94400,VITRY SUR SEINE,,48.7882828307,2.39412680533,81,,Vitry-sur-Seine,Vitry-sur-Seine,94,Val-de-Marne,11,Île-de-France
95002,ABLEIGES,95450,ABLEIGES,,49.085772994,1.97486524588,2,,Ableiges,Ableiges,95,Val-d'Oise,11,Île-de-France
95008,AINCOURT,95510,AINCOURT,,49.0728443021,1.77403416153,8,,Aincourt,Aincourt,95,Val-d'Oise,11,Île-de-France
95011,AMBLEVILLE,95420,AMBLEVILLE,LE VAUMION,49.1509533273,1.69495333477,11,,Ambleville,Ambleville,95,Val-d'Oise,11,Île-de-France
95011,AMBLEVILLE,95710,AMBLEVILLE,,49.1509533273,1.69495333477,11,,Ambleville,Ambleville,95,Val-d'Oise,11,Île-de-France
95012,AMENUCOURT,95510,AMENUCOURT,,49.11033118,1.65004984269,12,,Amenucourt,Amenucourt,95,Val-d'Oise,11,Île-de-France
95014,ANDILLY,95580,ANDILLY,,49.0084088051,2.30277335813,14,,Andilly,Andilly,95,Val-d'Oise,11,Île-de-France
95018,ARGENTEUIL,95100,ARGENTEUIL,,48.9511344629,2.2410409502,18,,Argenteuil,Argenteuil,95,Val-d'Oise,11,Île-de-France
95019,ARNOUVILLE,95400,ARNOUVILLE,,48.9858270233,2.41714370822,19,,Arnouville,Arnouville,95,Val-d'Oise,11,Île-de-France
95023,ARRONVILLE,95810,ARRONVILLE,,49.1740736985,2.1128755699,23,,Arronville,Arronville,95,Val-d'Oise,11,Île-de-France
95024,ARTHIES,95420,ARTHIES,,49.0949860681,1.79726253732,24,,Arthies,Arthies,95,Val-d'Oise,11,Île-de-France
95026,ASNIERES SUR OISE,95270,ASNIERES SUR OISE,,49.1407338587,2.3800597257,26,,Asnières-sur-Oise,Asnières-sur-Oise,95,Val-d'Oise,11,Île-de-France
95028,ATTAINVILLE,95570,ATTAINVILLE,,49.0609607975,2.35043699897,28,,Attainville,Attainville,95,Val-d'Oise,11,Île-de-France
95039,AUVERS SUR OISE,95430,AUVERS SUR OISE,,49.0803858577,2.15488070885,39,,Auvers-sur-Oise,Auvers-sur-Oise,95,Val-d'Oise,11,Île-de-France
95040,AVERNES,95450,AVERNES,,49.0888264241,1.87003944746,40,,Avernes,Avernes,95,Val-d'Oise,11,Île-de-France
95042,BAILLET EN FRANCE,95560,BAILLET EN FRANCE,,49.0587634445,2.30242390203,42,,Baillet-en-France,Baillet-en-France,95,Val-d'Oise,11,Île-de-France
95046,BANTHELU,95420,BANTHELU,,49.125635171,1.81493362014,46,,Banthelu,Banthelu,95,Val-d'Oise,11,Île-de-France
95051,BEAUCHAMP,95250,BEAUCHAMP,,49.0148746005,2.19223334805,51,,Beauchamp,Beauchamp,95,Val-d'Oise,11,Île-de-France
95052,BEAUMONT SUR OISE,95260,BEAUMONT SUR OISE,,49.1400981058,2.30021267239,52,,Beaumont-sur-Oise,Beaumont-sur-Oise,95,Val-d'Oise,11,Île-de-France
95054,LE BELLAY EN VEXIN,95750,LE BELLAY EN VEXIN,,49.149355833,1.87860817105,54,Le,Bellay-en-Vexin,Le Bellay-en-Vexin,95,Val-d'Oise,11,Île-de-France
95055,BELLEFONTAINE,95270,BELLEFONTAINE,,49.0925450079,2.46955814701,55,,Bellefontaine,Bellefontaine,95,Val-d'Oise,11,Île-de-France
95056,BELLOY EN FRANCE,95270,BELLOY EN FRANCE,,49.0922395849,2.371761287,56,,Belloy-en-France,Belloy-en-France,95,Val-d'Oise,11,Île-de-France
95058,BERNES SUR OISE,95340,BERNES SUR OISE,,49.165413332,2.3012763367,58,,Bernes-sur-Oise,Bernes-sur-Oise,95,Val-d'Oise,11,Île-de-France
95059,BERVILLE,95810,BERVILLE,,49.1924943178,2.07091417142,59,,Berville,Berville,95,Val-d'Oise,11,Île-de-France
95060,BESSANCOURT,95550,BESSANCOURT,,49.0373082647,2.19851000707,60,,Bessancourt,Bessancourt,95,Val-d'Oise,11,Île-de-France
95061,BETHEMONT LA FORET,95840,BETHEMONT LA FORET,,49.0543170878,2.25114028557,61,,Béthemont-la-Forêt,Béthemont-la-Forêt,95,Val-d'Oise,11,Île-de-France
95063,BEZONS,95870,BEZONS,,48.9259810125,2.21133387765,63,,Bezons,Bezons,95,Val-d'Oise,11,Île-de-France
95074,BOISEMONT,95000,BOISEMONT,,49.0186866586,2.00384200606,74,,Boisemont,Boisemont,95,Val-d'Oise,11,Île-de-France
95078,BOISSY L AILLERIE,95650,BOISSY L AILLERIE,,49.0831174161,2.03269283542,78,,Boissy-l'Aillerie,Boissy-l'Aillerie,95,Val-d'Oise,11,Île-de-France
95088,BONNEUIL EN FRANCE,95500,BONNEUIL EN FRANCE,,48.9651461518,2.43492885419,88,,Bonneuil-en-France,Bonneuil-en-France,95,Val-d'Oise,11,Île-de-France
95091,BOUFFEMONT,95570,BOUFFEMONT,,49.04157253,2.29838457991,91,,Bouffémont,Bouffémont,95,Val-d'Oise,11,Île-de-France
95094,BOUQUEVAL,95720,BOUQUEVAL,,49.0223417404,2.42997422795,94,,Bouqueval,Bouqueval,95,Val-d'Oise,11,Île-de-France
95101,BRAY ET LU,95710,BRAY ET LU,,49.1421953145,1.66575656789,101,,Bray-et-Lû,Bray-et-Lû,95,Val-d'Oise,11,Île-de-France
95102,BREANCON,95640,BREANCON,,49.1493481193,2.02307108872,102,,Bréançon,Bréançon,95,Val-d'Oise,11,Île-de-France
95110,BRIGNANCOURT,95640,BRIGNANCOURT,,49.1395537337,1.94352100236,110,,Brignancourt,Brignancourt,95,Val-d'Oise,11,Île-de-France
95116,BRUYERES SUR OISE,95820,BRUYERES SUR OISE,,49.1610125293,2.32878300621,116,,Bruyères-sur-Oise,Bruyères-sur-Oise,95,Val-d'Oise,11,Île-de-France
95119,BUHY,95770,BUHY,,49.1917501414,1.70277550581,119,,Buhy,Buhy,95,Val-d'Oise,11,Île-de-France
95120,BUTRY SUR OISE,95430,BUTRY SUR OISE,,49.083906326,2.19184448916,120,,Butry-sur-Oise,Butry-sur-Oise,95,Val-d'Oise,11,Île-de-France
95127,CERGY,95000,CERGY,,49.0401131567,2.05082123731,127,,Cergy,Cergy,95,Val-d'Oise,11,Île-de-France
95127,CERGY,95000,CERGY,MENANDON,49.0401131567,2.05082123731,127,,Cergy,Cergy,95,Val-d'Oise,11,Île-de-France
95127,CERGY,95800,CERGY,,49.0401131567,2.05082123731,127,,Cergy,Cergy,95,Val-d'Oise,11,Île-de-France
95134,CHAMPAGNE SUR OISE,95660,CHAMPAGNE SUR OISE,,49.1427759123,2.22801680604,134,,Champagne-sur-Oise,Champagne-sur-Oise,95,Val-d'Oise,11,Île-de-France
95139,LA CHAPELLE EN VEXIN,95420,LA CHAPELLE EN VEXIN,,49.1835139361,1.72855495896,139,La,Chapelle-en-Vexin,La Chapelle-en-Vexin,95,Val-d'Oise,11,Île-de-France
95141,CHARMONT,95420,CHARMONT,,49.1370870263,1.78987414307,141,,Charmont,Charmont,95,Val-d'Oise,11,Île-de-France
95142,CHARS,95750,CHARS,,49.1576020656,1.93153133198,142,,Chars,Chars,95,Val-d'Oise,11,Île-de-France
95144,CHATENAY EN FRANCE,95190,CHATENAY EN FRANCE,,49.0649154148,2.46274737647,144,,Châtenay-en-France,Châtenay-en-France,95,Val-d'Oise,11,Île-de-France
95149,CHAUMONTEL,95270,CHAUMONTEL,,49.1289962967,2.43643330005,149,,Chaumontel,Chaumontel,95,Val-d'Oise,11,Île-de-France
95150,CHAUSSY,95710,CHAUSSY,,49.1134565492,1.70351386398,150,,Chaussy,Chaussy,95,Val-d'Oise,11,Île-de-France
95151,CHAUVRY,95560,CHAUVRY,,49.0530490833,2.26887039033,151,,Chauvry,Chauvry,95,Val-d'Oise,11,Île-de-France
95154,CHENNEVIERES LES LOUVRES,95380,CHENNEVIERES LES LOUVRES,,49.0420924353,2.54653894777,154,,Chennevières-lès-Louvres,Chennevières-lès-Louvres,95,Val-d'Oise,11,Île-de-France
95157,CHERENCE,95510,CHERENCE,,49.0911714832,1.68127200467,157,,Chérence,Chérence,95,Val-d'Oise,11,Île-de-France
95166,CLERY EN VEXIN,95420,CLERY EN VEXIN,,49.1319286241,1.8481642148,166,,Cléry-en-Vexin,Cléry-en-Vexin,95,Val-d'Oise,11,Île-de-France
95169,COMMENY,95450,COMMENY,,49.126580535,1.88800469417,169,,Commeny,Commeny,95,Val-d'Oise,11,Île-de-France
95170,CONDECOURT,95450,CONDECOURT,,49.0375647936,1.93659110857,170,,Condécourt,Condécourt,95,Val-d'Oise,11,Île-de-France
95176,CORMEILLES EN PARISIS,95240,CORMEILLES EN PARISIS,,48.9682249677,2.19921349468,176,,Cormeilles-en-Parisis,Cormeilles-en-Parisis,95,Val-d'Oise,11,Île-de-France
95177,CORMEILLES EN VEXIN,95830,CORMEILLES EN VEXIN,,49.1134329307,2.01977916637,177,,Cormeilles-en-Vexin,Cormeilles-en-Vexin,95,Val-d'Oise,11,Île-de-France
95181,COURCELLES SUR VIOSNE,95650,COURCELLES SUR VIOSNE,,49.073025738,1.99630178022,181,,Courcelles-sur-Viosne,Courcelles-sur-Viosne,95,Val-d'Oise,11,Île-de-France
95183,COURDIMANCHE,95800,COURDIMANCHE,,49.040676793,1.9994435918,183,,Courdimanche,Courdimanche,95,Val-d'Oise,11,Île-de-France
95197,DEUIL LA BARRE,95170,DEUIL LA BARRE,,48.9712690171,2.32616290144,197,,Deuil-la-Barre,Deuil-la-Barre,95,Val-d'Oise,11,Île-de-France
95199,DOMONT,95330,DOMONT,,49.0296655684,2.32290103729,199,,Domont,Domont,95,Val-d'Oise,11,Île-de-France
95203,EAUBONNE,95600,EAUBONNE,,48.9909940165,2.27800925488,203,,Eaubonne,Eaubonne,95,Val-d'Oise,11,Île-de-France
95205,ECOUEN,95440,ECOUEN,,49.0260643173,2.38485695599,205,,Écouen,Écouen,95,Val-d'Oise,11,Île-de-France
95210,ENGHIEN LES BAINS,95880,ENGHIEN LES BAINS,,48.97016048,2.30485955429,210,,Enghien-les-Bains,Enghien-les-Bains,95,Val-d'Oise,11,Île-de-France
95211,ENNERY,95300,ENNERY,,49.0784386043,2.10941210454,211,,Ennery,Ennery,95,Val-d'Oise,11,Île-de-France
95212,EPIAIS LES LOUVRES,95380,EPIAIS LES LOUVRES,,49.030193287,2.54884090855,212,,Épiais-lès-Louvres,Épiais-lès-Louvres,95,Val-d'Oise,11,Île-de-France
95213,EPIAIS RHUS,95810,EPIAIS RHUS,,49.1238363223,2.07311253247,213,,Épiais-Rhus,Épiais-Rhus,95,Val-d'Oise,11,Île-de-France
95214,EPINAY CHAMPLATREUX,95270,EPINAY CHAMPLATREUX,,49.0900262096,2.40710033598,214,,Épinay-Champlâtreux,Épinay-Champlâtreux,95,Val-d'Oise,11,Île-de-France
95218,ERAGNY,95610,ERAGNY SUR OISE,,49.0204587496,2.10136635847,218,,Éragny,Éragny,95,Val-d'Oise,11,Île-de-France
95219,ERMONT,95120,ERMONT,,48.98824217,2.25662443737,219,,Ermont,Ermont,95,Val-d'Oise,11,Île-de-France
95229,EZANVILLE,95460,EZANVILLE,,49.038600379,2.36269120289,229,,Ézanville,Ézanville,95,Val-d'Oise,11,Île-de-France
95241,FONTENAY EN PARISIS,95190,FONTENAY EN PARISIS,,49.0501196631,2.44539371928,241,,Fontenay-en-Parisis,Fontenay-en-Parisis,95,Val-d'Oise,11,Île-de-France
95250,FOSSES,95470,FOSSES,,49.1000437966,2.49906495383,250,,Fosses,Fosses,95,Val-d'Oise,11,Île-de-France
95252,FRANCONVILLE,95130,FRANCONVILLE LA GARENNE,,48.9884161105,2.2238932062,252,,Franconville,Franconville,95,Val-d'Oise,11,Île-de-France
95253,FREMAINVILLE,95450,FREMAINVILLE,,49.0662903393,1.86961416716,253,,Frémainville,Frémainville,95,Val-d'Oise,11,Île-de-France
95254,FREMECOURT,95830,FREMECOURT,,49.1234959164,1.99347687153,254,,Frémécourt,Frémécourt,95,Val-d'Oise,11,Île-de-France
95256,FREPILLON,95740,FREPILLON,,49.0501306912,2.20489899147,256,,Frépillon,Frépillon,95,Val-d'Oise,11,Île-de-France
95257,LA FRETTE SUR SEINE,95530,LA FRETTE SUR SEINE,,48.9745244717,2.17786777518,257,La,Frette-sur-Seine,La Frette-sur-Seine,95,Val-d'Oise,11,Île-de-France
95258,FROUVILLE,95690,FROUVILLE,,49.1574870013,2.14659092833,258,,Frouville,Frouville,95,Val-d'Oise,11,Île-de-France
95259,GADANCOURT,95450,GADANCOURT,,49.0985979496,1.85847770637,259,,Gadancourt,Gadancourt,95,Val-d'Oise,11,Île-de-France
95268,GARGES LES GONESSE,95140,GARGES LES GONESSE,,48.9701231267,2.40534038501,268,,Garges-lès-Gonesse,Garges-lès-Gonesse,95,Val-d'Oise,11,Île-de-France
95270,GENAINVILLE,95420,GENAINVILLE,,49.1168671395,1.75427492934,270,,Genainville,Genainville,95,Val-d'Oise,11,Île-de-France
95271,GENICOURT,95650,GENICOURT,,49.0948354012,2.06072705902,271,,Génicourt,Génicourt,95,Val-d'Oise,11,Île-de-France
95277,GONESSE,95500,GONESSE,,48.9864903049,2.4569899628,277,,Gonesse,Gonesse,95,Val-d'Oise,11,Île-de-France
95280,GOUSSAINVILLE,95190,GOUSSAINVILLE,,49.024343017,2.46619848866,280,,Goussainville,Goussainville,95,Val-d'Oise,11,Île-de-France
95282,GOUZANGREZ,95450,GOUZANGREZ,,49.113864439,1.90836879616,282,,Gouzangrez,Gouzangrez,95,Val-d'Oise,11,Île-de-France
95287,GRISY LES PLATRES,95810,GRISY LES PLATRES,,49.1332346678,2.0484611533,287,,Grisy-les-Plâtres,Grisy-les-Plâtres,95,Val-d'Oise,11,Île-de-France
95288,GROSLAY,95410,GROSLAY,,48.9849701077,2.35049057811,288,,Groslay,Groslay,95,Val-d'Oise,11,Île-de-France
95295,GUIRY EN VEXIN,95450,GUIRY EN VEXIN,,49.1158466771,1.85568912619,295,,Guiry-en-Vexin,Guiry-en-Vexin,95,Val-d'Oise,11,Île-de-France
95298,HARAVILLIERS,95640,HARAVILLIERS,,49.1752644516,2.03713061027,298,,Haravilliers,Haravilliers,95,Val-d'Oise,11,Île-de-France
95301,HAUTE ISLE,95780,HAUTE ISLE,,49.0827306409,1.66281760836,301,,Haute-Isle,Haute-Isle,95,Val-d'Oise,11,Île-de-France
95303,LE HEAULME,95640,LE HEAULME,,49.1669918244,2.00040147185,303,Le,Heaulme,Le Heaulme,95,Val-d'Oise,11,Île-de-France
95304,HEDOUVILLE,95690,HEDOUVILLE,,49.1584963577,2.17768034545,304,,Hédouville,Hédouville,95,Val-d'Oise,11,Île-de-France
95306,HERBLAY,95220,HERBLAY,,49.0007002763,2.14967680776,306,,Herblay,Herblay,95,Val-d'Oise,11,Île-de-France
95306,HERBLAY,95220,HERBLAY,LA PATTE D OIE,49.0007002763,2.14967680776,306,,Herblay,Herblay,95,Val-d'Oise,11,Île-de-France
95308,HEROUVILLE,95300,HEROUVILLE,,49.1048939093,2.12722046859,308,,Hérouville-en-Vexin,Hérouville-en-Vexin,95,Val-d'Oise,11,Île-de-France
95309,HODENT,95420,HODENT,,49.1421704888,1.762430658,309,,Hodent,Hodent,95,Val-d'Oise,11,Île-de-France
95313,L ISLE ADAM,95290,L ISLE ADAM,,49.1072255924,2.23436241795,313,L',Isle-Adam,L'Isle-Adam,95,Val-d'Oise,11,Île-de-France
95316,JAGNY SOUS BOIS,95850,JAGNY SOUS BOIS,,49.0814278078,2.44399511411,316,,Jagny-sous-Bois,Jagny-sous-Bois,95,Val-d'Oise,11,Île-de-France
95323,JOUY LE MOUTIER,95280,JOUY LE MOUTIER,,49.0113625056,2.03408317867,323,,Jouy-le-Moutier,Jouy-le-Moutier,95,Val-d'Oise,11,Île-de-France
95323,JOUY LE MOUTIER,95280,JOUY LE MOUTIER,JOUY LA FONTAINE,49.0113625056,2.03408317867,323,,Jouy-le-Moutier,Jouy-le-Moutier,95,Val-d'Oise,11,Île-de-France
95323,JOUY LE MOUTIER,95280,JOUY LE MOUTIER,VINCOURT,49.0113625056,2.03408317867,323,,Jouy-le-Moutier,Jouy-le-Moutier,95,Val-d'Oise,11,Île-de-France
95328,LABBEVILLE,95690,LABBEVILLE,,49.1338305724,2.13502924194,328,,Labbeville,Labbeville,95,Val-d'Oise,11,Île-de-France
95331,LASSY,95270,LASSY,,49.0949281199,2.4378486193,331,,Lassy,Lassy,95,Val-d'Oise,11,Île-de-France
95341,LIVILLIERS,95300,LIVILLIERS,,49.1004574229,2.09462391064,341,,Livilliers,Livilliers,95,Val-d'Oise,11,Île-de-France
95348,LONGUESSE,95450,LONGUESSE,,49.0616626709,1.92659421162,348,,Longuesse,Longuesse,95,Val-d'Oise,11,Île-de-France
95351,LOUVRES,95380,LOUVRES,,49.0422977702,2.50358491408,351,,Louvres,Louvres,95,Val-d'Oise,11,Île-de-France
95352,LUZARCHES,95270,LUZARCHES,,49.1151211125,2.44082640954,352,,Luzarches,Luzarches,95,Val-d'Oise,11,Île-de-France
95353,MAFFLIERS,95560,MAFFLIERS,,49.083629147,2.312333333,353,,Maffliers,Maffliers,95,Val-d'Oise,11,Île-de-France
95355,MAGNY EN VEXIN,95420,MAGNY EN VEXIN,,49.158617564,1.81039322129,355,,Magny-en-Vexin,Magny-en-Vexin,95,Val-d'Oise,11,Île-de-France
95365,MAREIL EN FRANCE,95850,MAREIL EN FRANCE,,49.0700384798,2.41753793907,365,,Mareil-en-France,Mareil-en-France,95,Val-d'Oise,11,Île-de-France
95369,MARGENCY,95580,MARGENCY,,49.0014888466,2.28907008969,369,,Margency,Margency,95,Val-d'Oise,11,Île-de-France
95370,MARINES,95640,MARINES,,49.1480448573,1.98376019265,370,,Marines,Marines,95,Val-d'Oise,11,Île-de-France
95371,MARLY LA VILLE,95670,MARLY LA VILLE,,49.0815057636,2.5087179751,371,,Marly-la-Ville,Marly-la-Ville,95,Val-d'Oise,11,Île-de-France
95379,MAUDETOUR EN VEXIN,95420,MAUDETOUR EN VEXIN,,49.1093620224,1.77708417418,379,,Maudétour-en-Vexin,Maudétour-en-Vexin,95,Val-d'Oise,11,Île-de-France
95387,MENOUVILLE,95810,MENOUVILLE,,49.1532355635,2.10779433987,387,,Menouville,Menouville,95,Val-d'Oise,11,Île-de-France
95388,MENUCOURT,95180,MENUCOURT,,49.0275422182,1.9746848215,388,,Menucourt,Menucourt,95,Val-d'Oise,11,Île-de-France
95392,MERIEL,95630,MERIEL,,49.0771587262,2.21325051405,392,,Mériel,Mériel,95,Val-d'Oise,11,Île-de-France
95394,MERY SUR OISE,95540,MERY SUR OISE,,49.0548422566,2.17397254184,394,,Méry-sur-Oise,Méry-sur-Oise,95,Val-d'Oise,11,Île-de-France
95395,LE MESNIL AUBRY,95720,LE MESNIL AUBRY,,49.0535430501,2.39230869097,395,Le,Mesnil-Aubry,Le Mesnil-Aubry,95,Val-d'Oise,11,Île-de-France
95409,MOISSELLES,95570,MOISSELLES,,49.0459598728,2.33744939306,409,,Moisselles,Moisselles,95,Val-d'Oise,11,Île-de-France
95422,MONTGEROULT,95650,MONTGEROULT,,49.0908042782,2.00787015509,422,,Montgeroult,Montgeroult,95,Val-d'Oise,11,Île-de-France
95424,MONTIGNY LES CORMEILLES,95370,MONTIGNY LES CORMEILLES,,48.9938035561,2.19351793046,424,,Montigny-lès-Cormeilles,Montigny-lès-Cormeilles,95,Val-d'Oise,11,Île-de-France
95426,MONTLIGNON,95680,MONTLIGNON,,49.0141682011,2.28900488904,426,,Montlignon,Montlignon,95,Val-d'Oise,11,Île-de-France
95427,MONTMAGNY,95360,MONTMAGNY,,48.9699195841,2.3460753179,427,,Montmagny,Montmagny,95,Val-d'Oise,11,Île-de-France
95428,MONTMORENCY,95160,MONTMORENCY,,48.9918643363,2.32119797848,428,,Montmorency,Montmorency,95,Val-d'Oise,11,Île-de-France
95429,MONTREUIL SUR EPTE,95770,MONTREUIL SUR EPTE,,49.1704950487,1.68615384906,429,,Montreuil-sur-Epte,Montreuil-sur-Epte,95,Val-d'Oise,11,Île-de-France
95430,MONTSOULT,95560,MONTSOULT,,49.0715830247,2.30381480542,430,,Montsoult,Montsoult,95,Val-d'Oise,11,Île-de-France
95436,MOURS,95260,MOURS,,49.1303947491,2.26415088892,436,,Mours,Mours,95,Val-d'Oise,11,Île-de-France
95438,MOUSSY,95640,MOUSSY,,49.1349938099,1.90868035748,438,,Moussy,Moussy,95,Val-d'Oise,11,Île-de-France
95445,NERVILLE LA FORET,95590,NERVILLE LA FORET,,49.0903513665,2.27625010335,445,,Nerville-la-Forêt,Nerville-la-Forêt,95,Val-d'Oise,11,Île-de-France
95446,NESLES LA VALLEE,95690,NESLES LA VALLEE,,49.1260403898,2.17176267252,446,,Nesles-la-Vallée,Nesles-la-Vallée,95,Val-d'Oise,11,Île-de-France
95447,NEUILLY EN VEXIN,95640,NEUILLY EN VEXIN,,49.1737847677,1.98023153343,447,,Neuilly-en-Vexin,Neuilly-en-Vexin,95,Val-d'Oise,11,Île-de-France
95450,NEUVILLE SUR OISE,95000,NEUVILLE SUR OISE,,49.0173343999,2.06276656128,450,,Neuville-sur-Oise,Neuville-sur-Oise,95,Val-d'Oise,11,Île-de-France
95452,NOINTEL,95590,NOINTEL,,49.1288691946,2.29434375998,452,,Nointel,Nointel,95,Val-d'Oise,11,Île-de-France
95456,NOISY SUR OISE,95270,NOISY SUR OISE,,49.1346676659,2.32848304396,456,,Noisy-sur-Oise,Noisy-sur-Oise,95,Val-d'Oise,11,Île-de-France
95459,NUCOURT,95420,NUCOURT,,49.1595393224,1.85643409298,459,,Nucourt,Nucourt,95,Val-d'Oise,11,Île-de-France
95462,OMERVILLE,95420,OMERVILLE,,49.1427407784,1.72224789983,462,,Omerville,Omerville,95,Val-d'Oise,11,Île-de-France
95476,OSNY,95520,OSNY,,49.0676589923,2.06311678451,476,,Osny,Osny,95,Val-d'Oise,11,Île-de-France
95480,PARMAIN,95620,PARMAIN,,49.1228699088,2.201277887,480,,Parmain,Parmain,95,Val-d'Oise,11,Île-de-France
95483,LE PERCHAY,95450,LE PERCHAY,,49.1164053302,1.92739087092,483,Le,Perchay,Le Perchay,95,Val-d'Oise,11,Île-de-France
95487,PERSAN,95340,PERSAN,,49.1497441715,2.26978393249,487,,Persan,Persan,95,Val-d'Oise,11,Île-de-France
95488,PIERRELAYE,95220,PIERRELAYE,,49.0194444023,2.16105557777,488,,Pierrelaye,Pierrelaye,95,Val-d'Oise,11,Île-de-France
95488,PIERRELAYE,95480,PIERRELAYE,,49.0194444023,2.16105557777,488,,Pierrelaye,Pierrelaye,95,Val-d'Oise,11,Île-de-France
95489,PISCOP,95350,PISCOP,,49.0165874672,2.34203473029,489,,Piscop,Piscop,95,Val-d'Oise,11,Île-de-France
95491,LE PLESSIS BOUCHARD,95130,LE PLESSIS BOUCHARD,,49.0032793478,2.23401999637,491,Le,Plessis-Bouchard,Le Plessis-Bouchard,95,Val-d'Oise,11,Île-de-France
95492,LE PLESSIS GASSOT,95720,LE PLESSIS GASSOT,,49.0357861801,2.41591923278,492,Le,Plessis-Gassot,Le Plessis-Gassot,95,Val-d'Oise,11,Île-de-France
95493,LE PLESSIS LUZARCHES,95270,LE PLESSIS LUZARCHES,,49.095729672,2.45475644312,493,Le,Plessis-Luzarches,Le Plessis-Luzarches,95,Val-d'Oise,11,Île-de-France
95500,PONTOISE,95000,PONTOISE,,49.0513737853,2.09487928948,500,,Pontoise,Pontoise,95,Val-d'Oise,11,Île-de-France
95500,PONTOISE,95300,PONTOISE,,49.0513737853,2.09487928948,500,,Pontoise,Pontoise,95,Val-d'Oise,11,Île-de-France
95504,PRESLES,95590,PRESLES,,49.1118569843,2.28674437908,504,,Presles,Presles,95,Val-d'Oise,11,Île-de-France
95509,PUISEUX EN FRANCE,95380,PUISEUX EN FRANCE,,49.0668504076,2.48852340935,509,,Puiseux-en-France,Puiseux-en-France,95,Val-d'Oise,11,Île-de-France
95510,PUISEUX PONTOISE,95650,PUISEUX PONTOISE,,49.0637642089,2.01586749979,510,,Puiseux-Pontoise,Puiseux-Pontoise,95,Val-d'Oise,11,Île-de-France
95523,LA ROCHE GUYON,95780,LA ROCHE GUYON,,49.0874855412,1.63442620986,523,La,Roche-Guyon,La Roche-Guyon,95,Val-d'Oise,11,Île-de-France
95527,ROISSY EN FRANCE,95700,ROISSY EN FRANCE,,49.00660444,2.51417743519,527,,Roissy-en-France,Roissy-en-France,95,Val-d'Oise,11,Île-de-France
95527,ROISSY EN FRANCE,95700,ROISSY EN FRANCE,ROISSY AEROPORT CHARLES DE GAULLE,49.00660444,2.51417743519,527,,Roissy-en-France,Roissy-en-France,95,Val-d'Oise,11,Île-de-France
95529,RONQUEROLLES,95340,RONQUEROLLES,,49.1677039084,2.21341666638,529,,Ronquerolles,Ronquerolles,95,Val-d'Oise,11,Île-de-France
95535,SAGY,95450,SAGY,,49.0526461167,1.96852121634,535,,Sagy,Sagy,95,Val-d'Oise,11,Île-de-France
95539,ST BRICE SOUS FORET,95350,ST BRICE SOUS FORET,,49.0019033624,2.35030688962,539,,Saint-Brice-sous-Forêt,Saint-Brice-sous-Forêt,95,Val-d'Oise,11,Île-de-France
95541,ST CLAIR SUR EPTE,95770,ST CLAIR SUR EPTE,,49.2131541909,1.70318156018,541,,Saint-Clair-sur-Epte,Saint-Clair-sur-Epte,95,Val-d'Oise,11,Île-de-France
95543,ST CYR EN ARTHIES,95510,ST CYR EN ARTHIES,,49.059130605,1.74195790474,543,,Saint-Cyr-en-Arthies,Saint-Cyr-en-Arthies,95,Val-d'Oise,11,Île-de-France
95554,ST GERVAIS,95420,ST GERVAIS,,49.1669076657,1.76166350491,554,,Saint-Gervais,Saint-Gervais,95,Val-d'Oise,11,Île-de-France
95555,ST GRATIEN,95210,ST GRATIEN,,48.9689724948,2.28518003438,555,,Saint-Gratien,Saint-Gratien,95,Val-d'Oise,11,Île-de-France
95563,ST LEU LA FORET,95320,ST LEU LA FORET,,49.0202164812,2.24676044206,563,,Saint-Leu-la-Forêt,Saint-Leu-la-Forêt,95,Val-d'Oise,11,Île-de-France
95566,ST MARTIN DU TERTRE,95270,ST MARTIN DU TERTRE,,49.1039330244,2.33445740142,566,,Saint-Martin-du-Tertre,Saint-Martin-du-Tertre,95,Val-d'Oise,11,Île-de-France
95572,ST OUEN L AUMONE,95310,ST OUEN L AUMONE,,49.0441683455,2.12861303743,572,,Saint-Ouen-l'Aumône,Saint-Ouen-l'Aumône,95,Val-d'Oise,11,Île-de-France
95574,ST PRIX,95390,ST PRIX,,49.0208224816,2.27015066493,574,,Saint-Prix,Saint-Prix,95,Val-d'Oise,11,Île-de-France
95580,ST WITZ,95470,ST WITZ,,49.0857837241,2.55227615042,580,,Saint-Witz,Saint-Witz,95,Val-d'Oise,11,Île-de-France
95582,SANNOIS,95110,SANNOIS,,48.9717773111,2.25299514112,582,,Sannois,Sannois,95,Val-d'Oise,11,Île-de-France
95584,SANTEUIL,95640,SANTEUIL,,49.1295034535,1.95810304373,584,,Santeuil,Santeuil,95,Val-d'Oise,11,Île-de-France
95585,SARCELLES,95200,SARCELLES,,48.9902250437,2.38160272749,585,,Sarcelles,Sarcelles,95,Val-d'Oise,11,Île-de-France
95592,SERAINCOURT,95450,SERAINCOURT,,49.0421395607,1.87866991801,592,,Seraincourt,Seraincourt,95,Val-d'Oise,11,Île-de-France
95594,SEUGY,95270,SEUGY,,49.1230209321,2.39479551154,594,,Seugy,Seugy,95,Val-d'Oise,11,Île-de-France
95598,SOISY SOUS MONTMORENCY,95230,SOISY SOUS MONTMORENCY,,48.9884077875,2.30049978342,598,,Soisy-sous-Montmorency,Soisy-sous-Montmorency,95,Val-d'Oise,11,Île-de-France
95604,SURVILLIERS,95470,SURVILLIERS,,49.1059736219,2.54410742752,604,,Survilliers,Survilliers,95,Val-d'Oise,11,Île-de-France
95607,TAVERNY,95150,TAVERNY,,49.0267316203,2.22116067166,607,,Taverny,Taverny,95,Val-d'Oise,11,Île-de-France
95610,THEMERICOURT,95450,THEMERICOURT,,49.0903809548,1.90532262834,610,,Théméricourt,Théméricourt,95,Val-d'Oise,11,Île-de-France
95611,THEUVILLE,95810,THEUVILLE,,49.1567965357,2.0691663538,611,,Theuville,Theuville,95,Val-d'Oise,11,Île-de-France
95612,LE THILLAY,95500,LE THILLAY,,49.004277001,2.46927524969,612,Le,Thillay,Le Thillay,95,Val-d'Oise,11,Île-de-France
95625,US,95450,US,,49.1048076828,1.95764610139,625,,Us,Us,95,Val-d'Oise,11,Île-de-France
95627,VALLANGOUJARD,95810,VALLANGOUJARD,,49.1340749867,2.10393344842,627,,Vallangoujard,Vallangoujard,95,Val-d'Oise,11,Île-de-France
95628,VALMONDOIS,95760,VALMONDOIS,,49.0976500716,2.18016319535,628,,Valmondois,Valmondois,95,Val-d'Oise,11,Île-de-France
95633,VAUDHERLAND,95500,VAUDHERLAND,,49.0004967295,2.48642956224,633,,Vaudherland,Vaudherland,95,Val-d'Oise,11,Île-de-France
95637,VAUREAL,95490,VAUREAL,,49.0295073382,2.02446619061,637,,Vauréal,Vauréal,95,Val-d'Oise,11,Île-de-France
95641,VEMARS,95470,VEMARS,,49.0628571398,2.56723072677,641,,Vémars,Vémars,95,Val-d'Oise,11,Île-de-France
95651,VETHEUIL,95510,VETHEUIL,,49.0620216513,1.70672153604,651,,Vétheuil,Vétheuil,95,Val-d'Oise,11,Île-de-France
95652,VIARMES,95270,VIARMES,,49.1231107932,2.37799676025,652,,Viarmes,Viarmes,95,Val-d'Oise,11,Île-de-France
95656,VIENNE EN ARTHIES,95510,VIENNE EN ARTHIES,,49.0664410063,1.72229012946,656,,Vienne-en-Arthies,Vienne-en-Arthies,95,Val-d'Oise,11,Île-de-France
95658,VIGNY,95450,VIGNY,,49.080165557,1.92992323682,658,,Vigny,Vigny,95,Val-d'Oise,11,Île-de-France
95660,VILLAINES SOUS BOIS,95570,VILLAINES SOUS BOIS,,49.0753739915,2.35663670214,660,,Villaines-sous-Bois,Villaines-sous-Bois,95,Val-d'Oise,11,Île-de-France
95675,VILLERON,95380,VILLERON,,49.060842982,2.5345109745,675,,Villeron,Villeron,95,Val-d'Oise,11,Île-de-France
95676,VILLERS EN ARTHIES,95510,VILLERS EN ARTHIES,,49.0858999284,1.73039629888,676,,Villers-en-Arthies,Villers-en-Arthies,95,Val-d'Oise,11,Île-de-France
95678,VILLIERS ADAM,95840,VILLIERS ADAM,,49.0702888248,2.23950871871,678,,Villiers-Adam,Villiers-Adam,95,Val-d'Oise,11,Île-de-France
95680,VILLIERS LE BEL,95400,VILLIERS LE BEL,,49.0084494157,2.40385604039,680,,Villiers-le-Bel,Villiers-le-Bel,95,Val-d'Oise,11,Île-de-France
95682,VILLIERS LE SEC,95720,VILLIERS LE SEC,,49.0743088682,2.38688959984,682,,Villiers-le-Sec,Villiers-le-Sec,95,Val-d'Oise,11,Île-de-France
95690,WY DIT JOLI VILLAGE,95420,WY DIT JOLI VILLAGE,,49.0958197758,1.82502883308,690,,Wy-dit-Joli-Village,Wy-dit-Joli-Village,95,Val-d'Oise,11,Île-de-France
97101,LES ABYMES,97139,LES ABYMES,,16.2727794035,-61.5017044974,1,Les,Abymes,Les Abymes,971,Guadeloupe,1,Guadeloupe
97101,LES ABYMES,97142,LES ABYMES,,16.2727794035,-61.5017044974,1,Les,Abymes,Les Abymes,971,Guadeloupe,1,Guadeloupe
97102,ANSE BERTRAND,97121,ANSE BERTRAND,,16.4655608425,-61.4616707348,2,,Anse-Bertrand,Anse-Bertrand,971,Guadeloupe,1,Guadeloupe
97103,BAIE MAHAULT,97122,BAIE MAHAULT,,16.251171116,-61.5929355877,3,,Baie-Mahault,Baie-Mahault,971,Guadeloupe,1,Guadeloupe
97104,BAILLIF,97123,BAILLIF,,16.0455136353,-61.7192956906,4,,Baillif,Baillif,971,Guadeloupe,1,Guadeloupe
97105,BASSE TERRE,97100,BASSE TERRE,,16.0004645662,-61.7281032959,5,,Basse-Terre,Basse-Terre,971,Guadeloupe,1,Guadeloupe
97106,BOUILLANTE,97125,BOUILLANTE,,16.1437220237,-61.754179331,6,,Bouillante,Bouillante,971,Guadeloupe,1,Guadeloupe
97106,BOUILLANTE,97125,BOUILLANTE,PIGEON,16.1437220237,-61.754179331,6,,Bouillante,Bouillante,971,Guadeloupe,1,Guadeloupe
97107,CAPESTERRE BELLE EAU,97130,CAPESTERRE BELLE EAU,,16.0553416101,-61.6117007507,7,,Capesterre-Belle-Eau,Capesterre-Belle-Eau,971,Guadeloupe,1,Guadeloupe
97107,CAPESTERRE BELLE EAU,97130,CAPESTERRE BELLE EAU,BANANIER,16.0553416101,-61.6117007507,7,,Capesterre-Belle-Eau,Capesterre-Belle-Eau,971,Guadeloupe,1,Guadeloupe
97107,CAPESTERRE BELLE EAU,97130,CAPESTERRE BELLE EAU,STE MARIE,16.0553416101,-61.6117007507,7,,Capesterre-Belle-Eau,Capesterre-Belle-Eau,971,Guadeloupe,1,Guadeloupe
97108,CAPESTERRE DE MARIE GALANTE,97140,CAPESTERRE DE MARIE GALANTE,,15.9200251247,-61.2299898097,8,,Capesterre-de-Marie-Galante,Capesterre-de-Marie-Galante,971,Guadeloupe,1,Guadeloupe
97109,GOURBEYRE,97113,GOURBEYRE,,15.9939024439,-61.6864178604,9,,Gourbeyre,Gourbeyre,971,Guadeloupe,1,Guadeloupe
97110,LA DESIRADE,97127,LA DESIRADE,,16.311751596,-61.0526890523,10,La,Désirade,La Désirade,971,Guadeloupe,1,Guadeloupe
97111,DESHAIES,97126,DESHAIES,,16.3037984071,-61.7795787226,11,,Deshaies,Deshaies,971,Guadeloupe,1,Guadeloupe
97112,GRAND BOURG,97112,GRAND BOURG,,15.9060600751,-61.2943821122,12,,Grand-Bourg,Grand-Bourg,971,Guadeloupe,1,Guadeloupe
97113,LE GOSIER,97190,LE GOSIER,,16.2252192032,-61.4698776652,13,Le,Gosier,Le Gosier,971,Guadeloupe,1,Guadeloupe
97114,GOYAVE,97128,GOYAVE,,16.1216759478,-61.6138081813,14,,Goyave,Goyave,971,Guadeloupe,1,Guadeloupe
97115,LAMENTIN,97129,LAMENTIN,,16.2413995875,-61.6685299192,15,,Lamentin,Lamentin,971,Guadeloupe,1,Guadeloupe
97116,MORNE A L EAU,97111,MORNE A L EAU,,16.3240215714,-61.470417696,16,,Morne-à-l'Eau,Morne-à-l'Eau,971,Guadeloupe,1,Guadeloupe
97117,LE MOULE,97160,LE MOULE,,16.3204595682,-61.3718985191,17,Le,Moule,Le Moule,971,Guadeloupe,1,Guadeloupe
97118,PETIT BOURG,97170,PETIT BOURG,,16.1716063795,-61.657244347,18,,Petit-Bourg,Petit-Bourg,971,Guadeloupe,1,Guadeloupe
97119,PETIT CANAL,97131,PETIT CANAL,,16.3893559546,-61.4497634927,19,,Petit-Canal,Petit-Canal,971,Guadeloupe,1,Guadeloupe
97119,PETIT CANAL,97131,PETIT CANAL,LES MANGLES,16.3893559546,-61.4497634927,19,,Petit-Canal,Petit-Canal,971,Guadeloupe,1,Guadeloupe
97120,POINTE A PITRE,97110,POINTE A PITRE,,16.2386006963,-61.5357180881,20,,Pointe-à-Pitre,Pointe-à-Pitre,971,Guadeloupe,1,Guadeloupe
97121,POINTE NOIRE,97116,POINTE NOIRE,,16.227093809,-61.7632677647,21,,Pointe-Noire,Pointe-Noire,971,Guadeloupe,1,Guadeloupe
97122,PORT LOUIS,97117,PORT LOUIS,,16.4263736562,-61.4965320062,22,,Port-Louis,Port-Louis,971,Guadeloupe,1,Guadeloupe
97124,ST CLAUDE,97120,ST CLAUDE,,16.033779046,-61.690101603,24,,Saint-Claude,Saint-Claude,971,Guadeloupe,1,Guadeloupe
97125,ST FRANCOIS,97118,ST FRANCOIS,,16.2732452923,-61.2816382728,25,,Saint-François,Saint-François,971,Guadeloupe,1,Guadeloupe
97126,ST LOUIS,97134,ST LOUIS,,15.9659560448,-61.275417015,26,,Saint-Louis,Saint-Louis,971,Guadeloupe,1,Guadeloupe
97128,STE ANNE,97180,STE ANNE,,16.2572300868,-61.3874730054,28,,Sainte-Anne,Sainte-Anne,971,Guadeloupe,1,Guadeloupe
97128,STE ANNE,97180,STE ANNE,DOUVILLE,16.2572300868,-61.3874730054,28,,Sainte-Anne,Sainte-Anne,971,Guadeloupe,1,Guadeloupe
97129,STE ROSE,97115,STE ROSE,,16.2939779294,-61.7100293031,29,,Sainte-Rose,Sainte-Rose,971,Guadeloupe,1,Guadeloupe
97130,TERRE DE BAS,97136,TERRE DE BAS,,15.8543976309,-61.6341060684,30,,Terre-de-Bas,Terre-de-Bas,971,Guadeloupe,1,Guadeloupe
97131,TERRE DE HAUT,97137,TERRE DE HAUT,,15.8653892358,-61.5842592994,31,,Terre-de-Haut,Terre-de-Haut,971,Guadeloupe,1,Guadeloupe
97132,TROIS RIVIERES,97114,TROIS RIVIERES,,15.9908100557,-61.6479632247,32,,Trois-Rivières,Trois-Rivières,971,Guadeloupe,1,Guadeloupe
97133,VIEUX FORT,97141,VIEUX FORT,,15.9602228219,-61.6945194162,33,,Vieux-Fort,Vieux-Fort,971,Guadeloupe,1,Guadeloupe
97134,VIEUX HABITANTS,97119,VIEUX HABITANTS,,16.0870945547,-61.7257229749,34,,Vieux-Habitants,Vieux-Habitants,971,Guadeloupe,1,Guadeloupe
97201,L AJOUPA BOUILLON,97216,L AJOUPA BOUILLON,,14.8142832959,-61.1230843265,1,L',Ajoupa-Bouillon,L'Ajoupa-Bouillon,972,Martinique,2,Martinique
97202,LES ANSES D ARLET,97217,LES ANSES D ARLET,,14.4993591112,-61.0720993976,2,Les,Anses-d'Arlet,Les Anses-d'Arlet,972,Martinique,2,Martinique
97203,BASSE POINTE,97218,BASSE POINTE,,14.8414694685,-61.1232211344,3,,Basse-Pointe,Basse-Pointe,972,Martinique,2,Martinique
97204,LE CARBET,97221,LE CARBET,,14.7110039815,-61.1647565483,4,Le,Carbet,Le Carbet,972,Martinique,2,Martinique
97205,CASE PILOTE,97222,CASE PILOTE,,14.6586961722,-61.1253927105,5,,Case-Pilote,Case-Pilote,972,Martinique,2,Martinique
97206,LE DIAMANT,97223,LE DIAMANT,,14.4890909775,-61.0210620105,6,Le,Diamant,Le Diamant,972,Martinique,2,Martinique
97207,DUCOS,97224,DUCOS,,14.5777851381,-60.9676704441,7,,Ducos,Ducos,972,Martinique,2,Martinique
97208,FONDS ST DENIS,97250,FONDS ST DENIS,,14.7260291636,-61.1150746091,8,,Fonds-Saint-Denis,Fonds-Saint-Denis,972,Martinique,2,Martinique
97209,FORT DE FRANCE,97200,FORT DE FRANCE,,14.6411114389,-61.0691889244,9,,Fort-de-France,Fort-de-France,972,Martinique,2,Martinique
97209,FORT DE FRANCE,97234,FORT DE FRANCE,,14.6411114389,-61.0691889244,9,,Fort-de-France,Fort-de-France,972,Martinique,2,Martinique
97210,LE FRANCOIS,97240,LE FRANCOIS,,14.6060564718,-60.9057158214,10,Le,François,Le François,972,Martinique,2,Martinique
97211,GRAND RIVIERE,97218,GRAND RIVIERE,,14.8500162757,-61.1794005082,11,,Grand'Rivière,Grand'Rivière,972,Martinique,2,Martinique
97212,GROS MORNE,97213,GROS MORNE,,14.7121020202,-61.0260573879,12,,Gros-Morne,Gros-Morne,972,Martinique,2,Martinique
97213,LE LAMENTIN,97232,LE LAMENTIN,,14.6247600105,-60.9933549815,13,Le,Lamentin,Le Lamentin,972,Martinique,2,Martinique
97214,LE LORRAIN,97214,LE LORRAIN,,14.801120487,-61.0765081422,14,Le,Lorrain,Le Lorrain,972,Martinique,2,Martinique
97215,MACOUBA,97218,MACOUBA,,14.8550940199,-61.151590278,15,,Macouba,Macouba,972,Martinique,2,Martinique
97216,LE MARIGOT,97225,LE MARIGOT,,14.7808848923,-61.0507940924,16,Le,Marigot,Le Marigot,972,Martinique,2,Martinique
97217,LE MARIN,97290,LE MARIN,,14.4840254458,-60.8579380107,17,Le,Marin,Le Marin,972,Martinique,2,Martinique
97218,LE MORNE ROUGE,97260,LE MORNE ROUGE,,14.7694146135,-61.1206407775,18,Le,Morne-Rouge,Le Morne-Rouge,972,Martinique,2,Martinique
97219,LE PRECHEUR,97250,LE PRECHEUR,,14.8208189879,-61.2034771768,19,Le,Prêcheur,Le Prêcheur,972,Martinique,2,Martinique
97220,RIVIERE PILOTE,97211,RIVIERE PILOTE,,14.5088319879,-60.8978478666,20,,Rivière-Pilote,Rivière-Pilote,972,Martinique,2,Martinique
97221,RIVIERE SALEE,97215,RIVIERE SALEE,,14.5261192696,-60.9652129464,21,,Rivière-Salée,Rivière-Salée,972,Martinique,2,Martinique
97221,RIVIERE SALEE,97215,RIVIERE SALEE,RIVIERE SALEE PETIT BOURG,14.5261192696,-60.9652129464,21,,Rivière-Salée,Rivière-Salée,972,Martinique,2,Martinique
97222,LE ROBERT,97231,LE ROBERT,,14.6752109401,-60.9429518756,22,Le,Robert,Le Robert,972,Martinique,2,Martinique
97222,LE ROBERT,97231,LE ROBERT,ROBERT VERT PRE,14.6752109401,-60.9429518756,22,Le,Robert,Le Robert,972,Martinique,2,Martinique
97223,ST ESPRIT,97270,ST ESPRIT,,14.5588947803,-60.9216071826,23,,Saint-Esprit,Saint-Esprit,972,Martinique,2,Martinique
97224,ST JOSEPH,97212,ST JOSEPH,,14.6764143687,-61.0439024925,24,,Saint-Joseph,Saint-Joseph,972,Martinique,2,Martinique
97225,ST PIERRE,97250,ST PIERRE,,14.7748596787,-61.1724905513,25,,Saint-Pierre,Saint-Pierre,972,Martinique,2,Martinique
97226,STE ANNE,97227,STE ANNE,,14.4366363772,-60.8550865047,26,,Sainte-Anne,Sainte-Anne,972,Martinique,2,Martinique
97227,STE LUCE,97228,STE LUCE,,14.4855423973,-60.9451190973,27,,Sainte-Luce,Sainte-Luce,972,Martinique,2,Martinique
97228,STE MARIE,97230,STE MARIE,,14.7689902687,-61.0169082223,28,,Sainte-Marie,Sainte-Marie,972,Martinique,2,Martinique
97228,STE MARIE,97230,STE MARIE,STE MARIE MORNE DES ESSES,14.7689902687,-61.0169082223,28,,Sainte-Marie,Sainte-Marie,972,Martinique,2,Martinique
97229,SCHOELCHER,97233,SCHOELCHER,,14.6449217661,-61.100038609,29,,Schœlcher,Schœlcher,972,Martinique,2,Martinique
97230,LA TRINITE,97220,LA TRINITE,,14.7386362443,-60.9496306893,30,La,Trinité,La Trinité,972,Martinique,2,Martinique
97231,LES TROIS ILETS,97229,LES TROIS ILETS,,14.5272688928,-61.0370896934,31,Les,Trois-Îlets,Les Trois-Îlets,972,Martinique,2,Martinique
97232,LE VAUCLIN,97280,LE VAUCLIN,,14.5436764866,-60.8571906355,32,Le,Vauclin,Le Vauclin,972,Martinique,2,Martinique
97233,LE MORNE VERT,97226,LE MORNE VERT,,14.7038196531,-61.1353176501,33,Le,Morne-Vert,Le Morne-Vert,972,Martinique,2,Martinique
97234,BELLEFONTAINE,97222,BELLEFONTAINE,,14.6763386824,-61.1469824012,34,,Bellefontaine,Bellefontaine,972,Martinique,2,Martinique
97301,REGINA,97353,REGINA,KAW,3.94091776652,-52.5199269871,1,,Régina,Régina,973,Guyane,3,Guyane
97301,REGINA,97390,REGINA,,3.94091776652,-52.5199269871,1,,Régina,Régina,973,Guyane,3,Guyane
97302,CAYENNE,97300,CAYENNE,,5.02173742828,-52.5012511113,2,,Cayenne,Cayenne,973,Guyane,3,Guyane
97303,IRACOUBO,97350,IRACOUBO,,5.27291181428,-53.3208385984,3,,Iracoubo,Iracoubo,973,Guyane,3,Guyane
97304,KOUROU,97310,KOUROU,,4.93360677012,-52.7663314416,4,,Kourou,Kourou,973,Guyane,3,Guyane
97305,MACOURIA,97355,MACOURIA TONATE,,4.98238462171,-52.5084392425,5,,Macouria,Macouria,973,Guyane,3,Guyane
97306,MANA,97318,MANA,JAVOUHEY,4.98285747902,-53.6480364932,6,,Mana,Mana,973,Guyane,3,Guyane
97306,MANA,97360,MANA,,4.98285747902,-53.6480364932,6,,Mana,Mana,973,Guyane,3,Guyane
97307,MATOURY,97351,MATOURY,,4.83179351032,-52.3435609813,7,,Matoury,Matoury,973,Guyane,3,Guyane
97308,ST GEORGES,97313,ST GEORGES,,3.74201791735,-52.1323737516,8,,Saint-Georges,Saint-Georges,973,Guyane,3,Guyane
97309,REMIRE MONTJOLY,97354,REMIRE MONTJOLY,,4.88437128593,-52.2788402688,9,,Remire-Montjoly,Remire-Montjoly,973,Guyane,3,Guyane
97310,ROURA,97311,ROURA,,4.46468598944,-52.5128388412,10,,Roura,Roura,973,Guyane,3,Guyane
97310,ROURA,97352,ROURA,CACAO,4.46468598944,-52.5128388412,10,,Roura,Roura,973,Guyane,3,Guyane
97311,ST LAURENT DU MARONI,97320,ST LAURENT DU MARONI,,4.96474398632,-53.9832113662,11,,Saint-Laurent-du-Maroni,Saint-Laurent-du-Maroni,973,Guyane,3,Guyane
97312,SINNAMARY,97315,SINNAMARY,,5.23199544843,-53.01314648,12,,Sinnamary,Sinnamary,973,Guyane,3,Guyane
97313,MONTSINERY TONNEGRANDE,97356,MONTSINERY TONNEGRANDE,,4.80953892931,-52.5098116892,13,,Montsinéry-Tonnegrande,Montsinéry-Tonnegrande,973,Guyane,3,Guyane
97314,OUANARY,97380,OUANARY,,4.19095709482,-51.8100673748,14,,Ouanary,Ouanary,973,Guyane,3,Guyane
97352,SAUL,97314,SAUL,,3.91592827293,-53.3990290604,52,,Saül,Saül,973,Guyane,3,Guyane
97353,MARIPASOULA,97370,MARIPASOULA,,2.97088417594,-53.7584193112,53,,Maripasoula,Maripasoula,973,Guyane,3,Guyane
97356,CAMOPI,97330,CAMOPI,,2.83799126092,-52.796516306,56,,Camopi,Camopi,973,Guyane,3,Guyane
97357,GRAND SANTI,97340,GRAND SANTI,,4.3843431186,-54.1716818959,57,,Grand-Santi,Grand-Santi,973,Guyane,3,Guyane
97358,ST ELIE,97312,ST ELIE,,4.52922646237,-53.1006779669,58,,Saint-Élie,Saint-Élie,973,Guyane,3,Guyane
97360,APATOU,97317,APATOU,,4.83044768998,-54.2705505503,60,,Apatou,Apatou,973,Guyane,3,Guyane
97361,AWALA YALIMAPO,97319,AWALA YALIMAPO,,5.68354192071,-53.9162639219,61,,Awala-Yalimapo,Awala-Yalimapo,973,Guyane,3,Guyane
97362,PAPAICHTON,97316,POMPIDOU PAPA ICHTON,,4.04155401384,-54.044235852,62,,Papaichton,Papaichton,973,Guyane,3,Guyane
97401,LES AVIRONS,97425,LES AVIRONS,,-21.2089994294,55.3591485447,1,Les,Avirons,Les Avirons,974,La Réunion,4,La Réunion
97401,LES AVIRONS,97425,LES AVIRONS,TEVELAVE,-21.2089994294,55.3591485447,1,Les,Avirons,Les Avirons,974,La Réunion,4,La Réunion
97402,BRAS PANON,97412,BRAS PANON,,-21.0233281862,55.6195846061,2,,Bras-Panon,Bras-Panon,974,La Réunion,4,La Réunion
97402,BRAS PANON,97412,BRAS PANON,RIVIERE DU MAT,-21.0233281862,55.6195846061,2,,Bras-Panon,Bras-Panon,974,La Réunion,4,La Réunion
97403,ENTRE DEUX,97414,ENTRE DEUX,,-21.2042397201,55.4922992792,3,,Entre-Deux,Entre-Deux,974,La Réunion,4,La Réunion
97404,L ETANG SALE,97427,L ETANG SALE,,-21.2494246287,55.3667927801,4,L',Étang-Salé,L'Étang-Salé,974,La Réunion,4,La Réunion
97404,L ETANG SALE,97427,L ETANG SALE,L ETANG SALE LES BAINS,-21.2494246287,55.3667927801,4,L',Étang-Salé,L'Étang-Salé,974,La Réunion,4,La Réunion
97405,PETITE ILE,97429,PETITE ILE,,-21.3401068981,55.5687050843,5,,Petite-Île,Petite-Île,974,La Réunion,4,La Réunion
97405,PETITE ILE,97429,PETITE ILE,PITON GOYAVES,-21.3401068981,55.5687050843,5,,Petite-Île,Petite-Île,974,La Réunion,4,La Réunion
97406,LA PLAINE DES PALMISTES,97431,LA PLAINE DES PALMISTES,,-21.1521762495,55.6445268962,6,La,Plaine-des-Palmistes,La Plaine-des-Palmistes,974,La Réunion,4,La Réunion
97407,LE PORT,97420,LE PORT,,-20.9448851377,55.305326715,7,Le,Port,Le Port,974,La Réunion,4,La Réunion
97407,LE PORT,97420,LE PORT,LE PORT MARINE,-20.9448851377,55.305326715,7,Le,Port,Le Port,974,La Réunion,4,La Réunion
97407,LE PORT,97420,LE PORT,LE PORT ZUP,-20.9448851377,55.305326715,7,Le,Port,Le Port,974,La Réunion,4,La Réunion
97408,LA POSSESSION,97419,LA POSSESSION,,-20.9940292693,55.3975084985,8,La,Possession,La Possession,974,La Réunion,4,La Réunion
97408,LA POSSESSION,97419,LA POSSESSION,LA RIVIERE DES GALETS,-20.9940292693,55.3975084985,8,La,Possession,La Possession,974,La Réunion,4,La Réunion
97408,LA POSSESSION,97419,LA POSSESSION,LE DOS D ANE,-20.9940292693,55.3975084985,8,La,Possession,La Possession,974,La Réunion,4,La Réunion
97408,LA POSSESSION,97419,LA POSSESSION,STE THERESE,-20.9940292693,55.3975084985,8,La,Possession,La Possession,974,La Réunion,4,La Réunion
97409,ST ANDRE,97440,ST ANDRE,,-20.9630406732,55.6427328786,9,,Saint-André,Saint-André,974,La Réunion,4,La Réunion
97409,ST ANDRE,97440,ST ANDRE,CAMBUSTON,-20.9630406732,55.6427328786,9,,Saint-André,Saint-André,974,La Réunion,4,La Réunion
97409,ST ANDRE,97440,ST ANDRE,LA CRESSONNIERE,-20.9630406732,55.6427328786,9,,Saint-André,Saint-André,974,La Réunion,4,La Réunion
97410,ST BENOIT,97437,ST BENOIT,STE ANNE,-21.0918662796,55.6494168586,10,,Saint-Benoît,Saint-Benoît,974,La Réunion,4,La Réunion
97410,ST BENOIT,97470,ST BENOIT,,-21.0918662796,55.6494168586,10,,Saint-Benoît,Saint-Benoît,974,La Réunion,4,La Réunion
97410,ST BENOIT,97470,ST BENOIT,BEAUFONDS,-21.0918662796,55.6494168586,10,,Saint-Benoît,Saint-Benoît,974,La Réunion,4,La Réunion
97411,ST DENIS,97400,ST DENIS,,-20.9329708192,55.446867167,11,,Saint-Denis,Saint-Denis,974,La Réunion,4,La Réunion
97411,ST DENIS,97400,ST DENIS,BELLE PIERRE,-20.9329708192,55.446867167,11,,Saint-Denis,Saint-Denis,974,La Réunion,4,La Réunion
97411,ST DENIS,97400,ST DENIS,LE BRULE,-20.9329708192,55.446867167,11,,Saint-Denis,Saint-Denis,974,La Réunion,4,La Réunion
97411,ST DENIS,97400,ST DENIS,ST DENIS CAMELIAS,-20.9329708192,55.446867167,11,,Saint-Denis,Saint-Denis,974,La Réunion,4,La Réunion
97411,ST DENIS,97400,ST DENIS,ST DENIS TADAR,-20.9329708192,55.446867167,11,,Saint-Denis,Saint-Denis,974,La Réunion,4,La Réunion
97411,ST DENIS,97400,ST DENIS,ST FRANCOIS,-20.9329708192,55.446867167,11,,Saint-Denis,Saint-Denis,974,La Réunion,4,La Réunion
97411,ST DENIS,97417,ST DENIS,LA MONTAGNE,-20.9329708192,55.446867167,11,,Saint-Denis,Saint-Denis,974,La Réunion,4,La Réunion
97411,ST DENIS,97417,ST DENIS,ST BERNARD,-20.9329708192,55.446867167,11,,Saint-Denis,Saint-Denis,974,La Réunion,4,La Réunion
97411,ST DENIS,97490,ST DENIS,BOIS DE NEFLES ST DENIS,-20.9329708192,55.446867167,11,,Saint-Denis,Saint-Denis,974,La Réunion,4,La Réunion
97411,ST DENIS,97490,ST DENIS,LA BRETAGNE,-20.9329708192,55.446867167,11,,Saint-Denis,Saint-Denis,974,La Réunion,4,La Réunion
97411,ST DENIS,97490,ST DENIS,MOUFIA,-20.9329708192,55.446867167,11,,Saint-Denis,Saint-Denis,974,La Réunion,4,La Réunion
97411,ST DENIS,97490,ST DENIS,ST DENIS CHAUDRON,-20.9329708192,55.446867167,11,,Saint-Denis,Saint-Denis,974,La Réunion,4,La Réunion
97411,ST DENIS,97490,ST DENIS,STE CLOTILDE,-20.9329708192,55.446867167,11,,Saint-Denis,Saint-Denis,974,La Réunion,4,La Réunion
97412,ST JOSEPH,97480,ST JOSEPH,,-21.3063550443,55.6420686506,12,,Saint-Joseph,Saint-Joseph,974,La Réunion,4,La Réunion
97412,ST JOSEPH,97480,ST JOSEPH,JEAN PETIT,-21.3063550443,55.6420686506,12,,Saint-Joseph,Saint-Joseph,974,La Réunion,4,La Réunion
97412,ST JOSEPH,97480,ST JOSEPH,LES LIANES,-21.3063550443,55.6420686506,12,,Saint-Joseph,Saint-Joseph,974,La Réunion,4,La Réunion
97412,ST JOSEPH,97480,ST JOSEPH,VINCENDO,-21.3063550443,55.6420686506,12,,Saint-Joseph,Saint-Joseph,974,La Réunion,4,La Réunion
97413,ST LEU,97416,ST LEU,LA CHALOUPE,-21.1665966424,55.3331335776,13,,Saint-Leu,Saint-Leu,974,La Réunion,4,La Réunion
97413,ST LEU,97424,ST LEU,LE PITON ST LEU,-21.1665966424,55.3331335776,13,,Saint-Leu,Saint-Leu,974,La Réunion,4,La Réunion
97413,ST LEU,97424,ST LEU,LE PLATE,-21.1665966424,55.3331335776,13,,Saint-Leu,Saint-Leu,974,La Réunion,4,La Réunion
97413,ST LEU,97436,ST LEU,,-21.1665966424,55.3331335776,13,,Saint-Leu,Saint-Leu,974,La Réunion,4,La Réunion
97414,ST LOUIS,97421,ST LOUIS,LA RIVIERE,-21.2339268494,55.4213388686,14,,Saint-Louis,Saint-Louis,974,La Réunion,4,La Réunion
97414,ST LOUIS,97421,ST LOUIS,LES MAKES,-21.2339268494,55.4213388686,14,,Saint-Louis,Saint-Louis,974,La Réunion,4,La Réunion
97414,ST LOUIS,97450,ST LOUIS,,-21.2339268494,55.4213388686,14,,Saint-Louis,Saint-Louis,974,La Réunion,4,La Réunion
97415,ST PAUL,97411,ST PAUL,BOIS DE NEFLES ST PAUL,-21.0445317522,55.3223334298,15,,Saint-Paul,Saint-Paul,974,La Réunion,4,La Réunion
97415,ST PAUL,97422,ST PAUL,LA SALINE,-21.0445317522,55.3223334298,15,,Saint-Paul,Saint-Paul,974,La Réunion,4,La Réunion
97415,ST PAUL,97423,ST PAUL,LE GUILLAUME,-21.0445317522,55.3223334298,15,,Saint-Paul,Saint-Paul,974,La Réunion,4,La Réunion
97415,ST PAUL,97434,ST PAUL,LA SALINE LES BAINS,-21.0445317522,55.3223334298,15,,Saint-Paul,Saint-Paul,974,La Réunion,4,La Réunion
97415,ST PAUL,97434,ST PAUL,ST GILLES LES BAINS,-21.0445317522,55.3223334298,15,,Saint-Paul,Saint-Paul,974,La Réunion,4,La Réunion
97415,ST PAUL,97435,ST PAUL,BERNICA,-21.0445317522,55.3223334298,15,,Saint-Paul,Saint-Paul,974,La Réunion,4,La Réunion
97415,ST PAUL,97435,ST PAUL,ST GILLES LES HAUTS,-21.0445317522,55.3223334298,15,,Saint-Paul,Saint-Paul,974,La Réunion,4,La Réunion
97415,ST PAUL,97435,ST PAUL,TAN ROUGE,-21.0445317522,55.3223334298,15,,Saint-Paul,Saint-Paul,974,La Réunion,4,La Réunion
97415,ST PAUL,97460,ST PAUL,,-21.0445317522,55.3223334298,15,,Saint-Paul,Saint-Paul,974,La Réunion,4,La Réunion
97415,ST PAUL,97460,ST PAUL,BELLEMENE,-21.0445317522,55.3223334298,15,,Saint-Paul,Saint-Paul,974,La Réunion,4,La Réunion
97415,ST PAUL,97460,ST PAUL,PLATEAU CAILLOUX,-21.0445317522,55.3223334298,15,,Saint-Paul,Saint-Paul,974,La Réunion,4,La Réunion
97416,ST PIERRE,97410,ST PIERRE,,-21.3123242427,55.4936155164,16,,Saint-Pierre,Saint-Pierre,974,La Réunion,4,La Réunion
97416,ST PIERRE,97410,ST PIERRE,BASSE TERRE,-21.3123242427,55.4936155164,16,,Saint-Pierre,Saint-Pierre,974,La Réunion,4,La Réunion
97416,ST PIERRE,97410,ST PIERRE,GRAND BOIS,-21.3123242427,55.4936155164,16,,Saint-Pierre,Saint-Pierre,974,La Réunion,4,La Réunion
97416,ST PIERRE,97410,ST PIERRE,MONT VERT,-21.3123242427,55.4936155164,16,,Saint-Pierre,Saint-Pierre,974,La Réunion,4,La Réunion
97416,ST PIERRE,97410,ST PIERRE,RAVINE BLANCHE,-21.3123242427,55.4936155164,16,,Saint-Pierre,Saint-Pierre,974,La Réunion,4,La Réunion
97416,ST PIERRE,97410,ST PIERRE,TERRE SAINTE,-21.3123242427,55.4936155164,16,,Saint-Pierre,Saint-Pierre,974,La Réunion,4,La Réunion
97416,ST PIERRE,97432,ST PIERRE,RAVINE DES CABRIS,-21.3123242427,55.4936155164,16,,Saint-Pierre,Saint-Pierre,974,La Réunion,4,La Réunion
97417,ST PHILIPPE,97442,ST PHILIPPE,,-21.3040005374,55.7454668204,17,,Saint-Philippe,Saint-Philippe,974,La Réunion,4,La Réunion
97417,ST PHILIPPE,97442,ST PHILIPPE,BASSE VALLEE,-21.3040005374,55.7454668204,17,,Saint-Philippe,Saint-Philippe,974,La Réunion,4,La Réunion
97418,STE MARIE,97438,STE MARIE,,-20.9470588515,55.5303555863,18,,Sainte-Marie,Sainte-Marie,974,La Réunion,4,La Réunion
97418,STE MARIE,97438,STE MARIE,LA GRANDE MONTEE,-20.9470588515,55.5303555863,18,,Sainte-Marie,Sainte-Marie,974,La Réunion,4,La Réunion
97418,STE MARIE,97438,STE MARIE,RIVIERE DES PLUIES,-20.9470588515,55.5303555863,18,,Sainte-Marie,Sainte-Marie,974,La Réunion,4,La Réunion
97418,STE MARIE,97438,STE MARIE,ROLAND GARROS AEROPORT,-20.9470588515,55.5303555863,18,,Sainte-Marie,Sainte-Marie,974,La Réunion,4,La Réunion
97419,STE ROSE,97439,STE ROSE,,-21.1925353922,55.7545224725,19,,Sainte-Rose,Sainte-Rose,974,La Réunion,4,La Réunion
97419,STE ROSE,97439,STE ROSE,LE PITON STE ROSE,-21.1925353922,55.7545224725,19,,Sainte-Rose,Sainte-Rose,974,La Réunion,4,La Réunion
97420,STE SUZANNE,97441,STE SUZANNE,,-20.9457340523,55.5927790824,20,,Sainte-Suzanne,Sainte-Suzanne,974,La Réunion,4,La Réunion
97421,SALAZIE,97433,SALAZIE,,-21.0469188863,55.5082381926,21,,Salazie,Salazie,974,La Réunion,4,La Réunion
97421,SALAZIE,97433,SALAZIE,HELL BOURG,-21.0469188863,55.5082381926,21,,Salazie,Salazie,974,La Réunion,4,La Réunion
97422,LE TAMPON,97418,LE TAMPON,LA PLAINE DES CAFRES,-21.223274585,55.5584875266,22,Le,Tampon,Le Tampon,974,La Réunion,4,La Réunion
97422,LE TAMPON,97418,LE TAMPON,TAMPON 17EME KM,-21.223274585,55.5584875266,22,Le,Tampon,Le Tampon,974,La Réunion,4,La Réunion
97422,LE TAMPON,97430,LE TAMPON,,-21.223274585,55.5584875266,22,Le,Tampon,Le Tampon,974,La Réunion,4,La Réunion
97422,LE TAMPON,97430,LE TAMPON,LES TROIS MARES,-21.223274585,55.5584875266,22,Le,Tampon,Le Tampon,974,La Réunion,4,La Réunion
97422,LE TAMPON,97430,LE TAMPON,PONT D YVES,-21.223274585,55.5584875266,22,Le,Tampon,Le Tampon,974,La Réunion,4,La Réunion
97422,LE TAMPON,97430,LE TAMPON,TAMPON 14EME KM,-21.223274585,55.5584875266,22,Le,Tampon,Le Tampon,974,La Réunion,4,La Réunion
97423,LES TROIS BASSINS,97426,LES TROIS BASSINS,,-21.1104753596,55.3297522865,23,Les,Trois-Bassins,Les Trois-Bassins,974,La Réunion,4,La Réunion
97424,CILAOS,97413,CILAOS,,-21.1441659144,55.4586035944,24,,Cilaos,Cilaos,974,La Réunion,4,La Réunion
97424,CILAOS,97413,CILAOS,PALMISTE ROUGE,-21.1441659144,55.4586035944,24,,Cilaos,Cilaos,974,La Réunion,4,La Réunion
97501,MIQUELON LANGLADE,97500,ST PIERRE ET MIQUELON,LANGLADE,,,501,,Miquelon langlade,Miquelon langlade,97,,,
97501,MIQUELON LANGLADE,97500,ST PIERRE ET MIQUELON,MIQUELON LANGLADE,,,501,,Miquelon langlade,Miquelon langlade,97,,,
97502,ST PIERRE,97500,ST PIERRE ET MIQUELON,ST PIERRE,,,502,,St pierre,St pierre,97,,,
97601,ACOUA,97630,ACOUA,,-12.7238913738,45.0687067258,1,,Acoua,Acoua,976,Mayotte,6,Mayotte
97602,BANDRABOUA,97650,BANDRABOUA,,-12.7232292527,45.1181212208,2,,Bandraboua,Bandraboua,976,Mayotte,6,Mayotte
97603,BANDRELE,97660,BANDRELE,,-12.9305468714,45.1775042961,3,,Bandrele,Bandrele,976,Mayotte,6,Mayotte
97604,BOUENI,97620,BOUENI,,-12.9244813606,45.0984953615,4,,Bouéni,Bouéni,976,Mayotte,6,Mayotte
97605,CHICONI,97670,CHICONI,,-12.821522233,45.1132970298,5,,Chiconi,Chiconi,976,Mayotte,6,Mayotte
97606,CHIRONGUI,97620,CHIRONGUI,,-12.9197356613,45.1484046861,6,,Chirongui,Chirongui,976,Mayotte,6,Mayotte
97607,DEMBENI,97660,DEMBENI,,-12.8534223602,45.1755961092,7,,Dembeni,Dembeni,976,Mayotte,6,Mayotte
97608,DZAOUDZI,97615,DZAOUDZI,,-12.7791666355,45.2822648358,8,,Dzaoudzi,Dzaoudzi,976,Mayotte,6,Mayotte
97608,DZAOUDZI,97615,DZAOUDZI,LABATTOIR,-12.7791666355,45.2822648358,8,,Dzaoudzi,Dzaoudzi,976,Mayotte,6,Mayotte
97609,KANI KELI,97625,KANI KELI,,-12.9717285854,45.1251508512,9,,Kani-Kéli,Kani-Kéli,976,Mayotte,6,Mayotte
97610,KOUNGOU,97600,KOUNGOU,,-12.7465604467,45.1869991913,10,,Koungou,Koungou,976,Mayotte,6,Mayotte
97611,MAMOUDZOU,97600,MAMOUDZOU,,-12.7899979586,45.1932456026,11,,Mamoudzou,Mamoudzou,976,Mayotte,6,Mayotte
97611,MAMOUDZOU,97600,MAMOUDZOU,KAWENI,-12.7899979586,45.1932456026,11,,Mamoudzou,Mamoudzou,976,Mayotte,6,Mayotte
97611,MAMOUDZOU,97605,MAMOUDZOU,PASSAMAINTI,-12.7899979586,45.1932456026,11,,Mamoudzou,Mamoudzou,976,Mayotte,6,Mayotte
97611,MAMOUDZOU,97650,MAMOUDZOU,DZOUMOGNE,-12.7899979586,45.1932456026,11,,Mamoudzou,Mamoudzou,976,Mayotte,6,Mayotte
97611,MAMOUDZOU,97680,MAMOUDZOU,COMBANI,-12.7899979586,45.1932456026,11,,Mamoudzou,Mamoudzou,976,Mayotte,6,Mayotte
97612,MTSAMBORO,97630,MTSAMBORO,,-12.6875073612,45.0736802335,12,,Mtsamboro,Mtsamboro,976,Mayotte,6,Mayotte
97613,M TSANGAMOUJI,97650,M TSANGAMOUJI,,-12.7513099309,45.0871696871,13,,M'Tsangamouji,M'Tsangamouji,976,Mayotte,6,Mayotte
97614,OUANGANI,97670,OUANGANI,,-12.8370955196,45.1379095497,14,,Ouangani,Ouangani,976,Mayotte,6,Mayotte
97614,OUANGANI,97670,OUANGANI,COCONI,-12.8370955196,45.1379095497,14,,Ouangani,Ouangani,976,Mayotte,6,Mayotte
97615,PAMANDZI,97615,PAMANDZI,,-12.7961353309,45.2842063102,15,,Pamandzi,Pamandzi,976,Mayotte,6,Mayotte
97616,SADA,97640,SADA,,-12.8611649609,45.1185503145,16,,Sada,Sada,976,Mayotte,6,Mayotte
97617,TSINGONI,97680,TSINGONI,,-12.7821666736,45.1344279083,17,,Tsingoni,Tsingoni,976,Mayotte,6,Mayotte
97701,ST BARTHELEMY,97133,ST BARTHELEMY,,,,701,,St barthelemy,St barthelemy,97,,,
97801,ST MARTIN,97150,ST MARTIN,,,,801,,St martin,St martin,97,,,
98611,ALO,98610,ALO,,,,611,,Alo,Alo,98,,,
98612,SIGAVE,98620,SIGAVE,,,,612,,Sigave,Sigave,98,,,
98613,UVEA,98600,UVEA,,,,613,,Uvea,Uvea,98,,,
98711,ANAA,98760,TUUHORA,ANAA,,,711,,Anaa,Anaa,98,,,
98711,ANAA,98786,HITIANAU,ANAA,,,711,,Anaa,Anaa,98,,,
98711,ANAA,98790,TEPUPAHEA,ANAA,,,711,,Anaa,Anaa,98,,,
98712,ARUE,98701,ARUE,,,,712,,Arue,Arue,98,,,
98713,ARUTUA,98761,RAUTINI,ARUTUA,,,713,,Arutua,Arutua,98,,,
98713,ARUTUA,98762,NIUTAHI,ARUTUA,,,713,,Arutua,Arutua,98,,,
98713,ARUTUA,98785,RAITAHITI,ARUTUA,,,713,,Arutua,Arutua,98,,,
98714,BORA BORA,98730,ANAU,BORA BORA,,,714,,Bora bora,Bora bora,98,,,
98714,BORA BORA,98730,FAANUI,BORA BORA,,,714,,Bora bora,Bora bora,98,,,
98714,BORA BORA,98730,NUNUE,BORA BORA,,,714,,Bora bora,Bora bora,98,,,
98714,BORA BORA,98730,VAITAPE,BORA BORA,,,714,,Bora bora,Bora bora,98,,,
98715,FAAA,98704,FAAA,,,,715,,Faaa,Faaa,98,,,
98716,FAKARAVA,98763,ROTOAVA,FAKARAVA,,,716,,Fakarava,Fakarava,98,,,
98716,FAKARAVA,98764,PAPARARA,FAKARAVA,,,716,,Fakarava,Fakarava,98,,,
98716,FAKARAVA,98787,TEARAVERO,FAKARAVA,,,716,,Fakarava,Fakarava,98,,,
98716,FAKARAVA,98790,MOTU TAPU,FAKARAVA,,,716,,Fakarava,Fakarava,98,,,
98716,FAKARAVA,98790,OFARE,FAKARAVA,,,716,,Fakarava,Fakarava,98,,,
98716,FAKARAVA,98790,TOAU,FAKARAVA,,,716,,Fakarava,Fakarava,98,,,
98717,FANGATAU,98765,TEANA,FANGATAU,,,717,,Fangatau,Fangatau,98,,,
98717,FANGATAU,98766,TARIONE,FANGATAU,,,717,,Fangatau,Fangatau,98,,,
98718,FATU HIVA,98740,HANAVAVE,FATU HIVA,,,718,,Fatu hiva,Fatu hiva,98,,,
98718,FATU HIVA,98740,OMOA,FATU HIVA,,,718,,Fatu hiva,Fatu hiva,98,,,
98719,GAMBIER,98755,AKAMARU,GAMBIER,,,719,,Gambier,Gambier,98,,,
98719,GAMBIER,98755,ANGAKAUITAI,GAMBIER,,,719,,Gambier,Gambier,98,,,
98719,GAMBIER,98755,AUKENA,GAMBIER,,,719,,Gambier,Gambier,98,,,
98719,GAMBIER,98755,KAMAKA,GAMBIER,,,719,,Gambier,Gambier,98,,,
98719,GAMBIER,98755,MAKAROA,GAMBIER,,,719,,Gambier,Gambier,98,,,
98719,GAMBIER,98755,MANGAREVA,GAMBIER,,,719,,Gambier,Gambier,98,,,
98719,GAMBIER,98755,MANIUI,GAMBIER,,,719,,Gambier,Gambier,98,,,
98719,GAMBIER,98755,RIKITEA,GAMBIER,,,719,,Gambier,Gambier,98,,,
98719,GAMBIER,98755,TARAVAI,GAMBIER,,,719,,Gambier,Gambier,98,,,
98719,GAMBIER,98792,MARIA EST,GAMBIER,,,719,,Gambier,Gambier,98,,,
98719,GAMBIER,98792,MORANE,GAMBIER,,,719,,Gambier,Gambier,98,,,
98719,GAMBIER,98792,MOTUREIVAVAO,GAMBIER,,,719,,Gambier,Gambier,98,,,
98719,GAMBIER,98792,TEMOE,GAMBIER,,,719,,Gambier,Gambier,98,,,
98719,GAMBIER,98792,TENARARO,GAMBIER,,,719,,Gambier,Gambier,98,,,
98719,GAMBIER,98792,TENARUNGA,GAMBIER,,,719,,Gambier,Gambier,98,,,
98719,GAMBIER,98792,VAHANGA,GAMBIER,,,719,,Gambier,Gambier,98,,,
98719,GAMBIER,98793,MARUTEA SUD,GAMBIER,,,719,,Gambier,Gambier,98,,,
98720,HAO,98767,OTEPA,HAO,,,720,,Hao,Hao,98,,,
98720,HAO,98790,AHUNUI,HAO,,,720,,Hao,Hao,98,,,
98720,HAO,98790,AMANU,HAO,,,720,,Hao,Hao,98,,,
98720,HAO,98790,HEREHRETUE,HAO,,,720,,Hao,Hao,98,,,
98720,HAO,98790,HIKITAKE,HAO,,,720,,Hao,Hao,98,,,
98720,HAO,98790,MANUHANGI,HAO,,,720,,Hao,Hao,98,,,
98720,HAO,98790,NENGONENGO,HAO,,,720,,Hao,Hao,98,,,
98720,HAO,98790,OTETOU,HAO,,,720,,Hao,Hao,98,,,
98720,HAO,98790,PARAOA,HAO,,,720,,Hao,Hao,98,,,
98720,HAO,98790,TEANOGA,HAO,,,720,,Hao,Hao,98,,,
98721,HIKUERU,98768,TUPAPATI,HIKUERU,,,721,,Hikueru,Hikueru,98,,,
98721,HIKUERU,98790,MAHETIKA,HIKUERU,,,721,,Hikueru,Hikueru,98,,,
98721,HIKUERU,98790,MOTUTAPU,HIKUERU,,,721,,Hikueru,Hikueru,98,,,
98721,HIKUERU,98790,RAVAHERE,HIKUERU,,,721,,Hikueru,Hikueru,98,,,
98721,HIKUERU,98790,TEPEPERU,HIKUERU,,,721,,Hikueru,Hikueru,98,,,
98722,HITIAA O TE RA,98705,HITIAA,HITIAA O TE RA,,,722,,Hitiaa o te ra,Hitiaa o te ra,98,,,
98722,HITIAA O TE RA,98706,MAHAENA,HITIAA O TE RA,,,722,,Hitiaa o te ra,Hitiaa o te ra,98,,,
98722,HITIAA O TE RA,98707,PAPENOO,HITIAA O TE RA,,,722,,Hitiaa o te ra,Hitiaa o te ra,98,,,
98722,HITIAA O TE RA,98708,TIAREI,HITIAA O TE RA,,,722,,Hitiaa o te ra,Hitiaa o te ra,98,,,
98723,HIVA OA,98741,ATUONA,HIVA OA,,,723,,Hiva oa,Hiva oa,98,,,
98723,HIVA OA,98741,EIAONE,HIVA OA,,,723,,Hiva oa,Hiva oa,98,,,
98723,HIVA OA,98741,HANAIAPA,HIVA OA,,,723,,Hiva oa,Hiva oa,98,,,
98723,HIVA OA,98741,HANAPAOA,HIVA OA,,,723,,Hiva oa,Hiva oa,98,,,
98723,HIVA OA,98741,MOTU UA,HIVA OA,,,723,,Hiva oa,Hiva oa,98,,,
98723,HIVA OA,98741,NAHOE,HIVA OA,,,723,,Hiva oa,Hiva oa,98,,,
98723,HIVA OA,98741,PAUMAU,HIVA OA,,,723,,Hiva oa,Hiva oa,98,,,
98723,HIVA OA,98749,PUAMAU,HIVA OA,,,723,,Hiva oa,Hiva oa,98,,,
98723,HIVA OA,98796,FATU HUKU,HIVA OA,,,723,,Hiva oa,Hiva oa,98,,,
98723,HIVA OA,98796,MOHOTANI,HIVA OA,,,723,,Hiva oa,Hiva oa,98,,,
98724,HUAHINE,98731,FAIE,HUAHINE,,,724,,Huahine,Huahine,98,,,
98724,HUAHINE,98731,FARE,HUAHINE,,,724,,Huahine,Huahine,98,,,
98724,HUAHINE,98731,FITII,HUAHINE,,,724,,Huahine,Huahine,98,,,
98724,HUAHINE,98731,HAAPU,HUAHINE,,,724,,Huahine,Huahine,98,,,
98724,HUAHINE,98731,HUAHINE,,,,724,,Huahine,Huahine,98,,,
98724,HUAHINE,98731,MAEVA,HUAHINE,,,724,,Huahine,Huahine,98,,,
98724,HUAHINE,98731,MAROE,HUAHINE,,,724,,Huahine,Huahine,98,,,
98724,HUAHINE,98731,PAREA,HUAHINE,,,724,,Huahine,Huahine,98,,,
98724,HUAHINE,98731,TEFARERII,HUAHINE,,,724,,Huahine,Huahine,98,,,
98724,HUAHINE,98732,AVERA,HUAHINE,,,724,,Huahine,Huahine,98,,,
98725,MAHINA,98709,MAHINA,,,,725,,Mahina,Mahina,98,,,
98725,MAHINA,98710,OROFARA,MAHINA,,,725,,Mahina,Mahina,98,,,
98726,MAKEMO,98769,POUHEVA,MAKEMO,,,726,,Makemo,Makemo,98,,,
98726,MAKEMO,98789,HITIANAU,MAKEMO,,,726,,Makemo,Makemo,98,,,
98726,MAKEMO,98790,GARUMAOA,MAKEMO,,,726,,Makemo,Makemo,98,,,
98726,MAKEMO,98790,HARAIKI,MAKEMO,,,726,,Makemo,Makemo,98,,,
98726,MAKEMO,98790,HENUAPAREA,MAKEMO,,,726,,Makemo,Makemo,98,,,
98726,MAKEMO,98790,HITI,MAKEMO,,,726,,Makemo,Makemo,98,,,
98726,MAKEMO,98790,MARUTEA NORD,MAKEMO,,,726,,Makemo,Makemo,98,,,
98726,MAKEMO,98790,OTATAKE,MAKEMO,,,726,,Makemo,Makemo,98,,,
98726,MAKEMO,98790,TEPOTO SUD,MAKEMO,,,726,,Makemo,Makemo,98,,,
98726,MAKEMO,98790,TUANAKE,MAKEMO,,,726,,Makemo,Makemo,98,,,
98727,MANIHI,98770,TENOKUPARA,MANIHI,,,727,,Manihi,Manihi,98,,,
98727,MANIHI,98771,TURIPAOA,MANIHI,,,727,,Manihi,Manihi,98,,,
98728,MAUPITI,98732,MAUPITI,,,,728,,Maupiti,Maupiti,98,,,
98728,MAUPITI,98732,MOPELIA,MAUPITI,,,728,,Maupiti,Maupiti,98,,,
98728,MAUPITI,98732,MOTU ONE,MAUPITI,,,728,,Maupiti,Maupiti,98,,,
98728,MAUPITI,98732,SCILLY,MAUPITI,,,728,,Maupiti,Maupiti,98,,,
98729,MOOREA MAIAO,98728,AFAREAITU,MOOREA MAIAO,,,729,,Moorea maiao,Moorea maiao,98,,,
98729,MOOREA MAIAO,98728,HAUMI,MOOREA MAIAO,,,729,,Moorea maiao,Moorea maiao,98,,,
98729,MOOREA MAIAO,98728,MAATEA,MOOREA MAIAO,,,729,,Moorea maiao,Moorea maiao,98,,,
98729,MOOREA MAIAO,98728,MAHAREPA,MOOREA MAIAO,,,729,,Moorea maiao,Moorea maiao,98,,,
98729,MOOREA MAIAO,98728,PAOPAO,MOOREA MAIAO,,,729,,Moorea maiao,Moorea maiao,98,,,
98729,MOOREA MAIAO,98728,TEAVARO,MOOREA MAIAO,,,729,,Moorea maiao,Moorea maiao,98,,,
98729,MOOREA MAIAO,98728,TEMAE,MOOREA MAIAO,,,729,,Moorea maiao,Moorea maiao,98,,,
98729,MOOREA MAIAO,98728,TIAIA,MOOREA MAIAO,,,729,,Moorea maiao,Moorea maiao,98,,,
98729,MOOREA MAIAO,98728,VAIARE,MOOREA MAIAO,,,729,,Moorea maiao,Moorea maiao,98,,,
98729,MOOREA MAIAO,98729,ATIHA,MOOREA MAIAO,,,729,,Moorea maiao,Moorea maiao,98,,,
98729,MOOREA MAIAO,98729,HAAPITI,MOOREA MAIAO,,,729,,Moorea maiao,Moorea maiao,98,,,
98729,MOOREA MAIAO,98729,HAURU,MOOREA MAIAO,,,729,,Moorea maiao,Moorea maiao,98,,,
98729,MOOREA MAIAO,98729,MAIAO,MOOREA MAIAO,,,729,,Moorea maiao,Moorea maiao,98,,,
98729,MOOREA MAIAO,98729,OPUNOHU,MOOREA MAIAO,,,729,,Moorea maiao,Moorea maiao,98,,,
98729,MOOREA MAIAO,98729,PAOPAO,MOOREA MAIAO,,,729,,Moorea maiao,Moorea maiao,98,,,
98729,MOOREA MAIAO,98729,PAPETOAI,MOOREA MAIAO,,,729,,Moorea maiao,Moorea maiao,98,,,
98729,MOOREA MAIAO,98729,PIHAENA,MOOREA MAIAO,,,729,,Moorea maiao,Moorea maiao,98,,,
98729,MOOREA MAIAO,98729,TIAHURA,MOOREA MAIAO,,,729,,Moorea maiao,Moorea maiao,98,,,
98729,MOOREA MAIAO,98729,VAIANAE,MOOREA MAIAO,,,729,,Moorea maiao,Moorea maiao,98,,,
98729,MOOREA MAIAO,98729,VARARI,MOOREA MAIAO,,,729,,Moorea maiao,Moorea maiao,98,,,
98730,NAPUKA,98772,TEPUKAMARUIA,NAPUKA,,,730,,Napuka,Napuka,98,,,
98731,NUKU HIVA,98742,AAKAPA,NUKU HIVA,,,731,,Nuku hiva,Nuku hiva,98,,,
98731,NUKU HIVA,98742,NUKUATAHA,NUKU HIVA,,,731,,Nuku hiva,Nuku hiva,98,,,
98731,NUKU HIVA,98742,TAIOHAE,NUKU HIVA,,,731,,Nuku hiva,Nuku hiva,98,,,
98731,NUKU HIVA,98742,TAIPIVAI,NUKU HIVA,,,731,,Nuku hiva,Nuku hiva,98,,,
98731,NUKU HIVA,98742,TERRE DESERTE,NUKU HIVA,,,731,,Nuku hiva,Nuku hiva,98,,,
98731,NUKU HIVA,98748,HATIHEU,NUKU HIVA,,,731,,Nuku hiva,Nuku hiva,98,,,
98731,NUKU HIVA,98796,EIAO,NUKU HIVA,,,731,,Nuku hiva,Nuku hiva,98,,,
98731,NUKU HIVA,98796,HATU ITI,NUKU HIVA,,,731,,Nuku hiva,Nuku hiva,98,,,
98731,NUKU HIVA,98796,HATUTAA,NUKU HIVA,,,731,,Nuku hiva,Nuku hiva,98,,,
98732,NUKUTAVAKE,98773,TAVAVA,NUKUTAVAKE,,,732,,Nukutavake,Nukutavake,98,,,
98732,NUKUTAVAKE,98788,TEMANUFAARA,NUKUTAVAKE,,,732,,Nukutavake,Nukutavake,98,,,
98733,PAEA,98711,PAEA,,,,733,,Paea,Paea,98,,,
98734,PAPARA,98712,PAPARA,,,,734,,Papara,Papara,98,,,
98735,PAPEETE,98714,PAPEETE,,,,735,,Papeete,Papeete,98,,,
98736,PIRAE,98716,PIRAE,,,,736,,Pirae,Pirae,98,,,
98737,PUKAPUKA,98774,TEONEMAHINA,PUKAPUKA,,,737,,Pukapuka,Pukapuka,98,,,
98738,PUNAAUIA,98703,PUNAAUIA,,,,738,,Punaauia,Punaauia,98,,,
98738,PUNAAUIA,98718,PUNAAUIA,,,,738,,Punaauia,Punaauia,98,,,
98739,RAIVAVAE,98750,ANATONU,RAIVAVAE,,,739,,Raivavae,Raivavae,98,,,
98739,RAIVAVAE,98750,MAHANATOA,RAIVAVAE,,,739,,Raivavae,Raivavae,98,,,
98739,RAIVAVAE,98750,RAIRUA,RAIVAVAE,,,739,,Raivavae,Raivavae,98,,,
98739,RAIVAVAE,98750,VAIURU,RAIVAVAE,,,739,,Raivavae,Raivavae,98,,,
98740,RANGIROA,98775,AVATORU,RANGIROA,,,740,,Rangiroa,Rangiroa,98,,,
98740,RANGIROA,98776,TIPUTA,RANGIROA,,,740,,Rangiroa,Rangiroa,98,,,
98740,RANGIROA,98777,PAHUA,RANGIROA,,,740,,Rangiroa,Rangiroa,98,,,
98740,RANGIROA,98778,TUHERAHERA,RANGIROA,,,740,,Rangiroa,Rangiroa,98,,,
98740,RANGIROA,98790,VAITEPAUA,RANGIROA,,,740,,Rangiroa,Rangiroa,98,,,
98741,RAPA,98751,AHUREI,RAPA,,,741,,Rapa,Rapa,98,,,
98741,RAPA,98794,MAROTIRI,RAPA,,,741,,Rapa,Rapa,98,,,
98742,REAO,98779,TAPUARAVA,REAO,,,742,,Reao,Reao,98,,,
98742,REAO,98780,MARAUTAGAROA,REAO,,,742,,Reao,Reao,98,,,
98743,RIMATARA,98752,AMARU,RIMATARA,,,743,,Rimatara,Rimatara,98,,,
98743,RIMATARA,98752,ANAPOTO,RIMATARA,,,743,,Rimatara,Rimatara,98,,,
98743,RIMATARA,98752,MUTUAURA,RIMATARA,,,743,,Rimatara,Rimatara,98,,,
98743,RIMATARA,98752,RIMATARA,,,,743,,Rimatara,Rimatara,98,,,
98743,RIMATARA,98795,MARIA ILOTS,RIMATARA,,,743,,Rimatara,Rimatara,98,,,
98744,RURUTU,98753,AVERA,RURUTU,,,744,,Rurutu,Rurutu,98,,,
98744,RURUTU,98753,HAUTI,RURUTU,,,744,,Rurutu,Rurutu,98,,,
98744,RURUTU,98753,MOERAI,RURUTU,,,744,,Rurutu,Rurutu,98,,,
98745,TAHAA,98733,HIPU,TAHAA,,,745,,Tahaa,Tahaa,98,,,
98745,TAHAA,98733,IRIPAU,TAHAA,,,745,,Tahaa,Tahaa,98,,,
98745,TAHAA,98733,PATIO,TAHAA,,,745,,Tahaa,Tahaa,98,,,
98745,TAHAA,98733,RUUTIA,TAHAA,,,745,,Tahaa,Tahaa,98,,,
98745,TAHAA,98733,TAHAA,,,,745,,Tahaa,Tahaa,98,,,
98745,TAHAA,98733,TAPUAMU,TAHAA,,,745,,Tahaa,Tahaa,98,,,
98745,TAHAA,98733,TIVA,TAHAA,,,745,,Tahaa,Tahaa,98,,,
98745,TAHAA,98734,FAAAHA,TAHAA,,,745,,Tahaa,Tahaa,98,,,
98745,TAHAA,98734,HAAMENE,TAHAA,,,745,,Tahaa,Tahaa,98,,,
98745,TAHAA,98734,NIUA,TAHAA,,,745,,Tahaa,Tahaa,98,,,
98745,TAHAA,98734,POUTORU,TAHAA,,,745,,Tahaa,Tahaa,98,,,
98745,TAHAA,98734,VAITOARE,TAHAA,,,745,,Tahaa,Tahaa,98,,,
98746,TAHUATA,98743,HANATETENA,TAHUATA,,,746,,Tahuata,Tahuata,98,,,
98746,TAHUATA,98743,HAPATONI,TAHUATA,,,746,,Tahuata,Tahuata,98,,,
98746,TAHUATA,98743,MOTOPU,TAHUATA,,,746,,Tahuata,Tahuata,98,,,
98746,TAHUATA,98743,VAITAHU,TAHUATA,,,746,,Tahuata,Tahuata,98,,,
98747,TAIARAPU EST,98719,AFAAHITI,TAIARAPU EST,,,747,,Taiarapu est,Taiarapu est,98,,,
98747,TAIARAPU EST,98719,TARAVAO,TAIARAPU EST,,,747,,Taiarapu est,Taiarapu est,98,,,
98747,TAIARAPU EST,98720,FAAONE,TAIARAPU EST,,,747,,Taiarapu est,Taiarapu est,98,,,
98747,TAIARAPU EST,98721,PUEU,TAIARAPU EST,,,747,,Taiarapu est,Taiarapu est,98,,,
98747,TAIARAPU EST,98722,TAUTIRA,TAIARAPU EST,,,747,,Taiarapu est,Taiarapu est,98,,,
98748,TAIARAPU OUEST,98723,TEAHUPOO,TAIARAPU OUEST,,,748,,Taiarapu ouest,Taiarapu ouest,98,,,
98748,TAIARAPU OUEST,98724,TOAHOTU,TAIARAPU OUEST,,,748,,Taiarapu ouest,Taiarapu ouest,98,,,
98748,TAIARAPU OUEST,98725,VAIRAO,TAIARAPU OUEST,,,748,,Taiarapu ouest,Taiarapu ouest,98,,,
98749,TAKAROA,98781,TEAVAROA,TAKAROA,,,749,,Takaroa,Takaroa,98,,,
98749,TAKAROA,98782,FAKATOPATERE,TAKAROA,,,749,,Takaroa,Takaroa,98,,,
98749,TAKAROA,98790,TIKEI,TAKAROA,,,749,,Takaroa,Takaroa,98,,,
98750,TAPUTAPUATEA,98735,AVERA,TAPUTAPUATEA,,,750,,Taputapuatea,Taputapuatea,98,,,
98750,TAPUTAPUATEA,98735,OPOA,TAPUTAPUATEA,,,750,,Taputapuatea,Taputapuatea,98,,,
98750,TAPUTAPUATEA,98735,PUOHINE,TAPUTAPUATEA,,,750,,Taputapuatea,Taputapuatea,98,,,
98750,TAPUTAPUATEA,98735,TAPUTAPUATEA,,,,750,,Taputapuatea,Taputapuatea,98,,,
98751,TATAKOTO,98783,TUMUKURU,TATAKOTO,,,751,,Tatakoto,Tatakoto,98,,,
98752,TEVA I UTA,98726,MATAIEA,TEVA I UTA,,,752,,Teva i uta,Teva i uta,98,,,
98752,TEVA I UTA,98727,PAPEARI,TEVA I UTA,,,752,,Teva i uta,Teva i uta,98,,,
98753,TUBUAI,98754,MAHU,TUBUAI,,,753,,Tubuai,Tubuai,98,,,
98753,TUBUAI,98754,MATAURA,TUBUAI,,,753,,Tubuai,Tubuai,98,,,
98753,TUBUAI,98754,TAAHUAIA,TUBUAI,,,753,,Tubuai,Tubuai,98,,,
98754,TUMARAA,98735,FETUNA,TUMARAA,,,754,,Tumaraa,Tumaraa,98,,,
98754,TUMARAA,98735,TEHURUI,TUMARAA,,,754,,Tumaraa,Tumaraa,98,,,
98754,TUMARAA,98735,TEVAITOA,TUMARAA,,,754,,Tumaraa,Tumaraa,98,,,
98754,TUMARAA,98735,TUMARAA,,,,754,,Tumaraa,Tumaraa,98,,,
98755,TUREIA,98784,FAKAMARU,TUREIA,,,755,,Tureia,Tureia,98,,,
98756,UA HUKA,98744,VAIPAEE,UA HUKA,,,756,,Ua huka,Ua huka,98,,,
98756,UA HUKA,98747,HANE,UA HUKA,,,756,,Ua huka,Ua huka,98,,,
98757,UA POU,98745,HAKAHAU,UA POU,,,757,,Ua pou,Ua pou,98,,,
98757,UA POU,98745,HAKAHETAU,UA POU,,,757,,Ua pou,Ua pou,98,,,
98757,UA POU,98745,HAKATAO,UA POU,,,757,,Ua pou,Ua pou,98,,,
98757,UA POU,98745,HOHOI,UA POU,,,757,,Ua pou,Ua pou,98,,,
98757,UA POU,98746,HAAKUTI,UA POU,,,757,,Ua pou,Ua pou,98,,,
98757,UA POU,98746,HAKAMAII,UA POU,,,757,,Ua pou,Ua pou,98,,,
98758,UTUROA,98735,UTUROA,,,,758,,Uturoa,Uturoa,98,,,
98801,BELEP,98811,BELEP,,,,801,,Belep,Belep,98,,,
98802,BOULOUPARI,98812,BOULOUPARIS,,,,802,,Bouloupari,Bouloupari,98,,,
98803,BOURAIL,98870,BOURAIL,,,,803,,Bourail,Bourail,98,,,
98804,CANALA,98813,CANALA,,,,804,,Canala,Canala,98,,,
98805,DUMBEA,98830,DUMBEA,,,,805,,Dumbea,Dumbea,98,,,
98805,DUMBEA,98835,DUMBEA,,,,805,,Dumbea,Dumbea,98,,,
98805,DUMBEA,98836,DUMBEA GA,DUMBEA,,,805,,Dumbea,Dumbea,98,,,
98805,DUMBEA,98837,DUMBEA,,,,805,,Dumbea,Dumbea,98,,,
98805,DUMBEA,98839,DUMBEA,,,,805,,Dumbea,Dumbea,98,,,
98806,FARINO,98881,FARINO,,,,806,,Farino,Farino,98,,,
98807,HIENGHENE,98815,HIENGHENE,,,,807,,Hienghene,Hienghene,98,,,
98808,HOUAILOU,98816,HOUAILOU,,,,808,,Houailou,Houailou,98,,,
98808,HOUAILOU,98838,PORO,HOUAILOU,,,808,,Houailou,Houailou,98,,,
98809,L ILE DES PINS,98832,VAO,L ILE DES PINS,,,,L',Ile des pins,L'Ile des pins,,,,
98810,KAALA GOMEN,98817,KAALA GOMEN,,,,810,,Kaala gomen,Kaala gomen,98,,,
98811,KONE,98859,KONE,,,,811,,Kone,Kone,98,,,
98811,KONE,98860,KONE,,,,811,,Kone,Kone,98,,,
98812,KOUMAC,98850,KOUMAC,,,,812,,Koumac,Koumac,98,,,
98813,LA FOA,98880,LA FOA,,,,,La,Foa,La Foa,,,,
98814,LIFOU,98820,WE,LIFOU,,,814,,Lifou,Lifou,98,,,
98814,LIFOU,98884,CHEPENEHE,LIFOU,,,814,,Lifou,Lifou,98,,,
98814,LIFOU,98885,MOU,LIFOU,,,814,,Lifou,Lifou,98,,,
98815,MARE,98828,TADINE,MARE,,,815,,Mare,Mare,98,,,
98815,MARE,98878,LA ROCHE,MARE,,,815,,Mare,Mare,98,,,
98816,MOINDOU,98819,MOINDOU,,,,816,,Moindou,Moindou,98,,,
98817,LE MONT DORE,98809,MONT DORE,,,,,Le,Mont dore,Le Mont dore,,,,
98817,LE MONT DORE,98810,MONT DORE,,,,,Le,Mont dore,Le Mont dore,,,,
98817,LE MONT DORE,98874,PONT DES FRANCAIS,LE MONT DORE,,,,Le,Mont dore,Le Mont dore,,,,
98817,LE MONT DORE,98875,PLUM,LE MONT DORE,,,,Le,Mont dore,Le Mont dore,,,,
98817,LE MONT DORE,98876,LA COULEE,LE MONT DORE,,,,Le,Mont dore,Le Mont dore,,,,
98818,NOUMEA,98800,NOUMEA,,,,818,,Noumea,Noumea,98,,,
98819,OUEGOA,98821,OUEGOA,,,,819,,Ouegoa,Ouegoa,98,,,
98820,OUVEA,98814,FAYAOUE,OUVEA,,,820,,Ouvea,Ouvea,98,,,
98821,PAITA,98840,TONTOUTA,PAITA,,,821,,Paita,Paita,98,,,
98821,PAITA,98889,PAITA,,,,821,,Paita,Paita,98,,,
98821,PAITA,98890,PAITA,,,,821,,Paita,Paita,98,,,
98822,POINDIMIE,98822,POINDIMIE,,,,822,,Poindimie,Poindimie,98,,,
98823,PONERIHOUEN,98823,PONERIHOUEN,,,,823,,Ponerihouen,Ponerihouen,98,,,
98824,POUEBO,98824,POUEBO,,,,824,,Pouebo,Pouebo,98,,,
98825,POUEMBOUT,98825,POUEMBOUT,,,,825,,Pouembout,Pouembout,98,,,
98826,POUM,98826,POUM,,,,826,,Poum,Poum,98,,,
98827,POYA,98827,POYA,,,,827,,Poya,Poya,98,,,
98827,POYA,98877,NEPOUI,POYA,,,827,,Poya,Poya,98,,,
98828,SARRAMEA,98882,SARRAMEA,,,,828,,Sarramea,Sarramea,98,,,
98829,THIO,98829,THIO,,,,829,,Thio,Thio,98,,,
98830,TOUHO,98831,TOUHO,,,,830,,Touho,Touho,98,,,
98831,VOH,98833,VOH,,,,831,,Voh,Voh,98,,,
98831,VOH,98883,OUACO,VOH,,,831,,Voh,Voh,98,,,
98832,YATE,98834,YATE,,,,832,,Yate,Yate,98,,,
98833,KOUAOUA,98818,KOUAOUA,,,,833,,Kouaoua,Kouaoua,98,,,
98901,ILE DE CLIPPERTON,98799,ILE DE CLIPPERTON,,,,901,,Ile de clipperton,Ile de clipperton,98,,,
99138,MONACO,98000,MONACO,,,,138,,Monaco,Monaco,99,,,`;
export default geocode;
