import React, { useState } from 'react';
import Modal from '../components/Modal';
import Input from '../components/Input';
import Button from '../components/Button';
import { ErrorMessage } from '../components/Text';
import { useTranslation } from '../contexts/languageContext';
import { updatePassword } from '../api';

interface UpdatePasswordProps {
  onClose: () => void;
}

const UpdatePassword: React.FC<UpdatePasswordProps> = ({ onClose }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [feedback, setFeedback] = useState('')
  const { t } = useTranslation()

  const handleSubmit = React.useCallback(() => {
    if(newPassword!==confirmNewPassword) return setFeedback(t('passwordMismatch'))
    updatePassword({ oldPassword, newPassword }).catch(err => setFeedback(err.message))
  }, [confirmNewPassword, newPassword, oldPassword, t])

  return (
    <Modal onClose={onClose} name="updatePassword">
      <Input name="oldPassword" type="password" value={oldPassword} onChangeText={setOldPassword} />
      <Input name="newPassword" type="password" value={newPassword} onChangeText={setNewPassword} />
      <Input name="confirmPassword" type="password" value={confirmNewPassword} onChangeText={setConfirmNewPassword} />

      <Button name='updatePassword' onClick={handleSubmit} />
      {feedback && <ErrorMessage>{feedback}</ErrorMessage>}
    </Modal>
  );
};

export default UpdatePassword;
