// src/components/Input.tsx
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from '../contexts/languageContext';
import { colors } from '../theme';

interface InputProps {
  type?: 'text' | 'password' | 'email' | 'number' | 'date' | 'time';
  name: string;
  value?: string;
  className?: string
  onChangeText?: (value: string) => void;
  disabled?: boolean;
  required?: boolean;
}

const StyledInput = styled.input`
  flex: 1 1 auto;
  padding: 10px;
  border: 1px solid ${colors.lightGrey};
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
  color: ${colors.dark};

  &:focus {
    border-color: ${colors.main};
    outline: none;
  }

  &::placeholder {
    color: ${colors.mainDark};
    opacity: 0.7;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${colors.lightGrey};
  }
`;

function cleanTime(text: string) {
  let numbers = text.replace(':', '').match(/^(0?\d|1?\d|2?[0-4])([0-6]\d?)?$/)?.[0] || ''
  // Hours can start only with 0, 1 or 2. Pad with 0
  if (numbers.match(/^[3-9]/)) numbers = '0' + numbers
  const [dH = '', uH = '', dM = '', uM = ''] = numbers.split('')
  // Adds ":" as soon as there is more than 2 digits
  if (dM === '') return '' + dH + uH
  else return '' + dH + uH + ':' + dM + uM
}

const Input: React.FC<InputProps> = ({
  type = 'text',
  name,
  value,
  className,
  onChangeText,
  disabled = false,
  required = false,
}) => {
  const { t } = useTranslation();

  const handleChange = React.useMemo(() => {
    if(!onChangeText) return undefined
    else if(type === 'time') return (e: React.ChangeEvent<HTMLInputElement>) => onChangeText(cleanTime(e.target.value))
    else return (e: React.ChangeEvent<HTMLInputElement>) => onChangeText(e.target.value)
  }, [onChangeText, type])

  // const cleanedValue = React.useMemo(() => value && type === 'time' ? cleanTime(value) : value, [type, value])

  return (
    <StyledInput
      type={type}
      name={name}
      value={value}
      className={className}
      onChange={handleChange}
      placeholder={t(name)}
      disabled={disabled}
      required={required}
    />
  );
};

export default Input;
