import React, { ChangeEvent, useState } from 'react';
import { MessageList, Input, MessageType } from 'react-chat-elements';
import { getSessionMessages, getSubscribersForSession, postMessage, deleteMessage, Message, SportSession, Participant } from '../api';
import { ErrorMessage } from '../components/Text';
import { useProfile } from '../contexts/profileContext';
import { colors } from '../theme';
import { useTranslation } from '../contexts/languageContext';
import styled from 'styled-components';
import Button from '../components/Button';
import { useNavigation } from '../contexts/navigationContext';
import "react-chat-elements/dist/main.css"
import { cache } from '../contexts/cache';

const Container = styled.div`
  background-color: ${colors.dark};
  padding: 0.2em;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
`

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: auto;
`

const Messages = ({ session }: { session: SportSession }) => {
    const [messages, setMessages] = useState<Message[]>(cache.sessionMessages[session.uuid] || []);
    const [feedback, setFeedback] = useState('')
    const { profile } = useProfile()
    const [participants, setParticipants] = useState<Participant[]>([])
    const [inputText, setInputText] = React.useState('')
    const { t } = useTranslation()
    const { toSessionDetails } = useNavigation()
    const inputRef = React.useRef<HTMLTextAreaElement>(null)

    // Save old text
    const onChange = React.useCallback((e: ChangeEvent<HTMLTextAreaElement>) => setInputText(e.target.value), [])

    // Load old text on start up
    React.useEffect(() => {
      const oldText = localStorage.getItem('inputText') || ''
      if(inputRef.current) inputRef.current.value = oldText
    }, [])

    // Save text
    React.useEffect(() => {
      localStorage.setItem('inputText', inputText)
    }, [inputText])
        
    // Todo: use a more efficient system
    const loadMessages = React.useCallback(() =>
      getSessionMessages(session.uuid)
        .then(newMessages => setMessages(oldMessages => {
          // If both lists are identical, return the old list
          if(newMessages.every((message, index) => message.uuid === oldMessages[index]?.uuid) && newMessages.length === oldMessages.length) return oldMessages
          // We try to update only the changes to prevent rerenders
          const mergedMessages: Message[] = []
          // We need a copy to edit it safely
          const oldMessagesCopy = [...oldMessages]
          newMessages.forEach(message => {
            const index = oldMessagesCopy.findIndex(m => m.uuid === message.uuid)
            // We add the message if it's new
            if(index === -1) mergedMessages.push(message)
            // We add the old version of the message if it was already there
            else mergedMessages.push(...oldMessagesCopy.splice(index, 1))
          })
          return mergedMessages
        }))
        .catch(err => setFeedback(err.message))
    , [session.uuid])

    const loadUsers = React.useCallback(() => {
      getSubscribersForSession(session.uuid)
        .then(newParticipants => setParticipants(oldParticipants => {
          // If both lists are identical, return the old list
          if(newParticipants.every((participant, index) => participant.uuid === oldParticipants[index]?.uuid) && newParticipants.length === oldParticipants.length) return oldParticipants
          // We try to update only the changes to prevent rerenders
          const mergedParticipants: Participant[] = []
          // We need a copy to edit it safely
          const oldParticipantsCopy = [...oldParticipants]
          newParticipants.forEach(participant => {
            const index = oldParticipantsCopy.findIndex(m => m.uuid === participant.uuid)
            // We add the participant if it's new
            if(index === -1) mergedParticipants.push(participant)
            // We add the old version of the participant if it was already there
            else mergedParticipants.push(...oldParticipantsCopy.splice(index, 1))
          })
          return mergedParticipants
        }))
        .catch(err => setFeedback(err.message))
    }, [session.uuid])

    const dataSource = React.useMemo<MessageType[]>(() => messages.map(message => {
      const author = participants.find(user => user.uuid === message.userId)
      if(!author) loadUsers()
      const isAuthor = profile?.uuid === message.userId
      return {
        type: 'text',
        date: new Date(message.postDate),
        text: message.content,
        position: isAuthor ? 'right' : 'left',
        id: message.uuid,
        title: `${author?.firstName} ${author?.lastName}`,
        focus: false,
        titleColor: colors.mainDark,
        status: isAuthor ? 'sent' : 'read',
        replyButton: false,
        removeButton: isAuthor,
        retracted: false,
        forwarded: false,
        notch: true
      }
    }), [loadUsers, messages, participants, profile?.uuid])

    // Clear error message if any
    React.useEffect(() => {
      if(feedback) setTimeout(() => setFeedback(''), 5000)
    }, [feedback])

    // Load new messages every minute
    React.useEffect(() => {
      const interval = setInterval(loadMessages, 60000)
      loadMessages()
      return () => clearInterval(interval)
    }, [loadMessages])

    const onSubmit = React.useCallback(() => {
      if (!inputRef.current?.value) return
      const inputText = inputRef.current.value
      postMessage(session.uuid, inputText).then(response => {
        const newMessage: Message = {
          content: inputText,
          postDate: response.postDate,
          sportSessionId: session.uuid,
          userId: profile?.uuid || '',
          uuid: response.messageId
        }
        setMessages(messages => [...messages, newMessage])
        setInputText('')
        if(inputRef.current) inputRef.current.value = ''
      }).catch(err => setFeedback(err.message))
    }, [profile?.uuid, session.uuid]);

    const removeMessage = React.useCallback((message: MessageType) => {
      deleteMessage(message.id as string)
        .then(() => setMessages(messages.filter(m => m.uuid !== message.id)))
        .catch(err => setFeedback(err.message))
    }, [messages])

    const sendButton = React.useMemo(() => <Button variant='confirm' onClick={onSubmit} disabled={!inputText} name='submit' />, [inputText, onSubmit])
    const goBackButton = React.useMemo(() => <Button name='goBack' onClick={() => toSessionDetails(session)} />, [session, toSessionDetails])

    return (<Container>
      <MessageContainer>
        <MessageList referance={React.useRef(null)} lockable={false} downButton dataSource={dataSource} onRemoveMessageClick={removeMessage} />
      </MessageContainer>
      {feedback && <ErrorMessage>{feedback}</ErrorMessage>}
      <Input
        referance={inputRef}
        onChange={onChange}
        autofocus
        autoHeight
        leftButtons={goBackButton}
        rightButtons={sendButton}
        placeholder={t('typeHere')}
        multiline
        maxHeight={400}
        onSubmit={onSubmit}/>
    </Container>
    );
}

export default Messages;
