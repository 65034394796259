// src/components/Text.tsx
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from '../contexts/languageContext';
import { colors } from '../theme';

interface TextProps {
  name: string; // Key used to fetch the translated text
  values?: { [key: string]: string | number }; // Optional values for interpolation in translations
  className?: string; // Optional prop if we ever need to style this component from outside
  customText?: string;
  color?: string;
}

const StyledText = styled.span<{ color?: string }>`
  color: ${props => props.color || colors.dark};
  font-size: 16px;
  flex: 0 1 auto;
`;

const StyledTitle = styled(StyledText)<{ color?: string }>`
  color: ${props => props.color || colors.mainDark};
  font-size: 24px;
  font-weight: bold;
`;

const Link = styled.a`
  text-overflow: ellipsis;
`

export const ErrorMessage = styled(StyledText)`
  color: ${colors.cancel};
  margin-top: 10px;
`;

const linkify = (text: string) => {
  const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
  const phoneRegex = /(\b\+?[\d -]{8,}\b)/g;

  return text.split(' ').map((part, index) => {
    if (urlRegex.test(part)) {
      return <Link key={index} href={part} target="_blank" rel="noopener noreferrer">{part}</Link>;
    } else if (phoneRegex.test(part)) {
      return <Link key={index} href={`tel:${part}`}>{part}</Link>;
    }
    return part + ' ';
  });
};

const TextualContent: React.FC<TextProps & { type: 'text' | 'title' }> = ({ type, color, name, values, className, customText }) => {
  const { t } = useTranslation();
  const text = customText ?? t(name, values);
  const TextComponent = type === 'title' ? StyledTitle : StyledText;

  return (
    <TextComponent color={color} className={className}>
      {linkify(text)}
    </TextComponent>
  );
}

const Text: React.FC<TextProps> = (props) => <TextualContent {...props} type='text' />;

export const Title: React.FC<TextProps> = (props) => <TextualContent {...props} type='title' />

export default Text;
