import React from 'react';
import { Button as ChatButton } from 'react-chat-elements'
import { colors } from '../theme'
import { useTranslation } from '../contexts/languageContext';
import "react-chat-elements/dist/main.css"

type ButtonVariants = 'primary' | 'secondary' | 'confirm' | 'cancel';

type ButtonProps = {
  variant?: ButtonVariants;
  name: string;
  customText?: string
} & ({
  onClick: () => void;
  disabled?: undefined | false;
} | {
  onClick?: () => void;
  disabled: true;
})

// Extract the color logic
const getColor = (variant: ButtonVariants = 'primary') => {
  switch (variant) {
    case 'primary':
      return {
        backgroundColor: colors.main,
        color: colors.light,
      };
    case 'secondary':
      return {
        backgroundColor: colors.secondary,
        color: colors.light,
      };
    case 'confirm':
      return {
        backgroundColor: colors.confirm,
        color: colors.light,
      };
    case 'cancel':
      return {
        backgroundColor: colors.cancel,
        color: colors.light,
      };
    default:
      return {
        backgroundColor: colors.main,
        color: colors.light,
      };
  }
};

// const StyledButton = styled.button<Omit<ButtonProps, 'name'>>`
//   padding: 10px 20px;
//   border: none;
//   border-radius: 5px;
//   font-size: 16px;
//   cursor: pointer;
//   transition: background-color 0.2s ease;
//   background-color: ${(props) => getColor(props.variant).backgroundColor};
//   color: ${(props) => getColor(props.variant).color};

//   &:hover {
//     opacity: 0.9;
//   }

//   &:disabled {
//     cursor: not-allowed;
//     opacity: 0.7;
//   }
// `;

const Button: React.FC<ButtonProps> = ({
  variant = 'primary',
  name,
  customText,
  onClick,
  disabled = false,
}) => {
  const { t } = useTranslation()
  const text = customText || t(name)
  const buttonColors = getColor(variant)
  return <ChatButton
    disabled={disabled}
    onClick={onClick}
    title={t(name)}
    text={text}
    backgroundColor={disabled ? colors.lightGrey : buttonColors.backgroundColor}
    color={disabled ? 'white' : buttonColors.color}
  />
};

export default Button;
