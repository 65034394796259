import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Text, { Title, ErrorMessage } from '../components/Text';
import Input from '../components/Input';
import Button from '../components/Button';
import { useProfile } from '../contexts/profileContext';
import { Friend, User, UpdateRequest, deleteFriend, listFriends } from '../api'; // Add your actual import here
import { Reputation } from '../components/Reputation';
import UpdatePassword from '../modals/UpdatePassword';
import UpdatePhone from '../modals/UpdatePhoneNumber';
import { useNavigation } from '../contexts/navigationContext';
import { cache } from '../contexts/cache';
import Terms from '../modals/Terms';

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 16px;
`;

const ProfileField = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
  margin-bottom: 16px;
`;

const FriendList = styled.div`
  margin-top: 24px;
`;

const FriendItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding: 8px 0;
`;

const Label = styled(Text)`
  min-width: 10em;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
  padding: 0.5em;
`

const ClickableLink = styled.div`
  cursor: pointer;
  text-decoration: underline;
  color: blue;
`

const Profile: React.FC = () => {
  const { profile, updateProfile, logout } = useProfile()
  const [localProfile, setLocalProfile] = useState<User | undefined>(profile)
  const [isChanged, setIsChanged] = useState(false);
  const [feedback, setFeedback] = useState('')
  const [friends, setFriends] = useState<Friend[]>(cache.friends)
  const [modal, setModal] = useState<'updatePhone' | 'updatePassword' | 'cgu' | 'legalTerms'>()
  const { toCalendar } = useNavigation()

  React.useEffect(() => setLocalProfile(profile), [profile])

  const handleSave = () => {
    if(!localProfile) return
    // Call the API to update the user profile
    updateProfile(localProfile)
      .then(() => setIsChanged(false))
      .catch(err => setFeedback(err.message));
  };

  useEffect(() => {
    listFriends().then(setFriends).catch(err => setFeedback(err.message))
  }, [])

  const handleRemoveFriend = (friendId: string) => {
    // Call API to remove friend
    deleteFriend(friendId)
      .then(() => setFriends(friends => friends.filter(friend => friend.uuid === friendId)))
      .catch(err => setFeedback(err.message));
  };

  const updateLocalProfile = React.useCallback((key: keyof UpdateRequest, value: string) => {
    setLocalProfile(profile => profile && ({...profile, [key]: value }))
    setIsChanged(true)
  }, [])

  const closeModal = React.useCallback(() => setModal(undefined), [])

  const openLegalTerms = React.useCallback(() => setModal('legalTerms'), [])
  const openCGU = React.useCallback(() => setModal('cgu'), [])

  return (
    <ProfileContainer>
      <ProfileField>
        <Label name='firstName' />
        <Input
          name="firstName"
          value={localProfile?.firstName || ''}
          onChangeText={(e: string) => { updateLocalProfile('firstName', e) }}
        />
      </ProfileField>
      <ProfileField>
        <Label name='lastName' />
        <Input name="lastName" value={localProfile?.lastName || ''} onChangeText={(e) => { updateLocalProfile('lastName', e) }} />
      </ProfileField>
      <ProfileField>
        <Label name='postalCode' />
        <Input name="postalCode" value={localProfile?.postalCode || ''} onChangeText={(e) => { updateLocalProfile('postalCode', e) }} />
      </ProfileField>
      <ProfileField>
        <Label name='country' />
        <Input name="country" value={localProfile?.country || ''} onChangeText={(e) => { updateLocalProfile('country', e) }} />
      </ProfileField>
      <ProfileField>
        <Label name='phoneNumber' values={React.useMemo(() => ({ phoneNumber: profile?.phoneNumber || '' }), [profile?.phoneNumber])} />
        <Button name='edit' onClick={React.useCallback(() => setModal('updatePhone'), [])} />
      </ProfileField>
      <ProfileField>
        <Button name='changePassword' onClick={React.useCallback(() => setModal('updatePassword'), [])} />
      </ProfileField>
      <ProfileField>
        <Label name='reputation' />
        <Reputation reputation={profile?.reputation || 10} />
        <Text name='voteCount' values={React.useMemo(() => ({ voteCount: (profile?.voteCount || 0) }), [profile?.voteCount])} />
      </ProfileField>

      <FriendList>
        <Title name='friends' />
        {/* Loop through friends list and render each friend */}
        {friends?.map(friend => (
          <FriendItem key={friend.uuid}>
            <Text name='name' customText={`${friend.firstName} ${friend.lastName}`} />
            <Button name='remove' onClick={() => handleRemoveFriend(friend.uuid)} />
          </FriendItem>
        ))}
      </FriendList>

      {isChanged && <Button name='save' onClick={handleSave} />}
      <Button name='goBack' onClick={toCalendar} />
      <Row>
        <ClickableLink onClick={openLegalTerms}><Text color='blue' name='legalTerms' /></ClickableLink>
        <ClickableLink onClick={openCGU}><Text color='blue' name='cgu' /></ClickableLink>
        <Button name='logout' onClick={logout} />
      </Row>
      {feedback && <ErrorMessage>{feedback}</ErrorMessage>}
      {modal === 'updatePassword' ? <UpdatePassword onClose={closeModal} />
      : modal === 'updatePhone' ? <UpdatePhone onClose={closeModal} />
      : modal === 'cgu' ? <Terms type='cgu' onClose={closeModal} />
      : modal === 'legalTerms' ? <Terms type='legalTerms' onClose={closeModal} />
      : null}
    </ProfileContainer>
  );
};

export default Profile;
