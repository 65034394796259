import React, { useState } from 'react';
import Modal from '../components/Modal';
import Input from '../components/Input';
import Button from '../components/Button';
import { participateInSession } from '../api'
import { ErrorMessage } from '../components/Text';

interface EnterPasswordProps {
  sessionId: string;
  onClose: () => void;
}

const EnterPassword: React.FC<EnterPasswordProps> = ({ sessionId, onClose }) => {
  const [password, setPassword] = useState('');
  const [feedback, setFeedback] = useState<string>();

  const handleSubmit = React.useCallback(() => {
    participateInSession(sessionId, password).then(onClose).catch(err => setFeedback(err.message))
  }, [onClose, password, sessionId])

  return (
    <Modal name='enterPassword' onClose={onClose}>
      <Input 
        type="password"
        name='password'
        value={password}
        onChangeText={setPassword}
      />
      <Button name='submit' disabled={!!password} onClick={handleSubmit} />
      {feedback && <ErrorMessage>{feedback}</ErrorMessage>}
    </Modal>
  );
};

export default EnterPassword;
