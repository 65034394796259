import React, { useState } from 'react';
import Dropdown from '../components/Dropdown';
import { Sport, Place, listPlaces, SportSessionCreation, listSports, createSportSession } from '../api';
import Button from '../components/Button';
import Input from '../components/Input';
import { useLanguage, useTranslation } from '../contexts/languageContext';
import Text, { ErrorMessage, Title } from '../components/Text';
import { useNavigation } from '../contexts/navigationContext';
import SportCreation from '../modals/SportCreation';
import PlaceCreation from '../modals/PlaceCreation';
import styled from 'styled-components';
import { cache } from '../contexts/cache';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em;
  gap: 0.5em;
`

const Entry = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.8em 0;
  align-items: center;
  gap: 1em;
`

const levels = Array(6).fill(0).map((_, i) => i)
export const levelNames = [
  'anyLevel',
  'beginners',
  'casual',
  'intermediate',
  'advanced',
  'experts'
]

const Description = styled.textarea`
  min-height: 6em;
`

const Time = styled(Input)`
  flex: 0 1 5em;
  max-width: 5em;
  display: flex;
  flex-direction: row;
`

const CreateSession = () => {
  const { date, setDate, toCalendar } = useNavigation()
  const [session, setSession] = useState<Omit<SportSessionCreation, 'datetime'>>({
    description: '',
    duration: 30,
    level: 10,
    participants: 10,
    placeId: -1,
    price: 0,
    sportId: -1,
    teams: 2,
  })
  const [feedback, setFeedback] = useState('')
  const language = useLanguage()
  const [modal, setModal] = useState<'sport' | 'place'>()
  const { t } = useTranslation()

  const [availablePlaces, setAvailablePlaces] = useState<Place[]>(cache.places)
  const [availableSports, setAvailableSports] = useState<Sport[]>(cache.sports)

  const fetchSports = React.useCallback((language: string) => {
    listSports(language).then(setAvailableSports).catch(err => setFeedback(err.message))
  }, [])

  const fetchPlaces = React.useCallback(() => {
    listPlaces().then(setAvailablePlaces).catch(err => setFeedback(err.message))
  }, [])

  // Load available places
  React.useEffect(() => { fetchPlaces() }, [fetchPlaces])

  // Load available sports
  React.useEffect(() => { fetchSports(language) }, [fetchSports, language])

  // Loads last paramters
  React.useEffect(() => {
    const oldParams = localStorage.getItem('lastCreatedSession')
    if(oldParams) setSession(session => ({ ...session, ...JSON.parse(oldParams) }))
  }, [])

  // Save params in local storage
  React.useEffect(() => {
    localStorage.setItem('lastCreatedSession', JSON.stringify(session))
  }, [session])

  const handleSubmit = React.useMemo(() => {
    if (session.placeId >= 0 && session.sportId >= 0 && session.participants > 1 && date > new Date()) {
      return () => createSportSession({ ...session, datetime: date })
        .then(toCalendar)
        .catch(err => setFeedback(err.message))
    } else return undefined
  }, [date, session, toCalendar])

  const updateSession = React.useCallback((sessionUpdate: Partial<SportSessionCreation>) =>
    setSession(old => ({ ...old, ...sessionUpdate }))
  , [])

  const selectedSport = availableSports.find(sport => sport.id === session.sportId)
  const selectedPlace = availablePlaces.find(place => place.id === session.placeId)
  const closeModal = React.useCallback(() => setModal(undefined), [])
  const closePlaceModal = React.useCallback(() => {
    closeModal()
    fetchPlaces()
  }, [closeModal, fetchPlaces])
  const closeSportModal = React.useCallback(() => {
    closeModal()
    fetchSports(language)
  }, [closeModal, fetchSports, language])
  const addSport = React.useCallback(() => setModal('sport'), [])
  const addPlace = React.useCallback(() => setModal('place'), [])
  const sessionValues = React.useMemo(() => ({ date: date.toLocaleDateString(language, { weekday: 'long', day: 'numeric', month: 'long' }) }), [date, language])
  const updateTime = React.useCallback((newValue: string) => {
    const [HH, mm] = newValue.split(':')
    const newDate = new Date(date)
    newDate.setHours(parseInt(HH), parseInt(mm), 0, 0)
    setDate(newDate)
  }, [date, setDate])

  return (
    <Container>
      <Title name="sessionCreation" values={sessionValues} />
      <Entry>
        <Text name="inputTime" />
        <Time required type="time" value={date.toLocaleTimeString().slice(0, 5)} onChangeText={updateTime} name="duration" />
        <Text name='H' customText='H' />
      </Entry>
      <Dropdown name='Sport' addElement={addSport} items={availableSports} selectedItem={selectedSport} itemToString={item => item?.name || ''} onChange={e => e?.id && updateSession({ sportId: e.id })} />
      <Dropdown name='Place' addElement={addPlace} items={availablePlaces} selectedItem={selectedPlace} itemToString={item => item?.name || ''} onChange={e => e?.id && updateSession({ placeId: e.id })} />
      {/* You can use a third-party date-time picker library for the datetime input */}
      <Entry>
        <Text name="inputDuration" />
        <Input required type="number" value={(session.duration ?? 30) + ''} onChangeText={React.useCallback((e: string) => updateSession({ duration: Number(e) }), [updateSession])} name="duration" />
      </Entry>
      <Entry>
        <Text name="inputPrice" />
        <Input required type="number" value={(session.price ?? 0) + ''} onChangeText={React.useCallback((e: string) => updateSession({ price: Number(e) }), [updateSession])} name="price" />
      </Entry>
      <Entry>
        <Text name="password" />
        <Input value={session.password ?? ''} onChangeText={React.useCallback((e: string) => updateSession({ password: e }), [updateSession])} name="password" />
      </Entry>
      <Dropdown name='Level' items={levels} selectedItem={session.level * 2} itemToString={React.useCallback((level: number) => t(levelNames[level]), [t])} onChange={React.useCallback((l: number) => updateSession({ level: Math.floor(l / 2) }), [updateSession])} />
      <Entry>
        <Text name="participants" />
        <Input type="number" value={(session.participants ?? 2) + ''} onChangeText={React.useCallback((e: string) => updateSession({ participants: Number(e) }), [updateSession])} name="participants" />
      </Entry>
      <Entry>
        <Text name="teams" />
        <Input type="number" value={(session.teams ?? 2) + ''} onChangeText={React.useCallback((e: string) => updateSession({ teams: Number(e) }), [updateSession])} name="teams" />
      </Entry>
      <Text name="inputDescription" />
      <Description value={session.description} onChange={React.useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => updateSession({ description: e.target.value }), [updateSession])} name="description" />
      {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
      <Button name='submit' disabled={!handleSubmit} onClick={handleSubmit!} />
      {feedback && <ErrorMessage>{feedback}</ErrorMessage>}
      <Button name='cancel' onClick={toCalendar} />
      {modal === 'sport' ? <SportCreation onClose={closeSportModal} />
      : modal === 'place' ? <PlaceCreation onClose={closePlaceModal} />
      : null}
    </Container>
  );
};

export default CreateSession;
