import React from 'react';
import Modal from '../components/Modal';
import { CGU, legalTerms } from '../localization/terms'

interface TermsProps {
  type: 'cgu' | 'legalTerms'
  onClose: () => void
}

const Terms: React.FC<TermsProps> = ({ type, onClose }) => {
  const content = React.useMemo(() => ({ __html: type === 'cgu' ? CGU : legalTerms }), [type])

  return (
    <Modal name={type} onClose={onClose}>
      <div dangerouslySetInnerHTML={content} />
    </Modal>
  );
};

export default Terms;
