import React from 'react'

const languages = {
  get fr() {
    return require('../localization/fr.json')
  }
}

type AvailableLang = keyof typeof languages

// TODO handle language swap with a proper provider
const LanguageContext = React.createContext('fr')
const LanguageUpdateContext = React.createContext<(lang: AvailableLang) => void>(() => {
  throw new Error('No LanguageUpdateContext provider provided')
})
const LanguageContentContext = React.createContext(languages.fr)
export const LanguageProvider = ({ children }: { children: React.ReactNode }) => {
  const [lang, setLang] = React.useState<AvailableLang>('fr')
  const [language, setLanguage] = React.useState(languages[lang])

  const updateLang = React.useCallback((lang: AvailableLang) => {
    const language = languages[lang]
    if(language) {
      setLang(lang)
      setLanguage(language)
    }
  }, [])

  return <LanguageContext.Provider value={lang}>
    <LanguageContentContext.Provider value={language}>
      <LanguageUpdateContext.Provider value={updateLang}>
        {children}
      </LanguageUpdateContext.Provider>
    </LanguageContentContext.Provider>
  </LanguageContext.Provider>
}

export const useLanguage = () => React.useContext(LanguageContext)
export const useLanguageChange = () => React.useContext(LanguageUpdateContext)

export const useTranslation = () => {
  const language = React.useContext(LanguageContentContext)

  const translator = React.useCallback((key: string, options?: Record<string, string | number>) => {
    let translation = language[key]
    if(!translation) console.error('Missing key', key)
    else if(options) Object.entries(options).forEach(([key, value]) => translation = translation.replace(new RegExp(`{${key}}`, "g"), value))
    return translation
  }, [language])
  return { t: translator }
}