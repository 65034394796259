import React, { useState } from 'react';
import DayLane from '../components/DayLane'; // Assuming you have DayLane in the same directory
import Button from '../components/Button';
import styled from 'styled-components';
import { colors } from '../theme';
import { useNavigation } from '../contexts/navigationContext';

const Week = styled.main`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  border-right: 1px solid ${colors.dark};
  overflow: auto;
`

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
  margin: 0.2em;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 0.5em;
`

const getStartAndEndOfWeek = (date: Date) => {
  const start = new Date(date);
  start.setDate(date.getDate() - (date.getDay() + 6) % 7 ); // Set to Monday of the current week
  start.setHours(0, 0, 0, 0)
  const end = new Date(start);
  end.setDate(start.getDate() + 6); // Set to Sunday of the current week
  end.setHours(23, 59, 59, 999)
  return [start, end];
};

const Calendar: React.FC = () => {
  const { toSubscribedSessions, toProfile, date, setDate } = useNavigation()
  const [displayedWeek, setDisplayedWeek] = useState(() => {
    return getStartAndEndOfWeek(date);
  });

  React.useEffect(() => {
    setDisplayedWeek(getStartAndEndOfWeek(date))
  }, [date])

  const currentWeek = React.useMemo(() => getStartAndEndOfWeek(new Date()), [])
  const [currentMonday] = currentWeek
  const [displayedMonday, displayedSunday] = displayedWeek

  const goToPreviousWeek = React.useMemo(() => {
    const newDate = new Date(date)
    newDate.setDate(date.getDate() - 7)
    if(newDate < currentMonday) return undefined
    else return () => setDate(newDate)
  }, [currentMonday, date, setDate])

  const goToNextWeek = React.useCallback(() => {
    setDate(date => {
      const newDate = new Date(date)
      newDate.setDate(date.getDate() + 7)
      return newDate
    })
  }, [setDate])

  const showCurrentWeek = React.useCallback(() => setDate(new Date()), [setDate])

  const weekLanes = React.useMemo(() => Array.from({ length: 7 }).map((_, index) => {
    const dayDate = new Date(displayedMonday);
    dayDate.setDate(dayDate.getDate() + index);
    return <DayLane key={index} date={dayDate} />;
  }), [displayedMonday])

  return (
    <Container>
      <Header>
        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
        <Button name='previous' disabled={!goToPreviousWeek} onClick={goToPreviousWeek!} />
        <span>{`${
          displayedMonday.toLocaleString(navigator.language || 'en-US', { month: 'short', day: 'numeric', weekday: 'long' })
        } - ${
          displayedSunday.toLocaleString(navigator.language || 'en-US', { month: 'short', day: 'numeric', weekday: 'long' })
        }`}</span>
        <Button name='next' onClick={goToNextWeek} />
        <Button name='showCurrent' disabled={displayedMonday.getTime() === currentMonday.getTime()} onClick={showCurrentWeek} />
        <Button name='toSubscribedSessions' onClick={toSubscribedSessions} />
        <Button name='toProfile' onClick={toProfile} />
      </Header>
      <Week>
        {weekLanes}
      </Week>
    </Container>
  );
};

export default Calendar;
