import React, { useState } from 'react';
import Modal from '../components/Modal';
import Button from '../components/Button';
import { Place, createPlace } from '../api';
import Text, { ErrorMessage } from '../components/Text';
import PickPlace from './PickPlace';

interface PlaceCreationProps {
  onClose: () => void;
}

export const API_KEY = 'AIzaSyB1fQQkoVqQHdjYnwHF9euv68q9sP1YQIk'

const PlaceCreation: React.FC<PlaceCreationProps> = ({ onClose }) => {
  const [place, setPlace] = useState<Omit<Place, 'id'>>()
  const [feedback, setFeedback] = useState('');

  const handleFormSubmit = React.useCallback(() => {
    if(place) createPlace(place).then(onClose).catch(err => setFeedback(err.message))
  }, [onClose, place])

  return (
    <Modal name='createPlace' onClose={onClose}>
      <Text name='enterPlace' />
      <PickPlace onPlaceChanged={setPlace} onError={setFeedback} />
      <Button disabled={!place} name='create' onClick={handleFormSubmit} />
      {feedback && <ErrorMessage>{feedback}</ErrorMessage>}
    </Modal>
  );
};

export default PlaceCreation;
