import React from 'react';
import Modal from '../components/Modal';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Place } from '../api';
import Text from '../components/Text';
import styled from 'styled-components';

interface SeePlaceProps {
  place: Place;
  onClose: () => void;
}

const containerStyle = {
  display: 'flex',
  flex: '1 1 100vh',
  flexDirection: 'column'
} as const;

const Entry = styled.div``

const PlaceModal = styled(Modal)`
  width: 85vw;
`

const SeePlace: React.FC<SeePlaceProps> = ({ place, onClose }) => {
  const center = React.useMemo(() => ({lat: place.latitude, lng: place.longitude}), [place.latitude, place.longitude])
  const values = React.useMemo(() => ({
    name: place.name,
    websiteUrl: place.websiteUrl || '',
    phoneNumber: place.phoneNumber || '',
    address: place.address
  }), [place.address, place.name, place.phoneNumber, place.websiteUrl])

  return (
    <PlaceModal name='placeLocation' onClose={onClose}>
      <Entry><Text name='placeName' customText={place.name} /></Entry>
      <Entry><a href={`https://maps.google.com/?ll=${place.latitude},${place.longitude}`}><Text name='placeAddress' values={values} /></a></Entry>
      <Entry><Text name='placeWebsite' values={values} /></Entry>
      <Entry><Text name='placePhone' values={values} /></Entry>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={15}
      >
        <Marker position={center} />
      </GoogleMap>
    </PlaceModal>
  );
};

export default SeePlace;
