import React, { useState } from 'react';
import styled from 'styled-components';
import Input from './Input';
import Button from './Button';

export interface ListProps<ItemType> {
  type: string;
  items: ItemType[];
  Item: React.ComponentType<{ item: ItemType; onPress?: () => void }>;
  addElement?: () => void;
  className?: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 0 1 auto;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  overflow: auto;
`;

const SearchBar = styled(Input)`
  margin-bottom: 20px;
`;

const List = <ItemType,>({
  type,
  items,
  Item,
  addElement,
  className,
}: ListProps<ItemType>) => {

  // State to handle search filter
  const [searchTerm, setSearchTerm] = useState('');

  const filteredItems = React.useMemo(() =>
    items.filter((item) => (typeof item === 'string' ? item : typeof item === 'object' ? Object.values(item as object).map(v => v + '').join(' ') : item + '').toLowerCase().includes(searchTerm.toLowerCase()))
      .map((item, index) => (<Item key={index} item={item} />))
  , [Item, items, searchTerm]);

  return (
    <Container className={className}>
      <SearchBar
        name={`searchFor${type}`}
        value={searchTerm}
        onChangeText={setSearchTerm}
      />

      <ListContainer>
        {filteredItems}
      </ListContainer>

      {addElement && (
          <Button onClick={addElement} variant="primary" name={`addNew${type}`} />
      )}
    </Container>
  );
};

export default List;
