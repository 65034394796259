import React from 'react';
import { ProfileProvider, useProfile } from './contexts/profileContext';
import { NavigationProvider, useNavigation } from './contexts/navigationContext';
import SessionDetails from './screens/SessionDetails';
import Login from './screens/Login';
import Profile from './screens/Profile';
import Messages from './screens/Messages';
import { LanguageProvider } from './contexts/languageContext';
import Calendar from './screens/Calendar';
import CreateSession from './screens/CreateSession';
import styled from 'styled-components';
import SubscribedSessions from './screens/SubscribedSessions'
import { API_KEY } from './modals/PlaceCreation';
import { Libraries, LoadScript } from '@react-google-maps/api';

const libraries = ["places"] as Libraries;

const FullscreenContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
`

function App() {
  return (
    <div className="App">
    <LoadScript googleMapsApiKey={API_KEY} libraries={libraries}>
      <LanguageProvider>
        <ProfileProvider>
          <NavigationProvider>
            <FullscreenContainer>
              <Content />
            </FullscreenContainer>
          </NavigationProvider>
        </ProfileProvider>
      </LanguageProvider>
      </LoadScript>
    </div>
  );
}

const Content = () => {
  const { screen, session } = useNavigation()
  const { profile } = useProfile()
  if(!profile) return <Login />
  else {
    return screen === 'calendar' ? <Calendar />
    : screen === 'createSession' ? <CreateSession />
    : screen === 'messages' && session ? <Messages session={session} />
    : screen === 'profile' ? <Profile />
    : screen === 'sessionDetails' && session ? <SessionDetails session={session} />
    : screen === 'subscribedSessions' ? <SubscribedSessions />
    : <Login />
  }
}

export default App;
