import React from 'react';
import styled from 'styled-components';
import { colors } from '../theme';
import { SportSession, Status } from '../api';
import LockIcon from '../assets/lock.svg';
import Text from './Text'
import { useNavigation } from '../contexts/navigationContext';

interface SessionCardProps {
  session: SportSession;
  showDate?: boolean;
  status?: Status;
}

const borderColorMap = {
  participant: colors.confirm,
  invited: colors.secondary,
  interested: colors.main,
};

const Card = styled.div<{ $status: Status }>`
  border: 1px solid ${props => borderColorMap[props.$status as 'invited'] || colors.lightGrey};
  padding: 1em;
  position: relative;
  cursor: pointer;
  
  &:hover {
    background-color: ${colors.light};
  }
`;

const Lock = styled.img`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
`;

const SessionCard: React.FC<SessionCardProps> = ({ session, showDate, status }) => {
  const { toSessionDetails } = useNavigation()

  // Extract date in the format YYYY-MM-DD
  const { date, startTime } = React.useMemo(() => {
    const dateObj = new Date(session.datetime)
    const date = dateObj.toLocaleString(navigator.language || 'en-US', { month: 'short', day: 'numeric', weekday: 'long' })
    const startTime = dateObj.toLocaleString(navigator.language || 'en-US', { hour: '2-digit', minute: '2-digit' })
    return { date, startTime }
  }, [session.datetime])

  const values = React.useMemo(() => ({
    date: { date },
    sport: { name: session.sport.name },
    startTime: { startTime },
    duration: { duration: session.duration },
    place: { name: session.place.name },
    price: { price: session.price },
  }), [date, session.duration, session.place.name, session.price, session.sport.name, startTime])

  const onClick = React.useCallback<React.MouseEventHandler<HTMLDivElement>>(e => {
    e.stopPropagation()
    toSessionDetails(session)
  }, [session, toSessionDetails])

  return (
    <Card onClick={onClick} $status={status}>
      {!!session.password && <Lock src={LockIcon} alt="Private" />}
      {showDate && <div><Text name='date' values={values.date} /></div>}
      <div><Text name='sport' values={values.sport} /></div>
      <div><Text name='startTime' values={values.startTime} /></div>
      <div><Text name='duration' values={values.duration} /></div>
      <div><Text name='place' values={values.place} /></div>
      <div><Text name='price' values={values.price} /></div>
    </Card>
  );
};

export default SessionCard;
