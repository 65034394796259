import React from "react"
import { SportSession } from "../api"

export type Screen = 'profile' | 'calendar' | 'messages' | 'sessionDetails' | 'createSession' | 'subscribedSessions'

type NavigationContextType = {
  screen: Screen
  previous?: Screen
  session?: SportSession
  setScreen: (screen: Screen, session?: SportSession) => void
  date: Date,
  setDate: React.Dispatch<React.SetStateAction<Date>>
}

const getNow = () => {
  const date = new Date()
  date.setHours(14, 0, 0, 0)
  return date
}

const NavigationContext = React.createContext<NavigationContextType>({
  screen: 'calendar' as const,
  date: getNow(),
  setDate: () => {
    throw new Error('No NavigationProvider provided')
  },
  setScreen: () => {
    throw new Error('No NavigationProvider provided')
  },
})

export const NavigationProvider = ({ children }: { children: React.ReactNode }) => {
  const [screen, setScreen] = React.useState<Screen>('calendar')
  const [session, setSession] = React.useState<SportSession>()
  const [previous, setPrevious] = React.useState<Screen>()
  const [date, setDate] = React.useState<Date>(getNow())
  const updateScreen =React.useCallback((newScreen: Screen, session?: SportSession) => {
    if(screen === newScreen) return
    setPrevious(screen)
    setScreen(newScreen)
    setSession(session)
  }, [screen])
  const value = React.useMemo(() => ({
    session, screen, previous, setScreen: updateScreen, date, setDate
  }), [date, previous, screen, session, updateScreen])
  return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>
}

export const useNavigation = () => {
  const { screen, previous, session, setScreen, date, setDate } = React.useContext(NavigationContext)

  const toProfile = React.useCallback(() => setScreen('profile'), [setScreen])
  const toMessages = React.useCallback((session: SportSession) => setScreen('messages', session), [setScreen])
  const toCalendar = React.useCallback(() => setScreen('calendar'), [setScreen])
  const toSessionDetails = React.useCallback((session: SportSession) => setScreen('sessionDetails', session), [setScreen])
  const toCreateSession = React.useCallback((date: Date) => {
    setScreen('createSession')
    setDate(date)
  }, [setDate, setScreen])
  const toSubscribedSessions = React.useCallback(() => setScreen('subscribedSessions'), [setScreen])
  const goBack = React.useCallback(() => previous && setScreen(previous), [previous, setScreen])

  return { screen, date, session, setDate, goBack, toProfile, toMessages, toCalendar, toSessionDetails, toCreateSession, toSubscribedSessions }
}