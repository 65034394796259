import React, { useState, useEffect } from 'react';
import { SportSessionWithStatus, getSubscribedSessions } from '../api';
import SessionCard from '../components/SessionCard';
import styled from 'styled-components';
import Text, { ErrorMessage, Title } from '../components/Text';
import { cache } from '../contexts/cache';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  overflow: auto;
  gap: 0.5em;
  padding: 0.5em;
`

const SubscribedSessionsView: React.FC = () => {
  const [sessions, setSessions] = useState<SportSessionWithStatus[]>(cache.subscribedSessions);
  const [feedback, setFeedback] = useState('')

  useEffect(() => {
    getSubscribedSessions().then(setSessions).catch(err => setFeedback(err.message))
  }, []);

  const sessionCards = React.useMemo(() =>
    sessions.sort((s1, s2) => s1.datetime < s2.datetime ? -1 : 1)
      .map(session => <SessionCard showDate key={session.uuid} session={session} status={session.status} />)
  , [sessions])

  const breakIndex = sessions.findIndex(session => session.datetime > new Date())
  const pastSessions = React.useMemo(() => breakIndex === -1 ? sessionCards : sessionCards.slice(0, Math.max(0, breakIndex-1)), [breakIndex, sessionCards])
  const futureSessions = React.useMemo(() => breakIndex === -1 ? [] : sessionCards.slice(Math.max(0, breakIndex)), [breakIndex, sessionCards])

  return (
    <Container>
      <Title name='nextSessions' />
      {futureSessions.length ? futureSessions : <Text name='noSession' />}
      <Title name='pastSessions' />
      {pastSessions.length ? pastSessions : <Text name='noSession' />}
      {feedback && <ErrorMessage>{feedback}</ErrorMessage>}
    </Container>
  );
};

export default SubscribedSessionsView;
