import React, { createContext, useState, useEffect, useContext } from 'react';
import { getUserProfile, User, updateUser, loginUser, logoutUser, LoginRequest, RegisterRequest, registerUser } from '../api'

type UserUpdate = Partial<Omit<User, 'uuid' | 'phoneNumber'>>

interface ProfileContextType {
  profile: User | undefined;
  updateProfile: (updates: UserUpdate) => Promise<void>;
  login: (loginData: LoginRequest, remember: boolean) => Promise<void>;
  register: (registerData: RegisterRequest, remember: boolean) => Promise<void>;
  logout: () => void;
}

const ProfileContext = createContext<ProfileContextType>({
  profile: undefined,
  updateProfile: () => {
    throw new Error('No ProfileProvider provided')
  },
  login: async () => {
    throw new Error('No ProfileProvider provided')
  },
  register: async () => {
    throw new Error('No ProfileProvider provided')
  },
  logout: () => {
    throw new Error('No ProfileProvider provided')
  },
});

// 2. Create the ProfileProvider component
export const ProfileProvider = ({ children }: { children: React.ReactNode }) => {
  const [token, setToken] = React.useState('')
  const [profile, setProfile] = useState<User>();

  const login = React.useCallback((loginData: LoginRequest, remember: boolean) => loginUser(loginData).then(({ token }) => {
    setToken(token)
    remember && localStorage.setItem('token', token)
  }), [])

  const logout = React.useCallback(() => {
    logoutUser()
    setToken('')
    localStorage.removeItem('token')
  }, [])

  const register = React.useCallback((register: RegisterRequest, remember: boolean) =>
    registerUser(register).then(({ token }) => {
      setToken(token)
      remember && localStorage.setItem('token', token)
    })
  , [])

  // Try to load token from localStorage
  React.useEffect(() => {
    const token = localStorage.getItem('token')
    if(token) setToken(token)
  }, [])

  // Fetch profile data on login
  useEffect(() => {
    token && getUserProfile().then(setProfile)
  }, [token]);

  const updateProfile = React.useCallback(async (updates: UserUpdate) => {
    const remoteProfile = await getUserProfile()
    const updatedProfile = { ...remoteProfile, ...updates }
    return updateUser(updatedProfile).then(() => setProfile(updatedProfile))
  }, [])

  const value = React.useMemo(() => ({
    profile, updateProfile, login, logout, register, token
  }), [login, logout, profile, register, token, updateProfile])

  return (
    <ProfileContext.Provider value={value}>
      {children}
    </ProfileContext.Provider>
  );
};

// Custom hook for easier consumption
export const useProfile = (): ProfileContextType => useContext(ProfileContext);
