import React from 'react';
import styled from 'styled-components';
import { ReactComponent as FullStarIcon } from '../assets/fullStar.svg';
import { ReactComponent as EmptyStarIcon } from '../assets/emptyStar.svg';
import { ReactComponent as HalfStarIcon } from '../assets/halfStar.svg';
import { colors } from '../theme';

const InteractiveArea = styled.span`
  cursor: pointer;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  gap: 0.3em;
`

const starStyle = { width: '1em', height: '1em' }
const hoveredStarStyle = { ...starStyle, color: colors.grey }
const FullStar = ({ hovered }: { hovered?: boolean }) => <FullStarIcon style={hovered ? hoveredStarStyle : starStyle} />
const HalfStar = () => <HalfStarIcon style={starStyle} />
const EmptyStar = () => <EmptyStarIcon style={starStyle} />

const StarButton = ({ selected, onClick, hovered, onHover }: {
  selected: boolean; hovered?: boolean;
  onClick: () => void; onHover: (b: boolean) => void
}) => {
  React.useEffect(() => {
    const mouseEnter = () => onHover(true)
    const mouseLeave = () => onHover(false)
    const span = ref.current
    if(!span) return
    span.addEventListener('mouseenter', mouseEnter)
    span.addEventListener('mouseleave', mouseLeave)
    return () => {
      span.removeEventListener('mouseenter', mouseEnter)
      span.removeEventListener('mouseleave', mouseLeave)
    }
  }, [onHover])
  const ref = React.useRef<HTMLSpanElement>(null)
  return <span ref={ref} onClick={onClick}>{selected || hovered ? <FullStar hovered={hovered} /> : <EmptyStar />}</span> 
}

const ReputationDisplay = ({ reputation }: { reputation: number }) => {
  const fullStars = Math.floor(reputation / 2);
  const halfStars = reputation % 2;
  const emptyStars = 5 - fullStars - halfStars;
  return <Row>
    {Array(fullStars).fill(0).map((_, i) => <span key={i}><FullStar /></span>)}
    {Array(halfStars).fill(0).map((_, i) => <span key={i + fullStars}><HalfStar /></span>)}
    {Array(emptyStars).fill(0).map((_, i) => <span key={i + fullStars + halfStars}><EmptyStar /></span>)}
    <span> ({Math.round((reputation * 10))}%)</span>
  </Row>
}

const ReputationInput = ({ reputation, onRatingChange }: { reputation?: number; onRatingChange: (rating: number) => void }) => {
  const [hover, setHover] = React.useState<number>()
  const handleStarClick = React.useCallback((index: number) => {
    const newRating = index * 2.5;
    onRatingChange?.(newRating);
  }, [onRatingChange])
  const handleStarHover = React.useCallback((index: number) => {
    const newRating = index * 2.5;
    setHover(newRating)
  }, [])
  return <InteractiveArea>
    {Array(5).fill(0).map((_, index) => (
      <StarButton
        key={index}
        selected={reputation !== undefined && reputation >= index * 2.5}
        hovered={hover !== undefined && hover >= index * 2.5}
        onClick={() => handleStarClick(index)}
        onHover={(b: boolean) => b ? handleStarHover(index) : setHover(undefined)}
      />
    ))}
  </InteractiveArea>
}

export const Reputation = ({ reputation, onRatingChange }: {reputation: number; onRatingChange?: (rating: number) => void }) => {
  return onRatingChange
    ? <ReputationInput reputation={reputation} onRatingChange={onRatingChange} />
    : <ReputationDisplay reputation={reputation} />
}