export const colors = {
  main: '#6625A3',
  mainDark: '#2A044A',
  dark: '#333333',
  grey: "#A0A0A0",
  lightGrey: '#E5E5E5',
  light: '#F2F2F2',
  confirm: '#5CC884',
  cancel: '#E31963',
  secondary: '#F15642'
}