import React, { useState, useEffect } from 'react';
import { Participant, SportSession, banUserFromSession, cancelParticipation, getSubscribersForSession, kickUserFromSession, participateInSession, showInterestInSession, updateExtraPeople } from '../api';
import styled from 'styled-components';
import Text, { ErrorMessage, Title } from '../components/Text';
import Button from '../components/Button'
import InviteFriends from '../modals/InviteFriends'
import { useProfile } from '../contexts/profileContext';
import EnterPassword from '../modals/EnterPassword';
import { Reputation } from '../components/Reputation';
import SeePlace from '../modals/SeePlace'
import { useNavigation } from '../contexts/navigationContext';
import { colors } from '../theme';
import { levelNames } from './CreateSession';
import { useTranslation } from '../contexts/languageContext';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import { cache } from '../contexts/cache';

const teamColors = [
  '#FFADAD', '#FFD6A5', '#FDFFB6', '#CAFFBF', 
  '#9BF6FF', '#A0C4FF', '#BDB2FF', '#FFC6FF',
  '#FF6B6B', '#FF9A8C', '#FFF5A5', '#CBFFD6',
  '#6EE7FF', '#91A9FF', '#C2A9FF', '#FFAAE5'
];

const calendars = ['Apple','Google','Yahoo','iCal', 'Microsoft365', 'MicrosoftTeams', 'Outlook.com'] as 'Apple'[]

interface SessionDetailsProps {
  session: SportSession;
}

type ExtraParticipant = {
  uuid: undefined;
  inviter: string;
  remove: () => void
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  flex: 1 1 auto;
  padding: 20px;
`

const SessionCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: ${colors.light};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const TabView = styled.div`
  display: flex;
  margin-top: 20px;
`;

const TabButton = styled.button<{ selected: boolean }>`
  flex: 1;
  padding: 10px 15px;
  background-color: ${colors.grey};
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  ${props => props.selected ? `
    background-color: ${colors.mainDark};
    color: ${colors.light} !important;
  ` : ''}

  &:hover {
    background-color: ${colors.main};
  }

  &:focus {
    outline: none;
  }
`;

const ClickableArea = styled.div`
  text-decoration: underline;
  cursor: pointer;
`

const User = styled.div`
  padding: 0.3em;
`

const UserRow = styled.div<{ $teamIndex?: number }>`
  display: flex;
  flex-direction: row;
  gap: 1em;
  ${props => props.$teamIndex !== undefined ? `
    background-color: ${teamColors[props.$teamIndex % teamColors.length]};
  ` : ''}
`

const Item = ({
  participant, showDetails, style, kick, ban
}: {
  participant: Participant; showDetails: boolean; style?: React.CSSProperties, kick?: () => void, ban?: () => void
}) => {
  const voteCount = React.useMemo(() => ({ voteCount: participant.voteCount }), [participant.voteCount])
  return <UserRow key={participant.uuid} style={style}>{
    showDetails
    ? <>
        <UserRow>
          <Text name='name' customText={`${participant.firstName} ${participant.lastName.charAt(0)}`} />
          <Text name='phone' customText={participant.phoneNumber} />
        </UserRow>
        <UserRow>
          <Reputation reputation={participant.reputation}/>
          <Text name='voteCount' values={voteCount} />
        </UserRow>
        {kick && ban && <UserRow>
          <Button name='kick' onClick={kick}/>
          <Button name='ban' onClick={ban} />
        </UserRow>}
      </>
    : `${participant.firstName} ${participant.lastName.charAt(0)}`
  }</UserRow>
}

const ExtraItem = ({ teamIndex, inviter, remove }: { teamIndex: number; inviter: string; remove: () => void }) => {
  return <UserRow $teamIndex={teamIndex}>
    <Text name="addedBy" values={{ user: inviter }} />
    <Button name='remove' onClick={remove} />
  </UserRow>
}

const SessionDetails: React.FC<SessionDetailsProps> = ({ session }) => {
  const { goBack, toMessages } = useNavigation()
  const [subscribers, setSubscribers] = useState<Participant[]>(cache.subscribers[session.uuid] || []);
  const [activeTab, setActiveTab] = useState<'participants' | 'interested'>('participants');
  const [teams, setTeams] = useState<number[][]>([])
  const [feedback, setFeedback] = useState('')
  const [modal, setModal] = useState<'invitations' | 'password' | 'place'>()
  const { profile } = useProfile()
  const { t } = useTranslation()

  const updateExtras = React.useCallback((sessionId: string, user: Participant, action: 'add' | 'remove') => {
    const delta = action === 'add' ? 1 : -1
    updateExtraPeople(sessionId, user.extraPeople + delta).then(() => setSubscribers(subscribers =>
      subscribers.map(subscriber => subscriber.uuid === user?.uuid ? { ...subscriber, extraPeople: subscriber.extraPeople + delta } : subscriber)
    ))
  }, [])

  const participants = React.useMemo(() => subscribers.filter(user => user.status === 'participant'), [subscribers])
  const interestedPeople = React.useMemo(() => subscribers.filter(user => user.status === 'interested'), [subscribers])
  const user = React.useMemo(() => subscribers.find(user => user.uuid === profile?.uuid), [profile, subscribers])
  const participantsPlusExtra = React.useMemo(() =>
    participants.map(user => [user, ...Array(user.extraPeople).fill(0)
      .map(() => ({
        inviter: `${user.firstName} ${user.lastName[0]}`,
        uuid: undefined,
        remove: () => updateExtras(session.uuid, user, 'remove')
      }))
    ]).flat()
  , [participants, session.uuid, updateExtras])

  useEffect(() => {
    // Fetch subscribers for the session on mount
    getSubscribersForSession(session.uuid).then(setSubscribers)
  }, [session.uuid]);

  const generateTeams = React.useCallback(() => {
    const shuffledParticipants = [...Array(participantsPlusExtra.length).keys()].sort(() => 0.5 - Math.random());
    const teamSize = Math.ceil(shuffledParticipants.length / session.teams);
    const generatedTeams: number[][] = [];
  
    while (shuffledParticipants.length) {
      generatedTeams.push(shuffledParticipants.splice(0, teamSize));
    }
  
    setTeams(generatedTeams);
  }, [participantsPlusExtra, session.teams]);

  const join = React.useCallback(() => {
    session.password ? setModal('password') : participateInSession(session.uuid).catch(err => setFeedback(err.message))
  }, [session])

  const showInterest = React.useCallback(() => {
    showInterestInSession(session.uuid).catch(err => setFeedback(err.message))
  }, [session])

  const closeModal = React.useCallback(() => setModal(undefined), [])

  const isAuthor = user?.uuid === session.creatorId
  const participantList = React.useMemo(() => participantsPlusExtra.map((participant, index) => {
    const teamIndex = teams.findIndex(team => team.includes(index))
    const style = teamIndex === -1 ? undefined : { backgroundColor: teamColors[teamIndex % teamColors.length] }
    const extraParticipant = participant as ExtraParticipant
    const kick = participant.uuid && isAuthor ? () => kickUserFromSession(session.uuid, participant.uuid) : undefined
    const ban = participant.uuid && isAuthor ? () => banUserFromSession(session.uuid, participant.uuid) : undefined
    return <User key={participant.uuid || (extraParticipant.inviter + index)}>
      {participant.uuid
        ? <Item participant={participant} showDetails={isAuthor || participant.uuid === session.creatorId} style={style} kick={kick} ban={ban} />
        : <ExtraItem teamIndex={teamIndex} inviter={extraParticipant.inviter} remove={extraParticipant.remove} />}
    </User>
  }), [isAuthor, participantsPlusExtra, session.creatorId, session.uuid, teams])

  const interestedList = React.useMemo(() =>
    interestedPeople.map((interested) => <User key={interested.uuid}>
      <Item participant={interested} showDetails={user?.uuid === session.creatorId} />
    </User>),
    [interestedPeople, session.creatorId, user?.uuid]
  )

  const toChat = React.useCallback(() => toMessages(session), [session, toMessages])
  const showInvitation = React.useCallback(() => setModal('invitations'), [])
  const cancel = React.useCallback(() => cancelParticipation(session.uuid).then(goBack), [goBack, session.uuid])

  const addExtraPeople = React.useCallback(() =>
    user && updateExtras(session.uuid, user, 'add')
  , [session.uuid, updateExtras, user])

  const isInTheFuture = new Date()<new Date(session.datetime)
  const hasAvailableSeat = participantsPlusExtra.length < session.participants 

  const values = React.useMemo(() => ({
    sport: { name: session.sport.name},
    description: { description: session.description },
    time: { time: session.datetime.toLocaleDateString() },
    duration: { duration: session.duration},
    level: { level: t(levelNames[Math.floor(session.level / 2)]) },
    price: { price: session.price},
    place: { name: session.place.name},
    sessionParticipants: { count: participantsPlusExtra.length, total: session.participants },
    interestedUsers: { count: interestedPeople.length }
  }), [participantsPlusExtra.length, interestedPeople.length, session.datetime, session.description, session.duration, session.level, session.participants, session.place.name, session.price, session.sport.name, t])

  return (
    <Container>
      <SessionCard>
        {/* Session details */}
        <Title name='sport' values={values.sport} />
        <div><Text name='description' values={values.description} /></div>
        <div><Text name='time' values={values.time} /></div>
        <div><Text name='duration' values={values.duration} /></div>
        <div><Text name='level' values={values.level} /></div>
        <div><Text name='price' values={values.price} /></div>
        <ClickableArea onClick={React.useCallback(() => setModal('place'), [])}><Text name='place' values={values.place} /></ClickableArea>

        <TabView>
          <TabButton selected={activeTab === 'participants'} onClick={React.useCallback(() => setActiveTab('participants'), [])}>
            <Text color={colors.light} name="sessionParticipants" values={values.sessionParticipants} />
          </TabButton>
          <TabButton selected={activeTab === 'interested'} onClick={React.useCallback(() => setActiveTab('interested'), [])}>
            <Text color={colors.light} name='interestedUsers' values={values.interestedUsers} />
          </TabButton>
        </TabView>
        {activeTab === 'participants' ? participantList : interestedList}


        {activeTab === 'participants' && <>
          {/* External people can join */}
          {[undefined, 'invited', 'interested'].includes(user?.status) && isInTheFuture && hasAvailableSeat && <Button name='join' onClick={join} />}
          {/* Participants can add extra people */}
          {user?.status === 'participant' && isInTheFuture && hasAvailableSeat && <Button name='addExtraPeople' onClick={addExtraPeople} />}
          {/* The owner can generate teams */}
          {user?.uuid === session.creatorId && <Button name='generateTeams' onClick={generateTeams} />}
          {/* participants can cancel their participation */}
          {user?.status === 'participant' && isInTheFuture && <Button name='cancelEvent' onClick={cancel} />}
        </>}
        {/* External people can register to the event if they are not banned */}
        {user?.status === undefined && isInTheFuture && <Button name='markInterest' onClick={showInterest} />}
        {/* Anyone can invite friends if some seats are still available */}
        {isInTheFuture && hasAvailableSeat && <Button name='invite' onClick={showInvitation} />}
        {/* participants and followers can access the chat */}
        {['participant', 'interested', 'completed'].includes(user?.status as 'participant') && <Button name='chat' onClick={toChat} />}
        <AddToCalendarButton
          name={t('calendarName', { sport: session.sport.name, place: session.place.name })}
          options={calendars}
          description={session.description}
          location={session.place.address}
          startDate={session.datetime.toISOString().split('T')[0]}
          startTime={session.datetime.toISOString().split('T')[1].slice(0, 5)}
          endDate={new Date(session.datetime.getTime() + session.duration * 60000).toISOString().split('T')[0]}
          endTime={new Date(session.datetime.getTime() + session.duration * 60000).toISOString().split('T')[1].slice(0, 5)}
          timeZone="UTC"
        />
        {feedback && <ErrorMessage>{feedback}</ErrorMessage>}
      </SessionCard>
      <Button name='goBack' onClick={goBack} />
      { modal === 'invitations' ? <InviteFriends session={session} subscribedUsers={subscribers} onClose={closeModal} />
      : modal === 'password' ? <EnterPassword sessionId={session.uuid} onClose={closeModal} />
      : modal === 'place' ? <SeePlace place={session.place} onClose={closeModal} />
      : null}
    </Container>
  );
};

export default SessionDetails;
