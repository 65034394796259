import { Friend, Message, Participant, Place, Sport, SportSession, SportSessionWithStatus } from "../api";

export const cache = {
  sports: [] as Sport[],
  places: [] as Place[],
  friends: [] as Friend[],
  sessions: {} as Record<string, SportSession[]>,
  subscribedSessions: [] as SportSessionWithStatus[],
  sessionMessages: {} as Record<string, Message[]>,
  subscribers: {} as Record<string, Participant[]>
}

export const clearCache = () => {
  cache.friends = []
  cache.sessions = {}
  cache.subscribedSessions = []
  cache.sessionMessages = {}
  cache.subscribers = {}
}