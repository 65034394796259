import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker, InfoWindow, Autocomplete } from '@react-google-maps/api';
import { useProfile } from '../contexts/profileContext';
import { Place, listPlaces } from '../api';
import Text, { Title } from '../components/Text';
import styled from 'styled-components';
import geocode from '../localization/geocode';
import { cache } from '../contexts/cache';
import Input from '../components/Input';

const containerStyle = {
  width: '400px',
  height: '400px'
};

const Card = styled.div`
  display: flex;
  flex-direction: column;
`

type Props = {
  onPlaceChanged: (place: Omit<Place, 'id'>) => void
  onError: (error: string) => void
}

const PickPlace = ({ onPlaceChanged, onError }: Props) => {
  const { profile } = useProfile()
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const [selectedPlace, setSelectedPlace] = React.useState<Omit<Place, 'id'> | undefined>();
  const [places, setPlaces] = React.useState<Place[]>(cache.places)
  const autocompleteRef = React.useRef<google.maps.places.Autocomplete>();
  const onLoad = React.useCallback((autocomplete: google.maps.places.Autocomplete) => {
    autocompleteRef.current = autocomplete;
  }, []);

  React.useEffect(() => {
    listPlaces().then(setPlaces)
  },[])
  
  useEffect(() => {
    // Function to geocode postal code
    if(!profile?.postalCode) return
    const [,,,,,lat,lng] = geocode.split('\n').map(line => line.split(',')).find(([,,postalCode]) => postalCode === profile?.postalCode) || []
    if(lng && lat) setMapCenter({ lat: parseFloat(lat), lng: parseFloat(lng) })
  }, [profile?.postalCode]);

  const marks = React.useMemo(() => {
    return places.map(place => (
      <Marker 
        key={place.id} 
        position={{ lat: place.latitude, lng: place.longitude }}
        onClick={() => {
          setSelectedPlace(place)
          onPlaceChanged(place)
        }}
      />
    ))
  }, [onPlaceChanged, places])

  const unselectPlace = React.useCallback(() => setSelectedPlace(undefined), [])
  const placePosition = React.useMemo(() => selectedPlace ? { lat: selectedPlace.latitude, lng: selectedPlace.longitude } : undefined, [selectedPlace])
  const placeDetails = React.useMemo(() => selectedPlace ? {
    name: selectedPlace.name,
    websiteUrl: selectedPlace.websiteUrl || '',
    phoneNumber: selectedPlace.phoneNumber || '',
    address: selectedPlace.address
  } : undefined, [selectedPlace])

  const onAutocomplete = React.useCallback(() => {
    if (autocompleteRef.current) {
      const selectedPlace = autocompleteRef.current.getPlace();
      const latitude = selectedPlace.geometry?.location?.lat()
      const longitude = selectedPlace.geometry?.location?.lng()
      if(!latitude || !longitude) return onError('latitude and longitude are missing')
      const place = ({
        name: selectedPlace.name || "",
        address: selectedPlace.formatted_address || "",
        phoneNumber: selectedPlace.formatted_phone_number,
        websiteUrl: selectedPlace.website,
        latitude,
        longitude
      });
      onPlaceChanged(place)
      setSelectedPlace(place)
    }
  }, [onError, onPlaceChanged])

  return (
    <div>
      <Autocomplete
        onLoad={onLoad}
        onPlaceChanged={onAutocomplete}
        >
        <Input name='enterPlace' />
      </Autocomplete>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={mapCenter}
        zoom={10}
        >
        {marks}

        {placeDetails && (
          <InfoWindow
          position={placePosition}
          onCloseClick={unselectPlace}
          >
            <Card>
              <Title name='placeName' values={placeDetails} />
              <Text name='placeAddress' values={placeDetails} />
              <Text name='placeWebsite' values={placeDetails} />
              <Text name='placePhone' values={placeDetails} />
            </Card>
          </InfoWindow>
        )}
      </GoogleMap>
    </div>
  );
}

export default PickPlace;